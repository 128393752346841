import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, DialogActions, Grid, Typography } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { makeStyles, useTheme } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import LDBasePortal from 'components/selects/LDBasePortal';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import { getGridApi } from 'services/grid';
import { useCompanyId } from 'hooks/auth';
import GridIconSVG from 'assets/images/svg/GridIconSVG';
import { getViewsApi } from 'services/view';
import { VIEW_TYPES } from 'const/gridUI';
import AccessViewSVG from 'assets/images/svg/AccessViewSVG';
import GridContainerSVG from 'assets/images/svg/GridContainerSVG';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notifier/actions';
import { useDispatch } from 'react-redux';
import { useSelectedGroupAccess, useSelectedGroupDetail } from 'hooks/permission';
import LDBaseGridBranch from 'components/selects/LDBaseGridBranch';
import ViewIcon from 'gridUI/views/ViewIcon';
import * as groupActions from 'permission/actions/group';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import uuidv1 from 'uuid/v1';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useCurrentSubscriptionIs } from 'hooks/payment';
import { PLAN_TYPES } from 'const';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    root: {},
    dialogContent: {
        width: 943,
        minHeight: 500,
        padding: 0
    },
    content: {
        padding: '0 24px'
    },
    tableContainer: {
        marginTop: 40,
        padding: '0 50px 0 24px',
        maxHeight: 400,
        overflow: 'auto'
    },
    tableWrapper: {
        border: `1px solid ${theme.colors.border}`,
        borderBottomWidth: 0,
        boxSizing: 'border-box'
    },
    tableHeader: {
        padding: '18px 16px',
        background: theme.colors.background
    },
    tableContent: {},
    tableItem: {
        padding: '7px 16px',
        borderTop: `1px solid ${theme.colors.border}`,
        position: 'relative'
    },
    item: {
        display: 'flex',
        alignItems: 'center'
    },
    tableClose: {
        position: 'absolute',
        top: 17,
        right: -30,
        '& svg': {
            cursor: 'pointer'
        }
    },
    addMoreContainer: {
        padding: '0 50px 0 24px'
    },
    addMoreWrapper: {
        padding: '0 16px',
        height: 50,
        border: `1px solid ${theme.colors.border}`
    },
    ldPortal: {
        width: '100%'
    },
    lbPortalControl: {
        border: 0,
        padding: '0 30px 0 0'
    },
    dialogAction: {
        marginTop: 35,
        borderTop: `1px solid ${theme.colors.border}`
    },
    btnActions: {
        padding: '16px 42px 16px 24px'
    },
    gridLDPortal: {
        width: 360
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    relative: {
        position: 'relative'
    }
}));

const WORKSPACEICON = () => <WorkspaceSVG color="#78778B" />;
const DATABASEICON = () => <DatabaseSVG />;
const GRIDICON = () => <GridContainerSVG />;

const allDatabases = { value: null, label: `(${i18n.t('all_databases')})`, icon: DATABASEICON };
const allGrids = { value: null, label: `(${i18n.t('all_grids')})`, icon: GRIDICON };
const allViews = { value: null, label: `(${i18n.t('all_views')})`, icon: () => <ViewIcon view={{}} size="small" /> };

const getInitData = () => {
    return {
        id: uuidv1(),
        workspace: null,
        database: null,
        grid: null,
        view: null,
        isNew: true
    };
};

const AddAccess = ({ projects }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const [sorting, setSorting] = useState({});
    const [data, setData] = useState([getInitData()]);
    const [gridOptions, setGridOptions] = useState({});
    const [viewOptions, setViewOptions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const companyId = useCompanyId();
    const selectedGroupDetail = useSelectedGroupDetail();
    const selectedGroupAccess = useSelectedGroupAccess();
    const isProPlan = useCurrentSubscriptionIs(PLAN_TYPES.PROFESSIONAL);
    const removeViews = useRef([]);

    const projectOptions = React.useMemo(() => {
        return projects?.map(project => {
            return {
                label: project.name,
                value: project.id,
                icon: WORKSPACEICON
            };
        });
    }, [projects]);

    const databaseOptions = useMemo(() => {
        if (!isEmpty(projects)) {
            return projects.reduce((acc, cur) => {
                acc[cur.id] = [
                    { ...allDatabases },
                    ...(cur?.databases?.map(database => ({
                        label: database.name,
                        value: database.id,
                        icon: DATABASEICON
                    })) || [])
                ];
                return acc;
            }, {});
        }
        return {};
    }, [projects]);

    const filterOptionRow = useMemo(() => {
        let obj = {};
        data.map((el, index) => {
            let str = `${el?.workspace?.value || ''}`;
            if (el?.database?.value) {
                str += `.${el.database.value}`;
            }
            if (el?.grid?.value) {
                str += `.${el.grid.value}`;
            }
            if (el?.view?.value) {
                str += `.${el.view.value}`;
            }
            obj[index] = str;
            return false;
        });
        return obj;
    }, [data]);

    useEffect(() => {
        if (open && !isEmpty(selectedGroupAccess) && !isEmpty(projects)) {
            let newData = [];
            if (!isEmpty(selectedGroupAccess.projects)) {
                const accessView = selectedGroupAccess.projects.map(project => ({
                    id: uuidv1(),
                    workspace: { value: project.id, label: project.name, icon: WORKSPACEICON },
                    database: { ...allDatabases },
                    grid: { ...allGrids },
                    view: { ...allViews },
                    isServerData: true
                }));
                newData = [...newData, ...accessView];
            }
            if (!isEmpty(selectedGroupAccess.databases)) {
                const accessView = selectedGroupAccess.databases.map(database => ({
                    id: uuidv1(),
                    workspace: { value: database.project.id, label: database.project.name, icon: WORKSPACEICON },
                    database: { value: database.id, label: database.name, icon: DATABASEICON },
                    grid: { ...allGrids },
                    view: { ...allViews },
                    isServerData: true
                }));
                newData = [...newData, ...accessView];
            }
            if (!isEmpty(selectedGroupAccess.accessViews)) {
                const accessView = selectedGroupAccess.accessViews.map(accessView => ({
                    id: uuidv1(),
                    workspace: { value: accessView.workspaceId, label: accessView.workspaceName, icon: WORKSPACEICON },
                    database: { value: accessView.databaseId, label: accessView.databaseName, icon: DATABASEICON },
                    grid: {
                        value: accessView.gridId,
                        label: accessView.gridName,
                        parentGridId: accessView.parentGridId,
                        parentGridName: accessView.parentGridName,
                        icon: GRIDICON
                    },
                    view: accessView.viewId
                        ? {
                              value: accessView.viewId,
                              label: accessView.viewName,
                              icon: () => <ViewIcon view={{ ...(accessView?.viewProperties || {}) }} size="small" />
                          }
                        : { ...allViews },
                    isServerData: true
                }));
                newData = [...newData, ...accessView];
            }
            setData(newData);
        }
    }, [open, selectedGroupAccess, projects]);

    const openDialog = useCallback(() => {
        setOpen(true);
    }, []);

    const closeDialog = useCallback(() => {
        setOpen(false);
        setData([getInitData()]);
        removeViews.current = [];
    }, []);

    const dataSorted = React.useMemo(() => {
        const cloneData = [...data];
        return cloneData;
    }, [data]);

    const fetchGrids = useCallback(
        async ({ dbId }) => {
            let grids = [];
            try {
                grids = await getGridApi({ companyId, dbId });
            } catch (err) {
                console.log('err', err);
            }
            if (isEmpty(grids)) return;
            let cloneGridOptions = { ...gridOptions };
            cloneGridOptions[dbId] = grids.map(grid => ({
                ...grid,
                label: grid.gridName,
                value: grid.gridId,
                icon: GRIDICON
            }));
            setGridOptions(cloneGridOptions);
        },
        [companyId, gridOptions]
    );

    const fetchViews = useCallback(
        async ({ dbId, gridId }) => {
            let list = [];
            try {
                list = await getViewsApi({
                    dbId,
                    gridId,
                    paramOptions: {
                        includeDefaultView: true,
                        expand: VIEW_TYPES.ACCESS_VIEW
                    }
                });
            } catch (err) {
                console.log('err', err);
            }
            if (isEmpty(list)) return;
            const cloneViewOptions = { ...viewOptions };
            if (!cloneViewOptions[dbId]) {
                cloneViewOptions[dbId] = {};
            }
            cloneViewOptions[dbId][gridId] = list.map(view => ({
                ...view,
                label: view.name,
                value: view.id,
                icon: () => <ViewIcon view={view} size="small" />
            }));
            setViewOptions(cloneViewOptions);
        },
        [viewOptions]
    );

    const onChangeData = useCallback(
        async (key, index, option) => {
            const cloneData = [...data];
            if (cloneData[index].isServerData) {
                removeViews.current.push({ ...cloneData[index] });
                cloneData[index].isServerData = undefined;
                cloneData[index].isNew = true;
            }
            cloneData[index][key] = option;
            if (key === 'workspace') {
                cloneData[index].database = { ...allDatabases };
                cloneData[index].grid = { ...allGrids };
                cloneData[index].view = { ...allViews };
            }
            if (key === 'database') {
                cloneData[index].grid = { ...allGrids };
                cloneData[index].view = { ...allViews };
            }
            if (key === 'grid') {
                cloneData[index].view = { ...allViews };
            }
            setData(cloneData);
            if (key === 'database') {
                if (gridOptions[option.value]) return;
                fetchGrids({ dbId: option.value });
            }
            if (key === 'grid') {
                const dbId = cloneData[index].database.value;
                const gridId = option.value;
                if (viewOptions[dbId]?.[gridId]) return;
                fetchViews({ dbId, gridId });
            }
        },
        [data, gridOptions, viewOptions, fetchGrids, fetchViews]
    );

    const getListSelectedOption = useCallback(
        rowIndex => {
            let obj = { ...filterOptionRow };
            delete obj[rowIndex];
            return Object.values(obj);
        },
        [filterOptionRow]
    );

    const workspaceRenderer = useCallback(
        props => {
            const { rowIndex, rowData } = props;
            const selectedProjectOption = rowData?.workspace;
            const listSelectedOption = getListSelectedOption(rowIndex);
            const options = [...projectOptions].filter(el => !listSelectedOption.includes(`${el?.value}`));
            return (
                <LDBasePortal
                    ddPlaceholder={t('select_a_project')}
                    iconPlaceholder={<WorkspaceSVG color={!selectedProjectOption ? '#BCBBC5' : '#78778B'} />}
                    options={options}
                    dropdownClassName={classes.ldPortal}
                    controlClassName={classes.lbPortalControl}
                    onChange={option => {
                        if (option?.value === selectedProjectOption?.value) return;
                        onChangeData('workspace', rowIndex, option);
                    }}
                    defaultValue={selectedProjectOption}
                    showTooltip
                />
            );
        },
        [classes, projectOptions, onChangeData, getListSelectedOption, t]
    );

    const databaseRenderer = useCallback(
        props => {
            const { rowIndex, rowData } = props;
            const selectedDatabaseOption = rowData?.database;
            const listSelectedOption = getListSelectedOption(rowIndex);
            const options = databaseOptions[rowData?.workspace?.value]?.filter(
                el =>
                    !listSelectedOption.includes(
                        el?.value ? `${rowData?.workspace?.value}.${el?.value}` : `${rowData?.workspace?.value}`
                    )
            );
            return (
                <LDBasePortal
                    ddPlaceholder={t('select_a_database')}
                    iconPlaceholder={<DatabaseSVG color={!selectedDatabaseOption ? '#BCBBC5' : '#79778B'} />}
                    options={options}
                    dropdownClassName={classes.ldPortal}
                    controlClassName={classes.lbPortalControl}
                    onChange={option => {
                        if (option?.value === selectedDatabaseOption?.value) return;
                        onChangeData('database', rowIndex, option);
                    }}
                    defaultValue={selectedDatabaseOption}
                    showTooltip
                />
            );
        },
        [classes, onChangeData, databaseOptions, getListSelectedOption, t]
    );

    const gridRenderer = useCallback(
        props => {
            const { rowIndex, rowData } = props;
            const selectedDatabaseOption = rowData?.database;
            const selectedGridOption = rowData?.grid;
            let options = gridOptions[selectedDatabaseOption?.value] || [];
            if (selectedDatabaseOption) {
                options = [{ ...allGrids }, ...options];
            }
            const listSelectedOption = getListSelectedOption(rowIndex);
            options = options.filter(
                el =>
                    !listSelectedOption.includes(
                        el?.value
                            ? `${rowData?.workspace?.value}.${rowData?.database?.value}.${el?.value}`
                            : `${rowData?.workspace?.value}.${rowData?.database?.value}`
                    )
            );
            return (
                <LDBaseGridBranch
                    dropdownClassName={classes.ldPortal}
                    ddPlaceholder={t('select_a_grid')}
                    iconPlaceholder={<GridIconSVG color="#BCBBC5" />}
                    options={options}
                    controlClassName={classes.lbPortalControl}
                    onChange={option => {
                        if (option?.value === selectedGridOption?.value) return;
                        onChangeData('grid', rowIndex, option);
                    }}
                    defaultValue={selectedGridOption}
                    placement="bottom-start"
                    afterToggleOpen={({ isOpen }) => {
                        if (isOpen && options.length <= 1 && selectedDatabaseOption?.value) {
                            fetchGrids({ dbId: selectedDatabaseOption.value });
                        }
                    }}
                    showTooltip
                />
            );
        },
        [classes, onChangeData, gridOptions, fetchGrids, getListSelectedOption, t]
    );

    const viewRenderer = useCallback(
        props => {
            const { rowIndex, rowData } = props;
            const selectedDatabaseOption = rowData?.database;
            const selectedGridOption = rowData?.grid;
            const selectedViewOption = rowData?.view;
            const isDisabled = isProPlan;
            let options =
                viewOptions[selectedDatabaseOption?.value]?.[selectedGridOption?.value]?.map(item => ({
                    ...item,
                    isDisabled,
                    moreIcon: isDisabled ? () => <UpgradeSVG /> : null
                })) || [];
            if (selectedGridOption) {
                options = [{ ...allViews }, ...options];
            }
            const listSelectedOption = getListSelectedOption(rowIndex);
            options = options.filter(
                el =>
                    !listSelectedOption.includes(
                        el?.value
                            ? `${rowData?.workspace?.value}.${rowData?.database?.value}.${rowData?.grid?.value}.${el?.value}`
                            : `${rowData?.workspace?.value}.${rowData?.database?.value}.${rowData?.grid?.value}`
                    )
            );
            return (
                <LDBasePortal
                    ddPlaceholder={t('select_a_view')}
                    iconPlaceholder={<AccessViewSVG color="#BCBBC5" width={20} height={20} />}
                    options={options}
                    dropdownClassName={classes.ldPortal}
                    controlClassName={classes.lbPortalControl}
                    onChange={option => {
                        if (option?.value === selectedViewOption?.value) return;
                        onChangeData('view', rowIndex, option);
                    }}
                    defaultValue={selectedViewOption}
                    afterToggleOpen={({ isOpen }) => {
                        if (
                            isOpen &&
                            options.length <= 1 &&
                            selectedDatabaseOption?.value &&
                            selectedGridOption?.value
                        ) {
                            fetchViews({ dbId: selectedDatabaseOption.value, gridId: selectedGridOption.value });
                        }
                    }}
                    showTooltip
                />
            );
        },
        [classes, onChangeData, viewOptions, fetchViews, getListSelectedOption, isProPlan, t]
    );

    const removeData = useCallback(dataId => {
        setData(prev => {
            const accessView = prev.find(ac => ac.id === dataId);
            if (accessView?.isServerData) {
                removeViews.current.push(accessView);
            }
            return prev.filter(d => d.id !== dataId);
        });
    }, []);

    const onAccessAdd = useCallback(() => {
        setData(prev => [...prev, getInitData()]);
    }, []);

    const tableContainerHeight = useMemo(() => {
        let height = 56;
        dataSorted.map(d => {
            height += d?.database?.parentGridName ? 67 : 51;
            return null;
        });
        return height;
    }, [dataSorted]);

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true);
            const { projects, databases } = selectedGroupAccess;
            let oldProjectIds = projects.map(p => p.id);
            let oldDatabaseIds = databases.map(d => d.id);
            const removeViewsServer = [];
            removeViews.current.map(d => {
                if (!!d.grid?.value || !!d.view?.value) {
                    removeViewsServer.push({
                        workspaceId: d.workspace?.value || undefined,
                        databaseId: d.database?.value || undefined,
                        gridId: d.grid?.value || undefined,
                        viewId: d.view?.value || undefined
                    });
                    return false;
                }
                if (!!d.database?.value) {
                    oldDatabaseIds = oldDatabaseIds.filter(id => id !== d.database.value);
                    return false;
                }
                oldProjectIds = oldProjectIds.filter(id => id !== d.workspace.value);
                return false;
            });

            const addNewViews = data.filter(
                d => !!d.isNew && (!!d.workspace?.value || !!d.database?.value || !!d.grid?.value || !!d.view?.value)
            );
            const addViews = [];
            const addProjectIds = [];
            const addDatabaseIds = [];
            addNewViews.map(d => {
                if (!!d.grid?.value || !!d.view?.value) {
                    addViews.push({
                        workspaceId: d.workspace?.value || undefined,
                        databaseId: d.database?.value || undefined,
                        gridId: d.grid?.value || undefined,
                        viewId: d.view?.value || undefined
                    });
                    return false;
                }
                if (!!d.database?.value) {
                    addDatabaseIds.push(d.database.value);
                    return false;
                }
                addProjectIds.push(d.workspace.value);
                return false;
            });
            dispatch(
                groupActions.updateGroupWithWorkspaceAccess({
                    groupName: selectedGroupDetail.name,
                    addViews,
                    removeViews: removeViewsServer,
                    projectIds: [...oldProjectIds, ...addProjectIds],
                    dbIds: [...oldDatabaseIds, ...addDatabaseIds],
                    successCallback: () => {
                        setIsLoading(false);
                        closeDialog();
                        dispatch(
                            enqueueSnackbar({
                                message: t('change_saved'),
                                type: 'info'
                            })
                        );
                        dispatch(groupActions.fetchGroupWorkspacesAccess({}));
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        console.log('failed to update group');
                    }
                })
            );
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'info'
                })
            );
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, t, data, closeDialog, selectedGroupDetail, selectedGroupAccess]);

    return (
        <Grid item className={classes.root}>
            <ButtonBase variant="outlined" width={212} onClick={openDialog}>
                + {t('add_new_access')}
            </ButtonBase>
            <Dialog maxWidth={false} open={open}>
                <DialogTitle title={t('add_access')} onClose={closeDialog} />
                <DialogContent className={classes.dialogContent}>
                    <Typography variant="body2" className={classes.content}>
                        {t('grant_access_to_project')}
                    </Typography>
                    <Grid item className={classes.tableContainer}>
                        <Grid item className={classes.tableWrapper}>
                            <Grid container className={classes.tableHeader}>
                                <Grid item xs={3}>
                                    {t('project')}
                                </Grid>
                                <Grid item xs={3}>
                                    {t('database')}
                                </Grid>
                                <Grid item xs={3}>
                                    {t('grid')}/{t('branch')}
                                </Grid>
                                <Grid item xs={2}>
                                    {t('view')}
                                </Grid>
                            </Grid>
                            <Grid item className={classes.tableContent}>
                                {dataSorted.map((data, index) => {
                                    return (
                                        <Grid container key={data.id} className={classes.tableItem}>
                                            <Grid item className={classes.item} xs={3}>
                                                {workspaceRenderer({ rowIndex: index, rowData: data })}
                                            </Grid>
                                            <Grid item className={classes.item} xs={3}>
                                                {databaseRenderer({ rowIndex: index, rowData: data })}
                                            </Grid>
                                            <Grid item className={classes.item} xs={3}>
                                                {gridRenderer({ rowIndex: index, rowData: data })}
                                            </Grid>
                                            <Grid item className={classes.item} xs={3}>
                                                {viewRenderer({ rowIndex: index, rowData: data })}
                                            </Grid>
                                            <Grid item className={classes.tableClose}>
                                                <CloseIconSVG onClick={() => removeData(data.id)} />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classes.addMoreContainer}
                        style={{
                            paddingRight: tableContainerHeight > 400 ? 67 : 50
                        }}
                    >
                        <Grid container className={classes.addMoreWrapper} justify="flex-end" alignItems="center">
                            <Grid item style={{ flex: 1 }} />
                            <Grid item>
                                <Grid container spacing={1} onClick={onAccessAdd}>
                                    <Grid item style={{ display: 'flex', cursor: 'pointer' }}>
                                        <ColorAddSVG color={theme.palette.primary.main} />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                            variant="body1"
                                        >
                                            Add Another Access
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Grid
                        container
                        item
                        justify="flex-end"
                        alignItems="center"
                        className={classes.btnActions}
                        style={{
                            paddingRight: tableContainerHeight > 400 ? 59 : 42
                        }}
                    >
                        <Grid item style={{ marginRight: 16 }}>
                            <ButtonBase width={140} variant="outlined" onClick={closeDialog}>
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.relative}>
                            <ButtonBase width={140} variant="contained" disabled={isLoading} onClick={onSubmit}>
                                {t('global_save')}
                            </ButtonBase>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default React.memo(AddAccess);
