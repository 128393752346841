import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import { Grid, TextareaAutosize, Typography } from '@material-ui/core';
import { object, string } from 'yup';
import i18n from 'i18n';
import { useFormik } from 'formik';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import { createSegment } from 'tm/actions';
import CircularProgress from 'components/CircularProgress';
import { useTmSourceSelected, useTmTargetsSelected } from 'hooks/tm';
import { LOCALIZATION_FONT } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: 700
    },
    inputText: {
        flex: 1,
        width: '100%',
        height: '100px !important',
        padding: 8,
        overflowY: 'auto !important',
        resize: 'none',
        outline: 'none',
        border: props =>
            props.error ? `1px solid ${theme.colors.burntSlena} !important` : `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        },
        '&.Mui-disabled': {
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        }
    },
    flex1: {
        flex: 1
    },
    mb32: {
        marginBottom: 32
    }
}));

const RenderItemTarget = ({ target, handleChange }) => {
    const classes = useStyles();

    const fontJPSource = React.useMemo(() => {
        return LOCALIZATION_FONT?.[target?.value];
    }, [target]);

    return (
        <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            spacing={3}
            className={fontJPSource}
        >
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                spacing={2}
                style={{ flex: 1, maxWidth: 260 }}
            >
                <Grid item className={classes.icon}>
                    {target?.icon()}
                </Grid>
                <Grid item>
                    {target?.languageCountry} - {target?.value}
                </Grid>
            </Grid>
            <Grid item className={classes.flex1}>
                <TextareaAutosize
                    className={classes.inputText}
                    placeholder="Input target"
                    name={target?.value}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    );
};

const Schema = object({
    sourceText: string().required(i18n.t(`global_this_field_is_required`))
});

const AddSegment = ({ open, onClose, tmId, onRestTable }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const targets = useTmTargetsSelected() || [];
    const source = useTmSourceSelected() || null;

    const fontJPSource = React.useMemo(() => {
        return LOCALIZATION_FONT?.[source?.value];
    }, [source]);

    const formik = useFormik({
        initialValues: {
            sourceText: ''
        },
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            const keys = Object.keys(values);

            var result = targets.reduce(function(map, obj) {
                const checking = keys.includes(obj.value);
                map[obj.value] = [
                    {
                        value: checking ? values[obj.value] : '',
                        primary: true
                    }
                ];
                return map;
            }, {});

            let body = {
                sourceText: values.sourceText,
                sourceLang: source?.value,
                segmentsByTargetLang: result
            };

            dispatch(
                createSegment({
                    tmId,
                    body,
                    successCallback: () => {
                        onClose && onClose();
                        onRestTable({ resetPage: true, resetSelectedRows: [] });
                    },
                    errorCallback: () => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 800);
                    }
                })
            );
        }
    });
    const { errors, handleChange, handleSubmit, isSubmitting, isValid, dirty } = formik;

    return (
        <Dialog maxWidth={true} open={open} onClose={onClose}>
            <DialogTitle title={t(`add_segment`)} onClose={onClose} className={classes.title} />
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body1"> {t('source_language')}</Typography>
                            </Grid>
                            <Grid item container direction="column" spacing={2} className={classes.mb32}>
                                <Grid
                                    item
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    wrap="nowrap"
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        wrap="nowrap"
                                        spacing={2}
                                        style={{ flex: 1, maxWidth: 260 }}
                                    >
                                        <Grid item className={classes.icon}>
                                            {source?.icon()}
                                        </Grid>
                                        <Grid item>
                                            {source?.languageCountry} - {source?.value}
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ flex: 1 }} className={fontJPSource}>
                                        <TextareaAutosize
                                            className={classes.inputText}
                                            placeholder="Input source"
                                            name="sourceText"
                                            error={errors.sourceText}
                                            errorText={errors.sourceText}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1"> {t('target_language')}</Typography>
                            </Grid>

                            {targets?.map((t, i) => (
                                <RenderItemTarget key={i} target={t} handleChange={handleChange} errors={errors} />
                            ))}
                        </Grid>
                        <Grid item container direction="column" className={classes.row} spacing={2}>
                            <Grid
                                className={classes.btnContainer}
                                container
                                alignItems="center"
                                justify="flex-end"
                                spacing={3}
                            >
                                <Grid item>
                                    <ButtonBase width={120} variant="outlined" onClick={onClose}>
                                        {t('global_cancel')}
                                    </ButtonBase>
                                </Grid>
                                <Grid item>
                                    <ButtonBase
                                        width={120}
                                        variant="contained"
                                        type="submit"
                                        disabled={!isValid || isSubmitting || !dirty}
                                    >
                                        Add
                                        {isSubmitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default React.memo(AddSegment);
