import React from 'react';

const SwitchIconSVG = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.3631 0.0948935C8.13094 -0.0591837 7.81512 -0.0241218 7.62312 0.189073C7.41186 0.423588 7.43083 0.784914 7.66523 0.996063L9.09769 2.28575H1.32148L1.24394 2.29097C0.965042 2.32881 0.750049 2.56791 0.750049 2.85718C0.750049 3.17275 1.00591 3.42861 1.32148 3.42861H9.10689L7.66523 4.72674L7.60665 4.78784C7.42928 5.00253 7.43106 5.32054 7.62312 5.53373C7.83432 5.76823 8.19534 5.7872 8.43003 5.576L10.9108 3.34239L10.9678 3.29917L11.0201 3.24795C11.0642 3.19891 11.0983 3.14436 11.1225 3.08681C11.1527 3.01475 11.1675 2.93795 11.1669 2.86137C11.168 2.72367 11.1195 2.58528 11.0201 2.47486C10.9869 2.43803 10.9501 2.40657 10.9108 2.38041L8.43003 0.146802L8.3631 0.0948935ZM4.29384 5.90343C4.18196 5.7792 4.02794 5.71543 3.87308 5.71439C3.73553 5.71342 3.59713 5.76183 3.48692 5.86114L1.00618 8.09474C0.966834 8.12091 0.930003 8.1524 0.8968 8.1892C0.79747 8.29966 0.748921 8.438 0.750018 8.57571C0.749479 8.65691 0.76622 8.73834 0.80026 8.81417C0.823976 8.86697 0.856064 8.91697 0.8968 8.96229L0.94535 9.01017L1.00618 9.05674L3.48692 11.2903C3.56672 11.3621 3.66103 11.4073 3.75897 11.4265C3.94953 11.4638 4.15433 11.4029 4.29384 11.2481C4.50506 11.0135 4.48609 10.6522 4.25171 10.4411L2.81924 9.15143H10.5954C10.911 9.15143 11.1669 8.89554 11.1669 8.58C11.1669 8.2644 10.911 8.00857 10.5954 8.00857H2.81002L4.25171 6.7104C4.48609 6.49926 4.50506 6.13794 4.29384 5.90343Z"
                fill="#78778B"
            />
        </svg>
    );
};

export default React.memo(SwitchIconSVG);
