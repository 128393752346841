import React from 'react';
import GridButtonAdd from './GridButtonAdd';
import { Grid } from '@material-ui/core';
import MenuBase from 'components/menus/Base';
import GridMenu from './GridMenu';
import { useIsSkeletonLoadingId } from 'hooks/grid';
import Dialog from 'components/dialog/Dialog';
import LocalizationEmptySetup from './localization/LocalizationEmptySetup';
import LocalizationImportJSONSetup from './localization/LocalizationImportJSONSetup';
import LocalizationImportPOSetup from './localization/LocalizationImportPOSetup';
import GridUploadSetup from './upload/GridUploadSetup';
import GridImport from './GridImport';

import { isFileJSON, isFilePO } from 'utils/fileUtils';
import Templates from 'template/grid';

const LOCALIZATION_TYPES = {
    EMPTY: 'empty',
    UPLOAD: 'upload'
};

function GridAdd({ dbId, fetchingDisabled }) {
    const isSkeletonLoadingId = useIsSkeletonLoadingId();
    const [isOpenLocalizationSetup, setIsOpenLocalizationSetup] = React.useState(false);
    const [localizationType, setLocalizationType] = React.useState(null);
    const [isOpenGridSetup, setIsOpenGridSetup] = React.useState(false);
    const [files, setFiles] = React.useState(null);
    const [isOpenTemplates, setIsOpenTemplates] = React.useState(false);

    const openLocalizationSetupHandler = () => {
        setIsOpenLocalizationSetup(true);
    };

    const closeLocalizationSetupHandler = () => {
        setIsOpenLocalizationSetup(false);
        setLocalizationType(null);
        setFiles(null);
    };

    const openUploadGridSetupHandler = () => {
        setIsOpenGridSetup(true);
    };

    const closeUploadGridSetupHandler = () => {
        setIsOpenGridSetup(false);
        setFiles(null);
    };

    const handleOpenTemplates = () => setIsOpenTemplates(true);
    const handleCloseTemplates = () => setIsOpenTemplates(false);

    const renderUpload = React.useMemo(() => {
        const isLocalizationUpload = localizationType === LOCALIZATION_TYPES.UPLOAD;
        const isLocalizationSetupEmpty = localizationType === LOCALIZATION_TYPES.EMPTY;

        const renderFileSetup = isFilePO(files?.[0]) ? (
            <LocalizationImportPOSetup
                dbId={dbId}
                onClose={closeLocalizationSetupHandler}
                isMulti={false}
                isUploading={false}
                files={files}
            />
        ) : (
            <GridImport dbId={dbId} onClose={closeLocalizationSetupHandler} files={files} />
        );

        const renderLocalizationImport = isFileJSON(files?.[0]) ? (
            <LocalizationImportJSONSetup
                dbId={dbId}
                onClose={closeLocalizationSetupHandler}
                isMulti={false}
                isUploading={false}
                files={files}
            />
        ) : (
            renderFileSetup
        );

        const renderEmpty = isLocalizationSetupEmpty ? (
            <LocalizationEmptySetup dbId={dbId} onClose={closeLocalizationSetupHandler} isMulti={false} />
        ) : (
            ''
        );

        return isLocalizationUpload ? renderLocalizationImport : renderEmpty;
    }, [dbId, files, localizationType]);

    return (
        <Grid item>
            <Grid container justify={'flex-end'}>
                <Grid item>
                    <MenuBase
                        Trigger={({ handleClick }) => (
                            <GridButtonAdd
                                buttonClickHandler={e => {
                                    handleClick(e);
                                }}
                                disabled={!!isSkeletonLoadingId}
                                fetchingDisabled={fetchingDisabled}
                            />
                        )}
                        Content={({ setOpen }) => (
                            <GridMenu
                                dbId={dbId}
                                setOpen={setOpen}
                                openUploadLocalizationSetup={files => {
                                    setOpen(false);
                                    openLocalizationSetupHandler();
                                    setFiles(files);
                                    setLocalizationType(LOCALIZATION_TYPES.UPLOAD);
                                }}
                                openEmptyLocalizationSetup={() => {
                                    setOpen(false);
                                    openLocalizationSetupHandler();
                                    setLocalizationType(LOCALIZATION_TYPES.EMPTY);
                                }}
                                openUploadGridSetup={files => {
                                    setOpen(false);
                                    setFiles(files);
                                    openUploadGridSetupHandler();
                                }}
                                addGridFromTemplate={() => {
                                    setOpen(false);
                                    handleOpenTemplates();
                                }}
                            />
                        )}
                        left={true}
                        offset={45}
                        width={200}
                    />
                </Grid>
            </Grid>
            <Dialog maxWidth="lg" open={isOpenLocalizationSetup} onClose={closeLocalizationSetupHandler}>
                {renderUpload}
            </Dialog>

            <Dialog maxWidth="lg" onClose={closeUploadGridSetupHandler} open={isOpenGridSetup} scroll={'paper'}>
                <GridUploadSetup dbId={dbId} onClose={closeUploadGridSetupHandler} files={files} />
            </Dialog>

            <Dialog maxWidth={false} open={isOpenTemplates} onClose={handleCloseTemplates}>
                <Templates dbId={dbId} onClose={handleCloseTemplates} />
            </Dialog>
        </Grid>
    );
}

export default React.memo(GridAdd);
