import i18n from 'i18n';

const WINDOWS_KEYBOARD_LIST = {
    General: [
        {
            label: i18n.t('keyboard_copy'),
            keys: [['Ctrl', 'C']]
        },
        // {
        //     label: 'Cut',
        //     keys: [['Ctrl', 'X']]
        // },
        {
            label: i18n.t('keyboard_paste'),
            keys: [['Ctrl', 'V']]
        },
        {
            label: i18n.t('keyboard_undo'),
            keys: [['Ctrl', 'Z']]
        },
        {
            label: i18n.t('keyboard_redo'),
            keys: [
                ['Ctrl', 'Y'],
                ['Cmd', 'Shift', 'Z']
            ]
        }
        // {
        //     label: 'Find on column',
        //     keys: [['Ctrl', 'F']]
        // },
        // {
        //     label: 'Find & replace on column',
        //     keys: [['Ctrl', 'Shift', 'H']]
        // }
    ],
    [i18n.t('navigate_on_grid')]: [
        {
            label: i18n.t('keyboard_scroll_up'),
            keys: [['Fn', '↑'], ['Page Up']]
        },
        {
            label: i18n.t('keyboard_scroll_down'),
            keys: [['Fn', '↓'], ['Page Down']]
        },
        {
            label: i18n.t('keyboard_scroll_to_first_column'),
            keys: [['Fn', '←'], ['Home']]
        },
        {
            label: i18n.t('keyboard_scroll_to_last_column'),
            keys: [['Fn', '→'], ['End']]
        },
        {
            label: i18n.t('keyboard_scroll_to_first_row'),
            keys: [['Ctrl', '↑']]
        },
        {
            label: i18n.t('keyboard_scroll_to_last_row'),
            keys: [['Ctrl', '↓']]
        }
    ],
    [i18n.t('edit_cell')]: [
        {
            label: i18n.t('keyboard_edit_selected_cell'),
            keys: [['F2'], ['Enter']]
        },
        {
            label: i18n.t('keyboard_finish_and_move_to_below_cell'),
            keys: [['Ctrl', 'Enter']]
        },
        {
            label: i18n.t('keyboard_finish_and_move_to_next_cell'),
            keys: [['Tab']]
        }
    ]
};

export const WINDOW_KEYBOARD_LIST_HEADER = {
    label: 'Keyboard shortcuts',
    keys: ['Ctrl', '/']
};

export default WINDOWS_KEYBOARD_LIST;
