import React, { useMemo } from 'react';
import {
    CircularProgress,
    Collapse,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    useTheme
} from '@material-ui/core';
import ListSelect from 'components/list/Select';
import ButtonBase from 'components/button/Base';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { useViewColumnsWithUserLanguageViewsAndMetadata } from 'hooks/gridUI/column';
import { useDispatch } from 'react-redux';
import { sendManualTrack } from 'tracker';
import { useDependencies, useCompositeViewId } from 'hooks/gridUI';
import { isTempId } from 'utils/uuid';
import * as columnTypes from 'const/columnTypes';
import { ColumnTypeSelectListDisplay, ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import CloseIcon from 'assets/images/svg/CloseIconSVG';
import MemoQSVG from 'assets/images/svg/addon/MemoQSVG';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import InfoIconSVG from 'assets/images/svg/InfoIconSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import { ADDITIONAL_INFORMATION, CHARACTER_LIMIT, RESOURCE_TYPES, USER_SETTINGS } from 'const';
import * as addonActions from 'addons/actions';
import LDBasePortalServer from 'components/selects/LDBasePortalServer';
import { getMemoQProjectsApi, getMemoQTemplatesApi, getWorkflowSubmittedJobsApi } from 'services/workflow';
import InputText from 'components/inputs/InputText';
import { useUserViewSettings, useSelectedWorkspace } from 'hooks/workspace';
import { useDatabaseByDbId } from 'hooks/database';
import Checkbox from 'components/checkbox/Base';
import DependencyStatusGuideItem from 'components/dependencyNote/Base';
import Tooltip from 'components/tooltip/Base';
import { INTEGRATION_PROVIDER } from 'const/inAppAddon';
import { getFuzzyByLanguage, SUPPORTED_LANGUAGES } from 'const/languageData';
import * as workspaceActions from 'workspaces/actions';
import { enqueueSnackbar } from 'notifier/actions';
import { useParams } from 'react-router-dom';

function getDefaultSource(sources) {
    if (sources?.length) return sources?.[0];
    return null;
}

const LIMIT_OPTIONS = 10;

const emptyOption = { value: null, label: 'None' };

const MEMBER_PUBLISH_OPTIONS = {
    CREATE_NEW_PROJECT: 'CREATE_NEW_PROJECT',
    SELECT_EXISTING_PROJECT: 'SELECT_EXISTING_PROJECT',
    SELECT_EXISTING_TRANSLATION_DOCUMENT: `SELECT_EXISTING_TRANSLATION_DOCUMENT`
};

const USER_IMPORT_FILTER = {
    YES: 'YES',
    FALSE: 'FALSE'
};

function ChooseColumns({ onClose, t, id }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const params = useParams();

    const workspaceId = useMemo(() => params?.workspaceId, [params]);
    const dbId = useMemo(() => params?.dbId, [params]);
    const viewId = useMemo(() => params?.viewId, [params]);

    const compositeViewId = useCompositeViewId();
    const workspaceDetail = useSelectedWorkspace();
    const currentDatabase = useDatabaseByDbId(dbId);
    const userViewSetting = useUserViewSettings({ wsId: workspaceId, dbId, viewId });
    const memoQSetting = React.useMemo(() => {
        return userViewSetting?.[USER_SETTINGS.VIEW.MEMO_Q];
    }, [userViewSetting]);

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const [publishOption, setPublishOption] = React.useState(
        memoQSetting?.publishOption || MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_PROJECT
    );
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [selectedTranslationDocument, setSelectedTranslationDocument] = React.useState(null);
    const [projectName, setProjectName] = React.useState(`${workspaceDetail?.name}_${currentDatabase?.name}`);
    const dependencies = useDependencies();
    const [includeUpToDate, setIncludeUpToDate] = React.useState(
        memoQSetting?.includeUpToDate ? memoQSetting?.includeUpToDate : true
    );

    const [projects, setProjects] = React.useState([]);
    const [defaultProjectOptions, setDefaultProjectOptions] = React.useState([]);
    const [defaultTemplateOptions, setDefaultTemplateOptions] = React.useState([]);
    const [segment, setSegment] = React.useState(memoQSetting?.segment || false);
    const [useUserImportFilter, setUseUserImportFilter] = React.useState(
        memoQSetting?.useUserImportFilter || USER_IMPORT_FILTER.FALSE
    );
    const [nameFile, setNameFile] = React.useState('');

    const isProjectNameExisted = React.useMemo(() => {
        return (
            publishOption === MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT &&
            projects.some(project => project.name === projectName)
        );
    }, [publishOption, projects, projectName]);

    React.useEffect(() => {
        const getMemoqProjects = async () => {
            try {
                const projects = await getMemoQProjectsApi({ id });
                setProjects(projects);

                const options = projects?.map(project => ({
                    ...project,
                    label: project?.name,
                    value: project?.id
                }));

                setDefaultProjectOptions(options);
            } catch (error) {
                dispatch(enqueueSnackbar({ message: error?.message }));
                setDefaultProjectOptions([]);
                setProjects([]);
            }
        };
        getMemoqProjects();
    }, [id, dispatch]);

    const isSourceDisabled = React.useMemo(() => {
        const isSelectedTemplate = selectedTemplate ? true : false;
        return publishOption === MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_PROJECT ? true : isSelectedTemplate;
    }, [publishOption, selectedTemplate]);

    const dependenciesWithoutFakeIds = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const parentDpDcColIds = React.useMemo(() => {
        return dependenciesWithoutFakeIds?.map(dpDc => dpDc?.parent);
    }, [dependenciesWithoutFakeIds]);

    const sourceOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isParent = parentDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isParent && columnType === columnTypes.TRANSLATION;
            })
            ?.map(col => {
                const matches = getFuzzyByLanguage({
                    langCode: col?.group,
                    langName: SUPPORTED_LANGUAGES?.find(lang => lang?.id === col?.group)?.name
                });

                return {
                    ...col,
                    value: col?.id,
                    group: col?.group,
                    label: col?.name,
                    icon: () => <CountryFlag languageCountryCode={col.group} />,
                    matches: matches?.map(i => i?.toLowerCase())
                };
            });
    }, [viewColumns, parentDpDcColIds]);

    const [selectedSource, setSelectedSource] = React.useState(null);
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});

    const childDpDcColIds = React.useMemo(() => {
        if (!selectedSource) return [];
        return dependenciesWithoutFakeIds
            ?.filter(dpDc => dpDc?.parent === selectedSource?.value)
            ?.map(dpDc => dpDc?.child);
    }, [dependenciesWithoutFakeIds, selectedSource]);

    const targetOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isChild = childDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isChild && columnType === columnTypes.TRANSLATION;
            })
            .map(col => {
                const matches = getFuzzyByLanguage({
                    langCode: col?.group,
                    langName: SUPPORTED_LANGUAGES?.find(lang => lang?.id === col?.group)?.name
                });

                return {
                    ...col,
                    value: col.id,
                    group: col.group,
                    label: `${col.name}`,
                    icon: () => <CountryFlag languageCountryCode={col.group} />,
                    matches: matches?.map(i => i?.toLowerCase())
                };
            });
    }, [viewColumns, childDpDcColIds]);

    const otherColumnsOptions = React.useMemo(() => {
        const targetOptionIds = targetOptions?.map(option => option?.value);

        const otherSources = !selectedSource
            ? []
            : sourceOptions?.filter(
                  option => option?.value !== selectedSource?.value && !targetOptionIds?.includes(option?.value)
              );

        const otherTargets = viewColumns
            ?.filter(viewCol => {
                const columnType = getCorrectColumnType(viewCol);
                return (
                    columnType !== columnTypes.TRANSLATION ||
                    (!childDpDcColIds?.includes(viewCol?.id) && !parentDpDcColIds?.includes(viewCol?.id))
                );
            })
            .map(col => ({
                ...col,
                value: col.id,
                label: col.name,
                icon: () => <ColumnIcon group={col?.group} type={col?.type} customProperties={col?.customProperties} />,
                options: null
            }));
        return [...otherTargets, ...otherSources];
    }, [viewColumns, selectedSource, childDpDcColIds, sourceOptions, targetOptions, parentDpDcColIds]);

    const recordIdOption = React.useMemo(() => {
        return otherColumnsOptions.find(column => column?.id === columnTypes.RECORD_ID);
    }, [otherColumnsOptions]);

    const [selectedContextKeyColumns, setSelectedContextKeyColumns] = React.useState(
        recordIdOption ? [recordIdOption] : []
    );

    const additionalInfoOption = React.useMemo(() => {
        return otherColumnsOptions.find(
            column =>
                column.name.toLowerCase() === ADDITIONAL_INFORMATION &&
                (column.type === columnTypes.SINGLE_LINE || column.type === columnTypes.MULTIPLE_LINES)
        );
    }, [otherColumnsOptions]);

    const [selectedContextNoteColumns, setSelectedContextNoteColumns] = React.useState(
        additionalInfoOption ? [additionalInfoOption] : []
    );

    const [selectedMaximumLengthColumn, setSelectedMaximumLengthColumn] = React.useState(
        otherColumnsOptions.find(
            column => column.name.toLowerCase() === CHARACTER_LIMIT && column.type === columnTypes.NUMBER
        )
    );

    const contextKeyColumnOptions = React.useMemo(() => {
        const contextNoteSelectedNames = selectedContextNoteColumns?.map(o => o?.name);

        let resultColumns = otherColumnsOptions.filter(column => {
            return (
                !contextNoteSelectedNames?.includes(column.name) &&
                column.name !== selectedMaximumLengthColumn?.name &&
                (column.type === columnTypes.SINGLE_LINE ||
                    column.type === columnTypes.MULTIPLE_LINES ||
                    column.type === columnTypes.RECORD_ID)
            );
        });
        if (selectedContextKeyColumns?.length) {
            resultColumns = [emptyOption, ...resultColumns];
        }
        return resultColumns;
    }, [otherColumnsOptions, selectedContextNoteColumns, selectedMaximumLengthColumn, selectedContextKeyColumns]);

    const contextNoteColumnOptions = React.useMemo(() => {
        const contextKeySelectedIds = selectedContextKeyColumns?.map(o => o?.id);

        let resultColumns = otherColumnsOptions.filter(column => {
            return (
                !contextKeySelectedIds?.includes(column?.id) &&
                column.name !== selectedMaximumLengthColumn?.name &&
                (column.type === columnTypes.SINGLE_LINE || column.type === columnTypes.MULTIPLE_LINES)
            );
        });
        if (selectedContextNoteColumns.length) {
            resultColumns = [emptyOption, ...resultColumns];
        }
        return resultColumns;
    }, [otherColumnsOptions, selectedContextKeyColumns, selectedMaximumLengthColumn, selectedContextNoteColumns]);

    const maximumLengthColumnOptions = React.useMemo(() => {
        const contextKeySelectedIds = selectedContextKeyColumns?.map(o => o?.id);
        const contextNoteSelectedNames = selectedContextNoteColumns?.map(o => o?.name);

        let resultColumns = otherColumnsOptions.filter(column => {
            return (
                !contextKeySelectedIds?.includes(column?.id) &&
                !contextNoteSelectedNames?.includes(column?.name) &&
                column.type === columnTypes.NUMBER
            );
        });
        if (selectedMaximumLengthColumn) {
            resultColumns = [emptyOption, ...resultColumns];
        }
        return resultColumns;
    }, [otherColumnsOptions, selectedContextKeyColumns, selectedContextNoteColumns, selectedMaximumLengthColumn]);

    const isAllTargetSelected = React.useMemo(() => {
        const values = Object.values(selectedTargetOptions);
        return values.every(el => !!el) && values.length === targetOptions.length;
    }, [targetOptions.length, selectedTargetOptions]);

    const handleSelectAllTargets = React.useCallback(
        checked => {
            if (checked) {
                let newSelectedTargetOptions = {};
                targetOptions.map(el => {
                    newSelectedTargetOptions[el.value] = true;
                    return false;
                });
                setSelectedTargetOptions(newSelectedTargetOptions);
                return;
            }
            setSelectedTargetOptions({});
        },
        [targetOptions]
    );

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions?.[targetValue] || false;

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    const validTargets = React.useMemo(() => {
        let validTargetCols = [];
        Object.keys(selectedTargetOptions).forEach(key => {
            if (selectedTargetOptions[key]) {
                validTargetCols = [
                    ...validTargetCols,
                    { key, id: targetOptions?.find(option => option.value === key)?.id }
                ];
            }
        });

        return validTargetCols;
    }, [selectedTargetOptions, targetOptions]);

    const isValid = React.useMemo(() => {
        const valid = !!selectedSource && validTargets.length && !isProjectNameExisted;
        return publishOption === MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT ? projectName && valid : valid;
    }, [selectedSource, validTargets, publishOption, projectName, isProjectNameExisted]);

    const handleSourceSelect = option => {
        setSelectedSource(option);
        setSelectedTargetOptions({});
    };

    const isSelectAllContextNote = React.useMemo(() => {
        return selectedContextNoteColumns?.length === contextNoteColumnOptions?.length;
    }, [contextKeyColumnOptions, selectedContextNoteColumns]);

    const isSelectAllContextKey = React.useMemo(() => {
        return selectedContextKeyColumns?.length === contextKeyColumnOptions?.length;
    }, [contextKeyColumnOptions, selectedContextKeyColumns]);

    const handleContextKeyChange = options => {
        const isSelectedOptionNone = options?.some(option => !option?.value);
        if (isSelectedOptionNone) {
            setSelectedContextKeyColumns([]);
            return;
        }
        setSelectedContextKeyColumns(options);
    };

    const handleCommentColumnChange = options => {
        const isSelectedOptionNone = options?.some(option => !option?.value);

        if (isSelectedOptionNone) {
            setSelectedContextNoteColumns([]);
            return;
        }
        setSelectedContextNoteColumns(options);
    };

    const handleMaximumLengthChange = option => {
        if (!option.value) {
            setSelectedMaximumLengthColumn(null);
            return;
        }
        setSelectedMaximumLengthColumn(option);
    };

    const handlePublishToProvider = React.useCallback(() => {
        const targetColumnIds = Object.keys(selectedTargetOptions)?.filter(columnId => {
            const isChecked = selectedTargetOptions?.[columnId];
            return isChecked;
        });

        let body = {
            provider: INTEGRATION_PROVIDER.MEMO_Q,
            columns: {
                [selectedSource?.id]: targetColumnIds
            },
            exportFormat: 'csv',
            memoQ: {
                contextKeyColumns: selectedContextKeyColumns?.map(column => column?.id),
                contextNoteColumns: selectedContextNoteColumns?.map(column => column?.id),
                maxLengthColumnId: selectedMaximumLengthColumn?.id,
                dependencyStatuses: includeUpToDate ? ['unset', 'outOfDate', 'upToDate'] : ['unset', 'outOfDate'],
                segmentSourceText: segment,
                translationFileName: nameFile
            }
        };

        if (publishOption === MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT) {
            body = {
                ...body,
                memoQ: {
                    ...body?.memoQ,
                    projectTemplateUid: selectedTemplate?.id,
                    projectName,
                    useUserImportFilter: !selectedTemplate ? false : useUserImportFilter === USER_IMPORT_FILTER.YES
                }
            };
        } else if (publishOption === MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_TRANSLATION_DOCUMENT) {
            body = {
                ...body,
                memoQ: {
                    ...body?.memoQ,
                    projectUid: selectedProject?.id,
                    documentGuid: selectedTranslationDocument?.value,
                    useUserImportFilter: useUserImportFilter === USER_IMPORT_FILTER.YES
                }
            };
        } else {
            body = {
                ...body,
                memoQ: {
                    ...body?.memoQ,
                    projectUid: selectedProject?.id,
                    useUserImportFilter: useUserImportFilter === USER_IMPORT_FILTER.YES
                }
            };
        }

        dispatch(
            workspaceActions.setUserSettings({
                dbId,
                workspaceId,
                resourceId: viewId,
                resourceType: RESOURCE_TYPES.VIEW,
                value: {
                    ...userViewSetting,
                    [USER_SETTINGS.VIEW.MEMO_Q]: {
                        publishOption,
                        segment,
                        includeUpToDate,
                        useUserImportFilter
                    }
                },
                successCallback: () => {
                    onClose();
                },
                errorCallback: () => {}
            })
        );

        sendManualTrack({ type: `Push to memoQ` });
        setIsSubmitting(true);
        dispatch(
            addonActions.publishSelectedColumnsToProvider({
                body,
                viewId: compositeViewId,
                successCallback: () => {
                    setIsSubmitting(false);
                    onClose();
                    console.log('publishSelectedColumnsToProvider success');
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    onClose();
                    console.log('publishSelectedColumnsToProvider failed');
                }
            })
        );
    }, [
        dbId,
        viewId,
        workspaceId,
        userViewSetting,
        compositeViewId,
        dispatch,
        onClose,
        projectName,
        publishOption,
        selectedContextKeyColumns,
        selectedContextNoteColumns,
        selectedMaximumLengthColumn,
        selectedProject,
        selectedSource,
        selectedTargetOptions,
        selectedTemplate,
        includeUpToDate,
        selectedTranslationDocument,
        segment,
        useUserImportFilter,
        nameFile
    ]);

    const runSetting = React.useCallback(
        option => {
            const targetLangs = option?.targetLanguageCodes;
            const sourceLang = option?.sourceLanguageCode;

            const source = sourceOptions?.find(option => option?.matches?.includes(sourceLang?.toLowerCase()));

            if (!source) {
                setSelectedSource(null);
                return;
            }

            setSelectedSource(source);
            //get depdencies
            let allDependencies = dependencies?.filter(dpDc => !isTempId(dpDc?.id));

            const childDpDcColIds = allDependencies
                ?.filter(dpDc => dpDc?.parent === source?.value)
                ?.map(dpDc => dpDc?.child);

            const targetOptions = viewColumns
                .filter(viewCol => {
                    const columnId = viewCol?.id;
                    const isChild = childDpDcColIds?.includes(columnId);
                    const columnType = getCorrectColumnType(viewCol);
                    return isChild && columnType === columnTypes.TRANSLATION;
                })
                .map(col => {
                    const matches = getFuzzyByLanguage({
                        langCode: col?.group,
                        langName: SUPPORTED_LANGUAGES?.find(lang => lang?.id === col?.group)?.name
                    });
                    return {
                        ...col,
                        value: col.id,
                        group: col.group,
                        label: `${col.name}`,
                        icon: () => <CountryFlag languageCountryCode={col.group} />,
                        matches: matches?.map(i => i?.toLowerCase())
                    };
                });

            let targetsSelected = {};
            for (const targetLang of targetLangs) {
                const targetFound = targetOptions?.find(option => option?.matches?.includes(targetLang?.toLowerCase()));
                if (targetFound) {
                    const targetValue = targetFound.value;
                    targetsSelected = {
                        ...targetsSelected,
                        [targetValue]: true
                    };
                }
            }

            setSelectedTargetOptions(targetsSelected);
        },
        [sourceOptions, viewColumns, dependencies]
    );

    const isNotMatchAnySource = React.useMemo(() => {
        const sourceLang = selectedProject?.sourceLanguageCode || selectedTemplate?.sourceLanguageCode;
        return !sourceLang
            ? false
            : !sourceOptions.some(option => option?.matches?.includes(sourceLang?.toLowerCase()));
    }, [selectedProject, sourceOptions, selectedTemplate]);

    const handlePublishOptionChange = React.useCallback(
        e => {
            const value = e.target?.value;

            if (value === MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT) {
                if (!selectedTemplate) {
                    setSelectedSource(getDefaultSource(sourceOptions));
                } else {
                    runSetting(selectedTemplate);
                }
            }

            if (value === MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_PROJECT) {
                if (!selectedProject) {
                    setSelectedSource(null);
                } else {
                    runSetting(selectedProject);
                }
            }

            if (value === MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_TRANSLATION_DOCUMENT) {
                if (!selectedTranslationDocument) {
                    setSelectedTranslationDocument(null);
                    setSelectedSource(null);
                    setSelectedTargetOptions({});
                } else {
                    runSetting(selectedTranslationDocument);
                }
            }

            setPublishOption(value);
        },
        [sourceOptions, selectedProject, selectedTemplate, runSetting, selectedTranslationDocument]
    );

    const handleProjectChange = React.useCallback(
        option => {
            if (publishOption === MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_PROJECT) {
                runSetting(option);
            } else {
                setSelectedTranslationDocument(null);
                setSelectedSource(null);
                setSelectedTargetOptions({});
            }

            setSelectedProject(option);
        },
        [runSetting, publishOption]
    );

    const handleTemplateChange = React.useCallback(
        option => {
            runSetting(option);
            setSelectedTemplate(option);
        },
        [runSetting]
    );

    const handleTranslationDocumentChange = React.useCallback(
        option => {
            runSetting(option);
            setSelectedTranslationDocument(option);
        },
        [runSetting]
    );

    React.useEffect(() => {
        const fetchDefaultTemplates = async () => {
            const serverBody = {
                offset: 0,
                limit: LIMIT_OPTIONS,
                name: ''
            };

            try {
                const templates = await getMemoQTemplatesApi({ id, query: serverBody });

                const options = templates?.map(template => ({
                    ...template,
                    label: template?.templateName,
                    value: template?.id
                }));
                setDefaultTemplateOptions(options);
            } catch (error) {
                setDefaultTemplateOptions([]);
            }
        };
        fetchDefaultTemplates();
    }, [id]);

    const severProjectFunction = React.useCallback(
        async inputValue => {
            const serverBody = {
                offset: 0,
                limit: LIMIT_OPTIONS,
                name: inputValue
            };

            const projects = await getMemoQProjectsApi({ id, query: serverBody });

            return projects?.map(project => ({
                ...project,
                label: project?.name,
                value: project?.id
            }));
        },
        [id]
    );

    const severTemplateFunction = React.useCallback(
        async inputValue => {
            const serverBody = {
                offset: 0,
                limit: LIMIT_OPTIONS,
                name: inputValue
            };

            const templates = await getMemoQTemplatesApi({ id, query: serverBody });

            return templates?.map(template => ({
                ...template,
                label: template?.templateName,
                value: template?.id
            }));
        },
        [id]
    );

    const severTranslationDocumentFunction = React.useCallback(
        async inputValue => {
            const serverBody = {
                offset: 0,
                limit: LIMIT_OPTIONS,
                name: inputValue,
                provider: INTEGRATION_PROVIDER.MEMO_Q,
                projectUid: selectedProject?.value
            };

            const templates = await getWorkflowSubmittedJobsApi({
                query: serverBody,
                viewId,
                id
            });

            return templates?.map(template => ({
                ...template,
                label: template?.title
                    ?.split('_')
                    ?.slice(1)
                    ?.join('_'),
                value: template?.externalId,
                sourceLanguageCode: template?.eventData?.sourceLanguage,
                targetLanguageCodes: template?.eventData?.targetLanguages
            }));
        },
        [id, viewId, selectedProject]
    );

    const handleChangeFileName = React.useCallback(e => {
        setNameFile(e.target.value);
    }, []);

    const isTargetDisabled = React.useMemo(() => {
        return MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_TRANSLATION_DOCUMENT === publishOption;
    }, [publishOption]);

    return (
        <div className="p-8 relative overflow-hidden max-w-[820px] min-w-[820px] rounded shadow bg-white">
            <div className="flex flex-col gap-4">
                <div className="relative flex items-center gap-2">
                    <div className="flex gap-2">
                        <MemoQSVG width={32} height={32} />
                        <h3 className="prose prose-2xl font-medium">{t(`memoq_push_button`)}</h3>
                    </div>
                    <IconButton onClick={onClose} className="!absolute !top-0 !right-0 !text-[#78778b4d]">
                        <CloseIcon size={20} />
                    </IconButton>
                </div>
                <div className="flex flex-col flex-nowrap gap-4 max-h-[calc(100vh-150px)] overflow-y-auto overflow-x-hidden">
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col flex-nowrap gap-2">
                            <div className="flex items-center">
                                <div className="number-step">1</div>
                                <p className="body1">{t(`memsource_publish_select_create_memoQ_project`)}</p>
                            </div>
                            <div className="flex flex-row items-center">
                                <RadioGroup
                                    aria-label="quiz"
                                    name="quiz"
                                    row
                                    value={publishOption}
                                    onChange={handlePublishOptionChange}
                                >
                                    <div className="flex flex-row flex-nowrap gap-4 items-center">
                                        <FormControlLabel
                                            value={MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_PROJECT}
                                            control={<Radio />}
                                            label={<p className="body2">{t('memoQ_publish_with_existing_project')}</p>}
                                        />

                                        <FormControlLabel
                                            value={MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT}
                                            control={<Radio />}
                                            label={<p className="body2">{t('memoQ_publish_new_project')}</p>}
                                        />

                                        <FormControlLabel
                                            value={MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_TRANSLATION_DOCUMENT}
                                            control={<Radio />}
                                            label={
                                                <p className="body2">
                                                    {t('memsource_publish_with_existing_translation_document')}
                                                </p>
                                            }
                                        />
                                    </div>
                                </RadioGroup>
                            </div>
                            <div className="flex gap-3 items-center">
                                {[
                                    MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_PROJECT,
                                    MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_TRANSLATION_DOCUMENT
                                ].includes(publishOption) && (
                                    <>
                                        <LDBasePortalServer
                                            ddPlaceholder={t(`global_select_project`)}
                                            menuPlaceholder={t(`global_label_find_a_project`)}
                                            defaultValue={selectedProject}
                                            isMulti={false}
                                            onChange={handleProjectChange}
                                            serverFunction={severProjectFunction}
                                            defaultOptions={defaultProjectOptions}
                                            dropdownClassName="w-1/3"
                                        />
                                        {MEMBER_PUBLISH_OPTIONS.SELECT_EXISTING_TRANSLATION_DOCUMENT ===
                                            publishOption && (
                                            <LDBasePortalServer
                                                ddPlaceholder={t(`memoQ_select_translation_document`)}
                                                menuPlaceholder={t(`memoQ_find_translation_document`)}
                                                defaultValue={selectedTranslationDocument}
                                                isMulti={false}
                                                onChange={handleTranslationDocumentChange}
                                                serverFunction={severTranslationDocumentFunction}
                                                isDisabled={!selectedProject}
                                                dropdownClassName="w-1/3"
                                            />
                                        )}
                                    </>
                                )}
                                {publishOption === MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT && (
                                    <>
                                        <LDBasePortalServer
                                            ddPlaceholder={t('global_select_template')}
                                            menuPlaceholder={t(`global_label_find_a_template`)}
                                            defaultValue={selectedTemplate}
                                            isMulti={false}
                                            onChange={handleTemplateChange}
                                            serverFunction={severTemplateFunction}
                                            defaultOptions={defaultTemplateOptions}
                                            dropdownClassName="w-1/3"
                                        />

                                        <InputText
                                            name="projectName"
                                            type="text"
                                            value={projectName}
                                            height={36}
                                            disabled={isSubmitting}
                                            onChange={e => setProjectName(e?.target?.value)}
                                            placeholder={'Project name'}
                                            autoComplete={'on'}
                                            error={isProjectNameExisted}
                                            errorText="Project name existed"
                                            className="!w-1/3"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="w-1/3">
                            <p className="body2 mb-3">File name</p>
                            <InputText name="name" onChange={handleChangeFileName} placeholder="Type a file name" />
                        </div>
                        <div className="flex flex-col flex-nowrap gap-2">
                            <div className="flex items-center">
                                <div className="number-step">2</div>
                                <p className="body1">{t(`memsource_publish_select_source_language`)}</p>
                            </div>
                            <div className="relative -left-3 flex flex-row items-center gap-1">
                                <Checkbox checked={segment} onChange={e => setSegment(e.target.checked)} />
                                <p className="body2">{t(`segment_source_data`)}</p>
                                <Tooltip
                                    title={
                                        <div className="max-w-[355px] p-2">
                                            <p className="body1 text-white">
                                                When Gridly pushs data, it splits the data into segments based on the
                                                segmentation rule in memoQ.
                                            </p>
                                        </div>
                                    }
                                >
                                    <div className="flex">
                                        <QuestionSVG />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="flex items-center">
                                {sourceOptions?.map(option => {
                                    const isSelected = selectedSource?.value === option?.value;
                                    const isMatchSourceLang =
                                        (selectedProject &&
                                            option?.matches?.includes(
                                                selectedProject?.sourceLanguageCode?.toLowerCase()
                                            )) ||
                                        (selectedTemplate &&
                                            option?.matches?.includes(
                                                selectedTemplate?.sourceLanguageCode?.toLowerCase()
                                            )) ||
                                        (selectedTranslationDocument &&
                                            option?.matches?.includes(
                                                selectedTranslationDocument?.sourceLanguageCode?.toLowerCase()
                                            ));
                                    const isCanSelect =
                                        publishOption === MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT
                                            ? true
                                            : isSourceDisabled && isMatchSourceLang;

                                    return (
                                        <div
                                            className="w-1/3"
                                            style={{ opacity: selectedSource && !isSelected ? 0.6 : `` }}
                                            key={option.value}
                                        >
                                            <ListSelect
                                                disabled={!isCanSelect}
                                                onClick={() => handleSourceSelect(option)}
                                                disabledTitle={
                                                    !isCanSelect ? (
                                                        <p className="body2 max-w-[300px]">
                                                            {t(`memoQ_does_not_match_with_setting`)}
                                                        </p>
                                                    ) : (
                                                        undefined
                                                    )
                                                }
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={<ColumnTypeSelectListDisplay {...option} />}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mt-6">
                            <div className="flex flex-col flex-nowrap gap-2">
                                <div className="grid grid-cols-2 items-center">
                                    <div className="flex items-center">
                                        <div className="number-step">3</div>
                                        <p className="prose prose-sm font-medium">
                                            {t(`memsource_publish_select_target_languages`)}
                                        </p>
                                    </div>
                                    {(targetOptions.length || selectedSource) && (
                                        <div className="flex justify-end">
                                            <div className="flex flex-row items-center justify-end flex-nowrap">
                                                <div className="flex flex-row items-center whitespace-nowrap justify-end">
                                                    <Checkbox
                                                        checked={isAllTargetSelected}
                                                        onChange={e => handleSelectAllTargets(e.target?.checked)}
                                                    />
                                                    <p className="prose prose-sm inline whitespace-nowrap">
                                                        Select all languages
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="min-h-[46px] max-h-[300px] overflow-y-auto overflow-x-hidden">
                                    {targetOptions.length === 0 && (
                                        <p className="body2">{t(`export_localization_explain`)}</p>
                                    )}
                                    <Collapse in={targetOptions.length > 0}>
                                        <div className="grid grid-cols-3 gap-2 items-center">
                                            {targetOptions?.map(option => {
                                                const isSelected = selectedTargetOptions?.[option?.value];
                                                return (
                                                    <div key={option.value}>
                                                        <ListSelect
                                                            disabled={isTargetDisabled}
                                                            onClick={() => handleTargetSelect(option)}
                                                            isSelected={isSelected}
                                                            icon={option?.icon}
                                                            name={<ColumnTypeSelectListDisplay {...option} />}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    {otherColumnsOptions?.length !== 0 && (
                        <div className="flex flex-col gap-3">
                            <div className="flex items-center">
                                <div className="number-step">4</div>
                                <p className="body1">{t(`memsource_publish_select_others`)}</p>
                            </div>
                            <div className="relative -left-3 flex flex-row items-center gap-1">
                                <Checkbox
                                    checked={includeUpToDate}
                                    onChange={e => setIncludeUpToDate(e.target.checked)}
                                />
                                <p className="body2">Push also</p>
                                <div className="relative top-[1px]">
                                    <DependencyStatusGuideItem color={theme.colors.atlantis} label={'Up-to-date'} />
                                </div>
                                <p className="body2">records</p>
                                <Tooltip
                                    title={
                                        <div className="flex gap-2 flex-col max-w-[300px] p-2">
                                            <div>
                                                <p className="caption inline-block">
                                                    <DependencyStatusGuideItem
                                                        color={theme.colors.sun}
                                                        label={'Out-of-date'}
                                                        labelColor={theme.colors.white}
                                                    />
                                                </p>{' '}
                                                <p className="caption inline">and</p>{' '}
                                                <p className="caption inline-block">
                                                    <DependencyStatusGuideItem
                                                        color={theme.colors.brightRed}
                                                        label={'Unset'}
                                                        labelColor={theme.colors.white}
                                                    />
                                                </p>{' '}
                                                <p className="caption inline">are pushed to memoQ by default.</p>
                                            </div>
                                            <div>
                                                <p className="caption inline">
                                                    Select this checkbox to add also records with the
                                                </p>{' '}
                                                <p className="caption inline-block">
                                                    <DependencyStatusGuideItem
                                                        color={theme.colors.atlantis}
                                                        label={'Up-to-date'}
                                                        labelColor={theme.colors.white}
                                                    />
                                                </p>{' '}
                                                <p className="caption inline">status.</p>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="flex">
                                        <QuestionSVG />
                                    </div>
                                </Tooltip>
                            </div>

                            {!(
                                [MEMBER_PUBLISH_OPTIONS.CREATE_NEW_PROJECT].includes(publishOption) && !selectedTemplate
                            ) && (
                                <div className="flex flex-col gap-2">
                                    <p className="body1">Does your project template has import filter already?</p>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={useUserImportFilter}
                                            onChange={e => setUseUserImportFilter(e?.target?.value)}
                                        >
                                            <div className="flex flex-col">
                                                <FormControlLabel
                                                    value={USER_IMPORT_FILTER.FALSE}
                                                    control={<Radio size="small" />}
                                                    label={<p className="body2">No, Create new one for me</p>}
                                                />
                                                <FormControlLabel
                                                    value={USER_IMPORT_FILTER.YES}
                                                    control={<Radio size="small" />}
                                                    label={
                                                        <div className="flex flex-col">
                                                            <p className="body2">Yes, Use my filter</p>
                                                            <p className="caption">
                                                                (Please note that Gridly sends translation files in csv
                                                                format and comma as the separator, please make sure that
                                                                your filter is configured probably)
                                                            </p>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )}
                            <div className="flex gap-2">
                                <div className="flex flex-col w-1/3">
                                    <div className="flex items-center gap-2">
                                        <MemoQSVG color={theme.colors.disabledGrey} width={16} height={16} />
                                        <p className="body2">{t(`memsource_publish_context_key`)}</p>
                                    </div>
                                    <LDBasePortal
                                        ddPlaceholder={t(`global_label_select_a_column`)}
                                        menuPlaceholder={t(`global_label_find_a_column`)}
                                        options={contextKeyColumnOptions}
                                        defaultValue={selectedContextKeyColumns}
                                        isMulti={true}
                                        onChange={options => handleContextKeyChange(options)}
                                        isAll={contextKeyColumnOptions?.length > 1}
                                        onSelectAll={setOpenState => {
                                            setOpenState(false);
                                            if (isSelectAllContextKey) {
                                                setSelectedContextKeyColumns([]);
                                            } else {
                                                setSelectedContextKeyColumns(contextKeyColumnOptions);
                                            }
                                        }}
                                        selectAllText={isSelectAllContextKey ? 'Unselect all' : 'Select all'}
                                    />
                                </div>
                                <div className="flex flex-col w-1/3">
                                    <div className="flex items-center gap-2">
                                        <MemoQSVG color={theme.colors.disabledGrey} width={16} height={16} />
                                        <p className="body2">{t(`memoQ_publish_comment_column`)}</p>
                                    </div>
                                    <LDBasePortal
                                        ddPlaceholder={t(`global_label_select_a_column`)}
                                        menuPlaceholder={t(`global_label_find_a_column`)}
                                        options={contextNoteColumnOptions}
                                        defaultValue={selectedContextNoteColumns}
                                        isMulti={true}
                                        onChange={options => handleCommentColumnChange(options)}
                                        isAll={contextNoteColumnOptions?.length > 1}
                                        onSelectAll={setOpenState => {
                                            setOpenState(false);
                                            if (isSelectAllContextNote) {
                                                setSelectedContextNoteColumns([]);
                                            } else {
                                                setSelectedContextNoteColumns(contextNoteColumnOptions);
                                            }
                                        }}
                                        selectAllText={isSelectAllContextNote ? 'Unselect all' : 'Select all'}
                                    />
                                </div>
                                <div className="flex flex-col w-1/3">
                                    <div className="flex items-center gap-2">
                                        <MemoQSVG color={theme.colors.disabledGrey} width={16} height={16} />
                                        <p className="body2">{t(`memsource_publish_maximum_length`)}</p>
                                    </div>
                                    <LDBasePortal
                                        ddPlaceholder={t(`global_label_select_a_column`)}
                                        menuPlaceholder={t(`global_label_find_a_column`)}
                                        options={maximumLengthColumnOptions}
                                        defaultValue={selectedMaximumLengthColumn}
                                        isMulti={false}
                                        onChange={option => handleMaximumLengthChange(option)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className="flex flex-row items-center mt-3 pt-3 border-t"
                    style={{ justifyContent: isNotMatchAnySource ? 'space-between' : 'flex-end' }}
                >
                    {isNotMatchAnySource && (
                        <div className="flex items-center flex-row gap-2">
                            <InfoIconSVG color={theme.colors.sun} />
                            <p className="body2">No source language match selected project</p>
                        </div>
                    )}
                    <div className="flex flex-row flex-nowrap gap-2">
                        <ButtonBase size="small" variant="outlined" width={120} onClick={onClose}>
                            {t(`global_cancel`)}
                        </ButtonBase>
                        <ButtonBase
                            size="small"
                            width={120}
                            disabled={!isValid || isSubmitting}
                            variant="contained"
                            onClick={handlePublishToProvider}
                        >
                            {isSubmitting ? (
                                <CircularProgress
                                    style={{
                                        color: 'white'
                                    }}
                                    size={20}
                                />
                            ) : (
                                t(`global_push`)
                            )}
                        </ButtonBase>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ChooseColumns);
