import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_API_KEYS](state, { payload }) {
        const apiKeys = state?.apiKeys || {};
        const newApiKeys = produce(apiKeys, draft => {
            draft.isFetching = true;
        });
        return {
            ...state,
            apiKeys: newApiKeys
        };
    },
    [types.FETCH_API_KEYS_FAILED](state, { payload }) {
        const { error } = payload;
        const apiKeys = state?.apiKeys || {};
        const newApiKeys = produce(apiKeys, draft => {
            draft.isFetching = false;
            draft.error = error;
        });
        return {
            ...state,
            apiKeys: newApiKeys
        };
    },
    [types.FETCH_API_KEYS_SUCCESS](state, { payload }) {
        const { apiKeys } = payload;
        const apiKeyStore = state?.apiKeys || {};
        const newApiKeys = produce(apiKeyStore, draft => {
            draft.isFetching = false;
            draft.list = apiKeys;
        });
        return {
            ...state,
            apiKeys: newApiKeys
        };
    },
    [types.CREATE_API_KEY_SUCCESS](state, { payload }) {
        const { apiKey } = payload;
        const apiKeyList = state?.apiKeys?.list || [];

        const newList = [apiKey, ...apiKeyList];
        return {
            ...state,
            apiKeys: {
                ...state.apiKeys,
                isCreating: false,
                list: newList
            }
        };
    },
    [types.UPDATE_API_KEY_SUCCESS](state, { payload }) {
        const { apiKey, apiKeyId } = payload;
        const apiKeyList = state?.apiKeys?.list || [];

        const newList = apiKeyList?.map(key => {
            if (key?.value === apiKeyId) {
                return apiKey;
            }
            return key;
        });

        return {
            ...state,
            apiKeys: {
                ...state.apiKeys,
                isUpdating: false,
                list: newList
            }
        };
    },
    [types.DELETE_API_KEY](state, { payload }) {
        const { apiKeyId } = payload;
        const apiKeyList = state?.apiKeys?.list || [];

        const newList = apiKeyList?.map(key => {
            if (key?.value === apiKeyId) {
                key.isDeleted = true;
            }
            return key;
        });

        return {
            ...state,
            apiKeys: {
                ...state.apiKeys,
                isDeleting: false,
                list: newList
            }
        };
    },
    [types.DELETE_API_KEY_FAILED](state, { payload }) {
        const { apiKeyId } = payload;
        const apiKeyList = state?.apiKeys?.list || [];

        const newList = apiKeyList?.map(key => {
            if (key?.value === apiKeyId) {
                key.isDeleted = false;
            }
            return key;
        });

        return {
            ...state,
            apiKeys: {
                ...state.apiKeys,
                isDeleting: false,
                list: newList
            }
        };
    },
    [types.DELETE_API_KEY_SUCCESS](state, { payload }) {
        const { apiKeyId } = payload;
        const apiKeyList = state?.apiKeys?.list || [];

        const newList = apiKeyList?.filter(key => key?.value !== apiKeyId);

        return {
            ...state,
            apiKeys: {
                ...state.apiKeys,
                isUpdating: false,
                list: newList
            }
        };
    },
    [types.FETCH_API_KEY_DETAIL](state, { payload }) {
        const apiKeys = state?.apiKeys || {};
        const newApiKeys = produce(apiKeys, draft => {
            draft.isFetchingDetail = true;
        });
        return {
            ...state,
            apiKeys: newApiKeys
        };
    },
    [types.FETCH_API_KEY_DETAIL_FAILED](state, { payload }) {
        const { error } = payload;
        const apiKeys = state?.apiKeys || {};
        const newApiKeys = produce(apiKeys, draft => {
            draft.isFetchingDetail = false;
            draft.error = error;
        });
        return {
            ...state,
            apiKeys: newApiKeys
        };
    },
    [types.FETCH_API_KEY_DETAIL_SUCCESS](state, { payload }) {
        const { apiKey, apiKeyId } = payload;
        const apiKeyList = state?.apiKeys?.list || [];

        const newList = apiKeyList?.map(key => {
            if (key?.value === apiKeyId) {
                return apiKey;
            }
            return key;
        });

        return {
            ...state,
            apiKeys: {
                ...state.apiKeys,
                isFetchingDetail: false,
                list: newList
            }
        };
    }
};

export default handler;
