import * as types from '../types';
import { generateWsAccessId } from 'utils/permission/workspaceAccess';
import produce from 'immer';

const handler = {
    [types.FETCH_GROUPS](state) {
        return {
            ...state,
            groups: {
                ...state.groups,
                isFetchingGroups: true
            }
        };
    },
    [types.FETCH_GROUPS_SUCCESS](state, action) {
        const { groups } = action.payload;
        return {
            ...state,
            groups: {
                ...state.groups,
                isFetchingGroups: false,
                list: groups
            }
        };
    },
    [types.CREATE_GROUP](state) {
        return {
            ...state,
            groups: {
                ...state.groups,
                isCreatingGroup: true
            }
        };
    },
    [types.CREATE_GROUP_SUCCESS](state, action) {
        const { group } = action.payload;
        const listGroup = state?.groups?.list;
        return {
            ...state,
            groups: {
                ...state.groups,
                isCreatingGroup: false,
                list: [...listGroup, group]
            }
        };
    },
    [types.CREATE_GROUP_FAILED](state) {
        return {
            ...state,
            groups: {
                ...state.groups,
                isCreatingGroup: false
            }
        };
    },
    [types.DELETE_GROUP](state, action) {
        const { groupId } = action.payload;
        const { groups } = state;
        const listGroup = groups?.list || [];
        const newListGroup = listGroup.map(group => {
            if (group.id === groupId) {
                group.isDeleted = true;
            }
            return group;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                list: newListGroup
            }
        };
    },

    [types.DELETE_GROUP_FAILED](state, action) {
        const { groupId } = action.payload;
        const { groups } = state;
        const listGroup = groups?.list || [];
        const newListGroup = listGroup.map(group => {
            if (group.id === groupId) {
                group.isDeleted = false;
            }
            return group;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                list: newListGroup
            }
        };
    },
    [types.DELETE_GROUP_SUCCESS](state, action) {
        const { groupId } = action.payload;
        const { groups } = state;
        const listGroup = groups?.list || [];
        const newListGroup = listGroup.filter(group => group?.id !== groupId);
        return {
            ...state,
            groups: {
                ...state.groups,
                list: newListGroup
            }
        };
    },
    [types.UPLOAD_AVATAR_GROUP_SUCCESS](state, action) {
        const { newGroup } = action.payload;
        const { groups } = state;
        const listGroup = groups?.list || [];
        const newListGroup = listGroup.map(i => {
            if (i.id === newGroup.id) {
                const newItem = { ...i };
                newItem.logoUrl = newGroup.logoUrl;

                return newItem;
            }
            return i;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                list: newListGroup
            }
        };
    },
    [types.UPDATE_GROUP](state, action) {
        const { group } = action.payload;
        const { groups } = state;
        const listGroup = groups?.list || [];
        const newListGroup = listGroup.map(i => {
            if (i.id === group.id) {
                const newItem = { ...i };
                newItem.name = group.name;

                return newItem;
            }
            return i;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                list: newListGroup
            }
        };
    },
    [types.UPDATE_GROUP_FAILED](state, action) {
        const { group } = action.payload;
        const { groups } = state;
        const listGroup = groups?.list || [];
        const newListGroup = listGroup.map(i => {
            if (i.id === group?.id) {
                i.name = group?.name;
            }
            return i;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                list: newListGroup
            }
        };
    },
    [types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_GROUP](state) {
        return {
            ...state,
            groups: {
                ...state.groups,
                isShowLastPopupCreate: null
            }
        };
    },
    [types.TURN_ON_SHOW_LAST_POP_UP_CREATE_GROUP](state, action) {
        const { groupId } = action.payload;
        return {
            ...state,
            groups: {
                ...state.groups,
                isShowLastPopupCreate: groupId
            }
        };
    },
    [types.GRANT_GROUP_TO_WORKSPACE_SUCCESS](state, action) {
        const { groupId, newWorkspace } = action.payload;
        const { groups } = state;
        const list = groups?.list || [];
        const newList = list.map(item => {
            if (item.id === groupId) {
                const newItem = { ...item };
                if (newItem.workspaces) {
                    newItem.workspaces.push(newWorkspace);
                } else {
                    newItem.workspaces = [newWorkspace];
                }
                return newItem;
            }

            return item;
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                list: newList
            }
        };
    },
    [types.GRANT_GROUP_TO_WORKSPACE_FAILED](state, action) {
        const { groupId, workspaceId } = action.payload;
        const { groups } = state;
        const list = groups?.list || [];

        const newList = list.map(item => {
            if (item.id === groupId) {
                const newItem = { ...item };
                if (newItem.workspaces) {
                    const newWorkspaces = item.workspaces.filter(workspace => workspace.id !== workspaceId);
                    newItem.workspaces = newWorkspaces;
                }
                return newItem;
            }

            return item;
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                list: newList
            }
        };
    },
    [types.UNGRANT_GROUP_TO_WORKSPACE_SUCCESS](state, action) {
        const { groupIds, workspaceId } = action.payload;
        const { groups } = state;
        const list = groups?.list || [];

        const newList = list.map(item => {
            if (groupIds?.includes(item.id)) {
                const newItem = { ...item };
                if (newItem.workspaces) {
                    const newWorkspaces = item.workspaces.filter(workspace => workspace.id !== workspaceId);
                    newItem.workspaces = newWorkspaces;
                }
                return newItem;
            }

            return item;
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                list: newList
            }
        };
    },
    [types.UNGRANT_GROUP_TO_WORKSPACE_FAILED](state, action) {
        const { groupIds, workspace } = action.payload;
        const { groups } = state;
        const list = groups?.list || [];
        const newList = list.map(item => {
            if (groupIds?.includes(item.id)) {
                const newItem = { ...item };
                if (newItem.workspaces) {
                    newItem.workspaces.push(workspace);
                } else {
                    newItem.workspaces = [workspace];
                }
                return newItem;
            }

            return item;
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                list: newList
            }
        };
    },
    [types.FETCH_GROUP_WORKSPACE_ACCESS](state, { payload }) {
        const { groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            draft[groupId].isFetchingGroupWorkspaceAccess = true;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.FETCH_GROUP_WORKSPACE_ACCESS_SUCCESS](state, { payload }) {
        const { workspacesAccess, groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            draft[groupId].workspacesAccess = workspacesAccess;
            draft[groupId].isFetchingGroupWorkspaceAccess = false;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.FETCH_GROUP_WORKSPACE_ACCESS_FAILED](state, { payload }) {
        const { error, groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            draft[groupId].error = error;
            draft[groupId].isFetchingGroupWorkspaceAccess = false;
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.FETCH_GROUP_MEMBERS](state, { payload }) {
        const { groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            draft[groupId].isFetchingMembers = true;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.FETCH_GROUP_MEMBERS_SUCCESS](state, { payload }) {
        const { members, groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            draft[groupId].isFetchingMembers = false;
            draft[groupId].members = members;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.FETCH_GROUP_MEMBERS_FAILED](state, { payload }) {
        const { error, groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            draft[groupId].isFetchingMembers = false;
            draft[groupId].error = error;
        });
        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.ADD_USER_TO_GROUP](state, action) {
        const { groupId, user } = action.payload;

        const members = state.groups.members || {};
        const groupMember = members[groupId] || {};
        const newList = groupMember.list || [];

        return {
            ...state,
            groups: {
                ...state.groups,
                members: {
                    ...members,
                    [groupId]: {
                        ...groupMember,
                        list: [...newList, ...[user]]
                    }
                }
            }
        };
    },
    [types.REMOVE_USER_OUT_OF_GROUP](state, action) {
        const { userId, groupId } = action.payload;

        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            const prevMembers = draft[groupId].members || [];

            draft[groupId].members = prevMembers?.map(member => {
                if (userId === member?.id) {
                    return {
                        ...member,
                        isDeleted: true
                    };
                }
                return member;
            });
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.REMOVE_USER_OUT_OF_GROUP_FAILED](state, action) {
        const { userId, groupId } = action.payload;

        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            const prevMembers = draft[groupId].members || [];

            draft[groupId].members = prevMembers?.map(member => {
                if (userId === member?.id) {
                    return {
                        ...member,
                        isDeleted: false
                    };
                }
                return member;
            });
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.REMOVE_USER_OUT_OF_GROUP_SUCCESS](state, action) {
        const { userId, groupId } = action.payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            const prevMembers = draft[groupId].members || [];
            draft[groupId].members = prevMembers?.filter(member => userId !== member?.id);
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.ASSIGN_USER_TO_GROUP_SUCCESS](state, action) {
        const { groupId } = action.payload;

        const members = state.groups.members || {};
        const groupMember = members[groupId] || {};

        return {
            ...state,
            groups: {
                ...state.groups,
                members: {
                    ...members,
                    [groupId]: {
                        ...groupMember,
                        isFetching: false,
                        list: null
                    }
                }
            }
        };
    },
    [types.CHANGE_GROUP](state, { payload }) {
        const { groupId } = payload;

        return {
            ...state,
            groups: {
                ...state.groups,
                selectedGroupId: groupId
            }
        };
    },
    [types.FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS](state, { payload }) {
        return {
            ...state,
            groups: {
                ...state.groups,
                isFetchingMembersAndWorkspaceAccess: true
            }
        };
    },
    [types.FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            groups: {
                ...state.groups,
                isFetchingMembersAndWorkspaceAccess: false,
                error
            }
        };
    },
    [types.FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS_SUCCESS](state, { payload }) {
        const { members, workspacesAccess } = payload;
        return {
            ...state,
            groups: {
                ...state.groups,
                isFetchingMembersAndWorkspaceAccess: false,
                members,
                workspacesAccess
            }
        };
    },
    [types.INVITE_USERS_TO_GROUP_SUCCESS](state, { payload }) {
        const { members, groupId } = payload;
        const { groups } = state;
        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }
            const prevMembers = draft[groupId].members || [];
            const prevMemberIds = prevMembers.map(member => member.id);
            const filteredMembers = members.filter(member => !prevMemberIds.includes(member.id));
            draft[groupId].members = [...prevMembers, ...filteredMembers];
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.UPDATE_WORKSPACE_ACCESSS](state, { payload }) {
        const { addViews, removeViews, groupId } = payload;
        const { groups } = state;

        const { groupMemberAccess } = groups;

        const newGroupMemberAccess = produce(groupMemberAccess, draft => {
            if (!draft[groupId]) {
                draft[groupId] = {};
            }

            const workspacesAccess = draft[groupId]?.workspacesAccess;

            const workspacesAccessFormatted =
                workspacesAccess?.accessViews?.map(dbAccess => ({
                    ...dbAccess,
                    id: generateWsAccessId(dbAccess)
                })) || [];

            const removeViewIds = removeViews.map(removeView => removeView.id);
            const newWorkspacesAccessFormatted = workspacesAccessFormatted.filter(
                dbAccess => !removeViewIds.includes(dbAccess?.id)
            );

            const newWorkspacesAccess = [...newWorkspacesAccessFormatted, ...addViews];
            draft[groupId].workspacesAccess = newWorkspacesAccess;
        });

        return {
            ...state,
            groups: {
                ...state.groups,
                groupMemberAccess: newGroupMemberAccess
            }
        };
    },
    [types.SET_SELECTED_GROUPS](state, { payload }) {
        const { groups } = payload;

        return {
            ...state,
            groups: {
                ...state.groups,
                selectedGroups: groups
            }
        };
    }
};

export default handler;
