import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import CircularProgress from 'components/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '7px 24px !important',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    flex: {
        display: 'flex'
    },
    linking: {
        width: 4,
        height: 1,
        backgroundColor: theme.colors.silver
    },
    icons: {
        flex: 0
    },
    iconWrapper: {
        width: 32,
        height: 32,
        background: theme.colors.white,
        borderRadius: 4,
        border: `1px solid ${theme.colors.silver}`
    },
    info: {
        marginLeft: 10
    }
}));

const AutomationSuggestedItem = ({ id, title, description, events = [], onClick, isAddingSuggestionId }) => {
    const classes = useStyles();

    return (
        <Grid className={classes.root} item container wrap="nowrap" alignItems="flex-start" onClick={onClick}>
            <Grid item container alignItems="center" wrap="nowrap" className={classes.icons}>
                <Grid item>
                    <WebHookLabel type={events[0]} />
                </Grid>
                <Grid item className={classes.linking} />
                <Grid item>
                    <WebHookLabel type={events[1]} />
                </Grid>
            </Grid>
            <Grid item container direction="column" wrap="nowrap" className={classes.info}>
                <Grid item>
                    <p className="body1">{title}</p>
                </Grid>
                <Grid item>
                    <p className="caption">{description}</p>
                </Grid>
            </Grid>
            {isAddingSuggestionId === id && <CircularProgress />}
        </Grid>
    );
};

export default React.memo(AutomationSuggestedItem);
