import React from 'react';
import { Avatar } from '@material-ui/core';
import Plus from 'assets/images/svg/diff/Plus';
import Minus from 'assets/images/svg/diff/Minus';
import { isFileAudio, isFileImage, isFileVideo, getFilePreview, isFilePdf } from 'utils/images';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';
import classNames from 'classnames';

const defaultState = {
    UPDATE: 'update',
    DELETE: 'delete',
    NORMAL: 'normal'
};

function ImageDiffCheck({ state = defaultState.NORMAL, fileMeta, width, height, isHideMarkIcon, dbId }) {
    if (!fileMeta) return null;

    let isImage = isFileImage(fileMeta);
    let isAudio = isFileAudio(fileMeta);
    let isVideo = isFileVideo(fileMeta);
    let isPdf = isFilePdf(fileMeta);

    let url = getFilePreview({ dbId, fileId: fileMeta?.thumbnailId });

    return (
        <div
            className={classNames('inline-flex w-fit relative p-[1px] rounded ', {
                'border-[2px]': state !== defaultState.NORMAL,
                'border-brightRed': state === defaultState.DELETE,
                'border-shamRock': state === defaultState.UPDATE
            })}
        >
            <Avatar
                style={{
                    height: height,
                    width: width,
                    borderRadius: 4,
                    background: !isImage ? (isAudio ? '#EEF5FB' : isVideo ? '#FDF0ED' : '#F3F2F9') : ''
                }}
                src={url}
                alt={fileMeta?.id}
            >
                {!isImage &&
                    (isAudio ? (
                        <AudioThumbnailSVG style={{ width, height }} />
                    ) : isVideo ? (
                        <VideoThumbnailSVG style={{ width, height }} />
                    ) : isPdf ? (
                        <PDFFILESVG style={{ width, height }} />
                    ) : (
                        <OtherFileTypeThumbnailSVG style={{ width, height }} />
                    ))}
            </Avatar>
            {state === defaultState.UPDATE && (
                <span className={'absolute -top-1 -right-1 flex'}>{!isHideMarkIcon && <Plus />}</span>
            )}
            {state === defaultState.DELETE && (
                <span className={'absolute -top-1 -right-1 flex'}>{!isHideMarkIcon && <Minus />}</span>
            )}
        </div>
    );
}

export default React.memo(ImageDiffCheck);
