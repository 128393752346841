import * as types from '../types';

export function registerHoldEvent(event) {
    return {
        type: types.REGISTER_HOLD_EVENT,
        payload: {
            event
        }
    };
}
