import store from 'store/configStore';
import * as socketTypes from 'socket/types';
import * as databaseActions from 'databases/actions';
import * as gridActions from 'grids/actions';
import { DATABASE_STATUS } from 'const';
import { getGridsApi } from 'services/grid';

const { dispatch } = store;

const duplicateHandler = ({ body, isDatabaseDetail }) => {
    const { subAction, workspaceId, dbId } = body;
    switch (subAction) {
        case socketTypes.DUPLICATE_DATABASE_END:
            return _duplicateDatabaseEndHandler({ dbId, workspaceId });
        default:
            return;
    }
};

async function _duplicateDatabaseEndHandler({ dbId, workspaceId }) {
    const grids = await getGridsApi({ dbId });
    dispatch(gridActions.fetchGridByDatabaseSuccess({ dbId, list: grids }));
    dispatch(databaseActions.setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE }));
}

export default duplicateHandler;
