import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { formatDateFromNow } from 'utils/datetime';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import PopperMenu from 'components/menus/Popper';
import MenuOptionBase from 'components/menuOption/Base';
import EditSVG from 'assets/images/svg/EditSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { useDispatch } from 'react-redux';
import { deleteCommentHandler, updateCommentHandler } from 'gridUI/actions';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { checkContainId } from 'utils/clickAway';
import { scrollInToView } from 'utils/scroll';
import CommentEmojiIconSVG from 'assets/images/svg/CommentEmojiIconSVG';
import EmojiBox from 'components/cellComment/Emoji';
import { isKbEnter, isKbEscape, isKbShiftEnter } from 'utils/keyboard';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import { useCurrentUserFullInfo } from 'hooks/auth';
import EditorMention from './EditorMention';

const useStyles = makeStyles(theme => ({
    root: {},
    expand: {
        flex: 1,
        marginTop: props => (!props.isOpenEdit ? 5 : 0),
        position: 'relative'
    },
    description: {
        marginTop: 12,
        wordBreak: 'break-word'
    },
    editBox: {
        border: '1px solid rgb(233, 234, 239)',
        padding: '0px 14px',
        borderRadius: '4px',
        background: 'rgb(255, 255, 255)',
        position: 'relative'
    },
    content: {
        fontSize: 14,
        padding: 5,
        paddingBottom: 12,
        color: theme.colors.dimGrey,
        lineHeight: `22px`,
        width: '100%',
        resize: `none`,
        border: `none`,
        background: `transparent`,
        '&:focus': {
            outline: 'none'
        }
    },
    emoji: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 5,
        right: 5
    },
    emojiPopup: {
        '& .emoji-mart': {
            border: 'none'
        }
    },
    mentionClassName: {
        backgroundColor: '#ffffff !important'
    }
}));

const cursorDefault = {
    anchor: {
        offset: 0,
        path: [0, 0]
    },
    focus: {
        offset: 0,
        path: [0, 0]
    }
};

function CommentItem({ comment, isAllowEdit, members }) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEmoji, setAnchorEmoji] = React.useState(null);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const classes = useStyles({ isOpenEdit });
    const { t } = useTranslation();
    const editCommentRef = React.useRef();
    const actionRef = React.useRef();
    const cursorRef = React.useRef({ ...cursorDefault });
    const editorRef = React.useRef();
    const { description, audit, mentionedUsers = [] } = comment;
    const [currentContent, setCurrentContent] = React.useState(description);
    const { createdBy, lastModifiedTime } = audit;
    const { id, name } = createdBy;
    const userInfo = useCurrentUserFullInfo();

    const users = React.useMemo(() => {
        const arr = members.map(m => m.user);
        mentionedUsers.map(u => {
            if (!arr.find(m => m.id === u.id)) {
                arr.push(u);
            }
            return false;
        });
        return arr;
    }, [members, mentionedUsers]);

    useClickAwaitListener(editCommentRef, e => {
        if (!isOpenEdit) return;
        handleClickAwayCommentBox(e);
    });

    React.useEffect(() => {
        if (isOpenEdit) {
            if (actionRef.current) {
                scrollInToView(actionRef.current, { block: 'center' });
            }
            if (editorRef.current) {
                editorRef.current.focus();
                setTimeout(() => {
                    cursorRef.current = editorRef.current.getRangeSelection() || { ...cursorDefault };
                }, 200);
            }
        }
    }, [isOpenEdit]);

    const handleClickAwayCommentBox = React.useCallback(
        e => {
            if (e && (checkContainId(e, 'comment_edit_emoji') || checkContainId(e, 'portal-mention-users'))) {
                return false;
            }
            setIsOpenEdit(false);
            setAnchorEmoji(null);
            setCurrentContent(description || '');
            cursorRef.current = { ...cursorDefault };
        },
        [description]
    );

    const handleFocus = React.useCallback(() => {
        if (editorRef.current && cursorRef.current) {
            editorRef.current.focusToSelection(cursorRef.current);
            return;
        }
    }, []);

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClickAwayEmoji = React.useCallback(() => {
        setAnchorEmoji(null);
    }, []);

    const cancelHandler = e => {
        handleClickAwayEmoji();
        setCurrentContent(description || '');
        setIsOpenEdit(false);
        if (editorRef.current) {
            editorRef.current.blur();
            editorRef.current.reset({ text: description || '' });
        }
    };

    const handleUpdate = React.useCallback(() => {
        if (!currentContent) {
            return;
        }
        handleClickAwayCommentBox();
        dispatch(
            updateCommentHandler({ oldComment: comment, newComment: { ...comment, description: currentContent } })
        );
    }, [dispatch, handleClickAwayCommentBox, comment, currentContent]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            cancelHandler();
        }
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            e.stopPropagation();
            handleUpdate();
        }
    };
    const calculateCursor = () => {
        if (editorRef.current) {
            const rangeSelection = editorRef.current.getRangeSelection();
            cursorRef.current = rangeSelection;
        }
    };

    const handleKeyUp = e => {
        setTimeout(calculateCursor, 200);
    };

    const handleClickEmoji = event => {
        handleFocus();
        setAnchorEmoji(anchorEl ? null : event.currentTarget);
    };

    const handleClick = React.useCallback(
        event => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleValueChange = React.useCallback(val => {
        setCurrentContent(val);
    }, []);

    const menuOptions = React.useMemo(() => {
        return [
            {
                name: 'Edit comment',
                icon: <EditSVG />,
                onClick: () => {
                    handleClickAway();
                    setIsOpenEdit(true);
                }
            },
            {
                name: 'Delete',
                icon: <DeleteSVG />,
                onClick: () => {
                    handleClickAway();
                    dispatch(deleteCommentHandler({ comment: comment }));
                }
            }
        ];
    }, [dispatch, handleClickAway, comment]);

    const emojiChange = emoji => {
        if (editorRef.current) {
            editorRef.current.focusToSelection(cursorRef.current);
            editorRef.current.insertText({
                text: emoji?.native,
                selection: cursorRef.current
            });
            handleKeyUp();
        }
    };

    const showMenuOption = React.useMemo(() => {
        return !isOpenEdit && userInfo.id === createdBy.id && isAllowEdit;
    }, [isOpenEdit, userInfo.id, createdBy.id, isAllowEdit]);

    return (
        <Grid container wrap="nowrap" direction="row" spacing={2}>
            <Grid item>
                <AvatarBase src={getAvatarUrl(id)} size={32}>
                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                </AvatarBase>
            </Grid>
            <Grid ref={editCommentRef} item className={classes.expand}>
                {!isOpenEdit ? (
                    <Grid container direction="column">
                        <Grid item>
                            <p className="body1 inline">{name}</p>{' '}
                            <p className="caption inline">({formatDateFromNow(lastModifiedTime)})</p>
                        </Grid>
                        <Grid item className={classes.description}>
                            <EditorMention
                                text={description}
                                members={users}
                                readOnly
                                mentionClassName={classes.mentionClassName}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item>
                        <Grid container direction="column" className={classes.editBox}>
                            <Grid item style={{ width: '100%', position: 'relative' }}>
                                <EditorMention
                                    className={`${classes.content}`}
                                    ref={editorRef}
                                    text={currentContent}
                                    onChange={handleValueChange}
                                    handleKeyDown={handleKeyDown}
                                    members={users}
                                    onKeyUp={handleKeyUp}
                                    onMouseUp={handleKeyUp}
                                    afterClickInsertSuggestion={handleKeyUp}
                                />
                            </Grid>
                            <span ref={actionRef} />
                            <Grid item className={classes.emoji}>
                                <CommentEmojiIconSVG style={{ cursor: 'pointer' }} onClick={handleClickEmoji} />
                                {anchorEmoji && (
                                    <PopperMenu
                                        className={classes.emojiPopup}
                                        anchorEl={anchorEmoji}
                                        placement={'right-end'}
                                        id={`comment_edit_emoji`}
                                    >
                                        <EmojiBox handleClickAway={handleClickAwayEmoji} onChange={emojiChange} />
                                    </PopperMenu>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-end" style={{ marginTop: 14, position: 'relative', left: -4 }}>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button width={80} onClick={cancelHandler} variant="outlined">
                                            {t('global_cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button width={80} onClick={handleUpdate} variant="contained">
                                            {t('global_save')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {showMenuOption && (
                <Grid item>
                    <IconButton onClick={handleClick}>
                        <IconMoreActionsSVG />
                    </IconButton>
                </Grid>
            )}
            {anchorEl && (
                <PopperMenu
                    className={classes.commentPopup}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                    id={`popper-comment-item`}
                >
                    <MenuOptionBase options={menuOptions} handleClickAway={handleClickAway} />
                </PopperMenu>
            )}
        </Grid>
    );
}

export default CommentItem;
