import React from 'react';

function AddRowBelowSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/CreateRecordBelow">
                <path
                    id="Combined Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.57968 5.69229C1.26855 5.7427 1.02943 6.03602 1.02943 6.38891V9.61888L1.03644 9.72303C1.08259 10.0629 1.35115 10.3242 1.67502 10.3242L12.1099 10.3318L12.205 10.3242C12.5155 10.2738 12.7554 9.98036 12.7554 9.62654V6.39656L12.7484 6.29267C12.7021 5.95353 12.4328 5.69229 12.1099 5.69229L1.67502 5.68463L1.57968 5.69229ZM1.57968 0.00765324C1.26855 0.0580683 1.02943 0.351387 1.02943 0.704272V3.93425L1.03644 4.0384C1.08259 4.37831 1.35115 4.63955 1.67502 4.63955L12.1099 4.6472L12.205 4.63955C12.5155 4.58913 12.7554 4.29572 12.7554 3.9419V0.711926L12.7484 0.608031C12.7021 0.268892 12.4328 0.00765324 12.1099 0.00765324L1.67502 0L1.57968 0.00765324ZM2.32163 7.09421V8.91564L11.4643 8.92329V7.10186L2.32163 7.09421ZM2.32163 3.23101V1.40958L11.4643 1.41723V3.23866L2.32163 3.23101ZM6.72808 11.3691C6.41695 11.4195 6.17783 11.7129 6.17783 12.0658V15.2957L6.18484 15.3996C6.23099 15.7388 6.49955 16 6.82341 16H15.1317L15.2268 15.9923C15.5373 15.9419 15.7772 15.6486 15.7772 15.2957V12.0658L15.7702 11.9619C15.7239 11.6227 15.4546 11.3615 15.1317 11.3615H6.82341L6.72808 11.3691ZM7.47003 14.5915V12.77H14.4861V14.5915H7.47003ZM2.51962 11.3721L0 13.6861L2.51962 16L3.35318 15.2345L2.22355 14.1982L5.42823 14.1979V13.1153L2.28839 13.1156L3.35318 12.1376L2.51962 11.3721Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(AddRowBelowSVG);
