import normalizeWheel from 'normalize-wheel';
import { checkContainId } from 'utils/clickAway';
import { isWindows } from 'utils/os';

export let moveGrid = '';
export let isFindingCell = '';
export let dragScrollBar = '';
export let lastScrollTopPosition = 0;
export let lastScrollLeftPosition = 0;

export function setMoveGrid(el) {
    moveGrid = el;
}

export function setFindingCell(value) {
    isFindingCell = value;
}

export function setDragScrollBar(value) {
    dragScrollBar = value;
}

export const SmoothScroll = ({
    target,
    speed,
    smooth,
    moveTopTargets = [],
    moveLeftTargets = [],
    isIgnoreLeftScroll = false,
    isNeedExtra = false
}) => {
    if (target === document)
        target = document.scrollingElement || document.documentElement || document.body.parentNode || document.body; // cross browser support for document scrolling
    let moving = false;
    let scrollTop = target.scrollTop;
    let scrollLeft = target.scrollLeft;
    let frame = target === document.body && document.documentElement ? document.documentElement : target; // safari is the new IE
    target.addEventListener('mousewheel', scrolled, false);
    target.addEventListener('DOMMouseScroll', scrolled, false);

    function scrolled(e) {
        if (checkContainId(e, 'cell-editor-123')) return;
        setMoveGrid();
        e.preventDefault(); // disable default scrolling
        let deltaY = normalizeWheelDeltaY(e);
        let deltaX = normalizeWheelDeltaX(e);
        if (!moving) {
            scrollTop = target.scrollTop;
            scrollLeft = target.scrollLeft;
        }
        if (e.shiftKey && isWindows()) {
            scrollLeft += -deltaY * speed;
            scrollLeft = Math.max(0, Math.min(scrollLeft, target.scrollWidth - frame.clientWidth)); // limit scrolling
        } else {
            scrollTop += -deltaY * speed;
            scrollLeft += -deltaX * speed;
            scrollTop = Math.max(0, Math.min(scrollTop, target.scrollHeight - frame.clientHeight)); // limit scrolling
            scrollLeft = Math.max(0, Math.min(scrollLeft, target.scrollWidth - frame.clientWidth)); // limit scrolling
        }
        if (!moving) update();
    }
    function normalizeWheelDeltaY(e) {
        if (e.detail) {
            if (e.wheelDeltaY) return (e.wheelDeltaY / e.detail / 40) * (e.detail > 0 ? 1 : -1);
            // Opera
            else {
                const { pixelY } = normalizeWheel(e);
                return -pixelY / 40; // Firefox (MacOS)
            }
        } else return e.wheelDeltaY / 120; // IE,Safari,Chrome
    }
    function normalizeWheelDeltaX(e) {
        if (e.detail) {
            if (e.wheelDeltaX) return (e.wheelDeltaX / e.detail / 40) * (e.detail > 0 ? 1 : -1);
            // Opera
            else {
                const { pixelX } = normalizeWheel(e);
                return -pixelX / 40; // Firefox (MacOS)
            }
        } else return e.wheelDeltaX / 120; // IE,Safari,Chrome
    }
    function update() {
        moving = true;
        let deltaY = (scrollTop - target.scrollTop) / smooth;
        let deltaX = (scrollLeft - target.scrollLeft) / smooth;
        target.scrollTop += deltaY;
        target.scrollLeft += deltaX;
        moveTopTargets.forEach(grid => {
            if (grid) {
                grid.scrollTop += deltaY;
            }
        });

        moveLeftTargets.forEach(grid => {
            if (grid && !isIgnoreLeftScroll) {
                grid.scrollLeft += deltaX;
            }
        });

        if (Math.abs(deltaY) > 0.5 && Math.abs(deltaX) > 0.5) requestFrame(update);
        else moving = false;
    }
    var requestFrame = (function() {
        // requestAnimationFrame cross browser
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function(func) {
                window.setTimeout(func, 1000 / 50);
            }
        );
    })();
};
