import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper, Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import useMediaQueries from 'hooks/useMediaQueries';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1, 2),
        '& a': {
            color: props => props.color || theme.colors.primaryText
        },
        borderRadius: 0,
        background: 'transparent'
    },
    last: {
        fontSize: 14,
        color: props => props.color || theme.colors.primaryText,
        cursor: 'default',
        fontWeight: 500
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
        '& > p': {
            color: theme.colors.white,
            fontWeight: 500
        }
    },
    separator: {
        color: theme.colors.iconHeaderColor,
        margin: '0 15px'
    },
    separatorIcon: {},
    parentColor: {
        color: theme.colors.paleLavender
    },
    parent: {
        color: theme.colors.paleLavender,
        maxWidth: props => props.parentNameMaxWidth || 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(2),
        fontWeight: 500
    },
    disabled: {
        color: `${theme.colors.paleLavender} !important`
    },
    dropdownSelected: {
        color: theme.colors.white,
        fontWeight: 500,
        '& *': {
            maxWidth: props => props.collectionNameMaxWidth || 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
            // fill: theme.colors.white
        },
        height: 32,
        display: 'flex',
        alignItems: 'center'
    },
    dropdown: {
        height: 32
    }
}));

function getParentNameMaxWidth({ isWindowLaptop, isMacOSLaptop }) {
    let parentNameMaxWidth = 200;
    if (isWindowLaptop) {
        parentNameMaxWidth = 100;
    }
    if (isMacOSLaptop) {
        parentNameMaxWidth = 150;
    }
    return parentNameMaxWidth;
}

function getCollectioNameMaxWidth({ isWindowLaptop, isMacOSLaptop }) {
    let collectionNameMaxWidth = 500;
    if (isWindowLaptop) {
        collectionNameMaxWidth = 250;
    }
    if (isMacOSLaptop) {
        collectionNameMaxWidth = 300;
    }
    return collectionNameMaxWidth;
}

function BreadCrumbHeader({ color, breadcrumbs }) {
    const { isMacOSLaptop, isWindowLaptop } = useMediaQueries();
    const collectionNameMaxWidth = getCollectioNameMaxWidth({ isWindowLaptop, isMacOSLaptop });
    const parentNameMaxWidth = getParentNameMaxWidth({ isWindowLaptop, isMacOSLaptop });

    const classes = useStyles({ color, collectionNameMaxWidth, parentNameMaxWidth });
    const theme = useTheme();

    return (
        <Paper id="breadcrumbs-container" elevation={0} className={classes.paper}>
            <Breadcrumbs
                classes={{ separator: classes.separator }}
                separator={<ArrowRightSVG color={theme.colors.paleLavender} size={12} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs.map((breadcrumb, index) => {
                    if (breadcrumb.isLast || !breadcrumb.path) {
                        return (
                            <Grid
                                id={`breadcrumbs-item-${index}`}
                                key={index}
                                className={classes.last}
                                color="textPrimary"
                                container
                                direction="row"
                                alignItems="center"
                                wrap="nowrap"
                            >
                                {breadcrumb.parent && (
                                    <Grid item container className={classes.parentColor}>
                                        <Typography
                                            id={`breadcrumbs-item-${index}-parent`}
                                            variant="body2"
                                            className={classes.parent}
                                        >{`${breadcrumb.parent}`}</Typography>
                                    </Grid>
                                )}
                                <Grid item className={classes.dropdown}>
                                    <Typography
                                        id={`breadcrumbs-item-${index}-content`}
                                        className={`${classes.dropdownSelected} ${
                                            breadcrumb.disabled ? classes.disabled : ''
                                        }`}
                                        component="div"
                                        variant="body2"
                                    >
                                        {breadcrumb.component || breadcrumb.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }

                    return (
                        <Link
                            id={`breadcrumbs-item-${index}`}
                            key={index}
                            className={classes.link}
                            color="inherit"
                            to={breadcrumb.path}
                        >
                            <Typography variant="body2"> {breadcrumb.name}</Typography>
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Paper>
    );
}

export default React.memo(BreadCrumbHeader);
