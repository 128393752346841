import React from 'react';

function PathSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Path"
                d="M0 4.00081C0 3.56397 0.326916 3.20292 0.75121 3.14577L0.869168 3.1379L10.0324 3.13782L8.36071 1.47391C8.08825 1.20454 8.03376 0.80051 8.19694 0.476993L8.26834 0.359869L8.36014 0.252814C8.66553 -0.0504608 9.14189 -0.0809755 9.48165 0.161785L9.58943 0.252827L12.7476 3.38942C12.8375 3.47862 12.9065 3.58614 12.9524 3.72076L12.9904 3.86475L13 4.03479L12.9784 4.19859L12.9457 4.3056L12.8893 4.42546L12.8217 4.52634L12.7476 4.61043L9.58912 7.74694C9.24981 8.08427 8.6992 8.08439 8.35992 7.74709C8.19506 7.58337 8.1055 7.36409 8.1055 7.13787C8.10519 6.95605 8.1627 6.77891 8.27023 6.63121L8.36014 6.52634L10.0336 4.86373H0.869168C0.473035 4.86373 0.138944 4.60088 0.0341437 4.24119L0.00793194 4.11794L0 4.00081Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(PathSVG);
