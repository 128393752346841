import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CellCommentBase from './Base';
import { CELL_COMMENT_HEIGHT } from 'const/style';
import { useThreadId, useComment, useCompositeViewId } from 'hooks/gridUI';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import ReplyBox from 'components/cellComment/ReplyBox';
import { scrollInToView } from 'utils/scroll';
import { useCurrentUserFullInfo } from 'hooks/auth';
import Spinner from 'components/spinner/Base';

const COMMENT_WIDTH = 400;
const useStyles = makeStyles(theme => ({
    root: {
        width: COMMENT_WIDTH,
        height: 'auto',
        background: theme.colors.white,
        borderRadius: 6,
        padding: '6px 0'
    },
    replyBox: {
        width: COMMENT_WIDTH,
        background: theme.colors.white,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6
    },
    spinner: {
        width: 400,
        height: CELL_COMMENT_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

function CellCommentList({ threadId, comment, saveHandler, cancelHandler, currentUser, t, rowId, columnId }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = React.useState(true);
    const compositeViewId = useCompositeViewId();

    React.useEffect(() => {
        dispatch(
            gridActions.fetchCellCommentList({
                threadId,
                compositeViewId,
                rowId,
                columnId,
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch, threadId, compositeViewId, rowId, columnId]);

    const updateThreadStatusHandler = newStatus => {
        dispatch(
            gridActions.updateCellCommentThreadStatus({
                threadId,
                newStatus,
                successCallback: () => {
                    console.log('update thread status Success');
                    const node = document.getElementById('scrollToHere');
                    scrollInToView(node);
                },
                errorCallback: () => {
                    console.log('update thread status failed');
                }
            })
        );
    };

    if (isFetching)
        return (
            <div className={classes.spinner}>
                <Spinner size={30} thick={4} />
            </div>
        );

    if (isEmpty(comment)) return null;

    return (
        <CellCommentBase
            updateThreadStatusHandler={updateThreadStatusHandler}
            commentData={comment}
            saveHandler={saveHandler}
            cancelHandler={cancelHandler}
            currentUser={currentUser}
            t={t}
        />
    );
}

function CellCommentEdit({ t, rowId, columnId }) {
    const classes = useStyles();
    const currentUser = useCurrentUserFullInfo();
    const dispatch = useDispatch();
    const threadId = useThreadId({ rowId, columnId });
    const comment = useComment({ rowId, columnId });

    const cancelHandler = () => {
        dispatch(gridActions.closeCellComment());
    };

    const saveHandler = content => {
        dispatch(
            gridActions.createComment({
                content,
                threadId,
                rowId,
                columnId,
                successCallback: () => {
                    console.log('create comment Success');
                    const node = document.getElementById('scrollToHere');
                    scrollInToView(node);
                },
                errorCallback: () => {
                    console.log('create comment failed');
                }
            })
        );
    };

    return (
        <div className={`${!threadId ? classes.replyBox : classes.root}`}>
            {!threadId && <ReplyBox autoFocus={true} cancelHandler={cancelHandler} saveHandler={saveHandler} />}
            {threadId && (
                <CellCommentList
                    threadId={threadId}
                    comment={comment}
                    cancelHandler={cancelHandler}
                    saveHandler={saveHandler}
                    currentUser={currentUser}
                    t={t}
                    rowId={rowId}
                    columnId={columnId}
                />
            )}
        </div>
    );
}

export default React.memo(CellCommentEdit);
