import React from 'react';

function AvatarIconSVG({ color = 'white', ...other }) {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1751 10.0001C28.7823 10.0261 32.4957 13.7058 32.4693 18.219C32.4428 22.7322 28.6864 26.3698 24.0792 26.3439C19.472 26.318 15.7585 22.6383 15.785 18.1251C15.8115 13.6119 19.5679 9.9742 24.1751 10.0001ZM30.8179 30.6116H17.3421C12.8502 30.6116 9 34.3833 9 38.7835C12.8502 43.1838 17.9838 45.6982 24.4009 45.6982C30.1762 45.6982 35.9515 42.5552 39.16 38.7835C39.16 34.3833 35.3098 30.6116 30.8179 30.6116Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(AvatarIconSVG);
