import React from 'react';

function RevokeSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.27495 3.31328C5.29733 2.49955 6.592 2.01332 8.00023 2.01332C11.3066 2.01332 13.9869 4.69364 13.9869 7.99999C13.9869 9.40822 13.5007 10.7029 12.6869 11.7253L4.27495 3.31328ZM3.31331 4.27498C2.49971 5.29731 2.01356 6.59188 2.01356 7.99999C2.01356 11.3063 4.69389 13.9867 8.00023 13.9867C9.40834 13.9867 10.7029 13.5005 11.7252 12.6869L3.31331 4.27498ZM8.00023 0.65332C3.94278 0.65332 0.653564 3.94254 0.653564 7.99999C0.653564 12.0574 3.94278 15.3467 8.00023 15.3467C12.0577 15.3467 15.3469 12.0574 15.3469 7.99999C15.3469 3.94254 12.0577 0.65332 8.00023 0.65332Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(RevokeSVG);
