import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider, Grid, Radio } from '@material-ui/core';
import BranchSVG from 'assets/images/svg/BranchSVG';
import * as columnTypes from 'const/columnTypes';
import Text from '../normal/Text';
import MultipleSelection from '../normal/MultipleSelection';
import SingleSelection from '../normal/SingleSelection';
import Files from '../normal/Files';
import Boolean from '../normal/Boolean';
import Datetime from '../normal/Datetime';
import Ref from '../normal/Ref';
import { CONFLICT_TYPES } from 'const/gridUI';
import GridSVG from 'assets/images/svg/GridSVG';
import ImportSVG from 'assets/images/svg/ImportSVG';
import classNames from 'classnames';
import { getNumberDisplay } from 'utils/gridUI/cell';

const useStyles = makeStyles(theme => ({
    root: {},
    flex: {
        display: 'flex'
    },
    primaryText: {
        color: theme.palette.primary.main
    },
    checkbox: {
        position: 'relative',
        top: -8
    },
    parentIcon: {
        padding: `2px 8px`,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4
    },
    selected: {
        background: theme.colors.paleNavy,
        border: `1px solid ${theme.colors.solitude}`
    },
    textSelected: {
        fontWeight: 500,
        color: theme.palette.primary.main
    }
}));

function renderContentType({ columnType, ...rest }) {
    switch (columnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.TRANSLATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
        case columnTypes.RECORD_ID:
            return <Text {...rest} />;

        case columnTypes.NUMBER: {
            const displayData = getNumberDisplay({ numberFormat: rest?.numberFormat, data: rest.data });
            return <Text {...rest} data={displayData} />;
        }
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelection {...rest} />;
        case columnTypes.FILES:
            return <Files {...rest} isHideMarkIcon={true} state="normal" />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelection isHideMarkIcon={true} state="normal" {...rest} />;

        case columnTypes.BOOLEAN:
            return <Boolean {...rest} state="normal" />;
        case columnTypes.DATETIME:
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <Datetime {...rest} state="normal" />;

        case columnTypes.REFERENCE:
            return <Ref {...rest} isHideMarkIcon={true} />;

        default:
            return null;
    }
}

function ConflictDiffCheck({
    columnType,
    conflictMode,
    conflictType,
    branchName,
    mergeToBranchName,
    data,
    rowId,
    columnId,
    previousData,
    onConflictChanged,
    ...rest
}) {
    const classes = useStyles();
    const theme = useTheme();
    const isChildChecked = React.useMemo(() => {
        return conflictMode === 'child' ? true : false;
    }, [conflictMode]);

    const getParentConflictIcon = React.useMemo(() => {
        switch (conflictType) {
            case CONFLICT_TYPES.BRANCHING:
                return (
                    <Grid container alignItems="center" direction="row" spacing={1}>
                        <Grid item className={classes.flex}>
                            <BranchSVG color={theme.colors.lightLavender} />
                        </Grid>
                        <Grid item>
                            <p className="body1 text-brand-main">{mergeToBranchName}</p>
                        </Grid>
                    </Grid>
                );
            case CONFLICT_TYPES.IMPORT:
                return (
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className={`${classes.parentIcon} ${!isChildChecked ? classes.selected : ``}`}
                    >
                        <Grid item className={classes.flex}>
                            <GridSVG
                                width={18}
                                height={18}
                                color={!isChildChecked ? theme.palette.primary.main : theme.colors.steel}
                            />
                        </Grid>
                        <Grid item>
                            <p
                                className={classNames(`body2`, {
                                    'font-medium text-text-primary': !isChildChecked
                                })}
                            >
                                Current data
                            </p>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }, [isChildChecked, conflictType, theme, classes, mergeToBranchName]);

    const getChildConflictIcon = React.useMemo(() => {
        switch (conflictType) {
            case CONFLICT_TYPES.BRANCHING:
                return (
                    <Grid container alignItems="center" direction="row" spacing={1}>
                        <Grid item className={classes.flex}>
                            <BranchSVG color={theme.colors.lightLavender} />
                        </Grid>
                        <Grid item>
                            <p className="body1 text-brand-main">{branchName}</p>
                        </Grid>
                    </Grid>
                );
            case CONFLICT_TYPES.IMPORT:
                return (
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        className={`${classes.parentIcon} ${isChildChecked ? classes.selected : ``}`}
                    >
                        <Grid item className={classes.flex}>
                            <ImportSVG color={isChildChecked ? theme.palette.primary.main : theme.colors.steel} />
                        </Grid>
                        <Grid item>
                            <p
                                className={classNames(`body2`, {
                                    'font-medium text-text-primary': !isChildChecked
                                })}
                            >
                                Imported file
                            </p>
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }, [isChildChecked, conflictType, theme, classes, branchName]);

    return (
        <Grid container direction="column">
            <Grid item container wrap="nowrap" direction="row" spacing={1}>
                <Grid item className={classes.checkbox}>
                    <Radio
                        onClick={e => onConflictChanged({ columnId, rowId, mode: 'parent' })}
                        size={'small'}
                        checked={!isChildChecked}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>{getParentConflictIcon}</Grid>
                        <Grid item>
                            {renderContentType({
                                columnType,
                                data: previousData,
                                ...rest
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>
                <Divider />
            </Grid>
            <Grid item container wrap="nowrap" direction="row" spacing={1}>
                <Grid item className={classes.checkbox}>
                    <Radio
                        onClick={e => onConflictChanged({ columnId, rowId, mode: 'child' })}
                        size={'small'}
                        checked={isChildChecked}
                    />
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>{getChildConflictIcon}</Grid>
                        <Grid item>
                            {renderContentType({
                                columnType,
                                data,
                                ...rest
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ConflictDiffCheck;
