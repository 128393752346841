import React from 'react';

function InfoIconSVG({ color = '#F6BD42', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M0 9.00002C0 4.02756 4.02751 4.6134e-05 8.99998 4.6134e-05C13.9725 4.6134e-05 18 4.02751 18 9.00002C18 13.9725 13.9724 18 8.99998 18C4.02751 18 0 13.9725 0 9.00002Z"
                fill="white"
            />
            <path
                d="M0 9.00002C0 4.02756 4.02751 4.6134e-05 8.99998 4.6134e-05C13.9725 4.6134e-05 18 4.02751 18 9.00002C18 13.9725 13.9724 18 8.99998 18C4.02751 18 0 13.9725 0 9.00002Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.00001 13.7242C9.62133 13.7242 10.125 13.2205 10.125 12.5992C10.125 11.9779 9.62133 11.4742 9.00001 11.4742C8.37868 11.4742 7.875 11.9779 7.875 12.5992C7.875 13.2205 8.37868 13.7242 9.00001 13.7242ZM9.00001 10.1242C9.62133 10.1242 10.125 9.62048 10.125 8.99916L10.125 5.39918C10.125 4.77785 9.62133 4.27417 9.00001 4.27417C8.37868 4.27417 7.875 4.77785 7.875 5.39918V8.99916C7.875 9.62048 8.37868 10.1242 9.00001 10.1242Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(InfoIconSVG);
