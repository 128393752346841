import React from 'react';
import { Dialog, Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIsShowMaintainMode, useMaintainType } from 'hooks/app';
import * as appActions from 'app/actions';
import { useDispatch } from 'react-redux';
import MaintenanceSVG from 'assets/images/svg/MaintenanceSVG';
import UnstableSVG from 'assets/images/svg/UnstableSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';
import { MAINTAIN_TYPES } from 'const';
import ButtonBase from 'components/button/Base';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        position: 'relative',
        maxWidth: 510,
        padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`
    },
    subtitleWrapper: {
        marginTop: theme.spacing(2)
    },
    subtitle: {
        color: theme.colors.midGrey
    },
    closeIcon: {
        position: 'absolute',
        top: 4,
        right: 4,
        cursor: 'pointer'
    },
    btn: {
        marginTop: theme.spacing(3)
    }
}));

function Maintenance() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isOpenMaintainDialog, setIsOpenMaintainDialog] = React.useState(false);
    const isShowMaintainMode = useIsShowMaintainMode();
    const maintainType = useMaintainType();

    React.useEffect(() => {
        setIsOpenMaintainDialog(isShowMaintainMode);
    }, [isShowMaintainMode]);

    const handleClose = () => {
        setIsOpenMaintainDialog(false);
        dispatch(appActions.turnOffMaintainPopup());
    };

    const isMaintain = React.useMemo(() => {
        return maintainType === MAINTAIN_TYPES.MAINTAIN;
    }, [maintainType]);

    const refreshBrowser = React.useCallback(() => {
        window.location.reload(true);
    }, []);

    return (
        <Dialog
            className={classes.root}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={isOpenMaintainDialog}
        >
            <Grid container direction="column" alignItems="center" className={classes.content} spac>
                <Grid item className={classes.closeIcon} onClick={handleClose}>
                    <IconButton size="medium">
                        <CloseIconSVG size={20} />
                    </IconButton>
                </Grid>
                <Grid item>{isMaintain ? <MaintenanceSVG /> : <UnstableSVG />}</Grid>
                <Grid item>
                    <Typography variant="h3" style={{ fontWeight: 400, marginTop: theme.spacing(3) }}>
                        {isMaintain ? t('maintenance_title') : `Network unstable, please try again`}
                    </Typography>
                </Grid>
                {isMaintain ? (
                    <Grid item className={classes.subtitleWrapper}>
                        <Typography className={classes.subtitle} variant="body2" align="center">
                            {t('maintenance_subtitle')}
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item>
                        <ButtonBase onClick={refreshBrowser} variant="outlined" className={classes.btn}>
                            Try again
                        </ButtonBase>
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
}

export default React.memo(Maintenance);
