import React from 'react';
import { FixedSizeList as List } from 'react-window';
import { SELECTION_LIST_ITEM_MIN_HEIGHT } from 'const/style';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    virtualizeList: {
        outline: 'none'
    }
}));

function MenuList(props) {
    const classes = useStyles();
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * SELECTION_LIST_ITEM_MIN_HEIGHT;

    const defaultHeight = React.useMemo(() => {
        const height = (children?.length || 1) * SELECTION_LIST_ITEM_MIN_HEIGHT;
        return height > maxHeight ? maxHeight : height;
    }, [children, maxHeight]);

    return (
        <List
            className={classes.virtualizeList}
            height={defaultHeight}
            itemCount={children.length}
            itemSize={SELECTION_LIST_ITEM_MIN_HEIGHT}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
        </List>
    );
}

export default React.memo(MenuList);
