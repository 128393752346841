import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as gridActions from 'grids/actions';
import * as types from 'socket/types';
import gridHandler from '../gridUI/grid';
import taskHandler from '../tasks';
import { isCanAddGridAndDatabaseRealtime } from 'utils/realtime';
import { isForAll, isForYou, isForOthers } from 'socket/utils/room';

const { dispatch, getState } = store;

const gridsHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);
    const serverSocketId = headers?.['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    const { action, subObjectType, objectType } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ state, headers })) {
        if (objectType === types.GRID && action === types.RESTORE) {
            return taskHandler({ body });
        }
        if (
            objectType === types.GRID &&
            [
                types.UPLOAD,
                types.IMPORT,
                types.MERGE_GRID,
                types.DUPLICATE_GRID,
                types.PREVIEW_IMPORT,
                types.PREVIEW_TRANSLATION
            ].includes(action)
        ) {
            return gridHandler({ body });
        }
    }

    if (isForYou({ state, headers })) {
        console.log('IGNORE');
        return;
    } else if (isForOthers({ state, headers })) {
        if (types.SUB_OBJECT_TYPE_NOT_FOUND !== !!subObjectType) return;
        return _gridListHandler({ body });
    }
};

const _gridListHandler = ({ body }) => {
    const { action, dbId, gridId, data } = body;
    switch (action) {
        case types.UPDATE:
            return _updateGrid({ dbId, gridId, newGrid: data });
        case types.DELETE:
            return _deleteGrid({ dbId, gridId });
        case types.CREATE:
            return _createGrid({ dbId, grid: data });
        default:
            return;
    }
};

function _updateGrid({ dbId, gridId, newGrid }) {
    dispatch(
        gridActions.updateGridSocketAction({
            gridId,
            dbId,
            newGrid
        })
    );
}

function _deleteGrid({ dbId, gridId }) {
    dispatch(
        gridActions.deleteGridSocketAction({
            gridId,
            dbId
        })
    );
}

function _createGrid({ dbId, grid }) {
    const { auth } = getState();
    if (!isCanAddGridAndDatabaseRealtime(auth)) return;

    const parentGridId = grid?.parentGridId;
    if (parentGridId) return;

    dispatch(
        gridActions.createGridSocketAction({
            dbId,
            grid
        })
    );
}

export default gridsHandler;
