import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Slider, Typography, useTheme } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import { getDownloadedFile } from 'utils/fileUtils';
import { getFilePreview } from 'utils/images';
import { DISABLED_OPACITY } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import Tooltip from 'components/tooltip/Base';
import NextButtonSVG from 'assets/images/svg/NextButtonSVG';
import BackButtonSVG from 'assets/images/svg/BackButtonSVG';
import { isKbArrowDown, isKbArrowLeft, isKbArrowRight, isKbArrowUp } from 'utils/keyboard';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import { getFriendlyTime } from 'utils/datetime';
import PauseContainedSVG from 'assets/images/svg/PauseContainedSVG';
import AudioSongSVG from 'assets/images/svg/AudioSongSVG';
import OverflowTypography from 'components/typography/OverflowTypography';
import { formatDuration, getAudioDuration } from 'utils/audio';
import ExportSVG from 'assets/images/svg/ExportSVG';
import SpeakerSVG from 'assets/images/svg/SpeakerSVG';
import ShuffleSVG from 'assets/images/svg/ShuffleSVG';
import AudioPrevSVG from 'assets/images/svg/AudioPrevSVG';
import PlayContainedSVG from 'assets/images/svg/PlayContainedSVG';
import AudioNextSVG from 'assets/images/svg/AudioNextSVG';
import RepeatSVG from 'assets/images/svg/RepeatSVG';
import { useRef } from 'react';
import PauseContained2SVG from 'assets/images/svg/PauseContained2SVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    header: {
        height: HEADER_HEIGHT,
        background: '#1A1434',
        position: 'relative',
        paddingLeft: 29,
        '& *': {
            color: theme.colors.white,
            fill: theme.colors.white
        }
    },
    controlsWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 23,
        margin: 'auto',
        height: '100%'
    },
    controls: {
        height: '100%'
    },
    control: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8
        }
    },
    body: {
        flex: 1,
        padding: `20px 113px`,
        position: 'relative'
    },
    footer: {
        backgroundColor: '#1A1434',
        height: 130
    },
    flex: {
        display: 'flex',
        cursor: 'pointer'
    },
    arrowWrapper: {
        width: 48,
        height: 48,
        background: hexToRgba(theme.colors.dimGrey, 0.5),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'absolute',
        '&.left': {
            left: 65
        },
        '&.right': {
            right: 65
        }
    },
    arrow: {
        height: 25,
        width: 25,
        '& path': {
            fill: theme.colors.white
        }
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none',
        cursor: 'not-allowed'
    },
    arrowUp: {
        marginRight: 10,
        '& svg': {
            width: 25,
            height: 25
        }
    },
    arrowDown: {
        marginRight: 60,
        '& svg': {
            width: 25,
            height: 25
        }
    },
    filesCount: {
        color: hexToRgba(theme.colors.white, 0.5)
    },
    flx: {
        flex: 1
    },
    playList: {
        maxWidth: 944,
        maxHeight: 622,
        width: '100%',
        height: '100%'
    },
    table: {
        '& .ReactVirtualized__Grid.ReactVirtualized__Table__Grid': {
            background: '#1A1434 !important'
        },
        '& .ReactVirtualized__Table__headerRow': {
            background: '#292246 !important',
            borderColor: '#4C4470 !important',
            color: '#FFFFFF !important',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
        },
        '& .ReactVirtualized__Table__row': {
            background: '#1A1434 !important',
            border: 0,
            '&.selected': {
                background: '#0A0718 !important'
            },
            '&:hover': {
                background: '#0A0718 !important',
                cursor: 'pointer',
                '& .indexPlay': {
                    display: 'block'
                },
                '& .indexNumber': {
                    display: 'none'
                }
            }
        },
        '& .header-index': {
            justifyContent: 'center'
        },
        '& .cell': {
            color: 'white'
        },
        '& .cell-index': {
            textAlign: 'center'
        },
        '& .cell-duration': {
            paddingLeft: 10
        },
        '& .cell-actions': {
            textAlign: 'center'
        }
    },
    white: {
        color: theme.colors.white
    },
    dodgerBlue: {
        color: theme.colors.dodgerBlue
    },
    audioWrapper: {
        padding: '0 65px',
        height: '100%'
    },
    volumeSlider: {
        width: 173,
        '& .MuiSlider-track': {
            background: '#D9D9D9',
            height: 3
        },
        '& .MuiSlider-thumb': {
            display: 'none'
        }
    },
    audioController: {
        maxWidth: 318,
        margin: '0 auto'
    },
    audioTrack: {
        margin: `0 12px`
    },
    fullWidth: {
        width: '100%'
    },
    side: {
        width: 204,
        maxWidth: 204
    },
    indexWrapper: {
        position: 'relative',
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    indexPlay: {
        position: 'absolute',
        zIndex: 1,
        display: 'none',
        '&.active': {
            display: 'block'
        }
    }
}));

const cachedDuration = {};
const REPEAT_MODE = {
    NO_REPEAT: 'NO_REPEAT',
    REPEAT: 'REPEAT',
    REPEAT_ONE: 'REPEAT_ONE'
};

const stopPropagation = e => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
};

const randomStep = ({ min, max, current, cb }) => {
    const newStep = Math.floor(Math.random() * (max - min)) + min;
    if (newStep === current) {
        randomStep({ min, max, current, cb });
        return;
    }
    cb(newStep);
};

const Index = React.memo(({ index, isSelected, isPlaying, handleIndexPlayPause }) => {
    const classes = useStyles();
    return (
        <Grid item className={classes.indexWrapper}>
            {!isSelected && <span className="indexNumber">{index}</span>}
            <Grid
                item
                className={classnames(classes.indexPlay, 'indexPlay', {
                    active: isSelected
                })}
            >
                <IconButton onClick={handleIndexPlayPause}>
                    {isSelected && isPlaying ? <PauseContainedSVG /> : <PlayContainedSVG />}
                </IconButton>
            </Grid>
        </Grid>
    );
});

const Title = React.memo(({ name, isSelected }) => {
    const classes = useStyles();
    return (
        <Grid container wrap="nowrap" alignItems="center" spacing={4}>
            <Grid item>
                <AudioSongSVG />
            </Grid>
            <Grid item>
                <OverflowTypography
                    variant="body1"
                    maxLines={1}
                    className={classnames(classes.white, {
                        [classes.dodgerBlue]: isSelected
                    })}
                >
                    {name}
                </OverflowTypography>
            </Grid>
        </Grid>
    );
});

const Duration = React.memo(({ src }) => {
    const [duration, setDuration] = useState(undefined);

    useEffect(() => {
        if (src) {
            if (cachedDuration[src]) {
                setDuration(cachedDuration[src]);
                return;
            }
            getAudioDuration(src, duration => {
                setDuration(duration);
                cachedDuration[src] = duration;
            });
        }
    }, [src]);

    if (duration === undefined) return null;

    return <span>{formatDuration(duration)}</span>;
});

const AudioController = (
    { step, fileName, src, isFirst, isLast, isPlaying, setIsPlaying, onPrev, onNext, onShuffle, onPlayFromStart },
    ref
) => {
    const classes = useStyles();
    const theme = useTheme();
    const [volume, setVolume] = useState(1);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isShuffle, setIsShuffle] = useState(false);
    const [repeatMode, setRepeatMode] = useState(REPEAT_MODE.NO_REPEAT);
    const initPause = useRef(true);

    const audioRef = useRef();

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
            audioRef.current.src = src;
        }
        //passing "step" to dependencies to fix bug if files have same source
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, step]);

    const newRepeatMode = useMemo(() => {
        switch (repeatMode) {
            case REPEAT_MODE.NO_REPEAT:
                return REPEAT_MODE.REPEAT;
            case REPEAT_MODE.REPEAT:
                return REPEAT_MODE.REPEAT_ONE;
            case REPEAT_MODE.REPEAT_ONE:
            default:
                return REPEAT_MODE.NO_REPEAT;
        }
    }, [repeatMode]);

    const tooltipRepeatMode = useMemo(() => {
        switch (repeatMode) {
            case REPEAT_MODE.NO_REPEAT:
                return 'Enable repeat';
            case REPEAT_MODE.REPEAT:
                return 'Enable repeat one';
            case REPEAT_MODE.REPEAT_ONE:
            default:
                return 'Disable repeat';
        }
    }, [repeatMode]);

    const handleChangeShuffle = useCallback(e => {
        stopPropagation(e);
        setIsShuffle(prev => !prev);
    }, []);

    const handleChangeVolume = useCallback((e, value) => {
        audioRef.current.volume = value;
        setVolume(value);
    }, []);

    const onToggleVolume = useCallback(
        e => {
            stopPropagation(e);
            const newVolume = volume > 0 ? 0 : 1;
            audioRef.current.volume = newVolume;
            setVolume(newVolume);
        },
        [volume]
    );

    const togglePlay = useCallback(() => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    }, [isPlaying, setIsPlaying]);

    const handleLoadedData = useCallback(() => {
        setDuration(audioRef.current.duration);
        if (initPause.current) {
            initPause.current = false;
            return;
        }
        audioRef.current.play();
        setIsPlaying(true);
    }, [setIsPlaying]);

    const handleTimeSliderChange = useCallback(
        (e, value) => {
            audioRef.current.currentTime = value;
            setCurrentTime(value);

            if (!isPlaying) {
                setIsPlaying(true);
                audioRef.current.play();
            }
        },
        [isPlaying, setIsPlaying]
    );

    const onTimeUpdate = useCallback(() => {
        setCurrentTime(audioRef.current.currentTime);
    }, []);

    const replay = useCallback(() => {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        setCurrentTime(0);
        setIsPlaying(true);
    }, [setIsPlaying]);

    const onAudioEnded = useCallback(() => {
        setIsPlaying(false);
        if (repeatMode === REPEAT_MODE.REPEAT_ONE) {
            replay();
            return;
        }
        if (isShuffle) {
            onShuffle();
            return;
        }
        if (repeatMode === REPEAT_MODE.REPEAT && isLast) {
            onPlayFromStart();
            return;
        }
        onNext();
    }, [setIsPlaying, repeatMode, isShuffle, isLast, onNext, replay, onShuffle, onPlayFromStart]);

    const toggleRepeat = useCallback(() => {
        setRepeatMode(newRepeatMode);
    }, [newRepeatMode]);

    useImperativeHandle(ref, () => ({
        getAudio: () => audioRef.current,
        getIsShuffle: () => isShuffle
    }));

    return (
        <Grid className={classes.audioWrapper} container alignItems="center" justifyContent="between">
            <Grid item className={classes.side}>
                <Title name={fileName} />
            </Grid>
            <Grid item className={classes.flx}>
                <Grid container wrap="nowrap" alignItems="center" justifyContent="center" direction="column">
                    <Grid item>
                        <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
                            <Grid item>
                                <IconButton onClick={handleChangeShuffle}>
                                    <Tooltip
                                        arrow={false}
                                        title={isShuffle ? 'Disable shuffle' : 'Enable shuffle'}
                                        placement="top"
                                    >
                                        <Grid item>
                                            <ShuffleSVG color={isShuffle ? theme.colors.dodgerBlue : '#D1D0D6'} />
                                        </Grid>
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid
                                item
                                className={classnames({
                                    [classes.disabled]: isFirst
                                })}
                            >
                                <IconButton onClick={onPrev}>
                                    <Tooltip arrow={false} title="Previous" placement="top">
                                        <Grid item>
                                            <AudioPrevSVG />
                                        </Grid>
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={togglePlay}>
                                    <Tooltip arrow={false} title={isPlaying ? 'Pause' : 'Play'} placement="top">
                                        <Grid item>{isPlaying ? <PauseContained2SVG /> : <PlayContainedSVG />}</Grid>
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid
                                item
                                className={classnames({
                                    [classes.disabled]: isLast
                                })}
                            >
                                <IconButton onClick={onNext}>
                                    <Tooltip arrow={false} title="Next" placement="top">
                                        <Grid item>
                                            <AudioNextSVG />
                                        </Grid>
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={toggleRepeat}>
                                    <Tooltip arrow={false} title={tooltipRepeatMode} placement="top">
                                        <Grid item>
                                            <RepeatSVG
                                                isRepeatOne={repeatMode === REPEAT_MODE.REPEAT_ONE}
                                                color={
                                                    repeatMode !== REPEAT_MODE.NO_REPEAT
                                                        ? theme.colors.dodgerBlue
                                                        : '#D1D0D6'
                                                }
                                            />
                                        </Grid>
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <Grid
                            className={classes.audioController}
                            container
                            wrap="nowrap"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item>
                                <Typography variant="body2" component="span" className={classes.white}>
                                    {formatDuration(currentTime * 1000)}
                                </Typography>
                            </Grid>
                            <Grid item className={classnames(classes.flx, classes.audioTrack)}>
                                <Slider
                                    className={classnames(classes.volumeSlider, classes.fullWidth)}
                                    aria-labelledby="volume"
                                    min={0}
                                    max={duration}
                                    value={currentTime}
                                    onChange={handleTimeSliderChange}
                                />
                                <audio
                                    ref={audioRef}
                                    src={src}
                                    onLoadedData={handleLoadedData}
                                    onTimeUpdate={onTimeUpdate}
                                    onEnded={onAudioEnded}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" component="span" className={classes.white}>
                                    {formatDuration(duration * 1000)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.side}>
                <Grid container wrap="nowrap" alignItems="flex-end">
                    <Grid item>
                        <IconButton onClick={onToggleVolume}>
                            <SpeakerSVG muted={volume === 0} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Slider
                            className={classes.volumeSlider}
                            aria-labelledby="volume"
                            min={0}
                            max={1}
                            step={0.01}
                            value={volume}
                            onChange={handleChangeVolume}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const AudioControllerMemo = React.memo(forwardRef(AudioController));

function AudioFilesPreview({ files = [], dbId, onClose, onDelete, defaultImage }) {
    const classes = useStyles();
    const [step, setStep] = useState(!defaultImage ? 0 : files?.findIndex(file => file?.id === defaultImage?.id));
    const [fileList, setFileList] = useState(files);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioControllerRef = useRef();

    const filesCount = useMemo(() => {
        return fileList?.length;
    }, [fileList]);

    const selectedFile = useMemo(() => {
        return fileList?.[step];
    }, [fileList, step]);

    const handleIndexPlayPause = useCallback(() => {
        if (isPlaying) {
            audioControllerRef.current.getAudio().pause();
        } else {
            audioControllerRef.current.getAudio().play();
        }
        setIsPlaying(!isPlaying);
    }, [isPlaying]);

    const COLUMNS = useMemo(() => {
        return [
            {
                label: '#',
                dataKey: 'index',
                width: 50,
                cellRenderer: props => (
                    <Index
                        index={props?.rowIndex + 1}
                        isSelected={props?.rowIndex === step}
                        isPlaying={isPlaying}
                        handleIndexPlayPause={handleIndexPlayPause}
                    />
                )
            },
            {
                label: 'Title',
                dataKey: 'originalName',
                flexGrow: 1,
                maxWidth: 500,
                cellRenderer: props => <Title name={props?.cellData} isSelected={props?.rowIndex === step} />
            },
            {
                label: 'Date added',
                dataKey: 'alteredTime',
                width: 150,
                cellRenderer: props => getFriendlyTime(props?.cellData, 'DD/MM/YYYY')
            },
            {
                label: 'Duration',
                dataKey: 'duration',
                width: 120,
                cellRenderer: props => <Duration src={getFilePreview({ dbId, fileId: props?.rowData?.id })} />
            },
            {
                label: 'Download',
                dataKey: 'actions',
                width: 65,
                cellRenderer: props => (
                    <IconButton
                        onClick={e => {
                            stopPropagation(e);
                            getDownloadedFile(getFilePreview({ dbId, fileId: props?.rowData?.id }));
                        }}
                    >
                        <ExportSVG color="#FFFFFF" />
                    </IconButton>
                )
            }
        ];
    }, [dbId, step, isPlaying, handleIndexPlayPause]);

    const handleSetStep = useCallback(step => {
        setStep(step);
    }, []);

    const goRandomStep = useCallback(
        e => {
            stopPropagation(e);
            randomStep({ min: 0, max: fileList?.length - 1, current: step, cb: handleSetStep });
        },
        [handleSetStep, fileList, step]
    );

    const goNextStep = useCallback(
        e => {
            stopPropagation(e);
            if (audioControllerRef.current.getIsShuffle()) {
                goRandomStep();
                return;
            }
            if (step < filesCount - 1) {
                handleSetStep(step + 1);
            }
        },
        [step, filesCount, handleSetStep, goRandomStep]
    );

    const goPrevStep = useCallback(
        e => {
            stopPropagation(e);
            if (audioControllerRef.current.getIsShuffle()) {
                goRandomStep();
                return;
            }
            if (step > 0) {
                handleSetStep(step - 1);
            }
        },
        [step, handleSetStep, goRandomStep]
    );

    const handleKeyDown = useCallback(
        e => {
            if (isKbArrowRight(e) || isKbArrowDown(e)) {
                goNextStep(e);
                return;
            }
            if (isKbArrowLeft(e) || isKbArrowUp(e)) {
                goPrevStep(e);
                return;
            }
        },
        [goPrevStep, goNextStep]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown, true);
        return () => {
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        setFileList(files);
    }, [files]);

    const fileName = useMemo(() => {
        if (!selectedFile) return 'Not Found';
        return selectedFile?.originalName;
    }, [selectedFile]);

    const src = useMemo(() => {
        return getFilePreview({ dbId, fileId: selectedFile?.id });
    }, [dbId, selectedFile]);

    return (
        <Grid id={'file-previews'} container className={classes.root} direction="column" wrap="nowrap">
            <Grid item>
                <Grid className={classes.header} container wrap="nowrap" alignItems="center">
                    <Grid
                        item
                        className={`${classes.flex} ${classes.arrowUp} ${step === 0 ? classes.disabled : ''}`}
                        onClick={goPrevStep}
                    >
                        <ArrowUpSVG />
                    </Grid>
                    <Grid
                        item
                        className={`${classes.flex} ${classes.arrowDown} ${
                            step === filesCount - 1 ? classes.disabled : ''
                        }`}
                        onClick={goNextStep}
                    >
                        <ArrowDownSVG />
                    </Grid>
                    <Grid item className={classes.controlsWrapper}>
                        <Grid container alignItems="center" className={classes.controls} wrap="nowrap">
                            <Grid item className={classes.flex} onClick={onClose}>
                                <CloseIconSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.body} wrap="nowrap" alignItems="center" justifyContent="center">
                <Tooltip
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="subtitle2">Back</Typography>
                            </Grid>
                            <Grid item className={classes.flex}>
                                <BackButtonSVG />
                            </Grid>
                        </Grid>
                    }
                >
                    <Grid item className={`${classes.arrowWrapper} left ${step === 0 ? classes.disabled : ''}`}>
                        <ArrowLeftSVG onClick={goPrevStep} className={`${classes.arrow}`} />
                    </Grid>
                </Tooltip>
                <Tooltip
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="subtitle2">Next</Typography>
                            </Grid>
                            <Grid item className={classes.flex}>
                                <NextButtonSVG />
                            </Grid>
                        </Grid>
                    }
                >
                    <Grid
                        item
                        onClick={goNextStep}
                        className={`${classes.arrowWrapper} right ${step === filesCount - 1 ? classes.disabled : ''}`}
                    >
                        <ArrowRightSVG className={`${classes.arrow}`} />
                    </Grid>
                </Tooltip>
                <Grid item className={classes.playList}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                className={classes.table}
                                data={fileList}
                                columns={COLUMNS}
                                width={width}
                                height={height}
                                scrollToIndex={step}
                                rowClassName={({ index }) => {
                                    return index === step ? 'selected' : '';
                                }}
                                onRowClick={props => {
                                    setStep(props);
                                }}
                            />
                        )}
                    </AutoSizer>
                </Grid>
            </Grid>
            <Grid item className={classes.footer}>
                <AudioControllerMemo
                    ref={audioControllerRef}
                    step={step}
                    fileName={fileName}
                    src={src}
                    isFirst={step === 0}
                    isLast={step === files.length - 1}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    onPrev={goPrevStep}
                    onNext={goNextStep}
                    onShuffle={goRandomStep}
                    onPlayFromStart={() => handleSetStep(0)}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(AudioFilesPreview);
