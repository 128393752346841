import React from 'react';

function IconAdminUserSVG({ color = '#ffffff', ...other }) {
    return (
        <svg {...other} width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.84729 2.64444C12.3868 0.968661 15.3932 0 18.6143 0C27.6801 0 34.9907 7.57493 35 16.9903C35.0093 26.367 27.6521 34 18.6143 34C14.833 34 11.3411 32.6632 8.57751 30.416C7.8586 29.8348 7.80258 28.7402 8.45614 28.0815C8.99766 27.539 9.82861 27.5003 10.4168 27.9749C12.6856 29.796 15.5333 30.8906 18.6237 30.8906C26.1396 30.8906 32.227 24.4393 32.0123 16.5932C31.8162 9.24103 26.0462 3.28376 18.9598 3.09971C16.2055 3.03191 13.6286 3.82621 11.4718 5.24046C10.8276 5.66667 9.96866 5.53105 9.4925 4.90142C8.94164 4.1943 9.10036 3.13846 9.84729 2.64444ZM6.07094 11.75C3.13801 11.75 0.75 14.0894 0.75 16.9812C0.75 19.873 3.13801 22.2123 6.07094 22.2123C8.50359 22.2123 10.5593 20.5982 11.1902 18.3988H15.1768V20.71C15.1768 21.5679 15.8777 22.25 16.74 22.25C17.6024 22.25 18.3033 21.5679 18.3033 20.71V18.3988H19.9793V18.8173C19.9793 19.6752 20.6802 20.3573 21.5426 20.3573C22.4049 20.3573 23.1058 19.6752 23.1058 18.8173V18.3988H24.7059C25.5587 18.3988 26.25 17.7261 26.25 16.8776L26.25 16.8742C26.2385 16.0196 25.5306 15.3282 24.6676 15.3282H11.1234C10.4218 13.2523 8.42514 11.75 6.07094 11.75ZM3.78067 16.9906C3.78067 15.7581 4.80524 14.7453 6.07094 14.7453C7.33663 14.7453 8.3612 15.7581 8.3612 16.9906C8.3612 18.2231 7.33663 19.2359 6.07094 19.2359C4.80524 19.2359 3.78067 18.2231 3.78067 16.9906Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(IconAdminUserSVG);
