import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import SettingContentLayout from '../common/Layout';
import { PAGE_PADDING } from 'const/style';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { getDependencyColorByStatus, getDependencyLabelByStatus } from 'utils/gridUI/dependency';
import { DEPENDENCY_STATUS, FILL_COLOR_OPTIONS } from 'const/gridUI';
import hexToRgb from 'hex-to-rgba';
import SubtractPNG from 'assets/images/patterns/subtract.png';
import { useDependencyBlindness, useFillColorBlindness } from 'hooks/auth';
import { useDispatch } from 'react-redux';
import * as authActions from 'auth/actions';
import { USER_SETTINGS } from 'const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: PAGE_PADDING
    },
    dpDcOption: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 6,
        marginLeft: theme.spacing(3)
    },
    group: {
        marginTop: theme.spacing(3)
    },
    dependency: {
        width: 3,
        height: '100%'
    },
    selected: {
        border: `1px solid ${theme.colors.lightLavender}`
    },
    colorList: {
        padding: theme.spacing(2),
        width: 230,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 6
    },
    colorItem: {
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        margin: theme.spacing(1)
    }
}));

const BLIND_OPTIONS = {
    NORMAL: 'NORMAL',
    BLIND: 'BLIND'
};

function Accessibility() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const fillColorBlindness = useFillColorBlindness();
    const dependencyBlindness = useDependencyBlindness();

    const [blindDependency, setBlindDependency] = React.useState(dependencyBlindness || BLIND_OPTIONS.NORMAL);
    const [blindFillColor, setBlindFillColor] = React.useState(fillColorBlindness || BLIND_OPTIONS.NORMAL);

    const handleDependencyBlindChange = React.useCallback(
        e => {
            const value = e.target.value;
            setBlindDependency(value);
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.BLINDNESS_DEPENDENCY]: value
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleFillColorBlindChange = React.useCallback(
        e => {
            const value = e.target.value;
            setBlindFillColor(value);
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.BLINDNESS_FILL_COLOR]: value
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        },
        [dispatch]
    );

    return (
        <SettingContentLayout name={t('accessibility')}>
            <Grid container className={classes.root} spacing={5} direction="column">
                <Grid item container direction="column" classes={classes.section}>
                    <Grid item>
                        <Typography variant="body1">{t('dependency_status')}</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <RadioGroup
                                className={classes.group}
                                aria-label="gender"
                                name="gender1"
                                value={blindDependency}
                                onChange={handleDependencyBlindChange}
                            >
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <FormControlLabel
                                            value={BLIND_OPTIONS.NORMAL}
                                            control={<Radio size="small" />}
                                            label={
                                                <Grid
                                                    className={classes.dpDcOption}
                                                    spacing={3}
                                                    container
                                                    direction="row"
                                                >
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <div
                                                                    className={classes.dependency}
                                                                    style={{
                                                                        background: hexToRgb(
                                                                            getDependencyColorByStatus({
                                                                                status: DEPENDENCY_STATUS.UP_TO_DATE,
                                                                                theme
                                                                            }),
                                                                            0.8
                                                                        )
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getDependencyLabelByStatus({
                                                                        status: DEPENDENCY_STATUS.UP_TO_DATE
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Divider orientation="vertical" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <div
                                                                    className={classes.dependency}
                                                                    style={{
                                                                        background: hexToRgb(
                                                                            getDependencyColorByStatus({
                                                                                status: DEPENDENCY_STATUS.OUT_OF_DATE,
                                                                                theme
                                                                            }),
                                                                            0.8
                                                                        )
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getDependencyLabelByStatus({
                                                                        status: DEPENDENCY_STATUS.OUT_OF_DATE
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Divider orientation="vertical" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <div
                                                                    className={classes.dependency}
                                                                    style={{
                                                                        background: hexToRgb(
                                                                            getDependencyColorByStatus({
                                                                                status: DEPENDENCY_STATUS.UNSET,
                                                                                theme
                                                                            }),
                                                                            0.8
                                                                        )
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getDependencyLabelByStatus({
                                                                        status: DEPENDENCY_STATUS.UNSET
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value={BLIND_OPTIONS.BLIND}
                                            control={<Radio size="small" />}
                                            label={
                                                <Grid
                                                    className={classes.dpDcOption}
                                                    spacing={3}
                                                    container
                                                    direction="row"
                                                >
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <div
                                                                    className={classes.dependency}
                                                                    style={{
                                                                        background: hexToRgb(
                                                                            getDependencyColorByStatus({
                                                                                status: DEPENDENCY_STATUS.UP_TO_DATE,
                                                                                theme
                                                                            }),
                                                                            0.8
                                                                        )
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getDependencyLabelByStatus({
                                                                        status: DEPENDENCY_STATUS.UP_TO_DATE
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Divider orientation="vertical" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <div
                                                                    className={classes.dependency}
                                                                    style={{
                                                                        background: `${theme.colors.white} url(${SubtractPNG})`
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getDependencyLabelByStatus({
                                                                        status: DEPENDENCY_STATUS.OUT_OF_DATE
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Divider orientation="vertical" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <div
                                                                    className={classes.dependency}
                                                                    style={{
                                                                        border: `1.5px solid ${hexToRgb(
                                                                            getDependencyColorByStatus({
                                                                                status: DEPENDENCY_STATUS.UNSET,
                                                                                theme
                                                                            }),
                                                                            0.8
                                                                        )}`
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getDependencyLabelByStatus({
                                                                        status: DEPENDENCY_STATUS.UNSET
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item container direction="column" classes={classes.section}>
                    <Grid item>
                        <Typography variant="body1">Fill Color</Typography>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <RadioGroup
                                className={classes.group}
                                aria-label="gender"
                                name="gender1"
                                value={blindFillColor}
                                onChange={handleFillColorBlindChange}
                            >
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <FormControlLabel
                                            value={BLIND_OPTIONS.NORMAL}
                                            control={<Radio size="small" />}
                                            label={
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                    className={classes.colorList}
                                                >
                                                    {Object.keys(FILL_COLOR_OPTIONS).map(hex => {
                                                        return (
                                                            <Grid item xs={2} key={hex}>
                                                                <span
                                                                    className={classes.colorItem}
                                                                    style={{
                                                                        background: `${hex}`
                                                                    }}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value={BLIND_OPTIONS.BLIND}
                                            control={<Radio size="small" />}
                                            label={
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                    className={classes.colorList}
                                                >
                                                    {Object.keys(FILL_COLOR_OPTIONS).map(hex => {
                                                        const image = FILL_COLOR_OPTIONS?.[hex];
                                                        return (
                                                            <Grid item xs={2} key={hex}>
                                                                <span
                                                                    className={classes.colorItem}
                                                                    style={{
                                                                        background: `${hex} url(${image})`
                                                                    }}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
}

export default Accessibility;
