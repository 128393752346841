import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_RADIUS } from 'const/style';
import { CELL_PADDING_LEFT_RIGHT, TEXT_LINE_HEIGHT } from 'const/gridUI';
import { isKbCopy } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import TextareaAutosize from 'react-autosize-textarea';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    key: {
        marginRight: theme.spacing(3)
    },
    expand: {
        flex: 1
    },
    input: {
        width: '100%',
        background: theme.colors.paleNavy,
        border: `none`,
        height: 32,
        borderRadius: INPUT_RADIUS,
        paddingLeft: CELL_PADDING_LEFT_RIGHT,
        paddingRight: CELL_PADDING_LEFT_RIGHT,
        fontSize: 14,
        color: theme.colors.primaryText,

        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            outline: 'none'
        },
        fontFamily: 'courier !important'
    },
    inputWrapper: {
        background: theme.colors.paleNavy,
        borderRadius: INPUT_RADIUS,
        paddingLeft: CELL_PADDING_LEFT_RIGHT,
        paddingRight: CELL_PADDING_LEFT_RIGHT,
        fontSize: 14,
        '&:focus-within': {
            border: `1px solid ${theme.palette.primary.main}`
        },
        '& input': {
            width: '100%',
            border: `none`,
            height: 32,
            borderRadius: INPUT_RADIUS,
            background: 'transparent',
            fontSize: 14,
            color: theme.colors.primaryText,
            fontFamily: 'courier !important',
            '&:focus': {
                outline: 'none'
            }
        }
    },
    textarea: {
        width: '100%',
        background: theme.colors.paleNavy,
        border: `none`,
        borderRadius: INPUT_RADIUS,
        paddingLeft: CELL_PADDING_LEFT_RIGHT,
        paddingRight: CELL_PADDING_LEFT_RIGHT,
        fontSize: 14,
        height: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.colors.primaryText,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            outline: 'none'
        },
        fontFamily: 'courier !important',
        resize: 'none',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    fullWidth: {
        width: '100%'
    }
}));

function InputViewText({ label, value, secure = false, onCopied, multiline = false, action, ...rest }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const hiddenValue = `••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••`;
    const [isProtected, setIsProtected] = React.useState(secure);
    const inputRef = React.useRef();

    const handleFocus = () => {
        if (!secure) {
            inputRef.current.select();
            return;
        }
        setIsProtected(false);
    };

    React.useEffect(() => {
        if (!isProtected && secure) {
            inputRef.current.select();
        }
    }, [isProtected, secure]);

    const handleBlur = () => {
        if (!secure) return;
        setIsProtected(true);
    };

    const handleKeyDown = e => {
        if (isKbCopy(e)) {
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`global_copied`),
                    type: 'info'
                })
            );
            onCopied && onCopied();
        }
    };

    return (
        <Grid container className={classes.root} alignItems="center">
            {label && (
                <Grid item className={classes.key}>
                    <Typography variant="body1">{label}</Typography>
                </Grid>
            )}
            <Grid item className={`${classes.expand} ${classes.fullWidth}`}>
                {!multiline ? (
                    !action ? (
                        <input
                            ref={inputRef}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            readOnly={true}
                            value={isProtected ? hiddenValue : value}
                            className={classes.input}
                            onKeyDown={handleKeyDown}
                            {...rest}
                        />
                    ) : (
                        <Grid
                            container
                            className={classes.inputWrapper}
                            alignItems="center"
                            direction="row"
                            justify="space-between"
                            spacing={2}
                        >
                            <Grid item className={classes.expand}>
                                <input
                                    ref={inputRef}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    readOnly={true}
                                    value={isProtected ? hiddenValue : value}
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>
                            <Grid item>{action}</Grid>
                        </Grid>
                    )
                ) : (
                    <TextareaAutosize
                        ref={inputRef}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        readOnly={true}
                        value={isProtected ? hiddenValue : value}
                        className={classes.textarea}
                        onKeyDown={handleKeyDown}
                    />
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(InputViewText);
