import React from 'react';

function AddRecordAboveSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.72798 0.00765371C6.41685 0.0580683 6.17773 0.351388 6.17773 0.704273V3.93425L6.18474 4.03814C6.23089 4.37728 6.49945 4.63852 6.82332 4.63852H15.1316L15.2267 4.63087C15.5372 4.58045 15.7771 4.28713 15.7771 3.93425V0.704273L15.7701 0.600378C15.7238 0.261239 15.4545 0 15.1316 0H6.82332L6.72798 0.00765371ZM7.46993 3.22998V1.40855H14.486V3.22998H7.46993ZM1.57955 11.3308C1.26841 11.3812 1.0293 11.6746 1.0293 12.0274V15.2574L1.0363 15.3616C1.08246 15.7015 1.35101 15.9627 1.67488 15.9627L12.1097 15.9704L12.2048 15.9627C12.5153 15.9123 12.7553 15.6189 12.7553 15.2651V12.0351L12.7483 11.9312C12.702 11.5921 12.4327 11.3308 12.1097 11.3308L1.67488 11.3232L1.57955 11.3308ZM1.57955 5.64619C1.26841 5.69661 1.0293 5.98992 1.0293 6.34281V9.57279L1.0363 9.67694C1.08246 10.0168 1.35101 10.2781 1.67488 10.2781L12.1097 10.2857L12.2048 10.2781C12.5153 10.2277 12.7553 9.93426 12.7553 9.58044V6.35046L12.7483 6.24657C12.702 5.90743 12.4327 5.64619 12.1097 5.64619L1.67488 5.63854L1.57955 5.64619ZM2.32031 12.7328V14.5542L11.463 14.5618V12.7404L2.32031 12.7328ZM2.32031 8.86955V7.04812L11.463 7.05578V8.87721L2.32031 8.86955ZM2.51962 0.0106435L0 2.32458L2.51962 4.63852L3.35318 3.87301L2.22355 2.83668L5.42823 2.83645V1.75386L2.28839 1.75409L3.35318 0.776151L2.51962 0.0106435Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(AddRecordAboveSVG);
