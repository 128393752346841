import React from 'react';

function BillingSettingSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7992 0.835156C10.7992 0.393328 10.441 0.0351562 9.99922 0.0351562C9.55739 0.0351562 9.19922 0.393328 9.19922 0.835156V3.3685H7.91589C6.93016 3.3685 5.98482 3.76008 5.28781 4.45709C4.5908 5.1541 4.19922 6.09945 4.19922 7.08517C4.19922 8.07089 4.5908 9.01624 5.28781 9.71325C5.98482 10.4103 6.93016 10.8018 7.91589 10.8018H9.19922V15.0352H4.99922C4.55739 15.0352 4.19922 15.3933 4.19922 15.8352C4.19922 16.277 4.55739 16.6352 4.99922 16.6352H9.19922V19.1685C9.19922 19.6103 9.55739 19.9685 9.99922 19.9685C10.441 19.9685 10.7992 19.6103 10.7992 19.1685V16.6352H12.0826C13.0683 16.6352 14.0136 16.2436 14.7106 15.5466C15.4076 14.8496 15.7992 13.9042 15.7992 12.9185C15.7992 11.9328 15.4076 10.9874 14.7106 10.2904C14.0136 9.59341 13.0683 9.20183 12.0826 9.20183H10.7992V4.9685H14.1659C14.6077 4.9685 14.9659 4.61033 14.9659 4.1685C14.9659 3.72667 14.6077 3.3685 14.1659 3.3685H10.7992V0.835156ZM9.19922 4.9685H7.91589C7.35451 4.9685 6.81613 5.19151 6.41918 5.58846C6.02222 5.98541 5.79922 6.52379 5.79922 7.08517C5.79922 7.64654 6.02222 8.18492 6.41918 8.58188C6.81613 8.97883 7.35451 9.20183 7.91589 9.20183H9.19922V4.9685ZM10.7992 10.8018V15.0352H12.0826C12.6439 15.0352 13.1823 14.8122 13.5793 14.4152C13.9762 14.0183 14.1992 13.4799 14.1992 12.9185C14.1992 12.3571 13.9762 11.8187 13.5793 11.4218C13.1823 11.0248 12.6439 10.8018 12.0826 10.8018H10.7992Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(BillingSettingSVG);
