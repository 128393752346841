import isEmpty from 'lodash/isEmpty';
import { removeArrayInArray } from 'utils/object';
import { getViewColumnsWithReorderAndLanguagePairs } from 'utils/gridUI/column';

export function generateRecordServerByRange({ selection }) {
    let records = [];
    Object.keys(selection).forEach(rowId => {
        let recordServer = [rowId];
        let recordData = selection[rowId];
        Object.keys(recordData).forEach(columnId => {
            recordServer.push(recordData[columnId]);
        });
        records.push(recordServer);
    });

    return records;
}

export function isRangeSelection({ cellSelecteds, isCellClicked, rowsSelected }) {
    if (!isEmpty(cellSelecteds) || !isEmpty(rowsSelected)) {
        return true;
    }
    if (isCellClicked && isEmpty(cellSelecteds)) {
        return false;
    }
}

function _sortRanges(ranges) {
    return JSON.parse(JSON.stringify(ranges))
        .filter(el => Array.isArray(el) && el.length > 0)
        .sort(function(a, b) {
            if (a.length === 1) {
                a = [a[0], a[0]];
            }
            if (b.length === 1) {
                b = [b[0], b[0]];
            }
            return a[0] - b[0] || a[1] - b[1];
        });
}

export function mergeRangesOverlap(ranges) {
    let result = _sortRanges(ranges);
    let i = 0;

    while (i < result.length - 1) {
        if (result[i].length === 1) {
            result[i] = [result[i][0], result[i][0]];
        }
        if (result[i + 1]?.length === 1) {
            result[i + 1] = [result[i + 1][0], result[i + 1][0]];
        }
        var current = result[i],
            next = result[i + 1];

        // check if there is an overlapping
        if (current[1] >= next[0] - 1) {
            result[i][1] = Math.max(current[1], next[1]);
            // remove next
            result.splice(i + 1, 1);
        } else {
            // move to next
            i++;
        }
    }
    return result;
}

export function getTotalSelectedRowsByRange(ranges) {
    return ranges?.reduce((total, range) => {
        let num = 0;
        if (range?.length === 1) {
            num = 1;
        } else if (range?.length > 1) {
            num = range?.[1] - range?.[0] + 1;
        }
        return total + num;
    }, 0);
}

export function generateServerRowRange({ ranges }) {
    return ranges?.map(range => {
        const start = range?.[0];
        const end = range?.[1] || start;
        return {
            fromIndex: start + 1,
            toIndex: end + 1
        };
    });
}

export function isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) {
    return rowStartIndex > -1 && rowStopIndex > -1 && columnStartIndex > -1 && columnStopIndex > -1;
}

export function isSelectingRange({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) {
    const isCellSelected = rowStartIndex !== rowStopIndex || columnStartIndex !== columnStopIndex;
    return isCellSelected && isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex });
}

export function isSelectedByRange({ range, rowIndex, columnIndex }) {
    const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = range;
    const isSelecting = isSelectingRange({ ...range });
    const minRowIndex = Math.min(Number(rowStopIndex), Number(rowStartIndex));
    const maxRowIndex = Math.max(Number(rowStopIndex), Number(rowStartIndex));
    const minColIndex = Math.min(Number(columnStartIndex), Number(columnStopIndex));
    const maxColIndex = Math.max(Number(columnStartIndex), Number(columnStopIndex));

    const isInRange =
        rowIndex >= minRowIndex && rowIndex <= maxRowIndex && columnIndex >= minColIndex && columnIndex <= maxColIndex;
    return isSelecting && isInRange;
}

export function getFromRecordIdToRecordId({ rowIndexMap }) {
    const indexes = Object.keys(rowIndexMap);
    const max = Math.max(...indexes);
    const min = Math.min(...indexes);
    return {
        fromRecordId: rowIndexMap?.[min],
        toRecordId: rowIndexMap?.[max]
    };
}

export function getColumnIdsFromRange({ columnStartIndex, columnStopIndex, viewColumns }) {
    const max = Math.max(columnStartIndex, columnStopIndex);
    const min = Math.min(columnStartIndex, columnStopIndex);
    const columns = viewColumns?.slice(min, Math.min(max + 1, viewColumns?.length));
    return columns?.map(column => column?.id);
}

export function checkAndGenerateRange({ ranges, rowIndex }) {
    let isInRange = false;
    let rangeIndex = null;

    for (const [index, range] of ranges?.entries()) {
        if (range?.length === 0) continue;
        if (range?.length === 1) {
            if (range?.[0] !== rowIndex) continue;
            isInRange = true;
            rangeIndex = index;
            break;
        }
        if (range?.length > 1) {
            const [start, stop] = range;
            if (rowIndex < start || rowIndex > stop) continue;
            isInRange = true;
            rangeIndex = index;
            break;
        }
    }

    return { isInRange, rangeIndex };
}

export function getRowSelectedIndexes({ rangeIndexes }) {
    const rowsRangeIndexesMerged = rangeIndexes;

    let indexes = [];
    for (const range of rowsRangeIndexesMerged) {
        if (range?.length === 1) {
            indexes.push(range?.[0]);
        } else if (range?.length === 2) {
            const [start, stop] = range;
            for (let i = start; i <= stop; i++) {
                indexes.push(i);
            }
        }
    }

    return [...new Set(indexes)];
}

export function getRemainingRowIds({ rows = [], deletedIndexes, ROW_START_INDEX, ROW_STOP_INDEX }) {
    const deletedRowIds = [];
    let beforeStartIndexCount = 0;

    console.log('ROW_START_INDEX', ROW_START_INDEX);
    for (const index of deletedIndexes) {
        if (index >= ROW_START_INDEX && index <= ROW_STOP_INDEX) {
            beforeStartIndexCount++;
            const rowId = rows?.[index - ROW_START_INDEX];
            if (rowId) {
                deletedRowIds.push(rowId);
            }
        }
    }

    const remainingRecordIds = removeArrayInArray(rows, deletedRowIds);
    const newRowStartIndex = ROW_START_INDEX + beforeStartIndexCount;
    const newRowStopIndex = newRowStartIndex + remainingRecordIds?.length;

    return {
        newRowStartIndex,
        remainingRecordIds,
        newRowStopIndex
    };
}

export function generateDeletedRangeData({ data, recordIds, columnIds }) {
    const serverData = [];
    const redoData = {};
    const undoData = {};

    for (const recordId of recordIds) {
        const rowServerData = [recordId];
        const rowData = data?.[recordId];
        for (const columnId of columnIds) {
            const cellData = rowData?.[columnId];
            redoData[recordId] = {
                ...redoData?.[recordId],
                [columnId]: {
                    ...cellData,
                    value: null
                }
            };

            undoData[recordId] = {
                ...undoData?.[recordId],
                [columnId]: cellData
            };
            rowServerData.push(null);
        }
        serverData.push(rowServerData);
    }

    return {
        serverData,
        redoData,
        undoData
    };
}

export function isRowIndexInRange({ ranges, rowIndex }) {
    return ranges?.some(range => {
        const start = range?.[0];
        const end = range?.[1] || start;
        return rowIndex >= start && rowIndex <= end;
    });
}

export function getRecordIdsInRowRangeIndexes({ ranges, ROW_START_INDEX, rows }) {
    let recordIds = [];

    for (const range of ranges) {
        const start = range?.[0];
        const stop = range?.[1] || start;
        const startIndex = Math.max(start - ROW_START_INDEX, 0);
        const stopIndex = Math.max(stop - ROW_START_INDEX, 0);
        const ids = rows?.slice(startIndex, stopIndex + 1);

        recordIds = recordIds.concat(ids);
    }
    return [...new Set(recordIds)];
}

export function findSelectionByRange({ range, auth, gridUI, dispatch }) {
    const viewColumns = getViewColumnsWithReorderAndLanguagePairs({ auth, gridUI });
    const totalRecords = gridUI?.totalRecords;
    const maxColLength = viewColumns?.length;

    const DEFAILT_SELECTION = {
        rowStartIndex: -1,
        rowStopIndex: -1,
        columnStartIndex: -1,
        columnStopIndex: -1
    };

    let newRange = {
        _startRow: 0,
        _stopRow: 0,
        _startCol: 0,
        _stopCol: 0
    };

    const [row, col] = range?.split('-')?.map(v => v?.trim()?.toLowerCase());
    if (row && col) {
        const [_startRow, _stopRow] = row
            ?.replace(new RegExp('r', 'gi'), '')
            .split(':')
            ?.map(r => (r?.toLowerCase() === 'last' ? totalRecords : Number(r)));
        const [_startCol, _stopCol] = col
            ?.replace(new RegExp('c', 'gi'), '')
            .split(':')
            ?.map(c => (c?.toLowerCase() === 'last' ? maxColLength : Number(c)));
        if (isNaN(_startRow) || isNaN(_stopRow) || isNaN(_startCol) || isNaN(_stopCol)) {
            return DEFAILT_SELECTION;
        }
        //ROW
        if (_startRow) {
            newRange._startRow = _startRow;
        } else {
            if (_stopRow) {
                newRange._startRow = _stopRow;
            }
        }
        if (_stopRow) {
            newRange._stopRow = _stopRow;
        } else {
            if (_startRow) {
                newRange._stopRow = _startRow;
            }
        }
        //COL
        if (_startCol) {
            newRange._startCol = _startCol;
        } else {
            if (_stopCol) {
                newRange._startCol = _startCol;
            }
        }
        if (_stopCol) {
            newRange._stopCol = _stopCol;
        } else {
            if (_startCol) {
                newRange._stopCol = _startCol;
            }
        }
    } else if (row) {
        if (row?.includes('r')) {
            const [_startRow, _stopRow] = row
                ?.replace(new RegExp('r', 'gi'), '')
                .split(':')
                ?.map(r => (r?.toLowerCase() === 'last' ? totalRecords : Number(r)));
            if (isNaN(_startRow) || isNaN(_stopRow)) {
                return DEFAILT_SELECTION;
            }
            newRange._startCol = 1;
            newRange._stopCol = maxColLength;
            //ROW
            if (_startRow) {
                newRange._startRow = _startRow;
            } else {
                if (_stopRow) {
                    newRange._startRow = _stopRow;
                }
            }
            if (_stopRow) {
                newRange._stopRow = _stopRow;
            } else {
                if (_startRow) {
                    newRange._stopRow = _startRow;
                }
            }
        } else if (row?.includes('c')) {
            const [_startCol, _stopCol] = row
                ?.replace(new RegExp('c', 'gi'), '')
                .split(':')
                ?.map(c => (c?.toLowerCase() === 'last' ? maxColLength : Number(c)));
            if (isNaN(_startCol) || isNaN(_stopCol)) {
                return DEFAILT_SELECTION;
            }
            newRange._startRow = 1;
            newRange._stopRow = totalRecords;
            //COL
            if (_startCol) {
                newRange._startCol = _startCol;
            } else {
                if (_stopCol) {
                    newRange._startCol = _startCol;
                }
            }
            if (_stopCol) {
                newRange._stopCol = _stopCol;
            } else {
                if (_startCol) {
                    newRange._stopCol = _startCol;
                }
            }
        }
    }

    newRange._stopRow = Math.max(0, newRange._stopRow - 1);
    newRange._startRow = Math.max(0, newRange._startRow - 1);
    newRange._startCol = Math.max(0, newRange._startCol - 1);
    newRange._stopCol = Math.max(0, newRange._stopCol - 1);

    let _rowStartIndex = Math.min(Number(newRange._stopRow), Number(newRange._startRow));
    let _rowStopIndex = Math.max(Number(newRange._stopRow), Number(newRange._startRow));
    let _columnStartIndex = Math.min(Number(newRange._stopCol), Number(newRange._startCol));
    let _columnStopIndex = Math.max(Number(newRange._stopCol), Number(newRange._startCol));
    if (_rowStopIndex > totalRecords - 1) {
        _rowStopIndex = totalRecords - 1;
    }

    if (_rowStartIndex > totalRecords - 1) {
        _rowStopIndex = totalRecords - 1;
    }

    if (_columnStartIndex > maxColLength - 1) {
        _columnStartIndex = maxColLength - 1;
    }

    if (_columnStopIndex > maxColLength - 1) {
        _columnStopIndex = maxColLength - 1;
    }

    return {
        rowStartIndex: _rowStartIndex,
        rowStopIndex: _rowStopIndex,
        columnStartIndex: _columnStartIndex,
        columnStopIndex: _columnStopIndex
    };
}
