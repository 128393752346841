import React from 'react';

const HistorySVG2 = ({ color = '#606066', ...other }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M7 9.25H2.5V10.75H7V9.25Z" fill="#606066" />
            <path d="M11.5 3.25H7V4.75H11.5V3.25Z" fill="#606066" />
            <path d="M9.25 6.25H4.75V7.75H9.25V6.25Z" fill="#606066" />
            <path
                d="M12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM12.25 12.25H1.75V1.75H12.25V12.25Z"
                fill="#606066"
            />
        </svg>
    );
};

export default React.memo(HistorySVG2);
