import React from 'react';

const CopySourceSVG = ({ color = '#78778B', ...other }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M0.654083 14.791L15.3437 14.7458"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.65618 11.3924L4.14743 8.90753L7.95678 12.7169L6.46086 14.209H8.23089L15.2265 7.2312C15.813 6.64617 15.8143 5.69642 15.2292 5.10989L11.7778 1.64968C11.1928 1.06315 10.2431 1.06193 9.65652 1.64697L0.773428 10.5074C0.186894 11.0924 0.185681 12.0422 0.770719 12.6287L2.34695 14.209H4.11247L1.65573 11.746C1.55822 11.6482 1.55843 11.4899 1.65618 11.3924ZM5.03244 8.02477L8.84179 11.8341L14.3438 6.34619C14.4415 6.24869 14.4417 6.0904 14.3442 5.99264L10.8928 2.53243C10.7953 2.43468 10.637 2.43448 10.5393 2.53198L5.03244 8.02477Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(CopySourceSVG);
