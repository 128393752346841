import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import HashSymbolSVG from 'assets/images/svg/HashSymbolSVG';
import PercentageSymbolSVG from 'assets/images/svg/PercentageSymbolSVG';
import { sendManualTrack } from 'tracker';

const VIEWS = {
    PERCENT: 'percent',
    NORMAL: 'normal'
};

const useStyles = makeStyles(theme => ({
    root: {
        background: '#fff',
        border: `1px solid #D4CEEC`,
        borderRadius: 4,
        height: 36,
        overflow: 'hidden'
    },
    iconWrapper: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 14px',
        height: 36
    },
    iconSelected: {
        background: theme.colors.lightLavender
    }
}));

function PercentageSwitch({ value, onChange }) {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState(value || VIEWS.PERCENT);

    const handleNormalClick = () => {
        setSelectedValue(VIEWS.NORMAL);
        onChange(VIEWS.NORMAL);
        sendManualTrack({ type: '[Gridly] Choose Translation Normal Mode' });
    };

    const handlePercentClick = () => {
        setSelectedValue(VIEWS.PERCENT);
        onChange(VIEWS.PERCENT);
        sendManualTrack({ type: '[Gridly] Choose Translation Percent Mode' });
    };

    return (
        <Grid className={`view-switch ${classes.root}`} container alignItems="center">
            <Grid
                item
                className={`${classes.iconWrapper} ${classes.iconLeft} ${
                    selectedValue === VIEWS.NORMAL ? classes.iconSelected : ''
                }`}
                onClick={handleNormalClick}
            >
                <HashSymbolSVG color={selectedValue === VIEWS.NORMAL ? theme.colors.white : undefined} />
            </Grid>
            <Grid
                item
                className={`${classes.iconWrapper} ${classes.iconRight} ${
                    selectedValue === VIEWS.PERCENT ? classes.iconSelected : ''
                }`}
                onClick={handlePercentClick}
            >
                <PercentageSymbolSVG color={selectedValue === VIEWS.PERCENT ? theme.colors.white : undefined} />
            </Grid>
        </Grid>
    );
}

export default React.memo(PercentageSwitch);
