import React from 'react';

function DraggingSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Icons/Drag" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M5.6,12.2 C6.20751322,12.2 6.7,12.6924868 6.7,13.3 C6.7,13.9075132 6.20751322,14.4 5.6,14.4 C4.99248678,14.4 4.5,13.9075132 4.5,13.3 C4.5,12.6924868 4.99248678,12.2 5.6,12.2 Z M9.8,12.2 C10.4075132,12.2 10.9,12.6924868 10.9,13.3 C10.9,13.9075132 10.4075132,14.4 9.8,14.4 C9.19248678,14.4 8.7,13.9075132 8.7,13.3 C8.7,12.6924868 9.19248678,12.2 9.8,12.2 Z M5.6,6.6 C6.20751322,6.6 6.7,7.09248678 6.7,7.7 C6.7,8.30751322 6.20751322,8.8 5.6,8.8 C4.99248678,8.8 4.5,8.30751322 4.5,7.7 C4.5,7.09248678 4.99248678,6.6 5.6,6.6 Z M9.8,6.6 C10.4075132,6.6 10.9,7.09248678 10.9,7.7 C10.9,8.30751322 10.4075132,8.8 9.8,8.8 C9.19248678,8.8 8.7,8.30751322 8.7,7.7 C8.7,7.09248678 9.19248678,6.6 9.8,6.6 Z M5.6,1 C6.20751322,1 6.7,1.49248678 6.7,2.1 C6.7,2.70751322 6.20751322,3.2 5.6,3.2 C4.99248678,3.2 4.5,2.70751322 4.5,2.1 C4.5,1.49248678 4.99248678,1 5.6,1 Z M9.8,1 C10.4075132,1 10.9,1.49248678 10.9,2.1 C10.9,2.70751322 10.4075132,3.2 9.8,3.2 C9.19248678,3.2 8.7,2.70751322 8.7,2.1 C8.7,1.49248678 9.19248678,1 9.8,1 Z"
                    id="Shape"
                    fill="#DBDCE1"
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(DraggingSVG);
