import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, alpha, InputAdornment, IconButton } from '@material-ui/core';
import SettingContentLayout from 'permission/common/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import InputText from 'components/inputs/InputText';
import LDBasePortal from 'components/selects/LDBasePortal';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import {
    SOURCE_TYPE_OPTIONS,
    SOURCE_STATUS,
    TUNNEL_TYPE_OPTIONS,
    SOURCE_OPTIONS,
    REPLICATION_METHODS_OPTIONS,
    TUNNEL_OPTIONS,
    REPLICATION_PLUGIN_OPTIONS,
    REPLICATION_TYPES
} from 'const';
import InputCopy from 'components/inputs/InputViewCopyOnly';
import ButtonBase from 'components/button/Base';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import * as integrationActions from 'integrations/actions';
import { useDispatch } from 'react-redux';
import InfoIconSVG from 'assets/images/svg/InfoIconSVG';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import Spinner from 'components/spinner/Base';
import { useSourceInfoUrl } from 'hooks/integration';
import Tooltip from 'components/tooltip/Base';
import IOSSwitch from 'components/switches/IOS';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import CreatableSelect from 'react-select/creatable';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        position: 'relative'
    },
    createWrapper: {
        height: `calc(100%)`,
        maxHeight: `calc(100%)`,
        overflowY: 'auto',
        padding: theme.spacing(5)
    },
    create: {
        width: '662px',
        margin: 'auto',
        border: `1px solid ${theme.colors.border}`,
        padding: theme.spacing(5)
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    link: {
        color: theme.colors.dodgerBlue,
        textDecoration: 'underline'
    },
    warning: {
        background: theme.colors.cherub,
        borderRadius: 4,
        padding: 9
    },
    success: {
        background: theme.colors.grannyApple,
        borderRadius: 4,
        padding: 9
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: alpha(theme.colors.white, 0.5),
        zIndex: 2
    },
    tunnel: {
        background: theme.colors.ghostwhite,
        padding: theme.spacing(3),
        borderRadius: 4
    },
    dropdownClassName: {
        background: theme.colors.white,
        minWidth: 320
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    creatable: {
        '& > div': {
            border: `1px solid ${theme.colors.border}`,
            boxShadow: 'none',
            outline: 'none',
            '&:focus': {
                border: `1px solid ${theme.palette.primary.main}`,
                borderColor: theme.colors.border
            },
            '&:hover': {
                border: `1px solid ${theme.colors.border}`
            }
        }
    }
}));

function Source() {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();

    const isEdit = React.useMemo(() => {
        return !!id;
    }, [id]);

    const [sourceType, setSourceType] = React.useState(SOURCE_OPTIONS?.[0]);
    const [source, setSource] = React.useState(null);
    const [title, setTitle] = React.useState(`Untitled Source`);
    const sourceInfoUrl = useSourceInfoUrl();
    const [tunnelMethod, setTunnelMethod] = React.useState(TUNNEL_OPTIONS?.[0]);
    const [ssl, setSsl] = React.useState(false);
    const [schemas, setSchemas] = React.useState([]);
    const [replicationMethod, setReplicationMethod] = React.useState(REPLICATION_METHODS_OPTIONS?.[0]);

    const [showPassword, setShowPassword] = React.useState({
        password: false,
        tunnel_user_password: false
    });

    const handleBack = React.useCallback(() => {
        history.push('/integration-settings/connectors?tab=source');
    }, [history]);

    const isMySql = React.useMemo(() => {
        return sourceType?.value === SOURCE_TYPE_OPTIONS?.MYSQL;
    }, [sourceType]);

    const isPostgres = React.useMemo(() => {
        return sourceType?.value === SOURCE_TYPE_OPTIONS?.POSTGRES;
    }, [sourceType]);

    const isUsingTunnel = React.useMemo(() => {
        return [SOURCE_TYPE_OPTIONS.GOOGLE_SHEET]?.includes(sourceType?.value)
            ? false
            : tunnelMethod?.value !== TUNNEL_TYPE_OPTIONS.NO_TUNNEL;
    }, [tunnelMethod, sourceType]);

    const isReplicationCDC = React.useMemo(() => {
        return replicationMethod?.value === REPLICATION_TYPES.CDC;
    }, [replicationMethod]);

    const FormSchema = React.useMemo(() => {
        return object().shape({
            name: string()?.required('This field is required'),
            connector: string(),
            spreadsheetId:
                sourceType?.value === SOURCE_TYPE_OPTIONS?.GOOGLE_SHEET
                    ? string()?.required('This field is required')
                    : string(),
            database: isMySql || isPostgres ? string()?.required('This field is required') : string(),
            port: isMySql || isPostgres ? number()?.required('This field is required') : number(),
            host: isMySql || isPostgres ? string()?.required('This field is required') : string(),
            username: isMySql || isPostgres ? string()?.required('This field is required') : string(),
            password: isMySql || isPostgres ? string()?.required('This field is required') : string(),
            jdbc_url_params: string(),
            replication_method: string(),
            replication_method_plugin:
                isPostgres && isReplicationCDC ? string()?.required('This field is required') : string(),
            replication_method_publication:
                isPostgres && isReplicationCDC ? string()?.required('This field is required') : string(),
            replication_method_replication_slot:
                isPostgres && isReplicationCDC ? string()?.required('This field is required') : string(),

            //optional
            tunnel_host: isUsingTunnel ? string()?.required('This field is required') : string(),
            tunnel_method: string(),
            tunnel_port: isUsingTunnel ? string()?.required('This field is required') : number(),
            tunnel_user: isUsingTunnel ? string()?.required('This field is required') : string(),
            ssh_key:
                tunnelMethod?.value === TUNNEL_TYPE_OPTIONS.SSH_KEY_AUTH
                    ? string()?.required('This field is required')
                    : string(),
            tunnel_user_password:
                tunnelMethod?.value === TUNNEL_TYPE_OPTIONS.SSH_PASSWORD_AUTH
                    ? string()?.required('This field is required')
                    : string()
        });
    }, [sourceType, isMySql, tunnelMethod, isUsingTunnel, isPostgres, isReplicationCDC]);

    const formik = useFormik({
        initialValues: {
            name: isEdit ? source?.name : `Untitled Source`,
            connector: isEdit ? source?.connector : sourceType.value,
            spreadsheetId: isEdit ? source?.configuration.spreadsheetId : ``,
            database: isEdit ? source?.configuration?.database : ``,
            port: isEdit ? source?.configuration?.port : isPostgres ? 5432 : 3306,
            host: isEdit ? source?.configuration?.host : ``,
            username: isEdit ? source?.configuration?.username : ``,
            password: isEdit ? source?.configuration?.password : ``,
            jdbc_url_params: ``,
            //optional
            tunnel_host: ``,
            tunnel_method: ``,
            tunnel_port: ``,
            tunnel_user: ``,
            ssh_key: ``,
            tunnel_user_password: ``,
            replication_method_plugin: REPLICATION_PLUGIN_OPTIONS?.[0]?.value,
            replication_method_publication: ``,
            replication_method_replication_slot: ``
        },
        validationSchema: FormSchema,
        onSubmit: (values, { setSubmitting, setStatus, setFieldError }) => {
            setStatus({ error: null });
            // setSubmitting(true);

            let sourceBody = {};

            if (sourceType.value === SOURCE_TYPE_OPTIONS.GOOGLE_SHEET) {
                sourceBody = {
                    name: values.name,
                    connector: sourceType.value,
                    configuration: {
                        spreadsheetId: values.spreadsheetId
                    }
                };
            } else if (sourceType.value === SOURCE_TYPE_OPTIONS.MYSQL) {
                sourceBody = {
                    name: values.name,
                    connector: sourceType.value,
                    configuration: {
                        ssl,
                        database: values.database,
                        host: values.host,
                        port: values.port,
                        username: values.username,
                        password: values.password,
                        replication_method: replicationMethod.value,
                        tunnel_method: {
                            // optional
                            tunnel_method: tunnelMethod?.value
                        }
                    }
                };

                if (values?.jdbc_url_params) {
                    sourceBody = {
                        ...sourceBody,
                        configuration: {
                            ...sourceBody?.configuration,
                            jdbc_url_params: values.jdbc_url_params
                        }
                    };
                }

                if (tunnelMethod?.value !== TUNNEL_TYPE_OPTIONS.NO_TUNNEL) {
                    sourceBody = {
                        ...sourceBody,
                        configuration: {
                            ...sourceBody?.configuration,
                            tunnel_method: {
                                ...sourceBody?.configuration?.tunnel_method,
                                tunnel_host: values.tunnel_host,
                                tunnel_port: values.tunnel_port,
                                tunnel_user: values.tunnel_user,
                                ssh_key: values.ssh_key,
                                tunnel_user_password: values.tunnel_user_password
                            }
                        }
                    };
                }
            } else if (sourceType.value === SOURCE_TYPE_OPTIONS.POSTGRES) {
                sourceBody = {
                    name: values.name,
                    connector: sourceType.value,
                    configuration: {
                        ssl,
                        database: values.database,
                        host: values.host,
                        port: values.port,
                        username: values.username,
                        password: values.password,
                        replication_method: {
                            method:
                                replicationMethod.value === REPLICATION_TYPES.STANDARD
                                    ? isPostgres
                                        ? 'Standard'
                                        : REPLICATION_TYPES.STANDARD
                                    : replicationMethod.value
                        },
                        tunnel_method: {
                            tunnel_method: tunnelMethod?.value
                        }
                    }
                };

                if (schemas?.length) {
                    sourceBody = {
                        ...sourceBody,
                        configuration: {
                            ...sourceBody?.configuration,
                            schemas: schemas?.map(o => o?.value)
                        }
                    };
                }

                if (replicationMethod?.value === REPLICATION_TYPES.CDC) {
                    sourceBody = {
                        ...sourceBody,
                        configuration: {
                            ...sourceBody?.configuration,
                            replication_method: {
                                ...sourceBody?.configuration?.replication_method,
                                plugin:
                                    replicationMethod.value === REPLICATION_TYPES.STANDARD
                                        ? ``
                                        : values.replication_method_plugin,
                                publication:
                                    replicationMethod.value === REPLICATION_TYPES.STANDARD
                                        ? ``
                                        : values.replication_method_publication,
                                replication_slot:
                                    replicationMethod.value === REPLICATION_TYPES.STANDARD
                                        ? ``
                                        : values.replication_method_replication_slot
                            }
                        }
                    };
                }

                if (tunnelMethod?.value !== TUNNEL_TYPE_OPTIONS.NO_TUNNEL) {
                    sourceBody = {
                        ...sourceBody,
                        configuration: {
                            ...sourceBody?.configuration,
                            tunnel_method: {
                                ...sourceBody?.configuration?.tunnel_method,
                                tunnel_host: values.tunnel_host,
                                tunnel_port: values.tunnel_port,
                                tunnel_user: values.tunnel_user,
                                ssh_key: values.ssh_key,
                                tunnel_user_password: values.tunnel_user_password
                            }
                        }
                    };
                }
            }

            console.log('sourceBody', sourceBody);
            // return;

            if (!source) {
                dispatch(
                    integrationActions.createSource({
                        source: sourceBody,
                        error: message => {
                            console.log('create source failed');
                            setSubmitting(false);
                        },
                        success: newSource => {
                            setTitle(values.name);
                            setSource(newSource);
                            setSubmitting(false);
                            if (newSource?.lastCheckStatus !== 'succeeded') {
                                // setFieldError('spreadsheetId', newSource?.lastCheckMessage);
                            } else {
                                handleBack();
                            }
                        }
                    })
                );
            } else {
                dispatch(
                    integrationActions.updateSource({
                        sId: source?.id,
                        source: sourceBody,
                        error: message => {
                            console.log('update source failed');
                            setSubmitting(false);
                        },
                        success: newSource => {
                            setTitle(values.name);
                            setSource(newSource);
                            setSubmitting(false);
                            if (newSource?.lastCheckStatus !== 'succeeded') {
                                // setFieldError('spreadsheetId', newSource?.lastCheckMessage);
                            } else {
                                sendManualTrack({ type: 'Save And Check Connection' });

                                handleBack();
                            }
                        }
                    })
                );
            }

            setStatus({ error: null });
        }
    });

    React.useEffect(() => {
        dispatch(integrationActions.getSourceInfo({ success: () => {}, error: () => {} }));
    }, [dispatch]);

    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue
    } = formik;

    React.useEffect(() => {
        if (id) {
            dispatch(
                integrationActions.getSource({
                    sId: id,
                    success: nSource => {
                        setTitle(nSource?.name);
                        setSource(nSource);
                        setFieldValue('name', nSource?.name);
                        setFieldValue('connector', nSource?.connector);
                        setFieldValue('spreadsheetId', nSource?.configuration.spreadsheetId);
                    },
                    error: () => {}
                })
            );
        }
    }, [id, dispatch, setFieldValue]);

    const openLink = React.useCallback(() => {
        switch (sourceType?.value) {
            case SOURCE_TYPE_OPTIONS.GOOGLE_SHEET:
                return window.open(
                    `https://help.gridly.com/hc/en-us/articles/5281178274961-Gridly-connector-Google-Sheets`,
                    `_blank`
                );
            case SOURCE_TYPE_OPTIONS.POSTGRES:
                return window.open(
                    `https://help.gridly.com/hc/en-us/articles/6035345686417-Gridly-Connector-PostgreSQL`,
                    `_blank`
                );

            case SOURCE_TYPE_OPTIONS.MYSQL:
                return window.open(
                    `https://help.gridly.com/hc/en-us/articles/5619940727697-Gridly-connector-MySQL`,
                    `_blank`
                );

            default:
                return;
        }
    }, [sourceType]);

    return (
        <SettingContentLayout name={title} onBack={handleBack}>
            <form onSubmit={handleSubmit} className={classes.root}>
                {isSubmitting && (
                    <Grid item className={`${classes.loading}`}>
                        <Spinner size={55} thick={7} />
                    </Grid>
                )}
                <Grid
                    container
                    className={classes.createWrapper}
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                >
                    <Grid item className={classes.create}>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Typography variant="h3">{t('set_up_the_source')}</Typography>
                            </Grid>
                            <Grid item>
                                <Trans
                                    t={t}
                                    i18nKey="connector_setup_the_source_description"
                                    defaults="<normal>Follow the instructions below to set up a source.<br>With different types of sources, there will be different settings.</normal>"
                                    components={{
                                        normal: <Typography variant="caption" />,
                                        br: <br />
                                    }}
                                />
                            </Grid>
                            <Grid item container direction="column" spacing={1}>
                                <Grid item>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_1')}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">{t('source_name')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">{t('give_source_name')}</Typography>
                                </Grid>
                                <Grid item>
                                    <InputText
                                        autoFocus
                                        name="name"
                                        height={36}
                                        placeholder={'e.g Google sheets #1'}
                                        value={values.name}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.name && touched.name}
                                        errorText={errors.name}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container direction="column" spacing={1}>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item>
                                            <Grid container direction="row" spacing={2} alignItems="center">
                                                <Grid item className={classes.numberStep}>
                                                    {t('global_2')}
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">{t('source_type')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Typography
                                                onClick={openLink}
                                                variant="body2"
                                                style={{
                                                    color: theme.palette.primary.main,
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {t(`how_to_do_that`)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder={'Select a source type'}
                                        menuPlaceholder={'Find a source type'}
                                        options={SOURCE_OPTIONS}
                                        onChange={option => {
                                            if (option?.value === SOURCE_TYPE_OPTIONS.POSTGRES) {
                                                setFieldValue('port', 5432);
                                            }
                                            setSourceType(option);
                                        }}
                                        defaultValue={sourceType}
                                        isMulti={false}
                                    />
                                </Grid>
                            </Grid>

                            {sourceType?.value === SOURCE_TYPE_OPTIONS.GOOGLE_SHEET && (
                                <Grid item container direction="column" spacing={3}>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Grid container direction="row" spacing={2} alignItems="center">
                                                    <Grid item className={classes.numberStep}>
                                                        {t('global_3')}
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {t('authorize_google_account')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="caption">
                                                {t('connector_share_google_sheet')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <InputCopy value={sourceInfoUrl} style={{ height: 36 }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="caption">{t('input_yout_sheet_id')}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item xs>
                                                    <InputText
                                                        name="spreadsheetId"
                                                        height={36}
                                                        placeholder={t('input_yout_sheet_id_here')}
                                                        value={values.spreadsheetId}
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.spreadsheetId && touched.spreadsheetId}
                                                        errorText={errors.spreadsheetId}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {[SOURCE_TYPE_OPTIONS.MYSQL, SOURCE_TYPE_OPTIONS.POSTGRES]?.includes(sourceType?.value) && (
                                <>
                                    <Grid item container direction="column" spacing={3}>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <Grid container direction="row" spacing={2} alignItems="center">
                                                        <Grid item className={classes.numberStep}>
                                                            {t('global_3')}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                Database information
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" alignItems="center" spacing={2}>
                                                <Grid item xs={6}>
                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">Host</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" spacing={3}>
                                                                <Grid item xs>
                                                                    <InputText
                                                                        name="host"
                                                                        height={36}
                                                                        placeholder={'Hostname'}
                                                                        value={values.host}
                                                                        disabled={isSubmitting}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.host && touched.host}
                                                                        errorText={errors.host}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">Port</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" spacing={3}>
                                                                <Grid item xs>
                                                                    <InputText
                                                                        name="port"
                                                                        type="number"
                                                                        height={36}
                                                                        placeholder={'Port (e.g. 3306) '}
                                                                        value={values.port}
                                                                        disabled={isSubmitting}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.port && touched.port}
                                                                        errorText={errors.port}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid item container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">Database</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" spacing={3}>
                                                        <Grid item xs>
                                                            <InputText
                                                                name="database"
                                                                height={36}
                                                                placeholder={'Database name'}
                                                                value={values.database}
                                                                disabled={isSubmitting}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={errors.database && touched.database}
                                                                errorText={errors.database}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isPostgres && (
                                            <Grid item>
                                                <Grid item container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="body1">Schemas</Typography>
                                                        <Typography variant="caption">
                                                            The list of schemas to sync from. Defaults to user. Case
                                                            sensitive.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <CreatableSelect
                                                            options={schemas}
                                                            placeholder="Input list of schemas to sync from and press Enter"
                                                            className={classes.creatable}
                                                            onChange={(value, actionMeta) => {
                                                                switch (actionMeta.action) {
                                                                    case 'remove-value':
                                                                    case 'pop-value':
                                                                        if (actionMeta?.removedValue?.isFixed) {
                                                                            return;
                                                                        }
                                                                        break;
                                                                    case 'clear':
                                                                        value = schemas.filter(v => v?.isFixed);
                                                                        break;

                                                                    default:
                                                                        break;
                                                                }

                                                                setSchemas(value);
                                                            }}
                                                            components={{
                                                                IndicatorSeparator: null,
                                                                DropdownIndicator: null,
                                                                Menu: () => null
                                                            }}
                                                            styles={{
                                                                multiValue: (styles, { data }) => {
                                                                    return {
                                                                        ...styles,
                                                                        backgroundColor: alpha(
                                                                            theme.palette.primary.main,
                                                                            0.15
                                                                        )
                                                                    };
                                                                },
                                                                multiValueLabel: (styles, { data }) => ({
                                                                    ...styles,
                                                                    color: theme.palette.primary.main,
                                                                    fontWeight: 500
                                                                })
                                                            }}
                                                            value={schemas}
                                                            isClearable={false}
                                                            isMulti
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid item container direction="column" spacing={3}>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid item>
                                                    <Grid container direction="row" spacing={2} alignItems="center">
                                                        <Grid item className={classes.numberStep}>
                                                            {t('global_4')}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                Database credentials
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container direction="row" alignItems="center" spacing={2}>
                                                <Grid item xs={6}>
                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">Username</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" spacing={3}>
                                                                <Grid item xs>
                                                                    <InputText
                                                                        name="username"
                                                                        height={36}
                                                                        placeholder={'Database username'}
                                                                        value={values.username}
                                                                        disabled={isSubmitting}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.username && touched.username}
                                                                        errorText={errors.username}
                                                                        autoComplete={'off'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {t('global_password')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" spacing={3}>
                                                                <Grid item xs>
                                                                    <InputText
                                                                        type={
                                                                            showPassword?.password ? 'text' : 'password'
                                                                        }
                                                                        name="password"
                                                                        height={36}
                                                                        placeholder={'Password to access'}
                                                                        value={values.password}
                                                                        disabled={isSubmitting}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.password && touched.password}
                                                                        errorText={errors.password}
                                                                        autoComplete={'off'}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        setShowPassword({
                                                                                            ...showPassword,
                                                                                            password: !showPassword?.password
                                                                                        });
                                                                                    }}
                                                                                    edge="end"
                                                                                >
                                                                                    {showPassword?.password ? (
                                                                                        <EyeCloseIconSVG />
                                                                                    ) : (
                                                                                        <EyeOpenIconSVG />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isMySql && (
                                            <Grid item>
                                                <Grid item container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="body1" display="inline">
                                                            JDBC URL Params
                                                        </Typography>{' '}
                                                        <Typography variant="caption" display="inline">
                                                            (Optional)
                                                        </Typography>
                                                        <Tooltip title="Additional properties to pass to the jdbc url string when connecting to the database formatted as 'key=value' pairs separated by the symbol '&'. (example: key1=value1&key2=value2&key3=value3)">
                                                            <span
                                                                style={{
                                                                    position: 'relative',
                                                                    top: 2,
                                                                    marginLeft: theme.spacing(1)
                                                                }}
                                                            >
                                                                <QuestionSVG />
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <InputText
                                                            name="jdbc_url_params"
                                                            height={36}
                                                            placeholder={
                                                                '(example: key1=value1&key2=value2&key3=value3)'
                                                            }
                                                            value={values.jdbc_url_params}
                                                            disabled={isSubmitting}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.jdbc_url_params && touched.jdbc_url_params}
                                                            errorText={errors.jdbc_url_params}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                                wrap="nowrap"
                                            >
                                                <Grid item>
                                                    <IOSSwitch
                                                        checked={ssl}
                                                        onChange={() => setSsl(!ssl)}
                                                        className={classes.switchIcon}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography display="inline" variant="body1">
                                                        SSL Connection
                                                    </Typography>
                                                    {'  '}
                                                    <Typography display="inline" variant="caption">
                                                        Encrypt data using SSL
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid item container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1" display="inline">
                                                        Replication Method
                                                    </Typography>{' '}
                                                    <Tooltip title="Replication method to use for extracting data from the database. STANDARD replication requires no setup on the DB side but will not be able to represent deletions incrementally. CDC uses the Binlog to detect inserts, updates, and deletes. This needs to be configured on the source database itself.">
                                                        <span
                                                            style={{
                                                                position: 'relative',
                                                                top: 2,
                                                                marginLeft: theme.spacing(1)
                                                            }}
                                                        >
                                                            <QuestionSVG />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <LDBasePortal
                                                        ddPlaceholder={'Select replication method'}
                                                        menuPlaceholder={'Find replication method'}
                                                        options={REPLICATION_METHODS_OPTIONS}
                                                        onChange={option => setReplicationMethod(option)}
                                                        defaultValue={replicationMethod}
                                                        isMulti={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {isPostgres && replicationMethod?.value === REPLICATION_TYPES.CDC && (
                                            <Grid item>
                                                <Grid container direction="column" spacing={3}>
                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1" display="inline">
                                                                Plugin
                                                            </Typography>{' '}
                                                            <Tooltip title="A logical decoding plug-in installed on the PostgreSQL server. `pgoutput` plug-in is used by default. If replication table contains a lot of big jsonb values it is recommended to use `wal2json` plug-in.">
                                                                <span
                                                                    style={{
                                                                        position: 'relative',
                                                                        top: 2,
                                                                        marginLeft: theme.spacing(1)
                                                                    }}
                                                                >
                                                                    <QuestionSVG />
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <LDBasePortal
                                                                ddPlaceholder={'Select replication plugin'}
                                                                menuPlaceholder={'Find replication plugin'}
                                                                options={REPLICATION_PLUGIN_OPTIONS}
                                                                onChange={option => {
                                                                    setFieldValue(
                                                                        'replication_method_plugin',
                                                                        option?.value
                                                                    );
                                                                }}
                                                                defaultValue={REPLICATION_PLUGIN_OPTIONS?.find(
                                                                    option =>
                                                                        option?.value ===
                                                                        values.replication_method_plugin
                                                                )}
                                                                isMulti={false}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1" display="inline">
                                                                Replication slot
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid item>
                                                            <InputText
                                                                name="replication_method_replication_slot"
                                                                height={36}
                                                                placeholder={`A plug-in logical replication slot`}
                                                                value={values.replication_method_replication_slot}
                                                                disabled={isSubmitting}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={
                                                                    errors.replication_method_replication_slot &&
                                                                    touched.replication_method_replication_slot
                                                                }
                                                                errorText={errors.replication_method_replication_slot}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1" display="inline">
                                                                Publication
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid item>
                                                            <InputText
                                                                name="replication_method_publication"
                                                                height={36}
                                                                placeholder={`A Postgres publication used for consuming changes`}
                                                                value={values.replication_method_publication}
                                                                disabled={isSubmitting}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                error={
                                                                    errors.replication_method_publication &&
                                                                    touched.replication_method_publication
                                                                }
                                                                errorText={errors.replication_method_publication}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Grid item>
                                            <Grid className={classes.tunnel} container direction="column">
                                                <Grid item className={classes.mb2}>
                                                    <Grid container alignItems="center" direction="row" spacing={3}>
                                                        <Grid item>
                                                            <Typography variant="body1">SSH tunnel method</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <LDBasePortal
                                                                ddPlaceholder={'Select tunnel method'}
                                                                menuPlaceholder={'Find tunnel method'}
                                                                options={TUNNEL_OPTIONS}
                                                                onChange={option => setTunnelMethod(option)}
                                                                defaultValue={tunnelMethod}
                                                                isMulti={false}
                                                                dropdownClassName={classes.dropdownClassName}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.mb2}>
                                                    <Typography variant="caption">
                                                        Whether to initiate an SSH tunnel before connecting to the
                                                        database, and if so, which kind of authentication to use.
                                                    </Typography>
                                                </Grid>
                                                {tunnelMethod?.value !== TUNNEL_TYPE_OPTIONS.NO_TUNNEL && (
                                                    <Grid item container direction="column" spacing={4}>
                                                        <Grid item container direction="column" spacing={2}>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    SSH Tunnel jump server host
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <InputText
                                                                    name="tunnel_host"
                                                                    height={36}
                                                                    placeholder={'Hostname of the jump server host'}
                                                                    value={values.tunnel_host}
                                                                    disabled={isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tunnel_host && touched.tunnel_host}
                                                                    errorText={errors.tunnel_host}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container direction="column" spacing={2}>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    SSH Connection port
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <InputText
                                                                    type="number"
                                                                    name="tunnel_port"
                                                                    height={36}
                                                                    placeholder={
                                                                        'Port on the proxy/jump server (e.g 22)'
                                                                    }
                                                                    value={values.tunnel_port}
                                                                    disabled={isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tunnel_port && touched.tunnel_port}
                                                                    errorText={errors.tunnel_port}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item container direction="column" spacing={2}>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    SSH Login username
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <InputText
                                                                    name="tunnel_user"
                                                                    height={36}
                                                                    placeholder={'OS-level username'}
                                                                    value={values.tunnel_user}
                                                                    disabled={isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={errors.tunnel_user && touched.tunnel_user}
                                                                    errorText={errors.tunnel_user}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        {tunnelMethod?.value === TUNNEL_TYPE_OPTIONS.SSH_KEY_AUTH && (
                                                            <Grid item container direction="column" spacing={2}>
                                                                <Grid item>
                                                                    <Typography variant="body1">
                                                                        SSH Private key
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <InputText
                                                                        name="ssh_key"
                                                                        placeholder={'OS-level username'}
                                                                        value={values.ssh_key}
                                                                        disabled={isSubmitting}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={errors.ssh_key && touched.ssh_key}
                                                                        errorText={errors.ssh_key}
                                                                        multiline
                                                                        height={70}
                                                                        rows={3}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}

                                                        {tunnelMethod?.value ===
                                                            TUNNEL_TYPE_OPTIONS.SSH_PASSWORD_AUTH && (
                                                            <Grid item container direction="column" spacing={2}>
                                                                <Grid item>
                                                                    <Typography variant="body1">
                                                                        {t('global_password')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <InputText
                                                                        type={
                                                                            showPassword?.tunnel_user_password
                                                                                ? 'text'
                                                                                : 'password'
                                                                        }
                                                                        name="tunnel_user_password"
                                                                        height={36}
                                                                        placeholder={'Password to access'}
                                                                        value={values.tunnel_user_password}
                                                                        disabled={isSubmitting}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            errors.tunnel_user_password &&
                                                                            touched.tunnel_user_password
                                                                        }
                                                                        errorText={errors.tunnel_user_password}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        setShowPassword({
                                                                                            ...showPassword,
                                                                                            tunnel_user_password: !showPassword?.tunnel_user_password
                                                                                        });
                                                                                    }}
                                                                                    edge="end"
                                                                                >
                                                                                    {showPassword?.tunnel_user_password ? (
                                                                                        <EyeCloseIconSVG />
                                                                                    ) : (
                                                                                        <EyeOpenIconSVG />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="body2">
                                                <strong>Note:</strong> If your Database endpoint or SSH jump host is
                                                behind a firewall/private network, you need to whitelist our IP address:{' '}
                                                <strong>18.192.141.172/32</strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={source ? 'space-between' : 'flex-end'}
                                    wrap="nowrap"
                                    spacing={3}
                                >
                                    {source && (
                                        <Grid
                                            item
                                            className={
                                                source?.lastCheckStatus === SOURCE_STATUS.FAILED
                                                    ? classes.warning
                                                    : classes.success
                                            }
                                        >
                                            <Grid container spacing={1} direction="column">
                                                <Grid item>
                                                    <Grid container spacing={1} direction="row" alignItems="center">
                                                        <Grid item style={{ display: 'flex' }}>
                                                            {source?.lastCheckStatus === SOURCE_STATUS.FAILED ? (
                                                                <InfoIconSVG color={theme.colors.snackBarWarning} />
                                                            ) : (
                                                                <CheckIconSVG color={theme.colors.seaGreen} />
                                                            )}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {source?.lastCheckStatus === SOURCE_STATUS.FAILED
                                                                    ? 'Your source is created but authorization was failed'
                                                                    : 'Your source is created and authorized successfully'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {source?.lastCheckStatus === SOURCE_STATUS.FAILED && (
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            {source?.lastCheckMessage}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <ButtonBase
                                            disabled={!isValid || isSubmitting}
                                            width={200}
                                            height={36}
                                            variant="contained"
                                            type="submit"
                                        >
                                            {t('save_and_check_connection')}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </SettingContentLayout>
    );
}

export default Source;
