import React from 'react';
import { Grid } from '@material-ui/core';
import Datetime from '../components/Datetime';

function BooleanDelete({ previousData }) {
    return (
        <Grid container alignItems="center">
            <Grid item>
                <Datetime data={previousData} state={'delete'} />
            </Grid>
        </Grid>
    );
}

export default React.memo(BooleanDelete);
