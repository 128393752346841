import { getFullName } from 'utils/user';
import { ACTIVE, DEFAULT_ROLE_USER } from 'const';
import { useSelector } from 'react-redux';
import { useMembersInSelectedGroup } from 'hooks/permission/group';
import { useCurrentUser } from 'hooks/auth';

export function useMemberList() {
    const members = useSelector(state => state.permission.members?.list);
    const currentUser = useCurrentUser();
    const mappedMembers = members?.map(member => ({
        ...member,
        fullName: getFullName(member),
        role: member?.roles?.[0] || DEFAULT_ROLE_USER
    }));

    return currentUser?.ldManager || currentUser?.ldUser
        ? mappedMembers
        : mappedMembers?.filter(member => !member.ldUser);
}

export function useMemberMappingById() {
    const members = useMemberList();
    return members?.reduce((obj, member) => {
        obj[member?.id] = member;
        return obj;
    }, {});
}

export function useMemberMappingByEmail() {
    const members = useMemberList();
    return members?.reduce((obj, member) => {
        obj[member?.email] = member;
        return obj;
    }, {});
}

export function useInternalMemberList() {
    const permissionMember = useSelector(state => state.permission.members);
    const members = permissionMember?.list || [];
    return members
        ?.filter(member => member.ldUser || member.ldManager)
        ?.map(member => ({
            ...member,
            fullName: getFullName(member),
            role: member?.roles?.[0] || DEFAULT_ROLE_USER
        }));
}

export function useLdUserEmailMap() {
    const internalMemberList = useInternalMemberList();
    let map = {};
    for (const member of internalMemberList || []) {
        map = {
            ...map,
            [member?.email]: member?.ldUser
        };
    }
    return map;
}

export function useLdMemberMappingByEmail() {
    const internalMemberList = useInternalMemberList();
    return internalMemberList?.reduce((obj, member) => {
        obj[member?.email] = member;
        return obj;
    }, {});
}

export function usePermissionMembers() {
    return useSelector(state => state.permission.members);
}

export function useMemberEmails() {
    const members = usePermissionMembers();
    return members?.list?.map(member => member?.email);
}

export function useIsFetchingMemberList() {
    const members = usePermissionMembers();
    return members?.isFetching;
}

export function useWorkspaceRolesOfSingleUser({ userId }) {
    const members = usePermissionMembers();
    return members?.workspacesRoles?.[userId];
}

export function useInvitedMembers() {
    const members = usePermissionMembers();
    return members?.invitedMembers;
}

export function useNotInvitedMembersInSelectedGroup() {
    const members = useMemberList();
    const groupMembers = useMembersInSelectedGroup();
    const groupMemberIds = groupMembers?.map(member => member?.id);
    return members?.filter(member => !groupMemberIds.includes(member.id));
}

export function useActiveMemberList() {
    const members = useMemberList();
    return members?.filter(member => member.state === ACTIVE);
}
