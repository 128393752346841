import { useSelector } from 'react-redux';

export function useTmList() {
    return useSelector(state => state.tm?.list);
}

export function useTMData() {
    const tmList = useTmList();

    return tmList?.[0];
}

export function useTMList() {
    const tmList = useTmList();

    return tmList?.filter(tm => !tm?.isDeleted);
}

export function useTMIsActionInProgress() {
    return useSelector(state => state.tm?.isActionInProgress);
}

export function useTMIsFetching() {
    return useSelector(state => state.tm?.isFetching);
}

export function useIsShowLastPopupCreate() {
    return useSelector(state => state.tm?.isShowLastPopupCreate);
}

export function useIsCreatingTM() {
    return useSelector(state => state.tm?.isCreatingTM);
}

export function useTmSources() {
    return useSelector(state => state.tm?.tmSources);
}

export function useIsFetchingSourcesByTmId({ tmId }) {
    const tmSources = useTmSources();
    return tmSources?.[tmId]?.isFetching;
}

export function useTmSourcesByTmId({ tmId }) {
    const tmSources = useTmSources();
    return tmSources?.[tmId]?.sources || [];
}

export function useListTranslationMem() {
    return useSelector(state => state.tm?.listSegments);
}

export function useTmSourceSelected() {
    return useSelector(state => state.tm?.sourceSelected);
}

export function useTmTargetsSelected() {
    return useSelector(state => state.tm?.targetsSelected);
}
