import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CheckBox from 'components/checkbox/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import { makeStyles } from '@material-ui/core/styles';
import { INPUT_PADDING_LEFT } from 'const/style';
import { isNotContainAllCheckingArray } from 'utils/object';
import PopperMenu from 'components/menus/Popper';
import OptionMenu from './OptionMenu';
import isEmpty from 'lodash/isEmpty';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import GridIcon from 'grids/GridIcon';
import ViewIcon from 'gridUI/views/ViewIcon';
import hexToRgba from 'hex-to-rgba';
import BranchSVG from 'assets/images/svg/BranchSVG';
import DropdownSVG from 'assets/images/svg/DropdownSVG';
import { useTranslation } from 'react-i18next';

const CELL_PADDING = INPUT_PADDING_LEFT + 1;
const useStyles = makeStyles(theme => ({
    root: {
        minHeight: `80vh`,
        borderRadius: 4,
        overflow: 'hidden',
        color: theme.colors.primaryText,
        border: `1px solid ${theme.colors.border}`,
        '& .ReactVirtualized__Table__row': {
            '&:hover': {
                backgroundColor: 'rgba(74, 145, 226, 0.05)'
            }
        },

        '& .header-role': {
            marginLeft: CELL_PADDING
        },
        '& .cell-role': {
            paddingLeft: CELL_PADDING,

            '& .select': {
                marginLeft: CELL_PADDING * -1
            }
        }
    },
    ellipsis: {
        overflow: `hidden`,
        whiteSpace: `nowrap`,
        textOverflow: `ellipsis`
    },
    accessView: {
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.8),
        border: `1px solid ${theme.colors.fuchsiaBlue}`,
        borderRadius: '50%',
        '& svg': {
            width: 10,
            color: theme.colors.white
        }
    },
    dpFlex: {
        display: 'flex'
    }
}));

const HeaderSelectRender = ({ allIds, resetCheckedList, checkedList, options, selectedOption, onOptionChange }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleOnChange = e => {
        if (e.target.checked) {
            if (isEmpty(allIds)) return;
            resetCheckedList(allIds);
        } else {
            resetCheckedList([]);
        }
    };

    const handleOptionChange = option => {
        onOptionChange(option);
        setAnchorEl(null);
    };

    return (
        <Grid container alignItems="center" spacing={0} wrap="nowrap">
            <Grid item>
                <CheckBox
                    checked={
                        !isEmpty(allIds) && !isNotContainAllCheckingArray({ mainArr: checkedList, checkArr: allIds })
                    }
                    onChange={handleOnChange}
                />
            </Grid>

            <Grid item style={{ display: 'flex', cursor: 'pointer', position: 'relative', left: -5 }}>
                <ArrowDownSVG onClick={handleClick} />
                {anchorEl && (
                    <PopperMenu
                        className={classes.commentPopup}
                        anchorEl={anchorEl}
                        placement={'bottom-start'}
                        id={`select-table-options`}
                    >
                        <OptionMenu
                            handleClickAway={handleClickAway}
                            options={options}
                            selectedOption={selectedOption}
                            onChange={handleOptionChange}
                        />
                    </PopperMenu>
                )}
            </Grid>
        </Grid>
    );
};

function WorkspaceListTable({
    data = [],
    checkedList,
    resetCheckedList,
    removeChecked,
    options,
    selectedOption,
    handleOptionChange,
    addChecked,
    className,
    ...rest
}) {
    const ROW_HEIGHT = 48;
    const { t } = useTranslation();
    const classes = useStyles();
    const [sorting, setSorting] = React.useState({});

    const dataSorted = React.useMemo(() => {
        const cloneData = [...data];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, data]);

    const allIds = React.useMemo(() => {
        return dataSorted.map(item => item?.id);
    }, [dataSorted]);

    const checkBoxRender = ({ rowData }) => {
        const { id } = rowData;

        const handleOnChange = e => {
            if (e.target.checked) {
                addChecked(id);
            } else {
                removeChecked(id);
            }
        };

        return (
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                    <CheckBox checked={checkedList.includes(id)} onChange={handleOnChange} />
                </Grid>
            </Grid>
        );
    };

    const workspaceRenderer = ({ rowData }) => {
        const { workspaceName } = rowData;
        return (
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: 'flex' }}>
                    <WorkspaceSVG />
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.ellipsis}>
                        {workspaceName}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const databaseRenderer = ({ rowData }) => {
        const { databaseName } = rowData;
        return (
            <Typography variant="body2" className={classes.ellipsis}>
                {databaseName}
            </Typography>
        );
    };

    const gridRenderer = ({ rowData }) => {
        const { parentGridName, gridName, gridProperties } = rowData;
        const grid = { customProperties: gridProperties };

        return (
            <Grid container alignItems="center">
                <Grid item className={classes.dpFlex} style={{ marginRight: 4 }}>
                    <GridIcon grid={grid} size={'small'} />
                </Grid>
                {parentGridName && (
                    <>
                        <Grid item>
                            <Typography variant="body2" className={classes.ellipsis}>
                                {parentGridName}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.dpFlex} style={{ transform: 'rotate(-90deg)' }}>
                            <DropdownSVG color="#C9C9CE" />
                        </Grid>
                        <Grid item className={classes.dpFlex} style={{ marginRight: 4 }}>
                            <BranchSVG />
                        </Grid>
                    </>
                )}
                <Grid item>{gridName}</Grid>
            </Grid>
        );
    };

    const viewRenderer = ({ rowData }) => {
        const { viewName, viewProperties } = rowData;
        const view = { customProperties: viewProperties };

        return (
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: 'flex' }}>
                    <ViewIcon view={view} size="small" />
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.ellipsis}>
                        {viewName}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const columns = [
        {
            label: (
                <HeaderSelectRender
                    allIds={allIds}
                    resetCheckedList={resetCheckedList}
                    checkedList={checkedList}
                    options={options}
                    selectedOption={selectedOption}
                    onOptionChange={handleOptionChange}
                />
            ),
            dataKey: 'select',
            width: 90,
            cellRenderer: checkBoxRender
        },
        {
            label: t('project'),
            dataKey: 'workspaceName',
            width: 200,
            sort: true,
            cellRenderer: workspaceRenderer
        },
        {
            label: t('database'),
            dataKey: 'databaseName',
            width: 200,
            sort: true,
            cellRenderer: databaseRenderer
        },
        {
            label: t('grid'),
            dataKey: 'gridName',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: gridRenderer
        },
        {
            label: t('view'),
            dataKey: 'viewName',
            sort: true,
            cellRenderer: viewRenderer
        }
    ];

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const handleRowClick = () => {};

    return (
        <Grid className={`${classes.root} ${className}`} {...rest}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={dataSorted}
                        columns={columns}
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT}
                        onRowClick={handleRowClick}
                        onSort={handleOnSort}
                    ></Table>
                )}
            </AutoSizer>
        </Grid>
    );
}

export default React.memo(WorkspaceListTable);
