import React from 'react';
import { FAKE_ROW, ROW_HEIGHT } from 'const/gridUI';
import CheckBox from 'components/checkbox/Base';
import * as roleConst from 'auth/roleConst';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { useRole } from 'hooks/auth/role';
import { useTotalSelectedRowsByRange } from 'hooks/gridUI';

function IndexHeader({ totalRecords }) {
    const dispatch = useDispatch();
    const roles = useRole();

    const isCreator = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_ROLE] === roleConst.CREATOR;
    }, [roles]);

    const totalSelectedRows = useTotalSelectedRowsByRange();

    const isSelected = React.useMemo(() => {
        return totalSelectedRows === totalRecords - FAKE_ROW;
    }, [totalSelectedRows, totalRecords]);

    const handleCheckbox = React.useCallback(
        e => {
            const value = e.target?.checked;
            if (value) {
                dispatch(
                    gridUIActions._rowSelection({
                        rowsRangeIndexes: [[0, totalRecords - FAKE_ROW - 1]],
                        oldRowIndexSelected: null,
                        isOverride: true
                    })
                );
            } else {
                dispatch(gridUIActions.resetSelection());
            }
        },
        [dispatch, totalRecords]
    );

    return (
        <div className="w-full flex justify-center items-center">
            {isCreator && (
                <CheckBox
                    className={`max-w-[${ROW_HEIGHT} max-h-[${ROW_HEIGHT}]]`}
                    checked={isSelected}
                    onChange={handleCheckbox}
                />
            )}
        </div>
    );
}

export default React.memo(IndexHeader);
