import React, { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import TMGuideSVG from 'assets/images/svg/TMGuideSVG';
import TMSearchNotFoundSVG from 'assets/images/svg/TMSearchNotFoundSVG';
import Spinner from 'components/spinner/Base';
import RecordNotification from 'components/notification/Record';
import * as columnTypes from 'const/columnTypes';
import hexToRgba from 'hex-to-rgba';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import {
    useColumnsSelected,
    useDbIdInGridUI,
    useDefaultAccessViewIdInGridUI,
    useIsReplacingText,
    useMetaData,
    useQuickFilters,
    useReplacingType,
    useRowsRangeIndexes,
    useSearchRecords,
    useSuccessReplaceAll,
    useWindowColumnStartIndex,
    useWindowColumnStopIndex,
    useWindowRowStartIndex,
    useWindowRowStopIndex
} from 'hooks/gridUI';
import SearchRecordToolbar from './SearchRecordToolbar';
// import { getFriendlyTime } from 'utils/datetime';
import YAYSVG from 'assets/images/svg/YAYSVG';
import { sendManualTrack } from 'tracker';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { useTranslation } from 'react-i18next';
import { isSelectingRange } from 'utils/gridUI/range';
import { searchRecordsFnc, setIsShowSearchRange, setSearchRange } from 'gridUI/actions';

const useStyles = ({ windowHeight }) => {
    return makeStyles(theme => ({
        root: {
            width: 400,
            height: windowHeight > 771 ? 660 : windowHeight - 125,
            borderRadius: 4,
            position: 'relative',
            overflow: 'hidden'
        },
        textCenter: {
            textAlign: 'center'
        },
        spinnerWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%'
        },
        layer: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: hexToRgba(theme.colors.white, 0.8),
            zIndex: 1
        },
        header: {
            height: 40,
            borderBottom: `1px solid ${theme.colors.border}`
        },
        body: {
            flex: 1,
            overflowY: 'auto',
            position: 'relative',
            width: '100%',
            overflowX: 'hidden'
        },
        recordItem: {
            padding: `${theme.spacing(2)}px 18px`
        },
        recordIndex: {
            color: theme.colors.secondaryText
        },
        cellItem: {
            width: '100%',
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
            '&:hover': {
                cursor: 'pointer',
                background: theme.colors.ghostwhite
            }
        },
        cellSelectedItem: {
            background: `${theme.colors.selectionColor} !important`
        },
        fullWidth: {
            width: '100%'
        },
        showMoreWrapper: {
            padding: theme.spacing(3),
            '&:hover': {
                cursor: 'pointer'
            }
        },
        showMoreText: {
            color: hexToRgba(theme.colors.dodgerBlue, 0.8)
        },
        showMoreBadge: {
            padding: `2px 4px`,
            borderRadius: 2,
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        },
        hl: {
            color: '#4A91E2'
        },
        resultCommon: {
            height: '100%',
            width: 400,
            position: 'relative'
        },
        looking: {
            fontSize: 14,
            color: theme.colors.secondaryText
        }
    }));
};

const LIMIT = 7;

const AREA = {
    RANGES: 'RANGES',
    GRID: 'GRID'
};

function SearchBox({ value, onClose, viewId, dbId, handleSearchChange, handleClearSearch }) {
    const timerRef = React.useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isFirstTime = React.useRef(true);
    const [isSearching, setIsSearching] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const metaData = useMetaData();
    const [matches, setMatches] = React.useState([]);
    const [selectedRecord, setSelectedRecord] = React.useState({});
    const [windowHeight, setWindowHeight] = React.useState(
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    );
    const [isSearchingMore, setIsSearchingMore] = React.useState(false);
    const classes = useStyles({ windowHeight })();
    const successReplaceAll = useSuccessReplaceAll();
    const replacingText = useIsReplacingText();
    const searchRecords = useSearchRecords();
    const replacingType = useReplacingType();
    const quickFilters = useQuickFilters();
    const storeDbId = useDbIdInGridUI();
    const storeViewId = useDefaultAccessViewIdInGridUI();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();
    const rowStartIndex = useWindowRowStartIndex();
    const rowStopIndex = useWindowRowStopIndex();
    const columnsSelected = useColumnsSelected();
    const rowsRangeIndexes = useRowsRangeIndexes();

    const isRangeSelected = React.useMemo(() => {
        return isSelectingRange({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex });
    }, [rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex]);
    const [selectedArea, setSelectedArea] = React.useState(
        isRangeSelected || columnsSelected?.length > 0 || rowsRangeIndexes?.length > 0 ? AREA.RANGES : AREA.GRID
    );

    const dbIdCombined = useMemo(() => {
        return dbId || storeDbId;
    }, [storeDbId, dbId]);

    const viewIdCombined = useMemo(() => {
        return viewId || storeViewId;
    }, [viewId, storeViewId]);

    const filter = React.useMemo(() => {
        return formatQuickFilters(quickFilters);
    }, [quickFilters]);

    React.useEffect(() => {
        const resizeHandler = () => {
            setWindowHeight(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
        };
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
            dispatch(setIsShowSearchRange(false));
            dispatch(setSearchRange(null));
        };
    }, []);

    React.useEffect(() => {
        return () => {
            dispatch(gridUIActions.clearSearchState());
        };
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(setIsShowSearchRange(selectedArea === AREA.RANGES));
    }, [selectedArea]);

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            if (value.trim()) {
                isFirstTime.current = false;
                setIsSearching(true);
                dispatch(gridUIActions.setSuccessReplaceAll({}));
                setTotalRecords(0);
                setSelectedRecord({});
                dispatch(gridUIActions.setSearchRecords([]));
                dispatch(
                    searchRecordsFnc({
                        body: {
                            viewId: viewIdCombined,
                            dbId: dbIdCombined,
                            page: 1,
                            limit: LIMIT,
                            q: value,
                            modes: matches,
                            filter: JSON.stringify(filter)
                        },
                        area: selectedArea,
                        successCallback: data => {
                            const { records, totalRecords } = data;
                            setPage(1);
                            setTotalRecords(totalRecords);
                            dispatch(gridUIActions.setSearchRecords(records));
                            setIsSearching(false);
                        },
                        errorCallback: () => {
                            setIsSearching(false);
                        }
                    })
                );
            }
        }, DEBOUNCE_TIME_SEARCHING);
    }, [value, viewIdCombined, dbIdCombined, matches, dispatch, filter, selectedArea]);

    const resetFindRange = React.useCallback(() => {
        setSelectedArea(AREA.RANGES);
        if (value.trim()) {
            setIsSearching(true);
            dispatch(gridUIActions.setSuccessReplaceAll({}));
            setTotalRecords(0);
            setSelectedRecord({});
            dispatch(gridUIActions.setSearchRecords([]));
            dispatch(
                searchRecordsFnc({
                    body: {
                        viewId: viewIdCombined,
                        dbId: dbIdCombined,
                        page: 1,
                        limit: LIMIT,
                        q: value,
                        modes: matches,
                        filter: JSON.stringify(filter)
                    },
                    area: AREA.RANGES,
                    successCallback: data => {
                        const { records, totalRecords } = data;
                        setPage(1);
                        setTotalRecords(totalRecords);
                        dispatch(gridUIActions.setSearchRecords(records));
                        setIsSearching(false);
                    },
                    errorCallback: () => {
                        setIsSearching(false);
                    }
                })
            );
        }
    }, [value, viewIdCombined, dbIdCombined, matches, dispatch, filter]);

    const fetchMoreRecords = React.useCallback(() => {
        setIsSearchingMore(true);
        dispatch(
            searchRecordsFnc({
                body: {
                    viewId: viewIdCombined,
                    dbId: dbIdCombined,
                    page: page + 1,
                    limit: LIMIT,
                    q: value,
                    modes: matches,
                    filter: JSON.stringify(filter)
                },
                area: selectedArea,
                successCallback: data => {
                    const { records: resRecords, totalRecords } = data;
                    setPage(page + 1);
                    setTotalRecords(totalRecords);
                    setIsSearchingMore(false);
                    dispatch(gridUIActions.setSearchRecords([...searchRecords, ...resRecords]));
                },
                errorCallback: () => {
                    setIsSearching(false);
                }
            })
        );
    }, [viewIdCombined, page, dbIdCombined, value, searchRecords, matches, dispatch, filter, selectedArea]);

    const isHavingNoResult = React.useMemo(() => {
        return value.trim() && !searchRecords?.length && !isSearching;
    }, [value, searchRecords, isSearching]);

    const navigateToCell = React.useCallback(
        async ({ columnId, recordId }) => {
            dispatch(gridUIActions.jumpToCell({ columnId, recordId, isResetingCellStatus: false }));
        },
        [dispatch]
    );

    const handleCheckMatchCase = React.useCallback(
        (value, isChecked) => {
            if (isChecked) {
                setMatches(prev => [...prev, value]);
                return;
            }
            setMatches(matches.filter(m => m !== value));
        },
        [matches]
    );

    const handleSelectRecord = React.useCallback(
        ({ columnId, recordId, value }) => {
            setSelectedRecord({ columnId, recordId, value });
            navigateToCell({ columnId, recordId });
        },
        [navigateToCell]
    );

    const handleReplace = React.useCallback(
        async replaceText => {
            if (!selectedRecord?.recordId || !searchRecords.length || replacingText || !value) return;
            sendManualTrack({
                type: 'Replace Search Text',
                customData: {
                    replacedText: value,
                    newText: replaceText,
                    replaceOptions: matches.sort((a, b) => a.length - b.length),
                    filter
                }
            });
            const { recordId, columnId, value: selectedRecordValue } = selectedRecord;
            dispatch(
                gridUIActions.setReplacingType({
                    type: 'one',
                    oldValue: selectedRecordValue,
                    rowId: recordId,
                    columnId,
                    message: (
                        <span>
                            Replaced <b>{value}</b> with <b>{replaceText}</b>
                        </span>
                    )
                })
            );
            const body = {
                q: value,
                data: replaceText,
                recordIds: [recordId],
                columnIds: [columnId],
                modes: matches,
                filter: JSON.stringify(filter)
            };
            dispatch(
                gridUIActions.replaceSearchText({
                    dbId: dbIdCombined,
                    viewId: viewIdCombined,
                    body,
                    isReplaceSingle: true
                })
            );
        },
        [dispatch, dbIdCombined, viewIdCombined, value, selectedRecord, matches, searchRecords, replacingText, filter]
    );

    const handleReplaceAll = React.useCallback(
        async replaceText => {
            if (!value || replacingText || !searchRecords.length) return;
            sendManualTrack({
                type: 'Replace All Search Text',
                customData: {
                    replacedText: value,
                    newText: replaceText,
                    replaceOptions: matches.sort((a, b) => a.length - b.length),
                    filter
                }
            });
            dispatch(
                gridUIActions.setReplacingType({
                    type: 'all'
                })
            );
            const body = {
                q: value,
                data: replaceText,
                recordIds: [],
                columnIds: [],
                modes: matches,
                filter: JSON.stringify(filter)
            };
            dispatch(
                gridUIActions.replaceSearchText({
                    dbId: dbIdCombined,
                    viewId: viewIdCombined,
                    body,
                    area: selectedArea
                })
            );
        },
        [dispatch, dbIdCombined, viewIdCombined, value, matches, replacingText, searchRecords, filter, selectedArea]
    );

    const _handleSearchChange = React.useCallback(
        e => {
            if (e?.target?.value.trim()) {
                isFirstTime.current = false;
            }
            handleSearchChange(e);
        },
        [handleSearchChange]
    );

    const _handleClearSearch = React.useCallback(() => {
        isFirstTime.current = false;
        handleClearSearch();
    }, [handleClearSearch]);

    const _handleChangeArea = React.useCallback(area => {
        setSelectedArea(area);
    }, []);

    const searchRecordToolbar = React.useMemo(() => {
        return (
            <SearchRecordToolbar
                AREA={AREA}
                matches={matches}
                handleCheckMatchCase={handleCheckMatchCase}
                handleReplace={handleReplace}
                handleReplaceAll={handleReplaceAll}
                replacing={replacingText && replacingType === 'one'}
                searchValue={value}
                selectedArea={selectedArea}
                selectedRecord={selectedRecord}
                handleSearchChange={_handleSearchChange}
                handleClearSearch={_handleClearSearch}
                handleChangeArea={_handleChangeArea}
            />
        );
    }, [
        matches,
        handleCheckMatchCase,
        handleReplace,
        handleReplaceAll,
        value,
        _handleSearchChange,
        _handleClearSearch,
        replacingText,
        replacingType,
        selectedArea,
        _handleChangeArea,
        selectedRecord
    ]);

    if (isFirstTime.current)
        return (
            <Grid container className={classes.root} id="search-record" direction="column" wrap="nowrap">
                {searchRecordToolbar}
                <Grid item container className={classes.body} direction="column" alignItems="center" justify="center">
                    <Grid item style={{ paddingBottom: theme.spacing(2) }}>
                        <TMSearchNotFoundSVG />
                    </Grid>
                    <Grid item container direction="column" className={classes.textCenter}>
                        <Grid item style={{ paddingBottom: theme.spacing(1) }}>
                            <h4 className="prose prose-lg font-medium">{t('nothing_here_yet')}</h4>
                        </Grid>
                        <Grid item>
                            <p className="body2 text-text-secondary">{t('what_are_you_looking_for')}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

    return (
        <Grid container className={classes.root} id="search-record" direction="column" wrap="nowrap">
            <div id="reset_find_range" className="w-0 h-0 pointer-events-none" onClick={resetFindRange} />
            {searchRecordToolbar}
            {replacingText && replacingType === 'all' && (
                <Grid container className={classes.layer} direction="column" alignItems="center" justify="center">
                    <Grid item>
                        <Spinner size={18} thick={3} />
                    </Grid>
                </Grid>
            )}
            <Grid item className={classes.body}>
                {isSearching && (
                    <Grid
                        container
                        className={classes.spinnerWrapper}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <Spinner size={18} thick={3} />
                        </Grid>
                    </Grid>
                )}
                {successReplaceAll.show ? (
                    <Grid
                        container
                        spacing={2}
                        className={classes.resultCommon}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <YAYSVG />
                        </Grid>
                        <Grid item>
                            <h4 className="prose prose-lg font-medium">
                                All done! We make <span className={classes.hl}>{successReplaceAll.total}</span>{' '}
                                replacements
                            </h4>
                        </Grid>
                    </Grid>
                ) : isHavingNoResult ? (
                    <Grid
                        container
                        spacing={2}
                        className={classes.resultCommon}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <TMGuideSVG />
                        </Grid>
                        <Grid item container direction="column" spacing={1} className={classes.textCenter}>
                            <Grid item>
                                <h4 className="prose prose-lg font-medium">
                                    No results were found to match your search
                                </h4>
                            </Grid>
                            <Grid item>
                                <p className="caption">Try modifying your search criteria</p>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column" className={classes.fullWidth}>
                        {searchRecords?.map(record => {
                            const { cells, recordId, recordPublicId } = record;
                            return (
                                <Grid item key={recordId} container direction="column">
                                    <Grid item className={classes.recordItem}>
                                        <p className="body1 text-text-secondary">Record #{recordPublicId}</p>
                                    </Grid>
                                    <Grid item container direction="column" wrap="nowrap">
                                        {cells?.map((cell, index) => {
                                            const columnId = cell?.columnId;
                                            const columnName = metaData?.[columnId]?.name || cell?.columnName;
                                            const isSelected =
                                                selectedRecord.recordId === recordId &&
                                                selectedRecord.columnId === columnId;
                                            return (
                                                <Grid
                                                    item
                                                    key={index}
                                                    onClick={() =>
                                                        handleSelectRecord({ columnId, recordId, value: cell?.data })
                                                    }
                                                    className={`${classes.cellItem} ${
                                                        isSelected ? classes.cellSelectedItem : ''
                                                    }`}
                                                >
                                                    <RecordNotification
                                                        columnType={cell?.columnType || columnTypes.MULTIPLE_LINES}
                                                        data={cell?.data}
                                                        columnName={columnName}
                                                        dbId={dbId}
                                                        group={cell?.group}
                                                        customProperties={cell?.customProperties}
                                                        referencedColumnType={cell?.referencedColumnType}
                                                        // updatedAt={`Updated ${getFriendlyTime(
                                                        //     cell?.updatedAt,
                                                        //     'DD MMM YYYY'
                                                        // )}`}
                                                        containerProps={{
                                                            alignItems: 'center'
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            );
                        })}
                        {totalRecords > searchRecords?.length && (
                            <Grid item className={classes.showMoreWrapper}>
                                {isSearchingMore && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justify="center"
                                        direction="row"
                                        spacing={2}
                                        style={{ height: 33 }}
                                    >
                                        <Grid item>
                                            <Spinner size={18} thick={3} />
                                        </Grid>
                                    </Grid>
                                )}
                                {!isSearchingMore && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        direction="row"
                                        spacing={2}
                                        onClick={fetchMoreRecords}
                                    >
                                        <Grid item>
                                            <p className="body1 text-blue-dodger opacity-80">Show more</p>
                                        </Grid>
                                        <Grid item>
                                            <p className={'body1 px-1 py-0.5 rounded-sm bg-grey-pale'}>
                                                {totalRecords - searchRecords?.length}+
                                            </p>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(SearchBox);
