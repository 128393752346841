import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Dialog from 'components/dialog/Dialog';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Radio, RadioGroup, FormControlLabel, CircularProgress, IconButton } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';
import { EXPORT_TYPES } from 'const/gridUI';
import { useDispatch } from 'react-redux';
import { exportTokenSets } from 'gridUI/actions';
import ButtonBase from 'components/button/Base';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPaper-root': {
            width: `100%`,
            maxWidth: `754px !important`
        }
    },
    container: {},
    closeIcon: {
        verticalAlign: 'middle'
    },
    header: {
        padding: `32px 32px 0`
    },
    content: {
        marginTop: 41,
        padding: `0 32px`
    },
    footer: {
        marginTop: 18,
        padding: `18px 32px`,
        borderTop: `1px solid ${theme.colors.border}`
    },
    exportType: {
        marginLeft: 48
    },
    radio: {
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: theme.colors.lightLavender
        }
    }
}));

const ExportTagDialog = ({ open = true, onClose, tag }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultExportType = EXPORT_TYPES.CSV;
    const [exportType, setExportType] = useState(defaultExportType);
    const [exporting, setExporting] = useState(false);

    const options = useMemo(() => {
        return [
            // { value: EXPORT_TYPES.TBX, label: t(`global_tbx`) },
            { value: EXPORT_TYPES.CSV, label: t(`global_csv`) },
            { value: EXPORT_TYPES.XLSX, label: t(`global_xlsx`) }
        ];
    }, [t]);

    const handleExportTypeChange = useCallback(e => {
        let exportType = e.target.value;
        setExportType(exportType);
    }, []);

    const handleClose = useCallback(() => {
        if (exporting) return;
        onClose();
    }, [exporting, onClose]);

    const _export = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (exporting) return;
            setExporting(true);
            dispatch(
                exportTokenSets({
                    tokenSetId: tag?.id,
                    format: exportType,
                    successCallback: () => {
                        setExporting(false);
                        onClose();
                    },
                    errorCallback: () => {
                        setExporting(false);
                    }
                })
            );
        },
        [exporting, dispatch, tag, exportType, onClose]
    );

    useLayoutEffect(() => {
        if (open) {
            setExportType(defaultExportType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} className={classes.root}>
            <Grid
                container
                direction="column"
                className={classes.container}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Grid item container justifyContent="space-between" alignItems="center" className={classes.header}>
                    <Grid item>
                        <Typography variant="h3">Export tag</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseIconSVG className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container direction="column" className={classes.content}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <Typography variant="body1">Tag export file type:</Typography>
                        </Grid>
                        <Grid item className={classes.exportType}>
                            <RadioGroup
                                row
                                aria-label="export-type"
                                name="exportType"
                                value={exportType}
                                onChange={handleExportTypeChange}
                            >
                                {options.map(option => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio size="small" className={classes.radio} />}
                                        label={<Typography variant="body1">{option.label}</Typography>}
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.footer}>
                    <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonBase onClick={onClose} width={140} variant="outlined">
                                Cancel
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.submitButton}>
                            <ButtonBase onClick={_export} disabled={exporting} width={140} variant="contained">
                                Export
                                {exporting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default React.memo(ExportTagDialog);
