import React, { useMemo, useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import Skeleton from 'components/skeleton/Base';
import { useHistory } from 'react-router-dom';
import ListView from './views/ListView';
import { createView, updateView, deleteView } from './actions';
import Search from 'components/search';
import { VIEW_TYPES, MAXIMUM_VIEWS } from 'const/gridUI';
import { useViewList, useIsAddingView, useCurrentView } from 'hooks/gridUI';
import PopperMenu from 'components/menus/Popper';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Divider from 'components/divider/Base';
import { useRole } from 'hooks/auth/role';
import * as gridUIActions from 'gridUI/actions';
import { sendManualTrack } from 'tracker';
import ViewIcon from 'gridUI/views/ViewIcon';
import { checkContainId } from 'utils/clickAway';

function AccessView({
    Trigger,
    Content,
    left = false,
    offset = 0,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    isShareViewLink,
    t
}) {
    const views = useViewList();
    const isAddingView = useIsAddingView();
    const currentView = useCurrentView();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedView, setSelectedView] = useState(null);
    const [isUpdateLastItem, setIsUpdateLastItem] = useState(null);
    const theme = useTheme();
    const [filterValue, setFilterValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const roles = useRole();

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = e => {
        if (e && checkContainId(e, 'dialog-delete-view')) return;
        setAnchorEl(null);
    };

    const sortedViews = React.useMemo(() => {
        return [
            views.find(view => view?.type === VIEW_TYPES.DEFAULT_VIEW),
            ...views.filter(view => view?.type !== VIEW_TYPES.DEFAULT_VIEW)
        ];
    }, [views]);

    const isReachLimit = useMemo(() => {
        let filterViews = sortedViews.filter(view => !view?.isDeleted);
        return filterViews.length >= MAXIMUM_VIEWS;
    }, [sortedViews]);

    useEffect(() => {
        setSelectedView(currentView);
    }, [currentView]);

    useEffect(() => {
        if (open) setFilterValue('');
    }, [open]);

    const switchView = view => {
        if (view.id === viewId) return;
        setSelectedView(view);
        dispatch(gridUIActions.resetGridLoadingState());
        dispatch(gridUIActions.clearQuickFilterAndQuickSort());
        dispatch(gridUIActions.clearUndo());
        history.push(
            `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${view.id}`
        );
        handleClickAway();
        setOpen(false);
    };

    const handleCreateNewView = ({ type }) => {
        setIsUpdateLastItem(false);

        let newView = {
            name: `View ${views.length + 1}`,
            gridId: branchId,
            type
        };

        sendManualTrack({ type: `Add Access View` });

        dispatch(
            createView({
                dbId,
                view: newView,
                errorCallback: err => {
                    console.log('error', err);
                },
                successCallback: view => {
                    history.push(
                        `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${view.id}`
                    );
                    setIsUpdateLastItem(true);
                }
            })
        );
    };

    const handleUpdateNewView = ({ newView, oldView, callback }) => {
        setIsUpdateLastItem(false);

        if (newView.name !== oldView.name) {
            dispatch(
                updateView({
                    dbId,
                    newView,
                    oldView
                })
            );
        }
        callback && callback();
    };

    const handleDeleteView = ({ viewId, successCallback, errorCallback }) => {
        sendManualTrack({
            type: `Delete View`,
            customData: {
                viewId
            }
        });

        dispatch(
            deleteView({
                dbId,
                viewId,
                successCallback,
                errorCallback
            })
        );
    };

    return (
        <div className={'relative'}>
            <div>
                <Grid
                    className={'p-2 !w-[200px] rounded bg-transparent cursor-pointer'}
                    style={{ cursor: isShareViewLink ? 'default' : '' }}
                    onClick={handleClick}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid item className={'flex items-center overflow-hidden'}>
                        <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                            <Grid item>
                                <ViewIcon view={selectedView} size="small" />
                            </Grid>
                            <Grid item className="flex-1 truncate">
                                <p className="body2 text-ellipsis overflow-hidden">
                                    {selectedView && selectedView.name}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isShareViewLink && (
                        <Grid item>
                            <Grid container alignItems="center">
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {/*  */}
                {anchorEl && !isShareViewLink && (
                    <PopperMenu placement={'bottom-start'} anchorEl={anchorEl} handleClickAway={handleClickAway}>
                        <Grid className={'min-w-[360px] mt-0'} container direction="column">
                            <Grid item className={'w-full py-0 !px-2'}>
                                <Search
                                    width={'100%'}
                                    onChange={e => setFilterValue(e.target.value)}
                                    autoFocus
                                    background={theme.colors.paleGrey}
                                    placeholder={t('find_a_view')}
                                />
                            </Grid>
                            <Grid item>
                                <ListView
                                    views={sortedViews.filter(view =>
                                        view?.name?.toLowerCase().includes(filterValue.toLowerCase())
                                    )}
                                    selectedView={selectedView}
                                    switchView={switchView}
                                    isUpdateLastItem={isUpdateLastItem}
                                    updateNewView={handleUpdateNewView}
                                    deleteView={handleDeleteView}
                                />
                            </Grid>
                            {roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_VIEW] === roleConst.FULL && (
                                <>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column">
                                            {isReachLimit && (
                                                <Grid item>
                                                    <Grid container justify="center" alignItems="center">
                                                        <p className="text-error">
                                                            {t(`views_you_have_reached_limit`, {
                                                                length: MAXIMUM_VIEWS
                                                            })}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    className={'mt-2.5 w-full px-5'}
                                                    wrap="nowrap"
                                                >
                                                    <Grid item>
                                                        <p className="caption">{t(`views_add_a_view`)}:</p>
                                                    </Grid>
                                                    <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_VIEW}>
                                                        {({ isReadyOnly }) => (
                                                            <Grid item>
                                                                {isAddingView && <Skeleton height={36} width={130} />}
                                                                {!isAddingView && (
                                                                    <ButtonBase
                                                                        width={130}
                                                                        disabled={isReachLimit}
                                                                        onClick={() =>
                                                                            handleCreateNewView({
                                                                                type: VIEW_TYPES.ACCESS_VIEW
                                                                            })
                                                                        }
                                                                        variant="normal"
                                                                    >
                                                                        <ViewIcon
                                                                            view={{
                                                                                customProperties: {
                                                                                    type: VIEW_TYPES.ACCESS_VIEW
                                                                                }
                                                                            }}
                                                                            size="small"
                                                                        />
                                                                        <p className="body2 ml-2 whitespace-nowrap">
                                                                            {t(`views_access_view`)}
                                                                        </p>
                                                                    </ButtonBase>
                                                                )}
                                                            </Grid>
                                                        )}
                                                    </AccessControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </PopperMenu>
                )}
            </div>
        </div>
    );
}

AccessView.propTypes = {};

export default React.memo(AccessView);
