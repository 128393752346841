import React from 'react';
import types from './types';

const leafRenderer = ({ attributes, children, leaf }) => {
    if (leaf[types.BOLD]) {
        children = <strong>{children}</strong>;
    }

    if (leaf[types.ITALIC]) {
        children = <em>{children}</em>;
    }

    if (leaf[types.UNDERLINE]) {
        children = <u>{children}</u>;
    }

    if (leaf[types.STRIKETHROUGH]) {
        children = <del>{children}</del>;
    }

    if (leaf[types.H1]) {
        children = <h1>{children}</h1>;
    }

    if (leaf[types.H2]) {
        children = <h2>{children}</h2>;
    }

    if (leaf[types.H3]) {
        children = <h3>{children}</h3>;
    }

    if (leaf[types.H4]) {
        children = <h4>{children}</h4>;
    }

    if (leaf[types.H5]) {
        children = <h5>{children}</h5>;
    }

    if (leaf[types.H6]) {
        children = <h6>{children}</h6>;
    }

    if (leaf[types.OL]) {
        children = <ol>{children}</ol>;
    }

    if (leaf[types.UL]) {
        children = <ul>{children}</ul>;
    }

    if (leaf[types.LI]) {
        children = <li>{children}</li>;
    }

    if (leaf[types.BLOCKQUOTE]) {
        children = <blockquote>{children}</blockquote>;
    }

    if (leaf[types.CODE]) {
        children = <code style={{ fontFamily: 'courier new', backgroundColor: '#eee', padding: 3 }}>{children}</code>;
    }

    return <span {...attributes}>{children}</span>;
};

export default leafRenderer;
