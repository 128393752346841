import React from 'react';
import ListItem from 'components/list/Item';
import Tooltip from 'components/tooltip/Base';

function DropdownMenuItem({
    value,
    label,
    icon,
    selected,
    className,
    isDanger,
    isDisabled,
    tooltipTitle,
    onClick,
    ...rest
}) {
    const handleClick = event => {
        onClick && onClick({ value, label, ...rest });
        event.preventDefault();
        event.stopPropagation();
    };

    if (tooltipTitle) {
        return (
            <Tooltip title={tooltipTitle}>
                <div>
                    <ListItem
                        isSelected={selected}
                        icon={icon}
                        name={label}
                        onClick={handleClick}
                        disabled={isDisabled}
                    />
                </div>
            </Tooltip>
        );
    }
    return <ListItem isSelected={selected} icon={icon} name={label} onClick={handleClick} disabled={isDisabled} />;
}

export default React.memo(DropdownMenuItem);
