import { enqueueSnackbar } from 'notifier/actions';
import { searchCellDataApi, searchGridApi } from 'services/search';

export const searchCellData = ({ projectId, queryParams, successCallback, errorCallback }) => {
    return async function(dispatch) {
        try {
            const response = await searchCellDataApi({ projectId, queryParams });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
};

export const searchGrid = ({ projectId, queryParams, successCallback, errorCallback }) => {
    return async function(dispatch) {
        try {
            const response = await searchGridApi({ projectId, queryParams });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
};
