import React from 'react';

function XLMSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M27 27V36L36 27H27Z" fill="#ABABAB" />
            <path d="M18 36H27V27L18 36Z" fill="#DEDEDD" />
            <path d="M0 9V19.5H24V9H0Z" fill="#EFAC25" />
            <path
                d="M4.61169 11.0488L5.43969 13.1278L6.25868 11.0488H7.67168L6.17769 14.2258L7.77968 17.4748H6.32169L5.39619 15.2968L4.47669 17.4748H3.05469L4.65669 14.2258L3.18069 11.0488H4.61169Z"
                fill="white"
            />
            <path
                d="M11.1369 11.0488L12.0549 15.5848H12.0729L12.9999 11.0488H14.9169V17.4748H13.7289V12.3448H13.7109L12.5679 17.4748H11.5599L10.4169 12.3448H10.3989V17.4748H9.21094V11.0488H11.1369Z"
                fill="white"
            />
            <path d="M16.7422 11.0488H18.0397V16.4128H20.3332V17.4748H16.7422V11.0488Z" fill="white" />
        </svg>
    );
}

export default React.memo(XLMSVG);
