import * as types from '../types';

export function clearQuickFilterAndQuickSort() {
    return {
        type: types.CLEAR_WHEN_SWITCH_VIEW_OR_SWITCH_GRID
    };
}

export function clearColumnQuickFilterSocket(columnId) {
    return {
        type: types.CLEAR_QUICK_FILTER_WHEN_VIEW_FILTER_IS_UPDATED_SOCKET,
        payload: { columnId }
    };
}
