import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import * as roleConst from 'auth/roleConst';
import { useDispatch } from 'react-redux';
import ws from 'socket';
import { setCurrentPage } from 'app/actions';
import { PERMISSION_SETTING } from 'const';
import TmPage from './TmPage';
import Locale from './Locale';
import LQAPage from './LQAPage';
import TermBase from './tb';
import * as paymentActions from 'payment/actions';
import * as addonActions from 'addons/actions';
import TBDetail from './tb/TBDetail';
import TagDetail from './tag/TagDetail';
import Tag from './tag';
import { useCompanyId } from 'hooks/auth';
import { useCurrentUserId } from 'hooks/auth';
import { useRole } from 'hooks/auth/role';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import AutoQA from './autoQA';
import TBTermDetail from './tb/TBTermDetail';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}));

function LocalizationSetting() {
    const classes = useStyles();
    let { path } = useRouteMatch();
    const dispatch = useDispatch();
    const userId = useCurrentUserId();
    const companyId = useCompanyId();

    React.useEffect(() => {
        ws.permissionSettingSubscribeTopics({ userId, companyId });
        dispatch(
            setCurrentPage({
                currentPage: PERMISSION_SETTING
            })
        );
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [dispatch, userId, companyId]);

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchCustomerAndSubscriptions({
                successCallback: () => {
                    console.log('fetchCustomerAndSubscriptions success');
                },
                errorCallback: () => {
                    console.log('fetchCustomerAndSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            addonActions.getAddonsList({
                successCallback: () => {
                    console.log('fetchSubscriptions Success');
                },
                errorCallback: () => {
                    console.log('fetchSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container className={classes.root}>
            <Switch>
                <Route path={path} exact>
                    <LocalizationRedirect path={path} />
                </Route>
                <Route path={`${path}/locales`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_TM]}>
                        <Locale />
                    </AuthenticatedRoute>
                </Route>
                <Route exact path={`${path}/tm`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_TM]}>
                        <TmPage />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/lqa`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_LQA]}>
                        <LQAPage />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/tb/:tbId/term/:id`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_GLOSSARY]}>
                        <TBTermDetail />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/tb/:id`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_GLOSSARY]}>
                        <TBDetail />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/tb`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_GLOSSARY]}>
                        <TermBase />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/tag/:id`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_CUSTOM_TAGS]}>
                        <TagDetail />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/tag`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_CUSTOM_TAGS]}>
                        <Tag />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/autoqa`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_LQA]}>
                        <AutoQA />
                    </AuthenticatedRoute>
                </Route>
            </Switch>
        </Grid>
    );
}

function LocalizationRedirect({ path }) {
    const roles = useRole();

    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_TM] === roleConst.FULL) {
        return <Redirect to={`${path}/locales`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_LQA] === roleConst.FULL) {
        return <Redirect to={`${path}/lqa`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_GLOSSARY] === roleConst.FULL) {
        return <Redirect to={`${path}/tb`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_CUSTOM_TAGS] === roleConst.FULL) {
        return <Redirect to={`${path}/tag`} />;
    }
}

export default React.memo(LocalizationSetting);
