import React from 'react';

function AmazonTextToSpeechSVG({ color = '#6E5BBE', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.8342 4.00086V6.37241H1.76909L3.8342 4.00086ZM3.82118 3.20312C3.72743 3.20312 3.63975 3.24479 3.57987 3.31511L0.820312 6.48266C0.766492 6.54429 0.742188 6.62589 0.742188 6.6936V16.474C0.743055 16.6477 0.88889 16.79 1.06337 16.79L10.4974 16.8004C10.6736 16.8004 10.8177 16.6537 10.8168 16.4793V15.4888C10.6354 15.3968 10.4236 15.3065 10.1762 15.2189V16.1572L1.3853 16.1494V7.01409H4.1553C4.32978 7.01409 4.47561 6.86826 4.47561 6.69378V3.84667H10.1752L10.176 8.89445C10.4226 8.80677 10.6352 8.71563 10.8167 8.62449V3.52382C10.8167 3.34934 10.6743 3.20438 10.4989 3.20351H10.4981L3.82118 3.20312Z"
                fill={color}
            />
            <path
                d="M13.4415 7.35225C12.5596 7.35225 12.827 8.84096 9.85221 9.67692C9.0293 9.90783 8.61523 10.0806 8.61523 10.9365V13.2178C8.61523 14.072 9.0293 14.2456 9.85221 14.4756C12.8271 15.3116 12.5598 16.8003 13.4415 16.8003H13.8244C14.2254 16.8003 14.5535 16.4748 14.5535 16.0711V8.08159C14.5535 7.68054 14.2254 7.35243 13.8244 7.35243L13.4415 7.35156V7.35225Z"
                fill={color}
            />
            <path
                d="M17.6511 8.22137C17.1884 8.24307 16.9384 8.77433 17.2205 9.14497C17.7726 9.89584 18.1051 10.9609 18.1051 12.0763C18.1051 13.1935 17.7718 14.2586 17.2205 15.0112C17.0304 15.2673 17.086 15.6275 17.3421 15.8159C17.599 16.0034 17.9601 15.9496 18.1494 15.6935C18.8716 14.7056 19.2561 13.4175 19.2561 12.0764C19.2561 10.7378 18.8716 9.44882 18.1494 8.4626C18.033 8.30114 17.8473 8.2126 17.6511 8.22129L17.6511 8.22137Z"
                fill={color}
            />
            <path
                d="M16.0808 9.68184C15.6433 9.68878 15.3708 10.1593 15.586 10.5395C16.099 11.4857 16.099 12.6662 15.586 13.6115C15.4254 13.8927 15.527 14.2495 15.8108 14.4049C16.0947 14.5594 16.4497 14.4483 16.5982 14.1609C17.3004 12.8658 17.3004 11.2843 16.5982 9.98984C16.4966 9.79886 16.2969 9.67908 16.0808 9.68168V9.68184Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.18251 8.66211H7.27985C7.47169 8.66211 7.62447 8.81662 7.62447 9.00673C7.62447 9.19684 7.47169 9.35135 7.27985 9.35135H3.18251C2.99154 9.35135 2.83789 9.19684 2.83789 9.00673C2.83789 8.81662 2.99154 8.66211 3.18251 8.66211Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.18251 10.3945H7.27985C7.47169 10.3945 7.62447 10.5473 7.62447 10.7392C7.62447 10.9293 7.47169 11.0829 7.27985 11.0829H3.18251C2.99154 11.0829 2.83789 10.9301 2.83789 10.7392C2.83789 10.5473 2.99154 10.3945 3.18251 10.3945Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.18251 12.127H7.27985C7.47169 12.127 7.62447 12.2797 7.62447 12.4707C7.62447 12.6617 7.47169 12.8153 7.27985 12.8153H3.18251C2.99154 12.8153 2.83789 12.6617 2.83789 12.4707C2.83789 12.2806 2.99154 12.127 3.18251 12.127Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.18251 13.8574H5.2424C5.43424 13.8574 5.58702 14.0111 5.58702 14.202C5.58702 14.3921 5.43425 14.5467 5.2424 14.5467H3.18251C2.99154 14.5467 2.83789 14.393 2.83789 14.202C2.83789 14.0119 2.99154 13.8574 3.18251 13.8574Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(AmazonTextToSpeechSVG);
