import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import KickoutSVG from 'assets/images/svg/KickoutSVG';
import EditGroupSVG from 'assets/images/svg/EditGroupSVG';
import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    SPACING_LIST_NAME_WITH_ICON
} from 'const/style';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCompanyInfo } from 'hooks/app';
import { useWorkspaceRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import * as groupActions from 'permission/actions/group';
import AccessControl from 'auth/AccessControl';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { setRightSidebar } from 'app/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    listItem: {
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            fontSize: 20,
            color: theme.colors.midGrey,
            marginRight: SPACING_LIST_NAME_WITH_ICON,
            position: 'relative',
            top: 2
        }
    }
}));

function GroupListPopup({ level, handleClickAway, group, onRemoveGroup }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { id: companyId } = useCompanyInfo();
    const workspaceRole = useWorkspaceRole();

    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleRemoveGroup = ({ group }) => {
        onRemoveGroup({ group });
        handleClickAway();
    };

    const handleNavigateToGroupPermissionPage = ({ companyId, groupId }) => {
        dispatch(groupActions.changeGroup({ groupId }));
        dispatch(setRightSidebar({ type: null }));
        history.push(`/company-settings/groups/${groupId}`);
    };

    return (
        <Grid ref={rootRef} container direction="column" className={classes.root}>
            <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP}>
                <Grid
                    item
                    onClick={() => handleNavigateToGroupPermissionPage({ companyId, groupId: group.id })}
                    className={classes.listItem}
                >
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <EditGroupSVG />
                        </Grid>
                        <Grid item>
                            <p className="body2">{t(`share_view_edit_group`)}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </AccessControl>
            {workspaceRole === roleConst.CREATOR && (
                <Grid item onClick={() => handleRemoveGroup({ group })} className={classes.listItem}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <KickoutSVG />
                        </Grid>
                        <Grid item>
                            <p className="body2">
                                {t(`share_view_remove_from_level`, { level: level ? level : 'view' })}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(GroupListPopup);
