import React from 'react';
import { Grid, Popover, Dialog, Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridUIActions from '../actions';
import Spinner from 'components/spinner/Base';
import {
    useGridBegin,
    useIsFetchingGridHistory,
    useGridHistory,
    useGRID_HISTORY_OFFSET,
    useGRID_HISTORY_LIMIT,
    useTotalGridHistory
} from 'hooks/gridUI';
import isEmpty from 'lodash/isEmpty';
import LogItem from './LogItem';
import RestoreConfirmModal from './RestoreConfirmModal';
import LogItemPopoverContent from './LogItemPopover';
import { POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM } from 'const/style';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { restoreGrid } from 'gridUI/actions/gridUI';
import { getISOString, getFriendlyDate } from 'utils/datetime';
import moment from 'moment';
import { GRID_HISTORY_ACTIONS, GRID_HISTORY_OBJECT_TYPES } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {},
    spinner: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadMoreSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    popover: {
        '& .MuiPopover-paper': {
            boxShadow: theme.shadows[1],
            borderRadius: POPUP_RADIUS,
            paddingTop: POPUP_PADDING_TOP_BOTTOM,
            paddingBottom: POPUP_PADDING_TOP_BOTTOM,
            border: '1px solid #F0F0F0',
            backgroundColor: theme.colors.white,
            marginLeft: 10
        }
    },
    panel: {
        '&.MuiAccordion-root': {
            margin: 0,
            boxShadow: 'none',
            '&:before': {
                backgroundColor: 'transparent'
            }
        }
    },
    panelSummary: {
        padding: '10px 30px',
        '&.MuiAccordionSummary-root': {
            minHeight: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        }
    },
    panelDetail: {
        padding: 0,
        '&:hover': {
            backgroundColor: theme.colors.hoverItem
        }
    },
    icon: {
        width: 24,
        height: 24
    },
    shortLine: {
        marginLeft: 24,
        marginRight: 24
    }
}));

function LogList({ CONTENT_HEIGHT, closeSidebar, workspaceId, gridId, viewId, dbId, branchId, t, isShareViewLink }) {
    const dispatch = useDispatch();
    const isFetchingGridHistory = useIsFetchingGridHistory({ branchId });
    const logs = useGridHistory({ branchId });
    const GRID_HISTORY_OFFSET = useGRID_HISTORY_OFFSET({ branchId });
    const GRID_HISTORY_LIMIT = useGRID_HISTORY_LIMIT({ branchId });
    const totalGridHistory = useTotalGridHistory({ branchId });
    const grid_begin = useGridBegin({ branchId });

    const [isFetching, setIsFetching] = React.useState(false);
    const [isStopFetchMore, setIsStopFetchMore] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [popoverAnchor, setPopoverAnchor] = React.useState(null);
    const [backupTime, setBackupTime] = React.useState(true);
    const classes = useStyles();

    const timeMapByDate = React.useMemo(() => {
        let result = {};
        logs.sort((a, b) => moment(b.time) - moment(a.time)).forEach(log => {
            const date = getFriendlyDate(log.time);
            if (!result[date]) result[date] = [];
            result[date] = [...result[date], { ...log }];
        });
        return result;
    }, [logs]);

    const handleSelect = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleActionClick = (target, time) => {
        setPopoverAnchor(target);
        setBackupTime(time);
    };

    const handleAccept = ({ time }) => {
        dispatch(restoreGrid({ restoreToTime: getISOString(time), type: 'restore' }));
        closeSidebar();
    };

    const OFFSET_FETCH = 1000;

    const handleScroll = React.useCallback(() => {
        const target = document.getElementById('gridHistory');
        if (target.scrollTop === 0 || target.scrollTop < target.scrollHeight - OFFSET_FETCH || isFetching) return;
        setIsFetching(true);
    }, [isFetching, setIsFetching]);

    React.useEffect(() => {
        setIsStopFetchMore(false);
    }, [grid_begin]);

    React.useEffect(() => {
        const target = document.getElementById('gridHistory');
        target.addEventListener('scroll', handleScroll);
        return () => target.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const fetchMoreListItems = React.useCallback(() => {
        if (GRID_HISTORY_OFFSET >= totalGridHistory || totalGridHistory < GRID_HISTORY_LIMIT) {
            setIsStopFetchMore(true);
            setIsFetching(false);
            return;
        } else if (GRID_HISTORY_LIMIT + GRID_HISTORY_OFFSET > totalGridHistory) {
            dispatch(
                gridUIActions.fetchGridHistory({
                    branchId,
                    isFetchmore: true,
                    successCallback: () => {
                        console.log('fetch more suggessfully');
                        setIsFetching(false);
                        setIsStopFetchMore(true);
                    },
                    errorCallback: () => {
                        console.log('failed to fetch more grid history');
                        setIsFetching(false);
                        setIsStopFetchMore(true);
                    }
                })
            );
            return;
        } else {
            dispatch(
                gridUIActions.fetchGridHistory({
                    branchId,
                    isFetchmore: true,
                    successCallback: () => {
                        console.log('fetch more suggessfully');
                        setIsFetching(false);
                    },
                    errorCallback: () => {
                        console.log('failed to fetch more grid history');
                        setIsFetching(false);
                    }
                })
            );
        }
    }, [dispatch, GRID_HISTORY_LIMIT, GRID_HISTORY_OFFSET, totalGridHistory, branchId]);

    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (!isFetching || isStopFetchMore) {
            setIsFetching(false);
            return;
        }
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetchMoreListItems();
        }, 100);
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [isFetching, fetchMoreListItems, isStopFetchMore]);

    return (
        <Grid
            id={'gridHistory'}
            container
            direction="column"
            className={classes.root}
            style={{
                height: CONTENT_HEIGHT,
                overflow: 'auto'
            }}
            wrap="nowrap"
        >
            {isFetchingGridHistory && (
                <div className={classes.spinner} style={{ height: CONTENT_HEIGHT }}>
                    <Spinner size={30} thick={4} />
                </div>
            )}
            {!isFetchingGridHistory && (
                <>
                    {isEmpty(logs) && (
                        <Grid container justify="center" style={{ marginTop: 50 }}>
                            <p className="caption">{t(`global_there_is_no_matching_results`)}</p>
                        </Grid>
                    )}
                    {!isEmpty(logs) &&
                        Object.keys(timeMapByDate).map((date, index) => {
                            return (
                                <Accordion defaultExpanded key={index} className={classes.panel}>
                                    <AccordionSummary className={classes.panelSummary}>
                                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                                            <Grid item style={{ display: 'flex' }}>
                                                <ArrowDownSVG className={classes.icon} />
                                            </Grid>
                                            <Grid item>
                                                <h4 className="prose prose-lg font-medium">{date}</h4>
                                            </Grid>
                                            <Grid item style={{ flex: 1 }}></Grid>
                                            <Grid item>
                                                <p className="caption">
                                                    {timeMapByDate[date].length} {t(`global_update`)}
                                                    {timeMapByDate[date].length > 1 && 's'}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <Divider className={classes.shortLine} />
                                    {timeMapByDate[date].map((log, index) => {
                                        if (log?.action === GRID_HISTORY_ACTIONS.BULK_DELETE_RECORDS) {
                                            log.action = GRID_HISTORY_ACTIONS.DELETE;
                                            log.objectType = GRID_HISTORY_OBJECT_TYPES.RECORD;
                                        }
                                        return (
                                            <AccordionDetails key={index} className={classes.panelDetail}>
                                                <Grid container direction="column" justify="space-between">
                                                    <LogItem
                                                        t={t}
                                                        key={log.time}
                                                        onActionClick={handleActionClick}
                                                        {...log}
                                                        isShareViewLink={isShareViewLink}
                                                    />
                                                </Grid>
                                            </AccordionDetails>
                                        );
                                    })}
                                </Accordion>
                            );
                        })}
                    {isFetching && !isStopFetchMore && (
                        <div className={classes.loadMoreSpinner} key={0}>
                            <Spinner size={30} thick={4} />
                        </div>
                    )}
                </>
            )}
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                className={classes.popover}
            >
                <LogItemPopoverContent t={t} onClose={() => setPopoverAnchor(null)} onSelect={handleSelect} />
            </Popover>
            <Dialog open={modalOpen} onClose={handleModalClose}>
                <RestoreConfirmModal t={t} backupTime={backupTime} onClose={handleModalClose} onAccept={handleAccept} />
            </Dialog>
        </Grid>
    );
}

export default React.memo(LogList);
