import { fetchMe } from 'auth/actions';
import { useCurrentUser } from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

export default function UnauthenticatedRoute({ children, ...rest }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUser = useCurrentUser();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (currentUser) return;
        setIsLoading(true);
        dispatch(
            fetchMe({
                successCallback: () => {
                    setIsLoading(false);
                },
                errorCallback: () => {
                    setIsLoading(false);
                }
            })
        );
    }, [dispatch, currentUser]);

    if (isLoading) return <span>Loading...</span>;

    if (currentUser) {
        if (currentUser?.authorities?.includes('PRE_AUTH') && location?.pathname !== '/signin-user-2fa') {
            return (
                <Redirect
                    to={{
                        pathname: '/signin-user-2fa',
                        search: location?.search,
                        state: { intendedRoute: location?.state?.intendedRoute }
                    }}
                />
            );
        } else {
            return (
                <Redirect
                    to={{
                        pathname: location.state?.intendedRoute || '/',
                        search: location?.search,
                        state: { intendedRoute: location?.state?.intendedRoute }
                    }}
                />
            );
        }
    } else {
        return children;
    }
}
