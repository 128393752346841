import React from 'react';
import { Grid, Typography, IconButton, LinearProgress, makeStyles, Paper, Popper } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import theme from 'theme';
import ButtonBase from 'components/button/Base';
import { DASHBOARD_TUTORIAL_STEPS, DASHBOARD_TUTORIAL_FROM_SIGNUP_STEPS } from 'tutorials/dashboard';
import store from 'store/configStore';
import { GRID_TUTORIAL_STEPS } from 'tutorials/grid';
import { DASHBOARD_TUTORIAL, DASHBOARD_TUTORIAL_FROM_SIGNUP, GRID_TUTORIAL } from 'const';
import { useCurrentTutorial } from 'hooks/app';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    '@keyframes lds-ripple': {
        '0%': {
            opacity: 1
        },
        '50%': {
            opacity: 0.4
        },
        '100%': {
            opacity: 1
        }
    },
    root: {
        zIndex: 10000,
        position: 'relative',
        '& *': {
            color: theme.colors.white
        }
    },
    content: {
        padding: theme.spacing(3, 4),
        position: 'relative',
        background: '#6158BA',
        maxWidth: 380,
        zIndex: 101
    },
    overlay: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 100
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#4F47A4',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    },
    bar: {
        backgroundColor: theme.colors.dodgerBlue
    },
    nextButton: {
        '&.MuiButton-root': {
            backgroundColor: '#4F47A4',
            '&:hover': {
                backgroundColor: '#423A9A'
            }
        }
    },
    backButton: {
        '&.MuiButton-root': {
            backgroundColor: 'inherit',
            '&:hover': {
                backgroundColor: '#4F47A4'
            }
        }
    }
}));

const CONTENT_SPACE = 14;

function getContentSpacingByPlacement({ placement, spacing = CONTENT_SPACE }) {
    switch (placement) {
        case 'top-start':
        case 'top':
        case 'top-end':
        case 'bottom-start':
        case 'bottom':
        case 'bottom-end':
            return { marginTop: spacing, marginBottom: spacing };
        case 'right-start':
        case 'right':
        case 'right-end':
        case 'left-start':
        case 'left':
        case 'left-end':
            return { marginRight: spacing, marginLeft: spacing };
        default:
            return {};
    }
}

function Tutorial({ onFinish, className, id, ...rest }) {
    const currentTutorial = useCurrentTutorial();
    const TUTORIAL_STEPS = {
        [DASHBOARD_TUTORIAL]: DASHBOARD_TUTORIAL_STEPS(store),
        [DASHBOARD_TUTORIAL_FROM_SIGNUP]: DASHBOARD_TUTORIAL_FROM_SIGNUP_STEPS(store),
        [GRID_TUTORIAL]: GRID_TUTORIAL_STEPS
    };
    const steps = TUTORIAL_STEPS[currentTutorial];
    const classes = useStyles();
    const [currentStep, setCurrentStep] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isShow, setIsShow] = React.useState(false);

    const open = Boolean(anchorEl);
    const popperId = open ? id || 'tutorial-popper' : undefined;
    const {
        title,
        content,
        nextButtonText,
        onMount,
        anchorSelector,
        hightlightSelectors,
        onUnmount,
        placement,
        backButtonText,
        computeStyle,
        onNext
    } = steps?.[currentStep];

    React.useEffect(() => {
        onMount && onMount();
        //const all = document.querySelector('#root');
        //all.style.pointerEvents = 'none';
        setTimeout(() => {
            const popper = document.querySelector('#dependencyPopup');
            if (popper) popper.style.pointerEvents = 'none';
            let anchorElRef = document.querySelector(anchorSelector);
            if (anchorElRef) {
                setAnchorEl(anchorElRef);
                setIsShow(true);
            } else {
                setAnchorEl(null);
                onFinish();
            }
        }, 0);

        return () => {
            onUnmount && onUnmount();
            //all.style.pointerEvents = 'auto';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, steps, onMount, anchorSelector, hightlightSelectors, onUnmount]);

    if (!anchorEl || !steps.length || !steps) return null;

    const handleNext = () => {
        onNext();
        setIsShow(false);
        if (currentStep === steps.length - 1) {
            onFinish();
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentTutorial === DASHBOARD_TUTORIAL || currentTutorial === DASHBOARD_TUTORIAL_FROM_SIGNUP) {
            sendManualTrack({ type: 'Dashboard Tutorial Click On Back' });
        }
        if (currentTutorial === GRID_TUTORIAL) {
            sendManualTrack({ type: 'Grid Tutorial Click On Back' });
        }
        setIsShow(false);
        setCurrentStep(currentStep - 1);
    };

    const handleClose = () => {
        if (currentTutorial === DASHBOARD_TUTORIAL || currentTutorial === DASHBOARD_TUTORIAL_FROM_SIGNUP) {
            sendManualTrack({ type: 'Dashboard Tutorial Click On Close' });
        }
        if (currentTutorial === GRID_TUTORIAL) {
            sendManualTrack({ type: 'Grid Tutorial Click On Close' });
        }
        onFinish();
    };

    if (!isShow) return null;
    return (
        <>
            <Popper
                className={`${classes.root} ${className}`}
                id={popperId}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
                modifiers={{
                    computeStyle
                }}
                {...rest}
            >
                <Paper
                    className={classes.content}
                    style={getContentSpacingByPlacement({ placement, spacing: CONTENT_SPACE })}
                >
                    <Grid container direction="column" wrap="nowrap">
                        <Grid item container alignItems="flex-start" justify="space-between" wrap="nowrap">
                            <Grid item>
                                <Typography variant="h4">{title}</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    size="small"
                                    className={`${classes.close} tutorialCloseBtn`}
                                    onClick={handleClose}
                                >
                                    <CloseIconSVG color={theme.colors.white} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: 8 }}>
                            {content}
                        </Grid>
                        <Grid item container spacing={3} style={{ marginTop: 14 }}>
                            <Grid item style={{ flex: 1 }} />
                            {currentStep > 0 && backButtonText && (
                                <Grid item>
                                    <ButtonBase
                                        className={classes.backButton}
                                        width={110}
                                        variant="contained"
                                        onClick={handleBack}
                                    >
                                        {backButtonText}
                                    </ButtonBase>
                                </Grid>
                            )}
                            {nextButtonText && (
                                <Grid item>
                                    <ButtonBase
                                        className={classes.nextButton}
                                        width={110}
                                        variant="contained"
                                        onClick={handleNext}
                                    >
                                        {nextButtonText}
                                    </ButtonBase>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <LinearProgress
                        className={classes.linearProgress}
                        variant="determinate"
                        value={((currentStep + 1) / steps.length) * 100}
                        classes={{ bar: classes.bar }}
                    />
                </Paper>
            </Popper>
            <div className={classes.overlay} />
        </>
    );
}

export default React.memo(Tutorial);
