import React from 'react';
import ListItem from 'components/list/Item';

function SelectItem({ value, label, icon, selected, isDisabled, onClick, ...rest }) {
    const handleClick = event => {
        onClick && onClick({ value, label });
    };

    return (
        <ListItem
            disabled={isDisabled}
            onClick={handleClick}
            name={label}
            isSelected={selected}
            icon={icon}
            {...rest}
        />
    );
}

export default React.memo(SelectItem);
