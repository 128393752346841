import React from 'react';
import * as types from './types';
import {
    getGridsApi,
    createGridApi,
    deleteGridApi,
    updateGridApi,
    createGridByImportFileApi,
    duplicateGridApi,
    createGridByIntegrationCloudsApi,
    createLocalizationGridApi,
    createLocalizationGridByImportApi,
    createGridByImportApi,
    createJsonLocalizationGridApi,
    createGridTemplateApi,
    getGridTemplatesApi,
    updateGridTemplateApi,
    deleteGridTemplateApi,
    getGridLastViewApi,
    getListGridFavorite,
    hanlderFavoriteGridApi,
    fetchGridDetailApi
} from 'services/grid';
import { getViewsApi } from 'services/view';
import { generateTempId } from 'utils/uuid';
import { enqueueSnackbar, closeSnackbar } from 'notifier/actions';
import { MULTIPLE_LINES } from 'const/columnTypes';
import { requestData, receiveData } from '../api/actions';
import isArray from 'lodash/isArray';
import CloseIcon from 'assets/images/svg/CloseIconSVG';
import moment from 'moment';
import i18n from 'i18n';

import { GRID_STATUS, VIEW_TYPES } from 'const/gridUI';
import { DUPLICATE_RECORD_OPTIONS } from 'const';
import * as versionControlActions from 'versionControl/actions';

export function setSelectedGrid(gridId) {
    return {
        type: types.SET_SELECTED_GRID,
        payload: gridId
    };
}

export function addGridsAfterDatabaseCreated({ database }) {
    return {
        type: types.ADD_GRID_AFTER_DATABASE_CREATED,
        payload: {
            database
        }
    };
}

export function generateGridsByDbId({ databases }) {
    return async function(dispatch, getState) {
        const { database } = getState();
        const { list: dbs = [] } = database;
        const gridListByDbIds = {};
        for (let db of dbs) {
            gridListByDbIds[db.id] = db?.grids || [];
        }
        dispatch(_mapGridsByDbId({ gridListByDbIds }));
    };
}

function _mapGridsByDbId({ gridListByDbIds }) {
    return {
        type: types.GENERATE_GRIDS_BY_DBID,
        payload: {
            gridListByDbIds
        }
    };
}

export function getGridsActionFailed({ dbId, error }) {
    return {
        type: types.GET_GRIDS_BY_DBID_FAILED,
        payload: {
            error,
            dbId
        }
    };
}

export function getGridsActionSuccess({ grids, dbId }) {
    return {
        type: types.GET_GRIDS_BY_DBID_SUCCESS,
        payload: {
            grids,
            dbId
        }
    };
}

export function getGrids({ dbId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const grids = await getGridsApi({ dbId });
            dispatch(getGridsActionSuccess({ dbId, grids }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(getGridsActionFailed({ error: message }));
            errorCallback && errorCallback(message);
        }
    };
}

export function fetchGridsByDatabase({ dbId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(requestData(types.FETCH_GRID_LIST_BY_DATABASE, dbId));

        try {
            const list = await getGridsApi({ dbId });

            dispatch(fetchGridByDatabaseSuccess({ dbId, list }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(receiveData(types.FETCH_GRID_LIST_BY_DATABASE_FAILED, { dbId, error: message }));
            errorCallback && errorCallback(message);
        }
    };
}

export function fetchGridByDatabaseSuccess({ dbId, list }) {
    return {
        type: types.FETCH_GRID_LIST_BY_DATABASE_SUCCESS,
        payload: {
            dbId,
            list
        }
    };
}

export function fetchGridViewsByDb({ dbId, gridId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(requestData(types.FETCH_GRIDVIEWS_BY_DATABASE, { dbId, gridId }));

        try {
            const list = await getViewsApi({
                dbId,
                gridId,
                paramOptions: {
                    includeDefaultView: false,
                    expand: VIEW_TYPES.ACCESS_VIEW
                }
            });
            dispatch(receiveData(types.FETCH_GRIDVIEWS_BY_DATABASE_SUCCESS, { dbId, gridId, list }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(receiveData(types.FETCH_GRIDVIEWS_BY_DATABASE_FAILED, { dbId, error: message }));
            errorCallback && errorCallback(message);
        }
    };
}

export function createGridAction({ dbId, grid }) {
    return {
        type: types.CREATE_GRID,
        payload: {
            grid,
            dbId
        }
    };
}

export function createGridSocketAction({ dbId, grid }) {
    return async function(dispatch, getState) {
        const { grid: stateGrid } = getState();
        const list = stateGrid?.list || {};
        if (isArray(list?.[dbId])) {
            const grids = list?.[dbId] || [];
            const index = grids?.findIndex(item => item?.id === grid?.id);

            if (index === -1) {
                dispatch(createGridAction({ dbId, grid }));
            }
        }
    };
}

export function createGridMessageFailed({ error }) {
    return {
        type: types.CREATE_GRID_MESSAGE_FAILED,
        payload: {
            error
        }
    };
}

export function createGridActionFailed({ dbId, oldId, error }) {
    return {
        type: types.CREATE_GRID_FAILED,
        payload: {
            error,
            dbId,
            oldId
        }
    };
}

export function createGridActionSuccess({ oldId, newId, dbId, newGrid }) {
    return {
        type: types.CREATE_GRID_SUCCESS,
        payload: {
            oldId,
            newId,
            dbId,
            newGrid
        }
    };
}

export function createTMGrid({ dbId, grid, successCallback, errorCallback }) {
    return async function(dispatch) {
        let gridExtra = {
            ...grid,
            totalRecord: 5,
            columns: [
                {
                    defaultValue: '',
                    name: 'Source',
                    type: MULTIPLE_LINES
                },
                {
                    defaultValue: '',
                    name: 'Target',
                    type: MULTIPLE_LINES
                }
            ]
        };

        dispatch(turnOnFirstCreateSkeleton({ dbId }));
        try {
            const newGrid = await createGridApi({ grid: gridExtra, dbId });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            dispatch(turnOnFirstPopup({ gridId: newGrid.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(createGridMessageFailed({ error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function createJsonLocalizationGrid({ dbId, body, query = {}, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(requestData(types.CREATE_LOCALIZATION_GRID));
        try {
            const newGrid = await createJsonLocalizationGridApi({ dbId, body, query });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            successCallback && successCallback(newGrid);
        } catch (error) {
            const { message } = error;
            dispatch(requestData(types.CREATE_LOCALIZATION_GRID_FAILED, { error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createNormalGrid({
    dbId,
    grid,
    columns,
    recordIdentifierType,
    globalUniquePublicRecordId,
    successCallback,
    errorCallback
}) {
    return async function(dispatch) {
        let gridExtra = {
            ...grid,
            totalRecord: 5,
            columns: columns || []
        };

        try {
            const newGrid = await createGridApi({
                grid: gridExtra,
                dbId,
                recordIdentifierType,
                globalUniquePublicRecordId
            });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            successCallback && successCallback(newGrid);
        } catch (error) {
            const { message } = error;
            dispatch(createGridMessageFailed({ error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function createLocalizationGrid({ dbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(requestData(types.CREATE_LOCALIZATION_GRID));
        try {
            const newGrid = await createLocalizationGridApi({ dbId, body });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            successCallback && successCallback(newGrid);
        } catch (error) {
            const { message } = error;
            dispatch(requestData(types.CREATE_LOCALIZATION_GRID_FAILED, { error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createLocalizationGridByImport({ resourceKey, dbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(requestData(types.CREATE_LOCALIZATION_GRID));
        try {
            const newGrid = await createLocalizationGridByImportApi({ dbId, body, resourceKey });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(requestData(types.CREATE_LOCALIZATION_GRID_FAILED, { error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createGridByImport({ resourceKey, dbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(requestData(types.CREATE_LOCALIZATION_GRID));
        const gridTempId = generateTempId();
        const fakeGrid = {
            id: gridTempId,
            name: body?.gridName,
            alteredTime: moment().format(),
            status: GRID_STATUS.UPLOADING
        };
        dispatch(createGridAction({ dbId, grid: fakeGrid }));

        try {
            const createdGrid = await createGridByImportApi({ dbId, body, resourceKey });
            const newGrid = {
                ...createdGrid,
                status: GRID_STATUS.UPLOADING
            };
            dispatch(
                updateGridAction({
                    dbId,
                    gridId: gridTempId,
                    newGrid
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(requestData(types.CREATE_LOCALIZATION_GRID_FAILED, { error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createGrid({ dbId, grid, successCallback, errorCallback }) {
    return async function(dispatch) {
        let gridExtra = {
            ...grid,
            totalRecord: 5,
            columns: [
                {
                    defaultValue: '',
                    name: i18n.t('grid_default_col_1'),
                    type: MULTIPLE_LINES
                },
                {
                    defaultValue: '',
                    name: i18n.t('grid_default_col_2'),
                    type: MULTIPLE_LINES
                }
            ]
        };

        dispatch(turnOnFirstCreateSkeleton({ dbId }));
        try {
            const newGrid = await createGridApi({ grid: gridExtra, dbId });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            dispatch(turnOnFirstPopup({ gridId: newGrid.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(createGridMessageFailed({ error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteGridAction({ gridId, dbId }) {
    return {
        type: types.DELETE_GRID,
        payload: {
            gridId,
            dbId
        }
    };
}

export function deleteGridSocketAction({ gridId, dbId }) {
    return async function(dispatch, getState) {
        const { grid: stateGrid } = getState();
        const { list } = stateGrid;
        if (isArray(list?.[dbId])) {
            dispatch(deleteGridAction({ gridId, dbId }));
        }
    };
}

export function deleteGridActionFailed({ gridId, dbId, error }) {
    return {
        type: types.DELETE_GRID_FAILED,
        payload: {
            error,
            dbId,
            gridId
        }
    };
}

export function deleteGridActionSuccess({ gridId, dbId }) {
    return {
        type: types.DELETE_GRID_SUCCESS,
        payload: {
            gridId,
            dbId
        }
    };
}

export function deleteGrid({ dbId, gridId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(deleteGridAction({ gridId, dbId }));
        try {
            await deleteGridApi({ gridId, dbId });
            dispatch(deleteGridActionSuccess({ gridId, dbId }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('grid_delete_success_msg'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(deleteGridActionFailed({ dbId, gridId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateGridAction({ dbId, gridId, newGrid }) {
    return {
        type: types.UPDATE_GRID,
        payload: {
            gridId,
            dbId,
            newGrid
        }
    };
}

export function deleteGridRealtime({ dbId, gridId }) {
    return async function(dispatch, getState) {
        dispatch(
            enqueueSnackbar({
                message: i18n.t('grid_realtime_deleted_msg'),
                type: 'info',
                duration: 100000,
                action: key => (
                    <CloseIcon
                        style={{
                            cursor: 'pointer',
                            fontSize: 20,
                            opacity: 0.8
                        }}
                        color="white"
                        onClick={() => {
                            dispatch(closeSnackbar(key));
                            console.log('redirect');
                        }}
                    />
                )
            })
        );
    };
}

export function updateGridSocketAction({ gridId, dbId, newGrid }) {
    return async function(dispatch, getState) {
        const { grid: stateGrid } = getState();
        const { list } = stateGrid;
        if (isArray(list?.[dbId])) {
            dispatch(updateGridAction({ gridId, dbId, newGrid }));
        }
    };
}

export function updateGridActionFailed({ dbId, gridId, oldGrid, error }) {
    return {
        type: types.UPDATE_GRID_FAILED,
        payload: {
            error,
            dbId,
            gridId,
            oldGrid
        }
    };
}

export function updateGridActionSuccess() {
    return {
        type: types.UPDATE_GRID_SUCCESS
    };
}

export function updateGrid({ dbId, oldGrid, newGrid, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(updateGridAction({ gridId: oldGrid.id, dbId, newGrid }));
        try {
            await updateGridApi({ gridId: oldGrid.id, dbId, newGrid });
            dispatch(updateGridActionSuccess());
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(updateGridActionFailed({ dbId, gridId: oldGrid.id, oldGrid, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function getGridAction({ dbId, gridId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const grid = await fetchGridDetailApi({ dbId, gridId });

            successCallback && successCallback({ grid });
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function fetchFavoriteGridWorkspaces({ companyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const _companyId = companyId || auth.currentUser.companyId;
        dispatch(_fetchGridFavoriteAction());
        try {
            const listFavorite = await getListGridFavorite({ companyId: _companyId });
            dispatch(_fetchFavoriteActionSuccess({ listFavorite }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchGridFavoriteActionFailed({ error: message }));

            errorCallback && errorCallback();
        }
    };
}

export function changeFavoriteGrid({ gridId, dbId, action, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth?.currentUser?.companyId;
        let body = {
            gridId,
            dbId,
            scope: 'GRID',
            action: action
        };
        try {
            if (action === types.REMOVE_FAVORITE_GRID) {
                const newGrid = await hanlderFavoriteGridApi({ companyId, body });
                dispatch(_removeFavoriteGrid({ gridId: newGrid?.gridId }));
                dispatch(
                    enqueueSnackbar({
                        message: `Removed from favorite grids`,
                        type: 'info'
                    })
                );
                successCallback && successCallback();
            } else {
                const newGrid = await hanlderFavoriteGridApi({ companyId, body });
                dispatch(_addFavorireGrid({ action, newGrid }));
                dispatch(
                    enqueueSnackbar({
                        message: `Added to favorite grids`,
                        type: 'info'
                    })
                );
                successCallback && successCallback();
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _removeFavoriteGridByProjectId({ projectId }) {
    return {
        type: types.REMOVE_FAVORITE_GRID_BY_PROJECT_ID,
        payload: {
            projectId
        }
    };
}

export function _removeFavoriteGrid({ gridId }) {
    return {
        type: types.REMOVE_FAVORITE_GRID,
        payload: {
            gridId
        }
    };
}

export function _updateNameFavoriteGrid({ gridId, newName }) {
    return {
        type: types.UPDATE_NAME_FAVORITE,
        payload: {
            gridId,
            newName
        }
    };
}

export function _addFavorireGrid({ action, newGrid }) {
    return {
        type: action,
        payload: {
            newGrid
        }
    };
}

export function _fetchGridFavoriteAction() {
    return {
        type: types.FETCH_GRID_FAVORITE
    };
}
export function _fetchFavoriteActionSuccess({ listFavorite }) {
    return {
        type: types.FETCH_GRID_FAVORITE_SUCCESS,
        payload: {
            listFavorite
        }
    };
}

function _fetchGridFavoriteActionFailed({ error }) {
    return {
        type: types.FETCH_GRID_FAVORITE_FAILED,
        payload: {
            error
        }
    };
}

export function turnOnFirstPopup({ gridId }) {
    return {
        type: types.TURN_ON_SHOW_FIRST_POP_UP_CREATE_GRID,
        payload: {
            gridId
        }
    };
}

export function turnOffFirstPopup() {
    return {
        type: types.TURN_OFF_SHOW_FIRST_POP_UP_CREATE_GRID
    };
}

export function turnOnFirstCreateSkeleton({ dbId }) {
    return {
        type: types.TURN_ON_FIRST_CREATE_SKELETON,
        payload: {
            dbIdCreatingGrid: dbId
        }
    };
}

export function createGridByImportFile({ name, dbId, file, successCallback, errorCallback }) {
    return async function(dispatch) {
        // dispatch(turnOnFirstCreateSkeleton({ dbId }));
        const gridTempId = generateTempId();
        const fakeGrid = {
            id: gridTempId,
            name,
            alteredTime: moment().format(),
            status: GRID_STATUS.UPLOADING
        };
        dispatch(createGridAction({ dbId, grid: fakeGrid }));

        try {
            let formData = new FormData();
            formData.append('file', file);
            const createdGrid = await createGridByImportFileApi({ formData, dbId });
            const newGrid = {
                ...createdGrid,
                status: GRID_STATUS.UPLOADING
            };
            dispatch(
                updateGridAction({
                    dbId,
                    gridId: gridTempId,
                    newGrid
                })
            );
            // dispatch(turnOnFirstPopup({ gridId: createdGrid.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(createGridMessageFailed({ error: message }));
            dispatch(deleteGridActionSuccess({ dbId, gridId: gridTempId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function createGridByIntegrationClouds({ dbId, url, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(turnOnFirstCreateSkeleton({ dbId }));
        try {
            const newGrid = await createGridByIntegrationCloudsApi({ url, dbId });
            dispatch(createGridAction({ dbId, grid: newGrid }));
            dispatch(turnOnFirstPopup({ gridId: newGrid.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(createGridMessageFailed({ error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateGridStatus({ dbId, gridId, status }) {
    return {
        type: types.UPDATE_GRID_STATUS,
        payload: {
            dbId,
            gridId,
            status
        }
    };
}

export function updateGridBranchStatus({ dbId, gridId, status }) {
    return (dispatch, getState) => {
        const { gridUI } = getState();
        const parentGridId = gridUI?.parentGridId;
        if (parentGridId === gridId) {
            dispatch(updateGridStatus({ dbId, gridId, status }));
        } else {
            dispatch(versionControlActions.changeBranchStatus({ masterId: parentGridId, gridId, status }));
        }
    };
}

export function updateGridTemplateStatus({ id, status }) {
    return {
        type: types.UPDATE_TEMPLATE_STATUS,
        payload: {
            id,
            status
        }
    };
}

export function duplicateGrid({
    clonedGrid,
    name,
    duplicateRecordOption,
    dbId,
    destinationDbId,
    successCallback,
    errorCallback
}) {
    return async function(dispatch) {
        // dispatch(turnOnFirstCreateSkeleton({ dbId }));
        const gridTempId = generateTempId();
        const fakeGrid = {
            ...clonedGrid,
            customProperties: {
                color: clonedGrid?.customProperties?.color
            },
            id: gridTempId,
            name,
            alteredTime: moment().format(),
            status: GRID_STATUS.DUPLICATING
        };
        dispatch(createGridAction({ dbId: destinationDbId || dbId, grid: fakeGrid }));

        try {
            let body = {
                name,
                customProperties: {
                    color: clonedGrid?.customProperties?.color
                },
                duplicateRecordOption
            };

            if (destinationDbId) {
                body = {
                    ...body,
                    destinationDbId
                };
            }

            const duplicatedGrid = await duplicateGridApi({
                dbId,
                gridId: clonedGrid?.id,
                body: body
            });
            const newGrid = {
                ...duplicatedGrid,
                status:
                    duplicateRecordOption === DUPLICATE_RECORD_OPTIONS.EXCLUDE
                        ? GRID_STATUS.ACTIVE
                        : GRID_STATUS.DUPLICATING
            };
            dispatch(
                updateGridAction({
                    dbId: destinationDbId || dbId,
                    gridId: gridTempId,
                    newGrid
                })
            );
            // dispatch(turnOnFirstPopup({ gridId: createdGrid.id }));
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: `${name} duplicated succesfully`,
                    type: 'info'
                })
            );
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;

            dispatch(createGridMessageFailed({ error: originalMessage }));
            dispatch(deleteGridActionSuccess({ dbId: destinationDbId || dbId, gridId: gridTempId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function fetchGridTemplates({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.FETCH_GRID_TEMPLATES));

            const gridTemplates = await getGridTemplatesApi();
            dispatch(_fetchGridTemplateSuccess({ templates: gridTemplates }));
            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;

            dispatch(receiveData(types.FETCH_GRID_TEMPLATES_FAILED));
            dispatch(createGridMessageFailed({ error: originalMessage }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchGridTemplateSuccess({ templates }) {
    return {
        type: types.FETCH_GRID_TEMPLATES_SUCCESS,
        payload: {
            templates
        }
    };
}

export function saveGridAsTemplate({
    dbId,
    cloneGridId,
    cloneGridColor,
    name,
    description,
    duplicateRecordOption,
    duplicateGroupAccessOption,
    duplicateAutomationOption,
    successCallback,
    errorCallback
}) {
    return async function(dispatch) {
        try {
            const template = await createGridTemplateApi({
                body: {
                    name,
                    customProperties: {
                        color: cloneGridColor
                    },
                    duplicateRecordOption,
                    duplicateGroupAccessOption,
                    duplicateAutomationOption,
                    template: true,
                    sourceDbId: dbId,
                    sourceGridId: cloneGridId,
                    description
                }
            });
            dispatch(_saveTemplateSuccess({ template }));
            successCallback && successCallback(template);
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;
            dispatch(createGridMessageFailed({ error: originalMessage }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _saveTemplateSuccess({ template }) {
    return {
        type: types.SAVE_GRID_AS_TEMPLATE,
        payload: {
            template
        }
    };
}

export function createGridFromTemplate({ dbId, template, successCallback, errorCallback }) {
    return async function(dispatch) {
        const gridTempId = generateTempId();
        const fakeGrid = {
            ...template,
            customProperties: {
                color: template?.customProperties?.color
            },
            id: gridTempId,
            name: template?.name,
            alteredTime: moment().format(),
            status: GRID_STATUS.DUPLICATING
        };

        dispatch(createGridAction({ dbId, grid: fakeGrid }));

        try {
            const newGridFromTemplate = await createGridApi({
                dbId,
                grid: {
                    name: template?.name,
                    customProperties: {
                        color: template?.customProperties?.color
                    },
                    templateGridId: template?.id,
                    description: template?.description
                }
            });

            const newGrid = {
                ...newGridFromTemplate,
                status: GRID_STATUS.DUPLICATING
            };

            dispatch(
                updateGridAction({
                    dbId,
                    gridId: gridTempId,
                    newGrid
                })
            );

            successCallback && successCallback(template);
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;
            dispatch(createGridMessageFailed({ error: originalMessage }));
            dispatch(deleteGridActionSuccess({ dbId, gridId: gridTempId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateGridTemplate({ templateId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const updatedTemplate = await updateGridTemplateApi({ templateId, body });
            dispatch(_updateTemplateSuccess({ id: templateId, newTemplate: updatedTemplate }));
            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;
            dispatch(createGridMessageFailed({ error: originalMessage }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _updateTemplateSuccess({ id, newTemplate }) {
    return {
        type: types.UPDATE_GRID_TEMPLATE,
        payload: {
            id,
            newTemplate
        }
    };
}

export function deleteGridTemplate({ templateId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await deleteGridTemplateApi({ templateId });
            dispatch(_deleteTemplateSuccess({ id: templateId }));
            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;
            dispatch(createGridMessageFailed({ error: originalMessage }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _deleteTemplateSuccess({ id }) {
    return {
        type: types.REMOVE_GRID_TEMPLATE,
        payload: {
            id
        }
    };
}

export function getGridLastView({ dbId, gridId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responseData = await getGridLastViewApi({ dbId, gridId });
            successCallback && successCallback(responseData);
        } catch (error) {
            const message = error?.message;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}
