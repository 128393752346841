import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

import TextDelete from './diffCheckComponents/delete/TextDelete';
import SingleSelectionDelete from './diffCheckComponents/delete/SingleSelection';
import MultipleSelectionDelete from './diffCheckComponents/delete/MultipleSelection';
import BooleanDelete from './diffCheckComponents/delete/Boolean';
import DatetimeDelete from './diffCheckComponents/delete/Datetime';
import FileDelete from './diffCheckComponents/delete/File';
import ReferenceDelete from './diffCheckComponents/delete/Reference';

import SingleSelectionUpdate from './diffCheckComponents/update/SingleSelection';
import MultipleSelectionUpdate from './diffCheckComponents/update/MultipleSelection';
import BooleanUpdate from './diffCheckComponents/update/Boolean';
import DatetimeUpdate from './diffCheckComponents/update/Datetime';
import TextUpdate from './diffCheckComponents/update/TextUpdate';
import NumberUpdate from './diffCheckComponents/update/NumberUpdate';
import FileUpdate from './diffCheckComponents/update/File';
import ReferenceUpdate from './diffCheckComponents/update/Reference';

import ComingSoon from './diffCheckComponents/ComingSoon';

import DiffTextWithOtherTypes from './components/diffType/Text';
import DiffDatetimeWithOtherTypes from './components/diffType/Datetime';
import DiffBooleanWithOtherTypes from './components/diffType/Boolean';
import DiffMultipleSelectionWithOtherTypes from './components/diffType/MultipleSelection';
import DiffSingleSelectionWithOtherTypes from './components/diffType/SingleSelection';
import DiffRefWithOtherTypes from './components/diffType/Ref';
import DiffFilesWithOtherTypes from './components/diffType/Files';
import * as columnTypes from 'const/columnTypes';
import { MAX_ROW_RESIZE, PREVIEW_STATUS } from 'const/gridUI';
import { getNumberDisplay } from 'utils/gridUI/cell';
import hexToRgba from 'hex-to-rgba';

import Conflict from './diffCheckComponents/conflict';
import Tooltip from 'components/tooltip/Base';
import RevertSVG from 'assets/images/svg/RevertSVG';
import PopperMenu from 'components/menus/Popper';
import ConfirmRevert from 'components/confirmBox/Small';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import LangStatus from 'components/langStatus';
import { useDependencyBlindness } from 'hooks/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: 500,
        minWidth: 320,
        maxHeight: MAX_ROW_RESIZE,
        overflow: 'hidden auto'
    },
    fullWidth: {
        width: '100%',
        flex: 1
    },
    revertIcon: {
        marginLeft: theme.spacing(2)
    },
    iconAction: {
        cursor: 'pointer'
    },
    convert: {
        paddingTop: 0,
        paddingBottom: 0
    },
    diffDependency: {
        padding: 3,
        borderRadius: 1,
        background: theme.colors.griptide
    },
    dependencyStatus: {
        width: 3,
        height: 18,
        pointerEvents: 'none',
        borderRadius: 0
    },
    outOfDate: {
        background: hexToRgba(theme.colors.sun, 0.8)
    },
    upToDate: {
        background: hexToRgba(theme.colors.atlantis, 0.8)
    },
    notTranslated: {
        background: hexToRgba(theme.colors.brightRed, 0.8)
    }
}));

function RenderDiffOtherTypes({
    columnType,
    previousColumnType,
    data,
    previousData,
    options,
    customProperties,
    numberFormat,
    ...rest
}) {
    switch (columnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.TRANSLATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.ALTERED_BY:
        case columnTypes.RECORD_ID:
        case columnTypes.FORMULA:
            return (
                <DiffTextWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );

        case columnTypes.NUMBER: {
            const displayData = getNumberDisplay({ numberFormat, data });
            return (
                <DiffTextWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={displayData}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        }

        case columnTypes.BOOLEAN:
            return (
                <DiffBooleanWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.DATETIME:
            return (
                <DiffDatetimeWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.SINGLE_SELECTION:
            return (
                <DiffSingleSelectionWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.MULTIPLE_SELECTIONS:
            return (
                <DiffMultipleSelectionWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.REFERENCE:
            return (
                <DiffRefWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.FILES:
            return (
                <DiffFilesWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        default:
            return (
                <ComingSoon
                    previousColumnType={previousColumnType}
                    columnType={columnType}
                    isSameType={previousColumnType === columnType}
                    {...rest}
                />
            );
    }
}

function DiffCheckUI({
    status,
    columnType,
    previousColumnType,
    data,
    previousData,
    handleClickAway,
    options = [],
    customProperties = {},
    numberFormat,
    showRevertIcon,
    onRevert,
    previousDependencyStatus,
    dependencyStatus,
    sourceStatus,
    previousSourceStatus,
    ...rest
}) {
    const classes = useStyles();
    const rootRef = React.useRef();
    const { t } = useTranslation();
    const [revertAnchorEl, setRevertAnchorEl] = React.useState(null);
    const containerRef = React.useRef();
    const dependencyBlindness = useDependencyBlindness();

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleRevertCellClick = event => {
        setRevertAnchorEl(revertAnchorEl ? null : event.currentTarget);
        stopPropagation(event);
    };

    const handleRevertClickAway = e => {
        stopPropagation(e);
        setRevertAnchorEl(null);
    };

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const isSameType = columnType === previousColumnType;

    const renderDeleteDiffSameType = React.useCallback(() => {
        switch (columnType) {
            case columnTypes.SINGLE_LINE:
            case columnTypes.MULTIPLE_LINES:
            case columnTypes.RICH_TEXT:
            case columnTypes.MARKDOWN:
            case columnTypes.TRANSLATION:
            case columnTypes.JSON_LD:
            case columnTypes.HTML:
            case columnTypes.YAML:
            case columnTypes.ALTERED_BY:
            case columnTypes.CREATED_BY:
            case columnTypes.RECORD_ID:
            case columnTypes.FORMULA:
                return <TextDelete previousData={previousData} {...rest} />;
            case columnTypes.NUMBER: {
                const displayPrevious = getNumberDisplay({ numberFormat, data: previousData });
                console.log('displayPrevious', displayPrevious);
                return <TextDelete previousData={displayPrevious} {...rest} />;
            }

            case columnTypes.SINGLE_SELECTION:
                return <SingleSelectionDelete previousData={previousData} {...rest} />;
            case columnTypes.MULTIPLE_SELECTIONS:
                return <MultipleSelectionDelete previousData={previousData} {...rest} />;
            case columnTypes.BOOLEAN:
                return <BooleanDelete previousData={previousData} {...rest} />;
            case columnTypes.DATETIME:
            case columnTypes.ALTERED_TIME:
            case columnTypes.CREATED_TIME:
                return <DatetimeDelete previousData={previousData} {...rest} />;
            case columnTypes.FILES:
                return <FileDelete previousData={previousData} {...rest} />;
            case columnTypes.REFERENCE:
                return <ReferenceDelete previousData={previousData} {...rest} />;
            default:
                return null;
        }
    }, [columnType, previousData, rest, numberFormat]);

    const renderUpdateDiffSameType = React.useCallback(() => {
        switch (columnType) {
            case columnTypes.SINGLE_LINE:
            case columnTypes.MULTIPLE_LINES:
            case columnTypes.RICH_TEXT:
            case columnTypes.MARKDOWN:
            case columnTypes.TRANSLATION:
            case columnTypes.JSON_LD:
            case columnTypes.HTML:
            case columnTypes.YAML:
            case columnTypes.ALTERED_BY:
            case columnTypes.RECORD_ID:
            case columnTypes.FORMULA:
                return <TextUpdate data={data} previousData={previousData} {...rest} />;

            case columnTypes.NUMBER: {
                const displayData = getNumberDisplay({ numberFormat, data });
                const displayPrevious = getNumberDisplay({ numberFormat, data: previousData });
                return <NumberUpdate data={displayData} previousData={displayPrevious} {...rest} />;
            }

            case columnTypes.SINGLE_SELECTION:
                return <SingleSelectionUpdate data={data} previousData={previousData} {...rest} />;
            case columnTypes.MULTIPLE_SELECTIONS:
                return <MultipleSelectionUpdate data={data} previousData={previousData} {...rest} />;
            case columnTypes.BOOLEAN:
                return <BooleanUpdate data={data} previousData={previousData} {...rest} />;
            case columnTypes.DATETIME:
            case columnTypes.ALTERED_TIME:
                return <DatetimeUpdate data={data} previousData={previousData} {...rest} />;
            case columnTypes.FILES:
                return <FileUpdate data={data} previousData={previousData} {...rest} />;
            case columnTypes.REFERENCE:
                return <ReferenceUpdate data={data} previousData={previousData} {...rest} />;
            default:
                return null;
        }
    }, [columnType, previousData, data, rest, numberFormat]);

    const handleRevert = React.useCallback(
        e => {
            onRevert && onRevert(e);
            handleClickAway(e);
        },
        [onRevert, handleClickAway]
    );

    return (
        <Grid ref={rootRef} container direction="row" className={classes.root}>
            {(previousDependencyStatus !== dependencyStatus || sourceStatus !== previousSourceStatus) && (
                <Grid item style={{ marginRight: 2, position: 'relative', top: -2 }}>
                    <Grid alignItems="center" className={classes.diffDependency} container direction="row">
                        <Grid item>
                            <LangStatus
                                dependencyBlindness={dependencyBlindness}
                                sourceStatus={previousSourceStatus}
                                dependencyStatus={previousDependencyStatus}
                            />
                        </Grid>
                        <Grid item style={{ display: 'flex', paddingLeft: 2, paddingRight: 2 }}>
                            <ArrowNextSVG color={'black'} />
                        </Grid>
                        <Grid item>
                            <LangStatus
                                dependencyBlindness={dependencyBlindness}
                                sourceStatus={sourceStatus}
                                dependencyStatus={dependencyStatus}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item className={classes.fullWidth}>
                {isSameType && status === PREVIEW_STATUS.DELETED && renderDeleteDiffSameType()}
                {isSameType && status === PREVIEW_STATUS.UPDATED && renderUpdateDiffSameType()}
                {isSameType && status === PREVIEW_STATUS.CONFLICT && (
                    <Conflict
                        columnType={columnType}
                        previousColumnType={previousColumnType}
                        data={data}
                        options={options}
                        customProperties={customProperties}
                        previousData={previousData}
                        numberFormat={numberFormat}
                        {...rest}
                    />
                )}
                {!isSameType && (
                    <RenderDiffOtherTypes
                        columnType={columnType}
                        previousColumnType={previousColumnType}
                        data={data}
                        options={options}
                        customProperties={customProperties}
                        previousData={previousData}
                        numberFormat={numberFormat}
                        {...rest}
                    />
                )}
            </Grid>
            {showRevertIcon && (
                <Grid item className={classes.revertIcon}>
                    <Tooltip title={t('tooltip_record_content')}>
                        <span onClick={handleRevertCellClick} className={`${classes.iconAction}`}>
                            <RevertSVG />
                        </span>
                    </Tooltip>
                </Grid>
            )}

            <div ref={containerRef}></div>

            {showRevertIcon && (
                <PopperMenu
                    className={classes.convert}
                    anchorEl={revertAnchorEl}
                    placement={'right-end'}
                    id={`replace_cell_data`}
                    container={containerRef.current}
                >
                    <ConfirmRevert
                        title={'Replace cell data?'}
                        onCancel={handleRevertClickAway}
                        onAgreed={handleRevert}
                    />
                </PopperMenu>
            )}
        </Grid>
    );
}

export default React.memo(DiffCheckUI);
