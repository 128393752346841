import React from 'react';

const JiraStorySVG = props => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 0H17.5C18.163 0 18.7989 0.263392 19.2678 0.732233C19.7366 1.20107 20 1.83696 20 2.5V17.5C20 18.163 19.7366 18.7989 19.2678 19.2678C18.7989 19.7366 18.163 20 17.5 20H2.5C1.83696 20 1.20107 19.7366 0.732233 19.2678C0.263392 18.7989 0 18.163 0 17.5L0 2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0V0ZM13.0392 16.2625L14.1142 14.9892L10 11.5167L5.88417 14.9892C5.86583 15.0042 5.83333 14.9933 5.83333 15.1275V5.57333C5.83333 5.29 6.17667 5 6.66667 5H13.3333C13.8225 5 14.1667 5.29083 14.1667 5.57333V15.1275C14.1667 14.9925 14.1333 15.005 14.1142 14.9892L13.0392 16.2625C14.0708 17.1333 15.8333 16.5175 15.8333 15.1275V5.57333C15.8333 4.30167 14.6858 3.33333 13.3333 3.33333H6.66667C5.31333 3.33333 4.16667 4.30083 4.16667 5.57333V15.1275C4.16667 16.5175 5.9275 17.1333 6.95917 16.2625L10 13.6975L13.0392 16.2625Z"
                fill="#36B37E"
            />
        </svg>
    );
};

export default React.memo(JiraStorySVG);
