import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 6px',
        background: 'rgba(249, 119, 42, 0.8)',
        borderRadius: 2
    },
    content: {
        fontWeight: 500,
        fontSize: 11,
        color: `${theme.colors.white} !important`
    }
}));

const BetaTag = props => {
    const classes = useStyles();
    return (
        <Grid item className={classes.root} {...props}>
            <Typography className={classes.content}>Beta</Typography>
        </Grid>
    );
};

export default React.memo(BetaTag);
