import * as types from './types';
import {
    getDatabasesApi,
    getDatabaseApi,
    createDatabaseApi,
    deleteDatabaseApi,
    updateDatabaseApi,
    getDatabaseBackupFilesApi,
    backupDatabaseApi,
    restoreDatabaseApi,
    getDatabaseBackupStatusApi,
    duplicateDatabaseApi,
    getSharedGroupsOfDatabaseApi,
    shareDatabaseApi,
    unshareDatabaseApi,
    getScheduleBackupDatabaseApi,
    createScheduleBackupDatabaseApi
} from 'services/database';
import { enqueueSnackbar } from 'notifier/actions';
import { addGridsAfterDatabaseCreated, generateGridsByDbId, _fetchGridFavoriteAction } from '../grids/actions';
import { receiveData } from '../api/actions';
import { DATABASE_STATUS, SORT_BY_CRITERIA } from 'const';
import { generateTempId } from 'utils/uuid';
import moment from 'moment';
import i18n from 'i18n';
import { updateSortCriteria } from 'workspaces/actions';

export function getDatabasesAction({ workspaceId }) {
    return {
        type: types.GET_DATABASE_LIST,
        payload: {
            workspaceId
        }
    };
}

export function getDatabasesActionFailed({ error, workspaceId }) {
    return {
        type: types.GET_DATABASE_LIST_FAILED,
        payload: {
            error,
            workspaceId
        }
    };
}

export function getDatabasesActionSuccess({ workspaceId, dbs = [], isForced }) {
    return {
        type: types.GET_DATABASE_LIST_SUCCESS,
        payload: {
            dbs,
            workspaceId,
            isForced
        }
    };
}

export function getDatabases({ workspaceId, isForced = false, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        dispatch(getDatabasesAction({ workspaceId }));
        try {
            const dbs = await getDatabasesApi({ workspaceId, companyId });
            dispatch(getDatabasesActionSuccess({ workspaceId, dbs, isForced }));
            dispatch(
                generateGridsByDbId({
                    databases: dbs
                })
            );
            successCallback && successCallback({ databases: dbs });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(getDatabasesActionFailed({ error: message, workspaceId }));
            errorCallback && errorCallback();
        }
    };
}

export function getDatabase(dbId) {
    return async function(dispatch) {
        try {
            const resDb = await getDatabaseApi(dbId);

            dispatch(receiveData(types.GET_DATABASE_DETAIL_SUCCESS, resDb));
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function createDatabaseActionSocket({ database, workspaceId }) {
    return {
        type: types.CREATE_DATABASE_SOCKET,
        payload: {
            database,
            workspaceId
        }
    };
}

export function createDatabaseAction({ database, workspaceId }) {
    return {
        type: types.CREATE_DATABASE,
        payload: {
            database,
            workspaceId
        }
    };
}

export function createDatabaseActionFailed({ error, id, workspaceId }) {
    return {
        type: types.CREATE_DATABASE_FAILED,
        payload: {
            error,
            id,
            workspaceId
        }
    };
}

export function createDatabasesActionSuccess({ oldId, newId, newDatabase, workspaceId }) {
    return {
        type: types.CREATE_DATABASE_SUCCESS,
        payload: {
            oldId,
            newId,
            newDatabase,
            workspaceId
        }
    };
}

export function createDatabaseSocketAction({ database }) {
    return async function(dispatch, getState) {
        const { workspace } = getState();
        const { selectedWorkspace } = workspace;
        const { workspaceId } = database;
        // eslint-disable-next-line eqeqeq
        if (workspaceId == selectedWorkspace?.id) {
            dispatch(createDatabaseActionSocket({ database, workspaceId }));
            dispatch(addGridsAfterDatabaseCreated({ database }));
        }
    };
}

export function createDatabase({ workspaceId, database, isOpenPopup = true, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const databaseFormat = {
            name: database.name,
            workspaceId
        };
        dispatch(
            createDatabaseAction({
                workspaceId,
                database: {
                    ...database,
                    workspaceId
                }
            })
        );

        dispatch(turnOnFirstCreateSkeleton({ isSkeletonLoadingId: database.id }));
        try {
            const newDatabase = await createDatabaseApi({
                database: databaseFormat,
                workspaceId,
                companyId
            });
            dispatch(
                createDatabasesActionSuccess({
                    oldId: database.id,
                    newId: newDatabase.id,
                    newDatabase,
                    workspaceId
                })
            );
            dispatch(addGridsAfterDatabaseCreated({ database: newDatabase }));
            isOpenPopup && dispatch(turnOnFirstPopup({ databaseId: newDatabase.id }));
            dispatch(
                updateSortCriteria({
                    workspaceId,
                    dbId: newDatabase.id,
                    data: {
                        field: SORT_BY_CRITERIA.CREATED_DATE,
                        direction: 'DESC'
                    }
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(createDatabaseActionFailed({ error: message, id: database.id, workspaceId }));
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteDatabaseAction({ dbId }) {
    return {
        type: types.DELETE_DATABASE,
        payload: {
            dbId
        }
    };
}

export function deleteDatabaseActionFailed({ dbId, error }) {
    return {
        type: types.DELETE_DATABASE_FAILED,
        payload: {
            dbId,
            error
        }
    };
}

export function deleteDatabaseActionSuccess({ dbId, workspaceId }) {
    return {
        type: types.DELETE_DATABASE_SUCCESS,
        payload: {
            dbId,
            workspaceId
        }
    };
}

export function deleteDatabase({ dbId, workspaceId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { workspace } = getState();
        const { selectedWorkspace } = workspace;

        const wpIdCombined = selectedWorkspace?.id || workspaceId;

        dispatch(deleteDatabaseAction({ dbId }));
        try {
            await deleteDatabaseApi({ dbId });
            dispatch(deleteDatabaseActionSuccess({ dbId, workspaceId: wpIdCombined }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`database_deleted`),
                    type: 'info'
                })
            );
            dispatch(_fetchGridFavoriteAction());

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(deleteDatabaseActionFailed({ dbId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateDatabaseAction({ dbId, newDatabase }) {
    return {
        type: types.UPDATE_DATABASE,
        payload: {
            dbId,
            newDatabase
        }
    };
}

export function updateDatabaseActionFailed({ dbId, oldDatabase, error }) {
    return {
        type: types.UPDATE_DATABASE_FAILED,
        payload: {
            error,
            dbId,
            oldDatabase
        }
    };
}

export function updateDatabaseActionSuccess() {
    return {
        type: types.UPDATE_DATABASE_SUCCESS
    };
}

export function updateDatabase({ dbId, oldDatabase, newDatabase, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { workspace } = getState();
        const { selectedWorkspace } = workspace;
        const formattedNewDatabase = {
            ...newDatabase,
            workspaceId: selectedWorkspace?.id
        };
        dispatch(updateDatabaseAction({ dbId, newDatabase: formattedNewDatabase }));
        try {
            await updateDatabaseApi({ dbId, newDatabase: formattedNewDatabase });
            dispatch(updateDatabaseActionSuccess());
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(updateDatabaseActionFailed({ dbId, oldDatabase, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function turnOnFirstPopup({ databaseId }) {
    return {
        type: types.TURN_ON_SHOW_FIRST_POP_UP_CREATE_DATABASE,
        payload: {
            databaseId
        }
    };
}

export function turnOnFirstCreateSkeleton({ isSkeletonLoadingId }) {
    return {
        type: types.TURN_ON_FIRST_CREATE_SKELETON,
        payload: {
            isSkeletonLoadingId
        }
    };
}

export function turnOffFirstPopup() {
    return {
        type: types.TURN_OFF_SHOW_FIRST_POP_UP_CREATE_DATABASE
    };
}

export function getDatabaseBackupFiles({ dbId, offset, limit, options }) {
    return async function(dispatch, getState) {
        dispatch(_getDatabaseBackupFiles({ dbId }));
        try {
            const { files, total } = await getDatabaseBackupFilesApi({ dbId, offset, limit, options });
            dispatch(
                _getDatabaseBackupFilesSuccess({
                    dbId,
                    files,
                    offset: offset > 0 && !files?.length ? offset - limit : offset,
                    total
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(_getDatabaseBackupFilesFailed({ dbId, message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _getDatabaseBackupFiles({ dbId }) {
    return {
        type: types.FETCH_DATABASE_BACKUP_FILES,
        payload: { dbId }
    };
}

function _getDatabaseBackupFilesSuccess({ dbId, files, offset, total }) {
    return {
        type: types.FETCH_DATABASE_BACKUP_FILES_SUCCESS,
        payload: { dbId, files, offset, total }
    };
}

function _getDatabaseBackupFilesFailed({ dbId, message }) {
    return {
        type: types.FETCH_DATABASE_BACKUP_FILES_SUCCESS,
        payload: { dbId, message }
    };
}

export function backupDatabase({ dbId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(setDatabaseStatus({ dbId, status: DATABASE_STATUS.BACKING_UP }));
        try {
            await backupDatabaseApi({ dbId });

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE }));
            errorCallback && errorCallback();
        }
    };
}

export function restoreDatabase({ dbId, backupId }) {
    return async function(dispatch, getState) {
        dispatch(setDatabaseStatus({ dbId, status: DATABASE_STATUS.RESTORING }));
        try {
            await restoreDatabaseApi({ dbId, backupId });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE }));
        }
    };
}

export function getDatabaseBackupStatus({ dbId }) {
    return async function(dispatch, getState) {
        dispatch(_getDatabaseBackupStatus({ dbId }));
        try {
            const files = await getDatabaseBackupStatusApi({ dbId });
            dispatch(_getDatabaseBackupStatusSuccess({ dbId, files }));
        } catch (error) {
            const { message } = error;
            dispatch(_getDatabaseBackupStatusFailed({ dbId, message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _getDatabaseBackupStatus({ dbId }) {
    return {
        type: types.FETCH_DATABASE_BACKUP_FILES,
        payload: { dbId }
    };
}

function _getDatabaseBackupStatusSuccess({ dbId, files }) {
    return {
        type: types.FETCH_DATABASE_BACKUP_FILES_SUCCESS,
        payload: { dbId, files }
    };
}

function _getDatabaseBackupStatusFailed({ dbId, message }) {
    return {
        type: types.FETCH_DATABASE_BACKUP_FILES_SUCCESS,
        payload: { dbId, message }
    };
}

//! Backup Scheduler API

export function createScheduleBackupDatabaseAction({ dbId, body }) {
    return async function(dispatch) {
        try {
            await createScheduleBackupDatabaseApi({ dbId, body });
            const data = await getScheduleBackupDatabaseApi({ dbId });
            dispatch(_getScheduleBackupDatabaseSuccess({ dbId, data }));

            dispatch(
                enqueueSnackbar({
                    message: 'Backup schedule has been saved',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function getScheduleBackupDatabaseAction({ dbId }) {
    return async function(dispatch) {
        try {
            const data = await getScheduleBackupDatabaseApi({ dbId });
            dispatch(_getScheduleBackupDatabaseSuccess({ dbId, data }));
        } catch (error) {
            const { message } = error;
            dispatch(_getScheduleBackupDatabaseSuccess({ dbId, message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _getScheduleBackupDatabaseSuccess({ dbId, data }) {
    return {
        type: types.SET_DATABASE_BACKUP_SCHEDULE,
        payload: { dbId, data }
    };
}

export function _backupDatabaseSuccessSocket({ dbId, backupFile }) {
    return {
        type: types.BACKUP_DATABASE_SOCKET_SUCCESS,
        payload: { dbId, backupFile }
    };
}

export function setIsBackuping({ dbId, isBackuping }) {
    return {
        type: types.SET_IS_BACKUPING,
        payload: { dbId, isBackuping }
    };
}

export function setDatabaseStatus({ dbId, status }) {
    return {
        type: types.SET_DATABASE_STATUS,
        payload: {
            status,
            dbId
        }
    };
}

export function duplicateDatabase({ workspaceId, clonedDatabase, name, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { workspace, auth } = getState();
        const { selectedWorkspace } = workspace;
        const companyId = auth.companyId || auth.currentUser.companyId;

        const wpIdCombined = selectedWorkspace?.id || workspaceId;

        const databaseTempId = generateTempId();
        const fakeDatabase = {
            ...clonedDatabase,
            id: databaseTempId,
            name,
            alteredTime: moment().format(),
            status: DATABASE_STATUS.DUPLICATING
        };
        dispatch(createDatabaseActionSocket({ workspaceId: wpIdCombined, database: fakeDatabase }));

        try {
            const duplicatedDatabase = await duplicateDatabaseApi({
                dbId: clonedDatabase?.id,
                workspaceId: wpIdCombined,
                companyId,
                body: {
                    name
                }
            });
            const newDatabase = {
                ...duplicatedDatabase,
                status: DATABASE_STATUS.DUPLICATING
            };
            dispatch(
                _updateDatabaseActionWithFakeId({
                    dbId: databaseTempId,
                    newDatabase,
                    workspaceId
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;

            dispatch(deleteDatabaseActionSuccess({ dbId: databaseTempId, workspaceId: wpIdCombined }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(originalMessage);
        }
    };
}

function _updateDatabaseActionWithFakeId({ workspaceId, dbId, newDatabase }) {
    return {
        type: types.UPDATE_DATABASE_WITH_TEMP_ID,
        payload: {
            dbId,
            newDatabase,
            workspaceId
        }
    };
}

export function fetchDatabaseGroups({ dbId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchDatabaseGroupsAction());
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const groups = await getSharedGroupsOfDatabaseApi({ companyId, dbId });
            dispatch(_fetchDatabaseGroupsActionSuccess({ groups }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_fetchDatabaseGroupsActionFailed({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchDatabaseGroupsAction() {
    return {
        type: types.FETCH_DATABASE_GROUPS
    };
}

function _fetchDatabaseGroupsActionSuccess({ groups }) {
    return {
        type: types.FETCH_DATABASE_GROUPS_SUCCESS,
        payload: { groups }
    };
}

function _fetchDatabaseGroupsActionFailed({ error }) {
    return {
        type: types.FETCH_DATABASE_GROUPS_FAILED,
        payload: { error }
    };
}

export function shareDatabase({ dbId, groups, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const groupIds = groups.map(group => group.id);
        try {
            await shareDatabaseApi({ companyId, dbId, groupIds });
            dispatch(__shareDatabaseActionSuccess({ groups }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function __shareDatabaseActionSuccess({ groups }) {
    return {
        type: types.SHARE_DATABASE_SUCCESS,
        payload: { groups }
    };
}

export function unshareDatabase({ dbId, groupIds, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            await unshareDatabaseApi({ companyId, dbId, groupIds });
            dispatch(__unshareDatabaseActionSuccess({ groupIds }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function __unshareDatabaseActionSuccess({ groupIds }) {
    return {
        type: types.UNSHARE_DATABASE_SUCCESS,
        payload: { groupIds }
    };
}
