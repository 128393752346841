import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RoleItem from './RoleItem';
import { useIsCreatingRole, useIsShowLastRolePopupCreate } from 'hooks/permission/role';
import { useContextMenuId } from 'hooks/app';
import Skeleton from 'components/skeleton/Base';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function RoleList({ roles, selectedRoleId, handleItemClick }) {
    const classes = useStyles();
    const contextMenuId = useContextMenuId();
    const [isScrolling, setIsScrolling] = React.useState(false);
    const timerRef = React.useRef(null);

    React.useEffect(() => {
        const target = document.getElementById('roleList');
        if (!target) return;
        target.addEventListener('mousewheel', handleScroll);
        target.addEventListener('DOMMouseScroll', handleScroll);
        target.addEventListener('scroll', handleScroll);
        return () => {
            target.removeEventListener('mousewheel', handleScroll);
            target.removeEventListener('DOMMouseScroll', handleScroll);
            target.removeEventListener('scroll', handleScroll);
        };
    });

    function handleScroll() {
        setIsScrolling(true);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setIsScrolling(false);
        }, 100);
    }

    const isCreatingRole = useIsCreatingRole();
    const isShowLastPopupCreate = useIsShowLastRolePopupCreate();

    return (
        <Grid className={classes.root} container direction={'column'} id="roleList">
            {roles?.map((role, index) => {
                if (role.isDeleted) return null;
                return (
                    <RoleItem
                        contextMenuId={contextMenuId}
                        key={role.id}
                        role={role}
                        active={selectedRoleId === role.id}
                        handleItemClick={handleItemClick}
                        isShowLastPopupCreate={isShowLastPopupCreate === role.id && index === roles.length - 1}
                        isScrolling={isScrolling}
                    />
                );
            })}
            <div id={'roleListBottom'} />
            {isCreatingRole && <Skeleton height={42} />}
        </Grid>
    );
}

export default React.memo(RoleList);
