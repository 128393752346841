import React, { useCallback } from 'react';
import ButtonBase from 'components/button/Base';
import { useDispatch, useSelector } from 'react-redux';
import * as gridActions from '../actions';
import { DISABLED_OPACITY } from 'const/style';
import { sendManualTrack } from 'tracker';

function AddSortOrderButton({ isReadOnly, onCreated, t }) {
    const dispatch = useDispatch();
    const gridUI = useSelector(state => state.gridUI);

    const addSortOrderHandler = useCallback(() => {
        let sortOrder = gridActions.generateDefaultViewSort(gridUI);
        if (!sortOrder) {
            console.log('there is no column');
            return;
        }
        sendManualTrack({ type: `Add View Sort` });

        dispatch(gridActions.createViewSortOrder({ sortOrder }));
        onCreated && onCreated();
    }, [dispatch, gridUI, onCreated]);

    return (
        <ButtonBase
            style={{
                pointerEvents: isReadOnly ? 'none' : '',
                opacity: isReadOnly ? DISABLED_OPACITY : 1
            }}
            size="small"
            onClick={addSortOrderHandler}
            variant="outlined"
        >
            {t(`sort_add_button`)}
        </ButtonBase>
    );
}
export default React.memo(AddSortOrderButton);
