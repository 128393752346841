import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LDBasePortal from 'components/selects/LDBasePortal';
import { ColumnTypeSelectListDisplay } from 'gridUI/ColumnTypeDisplay';
import ListSelect from 'components/list/Select';
import ButtonBase from 'components/button/Base';
import SettingSVG from 'assets/images/svg/SettingSVG';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    mt3: {
        marginTop: theme.spacing(3)
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
    targetList: {
        padding: theme.spacing(3),
        borderRadius: 4,
        background: theme.colors.ghostwhite
    },
    steel: {
        color: theme.colors.steel
    }
}));

function RunningAutoQA({
    onRunAutoQA,
    t,
    selectedSource,
    onSourceChange,
    selectedTargetOptions,
    targetOptions,
    onTargetSelect,
    sourceOptions,
    autoQASettings,
    selectedSettingId,
    onSettingChange
}) {
    const classes = useStyles();
    const { workspaceId } = useParams();

    const autoQASettingOptions = autoQASettings
        .filter(setting => setting.projectId === Number(workspaceId))
        .map(setting => ({
            label: setting.name,
            value: setting.id,
            icon: () => <SettingSVG />,
            disabledTitle: (
                <Grid style={{ padding: 8 }}>
                    <p className="body1 inline">Spellchecking: </p>
                    <p className="body2 inline">{setting.spellcheck.toString()}</p>
                    <p className="body1">Length check:</p>
                    <p className="body2">• Target longer than source {setting.lengthCharMore} chars</p>
                    <p className="body2">• Target shorter than source {setting.lengthCharLess} chars</p>
                </Grid>
            )
        }));
    const selectedSettingOption = autoQASettingOptions.find(op => op.value === selectedSettingId);

    return (
        <Grid container direction="column">
            <Grid item>
                <p className="body1">Settings</p>
            </Grid>
            <Grid item style={{ width: 300 }} className={classes.mt3}>
                <LDBasePortal
                    ddPlaceholder="Choose a setting"
                    menuPlaceholder="Find a setting"
                    options={autoQASettingOptions}
                    onChange={option => onSettingChange(option.value)}
                    defaultValue={selectedSettingOption}
                />
            </Grid>
            <Grid item className={classes.mt3}>
                <p className="body1">Source language</p>
            </Grid>
            <Grid item style={{ width: 300 }} className={classes.mt3}>
                <LDBasePortal
                    ddPlaceholder={t('source_select_placeholder')}
                    menuPlaceholder={t('source_select_search_placeholder')}
                    options={sourceOptions}
                    onChange={onSourceChange}
                    defaultValue={selectedSource}
                />
            </Grid>
            <Grid item className={classes.mt3}>
                <p className="body1 inline">Target languages</p>
                {selectedSource && (
                    <>
                        <p className="body2 inline"> | </p>
                        <p className="body2 inline">
                            Selected languages ({selectedTargetOptions.length}/{targetOptions.length})
                        </p>
                    </>
                )}
            </Grid>
            <Grid item className={`${classes.mt3} ${classes.targetList}`}>
                {targetOptions.length === 0 ? (
                    <Grid className={classes.steel}>
                        <p className="body2">Please select target language</p>
                    </Grid>
                ) : (
                    <Grid container alignItems="center" spacing={3}>
                        {targetOptions?.map(option => {
                            const isSelected = selectedTargetOptions.some(o => o.value === option.value);
                            return (
                                <Grid onClick={e => onTargetSelect(option)} item xs={6} key={option.value}>
                                    <ListSelect
                                        isSelected={isSelected}
                                        icon={option?.icon}
                                        name={<ColumnTypeSelectListDisplay {...option} />}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Grid>
            <Grid className={classes.mt4}>
                <ButtonBase
                    disabled={!selectedSource || selectedTargetOptions.length === 0}
                    fullWidth
                    variant="contained"
                    onClick={onRunAutoQA}
                >
                    Run Auto QA by lexiqa
                </ButtonBase>
            </Grid>
        </Grid>
    );
}

export default React.memo(RunningAutoQA);
