import React from 'react';

function Large({ color = '#78778B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M17 2.03906V14.7701C17 16.0019 16.1046 17.0004 15 17.0004H3C1.89543 17.0004 1 16.0019 1 14.7701L1.00001 2.03906"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M1 2.78809H17" stroke={color} strokeWidth="1.5" />
        </svg>
    );
}

export default React.memo(Large);
