import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const TIME_INTERVAL = 8;
const INIT_WIDTH = 1;
const COLORS = ['#F6BD48', '#FFA26B', '#E87C5E', '#F6BD48'];

const useStyles = makeStyles(theme => ({
    bar1: {
        position: 'absolute',
        width: '100%',
        height: 4,
        left: 0,
        bottom: 0,
        backgroundColor: '#F6BD48'
    },
    bar2: {
        width: `${INIT_WIDTH}%`,
        height: '100%',
        backgroundColor: '#FFA26B'
    },
    bar3: {
        width: `${INIT_WIDTH}%`,
        height: '100%',
        backgroundColor: '#4D3F8A',
        transition: 'width 1s',
        transitionTimingFunction: 'cubic-bezier(0.5, 0.5, 0.7, 0.9)'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    left: {
        float: 'left'
    },
    right: {
        float: 'right'
    }
}));

function LinearProgress({ className = '', value }) {
    const classes = useStyles();

    const [colorIndex, setColorIndex] = useState(1);
    const [bar2Width, setBar2Width] = useState(0);
    const [bar3Width, setBar3Width] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    const move = () => {
        let colorIndex = 1;
        let width = INIT_WIDTH;
        const intervalId = setInterval(frame, TIME_INTERVAL);

        function frame() {
            if (width >= 100) {
                if (colorIndex < COLORS.length) {
                    colorIndex = colorIndex + 1;
                } else {
                    colorIndex = 1;
                }
                width = INIT_WIDTH;
                setColorIndex(colorIndex);
                setBar2Width(width);
            } else {
                width = width + 1;
                setBar2Width(width);
            }
        }

        return intervalId;
    };

    useEffect(() => {
        if (value === 'loading') {
            if (!intervalId) {
                setIntervalId(move());
            }
        } else if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
            setBar2Width(0);
            setBar3Width(100);
        }
    }, [value, intervalId]);

    if (value === 'loading' || bar2Width) {
        return (
            <div
                className={`${classes.bar1} ${classes.center} ${className}`}
                style={{ backgroundColor: `${COLORS[colorIndex - 1]}` }}
            >
                <div
                    className={classes.bar2}
                    style={{
                        width: `${bar2Width}%`,
                        backgroundColor: `${COLORS[colorIndex]}`
                    }}
                ></div>
            </div>
        );
    } else if (bar3Width) {
        return (
            <div
                className={`${classes.bar1} ${classes.center} ${className}`}
                style={{ backgroundColor: `${COLORS[colorIndex - 1]}` }}
            >
                <div
                    className={classes.bar3}
                    style={{
                        width: `${bar3Width}%`
                    }}
                ></div>
            </div>
        );
    } else {
        return <span />;
    }
}

export default React.memo(LinearProgress);
