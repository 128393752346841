import { IMAGE_ORGANIZATION_PREFIX, IMAGE_PREFIX } from 'config';
import mime from 'mime';
import { INPUT_PADDING_LEFT } from 'const/style';
import { MAX_FILES_PER_ROW, FILE_SPACING, FILE_RATIO } from 'const/gridUI';
import store from 'store/configStore';
import { IMAGE_WORKFLOW_PREFIX } from 'config';

const prefixAvatarOrganization = `/api/v1/media/avatars/`;
const prefixCompanyLogoOrganization = `/api/v1/media/company-logo/`;
const prefixAutomationLogo = `/v1/media/automation-icons/`;
const prefixGroupLogo = `/v1/media/group-avatar/`;

function _getShareViewTail() {
    const { getState } = store;
    const { app } = getState();
    const shareViewKey = app?.shareViewKey;

    if (!shareViewKey) return '';
    return '?x_share_key=' + shareViewKey;
}

export function getAvatarUrl(imageUrl) {
    if (!imageUrl) return null;
    if (imageUrl.includes(prefixAvatarOrganization)) {
        return `${IMAGE_ORGANIZATION_PREFIX}${imageUrl}`;
    }
    return `${IMAGE_ORGANIZATION_PREFIX}${prefixAvatarOrganization}${imageUrl}`;
}

export function getGroupAvatarUrl(imageUrl) {
    if (!imageUrl) return null;
    if (imageUrl.includes(prefixGroupLogo)) {
        return `${IMAGE_ORGANIZATION_PREFIX}${imageUrl}`;
    }
    return `${IMAGE_ORGANIZATION_PREFIX}${prefixGroupLogo}${imageUrl}`;
}

export function getCompanyLogoUrl(imageUrl) {
    if (!imageUrl) return null;
    if (imageUrl.includes(prefixCompanyLogoOrganization)) {
        return `${IMAGE_ORGANIZATION_PREFIX}${imageUrl}`;
    }
    return `${IMAGE_ORGANIZATION_PREFIX}${prefixCompanyLogoOrganization}${imageUrl}`;
}

export function getAutomationLogoUrl(iconFileId) {
    if (!iconFileId) return null;
    return `${IMAGE_WORKFLOW_PREFIX}${prefixAutomationLogo}${iconFileId}`;
}

export function getFileOnly(filePath) {
    return filePath.replace(/^.*[\\/]/, '');
}

export function isFileAudio(fileMeta) {
    const contentType = fileMeta?.contentType || mime?.getType(fileMeta?.originalName);
    return contentType?.match('audio.*')?.length > 0;
}

export function isFileVideo(fileMeta) {
    const contentType = fileMeta?.contentType || mime?.getType(fileMeta?.originalName);
    return contentType?.match('video.*')?.length > 0;
}

export function isFileImage(fileMeta) {
    const contentType = fileMeta?.contentType || mime?.getType(fileMeta?.originalName);
    return contentType?.match('image.*')?.length > 0;
}

export function isFilePdf(fileMeta) {
    const contentType = fileMeta?.contentType || mime?.getType(fileMeta?.originalName);
    return `${contentType}`?.toLowerCase().match('pdf$')?.length > 0;
}

export function getFilePreview({ dbId, fileId }) {
    if (!dbId || !fileId) {
        console.log('fileId or dbId cannot be empty!');
        return;
    }
    return `${IMAGE_PREFIX}/databases/${dbId}/files/${fileId}${_getShareViewTail()}`;
}

export const readFile = async file => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
};

export function generateAvatarWithHeight({ columnWidth, ratio = FILE_RATIO }) {
    const width = (columnWidth - 4 * FILE_SPACING * MAX_FILES_PER_ROW - INPUT_PADDING_LEFT * 2) / MAX_FILES_PER_ROW;
    return {
        width,
        height: width * ratio
    };
}
