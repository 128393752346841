import { useSelector } from 'react-redux';

export function useViewList() {
    return useSelector(state => state.gridUI.views);
}

export function useView(viewId) {
    const views = useViewList();
    return views?.find(view => view.id === viewId);
}

export function useCurrentView() {
    return useSelector(state => state.gridUI?.currentView);
}

export function useCurrentViewId() {
    const currentView = useCurrentView();
    return currentView?.id;
}

export function useIsAddingView() {
    return useSelector(state => state.gridUI?.isAddingView);
}

export function useCompositeViewId() {
    const currentView = useCurrentView();
    return currentView?.compositeViewId;
}

export function useIsViewBlocked() {
    return useSelector(state => state.gridUI?.isViewBlocked);
}

export function useIsFetchingView() {
    return useSelector(state => state.gridUI?.isFetchingView);
}
