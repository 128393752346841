import React from 'react';

function ItalicSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <polygon
                    fill={color}
                    fillRule="nonzero"
                    points="11.141 5 16.054 5.055 16.038 6.15 14.34 6.131 11.84 17.885 14.025 17.905 14.005 19 8 18.945 8.02 17.85 10.363 17.871 12.865 6.115 11.125 6.095"
                />
            </g>
        </svg>
    );
}

export default React.memo(ItalicSVG);
