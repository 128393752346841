import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Search from 'components/search';
import { HEADER_HEIGHT } from 'const';
import * as workspaceActions from 'workspaces/actions';
import { useDispatch } from 'react-redux';
import GroupList from 'gridUI/shareView/groupList';
import MemberList from 'gridUI/shareView/memberList';
import GroupTag from 'gridUI/shareView/groupTag';
import { useParams } from 'react-router-dom';
import {
    useWorkspaceMembers,
    useSelectedGroupIdInWorkspace,
    useWorkspaceIsFetching,
    useGroupListInWorkspace
} from 'hooks/workspace';
import NoMember from './NoMember';
import GroupListSkeleton from './GroupListSkeleton';
import Spinner from 'components/spinner/Base';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const HEADER_HISTORY_HEIGHT = 72;

const useStyles = makeStyles(theme => ({
    groupList: {
        minHeight: 66,
        maxHeight: 138,
        overflow: 'auto'
    },
    memberList: {
        height: `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT + 210}px)`,
        overflow: 'auto'
    },
    spinner: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeIcon: {
        height: 24,
        width: 24
    }
}));

function GroupAndMember() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { workspaceId } = useParams();
    const selectedGroupId = useSelectedGroupIdInWorkspace();
    const groups = useGroupListInWorkspace();
    const isFetching = useWorkspaceIsFetching();
    const members = useWorkspaceMembers();

    const [searchMemberValue, setSearchMemberValue] = React.useState('');
    const filteredMembers = React.useMemo(() => {
        return members?.filter(member =>
            member?.user?.fullName?.toLowerCase().includes(searchMemberValue.trim().toLowerCase())
        );
    }, [members, searchMemberValue]);

    const selectedGroup = React.useMemo(() => {
        return groups?.find(group => group.id === selectedGroupId);
    }, [groups, selectedGroupId]);

    React.useEffect(() => {
        if (workspaceId) {
            dispatch(
                workspaceActions.fetchGroupsAndMembersOfWorkspace({
                    workspaceId,
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        }
    }, [dispatch, workspaceId]);

    const handleGroupSelect = ({ groupId }) => {
        setSearchMemberValue('');
        if (selectedGroupId === groupId) {
            dispatch(workspaceActions.changeSelectedGroupId({ groupId: null }));
        } else {
            dispatch(workspaceActions.changeSelectedGroupId({ groupId }));
        }
    };

    function handleGroupTagClose() {
        dispatch(workspaceActions.changeSelectedGroupId({ groupId: null }));
    }

    function handleRemoveGroup({ group }) {
        sendManualTrack({ type: `Remove Group From View` });
        if (selectedGroupId === group.id) {
            dispatch(workspaceActions.changeSelectedGroupId({ groupId: null }));
        }
        dispatch(
            workspaceActions.ungrantGroupOutOfWorkspace({
                data: {
                    groupIds: [group.id]
                },
                workspaceId,
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }

    const handleRoleChange = ({ member, role }) => {
        sendManualTrack({ type: `Edit Workspace Role From Workspace` });
        dispatch(
            workspaceActions.changeUserWorkspaceRole({
                userId: member?.user?.id,
                workspaceId,
                newRole: role,
                oldRole: member.role,
                successCallback: () => {
                    console.log('handleRoleChange successfully');
                },
                errorCallback: () => {
                    console.log('handleRoleChange failed');
                }
            })
        );
    };

    return (
        <Grid container direction="column">
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="body1">
                            {t('global_groups')} ({groups?.length})
                        </Typography>
                    </Grid>
                    <Grid item className={classes.groupList}>
                        {isFetching ? (
                            <GroupListSkeleton />
                        ) : (
                            <GroupList
                                groups={groups}
                                selectedGroupId={selectedGroupId}
                                onGroupSelect={handleGroupSelect}
                                onRemoveGroup={handleRemoveGroup}
                                level="Project"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.section}>
                <Grid container wrap="nowrap" direction="column" spacing={3}>
                    <Grid item>
                        {selectedGroupId ? (
                            <GroupTag members={members} selectedGroup={selectedGroup} onClose={handleGroupTagClose} />
                        ) : (
                            <Typography variant="body1">
                                {t('all_members')} ({members.length})
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Search
                            value={searchMemberValue}
                            onChange={e => setSearchMemberValue(e.target.value)}
                            placeholder={t('global_find_a_member')}
                            background={theme.colors.paleGrey}
                            width="100%"
                        />
                    </Grid>
                    <Grid item className={classes.memberList}>
                        {!isFetching && <MemberList members={filteredMembers} onRoleChange={handleRoleChange} />}
                        {isFetching && (
                            <div className={classes.spinner}>
                                <Spinner size={30} thick={4} />
                            </div>
                        )}
                        {Boolean(selectedGroupId) && !members?.length && <NoMember />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(GroupAndMember);
