import * as roleConst from 'auth/roleConst';

export function isCanAddGridAndDatabaseRealtime(auth) {
    const roleObj = auth?.role;
    const groups = auth.currentUser?.groups;
    const isInGroupAdmin = groups?.find(
        group => (group?.systemGroup || group?.isSystemGroup) && group?.type?.toLowerCase() === 'admin'
    );
    const workspaceAuthorityArr = auth?.workspaceAuthorities;
    if (roleObj?.[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] || isInGroupAdmin) return true;
    return workspaceAuthorityArr?.includes(roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE);
}
