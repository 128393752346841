import React, { useMemo, useState, useCallback, useRef } from 'react';
import classnames from 'classnames';
import { Grid, Radio } from '@material-ui/core';
import TooltipActionDisable from '../TooltipActionDisable';
import { ACTION_STATUS } from 'gridUI/automations/const';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';
import Payloadv2 from './Payloadv2';
import { useDispatch } from 'react-redux';
import { updateActionNode } from 'gridUI/automations/action';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import MapTranslatedColumn from './MapTranslatedColumn';
import * as columnTypes from 'const/columnTypes';
import SetColor from './SetColor';

const RADIO_MODE = {
    SPECIFIC: 'SPECIFIC',
    PREVIOUS_ACTIONS: 'PREVIOUS_ACTIONS'
};

const ActionSet = ({ selectedNode, setSelectedNode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const automationDetail = useGetAutomationDetail();
    const [radioMode, setRadioMode] = useState(
        selectedNode?.params?.message?.toLocaleLowerCase().indexOf('${trigger') > -1 ||
            selectedNode?.params?.message?.toLocaleLowerCase().indexOf('${node') > -1
            ? RADIO_MODE.PREVIOUS_ACTIONS
            : RADIO_MODE.SPECIFIC
    );
    const [message, setMessage] = useState(selectedNode?.params?.message || '');
    const [openChangeAction, setOpenChangeAction] = useState(false);
    const timeout = useRef();

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const handleChange = useCallback(
        e => {
            const value = e.target.value;
            setMessage(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                message: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 800);
        },
        [automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const handleChooseSpecific = useCallback(() => {
        const serverMessage = selectedNode?.params?.message;
        if (
            serverMessage?.toLocaleLowerCase().indexOf('${trigger') > -1 ||
            serverMessage?.toLocaleLowerCase().indexOf('${node') > -1
        ) {
            setOpenChangeAction(true);
            return;
        }
        setMessage(serverMessage);
        setRadioMode(RADIO_MODE.SPECIFIC);
    }, [selectedNode.params.message]);

    const handleCloseChangeAction = useCallback(() => {
        setOpenChangeAction(false);
    }, []);

    const handleChangeAction = useCallback(() => {
        handleChange({ target: { value: '' } });
        setRadioMode(RADIO_MODE.SPECIFIC);
        setOpenChangeAction(false);
    }, [handleChange]);

    return (
        <>
            <Grid item className="!mb-3">
                <TooltipActionDisable isAction disabled={isDisabled}>
                    <Grid item className="!mb-6">
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            className={classnames({
                                '!mb-[9px]': radioMode === RADIO_MODE.SPECIFIC
                            })}
                        >
                            <Grid item>
                                <Radio
                                    className="!p-0"
                                    onChange={handleChooseSpecific}
                                    size="small"
                                    checked={radioMode === RADIO_MODE.SPECIFIC}
                                />
                            </Grid>
                            <Grid item>
                                <p className={'body2 cursor-pointer'} onClick={handleChooseSpecific}>
                                    {t('automation_set_specific_value')}
                                </p>
                            </Grid>
                        </Grid>
                        {radioMode === RADIO_MODE.SPECIFIC && (
                            <Grid item>
                                <TextareaAutosize
                                    className="w-full h-full min-h-9 border border-grey-border py-2 px-3.5 rounded resize-none text-sm font-normal text-grey-dim bg-white"
                                    rows={1}
                                    maxRows={4}
                                    placeholder={t('automation_set_specific_value_placeholder')}
                                    value={message}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item className="!mb-6">
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            className={classnames({
                                '!mb-[15px]': radioMode === RADIO_MODE.PREVIOUS_ACTIONS
                            })}
                        >
                            <Grid item>
                                <Radio
                                    className="!p-0"
                                    onChange={() => setRadioMode(RADIO_MODE.PREVIOUS_ACTIONS)}
                                    size="small"
                                    checked={radioMode === RADIO_MODE.PREVIOUS_ACTIONS}
                                />
                            </Grid>
                            <Grid item>
                                <p
                                    className={'body2 cursor-pointer'}
                                    onClick={() => setRadioMode(RADIO_MODE.PREVIOUS_ACTIONS)}
                                >
                                    {t('automation_set_value_from_previous_actions')}
                                </p>
                            </Grid>
                        </Grid>
                        {radioMode === RADIO_MODE.PREVIOUS_ACTIONS && (
                            <Payloadv2
                                hideLabel
                                placeholder={'Input your message'}
                                editorClassName="max-h-[280px] !h-auto"
                                selectedNode={selectedNode}
                                setSelectedNode={setSelectedNode}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <MapTranslatedColumn
                            label={t('automation_select_column_to_put_result_in')}
                            isRequired
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                            filterColumns={[
                                ...columnTypes.AUTOMATION_TEXT_COLUMNS,
                                columnTypes.DATETIME,
                                columnTypes.NUMBER
                            ]}
                        />
                    </Grid>
                    <Grid item className="!mt-6">
                        <SetColor
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                            automationId={automationDetail?.id}
                        />
                    </Grid>
                </TooltipActionDisable>
                <Dialog open={openChangeAction} onClose={handleCloseChangeAction}>
                    <ConfirmBox
                        title={t('automation_set_change_action')}
                        body={<p className="body2">{t('automation_set_change_action_content')}</p>}
                        handleCancel={handleCloseChangeAction}
                        onClose={handleCloseChangeAction}
                        handleAgreed={handleChangeAction}
                        agreeLabel={t('global_change')}
                    />
                </Dialog>
            </Grid>
        </>
    );
};

export default React.memo(ActionSet);
