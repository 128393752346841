import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
const DialogCustom = withStyles(theme => ({
    // paper: {
    //     paddingTop: theme.spacing(1)
    // }
}))(Dialog);

export default function DialogWrapper({ disableEnforceFocus, scroll, ...rest }) {
    return <DialogCustom scroll={'paper'} disableEnforceFocus {...rest} />;
}
