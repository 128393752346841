import React from 'react';

function EditSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M14.7567 14.1133C14.6577 14.0144 14.4598 13.9154 14.2619 13.9154H1.69278C1.49485 13.9154 1.29691 14.0144 1.19794 14.1133C1.09897 14.2123 1 14.5092 1 14.7072C1 14.9051 1.09897 15.103 1.19794 15.202C1.29691 15.301 1.49485 15.3999 1.69278 15.3999H14.1629C14.3608 15.3999 14.5588 15.301 14.6577 15.202C14.7567 15.103 14.8557 14.9051 14.8557 14.7072C14.8557 14.5092 14.9546 14.3113 14.7567 14.1133Z"
                fill={color}
            />
            <path
                d="M14.4598 4.5134C14.7567 4.21649 14.8557 3.82062 14.8557 3.52371C14.8557 3.32577 14.8557 3.12784 14.7567 2.9299C14.6577 2.73196 14.5588 2.63299 14.4598 2.43505L13.4701 1.44536C12.8763 0.851546 11.9856 0.851546 11.3918 1.44536L10.4021 2.43505L3.47425 9.36289L3.07837 12.8268L6.54229 12.4309L14.4598 4.5134ZM12.3815 2.53402L13.3712 3.52371L12.8763 4.01856L11.8866 3.02887L12.3815 2.53402ZM4.76084 11.1443L4.85981 10.0557L10.798 4.11753L11.7876 5.10722L5.8495 11.0454L4.76084 11.1443Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(EditSVG);
