import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import ListItem from 'components/list/Item';
import VideoPlaySVG from 'assets/images/svg/VideoPlaySVG';
import ImageSlideShowSVG from 'assets/images/svg/ImageSlideShowSVG';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';
import { useDispatch } from 'react-redux';
import { getCellDataFileType } from 'gridUI/actions';
import { useColumnsWithData } from 'hooks/gridUI';
import { FILES } from 'const/columnTypes';
import AudioPlaySVG from 'assets/images/svg/AudioPlaySVG';

const PlayFileActions = ({ columnIds, closePopup }) => {
    const dispatch = useDispatch();

    const columns = useColumnsWithData();

    const hasFileColumnInRangeSelection = React.useMemo(() => {
        return columnIds?.some(columnId => {
            const column = columns?.find(column => column?.id === columnId);
            return column?.type === FILES;
        });
    }, [columnIds, columns]);

    const handlePlayFile = useCallback(
        fileType => e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(getCellDataFileType({ fileType }));
            closePopup && closePopup();
        },
        [dispatch, closePopup]
    );

    if (!hasFileColumnInRangeSelection) return null;

    return (
        <Grid item id="play-file-actions">
            <Grid item onClick={handlePlayFile(['pdf'])}>
                <ListItem icon={<PDFFILESVG width={16} height={16} />} name={'Play PDF'} />
            </Grid>
            <Grid item onClick={handlePlayFile(['audio'])}>
                <ListItem icon={<AudioPlaySVG width={16} height={16} />} name={'Play audio'} />
            </Grid>
            <Grid item onClick={handlePlayFile(['video'])}>
                <ListItem icon={<VideoPlaySVG width={16} height={16} />} name={'Play video'} />
            </Grid>
            <Grid item onClick={handlePlayFile(['image'])}>
                <ListItem icon={<ImageSlideShowSVG width={16} height={16} />} name={'Play image slideshow'} />
            </Grid>
        </Grid>
    );
};

export default React.memo(PlayFileActions);
