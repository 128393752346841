import React from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/list/Item';
import ToolTipClickContent from 'components/tooltip/ToolTipClickContent';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import BranchSVG from 'assets/images/svg/BranchSVG';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    root: {},
    focused: {
        background: theme.colors.hoverItem
    },
    pointerEventsNone: {
        pointerEvents: 'none'
    },
    branch: {
        padding: '3px 10px',
        background: '#F3F4FC',
        border: `1px solid #EBEBF6`,
        boxSizing: 'border-box',
        borderRadius: 3,
        '& content': {
            maxWidth: 230,
            overflow: 'hidden'
        },
        '& p': {
            fontWeight: 500,
            color: '#7869B9',
            whiteSpace: 'break-spaces',
            ...theme.ellipsis()
        },
        '& svg': {
            marginRight: 6,
            verticalAlign: 'middle'
        }
    },
    parentGridName: {
        maxWidth: 120,
        '& p': {
            ...theme.ellipsis(),
            display: 'block'
        }
    }
}));
function OptionGridBranch(props) {
    const classes = useStyles();
    const { data, isSelected, innerRef, children, innerProps, isFocused, selectProps } = props;

    const { icon: Icon, isDisabled, disabledTitle = '', moreIcon: MoreIcon, placement, parentGridName } = data;

    const { showTooltip } = selectProps;

    const tooltipTitle = React.useMemo(() => {
        return isDisabled
            ? disabledTitle
            : showTooltip
            ? [parentGridName, children].filter(el => !!el).join(' > ')
            : '';
    }, [parentGridName, children, isDisabled, disabledTitle, showTooltip]);

    return (
        <ToolTipClickContent placement={placement} title={tooltipTitle}>
            <div
                className={`${isFocused ? classes.focused : ``} ${isDisabled ? classes.pointerEventsNone : ``}`}
                ref={innerRef}
                {...innerProps}
            >
                <ListItem
                    disabled={isDisabled}
                    icon={Icon ? <Icon /> : undefined}
                    name={
                        parentGridName ? (
                            <Grid item container spacing={2} alignItems="center">
                                <Tooltip title={!showTooltip ? parentGridName : ''}>
                                    <Grid item className={classes.parentGridName}>
                                        <Typography variant="body2">{parentGridName}</Typography>
                                    </Grid>
                                </Tooltip>
                                <Grid item container alignItems="center" style={{ flex: 1 }} wrap="nowrap">
                                    <Grid item style={{ display: 'flex', marginRight: 6 }}>
                                        <ArrowRightSVG />
                                    </Grid>
                                    <Tooltip title={!showTooltip ? children : ''}>
                                        <Grid item>
                                            <Grid
                                                item
                                                container
                                                alignItems="center"
                                                wrap="nowrap"
                                                className={classes.branch}
                                            >
                                                <Grid item>
                                                    <BranchSVG color="#7869B9" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{children}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ) : (
                            children
                        )
                    }
                    isSelected={isSelected}
                    moreIcon={MoreIcon ? <MoreIcon /> : undefined}
                />
            </div>
        </ToolTipClickContent>
    );
}

OptionGridBranch.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * props passed to the wrapping element for the group.
     */
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        onMouseMove: PropTypes.func.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired
    }).isRequired,
    /**
     * Inner ref to DOM Node
     */
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired
        })
    ]),
    /**
     * Whether the option is focused.
     */
    isFocused: PropTypes.bool.isRequired,
    /**
     * Whether the option is selected.
     */
    isSelected: PropTypes.bool.isRequired
};

export default React.memo(OptionGridBranch);
