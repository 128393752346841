import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import GroupListSkeleton from './GroupListSkeleton';
import Search from 'components/search';
import { SELECTION_LIST_ITEM_MIN_HEIGHT, INPUT_RADIUS, INPUT_PADDING_LEFT } from 'const/style';

import { useGroupList, useSelectedGroupId, useGroupIsFetchingGroups } from 'hooks/permission';

import GroupList from './GroupList';
import GroupContent from './GroupContent';
import NestedSettingLayout from '../common/NestedSettingLayout';
import * as groupActions from '../actions/group';
import { useParams, useHistory } from 'react-router-dom';
import AddGroupButton from './AddGroupButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    listItem: {
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        borderRadius: INPUT_RADIUS,
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT
    },
    active: {
        background: theme.colors.hoverItem
    },
    name: {
        fontSize: '0.875rem',
        color: theme.colors.dimGrey
    }
}));

function Groups() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const params = useParams();
    const history = useHistory();
    const groups = useGroupList();
    const selectedGroupId = useSelectedGroupId();
    const isFetchingGroups = useGroupIsFetchingGroups();
    const [searchValue, setSearchValue] = useState('');
    const { groupId } = params;

    useEffect(() => {
        if (groupId) {
            dispatch(groupActions.changeGroup({ groupId }));
        }
    }, [dispatch, groupId]);

    useEffect(() => {
        dispatch(groupActions.fetchGroups({}));
    }, [dispatch]);

    useEffect(() => {
        if (!groups.length) return;
        history.push(`/company-settings/groups/${groupId || groups[0].id}`);
    }, [history, groupId, groups]);

    const filteredGroups = useMemo(() => {
        let result = groups;

        if (searchValue.trim() !== '') {
            result = groups.filter(item => item.name.toLowerCase().includes(searchValue.trim().toLowerCase()));
        }

        return result;
    }, [searchValue, groups]);

    const handleGroupSelect = ({ id }) => {
        history.push(`/company-settings/groups/${id}`);
    };

    const handleGroupCreated = createdGroup => {
        const groupId = createdGroup?.id;
        history.push(`/company-settings/groups/${groupId}`);
    };

    return (
        <NestedSettingLayout
            name={t('global_groups')}
            Sidebar={
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Search
                            placeholder={t('global_label_find_a_group')}
                            background={theme.colors.paleGrey}
                            width={220}
                            onChange={e => setSearchValue(e.target.value)}
                        />
                    </Grid>
                    <Grid item className={classes.groupList}>
                        {isFetchingGroups && groups === null ? (
                            <GroupListSkeleton />
                        ) : (
                            <GroupList
                                groups={filteredGroups}
                                selectedGroupId={selectedGroupId}
                                onItemSelect={handleGroupSelect}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <AddGroupButton onGroupCreated={handleGroupCreated} />
                    </Grid>
                </Grid>
            }
            Content={<GroupContent selectedGroupId={selectedGroupId} />}
        ></NestedSettingLayout>
    );
}

export default React.memo(Groups);
