import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import LDBasePortal from 'components/selects/LDBasePortal';
import hexToRgba from 'hex-to-rgba';
import { SELECTION_COLORS, DEFAULT_COLOR } from 'utils/color';
import { useTranslation } from 'react-i18next';
import { SELECTION_OPACITY } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {},
    multiText: {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.colors.border}`,
        padding: `6px ${theme.spacing(3)}px`,
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`
        },
        borderRadius: 4,
        resize: 'none',
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        backgroundColor: theme.palette.common.white
    },
    option: {
        width: 20,
        height: 20,
        borderRadius: '50%'
    }
}));

function MultipleSelection({ defaultValue, onChange, extraOptions, customPropsOptions }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const options = React.useMemo(() => {
        return extraOptions?.map(option => {
            const color = customPropsOptions?.[option?.id]?.color || DEFAULT_COLOR;

            return {
                ...option,
                value: option?.id,
                icon: () => (
                    <div
                        className={classes.option}
                        style={{
                            background: hexToRgba(SELECTION_COLORS?.[color], SELECTION_OPACITY.BACKGROUND),
                            border: `1px solid ${hexToRgba(SELECTION_COLORS?.[color], SELECTION_OPACITY.BORDER)}`
                        }}
                    />
                )
            };
        });
    }, [classes, customPropsOptions, extraOptions]);

    const value = React.useMemo(() => {
        const isArray = Array.isArray(defaultValue);
        return !isArray
            ? []
            : defaultValue
                  ?.filter(optionId => {
                      return options?.findIndex(option => option?.id === optionId) !== -1;
                  })
                  ?.map(optionId => options?.find(option => option?.id === optionId));
    }, [defaultValue, options]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <p className="body1 inline">{t('global_default_value')}</p>{' '}
                <p className="caption inline">({t('global_optional')})</p>
            </Grid>
            <Grid item>
                <LDBasePortal
                    ddPlaceholder={t('select_option')}
                    menuPlaceholder={t('find_an_option')}
                    options={options}
                    onChange={options => onChange(options?.map(option => option?.id))}
                    defaultValue={value}
                    isMulti={true}
                    isUsingContainer={true}
                />
            </Grid>
        </Grid>
    );
}

export default MultipleSelection;
