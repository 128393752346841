import * as types from './type';
import produce from 'immer';

const initialState = {
    companyUsage: null,
    usage: {
        workspaceId: null
    }
};

export default function Database(state = initialState, { type, payload }) {
    switch (type) {
        case types.FETCH_DATA_COLLECTOR_BY_WORKSPACE_ID_SUCCESS: {
            const { workspaceId, data } = payload;
            const { usage } = state;
            const newUsage = produce(usage, draft => {
                draft[workspaceId] = data;
            });
            return {
                ...state,
                usage: newUsage
            };
        }

        case types.FETCH_DATA_USAGE: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.FETCH_DATA_USAGE_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.FETCH_DATA_USAGE_SUCCESS: {
            const { usage = {} } = payload;
            return {
                ...state,
                isFetching: false,
                companyUsage: usage
            };
        }

        default:
            return state;
    }
}
