import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { isKbEnter } from 'utils/keyboard';
import { isLDEmpty } from 'utils/object';
import { useTranslation } from 'react-i18next';

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
const DEFAULT_TIME_FORMAT = 'hh:mm aa';

function DateTimeInput({
    selectedDate,
    placeholder,
    onEnter,
    isShowTimeSelect = true,
    autoFocus = true,
    className,
    onKeyDown,
    isClearable = false,
    dateRef,
    timeFormat = DEFAULT_TIME_FORMAT,
    ...rest
}) {
    const [date, setDate] = React.useState(null);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (isLDEmpty(selectedDate)) setDate(null);
        else setDate(new Date(selectedDate));
    }, [selectedDate]);

    const handleDateChange = React.useCallback(
        date => {
            const dateFormat = moment(date).toISOString();

            setDate(date);
            onEnter(dateFormat);
        },
        [onEnter]
    );

    const handleKeyDown = e => {
        if (!isKbEnter(e)) return;
        let dateFormat = moment(date).toISOString();
        setDate(date);
        onEnter(dateFormat);
    };

    const getDateTimeFormat = React.useMemo(() => `${DEFAULT_DATE_FORMAT} ${timeFormat}`, [timeFormat]);

    return (
        <DatePicker
            ref={dateRef}
            className={className}
            selected={date}
            onChange={handleDateChange}
            showTimeSelect={isShowTimeSelect}
            timeFormat={timeFormat}
            dateFormat={`${DEFAULT_DATE_FORMAT} ${isShowTimeSelect ? timeFormat : ''}`}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown || handleKeyDown}
            placeholderText={placeholder || getDateTimeFormat}
            todayButton={t('global_today')}
            isClearable={isClearable}
            {...rest}
        />
    );
}

export default React.memo(DateTimeInput);
