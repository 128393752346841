import React from 'react';

function JsonFileSVG({ color = '#00C887', ...other }) {
    return (
        <svg width="75" height="85" viewBox="0 0 75 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M71.4418 18.4968L71.4414 17.8795L71.0068 17.4412L58.0744 4.3975L57.634 3.95326L57.0086 3.95353L19.9968 3.96961C15.5212 3.97155 11.9197 7.63286 11.9228 12.1174L11.9649 71.9145C11.9681 76.3991 15.5747 80.0573 20.0503 80.0553L63.4054 80.0365C67.8811 80.0346 71.4825 76.3732 71.4794 71.8887L71.4418 18.4968Z"
                    fill="white"
                    stroke="#EBEBF6"
                    strokeWidth="3"
                />
                <rect
                    opacity="0.2"
                    width="31.6357"
                    height="3.99635"
                    rx="1.99817"
                    transform="matrix(0.999872 -0.000434263 0.000704072 1.00013 21.4219 14.2344)"
                    fill="#A69FC4"
                />
                <rect
                    opacity="0.2"
                    width="39.4843"
                    height="3.99635"
                    rx="1.99817"
                    transform="matrix(0.999872 -0.000434252 0.000704083 1.00013 21.5469 23.3047)"
                    fill="#A69FC4"
                />
                <rect
                    opacity="0.2"
                    width="18.4051"
                    height="3.99663"
                    rx="1.99832"
                    transform="matrix(0.999865 -0.00379892 -0.00363991 1.00012 43.3945 41.2656)"
                    fill="#A69FC4"
                />
                <rect
                    opacity="0.2"
                    width="39.4843"
                    height="3.99635"
                    rx="1.99817"
                    transform="matrix(0.999872 -0.000434258 0.000704077 1.00013 21.6797 32.4414)"
                    fill="#A69FC4"
                />
                <rect
                    opacity="0.2"
                    width="18.4051"
                    height="3.99663"
                    rx="1.99831"
                    transform="matrix(0.999865 -0.00379893 -0.00363993 1.00012 43.5273 50.4023)"
                    fill="#A69FC4"
                />
                <rect
                    opacity="0.2"
                    width="18.4051"
                    height="3.99664"
                    rx="1.99832"
                    transform="matrix(0.999865 -0.00379891 -0.00363989 1.00012 43.6602 59.5312)"
                    fill="#A69FC4"
                />
                <rect
                    opacity="0.2"
                    width="39.4843"
                    height="3.99634"
                    rx="1.99817"
                    transform="matrix(0.999872 -0.000434272 0.000704063 1.00013 21.0781 68.9961)"
                    fill="#A69FC4"
                />
                <path
                    d="M37.5322 60.3554C37.5327 61.1995 36.8953 61.8905 36.1157 61.8909L4.93223 61.908C4.15264 61.9085 3.51439 61.2182 3.51389 60.3741L3.50481 45.0269C3.50431 44.1828 4.14175 43.4918 4.92134 43.4914L36.1048 43.4743C36.8844 43.4739 37.5227 44.1642 37.5232 45.0083L37.5322 60.3554Z"
                    fill="#7869B9"
                />
                <path
                    d="M12.0262 49.4091L13.3222 49.4084L13.3248 53.7697C13.325 54.1706 13.236 54.5254 13.0576 54.8342C12.8821 55.14 12.6331 55.3767 12.3107 55.5441C11.9882 55.7116 11.6239 55.7955 11.2179 55.7957C10.5526 55.7961 10.0341 55.6276 9.66236 55.2903C9.29065 54.9502 9.1046 54.47 9.10424 53.8499L10.4089 53.8491C10.409 54.1578 10.474 54.3856 10.6037 54.5326C10.7334 54.6797 10.9379 54.7531 11.2172 54.753C11.4649 54.7528 11.6621 54.6676 11.8089 54.4974C11.9557 54.3271 12.029 54.0848 12.0288 53.7704L12.0262 49.4091Z"
                    fill="white"
                />
                <path
                    d="M17.8493 54.0527C17.8492 53.8076 17.7626 53.6201 17.5898 53.4904C17.4169 53.3578 17.1058 53.2196 16.6564 53.0756C16.2071 52.9287 15.8513 52.7847 15.5891 52.6435C14.8747 52.2574 14.5172 51.7369 14.5169 51.0822C14.5167 50.7418 14.6115 50.4389 14.8014 50.1734C14.9942 49.9051 15.2692 49.6958 15.6262 49.5456C15.9861 49.3954 16.3892 49.3202 16.8356 49.32C17.2849 49.3197 17.6853 49.4017 18.0367 49.5659C18.3882 49.7273 18.6605 49.9564 18.8536 50.2534C19.0496 50.5504 19.1477 50.8878 19.148 51.2657L17.852 51.2664C17.8518 50.978 17.761 50.7545 17.5794 50.5959C17.3979 50.4345 17.143 50.3539 16.8146 50.3541C16.4978 50.3542 16.2517 50.4221 16.0761 50.5578C15.9005 50.6906 15.8127 50.8666 15.8128 51.0858C15.813 51.2906 15.9153 51.4622 16.1199 51.6005C16.3273 51.7388 16.6312 51.8685 17.0316 51.9894C17.769 52.2111 18.3063 52.4863 18.6434 52.8149C18.9806 53.1435 19.1493 53.553 19.1496 54.0434C19.1499 54.5885 18.9443 55.017 18.5326 55.3287C18.121 55.6376 17.5667 55.7922 16.8697 55.7926C16.3859 55.7929 15.9452 55.7051 15.5476 55.5294C15.1501 55.3508 14.8461 55.1072 14.6357 54.7987C14.4282 54.4902 14.3243 54.1326 14.324 53.7259L15.6243 53.7251C15.6247 54.4203 16.0397 54.7676 16.8691 54.7672C17.1773 54.767 17.4177 54.7049 17.5904 54.5807C17.7631 54.4537 17.8494 54.2777 17.8493 54.0527Z"
                    fill="white"
                />
                <path
                    d="M25.3493 52.6944C25.3497 53.3145 25.2405 53.8583 25.0219 54.3257C24.8033 54.7931 24.4896 55.1538 24.0808 55.4079C23.6749 55.6619 23.2084 55.7891 22.6814 55.7894C22.1601 55.7897 21.6949 55.6645 21.2858 55.4137C20.8767 55.163 20.5597 54.8055 20.3348 54.3413C20.1099 53.8741 19.9958 53.3377 19.9926 52.732L19.9924 52.4204C19.992 51.8003 20.1026 51.2551 20.3241 50.7848C20.5484 50.3116 20.8636 49.9495 21.2695 49.6983C21.6783 49.4442 22.1448 49.3171 22.6689 49.3168C23.1931 49.3165 23.6583 49.4431 24.0645 49.6968C24.4736 49.9475 24.7892 50.3093 25.0112 50.7822C25.2361 51.2523 25.3488 51.7959 25.3491 52.4132L25.3493 52.6944ZM24.0359 52.4096C24.0355 51.749 23.9171 51.2472 23.6807 50.9041C23.4444 50.561 23.1073 50.3895 22.6696 50.3898C22.2347 50.39 21.8993 50.5604 21.6633 50.9009C21.4273 51.2385 21.3081 51.7347 21.3056 52.3894L21.3058 52.6966C21.3062 53.3398 21.4246 53.8388 21.6609 54.1934C21.8973 54.5481 22.2372 54.7253 22.6808 54.725C23.1156 54.7248 23.4496 54.5544 23.6827 54.214C23.9158 53.8706 24.0335 53.3715 24.036 52.7168L24.0359 52.4096Z"
                    fill="white"
                />
                <path
                    d="M31.5602 55.698L30.2642 55.6987L27.7389 51.5552L27.7414 55.7001L26.4454 55.7008L26.4416 49.4012L27.7376 49.4005L30.2673 53.5527L30.2648 49.3991L31.5565 49.3984L31.5602 55.698Z"
                    fill="white"
                />
                <path
                    d="M60.3733 18.6918L70.0719 18.6865L57.1328 5.74219L57.1386 15.4557C57.1396 17.2365 58.5953 18.6928 60.3733 18.6918Z"
                    fill="#EBEBF6"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="75" height="85" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(JsonFileSVG);
