import React from 'react';

function GoogleLoginSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.54594 9.66893L2.989 11.7481L0.953406 11.7911C0.345063 10.6628 0 9.3718 0 7.99993C0 6.67333 0.322625 5.42233 0.8945 4.3208H0.894938L2.70719 4.65305L3.50106 6.45443C3.33491 6.93883 3.24434 7.45883 3.24434 7.99993C3.24441 8.58718 3.35078 9.14983 3.54594 9.66893Z"
                fill="#FBBB00"
            />
            <path
                d="M15.8601 6.50586C15.952 6.9898 15.9999 7.48958 15.9999 8.00036C15.9999 8.57311 15.9397 9.1318 15.825 9.6707C15.4355 11.5045 14.4179 13.1059 13.0083 14.239L13.0078 14.2386L10.7252 14.1221L10.4022 12.1054C11.3375 11.5569 12.0685 10.6984 12.4536 9.6707H8.17578V6.50586H12.516H15.8601Z"
                fill="#518EF8"
            />
            <path
                d="M13.0076 14.2382L13.0081 14.2386C11.6371 15.3406 9.89553 15.9999 7.99972 15.9999C4.95312 15.9999 2.30434 14.2971 0.953125 11.7911L3.54566 9.66895C4.22125 11.472 5.96059 12.7555 7.99972 12.7555C8.87619 12.7555 9.69731 12.5186 10.4019 12.105L13.0076 14.2382Z"
                fill="#28B446"
            />
            <path
                d="M13.1064 1.84175L10.5148 3.9635C9.78553 3.50769 8.92353 3.24437 8.00003 3.24437C5.91475 3.24437 4.14288 4.58678 3.50113 6.4545L0.894969 4.32088H0.894531C2.22597 1.75384 4.90816 0 8.00003 0C9.94112 0 11.7209 0.691437 13.1064 1.84175Z"
                fill="#F14336"
            />
        </svg>
    );
}

export default React.memo(GoogleLoginSVG);
