import React from 'react';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import ForgotPasswordForm from './ForgotPasswordForm';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 550
    }
}));

function ForgotPassword() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <AuthenticationWrapper isForgotPassword>
            <AuthFormWrapper
                title={t(`auth_for_got_password_title`)}
                subTitle={<span>{t(`auth_for_got_password_subtitle`)}</span>}
                className={classes.root}
            >
                <ForgotPasswordForm />
            </AuthFormWrapper>
        </AuthenticationWrapper>
    );
}

export default ForgotPassword;
