import React, { memo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import hexToRgba from 'hex-to-rgba';
import { SELECTION_OPACITY } from 'const/gridUI';
import classNames from 'classnames';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

function Option({ label, size, type, color, onDelete, className, lineThrough }) {
    const theme = useTheme();

    return (
        <div
            style={{
                background: hexToRgba(color || theme.palette.primary.main, SELECTION_OPACITY.BACKGROUND),
                border: `1px solid ${hexToRgba(color || theme.palette.primary.main, SELECTION_OPACITY.BORDER)}`
            }}
            className={classNames('tag tag-sm inline-flex gap-1 items-center', className, {
                'line-through': lineThrough
            })}
        >
            <div className="flex-1 body2 my-0 truncate">{label}</div>
            {onDelete && <CloseIconSVG onClick={onDelete} size="small" className="cursor-pointer" />}
        </div>
    );
}

export default memo(Option);
