import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ws from 'socket';
import { setCurrentPage } from 'app/actions';
import { PERMISSION_SETTING } from 'const';
import Members from 'permission/members';
import Groups from 'permission/groups';
import Roles from 'permission/roles';
import CompanyInfo from 'permission/companies/CompanyInfo';
import Payment from 'payment/setting';
import StatisticsSetting from 'dataCollector';
import ApiKeys from './apiKeys';
import * as paymentActions from 'payment/actions';
import * as addonActions from 'addons/actions';
import * as roleActions from 'permission/actions/role';
import * as workspaceActions from 'workspaces/actions';
import Security from './security';
import { useCompanyId, useCurrentUserFullInfo, useCurrentUserId } from 'hooks/auth';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import * as dataCollectorActions from 'dataCollector/actions';
import { isExternal } from 'config';
import LicenseSetting from 'permission/license';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}));

function Permission() {
    const classes = useStyles();
    let { path } = useRouteMatch();
    const dispatch = useDispatch();
    const userId = useCurrentUserId();
    const companyId = useCompanyId();
    const currentUser = useCurrentUserFullInfo();

    React.useEffect(() => {
        ws.permissionSettingSubscribeTopics({ userId, companyId });
        dispatch(
            setCurrentPage({
                currentPage: PERMISSION_SETTING
            })
        );
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [dispatch, userId, companyId]);

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchCustomerAndSubscriptions({
                successCallback: () => {
                    console.log('fetchCustomerAndSubscriptions success');
                },
                errorCallback: () => {
                    console.log('fetchCustomerAndSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            addonActions.getAddonsList({
                successCallback: () => {
                    console.log('fetchSubscriptions Success');
                },
                errorCallback: () => {
                    console.log('fetchSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            roleActions.fetchRoles({
                successCallback: () => {
                    console.log('fetch roles successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch roles');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspaceRoles({
                successCallback: () => {
                    console.log('fetch ProjectRoles successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch ProjectRoles');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            dataCollectorActions.getUsage({
                successCallback: () => {
                    console.log('get company usage success');
                },
                errorCallback: () => {
                    console.log('get company usage failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container className={classes.root}>
            <Switch>
                <Route path={path} exact>
                    <PermissionRedirect path={path} />
                </Route>
                <Route path={`${path}/info`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_COMPANY]}>
                        <CompanyInfo />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/members`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_MEMBER]}>
                        <Members />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/groups/:groupId?`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP]}>
                        <Groups />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/roles/:roleId?`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ROLE]}>
                        <Roles />
                    </AuthenticatedRoute>
                </Route>
                {isExternal && currentUser?.ldManager && (
                    <Route path={`${path}/license`}>
                        <AuthenticatedRoute>
                            <LicenseSetting />
                        </AuthenticatedRoute>
                    </Route>
                )}
                {!isExternal && (
                    <Route path={`${path}/billing`}>
                        <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING]}>
                            <Payment />
                        </AuthenticatedRoute>
                    </Route>
                )}
                <Route path={`${path}/statistics`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_STATISTICS]}>
                        <StatisticsSetting />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/api-keys`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_API_KEY]}>
                        <ApiKeys />
                    </AuthenticatedRoute>
                </Route>
                <Route path={`${path}/security`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_COMPANY]}>
                        <Security />
                    </AuthenticatedRoute>
                </Route>
            </Switch>
        </Grid>
    );
}

function PermissionRedirect({ path }) {
    const roles = useRole();
    const currentUser = useCurrentUserFullInfo();

    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_COMPANY] === roleConst.FULL) {
        return <Redirect to={`${path}/info`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP] === roleConst.FULL) {
        return <Redirect to={`${path}/groups`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_ROLE] === roleConst.FULL) {
        return <Redirect to={`${path}/roles`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_MEMBER] === roleConst.FULL) {
        return <Redirect to={`${path}/members`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING] === roleConst.FULL) {
        return <Redirect to={`${path}/overview`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_STATISTICS] === roleConst.FULL) {
        return <Redirect to={`${path}/statistics`} />;
    }
    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_API_KEY] === roleConst.FULL) {
        return <Redirect to={`${path}/api-keys`} />;
    }
    if (currentUser?.ldManager) {
        return <Redirect to={`${path}/license`} />;
    }
}

export default React.memo(Permission);
