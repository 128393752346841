import React from 'react';

function OtherFileTypeThumbnailSVG({ color = '#93929e', ...other }) {
    return (
        <svg {...other} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.90015 24.2H11.9001V27.7999L7.90015 24.2Z"
                fill="#55479E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3 5.1001C12.6 5.1001 12 5.7001 12 6.4001V47.6001C12 48.3001 12.6 48.9001 13.3 48.9001H44.9C45.6 48.9001 46.2 48.3001 46.2 47.6001V15.7001L35.5 5.1001H13.3Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.5999 30.9001H18.6C18.1 30.9001 17.7 30.5001 17.7 30.0001C17.7 29.5001 18.1 29.1001 18.6 29.1001H38.5999C39.0999 29.1001 39.5 29.5001 39.5 30.0001C39.5 30.5001 39.0999 30.9001 38.5999 30.9001Z"
                fill="#877CC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2001 35.3H18.2001C17.7001 35.3 17.3 34.9 17.3 34.4C17.3 33.9 17.7001 33.5 18.2001 33.5H38.2001C38.7001 33.5 39.1 33.9 39.1 34.4C39.1 34.9 38.7001 35.3 38.2001 35.3Z"
                fill="#877CC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2001 39.3H18.2001C17.7001 39.3 17.3 38.9 17.3 38.4C17.3 37.9 17.7001 37.5 18.2001 37.5H38.2001C38.7001 37.5 39.1 37.9 39.1 38.4C39.1 38.9 38.7001 39.3 38.2001 39.3Z"
                fill="#877CC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2001 43.3H18.2001C17.7001 43.3 17.3 42.9 17.3 42.4C17.3 41.9 17.7001 41.5 18.2001 41.5H38.2001C38.7001 41.5 39.1 41.9 39.1 42.4C39.1 42.9 38.7001 43.3 38.2001 43.3Z"
                fill="#877CC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46 16.5C46 16 45.8001 15.5 45.4001 15.1L35.9001 5.6C35.5001 5.2 35 5 34.5 5H13.1C12.4 5 11.8 5.6 11.8 6.3V47.5C11.8 48.2 12.4 48.8 13.1 48.8H44.7001C45.4001 48.8 46 48.2 46 47.5V16.5ZM13.1 6H34.8V14.4C34.8 15.1 35.4 15.7 36.1 15.7H44.6L45 16.1V47.6C45 47.8 44.9001 47.9 44.7001 47.9H13.1C12.9 47.9 12.8 47.7 12.8 47.6V6.4C12.8 6.1 12.9 6 13.1 6Z"
                fill="#877CC6"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.80005 24H12V27.5L7.80005 24Z" fill="#55479E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.1 24.2001H7.80005V13.1001H31.1C32.2 13.1001 33.2001 14.0001 33.2001 15.2001V22.1001C33.2001 23.3001 32.2 24.2001 31.1 24.2001Z"
                fill="#877CC6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9 19.2999H14V21.2999H12.8L12.9 16.3999H16.2V17.2999H14.1V18.3999H16L15.9 19.2999Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5 21.2001H17.3L17.4 16.3H18.6L18.5 21.2001Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5 20.3999H23.6V21.2999H20.3L20.4 16.3999H21.6L21.5 20.3999Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.1 19.3H26.2V20.5H28.4V21.4H25L25.1 16.5H28.5V17.4H26.3V18.4H28.2L28.1 19.3Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(OtherFileTypeThumbnailSVG);
