import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { Grid, Typography } from '@material-ui/core';
import GoogleAuthSVG from 'assets/images/svg/GoogleAuthSVG';
import MicrosoftAuthSVG from 'assets/images/svg/MicrosoftAuthSVG';
import AuthyAuthSVG from 'assets/images/svg/AuthyAuthSVG';
import { useCurrentUser } from 'hooks/auth';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'normal'
    }
}));

function ChooseMethodStep({ onChooseApp, onChooseEmail, onBack }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useCurrentUser();
    const { state } = useLocation();

    return (
        <Grid item container direction="column">
            <Grid item style={{ marginTop: 50 }}>
                <Typography variant="h2" className={classes.title}>
                    {t('choose_verification_method')}
                </Typography>
            </Grid>
            {state?.showHello && (
                <Grid item style={{ marginTop: 14 }}>
                    <Typography variant="body2">Hi {user?.firstName},</Typography>
                    <Typography variant="body2">{t('enforce_2fa_explain')}</Typography>
                </Grid>
            )}
            <Grid item style={{ marginTop: 32 }}>
                <ButtonBase variant="outlined" fullWidth onClick={onChooseApp}>
                    {t('authenticator_app')}
                </ButtonBase>
            </Grid>
            <Grid item style={{ marginTop: 14 }}>
                <ButtonBase variant="outlined" fullWidth onClick={onChooseEmail}>
                    {t('email_address')}
                </ButtonBase>
            </Grid>
            <Grid item style={{ marginTop: 32 }}>
                <Typography variant="h4">{t('we_support')}</Typography>
            </Grid>
            <Grid item container style={{ marginTop: 32 }}>
                <Grid item container xs={4} alignItems="center" direction="column">
                    <Grid item>
                        <GoogleAuthSVG />
                    </Grid>
                    <Grid item style={{ marginTop: 14 }}>
                        <Typography variant="body2">Google {t('authenticator')}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={4} alignItems="center" direction="column">
                    <Grid item>
                        <MicrosoftAuthSVG />
                    </Grid>
                    <Grid item style={{ marginTop: 14 }}>
                        <Typography variant="body2">Microsoft {t('authenticator')}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={4} alignItems="center" direction="column">
                    <Grid item>
                        <AuthyAuthSVG />
                    </Grid>
                    <Grid item style={{ marginTop: 14 }}>
                        <Typography variant="body2">Authy {t('authenticator')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ marginLeft: 'auto', marginTop: 32 }}>
                <ButtonBase variant="outlined" width={100} onClick={onBack}>
                    {t('global_cancel')}
                </ButtonBase>
            </Grid>
        </Grid>
    );
}

export default ChooseMethodStep;
