import React from 'react';
import { HIGHLIGHT_TYPES } from 'const/gridUI';
import { memo, useMemo } from 'react';
import Highlighter from 'components/highlighter';
import latinize from 'latinize';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import { getHighlightWords } from 'utils/gridUI/cell';
import classNames from 'classnames';
import { getLines } from 'utils/text';

function RecordId({
    value,
    rowHeight = 32,
    rowIndex,
    columnIndex,
    filterValue,
    rowId,
    group,
    isCaseSensitive,
    highlightPositions = []
}) {
    const languageDetail = LANGUAGE_DETAIL_BY_CODE?.[group];
    const isAccentInsensitive = languageDetail?.accentInsensitive;
    const isLatinize = languageDetail?.latinize;

    const lines = useMemo(() => {
        return getLines(rowHeight);
    }, [rowHeight]);

    const style = useMemo(() => {
        return {
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': `${lines}`,
            display: '-webkit-box'
        };
    }, [lines]);

    if (typeof value !== 'string' && typeof value !== 'number') {
        return null;
    }

    const mapValue = value?.toString();
    const highlightWords = getHighlightWords(mapValue, highlightPositions);

    return (
        <p
            className={classNames(
                `body2 cell_${rowIndex}_${columnIndex} w-full whitespace-pre-wrap align-top text-left leading-6 break-words child-link-highlight`
            )}
            style={style}
        >
            <Highlighter
                sanitize={isLatinize ? latinize : undefined}
                highlightClassName="highlight"
                searchWords={[
                    { key: filterValue, type: HIGHLIGHT_TYPES.DEFAULT },
                    ...highlightWords?.map(value => ({
                        key: value?.toString(),
                        type: HIGHLIGHT_TYPES.DEFAULT
                    }))
                ]}
                autoEscape={true}
                textToHighlight={mapValue || ''}
                accentInsensitive={isAccentInsensitive}
                caseSensitive={!!isCaseSensitive}
            />
        </p>
    );
}

export default memo(RecordId);
