import * as types from '../../types';
import history from 'utils/history';
import * as gridUIActions from 'gridUI/actions';
import store from 'store/configStore';

const { dispatch } = store;

const cloneHandler = ({ body }) => {
    const { subAction } = body;
    switch (subAction) {
        case types.BRANCH_GRID_END:
            return _branchGridEndHandler({ body });
        default:
            return;
    }
};

function _branchGridEndHandler({ body }) {
    const workspaceId = body?.workspaceId;
    const dbId = body?.dbId;
    const basedGridId = body?.data?.basedGridId;
    const branchId = body?.data?.id;
    const viewId = body?.data?.defaultAccessViewId;

    history.replace(
        `/projects/${workspaceId}/databases/${dbId}/grids/${basedGridId}/branches/${branchId}/views/${viewId}`
    );
    const backdrop = document.querySelector('#clone-grid-modal .MuiBackdrop-root');
    if (backdrop) backdrop.click();

    dispatch(gridUIActions.clearQuickFilterAndQuickSort());
    dispatch(gridUIActions.resetDisabledAndPendingColumnIds());
}

export default cloneHandler;
