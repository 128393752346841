import React, { useCallback, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isKbEnter, isKbEscape, isKbShiftEnter } from 'utils/keyboard';
import { Grid } from '@material-ui/core';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import EditorMention from './EditorMention';
import { checkContainId } from 'utils/clickAway';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

const useStyles = makeStyles(theme => ({
    root: {},
    editBoxCommon: {
        width: '100%'
    },
    editBox: {
        position: 'relative',
        borderColor: 'rgb(233, 234, 239)',
        background: theme.colors.ghostwhite,
        padding: '14px 14px 10px',
        borderRadius: 4
    },
    editBoxAction: {
        cursor: 'text'
    },
    edit: {
        border: '1px solid rgb(233, 234, 239)',
        background: 'rgb(255, 255, 255) !important'
    },
    content: {
        width: '100%',
        resize: `none`,
        border: `none`,
        background: `transparent`,
        fontSize: 14,
        // lineHeight: '21px',
        '&:focus': {
            outline: 'none'
        }
    },
    normalContent: {
        fontSize: 14,
        background: theme.colors.ghostwhite
    },
    mentionClassName: {
        backgroundColor: '#ffffff !important'
    }
}));

const TicketDetailDescription = ({ description, onChange, isAllowEdit, mentionedUsers = [], members = [] }) => {
    const [currentContent, setCurrentContent] = useState(description);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const rootRef = useRef();
    const editorRef = useRef();
    const classes = useStyles();
    const { t } = useTranslation();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const users = useMemo(() => {
        const arr = members.map(m => m.user);
        mentionedUsers.map(u => {
            if (!arr.find(m => m.id === u.id)) {
                arr.push(u);
            }
            return false;
        });
        return arr;
    }, [members, mentionedUsers]);

    const handleFocus = useCallback(() => {
        if (isOpenEdit) return;
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, [isOpenEdit]);

    const cancelHandler = useCallback(
        e => {
            if (editorRef.current) {
                editorRef.current.blur();
                editorRef.current.transformSelectStart();
                editorRef.current.reset({ text: description || '' });
            }
            setIsOpenEdit(false);
            setCurrentContent(description);
        },
        [description]
    );

    const handleClickAway = useCallback(
        e => {
            if (!isOpenEdit) return false;
            if (e && checkContainId(e, 'portal-mention-users')) {
                return false;
            }
            cancelHandler();
        },
        [isOpenEdit, cancelHandler]
    );

    const handleOpenEdit = useCallback(() => {
        if (!isAllowEdit || isOpenEdit) return;
        setIsOpenEdit(true);
        handleFocus();
    }, [handleFocus, isAllowEdit, isOpenEdit]);

    const handleValueChange = useCallback(val => {
        setCurrentContent(val);
    }, []);

    const handleUpdate = useCallback(() => {
        const newDescription = currentContent.trim();
        if (newDescription !== description) {
            onChange(currentContent.trim());
        }
        setIsOpenEdit(false);
        if (editorRef.current) {
            editorRef.current.blur();
        }
    }, [onChange, currentContent, description]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            cancelHandler();
        }
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            e.stopPropagation();
            handleUpdate();
        }
    };

    return (
        <Grid className={classes.root} ref={rootRef} container onClick={handleOpenEdit}>
            <Grid
                className={`
                ${classes.editBoxCommon}  ${classes.editBox}
                ${isOpenEdit ? classes.edit : ''} 
                ${isAllowEdit ? `${classes.editBoxAction}` : ''}
                `}
                item
                onClick={handleFocus}
            >
                <EditorMention
                    className={`${classes.content} ${!isOpenEdit ? classes.normalContent : ''}`}
                    ref={editorRef}
                    text={currentContent}
                    onChange={handleValueChange}
                    handleKeyDown={handleKeyDown}
                    readOnly={!isOpenEdit || !isAllowEdit}
                    members={users}
                    mentionClassName={!isOpenEdit ? classes.mentionClassName : ''}
                />
            </Grid>
            {isOpenEdit && (
                <Grid container justify="flex-end" style={{ marginTop: 14, position: 'relative', left: -4 }}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button width={80} onClick={cancelHandler} variant="outlined">
                                    {t('global_cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button width={80} onClick={handleUpdate} variant="contained">
                                    {t('global_save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(TicketDetailDescription);
