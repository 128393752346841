import React from 'react';
import { ScrollSync, Grid, defaultCellRangeRenderer } from 'react-virtualized-dn';
import { useTheme } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import CellRow from './Cell';
import HeaderFilter from './HeaderFilter';
import { getWidthDistanceToIndex, getColumnData, getWidthOfColumnList, getScrollLeftPx } from 'utils/gridUI/column';
import IndexHeader from './IndexHeader';
import IndexClearFilterHeader from './IndexClearFilterHeader';
import EmptyQuickFilter from './EmptyQuickFilter';
import IndexCell from './IndexCell';
import { SmoothScroll } from './scroll';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { getElementOffsetPosition } from 'utils/gridUI/cell';
import { getTotalRowsHeight } from 'utils/gridUI/row';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import {
    RECORDS_OFFSET_BOTTOM,
    RECORDS_OFFSET_TOP,
    INDEX_COLUMN_WIDTH,
    AGG_HEIGHT,
    OFFSET_FETCH,
    RECORDS_RENDER,
    LIMIT_ROWS,
    FREEZING_BAR_WIDTH,
    FREEZING_BAR_HANDLER_WIDTH,
    TOTAL_GRID_UI_HEADER,
    ROW_RESIZE_HEIGHT,
    DEFAULT_COLUMN_WIDTH,
    COLUMN_RESIZE_WIDTH,
    MIN_COLUMN_RESIZE,
    MAX_COLUMN_RESIZE,
    FAKE_ROW,
    FAKE_COLUMN_COUNT,
    CELL_PADDING_LEFT_RIGHT,
    SIDEBAR_LEFT_CONTENT,
    ROW_HEIGHT,
    GLOBAL_FILTER_HEIGHT,
    ROW_HEIGHT_OPTIONS,
    SIDEBAR_RIGHT_CONTENT,
    SIDEBAR_RIGHT_WIDTH,
    FILTER_MODES
} from 'const/gridUI';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import isEmpty from 'lodash/isEmpty';
import CellOverlay from './cellOverlay';
import CellCopiedOverlay from './cellOverlay/CellCopiedOverlay';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import { HEADER_HEIGHT, SCROLLBAR_RELEASE_TIME } from 'const';
import { isChrome, isMac, isWindows } from 'utils/os';
import LastColumn from './LastColumn';
import AddRecordBelow from './AddRecordBelow';
import HeaderWrapper from './HeaderWrapper';
import Tooltip from 'components/tooltip/Base';
import classNames from 'classnames';
import SearchOverlay from './searchOverlay';
import { setSearchRange } from 'gridUI/actions';

function GridUIExample({
    width,
    maxHeight,
    columns = [],
    totalRecords,
    rows,
    metaData,
    rowHeight = ROW_HEIGHT * 2,
    HEADER_ROW_HEIGHT = 36,
    fixedColumnCount = 2,
    fixedRowCount = 2,
    overscanColumnCount = 0,
    overscanRowCount = 0,
    dependencies,
    quickSorts,
    // data = {},
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    isFilteringSorting,
    quickFilters,
    viewColumns = [],
    columnWidthStore,
    accessEditRecords,
    accessRecordHistory,
    accessManageGridRecord,
    accessManageGridColumn,
    isRecomputedGrid,
    contextMenuId,
    isImporting,
    isPathTagOn,
    accessEditTranslation,
    isTmDisabled,
    isShareViewLink,
    dbId,
    workspaceId,
    gridId,
    viewId,
    branchId,
    t,
    isDisabledCreateRecord,
    fillColorBlindness,
    dependencyBlindness,
    isOverFileStorage,
    ldUserEmailMap,
    isDeletingRecords,
    accessManageTicket,
    isShowTag,
    tokenDetection,
    predefinedTokens,
    isShowAutoQA,
    totalRecordWithoutFilters
}) {
    const isRowOverLimit = React.useMemo(() => {
        return totalRecords > LIMIT_ROWS;
    }, [totalRecords]);

    const WIDTH_WHEN_PATH_TAG_ON = React.useMemo(() => (isPathTagOn ? SIDEBAR_LEFT_CONTENT : 0), [isPathTagOn]);
    const columnCount = React.useMemo(() => columns.length + FAKE_COLUMN_COUNT, [columns.length]);
    const heightLeftGridUI = React.useMemo(() => HEADER_ROW_HEIGHT * fixedRowCount, [HEADER_ROW_HEIGHT, fixedRowCount]);
    const theme = useTheme();
    const height = React.useMemo(() => maxHeight - AGG_HEIGHT, [maxHeight]);
    const topRef = React.useRef(0);
    const leftRef = React.useRef(0);
    const onScrollRef = React.useRef();
    const scrollPropsRef = React.useRef();
    const dispatch = useDispatch();

    //GridUILayoutRef
    const gridRef = React.useRef();
    const freezingGridUIRef = React.useRef();
    const leftHeaderRef = React.useRef();
    const rightHeaderRef = React.useRef();
    const leftFooterRef = React.useRef();
    const rightFooterRef = React.useRef();

    // const rowResizeRef = React.useRef();
    const columnResizeRef = React.useRef();
    const columnReorderRef = React.useRef();

    //scrolling
    const scrollStartIndexRef = React.useRef(0);
    const scrollStopIndexRef = React.useRef(0);

    const isCellCopyDraggingRef = React.useRef(false);

    const [scrollTopMore, setScrollTopMore] = React.useState(false);
    const [scrollBottomMore, setScrollBottomMore] = React.useState(false);
    const [scrollLeftMore, setScrollLeftMore] = React.useState(false);
    const [scrollRightMore, setScrollRightMore] = React.useState(false);

    //freezing handler
    const freezingHighlightRef = React.useRef();
    const isDraggingFreezingRef = React.useRef(false);

    const isFetchingMoreRecordsRef = React.useRef(false);
    const fillColumnsConfirmRef = React.useRef();
    const selectingRef = React.useRef(false);
    const addRecordRef = React.useRef();

    //column Resize
    const isColumnResizeDraggingRef = React.useRef(false);
    //RE ORDER COLUMN
    const isColumnReorderDraggingRef = React.useRef(false);

    const getWidthDistanceFrom0ToIndex = React.useCallback(
        index => {
            return getWidthDistanceToIndex({ fromIndex: 0, toIndex: index, columnWidthStore, columns });
        },
        [columns, columnWidthStore]
    );

    React.useEffect(() => {
        if (scrollBarRef.current) {
            scrollBarRef.current.recalculate();
        }
    }, [columnCount, totalRecords, rowHeight]);

    const getWidthDistanceOfColumnList = React.useCallback(
        columnIds => {
            return getWidthOfColumnList({ columnIds, columnWidthStore });
        },
        [columnWidthStore]
    );

    const getColumn = React.useCallback(
        columnIndex => {
            return getColumnData({ columnIndex, columns, metaData });
        },
        [columns, metaData]
    );

    const getColumnWidth = React.useCallback(
        ({ index }) => {
            const column = metaData?.[columns?.[index]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            return columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH;
        },
        [columns, metaData, columnWidthStore]
    );

    const getLeftGridColumnWidth = React.useCallback(
        ({ index }) => {
            if (index === 0) {
                return INDEX_COLUMN_WIDTH;
            }
            const newIndex = index - 1;
            const column = metaData?.[columns?.[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            return columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH;
        },
        [columns, metaData, columnWidthStore]
    );

    const getRightSectionColumnWidth = React.useCallback(
        ({ index }) => {
            let newIndex = index + fixedColumnCount;
            if (newIndex >= columnCount - FAKE_COLUMN_COUNT) {
                return FAKE_COLUMN_COUNT * DEFAULT_COLUMN_WIDTH;
            }
            const column = metaData?.[columns?.[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            return columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH;
        },
        [columns, columnCount, metaData, columnWidthStore, fixedColumnCount]
    );

    const getRowHeight = React.useCallback(
        ({ index }) => {
            return rowHeight;
        },
        [rowHeight]
    );

    const getTotalFreezingWidth = React.useCallback(() => {
        let arrs = Array.from(Array(fixedColumnCount).keys());
        let total = arrs.reduce((total, columnIndex) => total + getColumnWidth({ index: columnIndex }), 0);
        return total + INDEX_COLUMN_WIDTH;
    }, [fixedColumnCount, getColumnWidth]);

    const getTotalWidthWithIndex = React.useMemo(() => {
        const arr = Array.from(Array(fixedColumnCount).keys());
        return arr.reduce((total, num) => total + getColumnWidth({ index: num }), INDEX_COLUMN_WIDTH);
    }, [fixedColumnCount, getColumnWidth]);

    const totalLeftOfColumns = React.useCallback(() => {
        return getScrollLeftPx({ viewColumns }) + FAKE_COLUMN_COUNT * DEFAULT_COLUMN_WIDTH;
    }, [viewColumns]);

    const viewColumnIds = React.useMemo(() => {
        return viewColumns?.map(v => v?.id);
    }, [viewColumns]);

    //cell range selection

    const scrollToPosition = React.useCallback(({ scrollLeft, scrollTop }) => {
        onScrollRef.current({ scrollLeft, scrollTop });
    }, []);

    const setColumnHandlerStartPosition = React.useCallback(
        ({ e, columnId }) => {
            isColumnResizeDraggingRef.current = true;
            let resizeHandler = document.getElementById('columnResizeHighlight');
            let columnResizeHighlightNumber = document.getElementById('columnResizeHighlightNumber');

            if (resizeHandler) {
                const { left } = getElementOffsetPosition(e);
                let currentColumnWidth = columnWidthStore?.[columnId] || DEFAULT_COLUMN_WIDTH;
                console.log('left - WIDTH_WHEN_PATH_TAG_ON', left - WIDTH_WHEN_PATH_TAG_ON);
                resizeHandler.style.left = `${left - WIDTH_WHEN_PATH_TAG_ON}px`;
                columnResizeRef.current = {
                    leftColumnX: left - currentColumnWidth,
                    rightColumnX: left,
                    columnId,
                    currentColumnWidth
                };

                if (columnResizeHighlightNumber) {
                    columnResizeHighlightNumber.textContent = `${Math.round(currentColumnWidth)}px`;
                }
            }
        },
        [columnWidthStore, WIDTH_WHEN_PATH_TAG_ON]
    );

    const columnResizeDraggingHandler = React.useCallback(
        e => {
            if (!isColumnResizeDraggingRef.current) return;
            let columnResizeHighlight = document.getElementById('columnResizeHighlight');
            let columnResizeHighlightNumber = document.getElementById('columnResizeHighlightNumber');
            if (columnResizeHighlight) {
                let { leftColumnX } = columnResizeRef.current;
                let left = e.clientX;
                if (left - leftColumnX < MIN_COLUMN_RESIZE) {
                    columnResizeHighlight.style.left = `${leftColumnX - WIDTH_WHEN_PATH_TAG_ON + MIN_COLUMN_RESIZE}px`;
                } else {
                    columnResizeHighlight.style.left = `${left - WIDTH_WHEN_PATH_TAG_ON}px`;
                }
                columnResizeRef.current.stopPostion = left;
            }

            if (columnResizeHighlightNumber) {
                const { leftColumnX, rightColumnX, currentColumnWidth, stopPostion } = columnResizeRef.current;
                let columnWidthChange = 0;
                if (stopPostion > rightColumnX) {
                    columnWidthChange = currentColumnWidth + (stopPostion - rightColumnX);
                } else {
                    if (stopPostion - leftColumnX < MIN_COLUMN_RESIZE) {
                        columnWidthChange = MIN_COLUMN_RESIZE;
                    } else {
                        columnWidthChange = currentColumnWidth - (rightColumnX - stopPostion);
                    }
                }
                columnWidthChange = Math.min(columnWidthChange, MAX_COLUMN_RESIZE);
                columnResizeHighlightNumber.textContent = `${Math.round(columnWidthChange)}px`;
            }
        },
        [WIDTH_WHEN_PATH_TAG_ON]
    );

    const columnResizeStopHandler = React.useCallback(() => {
        isColumnResizeDraggingRef.current = false;
        let columnResizeHighlight = document.getElementById('columnResizeHighlight');
        if (columnResizeHighlight) {
            columnResizeHighlight.style.left = `-10000px`;
        }
        const { leftColumnX, rightColumnX, columnId, currentColumnWidth, stopPostion } = columnResizeRef.current;
        let columnWidthChange = 0;
        if (stopPostion > rightColumnX) {
            columnWidthChange = currentColumnWidth + (stopPostion - rightColumnX);
        } else {
            if (stopPostion - leftColumnX < MIN_COLUMN_RESIZE) {
                columnWidthChange = MIN_COLUMN_RESIZE;
            } else {
                columnWidthChange = currentColumnWidth - (rightColumnX - stopPostion);
            }
        }
        columnWidthChange = Math.min(columnWidthChange, MAX_COLUMN_RESIZE);
        dispatch(
            gridUIActions.resizeColumn({
                columnId,
                columnWidthChange
            })
        );
        columnResizeRef.current = null;
    }, [dispatch]);

    const OFFSET_MORE_LEFT = SPACING_LIST_NAME_WITH_ICON + CELL_PADDING_LEFT_RIGHT + 18;

    const columnReorderRangePositionRef = React.useRef([]);

    const setColumnReorderStartPosition = React.useCallback(
        ({ e, columnId, columnsSelected, isMulti }) => {
            isColumnReorderDraggingRef.current = true;
            let reorderHandler = document.getElementById('columnReorderHighlight');
            if (reorderHandler) {
                let width = 200;
                let newLeft = 0;
                let MORE_LEFT = 0;
                const { left } = getElementOffsetPosition(e);
                const currentLeft = e.clientX;
                if (!isMulti) {
                    width = columnWidthStore?.[columnId] || DEFAULT_COLUMN_WIDTH;
                    newLeft = left - OFFSET_MORE_LEFT;
                    MORE_LEFT = OFFSET_MORE_LEFT + (currentLeft - left);
                } else {
                    width = getWidthDistanceOfColumnList(columnsSelected);
                    let offsetLeft = 0;
                    if (columnId !== columnsSelected[0]) {
                        let currentColumnIndex = columnsSelected.findIndex(id => id === columnId);
                        let offsetColumns = columnsSelected.slice(0, currentColumnIndex);
                        offsetLeft = getWidthDistanceOfColumnList(offsetColumns);
                    }
                    MORE_LEFT = OFFSET_MORE_LEFT + offsetLeft + (currentLeft - left);
                    newLeft = left - offsetLeft - OFFSET_MORE_LEFT;
                }

                for (let i = 0; i <= columns.length - 1; i++) {
                    let widthFrom0ToIndex = getWidthDistanceFrom0ToIndex(i);
                    let columnWidth = getColumnWidth({ index: i });
                    const leftSide = widthFrom0ToIndex + INDEX_COLUMN_WIDTH + WIDTH_WHEN_PATH_TAG_ON;
                    const rightSide = widthFrom0ToIndex + columnWidth + INDEX_COLUMN_WIDTH + WIDTH_WHEN_PATH_TAG_ON;
                    let range = {
                        left: leftSide,
                        right: rightSide,
                        columnId: columns[i],
                        columnIdLeft: columns[i - 1] || null,
                        columnIdRight: columns[i + 1] || null,
                        haftWidth: leftSide + (rightSide - leftSide) / 2
                    };
                    columnReorderRangePositionRef.current.push(range);
                }

                reorderHandler.style.width = `${width}px`;
                reorderHandler.style.left = `${newLeft - WIDTH_WHEN_PATH_TAG_ON}px`;
                columnReorderRef.current = {
                    isMulti,
                    columnsSelected,
                    columnId,
                    width,
                    left,
                    MORE_LEFT
                };
            }
        },
        [
            OFFSET_MORE_LEFT,
            getWidthDistanceOfColumnList,
            columnWidthStore,
            columns,
            getWidthDistanceFrom0ToIndex,
            getColumnWidth,
            WIDTH_WHEN_PATH_TAG_ON
        ]
    );

    const columnIdReleaseRef = React.useRef(null);
    const moveLeftRef = React.useRef();

    const columnReorderDraggingHandler = React.useCallback(
        e => {
            if (!isColumnReorderDraggingRef.current) return;
            let columnReorderHighlight = document.getElementById('columnReorderHighlight');
            let columnReorderReleaseHighlight = document.getElementById('columnReorderReleaseHighlight');

            if (columnReorderHighlight && columnReorderReleaseHighlight) {
                const FREEZING_WIDTH = getTotalFreezingWidth();
                const ALL_COLUMNS_WIDTH = totalLeftOfColumns();
                const moveLeft = e.clientX;
                const { MORE_LEFT, width: reorderWidth, columnsSelected, isMulti, columnId } = columnReorderRef.current;
                const leftDragging = moveLeft - MORE_LEFT;
                const rightDragging = leftDragging + reorderWidth;
                columnReorderHighlight.style.left = `${leftDragging - WIDTH_WHEN_PATH_TAG_ON}px`;
                const { state } = gridRef.current;
                const { scrollLeft } = state;
                const WIDTH = Math.abs(width + WIDTH_WHEN_PATH_TAG_ON);
                const LEFT_EGDE_SIDE = FREEZING_WIDTH + WIDTH_WHEN_PATH_TAG_ON;

                moveLeftRef.current = moveLeft;
                if (leftDragging < LEFT_EGDE_SIDE && scrollLeft !== 0) {
                    setScrollLeftMore(true);
                } else {
                    setScrollLeftMore(false);
                }

                if (rightDragging > WIDTH && scrollLeft !== ALL_COLUMNS_WIDTH) {
                    setScrollRightMore(true);
                } else {
                    setScrollRightMore(false);
                }
                const columnReorderRangePosition = columnReorderRangePositionRef.current;

                columnReorderRangePosition.forEach(range => {
                    const { left, right, columnId: columnIdRange, haftWidth, columnIdLeft, columnIdRight } = range;
                    const leftColumn = Math.abs(scrollLeft - left);
                    const rightColumn = Math.abs(scrollLeft - right);
                    const haftColumm = Math.abs(scrollLeft - haftWidth);

                    if (moveLeft > leftColumn && moveLeft < rightColumn) {
                        if (isMulti) {
                            if (!columnsSelected.includes(columnIdRange)) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        } else {
                            if (columnId !== columnIdRange) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        }
                    }
                });
            }
        },
        [width, totalLeftOfColumns, getTotalFreezingWidth, WIDTH_WHEN_PATH_TAG_ON]
    );

    const generateDurationBaseDistance = React.useCallback(distance => {
        //1s = 400px
        const WIDTH_DEFAULT = 400;
        return Math.round((distance / WIDTH_DEFAULT) * 1000, 0);
    }, []);

    const highlightBar = React.useCallback(
        ({ scrollLeft }) => {
            let columnReorderReleaseHighlight = document.getElementById('columnReorderReleaseHighlight');
            const columnReorderRangePosition = columnReorderRangePositionRef.current;
            const moveLeft = moveLeftRef.current;
            if (!isEmpty(columnReorderRangePositionRef.current) && !isEmpty(columnReorderRef.current)) {
                const { columnsSelected, isMulti, columnId } = columnReorderRef.current;
                columnReorderRangePosition.forEach(range => {
                    const { left, right, columnId: columnIdRange, haftWidth, columnIdLeft, columnIdRight } = range;
                    const leftColumn = Math.abs(scrollLeft - left);
                    const rightColumn = Math.abs(scrollLeft - right);
                    const haftColumm = Math.abs(scrollLeft - haftWidth);

                    if (moveLeft > leftColumn && moveLeft < rightColumn) {
                        if (isMulti) {
                            if (!columnsSelected.includes(columnIdRange)) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        } else {
                            if (columnId !== columnIdRange) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        }
                    }
                });
            }
        },
        [WIDTH_WHEN_PATH_TAG_ON]
    );

    const scrollTo = React.useCallback(
        ({ element, to, duration, isDisabledScroll, isRight }) => {
            let start = element.scrollLeft,
                change = to - start,
                currentTime = 0,
                increment = 10;
            const changeABS = Math.abs(change);

            let animateScroll = function() {
                if (isDisabledScroll) {
                    clearTimeout(scrollXTimerLeftRef && scrollXTimerLeftRef.current);
                    return;
                }
                if (currentTime < changeABS) {
                    currentTime += increment;
                    let val = Math.easeInOutQuad(currentTime, start, change, changeABS);
                    element.scrollLeft = val;
                    highlightBar({ scrollLeft: val });
                    scrollXTimerLeftRef.current = setTimeout(animateScroll, increment);
                }

                if (selectingRef.current) {
                    const { _columnStopIndex, _columnStartIndex } = gridRef.current;

                    dispatch(
                        gridUIActions.scrollMoreRange({
                            columnStopIndex: isRight ? _columnStopIndex : _columnStartIndex,
                            type: 'column'
                        })
                    );
                }
            };
            animateScroll();
        },
        [highlightBar, dispatch]
    );

    const scrollXTimerLeftRef = React.useRef();

    React.useEffect(() => {
        const { state } = gridRef.current;
        const { scrollLeft } = state;
        const MainGridUI = document.getElementById('RightCell');
        if (scrollLeft !== 0) {
            scrollTo({
                element: MainGridUI,
                to: 0,
                duration: generateDurationBaseDistance(scrollLeft),
                isDisabledScroll: !scrollLeftMore,
                isRight: false
            });
        }
    }, [scrollLeftMore, scrollTo, generateDurationBaseDistance]);

    //scroll left right
    React.useEffect(() => {
        const { state } = gridRef.current;
        const { scrollLeft } = state;
        const MainGridUI = document.getElementById('RightCell');
        const ALL_COLUMNS_WIDTH = totalLeftOfColumns();

        if (scrollLeft !== ALL_COLUMNS_WIDTH) {
            scrollTo({
                element: MainGridUI,
                to: ALL_COLUMNS_WIDTH,
                duration: generateDurationBaseDistance(ALL_COLUMNS_WIDTH),
                isDisabledScroll: !scrollRightMore,
                isRight: true
            });
        }
    }, [scrollRightMore, totalLeftOfColumns, scrollTo, generateDurationBaseDistance]);

    const scrollXTimerTopRef = React.useRef();

    const scrollToTopBottom = React.useCallback(
        ({ element, to, duration, isDisabledScroll, isDown }) => {
            let start = element.scrollTop,
                change = to - start,
                currentTime = 0,
                increment = 20;

            const changeABS = Math.abs(change);

            const animateScroll = () => {
                if (isDisabledScroll) {
                    clearTimeout(scrollXTimerTopRef && scrollXTimerTopRef.current);
                    return;
                }

                if (currentTime < changeABS) {
                    currentTime += increment;
                    let val = Math.linearTween(currentTime, start, change, changeABS);
                    element.scrollTop = val;
                    scrollXTimerTopRef.current = setTimeout(animateScroll, increment);
                }

                if (isCellCopyDraggingRef.current || selectingRef.current) {
                    const { _rowStopIndex, _rowStartIndex } = gridRef.current;
                    if (isCellCopyDraggingRef.current) {
                        dispatch(
                            gridUIActions.selectingCellCopySelection({
                                rowDragStopIndex: isDown ? _rowStopIndex : _rowStartIndex
                            })
                        );
                    }

                    if (selectingRef.current) {
                        dispatch(
                            gridUIActions.scrollMoreRange({
                                rowStopIndex: isDown ? _rowStopIndex : _rowStartIndex,
                                type: 'row'
                            })
                        );
                    }
                }
            };
            animateScroll();
        },
        [dispatch]
    );

    const TOTAL_RECORD_HEIGHT = React.useMemo(() => {
        return getTotalRowsHeight({ rowHeight, totalRecords });
    }, [rowHeight, totalRecords]);

    const generateDurationBaseDistanceTopBottom = React.useCallback(
        distance => {
            //1s = 100 rows
            const HEIGHT_DEFAULT = 150 * rowHeight;
            return Math.round((distance / HEIGHT_DEFAULT) * 1000, 0);
        },
        [rowHeight]
    );

    //scroll bottom
    React.useEffect(() => {
        const state = gridRef.current?.state;
        const scrollTop = state?.scrollTop;
        const MainGridUI = document.getElementById('RightCell');
        if (scrollTop === undefined) return;
        if (!MainGridUI) return;

        if (scrollTop !== TOTAL_RECORD_HEIGHT) {
            scrollToTopBottom({
                isScrollBottom: true,
                element: MainGridUI,
                to: TOTAL_RECORD_HEIGHT,
                duration: generateDurationBaseDistanceTopBottom(TOTAL_RECORD_HEIGHT),
                isDisabledScroll: !scrollBottomMore,
                isDown: true
            });
        }
    }, [scrollBottomMore, TOTAL_RECORD_HEIGHT, scrollToTopBottom, generateDurationBaseDistanceTopBottom]);

    //scroll top
    React.useEffect(() => {
        const state = gridRef.current?.state;
        const scrollTop = state?.scrollTop;
        const MainGridUI = document.getElementById('RightCell');

        if (scrollTop === undefined) return;
        if (!MainGridUI) return;

        if (scrollTop !== 0) {
            scrollToTopBottom({
                element: MainGridUI,
                to: 0,
                duration: generateDurationBaseDistanceTopBottom(scrollTop),
                isDisabledScroll: !scrollTopMore,
                isDown: false
            });
        }
    }, [scrollTopMore, scrollToTopBottom, generateDurationBaseDistanceTopBottom]);

    Math.easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    Math.linearTween = function(t, b, c, d) {
        return (c * t) / d + b;
    };

    const columnReorderStopHandler = React.useCallback(
        e => {
            setScrollLeftMore(false);
            setScrollRightMore(false);
            isColumnReorderDraggingRef.current = false;

            let columnReorderHighlight = document.getElementById('columnReorderHighlight');
            let columnReorderReleaseHighlight = document.getElementById('columnReorderReleaseHighlight');

            if (columnReorderHighlight) {
                columnReorderHighlight.style.left = `-10000px`;
            }

            if (columnReorderReleaseHighlight) {
                columnReorderReleaseHighlight.style.left = `-10000px`;
            }

            if (columnIdReleaseRef.current) {
                const { columnId, columnsSelected, columnIdLeft, columnIdRight, isLeft } = columnIdReleaseRef.current;

                if (
                    (!isLeft && columnsSelected.includes(columnIdRight)) ||
                    (isLeft && columnsSelected.includes(columnIdLeft))
                ) {
                    return false;
                }

                const { columnId: draggingColumnId } = columnReorderRef.current;

                const body = {};
                if (isLeft && !columnIdLeft) {
                    body['beforeColumn'] = columnId;
                } else if (isLeft && columnIdLeft) {
                    body['afterColumn'] = columnIdLeft;
                } else if (!isLeft && !columnIdRight) {
                    body['afterColumn'] = columnId;
                } else if (!isLeft && columnIdRight) {
                    body['beforeColumn'] = columnIdRight;
                }

                dispatch(
                    gridUIActions.reorderColumns({
                        ...body,
                        columnId,
                        isLeft,
                        reorderColumns: columnsSelected,
                        successCallback: () => {
                            recomputeGridUI();
                            dispatch(
                                gridUIActions.setColumnsSelection({
                                    columnsSelected,
                                    columnId: draggingColumnId
                                })
                            );
                        }
                    })
                );
            }
            columnReorderRef.current = null;
            columnReorderRangePositionRef.current = [];
            columnIdReleaseRef.current = null;
        },
        [dispatch]
    );

    const startingTopFreezingHandleWhenDragging = React.useRef();
    const columnRangePositionRef = React.useRef([]);
    const freezingColumnPosition = React.useRef(fixedColumnCount);

    let isDraggingFreeze = React.useRef(false);
    const startDraggingFreezing = React.useCallback(
        e => {
            startingTopFreezingHandleWhenDragging.current = `${e.clientY - TOTAL_GRID_UI_HEADER - 10}px`;
            isDraggingFreezingRef.current = true;
            freezingHighlightRef.current.style.display = 'block';
            const dragHandler = document.getElementById('freezingHandler');

            if (dragHandler) {
                dragHandler.style.top = `${e.clientY - TOTAL_GRID_UI_HEADER - 10}px`;
                const { state } = gridRef.current;
                const { scrollTop, scrollLeft } = state;
                if (scrollLeft !== 0) {
                    scrollToPosition({
                        scrollTop,
                        scrollLeft: 0
                    });
                }
            }
            for (let i = 0; i <= columns.length - 2; i++) {
                let widthFrom0ToIndex = getWidthDistanceFrom0ToIndex(i);
                let columnWidth = getColumnWidth({ index: i });
                let range = {
                    left: widthFrom0ToIndex + INDEX_COLUMN_WIDTH,
                    right: widthFrom0ToIndex + columnWidth + INDEX_COLUMN_WIDTH,
                    index: i + 1,
                    haftWidth: widthFrom0ToIndex + columnWidth / 2
                };

                const POSSIBLE_FREEZING_WIDTH = width - SIDEBAR_RIGHT_CONTENT - SIDEBAR_RIGHT_WIDTH;

                if (range.right < POSSIBLE_FREEZING_WIDTH) {
                    columnRangePositionRef.current.push(range);
                }
            }
        },
        [columns.length, getColumnWidth, width, getWidthDistanceFrom0ToIndex, scrollToPosition]
    );

    const freezingStopHandler = React.useCallback(
        e => {
            if (!isDraggingFreeze.current) {
                freezingColumnPosition.current = {
                    index: fixedColumnCount,
                    left: getWidthDistanceFrom0ToIndex(fixedColumnCount) + INDEX_COLUMN_WIDTH
                };
            }
            isDraggingFreezingRef.current = false;
            freezingHighlightRef.current.style.display = 'none';

            let freezingDom = document.getElementById('freezing');
            let left = freezingColumnPosition?.current?.left || INDEX_COLUMN_WIDTH;
            let index = freezingColumnPosition?.current?.index || 0;

            if (freezingDom) {
                freezingDom.style.left = `${left}px`;
            }
            dispatch(
                gridUIActions.changeViewFixedColumnCount({
                    index: Math.max(Math.min(columns.length - 1, index), 0)
                })
            );
            freezingColumnPosition.current = null;
            isDraggingFreeze.current = false;
        },
        [dispatch, columns.length, fixedColumnCount, getWidthDistanceFrom0ToIndex]
    );

    const freezingDraggingHandler = React.useCallback(
        e => {
            isDraggingFreeze.current = true;
            if (!isDraggingFreezingRef.current) return;
            let freezingDom = document.getElementById('freezing');
            let freezingHighlight = freezingHighlightRef.current;

            if (freezingDom) {
                freezingDom.style.left = `${Math.max(0, e.clientX - WIDTH_WHEN_PATH_TAG_ON)}px`;

                let realLeft = Math.max(0, e.clientX - INDEX_COLUMN_WIDTH - WIDTH_WHEN_PATH_TAG_ON);

                if (realLeft === 0) {
                    if (freezingHighlight && freezingColumnPosition.current) {
                        freezingHighlight.style.left = `${INDEX_COLUMN_WIDTH - 1}px`;
                    }
                    freezingColumnPosition.current = {
                        index: 0,
                        left: INDEX_COLUMN_WIDTH
                    };
                    return;
                }

                const columnRangePosition = columnRangePositionRef.current;
                columnRangePosition.forEach(range => {
                    const { left, right, index, haftWidth } = range;
                    if (realLeft > left && realLeft < right) {
                        if (realLeft < haftWidth) {
                            freezingHighlight.style.left = `${left - 1}px`;
                            freezingColumnPosition.current = {
                                index: index - 1,
                                left
                            };
                        } else {
                            freezingHighlight.style.left = `${right - 1}px`;
                            freezingColumnPosition.current = {
                                index,
                                left: right
                            };
                        }
                    }
                });
            }
        },
        [WIDTH_WHEN_PATH_TAG_ON]
    );

    const setFreezingTopPosition = e => {
        let dragHandler = document.getElementById('freezingHandler');
        if (dragHandler && !isDraggingFreezingRef.current) {
            dragHandler.style.top = `${e.clientY - TOTAL_GRID_UI_HEADER - 10}px`;
        }
    };

    /**
     * SELECTION:
     */

    const recomputeGridUI = () => {
        gridRef.current.recomputeGridSize();
        freezingGridUIRef.current.recomputeGridSize();
        leftHeaderRef.current.recomputeGridSize();
        rightHeaderRef.current.recomputeGridSize();
        leftFooterRef.current.recomputeGridSize();
        rightFooterRef.current.recomputeGridSize();
    };

    React.useEffect(() => {
        recomputeGridUI();
    }, [rowHeight, columnWidthStore]);

    React.useEffect(() => {
        if (isRecomputedGrid) {
            recomputeGridUI();
            dispatch(gridUIActions.resetTriggerRecomputedGrid());
        }
    }, [dispatch, isRecomputedGrid]);

    /**
     * Scroll Fetch
     */
    const timerScroll = React.useRef();

    React.useEffect(() => {
        const MainGridUI = document.getElementById('RightCell');
        const HeaderGridUI = document.getElementById('RightCellHeader');
        const LeftGridUI = document.getElementById('LeftCell');
        const FooterGridUI = document.getElementById('RightCellFooter');

        const scrollbarOverlay = scrollBarRef.current.getScrollElement();

        const { _rowStartIndex, _rowStopIndex } = gridRef.current;
        scrollStartIndexRef.current = _rowStartIndex;
        scrollStopIndexRef.current = _rowStopIndex;

        dispatch(
            gridUIActions.saveTableInfo({
                tableInfo: {
                    scroll: onScrollRef.current,
                    width,
                    height,
                    gridRef: gridRef.current,
                    scrollbarOverlay,
                    fakeColumnCount: FAKE_COLUMN_COUNT
                }
            })
        );

        const normalSpeed = 60;
        const speed = isMac()
            ? normalSpeed
            : normalSpeed *
              (rowHeight <= ROW_HEIGHT_OPTIONS.DEFAULT ? 2 : rowHeight <= ROW_HEIGHT_OPTIONS.COMFORTABLE ? 4 : 6);

        // SmoothScroll({
        //     target: scrollbarOverlay,
        //     speed,
        //     smooth: 8,
        //     moveTopTargets: [LeftGridUI, MainGridUI],
        //     moveLeftTargets: [HeaderGridUI, FooterGridUI, MainGridUI],
        //     isNeedExtra: true
        // });
        SmoothScroll({
            target: MainGridUI,
            speed,
            smooth: 8,
            moveTopTargets: [LeftGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, FooterGridUI, scrollbarOverlay],
            isNeedExtra: true
        });

        SmoothScroll({
            target: LeftGridUI,
            speed,
            smooth: 8,
            moveTopTargets: [MainGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, FooterGridUI, scrollbarOverlay],
            isIgnoreLeftScroll: true,
            isNeedExtra: true
        });
    }, [dispatch, width, height, rowHeight]);

    React.useEffect(() => {
        if (!isFilteringSorting && ROW_START_INDEX === 0) {
            const { _rowStartIndex, _rowStopIndex } = gridRef.current;
            scrollStartIndexRef.current = _rowStartIndex;
            scrollStopIndexRef.current = _rowStopIndex;
            isFetchingMoreRecordsRef.current = false;
        }
    }, [isFilteringSorting, ROW_START_INDEX]);

    const _renderHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const isLastColumn = columnIndex >= columnCount - FAKE_COLUMN_COUNT;
            if (isLastColumn && rowIndex === 0) {
                const prevColumn = getColumn(columnIndex - 1);
                return <LastColumn key="last-column" style={style} prevColumnId={prevColumn?.id} />;
            }
            const column = getColumn(columnIndex);
            if (!column) return <span key={key} />;

            const columnWidth = getColumnWidth({ index: columnIndex });

            return (
                <HeaderWrapper
                    key={key}
                    style={style}
                    isLastColumn={isLastColumn}
                    columnId={column?.id}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                >
                    {rowIndex === 0 ? (
                        <Header
                            id={`grid-header-${columnIndex}`}
                            accessManageGridColumn={accessManageGridColumn}
                            accessManageGridRecord={accessManageGridRecord}
                            columnIndex={columnIndex}
                            column={column}
                            setColumnHandlerStartPosition={setColumnHandlerStartPosition}
                            setColumnReorderStartPosition={setColumnReorderStartPosition}
                            columns={columns}
                            isImporting={isImporting}
                            t={t}
                            isShareViewLink={isShareViewLink}
                            columnWidth={columnWidth}
                        />
                    ) : (
                        <HeaderFilter
                            id={`grid-header-filter-${columnIndex}`}
                            column={column}
                            quickSorts={quickSorts}
                            quickFilters={quickFilters}
                            columnWidth={columnWidth}
                            isImporting={isImporting}
                            workspaceId={workspaceId}
                            gridId={gridId}
                            viewId={viewId}
                            dbId={dbId}
                            branchId={branchId}
                            dependencies={dependencies}
                            t={t}
                            columnIndex={columnIndex}
                        />
                    )}
                </HeaderWrapper>
            );
        },
        [
            accessManageGridRecord,
            isShareViewLink,
            // columnStartIndex,
            // columnStopIndex,
            dependencies,
            workspaceId,
            gridId,
            viewId,
            branchId,
            dbId,
            t,
            isImporting,
            // contextMenuId,
            accessManageGridColumn,
            setColumnReorderStartPosition,
            getColumn,
            setColumnHandlerStartPosition,
            quickSorts,
            // isSelectionActive,
            quickFilters,
            getColumnWidth,
            columns,
            columnCount
        ]
    );

    const _renderFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const column = getColumn(columnIndex);
            if (!column) return <span key={key} />;
            return (
                <div
                    className={classNames(`grid-cell footer`, {
                        disabled: isImporting
                    })}
                    key={key}
                    style={style}
                >
                    <Footer
                        columnWidth={columnWidthStore?.[column?.id] || DEFAULT_COLUMN_WIDTH}
                        metaData={metaData}
                        key={key}
                        column={column}
                    />
                </div>
            );
        },
        [getColumn, metaData, isImporting, columnWidthStore]
    );

    const handleCellClick = React.useCallback(
        (e, { rowIndex, columnIndex, rowId, columnId }) => {
            if (document.getElementById('retranslation-processing')) {
                return;
            }
            const { isShift } = getStatusCtrlOrShiftKey(e);
            dispatch(setSearchRange(null));
            dispatch(gridUIActions.cellClick({ isShift, rowId, columnId, rowIndex, columnIndex }));
        },
        [dispatch]
    );

    const handleCellMouseDown = React.useCallback(
        (e, { rowIndex, columnIndex }) => {
            if (document.getElementById('retranslation-processing')) {
                return;
            }
            if (isCellCopyDraggingRef.current) return;
            timerRef.current = setTimeout(() => {
                selectingRef.current = true;
                dispatch(gridUIActions.enableSelecting());
                dispatch(
                    gridUIActions.selectRangeCell({
                        rowStopIndex: +rowIndex,
                        rowStartIndex: +rowIndex,
                        columnStartIndex: +columnIndex,
                        columnStopIndex: +columnIndex
                    })
                );
            }, 150);
        },
        [dispatch]
    );

    const startDraggingCellCopy = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            isCellCopyDraggingRef.current = true;
            dispatch(gridUIActions.setSelectingCopy(true));
        },
        [dispatch]
    );

    const handleCellMouseMove = React.useCallback(
        (e, { rowIndex, columnIndex, rowId }) => {
            const rowIndexElements = document.querySelectorAll(`div[rid="${rowId}"]`);
            if (rowIndexElements?.length) {
                rowIndexElements.forEach(el => {
                    el.classList.add(`cell-selected`);
                });
            }
            if (selectingRef.current) {
                dispatch(
                    gridUIActions.selectRangeCell({
                        columnStartIndex: +columnIndex,
                        columnStopIndex: +columnIndex,
                        rowStartIndex: +rowIndex,
                        rowStopIndex: +rowIndex,
                        isMoving: true
                    })
                );
            }

            if (isCellCopyDraggingRef.current) {
                dispatch(
                    gridUIActions.selectingCellCopySelection({
                        rowDragStopIndex: +rowIndex
                    })
                );
            }
        },
        [dispatch]
    );

    const handleCellMouseLeave = React.useCallback((e, { rowIndex, columnIndex, rowId }) => {
        const rowIndexElements = document.querySelectorAll(`div[rid="${rowId}"]`);
        if (rowIndexElements?.length) {
            rowIndexElements.forEach(el => {
                el.classList.remove('cell-selected');
            });
        }
    }, []);

    const handleStopCellCopy = React.useCallback(
        e => {
            if (isCellCopyDraggingRef.current) {
                isCellCopyDraggingRef.current = false;
                setScrollBottomMore(false);
                setScrollTopMore(false);
                dispatch(gridUIActions.setSelectingCopy(false));
                dispatch(gridUIActions.stopCellCopySelection(e));
            }
        },
        [dispatch]
    );

    const _renderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style, parent }) => {
            const columnId = columns[columnIndex];
            const filter = quickFilters?.[columnId];
            const filterValue = filter?.value;
            const isCaseSensitive = filter?.filterMode === FILTER_MODES.CASE_SENSITIVE;

            const rowId = rows?.[rowIndex - ROW_START_INDEX];
            const previousRowId = rows?.[rowIndex - ROW_START_INDEX - 1];
            const nextRowId = rows?.[rowIndex - ROW_START_INDEX + 1];

            const realColumnWidth = getColumnWidth({ index: columnIndex });

            const maxRowIndex = totalRecords - FAKE_ROW;
            const isLastRow = rowIndex === maxRowIndex;
            const isLastColumn = columnIndex >= columnCount - FAKE_COLUMN_COUNT;

            const positionTop = style.top;
            const positionBottom = style.top + style.height;

            if (rowIndex > maxRowIndex) return;

            if (isLastRow || isLastColumn)
                return (
                    <div
                        pst={positionTop}
                        psb={positionBottom}
                        prid={previousRowId}
                        nrid={nextRowId}
                        rindex={rowIndex}
                        key={key}
                        className={`RECORD_${rowId}`}
                        style={{
                            ...style,
                            pointerEvents: 'none',
                            background: 'transparent'
                        }}
                    />
                );

            return (
                <div
                    onClick={e => handleCellClick(e, { rowIndex, columnIndex, rowId, columnId })}
                    onMouseDown={e => handleCellMouseDown(e, { rowIndex, columnIndex })}
                    onMouseEnter={e => handleCellMouseMove(e, { rowIndex, columnIndex, rowId })}
                    onMouseLeave={e => handleCellMouseLeave(e, { rowIndex, columnIndex, rowId })}
                    key={key}
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rid={rowId}
                    rindex={rowIndex}
                    className={classNames(`grid-cell cell_${rowIndex}_${columnIndex} RECORD_${rowId}`, {
                        disabled: isImporting
                    })}
                    style={style}
                >
                    <CellRow
                        column={metaData?.[columnId]}
                        accessManageTicket={accessManageTicket}
                        isOverFileStorage={isOverFileStorage}
                        accessManageGridRecord={accessManageGridRecord}
                        isShareViewLink={isShareViewLink}
                        dbId={dbId}
                        positionTop={positionTop}
                        positionBottom={positionBottom}
                        previousRowId={previousRowId}
                        nextRowId={nextRowId}
                        dependencies={dependencies}
                        isTmDisabled={isTmDisabled}
                        isShowTag={isShowTag}
                        fillColorBlindness={fillColorBlindness}
                        dependencyBlindness={dependencyBlindness}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        columnId={columnId}
                        rowId={rowId}
                        rowHeight={rowHeight}
                        columnWidth={realColumnWidth}
                        isRowOverLimit={isRowOverLimit}
                        filterValue={filterValue}
                        accessRecordHistory={accessRecordHistory}
                        accessEditTranslation={accessEditTranslation}
                        t={t}
                        ldUserEmailMap={ldUserEmailMap}
                        tokenDetection={tokenDetection}
                        predefinedTokens={predefinedTokens}
                        isShowAutoQA={isShowAutoQA}
                        isShowLockCell={true}
                        isCaseSensitive={isCaseSensitive}
                        viewColumnIds={viewColumnIds}
                    />
                </div>
            );
        },
        [
            metaData,
            viewColumnIds,
            isShowAutoQA,
            predefinedTokens,
            tokenDetection,
            isShowTag,
            rowHeight,
            handleCellClick,
            handleCellMouseMove,
            handleCellMouseDown,
            accessManageTicket,
            ldUserEmailMap,
            isOverFileStorage,
            fillColorBlindness,
            dependencyBlindness,
            accessManageGridRecord,
            isShareViewLink,
            t,
            isTmDisabled,
            accessEditTranslation,
            dependencies,
            isImporting,
            accessRecordHistory,
            totalRecords,
            getColumnWidth,
            quickFilters,
            columns,
            columnCount,
            rows,
            ROW_START_INDEX,
            isRowOverLimit,
            dbId,
            handleCellMouseLeave,
            metaData
        ]
    );

    const _renderIndexHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            return (
                <div
                    key={key}
                    style={style}
                    className={classNames(`grid-cell`, {
                        header: rowIndex === 0
                    })}
                >
                    {rowIndex === 0 && <IndexHeader totalRecords={totalRecords} />}
                    {rowIndex === 1 && <IndexClearFilterHeader />}
                </div>
            );
        },
        [totalRecords]
    );

    const _renderLeftHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            //render index column
            if (columnIndex === 0) {
                return _renderIndexHeaderCell({ columnIndex, rowIndex, key, style });
            }
            //render rest column of left grid.
            const leftColumnIndex = columnIndex - 1;
            // const columnWidth = getColumnWidth({ index: leftColumnIndex });
            // const position = {
            //     left: getWidthDistanceFrom0ToIndex(leftColumnIndex) + INDEX_COLUMN_WIDTH + WIDTH_WHEN_PATH_TAG_ON,
            //     top: fixedRowCount * rowHeight
            // };

            return _renderHeaderCell({
                columnIndex: leftColumnIndex,
                key,
                rowIndex,
                // position,
                style
            });
        },
        [
            _renderIndexHeaderCell,
            _renderHeaderCell
            // rowHeight,
            // getColumnWidth,
            // getWidthDistanceFrom0ToIndex,
            // WIDTH_WHEN_PATH_TAG_ON
        ]
    );

    const _renderRightHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            // const columnWidth = getColumnWidth({ index: newColumnIndex });
            // const position = {
            //     left:
            //         getTotalFreezingWidth() +
            //         getWidthDistanceFromIndexToIndex({ fromIndex: fixedColumnCount, toIndex: newColumnIndex }) -
            //         leftRef.current +
            //         WIDTH_WHEN_PATH_TAG_ON,
            //     top: fixedRowCount * rowHeight
            // };
            return _renderHeaderCell({
                columnIndex: newColumnIndex,
                rowIndex,
                key,
                style
                // position
            });
        },
        [
            _renderHeaderCell,
            fixedColumnCount
            // rowHeight,
            // fixedRowCount,
            // getWidthDistanceFromIndexToIndex,
            // getTotalFreezingWidth,
            // getColumnWidth,
            // WIDTH_WHEN_PATH_TAG_ON
        ]
    );

    const _renderIndexCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            // const { state } = gridRef.current;
            // const { scrollTop } = state;
            const rowId = rows?.[rowIndex - ROW_START_INDEX];
            const previousRowId = rows?.[rowIndex - ROW_START_INDEX - 1];
            const nextRowId = rows?.[rowIndex - ROW_START_INDEX + 1];

            const maxRowIndex = totalRecords - FAKE_ROW;
            const isLastRow = rowIndex === maxRowIndex;

            const positionTop = style.top;
            const positionBottom = style.top + style.height;

            if (rowIndex > maxRowIndex) return;
            if (isLastRow) {
                if (accessManageGridRecord && !isShareViewLink) {
                    return (
                        <AddRecordBelow
                            key={key}
                            isDisabledCreateRecord={isDisabledCreateRecord}
                            positionTop={positionTop}
                            positionBottom={positionBottom}
                            previousRowId={previousRowId}
                            nextRowId={nextRowId}
                            rowIndex={rowIndex}
                            style={style}
                            addRecordRef={addRecordRef}
                        />
                    );
                }

                return;
            }

            return (
                <div
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rid={rowId}
                    rindex={rowIndex}
                    key={key}
                    style={style}
                    className={`w-full h-full flex justify-center items-center border-b border-r bg-transparent overflow-hidden RECORD_${rowId}`}
                >
                    <IndexCell
                        accessEditRecords={accessEditRecords}
                        isShareViewLink={isShareViewLink}
                        positionTop={positionTop}
                        positionBottom={positionBottom}
                        previousRowId={previousRowId}
                        nextRowId={nextRowId}
                        isPathTagOn={isPathTagOn}
                        isImporting={isImporting}
                        contextMenuId={contextMenuId}
                        // recordHistoryId={recordHistoryId}
                        // isDragging={isRowResizeDragging}
                        rowId={rowId}
                        rowIndex={rowIndex}
                        // setRowHandlerStartPosition={setRowHandlerStartPosition}
                        accessManageGridRecord={accessManageGridRecord}
                        accessRecordHistory={accessRecordHistory}
                        accessEditTranslation={accessEditTranslation}
                        t={t}
                        isDisabledCreateRecord={isDisabledCreateRecord}
                    />
                </div>
            );
        },
        [
            // rowStartIndex,
            // rowStopIndex,
            accessEditRecords,
            isShareViewLink,
            t,
            accessEditTranslation,
            isPathTagOn,
            isImporting,
            contextMenuId,
            accessRecordHistory,
            accessManageGridRecord,
            // recordHistoryId,
            totalRecords,
            // getRowHeight,
            rows,
            ROW_START_INDEX,
            // isRowResizeDragging,
            // setRowHandlerStartPosition,
            isDisabledCreateRecord
        ]
    );

    const _renderLeftCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            //render index cell body
            if (columnIndex === 0) {
                return _renderIndexCell({ columnIndex, rowIndex, key, style, parent });
            }
            const newColumnIndex = columnIndex - 1;

            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style,
                parent
            });
        },
        [_renderIndexCell, _renderCell]
    );

    const _renderRightCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style,
                // position,
                parent
            });
        },
        [_renderCell, fixedColumnCount]
    );

    const _renderIndexFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const isHide = totalRecords > 10000;
            const isShowTotal = totalRecordWithoutFilters !== totalRecords - FAKE_ROW;

            return (
                <div
                    key={key}
                    style={{
                        ...style,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    className={classNames(`grid-cell footer`, {
                        disabled: isImporting
                    })}
                >
                    {isHide && isShowTotal ? (
                        <Tooltip placement="top" title={`Total records: ${totalRecordWithoutFilters}`}>
                            <p className="body2 inline">{totalRecords - FAKE_ROW}</p>
                        </Tooltip>
                    ) : (
                        <p className="body2 inline">{totalRecords - FAKE_ROW}</p>
                    )}

                    {isShowTotal && !isHide && (
                        <Tooltip placement="top" title={`Total records: ${totalRecordWithoutFilters}`}>
                            <p className="body2 inline ml-1 text-grey-blue">({totalRecordWithoutFilters})</p>
                        </Tooltip>
                    )}
                </div>
            );
        },
        [totalRecords, isImporting, totalRecordWithoutFilters, theme]
    );

    const _renderLeftFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            if (columnIndex > fixedColumnCount || rowIndex > fixedRowCount) return;

            if (columnIndex === 0) {
                return _renderIndexFooterCell({ columnIndex, rowIndex, key, style });
            }
            const newColumnIndex = columnIndex - 1;

            return _renderFooterCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style
            });
        },
        [_renderIndexFooterCell, _renderFooterCell, fixedColumnCount, fixedRowCount]
    );

    const _renderRightFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            return _renderFooterCell({
                columnIndex: newColumnIndex,
                rowIndex,
                key,
                style
            });
        },
        [_renderFooterCell, fixedColumnCount]
    );

    let fetchMoreRequests = React.useRef([]);
    let timerFetchingRef = React.useRef();
    let isDragScrollbar = React.useRef(false);

    const lastIndexAfterRecomputedRef = React.useRef([]);
    const requestsWhenFetching = React.useRef([]);
    const lastSectionRef = React.useRef({ rowStartIndexLast: 0, rowStopIndexLast: 0 });

    const handleOnSectionRendered = React.useCallback(
        ({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, ...other }) => {
            const { state } = gridRef.current;
            const { scrollDirectionVertical } = state;

            function isDragOutRange() {
                const isOutRange = rowStopIndex > ROW_STOP_INDEX || rowStartIndex < ROW_START_INDEX;
                return isOutRange;
            }

            if (isFetchingMoreRecordsRef.current) {
                const isRefresh =
                    Math.abs(lastSectionRef?.current.rowStopIndexLast - rowStartIndex) + 1 === totalRecords - 1 ||
                    Math.abs(rowStopIndex - lastSectionRef?.current.rowStartIndexLast) + 1 === totalRecords - 1;

                const isHorizontalScroll =
                    lastSectionRef.current?.rowStartIndexLast === rowStartIndex &&
                    lastSectionRef.current?.rowStopIndexLast === rowStopIndex;

                if ((isDragOutRange() || isRefresh) && !isHorizontalScroll) {
                    window.dragScrollBar = true;
                    console.log('fetch update range');
                    let request = {
                        rowStartIndex,
                        rowStopIndex,
                        ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                        ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                    };
                    requestsWhenFetching.current.push(request);
                    if (timerScroll.current) clearTimeout(timerScroll.current);
                    isFetchingMoreRecordsRef.current = true;
                    timerScroll.current = setTimeout(() => {
                        const data = requestsWhenFetching.current[requestsWhenFetching.current.length - 1];
                        if (data) {
                            fetchServer({
                                ...data,
                                successCallback: () => {
                                    requestsWhenFetching.current = [];
                                    isDragScrollbar.current = false;
                                },
                                errorCallback: () => {
                                    requestsWhenFetching.current = [];
                                    isDragScrollbar.current = false;
                                }
                            });
                        }
                    }, 150);
                }

                return;
            }

            if (isDeletingRecords) return;

            /**
             * Draging scrollbar to another place.
             */

            if (isDragOutRange()) {
                window.dragScrollBar = true;
                isDragScrollbar.current = true;
                let request = {
                    rowStartIndex,
                    rowStopIndex,
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                };

                fetchMoreRequests.current.push(request);
                if (timerScroll.current) clearTimeout(timerScroll.current);
                isFetchingMoreRecordsRef.current = true;
                timerScroll.current = setTimeout(() => {
                    const data = fetchMoreRequests.current[fetchMoreRequests.current.length - 1];
                    if (data) {
                        fetchServer({
                            ...data,
                            successCallback: () => {
                                fetchMoreRequests.current = [];
                                isDragScrollbar.current = false;
                            },
                            errorCallback: () => {
                                fetchMoreRequests.current = [];
                                isDragScrollbar.current = false;
                            }
                        });
                    }
                }, 150);
            }

            if (
                scrollDirectionVertical === 1 &&
                !isFetchingMoreRecordsRef.current &&
                rowStopIndex > scrollStopIndexRef.current + OFFSET_FETCH &&
                !isDragScrollbar.current &&
                !isDragOutRange()
            ) {
                console.log('FETCH DOWN', rowStartIndex, OFFSET_FETCH);
                isDragScrollbar.current = false;
                isFetchingMoreRecordsRef.current = true;
                fetchServer({
                    rowStartIndex,
                    rowStopIndex,
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                });
            }

            if (
                scrollDirectionVertical === -1 &&
                !isFetchingMoreRecordsRef.current &&
                Math.abs(rowStartIndex - scrollStartIndexRef.current) > OFFSET_FETCH &&
                !isDragScrollbar.current &&
                !isDragOutRange()
            ) {
                console.log('FETCH UP', rowStartIndex, OFFSET_FETCH);
                isDragScrollbar.current = false;
                isFetchingMoreRecordsRef.current = true;
                fetchServer({
                    rowStartIndex,
                    rowStopIndex,
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                });
            }

            if (isFetchingMoreRecordsRef.current) {
                lastIndexAfterRecomputedRef.current.push(rowStartIndex);
            }

            function fetchServer({
                rowStartIndex: rowStartIndexLast,
                rowStopIndex: rowStopIndexLast,
                ROW_START_INDEX,
                ROW_STOP_INDEX,
                successCallback,
                errorCallback
            }) {
                if (timerFetchingRef.current) clearTimeout(timerFetchingRef.current);

                lastSectionRef.current = {
                    rowStartIndexLast,
                    rowStopIndexLast
                };
                timerFetchingRef.current = setTimeout(() => {
                    dispatch(
                        gridUIActions.fetchMoreRows({
                            ROW_START_INDEX: ROW_START_INDEX,
                            ROW_STOP_INDEX: ROW_STOP_INDEX,
                            successCallback: ({ rows }) => {
                                window.dragScrollBar = false;
                                scrollStartIndexRef.current = rowStartIndexLast;
                                scrollStopIndexRef.current = rowStopIndexLast;
                                isFetchingMoreRecordsRef.current = false;
                                dispatch(gridUIActions.updateRowStopIndexMap({ rows }));
                                successCallback && successCallback();
                            },
                            errorCallback: () => {
                                // window.dragScrollBar = false;
                                console.log('failed to load more');
                                errorCallback && errorCallback();
                            }
                        })
                    );
                }, 100);
            }
        },
        [
            dispatch,
            totalRecords,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            // reCalculateCellCopyPosition,
            // isCellCopyDraggingRef.current,
            isDeletingRecords
        ]
    );

    const timerSimpleBarScroll = React.useRef();

    const handleOverlayScroll = React.useCallback(e => {
        const gridState = gridRef.current?.state;
        const scrollRef = scrollableNodeRef?.current;

        const isScrollHorizontalOnly =
            gridState?.scrollTop === scrollRef?.scrollTop && gridState?.scrollLeft !== scrollRef?.scrollLeft;

        if (timerSimpleBarScroll.current) clearTimeout(timerSimpleBarScroll.current);

        timerSimpleBarScroll.current = setTimeout(
            () => {
                const target = scrollableNodeRef?.current;
                onScrollRef.current({ scrollTop: target?.scrollTop, scrollLeft: target?.scrollLeft });
            },
            isScrollHorizontalOnly ? 0 : SCROLLBAR_RELEASE_TIME
        );
    }, []);

    const timerRef = React.useRef();

    const stoppingCellSelectionHandler = React.useCallback(
        e => {
            if (timerRef.current) clearTimeout(timerRef.current);
            if (isDraggingFreezingRef.current) {
                freezingStopHandler();
            }
            // if (isRowResizeDragging) {
            //     rowResizeStopHandler();
            // }
            if (isColumnResizeDraggingRef.current) {
                columnResizeStopHandler();
            }

            if (isColumnReorderDraggingRef.current) {
                columnReorderStopHandler();
            }

            if (isCellCopyDraggingRef.current) {
                handleStopCellCopy(e);
                selectingRef.current = false;
                setScrollLeftMore(false);
                setScrollRightMore(false);
                setScrollBottomMore(false);
                setScrollTopMore(false);
                dispatch(gridUIActions.stoppingCellSelection());
            }

            if (selectingRef.current) {
                selectingRef.current = false;
                dispatch(gridUIActions.stoppingCellSelection());
                setScrollLeftMore(false);
                setScrollRightMore(false);
                setScrollBottomMore(false);
                setScrollTopMore(false);
            }
        },
        [
            columnReorderStopHandler,
            dispatch,
            // rowResizeStopHandler,
            // isRowResizeDragging,
            columnResizeStopHandler,
            // isSelecting,
            freezingStopHandler,
            handleStopCellCopy
            // cellCopyStopHandler
        ]
    );

    const selectingCellSelectionHandler = React.useCallback(
        e => {
            if (isDraggingFreezingRef.current) {
                freezingDraggingHandler(e);
            }

            if (isColumnResizeDraggingRef.current) {
                columnResizeDraggingHandler(e);
            }

            if (isColumnReorderDraggingRef.current) {
                columnReorderDraggingHandler(e);
            }

            if (isCellCopyDraggingRef.current || selectingRef.current) {
                const top = e.clientY;
                const right = e.clientX;

                const TOP_SCROLL = HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT + 2 * ROW_HEIGHT;
                const state = gridRef.current?.state;
                const scrollTop = state?.scrollTop || 0;
                const scrollLeft = state?.scrollLeft || 0;
                const FREEZING_WIDTH = getTotalFreezingWidth();
                const ALL_COLUMNS_WIDTH = totalLeftOfColumns();
                const LEFT_EDGE_SIDE = FREEZING_WIDTH + WIDTH_WHEN_PATH_TAG_ON + DEFAULT_COLUMN_WIDTH / 2;
                const BOTTOM_SCROLL = TOP_SCROLL + height - 2 * ROW_HEIGHT;
                const WIDTH = Math.abs(width + WIDTH_WHEN_PATH_TAG_ON - DEFAULT_COLUMN_WIDTH / 2);

                if (top < TOP_SCROLL && scrollTop !== 0) {
                    setScrollTopMore(true);
                } else {
                    setScrollTopMore(false);
                }

                if (top > BOTTOM_SCROLL && scrollTop !== TOTAL_RECORD_HEIGHT) {
                    setScrollBottomMore(true);
                } else {
                    setScrollBottomMore(false);
                }

                if (right < LEFT_EDGE_SIDE && scrollLeft !== 0) {
                    setScrollLeftMore(true);
                } else {
                    setScrollLeftMore(false);
                }

                if (right > WIDTH && scrollLeft !== ALL_COLUMNS_WIDTH) {
                    setScrollRightMore(true);
                } else {
                    setScrollRightMore(false);
                }
            }
        },
        [
            width,
            WIDTH_WHEN_PATH_TAG_ON,
            getTotalFreezingWidth,
            totalLeftOfColumns,
            height,
            TOTAL_RECORD_HEIGHT,
            columnReorderDraggingHandler,
            columnResizeDraggingHandler,
            freezingDraggingHandler
            // draggingCellCopy
        ]
    );

    const scrollBarRef = React.useRef();
    const scrollableNodeRef = React.useRef();

    const cellRangeRenderer = React.useCallback(
        ({ type, ...rest }) => {
            const children = defaultCellRangeRenderer(rest);
            children.push(
                <SearchOverlay key="search-overlay" type={type} />,
                <CellOverlay key="cell-overlay" onCellSelectionMouseDown={startDraggingCellCopy} type={type} />,
                <CellCopiedOverlay key="cell-copied-overlay" type={type} />
            );
            return children;
        },
        [startDraggingCellCopy]
    );

    return (
        <ScrollSync>
            {({ clientHeight, clientWidth, onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth }) => {
                return (
                    <div
                        onMouseUp={stoppingCellSelectionHandler}
                        // onMouseLeave={stoppingCellSelectionHandler}
                        // onMouseDown={startingCellSelectionHandler}
                        onMouseMove={selectingCellSelectionHandler}
                        style={{
                            width,
                            height: height
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: heightLeftGridUI,
                                width
                            }}
                            id={'gridTable'}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    ref={leftHeaderRef}
                                    id="LeftCellHeader"
                                    cellRenderer={_renderLeftHeaderCell}
                                    className={'w-full overflow-hidden outline-none bg-background'}
                                    width={getTotalFreezingWidth()}
                                    height={heightLeftGridUI}
                                    rowHeight={HEADER_ROW_HEIGHT}
                                    columnWidth={getLeftGridColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={fixedColumnCount + 1}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalFreezingWidth(),
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                            >
                                <Grid
                                    ref={rightHeaderRef}
                                    id="RightCellHeader"
                                    className={'w-full overflow-hidden outline-none bg-background'}
                                    scrollLeft={scrollLeft}
                                    cellRenderer={_renderRightHeaderCell}
                                    width={width - getTotalWidthWithIndex}
                                    overscanColumnCount={overscanColumnCount}
                                    height={heightLeftGridUI}
                                    rowHeight={HEADER_ROW_HEIGHT}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: height - heightLeftGridUI,
                                width
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    id="LeftCell"
                                    ref={freezingGridUIRef}
                                    scrollTop={scrollTop}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderLeftCell}
                                    className={'outline-none overflow-hidden'}
                                    width={getTotalFreezingWidth()}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getLeftGridColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={fixedColumnCount + 1}
                                    cellRangeRenderer={props => cellRangeRenderer({ ...props, type: 'left' })}
                                />
                            </div>
                            <div
                                id="scrollOverlay"
                                className={'w-full h-full top-0 left-0 pointer-events-none absolute z-[100]'}
                                style={{
                                    left: getTotalWidthWithIndex,
                                    width: width - getTotalWidthWithIndex,
                                    height: height - 2 * HEADER_ROW_HEIGHT
                                }}
                            >
                                <SimpleBar
                                    autoHide={false}
                                    className={classNames(`simplebar`, {
                                        mac: isMac(),
                                        'window-chrome': isWindows() && isChrome()
                                    })}
                                    style={{
                                        maxWidth: width - getTotalFreezingWidth(),
                                        maxHeight: height - heightLeftGridUI
                                    }}
                                    ref={scrollBarRef}
                                    scrollableNodeProps={{ ref: scrollableNodeRef, onScroll: handleOverlayScroll }}
                                >
                                    <div
                                        style={{
                                            width: totalLeftOfColumns() - getTotalFreezingWidth() + INDEX_COLUMN_WIDTH,
                                            height: getTotalRowsHeight({ rowHeight, totalRecords }),
                                            minHeight: height - 2 * HEADER_ROW_HEIGHT
                                        }}
                                    ></div>
                                </SimpleBar>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalWidthWithIndex,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                                className={'scroll-smooth outline-none'}
                            >
                                <Grid
                                    style={{ display: totalRecords <= 1 && rows.length < 1 ? 'none' : 'block' }}
                                    id="RightCell"
                                    ref={gridRef}
                                    scrollTop={scrollTop}
                                    scrollLeft={scrollLeft}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderRightCell}
                                    className={'outline-none overflow-hidden'}
                                    onSectionRendered={handleOnSectionRendered}
                                    cellRangeRenderer={props => cellRangeRenderer({ ...props, type: 'right' })}
                                    onScroll={props => {
                                        onScroll(props);
                                        onScrollRef.current = onScroll;
                                        scrollPropsRef.current = props;
                                        topRef.current = props.scrollTop;
                                        leftRef.current = props.scrollLeft;

                                        scrollableNodeRef.current.scrollTop = props?.scrollTop;
                                        scrollableNodeRef.current.scrollLeft = props?.scrollLeft;
                                    }}
                                    width={width - getTotalWidthWithIndex}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                                {totalRecords <= FAKE_ROW && rows.length < 1 && <EmptyQuickFilter />}
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 10,
                                bottom: 0,
                                left: 0,
                                height: AGG_HEIGHT,
                                width
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: AGG_HEIGHT,
                                    width: width,
                                    borderTop: `1px solid ${theme.colors.border}`
                                }}
                                className={'scroll-smooth outline-none'}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        zIndex: 3,
                                        height: AGG_HEIGHT,
                                        width: getTotalFreezingWidth()
                                    }}
                                >
                                    <Grid
                                        ref={leftFooterRef}
                                        id="LeftCellFooter"
                                        cellRenderer={_renderLeftFooterCell}
                                        className={'w-full overflow-hidden outline-none bg-background'}
                                        width={getTotalFreezingWidth()}
                                        height={AGG_HEIGHT}
                                        rowHeight={AGG_HEIGHT}
                                        columnWidth={getLeftGridColumnWidth}
                                        rowCount={1}
                                        columnCount={fixedColumnCount + 1}
                                    />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: getTotalWidthWithIndex,
                                        top: 0,
                                        zIndex: 3,
                                        height: AGG_HEIGHT,
                                        width: width - getTotalWidthWithIndex
                                    }}
                                >
                                    <Grid
                                        ref={rightFooterRef}
                                        id="RightCellFooter"
                                        className={'w-full overflow-hidden outline-none bg-background'}
                                        scrollLeft={scrollLeft}
                                        cellRenderer={_renderRightFooterCell}
                                        width={width - getTotalWidthWithIndex}
                                        overscanColumnCount={overscanColumnCount}
                                        height={AGG_HEIGHT}
                                        rowHeight={AGG_HEIGHT}
                                        columnWidth={getRightSectionColumnWidth}
                                        rowCount={1}
                                        columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={'group'}
                            id={'freezing'}
                            style={{
                                position: 'absolute',
                                top: 0,
                                height: height + AGG_HEIGHT,
                                width: FREEZING_BAR_WIDTH,
                                background: theme.colors.freezingColor,
                                zIndex: 20,
                                left: getTotalFreezingWidth() - FREEZING_BAR_WIDTH
                            }}
                            onMouseDown={isShareViewLink ? () => {} : startDraggingFreezing}
                            onMouseMove={setFreezingTopPosition}
                        >
                            {!isShareViewLink && (
                                <div
                                    id={'freezingHandler'}
                                    className="hidden group-hover:block"
                                    style={{
                                        position: 'absolute',
                                        width: FREEZING_BAR_HANDLER_WIDTH,
                                        height: 30,
                                        borderRadius: FREEZING_BAR_HANDLER_WIDTH / 2,
                                        background: theme.colors.freezingColor,
                                        left: -FREEZING_BAR_HANDLER_WIDTH / 2 + 1,
                                        cursor: 'pointer'
                                    }}
                                />
                            )}
                        </div>

                        <div
                            ref={freezingHighlightRef}
                            id={'freezingHighlight'}
                            style={{
                                display: 'none',
                                position: 'absolute',
                                top: 0,
                                left: -10000,
                                height: height + AGG_HEIGHT,
                                width: 0,
                                borderRight: `1px solid ${theme.colors.freezingColor}`,
                                zIndex: 20
                            }}
                        />

                        {
                            <div
                                id={'rowResizeHighlight'}
                                style={{
                                    position: 'absolute',
                                    top: -10000,
                                    height: ROW_RESIZE_HEIGHT,
                                    width: '100vw',
                                    background: `${theme.colors.resizeColor}`,
                                    zIndex: 20,
                                    opacity: 1,
                                    cursor: 'ns-resize'
                                }}
                            >
                                <div
                                    id={`rowResizeHighlightNumber`}
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 20,
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                        fontSize: 13,
                                        color: theme.colors.white,
                                        borderRadius: 4,
                                        background: `${theme.colors.resizeColor}`,
                                        left: INDEX_COLUMN_WIDTH,
                                        top: -10,
                                        cursor: 'pointer'
                                    }}
                                >
                                    36px
                                </div>
                            </div>
                        }
                        {
                            <div
                                id={'columnResizeHighlight'}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: -10000,
                                    height: height + AGG_HEIGHT,
                                    width: COLUMN_RESIZE_WIDTH,
                                    background: `${theme.colors.resizeColor}`,
                                    zIndex: 20,
                                    opacity: 1,
                                    cursor: 'ew-resize'
                                }}
                            >
                                <div
                                    id={`columnResizeHighlightNumber`}
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 20,
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                        fontSize: 13,
                                        color: theme.colors.white,
                                        borderRadius: 4,
                                        background: `${theme.colors.resizeColor}`,
                                        transform: `translateX(-50%)`,
                                        transformOrigin: 'center center',
                                        top: rowHeight,
                                        cursor: 'pointer'
                                    }}
                                >
                                    36px
                                </div>
                            </div>
                        }
                        {
                            <div
                                id={'columnReorderHighlight'}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: -10000,
                                    height: height + AGG_HEIGHT,
                                    width: 200,
                                    background: `${theme.colors.steel}`,
                                    zIndex: 20,
                                    opacity: 0.2,
                                    cursor: 'grabbing'
                                }}
                            />
                        }
                        {
                            <div
                                id={'columnReorderReleaseHighlight'}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: -10000,
                                    height: height + AGG_HEIGHT,
                                    width: 1,
                                    background: `${theme.colors.highlight}`,
                                    zIndex: 20,
                                    opacity: 1,
                                    cursor: 'grabbing'
                                }}
                            />
                        }
                        <div ref={fillColumnsConfirmRef} />
                        <div ref={addRecordRef} />
                    </div>
                );
            }}
        </ScrollSync>
    );
}

export default React.memo(GridUIExample);
