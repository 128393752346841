import React from 'react';

function TemplateSVG({ color = '#A69FC4', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M13.3168 0H2.58385C1.09317 0 0 1.1 0 2.6V13.5C0 14.8 1.09317 16 2.58385 16H13.4161C14.8075 16 16 14.9 16 13.4V2.6C15.9006 1.1 14.7081 0 13.3168 0ZM2.58385 1.5H13.4161C14.0124 1.5 14.5093 2 14.5093 2.6V4.3H1.59006V2.6C1.49068 2 1.98758 1.5 2.58385 1.5ZM1.49068 13.4V5.7H5.06832V14.5H2.58385C1.98758 14.5 1.49068 14 1.49068 13.4ZM13.3168 14.5H6.55901V5.7H14.4099V13.4C14.4099 14 13.913 14.5 13.3168 14.5Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TemplateSVG);
