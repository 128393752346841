import React from 'react';

function EssentialPlanSVG({ ...other }) {
    return (
        <svg {...other} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#2DD2A3" fillOpacity="0.8" />
            <path
                d="M34.7169 10.0028C32.9024 10.063 30.7395 11.1444 29.4298 12.6464L27.0506 15.386L20.7733 17.2798L21.5832 21.7186L16.5949 27.5517L11.2185 26.4942C10.9301 26.4462 10.6537 26.5303 10.4494 26.7346L9.24783 27.9362C9.06765 28.1164 8.97146 28.3808 9.00749 28.6332C9.03156 28.8975 9.18773 29.1257 9.41607 29.258L15.3452 32.7666L18.8538 38.5824C18.9022 38.7278 19.1496 38.9497 19.4786 38.991C19.731 39.027 19.9954 38.9309 20.1756 38.7507L21.3772 37.5491C21.5815 37.3448 21.6656 37.0684 21.6176 36.78L20.5601 31.5169L26.2935 26.4288L30.8247 27.1598L32.614 20.9735L35.3657 18.5823C36.8436 17.2966 37.9251 15.1336 37.9972 13.3072C38.0694 11.2405 36.7356 9.93072 34.7169 10.0028ZM23.4353 16.1656L25.7528 13.848C26.0849 13.5159 26.0849 12.9785 25.7528 12.6464C25.4207 12.3143 24.8833 12.3143 24.5512 12.6464L22.2337 14.964C21.9015 15.2961 21.9015 15.8335 22.2337 16.1656C22.5658 16.4976 23.1032 16.4976 23.4353 16.1656ZM33.0482 25.7785L35.3657 23.4609C35.6978 23.1288 35.6978 22.5914 35.3657 22.2593C35.0336 21.9272 34.4962 21.9272 34.1641 22.2593L31.8466 24.5769C31.5145 24.909 31.5145 25.4464 31.8466 25.7785C32.1786 26.1106 32.7161 26.1106 33.0482 25.7785ZM19.3159 15.4785L21.5472 13.2471C21.8793 12.915 21.8793 12.3776 21.5472 12.0455C21.2151 11.7134 20.6777 11.7134 20.3456 12.0455L18.1143 14.2769C17.7822 14.609 17.7822 15.1464 18.1143 15.4785C18.4463 15.8106 18.9838 15.8106 19.3159 15.4785ZM34.7649 26.4649L32.5336 28.6963C32.2015 29.0284 32.2015 29.5658 32.5336 29.8979C32.8657 30.2299 33.4032 30.2299 33.7352 29.8979L35.9665 27.6665C36.2986 27.3344 36.2986 26.797 35.9665 26.4649C35.6345 26.1329 35.097 26.1329 34.7649 26.4649Z"
                fill="white"
            />
            <path
                d="M12.6795 13.0042C12.4031 12.9682 12.1268 13.0523 11.9345 13.2446L10.7328 14.4462C10.5406 14.6384 10.4565 14.8908 10.4925 15.1671C10.5165 15.3594 10.6006 15.5156 10.7328 15.6478C10.8836 15.7985 10.4485 15.5161 21.5834 21.7159L27.0508 15.3834L12.6795 13.0042ZM35.0054 35.3301L32.6142 20.971L26.2937 26.4263C32.5057 37.5831 32.2133 37.1283 32.3619 37.2768C32.494 37.409 32.6503 37.4931 32.8425 37.5171C33.1189 37.5532 33.3713 37.4691 33.5635 37.2768L34.7651 36.0752C34.9574 35.8829 35.0414 35.6066 35.0054 35.3301ZM32.9626 16.2486L34.1642 15.047C34.4963 14.7149 34.4963 14.1775 34.1642 13.8454C33.8321 13.5134 33.2947 13.5133 32.9626 13.8454L31.761 15.047C31.4289 15.3791 31.4289 15.9166 31.761 16.2486C32.0931 16.5807 32.6306 16.5807 32.9626 16.2486Z"
                fill="#00A2C3"
            />
        </svg>
    );
}

export default React.memo(EssentialPlanSVG);
