import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { requestOtpEmailApi } from 'services/company';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'normal'
    }
}));

function EmailVerificationStep({ onVerify, onBack, isLoading, error }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [otp, setOtp] = React.useState('');

    const isValidOtp = /^\d{6}$/gm.test(otp);

    React.useEffect(() => {
        const sendOtpToEmail = async () => {
            try {
                await requestOtpEmailApi();
                dispatch(
                    enqueueSnackbar({
                        message: i18n.t('code_sent'),
                        type: 'info'
                    })
                );
            } catch (error) {
                console.log(error.message);
            }
        };
        sendOtpToEmail();
    }, [dispatch]);

    const resendOtp = async () => {
        try {
            await requestOtpEmailApi();
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('code_sent'),
                    type: 'info'
                })
            );
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        onVerify(otp);
    };

    return (
        <Grid item container direction="column">
            <form onSubmit={handleSubmit}>
                <Grid item style={{ marginTop: 50 }}>
                    <Typography variant="h2" className={classes.title}>
                        {t('verify_email_address')}
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 30 }}>
                    <Typography variant="body2" className={classes.title}>
                        {t('enter_a_code_from_your_email')}
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 18 }}>
                    <Typography variant="body1">{t('6_digits_code')}</Typography>
                </Grid>
                <Grid item style={{ marginTop: 8 }}>
                    <InputText
                        name="code"
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                        placeholder={t('enter_code')}
                        error={Boolean(error)}
                        errorText={error}
                    />
                </Grid>
                <Grid item style={{ marginTop: 14, marginLeft: 'auto' }}>
                    <Link className="cursor-pointer underline text-brand-main" onClick={resendOtp}>
                        {t('resend_code')}
                    </Link>
                </Grid>
                <div className="flex gap-2 justify-end items-center mt-5 w-full">
                    <ButtonBase variant="outlined" width={100} onClick={onBack}>
                        {t('global_back')}
                    </ButtonBase>
                    <ButtonBase disabled={!isValidOtp || isLoading} variant="contained" width={100} type="submit">
                        {isLoading ? <CircularProgress size={24} /> : t('verify')}
                    </ButtonBase>
                </div>
            </form>
        </Grid>
    );
}

export default EmailVerificationStep;
