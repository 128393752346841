import React, { useState, useRef } from 'react';
import Button from 'components/button/Base';
import uuidv1 from 'uuid/v1';
import UpdateOptionItem from './UpdateOptionItem';
import SortableList from 'components/virtualList/SortableList';
import { DEFAULT_COLOR, getSelectionRandomColorId } from 'utils/color';

const OPTION_HEIGHT = 30;
const MAX_OPTIONS_HEIGHT = 290;

function ExtraOptionsWithSelectionAndTag({
    type,
    options,
    customPropsOptions,
    handleAddMoreOption,
    handleRemoveOption,
    handleUpdateOption,
    onReorderOptionChange,
    t
}) {
    const height = React.useMemo(() => {
        const totalHeight = options.length * OPTION_HEIGHT;
        return totalHeight > MAX_OPTIONS_HEIGHT ? MAX_OPTIONS_HEIGHT : totalHeight;
    }, [options]);

    const [isFocusLastOption, setIsFocusLastOption] = useState(false);
    const listContainerRef = useRef();
    const handleAddOption = ({ color }) => {
        let option = {
            id: uuidv1(),
            label: '',
            color: getSelectionRandomColorId()
        };
        handleAddMoreOption(option);
        setIsFocusLastOption(true);
    };

    const rowRenderer = ({ rowIndex, rowData, ...rest }) => {
        const color = customPropsOptions?.[rowData?.id]?.color || DEFAULT_COLOR;

        return (
            <UpdateOptionItem
                isFocusLastOption={isFocusLastOption}
                setIsFocusLastOption={setIsFocusLastOption}
                handleRemoveOption={() => handleRemoveOption(rowData.id)}
                option={rowData}
                color={color}
                handleUpdateOption={handleUpdateOption}
                isLast={rowIndex === options.length - 1}
                handleAddOption={handleAddOption}
                containerRef={listContainerRef}
                t={t}
                {...rest}
            />
        );
    };

    const onSortEnd = React.useCallback(
        ({ oldIndex, newIndex, ...rest }) => {
            const movedOption = options?.[oldIndex];
            const lastOptions = options?.filter(option => option?.label !== movedOption?.label);
            const newOptions = [...lastOptions?.slice(0, newIndex), movedOption, ...lastOptions?.slice(newIndex)];
            onReorderOptionChange && onReorderOptionChange(newOptions);
        },
        [options, onReorderOptionChange]
    );

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
                <div ref={listContainerRef} container wrap={'nowrap'} direction="column">
                    <SortableList
                        height={height}
                        width={285}
                        items={options}
                        itemHeight={OPTION_HEIGHT}
                        maxHeight={MAX_OPTIONS_HEIGHT}
                        itemRenderer={rowRenderer}
                        onSortEnd={onSortEnd}
                    />
                </div>
            </div>
            <Button variant="outlined" fullWidth size="small" onClick={handleAddOption}>
                + {t('selection_tag_extra_options_add_option')}
            </Button>
        </div>
    );
}

export default React.memo(ExtraOptionsWithSelectionAndTag);
