import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import Table from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import { formatDateFromNow } from 'utils/datetime';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import { useHistory } from 'react-router-dom';
import * as integrationActions from 'integrations/actions';
import { useDispatch } from 'react-redux';
import { useSources, useSourcesLoading } from 'hooks/integration';
import PopperMenu from 'components/menus/Popper';
import Popup from 'integrations/connectors/sources/Popup';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { COLOR_TYPES, SOURCE_STATUS } from 'const';
import { getConnectorLabel, getConnectorIcon } from 'utils/connector';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import ChipStatus from 'components/chipStatus/ChipStatus';
import { UpperCaseFirstCharacter } from 'utils/name';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    content: {
        flex: 1,
        height: '100%'
    },
    notFound: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mt5: { marginTop: theme.spacing(4) },
    failed: {
        width: 'fit-content',
        padding: 2,
        borderRadius: 3,
        background: theme.ba
    }
}));

function NameRender({ rowData }) {
    return rowData?.name;
}

function ActionsRenderer({ rowData, onClose }) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleClick = React.useCallback(
        event => {
            stopPropagation(event);
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl, stopPropagation]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleEdit = React.useCallback(() => {
        onClose && onClose();
        setAnchorEl(null);
        history.push(`/integration-settings/connectors/source/${rowData?.id}?tab=setting`);
    }, [rowData, history, onClose]);

    const handleDelete = React.useCallback(() => {
        setOpenConfirmDelete(true);
        handleClickAway();
    }, [handleClickAway]);

    const handleCloseDeleteConfirm = React.useCallback(
        e => {
            stopPropagation(e);
            setOpenConfirmDelete(false);
        },
        [stopPropagation]
    );

    const handleAgreeDelete = React.useCallback(
        e => {
            stopPropagation(e);
            setIsDeleting(true);

            dispatch(
                integrationActions.deleteSource({
                    sId: rowData?.id,
                    success: () => {
                        setIsDeleting(false);
                        handleCloseDeleteConfirm(e);
                    },
                    error: () => {
                        setIsDeleting(false);
                        console.log('err');
                    }
                })
            );
        },
        [stopPropagation, dispatch, rowData, handleCloseDeleteConfirm]
    );

    return (
        <>
            <IconButton onClick={handleClick}>
                <IconMoreActionsSVG />
            </IconButton>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-end'}>
                    <Popup onEdit={handleEdit} onDelete={handleDelete} source={rowData} onClickAway={handleClickAway} />
                </PopperMenu>
            )}
            <Dialog open={openConfirmDelete} onClose={handleCloseDeleteConfirm}>
                <ConfirmBox
                    title={t('delete_source')}
                    body={
                        <Typography component="div" variant="body2">
                            Delete source <strong>{rowData?.name}</strong> ? All related connection with this source
                            will be halted.
                        </Typography>
                    }
                    handleCancel={handleCloseDeleteConfirm}
                    onClose={handleCloseDeleteConfirm}
                    handleAgreed={handleAgreeDelete}
                    agreeLabel={t('global_delete')}
                    isLoading={isDeleting}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </>
    );
}

function LastSync({ rowData }) {
    return rowData?.lastCheck ? formatDateFromNow(rowData?.lastCheck) : null;
}

function LastStatus({ rowData }) {
    const status = rowData?.lastCheckStatus === SOURCE_STATUS.SUCCEEDED ? 'success' : rowData?.lastCheckStatus;
    return (
        <Grid container alignItems="center" direction="row" spacing={2}>
            <Grid item>
                <ChipStatus label={UpperCaseFirstCharacter(rowData?.lastCheckStatus)} status={status} />
            </Grid>
        </Grid>
    );
}

function Connector({ rowData }) {
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item>{getConnectorIcon(rowData?.connector)}</Grid>
            <Grid item>
                <Typography variant="body2">{getConnectorLabel(rowData?.connector)}</Typography>
            </Grid>
        </Grid>
    );
}

function LoadingRow({ maxWidth }) {
    return (
        <Grid
            item
            style={{
                width: `100%`,
                maxWidth,
                height: 7,
                background: `#EEF0F5`,
                borderRadius: 20
            }}
        />
    );
}

function Sources({ onClose }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const sources = useSources();
    const sourcesLoading = useSourcesLoading();
    const roles = useRole();
    const history = useHistory();
    const [sorting, setSorting] = React.useState({});

    const isManageAddon = React.useMemo(() => {
        return roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON] === roleConst.FULL;
    }, [roles]);

    const COLUMNS = React.useMemo(() => {
        return [
            {
                label: t('global_name'),
                dataKey: 'name',
                sort: true,
                cellRenderer: sourcesLoading ? () => <LoadingRow maxWidth={120} /> : NameRender
            },
            {
                label: t('connector'),
                dataKey: 'connector',
                flexGrow: 1,
                sort: true,
                cellRenderer: sourcesLoading ? () => <LoadingRow maxWidth={250} /> : Connector
            },
            {
                label: t('last_check'),
                dataKey: 'lastCheck',
                flexGrow: 1,
                sort: true,
                cellRenderer: sourcesLoading ? () => <LoadingRow maxWidth={93} /> : LastSync
            },
            {
                label: i18n.t('global_status'),
                dataKey: 'lastCheckStatus',
                flexGrow: 1,
                sort: true,
                cellRenderer: sourcesLoading ? () => <LoadingRow maxWidth={72} /> : props => <LastStatus {...props} />
            },
            {
                label: '',
                dataKey: 'actions',
                width: 50,
                hidden: !isManageAddon || sourcesLoading,
                cellRenderer: props => <ActionsRenderer onClose={onClose} {...props} />
            }
        ];
    }, [isManageAddon, onClose, sourcesLoading, t]);

    const data = React.useMemo(() => {
        const cloneData = [...sources];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                if (sortKey === 'lastCheck') {
                    return sortType === 'desc'
                        ? new Date(valueA) - new Date(valueB)
                        : new Date(valueB) - new Date(valueA);
                }
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, sources]);

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const CreateNewSource = React.useCallback(() => {
        onClose && onClose();
        history.push('/integration-settings/connectors/source');
    }, [history, onClose]);

    React.useEffect(() => {
        dispatch(
            integrationActions.getSources({
                success: () => {},
                error: error => {
                    console.log('error', error);
                }
            })
        );
    }, [dispatch]);

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
                spacing={1}
            >
                <Grid item>
                    <NotFoundBoxSVG />
                </Grid>
                <Grid item>
                    <Typography variant="h3">{t('no_source_found')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t('click_add_new_source')}</Typography>
                </Grid>
            </Grid>
        );
    }, []);

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap">
            <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON}>
                <Grid item className={classes.mb3}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <ButtonBase onClick={CreateNewSource} width={140} variant="contained">
                            + {t('new_source')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </AccessControl>

            <Grid item className={`${classes.content} ${classes.mt5}`}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            data={
                                sourcesLoading
                                    ? Array(10)
                                          .fill()
                                          .map(() => ({}))
                                    : data
                            }
                            columns={COLUMNS}
                            width={width}
                            height={height}
                            onRowClick={index => {
                                if (sourcesLoading) return;
                                const id = data?.[index]?.id;
                                onClose && onClose();
                                history.push(`/integration-settings/connectors/source/${id}`);
                            }}
                            onSort={handleOnSort}
                            noRowsRenderer={_noRowsRenderer}
                            rowClickable
                        />
                    )}
                </AutoSizer>
            </Grid>
        </Grid>
    );
}

export default Sources;
