import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_HEIGHT, SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT } from 'const/style';
import { useDispatch } from 'react-redux';
import * as importActions from 'gridUI/actions/import';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import InputText from 'components/inputs/InputText';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import { SYSTEM_COLUMN_IDS, COLUMN_GROUP_SECTIONS } from 'const';
import { useColumnTypes } from 'hooks/gridUI/column';
import LDBasePortalGroup from 'components/selects/LDBasePortalGroup';
import Button from 'components/button/Base';
import SvgIcon from 'components/svg-icon/Base';
import { getLabelColumnType } from 'gridUI/column-types';
import produce from 'immer';
import { IMPORT_VALID_COLUMN_TYPES, IMPORT_WARNING_COLUMN_TYPES } from 'const';
import { getFriendlyColumnName } from 'utils/gridUI/column';
import { Trans, useTranslation } from 'react-i18next';
import { getTimezoneObj, LIST_TIME_ZONES } from 'const/time';
import * as columnTypesConst from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {
        width: 300
    },
    content: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px `
    },
    item: {
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        padding: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.hoverItem
        }
    },
    footer: {
        borderTop: `1px solid ${theme.colors.divider}`,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
    },
    spb3: {
        marginBottom: theme.spacing(3)
    },
    spt3: {
        marginTop: theme.spacing(3)
    }
}));

function ColumnSetup({ handleClickAway, columnId, columnType, columnName, columnIndex, columnMap, columnTimezone }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const [name, setName] = React.useState(columnName);
    const [type, setType] = React.useState({
        value: columnType,
        label: getLabelColumnType(columnType),
        icon: () => <SvgIcon type={columnType} />
    });
    const [timezone, setTimezone] = React.useState(getTimezoneObj(columnTimezone));

    let columnTypes = useColumnTypes([
        COLUMN_GROUP_SECTIONS.BASIC,
        COLUMN_GROUP_SECTIONS.CODE_FORMATTING,
        COLUMN_GROUP_SECTIONS.SYSTEM_COLUMNS
    ]);

    const column = columnMap?.[columnId];

    const isDuplicateName = React.useMemo(() => {
        const names = Object.keys(columnMap)
            ?.filter(key => columnMap?.[key]?.index !== `${column?.index}`)
            ?.map(key => columnMap?.[key]?.columnName);
        return names?.includes(name);
    }, [column, columnMap, name]);

    const validOptions = React.useMemo(() => {
        return produce(columnTypes, draft => {
            for (let group of draft) {
                group.options = group?.options
                    ?.filter(option =>
                        [...IMPORT_VALID_COLUMN_TYPES, ...IMPORT_WARNING_COLUMN_TYPES]?.includes(option?.value)
                    )
                    ?.map(option => {
                        if (IMPORT_WARNING_COLUMN_TYPES?.includes(option?.value)) {
                            return {
                                ...option,
                                isDisabled: true,
                                disabledTitle: (
                                    <Typography variant="body2">
                                        <Trans i18nKey="localization_upload_datatype_not_supported">
                                            Uploading this data type is <br /> not supported for now.
                                        </Trans>
                                    </Typography>
                                ),
                                placement: 'right'
                            };
                        }
                        return option;
                    });
            }
            return draft;
        });
    }, [columnTypes]);

    const formatGroupLabel = data => (
        <div
            style={{
                marginTop: 0,
                paddingTop: 0
            }}
        >
            <span>{data.label}</span>
        </div>
    );

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const nextColumnIndex = React.useMemo(() => {
        return columnId;
    }, [columnId]);

    const handleSave = () => {
        const columnName = getFriendlyColumnName({ columnName: name, columnType: type?.value });
        dispatch(
            importActions.saveImportGridColumn({
                columnId,
                index: nextColumnIndex,
                columnName,
                columnType: type?.value,
                columnTimezone: timezone.value
            })
        );
        handleClickAway();
    };

    const isSystemType = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS?.includes(type?.value);
    }, [type]);

    const isValidCreate = React.useMemo(() => {
        if (isSystemType) return true;
        return !!name;
    }, [isSystemType, name]);

    const handleOnKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (isKbEnter(e) && isValidCreate && !isDuplicateName) {
            handleSave();
            return;
        }
        return;
    };

    const handleColumnTypeChange = React.useCallback(option => {
        setType(option);
    }, []);

    const handleTimezoneTypeChange = React.useCallback(option => {
        setTimezone(option);
    }, []);

    return (
        <Grid ref={rootRef} container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item className={classes.content} direction="column" container>
                {!isSystemType && (
                    <Grid item className={classes.spb3}>
                        <InputText
                            name="columnName"
                            label={t('column_format_column_name_label')}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={t('column_format_column_name_label')}
                            autoFocus
                            onKeyDown={handleOnKeyDown}
                            autoSelect={true}
                            error={!isValidCreate || isDuplicateName}
                            errorText={isDuplicateName ? t(`column_format_column_name_warning`) : ``}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="body1">{t('column_format_data_type_label')}</Typography>
                        </Grid>
                        <Grid item>
                            <LDBasePortalGroup
                                ddPlaceholder={t('column_format_data_type_select')}
                                menuPlaceholder={t('column_format_data_type_search')}
                                options={validOptions}
                                onChange={handleColumnTypeChange}
                                defaultValue={type}
                                isMulti={false}
                                formatGroupLabel={formatGroupLabel}
                                isUsingContainer={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {type.value === columnTypesConst.DATETIME && (
                    <Grid item className={classes.spt3}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body1">{t('global_time_zone')}</Typography>
                            </Grid>
                            <Grid item>
                                <LDBasePortalGroup
                                    ddPlaceholder={t('column_format_time_zone_select')}
                                    menuPlaceholder={t('column_format_time_zone_search')}
                                    options={LIST_TIME_ZONES}
                                    onChange={handleTimezoneTypeChange}
                                    defaultValue={timezone}
                                    isMulti={false}
                                    isUsingContainer={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item className={classes.footer}>
                <Grid container direction="row" wrap="nowrap" alignItems="center" justify="space-between">
                    <Grid item>
                        <Button onClick={handleClickAway} width={126} variant="outlined">
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!isValidCreate || isDuplicateName}
                            onClick={handleSave}
                            width={126}
                            variant="contained"
                        >
                            {t('global_save')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ColumnSetup);
