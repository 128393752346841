import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import Tooltip from 'components/tooltip/Base';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { makeStyles, useTheme } from '@material-ui/styles';
import { DISABLED_OPACITY } from 'const/style';
import PopperMenu from 'components/menus/Popper';
import { useTranslation } from 'react-i18next';
import NormalSortSVG from 'assets/images/svg/NormalSortSVG';
import hexToRgba from 'hex-to-rgba';
import viewSortGif from 'assets/images/viewSort.gif';
import { useIsHighlightSort } from 'hooks/gridUI';
import SortMenu from '../sorts';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: theme.colors.tutorialColor,
        padding: theme.spacing(3),
        borderRadius: 4,
        width: 252
    },
    spb2: {
        marginBottom: theme.spacing(1)
    },
    viewGift: {
        width: '100%',
        height: 'auto',
        borderRadius: 4
    },
    propertyButton: {
        display: 'flex',
        margin: `0px 4px`,
        '&:hover': {
            background: theme.colors.divider
        }
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    }
}));

const ManageSort = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isHighLightSort = useIsHighlightSort();
    const [sortAnchorEl, setSortAnchorEl] = React.useState(null);

    const handleSortClick = React.useCallback(
        event => {
            setSortAnchorEl(sortAnchorEl ? null : event.currentTarget);
        },
        [sortAnchorEl]
    );

    const handleSortClickAway = React.useCallback(() => {
        setSortAnchorEl(null);
    }, []);

    return (
        <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_SORT}>
            {({ isReadOnly }) => (
                <>
                    <Tooltip
                        title={
                            <Grid container className={classes.tooltip} direction="column">
                                <Grid item className={classes.spb2}>
                                    <img className={classes.viewGift} src={viewSortGif} alt="view sort gif" />
                                </Grid>
                                <Grid item>
                                    <p className="text-light font-medium">{t('view_sort_tutorial_title')}</p>
                                </Grid>
                                <Grid item>
                                    <p className="caption">{t('view_sort_tutorial_text')}</p>
                                </Grid>
                            </Grid>
                        }
                    >
                        <Grid item>
                            <ButtonBase
                                id="g-h-menu-item-sort-access"
                                className={`${classes.propertyButton} ${classes.button} ${
                                    isReadOnly ? classes.disabled : ''
                                }`}
                                style={{
                                    // background: isHighLightSort ? theme.colors.solitude : '',
                                    background: isHighLightSort ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                                    position: 'relative'
                                }}
                                onClick={handleSortClick}
                                tr-dt="Open Column Sorts Setting"
                            >
                                <NormalSortSVG
                                    height={18}
                                    width={18}
                                    color={isHighLightSort ? theme.palette.primary.main : theme.colors.darkness}
                                />
                                {!!isHighLightSort && (
                                    <div
                                        style={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            background: theme.colors.indicator,
                                            border: `1px solid ${theme.colors.solitude}`,
                                            position: 'absolute',
                                            right: 3,
                                            top: 3
                                        }}
                                    ></div>
                                )}
                            </ButtonBase>
                        </Grid>
                    </Tooltip>
                    {sortAnchorEl && (
                        <PopperMenu
                            placement={'bottom-start'}
                            anchorEl={sortAnchorEl}
                            handleClickAway={handleSortClickAway}
                            className={`${classes.popperMenu} small`}
                        >
                            <SortMenu t={t} />
                        </PopperMenu>
                    )}
                </>
            )}
        </AccessControl>
    );
};

export default React.memo(ManageSort);
