import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import { Typography } from '@material-ui/core';
import { beautifyPrice } from 'utils/payment';
import ButtonBase from 'components/button/Base';
import { getFriendlyDate } from 'utils/datetime';
import DownloadSVG from 'assets/images/svg/DownloadSVG';
import { sendManualTrack } from 'tracker';
import { getPdfInvoiceFortnoxApi } from 'services/payment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    table: {
        border: '1px solid #e9eaef',
        borderRadius: 4,
        overflow: 'hidden',
        color: theme.colors.primaryText,
        '& .header-role': {
            marginLeft: 15
        },
        '& .cell-role': {
            paddingLeft: 15,

            '& .select': {
                marginLeft: -15
            }
        }
    }
}));

const ROW_HEIGHT = 47;
const HEADER_HEIGHT = 47;

const TableFortnox = ({ data }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [sorting, setSorting] = useState({});

    const dataSorted = React.useMemo(() => {
        const cloneData = [...data];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, data]);

    const issueDateRenderer = useCallback(({ rowData }) => {
        const { issueDate } = rowData;

        return <Typography variant="body2">{getFriendlyDate(issueDate)}</Typography>;
    }, []);

    const totalRenderer = useCallback(({ rowData }) => {
        const { total, currency } = rowData;
        const friendlyPrice = beautifyPrice({ unitAmount: total, currency });
        return <Typography variant="body2">{friendlyPrice}</Typography>;
    }, []);

    const handleDownloadFortnox = rowData => {
        sendManualTrack({ type: 'Download Invoice Fortnox' });
        const { id } = rowData;
        getPdfInvoiceFortnoxApi(id);
    };

    const actionRenderer = useCallback(({ rowData }) => {
        return (
            <ButtonBase
                variant="text"
                width={64}
                size="small"
                target="_blank"
                onClick={() => handleDownloadFortnox(rowData)}
            >
                <DownloadSVG />
            </ButtonBase>
        );
    }, []);

    const COLUMNS = useMemo(() => {
        return [
            {
                label: t('issue_date'),
                dataKey: 'issueDate',
                width: 200,
                flexGrow: 1,
                sort: true,
                cellRenderer: issueDateRenderer
            },
            {
                label: t('total_amount_paid'),
                dataKey: 'total',
                width: 200,
                flexGrow: 1,
                sort: true,
                cellRenderer: totalRenderer
            },
            { label: '', dataKey: 'id', width: 64, cellRenderer: actionRenderer }
        ];
    }, [issueDateRenderer, totalRenderer, actionRenderer, t]);

    const handleOnSort = useCallback((sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    }, []);

    return (
        <div className={classes.table} style={{ width: '100%', height: ROW_HEIGHT * 4 }}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={dataSorted}
                        columns={COLUMNS}
                        headerHeight={HEADER_HEIGHT}
                        width={width}
                        height={ROW_HEIGHT * 4}
                        rowHeight={ROW_HEIGHT}
                        onSort={handleOnSort}
                    ></Table>
                )}
            </AutoSizer>
        </div>
    );
};

export default React.memo(TableFortnox);
