import React from 'react';

function RevertSVG({ color = '#42B883', ...rest }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect width="16" height="16" rx="2" fill={color} />
            <g clipPath="url(#revert)">
                <path
                    d="M8.27474 5.77821C6.80252 5.77821 5.46918 6.32821 4.44141 7.22266L2.44141 5.22266V10.2227H7.44141L5.4303 8.21155C6.20252 7.5671 7.18585 7.1671 8.27474 7.1671C10.2414 7.1671 11.9136 8.45043 12.497 10.2227L13.8136 9.78932C13.0414 7.46155 10.8581 5.77821 8.27474 5.77821Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="revert">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(1.33203 1.33398)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(RevertSVG);
