import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';
import isArray from 'lodash/isArray';

function NormalMultipleSelection({ data, state, options = [], isHideMarkIcon = false, customProperties = {} }) {
    if (!data || data?.length === 0 || !isArray(data)) return null;

    return (
        <Grid container alignItems="center" spacing={1}>
            {data?.map((option, index) => {
                return (
                    <Grid item key={index}>
                        <Option
                            isHideMarkIcon={isHideMarkIcon}
                            data={option}
                            state={state || 'normal'}
                            options={options}
                            customProperties={customProperties}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(NormalMultipleSelection);
