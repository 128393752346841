import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import DateTimeInput from 'components/datetime/Base';
import { Grid } from '@material-ui/core';
import { Portal } from 'react-overlays';
import { DATETIME } from 'const/columnTypes';
import { INPUT_QUICK_FILTER_HEIGHT, INPUT_PADDING_LEFT, INPUT_RADIUS, DISABLED_OPACITY } from 'const/style';
import { OPERATOR } from 'gridUI/conditions';

const DEBOUNCE_TIME = 500;

const useStyles = makeStyles(theme => ({
    closeIcon: {},
    dateInput: {
        height: INPUT_QUICK_FILTER_HEIGHT
    },
    datetime: {
        paddingLeft: INPUT_PADDING_LEFT,
        height: INPUT_QUICK_FILTER_HEIGHT,
        fontSize: `13px !important`,
        color: theme.colors.primaryText,
        borderRadius: INPUT_RADIUS,
        background: theme.colors.paleGrey,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main} !important`,
            outline: 'none'
        },
        border: `none`,
        '&::placeholder': {
            color: `${theme.colors.midGrey} !important`
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY,
        cursor: 'not-allowed'
    }
}));

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children}</Portal>;
};

function DatetimeQuickFilter({ columnId, quickFilters, isDisabled, t, ...rest }) {
    const classes = useStyles();
    const [dateTime, setDateTime] = useState((quickFilters[columnId] && quickFilters[columnId].value) || null);
    const dispatch = useDispatch();
    const timer = useRef(0);

    const handleDateTimeChange = date => {
        dispatch(
            advancedSearchActions.QuickFilterChange({
                columnId,
                value: date,
                type: DATETIME,
                operator: OPERATOR.between
            })
        );
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(function() {
            dispatch(
                advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                    errorCallback: () => {
                        console.log('failed to filter');
                    },
                    successCallback: () => {
                        console.log('filter successfully');
                    }
                })
            );
        }, DEBOUNCE_TIME);
        setDateTime(date);
    };

    const handleInputClick = () => {
        dispatch(advancedSearchActions.resetCellStatus());
    };

    return (
        <Grid className={`${classes.dateInput} ${isDisabled ? classes.disabled : ``}`}>
            <DateTimeInput
                placeholder={t('global_label_pick_a_date')}
                selectedDate={dateTime}
                autoFocus={false}
                utcOffset={0}
                isShowTimeSelect={false}
                onEnter={handleDateTimeChange}
                popperContainer={CalendarContainer}
                onInputClick={handleInputClick}
                isClearable
                className={classes.datetime}
            />
        </Grid>
    );
}

export default React.memo(DatetimeQuickFilter);
