import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import { INPUT_HEIGHT } from 'const/style';
import DateTimeInput from 'components/datetime/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    datetime: {
        width: '100%',
        height: INPUT_HEIGHT,
        border: `1px solid ${theme.colors.border}`,
        padding: `6px ${theme.spacing(4)}px 6px ${theme.spacing(3)}px`,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        cursor: 'pointer'
    }
}));

function Datetime({ defaultValue, onChange }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [date, time] = React.useMemo(() => {
        if (!defaultValue || defaultValue?.length === 0) return [null, null];
        return formatDateTimeLocal(defaultValue);
    }, [defaultValue]);

    const CustomInput = React.useMemo(() => {
        return React.forwardRef(({ value, onClick }, ref) =>
            !date || !time ? (
                <Grid
                    container
                    className={classes.datetime}
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    onClick={onClick}
                    ref={ref}
                >
                    <p className="caption">MM/dd/yyyy hh:mm aa</p>
                </Grid>
            ) : (
                <Grid
                    container
                    className={classes.datetime}
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    onClick={onClick}
                    ref={ref}
                >
                    <Grid item>
                        <p className="body2">{date}</p>
                    </Grid>
                    <Grid item>
                        <p className="body2">{time}</p>
                    </Grid>
                </Grid>
            )
        );
    }, [date, time, classes]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <p className="body1 inline">{t('global_default_value')}</p>{' '}
                <p className="caption inline">({t('global_optional')})</p>
            </Grid>
            <Grid item>
                <DateTimeInput
                    placeholder={t('global_label_pick_a_date')}
                    selectedDate={defaultValue}
                    utcOffset={0}
                    isShowTimeSelect={true}
                    onEnter={date => onChange(date)}
                    isClearable
                    className={classes.datetime}
                    customInput={<CustomInput />}
                    popperPlacement="bottom"
                />
            </Grid>
        </Grid>
    );
}

export default Datetime;
