import orderBy from 'lodash/orderBy';
import { DEFAULT_COLUMN_WIDTH, SPECIAL_SPLIT_KEY } from 'const/gridUI';
import * as columnTypes from 'const/columnTypes';
import { MaxNumberInAr, isLDEmpty } from 'utils/object';
import validator from 'validator';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { beautifyJSONValue, getCorrectColumnType } from 'utils/gridUI/formatData';
import { DISABLED_COLUMN_BY_TYPES, ALL_LANGUAGES } from 'const';
import { getWorkspaceRole } from 'utils/workspace';
import { isTempId } from 'utils/uuid';
import * as roleConst from 'auth/roleConst';
import { FORMULA_RESULT_TYPE } from 'components/formula/const';

export function generateNewColumnsOrder(columns) {
    return orderBy(columns, ['order'], ['asc'])?.map((column, index) => ({
        ...column,
        order: index + 1
    }));
}

export function getDisabledColumnIds({
    viewColumns = [],
    disabledColumns = [],
    processingColumns = [],
    disabledSourceColumns = [],
    disabledColumnIdsByType = [],
    referenceDisabledColumns = []
}) {
    return viewColumns
        .filter(vCol => !vCol.editable)
        .map(vCol => vCol.id)
        .concat([
            ...disabledColumns,
            ...processingColumns,
            ...disabledSourceColumns,
            ...disabledColumnIdsByType,
            ...referenceDisabledColumns
        ]);
}

export function getEditableColumns({
    columnIds = [],
    viewColumns = [],
    disabledColumns = [],
    processingColumns = [],
    disabledSourceColumns = [],
    disabledColumnIdsByType = [],
    referenceDisabledColumns = []
}) {
    const disabledInViewColumnIds = viewColumns
        ?.filter(viewCol => !viewCol?.editable || !viewCol?.viewable)
        ?.map(viewCol => viewCol?.id);

    const pendingColumnIds = [
        ...new Set([
            ...disabledColumns,
            ...processingColumns,
            ...disabledSourceColumns,
            ...disabledInViewColumnIds,
            ...disabledColumnIdsByType,
            ...referenceDisabledColumns
        ])
    ];

    return columnIds?.filter(colId => !pendingColumnIds?.includes(colId));
}

export function reOrderColumnsOnlyExistingColumns({ viewColumns, columns }) {
    let cloneViewColumns = [...viewColumns];
    let newColumns = orderBy(cloneViewColumns, ['order'], ['asc']).filter(viewCol => columns.includes(viewCol.id));
    return newColumns.map(column => column.id);
}

export function getExactColumns(viewColumns) {
    let cloneViewColumns = [...viewColumns];
    let filteredColumns = cloneViewColumns.filter(col => col.viewable);
    let newColumns = orderBy(filteredColumns, ['order'], ['asc']);
    return newColumns.map(column => column.id);
}

export function getAllExistingEditableColumnIds(viewColumns) {
    let cloneViewColumns = [...viewColumns];
    let filteredColumns = cloneViewColumns?.filter(col => col.viewable)?.filter(col => col?.editable);
    let newColumns = orderBy(filteredColumns, ['order'], ['asc']);
    return newColumns.map(column => column.id);
}

export function isDragMultipleColumn({ columnsSelected, columns }) {
    let isMulti = false;
    for (let i = 0; i < columnsSelected.length - 1; i++) {
        let currentValue = columnsSelected[i];
        let nextValue = columnsSelected[i + 1];
        let currentValueIndexInColumns = columns.findIndex(columnId => columnId === currentValue);
        let nextValueIndexInColumns = columns.findIndex(columnId => columnId === nextValue);
        if (nextValue !== -1 && Math.abs(nextValueIndexInColumns - currentValueIndexInColumns) === 1) {
            isMulti = true;
        } else {
            isMulti = false;
        }
    }
    return isMulti;
}

export const getWidthOfColumnList = ({ columnIds, columnWidthStore }) => {
    return columnIds.reduce((total, columnId) => {
        const realColumnWidth = columnWidthStore[columnId] ? columnWidthStore[columnId] : DEFAULT_COLUMN_WIDTH;
        total += realColumnWidth;
        return total;
    }, 0);
};

export const getWidthDistanceToIndex = ({ fromIndex, toIndex, columnWidthStore, columns }) => {
    let restColumns = columns.slice(fromIndex, toIndex);
    return restColumns.reduce((total, columnId) => {
        const realColumnWidth = columnWidthStore?.[columnId] ? columnWidthStore?.[columnId] : DEFAULT_COLUMN_WIDTH;
        total += realColumnWidth;
        return total;
    }, 0);
};

export const getColumnData = ({ columnIndex, columns, metaData }) => {
    const columnId = columns?.[columnIndex];
    if (!columnId) return null;
    return metaData?.[columnId];
};

export function reOrderColumns(columns) {
    let newColumns = orderBy(columns, ['order'], ['asc']);
    return newColumns?.map(column => column.id);
}

export function getScrollLeftPx({ viewColumns }) {
    let total = 0;
    viewColumns.forEach(viewColumn => {
        const viewable = viewColumn?.viewable;
        if (!viewable) {
            total += 0;
        } else {
            total += getViewColumnWidth(viewColumn);
        }
    });
    return total;
}

export function generateColumnData(rowId, columns, record) {
    let obj = {};
    obj[`_recordId`] = rowId;
    for (let i = 0; i < columns.length; i++) {
        obj[columns[i]] = record[i + 1];
    }
    return obj;
}

export function generateColumnDataWithoutRecordId(rowId, columns, record) {
    let obj = {};
    obj[`_recordId`] = rowId;
    for (let i = 0; i < columns.length; i++) {
        obj[columns[i]] = record[i];
    }
    return obj;
}

export function formatColumnPermission({ viewColumns, gridColumnsCombined }) {
    let orders = viewColumns?.map(vCol => vCol?.order);
    return gridColumnsCombined.map(gridColumn => {
        const matchViewColumn = viewColumns.find(viewColumn => viewColumn.id === gridColumn.id);
        if (matchViewColumn) {
            return {
                ...gridColumn,
                order: matchViewColumn?.order,
                viewable: matchViewColumn,
                editable: matchViewColumn.editable
            };
        }

        const gridOrder = MaxNumberInAr(orders) + 1;
        orders.push(gridOrder);
        return {
            ...gridColumn,
            order: gridOrder,
            editable: false,
            viewable: false
        };
    });
}

export function calcColumnWidth(index, columns, tableWidth, metaData) {
    const column = metaData[columns[index]];
    if (!column) return DEFAULT_COLUMN_WIDTH;

    let width = getDeterministicColumnWidth(column, tableWidth);

    if (width) {
        return width;
    }

    const totalAllocatedWidth = columns.reduce(
        (result, c) => result + (getDeterministicColumnWidth(metaData[c], tableWidth) || 0),
        0
    );

    // Evenly distribute remaining width amoungst columns (accounting for minWidths)
    const variableWidthColumns = columns.filter(
        c => typeof metaData[c].width !== 'number' && typeof metaData[c].width !== 'string'
    );
    const initialDistributedWidthPerColumn = (tableWidth - totalAllocatedWidth) / variableWidthColumns.length;
    const activeMinWidthColumns = variableWidthColumns.filter(c =>
        metaData[c].minWidth > initialDistributedWidthPerColumn ? metaData[c].minWidth : 0
    );
    const allocatedMinWidth = activeMinWidthColumns.reduce((result, c) => result + metaData[c].minWidth, 0);
    const remainingWidth = tableWidth - totalAllocatedWidth - allocatedMinWidth;
    const columnWidth = Math.max(
        column.minWidth || 0,
        remainingWidth / (variableWidthColumns.length - activeMinWidthColumns.length)
    );

    return columnWidth;
}

function getDeterministicColumnWidth(column, tableWidth) {
    if (typeof column.width === 'number') {
        // Fixed width
        return column.width;
    } else if (typeof column.width === 'string') {
        // Percentage width
        const percentageBasedWidth = percentToFixedWidth(column.width, tableWidth);
        return Math.max(percentageBasedWidth, column.minWidth || 0);
    } else {
        // Variable width
        return null;
    }
}

function percentToFixedWidth(percentAsString, tableWidth) {
    return (parseFloat(percentAsString) / 100) * tableWidth;
}

export function isSelectionColumnType(type) {
    if (
        type === columnTypes.MULTIPLE_SELECTIONS ||
        type === columnTypes.GROUP_TAGS ||
        type === columnTypes.REFERENCE ||
        type === columnTypes.SINGLE_SELECTION ||
        type === columnTypes.LOCALIZATION
    ) {
        return true;
    }
    return false;
}

export function getMaxOrder(columns) {
    const orders = columns?.map(col => col?.order);
    return MaxNumberInAr(orders);
}

function _getJsonValue(value) {
    if (typeof value === 'string' && !validator.isJSON(value)) {
        return [{ _dataItem: null, value: null }];
    }

    if (typeof value === 'number') {
        return [{ _dataItem: value?.toString(), value: value?.toString() }];
    }

    if (validator.isJSON(value)) {
        return [{ _dataItem: beautifyJSONValue(value), value: beautifyJSONValue(value) }];
    }

    return [{ _dataItem: value?.toString(), value: value?.toString() }];
}

export function convertJSONValue({ value, currentCellData }) {
    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });
            const values = _getJsonValue(convertValue);
            return values?.map(i => ({
                ...i,
                _color
            }));
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { _dataItem: currentCellData?.value || '' }];
        }
    }

    return _getJsonValue(value);
}

function _getBooleanValue(value) {
    if (typeof value === 'string' && validator.isBoolean(value.toLowerCase())) {
        if (value.toLowerCase() === 'true') {
            return [{ _dataItem: true }, { value: true }];
        }
        if (value.toLowerCase() === 'false') {
            return [{ _dataItem: false }, { value: false }];
        }
    }

    if (typeof value === 'number') {
        return value === 0
            ? [{ _dataItem: false }, { value: false }]
            : value === 1
            ? [{ _dataItem: true }, { value: true }]
            : [{ _dataItem: null }, { value: null }];
    }

    if (typeof value === 'boolean') {
        return [{ _dataItem: value }, { value }];
    }

    return [{ _dataItem: null }, { value: null }];
}

export function convertBooleanValue({ value, currentCellData }) {
    if (typeof value === 'boolean') {
        return [{ _dataItem: value }, { value }];
    }

    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });
            const values = _getBooleanValue(convertValue);
            return values?.map(i => ({
                ...i,
                _color
            }));
        } catch (error) {
            return [{ _dataItem: currentCellData?.value }, { _dataItem: currentCellData?.value }];
        }
    }

    return _getBooleanValue(value);
}

export function convertDateTimeValue({ value, currentCellData }) {
    try {
        if (value?.includes('_isInternal')) {
            try {
                const parseValue = JSON.parse(value);
                const cellValue = parseValue.value;
                const type = parseValue.type;
                const _color = parseValue._color;
                const convertValue = _getTextByType({ type, cellValue });
                return [
                    {
                        _color,
                        _dataItem: typeof convertValue === 'string' ? new Date(convertValue).toISOString() : null
                    },
                    {
                        _color,
                        value: typeof convertValue === 'string' ? new Date(convertValue).toISOString() : null
                    }
                ];
            } catch (error) {
                return [{ _dataItem: currentCellData?.value || null }, { _dataItem: currentCellData?.value || null }];
            }
        }

        if (typeof value === 'string') {
            return [{ _dataItem: new Date(value).toISOString() }, { value: new Date(value).toISOString() }];
        } else {
            return [{ _dataItem: currentCellData?.value }, { _dataItem: currentCellData?.value }];
        }
    } catch (error) {
        return [{ _dataItem: currentCellData?.value }, { _dataItem: currentCellData?.value }];
    }
}

export function convertFilesValue({ value, columnId, currentCellData }) {
    if (!value) {
        return [{ _dataItem: null }, { value: null }];
    }

    try {
        const parseValue = JSON.parse(value);
        const cellValue = parseValue?.value;
        const fileColumnId = parseValue?.columnId;
        const referencedColumnType = parseValue.referencedColumnType;

        const isHasColor = parseValue?.hasOwnProperty('_color');

        if (referencedColumnType === columnTypes.FILES) {
            if (isArray(cellValue) && !isEmpty(cellValue)) {
                let items = [];
                cellValue.forEach(refItem => {
                    try {
                        const files = refItem?.referencedDataItem;
                        items = items.concat(files);
                    } catch (err) {
                        console.log('err', err);
                    }
                });
                return [{ _dataItem: items?.map(item => item?.id) }, { value: items }]?.map(i =>
                    isHasColor ? { ...i, _color: parseValue?._color } : i
                );
            } else {
                return [
                    { _dataItem: currentCellData?.value?.map(item => item?.id) },
                    { value: currentCellData?.value }
                ]?.map(i => (isHasColor ? { ...i, _color: parseValue?._color } : i));
            }
        }

        if (columnId !== fileColumnId) {
            return [
                { _dataItem: currentCellData?.value?.map(item => item?.id) },
                { value: currentCellData?.value }
            ]?.map(i => (isHasColor ? { ...i, _color: parseValue?._color } : i));
        } else {
            return [{ _dataItem: cellValue?.map(item => item?.id) }, { value: cellValue }]?.map(i =>
                isHasColor ? { ...i, _color: parseValue?._color } : i
            );
        }
    } catch (err) {
        return [{ _dataItem: currentCellData?.value?.map(item => item?.id) }, { value: currentCellData?.value }];
    }
}

function _getTextByType({ type, cellValue }) {
    switch (type) {
        case columnTypes.FILES:
            return cellValue?.map(item => item?.originalName)?.join(SPECIAL_SPLIT_KEY);
        case columnTypes.REFERENCE:
            return cellValue?.map(item => item?.referencedDataItem)?.join(SPECIAL_SPLIT_KEY);

        default:
            return isLDEmpty(cellValue) ? null : cellValue;
    }
}

export function convertTextValue({ value, currentCellData }) {
    if (isLDEmpty(value)) {
        return [{ _dataItem: value || null }, { value }];
    }

    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });

            return [
                { _color, _dataItem: typeof convertValue === 'number' ? convertValue : convertValue || null },
                { _color, value: convertValue }
            ];
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { value: currentCellData?.value }];
        }
    }

    if (typeof value === 'number') {
        return [{ _dataItem: value?.toString() }, { value: value?.toString() }];
    }

    if (value?.includes('referencedRecordId') && value?.includes('referencedDataItem')) {
        return [
            { _dataItem: _convertRefValue({ value, oldValue: currentCellData?.value }) },
            { value: _convertRefValue({ value, oldValue: currentCellData?.value }) }
        ];
    }

    if (value?.includes('thumbnailId') && value?.includes('originalName')) {
        return [
            { _dataItem: _convertFileValue({ value, oldValue: currentCellData?.value }) },
            { value: _convertFileValue({ value, oldValue: currentCellData?.value }) }
        ];
    }

    return [{ _dataItem: value.toString() }, { value: value.toString() }];
}

export function convertYAMLValue({ value, currentCellData }) {
    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });
            return [
                { _dataItem: convertValue || null, _color },
                { value: convertValue, _color }
            ];
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { _dataItem: currentCellData?.value || null }];
        }
    }

    return [{ _dataItem: value || null }, { value }];
}

export function convertHtmlValue({ value, currentCellData }) {
    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });
            return [
                { _dataItem: convertValue || null, _color },
                { value: convertValue, _color }
            ];
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { _dataItem: currentCellData?.value || null }];
        }
    }
    return [{ _dataItem: value || null }, { value }];
}

export function convertSingleSelectionValue({ value, currentCellData }) {
    if (value instanceof Array) {
        const item = value?.[0];
        return typeof item === 'string'
            ? [{ _dataItem: item }, { value: item }]
            : [{ _dataItem: null }, { value: null }];
    }

    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });

            return [
                { _color, _dataItem: convertValue?.toString() || null },
                { _color, value: convertValue?.toString() || null }
            ];
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { value: currentCellData?.value }];
        }
    }

    if (value?.includes('thumbnailId') && value?.includes('originalName')) {
        const convertValue = _convertFileValue({ value, oldValue: null });

        return [{ _dataItem: convertValue }, { value: convertValue }];
    }

    if (typeof value === 'string') {
        return [{ _dataItem: value || null }, { value: value || null }];
    }
    return [{ _dataItem: null }, { value: null }];
}

// function getPossibleValue({ value, options }) {
//     let items = value.filter(item => options.includes(item));
//     return items?.length > 0 ? [...new Set(items)] : null;
// }

export function convertMultiSelectionsValue({ value, currentCellData }) {
    if (value instanceof Array) {
        const convertValue = value?.filter(item => typeof item === 'string');
        return [{ _dataItem: convertValue }, { value: convertValue }];
    }

    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });

            return [
                { _color, _dataItem: convertValue?.toString().split(SPECIAL_SPLIT_KEY) || null },
                { _color, value: convertValue?.toString()?.split(SPECIAL_SPLIT_KEY) || null }
            ];
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { value: currentCellData?.value }];
        }
    }

    if (value?.includes('thumbnailId') && value?.includes('originalName')) {
        const convertValue = _convertFileValue({ value, oldValue: null })?.split(SPECIAL_SPLIT_KEY);
        return [{ _dataItem: convertValue }, { value: convertValue }];
    }

    if (typeof value === 'string') {
        const convertValue = value?.split(SPECIAL_SPLIT_KEY);
        return [{ _dataItem: convertValue }, { value: convertValue }];
    }

    return [{ _dataItem: null }, { value: null }];
}

function _convertRefValue({ value, oldValue }) {
    try {
        const parseValue = JSON.parse(value);
        const data = parseValue?.data;
        const referencedColumnType = parseValue.referencedColumnType;

        switch (referencedColumnType) {
            case columnTypes.FILES: {
                if (isArray(data) && !isEmpty(data)) {
                    let items = [];

                    data.forEach(refItem => {
                        try {
                            const files = refItem?.referencedDataItem;
                            items = items.concat(files);
                        } catch (err) {
                            console.log('err', err);
                        }
                    });
                    return [items?.map(item => item?.id), items];
                } else {
                    return [oldValue?.map(item => item?.id), oldValue];
                }
            }

            default: {
                if (isArray(data) && !isEmpty(data)) {
                    return data?.map(item => item?.referencedDataItem)?.join(',');
                }
            }
        }
    } catch (err) {
        return oldValue;
    }
}

function _convertFileValue({ value, oldValue }) {
    try {
        const parseValue = JSON.parse(value);
        const data = parseValue?.data;

        if (isArray(data) && !isEmpty(data)) {
            return data?.map(item => item?.originalName)?.join(SPECIAL_SPLIT_KEY);
        } else {
            return oldValue;
        }
    } catch (err) {
        return oldValue;
    }
}

function _convertNumber(value) {
    if (typeof value === 'string' && validator.isNumeric(value)) {
        return [{ _dataItem: +value }, { value: +value }];
    }

    if (typeof value === 'number') {
        return [{ _dataItem: value }, { value: value }];
    }
    return [{ _dataItem: null }, { value: null }];
}

export function convertNumberValue({ value, currentCellData }) {
    if (isLDEmpty(value) || typeof value === 'number') {
        return [{ _dataItem: value }, { value }];
    }

    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });
            const castValue = _convertNumber(convertValue);

            return castValue?.map(i => ({
                ...i,
                _color
            }));
        } catch (error) {
            return [{ _dataItem: currentCellData?.value || null }, { value: currentCellData?.value }];
        }
    }

    if (typeof value === 'string' && validator.isNumeric(value)) {
        return [{ _dataItem: +value }, { value: +value }];
    }
    return [{ _dataItem: null }, { value: null }];
}

export function convertPathTagValue({ value, currentCellData }) {
    if (isLDEmpty(value)) {
        return [{ _dataItem: value }, { value }];
    }

    if (value?.includes('_isInternal')) {
        try {
            const parseValue = JSON.parse(value);
            const cellValue = parseValue.value;
            const type = parseValue.type;
            const _color = parseValue._color;
            const convertValue = _getTextByType({ type, cellValue });

            return [
                { _dataItem: convertValue, _color },
                { value: convertValue, _color }
            ];
        } catch (error) {
            return [{ _dataItem: currentCellData?.value }, { value: currentCellData?.value }];
        }
    }

    if (typeof value === 'string') {
        return [{ _dataItem: value }, { value: value }];
    }

    return [{ _dataItem: null }, { value: null }];
}

export function convertReferenceValue({ value, columnId, currentCellData }) {
    if (isLDEmpty(value)) {
        return getRefValue(currentCellData);
    }

    try {
        const parseValue = JSON.parse(value);
        const refColumnId = parseValue?.columnId;

        if (columnId !== refColumnId) {
            return getRefValue(currentCellData);
        } else {
            return getRefValue(parseValue);
        }
    } catch (err) {
        return getRefValue(currentCellData);
    }
}

function getRefValue(cellValue) {
    const isHasColor = cellValue?.hasOwnProperty('_color');
    const value = cellValue?.value;

    let [serverValue, storeValue] = [{}, {}];

    if (isHasColor) {
        serverValue._color = cellValue?._color;
        storeValue._color = cellValue?._color;
    }

    if (isLDEmpty(value)) {
        serverValue.value = null;
        storeValue.value = null;
        return [serverValue, storeValue];
    }

    if (isArray(value) && !isEmpty(value)) {
        const items = value?.filter(
            obj => obj.hasOwnProperty('referencedRecordId') && obj.hasOwnProperty('referencedDataItem')
        );

        serverValue._dataItem = items.map(item => item?.referencedRecordId);
        storeValue.value = items;

        return [serverValue, storeValue];
    } else {
        serverValue.value = null;
        storeValue.value = null;
        return [serverValue, storeValue];
    }
}

export function getFriendlyColumnName({ columnName, columnType }) {
    switch (columnType) {
        case columnTypes.RECORD_ID:
            return 'Record ID';
        case columnTypes.PATH_TAG:
            return 'Path';
        default:
            return columnName;
    }
}

export function getViewColumnCustomPropertiesByColumnId({ columnId, viewColumns }) {
    return getViewColumnCustomProperties(viewColumns?.find(cId => cId === columnId));
}

export function getViewColumnWidthByColumnId({ columnId, viewColumns }) {
    return getViewColumnWidth(viewColumns?.find(viewColumn => viewColumn?.id === columnId));
}

export function getViewColumnCustomProperties(viewColumn) {
    return viewColumn?.customProperties;
}

export function getViewColumnWidth(viewColumn) {
    return viewColumn?.customProperties?.width || DEFAULT_COLUMN_WIDTH;
}

export function getDisableColumnIdsByType({ viewColumns, metaData }) {
    const viewColumnIds = viewColumns?.map(viewColumn => viewColumn?.id);
    return viewColumnIds?.filter(columnId => {
        const column = metaData?.[columnId];
        return DISABLED_COLUMN_BY_TYPES?.includes(column?.type);
    });
}

export function setDefaultValueNewColumn(oldColumn, newColumn) {
    const { type: oldType } = oldColumn;
    const { type: newType, defaultValue } = newColumn;
    if (oldType === columnTypes.BOOLEAN) {
        if (newType !== columnTypes.BOOLEAN) {
            return '';
        } else {
            return defaultValue;
        }
    }
    switch (newType) {
        case columnTypes.BOOLEAN:
            return false;
        default:
            return defaultValue;
    }
}

export function getAllDisabledSourceColumnIds({ viewColumns, metaData, dependencies = [], languagePairs = [] }) {
    const sourceIds = [];

    for (const viewCol of viewColumns) {
        const columnId = viewCol?.id;
        const columnDetail = metaData?.[columnId];
        const parentType = getCorrectColumnType(columnDetail);
        const dependencyFound = dependencies?.find(dpDc => dpDc?.parent === columnId);

        //child
        const child = dependencyFound?.child;
        const childDetail = metaData?.[child];
        const childColumnType = getCorrectColumnType(childDetail);

        if (parentType === columnTypes.TRANSLATION && dependencyFound && childColumnType === columnTypes.TRANSLATION) {
            sourceIds.push(viewCol?.id);
        }
    }

    return sourceIds?.filter(columnId => {
        //check this columnId has parent dependency;
        const parentDependency = dependencies?.find(dpDc => dpDc?.child === columnId);
        const parent = parentDependency?.parent;

        if (!parent) return true;

        const to = metaData?.[columnId]?.group;
        const from = metaData?.[parent]?.group;

        const isSourceIncludeParent = sourceIds.includes(parent);

        if (!isSourceIncludeParent) return true;

        const isMatch = _isMatchLanguagePair({
            to,
            from,
            languagePairs
        });

        return !isMatch;
    });
}

function _isMatchLanguagePair({ languagePairs = [], to, from }) {
    return languagePairs?.some(pair => {
        const fromInPair = pair?.from;
        const toInPair = pair?.to;

        if (fromInPair === ALL_LANGUAGES && toInPair === ALL_LANGUAGES) return true;
        if (toInPair === ALL_LANGUAGES && from === fromInPair) return true;

        if (fromInPair === ALL_LANGUAGES && toInPair === to) return true;

        return toInPair === to && fromInPair === from;
    });
}

export function getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth }) {
    const role = auth?.role;
    const workspaceRoleStore = auth?.workspaceRole;
    const viewColumns = orderBy(
        gridUI?.viewColumns?.filter(col => col?.viewable),
        ['order'],
        ['asc']
    );
    const workspaceRole = getWorkspaceRole({ role, workspaceRole: workspaceRoleStore });
    const languagePairs = auth.currentUser?.languagePairs;
    const metaData = gridUI?.metaData;
    const dependencies = gridUI?.dependencies?.filter(dpDc => !isTempId(dpDc?.id));

    if ([roleConst.CREATOR, roleConst.EDITOR, roleConst.VIEWER, roleConst.LOCALIZATION_MANAGER].includes(workspaceRole))
        return viewColumns;

    return viewColumns.filter(viewCol => {
        const columnId = viewCol?.id;
        const columnInfo = metaData?.[columnId];
        const columnType = getCorrectColumnType(columnInfo);

        if (columnType !== columnTypes.TRANSLATION) return true;
        const country = columnInfo?.group;
        if (languagePairs?.length === 0) return false;

        const matchedLanguagePairs = findMatchLanguagePairs({ languagePairs, country });

        if (matchedLanguagePairs?.length === 0) return false;
        const isShow = isShowMatchedLanguagePairsColumn({
            country,
            columnId,
            matchedLanguagePairs,
            metaData,
            dependencies
        });

        if (!isShow) return false;

        return true;
    });
}

export function findMatchLanguagePairs({ languagePairs = [], country }) {
    return languagePairs?.filter(pair => {
        if (pair.from === ALL_LANGUAGES || pair.to === ALL_LANGUAGES) return true;
        if (pair.to === country || pair.from === country) return true;
        return false;
    });
}

export function isShowMatchedLanguagePairsColumn({ country, columnId, matchedLanguagePairs, dependencies, metaData }) {
    let isValid = false;

    for (let matchedLanguagePair of matchedLanguagePairs) {
        const from = matchedLanguagePair?.from;
        const to = matchedLanguagePair?.to;

        if (from === ALL_LANGUAGES && to === ALL_LANGUAGES) {
            isValid = true;
            break;
        } else if (to === ALL_LANGUAGES) {
            if (from === country) {
                const matchDpDcs = dependencies?.filter(dpDc => dpDc?.parent === columnId || dpDc?.child === columnId);
                if (matchDpDcs?.length) {
                    isValid = true;
                    break;
                }
            } else {
                const matchDpDcs = dependencies?.filter(dpDc => dpDc?.child === columnId);

                const countries = matchDpDcs
                    ?.map(dpDc => {
                        const parent = dpDc?.parent;
                        const parentColumn = metaData?.[parent];
                        return parentColumn?.group;
                    })
                    ?.filter(Boolean);

                if (countries?.includes(from)) {
                    isValid = true;
                    break;
                }
            }
        } else if (from === ALL_LANGUAGES) {
            if (to === country) {
                const matchDpDcs = dependencies?.filter(dpDc => dpDc?.child === columnId || dpDc?.parent === columnId);
                if (matchDpDcs?.length) {
                    isValid = true;
                    break;
                }
            } else {
                const matchDpDcs = dependencies?.filter(dpDc => dpDc?.parent === columnId);

                const countries = matchDpDcs
                    ?.map(dpDc => {
                        const child = dpDc?.child;
                        const childColumn = metaData?.[child];
                        return childColumn?.group;
                    })
                    ?.filter(Boolean);

                if (countries?.includes(to)) {
                    isValid = true;
                    break;
                }
            }
        } else {
            if (from === country) {
                const matchDpDcs = dependencies?.filter(dpDc => dpDc?.parent === columnId);

                const countries = matchDpDcs?.map(dpDc => {
                    const child = dpDc?.child;
                    const childColumn = metaData?.[child];
                    return childColumn?.group;
                });

                if (countries?.includes(to)) {
                    isValid = true;
                    break;
                }
            }

            if (to === country) {
                const matchDpDcs = dependencies?.filter(dpDc => dpDc?.child === columnId);

                const countries = matchDpDcs?.map(dpDc => {
                    const parent = dpDc?.parent;
                    const parentColumn = metaData?.[parent];
                    return parentColumn?.group;
                });

                if (countries?.includes(from)) {
                    isValid = true;
                    break;
                }
            }
        }
    }

    return isValid;
}

export function getReferenceDisabledColumns({ auth, gridUI }) {
    const viewColumns = getViewColumnsWithReorderAndLanguagePairs({ auth, gridUI });
    const metaData = gridUI?.metaData;

    return viewColumns
        ?.filter(col => {
            const column = metaData?.[col?.id];
            const type = getCorrectColumnType(column);

            if (type !== columnTypes.REFERENCE) return false;

            const referenceSettings = column?.referenceSettings;
            const primaryReference = referenceSettings?.primaryReference;
            const referenceType = referenceSettings?.referenceType;

            if (referenceType === 'row' && !primaryReference) return true;
            return false;
        })
        ?.map(col => col?.id);
}

export function getCombinedColumnId(column) {
    return `${column?.dbId}.${column?.gridId}.${column?.columnId}`;
}

export function getRealColumnType(column) {
    const correctColumnType = getCorrectColumnType(column);
    if (correctColumnType !== columnTypes.FORMULA) return correctColumnType;
    switch (column?.formula?.resultType) {
        case FORMULA_RESULT_TYPE.BOOLEAN:
            return columnTypes.BOOLEAN;
        case FORMULA_RESULT_TYPE.DECIMAL:
            return columnTypes.NUMBER;
        case FORMULA_RESULT_TYPE.DATETIME:
            return columnTypes.DATETIME;
        case FORMULA_RESULT_TYPE.LIST:
            return FORMULA_RESULT_TYPE.LIST;
        default:
            return correctColumnType;
    }
}
