import { useEffect, useState } from 'react';

const checkInView = (container, element, partial) => {
    //Get container properties
    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;

    //Get element properties
    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight;

    //Check if in view
    let isTotal = eTop >= cTop && eBottom <= cBottom;
    let isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));
    return { isNewTotal: isTotal, isNewPartial: isPartial };
};

const useScrollInViewListener = ({
    container,
    element,
    partial = true,
    condition,
    defaultIsTotal = true,
    defaultIsPartial = true
}) => {
    const [data, setData] = useState({
        isTotal: defaultIsTotal,
        isPartial: defaultIsPartial
    });

    useEffect(() => {
        if (!container && !element) return;
        const handleEvent = () => {
            const { isNewTotal, isNewPartial } = checkInView(container, element, partial);
            if (isNewTotal !== data.isTotal || isNewPartial !== data.isPartial) {
                setData({
                    isTotal: isNewTotal,
                    isPartial: isNewPartial
                });
            }
        };
        if (condition) {
            container.addEventListener('scroll', handleEvent);
        } else {
            container.removeEventListener('scroll', handleEvent);
        }
        return () => {
            container.removeEventListener('scroll', handleEvent);
        };
    }, [container, element, partial, condition, data.isTotal, data.isPartial]);

    return data;
};

export default useScrollInViewListener;
