import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NoDatabase from './NoDatabase';
import DatabaseList from './DatabaseList';
import DatabaseAdd from './DatabaseAdd';
import { getDatabases } from './actions';
import { Grid, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import DatabaseListSkeleton from './DatabaseListSkeleton';
import { HEADER_HEIGHT, USER_SETTINGS, PAYMENT_WARNING_TYPES, PLAN_TYPES } from 'const';
import { useHistory, useParams } from 'react-router-dom';
import { useDatabaseDetailByWorkspaceId, useIsFetchingDatabases } from 'hooks/database';
import { useWorkspaceList, useSelectedWorkspace } from 'hooks/workspace';
import Tutorial from 'components/tutorial/Base';
import Dialog from '@material-ui/core/Dialog';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { WORKSPACE_DETAILS, DASHBOARD_TUTORIAL_FROM_SIGNUP } from 'const';
import { Skeleton } from '@material-ui/lab';
import * as appActions from 'app/actions';
import { useRightSidebarType } from 'hooks/app';
import * as paymentActions from 'payment/actions';
import * as addonActions from 'addons/actions';
import WarningBox from 'payment/components/warning';
import ButtonBase from 'components/button/Base';
import { useCurrentSubscriptionIs, useIsProTrial, useIsMemberSeatExceeded } from 'hooks/payment';

import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Base';
import { useCurrentTutorial, useIsShowFreeTrial } from 'hooks/app';
import { useIsFinishDashboardTutorial } from 'hooks/auth';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import PreloadImages from 'components/preload/Image';
import uploadTranslationGridGif from 'assets/images/uploadTranslationGrid.gif';
import emptyTranslationGridGif from 'assets/images/emptyTranslationGrid.gif';
import uploadNormalGridGif from 'assets/images/uploadNormalGrid.gif';
import emptyNormalGridGif from 'assets/images/emptyNormalGrid.gif';
import TemplateDefaultPNG from 'assets/images/templateDefault.png';
import FreeTrial from '../workspaces/FreeTrial';

import * as dataCollectorActions from 'dataCollector/actions';
import * as authActions from 'auth/actions';
import { fetchWorkspaceDetail, getSortCriteria } from 'workspaces/actions';
import DatabaseSelect from './DatabaseSelect';

const useStyles = makeStyles(theme => ({
    root: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(3)
    },
    noDatabase: {
        marginTop: 70
    },
    databaseContent: {
        width: '100%',
        marginTop: theme.spacing(3)
    },
    detailButton: {
        backgroundColor: '#E9EAEF',
        '& svg': {
            marginRight: SPACING_LIST_NAME_WITH_ICON
        }
    },
    header: {
        minHeight: HEADER_HEIGHT,
        marginTop: theme.spacing(3)
    },
    divider: {
        width: 1,
        height: '100%',
        background: theme.colors.border
    },
    upgrade: {
        marginRight: theme.spacing(2)
    },
    expand: {
        flex: 1
    },
    freeTrialBanner: {
        borderRadius: 8
    }
}));

function DatabasePage() {
    // const isOverLimitExceeded = useIsOverLimitExceeded();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentTutorial = useCurrentTutorial();
    const roles = useRole();

    const classes = useStyles();
    const history = useHistory();
    const { workspaceId } = useParams();
    const database = useDatabaseDetailByWorkspaceId(workspaceId);
    const type = useRightSidebarType();
    const isFetchingDatabases = useIsFetchingDatabases();
    const dbIds = database?.dbIds;
    const isFinishDashboardTutorial = useIsFinishDashboardTutorial();

    const isShowFreeTrial = useIsShowFreeTrial();
    const isProTrial = useIsProTrial();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isMemberSeatExceeded = useIsMemberSeatExceeded(0);

    function handleDrawerToggle() {
        if (Boolean(type)) {
            dispatch(appActions.setRightSidebar({ type: null }));
        } else {
            dispatch(appActions.setRightSidebar({ type: WORKSPACE_DETAILS }));
        }
    }

    const workspaceList = useWorkspaceList();

    const selectedWorkspace = useSelectedWorkspace();

    React.useEffect(() => {
        dispatch(
            addonActions.getAddonsList({
                successCallback: () => {
                    console.log('fetchSubscriptions Success');
                },
                errorCallback: () => {
                    console.log('fetchSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchCustomerAndSubscriptions({
                successCallback: () => {
                    console.log('fetchCustomerAndSubscriptions success');
                },
                errorCallback: () => {
                    console.log('fetchCustomerAndSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        let databases_page = document.getElementById(`databases_page`);
        databases_page.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
    }, []);

    React.useEffect(() => {
        dispatch(
            getSortCriteria({
                workspaceId,
                errorCallback: () => {
                    console.log('getSortCriteria failed');
                },
                successCallback: () => {
                    console.log('getSortCriteria successfully');
                }
            })
        );
    }, [dispatch, workspaceId]);

    React.useEffect(() => {
        dispatch(
            fetchWorkspaceDetail({
                workspaceId,
                successCallback: () => {
                    if (!database) {
                        dispatch(
                            getDatabases({
                                workspaceId,
                                errorCallback: () => {
                                    console.log('getDatabases failed');
                                },
                                successCallback: () => {
                                    console.log('getDatabases successfully');
                                }
                            })
                        );
                    }
                }
            })
        );
    }, [dispatch, workspaceId, database]);

    React.useEffect(() => {
        dispatch(
            dataCollectorActions.getUsage({
                successCallback: () => {
                    console.log('fetch usage Successfully');
                },
                errorCallback: () => {
                    console.log('fetch usage failed');
                }
            })
        );
    }, [dispatch]);

    const handleFinished = () => {
        dispatch(appActions.setCurrentTutorial(''));
        document.querySelector('#root').click();
        if (!isFinishDashboardTutorial) {
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.FINISH_DASHBOARD_TUTORIAL]: true
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        }
    };

    const closeFreeTrial = () => {
        dispatch(appActions.hideFreeTrial());
        dispatch(appActions.setCurrentTutorial(DASHBOARD_TUTORIAL_FROM_SIGNUP));
    };

    const gotoMemberSetting = () => history.push(`/company-settings/members`);

    return (
        <Grid
            id="databases_page"
            className={`${classes.root} scrollbar-app`}
            container
            direction="column"
            wrap="nowrap"
        >
            {/* {isOverLimitExceeded && (
                <Grid item>
                    <WarningBox
                        warningType={PAYMENT_WARNING_TYPES.ERROR}
                        message={t(`database_warning_message`, {
                            plan: currentSubscription?.price?.plan?.name || PLAN_TYPES.FREE
                        })}
                        action={
                            <ButtonBase variant="contained" width={120} onClick={handleUpgrade}>
                                <UpgradeSVG className={classes.upgrade} />
                                {t(`global_upgrade`)}
                            </ButtonBase>
                        }
                    />
                </Grid>
            )} */}
            {isFreePlan && isMemberSeatExceeded && (
                <Grid item>
                    <WarningBox
                        warningType={PAYMENT_WARNING_TYPES.ERROR}
                        message="Your previous plan has expired. You are using FREE PLAN and exceeding 3 member seats."
                        action={
                            <ButtonBase variant="contained" width={120} onClick={gotoMemberSetting}>
                                View detail
                            </ButtonBase>
                        }
                    />
                </Grid>
            )}
            {Boolean(workspaceList.length) && (
                <Grid className={classes.header} item container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item className={classes.expand}>
                        <Grid container wrap="nowrap" spacing={2} alignItems="center">
                            <Grid item>
                                {selectedWorkspace ? (
                                    <Typography variant="h3">{selectedWorkspace.name}</Typography>
                                ) : (
                                    <Skeleton width={90} height={50} />
                                )}
                            </Grid>
                            <Grid item>
                                <Tooltip title={t('show_project_details')} placement="top">
                                    <Button
                                        tr-dt="Open Workspace Detail"
                                        className={classes.detailButton}
                                        onClick={handleDrawerToggle}
                                    >
                                        <InfoIconSVG />
                                        {t('dashboard_project_detail_button')}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item container alignItems="center" spacing={1} wrap="nowrap">
                            <Grid item>
                                <DatabaseSelect workspaceId={workspaceId} />
                            </Grid>
                            {(roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE] === roleConst.FULL ||
                                roles[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] === roleConst.FULL) && (
                                <Grid item>
                                    <DatabaseAdd fetchingDisabled={isFetchingDatabases} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {isFetchingDatabases && <DatabaseListSkeleton />}
            <Grid item id="database-list-container" className={classes.databaseContent}>
                {dbIds?.length > 0 && <DatabaseList fetchingDisabled={isFetchingDatabases} />}
                {dbIds?.length === 0 && (
                    <Grid container justify="center" className={classes.noDatabase}>
                        <NoDatabase />
                    </Grid>
                )}
            </Grid>
            {currentTutorial && <Tutorial onFinish={handleFinished} />}
            <PreloadImages
                paths={[
                    uploadTranslationGridGif,
                    emptyTranslationGridGif,
                    uploadNormalGridGif,
                    emptyNormalGridGif,
                    TemplateDefaultPNG
                ]}
            />
            <Dialog open={isProTrial && isShowFreeTrial} classes={{ paper: classes.freeTrialBanner }}>
                <FreeTrial onClose={closeFreeTrial} />
            </Dialog>
        </Grid>
    );
}

export default React.memo(DatabasePage);
