import { useSelector } from 'react-redux';
import { SUBSCRIPTION_STATUS, PLAN_TYPES, PLAN_CYCLE } from 'const';

export function useCompanySubscription() {
    return useSelector(state => state.payment.companySubscription);
}

export function usePaymentMethod() {
    const companySubscription = useCompanySubscription();
    return companySubscription?.paymentMethod;
}

export function useSeatSubscription() {
    return useSelector(state => state.payment.seatSubscription);
}

export function useCurrentSubscriptionIs(plan = PLAN_TYPES.FREE) {
    const companySubscription = useCompanySubscription();

    const currentPlan = companySubscription?.plan || PLAN_TYPES.FREE;
    return currentPlan === plan;
}

export function useIsProTrial() {
    const companySubscription = useCompanySubscription();
    return (
        companySubscription?.plan === PLAN_TYPES.PROFESSIONAL &&
        companySubscription?.status === SUBSCRIPTION_STATUS.TRIALING
    );
}

export function useIsProActive() {
    const companySubscription = useCompanySubscription();
    return (
        companySubscription?.plan === PLAN_TYPES.PROFESSIONAL &&
        companySubscription?.status === SUBSCRIPTION_STATUS.ACTIVE
    );
}

export function useIsSeatExceeded() {
    const companySubscription = useCompanySubscription();
    return companySubscription?.activeSeat > companySubscription?.totalSeat;
}

export function useIsPendingFromAnnualToMonthly() {
    const companySubscription = useCompanySubscription();
    return (
        companySubscription?.plan === PLAN_TYPES.PROFESSIONAL &&
        companySubscription?.cycle === PLAN_CYCLE.year &&
        companySubscription?.nextCycle === PLAN_CYCLE.month
    );
}
