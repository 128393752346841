import React from 'react';

function MultiLinesSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Datatype/Multipleline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M0,8.88888889 L0,7.11111111 L9.77777778,7.11111111 L9.77777778,8.88888889 L0,8.88888889 Z M0,1.77777778 L16,1.77777778 L16,3.55555556 L0,3.55555556 L0,1.77777778 Z M0,12.4444444 L16,12.4444444 L16,14.2222222 L0,14.2222222 L0,12.4444444 Z"
                    id="s-copy-2"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(MultiLinesSVG);
