import React from 'react';

function TMStatusSVG({ color = '#F8AD13', ...other }) {
    return (
        <svg {...other} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5002 12.7C11.9973 12.7 12.4002 12.2971 12.4002 11.8C12.4002 11.303 11.9973 10.9 11.5002 10.9C11.0032 10.9 10.6002 11.303 10.6002 11.8C10.6002 12.2971 11.0032 12.7 11.5002 12.7Z"
                fill={color}
            />
            <path
                d="M13.4002 10.2C13.8973 10.2 14.3002 9.79708 14.3002 9.30002C14.3002 8.80297 13.8973 8.40002 13.4002 8.40002C12.9032 8.40002 12.5002 8.80297 12.5002 9.30002C12.5002 9.79708 12.9032 10.2 13.4002 10.2Z"
                fill={color}
            />
            <path
                d="M13.7002 7.3C14.1972 7.3 14.6002 6.89706 14.6002 6.4C14.6002 5.90294 14.1972 5.5 13.7002 5.5C13.2031 5.5 12.8002 5.90294 12.8002 6.4C12.8002 6.89706 13.2031 7.3 13.7002 7.3Z"
                fill={color}
            />
            <path
                d="M8.80021 11.9C8.60021 11.9 8.30021 11.9 8.30021 11.9C7.00021 12.2 5.90021 12 4.70021 11C2.80021 9.50002 2.30021 6.70002 3.60021 4.60002C5.10021 2.20002 8.30021 1.50002 10.6002 3.10002L9.50021 4.60002C9.40021 4.70002 9.50021 4.80002 9.60021 4.80002L14.3002 4.70002C14.4002 4.70002 14.4002 4.60002 14.4002 4.50002L12.9002 0.100024C12.9002 2.44156e-05 12.7002 2.44156e-05 12.7002 0.100024L11.6002 1.60002C8.40021 -0.699975 3.80021 0.300024 1.80021 4.00002C0.300211 6.80002 0.900211 10.3 3.30021 12.3C4.90021 13.7 6.90021 14.2 8.80021 13.9C8.90021 13.9 9.20021 13.8 9.30021 13.8C9.60021 13.7 9.90021 13.3 9.90021 12.9C9.80021 12.4 9.40021 12 8.80021 11.9Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TMStatusSVG);
