import React from 'react';
import { Grid } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { HEADER_HEIGHT } from 'const';
import { useHistory } from 'react-router-dom';
import ProgressLinear from 'components/notify/Import';

function ConfirmMergingBox({ workspaceId, gridId, viewId, dbId, branchId }) {
    const history = useHistory();

    const handleGoToDashboard = () => {
        history.push(`/projects/${workspaceId}`);
    };

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            className={`fixed flex justify-center items-center top-[${HEADER_HEIGHT}px] left-0 right-0 p-3.5 w-full z-[998] shadow bg-transparent`}
            style={{
                height: `calc(100vh - ${HEADER_HEIGHT}px)`
            }}
        >
            <Grid item className={`opacity-40 w-full h-full absolute z-[1] bg-white`} />
            <Grid item className={'w-full h-full absolute z-[2]'}>
                <Grid className={'h-full'} container alignItems="center" justify="center" direction="row">
                    <Grid item className={'p-8 w-[540px] bg-white shadow'}>
                        <div className="flex flex-col gap-6">
                            <h4 className="prose prose-lg font-medium">Grid is being merged</h4>
                            <p className="body2">
                                The Grid is being merged. It is temporarily locked and may take a while. You can return
                                to the Project to do other things while waiting.
                            </p>
                            <ProgressLinear title="Merging..." />
                            <div className="flex flex-row justify-end items-center">
                                <ButtonBase onClick={handleGoToDashboard} variant="contained">
                                    Back to project
                                </ButtonBase>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ConfirmMergingBox);
