import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Button from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import WarningBox from 'payment/components/warning';
import { useTranslation } from 'react-i18next';
import LDBasePortal from 'components/selects/LDBasePortal';
import { PAYMENT_WARNING_TYPES } from 'const';
import * as gridActions from './actions';
import { DUPLICATE_RECORD_OPTIONS } from 'const';
import { sendManualTrack } from 'tracker';
import { useDatabasesByWorkspaceId } from 'hooks/database';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        width: 520,

        borderRadius: 4
    },
    spacing3: {
        marginBottom: theme.spacing(3)
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: theme.spacing(2)
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    options: {
        position: 'relative',
        left: -11
    }
}));

function DuplicateAnotherGrid({ onClose, clonedGrid, dbId }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const _dbs = useDatabasesByWorkspaceId(workspaceId);

    const [selectedDb, setSelectedDb] = React.useState();

    const options = _dbs?.map(_d => {
        const label = _d?.name;
        const value = _d?.id;
        return { ..._d, label: label, value: value };
    });

    const onDuplicateAnotherGrid = () => {
        sendManualTrack({
            type: t('grid_duplicate_form_action'),
            customData: {
                dbId: selectedDb?.id,
                gridId: clonedGrid?.id
            }
        });
        dispatch(
            gridActions.duplicateGrid({
                name: t('grid_duplicate_form_name_default', { name: clonedGrid.name }),
                destinationDbId: selectedDb?.id,
                clonedGrid,
                duplicateRecordOption: DUPLICATE_RECORD_OPTIONS.INCLUDE,
                dbId,
                successCallback: () => {
                    onClose && onClose();
                },
                errorCallback: () => {}
            })
        );
    };

    return (
        <>
            <DialogTitle
                title={<Typography variant="h4">{t(`grid_duplicate_another_title`)}</Typography>}
                onClose={onClose}
            />
            <DialogContent>
                <Grid container direction="column" wrap="nowrap" className={classes.root} spacing={3}>
                    <Grid item>
                        <Typography variant="h6">{t(`grid_duplicate_another_lable`)}</Typography>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            ddPlaceholder={t('select_a_database')}
                            menuPlaceholder={`Database name`}
                            options={options || []}
                            onChange={option => {
                                setSelectedDb(option);
                            }}
                            defaultValue={selectedDb}
                            isMulti={false}
                        />
                    </Grid>
                    <Grid item>
                        <WarningBox
                            warningType={PAYMENT_WARNING_TYPES.INFO}
                            content={
                                <Typography variant="p">
                                    Only <strong>column data</strong> and <strong>permission</strong> will be duplicated
                                    along
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Button onClick={onClose} width={150} variant="outlined">
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <div onClick={onDuplicateAnotherGrid} className={classes.btnSubmit}>
                            <Button width={150} disabled={!selectedDb} type="submit" variant="contained">
                                {t(`global_duplicate`)}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(DuplicateAnotherGrid);
