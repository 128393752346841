import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from 'assets/images/svg/CloseIconSVG';
import Overview from './Overview';
import { useDispatch } from 'react-redux';
import * as appActions from 'app/actions';
import { useJiraCreateTypeOptions } from 'hooks/app';
import ConnectorSVG from 'assets/images/svg/ConnectorSVG';
import AddonSVG from 'assets/images/svg/AddonSVG';
import ConnectorDetail from './connector';
import { useTranslation } from 'react-i18next';
import AutomationActionSVG from 'assets/images/svg/addon/AutomationActionSVG';
import { useNormalAddons } from 'hooks/addons';
import AutomationAddons from 'addons/automation';
import InAppAddon from './inapp';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        height: 600,
        overflow: 'hidden',
        width: 1200
    },
    header: {
        borderBottom: `1px solid ${theme.colors.border}`,
        height: 72,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    contentWrapper: {
        flex: 1,
        overflow: 'hidden'
    },
    dlFlex: {
        display: 'flex'
    },
    backWrapper: {
        cursor: 'pointer'
    },
    sidebar: {
        width: 280,
        background: theme.colors.ghostwhite,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(5)
    },
    content: {
        flex: 1
    },
    item: {
        padding: theme.spacing(2, 3),
        marginBottom: theme.spacing(2),
        borderRadius: 8,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    selected: {
        background: theme.palette.primary.main
    }
}));

const STATES = {
    LIST: 'LIST',
    DETAIL: 'DETAIL',
    CONNECTOR: 'CONNECTOR'
};

const TYPES = {
    ADD_ON: 'ADD_ON',
    CONNECTORS: 'CONNECTORS',
    AUTOMATIONS: 'AUTOMATIONS'
};

function Addon({ onClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [type, setType] = React.useState(TYPES.ADD_ON);
    const [currentState, setCurrentState] = React.useState(STATES.LIST);
    const [selectedAddonName, setSelectedAddonName] = React.useState('');
    const jiraCreateTypeOptions = useJiraCreateTypeOptions();
    const normalAddons = useNormalAddons();
    const selectedAddon = normalAddons.find(addon => addon.name === selectedAddonName);

    const handleBackToAddonList = () => {
        setType(TYPES.ADD_ON);
        setCurrentState(STATES.LIST);
    };

    const handleAddonChange = React.useCallback(addon => {
        setSelectedAddonName(addon.name);
        setCurrentState(STATES.DETAIL);
    }, []);

    const handleAddonRequest = React.useCallback(() => {
        onClose();
        dispatch(appActions.setIsOpenFeedback(true));
        dispatch(appActions.setFeedbackOption(jiraCreateTypeOptions?.[2]));
    }, [onClose, dispatch, jiraCreateTypeOptions]);

    const content = React.useMemo(() => {
        if (type === TYPES.AUTOMATIONS) {
            return <AutomationAddons onClose={onClose} onAddonRequest={handleAddonRequest} />;
        }
        switch (currentState) {
            case STATES.LIST:
                return (
                    <Overview
                        onAddonClick={handleAddonChange}
                        onAddonRequest={handleAddonRequest}
                        addons={normalAddons}
                    />
                );
            case STATES.DETAIL:
                return <InAppAddon onBack={handleBackToAddonList} addon={selectedAddon} onClose={onClose} />;
            case STATES.CONNECTOR:
                return <ConnectorDetail onClose={onClose} />;
            default:
                return null;
        }
    }, [currentState, selectedAddon, handleAddonRequest, handleAddonChange, onClose, normalAddons, type]);

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item>
                <Grid justify="space-between" wrap="nowrap" alignItems="center" container className={classes.header}>
                    <Grid item>
                        <Typography variant="h3" className={classes.envLabel}>
                            {t('global_integrations')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose} className={classes.closeButton}>
                            <CloseIcon size={20} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.contentWrapper} wrap="nowrap">
                <Grid className={classes.sidebar} item container direction="column">
                    <Grid
                        className={`${classes.item} ${type === TYPES.ADD_ON ? classes.selected : ``}`}
                        item
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        onClick={() => {
                            setType(TYPES.ADD_ON);
                            setCurrentState(STATES.LIST);
                        }}
                    >
                        <Grid item className={classes.dlFlex}>
                            <AddonSVG
                                height={24}
                                width={24}
                                color={type === TYPES.ADD_ON ? theme.colors.white : theme.colors.black}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{ color: type === TYPES.ADD_ON ? theme.colors.white : `` }}
                                variant={type === TYPES.ADD_ON ? 'body1' : 'body2'}
                            >
                                {t('addon_title')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!isExternal && (
                        <Grid item>
                            <Grid
                                className={`${classes.item} ${type === TYPES.CONNECTORS ? classes.selected : ``}`}
                                container
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                onClick={() => {
                                    setType(TYPES.CONNECTORS);
                                    setCurrentState(STATES.CONNECTOR);
                                }}
                            >
                                <Grid item className={classes.dlFlex}>
                                    <ConnectorSVG
                                        height={24}
                                        width={24}
                                        color={type === TYPES.CONNECTORS ? theme.colors.white : theme.colors.black}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        style={{ color: type === TYPES.CONNECTORS ? theme.colors.white : `` }}
                                        variant={type === TYPES.CONNECTORS ? 'body1' : 'body2'}
                                    >
                                        {t('gridly_connector')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid
                            id="addon-automation-actions"
                            className={`${classes.item} ${type === TYPES.AUTOMATIONS ? classes.selected : ``}`}
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            onClick={() => {
                                setType(TYPES.AUTOMATIONS);
                                setCurrentState(STATES.LIST);
                            }}
                        >
                            <Grid item className={classes.dlFlex}>
                                <AutomationActionSVG
                                    height={24}
                                    width={24}
                                    color={type === TYPES.AUTOMATIONS ? theme.colors.white : theme.colors.black}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{ color: type === TYPES.AUTOMATIONS ? theme.colors.white : `` }}
                                    variant={type === TYPES.AUTOMATIONS ? 'body1' : 'body2'}
                                >
                                    {t('automation_actions')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.content}>
                    {content}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(Addon);
