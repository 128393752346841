import React from 'react';

function ExportSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/Export">
                <g id="Toolbar/Dependency">
                    <path
                        id="Shape"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.32098 0.506048C7.40614 0.211045 7.67839 0 8.00038 0C8.39407 0 8.70781 0.313745 8.70781 0.707429L8.70666 8.53306L11.3858 5.97688L11.4702 5.90375L11.5676 5.84216C11.8358 5.70144 12.1654 5.74859 12.3936 5.97684C12.6785 6.26174 12.6785 6.69969 12.3936 6.98459L8.50464 10.8533L8.41503 10.9301L8.324 10.9855C8.22869 11.0331 8.12061 11.0573 8.00038 11.0573C7.7999 11.0573 7.63285 10.9901 7.49651 10.8537L3.64803 6.98497L3.57476 6.90048L3.51311 6.80305C3.37227 6.53474 3.41938 6.20511 3.64765 5.97684C3.93256 5.69193 4.3705 5.69193 4.65541 5.97684L7.20663 8.52842L7.29296 0.70744L7.29971 0.607317L7.32098 0.506048ZM14.6132 11.6346C14.6983 11.3396 14.9706 11.1286 15.2926 11.1286C15.6863 11.1286 16 11.4423 16 11.836V14.4694L15.9932 14.5695L15.972 14.6708C15.8868 14.9658 15.6146 15.1768 15.2926 15.1768H0.707429L0.607311 15.1701L0.506043 15.1488C0.211044 15.0637 0 14.7914 0 14.4694V11.836L0.00675009 11.7359L0.0280201 11.6346C0.113183 11.3396 0.385437 11.1286 0.707429 11.1286C1.10111 11.1286 1.41486 11.4423 1.41486 11.836V13.5578L1.58342 13.7473L14.381 13.762L14.5704 13.5934L14.5851 11.836L14.5919 11.7359L14.6132 11.6346Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(ExportSVG);
