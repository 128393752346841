import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import CellBaseDropdown from 'components/selects/CellBase';
import { Grid, Popper, Paper } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import * as gridActions from 'gridUI/actions';
import { SINGLE_SELECTION } from 'const/columnTypes';
import { INPUT_QUICK_FILTER_HEIGHT } from 'const/style';
import { MAX_OPTIONS } from 'const/gridUI';
import { OPERATOR } from 'gridUI/conditions';
import classNames from 'classnames';
import Option from 'components/option/Base';
import { getOptionColor } from 'utils/color';

function SingleSelectionQuickFilter({ column, columnId, isSelectionActive, quickFilters, isDisabled, t, ...rest }) {
    const selectedOption = useMemo(() => {
        return quickFilters?.[columnId]?.value || null;
    }, [quickFilters, columnId]);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const singleSelectOptions = useMemo(() => {
        return isEmpty(column.options)
            ? []
            : column.options.map(option => {
                  return {
                      value: option.label,
                      label: option.label
                  };
              });
    }, [column.options]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleOptionChange = option => {
        if ((option && option.value) === (selectedOption && selectedOption.value)) {
            callServer(null);
            setAnchorEl(null);
            return;
        }
        setAnchorEl(null);
        callServer(option);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const callServer = option => {
        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: option,
                type: SINGLE_SELECTION,
                operator: OPERATOR.contains
            })
        );
        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    const handleDelete = () => {
        callServer(null);
    };

    return (
        <Grid>
            <div
                onClick={handleClick}
                className={classNames(`body2 bg-grey-pale border overflow-hidden px-3.5 flex items-center rounded`, {
                    disabled: isDisabled,
                    'border border-text-primary': anchorEl
                })}
                style={{
                    height: INPUT_QUICK_FILTER_HEIGHT
                }}
            >
                {!selectedOption && (
                    <span className="text-grey-mid body2 flex-1 line-clamp-1">
                        {t(`global_label_select_an_option`)}
                    </span>
                )}
                {selectedOption && (
                    <Option
                        size="small"
                        onDelete={handleDelete}
                        label={selectedOption.label}
                        color={getOptionColor({ ...column, data: selectedOption?.value })}
                    />
                )}
            </div>
            <Popper className={`z-[1500]`} id={id} open={open} anchorEl={anchorEl} transition>
                <Paper className={'min-w-[200px] bg-white shadow'}>
                    <CellBaseDropdown
                        options={singleSelectOptions}
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        maxSize={MAX_OPTIONS}
                        defaultValue={selectedOption}
                        onBlur={handleClickAway}
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(SingleSelectionQuickFilter);
