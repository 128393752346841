import React from 'react';
import Func from './Func';
import Tag from './Tag';
import Mention from './Mention';
import Payload from './Payload';
import ParagraphSVG from 'assets/images/svg/ParagraphSVG';
import { DESERIALIZE_TYPES } from '../const';

const Element = props => {
    const { attributes, children, element, isHighlightSymbolCharacter } = props;
    switch (element.type) {
        case 'tag':
            return <Tag {...props} />;
        case 'func':
            return <Func {...props} />;
        case 'mention':
            return <Mention {...props} />;
        case DESERIALIZE_TYPES.PAYLOAD:
            return <Payload {...props} />;
        default:
            // eslint-disable-next-line no-case-declarations
            const isParagraphEmpty = element?.children?.length === 1 && !element?.children?.[0]?.text;
            return (
                <p
                    {...attributes}
                    style={{
                        ...(isHighlightSymbolCharacter && isParagraphEmpty ? { position: 'relative' } : {})
                    }}
                >
                    {children}
                    {isHighlightSymbolCharacter && (
                        <span
                            style={{
                                padding: `0 2px`,
                                background: '#f8ad14',
                                ...(isParagraphEmpty && { position: 'absolute', left: 1, top: 2.75, height: 16.5 })
                            }}
                        >
                            <ParagraphSVG className="single-revert-style" />
                        </span>
                    )}
                </p>
            );
    }
};

export default Element;
