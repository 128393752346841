import * as types from '../types';
import * as optimisticActions from './optimistic';
import uuidv1 from 'uuid/v1';
import { enqueueSnackbar } from 'notifier/actions';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
// import chunk from 'lodash/chunk';
import { setViewRecords, pasteViewRecordsOnColumnHeaderApi, copyViewRecordsOnColumnHeaderApi } from 'services/view';
import {
    contentBeforeConfirm,
    copyToClipboard,
    getClipboardContent,
    setContentBeforeConfirm,
    setCopyPaseEvt
} from 'utils/clipboard';
import { MaxNumberInAr, removeArrayInArray, parseJson, isLDEmpty } from 'utils/object';
import * as statusActions from './status';
import * as columnUtils from 'utils/gridUI/column';
import * as columnActions from './column';
import * as tmActions from './tm';
import { getCellData } from 'utils/gridUI/cell';
import * as columnTypes from 'const/columnTypes';
import {
    DATA_QUERY_OPTIONS,
    MAX_RECORD_LIMIT,
    MAX_SELECTION_RECORDS,
    RANGE_TYPES,
    SOURCE_STATUS,
    SPECIAL_SPLIT_KEY
} from 'const/gridUI';
import * as rowActions from './row';
import { getDisabledColumnIds, getDisableColumnIdsByType } from 'utils/gridUI/column';
import { SYSTEM_COLUMN_IDS, SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG } from 'const';
import { formatDateTimeLocal, getCorrectColumnType } from 'utils/gridUI/formatData';
import { isTempId } from 'utils/uuid';
import * as dataActions from './data';
import { combinedData } from 'utils/gridUI/data';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { retrieveImageFromClipboardAsBlob } from 'utils/fileUtils';
import * as gridActions from '.';
import { chunk } from 'lodash';
import * as cellCopiedActions from './cellCopied';
import { isSelectingRange } from 'utils/gridUI/range';
import { generateDefaultName } from 'utils/name';

const COPY_PASTE_SNACKBAR_DURATION = 1000;

export function cannotPasteWarning() {
    return async function(dispatch, getState) {
        dispatch(
            enqueueSnackbar({
                message: "You don't have permission to do this action",
                type: 'info'
            })
        );
    };
}

export function copy(e) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = gridUI;

        const { recordIds, data, columnIds, isOverRecordLimit, totalSelectedRecords } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length && !columnIds?.length) return;

        if (columnIds?.length && !recordIds?.length) {
            e.stopPropagation();
            e.preventDefault();
            dispatch(statusActions.removeDoingAction({ actionId }));
            return dispatch(copyColumnsServer());
        }

        const { metaData } = gridUI;
        const clipboardValue = [];
        const internalValue = [];
        let cellCount = 0;

        for (const recordId of recordIds) {
            const recordData = [];
            const recordInternalData = [];

            for (const columnId of columnIds) {
                const column = metaData?.[columnId];
                const columnType = getCorrectColumnType(column);
                const cellData = getCellData({ data, rowId: recordId, columnId });
                const cellValue = cellData?.value;
                const _color = cellData?._color;

                cellCount += 1;
                const copyValue = convertCopyValue({ columnType, cellValue, column });
                const copyInternalValue = convertCopyInternalValue({ columnType, cellValue, columnId, column, _color });
                recordData.push(copyValue);
                recordInternalData.push(copyInternalValue);
            }

            internalValue.push(recordInternalData);
            clipboardValue.push(recordData);
        }

        if (!cellCount) return;
        e.stopPropagation();
        e.preventDefault();
        copyToClipboard({ copyValue: clipboardValue, internalValue });
        dispatch(statusActions.removeDoingAction({ actionId }));
        dispatch(
            enqueueSnackbar({
                message: `${cellCount} ${cellCount > 1 ? 'cells' : 'cell'} copied`,
                type: 'info',
                duration: COPY_PASTE_SNACKBAR_DURATION
            })
        );

        dispatch(
            cellCopiedActions.setCopiedRange({
                rowStartIndex,
                rowStopIndex,
                columnStartIndex,
                columnStopIndex
            })
        );
    };
}

export function paste(props) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();

        let { event, onOpenConfirm, onOpenExtendConfirm, content, accessManageGridRecord, accessManageGridColumn } =
            props || {};

        const {
            metaData,
            viewColumns,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            dependencies,
            defaultAccessViewId,
            dbId,
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex,
            data: dataStore,
            totalRecords
            // rowIndexMap
        } = gridUI;

        let blobs = [];
        const actionId = uuidv1();

        try {
            if (!content) {
                content = await getClipboardContent();
            }

            if ((content?.length === 1 && content?.[0]?.length === 0) || !content) {
                dispatch(
                    enqueueSnackbar({
                        message: `Nothing to paste`,
                        type: 'info'
                    })
                );
                return dispatch(statusActions.removeDoingAction({ actionId }));
            }

            const {
                recordIds: selectedRecordIds,
                columnIds: selectedColumnIds,
                isOverRecordLimit,
                totalSelectedRecords
            } = await dataActions.getRangeData({
                gridUI,
                auth,
                dataOptions: [DATA_QUERY_OPTIONS.DATA],
                type: RANGE_TYPES.INDEX
            });

            if (isOverRecordLimit) {
                dispatch(
                    enqueueSnackbar({
                        type: 'info',
                        message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                    })
                );
                dispatch(statusActions.removeDoingAction({ actionId }));
                return;
            }

            dispatch(statusActions.registerDoingAction({ actionId }));

            const isRangeSelected = isSelectingRange({
                rowStartIndex,
                rowStopIndex,
                columnStartIndex,
                columnStopIndex
            });

            if (event && selectedRecordIds?.length === 1 && selectedColumnIds?.length === 1) {
                const rowId = selectedRecordIds?.[0];
                const columnId = selectedColumnIds?.[0];

                const column = metaData?.[columnId];

                if (column?.type === columnTypes.FILES) {
                    try {
                        blobs = await retrieveImageFromClipboardAsBlob(event);
                        if (!isEmpty(blobs)) {
                            event.stopPropagation();
                            event.preventDefault();

                            dispatch(
                                gridActions.uploadMultipleFilesForCell({
                                    rowId,
                                    columnId,
                                    uploadFiles: blobs,
                                    successCallback: () => {
                                        dispatch(gridActions.cancelCellEdit());
                                        dispatch(
                                            enqueueSnackbar({
                                                message: `Pasted`,
                                                type: 'info',
                                                duration: COPY_PASTE_SNACKBAR_DURATION
                                            })
                                        );
                                        return dispatch(statusActions.removeDoingAction({ actionId }));
                                    },
                                    errorCallback: () => {
                                        return dispatch(statusActions.removeDoingAction({ actionId }));
                                    }
                                })
                            );
                            return;
                        }
                    } catch (error) {
                        console.log('retrieveImageFromClipboardAsBlob catch', error.message);
                        dispatch(statusActions.removeDoingAction({ actionId }));
                    }
                }
            }

            const rowLength = content?.length;
            const columnLength = content?.[0]?.length || 0;

            const { recordIds, data, columnIds } = await dataActions.getRangeDataPaste({
                gridUI,
                auth,
                type: RANGE_TYPES.INDEX,
                contentLength: rowLength,
                dataOptions: [DATA_QUERY_OPTIONS.DATA]
            });

            const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });
            const referenceDisabledColumns = columnUtils.getReferenceDisabledColumns({ gridUI, auth });

            const disabledCols = getDisabledColumnIds({
                disabledColumns,
                processingColumns,
                disabledSourceColumns,
                viewColumns,
                disabledColumnIdsByType,
                referenceDisabledColumns
            });

            if (columnIds?.length && !recordIds?.length) {
                const _columnId = columnIds?.[0];

                if (SYSTEM_COLUMN_IDS?.includes(_columnId)) {
                    dispatch(
                        enqueueSnackbar({
                            message: `You are not allowed to paste into ${_columnId} column`,
                            type: 'info',
                            duration: COPY_PASTE_SNACKBAR_DURATION
                        })
                    );
                    dispatch(statusActions.removeDoingAction({ actionId }));
                    return;
                }

                if (disabledCols?.includes(_columnId)) {
                    dispatch(
                        enqueueSnackbar({
                            message: `You are not allowed to paste into disabled column`,
                            type: 'info',
                            duration: COPY_PASTE_SNACKBAR_DURATION
                        })
                    );
                    dispatch(statusActions.removeDoingAction({ actionId }));
                    return;
                }

                dispatch(statusActions.removeDoingAction({ actionId }));
                setContentBeforeConfirm(content);
                onOpenConfirm && onOpenConfirm();
                return;
            }

            if (!recordIds?.length && !columnIds?.length) {
                dispatch(statusActions.removeDoingAction({ actionId }));
                return;
            }

            const recordId = recordIds?.[0];
            const columnId = columnIds?.[0];

            if (!recordId || !columnId) {
                dispatch(statusActions.removeDoingAction({ actionId }));
                return;
            }

            const viewColumnIds = columnUtils.getExactColumns(viewColumns);

            const {
                updatedPastedServerValue,
                updatedPastedLocalValue,
                oldValuesWasPasted,
                refColumns,
                affectedRowIds,
                affectedColumnIds,
                transUnits,
                pastedColumnIds
            } = detectColumnCanPaste({
                content,
                recordId,
                columnId,
                columnIds: viewColumnIds,
                recordIds,
                data,
                metaData,
                dependencies,
                disabledCols,
                isRangeSelected,
                limitRecordIds: selectedRecordIds,
                limitColumnIds: selectedColumnIds
            });

            if (onOpenExtendConfirm) {
                const _rowStartIndex = Math.min(rowStartIndex, rowStopIndex);
                const _columnStartIndex = Math.min(columnStartIndex, columnStopIndex);
                const extendRows = Math.max(0, _rowStartIndex + rowLength - totalRecords);
                const extendColumns = Math.max(0, _columnStartIndex + columnLength - viewColumnIds.length);
                if (
                    onOpenExtendConfirm &&
                    ((accessManageGridRecord && extendRows > 0) || (accessManageGridColumn && extendColumns > 0))
                ) {
                    dispatch(statusActions.removeDoingAction({ actionId }));
                    setContentBeforeConfirm(content);
                    const affectedCellValue = affectedRowIds.reduce((acc, rowId) => {
                        acc[rowId] = affectedColumnIds.map(
                            columnId => getCellData({ data: dataStore, rowId, columnId })?.value
                        );
                        return acc;
                    }, {});
                    let overwriteCells = 0;
                    Object.values(affectedCellValue).forEach(rows => {
                        overwriteCells += rows.filter(el => !!el).length;
                    });
                    onOpenExtendConfirm({
                        data,
                        oldValuesWasPasted,
                        updatedPastedLocalValue,
                        updatedPastedServerValue,
                        transUnits,
                        refColumns,
                        expectedRowLength: rowLength,
                        expectedColumnLength: columnLength,
                        affectedRowIds,
                        affectedColumnIds,
                        overwriteCells,
                        extendColumns,
                        extendRows,
                        pastedColumnIds,
                        disabledCols
                    });
                    return;
                }
            }

            dispatch(
                optimisticActions.commitAction({
                    actionId,
                    type: types.OPTIMISTIC_PASTE,
                    body: {
                        oldValuesWasPasted
                    }
                })
            );

            const newData = combinedData({ data, newData: updatedPastedLocalValue });
            dispatch(dataActions.updateData({ newData }));

            console.log('transUnits', transUnits);

            if (transUnits?.length) {
                dispatch(
                    tmActions.updateBulkTm({
                        transUnits,
                        successCallback: () => {
                            console.log('success');
                        },
                        errorCallback: () => {
                            console.log('error ');
                        }
                    })
                );
            }

            const updateChunks = chunk(updatedPastedServerValue?.records, MAX_RECORD_LIMIT);

            console.log('updateChunks', updateChunks);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            ...updatedPastedServerValue,
                            records
                        }
                    });
                })
            );

            dispatch(
                enqueueSnackbar({
                    message: `Pasted`,
                    type: 'info',
                    duration: COPY_PASTE_SNACKBAR_DURATION
                })
            );

            const isSettingReference = refColumns.length > 0;
            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: refColumns,
                        recordIds: affectedRowIds
                    })
                );
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
            event.stopPropagation();
            event.preventDefault();

            // if (!isRangeSelected) {
            //     const newRowStopIndex = rowStartIndex + affectedRowIds?.length - 1;
            //     const newColumnStopIndex =
            //         columnStartIndex + (updatedPastedServerValue?.columns || []).slice(1)?.length - 1;

            //     const newRowIndexMap = {
            //         ...rowIndexMap,
            //         [newColumnStopIndex]: affectedRowIds?.[affectedRowIds?.length - 1]
            //     };

            //     dispatch(
            //         cellActions._selectRangeCell({
            //             rowStartIndex,
            //             rowStopIndex: newRowStopIndex,
            //             columnStartIndex,
            //             columnStopIndex: newColumnStopIndex
            //         })
            //     );

            //     dispatch(
            //         rangeActions.setRowIndexMap({
            //             rowIndexMap: newRowIndexMap
            //         })
            //     );
            // }

            dispatch(
                cellCopiedActions.setCopiedRange({
                    rowStartIndex: -1,
                    rowStopIndex: -1,
                    columnStartIndex: -1,
                    columnStopIndex: -1
                })
            );
            setCopyPaseEvt();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function pasteExtendRecordAndColumn({
    extendPasteData,
    accessManageGridColumn,
    accessManageGridRecord,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        try {
            const { gridUI } = getState();
            const { defaultAccessViewId, dbId, quickFilters, quickSorts, columns, metaData } = gridUI;
            const {
                data,
                oldValuesWasPasted,
                updatedPastedLocalValue,
                transUnits,
                refColumns,
                affectedRowIds,
                affectedColumnIds,
                extendColumns,
                extendRows,
                pastedColumnIds,
                disabledCols
            } = extendPasteData;
            dispatch(statusActions.registerDoingAction({ actionId }));
            const cloneContentBeforeConfirm = JSON.parse(JSON.stringify(contentBeforeConfirm));
            const content = accessManageGridRecord
                ? cloneContentBeforeConfirm
                : cloneContentBeforeConfirm.slice(0, cloneContentBeforeConfirm.length - extendRows);
            setContentBeforeConfirm();
            let numberSlice = 0;
            pastedColumnIds.forEach((colId, colIdx) => {
                if (disabledCols.includes(colId)) {
                    content.forEach(row => {
                        row.splice(colIdx - numberSlice, 1);
                    });
                    numberSlice++;
                }
            });

            dispatch(
                optimisticActions.commitAction({
                    actionId,
                    type: types.OPTIMISTIC_PASTE,
                    body: {
                        oldValuesWasPasted
                    }
                })
            );

            const newData = combinedData({ data, newData: updatedPastedLocalValue });
            dispatch(dataActions.updateData({ newData }));

            if (transUnits?.length) {
                dispatch(
                    tmActions.updateBulkTm({
                        transUnits,
                        successCallback: () => {
                            console.log('success');
                        },
                        errorCallback: () => {
                            console.log('error ');
                        }
                    })
                );
            }

            const cellNumberToSendToServer = affectedColumnIds.length + Math.max(0, extendColumns);

            const records = content.map(row => {
                return row.reduce((acc, r, rIdx) => {
                    if (rIdx < cellNumberToSendToServer) {
                        acc.push(r);
                    }
                    return acc;
                }, []);
            });

            const recordsServer = records.map(record => {
                return record.map((recordValue, colIdx) => {
                    const columnId = affectedColumnIds?.[colIdx];
                    const column = metaData?.[columnId];
                    const columnType = getCorrectColumnType(column) || columnTypes.MULTIPLE_LINES;
                    const { serverValue } = formatPastingValueByType({
                        columnType,
                        value: recordValue,
                        columnId
                    });
                    return serverValue;
                });
            });

            let body = {
                startRecordId: affectedRowIds[0],
                columnIds: affectedColumnIds,
                records: recordsServer
            };

            const quickFiltersFormatted = formatQuickFilters(quickFilters);
            if (!isEmpty(quickFiltersFormatted)) {
                body.filterFields = JSON.stringify(quickFiltersFormatted);
            }
            if (!isEmpty(quickSorts)) {
                body.sortFields = JSON.stringify(quickSorts);
            }

            if (accessManageGridColumn && extendColumns > 0) {
                const columnsWithData = columns?.map(columnId => metaData?.[columnId]);
                let alrPickedName = [];
                body.createGridColumns = Array(extendColumns)
                    .fill({})
                    .map(el => {
                        const name = generateDefaultName({
                            list: [...columnsWithData, ...alrPickedName],
                            property: 'name',
                            prefixName: 'Column'
                        });
                        alrPickedName.push({ name });
                        return {
                            type: columnTypes.MULTIPLE_LINES,
                            name
                        };
                    });
            }

            await pasteViewRecordsOnColumnHeaderApi({
                dbId,
                viewId: defaultAccessViewId,
                body
            });

            dispatch(
                enqueueSnackbar({
                    message: `Pasted`,
                    type: 'info',
                    duration: COPY_PASTE_SNACKBAR_DURATION
                })
            );

            const isSettingReference = refColumns.length > 0;
            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: refColumns,
                        recordIds: affectedRowIds
                    })
                );
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));

            dispatch(
                cellCopiedActions.setCopiedRange({
                    rowStartIndex: -1,
                    rowStopIndex: -1,
                    columnStartIndex: -1,
                    columnStopIndex: -1
                })
            );
            setCopyPaseEvt();
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

function convertRefColumnType({ referencedColumnType, cellValue }) {
    switch (referencedColumnType) {
        case columnTypes.FILES: {
            return cellValue
                ?.map(item => {
                    const parsedItems = parseJson(item?.referencedDataItem);
                    if (parsedItems instanceof Array) {
                        return parsedItems?.map(fileMeta => fileMeta?.originalName)?.join(SPECIAL_SPLIT_KEY);
                    }
                    return item?.referencedDataItem;
                })
                ?.join(SPECIAL_SPLIT_KEY);
        }

        default:
            return cellValue
                ?.map(item => {
                    const parsedItems = parseJson(item?.referencedDataItem);
                    if (parsedItems instanceof Array) {
                        return parsedItems?.join(SPECIAL_SPLIT_KEY);
                    }
                    return item?.referencedDataItem;
                })
                ?.join(SPECIAL_SPLIT_KEY);
    }
}

function convertCopyValue({ columnType, cellValue, column }) {
    if (cellValue instanceof Array) {
        switch (columnType) {
            case columnTypes.REFERENCE:
                return convertRefColumnType({ referencedColumnType: column?.referencedColumnType, cellValue });

            case columnTypes.FILES:
                return cellValue?.map(fileMeta => fileMeta?.originalName)?.join(SPECIAL_SPLIT_KEY);

            default:
                return cellValue.join(SPECIAL_SPLIT_KEY);
        }
    }

    if (columnType === columnTypes.DATETIME && cellValue) {
        const [date, time] = formatDateTimeLocal(cellValue);
        return `${date} ${time}`;
    }

    return cellValue;
}

function convertCopyInternalValue({ columnType, cellValue, columnId, column, _color = '' }) {
    let convertValue = cellValue;
    if (cellValue instanceof Array) {
        switch (columnType) {
            case columnTypes.REFERENCE:
                return JSON.stringify({
                    columnId,
                    value: convertValue,
                    type: columnType,
                    _color,
                    referencedColumnType: column?.referencedColumnType,
                    _isInternal: true
                });
            case columnTypes.FILES:
                return JSON.stringify({
                    columnId,
                    type: columnType,
                    value: convertValue,
                    _color,
                    _isInternal: true
                });

            default:
                return JSON.stringify({
                    _color,
                    type: columnType,
                    value: convertValue.join(SPECIAL_SPLIT_KEY),
                    _isInternal: true
                });
        }
    }

    if (columnType === columnTypes.DATETIME && cellValue) {
        const [date, time] = formatDateTimeLocal(cellValue);
        return JSON.stringify({
            _color,
            value: `${date} ${time}`,
            type: columnType,
            _isInternal: true
        });
    }

    return JSON.stringify({
        _color,
        value: convertValue,
        type: columnType,
        _isInternal: true
    });
}

export function copyColumnsServer() {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        const { gridUI } = getState();
        const { columnsSelected, dbId, currentView, totalRecords, quickFilters, quickSorts, metaData } = gridUI;

        if (!totalRecords) return;

        try {
            const { recordIds = [], columnIds = [], data } = await copyViewRecordsOnColumnHeaderApi({
                dbId,
                viewId: currentView?.id,
                columnIds: columnsSelected,
                queryParams: {
                    query: formatQuickFilters(quickFilters),
                    sort: quickSorts
                }
            });

            const clipboardValue = [];
            const internalValue = [];

            for (const recordId of recordIds) {
                const recordData = [];
                const recordInternalData = [];

                for (const columnId of columnIds) {
                    const column = metaData?.[columnId];
                    const columnType = getCorrectColumnType(column);
                    const cellData = getCellData({ data, rowId: recordId, columnId });
                    const cellValue = cellData?.value;
                    const _color = cellData?._color;
                    const copyValue = convertCopyValue({ columnType, cellValue, column });

                    const copyInternalValue = convertCopyInternalValue({
                        columnType,
                        cellValue,
                        columnId,
                        column,
                        _color
                    });

                    recordData.push(copyValue);
                    recordInternalData.push(copyInternalValue);
                }
                clipboardValue.push(recordData);
                internalValue.push(recordInternalData);
            }

            copyToClipboard({ copyValue: clipboardValue, internalValue });
            dispatch(
                enqueueSnackbar({
                    message: `${columnsSelected?.length * totalRecords} cells copied`,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );

            dispatch(statusActions.removeDoingAction({ actionId }));
        }
    };
}

function getColumnsCanPaste({ content, columnId, columnIds, disabledCols, limitColumnIds = [] }) {
    const columnLength = limitColumnIds?.length
        ? limitColumnIds?.length
        : MaxNumberInAr(content.map(row => row.length));
    let columnIndex = columnIds.findIndex(id => columnId === id);
    let columnsPasted = columnIds.slice(columnIndex, columnIndex + columnLength);
    let remainColumnIdsCanPaste = removeArrayInArray(columnsPasted, [
        ...disabledCols,
        ...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG.filter(columnId => columnId !== columnTypes.RECORD_ID)
    ]);
    const remainColumnIndexes = remainColumnIdsCanPaste.map(remainColumnId =>
        columnsPasted.findIndex(columnIdWasPasted => columnIdWasPasted === remainColumnId)
    );

    return {
        remainColumnIndexes,
        remainColumnIdsCanPaste,
        columnsPasted
    };
}

function formatPastingValueByType({ columnType, value, options, columnId, currentCellData }) {
    switch (columnType) {
        case columnTypes.JSON_LD: {
            const [serverValue, storeValue] = columnUtils.convertJSONValue({ value, currentCellData });

            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.HTML: {
            const [serverValue, storeValue] = columnUtils.convertHtmlValue({ value, currentCellData });

            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.YAML: {
            const [serverValue, storeValue] = columnUtils.convertYAMLValue({ value, currentCellData });

            return {
                serverValue: serverValue?.toString(),
                storeValue: storeValue?.toString()
            };
        }

        case columnTypes.BOOLEAN: {
            const [serverValue, storeValue] = columnUtils.convertBooleanValue({ value, currentCellData });
            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.DATETIME:
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME: {
            const [serverValue, storeValue] = columnUtils.convertDateTimeValue({ value, currentCellData });

            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.FILES: {
            const [serverValue, storeValue] = columnUtils.convertFilesValue({ value, columnId, currentCellData });
            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.MARKDOWN:
        case columnTypes.RICH_TEXT:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.TRANSLATION:
        case columnTypes.RECORD_ID:
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
        case columnTypes.SINGLE_LINE: {
            const [serverValue, storeValue] = columnUtils.convertTextValue({ value, currentCellData });
            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.SINGLE_SELECTION: {
            const [serverValue, storeValue] = columnUtils.convertSingleSelectionValue({ value, currentCellData });
            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.MULTIPLE_SELECTIONS: {
            const [serverValue, storeValue] = columnUtils.convertMultiSelectionsValue({ value, currentCellData });
            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.NUMBER: {
            const [serverValue, storeValue] = columnUtils.convertNumberValue({ value, currentCellData });

            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.REFERENCE: {
            const [serverValue, storeValue] = columnUtils.convertReferenceValue({ value, columnId, currentCellData });

            return {
                serverValue,
                storeValue
            };
        }

        case columnTypes.PATH_TAG: {
            const [serverValue, storeValue] = columnUtils.convertPathTagValue({ value, columnId, currentCellData });

            return {
                serverValue,
                storeValue
            };
        }

        default:
            return {
                serverValue: value,
                storeValue: value
            };
    }
}

function _formatDataItemByType(type, cellData) {
    switch (type) {
        case columnTypes.NUMBER: {
            return { ...cellData, _dataItem: isLDEmpty(cellData?._dataItem) ? null : +cellData?._dataItem };
        }
        case columnTypes.MARKDOWN:
        case columnTypes.RICH_TEXT:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.TRANSLATION:
        case columnTypes.RECORD_ID:
        case columnTypes.ALTERED_BY:
        case columnTypes.SINGLE_LINE:
            return { ...cellData, _dataItem: isLDEmpty(cellData?._dataItem) ? null : cellData?._dataItem?.toString() };

        default:
            return cellData;
    }
}

function detectColumnCanPaste({
    content,
    recordId,
    columnId,
    columnIds,
    recordIds,
    data,
    metaData,
    dependencies,
    disabledCols,
    isRangeSelected,
    limitRecordIds,
    limitColumnIds
}) {
    const refColumns = [];
    const recordsServer = [];
    let oldValuesWasPasted = {};
    let newRecordMetadataChanges = {};
    let oldRecordMetadata = {};
    const newValuesWasPasted = {};

    const rowLength = content.length;
    const rowIndex = recordIds.findIndex(id => recordId === id);
    const { remainColumnIndexes, remainColumnIdsCanPaste, columnsPasted } = getColumnsCanPaste({
        content,
        columnId,
        columnIds,
        disabledCols,
        limitColumnIds: isRangeSelected ? limitColumnIds : []
    });

    const affectedRowIds = isRangeSelected ? limitRecordIds : recordIds.slice(rowIndex, rowIndex + rowLength);

    const childDependencyIds = dependencies?.filter(dpDc => !isTempId(dpDc))?.map(dpDc => dpDc?.child);

    let transUnits = [];

    const maxContentRowIndex = content.length;

    console.log('body', {
        maxContentRowIndex,
        affectedRowIds,
        remainColumnIdsCanPaste,
        content
    });

    for (let i = 0; i < affectedRowIds.length; i++) {
        // expand row
        let recordData = content?.[i % maxContentRowIndex];
        const rowDataServer = [];
        const rowId = affectedRowIds?.[i];

        const recordDataLength = recordData?.length;

        const rowDataMap = remainColumnIndexes.map((remainColIndex, index) => {
            const pastedColumnId = remainColumnIdsCanPaste?.[index];
            const column = metaData?.[pastedColumnId];
            const options = column?.options || [];
            const columnType = getCorrectColumnType(column);

            const isChildDependencyLanguage =
                columnType === columnTypes.TRANSLATION && childDependencyIds?.includes(pastedColumnId);
            const columnParentId = dependencies?.find(dpDc => dpDc?.child === pastedColumnId)?.parent;
            const parentColumnDetail = metaData?.[columnParentId];
            const isParentColumnLanguage = getCorrectColumnType(parentColumnDetail) === columnTypes.TRANSLATION;

            const recordDataIndex = recordData?.[remainColIndex % recordDataLength];

            const parentCellData = getCellData({ data, rowId, columnId: columnParentId });
            const parentCellValue = parentCellData?.value;

            const isParentDisabledStatus = [
                SOURCE_STATUS.DO_NOT_TRANSLATE,
                SOURCE_STATUS.NOT_READY_FOR_TRANSLATION
            ]?.includes(parentCellData?._sourceStatus);

            const currentCellData = getCellData({ data, rowId, columnId: pastedColumnId });

            const isCurrentCellSourceStatusLocked = [SOURCE_STATUS?.LOCKED]?.includes(currentCellData?._sourceStatus);

            const isReadOnly = isParentDisabledStatus || isCurrentCellSourceStatusLocked;

            const { serverValue, storeValue } = formatPastingValueByType({
                columnType,
                value: isReadOnly ? currentCellData?.value : recordDataIndex,
                options,
                columnId: pastedColumnId,
                currentCellData
            });

            if (
                isParentColumnLanguage &&
                isChildDependencyLanguage &&
                !!parentCellValue &&
                !!serverValue &&
                !isReadOnly
            ) {
                const transUnit = {
                    sourceLang: parentColumnDetail?.group,
                    translations: [
                        {
                            lang: parentColumnDetail?.group,
                            texts: [parentCellValue]
                        },
                        {
                            lang: column?.group,
                            texts: [serverValue?._dataItem]
                        }
                    ]
                };

                transUnits.push(transUnit);
            }

            if (columnType === columnTypes.REFERENCE) {
                if (!refColumns.includes(pastedColumnId)) {
                    refColumns.push(pastedColumnId);
                }
            }

            rowDataServer.push(
                [columnTypes.PATH_TAG, columnTypes.RECORD_ID]?.includes(columnType)
                    ? serverValue?._dataItem?.toString()
                    : _formatDataItemByType(columnType, serverValue)
            );
            return [serverValue, { ...currentCellData, ...storeValue }];
        });

        recordsServer.push([rowId, ...rowDataServer]);
        let rowOldData = {};
        let rowNewData = {};

        for (let i = 0; i < remainColumnIdsCanPaste.length; i++) {
            const columnId = remainColumnIdsCanPaste?.[i];
            const [, cellData] = rowDataMap[i];
            const oldCellData = getCellData({ data, rowId, columnId });
            rowNewData[columnId] = cellData;
            rowOldData[columnId] = oldCellData;
        }
        oldValuesWasPasted[rowId] = rowOldData;
        newValuesWasPasted[rowId] = rowNewData;
    }

    const updatedPastedServerValue = {
        columns: ['_recordId', ...remainColumnIdsCanPaste],
        records: recordsServer
    };

    return {
        updatedPastedServerValue,
        updatedPastedLocalValue: newValuesWasPasted,
        oldValuesWasPasted,
        recordsServer,
        newRecordMetadataChanges,
        oldRecordMetadata,
        refColumns,
        affectedRowIds,
        affectedColumnIds: remainColumnIdsCanPaste,
        transUnits,
        pastedColumnIds: columnsPasted
    };
}

export function pasteColumnsHeader({ success, error }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            columnsSelected,
            viewColumns,
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            metaData,
            quickFilters,
            quickSorts
        } = gridUI;
        // const columnIds = columnUtils.getExactColumns(viewColumns);
        const columnId = columnsSelected && columnsSelected[0];
        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });
        const referenceDisabledColumns = columnUtils.getReferenceDisabledColumns({ gridUI, auth });

        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });

        if (!columnId) {
            console.log('there is not thing to paste');
            success && success();
            return;
        }

        let columnsPasted = [];
        try {
            const content = contentBeforeConfirm || (await getClipboardContent());
            if (content.length === 1 && content[0].length === 0)
                return dispatch(
                    enqueueSnackbar({
                        message: `Nothing to paste`,
                        type: 'info'
                    })
                );

            const { remainColumnIndexes, remainColumnIdsCanPaste } = getColumnsCanPaste({
                content,
                columnId,
                columnIds: columnsSelected,
                disabledCols,
                limitColumnIds: []
            });
            columnsPasted = remainColumnIdsCanPaste;

            const recordsServer = [];
            const rowLength = content.length;

            for (let i = 0; i < rowLength; i++) {
                let recordData = content?.[i];
                let rowDataServer = remainColumnIndexes.map(index => {
                    const cellValue = recordData[index];
                    const columnId = remainColumnIdsCanPaste?.[index];
                    const column = metaData?.[columnId];
                    const columnType = column?.type;

                    const { serverValue } = formatPastingValueByType({
                        columnType,
                        value: cellValue,
                        options: column?.options,
                        columnId,
                        currentCellData: null
                    });
                    return serverValue;
                });

                recordsServer.push(rowDataServer);
            }

            if (isEmpty(remainColumnIdsCanPaste)) {
                console.log('no columns to paste');
                success && success();
                return;
            }

            let body = {
                columnIds: remainColumnIdsCanPaste,
                records: recordsServer
            };

            console.log('body', body);
            // return;
            dispatch(statusActions.registerDoingAction({ actionId }));

            dispatch(
                columnActions.addProcessingColumns({
                    columnIds: remainColumnIdsCanPaste
                })
            );
            const quickFiltersFormatted = formatQuickFilters(quickFilters);

            if (!isEmpty(quickFiltersFormatted)) {
                body = {
                    ...body,
                    query: JSON.stringify(quickFiltersFormatted)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sort: JSON.stringify(quickSorts)
                };
            }

            await pasteViewRecordsOnColumnHeaderApi({
                dbId,
                viewId: defaultAccessViewId,
                body
            });

            dispatch(
                cellCopiedActions.setCopiedRange({
                    rowStartIndex: -1,
                    rowStopIndex: -1,
                    columnStartIndex: -1,
                    columnStopIndex: -1
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message: `Action is in process`,
                    type: 'info'
                })
            );
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(
                columnActions.removeProcessingColumns({
                    columnIds: columnsPasted
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            error && error();
        }
    };
}

export function undoPasteRangeCell({
    columnIds,
    undoSelectionData,
    redoSelectionData,
    isUndo,
    rowIds,
    isSettingReference,
    refColumnId
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, metaData } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));

        console.log('body', {
            columnIds,
            undoSelectionData,
            redoSelectionData,
            isUndo,
            rowIds,
            isSettingReference,
            refColumnId
        });
        const records = [];

        let selectionData = {};
        if (isUndo) {
            selectionData = cloneDeep(undoSelectionData);
        } else {
            selectionData = cloneDeep(redoSelectionData);
        }

        dispatch(dataActions.updateData({ newData: selectionData, isCareData: true }));

        const affectedRowIds = Object.keys(selectionData);
        //convert to server value
        for (const recordId of affectedRowIds) {
            const recordData = columnIds.map(columnId => {
                const column = metaData?.[columnId];
                const columnType = getCorrectColumnType(column);

                const cellData = getCellData({ data: selectionData, rowId: recordId, columnId });
                const cellValue = cellData?.value;

                switch (columnType) {
                    case columnTypes.REFERENCE: {
                        if (isArray(cellValue) && !isEmpty(cellValue)) {
                            const items = data?.filter(
                                obj =>
                                    obj.hasOwnProperty('referencedRecordId') && obj.hasOwnProperty('referencedDataItem')
                            );
                            if (!items?.length) return null;
                            return items.map(item => item?.referencedRecordId);
                        } else {
                            return null;
                        }
                    }

                    case columnTypes.FILES: {
                        if (isArray(cellValue)) {
                            return cellValue?.map(value => value?.id)?.filter(Boolean);
                        } else {
                            return null;
                        }
                    }
                    default:
                        return cellValue;
                }

                //convert server ref
            });

            records.push([recordId, ...recordData]);
        }

        try {
            const updateChunks = chunk(records, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...columnIds],
                            records
                        }
                    });
                })
            );

            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: [refColumnId],
                        recordIds: rowIds
                    })
                );
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}
