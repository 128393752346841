import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import Upload from 'components/upload/Base';
import Button from 'components/button/Base';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import SettingContentLayout from '../common/Layout';
import * as companyActions from '../actions/company';
import { SHARED_STYLES, PAGE_PADDING } from 'const/style';
import { AVATAR_EXTENSIONS, HEADER_HEIGHT } from 'const';
import Avatar from 'components/avatar/Company';
import AvatarCropper from 'components/avatar/Cropper';
import Spinner from 'components/spinner/Base';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/dialog/Dialog';
import { getCompanyLogoUrl, readFile } from 'utils/images';
import * as appActions from 'app/actions';
import { useCompanyInfo } from 'hooks/app';

const useStyles = makeStyles(theme => ({
    root: {
        padding: PAGE_PADDING
    },
    form: SHARED_STYLES.settingForm,
    divider: {
        width: PAGE_PADDING * 2,
        alignContent: `stretch`,
        display: 'flex',
        justifyContent: 'center',
        '& span': {
            width: 1,
            background: theme.colors.border
        },

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    fileInput: {
        width: 130,
        height: 130,
        position: 'relative',
        '& .hoverWrapper': {
            position: 'absolute',
            width: 130,
            height: 130,
            opacity: 0.6,
            borderRadius: 4,
            background: 'black',
            display: 'none',
            '& path': {
                fill: theme.colors.white
            }
        },
        '&:hover': {
            '& .hoverWrapper': {
                display: 'block'
            }
        },
        overflow: 'hidden',
        outline: 'none'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: 130,
        height: 130,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    uploading: {
        opacity: 0.6,
        background: 'black'
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    settingContent: {
        width: '100%',

        [theme.breakpoints.down('xs')]: {
            maxHeight: `initial`,
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            overflowY: 'auto'
        }
    }
}));

function CompanyInfo() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const company = useCompanyInfo();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [thumbnail, setThumbnail] = React.useState('');
    const [isUploading, setIsUploading] = React.useState(false);
    const [selectedImg, setSelectedImg] = useState(null);

    React.useEffect(() => {
        dispatch(
            appActions.fetchCompanyInfo({
                successCallback: () => {
                    console.log('fetch company info successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch company info');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        setThumbnail(getCompanyLogoUrl(company.logoUrl));
    }, [company]);

    const onRejectedHandler = useCallback(
        rejectedFiles => {
            dispatch(companyActions.uploadCompanyException({ error: 'Failed to upload company logo' }));
        },
        [dispatch]
    );

    const onDropAcceptedHandler = useCallback(async acceptedFiles => {
        const file = acceptedFiles?.[0];

        const imageDataUrl = await readFile(file);

        setSelectedImg(imageDataUrl);
    }, []);

    const onAvatarSave = useCallback(
        file => {
            setIsUploading(true);
            setSelectedImg(null);
            if (file) {
                if (thumbnail) {
                    window.URL.revokeObjectURL(thumbnail);
                }
                setThumbnail(window.URL.createObjectURL(file));
            }
            dispatch(
                companyActions.uploadCompanyLogo({
                    file,
                    successCallback: () => {
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                    }
                })
            );
        },
        [thumbnail, dispatch]
    );

    React.useEffect(() => {
        return () => {
            if (thumbnail) {
                window.URL.revokeObjectURL(thumbnail);
            }
        };
    }, [thumbnail]);

    React.useEffect(() => {
        setName(company.name);
    }, [company]);

    React.useEffect(() => {
        setDescription(company.description);
    }, [company]);

    const handleUpdateCompany = () => {
        dispatch(
            companyActions.changeCompanyInfo({
                notify: true,
                newData: {
                    ...company,
                    name,
                    description
                },
                errorCallback: () => {
                    console.log('failed to update company info');
                },
                successCallback: () => {
                    console.log('update company info successfully');
                }
            })
        );
    };

    return (
        <div className={classes.settingContent}>
            <SettingContentLayout name={t('settings_company_info_title')} height={'auto'}>
                <Grid className={classes.root} container direction="row" spacing={3}>
                    <Grid item>
                        <Upload
                            onDropAccepted={onDropAcceptedHandler}
                            onDropRejected={onRejectedHandler}
                            className={`${classes.fileInput} `}
                            accept={AVATAR_EXTENSIONS}
                            hideBorder={Boolean(company.logoUrl)}
                        >
                            {thumbnail && (
                                <>
                                    <Avatar className={classes.avatar} size={130} src={thumbnail} />
                                    {!isUploading && (
                                        <Grid item className={`hoverWrapper`}>
                                            <div className={classes.center}>
                                                <IconCameraSVG />
                                            </div>
                                        </Grid>
                                    )}
                                    {isUploading && (
                                        <Grid
                                            item
                                            className={`${classes.spinnerWrapper} ${
                                                isUploading ? classes.uploading : ''
                                            }`}
                                        >
                                            <Spinner size={18} thick={3} />
                                        </Grid>
                                    )}
                                </>
                            )}

                            {!thumbnail && <IconCameraSVG />}
                        </Upload>
                        <Dialog
                            open={!!selectedImg}
                            onClose={e => {
                                setSelectedImg(null);
                            }}
                        >
                            {selectedImg && <AvatarCropper src={selectedImg} onSave={onAvatarSave} />}
                        </Dialog>
                    </Grid>
                    <Grid item className={classes.divider}>
                        <span></span>
                    </Grid>
                    <Grid item className={classes.form} style={{ flex: 1 }}>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t('settings_company_info_company_name_label')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="name"
                                            type="text"
                                            value={name}
                                            height={40}
                                            onChange={e => setName(e.target.value)}
                                            placeholder={t('settings_company_info_company_name_placeholder')}
                                            inputProps={{
                                                maxLength: 255
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t('settings_company_info_company_description_label')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="description"
                                            type="text"
                                            height={200}
                                            value={description}
                                            multiline
                                            rows={5}
                                            onChange={e => setDescription(e.target.value)}
                                            placeholder={t('settings_company_info_company_description_placeholder')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleUpdateCompany}
                                    disabled={!name || (name === company.name && description === company.description)}
                                    width={120}
                                    variant="contained"
                                >
                                    {t('global_text_save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SettingContentLayout>
        </div>
    );
}

export default React.memo(CompanyInfo);
