import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import { DEFAULT_FIRST_TABLE_COLUMN_WIDTH } from 'const/style';
import GridIcon from 'grids/GridIcon';
import ViewIcon from 'gridUI/views/ViewIcon';
import hexToRgba from 'hex-to-rgba';
import BranchSVG from 'assets/images/svg/BranchSVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import Tooltip from 'components/tooltip/Base';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as groupActions from 'permission/actions/group';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import { enqueueSnackbar } from 'notifier/actions';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    table: {
        border: '1px solid #e9eaef',
        borderRadius: 4,
        overflow: 'hidden',
        color: theme.colors.primaryText,
        '& .ReactVirtualized__Table__row': {
            '&:hover': {
                backgroundColor: 'rgba(74, 145, 226, 0.05)'
            }
        },

        '& .header-role': {
            marginLeft: 15
        },
        '& .cell-role': {
            paddingLeft: 15,

            '& .select': {
                marginLeft: -15
            }
        }
    },
    ellipsis: {
        ...theme.ellipsis(),
        whiteSpace: `break-spaces`
    },
    accessView: {
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.8),
        border: `1px solid ${theme.colors.fuchsiaBlue}`,
        borderRadius: '50%',
        '& svg': {
            width: 12,
            color: theme.colors.white
        }
    },
    parentGridName: {
        padding: '3px 10px 3px 24px',
        background: '#F3F4FC',
        border: `1px solid #EBEBF6`,
        boxSizing: 'border-box',
        borderRadius: 3,
        width: 'fit-content',
        position: 'relative',
        '& svg': {
            position: 'absolute',
            left: 6,
            top: 4
        },
        '& p': {
            fontWeight: 500,
            color: '#7869B9',
            whiteSpace: 'break-spaces',
            ...theme.ellipsis()
        }
    },
    gridName: {
        whiteSpace: 'break-spaces',
        ...theme.ellipsis()
    },
    noRowsRenderer: {
        height: '100%',
        '& p': {
            color: theme.colors.steel
        }
    },
    spanRemoveAccess: {
        fontWeight: 700,
        color: theme.colors.burntSlena
    },
    buttonRightClassName: {
        background: `${theme.colors.burntSlena} !important`
    }
}));

function WorkspacesAccessTable({ rawWorkspaces = [], workspaces = [], isFilter, isFetching }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [sorting, setSorting] = useState({});
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedAccessView, setSelectedAccessView] = useState(undefined);
    const [confirming, setConfirming] = useState(false);

    const data = useMemo(() => {
        const cloneData = [...workspaces];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, workspaces]);

    const workspaceNameRenderer = ({ rowData }) => {
        const { workspaceName } = rowData;
        return (
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
                <Grid item style={{ display: 'flex' }}>
                    <WorkspaceSVG color={'#78778B'} />
                </Grid>
                <Grid item>
                    <Tooltip title={workspaceName}>
                        <Typography variant="body2" className={classes.ellipsis}>
                            {workspaceName}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    const dbNameRenderer = ({ rowData }) => {
        const { databaseName } = rowData;
        return (
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
                <Grid item style={{ display: 'flex' }}>
                    <DatabaseSVG />
                </Grid>
                <Grid item>
                    <Tooltip title={databaseName}>
                        <Typography variant="body2" className={classes.ellipsis}>
                            {databaseName}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    const gridNameRenderer = ({ rowData }) => {
        const { parentGridName, gridName, gridProperties } = rowData;
        const grid = { customProperties: gridProperties };

        return (
            <>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item className={classes.gridIcon}>
                        <GridIcon grid={grid} size="small" />
                    </Grid>
                    <Grid item>
                        <Tooltip title={parentGridName || gridName}>
                            <Typography variant="body2" className={classes.gridName}>
                                {parentGridName || gridName}
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
                {parentGridName && (
                    <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 4 }}>
                        <Grid item style={{ marginRight: 9, display: 'flex' }}>
                            <ArrowRightSVG />
                        </Grid>

                        <Grid
                            item
                            style={{
                                width: '100%'
                            }}
                        >
                            <Tooltip title={gridName}>
                                <Typography variant="body2" component="div" className={classes.parentGridName}>
                                    <BranchSVG color="#7869B9" />
                                    <Typography variant="body2">{gridName}</Typography>
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    };

    const viewNameRenderer = ({ rowData }) => {
        const { viewName, viewProperties } = rowData;
        const view = viewProperties || {};

        return (
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: 'flex' }}>
                    <ViewIcon view={view} size="small" />
                </Grid>
                <Grid item>
                    <Tooltip title={viewName}>
                        <Typography variant="body2" className={classes.ellipsis}>
                            {viewName}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const handleOpenConfirmDialog = useCallback(view => {
        setSelectedAccessView(view);
        setOpenConfirmDialog(true);
    }, []);

    const handleCloseConfirmDialog = useCallback(() => {
        setSelectedAccessView(undefined);
        setOpenConfirmDialog(false);
    }, []);

    const COLUMNS = [
        { dataKey: 'empty', width: DEFAULT_FIRST_TABLE_COLUMN_WIDTH, cellRenderer: null },
        {
            label: t('project'),
            dataKey: 'workspaceName',
            width: 250,
            flexGrow: 1,
            sort: true,
            cellRenderer: workspaceNameRenderer
        },
        {
            label: t('database'),
            dataKey: 'databaseName',
            width: 250,
            flexGrow: 1,
            sort: true,
            cellRenderer: dbNameRenderer
        },
        { label: t('grid'), dataKey: 'gridName', width: 250, flexGrow: 1, sort: true, cellRenderer: gridNameRenderer },
        { label: t('view'), dataKey: 'viewName', width: 250, flexGrow: 1, sort: true, cellRenderer: viewNameRenderer },
        {
            label: '',
            dataKey: 'delete',
            width: 50,
            cellRenderer: ({ rowData }) => (
                <DeleteSVG style={{ cursor: 'pointer' }} onClick={() => handleOpenConfirmDialog(rowData)} />
            )
        }
    ];

    const handleRemoveAccessView = useCallback(() => {
        setConfirming(true);
        let oldProjectIds = rawWorkspaces?.projects?.map(p => p.id);
        let oldDbIds = rawWorkspaces?.databases?.map(d => d.id);
        const removeViews = [];
        if (!!selectedAccessView.gridId || !!selectedAccessView.viewId) {
            removeViews.push(selectedAccessView);
        } else if (!!selectedAccessView.databaseId) {
            oldDbIds = oldDbIds.filter(id => id !== selectedAccessView.databaseId);
        } else if (!!selectedAccessView.workspaceId) {
            oldProjectIds = oldProjectIds.filter(id => id !== selectedAccessView.workspaceId);
        }
        dispatch(
            groupActions.updateGroupWithWorkspaceAccess({
                removeViews: removeViews,
                projectIds: [...oldProjectIds],
                dbIds: [...oldDbIds],
                addViews: [],
                errorCallback: () => {
                    setConfirming(false);
                },
                successCallback: () => {
                    dispatch(
                        groupActions.fetchGroupWorkspacesAccess({
                            isFetching: false,
                            successCallback: () => {
                                console.log('fetch group Access successfully');
                                dispatch(
                                    enqueueSnackbar({
                                        message: t('change_saved'),
                                        type: 'info'
                                    })
                                );
                            },
                            errorCallback: () => {
                                console.log('failed to fetch group Access');
                            }
                        })
                    );
                    setConfirming(false);
                    handleCloseConfirmDialog();
                }
            })
        );
    }, [dispatch, selectedAccessView, handleCloseConfirmDialog, rawWorkspaces, t]);

    return (
        <>
            <div className={classes.table} style={{ width: '100%', height: data.length > 0 ? '55vh' : '180px' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <>
                            <Table
                                data={data}
                                columns={COLUMNS}
                                width={width}
                                height={height}
                                onRowClick={handleRowClick}
                                onSort={handleOnSort}
                                noRowsRenderer={
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        className={classes.noRowsRenderer}
                                    >
                                        <Grid item>
                                            <Typography variant="body2" className={classes.noAccess}>
                                                {isFilter ? 'No result found' : 'No access granted'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            ></Table>
                            {isFetching && (
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    style={{ position: 'absolute', height, width, top: 0, left: 0 }}
                                >
                                    <Grid item>
                                        <CircularProgress size={24} />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </AutoSizer>
            </div>
            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <ConfirmBox
                    title={t('modal_delete_access_view')}
                    body={
                        <>
                            <Grid item>
                                <Typography variant="body2">
                                    Are you sure you want to{' '}
                                    <span className={classes.spanRemoveAccess}>remove this access?</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    This action may affect the group access and cannot be undone
                                </Typography>
                            </Grid>
                        </>
                    }
                    handleCancel={handleCloseConfirmDialog}
                    onClose={handleCloseConfirmDialog}
                    handleAgreed={handleRemoveAccessView}
                    agreeLabel={t('global_remove')}
                    isLoading={confirming}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </>
    );
}

export default React.memo(WorkspacesAccessTable);
