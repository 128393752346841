import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ErrorIcon from 'assets/images/svg/ErrorIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        top: -20,
        '& svg': {
            fontSize: 25,
            position: 'relative',
            top: 1,
            color: theme.colors.burntSlena
        },
        '& p': {
            color: theme.colors.burntSlena
        }
    }
}));

function ErrorMessage({ message = 'Default Error Message' }) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Grid
            container
            justify="center"
            spacing={1}
            alignItems="flex-start"
            className={classes.root}
            e2e="ErrorMessage"
            wrap="nowrap"
        >
            <Grid item>
                <ErrorIcon color={theme.colors.burntSlena} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{message}</Typography>
            </Grid>
        </Grid>
    );
}

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired
};

export default React.memo(ErrorMessage);
