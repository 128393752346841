import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import LDBasePortal from 'components/selects/LDBasePortal';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    dropdown: {
        '& .popper': {
            width: '300px !important',
            [theme.breakpoints.down('md')]: {
                width: '249px !important'
            }
        }
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    }
}));

function getWrap({ map, childrens = [] }) {
    let ids = [];
    childrens.forEach(child => {
        let elements = map[child];
        elements = elements?.filter(Boolean);
        if (elements && elements.length) {
            ids.push(...elements, ...getWrap({ map, childrens: elements }));
        }
    });

    return ids;
}

function generateParentMap({ dependencies, nodeId }) {
    const parentMap = {};
    dependencies.forEach(dp => {
        const parent = dp.parent;
        if (!parentMap[parent]) {
            parentMap[parent] = [];
        }
        parentMap[parent] = [...parentMap[parent], dp.child];
    });
    const prevRelationShip = getWrap({
        map: parentMap,
        childrens: parentMap[nodeId] || []
    });

    const parentNode = parentMap[nodeId] || [];
    return [...parentNode, ...prevRelationShip] || [];
}

function generateChildMap({ dependencies, nodeId }) {
    const childMap = {};

    dependencies.forEach(dp => {
        const child = dp.child;
        if (!childMap[child]) {
            childMap[child] = [];
        }
        childMap[child] = [...childMap[child], dp.parent];
    });

    const prevRelationShip = getWrap({
        map: childMap,
        childrens: childMap[nodeId] || []
    });

    const childNode = childMap[nodeId] || [];
    return [...childNode, ...prevRelationShip] || [];
}

function Dependency({
    id,
    child,
    parent,
    dependencies,
    updateParentDependency,
    updateChildDependency,
    removeDependency,
    dependencyOptions,
    isDoingOnDependencies,
    t
}) {
    const classes = useStyles();

    const childRejectedIds = React.useMemo(() => {
        return generateChildMap({
            dependencies,
            nodeId: parent
        });
    }, [dependencies, parent]);

    const parentRejectedIds = React.useMemo(() => {
        return generateParentMap({
            dependencies,
            nodeId: child
        });
    }, [dependencies, child]);

    const parentOptions = React.useMemo(() => {
        return dependencyOptions
            .filter(option => {
                return option.value !== child;
            })
            .filter(option => {
                return !parentRejectedIds.includes(option.value);
            });
    }, [child, dependencyOptions, parentRejectedIds]);

    const childOptions = React.useMemo(() => {
        return dependencyOptions
            .filter(option => {
                return option.value !== parent;
            })
            .filter(option => {
                const dependencyChildIds = dependencies.map(dp => dp.child);
                return !dependencyChildIds.includes(option.value);
            })
            .filter(option => {
                return !childRejectedIds.includes(option.value);
            });
    }, [parent, dependencyOptions, dependencies, childRejectedIds]);

    const defaultParent = React.useMemo(() => {
        return parent ? dependencyOptions.find(option => option.value === parent) : null;
    }, [dependencyOptions, parent]);

    const defaultChild = React.useMemo(() => {
        return child ? dependencyOptions.find(option => option.value === child) : null;
    }, [child, dependencyOptions]);

    const handleParentChange = React.useCallback(
        option => {
            updateParentDependency({ id, value: option.value, child, parent });
        },
        [child, id, parent, updateParentDependency]
    );

    const handleChildChange = React.useCallback(
        option => {
            updateChildDependency({ id, value: option.value, parent, child });
        },
        [id, parent, child, updateChildDependency]
    );

    const removeDependencyHandler = React.useCallback(() => {
        removeDependency({ id });
    }, [id, removeDependency]);

    return (
        <Grid className={classes.root} container alignItems="center" direction="row" spacing={2}>
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY}>
                {({ isReadOnly }) => {
                    return (
                        <Grid item>
                            <Grid container justify="center" alignItems="center">
                                <Tooltip
                                    title={
                                        isReadOnly ? (
                                            <Grid container className={classes.permissionTooltip}>
                                                {t('toolbar_no_permission')}
                                            </Grid>
                                        ) : (
                                            ``
                                        )
                                    }
                                >
                                    <Grid
                                        item
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            pointerEvents: isReadOnly || isDoingOnDependencies ? 'none' : '',
                                            opacity: isReadOnly ? DISABLED_OPACITY : 1
                                        }}
                                        onClick={removeDependencyHandler}
                                    >
                                        <CloseIconSVG />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    );
                }}
            </AccessControl>
            <Grid item>
                <p className="body2">{t('global_from')}</p>
            </Grid>
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY}>
                {({ isReadOnly }) => (
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ``
                            )
                        }
                    >
                        <Grid item style={{ flex: 1 }}>
                            <LDBasePortal
                                isDisabled={isReadOnly || isDoingOnDependencies}
                                ddPlaceholder={t(`global_label_select_a_column`)}
                                menuPlaceholder={t(`global_label_find_a_column`)}
                                defaultValue={defaultParent}
                                onChange={handleParentChange}
                                options={parentOptions}
                                isUsingContainer={true}
                                placement="bottom-start"
                                showTooltip
                            />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>

            <Grid item>
                <p className="body2">{t('global_to')}</p>
            </Grid>
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY}>
                {({ isReadOnly }) => (
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ``
                            )
                        }
                    >
                        <Grid item style={{ flex: 1 }}>
                            <LDBasePortal
                                isDisabled={isReadOnly || isDoingOnDependencies}
                                ddPlaceholder={t(`global_label_select_a_column`)}
                                menuPlaceholder={t(`global_label_find_a_column`)}
                                defaultValue={defaultChild}
                                onChange={handleChildChange}
                                options={childOptions}
                                isUsingContainer={true}
                                placement="bottom-start"
                                showTooltip
                            />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
        </Grid>
    );
}

export default React.memo(Dependency);
