import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, alpha, Collapse } from '@material-ui/core';
import SettingContentLayout from 'permission/common/Layout';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import LDBasePortal from 'components/selects/LDBasePortal';
import LDBaseCreatablePortal from 'components/selects/LDBaseCreatablePortal';
import ButtonBase from 'components/button/Base';
import * as integrationActions from 'integrations/actions';
import { useDispatch } from 'react-redux';
import Spinner from 'components/spinner/Base';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useSheetsBySourceId, useSourceOptions } from 'hooks/integration';
import { SYSTEM_COLUMNS, SOURCE_TYPE_OPTIONS } from 'const';
import * as databaseActions from 'databases/actions';
import * as workspaceActions from 'workspaces/actions';
import { useDatabasesOptionsByWorkspaceId } from 'hooks/database';
import * as roleConst from 'auth/roleConst';
import { useWorkspaceList } from 'hooks/workspace';
import * as gridActions from 'grids/actions';
import { useGridsByDbId } from 'hooks/grid';
import { getGridColumnsApi } from 'services/grid';
import * as columnTypes from 'const/columnTypes';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import GridIcon from 'grids/GridIcon';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import { getConnectorIcon } from 'utils/connector';
import { useCompanyAuthorities } from 'hooks/auth/role';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import InputText from 'components/inputs/InputText';
import NoSourceIllustratorSVG from 'assets/images/svg/NoSourceIllustratorSVG';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import Dialog from 'components/dialog/Dialog';
import LocalizationEmptySetup from 'grids/localization/LocalizationEmptySetup';
import { generateColumnMetaDataByHeaders } from 'utils/gridUI/formatData';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        position: 'relative',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    createWrapper: {
        height: `calc(100%)`,
        maxHeight: `calc(100%)`,
        overflowY: 'auto'
    },
    formContent: {
        minWidth: 700,
        maxWidth: 700,
        height: '100%',
        margin: 'auto',

        [theme.breakpoints.down('md')]: {
            minWidth: 'auto'
        }
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    link: {
        color: theme.colors.dodgerBlue,
        textDecoration: 'underline'
    },
    warning: {
        background: theme.colors.cherub,
        borderRadius: 4,
        padding: 9
    },
    success: {
        background: theme.colors.grannyApple,
        borderRadius: 4,
        padding: 9
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: alpha(theme.colors.white, 0.5),
        zIndex: 2
    },
    table: {
        height: 220,
        overflow: 'hidden',
        userSelect: 'none',
        '& .cell': {
            pointerEvents: 'none'
        },
        position: 'relative',
        borderRight: `1px solid ${theme.colors.divider}`,
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    fullWidth: {
        width: '100%'
    },
    stepContent: {
        width: '100%',
        flex: 1
    },
    my5: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    mb5: {
        marginBottom: theme.spacing(5)
    },
    footer: {
        height: 84,
        minHeight: 84,
        borderTop: `1px solid ${theme.colors.border}`,
        width: '100%',
        background: theme.colors.white
    },
    contentPadding: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    smallPadding: {
        padding: theme.spacing(4)
    },

    ellipsis: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    name: {
        background: theme.colors.paleGrey,
        borderRadius: 4,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        border: `1px solid ${theme.colors.silver}`,
        height: 36,
        display: 'flex',
        alignItems: 'center'
    },
    spinner: {
        height: 36,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    createLabel: {
        width: '100%',
        overflow: 'hidden'
    },
    wrapper: {
        padding: theme.spacing(4),
        background: theme.colors.ghostwhite,
        borderRadius: 6
    },
    pet: {
        position: 'absolute',
        top: -48,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center'
    }
}));

const steps = ['Set up source', 'Set up destination', 'Set up connection'];

function getStepTitle(step) {
    switch (step) {
        case 0:
            return 'Define the data source';
        case 1:
            return 'Define the destination in Gridly';
        case 2:
            return 'Map your data to Gridly';
        default:
            return 'Define the data source';
    }
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Connection() {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const sourceOptions = useSourceOptions();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [selectedSource, setSelectedSource] = React.useState(null);
    const [selectedSheet, setSelectedSheet] = React.useState(null);
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [selectedDatabase, setSelectedDatabase] = React.useState(null);
    const [selectedGrid, setSelectedGrid] = React.useState(null);
    const databaseOptions = useDatabasesOptionsByWorkspaceId(selectedProject?.value);
    const workspaceList = useWorkspaceList();
    const sheets = useSheetsBySourceId(selectedSource?.value);
    const dbGridList = useGridsByDbId(selectedDatabase?.value);
    const [gridColumnOptions, setGridColumnOptions] = React.useState([]);
    const [columnMappings, setColumnMappings] = React.useState({});
    const connectionRef = React.useRef(null);
    const companyAuthorities = useCompanyAuthorities();
    const [isSheetLoading, setIsSheetLoading] = React.useState(false);
    const { id } = useParams();
    const [connectionName, setConnectionName] = React.useState('Untitled Connection');
    const [openAddNewGrid, setOpenNewGrid] = React.useState(false);

    const query = useQuery();

    const selectedSourceLabel = React.useMemo(() => {
        switch (selectedSource?.connector) {
            case SOURCE_TYPE_OPTIONS.MYSQL:
            case SOURCE_TYPE_OPTIONS.POSTGRES:
                return 'Select a table you want to sync to Gridly';
            default:
                return 'Select a worksheet to be connected with Gridly';
        }
    }, [selectedSource]);

    const defaultSourceId = React.useMemo(() => {
        return query?.get('sourceId');
    }, [query]);

    const [title, setTitle] = React.useState('New connection');

    const isEdit = React.useMemo(() => {
        return !!id;
    }, [id]);

    const isCreatingNewGrid = React.useMemo(() => {
        return selectedGrid?.__isNew__;
    }, [selectedGrid]);

    const gridOptions = React.useMemo(() => {
        return dbGridList?.map(grid => ({
            label: grid?.name,
            value: grid?.id,
            icon: () => <GridIcon grid={grid} size="small" />,
            ...grid
        }));
    }, [dbGridList]);

    const sheetOptions = React.useMemo(() => {
        return sheets?.map(sheet => ({
            label: sheet?.stream?.name,
            value: sheet?.config?.aliasName,
            ...sheet
        }));
    }, [sheets]);

    const isCreator = React.useMemo(() => {
        return companyAuthorities?.includes(roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE);
    }, [companyAuthorities]);

    const workspaceOptions = React.useMemo(() => {
        const options = [];
        workspaceList.forEach(ws => {
            const gridPrivileges =
                ws?.role?.privileges?.filter(priv =>
                    isCreator ? true : priv.name === roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID
                ) || [];

            if (gridPrivileges.length > 0) {
                options.push({
                    label: ws?.name,
                    value: ws?.id,
                    icon: () => <WorkspaceSVG />
                });
            }
        });

        return options;
    }, [workspaceList, isCreator]);

    const handleBack = React.useCallback(() => {
        history.push('/integration-settings/connectors');
    }, [history]);

    const validateNext = React.useMemo(() => {
        switch (activeStep) {
            case 0:
                return selectedSource && selectedSheet;
            case 1:
                return selectedDatabase && selectedProject && selectedGrid;
            default:
                return connectionName && Object.keys(columnMappings)?.length;
        }
    }, [
        activeStep,
        connectionName,
        selectedSource,
        selectedSheet,
        selectedDatabase,
        selectedProject,
        selectedGrid,
        columnMappings
    ]);

    const sheetColumnOptions = React.useMemo(() => {
        if (!selectedSheet) return [];
        const properties = selectedSheet?.stream?.jsonSchema?.properties || {};
        return Object.keys(properties)?.map(key => ({
            label: key,
            value: key
        }));
    }, [selectedSheet]);

    const headers = React.useMemo(() => {
        return sheetColumnOptions?.map(opt => opt?.value);
    }, [sheetColumnOptions]);

    const headerMapping = React.useMemo(() => {
        return generateColumnMetaDataByHeaders(headers);
    }, [headers]);

    const isTranslationGrid = React.useMemo(() => {
        const keys = Object.keys(headerMapping);
        return keys?.some(key => headerMapping?.[key]?.type === columnTypes.TRANSLATION);
    }, [headerMapping]);

    React.useEffect(() => {
        const fetchGridData = async () => {
            try {
                const gridColumns = await getGridColumnsApi({
                    gridId: selectedGrid?.value,
                    dbId: selectedDatabase?.value
                });

                const newGridColumnOptions = [
                    ...SYSTEM_COLUMNS?.filter(sysCol =>
                        [columnTypes.PATH_TAG, columnTypes.RECORD_ID]?.includes(sysCol?.id)
                    ),
                    ...gridColumns
                ]
                    ?.filter(col => ![columnTypes.FILES, columnTypes.FORMULA].includes(col?.type))
                    ?.map(col => ({
                        ...col,
                        label: col?.name,
                        value: col?.id,
                        icon: () => <ColumnIcon {...col} />,
                        options: undefined
                    }));

                const newColumnMappings = {};

                const needAutoMappingValues = sheetColumnOptions
                    // ?.filter(sheetOpt => !columnMappings?.hasOwnProperty(sheetOpt?.value))
                    ?.map(s => s?.value);

                const matchColumn = generateColumnMetaDataByHeaders(needAutoMappingValues);

                for (const value of needAutoMappingValues) {
                    const match = matchColumn?.[value];
                    const found =
                        match?.type === columnTypes.TRANSLATION
                            ? newGridColumnOptions?.find(col => col?.group === match?.group)
                            : [columnTypes.PATH_TAG, columnTypes?.RECORD_ID]?.includes(match?.type)
                            ? newGridColumnOptions?.find(col => col?.type === match?.type)
                            : newGridColumnOptions?.find(col => col?.label?.toLowerCase() === value?.toLowerCase());

                    if (found) {
                        newColumnMappings[value] = found?.value;
                    }
                }

                setColumnMappings({
                    ...columnMappings,
                    ...newColumnMappings
                });

                setGridColumnOptions([...newGridColumnOptions]);
            } catch ({ message }) {
                setGridColumnOptions([]);
            }
        };

        if (activeStep === 2) {
            if (!isCreatingNewGrid) {
                fetchGridData();
            } else {
                const newGridColumnOptions = [
                    ...SYSTEM_COLUMNS?.filter(sysCol =>
                        [columnTypes.PATH_TAG, columnTypes.RECORD_ID]?.includes(sysCol?.id)
                    )
                ]?.map(col => ({
                    ...col,
                    label: col?.name,
                    value: col?.id,
                    icon: () => <ColumnIcon {...col} />,
                    options: undefined
                }));
                setGridColumnOptions([...newGridColumnOptions]);
                setColumnMappings({});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, isCreatingNewGrid, selectedDatabase, selectedGrid]);

    const sheetColumnOptionNames = React.useMemo(() => {
        return sheetColumnOptions?.map(i => i?.label);
    }, [sheetColumnOptions]);

    const moveToCreateSource = React.useCallback(() => {
        sendManualTrack({ type: 'Open Add New Source' });
        history.push('/integration-settings/connectors/source');
    }, [history]);

    const handleNextStep = React.useCallback(() => {
        if (activeStep === steps?.length - 1) {
            setIsSubmitting(true);

            let connectionBody = {
                name: connectionName,
                sourceId: selectedSource?.value,
                destinationProjectId: selectedProject?.value,
                destinationDatabaseId: selectedDatabase?.value,
                columnMappings: Object.keys(columnMappings)
                    ?.filter(columnName => {
                        return !isEdit ? true : sheetColumnOptionNames?.includes(columnName);
                    })
                    ?.map(columnName => {
                        let obj = {
                            columnName
                        };
                        const columnId = columnMappings?.[columnName];

                        const isCorrectColumnId = gridColumnOptions?.find(
                            opt => opt?.value === columnId && !opt?.__isNew__
                        );
                        if (isCorrectColumnId) {
                            obj['gridColumnId'] = columnId;
                        } else {
                            obj['gridColumnName'] = columnId;
                            obj['gridColumnType'] = columnTypes.MULTIPLE_LINES;
                        }
                        return obj;
                    }),
                configuration: {
                    selectedSheet: selectedSheet.config.aliasName
                }
            };

            if (selectedGrid?.__isNew__) {
                connectionBody = {
                    ...connectionBody,
                    destinationGridName: selectedGrid?.label
                };
            } else {
                connectionBody = {
                    ...connectionBody,
                    destinationGridId: selectedGrid?.value
                };
            }

            if (isEdit) {
                dispatch(
                    integrationActions.updateConnection({
                        cId: id,
                        connection: connectionBody,
                        success: () => {
                            console.log('');
                            handleBack();
                        },
                        error: () => {
                            console.log('failed create connection');
                            setIsSubmitting(false);
                        }
                    })
                );
            } else {
                dispatch(
                    integrationActions.createConnection({
                        connection: connectionBody,
                        success: connection => {
                            dispatch(
                                integrationActions.syncConnection({
                                    cId: connection?.id,
                                    ignoreMessage: true,
                                    success: () => {
                                        console.log('he');
                                        sendManualTrack({ type: 'Add New Connection' });
                                    },
                                    error: () => {
                                        console.log('he');
                                    }
                                })
                            );
                            handleBack();
                        },
                        error: () => {
                            console.log('failed create connection');
                            setIsSubmitting(false);
                        }
                    })
                );
            }

            return;
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }, [
        connectionName,
        gridColumnOptions,
        sheetColumnOptionNames,
        id,
        isEdit,
        activeStep,
        handleBack,
        columnMappings,
        selectedDatabase,
        selectedGrid,
        selectedProject,
        selectedSheet,
        selectedSource,
        dispatch
    ]);

    const handleBackStep = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    React.useEffect(() => {
        dispatch(
            integrationActions.getSources({
                success: sources => {
                    if (defaultSourceId) {
                        const foundSource = sources?.find(s => s?.id?.toString() === defaultSourceId);
                        if (foundSource) {
                            setSelectedSource({
                                value: foundSource?.id,
                                label: foundSource?.name,
                                icon: () => getConnectorIcon(foundSource?.connector, '16px'),
                                connector: foundSource?.connector
                            });
                        }
                    }
                },
                error: () => {}
            })
        );
    }, [dispatch, defaultSourceId]);

    React.useEffect(() => {
        if (selectedProject) {
            dispatch(
                databaseActions.getDatabases({
                    workspaceId: selectedProject?.value,
                    successCallback: ({ databases }) => {
                        if (!connectionRef.current) {
                            const defaultDB =
                                databases?.length === 0
                                    ? null
                                    : {
                                          label: databases?.[0]?.name,
                                          value: databases?.[0]?.id,
                                          icon: () => <DatabaseSVG />
                                      };
                            setSelectedDatabase(defaultDB);
                        } else {
                            const foundDb = databases?.find(
                                db => db?.id === connectionRef?.current?.destinationDatabaseId
                            );
                            if (foundDb) {
                                setSelectedDatabase({
                                    label: foundDb?.name,
                                    value: foundDb?.id,
                                    icon: () => <DatabaseSVG />
                                });
                            } else {
                                setSelectedDatabase(
                                    databases?.length === 0
                                        ? null
                                        : {
                                              label: databases?.[0]?.name,
                                              value: databases?.[0]?.id
                                          }
                                );
                            }
                        }
                    },
                    errorCallback: () => {}
                })
            );
        }
    }, [selectedProject, dispatch]);

    React.useEffect(() => {
        if (selectedDatabase) {
            dispatch(
                gridActions.fetchGridsByDatabase({
                    dbId: selectedDatabase?.value,
                    successCallback: () => {
                        console.log('fetchGridList success');
                    },
                    errorCallback: () => {
                        console.log('fetchGridList failed');
                    }
                })
            );
        }
    }, [dispatch, selectedDatabase]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspacesByGraphql({
                successCallback: workspaces => {
                    if (isEdit) {
                        dispatch(
                            integrationActions.getConnection({
                                cId: id,
                                success: connection => {
                                    setSelectedProject({
                                        value: connection?.destinationProjectId,
                                        label: workspaces?.find(w => w?.id === connection?.destinationProjectId)?.name,
                                        icon: () => <WorkspaceSVG />
                                    });

                                    setSelectedDatabase({
                                        value: connection?.destinationDatabaseId,
                                        label: connection.destinationDatabaseName,
                                        icon: () => <DatabaseSVG />
                                    });

                                    setSelectedGrid({
                                        value: connection?.destinationGridId,
                                        label: connection.destinationGridName,
                                        icon: () => <GridIcon size="small" />
                                    });

                                    setSelectedSource({
                                        value: connection?.sourceId,
                                        label: connection.sourceResponse?.name,
                                        icon: () => getConnectorIcon(connection?.sourceResponse?.connector, '16px')
                                    });

                                    setTitle(connection?.name);

                                    setColumnMappings(
                                        connection?.columnMappings?.reduce((obj, i) => {
                                            obj[i?.columnName] = i?.gridColumnId;
                                            return obj;
                                        }, {})
                                    );

                                    setConnectionName(connection?.name);

                                    connectionRef.current = connection;
                                },
                                error: () => {}
                            })
                        );
                    }
                },
                errorCallback: () => {
                    console.log('fetch workspaces failed');
                }
            })
        );
    }, [dispatch, isEdit, id]);

    React.useEffect(() => {
        if (selectedSource) {
            setIsSheetLoading(true);
            dispatch(
                integrationActions.getFreshSheetsBySourceId({
                    sId: selectedSource?.value,
                    success: streams => {
                        setIsSheetLoading(false);

                        if (connectionRef.current) {
                            const foundSelectedSheet = streams?.find(
                                s => s?.config?.aliasName === connectionRef.current?.configuration?.selectedSheet
                            );

                            if (foundSelectedSheet) {
                                setSelectedSheet({
                                    ...foundSelectedSheet,
                                    value: foundSelectedSheet?.config?.aliasName,
                                    label: foundSelectedSheet?.stream?.name
                                });
                            }
                        }
                    },
                    error: () => {
                        setIsSheetLoading(false);
                        console.log('fetch sheetsBySourceId failed');
                    }
                })
            );
        }
    }, [dispatch, selectedSource]);

    const handleOpenAddGridModal = React.useCallback((onClickAway, setOpenState) => {
        setOpenNewGrid(true);
        setOpenState(null);
    }, []);

    const handleCloseAddGridModal = React.useCallback(() => {
        setOpenNewGrid(false);
    }, []);

    const stepContent = React.useMemo(() => {
        switch (activeStep) {
            case 0:
                return (
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography variant="body1">Select your data source type</Typography>
                                </Grid>
                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder={'Select source'}
                                        menuPlaceholder={'Find source'}
                                        options={sourceOptions}
                                        onChange={option => {
                                            if (option?.value !== selectedSource?.value) {
                                                setSelectedSource(option);
                                                setSelectedSheet(null);
                                            }
                                        }}
                                        defaultValue={selectedSource}
                                        isMulti={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Collapse in={selectedSource}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">{selectedSourceLabel}</Typography>
                                    </Grid>
                                    <Grid item>
                                        {isSheetLoading ? (
                                            <div className={classes.spinner}>
                                                <Spinner size={18} thick={3} />
                                            </div>
                                        ) : (
                                            <LDBasePortal
                                                ddPlaceholder={selectedSourceLabel}
                                                menuPlaceholder={'Find sheet'}
                                                options={sheetOptions}
                                                onChange={option => setSelectedSheet(option)}
                                                defaultValue={selectedSheet}
                                                isMulti={false}
                                                isLoading={isSheetLoading}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>

                        <Grid item style={{ position: 'relative', marginTop: 56 }}>
                            <div className="absolute top-[-80px] right-0 left-0 flex justify-center">
                                <NoSourceIllustratorSVG />
                            </div>
                            <Grid
                                className={classes.wrapper}
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={3}
                            >
                                <Grid item>
                                    <Typography variant="body2" align="center">
                                        Can't find the source you're looking for?
                                        <br />
                                        Instantly create a new source that fits your needs.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ButtonBase
                                        onClick={moveToCreateSource}
                                        tr-dt="Add Database"
                                        width={180}
                                        variant="outlined"
                                        size="large"
                                    >
                                        <AddIconSVG style={{ marginRight: 8, width: 10, height: 10 }} />
                                        Create a Source
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography variant="body1">Project</Typography>
                                </Grid>
                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder={'Select project'}
                                        menuPlaceholder={'Find project'}
                                        options={workspaceOptions}
                                        onChange={option => setSelectedProject(option)}
                                        defaultValue={selectedProject}
                                        isMulti={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Collapse in={selectedProject}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">Database</Typography>
                                    </Grid>
                                    <Grid item>
                                        <LDBasePortal
                                            ddPlaceholder={'Select database'}
                                            menuPlaceholder={'Find database'}
                                            options={databaseOptions}
                                            onChange={option => setSelectedDatabase(option)}
                                            defaultValue={selectedDatabase}
                                            isMulti={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                        <Grid item>
                            <Collapse in={selectedDatabase}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">Grid</Typography>
                                    </Grid>
                                    <Grid item>
                                        <LDBasePortal
                                            ddPlaceholder={'Select grid'}
                                            menuPlaceholder={'Select from list or enter name to create a new one'}
                                            options={gridOptions}
                                            onChange={option => {
                                                setSelectedGrid(option);
                                            }}
                                            isCreatable={true}
                                            createText="Add New Grid"
                                            onCreateNew={handleOpenAddGridModal}
                                            defaultValue={selectedGrid}
                                            isMulti={false}
                                            handleClickAway={handleCloseAddGridModal}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Grid container wrap="nowrap" direction="column" spacing={3}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography variant="body1">{t('connection_name')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        Give a remarkable name for your connection
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="name"
                                        value={connectionName || ''}
                                        height={40}
                                        onChange={e => setConnectionName(e?.target?.value)}
                                        placeholder={'Enter connection name'}
                                        autoFocus={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Typography variant="body1">Map columns:</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" wrap="nowrap" spacing={3}>
                                {sheetColumnOptions?.map(sheetOpt => {
                                    const sheetColumnNames = Object.keys(columnMappings);
                                    const selectedColumnId = columnMappings?.[sheetOpt?.value];
                                    return (
                                        <Grid item key={sheetOpt?.value}>
                                            <Grid container wrap="nowrap" direction="row" alignItems="center">
                                                <Grid item xs={5} className={classes.name}>
                                                    <Typography variant="body2">{sheetOpt?.label}</Typography>
                                                </Grid>
                                                <Grid item xs={2} alignItems="center" justify="center" container>
                                                    <PathSVG />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {selectedGrid.__isNew__ ? (
                                                        <LDBaseCreatablePortal
                                                            ddPlaceholder={'Select column'}
                                                            menuPlaceholder={
                                                                'Select from list or enter name to create a new one'
                                                            }
                                                            options={gridColumnOptions}
                                                            formatCreateLabel={inputValue => (
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                    className={classes.createLabel}
                                                                    wrap="nowrap"
                                                                >
                                                                    <Grid item style={{ display: 'flex' }}>
                                                                        <ColorAddSVG
                                                                            color={theme.palette.primary.main}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="body2">
                                                                            Create new column{' '}
                                                                            <strong>"{inputValue}"</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            onChange={option => {
                                                                if (option.__isNew__) {
                                                                    const isExisted = gridColumnOptions?.some(
                                                                        c =>
                                                                            c?.label?.toLowerCase() ===
                                                                            option?.label?.toLowerCase()
                                                                    );

                                                                    if (!isExisted) {
                                                                        setGridColumnOptions([
                                                                            ...gridColumnOptions,
                                                                            {
                                                                                ...option,
                                                                                icon: () => (
                                                                                    <ColumnIcon
                                                                                        {...{
                                                                                            type:
                                                                                                columnTypes.MULTIPLE_LINES
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }
                                                                        ]);
                                                                    }
                                                                }
                                                                for (const key of sheetColumnNames) {
                                                                    if (columnMappings?.[key] === option?.value) {
                                                                        delete columnMappings?.[key];
                                                                    }
                                                                }

                                                                setColumnMappings({
                                                                    ...columnMappings,
                                                                    [sheetOpt?.value]: option?.value
                                                                });
                                                            }}
                                                            defaultValue={
                                                                !selectedColumnId
                                                                    ? null
                                                                    : gridColumnOptions?.find(
                                                                          c => c?.value === selectedColumnId
                                                                      )
                                                            }
                                                            isMulti={false}
                                                        />
                                                    ) : (
                                                        <LDBasePortal
                                                            ddPlaceholder={'Select column'}
                                                            menuPlaceholder={'Find column'}
                                                            options={gridColumnOptions}
                                                            onChange={option => {
                                                                for (const key of sheetColumnNames) {
                                                                    if (columnMappings?.[key] === option?.value) {
                                                                        delete columnMappings?.[key];
                                                                    }
                                                                }

                                                                setColumnMappings({
                                                                    ...columnMappings,
                                                                    [sheetOpt?.value]: option?.value
                                                                });
                                                            }}
                                                            defaultValue={
                                                                !selectedColumnId
                                                                    ? null
                                                                    : gridColumnOptions?.find(
                                                                          c => c?.value === selectedColumnId
                                                                      )
                                                            }
                                                            isMulti={false}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                );

            default:
                return null;
        }
    }, [
        handleCloseAddGridModal,
        moveToCreateSource,
        selectedSourceLabel,
        connectionName,
        theme,
        columnMappings,
        sheetOptions,
        activeStep,
        selectedSheet,
        classes,
        selectedSource,
        sourceOptions,
        selectedDatabase,
        selectedGrid,
        selectedProject,
        databaseOptions,
        gridOptions,
        workspaceOptions,
        gridColumnOptions,
        sheetColumnOptions,
        isSheetLoading,
        handleOpenAddGridModal,
        t
    ]);

    return (
        <SettingContentLayout name={title} onBack={handleBack}>
            <div className={classes.root}>
                {isSubmitting && (
                    <Grid item className={`${classes.loading}`}>
                        <Spinner size={55} thick={7} />
                    </Grid>
                )}

                <Grid
                    container
                    className={classes.createWrapper}
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                >
                    <Grid item className={classes.formContent}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="flex-start"
                            wrap="nowrap"
                            style={{ height: 'auto', minHeight: '100%', border: `1px solid ${theme.colors.border}` }}
                        >
                            <Grid item className={classes.fullWidth}>
                                <Stepper alternativeLabel activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};

                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Grid>

                            <Grid item className={classes.my5}>
                                <Typography variant="h4">{getStepTitle(activeStep)}</Typography>
                            </Grid>

                            <Grid item className={`${classes.stepContent} ${classes.mb5}`}>
                                <div
                                    className={
                                        activeStep === steps?.length - 1 ? classes.smallPadding : classes.contentPadding
                                    }
                                >
                                    {stepContent}
                                </div>
                            </Grid>
                            <Grid item className={classes.footer}>
                                <Grid
                                    container
                                    style={{ width: '100%', height: '100%' }}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    spacing={2}
                                >
                                    {activeStep !== 0 && (
                                        <Grid item>
                                            <ButtonBase width={150} variant="outlined" onClick={handleBackStep}>
                                                {t('global_back')}
                                            </ButtonBase>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <ButtonBase
                                            disabled={!validateNext}
                                            width={150}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNextStep}
                                        >
                                            {activeStep === steps.length - 1 ? t('global_finish') : t('global_next')}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog maxWidth={false} open={openAddNewGrid} onClose={handleCloseAddGridModal}>
                    <LocalizationEmptySetup
                        onGridCreated={grid => {
                            setSelectedGrid({
                                value: grid?.id,
                                label: grid?.name,
                                icon: () => <GridIcon grid={grid} size="small" />
                            });
                        }}
                        isTranslationGrid={isTranslationGrid}
                        headers={headers}
                        isRedirect={false}
                        dbId={selectedDatabase?.value}
                        onClose={handleCloseAddGridModal}
                        defaultName={selectedSheet?.label}
                        totalRecord={0}
                    />
                </Dialog>
            </div>
        </SettingContentLayout>
    );
}

export default Connection;
