import React from 'react';
import { useDispatch } from 'react-redux';
import SettingContentLayout from '../common/Layout';
import ApiKeyTable from './ApiKeyTable';
import CreateApiKey from './CreateApiKey';
import * as permissionActions from '../actions';
import { useTranslation } from 'react-i18next';

const state = {
    LIST: 'LIST',
    CREATE: 'CREATE'
};

function ApiKeys() {
    const [currentState, setCurrentState] = React.useState(state.LIST);
    const { t } = useTranslation();
    const handleBack = () => {
        setCurrentState(state.LIST);
        setEditedApiKeyId(null);
        setIsDuplicating(false);
    };
    const handleCreateClick = () => setCurrentState(state.CREATE);
    const dispatch = useDispatch();
    const [editedApiKeyId, setEditedApiKeyId] = React.useState(null);
    const [isDuplicating, setIsDuplicating] = React.useState(null);

    const handleEdit = apiKeyId => {
        setEditedApiKeyId(apiKeyId);
        setCurrentState(state.CREATE);
    };

    const handleDuplicate = apiKeyId => {
        setIsDuplicating(true);
        setEditedApiKeyId(apiKeyId);
        setCurrentState(state.CREATE);
    };

    React.useEffect(() => {
        dispatch(
            permissionActions.fetchApiKeys({
                successCallback: () => {
                    console.log('fetch api keys success');
                },
                errorCallback: () => {
                    console.log('fetch api keys failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            permissionActions.fetchWorkspacePrivileges({
                successCallback: () => {
                    console.log('fetch Privileges success');
                },
                errorCallback: () => {
                    console.log('fetch Privileges  failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            permissionActions.fetchCompanyGroups({
                successCallback: () => {
                    console.log('fetch companyGroups success');
                },
                errorCallback: () => {
                    console.log('fetch companyGroups failed');
                }
            })
        );
    }, [dispatch]);

    const content = React.useCallback(() => {
        switch (currentState) {
            case state.LIST:
                return (
                    <ApiKeyTable onCreateClick={handleCreateClick} onDuplicate={handleDuplicate} onEdit={handleEdit} />
                );
            case state.CREATE:
                return (
                    <CreateApiKey isDuplicating={isDuplicating} onBack={handleBack} editedApiKeyId={editedApiKeyId} />
                );
            default:
                return null;
        }
    }, [currentState, editedApiKeyId, isDuplicating]);

    return (
        <SettingContentLayout
            contentClassName={'content-medium'}
            name={
                currentState === state.CREATE
                    ? editedApiKeyId
                        ? isDuplicating
                            ? t('duplicate_api_key')
                            : t('edit_api_key')
                        : t('create_api_key')
                    : t('api_keys')
            }
            height={'auto'}
        >
            {content()}
        </SettingContentLayout>
    );
}

export default React.memo(ApiKeys);
