import React from 'react';
import Spinner from 'components/spinner/Base';
import DoneSVG from 'assets/images/svg/DoneSVG';
import { useTranslation } from 'react-i18next';

function ActionStatus({ doingActionIds }) {
    //1 -> loading, 2 -> all done , 3 => null
    const { t } = useTranslation();
    const [status, setStatus] = React.useState(3);
    const isFirstLoadRef = React.useRef(false);
    let timerRef = React.useRef();

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        if (doingActionIds.length) {
            setStatus(1);
            if (!isFirstLoadRef.current) {
                isFirstLoadRef.current = true;
            }
        } else {
            if (isFirstLoadRef.current) {
                setStatus(2);
                timerRef.current = setTimeout(() => {
                    setStatus(3);
                }, 1000);
            } else {
                setStatus(3);
            }
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [doingActionIds.length]);

    if (status === 3) return null;

    if (status === 2)
        return (
            <div className="flex flex-row gap-2 items-center">
                <p className="caption">{t('saving')}...</p>
                <Spinner size={20} thick={3} />
            </div>
        );

    return (
        <div className="flex flex-row gap-2 items-center">
            <p className="caption"> {t('global_done')}</p>
            <DoneSVG size={20} />
        </div>
    );
}

export default React.memo(ActionStatus);
