import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { POPUP_PADDING_TOP_BOTTOM } from 'const/style';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ExportSVG from 'assets/images/svg/ExportSVG';
import CleanSVG from 'assets/images/svg/CleanSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import ListItem from 'components/list/Item';
import * as tmActions from './actions';
import trim from 'lodash/trim';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM
    },

    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        marginBottom: theme.spacing(2)
    },
    input: {
        backgroundColor: theme.colors.paleGrey,
        borderRadius: 4,
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                boxShadow: 'none'
            }
        }
    },
    footer: {
        borderTop: `1px solid ${theme.colors.divider}`,
        paddingTop: theme.spacing(2)
    },
    listItem: {
        '& .textContent': {
            color: theme.colors.snackBarWarning,
            fontWeight: 500
        }
    }
}));

function TMPopup({ name, id, handleClickAway, tm, isDefault, onDelete, onClean, onExport, onEditor, goToTmEditor }) {
    const [tmName, setTMName] = useState(name);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    const clickAwayHandler = () => {
        if (trim(tmName) === '') {
            handleClickAway();
            return;
        }
        if (tmName === name) {
            handleClickAway();
            return;
        }

        dispatch(
            tmActions.updateTM({
                newTM: {
                    ...tm,
                    name: tmName
                },
                oldTM: tm,
                successCallback: () => {
                    console.log('update success');
                },
                errorCallback: error => {
                    console.log('error update');
                }
            })
        );
        handleClickAway();
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };

    return (
        <Grid ref={rootRef} id={`tm_popup`} container direction="column" className={classes.root}>
            <Grid item className={classes.name}>
                <InputText
                    id={`dbInput`}
                    name="tmName"
                    value={tmName}
                    onChange={e => setTMName(e.target.value)}
                    placeholder="Name"
                    onKeyDown={handleKeyDown}
                    autoFocus
                    className={classes.input}
                    autoSelect={true}
                />
            </Grid>
            <Grid item>
                <ListItem onClick={onExport} icon={<ExportSVG />} name={'Export TM'} />
            </Grid>

            <Grid item>
                <ListItem onClick={onClean} icon={<CleanSVG />} name={'Clean TM'} />
            </Grid>
            <Grid item>
                <ListItem onClick={onEditor} icon={<EditSVG />} name={'Open TM Editor'} />
            </Grid>
            {!tm?.isDefault && (
                <Grid item className={classes.footer}>
                    <ListItem
                        className={`${classes.listItem}`}
                        onClick={onDelete}
                        icon={<DeleteSVG color={theme.colors.snackBarWarning} />}
                        name={'Delete TM'}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(TMPopup);
