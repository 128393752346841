import React from 'react';

function MagicWandSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip-magic)">
                <path
                    d="M8.3867 12.1986C8.23674 12.1986 8.08678 12.1414 7.9724 12.027C7.74357 11.7982 7.74357 11.4272 7.9724 11.1983L13.8878 5.28292C14.1166 5.05413 14.4876 5.05413 14.7164 5.28292C14.9452 5.51171 14.9452 5.88272 14.7164 6.11155L8.801 12.027C8.68662 12.1414 8.53666 12.1986 8.3867 12.1986Z"
                    fill="#FFB454"
                />
                <path
                    d="M7.97269 12.0273C8.0871 12.1417 8.23706 12.1989 8.38698 12.1989C8.5369 12.1989 8.6869 12.1417 8.80128 12.0273L14.7166 6.11184C14.9455 5.88305 14.9455 5.51203 14.7166 5.2832L7.97269 12.0273C7.97265 12.0273 7.97265 12.0273 7.97269 12.0273Z"
                    fill="#FF8659"
                />
                <path
                    d="M15.1121 8.88829C15.0816 8.88829 15.051 8.88591 15.0203 8.88107C14.8 8.84615 14.6186 8.68923 14.5524 8.47626L13.8343 6.16587L11.5238 5.44771C11.3109 5.3815 11.154 5.20013 11.119 4.97982C11.0842 4.75951 11.1774 4.53853 11.3594 4.40974L13.3348 3.01275L13.3039 0.593451C13.301 0.370443 13.4251 0.165131 13.6238 0.0638806C13.8225 -0.0374475 14.0615 -0.0169788 14.2402 0.116381L16.1792 1.56337L18.4705 0.78638C18.6818 0.714662 18.9153 0.769232 19.073 0.926966C19.2308 1.08466 19.2853 1.31826 19.2136 1.52947L18.4366 3.82076L19.8836 5.75978C20.017 5.93853 20.0374 6.17751 19.9361 6.37622C19.8349 6.57497 19.6296 6.69935 19.4066 6.69611L16.9873 6.66521L15.5903 8.64056C15.4795 8.79743 15.3003 8.88829 15.1121 8.88829Z"
                    fill="#FFDD54"
                />
                <path
                    d="M0.343242 19.6575C-0.114414 19.1998 -0.114414 18.4578 0.343242 18.0002L7.38668 10.9568C7.61551 10.7279 7.98649 10.7279 8.21531 10.9568L9.04395 11.7854C9.27277 12.0142 9.27277 12.3852 9.04395 12.614L2.00051 19.6575C1.54285 20.1151 0.800898 20.1151 0.343242 19.6575Z"
                    fill="#6B61B1"
                />
                <path
                    d="M19.4141 12.5H18.8281V11.9141C18.8281 11.5905 18.5658 11.3281 18.2422 11.3281C17.9186 11.3281 17.6562 11.5905 17.6562 11.9141V12.5H17.0703C16.7467 12.5 16.4844 12.7623 16.4844 13.0859C16.4844 13.4095 16.7467 13.6719 17.0703 13.6719H17.6562V14.2578C17.6562 14.5814 17.9186 14.8438 18.2422 14.8438C18.5658 14.8438 18.8281 14.5814 18.8281 14.2578V13.6719H19.4141C19.7377 13.6719 20 13.4095 20 13.0859C20 12.7623 19.7377 12.5 19.4141 12.5Z"
                    fill="#FFB454"
                />
                <path
                    d="M13.5547 13.0859C13.8783 13.0859 14.1406 12.8236 14.1406 12.5C14.1406 12.1764 13.8783 11.9141 13.5547 11.9141C13.2311 11.9141 12.9688 12.1764 12.9688 12.5C12.9688 12.8236 13.2311 13.0859 13.5547 13.0859Z"
                    fill="#FFB454"
                />
                <path
                    d="M18.2422 9.96094C18.5658 9.96094 18.8281 9.6986 18.8281 9.375C18.8281 9.0514 18.5658 8.78906 18.2422 8.78906C17.9186 8.78906 17.6562 9.0514 17.6562 9.375C17.6562 9.6986 17.9186 9.96094 18.2422 9.96094Z"
                    fill="#FFB454"
                />
                <path
                    d="M8.04688 6.64062H7.46094V6.05469C7.46094 5.73109 7.19859 5.46875 6.875 5.46875C6.55141 5.46875 6.28906 5.73109 6.28906 6.05469V6.64062H5.70312C5.37953 6.64062 5.11719 6.90297 5.11719 7.22656C5.11719 7.55016 5.37953 7.8125 5.70312 7.8125H6.28906V8.39844C6.28906 8.72203 6.55141 8.98438 6.875 8.98438C7.19859 8.98438 7.46094 8.72203 7.46094 8.39844V7.8125H8.04688C8.37047 7.8125 8.63281 7.55016 8.63281 7.22656C8.63281 6.90297 8.37047 6.64062 8.04688 6.64062Z"
                    fill="#FFDD54"
                />
                <path
                    d="M10.7812 1.17188H10.1953V0.585938C10.1953 0.262344 9.93297 0 9.60938 0C9.28578 0 9.02344 0.262344 9.02344 0.585938V1.17188H8.4375C8.11391 1.17188 7.85156 1.43422 7.85156 1.75781C7.85156 2.08141 8.11391 2.34375 8.4375 2.34375H9.02344V2.92969C9.02344 3.25328 9.28578 3.51562 9.60938 3.51562C9.93297 3.51562 10.1953 3.25328 10.1953 2.92969V2.34375H10.7812C11.1048 2.34375 11.3672 2.08141 11.3672 1.75781C11.3672 1.43422 11.1048 1.17188 10.7812 1.17188Z"
                    fill="#FFDD54"
                />
                <path
                    d="M5.50781 4.10156C5.83142 4.10156 6.09375 3.83923 6.09375 3.51562C6.09375 3.19202 5.83142 2.92969 5.50781 2.92969C5.18421 2.92969 4.92188 3.19202 4.92188 3.51562C4.92188 3.83923 5.18421 4.10156 5.50781 4.10156Z"
                    fill="#FFDD54"
                />
                <path
                    d="M13.834 6.16652L14.5521 8.47691C14.6183 8.68988 14.7997 8.84684 15.02 8.88172C15.0507 8.88656 15.0813 8.88895 15.1118 8.88895C15.3 8.88895 15.4792 8.79809 15.5901 8.64133L16.9871 6.66598L19.4064 6.69688C19.6293 6.70012 19.8347 6.5757 19.9359 6.37699C20.0372 6.17828 20.0168 5.9393 19.8834 5.76055L18.4364 3.82152L19.2134 1.53023C19.285 1.31902 19.2306 1.08543 19.0729 0.927734L13.834 6.16652Z"
                    fill="#FFB454"
                />
                <path
                    d="M0.343262 19.6575C0.800918 20.1152 1.54291 20.1152 2.00053 19.6575L9.04396 12.6141C9.27279 12.3852 9.27279 12.0143 9.04396 11.7854L8.62963 11.3711L0.343262 19.6575Z"
                    fill="#453D81"
                />
            </g>
            <defs>
                <clipPath id="clip-magic">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(MagicWandSVG);
