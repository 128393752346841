import React from 'react';
import { Divider } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { useDispatch } from 'react-redux';
import * as gridUIActions from './actions';
import { sendManualTrack } from 'tracker';
import { FILL_COLOR_OPTIONS } from 'const/gridUI';
import { useTranslation } from 'react-i18next';

function FillColorMenu({ closeHandler, selectedCellColor, fillColorBlindness }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const changeColorHandler = color => {
        sendManualTrack({
            type: `Click On Color`,
            customData: {
                color
            }
        });
        if (color !== selectedCellColor || (!color && color === selectedCellColor)) {
            dispatch(gridUIActions.setCurrentCellColor({ color }));
            dispatch(
                gridUIActions.setCellsColor({
                    color
                })
            );
        }
        closeHandler && closeHandler();
    };

    return (
        <div id={'fill-color-panel'} className={'w-[300px] flex flex-col'}>
            <div
                item
                className={'cursor-pointer px-5 pt-1.5 pb-3.5 flex flex-row items-center flex-nowrap gap-2'}
                onClick={() => changeColorHandler()}
            >
                <span
                    className={
                        'w-6 h-6 border border-grey-border bg-white flex justify-center items-center rounded-full'
                    }
                ></span>
                <div className="caption">{t('no_fill_color')}</div>
            </div>
            <div>
                <Divider />
            </div>
            <div className="px-5 pb-1.5 pt-3.5 grid grid-cols-8 gap-2">
                {Object.keys(FILL_COLOR_OPTIONS).map(hex => {
                    const image = FILL_COLOR_OPTIONS?.[hex];
                    return (
                        <span
                            key={hex}
                            onClick={() => changeColorHandler(hex)}
                            className={'w-6 h-6 flex justify-center items-center rounded-full cursor-pointer'}
                            style={{
                                background: fillColorBlindness === 'BLIND' ? `${hex} url(${image})` : `${hex}`
                            }}
                        >
                            {hex === selectedCellColor && <CheckIconSVG />}
                        </span>
                    );
                })}
            </div>
        </div>
    );
}

export default React.memo(FillColorMenu);
