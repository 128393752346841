import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto',
        height: '100%',
        padding: theme.spacing(5)
    },
    dlFlex: {
        display: 'flex'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

function AppLayout({ children, onBack }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container wrap="nowrap" direction="column" className={classes.root}>
            <Grid item>
                <Grid
                    onClick={onBack}
                    container
                    className={classes.pointer}
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <Grid item className={classes.dlFlex}>
                        <ArrowLeftSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('addon_title')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>{children}</Grid>
        </Grid>
    );
}

export default React.memo(AppLayout);
