import React from 'react';

function LinkSVG({ color = 'white', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.2255 6.5573C3.59762 6.5573 2.99546 6.80673 2.55148 7.2507C2.10751 7.69468 1.85808 8.29684 1.85808 8.92472C1.85808 9.5526 2.10751 10.1548 2.55148 10.5987C2.77132 10.8186 3.0323 10.993 3.31953 11.1119C3.60676 11.2309 3.91461 11.2921 4.2255 11.2921H6.11314C6.54318 11.2921 6.89179 11.6407 6.89179 12.0708C6.89179 12.5008 6.54318 12.8494 6.11314 12.8494H4.2255C3.7101 12.8494 3.19974 12.7479 2.72358 12.5507C2.24741 12.3535 1.81475 12.0644 1.4503 11.6999C0.714277 10.9639 0.300781 9.96562 0.300781 8.92472C0.300781 7.88382 0.714277 6.88555 1.4503 6.14952C2.18633 5.4135 3.1846 5 4.2255 5H6.11314C6.54318 5 6.89179 5.34861 6.89179 5.77865C6.89179 6.20869 6.54318 6.5573 6.11314 6.5573H4.2255ZM9.10977 5.77865C9.10977 5.34861 9.45838 5 9.88842 5H11.7761C12.2915 5 12.8018 5.10152 13.278 5.29875C13.7542 5.49599 14.1868 5.78508 14.5513 6.14952C14.9157 6.51397 15.2048 6.94663 15.402 7.42279C15.5993 7.89896 15.7008 8.40932 15.7008 8.92472C15.7008 9.44012 15.5993 9.95047 15.402 10.4266C15.2048 10.9028 14.9157 11.3355 14.5513 11.6999C14.1868 12.0644 13.7542 12.3535 13.278 12.5507C12.8018 12.7479 12.2915 12.8494 11.7761 12.8494H9.88842C9.45838 12.8494 9.10977 12.5008 9.10977 12.0708C9.10977 11.6407 9.45838 11.2921 9.88842 11.2921H11.7761C12.087 11.2921 12.3948 11.2309 12.682 11.1119C12.9693 10.993 13.2302 10.8186 13.4501 10.5987C13.6699 10.3789 13.8443 10.1179 13.9633 9.83069C14.0822 9.54346 14.1435 9.23561 14.1435 8.92472C14.1435 8.61383 14.0822 8.30598 13.9633 8.01875C13.8443 7.73152 13.6699 7.47054 13.4501 7.2507C13.2302 7.03087 12.9693 6.85649 12.682 6.73751C12.3948 6.61854 12.087 6.5573 11.7761 6.5573H9.88842C9.45838 6.5573 9.10977 6.20869 9.10977 5.77865Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.70703 8.92318C4.70703 8.49315 5.05565 8.14453 5.48568 8.14453H10.5194C10.9494 8.14453 11.298 8.49315 11.298 8.92318C11.298 9.35322 10.9494 9.70183 10.5194 9.70183H5.48568C5.05565 9.70183 4.70703 9.35322 4.70703 8.92318Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(LinkSVG);
