import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width,
        height: props => props.height
    }
}));

const SIZES = {
    small: {
        height: 16,
        width: 16
    },
    medium: {
        width: 24,
        height: 24
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function ArrowLeftSVG({ color = '#ACADB9', className, size = 'small', ...other }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height });
    return (
        <svg
            className={`${classes.root} ${className}`}
            {...other}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.2333 4.39773L15.8584 3L7 11.7069L15.8584 20.4139L17.2333 19.0162L9.79628 11.7059L17.2333 4.39773Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowLeftSVG);
