import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import { INPUT_HEIGHT } from 'const/style';
import BaseTimePickerInput from 'components/datetime/BaseTimePickerInput';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    datetime: {
        height: INPUT_HEIGHT,
        border: `1px solid ${theme.colors.border}`,
        padding: `6px ${theme.spacing(4)}px 6px ${theme.spacing(3)}px`,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        cursor: 'pointer',
        width: 'fix-content'
    }
}));

function TimePicker({ defaultValue, onChange, checked }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [date, time] = React.useMemo(() => {
        if (!defaultValue || defaultValue?.length === 0) return [null, null];

        return formatDateTimeLocal(defaultValue, true);
    }, [defaultValue]);

    const CustomInput = React.useMemo(() => {
        return React.forwardRef(({ value, onClick }, ref) =>
            !date || !time ? (
                <Grid
                    container
                    className={classes.datetime}
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    onClick={onClick}
                    ref={ref}
                >
                    <Typography variant="caption" style={{ color: checked ? '#2A293A' : '#ACADB9' }}>
                        {formatDateTimeLocal(defaultValue, true)} <strong>UTC</strong>
                    </Typography>
                </Grid>
            ) : (
                <Grid
                    container
                    className={classes.datetime}
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    onClick={onClick}
                    ref={ref}
                >
                    <Grid item>
                        <Typography
                            className={`${classes.dateItem}`}
                            variant="body2"
                            style={{ color: checked ? '#2A293A' : '#ACADB9' }}
                        >
                            {time} <strong>UTC</strong>
                        </Typography>
                    </Grid>
                </Grid>
            )
        );
    }, [date, time, classes, defaultValue, checked]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <BaseTimePickerInput
                    placeholder={t('global_label_pick_a_date')}
                    selectedDate={defaultValue}
                    utcOffset={0}
                    isShowTimeSelect={true}
                    onEnter={date => onChange(date)}
                    isClearable
                    className={classes.datetime}
                    customInput={<CustomInput />}
                    popperPlacement="bottom"
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                />
            </Grid>
        </Grid>
    );
}

export default TimePicker;
