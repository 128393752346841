import React, { useCallback, useMemo, useRef } from 'react';
import { Grid, TableRow, Typography, TableHead, TableCell, TableContainer, Table, TableBody } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { AutoSizer } from 'react-virtualized-dn';
import ThreeStateCheckBox from 'components/checkbox/ThreeStateCheckBox';
import PaginationBase from 'components/pagination/Base';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import Spinner from 'components/spinner/Base';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import { useListTranslationMem, useTmSourceSelected, useTmTargetsSelected } from 'hooks/tm';
import { useParams } from 'react-router-dom';
import TargetCell from './TargetCell';
import { LOCALIZATION_FONT } from 'const/gridUI';
// import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
// import Dialog from 'components/dialog';
// import AddSegment from './AddSegment';

const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        '0%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        }
    },
    root: {
        height: 'calc(80vh - 260px)',
        width: '100%',
        overflowX: 'hidden',
        '& *::-webkit-scrollbar': {
            width: 12,
            height: 12
        },
        '& *::-webkit-scrollbar-track': {
            backgroundColor: `#F7F8FA`
        },
        '& *::-webkit-scrollbar-thumb': {
            backgroundColor: `#D7D7DC`,
            borderRadius: 10,
            border: `2px solid #F7F8FA`,
            height: 30
        },
        '& *::-webkit-scrollbar-button': {
            display: `none`
        }
    },
    cellTarget: {
        position: 'relative',
        verticalAlign: 'middle',
        minWidth: 500,
        maxWidth: 500
    },
    rowData: {
        width: '100%'
    },
    isSelected: {
        background: `rgba(45, 151, 242, 0.1)`,
        transition: 'all 500ms'
    },
    highlight: {
        color: theme.colors.lightLavender
    },
    dlFlex: {
        display: 'flex'
    },
    addAlternative: {
        // position: 'absolute',
        // bottom: 4,
        // left: -30,
        animation: '$blinker 1s linear',
        cursor: 'pointer',
        // zIndex: 1,
        width: 'auto',
        padding: 4
    },
    pagination: {
        margin: '20px 0'
    },
    empty: {
        overflow: 'hidden'
    }
}));

const CellTexts = ({ dataRow, unitId, lang, onRestTable, tmId, isChecked, sourceText }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const areaRef = useRef();
    const source = useTmSourceSelected() || null;

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[source?.value];
    }, [source]);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    useClickAwaitListener(areaRef, e => {
        handleClickAway(e);
    });

    const sourceData = React.useMemo(() => {
        return dataRow?.translations?.filter(s => s.lang === dataRow?.sourceLang);
    }, [dataRow]);

    const _renderSource = React.useCallback(() => {
        return sourceData?.map((_source, i) => {
            return (
                lang === _source?.lang && (
                    <Grid container alignItems="center" key={i}>
                        <Grid item className={`${classes.rowData} ${fontJP}`}>
                            <Typography
                                variant="body2"
                                style={{
                                    background: '#F7F8FA',
                                    padding: 12,
                                    maxHeight: '150px',
                                    overflow: 'auto',
                                    borderRadius: 4,
                                    width: '100%'
                                }}
                            >
                                {_source?.texts[0]}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            );
        });
    }, [classes.rowData, lang, sourceData]);

    return (
        <TableCell
            className={`
            ${classes.cellTarget}`}
            ref={areaRef}
            onClick={handleClick}
            onMouseEnter={handleClick}
            onMouseLeave={handleClickAway}
        >
            {_renderSource()}
            <TargetCell
                sourceText={sourceText}
                isChecked={isChecked}
                onRestTable={onRestTable}
                lang={lang}
                unitId={unitId}
                dataRow={dataRow}
                isHover={anchorEl}
            />
        </TableCell>
    );
};

const EditorTmRow = ({ dataRow, tmId, onRestTable, selectedRows, handleCheckRow, columns }) => {
    const classes = useStyles();
    const theme = useTheme();

    const sourceText = React.useMemo(() => {
        let text;
        text = dataRow?.translations?.filter(s => {
            return s?.lang === dataRow?.sourceLang;
        });
        return text[0].texts[0];
    }, [dataRow]);

    const isChecked = useMemo(() => {
        return selectedRows.includes(dataRow?.id);
    }, [dataRow.id, selectedRows]);

    return (
        <TableRow
            role="checkbox"
            style={{
                ...(isChecked
                    ? {
                          background: '#E0EFFD'
                      }
                    : {
                          background: theme.palette.common.white
                      })
            }}
        >
            <TableCell style={{ verticalAlign: 'middle' }}>
                <ThreeStateCheckBox
                    className={classes.checkbox}
                    state={isChecked ? 1 : 2}
                    onChange={e => handleCheckRow(dataRow?.id)}
                />
            </TableCell>
            {columns?.map(_column => {
                return (
                    <CellTexts
                        sourceText={sourceText}
                        lang={_column.value}
                        key={_column?.dataKey}
                        onRestTable={onRestTable}
                        tmId={tmId}
                        isChecked={isChecked}
                        unitId={dataRow?.id}
                        dataRow={dataRow}
                    />
                );
            })}
        </TableRow>
    );
};

const EditorTmDetail = ({
    // dataTransMems,
    onChangePage,
    onRestTable,
    limit,
    currentPage,
    selectedRows,
    setSelectedRows,
    // totalSegments,
    isFetching
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const { tmId } = useParams();
    const source = useTmSourceSelected() || null;
    const targets = useTmTargetsSelected() || [];
    const { list, totalSegment } = useListTranslationMem() || [];

    const columnsTarget = useMemo(() => {
        return targets.map(target => {
            return {
                label: (
                    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                        <Grid item className={classes.icon}>
                            {target?.icon()}
                        </Grid>
                        <Grid item>
                            {target?.languageCountry} - {target?.value}
                        </Grid>
                    </Grid>
                ),
                dataKey: target?.value,
                minWidth: 350,
                className: classes.cellName,
                value: target?.value
            };
        });
    }, [classes.cellName, classes.icon, targets]);

    const COLUMNS = useMemo(() => {
        return [
            {
                label: (
                    <Grid container justifyContent="center" alignItems="center" wrap="nowrap" spacing={2}>
                        <Grid item className={classes.icon}>
                            {source?.icon()}
                        </Grid>
                        <Grid item>
                            {source?.languageCountry} - {source?.value}
                        </Grid>
                    </Grid>
                ),
                dataKey: source?.value,
                minWidth: 350,
                className: classes.cellName,
                value: source?.value
            },
            ...columnsTarget?.map(column => column)
        ];
    }, [classes.icon, classes.cellName, source, columnsTarget]);

    const renderLoading = useMemo(() => {
        if (isFetching) {
            return (
                <Grid container className={classes.empty} justify="center" alignItems="center" direction="column">
                    <Spinner size={30} thick={4} />
                </Grid>
            );
        }
        return null;
    }, [isFetching, classes]);

    const renderTableHeader = useCallback(() => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell className={classes.whiteBackground} />

                    {COLUMNS.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                className={classes.whiteBackground}
                            >
                                {column.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    }, [COLUMNS, classes.whiteBackground]);

    const renderEmpty = useMemo(() => {
        if ((!isFetching && !list.length) || targets.length < 1) {
            return (
                <Grid
                    container
                    className={classes.empty}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid item>
                        <EmptyBoxSVG width="252" height="196" />
                    </Grid>
                    <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">Nothing here yet</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                Please select the <strong>source</strong> and <strong>target</strong> language to define
                                search range.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }, [isFetching, list, classes, targets]);

    const handleCheckRow = useCallback(
        id => {
            if (selectedRows.includes(id)) {
                const newSelectedRows = selectedRows.filter(r => r !== id);
                setSelectedRows(newSelectedRows);
                return;
            }
            selectedRows.push(id);
            setSelectedRows([...selectedRows]);
        },
        [selectedRows, setSelectedRows]
    );

    return (
        <>
            <Grid container className={classes.root} direction="row">
                {renderLoading}
                {renderEmpty}
                {list?.length > 0 && targets.length > 0 && (
                    <AutoSizer>
                        {({ width, height }) => (
                            <TableContainer
                                style={{
                                    height,
                                    position: 'relative',
                                    ...(isFetching
                                        ? { overflow: 'hidden' }
                                        : !list?.length
                                        ? {
                                              overflowY: 'hidden'
                                          }
                                        : {}),
                                    width,
                                    border: `1px solid ${theme.colors.border}`,
                                    borderRadius: 4
                                }}
                            >
                                {renderLoading}
                                <Table stickyHeader aria-label="sticky table">
                                    {renderTableHeader()}
                                    <TableBody>
                                        {renderEmpty}

                                        {list?.map(segment => (
                                            <EditorTmRow
                                                key={segment?.id}
                                                onRestTable={onRestTable}
                                                handleCheckRow={handleCheckRow}
                                                tmId={tmId}
                                                dataRow={segment}
                                                columns={COLUMNS}
                                                selectedRows={selectedRows}
                                                setSelectedRows={setSelectedRows}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </AutoSizer>
                )}
            </Grid>
            <Grid container justifyContent="center" className={classes.pagination}>
                {totalSegment > 0 && targets.length > 0 && !isFetching && (
                    <Grid item>
                        <PaginationBase
                            count={Math.ceil(totalSegment / limit)}
                            page={currentPage}
                            onChange={onChangePage}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default React.memo(EditorTmDetail);
