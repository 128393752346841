export const SUPPORTED_LANGUAGES = [
    { id: 'en', name: 'English', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'enUS',
        name: 'English (United States)',
        recommended: true,
        rtl: false,
        accentInsensitive: false,
        latinize: false,
        extraMatches: ['EN English', 'Source Language Text']
    },
    { id: 'ar', name: 'Arabic', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    {
        id: 'arSA',
        name: 'Arabic (Saudi Arabia)',
        recommended: true,
        rtl: true,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'frFR', name: 'French (France)', recommended: true, rtl: false, accentInsensitive: true, latinize: true },
    {
        id: 'zhCN',
        name: 'Chinese (Simplified)',
        recommended: true,
        rtl: false,
        accentInsensitive: false,
        latinize: false,
        extraMatches: [
            'Simplified Chinese text',
            `Chinese Simplified`,
            `Simplified Chinese`,
            `Chinese Simplified text`,
            `Chinese (Simplified) Text`
        ]
    },
    {
        id: 'zhTW',
        name: 'Chinese (Traditional, Taiwan)',
        recommended: true,
        rtl: false,
        accentInsensitive: true,
        latinize: false,
        extraMatches: [
            'Traditional Chinese text',
            `Traditional Chinese`,
            `Chinese Traditional`,
            `Chinese Traditional text`,
            `Chinese (Trad) Text`,
            `Chinese (Traditional)`
        ]
    },
    {
        id: 'zhHK',
        name: 'Chinese (Traditional, Hong Kong)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'deDE', name: 'German (Germany)', recommended: true, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'itIT', name: 'Italian (Italy)', recommended: true, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ja', name: 'Japanese', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'jaJP', name: 'Japanese', recommended: true, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'ko', name: 'Korean', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'koKR', name: 'Korean', recommended: true, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'pl', name: 'Polish', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'plPL', name: 'Polish', recommended: true, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'ptBR',
        name: 'Portuguese (Brazil)',
        recommended: true,
        rtl: false,
        accentInsensitive: false,
        latinize: true,
        extraMatches: [
            'Portuguese Brazilian text',
            `Portuguese Brazilian`,
            `Portuguese (Brazil) Text`,
            `Brazilian Portuguese`,
            `Brazilian Portuguese text`,
            `PortugueseBR Text`
        ]
    },
    { id: 'ru', name: 'Russian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ruRU', name: 'Russian', recommended: true, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'es', name: 'Spanish', recommended: false, rtl: false, accentInsensitive: true, latinize: true },
    { id: 'esES', name: 'Spanish (Spain)', recommended: true, rtl: false, accentInsensitive: true, latinize: true },
    {
        id: 'esMX',
        name: 'Spanish (Mexico)',
        recommended: true,
        rtl: false,
        accentInsensitive: true,
        latinize: true,
        extraMatches: ['Spanish Mexican', 'Mexican Spanish text', 'Spanish Mexican text', 'Mexican Spanish']
    },
    {
        id: 'esLA',
        name: 'Spanish (Latin American)',
        recommended: true,
        rtl: false,
        accentInsensitive: true,
        latinize: true,
        extraMatches: [`Spanish (latam) text`, `Spanish (Latam)`]
    },
    { id: 'ca', name: 'Catalan', recommended: false, rtl: false, accentInsensitive: false, latinize: true },
    { id: 'caES', name: 'Catalan', recommended: true, rtl: false, accentInsensitive: false, latinize: true },
    { id: 'bn', name: 'Bengali', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'bnBD', name: 'Bengali', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'bg', name: 'Bulgarian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'bgBG', name: 'Bulgarian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'af', name: 'Afrikaans', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'afZA',
        name: 'Afrikaans (South Africa)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'arAE', name: 'Arabic (U.A.E.)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arBH', name: 'Arabic (Bahrain)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arDZ', name: 'Arabic (Algeria)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arEG', name: 'Arabic (Egypt)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arIQ', name: 'Arabic (Iraq)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arJO', name: 'Arabic (Jordan)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arKW', name: 'Arabic (Kuwait)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arLB', name: 'Arabic (Lebanon)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arLY', name: 'Arabic (Libya)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arMA', name: 'Arabic (Morocco)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arOM', name: 'Arabic (Oman)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arQA', name: 'Arabic (Qatar)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arSY', name: 'Arabic (Syria)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arTN', name: 'Arabic (Tunisia)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'arYE', name: 'Arabic (Yemen)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'az', name: 'Azeri (Latin)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'azAZ', name: 'Azeri (Azerbaijan)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'be', name: 'Belarusian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'beBY',
        name: 'Belarusian (Belarus)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'bs', name: 'Bosnian', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    {
        id: 'bsBA',
        name: 'Bosnian (Bosnia and Herzegovina)',
        recommended: true,
        rtl: true,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'cs', name: 'Czech', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'csCZ',
        name: 'Czech (Czech Republic)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'cy', name: 'Welsh', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'cyGB',
        name: 'Welsh (United Kingdom)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'da', name: 'Danish', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'daDK', name: 'Danish (Denmark)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'de', name: 'German', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'deAT', name: 'German (Austria)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'deCH',
        name: 'German (Switzerland)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'deLI',
        name: 'German (Liechtenstein)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'deLU',
        name: 'German (Luxembourg)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'dv', name: 'Divehi', recommended: false, rtl: true, accentInsensitive: false, latinize: false },
    { id: 'dvMV', name: 'Divehi (Maldives)', recommended: false, rtl: true, accentInsensitive: false, latinize: false },
    { id: 'el', name: 'Greek', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'elGR', name: 'Greek (Greece)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'enAU',
        name: 'English (Australia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false,
        extraMatches: [`English (Australia) text`, `English (AU)`]
    },
    { id: 'enBZ', name: 'English (Belize)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'enCA', name: 'English (Canada)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'enGB',
        name: 'English (United Kingdom)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false,
        extraMatches: [`English (GB) text`, `English (GB)`, `English (UK) text`]
    },
    {
        id: 'enIE',
        name: 'English (Ireland)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'enJM',
        name: 'English (Jamaica)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'enNZ',
        name: 'English (New Zealand)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'enPH',
        name: 'English (Republic of the Philippines)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'enTT',
        name: 'English (Trinidad and Tobago)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'enZA',
        name: 'English (South Africa)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'enZW',
        name: 'English (Zimbabwe)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'esAR',
        name: 'Spanish (Argentina)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'esBO', name: 'Spanish (Bolivia)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'esCL', name: 'Spanish (Chile)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'esCO',
        name: 'Spanish (Colombia)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'esCR',
        name: 'Spanish (Costa Rica)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'esDO',
        name: 'Spanish (Dominican Republic)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'esEC', name: 'Spanish (Ecuador)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'esGT',
        name: 'Spanish (Guatemala)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'esHN',
        name: 'Spanish (Honduras)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'esNI',
        name: 'Spanish (Nicaragua)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'esPA', name: 'Spanish (Panama)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'esPE', name: 'Spanish (Peru)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'esPR',
        name: 'Spanish (Puerto Rico)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'esPY',
        name: 'Spanish (Paraguay)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'esSV',
        name: 'Spanish (El Salvador)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'esUY', name: 'Spanish (Uruguay)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'esVE',
        name: 'Spanish (Venezuela)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'et', name: 'Estonian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'etEE',
        name: 'Estonian (Estonia)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'eu', name: 'Basque', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'euES', name: 'Basque (Spain)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'fa', name: 'Farsi', recommended: false, rtl: true, accentInsensitive: false, latinize: false },
    { id: 'faIR', name: 'Farsi (Iran)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'fi', name: 'Finnish', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'fiFI', name: 'Finnish (Finland)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'fo', name: 'Faroese', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'foFO',
        name: 'Faroese (Faroe Islands)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'fr', name: 'French', recommended: false, rtl: false, accentInsensitive: true, latinize: true },
    { id: 'frBE', name: 'French (Belgium)', recommended: false, rtl: false, accentInsensitive: true, latinize: true },
    {
        id: 'frCA',
        name: 'French (Canada)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: true,
        extraMatches: ['French Canadian text', 'French (Canadian) text']
    },
    {
        id: 'frCH',
        name: 'French (Switzerland)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: true
    },
    {
        id: 'frLU',
        name: 'French (Luxembourg)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: true
    },
    {
        id: 'frMC',
        name: 'French (Principality of Monaco)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: true
    },
    { id: 'gl', name: 'Galician', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'glES', name: 'Galician (Spain)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'gu', name: 'Gujarati', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'guIN', name: 'Gujarati (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'he', name: 'Hebrew', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'heIL', name: 'Hebrew (Israel)', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    { id: 'hi', name: 'Hindi', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'hiIN', name: 'Hindi (India)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'hr', name: 'Croatian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'hrBA',
        name: 'Croatian (Bosnia and Herzegovina)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'hrHR',
        name: 'Croatian (Croatia)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'hu', name: 'Hungarian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'huHU',
        name: 'Hungarian (Hungary)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'hy', name: 'Armenian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'hyAM',
        name: 'Armenian (Armenia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'id', name: 'Indonesian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'idID',
        name: 'Indonesian (Indonesia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'is', name: 'Icelandic', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'isIS',
        name: 'Icelandic (Iceland)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'it', name: 'Italian', recommended: false, rtl: false, accentInsensitive: false, latinize: true },
    {
        id: 'itCH',
        name: 'Italian (Switzerland)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: true
    },
    { id: 'ka', name: 'Georgian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'kaGE',
        name: 'Georgian (Georgia)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'kk', name: 'Kazakh', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'kkKZ',
        name: 'Kazakh (Kazakhstan)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'kn', name: 'Kannada', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'knIN', name: 'Kannada (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ky', name: 'Kyrgyz', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'kyKG',
        name: 'Kyrgyz (Kyrgyzstan)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'lt', name: 'Lithuanian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'ltLT',
        name: 'Lithuanian (Lithuania)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'lv', name: 'Latvian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'lvLV', name: 'Latvian (Latvia)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'mi', name: 'Maori', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'miNZ',
        name: 'Maori (New Zealand)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'mk', name: 'Macedonian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'mkMK',
        name: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'mn',
        name: 'Mongolian (Cyrillic)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'mnMN',
        name: 'Mongolian (Mongolia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'mr', name: 'Marathi', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'mrIN', name: 'Marathi (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ms', name: 'Malay', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'msMY',
        name: 'Malay (Malaysia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'msBN',
        name: 'Malay (Brunei Darussalam)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'mt', name: 'Maltese', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'mtMT', name: 'Maltese (Malta)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'no', name: 'Norwegian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'noNO',
        name: 'Norwegian (Norway)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'nb', name: 'Norwegian Bokmal', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'nbNO',
        name: 'Norwegian (Bokmal) (Norway)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false,
        extraMatches: [`Norwegian (bokmål)`, `Norwegian (Bokm?l) (Norway)`]
    },
    { id: 'nl', name: 'Dutch', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'nlNL',
        name: 'Dutch (Netherlands)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false,
        extraMatches: ['nlNL Netherlands']
    },
    { id: 'nlBE', name: 'Dutch (Belgium)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'nn', name: 'Norwegian Nynorsk', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'nnNO',
        name: 'Norwegian (Nynorsk) (Norway)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'nsZA',
        name: 'Northern Sotho (South Africa)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'pa', name: 'Punjabi', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'paIN', name: 'Punjabi (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ps', name: 'Pashto', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    {
        id: 'psAR',
        name: 'Pashto (Afghanistan)',
        recommended: false,
        rtl: true,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'pt', name: 'Portuguese', recommended: false, rtl: false, accentInsensitive: false, latinize: true },
    {
        id: 'ptPT',
        name: 'Portuguese (Portugal)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: true
    },
    { id: 'qu', name: 'Quechua', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'quBO',
        name: 'Quechua (Bolivia)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    {
        id: 'quEC',
        name: 'Quechua (Ecuador)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'quPE', name: 'Quechua (Peru)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ro', name: 'Romanian', recommended: false, rtl: false, accentInsensitive: true, latinize: true },
    {
        id: 'roRO',
        name: 'Romanian (Romania)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: true
    },
    { id: 'sa', name: 'Sanskrit', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'saIN', name: 'Sanskrit (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'se', name: 'Northern Sami', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'seFI', name: 'Sami (Finland)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'seNO', name: 'Sami (Norway)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'svSE', name: 'Swedish (Sweden)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'seSE', name: 'Sami (Sweden)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'sk', name: 'Slovak', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'skSK', name: 'Slovak (Slovakia)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'sl', name: 'Slovenian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'slSI',
        name: 'Slovenian (Slovenia)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'sq', name: 'Albanian', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'sqAL',
        name: 'Albanian (Albania)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'sr', name: 'Serbian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'srRS', name: 'Serbian (Serbia)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'srME',
        name: 'Serbian (Montenegro)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    {
        id: 'srBA',
        name: 'Serbian (Bosnia and Herzegovina)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'sv', name: 'Swedish', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'svFI', name: 'Swedish (Finland)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'sw', name: 'Swahili', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'swKE', name: 'Swahili (Kenya)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ta', name: 'Tamil', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'taIN', name: 'Tamil (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'te', name: 'Telugu', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'teIN', name: 'Telugu (India)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'th', name: 'Thai', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'thTH', name: 'Thai (Thailand)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'tl', name: 'Tagalog', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'tlPH',
        name: 'Tagalog (Philippines)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: true
    },
    { id: 'tn', name: 'Tswana', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'tnZA',
        name: 'Tswana (South Africa)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'tr', name: 'Turkish', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'trTR', name: 'Turkish (Turkey)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'tt', name: 'Tatar', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'ttRU', name: 'Tatar (Russia)', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    { id: 'uk', name: 'Ukrainian', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'ukUA',
        name: 'Ukrainian (Ukraine)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'ur', name: 'Urdu', recommended: false, rtl: true, accentInsensitive: true, latinize: false },
    {
        id: 'urPK',
        name: 'Urdu (Islamic Republic of Pakistan)',
        recommended: false,
        rtl: true,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'uz', name: 'Uzbek (Latin)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'uzUZ',
        name: 'Uzbek (Uzbekistan)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'vi', name: 'Vietnamese', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'viVN',
        name: 'Vietnamese (Viet Nam)',
        recommended: false,
        rtl: false,
        accentInsensitive: true,
        latinize: false
    },
    { id: 'xh', name: 'Xhosa', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'xhZA',
        name: 'Xhosa (South Africa)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'zh', name: 'Chinese', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    { id: 'zhMO', name: 'Chinese (Macau)', recommended: false, rtl: false, accentInsensitive: true, latinize: false },
    {
        id: 'zhSG',
        name: 'Chinese (Singapore)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    },
    { id: 'zu', name: 'Zulu', recommended: false, rtl: false, accentInsensitive: false, latinize: false },
    {
        id: 'zuZA',
        name: 'Zulu (South Africa)',
        recommended: false,
        rtl: false,
        accentInsensitive: false,
        latinize: false
    }
];
function mapLanguageByCode() {
    const data = {};
    for (const language of SUPPORTED_LANGUAGES) {
        data[language?.id] = language;
    }
    return data;
}

export const LANGUAGE_DETAIL_BY_CODE = mapLanguageByCode();

export const SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH = SUPPORTED_LANGUAGES.map(language => {
    // eslint-disable-next-line
    const nameWithoutClosingBracket = language?.name?.replace(/\(|\)|\,/g, '');
    let fuzzy = getFuzzyByLanguage({ langCode: language?.id, langName: language?.name });

    if (language?.extraMatches && language?.extraMatches?.length) {
        fuzzy = fuzzy?.concat(language?.extraMatches);
    }

    return {
        ...language,
        nameWithoutClosingBracket,
        fuzzy
    };
});

export function getFuzzyByLanguage({ langName, langCode }) {
    const name = langName?.replace(/ *\([^)]*\) */g, '');
    const first2Letter = langCode?.slice(0, 2);
    const last2Letter = langCode?.slice(2);
    const nameWithText = `${name} Text`;

    return langCode === 'idID'
        ? [langCode, `${first2Letter}-${last2Letter}`, name, nameWithText, langName]
        : [
              first2Letter,
              last2Letter,
              langCode,
              `${first2Letter}-${last2Letter}`,
              `${first2Letter}_${last2Letter}`,
              name,
              nameWithText,
              langName,
              `::${first2Letter}::`,
              `target_${first2Letter}`,
              `target_${last2Letter}`,
              `target_${langCode}`,
              `source_${langCode}`,
              `source_${first2Letter}`,
              `source_${last2Letter}`
          ];
}

export function getLanguageDisplayName(value) {
    const found = SUPPORTED_LANGUAGES.find(lang => lang.id === value);
    return found ? found.name : value;
}
