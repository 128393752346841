import { isTempId } from 'utils/uuid';

export function generateDefaultName({ list = [], property, prefixName = 'Untitled' }) {
    const validList = list?.filter(item => !isTempId(item?.id));
    const length = validList?.length + 1;
    let suggestName = `${prefixName} ${length}`;
    const founded = validList?.findIndex(item => item?.[property] === suggestName) !== -1;
    if (founded) {
        suggestName = generateDefaultName({ list: validList?.concat({ test: '1' }), property, prefixName });
    }
    return suggestName;
}

export function getFullName(user) {
    if (!user) return '';

    const { fullName, firstName, lastName, email } = user;

    if (fullName) return fullName;
    if (firstName === lastName && firstName) return firstName;
    if (!firstName && !lastName) return email?.split('@')?.[0];

    return ((firstName ? firstName : '') + ' ' + (lastName ? lastName : '')).trim();
}

export function trimTextBetween({ symbol = '...', text, max }) {
    if (!text) return text;
    const textLength = text?.length;

    if (textLength <= max) return text;

    const symbolLength = symbol?.length;
    const splitIndex = Math.round(max / 2);
    return text?.slice(0, splitIndex - symbolLength) + symbol + text?.slice(textLength - splitIndex, textLength);
}

export function UpperCaseFirstCharacter(string) {
    if (!string || typeof string !== 'string') return string;
    const rest = string?.slice(1);
    const firstLetter = string?.slice(0, 1);
    return `${firstLetter?.toUpperCase()}${rest}`;
}
