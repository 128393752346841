import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { CELL_COMMENT_HEIGHT } from 'const/style';
import {
    useSelectedCellData,
    useDbIdInGridUI,
    useGridIdInGridUI,
    useWorkspaceIdInGridUI,
    useShareViewGroupMembers
} from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import LDBasePortal from 'components/selects/LDBasePortal';
import InputText from 'components/inputs/InputText';
import ButtonBase from 'components/button/Base';
import { ISSUE_TYPE_OPTIONS, PRIORITY_OPTIONS } from 'utils/gridUI/lqa';
import { LQA_TYPES } from 'const/gridUI';
import * as gridUIActions from 'gridUI/actions';
import { useGetLQAModelDefault, useGetLQACreateType } from 'hooks/gridUI/lqa';
import { enqueueSnackbar } from 'notifier/actions';
import TicketSticky from 'gridUI/lqa/TicketSticky';
import { useAddonDetailByName } from 'hooks/addons';
import { ADD_ON_NAME } from 'const/inAppAddon';
import { ADD_ON_STATES, SYSTEM_COLUMN_IDS } from 'const';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import { Trans, useTranslation } from 'react-i18next';
import { sendManualTrack } from 'tracker';
import EditorMention from './EditorMention';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getFullName } from 'utils/name';
import Avatar from 'components/avatar/User';
import { getAvatarUrl } from 'utils/images';
import * as shareViewActions from 'gridUI/actions/shareView';
import { useParams } from 'react-router-dom';

const CELL_PREVIEW_WIDTH = 400;

const useStyles = makeStyles(theme => ({
    root: {
        width: CELL_PREVIEW_WIDTH,
        height: 'auto',
        boxShadow: theme.shadows[1],
        zIndex: 100,
        background: theme.colors.white,
        borderRadius: 6,
        padding: theme.spacing(3)
    },
    rootRight: {
        margin: 0,
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        borderRadius: 0,
        padding: `20px ${theme.spacing(4)}px`
    },
    replyBox: {
        width: props => (!props.isRightSidebar ? CELL_PREVIEW_WIDTH : '100%'),
        boxShadow: theme.shadows[1],
        zIndex: 100,
        background: theme.colors.white,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6
    },
    spinner: {
        width: 400,
        height: CELL_COMMENT_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    lqaModal: {
        padding: '32px 34px 24px'
    },
    emptyBox: {
        marginBottom: 27
    },
    addon: {
        color: theme.colors.highlight,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    inputCommon: {
        '& input, textarea': {
            '&::placeholder': {
                fontSize: 13
            }
        }
    },
    label: {
        textAlign: 'left',
        marginBottom: theme.spacing(1)
    },
    editorMention: {
        color: '#2A293A',
        padding: '6px 14px',
        position: 'relative',
        fontSize: 14,
        transition:
            'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 4,
        backgroundColor: '#fff',
        border: '1px solid rgb(233, 234, 239)',
        height: 70,
        overflow: 'hidden auto',
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        },
        '&.small': {
            fontSize: 12
        }
    },
    avatarUnassigned: {
        width: '20px',
        height: '20px'
    },
    ldPortal: {
        minWidth: 220
    }
}));

function TicketForm({ isRightSidebar, onAdded = () => {}, updatePopper = () => {}, onClose }) {
    const classes = useStyles({ isRightSidebar });
    const selectedCellData = useSelectedCellData();

    const dispatch = useDispatch();
    // const rootRef = React.useRef();
    const [summary, setSummary] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [priority, setPriority] = React.useState(PRIORITY_OPTIONS?.[2]);
    const [category, setCategory] = React.useState(undefined);
    const [subCategory, setSubcategory] = React.useState(undefined);
    const [severity, setSeverity] = React.useState(undefined);
    const [assignee, setAssignee] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const lqaModelDefault = useGetLQAModelDefault();
    const lqaIntegration = useAddonDetailByName(ADD_ON_NAME.LQA)?.integration;
    const { t } = useTranslation();
    const dbId = useDbIdInGridUI();
    const workspaceId = useWorkspaceIdInGridUI();
    const gridId = useGridIdInGridUI();
    const lqaCreateType = useGetLQACreateType();
    const members = useShareViewGroupMembers() || [];
    const { viewId } = useParams();

    const users = React.useMemo(() => {
        return members.map(m => m.user);
    }, [members]);

    React.useEffect(() => {
        updatePopper();
    }, [lqaCreateType, updatePopper]);

    React.useEffect(() => {
        dispatch(shareViewActions.fetchGroupsByView(viewId));
    }, [dispatch, viewId]);

    const isLQaModalEnable = React.useMemo(() => {
        return lqaIntegration?.id && ADD_ON_STATES.INACTIVE !== lqaIntegration?.state;
    }, [lqaIntegration]);

    const isLQAType = React.useMemo(() => {
        return lqaCreateType?.value === LQA_TYPES.LQA_ISSUE;
    }, [lqaCreateType]);

    const disabledButton = React.useMemo(() => {
        return loading || isLQAType ? !summary || !category || !subCategory || !severity : !summary;
    }, [loading, isLQAType, summary, category, subCategory, severity]);

    const categories = React.useMemo(() => {
        return lqaModelDefault?.categories.map(el => ({ ...el, label: el.name, value: el.id })) || [];
    }, [lqaModelDefault]);

    const subCategories = React.useMemo(() => {
        return category?.subCategories.map(el => ({ ...el, label: el.name, value: el.id })) || [];
    }, [category]);

    const severities = React.useMemo(() => {
        return subCategory?.severities.map(el => ({ ...el, label: el.name, value: el.severityId })) || [];
    }, [subCategory]);

    // useClickAwaitListener(rootRef, e => {
    //     handleClickAway(e);
    // });

    const handlePriorityTypeChange = React.useCallback(option => {
        setPriority(option);
    }, []);

    const handleSeverityTypeChange = React.useCallback(option => {
        setSeverity(option);
    }, []);

    const handleSubCategoryTypeChange = React.useCallback(
        option => {
            setSubcategory(option);
            handleSeverityTypeChange(undefined);
        },
        [handleSeverityTypeChange]
    );

    const handleCategoryTypeChange = React.useCallback(
        option => {
            setCategory(option);
            handleSubCategoryTypeChange(undefined);
        },
        [handleSubCategoryTypeChange]
    );

    const handleIssueTypeChange = React.useCallback(
        option => {
            dispatch(gridUIActions.setLQACreateType(option));
            handleCategoryTypeChange(undefined);
        },
        [dispatch, handleCategoryTypeChange]
    );

    const handleOpenAddon = React.useCallback(() => {
        const addonEl = document.getElementById('header-addon');
        if (addonEl) {
            addonEl.click();
            onClose && onClose();
            dispatch(gridActions.closeCellIssue());
        }
    }, [dispatch, onClose]);

    const customNode = React.useMemo(() => {
        return !isLQaModalEnable ? (
            <div className="flex flex-col gap-3 items-center justify-center">
                <EmptyBoxSVG />
                <div className="text-center">
                    <Trans t={t} i18nKey={'no_other_ticket_type_to_switch'}>
                        <p className="body2 text-center">No other ticket type to switch.</p>
                        <p
                            className="text-center cursor-pointer text-highlight underline inline body2"
                            onClick={handleOpenAddon}
                        >
                            Install related add-on
                        </p>
                        <p className="body2 inline text-center"> to have new ticket type</p>
                    </Trans>
                </div>
            </div>
        ) : null;
    }, [isLQaModalEnable, classes, handleOpenAddon, t]);

    // const handleClickAway = e => {
    //     if (checkContainId(e, 'trigger-comment')) {
    //         return false;
    //     }
    //     if (checkContainId(e, 'comment_edit_emoji')) {
    //         return false;
    //     }
    //     if (checkContainId(e, 'comment-emoji')) {
    //         return false;
    //     }
    //     dispatch(gridActions.closeCellIssue());
    // };

    const handleAdd = () => {
        if (loading) return;
        if (!selectedCellData) {
            dispatch(
                enqueueSnackbar({
                    message: 'Choose a cell to create ticket',
                    type: 'info'
                })
            );
            return;
        }
        if (SYSTEM_COLUMN_IDS.includes(selectedCellData?.columnId)) {
            dispatch(
                enqueueSnackbar({
                    message: 'Cannot add ticket on system column',
                    type: 'info'
                })
            );
            return;
        }
        setLoading(true);
        const { rowId, columnId, data } = selectedCellData;
        let body = {
            type: lqaCreateType.value,
            summary,
            description: comment,
            priority: priority.value,
            dbId,
            projectId: workspaceId,
            gridId,
            columnId,
            recordId: rowId
        };

        if (assignee) {
            body = {
                ...body,
                assigneeId: assignee?.value
            };
        }

        if (isLQAType) {
            body = {
                ...body,
                lqaModelCategoryId: category.id,
                lqaModelSubCategoryId: subCategory.id,
                lqaModelSeverityId: severity.severityId
            };
        }
        const successCallback = responseData => {
            dispatch(gridActions.closeCellIssue());
            dispatch(
                enqueueSnackbar({
                    action: key => <TicketSticky snackBarKey={key} ticket={responseData} />,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    type: 'info'
                })
            );
            onAdded();
        };
        const errorCallback = () => {
            setLoading(false);
        };
        sendManualTrack({
            type: isRightSidebar ? `Add Ticket in Ticket Center` : `Add Ticket`,
            customData: { type: body?.type, priority: body?.priority }
        });
        dispatch(gridUIActions.createLQATicket({ body, cellData: data, successCallback, errorCallback }));
    };

    const membersOption = React.useMemo(() => {
        let arr = [
            {
                value: '',
                label: 'Unassigned',
                icon: () => <AvatarIconSVG className={classes.avatarUnassigned} color="#DBDCE1" />
            }
        ];
        const mapMembers = (members || []).map(m => ({
            value: m.user.id,
            label: getFullName(m.user),
            icon: () => (
                <Avatar src={getAvatarUrl(m.user.id)} size={20} color="#DBDCE1">
                    <AvatarIconSVG />
                </Avatar>
            )
        }));
        arr = [...(assignee ? arr : []), ...mapMembers];
        return arr;
    }, [classes, members, assignee]);

    const handleAssigneeChange = React.useCallback(member => {
        setAssignee(!member?.value ? null : member);
    }, []);

    return (
        <Grid
            spacing={3}
            container
            direction="column"
            className={`${classes.root} ${isRightSidebar ? classes.rootRight : ''}`}
        >
            <Grid item>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item style={{ display: 'flex' }}>
                        <h4 className="prose prose-lg font-medium">{t('cell_options_add_ticket')}</h4>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid container item direction="column" spacing={1}>
                    <Grid item>
                        <p className="body1">{t('ticket_type')}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            dropdownClassName={classes.test}
                            ddPlaceholder={'Select a group'}
                            menuPlaceholder={t('global_label_find_a_group')}
                            options={ISSUE_TYPE_OPTIONS}
                            onChange={handleIssueTypeChange}
                            defaultValue={lqaCreateType}
                            isUsingContainer={true}
                            customNode={customNode}
                        />
                    </Grid>
                </Grid>
                <Grid container item direction="column" spacing={1}>
                    <Grid item>
                        <InputText
                            name="summary"
                            value={summary}
                            onChange={e => setSummary(e?.target?.value)}
                            placeholder={t('insert_a_short_summary')}
                            label={t('global_summary')}
                            inputClassName={classes.inputCommon}
                        />
                    </Grid>
                </Grid>
                <Grid container item direction="column" spacing={1}>
                    <Grid item>
                        <p className="body1">{t('global_assignee')}</p>
                    </Grid>

                    <Grid item>
                        <LDBasePortal
                            dropdownClassName={classes.ldPortal}
                            ddPlaceholder={t('select_an_assignee')}
                            menuPlaceholder={t('find_an_assignee')}
                            options={membersOption}
                            onChange={handleAssigneeChange}
                            defaultValue={assignee}
                            // isUsingContainer
                        />
                    </Grid>
                </Grid>
                <Grid container item direction="column" spacing={1}>
                    <Grid item>
                        <p className="body1">{t('global_priority')}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            dropdownClassName={classes.test}
                            ddPlaceholder={'Select a priority'}
                            menuPlaceholder={t('find_a_priority')}
                            options={PRIORITY_OPTIONS}
                            onChange={handlePriorityTypeChange}
                            defaultValue={priority}
                            // isUsingContainer
                        />
                    </Grid>
                </Grid>
                {isLQAType && (
                    <>
                        <Grid container item direction="column" spacing={1}>
                            <Grid item>
                                <p className="body1">Category</p>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    dropdownClassName={classes.test}
                                    ddPlaceholder={'Select a category'}
                                    menuPlaceholder={'Find a category'}
                                    options={categories}
                                    onChange={handleCategoryTypeChange}
                                    defaultValue={category}
                                    // isUsingContainer
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" spacing={1}>
                            <Grid item>
                                <p className="body1">Sub-category</p>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    dropdownClassName={classes.test}
                                    ddPlaceholder={'Select a sub category'}
                                    menuPlaceholder={'Find a sub category'}
                                    options={subCategories}
                                    onChange={handleSubCategoryTypeChange}
                                    defaultValue={subCategory}
                                    // isUsingContainer
                                    isDisabled={!category}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" spacing={1}>
                            <Grid item>
                                <p className="body1">Severity</p>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    dropdownClassName={classes.test}
                                    ddPlaceholder={'Select a severity'}
                                    menuPlaceholder={'Find a severity'}
                                    options={severities}
                                    onChange={handleSeverityTypeChange}
                                    defaultValue={severity}
                                    // isUsingContainer
                                    isDisabled={!subCategory}
                                />
                            </Grid>
                        </Grid>{' '}
                    </>
                )}

                <Grid container item direction="column" spacing={1}>
                    <Grid item>
                        <p className={'body1 mb-1'}> {t('global_description')}</p>
                    </Grid>
                    <Grid item>
                        <EditorMention
                            placeholder={t('leave_a_description')}
                            text={comment}
                            onChange={val => setComment(val)}
                            members={users}
                            className={`${classes.editorMention} ${!comment ? 'small' : ''}`}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ButtonBase fullWidth onClick={handleAdd} variant="contained" disabled={disabledButton}>
                    {loading ? <CircularProgress size={24} /> : t('global_add')}
                </ButtonBase>
            </Grid>
        </Grid>
    );
}

export default React.memo(TicketForm);
