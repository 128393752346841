import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        width: 440,
        background: theme.colors.white
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function ConfirmDeclineBox({ email = 'example.com', handleCancel, handleAgreed, isSubmitting }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container wrap="nowrap" direction="column" spacing={4} className={classes.root}>
            <Grid item>
                <Typography variant="h4">{t('confirm_decline_title')}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" display="inline">
                    {t('confirm_decline_text_1')}{' '}
                </Typography>
                <Typography variant="body1" display="inline">
                    {email}
                </Typography>
                <Typography variant="body2" display="inline">
                    ?
                </Typography>
            </Grid>
            <Grid item>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase onClick={handleCancel} width={100} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <ButtonBase
                            onClick={handleAgreed}
                            width={100}
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                        >
                            {t('global_remove')}
                        </ButtonBase>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default React.memo(ConfirmDeclineBox);
