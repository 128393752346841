import React from 'react';

function ProfessionalPlanSVG({ ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.21469 9.42937C6.85052 9.42937 7.47206 9.24082 8.00073 8.88756C8.52939 8.5343 8.94143 8.0322 9.18472 7.44476C9.42802 6.85732 9.49165 6.21093 9.36756 5.58733C9.24348 4.96372 8.93725 4.39092 8.48761 3.94136C8.03798 3.4918 7.46512 3.18568 6.8415 3.0617C6.21787 2.93773 5.57149 3.00147 4.98409 3.24487C4.39669 3.48826 3.89467 3.90039 3.5415 4.42911C3.18833 4.95784 2.99989 5.57942 3 6.21525C3.00089 7.06751 3.33989 7.8846 3.94258 8.48719C4.54527 9.08978 5.36242 9.42863 6.21469 9.42937Z"
                fill="#F9BD42"
            />
            <path
                d="M10.6341 11.6549C9.81946 10.8193 8.77428 10.2456 7.63203 10.0069C6.48977 9.76822 5.3023 9.87546 4.22129 10.3149C3.14029 10.7544 2.21482 11.5061 1.56311 12.4741C0.911396 13.4421 0.563027 14.5824 0.5625 15.7493C0.5625 15.8985 0.621763 16.0415 0.727252 16.147C0.832742 16.2525 0.975816 16.3118 1.125 16.3118H11.7338C11.8829 16.3118 12.026 16.2525 12.1315 16.147C12.237 16.0415 12.2963 15.8985 12.2963 15.7493C12.2984 15.1528 12.2064 14.5598 12.0234 13.992C11.7511 13.1143 11.275 12.3135 10.6341 11.6549Z"
                fill="#F9BD42"
            />
            <path
                d="M13.2188 9.28125C14.5391 9.28125 15.6094 8.21093 15.6094 6.89062C15.6094 5.57032 14.5391 4.5 13.2188 4.5C11.8984 4.5 10.8281 5.57032 10.8281 6.89062C10.8281 8.21093 11.8984 9.28125 13.2188 9.28125Z"
                fill="#FFD98C"
            />
            <path
                d="M13.2188 9.93945C12.4648 9.94229 11.7254 10.1476 11.0779 10.534C11.1999 10.6431 11.3231 10.7506 11.4384 10.8687C12.2044 11.6559 12.7733 12.6131 13.0989 13.6621C13.2105 14.0074 13.2927 14.3616 13.3448 14.7207H16.875C17.0242 14.7207 17.1673 14.6614 17.2728 14.556C17.3782 14.4505 17.4375 14.3074 17.4375 14.1582C17.4363 13.0397 16.9915 11.9673 16.2005 11.1764C15.4096 10.3855 14.3373 9.94064 13.2188 9.93945Z"
                fill="#FFD98C"
            />
        </svg>
    );
}

export default React.memo(ProfessionalPlanSVG);
