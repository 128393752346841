/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, useTheme, Divider } from '@material-ui/core';
import CodingSVG from 'assets/images/svg/CodingSVG';
import CommentsSVG from 'assets/images/svg/CommentsSVG';
import HistorySVG from 'assets/images/svg/HistorySVG';
import SettingGroupSVG from 'assets/images/svg/SettingGroupSVG';
import AutomationSVG from 'assets/images/svg/webHook/AutomationSVG';
import { makeStyles } from '@material-ui/core/styles';
import { RIGHT_SIDEBAR_ACTION_TYPE, SIDEBAR_RIGHT_WIDTH } from 'const/gridUI';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import BranchSVG from 'assets/images/svg/BranchSVG';
import MemSourceSVG from 'assets/images/svg/addon/MemSourceSVG';
import MemoQSVG from 'assets/images/svg/addon/MemoQSVG';
import TranslationOverviewSVG from 'assets/images/svg/addon/TranslationOverviewSVG';
import LQASVG from 'assets/images/svg/LQASVG';
import { useIsHighlightTrigger } from 'hooks/gridUI/webhook';
import { ADD_ON_NAME } from 'const/inAppAddon';
import { useAddonDetailByName } from 'hooks/addons';
import { ADD_ON_STATES } from 'const';
import { useRole } from 'hooks/auth/role';
import { useIsOpenedLQATickets } from 'hooks/gridUI/lqa';
import AutoQASVG from 'assets/images/svg/AutoQASVG';
import { useIsShowAutoQAButtons } from 'hooks/gridUI';
import ConnectorSidebarSVG from 'assets/images/svg/ConnectorSidebarSVG';
import HistorySVG2 from 'assets/images/svg/HistorySVG2';
import { useDispatch } from 'react-redux';
import {
    getListAutomations,
    setAutomationListExecutions,
    setAutomations,
    setIsFetchingAutomations
} from './automations/action';
import { useParams } from 'react-router-dom';
import SidebarRightAutomationIcon from './SidebarRightAutomationIcon';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 12
    },
    icon: {
        marginTop: 4,
        marginBottom: 4,
        cursor: 'pointer',
        width: SIDEBAR_RIGHT_WIDTH - 8,
        height: SIDEBAR_RIGHT_WIDTH - 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        '&:hover': {
            background: theme.colors.solitude
        }
    },
    selected: {
        background: theme.colors.solitude,
        '& svg path': {
            fill: theme.palette.primary.main
        },
        '& svg circle, line': {
            stroke: theme.palette.primary.main
        }
    },
    settingGroupSVG: {
        width: 16,
        height: 16
    },
    bgSelected: {
        background: theme.colors.solitude
    },
    divider: {
        width: '80%',
        margin: `${theme.spacing(3)}px auto`
    },
    badgeDot: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: theme.colors.burntSlena,
        border: `1px solid ${theme.colors.solitude}`,
        position: 'absolute',
        right: 5,
        top: 5
    }
}));

function SidebarRight({ handleItemClick, action, handleClose, t, isShareViewLink, canAccessGridHistoryInShareView }) {
    const classes = useStyles();
    const roles = useRole();
    const theme = useTheme();
    const isHighlightTrigger = useIsHighlightTrigger();
    const dispatch = useDispatch();
    const memSource = useAddonDetailByName(ADD_ON_NAME.MEM_SOURCE)?.integration;
    const memoQ = useAddonDetailByName(ADD_ON_NAME.MEMO_Q)?.integration;
    const translationDashboard = useAddonDetailByName(ADD_ON_NAME.TRANSLATION_DASHBOARD)?.integration;
    const isOpenedLQATickets = useIsOpenedLQATickets();
    const isShowAutoQAButtons = useIsShowAutoQAButtons();
    const { dbId, branchId, viewId } = useParams();

    React.useEffect(() => {
        dispatch(setAutomations([]));
        dispatch(setAutomationListExecutions([], true));
        dispatch(setIsFetchingAutomations(true));
        dispatch(
            getListAutomations({
                body: { dbId, gridId: branchId, viewId },
                successCallback: data => {
                    dispatch(setAutomations(data));
                    dispatch(setIsFetchingAutomations(false));
                },
                errorCallback: () => {
                    dispatch(setIsFetchingAutomations(false));
                }
            })
        );
    }, [dbId, dispatch, branchId, viewId]);

    const isMemSourceInactive = React.useMemo(() => {
        return memSource?.id && ADD_ON_STATES.INACTIVE !== memSource?.state;
    }, [memSource]);

    const isMemSourceActive = React.useMemo(() => {
        return memSource?.id && ADD_ON_STATES.ACTIVE === memSource?.state;
    }, [memSource]);

    const isMemoQInactive = React.useMemo(() => {
        return memoQ?.id && ADD_ON_STATES.INACTIVE !== memoQ?.state;
    }, [memoQ]);

    const isMemoQActive = React.useMemo(() => {
        return memoQ?.id && ADD_ON_STATES.ACTIVE === memoQ?.state;
    }, [memoQ]);

    const isAdminOrOwner = React.useMemo(() => {
        return roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON] === roleConst.FULL;
    }, [roles]);

    const isCreator = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_ROLE] === roleConst.CREATOR;
    }, [roles]);

    const isShowMemoQSidebar = React.useMemo(() => {
        return (isMemoQInactive && isAdminOrOwner) || (isMemoQActive && isCreator);
    }, [isMemoQActive, isCreator, isAdminOrOwner, isMemoQInactive]);

    const isShowMemSourceSidebar = React.useMemo(() => {
        return (isMemSourceInactive && isAdminOrOwner) || (isMemSourceActive && isCreator);
    }, [isMemSourceActive, isCreator, isAdminOrOwner, isMemSourceInactive]);

    const isTranslationEnable = React.useMemo(() => {
        return translationDashboard?.id && ADD_ON_STATES.INACTIVE !== translationDashboard?.state;
    }, [translationDashboard]);

    // const isLQaModalEnable = React.useMemo(() => {
    //     return lqa?.id && ADD_ON_STATES.INACTIVE !== lqa?.state;
    // }, [lqa]);

    if (isShareViewLink && canAccessGridHistoryInShareView) {
        return (
            <Grid container className={classes.root} direction="column" alignItems="center">
                <Tooltip title={t('right_toolbar_history_tooltip')} placement="left">
                    <Grid
                        onClick={() =>
                            action === RIGHT_SIDEBAR_ACTION_TYPE.HISTORY
                                ? handleClose()
                                : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.HISTORY)
                        }
                        item
                        className={`${classes.icon} ${
                            action === RIGHT_SIDEBAR_ACTION_TYPE.HISTORY ? classes.selected : ``
                        }`}
                        tr-dt="Open Grid History List"
                    >
                        <HistorySVG color={theme.colors.darkness} />
                    </Grid>
                </Tooltip>
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root} direction="column" alignItems="center">
            <Tooltip title={t('right_toolbar_lqa_tooltip')} placement="left">
                <Grid
                    id="sidebar-right-lqa"
                    onClick={() =>
                        action === RIGHT_SIDEBAR_ACTION_TYPE.LQA
                            ? handleClose()
                            : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.LQA)
                    }
                    item
                    className={`${classes.icon} ${action === RIGHT_SIDEBAR_ACTION_TYPE.LQA ? classes.selected : ``}`}
                    tr-dt="Open Ticket Center"
                    style={{ position: 'relative' }}
                >
                    <LQASVG color={theme.colors.darkness} />
                    {!!isOpenedLQATickets && <div className={classes.badgeDot} />}
                </Grid>
            </Tooltip>

            <Tooltip title={t('right_toolbar_comments_tooltip')} placement="left">
                <Grid
                    onClick={() =>
                        action === RIGHT_SIDEBAR_ACTION_TYPE.COMMENT
                            ? handleClose()
                            : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.COMMENT)
                    }
                    item
                    className={`${classes.icon} ${
                        action === RIGHT_SIDEBAR_ACTION_TYPE.COMMENT ? classes.selected : ``
                    }`}
                    tr-dt="Open Grid Comments"
                >
                    <CommentsSVG color={theme.colors.darkness} />
                </Grid>
            </Tooltip>
            {/* )}
            </AccessControl> */}

            {(roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE] === roleConst.FULL ||
                roles[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] === roleConst.FULL) && (
                <Tooltip title={t('right_toolbar_backup_tooltip')} placement="left">
                    <Grid
                        onClick={() =>
                            action === RIGHT_SIDEBAR_ACTION_TYPE.BACKUP_GRID
                                ? handleClose()
                                : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.BACKUP_GRID)
                        }
                        item
                        className={`${classes.icon} ${
                            action === RIGHT_SIDEBAR_ACTION_TYPE.BACKUP_GRID ? classes.selected : ``
                        }`}
                        tr-dt="Open Grid Backup & Restore"
                    >
                        <HistorySVG color={theme.colors.darkness} />
                    </Grid>
                </Tooltip>
            )}

            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.ACCESS_GRID_HISTORY}>
                {({ isReadOnly }) => (
                    <Tooltip title={t('right_toolbar_history_tooltip')} placement="left">
                        <Grid
                            onClick={() =>
                                action === RIGHT_SIDEBAR_ACTION_TYPE.HISTORY
                                    ? handleClose()
                                    : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.HISTORY)
                            }
                            item
                            className={`${classes.icon} ${
                                action === RIGHT_SIDEBAR_ACTION_TYPE.HISTORY ? classes.selected : ``
                            }`}
                            tr-dt="Open Grid History List"
                        >
                            <HistorySVG2 color={theme.colors.darkness} />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD}>
                {({ isReadOnly }) => (
                    <Tooltip title={t('right_toolbar_api_quick_start_tooltip')} placement="left">
                        <Grid
                            onClick={() =>
                                action === RIGHT_SIDEBAR_ACTION_TYPE.CODE
                                    ? handleClose()
                                    : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.CODE)
                            }
                            item
                            className={`${classes.icon} ${
                                action === RIGHT_SIDEBAR_ACTION_TYPE.CODE ? classes.selected : ``
                            }`}
                            tr-dt="Open Api Quick Start"
                        >
                            <CodingSVG color={theme.colors.darkness} />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_VIEW}>
                {({ isReadOnly }) => (
                    <Tooltip title={t('right_toolbar_share_view_tooltip')} placement="left">
                        <Grid
                            onClick={() =>
                                action === RIGHT_SIDEBAR_ACTION_TYPE.SHARE_VIEW
                                    ? handleClose()
                                    : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.SHARE_VIEW)
                            }
                            item
                            className={`${classes.icon} ${
                                action === RIGHT_SIDEBAR_ACTION_TYPE.SHARE_VIEW ? classes.selected : ``
                            }`}
                            tr-dt="Open Share View"
                        >
                            <SettingGroupSVG color={theme.colors.darkness} className={classes.settingGroupSVG} />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_MERGING}>
                {({ isReadOnly }) => (
                    <Tooltip title={t('right_toolbar_version_control_tooltip')} placement="left">
                        <Grid
                            onClick={() =>
                                action === RIGHT_SIDEBAR_ACTION_TYPE.BRANCH
                                    ? handleClose()
                                    : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.BRANCH)
                            }
                            item
                            className={`${classes.icon} ${
                                action === RIGHT_SIDEBAR_ACTION_TYPE.BRANCH ? classes.selected : ``
                            }`}
                            tr-dt="Open Version Control"
                        >
                            <BranchSVG color={theme.colors.darkness} />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            {/* <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_TRIGGER}>
                {({ isReadOnly }) => (
                    <Tooltip title={t('right_toolbar_trigger_tooltip')} placement="left">
                        <Grid
                            onClick={() =>
                                action === RIGHT_SIDEBAR_ACTION_TYPE.WEB_HOOK
                                    ? handleClose()
                                    : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.WEB_HOOK)
                            }
                            item
                            className={`${classes.icon} ${
                                action === RIGHT_SIDEBAR_ACTION_TYPE.WEB_HOOK ? classes.selected : ``
                            }`}
                            style={{ position: 'relative' }}
                            tr-dt="Open Triggers"
                        >
                            <AutomationSVG color={theme.colors.darkness} />
                            {!!isHighlightTrigger && <div className={classes.badgeDot} />}
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl> */}
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_TRIGGER}>
                {({ isReadOnly }) => (
                    <Tooltip title={t('right_toolbar_trigger_automations')} placement="left">
                        <Grid
                            onClick={() =>
                                action === RIGHT_SIDEBAR_ACTION_TYPE.AUTOMATIONS
                                    ? handleClose()
                                    : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.AUTOMATIONS)
                            }
                            item
                            className={`${classes.icon} ${
                                action === RIGHT_SIDEBAR_ACTION_TYPE.AUTOMATIONS ? classes.selected : ``
                            }`}
                            style={{ position: 'relative' }}
                            tr-dt="Open Triggers"
                        >
                            <SidebarRightAutomationIcon />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            {isShowAutoQAButtons && isCreator && (
                <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_LQA}>
                    {({ isReadOnly }) => (
                        <Tooltip title={t('auto_qa_by_lexiqa')} placement="left">
                            <Grid
                                onClick={() =>
                                    action === RIGHT_SIDEBAR_ACTION_TYPE.AUTO_QA
                                        ? handleClose()
                                        : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.AUTO_QA)
                                }
                                item
                                className={`${classes.icon} ${
                                    action === RIGHT_SIDEBAR_ACTION_TYPE.AUTO_QA ? classes.selected : ``
                                }`}
                                style={{ position: 'relative' }}
                                tr-dt="Open Triggers"
                            >
                                <AutoQASVG color={theme.colors.darkness} />
                            </Grid>
                        </Tooltip>
                    )}
                </AccessControl>
            )}

            {!isExternal && (
                <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID}>
                    {({ isReadOnly }) => (
                        <Tooltip title={t('gridly_connector')} placement="left">
                            <Grid
                                onClick={() =>
                                    action === RIGHT_SIDEBAR_ACTION_TYPE.SYNC_CONNECTION
                                        ? handleClose()
                                        : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.SYNC_CONNECTION)
                                }
                                item
                                className={`${classes.icon} ${
                                    action === RIGHT_SIDEBAR_ACTION_TYPE.SYNC_CONNECTION ? classes.selected : ``
                                }`}
                                style={{ position: 'relative' }}
                                tr-dt="Open Triggers"
                            >
                                <ConnectorSidebarSVG color={theme.colors.darkness} />
                            </Grid>
                        </Tooltip>
                    )}
                </AccessControl>
            )}

            {(isShowMemSourceSidebar || isShowMemoQSidebar) && (
                <Grid item className={classes.divider}>
                    <Divider />
                </Grid>
            )}

            {isShowMemSourceSidebar && (
                <Tooltip title={t('right_toolbar_memsource_tooltip')} placement="left">
                    <Grid
                        onClick={() =>
                            action === RIGHT_SIDEBAR_ACTION_TYPE.MEM_SOURCE
                                ? handleClose()
                                : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.MEM_SOURCE)
                        }
                        item
                        className={`${classes.icon} ${
                            action === RIGHT_SIDEBAR_ACTION_TYPE.MEM_SOURCE ? classes.bgSelected : ``
                        }`}
                        style={{ position: 'relative' }}
                        tr-dt="Open MemSource"
                    >
                        <MemSourceSVG />
                    </Grid>
                </Tooltip>
            )}

            {isShowMemoQSidebar && (
                <Tooltip title={t('right_toolbar_memoQ_tooltip')} placement="left">
                    <Grid
                        onClick={() =>
                            action === RIGHT_SIDEBAR_ACTION_TYPE.MEMO_Q
                                ? handleClose()
                                : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.MEMO_Q)
                        }
                        item
                        className={`${classes.icon} ${
                            action === RIGHT_SIDEBAR_ACTION_TYPE.MEMO_Q ? classes.bgSelected : ``
                        }`}
                        style={{ position: 'relative' }}
                        tr-dt="Open memoQ"
                    >
                        <MemoQSVG width={18} />
                    </Grid>
                </Tooltip>
            )}

            {isTranslationEnable && (
                <Tooltip title={t('right_toolbar_translation_overview_tooltip')} placement="left">
                    <Grid
                        onClick={() =>
                            action === RIGHT_SIDEBAR_ACTION_TYPE.TRANSLATION_OVERVIEW
                                ? handleClose()
                                : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.TRANSLATION_OVERVIEW)
                        }
                        item
                        className={`${classes.icon} ${
                            action === RIGHT_SIDEBAR_ACTION_TYPE.TRANSLATION_OVERVIEW ? classes.bgSelected : ``
                        }`}
                        style={{ position: 'relative' }}
                        tr-dt="Translation Overview"
                    >
                        <TranslationOverviewSVG />
                    </Grid>
                </Tooltip>
            )}

            {/* {isLQaModalEnable && (
                <Tooltip title={t('right_toolbar_lqa_modal_tooltip')} placement="left">
                    <Grid
                        onClick={() =>
                            action === RIGHT_SIDEBAR_ACTION_TYPE.LQA_MODAL
                                ? handleClose()
                                : handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.LQA_MODAL)
                        }
                        item
                        className={`${classes.icon} ${
                            action === RIGHT_SIDEBAR_ACTION_TYPE.LQA_MODAL ? classes.bgSelected : ``
                        }`}
                        style={{ position: 'relative' }}
                        tr-dt="LQA Model"
                    >
                        <LQAIssueTypeSVG />
                    </Grid>
                </Tooltip>
            )} */}
        </Grid>
    );
}

export default React.memo(SidebarRight);
