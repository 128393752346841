import { useSelector } from 'react-redux';
import { isTempId } from 'utils/uuid';
import { useViewColumnIds } from './column';
import { useMetaData } from './metaData';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';

export function useIsFetchingDependencies() {
    return useSelector(state => state.gridUI.isFetchingDependencies);
}

export function useIsDeletingDependency() {
    return useSelector(state => state.gridUI.isDeletingDependency);
}

export function useIsCreatingDependency() {
    return useSelector(state => state.gridUI.isCreatingDependency);
}

export function useIsUpdatingDependency() {
    return useSelector(state => state.gridUI.isUpdatingDependency);
}

export function useIsOpenNotiDependency() {
    return useSelector(state => state.gridUI.isOpenNotiDependency);
}

export function useDependencyStatusData() {
    return useSelector(state => state.gridUI.dependencyStatusData);
}

export function useDependencyList() {
    return useSelector(state => state.gridUI.dependencies);
}

export function useDependencyListWithoutFakeId() {
    const dependencies = useDependencyList();
    return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
}

export function useIsChildColumnByColumnId(columnId) {
    const dependencies = useDependencyList();
    let founded = dependencies?.find(dpdc => dpdc.child === columnId);
    return founded ? true : false;
}

export function useIsChildDependencyAndLanguage(columnId) {
    const metaData = useMetaData();
    const column = metaData?.[columnId];
    const columnType = getCorrectColumnType(column);
    const dependencies = useDependencyList();
    let founded = dependencies?.find(dpdc => dpdc.child === columnId);
    return founded && columnType === columnTypes.TRANSLATION;
}

export function useDependencies() {
    const dependencies = useDependencyList();
    const viewColumnIds = useViewColumnIds();
    return dependencies?.filter(dpdc => {
        return !isTempId(dpdc?.id) && viewColumnIds.includes(dpdc?.parent) && viewColumnIds.includes(dpdc?.child);
    });
}

export function useChildDependencies() {
    const dependencies = useDependencies();
    return dependencies?.filter(dpDc => dpDc?.child);
}

export function useChildDependenciesWithData() {
    const metaData = useMetaData();
    const childDependencies = useChildDependencies();

    return childDependencies?.map(dpDc => metaData?.[dpDc?.child]);
}

export function useParentDependencies() {
    const dependencies = useDependencies();
    return dependencies?.filter(dpDc => dpDc?.parent);
}

export function useParentDependenciesWithData() {
    const metaData = useMetaData();
    const parentDependencies = useParentDependencies();
    return parentDependencies?.map(dpDc => metaData?.[dpDc?.parent]);
}

export function useIsHighlightDependency() {
    let dependencies = useDependencies();
    return dependencies?.length;
}

export function useIsChildDependencyByColumnId(columnId) {
    const dependencies = useDependencyListWithoutFakeId();
    const dpDc = dependencies.find(dpdc => dpdc.child === columnId);
    return Boolean(dpDc) && dpDc.parent;
}

export function useIsParentDependencyByColumnId(columnId) {
    const dependencies = useDependencyListWithoutFakeId();
    const dpDc = dependencies.find(dpdc => dpdc.parent === columnId);
    return Boolean(dpDc);
}
