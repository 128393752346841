import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { PAYMENT_CARD_TYPES } from 'const';
import VisaSVG from 'assets/images/svg/payment/cards/VisaSVG';
import MasterSVG from 'assets/images/svg/payment/cards/MasterSVG';

const useStyles = makeStyles(theme => ({
    root: {},
    visa: {
        position: 'relative'
    },
    expireDate: {
        color: theme.colors.white,
        position: 'absolute',
        bottom: 20,
        left: 20
    },
    last4Number: {
        color: theme.colors.white,
        position: 'absolute',
        bottom: 40,
        left: 20,
        fontWeight: 500
    },
    spacing: {
        '&:not(:last-child)': {
            paddingRight: 18
        }
    },
    white: {
        color: theme.colors.white
    }
}));

function getCardIconByType(cardType) {
    switch (cardType) {
        case PAYMENT_CARD_TYPES.VISA:
            return <VisaSVG />;
        case PAYMENT_CARD_TYPES.MASTER:
            return <MasterSVG />;
        default:
            return <VisaSVG />;
    }
}

function UserCard({ cardType, expireDate, last4Number = 1234 }) {
    const classes = useStyles();

    const cardIcon = getCardIconByType(cardType);

    return (
        <Grid container>
            <Grid item className={classes.visa}>
                {cardIcon}
                <Grid container className={classes.last4Number}>
                    <Grid item className={classes.spacing}>
                        • • • •
                    </Grid>
                    <Grid item className={classes.spacing}>
                        • • • •
                    </Grid>
                    <Grid item className={classes.spacing}>
                        • • • •
                    </Grid>
                    <Grid item className={`${classes.spacing}`}>
                        <Typography variant="body1" className={classes.white}>
                            {last4Number}
                        </Typography>
                    </Grid>
                </Grid>

                <Typography className={classes.expireDate} variant="body1">
                    Exp: {expireDate}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default React.memo(UserCard);
