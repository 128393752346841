import { CREATE_RECORD_TYPES, RECORDS_RENDER, CACHE_ROWS_COUNT } from 'const/gridUI';
import * as types from '../types';
import { combinedRecordMetaData } from 'utils/gridUI/recordMetadata';
import produce from 'immer';
import { combinedData } from 'utils/gridUI/data';

const handler = {
    [types.ROW_SELECTION](state, { payload }) {
        const { oldRowIndexSelected, rowsRangeIndexes } = payload;

        return {
            ...state,
            oldRowIndexSelected,
            rowsRangeIndexes,
            columnsSelected: [],

            oldColumnIdSelected: null,
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1
        };
    },

    [types.CREATE_ROW](state) {
        return {
            ...state,
            isCreatingRecord: true
        };
    },
    [types.CREATE_RELATIVE_RECORDS_SUCCESS](state, { payload }) {
        const { type, recordIds, selectedRecordId } = payload;
        const { totalRecords, totalRecordsWithoutFilters, gridTotalRecords, rows, rowsRangeIndexes } = state;

        const currentRecordIndex = rows?.findIndex(recordId => recordId === selectedRecordId);

        let newRows = [];

        if (type === CREATE_RECORD_TYPES.ABOVE) {
            newRows = [...rows?.slice(0, currentRecordIndex), ...recordIds, ...rows?.slice(currentRecordIndex)];
        } else {
            newRows = [
                ...rows?.slice(0, Math.max(currentRecordIndex + 1, 0)),
                ...recordIds,
                ...rows?.slice(currentRecordIndex + 1)
            ];
        }
        const newTotalRecordsWithoutFilters = totalRecordsWithoutFilters + recordIds?.length;
        const newTotalRecords = totalRecords + recordIds?.length;
        const newGridTotalRecords = gridTotalRecords + recordIds?.length;

        if (type === CREATE_RECORD_TYPES.ABOVE && rowsRangeIndexes?.length) {
            const selectedIndex = rowsRangeIndexes?.[0]?.[0];
            const newIndex = selectedIndex + recordIds?.length;
            return {
                ...state,
                rows: newRows,
                totalRecordsWithoutFilters: newTotalRecordsWithoutFilters,
                totalRecords: newTotalRecords,
                gridTotalRecords: newGridTotalRecords,
                isCreatingRecord: false,
                rowsRangeIndexes: [[newIndex, newIndex]]
            };
        }

        return {
            ...state,
            rows: newRows,
            totalRecordsWithoutFilters: newTotalRecordsWithoutFilters,
            totalRecords: newTotalRecords,
            gridTotalRecords: newGridTotalRecords,
            isCreatingRecord: false
        };
    },

    [types.CREATE_ROW_SUCCESS](state, { payload }) {
        const { recordIds, isScroll } = payload;
        const { totalRecords, gridTotalRecords, totalRecordsWithoutFilters } = state;
        const rows = [...(state?.rows || []), ...recordIds];

        const newTotalRecordsWithoutFilters = totalRecordsWithoutFilters + recordIds?.length;

        const newTotalRecords = totalRecords + recordIds?.length;
        const newGridTotalRecords = gridTotalRecords + recordIds?.length;

        if (!isScroll) {
            return {
                ...state,
                rows,
                totalRecordsWithoutFilters: newTotalRecordsWithoutFilters,
                totalRecords: newTotalRecords,
                gridTotalRecords: newGridTotalRecords,
                isCreatingRecord: false,
                ROW_STOP_INDEX: Math.max(newTotalRecords, RECORDS_RENDER)
            };
        }

        return {
            ...state,
            rows,
            totalRecordsWithoutFilters: newTotalRecordsWithoutFilters,
            totalRecords: newTotalRecords,
            gridTotalRecords: newGridTotalRecords,
            isCreatingRecord: false
        };
    },

    [types.CREATE_ROWS_SOCKET](state, { payload }) {
        const { recordIds } = payload;
        const { rows, totalRecords, totalRecordsWithoutFilters, gridTotalRecords } = state;

        return {
            ...state,
            rows: [...rows, ...recordIds],
            totalRecords: totalRecords + recordIds?.length,
            totalRecordsWithoutFilters: totalRecordsWithoutFilters + recordIds?.length,
            gridTotalRecords: gridTotalRecords + recordIds?.length
        };
    },

    [types.POPULATE_SYSTEM_DATA_AFTER_RECORD_MODIFIED](state, { payload }) {
        const { recordIds = [], systemData } = payload;
        const data = state?.data;

        const newData = produce(data, draft => {
            for (let recordId of recordIds) {
                if (!draft?.[recordId]) {
                    draft[recordId] = {};
                }

                const systemDataByRecordId = systemData?.[recordId];

                draft[recordId] = {
                    ...draft[recordId],
                    ...systemDataByRecordId
                };
            }
            return draft;
        });
        return {
            ...state,
            data: newData
        };
    },

    [types.CREATE_ROW_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            error,
            isCreatingRecord: false
        };
    },

    [types.DELETE_ROWS](state, { payload }) {
        const { recordIds, deletedCount } = payload;
        const { totalRecords, totalRecordsWithoutFilters } = state;
        return {
            ...state,
            rows: recordIds,
            totalRecords: totalRecords - deletedCount,
            totalRecordsWithoutFilters: totalRecordsWithoutFilters - deletedCount,
            rowsRangeIndexes: [],
            rowIndexMap: {},
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1
        };
    },

    [types.RESET_ROW_DATA](state) {
        return {
            ...state,
            rows: [],
            data: {},
            totalRecords: 0
        };
    },

    [types.FETCH_RECORDS_WITH_FILTERS_SORTS](state) {
        return {
            ...state,
            isFilteringSorting: true
        };
    },

    [types.FETCH_RECORDS_WITH_FILTERS_SORTS_FAILED](state) {
        return {
            ...state,
            isFilteringSorting: false
        };
    },

    [types.FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS](state, { payload }) {
        const {
            columns,
            rows,
            data,
            totalRecords,
            recordMetaData,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        } = payload;

        const { recordMetaData: recordMetaDataStore } = state;
        return {
            ...state,
            isFilteringSorting: false,
            totalRecordsWithoutFilters,
            columns,
            rows,
            data,
            totalRecords,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            recordMetaData: combinedRecordMetaData({
                recordMetaData: recordMetaDataStore,
                newRecordMetaData: recordMetaData
            }),
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1,
            copiedRange: {
                rowStartIndex: -1,
                rowStopIndex: -1,
                columnStartIndex: -1,
                columnStopIndex: -1
            }
        };
    },

    [types.FETCH_MORE_ROWS](state) {
        return {
            ...state,
            isFetchingMore: true
        };
    },

    [types.FETCH_MORE_ROWS_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            error,
            isFetchingMore: false
        };
    },

    [types.FETCH_MORE_ROWS_SUCCESS](state, { payload }) {
        const {
            columns,
            rows,
            data: newData,
            totalRecords,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        } = payload;
        const { data } = state;
        /**
         * Improve cache later
         */
        const rowCounts = Object.keys(data)?.length;
        const dataCache = rowCounts > CACHE_ROWS_COUNT ? newData : combinedData({ data, newData, isCareData: true });

        return {
            ...state,
            columns,
            rows,
            data: dataCache,
            totalRecords,
            isFetchingMore: false,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        };
    },
    [types.UPDATE_RECORD_METADATA](state, { payload }) {
        const { newRecordMetaData } = payload;
        const { recordMetaData } = state;
        return {
            ...state,
            recordMetaData: combinedRecordMetaData({ recordMetaData, newRecordMetaData })
        };
    },
    [types.DELETE_ROWS_SELECTION_INFO](state, { payload }) {
        const { newRowsData } = payload;
        return {
            ...state,
            data: newRowsData
        };
    },
    [types.TURN_OFF_DRAGGING_ROWS](state, { payload }) {
        return {
            ...state,
            isDraggingRows: false
        };
    },
    [types.TURN_ON_DRAGGING_ROWS](state, { payload }) {
        return {
            ...state,
            isDraggingRows: true
        };
    },
    [types.FETCH_UPDATE_OTHER_REF_CELL](state, { payload }) {
        const { newData } = payload;
        return {
            ...state,
            data: newData
        };
    },
    [types.SET_DROP_RECORD_ID](state, { payload }) {
        const { afterRecordId, beforeRecordId, currentRecordId, highlight, currentRowIndex } = payload;
        return {
            ...state,
            beforeRecordId,
            afterRecordId,
            currentRecordId,
            reorderHighlight: highlight,
            currentRowIndex
        };
    },
    [types.REORDER_RECORDS](state, { payload }) {
        const { newRows = {} } = payload;
        return {
            ...state,
            rows: newRows,
            rowsRangeIndexes: []
        };
    },
    [types.CHANGE_DEFAULT_ROW_HEIGHT](state, { payload }) {
        const { height } = payload;

        return {
            ...state,
            rowHeight: height
        };
    },
    [types.SET_ROW_IDS](state, { payload }) {
        const { recordIds, count } = payload;
        const { totalRecords } = state;
        return {
            ...state,
            rows: recordIds,
            totalRecords: Math.max(0, totalRecords - count),
            rowsRangeIndexes: [],
            rowIndexMap: {},
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1
        };
    },
    [types.TOGGLE_DELETING_RECORDS](state, { payload }) {
        const { isDeletingRecords } = state;

        return {
            ...state,
            isDeletingRecords: !isDeletingRecords
        };
    },
    [types.DELETE_ALL_RECORDS](state, { payload }) {
        return {
            ...state,
            rows: [],
            totalRecords: 0,
            totalRecordsWithoutFilters: 0
        };
    },
    [types.SET_SECTION_SCROLL](state, { payload }) {
        return {
            ...state,
            ...payload,
            rows: []
        };
    }
};

export default handler;
