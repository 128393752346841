import { useSelector } from 'react-redux';

export function useApiKeys() {
    return useSelector(state => state.permission.apiKeys);
}

export function useApiKeyList() {
    const apiKeys = useApiKeys();
    return apiKeys?.list?.filter(key => !key?.isDeleted);
}

export function useIsFetching() {
    const apiKeys = useApiKeys();
    return apiKeys?.isFetching;
}

export function useApiKeyDetailByApiKeyId(apiKeyId) {
    const apiKeys = useApiKeyList();
    return apiKeys?.find(key => key?.value === apiKeyId);
}
