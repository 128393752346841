import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';

const DividerBase = withStyles(theme => ({
    root: {
        background: theme.colors.divider,
    },
}))(props => <Divider color="default" {...props} />);

export default React.memo(DividerBase);