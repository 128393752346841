import React from 'react';

function TMGuideSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="139" height="189" viewBox="0 0 139 189" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path opacity="0.5" d="M138.454 188.145H6.35156L20.0543 162.636H124.752L138.454 188.145Z" fill="#E9EAEF" />
            <path d="M124.803 128.745H19.9062L30.7886 108.49H113.921L124.803 128.745Z" fill="#D99C65" />
            <path d="M124.705 128.745H20.0078V177.091H124.705V128.745Z" fill="#E6BB98" />
            <path d="M124.803 78.4727H19.9062L30.7886 108.491H113.921L124.803 78.4727Z" fill="#F4DCCA" />
            <g style={{ mixBlendMode: `multiply` }} opacity="0.5">
                <path d="M113.922 108.49V128.745H124.704L113.922 108.49Z" fill="#BD844C" />
            </g>
            <g style={{ mixBlendMode: `multiply` }} opacity="0.5">
                <path d="M30.7904 108.49V128.745H20.0078L30.7904 108.49Z" fill="#BD844C" />
            </g>
            <path
                d="M118.233 92.3903C121.335 91.863 124.69 96.3903 125.77 102.527V102.336C124.473 94.6994 120.891 88.9357 117.771 89.4721C114.651 90.0085 113.164 96.6266 114.461 104.263C114.461 104.327 114.461 104.39 114.461 104.454C113.472 98.3357 115.141 92.8812 118.233 92.3903Z"
                fill="#46BCEC"
            />
            <path
                d="M122.052 114.9C119.186 115.382 116.112 111.545 114.797 106.118C116.311 112.809 119.558 117.609 122.424 117.127C125.289 116.645 126.767 111.027 125.997 104.209C126.559 109.764 124.917 114.409 122.052 114.9Z"
                fill="#FAAF1B"
            />
            <path
                d="M30.3551 92.3903C27.2627 91.863 23.9073 96.3903 22.8281 102.527V102.336C24.1249 94.6994 27.7071 88.9357 30.8267 89.4721C33.9463 90.0085 35.4335 96.6266 34.1367 104.263C34.1367 104.327 34.1367 104.39 34.1367 104.454C35.1252 98.3357 33.4566 92.8812 30.3551 92.3903Z"
                fill="#FAAF1B"
            />
            <path
                d="M26.546 114.9C29.4026 115.382 32.486 111.545 33.8009 106.118C32.2774 112.809 29.0399 117.609 26.1742 117.127C23.3085 116.645 21.8303 111.027 22.6011 104.209C22.0389 109.764 23.6803 114.409 26.546 114.9Z"
                fill="#46BCEC"
            />
            <path
                d="M106.549 87.8182C104.592 88.2124 102.868 89.3575 101.742 91.0091C102.526 90.4044 103.444 89.9989 104.417 89.8273C109.75 88.9182 115.3 95.4819 116.841 104.491C118.383 113.5 115.282 121.536 109.949 122.446C108.975 122.602 107.977 122.524 107.038 122.218C108.642 123.409 110.649 123.918 112.625 123.636C118.483 122.636 121.875 113.809 120.197 103.918C118.519 94.0273 112.407 86.8182 106.549 87.8182Z"
                fill="#E43383"
            />
            <path
                d="M42.0448 87.8182C44.0042 88.2102 45.7327 89.3556 46.8603 91.0091C46.0768 90.4044 45.1589 89.9989 44.185 89.8273C38.8527 88.9182 33.3027 95.4819 31.761 104.491C30.2193 113.5 33.3208 121.536 38.6531 122.446C39.6278 122.602 40.6254 122.524 41.5642 122.218C39.96 123.408 37.9535 123.917 35.9779 123.636C30.1196 122.636 26.7279 113.809 28.4056 103.918C30.0833 94.0273 36.1865 86.8182 42.0448 87.8182Z"
                fill="#E43383"
            />
            <path
                d="M113.53 109.845C112.351 103.545 110.809 97.1177 107.264 91.6631C106.883 91.0813 106.484 90.5086 106.057 89.954C98.1677 79.5631 84.2927 75.1449 71.5966 75.8631C60.2337 76.5086 48.653 81.1995 41.8606 90.6449C37.3263 97.0086 35.168 104.754 34.7145 112.463C34.4062 117.745 34.3971 123.954 37.7072 128.454C52.6342 148.754 95.8734 148.454 110.61 128.454C114.6 123.09 114.709 116.154 113.53 109.845Z"
                fill="url(#paint0_linear_2121_34977)"
            />
            <path
                d="M72.6082 123.727C65.8516 123.805 59.1112 123.047 52.5393 121.473C47.5607 120.227 44.6224 118.018 43.7881 114.909C41.8565 107.636 43.2531 97.5725 49.6555 92.2725C53.4618 89.1036 58.1007 87.1053 63.0136 86.518C70.4313 85.5604 77.941 85.5604 85.3587 86.518C90.2739 87.1009 94.916 89.0962 98.7259 92.2634C105.074 97.5634 106.516 107.663 104.584 114.9C103.822 117.773 101.093 119.909 96.4859 121.263C89.7207 123.218 81.8945 123.6 74.4401 123.727H72.6082Z"
                fill="#9863A9"
            />
            <path
                d="M72.7218 121.418C66.5651 121.482 60.4223 120.817 54.4213 119.436C49.887 118.345 47.1664 116.409 46.459 113.682C44.6997 107.318 45.9693 98.4725 51.8005 93.8179C55.3077 91.0257 59.5286 89.2803 63.9796 88.7816C70.7404 87.9452 77.578 87.9452 84.3387 88.7816C88.7987 89.2691 93.0319 91.0049 96.5541 93.7907C102.385 98.4452 103.655 107.3 101.896 113.654C101.197 116.172 98.7125 118.045 94.5137 119.218C88.347 120.936 81.21 121.272 74.4176 121.372H72.7581L72.7218 121.418Z"
                fill="#121212"
            />
            <path
                d="M72.7209 119.8C66.6924 119.865 60.6774 119.215 54.8013 117.863C50.8837 116.954 48.5984 115.363 48.0089 113.245C46.3947 107.409 47.5192 99.2997 52.8062 95.0633C56.0786 92.4524 60.0211 90.823 64.1783 90.3633C70.8032 89.5391 77.5043 89.5391 84.1292 90.3633C88.2887 90.8247 92.2337 92.4539 95.5104 95.0633C100.788 99.2724 101.913 107.381 100.299 113.245C99.7635 115.181 97.6596 116.672 94.0412 117.681C88.0559 119.354 81.0459 119.681 74.3623 119.781H72.7209V119.8Z"
                fill="#17171A"
            />
            <path
                d="M53.5775 88.5087L54.6386 87.5996L50.4851 82.4814C50.1043 82.736 49.7234 82.9815 49.3516 83.2542L53.5775 88.5087Z"
                fill="white"
            />
            <path
                d="M99.2915 83.3997C98.9197 83.136 98.5388 82.8815 98.1579 82.627L93.8594 87.936L94.9204 88.8451L99.2915 83.3997Z"
                fill="white"
            />
            <path
                d="M69.8325 61.4706L69.6421 61.1399C69.5895 61.0486 69.473 61.0173 69.3819 61.0701L64.0415 64.161C63.9504 64.2137 63.9192 64.3305 63.9718 64.4218L64.1622 64.7524C64.2148 64.8437 64.3313 64.875 64.4223 64.8223L69.7628 61.7314C69.8539 61.6787 69.8851 61.5619 69.8325 61.4706Z"
                fill="white"
            />
            <path
                d="M63.2712 65.6813C63.5116 65.6813 63.7065 65.486 63.7065 65.245C63.7065 65.004 63.5116 64.8086 63.2712 64.8086C63.0308 64.8086 62.8359 65.004 62.8359 65.245C62.8359 65.486 63.0308 65.6813 63.2712 65.6813Z"
                fill="white"
            />
            <path
                d="M99.0643 90.6546C94.9845 87.3981 90.0721 85.3597 84.89 84.7727C77.7867 83.8817 70.6004 83.8817 63.4971 84.7727C58.3141 85.3563 53.4009 87.3951 49.3228 90.6546C42.3763 96.1909 40.3177 106.536 42.603 114.764C43.9996 119.827 48.7697 122.227 53.4853 123.373C60.3602 124.941 67.4038 125.641 74.452 125.455C81.3804 125.345 88.8621 125.009 95.5728 123.145C99.9983 121.909 104.442 119.509 105.757 114.764C108.088 106.518 106.011 96.1909 99.0643 90.6546ZM103.444 114.109C102.592 117.218 99.7263 119.473 94.9471 120.8C88.5991 122.573 81.3442 122.909 74.4429 123.018H72.7562C66.474 123.086 60.206 122.405 54.0839 120.991C48.9329 119.755 45.8586 117.436 44.9336 114.091C43.0292 107.236 44.4439 97.6273 50.8282 92.5364C54.567 89.5615 59.0629 87.697 63.8054 87.1545C70.705 86.3061 77.6821 86.3061 84.5817 87.1545C89.3243 87.697 93.8201 89.5615 97.5589 92.5364C103.934 97.6364 105.367 107.255 103.444 114.109Z"
                fill="url(#paint1_linear_2121_34977)"
            />
            <path
                d="M63.8247 59.1541C62.8725 59.736 61.9022 60.3087 60.95 60.9087C60.5858 61.1327 60.2836 61.4449 60.0712 61.8167C59.8589 62.1884 59.7432 62.6077 59.7348 63.036C59.7265 63.4954 59.8438 63.9484 60.074 64.3458C60.3042 64.7432 60.6385 65.0699 61.0407 65.2905L70.8257 71.5178C71.8563 72.2055 73.0613 72.5839 74.299 72.6087C75.5405 72.621 76.7592 72.2738 77.8086 71.6087L84.8912 67.345L87.6752 65.6632C88.0866 65.458 88.4342 65.1441 88.6805 64.7553C88.9267 64.3664 89.0623 63.9175 89.0726 63.4571C89.0829 62.9966 88.9674 62.5421 88.7387 62.1426C88.51 61.7431 88.1768 61.4139 87.775 61.1905L80.239 56.3814L77.3823 58.0905C76.9953 58.307 76.6717 58.6217 76.4441 59.003C76.2165 59.3844 76.0929 59.8189 76.0855 60.2632C76.0813 60.7209 76.1993 61.1713 76.4273 61.5677C76.6554 61.9642 76.9851 62.2922 77.3823 62.5178L78.6338 63.2996L74.3806 65.836L71.2338 63.8269L70.1546 63.145L73.2652 61.3269C73.6297 61.103 73.9316 60.7902 74.1429 60.4176C74.3541 60.0451 74.4678 59.6249 74.4732 59.1965C74.4786 58.768 74.3757 58.3451 74.1739 57.9673C73.9722 57.5895 73.6783 57.2691 73.3196 57.036L70.4267 55.2178L63.8519 59.145"
                fill="url(#paint2_linear_2121_34977)"
            />
            <path
                opacity="0.47"
                d="M70.97 57.9131L70.6209 57.3069C70.5282 57.146 70.323 57.0909 70.1625 57.1838L65.0655 60.1338C64.905 60.2267 64.85 60.4324 64.9427 60.5933L65.2918 61.1995C65.3845 61.3604 65.5897 61.4155 65.7502 61.3226L70.8472 58.3726C71.0077 58.2797 71.0627 58.074 70.97 57.9131Z"
                fill="white"
            />
            <path
                opacity="0.47"
                d="M63.7809 62.5815C64.2467 62.5815 64.6243 62.203 64.6243 61.7361C64.6243 61.2691 64.2467 60.8906 63.7809 60.8906C63.3151 60.8906 62.9375 61.2691 62.9375 61.7361C62.9375 62.203 63.3151 62.5815 63.7809 62.5815Z"
                fill="white"
            />
            <path
                d="M64.5607 90.3546C60.0472 91.016 55.7888 92.8634 52.2183 95.7092C52.0551 95.8365 51.91 95.9819 51.7558 96.1183C47.4482 100.527 46.5958 107.855 48.083 113.246C48.6725 115.364 50.9578 116.882 54.8754 117.864C55.9818 118.127 57.1154 118.355 58.258 118.564L59.972 98.8092C60.074 97.6217 60.4678 96.4779 61.1182 95.4801C61.7685 94.4824 62.6553 93.6615 63.6992 93.091L69.5122 89.9092C67.7892 90.0092 66.1296 90.1546 64.5607 90.3546Z"
                fill="url(#paint3_linear_2121_34977)"
            />
            <path d="M138.407 154.254H6.30469L20.0074 128.745H124.705L138.407 154.254Z" fill="#F4DCCA" />
            <path
                d="M61.7627 112.509C66.05 112.509 69.5255 109.025 69.5255 104.727C69.5255 100.429 66.05 96.9453 61.7627 96.9453C57.4755 96.9453 54 100.429 54 104.727C54 109.025 57.4755 112.509 61.7627 112.509Z"
                fill="white"
            />
            <path
                d="M89.0674 112.509C93.3547 112.509 96.8302 109.025 96.8302 104.727C96.8302 100.429 93.3547 96.9453 89.0674 96.9453C84.7802 96.9453 81.3047 100.429 81.3047 104.727C81.3047 109.025 84.7802 112.509 89.0674 112.509Z"
                fill="white"
            />
            <path
                d="M89.0432 96.9453C88.7124 96.9466 88.3821 96.9709 88.0547 97.018C89.9549 97.225 91.712 98.1282 92.9885 99.5544C94.265 100.981 94.9711 102.829 94.9711 104.745C94.9711 106.661 94.265 108.51 92.9885 109.936C91.712 111.362 89.9549 112.266 88.0547 112.473C88.3827 112.512 88.7127 112.533 89.0432 112.536C91.102 112.536 93.0765 111.716 94.5323 110.257C95.9881 108.798 96.8059 106.818 96.8059 104.754C96.8059 102.691 95.9881 100.711 94.5323 99.2518C93.0765 97.7925 91.102 96.9726 89.0432 96.9726V96.9453Z"
                fill="#EDE9EE"
            />
            <path
                d="M61.7627 96.9453C62.0905 96.9462 62.4178 96.9705 62.7422 97.018C60.8419 97.225 59.0849 98.1282 57.8084 99.5544C56.5318 100.981 55.8258 102.829 55.8258 104.745C55.8258 106.661 56.5318 108.51 57.8084 109.936C59.0849 111.362 60.8419 112.266 62.7422 112.473C62.4172 112.512 62.0902 112.534 61.7627 112.536C59.7039 112.536 57.7295 111.716 56.2737 110.257C54.8179 108.798 54 106.818 54 104.754C54 102.691 54.8179 100.711 56.2737 99.2518C57.7295 97.7925 59.7039 96.9726 61.7627 96.9726V96.9453Z"
                fill="#EDE9EE"
            />
            <path
                d="M63.7786 106.29C64.64 106.29 65.3384 105.59 65.3384 104.727C65.3384 103.863 64.64 103.163 63.7786 103.163C62.9171 103.163 62.2188 103.863 62.2188 104.727C62.2188 105.59 62.9171 106.29 63.7786 106.29Z"
                fill="#17171A"
            />
            <path
                d="M85.552 106.29C86.4134 106.29 87.1118 105.59 87.1118 104.727C87.1118 103.863 86.4134 103.163 85.552 103.163C84.6905 103.163 83.9922 103.863 83.9922 104.727C83.9922 105.59 84.6905 106.29 85.552 106.29Z"
                fill="#17171A"
            />
            <path
                d="M76.0021 114.7C76.4606 114.712 76.915 114.611 77.3258 114.406C77.7367 114.202 78.0917 113.9 78.3599 113.527C78.4855 113.355 78.5379 113.139 78.5056 112.928C78.4733 112.717 78.359 112.527 78.1876 112.4C78.1032 112.335 78.0067 112.288 77.9038 112.262C77.8009 112.236 77.6937 112.23 77.5887 112.246C77.4837 112.263 77.383 112.3 77.2927 112.356C77.2024 112.412 77.1243 112.486 77.0631 112.573C76.8396 112.82 76.5421 112.989 76.2151 113.053C75.8881 113.117 75.5493 113.073 75.2494 112.927C75.0531 112.844 74.8322 112.842 74.634 112.92C74.4357 112.998 74.2758 113.151 74.1883 113.346C74.1447 113.442 74.1206 113.546 74.1175 113.652C74.1144 113.758 74.1324 113.864 74.1704 113.963C74.2083 114.062 74.2656 114.152 74.3387 114.229C74.4119 114.306 74.4995 114.367 74.5964 114.409C75.0404 114.601 75.5187 114.7 76.0021 114.7Z"
                fill="#EDE9EE"
            />
            <path
                d="M34.1062 68.8088L33.0089 63.9179C32.815 63.0879 32.8495 62.2207 33.1087 61.4088C34.2859 57.7084 34.1285 53.711 32.6639 50.1151C31.1994 46.5193 28.5208 43.5537 25.0965 41.7367C21.6721 39.9198 17.7196 39.3669 13.9299 40.1748C10.1401 40.9827 6.75412 43.1 4.36361 46.1566C1.97309 49.2132 0.730121 53.0148 0.851922 56.8969C0.973723 60.779 2.45254 64.4947 5.02994 67.3947C7.60734 70.2946 11.1194 72.1944 14.9523 72.762C18.7852 73.3296 22.6952 72.5288 25.9989 70.4997C26.9064 69.9256 27.9772 69.6668 29.0459 69.7633L32.9273 70.1361C33.0998 70.1549 33.2742 70.1322 33.4361 70.0697C33.598 70.0073 33.7427 69.9069 33.8581 69.777C33.9734 69.6472 34.0561 69.4915 34.0993 69.3231C34.1425 69.1547 34.1449 68.9783 34.1062 68.8088Z"
                fill="#7B67AD"
            />
            <path
                d="M21.9346 50.3448L17.4909 54.7902L13.0564 50.3448C12.8399 50.1278 12.5463 50.0059 12.2402 50.0059C11.9341 50.0059 11.6405 50.1278 11.424 50.3448C11.2076 50.5618 11.0859 50.8561 11.0859 51.163C11.0859 51.4698 11.2076 51.7642 11.424 51.9812L15.8586 56.4266L11.424 60.8812C11.2076 61.0982 11.0859 61.3925 11.0859 61.6993C11.0859 62.0062 11.2076 62.3005 11.424 62.5175C11.6405 62.7345 11.9341 62.8564 12.2402 62.8564C12.5463 62.8564 12.8399 62.7345 13.0564 62.5175L17.4909 58.0721L21.9346 62.5175C22.0416 62.6253 22.1688 62.7108 22.3088 62.7691C22.4489 62.8274 22.5991 62.8575 22.7507 62.8575C22.9024 62.8575 23.0526 62.8274 23.1926 62.7691C23.3327 62.7108 23.4599 62.6253 23.5669 62.5175C23.7796 62.2985 23.8987 62.005 23.8987 61.6993C23.8987 61.3937 23.7796 61.1001 23.5669 60.8812L19.1324 56.4266L23.5941 51.9721C23.8068 51.7531 23.9259 51.4595 23.9259 51.1539C23.9259 50.8482 23.8068 50.5547 23.5941 50.3357C23.4861 50.2244 23.3567 50.1361 23.2138 50.076C23.0709 50.016 22.9173 49.9855 22.7624 49.9863C22.6075 49.9872 22.4543 50.0194 22.312 50.081C22.1698 50.1426 22.0414 50.2323 21.9346 50.3448Z"
                fill="white"
            />
            <path
                d="M58.9385 39.6027C60.8467 42.1469 64.853 42.22 66.0008 43.4701C65.6645 39.5588 63.9391 36.3202 61.7459 35.5818C59.5526 34.8434 56.9793 36.9708 58.9385 39.6027Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M58.9385 39.6027C60.8467 42.1469 64.853 42.22 66.0008 43.4701C65.6645 39.5588 63.9391 36.3202 61.7459 35.5818C59.5526 34.8434 56.9793 36.9708 58.9385 39.6027Z"
                fill="white"
            />
            <path
                d="M56.3587 35.8891C56.709 36.0379 57.0709 36.1577 57.4407 36.2473C57.9232 36.3935 58.4057 36.547 58.9029 36.7225C59.7008 36.9984 60.4638 37.3665 61.1765 37.8191C61.8648 38.2679 62.5043 38.7873 63.0846 39.369C63.6672 39.984 64.2093 40.636 64.7076 41.321C64.7076 41.321 64.8027 41.321 64.7881 41.321C64.0701 39.8132 62.933 38.5441 61.5128 37.6656C60.7614 37.1778 59.9538 36.7826 59.1076 36.4885C58.2478 36.1562 57.3482 35.9375 56.4318 35.8379C56.4318 35.8379 56.3002 35.8598 56.3587 35.8891Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M56.3587 35.8891C56.709 36.0379 57.0709 36.1577 57.4407 36.2473C57.9232 36.3935 58.4057 36.547 58.9029 36.7225C59.7008 36.9984 60.4638 37.3665 61.1765 37.8191C61.8648 38.2679 62.5043 38.7873 63.0846 39.369C63.6672 39.984 64.2093 40.636 64.7076 41.321C64.7076 41.321 64.8027 41.321 64.7881 41.321C64.0701 39.8132 62.933 38.5441 61.5128 37.6656C60.7614 37.1778 59.9538 36.7826 59.1076 36.4885C58.2478 36.1562 57.3482 35.9375 56.4318 35.8379C56.4318 35.8379 56.3002 35.8598 56.3587 35.8891Z"
                fill="black"
            />
            <path
                d="M108.274 23.7455C111.367 23.38 113.392 20.017 115.008 19.6588C111.535 18.0212 107.945 17.9042 106.235 19.4029C104.524 20.9016 105.094 24.1257 108.274 23.7455Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M108.274 23.7455C111.367 23.38 113.392 20.017 115.008 19.6588C111.535 18.0212 107.945 17.9042 106.235 19.4029C104.524 20.9016 105.094 24.1257 108.274 23.7455Z"
                fill="white"
            />
            <path
                d="M103.862 24.1174C104.162 23.8973 104.443 23.6527 104.703 23.3863C105.061 23.05 105.434 22.7137 105.807 22.392C107.048 21.3242 108.519 20.5585 110.106 20.1549C110.909 19.9587 111.725 19.817 112.547 19.7309C112.547 19.7309 112.606 19.6578 112.547 19.6505C110.918 19.5246 109.287 19.867 107.847 20.6375C107.06 21.0363 106.324 21.5271 105.653 22.0996C104.95 22.6672 104.324 23.3233 103.789 24.0516C103.789 24.0516 103.811 24.1539 103.862 24.1174Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M103.862 24.1174C104.162 23.8973 104.443 23.6527 104.703 23.3863C105.061 23.05 105.434 22.7137 105.807 22.392C107.048 21.3242 108.519 20.5585 110.106 20.1549C110.909 19.9587 111.725 19.817 112.547 19.7309C112.547 19.7309 112.606 19.6578 112.547 19.6505C110.918 19.5246 109.287 19.867 107.847 20.6375C107.06 21.0363 106.324 21.5271 105.653 22.0996C104.95 22.6672 104.324 23.3233 103.789 24.0516C103.789 24.0516 103.811 24.1539 103.862 24.1174Z"
                fill="black"
            />
            <path
                d="M30.8234 6.22146C32.4391 8.88258 36.343 9.32122 37.3446 10.6518C37.3446 6.80632 35.9994 3.49454 33.9305 2.56607C31.8615 1.6376 29.1565 3.49454 30.8234 6.22146Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M30.8234 6.22146C32.4391 8.88258 36.343 9.32122 37.3446 10.6518C37.3446 6.80632 35.9994 3.49454 33.9305 2.56607C31.8615 1.6376 29.1565 3.49454 30.8234 6.22146Z"
                fill="white"
            />
            <path
                d="M28.6547 2.36115C28.9793 2.54415 29.3193 2.69826 29.6709 2.82172C30.1291 3.00693 30.5823 3.20433 31.0307 3.4139C31.782 3.7574 32.491 4.18672 33.1435 4.69329C33.7744 5.19378 34.3503 5.75989 34.8616 6.38208C35.3726 7.03474 35.8391 7.72106 36.2579 8.4364C36.2579 8.4364 36.353 8.4364 36.3383 8.4364C35.781 6.89916 34.7908 5.55601 33.4871 4.569C32.7992 4.02292 32.0481 3.56147 31.25 3.19458C30.4426 2.79382 29.5878 2.4966 28.7059 2.30997C28.6693 2.29535 28.5962 2.33191 28.6547 2.36115Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M28.6547 2.36115C28.9793 2.54415 29.3193 2.69826 29.6709 2.82172C30.1291 3.00693 30.5823 3.20433 31.0307 3.4139C31.782 3.7574 32.491 4.18672 33.1435 4.69329C33.7744 5.19378 34.3503 5.75989 34.8616 6.38208C35.3726 7.03474 35.8391 7.72106 36.2579 8.4364C36.2579 8.4364 36.353 8.4364 36.3383 8.4364C35.781 6.89916 34.7908 5.55601 33.4871 4.569C32.7992 4.02292 32.0481 3.56147 31.25 3.19458C30.4426 2.79382 29.5878 2.4966 28.7059 2.30997C28.6693 2.29535 28.5962 2.33191 28.6547 2.36115Z"
                fill="black"
            />
            <path
                d="M113.687 26.1953C112.589 27.326 111.362 28.3246 110.032 29.1708C110.023 29.1785 110.011 29.1828 109.999 29.1828C109.987 29.1828 109.975 29.1785 109.966 29.1708C109.958 29.1604 109.953 29.1475 109.953 29.1342C109.953 29.121 109.958 29.1081 109.966 29.0977C111.316 28.2804 112.566 27.3062 113.687 26.1953Z"
                fill="#37474F"
            />
            <path
                d="M105.87 31.4161C104.44 32.0647 102.976 32.6358 101.484 33.1268C101.465 33.1337 101.445 33.1366 101.425 33.1355C101.406 33.1343 101.386 33.1291 101.368 33.1203C101.351 33.1114 101.335 33.0989 101.322 33.0837C101.309 33.0685 101.299 33.0508 101.294 33.0318C101.284 32.9931 101.289 32.9522 101.308 32.917C101.327 32.8819 101.358 32.8551 101.396 32.8417C102.898 32.3852 104.375 31.8483 105.819 31.2333C105.842 31.2289 105.866 31.2323 105.886 31.2431C105.907 31.2539 105.923 31.2713 105.932 31.2926C105.942 31.3138 105.944 31.3377 105.938 31.3601C105.932 31.3826 105.918 31.4023 105.9 31.4161H105.87Z"
                fill="#37474F"
            />
            <path
                d="M96.9062 34.3973C95.3636 34.7628 93.8064 35.0333 92.2419 35.2599C92.1801 35.2663 92.1183 35.2485 92.0693 35.2104C92.0202 35.1722 91.9878 35.1167 91.9787 35.0552C91.9704 34.9942 91.9864 34.9324 92.0234 34.8831C92.0603 34.8338 92.1152 34.8011 92.1761 34.792C93.7333 34.5947 95.2759 34.3242 96.8111 34.0098C96.8625 33.9982 96.9164 34.0074 96.961 34.0355C97.0055 34.0636 97.0371 34.1083 97.0488 34.1597C97.0604 34.211 97.0511 34.2649 97.023 34.3095C96.9949 34.3541 96.9503 34.3856 96.8989 34.3973H96.9062Z"
                fill="#37474F"
            />
            <path
                d="M87.5385 35.7127C85.9594 35.7932 84.3729 35.8078 82.7938 35.7127C82.7557 35.7118 82.7182 35.7033 82.6835 35.6876C82.6488 35.6719 82.6175 35.6495 82.5916 35.6215C82.5657 35.5936 82.5457 35.5607 82.5327 35.5249C82.5197 35.4891 82.514 35.451 82.516 35.413C82.5179 35.3746 82.5273 35.3369 82.5438 35.3021C82.5602 35.2674 82.5834 35.2362 82.6119 35.2104C82.6404 35.1846 82.6738 35.1646 82.71 35.1517C82.7462 35.1388 82.7846 35.1332 82.8231 35.1352C84.3876 35.2132 85.9521 35.2132 87.5166 35.1352C87.5508 35.1332 87.585 35.1381 87.6173 35.1496C87.6496 35.1612 87.6792 35.1791 87.7044 35.2022C87.7297 35.2254 87.75 35.2534 87.7642 35.2846C87.7784 35.3158 87.7861 35.3495 87.7871 35.3838C87.797 35.4221 87.7981 35.4623 87.7903 35.5011C87.7826 35.54 87.7661 35.5766 87.7422 35.6083C87.7183 35.6399 87.6876 35.6657 87.6523 35.6838C87.6171 35.7019 87.5781 35.7118 87.5385 35.7127Z"
                fill="#37474F"
            />
            <path
                d="M78.0349 35.2671C76.4632 35.0167 74.9117 34.6527 73.3926 34.1778C73.3096 34.1446 73.2425 34.0811 73.2047 34.0001C73.167 33.9192 73.1616 33.8269 73.1895 33.742C73.2175 33.6572 73.2768 33.5862 73.3552 33.5436C73.4337 33.5009 73.5255 33.4898 73.6119 33.5125C75.091 33.9974 76.6034 34.3737 78.1373 34.6383C78.1833 34.6393 78.2286 34.6501 78.2701 34.6701C78.3116 34.6901 78.3483 34.7187 78.3778 34.7541C78.4073 34.7895 78.4288 34.8308 78.4409 34.8752C78.453 34.9197 78.4555 34.9662 78.4481 35.0116C78.4407 35.0571 78.4236 35.1004 78.398 35.1387C78.3724 35.177 78.3389 35.2093 78.2998 35.2335C78.2606 35.2578 78.2167 35.2733 78.171 35.2791C78.1253 35.2849 78.0789 35.2808 78.0349 35.2671Z"
                fill="#37474F"
            />
            <path
                d="M69.0243 32.2549C67.6394 31.4369 66.3582 30.455 65.2081 29.3306C65.1397 29.2586 65.1016 29.163 65.1016 29.0637C65.1016 28.9644 65.1397 28.8689 65.2081 28.7969C65.2792 28.7268 65.3751 28.6875 65.4749 28.6875C65.5748 28.6875 65.6707 28.7268 65.7418 28.7969C66.8442 29.879 68.0712 30.8263 69.3972 31.6188C69.4438 31.6399 69.4854 31.6705 69.5195 31.7087C69.5535 31.7469 69.5792 31.7918 69.5948 31.8405C69.6103 31.8892 69.6155 31.9406 69.61 31.9915C69.6045 32.0423 69.5883 32.0915 69.5626 32.1357C69.5369 32.1799 69.5022 32.2182 69.4608 32.2482C69.4193 32.2782 69.3721 32.2991 69.322 32.3096C69.272 32.3202 69.2202 32.3201 69.1702 32.3094C69.1202 32.2987 69.073 32.2777 69.0316 32.2476L69.0243 32.2549Z"
                fill="#37474F"
            />
            <path
                d="M62.2565 25.5514C61.45 24.1336 60.9401 22.5666 60.7578 20.9456C60.7566 20.8471 60.7923 20.7516 60.8579 20.678C60.9234 20.6044 61.0142 20.558 61.1122 20.548C61.2103 20.5379 61.3085 20.5649 61.3877 20.6236C61.4669 20.6823 61.5212 20.7685 61.5401 20.8652C61.709 22.3817 62.1814 23.8486 62.9291 25.1786C62.9537 25.2232 62.9691 25.2722 62.9747 25.3229C62.9802 25.3735 62.9757 25.4247 62.9615 25.4736C62.9472 25.5225 62.9235 25.5681 62.8916 25.6078C62.8597 25.6475 62.8203 25.6805 62.7756 25.7049C62.7315 25.7293 62.683 25.7446 62.633 25.7501C62.5829 25.7556 62.5323 25.751 62.484 25.7367C62.4357 25.7224 62.3907 25.6987 62.3517 25.6669C62.3126 25.6351 62.2803 25.5958 62.2565 25.5514Z"
                fill="#37474F"
            />
            <path
                d="M61.3683 16.1207C61.9551 14.5595 62.9683 13.1942 64.2926 12.1802C64.3357 12.1472 64.3849 12.1231 64.4373 12.1093C64.4898 12.0954 64.5445 12.0921 64.5982 12.0996C64.6519 12.1071 64.7037 12.1252 64.7504 12.1528C64.797 12.1805 64.8378 12.2171 64.8702 12.2607C64.9034 12.3043 64.9277 12.3541 64.9416 12.4071C64.9555 12.4602 64.9589 12.5154 64.9514 12.5698C64.9439 12.6241 64.9258 12.6765 64.8981 12.7238C64.8703 12.7711 64.8335 12.8125 64.7897 12.8455C63.601 13.7684 62.6923 15.0036 62.1652 16.4132C62.1547 16.472 62.1313 16.5277 62.0966 16.5764C62.0619 16.625 62.0168 16.6653 61.9646 16.6943C61.9124 16.7233 61.8544 16.7404 61.7947 16.7441C61.7351 16.7479 61.6754 16.7384 61.62 16.7162C61.5645 16.694 61.5147 16.6597 61.4741 16.6159C61.4335 16.572 61.4032 16.5197 61.3854 16.4627C61.3676 16.4056 61.3628 16.3454 61.3712 16.2862C61.3796 16.2271 61.4011 16.1705 61.4341 16.1207H61.3683Z"
                fill="#37474F"
            />
            <path
                d="M69.0425 11.1053C70.7125 11.4275 72.1929 12.3838 73.1731 13.7737C73.2205 13.8656 73.2327 13.9716 73.2074 14.0718C73.182 14.1721 73.121 14.2596 73.0357 14.318C72.9504 14.3763 72.8467 14.4015 72.7441 14.3888C72.6415 14.3761 72.5471 14.3263 72.4786 14.2489C71.6224 13.0356 70.3267 12.2042 68.8671 11.9314C68.7588 11.9068 68.6642 11.8414 68.6029 11.7488C68.5416 11.6562 68.5184 11.5435 68.5381 11.4343C68.5477 11.3791 68.5683 11.3263 68.5987 11.2793C68.6292 11.2322 68.6688 11.1918 68.7152 11.1604C68.7616 11.129 68.8138 11.1073 68.8688 11.0966C68.9238 11.0859 68.9804 11.0863 69.0352 11.098L69.0425 11.1053Z"
                fill="#37474F"
            />
            <path
                d="M74.3793 18.5415C74.2042 20.1863 73.5963 21.7554 72.6174 23.0888C72.5908 23.1395 72.5536 23.1839 72.5084 23.2191C72.4632 23.2543 72.411 23.2794 72.3553 23.2927C72.2996 23.3061 72.2417 23.3074 72.1854 23.2966C72.1292 23.2857 72.0759 23.263 72.0292 23.2299C71.9824 23.1968 71.9433 23.1541 71.9144 23.1047C71.8854 23.0552 71.8674 23.0002 71.8615 22.9432C71.8556 22.8862 71.8619 22.8287 71.8801 22.7743C71.8982 22.72 71.9278 22.6702 71.9667 22.6282C72.8524 21.4047 73.4019 19.9704 73.5605 18.4684C73.5651 18.4145 73.5803 18.3621 73.6052 18.3142C73.6302 18.2663 73.6644 18.2237 73.7058 18.1891C73.7473 18.1544 73.7952 18.1283 73.8468 18.1122C73.8984 18.0962 73.9526 18.0905 74.0065 18.0955C74.1137 18.1084 74.212 18.1617 74.2813 18.2446C74.3506 18.3274 74.3857 18.4336 74.3793 18.5415Z"
                fill="#37474F"
            />
            <path
                d="M69.0588 26.3921C67.7022 27.2546 66.2435 27.9447 64.7162 28.4464C64.6687 28.4624 64.6185 28.4689 64.5684 28.4654C64.5184 28.4619 64.4696 28.4486 64.4247 28.4262C64.3799 28.4037 64.3399 28.3727 64.3071 28.3347C64.2743 28.2968 64.2494 28.2527 64.2337 28.2051C64.2165 28.1581 64.2089 28.1082 64.2114 28.0582C64.2139 28.0082 64.2265 27.9593 64.2483 27.9142C64.2701 27.8692 64.3008 27.829 64.3385 27.7961C64.3761 27.7632 64.4201 27.7382 64.4676 27.7226C65.9349 27.2379 67.335 26.5697 68.6348 25.7341C68.7213 25.6932 68.8198 25.6852 68.9119 25.7115C69.004 25.7378 69.0833 25.7967 69.1352 25.8772C69.187 25.9577 69.2079 26.0543 69.1938 26.149C69.1798 26.2437 69.1318 26.3301 69.0588 26.3921Z"
                fill="#37474F"
            />
            <path
                d="M60.0551 29.5355C58.4805 29.7767 56.8886 29.8867 55.2958 29.8644C55.2478 29.8635 55.2004 29.8531 55.1565 29.8338C55.1125 29.8146 55.0727 29.7869 55.0394 29.7522C55.0062 29.7176 54.9801 29.6768 54.9626 29.632C54.9451 29.5873 54.9366 29.5396 54.9376 29.4916C54.9376 29.4439 54.9471 29.3967 54.9655 29.3528C54.984 29.3088 55.011 29.269 55.0451 29.2357C55.0791 29.2023 55.1195 29.1761 55.1638 29.1585C55.2081 29.1409 55.2555 29.1324 55.3031 29.1334C56.8572 29.1495 58.4099 29.037 59.9454 28.7971C59.9939 28.7898 60.0434 28.7922 60.091 28.8041C60.1385 28.816 60.1833 28.8371 60.2227 28.8663C60.2621 28.8955 60.2953 28.9322 60.3205 28.9742C60.3457 29.0163 60.3623 29.0629 60.3695 29.1114C60.3778 29.1595 60.3764 29.2088 60.3654 29.2563C60.3544 29.3038 60.334 29.3487 60.3054 29.3882C60.2768 29.4277 60.2405 29.4611 60.1988 29.4864C60.1571 29.5117 60.1107 29.5284 60.0624 29.5355H60.0551Z"
                fill="#37474F"
            />
            <path
                d="M50.5458 29.389C48.9825 29.097 47.4449 28.6814 45.9473 28.1462C45.9058 28.1336 45.8672 28.1128 45.834 28.0849C45.8007 28.0571 45.7734 28.0228 45.7537 27.9841C45.734 27.9454 45.7224 27.9032 45.7195 27.8599C45.7166 27.8166 45.7224 27.7731 45.7368 27.7322C45.7511 27.6912 45.7735 27.6536 45.8028 27.6215C45.832 27.5894 45.8674 27.5636 45.9069 27.5456C45.9464 27.5276 45.9891 27.5178 46.0325 27.5167C46.0759 27.5156 46.119 27.5233 46.1593 27.5394C47.6296 28.0464 49.1379 28.4351 50.6701 28.7018C50.7609 28.7179 50.8418 28.7691 50.8951 28.8444C50.9485 28.9196 50.9701 29.0128 50.9552 29.1039C50.9482 29.1493 50.9321 29.1929 50.9079 29.2319C50.8837 29.271 50.8519 29.3049 50.8144 29.3314C50.7769 29.358 50.7345 29.3768 50.6896 29.3867C50.6447 29.3966 50.5983 29.3974 50.5531 29.389H50.5458Z"
                fill="#37474F"
            />
            <path
                d="M41.5991 26.2089C40.202 25.4553 38.8607 24.6026 37.5854 23.6574C37.5405 23.6117 37.5133 23.5516 37.5085 23.4877C37.5038 23.4237 37.522 23.3602 37.5597 23.3084C37.5975 23.2567 37.6524 23.2199 37.7147 23.2049C37.7769 23.1898 37.8426 23.1973 37.8998 23.2261C39.165 24.1411 40.4941 24.9645 41.8769 25.6898C41.9444 25.727 41.9949 25.7888 42.0181 25.8623C42.0412 25.9358 42.0352 26.0154 42.0012 26.0846C41.9639 26.154 41.9008 26.2059 41.8256 26.2292C41.7503 26.2524 41.669 26.2451 41.5991 26.2089Z"
                fill="#37474F"
            />
            <path
                d="M33.9683 20.5798C32.8302 19.4784 31.7655 18.3038 30.7808 17.0633C30.7411 17.0236 30.7187 16.9697 30.7188 16.9135C30.7188 16.8573 30.7411 16.8033 30.7808 16.7636C30.7999 16.7441 30.8226 16.7287 30.8477 16.7181C30.8728 16.7076 30.8998 16.7021 30.927 16.7021C30.9543 16.7021 30.9812 16.7076 31.0063 16.7181C31.0314 16.7287 31.0542 16.7441 31.0732 16.7636C32.0607 17.9763 33.1254 19.1241 34.2607 20.1997C34.2833 20.2215 34.3012 20.2476 34.3135 20.2765C34.3257 20.3054 34.332 20.3364 34.332 20.3678C34.332 20.3992 34.3257 20.4302 34.3135 20.4591C34.3012 20.488 34.2833 20.5141 34.2607 20.536C34.2157 20.5797 34.1554 20.6042 34.0926 20.6042C34.0298 20.6042 33.9695 20.5797 33.9244 20.536L33.9683 20.5798Z"
                fill="#37474F"
            />
            <path
                d="M28.0736 13.1741C27.2595 11.8228 26.5271 10.4239 25.8804 8.98502C25.8718 8.95768 25.8735 8.92815 25.8853 8.90201C25.897 8.87588 25.918 8.85497 25.9441 8.84325C25.9703 8.83153 25.9998 8.8298 26.0271 8.83842C26.0545 8.84703 26.0777 8.86538 26.0924 8.88998C26.7666 10.3042 27.5259 11.6762 28.366 12.9986C28.3893 13.0355 28.397 13.0801 28.3874 13.1226C28.3778 13.1651 28.3517 13.202 28.3149 13.2253C28.278 13.2485 28.2334 13.2562 28.1909 13.2466C28.1484 13.237 28.1115 13.2109 28.0882 13.1741H28.0736Z"
                fill="#37474F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2121_34977"
                    x1="85.8253"
                    y1="135.263"
                    x2="65.6067"
                    y2="97.2588"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="1" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2121_34977"
                    x1="52.914"
                    y1="93.9818"
                    x2="106.038"
                    y2="124.233"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stopColor="white" />
                    <stop offset="1" stopColor="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2121_34977"
                    x1="79.8671"
                    y1="68.4723"
                    x2="51.2522"
                    y2="41.197"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="0.67" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2121_34977"
                    x1="66.1024"
                    y1="94.4001"
                    x2="52.1975"
                    y2="110.466"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.25" stopColor="#353537" />
                    <stop offset="1" stopColor="#17171A" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(TMGuideSVG);
