import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as authActions from 'auth/actions';
import IOSSwitch from 'components/switches/IOS';
import Tabs from 'components/tabs/Base';
import { NOTIFICATION_CENTER_READ_STATE_TYPE, NOTIFICATION_CENTER_TABS, USER_SETTINGS } from 'const';
import { useNotificationCenterReadState, useNotificationCenterWithKey } from 'hooks/auth';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sendManualTrack } from 'tracker';
import ListNotificationCenter from './ListNotificationCenter';

const useStyles = makeStyles(theme => ({
    notificationList: {
        width: '100%',
        minWidth: 428,
        backgroundColor: theme.colors.white,
        boxShadow: theme.shadows[1]
    },
    from: {
        color: theme.colors.secondaryText
    },
    to: {
        color: theme.colors.green
    },
    steel: {
        color: theme.colors.steel
    },
    header: {
        padding: '24px 16px 14px'
    },
    tabs: {
        '& .MuiTabs-root': {
            minHeight: 40,
            margin: 0
        },
        '& .MuiTab-root': {
            minHeight: 0,
            minWidth: 100,
            '& *': {
                opacity: 1
            }
        },
        '& .Mui-selected': {
            '& span': {
                fontWeight: '500 !important'
            },
            '& .badge': {
                background: theme.palette.primary.main,
                color: `${theme.colors.white} !important`
            }
        },
        '& .MuiBox-root': {
            padding: '14px 0 0'
        }
    },
    label: {
        color: theme.colors.lightGreyBlue,
        opacity: 1,
        fontWeight: 400
    },
    badge: {
        position: 'absolute',
        right: 0,
        top: 9,
        minWidth: 22,
        height: 16,
        lineHeight: `16px`,
        borderRadius: 3,
        background: theme.colors.silver,
        color: theme.colors.white,
        fontWeight: 500,
        fontSize: 12,
        textAlign: 'center'
    }
}));

const Label = ({ keyNotification, label }) => {
    const classes = useStyles();
    const notification = useNotificationCenterWithKey(keyNotification) || {};

    return (
        <Grid item>
            <span className={classes.label}>{label}</span>
            {typeof notification.unreadTotal !== 'undefined' && notification.unreadTotal > 0 && (
                <Grid item className={`${classes.badge} badge`}>
                    {notification.unreadTotal}
                </Grid>
            )}
        </Grid>
    );
};

function NotificationMenu({ setOpen }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const readState = useNotificationCenterWithKey('readState');
    const notificationCenterReadState = useNotificationCenterReadState();
    const [checked, setChecked] = useState(
        (readState || notificationCenterReadState) === NOTIFICATION_CENTER_READ_STATE_TYPE.UNREAD
    );

    const tabs = useMemo(() => {
        return NOTIFICATION_CENTER_TABS.map(tab => ({
            label: <Label keyNotification={tab.key} label={tab.label} />,
            content: <ListNotificationCenter keyNotification={tab.key} />
        }));
    }, []);

    const handleSwitchChange = useCallback(
        (e, value) => {
            setChecked(value);
            const newState = value
                ? NOTIFICATION_CENTER_READ_STATE_TYPE.UNREAD
                : NOTIFICATION_CENTER_READ_STATE_TYPE.ALL;
            dispatch(authActions.setNotificationCenterWithKeyValue({ key: 'readState', value: newState }));
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.NOTIFICATION_CENTER_READ_STATE]: newState
                    }
                })
            );

            if (value) {
                sendManualTrack({ type: 'Only Show Unread Notifications' });
            }
            setTimeout(() => {
                dispatch(authActions.setNotificationCenterWithKeyValue({ key: 'allowFetch', value: true }));
            }, 0);
        },
        [dispatch]
    );

    return (
        <Paper className={classes.notificationList}>
            <Grid item className={classes.header}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h4">{t('notifications')}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                            <Grid item>
                                <Typography variant="caption" className={classes.steel}>
                                    {t('only_show_unread')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IOSSwitch
                                    className={classes.switchIcon}
                                    checked={checked}
                                    onChange={handleSwitchChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>{tabs.length > 0 ? <Tabs className={classes.tabs} tabs={tabs} /> : tabs[0].content}</Grid>
        </Paper>
    );
}

NotificationMenu.propTypes = {
    setOpen: PropTypes.func.isRequired
};

export default React.memo(NotificationMenu);
