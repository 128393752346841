import { Grid } from '@material-ui/core';
import { useTmSourceSelected, useTmTargetsSelected } from 'hooks/tm';
import React from 'react';
import { useParams } from 'react-router-dom';
import Alternative from './Alternative';

const TargetCell = ({ sourceText, onRestTable, lang, unitId, dataRow, isChecked, isHover }) => {
    const { tmId } = useParams();

    const source = useTmSourceSelected() || null;
    const targets = useTmTargetsSelected() || [];

    const targetsData = React.useMemo(() => {
        return dataRow?.translations?.filter(s => s.lang !== dataRow?.sourceLang);
    }, [dataRow]);

    const target = React.useMemo(() => {
        return targets?.filter(t => t.lang !== dataRow?.sourceLang);
    }, [dataRow, targets]);

    return (
        <div>
            {targetsData?.map((targetData, i) => {
                return (
                    lang === targetData?.lang && (
                        <Grid>
                            {targetData?.segments?.map(_alternative => {
                                return (
                                    <>
                                        <Alternative
                                            unitId={dataRow?.id}
                                            tmId={tmId}
                                            target={target[0]}
                                            onRestTable={onRestTable}
                                            targetLang={targetData?.lang}
                                            alternative={_alternative}
                                            source={source}
                                            sourceText={sourceText}
                                            listAlternatives={targetData?.segments}
                                            primary={_alternative?.primary}
                                            // setSegmentId={setSegmentId}
                                        />
                                    </>
                                );
                            })}
                        </Grid>
                    )
                );
            })}
        </div>
    );
};

export default TargetCell;
