import React from 'react';
import { getPropertyInCustomProperties } from './gridUI/formatData';
import hexToRgba from 'hex-to-rgba';
import { UPDATE, DELETE, CREATE, FILL_COLOR_OPTIONS, GRID_HISTORY_ACTIONS } from 'const/gridUI';
import EditIconSVG from 'assets/images/svg/versionControl/EditIconSVG';
import AddIconSVG from 'assets/images/svg/versionControl/AddIconSVG';
import DeleteIconSVG from 'assets/images/svg/versionControl/DeleteIconSVG';
import {
    DEFAULT_ROLE_ADMIN,
    DEFAULT_ROLE_OWNER,
    DEFAULT_ROLE_USER,
    PLAN_TYPES,
    PAYMENT_WARNING_TYPES,
    API_REQUEST_STATUS
} from 'const';

export const COLORS = {
    brightRed: `#EB4F5E`,
    burntSlena: '#EA6A46',
    crusta: '#F9772A',
    sun: '#F8AD13',
    atlantis: '#77B32B',
    shamRock: '#2DD2A3',
    lightCyan: `#5DCDE3`,
    dodgerBlue: '#2D97F2',
    funBlue: '#1D66AE',
    shipCove: '#6A7AB9',
    fuchsiaBlue: '#6E5BBE',
    froly: '#F67B8E'
};
export const DEFAULT_PATH_TAG_COLOR = COLORS.lightCyan;

export const DEFAULT_COLOR = 'dodgerBlue';

const colorsArr = Object.keys(COLORS).map(key => COLORS[key]);
const colorsKeyArr = Object.keys(COLORS);

export const gridColorArray = colorsArr;

export function getGridRandomColor() {
    const length = colorsKeyArr.length;
    const number = Math.floor(Math.random() * length);
    const color = colorsKeyArr[number];

    return color;
}

export function getGridRandomHexColor() {
    const length = colorsKeyArr.length;
    const number = Math.floor(Math.random() * length);
    const colorKey = colorsKeyArr[number];

    return COLORS[colorKey];
}

export function getGridColorKey(grid) {
    return getPropertyInCustomProperties(grid, 'color', DEFAULT_COLOR);
}

export function getGridColorValue(grid) {
    const colorkey = getGridColorKey(grid);

    return COLORS[colorkey] || COLORS[DEFAULT_COLOR];
}

export const GRID_COLOR_OPTIONS = Object.keys(COLORS).map(key => ({
    id: key,
    value: COLORS[key]
}));

export const SELECTION_COLORS = {
    brightRed: `#EB4F5E`,
    burntSlena: '#EA6A46',
    crusta: '#F9772A',
    sun: '#F8AD13',
    atlantis: '#77B32B',
    shamRock: '#2DD2A3',
    lightCyan: `#5DCDE3`,
    dodgerBlue: '#2D97F2',
    funBlue: '#1D66AE',
    shipCove: '#6A7AB9',
    fuchsiaBlue: '#6E5BBE',
    froly: '#F67B8E'
};

export const BORDER_COLORS = {
    brightRed: `#EB4F5E`,
    burntSlena: '#EA6A46',
    crusta: '#F9772A',
    sun: '#F8AD13',
    atlantis: '#77B32B',
    shamRock: '#2DD2A3',
    lightCyan: `#5DCDE3`,
    dodgerBlue: '#2D97F2',
    funBlue: '#1D66AE',
    shipCove: '#6A7AB9',
    fuchsiaBlue: '#6E5BBE',
    froly: '#F67B8E'
};

const colorsSelectionArr = Object.keys(SELECTION_COLORS).map(key => SELECTION_COLORS[key]);

export const SELECTION_COLOR_OPTIONS = Object.keys(SELECTION_COLORS).map(key => ({
    id: key,
    value: SELECTION_COLORS[key],
    border: BORDER_COLORS[key]
}));

export function getSelectionRandomColor() {
    let length = colorsSelectionArr.length;
    let number = Math.floor(Math.random() * length);
    let color = colorsSelectionArr[number];
    return color;
}

export function getSelectionRandomColorId() {
    const length = SELECTION_COLOR_OPTIONS.length;
    const number = Math.floor(Math.random() * length);

    return SELECTION_COLOR_OPTIONS[number].id;
}

export function getOptionColor({ options, data, customProperties }) {
    if (!options || !options?.length) return COLORS?.[DEFAULT_COLOR];
    const selectedOption = options.find(option => option.label === data);
    if (!selectedOption) return COLORS?.[DEFAULT_COLOR];
    let selectedOptionId = selectedOption?.id;
    let selectedColor = customProperties?.options?.[selectedOptionId]?.color || DEFAULT_COLOR;
    return COLORS[selectedColor];
}

export function getBGByAction({ action, theme, opacity = 0.1 }) {
    switch (action) {
        case UPDATE:
        case GRID_HISTORY_ACTIONS.AUTOFILL:
        case GRID_HISTORY_ACTIONS.PASTE_RECORDS:
        case GRID_HISTORY_ACTIONS.APPROVE_TM:
        case GRID_HISTORY_ACTIONS.MARK_SOURCE_STATUS:
        case GRID_HISTORY_ACTIONS.MARK_TARGET_STATUS:
            return hexToRgba(theme.colors.dodgerBlue, opacity);
        case DELETE:
        case GRID_HISTORY_ACTIONS.CLEAN_COLUMN:
            return hexToRgba(theme.colors.brightRed, opacity);
        case CREATE:
            return hexToRgba(theme.colors.atlantis, opacity);
        default:
            return theme.colors.white;
    }
}

export function getColorByAction({ action, theme }) {
    switch (action) {
        case UPDATE:
            return hexToRgba(theme.colors.dodgerBlue, 0.8);
        case DELETE:
            return hexToRgba(theme.colors.brightRed, 0.8);
        case CREATE:
            return hexToRgba(theme.colors.shamRock, 0.8);
        default:
            return theme.colors.white;
    }
}

export function getIconByAction({ action, theme }) {
    switch (action) {
        case UPDATE:
        case GRID_HISTORY_ACTIONS.AUTOFILL:
        case GRID_HISTORY_ACTIONS.PASTE_RECORDS:
        case GRID_HISTORY_ACTIONS.MARK_SOURCE_STATUS:
        case GRID_HISTORY_ACTIONS.MARK_TARGET_STATUS:
            return <EditIconSVG color={hexToRgba(theme.colors.dodgerBlue, 0.8)} />;
        case DELETE:
            return <DeleteIconSVG color={hexToRgba(theme.colors.brightRed, 0.8)} />;
        case CREATE:
            return <AddIconSVG color={hexToRgba(theme.colors.shamRock, 0.8)} />;
        default:
            return null;
    }
}

export function getRoleColor({ theme, roleName }) {
    switch (roleName) {
        case 'administrator':
            return theme.colors.dodgerBlue;
        case 'owner':
            return theme.colors.sun;
        default:
            return null;
    }
}

export function getStatusColor({ theme, status }) {
    switch (status) {
        case 'active':
            return theme.colors.shamRock;
        case 'inactive':
        case 'revoked':
            return theme.colors.burntSlena;
        case 'pending_approval':
            return theme.colors.disabledGrey;
        case 'pending_invitation':
            return theme.colors.disabledGrey;
        default:
            return theme.colors.disabledGrey;
    }
}

export function getSystemRoleColor({ theme, role }) {
    if (role?.id === DEFAULT_ROLE_USER?.id && role?.name?.toLowerCase() === DEFAULT_ROLE_USER?.name?.toLowerCase()) {
        return hexToRgba(theme.colors.disabledGrey, 0.8);
    }
    if (role?.id === DEFAULT_ROLE_ADMIN?.id && role?.name?.toLowerCase() === DEFAULT_ROLE_ADMIN?.name?.toLowerCase()) {
        return hexToRgba(theme.colors.dodgerBlue, 0.8);
    }
    if (role?.id === DEFAULT_ROLE_OWNER?.id && role?.name?.toLowerCase() === DEFAULT_ROLE_OWNER?.name?.toLowerCase()) {
        return hexToRgba(theme.colors.sun, 0.8);
    }
    return hexToRgba(theme.colors.disabledGrey, 0.8);
}

export function getPlanColor({ planType, theme }) {
    switch (planType) {
        case PLAN_TYPES.FREE:
            return theme.colors.dodgerBlue;
        case PLAN_TYPES.LITE:
            return theme.colors.lightCyan;
        case PLAN_TYPES.ESSENTIAL:
            return theme.colors.atlantis;
        case PLAN_TYPES.PROFESSIONAL:
            return theme.colors.sun;
        case PLAN_TYPES.ENTERPRISE:
            return theme.colors.brightRed;
        default:
            return theme.colors.dodgerBlue;
    }
}

export function getPaymentWarningColor({ warningType, theme }) {
    switch (warningType) {
        case PAYMENT_WARNING_TYPES.INFO:
            return theme.colors.fuchsiaBlue;
        case PAYMENT_WARNING_TYPES.ERROR:
            return theme.colors.snackBarError;
        default:
            return theme.colors.fuchsiaBlue;
    }
}

const REALTIME_COLORS = {
    burntSlena: '#EA6A46',
    crusta: '#F9772A',
    sun: '#F8AD13',
    atlantis: '#77B32B',
    shamRock: '#2DD2A3',
    lightCyan: `#5DCDE3`,
    funBlue: '#1D66AE',
    brightRed: `#EB4F5E`,
    shipCove: '#6A7AB9',
    fuchsiaBlue: '#6E5BBE',
    froly: '#F67B8E'
};

export function getColorByUserId(userId) {
    const colorsArr = Object.keys(REALTIME_COLORS).map(key => REALTIME_COLORS[key]);
    const index = Number(userId) % colorsArr.length;
    return colorsArr?.[index] || colorsArr?.[0];
}

export function getCellBackgroundByHex(hex) {
    return FILL_COLOR_OPTIONS?.[hex];
}

export function getColorByStatusApi({ apiStatus, theme }) {
    switch (apiStatus) {
        case API_REQUEST_STATUS.OPERATIONAL:
            return theme.colors.seaGreen;
        case API_REQUEST_STATUS.DEGRADED_PERFORMANCE:
            return theme.colors.sun;
        case API_REQUEST_STATUS.PARTIAL_OUTAGE:
            return theme.colors.burntSlena;
        case API_REQUEST_STATUS.MAJOR_OUTAGE:
            return theme.colors.brightRed;
        case API_REQUEST_STATUS.UNDER_MAINTENANCE:
            return theme.colors.dodgerBlue;
        default:
            return theme.colors.midGrey;
    }
}

export function isHexColor(color) {
    if (!color) return false;
    const reg = /^#([0-9a-f]{3}){1,2}$/i;

    return reg.test(color);
}
