import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor, Range } from 'slate';
import { withHistory } from 'slate-history';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Portal } from '@material-ui/core';
import BoldSVG from 'assets/images/svg/BoldSVG';
import ItalicSVG from 'assets/images/svg/ItalicSVG';
import BlockQuoteSVG from 'assets/images/svg/BlockQuoteSVG';
import BulletListSVG from 'assets/images/svg/BulletList';
import NumberList from 'assets/images/svg/NumberList';
import StrikethroughSVG from 'assets/images/svg/StrikethroughSVG';
import UnderlineSVG from 'assets/images/svg/UnderlineSVG';
import TextColorSVG from 'assets/images/svg/TextColorSVG';
import hexToRgba from 'hex-to-rgba';
import { COLORING } from 'const';
import ColorPallet from './ColorPallet';
import LinkIconSVG from 'assets/images/svg/LinkIconSVG';
import editorController from 'components/editor/markdown/editorController';
import { withLink } from 'components/formula/plugins/withLink';
import pipe from 'lodash/fp/pipe';

const useStyles = makeStyles(theme => ({
    root: {
        // width: 350,
        height: 36,
        padding: `0px 14px`,
        display: 'flex',
        alignItems: 'center',
        position: `absolute`,
        zIndex: 10000,
        top: '-10000px',
        left: '-10000px',
        marginTop: '-8px',
        opacity: 0,
        boxShadow: theme.shadows[1],
        background: theme.colors.dimGrey,
        borderRadius: 4,
        '& > *': {
            display: `inline-block`
        },
        '& button': {
            minWidth: 26,
            minHeight: 26,
            width: 26,
            height: 26,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            marginLeft: 8,
            '&:hover': {
                background: hexToRgba(theme.colors.white, 0.1)
            },
            '&:first-child': {
                marginLeft: 0
            }
        }
    },
    wrapperDivider: {
        paddingLeft: 14,
        paddingRight: 6,
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center'
    },
    divider: {
        width: 1,
        height: 20,
        background: hexToRgba(`#9392b4`, 0.1)
    },
    active: {
        background: hexToRgba(theme.colors.white, 0.1)
    },
    italicIcon: {
        '& polygon:last-child': {
            fill: theme.colors.white
        }
    },
    buttonColor: {
        width: `40px !important`,
        minWidth: `40px !important`
    }
}));

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code'
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const createEditorWithPlugins = pipe(withReact, withHistory, withLink);

const RichTextExample = ({ value, onChange, containerRef, onKeyDown, autoFocus = true, initFocus, ...rest }) => {
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />;
    }, []);
    const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

    useEffect(() => {
        if (editor && initFocus) {
            Transforms.select(editor, Editor.end(editor, []));
            ReactEditor.focus(editor);
        }
    }, [editor, initFocus]);

    return (
        <>
            <Slate editor={editor} value={value} onChange={onChange} {...rest} id={'slate'}>
                <HoveringToolbar containerRef={containerRef} />
                <Editable
                    style={rest.style}
                    className={rest.className}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder=""
                    spellCheck
                    autoFocus={autoFocus}
                    onKeyDown={event => {
                        onKeyDown(event);
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault();
                                const mark = HOTKEYS[hotkey];
                                toggleMark(editor, mark);
                            }
                        }
                    }}
                />
            </Slate>
        </>
    );
};

const HoveringToolbar = ({ containerRef }) => {
    const ref = useRef();
    const editor = useSlate();
    const classes = useStyles();
    const theme = useTheme();

    const [toolBarRef, setToolBarRef] = React.useState(null);

    useEffect(() => {
        let toolbar = document.getElementById('richTextToolbar');
        setToolBarRef(toolbar);
    }, []);

    useEffect(() => {
        const el = ref.current;
        const { selection } = editor;
        if (!el) {
            return;
        }

        if (
            !selection ||
            !ReactEditor.isFocused(editor) ||
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ''
        ) {
            el.removeAttribute('style');
            return;
        }

        const domSelection = window.getSelection();
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();

        el.style.opacity = '1';
        el.style.top = `${rect.top - 227 + 40}px`;
        el.style.left = 0;
        const rect1 = el.getBoundingClientRect();
        el.style.left = `${rect.left - rect1.left}px`;
    });

    return (
        <>
            <div id={'richTextToolbar'} />
            <Portal container={toolBarRef}>
                <div ref={ref} className={classes.root}>
                    <MarkButton format="bold" Icon={<BoldSVG color={theme.colors.white} />} />
                    <MarkButton
                        format="italic"
                        Icon={<ItalicSVG color={theme.colors.white} />}
                        className={classes.italicIcon}
                    />
                    <MarkButton format="underline" Icon={() => <UnderlineSVG color={theme.colors.white} />} />
                    <MarkColorButton format={COLORING} Icon={() => <TextColorSVG color={theme.colors.white} />} />
                    <MarkButton format="strikethrough" Icon={() => <StrikethroughSVG color={theme.colors.white} />} />
                    <span className={classes.wrapperDivider}>
                        <span className={classes.divider}></span>
                    </span>
                    <BlockButton format="numbered-list" Icon={() => <NumberList color={theme.colors.white} />} />
                    <BlockButton format="bulleted-list" Icon={() => <BulletListSVG color={theme.colors.white} />} />
                    <span className={classes.wrapperDivider}>
                        <span className={classes.divider}></span>
                    </span>
                    <BlockButton format="block-quote" Icon={() => <BlockQuoteSVG color={theme.colors.white} />} />
                    <BlockButton format="link" Icon={() => <LinkIconSVG color={theme.colors.white} />} />
                </div>
            </Portal>
        </>
    );
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true
    });

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format, color) => {
    if (format === COLORING) {
        if (color) {
            Editor.addMark(editor, 'color', color);
            Editor.addMark(editor, format, true);
        } else {
            Editor.removeMark(editor, 'color');
            Editor.removeMark(editor, format);
        }
    } else {
        const isActive = isMarkActive(editor, format);
        if (isActive) {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    }
};

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === format
    });

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        default:
            return <p {...attributes}>{children}</p>;
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'code':
            return <code {...attributes}>{children}</code>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>;
        case 'heading-four':
            return <h4 {...attributes}>{children}</h4>;
        case 'heading-five':
            return <h5 {...attributes}>{children}</h5>;
        case 'heading-six':
            return <h6 {...attributes}>{children}</h6>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'link':
            return (
                <a href={element.url} target="_blank" rel="noopener noreferrer" {...attributes}>
                    {children}
                </a>
            );
        case COLORING: {
            return <div {...attributes}>{children}</div>;
        }
    }
};

const Leaf = props => {
    let { attributes, children, leaf } = props;

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.strikethrough) {
        children = <del>{children}</del>;
    }

    if (leaf?.[COLORING]) {
        const hexColor = leaf?.color;
        if (hexColor) {
            children = <span style={{ color: hexColor }}>{children}</span>;
        } else {
            children = <span>{children}</span>;
        }
    }

    return <span {...attributes}>{children}</span>;
};

const handleLinkClick = editor => {
    const url = window.prompt('Enter the URL of the link:');

    if (!url) return;

    editorController.insertLink(editor, url);
};

const BlockButton = ({ format, Icon, className }) => {
    const classes = useStyles();
    const editor = useSlate();
    const active = isBlockActive(editor, format);

    const handleClick = e => {
        stopPropagation(e);
        if (format === 'link') {
            handleLinkClick(editor);
            return;
        }
        toggleBlock(editor, format);
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Button size="small" className={`${className} ${active ? classes.active : ''}`} onClick={handleClick}>
            <Icon />
        </Button>
    );
};

const MarkColorButton = ({ format, Icon, className }) => {
    const editor = useSlate();
    const classes = useStyles();

    const [isOpenColor, setIsOpenColor] = React.useState(null);

    const onChangeHandler = (e, color) => {
        stopPropagation(e);
        toggleMark(editor, format, color);
        handleClickAway();
    };

    const handleResetTextColor = e => {
        stopPropagation(e);
        toggleMark(editor, format);
        handleClickAway();
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = () => {
        setIsOpenColor(true);
    };

    const handleClickAway = () => {
        setIsOpenColor(false);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                size="small"
                className={`${className} ${classes.buttonColor}`}
                style={{ position: 'relative' }}
            >
                <Icon />
                {isOpenColor && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 36,
                            left: 0
                        }}
                    >
                        <ColorPallet
                            resetTextColor={handleResetTextColor}
                            handleClickAway={handleClickAway}
                            onChangeHandler={onChangeHandler}
                        />
                    </div>
                )}
            </Button>
        </>
    );
};

const MarkButton = ({ format, Icon, className }) => {
    const classes = useStyles();
    const editor = useSlate();
    const active = isMarkActive(editor, format);

    const handleClick = e => {
        stopPropagation(e);
        toggleMark(editor, format);
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Button size="small" className={`${className} ${active ? classes.active : ''}`} onClick={handleClick}>
            {typeof Icon === 'function' ? Icon() : Icon}
        </Button>
    );
};

export default React.memo(RichTextExample);
