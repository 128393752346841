import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import DependencyStatusGuideItem from 'components/dependencyNote/Base';
import { useTranslation } from 'react-i18next';

function DependencyStatusGuide() {
    const theme = useTheme();
    const { t } = useTranslation();

    const dependencies = [
        { color: theme.colors.atlantis, label: t('up_to_date') },
        { color: theme.colors.sun, label: t('out_of_date') },
        { color: theme.colors.brightRed, label: t('unset') }
    ];

    return (
        <Grid container direction="row" spacing={3}>
            {dependencies.map((dependency, index) => (
                <Grid item key={index}>
                    <DependencyStatusGuideItem label={dependency?.label} color={dependency?.color} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(DependencyStatusGuide);
