import React from 'react';
import { Grid, Drawer, Dialog, Fade, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    clearTableData,
    copy,
    paste,
    cannotPasteWarning,
    registerUndoRedo,
    deleteKeyEvent,
    setCopiedRange,
    setOpenRightSideBar
} from './actions';
import {
    SIDEBAR_RIGHT_CONTENT,
    SIDEBAR_RIGHT_WIDTH,
    GLOBAL_FILTER_HEIGHT,
    USER_ACTION_TYPES,
    GRID_STATUS,
    RIGHT_SIDEBAR_ACTION_TYPE
} from 'const/gridUI';
import { HEADER_HEIGHT, DATABASE_STATUS, GRID_TUTORIAL, USER_SETTINGS } from 'const';
import UndoProvider from './undoRedo/UndoProvider';
import {
    useGridUIIsLoading,
    useIsFetchingView,
    useIsViewBlocked,
    useGridStatus,
    useIsSearchingRecord,
    useGetLQAExporting,
    useIsReplacingText,
    useIsClearingRecords,
    useClearingRecords,
    useIsApplyingTM,
    useOpenRightSidebar
} from 'hooks/gridUI';
import {
    isKbUndo,
    isKbRedo,
    isKbRefresh,
    isKbCommandAndSlash,
    isKbSearch,
    isKbEscape,
    isKbDelete
} from 'utils/keyboard';
import Toolbar from './Toolbar';
import RenderRightSidebarContent from './RenderRightSidebarContent';
import SidebarRight from './SidebarRight';
import GridUI from './GridUI';
import { useGridDetail } from 'hooks/grid';
import ProgressLinear from 'components/notify/Import';
import ExcelSVG from 'assets/images/svg/ExcelSVG';
import ActiveUserActionProvider from './ActiveUserActionProvider';
import ConfirmMergingBox from './ConfirmMergingBox';
import RestoringGridConfirmation from './RestoringGridConfirmation';
import RestoringDBConfirmation from './RestoringDBConfirmation';
import BackingUpDBConfirmation from './BackingUpDBConfirmation';
import { useDatabaseStatusByDbId } from 'hooks/database';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { useTranslation } from 'react-i18next';
import Tutorial from 'components/tutorial/Base';
import * as appActions from 'app/actions';
import { useCurrentTutorial } from 'hooks/app';
import FilesPreview from 'components/filesPreview';
import {
    useIsCellPreview,
    useCurrentDefaultPreviewFile,
    useCellPreviewData,
    useCurrentPreviewData
} from 'hooks/gridUI/cellFilePreview';
import * as gridUIActions from './actions';
import PreloadImages from 'components/preload/Image';
import manageColumnGif from 'assets/images/manageColumn.gif';
import dependencyGif from 'assets/images/dependency.gif';
import viewFilterGif from 'assets/images/viewFilter.gif';
import viewSortGif from 'assets/images/viewSort.gif';
import searchGif from 'assets/images/search.gif';
import referenceRecordGif from 'assets/images/reference_record.gif';
import referenceCellValueGif from 'assets/images/reference_cell_value.gif';
import groupByReferenceGif from 'assets/images/groupByReference.gif';
import NoAccessSVG from 'assets/images/svg/NoAccessSVG';
import { useIsFinishGridTutorial } from 'hooks/auth';
import LocalizationJsonExport from './localization/export/LocalizationJsonExport';
import ButtonBase from 'components/button/Base';
import { useHistory } from 'react-router-dom';
import { checkContainClassName, checkContainId } from 'utils/clickAway';
import * as workspaceActions from 'workspaces/actions';
import { useIsFetchingUserSetting } from 'hooks/workspace';
import { Helmet } from 'react-helmet';
import * as authActions from 'auth/actions';
import { contentBeforeConfirm, copyPasteEvt, setCopyPaseEvt } from 'utils/clipboard';
import TMSearchNotFoundSVG from 'assets/images/svg/TMSearchNotFoundSVG';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import * as integrationActions from 'integrations/actions';
import Sync2SVG from 'assets/images/svg/Sync2SVG';
import * as paymentActions from 'payment/actions';
import ConfirmBox from 'components/confirmBox/Base';
import Spinner from 'components/spinner/Base';
import * as shareViewActions from 'gridUI/actions/shareView';
import BackingUpGridConfirmation from './BackingUpGridConfirmation';
import * as automationActions from 'gridUI/automations/action';
import { useIsOpenMultipleAutomationDialog } from 'hooks/gridUI/automation';
import MultipleAnimation from './automations/MultiAnimation';
import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const CONFIRM_TYPES = {
    DELETE_COLUMN: 'Delete',
    PASTE_COLUMN: 'Paste'
};

function GridPage({ isShareViewLink, workspaceId, gridId, viewId, dbId, branchId, privileges }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();
    const gridStatus = useGridStatus({ dbId, branchId });
    const openRightSideBar = useOpenRightSidebar();
    const canAccessGridHistoryInShareView = privileges?.includes(roleConst.WORKSPACE_AUTHORITIES.ACCESS_GRID_HISTORY);
    const [rightSidebarWidth, setRightSidebarWidth] = React.useState(
        isShareViewLink && !canAccessGridHistoryInShareView ? 0 : SIDEBAR_RIGHT_WIDTH
    );
    const [action, setAction] = React.useState(null);
    const dbStatus = useDatabaseStatusByDbId(dbId);
    const roles = useRole();
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const accessManageGridColumn = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN];
    const accessManageGridRecord = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD];
    const { t } = useTranslation();
    const currentTutorial = useCurrentTutorial();
    const filesPreview = useCellPreviewData();

    const isOpenPreview = useIsCellPreview();
    const previewDefaultFile = useCurrentDefaultPreviewFile();
    const currentPreviewData = useCurrentPreviewData();
    const isLoading = useGridUIIsLoading();
    const [isOpenJsonExport, setIsOpenJsonExport] = React.useState(false);
    const isViewBlocked = useIsViewBlocked();
    const isFetchingView = useIsFetchingView();
    const isFetchingUserSetting = useIsFetchingUserSetting();
    const currentGrid = useGridDetail({ dbId, gridId });
    const isFinishGridTutorial = useIsFinishGridTutorial();
    const isSearchingRecord = useIsSearchingRecord();
    const lqaExporting = useGetLQAExporting();
    const replacingText = useIsReplacingText();
    const clearingRecords = useClearingRecords() || {};
    const { user: userClearingRecords } = clearingRecords;
    const isClearingRecords = useIsClearingRecords();
    const isApplyingTM = useIsApplyingTM();
    const [actionType, setActionType] = React.useState(null);
    const [isConfirming, setIsConfirming] = React.useState(false);
    const [extendPasteData, setExtendPasteData] = React.useState(null);
    const isOpenMultipleAutomationDialog = useIsOpenMultipleAutomationDialog();

    const handleOpenExtendConfirm = React.useCallback(
        data => {
            let message = `To fit your pasted data into the grid, we need to `;
            const { affectedRowIds, overwriteCells, extendColumns, extendRows } = data;
            const isExtendingRecord = accessManageGridRecord && extendRows > 0;
            const isExtendingColumn = accessManageGridColumn && extendColumns > 0;
            if (overwriteCells > 0) {
                message += `overwrite <b>${overwriteCells} cells in ${affectedRowIds.length} records</b> and `;
            }
            if (isExtendingRecord) {
                message += `add <b>${extendRows} more records</b>`;
                message += isExtendingColumn ? ` and ` : '.';
            }
            if (isExtendingColumn) {
                message += `add <b>${extendColumns} more columns</b>.`;
            }
            setExtendPasteData({ ...data, expand: true, message });
        },
        [accessManageGridColumn, accessManageGridRecord]
    );

    const handleCloseExtendConfirm = React.useCallback(data => {
        setExtendPasteData(null);
    }, []);

    const handleOpenConfirm = React.useCallback(type => {
        setActionType(type);
    }, []);

    const handleCloseConfirm = React.useCallback(() => {
        setActionType(null);
    }, []);

    const isImporting = React.useMemo(() => {
        return gridStatus === GRID_STATUS.UPLOADING || gridStatus === GRID_STATUS.IMPORTING;
    }, [gridStatus]);

    const isGridRestoring = React.useMemo(() => {
        return gridStatus === GRID_STATUS.RESTORING;
    }, [gridStatus]);

    const isGridClearingRecords = React.useMemo(() => {
        return gridStatus === GRID_STATUS.CLEARING_RECORDS;
    }, [gridStatus]);

    const isLoadingClearingRecords = React.useMemo(() => {
        return isClearingRecords || isGridClearingRecords;
    }, [isClearingRecords, isGridClearingRecords]);

    const isDBBackingUp = React.useMemo(() => {
        return dbStatus === DATABASE_STATUS.BACKING_UP;
    }, [dbStatus]);

    const isDBRestoring = React.useMemo(() => {
        return dbStatus === DATABASE_STATUS.RESTORING;
    }, [dbStatus]);

    const isMerging = React.useMemo(() => {
        return gridStatus === GRID_STATUS.MERGING;
    }, [gridStatus]);

    const isBackuping = React.useMemo(() => {
        return gridStatus === GRID_STATUS.BACKING_UP;
    }, [gridStatus]);

    const handleItemClick = React.useCallback(action => {
        setAction(action);
        dispatch(setOpenRightSideBar(true));
    }, []);

    const handleCloseRightSidebar = React.useCallback(() => {
        dispatch(setOpenRightSideBar(false));
        setRightSidebarWidth(SIDEBAR_RIGHT_WIDTH);
        setTimeout(() => {
            setAction(null);
        }, 300);
    }, []);

    const handleOpenTranslationOverview = React.useCallback(() => {
        setRightSidebarWidth(0);
        handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.TRANSLATION_OVERVIEW);
    }, [handleItemClick]);

    const handleOpenGridTypeSetting = React.useCallback(() => {
        setRightSidebarWidth(0);
        handleItemClick(RIGHT_SIDEBAR_ACTION_TYPE.GRID_TYPE_SETTING);
    }, [handleItemClick]);

    const handleFinished = React.useCallback(() => {
        dispatch(appActions.setCurrentTutorial(''));
        const dependencyPopup = document.querySelector('#dependencyPopup');
        if (dependencyPopup) {
            document.querySelector('#g-h-menu-item-dependency-add').click();
        }
        if (!isFinishGridTutorial) {
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.FINISH_GRID_TUTORIAL]: true
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        }
    }, [dispatch, isFinishGridTutorial]);

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(gridUIActions.closeCellFilePreview());
    };

    const deleteImageHandler = React.useCallback(
        file => {
            dispatch(
                gridUIActions.deleteFileOfCell({
                    columnId: currentPreviewData?.columnId,
                    rowId: currentPreviewData?.rowId,
                    fileId: file?.id,
                    successCallback: () => {
                        console.log('delete file successfully');
                    },
                    errorCallback: () => {
                        console.log('Failed to delete file successfully');
                    }
                })
            );
        },
        [currentPreviewData, dispatch]
    );

    const openJsonExport = React.useCallback(() => {
        setIsOpenJsonExport(true);
        setRightSidebarWidth(SIDEBAR_RIGHT_WIDTH);
    }, []);

    const closeJsonExport = React.useCallback(() => {
        setIsOpenJsonExport(false);
    }, []);

    React.useEffect(() => {
        dispatch(
            gridUIActions.setDefaultRouterParams({
                defaultAccessViewId: viewId,
                workspaceId,
                gridId: branchId,
                dbId,
                branchId,
                parentGridId: gridId
            })
        );
    }, [dispatch, viewId, branchId, gridId, dbId, workspaceId]);

    React.useEffect(() => {
        dispatch(
            gridUIActions.fetchCurrentGrid({
                dbId,
                gridId: branchId,
                successCallback: () => {
                    console.log('fetchCurrentGrid success');
                },
                errorCallback: () => {
                    console.log('fetchCurrentGrid failed');
                }
            })
        );
    }, [dispatch, dbId, branchId]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(shareViewActions.fetchGroupsByView(viewId));
        }
    }, [dispatch, viewId, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                integrationActions.getConnectionsByDestinationGridId({
                    destinationGridId: branchId,
                    successCallback: () => {
                        console.log('getConnectionsByDestinationGridId success');
                    },
                    errorCallback: () => {
                        console.log('getConnectionsByDestinationGridId failed');
                    }
                })
            );
        }
    }, [dispatch, branchId, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                gridUIActions.fetchCurrentView({
                    dbId,
                    defaultAccessViewId: viewId,
                    successCallback: () => {
                        console.log('fetchCurrentView success');
                    },
                    errorCallback: () => {
                        console.log('fetchCurrentView failed');
                    }
                })
            );
        } else {
            dispatch(gridUIActions.fetchCurrentViewSuccess());
        }
    }, [dispatch, dbId, viewId, isShareViewLink]);

    React.useEffect(() => {
        let unmounted = false;

        if (isShareViewLink) {
            dispatch(
                gridUIActions.renderGridUITable({
                    wsId: workspaceId,
                    dbId,
                    defaultAccessViewId: viewId,
                    gridId: branchId,
                    successCallback: message => {
                        console.log('render table success');
                        if (unmounted) return;
                        dispatch(
                            gridUIActions.fetchAggregations({
                                successCallback: () => {
                                    console.log('fetching aggregations success');
                                },
                                errorCallback: () => {
                                    console.log('fetching aggregations failed');
                                }
                            })
                        );

                        dispatch(
                            gridUIActions.fetchViewFilters({
                                dbId,
                                viewId,
                                successCallback: () => {
                                    console.log('fetching view filters done');
                                }
                            })
                        );

                        const { action, find } = queryParamsToObject(window?.location?.search);

                        dispatch(
                            gridUIActions.fetchDependencies({
                                branchId,
                                dbId,
                                successCallback: () => {
                                    console.log('fetchDependencies success');

                                    if (find) {
                                        dispatch(gridUIActions.jumpToRange(find));
                                    }
                                },
                                errorCallback: () => {
                                    console.log('fetchDependencies failed');
                                }
                            })
                        );

                        if (action?.indexOf('SEARCHCELL') > -1) {
                            const arr = action.split('-');
                            if (arr[1] && arr[2]) {
                                dispatch(
                                    gridUIActions.jumpToCell({
                                        recordId: arr[1],
                                        columnId: arr[2]
                                    })
                                );
                            }
                        }
                    },
                    errorCallback: () => {
                        console.log('load gridly failed');
                    }
                })
            );
        }

        return function cleanup() {
            unmounted = true;
        };
    }, [viewId, dbId, branchId, dispatch, isShareViewLink, workspaceId]);

    React.useEffect(() => {
        let unmounted = false;
        if (!isShareViewLink) {
            dispatch(
                paymentActions.fetchCustomerAndSubscriptions({
                    successCallback: () => {
                        console.log('fetchCustomerAndSubscriptions success');
                        dispatch(
                            workspaceActions.fetchUserSettings({
                                gridId,
                                viewId,
                                workspaceId,
                                dbId,
                                successCallback: () => {
                                    console.log('fetchUserSettings success');
                                    dispatch(
                                        gridUIActions.renderGridUITable({
                                            wsId: workspaceId,
                                            dbId,
                                            defaultAccessViewId: viewId,
                                            gridId: branchId,
                                            successCallback: message => {
                                                console.log('render table success');
                                                if (unmounted) return;
                                                dispatch(
                                                    gridUIActions.fetchAggregations({
                                                        successCallback: () => {
                                                            console.log('fetching aggregations success');
                                                        },
                                                        errorCallback: () => {
                                                            console.log('fetching aggregations failed');
                                                        }
                                                    })
                                                );

                                                dispatch(
                                                    gridUIActions.fetchViewFilters({
                                                        dbId,
                                                        viewId,
                                                        successCallback: () => {
                                                            console.log('fetching view filters done');
                                                        }
                                                    })
                                                );

                                                const { action, find } = queryParamsToObject(window?.location?.search);

                                                dispatch(
                                                    gridUIActions.fetchDependencies({
                                                        branchId,
                                                        dbId,
                                                        successCallback: () => {
                                                            console.log('fetchDependencies success');

                                                            if (find) {
                                                                dispatch(gridUIActions.jumpToRange(find));
                                                            }
                                                        },
                                                        errorCallback: () => {
                                                            console.log('fetchDependencies failed');
                                                        }
                                                    })
                                                );

                                                if (action?.indexOf('SEARCHCELL') > -1) {
                                                    const arr = action.split('-');
                                                    if (arr[1] && arr[2]) {
                                                        dispatch(
                                                            gridUIActions.jumpToCell({
                                                                recordId: arr[1],
                                                                columnId: arr[2]
                                                            })
                                                        );
                                                    }
                                                }
                                            },
                                            errorCallback: () => {
                                                console.log('load gridly failed');
                                            }
                                        })
                                    );
                                },
                                errorCallback: () => {
                                    console.log('fetchUserSettings failed');
                                }
                            })
                        );
                    },
                    errorCallback: () => {
                        console.log('fetchCustomerAndSubscriptions failed');
                    }
                })
            );
        }

        return function cleanup() {
            unmounted = true;
        };
    }, [isShareViewLink, gridId, viewId, workspaceId, dbId, dispatch, branchId]);

    React.useEffect(() => {
        if (!isFinishGridTutorial && isLoading === false) {
            dispatch(appActions.setCurrentTutorial(GRID_TUTORIAL));
        }
    }, [dispatch, isFinishGridTutorial, isLoading]);

    React.useEffect(() => {
        return () => {
            dispatch(clearTableData());
        };
    }, [dispatch]);

    const copyHandler = React.useCallback(
        e => {
            let event = document.all ? window.event : e;
            if (
                !(
                    (/^(?:input|textarea|select|button)$/i.test(e.target.tagName) &&
                        !checkContainClassName(event, 'ace_editor_disabled')) ||
                    checkContainClassName(event, 'cell-editor') ||
                    checkContainId(event, 'editable-mention')
                )
            ) {
                setCopyPaseEvt(e);
                dispatch(copy(e));
            }
        },
        [dispatch]
    );

    const pasteHandler = React.useCallback(
        e => {
            let event = document.all ? window.event : e;
            if (
                !(
                    (/^(?:input|textarea|select|button)$/i.test(e.target.tagName) &&
                        !checkContainClassName(event, 'ace_editor_disabled')) ||
                    checkContainClassName(event, 'cell-editor') ||
                    checkContainId(event, 'editable-mention') ||
                    document.getElementById('path-tag-filter')
                )
            ) {
                if (accessEditRecords === roleConst.FULL) {
                    setCopyPaseEvt(event);
                    dispatch(
                        paste({
                            event,
                            onOpenConfirm: () => handleOpenConfirm(CONFIRM_TYPES.PASTE_COLUMN),
                            accessManageGridRecord,
                            accessManageGridColumn,
                            onOpenExtendConfirm:
                                accessManageGridRecord || accessManageGridColumn ? handleOpenExtendConfirm : null
                        })
                    );
                } else {
                    dispatch(cannotPasteWarning());
                }
            }
        },
        [
            accessEditRecords,
            dispatch,
            accessManageGridRecord,
            accessManageGridColumn,
            handleOpenExtendConfirm,
            handleOpenConfirm
        ]
    );

    React.useEffect(() => {
        window.addEventListener('copy', copyHandler, true);
        window.addEventListener('paste', pasteHandler, true);
        return () => {
            window.removeEventListener('copy', copyHandler, true);
            window.removeEventListener('paste', pasteHandler, true);
        };
    }, [dispatch, copyHandler, pasteHandler]);

    const keydownHandler = React.useCallback(
        e => {
            let event = document.all ? window.event : e;
            if (
                !(
                    /^(?:input|textarea|select|button)$/i.test(e.target.tagName) ||
                    checkContainClassName(event, 'cell-editor') ||
                    checkContainId(event, 'editable-mention') ||
                    checkContainId(event, 'react-flow-wrapper')
                )
            ) {
                if (isKbSearch(event)) {
                    return;
                }

                if (isKbUndo(event)) {
                    dispatch(registerUndoRedo(USER_ACTION_TYPES.UNDO));
                    return;
                }

                if (isKbRedo(event)) {
                    dispatch(registerUndoRedo(USER_ACTION_TYPES.REDO));
                    return;
                }

                if (isKbRefresh(event)) {
                    window.location.reload();
                    return;
                }

                if (isKbCommandAndSlash(event)) {
                    dispatch(appActions.toggleKeyboardShortCut());
                    return;
                }

                if (isKbDelete(event) && accessManageGridColumn) {
                    dispatch(deleteKeyEvent({ onOpenConfirm: () => handleOpenConfirm(CONFIRM_TYPES.DELETE_COLUMN) }));
                    return;
                }

                if (isKbEscape(event)) {
                    dispatch(
                        setCopiedRange({
                            columnStartIndex: -1,
                            columnStopIndex: -1,
                            rowStartIndex: -1,
                            rowStopIndex: -1
                        })
                    );
                    return;
                }
            }
        },
        [dispatch, handleOpenConfirm, accessManageGridColumn]
    );

    React.useEffect(() => {
        window.addEventListener('keydown', keydownHandler, true);
        return () => {
            window.removeEventListener('keydown', keydownHandler, true);
        };
    }, [keydownHandler, dispatch]);

    React.useEffect(() => {
        return function cleanup() {
            dispatch(appActions.setCurrentTutorial(''));
        };
    }, [dispatch]);

    const onBack = React.useCallback(() => {
        history.push('/');
    }, [history]);

    const handleConfirmAgree = React.useCallback(
        e => {
            e.stopPropagation();

            setIsConfirming(true);
            if (actionType === CONFIRM_TYPES.DELETE_COLUMN) {
                dispatch(
                    gridUIActions.deleteColumnsValue({
                        successCallback: () => {
                            setIsConfirming(false);
                            handleCloseConfirm();
                        },
                        errorCallback: () => {
                            setIsConfirming(false);
                        }
                    })
                );
            }

            if (actionType === CONFIRM_TYPES.PASTE_COLUMN) {
                dispatch(
                    gridUIActions.pasteColumnsHeader({
                        success: () => {
                            setIsConfirming(false);
                            handleCloseConfirm();
                        },
                        error: () => {
                            setIsConfirming(false);
                        }
                    })
                );
            }
        },
        [actionType, dispatch, handleCloseConfirm]
    );

    const handleCloseAutomation = React.useCallback(() => {
        dispatch(automationActions.setOpenMultipleAutomationDialog(false));
    }, [dispatch]);

    const handleConfirmExtendPasteData = React.useCallback(
        e => {
            e.stopPropagation();
            if (extendPasteData.expand) {
                setIsConfirming(true);
                dispatch(
                    gridUIActions.pasteExtendRecordAndColumn({
                        extendPasteData,
                        accessManageGridColumn,
                        accessManageGridRecord,
                        successCallback: () => {
                            setIsConfirming(false);
                            handleCloseExtendConfirm();
                        },
                        errorCallback: () => {
                            setIsConfirming(false);
                        }
                    })
                );
            } else {
                handleCloseExtendConfirm();
                dispatch(
                    gridUIActions.paste({
                        content: contentBeforeConfirm,
                        event: copyPasteEvt
                    })
                );
            }
        },
        [accessManageGridColumn, accessManageGridRecord, dispatch, extendPasteData, handleCloseExtendConfirm]
    );

    return (
        <div container className={`flex bg-background relative h-full w-full`}>
            {currentGrid?.name && (
                <Helmet>
                    <title>{`${currentGrid ? `${currentGrid.name} -` : ''} Gridly`}</title>
                </Helmet>
            )}
            {(isImporting || lqaExporting || replacingText) && (
                <div
                    className={
                        'flex items-center flex-row fixed left-0 right-0 m-auto p-3.5 bottom-[53px] !w-[300px] z-[100000] rounded-lg shadow bg-white'
                    }
                >
                    <ProgressLinear
                        title={t(
                            replacingText ? `global_replacing` : lqaExporting ? `global_exporting` : `global_importing`
                        )}
                        icon={!replacingText ? <ExcelSVG /> : <TMSearchNotFoundSVG width="60" height="40" />}
                    />
                </div>
            )}
            {isApplyingTM && (
                <div
                    className={
                        'flex flex-row items-center fixed left-0 right-0 m-auto p-3.5 bottom-[53px] !w-[300px] z-[100000] rounded-lg shadow bg-white'
                    }
                >
                    <ProgressLinear
                        title={t(`applying_tm`)}
                        icon={<Sync2SVG className={'animate-spin 1s'} width="52" height="40" />}
                    />
                </div>
            )}
            {isLoadingClearingRecords && (
                <div
                    className={
                        'flex flex-row items-center fixed left-0 right-0 m-auto p-3.5 bottom-[53px] !w-[300px] z-[100000] rounded-lg shadow bg-white'
                    }
                >
                    <ProgressLinear
                        title={
                            userClearingRecords?.fullName
                                ? `${userClearingRecords.fullName} is deleting records...`
                                : 'Deleting records...'
                        }
                    />
                </div>
            )}

            {isSearchingRecord && (
                <Grid
                    className={
                        'flex flex-row items-center fixed left-0 right-0 m-auto p-3.5 bottom-[53px] !w-[300px] z-[100000] rounded-lg shadow bg-white'
                    }
                >
                    <ProgressLinear
                        background={theme.colors.lightLavender}
                        foreground={theme.colors.solitude}
                        title={'Looking for data...'}
                        icon={<img className={'w-[52px] h-[52px]'} src={searchGif} alt="Search gif" />}
                    />
                </Grid>
            )}

            {isGridRestoring && (
                <RestoringGridConfirmation
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            )}
            {isDBRestoring && (
                <RestoringDBConfirmation
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            )}
            {isDBBackingUp && (
                <BackingUpDBConfirmation
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            )}
            {isMerging && <ConfirmMergingBox />}
            {isBackuping && (
                <BackingUpGridConfirmation
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            )}

            <Grid
                item
                className={classNames(`flex-1 sm:w-0`)}
                style={{
                    marginRight: openRightSideBar ? rightSidebarWidth + SIDEBAR_RIGHT_CONTENT : rightSidebarWidth,
                    width: openRightSideBar
                        ? `calc(100vw - ${rightSidebarWidth + SIDEBAR_RIGHT_CONTENT}px)`
                        : `calc(100vw - ${rightSidebarWidth}px)`
                }}
            >
                <Grid container direction="column" className="h-full">
                    <Grid
                        item
                        className={classNames(`relative py-1.5 px-3.5 bg-white border-b border-grey-border w-full`, {
                            disabled: isImporting || isViewBlocked || isLoadingClearingRecords
                        })}
                    >
                        <div id="grid-header-ghost" className="absolute right-3.5 bottom-2.5" />
                        <Toolbar
                            onOpenTranslationOverview={handleOpenTranslationOverview}
                            workspaceId={workspaceId}
                            gridId={gridId}
                            viewId={viewId}
                            dbId={dbId}
                            branchId={branchId}
                            isShareViewLink={isShareViewLink}
                            t={t}
                            onOpenGridTypeSetting={handleOpenGridTypeSetting}
                        />
                    </Grid>
                    {isViewBlocked && (
                        <div
                            className="flex flex-col justify-center items-center gap-3.5"
                            style={{
                                height: `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT}px)`
                            }}
                        >
                            <NoAccessSVG />
                            <h3 className="prose prose-2xl font-medium">You need permission</h3>
                            <p className="body2">Ask the owner/admin of the Company to request access to the Grid.</p>
                            <ButtonBase onClick={onBack} color="default" variant="outlined">
                                Back to dashboard
                            </ButtonBase>
                        </div>
                    )}
                    {!isFetchingView &&
                        !isViewBlocked &&
                        !isFetchingUserSetting &&
                        isLoading !== null &&
                        (isLoading ? (
                            <div
                                className="flex flex-col items-center justify-center"
                                style={{
                                    height: `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT}px)`
                                }}
                            >
                                <Spinner size={60} thick={6} />
                            </div>
                        ) : (
                            <GridUI
                                roles={roles}
                                workspaceId={workspaceId}
                                gridId={gridId}
                                viewId={viewId}
                                dbId={dbId}
                                branchId={branchId}
                                isShareViewLink={isShareViewLink}
                                t={t}
                            />
                        ))}
                </Grid>
            </Grid>

            <Drawer
                anchor="right"
                variant="permanent"
                classes={{
                    paper: classNames(`!z-[99] !top-[60px] !overflow-y-visible`, {
                        [`!w-[490px]`]: openRightSideBar,
                        'w-0': isShareViewLink && !canAccessGridHistoryInShareView && !openRightSideBar,
                        'w-[40px]': !openRightSideBar && !(isShareViewLink && !canAccessGridHistoryInShareView)
                    })
                }}
            >
                <Grid container wrap="nowrap" className={'h-full'}>
                    {Boolean(rightSidebarWidth) && (
                        <Grid
                            item
                            className={classNames(`w-full`, {
                                disabled: isImporting || isLoadingClearingRecords
                            })}
                        >
                            <SidebarRight
                                handleClose={handleCloseRightSidebar}
                                action={action}
                                handleItemClick={handleItemClick}
                                workspaceId={workspaceId}
                                gridId={gridId}
                                viewId={viewId}
                                dbId={dbId}
                                branchId={branchId}
                                t={t}
                                isShareViewLink={isShareViewLink}
                                canAccessGridHistoryInShareView={canAccessGridHistoryInShareView}
                            />
                        </Grid>
                    )}
                    {openRightSideBar && (
                        <Grid
                            item
                            className={classNames(`flex`, {
                                'border-l-none': !rightSidebarWidth,
                                'border-l border-grey-border': rightSidebarWidth,
                                disabled: isImporting || isLoadingClearingRecords
                            })}
                        >
                            <RenderRightSidebarContent
                                className={`sm:w-[calc(100vw - ${rightSidebarWidth}px)]`}
                                handleClose={handleCloseRightSidebar}
                                width={SIDEBAR_RIGHT_CONTENT}
                                action={action}
                                workspaceId={workspaceId}
                                gridId={gridId}
                                viewId={viewId}
                                dbId={dbId}
                                branchId={branchId}
                                t={t}
                                onOpenJsonExport={openJsonExport}
                                isShareViewLink={isShareViewLink}
                            />
                        </Grid>
                    )}
                </Grid>
            </Drawer>
            {currentTutorial && !isShareViewLink && !isLoading && <Tutorial onFinish={handleFinished} />}
            <ActiveUserActionProvider />
            <UndoProvider />
            {isOpenPreview && (
                <Dialog
                    className={'dialog'}
                    fullScreen
                    open={isOpenPreview}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <FilesPreview
                        onDelete={deleteImageHandler}
                        files={filesPreview}
                        onClose={handleClose}
                        defaultImage={previewDefaultFile}
                        isShareViewLink={isShareViewLink}
                        dbId={dbId}
                    />
                </Dialog>
            )}
            <Dialog maxWidth={false} open={isOpenJsonExport} onClose={closeJsonExport}>
                <LocalizationJsonExport onClose={closeJsonExport} />
            </Dialog>

            <PreloadImages
                paths={[
                    viewSortGif,
                    manageColumnGif,
                    viewFilterGif,
                    dependencyGif,
                    referenceRecordGif,
                    referenceCellValueGif,
                    groupByReferenceGif,
                    searchGif
                ]}
            />

            <Dialog id="confirm-column-box" open={Boolean(actionType)} onClose={handleCloseConfirm}>
                <ConfirmBox
                    title={`Perform ${actionType}?`}
                    body={
                        <div className="body2">
                            This action will change all records in the column. Are you sure you want to continue?
                        </div>
                    }
                    handleCancel={handleCloseConfirm}
                    onClose={handleCloseConfirm}
                    handleAgreed={handleConfirmAgree}
                    agreeLabel="Confirm"
                    isLoading={isConfirming}
                />
            </Dialog>
            <Dialog
                maxWidth="md"
                id="confirm-column-box"
                open={Boolean(extendPasteData)}
                onClose={handleCloseExtendConfirm}
            >
                <ConfirmBox
                    width={600}
                    title="Do you want to expand this grid?"
                    body={
                        <Grid item>
                            <div className="body2" dangerouslySetInnerHTML={{ __html: extendPasteData?.message }} />
                            <Grid item>
                                <RadioGroup
                                    defaultValue={extendPasteData?.expand ? 'expand' : 'keep'}
                                    onChange={e =>
                                        setExtendPasteData({
                                            ...extendPasteData,
                                            expand: e.target.value === 'expand'
                                        })
                                    }
                                >
                                    <Grid item>
                                        <FormControlLabel
                                            value={'expand'}
                                            control={<Radio size="small" />}
                                            label={
                                                <p className="body2">
                                                    <b>Expand the table</b> so that all of the pasted cells will fit.
                                                </p>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value={'keep'}
                                            control={<Radio size="small" />}
                                            label={
                                                <p className="body2">
                                                    <b>Don't expand the table.</b> Values outside of the table will not
                                                    be pasted.
                                                </p>
                                            }
                                        />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    }
                    handleCancel={handleCloseExtendConfirm}
                    onClose={handleCloseExtendConfirm}
                    handleAgreed={handleConfirmExtendPasteData}
                    agreeLabel="Continue"
                    isLoading={isConfirming}
                />
            </Dialog>

            <Dialog id="confirm-column-box" open={isOpenMultipleAutomationDialog} onClose={handleCloseAutomation}>
                <MultipleAnimation onClose={handleCloseAutomation} />
            </Dialog>
        </div>
    );
}

export default React.memo(GridPage);
