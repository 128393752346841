import React from 'react';
import { Grid } from '@material-ui/core';
import RecordHistory from 'gridUI/recordHistory';
import { AutoSizer } from 'react-virtualized-dn';

function RecordHistoryWrapper({ height: mainHeight, ...rest }) {
    return (
        <Grid container style={{ height: mainHeight, padding: `12px 24px 12px` }}>
            <AutoSizer>
                {({ width, height }) => {
                    return <RecordHistory width={width} height={height - 24} {...rest} />;
                }}
            </AutoSizer>
        </Grid>
    );
}

export default RecordHistoryWrapper;
