import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useTranslation } from 'react-i18next';

function DatabaseButtonAdd({ buttonClickHandler, disabled, fetchingDisabled }) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <Grid container id={`addDbBtn`} justify="center" alignItems="center">
            <Grid item style={{ pointerEvents: fetchingDisabled ? 'none' : '' }}>
                <Button
                    onClick={buttonClickHandler}
                    tr-dt="Add Database"
                    width={isTablet ? 'auto' : 144}
                    variant="contained"
                    size="large"
                    disabled={disabled}
                >
                    <AddIconSVG color={theme.colors.white} style={{ marginRight: 8, width: 10, height: 10 }} />
                    {isTablet ? t('dashboard_add_database_button_tablet') : t('dashboard_add_database_button_desktop')}
                </Button>
            </Grid>
        </Grid>
    );
}

DatabaseButtonAdd.propTypes = {
    buttonClickHandler: PropTypes.func
};
export default DatabaseButtonAdd;
