import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_TRANSLATION_DASHBOARD_DATA](state, { payload }) {
        const { viewId } = payload;
        const { translationDashboard } = state;
        const newTranslationDashboard = produce(translationDashboard, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetching = true;
        });
        return {
            ...state,
            translationDashboard: newTranslationDashboard
        };
    },
    [types.FETCH_TRANSLATION_DASHBOARD_DATA_FAILED](state, { payload }) {
        const { viewId, error } = payload;
        const { translationDashboard } = state;
        const newTranslationDashboard = produce(translationDashboard, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }

            draft[viewId].isFetching = false;
            draft[viewId].error = error;
        });

        return {
            ...state,
            translationDashboard: newTranslationDashboard
        };
    },
    [types.FETCH_TRANSLATION_DASHBOARD_DATA_SUCCESS](state, { payload }) {
        const { viewId, data } = payload;
        const { translationDashboard } = state;
        const newTranslationDashboard = produce(translationDashboard, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }

            draft[viewId].isFetching = false;
            draft[viewId].data = data;
        });

        return {
            ...state,
            translationDashboard: newTranslationDashboard
        };
    }
};

export default handler;
