import { USER_LOGOUT_SUCCESS } from 'auth/types';
import createReducer from 'utils/createReducer';
import { branchHandler, previewHandler } from './reducers';
import { RECORDS_RENDER_GRID_PREVIEW } from 'const/gridUI';

const INITIAL_STATE = {
    selectedBranchId: null,
    branchType: null,
    gridBranches: {
        gridId: {
            list: null,
            isFetching: false
        }
    },
    databaseBranches: {
        dbId: {
            list: null,
            isFetching: false
        }
    },
    isMerging: false,
    mergingGridPreview: {
        isInitializing: false,
        isFetchingMore: false,
        columns: [],
        rows: [],
        data: {},
        metaData: [],
        totalRecords: 0,
        ROW_START_INDEX: 0,
        ROW_STOP_INDEX: RECORDS_RENDER_GRID_PREVIEW,
        tree: []
    }
};

const commonHandler = {
    [USER_LOGOUT_SUCCESS]() {
        return INITIAL_STATE;
    }
};

export default createReducer(INITIAL_STATE, Object.assign(commonHandler, branchHandler, previewHandler));
