import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { HEADER_HEIGHT } from 'const';
import * as tmActions from './actions';
import { useTMList, useIsShowLastPopupCreate, useIsCreatingTM } from 'hooks/tm';
import TMItem from './TMItem';
import * as workspaceActions from 'workspaces/actions';
import Skeleton from 'components/skeleton/Base';
import scrollIntoView from 'scroll-into-view-if-needed';
import SettingContentLayout from 'permission/common/Layout';
import { useIsCompanyHasTMPermission } from 'hooks/payment';
import TMBannerSVG from 'assets/images/svg/payment/illusttration/TMBannerSVG';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TMSearchBox from './TMSearchBox';
import SearchSVG from 'assets/images/svg/SearchSVG';
import Dialog from 'components/dialog/Dialog';

const useStyles = makeStyles(theme => ({
    container: {
        height: `calc(100vh - 40px - ${HEADER_HEIGHT}px)`,
        background: theme.colors.white,
        overflowY: 'hidden',
        padding: `22px 24px`
    },
    tmList: {
        height: `calc(100%)`,
        overflowY: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    },
    tmListContent: {
        maxWidth: `60vw`
    },
    tmItem: {
        '&:not(last-child)': {
            marginBottom: theme.spacing(3)
        }
    },
    search: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    noHasPermission: {
        width: '100%',
        height: '100%'
    },
    mxw500: {
        maxWidth: 500,
        margin: '0 auto',
        textAlign: 'center'
    },
    searchPopup: {
        '& > div': {
            padding: 0
        }
    },
    searchIcon: {
        marginRight: theme.spacing(2)
    }
}));

function TMPage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tmList = useTMList();
    const isShowLastPopupCreate = useIsShowLastPopupCreate();
    const isCreatingTM = useIsCreatingTM();
    const isCompanyHasTM = useIsCompanyHasTMPermission();
    const history = useHistory();
    const rootRef = React.useRef();
    const [openSearch, setOpenSearch] = React.useState(false);

    const handleOpenSearch = React.useCallback(event => {
        setOpenSearch(true);
    }, []);

    const handleCloseSearch = React.useCallback(event => {
        setOpenSearch(false);
    }, []);

    React.useEffect(() => {
        dispatch(
            tmActions.fetchCurrentCompanyTMs({
                successCallback: () => {
                    console.log('fetchCurrentCompanyTMs success');
                },
                errorCallback: () => {
                    console.log('fetchCurrentCompanyTMs failed');
                }
            })
        );

        dispatch(
            workspaceActions.fetchWorkspaces({
                successCallback: () => {
                    console.log('fetch projects success');
                },
                errorCallback: () => {
                    console.log('failed to fetch project');
                }
            })
        );
    }, [dispatch]);

    const handleCreateTM = () => {
        dispatch(
            tmActions.createTM({
                successCallback: () => {
                    console.log('create TM successfully');
                    const node = document.getElementById('tmListBottom');
                    scrollIntoView(node, {
                        behavior: 'smooth',
                        block: 'end',
                        duration: 300
                        // boundary: document.getElementById('tmListBoundary')
                    });
                },
                errorCallback: () => {
                    console.log('create TM failed');
                }
            })
        );
    };

    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    return (
        <>
            <SettingContentLayout name={t('global_translation_memory')}>
                {!isCompanyHasTM ? (
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        className={classes.noHasPermission}
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <TMBannerSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="h3">
                                <span role="img" aria-label="emoji">
                                    ✨
                                </span>{' '}
                                {t('global_translation_memory')}{' '}
                                <span role="img" aria-label="emoji">
                                    ✨
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item className={classes.mxw500}>
                            <Typography variant="caption">{t('tm_page_dont_translate_more_than_one')}</Typography>
                        </Grid>
                        <Grid item>
                            <ButtonBase width={120} variant="contained" onClick={handleUpgrade}>
                                <UpgradeSVG className={classes.upgradeIcon} />
                                {t('global_upgrade')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid id="tm-page" container direction="column" wrap="nowrap" spacing={5}>
                        <Grid item>
                            <Grid className={classes.container} container direction="column" wrap="nowrap" spacing={4}>
                                <Grid item container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h4">{t('tm_page_your_current_tm')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ButtonBase
                                            tr-dt="Create TM"
                                            disabled={isCreatingTM}
                                            variant="outlined"
                                            onClick={handleCreateTM}
                                        >
                                            + {t('tm_page_create_tm')}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">{t('tm_page_the_default_tm')}</Typography>
                                </Grid>
                                <Grid item className={`${classes.tmList} scrollbar-app`}>
                                    <Grid
                                        id={'tmListBoundary'}
                                        className={classes.tmListContent}
                                        container
                                        direction="column"
                                        wrap="nowrap"
                                    >
                                        <Grid item ref={rootRef}>
                                            <Grid
                                                container
                                                onClick={handleOpenSearch}
                                                direction="row"
                                                justify="flex-end"
                                            >
                                                <ButtonBase variant="outlined">
                                                    <SearchSVG className={classes.searchIcon} />
                                                    Search for TM
                                                </ButtonBase>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.search}>
                                            <Divider />
                                        </Grid>
                                        {tmList?.map((tm, index) => {
                                            return (
                                                <Grid className={`${classes.tmItem}`} item key={tm?.id}>
                                                    <TMItem
                                                        tm={tm}
                                                        {...tm}
                                                        isShowLastPopupCreate={
                                                            isShowLastPopupCreate === tm.id &&
                                                            index === tmList.length - 1
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })}
                                        <div id={'tmListBottom'} />
                                        {isCreatingTM && (
                                            <Grid item className={`${classes.tmItem}`}>
                                                <Skeleton height={42} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </SettingContentLayout>
            <Dialog hideBackdrop={true} open={openSearch} maxWidth={false} onClose={handleCloseSearch}>
                <TMSearchBox tmList={tmList} onClose={handleCloseSearch} />
            </Dialog>
        </>
    );
}

export default React.memo(TMPage);
