import React from 'react';
import clsx from 'clsx';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import RemoveTagSVG from 'assets/images/svg/RemoveTagSVG';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.5, 0.25),
        '& .MuiChip-deleteIcon': {
            width: 8,
            height: 8
        }
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        )
    }
}));

function MultiValue(props) {
    const classes = useStyles();
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(classes.chip, {
                [classes.chipFocused]: props.isFocused
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<RemoveTagSVG {...props.removeProps} />}
        />
    );
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool.isRequired,
    removeProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onTouchEnd: PropTypes.func.isRequired
    }).isRequired,
    selectProps: PropTypes.object.isRequired
};

export default React.memo(MultiValue);
