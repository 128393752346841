import * as types from '../types';

const handler = {
    [types.FETCH_ADD_ON_PLANS_SUCCESS](state, { payload }) {
        const { plans } = payload;
        return {
            ...state,
            addonPlans: plans
        };
    },
    [types.FETCH_ADD_ON_USAGE_SUCCESS](state, { payload }) {
        const { addonUsage } = payload;
        return {
            ...state,
            addonUsage
        };
    }
};

export default handler;
