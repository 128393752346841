import React from 'react';

const GoogleOCRSVG = ({ ...others }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
            <path
                d="M4.43242 12.0855L3.73625 14.6845L1.19176 14.7383C0.431328 13.3279 0 11.7141 0 9.9993C0 8.34105 0.403281 6.7773 1.11812 5.40039H1.11867L3.38398 5.8157L4.37633 8.06742C4.16863 8.67293 4.05543 9.32293 4.05543 9.9993C4.05551 10.7334 4.18848 11.4367 4.43242 12.0855Z"
                fill="#FBBB00"
            />
            <path
                d="M19.8242 8.13281C19.939 8.73773 19.9989 9.36246 19.9989 10.0009C19.9989 10.7169 19.9236 11.4152 19.7802 12.0889C19.2934 14.3812 18.0214 16.3828 16.2594 17.7993L16.2588 17.7987L13.4055 17.6532L13.0017 15.1323C14.1709 14.4466 15.0847 13.3735 15.566 12.0889H10.2188V8.13281H15.644H19.8242Z"
                fill="#518EF8"
            />
            <path
                d="M16.2595 17.7975L16.2601 17.798C14.5464 19.1755 12.3694 19.9996 9.99965 19.9996C6.19141 19.9996 2.88043 17.8711 1.19141 14.7387L4.43207 12.0859C5.27656 14.3398 7.45074 15.9442 9.99965 15.9442C11.0952 15.9442 12.1216 15.648 13.0024 15.131L16.2595 17.7975Z"
                fill="#28B446"
            />
            <path
                d="M16.382 2.30219L13.1425 4.95437C12.2309 4.38461 11.1534 4.05547 9.99906 4.05547C7.39246 4.05547 5.17762 5.73348 4.37543 8.06812L1.11773 5.40109H1.11719C2.78148 2.1923 6.13422 0 9.99906 0C12.4254 0 14.6502 0.864297 16.382 2.30219Z"
                fill="#F14336"
            />
        </svg>
    );
};

export default React.memo(GoogleOCRSVG);
