import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelectedCellData, useSelectedRecordId } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';
import CensorVersionControlLightSVG from 'assets/images/svg/censors/VersionControlLightSVG';
import SingleLine from './components/SingleLine';
import MultipleLines from './components/MultipleLines';
import RecordId from './components/RecordId';
import TranslationText from './components/Translation';

function renderEditor(props) {
    switch (props?.column?.type) {
        case columnTypes.SINGLE_LINE:
            return <SingleLine {...props} />;
        case columnTypes.RECORD_ID:
            return <RecordId {...props} />;
        case columnTypes.MULTIPLE_LINES:
            return <MultipleLines {...props} />;
        case columnTypes.TRANSLATION:
            return <TranslationText {...props} />;
        default:
            return null;
    }
}

function EditorView({ height, showDiff, setShowDiff }) {
    const selectedCellData = useSelectedCellData();
    const recordId = useSelectedRecordId();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (recordId) {
            dispatch(
                gridActions.fetchRecordHistory({
                    recordId,
                    errorCallback: () => {
                        console.log('failed to refresh recordHistory');
                    }
                })
            );
        }
    }, [dispatch, recordId]);

    if (!selectedCellData)
        return (
            <Grid style={{ height }} container direction="row" alignItems="center" justify="center">
                <p className="caption">Please select a cell to edit</p>
            </Grid>
        );

    if (
        ![
            columnTypes.SINGLE_LINE,
            columnTypes.RECORD_ID,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION
        ]?.includes(selectedCellData?.column?.type)
    )
        return (
            <Grid
                style={{ height }}
                container
                direction="column"
                alignItems="center"
                justify="center"
                spacing={2}
                wrap="nowrap"
            >
                <Grid item>
                    <CensorVersionControlLightSVG
                        {...(height < 270
                            ? {
                                  width: 150,
                                  height: 159
                              }
                            : {})}
                    />
                </Grid>
                <Grid item>
                    <h3 className="prose prose-2xl font-medium">Coming soon</h3>
                </Grid>
                <Grid item>
                    <p className="body2 text-steel">Sorry this column type will be supported in the next update</p>
                </Grid>
            </Grid>
        );

    return (
        <Grid container className={'max-w-[1920px] my-0 mx-auto'}>
            {renderEditor({ ...selectedCellData, height, showDiff, setShowDiff })}
        </Grid>
    );
}

export default EditorView;
