import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { updateTB } from 'gridUI/actions';
import ProjectAndDatabaseAccessList from 'permission/groups/createGroup/ProjectAndDatabaseAccessList';
import { getProjectsAndDatabasesApi } from 'services/workspaces';
import { allDatabaseOption, defaultProjectAccess, getProjectAccessedFromServer, getProjectsServer } from './utils';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 30px',
        maxWidth: 678,
        height: '100%'
    },
    projectContainer: {
        marginTop: 30,
        border: `1px solid ${theme.colors.border}`
    },
    projectContainerHeader: {
        padding: '13px 17px',
        background: '#F4F5F7'
    },
    openAssignProject: {
        cursor: 'pointer'
    },
    addProjectText: {
        color: theme.palette.primary.main
    },
    projectContainerItem: {
        padding: '13px 17px',
        borderTop: `1px solid ${theme.colors.border}`
    },
    workspaceIcon: {
        marginRight: 8,
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    zIndex: {
        zIndex: theme.zIndex.modal + 200,
        width: 200
    },
    listItem: {
        maxHeight: 376,
        overflow: 'auto'
    },
    btnContainer: {
        marginTop: 24
    },
    addIcon: {
        cursor: 'pointer',
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    panelSummaryClassName: {
        display: 'none !important'
    }
}));

const TBDetailAssignProject = ({ glossary, setGlossary }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [projectAccesses, setProjectAccesses] = useState([defaultProjectAccess]);
    const [projects, setProjects] = useState([]);

    React.useEffect(() => {
        const getProjectAndDatabases = async () => {
            try {
                const projects = await getProjectsAndDatabasesApi();
                setProjects(projects);
            } catch (error) {
                console.log('getProjectAndDatabases failed');
            }
        };
        getProjectAndDatabases();
    }, []);

    const optionsObj = useMemo(() => {
        return projects.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});
    }, [projects]);

    useEffect(() => {
        if (!isEmpty(glossary?.projects) && !isEmpty(optionsObj)) {
            setProjectAccesses(
                getProjectAccessedFromServer({
                    serverProjects: glossary.projects,
                    projectOptions: optionsObj
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsObj]);

    const updateGlossary = newProjectAccess => {
        const oldGlossary = { ...glossary };
        setGlossary({ ...oldGlossary, projects: getProjectsServer(newProjectAccess) });
        dispatch(
            updateTB({
                oldTB: { ...oldGlossary },
                newTB: { ...oldGlossary, projects: getProjectsServer(newProjectAccess) },
                errorCallback: () => {
                    setGlossary({ ...oldGlossary });
                }
            })
        );
    };

    const handleProjectChange = (index, newProjectOption) => {
        const newProjectAccess = projectAccesses.map((access, id) => {
            if (id === index)
                return { ...access, project: newProjectOption, database: allDatabaseOption, isFake: false };
            return access;
        });
        setProjectAccesses(newProjectAccess);
        updateGlossary(newProjectAccess);
    };

    const handleDatabaseChange = (index, newDatabaseOption) => {
        const newProjectAccess = projectAccesses.map((access, id) => {
            if (id === index) return { ...access, database: newDatabaseOption, isFake: false };
            return access;
        });
        setProjectAccesses(newProjectAccess);
        updateGlossary(newProjectAccess);
    };

    const handleAccessAdd = () => {
        setProjectAccesses([...projectAccesses, defaultProjectAccess]);
    };

    const handleAccessRemove = index => {
        const newProjectAccess = projectAccesses.filter((_, id) => id !== index);
        setProjectAccesses(newProjectAccess);
        updateGlossary(newProjectAccess);
    };

    return (
        <Grid item className={classes.root}>
            <Grid item>
                <Typography variant="body2">
                    Assign this glossary to some projects and use it for translation activity
                </Typography>
            </Grid>
            <Grid item>
                <ProjectAndDatabaseAccessList
                    noNeedFetchProjects
                    defaultExpanded
                    hideLoading
                    panelSummaryClassName={classes.panelSummaryClassName}
                    projectAccesses={projectAccesses}
                    externalProjects={projects}
                    onProjectChange={handleProjectChange}
                    onDatabaseChange={handleDatabaseChange}
                    onAccessAdd={handleAccessAdd}
                    onAccessRemove={handleAccessRemove}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(TBDetailAssignProject);
