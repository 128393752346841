import React from 'react';

function MarkdownSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 2.3H2C1.6134 2.3 1.3 2.6134 1.3 3V13C1.3 13.3866 1.6134 13.7 2 13.7H14C14.3866 13.7 14.7 13.3866 14.7 13V3C14.7 2.6134 14.3866 2.3 14 2.3ZM2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2Z"
                fill={color}
            />
            <path
                d="M7.81364 5.01367C8.04154 5.01367 8.22746 5.08801 8.37139 5.23668C8.51532 5.38536 8.58729 5.57864 8.58729 5.81652V10.6693C8.58729 10.8953 8.52132 11.0737 8.38938 11.2045C8.25744 11.3354 8.08352 11.4008 7.86762 11.4008C7.65771 11.4008 7.48379 11.3354 7.34586 11.2045C7.21392 11.0737 7.14795 10.8953 7.14795 10.6693V8.04665L6.12241 9.91996C6.02046 10.1103 5.91251 10.247 5.79856 10.3303C5.68461 10.4076 5.54967 10.4463 5.39374 10.4463C5.23781 10.4463 5.10288 10.4076 4.98893 10.3303C4.87498 10.247 4.76703 10.1103 4.66507 9.91996L3.64854 8.10017V10.6693C3.64854 10.8893 3.57957 11.0677 3.44163 11.2045C3.30969 11.3354 3.13577 11.4008 2.91987 11.4008C2.70996 11.4008 2.53604 11.3354 2.39811 11.2045C2.26617 11.0737 2.2002 10.8953 2.2002 10.6693V5.81652C2.2002 5.57864 2.27216 5.38536 2.4161 5.23668C2.56603 5.08801 2.75494 5.01367 2.98284 5.01367C3.14477 5.01367 3.2917 5.06125 3.42364 5.1564C3.55558 5.24561 3.66653 5.37644 3.75649 5.5489L5.41174 8.63541L7.05799 5.5489C7.2439 5.19208 7.49579 5.01367 7.81364 5.01367Z"
                fill={color}
            />
            <path
                d="M9.53431 9.02C9.53101 9.18992 9.59144 9.35536 9.70402 9.47968L11.2589 11.1993L11.3299 11.2661L11.3371 11.2717C11.4481 11.359 11.5854 11.4054 11.7273 11.4004C11.9027 11.3942 12.0658 11.31 12.1774 11.1714L13.8381 9.48111L13.9036 9.40032L13.9094 9.3914C14.0525 9.17147 14.036 8.86869 13.8537 8.667L13.7636 8.56661L13.6874 8.49697L13.6785 8.49049C13.4658 8.33469 13.1715 8.35406 12.9789 8.54991L12.3818 9.15767L12.3822 5.44857L12.3736 6.40678L12.3721 5.33331C12.321 5.03507 12.0727 4.80078 11.765 4.80078C11.4186 4.80078 11.1482 5.09681 11.1482 5.44857L11.1482 9.19408L10.599 8.58653L10.533 8.52366L10.5263 8.51832C10.4233 8.43553 10.2967 8.388 10.1642 8.38511C9.99846 8.38149 9.83951 8.44793 9.72194 8.56776C9.60444 8.68722 9.53761 8.85005 9.53431 9.02Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(MarkdownSVG);
