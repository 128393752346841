import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import React, { useCallback, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/styles';
import DialogContent from 'components/dialog/DialogContent';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import InputText from 'components/inputs/InputText';
import ButtonBase from 'components/button/Base';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { createTokenSet, updateTokenSet } from 'gridUI/actions';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 388
    },
    btnContainer: {
        padding: '17px 24px',
        borderTop: `1px solid ${theme.colors.border}`
    },
    dialogContent: {
        padding: 0,
        overflow: 'hidden',
        minWidth: 388
    },
    title: {
        padding: theme.spacing(4)
    },
    contentContainer: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(5)
    },
    form: {
        '& > div': {
            paddingTop: `0px !important`
        }
    }
}));

const TBDetailAdd = ({ tag, initData, open, onClose, handleResetFetch }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(open);

    console.log('initData', initData);

    const isEdit = useMemo(() => {
        return !isEmpty(initData);
    }, [initData]);

    const _handleClose = useCallback(() => {
        setDialogOpen(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }, [onClose]);

    const onSubmit = useCallback(
        (values, { setSubmitting }) => {
            setSubmitting(true);
            const body = {
                ...values
            };
            if (isEdit) {
                dispatch(
                    updateTokenSet({
                        tokenSetId: tag.id,
                        tokenId: initData?.id,
                        body,
                        successCallback: () => {
                            _handleClose();
                            handleResetFetch({ resetPage: false });
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            } else {
                dispatch(
                    createTokenSet({
                        tokenSetId: tag.id,
                        body,
                        successCallback: () => {
                            _handleClose();
                            handleResetFetch({ resetPage: true });
                        },
                        errorCallback: () => {
                            setSubmitting(false);
                        }
                    })
                );
            }
        },
        [dispatch, _handleClose, tag, handleResetFetch, isEdit, initData]
    );

    return (
        <Dialog maxWidth={false} open={dialogOpen} onClose={_handleClose}>
            <DialogTitle
                title={!isEdit ? t('add_tag') : t('edit_tag')}
                onClose={_handleClose}
                className={classes.title}
                showCloseIcon={false}
            />
            <Formik
                initialValues={{
                    value: isEdit ? initData?.value : ''
                }}
                validationSchema={object().shape({
                    value: string().required('This field is required')
                })}
                onSubmit={onSubmit}
                isInitialValid={false}
                render={({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    isValid,
                    isSubmitting
                }) => {
                    return (
                        <Form className={classes.form} onSubmit={handleSubmit}>
                            <DialogContent className={classes.dialogContent}>
                                <Grid container className={classes.contentContainer} direction="column" wrap="nowrap">
                                    <Grid item style={{ marginBottom: theme.spacing(1) }}>
                                        <Typography variant="body1">{t('tag_value')}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="value"
                                            value={values.value || ''}
                                            height={40}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.value && touched.value}
                                            errorText={errors.value}
                                            placeholder={t('input_tag_format')}
                                            autoFocus={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    className={classes.btnContainer}
                                    container
                                    alignItems="center"
                                    justify="flex-end"
                                    spacing={3}
                                >
                                    <Grid item>
                                        <ButtonBase width={120} variant="outlined" onClick={_handleClose}>
                                            {t('global_cancel')}
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item>
                                        <ButtonBase
                                            width={120}
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting || !isValid}
                                        >
                                            {t(!isEdit ? 'global_add' : 'global_save')}{' '}
                                            {isSubmitting && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Form>
                    );
                }}
            />
        </Dialog>
    );
};

export default React.memo(TBDetailAdd);
