import React from 'react';

function PlayOutlinedSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M1.33398 1L10.6673 7L1.33398 13V1Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default React.memo(PlayOutlinedSVG);
