import { useSelector } from 'react-redux';
import { useUserViewSettingByProperty } from 'hooks/workspace';
import { useWorkspaceIdInGridUI, useDbIdInGridUI, useDefaultAccessViewIdInGridUI } from 'hooks/gridUI';
import { USER_SETTINGS, SUBSCRIPTION_STATUS, PLAN_TYPES } from 'const';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { useAddonSubscription } from 'hooks/payment';

function useIsEnabledAutoQA() {
    const autoQASubscription = useAddonSubscription(PLAN_TYPES.AUTO_QA);
    return (
        autoQASubscription &&
        (autoQASubscription.status === SUBSCRIPTION_STATUS.ACTIVE ||
            autoQASubscription.status === SUBSCRIPTION_STATUS.TRIALING)
    );
}

export function useIsShowAutoQAButtons() {
    const roles = useRole();
    const isEnabledAutoQA = useIsEnabledAutoQA();
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];
    return accessEditTranslation === roleConst.FULL && isEnabledAutoQA;
}

export function useIsShowAutoQA() {
    const workspaceId = useWorkspaceIdInGridUI();
    const dbId = useDbIdInGridUI();
    const defaultAccessViewId = useDefaultAccessViewIdInGridUI();
    const isEnabledAutoQA = useIsEnabledAutoQA();

    const autoQAUserSetting = useUserViewSettingByProperty({
        dbId,
        viewId: defaultAccessViewId,
        wsId: workspaceId,
        property: USER_SETTINGS.VIEW.AUTO_QA
    });

    const roles = useRole();
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];

    return accessEditTranslation === roleConst.FULL && autoQAUserSetting && isEnabledAutoQA;
}

export function useIsRunningAutoQA() {
    return useSelector(state => state.gridUI.isRunningAutoQA);
}

export function useShowErrorControlPanel() {
    return useSelector(state => state.gridUI.showErrorControlPanel);
}

export function useAutoQAErrorTypes() {
    return useSelector(state => state.gridUI.autoQAErrorTypes);
}

export function useAutoQAMappingByCategory() {
    const autoQAErrorTypes = useAutoQAErrorTypes();

    return autoQAErrorTypes?.reduce((obj, e) => {
        obj[e?.value] = e;
        return obj;
    }, {});
}

export function useAutoQAByCategory(category) {
    const autoQAMappingByCategory = useAutoQAMappingByCategory();
    return autoQAMappingByCategory?.[category];
}

export function useActiveCategories() {
    const autoQAErrorTypes = useAutoQAErrorTypes();

    return autoQAErrorTypes?.filter(e => e?.isActive)?.map(e => e?.value);
}

export function useAutoQASettings() {
    return useSelector(state => state.gridUI.autoQASettings);
}

export function useIsOpenIgnoreSimilarErrorsConfirm() {
    return useSelector(state => state.gridUI.ignoreSimilarErrors.isOpenConfirmModal);
}

export function useIgnoreSimilarErrorsSelectedValidation() {
    return useSelector(state => state.gridUI.ignoreSimilarErrors.selectedValidation);
}
