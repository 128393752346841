import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { object, ref, string } from 'yup';
import { Grid, Typography, InputAdornment, IconButton } from '@material-ui/core';

import InputText from 'components/inputs/InputText';
import SettingContentLayout from '../common/Layout';
import Button from 'components/button/Base';
import { updateUserPassword } from '../actions';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import { SHARED_STYLES, PAGE_PADDING } from 'const/style';
import { useTranslation } from 'react-i18next';
import PasswordValidationPopup from 'components/PasswordValidationPopup';
import Divider from 'components/divider/Base';

const SignupSchema = object({
    currentPassword: string()
        .min(4, `Min is 4 characters`)
        .required('This field is required'),
    newPassword: string().required('This field is required'),
    confirmPassword: string()
        .oneOf([ref('newPassword'), null], 'Passwords must match')
        .required('This field is required')
});

const useStyles = makeStyles(theme => ({
    root: {
        padding: PAGE_PADDING
    },
    form: SHARED_STYLES.settingForm,
    divider: {
        width: PAGE_PADDING * 2,
        margin: `0px 40px`,
        alignContent: `stretch`,
        display: 'flex',
        justifyContent: 'center',
        '& span': {
            width: 1,
            background: theme.colors.border
        }
    },
    verticalDivider: {
        marginTop: 47,
        height: 238
    },
    fileInput: {
        borderRadius: '50%',
        width: 130,
        height: 130
    },
    avatar: {
        width: 60,
        height: 60
    }
}));

function ChangePassword() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            newPassword: ''
        },
        validationSchema: SignupSchema,
        onSubmit: (values, { setSubmitting, setStatus, resetForm }) => {
            const { newPassword, currentPassword } = values;
            setStatus({ error: null });
            dispatch(
                updateUserPassword({
                    newPassword,
                    currentPassword,
                    successCallback: () => {
                        setSubmitting(false);
                        console.log('update password successfully');
                        setStatus({ error: null });
                        resetForm();
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                        console.log('failed to update password');
                        setStatus({ error: `failed to update password` });
                    }
                })
            );
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;
    const [isShowPassword, setIsShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const passwordValue = values.newPassword;
    const hasValidLength = passwordValue.length >= 12 && passwordValue.length <= 64;
    const hasAtLeastOneUpperCase = /^(?=.*[A-Z])/.test(passwordValue);
    const hasAtLeastOneLowerCase = /^(?=.*[a-z])/.test(passwordValue);
    const hasAtLeastOneDigit = /^(?=.*[0-9])/.test(passwordValue);
    const hasAtleastOneSpecialChar = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(passwordValue);
    const isValidPassword =
        hasValidLength &&
        hasAtLeastOneUpperCase &&
        hasAtLeastOneLowerCase &&
        hasAtLeastOneDigit &&
        hasAtleastOneSpecialChar;

    function handleClickShowPassword(fieldName) {
        setIsShowPassword(prev => ({
            ...prev,
            [fieldName]: !prev[fieldName]
        }));
    }

    return (
        <SettingContentLayout name={t('global_password')}>
            <form onSubmit={handleSubmit}>
                <Grid container className={classes.root} wrap="nowrap" spacing={4}>
                    <Grid item className={classes.form} xs>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t(`auth_change_password_current_password`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            disabled={isSubmitting}
                                            name="currentPassword"
                                            value={values.currentPassword || ''}
                                            height={40}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder=""
                                            error={errors.currentPassword && touched.currentPassword}
                                            errorText={errors.currentPassword}
                                            autoFocus={values.currentPassword ? true : false}
                                            type={isShowPassword.currentPassword ? 'text' : 'password'}
                                            data-openreplay-obscured
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => handleClickShowPassword('currentPassword')}
                                                        edge="end"
                                                    >
                                                        {isShowPassword.currentPassword ? (
                                                            <EyeCloseIconSVG />
                                                        ) : (
                                                            <EyeOpenIconSVG />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t(`auth_change_password_new_password`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            autoComplete="new-password"
                                            name="newPassword"
                                            disabled={isSubmitting}
                                            value={values.newPassword || ''}
                                            height={40}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            placeholder=""
                                            error={errors.newPassword && touched.newPassword}
                                            errorText={errors.newPassword}
                                            autoFocus={values.newPassword ? true : false}
                                            type={isShowPassword.newPassword ? 'text' : 'password'}
                                            data-openreplay-obscured
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => handleClickShowPassword('newPassword')}
                                                        edge="end"
                                                    >
                                                        {isShowPassword.newPassword ? (
                                                            <EyeCloseIconSVG />
                                                        ) : (
                                                            <EyeOpenIconSVG />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={3}>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t(`auth_change_password_confirm_password`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            autoComplete="new-password"
                                            name="confirmPassword"
                                            disabled={isSubmitting}
                                            value={values.confirmPassword || ''}
                                            height={40}
                                            onBlur={handleBlur}
                                            error={errors.confirmPassword && touched.confirmPassword}
                                            errorText={errors.confirmPassword}
                                            autoFocus={values.confirmPassword ? true : false}
                                            onChange={handleChange}
                                            placeholder=""
                                            type={isShowPassword.confirmPassword ? 'text' : 'password'}
                                            data-openreplay-obscured
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => handleClickShowPassword('confirmPassword')}
                                                        edge="end"
                                                    >
                                                        {isShowPassword.confirmPassword ? (
                                                            <EyeCloseIconSVG />
                                                        ) : (
                                                            <EyeOpenIconSVG />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting || !isValid || !isValidPassword}
                                    width={120}
                                    variant="contained"
                                >
                                    {t(`global_save`)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Divider orientation="vertical" className={classes.verticalDivider} />
                    </Grid>
                    <Grid item xs>
                        <PasswordValidationPopup
                            hasValidLength={hasValidLength}
                            hasAtLeastOneUpperCase={hasAtLeastOneUpperCase}
                            hasAtLeastOneLowerCase={hasAtLeastOneLowerCase}
                            hasAtLeastOneDigit={hasAtLeastOneDigit}
                            hasAtleastOneSpecialChar={hasAtleastOneSpecialChar}
                            isValidPassword={isValidPassword}
                            passwordValue={passwordValue}
                        />
                    </Grid>
                </Grid>
            </form>
        </SettingContentLayout>
    );
}

export default ChangePassword;
