import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_VIEW_GROUPS](state) {
        return {
            ...state,
            shareView: {
                ...state.shareView,
                isFetching: true
            }
        };
    },

    [types.FETCH_VIEW_GROUPS_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            shareView: {
                ...state.shareView,
                isFetching: false,
                error
            }
        };
    },

    [types.FETCH_VIEW_GROUPS_SUCCESS](state, { payload }) {
        const { viewGroups, workspaceMembers, viewId } = payload;
        const { shareView } = state;

        const newShareView = {
            shareView,
            [viewId]: {
                groups: viewGroups
            },
            isFetching: false,
            workspaceMembers
        };

        return {
            ...state,
            shareView: newShareView
        };
    },

    [types.CHANGE_SELECTED_GROUP_ID](state, { payload }) {
        const { groupId } = payload;
        return {
            ...state,
            shareView: {
                ...state.shareView,
                selectedGroupId: groupId
            }
        };
    },
    [types.CHANGE_SHARE_VIEW_USER_WORKSPACE_ROLE](state, { payload }) {
        const { role, userId } = payload;
        const newMembers = [...(state?.shareView?.workspaceMembers || [])].map(member => {
            if (member.user.id === userId) {
                return {
                    ...member,
                    role
                };
            }
            return member;
        });

        return {
            ...state,
            shareView: {
                ...state.shareView,
                workspaceMembers: newMembers
            }
        };
    },
    [types.GRANT_GROUP_TO_VIEW_SUCCESS](state, action) {
        const { group, viewId } = action.payload;
        const groups = state?.shareView?.[viewId]?.groups || [];
        return {
            ...state,
            shareView: {
                ...state.shareView,
                [viewId]: {
                    groups: [...groups, group]
                }
            }
        };
    },
    [types.GRANT_GROUP_TO_VIEW_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            shareView: {
                ...state.shareView,
                error
            }
        };
    },
    [types.REMOVE_VIEW_OUT_OF_GROUP_SUCCESS](state, action) {
        const { group: ungrantGroup, viewId } = action.payload;
        const groups = state?.shareView?.[viewId]?.groups || [];

        const newGroups = groups.filter(group => group.id !== ungrantGroup.id);
        return {
            ...state,
            shareView: {
                ...state.shareView,
                [viewId]: {
                    groups: newGroups
                }
            }
        };
    },
    [types.REMOVE_VIEW_OUT_OF_GROUP_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            shareView: {
                ...state.shareView,
                error
            }
        };
    },
    [types.GET_SHARE_LINK_VIEW](state, { payload }) {
        const { compositeViewId, viewDetail } = payload;
        const { shareLinkDetail } = state;
        const newShareLinkDetail = produce(shareLinkDetail, draft => {
            draft[compositeViewId] = viewDetail;
        });
        return {
            ...state,
            shareLinkDetail: newShareLinkDetail
        };
    },
    [types.DELETE_SHARE_LINK_VIEW](state, { payload }) {
        const { compositeViewId, viewDetail } = payload;
        const { shareLinkDetail } = state;

        const newShareLinkDetail = produce(shareLinkDetail, draft => {
            draft[compositeViewId] = viewDetail;
        });

        return {
            ...state,
            shareLinkDetail: newShareLinkDetail
        };
    }
};

export default handler;
