import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CompanyGroupTutorialSVG from 'assets/images/svg/CompanyGroupTutorialSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: 224,
        padding: theme.spacing(3, 2)
    },
    whiteText: {
        color: theme.colors.white
    },
    image: {
        borderRadius: 4
    },
    grayText: {
        color: theme.colors.lightGreyBlue,
        fontSize: 13
    }
}));

function LanguagePairTooltip({ title = 'Company Group' }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item container>
                <CompanyGroupTutorialSVG />
            </Grid>
            <Grid item>
                <Typography align="left" className={classes.whiteText} style={{ marginTop: 8 }} variant="body1">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="left" variant="body2" className={classes.grayText}>
                    Add members to a group so they can see views that shared to groups.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default React.memo(LanguagePairTooltip);
