import React, { useCallback } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
// import WraptextSVG from 'assets/images/svg/WraptextSVG';
import * as roleConst from 'auth/roleConst';
import ListItem from 'components/list/Item';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { sendManualTrack } from 'tracker';
import ApproveDependencySVG from 'assets/images/svg/dependencies/ApproveSVG';
import {
    DEPENDENCY_STATUS,
    MAX_SELECTION_RECORDS,
    TM_STATUS,
    DATA_QUERY_OPTIONS,
    SOURCE_STATUS,
    RANGE_TYPES
} from 'const/gridUI';
import hexToRgba from 'hex-to-rgba';
import {
    useIsTmDisabled,
    useAllPendingColumnIds,
    useTotalRecords,
    useDependencyList,
    useDisabledSourceColumns
} from 'hooks/gridUI';
import store from 'store/configStore';
import { isShowApproveTmStatusByRange } from 'utils/gridUI/tm';
import {
    isShowMarkAsOutOfDateByRange,
    isShowMarkAsUnsetByRange,
    isShowMarkAsUpToDateByRange,
    getSourceStatusIcon,
    getSourceStatusLabelByStatus
} from 'utils/gridUI/dependency';
import { enqueueSnackbar } from 'notifier/actions';
import Spinner from 'components/spinner/Base';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import PopperMenu from 'components/menus/Popper';
import { useRole } from 'hooks/auth/role';
import NestedMenuItem from 'components/menus/HoverMenu';
import { isTempId } from 'utils/uuid';
import SourceStatusSVG from 'assets/images/svg/dependencies/SourceStatusSVG';
import LinkIconSVG from 'assets/images/svg/LinkSVG';
import { copyToClipboard } from 'utils/clipboard';
import PlayFileActions from './cellOverlay/PlayFileActions';
import PlayAutomationMenu from './cellOverlay/PlayAutomationMenu';
import FindInThisRange from './cellOverlay/FindInThisRange';
import { COLOR_TYPES } from 'const';

function CellMultipleRowsPopup({
    anchorEl,
    handleClickAway,
    closePopup,
    accessManageGridRecord,
    accessEditTranslation,
    accessEditRecords,
    totalSelectedRows,
    t,
    onRePosContext
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const [rangeData, setRangeData] = React.useState({});
    const [recordIds, setRecordIds] = React.useState([]);
    const [columnIds, setColumnIds] = React.useState([]);
    const pendingColumnIds = useAllPendingColumnIds();
    const isTmDisabled = useIsTmDisabled();
    const totalRecords = useTotalRecords();
    const isSelectingAll = totalSelectedRows === totalRecords;
    const [isLoading, setIsLoading] = React.useState(!isSelectingAll);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const roles = useRole();
    const dependencies = useDependencyList();
    const disabledSourceColumns = useDisabledSourceColumns();

    const isCreator = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_ROLE] === roleConst.CREATOR;
    }, [roles]);

    React.useEffect(() => {
        const { getState } = store;
        const { gridUI, auth } = getState();
        const fetchData = async () => {
            try {
                if (isCreator && isSelectingAll) {
                    return;
                }
                setIsLoading(true);
                const {
                    data,
                    recordIds: rangeRecordIds,
                    columnIds: rangeColumnIds,
                    isOverRecordLimit,
                    totalSelectedRecords
                } = await gridUIActions.getRangeData({
                    gridUI,
                    auth,
                    dataOptions: [
                        DATA_QUERY_OPTIONS.COLOR,
                        DATA_QUERY_OPTIONS.TM,
                        DATA_QUERY_OPTIONS.DEPENDENCY_STATUS,
                        DATA_QUERY_OPTIONS.DATA,
                        DATA_QUERY_OPTIONS.SOURCE_STATUS
                    ],
                    type: RANGE_TYPES.INDEX
                });
                setIsLoading(false);
                if (isOverRecordLimit) {
                    dispatch(
                        enqueueSnackbar({
                            type: 'info',
                            message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                        })
                    );
                    handleClickAway();
                    return;
                }

                setRangeData(data);
                setRecordIds(rangeRecordIds);
                setColumnIds(rangeColumnIds);
                onRePosContext && onRePosContext();
            } catch (err) {
                setIsLoading(false);
                handleClickAway();
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const isShowApproveTMStatus = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        if (isTmDisabled) return false;
        return isShowApproveTmStatusByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, isTmDisabled, recordIds, pendingColumnIds]);

    const isShowMarkAsOutOfDate = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsOutOfDateByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowMarkAsUnset = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsUnsetByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowMarkAsUpToDate = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsUpToDateByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const deleteRecordHandler = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            sendManualTrack({
                type: `Delete Records`
            });
            closePopup();
            dispatch(
                gridUIActions.deleteViewRecordsByIndex({
                    successCallback: () => {
                        console.log('Record Deleted');
                    },
                    errorCallback: () => {
                        console.log('Failed to delete record');
                    }
                })
            );
        },
        [dispatch, closePopup]
    );

    const handleApproveStatus = () => {
        sendManualTrack({ type: `Approve TM Status` });
        dispatch(gridUIActions.approveTMStatus(TM_STATUS.APPROVED));
        closePopup();
    };

    const handleMarkDependencyUpToDate = () => {
        sendManualTrack({ type: `Mark as Up-to-date'` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UP_TO_DATE));
        closePopup();
    };

    const handleMarkDependencyOutOfDate = () => {
        sendManualTrack({ type: `Mark as Out-of-date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.OUT_OF_DATE));
        closePopup();
    };

    const handleMarkDependencyNotTranslated = () => {
        sendManualTrack({ type: `Mark as Unset` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UNSET));
        closePopup();
    };

    const handleMarkSourceStatus = React.useCallback(
        sourceStatus => {
            sendManualTrack({
                type: `Mark Source Status As ${getSourceStatusLabelByStatus({ status: sourceStatus })}`
            });
            dispatch(gridUIActions.approveSourceStatus(sourceStatus));
            closePopup();
        },
        [dispatch, closePopup]
    );

    const showDivider = React.useMemo(() => {
        return (
            (accessEditRecords === roleConst.FULL && isShowMarkAsUnset) ||
            (accessEditRecords === roleConst.FULL && isShowMarkAsOutOfDate) ||
            (accessEditRecords === roleConst.FULL && isShowMarkAsUpToDate) ||
            (accessEditRecords === roleConst.FULL && isShowApproveTMStatus)
        );
    }, [accessEditRecords, isShowMarkAsUnset, isShowMarkAsOutOfDate, isShowMarkAsUpToDate, isShowApproveTMStatus]);

    const handleOpenDeleteConfirm = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setOpenConfirmDelete(true);
            closePopup();
        },
        [closePopup]
    );

    const handleCloseDeleteConfirm = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (isDeleting) return;
            setOpenConfirmDelete(false);
        },
        [isDeleting]
    );

    const handleAgreeDelete = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (isDeleting) return;
            setIsDeleting(true);
            dispatch(
                gridUIActions.deleteAllRecords({
                    errorCallback: error => {
                        setIsDeleting(false);
                    },
                    successCallback: () => {
                        setIsDeleting(false);
                        setOpenConfirmDelete(false);
                    }
                })
            );
        },
        [dispatch, isDeleting]
    );

    const isShowSourceStatus = React.useMemo(() => {
        const dependenciesWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        const filteredColumnIds = affectedColumnIds?.filter(colId => {
            const found = dependenciesWithoutFake?.find(dpDc => dpDc?.parent === colId);
            return found && found?.child && !disabledSourceColumns?.includes(colId);
        });
        return filteredColumnIds?.length > 0;
    }, [columnIds, pendingColumnIds, dependencies, disabledSourceColumns]);

    const getLink = React.useCallback(
        e => {
            e.stopPropagation();
            const currentUrl = new URL(window.location);
            const href = currentUrl?.href;
            copyToClipboard({ copyValue: href, internalValue: href });
            dispatch(enqueueSnackbar({ message: t('copied_range_link') }));
            closePopup && closePopup();
        },
        [closePopup, dispatch, t]
    );

    return (
        <>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'right-start'} id={`rows_popup_context`}>
                    <Grid ref={rootRef} id={`grid_popup`} container direction="column">
                        {isLoading && (
                            <Grid item>
                                <ListItem
                                    icon={<Spinner size={18} thick={3} style={{ marginRight: theme.spacing(2) }} />}
                                    name={'loading...'}
                                />
                            </Grid>
                        )}

                        {!isLoading && (
                            <>
                                <Grid item onClick={getLink}>
                                    <ListItem
                                        icon={<LinkIconSVG width={16} height={16} />}
                                        name={t(`get_link_to_this_range`)}
                                    />
                                </Grid>
                                <FindInThisRange onClose={closePopup} />
                                <PlayFileActions columnIds={columnIds} closePopup={closePopup} />
                                <PlayAutomationMenu closePopup={closePopup} />

                                {accessEditRecords === roleConst.FULL && isShowApproveTMStatus && (
                                    <Grid item onClick={handleApproveStatus}>
                                        <ListItem
                                            icon={<TMStatusAprrovedSVG />}
                                            name={t(`column_options_approve_tm`)}
                                        />
                                    </Grid>
                                )}
                                {accessEditRecords === roleConst.FULL && isShowMarkAsUpToDate && (
                                    <Grid item onClick={handleMarkDependencyUpToDate}>
                                        <ListItem
                                            icon={<ApproveDependencySVG />}
                                            name={t(`dependency_mark_as_uptodate`)}
                                        />
                                    </Grid>
                                )}
                                {accessEditRecords === roleConst.FULL && isShowMarkAsOutOfDate && (
                                    <Grid item onClick={handleMarkDependencyOutOfDate}>
                                        <ListItem
                                            icon={<ApproveDependencySVG color={hexToRgba(theme.colors.sun, 0.8)} />}
                                            name={t(`dependency_mark_as_outofdate`)}
                                        />
                                    </Grid>
                                )}
                                {accessEditRecords === roleConst.FULL && isShowMarkAsUnset && (
                                    <Grid item onClick={handleMarkDependencyNotTranslated}>
                                        <ListItem
                                            icon={
                                                <ApproveDependencySVG color={hexToRgba(theme.colors.brightRed, 0.8)} />
                                            }
                                            name={t(`dependency_mark_as_unset`)}
                                        />
                                    </Grid>
                                )}

                                {accessManageGridRecord === roleConst.FULL && isShowSourceStatus && (
                                    <NestedMenuItem
                                        icon={<SourceStatusSVG color={theme.colors.steel} />}
                                        label={t('set_source_status')}
                                        parentMenuOpen={true}
                                        anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom'
                                        }}
                                    >
                                        <Grid container direction="column">
                                            <Grid
                                                item
                                                onClick={() => handleMarkSourceStatus(SOURCE_STATUS.DO_NOT_TRANSLATE)}
                                            >
                                                <ListItem
                                                    icon={getSourceStatusIcon({
                                                        status: SOURCE_STATUS.DO_NOT_TRANSLATE,
                                                        size: 18
                                                    })}
                                                    name={t('do_not_translate')}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                onClick={() =>
                                                    handleMarkSourceStatus(SOURCE_STATUS.NOT_READY_FOR_TRANSLATION)
                                                }
                                            >
                                                <ListItem
                                                    icon={getSourceStatusIcon({
                                                        status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                                                        size: 18
                                                    })}
                                                    name={t('not_ready_for_translation')}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                onClick={() =>
                                                    handleMarkSourceStatus(SOURCE_STATUS.READY_FOR_TRANSLATION)
                                                }
                                            >
                                                <ListItem
                                                    icon={getSourceStatusIcon({
                                                        status: SOURCE_STATUS.READY_FOR_TRANSLATION,
                                                        size: 18
                                                    })}
                                                    name={t('ready_for_translation')}
                                                />
                                            </Grid>
                                            <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.LOCKED)}>
                                                <ListItem
                                                    icon={getSourceStatusIcon({
                                                        status: SOURCE_STATUS.LOCKED,
                                                        size: 18
                                                    })}
                                                    name={t('locked')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </NestedMenuItem>
                                )}

                                {accessManageGridRecord === roleConst.FULL && (
                                    <>
                                        {showDivider && (
                                            <Grid item className={'pb-2'}>
                                                <Divider />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <ListItem
                                                onClick={
                                                    isSelectingAll && isCreator
                                                        ? handleOpenDeleteConfirm
                                                        : deleteRecordHandler
                                                }
                                                icon={<DeleteSVG />}
                                                name={t('record_options_delete', { count: totalSelectedRows })}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </PopperMenu>
            )}

            <Dialog open={openConfirmDelete} onClose={handleCloseDeleteConfirm}>
                <ConfirmBox
                    title={'Delete all selected records'}
                    body={
                        <>
                            <div>
                                <p className="body2">Do you really want to delete all selected records?</p>
                            </div>
                            <div>
                                <p className="body2">This process can not be undone.</p>
                            </div>
                        </>
                    }
                    handleCancel={handleCloseDeleteConfirm}
                    onClose={handleCloseDeleteConfirm}
                    handleAgreed={handleAgreeDelete}
                    agreeLabel="Delete All"
                    isLoading={isDeleting}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </>
    );
}

export default React.memo(CellMultipleRowsPopup);
