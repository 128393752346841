import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { Typography, Paper, Chip, Grid } from '@material-ui/core';
import CloseIcon from 'assets/images/svg/CloseIconSVG';
import hexToRgba from 'hex-to-rgba';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { INPUT_PADDING_LEFT, INPUT_RADIUS, INPUT_HEIGHT } from 'const/style';

import { MAX_OPTIONS } from 'const/gridUI';

import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import Option from './common/Option';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';
import { AutoSizer } from 'react-virtualized-dn';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    dropdown: {
        position: 'relative',
        minWidth: 100
    },
    control: {
        width: '100%',
        borderRadius: INPUT_RADIUS,
        border: props =>
            props.isOpen
                ? props.isHighlight
                    ? `1px solid ${theme.palette.primary.main}`
                    : `1px solid ${theme.colors.border}`
                : `1px solid ${theme.colors.border}`,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText
    },
    disabled: {
        background: theme.colors.paleGrey,
        pointerEvents: 'none'
    },
    singleValueStyle: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        '& > svg, & img': {
            minWidth: 16
        },
        '& p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    multiValueDelete: {
        display: 'flex',
        alignItems: 'center'
    },
    closeIcon: {
        color: theme.colors.secondaryText
    },
    menuDropdown: {
        position: 'absolute',
        width: '100%',
        zIndex: 1000
    },
    iconCenter: {
        display: 'flex'
    },
    expand: {
        flex: 1
    },
    chip: {
        border: `1px solid ${hexToRgba(theme.colors.highlight, 0.3)}`,
        backgroundColor: hexToRgba(theme.colors.highlight, 0.1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: 28,
        '& .MuiAvatar-root': {
            marginLeft: 2,
            color: theme.colors.white
        },
        '& img': {
            marginLeft: 10
        }
    },
    multipleValues: {
        paddingTop: theme.spacing(1),
        paddingBottom: 2
    }
}));

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

const MenuDropDown = ({ className, children }) => {
    const classes = useStyles();
    return <Paper className={`${classes.menuDropdown} ${className}`}>{children}</Paper>;
};

const Dropdown = React.forwardRef(({ children, dropdownClassName, isOpen, target, menuClassName, ...rest }, ref) => {
    const classes = useStyles();
    return (
        <Grid ref={ref} className={`${classes.dropdown} ${dropdownClassName}`} {...rest}>
            {target}
            {isOpen && <MenuDropDown className={menuClassName}>{children}</MenuDropDown>}
        </Grid>
    );
});

function LDSelectBase({
    ddPlaceholder = i18n.t('global_label_select_an_option'),
    menuPlaceholder = i18n.t('global_find_a_type'),
    isMulti = false,
    defaultValue,
    onChange,
    selectedColor,
    options = [],
    maxSize = MAX_OPTIONS,
    isHighlight = true,
    isCanClear = false,
    dropdownClassName,
    isDisabled,
    menuClassName,
    controlClassName,
    ...other
}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    const classes = useStyles({ selectedColor, isOpen, isHighlight });
    const theme = useTheme();
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        })
    };

    const handleOnChange = option => {
        onChange(option);
        setIsOpen(false);
    };

    const handleDelete = index => {
        const cpArr = [...defaultValue];
        cpArr.splice(index, 1);
        onChange(cpArr);
    };

    const Target = () => {
        if (!defaultValue)
            return (
                <Grid
                    container
                    className={`${classes.control} ${isDisabled ? classes.disabled : ''} ${controlClassName}`}
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    onClick={toggleOpen}
                >
                    <Grid item style={{ flex: 1 }}>
                        <Typography variant="caption">{ddPlaceholder}</Typography>
                    </Grid>
                    {!isDisabled && (
                        <Grid item style={{ display: 'flex' }}>
                            <ArrowDownSVG />
                        </Grid>
                    )}
                </Grid>
            );
        if (defaultValue && isMulti)
            return (
                <Grid
                    container
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.control} ${classes.multipleValues} ${isDisabled ? classes.disabled : ''}`}
                    onClick={toggleOpen}
                >
                    <Grid item style={{ flex: 1 }}>
                        {isEmpty(defaultValue) && <Typography variant="caption">{ddPlaceholder}</Typography>}
                        {!isEmpty(defaultValue) &&
                            defaultValue.map((item, index) => {
                                const itemColor = item?.color;

                                return (
                                    <Chip
                                        avatar={item?.icon && item?.icon()}
                                        size="small"
                                        key={index}
                                        label={item.label}
                                        onDelete={() => handleDelete(index)}
                                        className={classes.chip}
                                        style={{
                                            background: itemColor ? hexToRgba(itemColor, 0.1) : ``,
                                            border: itemColor ? `1px solid ${hexToRgba(itemColor, 0.3)}` : ``
                                        }}
                                        deleteIcon={<CloseIcon size={12} />}
                                    />
                                );
                            })}
                    </Grid>
                    <Grid item className={classes.multiValueDelete}>
                        <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                            {isCanClear && !isEmpty(defaultValue) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleOnChange([]);
                                        }}
                                    />
                                </Grid>
                            )}
                            {!isDisabled && (
                                <Grid item style={{ display: 'flex' }}>
                                    <ArrowDownSVG />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            );
        if (defaultValue && !isMulti)
            return (
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.control} ${isDisabled ? classes.disabled : ''}`}
                    onClick={toggleOpen}
                    wrap="nowrap"
                >
                    <Grid
                        item
                        style={{ width: `calc(100% - ${isCanClear ? 40 : 16}px)` }}
                        className={classes.singleValueStyle}
                    >
                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                            {defaultValue.icon && (
                                <Grid item className={classes.iconCenter}>
                                    <defaultValue.icon></defaultValue.icon>
                                </Grid>
                            )}
                            <Grid
                                item
                                style={{
                                    width: '100%',
                                    height: 28
                                }}
                            >
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <Typography
                                            style={{
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                width,
                                                height
                                            }}
                                            variant="body2"
                                            component="div"
                                        >
                                            {defaultValue.label}
                                        </Typography>
                                    )}
                                </AutoSizer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1} wrap="nowrap">
                            {isCanClear && !isEmpty(defaultValue) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleOnChange(null);
                                        }}
                                    />
                                </Grid>
                            )}
                            {!isDisabled && (
                                <Grid item style={{ display: 'flex' }}>
                                    <ArrowDownSVG />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            );
    };

    const filterOptions = inputValue => {
        let newOptions = [...options];
        const filterValues = newOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
        return filterValues.slice(0, maxSize);
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(filterOptions(inputValue));
        }, 300);
    };

    return (
        <Dropdown
            dropdownClassName={dropdownClassName}
            menuClassName={menuClassName}
            isOpen={isOpen}
            onClose={toggleOpen}
            target={<Target />}
            onBlur={toggleOpen}
        >
            <AsyncSelect
                cacheOptions
                autoFocus
                defaultOptions={options.slice(0, maxSize)}
                loadOptions={loadOptions}
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                placeholder={menuPlaceholder}
                isClearable={false}
                tabSelectsValue={false}
                components={components}
                defaultValue={defaultValue}
                styles={selectStyles}
                onChange={handleOnChange}
                classes={classes}
                menuIsOpen
                isMulti={isMulti}
                {...other}
            />
        </Dropdown>
    );
}

LDSelectBase.propTypes = {
    ddPlaceholder: PropTypes.string,
    menuPlaceholder: PropTypes.string,
    isMulti: PropTypes.bool,
    defaultValue: PropTypes.any
};

export default React.memo(LDSelectBase);
