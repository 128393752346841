import React, { useEffect, useMemo, useState, useCallback, memo, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, CircularProgress, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Spinner from 'components/spinner/Base';
import { DISABLED_OPACITY } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';
import ButtonBase from 'components/button/Base';
import MergedSVG from 'assets/images/svg/versionControl/MergedSVG';
import AddRecordSVG from 'assets/images/svg/versionControl/AddRecordSVG';
import UpdateRecordSVG from 'assets/images/svg/versionControl/UpdateRecordSVG';
import OverrideRecordSVG from 'assets/images/svg/versionControl/OverrideRecordSVG';
import DeleteRecordSVG from 'assets/images/svg/versionControl/DeleteRecordSVG';
import AddColumnSVG from 'assets/images/svg/versionControl/AddColumnSVG';
import UpdateColumnSVG from 'assets/images/svg/versionControl/UpdateColumnSVG';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import Checkbox from 'components/checkbox/Base';
import { getGridColumnsApi } from 'services/grid';
import { formatDataGridPreview, formatMetaDataGridPreview } from 'utils/gridUI/formatData';
import {
    getPreviewMergeApi,
    getViewColumnsApi,
    getViewsApi,
    createPreviewMergeApi,
    getPreviewMergeAggregationApi,
    getConvertedDataReferenceColumnsApi
} from 'services/view';
import produce from 'immer';
import NoDiffCheck from 'assets/images/svg/versionControl/NoDiffCheck';
import * as roleConst from 'auth/roleConst';
import {
    ROW_HEIGHT,
    CONFLICT_TYPES,
    RECORDS_RENDER_GRID_PREVIEW,
    PREVIEW_STATUS,
    VIEW_TYPES,
    GRID_STATUS,
    FAKE_ROW,
    ROW_HEIGHT_OPTIONS
} from 'const/gridUI';
import { useContextMenuId } from 'hooks/app';
import { AutoSizer } from 'react-virtualized-dn';
import GridTable from './MergingGrid';
import * as branchActions from 'versionControl/actions';
import { sendManualTrack } from 'tracker';
import Tooltip from 'components/tooltip/Base';
import * as columnTypes from 'const/columnTypes';
import { useViewList, useCurrentView } from 'hooks/gridUI/view';
import ViewIcon from 'gridUI/views/ViewIcon';
import LDBasePortal from 'components/selects/LDBasePortal';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { useMergingTask, usePathTagTree } from 'hooks/gridUI';
// import * as gridUIActions from 'gridUI/actions';
import ShowAllConflictsSVG from 'assets/images/svg/ShowAllConflictsSVG';
import { isLDEmpty } from 'utils/object';
import Dialog from 'components/dialog/Dialog';
import CustomRowHeight from 'gridUI/CustomRowHeight';
import RowHeightSVG from 'assets/images/svg/RowHeightSVG';
import ShortSVG from 'assets/images/svg/ShortSVG';
import CustomSVG from 'assets/images/svg/CustomSVG';
import TallSVG from 'assets/images/svg/TallSVG';
import MediumSVG from 'assets/images/svg/MediumSVG';
import ListItem from 'components/list/Item';
import PopperMenu from 'components/menus/Popper';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import BranchTag from 'versionControl/sharedComponents/BranchTag';
import InfoIconSVG from 'assets/images/svg/InfoIconSVG';
import DoneSVG from 'assets/images/svg/DoneSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import classNames from 'classnames';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import GridIcon from 'grids/GridIcon';
import ws from 'socket';
import { useCompanyId } from 'hooks/auth';
import { mapResponse } from 'utils/socket';
import * as types from 'socket/types';
import ProgressLinear from 'components/notify/Import';
import ErrorSVG from 'assets/images/svg/ErrorSVG';

const useStyles = makeStyles(theme => ({
    content: {
        width: `calc(100vw - 240px)`,
        height: `calc(100vh - 120px)`,
        background: theme.colors.white,
        borderRadius: 4,
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            width: `calc(100vw - 120px)`
        }
    },
    expand: {
        flex: 1
    },
    header: {
        borderBottom: `1px solid ${theme.colors.border}`
    },
    spinner: {
        width: '100%',
        height: '100%'
    },
    flex: {
        display: 'flex'
    },
    branch: {
        background: theme.colors.paleNavy,
        borderRadius: 4,
        padding: 2
    },
    branchName: {
        color: theme.colors.lightLavender,
        fontWeight: 500
    },
    diffCheckTable: {
        height: 430,
        border: `1px solid ${theme.colors.border}`
    },
    option: {
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    active: {
        background: theme.colors.ghostwhite,
        borderRadius: 6
    },
    optionIcon: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(1)
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    submitButton: {
        position: 'relative'
    },
    table: {
        height: `100%`,
        width: '100%',
        overflow: 'hidden',
        userSelect: 'none'
    },
    showAllConflict: {
        width: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    }
}));

const RECORD_OPTIONS = [
    { value: 'add', label: 'Add', description: 'Add new records at bottom', icon: <AddRecordSVG /> },
    { value: 'update', label: 'Update', description: 'Update existing records', icon: <UpdateRecordSVG /> },
    { value: 'delete', label: 'Delete', description: 'Delete existing records', icon: <DeleteRecordSVG /> },
    { value: 'override', label: 'Overwrite', description: 'Overwrite all conflict data', icon: <OverrideRecordSVG /> }
];

const COLUMN_OPTIONS = [
    { value: 'add', label: 'Add', description: 'Add new column', icon: <AddColumnSVG /> },
    { value: 'update', label: 'Update', description: 'Update existing column', icon: <UpdateColumnSVG /> }
];

function _generateOption(view) {
    return {
        label: view?.name,
        value: view?.id,
        icon: () => <ViewIcon view={view} size="small" />
    };
}

function MergingDiffCheck({ branch, mergeToBranch, dbId, onClose, gridId, grid }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [recordOptions, setRecordOptions] = useState(['add', 'update', 'delete', 'override']);
    const [columnOptions, setColumnOptions] = useState(['add', 'update']);
    const [isMerging, setIsMerging] = useState(false);
    const sourceViews = useViewList();
    const currentSourceView = useCurrentView();
    const [isShowExpand, setIsShowExpand] = useState(false);
    const companyId = useCompanyId();

    const subscribeTopicsRef = useRef([]);

    const [sourceView, setSourceView] = useState(_generateOption(currentSourceView));
    const [destinationView, setDestinationView] = useState(null);

    const [processSourceView, setProcessSourceView] = useState(_generateOption(currentSourceView));
    const [processDestinationView, setProcessDestinationView] = useState(null);

    const [destinationViews, setDestinationViews] = useState([]);
    const [mainTaskId, setMainTaskId] = useState(null);
    const [childTaskId, setChildTaskId] = useState(null);
    const childRef = useRef({});

    const [selectedRefColumn, setSelectedRefColumn] = useState(null);
    const selectedRefColumnRef = useRef(null);

    const [refColumns, setRefColumns] = useState(null);
    const [mergingTasks, setMergingTaskRef] = useState({
        // [columnId]: true,
    });

    const [isFetching, setIsFetching] = useState(false);
    const [showAllConflicts, setShowAllConflicts] = useState(false);
    const tree = usePathTagTree();
    const [rowHeight, setRowHeight] = useState(ROW_HEIGHT * 2);
    const [defaultRowHeightAnchorEl, setDefaultRowHeightAnchorEl] = useState(null);
    const [openCustomRowHeight, setOpenCustomRowHeight] = useState(false);
    const [isVerifying, setIsVerifying] = useState({});
    const [isProcessMergingChild, setIsProcessMergingChild] = useState(false);
    const [isError, setIsError] = useState(false);

    const currentTaskId = useMemo(() => {
        return childTaskId || mainTaskId;
    }, [childTaskId, mainTaskId]);

    const currentTask = useMergingTask(currentTaskId);

    const currentTaskStatus = useMemo(() => {
        return currentTask?.status;
    }, [currentTask]);

    const isTaskSuccess = useMemo(() => {
        return currentTaskStatus === GRID_STATUS.SUCCESS;
    }, [currentTaskStatus]);

    const [aggregations, setAggregations] = useState({});

    const [quickFilters, setQuickFilters] = useState({});

    const handleRefreshGrid = useCallback(() => {}, []);

    const generateOptions = useCallback(views => {
        return views?.map(view => {
            return _generateOption(view);
        });
    }, []);

    const branchId = useMemo(() => {
        return branch?.id;
    }, [branch]);

    const toBranchId = useMemo(() => {
        return mergeToBranch?.id;
    }, [mergeToBranch]);

    const currentBranchId = useMemo(() => {
        return selectedRefColumn?.sourceBranch?.id || branchId;
    }, [branchId, selectedRefColumn]);

    const currentToBranchId = useMemo(() => {
        return selectedRefColumn?.destinationBranch?.id || toBranchId;
    }, [toBranchId, selectedRefColumn]);

    //APP
    const contextMenuId = useContextMenuId();

    const [previewData, setPreviewData] = useState({
        isFetchingMore: false,
        parentColumnIds: [],
        childColumnIds: [],
        rows: [],
        data: {},
        metaData: {},
        totalRecords: 0,
        ROW_START_INDEX: 0,
        ROW_STOP_INDEX: RECORDS_RENDER_GRID_PREVIEW,
        tree: []
    });

    const [resolveConflictData, setResolveConflictData] = useState({});

    useEffect(() => {
        let gridTable = document.getElementById(`gridUI-table`);
        if (gridTable) {
            gridTable.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            });
        }
    }, []);

    const columnIds = useMemo(() => {
        if (columnOptions?.includes('add')) return [columnTypes.RECORD_ID, ...previewData?.childColumnIds];
        const diffColumnIds = previewData?.childColumnIds?.filter(columnId =>
            previewData?.parentColumnIds?.includes(columnId)
        );

        return [columnTypes.RECORD_ID, ...diffColumnIds];
    }, [columnOptions, previewData]);

    useEffect(() => {
        const initializeFetch = async () => {
            try {
                const parentViews = await getViewsApi({
                    gridId: toBranchId,
                    dbId,
                    paramOptions: {
                        includeDefaultView: true,
                        expand: `${VIEW_TYPES.ACCESS_VIEW},${VIEW_TYPES.USER_VIEW}`
                    }
                });
                const destinationView = _generateOption(
                    parentViews?.find(view => view?.type === VIEW_TYPES.DEFAULT_VIEW)
                );
                setDestinationViews(parentViews);
                setDestinationView(destinationView);
                setProcessDestinationView(destinationView);
            } catch (error) {
                console.log('error', error);
            }
        };

        initializeFetch();
    }, [dbId, toBranchId]);

    useEffect(() => {
        const createPreviewMerge = async () => {
            try {
                setIsFetching(true);
                const task = await createPreviewMergeApi({
                    dbId,
                    sourceViewId: sourceView?.value,
                    body: {
                        destinationViewId: destinationView?.value
                    }
                });
                setMainTaskId(task?.id);
                setChildTaskId(task?.id);
                // dispatch(gridUIActions.registerMergingTask(task));
            } catch ({ message }) {
                console.log('error', message);
                setIsFetching(false);
            }
        };

        if (!destinationView || !sourceView) return;
        createPreviewMerge();
    }, [dispatch, destinationView, dbId, sourceView]);

    console.log('isTaskSuccess', isTaskSuccess);
    console.log('isTaskSuccess', isTaskSuccess);

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);

            try {
                let sourceGridColumns = [];
                let sourceViewColumns = [];
                let destinationGridColumns = [];
                let destinationViewColumns = [];
                let convertedDateRefColumns = [];
                const currentTaskId = childRef?.current?.taskId || mainTaskId;
                const processSourceViewId = childRef.current?.sourceViewId || processSourceView?.value;
                const processDestinationViewId = childRef.current?.destinationViewId || processDestinationView?.value;

                const isParent = currentTaskId === mainTaskId;

                if (isParent) {
                    [
                        sourceGridColumns,
                        sourceViewColumns,
                        destinationGridColumns,
                        destinationViewColumns,
                        convertedDateRefColumns
                    ] = await Promise.all([
                        getGridColumnsApi({ gridId: currentBranchId, dbId }),
                        getViewColumnsApi({ defaultAccessViewId: processSourceViewId, dbId }),
                        getGridColumnsApi({ gridId: currentToBranchId, dbId }),
                        getViewColumnsApi({ defaultAccessViewId: processDestinationViewId, dbId }),
                        getConvertedDataReferenceColumnsApi({
                            sourceViewId: processSourceViewId,
                            dbId,
                            taskId: currentTaskId
                        })
                    ]);

                    const convertedDateRefColumnsKey = convertedDateRefColumns?.reduce((obj, k) => {
                        obj = {
                            ...obj,
                            [`${k.destinationBranch}.${k.sourceBranch}`]: k
                        };
                        return obj;
                    }, {});

                    setRefColumns(Object.values(convertedDateRefColumnsKey));
                } else {
                    [
                        sourceGridColumns,
                        sourceViewColumns,
                        destinationGridColumns,
                        destinationViewColumns
                    ] = await Promise.all([
                        getGridColumnsApi({ gridId: currentBranchId, dbId }),
                        getViewColumnsApi({ defaultAccessViewId: processSourceViewId, dbId }),
                        getGridColumnsApi({ gridId: currentToBranchId, dbId }),
                        getViewColumnsApi({ defaultAccessViewId: processDestinationViewId, dbId })
                    ]);
                }

                const sourceViewColumnsViewable = sourceViewColumns
                    ?.filter(vCol => vCol?.viewable)
                    ?.map(vCol => vCol?.id);
                const destinationViewColumnsViewable = destinationViewColumns
                    ?.filter(vCol => vCol?.viewable)
                    ?.map(vCol => vCol?.id);

                const containedPathTag = sourceViewColumnsViewable?.includes(columnTypes.PATH_TAG);

                const sourceColumns = sourceGridColumns?.filter(col => sourceViewColumnsViewable?.includes(col?.id));
                const destinationColumns = destinationGridColumns?.filter(col =>
                    destinationViewColumnsViewable?.includes(col?.id)
                );

                const sourceColumnIds = containedPathTag
                    ? [columnTypes.PATH_TAG, ...sourceColumns?.map(c => c?.id)]
                    : sourceColumns?.map(c => c?.id);

                const [{ totalRecords, records }, columnAggregations] = await Promise.all([
                    await getPreviewMergeApi({
                        taskId: currentTaskId,
                        sourceViewId: processSourceViewId,
                        dbId,
                        offset: 0,
                        limit: RECORDS_RENDER_GRID_PREVIEW,
                        columnIds: sourceColumnIds,
                        mergeColumnOptions: [],
                        mergeRecordOptions: recordOptions,
                        query: formatQuickFilters(quickFilters)
                    }),
                    await getPreviewMergeAggregationApi({
                        taskId: currentTaskId,
                        sourceViewId: processSourceViewId,
                        dbId,
                        columnIds: sourceColumnIds,
                        mergeColumnOptions: [],
                        mergeRecordOptions: recordOptions,
                        query: formatQuickFilters(quickFilters)
                    })
                ]);

                setAggregations(
                    columnAggregations?.reduce((obj, i) => {
                        obj[i?.columnId] = i;
                        return obj;
                    }, {})
                );

                const metaData = formatMetaDataGridPreview({
                    childColumns: sourceColumns,
                    parentColumns: destinationColumns
                });
                const { rows, data } = formatDataGridPreview(records);
                const newPreviewData = produce(previewData, draft => {
                    draft.rows = rows;
                    draft.data = data;
                    draft.metaData = {
                        ...metaData,
                        [columnTypes.RECORD_ID]: {
                            name: 'Record ID',
                            type: columnTypes.RECORD_ID,
                            id: columnTypes.RECORD_ID
                        },
                        [columnTypes.PATH_TAG]: {
                            name: 'Path',
                            type: columnTypes.PATH_TAG,
                            id: columnTypes.PATH_TAG
                        }
                    };
                    draft.childColumnIds = sourceColumnIds;
                    draft.totalRecords = totalRecords;
                    draft.parentColumnIds = destinationColumns?.map(column => column?.id);
                    draft.originalTotalRecords = totalRecords;
                });

                if (!isParent && selectedRefColumn) {
                    setMergingTaskRef(prev => ({
                        ...prev,
                        [selectedRefColumn.id]: rows?.length === 0 && !totalRecords ? true : false
                    }));
                }
                setIsError(false);
                setPreviewData(newPreviewData);
                setIsFetching(false);
                childRef.current = {};
            } catch ({ message }) {
                setIsError(true);
                setPreviewData({
                    isFetchingMore: false,
                    parentColumnIds: [],
                    childColumnIds: [],
                    rows: [],
                    data: {},
                    metaData: {},
                    totalRecords: 0,
                    ROW_START_INDEX: 0,
                    ROW_STOP_INDEX: RECORDS_RENDER_GRID_PREVIEW,
                    tree: []
                });
                childRef.current = {};
                setIsFetching(false);
                console.log('message error', message);
            }
        };

        if (!processSourceView || !processDestinationView) return;

        if (isTaskSuccess) fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        currentBranchId,
        currentToBranchId,
        dbId,
        processDestinationView,
        processSourceView,
        isTaskSuccess,
        mainTaskId,
        selectedRefColumn
    ]);

    const refreshGrid = useCallback(
        async ({ quickFilters, processSourceView, recordOptions, showAllConflicts }) => {
            console.log('refresh Grid');
            try {
                const [{ totalRecords, records }, columnAggregations] = await Promise.all([
                    await getPreviewMergeApi({
                        taskId: currentTaskId,
                        sourceViewId: processSourceView?.value,
                        dbId,
                        offset: 0,
                        limit: RECORDS_RENDER_GRID_PREVIEW,
                        columnIds: previewData?.childColumnIds,
                        mergeColumnOptions: [],
                        mergeRecordOptions: recordOptions,
                        query: formatQuickFilters(quickFilters),
                        showAllConflicts
                    }),
                    await getPreviewMergeAggregationApi({
                        taskId: currentTaskId,
                        sourceViewId: processSourceView?.value,
                        dbId,
                        columnIds: previewData?.childColumnIds,
                        mergeColumnOptions: [],
                        mergeRecordOptions: recordOptions,
                        query: formatQuickFilters(quickFilters),
                        showAllConflicts
                    })
                ]);

                setAggregations(
                    columnAggregations?.reduce((obj, i) => {
                        obj[i?.columnId] = i;
                        return obj;
                    }, {})
                );

                const { rows, data } = formatDataGridPreview(records);
                setPreviewData(
                    produce(previewData, draft => {
                        draft.isFetchingMore = false;
                        draft.rows = rows;
                        draft.data = data;
                        draft.totalRecords = totalRecords;
                        draft.ROW_STOP_INDEX = RECORDS_RENDER_GRID_PREVIEW;
                        draft.ROW_START_INDEX = 0;

                        return draft;
                    })
                );
                setIsError(false);
            } catch ({ message }) {
                console.log('error', message);
                setIsError(false);
            }
        },
        [dbId, currentTaskId, previewData]
    );

    const handleToggleShowAllConflict = useCallback(() => {
        const newShowAllConflicts = !showAllConflicts;

        setShowAllConflicts(newShowAllConflicts);

        refreshGrid({ quickFilters, processSourceView, recordOptions, showAllConflicts: newShowAllConflicts });
    }, [quickFilters, processSourceView, recordOptions, refreshGrid, showAllConflicts]);

    const handleRecordOptionChange = useCallback(
        option => {
            if (option === 'override') {
                setResolveConflictData({});
            }

            let newRecordOptions = [];

            if (recordOptions.includes(option)) {
                newRecordOptions = recordOptions?.filter(opt => option !== opt);
                setRecordOptions();
            } else {
                newRecordOptions = [...recordOptions, option];
            }

            setRecordOptions(newRecordOptions);

            refreshGrid({ quickFilters, processSourceView, recordOptions: newRecordOptions, showAllConflicts });
        },
        [recordOptions, refreshGrid, quickFilters, processSourceView, showAllConflicts]
    );

    const handleColumnOptionChange = useCallback(
        option => {
            if (columnOptions.includes(option)) {
                setColumnOptions(columnOptions?.filter(opt => option !== opt));
            } else {
                setColumnOptions([...columnOptions, option]);
            }
        },
        [columnOptions]
    );

    const NOTES = useMemo(
        () => [
            { label: 'Updated', value: PREVIEW_STATUS.UPDATED, color: theme.colors.dodgerBlue },
            { label: 'Deleted', value: PREVIEW_STATUS.DELETED, color: theme.colors.brightRed },
            { label: 'Added', value: PREVIEW_STATUS.ADDED, color: theme.colors.shamRock },
            { label: 'Conflict', value: PREVIEW_STATUS.CONFLICT, color: theme.colors.sun }
        ],
        [theme]
    );

    const onFetchingMore = useCallback(
        async ({ dbId, gridId, parentGridId, ROW_START_INDEX, ROW_STOP_INDEX, successCallback, errorCallback }) => {
            setPreviewData(
                produce(previewData, draft => {
                    draft.isFetchingMore = true;
                })
            );
            try {
                const { totalRecords, records } = await getPreviewMergeApi({
                    taskId: currentTask?.id,
                    sourceViewId: processSourceView?.value,
                    dbId,
                    offset: ROW_START_INDEX,
                    limit: ROW_STOP_INDEX,
                    columnIds: previewData?.childColumnIds,
                    mergeColumnOptions: [],
                    mergeRecordOptions: recordOptions,
                    query: formatQuickFilters(quickFilters),
                    showAllConflicts
                });
                const { rows, data } = formatDataGridPreview(records);

                const newPreviewData = produce(previewData, draft => {
                    draft.isFetchingMore = false;
                    draft.rows = rows;
                    draft.data = data;
                    draft.totalRecords = totalRecords;
                    draft.ROW_STOP_INDEX = ROW_STOP_INDEX;
                    draft.ROW_START_INDEX = ROW_START_INDEX;
                });

                setPreviewData(newPreviewData);
            } catch (error) {
                setPreviewData(
                    produce(previewData, draft => {
                        draft.isFetchingMore = false;
                    })
                );
            }
        },
        [previewData, processSourceView, recordOptions, quickFilters, currentTask, showAllConflicts]
    );

    const isMergingChild = useMemo(() => {
        return !!selectedRefColumn;
    }, [selectedRefColumn]);

    const handleMerge = useCallback(() => {
        setIsMerging(true);
        if (isMergingChild) {
            setIsProcessMergingChild(true);
        }

        sendManualTrack({
            type: `Merge branch`,
            customData: {
                sourceViewId: processSourceView?.value,
                mergeColumnOptions: columnOptions,
                mergeDependencyOptions: ['update'],
                mergeRecordOptions: recordOptions,
                mergeTagTreeOptions: ['update']
            }
        });

        const mergeRecordConflicts = [];

        const recordIds = Object.keys(resolveConflictData);
        for (const recordId of recordIds) {
            const columnIds = Object.keys(resolveConflictData?.[recordId]);

            let cells = [];

            for (const columnId of columnIds) {
                let cell = {
                    columnId,
                    option: resolveConflictData?.[recordId]?.[columnId]
                };
                cells.push(cell);
            }
            mergeRecordConflicts.push({
                recordId,
                cells
            });
        }

        let body = {
            sourceViewId: processSourceView?.value,
            mergeColumnOptions: columnOptions,
            mergeDependencyOptions: ['update'],
            mergeRecordOptions: recordOptions,
            mergeTagTreeOptions: ['update'],
            mergeRecordConflicts,
            showAllConflicts
        };

        if (!isLDEmpty(quickFilters)) {
            body = {
                ...body,
                query: JSON.stringify(formatQuickFilters(quickFilters))
            };
        }

        dispatch(
            branchActions.mergeBranch({
                dbId,
                destinationViewId: processDestinationView?.value,
                taskId: currentTaskId,
                body,
                successCallback: () => {
                    setIsMerging(false);

                    if (!isMergingChild) {
                        onClose();
                    }
                },
                errorCallback: () => {
                    console.log('merge failed');
                    setIsMerging(false);
                }
            })
        );
    }, [
        recordOptions,
        columnOptions,
        dispatch,
        dbId,
        quickFilters,
        processSourceView,
        onClose,
        resolveConflictData,
        processDestinationView,
        showAllConflicts,
        currentTaskId,
        isMergingChild
    ]);

    const handleConflictChanged = useCallback(
        ({ rowId, columnId, mode }) => {
            setResolveConflictData(
                produce(resolveConflictData, draft => {
                    if (!draft?.[rowId]) {
                        draft[rowId] = {};
                    }
                    draft[rowId][columnId] = mode;
                })
            );
        },
        [resolveConflictData]
    );

    const handleQuickFilterChange = useCallback(
        ({ columnId, value, type, currentState, operator }) => {
            const newQuickFilters = {
                ...quickFilters,
                [columnId]: {
                    ...quickFilters[columnId],
                    type,
                    value,
                    currentState,
                    operator
                }
            };
            setQuickFilters(newQuickFilters);
            refreshGrid({ quickFilters: newQuickFilters, processSourceView, recordOptions, showAllConflicts });
        },
        [quickFilters, processSourceView, recordOptions, refreshGrid, showAllConflicts]
    );

    const handleExtraFilterChange = useCallback(
        ({ extraFilter, columnId }) => {
            const newQuickFilters = {
                ...quickFilters,
                [columnId]: {
                    ...quickFilters[columnId],
                    extraFilter
                }
            };
            setQuickFilters(newQuickFilters);

            refreshGrid({ quickFilters: newQuickFilters, processSourceView, recordOptions, showAllConflicts });
        },
        [quickFilters, refreshGrid, processSourceView, recordOptions, showAllConflicts]
    );

    const isNotFoundAnyChanges = useMemo(() => {
        return (
            previewData?.rows?.length === 0 &&
            !previewData?.originalTotalRecords &&
            !previewData?.childColumnIds?.some(columnId => {
                const column = previewData?.metaData?.[columnId];
                return (
                    column?.diffStatus && [PREVIEW_STATUS.ADDED, PREVIEW_STATUS.UPDATED]?.includes(column?.diffStatus)
                );
            })
        );
    }, [previewData]);

    const handleSourceViewChange = useCallback(
        option => {
            if (option?.value === sourceView?.value) return;
            setSourceView(option);
            setProcessSourceView(option);
            setSelectedRefColumn(null);
            selectedRefColumnRef.current = null;
            setQuickFilters({});

            if (subscribeTopicsRef.current?.length) {
                subscribeTopicsRef.current.forEach(topicSubscribe => {
                    ws.unsubscribe({ topicSubscribe });
                });
            }
            setQuickFilters({});

            setMainTaskId(null);
            setChildTaskId(null);
        },
        [sourceView]
    );

    const handleDestinationViewChange = useCallback(
        async option => {
            if (option?.value === destinationView?.value) return;
            setDestinationView(option);
            setProcessDestinationView(option);
            setSelectedRefColumn(null);
            selectedRefColumnRef.current = null;
            setQuickFilters({});

            if (subscribeTopicsRef.current?.length) {
                subscribeTopicsRef.current.forEach(topicSubscribe => {
                    ws.unsubscribe({ topicSubscribe });
                });
            }

            setMainTaskId(null);
            setChildTaskId(null);
        },
        [destinationView]
    );

    const handleAggregationChange = useCallback(
        ({ columnId, aggregationType }) => {
            const value = 30;

            setAggregations({
                ...aggregations,
                [columnId]: {
                    ...aggregations?.[columnId],
                    type: aggregationType,
                    value: value
                }
            });
        },
        [aggregations]
    );

    const handleRowHeightClick = useCallback(
        event => {
            setDefaultRowHeightAnchorEl(defaultRowHeightAnchorEl ? null : event.currentTarget);
        },
        [defaultRowHeightAnchorEl]
    );

    const handleCloseRowHeight = useCallback(event => {
        setDefaultRowHeightAnchorEl(null);
    }, []);

    const changeDefaultRowHeight = useCallback(
        newRowHeight => {
            setRowHeight(newRowHeight);
            handleCloseRowHeight();
        },
        [handleCloseRowHeight]
    );

    const openCustomRowHeightHandler = useCallback(() => {
        setOpenCustomRowHeight(true);
        handleCloseRowHeight();
    }, [handleCloseRowHeight]);

    const closeCustomRowHeightHandler = useCallback(() => setOpenCustomRowHeight(false), []);

    const handleCustomRowHeightChange = useCallback(
        newHeight => {
            setRowHeight(newHeight);
            closeCustomRowHeightHandler();
        },
        [closeCustomRowHeightHandler]
    );

    useEffect(() => {
        return () => {
            if (subscribeTopicsRef.current?.length) {
                subscribeTopicsRef.current.forEach(topicSubscribe => {
                    ws.unsubscribe({ topicSubscribe });
                });
            }
        };
    }, []);

    const handleBackToMain = useCallback(
        async refColumn => {
            const combinedRefColumn = refColumn || selectedRefColumn;

            if (!combinedRefColumn) return;

            setSelectedRefColumn(null);
            selectedRefColumnRef.current = null;

            if (subscribeTopicsRef.current?.length) {
                subscribeTopicsRef.current.forEach(topicSubscribe => {
                    ws.unsubscribe({ topicSubscribe });
                });
            }
            setChildTaskId(mainTaskId);
            setProcessSourceView(sourceView);
            setProcessDestinationView(destinationView);
        },
        [sourceView, destinationView, mainTaskId, selectedRefColumn]
    );

    const gridDetailHandler = useCallback(
        message => {
            let { headers, body } = mapResponse(message);
            const serverSocketId = headers['X-Socket-Id'];
            const { objectType, action, subAction, status, gridId } = body;

            if (!serverSocketId) {
                console.log('WHY THIS HAPPENED A.TRONG???');
            }

            if (objectType === types.GRID && types.MERGE_GRID === action && subAction === types.MERGE_GRID_END) {
                if (
                    status?.toLowerCase() === GRID_STATUS.SUCCESS &&
                    gridId === selectedRefColumnRef?.current?.sourceBranch?.id
                ) {
                    setMergingTaskRef(prev => ({
                        ...prev,
                        [selectedRefColumnRef.current.id]: true
                    }));
                    handleBackToMain(selectedRefColumnRef.current);
                    setIsProcessMergingChild(false);
                } else {
                    setIsProcessMergingChild(false);
                }
            }
        },
        [selectedRefColumn, handleBackToMain]
    );

    const handleSwitchRef = useCallback(
        async refColumn => {
            if (selectedRefColumn?.id === refColumn?.id) return;

            const sourceViewId = refColumn?.sourceBranch?.defaultAccessViewId;
            const destinationViewId = refColumn?.destinationBranch?.defaultAccessViewId;

            try {
                setIsVerifying(prev => ({
                    ...prev,
                    [refColumn.id]: true
                }));
                let subscribePages = [
                    {
                        topic: `/topic/c/${companyId}/databases/${dbId}/grids/${refColumn?.sourceBranch?.id}`,
                        handler: gridDetailHandler
                    }
                ];

                if (subscribeTopicsRef.current?.length) {
                    subscribeTopicsRef.current.forEach(topicSubscribe => {
                        ws.unsubscribe({ topicSubscribe });
                    });
                }

                const subscriptions = await Promise.all(
                    subscribePages.map(sub =>
                        ws.subscribe({
                            topic: sub.topic,
                            handler: sub.handler
                        })
                    )
                );

                subscribeTopicsRef.current = subscriptions;

                const task = await createPreviewMergeApi({
                    dbId,
                    sourceViewId,
                    body: {
                        destinationViewId
                    }
                });
                setSelectedRefColumn(refColumn);
                selectedRefColumnRef.current = refColumn;
                childRef.current = {
                    taskId: task?.id,
                    sourceViewId,
                    destinationViewId
                };
                setChildTaskId(task?.id);
                setProcessSourceView({ id: sourceViewId, value: sourceViewId, label: 'Default Access View' });
                setProcessDestinationView({
                    id: destinationViewId,
                    value: destinationViewId,
                    label: 'Default Access View'
                });
                // dispatch(gridUIActions.registerMergingTask(task));
                setIsVerifying(prev => ({
                    ...prev,
                    [refColumn.id]: false
                }));

                setResolveConflictData({});
            } catch ({ message }) {
                console.log('error', message);
                setIsVerifying(prev => ({
                    ...prev,
                    [refColumn.id]: false
                }));
            }
        },
        [processSourceView, companyId, selectedRefColumn, gridDetailHandler]
    );

    return (
        <>
            <div
                className={classNames('w-screen h-screen flex flex-col select-none', {
                    disabled: isProcessMergingChild
                })}
            >
                <div className="flex items-center justify-between px-8 border-b border-grey-border h-16">
                    <div className="items-center flex gap-4">
                        <div className="flex flex-row items-center gap-2">
                            <MergedSVG color={theme.colors.secondaryText} width={20} height={20} />
                            <h3 className="prose prose-2xl font-medium">{t('merge_branch')}</h3>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <BranchTag
                                className={'h-9 [&>p]:text-brand-main [&>p]:font-medium'}
                                name={branchId === gridId ? t('master_branch') : branch?.name}
                            />
                            <div className="min-w-[200px]">
                                <LDBasePortal
                                    ddPlaceholder={'Select view'}
                                    menuPlaceholder={t('find_a_view')}
                                    options={generateOptions(sourceViews)}
                                    onChange={handleSourceViewChange}
                                    defaultValue={sourceView}
                                    isMulti={false}
                                    isDisabled={isMerging || isFetching}
                                />
                            </div>
                        </div>
                        <ArrowNextSVG className="flex-none" color={theme.colors.lightLavender} />

                        <div className="flex flex-row items-center gap-2">
                            <BranchTag
                                className={'h-9 [&>p]:text-brand-main [&>p]:font-medium'}
                                name={toBranchId === gridId ? t('master_branch') : mergeToBranch?.name}
                            />

                            <div className="min-w-[200px]">
                                <LDBasePortal
                                    ddPlaceholder={'Select view'}
                                    menuPlaceholder={t('find_a_view')}
                                    options={generateOptions(destinationViews)}
                                    onChange={handleDestinationViewChange}
                                    defaultValue={destinationView}
                                    isMulti={false}
                                    isDisabled={isMerging || isFetching}
                                />
                            </div>
                        </div>
                    </div>
                    <CloseIconSVG onClick={onClose} className={'cursor-pointer'} size={20} />
                </div>
                <div className="flex flex-1">
                    <AutoSizer>
                        {({ width, height }) => {
                            const actualHeight = height - 64;

                            return (
                                <div
                                    style={{
                                        width,
                                        height: actualHeight
                                    }}
                                    className="flex"
                                >
                                    <div
                                        style={{
                                            maxHeight: actualHeight
                                        }}
                                        className="flex-1 px-12 overflow-y-auto flex flex-col py-6 gap-6"
                                    >
                                        {selectedRefColumn && (
                                            <h3 className="prose prose-lg font-medium">
                                                You are comparing changes and merging for:
                                            </h3>
                                        )}
                                        {refColumns?.length > 0 &&
                                            (!selectedRefColumn ? (
                                                <div className="gap-3.5 p-3.5 flex items-center rounded border border-l-4 border-yellow-400">
                                                    <InfoIconSVG color={theme.colors.sun} />
                                                    <div className="flex flex-col">
                                                        <p className="body2">
                                                            This Grid has some columns which are referencing to the
                                                            different data sources.
                                                        </p>
                                                        <p className="body2">
                                                            Please resolve the conflicts by merging the appropriate
                                                            branches of the listed grids in the{' '}
                                                            <strong>Merge queue</strong> to continue.
                                                        </p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="gap-3.5 p-3.5 flex items-center rounded border border-l-4 border-yellow-400">
                                                    <InfoIconSVG color={theme.colors.sun} />
                                                    <div className="flex flex-col">
                                                        <p className="body2">
                                                            This Grid has some columns which are referenced from the
                                                            Main grid.
                                                        </p>
                                                        <p className="body2">
                                                            You need to resolve the conflicts in this Grid before
                                                            merging the referenced branches in Main grid.
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="flex-1">
                                            <AutoSizer>
                                                {({ width, height }) => {
                                                    return (
                                                        <div
                                                            style={{ width, height }}
                                                            className="flex flex-col border border-app"
                                                        >
                                                            {selectedRefColumn && (
                                                                <div className="flex flex-col p-4 border-b border-app">
                                                                    <div className="flex flex-row gap-2 items-center">
                                                                        <GridIcon
                                                                            size="small"
                                                                            grid={selectedRefColumn?.sourceGrid}
                                                                        />
                                                                        <h3 className="prose prose-lg font-medium">
                                                                            {selectedRefColumn?.sourceGrid?.name}
                                                                        </h3>
                                                                    </div>

                                                                    <div className="flex gap-2 items-center">
                                                                        <BranchTag
                                                                            name={selectedRefColumn?.sourceBranch?.name}
                                                                        />

                                                                        <ArrowNextSVG
                                                                            className="flex-none"
                                                                            color={theme.colors.lightLavender}
                                                                        />

                                                                        <BranchTag
                                                                            name={
                                                                                selectedRefColumn?.destinationBranch
                                                                                    ?.name
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="flex justify-between items-center px-4 h-[46px] border-b border-app">
                                                                <h3 className="prose prose-lg font-medium">
                                                                    Merge Options
                                                                </h3>
                                                                {isShowExpand ? (
                                                                    <ArrowUpSVG
                                                                        className={'cursor-pointer'}
                                                                        onClick={() => setIsShowExpand(!isShowExpand)}
                                                                    />
                                                                ) : (
                                                                    <ArrowDownSVG
                                                                        className={'cursor-pointer'}
                                                                        onClick={() => setIsShowExpand(!isShowExpand)}
                                                                    />
                                                                )}
                                                            </div>
                                                            {isShowExpand && (
                                                                <div className="p-4 border-b border-app">
                                                                    <div className="flex flex-col gap-2">
                                                                        <p className="body1">Record options</p>
                                                                        <div className="grid grid-cols-4">
                                                                            {RECORD_OPTIONS.map(option => {
                                                                                const isSelected = recordOptions?.includes(
                                                                                    option?.value
                                                                                );
                                                                                return (
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleRecordOptionChange(
                                                                                                option?.value
                                                                                            )
                                                                                        }
                                                                                        className="flex cursor-pointer items-center"
                                                                                        key={option?.value}
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={isSelected}
                                                                                        />
                                                                                        <p className="body2">
                                                                                            {option?.description}
                                                                                        </p>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>

                                                                        <p className="body1">Column options</p>
                                                                        <div className="grid grid-cols-4">
                                                                            {COLUMN_OPTIONS.map(option => {
                                                                                const isSelected = columnOptions?.includes(
                                                                                    option?.value
                                                                                );
                                                                                const isUpdate =
                                                                                    option?.value === 'update';

                                                                                return !isUpdate ? (
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleColumnOptionChange(
                                                                                                option?.value
                                                                                            )
                                                                                        }
                                                                                        className="flex cursor-pointer items-center"
                                                                                        key={option?.value}
                                                                                    >
                                                                                        <Checkbox
                                                                                            checked={
                                                                                                isUpdate
                                                                                                    ? true
                                                                                                    : isSelected
                                                                                            }
                                                                                        />
                                                                                        <p className="body2">
                                                                                            {option?.description}
                                                                                        </p>
                                                                                    </div>
                                                                                ) : (
                                                                                    <Tooltip
                                                                                        title={
                                                                                            'This option is selected by default'
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            onClick={() =>
                                                                                                handleColumnOptionChange(
                                                                                                    option?.value
                                                                                                )
                                                                                            }
                                                                                            className="flex cursor-pointer items-center gap-2 disabled"
                                                                                            key={option?.value}
                                                                                        >
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    isUpdate
                                                                                                        ? true
                                                                                                        : isSelected
                                                                                                }
                                                                                            />
                                                                                            <p className="body2">
                                                                                                {option?.description}
                                                                                            </p>
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            <div className="flex-1">
                                                                <AutoSizer>
                                                                    {({ width, height }) => {
                                                                        return (
                                                                            <div
                                                                                style={{ width, height }}
                                                                                className="flex flex-col"
                                                                            >
                                                                                <div className="h-12 px-4 border-b border-app flex items-center justify-between">
                                                                                    <h3 className="prose prose-lg font-medium">
                                                                                        Changes
                                                                                    </h3>
                                                                                    <div className="flex items-center gap-3.5">
                                                                                        <Tooltip
                                                                                            title={t(
                                                                                                'toolbar_default_rowHeight_tooltip'
                                                                                            )}
                                                                                        >
                                                                                            <div
                                                                                                onClick={
                                                                                                    handleRowHeightClick
                                                                                                }
                                                                                                className="cursor-pointer"
                                                                                            >
                                                                                                <RowHeightSVG
                                                                                                    color={
                                                                                                        theme.colors
                                                                                                            .darkness
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Toggle all conflicted cells">
                                                                                            <div
                                                                                                onClick={
                                                                                                    handleToggleShowAllConflict
                                                                                                }
                                                                                                className={classNames(
                                                                                                    'w-8 cursor-pointer h-8 flex items-center justify-center rounded',
                                                                                                    {
                                                                                                        'bg-solitude': showAllConflicts
                                                                                                    }
                                                                                                )}
                                                                                            >
                                                                                                <ShowAllConflictsSVG
                                                                                                    color={
                                                                                                        showAllConflicts
                                                                                                            ? theme
                                                                                                                  .palette
                                                                                                                  .primary
                                                                                                                  .main
                                                                                                            : undefined
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                        <Divider
                                                                                            orientation="vertical"
                                                                                            className="!h-6"
                                                                                        />
                                                                                        <div className="flex items-center gap-3">
                                                                                            {NOTES.map(note => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="flex gap-2 items-center"
                                                                                                        key={
                                                                                                            note?.vlaue
                                                                                                        }
                                                                                                    >
                                                                                                        <div
                                                                                                            className="w-4 h-4 rounded border"
                                                                                                            style={{
                                                                                                                borderColor:
                                                                                                                    note?.color,
                                                                                                                background: hexToRgba(
                                                                                                                    note.color,
                                                                                                                    0.2
                                                                                                                )
                                                                                                            }}
                                                                                                        ></div>
                                                                                                        <p className="body2">
                                                                                                            {note.label}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        height: height - 48,
                                                                                        minHeight: 350
                                                                                    }}
                                                                                >
                                                                                    {isFetching ? (
                                                                                        <div className="w-full h-full flex justify-center items-center">
                                                                                            <Spinner
                                                                                                size={40}
                                                                                                thick={5}
                                                                                            />
                                                                                        </div>
                                                                                    ) : isError ? (
                                                                                        <div className="w-full h-full flex flex-col overflow-hidden items-center justify-center select-none gap-1">
                                                                                            <ErrorSVG />
                                                                                            <h3 className="prose prose-2xl font-medium">
                                                                                                {t(
                                                                                                    'an_error_has_occur'
                                                                                                )}
                                                                                            </h3>
                                                                                            <p className="caption">
                                                                                                {t('error_description')}
                                                                                            </p>
                                                                                            <ButtonBase
                                                                                                variant="contained"
                                                                                                width={264}
                                                                                                className="mt-2"
                                                                                                onClick={() => {
                                                                                                    refreshGrid({
                                                                                                        quickFilters,
                                                                                                        processSourceView,
                                                                                                        recordOptions,
                                                                                                        showAllConflicts
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                Try again
                                                                                            </ButtonBase>
                                                                                        </div>
                                                                                    ) : isNotFoundAnyChanges ? (
                                                                                        <div className="w-full h-full flex flex-col overflow-hidden items-center justify-center select-none gap-1">
                                                                                            <NoDiffCheck
                                                                                                width={200}
                                                                                                height={200}
                                                                                            />
                                                                                            <h3 className="prose prose-2xl font-medium">
                                                                                                {t(
                                                                                                    'diffcheck_wrapper_text_1'
                                                                                                )}
                                                                                            </h3>
                                                                                            <p className="caption">
                                                                                                {t(
                                                                                                    'diffcheck_wrapper_text_2'
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <Grid
                                                                                            id={`gridUI-table`}
                                                                                            className={classes.table}
                                                                                        >
                                                                                            <AutoSizer>
                                                                                                {({
                                                                                                    width,
                                                                                                    height
                                                                                                }) => {
                                                                                                    return (
                                                                                                        <GridTable
                                                                                                            branchId={
                                                                                                                currentBranchId
                                                                                                            }
                                                                                                            parentGridId={
                                                                                                                currentToBranchId
                                                                                                            }
                                                                                                            dbId={dbId}
                                                                                                            accessSupportSelectAllCheckbox={
                                                                                                                roleConst.HIDDEN
                                                                                                            }
                                                                                                            accessSupportAggregation={
                                                                                                                roleConst.HIDDEN
                                                                                                            }
                                                                                                            accessEditCell={
                                                                                                                roleConst.READ_ONLY
                                                                                                            }
                                                                                                            accessViewRecordHistory={
                                                                                                                roleConst.READ_ONLY
                                                                                                            }
                                                                                                            accessManageGridRecord={
                                                                                                                roleConst.READ_ONLY
                                                                                                            }
                                                                                                            accessEditColumn={
                                                                                                                true
                                                                                                            }
                                                                                                            dependencies={[]}
                                                                                                            metaData={
                                                                                                                previewData?.metaData
                                                                                                            }
                                                                                                            rows={
                                                                                                                previewData?.rows
                                                                                                            }
                                                                                                            totalRecords={
                                                                                                                previewData?.totalRecords +
                                                                                                                FAKE_ROW
                                                                                                            }
                                                                                                            data={
                                                                                                                previewData?.data
                                                                                                            }
                                                                                                            columns={
                                                                                                                columnIds
                                                                                                            }
                                                                                                            width={
                                                                                                                width
                                                                                                            }
                                                                                                            maxHeight={
                                                                                                                height
                                                                                                            }
                                                                                                            rowHeight={
                                                                                                                rowHeight
                                                                                                            }
                                                                                                            fixedColumnCount={
                                                                                                                0
                                                                                                            }
                                                                                                            fixedRowCount={
                                                                                                                2
                                                                                                            }
                                                                                                            ROW_START_INDEX={
                                                                                                                previewData?.ROW_START_INDEX
                                                                                                            }
                                                                                                            ROW_STOP_INDEX={
                                                                                                                previewData?.ROW_STOP_INDEX
                                                                                                            }
                                                                                                            isFetchingMore={
                                                                                                                previewData?.isFetchingMore
                                                                                                            }
                                                                                                            contextMenuId={
                                                                                                                contextMenuId
                                                                                                            }
                                                                                                            onFetchingMore={
                                                                                                                onFetchingMore
                                                                                                            }
                                                                                                            isOverride={recordOptions?.includes(
                                                                                                                'override'
                                                                                                            )}
                                                                                                            resolveConflictData={
                                                                                                                resolveConflictData
                                                                                                            }
                                                                                                            onConflictChanged={
                                                                                                                handleConflictChanged
                                                                                                            }
                                                                                                            branchName={
                                                                                                                currentBranchId ===
                                                                                                                gridId
                                                                                                                    ? t(
                                                                                                                          'master_branch'
                                                                                                                      )
                                                                                                                    : branch?.name
                                                                                                            }
                                                                                                            mergeToBranchName={
                                                                                                                gridId ===
                                                                                                                currentToBranchId
                                                                                                                    ? t(
                                                                                                                          'master_branch'
                                                                                                                      )
                                                                                                                    : mergeToBranch?.name
                                                                                                            }
                                                                                                            conflictType={
                                                                                                                CONFLICT_TYPES.BRANCHING
                                                                                                            }
                                                                                                            AGG_HEIGHT={
                                                                                                                ROW_HEIGHT
                                                                                                            }
                                                                                                            onAggregationChange={
                                                                                                                handleAggregationChange
                                                                                                            }
                                                                                                            aggregations={
                                                                                                                aggregations
                                                                                                            }
                                                                                                            quickFilters={
                                                                                                                quickFilters
                                                                                                            }
                                                                                                            onRefreshGrid={
                                                                                                                handleRefreshGrid
                                                                                                            }
                                                                                                            onExtraFilterChange={
                                                                                                                handleExtraFilterChange
                                                                                                            }
                                                                                                            onQuickFilterChange={
                                                                                                                handleQuickFilterChange
                                                                                                            }
                                                                                                            tree={tree}
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            </AutoSizer>
                                                                                        </Grid>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </AutoSizer>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </AutoSizer>
                                        </div>
                                    </div>
                                    {refColumns?.length > 0 && (
                                        <div
                                            className="w-[320px] border-l border-app flex flex-col"
                                            style={{ height: actualHeight }}
                                        >
                                            <div className="p-4 border-b border-app">
                                                <h3 className="prose prose-lg font-medium">Merge queue</h3>
                                            </div>
                                            <div
                                                onClick={handleBackToMain}
                                                className={classNames('p-4 flex flex-col gap-2 border-b border-app', {
                                                    disabled: isFetching
                                                })}
                                            >
                                                <p className="body1">Main Grid:</p>
                                                <div
                                                    className={classNames(
                                                        'p-4 flex flex-row gap-2 rounded items-center',
                                                        {
                                                            'bg-navy-pale': !selectedRefColumn,
                                                            'bg-transparent border border-app cursor-pointer hover:bg-grey-ghost': selectedRefColumn
                                                        }
                                                    )}
                                                >
                                                    <GridIcon grid={grid} size="small" />
                                                    <p className="body1 truncate">{grid?.name}</p>
                                                </div>
                                            </div>
                                            <div
                                                style={{ height: actualHeight - 65 - 121 }}
                                                className="py-4 flex flex-col gap-2 border-b border-app "
                                            >
                                                <p className="body1 px-4">
                                                    {t(`waiting_to_be_merge`)} ({refColumns?.length})
                                                </p>
                                                <p className="caption px-4">{t('waiting_to_be_merge_description')}</p>
                                                <div className="flex-1">
                                                    <AutoSizer>
                                                        {({ width, height }) => {
                                                            return (
                                                                <div
                                                                    style={{ width, height }}
                                                                    className="flex flex-col gap-3 px-4 overflow-y-auto scrollbar-app"
                                                                >
                                                                    {refColumns?.map(refColumn => {
                                                                        const isDifference =
                                                                            refColumn?.sourceGrid?.id !==
                                                                            refColumn?.destinationGrid?.id;

                                                                        if (isDifference)
                                                                            return (
                                                                                <div
                                                                                    className="w-full gap-3 flex flex-col p-4 rounded border border-app disabled"
                                                                                    key={refColumn?.id}
                                                                                >
                                                                                    <div className="flex flex-col gap-2">
                                                                                        <div className="flex flex-row gap-1 flex-wrap items-center">
                                                                                            <BranchTag
                                                                                                name={
                                                                                                    refColumn
                                                                                                        ?.sourceBranch
                                                                                                        ?.name
                                                                                                }
                                                                                            />

                                                                                            <div className="flex flex-row gap-1 items-center">
                                                                                                <GridIcon
                                                                                                    grid={
                                                                                                        refColumn?.sourceGrid
                                                                                                    }
                                                                                                    size="small"
                                                                                                />
                                                                                                <p className="body1 truncate">
                                                                                                    {
                                                                                                        refColumn
                                                                                                            ?.sourceGrid
                                                                                                            ?.name
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <ArrowNextSVG
                                                                                            className="flex-none"
                                                                                            color={
                                                                                                theme.colors
                                                                                                    .lightLavender
                                                                                            }
                                                                                        />
                                                                                        <div className="flex flex-row gap-1 flex-wrap items-center">
                                                                                            <BranchTag
                                                                                                name={
                                                                                                    refColumn
                                                                                                        ?.destinationBranch
                                                                                                        ?.name
                                                                                                }
                                                                                            />

                                                                                            <div className="flex flex-row gap-1 items-center">
                                                                                                <GridIcon
                                                                                                    grid={
                                                                                                        refColumn?.destinationGrid
                                                                                                    }
                                                                                                    size="small"
                                                                                                />
                                                                                                <p className="body1 truncate">
                                                                                                    {
                                                                                                        refColumn
                                                                                                            ?.destinationGrid
                                                                                                            ?.name
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );

                                                                        const isDone = !!mergingTasks?.[refColumn?.id];
                                                                        return (
                                                                            <div
                                                                                className={classNames(
                                                                                    'w-full gap-3 flex cursor-pointer flex-col p-4 rounded border border-app',
                                                                                    {
                                                                                        'bg-navy-pale border-none':
                                                                                            refColumn?.id ===
                                                                                            selectedRefColumn?.id,
                                                                                        disabled: isFetching
                                                                                    }
                                                                                )}
                                                                                onClick={() =>
                                                                                    handleSwitchRef(refColumn)
                                                                                }
                                                                                key={refColumn?.id}
                                                                            >
                                                                                <div className="flex items-center justify-between gap-1">
                                                                                    <div className="flex flex-row gap-1 items-center">
                                                                                        <GridIcon size="small" />
                                                                                        <p className="body1 truncate">
                                                                                            {
                                                                                                refColumn?.sourceGrid
                                                                                                    ?.name
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="flex items-center gap-2">
                                                                                        {isVerifying?.[
                                                                                            refColumn.id
                                                                                        ] && (
                                                                                            <CircularProgress
                                                                                                size={'18px'}
                                                                                            />
                                                                                        )}
                                                                                        {isDone ? (
                                                                                            <DoneSVG
                                                                                                width={18}
                                                                                                height={18}
                                                                                            />
                                                                                        ) : (
                                                                                            <InfoIconSVG
                                                                                                color={theme.colors.sun}
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-row items-center justify-between">
                                                                                    <BranchTag
                                                                                        name={
                                                                                            refColumn?.sourceBranch
                                                                                                ?.name
                                                                                        }
                                                                                    />

                                                                                    <ArrowNextSVG
                                                                                        className="flex-none"
                                                                                        color={
                                                                                            theme.colors.lightLavender
                                                                                        }
                                                                                    />

                                                                                    <BranchTag
                                                                                        name={
                                                                                            refColumn?.destinationBranch
                                                                                                ?.name
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            );
                                                        }}
                                                    </AutoSizer>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    </AutoSizer>
                    {defaultRowHeightAnchorEl && (
                        <PopperMenu
                            placement={`bottom-end`}
                            anchorEl={defaultRowHeightAnchorEl}
                            handleClickAway={handleCloseRowHeight}
                            className={classes.popperMenu}
                        >
                            <ListItem
                                name={t('global_short')}
                                icon={<ShortSVG />}
                                onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.DEFAULT)}
                                isSelected={rowHeight === ROW_HEIGHT_OPTIONS.DEFAULT}
                            />
                            <ListItem
                                name={t('global_medium')}
                                icon={<MediumSVG />}
                                onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMFORTABLE)}
                                isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMFORTABLE}
                            />
                            <ListItem
                                icon={<TallSVG />}
                                name={t('global_tall')}
                                onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMPACT)}
                                isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMPACT}
                            />

                            <ListItem
                                icon={<CustomSVG />}
                                name={t('global_custom')}
                                onClick={openCustomRowHeightHandler}
                                isSelected={
                                    !Object.keys(ROW_HEIGHT_OPTIONS)
                                        ?.map(key => ROW_HEIGHT_OPTIONS?.[key])
                                        ?.includes(rowHeight)
                                }
                            />
                        </PopperMenu>
                    )}

                    <Dialog maxWidth={false} open={openCustomRowHeight} onClose={closeCustomRowHeightHandler}>
                        <CustomRowHeight
                            rowHeight={rowHeight}
                            onClose={closeCustomRowHeightHandler}
                            onHeightChange={handleCustomRowHeightChange}
                        />
                    </Dialog>
                </div>

                <div className="fixed flex gap-2 bottom-0 w-full h-16 items-center px-8 border-t border-grey-border justify-end">
                    <ButtonBase
                        onClick={handleMerge}
                        disabled={
                            isMerging ||
                            !destinationView ||
                            mergingTasks?.[selectedRefColumn?.id] ||
                            isError ||
                            isFetching
                        }
                        width={140}
                        variant="contained"
                    >
                        Merge
                        {isMerging && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </ButtonBase>
                </div>
            </div>
            {isProcessMergingChild && (
                <div className="absolute bg-[#ffffff90]  z-10 top-0 left-0 w-screen h-screen flex items-center justify-center">
                    <div className="bg-white shadow p-8 flex flex-col gap-2">
                        <h3 className="prose prose-lg font-medium">Merging in process</h3>
                        <p className="body2">
                            This Grid is being merged. It is temporarily locked and may take a while. <br /> Please be
                            patient.
                        </p>
                        <ProgressLinear title="Merging....." />
                    </div>
                </div>
            )}
        </>
    );
}

export default memo(MergingDiffCheck);
