export function isValidRegex(str) {
    let isValid = true;
    try {
        new RegExp(str);
    } catch (e) {
        isValid = false;
    }

    return isValid;
}
