import React from 'react';

function UserViewSVG({ color = '#93929e', ...other }) {
    return (
        <svg
            {...other}
            width="13px"
            height="13px"
            viewBox="0 0 13 13"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons/view" transform="translate(-6.000000, -6.000000)" fill={color} fillRule="nonzero">
                    <path
                        d="M9.83918129,13.6403509 C10.8021491,13.4122796 11.6890798,13.2982456 12.5,13.2982456 C13.3109202,13.2982456 14.1978509,13.4122796 15.1608187,13.6403509 C16.1237865,13.8684222 17.004382,14.2485354 17.8026316,14.7807018 C18.6008812,15.3128682 19,15.9083788 19,16.5672515 L19,19 L6,19 L6,16.5672515 C6,15.9083788 6.39911882,15.3128682 7.19736842,14.7807018 C7.99561803,14.2485354 8.87621351,13.8684222 9.83918129,13.6403509 Z M10.2192982,6.98830409 C10.8528297,6.3294314 11.613056,6 12.5,6 C13.386944,6 14.1471703,6.32309618 14.7807018,6.96929825 C15.4142332,7.61550031 15.7309942,8.38206184 15.7309942,9.26900585 C15.7309942,10.1559499 15.4142332,10.9161762 14.7807018,11.5497076 C14.1471703,12.183239 13.386944,12.5 12.5,12.5 C11.613056,12.5 10.8528297,12.183239 10.2192982,11.5497076 C9.58576681,10.9161762 9.26900585,10.1559499 9.26900585,9.26900585 C9.26900585,8.38206184 9.58576681,7.62183553 10.2192982,6.98830409 Z M15.8070175,15.502924 C14.7173435,15.0721226 13.6150153,14.8567251 12.5,14.8567251 C11.3849847,14.8567251 10.2826565,15.0657874 9.19298246,15.4839181 C8.10330839,15.9020489 7.55847953,16.2631564 7.55847953,16.5672515 L7.55847953,17.4415205 L17.4415205,17.4415205 L17.4415205,16.5672515 C17.4415205,16.2631564 16.8966916,15.9083841 15.8070175,15.502924 Z M13.7163743,8.05263158 C13.3869379,7.72319523 12.9814839,7.55847953 12.5,7.55847953 C12.0185161,7.55847953 11.6130621,7.72319523 11.2836257,8.05263158 C10.9541894,8.38206792 10.7894737,8.78752196 10.7894737,9.26900585 C10.7894737,9.75048974 10.9541894,10.1496086 11.2836257,10.4663743 C11.6130621,10.78314 12.0185161,10.9415205 12.5,10.9415205 C12.9814839,10.9415205 13.3869379,10.78314 13.7163743,10.4663743 C14.0458106,10.1496086 14.2105263,9.75048974 14.2105263,9.26900585 C14.2105263,8.78752196 14.0458106,8.38206792 13.7163743,8.05263158 Z"
                        id="p"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default React.memo(UserViewSVG);
