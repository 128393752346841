import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width,
        height: props => props.height
    }
}));

const SIZES = {
    small: {
        height: 16,
        width: 16
    },
    medium: {
        width: 24,
        height: 24
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function ArrowUpSVG({ color = '#78778B', className, size = 'small', ...other }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height });
    return (
        <svg
            className={`${classes.root} ${className}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...other}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4453 10.5L8 6.77848L4.55469 10.5L3.5 9.36076L8 4.5L12.5 9.36076L11.4453 10.5Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowUpSVG);
