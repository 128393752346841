import React from 'react';

function GridListEmptySVG({ color = '#93929e', ...other }) {
    return (
        <svg width="247" height="113" viewBox="0 0 247 113" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0)">
                <path d="M181 15V51H202L211 42V15H181Z" fill="#EAEAF3" />
                <path d="M202 42V51L211 42H202Z" fill="#CCCEE3" />
                <path d="M193 51H202V42L193 51Z" fill="#DCDCED" />
                <path d="M175 24V34.5H199V24H175Z" fill="#CB5641" />
                <path
                    d="M180.145 30.6748C180.145 31.3588 179.988 31.8463 179.668 32.1388C179.352 32.4283 178.866 32.5753 178.212 32.5753C177.868 32.5753 177.588 32.5288 177.369 32.4343C177.15 32.3413 176.974 32.2108 176.845 32.0383C176.716 31.8658 176.628 31.6678 176.581 31.4398C176.532 31.2133 176.508 30.9718 176.508 30.7213V30.4408H177.696V30.6403C177.696 30.9823 177.732 31.2343 177.808 31.4008C177.883 31.5643 178.036 31.6483 178.272 31.6483C178.507 31.6483 178.662 31.5643 178.737 31.4008C178.812 31.2358 178.849 30.9823 178.849 30.6403V26.0488H180.145C180.145 26.0488 180.145 30.6748 180.145 30.6748Z"
                    fill="#EAEAF3"
                />
                <path
                    d="M182.575 30.6568C182.575 30.8068 182.589 30.9433 182.611 31.0648C182.635 31.1878 182.68 31.2913 182.746 31.3753C182.812 31.4608 182.902 31.5268 183.013 31.5748C183.123 31.6213 183.262 31.6468 183.43 31.6468C183.628 31.6468 183.807 31.5823 183.964 31.4533C184.123 31.3243 184.204 31.1233 184.204 30.8548C184.204 30.7108 184.183 30.5863 184.146 30.4813C184.107 30.3763 184.042 30.2818 183.952 30.1993C183.861 30.1138 183.742 30.0388 183.595 29.9698C183.448 29.9008 183.267 29.8303 183.052 29.7583C182.764 29.6623 182.515 29.5558 182.305 29.4418C182.095 29.3293 181.92 29.1958 181.779 29.0413C181.638 28.8883 181.533 28.7113 181.468 28.5103C181.401 28.3079 181.369 28.0769 181.369 27.8114C181.369 27.1754 181.546 26.7029 181.9 26.3909C182.254 26.0789 182.742 25.9229 183.36 25.9229C183.646 25.9229 183.913 25.9544 184.156 26.0174C184.399 26.0804 184.609 26.1809 184.786 26.3234C184.962 26.4644 185.101 26.6444 185.199 26.8619C185.298 27.0809 185.349 27.3434 185.349 27.6493V27.8294H184.105C184.105 27.5234 184.051 27.2879 183.945 27.1214C183.837 26.9579 183.655 26.8739 183.403 26.8739C183.259 26.8739 183.139 26.8964 183.045 26.9369C182.95 26.9789 182.872 27.0344 182.815 27.1034C182.758 27.1724 182.718 27.2549 182.697 27.3448C182.676 27.4348 182.665 27.5279 182.665 27.6239C182.665 27.8234 182.707 27.9883 182.791 28.1233C182.875 28.2598 183.055 28.3844 183.331 28.4969L184.33 28.9289C184.576 29.0369 184.776 29.1493 184.932 29.2678C185.088 29.3863 185.212 29.5108 185.305 29.6458C185.398 29.7808 185.466 29.9278 185.5 30.0913C185.536 30.2518 185.554 30.4333 185.554 30.6298C185.554 31.3078 185.358 31.8013 184.963 32.1103C184.57 32.4193 184.023 32.5753 183.322 32.5753C182.59 32.5753 182.065 32.4163 181.75 32.0983C181.435 31.7803 181.278 31.3243 181.278 30.7303V30.4693H182.575V30.6568Z"
                    fill="#EAEAF3"
                />
                <path
                    d="M186.674 27.9164C186.718 27.5069 186.818 27.1529 186.97 26.8589C187.124 26.5664 187.345 26.3354 187.631 26.1704C187.918 26.0069 188.306 25.9229 188.791 25.9229C189.277 25.9229 189.664 26.0069 189.952 26.1704C190.24 26.3354 190.46 26.5664 190.612 26.8589C190.766 27.1529 190.864 27.5069 190.91 27.9164C190.954 28.3274 190.978 28.7759 190.978 29.2619C190.978 29.7524 190.954 30.2039 190.91 30.6104C190.864 31.0184 190.765 31.3694 190.612 31.6634C190.459 31.9574 190.24 32.1824 189.952 32.3384C189.664 32.4944 189.277 32.5724 188.791 32.5724C188.306 32.5724 187.918 32.4929 187.631 32.3384C187.345 32.1824 187.123 31.9574 186.97 31.6634C186.817 31.3694 186.718 31.0184 186.674 30.6104C186.629 30.2039 186.607 29.7524 186.607 29.2619C186.607 28.7774 186.629 28.3289 186.674 27.9164ZM187.934 30.3644C187.955 30.6674 187.997 30.9134 188.06 31.1024C188.123 31.2914 188.213 31.4294 188.33 31.5164C188.447 31.6034 188.603 31.6469 188.794 31.6469C188.987 31.6469 189.14 31.6034 189.259 31.5164C189.376 31.4279 189.466 31.2914 189.53 31.1024C189.592 30.9134 189.634 30.6674 189.656 30.3644C189.677 30.0614 189.688 29.6954 189.688 29.2634C189.688 28.8314 189.677 28.4654 189.656 28.1654C189.634 27.8654 189.592 27.6194 189.53 27.4274C189.466 27.2354 189.376 27.0959 189.259 27.0104C189.142 26.9219 188.987 26.8784 188.794 26.8784C188.603 26.8784 188.449 26.9219 188.33 27.0104C188.213 27.0974 188.123 27.2354 188.06 27.4274C187.997 27.6194 187.955 27.8654 187.934 28.1654C187.913 28.4654 187.903 28.8314 187.903 29.2634C187.903 29.6954 187.913 30.0614 187.934 30.3644Z"
                    fill="#EAEAF3"
                />
                <path
                    d="M195.346 30.4513H195.364V26.0488H196.552V32.4748H195.078L193.492 27.9748H193.474V32.4748H192.285V26.0488H193.779L195.346 30.4513Z"
                    fill="#EAEAF3"
                />
            </g>
            <path d="M49 15V51H70L79 42V15H49Z" fill="#EAEAF3" />
            <path d="M70 42V51L79 42H70Z" fill="#CCCEE3" />
            <path d="M61 51H70V42L61 51Z" fill="#DCDCED" />
            <path d="M43 24V34.5H67V24H43Z" fill="#4A91E2" />
            <path
                d="M49.0299 27.1982C48.9189 26.9837 48.7164 26.8772 48.4224 26.8772C48.2604 26.8772 48.1269 26.9207 48.0219 27.0092C47.9169 27.0962 47.8344 27.2357 47.7744 27.4307C47.7144 27.6257 47.6724 27.8807 47.6484 28.1972C47.6229 28.5122 47.6124 28.8977 47.6124 29.3537C47.6124 29.8397 47.6289 30.2327 47.6619 30.5327C47.6934 30.8327 47.7459 31.0637 47.8149 31.2302C47.8839 31.3967 47.9709 31.5077 48.0759 31.5632C48.1809 31.6202 48.3024 31.6502 48.4404 31.6502C48.5544 31.6502 48.6609 31.6307 48.7599 31.5917C48.8589 31.5527 48.9444 31.4747 49.0164 31.3577C49.0899 31.2407 49.1469 31.0757 49.1874 30.8627C49.2294 30.6497 49.2504 30.3692 49.2504 30.0212H50.5449C50.5449 30.3677 50.5179 30.6992 50.4639 31.0112C50.4099 31.3232 50.3079 31.5962 50.1624 31.8302C50.0139 32.0627 49.8084 32.2457 49.5414 32.3777C49.2744 32.5097 48.9279 32.5772 48.5019 32.5772C48.0174 32.5772 47.6289 32.4977 47.3424 32.3432C47.0544 32.1872 46.8339 31.9622 46.6809 31.6682C46.5279 31.3742 46.4289 31.0232 46.3839 30.6152C46.3404 30.2087 46.3164 29.7572 46.3164 29.2667C46.3164 28.7807 46.3404 28.3322 46.3839 27.9212C46.4289 27.5117 46.5294 27.1577 46.6809 26.8637C46.8354 26.5712 47.0544 26.3402 47.3424 26.1752C47.6289 26.0117 48.0174 25.9277 48.5019 25.9277C48.9654 25.9277 49.3284 26.0027 49.5954 26.1527C49.8624 26.3027 50.0619 26.4932 50.1924 26.7242C50.3229 26.9552 50.4084 27.2042 50.4399 27.4712C50.4729 27.7382 50.4894 27.9887 50.4894 28.2227H49.1949C49.1949 27.7502 49.1394 27.4097 49.0299 27.1982Z"
                fill="#EAEAF3"
            />
            <path
                d="M53.2254 30.6569C53.2254 30.8069 53.2389 30.9434 53.2614 31.0649C53.2869 31.1879 53.3304 31.2914 53.3964 31.3754C53.4624 31.4609 53.5524 31.5269 53.6634 31.5749C53.7729 31.6214 53.9139 31.6469 54.0804 31.6469C54.2799 31.6469 54.4569 31.5824 54.6159 31.4534C54.7734 31.3244 54.8544 31.1234 54.8544 30.8549C54.8544 30.7109 54.8334 30.5864 54.7959 30.4814C54.7569 30.3764 54.6924 30.2819 54.6024 30.1994C54.5109 30.1139 54.3924 30.0389 54.2469 29.9699C54.1014 29.9009 53.9184 29.8304 53.7024 29.7584C53.4159 29.6624 53.1669 29.5559 52.9554 29.4419C52.7454 29.3294 52.5699 29.1959 52.4289 29.0414C52.2879 28.8884 52.1844 28.7114 52.1184 28.5104C52.0524 28.3079 52.0194 28.0769 52.0194 27.8114C52.0194 27.1754 52.1964 26.7029 52.5504 26.3909C52.9029 26.0789 53.3904 25.9229 54.0084 25.9229C54.2964 25.9229 54.5619 25.9544 54.8049 26.0174C55.0479 26.0804 55.2579 26.1809 55.4349 26.3234C55.6119 26.4644 55.7499 26.6444 55.8489 26.8619C55.9464 27.0809 55.9974 27.3434 55.9974 27.6494V27.8294H54.7569C54.7569 27.5234 54.7029 27.2879 54.5964 27.1214C54.4884 26.9579 54.3084 26.8739 54.0564 26.8739C53.9124 26.8739 53.7924 26.8964 53.6964 26.9369C53.6019 26.9789 53.5239 27.0344 53.4669 27.1034C53.4099 27.1724 53.3694 27.2549 53.3484 27.3449C53.3259 27.4349 53.3169 27.5279 53.3169 27.6239C53.3169 27.8234 53.3589 27.9884 53.4429 28.1234C53.5269 28.2599 53.7069 28.3844 53.9829 28.4969L54.9819 28.9289C55.2279 29.0369 55.4289 29.1494 55.5834 29.2679C55.7394 29.3849 55.8654 29.5109 55.9569 29.6459C56.0499 29.7809 56.1144 29.9279 56.1504 30.0914C56.1864 30.2519 56.2044 30.4334 56.2044 30.6299C56.2044 31.3079 56.0079 31.8014 55.6149 32.1104C55.2219 32.4194 54.6744 32.5754 53.9724 32.5754C53.2404 32.5754 52.7169 32.4164 52.4004 32.0984C52.0854 31.7804 51.9294 31.3244 51.9294 30.7304V30.4694H53.2254V30.6569Z"
                fill="#EAEAF3"
            />
            <path
                d="M59.6257 30.8008H59.6542L60.6082 26.0488H61.9507L60.4747 32.4748H58.8097L57.3352 26.0488H58.7212L59.6257 30.8008Z"
                fill="#EAEAF3"
            />
            <path d="M115 0V36H136L145 27V0H115Z" fill="#EAEAF3" />
            <path d="M136 27V36L145 27H136Z" fill="#CCCEE3" />
            <path d="M127 36H136V27L127 36Z" fill="#DCDCED" />
            <path d="M109 9V19.5H133V9H109Z" fill="#61B565" />
            <path
                d="M113.611 11.0488L114.439 13.1278L115.258 11.0488H116.671L115.177 14.2258L116.779 17.4748H115.321L114.395 15.2968L113.476 17.4748H112.054L113.656 14.2258L112.18 11.0488H113.611Z"
                fill="white"
            />
            <path d="M118.183 11.0488H119.479V16.4128H121.773V17.4748H118.183V11.0488Z" fill="white" />
            <path
                d="M124.294 15.6568C124.294 15.8068 124.306 15.9433 124.328 16.0648C124.354 16.1878 124.397 16.2913 124.463 16.3753C124.529 16.4608 124.619 16.5268 124.73 16.5748C124.84 16.6213 124.981 16.6468 125.147 16.6468C125.347 16.6468 125.524 16.5823 125.683 16.4533C125.84 16.3243 125.921 16.1233 125.921 15.8548C125.921 15.7108 125.9 15.5863 125.863 15.4813C125.824 15.3763 125.759 15.2818 125.669 15.1993C125.578 15.1138 125.459 15.0388 125.314 14.9698C125.168 14.9008 124.985 14.8303 124.769 14.7583C124.483 14.6623 124.234 14.5558 124.022 14.4418C123.812 14.3293 123.637 14.1958 123.496 14.0413C123.355 13.8883 123.251 13.7113 123.185 13.5103C123.119 13.3078 123.086 13.0768 123.086 12.8113C123.086 12.1754 123.263 11.7029 123.617 11.3909C123.97 11.0789 124.457 10.9229 125.075 10.9229C125.363 10.9229 125.629 10.9544 125.872 11.0174C126.115 11.0804 126.325 11.1809 126.502 11.3234C126.679 11.4644 126.817 11.6444 126.916 11.8619C127.013 12.0809 127.064 12.3433 127.064 12.6493V12.8293H125.822C125.822 12.5234 125.768 12.2879 125.662 12.1214C125.554 11.9579 125.374 11.8739 125.122 11.8739C124.978 11.8739 124.858 11.8964 124.762 11.9369C124.667 11.9789 124.589 12.0344 124.532 12.1034C124.475 12.1724 124.438 12.2518 124.417 12.3418C124.394 12.4318 124.385 12.5248 124.385 12.6208C124.385 12.8203 124.427 12.9853 124.511 13.1203C124.595 13.2568 124.775 13.3813 125.051 13.4938L126.05 13.9258C126.296 14.0338 126.497 14.1463 126.652 14.2648C126.808 14.3818 126.934 14.5078 127.025 14.6428C127.118 14.7778 127.183 14.9248 127.219 15.0883C127.255 15.2488 127.273 15.4303 127.273 15.6268C127.273 16.3048 127.076 16.7983 126.683 17.1073C126.29 17.4163 125.743 17.5723 125.041 17.5723C124.309 17.5723 123.785 17.4133 123.469 17.0953C123.154 16.7773 122.998 16.3213 122.998 15.7273V15.4663H124.294V15.6568Z"
                fill="white"
            />
            <path
                d="M2.71107 79H12.9949C13.6684 79 14.2905 79.3598 14.6266 79.9434L16.3191 82.2556C16.6552 82.8392 17.2774 83.199 17.9508 83.199H42.9999V109.598C42.9999 110.984 41.8759 112.108 40.4895 112.108H2.51044C1.124 112.108 0 110.984 0 109.598V86.1274C0 84.1869 0.299802 82.258 0.888845 80.409C1.10463 79.5792 1.85372 79 2.71107 79Z"
                fill="#CCCEE3"
            />
            <path d="M36.9657 82.7676H3.91309V96.73H36.9657V82.7676Z" fill="#EAEAF3" />
            <path
                d="M41.1172 80.3682H30.754C30.017 80.3682 29.3477 80.7982 29.0415 81.4685L27.2681 85.3496C26.9618 86.0199 26.2926 86.4499 25.5556 86.4499H0V110.338C0 111.724 1.124 112.848 2.51044 112.848H40.4896C41.876 112.848 43 111.724 43 110.338V82.251C43 81.2112 42.157 80.3682 41.1172 80.3682Z"
                fill="#DCDCED"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M150.298 80.9946C151.355 80.7815 152.43 80.6746 153.508 80.6746C162.459 80.6746 169.717 87.9108 169.717 96.8373C169.717 105.764 162.459 113 153.508 113H132.294C132.401 112.723 132.46 112.422 132.46 112.108V98.6321H137.007C137.759 98.6321 138.435 98.1778 138.724 97.4839C139.012 96.7902 138.853 95.9911 138.321 95.4596L127.921 85.0657C127.193 84.3413 126.016 84.3413 125.29 85.0657L114.889 95.4596C114.357 95.9911 114.197 96.7915 114.484 97.4839C114.774 98.1791 115.45 98.6321 116.203 98.6321H120.748V112.108C120.748 112.422 120.807 112.723 120.914 113H98.9887C90.9499 112.987 84.4062 106.549 84.2854 98.5341C84.1652 90.5194 90.5118 83.888 98.5462 83.6348C95.2164 74.4894 99.9521 64.3827 109.124 61.0624C118.296 57.742 128.431 62.4643 131.76 71.6103C135.44 69.9121 139.697 69.9889 143.312 71.8191C146.927 73.6493 149.502 77.0307 150.298 80.9946Z"
                fill="#DCDCED"
            />
            <path d="M217 77V113H238L247 104V77H217Z" fill="#EAEAF3" />
            <path d="M238 104V113L247 104H238Z" fill="#CCCEE3" />
            <path d="M229 113H238V104L229 113Z" fill="#DCDCED" />
            <path d="M211 86V96.5H235V86H211Z" fill="#897EC6" />
            <path
                d="M216.72 88.0488C217.05 88.0488 217.326 88.1043 217.552 88.2153C217.779 88.3263 217.959 88.4718 218.097 88.6518C218.235 88.8318 218.334 89.0373 218.394 89.2683C218.452 89.4978 218.484 89.7348 218.484 89.9748C218.484 90.3048 218.434 90.5928 218.335 90.8388C218.236 91.0848 218.098 91.2858 217.921 91.4403C217.744 91.5963 217.53 91.7148 217.278 91.7913C217.026 91.8678 216.745 91.9098 216.439 91.9098H215.746V94.4748H214.45V88.0488H216.72ZM216.322 90.9588C216.58 90.9588 216.79 90.8793 216.948 90.7233C217.107 90.5673 217.186 90.3243 217.186 89.9943C217.186 89.6703 217.117 89.4243 216.979 89.2563C216.841 89.0898 216.61 89.0043 216.286 89.0043H215.746V90.9588H216.322V90.9588Z"
                fill="white"
            />
            <path
                d="M219.568 89.9935C219.613 89.584 219.713 89.23 219.865 88.936C220.019 88.6435 220.238 88.4125 220.526 88.2475C220.813 88.084 221.201 88 221.686 88C222.172 88 222.559 88.084 222.847 88.2475C223.133 88.4125 223.355 88.6435 223.507 88.936C223.661 89.23 223.759 89.584 223.804 89.9935C223.849 90.4045 223.871 90.853 223.871 91.339C223.871 91.8295 223.849 92.281 223.804 92.6875C223.759 93.0955 223.66 93.4465 223.507 93.7405C223.354 94.0345 223.133 94.2595 222.847 94.4155C222.559 94.5715 222.172 94.6495 221.686 94.6495C221.201 94.6495 220.813 94.57 220.526 94.4155C220.238 94.2595 220.018 94.0345 219.865 93.7405C219.712 93.4465 219.613 93.0955 219.568 92.6875C219.524 92.281 219.5 91.8295 219.5 91.339C219.5 90.8545 219.524 90.406 219.568 89.9935ZM220.829 92.4415C220.85 92.7445 220.892 92.9905 220.955 93.1795C221.018 93.3685 221.108 93.5065 221.225 93.5935C221.342 93.6805 221.496 93.724 221.688 93.724C221.881 93.724 222.035 93.6805 222.154 93.5935C222.271 93.505 222.361 93.3685 222.424 93.1795C222.487 92.9905 222.529 92.7445 222.55 92.4415C222.571 92.1385 222.581 91.7725 222.581 91.3405C222.581 90.9085 222.569 90.5425 222.55 90.2425C222.53 89.9425 222.487 89.6965 222.424 89.5045C222.361 89.3125 222.271 89.173 222.154 89.0875C222.037 88.999 221.881 88.9555 221.688 88.9555C221.496 88.9555 221.343 88.999 221.225 89.0875C221.108 89.1745 221.018 89.3125 220.955 89.5045C220.892 89.6965 220.85 89.9425 220.829 90.2425C220.806 90.5425 220.798 90.9085 220.798 91.3405C220.798 91.7725 220.806 92.1385 220.829 92.4415Z"
                fill="white"
            />
            <defs>
                <clipPath id="clip0">
                    <rect width="36" height="36" fill="white" transform="translate(175 15)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(GridListEmptySVG);
