import React from 'react';
import Checkbox from 'components/checkbox/Base';
import Plus from 'assets/images/svg/diff/Plus';
import Minus from 'assets/images/svg/diff/Minus';
import classNames from 'classnames';

const defaultState = {
    UPDATE: 'update',
    DELETE: 'delete',
    NORMAL: 'normal'
};

function CheckboxDiffCheck({ data, state = defaultState.NORMAL, isHideMarkIcon = false }) {
    const checked = data ? true : false;

    return (
        <div
            className={classNames('inline-flex w-fit relative p-[1px] rounded ', {
                'border-[2px]': state !== defaultState.NORMAL,
                'border-brightRed': state === defaultState.DELETE,
                'border-shamRock': state === defaultState.UPDATE
            })}
        >
            <Checkbox
                checked={checked}
                style={{
                    maxWidth: 24,
                    maxHeight: 24
                }}
            />
            {state === defaultState.UPDATE && (
                <span className={'absolute -top-1 -right-1 flex'}>{!isHideMarkIcon && <Plus />}</span>
            )}
            {state === defaultState.DELETE && (
                <span className={'absolute -top-1 -right-1 flex'}>{!isHideMarkIcon && <Minus />}</span>
            )}
        </div>
    );
}

export default React.memo(CheckboxDiffCheck);
