import React from 'react';

function SourceStatusSVG({ color = '#78778B', ...rest }) {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 2.42831L12.4605 6.88059L12.4532 6.87323L12.4495 6.88059L9.07416 4.04424L10.1069 2.81325L12.2586 4.62423L16.042 0.122559L16 2.42831Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.368 14.546H3.137V1.255H1.368V14.546ZM3.822 0H0.685C0.307 0 0 0.281 0 0.627V15.173C0 15.518 0.307 15.8 0.685 15.8H3.822C4.199 15.8 4.506 15.518 4.506 15.173V0.627C4.506 0.281 4.199 0 3.822 0Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.65821 6.49V14.545H6.88921V1.37H10.0272V0.627C10.0272 0.281 9.72021 0 9.34321 0H6.20521C5.82821 0 5.52121 0.281 5.52121 0.627V15.172C5.52121 15.518 5.82821 15.8 6.20521 15.8H9.34321C9.72021 15.8 10.0272 15.518 10.0272 15.172V6.49H8.65821Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1787 6.49023V14.5452H12.4097V9.44923H11.0417V15.1722C11.0417 15.5182 11.3487 15.8002 11.7257 15.8002H14.8637C15.2407 15.8002 15.5477 15.5182 15.5477 15.1722V6.49023H14.1787Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(SourceStatusSVG);
