export const DASHBOARD_PAGE = '[SOCKET]DASHBOARD_PAGE';
export const GRID_UI_PAGE = '[SOCKET]GRID_UI_PAGE';
export const OTHER_PAGE = '[SOCKET]OTHER_PAGE';

export const PROJECT_DETAIL_HANDLER = '[SOCKET]PROJECT_DETAIL_HANDLER';
export const ADVANCED_SEARCH_HANDLER = '[SOCKET]ADVANCED_SEARCH_HANDLER';
export const DATABASES_HANDLER = '[SOCKET]DATABASES_HANDLER';
export const DATABASE_DETAIL_HANDLER = '[SOCKET]DATABASE_DETAIL_HANDLER';
export const GRIDS_HANDLER = '[SOCKET]GRIDS_HANDLER';
export const GRID_DETAIL_HANDLER = '[SOCKET]GRID_DETAIL_HANDLER';
export const GRID_USERS_HANDLER = '[SOCKET]GRID_USERS_HANDLER';
export const GRID_UI_DETAIL_HANDLER = '[SOCKET]GRID_UI_DETAIL_HANDLER';
export const USER_HANDLER = '[SOCKET]USER_HANDLER';
export const BRANCH_HANDLER = '[SOCKET]BRANCH_HANDLER';
export const VERSION_HANDLER = '[SOCKET]VERSION_HANDLER';
export const USER_VERSION_HANDLER = '[SOCKET]USER_VERSION_HANDLER';
export const NOTIFICATION_CENTER_HANDLER = '[SOCKET]NOTIFICATION_CENTER_HANDLER';
export const ADVANCED_SEARCH_GRIDS_DETAIL_HANDLER = '[SOCKET]ADVANCED_SEARCH_GRIDS_DETAIL_HANDLER';

export const DATABASE = 'DATABASE';
export const GRID = 'GRID';
export const VIEW = 'VIEW';
export const VIEW_FILTER = 'VIEW_FILTER';
export const VIEW_SORT = 'VIEW_SORT';
export const RECORD = 'RECORD';
export const COLUMN = 'COLUMN';
export const PASTE_COLUMNS = 'PASTE_COLUMNS';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const BULK_DELETE = 'BULK_DELETE';
export const GRIDLY_CREATE = 'GRIDLY_CREATE';
export const REORDER_COLUMNS = 'REORDER_COLUMNS';
export const REORDER_RECORDS = 'REORDER_RECORDS';
export const UPDATE_PARENT_PATH_START = 'UPDATE_PARENT_PATH_START';
export const UPDATE_PARENT_PATH_END = 'UPDATE_PARENT_PATH_END';
export const PASTE = 'PASTE';
export const AUTOFILL = 'AUTOFILL';
export const AUTOFILL_START = 'AUTOFILL_START';
export const AUTOFILL_END = 'AUTOFILL_END';
export const GROUP_ACCESS_VIEW = 'GROUP_ACCESS_VIEW';
export const CLEAN_COLUMN = 'CLEAN_COLUMN';
export const CLEAN_COLUMN_START = 'CLEAN_COLUMN_START';
export const CLEAN_COLUMN_END = 'CLEAN_COLUMN_END';
export const REPLACE_RECORD = 'REPLACE_RECORD';
export const REPLACE_RECORD_START = 'REPLACE_RECORD_START';
export const REPLACE_RECORD_END = 'REPLACE_RECORD_END';
export const AUTO_QA_END = 'AUTO_QA_END';
export const AUTO_QA = 'AUTO_QA';
export const PASTE_RECORDS = 'PASTE_RECORDS';
export const APPROVE_TM = 'APPROVE_TM';

export const CLEAR_RECORDS_START = 'CLEAR_RECORDS_START';
export const CLEAR_RECORDS_END = 'CLEAR_RECORDS_END';

// export const CLONE = 'CLONE';
// export const CLONE_GRID = 'CLONE_GRID';
// export const CLONE_GRID_START = 'CLONE_GRID_START';
// export const CLONE_GRID_END = 'CLONE_GRID_END';

//
export const BRANCHING = 'BRANCHING';
export const BRANCH_GRID = 'BRANCH_GRID';
export const BRANCH_GRID_START = 'BRANCH_GRID_START';
export const BRANCH_GRID_END = 'BRANCH_GRID_END';

export const DUPLICATE_GRID = 'DUPLICATE_GRID';
export const DUPLICATE_GRID_START = 'DUPLICATE_GRID_START';
export const DUPLICATE_GRID_END = 'DUPLICATE_GRID_END';

export const DUPLICATE_DATABASE = 'DUPLICATE_DATABASE';
export const DUPLICATE_DATABASE_START = 'DUPLICATE_DATABASE_START';
export const DUPLICATE_DATABASE_END = 'DUPLICATE_DATABASE_END';

export const ADD_FILE = 'ADD_FILE';

//status upload
export const UPLOAD = 'UPLOAD';
export const UPLOAD_END = 'UPLOAD_END';
export const UPLOADING = 'uploading';
export const ACTIVE = 'active';

//status restore
export const RESTORE = 'RESTORE';
export const BACKUP = 'BACKUP';

//column status
export const PASTE_COLUMNS_END = 'PASTE_COLUMNS_END';
export const PASTE_COLUMNS_START = 'PASTE_COLUMNS_START';
export const APPROVE_POPULATED_TEXT = 'APPROVE_POPULATED_TEXT';
export const APPROVE_POPULATED_TEXT_COLUMN_START = 'APPROVE_POPULATED_TEXT_COLUMN_START';
export const APPROVE_POPULATED_TEXT_COLUMN_END = 'APPROVE_POPULATED_TEXT_COLUMN_END';
export const MARK_COLUMN_DEPENDENCY_STATUS_START = 'MARK_COLUMN_DEPENDENCY_STATUS_START';
export const MARK_COLUMN_DEPENDENCY_STATUS_END = 'MARK_COLUMN_DEPENDENCY_STATUS_END';
export const MARK_COLUMN_SOURCE_STATUS_START = 'MARK_COLUMN_SOURCE_STATUS_START';
export const MARK_COLUMN_SOURCE_STATUS_END = 'MARK_COLUMN_SOURCE_STATUS_END';
export const SWITCH_TYPE = 'SWITCH_TYPE';
export const SWITCH_TYPE_START = 'SWITCH_TYPE_START';
export const SWITCH_TYPE_END = 'SWITCH_TYPE_END';

export const EXECUTE_FORMULA_COLUMN_START = 'EXECUTE_FORMULA_COLUMN_START';
export const EXECUTE_FORMULA_COLUMN_END = 'EXECUTE_FORMULA_COLUMN_END';

export const POPULATED_DATA = 'POPULATED_DATA';
//status upload
export const IMPORT = 'IMPORT';
export const PREVIEW_IMPORT = 'PREVIEW_IMPORT';
export const PREVIEW_TRANSLATION = 'PREVIEW_TRANSLATION';

export const SUB_OBJECT_TYPE_NOT_FOUND = false;

//status upload
export const MERGE_GRID = 'MERGE_GRID';
export const MERGE_GRID_START = 'MERGE_GRID_START';
export const MERGE_GRID_END = 'MERGE_GRID_END';
export const PREVIEW_MERGE = 'PREVIEW_MERGE';
export const PREVIEW_MERGE_END = 'PREVIEW_MERGE_END';
export const PREVIEW_TRANSLATION_END = 'PREVIEW_TRANSLATION_END';

export const DEPENDENCY = 'DEPENDENCY';

//undo redo
export const UNDO_CREATE = 'UNDO_CREATE';
export const UNDO_UPDATE = 'UNDO_UPDATE';
export const UNDO_DELETE = 'UNDO_DELETE';
export const UNDO_REORDER = 'UNDO_REORDER';

export const REDO_CREATE = 'REDO_CREATE';
export const REDO_UPDATE = 'REDO_UPDATE';
export const REDO_DELETE = 'REDO_DELETE';
export const REDO_REORDER = 'REDO_REORDER';

export const REDO_PASTE_COLUMN_END = 'REDO_PASTE_COLUMN_END';
export const UNDO_PASTE_COLUMN_END = 'UNDO_PASTE_COLUMN_END';

export const UNDO_CLEAN_COLUMN_START = 'UNDO_CLEAN_COLUMN_START';
export const UNDO_CLEAN_COLUMN_END = 'UNDO_CLEAN_COLUMN_END';

export const TAG = 'TAG';
export const CREATE_PATH_VIA_SET_RECORD = 'CREATE_PATH_VIA_SET_RECORD';
