import React from 'react';
import * as gridUIActions from './actions';
import { fetchGridsByDatabase } from 'grids/actions';
import { getDatabase } from '../databases/actions';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { HEADER_HEIGHT } from 'const';
import { GLOBAL_FILTER_HEIGHT, SIDEBAR_LEFT_CONTENT, LQA_TICKET_STATUS, ROW_HEIGHT } from 'const/gridUI';
import { useDatabaseList } from 'hooks/database';
import {
    useGridUIIsLoading,
    useIsPathTagOn,
    useIsDraggingRows,
    useTableInfo,
    useTotalRecords,
    useIsSortingIsOn,
    useColumnUploadFolderStatus,
    useRowHeight,
    useRowsRangeIndexes
} from 'hooks/gridUI';
import { checkContainClassName, checkContainId } from 'utils/clickAway';

import GridUITable from './table';
import * as versionControlActions from 'versionControl/actions/branch';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as paymentActions from 'payment/actions';
import PathTag from './pathTag';
import MultipleRowSVG from 'assets/images/svg/MultipleRowSVG';
import SingleRowSVG from 'assets/images/svg/SingleRowSVG';
import * as dataCollectorActions from 'dataCollector/actions';
import * as addonActions from 'addons/actions';
import * as appActions from 'app/actions';
import * as roleConst from 'auth/roleConst';
import { getTotalRowsHeight } from 'utils/gridUI/row';
import MultipleFileUpload from './MultipleFileUpload';
import { isRowIndexInRange, getTotalSelectedRowsByRange } from 'utils/gridUI/range';
import { useCompanyId } from 'hooks/auth';
import TicketDetail from './lqa/TicketDetail';
import ConfirmDeleteTicket from './lqa/ConfirmDeleteTicket';
import { useCompanyInfo } from 'hooks/app';
import { fetchTagsActionSuccess } from './actions';

function GridUI({ roles, workspaceId, gridId, viewId, dbId, branchId, isShareViewLink, t }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const rootRef = React.useRef();
    const accessEditRecords = roles[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const isLoading = useGridUIIsLoading();

    const isPathTagOpen = useIsPathTagOn();
    const databases = useDatabaseList() || [];
    const database = databases.find(dbItem => dbItem.id === dbId);
    const isRowDragging = useIsDraggingRows();
    const tableInfo = useTableInfo();
    const totalRecords = useTotalRecords();
    const isSortingOn = useIsSortingIsOn();
    const columnUploadFolderStatus = useColumnUploadFolderStatus();
    const rowsRangeIndexes = useRowsRangeIndexes();

    const [scrollTopMore, setScrollTopMore] = React.useState(false);
    const [scrollBottomMore, setScrollBottomMore] = React.useState(false);
    const rowHeight = useRowHeight();
    const companyId = useCompanyId();
    const companyInfo = useCompanyInfo();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    React.useEffect(() => {
        dispatch(
            gridUIActions.fetchViewSorts({
                dbId,
                viewId,
                successCallback: () => {
                    console.log('fetching view sorts done');
                }
            })
        );
    }, [dispatch, dbId, viewId]);

    React.useEffect(() => {
        dispatch(
            gridUIActions.setCopiedRange({
                columnStartIndex: -1,
                rowStartIndex: -1,
                rowStopIndex: -1,
                columnStopIndex: -1
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId, viewId]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                gridUIActions.fetchApiKey({
                    successCallback: () => {
                        console.log('fetchApiKey success');
                    },
                    errorCallback: () => {
                        console.log('fetchApiKey failed');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                gridUIActions.fetchTagModels({
                    successCallback: () => {
                        console.log('fetchTagModels success');
                    },
                    errorCallback: () => {
                        console.log('fetchTagModels failed');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                gridUIActions.fetchViews({
                    branchId,
                    successCallback: () => {
                        console.log('fetchApiKey success');
                    },
                    errorCallback: () => {
                        console.log('fetchApiKey failed');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink, branchId]);

    React.useEffect(() => {
        dispatch(fetchTagsActionSuccess({ tree: [] }));
        dispatch(
            gridUIActions.fetchTags({
                dbId,
                branchId,
                successCallback: () => {
                    console.log('fetch tags success');
                },
                errorCallback: () => {
                    console.log('fetch tags failed');
                }
            })
        );

        return () => {
            console.log('unmounted');
        };
    }, [dispatch, branchId, dbId]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                gridUIActions.fetchTMId({
                    successCallback: () => {
                        console.log('fetchTMId success');
                    },
                    errorCallback: () => {
                        console.log('fetchTMId failed');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                fetchGridsByDatabase({
                    dbId,
                    successCallback: () => {
                        console.log('fetchGridList success');
                    },
                    errorCallback: () => {
                        console.log('fetchGridList failed');
                    }
                })
            );
        }
    }, [dispatch, dbId, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                versionControlActions.fetchingGridBranches({
                    dbId,
                    gridId,
                    query: {
                        includeBasedGrid: true
                    },
                    errorCallback: () => {
                        console.log('fetch grid branch failed');
                    },
                    successCallback: () => {
                        console.log('fetch grid branch success');
                    }
                })
            );
        }
    }, [dispatch, dbId, gridId, isShareViewLink]);

    React.useEffect(() => {
        if (isShareViewLink || companyInfo) return;
        dispatch(
            appActions.fetchCompanyInfo({
                successCallback: () => {
                    console.log('fetch company info successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch company info');
                }
            })
        );
    }, [dispatch, isShareViewLink, companyInfo, companyId]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                paymentActions.fetchPlans({
                    errorCallback: () => {
                        console.log('fetchPlans failed');
                    },
                    successCallback: () => {
                        console.log('fetchPlans successfully');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                gridUIActions.getTotalGridRecords({
                    dbId,
                    branchId,
                    errorCallback: () => {
                        console.log('getTotalGridRecords failed');
                    },
                    successCallback: () => {
                        console.log('getTotalGridRecords successfully');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink, dbId, branchId]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                dataCollectorActions.getUsage({
                    successCallback: () => {
                        console.log('fetch usage Successfully');
                    },
                    errorCallback: () => {
                        console.log('fetch usage failed');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!database && !isShareViewLink) {
            dispatch(getDatabase(dbId));
        }
    }, [dbId, database, dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (workspaceId && !isShareViewLink) {
            dispatch(
                paymentActions.fetchCustomerAndSubscriptions({
                    successCallback: () => {
                        console.log('fetchCustomerAndSubscriptions success');
                    },
                    errorCallback: () => {
                        console.log('fetchCustomerAndSubscriptions failed');
                    }
                })
            );
        }
    }, [dispatch, workspaceId, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                addonActions.getAddonsList({
                    successCallback: () => {
                        console.log('fetchSubscriptions Success');
                    },
                    errorCallback: () => {
                        console.log('fetchSubscriptions failed');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            dispatch(
                paymentActions.fetchCardInfo({
                    errorCallback: () => {
                        console.log('fetchCardInfo failed');
                    },
                    successCallback: () => {
                        console.log('fetchCardInfo success');
                    }
                })
            );
        }
    }, [dispatch, isShareViewLink]);

    React.useEffect(() => {
        if (!isShareViewLink) {
            if (window.timerLqa) clearTimeout(window.timerLqa);
            window.timerLqa = setTimeout(() => {
                dispatch(gridUIActions.fetchLQADefault({}));
                dispatch(gridUIActions.resetDefaultLQA({}));
                dispatch(
                    gridUIActions.fetchLQATicketsTotal({
                        dbId,
                        viewId,
                        params: {
                            status: [LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.REOPENED],
                            pageSize: 1
                        },
                        totalKey: LQA_TICKET_STATUS.OPEN,
                        storeOnlyTotal: true
                    })
                );
            }, 1000);
        }
    }, [dispatch, isShareViewLink, dbId, viewId]);

    const handleClickAway = React.useCallback(
        e => {
            if (
                checkContainId(e, 'sidebar-right-lqa') ||
                checkContainId(e, 'sidebar-right-content-lqa') ||
                checkContainId(e, 'search-record')
            ) {
                return false;
            }
            if (checkContainId(e, 'cell_context_menu')) {
                return false;
            }
            if (checkContainId(e, 'comment-emoji')) {
                return false;
            }
            if (checkContainId(e, 'comment_edit_emoji')) {
                return false;
            }

            if (checkContainId(e, 'rows_popup_context')) {
                return false;
            }

            if (checkContainId(e, 'cell_auto_expand')) {
                return false;
            }
            if (checkContainId(e, 'fill-color-panel-trigger') || checkContainId(e, 'popper-fill-color')) {
                return false;
            }
            if (checkContainId(e, 'file-previews')) {
                return false;
            }
            if (checkContainId(e, 'trigger-comment')) {
                return false;
            }
            if (checkContainId(e, 'more-actions-trigger') || checkContainId(e, 'popper-more-actions')) {
                return false;
            }
            if (checkContainId(e, 'portal-mention-users')) {
                return false;
            }

            if (checkContainId(e, 'select-target-language')) {
                return false;
            }

            if (checkContainId(e, 'lqa-suggestion')) {
                return false;
            }

            if (checkContainId(e, 'confirm-column-box')) {
                return false;
            }

            if (
                checkContainId(e, 'confirm-retranslate-popper') ||
                checkContainId(e, 'calendar-portal') ||
                checkContainId(e, 'choose-selection') ||
                checkContainClassName(e, 'react-datepicker-popper')
            ) {
                return false;
            }

            if (checkContainId(e, 'translation-edit-popper')) {
                return false;
            }

            if (checkContainId(e, 'transitions-popper')) {
                return false;
            }

            if (checkContainId(e, 'play-file-actions')) {
                return false;
            }

            if (checkContainId(e, 'portal-popper')) {
                return false;
            }

            if (checkContainId(e, 'search_record') || checkContainId(e, 'find_in_this_column')) {
                return false;
            }

            dispatch(gridUIActions.resetCellStatus());
        },
        [
            // isOpenCellIssue,
            dispatch
            // isOpenCellEdit,
            // columnsSelected,
            // isOpenCellPreview
            // isRangeSelected,
            // isOpenViewCellTicket
        ]
    );

    const handleMouseStop = React.useCallback(
        e => {
            setScrollTopMore(false);
            setScrollBottomMore(false);
            let dropReview = document.getElementById('dropReview');
            if (dropReview) {
                dropReview.style.left = `-10000px`;
                dropReview.style.top = `-10000px`;
            }
            if (isRowDragging) {
                dispatch(gridUIActions.turnOffDraggingRows());
                dispatch(gridUIActions.handleDropRows({ isSortingOn }));
            }
        },
        [isRowDragging, dispatch, isSortingOn]
    );

    const TOTAL_RECORD_HEIGHT = React.useMemo(() => {
        return getTotalRowsHeight({ rowHeight, totalRecords });
    }, [rowHeight, totalRecords]);

    const handleMouseMove = React.useCallback(
        e => {
            if (!isRowDragging) return;
            let dropReview = document.getElementById('dropReview');

            const left = e.clientX;
            const top = e.clientY;

            if (dropReview) {
                dropReview.style.left = `${left - 14}px`;
                dropReview.style.top = `${top - HEADER_HEIGHT - 20}px`;
            }

            const value = e.target && e.target.classList && e.target.classList.value;
            const matchesPathTag = value.match(/PATH_TAG([^&]*)_([^&]*) ?/);
            const matchesRecord = value.match(/RECORD([^&]*)_([^&]*) ?/);
            const pathTagClassName = matchesPathTag && matchesPathTag[0].split(' ');
            const recordClassName = matchesRecord && matchesRecord[0].split(' ');

            /**
             * Scroll more information
             */
            const TOP_SCROLL = HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT + 2 * ROW_HEIGHT;
            const height = tableInfo?.height;
            const gridRef = tableInfo?.gridRef;
            const state = gridRef?.state;
            const scrollTop = state?.scrollTop || 0;

            const BOTTOM_SCROLL = TOP_SCROLL + height - 2 * ROW_HEIGHT;

            if (top < TOP_SCROLL && scrollTop !== 0) {
                setScrollTopMore(true);
            } else {
                setScrollTopMore(false);
            }

            if (top > BOTTOM_SCROLL && scrollTop !== TOTAL_RECORD_HEIGHT) {
                setScrollBottomMore(true);
            } else {
                setScrollBottomMore(false);
            }

            if (pathTagClassName && pathTagClassName[0].includes('PATH_TAG_')) {
                const currentSelected = pathTagClassName[0];
                const nodeId = currentSelected?.split(`PATH_TAG_`)?.[1];
                if (nodeId && accessEditRecords === roleConst.FULL) {
                    dispatch(gridUIActions.setDropPath({ nodeId }));
                    dispatch(gridUIActions.expandWhenHover({ nodeId }));
                }
            } else if (recordClassName && recordClassName[0].includes('RECORD_')) {
                const currentSelected = recordClassName[0];

                const positionTopAttr = e.target.getAttribute(`pst`) || 0;
                const positionBottomAttr = e.target.getAttribute(`psb`) || 0;
                const previousRowId = e.target.getAttribute(`prid`);
                const nextRowId = e.target.getAttribute(`nrid`);
                const rowIndex = e.target.getAttribute(`rindex`);
                const recordId = currentSelected?.split(`RECORD_`)?.[1];

                const positionTop = Math.abs(positionTopAttr - scrollTop);
                const positionBottom = Math.abs(positionBottomAttr - scrollTop);

                if (accessEditRecords !== roleConst.FULL) {
                    console.log("you don't have permission to drop row");
                    return;
                }

                if (isSortingOn) {
                    console.log("you can't drop when sorting is on");
                    return;
                }

                if (recordId) {
                    // if (isRowIndexInRange({ ranges: rowsRangeIndexes, rowIndex: +rowIndex })) {
                    //     return;
                    // }

                    const movingTop = top - (HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT + 2 * ROW_HEIGHT);
                    const distanceAB = Math.abs(Number(positionBottom) + Number(positionTop));
                    const haft = distanceAB === 0 ? 0 : distanceAB / 2;

                    const rangeIndexes = rowsRangeIndexes?.flat();

                    const rowIndexNumber = +rowIndex;
                    let min = Math.min(...rangeIndexes);
                    let max = Math.max(...rangeIndexes);

                    if (rowIndexNumber > min && rowIndexNumber < max) {
                        console.log('do nothing if drop index is between min, max. BE not handle this case');
                        return;
                    }

                    const isMoveDown = rowIndexNumber >= max;

                    if (movingTop > haft) {
                        if (isRowIndexInRange({ ranges: rowsRangeIndexes, rowIndex: +rowIndex + 1 })) {
                            return;
                        }

                        if (nextRowId) {
                            if (isMoveDown) {
                                dispatch(
                                    gridUIActions.setDropRecordId({
                                        afterRecordId: recordId,
                                        beforeRecordId: null,
                                        currentRecordId: recordId,
                                        highlight: 'bottom',
                                        currentRowIndex: rowIndex
                                    })
                                );
                            } else {
                                dispatch(
                                    gridUIActions.setDropRecordId({
                                        afterRecordId: null,
                                        beforeRecordId: nextRowId,
                                        currentRecordId: recordId,
                                        highlight: 'bottom',
                                        currentRowIndex: rowIndex
                                    })
                                );
                            }
                        } else {
                            dispatch(
                                gridUIActions.setDropRecordId({
                                    afterRecordId: recordId,
                                    beforeRecordId: null,
                                    currentRecordId: recordId,
                                    highlight: 'bottom',
                                    currentRowIndex: rowIndex
                                })
                            );
                        }
                    } else {
                        if (isRowIndexInRange({ ranges: rowsRangeIndexes, rowIndex: +rowIndex - 1 })) {
                            return;
                        }

                        if (previousRowId) {
                            if (isMoveDown) {
                                dispatch(
                                    gridUIActions.setDropRecordId({
                                        afterRecordId: previousRowId,
                                        beforeRecordId: null,
                                        currentRecordId: recordId,
                                        highlight: 'top',
                                        currentRowIndex: rowIndex
                                    })
                                );
                            } else {
                                dispatch(
                                    gridUIActions.setDropRecordId({
                                        afterRecordId: null,
                                        beforeRecordId: recordId,
                                        currentRecordId: recordId,
                                        highlight: 'top',
                                        currentRowIndex: rowIndex
                                    })
                                );
                            }
                        } else {
                            dispatch(
                                gridUIActions.setDropRecordId({
                                    afterRecordId: null,
                                    beforeRecordId: recordId,
                                    currentRecordId: recordId,
                                    highlight: 'top',
                                    currentRowIndex: rowIndex
                                })
                            );
                        }
                    }
                }
            } else {
                dispatch(gridUIActions.setDropPath({ nodeId: null }));
                dispatch(
                    gridUIActions.setDropRecordId({
                        afterRecordId: null,
                        beforeRecordId: null,
                        currentRecordId: null,
                        highlight: null,
                        currentRowIndex: null
                    })
                );
            }
        },
        [rowsRangeIndexes, isRowDragging, dispatch, accessEditRecords, TOTAL_RECORD_HEIGHT, tableInfo, isSortingOn]
    );

    const generateDurationBaseDistance = distance => {
        //1s = 400px
        const HEIGHT_DEFAULT = 600;
        return Math.round((distance / HEIGHT_DEFAULT) * 1000, 0);
    };

    const scrollTo = React.useCallback(({ element, to, duration, isDisabledScroll }) => {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        const changeABS = Math.abs(change);

        let animateScroll = function() {
            if (isDisabledScroll) {
                clearTimeout(scrollXTimerRef && scrollXTimerRef.current);
                return;
            }

            if (currentTime < changeABS) {
                currentTime += increment;
                let val = Math.linearTween(currentTime, start, change, changeABS);
                element.scrollTop = val;
                scrollXTimerRef.current = setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }, []);

    const scrollXTimerRef = React.useRef();
    React.useEffect(() => {
        const gridRef = tableInfo?.gridRef;
        const state = gridRef?.state;
        const scrollTop = state?.scrollTop;
        const MainGridUI = document.getElementById('RightCell');
        if (scrollTop === undefined) return;
        if (!MainGridUI) return;

        if (scrollTop !== 0) {
            scrollTo({
                element: MainGridUI,
                to: 0,
                duration: generateDurationBaseDistance(scrollTop),
                isDisabledScroll: !scrollTopMore
            });
        }
    }, [scrollTopMore, scrollTo, tableInfo]);

    React.useEffect(() => {
        const gridRef = tableInfo?.gridRef;
        const state = gridRef?.state;
        const scrollTop = state?.scrollTop;
        const MainGridUI = document.getElementById('RightCell');

        if (scrollTop === undefined) return;
        if (!MainGridUI) return;

        if (scrollTop !== TOTAL_RECORD_HEIGHT) {
            scrollTo({
                element: MainGridUI,
                to: TOTAL_RECORD_HEIGHT,
                duration: generateDurationBaseDistance(TOTAL_RECORD_HEIGHT),
                isDisabledScroll: !scrollBottomMore
            });
        }
    }, [scrollBottomMore, TOTAL_RECORD_HEIGHT, tableInfo, scrollTo]);

    Math.easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    Math.linearTween = function(t, b, c, d) {
        return (c * t) / d + b;
    };

    const totalSelectedRows = React.useMemo(() => {
        return getTotalSelectedRowsByRange(rowsRangeIndexes);
    }, [rowsRangeIndexes]);

    const isDragMultiple = React.useMemo(() => {
        return totalSelectedRows > 1;
    }, [totalSelectedRows]);

    // if (isLoading) {
    //     return <FallBack />;
    // }

    return (
        <>
            <div
                onMouseUp={handleMouseStop}
                onMouseMove={handleMouseMove}
                container
                className={'flex-1 bg-background relative'}
            >
                <div ref={rootRef} item className={`h-full w-full`}>
                    <div className="flex flex-row h-full items-stretch">
                        {isPathTagOpen && !isLoading && (
                            <div className={`bg-white w-[${SIDEBAR_LEFT_CONTENT}px] border-r border-grey-border`}>
                                <PathTag isShareViewLink={isShareViewLink} t={t} />
                            </div>
                        )}
                        <div item className={'flex-1 h-full'}>
                            <GridUITable
                                t={t}
                                defaultAccessViewId={viewId}
                                gridId={gridId}
                                workspaceId={workspaceId}
                                viewId={viewId}
                                dbId={dbId}
                                branchId={branchId}
                                roles={roles}
                                isShareViewLink={isShareViewLink}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="dropReview"
                style={{
                    position: 'absolute',
                    top: -10000,
                    left: -10000,
                    zIndex: theme.zIndex.drawer + 1,
                    boxShadow: theme.shadows[1],
                    pointerEvents: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {isDragMultiple ? <MultipleRowSVG /> : <SingleRowSVG />}
                {isDragMultiple && (
                    <span
                        style={{
                            fontSize: 9,
                            position: 'absolute',
                            fontWeight: 'bold',
                            top: -4,
                            right: -4,
                            padding: `1px 6px`,
                            background: theme.colors.highlight,
                            color: theme.colors.white,
                            borderRadius: 4
                        }}
                    >
                        {totalSelectedRows}
                    </span>
                )}
            </div>
            <div className={'flex flex-col gap-3.5 fixed right-5 bottom-5 z-[1000] max-w-[440px]'}>
                {columnUploadFolderStatus.map(column => (
                    <div key={column.id}>
                        <MultipleFileUpload t={t} column={column} />
                    </div>
                ))}
            </div>
            {!isShareViewLink && typeof isLoading == 'boolean' && !isLoading && (
                <>
                    <TicketDetail dbId={dbId} />
                    <ConfirmDeleteTicket />
                </>
            )}
        </>
    );
}

export default React.memo(GridUI);
