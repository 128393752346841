import { makeStyles, Grid, Typography, CircularProgress } from '@material-ui/core';
import { useCompanyId } from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SettingContentLayout from 'permission/common/Layout';
import InputText from 'components/inputs/InputText';
import Checkbox from 'components/checkbox/Base';
import { getCompanyEnforcePasswordSetting, updateCompanyEnforcePasswordSetting } from 'services/company';
import ButtonBase from 'components/button/Base';
import { enqueueSnackbar } from 'notifier/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    inputText: {
        width: 208
    },
    mt1: {
        marginTop: theme.spacing(1)
    },
    mt2: {
        marginTop: theme.spacing(2)
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mt4: {
        marginTop: theme.spacing(4)
    }
}));

function SecurityPassword() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const companyId = useCompanyId();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [enforcePasswordExpire, setEnforcePasswordExpire] = useState(false);
    const [passwordValidityDays, setPasswordValidityDays] = useState(365);
    const [notifyPasswordBeforeExpireDays, setNotifyPasswordBeforeExpireDays] = useState(14);

    useEffect(() => {
        async function fetchCompanyEnforcePasswordSetting() {
            try {
                const setting = await getCompanyEnforcePasswordSetting(companyId);
                setEnforcePasswordExpire(setting?.enforcePasswordExpire);
                setPasswordValidityDays(setting?.enforcePasswordExpire ? setting?.passwordValidityDays : 365);
                setNotifyPasswordBeforeExpireDays(
                    setting?.enforcePasswordExpire ? setting.notifyPasswordBeforeExpireDays : 14
                );
            } catch (error) {
                const { message } = error;
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
            }
        }
        fetchCompanyEnforcePasswordSetting();
    }, [companyId, dispatch]);

    const handleBack = () => {
        history.push(`/company-settings/security`);
    };

    const handleSave = async () => {
        try {
            const body = {
                enforcePasswordExpire,
                passwordValidityDays,
                notifyPasswordBeforeExpireDays
            };
            setIsLoading(true);
            await updateCompanyEnforcePasswordSetting(companyId, body);
            setIsLoading(false);
            dispatch(
                enqueueSnackbar({
                    message: 'Your password setting has been saved',
                    type: 'info'
                })
            );
        } catch (error) {
            setIsLoading(false);
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };

    return (
        <SettingContentLayout
            contentClassName={'content-medium'}
            name={t('password_settings')}
            height={'auto'}
            onBack={handleBack}
        >
            <Grid container direction="column" className={classes.root}>
                <Grid item>
                    <Typography variant="h4">{t('change_the_password_policy')}</Typography>
                </Grid>
                <Grid item container alignItems="center" className={classes.mt4}>
                    <Grid item style={{ marginLeft: -9 }}>
                        <Checkbox
                            checked={enforcePasswordExpire}
                            onChange={e => setEnforcePasswordExpire(e.target.checked)}
                            name="enforcePassword"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('enable_forcible_password_change_policy')}</Typography>
                    </Grid>
                </Grid>
                <Grid item className={classes.mt3}>
                    <Typography variant="body2">{t('password_validity_period')}</Typography>
                </Grid>
                <Grid item className={classes.mt1}>
                    <InputText
                        className={classes.inputText}
                        disabled={!enforcePasswordExpire}
                        name="name"
                        type="number"
                        value={passwordValidityDays}
                        onChange={e => setPasswordValidityDays(parseInt(e.target.value))}
                        placeholder="0"
                        inputProps={{ min: 1 }}
                        endAdornment={t('day(s)')}
                    />
                </Grid>
                <Grid item className={classes.mt3}>
                    <Typography variant="body2">
                        Days before a user is notified that their password will expire
                    </Typography>
                </Grid>
                <Grid item className={classes.mt1}>
                    <InputText
                        className={classes.inputText}
                        disabled={!enforcePasswordExpire}
                        name="name"
                        type="number"
                        value={notifyPasswordBeforeExpireDays}
                        onChange={e => setNotifyPasswordBeforeExpireDays(parseInt(e.target.value))}
                        placeholder="0"
                        inputProps={{ min: -1 }}
                        endAdornment={t('day(s)')}
                    />
                </Grid>
                <Grid item container alignItems="center" spacing={2} className={classes.mt4}>
                    <Grid item>
                        <ButtonBase onClick={handleBack} width={100} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={handleSave} disabled={isLoading} width={100} variant="contained">
                            {isLoading ? <CircularProgress size={24} /> : t(`global_save`)}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
}

export default React.memo(SecurityPassword);
