import React from 'react';

function InternalUserSVG() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="#36A6B3" />
            <path
                d="M7.52653 1.94987C6.03618 1.92093 5.33441 2.86868 4.46624 3.74408C4.16962 4.0407 4.16962 4.51819 4.46624 4.81482L6.36174 6.71032C6.65837 7.00694 7.13586 7.00694 7.43248 6.71032C7.72911 6.41369 7.72911 5.9362 7.43248 5.63958L6.07959 4.27945L6.43409 3.92495C6.74518 3.61385 7.09245 3.48363 7.50483 3.46916C8.54663 3.50533 9.16882 4.43138 8.92284 5.34295C8.87219 5.54552 8.72026 5.7915 8.64792 5.87832C8.59004 5.94343 8.65515 5.87109 8.5394 5.99408C8.44534 6.08813 8.09084 6.4354 7.77975 6.74649C7.83763 6.73202 7.8955 6.73202 7.96062 6.73202C8.19936 6.73202 8.43087 6.82607 8.59727 6.9997C8.82878 7.23122 8.90837 7.54954 8.83602 7.83893C9.2484 7.42655 9.20499 7.46996 9.5812 7.09376C11.4984 5.16208 10.2034 2.02221 7.52653 1.94987Z"
                fill="white"
            />
            <path
                d="M2.79497 6.47161C2.79497 6.47161 3.12777 6.80441 3.61973 7.29637C4.23468 7.91132 4.97986 8.6565 5.0088 8.68544C4.97986 8.6565 5.00157 8.67821 4.99433 8.67097C5.06668 8.73608 5.12456 8.78673 5.23308 8.8229C5.42118 8.88801 5.63822 8.85184 5.79015 8.72161C5.83356 8.68544 5.6744 8.83737 7.41796 7.0938C7.71459 6.79718 8.19208 6.79718 8.4887 7.0938C8.78533 7.39042 8.78533 7.86792 8.4887 8.16454C6.76684 9.8864 6.87536 9.78512 6.7596 9.87917C5.93484 10.5809 4.71941 10.5086 3.97423 9.79959C3.91636 9.74171 4.11893 9.94428 1.717 7.54235C1.42761 7.25296 1.42761 6.76824 1.72424 6.47161C2.02086 6.17499 2.49835 6.17499 2.79497 6.47161Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(InternalUserSVG);
