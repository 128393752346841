import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
// import * as gridActions from 'gridUI/actions';
import { INPUT_HEIGHT, SPACING_LIST_NAME_WITH_ICON, DISABLED_OPACITY } from 'const/style';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import RemoveSVG from 'assets/images/svg/RemoveSVG';
import AToZSVG from 'assets/images/svg/AToZSVG';
import ZToASVG from 'assets/images/svg/ZToASVG';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import LDBasePortal from 'components/selects/LDBasePortal';
import * as columnTypes from 'const/columnTypes';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { useTranslation } from 'react-i18next';

const SORT_COLUMN_WIDTH = 200;

const useStyles = makeStyles(theme => ({
    root: {},
    buttonContainer: {
        height: 36,
        background: '#fff',
        borderRadius: 4,
        border: `1px solid #D4CEEC`,
        overflow: 'hidden'
    },
    button: {
        padding: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    active: {
        background: theme.palette.primary.main,
        '& path': {
            fill: theme.colors.white
        }
    },
    buttonGroup: {
        display: 'flex',
        alignItems: 'center',
        maxHeight: INPUT_HEIGHT
    },
    icon: {
        marginRight: SPACING_LIST_NAME_WITH_ICON,
        maxWidth: 18,
        color: theme.colors.steel
    },
    selected: {
        background: `${theme.palette.primary.main} !important`,
        '& p': {
            color: theme.colors.white
        },
        '& g': {
            fill: theme.colors.white
        },
        '&:hover': {
            background: props => (props.selectedDirection ? theme.palette.primary.main : '')
        }
    },
    dropdown: {
        '& .popper': {
            width: '300px !important'
        }
    },
    dropdownClassName: {
        width: SORT_COLUMN_WIDTH
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    close: {
        cursor: 'pointer'
    },
    dpFlex: {
        display: 'flex'
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    }
}));

function SortOrderItem({
    id,
    direction,
    sortOrder,
    metaData,
    columns,
    deleteViewSort,
    updateViewSortState,
    updateViewSort,
    isReadOnly
}) {
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedDirection, setSelectedDirection] = useState(direction);
    const classes = useStyles({ selectedDirection });
    const { t } = useTranslation();

    const options = React.useMemo(() => {
        return columns?.map(column => {
            let columnType = getCorrectColumnType(column);
            return {
                value: column.hashColumnId,
                label: column.name,
                column: column,
                type: columnType,
                icon: () => (
                    <ColumnIcon group={column?.group} type={columnType} customProperties={column.customProperties} />
                )
            };
        });
    }, [columns]);

    useEffect(() => {
        const { hashColumnId } = sortOrder;
        const column = metaData?.[hashColumnId];
        const defaultSelectedColumn = {
            label: column?.name,
            value: column?.hashColumnId,
            type: column?.type,
            column,
            icon: () => (
                <ColumnIcon group={column?.group} type={column?.type} customProperties={column?.customProperties} />
            )
        };
        setSelectedColumn(defaultSelectedColumn);
    }, [sortOrder, metaData]);

    useEffect(() => {
        setSelectedDirection(direction);
    }, [direction]);

    const handleDescendingClick = () => {
        let direction = selectedDirection === 'desc' ? '' : 'desc';
        setSelectedDirection(direction);
        updateViewSortOrderAndFetchingData({
            direction,
            hashColumnId: selectedColumn.value
        });
    };

    const handleAscendingClick = () => {
        let direction = selectedDirection === 'asc' ? '' : 'asc';
        setSelectedDirection(direction);
        updateViewSortOrderAndFetchingData({
            direction,
            hashColumnId: selectedColumn.value
        });
    };

    const deleteViewSortOrderHandler = () => {
        sendManualTrack({
            type: `Delete View Sort`,
            customData: {
                sortOrderId: sortOrder.id,
                direction: selectedDirection
            }
        });

        deleteViewSort({
            id: sortOrder?.id,
            direction: selectedDirection
        });
    };

    const handleColumnChange = option => {
        setSelectedColumn(option);
        if (selectedDirection) {
            updateViewSortOrderAndFetchingData({
                hashColumnId: option.value,
                direction: selectedDirection
            });
        } else {
            updateViewSortOrderOnly({
                hashColumnId: option.value,
                direction: ''
            });
        }
    };

    const updateViewSortOrderAndFetchingData = ({ hashColumnId, direction }) => {
        updateViewSort({
            id,
            newSort: {
                ...sortOrder,
                direction,
                hashColumnId
            }
        });
    };

    const updateViewSortOrderOnly = ({ hashColumnId, direction }) => {
        updateViewSortState({
            id: sortOrder.id,
            newSort: {
                ...sortOrder,
                hashColumnId,
                direction,
                oldValue: direction
            }
        });
    };

    const sortOptions = React.useMemo(() => {
        return options?.filter(col => ![columnTypes.PATH_TAG]?.includes(col?.value));
    }, [options]);

    return (
        <Grid container className={classes.root} spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
                <Grid container justify="flex-start">
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ``
                            )
                        }
                    >
                        <Grid item className={classes.dpFlex}>
                            <RemoveSVG
                                onClick={deleteViewSortOrderHandler}
                                className={`${classes.close} ${isReadOnly ? classes.disabled : ''}`}
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            <Tooltip
                title={
                    isReadOnly ? (
                        <Grid container className={classes.permissionTooltip}>
                            {t('toolbar_no_permission')}
                        </Grid>
                    ) : (
                        ``
                    )
                }
            >
                <Grid item className={classes.dropdown}>
                    <LDBasePortal
                        ddPlaceholder={'Select a column'}
                        menuPlaceholder={t('global_label_find_a_column')}
                        options={sortOptions}
                        isMulti={false}
                        isUsingContainer={true}
                        isDisabled={isReadOnly}
                        dropdownClassName={`${classes.dropdownClassName}`}
                        defaultValue={selectedColumn}
                        placement="bottom-start"
                        onChange={handleColumnChange}
                    />
                </Grid>
            </Tooltip>
            <Tooltip
                title={
                    isReadOnly ? (
                        <Grid container className={classes.permissionTooltip}>
                            {t('toolbar_no_permission')}
                        </Grid>
                    ) : (
                        ``
                    )
                }
            >
                <Grid item>
                    <Grid
                        className={classes.buttonContainer}
                        justify="center"
                        alignItems="center"
                        container
                        style={{
                            pointerEvents: isReadOnly ? 'none' : ''
                        }}
                        wrap={'nowrap'}
                    >
                        <Grid
                            item
                            className={`${classes.button} ${classes.buttonLeft} ${
                                selectedDirection === 'asc' ? classes.active : ''
                            }`}
                            onClick={handleAscendingClick}
                        >
                            <AToZSVG />
                        </Grid>
                        <Grid
                            item
                            className={`${classes.button} ${classes.buttonRight} ${
                                selectedDirection === 'desc' ? classes.active : ''
                            }`}
                            onClick={handleDescendingClick}
                        >
                            <ZToASVG />
                        </Grid>
                    </Grid>
                </Grid>
            </Tooltip>
        </Grid>
    );
}

export default React.memo(SortOrderItem);
