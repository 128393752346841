import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import EmptyGroupMembersSVG from 'assets/images/svg/EmptyGroupMembersSVG';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    icon: {
        display: 'flex',
        marginTop: 172
    },
    heading: {
        marginTop: 24
    },
    text: {
        textAlign: 'center'
    }
}));

function NoContent() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container direction="column" justify="center" alignItems="center" spacing={2} className={classes.root}>
            <Grid item className={classes.icon}>
                <EmptyGroupMembersSVG />
            </Grid>
            <Grid item className={classes.heading}>
                <h4 className="prose prose-lg font-medium">{t(`share_view_no_group_shared`)}</h4>
            </Grid>
            <Grid item className={classes.text}>
                <p className="caption text-center">
                    <Trans i18nKey={`share_view_no_content`} t={t}>
                        This access view hasn't been
                        <br />
                        shared to any group
                    </Trans>
                </p>
            </Grid>
        </Grid>
    );
}

export default React.memo(NoContent);
