import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HEADER_HEIGHT } from 'const';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import GridlyUIPage from 'gridUI';
import { getShareViewDetailApi, getAuthShareLinkApi } from 'services/shareLink';
import * as appActions from 'app/actions';
import * as gridUIActions from 'gridUI/actions';
import ws from 'socket';
import * as constants from 'const';
import NotFoundIllustrationSVG from 'assets/images/svg/NotFoundIllustrationSVG';
import { _setCompanyId } from 'auth/actions';

const useStyles = makeStyles(theme => ({
    root: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    fw400: {
        fontWeight: 400
    },
    disabledText: {
        color: theme.colors.midGrey,
        fontWeight: 'normal'
    }
}));

function ShareLinkView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { key } = useParams();
    const ROW_START_INDEX = useSelector(state => state?.gridUI?.ROW_START_INDEX);
    const ROW_STOP_INDEX = useSelector(state => state?.gridUI?.ROW_STOP_INDEX);
    const ROW_START_INDEX_Ref = React.useRef();
    const ROW_STOP_INDEX_Ref = React.useRef();

    const [isLoading, setIsLoading] = React.useState(true);
    const [shareViewDetail, setShareViewDetail] = React.useState(null);

    React.useEffect(() => {
        ROW_START_INDEX_Ref.current = ROW_START_INDEX;
        ROW_STOP_INDEX_Ref.current = ROW_STOP_INDEX;
    }, [ROW_START_INDEX, ROW_STOP_INDEX]);

    React.useEffect(() => {
        const getShareViewDetail = async () => {
            setIsLoading(true);
            try {
                const authShare = await getAuthShareLinkApi({ shareKey: key });
                if (!authShare) throw new Error('not working');
                dispatch(_setCompanyId({ companyId: authShare?.cid }));
                const shareViewDetail = await getShareViewDetailApi({ compositeViewId: authShare?.rid, key });
                if (!shareViewDetail) throw new Error('not working');
                dispatch(appActions.saveShareKeyId({ key }));
                dispatch(gridUIActions._setCurrentView(shareViewDetail?.view));
                setShareViewDetail(shareViewDetail);

                dispatch(
                    gridUIActions.setDefaultRouterParams({
                        defaultAccessViewId: shareViewDetail?.view?.id,
                        workspaceId: shareViewDetail?.database?.projectId,
                        gridId: shareViewDetail?.grid?.id,
                        dbId: shareViewDetail?.dbId,
                        branchId: shareViewDetail?.grid?.id,
                        parentGridId: shareViewDetail?.grid?.id
                    })
                );

                setIsLoading(false);
            } catch (error) {
                setShareViewDetail(null);
                setIsLoading(false);
            }
        };
        if (key) {
            getShareViewDetail();
        }

        return () => {
            dispatch(appActions.saveShareKeyId({ key: null }));
        };
    }, [key, dispatch]);

    React.useEffect(() => {
        if (shareViewDetail) {
            ws.shareLinkViewSubscribeTopics({
                companyId: shareViewDetail?.companyId,
                dbId: shareViewDetail?.dbId,
                viewId: shareViewDetail?.view?.id,
                gridId: shareViewDetail?.grid?.id,
                parentGridId: shareViewDetail?.grid?.id,
                userId: shareViewDetail?.uid,
                ROW_START_INDEX: ROW_START_INDEX_Ref.current,
                ROW_STOP_INDEX: ROW_STOP_INDEX_Ref.current
            });
        }
        dispatch(
            appActions.setCurrentPage({
                currentPage: constants.GRID_UI
            })
        );
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [shareViewDetail, dispatch]);

    if (isLoading) return null;

    if (!shareViewDetail && !isLoading)
        return (
            <Grid
                className={classes.root}
                direction="column"
                spacing={3}
                container
                alignItems="center"
                justify="center"
            >
                <Grid item>
                    <NotFoundIllustrationSVG />
                </Grid>
                <Grid item>
                    <Typography className={classes.fw400} variant="h3">
                        Oops! Looks like you get lose
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" className={classes.disabledText}>
                        Sorry we can't find the page you are looking for.
                    </Typography>
                </Grid>
            </Grid>
        );

    return (
        <GridlyUIPage
            isShareViewLink={true}
            workspaceId={shareViewDetail?.projectId}
            gridId={shareViewDetail?.grid?.id}
            viewId={shareViewDetail?.view?.id}
            dbId={shareViewDetail?.dbId}
            branchId={shareViewDetail?.grid?.id}
            privileges={shareViewDetail.privileges}
        />
    );
}

export default React.memo(ShareLinkView);
