import React from 'react';
import { findAll } from './utils';
import PropTypes from 'prop-types';
import { createElement } from 'react';
import memoizeOne from 'memoize-one';
import { HIGHLIGHT_TYPES } from 'const/gridUI';
import Tooltip from 'components/tooltip/Base';

Highlighter.propTypes = {
    activeClassName: PropTypes.string,
    activeIndex: PropTypes.number,
    activeStyle: PropTypes.object,
    autoEscape: PropTypes.bool,
    className: PropTypes.string,
    findChunks: PropTypes.func,
    highlightClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    highlightStyle: PropTypes.object,
    highlightTag: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
    sanitize: PropTypes.func,
    searchWords: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(RegExp)])).isRequired,
    textToHighlight: PropTypes.string.isRequired,
    unhighlightClassName: PropTypes.string,
    unhighlightStyle: PropTypes.object
};

/**
 * Highlights all occurrences of search terms (searchText) within a string (textToHighlight).
 * This function returns an array of strings and <span>s (wrapping highlighted words).
 */
export default function Highlighter({
    activeClassName = '',
    activeIndex = -1,
    activeStyle,
    autoEscape,
    caseSensitive = false,
    className,
    findChunks,
    highlightClassName = '',
    highlightStyle = {},
    highlightTag = 'mark',
    sanitize,
    searchWords,
    textToHighlight,
    unhighlightClassName = '',
    unhighlightStyle,
    accentInsensitive = false,
    _tokens,
    isCombinedNearest = true,
    parentTokens = [],
    isChildDependency,
    ...rest
}) {
    const chunks = findAll({
        autoEscape,
        caseSensitive,
        findChunks,
        sanitize,
        searchWords,
        textToHighlight,
        accentInsensitive,
        isCombinedNearest
    });

    const HighlightTag = highlightTag;
    let highlightIndex = -1;
    let highlightClassNames = '';
    let highlightStyles;

    const lowercaseProps = object => {
        const mapped = {};
        for (let key in object) {
            mapped[key.toLowerCase()] = object[key];
        }
        return mapped;
    };
    const memoizedLowercaseProps = memoizeOne(lowercaseProps);

    return createElement('span', {
        className,
        ...rest,
        children: chunks.map((chunk, index) => {
            const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);
            const isCustomTag = !parentTokens?.length
                ? isChildDependency
                    ? true
                    : false
                : !parentTokens?.includes(chunk?.word);
            if (chunk.highlight) {
                highlightIndex++;

                let highlightClass;
                if (typeof highlightClassName === 'object') {
                    if (!caseSensitive) {
                        highlightClassName = memoizedLowercaseProps(highlightClassName);
                        highlightClass = highlightClassName[text.toLowerCase()];
                    } else {
                        highlightClass = highlightClassName[text];
                    }
                } else {
                    highlightClass = `${highlightClassName}${
                        chunk?.type !== HIGHLIGHT_TYPES.DEFAULT ? `-${chunk?.type}${isCustomTag ? '-new' : ''}` : ''
                    }`;
                }

                const isActive = highlightIndex === +activeIndex;
                const isLink = chunk?.type === HIGHLIGHT_TYPES.LINK;

                highlightClassNames = `${highlightClass} ${isActive ? activeClassName : ''}`;
                highlightStyles =
                    isActive === true && activeStyle != null
                        ? Object.assign({}, highlightStyle, activeStyle)
                        : highlightStyle;

                let props = {
                    children: text,
                    className: highlightClassNames,
                    key: index,
                    style: highlightStyles
                };

                // Don't attach arbitrary props to DOM elements; this triggers React DEV warnings (https://fb.me/react-unknown-prop)
                // Only pass through the highlightIndex attribute for custom components.
                if (typeof HighlightTag !== 'string') {
                    props.highlightIndex = highlightIndex;
                }

                if (isLink) {
                    props = {
                        ...props,
                        href: chunk?.word,
                        target: '_blank'
                    };
                }

                if (isCustomTag) {
                    return (
                        <Tooltip title="Tag not found in source">
                            <HighlightTag {...props} />
                        </Tooltip>
                    );
                }

                return createElement(isLink ? 'a' : HighlightTag, props);
            } else {
                return createElement('span', {
                    children: text,
                    className: unhighlightClassName,
                    key: index,
                    style: unhighlightStyle
                });
            }
        })
    });
}
