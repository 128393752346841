import React from 'react';

function InternalManagerSVG() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="#EA6A46" />
            <path
                d="M7.52666 1.94987C6.0363 1.92093 5.33453 2.86868 4.46636 3.74408C4.16974 4.0407 4.16974 4.51819 4.46636 4.81482L6.36186 6.71032C6.65849 7.00694 7.13598 7.00694 7.4326 6.71032C7.72923 6.41369 7.72923 5.9362 7.4326 5.63958L6.07971 4.27945L6.43421 3.92495C6.7453 3.61385 7.09257 3.48363 7.50495 3.46916C8.54675 3.50533 9.16894 4.43138 8.92296 5.34295C8.87231 5.54552 8.72039 5.7915 8.64804 5.87832C8.59016 5.94343 8.65527 5.87109 8.53952 5.99408C8.44547 6.08813 8.09096 6.4354 7.77987 6.74649C7.83775 6.73202 7.89563 6.73202 7.96074 6.73202C8.19949 6.73202 8.431 6.82607 8.5974 6.9997C8.82891 7.23122 8.90849 7.54954 8.83614 7.83893C9.24852 7.42655 9.20511 7.46996 9.58132 7.09376C11.4985 5.16208 10.2035 2.02221 7.52666 1.94987Z"
                fill="white"
            />
            <path
                d="M2.7951 6.47161C2.7951 6.47161 3.12789 6.80441 3.61986 7.29637C4.23481 7.91132 4.97998 8.6565 5.00892 8.68544C4.97998 8.6565 5.00169 8.67821 4.99445 8.67097C5.0668 8.73608 5.12468 8.78673 5.2332 8.8229C5.4213 8.88801 5.63834 8.85184 5.79027 8.72161C5.83368 8.68544 5.67452 8.83737 7.41809 7.0938C7.71471 6.79718 8.1922 6.79718 8.48883 7.0938C8.78545 7.39042 8.78545 7.86792 8.48883 8.16454C6.76696 9.8864 6.87548 9.78512 6.75973 9.87917C5.93497 10.5809 4.71953 10.5086 3.97436 9.79959C3.91648 9.74171 4.11905 9.94428 1.71712 7.54235C1.42773 7.25296 1.42773 6.76824 1.72436 6.47161C2.02098 6.17499 2.49847 6.17499 2.7951 6.47161Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(InternalManagerSVG);
