import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditSVG from 'assets/images/svg/EditSVG';

import { Grid } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { isKbEnter, isKbEscape } from 'utils/keyboard';

const useStyles = makeStyles(theme => ({
    editIcon: {
        cursor: 'pointer'
    }
}));

function EditableText({ className, isEdit = false, errorText, value, onEnter, onChange, textRender, ...rest }) {
    const classes = useStyles();

    const [inputValue, setInputValue] = React.useState(value);
    const [isFocused, setIsFocused] = React.useState(isEdit);

    const handleKeyDown = event => {
        if (isKbEnter(event)) {
            setIsFocused(false);
            onEnter(inputValue);
        }

        if (isKbEscape(event)) {
            setIsFocused(false);
            setInputValue(value);
            onEnter(value);
        }
    };

    return (
        <div className={className}>
            {!isFocused && !errorText ? (
                <Grid container direction="row" spacing={2} wrap="nowrap">
                    <Grid item>{textRender(inputValue)}</Grid>
                    <Grid item>
                        <EditSVG className={classes.editIcon} onClick={() => setIsFocused(true)} />
                    </Grid>
                </Grid>
            ) : (
                <InputText
                    autoFocus
                    value={inputValue}
                    onChange={event => {
                        setInputValue(event.target.value);
                        onChange();
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                        onEnter(inputValue);
                    }}
                    onKeyDown={handleKeyDown}
                    errorText={errorText}
                    {...rest}
                />
            )}
        </div>
    );
}

export default React.memo(EditableText);
