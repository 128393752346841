import React from 'react';
import HeaderShareView from './HeaderShareView';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import useMediaQueries from '../hooks/useMediaQueries';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    content: {
        marginTop: 1,
        background: theme.colors.background
    }
}));

function GridUIShareViewLayout({ children }) {
    const classes = useStyles();
    let { isMobile, isTablet } = useMediaQueries();

    return (
        <Grid container direction={'column'}>
            <Grid item style={{ width: '100%' }}>
                <HeaderShareView isGridUI isShowToggle={isMobile} isTablet={isTablet} />
            </Grid>
            <Grid>
                <Grid className={classes.content}>{children}</Grid>
            </Grid>
        </Grid>
    );
}

export default GridUIShareViewLayout;
