import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { useGetLQAConfirmDeleteTicket } from 'hooks/gridUI/lqa';
import { useTranslation } from 'react-i18next';
import { COLOR_TYPES } from 'const';

const ConfirmDeleteTicket = () => {
    const [deleting, setDeleting] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isOpen, ticket } = useGetLQAConfirmDeleteTicket();

    const closeDeleteConfirm = useCallback(() => {
        dispatch(
            gridActions.setOpenConfirmDeleteTicket({
                isOpen: false,
                ticket: null
            })
        );
    }, [dispatch]);

    const handleDeleteTicket = useCallback(() => {
        if (deleting) return;
        setDeleting(true);
        dispatch(
            gridActions.deleteTicketHandler({
                ticket,
                successCallback: closeDeleteConfirm,
                finallyCallback: () => {
                    setDeleting(false);
                }
            })
        );
    }, [dispatch, ticket, deleting, closeDeleteConfirm]);

    return (
        <Dialog open={isOpen} onClose={closeDeleteConfirm}>
            <ConfirmBox
                title={`${t('delete_ticket')} #${ticket?.id}`}
                body={<p className="body2">{t('are_you_sure_you_want_to_delete_this_ticket')}</p>}
                onClose={closeDeleteConfirm}
                handleAgreed={handleDeleteTicket}
                handleCancel={closeDeleteConfirm}
                isLoading={deleting}
                agreeLabel={t('global_delete')}
                colorType={COLOR_TYPES.SECONDARY}
            />
        </Dialog>
    );
};

export default React.memo(ConfirmDeleteTicket);
