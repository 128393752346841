import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import NotFoundIllustrationSVG from 'assets/images/svg/NotFoundIllustrationSVG';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    fw400: {
        fontWeight: 400
    },
    textDisabled: {
        color: theme.colors.secondaryText
    },
    link: {
        color: theme.colors.dodgerBlue,
        fontWeight: 400,
        cursor: 'pointer',
        textDecoration: 'none'
    }
}));

function NotFound() {
    const classes = useStyles();
    return (
        <Grid className={classes.root} container direction="column" alignItems="center" justify="center" spacing={3}>
            <Grid item>
                <NotFoundIllustrationSVG />
            </Grid>
            <Grid item>
                <Typography className={classes.fw400} variant="h3">
                    Oops! Looks like you get lose
                </Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center' }}>
                <Typography className={classes.textDisabled} variant="body2">
                    Sorry we can't find the page you are looking for.
                </Typography>
                <Typography display="inline" className={classes.textDisabled} variant="body2">
                    Click
                </Typography>{' '}
                <Typography component="div" display="inline" variant="body2">
                    <Link to="/" className={classes.link}>
                        here
                    </Link>
                </Typography>{' '}
                <Typography display="inline" className={classes.textDisabled} variant="body2">
                    to comeback to the Dashboard
                </Typography>
            </Grid>
        </Grid>
    );
}

export default NotFound;
