import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.INIT_MERGING_GRID_PREVIEW](state, { payload }) {
        const { mergingGridPreview } = state;
        const newMergingGridPreview = produce(mergingGridPreview, draft => {
            draft.isInitializing = true;
        });
        return {
            ...state,
            mergingGridPreview: newMergingGridPreview
        };
    },
    [types.INIT_MERGING_GRID_PREVIEW_FAILED](state, { payload }) {
        const { mergingGridPreview } = state;
        const newMergingGridPreview = produce(mergingGridPreview, draft => {
            draft.isInitializing = false;
        });
        return {
            ...state,
            mergingGridPreview: newMergingGridPreview
        };
    },
    [types.INIT_MERGING_GRID_PREVIEW_SUCCESS](state, { payload }) {
        const { columns, rows, metaData, data, totalRecords } = payload;
        const { mergingGridPreview } = state;
        const newMergingGridPreview = produce(mergingGridPreview, draft => {
            draft.isInitializing = false;
            draft.columns = columns;
            draft.rows = rows;
            draft.metaData = metaData;
            draft.data = data;
            draft.totalRecords = totalRecords;
        });
        return {
            ...state,
            mergingGridPreview: newMergingGridPreview
        };
    },
    [types.FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW](state, { payload }) {
        const { mergingGridPreview } = state;
        const newMergingGridPreview = produce(mergingGridPreview, draft => {
            draft.isFetchingMore = true;
        });
        return {
            ...state,
            mergingGridPreview: newMergingGridPreview
        };
    },
    [types.FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW_FAILED](state, { payload }) {
        const { mergingGridPreview } = state;
        const newMergingGridPreview = produce(mergingGridPreview, draft => {
            draft.isFetchingMore = false;
        });
        return {
            ...state,
            mergingGridPreview: newMergingGridPreview
        };
    },
    [types.FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW_SUCCESS](state, { payload }) {
        const { rows, data, totalRecords, ROW_START_INDEX, ROW_STOP_INDEX } = payload;
        const { mergingGridPreview } = state;
        const newMergingGridPreview = produce(mergingGridPreview, draft => {
            draft.isFetchingMore = false;
            draft.rows = rows;
            draft.data = data;
            draft.totalRecords = totalRecords;
            draft.ROW_START_INDEX = ROW_START_INDEX;
            draft.ROW_STOP_INDEX = ROW_STOP_INDEX;
        });
        return {
            ...state,
            mergingGridPreview: newMergingGridPreview
        };
    }
};

export default handler;
