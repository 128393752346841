import React, { useCallback, useRef } from 'react';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { FILL_COLOR_OPTIONS } from 'const/gridUI';
import { useFillColorBlindness } from 'hooks/auth';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateActionNode } from 'gridUI/automations/action';
import { useTranslation } from 'react-i18next';

const SetColor = ({ className, postbackCellField = 'result', selectedNode, setSelectedNode, automationId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fillColorBlindness = useFillColorBlindness();
    const [hexColor, setHexColor] = useState(selectedNode?.params?.postbackCellColorMap?.[postbackCellField] || '');
    const timeout = useRef();

    const handleChange = useCallback(
        hex => {
            if (hex === hexColor) {
                hex = '';
            }
            setHexColor(hex);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                postbackCellColorMap: {
                                    ...(selectedNode.params?.postbackCellColorMap || {}),
                                    [postbackCellField]: hex
                                }
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setHexColor(selectedNode?.params?.postbackCellColorMap?.[postbackCellField] || '');
                        }
                    })
                );
            }, 800);
        },
        [dispatch, automationId, selectedNode.id, selectedNode.params, setSelectedNode, postbackCellField, hexColor]
    );

    return (
        <div className={className}>
            <p className="body1 mb-5">{t('automation_set_cell_color')}</p>
            <div className="grid grid-cols-8 gap-x-2.5 gap-y-4 max-w-[234px]">
                {Object.keys(FILL_COLOR_OPTIONS).map(hex => {
                    const image = FILL_COLOR_OPTIONS?.[hex];
                    return (
                        <span
                            key={hex}
                            onClick={() => handleChange(hex)}
                            className={'w-6 h-6 flex justify-center items-center rounded-full cursor-pointer'}
                            style={{
                                background: fillColorBlindness === 'BLIND' ? `${hex} url(${image})` : `${hex}`
                            }}
                        >
                            {hex === hexColor && <CheckIconSVG />}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(SetColor);
