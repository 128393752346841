import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Typography, Chip } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    code: {
        fontWeight: 400,
        fontSize: '0.75rem'
    },
    chip: {
        borderRadius: 4,
        height: 20,
        display: 'flex',
        alignItems: 'center'
    },
    ok: {
        border: `1px solid ${hexToRgba(theme.colors.atlantis, 0.3)}`,
        color: theme.colors.atlantis,
        background: hexToRgba(theme.colors.atlantis, 0.1)
    },
    unauthorized: {
        background: hexToRgba(theme.colors.sun, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.sun, 0.3)}`,
        color: theme.colors.sun
    },
    status: {
        fontSize: '0.75rem',
        color: theme.colors.primaryText,
        fontWeight: 400
    },
    forbidden: {
        background: hexToRgba(theme.colors.sun, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.sun, 0.3)}`,
        color: theme.colors.sun
    },
    notFound: {
        background: hexToRgba(theme.colors.lightGrey, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.lightGrey, 1)}`,
        color: theme.colors.lightGrey
    },
    created: {
        background: hexToRgba(theme.colors.dodgerBlue, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        color: theme.colors.dodgerBlue
    },
    deleted: {
        background: hexToRgba(theme.colors.snackBarError, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.snackBarError, 0.3)}`,
        color: theme.colors.snackBarError
    },
    internalError: {
        background: hexToRgba(theme.colors.snackBarError, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.snackBarError, 0.3)}`,
        color: theme.colors.snackBarError
    },
    badGateway: {
        background: hexToRgba(theme.colors.lightGrey, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.lightGrey, 1)}`,
        color: theme.colors.lightGrey
    },
    errorColor: {
        color: theme.colors.snackBarError
    },
    okColor: {
        color: theme.colors.atlantis
    },
    notFoundColor: {
        color: theme.colors.midGrey
    },
    createdColor: {
        color: theme.colors.dodgerBlue
    },
    warningColor: {
        color: theme.colors.sun
    }
}));

function StatusCode() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid className={classes.root} container spacing={2} wrap="wrap">
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.ok} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.okColor}`}>200</span>{' '}
                            <span className={`${classes.statusOk} ${classes.status}`}>{t('api_status_msg_200')}</span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.created} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.createdColor}`}>201</span>{' '}
                            <span className={`${classes.statusCreated} ${classes.status}`}>
                                {t('api_status_msg_201')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.deleted} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.errorColor}`}>204</span>{' '}
                            <span className={`${classes.statusDeleted} ${classes.status}`}>
                                {t('api_status_msg_204')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.unauthorized} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.warningColor}`}>401</span>{' '}
                            <span className={`${classes.statusUnauthorized}  ${classes.status}`}>
                                {t('api_status_msg_401')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.forbidden} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.warningColor}`}>403</span>{' '}
                            <span className={`${classes.statusForbidden}  ${classes.status}`}>
                                {t('api_status_msg_403')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.notFound} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.notFoundColor}`}>404</span>{' '}
                            <span className={`${classes.statusNotFound}  ${classes.status}`}>
                                {t('api_status_msg_404')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.internalError} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.errorColor}`}>500</span>{' '}
                            <span className={`${classes.statusInternalError}  ${classes.status}`}>
                                {t('api_status_msg_500')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
            <Grid item>
                <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={`${classes.badGateway} ${classes.chip}`}
                    label={
                        <Typography>
                            <span className={`${classes.code} ${classes.notFoundColor}`}>504</span>{' '}
                            <span className={`${classes.statusBadGateway}  ${classes.status}`}>
                                {t('api_status_msg_504')}
                            </span>
                        </Typography>
                    }
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(StatusCode);
