import request from './request';
import { APP_API_PREFIX_EVENT_LOG } from '../config';

const dbApis = {
    getEventlogs: () => `${APP_API_PREFIX_EVENT_LOG}/api/v1/event`
};

export async function getEventlogsApi({ dbId, gridId, offset, limit, beginTime }) {
    /**
     * TODO: BE change gridid -> gridId
     */
    let extraUrl = `?dbId=${dbId}&gridId=${gridId}&offset=${offset}&limit=${limit}`;
    if (!!beginTime) {
        extraUrl += `&beginTime=${beginTime}`;
    }
    const response = await request.get(`${dbApis.getEventlogs()}${extraUrl}`);
    return {
        histories: response.data,
        totalGridHistory: Number(response?.headers?.['x-total-count'] || 0)
    };
}
