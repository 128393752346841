import scrollIntoView from 'scroll-into-view-if-needed';

export function scrollInToView(node, option = {}) {
    if (node) {
        scrollIntoView(node, {
            behavior: 'smooth',
            block: 'end',
            duration: 300,
            ...option
        });
    }
}
