import React from 'react';

function FileSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Add Grid/Template">
                <path
                    id="Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.26602 0C9.52426 0 9.80158 0.112964 10.0038 0.295712L10.0982 0.392702L13.7468 4.69164C13.8983 4.87085 14.005 5.12798 14.0364 5.37645L14.046 5.5238V13.889C14.046 14.9996 13.2565 15.9134 12.2586 15.9942L12.1147 16H3.9313C2.91484 16 2.07918 15.1367 2.00531 14.0462L2 13.889V2.10999C2 1.00184 2.78588 0.0906287 3.7806 0.00635956L3.9313 0H9.26602ZM7.59652 1.6747H3.9313C3.7695 1.6747 3.62996 1.82741 3.5976 2.02358L3.59055 2.10999V13.889C3.59055 14.0953 3.70986 14.2738 3.86357 14.3152L3.9313 14.3243H12.1147C12.2792 14.3243 12.4175 14.1747 12.4495 13.9766L12.4565 13.889V7.22031H9.24317C8.38296 7.22031 7.67474 6.49862 7.60258 5.58172L7.59652 5.42718V1.6747ZM11.2088 11.5028V12.7034H5.11668V11.5028H11.2088ZM11.2088 8.90488V10.1069H5.11668V8.90488H11.2088ZM6.48594 6.30832V7.50897H5.11668V6.30832H6.48594ZM9.18499 1.99156V5.42718C9.18499 5.48406 9.20953 5.52165 9.22794 5.5369L9.24317 5.54457H12.4731L9.18499 1.99156Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(FileSVG);
