import { HEADER_HEIGHT, WEB_HOOK_EXTERNAL_SYSTEMS } from './index';
import { isFirefox } from 'utils/os';
import Pattern1PNG from 'assets/images/patterns/pattern1.png';
import Pattern2PNG from 'assets/images/patterns/pattern2.png';
import Pattern3PNG from 'assets/images/patterns/pattern3.png';
import Pattern4PNG from 'assets/images/patterns/pattern4.png';
import Pattern5PNG from 'assets/images/patterns/pattern5.png';
import Pattern6PNG from 'assets/images/patterns/pattern6.png';
import Pattern7PNG from 'assets/images/patterns/pattern7.png';
import Pattern8PNG from 'assets/images/patterns/pattern8.png';
import Pattern9PNG from 'assets/images/patterns/pattern9.png';
import Pattern10PNG from 'assets/images/patterns/pattern10.png';
import Pattern11PNG from 'assets/images/patterns/pattern11.png';
import Pattern12PNG from 'assets/images/patterns/pattern12.png';
import { ADD_ONS_OBJECT } from './inAppAddon';
export const GLOBAL_FILTER_HEIGHT = 52;
export const TOTAL_GRID_UI_HEADER = HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT;
export const characters = '`1234567890-=_+qwertyuiop[]\\"{}|asdfghjkl;\':zxcvbnm,./<>?';
export const NOT_NUMBER_REGEX = /[^0-9.-]/g;
export const RECORDS_RENDER_GRID_PREVIEW = 400;
export const RECORDS_OFFSET_TOP = 100; // old 200
export const RECORDS_RENDER = 200; //old 400
export const RECORDS_OFFSET_BOTTOM = 100; //old 200
export const OFFSET_FETCH = 75; //old 75
export const OFFSET_FETCH_ADVANCED_SEARCH = 200; //old 75

//record history
export const RECORD_HISTORY_OFFSET_BOTTOM = 50;
export const RECORD_HISTORY_OFFSET_TOP = 50;
export const RECORD_HISTORY_OFFSET_FETCH = 30;
export const RECORD_HISTORY_RENDER = 75;

//record history
export const DEFAULT_GRID_HISTORY_OFFSET = 0;
export const DEFAULT_GRID_HISTORY_LIMIT = 50;

export const INDEX_COLUMN_WIDTH = 88;
export const INDEX_COLUMN_WIDTH_ADVANCED_SEARCH = 56;
export const COLUMN_ID_WIDTH = 64;
export const RECORD_PANEL_WIDTH = 450;
export const ROW_HEIGHT = 36;
export const DEFAULT_COLUMN_WIDTH = 200;
export const AGG_HEIGHT = 36;
export const LIMIT_ROWS = Math.floor((isFirefox() ? 8947850 : 33554432) / 2 / ROW_HEIGHT) - 1;
export const FIXED_COLUMN_COUNT = 1;
export const FIXED_ROW_COUNT = 2;
export const MAXIMUM_VIEWS = 500;
export const MAX_OPTIONS = 100;
export const FREEZING_BAR_WIDTH = 1;
export const FREEZING_BAR_HANDLER_WIDTH = 8;
export const DEBOUNCE_TIME_SEARCHING = 500;
export const SPECIAL_SPLIT_KEY = ',';
export const SPECIAL_SPLIT_KEY_FOR_REFERENCE = ';';
export const ROW_RESIZE_HEIGHT = 1;
export const COLUMN_RESIZE_WIDTH = 1;
export const MIN_ROW_RESIZE = ROW_HEIGHT;
export const MAX_ROW_RESIZE = 640;

export const MIN_COLUMN_RESIZE = 100;
export const MAX_COLUMN_RESIZE = 1024;
export const TEXT_LINE_HEIGHT = 22;
export const CELL_PADDING_LEFT_RIGHT = 14;
export const CELL_PADDING_TOP_BOTTOM = 5;
export const CELL_PADDING = `${CELL_PADDING_TOP_BOTTOM}px ${CELL_PADDING_LEFT_RIGHT}px`;
export const FAKE_ROW = 1;
export const FAKE_COLUMN_COUNT = 1;
export const BORDER_HIGHLIGHT = 1;

export const SIDEBAR_RIGHT_HEADER = 72;
export const CACHE_ROWS_COUNT = 800;

export const RIGHT_SIDEBAR_ACTION_TYPE = {
    WEB_HOOK: `WEBHOOK`,
    BRANCH: `BRANCH`,
    SHARE_VIEW: `SHARE_VIEW`,
    KEYBOARD: `KEYBOARD`,
    CHAT: `CHAT`,
    CODE: `CODE`,
    HISTORY: `HISTORY`,
    MEM_SOURCE: 'MEM_SOURCE',
    MEMO_Q: 'MEMO_Q',
    ADD_ON: 'ADD_ON',
    TRANSLATION_OVERVIEW: 'TRANSLATION_OVERVIEW',
    COMMENT: `COMMENT`,
    JSON_SETTING: 'JSON_SETTING',
    LQA: 'LQA',
    PO_SETTING: 'PO_SETTING',
    GRID_TYPE_SETTING: `GRID_TYPE_SETTING`,
    LQA_MODAL: `LQA_MODAL`,
    AUTO_QA: 'AUTO_QA',
    SYNC_CONNECTION: `SYNC_CONNECTION`,
    BACKUP_GRID: 'BACKUP_GRID',
    AUTOMATIONS: `AUTOMATIONS`
};

export const SIDEBAR_RIGHT_WIDTH = 40;
export const SIDEBAR_RIGHT_CONTENT = 450;

export const SIDEBAR_LEFT_CONTENT = 300;

export const MAX_COLUMN_ORDER = 1000000;

export const MAX_RECORD_LIMIT = 2000;

export const RECORD_PANEL_EDITOR_ROW_HEIGHT = 600;

export const MAX_SELECTION_RECORDS = 2000;

export const ADVANCED_SEARCH_RECORD_LIMIT = 400;
export const OVER_SCAN_ROWS = 3;

//IMAGES
export const MAX_FILES_PER_ROW = 5;
export const FILE_SPACING = 2;
export const FILE_RATIO = 1;

//TYPES
export const COLUMN = 'COLUMN';
export const RECORD = 'RECORD';
export const GRID = 'GRID';

//ACTION
export const UPDATE = 'UPDATE';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';

export const SELECT_TYPES = {
    ROW_SELECT: 'ROW_SELECT',
    COLUMN_SELECT: 'COLUMN_SELECT',
    CELL_SELECT: 'CELL_SELECT',
    SELECTION_SELECT: 'SELECTION_SELECT'
};

//GRID STATUS
export const GRID_STATUS = {
    ACTIVE: 'active',
    UPLOADING: 'uploading',
    IMPORTING: 'importing',
    DUPLICATING: 'duplicating',
    IN_PROGRESS: 'in_progress',
    RESTORING: 'restoring',
    SUCCESS: 'success',
    MERGING: 'merging',
    MERGE_START: 'MERGE_START',
    MERGE_END: 'MERGE_END',
    UPLOAD_START: 'UPLOAD_START',
    UPLOAD_END: 'UPLOAD_END',
    IMPORT_START: 'IMPORT_START',
    IMPORT_END: 'IMPORT_END',
    FAILED: 'FAILED',
    PREVIEW_IMPORT_END: 'PREVIEW_IMPORT_END',
    CLEARING_RECORDS: 'clearingRecords',
    BACKING_UP: 'backingup'
};

//USER ACTION
export const USER_REALTIME_ACTION = {
    JOIN: 'JOIN',
    REGISTER: 'REGISTER',
    EDIT: 'EDIT',
    FOCUS: 'FOCUS',
    RESET: 'RESET',
    LEAVE: 'LEAVE'
};

export const COLUMN_STATUS = {
    UPLOADING: 'uploading',
    AUTO_FILL: 'autofill',
    ACTIVE: 'active',
    PASTE: 'paste',
    APPROVE_POPULATED_TEXT: `approvePopulatedText`,
    POPULATED_TEXT_FROM_TM: `populateTextFromTM`,
    SWITCH: 'switch'
};

export const DEPENDENCY_STATUS = {
    OUT_OF_DATE: 'outOfDate',
    UP_TO_DATE: 'upToDate',
    UNSET: 'unset'
};

export const SOURCE_STATUS = {
    DO_NOT_TRANSLATE: 'doNotTranslate',
    NOT_READY_FOR_TRANSLATION: 'notReadyForTranslation',
    READY_FOR_TRANSLATION: 'readyForTranslation',
    UNSET: 'unset',
    LOCKED: `locked`
};

export const SOURCE_STATUS_LABEL = {
    [SOURCE_STATUS.DO_NOT_TRANSLATE]: 'Do not translate',
    [SOURCE_STATUS.NOT_READY_FOR_TRANSLATION]: 'Not ready for translation',
    [SOURCE_STATUS.READY_FOR_TRANSLATION]: 'Ready for translation',
    [SOURCE_STATUS.LOCKED]: 'Locked',
    [SOURCE_STATUS.UNSET]: 'Not set'
};

export const TM_STATUS = {
    APPROVED: true
};

export const VIEW_TYPES = {
    ACCESS_VIEW: 'accessView',
    USER_VIEW: 'userView',
    DEFAULT_VIEW: 'defaultView'
};

export const PREVIEW_STATUS = {
    UPDATED: 'updated',
    ADDED: 'added',
    DELETED: 'deleted',
    CONFLICT: 'conflicted',
    NORMAL: 'normal',
    BEHIND: 'behind',
    AHEAD: 'ahead',
    UNCHANGED: 'unchanged',
    PARENT_DELETED: 'parentDeleted'
};

export const WEB_HOOK_EVENT_TYPES = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    RESOLVE: 'RESOLVE',
    REOPEN: 'REOPEN',
    GRIDLY_CREATE: 'GRIDLY_CREATE',
    GRIDLY_UPDATE: 'GRIDLY_UPDATE',
    GRIDLY_DELETE: 'GRIDLY_DELETE',
    REORDER_COLUMNS: 'REORDER_COLUMNS',
    PASTE_COLUMNS_START: 'PASTE_COLUMNS_START',
    PASTE_COLUMNS_END: 'PASTE_COLUMNS_END',
    APPROVE_POPULATED_TEXT_COLUMN_START: 'APPROVE_POPULATED_TEXT_COLUMN_START',
    APPROVE_POPULATED_TEXT_COLUMN_END: 'APPROVE_POPULATED_TEXT_COLUMN_END',
    SWITCH_TYPE_START: 'SWITCH_TYPE_START',
    SWITCH_TYPE_END: 'SWITCH_TYPE_END',
    IMPORT: 'IMPORT',
    IMPORT_START: 'IMPORT_START',
    IMPORT_END: 'IMPORT_END',
    UPLOAD: 'UPLOAD',
    UPLOAD_START: 'UPLOAD_START',
    UPLOAD_END: 'UPLOAD_END',
    POPULATED_DATA: 'POPULATED_DATA',
    MERGE_GRID: 'MERGE_GRID',
    MERGE_GRID_START: 'MERGE_GRID_START',
    MERGE_GRID_END: 'MERGE_GRID_END',
    MANUAL: 'MANUAL',
    STATUS_CHANGE: `STATUS_CHANGE`
};

export const METHOD_TYPES = {
    // GET: 'GET',
    // HEAD: 'HEAD',
    POST: 'POST',
    PUT: 'PUT',
    // PATCH: 'PATCH',
    DELETE: 'DELETE'
    // OPTIONS: 'OPTIONS',
    // TRACE: 'TRACE'
};

export const OBJECT_TYPES = {
    GRID: 'GRID',
    VIEW: 'VIEW',
    COLUMN: 'COLUMN',
    RECORD: 'RECORD',
    COMMENT: 'COMMENT',
    TICKET: 'TICKET'
    // VIEW_FILTER: 'VIEW_FILTER',
    // VIEW_SORT: 'VIEW_SORT'
};

export const PAYLOAD_TYPES = {
    JSON: 'JSON'
    // CSV: 'CSV',
    // XML: 'XML'
};

export const WEB_HOOK_STATUS = {
    ACTIVE: 'ACTIVE',
    DISABLED: 'DISABLED'
};

export const WEB_HOOK_TRIGGER_ACTIONS = {
    COLUMN_CREATED: 'COLUMN_CREATED',
    COLUMN_UPDATED: 'COLUMN_UPDATED',
    COLUMN_DELETED: 'COLUMN_DELETED',

    RECORD_CREATED: 'RECORD_CREATED',
    RECORD_UPDATED: 'RECORD_UPDATED',
    RECORD_DELETED: 'RECORD_DELETED',

    MANUAL: 'MANUAL',
    RESOLVE_COMMENT: 'RESOLVE_COMMENT',
    REOPEN_COMMENT: 'REOPEN_COMMENT',

    TICKET_CREATED: 'TICKET_CREATED',
    TICKET_UPDATED: 'TICKET_UPDATED',
    TICKET_DELETED: 'TICKET_DELETED',
    TICKET_STATUS_CHANGED: `TICKET_STATUS_CHANGED`,
    COMMENT_CREATED: `COMMENT_CREATED`
};

export const WEB_HOOK_TRIGGERS_ACTIONS_LABEL = {
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED]: 'Ticket created',
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED]: 'Ticket updated',
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED]: 'Ticket deleted',
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED]: 'Ticket status changed',
    [WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED]: 'Comment added',
    [WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED]: 'Record created',
    [WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED]: 'Record updated',
    [WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED]: 'Record deleted',
    [WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED]: 'Column created',
    [WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED]: 'Column updated',
    [WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED]: 'Column deleted',
    [WEB_HOOK_TRIGGER_ACTIONS.MANUAL]: 'Trigger button clicked'
};

export const getHookTypeLabel = hookType => {
    switch (hookType) {
        case WEB_HOOK_EXTERNAL_SYSTEMS.HTTP:
            return `Send web request`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.SLACK:
            return `Send Slack message`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.JIRA:
            return `JIRA Ticket Creation`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION:
            return `IF condition`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.SET:
            return `Set value`;
        default:
            return ADD_ONS_OBJECT[hookType]?.title || '';
    }
};

export const getHookTypeSubtitle = hookType => {
    switch (hookType) {
        case WEB_HOOK_EXTERNAL_SYSTEMS.HTTP:
            return `Send request to a given URL`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.SLACK:
            return `Send a message to Slack`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.JIRA:
            return `Add a ticket to your Jira board`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION:
            return `Continue next actions only when the condition meets`;
        case WEB_HOOK_EXTERNAL_SYSTEMS.SET:
            return `Set value`;
        default:
            return ADD_ONS_OBJECT[hookType]?.subtitle || '';
    }
};

export const WEB_HOOK_POSSIBLE_VARIABLES = {
    DATABASE_ID: 'database.id',
    DATABASE_NAME: 'database.name',
    GRID_ID: 'grid.id',
    GRID_NAME: 'grid.name',
    VIEW_ID: 'view.id',
    VIEW_NAME: 'view.name',
    COLUMN_NAME: 'column.name',
    USER_ID: 'user.id',
    USER_COMPANY_ID: 'user.companyId',
    USER_FULL_NAME: 'user.fullName',
    USER_EMAIL: 'user.email',
    RAW_DATA: 'rawData',
    EVENT_TYPE: 'event.type',
    EVENT_ACTION: 'event.action'
};

export const WEB_HOOK_POSSIBLE_VARIABLES_FOR_COMMENT = [
    WEB_HOOK_POSSIBLE_VARIABLES.GRID_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.RAW_DATA,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_EMAIL,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_FULL_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_TYPE,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_ACTION
];

export const WEB_HOOK_POSSIBLE_VARIABLES_FOR_COLUMN = [
    WEB_HOOK_POSSIBLE_VARIABLES.GRID_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.VIEW_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.RAW_DATA,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_EMAIL,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_FULL_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.COLUMN_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_TYPE,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_ACTION
];

export const WEB_HOOK_POSSIBLE_VARIABLES_FOR_RECORD = [
    WEB_HOOK_POSSIBLE_VARIABLES.GRID_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.VIEW_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.RAW_DATA,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_EMAIL,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_FULL_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_TYPE,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_ACTION
];

export const WEB_HOOK_POSSIBLE_VARIABLES_FOR_TICKET = [
    WEB_HOOK_POSSIBLE_VARIABLES.GRID_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.RAW_DATA,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_EMAIL,
    WEB_HOOK_POSSIBLE_VARIABLES.USER_FULL_NAME,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_TYPE,
    WEB_HOOK_POSSIBLE_VARIABLES.EVENT_ACTION
];

export const MESSAGE_WEB_HOOK_STATES = {
    SUCCESS: `SUCCESS`,
    RETRY_SUCCESS: `RETRY_SUCCESS`,
    RETRY_FAIL: `RETRY_FAIL`,
    EXCEED_RETRY: `EXCEED_RETRY`
};

export const GRID_HISTORY_ACTIONS = {
    UPDATE: 'UPDATE',
    CREATE: 'CREATE',
    DELETE: 'DELETE',
    MERGE: 'MERGE',
    RESTORE: 'RESTORE',
    IMPORT: 'IMPORT',
    UPLOAD_FILES: 'UPLOAD_FILES',
    AUTOFILL: 'AUTOFILL',
    CLEAR_RECORDS: 'CLEAR_RECORDS',
    CLEAN_COLUMN: 'CLEAN_COLUMN',
    PASTE_RECORDS: 'PASTE_RECORDS',
    APPROVE_TM: 'APPROVE_TM',
    MARK_TARGET_STATUS: 'MARK_TARGET_STATUS',
    MARK_SOURCE_STATUS: 'MARK_SOURCE_STATUS',
    BULK_DELETE_RECORDS: 'BULK_DELETE_RECORDS'
};

export const GRID_HISTORY_OBJECT_TYPES = {
    COLUMN: 'COLUMN',
    RECORD: 'RECORD',
    GRID: 'GRID',
    TAG: 'TAG',
    DEPENDENCY: 'DEPENDENCY'
};

export const EXPORT_TYPES = {
    CSV: 'csv',
    XLSX: 'xlsx',
    TSV: 'tsv',
    TBX: 'tbx',
    TMX: 'tmx'
};

export const EXTRA_QUICK_FILTER = {
    IS_HAS_COMMENT: 'IS_HAS_COMMENT',
    IS_EMPTY: 'IS_EMPTY',
    IS_FILLED: 'IS_FILLED',
    IS_UP_TO_DATE: `IS_UP_TO_DATE`,
    IS_OUT_OF_DATE: `IS_OUT_OF_DATE`,
    IS_UNSET: `IS_UNSET`,
    IS_TM: `IS_TM`,
    IS_NOT_TM: `IS_NOT_TM`,
    NONE: `NONE`
};

export const EXTRA_PREVIEW_STATUS = {
    CONFLICT: 'conflicted',
    ALL: 'all',
    NONE_CONFLICT: 'none_conflicted'
};

export const TRANSLATION_OVERVIEW_TYPES = {
    SOURCE: 'SOURCE',
    SOURCE_TARGET: 'SOURCE_TARGET',
    TARGET: 'TARGET'
};

export const NUMBER_TYPES = {
    DECIMAL: 'decimal',
    INTEGER: 'integer'
};

export const NUMBER_DEFAULT_VALUE = {
    decimalPlaces: 0,
    use1000Separator: false,
    currencySymbol: '',
    type: 'integer'
};

export const ROW_HEIGHT_OPTIONS = {
    DEFAULT: ROW_HEIGHT,
    COMFORTABLE: 80,
    COMPACT: ROW_HEIGHT * 3
};

export const GRID_TYPE_SETTING = {
    PO: 'po',
    JSON: 'json'
};

export const SHOW_GRID_TYPES = [GRID_TYPE_SETTING.JSON, GRID_TYPE_SETTING.PO];

export const FILL_COLOR_OPTIONS = {
    '#E9EAEF': Pattern1PNG,
    '#F8D2C8': Pattern2PNG,
    '#FFD7CC': Pattern2PNG,
    '#D6E8BF': Pattern3PNG,
    '#F4DEB0': Pattern4PNG,
    '#D2D7EA': Pattern5PNG,
    '#DBE2FF': Pattern5PNG,
    '#F9D6BF': Pattern6PNG,
    '#C0F2E3': Pattern7PNG,
    '#C0DFFB': Pattern8PNG,
    '#DBEDFF': Pattern8PNG,
    '#EBEBF6': Pattern9PNG,
    '#F9D8DD': Pattern10PNG,
    '#F7CACF': Pattern11PNG,
    '#FFCDD1': Pattern11PNG,
    '#CEF0F7': Pattern12PNG
};

export const TASK_STATUS = {
    FAILED: 'failed',
    SUCCESS: 'successful',
    RUNNING: 'running'
};

export const CONFLICT_TYPES = {
    IMPORT: 'IMPORT',
    BRANCHING: 'BRANCHING'
};

//LQA

export const LQA_TICKET_STATUS = {
    OPEN: 'open',
    RESOLVED: 'resolved',
    REOPENED: 'reopened',
    ALL: 'all'
};

export const LQA_TYPES = {
    BASIC: 'basic',
    LQA_ISSUE: 'lqa'
};

export const LQA_PRIORITY_TYPES = {
    CRITICAL: 'critical',
    HIGH: 'high',
    MEDIUM: 'medium',
    LOW: 'low'
};

export const DISABLED_CREATE_RECORD_GRID_TYPES = [GRID_TYPE_SETTING.JSON, GRID_TYPE_SETTING.PO];
export const DISABLED_UPDATE_COLUMN_GRID_TYPES = [GRID_TYPE_SETTING.PO];

export const GRID_UI_TYPE = {
    TABULAR: 'tabular',
    JSON: 'json',
    PO: 'po'
};

export const RANGE_TYPES = {
    INDEX: 'INDEX',
    RECORD_ID: 'RECORD_ID'
};

export const CREATE_COLUMN_TYPES = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
};

export const CREATE_RECORD_TYPES = {
    BELOW: 'BELOW',
    ABOVE: 'ABOVE'
};

export const DATA_QUERY_OPTIONS = {
    DATA: 'data',
    DEPENDENCY_STATUS: 'dependencyStatus',
    DEPENDENCY_PREVIOUS_DATA: 'dependencyPreviousData',
    TM: 'tm',
    TICKET: 'ticket',
    COLOR: 'color',
    SOURCE_STATUS: 'sourceStatus'
};

export const COLUMN_FORMAT_STATES = {
    MENU: 'MENU',
    FORMAT: 'FORMAT',
    NEW_COLUMN: 'NEW_COLUMN'
};

export const TAG_DETECT_MODE = {
    AUTO: 'auto',
    REGEX: 'regex',
    PREDEFINED: 'predefined'
};

export const HIGHLIGHT_TYPES = {
    TOKEN: 'token',
    DEFAULT: 'default',
    LINK: 'link',
    ZWS: 'zeroWidthSpace'
};

export const PREVIEW_IMAGE_TYPES = {
    DEFAULT: 'DEFAULT',
    RECORD_HISTORY: 'RECORD_HISTORY'
};

export const USER_ACTION_TYPES = {
    UNDO: 'UNDO',
    REDO: 'REDO'
};

export const ADDITIONAL_SETTING_STATUS = {
    DEFAULT: 'default',
    ASK: 'ask'
};

//REFERENCE
export const REFERENCE_SELECTION_TYPES = {
    MULTIPLE: 'multiple',
    SINGLE: 'single'
};

//FILES
export const FILE_UPLOAD_TYPES = {
    MULTIPLE: 'multiple',
    SINGLE: 'single'
};

export const MAX_EXTEND_VIEW_HEIGHT = 75;
export const MIN_EXTEND_VIEW_HEIGHT = 40;
const stepByVh = (MAX_EXTEND_VIEW_HEIGHT - MIN_EXTEND_VIEW_HEIGHT) / 100;

export function getVhBySteps(steps) {
    return steps * stepByVh + MIN_EXTEND_VIEW_HEIGHT;
}

export function getSteps(height) {
    return (height - MIN_EXTEND_VIEW_HEIGHT) / stepByVh;
}

export const DEFAULT_RECORD_HISTORY_HEIGHT = MIN_EXTEND_VIEW_HEIGHT;

export const EXTEND_VIEW_TYPES = {
    TRANSLATOR: 'TRANSLATOR',
    RECORD_HISTORY: 'RECORD_HISTORY',
    EDITOR: 'EDITOR'
};

export const SELECTION_OPACITY = {
    BACKGROUND: 0.1,
    BORDER: 0.3
};

export const GLOBAL_SEARCH_TYPES = {
    FINDING: `FINDING`,
    REPLACING: `REPLACING`
};

export const ADVANCED_SEARCH_TAB = {
    OWNER: 'OWNER',
    SHARED: 'SHARED'
};

export const SHARED_MODES = {
    PROJECT: 'project',
    SPECIFIC: 'specific'
};

export const FILTER_MODES = {
    REGEX: 'REGEX',
    NORMAL: 'NORMAL',
    CASE_SENSITIVE: 'CASE_SENSITIVE'
};

export const LOCALIZATION_FONT = {
    ja: 'jpFont',
    jaJP: 'jpFont'
};
