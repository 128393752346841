import React from 'react';
function OutlineStarSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none" {...other}>
            <path
                d="M13.1591 6.20527L13.1591 6.20527C13.4843 6.25212 13.6147 6.65165 13.3797 6.88126L11.5055 8.71231L11.8549 9.06996L11.5055 8.71232C11.1766 9.03362 11.0272 9.49597 11.1053 9.9487L11.1053 9.94883L11.5513 12.5308C11.5513 12.5308 11.5513 12.5308 11.5513 12.5308C11.6072 12.8546 11.2676 13.102 10.9765 12.9495L8.6559 11.733L8.65582 11.733C8.24891 11.5198 7.76289 11.5201 7.35622 11.7345C7.35621 11.7345 7.3562 11.7345 7.35619 11.7345L5.03842 12.9566L5.27162 13.3989L5.03841 12.9566C4.74783 13.1099 4.40748 12.8633 4.46261 12.5393C4.46262 12.5393 4.46262 12.5393 4.46262 12.5393L4.90243 9.95627L4.90244 9.95618C4.97946 9.50331 4.82903 9.04115 4.49922 8.72058L2.62106 6.89404L2.62104 6.89402C2.38545 6.66493 2.51482 6.26513 2.84 6.2175L2.84002 6.2175L5.43245 5.83768C5.43247 5.83768 5.4325 5.83767 5.43252 5.83767C5.88735 5.77108 6.28021 5.48486 6.48305 5.07246L7.63988 2.72146C7.63989 2.72145 7.63989 2.72144 7.63989 2.72144C7.78506 2.42649 8.20521 2.42618 8.35087 2.72055L8.3509 2.72061L9.51331 5.06888L9.51334 5.06894C9.71719 5.48062 10.1106 5.76599 10.5657 5.83151L13.1591 6.20527Z"
                stroke={color}
            />
        </svg>
    );
}
export default React.memo(OutlineStarSVG);
