import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Formula from 'components/formula/v2';
import { FUNCTION_SUGGESTION_LIST } from 'components/formula/const';
import { INPUT_PADDING_LEFT, INPUT_HEIGHT } from 'const/style';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import FormulaTooltip from './FormulaTooltip';
import { REFERENCE_SELECTION_TYPES } from 'const/gridUI';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    root: {},
    formula: {
        width: '100%',
        border: props => `1px solid ${props?.formularError ? theme.colors.brightRed : theme.colors.border}`,
        padding: `6px ${INPUT_PADDING_LEFT}px`,
        borderRadius: 4,
        minHeight: INPUT_HEIGHT,
        maxHeight: 300,
        overflowY: 'auto',
        '& *': {
            fontSize: 14,
            fontFamily: 'Roboto',
            color: theme.colors.primaryText
        },
        '& p': {
            margin: 0
        },
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    error: {
        color: theme.colors.brightRed
    },
    learnButton: {
        color: '#4A91E2',
        textDecoration: 'none',
        fontSize: '13px'
    },
    questionIcon: {
        display: 'flex',
        marginLeft: 5
    }
}));

function ExtraFormula({
    formula,
    onFormulaChange,
    columnSuggestions,
    formularError,
    onBlurFormula,
    metaData,
    singleResultValue,
    onSingleResultValueChange
}) {
    const classes = useStyles({ formularError });
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Grid container direction="column">
            <Grid container justify="space-between">
                <Grid item>
                    <p className="body1 mb-1">Insert formula</p>
                </Grid>
                <Grid item style={{ height: '36px' }}>
                    <a
                        className={classes.learnButton}
                        href="https://help.gridly.com/hc/en-us/articles/4405714554769-Formulas"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => sendManualTrack({ type: 'Click on Learn More Formula' })}
                    >
                        {t('global_learn_more')}
                    </a>
                </Grid>
            </Grid>
            <FormulaTooltip>
                <Grid item>
                    <Formula
                        className={classes.formula}
                        suggestions={[...columnSuggestions, ...FUNCTION_SUGGESTION_LIST]}
                        value={formula}
                        onChange={onFormulaChange}
                        placeholder="Insert column formula"
                        suggestionZIndex={1302}
                        onBlur={onBlurFormula}
                        metaData={metaData}
                    />
                </Grid>
            </FormulaTooltip>
            {formularError && (
                <Grid item>
                    <p className="text-error">{formularError}</p>
                </Grid>
            )}

            <Grid style={{ marginTop: theme.spacing(3) }} item container direction="column">
                <Grid item container wrap="nowrap" alignItems="center">
                    <Grid item>
                        <p className="body1">{t('global_formula_value_format')}</p>
                    </Grid>
                    <Tooltip title={t('formula_selection_type_hint')}>
                        <Grid item className={classes.questionIcon}>
                            <QuestionSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        row
                        value={singleResultValue}
                        onChange={onSingleResultValueChange}
                    >
                        <Grid container direction="column">
                            <Grid item container direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        className={classes.formControl}
                                        value={REFERENCE_SELECTION_TYPES.MULTIPLE}
                                        control={<Radio size="small" />}
                                        label={
                                            <p className="body2 !font-normal">{t(`formula_selection_type_multiple`)}</p>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        className={classes.formControl}
                                        value={REFERENCE_SELECTION_TYPES.SINGLE}
                                        control={<Radio size="small" />}
                                        label={
                                            <p className="body2 !font-normal">
                                                {t('reference_extra_options_reference_selection_type_single')}
                                            </p>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ExtraFormula;
