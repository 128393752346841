import { Transforms } from 'slate';

export const insertTag = (editor, suggestion) => {
    if (suggestion?.type === 'column') {
        const tag = {
            type: 'tag',
            suggestion,
            children: [{ text: '' }]
        };
        Transforms.insertNodes(editor, tag);
        Transforms.move(editor);
    } else if (suggestion?.type === 'func') {
        Transforms.insertNodes(editor, {
            type: 'func',
            suggestion,
            children: [{ text: '' }]
        });
        Transforms.move(editor);
        Transforms.insertNodes(editor, {
            text: `()`
        });
        Transforms.move(editor, { distance: 1, reverse: true, unit: 'character' });
    } else if (suggestion?.type === 'token') {
        Transforms.insertText(editor, suggestion?.value);
        Transforms.move(editor);
    }
};

export const removeTags = str => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
};
