import store from 'store/configStore';
import * as types from 'socket/types';
import * as columnActions from 'gridUI/actions/column';
import * as viewActions from 'gridUI/actions/views';
import * as viewFilterActions from 'gridUI/actions/viewFilter';
import * as viewSortActions from 'gridUI/actions/viewSort';
import * as aggregationActions from 'gridUI/actions/aggregation';
import * as dependencyActions from 'gridUI/actions/dependencies';
import * as tmActions from 'gridUI/actions/tm';
import * as gridUIActions from 'gridUI/actions';
import { enqueueSnackbar } from 'notifier/actions';
import isEmpty from 'lodash/isEmpty';
import { getNewAggregationTypeByColumnType } from 'utils/gridUI/aggregation';
import { AGGREGATIONS_VALUES, AGGREGATIONS_DISABLED_COLUMNS, SYSTEM_COLUMNS } from 'const';
import { getCorrectColumnType, formatMetaData } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import { getViewColumnsApi, getViewRecordsApiV2 } from 'services/view';
import { getGridColumnsApi } from 'services/grid';
import { formatColumnPermission } from 'utils/gridUI/column';
import { getViewFilterApi } from 'services/viewFilter';
import { convertServerFilterValue, formatQuickFilters } from 'utils/gridUI/filter';
import { RECORDS_OFFSET_BOTTOM, RECORDS_OFFSET_TOP, RECORDS_RENDER, COLUMN_STATUS } from 'const/gridUI';
import { getDependencyColumnIds } from 'utils/gridUI/dependency';
import { fetchDependenciesApi } from 'services/dependency';
import { getViewSortApi } from 'services/viewSort';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

const { dispatch, getState } = store;

const columnHandler = ({ body }) => {
    const { objectType } = body;
    switch (objectType) {
        case types.GRID:
            return _gridColumnHandler({ body });
        case types.VIEW:
            return _viewColumnHandler({ body });
        default:
            return;
    }
};

const _gridColumnHandler = ({ body }) => {
    const { columnId, subAction, data, status } = body;
    switch (subAction) {
        case types.UPDATE:
            return _updateGridColumnMetaData({ data, columnId });
        case types.DELETE:
            return _deleteGridColumnMetaData({ columnId });
        case types.CREATE:
            return _createGridColumn({ data });
        case types.PASTE_COLUMNS_START:
            return _pasteGridColumnStart({ data });
        case types.PASTE_COLUMNS_END:
            return _pasteGridColumnEnd({ data });
        case types.APPROVE_POPULATED_TEXT_COLUMN_END:
            return _approveColumnTMStatusEnd({ data });
        case types.APPROVE_POPULATED_TEXT_COLUMN_START:
            return _approveColumnTMStatusStart({ data });
        case types.MARK_COLUMN_DEPENDENCY_STATUS_START:
        case types.MARK_COLUMN_SOURCE_STATUS_START:
            return _markColumnDependencyStatusStart({ data });
        case types.MARK_COLUMN_DEPENDENCY_STATUS_END:
        case types.MARK_COLUMN_SOURCE_STATUS_END:
            return _markColumnDependencyStatusEnd({ data, status });
        case types.SWITCH_TYPE_START:
            return _switchColumnTypeStart({ data });
        case types.SWITCH_TYPE_END:
            return _switchColumnTypeEnd({ data });
        case types.EXECUTE_FORMULA_COLUMN_START:
            return _executeFormulaColumnStart({ data });
        case types.EXECUTE_FORMULA_COLUMN_END:
            return _executeFormulaColumnEnd({ data });

        case types.UNDO_UPDATE:
        case types.REDO_UPDATE:
            return _switchColumnTypeEnd({ data });

        case types.UNDO_DELETE:
            return _undoDeleteGridColumn({ data });
        case types.REDO_DELETE:
            return _deleteGridColumnMetaData({ columnId: data?.id });

        case types.UNDO_CREATE:
            return _deleteGridColumnMetaData({ columnId: data?.id });

        case types.REDO_CREATE:
            return _redoCreateColumn({ data });

        case types.UNDO_PASTE_COLUMN_END:
        case types.REDO_PASTE_COLUMN_END:
            return _pasteGridColumnEnd({ data, isShowMessage: false });

        case types.UNDO_CLEAN_COLUMN_START:
            return _cleanColumnsStart({ data });
        case types.UNDO_CLEAN_COLUMN_END:
            return _cleanColumnsEnd({ data });
        default:
            return;
    }
};

async function _redoCreateColumn({ data }) {
    _createGridColumn({ data });
    await getLatestViewColumns({ data });
}

async function _undoDeleteGridColumn({ data }) {
    const { gridUI } = getState();
    const { tableInfo, defaultAccessViewId, dbId, quickFilters, quickSorts, gridId } = gridUI;
    const columnId = data?.id;
    const columnType = getCorrectColumnType(data);
    if (!columnId) return;
    const isShowAutoQA = getIsShowAutoQA();

    try {
        //undo back position of column with order

        const [viewColumns, gridColumns, viewFilters, viewSorts, dependencies] = await Promise.all([
            getViewColumnsApi({ defaultAccessViewId, dbId }),
            getGridColumnsApi({ gridId, dbId }),
            getViewFilterApi({ dbId, defaultAccessViewId }),
            getViewSortApi({ dbId, defaultAccessViewId }),
            fetchDependenciesApi({ dbId, gridId })
        ]);
        const gridColumnsCombined = [...gridColumns, ...SYSTEM_COLUMNS.filter(sysCol => sysCol?.show)];
        let metaData = formatMetaData({ meta: gridColumnsCombined, viewColumns });
        let columnsPermission = formatColumnPermission({ viewColumns, gridColumnsCombined });

        const viewSortsMapped = viewSorts.map(viewSort => {
            return {
                ...viewSort,
                oldValue: viewSort.direction
            };
        });

        const viewFiltersMapped = viewFilters.map(viewFilter => {
            let convertValues = convertServerFilterValue({
                ...viewFilter,
                type: getCorrectColumnType(metaData?.[viewFilter?.columnId])
            });

            return {
                ...viewFilter,
                values: convertValues
            };
        });

        dispatch(
            columnActions.undoGridColumnV2({
                metaData,
                viewColumns,
                columnsPermission,
                dependencies,
                viewFilters: viewFiltersMapped,
                viewSorts: viewSortsMapped
            })
        );

        //fetch data of that column

        try {
            const { gridRef } = tableInfo;
            const { _rowStartIndex, _rowStopIndex } = gridRef;
            const quickFiltersFormatted = formatQuickFilters(quickFilters);
            const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });

            dispatch(gridUIActions._fetchMoreRowsAction());

            const {
                recordIds,
                columnIds,
                recordMetaData,
                data: newSectionData,
                totalRecords,
                totalRecordsWithoutFilters
            } = await getViewRecordsApiV2({
                defaultAccessViewId,
                dbId,
                offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                filterQuery: quickFiltersFormatted,
                sortQuery: quickSorts,
                columnIds: dependencyColumnIds,
                isShowAutoQA
            });

            dispatch(
                gridUIActions._fetchMoreRowsActionSuccess({
                    columns: columnIds,
                    rows: recordIds,
                    data: newSectionData,
                    totalRecords: totalRecords,
                    ROW_START_INDEX: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                    totalRecordsWithoutFilters
                })
            );
            dispatch(
                gridUIActions.updateRecordMetaData({
                    newRecordMetaData: recordMetaData
                })
            );

            dispatch(gridUIActions.removeProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
        } catch (error) {
            dispatch(gridUIActions.removeProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
        }

        if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnType)) {
            const { aggregations } = gridUI;
            const oldAggregationType = aggregations?.[columnId]?.aggregateType || AGGREGATIONS_VALUES.empty;
            const newAggregationType = getNewAggregationTypeByColumnType({
                oldType: oldAggregationType,
                columnType: getCorrectColumnType(data)
            });

            dispatch(
                aggregationActions.changeAggregation({
                    columnId,
                    oldType: oldAggregationType,
                    newType: newAggregationType
                })
            );
        }
    } catch (error) {
        console.log('noway...');
    }
}

function _executeFormulaColumnStart({ data }) {
    const columnId = data?.id;
    if (!columnId) return;

    dispatch(columnActions.addProcessingColumns({ columnIds: [columnId] }));
}

function _executeFormulaColumnEnd({ data = {} }) {
    const { gridUI } = getState();
    const columnId = data?.id;
    const columnType = getCorrectColumnType(data);
    if (!columnId) return;

    dispatch(
        columnActions.fetchGridSectionWithColumns({
            columnIds: [columnId],
            successCallback: () => {
                dispatch(columnActions.removeProcessingColumns({ columnIds: [columnId] }));
            },
            errorCallback: () => {
                dispatch(columnActions.removeProcessingColumns({ columnIds: [columnId] }));
            }
        })
    );

    if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnType)) {
        const { aggregations } = gridUI;
        const oldAggregationType = aggregations?.[columnId]?.aggregateType || AGGREGATIONS_VALUES.empty;
        const newAggregationType = getNewAggregationTypeByColumnType({
            oldType: oldAggregationType,
            columnType: getCorrectColumnType(data)
        });

        dispatch(
            aggregationActions.changeAggregation({
                columnId,
                oldType: oldAggregationType,
                newType: newAggregationType
            })
        );
    }
}

function _switchColumnTypeStart({ data }) {
    const columnId = data?.id;
    if (!columnId) return;

    dispatch(columnActions.addProcessingColumns({ columnIds: [columnId] }));
}

function _switchColumnTypeEnd({ data = {} }) {
    const { gridUI } = getState();
    const columnId = data?.id;
    const columnType = getCorrectColumnType(data);

    if (columnType === columnTypes.FORMULA) {
        return;
    }
    if (!columnId) return;

    dispatch(columnActions.updateGridColumnAction({ column: data }));
    dispatch(
        columnActions.fetchGridSectionWithColumns({
            columnIds: [columnId],
            successCallback: () => {
                dispatch(columnActions.removeProcessingColumns({ columnIds: [columnId] }));
            },
            errorCallback: () => {
                dispatch(columnActions.removeProcessingColumns({ columnIds: [columnId] }));
            }
        })
    );

    if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnType)) {
        const { aggregations } = gridUI;
        const oldAggregationType = aggregations?.[columnId]?.aggregateType || AGGREGATIONS_VALUES.empty;
        const newAggregationType = getNewAggregationTypeByColumnType({
            oldType: oldAggregationType,
            columnType: getCorrectColumnType(data)
        });

        dispatch(
            aggregationActions.changeAggregation({
                columnId,
                oldType: oldAggregationType,
                newType: newAggregationType
            })
        );
    }
}

function _approveColumnTMStatusStart({ data = {} }) {
    const columnId = data?.id;
    if (!columnId) return;
    dispatch(columnActions.addProcessingColumns({ columnIds: [columnId] }));
}

function _approveColumnTMStatusEnd({ data = {} }) {
    const columnId = data?.id;
    if (!columnId) return;
    dispatch(tmActions.approveTMStatusColumnIds({ columnIds: [columnId] }));
    dispatch(columnActions.removeProcessingColumns({ columnIds: [columnId] }));
}

function _markColumnDependencyStatusStart({ data = {} }) {
    const columnId = data?.id;
    if (!columnId) return;
    dispatch(columnActions.addProcessingColumns({ columnIds: [columnId] }));
}

function _markColumnDependencyStatusEnd({ data = {}, status }) {
    const columnId = data?.id;
    if (!columnId) return;

    dispatch(columnActions.removeProcessingColumns({ columnIds: [columnId] }));

    if (status === 'FAILED') {
        dispatch(
            enqueueSnackbar({
                message: 'Mark column dependency failed! your changes have been reverted',
                type: 'info'
            })
        );
    }
}

function _cleanColumnsStart({ data = [] }) {
    const columnIds = data?.columnIds;
    if (!columnIds?.length) return;
    dispatch(columnActions.addProcessingColumns({ columnIds: [...columnIds] }));
}

function _cleanColumnsEnd({ data = [] }) {
    const columnIds = data?.columnIds;
    if (!columnIds?.length) return;

    dispatch(
        columnActions.fetchGridSectionWithColumns({
            columnIds: [...columnIds],
            isCareData: true,
            successCallback: () => {
                dispatch(columnActions.removeProcessingColumns({ columnIds: [...columnIds] }));
            },
            errorCallback: () => {
                dispatch(columnActions.removeProcessingColumns({ columnIds: [...columnIds] }));
            }
        })
    );
}

function _pasteGridColumnStart({ data = [] }) {
    const columnIds = data?.map ? data?.map(col => col?.id) : data?.columns?.map(col => col?.id);
    if (!columnIds?.length) return;
    dispatch(columnActions.addProcessingColumns({ columnIds: [...columnIds] }));
}

async function _pasteGridColumnEnd({ data = [], isShowMessage = true }) {
    const columnIds = data?.map ? data?.map(col => col?.id) : data?.columns?.map(col => col?.id);
    if (!columnIds?.length) return;

    if (!data?.createdRecordsAvailable && !data?.createdColumnsAvailable) {
        dispatch(
            columnActions.fetchGridSectionWithColumns({
                columnIds: [...columnIds],
                isCareData: true,
                successCallback: () => {
                    dispatch(columnActions.removeProcessingColumns({ columnIds: [...columnIds] }));
                    if (isShowMessage) {
                        dispatch(
                            enqueueSnackbar({
                                message: `Pasted`,
                                type: 'info'
                            })
                        );
                    }
                },
                errorCallback: () => {
                    dispatch(columnActions.removeProcessingColumns({ columnIds: [...columnIds] }));
                }
            })
        );
        return;
    }

    const { gridUI } = getState();
    const { tableInfo, defaultAccessViewId, dbId, quickFilters, dependencies, quickSorts, viewColumns } = gridUI;

    const { gridRef } = tableInfo;
    const { _rowStartIndex, _rowStopIndex } = gridRef;
    const quickFiltersFormatted = formatQuickFilters(quickFilters);
    const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
    const isShowAutoQA = getIsShowAutoQA();

    try {
        dispatch(gridUIActions._fetchMoreRowsAction());

        dispatch(gridUIActions._toggleDeleteRecordState());

        const {
            recordIds,
            columnIds,
            data: newSectionData,
            totalRecords,
            totalRecordsWithoutFilters
        } = await getViewRecordsApiV2({
            defaultAccessViewId,
            dbId,
            offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
            limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
            filterQuery: quickFiltersFormatted,
            sortQuery: quickSorts,
            columnIds: dependencyColumnIds,
            isShowAutoQA
        });

        dispatch(
            gridUIActions._fetchMoreRowsActionSuccess({
                columns: columnIds,
                rows: recordIds,
                data: newSectionData,
                totalRecords: totalRecords,
                ROW_START_INDEX: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                ROW_STOP_INDEX: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                totalRecordsWithoutFilters
            })
        );

        dispatch(gridUIActions._toggleDeleteRecordState());

        dispatch(columnActions.removeProcessingColumns({ columnIds: [...columnIds] }));
    } catch (err) {
        dispatch(columnActions.removeProcessingColumns({ columnIds: [...columnIds] }));
    }
}

function _createGridColumn({ data }) {
    dispatch(
        columnActions.createGridColumnSocket({
            column: {
                ...data,
                editable: true
            }
        })
    );
}

function _updateGridColumnMetaData({ data }) {
    const status = data?.status;

    if ([COLUMN_STATUS.UPLOADING, COLUMN_STATUS.AUTO_FILL].includes(status)) {
        dispatch(columnActions.addProcessingColumns({ columnIds: [data?.id] }));
    }

    if (COLUMN_STATUS.ACTIVE === status?.toLowerCase()) {
        dispatch(columnActions.removeProcessingColumns({ columnIds: [data?.id] }));
    }

    let column = {
        ...data
    };
    dispatch(
        columnActions.updateGridColumnAction({
            column
        })
    );
}

async function _deleteGridColumnMetaData({ columnId }) {
    if (!columnId) {
        console.log('why columnId is empty?');
        return;
    }

    dispatch(
        columnActions.deleteGridColumnAction({
            columnId
        })
    );

    const { gridUI } = getState();
    const { viewColumns, viewSorts, viewFilters, dependencies } = gridUI;

    try {
        //clear aggregations local
        dispatch(aggregationActions.removeViewAggregateByColumnId({ columnId }));

        //clear view Filters
        let filterIdsRelateToColumnId = viewFilters
            .filter(viewFilter => viewFilter?.columnId === columnId)
            .map(viewFilter => viewFilter.id);

        dispatch(viewFilterActions.removeMultipleFilters({ filterIds: filterIdsRelateToColumnId }));
        //clear view Sorts
        let sortIdsRelateToColumnId = viewSorts
            .filter(viewSort => viewSort?.columnId === columnId)
            .map(viewSort => viewSort.id);

        dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

        //clear quickFilters, quickSorts local
        dispatch(columnActions.clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
        //clear quickSorts local
        dispatch(columnActions.clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));

        //clear child dependencies
        let newDependencies = [...dependencies];
        const childDependencyByColumnId = dependencies.find(dpDc => dpDc?.child === columnId);
        if (childDependencyByColumnId) {
            newDependencies = newDependencies?.filter(dpDc => dpDc?.id !== childDependencyByColumnId?.id);
            dispatch(dependencyActions._deleteDependencySuccess({ dependencyId: childDependencyByColumnId?.id }));
        }

        //generate new gridUI sorts, views
        const newQuickFilters = { ...gridUI.quickFilters };
        const newQuickSorts = { ...gridUI.quickSorts };
        const isHaveQuickFiltersToClear = !isEmpty(newQuickFilters[columnId]);
        const isHaveQuickSortsToClear = !isEmpty(newQuickSorts[columnId]);
        if (isHaveQuickFiltersToClear) {
            delete newQuickFilters[columnId];
        }
        if (isHaveQuickSortsToClear) {
            delete newQuickSorts[columnId];
        }

        //fetching new section
        await viewFilterActions._fetchRecordsAfterFilter({
            gridUI: {
                ...gridUI,
                quickFilters: newQuickFilters,
                quickSorts: newQuickSorts,
                viewColumns,
                dependencies: newDependencies
            },
            dispatch
        });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

const _viewColumnHandler = ({ body }) => {
    const { columnId, subAction, data } = body;
    switch (subAction) {
        case types.UPDATE:
            return _updateViewColumn({ data, columnId });
        case types.DELETE:
            return _deleteViewColumn({ columnId });
        case types.CREATE:
            return _createViewColumn({ data });
        case types.GRIDLY_CREATE:
            return _createColumnCombine({ data });
        case types.REORDER_COLUMNS:
            return _viewReorderColumn({ data });

        case types.REDO_CREATE:
            return _deleteViewColumn({ columnId: data?.id });

        case types.UNDO_CREATE:
        case types.UNDO_UPDATE:
        case types.REDO_UPDATE:
        case types.UNDO_REORDER:
        case types.REDO_REORDER:
            return getLatestViewColumns({ data });
        default:
            return;
    }
};

async function getLatestViewColumns({ data }) {
    const { gridUI } = getState();
    const { defaultAccessViewId, dbId } = gridUI;
    try {
        const [viewColumns] = await Promise.all([getViewColumnsApi({ defaultAccessViewId, dbId })]);
        dispatch(gridUIActions.updateReorderColumnSocket({ newViewColumns: viewColumns }));
    } catch (error) {
        console.log('no no _undoCreateViewColumn');
    }
}

function _viewReorderColumn({ data = [] }) {
    dispatch(
        columnActions.updateReorderColumnSocket({
            newViewColumns: data
        })
    );
    dispatch(gridUIActions.triggerRecomputedGrid());
}

function _createColumnCombine({ data }) {
    const { gridColumn, viewColumn } = data;
    dispatch(
        columnActions.createGridColumnSocket({
            column: {
                ...gridColumn,
                editable: true
            }
        })
    );
    dispatch(
        columnActions.addGridColumnToView({
            viewColumn: {
                ...viewColumn,
                viewable: true
            }
        })
    );
}

function _updateViewColumn({ data, columnId }) {
    dispatch(
        viewActions.updateViewPermissionSocket({
            columnId,
            data
        })
    );
}

function _createViewColumn({ data }) {
    let viewColumn = data && data[0];

    dispatch(
        columnActions.addGridColumnToView({
            viewColumn: {
                ...viewColumn,
                viewable: true
            }
        })
    );
}

async function _deleteViewColumn({ columnId }) {
    if (!columnId) {
        console.log('why columnId is empty?');
        return;
    }

    dispatch(
        viewActions.deleteViewColumnPermissionSocket({
            columnId
        })
    );

    const { gridUI } = getState();
    const { viewColumns, viewSorts, viewFilters } = gridUI;

    try {
        //clear aggregations local
        dispatch(aggregationActions.removeViewAggregateByColumnId({ columnId }));

        //clear view Filters
        let filterIdsRelateToColumnId = viewFilters
            .filter(viewFilter => viewFilter?.columnId === columnId)
            .map(viewFilter => viewFilter.id);

        dispatch(viewFilterActions.removeMultipleFilters({ filterIds: filterIdsRelateToColumnId }));
        //clear view Sorts
        let sortIdsRelateToColumnId = viewSorts
            .filter(viewSort => viewSort?.columnId === columnId)
            .map(viewSort => viewSort.id);

        dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

        //clear quickFilters, quickSorts local
        dispatch(columnActions.clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
        //clear quickSorts local
        dispatch(columnActions.clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));

        //generate new gridUI sorts, views
        const newQuickFilters = { ...gridUI.quickFilters };
        const newQuickSorts = { ...gridUI.quickSorts };
        const isHaveQuickFiltersToClear = !isEmpty(newQuickFilters[columnId]);
        const isHaveQuickSortsToClear = !isEmpty(newQuickSorts[columnId]);
        if (isHaveQuickFiltersToClear) {
            delete newQuickFilters[columnId];
        }
        if (isHaveQuickSortsToClear) {
            delete newQuickSorts[columnId];
        }

        //fetching new section
        await viewFilterActions._fetchRecordsAfterFilter({
            gridUI: {
                ...gridUI,
                quickFilters: newQuickFilters,
                quickSorts: newQuickSorts,
                viewColumns
            },
            dispatch
        });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export default columnHandler;
