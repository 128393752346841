import * as types from './type';
import { enqueueSnackbar } from 'notifier/actions';
import { getUsageApi } from 'services/dataCollector';
import { mapDataCollectorToMatchPaymentProperties } from 'utils/usage';

export function getUsage({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchUsageAction());
        try {
            const response = await getUsageApi();
            const usage = mapDataCollectorToMatchPaymentProperties(response);

            dispatch(_fetchUsageActionSuccess({ usage }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchUsageActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchUsageAction() {
    return {
        type: types.FETCH_DATA_USAGE
    };
}

function _fetchUsageActionFailed() {
    return {
        type: types.FETCH_DATA_USAGE_FAILED
    };
}

function _fetchUsageActionSuccess({ usage }) {
    return {
        type: types.FETCH_DATA_USAGE_SUCCESS,
        payload: {
            usage
        }
    };
}

export function getDataCollectorByWorkspaceIdActionSuccess({ workspaceId, data }) {
    return {
        type: types.FETCH_DATA_COLLECTOR_BY_WORKSPACE_ID_SUCCESS,
        payload: {
            workspaceId,
            data
        }
    };
}
