import React from 'react';

function HelpSquareSVG({ ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_4915_232192)">
                <path
                    d="M8.83333 11.16C8.83333 11.62 8.48 12 8 12C7.53333 12 7.16 11.6267 7.16 11.16C7.16 10.6867 7.53333 10.3267 8 10.3267C8.47333 10.3267 8.83333 10.6933 8.83333 11.16ZM7.99333 4C6.81333 4 6.00667 4.76667 5.70667 5.66L6.8 6.12C6.94667 5.67333 7.29333 5.13333 8 5.13333C9.08 5.13333 9.29333 6.14667 8.91333 6.68667C8.55333 7.2 7.93333 7.54667 7.60667 8.12667C7.34667 8.58667 7.4 9.12 7.4 9.44667H8.61333C8.61333 8.82667 8.66 8.7 8.76 8.50667C9.02 8.02667 9.5 7.8 10.0067 7.06C10.46 6.39333 10.2867 5.48667 9.99333 5.00667C9.65333 4.44667 8.98 4 7.99333 4ZM12.6667 3.33333H3.33333V12.6667H12.6667V3.33333ZM12.6667 2C13.4 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.6 14 2 13.4 2 12.6667V3.33333C2 2.6 2.6 2 3.33333 2H12.6667Z"
                    fill="#78778B"
                />
            </g>
            <defs>
                <clipPath id="clip0_4915_232192">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(HelpSquareSVG);
