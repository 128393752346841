import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import ButtonBase from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useTranslation } from 'react-i18next';
import Search from 'components/search';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useDispatch } from 'react-redux';
import { deleteTerms } from 'gridUI/actions';
import Tooltip from 'components/tooltip/Base';
import { useDropzone } from 'react-dropzone';
import { enqueueSnackbar } from 'notifier/actions';
import { getUploadErrorMessage } from 'utils/upload';
import { COLOR_TYPES, GRID_UPLOAD_MAX_SIZE, TB_UPLOAD_SUPPORTED_EXTENSIONS } from 'const';
import ImportSVG from 'assets/images/svg/ImportSVG';
import ExportSVG from 'assets/images/svg/ExportSVG';
import ExportTBDialog from './ExportTBDialog';
import ImportTBDialog from './ImportTBDialog';

const useStyles = makeStyles(theme => ({
    toolbar: {
        marginBottom: 40
    },
    searchBox: {
        border: `1px solid ${theme.colors.silver}`,
        background: theme.colors.white,
        marginRight: 8,
        '& input': {
            '&::placeholder': {
                fontSize: '14px !important',
                color: `${theme.colors.lightGreyBlue} !important`,
                opacity: 1
            }
        }
    },
    deleteBox: {
        borderRadius: 4,
        margin: '0 8px',
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.5s ease',
        '&.mr-16': {
            marginRight: 16
        },
        '& svg': {
            width: 24,
            height: 24,
            '& path': {
                fill: theme.colors.disabledGrey
            }
        },
        '&.active': {
            background: 'transparent',
            cursor: 'pointer',
            '& path': {
                fill: theme.colors.midGrey
            },
            '&:hover': {
                background: theme.colors.solitude,
                '& path': {
                    fill: theme.palette.primary.main
                }
            }
        }
    },
    buttonProgress: {
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    }
}));

const TBDetailToolbar = ({
    searchValue,
    handleSearchChange,
    handleClearSearch,
    glossary,
    listTerms,
    setListTerms,
    selectedRows,
    setSelectedRows,
    handleResetFetch,
    tableContainerRef
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [files, setFiles] = useState(null);

    const langOptions = useLanguageOptionsWithUsageValidate();

    const langObject = useMemo(() => {
        return langOptions.reduce((acc, cur) => {
            acc[cur.value] = cur;
            return acc;
        }, {});
    }, [langOptions]);

    const _handleOpenAdd = useCallback(() => {
        if (tableContainerRef?.current) {
            tableContainerRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        if (listTerms?.some(t => !!t.isNew)) return;
        setListTerms([
            {
                entryId: new Date().getTime(),
                isNew: true,
                terms: (glossary?.langs || []).map(el => ({
                    lang: el,
                    id: el,
                    text: ''
                }))
            },
            ...listTerms
        ]);
    }, [glossary.langs, listTerms, setListTerms, tableContainerRef]);

    const langOptionsExport = useMemo(() => {
        return (glossary?.langs || []).map(el => langObject[el]);
    }, [glossary, langObject]);

    const handleOpenDeleteDialog = useCallback(() => {
        if (selectedRows.length) {
            setOpenDeleteDialog(true);
        }
    }, [selectedRows]);

    const closeDeleteDialog = useCallback(() => {
        setOpenDeleteDialog(false);
    }, []);

    const handleDeleteTB = useCallback(() => {
        if (confirming) return;
        setConfirming(true);
        dispatch(
            deleteTerms({
                tbId: glossary.id,
                body: {
                    entryIds: selectedRows
                },
                successCallback: () => {
                    setConfirming(false);
                    closeDeleteDialog();
                    handleResetFetch({ resetPage: true });
                    setSelectedRows([]);
                },
                errorCallback: () => {
                    setConfirming(false);
                }
            })
        );
    }, [dispatch, confirming, glossary, selectedRows, handleResetFetch, closeDeleteDialog, setSelectedRows]);

    const _openExport = useCallback(() => {
        setOpenExport(true);
    }, []);

    const _closeExport = useCallback(() => {
        setOpenExport(false);
    }, []);

    const onDropLocalizationRejected = useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, TB_UPLOAD_SUPPORTED_EXTENSIONS);
            const fileError = fileErrors?.[0];

            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const handleFileImportInput = useCallback(files => {
        setFiles(files);
    }, []);

    const _closeImport = useCallback(() => {
        setFiles(null);
    }, []);

    const {
        getRootProps: getRootPropsLocalization,
        getInputProps: getInputPropsLocalization
        // isDragActive: isDragActiveLocalization
    } = useDropzone({
        noDragEventsBubbling: true,
        onDropAccepted: handleFileImportInput,
        onDropRejected: onDropLocalizationRejected,
        accept: TB_UPLOAD_SUPPORTED_EXTENSIONS,
        multiple: false,
        maxSize: GRID_UPLOAD_MAX_SIZE
    });

    return (
        <>
            <Grid className={classes.toolbar} container justify="flex-end" alignItems="center">
                <Grid item>
                    <Search
                        className={classes.searchBox}
                        background={theme.colors.paleGrey}
                        onChange={handleSearchChange}
                        defaultValue={searchValue}
                        placeholder={`${t('global_search')} …`}
                        width={240}
                        onClear={handleClearSearch}
                    />
                </Grid>
                <Tooltip title="Import terms">
                    <Grid className={`${classes.deleteBox} active`} item {...getRootPropsLocalization({})}>
                        <input
                            {...getInputPropsLocalization()}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            // tr-dt="Import terms"
                        />
                        <ImportSVG />
                    </Grid>
                </Tooltip>
                <Grid item>
                    <Tooltip title="Export terms">
                        <Grid item className={`${classes.deleteBox} active`} onClick={_openExport}>
                            <ExportSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Delete terms">
                        <Grid
                            item
                            className={`${classes.deleteBox} ${selectedRows.length ? 'active' : ''} mr-16`}
                            onClick={handleOpenDeleteDialog}
                        >
                            <DeleteSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <ButtonBase variant="contained" width={140} onClick={_handleOpenAdd}>
                        <AddIconSVG color={theme.colors.white} style={{ marginRight: 8, width: 10, height: 10 }} />
                        Add term
                    </ButtonBase>
                </Grid>
            </Grid>

            <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}>
                <ConfirmBox
                    title="Delete Terms"
                    // body={t('delete_terms_content', {
                    //     count: selectedRows.length
                    // })}
                    body={`Are you sure you want to delete ${selectedRows.length} selected Terms? This action cannot be undo`}
                    handleCancel={closeDeleteDialog}
                    onClose={closeDeleteDialog}
                    handleAgreed={handleDeleteTB}
                    isLoading={confirming}
                    agreeLabel={t('global_remove')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
            <ExportTBDialog
                open={openExport}
                onClose={_closeExport}
                glossary={glossary}
                langOptions={langOptionsExport}
            />
            <ImportTBDialog
                open={!!files}
                onClose={_closeImport}
                files={files}
                glossaryId={glossary?.id}
                handleResetFetch={handleResetFetch}
            />
        </>
    );
};

export default React.memo(TBDetailToolbar);
