import React from 'react';

function RedoSVG({ color = '#78778B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M13.8003 7.95C12.4128 6.7425 10.6128 6 8.62527 6C5.13777 6 2.19027 8.2725 1.15527 11.415L2.92527 12C3.71277 9.6075 5.96277 7.875 8.62527 7.875C10.0878 7.875 11.4228 8.415 12.4653 9.285L9.75027 12H16.5003V5.25L13.8003 7.95Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(RedoSVG);
