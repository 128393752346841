import * as types from '../types';
import {
    getCalcViewAggregationsApi,
    getViewAggregatesApi,
    createViewAggregateApi,
    updateViewAggregateApi
} from 'services/aggregation';
import * as statusActions from './status';
import uuidv1 from 'uuid/v1';
import { enqueueSnackbar } from 'notifier/actions';
import {
    getSupportedAggregationColumns,
    generateAggregationTypes,
    generateAggregationTypesAtInitialState
} from 'utils/gridUI/aggregation';
import { convertArrayToObject } from 'utils/object';
import { DEFAULT_AGGREGATION_TYPE } from 'const';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { isHasManageView } from 'utils/permission/workspaceAuthorities';

function generateAggregationDefault({ columnIds, aggregateTypes, isFetching }) {
    const aggregations = columnIds.map((columnId, index) => ({
        columnId,
        type: aggregateTypes?.[index],
        isFetching: isFetching
    }));
    return convertArrayToObject(aggregations, `columnId`);
}

export function fetchAggregations({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewColumns, metaData, dbId, defaultAccessViewId: viewId } = gridUI;
        const columnIds = getSupportedAggregationColumns({ viewColumns, metaData });

        if (!columnIds?.length) {
            console.log('THERE IS NO COLUMNS');
            return;
        }

        try {
            const viewAggregates = await getViewAggregatesApi({ dbId, viewId });
            let aggregateTypes = generateAggregationTypesAtInitialState({
                viewAggregates,
                columnIds
            });

            dispatch(
                _fetchAggregationActionSuccess({
                    aggregations: generateAggregationDefault({ columnIds, aggregateTypes, isFetching: true })
                })
            );
            dispatch(_fetchViewAggregationActionSuccess({ viewAggregates }));
            const response = await getCalcViewAggregationsApi({ dbId, viewId, columnIds, aggregateTypes });
            const aggregationsFormatted = response?.map((agg, index) => ({
                ...agg,
                value: agg?.result,
                isFetching: false,
                type: aggregateTypes?.[index]
            }));
            const aggregations = convertArrayToObject(aggregationsFormatted, 'columnId');
            dispatch(_fetchAggregationActionSuccess({ aggregations }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(
                _fetchAggregationActionSuccess({
                    aggregations: generateAggregationDefault({
                        columnIds,
                        aggregateTypes: columnIds?.map(col => DEFAULT_AGGREGATION_TYPE),
                        isFetching: false
                    })
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function reFetchAggregations({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            viewColumns,
            metaData,
            dbId,
            defaultAccessViewId: viewId,
            viewAggregates,
            quickFilters,
            aggregations: defaultAggregations
        } = gridUI;

        const columnIds = getSupportedAggregationColumns({ viewColumns, metaData });

        if (!columnIds?.length) {
            console.log('THERE IS NO COLUMNS');
            return;
        }
        try {
            let aggregateTypes = generateAggregationTypes({
                viewAggregates,
                columnIds,
                aggregations: defaultAggregations
            });

            dispatch(
                _fetchAggregationActionSuccess({
                    aggregations: generateAggregationDefault({ columnIds, aggregateTypes, isFetching: true })
                })
            );
            const quickFiltersFormatted = formatQuickFilters(quickFilters);
            const response = await getCalcViewAggregationsApi({
                dbId,
                viewId,
                columnIds,
                aggregateTypes,
                quickFilters: quickFiltersFormatted
            });
            const aggregationsFormatted = response?.map((agg, index) => ({
                ...agg,
                value: agg?.result,
                isFetching: false,
                type: aggregateTypes?.[index]
            }));
            const aggregations = convertArrayToObject(aggregationsFormatted, 'columnId');
            dispatch(_fetchAggregationActionSuccess({ aggregations }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(
                _fetchAggregationActionSuccess({
                    aggregations: generateAggregationDefault({
                        columnIds,
                        aggregateTypes: columnIds?.map(col => DEFAULT_AGGREGATION_TYPE),
                        isFetching: false
                    })
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchViewAggregationActionSuccess({ viewAggregates }) {
    return {
        type: types.FETCH_VIEW_AGGREGATION_LIST_SUCCESS,
        payload: {
            viewAggregates
        }
    };
}

export function _fetchAggregationActionSuccess({ aggregations }) {
    return {
        type: types.FETCH_AGGREGATION_LIST_SUCCESS,
        payload: {
            aggregations
        }
    };
}

export function changeAggregation({ columnId, oldType, newType, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const { dbId, defaultAccessViewId: viewId, quickFilters } = gridUI;
        const actionId = uuidv1();

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_changeAggregationAction({ columnId, type: newType }));
        const quickFiltersFormatted = formatQuickFilters(quickFilters);
        try {
            if (isHasManageView(auth)) {
                dispatch(checkCreateOrUpdateAggregation({ columnId, type: newType }));
            }
            const aggregations = await getCalcViewAggregationsApi({
                dbId,
                viewId,
                columnIds: [columnId],
                aggregateTypes: newType,
                quickFilters: quickFiltersFormatted
            });

            dispatch(_changeAggregationActionSuccess({ columnId, value: aggregations?.[0]?.result }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_changeAggregationActionFailed({ columnId, type: oldType }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

function _changeAggregationAction({ columnId, type }) {
    return {
        type: types.CHANGE_AGGREGATION,
        payload: {
            columnId,
            type
        }
    };
}

function _changeAggregationActionSuccess({ columnId, value }) {
    return {
        type: types.CHANGE_AGGREGATION_SUCCESS,
        payload: {
            columnId,
            value
        }
    };
}

function _changeAggregationActionFailed({ columnId, type }) {
    return {
        type: types.CHANGE_AGGREGATION_FAILED,
        payload: {
            columnId,
            type
        }
    };
}

export function addAggregationAfterNewColumnIsCreated({ columnId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { totalRecords } = gridUI;
        dispatch(
            _addAggregationAfterColumnIsCreatedAction({ columnId, value: totalRecords, type: DEFAULT_AGGREGATION_TYPE })
        );
    };
}

function _addAggregationAfterColumnIsCreatedAction({ columnId, value, type }) {
    return {
        type: types.ADD_AGGREGATION_AFTER_NEW_COLUMN_CREATED,
        payload: {
            columnId,
            value,
            type
        }
    };
}

export function removeViewAggregateByColumnId({ columnId }) {
    return {
        type: types.DELETE_VIEW_AGGREGATE_BY_COLUMN_ID,
        payload: {
            columnId
        }
    };
}

function checkCreateOrUpdateAggregation({ columnId, type }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, viewAggregates, defaultAccessViewId } = gridUI;
        const actionId = uuidv1();
        let aggregateFounded = viewAggregates?.find(aggregate => aggregate?.columnId === columnId);
        const isCreate = !aggregateFounded;
        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            let newAggregate = isCreate
                ? await createViewAggregateApi({
                      dbId,
                      viewId: defaultAccessViewId,
                      body: {
                          columnId,
                          type
                      }
                  })
                : await updateViewAggregateApi({
                      dbId,
                      viewId: defaultAccessViewId,
                      aggregateId: aggregateFounded?.id,
                      body: {
                          columnId,
                          type
                      }
                  });

            if (isCreate) {
                dispatch(_createViewAggregateActionSuccess({ aggregate: newAggregate }));
            } else {
                dispatch(
                    _updateViewAggregateActionSuccess({ aggregateId: aggregateFounded?.id, aggregate: newAggregate })
                );
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
        }
    };
}

function _createViewAggregateActionSuccess({ aggregate }) {
    return {
        type: types.CREATE_VIEW_AGGREGATE_SUCCESS,
        payload: {
            aggregate
        }
    };
}

function _updateViewAggregateActionSuccess({ aggregateId, aggregate }) {
    return {
        type: types.UPDATE_VIEW_AGGREGATE_SUCCESS,
        payload: {
            aggregateId,
            aggregate
        }
    };
}

export function registerAggregation({ columnId, aggregate }) {
    return {
        type: types.REGISTER_AGGREGATION,
        payload: {
            columnId,
            aggregate
        }
    };
}
