import React from 'react';
import Skeleton from 'components/skeleton/Base';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 24,
        '& .tabs': {
            borderBottom: `1px solid ${theme.colors.border}`
        }
    }
}));

function GroupContentSkeleton() {
    const classes = useStyles();

    return (
        <Grid className={classes.root} direction="column" spacing={5} container wrap="nowrap">
            <Grid item>
                <Grid container justify={'space-between'} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Grid container direction="row" spacing={2} alignItems="center" wrap="nowrap">
                            <Grid item>
                                <Skeleton variant="circle" width={48} height={48} />
                            </Grid>
                            <Grid item>
                                <Skeleton height={24} width={120} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Skeleton height={36} width={180} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={0} direction="column" wrap="nowrap">
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Skeleton height={36} width={140} />
                            </Grid>
                            <Grid item>
                                <Skeleton height={36} width={140} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Skeleton height={2} width={'100%'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justify={'flex-end'} direction="row" spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Skeleton height={36} width={220} />
                    </Grid>
                    <Grid item>
                        <Skeleton height={36} width={220} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                {/* <Skeleton width="100%" height={500} /> */}
                <Grid container direction="column" spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item style={{ width: '100%' }}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    {Array.from(Array(8).keys()).map(index => (
                        <Grid item key={index} style={{ width: '100%' }}>
                            <Skeleton width="100%" height={72} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(GroupContentSkeleton);
