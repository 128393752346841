import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';
import isEmpty from 'lodash/isEmpty';
import { encodeURL } from 'utils/http';

const aggregateApis = {
    gridCalcAggregates: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/records/aggregate`,
    viewCalcAggregates: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/records/aggregate`,
    viewAggregates: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/aggregates`,
    viewAggregate: ({ dbId, viewId, aggregateId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/aggregates/${aggregateId}`
};

export async function getCalcGridAggregationsApi({ dbId, gridId, columnIds = [], aggregateTypes = [] }) {
    const response = await request.get(
        `${aggregateApis.gridCalcAggregates({ dbId, gridId })}?columnIds=${columnIds}&aggregateTypes=${aggregateTypes}`
    );
    return response.data;
}

export async function getCalcViewAggregationsApi({ dbId, viewId, columnIds = [], aggregateTypes = [], quickFilters }) {
    let url = `${aggregateApis.viewCalcAggregates({
        dbId,
        viewId
    })}?columnIds=${columnIds}&aggregateTypes=${aggregateTypes}`;

    if (!isEmpty(quickFilters)) {
        url += `&query=${encodeURL(quickFilters)}`;
    }

    const response = await request.get(url);
    return response.data;
}

export async function getViewAggregatesApi({ dbId, viewId }) {
    const response = await request.get(`${aggregateApis.viewAggregates({ dbId, viewId })}`);
    return response.data;
}

export async function createViewAggregateApi({ dbId, viewId, body }) {
    const response = await request.post(`${aggregateApis.viewAggregates({ dbId, viewId })}`, body);
    return response.data;
}

export async function updateViewAggregateApi({ dbId, viewId, aggregateId, body }) {
    const response = await request.put(`${aggregateApis.viewAggregate({ dbId, viewId, aggregateId })}`, body);
    return response.data;
}

export async function deleteViewAggregateApi({ dbId, viewId, aggregateId }) {
    const response = await request.delete(`${aggregateApis.viewAggregate({ dbId, viewId, aggregateId })}`);
    return response.data;
}
