import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import SettingContentLayout from 'permission/common/Layout';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TBDetailOverview from './TBDetailOverview';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getTBDetail, getTerms } from 'gridUI/actions';
import TBDetailAssignProject from './TBDetailAssignProject';
import * as workspaceActions from 'workspaces/actions';
import { useCompanyId } from 'hooks/auth';
import * as memberActions from 'permission/actions/member';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    tabs: {
        margin: `${theme.spacing(2)}px 32px 32px`,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    wrapper: {
        height: '100%'
    },
    hide: {
        height: 0,
        overflow: 'hidden'
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

let savedData = {};

const TBDetail = props => {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();
    const companyId = useCompanyId();
    const [tabValue, setTabValue] = useState('OV');
    const location = useLocation();
    const [searchValue, setSearchValue] = useState(savedData.searchValue || '');
    const [filterOptions, setFilterOption] = useState(savedData.filterOptions || {});
    const [currentPage, setCurrentPage] = useState(1);
    const [glossary, setGlossary] = useState(null);
    const [listTerms, setListTerms] = useState([]);
    const [totalTerms, setTotalTerms] = useState(0);
    const [isFetchingListTerms, setIsFetchingListTerms] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [forceFetch, setForceFetch] = useState({});
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [auditors, setAuditors] = useState('');
    const limit = 7;
    const timerRef = useRef();
    const timerFetchRef = useRef();
    const history = useHistory();

    useEffect(() => {
        savedData = {};
    }, []);

    useEffect(() => {
        dispatch(
            getTBDetail({
                tbId: id,
                successCallback: responses => {
                    setGlossary(responses[0]);
                    setAuditors((responses[1] || []).join(','));
                    dispatch(workspaceActions.fetchWorkspaces({}));
                },
                errorCallback: () => {
                    if (location.state?.fromGlossary) {
                        history.goBack();
                    } else {
                        history.replace('localization-settings/tb');
                    }
                }
            })
        );
        dispatch(memberActions.fetchMembers({}));
    }, [companyId, id, dispatch, history, location]);

    useEffect(() => {
        setIsFetchingListTerms(true);
        setIsFetchingMore(false);
        if (timerFetchRef.current) clearTimeout(timerFetchRef.current);
        timerFetchRef.current = setTimeout(() => {
            const newFilterOptions = {
                ...filterOptions,
                fromCreatedDate: filterOptions.createdTime || undefined,
                fromAlteredDate: filterOptions.alteredTime || undefined,
                createdTime: undefined,
                alteredTime: undefined
            };
            dispatch(
                getTerms({
                    tbId: id,
                    params: {
                        tbId: id,
                        search: searchValue,
                        offset: (currentPage - 1) * limit,
                        limit,
                        ...newFilterOptions
                    },
                    successCallback: ({ data, headers }) => {
                        setListTerms(data);
                        setIsFetchingListTerms(false);
                        setTotalTerms(headers?.['x-total-count'] || 0);
                    },
                    errorCallback: () => {
                        setIsFetchingListTerms(false);
                    }
                })
            );
        }, DEBOUNCE_TIME_SEARCHING);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch, searchValue, forceFetch, filterOptions]);

    const isFilteringOption = useMemo(() => {
        return !!searchValue || Object.values(filterOptions).some(el => !!el);
    }, [searchValue, filterOptions]);

    const handleResetFetch = useCallback(({ resetPage, resetSelectedRows }) => {
        if (resetPage) {
            setCurrentPage(1);
        }
        if (resetSelectedRows) {
            setSelectedRows([]);
        }
        setForceFetch({});
    }, []);

    const handleChangeTab = useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    const handleSearchChange = useCallback(e => {
        const value = e?.target?.value;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setCurrentPage(1);
            setSearchValue(value);
        }, DEBOUNCE_TIME_SEARCHING);
    }, []);

    const handleClearSearch = React.useCallback(() => {
        setSearchValue('');
    }, []);

    const onBack = useCallback(() => {
        if (location.state?.fromGlossary) {
            history.goBack();
        } else {
            history.push('/localization-settings/tb');
        }
    }, [history, location]);

    const handleChangeFilterOption = useCallback(
        ({ key, value }) => {
            setCurrentPage(1);
            setSelectedRows([]);
            filterOptions[key] = value;
            setFilterOption({ ...filterOptions });
        },
        [filterOptions]
    );

    const handleFetchMore = useCallback(() => {
        if (isFetchingMore || !listTerms.length || totalTerms <= currentPage * limit) return;
        setIsFetchingMore(true);
        if (timerFetchRef.current) clearTimeout(timerFetchRef.current);
        timerFetchRef.current = setTimeout(() => {
            const newPage = currentPage + 1;
            const newFilterOptions = {
                ...filterOptions,
                fromCreatedDate: filterOptions.createdTime || undefined,
                fromAlteredDate: filterOptions.alteredTime || undefined,
                createdTime: undefined,
                alteredTime: undefined
            };
            dispatch(
                getTerms({
                    tbId: id,
                    params: {
                        tbId: id,
                        search: searchValue,
                        offset: (newPage - 1) * limit,
                        limit,
                        ...newFilterOptions
                    },
                    successCallback: ({ data, headers }) => {
                        if (data?.length) {
                            setCurrentPage(newPage);
                            setListTerms(prev => [...prev, ...data]);
                        }
                        setTotalTerms(headers?.['x-total-count'] || 0);
                        setIsFetchingMore(false);
                    },
                    errorCallback: () => {
                        setIsFetchingMore(false);
                    }
                })
            );
        }, DEBOUNCE_TIME_SEARCHING);
    }, [currentPage, dispatch, filterOptions, id, isFetchingMore, listTerms.length, searchValue, totalTerms]);

    const onClickTerm = useCallback(
        ({ term }) => {
            savedData = {
                searchValue,
                filterOptions
            };
            history.push(`${window.location.pathname}/term/${term.id}`, { fromGlossaryDetail: true });
        },
        [filterOptions, history, searchValue]
    );

    if (!glossary) {
        return null;
    }

    return (
        <SettingContentLayout name={glossary?.name} onBack={onBack}>
            <Grid item container direction="column" wrap="nowrap" className={classes.root}>
                <Grid item>
                    <Tabs className={classes.tabs} value={tabValue} onChange={handleChangeTab}>
                        <Tab classes={tabClasses} value="OV" label={t('overview')} />
                        <Tab classes={tabClasses} value="AP" label="Assign Project" />
                    </Tabs>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                    <Grid
                        item
                        className={classnames(classes.wrapper, {
                            [classes.hide]: tabValue !== 'OV'
                        })}
                    >
                        <TBDetailOverview
                            glossary={glossary}
                            searchValue={searchValue}
                            listTerms={listTerms}
                            setListTerms={setListTerms}
                            isFetching={isFetchingListTerms}
                            isFetchingMore={isFetchingMore}
                            selectedRows={selectedRows}
                            totalTerms={totalTerms}
                            isFilteringOption={isFilteringOption}
                            handleSearchChange={handleSearchChange}
                            handleClearSearch={handleClearSearch}
                            setSelectedRows={setSelectedRows}
                            handleResetFetch={handleResetFetch}
                            handleChangeFilterOption={handleChangeFilterOption}
                            handleFetchMore={handleFetchMore}
                            filterOptions={filterOptions}
                            onClickTerm={onClickTerm}
                            auditors={auditors}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classnames(classes.wrapper, {
                            [classes.hide]: tabValue !== 'AP'
                        })}
                    >
                        <TBDetailAssignProject glossary={glossary} setGlossary={setGlossary} />
                    </Grid>
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
};

export default React.memo(TBDetail);
