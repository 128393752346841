import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SortOrderList from './SortOrderList';
import AddSortOrderButton from './AddSortOrderButton';
import { LIST_MENU_MAX_HEIGHT } from 'const/style';
import Divider from 'components/divider/Base';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import { useMetaData, useViewSorts } from 'hooks/advanced';

const useStyles = makeStyles(theme => ({
    root: {
        width: 390
    },
    buttonAdd: {
        padding: `0px 20px`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        width: 'fit-content'
    },
    sortList: {
        padding: `0px 20px`,
        maxHeight: `calc(${LIST_MENU_MAX_HEIGHT} - ${16 + 54}px)`,
        overflowY: 'auto'
    },
    whiteText: {
        color: theme.colors.white
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    }
}));

function SortMenu({ onAddSort, deleteViewSort, updateViewSortState, updateViewSort, columns, isReadOnly }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const metaData = useMetaData();
    const sorts = useViewSorts();

    return (
        <Grid id="view-sort-trigger" container wrap="nowrap" direction="column" className={classes.root}>
            <Grid item>
                <Grid container direction="column" className={classes.sortList} id={'sortListBoundary'} wrap="nowrap">
                    <SortOrderList
                        sorts={sorts}
                        deleteViewSort={deleteViewSort}
                        updateViewSortState={updateViewSortState}
                        updateViewSort={updateViewSort}
                        metaData={metaData}
                        columns={columns}
                    />
                    <div id="sortListBottom"></div>
                </Grid>
            </Grid>
            <Grid>
                <Divider />
            </Grid>
            <Tooltip
                title={
                    isReadOnly ? (
                        <Grid container className={classes.permissionTooltip}>
                            {t('toolbar_no_permission')}
                        </Grid>
                    ) : (
                        ''
                    )
                }
            >
                <Grid item className={classes.buttonAdd}>
                    <AddSortOrderButton t={t} onAddSort={onAddSort} isReadOnly={isReadOnly} />
                </Grid>
            </Tooltip>
        </Grid>
    );
}

export default React.memo(SortMenu);
