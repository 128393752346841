import React from 'react';
import { Grid } from '@material-ui/core';
import NormalSelect from 'gridUI/common/normalSelects';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import { TRANSLATION_TYPES, TRANSLATION_TYPE_OPTIONS } from 'gridUI/column-types';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';

function ExtraOptionsWithTranslation({
    translationData = {},
    columnId,
    translationDataChangeHandler,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    t
}) {
    const langOptions = useLanguageOptionsWithUsageValidate(workspaceId);

    const selectedTypeOption = React.useMemo(() => {
        return TRANSLATION_TYPE_OPTIONS.find(option => option.value === translationData.type);
    }, [translationData]);

    const selectedLanguageOption = React.useMemo(() => {
        return langOptions.find(option => option.value === translationData.value);
    }, [langOptions, translationData]);

    const onTypeSelect = React.useCallback(
        option => {
            translationDataChangeHandler({
                columnId,
                type: option.value
            });
        },
        [columnId, translationDataChangeHandler]
    );

    const onLanguageSelect = React.useCallback(
        option => {
            translationDataChangeHandler({
                columnId,
                value: option.value
            });
        },
        [columnId, translationDataChangeHandler]
    );

    return (
        <Grid container direction="column">
            <Grid item container direction="column" className={'mb-3.5'}>
                <Grid item>
                    <p className="body1 mb-1">{t('global_localization_type')}</p>
                </Grid>
                <Grid item>
                    <NormalSelect
                        defaultValue={selectedTypeOption}
                        options={TRANSLATION_TYPE_OPTIONS}
                        handleOptionChange={onTypeSelect}
                        ddPlaceholder="Select a type"
                        menuPlaceholder={t('global_find_a_type')}
                    />
                </Grid>
            </Grid>

            {(translationData.type === TRANSLATION_TYPES.SOURCE_LANG ||
                translationData.type === TRANSLATION_TYPES.TARGET_LANG) && (
                <Grid item container direction="column">
                    <Grid item>
                        <p className="body1 mb-1">{t('global_language')}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortalVirtualize
                            defaultValue={selectedLanguageOption}
                            options={langOptions}
                            onChange={onLanguageSelect}
                            ddPlaceholder="Select a language"
                            menuPlaceholder="Find a language"
                            maxSize={200}
                            isUsingContainer={false}
                            popperId="popper-language"
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(ExtraOptionsWithTranslation);
