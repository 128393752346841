import * as types from '../types';
import { combinedData } from 'utils/gridUI/data';

const handler = {
    [types.UPDATE_DATA](state, { payload }) {
        const { newData, isCareData } = payload;
        const { data } = state;

        return {
            ...state,
            data: combinedData({ data, newData, isCareData })
        };
    },
    [types.DELETE_RECORDS_DATA](state, { payload }) {
        const { recordIds } = payload;
        const { data } = state;

        const newData = { ...data };
        for (const recordId of recordIds) {
            delete newData[recordId];
        }

        return {
            ...state,
            data: newData
        };
    },
    [types.UPDATE_GRID_DATA](state, { payload }) {
        const {
            data: newData,
            totalRecords,
            rows,
            recordMetaData,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        } = payload;
        const { data } = state;

        return {
            ...state,
            data: combinedData({ data, newData, isCareData: true }),
            totalRecords,
            rows,
            recordMetaData,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        };
    }
};

export default handler;
