import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Popper, Paper } from '@material-ui/core';
import SettingContentLayout from 'permission/common/Layout';
import ListSelect from 'components/list/Select';
import AddIconSVGFilled from 'assets/images/svg/AddIconSVGFilled';
import { useDefaultThresHoldValueByProperty } from 'hooks/payment';
import { useSelectedLocalesOptions, useLanguageOptions, useCompanyInfo } from 'hooks/app';
import { USAGE_PROPERTIES } from 'const';
import CellBaseVirtualize from 'components/selects/virtualize/CellBaseVirtualize';
import ModalBase from 'components/modal/Base';
import ConfirmDeleteLocale from './ConfirmDeleteLocale';
import * as companyActions from 'permission/actions/company';
import { useDispatch } from 'react-redux';
import { useIsLocalesLimitInfinity } from 'hooks/app';
import LocaleBannerSVG from 'assets/images/svg/payment/illusttration/LocaleBannerSVG';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import ButtonBase from 'components/button/Base';
import { useHistory } from 'react-router-dom';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 24
    },
    list: {
        maxWidth: 500
    },
    addIcon: {
        display: 'flex'
    },
    primaryColor: {
        color: theme.palette.primary.main
    },
    addWrapper: {
        width: 'fit-content',
        cursor: 'pointer'
    },
    zIndex: {
        zIndex: theme.zIndex.modal + 200
    },
    paper: {
        minWidth: 200
    },
    infinity: {
        width: '100%',
        height: '100%'
    },
    mxw500: {
        maxWidth: 500,
        margin: '0 auto',
        textAlign: 'center'
    },
    banner: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        maxWidth: 500,
        padding: theme.spacing(4)
    }
}));

function Locale() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const oldCompany = useCompanyInfo();
    const MAX_LOCALES = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.MAX_LOCALES);
    const changesOptionsRef = React.useRef([]);
    const deleteOptionRef = React.useRef();
    const selectedLocales = useSelectedLocalesOptions();
    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    const LANGUAGE_OPTIONS = useLanguageOptions();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const isLocalesInfinity = useIsLocalesLimitInfinity();

    const DROPDOWN_OPTIONS = React.useMemo(() => {
        const selectedLocaleIds = selectedLocales?.map(opt => opt?.value);
        return LANGUAGE_OPTIONS.filter(opt => {
            return !selectedLocaleIds?.includes(opt?.value);
        });
    }, [LANGUAGE_OPTIONS, selectedLocales]);

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleOpenDeleteConfirmModal = () => setOpenDeleteConfirm(true);
    const handleCloseDeleteConfirmModal = () => setOpenDeleteConfirm(false);

    const handleLocalesChange = React.useCallback(
        options => {
            changesOptionsRef.current = options;
            const optionLength = options?.length;
            const selectedOptionLength = selectedLocales?.length;

            if (optionLength > MAX_LOCALES) {
                handleClickAway();
                return;
            } else if (optionLength < selectedOptionLength) {
                handleClickAway();
                handleOpenDeleteConfirmModal();
                return;
            }
            handleClickAway();
            sendManualTrack({
                type: `Change Locales`,
                customData: {
                    locales: options?.map(opt => opt?.value)
                }
            });
            dispatch(
                companyActions.changeCompanyInfo({
                    newData: {
                        ...oldCompany,
                        locales: options?.map(opt => opt?.value)
                    },
                    successCallback: () => {
                        console.log('update locale success');
                    },
                    errorCallback: () => {
                        console.log('update locales failed');
                    }
                })
            );
        },
        [MAX_LOCALES, selectedLocales, dispatch, oldCompany]
    );

    const selectedLocaleLength = React.useMemo(() => {
        return selectedLocales?.length;
    }, [selectedLocales]);

    const handleDeleteLocale = () => {
        const leftOptions = selectedLocales?.filter(option => option?.value !== deleteOptionRef.current?.value);
        dispatch(
            companyActions.changeCompanyInfo({
                newData: {
                    ...oldCompany,
                    locales: leftOptions?.map(opt => opt?.value)
                },
                successCallback: () => {
                    console.log('delete locale success');
                },
                errorCallback: () => {
                    console.log('delete locales failed');
                }
            })
        );
        handleCloseDeleteConfirmModal();
    };

    const handleDelete = option => {
        deleteOptionRef.current = option;
        handleOpenDeleteConfirmModal();
    };

    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    return (
        <SettingContentLayout name={t('global_locales')}>
            {isLocalesInfinity ? (
                <Grid
                    container
                    spacing={3}
                    direction="column"
                    className={classes.infinity}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item>
                        <LocaleBannerSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            <span role="img" aria-label="emoji">
                                ✨
                            </span>{' '}
                            {t('unlimited_locales')}{' '}
                            <span role="img" aria-label="emoji">
                                ✨
                            </span>{' '}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container className={classes.root} direction="row" spacing={3}>
                    <Grid item xs={6}>
                        <Grid container spacing={3} item direction="column">
                            <Grid item>
                                <Typography variant="caption">{t('locales_allow_you_to_define')}</Typography>
                            </Grid>
                            {selectedLocaleLength > 0 && (
                                <>
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t('locales_selected_locales')} {selectedLocaleLength || 0} / {MAX_LOCALES}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction="column">
                                        <Grid item className={classes.list}>
                                            <Grid container spacing={3} justify="flex-start" alignItems="center">
                                                {selectedLocales?.map(locale => {
                                                    return (
                                                        <Grid key={locale?.value} item xs={6}>
                                                            <ListSelect
                                                                isSelected={false}
                                                                icon={locale?.icon}
                                                                name={locale?.label}
                                                                onClose={() => handleDelete(locale)}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {selectedLocaleLength < MAX_LOCALES && (
                                <Grid item container spacing={2} onClick={handleClick} className={classes.addWrapper}>
                                    <Grid item className={classes.addIcon}>
                                        <AddIconSVGFilled />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.primaryColor} variant="body1">
                                            {t('locales_add_another_language')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="space-around"
                                    direction="column"
                                    className={classes.banner}
                                    spacing={3}
                                >
                                    <Grid item>
                                        <LocaleBannerSVG />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">
                                            <span role="img" aria-label="emoji">
                                                ✨
                                            </span>{' '}
                                            {t('locales_work_with_more_locales')}{' '}
                                            <span role="img" aria-label="emoji">
                                                ✨
                                            </span>{' '}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.mxw500}>
                                        <Typography variant="caption">
                                            {t('locales_dont_let_the_locales_limit')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <ButtonBase width={120} variant="contained" onClick={handleUpgrade}>
                                            <UpgradeSVG />
                                            {t('global_upgrade')}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Popper className={classes.zIndex} id={id} open={open} anchorEl={anchorEl} transition>
                        <Paper className={classes.paper}>
                            <CellBaseVirtualize
                                options={DROPDOWN_OPTIONS}
                                placeholder={t('locale_select_search_placeholder')}
                                onChange={handleLocalesChange}
                                maxSize={200}
                                defaultValue={selectedLocales}
                                onBlur={handleClickAway}
                                isMulti
                            />
                        </Paper>
                    </Popper>
                    <ModalBase open={openDeleteConfirm} handleClose={handleCloseDeleteConfirmModal}>
                        <ConfirmDeleteLocale
                            lang={deleteOptionRef.current?.value}
                            handleAgreed={handleDeleteLocale}
                            handleCancel={handleCloseDeleteConfirmModal}
                        />
                    </ModalBase>
                </Grid>
            )}
        </SettingContentLayout>
    );
}

export default React.memo(Locale);
