import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import SettingContentLayout from 'permission/common/Layout';
import { useTranslation } from 'react-i18next';
import { useSelfHostingLicense } from 'hooks/app';
import { getFriendlyTime } from 'utils/datetime';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(4),
        padding: theme.spacing(3),
        border: `1px solid ${theme.colors.lightGrey}`,
        borderRadius: 4,
        width: 465
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '100px auto',
        gap: theme.spacing(3, 5)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    activeText: {
        color: theme.colors.dodgerBlue
    }
}));

function LicenseSetting() {
    const { t } = useTranslation();
    const classes = useStyles();
    const license = useSelfHostingLicense();

    return (
        <SettingContentLayout name={t('license_information')}>
            <div direction="column" wrap="nowrap" className={classes.root}>
                <div className={classes.container}>
                    <div>
                        <Typography variant="body2">{t('license_status')}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1" className={classes.activeText}>
                            {license?.status}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="body2">{t('contact_name')}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1">{license?.contactName}</Typography>
                    </div>
                    <div>
                        <Typography variant="body2">{t('contact_phone')}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1">{license?.contactPhone}</Typography>
                    </div>
                    <div>
                        <Typography variant="body2">{t('contact_email')}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1">{license?.contactEmail}</Typography>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.container}>
                    <div>
                        <Typography variant="body2">{t('activated_date')}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1">{getFriendlyTime(license?.activatedDate)}</Typography>
                    </div>
                    <div>
                        <Typography variant="body2">{t('expired_date')}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1">{getFriendlyTime(license?.expiredDate)}</Typography>
                    </div>
                </div>
            </div>
        </SettingContentLayout>
    );
}

export default React.memo(LicenseSetting);
