import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import InputPhone from 'components/inputs/InputPhone';
import ButtonBase from 'components/button/Base';
import { useFormik } from 'formik';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import Tooltip from 'components/tooltip/Base';
import * as paymentActions from 'payment/actions';
import { useDispatch } from 'react-redux';
import LDSelectBase from 'components/selects/LDBase';
import CountryCodes from 'components/inputs/CountryCodes';
import ReactCountryFlag from 'react-country-flag';
import { mixed, object, string } from 'yup';
import { INPUT_SPACING } from 'const/style';
import { useTranslation } from 'react-i18next';

const countryOptions = CountryCodes.map(country => {
    return {
        icon: () => <ReactCountryFlag countryCode={country.code} svg style={{ width: 24 }} />,
        value: country.code,
        label: country.name
    };
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '65%'
    },
    buttonWrapper: {
        position: 'relative',
        alignSelf: 'flex-start'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    countryCode: {
        minWidth: 'unset'
    },
    countryCodeMenu: {
        width: props => (props.countryCodeAnchor?.offsetWidth ? props.countryCodeAnchor?.offsetWidth - 10 : 300)
    },
    error: {
        fontSize: 11,
        color: theme.colors.burntSlena,
        width: '100%',
        textAlign: 'left',
        marginTop: INPUT_SPACING,
        position: props => (props.disableErrorAbsolute ? 'static' : 'absolute'),
        left: 5,
        bottom: -13
    },
    countryError: {
        border: `1px solid ${theme.colors.burntSlena} !important`
    }
}));

const BillingInfoSchema = object({
    billingEmail: string()
        .email('Invalid Email')
        .required('This field is required'),
    companyName: string().required('This field is required'),
    countryCode: mixed().required('This field is required')
});

function EditBillingInfo({ billingInfo, onBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [countryCodeAnchor, setCountryCodeAnchor] = useState(null);
    const countryCodeRef = useRef();
    const classes = useStyles({ countryCodeAnchor });

    useEffect(() => {
        setCountryCodeAnchor(countryCodeRef.current);
    }, []);

    const formik = useFormik({
        initialValues: {
            firstName: billingInfo?.firstName,
            lastName: billingInfo?.lastName,
            phone: billingInfo?.phone,
            billingEmail: billingInfo?.email,
            companyName: billingInfo?.companyName,
            taxCode: billingInfo?.taxCode,
            city: billingInfo?.city,
            address: billingInfo?.address,
            countryCode: countryOptions.find(option => option.value === billingInfo?.countryCode)
        },
        validationSchema: BillingInfoSchema,
        enableReinitialize: true,
        onSubmit: values => {
            setIsSubmitting(true);
            dispatch(
                paymentActions.updateBillingInfo({
                    billingInfo: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        phone: values.phone,
                        email: values.billingEmail,
                        companyName: values.companyName,
                        taxCode: values.taxCode,
                        countryCode: values.countryCode?.value,
                        city: values.city,
                        address: values.address
                    },
                    errorCallback: () => {
                        setIsSubmitting(false);
                        console.log('updateBillingInfo failed');
                    },
                    successCallback: () => {
                        setIsSubmitting(false);
                        console.log('updateBillingInfo success');
                        onBack();
                    }
                })
            );
        }
    });

    const { errors, values, handleChange, handleSubmit, setFieldValue } = formik;

    const changeCountryOption = useCallback(
        option => {
            handleChange({
                target: {
                    name: 'countryCode',
                    value: option
                }
            });
        },
        [handleChange]
    );

    return (
        <form onSubmit={handleSubmit} className={classes.root}>
            <Grid container spacing={4} direction="column">
                <Grid item container spacing={4} wrap="nowrap">
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('global_first_name')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText name="firstName" value={values.firstName} onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('global_last_name')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText name="lastName" value={values.lastName} onChange={handleChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={4} wrap="nowrap">
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('phone_number')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputPhone
                                name="phone"
                                value={values.phone}
                                onChange={value => setFieldValue('phone', value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="body1">Billing email</Typography>
                            </Grid>
                            <Tooltip title="Receipt will be sent to the company owner email and this billing email">
                                <Grid item style={{ display: 'flex' }}>
                                    <InfoIconSVG size={16} />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <InputText
                                name="billingEmail"
                                value={values.billingEmail}
                                onChange={handleChange}
                                error={errors.billingEmail}
                                errorText={errors.billingEmail}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={4} wrap="nowrap">
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('company_name')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText
                                name="companyName"
                                value={values.companyName}
                                error={Boolean(errors.companyName)}
                                errorText={errors.companyName}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('country')}</Typography>
                        </Grid>
                        <Grid item style={{ position: 'relative' }}>
                            <LDSelectBase
                                controlClassName={errors.countryCode ? classes.countryError : ''}
                                menuPlaceholder={'Find a country'}
                                options={countryOptions}
                                onChange={changeCountryOption}
                                defaultValue={values.countryCode}
                                isMulti={false}
                            />
                            {errors.countryCode && <div className={classes.error}>{errors.countryCode}</div>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={4} wrap="nowrap">
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('city')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText name="city" value={values.city} onChange={handleChange} />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="body1">
                                    {t('country_code')} &amp; {t('vat_number')}
                                </Typography>
                            </Grid>
                            <Tooltip title={t('vat_explain')}>
                                <Grid item style={{ display: 'flex' }}>
                                    <InfoIconSVG size={16} />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid item ref={countryCodeRef}>
                            <Grid container spacing={1} wrap="nowrap">
                                <Grid item style={{ width: '110px' }}>
                                    <LDSelectBase
                                        dropdownClassName={classes.countryCode}
                                        menuClassName={classes.countryCodeMenu}
                                        ddPlaceholder={''}
                                        menuPlaceholder={'Find a country'}
                                        options={countryOptions}
                                        onChange={changeCountryOption}
                                        defaultValue={
                                            values.countryCode
                                                ? { ...values.countryCode, label: values.countryCode.value }
                                                : null
                                        }
                                        isMulti={false}
                                    />
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <InputText name="taxCode" value={values.taxCode} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction="column">
                    <Grid item>
                        <Typography variant="body1">{t('address')}</Typography>
                    </Grid>
                    <Grid item>
                        <InputText name="address" value={values.address} onChange={handleChange} />
                    </Grid>
                </Grid>
                <Grid item container wrap="nowrap" spacing={2}>
                    <Grid item className={classes.buttonWrapper}>
                        <ButtonBase variant="contained" type="submit" disabled={isSubmitting}>
                            Save
                        </ButtonBase>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={onBack} variant="outlined" type="submit" width={120}>
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default React.memo(EditBillingInfo);
