import React from 'react';
import { IconButton } from '@material-ui/core';
import CheckedBoolean from 'assets/images/svg/CheckedBoolean';
import PartialCheckedBoolean from 'assets/images/svg/PartialCheckedBoolean';
import UncheckedBoolean from 'assets/images/svg/UncheckedBoolean';

function ThreeStatesCheckbox({ state, onChange, className, disabled }) {
    const handleChange = e => {
        let nextState = state;
        nextState++;
        if (nextState > 2) {
            nextState = 0;
        }
        onChange(e, nextState);
    };

    const render = () => {
        switch (state) {
            case 0:
                return <PartialCheckedBoolean />;
            case 1:
                return <CheckedBoolean />;
            case 2:
                return <UncheckedBoolean />;
            default:
                return <PartialCheckedBoolean />;
        }
    };

    return (
        <IconButton className={className} disabled={disabled} onClick={handleChange}>
            {render()}
        </IconButton>
    );
}

export default React.memo(ThreeStatesCheckbox);
