import * as types from '../types';
import produce from 'immer';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import * as columnTypes from 'const/columnTypes';

const handler = {
    [types.IMPORT_REVIEW_FILE_REQUEST](state) {
        const viewImport = state?.viewImport || {};

        return {
            ...state,
            viewImport: {
                ...viewImport,
                loading: true
            }
        };
    },
    [types.IMPORT_READ_FILE_FAILED](state) {
        const viewImport = state?.viewImport || {};

        return {
            ...state,
            viewImport: {
                ...viewImport,
                loading: false
            }
        };
    },
    [types.IMPORT_READ_FILE_SUCCESS](state, { payload }) {
        const { file, data, columns, metaData, rows, hasRecordId, resourceKey, dependencyStatusData } = payload;
        return {
            ...state,
            viewImport: {
                loading: false,
                file: file,
                fileContent: {
                    columns,
                    data,
                    metaData,
                    rows,
                    hasRecordId,
                    dependencyStatusData
                },
                resourceKey
            }
        };
    },

    [types.IMPORT_FILE_SUCCESS](state, { payload }) {
        console.log({ ...payload });
        return {
            ...state
        };
    },
    [types.IMPORT_GRID_FOR_LOCALIZATION](state, { payload }) {
        const { localizationImport } = state;
        const newLocalizationImport = produce(localizationImport, draft => {
            draft.isFetching = true;
        });
        return {
            ...state,
            localizationImport: newLocalizationImport
        };
    },
    [types.IMPORT_GRID_FOR_LOCALIZATION_FAILED](state, { payload }) {
        const { error } = payload;
        const { localizationImport } = state;

        const newLocalizationImport = produce(localizationImport, draft => {
            draft.isFetching = false;
            draft.error = error;
        });
        return {
            ...state,
            localizationImport: newLocalizationImport
        };
    },
    [types.IMPORT_GRID_FOR_LOCALIZATION_SUCCESS](state, { payload }) {
        const {
            file,
            data,
            columns,
            metaData,
            rows,
            resourceKey,
            columnMap,
            firstRecord,
            dependencyStatusData
        } = payload;
        const { localizationImport } = state;
        const newLocalizationImport = produce(localizationImport, draft => {
            draft.isFetching = false;
            draft.file = file;
            draft.data = data;
            draft.columns = columns;
            draft.metaData = metaData;
            draft.rows = rows;
            draft.resourceKey = resourceKey;
            draft.columnMap = columnMap;
            draft.firstRecord = firstRecord;
            draft.dependencyStatusData = dependencyStatusData;
        });

        return {
            ...state,
            localizationImport: newLocalizationImport
        };
    },
    [types.IMPORT_LOCALIZATION_JSON_FILE](state, { payload }) {
        const { dbId } = payload;
        const { localizationJSONImport } = state;
        const newLocalizationJSONImport = produce(localizationJSONImport, draft => {
            if (!draft?.[dbId]) {
                draft[dbId] = {};
            }
            draft[dbId].isFetching = true;
        });

        return {
            ...state,
            localizationJSONImport: newLocalizationJSONImport
        };
    },
    [types.IMPORT_LOCALIZATION_JSON_FILE_FAILED](state, { payload }) {
        const { dbId, error } = payload;
        const { localizationJSONImport } = state;
        const newLocalizationJSONImport = produce(localizationJSONImport, draft => {
            if (!draft?.[dbId]) {
                draft[dbId] = {};
            }
            draft[dbId].isFetching = false;
            draft[dbId].error = error;
        });

        return {
            ...state,
            localizationJSONImport: newLocalizationJSONImport
        };
    },
    [types.IMPORT_LOCALIZATION_JSON_FILE_SUCCESS](state, { payload }) {
        const { dbId, resourceKey, file, tree, objectIds } = payload;
        const { localizationJSONImport } = state;
        const newLocalizationJSONImport = produce(localizationJSONImport, draft => {
            if (!draft?.[dbId]) {
                draft[dbId] = {};
            }
            draft[dbId].isFetching = false;
            draft[dbId].resourceKey = resourceKey;
            draft[dbId].file = file;
            draft[dbId].tree = tree;
            draft[dbId].objectIds = objectIds;
        });

        return {
            ...state,
            localizationJSONImport: newLocalizationJSONImport
        };
    },
    [types.IMPORT_LOCALIZATION_PO_FILE](state, { payload }) {
        const { dbId } = payload;
        const { localizationPOImport } = state;
        const newLocalizationPOImport = produce(localizationPOImport, draft => {
            if (!draft?.[dbId]) {
                draft[dbId] = {};
            }
            draft[dbId].isFetching = true;
        });

        return {
            ...state,
            localizationPOImport: newLocalizationPOImport
        };
    },
    [types.IMPORT_LOCALIZATION_PO_FILE_FAILED](state, { payload }) {
        const { dbId, error } = payload;
        const { localizationPOImport } = state;
        const newLocalizationPOImport = produce(localizationPOImport, draft => {
            if (!draft?.[dbId]) {
                draft[dbId] = {};
            }
            draft[dbId].isFetching = false;
            draft[dbId].error = error;
        });

        return {
            ...state,
            localizationPOImport: newLocalizationPOImport
        };
    },
    [types.IMPORT_LOCALIZATION_PO_FILE_SUCCESS](state, { payload }) {
        const { file, dbId, resourceKey, messages, headers, poTargetLanguage, messageKeys } = payload;
        const { localizationPOImport } = state;
        const newLocalizationPOImport = produce(localizationPOImport, draft => {
            if (!draft?.[dbId]) {
                draft[dbId] = {};
            }
            draft[dbId].isFetching = false;
            draft[dbId].resourceKey = resourceKey;
            draft[dbId].file = file;
            draft[dbId].messages = messages;
            draft[dbId].headers = headers;
            draft[dbId].poTargetLanguage = poTargetLanguage;
            draft[dbId].messageKeys = messageKeys;
        });

        return {
            ...state,
            localizationPOImport: newLocalizationPOImport
        };
    },
    [types.IMPORT_FILE_CREATE_GRID](state, { payload }) {
        const { gridScratchImport } = state;
        const newGridScratchImport = produce(gridScratchImport, draft => {
            draft.isFetching = true;
        });
        return {
            ...state,
            gridScratchImport: newGridScratchImport
        };
    },
    [types.IMPORT_FILE_CREATE_GRID_FAILED](state, { payload }) {
        const { error } = payload;
        const { gridScratchImport } = state;

        const newGridScratchImport = produce(gridScratchImport, draft => {
            draft.isFetching = false;
            draft.error = error;
        });
        return {
            ...state,
            gridScratchImport: newGridScratchImport
        };
    },
    [types.SAVE_IMPORT_GRID_COLUMN](state, { payload }) {
        const { columnId, index, columnName, columnType, columnTimezone } = payload;
        const { gridScratchImport } = state;
        const { columnMap } = gridScratchImport;

        const newColumnMap = produce(columnMap, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            if ([columnTypes.PATH_TAG, columnTypes.RECORD_ID]?.includes(columnType)) {
                const columnIdsMatched = Object.keys(draft)?.filter(
                    columnId => draft?.[columnId]?.columnType === columnType
                );

                for (let columnIdMatched of columnIdsMatched) {
                    draft[columnIdMatched] = {
                        ...draft[columnIdMatched],
                        columnType: columnTypes.MULTIPLE_LINES,
                        columnName: columnTypes.RECORD_ID ? 'Record ID' : 'Path'
                    };
                }
            }

            draft[columnId] = {
                ...draft?.[columnId],
                index,
                columnName,
                columnType,
                columnTimezone
            };

            return draft;
        });

        return {
            ...state,
            gridScratchImport: {
                ...gridScratchImport,
                columnMap: newColumnMap
            }
        };
    },
    [types.IMPORT_FILE_CREATE_GRID_SUCCESS](state, { payload }) {
        const { file, data, columns, metaData, rows, resourceKey, columnMap, firstRecord } = payload;
        const { gridScratchImport } = state;
        const newGridScratchImport = produce(gridScratchImport, draft => {
            draft.isFetching = false;
            draft.file = file;
            draft.data = data;
            draft.columns = columns;
            draft.metaData = metaData;
            draft.rows = rows;
            draft.resourceKey = resourceKey;
            draft.columnMap = columnMap;
            draft.firstRecord = firstRecord;
        });

        return {
            ...state,
            gridScratchImport: newGridScratchImport
        };
    },
    [types.SET_LOCALIZATION_COLUMN](state, { payload }) {
        const { columnId, body } = payload;
        const { localizationImport } = state;
        const { columnMap } = localizationImport;

        const newColumnMap = produce(columnMap, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            draft[columnId] = body;
        });

        return {
            ...state,
            localizationImport: {
                ...localizationImport,
                columnMap: newColumnMap
            }
        };
    },
    [types.RESET_LOCALIZATION_COLUMN](state, { payload }) {
        const { columnId } = payload;
        const { localizationImport } = state;
        const { columnMap } = localizationImport;

        const newColumnMap = produce(columnMap, draft => {
            if (draft[columnId]) {
                delete draft[columnId];
            }
        });

        return {
            ...state,
            localizationImport: {
                ...localizationImport,
                columnMap: newColumnMap
            }
        };
    },
    [types.CLEAR_ORIGINAL_OPTION_COLUMN](state, { payload }) {
        const { localizationImport } = state;
        const { columnMap } = localizationImport;

        const newColumnMap = produce(columnMap, draft => {
            const columnIds = Object.keys(draft);
            let originalColumnId = null;
            for (let i = 0; i < columnIds.length; i++) {
                const columnId = columnIds[i];
                const detail = columnMap[columnId];
                if (detail?.importType === TRANSLATION_TYPES.ORIGINAL_LANG) {
                    originalColumnId = columnId;
                    break;
                }
            }
            if (draft[originalColumnId]) {
                delete draft[originalColumnId];
            }
        });

        return {
            ...state,
            localizationImport: {
                ...localizationImport,
                columnMap: newColumnMap
            }
        };
    },
    [types.SET_TASK_ID_STATUS](state, { payload }) {
        const { taskId, status } = payload;
        const { importTasks } = state;

        return {
            ...state,
            importTasks: {
                ...importTasks,
                [taskId]: status
            }
        };
    }
};

export default handler;
