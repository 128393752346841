import React from 'react';

function TransformOwnerShipSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width="112" height="83" viewBox="0 0 112 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 83C46.5685 83 60 69.5685 60 53C60 36.4315 46.5685 23 30 23C13.4315 23 0 36.4315 0 53C0 69.5685 13.4315 83 30 83Z"
                fill="white"
            />
            <path
                d="M59.5 53C59.5 69.2924 46.2924 82.5 30 82.5C13.7076 82.5 0.5 69.2924 0.5 53C0.5 36.7076 13.7076 23.5 30 23.5C46.2924 23.5 59.5 36.7076 59.5 53Z"
                fill="#F8AD13"
                fillOpacity="0.8"
                stroke="#F8AD13"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.2189 35.5002C35.978 35.5326 40.6197 40.1322 40.5866 45.7737C40.5536 51.4152 35.8581 55.9623 30.0991 55.9299C24.34 55.8974 19.6982 51.2978 19.7313 45.6563C19.7644 40.0148 24.4599 35.4678 30.2189 35.5002ZM38.5223 61.2646H21.6777C16.0628 61.2646 11.25 65.9791 11.25 71.4794C16.0628 76.9797 22.4798 80.1228 30.5011 80.1228C37.7202 80.1228 44.9394 76.194 48.95 71.4794C48.95 65.9791 44.1372 61.2646 38.5223 61.2646Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82 83C98.5685 83 112 69.5685 112 53C112 36.4315 98.5685 23 82 23C65.4315 23 52 36.4315 52 53C52 69.5685 65.4315 83 82 83Z"
                fill="white"
            />
            <path
                d="M111.5 53C111.5 69.2924 98.2924 82.5 82 82.5C65.7076 82.5 52.5 69.2924 52.5 53C52.5 36.7076 65.7076 23.5 82 23.5C98.2924 23.5 111.5 36.7076 111.5 53Z"
                fill="#2D97F2"
                fillOpacity="0.8"
                stroke="#2D97F2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.2189 35.5002C87.978 35.5326 92.6197 40.1322 92.5866 45.7737C92.5536 51.4152 87.8581 55.9623 82.0991 55.9299C76.34 55.8974 71.6982 51.2978 71.7313 45.6563C71.7644 40.0148 76.4599 35.4678 82.2189 35.5002ZM90.5223 61.2646H73.6777C68.0628 61.2646 63.25 65.9791 63.25 71.4794C68.0628 76.9797 74.4798 80.1228 82.5011 80.1228C89.7202 80.1228 96.9394 76.194 100.95 71.4794C100.95 65.9791 96.1372 61.2646 90.5223 61.2646Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.5 0.5H31.8571V1.5H30.5V2.875H29.5V0.5ZM39.2857 1.5H35.5714V0.5H39.2857V1.5ZM46.7143 1.5H43V0.5H46.7143V1.5ZM54.1429 1.5H50.4286V0.5H54.1429V1.5ZM61.5714 1.5H57.8571V0.5H61.5714V1.5ZM69 1.5H65.2857V0.5H69V1.5ZM76.4286 1.5H72.7143V0.5H76.4286V1.5ZM81.5 1.5H80.1429V0.5H82.5V2.51786H81.5V1.5ZM81.5 8.58929V5.55357H82.5V8.58929H81.5ZM30.5 6.625V10.375H29.5V6.625H30.5ZM81.5 13.1429V11.625H82.5V13.1429H81.5ZM30.5 14.125V16H29.5V14.125H30.5Z"
                fill="#D7D7DC"
            />
            <path
                d="M79.36 15.48C79.2117 15.2822 79.3528 15 79.6 15L84.4 15C84.6472 15 84.7883 15.2822 84.64 15.48L82.24 18.68C82.12 18.84 81.88 18.84 81.76 18.68L79.36 15.48Z"
                fill="#D7D7DC"
            />
        </svg>
    );
}

export default React.memo(TransformOwnerShipSVG);
