import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { ScrollSync, Grid } from 'react-virtualized-dn';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from './Header';
import CellRow from 'gridUI/recordHistory/Cell';
import { getWidthDistanceToIndex, getColumnData } from 'utils/gridUI/column';
import { SmoothScroll } from 'gridUI/table/grid/scroll';
import { useDispatch } from 'react-redux';
import { getTotalRowsHeight } from 'utils/gridUI/row';
import { DISABLED_OPACITY } from 'const/style';
import { checkIsCellUpdateOrDelete } from 'utils/gridUI/formatData';
import {
    RECORDS_OFFSET_BOTTOM,
    RECORDS_OFFSET_TOP,
    INDEX_COLUMN_WIDTH,
    OFFSET_FETCH,
    RECORDS_RENDER,
    LIMIT_ROWS,
    DEFAULT_COLUMN_WIDTH,
    PREVIEW_STATUS,
    BORDER_HIGHLIGHT,
    FAKE_ROW,
    CONFLICT_TYPES,
    ROW_HEIGHT,
    LOCALIZATION_FONT
} from 'const/gridUI';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { isLDEmpty } from 'utils/object';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import Tooltip from 'components/tooltip/Base';
import { SCROLLBAR_RELEASE_TIME, SYSTEM_COLUMN_IDS } from 'const';
import HeaderFilter from './filter';
import capitalize from 'lodash/capitalize';
import classNames from 'classnames';
import { isChrome, isMac, isWindows } from 'utils/os';

const PREFIX = `_mergeGrid`;

const useStyles = makeStyles(theme => ({
    GridUIRow: {},
    leftCell: {
        outline: 'none',
        overflow: 'hidden !important'
    },
    rightCell: {
        outline: 'none',
        overflow: 'hidden !important'
    },
    rightCellGridUI: {
        scrollBehavior: 'smooth',
        outline: 'none'
    },
    HeaderGridUI: {
        width: '100%',
        overflow: 'hidden !important',
        outline: 'none'
    },
    LeftSideGridUI: {
        overflow: 'hidden !important',
        outline: 'none'
    },
    GridUIColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    BodyGridUI: {
        width: '100%',
        outline: 'none'
    },
    cell: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    cellRow: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: theme.colors.white,
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },

    cellHeader: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    indexCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        background: `transparent`,
        overflow: 'hidden'
    },
    cellSelected: {
        border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight} !important`
    },
    cellBackgroundSelected: {
        background: theme.colors.selectionColor
    },
    header: {
        fontWeight: 'bold'
    },
    headerSelected: {
        background: `${theme.colors.lightGrey} !important`
    },
    footer: {
        background: `${theme.colors.white} !important`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        padding: theme.spacing(2)
    },
    headerFilter: {
        background: theme.colors.white
    },
    uiSelected: {
        background: theme.colors.selectionColor
    },
    reorderCellHighLight: {
        boxShadow: `inset 1px 0px 0px 0px ${theme.colors.highlight}`
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    scrollOverLay: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 100
    },
    simpleBar: {
        height: '100%',
        '& .simplebar-track': {
            pointerEvents: 'auto'
        }
    },
    infoIcon: {
        position: 'absolute',
        top: 10,
        right: 4
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    },
    animation: {
        animation: `$spin 500ms infinite linear`
    }
}));

function GridUIExample({
    width,
    maxHeight,
    columns,
    totalRecords,
    rows,
    metaData,
    data,
    rowHeight = 36,
    HEADER_ROW_HEIGHT = 36,
    AGG_HEIGHT,
    fixedColumnCount = 0,
    fixedRowCount = 2,
    overscanColumnCount = 0,
    overscanRowCount = 0,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    columnWidthStore = {},
    accessEditCell,
    accessViewRecordHistory,
    accessManageGridRecord,
    accessEditColumn,
    accessSupportSelectAllCheckbox,
    contextMenuId,
    tree,
    isFetchingMore,
    branchId,
    parentGridId,
    dbId,
    recordMetaData,
    onFetchingMore,
    isOverride,
    resolveConflictData,
    onConflictChanged,
    branchName,
    conflictType,
    mergeToBranchName,
    aggregations,
    onAggregationChange,
    quickFilters = {},
    onRefreshGrid,
    onExtraFilterChange,
    onQuickFilterChange,
    noneFooter
}) {
    const isRowOverLimit = React.useMemo(() => {
        return totalRecords > LIMIT_ROWS;
    }, [totalRecords]);

    const classes = useStyles({ borderNumber: isRowOverLimit ? 2 : 1 });
    const columnCount = useMemo(() => columns.length, [columns.length]);
    const heightLeftGridUI = useMemo(() => HEADER_ROW_HEIGHT * fixedRowCount, [HEADER_ROW_HEIGHT, fixedRowCount]);
    const theme = useTheme();
    const height = useMemo(() => maxHeight - AGG_HEIGHT, [maxHeight, AGG_HEIGHT]);
    const topRef = useRef(0);
    const leftRef = useRef(0);
    const onScrollRef = useRef();
    const scrollPropsRef = useRef();
    const dispatch = useDispatch();

    //GridUILayoutRef
    const gridRef = useRef();
    const freezingGridUIRef = useRef();
    const leftHeaderRef = useRef();
    const rightHeaderRef = useRef();

    //scrolling
    const [scrollStartIndex, setScrollStartIndex] = useState(0);

    const [scrollStopIndex, setScrollStopIndex] = useState(0);

    const [isFetchingMoreRecords, setIsFetchingMoreRecords] = useState(false);

    const getWidthDistanceFrom0ToIndex = useCallback(
        index => {
            return getWidthDistanceToIndex({ fromIndex: 0, toIndex: index, columnWidthStore, columns });
        },
        [columns, columnWidthStore]
    );

    React.useEffect(() => {
        if (scrollBarRef.current) {
            scrollBarRef.current.recalculate();
        }
    }, [columnCount]);

    const getWidthDistanceFromIndexToIndex = useCallback(
        ({ fromIndex, toIndex }) => {
            return getWidthDistanceToIndex({ fromIndex, toIndex, columnWidthStore, columns });
        },
        [columns, columnWidthStore]
    );

    const getColumn = useCallback(
        columnIndex => {
            return getColumnData({ columnIndex, columns, metaData });
        },
        [columns, metaData]
    );

    //this is get cell data
    const getCellData = useCallback(
        ({ columnIndex, rowIndex }) => {
            const columnId = columns[columnIndex];
            if (rowIndex < ROW_START_INDEX) return null;
            const rowId = rows?.[rowIndex - ROW_START_INDEX];

            if (!rowId) return null;
            const rowData = data?.[rowId]?.[columnId];
            return rowData;
        },
        [rows, data, columns, ROW_START_INDEX]
    );

    const getAllCellByRowId = useCallback(
        ({ rowIndex }) => {
            if (rowIndex < ROW_START_INDEX) return null;
            const rowId = rows?.[rowIndex - ROW_START_INDEX];
            if (!rowId) return null;
            const rowData = data?.[rowId];
            return rowData;
        },
        [rows, data, ROW_START_INDEX]
    );

    const getColumnWidth = useCallback(
        ({ index }) => {
            const column = metaData?.[columns?.[index]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            const realColumnWidth = columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH;
            return realColumnWidth;
        },
        [columns, metaData, columnWidthStore]
    );

    const getLeftGridColumnWidth = useCallback(
        ({ index }) => {
            if (index === 0) {
                return INDEX_COLUMN_WIDTH;
            }
            const newIndex = index - 1;
            const column = metaData?.[columns?.[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            const realColumnWidth = columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH;
            return realColumnWidth;
        },
        [columns, metaData, columnWidthStore]
    );

    const getRightSectionColumnWidth = useCallback(
        ({ index }) => {
            let newIndex = index + fixedColumnCount;
            const column = metaData?.[columns?.[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            const realColumnWidth = columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH;
            return realColumnWidth;
        },
        [columns, metaData, columnWidthStore, fixedColumnCount]
    );

    const getRowHeight = useCallback(
        ({ index }) => {
            // const rowId = rows[index - ROW_START_INDEX];
            // const realRowHeight = recordMetaData?.[rowId] || rowHeight;
            return rowHeight;
        },
        [rowHeight]
    );

    const getTotalFreezingWidth = useCallback(() => {
        let arrs = Array.from(Array(fixedColumnCount).keys());
        let total = arrs.reduce((total, columnIndex) => (total += getColumnWidth({ index: columnIndex })), 0);
        return total + INDEX_COLUMN_WIDTH;
    }, [fixedColumnCount, getColumnWidth]);

    const getTotalWidthWithIndex = useMemo(() => {
        let arrs = Array.from(Array(fixedColumnCount).keys());
        let total = arrs.reduce((total, num) => (total += getColumnWidth({ index: num })), INDEX_COLUMN_WIDTH);
        return total;
    }, [fixedColumnCount, getColumnWidth]);

    const totalLeftOfColumns = useCallback(() => {
        let total = columns?.length * DEFAULT_COLUMN_WIDTH + INDEX_COLUMN_WIDTH;
        return total;
    }, [columns]);

    const getBackground = React.useCallback(
        status => {
            switch (status) {
                case PREVIEW_STATUS.DELETED:
                    return theme.colors.cherub;
                case PREVIEW_STATUS.UPDATED:
                    return theme.colors.hawkesBlue;
                case PREVIEW_STATUS.ADDED:
                    return theme.colors.grannyApple;
                case PREVIEW_STATUS.PARENT_DELETED:
                case PREVIEW_STATUS.CONFLICT:
                    return theme.colors.lightSun;
                default:
                    return undefined;
            }
        },
        [theme]
    );

    /**
     * SELECTION:
     */

    const recomputeGridUI = () => {
        gridRef.current.recomputeGridSize();
        freezingGridUIRef.current.recomputeGridSize();
        leftHeaderRef.current.recomputeGridSize();
        rightHeaderRef.current.recomputeGridSize();
    };

    useEffect(() => {
        recomputeGridUI();
        if (scrollBarRef.current) {
            scrollBarRef.current.recalculate();
        }
    }, [columnWidthStore, rowHeight]);

    /**
     * Scroll Fetch
     */
    const timerScroll = useRef();

    useEffect(() => {
        const MainGridUI = document.getElementById(`RightCell${PREFIX}`);
        const HeaderGridUI = document.getElementById(`RightCellHeader${PREFIX}`);
        const LeftGridUI = document.getElementById(`LeftCell${PREFIX}`);
        // const FooterGridUI = document.getElementById(`RightCellFooter${PREFIX}`);

        const scrollbarOverlay = scrollBarRef.current.getScrollElement();

        const { _rowStartIndex, _rowStopIndex } = gridRef.current;
        setScrollStartIndex(_rowStartIndex);
        setScrollStopIndex(_rowStopIndex);

        SmoothScroll({
            target: scrollbarOverlay,
            speed: 40,
            smooth: 8,
            moveTopTargets: [LeftGridUI, MainGridUI],
            moveLeftTargets: [HeaderGridUI, MainGridUI]
        });
        SmoothScroll({
            target: MainGridUI,
            speed: 40,
            smooth: 8,
            moveTopTargets: [LeftGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, scrollbarOverlay]
        });
        SmoothScroll({
            target: LeftGridUI,
            speed: 40,
            smooth: 8,
            moveTopTargets: [MainGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, scrollbarOverlay],
            isIgnoreLeftScroll: true
        });
    }, [dispatch, width, height]);

    const _renderHeaderCell = useCallback(
        ({ columnIndex, rowIndex, key, style, position }) => {
            const column = getColumn(columnIndex);

            if (!column) return null;
            const headerClassName = rowIndex < 1 ? classes.header : `${classes.headerFilter} `;
            const columnStatus = column?.diffStatus;
            const isChanged = [PREVIEW_STATUS.ADDED, PREVIEW_STATUS.UPDATED]?.includes(columnStatus);

            return (
                <div
                    className={`
                        ${classes.cellHeader} 
                        ${headerClassName} 
                    `}
                    key={key}
                    style={{
                        ...style,
                        background: isChanged
                            ? getBackground(columnStatus)
                            : rowIndex < 1
                            ? theme.colors.paleGrey
                            : theme.colors.white
                    }}
                >
                    {rowIndex === 0 ? (
                        <Header
                            contextMenuId={contextMenuId}
                            accessEditColumn={accessEditColumn}
                            columnIndex={columnIndex}
                            position={position}
                            key={key}
                            column={column}
                            isSelectionActive={false}
                            setColumnHandlerStartPosition={() => {}}
                            isDragging={false}
                            setColumnReorderStartPosition={() => {}}
                            columns={columns}
                            isDisabled={false}
                            isImporting={false}
                        />
                    ) : (
                        <HeaderFilter
                            dbId={dbId}
                            key={key}
                            column={column}
                            quickSorts={{}}
                            quickFilters={quickFilters}
                            columnIndex={columnIndex}
                            onRefreshGrid={onRefreshGrid}
                            onExtraFilterChange={onExtraFilterChange}
                            onQuickFilterChange={onQuickFilterChange}
                            tree={tree}
                            conflictType={conflictType}
                        />
                    )}
                </div>
            );
        },
        [
            conflictType,
            contextMenuId,
            accessEditColumn,
            getColumn,
            classes,
            columns,
            getBackground,
            theme,
            quickFilters,
            onRefreshGrid,
            onExtraFilterChange,
            onQuickFilterChange,
            tree,
            dbId
        ]
    );

    const _renderFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const column = getColumn(columnIndex);
            if (!column) return <span key={key} />;

            const aggregation = aggregations?.[column?.id];
            const isSystemColumn = SYSTEM_COLUMN_IDS?.includes(column?.id);

            return (
                <div className={`${classes.cell} ${classes.footer}`} key={key} style={style}>
                    {/* <div style={{ display: 'flex' }}>
                        {!isSystemColumn && (
                            <RefreshSVG
                                onClick={() =>
                                    onAggregationChange({ columnId: column?.id, type: AGGREGATIONS_VALUES.conflict })
                                }
                            />
                        )}
                    </div> */}

                    {isSystemColumn ? (
                        <p className="caption">None</p>
                    ) : (
                        <p className="body2">
                            {capitalize(
                                conflictType === CONFLICT_TYPES.IMPORT
                                    ? 'updated'
                                    : aggregation?.aggregateType || 'conflicted'
                            )}
                            : <strong>{aggregation?.result || 0}</strong>
                        </p>
                    )}
                </div>
            );
        },
        [getColumn, classes, aggregations, conflictType]
    );

    const _renderIndexFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            return (
                <div
                    key={key}
                    style={{
                        ...style,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    className={`${classes.cell} ${classes.footer}`}
                >
                    {totalRecords - FAKE_ROW}
                </div>
            );
        },
        [classes, totalRecords]
    );

    const _renderLeftFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            if (columnIndex > fixedColumnCount || rowIndex > fixedRowCount) return;

            if (columnIndex === 0) {
                return _renderIndexFooterCell({ columnIndex, rowIndex, key, style });
            }
            const newColumnIndex = columnIndex - 1;

            return _renderFooterCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style
            });
        },
        [_renderIndexFooterCell, _renderFooterCell, fixedColumnCount, fixedRowCount]
    );

    const _renderRightFooterCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            return _renderFooterCell({
                columnIndex: newColumnIndex,
                rowIndex,
                key,
                style
            });
        },
        [_renderFooterCell, fixedColumnCount]
    );

    const _renderCell = useCallback(
        ({ columnIndex, rowIndex, key, style, position, parent }) => {
            const column = getColumn(columnIndex);
            const cellData = getCellData({ columnIndex, rowIndex });
            const rowDataByRowId = getAllCellByRowId({ rowIndex });
            const columnId = columns[columnIndex];
            const rowId = rows[rowIndex - ROW_START_INDEX];
            const { state } = parent;
            const { isScrolling } = state;
            const isUseSkeleton = (rowIndex < ROW_START_INDEX || rowIndex > ROW_STOP_INDEX) && isFetchingMore;
            const realRowHeight = getRowHeight({ index: rowIndex });
            const realColumnWidth = getColumnWidth({ index: columnIndex });
            const fontJP = LOCALIZATION_FONT?.[column?.group];

            const cellStatus = cellData?.status;

            const isCellBanned = [PREVIEW_STATUS.BEHIND, PREVIEW_STATUS.UNCHANGED].includes(cellStatus);
            const parentData = cellData?.parentData;
            const childData = cellData?.childData;

            const isCellConflict = cellStatus === PREVIEW_STATUS.CONFLICT;

            const cellConflictPickedResult = resolveConflictData?.[rowId]?.[columnId];
            const isPickedConflictResult = !!cellConflictPickedResult;

            const maxRowIndex = totalRecords - FAKE_ROW;
            const isLastRow = rowIndex === maxRowIndex;

            const getCurrentData = () => {
                if (isCellBanned) {
                    return parentData;
                } else if (isCellConflict) {
                    if (isPickedConflictResult) {
                        return cellConflictPickedResult === 'child' ? childData : parentData;
                    }
                    return isOverride ? childData : parentData;
                } else {
                    return childData;
                }
            };

            const getConflictMode = () => {
                if (isPickedConflictResult) {
                    return cellConflictPickedResult;
                }
                return isOverride ? 'child' : 'parent';
            };

            const currentData = getCurrentData();
            const rowStatus = rowDataByRowId?.status;

            const isRowDeletedOrAdded = [
                PREVIEW_STATUS.DELETED,
                PREVIEW_STATUS.ADDED,
                PREVIEW_STATUS.PARENT_DELETED
            ].includes(rowStatus);
            const columnStatus = column?.diffStatus;
            const columnType = cellData?.childColumnType;
            const previousColumnType = cellData?.parentColumnType || cellData?.childColumnType;
            const isSameColumnType = columnType === previousColumnType;
            const isColumnAdded = columnStatus === PREVIEW_STATUS.ADDED;
            const previousDependencyStatus =
                cellData?.parentDependencyStatus ||
                cellData?.previousDependencyStatus ||
                cellData?.destinationDependencyStatus;
            const dependencyStatus =
                cellData?.childDependencyStatus || cellData?.dependencyStatus || cellData?.sourceDependencyStatus;

            //check source status
            const previousSourceStatus = cellData?.previousSourceStatus || cellData?.destinationSourceStatus;
            const sourceStatus = cellData?.sourceStatus || cellData?.sourceSourceStatus;

            const status = isCellConflict
                ? PREVIEW_STATUS.CONFLICT
                : isRowDeletedOrAdded || isColumnAdded || isCellBanned
                ? PREVIEW_STATUS.NORMAL
                : !isSameColumnType && !isLDEmpty(parentData) && !isLDEmpty(currentData)
                ? PREVIEW_STATUS.UPDATE
                : checkIsCellUpdateOrDelete({
                      previousData: parentData,
                      data: currentData,
                      dependencyStatus,
                      previousDependencyStatus,
                      previousSourceStatus,
                      sourceStatus
                  });

            const background = isCellConflict
                ? getBackground(PREVIEW_STATUS.CONFLICT)
                : isColumnAdded
                ? getBackground(PREVIEW_STATUS.ADDED)
                : isRowDeletedOrAdded
                ? getBackground(rowStatus)
                : getBackground(status);

            if (rowIndex > maxRowIndex) return;

            if (isLastRow)
                return (
                    <div
                        key={key}
                        className={`RECORD_${rowId}`}
                        style={{
                            ...style,
                            pointerEvents: 'none',
                            background: 'transparent'
                        }}
                    />
                );

            return (
                <div
                    key={key}
                    className={`${classes.cellRow} 
                        cell_${rowIndex}_${columnIndex} 
                        ${fontJP}
                    `}
                    style={style}
                >
                    <CellRow
                        isMergeGrid={true}
                        dbId={dbId}
                        status={status}
                        position={position}
                        columnIndex={columnIndex}
                        background={background}
                        rowIndex={rowIndex}
                        columnId={columnId}
                        prefixId={`merge_preview`}
                        rowId={rowId}
                        column={column}
                        rowData={cellData}
                        isScrolling={isScrolling}
                        rowHeight={realRowHeight}
                        columnWidth={realColumnWidth}
                        isRowOverLimit={isRowOverLimit}
                        isUseSkeleton={isUseSkeleton}
                        editable={true}
                        isViewOnly={true}
                        rowDataByRowId={rowDataByRowId}
                        previousData={parentData}
                        currentData={currentData}
                        childData={childData}
                        columnType={columnType}
                        previousColumnType={previousColumnType}
                        isOverride={isOverride}
                        conflictMode={getConflictMode()}
                        onConflictChanged={onConflictChanged}
                        branchName={branchName}
                        mergeToBranchName={mergeToBranchName}
                        cellConflictPickedResult={cellConflictPickedResult}
                        conflictType={conflictType}
                        previousDependencyStatus={previousDependencyStatus}
                        dependencyStatus={dependencyStatus}
                        sourceStatus={sourceStatus}
                        previousSourceStatus={previousSourceStatus}
                    />
                </div>
            );
        },
        [
            totalRecords,
            conflictType,
            branchName,
            mergeToBranchName,
            onConflictChanged,
            resolveConflictData,
            dbId,
            getBackground,
            getColumnWidth,
            getAllCellByRowId,
            getRowHeight,
            getCellData,
            getColumn,
            classes,
            columns,
            rows,
            ROW_START_INDEX,
            isRowOverLimit,
            ROW_STOP_INDEX,
            isFetchingMore,
            isOverride
        ]
    );

    const _renderIndexHeaderCell = useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            return (
                <div
                    key={key}
                    style={style}
                    className={`${classes.cellHeader} ${rowIndex === 0 ? classes.header : ''}`}
                ></div>
            );
        },
        [classes]
    );

    const _renderLeftHeaderCell = useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            //render index column
            if (columnIndex === 0) {
                return _renderIndexHeaderCell({ columnIndex, rowIndex, key, style });
            }
            //render rest column of left grid.
            const leftColumnIndex = columnIndex - 1;
            // const columnWidth = getColumnWidth({ index: leftColumnIndex });
            const position = {
                left: getWidthDistanceFrom0ToIndex(leftColumnIndex) + INDEX_COLUMN_WIDTH,
                top: fixedRowCount * rowHeight
            };
            if (leftColumnIndex > fixedColumnCount || rowIndex > fixedRowCount) return;

            return _renderHeaderCell({
                columnIndex: leftColumnIndex,
                key,
                rowIndex,
                position,
                style
            });
        },
        [
            _renderIndexHeaderCell,
            _renderHeaderCell,
            fixedColumnCount,
            fixedRowCount,
            rowHeight,
            // getColumnWidth,
            getWidthDistanceFrom0ToIndex
        ]
    );

    const _renderRightHeaderCell = useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            // const columnWidth = getColumnWidth({ index: newColumnIndex });
            const position = {
                left:
                    getTotalFreezingWidth() +
                    getWidthDistanceFromIndexToIndex({ fromIndex: fixedColumnCount, toIndex: newColumnIndex }) -
                    leftRef.current,
                top: fixedRowCount * rowHeight
            };
            return _renderHeaderCell({
                columnIndex: newColumnIndex,
                rowIndex,
                key,
                style,
                position
            });
        },
        [
            _renderHeaderCell,
            fixedColumnCount,
            rowHeight,
            fixedRowCount,
            getWidthDistanceFromIndexToIndex,
            getTotalFreezingWidth
            // getColumnWidth,
        ]
    );

    const _renderIndexCell = useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const rowDataByRowId = getAllCellByRowId({ rowIndex });
            const rowStatus = rowDataByRowId?.status;
            const background = getBackground(rowStatus);

            const maxRowIndex = totalRecords - FAKE_ROW;
            const isLastRow = rowIndex === maxRowIndex;

            if (rowIndex > maxRowIndex) return;

            if (isLastRow)
                return (
                    <div
                        className={`${classes.indexCell}`}
                        key={key}
                        style={{
                            ...style,
                            background,
                            borderRight: 'none',
                            borderBottom: 'none'
                        }}
                    />
                );

            return (
                <div
                    key={key}
                    style={{
                        ...style,
                        background
                    }}
                    className={`${classes.indexCell}`}
                >
                    {rowIndex + 1}{' '}
                    {[PREVIEW_STATUS.PARENT_DELETED].includes(rowStatus) && (
                        <Tooltip title={'This record was deleted on Master branch'}>
                            <span className={classes.infoIcon}>
                                <InfoIconSVG size={16} />
                            </span>
                        </Tooltip>
                    )}
                </div>
            );
        },
        [classes, getAllCellByRowId, getBackground, totalRecords]
    );

    const _renderLeftCell = useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            //render index cell body
            if (columnIndex === 0) {
                return _renderIndexCell({ columnIndex, rowIndex, key, style, parent });
            }
            const newColumnIndex = columnIndex - 1;

            const position = {
                left: getWidthDistanceFrom0ToIndex(newColumnIndex) + INDEX_COLUMN_WIDTH,
                top: rowIndex * rowHeight + fixedRowCount * rowHeight - topRef.current
            };
            // const cellHeight = getRowHeight({ index: rowIndex });
            // const columnWidth = getColumnWidth({ index: newColumnIndex });

            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                position,
                style,
                isHeader: true,
                parent
            });
        },
        [_renderIndexCell, _renderCell, rowHeight, fixedRowCount, getWidthDistanceFrom0ToIndex]
    );

    const _renderRightCell = useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            const position = {
                left:
                    getTotalFreezingWidth() +
                    getWidthDistanceFromIndexToIndex({ fromIndex: fixedColumnCount, toIndex: newColumnIndex }) -
                    leftRef.current,
                top: rowIndex * rowHeight + fixedRowCount * rowHeight - topRef.current
            };
            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style,
                position,
                parent
            });
        },
        [
            _renderCell,
            fixedColumnCount,
            fixedRowCount,
            rowHeight,
            getTotalFreezingWidth,
            getWidthDistanceFromIndexToIndex
        ]
    );

    let fetchMoreRequests = useRef([]);
    let timerFetchingRef = useRef();
    let isDragScrollbar = useRef(false);

    const handleOnSectionRendered = useCallback(
        ({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, ...other }) => {
            const { state } = gridRef.current;
            const { scrollDirectionVertical } = state;

            /**
             * Draging scrollbar to another place.
             */

            if (rowStopIndex > ROW_STOP_INDEX || rowStartIndex < ROW_START_INDEX) {
                isDragScrollbar.current = true;
                let request = {
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                };
                fetchMoreRequests.current.push(request);
                if (timerScroll.current) clearTimeout(timerScroll.current);
                timerScroll.current = setTimeout(() => {
                    const data = fetchMoreRequests.current[fetchMoreRequests.current.length - 1];
                    if (data) {
                        fetchServer({
                            ...data,
                            successCallback: () => {
                                fetchMoreRequests.current = [];
                            },
                            errorCallback: () => {
                                fetchMoreRequests.current = [];
                            }
                        });
                    }
                }, 150);
            }

            if (
                scrollDirectionVertical === 1 &&
                !isFetchingMoreRecords &&
                rowStopIndex > scrollStopIndex + OFFSET_FETCH &&
                !isDragScrollbar.current
            ) {
                console.log('FETCH DOWN');
                isDragScrollbar.current = false;
                setIsFetchingMoreRecords(true);
                fetchServer({
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                });
            }

            function isUpFetching() {
                const value = Math.max(scrollStartIndex - OFFSET_FETCH, 0);
                return rowStartIndex > OFFSET_FETCH && rowStartIndex < value;
            }

            if (
                scrollDirectionVertical === -1 &&
                !isFetchingMoreRecords &&
                isUpFetching() &&
                !isDragScrollbar.current
            ) {
                console.log('FETCH UP');
                isDragScrollbar.current = false;
                setIsFetchingMoreRecords(true);
                fetchServer({
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                });
            }

            function fetchServer({ ROW_START_INDEX, ROW_STOP_INDEX, successCallback, errorCallback }) {
                if (timerFetchingRef.current) clearTimeout(timerFetchingRef.current);
                timerFetchingRef.current = setTimeout(() => {
                    onFetchingMore({
                        dbId,
                        gridId: branchId,
                        parentGridId,
                        ROW_START_INDEX: ROW_START_INDEX,
                        ROW_STOP_INDEX: ROW_STOP_INDEX,

                        successCallback: () => {
                            setScrollStartIndex(rowStartIndex);
                            setScrollStopIndex(rowStopIndex);
                            isDragScrollbar.current = false;
                            setIsFetchingMoreRecords(false);
                            successCallback && successCallback();
                        },
                        errorCallback: () => {
                            console.log('failed to load more');
                            recomputeGridUI();
                            errorCallback && errorCallback();
                        }
                    });
                }, 100);
            }
        },
        [
            onFetchingMore,
            branchId,
            parentGridId,
            dbId,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            isFetchingMoreRecords,
            scrollStopIndex,
            scrollStartIndex
        ]
    );

    const scrollBarRef = useRef();
    const scrollableNodeRef = React.useRef();

    const timerSimpleBarScroll = React.useRef();

    const handleOverlayScroll = React.useCallback(e => {
        const gridState = gridRef.current?.state;
        const scrollRef = scrollableNodeRef?.current;

        const isScrollHorizontalOnly =
            gridState?.scrollTop === scrollRef?.scrollTop && gridState?.scrollLeft !== scrollRef?.scrollLeft;

        if (timerSimpleBarScroll.current) clearTimeout(timerSimpleBarScroll.current);

        timerSimpleBarScroll.current = setTimeout(
            () => {
                const target = scrollableNodeRef?.current;
                onScrollRef.current({ scrollTop: target?.scrollTop, scrollLeft: target?.scrollLeft });
            },
            isScrollHorizontalOnly ? 0 : SCROLLBAR_RELEASE_TIME
        );
    }, []);

    return (
        <ScrollSync>
            {({ clientHeight, clientWidth, onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth }) => {
                return (
                    <div
                        style={{
                            width,
                            height: height
                        }}
                        className={classes.gridRow}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: heightLeftGridUI,
                                width
                            }}
                            id={'gridTable'}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    ref={leftHeaderRef}
                                    id={`LeftCellHeader${PREFIX}`}
                                    cellRenderer={_renderLeftHeaderCell}
                                    className={classes.HeaderGridUI}
                                    width={getTotalFreezingWidth()}
                                    height={heightLeftGridUI}
                                    rowHeight={ROW_HEIGHT}
                                    columnWidth={getLeftGridColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={fixedColumnCount + 1}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalFreezingWidth(),
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                            >
                                <Grid
                                    ref={rightHeaderRef}
                                    id={`RightCellHeader${PREFIX}`}
                                    className={classes.HeaderGridUI}
                                    scrollLeft={scrollLeft}
                                    cellRenderer={_renderRightHeaderCell}
                                    width={width - getTotalWidthWithIndex}
                                    overscanColumnCount={overscanColumnCount}
                                    height={heightLeftGridUI}
                                    rowHeight={ROW_HEIGHT}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: height - heightLeftGridUI,
                                width
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    id={`LeftCell${PREFIX}`}
                                    ref={freezingGridUIRef}
                                    scrollTop={scrollTop}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderLeftCell}
                                    className={classes.leftCell}
                                    width={getTotalFreezingWidth()}
                                    height={noneFooter ? height : height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getLeftGridColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={fixedColumnCount + 1}
                                />
                            </div>
                            <div
                                className={classes.scrollOverLay}
                                style={{
                                    left: getTotalWidthWithIndex,
                                    width: width - getTotalWidthWithIndex,
                                    height: height - fixedRowCount * HEADER_ROW_HEIGHT
                                }}
                            >
                                <SimpleBar
                                    autoHide={false}
                                    className={classNames(`simplebar`, {
                                        mac: isMac(),
                                        'window-chrome': isWindows() && isChrome()
                                    })}
                                    style={{
                                        maxWidth: width - getTotalFreezingWidth(),
                                        maxHeight: height - fixedRowCount * HEADER_ROW_HEIGHT
                                    }}
                                    ref={scrollBarRef}
                                    scrollableNodeProps={{ ref: scrollableNodeRef, onScroll: handleOverlayScroll }}
                                >
                                    <div
                                        style={{
                                            width: totalLeftOfColumns() - getTotalFreezingWidth() + INDEX_COLUMN_WIDTH,
                                            height: getTotalRowsHeight({ rowHeight, totalRecords, rows }),
                                            minHeight: height - 2 * HEADER_ROW_HEIGHT
                                        }}
                                    ></div>
                                </SimpleBar>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalWidthWithIndex,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                                className={classes.rightCellGridUI}
                            >
                                <Grid
                                    id={`RightCell${PREFIX}`}
                                    ref={gridRef}
                                    scrollTop={scrollTop}
                                    scrollLeft={scrollLeft}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderRightCell}
                                    className={classes.rightCell}
                                    onSectionRendered={handleOnSectionRendered}
                                    onScroll={props => {
                                        onScroll(props);
                                        onScrollRef.current = onScroll;
                                        scrollPropsRef.current = props;
                                        topRef.current = props.scrollTop;
                                        leftRef.current = props.scrollLeft;
                                        scrollableNodeRef.current.scrollTop = props?.scrollTop;
                                        scrollableNodeRef.current.scrollLeft = props?.scrollLeft;
                                    }}
                                    width={width - getTotalWidthWithIndex}
                                    height={noneFooter ? height : height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                        {!noneFooter && (
                            <>
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 10,
                                        bottom: 0,
                                        left: 0,
                                        height: AGG_HEIGHT,
                                        width
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            zIndex: 2,
                                            height: AGG_HEIGHT,
                                            width: width,
                                            borderTop: `1px solid ${theme.colors.border}`
                                        }}
                                        className={classes.rightCellGridUI}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                zIndex: 3,
                                                height: AGG_HEIGHT,
                                                width: getTotalFreezingWidth()
                                            }}
                                        >
                                            <Grid
                                                id="LeftCellFooter"
                                                cellRenderer={_renderLeftFooterCell}
                                                className={classes.HeaderGridUI}
                                                width={getTotalFreezingWidth()}
                                                height={AGG_HEIGHT}
                                                rowHeight={AGG_HEIGHT}
                                                columnWidth={getLeftGridColumnWidth}
                                                rowCount={1}
                                                columnCount={fixedColumnCount + 1}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: getTotalWidthWithIndex,
                                                top: 0,
                                                zIndex: 3,
                                                height: AGG_HEIGHT,
                                                width: width - getTotalWidthWithIndex
                                            }}
                                        >
                                            <Grid
                                                id="RightCellFooter"
                                                className={classes.HeaderGridUI}
                                                scrollLeft={scrollLeft}
                                                cellRenderer={_renderRightFooterCell}
                                                width={width - getTotalWidthWithIndex}
                                                overscanColumnCount={overscanColumnCount}
                                                height={AGG_HEIGHT}
                                                rowHeight={AGG_HEIGHT}
                                                columnWidth={getRightSectionColumnWidth}
                                                rowCount={1}
                                                columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <div
                            className={classes.freezingHandler}
                            id={`s${PREFIX}`}
                            style={{
                                position: 'absolute',
                                top: 0,
                                height: height + AGG_HEIGHT,
                                width: FREEZING_BAR_WIDTH,
                                background: theme.colors.resizeColor,
                                zIndex: 20,
                                left: getTotalFreezingWidth() - FREEZING_BAR_WIDTH
                            }}
                        ></div> */}
                            </>
                        )}
                    </div>
                );
            }}
        </ScrollSync>
    );
}

export default React.memo(GridUIExample);
