import React from 'react';

function LogoutSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.00005 0.699951C6.5737 0.699951 6.22788 1.04355 6.22788 1.46724V6.52003C6.22788 6.9458 6.57331 7.28977 7.00005 7.28977C7.42697 7.28977 7.76959 6.94539 7.76959 6.52249V1.46724C7.76959 1.04433 7.42697 0.699951 7.00005 0.699951Z"
                fill="#78778B"
            />
            <path
                d="M11.1202 3.3466C10.815 3.1091 10.3902 3.17218 10.1409 3.4474C9.81818 3.8038 9.91304 4.34602 10.2645 4.63289C11.4459 5.59718 12.1607 7.05441 12.1607 8.61781C12.1607 11.4524 9.84491 13.7629 7.00005 13.7629C4.15527 13.7629 1.84176 11.4525 1.84176 8.61781C1.84176 7.05921 2.55121 5.60942 3.72328 4.64615C4.07296 4.35878 4.16641 3.8181 3.84448 3.4626C3.59455 3.1866 3.16821 3.12406 2.86305 3.3633C1.27045 4.61187 0.300049 6.54026 0.300049 8.61781C0.300049 10.4029 0.996747 12.0838 2.2596 13.3447C3.52687 14.6028 5.21124 15.3 7.00005 15.3C8.79129 15.3 10.4754 14.6007 11.7381 13.3425C13.0058 12.0814 13.7 10.4003 13.7 8.61781C13.7 6.5324 12.7244 4.59516 11.1202 3.3466Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(LogoutSVG);
