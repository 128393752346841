import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IconTableSVG from 'assets/images/svg/IconTableSVG';
import IconListSVG from 'assets/images/svg/IconListSVG';
import { sendManualTrack } from 'tracker';

const VIEWS = {
    TABLE: 'table',
    LIST: 'list'
};
const useStyles = makeStyles(theme => ({
    root: {
        background: '#fff',
        border: `1px solid #D4CEEC`,
        borderRadius: 4,
        height: 36,
        overflow: 'hidden'
    },
    iconWrapper: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 14px',
        height: 36
    },
    iconSelected: {
        background: theme.colors.lightLavender
    }
}));

function ViewsSwitch({ value, onChange }) {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState(value || VIEWS.TABLE);

    const handleTableClick = () => {
        setSelectedValue(VIEWS.TABLE);
        onChange(VIEWS.TABLE);
        sendManualTrack({ type: '[Gridly] Choose Import Horizontal Mode' });
    };

    const handleListClick = () => {
        setSelectedValue(VIEWS.LIST);
        onChange(VIEWS.LIST);
        sendManualTrack({ type: '[Gridly] Choose Import Vertical Mode' });
    };

    return (
        <Grid className={`view-switch ${classes.root}`} container alignItems="center">
            <Grid
                item
                className={`${classes.iconWrapper} ${classes.iconLeft} ${
                    selectedValue === VIEWS.TABLE ? classes.iconSelected : ''
                }`}
                onClick={handleTableClick}
            >
                <IconTableSVG color={selectedValue === VIEWS.TABLE ? theme.colors.white : undefined} />
            </Grid>
            <Grid
                item
                className={`${classes.iconWrapper} ${classes.iconRight} ${
                    selectedValue === VIEWS.LIST ? classes.iconSelected : ''
                }`}
                onClick={handleListClick}
            >
                <IconListSVG color={selectedValue === VIEWS.LIST ? theme.colors.white : undefined} />
            </Grid>
        </Grid>
    );
}

export default React.memo(ViewsSwitch);
