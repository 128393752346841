import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Search from '../search';
import isEmpty from 'lodash/isEmpty';
import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    SPACING_LIST_NAME_WITH_ICON,
    SELECTION_LIST_MARGIN_TOP
} from 'const/style';
import Checkbox from 'components/checkbox/Base';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import OverflowTypography from 'components/textTruncated/OverflowTypography';

const useStyles = makeStyles(theme => ({
    root: {},
    search: {
        padding: `0px ${SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT}px`
    },
    noOption: {
        marginTop: 14,
        textAlign: 'center'
    },
    optionList: {
        minHeight: 100,
        maxHeight: 200,
        overflowY: 'auto',
        flexWrap: 'nowrap',
        marginTop: SELECTION_LIST_MARGIN_TOP
    },
    option: {
        padding: `0px ${SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT}px !important`,
        minHeight: SELECTION_LIST_ITEM_MIN_HEIGHT
    },
    name: {
        fontSize: '0.875rem',
        color: theme.colors.primaryText,
        '& svg': {
            position: 'relative',
            top: 2,
            marginRight: SPACING_LIST_NAME_WITH_ICON
        }
    },
    control: {
        '& span': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    }
}));

function ItemRender({ label, icon, checked, onItemClick, value }) {
    const classes = useStyles();
    return (
        <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.option}
            style={{ whiteSpace: 'nowrap' }}
        >
            <Grid item xs={10}>
                <Grid className={classes.name} wrap="nowrap" container alignItems="center" spacing={1}>
                    {icon && <Grid item>{icon}</Grid>}
                    <OverflowTypography variant="body2">{label}</OverflowTypography>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container justify="flex-end">
                    <Grid item className={classes.control}>
                        <Checkbox
                            onChange={e => onItemClick({ checked: e.target.checked, value })}
                            checked={checked}
                            style={{ padding: 0 }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

function SelectOnOff({ options = [], selectedValues = [], onChange, handleClickAway }) {
    const [items, setItems] = React.useState(options);
    const [filterValue, setFiltervalue] = React.useState('');
    const classes = useStyles();
    const theme = useTheme();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    React.useEffect(() => {
        setItems(options);
    }, [options]);

    return (
        <Paper ref={rootRef} className={classes.root} elevation={0}>
            <Grid container direction={'column'}>
                <Grid item className={classes.search}>
                    <Search
                        onChange={e => setFiltervalue(e.target.value)}
                        autoFocus
                        background={theme.colors.paleGrey}
                    />
                </Grid>
                {isEmpty(options) && (
                    <Grid className={classes.noOption}>
                        <Typography variant="caption">No Options Available</Typography>
                    </Grid>
                )}
                {!isEmpty(options) && (
                    <Grid className={classes.optionList} item container direction="column">
                        {items
                            .filter(item => {
                                return item.label.toLowerCase().includes(filterValue.toLowerCase());
                            })
                            .map(item => {
                                return (
                                    <ItemRender
                                        key={item.value}
                                        {...item}
                                        checked={selectedValues.findIndex(value => value === item.value) !== -1}
                                        onItemClick={onChange}
                                    />
                                );
                            })}
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}

export default React.memo(SelectOnOff);
