import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TEXT_LINE_HEIGHT, ROW_HEIGHT, RECORD_PANEL_EDITOR_ROW_HEIGHT } from 'const/gridUI';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: RECORD_PANEL_EDITOR_ROW_HEIGHT,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative',
        overflow: 'hidden'
    },
    richText: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'left',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        '& *': {
            lineHeight: `22px`
        },
        '& p': {
            marginTop: 0,
            marginBottom: 0,
            minHeight: 22
        },
        '& blockquote': {
            margin: `0px 0px 14px`,
            padding: `12px 24px`,
            borderLeft: `2px solid ${theme.colors.midGrey}`,
            borderRadius: 4,
            background: theme.colors.ghostwhite,
            lineHeight: `${TEXT_LINE_HEIGHT}px`
        },
        '& ul, & ol': {
            '& li': {
                marginBottom: 3
            },
            marginTop: 0,
            marginBottom: 14,
            borderRadius: 4
        }
    }
}));

function RichText({ value: cellValue, isReadOnly }) {
    const classes = useStyles({ isReadOnly });
    if (typeof cellValue !== 'string' && typeof cellValue !== 'number') {
        return <div className={classes.root} />;
    }

    return (
        <div className={classes.root}>
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cellValue, { USE_PROFILES: { html: true } }) }}
            />
        </div>
    );
}

export default React.memo(RichText);
