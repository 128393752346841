import * as types from '../types';

export function registerMergingTask(task) {
    return {
        type: types.REGISTER_MERGING_TASK,
        payload: {
            task
        }
    };
}
