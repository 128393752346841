import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    getBillingInfoApi,
    updateBillingInfoApi,
    getBillingHistoryApi,
    getReceiptInfoApi,
    getTaxRateApi,
    getCustomerApi
} from 'services/payment';

export function fetchBillingInfo({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchBillingInfoAction());

        try {
            const billingInfo = await getBillingInfoApi();
            dispatch(_fetchBillingInfoSuccess(billingInfo));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(_fetchBillingInfoFailed);
        }
    };
}

function _fetchBillingInfoAction() {
    return {
        type: types.FETCH_BILL_INFO
    };
}

function _fetchBillingInfoSuccess(billingInfo) {
    return {
        type: types.FETCH_BILL_INFO_SUCCESS,
        payload: {
            billingInfo
        }
    };
}

function _fetchBillingInfoFailed() {
    return {
        type: types.FETCH_BILL_INFO_FAILED
    };
}

export function updateBillingInfo({ billingInfo, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            await updateBillingInfoApi({ body: billingInfo });
            dispatch(_updateBillingInfo(billingInfo));
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Payment method has been changed',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateBillingInfo(billingInfo) {
    return {
        type: types.UPDATE_BILL_INFO,
        payload: {
            billingInfo
        }
    };
}

export function fetchBillingHistory({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchBillingHistoryAction());

        try {
            const billingHistory = await getBillingHistoryApi();
            dispatch(_fetchBillingHistorySuccess(billingHistory));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(_fetchBillingHistoryFailed);
        }
    };
}

function _fetchBillingHistoryAction() {
    return {
        type: types.FETCH_BILL_HISTORY
    };
}

function _fetchBillingHistorySuccess(billingHistory) {
    return {
        type: types.FETCH_BILL_HISTORY_SUCCESS,
        payload: {
            billingHistory
        }
    };
}

function _fetchBillingHistoryFailed() {
    return {
        type: types.FETCH_BILL_HISTORY_FAILED
    };
}

export function fetchReceiptInfo({ month, year, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchReceiptInfoAction());

        try {
            const receiptInfo = await getReceiptInfoApi({ month, year });
            dispatch(_fetchReceiptInfoSuccess(receiptInfo));
            successCallback && successCallback(receiptInfo);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(_fetchReceiptInfoFailed);
        }
    };
}

function _fetchReceiptInfoAction() {
    return {
        type: types.FETCH_RECEIPT_INFO
    };
}

function _fetchReceiptInfoSuccess(receiptInfo) {
    return {
        type: types.FETCH_RECEIPT_INFO_SUCCESS,
        payload: {
            receiptInfo
        }
    };
}

function _fetchReceiptInfoFailed() {
    return {
        type: types.FETCH_RECEIPT_INFO_FAILED
    };
}

export function fetchTaxRate({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            const taxRate = await getTaxRateApi();
            dispatch(_fetchTaxRateSuccess(taxRate?.percentage));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchTaxRateSuccess(taxRate) {
    return {
        type: types.FETCH_TAX_RATE_SUCCESS,
        payload: {
            taxRate
        }
    };
}

export function fetchCustomerAndSubscriptions({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            const customer = await getCustomerApi();
            dispatch(_fetchCustomerAndSubscriptionsSuccess(customer));
            successCallback && successCallback(customer);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchCustomerAndSubscriptionsSuccess(customer) {
    return {
        type: types.FETCH_CUSTOMER_AND_SUBSCRIPTIONS_SUCCESS,
        payload: {
            customer
        }
    };
}
