import React from 'react';

const GridContainerSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#2D97F2" fillOpacity="0.8" />
            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#2D97F2" strokeOpacity="0.8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.3421 4.5H5.65789C5.02105 4.5 4.5 5.05 4.5 5.72222V14.2778C4.5 14.95 5.02105 15.5 5.65789 15.5H14.3421C14.9789 15.5 15.5 14.95 15.5 14.2778V5.72222C15.5 5.05 14.9789 4.5 14.3421 4.5ZM14.3421 5.72222V7.55556H5.65789V5.72222H14.3421ZM8.55263 14.2778H11.4474V8.77778H8.55263V14.2778ZM5.65789 8.77778H7.39474V14.2778H5.65789V8.77778ZM12.6053 8.77778V14.2778H14.3421V8.77778H12.6053Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(GridContainerSVG);
