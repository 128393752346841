import * as Bowser from 'bowser';

export function isWindows() {
    return navigator.platform.indexOf('Win') > -1;
}

export function isMac() {
    return navigator.platform.indexOf('Mac') > -1;
}

export function isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function isChrome() {
    const webDetail = getWebInfo();
    return ['microsoft edge', 'chrome', 'chromium']?.includes(webDetail?.name?.toLowerCase());
}

export function getChromeVersion() {
    const webDetail = getWebInfo();

    const version = webDetail?.version?.split('.')?.[0];

    return version ? Number(version) : 0;
}

export function isBrowser() {
    return typeof window !== 'undefined';
}

export function getWebInfo() {
    const browserDetail = getBrowserDetail();
    return browserDetail?.browser;
}

export function getBrowserDetail() {
    const browser = Bowser.getParser(window?.navigator?.userAgent);
    return browser?.parsedResult;
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isElectron() {
    return /electron/i.test(navigator.userAgent);
}
