import React from 'react';
import { Grid } from '@material-ui/core';

function CommingSoon({ isSameType, previousColumnType, columnType }) {
    return (
        <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid item>
                <h3 className="prose prose-2xl font-medium">Comming Soon</h3>
            </Grid>
            <Grid item>
                <p className="body2">
                    More Info: {isSameType ? `${columnType}` : `${previousColumnType} -> ${columnType}`}
                </p>
            </Grid>
            <Grid item>
                <p className="body2">We will support soon!</p>
            </Grid>
        </Grid>
    );
}

export default React.memo(CommingSoon);
