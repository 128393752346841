import React from 'react';
import { Grid } from '@material-ui/core';
import File from '../components/File';
import isArray from 'lodash/isArray';
import { diffStatusArrayInOrderFile } from 'utils/object';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { PREVIEW_IMAGE_TYPES } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            cursor: 'zoom-in'
        }
    }
}));

function Files({
    previousData = [],
    data = [],
    isHideMarkIcon = false,
    dbId,
    columnId,
    rowId,
    rowIndex,
    onOpenFilePreview,
    conflictType
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const diffOptions = diffStatusArrayInOrderFile(previousData, data);

    const allowedPreview = React.useMemo(() => {
        return !conflictType;
    }, [conflictType]);

    const handleClickOpen = React.useCallback(
        ({ e, fileMeta }) => {
            e.stopPropagation();
            let body = {
                rowId: rowIndex,
                columnId,
                type: PREVIEW_IMAGE_TYPES.RECORD_HISTORY
            };

            if (fileMeta) {
                body = {
                    ...body,
                    defaultPreviewFile: fileMeta
                };
            }
            dispatch(gridUIActions.openCellFilePreview(body));
            onOpenFilePreview && onOpenFilePreview();
        },
        [columnId, onOpenFilePreview, rowIndex, dispatch]
    );

    if (!diffOptions || diffOptions?.length === 0 || !isArray(diffOptions)) return null;

    return (
        <Grid container alignItems="center" spacing={1}>
            {diffOptions?.map((option, index) => {
                const status = option?.status;
                const fileMeta = option?.key;
                return (
                    <Grid
                        onClick={e => (allowedPreview ? handleClickOpen({ e, fileMeta }) : () => {})}
                        item
                        key={index}
                        className={allowedPreview && classes.root}
                    >
                        <File
                            isHideMarkIcon={isHideMarkIcon}
                            fileMeta={fileMeta}
                            state={status}
                            width={28}
                            height={28}
                            dbId={dbId}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(Files);
