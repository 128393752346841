import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Button from 'components/button/Base';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    wrapper: {},
    link: {
        textDecoration: 'none'
    },
    button: {
        marginTop: 10
    }
}));

function ResetPasswordSuccess() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <AuthenticationWrapper>
            <AuthFormWrapper
                className={classes.wrapper}
                title={t(`auth_reset_password_success_title`)}
                subTitle={t(`auth_reset_password_success_subtitle`)}
            >
                <Link to="/signin" className={classes.link}>
                    <Button variant="contained" color="primary" className={classes.button}>
                        {t(`global_login`)}
                    </Button>
                </Link>
            </AuthFormWrapper>
        </AuthenticationWrapper>
    );
}

export default ResetPasswordSuccess;
