import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import { getColorByUserId } from 'utils/color';
import Tooltip from 'components/tooltip/Base';
import { getAvatarUrl } from 'utils/images';
import hexToRgba from 'hex-to-rgba';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';

const useStyles = makeStyles(theme => ({
    root: {},
    avatarItem: {
        position: 'relative',
        width: 25,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        margin: -3,
        borderRadius: '50%',
        cursor: 'pointer'
    },
    moreUsers: {
        background: `transparent !important`,
        color: theme.colors.primaryText,
        border: `1px solid ${theme.colors.border} !important`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    moreUsersTooltip: {
        padding: `12px`
    },
    name: {
        color: theme.colors.white
    }
}));

function UserAvatarListPreview({ users = [], hiddenNumber = 5 }) {
    const classes = useStyles();
    const userLength = React.useMemo(() => {
        return users.length;
    }, [users]);

    if (userLength === 0) return null;
    return (
        <Grid container className={classes.root}>
            {users.slice(0, hiddenNumber).map((user, index) => {
                const userAvatarUrl = getAvatarUrl(user?.id?.toString());

                const userColor = getColorByUserId(user.id);
                if (index === hiddenNumber - 1)
                    return (
                        <Tooltip
                            key={user.id}
                            title={
                                <Grid
                                    className={classes.moreUsersTooltip}
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    spacing={2}
                                >
                                    {users.slice(hiddenNumber - 1).map(user => {
                                        const userAvatarUrl = getAvatarUrl(user?.id?.toString());
                                        return (
                                            <Grid container alignItems="center" spacing={2} key={user.id} item>
                                                <Grid
                                                    item
                                                    className={classes.avatarItem}
                                                    style={{ border: `1px solid ${userColor}` }}
                                                >
                                                    <AvatarBase
                                                        src={userAvatarUrl}
                                                        style={{ background: hexToRgba(userColor, 0.8) }}
                                                        size={24}
                                                    >
                                                        <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                                    </AvatarBase>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.name} variant="body2">
                                                        {user.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            }
                        >
                            <Grid item className={classes.avatarItem}>
                                <AvatarBase className={classes.moreUsers} size={24}>
                                    +{userLength - hiddenNumber + 1}
                                </AvatarBase>
                            </Grid>
                        </Tooltip>
                    );
                return (
                    <Tooltip key={user.id} title={user?.name}>
                        <Grid item className={classes.avatarItem} style={{ border: `1px solid ${userColor}` }}>
                            <AvatarBase src={userAvatarUrl} style={{ background: hexToRgba(userColor, 0.8) }} size={24}>
                                <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                            </AvatarBase>
                        </Grid>
                    </Tooltip>
                );
            })}
        </Grid>
    );
}

export default React.memo(UserAvatarListPreview);
