import React, { useMemo } from 'react';

const SIZES = {
    small: {
        height: 32,
        width: 120
    },
    medium: {
        width: 160,
        height: 36
    },
    large: {
        width: 200,
        height: 40
    }
};

function getSize({ size, width, fullWidth }) {
    if (fullWidth) {
        return {
            ...SIZES[size],
            width: `100%`
        };
    }
    if (width) {
        return {
            ...SIZES[size],
            width
        };
    } else {
        return SIZES[size];
    }
}

const ButtonBase = React.forwardRef(
    (
        {
            size = 'medium',
            width,
            style,
            children,
            disabled,
            color = 'primary',
            variant = 'normal',
            textPrimary = false,
            className,
            fullWidth,
            ...rest
        },
        ref
    ) => {
        const { width: sizeWidth, height } = getSize({ size, width, fullWidth });

        const classNameCombined = useMemo(() => {
            let className = `btn`;

            if (textPrimary) {
                className += ` text-brand-main`;
            }

            if (variant === 'contained') {
                if (color === 'primary') {
                    className += ` btn-contained-primary`;
                } else if (color === 'secondary') {
                    className += ` btn-contained-secondary`;
                }
            }

            if (variant === 'outlined') {
                className += ` btn-outlined`;
            }

            if (variant === 'normal') {
                className += ` btn-normal`;
            }

            if (fullWidth) {
                className += ` w-full`;
            }

            if (disabled) {
                if (variant === 'contained') {
                    className += ` btn-contained-disabled`;
                } else if (variant === 'outlined') {
                    className += ` btn-outlined-disabled`;
                } else {
                    className += ` btn-disabled`;
                }
            }
            return className;
        }, [variant, fullWidth, color, disabled]);

        return (
            <button
                type="button"
                ref={ref}
                className={`${classNameCombined} ${className}`}
                style={{
                    width: width ? sizeWidth : ``,
                    height,
                    ...style
                }}
                {...rest}
            >
                {children}
            </button>
        );
    }
);

export default ButtonBase;
