import React from 'react';
import { Button as ButtonBase, Grid, Typography } from '@material-ui/core';
import Tooltip from 'components/tooltip/Base';
import { makeStyles, useTheme } from '@material-ui/styles';
import { DISABLED_OPACITY } from 'const/style';
import PopperMenu from 'components/menus/Popper';
import { useTranslation } from 'react-i18next';
import NormalSortSVG from 'assets/images/svg/NormalSortSVG';
import hexToRgba from 'hex-to-rgba';
import viewSortGif from 'assets/images/viewSort.gif';
import {
    useIsAdvancedSearchOwner,
    useIsBlockedGridByOwner,
    useIsHighlightSort,
    useSelectedColumns
} from 'hooks/advanced';
import SortMenu from '../sorts';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import scrollIntoView from 'scroll-into-view-if-needed';
import { generateTempId } from 'utils/uuid';
import { enqueueSnackbar } from 'notifier/actions';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: theme.colors.tutorialColor,
        padding: theme.spacing(3),
        borderRadius: 4,
        width: 252
    },
    spb2: {
        marginBottom: theme.spacing(1)
    },
    viewGift: {
        width: '100%',
        height: 'auto',
        borderRadius: 4
    },
    propertyButton: {
        display: 'flex',
        margin: `0px 4px`,
        '&:hover': {
            background: theme.colors.divider
        }
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    }
}));

export function generateDefaultViewSort(columns) {
    const firstColumn = columns?.[0];
    if (!firstColumn) return undefined;

    return {
        id: generateTempId(),
        hashColumnId: firstColumn?.hashColumnId,
        direction: '',
        oldValue: ''
    };
}

const ManageSort = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isHighLightSort = useIsHighlightSort();
    const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
    const columns = useSelectedColumns();
    const isBlockedGridByOwner = useIsBlockedGridByOwner();
    const isAdvancedSearchOwner = useIsAdvancedSearchOwner();

    const handleSortClick = React.useCallback(
        event => {
            setSortAnchorEl(sortAnchorEl ? null : event.currentTarget);
        },
        [sortAnchorEl]
    );

    const handleSortClickAway = React.useCallback(() => {
        setSortAnchorEl(null);
    }, []);

    const deleteViewSort = React.useCallback(
        ({ id, direction }) => {
            dispatch(
                advancedSearchActions.deleteViewSortOrder({
                    sortOrderId: id,
                    direction
                })
            );
        },
        [dispatch]
    );

    const onSortCreated = React.useCallback(() => {
        const node = document.getElementById('sortListBottom');

        scrollIntoView(node, {
            behavior: 'smooth',
            block: 'end',
            duration: 300,
            boundary: document.getElementById('sortListBoundary')
        });
    }, []);

    const onAddSort = React.useCallback(() => {
        let sortOrder = generateDefaultViewSort(columns);

        if (!sortOrder) {
            dispatch(
                enqueueSnackbar({
                    message: `Please add column to grid before create sort`,
                    type: 'info'
                })
            );
            return;
        }

        dispatch(advancedSearchActions.createViewSortOrder(sortOrder));
        onSortCreated && onSortCreated();
    }, [onSortCreated, columns, dispatch]);

    const _updateViewSort = React.useCallback(
        ({ id, newSort }) => {
            dispatch(
                advancedSearchActions.updateViewSortOrderOnly({
                    sortOrderId: id,
                    newSortOrder: newSort
                })
            );
        },
        [dispatch]
    );

    const updateViewSort = React.useCallback(
        ({ id, newSort }) => {
            dispatch(
                advancedSearchActions.updateViewSortOrder({
                    sortOrderId: id,
                    sortOrder: newSort
                })
            );
        },
        [dispatch]
    );

    if (isBlockedGridByOwner) return null;

    return (
        <>
            <Tooltip
                title={
                    <Grid container className={classes.tooltip} direction="column">
                        <Grid item className={classes.spb2}>
                            <img className={classes.viewGift} src={viewSortGif} alt="view sort gif" />
                        </Grid>
                        <Grid item>
                            <Typography variant="overline">{t('view_sort_tutorial_title')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">{t('view_sort_tutorial_text')}</Typography>
                        </Grid>
                    </Grid>
                }
            >
                <Grid item>
                    <ButtonBase
                        id="g-h-menu-item-sort-access"
                        className={`${classes.propertyButton} ${classes.button}`}
                        style={{
                            // background: isHighLightSort ? theme.colors.solitude : '',
                            background: isHighLightSort ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                            position: 'relative'
                        }}
                        onClick={handleSortClick}
                        tr-dt="Open Column Sorts Setting"
                    >
                        <NormalSortSVG
                            height={18}
                            width={18}
                            color={isHighLightSort ? theme.palette.primary.main : theme.colors.darkness}
                        />
                        {!!isHighLightSort && (
                            <div
                                style={{
                                    width: 6,
                                    height: 6,
                                    borderRadius: '50%',
                                    background: theme.colors.indicator,
                                    border: `1px solid ${theme.colors.solitude}`,
                                    position: 'absolute',
                                    right: 3,
                                    top: 3
                                }}
                            ></div>
                        )}
                    </ButtonBase>
                </Grid>
            </Tooltip>
            {sortAnchorEl && (
                <PopperMenu
                    placement={'bottom-start'}
                    anchorEl={sortAnchorEl}
                    handleClickAway={handleSortClickAway}
                    className={`${classes.popperMenu} small`}
                >
                    <SortMenu
                        onAddSort={onAddSort}
                        deleteViewSort={deleteViewSort}
                        updateViewSortState={_updateViewSort}
                        updateViewSort={updateViewSort}
                        columns={columns}
                        isReadOnly={!isAdvancedSearchOwner}
                    />
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ManageSort);
