import React from 'react';

const TwitterSVG = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.7492 4.66087C15.253 4.88063 14.7194 5.02725 14.1565 5.09662C14.7288 4.75687 15.169 4.215 15.3757 3.57487C14.8402 3.88875 14.2469 4.11937 13.6158 4.24088C13.1118 3.7065 12.3929 3.375 11.5964 3.375C10.0664 3.375 8.8263 4.60425 8.8263 6.12C8.8263 6.3345 8.85142 6.54338 8.89867 6.74625C6.59692 6.63075 4.55542 5.5365 3.18967 3.876C2.94967 4.28175 2.81467 4.75688 2.81467 5.25825C2.81467 6.21113 3.30255 7.05 4.04655 7.54425C3.59242 7.53038 3.16492 7.404 2.79067 7.2015C2.79067 7.20975 2.79067 7.22213 2.79067 7.23375C2.79067 8.56538 3.7458 9.67425 5.0118 9.92663C4.78042 9.99 4.53592 10.0252 4.28392 10.0252C4.10505 10.0252 3.93067 10.005 3.76192 9.97463C4.11442 11.0629 5.13705 11.8582 6.34867 11.8819C5.40067 12.6169 4.20667 13.0571 2.90842 13.0571C2.68417 13.0571 2.4648 13.0444 2.24805 13.0181C3.47467 13.7948 4.93117 14.25 6.49455 14.25C11.5889 14.25 14.3759 10.0665 14.3759 6.43725C14.3759 6.31838 14.3722 6.19988 14.3665 6.08288C14.9103 5.69888 15.379 5.21437 15.7492 4.66087Z"
                fill="#03A9F4"
            />
        </svg>
    );
};

export default React.memo(TwitterSVG);
