import * as types from '../types';

const handler = {
    [types.TURN_ON_RECORD_PANEL](state, action) {
        return {
            ...state,
            isTurnOnRecordPanel: true
        };
    },

    [types.TURN_OFF_RECORD_PANEL](state, action) {
        return {
            ...state,
            isTurnOnRecordPanel: false
        };
    }
};

export default handler;
