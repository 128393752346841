export const FETCH_COMPANY_TMS = '[TM] FETCH_COMPANY_TMS';
export const FETCH_COMPANY_TMS_SUCCESS = '[TM] FETCH_COMPANY_TMS_SUCCESS';
export const FETCH_COMPANY_TMS_FAILED = '[TM] FETCH_COMPANY_TMS_FAILED';

export const EXECUTE_COMPANY_TM_ACTION = '[TM] EXECUTE_COMPANY_TM_ACTION';
export const EXECUTE_COMPANY_TM_ACTION_SUCCESS = '[TM] EXECUTE_COMPANY_TM_ACTION_SUCCESS';
export const EXECUTE_COMPANY_TM_ACTION_FAILED = '[TM] EXECUTE_COMPANY_TM_ACTION_FAILED';

export const CREATE_TM = '[TM]CREATE_TM';
export const CREATE_TM_FAILED = '[TM]CREATE_TM_FAILED';
export const CREATE_TM_SUCCESS = '[TM]CREATE_TM_SUCCESS';

export const DELETE_TM = '[TM]DELETE_TM';
export const DELETE_TM_FAILED = '[TM]DELETE_TM_FAILED';
export const DELETE_TM_SUCCESS = '[TM]DELETE_TM_SUCCESS';

export const UPDATE_TM = '[TM]UPDATE_TM';
export const UPDATE_TM_FAILED = '[TM]UPDATE_TM_FAILED';
export const UPDATE_TM_SUCCESS = '[TM]UPDATE_TM_SUCCESS';

export const TURN_ON_SHOW_LAST_POP_UP_CREATE_TM = '[TM]TURN_ON_SHOW_LAST_POP_UP_CREATE_TM';
export const TURN_OFF_SHOW_LAST_POP_UP_CREATE_TM = '[TM]TURN_OFF_SHOW_LAST_POP_UP_CREATE_TM';

export const FETCH_TM_SOURCES = '[TM]FETCH_TM_SOURCES';
export const FETCH_TM_SOURCES_SUCCESS = '[TM]FETCH_TM_SOURCES_SUCCESS';
export const FETCH_TM_SOURCES_FAILED = '[TM]FETCH_TM_SOURCES_FAILED';

export const SET_SELECTED_SOURCE_TM = '[TM]SET_SELECTED_SOURCE_TM';
export const SET_SELECTED_TARGETS_TM = '[TM]SET_SELECTED_TARGETS_TM';
export const SET_LIST_SEGMENT = '[TM]SET_LIST_SEGMENT';
export const UPDATE_NEW_ALTERNATIVE = '[TM]UPDATE_NEW_ALTERNATIVE';
