import React from 'react';
import { checkContainClassName } from 'utils/clickAway';
import { useCurrentTutorial } from 'hooks/app';

export default function useClickAwaitListener(ref, handler, timeout = 200) {
    const currentTutorial = useCurrentTutorial();

    React.useEffect(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (
                !ref.current ||
                ref.current.contains(event.target) ||
                checkContainClassName(event, 'menu-nested') ||
                currentTutorial
            ) {
                return;
            }
            if (timeout) {
                setTimeout(() => {
                    handler(event);
                }, timeout);
            } else {
                handler(event);
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, currentTutorial, timeout]);
}
