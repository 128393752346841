import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import { useTranslation } from 'react-i18next';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import { generateTempId } from 'utils/uuid';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import TMGuideSVG from 'assets/images/svg/TMGuideSVG';
import TMSearchNotFoundSVG from 'assets/images/svg/TMSearchNotFoundSVG';
import NewTMSVG from 'assets/images/svg/NewTMSVG';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { searchTextsApi } from 'services/tm';
import Spinner from 'components/spinner/Base';
import { getLanguageDisplayName } from 'const/languageData';
import Search from 'components/search';
import { isKbEnter } from 'utils/keyboard';

const useStyles = makeStyles(theme => ({
    root: {
        width: 850,
        height: 550
    },
    sidebar: {
        flexBasis: 300,
        height: 550,
        borderRight: `1px solid ${theme.colors.border}`
    },
    targets: {
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(4)
    },
    searchButton: {
        height: 64,
        borderTop: `1px solid ${theme.colors.border}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        background: theme.colors.white
    },
    spinner: {
        height: '100%',
        opacity: 0.5,
        position: 'absolute',
        background: theme.colors.white,
        top: 0,
        left: 0
    },
    results: {
        flex: 1,
        height: 550,
        overflowY: 'auto',
        padding: theme.spacing(4),
        position: 'relative'
    },
    flex: {
        display: 'flex'
    },
    blueText: {
        color: theme.colors.lavender
    },
    addTarget: {
        cursor: 'pointer'
    },
    expand: {
        flex: 1
    },
    fullHeight: {
        height: '100%'
    },
    resultItem: {
        padding: theme.spacing(3),
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.colors.border}`
        }
    },
    result: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    resultDetail: {
        border: `1px solid ${theme.colors.border}`
    },
    resultSource: {
        borderRight: `1px solid ${theme.colors.border}`,
        height: '100%'
    },
    countryIcon: {
        marginRight: theme.spacing(2)
    },
    textCenter: {
        textAlign: 'center'
    },
    noTMFound: {
        height: `calc(550px - 84px)`
    },
    closeIcon: {
        position: 'absolute',
        top: 24,
        right: 24,
        cursor: 'pointer'
    }
}));

const ALL_OPTION = {
    value: 'all',
    label: 'All languages'
};

function TMSearchBox({ tmList, onClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const sourceOptions = useLanguageOptionsWithUsageValidate();

    const targetOptions = React.useMemo(() => {
        return [ALL_OPTION, ...sourceOptions];
    }, [sourceOptions]);

    const [sourceLanguage, setSourceLanguage] = React.useState(sourceOptions?.[0]);
    const [targets, setTargets] = React.useState([{ id: generateTempId(), option: ALL_OPTION }]);
    const [searchResults, setSearchResults] = React.useState([]);
    const [isSearching, setIsSearching] = React.useState(false);
    const [isFirstTime, setIsFirstTime] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState('');

    const handleSourceChange = React.useCallback(option => {
        setSourceLanguage(option);
    }, []);

    const handleTargetChange = React.useCallback(
        ({ id, option }) => {
            setTargets(
                targets?.map(target => {
                    if (target?.id === id) {
                        target.option = option;
                    }
                    return target;
                })
            );
        },
        [targets]
    );

    const handleRemoveTarget = React.useCallback(
        id => {
            setTargets(targets?.filter(target => target?.id !== id));
        },
        [targets]
    );

    const handleAddTarget = React.useCallback(
        id => {
            setTargets([...targets, { id: generateTempId() }]);
        },
        [targets]
    );

    const validTargets = React.useMemo(() => {
        return targets
            ?.filter(target => {
                return !!target?.option && target?.option !== 'all';
            })
            ?.map(target => target?.option?.value);
    }, [targets]);

    const validSearch = React.useMemo(() => {
        return !!filterValue;
    }, [filterValue]);

    const isHasAllOption = React.useMemo(() => {
        return targets?.some(target => {
            return target?.option?.value === 'all';
        });
    }, [targets]);

    const handleSearch = React.useCallback(async () => {
        if (!validSearch) return;
        setIsSearching(true);
        try {
            const searchResults = await searchTextsApi({
                searchText: filterValue?.trim(),
                sourceLang: sourceLanguage?.value,
                targetLangs: isHasAllOption ? [] : validTargets
            });
            if (!isFirstTime) {
                setIsFirstTime(true);
            }
            setSearchResults(searchResults);
            setIsSearching(false);
        } catch (error) {
            setIsSearching(false);
        }
    }, [filterValue, sourceLanguage, validTargets, isFirstTime, isHasAllOption, validSearch]);

    const handleKeyDown = React.useCallback(
        e => {
            if (isKbEnter(e)) {
                handleSearch();
                return;
            }
            return false;
        },
        [handleSearch]
    );

    return (
        <Grid container direction="row" className={classes.root}>
            <Grid item className={classes.sidebar}>
                <Grid container wrap="nowrap" direction="column" className={classes.fullHeight}>
                    <Grid item direction="column" wrap="nowrap" container spacing={3} className={classes.targets}>
                        <Grid item>
                            <Search
                                background={theme.colors.paleGrey}
                                onChange={e => setFilterValue(e.target?.value)}
                                value={filterValue}
                                autoFocus
                                placeholder="Search for TM"
                                onKeyDown={handleKeyDown}
                            />
                        </Grid>
                        <Grid item container wrap="nowrap" direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">Source language</Typography>
                            </Grid>
                            <Grid item>
                                <LDBasePortalVirtualize
                                    ddPlaceholder={t('global_languague_select')}
                                    menuPlaceholder={t('global_languague_search')}
                                    options={sourceOptions}
                                    onChange={handleSourceChange}
                                    defaultValue={sourceLanguage}
                                    isMulti={false}
                                    isUsingContainer={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item wrap="nowrap" container direction="column" spacing={3}>
                            <Grid item>
                                <Typography variant="body1">Target language</Typography>
                            </Grid>
                            <Grid wrap="nowrap" item container direction="column" spacing={3}>
                                {targets.map(target => {
                                    const targetOption = target?.option;

                                    return (
                                        <Grid item key={target?.id}>
                                            <Grid container alignItems="center" spacing={2} direction="row">
                                                <Grid item className={classes.expand}>
                                                    <LDBasePortalVirtualize
                                                        ddPlaceholder={t(`global_languague_select`)}
                                                        menuPlaceholder={t('global_languague_search')}
                                                        options={targetOptions}
                                                        onChange={option =>
                                                            handleTargetChange({ id: target?.id, option })
                                                        }
                                                        defaultValue={targetOption}
                                                        isMulti={false}
                                                        isUsingContainer={true}
                                                    />
                                                </Grid>
                                                <Grid item onClick={() => handleRemoveTarget(target?.id)}>
                                                    <IconButton size="small">
                                                        <CloseIconSVG />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item>
                                <Grid
                                    onClick={handleAddTarget}
                                    container
                                    alignItems="center"
                                    direction="row"
                                    spacing={2}
                                    className={classes.addTarget}
                                >
                                    <Grid item className={classes.flex}>
                                        <ColorAddSVG />
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.blueText} variant="body1">
                                            Add Another Language
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.searchButton}>
                        <ButtonBase onClick={handleSearch} disabled={!validSearch} fullWidth variant="contained">
                            {t('global_search')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.results}>
                {onClose && (
                    <Grid item className={classes.closeIcon} onClick={onClose}>
                        <CloseIconSVG />
                    </Grid>
                )}
                {isSearching && (
                    <Grid
                        container
                        spacing={3}
                        className={classes.spinner}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Spinner size={18} thick={3} />
                    </Grid>
                )}
                {!isFirstTime ? (
                    <Grid
                        container
                        spacing={3}
                        className={classes.fullHeight}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <TMSearchNotFoundSVG />
                        </Grid>
                        <Grid item className={classes.textCenter}>
                            <Typography variant="h3">{t('nothing_here_yet')}</Typography>
                            <Typography variant="caption">{t('what_are_you_looking_for')}</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant={'h4'}>Results</Typography>
                        </Grid>
                        <Grid item>
                            {searchResults?.length === 0 && (
                                <Grid
                                    container
                                    spacing={3}
                                    className={classes.noTMFound}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item>
                                        <TMGuideSVG />
                                    </Grid>
                                    <Grid item className={classes.textCenter}>
                                        <Typography variant="h3">No Translation memory found</Typography>
                                        <Typography variant="caption">
                                            Please try again or input another text
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {searchResults?.length > 0 &&
                                searchResults.map(result => {
                                    return (
                                        <Grid
                                            className={classes.result}
                                            item
                                            container
                                            key={result?.tmId}
                                            wrap="nowrap"
                                            direction="column"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center" spacing={2}>
                                                    <Grid item>
                                                        <NewTMSVG width={30} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {tmList?.find(tm => tm?.id === result?.tmId)?.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" className={classes.resultDetail}>
                                                    <Grid item xs={6}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            alignItems="center"
                                                            justify="center"
                                                            spacing={2}
                                                            className={classes.resultSource}
                                                        >
                                                            <Grid item>
                                                                <CountryFlag
                                                                    languageCountryCode={sourceLanguage?.value}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {getLanguageDisplayName(sourceLanguage?.value)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container direction="column">
                                                            {result?.translations?.map(translation => {
                                                                return (
                                                                    <Grid
                                                                        className={classes.resultItem}
                                                                        item
                                                                        key={translation?.lang}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            direction="column"
                                                                            alignItems="flex-start"
                                                                            justify="flex-start"
                                                                            wrap="nowrap"
                                                                            spacing={3}
                                                                        >
                                                                            <Grid item className={classes.countryIcon}>
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    alignItems="center"
                                                                                    justify="center"
                                                                                    spacing={2}
                                                                                    className={classes.resultSource}
                                                                                >
                                                                                    <Grid item>
                                                                                        <CountryFlag
                                                                                            languageCountryCode={
                                                                                                translation?.lang
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Typography variant="body2">
                                                                                            {getLanguageDisplayName(
                                                                                                translation?.lang
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container direction="column">
                                                                                    {translation?.texts?.map(text => {
                                                                                        return (
                                                                                            <Grid item key={text}>
                                                                                                <Typography variant="body2">
                                                                                                    &#x02022; {text}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        );
                                                                                    })}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default TMSearchBox;
