import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import PopperMenu from 'components/menus/Popper';
import Tooltip from 'components/tooltip/Base';
import useMediaQueries from 'hooks/useMediaQueries';
import { useIsShareViewLink } from 'hooks/app';
import { checkContainId } from 'utils/clickAway';
import ManageExport from './ManageExport';
import ManageHeight from './ManageHeight';
import ManageImport from './ManageImport';
import ManageTemplate from './ManageTemplate';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        //[theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        overflowY: 'hidden',
        //}

        [theme.breakpoints.down('md')]: {
            minHeight: 50
        }
    },
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    }
}));

const ManageMoreActions = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { isDesktop } = useMediaQueries();
    const isShareViewLink = useIsShareViewLink();
    const [moreActionsAnchorEl, setMoreActionsAnchorEl] = React.useState(null);
    const notAllowCloseIconMoreRef = React.useRef();

    const handleMoreActionsClick = React.useCallback(
        event => {
            setMoreActionsAnchorEl(moreActionsAnchorEl ? null : event.currentTarget);
        },
        [moreActionsAnchorEl]
    );

    const handleMoreActionsClickAway = React.useCallback(e => {
        if (notAllowCloseIconMoreRef.current) return;
        if (e && checkContainId(e, 'simple-dialog')) return;
        if (e && checkContainId(e, 'simple-popper')) return;
        if (e && checkContainId(e, 'dialog-import-export')) return;

        setMoreActionsAnchorEl(null);
    }, []);

    return (
        <>
            <Tooltip title={t('more')}>
                <Grid item id={'more-actions-trigger'} className={classes.menuItem}>
                    <ButtonBase
                        tr-dt="More actions"
                        onClick={handleMoreActionsClick}
                        className={`${classes.addButton} ${classes.button}`}
                    >
                        <IconMoreActionsSVG height={18} width={18} color={theme.colors.darkness} />
                    </ButtonBase>
                </Grid>
            </Tooltip>
            <PopperMenu
                id={'popper-more-actions'}
                placement={'bottom-start'}
                anchorEl={moreActionsAnchorEl}
                handleClickAway={handleMoreActionsClickAway}
                className={`${classes.popperMenu} small`}
            >
                <Grid container className={classes.root} direction="column">
                    {!isShareViewLink && (
                        <ManageHeight
                            isDesktop={isDesktop}
                            handleMoreActionsClickAway={handleMoreActionsClickAway}
                            isShareViewLink={isShareViewLink}
                            isInIconMore
                        />
                    )}
                    <ManageTemplate handleMoreActionsClickAway={handleMoreActionsClickAway} isInIconMore />
                    <ManageExport handleMoreActionsClickAway={handleMoreActionsClickAway} isInIconMore />
                    <ManageImport
                        handleMoreActionsClickAway={handleMoreActionsClickAway}
                        isInIconMore
                        notAllowCloseIconMoreRef={notAllowCloseIconMoreRef}
                    />
                </Grid>
            </PopperMenu>
        </>
    );
};

export default React.memo(ManageMoreActions);
