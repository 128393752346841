import React, { useCallback } from 'react';
import Button from 'components/button/Base';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import { DISABLED_OPACITY } from 'const/style';
import { sendManualTrack } from 'tracker';

function AddFilterButton({ isReadOnly, onCreated, t }) {
    const dispatch = useDispatch();

    const addFilterHandler = useCallback(() => {
        sendManualTrack({ type: `Add View Filter` });
        dispatch(
            gridActions.createViewFilter({
                successCallback: () => {
                    console.log('create filter success');
                    onCreated && onCreated();
                },
                errorCallback: () => {
                    console.log('create filter failed');
                }
            })
        );
    }, [dispatch, onCreated]);

    return (
        <Button
            width={120}
            style={{
                pointerEvents: isReadOnly ? 'none' : '',
                opacity: isReadOnly ? DISABLED_OPACITY : 1
            }}
            size="small"
            onClick={addFilterHandler}
            variant="outlined"
        >
            {t(`view_filter_add_button`)}
        </Button>
    );
}
export default React.memo(AddFilterButton);
