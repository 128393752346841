import moment from 'moment';
import { PLAN_CYCLE } from 'const';

export const TIME_FORMAT = 'hh:mm A';
export const TIME_FORMAT_24h = 'HH:mm';
export const DATE_FORMAT = 'MM/DD/YYYY';

export function getShortTime(time) {
    return isToday(time) ? moment(time).format(`h:mm`) : moment(time).format(`DD/MM`);
}

export function getFriendlyTime(time, format = `MMMM DD, YYYY, h:mm A`) {
    return moment(time).format(format);
}

export function getFriendlyDate(time) {
    return moment(time).format(`MMM DD, YYYY`);
}

export function getFriendlyDateFromUnix(unixTime) {
    return moment.unix(unixTime).format(`MMM DD, YYYY`);
}

export function getFriendlyDateTimeFromUnix(unixTime) {
    return moment.unix(unixTime).format(`MMM DD, YYYY, h:mm A`);
}

export function isToday(date) {
    const today = moment();
    return moment(date).isSame(today, 'day');
}

export function getStartDateOffsetTimestamp({ offset = 0, unit = 'd' }) {
    let dateFrom = moment()
        .subtract(offset, unit)
        .utc()
        .format(`YYYY-MM-DD`);
    return moment(`${dateFrom} 23:59:59`).unix();
}

export function getStartDateUTC({ offset = 0, unit = 'd' }) {
    let dateFrom = moment()
        .subtract(offset, unit)
        .utc()
        .startOf('day');
    return dateFrom;
}

export function getStartDateFormat({ offset = 0, unit = 'd', format = 'YYYY-MM-DD' }) {
    return moment()
        .subtract(offset, unit)
        .utc()
        .format(`YYYY-MM-DD`);
}

export function getPlanRenewDate({ recurringInterval, recurringIntervalCount }) {
    if (!recurringInterval || !recurringIntervalCount) return;
    const intervalType = recurringInterval === PLAN_CYCLE.year ? 'years' : 'months';
    return moment()
        .add(recurringIntervalCount, intervalType)
        .format(`MMM DD, YYYY`);
}

export function getISOString(time) {
    return moment(time).toISOString();
}
export function getDateTime(time) {
    return moment(time).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
}

export function getFormatDateTime(date) {
    return moment(date).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
}

export function generateDefaultDateApiPublic(date) {
    return moment(date)
        .utc()
        .format();
}

export function getDateFormat(date) {
    if (!date) return;
    return moment(date).format(DATE_FORMAT);
}

export function getTimeFormat(time) {
    if (!time) return;
    return moment(time, TIME_FORMAT).format(TIME_FORMAT);
}

export function formatDateFromNow(date) {
    return moment(date || Date.now()).fromNow();
}

export function getCurrentUtc() {
    return moment(new Date())
        .utc()
        .format();
}

export function getDateTimeAndHourMinute(datetime) {
    return moment(datetime).format(`MMMM DD, YYYY - h:mm A`);
}

export function getOffsetNumber(date = new Date()) {
    return moment(date).utcOffset() / 60;
}

function _correctHours(h) {
    return h < 10 ? '0' + h : h;
}

export function getDateWithLocalTimezone(date) {
    const offset = getOffsetNumber(date);
    if (offset === 0) return date;

    const dateBefore = getDateBefore({ date, numOfDay: 1 })?.format();
    return offset < 0
        ? `${date?.split(`T`)?.[0]}T${_correctHours(Math.abs(offset))}:00:00Z`
        : `${dateBefore?.split(`T`)?.[0]}T${_correctHours(24 - Math.abs(offset))}:00:00Z`;
}

export function getDateAfter({ date, numOfDay }) {
    return moment(date).add(numOfDay, 'days');
}

export function getDateBefore({ date, numOfDay }) {
    return moment(date).subtract(numOfDay, 'days');
}

export function getDateUTC({ date, format = 'H:mm:ss' }) {
    return moment(date)
        .utc()
        .format(format);
}
