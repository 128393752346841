import { Editor, Transforms, Range } from 'slate';

import TYPES from './types';

const LIST_TYPES = [TYPES.OL, TYPES.UL];

const editorController = {
    isMarkActive(editor, format) {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    },
    isBlockActive(editor, format) {
        const [match] = Editor.nodes(editor, {
            match: n => n.type === format
        });

        return !!match;
    },
    toggleBlock(editor, format) {
        const isActive = this.isBlockActive(editor, format);
        const isList = LIST_TYPES.includes(format);

        Transforms.unwrapNodes(editor, {
            match: n => LIST_TYPES.includes(n.type),
            split: true
        });

        Transforms.setNodes(editor, {
            type: isActive ? TYPES.P : isList ? TYPES.LI : format
        });

        if (!isActive && isList) {
            const block = { type: format, children: [] };
            Transforms.wrapNodes(editor, block);
        }
    },
    toggleMark(editor, format) {
        const isActive = this.isMarkActive(editor, format);

        if (isActive) {
            Editor.removeMark(editor, format);
        } else {
            Editor.addMark(editor, format, true);
        }
    },
    insertImage(editor, url) {
        const text = { text: '' };
        const image = { type: TYPES.IMG, url, children: [text] };
        Transforms.insertNodes(editor, image);

        Transforms.insertNodes(editor, { type: TYPES.P, url, children: [{ text: ' ' }] });
    },
    insertLink(editor, url) {
        if (editor.selection) {
            this.wrapLink(editor, url);
        }
    },
    isLinkActive(editor) {
        const [link] = Editor.nodes(editor, { match: n => n.type === TYPES.LINK });
        return !!link;
    },
    unwrapLink(editor) {
        Transforms.unwrapNodes(editor, { match: n => n.type === TYPES.LINK });
    },
    wrapLink(editor, url) {
        if (this.isLinkActive(editor)) {
            this.unwrapLink(editor);
        }

        const { selection } = editor;
        const isCollapsed = selection && Range.isCollapsed(selection);
        const link = {
            type: TYPES.LINK,
            url,
            children: isCollapsed ? [{ text: url }] : []
        };

        if (isCollapsed) {
            Transforms.insertNodes(editor, link);
            Transforms.insertNodes(editor, { type: TYPES.P, url, children: [{ text: ' ' }] });
        } else {
            Transforms.wrapNodes(editor, link, { split: true });
            Transforms.collapse(editor, { edge: 'end' });
        }
    }
};

export default editorController;
