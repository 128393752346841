import {
    RESET_GRID_HISTORY,
    FETCH_EVENT_LOGS,
    FETCH_EVENT_LOGS_FAILED,
    FETCH_EVENT_LOGS_SUCCESS,
    CHANGE_BEGIN_FILTER,
    REFRESH_EVENT_LOGS,
    REFRESH_EVENT_LOGS_SUCCESS,
    REFRESH_EVENT_LOGS_FAILED
} from '../types';
import { getEventlogsApi } from 'services/eventLog';
import { enqueueSnackbar } from 'notifier/actions';
import { DEFAULT_GRID_HISTORY_LIMIT, DEFAULT_GRID_HISTORY_OFFSET } from 'const/gridUI';

export function resetGridHistory({ branchId }) {
    return {
        type: RESET_GRID_HISTORY,
        payload: {
            branchId
        }
    };
}

export function fetchGridHistory({ branchId, isFetchmore = false, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { historyOfGrid, gridId: branchIdStore, dbId } = gridUI;
        const grid_begin = historyOfGrid?.[branchId]?.grid_begin;
        const GRID_HISTORY_OFFSET = historyOfGrid?.[branchId]?.GRID_HISTORY_OFFSET || DEFAULT_GRID_HISTORY_OFFSET;
        const GRID_HISTORY_LIMIT = historyOfGrid?.[branchId]?.GRID_HISTORY_LIMIT || DEFAULT_GRID_HISTORY_LIMIT;

        const branchIdCombined = branchId || branchIdStore;

        if (!isFetchmore) {
            dispatch(_fetchingGridHistoryAction({ branchId: branchIdCombined }));
        }
        try {
            const { histories, totalGridHistory } = await getEventlogsApi({
                dbId,
                gridId: branchIdCombined,
                offset: GRID_HISTORY_OFFSET,
                limit: GRID_HISTORY_LIMIT,
                beginTime: grid_begin
            });
            dispatch(
                _fetchingGridHistoryActionSuccess({
                    branchId: branchIdCombined,
                    histories,
                    newOffset: GRID_HISTORY_OFFSET + GRID_HISTORY_LIMIT,
                    totalGridHistory
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchingGridHistoryActionFailed({ error: message, branchId: branchIdCombined }));
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchingGridHistoryAction({ branchId }) {
    return {
        type: FETCH_EVENT_LOGS,
        payload: {
            branchId
        }
    };
}

function _fetchingGridHistoryActionSuccess({ branchId, histories, newOffset, totalGridHistory }) {
    return {
        type: FETCH_EVENT_LOGS_SUCCESS,
        payload: {
            newOffset,
            histories,
            totalGridHistory,
            branchId
        }
    };
}

function _fetchingGridHistoryActionFailed({ error, branchId }) {
    return {
        type: FETCH_EVENT_LOGS_FAILED,
        payload: {
            error,
            branchId
        }
    };
}

export function refreshGridHistory({ branchId, isFetchmore, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { historyOfGrid, gridId: branchIdStore, dbId } = gridUI;
        const grid_begin = historyOfGrid?.[branchId]?.grid_begin;
        const GRID_HISTORY_LIMIT = historyOfGrid?.[branchId]?.GRID_HISTORY_LIMIT || DEFAULT_GRID_HISTORY_LIMIT;

        const branchIdCombined = branchId || branchIdStore;

        if (!isFetchmore) {
            dispatch(_refreshGridHistoryAction({ branchId }));
        }
        try {
            const { histories, totalGridHistory } = await getEventlogsApi({
                dbId,
                gridId: branchIdCombined,
                offset: 0,
                limit: GRID_HISTORY_LIMIT,
                beginTime: grid_begin
            });
            dispatch(
                _refreshGridHistoryActionSuccess({
                    branchId: branchIdCombined,
                    histories,
                    newOffset: GRID_HISTORY_LIMIT,
                    totalGridHistory
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_refreshGridHistoryActionFailed({ error: message, branchId: branchIdCombined }));
            errorCallback && errorCallback(message);
        }
    };
}

function _refreshGridHistoryAction({ branchId }) {
    return {
        type: REFRESH_EVENT_LOGS,
        payload: {
            branchId
        }
    };
}

function _refreshGridHistoryActionSuccess({ branchId, histories, newOffset, totalGridHistory }) {
    return {
        type: REFRESH_EVENT_LOGS_SUCCESS,
        payload: {
            branchId,
            newOffset,
            histories,
            totalGridHistory
        }
    };
}

function _refreshGridHistoryActionFailed({ error, branchId }) {
    return {
        type: REFRESH_EVENT_LOGS_FAILED,
        payload: {
            error,
            branchId
        }
    };
}

export function changeGridBeginFilter({ branchId, value }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { gridId: branchIdStore } = gridUI;

        const branchIdCombined = branchId || branchIdStore;

        dispatch(_changeGridBeginFilterAction({ branchId: branchIdCombined, begin: value }));
    };
}

function _changeGridBeginFilterAction({ begin, branchId }) {
    return {
        type: CHANGE_BEGIN_FILTER,
        payload: {
            begin,
            branchId
        }
    };
}
