import * as types from '../types';

const handler = {
    [types.FETCH_COMPANIES](state) {
        return {
            ...state,
            companies: {
                ...state.companies,
                isFetching: true
            }
        };
    },
    [types.FETCH_COMPANIES_SUCCESS](state, { payload }) {
        return {
            ...state,
            companies: {
                ...state.companies,
                isFetching: false,
                list: payload
            }
        };
    },
    [types.CHANGE_COMPANIES_LOGO](state, { payload }) {
        const { logoUrl, companyId } = payload;
        const newCompanies = state?.companies?.list?.map(company => {
            if (company.id === companyId) {
                return { ...company, logoUrl };
            }
            return company;
        });

        return {
            ...state,
            companies: {
                ...state.companies,
                isFetching: false,
                list: newCompanies
            }
        };
    },
    [types.INVITE_USERS_TO_COMPANY_SUCCESS](state) {
        return {
            ...state,
            members: {
                list: null
            }
        };
    }
};

export default handler;
