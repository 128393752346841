import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import GridTable from './grid';
import Controls from './Controls';
import {
    useAdvancedSearchId,
    useAdvancedSharedList,
    useIsHasManageAdvancedSearchPermission,
    useSelectedAdvancedSearchId
} from 'hooks/advanced';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import RocketSVG from 'assets/images/svg/payment/RocketSVG';
import { useParams } from 'react-router-dom';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useDispatch } from 'react-redux';
import ButtonBase from 'components/button/Base';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    expand: {
        flex: 1
    }
}));

function Wrapper({ isSideBarOn }) {
    const classes = useStyles();

    const isHasManageAdvancedSearchPermission = useIsHasManageAdvancedSearchPermission();

    const advancedSearchId = useAdvancedSearchId();
    const selectedAdvancedSearchId = useSelectedAdvancedSearchId();

    if (!advancedSearchId && !selectedAdvancedSearchId && !isHasManageAdvancedSearchPermission) {
        return <SharedNotFound />;
    }

    return (
        <Grid direction="column" container className={classes.root}>
            <Grid item>
                <Controls />
            </Grid>
            <Grid item className={classes.expand}>
                <GridTable isSideBarOn={isSideBarOn} />
            </Grid>
        </Grid>
    );
}

function SharedNotFound() {
    const advancedSearchList = useAdvancedSharedList();
    const { aId } = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        if (aId) {
            dispatch(advancedSearchActions.setSelectedAdvancedSearchId(aId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (advancedSearchList?.length) {
        return (
            <Grid
                className={classes.root}
                container
                direction="column"
                spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item>
                    <RocketSVG />
                </Grid>
                <Grid item>
                    <Typography style={{ fontWeight: 400 }} variant="h3">
                        Select a search query to start
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" align="center">
                        Choose a search query from the left panel to start working
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            className={classes.root}
            container
            direction="column"
            spacing={3}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item>
                <EmptyBoxSVG />
            </Grid>
            <Grid item>
                <Typography style={{ fontWeight: 400 }} variant="h3">
                    No search query found
                </Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center' }}>
                <Typography variant="body2" align="center">
                    Please ask the owner/admin of the Company to assign you to a shared search query
                </Typography>
            </Grid>
            <Grid item>
                <ButtonBase variant="outlined" width={157}>
                    Back To Dashboard
                </ButtonBase>
            </Grid>
        </Grid>
    );
}

export default Wrapper;
