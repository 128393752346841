import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function CancelAddonEnterprise({ onClose, addonName }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle
                title={
                    <Typography variant="h4" component="div">
                        Cancel {addonName}
                    </Typography>
                }
                onClose={onClose}
            />
            <DialogContent>
                <Typography variant="body2">
                    Please get in touch with our sales directly to request the cancellation of extra packs.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" width={120} className={classes.cancelButton} onClick={onClose}>
                    {t('global_ok')}
                </Button>
            </DialogActions>
        </>
    );
}

export default React.memo(CancelAddonEnterprise);
