import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import Avatar from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import { getDateTime } from 'utils/datetime';
import { useMemberMappingById } from 'hooks/permission/member';
import { getFullName } from 'utils/name';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    langName: {
        color: theme.colors.steel
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(3)
    },
    head: {
        width: 100,
        marginRight: theme.spacing(3),
        textAlign: 'right'
    },
    langBox: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: `4px`,
        padding: `2px 8px`
    }
}));

const TBDetailHeader = ({ glossary }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const memberMapping = useMemberMappingById();

    const langOptions = useLanguageOptionsWithUsageValidate();

    const langObject = useMemo(() => {
        return langOptions.reduce((acc, cur) => {
            acc[cur.value] = cur;
            return acc;
        }, {});
    }, [langOptions]);

    const langColumns = useMemo(() => {
        return (glossary?.langs || [])
            .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
            .reduce((acc, cur) => {
                acc.push({
                    dataKey: cur,
                    label: cur
                });
                return acc;
            }, []);
    }, [glossary]);

    return (
        <Grid container direction="column">
            <Grid item>
                <div className={classes.line}>
                    <Typography className={classes.head} variant="caption">
                        Languages
                    </Typography>
                    <Grid container direction="row" spacing={3} alignItems="center">
                        {langColumns?.map(column => {
                            const lang = langObject?.[column.dataKey];

                            return (
                                <Grid item key={lang.value}>
                                    <Grid
                                        className={classes.langBox}
                                        container
                                        wrap="nowrap"
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item className={classes.flag}>
                                            {lang.icon && lang.icon()}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" className={classes.langName}>
                                                {lang.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </Grid>
            <Grid item>
                <div className={classes.line}>
                    <Typography className={classes.head} variant="caption">
                        {t('owner')}
                    </Typography>
                    <Grid container direction="row" alignItems="center" spacing={5}>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Avatar
                                        size="small"
                                        src={getAvatarUrl(glossary?.owner?.toString())}
                                        alt={'Phu Vo'}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        {getFullName(memberMapping?.[glossary?.owner])}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" display="inline">
                                Date created
                            </Typography>{' '}
                            <Typography variant="body1" display="inline">
                                {getDateTime(glossary?.createdTime)}
                            </Typography>{' '}
                            <Typography variant="caption" display="inline">
                                by
                            </Typography>{' '}
                            <Typography variant="body1" display="inline">
                                {getFullName(memberMapping?.[glossary?.createdBy])}
                            </Typography>{' '}
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" display="inline">
                                Last updated
                            </Typography>{' '}
                            <Typography variant="body1" display="inline">
                                {getDateTime(glossary?.alteredTime)}
                            </Typography>{' '}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item>
                <div className={classes.line}>
                    <Typography className={classes.head} variant="caption">
                        Note
                    </Typography>
                    <Grid container direction="row" spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant="body1">{glossary?.description}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default React.memo(TBDetailHeader);
