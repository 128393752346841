import React from 'react';

function ArrowDownSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect x="3.47129" y="3.47812" width="17.0574" height="17.0438" rx="1.1" stroke={color} strokeWidth="1.8" />
            <rect x="12.9023" y="12.8945" width="8.52656" height="8.52656" fill="white" />
            <mask id="path-3-inside-1" fill="white">
                <path d="M13.9453 14.2375C13.9453 14.0718 14.0796 13.9375 14.2453 13.9375H21.1289C21.2946 13.9375 21.4289 14.0718 21.4289 14.2375V21.1211C21.4289 21.2868 21.2946 21.4211 21.1289 21.4211H14.2453C14.0796 21.4211 13.9453 21.2868 13.9453 21.1211V14.2375Z" />
            </mask>
            <path
                d="M13.9453 14.2375C13.9453 14.0718 14.0796 13.9375 14.2453 13.9375H21.1289C21.2946 13.9375 21.4289 14.0718 21.4289 14.2375V21.1211C21.4289 21.2868 21.2946 21.4211 21.1289 21.4211H14.2453C14.0796 21.4211 13.9453 21.2868 13.9453 21.1211V14.2375Z"
                fill="white"
                stroke={color}
                strokeWidth="3"
                mask="url(#path-3-inside-1)"
            />
            <path d="M12.6094 7.77148L7.76859 7.77148L7.76859 12.6121" stroke={color} strokeWidth="1.5" />
            <path d="M7.76855 7.77148L12.6093 12.8965" stroke={color} strokeWidth="1.5" />
        </svg>
    );
}

export default React.memo(ArrowDownSVG);
