import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import InputText from 'components/inputs/InputText';
import LDSelectBase from 'components/selects/LDBase';
import IOSSwitch from 'components/switches/IOS';
import TBSettingSVG from 'assets/images/svg/TBSettingSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 'fit-content'
    },
    content: {
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        padding: theme.spacing(3, 4)
    },
    mb14: {
        marginBottom: theme.spacing(3)
    },
    ml14: {
        marginLeft: theme.spacing(3)
    },
    mb24: {
        marginBottom: theme.spacing(4)
    },
    cancelButton: {
        marginRight: theme.spacing(2)
    },
    inputText: {
        width: 150
    }
}));

function NewAutoQASetting({ selectedSetting, glossaryList, isNew, onCreateSetting, onUpdateSetting, onBack, isDoing }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [name, setName] = React.useState(selectedSetting?.name || '');
    const [glossaryIds, setGlossaryIds] = React.useState(selectedSetting?.glossaryIds || []);
    const [isSpellCheck, setIsSpellCheck] = React.useState(selectedSetting?.spellcheck);
    const [lengthMore, setLengthMore] = React.useState(selectedSetting?.lengthMore || 0);
    const [lengthLess, setLengthLess] = React.useState(selectedSetting?.lengthLess || 0);
    const [lengthCharMore, setLengthCharMore] = React.useState(selectedSetting?.lengthCharMore || 0);
    const [lengthCharLess, setLengthCharLess] = React.useState(selectedSetting?.lengthCharLess || 0);

    const glossaryOptions = glossaryList.map(g => ({ label: g.name, value: g.id, icon: () => <TBSettingSVG /> }));
    const selectedGlossaryOptions = glossaryOptions.filter(option => glossaryIds.includes(option.value));

    const handleChangeGlossaryIds = options => {
        const newGlossaryIds = options.map(op => op.value);
        setGlossaryIds(newGlossaryIds);
    };

    const body = {
        name,
        glossaryIds,
        spellcheck: isSpellCheck,
        lengthMore,
        lengthLess,
        lengthCharMore,
        lengthCharLess
    };

    const handleCreateSetting = () => {
        onCreateSetting(body);
    };

    const handleUpdateSetting = () => {
        onUpdateSetting(body);
    };

    const isValid = Boolean(name);

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item container direction="column" className={classes.content}>
                <Grid item xs={6}>
                    <Grid item className={classes.mb14}>
                        <Typography variant="body1">Setting name</Typography>
                    </Grid>
                    <Grid item className={classes.mb24}>
                        <InputText
                            name="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder="Setting name"
                        />
                    </Grid>
                    <Grid item className={classes.mb14}>
                        <Typography variant="body1">Glossary files</Typography>
                    </Grid>
                    <Grid item className={classes.mb24}>
                        <LDSelectBase
                            ddPlaceholder={'Choose a glossary'}
                            menuPlaceholder={'Find a glossary'}
                            options={glossaryOptions}
                            onChange={handleChangeGlossaryIds}
                            defaultValue={selectedGlossaryOptions}
                            isMulti
                        />
                    </Grid>
                    <Grid item container className={classes.mb24} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body1">Spellchecking</Typography>
                        </Grid>
                        <Grid item>
                            <IOSSwitch
                                checked={isSpellCheck}
                                onChange={() => setIsSpellCheck(!isSpellCheck)}
                                className={classes.switchIcon}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.mb24}>
                    <Typography variant="body1">Length check</Typography>
                </Grid>
                <Grid item container alignItems="center" className={classes.mb14}>
                    <Grid item style={{ marginRight: 14 }}>
                        <Typography variant="body2">Target is longer than source by more than</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <InputText
                            className={classes.inputText}
                            name="name"
                            type="number"
                            value={lengthMore}
                            onChange={e => setLengthMore(e.target.value)}
                            placeholder="0"
                            inputProps={{ min: 0 }}
                            endAdornment="%"
                        />
                    </Grid>
                    <Grid item className={classes.ml14}>
                        <Typography variant="body2">or</Typography>
                    </Grid>
                    <Grid item className={classes.ml14}>
                        <InputText
                            className={classes.inputText}
                            name="name"
                            type="number"
                            value={lengthCharMore}
                            onChange={e => setLengthCharMore(e.target.value)}
                            placeholder="0"
                            inputProps={{ min: 0 }}
                            endAdornment="chars"
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.mb14}>
                    <Grid item style={{ marginRight: 14 }}>
                        <Typography variant="body2">Target is shorter than source by more than</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <InputText
                            className={classes.inputText}
                            name="name"
                            type="number"
                            value={lengthLess}
                            onChange={e => setLengthLess(e.target.value)}
                            placeholder="0"
                            inputProps={{ min: 0 }}
                            endAdornment="%"
                        />
                    </Grid>
                    <Grid item className={classes.ml14}>
                        <Typography variant="body2">or</Typography>
                    </Grid>
                    <Grid item className={classes.ml14}>
                        <InputText
                            className={classes.inputText}
                            type="number"
                            name="name"
                            value={lengthCharLess}
                            onChange={e => setLengthCharLess(e.target.value)}
                            placeholder="0"
                            inputProps={{ min: 0 }}
                            endAdornment="chars"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <div className="flex mt-6 items-center justify-end">
                <ButtonBase className={classes.cancelButton} width={120} variant="outlined" onClick={onBack}>
                    {t('global_cancel')}
                </ButtonBase>
                <ButtonBase
                    width={120}
                    variant="contained"
                    disabled={!isValid || isDoing}
                    onClick={isNew ? handleCreateSetting : handleUpdateSetting}
                >
                    {isDoing ? (
                        <CircularProgress
                            style={{
                                color: 'white'
                            }}
                            size={20}
                        />
                    ) : (
                        'Save'
                    )}
                </ButtonBase>
            </div>
        </Grid>
    );
}

export default React.memo(NewAutoQASetting);
