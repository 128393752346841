import React from 'react';

function UploadFileSVG({ ...other }) {
    return (
        <svg {...other} width="67" height="73" viewBox="0 0 67 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M46.2722 71.9722L3.47222 60.2722C0.972222 59.5722 -0.527778 56.9722 0.172222 54.3722L14.1722 3.47222C14.8722 0.972222 17.4722 -0.527778 20.0722 0.172222L62.8722 11.9722C65.3722 12.6722 66.8722 15.2722 66.1722 17.8722L52.1722 68.7722C51.3722 71.1722 48.7722 72.6722 46.2722 71.9722Z"
                fill="#EBEBF6"
            />
            <path
                d="M55.3 67.2H10.5C8 67.2 6 65.2 6 62.7V9.5C6 7 8 5 10.5 5H44.9C46.6 5 48.2 5.7 49.3 6.8L57.9 15.3C59.1 16.5 59.8 18.1 59.8 19.8V62.8C59.9 65.2 57.8 67.2 55.3 67.2Z"
                fill="white"
            />
            <path
                d="M43.3975 37.7483C42.6827 36.9161 41.8487 36.3217 40.7765 36.0839C41.1339 35.6084 41.2531 35.014 41.2531 34.4196C41.2531 33.5874 40.8957 32.8741 40.3 32.1608C39.7043 31.5664 38.9895 31.2098 38.0364 31.2098C37.3216 31.2098 36.6068 31.4476 36.0111 31.9231C35.5346 30.7343 34.8197 29.7832 33.7475 29.0699C32.6753 28.3566 31.4839 28 30.2926 28C28.6247 28 27.0759 28.5944 25.8846 29.7832C24.6932 30.972 24.0975 32.5175 24.0975 34.1818C24.0975 34.3007 24.0975 34.4196 24.0975 34.6573C23.1444 35.1329 22.4296 35.7273 21.834 36.6783C21.2383 37.6294 21 38.5804 21 39.6504C21 41.1958 21.4765 42.3846 22.5488 43.4545C23.621 44.5245 24.9315 45 26.3611 45H39.7043C41.0148 45 42.087 44.5245 43.0401 43.5734C43.9932 43.0979 44.4697 42.028 44.4697 40.7203C44.4697 39.6504 44.1123 38.6993 43.3975 37.7483ZM36.4876 37.5105C36.3685 37.6294 36.3685 37.6294 36.2494 37.6294H33.5092V41.9091C33.5092 42.028 33.5092 42.1469 33.3901 42.1469C33.271 42.2657 33.271 42.2657 33.1518 42.2657H30.7691C30.65 42.2657 30.5309 42.2657 30.5309 42.1469C30.4117 42.028 30.4117 42.028 30.4117 41.9091V37.6294H27.6716C27.5525 37.6294 27.4333 37.6294 27.4333 37.5105C27.3142 37.3916 27.3142 37.3916 27.3142 37.2727C27.3142 37.1538 27.3142 37.035 27.4333 37.035L31.7222 32.7552C31.8413 32.6364 31.8413 32.6364 31.9605 32.6364C32.0796 32.6364 32.1988 32.6364 32.1988 32.7552L36.4876 37.035C36.6068 37.1538 36.6068 37.1538 36.6068 37.2727C36.6068 37.2727 36.6068 37.3916 36.4876 37.5105Z"
                fill="#6E5BBE"
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(UploadFileSVG);
