import React from 'react';

function AddColumnSVG({ color = 'white', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <mask id="mask0AddColumn" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                <circle cx="20" cy="20" r="20" fill="#C9C9CE" />
            </mask>
            <g mask="url(#mask0AddColumn)">
                <circle cx="20" cy="20" r="20" fill="#7869B9" />
                <mask
                    id="mask1AddColumn"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="10"
                    y="10"
                    width="20"
                    height="20"
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 10H29.4338V29.75H10V10Z" fill="white" />
                </mask>
                <g mask="url(#mask1AddColumn)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.71 28.1825H13.9213V11.5687H11.71V28.1825ZM14.7775 10H10.8562C10.3838 10 10 10.3512 10 10.7837V28.9663C10 29.3975 10.3838 29.75 10.8562 29.75H14.7775C15.2488 29.75 15.6325 29.3975 15.6325 28.9663V10.7837C15.6325 10.3512 15.2488 10 14.7775 10Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.8236 18.1135V28.1822H18.6123V11.7135H20.8236H22.5348V10.7847C22.5348 10.3522 22.1511 10.001 21.6798 10.001H17.7573C17.2861 10.001 16.9023 10.3522 16.9023 10.7847V28.966C16.9023 29.3985 17.2861 29.751 17.7573 29.751H21.6798C22.1511 29.751 22.5348 29.3985 22.5348 28.966V18.1135H20.8236Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.724 18.1133V28.182H25.5127V21.812H23.8027V28.9658C23.8027 29.3983 24.1865 29.7508 24.6577 29.7508H28.5802C29.0515 29.7508 29.4352 29.3983 29.4352 28.9658V18.1133H27.724Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.2224 14.1675H25.5237V10.4688H23.8049V14.1675H20.1074V15.8863H23.8049V19.585H25.5237V15.8863H29.2224V14.1675Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(AddColumnSVG);
