import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputText from 'components/inputs/InputText';
import WorkspaceListTableWrapper from '../workspaceListTable';
import ButtonBase from 'components/button/Base';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import * as groupActions from 'permission/actions/group';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { enqueueSnackbar } from 'notifier/actions';
import { sendManualTrack } from 'tracker';
import { useSelectedGroupAccess, useSelectedGroupId } from 'hooks/permission/group';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import ProjectAndDatabaseAccessList from './ProjectAndDatabaseAccessList';
import i18n from 'i18n';

const CONTROLS_HEIGHT = 80;
const useStyles = makeStyles(theme => ({
    content: {
        maxHeight: 850 - CONTROLS_HEIGHT,
        position: 'relative',
        width: 'calc(100vw - 60*2px)'
    },
    input: {
        flex: 1
    },
    workspaceList: {
        minHeight: 300
    },
    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const defaultProjectAccess = {
    project: { value: 0, label: '' },
    database: { value: 0, label: '' },
    isFake: true
};

const allDatabaseOption = { value: -1, label: `(${i18n.t('all_databases')})` };

function CreateGroup({
    onClose,
    isEdit,
    defaultGroupName,
    onBack,
    checkedListDefault = [],
    checkedListDefaultIds = [],
    isEnableBackButton = false,
    onCreateNewGroup
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const groupAccess = useSelectedGroupAccess();
    const [groupName, setGroupName] = React.useState();
    const [checkedList, setCheckedList] = React.useState([]);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const selectedGroupId = useSelectedGroupId();
    const [projectAccesses, setProjectAccesses] = React.useState([defaultProjectAccess]);

    // console.log('projectAccesses', projectAccesses);

    React.useEffect(() => {
        dispatch(
            groupActions.fetchGroupWorkspacesAccess({
                successCallback: () => {
                    console.log('fetch group Access successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch group Access');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        const allDatabaseAccess = groupAccess?.projects
            ? groupAccess?.projects.map(project => ({
                  project: { value: project.id, label: project.name },
                  database: allDatabaseOption
              }))
            : [];
        const normalAccess = groupAccess?.databases
            ? groupAccess?.databases?.map(database => ({
                  project: { value: database.project.id, label: database.project.name },
                  database: { value: database.id, label: database.name }
              }))
            : [];
        setProjectAccesses([...allDatabaseAccess, ...normalAccess]);
    }, [groupAccess]);

    const handleProjectChange = (index, newProjectOption) => {
        const newProjectAccess = projectAccesses.map((access, id) => {
            if (id === index)
                return { ...access, project: newProjectOption, database: allDatabaseOption, isFake: false };
            return access;
        });
        setProjectAccesses(newProjectAccess);
    };

    const handleDatabaseChange = (index, newDatabaseOption) => {
        const newProjectAccess = projectAccesses.map((access, id) => {
            if (id === index) return { ...access, database: newDatabaseOption, isFake: false };
            return access;
        });
        setProjectAccesses(newProjectAccess);
    };

    const handleAccessAdd = () => {
        setProjectAccesses([...projectAccesses, defaultProjectAccess]);
    };

    const handleAccessRemove = index => {
        const newProjectAccess = projectAccesses.filter((_, id) => id !== index);
        setProjectAccesses(newProjectAccess);
    };

    const handleGroupNameChange = e => {
        setGroupName(e.target.value);
    };

    React.useEffect(() => {
        if (isEdit) {
            setGroupName(defaultGroupName);
        }
    }, [isEdit, defaultGroupName]);

    const onCheckedListChange = checkedList => {
        setCheckedList(checkedList);
    };

    const handleCreateGroup = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Create New Group` });
        const body = {
            name: groupName,
            accessViews: checkedList.map(list => ({
                workspaceId: list?.workspaceId,
                databaseId: list?.databaseId,
                gridId: list?.gridId,
                viewId: list?.viewId
            }))
        };

        // if (checkedList.length === 0) {
        //     dispatch(
        //         groupActions.createGroup({
        //             groupName,
        //             successCallback: group => {
        //                 console.log('createGroup successfully');
        //                 setIsSubmitting(false);
        //                 onCreateNewGroup && onCreateNewGroup(group);
        //                 onBack();
        //             },
        //             errorCallback: () => {
        //                 console.log('createGroup failed');
        //                 setIsSubmitting(false);
        //             }
        //         })
        //     );
        // } else {
        dispatch(
            groupActions.createGroupWithAccess({
                body,
                successCallback: group => {
                    setIsSubmitting(false);
                    onBack();
                    onCreateNewGroup && onCreateNewGroup(group);
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed handleCreateGroup');
                }
            })
        );
    };

    const handleEditGroup = () => {
        const projectIds = projectAccesses
            .filter(access => access.database.value === -1 && !access.isFake)
            .map(access => access.project.value);
        const dbIds = projectAccesses
            .filter(access => access.database.value !== -1 && !access.isFake)
            .map(access => access.database.value);
        const addViews = [];
        const removeViews = [];
        const checkedListIds = checkedList.map(checkedList => checkedList?.id);

        checkedList
            .filter(checked => Boolean(checked))
            .forEach(dbAccess => {
                if (!checkedListDefaultIds.includes(dbAccess?.id)) {
                    addViews.push(dbAccess);
                }
            });
        checkedListDefault.forEach(dbAccess => {
            if (!checkedListIds.includes(dbAccess?.id)) {
                removeViews.push(dbAccess);
            }
        });

        sendManualTrack({ type: `Edit Group & Access` });
        setIsSubmitting(true);
        dispatch(
            groupActions.updateGroup({
                newGroup: { id: selectedGroupId, name: groupName },
                successCallback: () => {
                    setIsSubmitting(false);
                    onClose();
                    console.log('updateGroup successfully');
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to updateGroup');
                }
            })
        );
        dispatch(
            groupActions.updateGroupWithWorkspaceAccess({
                projectIds,
                dbIds,
                groupName,
                addViews,
                removeViews,
                successCallback: () => {
                    setIsSubmitting(false);
                    onClose();
                    dispatch(
                        enqueueSnackbar({
                            message: t('change_saved'),
                            type: 'info'
                        })
                    );
                    console.log('update group successfully');
                    dispatch(
                        groupActions.fetchGroupWorkspacesAccess({
                            successCallback: () => {
                                console.log('fetch group Access successfully');
                            },
                            errorCallback: () => {
                                console.log('failed to fetch group Access');
                            }
                        })
                    );
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to update group');
                }
            })
        );
    };

    return (
        <>
            <DialogTitle title={isEdit ? t('edit_group_and_access') : t('create_group')} onClose={onClose} />
            <DialogContent>
                <Grid container className={classes.content} direction="column" spacing={3} wrap="nowrap">
                    <Grid xs={7} item container spacing={3} alignItems="center">
                        <Grid item>
                            <Typography variant="body1">{t('group_name')}</Typography>
                        </Grid>
                        <Grid item className={classes.input}>
                            <InputText
                                autoFocus={true}
                                name="password"
                                value={groupName || ''}
                                error={isEmpty(groupName)}
                                onChange={handleGroupNameChange}
                                errorText=""
                                placeholder={t('group_name_placeholder')}
                                isShowErrorMessage={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={7} item>
                        <ProjectAndDatabaseAccessList
                            projectAccesses={projectAccesses}
                            onProjectChange={handleProjectChange}
                            onDatabaseChange={handleDatabaseChange}
                            onAccessAdd={handleAccessAdd}
                            onAccessRemove={handleAccessRemove}
                        />
                    </Grid>
                    <Grid item>
                        <WorkspaceListTableWrapper
                            onCheckedListChange={onCheckedListChange}
                            className={classes.workspaceList}
                            checkedListDefaultIds={checkedListDefaultIds}
                            isEdit={isEdit}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    item
                    spacing={2}
                    justify={isEnableBackButton ? `space-between` : `flex-end`}
                    alignItems="center"
                >
                    {isEnableBackButton && (
                        <Grid item onClick={onBack}>
                            <ButtonBase width={120} variant="outlined">
                                {t('global_back')}
                            </ButtonBase>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item onClick={onClose}>
                                <ButtonBase width={120} variant="outlined">
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit}>
                                    <ButtonBase
                                        onClick={isEdit ? handleEditGroup : handleCreateGroup}
                                        width={120}
                                        disabled={isEmpty(groupName) || isSubmitting}
                                        variant="contained"
                                    >
                                        {isEdit ? t('global_save') : t('global_create')}
                                    </ButtonBase>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(CreateGroup);
