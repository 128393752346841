import React, { useState, useRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, Grid, MenuItem, Typography } from '@material-ui/core';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import clsx from 'clsx';
import {
    INPUT_HEIGHT,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    POPUP_PADDING_TOP_BOTTOM,
    SPACING_LIST_NAME_WITH_ICON
} from 'const/style';

const useMenuItemStyles = makeStyles(theme => ({
    root: {
        height: INPUT_HEIGHT,
        cursor: 'pointer',
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: `${theme.colors.hoverItem} !important`
        },
        '&.active': {
            background: `${theme.colors.hoverItem} !important`
        }
    },
    menu: {
        zIndex: '1302 !important',
        '& .MuiMenu-paper': {
            paddingTop: POPUP_PADDING_TOP_BOTTOM,
            paddingBottom: POPUP_PADDING_TOP_BOTTOM,
            borderRadius: 4,
            background: theme.colors.white,
            boxShadow: theme.shadows
        },
        '& .MuiMenu-list.MuiList-padding': {
            paddingTop: 0,
            paddingBottom: 0,
            boxShadow: 'none'
        }
    },
    icon: {
        display: 'flex',
        marginRight: SPACING_LIST_NAME_WITH_ICON
    }
}));

/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
const NestedMenuItem = React.forwardRef(function NestedMenuItem(props, ref) {
    const {
        parentMenuOpen,
        // component = 'div',
        label,
        icon: Icon,
        rightIcon = <ArrowRightSVG />,
        children,
        className,
        menuClassName,
        anchorOrigin = {
            horizontal: 'right',
            vertical: 'center'
        },
        tabIndex: tabIndexProp,
        // menuProps,
        ContainerProps: ContainerPropsProp = {},
        ...MenuItemProps
    } = props;
    const { ref: containerRefProp, placement, autoClose, ...ContainerProps } = ContainerPropsProp;

    const menuItemRef = useRef(null);
    useImperativeHandle(ref, () => menuItemRef.current);

    const containerRef = useRef(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);

    const menuContainerRef = useRef(null);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleMouseEnter = event => {
        setIsSubMenuOpen(true);

        if (ContainerProps?.onMouseEnter) {
            ContainerProps.onMouseEnter(event);
        }
    };
    const handleMouseLeave = event => {
        setIsSubMenuOpen(false);
        if (ContainerProps?.onMouseLeave) {
            ContainerProps.onMouseLeave(event);
        }
    };

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
        const active = containerRef.current?.ownerDocument?.activeElement;
        for (const child of menuContainerRef.current?.children ?? []) {
            if (child === active) {
                return true;
            }
        }
        return false;
    };

    const handleFocus = event => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }

        if (ContainerProps?.onFocus) {
            ContainerProps.onFocus(event);
        }
    };

    const handleKeyDown = event => {
        if (event.key === 'Escape') {
            return;
        }

        if (isSubmenuFocused()) {
            event.stopPropagation();
        }

        const active = containerRef.current?.ownerDocument?.activeElement;

        if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
            return containerRef.current?.focus();
        }

        if (event.key === 'ArrowRight' && event.target === containerRef.current && event.target === active) {
            return menuContainerRef.current?.children?.[0]?.focus();
        }
    };

    const handleAutoClose = React.useCallback(() => {
        autoClose && setIsSubMenuOpen(false);
    }, [autoClose]);

    const open = isSubMenuOpen && parentMenuOpen;
    const menuItemClasses = useMenuItemStyles({ open });

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return (
        <div
            {...ContainerProps}
            ref={containerRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
        >
            <MenuItem
                {...MenuItemProps}
                className={clsx(menuItemClasses.root, isSubMenuOpen && 'active', className)}
                ref={menuItemRef}
            >
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <Grid container alignItems="center" wrap="nowrap">
                            {Icon && (
                                <Grid item className={menuItemClasses.icon}>
                                    {Icon}
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant="body2">{label}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        {rightIcon}
                    </Grid>
                </Grid>
            </MenuItem>
            <Menu
                // Set pointer events to 'none' to prevent the invisible Popover div
                // from capturing events for clicks and hovers
                style={{ pointerEvents: 'none' }}
                anchorEl={menuItemRef.current}
                anchorOrigin={anchorOrigin}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: placement ? placement : 'center'
                }}
                getContentAnchorEl={null}
                open={open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                className={`${menuItemClasses.menu} ${menuClassName} menu-nested`}
                onClose={() => {
                    setIsSubMenuOpen(false);
                }}
            >
                <div
                    ref={menuContainerRef}
                    onClick={handleAutoClose}
                    style={{ pointerEvents: 'auto', outline: 'none' }}
                >
                    {children}
                </div>
            </Menu>
        </div>
    );
});

export default React.memo(NestedMenuItem);
