import React from 'react';

function LocalizationSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Datatype/Localization">
                <path
                    id="Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.84345 1.40002C5.03675 1.40002 5.19802 1.53714 5.23532 1.71941L5.24345 1.80002V3.34137H8.48889C8.68219 3.34137 8.84346 3.47849 8.88076 3.66076L8.88889 3.74137V4.40923C8.88889 4.60253 8.75178 4.7638 8.5695 4.8011L8.48889 4.80923H5.21848C5.55076 6.05448 6.31922 7.25695 7.52386 8.41663C7.62008 8.50926 7.66247 8.64229 7.641 8.77077L7.62037 8.84695L7.39273 9.44543C7.3707 9.50334 7.33553 9.55533 7.28998 9.59732C7.12756 9.74706 6.8745 9.73678 6.72476 9.57435C5.69527 8.45764 5.02672 7.52428 4.7191 6.77425C4.19021 8.13229 2.93582 9.50222 0.955931 10.884C0.926773 10.9044 0.895033 10.9207 0.861548 10.9327C0.679486 10.9977 0.481455 10.9229 0.385038 10.7637L0.35028 10.6905L0.108407 10.0134C0.045586 9.83756 0.112777 9.64164 0.270354 9.54142C2.37652 8.20041 3.50987 6.79663 3.67041 5.33008L3.70318 5.1625L3.74532 4.8329H0.4C0.2067 4.8329 0.0454251 4.69579 0.00812658 4.51351L0 4.4329V3.76505C0 3.57175 0.137113 3.41048 0.319386 3.37318L0.4 3.36505H3.67041V1.80002C3.67041 1.60672 3.80752 1.44545 3.9898 1.40815L4.07041 1.40002H4.84345ZM11.5556 4.06669C12.1019 4.06669 12.5936 4.3846 12.8211 4.87245L12.8724 4.99787L15.8115 13.3111C15.8266 13.354 15.8343 13.3991 15.8343 13.4445C15.8343 13.6378 15.6972 13.799 15.5149 13.8363L15.4343 13.8445H14.806C14.6588 13.8445 14.5256 13.7639 14.4561 13.6384L14.4269 13.572L13.5309 10.9088H9.58025L8.68425 13.572C8.63731 13.7116 8.5185 13.8121 8.37734 13.8379L8.30513 13.8445H7.67679C7.63137 13.8445 7.58628 13.8367 7.54346 13.8216C7.36121 13.7572 7.25486 13.5741 7.28045 13.3899L7.29966 13.3111L10.2387 4.99787C10.436 4.4398 10.9636 4.06669 11.5556 4.06669ZM10.0741 9.44092L11.5556 5.53454L13.037 9.46459L10.0741 9.44092Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(LocalizationSVG);
