import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import uuidv1 from 'uuid/v1';
import { getTopicAndSubscription } from './handlers';
import * as types from './types';
import * as gridUIActions from '../gridUI/actions';
import * as databasesActions from '../databases/actions';
import * as appActions from '../app/actions';
import isEmpty from 'lodash/isEmpty';
import delay from 'utils/delay';
import store from 'store/configStore';
import { MAINTAIN_TYPES } from 'const';
import { getRegionBaseUrl } from 'utils/workspace';

const { dispatch, getState } = store;

const getNotificationCenterChannel = ({ companyId, userId }) => [
    {
        name: `/topic/c/${companyId}/users/${userId}/messages`,
        handlerType: types.NOTIFICATION_CENTER_HANDLER
    },
    {
        name: `/topic/users/${userId}/messages`,
        handlerType: types.NOTIFICATION_CENTER_HANDLER
    }
];

const ws = () => {
    let stompClient = null;
    let isConnected = false;
    let isReconnecting = false;
    let latestCallback = null;
    let reconnectingHandler = null;
    let subscriptions = [];
    let RECONNECT_TIME = 3000;
    let RECONNECT_COUNT = 20;

    const connect = callback => {
        let uuid = uuidv1();
        const { getState } = store;
        const { app } = getState();
        const isShowMaintainMode = app?.isShowMaintainMode;
        const shareViewKey = app?.shareViewKey;
        const isShareViewLink = app?.isShareViewLink;

        const headers = {
            server: 'GRID_UI'
        };

        let url = getRegionBaseUrl() + '/websocket/tracker';

        if (isShowMaintainMode) {
            isConnected = false;
            isReconnecting = false;
            return;
        } else if (isShareViewLink) {
            url += '?x_share_key=' + shareViewKey;
        }

        console.log('RECONNECT_TIME', RECONNECT_TIME);
        console.log('RECONNECT_COUNT', RECONNECT_COUNT);
        const socket = new SockJS(url);
        stompClient = Stomp.over(socket);
        stompClient.connect(
            headers,
            () => {
                dispatch(appActions.saveSocketId({ socketId: uuid }));
                if (stompClient && stompClient.connected) {
                    callback && callback();
                    isConnected = true;
                    resetReconnectValues();
                }
                if (isReconnecting) {
                    reconnectingHandler && reconnectingHandler();
                    isReconnecting = false;
                }
            },
            async err => {
                --RECONNECT_COUNT;
                if (RECONNECT_COUNT === 0) {
                    RECONNECT_COUNT = 20;
                    RECONNECT_TIME += 3000;
                }
                console.log('err', err);
                if (err?.code === 2000) {
                    dispatch(appActions.turnOnMaintainPopup({ maintainType: MAINTAIN_TYPES.UNSTABLE }));
                    return;
                }
                isReconnecting = true;
                console.log('RE-CONNECTING.....');
                await delay(RECONNECT_TIME);
                // disconnect();
                connect(latestCallback);
            }
        );
    };

    const resetReconnectValues = () => {
        RECONNECT_COUNT = 20;
        RECONNECT_TIME = 3000;
    };

    const connectServer = (callback, handlerReconnecting) => {
        latestCallback = callback;
        reconnectingHandler = handlerReconnecting;

        console.log('isConnected', isConnected);
        if (isConnected) {
            callback && callback();
        } else {
            connect(callback);
        }
    };

    const getStompClient = () => {
        return stompClient;
    };

    const subscribe = async ({ topic, handler }) => {
        let subscriptionId = await stompClient.subscribe(topic, handler);
        return subscriptionId;
    };

    const unsubscribe = async ({ topicSubscribe }) => {
        // if (topicSubscribe !== null) {
        //     await topicSubscribe.unsubscribe();
        // }
        console.log('UNSUBCRIBE', topicSubscribe);
        return await topicSubscribe?.unsubscribe();
    };

    const sendMessage = ({ topic, message }) => {
        if (stompClient) {
            stompClient.send(topic, JSON.stringify(message));
        }
    };

    const disconnect = async () => {
        console.log('DISCONNECTED SOCKET!');
        if (isEmpty(stompClient)) return;
        try {
            stompClient.disconnect();
            stompClient = null;
            isConnected = false;
            resetReconnectValues();
        } catch (error) {
            stompClient = null;
            isConnected = false;
            resetReconnectValues();
        }
    };

    const unsubscribeMultipleTopic = () => {
        subscriptions.forEach(topicSubscribe => {
            unsubscribe({ topicSubscribe });
        });
    };

    const advancedSearchSubscribeTopics = ({ userId, companyId, advancedSearchId }) => {
        subscriptions = [];
        const { advanced } = getState();
        const paths = advanced?.advancedSearch?.gridIds?.map(str => str?.split('.'));

        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/advancedSearch/${advancedSearchId}`,
                handlerType: types.ADVANCED_SEARCH_HANDLER
            },
            ...paths?.map(([dbId, gridId]) => ({
                name: `/topic/c/${companyId}/databases/${dbId}/grids/${gridId}`,
                handlerType: types.ADVANCED_SEARCH_GRIDS_DETAIL_HANDLER
            })),
            ...getNotificationCenterChannel({ companyId, userId })
        ]);

        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                /**
                 * TODO: fetching projects
                 */

                console.log('auto reload current advancedSearch');
            }
        );
    };

    const dashboardSubscribeTopics = ({ userId, companyId, workspaceId }) => {
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/projects/${workspaceId}`,
                handlerType: types.PROJECT_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases`,
                handlerType: types.DATABASES_HANDLER
            },
            {
                name: `/topic/c/${companyId}/grids`,
                handlerType: types.GRIDS_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            },
            ...getNotificationCenterChannel({ companyId, userId })
        ]);
        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                /**
                 * TODO: fetching projects
                 */
                dispatch(
                    databasesActions.getDatabases({
                        workspaceId,
                        errorCallback: err => {
                            console.log(err);
                        },
                        successCallback: () => {
                            console.log('socket fetch db successfully');
                        }
                    })
                );
            }
        );
    };

    const gridlySubscribeTopics = ({
        companyId,
        dbId,
        viewId,
        parentGridId,
        ROW_START_INDEX,
        ROW_STOP_INDEX,
        gridId,
        userId
    }) => {
        unsubscribeMultipleTopic();
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/databases/${dbId}/grids/${gridId}/user_connected`,
                handlerType: types.GRID_USERS_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}/grids/${gridId}/branch`,
                handlerType: types.BRANCH_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}/views/${viewId}`,
                handlerType: types.GRID_UI_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}/grids/${gridId}`,
                handlerType: types.GRID_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}`,
                handlerType: types.DATABASE_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            },
            {
                name: `/topic/c/${companyId}/views/${viewId}/automationExecutions`,
                handlerType: types.GRID_UI_DETAIL_HANDLER
            },
            ...getNotificationCenterChannel({ companyId, userId })
        ]);

        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                dispatch(
                    gridUIActions.renderGridUITableAfterNetworkConnected({
                        successCallback: () => {
                            dispatch(
                                gridUIActions.fetchViewFilters({
                                    successCallback: () => {
                                        console.log('fetching view filters done');
                                    }
                                })
                            );

                            dispatch(
                                gridUIActions.fetchViewSorts({
                                    successCallback: () => {
                                        console.log('fetching view sorts done');
                                    }
                                })
                            );
                        }
                    })
                );
            }
        );
    };

    const shareLinkViewSubscribeTopics = ({
        companyId,
        dbId,
        viewId,
        parentGridId,
        ROW_START_INDEX,
        ROW_STOP_INDEX,
        gridId,
        userId
    }) => {
        unsubscribeMultipleTopic();
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/databases/${dbId}/grids/${gridId}/branch`,
                handlerType: types.BRANCH_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}/views/${viewId}`,
                handlerType: types.GRID_UI_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}/grids/${gridId}`,
                handlerType: types.GRID_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/databases/${dbId}`,
                handlerType: types.DATABASE_DETAIL_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            }
        ]);

        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                dispatch(
                    gridUIActions.renderGridUITableAfterNetworkConnected({
                        successCallback: () => {
                            dispatch(
                                gridUIActions.fetchViewFilters({
                                    successCallback: () => {
                                        console.log('fetching view filters done');
                                    }
                                })
                            );

                            dispatch(
                                gridUIActions.fetchViewSorts({
                                    successCallback: () => {
                                        console.log('fetching view sorts done');
                                    }
                                })
                            );
                        }
                    })
                );
            }
        );
    };

    const permissionSettingSubscribeTopics = ({ userId, companyId }) => {
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            },
            ...getNotificationCenterChannel({ companyId, userId })
        ]);
        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                console.log('handle unsync permissionSetting');
            }
        );
    };

    const workspaceSettingSubscribeTopics = ({ userId, companyId }) => {
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            },
            ...getNotificationCenterChannel({ companyId, userId })
        ]);
        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                console.log('handle unsync workspaceSetting');
            }
        );
    };

    const versionControlSubscribeTopics = ({ userId, companyId }) => {
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            },
            ...getNotificationCenterChannel({ companyId, userId })
        ]);
        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                console.log('handle unsync versionControl');
            }
        );
    };

    const profileSettingSubscribeTopics = ({ userId, companyId }) => {
        subscriptions = [];
        let subscribePages = getTopicAndSubscription([
            {
                name: `/topic/c/${companyId}/user/${userId}`,
                handlerType: types.USER_HANDLER
            },
            {
                name: `/topic/c/${companyId}/user/${userId}/version`,
                handlerType: types.USER_VERSION_HANDLER
            },
            {
                name: `/topic/client/info`,
                handlerType: types.VERSION_HANDLER
            },
            ...getNotificationCenterChannel({ companyId, userId })
        ]);
        connectServer(
            () => {
                subscribePages.map(async sub => {
                    let subscription = await subscribe({
                        topic: sub.topic,
                        handler: sub.handler
                    });
                    subscriptions.push(subscription);
                });
            },
            () => {
                console.log('handle unsync workspaceSetting');
            }
        );
    };

    return {
        connectServer,
        disconnect,
        subscribe,
        unsubscribe,
        getStompClient,
        dashboardSubscribeTopics,
        unsubscribeMultipleTopic,
        gridlySubscribeTopics,
        permissionSettingSubscribeTopics,
        workspaceSettingSubscribeTopics,
        profileSettingSubscribeTopics,
        versionControlSubscribeTopics,
        sendMessage,
        shareLinkViewSubscribeTopics,
        advancedSearchSubscribeTopics
    };
};

export default ws();
