import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { PROD_API_URL } from 'config';
import { PROMOTE_FEATURE_VIEW } from 'config';
import { PROMOTE_FEATURE_API_KEY } from 'config';
import { setPromoteFeatures } from 'app/actions';
import { isEmpty } from 'lodash';
import { getFriendlyDate } from 'utils/datetime';
import { addTargetBlankToATagInText, stripHTML } from 'utils/html';
import Slider from 'components/slider';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import * as authActions from 'auth/actions';
import { USER_SETTINGS } from 'const';
import { useShownPromoteFeatures } from 'hooks/auth';
import { useCurrentTutorial, useIsShowFreeTrial } from 'hooks/app';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        position: 'relative',
        width: 811,
        padding: 0,
        background: theme.colors.white
    },
    header: {
        padding: '24px 40px',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    thumbnail: {
        marginTop: 43,
        marginBottom: 24,
        height: 316,
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: 14,
            objectFit: 'cover'
        }
    },
    rightWrapper: {
        position: 'absolute',
        top: 22,
        right: 17
    },
    datetime: {
        height: 21.58
    },
    title: {
        margin: '12px 0',
        '& p': {
            margin: 0
        }
    },
    carousel: {
        overflow: 'hidden auto',
        padding: '0 40px',
        width: '100%',
        minHeight: 568,
        maxHeight: 568,
        '& .slick-dots': {
            top: 8,
            left: 0,
            bottom: 'unset',
            '& li': {
                width: 10,
                margin: '0 4px',
                '& button': {
                    position: 'relative',
                    width: 10,
                    '&:before': {
                        width: 10,
                        fontSize: 8
                    }
                },
                '&.slick-active': {
                    '& button': {
                        '&:before': {
                            color: theme.palette.primary.main
                        }
                    }
                }
            }
        }
    },
    mark: {},
    markContent: {
        color: theme.colors.steel,
        marginLeft: 9.5
    },
    underline: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    divider: {
        width: 1,
        height: 28,
        margin: '0 6px 0 16px',
        background: theme.colors.lightGrey
    },
    closeIcon: {
        cursor: 'pointer'
    }
}));

function getColumnValue({ cells, columnId }) {
    if (isEmpty(cells)) {
        cells = [];
    }
    return cells?.find(cell => cell.columnId === columnId)?.value;
}
export function getWhatNewTitle(cells) {
    return getColumnValue({ cells, columnId: 'title' })?.trim();
}
export function getWhatNewBrief(cells) {
    return getColumnValue({ cells, columnId: 'brief' })?.trim();
}
export function getWhatNewContent(cells) {
    return getColumnValue({ cells, columnId: 'content' })?.trim();
}
export function getWhatNewPromote(cells) {
    return getColumnValue({ cells, columnId: 'promote' });
}
export function getWhatNewCreatedTime(cells) {
    return getColumnValue({ cells, columnId: 'created_time' });
}
export function getWhatNewThumbnail(cells) {
    return getColumnValue({ cells, columnId: 'thumbnails' });
}

let timer = undefined;
let fetched = false;

const WhatNewDialogItem = ({ wn }) => {
    const classes = useStyles();
    const thumbnail = getWhatNewThumbnail(wn?.cells);
    const createdTime = getWhatNewCreatedTime(wn?.cells);
    const title = stripHTML(getWhatNewTitle(wn?.cells));
    const content = addTargetBlankToATagInText(getWhatNewContent(wn?.cells));
    return (
        <>
            <Grid item className={classes.thumbnail}>
                <img src={thumbnail} alt="what's new" />
            </Grid>
            <Grid item className={classes.datetime}>
                <Typography variant="caption">{createdTime && getFriendlyDate(createdTime)}</Typography>
            </Grid>
            <Grid item className={classes.title}>
                <Typography variant="h3">{title}</Typography>
            </Grid>
            <Grid item className={`${classes.content} revert-style`} dangerouslySetInnerHTML={{ __html: content }} />
        </>
    );
};

const WhatNewDialogItemMemo = React.memo(WhatNewDialogItem);

const WhatNewDialog = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [unpromotedFeatures, setUnpromotedFeatures] = useState([]);
    const [isMarkedAll, setIsMarkedAll] = useState(false);
    const shownPromoteFeatures = useShownPromoteFeatures();
    const currentTutorial = useCurrentTutorial();
    const isShowFreeTrial = useIsShowFreeTrial();

    const handleClose = useCallback(() => {
        sendManualTrack({ type: `[Gridly] Close what's new popup` });
        setOpen(false);
    }, []);

    const handleSetPromoteFeatureServer = useCallback(
        value => {
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.SHOWN_PROMOTE_FEATURES]: value
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleCarouselChange = useCallback(
        index => {
            if (shownPromoteFeatures.includes(unpromotedFeatures[index].id)) return;
            shownPromoteFeatures.push(unpromotedFeatures[index].id);
            handleSetPromoteFeatureServer(shownPromoteFeatures);
        },
        [unpromotedFeatures, shownPromoteFeatures, handleSetPromoteFeatureServer]
    );

    const onSlideInit = useCallback(() => {
        handleCarouselChange(0);
    }, [handleCarouselChange]);

    useEffect(() => {
        if (window?.location?.search?.indexOf('resetWN=true') > -1) {
            handleSetPromoteFeatureServer([]);
            let url = new URL(window?.location.href);
            let params = new URLSearchParams(url.search);
            params.delete('resetWN');
            const newSearchParam = params.toString();
            window.location.href = [`${url.origin}${url.pathname}`, newSearchParam].join('?');
        }
    }, [handleSetPromoteFeatureServer]);

    useEffect(() => {
        if (fetched) return;
        async function fetchData() {
            fetched = true;
            const response = await axios.get(`${PROD_API_URL}/v1/views/${PROMOTE_FEATURE_VIEW}/records`, {
                headers: { Authorization: `ApiKey ${PROMOTE_FEATURE_API_KEY}` }
            });
            const promoteFeatures = response?.data?.filter(el => !!getWhatNewTitle(el?.cells)) || [];
            dispatch(
                setPromoteFeatures({
                    promoteFeatures
                })
            );
            let tempShownPromoteFeatures = shownPromoteFeatures.filter(pf => promoteFeatures.some(el => el.id === pf));
            if (JSON.stringify(tempShownPromoteFeatures) !== JSON.stringify(shownPromoteFeatures)) {
                handleSetPromoteFeatureServer(tempShownPromoteFeatures);
            }
            const unpromotedFeatures = promoteFeatures.filter(
                el => !getWhatNewPromote(el?.cells) && !tempShownPromoteFeatures.includes(el?.id)
            );
            setUnpromotedFeatures(unpromotedFeatures);
            if (!currentTutorial && !isShowFreeTrial && !isEmpty(unpromotedFeatures)) {
                setOpen(true);
            }
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            fetchData();
        }, 1000);
    }, [dispatch, shownPromoteFeatures, currentTutorial, handleSetPromoteFeatureServer, isShowFreeTrial]);

    const handleMarkAll = useCallback(() => {
        if (isMarkedAll) return;
        sendManualTrack({ type: `Mark All As Read Notifications` });
        setIsMarkedAll(true);
        unpromotedFeatures.map(pf => {
            if (!shownPromoteFeatures.includes(pf?.id)) {
                shownPromoteFeatures.push(pf.id);
            }
            return false;
        });
        handleSetPromoteFeatureServer(shownPromoteFeatures);
    }, [isMarkedAll, unpromotedFeatures, shownPromoteFeatures, handleSetPromoteFeatureServer]);

    return (
        <Dialog className={classes.root} maxWidth="md" open={open} onClose={handleClose}>
            <Grid container direction="column" className={classes.container}>
                <Grid item className={classes.header}>
                    <Grid item>
                        <Typography variant="h3">{t('what_new')}</Typography>
                    </Grid>
                    <Grid item className={classes.rightWrapper}>
                        <Grid container alignItems="center">
                            <Grid item className={classes.mark} onClick={handleMarkAll}>
                                {isMarkedAll && <CheckIconSVG />}
                                <Typography
                                    className={`${classes.markContent} ${!isMarkedAll ? classes.underline : ''}`}
                                    variant="body2"
                                    display="inline"
                                >
                                    {!isMarkedAll ? t('global_mark_all_as_read') : t('mark_all_articles_as_read')}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.divider}></Grid>
                            <Grid item className={classes.closeIcon} onClick={handleClose}>
                                <IconButton size="medium">
                                    <CloseIconSVG size={14} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isEmpty(unpromotedFeatures) && (
                    <Slider
                        className={classes.carousel}
                        adaptiveHeight
                        onInit={onSlideInit}
                        afterChange={handleCarouselChange}
                    >
                        {unpromotedFeatures.map(wn => (
                            <WhatNewDialogItemMemo key={wn.id} wn={wn} />
                        ))}
                    </Slider>
                )}
            </Grid>
        </Dialog>
    );
};

export default React.memo(WhatNewDialog);
