import React from 'react';

function PathTagSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.31868 14.696H14.6813C15.3846 14.696 16 14.0806 16 13.3773V4.67403C16 3.97074 15.3846 3.35535 14.6813 3.35535H7.73626L6.24176 1.77293C5.97802 1.5092 5.62637 1.33337 5.27472 1.33337H1.31868C0.615385 1.33337 0 1.94876 0 2.65206V13.3773C0 14.0806 0.615385 14.696 1.31868 14.696ZM1.31868 2.65206H5.27472L7.12088 4.58612H14.6813V13.3773H1.31868V2.65206ZM9.75 10C9.33579 10 9 10.3358 9 10.75C9 11.1642 9.33579 11.5 9.75 11.5H12.25C12.6642 11.5 13 11.1642 13 10.75C13 10.3358 12.6642 10 12.25 10H9.75Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(PathTagSVG);
