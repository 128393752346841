import React from 'react';
import orderBy from 'lodash/orderBy';
import { useMetaData, useColumnMetaData } from './metaData';
import { useData } from './data';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { useSelector } from 'react-redux';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import { useWorkspaceRole } from 'hooks/auth/role';
import { useCurrentUserLanguagePairs } from 'hooks/auth';
import * as roleConst from 'auth/roleConst';
import * as columnTypes from 'const/columnTypes';
import { DISABLED_UPDATE_COLUMN_GRID_TYPES, TRANSLATION_OVERVIEW_TYPES } from 'const/gridUI';
import { removeArrayInArray } from 'utils/object';
import { COLUMN_GROUP_SECTIONS, SYSTEM_COLUMN_IDS, DISABLED_COLUMN_BY_TYPES } from 'const';
import { useCompanyLocales } from 'hooks/app';
import { useTranslationDashboardDataByView } from './translationDashboard';
import { isTempId } from 'utils/uuid';
import { useDependencies, useDependencyListWithoutFakeId, useDependencyList } from './dependency';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { getViewColumnWidth, findMatchLanguagePairs, isShowMatchedLanguagePairsColumn } from 'utils/gridUI/column';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { useGridDetail } from '../grid';
import { between } from 'utils/usage';
import store from 'store/configStore';
import { useRowsRangeIndexes } from './row';

export function useColumns() {
    return useSelector(state => state.gridUI.columns);
}

export function useColumnsWithData() {
    const columns = useColumns();
    const metaData = useMetaData();

    return columns?.map(columnId => metaData?.[columnId]);
}

export function useColumnsSelection() {
    return useSelector(state => state.gridUI.columnsSelection);
}

export function useViewColumns() {
    return useSelector(state => state.gridUI.viewColumns);
}

export function useViewColumnIds() {
    const viewColumns = useViewColumns();
    return viewColumns?.map(vCol => vCol.id);
}

export function useColumnsPermission() {
    return useSelector(state => state.gridUI.columnsPermission);
}

export function useColumnTypes(
    groupAccessLabels = [
        COLUMN_GROUP_SECTIONS.SPECIAL,
        COLUMN_GROUP_SECTIONS.BASIC,
        COLUMN_GROUP_SECTIONS.CODE_FORMATTING
    ]
) {
    const columnTypes = useSelector(state => state.gridUI.columnTypes);
    return columnTypes?.filter(group => groupAccessLabels?.includes(group?.label));
}

export function useIsCreatingColumn() {
    return useSelector(state => state.gridUI.isCreatingColumn);
}

export function useIsUpdatingColumnsPermission() {
    return useSelector(state => state.gridUI.isUpdatingColumnsPermission);
}

export function useColumnsSelected() {
    return useSelector(
        state => state.gridUI.columnsSelected,
        (prev, current) => {
            return JSON.stringify(prev) === JSON.stringify(current);
        }
    );
}

export function useIsSelectedByColumnId({ columnId }) {
    const columnsSelected = useColumnsSelected();
    return columnsSelected?.includes(columnId);
}

export function useDisabledColumns() {
    return useSelector(state => state.gridUI.disabledColumns);
}

export function useDisabledSourceColumns() {
    return useSelector(state => state.gridUI.disabledSourceColumns);
}

export function useDisabledColumnsByType() {
    const viewColumnIds = useViewColumnIds();
    const metaData = useMetaData();

    return viewColumnIds?.filter(columnId => {
        const column = metaData?.[columnId];
        return DISABLED_COLUMN_BY_TYPES?.includes(column?.type);
    });
}

export function useProcessingColumns() {
    return useSelector(state => state.gridUI.processingColumns);
}

export function useReferenceDisabledColumns() {
    const viewColumns = useViewColumnIdsWithReOrder();
    const metaData = useMetaData();

    return viewColumns?.filter(columnId => {
        const column = metaData?.[columnId];
        const type = getCorrectColumnType(column);

        if (type !== columnTypes.REFERENCE) return false;

        const referenceSettings = column?.referenceSettings;
        const primaryReference = referenceSettings?.primaryReference;
        const referenceType = referenceSettings?.referenceType;

        if (referenceType === 'row' && !primaryReference) return true;
        return false;
    });
}

export function useAllPendingColumnIds() {
    const disabledColumnIds = useDisabledColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const processingColumns = useProcessingColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const referenceDisabledColumnIds = useReferenceDisabledColumns();
    const viewColumns = useViewColumns();
    const viewDisabledColumnIds = viewColumns?.filter(viewCol => !viewCol?.editable)?.map(viewCol => viewCol?.id);

    return [
        ...new Set([
            ...disabledColumnIds,
            ...disabledSourceColumns,
            ...processingColumns,
            ...viewDisabledColumnIds,
            ...disabledColumnIdsByType,
            ...referenceDisabledColumnIds
        ])
    ];
}

export function useIsColumnDisabledById(columnId) {
    const disabledColumns = useDisabledColumns();
    return disabledColumns?.includes(columnId);
}

export function useIsFetchingDisabledColumnData() {
    return useSelector(state => state.gridUI.isFetchingDisabledColumnData);
}

export function useWindowColumnStartIndex() {
    return useSelector(state => state.gridUI.columnStartIndex);
}

export function useWindowColumnStopIndex() {
    return useSelector(state => state.gridUI.columnStopIndex);
}

export function useColumnWidthStore() {
    const viewColumnsWithReordered = useViewColumnsWithReOrder();
    const keyValue = {};
    for (const viewColumn of viewColumnsWithReordered) {
        keyValue[viewColumn?.id] = getViewColumnWidth(viewColumn);
    }

    return keyValue;
}

export function useViewColumnWidthMetadata() {
    const viewColumns = useViewColumns();
    const metaData = useMetaData();
    return viewColumns.map(viewCol => {
        const id = viewCol?.id;
        const columnInfo = metaData?.[id];
        return { ...viewCol, ...columnInfo };
    });
}

export function useViewColumnWidthMetadata2() {
    return useSelector(state => {
        const { gridUI } = state;
        const { viewColumns, metaData } = gridUI;
        return viewColumns.map(viewCol => {
            const id = viewCol?.id;
            const columnInfo = metaData?.[id];
            return { ...viewCol, ...columnInfo };
        });
    });
}

export function useViewColumnsWithReOrderAndData() {
    const viewColumns = useViewColumns();
    let cloneViewColumns = [...viewColumns];
    let filteredColumns = cloneViewColumns.filter(col => col.viewable);
    let newColumns = orderBy(filteredColumns, ['order'], ['asc']);
    return newColumns;
}

export function useViewColumnsWithReOrderAndMetaData() {
    const columns = useViewColumnsWithReOrderAndData();
    const metaData = useMetaData();

    return columns.map(col => {
        const id = col?.id;
        const columnInfo = metaData?.[id];
        return { ...col, ...columnInfo };
    });
}

export function useViewColumnsWithReOrder() {
    const viewColumns = useViewColumnsWithReOrderAndData();
    const workspaceRole = useWorkspaceRole();
    const metaData = useMetaData();
    const dependencies = useDependencyListWithoutFakeId();
    const languagePairs = useCurrentUserLanguagePairs();

    if ([roleConst.CREATOR, roleConst.EDITOR, roleConst.VIEWER, roleConst.LOCALIZATION_MANAGER].includes(workspaceRole))
        return viewColumns;

    return viewColumns.filter(viewCol => {
        const columnId = viewCol?.id;
        const columnInfo = metaData?.[columnId];
        const columnType = getCorrectColumnType(columnInfo);

        if (columnType !== columnTypes.TRANSLATION) return true;
        const country = columnInfo?.group;
        if (languagePairs?.length === 0) return false;

        const matchedLanguagePairs = findMatchLanguagePairs({ languagePairs, country });

        if (matchedLanguagePairs?.length === 0) return false;
        const isShow = isShowMatchedLanguagePairsColumn({
            country,
            columnId,
            matchedLanguagePairs,
            metaData,
            dependencies
        });

        if (!isShow) return false;

        return true;
    });
}

export function useViewColumnIdsWithReOrder() {
    const viewColumnsOrdered = useViewColumnsWithReOrder();
    return viewColumnsOrdered?.map(column => column?.id);
}

export function useViewColumnsWithUserLanguageViewsAndMetadata() {
    const columnIds = useViewColumnIdsWithReOrder();
    const metaData = useMetaData();

    return columnIds.map(columnId => ({
        ...metaData?.[columnId]
    }));
}

export function useViewColumnOptionsWithReOrder() {
    const newColumns = useViewColumnsWithReOrderAndData();
    const metaData = useMetaData();

    return newColumns.map(column => {
        let columnDetail = metaData[column.id];
        let columnType = getCorrectColumnType(columnDetail);
        return {
            label: columnDetail.name,
            value: columnDetail.id,
            icon: (
                <ColumnIcon
                    group={columnDetail.group}
                    type={columnType}
                    customProperties={columnDetail?.customProperties}
                />
            )
        };
    });
}

export function useGetColumnSelectList() {
    const viewColumns = useViewColumns();
    const metaData = useMetaData();

    return viewColumns
        ?.filter(col => col?.viewable)
        ?.map(col => {
            const column = metaData?.[col?.id];
            let columnType = getCorrectColumnType(column);
            return {
                value: column.id,
                label: column.name,
                column: column,
                type: columnType,
                icon: () => (
                    <ColumnIcon group={column?.group} type={columnType} customProperties={column.customProperties} />
                )
            };
        });
}

export function useDependencyOptions() {
    const viewColumns = useViewColumns();
    const metaData = useMetaData();
    const dependencyOptions = viewColumns
        ?.filter(viewCol => {
            const isSystemColumn = SYSTEM_COLUMN_IDS?.includes(viewCol?.id);
            const column = metaData?.[viewCol?.id];
            const columnType = getCorrectColumnType(column);
            return !isSystemColumn && columnType !== columnTypes.REFERENCE;
        })
        .map(viewCol => {
            const columnInfo = metaData?.[viewCol?.id];
            return {
                icon: () => (
                    <ColumnIcon
                        group={columnInfo?.group}
                        type={columnInfo?.type}
                        customProperties={columnInfo?.customProperties}
                    />
                ),
                value: columnInfo?.id,
                label: columnInfo?.name,
                placement: 'right'
            };
        });
    return dependencyOptions || [];
}

function getRefSource({ metaData, columnId, data, rowId, dependencies }) {
    const foundDependency = dependencies?.find(dpDc => dpDc.child === columnId);
    const isParentTranslation = metaData?.[foundDependency?.parent]?.type === columnTypes.TRANSLATION;

    if (!foundDependency || !isParentTranslation) return null;

    if (foundDependency) {
        const parent = foundDependency?.parent;
        const child = foundDependency?.child;
        const SUPPORT_LANGUAGE_TYPES = [TRANSLATION_TYPES.SOURCE_LANG, TRANSLATION_TYPES.TARGET_LANG];

        const childColumnDetail = metaData?.[child];
        const parentColumnDetail = metaData?.[parent];

        const isChildLanguageColumn = SUPPORT_LANGUAGE_TYPES?.includes(
            childColumnDetail?.customProperties?.localizationType
        );
        const isParentLanguageColumn = SUPPORT_LANGUAGE_TYPES?.includes(
            parentColumnDetail?.customProperties?.localizationType
        );

        if (!isChildLanguageColumn || !isParentLanguageColumn) {
            return null;
        }

        const value = getCellValueOnly({ data, rowId, columnId: parent });
        const sourceText = typeof value === 'string' ? value : JSON.stringify(value);

        return {
            columnId: parent,
            sourceText,
            sourceLang: parentColumnDetail?.group,
            currentColumnLanguage: childColumnDetail?.group
        };
    }
}

export function useSourceTextAndSourceLangueColumns({ rowId, columnId }) {
    const metaData = useMetaData();
    const data = useData();
    const dependencies = useDependencies();

    const detailInfo = getRefSource({ metaData, columnId, data, rowId, dependencies });

    return {
        sourceData: {
            columnId: detailInfo?.columnId,
            sourceText: detailInfo?.sourceText,
            sourceLang: detailInfo?.sourceLang
        },
        currentColumnLanguage: detailInfo?.currentColumnLanguage
    };
}

export function useColumnIdByIndex({ columnIndex }) {
    const columnIds = useViewColumnIdsWithReOrder();
    return columnIds[columnIndex];
}

export function useColumnDetailByColumnIndex({ columnIndex }) {
    const columnIds = useViewColumnIdsWithReOrder();
    const columnId = columnIds?.[columnIndex];
    return useColumnMetaData(columnId);
}

export function useColumnDetailByColumnId(columnId) {
    return useSelector(state => state.gridUI.metaData?.[columnId]);
}

export function useColumnIdsWithoutDisabledColumns() {
    const disabledColumnIds = useDisabledColumns();
    const processingColumnIds = useProcessingColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const viewColumns = useViewColumns();

    const viewColumnIds = viewColumns
        ?.filter(col => col => col?.viewable)
        ?.filter(col => col?.editable)
        ?.map(col => col?.id)
        ?.filter(colId => !SYSTEM_COLUMN_IDS?.includes(colId));

    return removeArrayInArray(viewColumnIds, [
        ...disabledColumnIds,
        ...processingColumnIds,
        ...disabledSourceColumns,
        ...disabledColumnIdsByType
    ]);
}

export function useIsOverLocaleLimit() {
    const locales = useCompanyLocales() || [];
    const columns = useViewColumnsWithReOrderAndData();
    const metaData = useMetaData();

    let isOver = false;

    for (let column of columns) {
        const colDetail = metaData?.[column?.id];
        if (colDetail?.type === columnTypes?.TRANSLATION && !locales?.includes(colDetail?.group)) {
            isOver = true;
            break;
        }
    }
    return isOver;
}

export function useColumnUploadFolderStatus() {
    const unsorted = useSelector(state => state.gridUI.columnUploadFolderStatus);
    return unsorted.map(column => {
        return {
            ...column,
            files: [...column.files.filter(file => file.error), ...column.files.filter(file => !file.error)]
        };
    });
}

export function useAllUploadingFileCount() {
    const columns = useColumnUploadFolderStatus();
    let count = 0;
    columns.forEach(column => {
        column.files.forEach(file => {
            if (file?.isUploading) {
                ++count;
            }
        });
    });
    return count;
}

export function useUploadFilesByColumnId(columnId) {
    const columns = useColumnUploadFolderStatus();
    return columns?.find(column => column.id === columnId)?.files;
}

export function useUploadingFileCountByColumnId(columnId) {
    const files = useUploadFilesByColumnId(columnId);
    return files?.filter(file => file.isUploading).length;
}

export function useFailedFileCountByColumnId(columnId) {
    const files = useUploadFilesByColumnId(columnId);
    return files?.filter(file => file.error).length;
}

export function useViewOnlyLanguageColumnsHaveDependency() {
    const translationData = useTranslationDashboardDataByView();
    const dependencies = useDependencyList();
    const viewColumnIds = useViewColumnIdsWithReOrder();
    const metaData = useMetaData();

    const dependencyWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    return viewColumnIds
        ?.filter(colId => {
            const columnDetail = metaData?.[colId];
            const columnType = getCorrectColumnType(columnDetail);
            const dependencyFound = dependencyWithoutFake?.find(
                dpDc => dpDc?.parent === colId || dpDc?.child === colId
            );
            return columnType === columnTypes.TRANSLATION && dependencyFound;
        })
        ?.map(colId => {
            const columnDetail = metaData?.[colId];
            let parentDpDcFound = dependencyWithoutFake?.find(dpDc => dpDc?.parent === colId);
            let childDpDcFound = dependencyWithoutFake?.find(dpDc => dpDc?.child === colId);

            return {
                ...columnDetail,
                translationData: translationData?.[colId],
                sourceLanguageCode: childDpDcFound ? metaData?.[childDpDcFound?.parent]?.group : undefined,
                type:
                    parentDpDcFound && childDpDcFound
                        ? TRANSLATION_OVERVIEW_TYPES.SOURCE_TARGET
                        : parentDpDcFound
                        ? TRANSLATION_OVERVIEW_TYPES.SOURCE
                        : TRANSLATION_OVERVIEW_TYPES.TARGET
            };
        });
}

export function useJsonLanguageOptions() {
    const columns = useViewOnlyLanguageColumnsHaveDependency();

    return columns?.map(column => ({
        value: column?.id,
        label: column?.group,
        icon: () => <CountryFlag languageCountryCode={column?.group} />
    }));
}

export function usePoLanguageOptions() {
    const columns = useViewOnlyLanguageColumnsHaveDependency();

    return columns
        ?.filter(column => column?.type !== TRANSLATION_OVERVIEW_TYPES.SOURCE)
        ?.map(column => ({
            value: column?.id,
            label: column?.group,
            icon: () => <CountryFlag languageCountryCode={column?.group} />
        }));
}

export function useIsDisabledUpdateColumn({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return DISABLED_UPDATE_COLUMN_GRID_TYPES?.includes(gridDetail?.type);
}

export function useFormatColumnId() {
    return useSelector(state => state?.gridUI?.formatColumnId);
}

export function useToggleFakeColumn() {
    return useSelector(state => state?.gridUI?.toggleFakeColumn);
}

export function useIsColumnInRange({ columnId, columnIndex }) {
    return useSelector(state => {
        const { gridUI } = state;
        const { columnStartIndex, columnStopIndex, columnsSelected } = gridUI;
        return (
            columnsSelected.includes(columnId) ||
            between(
                columnIndex,
                Math.min(columnStartIndex, columnStopIndex),
                Math.max(columnStartIndex, columnStopIndex)
            )
        );
    });
}

export function useIsColumnProcessing(columnId) {
    return useSelector(state => {
        const gridUI = state?.gridUI;
        const { processingColumns } = gridUI;
        return processingColumns?.includes(columnId);
    });
}

export function getColumnByPublicId(publicId) {
    const { getState } = store;
    const { gridUI } = getState();
    const { columnsPermission } = gridUI;
    return columnsPermission?.find(column => column.publicId === publicId);
}

export function useReferenceColumns() {
    return useSelector(state => {
        const columns = [];
        const { gridUI } = state;
        const { columnsPermission } = gridUI;
        columnsPermission.forEach(column => {
            if (column?.referencingItems?.length) {
                columns.push(column);
            }
        });
        return columns;
    });
}

export function useSelectColumnIds() {
    const viewColumnIds = useViewColumnIdsWithReOrder();
    const rowsRangeIndexes = useRowsRangeIndexes();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();

    if (rowsRangeIndexes?.length) return viewColumnIds;

    const max = Math.max(columnStartIndex, columnStopIndex);
    const min = Math.min(columnStartIndex, columnStopIndex);
    return viewColumnIds?.slice(min, Math.min(max + 1, viewColumnIds?.length));
}

export function useSelectPublicColumnIds() {
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const rowsRangeIndexes = useRowsRangeIndexes();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();

    const viewColumnIds = viewColumns?.map(v => v?.publicId);

    if (rowsRangeIndexes?.length) return viewColumnIds;

    const max = Math.max(columnStartIndex, columnStopIndex);
    const min = Math.min(columnStartIndex, columnStopIndex);
    return viewColumnIds?.slice(min, Math.min(max + 1, viewColumnIds?.length));
}

export function usePublicSelectedColumns() {
    const metaData = useMetaData();
    const columnsSelected = useColumnsSelected();
    return columnsSelected?.map(columnId => metaData?.[columnId]?.publicId);
}
