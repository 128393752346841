import React from 'react';

function IconTMXFileSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M35.5334 17.5586H34.6173V7.72157L26.8957 0H6.9207C6.16134 0.000813019 5.43331 0.302827 4.89637 0.839776C4.35942 1.37672 4.0574 2.10475 4.05659 2.86411V17.5586H3.14054C2.30824 17.5606 1.51061 17.8921 0.922081 18.4807C0.333556 19.0692 0.00202753 19.8668 0 20.6991L0 32.7184C0.00202753 33.5507 0.333556 34.3483 0.922081 34.9368C1.51061 35.5254 2.30824 35.8569 3.14054 35.8589H4.05659V40.1359C4.0574 40.8953 4.35942 41.6233 4.89637 42.1602C5.43331 42.6972 6.16134 42.9992 6.9207 43H31.7532C32.5126 42.9992 33.2406 42.6972 33.7775 42.1602C34.3145 41.6233 34.6165 40.8953 34.6173 40.1359V35.8589H35.5334C36.3657 35.8569 37.1633 35.5254 37.7518 34.9368C38.3403 34.3483 38.6719 33.5507 38.6739 32.7184V20.6991C38.6719 19.8668 38.3403 19.0692 37.7518 18.4807C37.1633 17.8921 36.3657 17.5606 35.5334 17.5586Z"
                    fill="white"
                />
                <path
                    d="M35.5334 17.5586H34.6173V7.72157L26.8957 0H6.9207C6.16134 0.000813019 5.43331 0.302827 4.89637 0.839776C4.35942 1.37672 4.0574 2.10475 4.05659 2.86411V17.5586H3.14054C2.30824 17.5606 1.51061 17.8921 0.922081 18.4807C0.333556 19.0692 0.00202753 19.8668 0 20.6991L0 32.7184C0.00202753 33.5507 0.333556 34.3483 0.922081 34.9368C1.51061 35.5254 2.30824 35.8569 3.14054 35.8589H4.05659V40.1359C4.0574 40.8953 4.35942 41.6233 4.89637 42.1602C5.43331 42.6972 6.16134 42.9992 6.9207 43H31.7532C32.5126 42.9992 33.2406 42.6972 33.7775 42.1602C34.3145 41.6233 34.6165 40.8953 34.6173 40.1359V35.8589H35.5334C36.3657 35.8569 37.1633 35.5254 37.7518 34.9368C38.3403 34.3483 38.6719 33.5507 38.6739 32.7184V20.6991C38.6719 19.8668 38.3403 19.0692 37.7518 18.4807C37.1633 17.8921 36.3657 17.5606 35.5334 17.5586ZM31.7532 41.5411H6.9207C6.54814 41.5407 6.19097 41.3925 5.92753 41.1291C5.6641 40.8656 5.51592 40.5084 5.51552 40.1359V35.8589H33.1584V40.1359C33.158 40.5084 33.0098 40.8656 32.7464 41.1291C32.4829 41.3925 32.1258 41.5407 31.7532 41.5411ZM27.0923 7.44821V2.58768C28.3278 3.77402 30.3519 5.77812 32.0258 7.44821H27.0923ZM6.9207 1.45893H25.6334V8.17768C25.6336 8.37108 25.7105 8.55651 25.8473 8.69326C25.984 8.83002 26.1694 8.90694 26.3628 8.90714H33.1584V17.5586H5.51552V2.86411C5.51592 2.49156 5.6641 2.13438 5.92753 1.87094C6.19097 1.60751 6.54814 1.45934 6.9207 1.45893ZM35.5334 34.4H3.14054C2.69473 34.3994 2.26736 34.222 1.95213 33.9068C1.6369 33.5916 1.45954 33.1642 1.45893 32.7184V20.6991C1.45954 20.2533 1.6369 19.826 1.95213 19.5107C2.26736 19.1955 2.69473 19.0181 3.14054 19.0175H35.5334C35.9792 19.0181 36.4065 19.1955 36.7218 19.5107C37.037 19.826 37.2144 20.2533 37.215 20.6991V32.7184C37.2144 33.1642 37.037 33.5916 36.7218 33.9068C36.4065 34.222 35.9792 34.3994 35.5334 34.4Z"
                    fill="#4D3F8A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.00732 23.4895H7.92749V31.5405H9.91931V23.4895H12.8794V21.8763H5.00732V23.4895ZM19.0981 28.886L16.6157 21.8763H14.0142V31.5405H16.0052V28.8991L15.8063 24.3457L18.4078 31.5405H19.7754L22.3838 24.3388L22.1849 28.8991V31.5405H24.1821V21.8763H21.5676L19.0981 28.886ZM31.3041 21.8763H33.5946L30.7804 26.6685L33.6676 31.5405H31.351L29.4927 28.1558L27.6338 31.5405H25.3171L28.205 26.6685L25.3909 21.8763H27.6806L29.4927 25.2088L31.3041 21.8763Z"
                    fill="#63C5EA"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="38.6747" height="43" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(IconTMXFileSVG);
