import request from './request';
import { encodeURL } from 'utils/http';
import isEmpty from 'lodash/isEmpty';
import { RECORDS_RENDER, MAXIMUM_VIEWS, RECORD_HISTORY_RENDER, DATA_QUERY_OPTIONS } from 'const/gridUI';
import { APP_API_PREFIX_GRID_UI } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';
import * as columnTypes from 'const/columnTypes';
import { getServerTimeZoneValue } from 'const/time';
import { getRegionBaseUrl } from 'utils/workspace';

const viewApis = {
    viewColumns: ({ defaultAccessViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${defaultAccessViewId}/columns`,
    addColumns: ({ dbId, defaultAccessViewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/columns`,
    createRecords: ({ dbId, defaultAccessViewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${defaultAccessViewId}/records`,
    viewRecords: ({ defaultAccessViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${defaultAccessViewId}/records`,
    getViews: ({ dbId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views`,
    getView: ({ dbId, viewId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}`,
    createView: ({ dbId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views`,
    deleteView: ({ dbId, viewId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}`,
    updateView: ({ dbId, viewId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}`,
    updateViewCustomProperties: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/properties`,
    viewColumnPermission: ({ dbId, columnId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/columns/${columnId}`,
    createGridColumnAndAddColumnToView: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/columns`,
    deleteViewRecords: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records`,
    exportView: ({ dbId, viewId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/export`,
    importViewReadFile: () => `${APP_API_PREFIX_GRID_UI}/api/v1/app/media/preview`,
    importViewReadJSONFile: () => `${APP_API_PREFIX_GRID_UI}/api/v1/app/media/preview-json`,
    importViewReadPOFile: () => `${APP_API_PREFIX_GRID_UI}/api/v1/app/media/preview-po`,
    importView: ({ dbId, viewId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/import`,
    reorderColumns: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/columns/reorder`,
    viewRecordHistory: ({ dbId, defaultAccessViewId, recordId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/records/${recordId}/histories`,
    viewRecordsPasted: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records/paste`,
    viewRecordsCopy: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records/copy`,
    getTranslationData: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/translation-statistic`,
    fillColumns: ({ dbId, defaultAccessViewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/autofill`,
    importJsonLocalizationGrid: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/translation-import`,
    searchRecords: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records/search`,
    getCurrentRecordIndex: ({ recordId, dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records/${recordId}/offset`,
    generatePreviewImport: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/import/preview`,
    getImportDiffCheck: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/import/diff-check`,
    mergeImport: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/import/merge`,
    viewRecordsByRangeIndex: ({ dbId, viewId, fromRecordId, toRecordId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records/ranges`,
    deleteRecords: ({ defaultAccessViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${defaultAccessViewId}/records/bulk`,
    replaceSearch: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/records/replace`,
    deleteColumnsValue: ({ dbId, defaultAccessViewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/cleancolumn`,
    deleteAllViewRecords: ({ viewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${viewId}/clearRecords`,
    undo: `${APP_API_PREFIX_GRID_UI}/api/v1/command/undo`,
    redo: `${APP_API_PREFIX_GRID_UI}/api/v1/command/redo`,
    clear: `${APP_API_PREFIX_GRID_UI}/api/v1/command/clear`,
    viewDiffRecords: ({ sourceViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${sourceViewId}/diff-check`,
    mergeView: ({ destinationViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${destinationViewId}/merge`,
    resizeColumn: ({ dbId, viewId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/columns/${columnId}/resize`,
    createPreviewMerges: ({ sourceViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${sourceViewId}/preview-merges`,
    previewMerges: ({ sourceViewId, taskId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${sourceViewId}/preview-merges/${taskId}`,
    importAggregate: ({ viewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/import/aggregate`,
    convertedDataReferenceColumns: ({ sourceViewId, taskId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${sourceViewId}/preview-merges/${taskId}/converted-data-reference-columns`
};

export async function getViewRecordHistoryApi({
    defaultAccessViewId,
    dbId,
    recordId,
    offset = 0,
    limit = RECORD_HISTORY_RENDER
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const pageParams = encodeURL(params);

    let extraUrl = `?page=${pageParams}`;

    const response = await request.get(
        `${viewApis.viewRecordHistory({ defaultAccessViewId, dbId, recordId })}${extraUrl}`
    );
    return {
        recordHistory: response.data,
        totalRecordHistory: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function reorderViewColumnsApi({ dbId, viewId, data }) {
    const response = await request.post(viewApis.reorderColumns({ dbId, viewId }), data);
    return response.data;
}

export async function getViewColumnsApi({ defaultAccessViewId, dbId }) {
    const response = await request.get(viewApis.viewColumns({ defaultAccessViewId, dbId }));
    return response.data;
}

export async function mergeViewApi({ dbId, destinationViewId, body }) {
    const response = await request.post(viewApis.mergeView({ dbId, destinationViewId }), body);
    return response.data;
}

export async function getViewRecordsApiV2({
    defaultAccessViewId,
    dbId,
    offset = 0,
    limit = RECORDS_RENDER,
    filterQuery,
    sortQuery,
    columnIds = [],
    isShowAutoQA,
    token
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const referenceOption = {
        referenceOption: 'all',
        fetchFileOption: 'all'
    };

    const pageParams = encodeURL(params);
    const refParams = encodeURL(referenceOption);

    let commonUrl = `?page=${pageParams}&fetchOptions=${refParams}`;
    if (!isEmpty(filterQuery)) {
        const object = { query: filterQuery };
        commonUrl += `&${objectToQueryParams(object)}`;
    }

    if (!isEmpty(sortQuery)) {
        const object = { sort: sortQuery };
        commonUrl += `&${objectToQueryParams(object)}`;
    }

    if (isShowAutoQA) {
        commonUrl += `&cellMetadata=true`;
    }

    let extraUrl = commonUrl;
    if (!isEmpty(columnIds)) {
        extraUrl += `&columnIds=${columnIds}`;
    }

    const extraUrlRecordMetaData = `${commonUrl}&columnIds=${['_metadata']}`;

    const [recordResponse, metaDataResponse] = await Promise.all([
        request.get(
            `${viewApis.viewRecords({ defaultAccessViewId, dbId })}${extraUrl}`,
            token && { cancelToken: token }
        ),
        request.get(
            `${viewApis.viewRecords({ defaultAccessViewId, dbId })}${extraUrlRecordMetaData}`,
            token && { cancelToken: token }
        )
    ]);

    return {
        recordIds: recordResponse?.data?.recordIds,
        columnIds: recordResponse?.data?.columnIds,
        data: recordResponse?.data?.data,
        recordMetaData: metaDataResponse?.data?.data,
        totalRecords: Number(recordResponse?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(recordResponse?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function getColumnsRecordsApi({
    dbId,
    defaultAccessViewId,
    offset,
    limit,
    columnIds,
    filterQuery,
    sortQuery,
    dataOptions = [
        DATA_QUERY_OPTIONS.COLOR,
        DATA_QUERY_OPTIONS.DATA,
        DATA_QUERY_OPTIONS.DEPENDENCY_STATUS,
        DATA_QUERY_OPTIONS.TICKET,
        DATA_QUERY_OPTIONS.SOURCE_STATUS,
        DATA_QUERY_OPTIONS.TM
    ]
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const referenceOption = {
        referenceOption: 'all',
        fetchFileOption: 'all',
        extendedAttrs: dataOptions
    };

    const pageParams = encodeURL(params);
    const refParams = encodeURL(referenceOption);

    let extraUrl = `?page=${pageParams}&fetchOptions=${refParams}`;

    extraUrl += `&columnIds=${columnIds}`;

    if (!isEmpty(filterQuery)) {
        const object = { query: filterQuery };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    if (!isEmpty(sortQuery)) {
        const object = { sort: sortQuery };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.get(`${viewApis.viewRecords({ dbId, defaultAccessViewId })}${extraUrl}`);
    return {
        ...response?.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function addColumnsToViewApi({ defaultAccessViewId, dbId, columns }) {
    const response = await request.post(viewApis.addColumns({ defaultAccessViewId, dbId }), { columns });
    return response.data;
}

export async function setViewRecords({ defaultAccessViewId, dbId, body, autoQA = false }) {
    let url = viewApis.createRecords({ defaultAccessViewId, dbId });
    if (autoQA) {
        url += `?autoQA=true`;
    }
    const response = await request.put(url, body);
    return response.data;
}

export async function createViewRecordApi({ defaultAccessViewId, dbId, body }) {
    const response = await request.post(viewApis.createRecords({ defaultAccessViewId, dbId }), body);
    return response.data;
}

export async function getViewsApi({ dbId, gridId, paramOptions = {}, sort = { name: 'asc' } }) {
    const params = {
        offset: 0,
        limit: MAXIMUM_VIEWS
    };

    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}&sort=${encodeURL(sort)}`;
    extraUrl += `&query=${encodeURL({ gridId: gridId })}`;
    if (!isEmpty(paramOptions)) {
        const includeDefaultView = paramOptions?.includeDefaultView;
        const expand = paramOptions?.expand;
        extraUrl += `&includeDefaultView=${includeDefaultView}`;
        if (expand) {
            extraUrl += `&expand=${expand}`;
        }
    }

    const response = await request.get(`${viewApis.getViews({ dbId })}${extraUrl}`);
    return response.data;
}

export async function getViewApi({ dbId, viewId }) {
    const response = await request.get(`${viewApis.getView({ dbId, viewId })}`);
    return response.data;
}

export async function createViewApi({ dbId, view, currentViewId }) {
    const response = await request.post(`${viewApis.createView({ dbId })}?sourceViewId=${currentViewId}`, view);
    return response.data;
}

export async function updateViewApi({ dbId, view }) {
    const response = await request.put(viewApis.updateView({ dbId, viewId: view.id }), view);
    return response.data;
}

export async function updateViewCustomPropertiesApi({ dbId, viewId, body }) {
    const response = await request.put(viewApis.updateViewCustomProperties({ dbId, viewId }), body);
    return response.data;
}

export async function deleteViewApi({ dbId, viewId }) {
    const response = await request.delete(viewApis.updateView({ dbId, viewId }));
    return response.data;
}

export async function updateColumnPermissionViewApi({ dbId, viewId, columnId, body }) {
    const response = await request.put(viewApis.viewColumnPermission({ dbId, viewId, columnId }), body);
    return response.data;
}

export async function removeColumnInViewApi({ dbId, viewId, columnId }) {
    const response = await request.delete(viewApis.viewColumnPermission({ dbId, viewId, columnId }));
    return response.data;
}

export async function createColumnCombineApi({ dbId, viewId, body }) {
    const response = await request.post(viewApis.createGridColumnAndAddColumnToView({ dbId, viewId }), body);
    return response.data;
}

export async function deleteViewRecordsApi({ dbId, defaultAccessViewId, data }) {
    const response = await request.delete(viewApis.deleteViewRecords({ dbId, viewId: defaultAccessViewId }), {
        data
    });
    return response.data;
}

export async function exportViewApi({ dbId, viewId, queryParams, viewName }) {
    const { exportFormat, columnIds, csvDelimiter, query, sort } = queryParams;
    let url = viewApis.exportView({ dbId, viewId });
    if (!isEmpty(queryParams)) {
        url += `?exportFormat=${exportFormat}&columnIds=${columnIds}&csvDelimiter=${encodeURL(csvDelimiter)}`;
        if (!isEmpty(query)) {
            const object = { query };
            url += `&${objectToQueryParams(object)}`;
        }
        if (!isEmpty(sort)) {
            const object = { sort };
            url += `&${objectToQueryParams(object)}`;
        }
    }

    const linkUrl = `${getRegionBaseUrl()}${url}`;
    window.location.href = linkUrl;
    return false;
}

export async function exportGridToFileContentApi({ dbId, viewId, queryParams }) {
    const { exportFormat, columnIds } = queryParams;
    let url = viewApis.exportView({ dbId, viewId });
    if (!isEmpty(queryParams)) {
        url += `?exportFormat=${exportFormat}&columnIds=${columnIds}`;
    }
    const response = await request.get(url);

    return response.data;
}

export async function importViewReadFileApi({ dbId, file }) {
    const formData = new FormData();

    formData.append('file', file[0], file[0].name);
    const response = await request.post(`${viewApis.importViewReadFile()}?dbId=${dbId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export async function importViewReadFileJSONApi({ dbId, file }) {
    const formData = new FormData();

    formData.append('file', file[0], file?.[0].name);
    const response = await request.post(`${viewApis.importViewReadJSONFile()}?dbId=${dbId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export async function importViewReadFilePOApi({ dbId, file }) {
    const formData = new FormData();

    formData.append('file', file[0], file?.[0].name);
    const response = await request.post(`${viewApis.importViewReadPOFile()}?dbId=${dbId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export async function importPreviewWithResourceKeyApi({ key, importRequest }) {
    const formData = new FormData();
    formData.append('importRequest', JSON.stringify(importRequest));
    formData.append('key', key);
    const response = await request.post(`${viewApis.importViewReadFile()}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export async function importViewApi({ dbId, viewId, options, resourceKey }) {
    const { columnSettings, isFirstRowHeader, importOption, keyColumn, rootPath } = options;
    const columnMappings = Object.keys(columnSettings).map(key => ({
        columnId: columnSettings[key]['targetColId'],
        fileColumnIndex: columnSettings[key]['order'],
        columnMappingOption:
            columnSettings[key].type === columnTypes.DATETIME && columnSettings[key]?.timezone?.value
                ? {
                      timezone: getServerTimeZoneValue(columnSettings[key].timezone.value)
                  }
                : undefined
    }));

    let importRequest = {
        withHeader: isFirstRowHeader ? true : false,
        firstDataRow: isFirstRowHeader ? 1 : 0,
        importOption,
        columnMappings: columnMappings.filter(map => !!map.columnId),
        keyColumn
    };

    if (rootPath) {
        importRequest = {
            ...importRequest,
            rootPath
        };
    }

    const formData = new FormData();

    // formData.append('file', file[0], file[0].name);
    formData.append('importRequest', JSON.stringify(importRequest));
    const response = await request.post(`${viewApis.importView({ dbId, viewId })}?key=${resourceKey}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export async function importJSONLocalizationGridApi({ dbId, viewId, body, query }) {
    let url = viewApis.importJsonLocalizationGrid({ dbId, viewId });
    if (!isEmpty(query)) {
        const extra = objectToQueryParams(query);
        url += `?${extra}`;
    }

    const response = await request.post(url, body);
    return response.data;
}

export async function pasteViewRecordsOnColumnHeaderApi({ dbId, viewId, body, queryParams }) {
    let url = viewApis.viewRecordsPasted({ dbId, viewId });
    const response = await request.put(url, body);
    return response.data;
}

export async function copyViewRecordsOnColumnHeaderApi({ dbId, viewId, columnIds, queryParams }) {
    let extraUrl = `${viewApis.viewRecordsCopy({ dbId, viewId })}?columnIds=${columnIds}`;

    const { query, sort } = queryParams;
    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }
    if (!isEmpty(sort)) {
        const object = { sort };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.get(extraUrl);
    return response.data;
}

export async function getExistViewRecordsApi({ dbId, viewId, columnIds, recordIds }) {
    let extraUrl = ``;

    const referenceOption = {
        referenceOption: 'all',
        fetchFileOption: 'all'
    };

    const object = {
        query: {
            _recordId: {
                in: recordIds
            }
        }
    };

    if (columnIds?.length) {
        extraUrl += `?columnIds=${columnIds}`;
        extraUrl += `&${objectToQueryParams(object)}`;
    } else {
        extraUrl += `?${objectToQueryParams(object)}`;
    }

    const refParams = encodeURL(referenceOption);

    extraUrl += `&fetchOptions=${refParams}`;

    const response = await request.get(`${viewApis.viewRecords({ defaultAccessViewId: viewId, dbId })}${extraUrl}`);
    return response.data;
}

export async function getTranslationDataApi({ dbId, viewId, columnIds }) {
    let extraUrl = viewApis.getTranslationData({ dbId, viewId });
    if (columnIds?.length) {
        extraUrl += `?columnIds=${columnIds}`;
    }
    const response = await request.get(`${extraUrl}`);
    return response.data;
}

export async function fillColumnsApi({ dbId, defaultAccessViewId, body }) {
    const response = await request.post(viewApis.fillColumns({ dbId, defaultAccessViewId }), body);
    return response.data;
}

export async function searchRecordsApi({ viewId, dbId, ...rest }) {
    let url = `${viewApis.searchRecords({ viewId, dbId })}`;

    const response = await request.post(url, rest);

    return {
        dbId: response?.data?.dbId,
        records: response?.data?.records || [],
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function generatePreviewImportApi({ dbId, viewId, query }) {
    const extra = objectToQueryParams(query);

    const url = `${viewApis.generatePreviewImport({ dbId, viewId })}?${extra}`;
    const response = await request.post(url);
    return response.data;
}

export async function getImportDiffCheckApi({
    dbId,
    viewId,
    offset,
    limit,
    mergeRecordOptions,
    taskId,
    showAllConflicts,
    query
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}&taskId=${taskId}`;
    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (showAllConflicts) {
        extraUrl += `&showAllConflicts=${showAllConflicts}`;
    }

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const url = `${viewApis.getImportDiffCheck({ dbId, viewId })}${extraUrl}`;
    const response = await request.get(url);
    return {
        records: response.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function getPreviewImportAggregationApi({
    taskId,
    viewId,
    dbId,
    columnIds = [],
    mergeColumnOptions,
    mergeRecordOptions,
    query,
    aggregateType = 'conflicted',
    showAllConflicts = false
}) {
    let extraUrl = `?taskId=${taskId}&aggregateType=${aggregateType}`;

    if (columnIds?.length) {
        extraUrl += `&columnIds=${columnIds}`;
    }

    if (showAllConflicts) {
        extraUrl += `&showAllConflicts=${showAllConflicts}`;
    }

    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (mergeColumnOptions?.length) {
        extraUrl += `&mergeColumnOptions=${mergeColumnOptions}`;
    }

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.get(`${viewApis.importAggregate({ dbId, viewId: viewId })}${extraUrl}`);
    return response?.data;
}

export async function getCurrentRecordOffsetApi({ viewId, dbId, recordId, filterQuery, sortQuery }) {
    let url = `${viewApis.getCurrentRecordIndex({
        viewId,
        dbId,
        recordId
    })}`;

    if (!isEmpty(sortQuery) && !isEmpty(filterQuery)) {
        const object = { query: filterQuery, sort: sortQuery };
        url += `?${objectToQueryParams(object)}`;
    } else if (!isEmpty(sortQuery)) {
        const object = { sort: sortQuery };
        url += `?${objectToQueryParams(object)}`;
    } else if (!isEmpty(filterQuery)) {
        const object = { query: filterQuery };
        url += `?${objectToQueryParams(object)}`;
    }

    const response = await request.get(`${url}`);
    return response?.data;
}

export async function mergeImportApi({ dbId, viewId, body }) {
    const url = `${viewApis.mergeImport({
        dbId,
        viewId
    })}`;
    const response = await request.post(url, body);
    return response?.data;
}

export async function fetchRangeRecordApi({ query, sort, type, recordIdRanges, columnIds, dbId, viewId, dataOptions }) {
    let url = `${viewApis.viewRecordsByRangeIndex({
        viewId,
        dbId
    })}?type=${type}`;

    if (!isEmpty(sort) && !isEmpty(query)) {
        const object = { query, sort };
        url += `&${objectToQueryParams(object)}`;
    } else if (!isEmpty(sort)) {
        const object = { sort };
        url += `&${objectToQueryParams(object)}`;
    } else if (!isEmpty(query)) {
        const object = { query };
        url += `&${objectToQueryParams(object)}`;
    }

    if (columnIds?.length) {
        url += `&columnIds=${columnIds}`;
    }

    if (recordIdRanges?.length) {
        url += `&recordIdRanges=${encodeURL(recordIdRanges)}`;
    }

    const params = {
        extendedAttrs: dataOptions
    };

    console.log('params', params);
    const refParams = encodeURL(params);
    url += `&fetchOptions=${refParams}`;

    const response = await request.get(`${url}`);

    return {
        recordIds: response?.data?.recordIds,
        columnIds: response?.data?.columnIds,
        data: response?.data?.data,
        recordMetaData: response?.data?.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function deleteRecordsApi({ dbId, defaultAccessViewId, body, query, sort }) {
    let url = viewApis.deleteRecords({ dbId, defaultAccessViewId });

    const response = await request.delete(url, {
        data: body
    });
    return response.data;
}

export async function replaceSearchApi({ dbId, viewId, body }) {
    const url = `${viewApis.replaceSearch({
        dbId,
        viewId
    })}`;
    const response = await request.put(url, body);
    return response;
}

export async function deleteColumnsValueApi({ dbId, defaultAccessViewId, body }) {
    const response = await request.post(viewApis.deleteColumnsValue({ dbId, defaultAccessViewId }), body);
    return response.data;
}

export async function deleteAllRecordsApi({ dbId, viewId, body, filterQuery, sortQuery }) {
    let api = `${viewApis.deleteAllViewRecords({ dbId, viewId })}`;

    if (!isEmpty(sortQuery) && !isEmpty(filterQuery)) {
        const object = { query: filterQuery, sort: sortQuery };
        api += `?${objectToQueryParams(object)}`;
    } else if (!isEmpty(sortQuery)) {
        const object = { sort: sortQuery };
        api += `?${objectToQueryParams(object)}`;
    } else if (!isEmpty(filterQuery)) {
        const object = { query: filterQuery };
        api += `?${objectToQueryParams(object)}`;
    }

    const response = await request.delete(api);
    return response.data;
}

export async function undoApi() {
    const response = await request.post(viewApis.undo);
    return response.data;
}

export async function redoApi() {
    const response = await request.post(viewApis.redo);
    return response.data;
}

export async function clearApi() {
    const response = await request.post(viewApis.clear);
    return response.data;
}

export async function getViewRecordsDiffCheckApi({
    sourceViewId,
    destinationViewId,
    dbId,
    offset,
    limit,
    columnIds = [],
    mergeColumnOptions,
    mergeRecordOptions
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}&destinationViewId=${destinationViewId}`;
    if (columnIds.length) {
        extraUrl += `&columnIds=${columnIds}`;
    }

    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (mergeColumnOptions?.length) {
        extraUrl += `&mergeColumnOptions=${mergeColumnOptions}`;
    }

    const response = await request.get(`${viewApis.viewDiffRecords({ dbId, sourceViewId })}${extraUrl}`);
    return {
        records: response.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function resizeColumnApi({ viewId, dbId, columnId, body }) {
    const response = await request.put(viewApis.resizeColumn({ viewId, dbId, columnId }), body);
    return response?.data;
}

export async function getPreviewMergeApi({
    taskId,
    sourceViewId,
    dbId,
    offset,
    limit,
    columnIds = [],
    mergeColumnOptions,
    mergeRecordOptions,
    query,
    showAllConflicts = false
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}`;
    if (columnIds.length) {
        extraUrl += `&columnIds=${columnIds}`;
    }

    if (showAllConflicts) {
        extraUrl += `&showAllConflicts=${showAllConflicts}`;
    }

    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (mergeColumnOptions?.length) {
        extraUrl += `&mergeColumnOptions=${mergeColumnOptions}`;
    }

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.get(`${viewApis.previewMerges({ dbId, sourceViewId, taskId })}${extraUrl}`);
    return {
        records: response.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function getPreviewMergeAggregationApi({
    taskId,
    sourceViewId,
    dbId,
    columnIds = [],
    mergeColumnOptions,
    mergeRecordOptions,
    query,
    aggregateType = 'conflicted',
    showAllConflicts = false
}) {
    let extraUrl = `?columnIds=${columnIds}&aggregateType=${aggregateType}`;

    if (showAllConflicts) {
        extraUrl += `&showAllConflicts=${showAllConflicts}`;
    }

    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (mergeColumnOptions?.length) {
        extraUrl += `&mergeColumnOptions=${mergeColumnOptions}`;
    }

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.get(
        `${viewApis.previewMerges({ dbId, sourceViewId, taskId })}/aggregate${extraUrl}`
    );
    return response?.data;
}

export async function mergeViaPreviewMergeApi({ dbId, destinationViewId, taskId, body }) {
    const response = await request.post(
        `${viewApis.previewMerges({ dbId, sourceViewId: destinationViewId, taskId })}/merge`,
        body
    );
    return response.data;
}

export async function createPreviewMergeApi({ dbId, sourceViewId, body }) {
    const response = await request.post(`${viewApis.createPreviewMerges({ dbId, sourceViewId })}`, body);
    return response.data;
}

export async function getConvertedDataReferenceColumnsApi({ dbId, sourceViewId, taskId }) {
    const response = await request.get(`${viewApis.convertedDataReferenceColumns({ dbId, sourceViewId, taskId })}`);
    return response.data;
}
