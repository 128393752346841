import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, InputBase } from '@material-ui/core';
import { DISABLED_OPACITY } from 'const/style';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ExtraFilterMenu from './ExtraFilterMenu';
import PopperMenu from 'components/menus/Popper';
import SubFieldRender from './SubFieldRender';
import * as columnTypes from 'const/columnTypes';
import { isLDEmpty } from 'utils/object';
import { NOT_NUMBER_REGEX } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.colors.divider}`,
        borderRadius: 4,
        height: 36
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    expand: {
        flex: 1
    },
    extraFilter: {
        width: 36,
        height: 28,
        padding: 4,
        boxSizing: 'border-box',
        borderLeft: `1px solid ${theme.colors.divider}`
    },
    dpFlex: {
        display: 'flex',
        cursor: 'pointer'
    },
    arrowDown: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center'
    },
    input: {
        paddingLeft: 14,
        paddingRight: 14
    },
    inputBase: {
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey
    }
}));

function InputFilter({
    defaultValue,
    onChange,
    isReadOnly,
    subField,
    isChildDependencyAndLanguageColumn,
    columnType,
    column,
    t
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const containerRef = React.useRef();
    const inputRef = React.useRef();

    const isNumber = React.useMemo(() => {
        return columnType === columnTypes.NUMBER;
    }, [columnType]);

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current && inputRef.current.focus();
        }
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleDelete = e => {
        onChange && onChange({ subField: null, value: '' });
        focusInput();
    };

    const handleInputChange = e => {
        const value = isNumber ? e.target.value.replace(NOT_NUMBER_REGEX, '') : e.target.value;
        onChange && onChange({ subField: null, value });
    };

    const handleExtraFilterChange = ({ value, subField }) => {
        onChange && onChange({ value, subField });
        handleClickAway();
    };

    return (
        <Grid
            direction="row"
            alignItems="center"
            wrap="nowrap"
            container
            className={`${classes.root} ${isReadOnly ? classes.disabled : ``}`}
        >
            <Grid item className={classes.expand}>
                <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.input}>
                    <Grid item className={classes.expand}>
                        {subField ? (
                            <SubFieldRender t={t} subField={subField} value={defaultValue} />
                        ) : (
                            <InputBase
                                placeholder="Value"
                                onChange={handleInputChange}
                                value={isLDEmpty(defaultValue) ? '' : defaultValue}
                                className={`${classes.inputBase} `}
                                ref={inputRef}
                            />
                        )}
                    </Grid>
                    {!isLDEmpty(defaultValue) && (
                        <Grid item onClick={handleDelete} className={classes.dpFlex}>
                            <CloseIconSVG size={13} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {isChildDependencyAndLanguageColumn && (
                <Grid
                    item
                    className={classes.extraFilter}
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item onClick={handleClick} className={classes.arrowDown}>
                        <ArrowDownSVG />
                    </Grid>
                    <div ref={containerRef}></div>
                </Grid>
            )}
            {anchorEl && (
                <PopperMenu
                    className={classes.commentPopup}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                    id={`filter-extra`}
                    handleClickAway={handleClickAway}
                    container={containerRef.current}
                >
                    <ExtraFilterMenu
                        subField={subField}
                        defaultValue={defaultValue}
                        onChange={handleExtraFilterChange}
                        columnType={columnType}
                    />
                </PopperMenu>
            )}
        </Grid>
    );
}
export default React.memo(InputFilter);
