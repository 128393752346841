import React from 'react';
import GridItem from './GridItem';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import GridListSkeleton from 'grids/GridListSkeleton';
import GridItemSkeleton from './GridItemSkeleton';
import { useGridsByDbId, useIsShowFirstPopupCreate, useDbIdCreatingGrid } from 'hooks/grid';
import { useIsSkeletonLoadingId } from 'hooks/database';
import { useContextMenuId } from 'hooks/app';
import GridListEmptySVG from 'assets/images/svg/GridListEmptySVG';
import { useTranslation, Trans } from 'react-i18next';
import { useSortCriteriaWithWorkspaceDbId } from 'hooks/workspace';
import { SORT_BY_CRITERIA } from 'const';

let SPACING = 136;

const useStyles = makeStyles(theme => ({
    root: {
        width: `calc(100% + ${SPACING}px)`,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -(SPACING / 2),
        marginRight: -(SPACING / 2)
    },
    gridItem: {
        paddingTop: '0 !important',
        paddingBottom: '8px !important',
        paddingLeft: SPACING / 2,
        paddingRight: SPACING / 2,
        width: '100%'
    },
    emptyContainer: {
        textAlign: 'center'
    }
}));

function GridList({ workspaceId, dbId, fetchingDisabled }) {
    const { t } = useTranslation();
    const contextMenuId = useContextMenuId();
    const grids = useGridsByDbId(dbId);
    const isShowFirstPopupCreate = useIsShowFirstPopupCreate();
    const isSkeletonLoadingId = useIsSkeletonLoadingId();
    const dbIdCreatingGrid = useDbIdCreatingGrid();
    const classes = useStyles();

    const dbSortCriteria = useSortCriteriaWithWorkspaceDbId({ workspaceId, dbId });

    const isCreating = React.useMemo(() => {
        return !!dbIdCreatingGrid && dbIdCreatingGrid === dbId;
    }, [dbIdCreatingGrid, dbId]);

    const sortedDbs = React.useMemo(() => {
        if (grids?.length > 0 && dbSortCriteria) {
            const newDbs = grids.filter(el => !!el.new);
            let oldDbs = grids.filter(el => !el.new);

            switch (dbSortCriteria.field) {
                case SORT_BY_CRITERIA.CREATED_DATE: {
                    oldDbs = oldDbs.sort((a, b) => {
                        const aTime = new Date(a.createdTime).getTime();
                        const bTime = new Date(b.createdTime).getTime();
                        return dbSortCriteria.direction === 'DESC' ? bTime - aTime : aTime - bTime;
                    });
                    break;
                }
                case SORT_BY_CRITERIA.LAST_MODIFIED: {
                    oldDbs = oldDbs.sort((a, b) => {
                        const aTime = new Date(a.alteredTime).getTime();
                        const bTime = new Date(b.alteredTime).getTime();
                        return dbSortCriteria.direction === 'DESC' ? bTime - aTime : aTime - bTime;
                    });
                    break;
                }
                case SORT_BY_CRITERIA.ALPHABETICAL: {
                    oldDbs = oldDbs.sort((a, b) => {
                        const compare = a.name.normalize().localeCompare(b.name.normalize());
                        return dbSortCriteria.direction === 'DESC' ? -1 * compare : compare;
                    });
                    break;
                }
                default: {
                    break;
                }
            }
            return [...newDbs, ...oldDbs];
        }
        return grids;
    }, [grids, dbSortCriteria]);

    if (!!isSkeletonLoadingId && isSkeletonLoadingId === dbId) return <GridListSkeleton />;

    return (
        <Grid container className={classes.root} direction="row">
            {isCreating && (
                <Grid item lg={6} xl={4} md={6} xs={12} className={classes.gridItem}>
                    <GridItemSkeleton />
                </Grid>
            )}
            {isEmpty(sortedDbs) && !isCreating && (
                <Grid
                    className={classes.emptyContainer}
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    spacing={3}
                >
                    <Grid item>
                        <GridListEmptySVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">{t('dashboard_database_empty_state_title')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            <Trans i18nKey="dashboard_database_empty_state_message" t={t}>
                                line 1
                                <br />
                                line 2
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {!isEmpty(sortedDbs) &&
                sortedDbs.map((grid, index) => {
                    if (grid.isDeleted) return null;
                    return (
                        <Grid item lg={4} md={6} xs={12} key={grid.id} className={classes.gridItem}>
                            <GridItem
                                sortedDbs={sortedDbs}
                                contextMenuId={contextMenuId}
                                grid={grid}
                                dbId={dbId}
                                index={index}
                                name={grid.name}
                                status={grid.status}
                                isShowFirstPopupCreate={isShowFirstPopupCreate === grid.id && index === 0}
                                disabled={fetchingDisabled}
                            />
                        </Grid>
                    );
                })}
        </Grid>
    );
}

export default GridList;
