import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import { PLAN_TYPES } from 'const';
import { useCurrentSubscriptionIs } from 'hooks/payment';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Security from './Security';
import SecurityPassword from './SecurityPassword';

function SecurityRoutes() {
    const { path } = useRouteMatch();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isProPlan = useCurrentSubscriptionIs(PLAN_TYPES.PROFESSIONAL);

    return (
        <Switch>
            <Route path={path} exact>
                <Security />
            </Route>
            <Route path={`${path}/password`}>
                <AuthenticatedRoute paymentConditions={[!isFreePlan, !isProPlan]}>
                    <SecurityPassword />
                </AuthenticatedRoute>
            </Route>
        </Switch>
    );
}

export default React.memo(SecurityRoutes);
