import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import SettingContentLayout from '../common/Layout';
import { PAGE_PADDING, INPUT_RADIUS } from 'const/style';
import ButtonBase from 'components/button/Base';
import { useHistory } from 'react-router-dom';
import { useCompanyId } from 'hooks/auth';
import { disableUserFactorApi, getCompanyInfoApi, getUserFactorApi } from 'services/company';
import Dialog from 'components/dialog/Dialog';
import ProfileBackupCode from 'permission/twoFactor/profile/ProfileBackupCode.jsx';
import StopUserFactorConfirm from './StopUserFactorConfirm';
import { useDispatch } from 'react-redux';
import * as paymentActions from 'payment/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: PAGE_PADDING,
        width: '60%'
    },
    table: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: INPUT_RADIUS,
        marginTop: 40
    },
    heading: {
        background: theme.colors.ghostwhite,
        borderBottom: `1px solid ${theme.colors.border}`,
        padding: theme.spacing(3, 4)
    },
    row: {
        borderBottom: `1px solid ${theme.colors.border}`,
        padding: theme.spacing(3, 4)
    },
    rowWithoutBottomBorder: {
        padding: theme.spacing(3, 4)
    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    midGreyText: {
        color: theme.colors.midGrey
    },
    emptySecurity: {
        height: '100%',
        maxWidth: 500,
        textAlign: 'center',
        margin: 'auto'
    }
}));

function ChangePassword() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const companyId = useCompanyId();
    const [userFactor, setUserFactor] = React.useState({});
    const [companyInfo, setCompanyInfo] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOpenBackupCode, setIsOpenBackupCode] = React.useState(false);
    const [isOpenStopUserFactorConfirm, setIsOpenStopUserFactorConfirm] = React.useState(false);
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchCustomerAndSubscriptions({
                successCallback: () => {
                    console.log('fetchSubscriptions Success');
                },
                errorCallback: () => {
                    console.log('fetchSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        const fetchFactorStatus = async () => {
            try {
                setIsLoading(true);
                const factorStatus = await getUserFactorApi();
                const cInfo = await getCompanyInfoApi({ companyId });
                setUserFactor(factorStatus);
                setCompanyInfo(cInfo);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchFactorStatus();
    }, [companyId]);

    const goToUserFactorSetting = () => history.push(`/profile-2fa`);
    const goToUserAppFactorSetting = () => history.push(`/profile-2fa-app`);
    const goToUserEmailFactorSetting = () => history.push(`/profile-2fa-email`);
    const openBackupCode = () => setIsOpenBackupCode(true);
    const closeBackupCode = () => setIsOpenBackupCode(false);
    const openStopUserFactorConfirm = () => setIsOpenStopUserFactorConfirm(true);
    const closeStopUserFactorConfirm = () => setIsOpenStopUserFactorConfirm(false);

    const disableUserFactor = async currentPassword => {
        try {
            setIsLoading(true);
            const factorStatus = await disableUserFactorApi(currentPassword);
            setUserFactor(factorStatus);
            setIsLoading(false);
            closeStopUserFactorConfirm();
        } catch (error) {
            setIsLoading(false);
            setError(error.originalMessage);
        }
    };

    return (
        <SettingContentLayout name={t('two_factor_authentication')}>
            <Grid container className={classes.root}>
                <Typography variant="body2">{t('2fa_adds_an_additional_layer')}</Typography>
                <Grid container direction="column" className={classes.table}>
                    <Grid
                        item
                        container
                        className={classes.heading}
                        alignItems="center"
                        justify="space-between"
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Typography variant="body1">{t('two_factor_method')}</Typography>
                        </Grid>
                        <Grid item>
                            {isLoading ? (
                                <ButtonBase variant="outlined" width={100} disabled>
                                    <CircularProgress size={24} />
                                </ButtonBase>
                            ) : userFactor.factorEnabled ? (
                                <ButtonBase
                                    variant="outlined"
                                    width={100}
                                    disabled={companyInfo?.setting?.factorRequired}
                                    onClick={openStopUserFactorConfirm}
                                >
                                    {t('global_disable')}
                                </ButtonBase>
                            ) : (
                                <ButtonBase variant="contained" width={100} onClick={goToUserFactorSetting}>
                                    {t('global_enable')}
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" className={classes.row} wrap="nowrap">
                        <Grid item>
                            <Typography variant="body2">{t('authenticator_app')}</Typography>
                        </Grid>
                        <Grid item className={classes.marginLeftAuto}>
                            <Typography className={classes.midGreyText} variant="body2">
                                {userFactor.enabledViaApp ? t('configured') : t('not_configured')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonBase
                                disabled={isLoading || !userFactor.factorEnabled}
                                variant="contained"
                                width={100}
                                onClick={goToUserAppFactorSetting}
                                style={{ marginLeft: 8 }}
                            >
                                {t('edit')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" className={classes.row} wrap="nowrap">
                        <Grid item>
                            <Typography variant="body2">{t('email_address')}</Typography>
                        </Grid>
                        <Grid item className={classes.marginLeftAuto}>
                            <Typography className={classes.midGreyText} variant="body2">
                                {userFactor.enabledViaEmail ? t('configured') : t('not_configured')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ButtonBase
                                disabled={isLoading || !userFactor.factorEnabled}
                                variant="contained"
                                width={100}
                                onClick={goToUserEmailFactorSetting}
                                style={{ marginLeft: 8 }}
                            >
                                {t('edit')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" className={classes.heading}>
                        <Grid item>
                            <Typography variant="body1">{t('recovery_option')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" className={classes.rowWithoutBottomBorder} wrap="nowrap">
                        <Grid item>
                            <Typography variant="body2">{t('backup_code')}</Typography>
                        </Grid>
                        <Grid item className={classes.marginLeftAuto}>
                            <ButtonBase
                                disabled={isLoading || !userFactor.factorEnabled}
                                variant="contained"
                                width={100}
                                onClick={openBackupCode}
                            >
                                {t('show')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={isOpenBackupCode} onClose={closeBackupCode}>
                <ProfileBackupCode onClose={closeBackupCode} />
            </Dialog>
            <Dialog open={isOpenStopUserFactorConfirm} onClose={closeStopUserFactorConfirm}>
                <StopUserFactorConfirm
                    onClose={closeStopUserFactorConfirm}
                    onConfirm={disableUserFactor}
                    isLoading={isLoading}
                    error={error}
                />
            </Dialog>
        </SettingContentLayout>
    );
}

export default ChangePassword;
