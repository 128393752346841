import React from 'react';

function UploadingDoneSVG({ ...other }) {
    return (
        <svg {...other} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99147 20C15.5096 20 19.9829 15.5228 19.9829 10C19.9829 4.47715 15.5096 0 9.99147 0C4.47333 0 0 4.47715 0 10C0 15.5228 4.47333 20 9.99147 20Z"
                fill="#96C060"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5763 6.30872C14.9581 6.62693 15.0097 7.19444 14.6915 7.57629L9.6915 13.5763C9.52962 13.7705 9.29349 13.8877 9.04088 13.8992C8.78828 13.9107 8.5425 13.8153 8.3637 13.6365L5.3637 10.6365C5.01223 10.285 5.01223 9.7152 5.3637 9.36372C5.71517 9.01225 6.28502 9.01225 6.63649 9.36372L8.93962 11.6668L13.3087 6.42395C13.6269 6.04211 14.1944 5.99051 14.5763 6.30872Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(UploadingDoneSVG);
