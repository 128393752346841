import { USER_REALTIME_ACTION } from 'const/gridUI';

export function mapActiveUsersByRowIdColumnId({ users, rows = [] }) {
    const obj = {};

    for (const user of users) {
        if (user.action === USER_REALTIME_ACTION.EDIT || user.action === USER_REALTIME_ACTION.FOCUS) {
            const { columnId, rowId } = user;

            if (columnId !== null && rowId !== null) {
                const rowIndex = rows?.findIndex(rId => rId === rowId);
                if (rowIndex !== -1) {
                    if (!obj[`${rowIndex}.${columnId}`]) {
                        obj[`${rowIndex}.${columnId}`] = [];
                    }
                    obj[`${rowIndex}.${columnId}`] = [
                        ...obj[`${rowIndex}.${columnId}`],
                        { name: user?.user?.name, id: user?.user?.id }
                    ];
                }
            }
        }
    }

    return obj;
}
