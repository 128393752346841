import * as types from "../types";

export function commitAction({actionId, type, body}){
    return {
        type: types.COMMIT_ACTION,
        payload: {
            actionId,
            type,
            body
        }
    }
}

export function removeAction({actionId}){
    return {
        type: types.REMOVE_ACTION,
        payload: {
            actionId
        }
    }
}

export function revertAction({actionId}){
    return {
        type: types.REVERT_ACTION,
        payload: {
            actionId
        }
    }
}