import React from "react";

function DomainEmptySVG({ color = "#8C8C8C", ...other }) {
    return <svg {...other} xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120">
        <defs>
            <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
                <stop offset="0%" stopColor="#E9E9E9"/>
                <stop offset="100%" stopColor="#E1DAE8"/>
            </linearGradient>
            <linearGradient id="b" x1="82.772%" y1="38.542%" y2="65.036%">
                <stop offset="0%" stopColor="#454545" stopOpacity="0"/>
                <stop offset="100%" stopColor="#451AAA" stopOpacity=".6"/>
            </linearGradient>
            <linearGradient id="c" x1="13.364%" x2="0%" y1="36.996%" y2="65.036%">
                <stop offset="0%" stopColor="#A2A2A2" stopOpacity="0"/>
                <stop offset="100%" stopColor="#5C3BA9" stopOpacity=".6"/>
            </linearGradient>
            <linearGradient id="d" x1="50%" x2="50%" y1="-12.346%" y2="100%">
                <stop offset="0%" stopColor="#969696" stopOpacity="0"/>
                <stop offset="100%" stopColor="#5C3BA9" stopOpacity=".6"/>
            </linearGradient>
            <linearGradient id="e" x1="50%" x2="50%" y1="-12.346%" y2="100%">
                <stop offset="0%" stopColor="#969696" stopOpacity="0"/>
                <stop offset="100%" stopColor="#5C3BA9" stopOpacity=".6"/>
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h120v120H0z"/>
            <path fill="url(#a)" d="M110 16v25.898L55 59 0 41.898V16z" transform="translate(5 56)"/>
            <path fill="#D8D8D8" d="M60 56l55 16-55 16L5 72z"/>
            <path fill="#D3D3D3" d="M7 75l24 7.129V91L7 84v-9zm26 8l24 7.129V99l-24-7v-9zM113 75v9l-24 7v-8.871L113 75zm-26 8v9l-24 7v-8.871L87 83z"/>
            <g>
                <path fill="url(#a)" d="M110 16v25.898L55 59 0 41.898V16z" transform="translate(5 22)"/>
                <path fill="#EAEAEA" d="M60 22l55 16-55 16L5 38z"/>
                <path fill="#FFF" d="M60 27l38 11-38 11-38-11z"/>
                <path fill="#5C3BA9" fillOpacity=".1" d="M60 31l24 7-24 7-24-7z" opacity=".4"/>
                <path fill="#D3D3D3" d="M7 41l24 7.129V57L7 50v-9zm26 8l24 7.129V65l-24-7v-9zM113 41v9l-24 7v-8.871L113 41zm-26 8v9l-24 7v-8.871L87 49z"/>
            </g>
            <g>
                <path fill="url(#b)" d="M31 0v17L0 9z" opacity=".6" transform="translate(29 5)"/>
                <path fill="url(#c)" d="M31 0l31 9-31 8z" opacity=".6" transform="translate(29 5)"/>
                <path fill="url(#d)" d="M0 9l7 24 24 7 24-7 7-24-31 8z" opacity=".4" transform="translate(29 5)"/>
                <path fill="url(#e)" d="M31 40l24-7 7-24-31 8z" opacity=".4" transform="translate(29 5)"/>
            </g>
        </g>
    </svg>
}

export default React.memo(DomainEmptySVG);
