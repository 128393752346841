import React from 'react';

function IconUploadFileSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.1844 12.233H23.5922C23.2077 12.233 22.8931 12.5476 22.8931 12.932C22.8931 13.3165 23.2077 13.6311 23.5922 13.6311H29.1844C30.3378 13.6311 31.2815 14.5748 31.2815 15.7282V32.5048C31.2815 33.6582 30.3378 34.6019 29.1844 34.6019H6.81546C5.66206 34.6019 4.71837 33.6582 4.71837 32.5048V15.7282C4.71837 14.5748 5.66206 13.6311 6.81546 13.6311H13.1067C13.4912 13.6311 13.8057 13.3165 13.8057 12.932C13.8057 12.5476 13.4912 12.233 13.1067 12.233H6.81546C4.89313 12.233 3.32031 13.8058 3.32031 15.7282V32.5048C3.32031 34.4272 4.89313 36 6.81546 36H29.1844C31.1067 36 32.6795 34.4272 32.6795 32.5048V15.7282C32.6795 13.8058 31.1067 12.233 29.1844 12.233Z"
                fill="#4D3F8A"
            />
            <path
                d="M18 2.4466C18.3845 2.4466 18.6991 2.13204 18.6991 1.74757V0.699029C18.6991 0.314563 18.3845 0 18 0C17.6156 0 17.301 0.314563 17.301 0.699029V1.74757C17.301 2.13204 17.6156 2.4466 18 2.4466Z"
                fill="#F67B8E"
            />
            <path
                d="M11.3242 18.4893C11.0446 18.7689 11.0446 19.1884 11.3242 19.468L17.5106 25.6544C17.6504 25.7942 17.8252 25.8641 17.9999 25.8641C18.1747 25.8641 18.3495 25.7942 18.4893 25.6544L24.6757 19.468C24.9553 19.1884 24.9553 18.7689 24.6757 18.4893C24.396 18.2097 23.9766 18.2097 23.697 18.4893L18.699 23.4874V4.89321C18.699 4.50874 18.3844 4.19418 17.9999 4.19418C17.6155 4.19418 17.3009 4.50874 17.3009 4.89321V23.4874L12.3029 18.4893C12.0232 18.2097 11.6038 18.2097 11.3242 18.4893Z"
                fill="#F67B8E"
            />
        </svg>
    );
}

export default React.memo(IconUploadFileSVG);
