import React, { useMemo } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import {
    useDependencies,
    useTokenDetection,
    usePredefinedTokens,
    useData,
    useIsShowTag,
    useQuickFilters,
    useIsShowAutoQA,
    useIsTmDisabled,
    useCellMetaData,
    useViewColumnIdsWithReOrder
} from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import { useParams } from 'react-router-dom';
import { generateTags } from 'utils/gridUI/tag';
import Tooltip from 'components/tooltip/Base';
import { removeArrayInArray } from 'utils/object';
import TranslationText from '../../components/TranslationText';
import { capitalize } from 'lodash';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import { Trans, useTranslation } from 'react-i18next';
import TMToolTipPreviewSVG from 'assets/images/svg/localization/TMToolTipPreviewSVG';
// import hexToRgba from 'hex-to-rgba';
import { useFillColorBlindness, useDependencyBlindness } from 'hooks/auth';
import { useIsShareViewLink } from 'hooks/app';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import LQAIconSVG from 'assets/images/svg/LQAIconSVG';
import CommentNotifySVG from 'assets/images/svg/CommentNotifySVG';
import { RESOLVED } from 'const/commentStatus';
import LangStatus from 'components/langStatus';
import { isTempId } from 'utils/uuid';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import classNames from 'classnames';
import hexToRgba from 'hex-to-rgba';
import { getBackgroundStyle } from 'utils/gridUI/cell';

function ViewMode({
    column,
    columnId,
    rowId,
    originalValue,
    data: cellData,
    isReadOnly,
    rowIndex,
    columnIndex,
    height
}) {
    const theme = useTheme();
    const isRtl = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[column?.group]?.rtl;
    }, [column]);
    const fillColorBlindness = useFillColorBlindness();
    const dependencyBlindness = useDependencyBlindness();
    const { t } = useTranslation();
    const isTmDisabled = useIsTmDisabled();
    const isShareViewLink = useIsShareViewLink();

    const type = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const isTranslation = React.useMemo(() => {
        return [columnTypes.TRANSLATION]?.includes(type);
    }, [type]);

    const dependencies = useDependencies();
    const predefinedTokens = usePredefinedTokens();
    const { dbId, branchId } = useParams();
    const tokenDetection = useTokenDetection({ dbId, gridId: branchId });
    const data = useData();
    const isShowTag = useIsShowTag({ dbId, gridId: branchId });
    const quickFilters = useQuickFilters();
    const isShowAutoQA = useIsShowAutoQA();
    const dispatch = useDispatch();
    const viewColumnIds = useViewColumnIdsWithReOrder();

    const cellMetaData = useCellMetaData({ recordId: rowId, columnId });

    const cellThreadId = React.useMemo(() => {
        return cellMetaData?.comment?.id;
    }, [cellMetaData]);

    const cellThreadStatus = React.useMemo(() => {
        return cellMetaData?.comment?.status;
    }, [cellMetaData]);

    const dependenciesFiltered = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const childDependency = React.useMemo(() => {
        return dependenciesFiltered?.find(dpDc => dpDc?.child === columnId);
    }, [dependenciesFiltered, columnId]);

    const isParentDependency = React.useMemo(() => {
        const found = dependenciesFiltered?.find(dpDc => dpDc?.parent === columnId);
        return Boolean(found) && found?.child;
    }, [dependenciesFiltered, columnId]);

    const isHasDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => {
            return dpdc?.child === columnId || dpdc?.parent === columnId;
        });
        return Boolean(dpdc) && (dpdc?.parent || dpdc?.child);
    }, [columnId, dependenciesFiltered]);

    const filterValue = React.useMemo(() => {
        return quickFilters?.[columnId]?.value;
    }, [quickFilters, columnId]);

    const isChildDependency = React.useMemo(() => {
        return Boolean(childDependency) && childDependency?.parent;
    }, [childDependency]);

    const parentText = React.useMemo(() => {
        return data?.[rowId]?.[childDependency?.parent]?.value;
    }, [childDependency, rowId, data]);

    const parentTokens = React.useMemo(() => {
        return !isChildDependency ? [] : generateTags({ tokenDetection, predefinedTokens, text: parentText });
    }, [isChildDependency, tokenDetection, predefinedTokens, parentText]);

    const _tokens = React.useMemo(() => {
        return generateTags({ tokenDetection, predefinedTokens, text: originalValue });
    }, [tokenDetection, predefinedTokens, originalValue]);

    const tagsMissingFromSources = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(parentTokens, _tokens)])];
    }, [parentTokens, _tokens]);

    const customTags = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(_tokens, parentTokens)])];
    }, [parentTokens, _tokens]);

    const warningTags = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => dpdc?.child === columnId);
        const parentId = viewColumnIds?.find(vId => vId === dpdc?.parent);
        const isChildDependency = Boolean(dpdc) && dpdc?.parent && Boolean(parentId);

        return (
            [columnTypes.TRANSLATION]?.includes(type) &&
            Boolean(isChildDependency) &&
            (customTags?.length > 0 || tagsMissingFromSources?.length > 0)
        );
    }, [dependenciesFiltered, viewColumnIds, type, customTags.length, tagsMissingFromSources.length, columnId]);

    const ignoredAutoQAErrors = React.useMemo(() => {
        return (cellData?.metadata?.validations || []).filter(error => error.ignored);
    }, [cellData]);

    const uniqueErrorCategories = React.useMemo(() => {
        return [...new Set(ignoredAutoQAErrors.map(err => err.category))];
    }, [ignoredAutoQAErrors]);

    const tmStatus = React.useMemo(() => {
        return cellData?._tm;
    }, [cellData]);

    const dependencyStatus = React.useMemo(() => {
        return cellData?._dependencyStatus;
    }, [cellData]);

    const sourceStatus = React.useMemo(() => {
        return cellData?._sourceStatus;
    }, [cellData]);

    const isShowDependencyStatus = React.useMemo(() => {
        return isHasDependency;
    }, [isHasDependency]);

    const viewTicketHandler = React.useCallback(() => {
        dispatch(gridUIActions.openViewCellTicket());
    }, [dispatch]);

    const handleOnCommentClick = React.useCallback(() => {
        dispatch(gridUIActions.openCellComment());
    }, [dispatch]);

    const background = useMemo(() => {
        return cellData?._color;
    }, [cellData]);

    const isNotTranslation = useMemo(() => {
        return type !== columnTypes.TRANSLATION;
    }, [type]);

    React.useEffect(() => {
        if (!isTranslation) return;
        const $cell = document.getElementsByClassName(`cell_${rowIndex}_${columnIndex}`)?.[0];

        if ($cell) {
            $cell.style.opacity = 0;
        }
        return () => {
            if ($cell) {
                $cell.style.opacity = 1;
            }
        };
    }, [rowIndex, columnIndex, isTranslation]);

    if (![columnTypes.TRANSLATION, columnTypes.MULTIPLE_LINES, columnTypes.SINGLE_LINE]?.includes(type)) return null;

    return (
        <Grid
            container
            className={classNames('w-full cell h-full relative -z-[1] overflow-hidden', {
                'p-0': type === columnTypes.FILES,
                'py-2 px-3.5': type !== columnTypes.FILES
            })}
            style={{
                height: height - 2,
                background: isNotTranslation
                    ? ''
                    : getBackgroundStyle({
                          background,
                          fillColorBlindness,
                          isCellCopyHighlight: false,
                          isReadOnly,
                          isSelectedByRowOrColumn: false,
                          theme
                      })
            }}
        >
            <Grid className={'w-full'} dir={isRtl ? 'rtl' : 'ltr'}>
                {[columnTypes.TRANSLATION]?.includes(type) ? (
                    <TranslationText
                        customProperties={column?.customProperties || {}}
                        value={originalValue}
                        rowHeight={height}
                        filterValue={filterValue}
                        rowId={rowId}
                        group={column?.group}
                        columnId={columnId}
                        tokenDetection={tokenDetection}
                        isShowTag={isShowTag}
                        predefinedTokens={predefinedTokens}
                        parentTokens={parentTokens}
                        targetValidations={cellData?.metadata?.targetValidations}
                        validations={cellData?.metadata?.validations}
                        isChildDependency={isChildDependency}
                        isReadOnly={isReadOnly}
                        isViewMode={false}
                    />
                ) : null}
            </Grid>
            {!isNotTranslation && (
                <div
                    className={'absolute w-full h-full pointer-events-none top-0 left-0'}
                    style={{
                        background: hexToRgba(theme.colors.highlight, 0.15)
                    }}
                />
            )}

            {isShowAutoQA && ignoredAutoQAErrors.length > 0 && (
                <Tooltip
                    title={
                        <Grid container direction="column" spacing={1}>
                            <p className="text-light font-medium">You ignored some errors:</p>
                            <ul className={'m-0 pl-6'}>
                                {uniqueErrorCategories.map(category => (
                                    <li key={category}>
                                        <p className="body2 font-normal text-white">{capitalize(category)}</p>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    }
                >
                    <div
                        className={'absolute bottom-0 left-0 w-0 h-0 inline-block'}
                        style={{
                            borderRight: `12px solid transparent`,
                            borderBottom: `12px solid ${theme.colors.missingTag}`
                        }}
                        dir="ltr"
                    ></div>
                </Tooltip>
            )}

            {!isShowAutoQA && isShowTag && warningTags && (
                <Tooltip
                    title={
                        <Grid container direction="column" spacing={2}>
                            {tagsMissingFromSources?.length > 0 && (
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <p className="text-light font-medium">Missing Tag from source:</p>
                                    </Grid>
                                    <Grid item container direction="column" spacing={1}>
                                        {tagsMissingFromSources?.map(tag => {
                                            return (
                                                <Grid item key={tag}>
                                                    <p className="body2 font-normal text-white">• {tag}</p>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                            {customTags?.length > 0 && (
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <p className="text-light font-medium">Tags not found in source:</p>
                                    </Grid>
                                    <Grid item container direction="column" spacing={1}>
                                        {customTags?.map(tag => {
                                            return (
                                                <Grid item key={tag}>
                                                    <p className="body2 font-normal text-white">• {tag}</p>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    }
                >
                    <div
                        className={'absolute bottom-0 left-0 w-0 h-0 inline-block'}
                        style={{
                            borderRight: `12px solid transparent`,
                            borderBottom: `12px solid ${theme.colors.missingTag}`
                        }}
                        dir="ltr"
                    />
                </Tooltip>
            )}

            {tmStatus && !isTmDisabled && (
                <Tooltip
                    placement="right"
                    title={
                        <Grid
                            container
                            spacing={1}
                            alignItems="flex-start"
                            direction="column"
                            className={'!w-[270px] p-2'}
                        >
                            <Grid item>
                                <Grid>
                                    <TMToolTipPreviewSVG />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <p className="body1 text-white">TM Text</p>
                            </Grid>
                            <Grid item>
                                <Trans i18nKey="tm_tooltip_helper_description" t={t}>
                                    <p className="caption">This text is auto-populated from the TM</p>
                                    <p className="caption">⮕ Edit cell or right click to hide this icon</p>
                                    <p className="caption">
                                        ⮕ Go to Home/Localization Settings to config the Translation Memory
                                    </p>
                                </Trans>
                            </Grid>
                        </Grid>
                    }
                >
                    <span
                        className={
                            'absolute bottom-0 right-0 text-[7px] w-[14px] h-[9px] font-bold uppercase text-white flex justify-center items-center cursor-pointer bg-[#7869B9]'
                        }
                    >
                        TM
                    </span>
                </Tooltip>
            )}

            {isShowDependencyStatus && (
                <div className={'absolute top-1 left-1'}>
                    <LangStatus
                        dependencyBlindness={dependencyBlindness}
                        dependencyStatus={dependencyStatus}
                        sourceStatus={isChildDependency && !isParentDependency ? null : sourceStatus}
                    />
                </div>
            )}

            {!isShareViewLink && (
                <div className={'absolute top-0 left-0 w-full flex justify-end items-start py-0 px-2 z-[2]'} dir="ltr">
                    {cellThreadId && cellThreadStatus !== RESOLVED && (
                        <span className={'w-4 h-4'} onClick={handleOnCommentClick}>
                            <CommentNotifySVG width="14" height="14" />
                        </span>
                    )}
                    {cellData?._ticket > 0 && (
                        <span className={'w-3 h-3 pr-2'} onClick={viewTicketHandler}>
                            <LQAIconSVG width="14" height="14" />
                        </span>
                    )}
                </div>
            )}
        </Grid>
    );
}

export default ViewMode;
