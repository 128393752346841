import React from 'react';

function ColorCloseCircleSVG({ color = '#EB4F5E', ...rest }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <circle cx="10" cy="10" r="10" fill={color} fillOpacity="0.8" />
            <path
                d="M13.5 7.2047L10.7047 10L13.5 12.7953L12.7953 13.5L10 10.7047L7.2047 13.5L6.5 12.7953L9.2953 10L6.5 7.2047L7.2047 6.5L10 9.2953L12.7953 6.5L13.5 7.2047Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(ColorCloseCircleSVG);
