import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Link, useTheme } from '@material-ui/core';
import Button from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import InputText from 'components/inputs/InputText';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import Divider from 'components/divider/Base';
import IOSSwitch from 'components/switches/IOS';
import LDBaseCardInfo from 'components/selects/LDBaseCardInfo';
import AvatarBase from 'components/avatar/Base';
import { useMemberList } from 'hooks/permission';
import { getAvatarUrl } from 'utils/images';
import CreatableSelect from 'react-select/creatable';

const useStyles = makeStyles(theme => ({
    root: {},
    background: {
        borderRadius: 4,
        padding: theme.spacing(3),
        background: theme.colors.paleGrey
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    error: {
        color: theme.colors.burntSlena
    }
}));

function SAMLSetting({ samlConfig, onClose, onSave, isLoading }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const memberList = useMemberList();
    const [url, setUrl] = React.useState(samlConfig.samlIdpMetaUrl || '');
    const [excludedMemberIds, setExcludedMemberIds] = React.useState(
        (samlConfig?.excludedUserIds || []).map(id => id.toString())
    );
    const [enforceSso, setEnforceSso] = React.useState(samlConfig?.enforceSso);
    const [inputValue, setInputValue] = React.useState('');
    const [domains, setDomains] = React.useState(samlConfig?.includedDomains || []);

    const domainOptions = domains.map(domain => ({
        label: domain,
        value: domain
    }));

    const isAllDomainsValid = domains.every(domain => {
        return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(domain);
    });

    const handleKeyDown = event => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case ' ':
                setInputValue('');
                setDomains([...domains, inputValue]);
                event.preventDefault();
                break;
            default:
        }
    };

    const handleChange = (options, actionMeta) => {
        console.group('Value Changed');
        console.log(options);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        const domains = (options || []).map(o => o.value);
        setDomains(domains || []);
    };

    const memberOptions = memberList
        ?.filter(member => !excludedMemberIds.includes(member.id))
        ?.map(member => ({
            ...member,
            value: member?.email,
            label: member?.fullName,
            icon: () => <AvatarBase src={getAvatarUrl(member?.imageUrl)} size="small" alt="avatar" />,
            subTitle: member?.email
        }));

    const selectedMemberOptions = memberList
        ?.filter(member => excludedMemberIds.includes(member.id))
        ?.map(member => ({
            ...member,
            value: member?.email,
            label: member?.fullName,
            icon: () => <AvatarBase src={getAvatarUrl(member?.imageUrl)} size="small" alt="avatar" />,
            subTitle: member?.email
        }));

    const handleMemberOptionsChange = options => {
        const memberIds = options.map(o => o.id);
        setExcludedMemberIds(memberIds);
    };

    const handleCopyToClipboard = stringToCopy => {
        navigator.clipboard.writeText(stringToCopy);
        dispatch(
            enqueueSnackbar({
                message: t(`global_copied`),
                type: 'info'
            })
        );
    };

    const handleSubmit = e => {
        e.preventDefault();
        onSave({ url, enforceSso, excludedUserIds: excludedMemberIds, includedDomains: domains });
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle title={t('saml_settings')} onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" className={classes.root}>
                    <Grid item style={{ marginBottom: 14 }}>
                        <Typography variant="body2">{t('add_acs_url')}</Typography>
                    </Grid>
                    <Grid item container direction="column" className={classes.background}>
                        <Grid item style={{ marginBottom: 8 }}>
                            <Typography variant="body1">{t('acs_url')}</Typography>
                        </Grid>
                        <Grid item container alignItems="center" spacing={3}>
                            <Grid item style={{ flex: 1 }}>
                                <InputText
                                    disabled
                                    value={samlConfig.samlSSOUrl}
                                    height={40}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item>
                                <Link
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCopyToClipboard(samlConfig.samlSSOUrl)}
                                >
                                    {t('copy_link')}
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: 14, marginBottom: 8 }}>
                            <Typography variant="body1">{t('sp_metadata_url')}</Typography>
                        </Grid>
                        <Grid item container alignItems="center" spacing={3}>
                            <Grid item style={{ flex: 1 }}>
                                <InputText disabled value={samlConfig.samlSpMetaUrl} height={40} />
                            </Grid>
                            <Grid item>
                                <Link
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCopyToClipboard(samlConfig.samlSpMetaUrl)}
                                >
                                    {t('copy_link')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.divider}>
                        <Divider />
                    </Grid>
                    <Grid item style={{ marginBottom: 14 }}>
                        <Typography variant="body2">{t('enter_the_idp')}</Typography>
                    </Grid>
                    <Grid item container direction="column" className={classes.background}>
                        <Grid item style={{ marginBottom: 8 }}>
                            <Typography variant="body1">{t('idp_metadata_url')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText
                                disabled={isLoading}
                                name="url"
                                value={url}
                                height={40}
                                onChange={e => setUrl(e.target.value)}
                                placeholder={t('idp_metadata_url')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.divider}>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('require_sso_for_all_users')}</Typography>
                    </Grid>
                    <Grid item container alignItems="center" justifyContent="space-between" className={classes.mt3}>
                        <Grid item>
                            <Typography variant="body1">{t('require_sso')}</Typography>
                        </Grid>
                        <Grid item>
                            <IOSSwitch
                                // disabled={isUpdatingColumnsPermission || isReadOnly}
                                onChange={e => setEnforceSso(e.target.checked)}
                                checked={enforceSso}
                                color={theme.palette.primary.main}
                            />
                        </Grid>
                    </Grid>
                    {enforceSso && (
                        <>
                            <Grid item className={classes.mt3}>
                                <Typography variant="body1">{t('list_of_domain_required')}</Typography>
                            </Grid>
                            <Grid item className={classes.mt3}>
                                <Typography variant="body2">{t('specify_email_domains')}</Typography>
                            </Grid>
                            <Grid item className={classes.mt3}>
                                <CreatableSelect
                                    components={{
                                        DropdownIndicator: null
                                    }}
                                    inputValue={inputValue}
                                    isClearable
                                    isMulti
                                    menuIsOpen={false}
                                    onChange={handleChange}
                                    onInputChange={setInputValue}
                                    onKeyDown={handleKeyDown}
                                    placeholder="example.com demo.org"
                                    value={domainOptions}
                                />
                            </Grid>
                            {!isAllDomainsValid && (
                                <Grid item>
                                    <Typography variant="body1" className={classes.error}>
                                        {t('contains_invalid_domain_name')}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item className={classes.mt3}>
                                <Typography variant="body1">{t('exclude_users_from_sso_requirement')}</Typography>
                            </Grid>
                            <Grid item className={classes.mt3}>
                                <LDBaseCardInfo
                                    ddPlaceholder="Choose members"
                                    menuPlaceholder={t('find_group_member_placeholder')}
                                    options={memberOptions}
                                    onChange={handleMemberOptionsChange}
                                    defaultValue={selectedMemberOptions}
                                    isMulti
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={100} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isLoading || !url || !isAllDomainsValid}
                            variant="contained"
                            width={100}
                            type="submit"
                        >
                            {isLoading ? <CircularProgress size={24} /> : t('global_save')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default React.memo(SAMLSetting);
