import React from 'react';

function ExcelSVG({ color = '#FFFFFF', ...other }) {
    return (
        <svg {...other} width="37" height="43" viewBox="0 0 37 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 28">
                <g id="Group 27">
                    <g id="Group 3">
                        <mask
                            id="mask0"
                            mask-type="alpha"
                            maskUnits="userSpaceOnUse"
                            x="3"
                            y="0"
                            width="34"
                            height="43"
                        >
                            <path
                                id="Clip 2"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.41406 0.661133H36.8744V42.8072H3.41406V0.661133Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0)">
                            <path
                                id="Fill 1"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.70142 0.780807C3.98683 0.784774 3.41005 1.35802 3.41408 2.06151L3.64063 41.5413C3.64466 42.2448 4.22749 42.8114 4.94208 42.8074L35.5866 42.6375C36.3026 42.6335 36.8787 42.0603 36.8746 41.3568L36.6998 10.8321L26.2419 0.661133L4.70142 0.780807Z"
                                fill="#EAF5DD"
                            />
                        </g>
                    </g>
                    <g id="Group 6">
                        <mask
                            id="mask1"
                            mask-type="alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="8"
                            width="25"
                            height="12"
                        >
                            <path
                                id="Clip 5"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 8.74707H24.6514V19.4847H0V8.74707Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask1)">
                            <path
                                id="Fill 4"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22.6463 19.3597L0.0605012 19.4847L0 8.87207L22.5851 8.74711C23.699 8.7405 24.6072 9.62383 24.6139 10.7194L24.6516 17.3649C24.6576 18.4605 23.7602 19.3538 22.6463 19.3597Z"
                                fill="#83B148"
                            />
                        </g>
                    </g>
                    <path
                        id="Fill 7"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.4477 25.6901L10.0248 25.7979C9.54817 25.8005 9.15895 25.4223 9.15626 24.9536C9.15424 24.4848 9.53876 24.1026 10.0154 24.1L29.4376 23.9922C29.9142 23.9896 30.3028 24.3678 30.3055 24.8359C30.3082 25.3053 29.9243 25.6875 29.4477 25.6901Z"
                        fill="#ADCD83"
                    />
                    <path
                        id="Fill 9"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.4685 29.5104L10.0456 29.6182C9.56898 29.6208 9.18043 29.2426 9.17774 28.7739C9.17573 28.3051 9.55957 27.9229 10.0362 27.9203L29.4584 27.8125C29.935 27.8099 30.3236 28.1881 30.3263 28.6568C30.3296 29.1256 29.9451 29.5078 29.4685 29.5104Z"
                        fill="#ADCD83"
                    />
                    <path
                        id="Fill 11"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.49 33.3317L10.0671 33.4395C9.59047 33.4421 9.20192 33.0639 9.19923 32.5952C9.19721 32.1264 9.58106 31.7442 10.0583 31.7416L29.4806 31.6338C29.9572 31.6312 30.3458 32.0094 30.3484 32.4775C30.3511 32.9462 29.9673 33.3291 29.49 33.3317Z"
                        fill="#ADCD83"
                    />
                    <path
                        id="Fill 13"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.5122 37.152L10.0892 37.2598C9.61262 37.2624 9.22407 36.8842 9.22071 36.4155C9.21869 35.9467 9.60321 35.5645 10.0798 35.5619L29.5021 35.4541C29.9787 35.4515 30.3672 35.8297 30.3699 36.2978C30.3726 36.7672 29.9888 37.1494 29.5122 37.152Z"
                        fill="#ADCD83"
                    />
                    <path
                        id="Fill 15"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.2422 0.661133L26.2933 9.6155C26.2973 10.319 26.8801 10.8863 27.5954 10.8823L36.7002 10.8321L26.2422 0.661133Z"
                        fill="#ADCD83"
                    />
                    <path
                        id="Fill 17"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.0605469 19.485L3.51382 19.4658L3.53332 22.8616L0.0605469 19.485Z"
                        fill="#96C060"
                    />
                    <path
                        id="Fill 19"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.41354 14.555L5.55615 14.5457L5.54674 16.4175L4.39453 16.4122L4.41873 11.7119L7.61656 11.7278L7.61118 12.6032L5.56624 12.5926L5.56019 13.6736L7.41825 13.6836L7.41354 14.555Z"
                        fill="white"
                    />
                    <path
                        id="Fill 21"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.2413 16.441L9.0918 16.4357L9.11667 11.7354L10.2655 11.7406L10.2413 16.441Z"
                        fill="white"
                    />
                    <path
                        id="Fill 23"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.1374 15.5848L15.142 15.5954L15.1386 16.4669L11.9805 16.4503L12.0053 11.75L13.1576 11.756L13.1374 15.5848Z"
                        fill="white"
                    />
                    <path
                        id="Fill 25"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.5404 14.4932L17.7294 14.484L17.7233 15.6073L19.8624 15.6179L19.859 16.4893L16.5664 16.4735L16.5906 11.7725L19.8899 11.7897L19.8846 12.6644L17.7374 12.6538L17.7334 13.6443L19.5444 13.6535L19.5404 14.4932Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(ExcelSVG);
