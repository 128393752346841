import React from 'react';

function DeleteAvailableRecordSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M11.7 10.5H15.3V12.25H11.7V10.5ZM11.7 3.5H18V5.25H11.7V3.5ZM11.7 7H17.1V8.75H11.7V7ZM0.9 12.25C0.9 13.2125 1.71 14 2.7 14H8.1C9.09 14 9.9 13.2125 9.9 12.25V3.5H0.9V12.25ZM2.25 4.8125H5.4H8.55V12.6875H2.25V4.8125ZM7.2 0H3.6L2.7 0.875H0V2.625H10.8V0.875H8.1L7.2 0Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(DeleteAvailableRecordSVG);
