import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import CardInfo from 'components/cardInfo/Base';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { ALL_LANGUAGES, DEFAULT_ROLE_ADMIN } from 'const';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import { useTranslation } from 'react-i18next';
import LanguagePair from 'components/tutorial/LanguagePairTutorial';
import Tooltip from 'components/tooltip/Base';
import DefaultSystemItemSVG from 'assets/images/svg/DefaultSystemItemSVG';
import hexToRgba from 'hex-to-rgba';
import AvatarBase from 'components/avatar/Base';
import { useMemberMappingByEmail } from 'hooks/permission';
import { getAvatarUrl } from 'utils/images';
import Avatar from 'components/avatar/User';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';

const useStyles = makeStyles(theme => ({
    table: {
        border: '1px solid #e9eaef',
        borderRadius: 4,
        overflow: 'hidden',
        color: theme.colors.primaryText,
        '& .header-role': {
            marginLeft: 15
        },
        '& .cell-role': {
            paddingLeft: 15,

            '& .select': {
                marginLeft: -15
            }
        }
        // '& .ReactVirtualized__Table__row': {
        //     background: hexToRgba(theme.colors.brightRed, 0.1)
        // }
    },
    nowrap: {
        flexWrap: 'nowrap'
    },
    roleSelect: {
        width: '100%'
    },
    moreActionsButton: {
        height: 40,
        width: 40,
        borderRadius: '100%',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.paleNavy
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    arrow: {
        margin: theme.spacing(0, 2)
    },
    titleError: {
        color: theme.colors.brightRed
    }
}));

function BulkInviteTable({ members = [], noStyle, className }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [sorting, setSorting] = useState({});
    const membersMappingByEmail = useMemberMappingByEmail();

    const data = useMemo(() => {
        const cloneData = [...members].sort(
            (a, b) => new Date(b?.audit?.createdDate) - new Date(a?.audit?.createdDate)
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                if (sortKey === 'role') {
                    valueA = valueA ? valueA.name : '';
                    valueB = valueB ? valueB.name : '';
                }

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, members]);

    const nameRenderer = ({ rowData }) => {
        const { email } = rowData;
        const member = membersMappingByEmail?.[email];

        return (
            <Tooltip
                title={
                    member ? (
                        <Grid container wrap="nowrap" direction="column" spacing={2}>
                            <Grid item>{t('invite_member_input_email_invited')}</Grid>
                            {member && (
                                <Grid item>
                                    <Grid container wrap="nowrap" spacing={2} alignItems="center">
                                        <Grid item>
                                            <Avatar src={getAvatarUrl(member.id)} size={24} color="#DBDCE1">
                                                <AvatarIconSVG />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>{member.fullName}</Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        ''
                    )
                }
            >
                <CardInfo
                    avatar={<AvatarBase src={getAvatarUrl(member?.id)} size="small" alt="avatar" />}
                    title={email}
                    titleClassName={member ? classes.titleError : ''}
                />
            </Tooltip>
        );
    };

    const roleRenderer = ({ rowData }) => {
        const { companyRole } = rowData;
        const isAdmin = companyRole === DEFAULT_ROLE_ADMIN?.name;

        return (
            <Grid container spacing={2} wrap="nowrap" alignItems="center">
                {companyRole && (
                    <Grid item style={{ display: 'flex' }}>
                        <DefaultSystemItemSVG
                            color={hexToRgba(isAdmin ? theme.colors.dodgerBlue : theme.colors.disabledGrey, 0.8)}
                            style={{ width: 12, height: 12 }}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Typography variant="body2">{companyRole}</Typography>
                </Grid>
            </Grid>
        );
    };

    const LanguageRenderer = ({ rowData }) => {
        const { languagePairs } = rowData;
        const { t } = useTranslation();

        return (
            <Grid container direction="column" spacing={3}>
                {languagePairs?.map(({ from, to }, index) => (
                    <Grid item xs={6} key={index}>
                        <Grid container wrap="nowrap">
                            {from !== ALL_LANGUAGES && (
                                <Grid item>
                                    <CountryFlag
                                        languageCountryCode={from}
                                        style={{ marginRight: 4, width: 30, height: 'auto', borderRadius: 3 }}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant="body2" display="inline">
                                    {from === ALL_LANGUAGES ? t('global_all') : from}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.arrow}>
                                <PathSVG />
                            </Grid>
                            {to !== ALL_LANGUAGES && (
                                <Grid item>
                                    <CountryFlag
                                        languageCountryCode={to}
                                        style={{ marginRight: 4, width: 30, height: 'auto', borderRadius: 3 }}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant="body2" display="inline">
                                    {to === ALL_LANGUAGES ? t('global_all') : to}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const groupRenderer = ({ rowData }) => {
        const { groups } = rowData;

        return (
            <Grid container direction="column" alignItems="flex-start" spacing={2}>
                {groups?.map((group, index) => (
                    <Grid item key={index}>
                        <Typography variant="body2">{group}</Typography>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const defaultProjectRoleRenderer = ({ rowData }) => {
        const { defaultProjectRole } = rowData;

        return (
            <Grid container direction="row" justify="flex-start" alignItems="center">
                <Typography variant="body2">{defaultProjectRole}</Typography>
            </Grid>
        );
    };

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const COLUMNS = [
        { label: t('global_email'), dataKey: 'email', width: 350, sort: true, cellRenderer: nameRenderer },
        { label: t('company_role'), dataKey: 'role', width: 180, flexGrow: 1, sort: true, cellRenderer: roleRenderer },
        {
            label: (
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item>{t('global_language_pairs')}</Grid>
                    <Tooltip title={<LanguagePair />}>
                        <Grid item style={{ display: 'flex' }}>
                            <InfoIconSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
            ),
            dataKey: 'language',
            width: 300,
            cellRenderer: props => <LanguageRenderer {...props} />
        },
        {
            label: t('global_groups'),
            dataKey: 'group',
            width: 200,
            flexGrow: 0,
            sort: true,
            cellRenderer: groupRenderer
        },
        {
            label: t('default_project_role'),
            dataKey: 'defaultProjectRole',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: defaultProjectRoleRenderer
        }
    ];

    return (
        <div className={`${classes.table} ${className}`} style={noStyle ? {} : { height: '55vh', width: '100%' }}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={data}
                        columns={COLUMNS}
                        width={width}
                        height={height}
                        onRowClick={handleRowClick}
                        onSort={handleOnSort}
                    ></Table>
                )}
            </AutoSizer>
        </div>
    );
}

export default React.memo(BulkInviteTable);
