import React from 'react';

function ShareLinkViewIlluSVG({ color = 'white', ...other }) {
    return (
        <svg width="147" height="133" viewBox="0 0 147 133" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M112.274 112.423H34.3028C30.3298 112.423 27.1094 109.202 27.1094 105.23V58.381C27.1094 53.6358 30.9562 49.7891 35.7013 49.7891H112.273C117.018 49.7891 120.865 53.6358 120.865 58.381V103.832C120.865 108.577 117.018 112.423 112.274 112.423Z"
                fill="#C3BCE4"
            />
            <path d="M86.3475 129.985H61.6289L65.7833 112.422H82.1931L86.3475 129.985Z" fill="#6E5DB8" />
            <path
                d="M27.1094 97.3125V105.233C27.1094 109.206 30.3301 112.427 34.3028 112.427H113.672C117.644 112.427 120.865 109.206 120.865 105.233V97.3125H27.1094Z"
                fill="#7869B9"
            />
            <path
                d="M94.1301 127.961H53.8459C52.7292 127.961 51.8242 128.866 51.8242 129.983C51.8242 131.099 52.7292 132.004 53.8459 132.004H94.1298C95.2465 132.004 96.1515 131.1 96.1515 129.983C96.1515 128.866 95.2467 127.961 94.1301 127.961Z"
                fill="#5E4CAC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82 66C80.8954 66 80 66.8954 80 68C80 69.1046 80.8954 70 82 70C83.1046 70 84 69.1046 84 68C84 66.8954 83.1046 66 82 66ZM78 68C78 65.7909 79.7909 64 82 64C84.2091 64 86 65.7909 86 68C86 70.2091 84.2091 72 82 72C80.8108 72 79.7427 71.481 79.01 70.6572L71.849 74.9075C71.9474 75.2548 72 75.6212 72 76C72 76.3737 71.9487 76.7355 71.8529 77.0787L78.593 80.9031C79.2974 79.7611 80.5598 79 82 79C84.2091 79 86 80.7909 86 83C86 85.2091 84.2091 87 82 87C79.7909 87 78 85.2091 78 83C78 82.9556 78.0007 82.9114 78.0022 82.8674L70.8484 78.8083C70.1231 79.544 69.1148 80 68 80C65.7909 80 64 78.2091 64 76C64 73.7909 65.7909 72 68 72C69.1096 72 70.1138 72.4518 70.8384 73.1816L78.0958 68.8741C78.0331 68.5927 78 68.3002 78 68ZM69.6947 74.9374C69.3409 74.3743 68.7141 74 68 74C66.8954 74 66 74.8954 66 76C66 77.1046 66.8954 78 68 78C68.722 78 69.3546 77.6175 69.7062 77.044C69.7108 77.0354 69.7154 77.0269 69.7203 77.0184C69.7294 77.0023 69.739 76.9865 69.7489 76.9711C69.9089 76.6835 70 76.3524 70 76C70 75.6568 69.9135 75.3337 69.7612 75.0515C69.7504 75.0355 69.74 75.0191 69.7301 75.0023C69.7174 74.9809 69.7056 74.9593 69.6947 74.9374ZM80 83C80 81.8954 80.8954 81 82 81C83.1046 81 84 81.8954 84 83C84 84.1046 83.1046 85 82 85C80.8954 85 80 84.1046 80 83Z"
                fill="white"
            />
            <path
                d="M0 33.8936V60.5616C0 64.1597 2.91683 67.0763 6.51472 67.0763H25.1032L34.8922 76.0621V67.0763H40.4916C44.0898 67.0763 47.0066 64.1595 47.0066 60.5616V33.8936C47.0066 30.2955 44.0898 27.3789 40.4916 27.3789H6.51472C2.91683 27.3786 0 30.2955 0 33.8936Z"
                fill="white"
            />
            <path
                d="M0 33.8936V60.5616C0 64.1597 2.91683 67.0763 6.51472 67.0763H25.1032L34.8922 76.0621V67.0763H40.4916C44.0898 67.0763 47.0066 64.1595 47.0066 60.5616V33.8936C47.0066 30.2955 44.0898 27.3789 40.4916 27.3789H6.51472C2.91683 27.3786 0 30.2955 0 33.8936Z"
                fill="#FBCC6B"
            />
            <path
                d="M23.5024 35.6992L27.1266 43.0376L35.2311 44.2144L29.3666 49.9264L30.751 57.9921L23.5024 54.1841L16.2535 57.9921L17.6379 49.9264L11.7734 44.2144L19.8779 43.0376L23.5024 35.6992Z"
                fill="white"
            />
            <path
                d="M146.007 46.3763V72.4774C146.007 75.9991 143.153 78.8538 139.631 78.8538H121.438L111.857 87.6486V78.8538H106.376C102.855 78.8538 100 75.9991 100 72.4774V46.3763C100 42.8547 102.855 40 106.376 40H139.631C143.153 40 146.007 42.8549 146.007 46.3763Z"
                fill="white"
            />
            <path
                d="M146.007 46.3763V72.4774C146.007 75.9991 143.153 78.8538 139.631 78.8538H121.438L111.857 87.6486V78.8538H106.376C102.855 78.8538 100 75.9991 100 72.4774V46.3763C100 42.8547 102.855 40 106.376 40H139.631C143.153 40 146.007 42.8549 146.007 46.3763Z"
                fill="#F67B8E"
                fillOpacity="0.8"
            />
            <path
                d="M123.01 53.0558C124.622 50.1395 128.561 48.9909 131.416 50.8004C133.929 52.3928 134.666 55.7705 134.005 58.5207C132.679 64.0423 127.924 67.8715 123.01 70.1134C118.096 67.8715 113.341 64.0423 112.015 58.5207C111.352 55.7628 112.095 52.4023 114.604 50.8004C117.454 48.9808 121.402 50.1469 123.01 53.0558Z"
                fill="white"
            />
            <path
                d="M40.0352 6.41856V32.6923C40.0352 36.2371 42.909 39.1109 46.4537 39.1109H64.6118L74.2561 47.9639V39.1106H79.928C83.4728 39.1106 86.3466 36.2368 86.3466 32.6921V6.41856C86.3466 2.8738 83.4728 0 79.928 0H46.4537C42.9087 0 40.0352 2.8738 40.0352 6.41856Z"
                fill="white"
            />
            <path
                d="M40.0352 6.41856V32.6923C40.0352 36.2371 42.909 39.1109 46.4537 39.1109H64.6118L74.2561 47.9639V39.1106H79.928C83.4728 39.1106 86.3466 36.2368 86.3466 32.6921V6.41856C86.3466 2.8738 83.4728 0 79.928 0H46.4537C42.9087 0 40.0352 2.8738 40.0352 6.41856Z"
                fill="#9CE1EF"
            />
            <path
                d="M54.1394 31.2659C51.974 31.2659 50.2188 29.5107 50.2188 27.3453V18.3034C50.2188 16.1381 51.974 14.3828 54.1394 14.3828C56.3047 14.3828 58.06 16.1381 58.06 18.3034V27.3453C58.06 29.5107 56.3045 31.2659 54.1394 31.2659Z"
                fill="#58B2FF"
            />
            <path
                d="M75.9508 17.8336L75.9593 17.8004C76.2627 16.6159 75.3651 15.4647 74.1425 15.4695C73.688 15.4714 73.2035 15.4727 72.7211 15.4732C70.6577 15.4754 67.3964 15.4775 65.9891 15.4775V8.88626C65.9891 7.22398 63.6949 6.62818 62.8771 8.08142L58.4658 17.1007C58.1875 17.6697 58.043 18.2947 58.043 18.9279V25.8435C58.043 27.0117 58.5341 28.126 59.3966 28.9139C60.4912 29.9139 61.9243 30.4691 63.4118 30.4691C66.3671 30.4691 69.0125 30.4571 71.2623 30.4651C72.1179 30.468 72.864 29.8898 73.0763 29.0607L73.0818 29.039C73.3844 27.858 75.7394 18.6599 75.9508 17.8336Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(ShareLinkViewIlluSVG);
