const TYPES = {
    A: 'link',
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    STRIKETHROUGH: 'strikethrough',
    BLOCKQUOTE: 'block-quote',
    H1: 'heading-one',
    H2: 'heading-two',
    H3: 'heading-three',
    H4: 'heading-four',
    H5: 'heading-five',
    H6: 'heading-six',
    UL: 'bulleted-list',
    OL: 'numbered-list',
    LI: 'list-item',
    IMG: 'image',
    LINK: 'link',
    P: 'paragraph',
    CODE: 'code',
    BR: 'horizontal-rule'
};

export default TYPES;
