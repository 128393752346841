import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import Button from 'components/button/Base';
import { getDateUTC } from 'utils/datetime';
import { useTranslation, Trans } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useDispatch } from 'react-redux';
import { getAffectedReferencesApi } from 'services/grid';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notifier/actions';
import InfoContainedIconSVG from 'assets/images/svg/InfoContainedIconSVG';
import ReferenceSVG from 'components/svg-icon/ReferenceSVG';
import OverflowTypography from 'components/textTruncated/OverflowTypography';

const useStyles = makeStyles(theme => ({
    flx: { flex: 1 },
    loadingWrapper: {
        position: 'relative',
        height: 41
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    icon: {
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    listAffectedColumns: {
        marginTop: 12,
        maxHeight: 180,
        overflow: 'hidden auto'
    },
    affectedColumn: {
        padding: '10px 8px',
        borderRadius: 4,
        background: theme.colors.paleGrey,
        '& svg': {
            verticalAlign: 'text-top'
        },
        '&:not(:first-child)': {
            marginTop: 12
        }
    }
}));

function RestoreConfirmModal({ backupFile, onClose, onAccept }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dbId, branchId } = useParams();
    const [fetchingAffectedReferences, setFetchingAffectedReferences] = useState(
        backupFile?.shouldCheckAffectedReference
    );
    const [affectedReferences, setAffectedReferences] = useState([]);
    const unmounted = useRef(false);

    useEffect(() => {
        if (backupFile?.shouldCheckAffectedReference) {
            const fetch = async () => {
                try {
                    const responseData = await getAffectedReferencesApi({
                        dbId,
                        gridId: branchId,
                        backupId: backupFile.id
                    });
                    if (!unmounted.current) {
                        setFetchingAffectedReferences(false);
                        setAffectedReferences(responseData);
                    }
                } catch (error) {
                    onClose();
                    dispatch(
                        enqueueSnackbar({
                            message: error?.message,
                            type: 'error'
                        })
                    );
                }
            };
            fetch();
        }
        return () => {
            unmounted.current = true;
        };
    }, [backupFile, dbId, dispatch, branchId, onClose]);

    return (
        <>
            <DialogTitle title={t(`restore_grid`)} onClose={onClose} />
            <DialogContent>
                {fetchingAffectedReferences ? (
                    <Grid item className={classes.loadingWrapper}>
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    </Grid>
                ) : affectedReferences?.length > 0 ? (
                    <Grid item>
                        <span className={classes.icon}>
                            <InfoContainedIconSVG bgColor="#F6BE42" />
                            &nbsp;
                        </span>
                        <Trans
                            t={t}
                            i18nKey={'restore_affected_references_description'}
                            components={{
                                br: <br />,
                                bold: <p className="body1 inline" />
                            }}
                            values={{ time: `${getDateUTC({ date: backupFile.alteredTime })} UTC` }}
                        />
                        <br />
                        <br />
                        <p className="body1">{t('affected_columns')}:</p>
                        <Grid item>
                            <Grid className={classes.listAffectedColumns} container direction="column" wrap="nowrap">
                                {affectedReferences.map(el => (
                                    <Grid key={el.id} item className={classes.affectedColumn}>
                                        <Grid container wrap="nowrap" spacing={1} alignItems="center">
                                            <Grid item>
                                                <ReferenceSVG />
                                            </Grid>
                                            <Grid item className={classes.flx}>
                                                <OverflowTypography maxLines={1} variant="body2">
                                                    {el.name}
                                                </OverflowTypography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item>
                        <Trans
                            t={t}
                            i18nKey={'ask_restore_grid'}
                            components={{
                                br: <br />,
                                bold: <p className="body1 inline" />
                            }}
                            values={{ time: `${getDateUTC({ date: backupFile.alteredTime })} UTC` }}
                        />
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            width={120}
                            onClick={onAccept}
                            disabled={fetchingAffectedReferences}
                        >
                            {t(`global_restore`)}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(RestoreConfirmModal);
