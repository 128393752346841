import React from 'react';

function DeleteSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Icons/Delete" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M3.75,13 C3.75,13.825 4.425,14.5 5.25,14.5 L11.25,14.5 C12.075,14.5 12.75,13.825 12.75,13 L12.75,4 L3.75,4 L3.75,13 Z M5.25,5.5 L11.25,5.5 L11.25,13 L5.25,13 L5.25,5.5 Z M10.875,1.75 L10.125,1 L6.375,1 L5.625,1.75 L3,1.75 L3,3.25 L13.5,3.25 L13.5,1.75 L10.875,1.75 Z"
                    id="Shape"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(DeleteSVG);
