import React from 'react';

function RegexSVG({ color = '#93929E', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip_regex_0)">
                <path
                    d="M7.25806 8.18457V5.41205L4.97434 6.81148L4.16925 5.40899L6.55243 4.10485L4.16925 2.78133L4.97843 1.40441L7.25806 2.79768V0.0144653H8.88511V2.79768L11.1858 1.40441L12 2.78133L9.6061 4.10485L12 5.40899L11.1826 6.81786L8.88511 5.41205V8.18457H7.25806ZM3.40249 10.2843C3.40249 8.97951 1.9803 8.15961 0.848702 8.81199C-0.282901 9.46437 -0.2829 11.1042 0.848702 11.7566C1.9803 12.4089 3.40249 11.589 3.40249 10.2843Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip_regex_0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(RegexSVG);
