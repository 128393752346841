import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { useSelected, useFocused } from 'slate-react';
import hexToRgba from 'hex-to-rgba';
import CountryFlag from 'components/svg-icon/CountryFlag';
import SvgIcon from 'components/svg-icon/Base';
import * as columnTypes from 'const/columnTypes';

const Tag = ({ attributes, children, element }) => {
    // const selected = useSelected();
    // const focused = useFocused();
    const theme = useTheme();
    const suggestion = element?.suggestion;
    const columnType = suggestion?.columnType;
    const isLangColumn = columnType === columnTypes.TRANSLATION;
    const group = suggestion?.group;

    return (
        <span
            {...attributes}
            contentEditable={false}
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                borderRadius: 4,
                fontSize: 14,
                fontFamily: 'Roboto',
                color: theme.colors.primaryText,
                padding: `2px 4px`,
                background: hexToRgba(theme.colors.fuchsiaBlue, 0.1)
            }}
        >
            {isLangColumn ? (
                <span style={{ marginRight: 3, position: 'relative', top: -1 }}>
                    <CountryFlag languageCountryCode={group} />
                </span>
            ) : (
                <span style={{ marginRight: 3, position: 'relative' }}>
                    <SvgIcon type={columnType} />
                </span>
            )}{' '}
            {suggestion?.label}
            {children}
        </span>
    );
};

export default Tag;
