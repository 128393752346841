import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import InputText from 'components/inputs/InputText';
import Divider from 'components/divider/Base';
import { beautifyPrice } from 'utils/payment';
import PlanTag from 'payment/components/tags/Plan';
import { useCompanyInfo } from 'hooks/app';
import { useTaxRate } from 'hooks/payment';
import { MAX_SEAT_INPUT } from 'const';

const useStyles = makeStyles(theme => ({
    greyText: {
        color: theme.colors.midGrey
    },
    dodgerBlue: {
        color: theme.colors.dodgerBlue
    },
    mt2: {
        marginTop: theme.spacing(2)
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
    divider: {
        width: '100%',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        border: '1px dashed #93929E'
    }
}));

function ChangeSeat({
    onClose,
    onConfirm,
    isSubmitting,
    companySubscription,
    proSeat,
    onChangeProSeat,
    changePlanPreview,
    isValidSeat,
    selectedCycle
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const companyInfo = useCompanyInfo();
    const taxRate = useTaxRate();

    return (
        <>
            <DialogTitle title="Manage member seats" onClose={onClose} />
            <DialogContent>
                <Grid container>
                    <Grid item>
                        <Typography variant="body1">Seats</Typography>
                    </Grid>
                    <Grid item container className={classes.mt2}>
                        <InputText
                            name="seatsNumber"
                            type="number"
                            value={proSeat}
                            onChange={onChangeProSeat}
                            inputProps={{ min: companySubscription?.activeSeat, max: MAX_SEAT_INPUT }}
                            endAdornment={
                                <Typography variant="body2" className={classes.greyText}>
                                    seats
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item container justifyContent="space-between" className={classes.mt4}>
                        <Grid item>
                            <Typography variant="body2" className={classes.greyText}>
                                Amount
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="flex-end">
                                <Grid item>
                                    <Typography variant="body1" display="inline">
                                        {beautifyPrice({
                                            unitAmount: changePlanPreview?.subTotalAmount,
                                            currency: companySubscription?.seatCurrency
                                        })}
                                    </Typography>
                                    <Typography variant="body2" display="inline" className={classes.greyText}>
                                        /{companySubscription?.cycle}
                                    </Typography>
                                </Grid>
                                {Boolean(changePlanPreview?.discountAmount) && (
                                    <Grid item>
                                        <Typography variant="body1" display="inline" className={classes.dodgerBlue}>
                                            (saved{' '}
                                            {beautifyPrice({
                                                unitAmount: changePlanPreview?.discountAmount,
                                                currency: companySubscription?.seatCurrency
                                            })}
                                            )
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" className={classes.mt3}>
                        <Grid item>
                            <Typography variant="body2" className={classes.greyText}>
                                Company
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{companyInfo?.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" className={classes.mt3}>
                        <Grid item>
                            <Typography variant="body2" className={classes.greyText}>
                                {t('plan')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <PlanTag planType={companySubscription?.plan} />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" className={classes.mt3}>
                        <Grid item>
                            <Typography variant="body2" className={classes.greyText}>
                                Subscription period
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                {companySubscription?.monthly ? 'Monthly' : 'Annually'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" className={classes.mt3}>
                        <Grid item>
                            <Typography variant="body2" className={classes.greyText}>
                                {t('global_tax')} ({taxRate * 100}%)
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                {beautifyPrice({
                                    unitAmount: changePlanPreview?.taxAmount,
                                    currency: companySubscription?.seatCurrency
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body2" className={classes.greyText}>
                                {t('total')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                <Typography variant="h3" display="inline" className={classes.dodgerBlue}>
                                    {beautifyPrice({
                                        unitAmount: changePlanPreview?.totalAmount,
                                        currency: companySubscription?.seatCurrency
                                    })}
                                </Typography>
                                <Typography className={classes.greyText} variant="body2" display="inline">
                                    /{companySubscription?.cycle}
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" width={100} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isSubmitting || !isValidSeat || proSeat === companySubscription?.totalSeat}
                            variant="contained"
                            width={120}
                            className={classes.cancelButton}
                            onClick={() => onConfirm({ totalSeat: proSeat })}
                        >
                            {isSubmitting ? <CircularProgress size={24} /> : 'Purchase'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(ChangeSeat);
