import unified from 'unified';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import html from 'rehype-stringify';
import htmlToSlate from './html/deserialize';
import { SLATE_MD_EMPTY_VALUE } from '../Base';

const desirialize = async string => {
    const data = await unified()
        .use(markdown)
        .use(remark2rehype)
        .use(html)
        .process(string);

    // TODO: test and fix multi line break. Use string replace.
    const LINEBREAK_TAGS = ['h1', 'h2', 'h3', 'ul', 'ol', 'p', 'li'];
    let removedBreakContent = data.contents;
    LINEBREAK_TAGS.forEach(tag => {
        removedBreakContent = removedBreakContent
            .split(`\n<${tag}>`)
            .join(`<${tag}>`)
            .split(`</${tag}>\n`)
            .join(`</${tag}>`);
    });

    const slate = htmlToSlate(removedBreakContent);

    if (slate.length === 1 && slate[0].children && slate[0].children.length === 0) {
        return SLATE_MD_EMPTY_VALUE;
    }

    return slate;
};

export default desirialize;
