// Tracker for CRM purpose
import { trackUserEvent } from 'services/external';
import { queryParamsToObject } from 'utils/urlQueryUtils';

const PREFIX = 'Gridly App - ';

export const CRM_EVENT_NAME = {
    EXPORT_FILE: 'Export File',
    CREATE_API_KEY: 'Create API Key',
    LOCALIZATION_EXPORT_FILE: 'Localization Export File',
    ADD_TICKET: 'Add Ticket',
    USE_TEMPLATE: 'Use Template',
    IMPORT_FILE: 'Import File',
    LOCALIZATION_IMPORT_FILE: 'Localization Import File',
    ADD_IMPORT_LOCALIZATION_GRID_FROM_JSON_VERTICAL_FILE: 'Add Import Localization Grid From JSON Vertical file',
    INSTALL_MEMOQ: 'Install memoQ',
    INSTALL_MEMSOURCE: 'Install Memsource',
    ADD_DATABASE: 'Add Database',
    DELETE_ACCOUNT: 'Delete Account',
    DELETE_ACCOUNT_WITH_UNSUBSCRIBE: 'Delete Account with Unsubscribe'
};

const EVENTS_WHITELIST = Object.values(CRM_EVENT_NAME);

export function track(eventName, data) {
    if (!EVENTS_WHITELIST.includes(eventName)) {
        return;
    }

    const customProps = {};
    const queryParams = queryParamsToObject(window.location.search);
    for (const prop in queryParams) {
        if (prop.includes('utm_')) {
            customProps[prop] = queryParams[prop];
        }
    }

    trackUserEvent({
        event: `${PREFIX}${eventName}`,
        customProps,
        ...data
    });
}
