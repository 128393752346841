import React from 'react';
import { useRole } from 'hooks/auth/role';
import { HIDDEN, READ_ONLY, FULL } from './roleConst';

function returnElement({ children, status, ...rest }) {
    const isFunc = typeof children === 'function';
    switch (status) {
        case HIDDEN:
            return null;
        case READ_ONLY:
            return isFunc
                ? children({ isReadOnly: true, ...rest })
                : React.cloneElement(children, { isReadOnly: true, ...rest });
        case FULL:
            return isFunc ? children({ ...rest }) : React.cloneElement(children);
        default:
            return null;
    }
}

function AccessControl({ children, view, bypass, ...rest }) {
    const role = useRole();
    return returnElement({ children, status: bypass ? FULL : role[view], ...rest });
}

export default React.memo(AccessControl);
