import request from './request';
import { APP_API_WORK_FLOW } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';
import { isEmpty } from 'lodash';

const workflowApis = {
    hooks: `${APP_API_WORK_FLOW}/v1/flows`,
    hook: hookId => `${APP_API_WORK_FLOW}/v1/flows/${hookId}`,
    testHook: `${APP_API_WORK_FLOW}/v1/flows/test`,
    previewHook: `${APP_API_WORK_FLOW}/v1/flows/preview`,
    flowSteps: hookId => `${APP_API_WORK_FLOW}/v1/flows/${hookId}/steps`,
    triggerFlow: flowId => `${APP_API_WORK_FLOW}/v1/flows/${flowId}/trigger`,
    getJiraCreateTypes: `${APP_API_WORK_FLOW}/v1/feedback/create-types`,
    createIssue: `${APP_API_WORK_FLOW}/v1/feedback`,
    addons: `${APP_API_WORK_FLOW}/v1/integrations`,
    addonDetail: id => `${APP_API_WORK_FLOW}/v1/integrations/${id}`,
    memSourceProjects: id => `${APP_API_WORK_FLOW}/v1/integrations/${id}/memSource/projects`,
    memSourceTemplates: id => `${APP_API_WORK_FLOW}/v1/integrations/${id}/memSource/projectTemplates`,
    memoQProjects: id => `${APP_API_WORK_FLOW}/v1/integrations/${id}/memoQ/projects`,
    memoQTemplates: id => `${APP_API_WORK_FLOW}/v1/integrations/${id}/memoQ/projectTemplates`,
    checkMemoQAvailable: id => `${APP_API_WORK_FLOW}/v1/integrations/${id}/memoQ/apiVersion`,
    lambdas: `${APP_API_WORK_FLOW}/v1/extensions/lambda`,
    singleLambda: lambdaId => `${APP_API_WORK_FLOW}/v1/extensions/lambda/${lambdaId}`,
    getWorkflowSubmittedJobs: ({ viewId, id }) => `${APP_API_WORK_FLOW}/v1/flows/${id}/steps/views/${viewId}`,
    invokeFlow: flowId => `${APP_API_WORK_FLOW}/v1/flows/${flowId}/invoke`,
    sources: `${APP_API_WORK_FLOW}/v1/connector/sources`,
    source: sId => `${APP_API_WORK_FLOW}/v1/connector/sources/${sId}`,
    connections: `${APP_API_WORK_FLOW}/v1/connector/connections`,
    connection: cId => `${APP_API_WORK_FLOW}/v1/connector/connections/${cId}`,
    connectorInfo: `${APP_API_WORK_FLOW}/v1/connector/info`,
    syncHistories: cId => `${APP_API_WORK_FLOW}/v1/connector/syncs?connectionId=${cId}`,
    syncHistoriesGridId: dId => `${APP_API_WORK_FLOW}/v1/connector/syncs?destinationGridId=${dId}`,
    sync: tId => `${APP_API_WORK_FLOW}/v1/connector/syncs/${tId}`,
    batches: `${APP_API_WORK_FLOW}/v1/connector/batches`
};

export async function getBatchesApi(syncId) {
    const response = await request.get(`${workflowApis.batches}?syncId=${syncId}`);
    return response.data;
}

export async function getAddonByIdApi(id) {
    const response = await request.get(`${workflowApis.addonDetail(id)}`);
    return response.data;
}

export async function getAddonsApi() {
    const response = await request.get(`${workflowApis.addons}`);
    return response.data;
}

export async function createAddonApi({ body }) {
    const response = await request.post(`${workflowApis.addons}`, body);
    return response.data;
}

export async function updateAddonApi({ body, addonId }) {
    const response = await request.put(`${workflowApis.addons}/${addonId}`, body);
    return response.data;
}

export async function createJiraIssueApi({ body }) {
    const response = await request.post(`${workflowApis.createIssue}`, body);
    return response.data;
}

export async function createZendeskIssueApi({ name, email, subject, comment }) {
    const body = { request: { requester: { name, email }, subject, comment: { body: comment } } };
    const response = await fetch('https://gridly.zendesk.com/api/v2/requests.json', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    });
    return await response.json();
}

export async function getJiraCreateTypesApi() {
    const response = await request.get(`${workflowApis.getJiraCreateTypes}`);
    return response.data;
}

export async function getFlowStepsApi({ hookId, offset, limit }) {
    let extraUrl = `offset=${offset}&limit=${limit}`;
    const response = await request.get(`${workflowApis.flowSteps(hookId)}?${extraUrl}`);
    return {
        logs: response.data,
        totalLog: Number(response?.headers?.['x-total-count'] || 0)
    };
}

export async function getHooksApi({ dbId, gridId, viewId }) {
    const query = {
        dbId,
        gridId,
        viewId
    };
    const extra = objectToQueryParams(query);
    const response = await request.get(`${workflowApis.hooks}?${extra}`);
    return response.data;
}

export async function createHookApi({ body }) {
    const response = await request.post(`${workflowApis.hooks}`, body);
    return response.data;
}

export async function deleteHookApi({ hookId }) {
    const response = await request.delete(`${workflowApis.hook(hookId)}`);
    return response.data;
}

export async function updateHookApi({ hookId, body }) {
    const response = await request.put(`${workflowApis.hook(hookId)}`, body);
    return response.data;
}

export async function testHookApi({ body }) {
    const response = await request.post(`${workflowApis.testHook}`, body);
    return response.data;
}

export async function previewHookApi({ body }) {
    const response = await request.post(`${workflowApis.previewHook}`, body);
    return response.data;
}

export async function triggerFlowApi(flowId) {
    const response = await request.put(`${workflowApis.triggerFlow(flowId)}`);
    return response.data;
}

export async function getMemSourceTemplatesApi({ id, query }) {
    let extraUrl = workflowApis.memSourceTemplates(id);
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getMemSourceProjectsApi({ id, query }) {
    let extraUrl = workflowApis.memSourceProjects(id);
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getMemoQTemplatesApi({ id, query }) {
    let extraUrl = workflowApis.memoQTemplates(id);
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getMemoQProjectsApi({ id, query }) {
    let extraUrl = workflowApis.memoQProjects(id);
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function checkMemoQAvailable({ id, query }) {
    const response = await request.get(workflowApis.checkMemoQAvailable(id));
    return response.data;
}

export async function getLambdasApi() {
    const response = await request.get(workflowApis.lambdas);
    return response.data;
}

export async function createLambdaApi(formData) {
    const response = await request.post(workflowApis.lambdas, formData);
    return response.data;
}

export async function editLambdaApi({ lambdaId, formData }) {
    const response = await request.put(workflowApis.singleLambda(lambdaId), formData);
    return response.data;
}

export async function deleteLambdaApi({ lambdaId }) {
    const response = await request.delete(workflowApis.singleLambda(lambdaId));
    return response.data;
}

export async function getWorkflowSubmittedJobsApi({ viewId, query, id }) {
    let extraUrl = `${workflowApis.getWorkflowSubmittedJobs({ viewId, id })}`;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function invokeFlowApi({ flowId, filterQuery }) {
    const response = await request.put(`${workflowApis.invokeFlow(flowId)}`, { filter: JSON.stringify(filterQuery) });
    return response.data;
}

export async function getConnectionsApi(query) {
    let extraUrl = workflowApis.connections;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getConnectionApi(cId) {
    const response = await request.get(`${workflowApis.connection(cId)}`);
    return response.data;
}

export async function deleteConnectionApi(cId) {
    const response = await request.delete(`${workflowApis.connection(cId)}`);
    return response.data;
}

export async function createConnectionApi(connection) {
    const response = await request.post(`${workflowApis.connections}`, connection);
    return response.data;
}

export async function updateConnectionApi({ cId, connection }) {
    const response = await request.put(`${workflowApis.connection(cId)}`, connection);
    return response.data;
}

export async function syncConnectionApi(cId) {
    const response = await request.post(`${workflowApis.connection(cId)}/sync`);
    return response.data;
}

export async function getSourcesApi() {
    const response = await request.get(`${workflowApis.sources}`);
    return response.data;
}

export async function getSourceApi(sId) {
    const response = await request.get(`${workflowApis.source(sId)}`);
    return response.data;
}

export async function createSourceApi(source) {
    const response = await request.post(`${workflowApis.sources}`, source);
    return response.data;
}

export async function updateSourceApi({ sId, source }) {
    const response = await request.put(`${workflowApis.source(sId)}`, source);
    return response.data;
}

export async function deleteSourceApi(sId) {
    const response = await request.delete(`${workflowApis.source(sId)}`);
    return response.data;
}

export async function checkSourceApi(sId) {
    const response = await request.post(`${workflowApis.source(sId)}/check`);
    return response.data;
}

export async function discoverSourceApi(sId) {
    const response = await request.get(`${workflowApis.source(sId)}/discover`);
    return response.data;
}

export async function freshDiscoverSourceApi(sId) {
    const response = await request.get(`${workflowApis.source(sId)}/freshDiscover`);
    return response.data;
}

export async function getSourceInfoApi(connector) {
    let url = `${workflowApis.connectorInfo}`;
    if (connector) {
        url += `?connector=${connector}`;
    }
    const response = await request.get(url);
    return response.data;
}

export async function getSyncHistoriesApi(cId) {
    const response = await request.get(`${workflowApis.syncHistories(cId)}`);
    return response.data;
}
export async function getSyncHistoriesDestinationIdApi(dId) {
    const response = await request.get(`${workflowApis.syncHistoriesGridId(dId)}`);
    return response.data;
}

export async function getSyncStatusApi(tId) {
    const response = await request.get(`${workflowApis.sync(tId)}`);
    return response.data;
}
