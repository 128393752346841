import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, InputBase } from '@material-ui/core';
import SearchSVG from 'assets/images/svg/SearchSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

import { SPACING_LIST_NAME_WITH_ICON, INPUT_PADDING_LEFT, INPUT_HEIGHT } from 'const/style';
import { SMALL, LARGE, MEDIUM } from 'const/componentSize';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width || '100%',
        background: props => props.background || 'transparent',
        borderRadius: 4,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        height: props => props.height || INPUT_HEIGHT,
        '&:focus-within': {
            border: `1px solid ${theme.palette.primary.main}`
        }
    },
    input: {
        flex: 1,
        fontSize: 13,
        color: theme.colors.primaryText,
        width: '100%',
        marginLeft: SPACING_LIST_NAME_WITH_ICON,
        '&::placeholder': {
            color: `${theme.colors.midGrey} !important`
        },
        fontWeight: 'normal'
    },
    searchIcon: {
        position: 'relative',
        top: -1,
        '& svg': {
            position: 'relative',
            top: 3,
            '& path': {
                fill: theme.colors.disabledGrey
            }
        }
    },
    clearIcon: {
        margin: theme.spacing(2),
        display: 'flex',
        cursor: 'pointer'
    }
}));

function getHeight(size) {
    switch (size) {
        case SMALL:
            return INPUT_HEIGHT;
        case MEDIUM:
            return INPUT_HEIGHT + 8;
        case LARGE:
            return INPUT_HEIGHT + 16;
        default:
            return INPUT_HEIGHT;
    }
}

function Search({
    id,
    value,
    size = 'small',
    width,
    className,
    background,
    onChange,
    onClear,
    placeholder,
    autoComplete = 'off',
    ...rest
}) {
    const classes = useStyles({ width, background, height: getHeight(size) });
    const { t } = useTranslation();

    return (
        <Grid id={id} container alignItems="center" className={`${classes.root} ${className}`}>
            <Grid item className={classes.searchIcon}>
                <SearchSVG />
            </Grid>
            <Grid item style={{ flex: 1 }}>
                <InputBase
                    name="search"
                    className={classes.input}
                    placeholder={placeholder || t('global_search')}
                    type={'text'}
                    onChange={onChange}
                    value={value}
                    autoComplete={autoComplete}
                    {...rest}
                />
            </Grid>
            {onClear && value && (
                <Grid item className={classes.clearIcon} onClick={onClear}>
                    <CloseIconSVG />
                </Grid>
            )}
        </Grid>
    );
}

export default Search;
