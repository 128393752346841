import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import ButtonBase from 'components/button/Base';
import { AutoSizer } from 'react-virtualized-dn';
import { useJsonResourceKey, useJsonTree, useJsonObjectIds } from 'hooks/gridUI/import';
import { useDispatch } from 'react-redux';
import * as importActions from 'gridUI/actions/import';
import * as gridActions from '../actions';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { ROW_HEIGHT } from 'const/gridUI';

import { sendManualTrack } from 'tracker';
import LDBasePortal from 'components/selects/LDBasePortal';
import PathSVG from 'assets/images/svg/localization/PathSVG';
// import { MaxNumberInAr } from 'utils/object';
import { LOCALIZATION_TYPES } from 'const';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { getFileName } from 'utils/fileUtils';
import { useTranslation } from 'react-i18next';
import JSONTree from './json/JSONTree';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import produce from 'immer';
import chunk from 'lodash/chunk';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import Search from 'components/search';
import { List } from 'react-virtualized-dn';
import NoDiffCheck from 'assets/images/svg/versionControl/NoDiffCheck';
import ListSelect from 'components/list/Select';
import Tooltip from 'components/tooltip/Base';
import Spinner from 'components/spinner/Base';
import {
    getAllNodePropertiesPath,
    getTreeItems,
    getAllNodePropertiesPathVertical,
    getFirstLevelProperties
} from 'utils/gridUI/jsonTree';
import JsonEditor from 'components/jsoneditor';
import { beautifyJSONValue } from 'utils/gridUI/formatData';
import FileSVG from 'assets/images/svg/FileSVG';
import MultiStepIllustratorSVG from 'assets/images/svg/MultiStepIllustratorSVG';
import GridUITable from 'gridUI/table/grid/GridUIViewOnly';
import ImportHeader from './json/ImportHeader';
import * as columnTypes from 'const/columnTypes';

const ITEMS_PER_ROW = 4;

const useStyles = makeStyles(theme => ({
    root: {
        width: 740,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'initial',
            width: '100%',
            height: '100%'
        }
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    displayFlex: {
        display: 'flex'
    },
    table: {
        height: 250,
        userSelect: 'none',
        '& .cell': {
            pointerEvents: 'none'
        },
        position: 'relative',
        width: '100%'
    },

    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },

    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    box: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        overflowX: 'auto'
    },
    w170: {
        minWidth: 170
    },
    split: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    uploadingUI: {
        width: 850,
        height: 620
    },
    columnMapList: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            overflowX: 'auto'
        }
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        zIndex: 10
    },
    mappedColumn: {
        borderLeft: `1px solid ${theme.colors.divider}`
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    treeBox: {
        height: 320,
        flex: 1,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`
    },
    treeBoxHeader: {
        height: 40,
        background: theme.colors.ghostwhite,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    treeBoxContent: {
        height: 280,
        overflow: 'auto',
        position: 'relative'
    },
    search: {
        width: 240
    },
    targetList: {
        padding: theme.spacing(3),
        height: 152,
        overflowY: 'auto',
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`
    },
    originLanguage: {
        position: 'relative'
    },
    arrow: {
        position: 'absolute',
        right: -18,
        top: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    formControl: {
        '&.MuiFormControlLabel-root': {
            marginRight: 0
        }
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    spacing4: {
        marginBottom: theme.spacing(4)
    },
    otherColumns: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        borderTop: `1px solid ${theme.colors.border}`
    },
    addMoreSource: {
        height: 36,
        cursor: 'pointer',
        minWidth: 170,
        border: `1px dashed ${theme.colors.border}`,
        borderRadius: 4,
        '& p': {
            color: theme.colors.lightLavender
        },
        '& svg': {
            marginRight: theme.spacing(2)
        },
        '&:hover': {
            background: theme.colors.solitude
        }
    },
    dlFlex: {
        display: 'flex'
    },
    langSelect: {
        maxWidth: 300
    },
    rowItem: {
        minWidth: 160
    },
    spb3: {
        marginBottom: theme.spacing(3)
    },
    virtualizeList: {
        outline: 'none'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    highlight: {
        color: theme.colors.lightLavender,
        cursor: 'pointer'
    },
    haft: {
        flexBasis: '50%',
        overflow: 'hidden'
    },
    multiStepExplain: {
        maxWidth: 250,
        padding: theme.spacing(2)
    },
    fileIcon: {
        display: 'flex',
        marginRight: theme.spacing(1)
    },
    fileName: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

const STEPS = {
    PREVIEW: 'PREVIEW',
    SETUP_LOCALIZATION: 'SETUP_LOCALIZATION'
};

const IMPORT_TYPES = {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
};

function LocalizationImportSetup({ onClose, isMulti, dbId, files }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [currentStep, setCurrentStep] = React.useState(STEPS.PREVIEW);

    const [isUploading, setIsUploading] = React.useState(false);
    const [jsonPreviewData, setJsonPreviewData] = React.useState(null);

    const [isTypeMultiple, setIsTypeMultiple] = React.useState(
        isMulti ? LOCALIZATION_TYPES.MULTIPLE : LOCALIZATION_TYPES.SINGLE
    );

    const [importType, setImportType] = React.useState(IMPORT_TYPES.HORIZONTAL);

    React.useEffect(() => {
        if (files) {
            var reader = new FileReader();
            reader.onload = e => {
                const data = JSON.parse(e.target.result);
                setJsonPreviewData(data);
            };
            reader.readAsText(files?.[0]);
        }
    }, [files]);

    const [name, setName] = React.useState(getFileName(files?.[0]?.name) || '');
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [objectKey, setObjectKey] = React.useState(null);
    const [additionalInformation, setAdditionalInformation] = React.useState(null);
    const [characterLimit, setCharacterLimit] = React.useState(null);
    const langOptions = useLanguageOptionsWithUsageValidate();
    const [propertyPaths, setPropertyPaths] = React.useState({});
    const [mapVerticalColumns, setMapVerticalColumns] = React.useState({});

    const isModeSingle = React.useMemo(() => {
        return isTypeMultiple === LOCALIZATION_TYPES.MULTIPLE ? false : true;
    }, [isTypeMultiple]);
    const [filterValue, setFilterValue] = React.useState('');

    const [sources, setSources] = React.useState(isModeSingle ? [] : [null]);
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});

    const targetOptions = React.useMemo(() => {
        const selectedSources = sources?.map(source => source?.value);
        return langOptions
            ?.filter(option => !selectedSources.includes(option.value))
            .filter(option => {
                return option?.label?.toLowerCase()?.includes(filterValue?.toLowerCase());
            });
    }, [langOptions, filterValue, sources]);

    const tree = useJsonTree(dbId);
    const resourceKey = useJsonResourceKey(dbId);
    const objectIds = useJsonObjectIds(dbId);

    const handleAddSource = React.useCallback(() => {
        setSources([...sources, null]);
    }, [sources]);

    const objectIdsOptions = React.useMemo(() => {
        return objectIds?.map(key => {
            return {
                value: key,
                label: key
            };
        });
    }, [objectIds]);

    const { columns, data, records, metaData, verticalPropertyPaths } = React.useMemo(() => {
        const items = getTreeItems(tree);
        const { columns, data, records, verticalPropertyPaths } = getFirstLevelProperties(items, propertyPaths);
        let metaData = { ...mapVerticalColumns };
        for (const column of columns) {
            metaData = {
                ...metaData,
                [column]: {
                    ...mapVerticalColumns?.[column],
                    type: metaData?.[column]?.type || columnTypes.MULTIPLE_LINES,
                    id: column,
                    name: column
                }
            };
        }
        return {
            columns: [columnTypes.RECORD_ID, ...columns],
            data,
            records,
            metaData: {
                ...metaData,
                [columnTypes.RECORD_ID]: {
                    type: columnTypes.RECORD_ID,
                    id: columnTypes.RECORD_ID,
                    name: 'Record ID'
                }
            },
            verticalPropertyPaths
        };
    }, [tree, propertyPaths, mapVerticalColumns]);

    const handleColumnChange = React.useCallback(
        ({ id, body }) => {
            setMapVerticalColumns({
                ...mapVerticalColumns,
                [id]: {
                    ...mapVerticalColumns,
                    ...body
                }
            });
        },
        [mapVerticalColumns]
    );

    React.useEffect(() => {
        if (files) {
            setIsUploading(true);
            dispatch(
                importActions.importLocalizationGridReadJSONFile({
                    dbId,
                    file: files,
                    successCallback: ({ tree }) => {
                        const treeItems = getTreeItems(tree);
                        const paths = getAllNodePropertiesPath(treeItems);
                        const defaultPropertyPaths = {};
                        for (const path of paths) {
                            defaultPropertyPaths[path] = 1;
                        }

                        setPropertyPaths(defaultPropertyPaths);
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                    }
                })
            );
        }
    }, [dispatch, dbId, files]);

    React.useEffect(() => {
        if (isTypeMultiple === LOCALIZATION_TYPES.SINGLE) {
            dispatch(importActions.clearOriginalLocalizationColumn());
        }
    }, [isTypeMultiple, dispatch]);

    const handleObjectKeyChange = React.useCallback(
        option => {
            if (option && option.value === additionalInformation?.value) {
                setAdditionalInformation(null);
            }

            if (option && option.value === characterLimit?.value) {
                setCharacterLimit(null);
            }

            setObjectKey(option);
        },
        [additionalInformation, characterLimit]
    );

    const handleAdditionalInformationChange = React.useCallback(option => {
        setAdditionalInformation(option);
    }, []);

    const handleCharacterLimitChange = React.useCallback(option => {
        setCharacterLimit(option);
    }, []);

    const handleModeChange = React.useCallback(
        e => {
            const value = e.target.value;
            setIsTypeMultiple(value);

            if (value === LOCALIZATION_TYPES.SINGLE) {
                setSources([sources?.find(source => !!source)]);
            }

            if (value === LOCALIZATION_TYPES.MULTIPLE) {
                if (!sources?.length) {
                    setSources([null]);
                }
            }

            setSelectedTargetOptions({});
        },
        [sources]
    );

    const handleImportTypeChange = React.useCallback(
        e => {
            const value = e.target.value;
            setIsUploading(true);
            setImportType(value);
            if (value === IMPORT_TYPES.VERTICAL) {
                setTimeout(() => {
                    const treeItems = getTreeItems(tree);
                    const defaultPropertyPaths = getAllNodePropertiesPathVertical(treeItems, false);
                    setPropertyPaths(defaultPropertyPaths);
                }, 0);
            } else {
                setTimeout(() => {
                    setMapVerticalColumns({});
                }, 0);
            }
            setTimeout(() => {
                setIsUploading(false);
            }, 0);
        },
        [tree]
    );

    const handleSourceChange = React.useCallback(
        option => {
            setSources([option]);
            const newSelectedTargetOptions = { ...selectedTargetOptions };
            if (newSelectedTargetOptions[option?.value]) {
                delete newSelectedTargetOptions[option?.value];
            }
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    const handleMultiSourceChange = React.useCallback(
        ({ option, index }) => {
            let updateSources = produce(sources, draft => {
                draft[index] = option;
            });
            setSources(updateSources);
            const newSelectedTargetOptions = { ...selectedTargetOptions };
            if (newSelectedTargetOptions[option?.value]) {
                delete newSelectedTargetOptions[option?.value];
            }
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [sources, selectedTargetOptions]
    );

    const handleMultiSourceRemove = React.useCallback(
        index => {
            let sourcesClone = [...sources];
            sourcesClone.splice(index, 1);
            setSources(sourcesClone);
        },
        [sources]
    );

    const targetOptionChunks = React.useMemo(() => {
        return chunk(targetOptions, ITEMS_PER_ROW);
    }, [targetOptions]);

    const validSelectedTargetOptions = React.useMemo(() => {
        const validSelected = [];
        Object.keys(selectedTargetOptions).forEach(key => {
            if (selectedTargetOptions[key]) {
                const validOption = langOptions.find(option => option.value === key);
                if (validOption) {
                    validSelected.push(validOption);
                }
            }
        });
        return validSelected;
    }, [selectedTargetOptions, langOptions]);

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item>
                    <NoDiffCheck />
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t('global_empty_language_msg')}</Typography>
                </Grid>
            </Grid>
        );
    }, [t]);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions[targetValue] || false;

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style }) => {
            const items = targetOptionChunks?.[index];
            return (
                <Grid style={{ ...style }} key={key} container alignItems="center" direction="row" wrap="nowrap">
                    {items?.map(option => {
                        const isSelected = selectedTargetOptions?.[option?.value];

                        if (option?.isDisabled) {
                            return (
                                <Grid key={option?.value} item xs={3}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                        return (
                            <Tooltip title={option.tooltip} key={option.value}>
                                <Grid item xs={3}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            );
        },
        [handleTargetSelect, targetOptionChunks, selectedTargetOptions, classes]
    );

    const onBack = React.useCallback(() => {
        setCurrentStep(STEPS.PREVIEW);
    }, []);

    const handleNext = React.useCallback(() => {
        setCurrentStep(STEPS.SETUP_LOCALIZATION);
    }, []);

    const isCreateValid = React.useMemo(() => {
        const isValidName = !!name;
        const isValidSourceRequest = sources?.filter(source => !!source)?.length;
        const isValidTargetLanguages = validSelectedTargetOptions.length;

        return isValidName && isValidSourceRequest && isValidTargetLanguages;
    }, [name, sources, validSelectedTargetOptions]);

    const onNodeChanges = React.useCallback(
        ({ checkNodeIds, unCheckNodeIds, partialNodeIds }) => {
            const newObj = { ...propertyPaths };
            for (const path of checkNodeIds) {
                newObj[path] = 1;
            }

            for (const path of unCheckNodeIds) {
                newObj[path] = 2;
            }

            for (const path of partialNodeIds) {
                newObj[path] = 0;
            }

            setPropertyPaths(newObj);
        },
        [propertyPaths]
    );

    const handleCreateLocalizationGrid = React.useCallback(() => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Add Import Localization Grid From JSON file` });

        const keys = Object.keys(propertyPaths);
        const propertyPathsServer = keys?.filter(key => propertyPaths?.[key] === 1);

        let body = {
            name,
            type: isModeSingle ? 'Single' : 'Multiple',
            description: t('localization_grid_default_description'),
            targetLanguages: validSelectedTargetOptions.map(option => option?.value),
            key: resourceKey,
            propertyPaths: propertyPathsServer
        };

        if (isModeSingle || sources?.length === 1) {
            body = {
                ...body,
                type: LOCALIZATION_TYPES.SINGLE,
                sourceLanguage: sources?.[0]?.value
            };
        } else {
            body = {
                ...body,
                type: LOCALIZATION_TYPES.MULTIPLE,
                sourceLanguages: sources?.filter(source => !!source)?.map(source => source?.value)
            };
        }

        if (objectKey?.value) {
            body = {
                ...body,
                objectId: objectKey?.value
            };
        }

        if (additionalInformation?.value) {
            body = {
                ...body,
                extraInfoKey: additionalInformation?.value
            };
        }

        if (characterLimit?.value) {
            body = {
                ...body,
                characterLimitKey: characterLimit?.value
            };
        }

        dispatch(
            gridActions.createJsonLocalizationGrid({
                dbId,
                body,
                successCallback: newGrid => {
                    console.log('create localization grid success');
                    setIsSubmitting(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to create localization');
                }
            })
        );
    }, [
        t,
        dbId,
        dispatch,
        onClose,
        isModeSingle,
        propertyPaths,
        name,
        sources,
        validSelectedTargetOptions,
        resourceKey,
        objectKey,
        additionalInformation,
        characterLimit
    ]);

    const handleCreateVerticalJsonGrid = React.useCallback(() => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Add Import Localization Grid From JSON Vertical file` });

        let body = {
            name,
            description: t('localization_grid_default_description'),
            mappingType: 'vertical',
            key: resourceKey,
            verticalColumns: columns
                ?.filter(columnId => columnId !== columnTypes.RECORD_ID)
                ?.map(columnId => {
                    const column = { ...metaData?.[columnId], ...mapVerticalColumns?.[columnId] };
                    return {
                        key: columnId,
                        type: column?.type,
                        group: column?.group,
                        localizationType: column?.localizationType
                    };
                }),
            parentPropertyPath: tree?.propertyPath || '', // improve this after we support user to choose root
            verticalPropertyPaths
        };
        console.log('body', body);

        setIsSubmitting(true);
        dispatch(
            gridActions.createJsonLocalizationGrid({
                dbId,
                body,
                successCallback: newGrid => {
                    console.log('create vertical json grid success');
                    setIsSubmitting(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to create vertical json');
                }
            })
        );
    }, [
        t,
        name,
        mapVerticalColumns,
        resourceKey,
        columns,
        metaData,
        tree,
        verticalPropertyPaths,
        dispatch,
        dbId,
        onClose
    ]);

    const validNext = React.useMemo(() => {
        const keys = Object.keys(propertyPaths);
        return keys?.some(key => propertyPaths?.[key] === 1);
    }, [propertyPaths]);

    const fileName = React.useMemo(() => {
        return files?.[0]?.name;
    }, [files]);

    // const handleDeselectAll  = React.useCallback(() => {

    // }, [])

    const contentRender = React.useCallback(() => {
        switch (currentStep) {
            case STEPS.PREVIEW:
                return (
                    <Grid container className={classes.root} direction="column" wrap="nowrap" spacing={4}>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item container alignItems="center" direction="row">
                                <Grid item xs={3}>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_1')}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">JSON import mode</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    <RadioGroup
                                        aria-label="quiz"
                                        name="quiz"
                                        row
                                        value={importType}
                                        onChange={handleImportTypeChange}
                                    >
                                        <Grid container spacing={4} alignItems="center" direction="row" wrap="nowrap">
                                            <Grid item>
                                                <FormControlLabel
                                                    disabled={isUploading}
                                                    className={classes.formControl}
                                                    value={IMPORT_TYPES.HORIZONTAL}
                                                    control={<Radio size="small" />}
                                                    label={<Typography variant="body2">JSON to records</Typography>}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    disabled={isUploading}
                                                    className={classes.formControl}
                                                    value={IMPORT_TYPES.VERTICAL}
                                                    control={<Radio size="small" />}
                                                    label={
                                                        <Grid container direction="row" alignItems="center" spacing={2}>
                                                            <Grid item>
                                                                <Typography variant="body2">JSON to columns</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" spacing={2} alignItems="center">
                                    <Grid item className={classes.numberStep}>
                                        {t('global_2')}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">Strings to be translated</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">|</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">350 strings selected</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container alignItems="center" direction="row" spacing={3}>
                                    <Grid item className={classes.haft}>
                                        <Grid className={classes.treeBox} wrap="nowrap" container direction="column">
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                className={classes.treeBoxHeader}
                                                alignItems="center"
                                                justify="space-between"
                                            >
                                                <Grid item>
                                                    <Typography variant="body1">Key</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.treeBoxContent}>
                                                {isUploading ? (
                                                    <Grid
                                                        item
                                                        className={`${classes.spinnerWrapper} ${
                                                            isUploading ? classes.uploading : ''
                                                        }`}
                                                    >
                                                        <Spinner size={18} thick={3} />
                                                    </Grid>
                                                ) : (
                                                    <JSONTree
                                                        onNodeChanges={onNodeChanges}
                                                        propertyPaths={propertyPaths}
                                                        tree={tree}
                                                        isVertical={importType === IMPORT_TYPES.VERTICAL}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.haft}>
                                        <Grid container direction="column" wrap="nowrap" className={classes.treeBox}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                className={classes.treeBoxHeader}
                                                alignItems="center"
                                                wrap="nowrap"
                                            >
                                                <Grid item className={classes.fileIcon}>
                                                    <FileSVG />
                                                </Grid>
                                                <Grid item className={classes.fileName}>
                                                    <Typography className={classes.ellipsis} variant="body1">
                                                        {fileName || 'Default File'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.treeBoxContent}>
                                                <JsonEditor
                                                    value={beautifyJSONValue(JSON.stringify(jsonPreviewData))}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {importType === IMPORT_TYPES.VERTICAL && (
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_3')}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">Column mapping</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ width: '100%', height: 300 }}>
                                    <AutoSizer>
                                        {({ width, height }) => {
                                            return (
                                                <GridUITable
                                                    // isUseColumnIdByIndex={true}
                                                    metaData={metaData}
                                                    totalRecords={records?.length}
                                                    data={data}
                                                    rows={records}
                                                    columns={columns}
                                                    width={width}
                                                    maxHeight={height}
                                                    rowHeight={ROW_HEIGHT}
                                                    fixedColumnCount={0}
                                                    fixedRowCount={1}
                                                    AGG_HEIGHT={0}
                                                    headerRenderer={headerProps => {
                                                        return (
                                                            <ImportHeader
                                                                columnId={columns?.[headerProps?.columnIndex]}
                                                                metaData={metaData}
                                                                onColumnChange={handleColumnChange}
                                                                {...headerProps}
                                                            />
                                                        );
                                                    }}
                                                    nonHeader
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                </Grid>
                            </Grid>
                        )}
                        {importType === IMPORT_TYPES.HORIZONTAL && (
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_3')}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">Other information (optional)</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={3} direction="row" alignItems="center">
                                        <Grid item xs={4}>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item container direction="row" spacing={2}>
                                                    <Grid item>
                                                        <Typography variant="body1">Object ID</Typography>
                                                    </Grid>
                                                    <Grid item className={classes.displayFlex}>
                                                        <Tooltip title="By mapping the Object IDs, you'll able to update the JSON file and import it back into the Grid without affecting the in-progress translation.">
                                                            <Grid container alignItems="center">
                                                                <QuestionSVG />
                                                            </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <LDBasePortal
                                                        ddPlaceholder={'Select key'}
                                                        menuPlaceholder={'Find a key'}
                                                        options={objectIdsOptions}
                                                        onChange={handleObjectKeyChange}
                                                        defaultValue={objectKey}
                                                        isMulti={false}
                                                        isDisabled={isUploading}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">Additional information</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <LDBasePortal
                                                        ddPlaceholder={'Select key'}
                                                        menuPlaceholder={'Find a key'}
                                                        options={objectIdsOptions?.filter(
                                                            option => option?.value !== objectKey?.value
                                                        )}
                                                        onChange={handleAdditionalInformationChange}
                                                        defaultValue={additionalInformation}
                                                        isMulti={false}
                                                        isDisabled={isUploading}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">Character limit</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <LDBasePortal
                                                        ddPlaceholder={'Select key'}
                                                        menuPlaceholder={'Find a key'}
                                                        options={objectIdsOptions?.filter(
                                                            option => option?.value !== objectKey?.value
                                                        )}
                                                        onChange={handleCharacterLimitChange}
                                                        defaultValue={characterLimit}
                                                        isMulti={false}
                                                        isDisabled={isUploading}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                );
            case STEPS.SETUP_LOCALIZATION:
                return (
                    <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                        <Grid item container alignItems="center" direction="row">
                            <Grid item xs={3}>
                                <Grid container direction="row" spacing={2} alignItems="center">
                                    <Grid item className={classes.numberStep}>
                                        {t('global_3')}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">{t('localization_grid_name_label')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <InputText
                                    placeholder={t('localization_grid_name_input')}
                                    name="projectName"
                                    value={name}
                                    error={!name}
                                    // errorText={t('global_this_field_is_required')}
                                    // disabled={isSubmitting}
                                    onChange={e => setName(e.target.value)}
                                    autoFocus={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container alignItems="center" direction="row">
                            <Grid item xs={3}>
                                <Grid container direction="row" spacing={2} alignItems="center">
                                    <Grid item className={classes.numberStep}>
                                        {t('global_4')}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">{t('localization_project_type_label')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <RadioGroup
                                    aria-label="quiz"
                                    name="quiz"
                                    row
                                    value={isTypeMultiple}
                                    onChange={handleModeChange}
                                >
                                    <Grid container spacing={4} alignItems="center" direction="row" wrap="nowrap">
                                        <Grid item>
                                            <FormControlLabel
                                                className={classes.formControl}
                                                value={LOCALIZATION_TYPES.SINGLE}
                                                control={<Radio size="small" />}
                                                label={
                                                    <Typography variant="body2">
                                                        {t('localization_project_type_single')}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                className={classes.formControl}
                                                value={LOCALIZATION_TYPES.MULTIPLE}
                                                control={<Radio size="small" />}
                                                label={
                                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                {t('localization_project_type_multi')}
                                                            </Typography>
                                                        </Grid>
                                                        <Tooltip
                                                            title={
                                                                <Grid
                                                                    className={classes.multiStepExplain}
                                                                    container
                                                                    direction="column"
                                                                    spacing={2}
                                                                >
                                                                    <Grid item>
                                                                        <MultiStepIllustratorSVG
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item spacing={1} direction="column" container>
                                                                        <Grid item>
                                                                            <Typography variant="overline">
                                                                                {t(`localization_project_type_multi`)}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="caption">
                                                                                {t(
                                                                                    `localization_project_type_multi_explain`
                                                                                )}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        >
                                                            <Grid item className={classes.dlFlex}>
                                                                <QuestionSVG />
                                                            </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid item container alignItems="center" direction="row">
                            <Grid item xs={3}>
                                <Grid container direction="row" spacing={2} alignItems="center">
                                    <Grid item className={classes.numberStep}>
                                        {t('global_5')}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">{t('global_select_source_lang')}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                {isModeSingle ? (
                                    <Grid item xs={12} md={5}>
                                        <LDBasePortalVirtualize
                                            ddPlaceholder={t('global_languague_select')}
                                            menuPlaceholder={t('global_languague_search')}
                                            options={langOptions}
                                            onChange={handleSourceChange}
                                            defaultValue={sources?.[0]}
                                            isMulti={false}
                                            isDisabled={isUploading}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item className={`${classes.fullWidth} ${classes.box}`}>
                                        <Grid container alignItems="center" direction="row" wrap="nowrap">
                                            {sources?.map((source, index) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            item
                                                            className={classes.w170}
                                                            key={source?.value || index}
                                                        >
                                                            <LDBasePortalVirtualize
                                                                ddPlaceholder={t('global_languague_select')}
                                                                menuPlaceholder={t('global_languague_search')}
                                                                options={langOptions}
                                                                onChange={option =>
                                                                    handleMultiSourceChange({ option, index })
                                                                }
                                                                defaultValue={source}
                                                                isMulti={false}
                                                                isCreatable={true}
                                                                onCreateNew={() => handleMultiSourceRemove(index)}
                                                                createText={t('global_delete')}
                                                                createIcon={
                                                                    <DeleteSVG color={theme.palette.primary.main} />
                                                                }
                                                                isDisabled={isUploading}
                                                            />
                                                        </Grid>
                                                        {index !== sources?.length && (
                                                            <Grid item className={classes.split}>
                                                                <PathSVG />
                                                            </Grid>
                                                        )}
                                                    </>
                                                );
                                            })}

                                            <Grid item>
                                                <Grid
                                                    className={classes.addMoreSource}
                                                    container
                                                    alignItems="center"
                                                    direction="row"
                                                    justify="center"
                                                    onClick={handleAddSource}
                                                >
                                                    <Grid item className={classes.dlFlex}>
                                                        <ColorAddSVG />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            {t('localization_add_more_source')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={3}>
                            <Grid item container alignItems="center" direction="row" justify="space-between">
                                <Grid item>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_6')}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">{t('global_select_target_lang')}</Typography>
                                        </Grid>
                                        <Grid item>|</Grid>
                                        <Grid item>
                                            <Typography variant="caption">
                                                {t('localization_selected_langs_note', {
                                                    selectedLangs: validSelectedTargetOptions.length,
                                                    total: langOptions.length
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Search
                                        className={classes.search}
                                        onChange={e => setFilterValue(e.target.value)}
                                        background={theme.colors.paleGrey}
                                        placeholder={t('localization_target_search')}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid item className={classes.targetList}>
                                    <AutoSizer>
                                        {({ width, height }) => {
                                            return (
                                                <List
                                                    className={classes.virtualizeList}
                                                    rowCount={Math.ceil(targetOptions?.length / ITEMS_PER_ROW)}
                                                    rowHeight={42}
                                                    width={width}
                                                    height={height}
                                                    noRowsRenderer={_noRowsRenderer}
                                                    rowRenderer={_rowRenderer}
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );

            default:
                return null;
        }
    }, [
        columns,
        handleColumnChange,
        data,
        records,
        metaData,
        importType,
        handleImportTypeChange,
        objectIdsOptions,
        validSelectedTargetOptions,
        handleMultiSourceChange,
        isModeSingle,
        isTypeMultiple,
        langOptions,
        handleSourceChange,
        handleMultiSourceRemove,
        sources,
        name,
        theme,
        targetOptions,
        _noRowsRenderer,
        _rowRenderer,
        handleAddSource,
        handleObjectKeyChange,
        handleModeChange,
        characterLimit,
        objectKey,
        additionalInformation,
        classes,
        currentStep,
        t,
        handleCharacterLimitChange,
        handleAdditionalInformationChange,
        isUploading,
        tree,
        propertyPaths,
        onNodeChanges,
        jsonPreviewData,
        fileName
    ]);

    return (
        <>
            <DialogTitle title={t('import_data')} onClose={onClose} />
            <DialogContent>{contentRender()}</DialogContent>
            <DialogActions>
                <Grid item container justify="space-between" spacing={2} alignItems="center">
                    <Grid item>
                        {currentStep === STEPS.SETUP_LOCALIZATION && (
                            <ButtonBase onClick={onBack} variant="outlined" width={120}>
                                {t('global_back')}
                            </ButtonBase>
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item>
                                <ButtonBase onClick={onClose} variant="outlined" width={120}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                {currentStep === STEPS.SETUP_LOCALIZATION ? (
                                    <div className={classes.btnSubmit}>
                                        <ButtonBase
                                            disabled={!isCreateValid || isSubmitting}
                                            onClick={handleCreateLocalizationGrid}
                                            variant="contained"
                                            width={120}
                                        >
                                            {t('global_create')}
                                        </ButtonBase>
                                        {isSubmitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                ) : importType === IMPORT_TYPES.VERTICAL ? (
                                    <div className={classes.btnSubmit}>
                                        <ButtonBase
                                            disabled={!validNext || isUploading}
                                            onClick={handleCreateVerticalJsonGrid}
                                            variant="contained"
                                            width={120}
                                        >
                                            {t('global_create')}
                                        </ButtonBase>
                                        {isSubmitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                ) : (
                                    <ButtonBase
                                        disabled={!validNext || isUploading}
                                        onClick={handleNext}
                                        variant="contained"
                                        width={120}
                                    >
                                        {t('global_next')}
                                    </ButtonBase>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(LocalizationImportSetup);
