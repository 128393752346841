import React from 'react';

const TMIconSVG = props => {
    return (
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1V8C14 8.55229 13.5523 9 13 9H1C0.447716 9 0 8.55229 0 8V1Z"
                fill="#7869B9"
            />
            <path d="M6.10381 2.83448H4.56055V7H3.52249V2.83448H2V2H6.10381V2.83448Z" fill="white" />
            <path
                d="M8.05536 2L9.34948 5.62637L10.6367 2H12V7H10.9585V5.63324L11.0623 3.27404L9.70242 7H8.98962L7.63322 3.27747L7.73702 5.63324V7H6.69896V2H8.05536Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(TMIconSVG);
