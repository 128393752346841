import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getCompanyLogoUrl } from 'utils/images';
import Avatar from 'components/avatar/Company';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 8,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.paleNavy,
            borderRadius: 4
        }
    },
    name: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));

function CompanyItem({ company, onClick }) {
    const classes = useStyles();

    const handleClick = event => {
        onClick && onClick(company);
    };

    return (
        <Grid item className={`${classes.root} company-item`} onClick={handleClick}>
            <Grid container alignItems="center" spacing={3} wrap="nowrap">
                <Grid item>
                    <Avatar size={45} src={getCompanyLogoUrl(company?.logoUrl)} label={company?.name} />
                </Grid>
                <Grid item className={classes.name}>
                    {company.name}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(CompanyItem);
