import store from 'store/configStore';
import * as types from 'socket/types';
import * as viewActions from 'gridUI/actions/views';

const { dispatch } = store;

const viewListHandler = ({ body }) => {
    const { subAction, data, viewId } = body;
    switch (subAction) {
        case types.UPDATE:
            return _updateView({ view: data });
        case types.DELETE:
            return _deleteView({ viewId });
        case types.CREATE:
            return _createView({ view: data });
        default:
            return;
    }
};

function _updateView({ view }) {
    dispatch(viewActions.updateViewAction({ view }));
}

function _createView({ view }) {
    dispatch(viewActions.createViewRealtimeSuccessAction({ view }));
}

function _deleteView({ viewId }) {
    dispatch(viewActions.deleteViewRealtimeAction({ viewId }));
}

export default viewListHandler;
