export function addEmojiInText({ text, idx, emoji }) {
    let cloneText = [...text];
    cloneText.splice(idx, 0, emoji);
    return cloneText?.join('');
}

export function getCaretPositionWithoutEmoji({ input, idx }) {
    const value = input?.value;
    let rest = value?.slice(0, idx);
    let arrs = [...rest];
    return arrs?.length || 0;
}
