import { AGILE_API_URL } from 'config';

const ONBOARDING_UTM_SOURCES = {
    index: 'Gridly Index Page',
    developers: 'Gridly Developer',
    locteams: 'Gridly Loc Manager',
    producers: 'Gridly Producer',
    invited: 'Gridly Invited User'
};

export function trackUserEvent({ event, ...data }) {
    fetch(`${AGILE_API_URL}/track`, {
        method: 'POST',
        headers: {
            Accept: 'application/json'
        },
        body: JSON.stringify({
            event,
            ...data
        })
    })
        .then(response => {})
        .catch(err => {});
}

export function trackUserOnboarding({ email, step, isDone, utmSource, userInfo = {}, queryParams = {} }) {
    const from = ONBOARDING_UTM_SOURCES[utmSource];
    const stepString = step ? `Completed Step ${step}` : null;
    const { firstName, lastName, companyName } = userInfo;

    const customProps = {};

    for (const prop in queryParams) {
        if (prop.includes('utm_')) {
            customProps[prop] = queryParams[prop];
        }
    }

    const tags = [];
    if (from) {
        tags.push(from);
    }
    if (stepString) {
        tags.push(`Gridly ${stepString}`);
    }

    const contact = {};
    contact.email = email;
    contact.event = stepString ? `Gridly App - ${stepString}` : '';
    contact.first_name = firstName;
    contact.last_name = lastName;
    contact.company = companyName;

    contact.tags = tags.join(', ');
    contact.customProps = customProps;
    contact.stepStatus = isDone ? 'done' : '';

    fetch(`${AGILE_API_URL}/trackUserOnboarding`, {
        method: 'POST',
        headers: {
            Accept: 'application/json'
        },
        body: JSON.stringify(contact)
    })
        .then(response => {})
        .catch(err => {});
}
