import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getPublicKeyApi } from 'services/payment';

export function fetchPublicKey({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            const publicKey = await getPublicKeyApi();
            dispatch(_fetchPublicKeySuccess(publicKey?.key));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchPublicKeySuccess(publicKey) {
    return {
        type: types.FETCH_PUBLIC_KEY,
        payload: {
            publicKey
        }
    };
}
