import React from 'react';

function H2IconSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" {...other} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1123 17.2021C13.1123 17.6428 12.7551 18 12.3144 18C11.8738 18 11.5166 17.6428 11.5166 17.2021V12.1249H5.99609V17.2022C5.99609 17.6428 5.63888 18 5.19824 18C4.7576 18 4.40039 17.6428 4.40039 17.2021V6.79785C4.40039 6.35721 4.7576 6 5.19824 6C5.63888 6 5.99609 6.35721 5.99609 6.79785V10.7065H11.5166V6.79785C11.5166 6.35721 11.8738 6 12.3144 6C12.7551 6 13.1123 6.35721 13.1123 6.79785V17.2021Z"
                fill={color}
            />
            <path
                d="M15.0938 18.2021H18.9951C19.376 18.2021 19.6055 17.9629 19.6055 17.6162C19.6055 17.2598 19.376 17.0303 18.9951 17.0303H16.3389V17.001L17.8623 15.5361C19.0146 14.4473 19.4346 13.9102 19.4346 13.0508C19.4346 11.8398 18.4287 11 16.9346 11C15.6016 11 14.7178 11.7275 14.4785 12.5088C14.4443 12.6113 14.4248 12.7139 14.4248 12.8262C14.4248 13.1924 14.6592 13.4268 15.0547 13.4268C15.3721 13.4268 15.543 13.29 15.709 12.9971C15.9629 12.4307 16.3535 12.1475 16.9199 12.1475C17.5498 12.1475 17.999 12.5625 17.999 13.1436C17.999 13.6514 17.7744 13.9932 16.9053 14.8281L14.8057 16.835C14.5078 17.1084 14.4004 17.2939 14.4004 17.5723C14.4004 17.9434 14.6299 18.2021 15.0938 18.2021Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(H2IconSVG);
