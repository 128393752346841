import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_AGGREGATION_LIST_SUCCESS](state, { payload }) {
        const { aggregations } = payload;
        return {
            ...state,
            aggregations
        };
    },
    [types.CHANGE_AGGREGATION](state, { payload }) {
        const { columnId, type } = payload;
        const { aggregations } = state;

        const newAggregations = produce(aggregations, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            draft[columnId] = {
                ...draft[columnId],
                isFetching: true,
                type
            };
            return draft;
        });
        return {
            ...state,
            aggregations: newAggregations
        };
    },
    [types.CHANGE_AGGREGATION_SUCCESS](state, { payload }) {
        const { columnId, value } = payload;
        const { aggregations } = state;

        const newAggregations = produce(aggregations, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            draft[columnId] = {
                ...draft[columnId],
                isFetching: false,
                value
            };
            return draft;
        });

        return {
            ...state,
            aggregations: newAggregations
        };
    },
    [types.CHANGE_AGGREGATION_FAILED](state, { payload }) {
        const { columnId, type } = payload;
        const { aggregations } = state;

        const newAggregations = produce(aggregations, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            draft[columnId] = {
                ...draft[columnId],
                isFetching: false,
                type
            };
            return draft;
        });

        return {
            ...state,
            aggregations: newAggregations
        };
    },
    [types.ADD_AGGREGATION_AFTER_NEW_COLUMN_CREATED](state, { payload }) {
        const { columnId, type, value } = payload;
        const { aggregations } = state;

        const newAggregations = produce(aggregations, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            draft[columnId] = {
                ...draft[columnId],
                isFetching: false,
                type,
                value
            };
            return draft;
        });

        return {
            ...state,
            aggregations: newAggregations
        };
    },
    [types.FETCH_VIEW_AGGREGATION_LIST_SUCCESS](state, { payload }) {
        const { viewAggregates } = payload;
        return {
            ...state,
            viewAggregates
        };
    },
    [types.CREATE_VIEW_AGGREGATE_SUCCESS](state, { payload }) {
        const { aggregate } = payload;
        const { viewAggregates } = state;

        return {
            ...state,
            viewAggregates: [...viewAggregates, aggregate]
        };
    },
    [types.UPDATE_VIEW_AGGREGATE_SUCCESS](state, { payload }) {
        const { aggregate, aggregateId } = payload;
        const { viewAggregates } = state;
        const newViewAggregates = produce(viewAggregates, draft => {
            return draft?.map(agg => {
                if (agg?.id === aggregateId) {
                    return aggregate;
                }
                return agg;
            });
        });
        return {
            ...state,
            viewAggregates: newViewAggregates
        };
    },
    [types.DELETE_VIEW_AGGREGATE_BY_COLUMN_ID](state, { payload }) {
        const { columnId } = payload;
        const { viewAggregates } = state;

        const newViewAggregates = viewAggregates?.filter(aggregate => aggregate?.columnId !== columnId);

        return {
            ...state,
            viewAggregates: newViewAggregates
        };
    },
    [types.REGISTER_AGGREGATION](state, { payload }) {
        const { columnId, aggregate } = payload;
        const { aggregations } = state;

        const newAggregations = produce(aggregations, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            draft[columnId] = {
                ...draft[columnId],
                ...aggregate
            };
            return draft;
        });

        return {
            ...state,
            aggregations: newAggregations
        };
    }
};

export default handler;
