import React from 'react';

function UniquePathtagRecordIdSVG({ ...rest }) {
    return (
        <svg {...rest} width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="18" rx="9" fill="#F9772A" />
            <path
                d="M22.6813 15.6956H9.31868C8.61538 15.6956 8 15.0803 8 14.377V3.65169C8 2.94839 8.61538 2.33301 9.31868 2.33301H13.2747C13.6264 2.33301 13.978 2.50883 14.2418 2.77257L15.7363 4.35499H22.6813C23.3846 4.35499 24 4.97037 24 5.67367V14.377C24 15.0803 23.3846 15.6956 22.6813 15.6956ZM13.2747 3.65169H9.31868V14.377H22.6813V5.58576H15.1209L13.2747 3.65169Z"
                fill="white"
            />
            <path
                d="M14.366 12.7289H14.431V12.6639V12.2878V12.2359L14.3803 12.2244L13.8234 12.0985V8.07207L14.381 7.93932L14.431 7.92743V7.87608V7.5V7.435H14.366H12.5H12.435V7.5V7.87608V7.92743L12.4849 7.93932L13.0425 8.07207V12.0985L12.4857 12.2244L12.435 12.2359V12.2878V12.6639V12.7289H12.5H14.366ZM18.8282 12.0534L18.8282 12.0534C19.3229 11.5937 19.565 10.9157 19.565 10.0313C19.565 9.46911 19.4599 8.99461 19.2458 8.61118C19.0323 8.22381 18.7286 7.93008 18.336 7.73131C17.9442 7.53293 17.4829 7.435 16.9542 7.435H15.3631H15.2981V7.5V12.6639V12.7289H15.3631H16.8023C17.662 12.7289 18.3403 12.5065 18.8282 12.0534ZM18.2545 8.58842L18.2545 8.58843L18.2552 8.58907C18.5791 8.89015 18.7479 9.37297 18.7479 10.053C18.7479 10.7273 18.5759 11.2212 18.2431 11.5471C17.9101 11.8732 17.405 12.042 16.7155 12.042H16.079V8.12189H16.8602C17.4711 8.12189 17.9326 8.28037 18.2545 8.58842Z"
                fill="white"
                stroke="white"
                strokeWidth="0.13"
            />
        </svg>
    );
}

export default React.memo(UniquePathtagRecordIdSVG);
