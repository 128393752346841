import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import FileSVG from 'assets/images/svg/FileSVG';
import Tooltip from 'components/tooltip/Base';
import { useFormik } from 'formik';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import { importTerms } from 'gridUI/actions';
import Select from 'components/selects/Select';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPaper-root': {
            width: `100%`,
            maxWidth: `754px !important`
        }
    },
    flx: {
        flex: 1
    },
    closeIcon: {
        verticalAlign: 'middle'
    },
    header: {
        padding: `16px 16px 0`
    },
    fileNameWrapper: {
        margin: '0 14px'
    },
    fileName: {
        width: 'fit-content',
        padding: '6px 10px',
        border: `1px solid ${theme.colors.border}`,
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        '& svg': {
            verticalAlign: 'text-top',
            marginRight: 6
        }
    },
    fName: {
        ...theme.ellipsis()
    },
    container: {
        margin: '32px 16px'
    },
    options: {
        color: theme.colors.midGrey
    },
    mt6: {
        marginTop: 6
    },
    infoIcon: {
        marginLeft: 5,
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    footer: {
        padding: 16,
        border: `1px solid ${theme.colors.lightGrey}`
    },
    dropdown: {
        width: 250
    }
}));

const ImportTBDialog = ({ glossaryId, open, files, onClose, handleResetFetch }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const IMPORT_OPTIONS = [
        { value: 'UPDATE', label: `Add new and update terms` },
        { value: 'UPDATE_ONLY', label: `Update existing terms` },
        { value: 'ADD', label: `Add new terms` }
    ];

    const file = useMemo(() => {
        return files?.[0] || null;
    }, [files]);

    const formik = useFormik({
        initialValues: {
            importOption: IMPORT_OPTIONS?.[0]?.value
        },
        onSubmit: (values, { setSubmitting }) => {
            dispatch(
                importTerms({
                    tbId: glossaryId,
                    file: file,
                    importOption: values.importOption,
                    successCallback: () => {
                        handleResetFetch({ resetPage: true });
                        setSubmitting(false);
                        onClose();
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
        }
    });
    const { values, handleSubmit, isValid, isSubmitting, setFieldValue } = formik;

    const handleClose = useCallback(() => {
        if (isSubmitting) return;
        onClose();
        setFieldValue('importOption', IMPORT_OPTIONS?.[0]?.value);
    }, [onClose, isSubmitting, setFieldValue, IMPORT_OPTIONS]);

    const handleImportOptionChange = React.useCallback(
        option => {
            let importOption = option.value;
            setFieldValue('importOption', importOption);
        },
        [setFieldValue]
    );

    return (
        <Dialog open={open} onClose={handleClose} className={classes.root}>
            <form onSubmit={handleSubmit}>
                <Grid container direction="column">
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.header}
                    >
                        <Grid item className={classes.flx}>
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item>
                                    <Typography variant="h3">Import terms</Typography>
                                </Grid>
                                <Grid item className={classnames(classes.flx, classes.fileNameWrapper)}>
                                    <Tooltip title={file?.name || ''}>
                                        <Grid
                                            item
                                            container
                                            alignItems="center"
                                            wrap="nowrap"
                                            className={classes.fileName}
                                        >
                                            <Grid item>
                                                <FileSVG />
                                            </Grid>
                                            <Grid item className={classes.fName}>
                                                {file?.name}
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIconSVG className={classes.closeIcon} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.container}>
                    <Grid item container alignItems="center" spacing={2} wrap="nowrap">
                        <Grid item>
                            <Typography variant="body1" className={classes.options}>
                                Import options
                            </Typography>
                        </Grid>
                        <Grid item className={classes.dropdown}>
                            <Select
                                options={IMPORT_OPTIONS}
                                value={values.importOption}
                                className={classes.dropdownClassName}
                                border
                                onChange={handleImportOptionChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.footer}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonBase onClick={handleClose} width={150} variant="outlined">
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.submitButton}>
                            <ButtonBase
                                type="submit"
                                disabled={!isValid || isSubmitting}
                                width={150}
                                variant="contained"
                            >
                                Import
                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Dialog>
    );
};

export default React.memo(ImportTBDialog);
