import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import DateTimeInput from 'components/datetime/Base';
import { Grid } from '@material-ui/core';
import { Portal } from 'react-overlays';
import { DATETIME } from 'const/columnTypes';
import { INPUT_QUICK_FILTER_HEIGHT } from 'const/style';
import { OPERATOR } from 'gridUI/conditions';
import classNames from 'classnames';

const DEBOUNCE_TIME = 500;

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children}</Portal>;
};

function DatetimeQuickFilter({ columnId, quickFilters, isDisabled, t, ...rest }) {
    const dispatch = useDispatch();
    const timer = useRef(0);

    const dateTime = useMemo(() => {
        return quickFilters?.[columnId]?.value || null;
    }, [quickFilters, columnId]);

    const handleDateTimeChange = date => {
        dispatch(gridActions.QuickFilterChange({ columnId, value: date, type: DATETIME, operator: OPERATOR.between }));
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(function() {
            dispatch(
                gridActions.fetchRecordsWithFiltersAndSorts({
                    errorCallback: () => {
                        console.log('failed to filter');
                    },
                    successCallback: () => {
                        console.log('filter successfully');
                    }
                })
            );
        }, DEBOUNCE_TIME);
    };

    const handleInputClick = () => {
        dispatch(gridActions.resetCellStatus());
    };

    return (
        <Grid
            className={classNames(`!h-[${INPUT_QUICK_FILTER_HEIGHT}px]`, {
                disabled: isDisabled
            })}
        >
            <DateTimeInput
                placeholder={t('global_label_pick_a_date')}
                selectedDate={dateTime}
                autoFocus={false}
                utcOffset={0}
                isShowTimeSelect={false}
                onEnter={handleDateTimeChange}
                popperContainer={CalendarContainer}
                onInputClick={handleInputClick}
                isClearable
                className={`pl-3.5 pr-[2px] !h-[26px] text-xs !border text-text-primary rounded bg-grey-pale focus:outline-none !focus:border-brand-main !placeholder:text-grey-mid`}
            />
        </Grid>
    );
}

export default React.memo(DatetimeQuickFilter);
