import { REGISTER_DOING_ACTION, REMOVE_DOING_ACTION } from '../types';

export function registerDoingAction({ actionId }) {
    return {
        type: REGISTER_DOING_ACTION,
        payload: {
            actionId
        }
    };
}

export function removeDoingAction({ actionId }) {
    return {
        type: REMOVE_DOING_ACTION,
        payload: {
            actionId
        }
    };
}
