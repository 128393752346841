import React from 'react';

function EditGroupSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.25 2C9.25 1.58579 9.58579 1.25 10 1.25H13.9998H14C14.4142 1.25 14.75 1.58579 14.75 2V6C14.75 6.41421 14.4142 6.75 14 6.75C13.5858 6.75 13.25 6.41421 13.25 6V3.8105L7.19683 9.86366C6.90394 10.1566 6.42907 10.1566 6.13617 9.86366C5.84328 9.57077 5.84328 9.0959 6.13617 8.803L12.1892 2.75H10C9.58579 2.75 9.25 2.41421 9.25 2ZM3.33333 4.75C3.17862 4.75 3.03025 4.81146 2.92085 4.92085C2.81146 5.03025 2.75 5.17862 2.75 5.33333V12.6667C2.75 12.8214 2.81146 12.9697 2.92085 13.0791C3.03025 13.1885 3.17862 13.25 3.33333 13.25H10.6667C10.8214 13.25 10.9697 13.1885 11.0791 13.0791C11.1885 12.9697 11.25 12.8214 11.25 12.6667V8.66667C11.25 8.25245 11.5858 7.91667 12 7.91667C12.4142 7.91667 12.75 8.25245 12.75 8.66667V12.6667C12.75 13.2192 12.5305 13.7491 12.1398 14.1398C11.7491 14.5305 11.2192 14.75 10.6667 14.75H3.33333C2.7808 14.75 2.25089 14.5305 1.86019 14.1398C1.46949 13.7491 1.25 13.2192 1.25 12.6667V5.33333C1.25 4.7808 1.46949 4.2509 1.86019 3.86019C2.2509 3.46949 2.7808 3.25 3.33333 3.25H7.33333C7.74755 3.25 8.08333 3.58579 8.08333 4C8.08333 4.41421 7.74755 4.75 7.33333 4.75H3.33333Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(EditGroupSVG);
