import React from 'react';
import { ROW_HEIGHT, HIGHLIGHT_TYPES } from 'const/gridUI';
import Highlighter from 'components/highlighter';
import isArray from 'lodash/isArray';
import { isRefImage } from 'utils/gridUI/reference';

/**
 * THIS CSS USING FOR TEXT WRAPPING, PLEASE DON'T REMOVE ONE OF THEM
 */
function AlterByRender({
    value = '',
    rowHeight = ROW_HEIGHT,
    rowIndex,
    columnIndex,
    filterValue,
    ldUserEmailMap,
    isCaseSensitive
}) {
    let convertedData = '';

    if (typeof value === 'boolean') {
        convertedData = value ? 'true' : 'false';
    }

    if (typeof value === 'string' || typeof value === 'number') {
        convertedData = value?.toString();
    }

    if (typeof value === 'object') {
        const isRefData = value?.some(
            option => option.hasOwnProperty('referencedDataItem') && option.hasOwnProperty('referencedRecordId')
        );
        const isImageData = isRefImage(value);
        if (isRefData || isImageData || !isArray(value)) return null;

        convertedData = value?.join(',');
    }

    const isLdUser = ldUserEmailMap?.[convertedData?.trim()];

    return (
        <p
            className={`body2 cell_${rowIndex}_${columnIndex} pointer-events-none w-full leading-6 overflow-hidden text-ellipsis whitespace-pre-wrap table-cell align-top text-center break-words`}
            style={{
                height: rowHeight
            }}
        >
            {isLdUser ? (
                'Translator'
            ) : (
                <Highlighter
                    highlightClassName="highlight"
                    searchWords={[{ key: filterValue, type: HIGHLIGHT_TYPES.DEFAULT }]}
                    autoEscape={true}
                    textToHighlight={convertedData + '' || ''}
                    caseSensitive={!!isCaseSensitive}
                />
            )}
        </p>
    );
}

export default React.memo(AlterByRender);
