import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        width: 440,
        background: theme.colors.white,
        borderRadius: 4
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function ConfirmTransferInternal({ onCancel, onAgree, isSubmitting }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container wrap="nowrap" direction="column" spacing={4} className={classes.root}>
            <Grid item>
                <Typography variant="h4">{t('confirm_transfer_internal_title')}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" display="inline">
                    Are you sure you want to transfer this member to{' '}
                </Typography>
                <Typography variant="body1" display="inline">
                    LD internal member?
                </Typography>
            </Grid>
            <Grid item>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase onClick={onCancel} width={100} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <ButtonBase onClick={onAgree} width={100} variant="contained" disabled={isSubmitting}>
                            {t('global_transfer')}
                        </ButtonBase>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default React.memo(ConfirmTransferInternal);
