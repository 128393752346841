import * as types from '../types';
import uuidv1 from 'uuid/v1';

const handler = {
    [types.UNDO_CHANGE_CELL_INFO](state, { payload }) {
        const stackId = uuidv1();
        const {
            undoValue,
            redoValue,
            columnId,
            rowId,
            undoRowHeight,
            redoRowHeight,
            isExpandRow,
            isSettingReference
        } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_CHANGE_CELL_INFO,
            body: {
                value: undoValue,
                columnId,
                rowId,
                rowHeight: undoRowHeight,
                isExpandRow,
                isSettingReference
            }
        };

        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_CHANGE_CELL_INFO,
            body: {
                value: redoValue,
                columnId,
                rowId,
                rowHeight: redoRowHeight,
                isExpandRow,
                isSettingReference
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_CHANGE_CELL_COPY](state, { payload }) {
        const stackId = uuidv1();
        const { data, oldData, affectedRowIds, columnIds } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_CHANGE_CELL_COPY,
            body: {
                data: oldData,
                affectedRowIds,
                columnIds
            }
        };

        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_CHANGE_CELL_COPY,
            body: {
                data,
                affectedRowIds,
                columnIds
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_RANGE_CELL_DELETE](state, { payload }) {
        const stackId = uuidv1();
        const { undoData, redoData, columnIds } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_RANGE_CELL_DELETE,
            body: {
                data: undoData,
                columnIds,
                isUndo: true
            }
        };

        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_RANGE_CELL_DELETE,
            body: {
                data: redoData,
                columnIds,
                isUndo: false
            }
        };

        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_PASTE_RANGE_CELL](state, { payload }) {
        const stackId = uuidv1();
        const {
            undoSelectionData,
            redoSelectionData,
            columnIds,
            undoRecordMetadata,
            redoRecordMetadata,
            isExpand,
            rowIds,
            isSettingReference,
            refColumnId
        } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_PASTE_RANGE_CELL,
            body: {
                undoSelectionData,
                redoSelectionData,
                columnIds,
                isUndo: true,
                recordMetaData: undoRecordMetadata,
                isExpand,
                rowIds,
                isSettingReference,
                refColumnId
            }
        };
        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_PASTE_RANGE_CELL,
            body: {
                undoSelectionData,
                redoSelectionData,
                columnIds,
                isUndo: false,
                recordMetaData: redoRecordMetadata,
                isExpand,
                rowIds,
                isSettingReference,
                refColumnId
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_CELL_COLOR_FORMATTING](state, { payload }) {
        const stackId = uuidv1();
        const { recordIds, undoData, redoData, columnIds } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_CELL_COLOR_FORMATTING,
            body: {
                recordIds,
                data: undoData,
                columnIds
            }
        };
        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_CELL_COLOR_FORMATTING,
            body: {
                recordIds,
                data: redoData,
                columnIds
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_FREEZING_POSITION](state, { payload }) {
        const stackId = uuidv1();
        const { undoIndex, redoIndex } = payload;
        const undoBody = {
            type: types.UNDO_REDO_VIEW,
            action: types.UNDO_FREEZING_POSITION,
            body: {
                index: undoIndex,
                oldIndex: redoIndex
            }
        };
        const redoBody = {
            type: types.UNDO_REDO_VIEW,
            action: types.REDO_FREEZING_POSITION,
            body: {
                index: redoIndex,
                oldIndex: undoIndex
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },

    [types.UNDO_DELETE_RECORD](state, { payload }) {
        const stackId = uuidv1();
        const { recordIds } = payload;
        const undoBody = {
            type: types.UNDO_REDO_RECORD,
            action: types.UNDO_DELETE_RECORD,
            body: {
                recordIds
            }
        };
        const redoBody = {
            type: types.UNDO_REDO_RECORD,
            action: types.REDO_DELETE_RECORD,
            body: {
                recordIds
            }
        };

        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },

    [types.UNDO_RESIZE_RECORD](state, { payload }) {
        const stackId = uuidv1();
        const { rowIds, undoRecordMetaData, redoRecordMetaData } = payload;
        const undoBody = {
            type: types.UNDO_REDO_RECORD,
            action: types.UNDO_RESIZE_RECORD,
            body: {
                rowIds,
                recordMetaData: undoRecordMetaData
            }
        };
        const redoBody = {
            type: types.UNDO_REDO_RECORD,
            action: types.REDO_RESIZE_RECORD,
            body: {
                rowIds,
                recordMetaData: redoRecordMetaData
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },

    [types.UNDO_DELETE_COLUMN](state, { payload }) {
        const stackId = uuidv1();
        const { columnId } = payload;
        const undoBody = {
            type: types.UNDO_REDO_COLUMN,
            action: types.UNDO_DELETE_COLUMN,
            body: {
                columnId
            }
        };
        const redoBody = {
            type: types.UNDO_REDO_COLUMN,
            action: types.REDO_DELETE_COLUMN,
            body: {
                columnId
            }
        };
        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },

    [types.UNDO_RESIZE_COLUMN](state, { payload }) {
        const stackId = uuidv1();
        const { columnId, undoColumnWidth, redoColumnWidth } = payload;
        const undoBody = {
            type: types.UNDO_REDO_COLUMN,
            action: types.UNDO_RESIZE_COLUMN,
            body: {
                columnId,
                columnWidth: undoColumnWidth
            }
        };

        const redoBody = {
            type: types.UNDO_REDO_COLUMN,
            action: types.REDO_RESIZE_COLUMN,
            body: {
                columnId,
                columnWidth: redoColumnWidth
            }
        };

        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_TM_STATUS](state, { payload }) {
        const stackId = uuidv1();
        const { rowIds, columnIds, clearedData } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_TM_STATUS,
            body: {
                rowIds,
                columnIds,
                clearedData,
                isUndo: true
            }
        };

        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_TM_STATUS,
            body: {
                rowIds,
                columnIds,
                clearedData,
                isUndo: false
            }
        };

        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },
    [types.UNDO_PATH_TAG_DATA](state, { payload }) {
        const stackId = uuidv1();
        const { recordIds, columnId, undoData, redoData } = payload;
        const undoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.UNDO_PATH_TAG_DATA,
            body: {
                recordIds,
                columnId,
                data: undoData,
                isUndo: true
            }
        };

        const redoBody = {
            type: types.UNDO_REDO_CELL,
            action: types.REDO_PATH_TAG_DATA,
            body: {
                recordIds,
                columnId,
                data: redoData,
                isUndo: false
            }
        };

        return {
            ...state,
            undoStack: [...state.undoStack, stackId],
            undoRedoData: {
                ...state.undoRedoData,
                [stackId]: {
                    undoBody,
                    redoBody
                }
            }
        };
    },

    [types.REGISTER_UNDO_TO_JOB_LIST](state, { payload }) {
        const { body } = payload;
        return {
            ...state,
            jobList: [body, ...state.jobList]
        };
    },
    [types.REMOVE_JOB_LIST](state, { payload }) {
        const { stackId } = payload;
        return {
            ...state,
            jobList: [...state.jobList].filter(job => job.stackId !== stackId)
        };
    },
    [types.POP_STACK_ID_OUT_OF_UNDO_STACK](state, { payload }) {
        const { stackId } = payload;
        // const newUndoRedoData = { ...state.undoRedoData };
        // if (newUndoRedoData[stackId]) {
        //     delete newUndoRedoData[stackId];
        // }
        return {
            ...state,
            undoStack: [...state.undoStack].filter(id => id !== stackId),
            redoStack: [...state.redoStack, stackId]
            // undoRedoData: newUndoRedoData
        };
    },
    [types.POP_STACK_ID_OUT_OF_REDO_STACK](state, { payload }) {
        const { stackId } = payload;
        // const newUndoRedoData = { ...state.undoRedoData };
        // if (newUndoRedoData[stackId]) {
        //     delete newUndoRedoData[stackId];
        // }
        return {
            ...state,
            redoStack: [...state.redoStack].filter(id => id !== stackId),
            undoStack: [...state.undoStack, stackId]
            // undoRedoData: newUndoRedoData
        };
    },
    [types.RESET_REDO_STACK](state, { payload }) {
        return {
            ...state,
            redoStack: []
        };
    },
    [types.REGISTER_UNDO_REDO](state, { payload }) {
        const actionId = uuidv1();
        const { type } = payload;
        const { undoRedoQueue } = state;
        const action = {
            id: actionId,
            type
        };
        return {
            ...state,
            undoRedoQueue: [...undoRedoQueue, action]
        };
    },
    [types.TOGGLE_PROCESSING_UNDO_REDO](state, { payload }) {
        const { isUndoRedoProcessing } = state;
        return {
            ...state,
            isUndoRedoProcessing: !isUndoRedoProcessing
        };
    }
};

export default handler;
