import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SidebarRightLayout from '../layout/rightSidebar';
import { Grid } from '@material-ui/core';
import SyncHistory from './SyncHistory';
import SyncConnection from './SyncConnection';
import Tab from 'components/tabs/Tab';
import Tabs from 'components/tabs/Tabs';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    fullWidth: {
        width: '100%'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    content: {
        flex: 1
    },
    indicator: {
        backgroundColor: theme.palette.primary.main
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontWeight: 400,
        color: theme.colors.primaryText
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));

const TABS = {
    SYNC_CONNECTION: 'SYNC_CONNECTION',
    SYNC_HISTORY: 'SYNC_HISTORY'
};

function SyncConnectionSidebar({ handleClose, branchId }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [tabValue, setTabValue] = React.useState(TABS.SYNC_CONNECTION);
    const tabClasses = useTabStyles();

    const handleChangeTab = React.useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    return (
        <SidebarRightLayout title={t('gridly_connector')} onClose={handleClose}>
            <Grid className={classes.root} direction="column">
                <Grid item>
                    <Tabs
                        textColor="primary"
                        classes={{
                            indicator: classes.indicator
                        }}
                        value={tabValue}
                        onChange={handleChangeTab}
                    >
                        <Tab
                            classes={tabClasses}
                            selected={tabValue === TABS.SYNC_CONNECTION}
                            value={TABS.SYNC_CONNECTION}
                            label={t('sync_connection')}
                        />
                        <Tab
                            classes={tabClasses}
                            selected={tabValue === TABS.SYNC_HISTORY}
                            value={TABS.SYNC_HISTORY}
                            label={t('sync_history')}
                        />
                    </Tabs>
                </Grid>
                <Grid item className={classes.content}>
                    {tabValue === TABS.SYNC_CONNECTION ? (
                        <SyncConnection branchId={branchId} />
                    ) : (
                        <SyncHistory branchId={branchId} />
                    )}
                    {/* <SyncConnection branchId={branchId} /> */}
                </Grid>
            </Grid>
        </SidebarRightLayout>
    );
}

export default React.memo(SyncConnectionSidebar);
