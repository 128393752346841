import React from 'react';

function AudioNextSVG({ color = '#D1D0D6', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M13.3333 1C12.9653 1 12.6667 1.29867 12.6667 1.66667V6.52667L2.99333 1.08533C2.78933 0.970667 2.53467 0.972 2.33067 1.09067C2.12667 1.20933 2 1.42933 2 1.66667V13.6667C2 13.904 2.12667 14.1227 2.33067 14.2427C2.43333 14.3027 2.55067 14.3333 2.66667 14.3333C2.78 14.3333 2.892 14.3053 2.99333 14.248L12.6667 8.80667V13.6667C12.6667 14.0347 12.9653 14.3333 13.3333 14.3333C13.7013 14.3333 14 14.0347 14 13.6667V1.66667C14 1.29867 13.7013 1 13.3333 1Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(AudioNextSVG);
