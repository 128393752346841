import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5),
        width: 500
    },
    content: {
        marginTop: theme.spacing(4)
    },
    action: {
        marginTop: theme.spacing(5)
    },
    cancelButton: {
        '&.MuiButton-root': {
            background: theme.colors.snackBarWarning,
            '&:hover': {
                background: theme.colors.hoverWarningButton
            }
        }
    },
    closeButton: {
        position: 'absolute',
        top: 15,
        right: 15,
        cursor: 'pointer'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function CancelAddon({ onClose, onConfirm, addonName, cancelAt, isSubmitting }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIconSVG />
            </IconButton>
            <Grid item>
                <Typography variant="h3">Cancel {addonName}</Typography>
            </Grid>
            <Grid item className={classes.content}>
                <Typography variant="body2" display="inline">
                    Are you sure you want to cancel {addonName}? If you would like to proceed with canceling
                    subscription, you can still use it until
                </Typography>{' '}
                <Typography variant="body1" display="inline">
                    {cancelAt}
                </Typography>
                <Typography variant="body2" display="inline">
                    , and the function will be locked until you reinstall it.
                </Typography>
            </Grid>
            <Grid item container justify="flex-end" spacing={2} className={classes.action}>
                <Grid item>
                    <Button variant="outlined" width={100} onClick={onClose}>
                        Undo
                    </Button>
                </Grid>
                <Grid item className={classes.buttonWrapper}>
                    <Button
                        disabled={isSubmitting}
                        variant="contained"
                        width={160}
                        className={classes.cancelButton}
                        onClick={onConfirm}
                    >
                        Cancel Subscription
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(CancelAddon);
