import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIsFreePlanAndSeatExceeded, useIsOverRecords, useIsUserBlockedGridByPayment } from 'hooks/payment';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import LDButton from 'components/button/Base';
import ToolTipClickContent from 'components/tooltip/ToolTipClickContent';
import OverlimitTag from 'payment/components/tags/Overlimit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelfHostingLicense } from 'hooks/app';

const useStyles = makeStyles(theme => ({
    upgradeIcon: {
        marginRight: theme.spacing(1)
    },
    mt2: {
        marginTop: theme.spacing(3)
    },
    spacing: {
        marginBottom: theme.spacing(1)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    learnMore: {
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 600
        }
    },
    lockedWarning: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 320,
            marginLeft: 6
        }
    },
    explainWarning: {
        padding: theme.spacing(3),
        minWidth: 330
    }
}));

const ManageToolbarLock = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const isOverRecords = useIsOverRecords();
    const isFreePlanAndSeatExceeded = useIsFreePlanAndSeatExceeded();
    const selfHostingLicense = useSelfHostingLicense();
    const isLicenseActive = selfHostingLicense?.status === 'ACTIVE';

    const handleUpgrade = React.useCallback(() => {
        history.push(`/company-settings/billing/overview`);
    }, [history]);

    const handleLearnMore = React.useCallback(() => {
        window.open(`https://www.gridly.com/pricing`, '_blank');
    }, []);

    return (
        <ToolTipClickContent
            className={classes.lockedWarning}
            title={
                <Grid direction="column" container className={classes.explainWarning}>
                    <Grid item className={classes.mb2}>
                        <p className="text-light font-medium">{t(`toolbar_grid_is_locked_because`)}:</p>
                    </Grid>

                    <Grid item className={classes.spacing}>
                        {isOverRecords && (
                            <p className="text-light font-medium">- {t(`toolbar_grid_blocked_by_records_limit`)}</p>
                        )}

                        {isFreePlanAndSeatExceeded && (
                            <p className="text-light font-medium">
                                - {t(`toolbar_grid_blocked_by_free_plan_and_exceeding_member_seats`)}
                            </p>
                        )}

                        {!isLicenseActive && <p variant="overline">- {t('license_expired')}</p>}
                    </Grid>

                    <Grid item className={classes.mt2}>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                                <p
                                    className={'cursor-pointer hover:font-semibold text-white font-medium body2'}
                                    onClick={handleLearnMore}
                                >
                                    {t(`global_learn_more`)}
                                </p>
                            </Grid>
                            <Grid item>
                                <LDButton width={120} variant="contained" onClick={handleUpgrade}>
                                    <UpgradeSVG className={classes.upgradeIcon} /> {t(`global_upgrade`)}
                                </LDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        >
            <Grid item style={{ cursor: 'pointer' }}>
                <OverlimitTag title={t(`global_view_only`)} />
            </Grid>
        </ToolTipClickContent>
    );
};

const ManageToolbarLockWrapper = () => {
    const isUserBlockedGridByPayment = useIsUserBlockedGridByPayment();
    if (!isUserBlockedGridByPayment) return null;
    return <ManageToolbarLock />;
};

export default React.memo(ManageToolbarLockWrapper);
