import React from 'react';
import { Typography, Grid, Collapse, fade } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from './Checkbox';
import { useSpring, animated } from 'react-spring';
import { getAllNodePropertiesPath } from 'utils/gridUI/jsonTree';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        background: 'transparent !important',
        minWidth: 300,
        '&:hover': {
            background: `${theme.colors.paleNavy} !important`
        }
    },
    iconContainer: {
        '& .close': {
            opacity: 0.3
        }
    },
    group: {
        marginLeft: 42,
        paddingLeft: 36,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`
    },
    checkbox: {
        padding: theme.spacing(2)
    },
    moveLeft: {
        position: 'relative',
        left: -25
    }
}));

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` }
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

function StyledTreeItem(props) {
    const classes = useStyles();

    const {
        labelText,
        propertyCount,
        node,
        state,
        parentPropertyPath,
        onNodeChanges,
        siblings,
        propertyPaths,
        disabled,
        ...other
    } = props;

    const isHavingParent = React.useMemo(() => {
        return !!parentPropertyPath;
    }, [parentPropertyPath]);

    const children = React.useMemo(() => {
        return node?.children;
    }, [node]);

    const isHaveChildren = React.useMemo(() => {
        return children?.length > 0;
    }, [children]);

    const stopPropagation = React.useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const isAllSiblingsUnCheck = React.useMemo(() => {
        return !siblings
            ?.filter(siblingNode => siblingNode?.propertyPath !== node?.propertyPath)
            ?.some(siblingNode => {
                const state = propertyPaths?.[siblingNode?.propertyPath];
                return state === 1 || state === 0;
            });
    }, [propertyPaths, node, siblings]);

    const isAllSiblingsChecked = React.useMemo(() => {
        return !siblings
            ?.filter(siblingNode => siblingNode?.propertyPath !== node?.propertyPath)
            ?.some(siblingNode => {
                const state = propertyPaths?.[siblingNode?.propertyPath];
                return state === 0 || state === 2;
            });
    }, [propertyPaths, siblings, node]);

    const handleChange = React.useCallback(
        (e, changedState) => {
            stopPropagation(e);
            const nodes = {
                checkNodeIds: [],
                unCheckNodeIds: [],
                partialNodeIds: []
            };

            if (changedState === 1) {
                if (isHaveChildren) {
                    //is parent -> check all children and itself
                    const allChildNodes = getAllNodePropertiesPath(children);
                    nodes.checkNodeIds = [...allChildNodes, node?.propertyPath];
                } else {
                    //is not parent -> check only itself
                    nodes.checkNodeIds = [node?.propertyPath];
                }
            } else if (changedState === 2) {
                if (isHaveChildren) {
                    //is parent -> uncheck all children and itself
                    const allChildNodes = getAllNodePropertiesPath(children);
                    nodes.unCheckNodeIds = [...allChildNodes, node?.propertyPath];
                } else {
                    nodes.unCheckNodeIds = [node?.propertyPath];
                }

                //is having parent -> reset partial

                if (isHavingParent) {
                    if (isAllSiblingsUnCheck) {
                        nodes.unCheckNodeIds = [...nodes?.unCheckNodeIds, parentPropertyPath];
                    } else {
                        nodes.partialNodeIds = [parentPropertyPath];
                    }
                }
            } else {
                //is parent -> check all children and itself
                if (isHaveChildren) {
                    const allChildNodes = getAllNodePropertiesPath(children);
                    nodes.checkNodeIds = [...allChildNodes, node?.propertyPath];
                } else {
                    nodes.checkNodeIds = [node?.propertyPath];
                }

                //is having parent -> partial parent
                if (isHavingParent) {
                    if (isAllSiblingsChecked) {
                        nodes.checkNodeIds = [...nodes?.checkNodeIds, parentPropertyPath];
                    } else {
                        nodes.partialNodeIds = [parentPropertyPath];
                    }
                }
            }

            onNodeChanges(nodes);
        },
        [
            children,
            isHaveChildren,
            node,
            parentPropertyPath,
            isHavingParent,
            onNodeChanges,
            stopPropagation,
            isAllSiblingsChecked,
            isAllSiblingsUnCheck
        ]
    );

    return (
        <TreeItem
            className={isHaveChildren ? classes.moveLeft : ``}
            label={
                <Grid container wrap="nowrap" direction="row" alignItems="center">
                    <Grid item>
                        <Checkbox
                            disabled={disabled}
                            className={classes.checkbox}
                            state={state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" display="inline">
                            {labelText}
                        </Typography>{' '}
                        <Typography variant="caption" display="inline">
                            ({propertyCount} strings)
                        </Typography>
                    </Grid>
                </Grid>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                iconContainer: classes.iconContainer,
                group: classes.group
            }}
            TransitionComponent={TransitionComponent}
            {...other}
        />
    );
}
export default StyledTreeItem;
