import { TAG_DETECT_MODE } from 'const/gridUI';
import { isValidRegex } from 'utils/regex';

const DEFAULT_STANDARD_MESSAGE_FORMAT_REGEX = [
    '(<.*?>)' /* match html tags <p>, </p> */,
    '(["\']?["$]?\\{.*?\\}["\']?)' /* match {s}, "{s}", '{s}' */,
    // '(["\']?\\$[^\\s|^\\.|^\\,|^\\;]+["\']?)' /* match $s, "$s", '$s' */,
    '(["\']?\\$[a-zA-Z|\\d]+["\']?)' /* match $s, "$s", '$s' */,
    '(["\']?\\%[a-zA-Z|\\d]+["\']?)'
    // '(["\']?\\%[^\\s|^\\.|^\\,|^\\;]+["\']?)' /* match %s, "%s", '%s' */
];

// (<.*?>)|([\"']?\\{.*?\\}[\"']?)|([\"']?\\$[^\\s|^\\.|^\\,|^\\;]+[\"']?)|([\"']?\\%[^\\s|^\\.|^\\,|^\\;]+[\"']?)

function _extractTokensByRegex(text, regex) {
    const re = new RegExp(regex, 'ig');
    return text.match(re) || [];
}

function _escape(s) {
    return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function generateRegex({ tokenDetection, predefinedTokens = [] }) {
    switch (tokenDetection?.mode) {
        case TAG_DETECT_MODE.AUTO:
            return DEFAULT_STANDARD_MESSAGE_FORMAT_REGEX?.join('|');

        case TAG_DETECT_MODE.REGEX: {
            const regex = tokenDetection?.regex;
            if (!regex || !isValidRegex(regex)) return '';
            return regex;
        }

        case TAG_DETECT_MODE.PREDEFINED: {
            if (!tokenDetection?.tokenSetId) return '';
            return predefinedTokens?.map(_escape)?.join('|');
        }
        default:
            return DEFAULT_STANDARD_MESSAGE_FORMAT_REGEX?.join('|');
    }
}

export function generateTags({ tokenDetection, text, predefinedTokens = [] }) {
    if (!text || typeof text !== 'string') return [];
    const regex = generateRegex({ tokenDetection, predefinedTokens });

    if (!regex) return [];
    return _extractTokensByRegex(text, regex);
}

export function isMissingSourceTag({ parentTokens = [], childTokens = [] }) {
    return parentTokens?.some(token => !childTokens?.includes(token));
}
