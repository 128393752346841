import React from 'react';

function LeftBushSVG({ ...other }) {
    return (
        <svg {...other} width="164" height="91" viewBox="0 0 164 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="164" height="91">
                <rect width="164" height="91" rx="4" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <g filter="url(#filter0_f)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M43.8288 183.501C63.5002 195.321 89.2031 189.001 100.946 169.457L136.649 110.039C148.392 90.4945 141.905 64.8332 122.234 53.0134C102.562 41.1936 76.8594 47.5134 65.1162 67.0575L29.4139 126.476C17.6707 146.02 24.1574 171.681 43.8288 183.501Z"
                        fill="#7869B9"
                    />
                    <path
                        d="M43.8288 183.501V183.501C63.5002 195.321 89.2031 189.001 100.946 169.457L136.649 110.039C148.392 90.4945 141.905 64.8332 122.234 53.0134C102.562 41.1936 76.8594 47.5134 65.1162 67.0575L29.4139 126.476C17.6707 146.02 24.1574 171.681 43.8288 183.501"
                        stroke="white"
                        strokeWidth="2"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M122.627 53.25L121.841 52.7777L43.436 183.265L44.2221 183.738L122.627 53.25Z"
                        stroke="white"
                    />
                    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="42" y="52" width="82" height="133">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M122.627 53.25L121.841 52.7777L43.436 183.265L44.2221 183.738L122.627 53.25Z"
                            fill="white"
                            stroke="white"
                        />
                    </mask>
                    <g mask="url(#mask1)"></g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M48.0697 175.836C70.1301 169.51 83.0374 146.402 76.7526 124.484L57.6455 57.8499C51.3608 35.9325 28.1694 23.1756 6.10906 29.5013C-15.9513 35.827 -28.8586 58.935 -22.5738 80.8525L-3.46675 147.487C2.81799 169.404 26.0094 182.161 48.0697 175.836Z"
                        fill="#BDB4E2"
                    />
                    <path
                        d="M48.0697 175.836V175.836C70.1301 169.51 83.0374 146.402 76.7526 124.484L57.6455 57.8499C51.3608 35.9325 28.1694 23.1756 6.10906 29.5013C-15.9513 35.827 -28.8586 58.935 -22.5738 80.8525L-3.46674 147.487C2.81799 169.404 26.0094 182.161 48.0697 175.836"
                        stroke="white"
                        strokeWidth="2"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.55078 29.375L5.66925 29.6278L47.6298 175.962L48.5114 175.709L6.55078 29.375Z"
                        stroke="white"
                    />
                    <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="28" width="45" height="149">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.55078 29.375L5.66925 29.6278L47.6298 175.962L48.5114 175.709L6.55078 29.375Z"
                            fill="white"
                            stroke="white"
                        />
                    </mask>
                    <g mask="url(#mask2)"></g>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_f"
                    x="-33.1641"
                    y="18.8809"
                    width="184.667"
                    height="179.579"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur" />
                </filter>
            </defs>
        </svg>
    );
}

export default React.memo(LeftBushSVG);
