import * as types from '../types';
import ws from 'socket';

export function clearUserActive() {
    return {
        type: types.CLEAR_ACTIVE_USER
    };
}

export function registerRealtimeUser({ actionId, body }) {
    return async function(dispatch, getState) {
        const { app } = getState;
        if (!_isShareLinkMode(app)) {
            dispatch({
                type: types.REGISTER_REALTIME_USER_ACTION,
                payload: {
                    actionId,
                    body
                }
            });
        }
    };
}

export function removeLastActionsFromServer({ actionId }) {
    return async function(dispatch, getState) {
        const { app } = getState;
        if (!_isShareLinkMode(app)) {
            dispatch({
                type: types.REMOVE_REALTIME_USER_ACTION,
                payload: {
                    actionId
                }
            });
        }
    };
}

export function sendCurrentAction(body) {
    return async function(dispatch, getState) {
        const { gridUI, app, auth } = getState();

        if (!_isShareLinkMode(app)) {
            const companyId = auth.companyId || auth.currentUser?.companyId;
            const dbId = gridUI.dbId;
            const branchId = gridUI.branchId;
            const topic = `/topic/c/${companyId}/databases/${dbId}/grids/${branchId}/user_connected`;
            ws.sendMessage({ topic, message: body });
        }
    };
}

export function appendUserToActiveUserList(user) {
    return async function(dispatch, getState) {
        const { app } = getState;
        if (!_isShareLinkMode(app)) {
            dispatch({
                type: types.USER_JOIN,
                payload: {
                    user
                }
            });
        }
    };
}

export function realtimeUserDoing(data) {
    return async function(dispatch, getState) {
        const { app } = getState;
        if (!_isShareLinkMode(app)) {
            dispatch({
                type: types.USER_DOING,
                payload: {
                    data
                }
            });
        }
    };
}

export function realtimeUserLeave(user) {
    return async function(dispatch, getState) {
        const { app } = getState;
        if (!_isShareLinkMode(app)) {
            dispatch({
                type: types.USER_LEAVE,
                payload: {
                    user
                }
            });
        }
    };
}

function _isShareLinkMode(app) {
    return app?.isShareViewLink;
}
