import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width,
        height: props => props.height
    }
}));

const SIZES = {
    small: {
        height: 16,
        width: 16
    },
    medium: {
        width: 24,
        height: 24
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function ArrowRightSVG({ color = '#78778B', className, size = 'small', ...other }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height });

    return (
        <svg
            className={`${classes.root} ${className}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...other}
        >
            <path d="M5.5 11.4453L9.22152 8L5.5 4.55469L6.63924 3.5L11.5 8L6.63924 12.5L5.5 11.4453Z" fill={color} />
        </svg>
    );
}

export default React.memo(ArrowRightSVG);
