import React from 'react';

function CustomSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M15 1H3C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H15C16.1046 17 17 16.1046 17 15V3C17 1.89543 16.1046 1 15 1Z"
                stroke="#78778B"
                strokeWidth="1.5"
            />
            <path
                d="M6.80955 11.6362L6 12.4429L8.90924 15.355L8.93213 15.375L8.95501 15.355L11.8671 12.4429L11.0576 11.6362L9.50425 13.1866V5.18265L11.0576 6.7331L11.8671 5.92354L8.94071 3H8.92354L6 5.92354L6.80955 6.7331L8.36 5.18265V13.1866L6.80955 11.6362Z"
                fill="#78778B"
                stroke="#78778B"
                strokeWidth="0.3"
            />
        </svg>
    );
}

export default React.memo(CustomSVG);
