import React, { useMemo, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';
import TicketItem from './TicketItem';
import YAYSVG from 'assets/images/svg/YAYSVG';
import { LQA_TICKET_STATUS } from 'const/gridUI';
import * as shareViewActions from 'gridUI/actions/shareView';
import { useDispatch } from 'react-redux';
import { useCompositeViewId, useGetLqaTab, useGetLQATicket, useShareViewGroupMembers } from 'hooks/gridUI';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getFullName } from 'utils/name';
import Avatar from 'components/avatar/User';
import { getAvatarUrl } from 'utils/images';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import Tab from 'components/tabs/Tab';
import Tabs from 'components/tabs/Tabs';

const useStyles = makeStyles(theme => ({
    tabList: {
        height: props => (props.isRightSideBar ? 'calc(100% - 68px)' : '511px'),
        overflowY: 'auto',
        width: '100%'
    },
    loadingWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: hexToRgba(theme.colors.white, 0.8)
    },
    rsbEmpty: {
        width: '100%',
        height: '100%'
    },
    empty: {
        width: '100%',
        height: '100%',
        margin: 0,
        '& .empty-title': {
            fontWeight: 500,
            fontSize: '18px'
        }
    },
    w400: {
        fontWeight: 400
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    ticketItem: {
        paddingTop: 24,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        borderBottom: `1px solid ${theme.colors.border}`,
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(3)
        },
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.ghostwhite
        }
    },
    avatarUnassigned: {
        width: '20px',
        height: '20px'
    },
    loadMoreSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }
}));

const TicketTabs = ({
    tabs,
    tab,
    handleChangeTab,
    queryTickets,
    isLoading,
    isLoadingMore,
    isRightSideBar = true,
    onChangeStatus = () => {},
    onChangeAssignee = () => {},
    fetchMore = () => {},
    onClose
}) => {
    const classes = useStyles({ isRightSideBar });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const viewId = useCompositeViewId();
    const members = useShareViewGroupMembers() || [];
    const lqaTab = useGetLqaTab();
    const lqaTicket = useGetLQATicket();
    const listRef = useRef();
    const roles = useRole();

    useEffect(() => {
        dispatch(shareViewActions.fetchGroupsByView(viewId));
    }, [dispatch, viewId]);

    const accessManageTicket = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_TICKET];

    const _empty = useMemo(() => {
        if (queryTickets?.length > 0 || isLoading) return null;
        const title = tab === LQA_TICKET_STATUS.RESOLVED ? t('cell_ticket_no_resolve') : t('cell_ticket_clear_clean');
        const subTitle =
            tab === LQA_TICKET_STATUS.RESOLVED ? t('cell_ticket_no_resolve_sub') : t('cell_ticket_no_ticket');
        return (
            <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                spacing={2}
                className={isRightSideBar ? classes.rsbEmpty : classes.empty}
            >
                <Grid item>
                    <YAYSVG />
                </Grid>
                <Grid item>
                    <h3 className="prose prose-2xl font-nomal empty-title">{title}</h3>
                </Grid>
                <Grid item>
                    <p className="body2 font-nomal text-text-secondary">{subTitle}</p>
                </Grid>
            </Grid>
        );
    }, [queryTickets, isLoading, classes, isRightSideBar, t, tab]);

    const membersOption = React.useMemo(() => {
        let arr = [
            {
                value: '',
                label: 'Unassigned',
                icon: () => <AvatarIconSVG className={classes.avatarUnassigned} color="#DBDCE1" />
            }
        ];
        const mapMembers = (members || []).map(m => ({
            value: m.user.id,
            label: getFullName(m.user),
            icon: () => (
                <Avatar src={getAvatarUrl(m.user.id)} size={20} color="#DBDCE1">
                    <AvatarIconSVG />
                </Avatar>
            )
        }));
        arr = [...arr, ...mapMembers];
        return arr;
    }, [classes, members]);

    return (
        <>
            <Grid container>
                <Tabs value={tab} onChange={handleChangeTab}>
                    {tabs.map(tab => (
                        <Tab key={tab.value} {...tab} />
                    ))}
                </Tabs>
            </Grid>
            <Grid ref={listRef} item className={classes.tabList} id="commentListDashboard">
                {isLoading && (
                    <Grid container justify="center" alignItems="center" className={classes.loadingWrapper}>
                        <Grid item>
                            <Spinner size={30} thick={4} />
                        </Grid>
                    </Grid>
                )}
                {_empty}
                {queryTickets?.length > 0 && (
                    <Grid container direction="column" wrap="nowrap">
                        <InfiniteScroll
                            dataLength={queryTickets.length}
                            next={fetchMore}
                            hasMore={true}
                            scrollableTarget={listRef.current}
                        >
                            {queryTickets?.map(ticket => (
                                <Grid item key={ticket?.id} className={classes.ticketItem}>
                                    <TicketItem
                                        onChangeStatus={onChangeStatus}
                                        onChangeAssignee={onChangeAssignee}
                                        ticket={ticket}
                                        membersOption={membersOption}
                                        lqaTab={lqaTab}
                                        lqaTicket={lqaTicket}
                                        isReadOnly={accessManageTicket !== roleConst.FULL}
                                        isRightSideBar={isRightSideBar}
                                        onClose={onClose}
                                    />
                                </Grid>
                            ))}
                        </InfiniteScroll>
                    </Grid>
                )}
                {isLoadingMore && (
                    <div className={classes.loadMoreSpinner} key={0}>
                        <Spinner size={30} thick={4} />
                    </div>
                )}
            </Grid>
        </>
    );
};

export default React.memo(TicketTabs);
