import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ADVANCED_SEARCH_RECORD_LIMIT, DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import {
    useIsReplacingText,
    useQuickFilters,
    useReplacingType,
    useIsOpenReplaceSearch,
    useTotalRecords,
    useCurrentAdvancedSearchId
} from 'hooks/advanced';
import SearchRecordToolbar from './SearchRecordToolbar';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { useParams } from 'react-router-dom';

const useStyles = ({ windowHeight }) => {
    return makeStyles(theme => ({
        root: {
            height: 'auto',
            borderRadius: 4,
            position: 'relative'
        },
        textCenter: {
            textAlign: 'center'
        },
        spinnerWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%'
        },
        layer: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            background: hexToRgba(theme.colors.white, 0.8),
            zIndex: 1
        },
        header: {
            height: 40,
            borderBottom: `1px solid ${theme.colors.border}`
        },
        body: {
            flex: 1,
            overflowY: 'auto',
            position: 'relative',
            width: '100%',
            overflowX: 'hidden'
        },
        recordItem: {
            padding: `${theme.spacing(2)}px 18px`
        },
        recordIndex: {
            color: theme.colors.secondaryText
        },
        cellItem: {
            width: '100%',
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
            '&:hover': {
                cursor: 'pointer',
                background: theme.colors.ghostwhite
            }
        },
        cellSelectedItem: {
            background: `${theme.colors.selectionColor} !important`
        },
        fullWidth: {
            width: '100%'
        },
        showMoreWrapper: {
            padding: theme.spacing(3),
            '&:hover': {
                cursor: 'pointer'
            }
        },
        showMoreText: {
            color: hexToRgba(theme.colors.dodgerBlue, 0.8)
        },
        showMoreBadge: {
            padding: `2px 4px`,
            borderRadius: 2,
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        },
        hl: {
            color: '#4A91E2'
        },
        resultCommon: {
            height: '100%',
            width: 400,
            position: 'relative'
        },
        looking: {
            fontSize: 14,
            color: theme.colors.secondaryText
        },
        footer: {
            padding: props => `${theme.spacing(2)}px 20px 0px`
        },
        note: {
            padding: props => `${theme.spacing(1)}px 20px ${theme.spacing(2)}px`
        }
    }));
};

function SearchBox({ value, onClose, dbId, viewId, handleSearchChange, handleClearSearch }) {
    const timerRef = React.useRef();
    const dispatch = useDispatch();
    const isFirstTime = React.useRef(true);
    const [isSearching, setIsSearching] = React.useState(false);
    const [matches, setMatches] = React.useState([]);
    const [windowHeight, setWindowHeight] = React.useState(
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    );
    const isOpenReplaceSearch = useIsOpenReplaceSearch();
    const classes = useStyles({ windowHeight, isOpenReplaceSearch })();
    const replacingText = useIsReplacingText();
    const replacingType = useReplacingType();
    const quickFilters = useQuickFilters();
    const totalRecords = useTotalRecords();
    const { workspaceId } = useParams();
    const currentAdvancedSearchId = useCurrentAdvancedSearchId();

    const filter = React.useMemo(() => {
        return formatQuickFilters(quickFilters);
    }, [quickFilters]);

    React.useEffect(() => {
        const resizeHandler = () => {
            setWindowHeight(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
        };
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    React.useEffect(() => {
        return () => {
            dispatch(advancedSearchActions.clearSearchState());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (isFirstTime?.current) return;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            isFirstTime.current = false;
            setIsSearching(true);

            let body = { limit: ADVANCED_SEARCH_RECORD_LIMIT, q: value.trim() };

            dispatch(
                advancedSearchActions.fetchGlobalSearchRecords({
                    wsId: workspaceId,
                    advancedSearchId: currentAdvancedSearchId,
                    body,
                    success: () => {
                        console.log('fetchAdvancedSearchRecords success');
                    },
                    error: () => {
                        console.log('fetchAdvancedSearchRecords failed');
                    }
                })
            );
            try {
                setIsSearching(false);
            } catch (error) {
                setIsSearching(false);
            }
        }, DEBOUNCE_TIME_SEARCHING);
    }, [value, viewId, dbId, matches, dispatch, filter, currentAdvancedSearchId, workspaceId]);

    const handleMatchesChange = React.useCallback(
        matches => {
            setMatches(matches);
            dispatch(advancedSearchActions.setMatches(matches));
        },
        [dispatch]
    );

    const handleCheckMatchCase = React.useCallback(
        (value, isChecked) => {
            isFirstTime.current = false;
            if (isChecked) {
                handleMatchesChange([...matches, value]);
                return;
            }
            handleMatchesChange(matches.filter(m => m !== value));
        },
        [matches, handleMatchesChange]
    );

    const handleReplace = React.useCallback(
        replaceMode => {
            dispatch(
                advancedSearchActions.handleReplace({
                    replaceMode,
                    success: () => {
                        console.log('success');
                    },
                    error: error => {
                        console.log('error', error);
                    }
                })
            );
        },
        [dispatch]
    );

    const _handleSearchChange = React.useCallback(
        e => {
            if (e?.target?.value.trim()) {
                isFirstTime.current = false;
            }
            handleSearchChange(e);
        },
        [handleSearchChange]
    );

    const _handleClearSearch = React.useCallback(() => {
        isFirstTime.current = false;
        handleClearSearch();
    }, [handleClearSearch]);

    const searchRecordToolbar = React.useMemo(() => {
        return (
            <SearchRecordToolbar
                matches={matches}
                handleCheckMatchCase={handleCheckMatchCase}
                handleReplace={handleReplace}
                replacing={replacingText && replacingType === 'single'}
                searchValue={value}
                handleSearchChange={_handleSearchChange}
                handleClearSearch={_handleClearSearch}
                totalRecords={totalRecords}
                replacingText={replacingText}
            />
        );
    }, [
        matches,
        handleCheckMatchCase,
        handleReplace,
        value,
        _handleSearchChange,
        _handleClearSearch,
        replacingText,
        replacingType,
        totalRecords
    ]);

    return (
        <Grid container className={classes.root} id="search-record" direction="column" wrap="nowrap">
            {searchRecordToolbar}
            {replacingText && replacingType === 'all' && (
                <Grid container className={classes.layer} direction="column" alignItems="center" justify="center">
                    <Grid item>
                        <Spinner size={18} thick={3} />
                    </Grid>
                </Grid>
            )}
            {value && (
                <Grid item className={classes.footer}>
                    <Typography variant="body2">
                        Found <strong>{totalRecords}</strong> record{totalRecords > 1 && `s`} matched.
                    </Typography>
                </Grid>
            )}
            <Grid item className={classes.note}>
                <Typography variant="body2" display="inline">
                    <strong>Tips:</strong> Enter the full words to get more accurate results.
                </Typography>
            </Grid>

            <Grid item className={classes.body}>
                {isSearching && (
                    <Grid
                        container
                        className={classes.spinnerWrapper}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <Spinner size={18} thick={3} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default SearchBox;
