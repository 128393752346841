import React from 'react';

function PauseContainedSVG({ ...other }) {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...other}
        >
            <circle cx="14" cy="14" r="14" fill="#4A91E2" />
            <rect x="6" y="6" width="16" height="16" fill="url(#patternPauseContained)" />
            <defs>
                <pattern id="patternPauseContained" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_5010_234129" transform="scale(0.0078125)" />
                </pattern>
                <image
                    id="image0_5010_234129"
                    width="128"
                    height="128"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACAEAQAAAA5p3UDAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmChoJKxFqzBtxAAAC6klEQVR42u3Zu04qUQCF4T1SamuiMhKNvgyiwIuIz2Kn0VhY2mngdYyJxJ4RK2DWKbxET3KK8bCHgfV/Ncl27fwz3kIAAAAAAAAukkUerrzRCEmnE0KrFcLeXlCahmR9/eeH3t5CMhyG8PQU1O+HcH+frD0/L/TWTPbEuyjV69LlpTSdqrDZTPndnbS3t+gdq7on8mV1u9Lra/GL+luWSe02e5aIdHYmzWb/f1nfn55ejz1L4P1Jmedlfbu0BTw5q7Yn7mXlaTqf1+S/ZJm0s8OeipJubuJd1of86oo9FaS80fjdT8dFTafK05Q9xazFPiCEbjeEWi3+ObXa++/g7CkifgBJsxn9jC9HR+wppoQ3wOFh/DM+HRywp5gSAtjain/Gp3qdPcVE/1+AJMU+48egJIm6adX2lPAGQJURgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzBGCOAMwRgDkCMEcA5gjAHAGYIwBzJQQwHpc3J8vYU0wJAby8xD/jg8o4a7X2lBDA42P8Mz4kZZy1WnviB6DBIPoZX/p99lSM8t1daTpVdJOJ8jRlTwVJ19fxL+zigj0VJdXrUpbFu6zRSNreZk+FSc1mnFfnbKb85IQ9S0B5ryfNZnO9LJ2esmeJSO32fF6fo5Hy42P2LCHlm5vS+bk0mfzuKbm9rdL3yGXekyz24tI0JJ1OUKsVwv5+SNI0hI2Nn58aj4OGw/c/igwGQQ8PydpwuMiv22UPAAAAAABYLX8Aps9QlpJHDcoAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTAtMjZUMDk6NDM6MTcrMDA6MDAVcJFuAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTEwLTI2VDA5OjQzOjE3KzAwOjAwZC0p0gAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMi0xMC0yNlQwOTo0MzoxNyswMDowMDM4CA0AAAAASUVORK5CYII="
                />
            </defs>
        </svg>
    );
}

export default React.memo(PauseContainedSVG);
