import * as appActions from 'app/actions';
import DatabasesPage from 'databases';
import { useCompanyId } from 'hooks/auth';
import { useCurrentUserId } from 'hooks/auth';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as constants from './const';
import ws from './socket';

export default function Dashboard(props) {
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const userId = useCurrentUserId();
    const companyId = useCompanyId();

    useEffect(() => {
        ws.dashboardSubscribeTopics({ userId, companyId, workspaceId });
        dispatch(
            appActions.setCurrentPage({
                currentPage: constants.DASHBOARD
            })
        );
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [dispatch, userId, companyId, workspaceId]);

    return <DatabasesPage {...props} />;
}
