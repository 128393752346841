import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import { isLDEmpty } from 'utils/object';
import NoTreeItemSVG from 'assets/images/svg/NoTreeItemSVG';
import StyledTreeItem from './StyledTreeItem';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { getTreeItems, getFirstNodeIdsWithLevel } from 'utils/gridUI/jsonTree';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
    },
    noPathTag: {
        height: '100%'
    }
}));

const getTreeItemsFromData = ({ treeItems, level, propertyPaths, parentPropertyPath, onNodeChanges, isVertical }) => {
    level++;
    return treeItems
        ?.filter(child => !child.isDeleted)
        ?.map(treeItemData => {
            let children = undefined;
            if (treeItemData.children && treeItemData.children.length > 0) {
                children = getTreeItemsFromData({
                    treeItems: treeItemData.children,
                    level,
                    propertyPaths,
                    parentPropertyPath: treeItemData?.propertyPath,
                    onNodeChanges,
                    isVertical
                });
            }

            const disabled =
                isVertical &&
                (typeof treeItemData !== 'object' ||
                    (typeof treeItemData === 'object' && !treeItemData?.children?.length) ||
                    (typeof treeItemData === 'object' && !treeItemData?.children?.[0]?.propertyPath?.includes('/')));

            return (
                <StyledTreeItem
                    level={level}
                    node={treeItemData}
                    key={treeItemData.propertyPath}
                    parentPropertyPath={parentPropertyPath}
                    nodeId={treeItemData.propertyPath}
                    propertyCount={treeItemData?.propertyCount}
                    labelText={treeItemData.name}
                    color={treeItemData?.customProperties?.color}
                    state={propertyPaths?.[treeItemData?.propertyPath]}
                    propertyPaths={propertyPaths}
                    onNodeChanges={onNodeChanges}
                    siblings={treeItems}
                    disabled={disabled}
                >
                    {children}
                </StyledTreeItem>
            );
        });
};

function JSONTree({ tree, propertyPaths, onNodeChanges, isVertical }) {
    const classes = useStyles();

    const treeItems = React.useMemo(() => {
        return getTreeItems(tree);
    }, [tree]);

    const defaultExpanded = React.useCallback(() => {
        return getFirstNodeIdsWithLevel(treeItems);
    }, [treeItems]);

    if (isLDEmpty(treeItems))
        return (
            <Grid direction="column" alignItems="center" justify="center" container className={classes.noPathTag}>
                <Grid item>
                    <NoTreeItemSVG />
                </Grid>
            </Grid>
        );

    return (
        <Grid className={classes.root}>
            <TreeView
                defaultExpanded={defaultExpanded}
                defaultCollapseIcon={<ArrowDownSVG />}
                defaultExpandIcon={<ArrowRightSVG />}
            >
                {getTreeItemsFromData({
                    treeItems,
                    level: 0,
                    propertyPaths,
                    onNodeChanges,
                    isVertical
                })}
            </TreeView>
        </Grid>
    );
}

export default JSONTree;
