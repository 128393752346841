import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';
import File from '../components/File';
import isArray from 'lodash/isArray';
import validator from 'validator';
import { isRefImage, getReferenceDataItem } from 'utils/gridUI/reference';

function RefSelection({ previousData, options = [], customProperties = {}, isHideMarkIcon = false, dbId }) {
    if (!previousData || previousData?.length === 0 || !isArray(previousData)) return null;
    const refOptions = getReferenceDataItem(previousData);

    return (
        <Grid container spacing={1}>
            {refOptions?.map((item, index) => {
                let values = item;
                if (values instanceof Array) {
                    const isRefFile = isRefImage(values);
                    return values?.map((option, index) => {
                        return isRefFile ? (
                            <Grid item key={index}>
                                <File
                                    fileMeta={option}
                                    isHideMarkIcon={isHideMarkIcon}
                                    state={'delete'}
                                    width={28}
                                    height={28}
                                    dbId={dbId}
                                />
                            </Grid>
                        ) : (
                            <Grid item key={index}>
                                <Option
                                    data={option}
                                    state={'delete'}
                                    options={options}
                                    customProperties={customProperties}
                                    isHideMarkIcon={isHideMarkIcon}
                                />
                            </Grid>
                        );
                    });
                }

                if (values && validator.isJSON(values)) {
                    values = JSON.parse(values);
                    if (values instanceof Array) {
                        const isRefFile = isRefImage(values);
                        return (
                            <Grid container item>
                                {values?.map((option, index) => {
                                    return isRefFile ? (
                                        <Grid item key={index}>
                                            <File
                                                fileMeta={option}
                                                isHideMarkIcon={isHideMarkIcon}
                                                state={'delete'}
                                                width={28}
                                                height={28}
                                                dbId={dbId}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item key={index}>
                                            <Option
                                                isHideMarkIcon={isHideMarkIcon}
                                                data={option}
                                                state={'delete'}
                                                options={options}
                                                customProperties={customProperties}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        );
                    }
                }

                const isRefFile = isRefImage(values);

                return isRefFile ? (
                    <Grid item key={index}>
                        <File
                            fileMeta={values}
                            isHideMarkIcon={isHideMarkIcon}
                            state={'delete'}
                            width={28}
                            height={28}
                            dbId={dbId}
                        />
                    </Grid>
                ) : (
                    <Grid item key={index}>
                        <Option
                            data={values}
                            isHideMarkIcon={isHideMarkIcon}
                            state={'delete'}
                            options={options}
                            customProperties={customProperties}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(RefSelection);
