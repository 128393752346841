import React from 'react';

function CompanySVG({ color = '#78778B', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.75 2.5C0.75 1.5335 1.5335 0.75 2.5 0.75H13.25C14.2165 0.75 15 1.5335 15 2.5V7.05928H17.5C18.4665 7.05928 19.25 7.84278 19.25 8.80928V18.75C19.25 19.0261 19.0261 19.25 18.75 19.25H9.92708C9.65094 19.25 9.42708 19.0261 9.42708 18.75V15.2938C9.42708 15.1557 9.31515 15.0438 9.17708 15.0438H6.92708C6.78901 15.0438 6.67708 15.1557 6.67708 15.2938V19.25H1.25C0.973858 19.25 0.75 19.0261 0.75 18.75V2.5ZM15 8.55928V17.45C15 17.6157 15.1343 17.75 15.3 17.75H17.45C17.6157 17.75 17.75 17.6157 17.75 17.45V8.80928C17.75 8.67121 17.6381 8.55928 17.5 8.55928H15ZM13.2 17.75C13.3657 17.75 13.5 17.6157 13.5 17.45V2.5C13.5 2.36193 13.3881 2.25 13.25 2.25H2.5C2.36193 2.25 2.25 2.36193 2.25 2.5V17.45C2.25 17.6157 2.38431 17.75 2.55 17.75H4.87708C5.04277 17.75 5.17708 17.6157 5.17708 17.45V15.2938C5.17708 14.3273 5.96059 13.5438 6.92708 13.5438H9.17708C10.1436 13.5438 10.9271 14.3273 10.9271 15.2938V17.45C10.9271 17.6157 11.0614 17.75 11.2271 17.75H13.2Z"
                fill={color}
            />
            <path
                d="M7 6.5C7 5.94772 6.55229 5.5 6 5.5C5.44772 5.5 5 5.94772 5 6.5C5 7.05228 5.44772 7.5 6 7.5C6.55229 7.5 7 7.05228 7 6.5Z"
                fill={color}
            />
            <path
                d="M7 9.5C7 8.94772 6.55229 8.5 6 8.5C5.44772 8.5 5 8.94772 5 9.5C5 10.0523 5.44772 10.5 6 10.5C6.55229 10.5 7 10.0523 7 9.5Z"
                fill={color}
            />
            <path
                d="M10.5 6.5C10.5 5.94772 10.0523 5.5 9.5 5.5C8.94772 5.5 8.5 5.94772 8.5 6.5C8.5 7.05228 8.94772 7.5 9.5 7.5C10.0523 7.5 10.5 7.05228 10.5 6.5Z"
                fill={color}
            />
            <path
                d="M10.5 9.5C10.5 8.94772 10.0523 8.5 9.5 8.5C8.94772 8.5 8.5 8.94772 8.5 9.5C8.5 10.0523 8.94772 10.5 9.5 10.5C10.0523 10.5 10.5 10.0523 10.5 9.5Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CompanySVG);
