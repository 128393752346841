import { makeStyles } from '@material-ui/styles';
import { useIsColumnInRange } from 'hooks/advanced';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    header: {
        fontWeight: 'bold',
        background: `${theme.colors.paleGrey} !important`
    },
    headerFilter: {
        background: theme.colors.white
    },
    headerSelected: {
        background: `${theme.colors.lightGrey} !important`
    }
}));

const HeaderWrapper = ({ style, rowIndex, isLastColumn, columnId, columnIndex, children }) => {
    const classes = useStyles();
    const isColumnSelected = useIsColumnInRange({ columnId, columnIndex });

    const headerClassName =
        rowIndex < 1 || isLastColumn
            ? classes.header
            : `${classes.headerFilter} ${isColumnSelected ? classes.headerSelected : ''}`;

    return (
        <div
            className={`
        ${classes.root} 
        ${headerClassName} 
        ${isColumnSelected ? classes.headerSelected : ''}
    `}
            style={style}
        >
            {children}
        </div>
    );
};

export default React.memo(HeaderWrapper);
