import React from 'react';

function RepeatSVG({ color = '#D1D0D6', isRepeatOne, ...other }) {
    if (isRepeatOne) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.8156 3.58752H11.1656C10.8219 3.58752 10.5406 3.86877 10.5406 4.21252C10.5406 4.55627 10.8219 4.83752 11.1656 4.83752H12.8156C13.8844 4.83752 14.7469 5.70315 14.75 6.7719V11.2031C14.75 12.2719 13.8844 13.1344 12.8156 13.1375H3.18438C2.11562 13.1375 1.25312 12.2719 1.25 11.2031V6.76877C1.25 5.70002 2.11562 4.83752 3.18438 4.8344H6.76875L5.80625 5.7969C5.5625 6.04065 5.5625 6.43752 5.80625 6.68127C6.05 6.92502 6.44688 6.92502 6.69063 6.68127L8.72188 4.65002C8.96562 4.40627 8.96562 4.0094 8.72188 3.76565L6.75 1.79377C6.50625 1.55002 6.10938 1.55002 5.86562 1.79377C5.62187 2.03752 5.62187 2.4344 5.86562 2.67815L6.77187 3.5844H3.18438C1.425 3.58752 0.003125 5.01252 0 6.76877V11.2C0.003125 12.9563 1.425 14.3813 3.18438 14.3844H12.8156C14.5719 14.3813 15.9969 12.9594 16 11.2V6.76877C15.9969 5.01252 14.575 3.58752 12.8156 3.58752Z"
                    fill={color}
                />
                <path
                    d="M8.95627 7.36877V12.1H7.90315V8.9594C7.90315 8.9594 7.5844 9.37815 7.04065 9.37815V8.32502C7.62502 8.32502 7.9094 7.3719 7.9094 7.3719H8.95627V7.36877Z"
                    fill={color}
                />
            </svg>
        );
    }
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M12.816 3.58598H11.1666C11.0008 3.58598 10.8419 3.65183 10.7247 3.76904C10.6074 3.88625 10.5416 4.04522 10.5416 4.21098C10.5416 4.37674 10.6074 4.53571 10.7247 4.65292C10.8419 4.77013 11.0008 4.83598 11.1666 4.83598H12.816C13.3288 4.83656 13.8203 5.0405 14.1829 5.40306C14.5455 5.76561 14.7494 6.25718 14.75 6.76992V11.2019C14.7494 11.7147 14.5455 12.2062 14.1829 12.5688C13.8203 12.9314 13.3288 13.1353 12.816 13.1359H3.18397C2.67123 13.1353 2.17966 12.9314 1.81709 12.5688C1.45453 12.2062 1.25059 11.7147 1.25 11.2019V6.76992C1.25059 6.25718 1.45453 5.76561 1.8171 5.40306C2.17966 5.0405 2.67123 4.83656 3.18397 4.83598H6.76731L5.80378 5.79948C5.68657 5.9167 5.62072 6.07567 5.62072 6.24143C5.62072 6.4072 5.68657 6.56617 5.80378 6.68339C5.92099 6.8006 6.07997 6.86645 6.24573 6.86645C6.4115 6.86645 6.57047 6.8006 6.68769 6.68339L8.71813 4.65308C8.83533 4.53587 8.90118 4.3769 8.90118 4.21114C8.90118 4.04538 8.83533 3.88641 8.71813 3.7692L6.74625 1.79733C6.62904 1.68011 6.47007 1.61426 6.30431 1.61426C6.13855 1.61425 5.97957 1.6801 5.86236 1.79731C5.74515 1.91452 5.67929 2.07349 5.67929 2.23925C5.67929 2.40501 5.74513 2.56399 5.86234 2.6812L6.76737 3.58623H3.18397C2.33986 3.58717 1.53059 3.92288 0.933694 4.51973C0.336795 5.11657 0.0010091 5.92581 0 6.76992L0 11.2019C0.000934673 12.0461 0.336688 12.8554 0.933595 13.4523C1.5305 14.0492 2.33981 14.385 3.18397 14.3859H12.816C13.6602 14.385 14.4695 14.0492 15.0664 13.4523C15.6633 12.8554 15.9991 12.0461 16 11.2019V6.76992C15.9991 5.92577 15.6633 5.11647 15.0664 4.51957C14.4695 3.92266 13.6602 3.58692 12.816 3.58598Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(RepeatSVG);
