import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function BulletedListElement(props) {
    const classes = useStyles();
    return (
        <ul className={classes.root} {...props.attributes}>
            {props.children}
        </ul>
    );
}

export default BulletedListElement;
