import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import { useDispatch } from 'react-redux';
import { sendManualTrack } from 'tracker';
import * as gridUIActions from 'gridUI/actions';
import ListItem from 'components/list/Item';
import LQASVG from 'assets/images/svg/LQASVG';
import ExtendViewSVG from 'assets/images/svg/ExtendViewSVG';
import { SYSTEM_COLUMN_IDS, RESOURCE_TYPES, USER_SETTINGS } from 'const';
import { useTranslation } from 'react-i18next';
import BetaTag from 'components/BetaTag';
import LQAViewSVG from 'assets/images/svg/LQAViewSVG';
import { CREATE_RECORD_TYPES, TM_STATUS, DEPENDENCY_STATUS, EXTEND_VIEW_TYPES, SOURCE_STATUS } from 'const/gridUI';
import PreviewSVG from 'assets/images/svg/PreviewSVG';
import AddRecordAboveSVG from 'assets/images/svg/AddRecordAboveSVG';
import AddRowBelowSVG from 'assets/images/svg/AddRowBelowSVG';
import HistorySVG from 'assets/images/svg/HistorySVG';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import ApproveDependencySVG from 'assets/images/svg/dependencies/ApproveSVG';
import LinkIconSVG from 'assets/images/svg/LinkSVG';
import SourceStatusSVG from 'assets/images/svg/dependencies/SourceStatusSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import hexToRgba from 'hex-to-rgba';
import {
    useDisabledColumns,
    useDisabledColumnsByType,
    useMetaData,
    useDisabledSourceColumns,
    useProcessingColumns,
    useIsOpenViewCellTicket,
    useShowCopySource,
    useIsOpenCellComment,
    useDependencyList,
    useReferenceDisabledColumns,
    useViewColumnIdsWithReOrder
} from 'hooks/gridUI';
import TicketForm from 'gridUI/lqa/TicketForm';
import ViewCellTicket from 'gridUI/lqa/ViewCellTicket';
import CopySourceSVG from 'assets/images/svg/CopySourceSVG';
import CellCommentOverlay from 'gridUI/cellComment';
import AddCommentSVG from 'assets/images/svg/AddCommentSVG';
import NestedMenuItem from 'components/menus/HoverMenu';
import * as workspaceActions from 'workspaces/actions';
import { useUserGridSettings } from 'hooks/workspace';
import { useParams } from 'react-router-dom';
import * as columnTypes from 'const/columnTypes';
import { copyToClipboard } from 'utils/clipboard';
import { enqueueSnackbar } from 'notifier/actions';
import { getSourceStatusLabelByStatus, getSourceStatusIcon } from 'utils/gridUI/dependency';
import PlayAutomationMenu from '../PlayAutomationMenu';

const STATES = {
    MENU: `MENU`,
    ADD_TICKET: `ADD_TICKET`,
    VIEW_TICKETS: `VIEW_TICKETS`,
    CELL_COMMENT: `CELL_COMMENT`,
    ADD_COMMENT: `ADD_COMMENT`
};

const getState = ({ isOpenViewCellTicket, isOpenCellComment }) => {
    if (isOpenViewCellTicket) {
        return STATES.VIEW_TICKETS;
    }
    if (isOpenCellComment) {
        return STATES.CELL_COMMENT;
    }
    return STATES.MENU;
};

function Single({
    recordId,
    columnId,
    rowIndex,
    columnIndex,
    onClose,
    isShowMarkAsUpToDate,
    isShowMarkAsOutOfDate,
    isShowMarkAsUnset,
    isShowApproveTMStatus,
    onRePosContext,
    selectedCellData
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const roles = useRole();
    const metaData = useMetaData();
    const dependencies = useDependencyList();
    const accessRecordHistory = roles[roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY];
    const accessManageTicket = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_TICKET];
    const accessManageGridRecord = roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD];
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const disabledColumns = useDisabledColumns();
    const referenceDisabledColumns = useReferenceDisabledColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const processingColumns = useProcessingColumns();
    const isOpenViewCellTicket = useIsOpenViewCellTicket();
    const isOpenCellComment = useIsOpenCellComment();
    const { dbId, branchId, workspaceId } = useParams();
    const viewColumnIds = useViewColumnIdsWithReOrder();

    const userGridSetting = useUserGridSettings({ wsId: workspaceId, dbId, gridId: branchId });
    const [currentState, setCurrentState] = React.useState(getState({ isOpenViewCellTicket, isOpenCellComment }));

    const { showCopySource, sourceData } = useShowCopySource({ ...selectedCellData });

    const isReadOnly = React.useMemo(() => {
        const notAllowEditRecord = accessEditRecords !== roleConst.FULL;
        const column = metaData?.[columnId];
        const isDisabled = [
            ...disabledColumns,
            ...disabledSourceColumns,
            ...disabledColumnIdsByType,
            ...processingColumns,
            ...referenceDisabledColumns
        ]?.includes(columnId);
        const isEditable = column && column.editable && !isDisabled;

        return notAllowEditRecord || !isEditable;
    }, [
        referenceDisabledColumns,
        columnId,
        accessEditRecords,
        disabledColumns,
        disabledSourceColumns,
        disabledColumnIdsByType,
        processingColumns,
        metaData
    ]);

    const workspaceRole = roles?.[roleConst.WORKSPACE_ROLE];

    const handleAddIssue = React.useCallback(e => {
        sendManualTrack({ type: `Open Add Ticket` });
        setCurrentState(STATES.ADD_TICKET);
    }, []);

    const handleViewIssue = React.useCallback(e => {
        sendManualTrack({ type: `Open Cell Ticket` });
        setCurrentState(STATES.VIEW_TICKETS);
    }, []);

    const handleOpenRecordPanel = React.useCallback(() => {
        dispatch(gridUIActions.turnOnRecordPanel());
        onClose && onClose();
    }, [dispatch, onClose]);

    const handleAddComment = React.useCallback(e => {
        sendManualTrack({ type: `Add Comment` });
        setCurrentState(STATES.ADD_COMMENT);
    }, []);

    const openRecordHistoryHandler = React.useCallback(
        (type = EXTEND_VIEW_TYPES.TRANSLATOR) => {
            sendManualTrack({
                type: `Open Record History`,
                customData: {
                    recordId
                }
            });
            dispatch(gridUIActions.openRecordHistory({ rowId: recordId, rowIndex, type }));
            dispatch(
                workspaceActions.setUserSettings({
                    dbId,
                    workspaceId,
                    resourceId: branchId,
                    resourceType: RESOURCE_TYPES.GRID,
                    value: {
                        ...userGridSetting,
                        [USER_SETTINGS.GRID.OPEN_EXTEND_VIEW]: true,
                        [USER_SETTINGS.GRID.EXTEND_VIEW_TYPE]: type
                    }
                })
            );

            onClose && onClose();
        },
        [recordId, rowIndex, dispatch, onClose, branchId, dbId, workspaceId, userGridSetting]
    );

    const openExtendViewHandler = React.useCallback(() => {
        const column = metaData?.[columnId];
        const isTranslator =
            workspaceRole === roleConst.TRANSLATOR ||
            (column?.type === columnTypes.TRANSLATION && !selectedCellData?.isParentDependency);
        openRecordHistoryHandler(isTranslator ? EXTEND_VIEW_TYPES.TRANSLATOR : EXTEND_VIEW_TYPES.EDITOR);
    }, [openRecordHistoryHandler, workspaceRole, metaData, columnId, selectedCellData]);

    const handleAdRecordAbove = React.useCallback(
        recordCount => {
            dispatch(
                gridUIActions.createRecordsRelative({
                    recordCount,
                    rowIndex,
                    type: CREATE_RECORD_TYPES.ABOVE,
                    successCallback: () => {
                        console.log('create below record error');
                    },
                    errorCallback: () => {
                        console.log('create above record error');
                    }
                })
            );
            onClose && onClose();
        },
        [dispatch, rowIndex, onClose]
    );

    const handleAdRecordBelow = React.useCallback(
        recordCount => {
            dispatch(
                gridUIActions.createRecordsRelative({
                    recordCount,
                    rowIndex,
                    type: CREATE_RECORD_TYPES.BELOW,
                    successCallback: () => {
                        console.log('create below record error');
                    },
                    errorCallback: () => {
                        console.log('create above record error');
                    }
                })
            );
            onClose && onClose();
        },
        [dispatch, rowIndex, onClose]
    );

    const showTicket = React.useMemo(() => {
        return !SYSTEM_COLUMN_IDS.includes(columnId);
    }, [columnId]);

    const source = React.useMemo(() => {
        return dependencies?.find(dpDc => dpDc?.parent === columnId);
    }, [dependencies, columnId]);

    const isShowMarkSource = React.useMemo(() => {
        return !isReadOnly && Boolean(source);
    }, [source, isReadOnly]);

    const targets = React.useMemo(() => {
        return dependencies
            ?.filter(dpDc => dpDc?.parent === columnId)
            ?.map(dpDc => dpDc?.child)
            ?.filter(columnId => viewColumnIds?.includes(columnId));
    }, [columnId, dependencies, viewColumnIds]);

    const hasChild = React.useMemo(() => {
        return targets?.length > 0;
    }, [targets]);

    const handleApproveTMStatus = React.useCallback(() => {
        sendManualTrack({ type: `Approve TM Status` });
        dispatch(gridUIActions.approveTMStatus(TM_STATUS.APPROVED));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleApproveDependencyUpToDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Up To Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UP_TO_DATE));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleMarkDependencyOutOfDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Out Of Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.OUT_OF_DATE));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleMarkDependencyNotTranslated = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Not Translated` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UNSET));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleMarkTargetDependencyOutOfDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Out Of Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.OUT_OF_DATE, targets));
        onClose && onClose();
    }, [onClose, dispatch, targets]);

    const handleMarkTargetDependencyUpToDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Up To Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UP_TO_DATE, targets));
        onClose && onClose();
    }, [onClose, dispatch, targets]);

    const handleMarkSourceStatus = React.useCallback(
        sourceStatus => {
            sendManualTrack({
                type: `Mark Source Status As ${getSourceStatusLabelByStatus({ status: sourceStatus })}`
            });
            dispatch(gridUIActions.approveSourceStatus(sourceStatus, [columnId]));
            onClose && onClose();
        },
        [dispatch, onClose, columnId]
    );

    const deleteRecords = React.useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            sendManualTrack({
                type: `Delete Records`,
                customData: {
                    recordIds: [recordId]
                }
            });
            dispatch(
                gridUIActions.deleteViewRecordsByIndex({
                    successCallback: () => {
                        console.log('Record Deleted');
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        console.log('Failed to delete record');
                    }
                })
            );
        },
        [dispatch, onClose, recordId]
    );

    const handleCopySource = React.useCallback(() => {
        onClose && onClose();
        if (selectedCellData?.data?.value === sourceData.sourceText) {
            dispatch(gridUIActions.openCellEdit({ ...selectedCellData }));
            return;
        }
        dispatch(
            gridUIActions.copySource({
                columnId: selectedCellData.columnId,
                rowId: selectedCellData.rowId,
                value: sourceData.sourceText,
                successCallback: () => {
                    dispatch(gridUIActions.openCellEdit({ ...selectedCellData }));
                }
            })
        );
    }, [dispatch, sourceData, onClose, selectedCellData]);

    React.useEffect(() => {
        onRePosContext();
    }, [currentState, onRePosContext]);

    const getLink = React.useCallback(() => {
        const currentUrl = new URL(window.location);
        const href = currentUrl?.href;
        copyToClipboard({ copyValue: href, internalValue: href });
        dispatch(enqueueSnackbar({ message: t('copied_range_link') }));
        onClose && onClose();
    }, [onClose, dispatch, t]);

    return React.useMemo(() => {
        switch (currentState) {
            case STATES.MENU:
                return (
                    <>
                        {accessManageTicket === roleConst.FULL && showTicket && (
                            <Grid item onClick={handleAddIssue}>
                                <ListItem
                                    icon={<LQASVG />}
                                    name={
                                        <Grid container>
                                            <Grid item style={{ marginRight: '5px' }}>
                                                {t('cell_options_add_ticket')}
                                            </Grid>
                                            <BetaTag />
                                        </Grid>
                                    }
                                />
                            </Grid>
                        )}
                        {showTicket && (
                            <Grid item onClick={handleViewIssue}>
                                <ListItem icon={<LQAViewSVG />} name={t('cell_options_view_ticket')} />
                            </Grid>
                        )}
                        {showCopySource && (
                            <Grid item onClick={handleCopySource}>
                                <ListItem
                                    icon={<CopySourceSVG />}
                                    name={
                                        <span>
                                            Copy source <span className={'text-grey-light'}>(Ctrl + Shift + C)</span>
                                        </span>
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item onClick={handleAddComment}>
                            <ListItem icon={<AddCommentSVG />} name={t('cell_options_add_comment')} />
                        </Grid>
                        <Grid item onClick={handleOpenRecordPanel}>
                            <ListItem icon={<PreviewSVG />} name={t('open_record_panel')} />
                        </Grid>

                        <Grid item onClick={getLink}>
                            <ListItem
                                icon={<LinkIconSVG width={16} height={16} />}
                                name={t(`get_link_to_this_range`)}
                            />
                        </Grid>
                        <PlayAutomationMenu closePopup={onClose} />

                        {accessManageGridRecord === roleConst.FULL && (
                            <Grid item onClick={() => handleAdRecordAbove(1)}>
                                <ListItem icon={<AddRecordAboveSVG />} name={t('insert_record_above')} />
                            </Grid>
                        )}
                        {accessManageGridRecord === roleConst.FULL && (
                            <Grid item onClick={() => handleAdRecordBelow(1)}>
                                <ListItem icon={<AddRowBelowSVG />} name={t('insert_record_below')} />
                            </Grid>
                        )}

                        {accessRecordHistory === roleConst.FULL && (
                            <Grid item onClick={() => openRecordHistoryHandler(EXTEND_VIEW_TYPES.RECORD_HISTORY)}>
                                <ListItem icon={<HistorySVG />} name={t('cell_options_record_history')} />
                            </Grid>
                        )}

                        {accessRecordHistory === roleConst.FULL && (
                            <Grid item onClick={openExtendViewHandler}>
                                <ListItem
                                    icon={<ExtendViewSVG />}
                                    name={
                                        <Grid container>
                                            <Grid item style={{ marginRight: '5px' }}>
                                                {t('global_open_extended_view')}
                                            </Grid>
                                            <BetaTag />
                                        </Grid>
                                    }
                                />
                            </Grid>
                        )}
                        {accessEditTranslation === roleConst.FULL && !isReadOnly && isShowApproveTMStatus && (
                            <Grid item onClick={handleApproveTMStatus}>
                                <ListItem icon={<TMStatusAprrovedSVG />} name={t('record_options_approve_tm')} />
                            </Grid>
                        )}
                        {isShowMarkAsUpToDate && !isReadOnly && (
                            <Grid item onClick={handleApproveDependencyUpToDate}>
                                <ListItem icon={<ApproveDependencySVG />} name={t('dependency_mark_as_uptodate')} />
                            </Grid>
                        )}

                        {isShowMarkAsOutOfDate && !isReadOnly && (
                            <Grid item onClick={handleMarkDependencyOutOfDate}>
                                <ListItem
                                    icon={<ApproveDependencySVG color={hexToRgba(theme.colors.sun, 0.8)} />}
                                    name={t('dependency_mark_as_outofdate')}
                                />
                            </Grid>
                        )}
                        {isShowMarkAsUnset && !isReadOnly && (
                            <Grid item onClick={handleMarkDependencyNotTranslated}>
                                <ListItem
                                    icon={<ApproveDependencySVG color={hexToRgba(theme.colors.brightRed, 0.8)} />}
                                    name={t('dependency_mark_as_unset')}
                                />
                            </Grid>
                        )}

                        {hasChild && (
                            <NestedMenuItem
                                menuClassName={'z-[9999]'}
                                icon={<ApproveDependencySVG color={theme.colors.steel} />}
                                label={t('set_target_dependencies')}
                                parentMenuOpen={true}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'bottom'
                                }}
                            >
                                <Grid container direction="column">
                                    <Grid item onClick={handleMarkTargetDependencyOutOfDate}>
                                        <ListItem
                                            icon={<ApproveDependencySVG color={hexToRgba(theme.colors.sun, 0.8)} />}
                                            name={t('dependency_mark_as_outofdate')}
                                        />
                                    </Grid>
                                    <Grid item onClick={handleMarkTargetDependencyUpToDate}>
                                        <ListItem
                                            icon={<ApproveDependencySVG />}
                                            name={t('dependency_mark_as_uptodate')}
                                        />
                                    </Grid>
                                </Grid>
                            </NestedMenuItem>
                        )}

                        {isShowMarkSource && (
                            <NestedMenuItem
                                menuClassName={'z-[9999]'}
                                icon={<SourceStatusSVG color={theme.colors.steel} />}
                                label={t('set_source_status')}
                                parentMenuOpen={true}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'bottom'
                                }}
                            >
                                <Grid container direction="column">
                                    <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.DO_NOT_TRANSLATE)}>
                                        <ListItem
                                            icon={getSourceStatusIcon({
                                                status: SOURCE_STATUS.DO_NOT_TRANSLATE,
                                                size: 18
                                            })}
                                            name={t('do_not_translate')}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        onClick={() => handleMarkSourceStatus(SOURCE_STATUS.NOT_READY_FOR_TRANSLATION)}
                                    >
                                        <ListItem
                                            icon={getSourceStatusIcon({
                                                status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                                                size: 18
                                            })}
                                            name={t('not_ready_for_translation')}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        onClick={() => handleMarkSourceStatus(SOURCE_STATUS.READY_FOR_TRANSLATION)}
                                    >
                                        <ListItem
                                            icon={getSourceStatusIcon({
                                                status: SOURCE_STATUS.READY_FOR_TRANSLATION,
                                                size: 18
                                            })}
                                            name={t('ready_for_translation')}
                                        />
                                    </Grid>
                                    <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.LOCKED)}>
                                        <ListItem
                                            icon={getSourceStatusIcon({
                                                status: SOURCE_STATUS.LOCKED,
                                                size: 18
                                            })}
                                            name={t('locked')}
                                        />
                                    </Grid>
                                </Grid>
                            </NestedMenuItem>
                        )}

                        {accessManageGridRecord === roleConst.FULL && (
                            <>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item onClick={deleteRecords}>
                                    <ListItem icon={<DeleteSVG />} name={t(`record_option_delete`)} />
                                </Grid>
                            </>
                        )}
                    </>
                );

            case STATES.ADD_TICKET: {
                return <TicketForm onAdded={onClose} recordId={recordId} columnId={columnId} onClose={onClose} />;
            }

            case STATES.VIEW_TICKETS: {
                return <ViewCellTicket onClose={onClose} recordId={recordId} columnId={columnId} />;
            }

            case STATES.CELL_COMMENT:
            case STATES.ADD_COMMENT: {
                return <CellCommentOverlay t={t} rowId={recordId} columnId={columnId} />;
            }

            default:
                return null;
        }
    }, [
        getLink,
        handleMarkSourceStatus,
        isShowMarkSource,
        deleteRecords,
        hasChild,
        onClose,
        recordId,
        columnId,
        accessRecordHistory,
        handleAddIssue,
        handleApproveTMStatus,
        handleApproveDependencyUpToDate,
        handleMarkDependencyNotTranslated,
        handleMarkDependencyOutOfDate,
        handleOpenRecordPanel,
        isReadOnly,
        handleViewIssue,
        isShowApproveTMStatus,
        isShowMarkAsOutOfDate,
        isShowMarkAsUnset,
        isShowMarkAsUpToDate,
        openRecordHistoryHandler,
        showTicket,
        t,
        theme,
        accessEditTranslation,
        accessManageGridRecord,
        accessManageTicket,
        currentState,
        handleAdRecordAbove,
        handleAdRecordBelow,
        showCopySource,
        handleCopySource,
        handleAddComment,
        handleMarkTargetDependencyUpToDate,
        handleMarkTargetDependencyOutOfDate,
        openExtendViewHandler
    ]);
}

export default Single;
