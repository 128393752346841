import request from './request';
import { APP_API_WORK_FLOW } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';

const VERSION = 'v1';

const AUTOMATION_PATH = `${APP_API_WORK_FLOW}/${VERSION}/automations`;

const automationApis = {
    list: AUTOMATION_PATH,
    create: AUTOMATION_PATH,
    automation: id => `${AUTOMATION_PATH}/${id}`,
    clone: id => `${AUTOMATION_PATH}/${id}/clone`,
    trigger: ({ automationId }) => `${AUTOMATION_PATH}/${automationId}/triggers`,
    node: ({ automationId }) => `${AUTOMATION_PATH}/${automationId}/nodes`,
    updateTrigger: ({ automationId, triggerId }) => `${AUTOMATION_PATH}/${automationId}/triggers/${triggerId}`,
    updateNode: ({ automationId, nodeId }) => `${AUTOMATION_PATH}/${automationId}/nodes/${nodeId}`,
    lambdas: `${AUTOMATION_PATH}/functions`,
    singleLambda: lambdaId => `${AUTOMATION_PATH}/functions/${lambdaId}`,
    execute: ({ automationId }) => `${AUTOMATION_PATH}/${automationId}/trigger`,
    test: ({ automationId }) => `${AUTOMATION_PATH}/${automationId}/test`,
    invoke: ({ automationId }) => `${AUTOMATION_PATH}/${automationId}/invoke`,
    history: ({ automationId, page, offset, size }) =>
        `${AUTOMATION_PATH}/${automationId}/executions?page=${page}&offset=${offset}&size=${size}`,
    move: ({ automationId, nodeId }) => `${AUTOMATION_PATH}/${automationId}/nodes/${nodeId}/move`,
    uploadLogo: () => `${APP_API_WORK_FLOW}/${VERSION}/media/automation-icons`,
    removeLogo: ({ automationId }) => `${APP_API_WORK_FLOW}/${VERSION}/media/automation-icons/${automationId}`
};

export const getListAutomationsApi = async ({ dbId, gridId, viewId }) => {
    const query = {
        dbId,
        gridId,
        viewId,
        size: 1000
    };
    const extra = objectToQueryParams(query);
    const response = await request.get(`${automationApis.list}?${extra}`);
    return response.data;
};

export const createAutomationApi = async body => {
    const response = await request.post(automationApis.create, body);
    return response.data;
};

export const cloneAutomationApi = async (id, body) => {
    const response = await request.post(automationApis.clone(id), body);
    return response.data;
};

export const updateAutomationApi = async (id, body) => {
    const response = await request.put(automationApis.automation(id), body);
    return response.data;
};

export const deleteAutomationApi = async id => {
    const response = await request.delete(automationApis.automation(id));
    return response.data;
};

export const getListTriggersOfAutomationApi = async ({ automationId }) => {
    const response = await request.get(automationApis.trigger({ automationId }));
    return response.data;
};

export const createAutomationTriggerApi = async ({ automationId, body }) => {
    const response = await request.post(automationApis.trigger({ automationId }), body);
    return response.data;
};

export const getListNodesOfAutomationApi = async ({ automationId }) => {
    const response = await request.get(automationApis.node({ automationId }));
    return response.data;
};

export const createAutomationNodeApi = async ({ automationId, body }) => {
    const response = await request.post(automationApis.node({ automationId }), body);
    return response.data;
};

export const updateTriggerNodeApi = async ({ automationId, triggerId, body }) => {
    const response = await request.put(automationApis.updateTrigger({ automationId, triggerId }), body);
    return response.data;
};

export const updateNodeApi = async ({ automationId, nodeId, body }) => {
    const response = await request.put(automationApis.updateNode({ automationId, nodeId }), body);
    return response.data;
};

export const getLambdasApi = async () => {
    const response = await request.get(automationApis.lambdas);
    return response.data;
};

export const createLambdaApi = async formData => {
    const response = await request.post(automationApis.lambdas, formData);
    return response.data;
};

export const editLambdaApi = async ({ lambdaId, formData }) => {
    const response = await request.put(automationApis.singleLambda(lambdaId), formData);
    return response.data;
};

export const deleteLambdaApi = async ({ lambdaId }) => {
    const response = await request.delete(automationApis.singleLambda(lambdaId));
    return response.data;
};

export const deleteTriggerNodeApi = async ({ automationId, triggerId }) => {
    const response = await request.delete(automationApis.updateTrigger({ automationId, triggerId }));
    return response.data;
};

export const deleteNodeApi = async ({ automationId, nodeId }) => {
    const response = await request.delete(automationApis.updateNode({ automationId, nodeId }));
    return response.data;
};

export const executeAutomationApi = async ({ automationId }) => {
    const response = await request.post(automationApis.execute({ automationId }));
    return response.data;
};

export const testAutomationApi = async ({ automationId, body }) => {
    const response = await request.post(automationApis.test({ automationId }), body);
    return response.data;
};

export const invokeAutomationApi = async ({ automationId, body }) => {
    const response = await request.post(automationApis.invoke({ automationId }), body);
    return response.data;
};

export const getAutomationHistoryApi = async ({ automationId, page, offset, size }) => {
    const response = await request.get(automationApis.history({ automationId, page, offset, size }));
    return response.data;
};

export const moveAutomationNodeApi = async ({ automationId, nodeId, body }) => {
    const response = await request.put(automationApis.move({ automationId, nodeId }), body);
    return response.data;
};

export const uploadAutomationLogoApi = async ({ formData }) => {
    const response = await request.post(automationApis.uploadLogo(), formData, {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: function(progressEvent) {
            console.log('progressEvent', progressEvent);
        }
    });
    return response.data;
};

export const removeAutomationLogoApi = async ({ automationId }) => {
    const response = await request.delete(automationApis.removeLogo({ automationId }));
    return response.data;
};
