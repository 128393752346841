import { HIGHLIGHT_TYPES } from 'const/gridUI';
import React, { useMemo, useRef } from 'react';
import Highlighter from 'components/highlighter';
import latinize from 'latinize';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import { useDataFromTBCurrentSource } from 'hooks/gridUI';
import { generateTags } from 'utils/gridUI/tag';
import { useIsShowAutoQA } from 'hooks/gridUI/autoQA';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import LQATargetHighlighter from 'components/lqa/LQATargetHighlighter';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';
import LQASourceHighlighter from 'components/lqa/LQASourceHighlighter';
import { getHighlightWords } from 'utils/gridUI/cell';
import classNames from 'classnames';
import { getLines } from 'utils/text';

function TranslationText({
    customProperties,
    value,
    rowHeight = 32,
    rowIndex,
    columnIndex,
    filterValue,
    rowId,
    group,
    columnId,
    tokenDetection,
    isShowTag,
    predefinedTokens = [],
    parentTokens = [],
    validations = [],
    targetValidations = {},
    isChildDependency,
    isReadOnly,
    highlightPositions = [],
    isViewMode = false,
    isCaseSensitive
}) {
    const dispatch = useDispatch();
    const languageDetail = LANGUAGE_DETAIL_BY_CODE?.[group];
    const isAccentInsensitive = languageDetail?.accentInsensitive;
    const isLatinize = languageDetail?.latinize;
    const tbSource = useDataFromTBCurrentSource({ rowId, langSource: group, isChildDependency });
    const isTbSource = useRef(false);
    const isShowAutoQA = useIsShowAutoQA();

    const lines = useMemo(() => {
        return getLines(rowHeight);
    }, [rowHeight]);

    const style = useMemo(() => {
        return {
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: `${lines}`,
            display: '-webkit-box'
        };
    }, [lines]);

    const _filterValue = useMemo(() => {
        if (tbSource?.length) {
            isTbSource.current = true;
            return tbSource;
        }
        isTbSource.current = false;
        return [filterValue];
    }, [tbSource, filterValue]);

    const isTargetLang = customProperties?.localizationType === TRANSLATION_TYPES.TARGET_LANG;

    const isRtlDirection = React.useMemo(() => {
        return languageDetail?.rtl;
    }, [languageDetail]);

    const handleReplaceSuggestion = React.useCallback(
        (e, validation, suggestion = '') => {
            const text = value?.toString();
            const newText = text?.slice(0, validation.start) + suggestion + text?.slice(validation?.end);
            dispatch(
                gridUIActions.cellClickAwayAndStay({
                    value: newText,
                    type: columnTypes.TRANSLATION,
                    rowId,
                    columnId
                })
            );
        },
        [value, dispatch, rowId, columnId]
    );

    if (typeof value !== 'string' && typeof value !== 'number') {
        return null;
    }

    const mapValue = value?.toString();

    const _tokens = generateTags({ tokenDetection, predefinedTokens, text: mapValue });

    const highlightWords = getHighlightWords(mapValue, highlightPositions);

    return (
        <p
            className={
                !isViewMode &&
                classNames(
                    `body2 cell_${rowIndex}_${columnIndex} RECORD_${rowId} leading-normal w-full !overflow-hidden text-ellipsis align-top break-all break-words whitespace-pre-wrap  editor`,
                    languageDetail?.id === 'jaJP' && `jpFont`
                )
            }
            style={{
                ...style,
                height: rowHeight
            }}
        >
            {isShowAutoQA ? (
                isTargetLang ? (
                    <LQATargetHighlighter
                        value={[{ type: 'paragraph', children: [{ text: mapValue || '' }] }]}
                        onBlur={() => {}}
                        dir={isRtlDirection ? 'rtl' : 'ltr'}
                        onChange={() => {}}
                        placeholder=""
                        className={`cell-editor`}
                        autoFocus
                        isChildDependency={isChildDependency}
                        validations={validations}
                        readOnly
                        columnId={columnId}
                        rowId={rowId}
                        onReplace={handleReplaceSuggestion}
                        showTooltip={!isReadOnly}
                    />
                ) : (
                    <LQASourceHighlighter
                        value={[{ type: 'paragraph', children: [{ text: mapValue || '' }] }]}
                        onBlur={() => {}}
                        dir={isRtlDirection ? 'rtl' : 'ltr'}
                        onChange={() => {}}
                        placeholder=""
                        className={`cell-editor`}
                        autoFocus
                        isChildDependency={isChildDependency}
                        targetValidations={targetValidations}
                        readOnly
                        columnId={columnId}
                        rowId={rowId}
                        showTooltip={!isReadOnly}
                    />
                )
            ) : (
                <Highlighter
                    sanitize={isLatinize ? latinize : undefined}
                    highlightClassName={isTbSource.current ? 'highlight-tb' : 'highlight'}
                    searchWords={[
                        ..._filterValue?.map(key => ({ key, type: HIGHLIGHT_TYPES.DEFAULT })),
                        ...highlightWords?.map(value => ({
                            key: value?.toString(),
                            type: HIGHLIGHT_TYPES.DEFAULT
                        })),
                        ...(isShowTag
                            ? _tokens?.map(token => ({
                                  key: token,
                                  type: HIGHLIGHT_TYPES.TOKEN
                              }))
                            : [])
                    ]}
                    parentTokens={parentTokens}
                    autoEscape={true}
                    textToHighlight={mapValue || ''}
                    accentInsensitive={isAccentInsensitive}
                    isCombinedNearest={false}
                    isChildDependency={isChildDependency}
                    caseSensitive={!!isCaseSensitive}
                />
            )}
        </p>
    );
}

export default React.memo(TranslationText);
