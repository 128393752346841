import * as types from '../types';

const handler = {
    [types.FETCH_BILL_INFO](state, { payload }) {
        return {
            ...state,
            isFetchingBillingInfo: true
        };
    },
    [types.FETCH_BILL_INFO_FAILED](state, { payload }) {
        return {
            ...state,
            isFetchingBillingInfo: false
        };
    },
    [types.FETCH_BILL_INFO_SUCCESS](state, { payload }) {
        const { billingInfo } = payload;
        return {
            ...state,
            isFetchingBillingInfo: false,
            billingInfo: billingInfo || {}
        };
    },
    [types.UPDATE_BILL_INFO](state, { payload }) {
        const { billingInfo } = payload;
        return {
            ...state,
            billingInfo
        };
    },
    [types.FETCH_BILL_HISTORY](state) {
        return {
            ...state,
            isFetchingBillingHistory: true
        };
    },
    [types.FETCH_BILL_HISTORY_FAILED](state) {
        return {
            ...state,
            isFetchingBillingHistory: false
        };
    },
    [types.FETCH_BILL_HISTORY_SUCCESS](state, { payload }) {
        const billingHistory = payload.billingHistory;
        return {
            ...state,
            isFetchingBillingHistory: false,
            billingHistory
        };
    },
    [types.FETCH_RECEIPT_INFO](state) {
        return {
            ...state,
            isFetchingReceiptInfo: true
        };
    },
    [types.FETCH_RECEIPT_INFO_FAILED](state) {
        return {
            ...state,
            isFetchingReceiptInfo: false
        };
    },
    [types.FETCH_RECEIPT_INFO_SUCCESS](state, { payload }) {
        const receiptInfo = payload.receiptInfo;
        return {
            ...state,
            isFetchingReceiptInfo: false,
            receiptInfo
        };
    },
    [types.FETCH_TAX_RATE_SUCCESS](state, { payload }) {
        const { taxRate } = payload;
        return {
            ...state,
            taxRate
        };
    },
    [types.FETCH_CUSTOMER_AND_SUBSCRIPTIONS_SUCCESS](state, { payload }) {
        const { customer } = payload;
        const companySubscription = { ...customer };
        delete companySubscription.subscriptions;
        const addonSubscriptions = customer?.subscriptions?.filter(sub => sub.type === 'ADD_ON');
        const seatSubscription = customer?.subscriptions?.find(sub => sub.type === 'SEAT');
        return {
            ...state,
            customer: companySubscription,
            companySubscription,
            addonSubscriptions,
            seatSubscription
        };
    }
};

export default handler;
