import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Divider } from '@material-ui/core';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import DialogContent from 'components/dialog/DialogContent';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
// import ShareSVG from 'assets/images/svg/ShareSVG';
import { LQA_TICKET_STATUS, LQA_TYPES } from 'const/gridUI';
import hexToRgba from 'hex-to-rgba';
import Avatar from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import { generateDefaultDateApiPublic, getFriendlyTime } from 'utils/datetime';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import SortFilterSVG from 'assets/images/svg/SortFilterSVG';
import ExpandSVG from 'assets/images/svg/ExpandSVG';
import { useCellDataAndColumnInfo, useShareViewGroupMembers } from 'hooks/gridUI';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import Tooltip from 'components/tooltip/Base';
import CommentItem from './CommentItem';
import {
    useGetLQATicketDetail,
    useGetOpenLQATicketDetail,
    useGetLQATicket,
    checkIsTicketOpen,
    useGetLqaTab
} from 'hooks/gridUI/lqa';
import {
    commentLQATicketHandler,
    fetchLQATicketDetail,
    setLQATicketDetail,
    setOpenLQATicketDetail,
    updateLQATicket,
    jumpToCell,
    setOpenConfirmDeleteTicket,
    setLqaTicket,
    updateLqaTicketStatus,
    increaseDecreaseLqaTotal,
    setLqaPageNumber
} from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import Dialog from 'components/dialog/Dialog';
import LQATags from './LQATags';
import Spinner from 'components/spinner/Base';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { getFullName } from 'utils/user';
import { scrollInToView } from 'utils/scroll';
import TicketDetailDescription from './TicketDetailDescription';
import TicketDetailSummary from './TicketDetailSummary';
import { isKbCtrlAndSpace, isKbSearch } from 'utils/keyboard';
import TicketDetailPriority from './TicketDetailPriority';
import TicketDetailTitle from './TicketDetailTitle';
import TicketMinimize from './TicketMinimize';
import TicketDetailContent from './TicketDetailContent';
import { enqueueSnackbar } from 'notifier/actions';
import * as shareViewActions from 'gridUI/actions/shareView';
import TicketDetailAssignee from './TicketDetailAssignee';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { sendManualTrack } from 'tracker';
import EditorMentionReplyBox from './EditorMentionReplyBox';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 620,
        height: 562,
        padding: 0
    },
    footer: {
        padding: 0,
        maxWidth: 620
    },
    checkIcon: {
        paddingRight: theme.spacing(1)
    },
    title: {
        borderBottom: `1px solid ${theme.colors.border}`
    },
    ticketId: {
        color: theme.colors.lightGreyBlue
    },

    tag: {
        borderRadius: 3,
        padding: `0px 4px`
    },
    accuracy: {
        // border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        background: hexToRgba(theme.colors.dodgerBlue, 0.1)
    },
    compliance: {
        // border: `1px solid ${hexToRgba(theme.colors.fuchsiaBlue, 0.3)}`,
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.1)
    },
    header: {
        padding: theme.spacing(4)
    },
    body: {
        padding: theme.spacing(4)
    },
    dlFlex: {
        display: 'flex'
    },
    key: {
        padding: `2px 4px`,
        background: theme.colors.white,
        borderRadius: 2
    },
    comment: {
        background: theme.colors.ghostwhite,
        padding: theme.spacing(4),
        flex: 1
    },
    divider: {
        width: `calc(100% - ${2 * theme.spacing(4)}px)`,
        margin: '0 auto'
    },
    hover: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    pb2: {
        paddingBottom: theme.pb(2)
    },
    pb3: {
        paddingBottom: theme.pb(3)
    },
    commentItem: {
        width: '100%',
        '&:first-child': {
            paddingTop: theme.spacing(4)
        },
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(4)
        }
    },
    expandSVG: {
        verticalAlign: 'text-top'
    },
    loadingWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: hexToRgba(theme.colors.white, 0.8),
        zIndex: 1
    }
}));

function TicketDetail({ dbId }) {
    const classes = useStyles();
    // const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [fetchingCellData, setFetchingCellData] = React.useState(false);
    const [isOpenTicketMinimize, setIsOpenTicketMinimize] = React.useState(false);
    const scrollRef = React.useRef();
    const openLQATicketDetail = useGetOpenLQATicketDetail();
    const ticket = useGetLQATicketDetail();
    const userInfo = useCurrentUserFullInfo();
    const members = useShareViewGroupMembers() || [];
    const lqaTicket = useGetLQATicket();
    const lqaTab = useGetLqaTab();
    const roles = useRole();
    const location = useLocation();
    const { viewId } = useParams();

    const accessManageTicket = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_TICKET];

    React.useEffect(() => {
        const queryParams = queryParamsToObject(window?.location?.search);
        const selectedTicket = queryParams?.selectedTicket;
        if (selectedTicket) {
            const arr = selectedTicket.split('-');
            if (arr.length) {
                dispatch(setLQATicketDetail({ id: arr[2] }));
                dispatch(setOpenLQATicketDetail(true));
            }
        }
    }, [dispatch, location]);

    React.useEffect(() => {
        dispatch(shareViewActions.fetchGroupsByView(viewId));
    }, [dispatch, viewId]);

    const {
        id,
        columnId,
        priority,
        recordId: rowId,
        summary,
        status,
        type = LQA_TYPES.BASIC,
        audit = {},
        description,
        lqaModel,
        lqaModelCategory,
        lqaModelSubCategory,
        lqaModelSeverity,
        comments = [],
        assignee,
        mentionedUsers = []
    } = ticket;

    const { createdBy = {}, createdTime } = audit;
    const { id: createdUserId } = createdBy;

    const closeTicketMinimize = React.useCallback(() => {
        setIsOpenTicketMinimize(false);
    }, []);

    const closeDetailTicketHandler = React.useCallback(() => {
        dispatch(setOpenLQATicketDetail(false));
        const status = checkIsTicketOpen(ticket.status) ? LQA_TICKET_STATUS.OPEN : LQA_TICKET_STATUS.RESOLVED;
        const idx = lqaTicket[status].findIndex(t => t.id === ticket.id);
        const allIdx = lqaTicket[LQA_TICKET_STATUS.ALL].findIndex(t => t.id === ticket.id);
        const newTicket = { ...ticket, commentCount: ticket.comments?.length || 0 };
        if (idx > -1) {
            lqaTicket[status][idx] = newTicket;
        }
        if (allIdx > -1) {
            lqaTicket[LQA_TICKET_STATUS.ALL][allIdx] = newTicket;
        }
        if (idx > -1 || allIdx > -1) {
            dispatch(setLqaTicket({ ...lqaTicket }));
        }
    }, [dispatch, ticket, lqaTicket]);

    const navigateToCell = React.useCallback(() => {
        closeDetailTicketHandler();
        dispatch(
            jumpToCell({
                columnId,
                recordId: rowId,
                displayDefaultError: false,
                errorCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: 'This cell was deleted',
                            type: 'info'
                        })
                    );
                }
            })
        );
        setIsOpenTicketMinimize(true);
    }, [dispatch, columnId, rowId, closeDetailTicketHandler]);

    React.useEffect(() => {
        const handleEvent = e => {
            if (isKbSearch(e) || isKbCtrlAndSpace(e)) {
                e.stopPropagation();
                e.preventDefault();
            }
            if (isKbCtrlAndSpace(e)) {
                navigateToCell();
            }
        };
        if (openLQATicketDetail) {
            window.addEventListener('keydown', handleEvent, true);
        } else {
            window.removeEventListener('keydown', handleEvent, true);
        }
        return () => {
            window.removeEventListener('keydown', handleEvent, true);
        };
    }, [openLQATicketDetail, navigateToCell]);

    React.useEffect(() => {
        if (openLQATicketDetail) {
            closeTicketMinimize();
            if (id) {
                setLoading(true);
                dispatch(
                    fetchLQATicketDetail({
                        ticketId: id,
                        errorCallback: () => {
                            dispatch(setOpenLQATicketDetail(false));
                        },
                        finallyCallback: () => setLoading(false)
                    })
                );
            }
        }
    }, [dispatch, openLQATicketDetail, id, closeTicketMinimize]);

    const isAllowEdit = React.useMemo(() => {
        return accessManageTicket === roleConst.FULL;
    }, [accessManageTicket]);

    const saveHandler = description => {
        const createdBy = { id: userInfo.id, name: getFullName(userInfo) };
        const datetime = generateDefaultDateApiPublic(new Date());
        const comment = {
            id: datetime,
            description,
            audit: {
                createdBy: createdBy,
                lastModifiedBy: createdBy,
                createdTime: datetime,
                lastModifiedTime: datetime
            }
        };
        const oldComments = [...(comments || [])];
        const oldCommentCount = oldComments.length;
        dispatch(
            setLQATicketDetail({ ...ticket, comments: [...oldComments, comment], commentCount: oldCommentCount + 1 })
        );
        dispatch(
            commentLQATicketHandler({
                ticketId: id,
                body: { description },
                successCallback: responseData => {
                    dispatch(
                        setLQATicketDetail({
                            ...ticket,
                            comments: [...oldComments, responseData],
                            commentCount: oldCommentCount + 1
                        })
                    );
                    scrollInToView(scrollRef.current);
                },
                errorCallback: () => {
                    dispatch(
                        setLQATicketDetail({ ...ticket, comments: [...oldComments], commentCount: oldComments.length })
                    );
                }
            })
        );
    };

    const cancelHandler = () => {};

    const { column } = useCellDataAndColumnInfo({ columnId, rowId });

    const isLQAType = React.useMemo(() => {
        return type === LQA_TYPES.LQA_ISSUE;
    }, [type]);

    const isEmptyComments = React.useMemo(() => {
        return comments?.length === 0;
    }, [comments]);

    const queryComments = React.useMemo(() => {
        return (
            comments?.sort(
                (a, b) => new Date(b.audit.createdTime).getTime() - new Date(a.audit.createdTime).getTime()
            ) || []
        );
    }, [comments]);

    const isResolved = React.useMemo(() => {
        return status === LQA_TICKET_STATUS.RESOLVED;
    }, [status]);

    const handleUpdateTicket = React.useCallback(
        (data, updateStatus = false) => {
            if (!ticket) return;
            const combinedTicket = { ...ticket, ...data, assigneeId: undefined };
            dispatch(setLQATicketDetail(combinedTicket));
            const oldStatus = ticket.status;
            const oldLqaTicket = JSON.parse(JSON.stringify(lqaTicket));
            if (updateStatus) {
                dispatch(updateLqaTicketStatus({ ticketId: ticket.id, currentStatus: oldStatus }));
                dispatch(
                    increaseDecreaseLqaTotal({
                        increaseStatus: checkIsTicketOpen(oldStatus)
                            ? LQA_TICKET_STATUS.RESOLVED
                            : LQA_TICKET_STATUS.OPEN,
                        decreaseStatus: checkIsTicketOpen(oldStatus)
                            ? LQA_TICKET_STATUS.OPEN
                            : LQA_TICKET_STATUS.RESOLVED
                    })
                );
            }
            dispatch(
                updateLQATicket({
                    ticket: { id: ticket.id, ...data, columnId, recordId: rowId, assignee: undefined },
                    updateStatus,
                    snackbarMessage: (
                        <>
                            <b style={{ marginRight: '5px' }}>Ticket-{ticket.id}</b> mark as{' '}
                            {oldStatus !== LQA_TICKET_STATUS.RESOLVED ? t('global_resolved') : t('unresolved')}
                        </>
                    ),
                    errorCallback: () => {
                        dispatch(setLQATicketDetail(ticket));
                        if (updateStatus) {
                            dispatch(setLqaTicket(oldLqaTicket));
                            dispatch(
                                increaseDecreaseLqaTotal({
                                    increaseStatus: checkIsTicketOpen(oldStatus)
                                        ? LQA_TICKET_STATUS.OPEN
                                        : LQA_TICKET_STATUS.RESOLVED,
                                    decreaseStatus: checkIsTicketOpen(oldStatus)
                                        ? LQA_TICKET_STATUS.RESOLVED
                                        : LQA_TICKET_STATUS.OPEN
                                })
                            );
                        }
                    },
                    successCallback: () => {
                        if (updateStatus) {
                            if (lqaTab === LQA_TICKET_STATUS.ALL) {
                                dispatch(
                                    setLqaPageNumber({
                                        [LQA_TICKET_STATUS.OPEN]: 1,
                                        [LQA_TICKET_STATUS.RESOLVED]: 1
                                    })
                                );
                            } else {
                                const tabStatus = checkIsTicketOpen(oldStatus)
                                    ? LQA_TICKET_STATUS.RESOLVED
                                    : LQA_TICKET_STATUS.OPEN;
                                dispatch(
                                    setLqaPageNumber({
                                        [tabStatus]: 1,
                                        [LQA_TICKET_STATUS.ALL]: 1
                                    })
                                );
                            }
                        }
                    }
                })
            );
        },
        [dispatch, ticket, columnId, rowId, lqaTab, lqaTicket, t]
    );

    const changeStatusHandler = React.useCallback(() => {
        const newStatus =
            ticket.status !== LQA_TICKET_STATUS.RESOLVED ? LQA_TICKET_STATUS.RESOLVED : LQA_TICKET_STATUS.REOPENED;
        if (newStatus === LQA_TICKET_STATUS.RESOLVED) {
            sendManualTrack({ type: 'Mark Ticket as Resolved in Popup' });
        } else {
            sendManualTrack({ type: 'Reopen Ticket in Popup' });
        }
        handleUpdateTicket({ status: newStatus }, true);
    }, [ticket, handleUpdateTicket]);

    const changeSummary = React.useCallback(
        content => {
            handleUpdateTicket({ summary: content });
        },
        [handleUpdateTicket]
    );

    const changeDescription = React.useCallback(
        content => {
            handleUpdateTicket({ description: content });
        },
        [handleUpdateTicket]
    );

    const changePriority = React.useCallback(
        content => {
            handleUpdateTicket({ priority: content });
        },
        [handleUpdateTicket]
    );

    const clickDelete = React.useCallback(() => {
        sendManualTrack({ type: 'Delete Ticket in Popup' });
        dispatch(
            setOpenConfirmDeleteTicket({
                isOpen: true,
                ticket
            })
        );
    }, [dispatch, ticket]);

    const onOpenTicketDetail = React.useCallback(() => {
        dispatch(setOpenLQATicketDetail(true));
    }, [dispatch]);

    const changeAssignee = React.useCallback(
        data => {
            handleUpdateTicket(data);
        },
        [handleUpdateTicket]
    );

    return (
        <>
            <Dialog
                maxWidth={false}
                open={openLQATicketDetail}
                onClose={closeDetailTicketHandler}
                className={classes.dialog}
            >
                {(loading || fetchingCellData) && (
                    <Grid container justify="center" alignItems="center" className={classes.loadingWrapper}>
                        <Grid item>
                            <Spinner size={30} thick={4} />
                        </Grid>
                    </Grid>
                )}
                <DialogTitle
                    title={
                        <TicketDetailTitle
                            isResolved={isResolved}
                            changeStatusHandler={changeStatusHandler}
                            openLQATicketDetail={openLQATicketDetail}
                            ticketId={id}
                            ticketSummary={summary}
                            canManageTicket={isAllowEdit}
                        />
                    }
                    actions={
                        isAllowEdit ? (
                            <Grid container direction="row" alignItems="center" spacing={2}>
                                <Grid item onClick={clickDelete}>
                                    <IconButton size="small">
                                        <DeleteSVG />
                                    </IconButton>
                                </Grid>
                                {/* <Grid item>
                    <IconButton size="small">
                        <ShareSVG color={theme.colors.steel} />
                    </IconButton>
                </Grid> */}
                            </Grid>
                        ) : null
                    }
                    onClose={closeDetailTicketHandler}
                    className={classes.title}
                />
                <DialogContent className={classes.root} id="ticket-dialog-content">
                    <Grid style={{ height: '100%' }} container direction="column" wrap="nowrap">
                        <Grid item className={classes.header} container direction="column" wrap="nowrap">
                            <Grid container className={classes.pb2} spacing={1} alignItems="center">
                                <Grid item>
                                    <h4 className="prose prose-lg font-medium text-grey-blue">#Ticket-{id}</h4>
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <TicketDetailSummary
                                        content={summary}
                                        onChange={changeSummary}
                                        isAllowEdit={isAllowEdit}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className={classes.pb2}>
                                {isLQAType && (
                                    <LQATags
                                        iconSize="large"
                                        lqaModel={lqaModel}
                                        lqaModelCategory={lqaModelCategory}
                                        lqaModelSubCategory={lqaModelSubCategory}
                                        lqaModelSeverity={lqaModelSeverity}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={2} alignItems="center">
                                    <Grid item>
                                        <Avatar src={getAvatarUrl(createdUserId)} size={24}>
                                            <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <p className="caption">{getFriendlyTime(createdTime)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item container direction="column" className={classes.body}>
                            <Grid item>
                                <Grid container wrap="nowrap" direction="column" spacing={3}>
                                    <Grid item>
                                        <TicketDetailPriority
                                            priority={priority}
                                            isAllowEdit={isAllowEdit}
                                            onChange={changePriority}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row">
                                            <Grid item xs={2}>
                                                <p className="caption">Target</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Grid container alignItems="center" direction="row" spacing={1}>
                                                    <Grid item className={classes.dlFlex}>
                                                        <ColumnIcon {...column} />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2">{column?.name}</p>
                                                    </Grid>

                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <Grid item>
                                                                    <p className="text-light font-medium">
                                                                        Go to this cell
                                                                    </p>
                                                                </Grid>
                                                                <Grid item>
                                                                    <p className="bg-white rounded-sm py-0.5 px-1 body2">
                                                                        Ctrl
                                                                    </p>
                                                                </Grid>
                                                                <Grid item>
                                                                    <p className="bg-white rounded-sm py-0.5 px-1 body2">
                                                                        {t('space')}
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    >
                                                        <Grid item className={classes.hover} onClick={navigateToCell}>
                                                            <ExpandSVG className={classes.expandSVG} />
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <TicketDetailAssignee
                                        assigneeId={assignee?.id}
                                        members={members}
                                        changeAssignee={changeAssignee}
                                        isAllowEdit={isAllowEdit}
                                    />

                                    <TicketDetailContent
                                        dbId={dbId}
                                        column={column}
                                        rowId={rowId}
                                        columnId={columnId}
                                        setFetchingCellData={setFetchingCellData}
                                    />

                                    <Grid item>
                                        <Grid container direction="row">
                                            <Grid item xs={2}>
                                                <p className="caption">{t('global_description')}</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                {!loading && ticket && openLQATicketDetail && (
                                                    <TicketDetailDescription
                                                        description={description}
                                                        onChange={changeDescription}
                                                        isAllowEdit={isAllowEdit}
                                                        mentionedUsers={mentionedUsers}
                                                        members={members}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isEmptyComments && (
                            <Grid item container wrap="nowrap" direction="column" className={classes.comment}>
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" justify="space-between">
                                        <Grid item>
                                            <p className="body2">Comment</p>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" alignItems="center" spacing={2}>
                                                <Grid item className={classes.dlFlex}>
                                                    <SortFilterSVG />
                                                </Grid>
                                                <Grid item>
                                                    <p className="body2">Newest first</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <span ref={scrollRef} style={{ height: 1 }}>
                                        &nbsp;
                                    </span>
                                    <Grid container direction="column" wrap="nowrap" alignItems="center">
                                        {openLQATicketDetail &&
                                            queryComments?.map(comment => {
                                                return (
                                                    <Grid className={classes.commentItem} item key={comment?.id}>
                                                        <CommentItem
                                                            comment={comment}
                                                            isAllowEdit={isAllowEdit}
                                                            members={members}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                {isAllowEdit && openLQATicketDetail && (
                    <DialogActions className={classes.footer}>
                        <EditorMentionReplyBox
                            saveHandler={saveHandler}
                            cancelHandler={cancelHandler}
                            members={members}
                        />
                    </DialogActions>
                )}
            </Dialog>
            <TicketMinimize
                ticket={ticket}
                isOpen={isOpenTicketMinimize}
                onOpenTicketDetail={onOpenTicketDetail}
                onClose={closeTicketMinimize}
            />
        </>
    );
}

export default React.memo(TicketDetail);
