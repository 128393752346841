import React from 'react';

const NonBreakingSpaceSVG = ({ color = '#2A293A', children, ...rest }) => {
    return (
        <svg width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M1.84901 4.46102V0.975016H2.85701V3.46702H9.14301V0.975016H10.151V4.46102H1.84901ZM0.169006 1.53502V0.541016H2.85701V1.53502H0.169006ZM9.14301 1.53502V0.541016H11.831V1.53502H9.14301Z"
                fill="#2A293A"
            />
            {children}
        </svg>
    );
};

export default React.memo(NonBreakingSpaceSVG);
