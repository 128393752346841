import * as types from '../types';

export function openCellPreview() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { isOpenCellPreview } = gridUI;

        if (isOpenCellPreview) {
            return false;
        }
        dispatch(_openCellPreview());
    };
}

function _openCellPreview() {
    return {
        type: types.OPEN_CELL_PREVIEW
    };
}

export function toggleCellPreview() {
    return {
        type: types.TOGGLE_CELL_PREVIEW
    };
}

export function closeCellPreview() {
    return {
        type: types.CLOSE_CELL_PREVIEW
    };
}
