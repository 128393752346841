import React from 'react';

function IconCaretRightSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
            <path
                fill={color}
                fillRule="nonzero"
                d="M.5 9.917V1.083A.3.3 0 0 1 .974.839l6.184 4.417a.3.3 0 0 1 0 .488L.974 10.161A.3.3 0 0 1 .5 9.917z"
            />
        </svg>
    );
}

export default React.memo(IconCaretRightSVG);
