import React from 'react';
import { getFormatDateTime } from 'utils/datetime';
import { ROW_HEIGHT } from 'const/gridUI';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative'
    }
}));

function LastModifiedDate({ value: cellValue, isReadOnly }) {
    const classes = useStyles({ isReadOnly });

    if (!cellValue || cellValue?.length === 0) return <div className={classes.root} />;

    return (
        <div className={classes.root}>
            <div>{getFormatDateTime(cellValue)}</div>
        </div>
    );
}

export default React.memo(LastModifiedDate);
