import React from 'react';

function GoogleTranslateSVG({ color = '#1E88E5', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M17.4772 6.34445H14.4304V5.93545C14.4304 5.55515 14.1214 5.24609 13.7411 5.24609C13.3608 5.24609 13.0517 5.55515 13.0517 5.93545V6.34445H12.0683C11.688 6.34445 11.3789 6.6535 11.3789 7.0338C11.3789 7.41409 11.688 7.72315 12.0683 7.72315H15.0553C14.9542 9.11103 14.4395 10.4369 13.5756 11.5284C13.4538 11.6686 13.3929 11.8536 13.409 12.0386C13.425 12.2247 13.5147 12.3959 13.6594 12.5142C13.803 12.6314 13.9892 12.6865 14.1741 12.6659C14.3591 12.644 14.528 12.5487 14.6417 12.4016C15.7056 11.0631 16.3317 9.42956 16.434 7.72338H17.4634C17.8437 7.72683 18.1562 7.42123 18.1597 7.04094C18.1631 6.66065 17.8575 6.34815 17.4772 6.34471L17.4772 6.34445Z"
                fill={color}
            />
            <path
                d="M17.179 13.3732C16.4322 13.3732 15.6992 13.1733 15.0558 12.7941C14.7307 12.6183 14.3263 12.7298 14.1367 13.0469C13.5025 14.2923 16.4069 14.7702 17.202 14.7519C17.5823 14.745 17.8867 14.4313 17.8798 14.051C17.8729 13.6707 17.5593 13.3663 17.179 13.3732L17.179 13.3732Z"
                fill={color}
            />
            <path
                d="M18.2719 3.04147H9.16335L8.12935 0.541467C8.07305 0.417385 7.98344 0.311688 7.87199 0.233556C7.75825 0.161176 7.62613 0.120965 7.49055 0.118668H1.72761C0.880876 0.0819029 0.163964 0.734491 0.119141 1.58008V15.5139C0.173138 16.3526 0.887758 16.9937 1.72761 16.9569H9.36085L9.53088 19.2547C9.56305 19.6132 9.86062 19.89 10.2202 19.8981H18.2717C19.1184 19.9348 19.8354 19.2823 19.8802 18.4367V4.48431C19.8262 3.64561 19.1116 3.00455 18.2717 3.04131L18.2719 3.04147ZM5.9604 13.8685C0.831581 13.6755 0.836287 6.32264 5.9604 6.1297C6.82896 6.06996 9.44835 6.91094 8.59829 8.1012C8.47535 8.23677 8.30302 8.3172 8.12035 8.32638C7.93767 8.33443 7.75958 8.27009 7.62402 8.14715C6.89332 7.50492 5.85588 7.34636 4.96661 7.74045C4.07852 8.13568 3.50061 9.01227 3.48799 9.98539C3.52016 12.8622 7.56417 13.3998 8.36858 10.6747H6.20864C5.82835 10.6747 5.51929 10.3657 5.51929 9.98539C5.51929 9.6051 5.82835 9.29604 6.20864 9.29604H9.15893C9.53923 9.29604 9.84829 9.6051 9.84829 9.98539C9.85058 11.0171 9.44158 12.0063 8.71202 12.7359C7.98246 13.4643 6.99211 13.8721 5.9604 13.8687V13.8685ZM18.2719 18.5009H12.201C12.2653 18.4457 14.3104 16.8419 14.3701 16.7821C14.3896 16.7672 14.4057 16.7511 14.4207 16.7316L14.4666 16.681C14.4804 16.6638 14.493 16.6454 14.5034 16.6259C14.5034 16.6259 14.5264 16.5845 14.5356 16.5615C14.5459 16.5443 14.5539 16.5259 14.5585 16.5064V16.442C14.5838 16.3329 14.5838 16.2203 14.5585 16.1112C14.5482 16.0721 14.5356 16.0342 14.5218 15.9963L9.73765 4.42009H18.2718C18.3568 4.4132 18.4407 4.44422 18.5015 4.50281V18.4046C18.4464 18.4735 18.3602 18.5103 18.2718 18.5011L18.2719 18.5009Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(GoogleTranslateSVG);
