import i18n from 'i18n';

export const ERROR_MESSAGES = {
    // General
    unknown: i18n.t('theres_a_glitch'),
    internal_server_error: i18n.t('theres_a_glitch'),
    not_found: i18n.t('theres_a_glitch'),
    network_error: "There's a glitch! Please check your network provider.",
    // Auth
    invalid_grant: 'Invalid email or password.'

    // Databases
    //database_missing: 'Database is not exist',

    // Views
    //view_missing: 'View is not exist'
};
