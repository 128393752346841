import React from 'react';
import { Grid } from '@material-ui/core';
import * as gridActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { BOOLEAN } from 'const/columnTypes';
import ThreeStatesCheckbox from 'components/checkbox/ThreeStateCheckBox';
import { getBooleanValue } from 'utils/gridUI/formatData';
import { OPERATOR } from 'gridUI/conditions';

function CheckboxForPresent({ columnId, isSelectionActive, quickFilters, checkboxStyle = {} }) {
    const filterData = quickFilters[columnId];
    const dispatch = useDispatch();

    const handleChange = (e, nextState) => {
        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: getBooleanValue(nextState),
                type: BOOLEAN,
                currentState: nextState,
                operator: OPERATOR.equal
            })
        );
        dispatch(gridActions.resetCellStatus());
        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    return (
        <Grid container justify="center">
            <ThreeStatesCheckbox
                className={'relative left-3'}
                state={(filterData && filterData.currentState) || 0}
                onChange={handleChange}
                style={checkboxStyle}
            />
        </Grid>
    );
}

export default React.memo(CheckboxForPresent);
