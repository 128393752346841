import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getPrivilegesApi, getWorkspacePrivilegesApi } from 'services/privileges';

export function setDefaultCompanySetting({ companyId }) {
    return {
        type: types.SET_DEFAULT_COMPANY_SETTING,
        payload: {
            companyId
        }
    };
}

export function fetchPrivileges() {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_fetchPrivilegesAction());
        try {
            const privileges = await getPrivilegesApi({ companyId });
            dispatch(_fetchPrivilegesActionSuccess({ privileges }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchPrivileges] ${message}`,
                    type: 'info'
                })
            );
        }
    };
}

function _fetchPrivilegesAction() {
    return {
        type: types.FETCH_PRIVILEGE
    };
}

function _fetchPrivilegesActionSuccess({ privileges }) {
    return {
        type: types.FETCH_PRIVILEGE_SUCCESS,
        payload: {
            privileges
        }
    };
}

export function fetchWorkspacePrivileges() {
    return async function(dispatch, getState) {
        dispatch(_fetchWorkspacePrivilegesAction());
        try {
            const workspacePrivileges = await getWorkspacePrivilegesApi();
            dispatch(_fetchWorkspacePrivilegesActionSuccess({ workspacePrivileges }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchWorkspacePrivileges] ${message}`,
                    type: 'error'
                })
            );
        }
    };
}

function _fetchWorkspacePrivilegesAction() {
    return {
        type: types.FETCH_WORKSPACE_PRIVILEGE
    };
}

function _fetchWorkspacePrivilegesActionSuccess({ workspacePrivileges }) {
    return {
        type: types.FETCH_WORKSPACE_PRIVILEGE_SUCCESS,
        payload: {
            workspacePrivileges
        }
    };
}
