import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { INPUT_HEIGHT, POPUP_PADDING_TOP_BOTTOM } from 'const/style';

const useStyles = makeStyles(theme => ({
    input: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: INPUT_HEIGHT,
        padding: 0,
        paddingLeft: 8,
        fontSize: '0.875rem',
        fontWeight: 'normal',
        width: '100%',
        borderRadius: 4,
        background: theme.colors.paleGrey
    }
}));

function inputComponent({ inputRef, ...props }) {
    return (
        <div ref={inputRef} {...props}>
            {props.children}
        </div>
    );
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired
        })
    ])
};

function Control(props) {
    const {
        children,
        innerProps,
        innerRef,
        isFocused,
        selectProps: { TextFieldProps, value }
    } = props;
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            style={{
                padding: POPUP_PADDING_TOP_BOTTOM
            }}
            InputProps={{
                inputComponent,
                disableUnderline: true,
                inputProps: {
                    className: `cell-editor ${classes.input} ${isFocused ? 'focused' : ''}`,
                    ref: innerRef,
                    children,
                    ...innerProps,
                    value,
                    classes
                }
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    /**
     * Children to render.
     */
    children: PropTypes.node,
    /**
     * The mouse down event and the innerRef to pass down to the controller element.
     */
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired
        })
    ]).isRequired,
    selectProps: PropTypes.object.isRequired
};

export default React.memo(Control);
