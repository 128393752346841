import React from 'react';

function POSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M6 0V36H27L36 27V0H6Z" fill="#EFEEEE" />
            <path d="M27 27V36L36 27H27Z" fill="#ABABAB" />
            <path d="M18 36H27V27L18 36Z" fill="#DEDEDD" />
            <path d="M0 9V19.5H24V9H0Z" fill="#897EC6" />
            <path
                d="M5.71872 11.0488C6.04872 11.0488 6.32472 11.1043 6.55122 11.2153C6.77772 11.3263 6.95772 11.4718 7.09572 11.6518C7.23372 11.8318 7.33272 12.0373 7.39272 12.2683C7.45122 12.4978 7.48272 12.7348 7.48272 12.9748C7.48272 13.3048 7.43322 13.5928 7.33422 13.8388C7.23522 14.0848 7.09722 14.2858 6.92022 14.4403C6.74322 14.5963 6.52872 14.7148 6.27672 14.7913C6.02472 14.8678 5.74422 14.9098 5.43822 14.9098H4.74522V17.4748H3.44922V11.0488H5.71872ZM5.32122 13.9588C5.57922 13.9588 5.78922 13.8793 5.94672 13.7233C6.10572 13.5673 6.18522 13.3243 6.18522 12.9943C6.18522 12.6703 6.11622 12.4243 5.97822 12.2563C5.84022 12.0898 5.60922 12.0043 5.28522 12.0043H4.74522V13.9588H5.32122Z"
                fill="white"
            />
            <path
                d="M8.5675 12.9935C8.6125 12.584 8.713 12.23 8.8645 11.936C9.019 11.6435 9.238 11.4125 9.526 11.2475C9.8125 11.084 10.201 11 10.6855 11C11.1715 11 11.5585 11.084 11.8465 11.2475C12.133 11.4125 12.355 11.6435 12.5065 11.936C12.661 12.23 12.7585 12.584 12.8035 12.9935C12.8485 13.4045 12.871 13.853 12.871 14.339C12.871 14.8295 12.8485 15.281 12.8035 15.6875C12.7585 16.0955 12.6595 16.4465 12.5065 16.7405C12.3535 17.0345 12.133 17.2595 11.8465 17.4155C11.5585 17.5715 11.1715 17.6495 10.6855 17.6495C10.201 17.6495 9.8125 17.57 9.526 17.4155C9.238 17.2595 9.0175 17.0345 8.8645 16.7405C8.7115 16.4465 8.6125 16.0955 8.5675 15.6875C8.524 15.281 8.5 14.8295 8.5 14.339C8.5 13.8545 8.524 13.406 8.5675 12.9935ZM9.829 15.4415C9.85 15.7445 9.892 15.9905 9.955 16.1795C10.018 16.3685 10.108 16.5065 10.225 16.5935C10.342 16.6805 10.4965 16.724 10.6885 16.724C10.8805 16.724 11.035 16.6805 11.1535 16.5935C11.2705 16.505 11.3605 16.3685 11.4235 16.1795C11.4865 15.9905 11.5285 15.7445 11.5495 15.4415C11.5705 15.1385 11.581 14.7725 11.581 14.3405C11.581 13.9085 11.569 13.5425 11.5495 13.2425C11.53 12.9425 11.4865 12.6965 11.4235 12.5045C11.3605 12.3125 11.2705 12.173 11.1535 12.0875C11.0365 11.999 10.8805 11.9555 10.6885 11.9555C10.4965 11.9555 10.3435 11.999 10.225 12.0875C10.108 12.1745 10.018 12.3125 9.955 12.5045C9.892 12.6965 9.85 12.9425 9.829 13.2425C9.8065 13.5425 9.7975 13.9085 9.7975 14.3405C9.7975 14.7725 9.8065 15.1385 9.829 15.4415Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(POSVG);
