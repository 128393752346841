import React, { useState } from 'react';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import { queryParamsToObject, objectToQueryParams } from 'utils/urlQueryUtils';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditableText from 'components/inputs/EditableText';

import CreateCompanyForm from './CreateCompanyForm';
import SignUpForm from './SignUpForm';
import IdentifyForm from './IdentifyForm';
import { trackUserOnboarding } from 'services/external';
import { logIn, userSignUp } from './actions';
import { sendManualTrack } from 'tracker';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { checkExistence } from 'auth/actions';
import LoadingPage from './LoadingPage';
import * as appActions from 'app/actions';
import { useTranslation } from 'react-i18next';
import isValidEmail from 'utils/isValidEmail';
import i18n from 'i18n';

const steps = {
    FILL_EMAIL: 2,
    CREATE_COMPANY: 3,
    INDENTIFY: 4,
    LOADING: 5
};

export const SUBJECTS = [
    { label: `🌎 ${i18n.t('global_translation')}`, value: 'Translation' },
    { label: '🏷️ Strings', value: 'Strings' },
    { label: '🗄️ Metadata', value: 'Metadata' },
    { label: '🖥️ Digital assets', value: 'Digital assets' },
    { label: '📄 Dialogues or Scripts', value: 'Dialogues or Scripts' },
    { label: '📝 ️️Web-page content', value: 'Web-page content' },
    { label: '😉 Something else', value: 'Something else' },
    { label: "😶 I don't know yet", value: "I don't know yet" }
];

const useStyles = makeStyles(theme => ({
    editableText: {
        // height: 52,
        // overflow: 'hidden',
        // marginBottom: '-12px'
    }
}));

function SignUp() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const queryParams = queryParamsToObject(location?.search);

    const [formData, setFormData] = useState({
        companyName: '',
        companyDescription: '',
        firstName: location.state?.firstName || '',
        lastName: location.state?.lastName || '',
        email: queryParams?.email || '',
        password: '',
        subjects: SUBJECTS,
        job: {
            label: 'Select your role',
            value: null
        },
        argeeTerm: false
    });

    const [currentStep, setCurrentStep] = React.useState(steps.FILL_EMAIL);
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState(null);

    const onSendTracker = (step, values) => {
        trackUserOnboarding({
            email: values.email || queryParams?.email,
            utmSource: queryParams?.landing_source,
            queryParams,
            userInfo: values,
            step,
            isDone: step === steps.INDENTIFY
        });
    };

    const handleGoToCreateCompany = values => {
        setFormData({ ...formData, ...values });
        setCurrentStep(steps.CREATE_COMPANY);
        onSendTracker(steps.FILL_EMAIL, {
            firstName: values.firstName,
            lastName: values.lastName
        });
    };

    React.useEffect(() => {
        if (queryParams?.email) {
            dispatch(
                checkExistence({
                    email: queryParams?.email,
                    errorCallback: () => {
                        setSubmitting(false);
                    },
                    successCallback: result => {
                        setSubmitting(false);
                        if (result.email) {
                            history.push(`/signin?email=${queryParams?.email}`, {
                                error: 'You have been registered with this email. Please login instead'
                            });
                            return;
                        }
                    }
                })
            );
        }
    }, [dispatch, history, queryParams]);

    const handleGoToIdentify = values => {
        setSubmitting(true);
        dispatch(
            checkExistence({
                companyName: values.name,
                errorCallback: () => {
                    setSubmitting(false);
                },
                successCallback: result => {
                    setSubmitting(false);
                    if (result.company) {
                        setErrorMessage(t(`auth_duplicate_company_name_message`));
                        return;
                    }
                    setFormData({ ...formData, companyName: values.name, companyDescription: values.description });
                    setCurrentStep(steps.INDENTIFY);
                    onSendTracker(steps.CREATE_COMPANY, {
                        companyName: values.name
                    });
                }
            })
        );
    };

    const handleBackToCreateCompany = () => {
        sendManualTrack({
            type: `Click On Sign Up Back`
        });
        setCurrentStep(steps.CREATE_COMPANY);
    };

    const handleGoBackToGetStartedPage = () => {
        sendManualTrack({
            type: `Click On Sign Up Back`
        });
        history.push('/getstarted');
    };

    const handleGoBackToSignUpForm = () => {
        sendManualTrack({
            type: `Click On Sign Up Back`
        });
        setCurrentStep(steps.FILL_EMAIL);
    };

    const handleSubmit = () => {
        setSubmitting(true);
        setCurrentStep(steps.LOADING);
        dispatch(
            userSignUp({
                ...formData,
                errorCallback: () => {
                    setSubmitting(false);
                },
                successCallback: ({ email, password }) => {
                    dispatch(
                        logIn({
                            username: email,
                            password,
                            errorCallback: () => {
                                setSubmitting(false);
                            },
                            successCallback: () => {
                                onSendTracker(steps.INDENTIFY, {
                                    email,
                                    ...formData
                                });
                                setSubmitting(false);
                                dispatch(appActions.toggleChromeNotify());
                                dispatch(appActions.showFreeTrial());
                                history.push('/');
                            }
                        })
                    );
                }
            })
        );
    };

    const handleSubjectChange = newSubject => {
        const newSubjects = formData.subjects.map(subject => {
            if (subject.value === newSubject.value) {
                subject.isSelected = !subject.isSelected;
            }
            return subject;
        });
        setFormData({ ...formData, subjects: newSubjects });
    };

    const handleJobChange = newJob => {
        setFormData({ ...formData, job: newJob });
    };

    const handleEmailChange = () => {
        setFormData({ ...formData, isInputting: true });
    };

    const handleEmailEnter = value => {
        setFormData({ ...formData, isInputting: true });
        if (!value) {
            setEmailError(t(`global_this_field_is_required`));
        } else if (!isValidEmail(value)) {
            setEmailError('Invalid Email');
        } else {
            setEmailError(null);
            dispatch(
                checkExistence({
                    email: value,
                    errorCallback: () => {},
                    successCallback: result => {
                        if (result.email) {
                            setEmailError(t(`auth_duplicate_email_message`));
                        } else {
                            setFormData({ ...formData, email: value, isInputting: false });

                            const search = objectToQueryParams({ ...queryParams, email: value });
                            history.replace({ pathname: location?.pathname, search });
                        }
                    }
                })
            );
        }
    };

    switch (currentStep) {
        case steps.FILL_EMAIL:
        default:
            return (
                <AuthenticationWrapper isSigningUp>
                    <AuthFormWrapper
                        title={t(`global_welcome_gridly`)}
                        subTitle={
                            <Grid container direction="column" spacing={2} wrap="nowrap">
                                <Grid item>
                                    <Typography variant="subtitle1" display="inline">
                                        {t(`auth_init_account_welcome_title`)}{' '}
                                    </Typography>
                                    <span role="img" aria-label="handshake">
                                        👋
                                    </span>{' '}
                                    <Typography variant="subtitle1" display="inline">
                                        {t('auth_init_account_description')}
                                    </Typography>{' '}
                                    <EditableText
                                        className={classes.editableText}
                                        isEdit={!!emailError}
                                        name="email"
                                        type="email"
                                        value={formData.email || ''}
                                        textRender={valueText => {
                                            return (
                                                <Typography variant="h4" display="inline">
                                                    {valueText}
                                                </Typography>
                                            );
                                        }}
                                        onEnter={handleEmailEnter}
                                        onChange={handleEmailChange}
                                        error={!!emailError}
                                        errorText={emailError}
                                    />
                                </Grid>
                            </Grid>
                        }
                    >
                        <SignUpForm
                            initFormData={formData}
                            onNext={handleGoToCreateCompany}
                            onBack={handleGoBackToGetStartedPage}
                        />
                    </AuthFormWrapper>
                </AuthenticationWrapper>
            );
        case steps.CREATE_COMPANY:
            return (
                <AuthenticationWrapper>
                    <AuthFormWrapper title={t(`auth_sign_up_create_company_title`)}>
                        <CreateCompanyForm
                            initFormData={formData}
                            onNext={handleGoToIdentify}
                            isSubmitting={isSubmitting}
                            errorMessage={errorMessage}
                            onBack={handleGoBackToSignUpForm}
                        />
                    </AuthFormWrapper>
                </AuthenticationWrapper>
            );
        case steps.INDENTIFY:
            return (
                <AuthenticationWrapper>
                    <IdentifyForm
                        initFormData={formData}
                        onBack={handleBackToCreateCompany}
                        onSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        onSubjectChange={handleSubjectChange}
                        onJobChange={handleJobChange}
                    />
                </AuthenticationWrapper>
            );
        case steps.LOADING:
            return (
                <AuthenticationWrapper>
                    <LoadingPage />
                </AuthenticationWrapper>
            );
    }
}

export default SignUp;
