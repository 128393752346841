import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SleepBellSVG from 'assets/images/svg/SleepBellSVG';
import * as authActions from 'auth/actions';
import { useNotificationCenterWithKey } from 'hooks/auth';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import NotificationCenterItemWrapper from './NotificationCenterItemWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: 505,
        position: 'relative'
    },
    rootEmpty: {
        height: 505
    },
    lastedWrapper: {
        padding: '0 16px'
    },
    lasted: {
        fontSize: 13,
        fontWeight: 600,
        color: theme.colors.lightGreyBlue
    },
    markAll: {
        fontSize: 13,
        color: theme.palette.primary.main,
        cursor: 'pointer',
        userSelect: 'none'
    },
    empty: {
        flex: 1
    },
    svgWrapper: {
        marginBottom: 13,
        textAlign: 'center'
    },
    emptyContent: {
        color: theme.colors.steel
    },
    list: {
        marginTop: '16px',
        flex: 1,
        overflow: 'hidden auto'
    },
    showMore: {
        minHeight: 48,
        maxHeight: 48,
        padding: `0 12px`,
        borderTop: `1px solid ${theme.colors.divider}`,
        '& span': {
            color: theme.colors.highlight,
            cursor: 'pointer'
        }
    },
    loadingMore: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        position: 'relative'
    },
    loading: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: theme.colors.white
    }
}));

const ListNotificationCenter = ({ keyNotification }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const notification = useNotificationCenterWithKey(keyNotification) || {};
    const { isLoading, isLoadingMore, data = [], hasNextPage } = notification;

    const isEmpty = useMemo(() => {
        return data?.length === 0;
    }, [data]);

    const handleFetchMore = useCallback(() => {
        dispatch(authActions.fetchNotificationCenterWithKey({ key: keyNotification, isFetchMore: true }));
    }, [dispatch, keyNotification]);

    const handleMarkAll = useCallback(() => {
        dispatch(authActions.markAllNotificationCenterAsRead({ key: keyNotification }));
    }, [dispatch, keyNotification]);

    return (
        <Grid
            container
            direction="column"
            className={`${classes.root} ${isEmpty ? classes.rootEmpty : ''}`}
            wrap="nowrap"
        >
            <Grid container className={classes.lastedWrapper} justifyContent="space-between" wrap="nowrap">
                <Grid item>
                    <Typography className={classes.lasted}>{t('global_latest')}</Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.markAll} onClick={handleMarkAll}>
                        {t('global_mark_all_as_read')}
                    </Typography>
                </Grid>
            </Grid>
            {isLoading && (
                <Grid
                    container
                    direction="column"
                    className={classes.loading}
                    alignContent="center"
                    justifyContent="center"
                >
                    <CircularProgress size={24} />
                </Grid>
            )}
            {isEmpty && (
                <div className="flex flex-1 flex-col items-center justify-center gap-2">
                    <SleepBellSVG className="single-revert-style" />
                    <p className="body2 text-steel">{t('there_is_no_noti')}</p>
                </div>
            )}
            {!isEmpty && (
                <Grid item className={classes.list}>
                    {data.map((message, idx) => (
                        <NotificationCenterItemWrapper
                            key={message.id}
                            keyNotification={keyNotification}
                            message={message}
                            isLast={idx === data.length - 1}
                        />
                    ))}
                </Grid>
            )}
            {hasNextPage && (
                <Grid container className={classes.showMore} alignItems="center" justifyContent="flex-end">
                    <Grid item className={classes.loadingMore}>
                        {isLoadingMore ? (
                            <CircularProgress size={20} />
                        ) : (
                            <span onClick={handleFetchMore}>View more notifications</span>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(ListNotificationCenter);
