import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import { useDispatch } from 'react-redux';
import { sendManualTrack } from 'tracker';
import * as gridUIActions from 'gridUI/actions';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';
import { TM_STATUS, DEPENDENCY_STATUS, SOURCE_STATUS } from 'const/gridUI';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import ApproveDependencySVG from 'assets/images/svg/dependencies/ApproveSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import hexToRgba from 'hex-to-rgba';
import { useDependencyList, useDisabledSourceColumns, useViewColumnIdsWithReOrder } from 'hooks/gridUI';
import NestedMenuItem from 'components/menus/HoverMenu';
import LinkIconSVG from 'assets/images/svg/LinkSVG';
import { copyToClipboard } from 'utils/clipboard';
import { enqueueSnackbar } from 'notifier/actions';
import { getSourceStatusLabelByStatus, getSourceStatusIcon } from 'utils/gridUI/dependency';
import SourceStatusSVG from 'assets/images/svg/dependencies/SourceStatusSVG';
import PlayFileActions from '../PlayFileActions';
import PlayAutomationMenu from '../PlayAutomationMenu';
import FindInThisRange from '../FindInThisRange';

function Multiple({
    recordIds,
    columnIds,
    onClose,
    isShowMarkAsUpToDate,
    isShowMarkAsOutOfDate,
    isShowMarkAsUnset,
    isShowApproveTMStatus
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const roles = useRole();
    const dependencies = useDependencyList();
    const disabledSourceColumns = useDisabledSourceColumns();

    const accessManageGridRecord = roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD];
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];
    const viewColumnIds = useViewColumnIdsWithReOrder();

    const showDivider = React.useMemo(() => {
        return (
            isShowMarkAsUpToDate ||
            isShowMarkAsOutOfDate ||
            isShowMarkAsUnset ||
            (accessEditTranslation === roleConst.FULL && isShowApproveTMStatus)
        );
    }, [accessEditTranslation, isShowMarkAsUnset, isShowMarkAsOutOfDate, isShowMarkAsUpToDate, isShowApproveTMStatus]);

    const targets = React.useMemo(() => {
        if (columnIds?.length !== 1) return [];
        return dependencies
            ?.filter(dpDc => dpDc?.parent === columnIds?.[0])
            ?.map(dpDc => dpDc?.child)
            ?.filter(columnId => viewColumnIds?.includes(columnId));
    }, [columnIds, dependencies, viewColumnIds]);

    const sources = React.useMemo(() => {
        return columnIds?.filter(columnId => {
            const match = dependencies?.find(dpDc => dpDc?.parent === columnId);
            return !!match && match?.child && !disabledSourceColumns?.includes(columnId);
        });
    }, [columnIds, dependencies, disabledSourceColumns]);

    const hasSource = React.useMemo(() => {
        return sources?.length > 0;
    }, [sources]);

    const hasChild = React.useMemo(() => {
        return targets?.length > 0;
    }, [targets]);

    const handleApproveTMStatus = React.useCallback(() => {
        sendManualTrack({ type: `Approve TM Status` });
        dispatch(gridUIActions.approveTMStatus(TM_STATUS.APPROVED));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleApproveDependencyUpToDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Up To Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UP_TO_DATE));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleMarkDependencyOutOfDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Out Of Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.OUT_OF_DATE));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleMarkDependencyNotTranslated = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Not Translated` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UNSET));
        onClose && onClose();
    }, [onClose, dispatch]);

    const handleMarkTargetDependencyOutOfDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Out Of Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.OUT_OF_DATE, targets));
        onClose && onClose();
    }, [onClose, dispatch, targets]);

    const handleMarkTargetDependencyUpToDate = React.useCallback(() => {
        sendManualTrack({ type: `Mark Dependency Status As Up To Date` });
        dispatch(gridUIActions.approveDependencyStatus(DEPENDENCY_STATUS.UP_TO_DATE, targets));
        onClose && onClose();
    }, [onClose, dispatch, targets]);

    const deleteRecords = React.useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            sendManualTrack({
                type: `Delete Records`,
                customData: {
                    recordIds
                }
            });
            dispatch(
                gridUIActions.deleteViewRecordsByIndex({
                    successCallback: () => {
                        console.log('Record Deleted');
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        console.log('Failed to delete record');
                    }
                })
            );
        },
        [dispatch, onClose, recordIds]
    );

    const getLink = React.useCallback(() => {
        const currentUrl = new URL(window.location);
        const href = currentUrl?.href;
        copyToClipboard({ copyValue: href, internalValue: href });
        dispatch(enqueueSnackbar({ message: t('copied_range_link') }));
        onClose && onClose();
    }, [onClose, dispatch, t]);

    const handleMarkSourceStatus = React.useCallback(
        sourceStatus => {
            sendManualTrack({
                type: `Mark Source Status As ${getSourceStatusLabelByStatus({ status: sourceStatus })}`
            });
            dispatch(gridUIActions.approveSourceStatus(sourceStatus, sources));
            onClose && onClose();
        },
        [dispatch, onClose, sources]
    );

    return (
        <>
            <Grid item onClick={getLink}>
                <ListItem icon={<LinkIconSVG width={16} height={16} />} name={t(`get_link_to_this_range`)} />
            </Grid>
            <FindInThisRange onClose={onClose} />
            <PlayFileActions columnIds={columnIds} closePopup={onClose} />
            <PlayAutomationMenu closePopup={onClose} />
            {accessEditTranslation === roleConst.FULL && isShowApproveTMStatus && (
                <Grid item onClick={handleApproveTMStatus}>
                    <ListItem
                        icon={<TMStatusAprrovedSVG width={16} height={16} />}
                        name={t('record_options_approve_tm')}
                    />
                </Grid>
            )}
            {isShowMarkAsUpToDate && (
                <Grid item onClick={handleApproveDependencyUpToDate}>
                    <ListItem
                        icon={<ApproveDependencySVG width={16} height={16} />}
                        name={t('dependency_mark_as_uptodate')}
                    />
                </Grid>
            )}
            {isShowMarkAsOutOfDate && (
                <Grid item onClick={handleMarkDependencyOutOfDate}>
                    <ListItem
                        icon={<ApproveDependencySVG color={hexToRgba(theme.colors.sun, 0.8)} width={16} height={16} />}
                        name={t('dependency_mark_as_outofdate')}
                    />
                </Grid>
            )}
            {isShowMarkAsUnset && (
                <Grid item onClick={handleMarkDependencyNotTranslated}>
                    <ListItem
                        icon={
                            <ApproveDependencySVG
                                color={hexToRgba(theme.colors.brightRed, 0.8)}
                                width={16}
                                height={16}
                            />
                        }
                        name={t('dependency_mark_as_unset')}
                    />
                </Grid>
            )}
            {hasChild && (
                <NestedMenuItem
                    icon={<ApproveDependencySVG color={theme.colors.steel} width={16} height={16} />}
                    label={t('set_target_dependencies')}
                    parentMenuOpen={true}
                >
                    <Grid container direction="column">
                        <Grid item onClick={handleMarkTargetDependencyOutOfDate}>
                            <ListItem
                                icon={
                                    <ApproveDependencySVG
                                        color={hexToRgba(theme.colors.sun, 0.8)}
                                        width={16}
                                        height={16}
                                    />
                                }
                                name={t('dependency_mark_as_outofdate')}
                            />
                        </Grid>
                        <Grid item onClick={handleMarkTargetDependencyUpToDate}>
                            <ListItem
                                icon={<ApproveDependencySVG />}
                                name={t('dependency_mark_as_uptodate')}
                                width={16}
                                height={16}
                            />
                        </Grid>
                    </Grid>
                </NestedMenuItem>
            )}

            {hasSource && (
                <NestedMenuItem
                    icon={<SourceStatusSVG color={theme.colors.steel} width={16} height={16} />}
                    label={t('set_source_status')}
                    parentMenuOpen={true}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom'
                    }}
                >
                    <Grid container direction="column">
                        <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.DO_NOT_TRANSLATE)}>
                            <ListItem
                                icon={getSourceStatusIcon({
                                    status: SOURCE_STATUS.DO_NOT_TRANSLATE,
                                    size: 18
                                })}
                                name={t('do_not_translate')}
                            />
                        </Grid>
                        <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.NOT_READY_FOR_TRANSLATION)}>
                            <ListItem
                                icon={getSourceStatusIcon({
                                    status: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION,
                                    size: 18
                                })}
                                name={t('not_ready_for_translation')}
                            />
                        </Grid>

                        <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.READY_FOR_TRANSLATION)}>
                            <ListItem
                                icon={getSourceStatusIcon({
                                    status: SOURCE_STATUS.READY_FOR_TRANSLATION,
                                    size: 18
                                })}
                                name={t('ready_for_translation')}
                            />
                        </Grid>
                        <Grid item onClick={() => handleMarkSourceStatus(SOURCE_STATUS.LOCKED)}>
                            <ListItem
                                icon={getSourceStatusIcon({
                                    status: SOURCE_STATUS.LOCKED,
                                    size: 18
                                })}
                                name={t('locked')}
                            />
                        </Grid>
                    </Grid>
                </NestedMenuItem>
            )}

            {accessManageGridRecord && (
                <>
                    {showDivider && (
                        <Grid item>
                            <Divider />
                        </Grid>
                    )}
                    <Grid item>
                        <ListItem
                            onClick={deleteRecords}
                            icon={<DeleteSVG />}
                            name={t(`record_options_delete`, { count: recordIds?.length })}
                        />
                    </Grid>
                </>
            )}
        </>
    );
}

export default Multiple;
