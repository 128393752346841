import React from 'react';

function SettingMemberSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4667 5.83353C7.4667 4.43441 8.60091 3.3002 10 3.3002C11.3992 3.3002 12.5334 4.43441 12.5334 5.83353C12.5334 7.23265 11.3992 8.36686 10 8.36686C8.60091 8.36686 7.4667 7.23265 7.4667 5.83353ZM10 1.7002C7.71726 1.7002 5.8667 3.55075 5.8667 5.83353C5.8667 8.11631 7.71726 9.96686 10 9.96686C12.2828 9.96686 14.1334 8.11631 14.1334 5.83353C14.1334 3.55075 12.2828 1.7002 10 1.7002ZM6.66654 11.7002C5.57031 11.7002 4.51898 12.1357 3.74383 12.9108C2.96868 13.686 2.5332 14.7373 2.5332 15.8335V17.5002C2.5332 17.942 2.89138 18.3002 3.3332 18.3002C3.77503 18.3002 4.1332 17.942 4.1332 17.5002V15.8335C4.1332 15.1616 4.40011 14.5173 4.8752 14.0422C5.35029 13.5671 5.99465 13.3002 6.66654 13.3002H13.3332C14.0051 13.3002 14.6494 13.5671 15.1245 14.0422C15.5996 14.5173 15.8665 15.1616 15.8665 15.8335V17.5002C15.8665 17.942 16.2247 18.3002 16.6665 18.3002C17.1084 18.3002 17.4665 17.942 17.4665 17.5002V15.8335C17.4665 14.7373 17.0311 13.686 16.2559 12.9108C15.4808 12.1357 14.4294 11.7002 13.3332 11.7002H6.66654Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SettingMemberSVG);
