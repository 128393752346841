import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as advancedSearchActions from 'advancedSearch/actions';
import ViewCellInfo from './ViewCellInfo';
import { Checkbox } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

const useStyles = makeStyles(theme => ({
    checkbox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '0 .5em',
        background: 'white',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`
        }
    }
}));

function BooleanEdit({ columnId, value: cellValue, rowId, width, height, rowIndex, originalValue }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(cellValue);
    const classes = useStyles();
    const valueRef = useRef(cellValue);
    const theme = useTheme();
    const rootRef = useRef();
    const inputRef = useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    }, []);

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        dispatch(
            advancedSearchActions.cellClickAway({
                isClickYourSelf,
                value,
                rowId,
                columnId
            })
        );
    };

    const handleOnChange = e => {
        setValue(e.target.checked);
        valueRef.current = e.target.checked;
    };

    return (
        <div ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <Checkbox
                className={classes.checkbox}
                onChange={handleOnChange}
                checked={valueRef.current === true}
                style={{
                    width,
                    height,
                    border: `1px solid ${theme.colors.highlight}`,
                    borderRadius: 3,
                    background: 'white'
                }}
                inputRef={inputRef}
            />
        </div>
    );
}

export default React.memo(BooleanEdit);
