import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
// import CountryFlag from 'components/svg-icon/CountryFlag';
import { ACTION_STATUS } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useDispatch } from 'react-redux';
import { updateActionNode } from 'gridUI/automations/action';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { useTranslation } from 'react-i18next';
import Payloadv2 from './Payloadv2';
import MapTranslatedColumn from './MapTranslatedColumn';
import * as columnTypes from 'const/columnTypes';
import { AUTOMATION_LANG_SENTIMENT_OPTIONS } from 'const/automation';

const useStyles = makeStyles(theme => ({
    mt12: {
        marginTop: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    requiredMark: {
        color: theme.colors.brightRed
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    selectError: {
        borderColor: theme.colors.brightRed
    },
    flagWrapper: {
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4
    },
    controlClassName: {
        minHeight: 60
    },
    editor: {
        maxHeight: 280,
        height: 'unset'
    }
}));

// const CustomCountryFlag = ({ languageCountryCode }) => {
//     const classes = useStyles();
//     return (
//         <Grid item className={classes.flagWrapper}>
//             <CountryFlag languageCountryCode={languageCountryCode} />
//         </Grid>
//     );
// };

const ActionAmazonSentiment = ({ selectedNode, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const [lang, setLang] = useState(selectedNode?.params?.lang || '');
    const openRef = useRef(false);
    const timeout = useRef(false);

    useEffect(() => {
        openRef.current = false;
        setLang(selectedNode?.params?.lang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const selectedLanguage = useMemo(() => {
        if (lang) {
            const source = AUTOMATION_LANG_SENTIMENT_OPTIONS?.find(option => option.value === lang);
            if (source) return source;
        }
        return null;
    }, [lang]);

    const errorMessage = nodeError?.lang || '';

    const handleChangeLanguage = useCallback(
        option => {
            const newValue = option.value;
            if (lang === newValue) return;
            setLang(newValue);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                lang: newValue
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setLang(selectedNode?.params?.lang);
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, lang, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    return (
        <Grid item>
            <Grid item className={classes.mb24}>
                <Payloadv2
                    isRequired
                    label={t('automation_select_cell_value_from_a_column')}
                    placeholder={t('automation_select_cell_value_from_a_column')}
                    editorClassName={classes.editor}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />
            </Grid>
            <Grid item className={classes.mb14}>
                <p className="body1">
                    Language<span className={classes.requiredMark}>*</span>
                </p>
            </Grid>
            <Grid item className={classes.mb24}>
                <TooltipActionDisable isAction disabled={isDisabled} containerClassName={classes.containerClassName}>
                    <Grid item style={{ flex: 1 }}>
                        <LDBasePortal
                            ddPlaceholder={'Choose language'}
                            menuPlaceholder={'Select language'}
                            options={AUTOMATION_LANG_SENTIMENT_OPTIONS}
                            onChange={handleChangeLanguage}
                            defaultValue={selectedLanguage}
                            isUsingContainer
                            controlClassName={classnames(classes.controlClassName, {
                                [classes.selectError]: !!errorMessage
                            })}
                        />
                    </Grid>
                    {errorMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorMessage}</p>
                        </Grid>
                    )}
                </TooltipActionDisable>
            </Grid>
            <Grid item>
                <MapTranslatedColumn
                    isRequired
                    label={t('automation_column_to_hold_sentiment_result')}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    filterColumns={[...columnTypes.AUTOMATION_TEXT_COLUMNS, columnTypes.DATETIME, columnTypes.NUMBER]}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(ActionAmazonSentiment);
