import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { SELECTION_LIST_ITEM_MIN_HEIGHT, INPUT_RADIUS } from 'const/style';
import { NavLink } from 'react-router-dom';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '29px 14px',
        '& svg': {
            cursor: 'pointer'
        }
    },
    item: {
        borderRadius: INPUT_RADIUS,
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        padding: `0px 8px`,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        textDecoration: 'none',
        color: theme.colors.primaryText,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '&.active': {
            background: theme.colors.hoverItem
        }
    },
    active: {
        background: theme.colors.hoverItem,
        fontWeight: 'bold'
    },
    userName: {
        width: 220,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}));

function Sidebar({ marginTop }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useCurrentUserFullInfo();

    const settings = [
        { value: `info`, name: t(`auth_my_profile`), path: `/profile/info` },
        { value: `reset`, name: t(`global_password`), path: `/profile/change-password` },
        { value: `accessibility`, name: t('accessibility'), path: `/profile/accessibility` },
        { value: `twoFactor`, name: t('two_factor_authentication'), path: `/profile/two-factor` },
        { value: `notification`, name: t(`global_notification_setting`), path: `/profile/notification-setting` }
    ];

    return (
        <Grid
            container
            className={classes.root}
            style={{
                marginTop
            }}
            direction="column"
        >
            <Grid container alignItems="flex-start" direction="column" spacing={4}>
                <Grid item>
                    <Typography variant="h3" className={classes.userName}>
                        {user?.fullName}
                    </Typography>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <Grid container direction="column" spacing={0}>
                        {settings.map(setting => {
                            return (
                                <Grid key={setting.value} item>
                                    <NavLink className={`${classes.item}`} to={setting.path}>
                                        {setting.name}
                                    </NavLink>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Sidebar;
