import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmptyGroupMembersSVG from 'assets/images/svg/EmptyGroupMembersSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function EmptyMember({ className }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            className={`${classes.root} ${className}`}
            container
            direction="column"
            wrap="nowrap"
            justify="center"
            spacing={2}
            alignItems="center"
        >
            <Grid item>
                <EmptyGroupMembersSVG />
            </Grid>
            <Grid item>
                <Typography variant="h4">{t('no_member_invited')}</Typography>
            </Grid>
            <Grid item>
                <Grid container direction="column" wrap="nowrap" justify="center" alignItems="center">
                    <Grid item>
                        <Typography variant="caption">{t('no_member_invited_text')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(EmptyMember);
