import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import ColumnSetup from './ColumnSetup';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import * as columnTypes from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: 14,
        paddingLeft: 14,
        cursor: 'pointer'
    },
    displayFlex: {
        display: 'flex'
    }
}));

function ImportHeader({ columnId, metaData, onColumnChange }) {
    const classes = useStyles();
    const column = metaData?.[columnId];
    const disabled = React.useMemo(() => {
        return column?.type === columnTypes.RECORD_ID || column?.disabled;
    }, [column]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid
                onClick={disabled ? () => {} : handleClick}
                container
                alignItems="center"
                justify="space-between"
                className={classes.root}
                wrap="nowrap"
            >
                <Grid item style={{ width: `calc(100% -  16px)` }}>
                    <ColumnTypeDisplay
                        type={column?.type}
                        group={column?.group}
                        name={column?.name}
                        customProperties={{ localizationType: column?.localizationType }}
                        truncated={true}
                    />
                </Grid>
                {!disabled && (
                    <Grid item className={classes.displayFlex}>
                        <ArrowDownSVG />
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom'} id={`localization-column-setup`}>
                    <ColumnSetup onClickAway={handleClickAway} {...column} onColumnChange={onColumnChange} />
                </PopperMenu>
            )}
        </>
    );
}

ImportHeader.propTypes = {
    column: PropTypes.object,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(ImportHeader);
