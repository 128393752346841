import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { CELL_COMMENT_HEIGHT } from 'const/style';
import { useSelectedCellData, useDbIdInGridUI } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import { checkContainId } from 'utils/clickAway';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import * as columnTypes from 'const/columnTypes';
import DisplayDataByType from './DisplayDataByType';
import { useLdUserEmailMap } from 'hooks/permission';

const CELL_PREVIEW_WIDTH = 400;

const useStyles = makeStyles(theme => ({
    root: {
        width: CELL_PREVIEW_WIDTH,
        height: 'auto',
        // boxShadow: theme.shadows[1],
        zIndex: 100,
        // background: theme.colors.white,
        borderRadius: 6,
        padding: theme.spacing(3)
    },
    replyBox: {
        width: CELL_PREVIEW_WIDTH,
        boxShadow: theme.shadows[1],
        zIndex: 100,
        background: theme.colors.white,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6
    },
    spinner: {
        width: 400,
        height: CELL_COMMENT_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

function CellPreview() {
    const classes = useStyles();
    const selectedCellData = useSelectedCellData();
    const dbId = useDbIdInGridUI();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const ldUserEmailMap = useLdUserEmailMap();
    const [isCellClickAway, setIsCellClickAway] = React.useState(false);

    const column = React.useMemo(() => {
        return selectedCellData?.column;
    }, [selectedCellData]);

    const columnType = React.useMemo(() => {
        return column?.type;
    }, [column]);

    const cellValue = React.useMemo(() => {
        return selectedCellData?.data?.value;
    }, [selectedCellData]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleClickAway = e => {
        if (checkContainId(e, 'trigger-comment')) {
            return false;
        }
        if (checkContainId(e, 'comment_edit_emoji')) {
            return false;
        }
        if (checkContainId(e, 'comment-emoji')) {
            return false;
        }

        if (
            ![
                columnTypes.SINGLE_LINE,
                columnTypes.MULTIPLE_LINES,
                columnTypes.FORMULA,
                columnTypes.HTML,
                columnTypes.JSON_LD,
                columnTypes.NUMBER,
                columnTypes.RECORD_ID,
                columnTypes.TRANSLATION,
                columnTypes.YAML
            ]?.includes(columnType)
        ) {
            dispatch(gridActions.closeCellPreview());
            return;
        }
        setIsCellClickAway(true);
    };

    const handleClose = React.useCallback(() => {
        dispatch(gridActions.closeCellPreview());
    }, [dispatch]);

    if (!selectedCellData) return null;

    return (
        <Grid ref={rootRef} container direction="column" className={classes.root}>
            <Grid item style={{ marginBottom: 14 }}>
                <div className="flex items-center gap-2">
                    <ColumnIcon group={column.group} type={columnType} customProperties={column.customProperties} />
                    <div className="flex-1 truncate">
                        <p className="whitespace-nowrap body2 inline">{column.name}</p>
                        {columnType === columnTypes.REFERENCE && (
                            <p className="caption whitespace-nowrap inline">
                                {' '}
                                (from {column?.referenceSettings?.referencedGridName})
                            </p>
                        )}
                    </div>
                </div>
            </Grid>
            <Grid item>
                <DisplayDataByType
                    columnType={columnType}
                    {...selectedCellData}
                    {...column}
                    value={cellValue}
                    dbId={dbId}
                    ldUserEmailMap={ldUserEmailMap}
                    onClose={handleClose}
                    isCellClickAway={isCellClickAway}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(CellPreview);
