import React from 'react';

const AutomationsEmptySVG = () => {
    return (
        <svg width="283" height="220" viewBox="0 0 283 220" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M130.21 177.851H126.523C121.028 177.851 116.576 173.408 116.576 167.924V159.662H140.157V167.924C140.157 173.408 135.706 177.851 130.21 177.851Z"
                fill="#222023"
            />
            <path
                d="M139.81 168.756H117.202C113.098 168.756 109.689 165.424 109.689 161.258V134.044H147.253V161.258C147.253 165.424 143.914 168.756 139.81 168.756Z"
                fill="#A59AA5"
            />
            <path
                d="M147.391 150.081H109.271C106.767 150.081 104.75 148.068 104.75 145.568C104.75 143.069 106.767 141.056 109.271 141.056H147.391C149.895 141.056 151.913 143.069 151.913 145.568C151.913 148.068 149.895 150.081 147.391 150.081Z"
                fill="#C2B8C2"
            />
            <path
                d="M124.576 150.081H116.576C114.072 150.081 112.055 148.068 112.055 145.568C112.055 143.069 114.072 141.056 116.576 141.056H124.576C127.08 141.056 129.097 143.069 129.097 145.568C129.097 148.068 127.08 150.081 124.576 150.081Z"
                fill="#DBD5DB"
            />
            <path
                d="M147.391 163.758H109.271C106.767 163.758 104.75 161.744 104.75 159.245C104.75 156.746 106.767 154.732 109.271 154.732H147.391C149.895 154.732 151.913 156.746 151.913 159.245C151.913 161.744 149.895 163.758 147.391 163.758Z"
                fill="#C2B8C2"
            />
            <path
                d="M120.819 163.758H120.332C117.828 163.758 115.811 161.744 115.811 159.245C115.811 156.746 117.828 154.732 120.332 154.732H120.819C123.323 154.732 125.34 156.746 125.34 159.245C125.34 161.744 123.323 163.758 120.819 163.758Z"
                fill="#DBD5DB"
            />
            <path
                d="M181.963 71.9087C181.963 44.6942 158.034 22.6172 128.47 22.6172C98.9064 22.6172 75.0469 44.6942 75.0469 71.9087C75.0469 88.2235 83.6725 102.664 96.8892 111.689C96.8892 111.689 103.08 115.091 103.776 124.324V130.989C103.776 134.113 106.489 136.613 109.897 136.613H147.182C150.591 136.613 153.303 134.113 153.303 130.989V124.324C153.999 115.091 160.051 111.758 160.051 111.758C173.337 102.733 181.963 88.293 181.963 71.9087Z"
                fill="#FDC72F"
            />
            <path
                d="M129.097 115.091C150.419 115.091 167.703 97.8399 167.703 76.56C167.703 55.2801 150.419 38.0293 129.097 38.0293C107.775 38.0293 90.4902 55.2801 90.4902 76.56C90.4902 97.8399 107.775 115.091 129.097 115.091Z"
                fill="#FFD56C"
            />
            <path
                d="M122.835 136.613V81.0036C122.835 76.9076 119.496 73.5752 115.392 73.5752C111.288 73.5752 107.949 76.9076 107.949 81.0036V85.1691C107.949 89.2652 111.288 92.5976 115.392 92.5976H128.261"
                stroke="white"
                strokeWidth="2.2052"
                strokeMiterlimit="10"
            />
            <path
                d="M133.825 136.613V81.0036C133.825 76.9076 137.164 73.5752 141.269 73.5752C145.373 73.5752 148.712 76.9076 148.712 81.0036V85.1691C148.712 89.2652 145.373 92.5976 141.269 92.5976H127.426"
                stroke="white"
                strokeWidth="2.2052"
                strokeMiterlimit="10"
            />
            <path
                d="M96.6113 48.5819C100.159 44.2775 104.541 40.6674 109.48 38.0293"
                stroke="white"
                strokeWidth="13.1376"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M88.125 83.5021C87.5685 80.7251 87.2207 77.8787 87.2207 74.9629"
                stroke="white"
                strokeWidth="9.0321"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M170.972 74.9629C170.972 78.8507 170.415 82.669 169.441 86.2791"
                stroke="white"
                strokeWidth="9.0321"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse cx="39.3224" cy="213.575" rx="39.3224" ry="6.42524" fill="#F4F5F7" />
            <path
                d="M39.7849 95.8892C39.7849 95.8892 39.7139 99.1561 39.5718 99.2272C39.4298 99.2982 38.9327 99.1561 38.9327 99.1561L39.8559 104.412L46.5319 117.409L44.6143 132.536C51.5034 144.112 56.1198 155.618 56.5459 166.91C55.4096 183.6 54.1312 199.793 56.4749 205.83L62.9378 213.571C56.3328 213.855 49.5148 213.997 43.7621 212.932L43.1939 204.054C41.4184 194.964 41.7735 184.737 43.549 173.799C44.0462 170.603 43.7621 167.336 42.6968 164.353C40.4951 158.246 37.9384 150.788 36.1628 141.84L30.9783 138.502L31.7595 133.317L28.8477 113.786L35.7367 104.341L36.731 99.9374C36.9441 99.2272 36.873 98.3039 36.9441 97.5937C37.2992 93.9716 39.6429 93.0483 39.7849 95.8892Z"
                fill="#FED1A0"
            />
            <path
                d="M39.7849 95.8892C39.7849 95.8892 39.7139 99.1561 39.5718 99.2272C39.4298 99.2982 38.9327 99.1561 38.9327 99.1561L39.8559 104.412L46.5319 117.409L44.6143 132.536C51.5034 144.112 56.1198 155.618 56.5459 166.91C55.4096 183.6 54.1312 199.793 56.4749 205.83L62.9378 213.571C56.3328 213.855 49.5148 213.997 43.7621 212.932L43.1939 204.054C41.4184 194.964 41.7735 184.737 43.549 173.799C44.0462 170.603 43.7621 167.336 42.6968 164.353C40.4951 158.246 37.9384 150.788 36.1628 141.84L30.9783 138.502L31.7595 133.317L28.8477 113.786L35.7367 104.341L36.731 99.9374C36.9441 99.2272 36.873 98.3039 36.9441 97.5937C37.2992 93.9716 39.6429 93.0483 39.7849 95.8892Z"
                fill="#FFDDCD"
            />
            <path
                d="M30.9795 138.502C30.5533 140.349 30.3403 142.053 30.5534 143.544C30.8374 145.533 30.9795 147.593 30.7664 149.652L29.7011 161.229C29.417 164.496 28.6358 167.692 27.3574 170.746C21.0365 186.015 18.6218 199.864 20.2553 212.364C25.6529 213.571 32.1868 213.571 39.2179 213.074L33.5362 207.748C32.897 207.108 32.4709 206.327 32.3289 205.404C31.6897 198.515 35.6669 189.14 39.0759 179.055C41.7747 173.018 42.911 167.194 42.698 161.442L36.022 141.769L30.9795 138.502Z"
                fill="#FFDDCD"
            />
            <path
                d="M37.4414 103.417C21.6037 108.957 5.76602 117.053 2.78314 128.914C2.35701 130.547 2.49905 132.252 3.49335 133.743C6.61828 138.43 21.3197 140.064 30.4814 141.413C30.9785 141.484 31.4047 141.697 31.6177 142.053C31.9018 142.408 32.3279 142.905 32.612 142.763C33.1092 142.621 32.0438 140.916 32.612 140.845C33.1802 140.703 34.5296 143.047 35.0977 142.834C36.5892 142.266 33.2512 140.277 33.9614 140.064C34.6716 139.851 37.4414 142.692 37.6545 141.91C37.9386 141.2 35.2398 138.928 35.3818 138.572C35.5239 138.217 37.5125 140.348 37.9386 139.709C38.1517 139.425 37.1574 137.791 35.3818 136.016C32.541 133.033 28.8479 130.973 24.8707 129.908L17.6266 127.919L30.2683 120.249L37.4414 103.417Z"
                fill="#FFDDCD"
            />
            <path
                d="M37.016 99.2273C36.5898 97.8779 36.3057 96.7416 36.6608 94.824C36.8029 94.1848 37.087 93.5456 37.5131 93.0485C37.7972 92.7644 38.0813 92.6934 38.4364 93.0485C39.1466 92.5514 39.8568 92.4093 40.425 93.1195C41.3482 92.9065 41.6323 93.7587 41.4193 94.611C41.4903 95.8183 40.9221 95.6763 39.7858 95.6053L38.6494 95.8893C38.7205 97.5228 37.8682 98.8722 37.016 99.2273Z"
                fill="#002DB3"
            />
            <path
                d="M39.573 99.2271L38.7207 98.943C38.6497 98.943 38.6497 98.872 38.6497 98.872C38.5787 98.6589 38.3656 97.8066 38.3656 97.8066C38.2946 97.8066 38.4366 99.156 38.4366 99.156C38.5076 99.5822 38.7917 99.7952 39.1468 100.008L39.0758 99.3691C39.2178 99.2981 39.5019 99.2981 39.573 99.2271Z"
                fill="#002DB3"
            />
            <path
                d="M56.7596 206.256H43.2656L43.6918 212.932C49.4445 213.997 56.1915 213.926 62.8674 213.571L56.7596 206.256Z"
                fill="#081013"
            />
            <path
                d="M33.6062 207.747C33.1091 207.321 32.825 206.753 32.6119 206.185H19.8281C19.8991 208.244 20.0412 210.304 20.3253 212.363C25.7229 213.571 32.2568 213.571 39.2879 213.074L33.6062 207.747Z"
                fill="#081013"
            />
            <path
                d="M24.6583 140.775C25.7946 136.016 47.8822 137.082 47.8111 137.934C49.3026 139.852 23.877 147.167 24.6583 140.775Z"
                fill="#01729C"
            />
            <path
                d="M32.3983 204.552C32.7534 198.018 36.2335 187.862 39.1453 179.055C41.8441 173.018 42.9805 167.194 42.7674 161.442L36.0914 141.769L30.9779 138.502C30.5518 140.349 30.3387 142.053 30.5518 143.544C30.8359 145.533 30.9779 147.593 30.7648 149.652L29.6995 161.229C29.4154 164.496 28.6342 167.692 27.3558 170.746C22.7395 181.967 19.8276 189.637 19.6855 203.983C24.2309 203.912 28.5632 204.125 32.3983 204.552Z"
                fill="#E94C8B"
            />
            <path
                d="M38.5783 97.7354C38.2943 97.5935 38.1523 97.3095 38.1523 97.0256C38.2233 96.6707 38.5073 96.5287 38.7202 96.7417L38.5783 97.7354Z"
                fill="#FED1A0"
            />
            <path
                d="M45.8926 117.48C51.5743 120.818 57.398 122.593 63.0797 122.948C68.6194 123.303 74.159 121.599 78.3493 117.906C83.5338 113.36 87.0848 106.471 89.9257 98.73C90.4939 97.1675 90.92 96.3863 92.1273 94.8948C92.1273 94.8948 93.5478 93.7585 93.4057 93.4744C93.2637 93.1903 91.7722 93.8295 91.7722 93.6875C91.7722 93.5454 93.5478 92.4801 93.4767 92.054C93.4057 91.6279 90.991 92.9773 90.92 92.6222C90.849 92.3381 92.6245 91.2728 92.3404 90.7756C92.0563 90.2785 89.3575 91.912 89.2155 91.6279C89.0734 91.3438 90.849 90.4205 90.4939 90.0654C90.1388 89.7103 85.2383 90.9177 83.3918 93.6875C77.9941 99.8663 72.1704 107.892 67.8381 110.235C66.7728 110.804 65.2814 110.875 64.074 110.448C56.7588 107.679 48.5204 105.548 39.4297 103.915L45.8926 117.48Z"
                fill="#FFDDCD"
            />
            <path
                d="M17.6976 127.849L30.2683 120.25L37.4414 103.347C21.6037 108.957 5.76602 117.054 2.78314 128.914C2.35701 130.548 2.49905 132.252 3.49335 133.744C5.55296 136.869 12.6551 138.644 19.8992 139.852C22.3139 137.082 23.4503 133.602 23.7344 129.553L17.6976 127.849Z"
                fill="#5BB382"
            />
            <path
                d="M42.6266 166.2C39.7858 160.66 38.0102 156.683 37.371 149.368C35.3824 148.8 34.3881 146.456 34.3881 143.544L34.2461 140.277C35.0983 144.397 36.1637 147.806 37.8682 149.226L43.3368 162.72L42.6266 166.2Z"
                fill="#C9185F"
            />
            <path
                d="M55.8359 203.628C54.3445 196.384 55.4808 181.896 56.5461 166.981C56.12 156.044 51.7877 144.893 45.2538 133.743C40.7084 134.951 36.1631 135.093 31.6887 134.169L30.9785 138.502L36.092 141.769C37.8676 150.717 40.4243 158.174 42.626 164.282C43.7623 167.336 43.9754 170.532 43.4782 173.728C41.7737 184.594 41.4186 194.75 43.1231 203.77C47.4554 203.273 51.6457 203.202 55.8359 203.628Z"
                fill="#E94C8B"
            />
            <path
                d="M47.598 138.005C47.3849 137.65 46.5327 117.409 46.5327 117.409L39.8567 104.412L39.5726 102.778H36.1636L35.8085 104.341L28.9195 113.787L24.6582 141.201C30.2689 138.289 43.5498 137.437 47.598 138.005Z"
                fill="#5BB382"
            />
            <path
                d="M46.7456 122.664C44.4019 123.09 42.8395 120.036 42.4844 118.261C45.1832 120.604 45.3962 119.894 46.8167 120.818L46.7456 122.664Z"
                fill="#01729C"
            />
            <path
                d="M67.7665 110.306C66.7012 110.875 65.2097 110.946 64.0024 110.52C56.6872 107.75 48.4487 105.619 39.358 103.986C34.5996 111.656 36.0911 116.911 43.5483 119.965C44.1165 120.178 44.6136 120.462 45.1108 120.676C50.7924 123.516 57.7525 122.522 63.2211 122.948C68.8318 123.374 74.3714 121.528 78.4907 117.764C81.2605 115.207 83.6042 112.082 85.5928 108.46C82.6099 105.619 79.485 103.559 76.0049 102.494C73.022 105.974 70.1812 108.957 67.7665 110.306Z"
                fill="#5BB382"
            />
            <ellipse cx="224.627" cy="213.576" rx="57.5701" ry="6.42524" fill="#F4F5F7" />
            <g clipPath="url(#clip0_1208_131359)">
                <path
                    d="M221.506 141.182C218.517 141.182 216.094 138.754 216.094 135.759C216.094 132.765 218.517 130.337 221.506 130.337C224.495 130.337 226.918 132.765 226.918 135.759C226.918 138.754 224.495 141.182 221.506 141.182Z"
                    fill="#FF4100"
                />
                <path
                    d="M219.703 179.788C214.002 172.342 208.23 167.859 202.312 166.196C198.199 165.04 193.798 166.847 191.777 170.679C187.736 178.415 189.973 191.79 190.55 203.647L182.18 212.105L199.859 211.889C202.962 201.622 203.034 190.778 200.436 178.776C204.116 188.536 208.879 196.778 214.363 204.008L219.703 179.788Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M181.963 212.25L190.117 203.791L201.807 204.225C201.374 206.972 200.796 209.358 199.93 212.033L181.963 212.25Z"
                    fill="#FF4100"
                />
                <path
                    d="M220.569 142.121C230.022 151.664 235.579 160.557 243.516 176.535C247.052 183.042 257.155 195.188 257.01 195.766C256.794 196.923 256.144 195.838 255.351 195.043C255.206 194.898 254.845 194.826 255.062 194.971C256.361 195.766 256.722 196.272 256.722 196.706C256.722 197.284 255.134 196.778 253.546 195.332C253.402 195.188 253.474 195.26 253.546 195.404C254.052 196.489 255.423 196.489 255.206 197.212C255.062 197.573 254.124 197.284 253.186 196.778C252.536 196.417 251.959 196.055 251.382 195.621C251.382 195.621 252.681 196.923 252.392 197.067C251.67 197.429 249.938 196.417 249.938 196.417C243.588 193.236 238.681 188.175 234.568 181.957L228.435 155.568L220.569 142.121Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M243.515 176.535C244.381 178.125 247.051 178.776 248.566 180.873C247.412 184.343 243.443 187.669 239.979 189.042C237.959 186.946 236.083 184.56 234.423 182.029L228.289 155.641L220.568 142.121C230.021 151.664 235.578 160.557 243.515 176.535Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M236.949 156.22C231.321 155.28 226.197 157.81 221.579 163.956C228.146 163.811 237.166 160.702 236.949 156.22Z"
                    fill="#FF4100"
                />
                <path
                    d="M197.766 143.64C196.395 141.037 189.251 144.724 186.22 148.05C191.56 148.05 197.405 148.195 197.766 143.64Z"
                    fill="#FF4100"
                />
                <path
                    d="M216.961 132.723L216.528 135.904L217.034 136.049L217.683 142.338C217.178 146.17 216.528 148.99 216.528 151.954C216.528 154.629 217.394 157.159 218.91 159.401C220.353 161.497 221.652 163.739 222.734 166.269C223.528 168.004 223.744 169.956 223.311 171.836C222.518 175.523 220.353 178.198 216.673 179.861C203.684 185.862 193.509 192.224 192.932 199.815C192.715 202.418 193.87 204.948 195.818 206.683C204.261 214.275 217.972 212.829 234.568 211.961L246.691 214.347L239.836 201.044C224.61 197.574 214.436 198.441 207.364 199.381L222.806 198.586C227.785 198.369 232.115 194.899 233.342 190.127C234.136 187.163 234.424 183.765 234.496 180.15C233.847 167.715 230.527 154.99 219.92 141.326L219.559 139.591C219.054 137.278 218.982 135.904 219.343 133.59C219.559 132.289 219.198 132 218.621 131.783C217.899 131.494 217.106 132 216.961 132.723Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M216.528 135.904H217.61C217.827 135.615 218.115 135.181 218.115 135.253C218.115 135.325 217.971 135.759 217.971 135.759C217.827 136.121 217.466 136.482 217.105 136.554L217.033 136.121L216.528 135.904Z"
                    fill="#060022"
                />
                <path
                    d="M217.61 141.326L219.919 140.748L220.064 141.399C222.229 144.218 225.043 146.966 227.063 149.351C228.001 150.436 235.795 153.111 237.021 156.22C227.208 155.569 224.105 161.642 221.651 163.956C220.858 162.365 219.919 160.847 218.981 159.401C217.466 157.232 216.6 154.629 216.6 151.954C216.6 149.062 217.25 146.17 217.755 142.339L217.61 141.326Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M220.137 149.785C219.992 153.111 219.415 155.93 218.116 158.172C217.972 157.955 217.683 157.376 217.395 156.798L220.137 149.785Z"
                    fill="#002DB3"
                />
                <path
                    d="M191.777 170.679C193.798 166.847 198.127 165.04 202.312 166.196C208.23 167.859 214.075 172.342 219.703 179.788L214.435 204.008C208.951 196.778 204.189 188.536 200.508 178.776C202.385 187.452 202.818 195.694 201.807 203.502H190.623C189.973 191.645 187.736 178.415 191.777 170.679Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M218.694 178.487L203.035 184.85C203.323 185.5 203.612 186.151 203.901 186.729L218.838 178.704L218.694 178.487Z"
                    fill="#002DB3"
                />
                <path
                    d="M192.859 199.815C193.436 192.224 203.683 185.862 216.6 179.789C219.342 178.559 221.218 176.824 222.372 174.438C226.413 175.017 230.31 175.161 234.062 174.728C234.279 176.535 234.495 178.343 234.568 180.15C234.495 183.765 234.279 187.669 233.485 190.633C232.331 195.043 228.506 198.224 223.671 198.73C227.207 198.947 230.671 199.237 235.073 200.032L235.361 212.178L234.495 212.033C217.826 212.829 204.188 214.274 195.745 206.756C193.797 204.948 192.642 202.418 192.859 199.815Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M208.951 198.586C212.847 197.574 217.249 197.212 223.238 198.658C218.837 198.441 214.074 198.658 209.023 199.092C208.734 199.092 208.662 198.658 208.951 198.586Z"
                    fill="#002DB3"
                />
                <path
                    d="M239.836 201.044L246.691 214.347L236.372 212.323L235.795 200.177C237.094 200.466 238.465 200.755 239.836 201.044Z"
                    fill="#FF4100"
                />
                <path
                    d="M185.499 132.217L186.581 134.964L187.52 134.531L186.148 131.422L185.499 132.217Z"
                    fill="white"
                />
                <path
                    d="M218.836 141.687C211.981 146.893 205.775 152.243 201.951 157.015L190.621 138.94C190.333 138.434 189.611 136.699 189.106 136.193C189.106 136.193 187.879 134.241 187.302 133.952C187.302 133.952 186.869 133.156 186.508 133.518C186.22 133.807 187.013 134.385 186.653 134.675C186.364 134.964 186.292 134.241 185.859 133.735C185.787 133.59 185.065 132.072 184.704 132.433C184.416 132.723 184.632 133.518 184.993 134.53C184.993 134.602 184.921 134.602 184.921 134.602L183.911 132.65C182.612 132 183.911 135.325 183.55 135.325C183.333 135.325 183.478 133.229 182.9 133.59C182.323 133.952 182.828 136.771 182.828 136.554C182.756 135.614 182.756 134.892 182.467 134.892C181.818 134.964 182.323 137.35 183.189 140.169C183.405 140.82 183.983 142.266 184.199 142.917C188.385 154.34 193.147 163.883 199.642 167.498C201.157 168.293 202.889 168.51 204.476 168.004C209.311 166.413 214.939 163.088 218.62 155.641C222.3 149.857 221.65 144.941 218.836 141.687Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M197.695 143.568L202.457 155.931C204.189 156.726 202.674 156.075 202.746 156.003C206.57 151.304 211.982 146.46 218.693 141.399C218.765 141.327 218.838 141.327 218.91 141.399C221.652 144.652 222.734 149.93 219.126 155.569C215.446 162.944 209.818 166.269 205.055 167.932C203.395 168.511 200.725 168.655 199.21 167.788C193.87 164.751 190.046 157.449 186.365 148.629C186.365 148.556 186.871 148.34 186.943 148.267C187.448 147.978 188.963 146.894 193.076 147.11C195.313 147.255 197.117 145.448 197.695 143.568Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M219.776 135.976C218.142 135.976 216.817 134.649 216.817 133.012C216.817 131.375 218.142 130.048 219.776 130.048C221.41 130.048 222.734 131.375 222.734 133.012C222.734 134.649 221.41 135.976 219.776 135.976Z"
                    fill="#002DB3"
                />
                <path
                    d="M220.281 132.723H220.353C220.569 132.651 220.641 132.434 220.569 132.29C220.569 132.217 220.569 132.145 220.569 132.073C220.641 132 220.714 131.928 220.93 131.928C221.147 131.856 221.219 131.711 221.219 131.494C221.147 131.277 221.002 131.205 220.786 131.205C220.425 131.277 220.136 131.494 219.992 131.711C219.848 131.928 219.848 132.217 219.92 132.506C219.992 132.579 220.136 132.723 220.281 132.723Z"
                    fill="#FF4100"
                />
            </g>
            <rect x="241.076" y="61.1689" width="34.4392" height="34.4392" rx="6" fill="#8B77DF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M266.194 72.1392C266.194 70.3579 264.749 68.9131 262.968 68.9131C261.186 68.9131 259.741 70.3579 259.741 72.1392C259.741 73.9206 261.186 75.3654 262.968 75.3654C264.749 75.3654 266.194 73.9206 266.194 72.1392ZM258.307 88.6284H268.831C269.734 88.6284 270.046 88.0268 269.529 87.2842L265.953 82.1653C265.436 81.4238 264.501 81.344 263.866 81.9883L262.485 83.3897C261.85 84.0339 260.891 83.9727 260.342 83.2537L254.698 75.8607C254.149 75.1418 253.374 75.2139 252.967 76.0219L247.354 87.1652C246.947 87.9733 247.349 88.6284 248.253 88.6284H258.307Z"
                fill="#6853BE"
            />
            <rect x="221.029" y="84.2998" width="34.4392" height="34.4393" rx="6" fill="#B9ECFF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M246.121 95.0806C246.121 93.2993 244.677 91.8545 242.895 91.8545C241.113 91.8545 239.669 93.2993 239.669 95.0806C239.669 96.862 241.113 98.3068 242.895 98.3068C244.677 98.3068 246.121 96.862 246.121 95.0806ZM238.235 111.57H248.758C249.662 111.57 249.973 110.968 249.457 110.226L245.881 105.107C245.363 104.365 244.428 104.285 243.794 104.93L242.413 106.331C241.778 106.975 240.819 106.914 240.27 106.195L234.626 98.8021C234.077 98.0832 233.302 98.1553 232.895 98.9634L227.282 110.107C226.875 110.915 227.277 111.57 228.18 111.57H238.235Z"
                fill="#F5FCFF"
            />
            <defs>
                <clipPath id="clip0_1208_131359">
                    <rect width="75.0467" height="84.2991" fill="white" transform="matrix(-1 0 0 1 257.01 130.048)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(AutomationsEmptySVG);
