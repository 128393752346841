import React from 'react';

function VideoPlaySVG({ ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00065 14.6673C11.6825 14.6673 14.6673 11.6825 14.6673 8.00065C14.6673 4.31875 11.6825 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673Z"
                stroke="#78778B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9369 7.73245C11.8787 7.61582 11.7842 7.52126 11.6675 7.46312L6.86811 5.0634C6.57171 4.91531 6.2114 5.03549 6.06327 5.33189C6.02162 5.41524 5.99996 5.50716 6 5.60035V10.3998C5.99986 10.7311 6.26832 10.9998 6.59966 11C6.69285 11 6.78477 10.9784 6.86811 10.9367L11.6675 8.53701C11.9641 8.38923 12.0847 8.02899 11.9369 7.73245ZM7.19988 9.4291V6.57103L10.0579 8.00006L7.19988 9.4291Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(VideoPlaySVG);
