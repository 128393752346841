import React from 'react';

function AddRecordSVG({ color = 'white', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <circle cx="20" cy="20" r="20" fill="#7869B9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9746 17.1153C11.5856 17.1784 11.2868 17.545 11.2868 17.9861V22.0236L11.2955 22.1538C11.3532 22.5787 11.6889 22.9052 12.0937 22.9052L25.1373 22.9148L25.2562 22.9052C25.6443 22.8422 25.9443 22.4754 25.9443 22.0332V17.9957L25.9355 17.8658C25.8776 17.4419 25.541 17.1153 25.1373 17.1153L12.0937 17.1058L11.9746 17.1153ZM11.9746 10.0096C11.5856 10.0726 11.2868 10.4392 11.2868 10.8803V14.9178L11.2955 15.048C11.3532 15.4729 11.6889 15.7994 12.0937 15.7994L25.1373 15.809L25.2562 15.7994C25.6443 15.7364 25.9443 15.3697 25.9443 14.9274V10.8899L25.9355 10.76C25.8776 10.3361 25.541 10.0096 25.1373 10.0096L12.0937 10L11.9746 10.0096ZM12.902 18.8677V21.1445L24.3303 21.1541V18.8773L12.902 18.8677ZM12.902 14.0387V11.7619L24.3303 11.7715V14.0483L12.902 14.0387ZM18.4101 24.2114C18.0212 24.2744 17.7223 24.641 17.7223 25.0821V29.1196L17.731 29.2495C17.7887 29.6734 18.1244 29.9999 18.5292 29.9999H28.9146L29.0334 29.9904C29.4216 29.9273 29.7215 29.5607 29.7215 29.1196V25.0821L29.7127 24.9523C29.6549 24.5283 29.3182 24.2018 28.9146 24.2018H18.5292L18.4101 24.2114ZM19.3375 28.2392V25.9624H28.1075V28.2392H19.3375ZM13.1495 24.2151L10 27.1075L13.1495 29.9999L14.1915 29.0431L12.7794 27.7476L16.7853 27.7473V26.3941L12.8605 26.3944L14.1915 25.172L13.1495 24.2151Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(AddRecordSVG);
