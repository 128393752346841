import React from 'react';

function BranchSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.1499 3.00002C2.1499 1.67016 3.19302 0.650024 4.57133 0.650024C5.94964 0.650024 6.99276 1.67016 6.99276 3.00002C6.99276 4.01389 6.35365 4.87439 5.29977 5.18965L5.19276 5.22166V10.7758L5.29652 10.8094C6.03317 11.0481 6.59076 11.5192 6.82944 12.2153L6.86419 12.3167H8.85705C9.87873 12.3167 10.7213 11.5035 10.7213 10.5V9.39095L10.6176 9.35733C9.64912 9.04348 9.00705 8.18318 9.00705 7.16669C9.00705 5.83682 10.0502 4.81669 11.4285 4.81669C12.8068 4.81669 13.8499 5.83682 13.8499 7.16669C13.8499 8.18318 13.2078 9.04348 12.2394 9.35733L12.1356 9.39095V10.5C12.1356 12.2466 10.6639 13.6834 8.85705 13.6834H6.86419L6.82944 13.7847C6.50823 14.7216 5.62421 15.35 4.57133 15.35C3.19302 15.35 2.1499 14.3299 2.1499 13C2.1499 11.9835 2.79198 11.1232 3.76043 10.8094L3.86419 10.7758V5.22729L3.76419 5.19194C2.87239 4.87665 2.1499 4.01086 2.1499 3.00002ZM4.57133 2.01669C4.07396 2.01669 3.56419 2.4038 3.56419 3.00002C3.56419 3.2854 3.6628 3.53427 3.84534 3.71174C4.0275 3.88884 4.28135 3.98336 4.57133 3.98336C4.86131 3.98336 5.11516 3.88884 5.29732 3.71174C5.47986 3.53427 5.57847 3.2854 5.57847 3.00002C5.57847 2.71465 5.47986 2.46577 5.29732 2.28831C5.11516 2.11121 4.86131 2.01669 4.57133 2.01669ZM11.4285 6.18336C11.1385 6.18336 10.8846 6.27788 10.7025 6.45498C10.5199 6.63244 10.4213 6.88131 10.4213 7.16669C10.4213 7.45207 10.5199 7.70094 10.7025 7.87841C10.8846 8.0555 11.1385 8.15002 11.4285 8.15002C11.7185 8.15002 11.9723 8.0555 12.1545 7.87841C12.337 7.70094 12.4356 7.45207 12.4356 7.16669C12.4356 6.88131 12.337 6.63244 12.1545 6.45498C11.9723 6.27788 11.7185 6.18336 11.4285 6.18336ZM4.57133 12.0167C4.28135 12.0167 4.0275 12.1112 3.84534 12.2883C3.6628 12.4658 3.56419 12.7146 3.56419 13C3.56419 13.2854 3.6628 13.5343 3.84534 13.7117C4.0275 13.8888 4.28135 13.9834 4.57133 13.9834C4.86131 13.9834 5.11516 13.8888 5.29732 13.7117C5.47986 13.5343 5.57847 13.2854 5.57847 13C5.57847 12.7146 5.47986 12.4658 5.29732 12.2883C5.11516 12.1112 4.86131 12.0167 4.57133 12.0167Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(BranchSVG);
