import React from 'react';
import CriticalSVG from 'assets/images/svg/priority/CriticalSVG';
import LowSVG from 'assets/images/svg/priority/LowSVG';
import MediumSVG from 'assets/images/svg/priority/MediumSVG';
import HighSVG from 'assets/images/svg/priority/HighSVG';
import { LQA_TYPES, LQA_PRIORITY_TYPES } from 'const/gridUI';
import { getUserViewSettingByProperty } from 'utils/workspace';
import { USER_SETTINGS, SUBSCRIPTION_STATUS, PLAN_TYPES } from 'const';
import * as roleConst from 'auth/roleConst';
import store from 'store/configStore';
import i18n from 'i18n';

export function getLQAPriorityLabel(priority) {
    switch (priority) {
        case LQA_PRIORITY_TYPES.CRITICAL:
            return i18n.t('global_critical');
        case LQA_PRIORITY_TYPES.HIGH:
            return i18n.t('global_high');
        case LQA_PRIORITY_TYPES.MEDIUM:
            return i18n.t('global_medium');
        case LQA_PRIORITY_TYPES.LOW:
        default:
            return i18n.t('global_low');
    }
}

export function getLQAPriorityColor({ priority, theme }) {
    switch (priority) {
        case LQA_PRIORITY_TYPES.CRITICAL:
            return theme.colors.brightRed;
        case LQA_PRIORITY_TYPES.HIGH:
            return theme.colors.crusta;
        case LQA_PRIORITY_TYPES.MEDIUM:
            return theme.colors.sun;
        case LQA_PRIORITY_TYPES.LOW:
            return theme.colors.fuchsiaBlue;

        default:
            return theme.colors.fuchsiaBlue;
    }
}

export function getPriorityIcon(priority) {
    switch (priority) {
        case LQA_PRIORITY_TYPES.CRITICAL:
            return <CriticalSVG />;
        case LQA_PRIORITY_TYPES.HIGH:
            return <HighSVG />;
        case LQA_PRIORITY_TYPES.MEDIUM:
            return <MediumSVG />;
        case LQA_PRIORITY_TYPES.LOW:
            return <LowSVG />;

        default:
            return <LowSVG />;
    }
}

export const ISSUE_TYPE_OPTIONS = [
    { label: i18n.t('global_basic'), value: LQA_TYPES.BASIC, icon: null },
    { label: i18n.t('lqa_issue'), value: LQA_TYPES.LQA_ISSUE, icon: null }
];

export const PRIORITY_OPTIONS = [
    { label: 'Critical', value: LQA_PRIORITY_TYPES.CRITICAL, icon: props => <CriticalSVG {...props} /> },
    { label: 'High', value: LQA_PRIORITY_TYPES.HIGH, icon: props => <HighSVG {...props} /> },
    { label: i18n.t('global_medium'), value: LQA_PRIORITY_TYPES.MEDIUM, icon: props => <MediumSVG {...props} /> },
    { label: 'Low', value: LQA_PRIORITY_TYPES.LOW, icon: props => <LowSVG {...props} /> }
];

export function getIsShowAutoQA() {
    const { getState } = store;
    const { workspace, gridUI, auth, payment } = getState();
    const { workspaceId, defaultAccessViewId, dbId } = gridUI;

    const autoQAUserSetting = getUserViewSettingByProperty({
        workspace,
        wsId: workspaceId,
        dbId,
        viewId: defaultAccessViewId,
        property: USER_SETTINGS.VIEW.AUTO_QA
    });

    const workspaceAuthorities = auth?.workspaceAuthorities;

    const isHasEditTranslation = workspaceAuthorities?.includes(roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION);
    const autoQASubscription = payment.addonSubscriptions?.find(
        subscription => subscription?.price?.plan?.name === PLAN_TYPES.AUTO_QA
    );
    const isEnabledAutoQA =
        autoQASubscription?.status === SUBSCRIPTION_STATUS.ACTIVE ||
        autoQASubscription?.status === SUBSCRIPTION_STATUS.TRIALING;
    return autoQAUserSetting && isEnabledAutoQA && isHasEditTranslation;
}
