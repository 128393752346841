import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupItem from './GroupItem';
import { useIsCreatingGroup, useIsShowLastGroupPopupCreate } from 'hooks/permission/group';
import { useContextMenuId } from 'hooks/app';
import Skeleton from 'components/skeleton/Base';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function GroupList({ groups, selectedGroupId, onItemSelect }) {
    const classes = useStyles();
    const contextMenuId = useContextMenuId();
    const isCreatingGroup = useIsCreatingGroup();
    const isShowLastPopupCreate = useIsShowLastGroupPopupCreate();
    const [isScrolling, setIsScrolling] = React.useState(false);
    const timerRef = React.useRef(null);

    React.useEffect(() => {
        const target = document.getElementById('groupList');
        if (!target) return;
        target.addEventListener('mousewheel', handleScroll);
        target.addEventListener('DOMMouseScroll', handleScroll);
        target.addEventListener('scroll', handleScroll);
        return () => {
            target.removeEventListener('mousewheel', handleScroll);
            target.removeEventListener('DOMMouseScroll', handleScroll);
            target.removeEventListener('scroll', handleScroll);
        };
    });

    function handleScroll() {
        setIsScrolling(true);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            console.log('stop scrolling');
            setIsScrolling(false);
        }, 100);
    }

    return (
        <Grid className={classes.root} container direction={'column'} id="groupList">
            {groups &&
                groups.map((group, index) => {
                    if (group.isDeleted) return null;
                    return (
                        <GroupItem
                            contextMenuId={contextMenuId}
                            key={group.id}
                            group={group}
                            active={selectedGroupId === group.id}
                            isShowLastPopupCreate={isShowLastPopupCreate === group.id && index === groups.length - 1}
                            onSelect={onItemSelect}
                            isScrolling={isScrolling}
                        />
                    );
                })}
            <div id={'groupListBottom'} />
            {isCreatingGroup && <Skeleton height={42} />}
        </Grid>
    );
}

export default React.memo(GroupList);
