import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import theme from 'theme';
import hexToRgba from 'hex-to-rgba';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 400,
        width: 400,
        padding: theme.spacing(3, 2)
    },
    whiteText: {
        color: theme.colors.white
    },
    exampleList: {
        backgroundColor: theme.colors.white,
        padding: theme.spacing(2)
    },
    languageItem: {
        fontSize: 13,
        borderRadius: 4,
        color: theme.colors.white,
        width: 35
    }
}));

const enUS = {
    name: 'enUS',
    color: hexToRgba(theme.colors.dodgerBlue, 0.8)
};

const frFR = {
    name: 'frFR',
    color: hexToRgba(theme.colors.brightRed, 0.8)
};

const all = {
    name: i18n.t('global_all'),
    color: hexToRgba(theme.colors.lightLavender, 0.8)
};

const examples = [
    {
        from: enUS,
        to: frFR,
        description: i18n.t('language_pair_tooltip_1')
    },
    {
        from: all,
        to: frFR,
        description: i18n.t('language_pair_tooltip_2')
    },
    {
        from: enUS,
        to: enUS,
        description: i18n.t('language_pair_tooltip_3')
    },
    {
        from: enUS,
        to: all,
        description: i18n.t('language_pair_tooltip_4')
    },
    {
        from: all,
        to: all,
        description: i18n.t('language_pair_tooltip_5')
    }
];

function LanguagePairTooltip() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item container className={classes.exampleList}>
                {examples.map(example => {
                    return (
                        <Grid item container key={example.description} style={{ marginBottom: 8 }}>
                            <Grid item>
                                <Typography
                                    className={classes.languageItem}
                                    style={{ backgroundColor: example.from.color }}
                                    align="center"
                                >
                                    {example.from.name}
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                                <Typography align="center">-</Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                                <Typography
                                    className={classes.languageItem}
                                    style={{ backgroundColor: example.to.color }}
                                    align="center"
                                >
                                    {example.to.name}
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                                <Typography variant="body2">{example.description}</Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            <Grid item>
                <Typography align="left" className={classes.whiteText} style={{ marginTop: 8 }} variant="body1">
                    {t('language_pair_tooltip_title')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="left" className={classes.whiteText} variant="body2">
                    {t('language_pair_tooltip_text')}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default React.memo(LanguagePairTooltip);
