import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Divider, Grid } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { HEADER_HEIGHT } from 'const';
import LogList from './LogList';
import TimeSelect from 'components/selects/TimeSelect';
import { fetchGridHistory, refreshGridHistory, resetGridHistory } from '../actions';
import { useDispatch } from 'react-redux';
import { getStartDateOffsetTimestamp } from 'utils/datetime';
import * as gridUIActions from 'gridUI/actions';
import { AutoSizer } from 'react-virtualized-dn';
import RefreshSVG from 'assets/images/svg/RefreshSVG';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import DownloadSVG from 'assets/images/svg/DownloadSVG';
import Tooltip from 'components/tooltip/Base';
import IconButton from 'components/button/IconButton';
import ExportGridHistoryDialog from './ExportGridHistoryDialog';

const HEADER_HISTORY_HEIGHT = 72;
const CONTENT_HEIGHT = `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT + 84}px)`;

const timeDropDownOptions = [
    { value: 'all', label: i18n.t('global_all'), timestamp: 0 },
    { value: 'today', label: i18n.t('global_today'), timestamp: getStartDateOffsetTimestamp({ offset: 0, unit: 'd' }) },
    {
        value: 'yesterday',
        label: i18n.t('global_yesterday'),
        timestamp: getStartDateOffsetTimestamp({ offset: 1, unit: 'd' })
    },
    { value: '7days', label: i18n.t('global_7days'), timestamp: getStartDateOffsetTimestamp({ offset: 7, unit: 'd' }) },
    {
        value: '15days',
        label: i18n.t('global_15days'),
        timestamp: getStartDateOffsetTimestamp({ offset: 15, unit: 'd' })
    },
    {
        value: '30days',
        label: i18n.t('global_30days'),
        timestamp: getStartDateOffsetTimestamp({ offset: 30, unit: 'd' })
    }
];

function GridHistory({ handleClose, workspaceId, gridId, viewId, dbId, branchId, isShareViewLink }) {
    const dispatch = useDispatch();
    const timerRef = useRef();
    const { t } = useTranslation();
    const [selected, setSelected] = useState(timeDropDownOptions[0]);
    const [isOpenExport, setIsOpenExport] = useState(false);

    const handleRefresh = useCallback(() => {
        dispatch(
            refreshGridHistory({
                branchId,
                successCallback: () => {
                    console.log('refreshGridHistory successfully');
                },
                errorCallback: () => {
                    console.log('failed to refreshGridHistory');
                }
            })
        );
    }, [dispatch, branchId]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            dispatch(
                fetchGridHistory({
                    branchId,
                    successCallback: () => {
                        console.log('fetch grid history successfully');
                    },
                    errorCallback: () => {
                        console.log('failed to fetch grid history');
                    }
                })
            );
        }, 200);

        return () => {
            dispatch(resetGridHistory({ branchId }));
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [dispatch, branchId]);

    const handleOptionChange = useCallback(
        (e, option) => {
            if (selected?.value !== option?.value) {
                dispatch(gridUIActions.changeGridBeginFilter({ branchId, value: option?.timestamp }));
                dispatch(
                    gridUIActions.fetchGridHistory({
                        branchId,
                        successCallback: () => {
                            console.log('fetch grid afterchagne begin successfully');
                        },
                        errorCallback: () => {
                            console.log('failed to fetch grid afterchagne begin');
                        }
                    })
                );
                setSelected(option);
            }
        },
        [dispatch, branchId, selected]
    );

    const toggleOpenExport = useCallback(() => {
        setIsOpenExport(prev => !prev);
    }, []);

    return (
        <Grid container direction="column" wrap="nowrap" className="h-full">
            <Grid item>
                <Grid
                    container
                    className="h-[72px] p-0 px-6 border-b border-grey-border"
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item>
                        <h4 className="prose prose-lg font-medium">{t(`grid_history`)}</h4>
                    </Grid>
                    <Grid item style={{ display: 'flex', cursor: 'pointer' }}>
                        <IconButton size="small" onClick={handleClose}>
                            <CloseIconSVG className="w-6 h-6" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className="p-6" justify="space-between">
                <Grid item style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <TimeSelect
                                    width={width}
                                    options={timeDropDownOptions}
                                    selected={selected}
                                    onChange={handleOptionChange}
                                />
                            );
                        }}
                    </AutoSizer>
                </Grid>
                <Grid item className="!ml-6 !mr-3">
                    <Divider orientation="vertical" className="bg-grey-border" />
                </Grid>
                <Grid item className="!mr-2">
                    <Tooltip title={t('global_refresh')}>
                        <IconButton onClick={handleRefresh}>
                            <RefreshSVG />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={t('global_export')}>
                        <IconButton onClick={toggleOpenExport}>
                            <DownloadSVG />
                        </IconButton>
                    </Tooltip>
                    <ExportGridHistoryDialog open={isOpenExport} onClose={toggleOpenExport} />
                </Grid>
            </Grid>
            <Grid item>
                <LogList
                    closeSidebar={handleClose}
                    CONTENT_HEIGHT={CONTENT_HEIGHT}
                    selected={selected}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                    isShareViewLink={isShareViewLink}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(GridHistory);
