import React from 'react';

const InviteMemberNoEmailSVG = () => {
    return (
        <svg width={217} height={226} viewBox="0 0 217 226" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="95.5101"
                cy="119.032"
                r="72.1949"
                stroke="#7869B9"
                strokeOpacity="0.3"
                strokeWidth="1.45848"
                strokeDasharray="3.65 3.65"
            />
            <g filter="url(#filter0_d_758_35651)">
                <mask
                    id="mask0_758_35651"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x={122}
                    y={129}
                    width={89}
                    height={88}
                >
                    <circle cx="166.247" cy="172.996" r="43.7545" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_758_35651)">
                    <circle cx="166.247" cy="172.996" r="43.0655" fill="#7DD7E9" stroke="white" strokeWidth="1.37809" />
                    <path
                        d="M159.072 143.95C158.398 144.36 157.712 144.764 157.038 145.185C156.781 145.343 156.567 145.563 156.417 145.825C156.266 146.086 156.183 146.381 156.176 146.682C156.172 147.005 156.256 147.322 156.419 147.601C156.582 147.879 156.818 148.109 157.101 148.264L164.024 152.641C164.75 153.129 165.604 153.394 166.48 153.404C167.357 153.419 168.219 153.178 168.959 152.709L173.968 149.71L175.939 148.532C176.229 148.386 176.474 148.165 176.647 147.891C176.821 147.618 176.916 147.302 176.923 146.979C176.93 146.655 176.849 146.336 176.687 146.055C176.526 145.774 176.291 145.542 176.007 145.384L170.678 141.97L168.656 143.171C168.378 143.324 168.145 143.549 167.984 143.822C167.822 144.096 167.737 144.407 167.737 144.724C167.737 145.041 167.822 145.353 167.984 145.626C168.145 145.899 168.378 146.124 168.656 146.278L169.536 146.847L166.531 148.629L164.304 147.217L163.544 146.739L165.737 145.464C165.997 145.309 166.213 145.09 166.364 144.828C166.515 144.566 166.597 144.271 166.602 143.969C166.606 143.667 166.534 143.368 166.39 143.102C166.247 142.836 166.038 142.611 165.783 142.448L163.738 141.156L159.089 143.916"
                        fill="url(#paint0_linear_758_35651)"
                    />
                    <path
                        d="M197.55 167.507C199.737 167.131 202.119 170.313 202.879 174.627C202.879 174.581 202.879 174.536 202.879 174.49C201.965 169.123 199.452 165.077 197.224 165.452C194.996 165.828 193.963 170.478 194.882 175.845C194.882 175.89 194.882 175.936 194.882 175.981C194.203 171.656 195.345 167.877 197.55 167.507Z"
                        fill="#22C3F3"
                    />
                    <path
                        d="M200.248 183.323C198.226 183.665 196.05 180.967 195.107 177.154C196.181 181.849 198.477 185.23 200.499 184.888C202.521 184.547 203.572 180.603 203.024 175.811C203.435 179.732 202.276 182.976 200.248 183.323Z"
                        fill="#FAAF1B"
                    />
                    <path
                        d="M135.401 167.507C133.214 167.131 130.832 170.313 130.072 174.627C130.075 174.581 130.075 174.536 130.072 174.49C130.986 169.123 133.499 165.077 135.727 165.452C137.954 165.828 138.988 170.478 138.069 175.845C138.069 175.89 138.069 175.936 138.069 175.981C138.8 171.656 137.595 167.877 135.401 167.507Z"
                        fill="#FAAF1B"
                    />
                    <path
                        d="M132.705 183.323C134.727 183.665 136.904 180.967 137.846 177.154C136.772 181.849 134.476 185.23 132.454 184.888C130.432 184.547 129.381 180.603 129.929 175.811C129.518 179.732 130.678 182.976 132.705 183.323Z"
                        fill="#22C3F3"
                    />
                    <path
                        d="M189.284 164.286C187.892 164.559 186.663 165.364 185.857 166.528C186.414 166.106 187.065 165.823 187.754 165.703C191.523 165.06 195.453 169.687 196.538 176.004C197.624 182.322 195.436 187.99 191.661 188.628C190.97 188.736 190.264 188.682 189.599 188.468C190.737 189.302 192.158 189.66 193.557 189.464C197.698 188.764 200.097 182.561 198.909 175.606C197.721 168.651 193.425 163.586 189.284 164.286Z"
                        fill="#E43383"
                    />
                    <path
                        d="M143.666 164.286C145.059 164.557 146.29 165.362 147.093 166.528C146.538 166.107 145.89 165.824 145.203 165.703C141.427 165.06 137.497 169.687 136.418 176.004C135.338 182.322 137.52 187.99 141.29 188.628C141.98 188.737 142.687 188.682 143.352 188.468C142.208 189.309 140.778 189.666 139.371 189.464C135.23 188.764 132.831 182.561 134.019 175.606C135.207 168.651 139.525 163.586 143.666 164.286Z"
                        fill="#E43383"
                    />
                    <path
                        d="M194.203 179.766C193.369 175.344 192.267 170.797 189.771 166.995C189.502 166.585 189.199 166.187 188.92 165.794C183.333 158.492 173.498 155.384 164.57 155.891C156.54 156.341 148.349 159.642 143.546 166.283C140.33 170.734 138.816 176.198 138.491 181.65C138.274 185.355 138.268 189.726 140.61 192.891C151.159 207.153 181.734 206.977 192.158 192.891C194.974 189.083 195.054 184.206 194.203 179.766Z"
                        fill="url(#paint1_linear_758_35651)"
                    />
                    <path
                        d="M165.285 189.532C160.507 189.584 155.74 189.051 151.092 187.944C147.573 187.068 145.494 185.52 144.906 183.329C143.541 178.246 144.529 171.144 149.052 167.393C151.747 165.172 155.027 163.771 158.5 163.358C163.746 162.683 169.058 162.683 174.304 163.358C177.777 163.769 181.057 165.17 183.751 167.393C188.281 171.121 189.263 178.207 187.898 183.329C187.327 185.355 185.43 186.852 182.186 187.791C177.4 189.168 171.859 189.436 166.587 189.498H165.297L165.285 189.532Z"
                        fill="#9863A9"
                    />
                    <path
                        d="M165.383 187.905C161.032 187.953 156.691 187.485 152.451 186.51C149.241 185.742 147.345 184.382 146.814 182.464C145.569 178.002 146.465 171.775 150.589 168.509C153.07 166.546 156.055 165.319 159.202 164.969C163.984 164.377 168.82 164.377 173.602 164.969C176.747 165.32 179.73 166.547 182.209 168.509C186.333 171.775 187.23 178.002 185.985 182.464C185.494 184.24 183.734 185.554 180.77 186.374C176.406 187.586 171.363 187.819 166.559 187.893H165.383V187.905Z"
                        fill="#121212"
                    />
                    <path
                        d="M165.382 186.767C161.121 186.82 156.868 186.369 152.714 185.423C149.943 184.757 148.327 183.67 147.916 182.179C146.773 178.076 147.567 172.373 151.303 169.414C153.62 167.566 156.414 166.408 159.362 166.073C164.045 165.496 168.781 165.496 173.464 166.073C176.405 166.398 179.194 167.542 181.512 169.374C185.248 172.333 186.047 178.036 184.899 182.139C184.522 183.5 183.032 184.547 180.478 185.258C176.24 186.431 171.282 186.658 166.559 186.732H165.417L165.382 186.767Z"
                        fill="#17171A"
                    />
                    <path
                        d="M149.358 160.731L149.105 160.936L151.875 164.325L152.127 164.12L149.358 160.731Z"
                        fill="white"
                    />
                    <path
                        d="M183.886 160.996C183.8 160.933 183.703 160.882 183.618 160.819L180.762 164.32L181.013 164.524L183.886 160.996Z"
                        fill="white"
                    />
                    <path
                        d="M183.991 166.278C181.104 163.991 177.631 162.559 173.967 162.146C168.943 161.52 163.86 161.52 158.836 162.146C155.172 162.559 151.699 163.991 148.812 166.278C143.9 170.171 142.449 177.444 144.06 183.227C145.054 186.79 148.424 188.474 151.782 189.277C156.642 190.383 161.622 190.874 166.604 190.739C171.505 190.665 176.8 190.432 181.54 189.117C184.671 188.252 187.823 186.55 188.743 183.227C190.354 177.456 188.903 170.171 183.991 166.278ZM187.092 182.783C186.481 184.963 184.459 186.55 181.078 187.484C176.594 188.725 171.459 188.969 166.581 189.043H165.388C160.946 189.091 156.515 188.612 152.188 187.615C148.55 186.75 146.373 185.116 145.716 182.771C144.374 177.945 145.374 171.195 149.886 167.615C152.527 165.523 155.706 164.214 159.059 163.836C163.939 163.237 168.875 163.237 173.755 163.836C177.108 164.214 180.287 165.524 182.928 167.615C187.435 171.195 188.435 177.945 187.092 182.771V182.783Z"
                        fill="url(#paint2_linear_758_35651)"
                    />
                    <path
                        d="M152.011 176.215C152.346 173.56 153.662 171.125 155.701 169.385C158.019 167.554 160.808 166.41 163.749 166.084C165.337 165.879 166.987 165.754 168.678 165.691C167.976 165.691 167.267 165.651 166.553 165.651C164.195 165.645 161.839 165.79 159.499 166.084C156.559 166.41 153.77 167.554 151.452 169.385C149.412 171.125 148.096 173.56 147.762 176.215H152.011Z"
                        fill="url(#paint3_linear_758_35651)"
                    />
                    <path
                        d="M166.555 186.755H166.697H167.771L166.555 186.71V186.755Z"
                        fill="url(#paint4_linear_758_35651)"
                    />
                    <path
                        d="M158.322 181.536C160.906 181.536 163 179.449 163 176.875C163 174.301 160.906 172.214 158.322 172.214C155.739 172.214 153.645 174.301 153.645 176.875C153.645 179.449 155.739 181.536 158.322 181.536Z"
                        fill="white"
                    />
                    <path
                        d="M158.322 172.214C158.513 172.216 158.704 172.229 158.894 172.253C157.768 172.401 156.735 172.951 155.987 173.801C155.239 174.652 154.826 175.744 154.826 176.875C154.826 178.006 155.239 179.098 155.987 179.948C156.735 180.799 157.768 181.349 158.894 181.496C158.704 181.523 158.514 181.536 158.322 181.536C157.082 181.536 155.892 181.045 155.015 180.171C154.137 179.297 153.645 178.111 153.645 176.875C153.645 175.639 154.137 174.453 155.015 173.579C155.892 172.705 157.082 172.214 158.322 172.214Z"
                        fill="#EDE9EE"
                    />
                    <path
                        d="M155.78 179.396C156.301 179.396 156.723 178.976 156.723 178.457C156.723 177.938 156.301 177.518 155.78 177.518C155.26 177.518 154.838 177.938 154.838 178.457C154.838 178.976 155.26 179.396 155.78 179.396Z"
                        fill="#17171A"
                    />
                    <path
                        d="M176.413 175.446L170.461 177.831H182.33"
                        stroke="white"
                        strokeWidth="1.29089"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <g filter="url(#filter1_d_758_35651)">
                <mask
                    id="mask1_758_35651"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x={8}
                    y={132}
                    width={47}
                    height={48}
                >
                    <circle cx="31.3357" cy="156.224" r="23.3357" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask1_758_35651)">
                    <circle cx="31.3357" cy="156.224" r="22.6065" fill="#FAB7BC" stroke="white" strokeWidth="1.45848" />
                    <path
                        d="M45.5192 153.38C46.5439 153.208 47.65 154.694 48.026 156.711V156.649C47.5967 154.151 46.4123 152.246 45.3782 152.421C44.3441 152.596 43.8553 154.772 44.2846 157.283C44.2846 157.302 44.2846 157.323 44.2846 157.345C43.943 155.319 44.4945 153.555 45.5192 153.38Z"
                        fill="#46BCEC"
                    />
                    <path
                        d="M46.7788 160.777C45.8387 160.939 44.8141 159.678 44.3848 157.892C44.8861 160.077 45.9515 161.67 46.8916 161.511C47.8316 161.351 48.3267 159.506 48.0729 157.264C48.2703 159.091 47.7282 160.627 46.7788 160.777Z"
                        fill="#FAAF1B"
                    />
                    <path
                        d="M16.456 153.38C15.4313 153.208 14.3252 154.694 13.9648 156.711V156.649C14.3941 154.151 15.5786 152.246 16.6127 152.421C17.6467 152.596 18.1356 154.772 17.7063 157.283C17.7063 157.302 17.7063 157.323 17.7063 157.345C18.0322 155.319 17.4807 153.555 16.456 153.38Z"
                        fill="#FAAF1B"
                    />
                    <path
                        d="M15.1962 160.777C16.1362 160.939 17.1609 159.678 17.5902 157.892C17.0888 160.077 16.0234 161.67 15.0834 161.511C14.1433 161.351 13.6482 159.506 13.902 157.264C13.7046 159.091 14.2467 160.627 15.1962 160.777Z"
                        fill="#46BCEC"
                    />
                    <path
                        d="M41.6524 151.884C41.005 152.014 40.434 152.391 40.0605 152.933C40.3219 152.737 40.6255 152.605 40.9473 152.546C42.7115 152.234 44.5509 154.42 45.0554 157.367C45.5599 160.315 44.5384 162.969 42.7773 163.268C42.4542 163.32 42.1234 163.294 41.8122 163.193C42.3438 163.583 43.0074 163.75 43.661 163.659C45.5975 163.331 46.7193 160.43 46.1678 157.177C45.6163 153.923 43.5889 151.547 41.6524 151.884Z"
                        fill="#E43383"
                    />
                    <path
                        d="M20.3238 151.884C20.9712 152.014 21.5422 152.391 21.9157 152.933C21.6543 152.737 21.3507 152.605 21.0289 152.546C19.2647 152.234 17.4253 154.42 16.9208 157.367C16.4163 160.315 17.4378 162.969 19.1989 163.268C19.522 163.32 19.8528 163.294 20.164 163.193C19.6324 163.583 18.9688 163.75 18.3152 163.659C16.3787 163.331 15.2569 160.43 15.8084 157.177C16.3599 153.923 18.3873 151.547 20.3238 151.884Z"
                        fill="#E43383"
                    />
                    <path
                        d="M43.9623 159.116C43.5706 157.046 43.0567 154.922 41.8879 153.143C41.7625 152.952 41.6309 152.765 41.4899 152.581C38.8797 149.165 34.2828 147.713 30.1058 147.95C26.3456 148.163 22.5195 149.705 20.2728 152.812C18.7687 154.891 18.0605 157.448 17.9101 159.993C17.8067 161.726 17.8067 163.771 18.9003 165.251C23.8356 171.921 38.1339 171.836 43.0066 165.251C44.3132 163.471 44.3508 161.189 43.9623 159.116Z"
                        fill="url(#paint5_linear_758_35651)"
                    />
                    <path
                        d="M30.4312 163.681C28.1957 163.706 25.9656 163.456 23.7913 162.937C22.1462 162.528 21.1748 161.804 20.899 160.783C20.2723 158.404 20.7236 155.082 22.8387 153.339C24.0999 152.304 25.634 151.654 27.257 151.466C29.7097 151.149 32.1931 151.149 34.6458 151.466C36.2697 151.654 37.8049 152.304 39.0672 153.339C41.1824 155.082 41.643 158.404 41.0038 160.783C40.7499 161.72 39.8506 162.428 38.3277 162.866C36.0904 163.512 33.499 163.637 31.036 163.674C30.8323 163.674 30.6318 163.674 30.4312 163.674V163.681Z"
                        fill="#9863A9"
                    />
                    <path
                        d="M30.4778 162.919C28.4422 162.941 26.4111 162.723 24.427 162.269C22.9291 161.91 22.0423 161.273 21.7916 160.377C21.2088 158.291 21.6287 155.381 23.559 153.848C24.7197 152.933 26.1147 152.361 27.5855 152.197C29.8217 151.922 32.0833 151.922 34.3195 152.197C35.7949 152.358 37.1948 152.93 38.3586 153.848C40.2857 155.378 40.7056 158.291 40.1259 160.377C39.8941 161.205 39.0731 161.82 37.6849 162.207C35.645 162.772 33.298 162.881 31.0387 162.916L30.4778 162.919Z"
                        fill="#121212"
                    />
                    <path
                        d="M30.4786 162.388C28.4856 162.414 26.4966 162.204 24.5531 161.763C23.2558 161.451 22.5006 160.942 22.3063 160.246C21.7736 158.329 22.1465 155.662 23.8919 154.279C24.9753 153.423 26.2782 152.888 27.6521 152.733C29.8373 152.464 32.0474 152.464 34.2326 152.733C35.6065 152.888 36.9094 153.423 37.9928 154.279C39.7382 155.662 40.1111 158.338 39.5596 160.246C39.3841 160.87 38.6884 161.373 37.4914 161.704C35.511 162.254 33.1922 162.36 30.9831 162.394H30.441L30.4786 162.388Z"
                        fill="#17171A"
                    />
                    <path
                        d="M22.978 150.216L22.8613 150.31L24.1552 151.894L24.2718 151.799L22.978 150.216Z"
                        fill="white"
                    />
                    <path
                        d="M39.1301 150.339L39.0048 150.254L37.6699 151.884L37.7859 151.978L39.1301 150.339Z"
                        fill="white"
                    />
                    <path
                        d="M29.4822 143.166L29.4806 143.163C29.446 143.103 29.3694 143.083 29.3094 143.117L27.6513 144.071C27.5914 144.106 27.5708 144.182 27.6055 144.242L27.607 144.244C27.6416 144.304 27.7183 144.325 27.7782 144.29L29.4363 143.336C29.4963 143.302 29.5168 143.225 29.4822 143.166Z"
                        fill="white"
                    />
                    <path
                        d="M27.3414 144.6C27.421 144.6 27.4855 144.535 27.4855 144.456C27.4855 144.377 27.421 144.312 27.3414 144.312C27.2618 144.312 27.1973 144.377 27.1973 144.456C27.1973 144.535 27.2618 144.6 27.3414 144.6Z"
                        fill="white"
                    />
                    <path
                        d="M39.1766 152.821C37.8233 151.749 36.1944 151.078 34.4763 150.885C32.1268 150.595 29.7503 150.595 27.4008 150.885C25.6924 151.082 24.0739 151.753 22.7287 152.821C20.4318 154.641 19.7518 158.041 20.5039 160.745C20.9708 162.413 22.5438 163.2 24.1168 163.574C26.3892 164.092 28.7181 164.322 31.0482 164.258C33.3388 164.224 35.8143 164.114 38.0297 163.499C39.4962 163.093 40.9658 162.3 41.3983 160.745C42.1534 158.029 41.4735 154.629 39.1766 152.821ZM40.6274 160.533C40.3423 161.554 39.3959 162.294 37.8072 162.719C35.7078 163.3 33.3075 163.412 31.0263 163.446H30.4685C28.3905 163.469 26.3171 163.244 24.2923 162.778C22.5908 162.372 21.5724 161.61 21.2685 160.514C20.6418 158.257 21.1055 155.1 23.2175 153.427C24.456 152.449 25.946 151.838 27.5167 151.666C29.7985 151.383 32.1068 151.383 34.3886 151.666C35.9557 151.847 37.4401 152.463 38.6721 153.445C40.7872 155.106 41.2573 158.263 40.6274 160.521V160.533Z"
                        fill="url(#paint6_linear_758_35651)"
                    />
                    <path
                        d="M24.2242 157.455C24.3783 156.215 24.9933 155.077 25.9476 154.267C27.031 153.411 28.3339 152.875 29.7078 152.721C30.4505 152.627 31.2245 152.565 32.0141 152.537C31.7008 152.537 31.3561 152.518 31.0208 152.518C29.9177 152.516 28.8156 152.584 27.7212 152.721C26.347 152.874 25.0438 153.41 23.961 154.267C23.0066 155.078 22.3908 156.215 22.2344 157.455H24.2242Z"
                        fill="url(#paint7_linear_758_35651)"
                    />
                    <path
                        d="M31.0234 162.385H31.0892H31.5906L31.0234 162.363V162.385Z"
                        fill="url(#paint8_linear_758_35651)"
                    />
                    <path
                        d="M27.526 142.455C27.2126 142.648 26.8993 142.836 26.5859 143.032C26.4654 143.106 26.3653 143.209 26.2947 143.331C26.2241 143.453 26.1853 143.591 26.1817 143.732C26.1802 143.882 26.2196 144.031 26.2957 144.161C26.3718 144.291 26.4818 144.399 26.6141 144.472L29.851 146.517C30.186 146.748 30.5812 146.877 30.9885 146.888C31.3971 146.898 31.7997 146.789 32.1479 146.576L34.4887 145.171L35.4099 144.622C35.5452 144.553 35.6594 144.449 35.7403 144.321C35.8211 144.194 35.8657 144.046 35.8693 143.895C35.8728 143.744 35.8352 143.595 35.7603 143.463C35.6855 143.331 35.5764 143.222 35.4444 143.148L32.9376 141.568L31.9975 142.13C31.8677 142.202 31.7596 142.308 31.6843 142.436C31.6091 142.564 31.5694 142.709 31.5694 142.857C31.5694 143.006 31.6091 143.151 31.6843 143.279C31.7596 143.407 31.8677 143.513 31.9975 143.585L32.4111 143.841L31.0073 144.675L29.967 144.016L29.616 143.766L30.6438 143.17C30.7646 143.096 30.8647 142.993 30.9348 142.871C31.0048 142.748 31.0426 142.61 31.0444 142.469C31.0463 142.328 31.0123 142.189 30.9455 142.065C30.8787 141.94 30.7814 141.835 30.6626 141.758L29.7069 141.156L27.5322 142.448"
                        fill="url(#paint9_linear_758_35651)"
                    />
                    <path
                        opacity="0.47"
                        d="M29.747 142.253C29.8549 142.191 29.8919 142.054 29.8296 141.946C29.7673 141.839 29.6293 141.802 29.5214 141.864L28.0343 142.719C27.9264 142.781 27.8894 142.919 27.9517 143.026C28.014 143.134 28.152 143.171 28.2599 143.109L29.747 142.253Z"
                        fill="white"
                    />
                    <path
                        opacity="0.47"
                        d="M27.5094 143.582C27.6634 143.582 27.7882 143.457 27.7882 143.304C27.7882 143.15 27.6634 143.026 27.5094 143.026C27.3553 143.026 27.2305 143.15 27.2305 143.304C27.2305 143.457 27.3553 143.582 27.5094 143.582Z"
                        fill="white"
                    />
                    <path
                        d="M26.8437 159.993C28.2611 159.993 29.4101 158.848 29.4101 157.436C29.4101 156.023 28.2611 154.878 26.8437 154.878C25.4263 154.878 24.2773 156.023 24.2773 157.436C24.2773 158.848 25.4263 159.993 26.8437 159.993Z"
                        fill="white"
                    />
                    <path
                        d="M35.873 159.993C37.2904 159.993 38.4394 158.848 38.4394 157.436C38.4394 156.023 37.2904 154.878 35.873 154.878C34.4556 154.878 33.3066 156.023 33.3066 157.436C33.3066 158.848 34.4556 159.993 35.873 159.993Z"
                        fill="white"
                    />
                    <path
                        d="M35.8739 154.875C35.769 154.876 35.6643 154.885 35.5605 154.9C36.1779 154.981 36.7446 155.283 37.1552 155.75C37.5657 156.216 37.7921 156.815 37.7921 157.436C37.7921 158.056 37.5657 158.655 37.1552 159.122C36.7446 159.588 36.1779 159.89 35.5605 159.971C35.6645 159.984 35.7691 159.992 35.8739 159.993C36.2187 160.005 36.5623 159.948 36.8844 159.824C37.2065 159.701 37.5004 159.515 37.7485 159.276C37.9967 159.037 38.194 158.751 38.3288 158.435C38.4636 158.118 38.5331 157.778 38.5331 157.434C38.5331 157.09 38.4636 156.75 38.3288 156.434C38.194 156.117 37.9967 155.831 37.7485 155.592C37.5004 155.354 37.2065 155.167 36.8844 155.044C36.5623 154.921 36.2187 154.863 35.8739 154.875Z"
                        fill="#EDE9EE"
                    />
                    <path
                        d="M26.8428 154.875C26.9477 154.876 27.0524 154.885 27.1562 154.9C26.5346 154.976 25.9623 155.275 25.5471 155.742C25.132 156.209 24.9028 156.812 24.9028 157.436C24.9028 158.06 25.132 158.662 25.5471 159.129C25.9623 159.596 26.5346 159.896 27.1562 159.971C27.0522 159.984 26.9476 159.991 26.8428 159.993C26.498 160.005 26.1544 159.948 25.8323 159.824C25.5102 159.701 25.2163 159.515 24.9682 159.276C24.72 159.037 24.5227 158.751 24.3879 158.435C24.2531 158.118 24.1836 157.778 24.1836 157.434C24.1836 157.09 24.2531 156.75 24.3879 156.434C24.5227 156.117 24.72 155.831 24.9682 155.592C25.2163 155.354 25.5102 155.167 25.8323 155.044C26.1544 154.921 26.498 154.863 26.8428 154.875Z"
                        fill="#EDE9EE"
                    />
                    <path
                        d="M27.5092 157.951C27.7948 157.951 28.0263 157.72 28.0263 157.436C28.0263 157.151 27.7948 156.92 27.5092 156.92C27.2237 156.92 26.9922 157.151 26.9922 157.436C26.9922 157.72 27.2237 157.951 27.5092 157.951Z"
                        fill="#17171A"
                    />
                    <path
                        d="M34.7104 157.951C34.9959 157.951 35.2274 157.72 35.2274 157.436C35.2274 157.151 34.9959 156.92 34.7104 156.92C34.4248 156.92 34.1934 157.151 34.1934 157.436C34.1934 157.72 34.4248 157.951 34.7104 157.951Z"
                        fill="#17171A"
                    />
                    <path
                        d="M31.5495 160.72C31.7009 160.724 31.851 160.691 31.9869 160.624C32.1227 160.557 32.2403 160.458 32.3297 160.336C32.3532 160.308 32.3706 160.276 32.381 160.241C32.3914 160.206 32.3944 160.17 32.39 160.134C32.3856 160.098 32.3738 160.063 32.3553 160.031C32.3369 160 32.3121 159.973 32.2827 159.951C32.2532 159.93 32.2196 159.915 32.184 159.907C32.1484 159.899 32.1115 159.898 32.0757 159.905C32.0399 159.912 32.0058 159.926 31.9757 159.946C31.9455 159.967 31.9199 159.993 31.9004 160.024C31.8266 160.107 31.7275 160.164 31.6182 160.185C31.5089 160.207 31.3955 160.192 31.2956 160.143C31.2307 160.115 31.1576 160.114 31.092 160.14C31.0264 160.166 30.9735 160.216 30.9447 160.28C30.9162 160.344 30.9143 160.417 30.9396 160.483C30.9648 160.548 31.0151 160.601 31.0794 160.63C31.2284 160.691 31.3883 160.722 31.5495 160.72Z"
                        fill="#EDE9EE"
                    />
                </g>
            </g>
            <g filter="url(#filter2_d_758_35651)">
                <circle cx="95.5082" cy="41.7329" r="35.7329" fill="#96E8D1" />
                <circle cx="95.5082" cy="41.7329" r="35.0036" stroke="white" strokeWidth="1.45848" />
            </g>
            <path
                d="M100.67 57.5665C100.985 57.5735 101.298 57.5043 101.58 57.3645C101.863 57.2248 102.108 57.0188 102.294 56.7641C102.369 56.6452 102.396 56.5022 102.37 56.3641C102.344 56.226 102.266 56.1031 102.152 56.0202C102.039 55.9373 101.898 55.9006 101.758 55.9176C101.619 55.9346 101.491 56.004 101.4 56.1117C101.248 56.2874 101.042 56.4071 100.813 56.452C100.585 56.4969 100.349 56.4644 100.141 56.3596C100.006 56.3024 99.8541 56.3004 99.7175 56.3541C99.5809 56.4077 99.4708 56.5128 99.4108 56.6467C99.3514 56.7808 99.3476 56.933 99.4001 57.0699C99.4527 57.2068 99.5574 57.3174 99.6913 57.3773C100.001 57.5054 100.334 57.5698 100.67 57.5665Z"
                fill="#EDE9EE"
            />
            <path
                d="M125.758 42.4583C127.891 42.093 130.201 45.2046 130.944 49.4122V49.2817C130.051 44.063 127.585 40.0837 125.439 40.4491C123.292 40.8144 122.268 45.3612 123.155 50.606C123.155 50.6517 123.155 50.6908 123.155 50.7365C122.497 46.5158 123.632 42.8171 125.758 42.4583Z"
                fill="#22C3F3"
            />
            <path
                d="M128.367 57.9123C126.41 58.245 124.277 55.6095 123.377 51.8781C124.421 56.4445 126.639 59.7715 128.628 59.4388C130.618 59.1061 131.629 55.2507 131.107 50.5474C131.492 54.3896 130.364 57.5731 128.367 57.9123Z"
                fill="#FAAF1B"
            />
            <path
                d="M65.2601 42.4583C63.1334 42.093 60.8242 45.2046 60.0805 49.4122C60.0773 49.3688 60.0773 49.3252 60.0805 49.2817C60.9677 44.063 63.4335 40.0837 65.5862 40.4491C67.739 40.8144 68.7566 45.3612 67.8629 50.606C67.866 50.6494 67.866 50.693 67.8629 50.7365C68.5479 46.5158 67.3932 42.8171 65.2601 42.4583Z"
                fill="#FAAF1B"
            />
            <path
                d="M62.6377 57.9121C64.5947 58.2448 66.7279 55.6093 67.6281 51.8779C66.5778 56.4443 64.3664 59.7712 62.3767 59.4385C60.3871 59.1059 59.389 55.2505 59.9174 50.5732C59.5326 54.3894 60.6676 57.5729 62.6377 57.9121Z"
                fill="#22C3F3"
            />
            <path
                d="M117.714 39.3139C116.369 39.5836 115.182 40.3677 114.406 41.4992C114.948 41.09 115.578 40.813 116.246 40.6903C119.919 40.0706 123.748 44.6044 124.798 50.7625C125.848 56.9206 123.728 62.4655 120.056 63.0917C119.383 63.2025 118.694 63.1488 118.046 62.9351C119.153 63.7506 120.534 64.1017 121.895 63.9136C125.933 63.2287 128.262 57.1619 127.114 50.3711C125.966 43.5802 121.752 38.6224 117.714 39.3139Z"
                fill="#E43383"
            />
            <path
                d="M73.3098 39.3139C74.6575 39.5814 75.847 40.3658 76.6237 41.4992C76.0796 41.0897 75.4475 40.8127 74.7775 40.6903C71.1049 40.0706 67.2821 44.6044 66.2253 50.7625C65.1686 56.9205 67.3017 62.4655 70.9744 63.0917C71.6448 63.2028 72.3321 63.149 72.9771 62.9351C71.8714 63.7519 70.4898 64.1031 69.1283 63.9136C65.0968 63.2287 62.7614 57.1619 63.9095 50.3711C65.0577 43.5802 69.2783 38.6224 73.3098 39.3139Z"
                fill="#E43383"
            />
            <path
                d="M122.496 54.4617C121.687 50.1432 120.611 45.7008 118.184 41.989C117.917 41.5845 117.643 41.1931 117.349 40.8082C111.915 33.6782 102.345 30.6448 93.6496 31.134C85.8215 31.5776 77.8564 34.8002 73.1791 41.291C70.0479 45.6356 68.5736 50.9782 68.2605 56.2948C68.0517 59.9218 68.0452 64.1881 70.3219 67.2802C80.5962 81.2142 110.363 81.0446 120.506 67.2802C123.259 63.5423 123.338 58.7672 122.496 54.4617Z"
                fill="url(#paint10_linear_758_35651)"
            />
            <path
                d="M94.3535 63.9726C89.7019 64.0269 85.0614 63.5076 80.5369 62.4265C77.1121 61.572 75.0834 60.052 74.5158 57.9188C73.2112 52.948 74.144 46.0136 78.5473 42.3736C81.1711 40.1986 84.3675 38.8281 87.7518 38.4269C92.8579 37.7659 98.0279 37.7659 103.134 38.4269C106.516 38.8293 109.71 40.1998 112.332 42.3736C116.735 46.0136 117.694 52.948 116.37 57.9188C115.842 59.8759 113.963 61.3567 110.792 62.2765C106.135 63.6203 100.746 63.8812 95.6125 63.9661L94.3535 63.9726Z"
                fill="#9863A9"
            />
            <path
                d="M94.4439 62.3872C90.2059 62.4316 85.9775 61.974 81.8472 61.0238C78.729 60.2736 76.8829 58.9493 76.361 57.0706C75.1542 52.713 76.0283 46.6332 80.0402 43.4367C82.4545 41.5202 85.3594 40.3221 88.4228 39.9793C93.0775 39.3966 97.7868 39.3966 102.442 39.9793C105.506 40.319 108.411 41.5175 110.824 43.4367C114.836 46.6266 115.717 52.713 114.503 57.0706C114.021 58.8058 112.311 60.0909 109.422 60.8933C105.175 62.074 100.289 62.3024 95.592 62.3741H94.4439V62.3872Z"
                fill="#121212"
            />
            <path
                d="M94.4443 61.2717C90.2952 61.3288 86.154 60.8908 82.1086 59.967C79.4144 59.3147 77.8423 58.2579 77.4378 56.8032C76.3223 52.7978 77.0986 47.2268 80.7321 44.3304C82.988 42.544 85.7005 41.4274 88.5602 41.1078C93.12 40.5425 97.7322 40.5425 102.292 41.1078C105.152 41.4246 107.866 42.5415 110.12 44.3304C113.754 47.2268 114.53 52.8108 113.421 56.8032C113.049 58.1078 111.601 59.1581 109.109 59.8496C104.986 60.9912 100.159 61.2195 95.5598 61.2913L94.4443 61.2717Z"
                fill="#17171A"
            />
            <path d="M78.8424 35.8433L78.5996 36.041L81.2932 39.3495L81.536 39.1518L78.8424 35.8433Z" fill="white" />
            <path
                d="M112.463 36.0978C112.378 36.0391 112.287 35.9869 112.202 35.9216L109.449 39.3464L109.691 39.5487L112.463 36.0978Z"
                fill="white"
            />
            <path
                d="M92.3798 21.1071L92.3766 21.1015C92.3045 20.9767 92.1449 20.9339 92.0201 21.006L88.5683 22.9989C88.4435 23.0709 88.4008 23.2305 88.4728 23.3553L88.4761 23.3609C88.5481 23.4858 88.7077 23.5285 88.8325 23.4565L92.2843 21.4636C92.4091 21.3915 92.4519 21.2319 92.3798 21.1071Z"
                fill="white"
            />
            <path
                d="M87.929 24.1077C88.0947 24.1077 88.2291 23.9734 88.2291 23.8076C88.2291 23.6419 88.0947 23.5076 87.929 23.5076C87.7633 23.5076 87.6289 23.6419 87.6289 23.8076C87.6289 23.9734 87.7633 24.1077 87.929 24.1077Z"
                fill="white"
            />
            <path
                d="M112.56 41.2581C109.742 39.019 106.351 37.6195 102.775 37.2201C97.8856 36.6135 92.9403 36.6135 88.0513 37.2201C84.4748 37.6195 81.0834 39.019 78.2662 41.2581C73.4845 45.0613 72.0689 52.1653 73.6411 57.8145C74.6065 61.298 77.8878 62.9419 81.156 63.7247C85.8867 64.8059 90.7349 65.2859 95.5858 65.1534C100.354 65.0816 105.508 64.8533 110.12 63.5747C113.173 62.7266 116.232 61.0632 117.139 57.8145C118.757 52.1653 117.341 45.0613 112.56 41.2581ZM115.58 57.3709C114.986 59.5041 113.016 61.0501 109.709 61.9373C105.345 63.1507 100.341 63.3855 95.5923 63.4638H94.4442C90.1207 63.5093 85.807 63.0429 81.5931 62.0743C78.0444 61.2263 75.9308 59.628 75.2915 57.3383C73.9868 52.6284 74.9588 46.0267 79.349 42.5302C81.9234 40.4909 85.0167 39.212 88.2796 38.8379C93.0304 38.2552 97.8346 38.2552 102.585 38.8379C105.849 39.2089 108.943 40.4882 111.516 42.5302C115.919 46.0593 116.891 52.661 115.58 57.3709Z"
                fill="url(#paint11_linear_758_35651)"
            />
            <path
                d="M81.4308 50.965C81.7565 48.3743 83.0384 45.9988 85.0252 44.3046C87.28 42.5163 89.993 41.3995 92.8532 41.082C94.3993 40.8798 96.0106 40.7558 97.6545 40.6971C96.9695 40.6971 96.2846 40.658 95.5866 40.658C93.2901 40.6507 90.9956 40.7924 88.7174 41.082C85.8574 41.4005 83.1447 42.5172 80.8893 44.3046C78.9051 46.0008 77.6237 48.3753 77.2949 50.965H81.4308Z"
                fill="url(#paint12_linear_758_35651)"
            />
            <path
                d="M95.5859 61.2653H95.749H96.7993L95.612 61.2131L95.5859 61.2653Z"
                fill="url(#paint13_linear_758_35651)"
            />
            <path
                d="M88.306 19.6329C87.6537 20.0308 87.0014 20.4222 86.349 20.8332C86.0981 20.9868 85.8901 21.2013 85.7441 21.4567C85.5982 21.7122 85.519 22.0003 85.514 22.2944C85.5076 22.6108 85.5882 22.9229 85.7469 23.1967C85.9056 23.4705 86.1365 23.6954 86.4143 23.847L93.1399 28.1198C93.8495 28.5934 94.6809 28.8517 95.534 28.8635C96.385 28.8865 97.2241 28.6597 97.9476 28.2112L102.827 25.2821L104.738 24.1275C105.02 23.9855 105.258 23.7695 105.427 23.5027C105.596 23.2359 105.689 22.9283 105.696 22.6128C105.703 22.2972 105.625 21.9856 105.469 21.7112C105.313 21.4369 105.085 21.21 104.81 21.055L99.5915 17.7476L97.6345 18.9218C97.3693 19.0719 97.1478 19.2887 96.9922 19.5508C96.8366 19.8128 96.7522 20.111 96.7473 20.4157C96.7415 20.7303 96.8216 21.0406 96.9791 21.3131C97.1366 21.5855 97.3655 21.8098 97.641 21.9617L98.4956 22.5032L95.5731 24.2449L93.4008 22.862L92.6637 22.3923L94.8033 21.1463C95.0533 20.9917 95.2603 20.7765 95.4051 20.5207C95.5498 20.2649 95.6278 19.9767 95.6317 19.6828C95.6356 19.3889 95.5653 19.0987 95.4274 18.8391C95.2894 18.5796 95.0883 18.359 94.8425 18.1977L92.8463 16.9387L88.3256 19.6394"
                fill="url(#paint14_linear_758_35651)"
            />
            <path
                opacity="0.47"
                d="M92.9282 19.2105C93.1528 19.0808 93.2298 18.7935 93.1001 18.5689C92.9704 18.3442 92.6831 18.2673 92.4585 18.397L89.3626 20.1844C89.1379 20.3141 89.061 20.6013 89.1907 20.826C89.3204 21.0506 89.6076 21.1276 89.8323 20.9979L92.9282 19.2105Z"
                fill="white"
            />
            <path
                opacity="0.47"
                d="M88.2739 21.9815C88.5946 21.9815 88.8545 21.7215 88.8545 21.4009C88.8545 21.0802 88.5946 20.8203 88.2739 20.8203C87.9533 20.8203 87.6934 21.0802 87.6934 21.4009C87.6934 21.7215 87.9533 21.9815 88.2739 21.9815Z"
                fill="white"
            />
            <g opacity="0.35">
                <path
                    d="M78.5581 67.3383C77.7557 67.8928 77.0838 69.2105 76.6402 73.3202C76.1966 77.43 71.963 77.7496 69.3601 76.2623C67.24 75.0489 65.831 69.902 67.6053 66.3271C70.7431 66.7838 74.3962 67.1295 78.395 67.3318L78.5581 67.3383Z"
                    fill="#371A45"
                />
            </g>
            <path
                d="M76.5317 64.2792C75.7032 63.7052 71.7631 62.0743 68.2339 64.5075C64.209 67.2865 65.6245 74.1035 68.11 75.4603C70.5954 76.8172 74.4768 76.5628 74.9008 72.8184C75.364 68.7086 76.0815 67.704 76.9492 67.2931C77.4729 67.1169 78.0125 66.9924 78.5604 66.9212C78.1301 65.8764 77.43 64.9647 76.5317 64.2792Z"
                fill="url(#paint15_linear_758_35651)"
            />
            <path
                d="M76.7675 67.391C76.351 66.5519 75.6606 65.8799 74.8105 65.4862C72.9791 64.5548 70.9481 64.0846 68.8938 64.1162C68.6697 64.2353 68.4519 64.3659 68.2414 64.5077C68.0297 64.6609 67.8271 64.8264 67.6348 65.0034H67.7C69.2069 64.475 72.4295 65.0034 74.4582 66.0863C75.1693 66.4712 76.0434 67.0974 76.1935 67.8868V67.9324C76.347 67.7157 76.5422 67.5316 76.7675 67.391Z"
                fill="#22C3F3"
            />
            <g opacity="0.25">
                <path
                    d="M78.5591 66.921C78.0112 66.9922 77.4715 67.1167 76.9478 67.2928C76.0802 67.7038 75.3626 68.7084 74.8995 72.8182C74.4755 76.5626 70.5353 76.8039 68.1086 75.4601C67.4016 75.0037 66.8491 74.3443 66.5234 73.5683C66.7363 73.8144 66.9901 74.0219 67.2736 74.1815C69.7003 75.4862 73.6405 75.284 74.0645 71.533C74.5211 67.4298 75.3692 66.6209 76.1128 66.0142C76.6021 65.6098 77.4632 65.8446 78.1808 66.1577C78.3241 66.4033 78.4505 66.6583 78.5591 66.921Z"
                    fill="#371A45"
                />
            </g>
            <g opacity="0.35">
                <path
                    d="M110.498 67.5083C111.3 68.0628 111.972 69.3805 112.416 73.4902C112.86 77.6 117.093 77.9196 119.696 76.4323C121.816 75.2189 123.225 70.072 121.451 66.5037C118.313 66.9538 114.66 67.2995 110.661 67.5017L110.498 67.5083Z"
                    fill="#371A45"
                />
            </g>
            <path
                d="M112.527 64.4162C113.355 63.8421 117.295 62.2113 120.825 64.6445C124.85 67.4235 123.434 74.2404 120.949 75.5973C118.463 76.9542 114.582 76.6998 114.158 72.9879C113.695 68.8847 112.977 67.8736 112.109 67.4626C111.586 67.2865 111.046 67.1619 110.498 67.0908C110.923 66.0341 111.624 65.1106 112.527 64.4162Z"
                fill="url(#paint16_linear_758_35651)"
            />
            <path
                d="M121.406 65.1209C121.224 64.9489 121.03 64.7897 120.826 64.6447C120.61 64.5012 120.395 64.3707 120.173 64.2468C118.134 64.2299 116.121 64.7019 114.302 65.6232C113.44 66.0113 112.738 66.6838 112.312 67.528C112.542 67.6656 112.741 67.8475 112.9 68.063C112.903 68.048 112.903 68.0323 112.9 68.0173C113.056 67.228 113.937 66.6017 114.648 66.2168C116.67 65.1274 119.886 64.6121 121.406 65.1209Z"
                fill="#22C3F3"
            />
            <g opacity="0.25">
                <path
                    d="M110.498 67.0581C111.046 67.1293 111.586 67.2538 112.109 67.4299C112.977 67.8409 113.695 68.852 114.158 72.9553C114.582 76.6997 118.522 76.9411 120.949 75.5646C121.656 75.1082 122.208 74.4488 122.534 73.6728C122.321 73.9189 122.067 74.1264 121.784 74.286C119.357 75.6233 115.417 75.3885 114.993 71.6375C114.536 67.5343 113.688 66.7254 112.944 66.1187C112.455 65.7143 111.594 65.9491 110.876 66.2622C110.732 66.5183 110.605 66.7842 110.498 67.0581Z"
                    fill="#371A45"
                />
            </g>
            <path
                d="M84.6623 52.7128C86.8513 52.6884 89.0158 53.1754 90.9835 54.1349C91.1343 54.2096 91.299 54.2519 91.4671 54.2591C91.6352 54.2664 91.8029 54.2384 91.9596 54.1769C92.1162 54.1155 92.2583 54.022 92.3767 53.9024C92.495 53.7828 92.5871 53.6398 92.6469 53.4826C92.9881 52.5473 93.0649 51.5361 92.8687 50.5601C92.6006 49.2637 91.8613 48.1129 90.7938 47.33C89.7263 46.5472 88.4064 46.188 87.0894 46.3219C85.7724 46.4558 84.5518 47.0732 83.6637 48.0549C82.7756 49.0366 82.283 50.3127 82.2812 51.6365C82.2866 51.7986 82.3254 51.958 82.3951 52.1045C82.4648 52.2511 82.5639 52.3817 82.6863 52.4882C82.8088 52.5947 82.9518 52.6748 83.1066 52.7236C83.2614 52.7723 83.4246 52.7886 83.5859 52.7715C83.8925 52.7324 84.2644 52.7128 84.6623 52.7128Z"
                fill="white"
            />
            <path
                d="M83.6088 51.6106C83.6092 50.3126 84.0821 49.0591 84.9393 48.0843C85.7964 47.1095 86.9791 46.4801 88.2665 46.3136L87.7838 46.281H87.2684L86.9292 46.3136L86.6944 46.3528L86.3682 46.418L86.1399 46.4767L85.8267 46.5811L85.6115 46.6594L85.2984 46.8029L85.1157 46.8942C84.9918 46.9594 84.8743 47.0312 84.7569 47.103L84.646 47.1682C84.4937 47.2701 84.3478 47.3812 84.2089 47.5009C84.1712 47.5278 84.1362 47.5584 84.1046 47.5922L83.8045 47.8662L83.6675 48.0162C83.5957 48.101 83.5175 48.1858 83.4457 48.2772L83.3152 48.4533C83.25 48.5446 83.1848 48.6294 83.126 48.7208L83.0086 48.9165L82.8521 49.2035L82.7542 49.4123C82.7085 49.5101 82.6694 49.6079 82.6303 49.7123C82.5911 49.8167 82.5716 49.8558 82.552 49.9276C82.5324 49.9993 82.4868 50.1429 82.4541 50.2538C82.4215 50.3647 82.415 50.3973 82.402 50.469C82.3889 50.5408 82.3563 50.7169 82.3367 50.8474C82.3172 50.9779 82.3367 50.9713 82.3041 51.03C82.2833 51.2229 82.2724 51.4167 82.2715 51.6106C82.2715 51.6106 82.2715 51.6106 82.2715 51.6106C82.278 51.769 82.3164 51.9244 82.3846 52.0675C82.4527 52.2106 82.5491 52.3384 82.668 52.4432C82.7868 52.5481 82.9257 52.6277 83.0762 52.6775C83.2266 52.7272 83.3856 52.746 83.5436 52.7327H83.7458C83.661 52.3645 83.6151 51.9884 83.6088 51.6106Z"
                fill="#EDE9EE"
            />
            <path
                d="M90.1486 52.413C90.7431 52.413 91.225 51.9311 91.225 51.3366C91.225 50.7422 90.7431 50.2603 90.1486 50.2603C89.5542 50.2603 89.0723 50.7422 89.0723 51.3366C89.0723 51.9311 89.5542 52.413 90.1486 52.413Z"
                fill="#17171A"
            />
            <path
                d="M106.397 52.7132C104.206 52.689 102.039 53.1759 100.069 54.1353C99.9189 54.2098 99.7546 54.2521 99.5869 54.2593C99.4193 54.2665 99.252 54.2384 99.0959 54.1769C98.9397 54.1155 98.7982 54.0219 98.6805 53.9024C98.5627 53.7829 98.4713 53.64 98.4122 53.4829C98.1574 52.7536 98.0638 51.9776 98.1379 51.2086C98.212 50.4395 98.452 49.6957 98.8413 49.0284C99.2307 48.3611 99.7601 47.7862 100.393 47.3433C101.026 46.9003 101.748 46.6 102.508 46.4629C103.268 46.3259 104.049 46.3553 104.797 46.5493C105.545 46.7433 106.242 47.0971 106.84 47.5864C107.438 48.0758 107.922 48.689 108.26 49.3837C108.598 50.0785 108.782 50.8383 108.797 51.6107C108.793 51.7732 108.755 51.933 108.686 52.08C108.616 52.227 108.517 52.358 108.394 52.4647C108.272 52.5714 108.128 52.6515 107.973 52.6999C107.818 52.7483 107.654 52.7639 107.493 52.7458C107.134 52.7327 106.769 52.7132 106.397 52.7132Z"
                fill="white"
            />
            <path
                d="M107.42 51.6106C107.418 50.312 106.944 49.0584 106.086 48.0837C105.227 47.1091 104.044 46.4799 102.756 46.3136H102.795L103.271 46.281H103.774L104.113 46.3136L104.354 46.3528L104.674 46.418L104.902 46.4767L105.222 46.5811L105.43 46.6594L105.75 46.8029L105.933 46.8942C106.057 46.9594 106.174 47.0312 106.292 47.103L106.402 47.1682C106.552 47.2726 106.696 47.3835 106.84 47.5009L106.944 47.5922L107.237 47.8662L107.374 48.0162C107.453 48.101 107.524 48.1858 107.596 48.2772C107.668 48.3685 107.688 48.3946 107.733 48.4533L107.916 48.7208C107.962 48.786 108.001 48.8512 108.04 48.9165C108.079 48.9817 108.144 49.1056 108.19 49.2035C108.229 49.2711 108.263 49.3408 108.294 49.4123C108.34 49.5101 108.379 49.6079 108.418 49.7123L108.496 49.9276C108.496 50.032 108.562 50.1429 108.588 50.2538L108.646 50.469C108.646 50.593 108.692 50.7169 108.712 50.8474C108.731 50.9779 108.712 50.9713 108.712 51.03C108.722 51.2234 108.722 51.4172 108.712 51.6106C108.706 51.772 108.666 51.9304 108.596 52.0759C108.526 52.2213 108.426 52.3508 108.304 52.4561C108.182 52.5614 108.039 52.6403 107.884 52.6879C107.73 52.7355 107.568 52.7507 107.407 52.7327H107.205C107.316 52.3673 107.388 51.9912 107.42 51.6106Z"
                fill="#EDE9EE"
            />
            <path
                d="M100.885 52.413C101.479 52.413 101.961 51.9311 101.961 51.3366C101.961 50.7422 101.479 50.2603 100.885 50.2603C100.29 50.2603 99.8086 50.7422 99.8086 51.3366C99.8086 51.9311 100.29 52.413 100.885 52.413Z"
                fill="#17171A"
            />
            <path
                d="M146.557 89.8333C146.557 82.6168 152.433 76.7666 159.683 76.7666C166.932 76.7666 172.809 82.6168 172.809 89.8333C172.809 97.0499 166.933 102.9 159.683 102.9C158.161 102.9 156.701 102.641 155.342 102.168L151.337 104.101V99.9193C148.418 97.5227 146.557 93.8944 146.557 89.8333Z"
                fill="#FFCC6D"
            />
            <path
                d="M159.59 93.6603C156.634 93.6603 154.059 92.0478 153.328 89.7398C153.27 89.5581 153.406 89.3734 153.631 89.3271C153.856 89.2798 154.086 89.3902 154.143 89.5719C154.78 91.5797 157.019 92.982 159.589 92.982C162.159 92.982 164.399 91.5797 165.035 89.5719C165.092 89.3902 165.321 89.2798 165.547 89.3271C165.773 89.3734 165.908 89.5581 165.851 89.7398C165.12 92.0478 162.545 93.6603 159.59 93.6603Z"
                fill="black"
            />
            <circle cx="156.694" cy="87.3853" r="0.965355" fill="black" />
            <circle cx="162.969" cy="87.3853" r="0.965355" fill="black" />
            <path
                d="M105.287 178.75H91.6209C91.5436 178.75 91.467 178.762 91.3935 178.786L91.2209 178.844C90.844 178.969 90.6401 179.376 90.7655 179.753C90.7662 179.755 90.767 179.758 90.7678 179.76L97.9977 200.887L93.2363 206.328C92.9749 206.627 93.0055 207.081 93.3046 207.343C93.4358 207.458 93.6042 207.521 93.7786 207.52H105.287C107.273 207.52 108.883 205.91 108.883 203.924V182.347C108.883 180.36 107.273 178.75 105.287 178.75Z"
                fill="#ECEFF1"
            />
            <path
                d="M99.4687 200.75C99.352 200.493 99.0961 200.328 98.8141 200.328H91.6216C91.2244 200.328 90.9023 200.65 90.9023 201.047C90.9023 201.134 90.9179 201.22 90.9484 201.301L93.1062 207.055C93.1952 207.292 93.4023 207.464 93.6513 207.509C93.6936 207.516 93.7364 207.52 93.7794 207.521C93.9867 207.521 94.1838 207.431 94.3202 207.275L99.3551 201.521C99.541 201.308 99.5854 201.007 99.4687 200.75Z"
                fill="#1976D2"
            />
            <path
                d="M105.289 188.82H95.2193C94.822 188.82 94.5 188.498 94.5 188.101C94.5 187.704 94.822 187.382 95.2193 187.382H105.289C105.686 187.382 106.008 187.704 106.008 188.101C106.008 188.498 105.686 188.82 105.289 188.82Z"
                fill="#455A64"
            />
            <path
                d="M99.5357 188.82C99.1385 188.82 98.8164 188.498 98.8164 188.101V186.662C98.8164 186.265 99.1385 185.943 99.5357 185.943C99.9329 185.943 100.255 186.265 100.255 186.662V188.101C100.255 188.498 99.9329 188.82 99.5357 188.82Z"
                fill="#455A64"
            />
            <path
                d="M97.3768 197.451C96.9796 197.451 96.6578 197.129 96.6582 196.731C96.6584 196.499 96.7707 196.281 96.9597 196.147C100.11 193.908 102.412 189.345 102.412 188.101C102.412 187.704 102.734 187.382 103.131 187.382C103.528 187.382 103.85 187.704 103.85 188.101C103.85 189.997 101.078 194.986 97.7941 197.317C97.6722 197.404 97.5264 197.451 97.3768 197.451Z"
                fill="#455A64"
            />
            <path
                d="M103.132 198.89C102.952 198.89 102.778 198.823 102.645 198.701C102.125 198.223 97.5271 193.977 96.7114 191.967C96.5617 191.599 96.7388 191.179 97.107 191.03C97.4753 190.88 97.8952 191.057 98.0449 191.425C98.6333 192.875 102.268 196.402 103.619 197.638C103.913 197.905 103.935 198.36 103.668 198.654C103.53 198.805 103.335 198.891 103.132 198.89Z"
                fill="#455A64"
            />
            <path
                d="M99.4945 200.814L90.1442 173.483C90.0446 173.192 89.7712 172.996 89.4637 172.996H77.9556C75.9695 172.996 74.3594 174.606 74.3594 176.593V198.17C74.3594 200.156 75.9695 201.767 77.9556 201.767H98.814C99.2113 201.767 99.5332 201.444 99.5332 201.047C99.5333 200.968 99.5202 200.889 99.4945 200.814Z"
                fill="#2196F3"
            />
            <path
                d="M88.0226 193.135C87.7185 193.135 87.4472 192.944 87.345 192.658L84.4263 184.484L81.5076 192.658C81.3591 193.026 80.94 193.204 80.5715 193.056C80.2239 192.916 80.0422 192.532 80.1539 192.174L83.7502 182.105C83.9261 181.731 84.3718 181.571 84.7455 181.746C84.903 181.821 85.0297 181.947 85.1039 182.105L88.7001 192.174C88.834 192.547 88.641 192.958 88.2686 193.093C88.1895 193.121 88.1063 193.136 88.0226 193.135Z"
                fill="#FAFAFA"
            />
            <path
                d="M85.8659 188.82H82.9888C82.5916 188.82 82.2695 188.498 82.2695 188.1C82.2695 187.703 82.5916 187.381 82.9888 187.381H85.8659C86.2631 187.381 86.5851 187.703 86.5851 188.1C86.5851 188.498 86.2631 188.82 85.8659 188.82Z"
                fill="#FAFAFA"
            />
            <path
                d="M44.6322 74.9174C44.6322 65.0479 36.5949 57.0471 26.6804 57.0471C16.7658 57.0471 8.72852 65.0479 8.72852 74.9174C8.72852 84.7869 16.7658 92.7877 26.6804 92.7877C28.7613 92.7877 30.7589 92.4341 32.6174 91.7859L38.0941 94.4309V88.7113C42.0869 85.4336 44.6322 80.4714 44.6322 74.9174Z"
                fill="#FF9E5E"
            />
            <path
                d="M26.6802 57.0471C26.1148 57.0471 25.5559 57.0745 25.0039 57.1253C34.1323 57.967 41.2795 65.6107 41.2795 74.9174C41.2795 84.07 33.94 91.8459 24.9785 92.7073C25.5387 92.7598 26.1062 92.7877 26.6802 92.7877C28.7611 92.7877 30.7587 92.434 32.6172 91.7858L38.094 94.4308V88.7112C42.0867 85.4336 44.632 80.4714 44.632 74.9174C44.632 65.0479 36.5947 57.0471 26.6802 57.0471V57.0471Z"
                fill="#FA8D46"
            />
            <path
                d="M18.3736 77.806C19.7719 77.806 20.9054 76.6725 20.9054 75.2742C20.9054 73.876 19.7719 72.7424 18.3736 72.7424C16.9753 72.7424 15.8418 73.876 15.8418 75.2742C15.8418 76.6725 16.9753 77.806 18.3736 77.806Z"
                fill="#FFF7E8"
            />
            <path
                d="M26.6822 77.806C28.0804 77.806 29.214 76.6725 29.214 75.2742C29.214 73.876 28.0804 72.7424 26.6822 72.7424C25.2839 72.7424 24.1504 73.876 24.1504 75.2742C24.1504 76.6725 25.2839 77.806 26.6822 77.806Z"
                fill="#FFF7E8"
            />
            <path
                d="M34.9869 77.806C36.3851 77.806 37.5187 76.6725 37.5187 75.2742C37.5187 73.876 36.3851 72.7424 34.9869 72.7424C33.5886 72.7424 32.4551 73.876 32.4551 75.2742C32.4551 76.6725 33.5886 77.806 34.9869 77.806Z"
                fill="#FFF7E8"
            />
            <defs>
                <filter
                    id="filter0_d_758_35651"
                    x="115.602"
                    y="123.73"
                    width="101.291"
                    height="101.29"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.37809" />
                    <feGaussianBlur stdDeviation="3.44524" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_758_35651" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_758_35651" result="shape" />
                </filter>
                <filter
                    id="filter1_d_758_35651"
                    x="0.707582"
                    y="127.054"
                    width="61.2567"
                    height="61.2562"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.45848" />
                    <feGaussianBlur stdDeviation="3.64621" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_758_35651" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_758_35651" result="shape" />
                </filter>
                <filter
                    id="filter2_d_758_35651"
                    x="52.483"
                    y="0.166065"
                    width="86.0497"
                    height="86.0507"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.45848" />
                    <feGaussianBlur stdDeviation="3.64621" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_758_35651" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_758_35651" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_758_35651"
                    x1="170.415"
                    y1="150.501"
                    x2="150.299"
                    y2="131.209"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="0.67" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_758_35651"
                    x1="174.629"
                    y1="197.637"
                    x2="160.463"
                    y2="170.852"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset={1} stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_758_35651"
                    x1="151.354"
                    y1="168.617"
                    x2="188.808"
                    y2="190.075"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stopColor="white" />
                    <stop offset={1} stopColor="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_758_35651"
                    x1="161.921"
                    y1="158.719"
                    x2="155.384"
                    y2="180.081"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.4" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_758_35651"
                    x1="166.555"
                    y1="186.721"
                    x2="167.771"
                    y2="186.721"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.4" />
                    <stop offset="0.04" stopColor="#F4F5F5" stopOpacity="0.39" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_758_35651"
                    x1="34.7998"
                    y1="167.471"
                    x2="28.1781"
                    y2="154.947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset={1} stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_758_35651"
                    x1="23.9163"
                    y1="153.901"
                    x2="41.4301"
                    y2="163.934"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stopColor="white" />
                    <stop offset={1} stopColor="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_758_35651"
                    x1="28.8587"
                    y1="149.271"
                    x2="25.8015"
                    y2="159.262"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.4" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_758_35651"
                    x1="31.0234"
                    y1="162.366"
                    x2="31.5906"
                    y2="162.366"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.4" />
                    <stop offset="0.04" stopColor="#F4F5F5" stopOpacity="0.39" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_758_35651"
                    x1="32.831"
                    y1="145.518"
                    x2="23.4219"
                    y2="136.498"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="0.67" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_758_35651"
                    x1="103.454"
                    y1="71.8988"
                    x2="89.5855"
                    y2="45.766"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset={1} stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_758_35651"
                    x1="80.7907"
                    y1="43.5478"
                    x2="117.315"
                    y2="64.3966"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stopColor="white" />
                    <stop offset={1} stopColor="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_758_35651"
                    x1="91.0789"
                    y1="33.8736"
                    x2="84.6729"
                    y2="54.729"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.4" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_758_35651"
                    x1="95.5859"
                    y1="61.2327"
                    x2="96.7732"
                    y2="61.2327"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0.4" />
                    <stop offset="0.04" stopColor="#F4F5F5" stopOpacity="0.39" />
                    <stop offset={1} stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_758_35651"
                    x1="99.3502"
                    y1="26.0258"
                    x2="79.6951"
                    y2="7.25148"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="0.67" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_758_35651"
                    x1="65.7159"
                    y1="69.7067"
                    x2="78.5604"
                    y2="69.7067"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7C5FA7" />
                    <stop offset={1} stopColor="#5D3885" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_758_35651"
                    x1="120.733"
                    y1="74.808"
                    x2="114.745"
                    y2="63.4442"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7C5FA7" />
                    <stop offset={1} stopColor="#5D3885" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default React.memo(InviteMemberNoEmailSVG);
