import { Grid, IconButton, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import React from 'react';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    arrowDown: {
        display: 'flex'
    },
    closeButton: {
        position: 'absolute',
        right: -30,
        top: 14.5
    },
    ldPortal: {
        width: '100%'
    },
    ldControl: {
        border: 0,
        minHeight: 50,
        padding: '0 16px'
    }
}));

function ProjectAndDatabaseAccessItem({ index, access, projects, onProjectChange, onDatabaseChange, onAccessRemove }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const projectOptions = React.useMemo(() => {
        return projects?.map(project => {
            return {
                label: project.name,
                value: project.id,
                icon: () => <WorkspaceSVG color={theme.colors.steel} />
            };
        });
    }, [projects, theme]);

    const selectedProject = projects?.find(project => project.id === access.project?.value);
    const selectedProjectOption = selectedProject && {
        label: selectedProject?.name,
        value: selectedProject?.id,
        icon: () => <WorkspaceSVG color={theme.colors.steel} />
    };
    const databaseOptions = selectedProject
        ? [
              { value: -1, label: `(${t('all_databases')})`, icon: () => <DatabaseSVG /> },
              ...selectedProject?.databases?.map(database => ({
                  label: database.name,
                  value: database.id,
                  icon: () => <DatabaseSVG />
              }))
          ]
        : [];
    const selectedDatabaseOption = databaseOptions?.find(database => database.value === access.database?.value);

    return (
        <Grid item container className={classes.root}>
            <Grid xs item container wrap="nowrap" alignItems="center">
                <LDBasePortal
                    menuPlaceholder={'Search project...'}
                    options={projectOptions}
                    dropdownClassName={classes.ldPortal}
                    controlClassName={classes.ldControl}
                    onChange={projectOption => onProjectChange(index, projectOption)}
                    defaultValue={selectedProjectOption}
                />
            </Grid>
            <Grid xs item container wrap="nowrap" alignItems="center">
                <LDBasePortal
                    menuPlaceholder={'Search database...'}
                    options={databaseOptions}
                    dropdownClassName={classes.ldPortal}
                    controlClassName={classes.ldControl}
                    onChange={databaseOption => onDatabaseChange(index, databaseOption)}
                    defaultValue={selectedDatabaseOption}
                />
            </Grid>
            <IconButton
                className={`${classes.closeButton} closeButton`}
                size="small"
                onClick={() => onAccessRemove(index)}
            >
                <CloseIconSVG size="small" />
            </IconButton>
        </Grid>
    );
}

export default React.memo(ProjectAndDatabaseAccessItem);
