import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import DropdownSVG from 'assets/images/svg/DropdownSVG';
import ReactCountryFlag from 'react-country-flag';
import AsyncSelect from 'react-select/async';
import CountryCodes from './CountryCodes';
import ListItem from 'components/list/Item';

import Control from 'components/selects/common/Control';
import DropdownIndicator from 'components/selects/common/DropdownIndicator';
import Menu from 'components/selects/common/Menu';
import MultiValue from 'components/selects/common/MultiValue';
import NoOptionsMessage from 'components/selects/common/NoOptionsMessage';
import Placeholder from 'components/selects/common/Placeholder';
import SingleValue from 'components/selects/common/SingleValue';
import ValueContainer from 'components/selects/common/ValueContainer';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function Option({ data, isSelected, innerRef, children, innerProps }) {
    const { icon, value } = data;
    return (
        <ListItem
            ref={innerRef}
            icon={icon}
            name={children}
            isSelected={isSelected}
            moreIcon={<Typography variant="caption">{value}</Typography>}
            {...innerProps}
        />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: 36,
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        padding: '6px 0',
        maxWidth: '100%',
        position: 'relative',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    left: {
        borderRight: `1px solid ${theme.colors.silver}`,
        padding: '0 14px',
        flex: '0',
        cursor: 'pointer'
    },
    flag: {
        marginRight: theme.spacing(2)
    },
    dropdown: {
        marginLeft: theme.spacing(1)
    },
    right: {
        height: '100%',
        padding: '0 14px'
    },
    input: {
        height: '100%',
        border: 'none',
        outline: 'none',
        width: '100%'
    },
    menuDropdown: {
        position: 'absolute',
        width: '100%',
        zIndex: 1000,
        top: 40
    }
}));

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

const options = CountryCodes.map(country => {
    return {
        ...country,
        icon: <ReactCountryFlag countryCode={country.code} svg style={{ width: 24 }} />,
        value: country.dial_code,
        label: country.name
    };
});

const defaultOption = options?.find(country => country?.code === 'SE');

function InputPhone({ maxSize = 250, value, onChange }) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [rawPhoneNumber, setRawPhoneNumber] = useState('');
    const toggleOpen = () => setIsOpen(!isOpen);

    const countryCode = parsePhoneNumberFromString(value || '')?.country;
    const nationalNumber = parsePhoneNumberFromString(value || '')?.nationalNumber;

    useEffect(() => {
        setRawPhoneNumber(nationalNumber);
        setSelectedOption(options.find(option => option.code === countryCode) || defaultOption);
    }, [nationalNumber, countryCode]);

    const filterOptions = inputValue => {
        let newOptions = [...options];
        const filterValues = newOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
        return filterValues.slice(0, maxSize);
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(filterOptions(inputValue));
        }, 300);
    };

    const handleOptionChange = option => {
        setSelectedOption(option);
        onChange(option?.value + rawPhoneNumber);
        setIsOpen(false);
    };

    const handleTextChange = e => {
        const numberRegex = /^[0-9\b]+$/;
        if (e?.target?.value === '' || numberRegex.test(e?.target?.value)) {
            setRawPhoneNumber(e?.target?.value);
            onChange(selectedOption?.value + e?.target?.value);
        }
    };

    return (
        <Grid container wrap="nowrap" className={classes.root} alignItems="center">
            <Grid item container wrap="nowrap" alignItems="center" className={classes.left} onClick={toggleOpen}>
                <Grid item className={classes.flag}>
                    <ReactCountryFlag countryCode={selectedOption?.code} svg style={{ width: 24 }} />
                </Grid>
                <Grid item>
                    <Typography variant="body2">{selectedOption?.value ? `(${selectedOption?.value})` : ''}</Typography>
                </Grid>
                <Grid item style={{ display: 'flex' }} className={classes.dropdown}>
                    <DropdownSVG size={16} />
                </Grid>
            </Grid>
            <Grid item className={classes.right}>
                <input type="text" onChange={handleTextChange} value={rawPhoneNumber} className={classes.input}></input>
            </Grid>
            {isOpen && (
                <Paper className={classes.menuDropdown}>
                    <AsyncSelect
                        onBlur={() => setIsOpen(false)}
                        cacheOptions
                        autoFocus
                        defaultOptions={options.slice(0, maxSize)}
                        loadOptions={loadOptions}
                        backspaceRemovesValue={false}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        placeholder="Find a country"
                        isClearable={false}
                        tabSelectsValue={false}
                        components={components}
                        // defaultValue={defaultValue}
                        // styles={selectStyles}
                        onChange={handleOptionChange}
                        classes={classes}
                        menuIsOpen
                        // isMulti={isMulti}
                    />
                </Paper>
            )}
        </Grid>
    );
}

export default React.memo(InputPhone);
