import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Dialog } from '@material-ui/core';
import PaymentContentLayout from '../common/PaymentContentLayout';
import * as paymentActions from 'payment/actions';
import { useDispatch } from 'react-redux';
import {
    useCardInfo,
    useCompanySubscription,
    useCurrentSubscriptionIs,
    useIsProTrial,
    usePaymentMethod
} from 'hooks/payment';
import * as workspaceActions from 'workspaces/actions';
import { PAYMENT_METHODS, PAYMENT_WARNING_TYPES, PLAN_CYCLE, PLAN_TYPES, SUBSCRIPTION_STATUS } from 'const';
import WarningBox from 'payment/components/warning';
import { getFriendlyDateFromUnix } from 'utils/datetime';
import CancelAddon from './CancelAddon';
import CurrentPlanDetail from './CurrentPlanDetail';
import AddonSubscription from './AddonSubscription';
import CancelAddonEnterprise from './CancelAddonEnterprise';
import { useNonPaygAddons, usePaygAddons } from 'hooks/addons';
import Divider from 'components/divider/Base';
import IOSSwitch from 'components/switches/IOS';
import { beautifyPrice } from 'utils/payment';
import { INPUT_RADIUS } from 'const/style';
import ConfirmBox from 'components/confirmBox/Base';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { isOwner } from 'utils/permission/role';
import Tooltip from 'components/tooltip/Base';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';

const useStyles = makeStyles(theme => ({
    totalWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        backgroundColor: theme.colors.ghostwhite,
        padding: theme.spacing(3, 4),
        borderRadius: INPUT_RADIUS
    },
    link: {
        color: theme.palette.primary.main
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mt2: {
        marginTop: theme.spacing(2)
    },
    midGrey: {
        color: theme.colors.midGrey
    },
    sectionWrapper: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(3, 4),
        borderTopLeftRadius: INPUT_RADIUS,
        borderTopRightRadius: INPUT_RADIUS,
        border: `1px solid ${theme.colors.divider}`,
        borderBottomStyle: 'dashed'
    },
    emptyAddon: {
        borderRadius: 4,
        marginTop: theme.spacing(3),
        border: `1px solid ${theme.colors.divider}`,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    divider: {
        margin: theme.spacing(3, 0)
    },
    priceText: {
        color: theme.colors.dodgerBlue
    },
    subtotal: {
        backgroundColor: theme.colors.ghostwhite,
        padding: theme.spacing(3, 4),
        borderBottomLeftRadius: INPUT_RADIUS,
        borderBottomRightRadius: INPUT_RADIUS,
        border: `1px solid ${theme.colors.divider}`,
        borderTop: 'none'
    },
    multiStepExplain: {
        maxWidth: 250,
        padding: theme.spacing(2)
    }
}));

function Overview() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const userInfo = useCurrentUserFullInfo();
    const isCurrentOwner = isOwner({ currentUser: userInfo });
    const cardInfo = useCardInfo();
    const companySubscription = useCompanySubscription();
    const isEnterprisePlan = useCurrentSubscriptionIs(PLAN_TYPES.ENTERPRISE);
    const isProTrial = useIsProTrial();
    const paymentMethod = usePaymentMethod();
    const nonPaygAddons = useNonPaygAddons();
    const subscribedNonPaygAddons = nonPaygAddons?.filter(addon => addon?.subscription);
    const paygAddons = usePaygAddons();
    const [isOpenAddonCancel, setIsOpenAddonCancel] = React.useState(false);
    const [isOpenAddonCancelEnterprise, setIsOpenAddonCancelEnterprise] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [openInstallPaygAddonModal, setOpenInstallPaygAddonModal] = React.useState(false);
    const [openUninstallPaygAddonModal, setOpenUninstallPaygAddonModal] = React.useState(false);
    const [isOpenNoCard, setIsOpenNoCard] = React.useState(false);
    const [isOpenContact, setIsOpenContact] = React.useState(false);
    const [selectedAddon, setSelectedAddon] = React.useState(null);

    const handleOpenInstallModal = () => {
        if (!Boolean(cardInfo) && !isProTrial) {
            handleOpenNoCard();
            return;
        }

        if (isEnterprisePlan || paymentMethod === PAYMENT_METHODS.INVOICE) {
            handleOpenContact();
            return;
        }
        setOpenInstallPaygAddonModal(true);
    };

    const handleCloseInstallModal = () => {
        setOpenInstallPaygAddonModal(false);
    };

    const handleOpenUninstallPaygAddonModal = () => {
        if (isEnterprisePlan || paymentMethod === PAYMENT_METHODS.INVOICE) {
            handleOpenContact();
            return;
        }
        setOpenUninstallPaygAddonModal(true);
    };

    const handleCloseUninstallPaygAddonModal = () => {
        setOpenUninstallPaygAddonModal(false);
    };

    const handleCloseNoCard = () => {
        setIsOpenNoCard(false);
    };

    const handleOpenNoCard = () => {
        setIsOpenNoCard(true);
    };

    const handleCloseContact = () => {
        setIsOpenContact(false);
    };

    const handleOpenContact = () => {
        setIsOpenContact(true);
    };

    const handleNoCard = () => {
        handleCloseNoCard();
        history.push(`/company-settings/billing/detail`);
    };

    const openAddonCancel = () => setIsOpenAddonCancel(true);
    const closeAddonCancel = () => setIsOpenAddonCancel(false);

    const openAddonCancelEnterprise = () => setIsOpenAddonCancelEnterprise(true);
    const closeAddonCancelEnterprise = () => setIsOpenAddonCancelEnterprise(false);

    useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspaces({
                successCallback: () => {
                    console.log('fetchWorkspaces success');
                },
                errorCallback: () => {
                    console.log('fetchWorkspaces failed');
                }
            })
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            paymentActions.fetchPlans({
                errorCallback: () => {
                    console.log('fetchPlans failed');
                },
                successCallback: () => {
                    console.log('fetchPlans successfully');
                }
            })
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            paymentActions.fetchTaxRate({
                errorCallback: () => {
                    console.log('fetchTaxRate failed');
                },
                successCallback: () => {
                    console.log('fetchTaxRate successfully');
                }
            })
        );
    }, [dispatch]);

    const handleBuy = addon => {
        setIsSubmitting(true);
        dispatch(
            paymentActions.buyAddon({
                body: { addonType: addon?.plan?.addonType },
                errorCallback: () => {
                    console.log('error');
                    setIsSubmitting(false);
                },
                successCallback: () => {
                    console.log('success');
                    setIsSubmitting(false);
                    handleCloseInstallModal();
                }
            })
        );
    };

    const handleCancelAddon = addon => {
        setIsSubmitting(true);
        dispatch(
            paymentActions.cancelAddonSubscription({
                id: addon?.subscription?.id,
                successCallback: () => {
                    setIsSubmitting(false);
                    handleCloseUninstallPaygAddonModal();
                },
                errorCallback: () => {
                    console.log('canceled failed');
                    setIsSubmitting(false);
                }
            })
        );
    };

    return (
        <PaymentContentLayout>
            <Grid container direction="column" className={classes.root}>
                <Grid item container direction="column" wrap="nowrap">
                    <Grid item>
                        <Typography variant="h4">
                            {companySubscription?.cycle === PLAN_CYCLE.year
                                ? t('annual_subscription_statement')
                                : t('monthly_total')}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" className={classes.totalWrapper}>
                        <Grid item container>
                            <Grid item xs={8}>
                                <Typography variant="body2">Amount total</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" display="inline" className={classes.priceText}>
                                    {beautifyPrice({
                                        unitAmount:
                                            companySubscription?.cycle === PLAN_CYCLE.year
                                                ? companySubscription?.seatSubtotalAmount
                                                : companySubscription?.subtotalAmount,
                                        currency: companySubscription?.seatCurrency
                                    })}
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    /{companySubscription?.cycle}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.mt3}>
                            <Grid item xs={8}>
                                <Typography variant="body2">Tax ({companySubscription?.taxPercent * 100}%)</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1">
                                    {beautifyPrice({
                                        unitAmount:
                                            companySubscription?.cycle === PLAN_CYCLE.year
                                                ? companySubscription?.seatTaxAmount
                                                : companySubscription?.taxAmount,
                                        currency: companySubscription?.seatCurrency
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.divider}>
                            <Divider />
                        </Grid>
                        <Grid item container>
                            <Grid item xs={8}>
                                <Typography variant="body1">{t('total_included_tax')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h3" display="inline" className={classes.priceText}>
                                    {beautifyPrice({
                                        unitAmount:
                                            companySubscription?.cycle === PLAN_CYCLE.year
                                                ? companySubscription?.seatTotalAmount
                                                : companySubscription?.totalAmount,
                                        currency: companySubscription?.seatCurrency
                                    })}
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    /{companySubscription?.cycle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {companySubscription?.cycle === PLAN_CYCLE.year && (
                    <Grid item container direction="column" wrap="nowrap">
                        <Grid item>
                            <Typography variant="h4">{t('monthly_total')}</Typography>
                        </Grid>
                        <Grid item container direction="column" className={classes.totalWrapper}>
                            <Grid item container>
                                <Grid item xs={8}>
                                    <Typography variant="body2">Amount total</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1" display="inline" className={classes.priceText}>
                                        {beautifyPrice({
                                            unitAmount: companySubscription?.monthlySubtotalAmount,
                                            currency: companySubscription?.seatCurrency
                                        })}
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        /month
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container className={classes.mt3}>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        Tax ({companySubscription?.taxPercent * 100}%)
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body1">
                                        {beautifyPrice({
                                            unitAmount: companySubscription?.monthlyTaxAmount,
                                            currency: companySubscription?.seatCurrency
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.divider}>
                                <Divider />
                            </Grid>
                            <Grid item container>
                                <Grid item xs={8}>
                                    <Typography variant="body1">{t('total_included_tax')}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h3" display="inline" className={classes.priceText}>
                                        {beautifyPrice({
                                            unitAmount: companySubscription?.monthlyTotalAmount,
                                            currency: companySubscription?.seatCurrency
                                        })}
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        /month
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item container direction="column" wrap="nowrap">
                    <Grid item>
                        <Typography variant="h4">Subscription detail</Typography>
                    </Grid>
                    <Grid item className={classes.mt3}>
                        <CurrentPlanDetail companySubscription={companySubscription} />
                    </Grid>
                </Grid>
                <Grid item container direction="column" wrap="nowrap" className={classes.mt4}>
                    <Grid item>
                        <Typography variant="h4">{t('addon_title')}</Typography>
                    </Grid>
                    {subscribedNonPaygAddons.length === 0 && (
                        <Grid className={classes.emptyAddon}>
                            <Typography variant="body2" className={classes.midGrey}>
                                You have not installed any add-on
                            </Typography>
                        </Grid>
                    )}
                    {subscribedNonPaygAddons.length > 0 && (
                        <Grid item container direction="column" wrap="nowrap" className={classes.sectionWrapper}>
                            {subscribedNonPaygAddons.map((addon, index) => {
                                const addonSubscription = addon?.subscription;
                                return (
                                    <Grid item container direction="column" key={addon.name}>
                                        <Grid item>
                                            <AddonSubscription
                                                addon={addon}
                                                Warning={
                                                    Boolean(addonSubscription?.endDate) && (
                                                        <WarningBox
                                                            warningType={PAYMENT_WARNING_TYPES.ERROR}
                                                            message={
                                                                <>
                                                                    <Typography variant="body2" display="inline">
                                                                        You've canceled this Add-on. Your subscription
                                                                        will end on{' '}
                                                                    </Typography>
                                                                    <Typography variant="body1" display="inline">
                                                                        {getFriendlyDateFromUnix(
                                                                            addonSubscription?.endDate
                                                                        )}
                                                                    </Typography>
                                                                    <Typography variant="body2" display="inline">
                                                                        .
                                                                    </Typography>
                                                                </>
                                                            }
                                                        />
                                                    )
                                                }
                                                onCancel={() => {
                                                    setSelectedAddon(addon);
                                                    companySubscription.plan === PLAN_TYPES.ENTERPRISE
                                                        ? openAddonCancelEnterprise()
                                                        : openAddonCancel();
                                                }}
                                            />
                                        </Grid>
                                        {index !== subscribedNonPaygAddons.length - 1 && (
                                            <Grid item>
                                                <Divider className={classes.divider} />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
                <Grid item container className={classes.subtotal}>
                    <Grid item xs={8}>
                        <Typography variant="body1">Monthly add-on total</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" display="inline" className={classes.priceText}>
                            {beautifyPrice({
                                unitAmount: companySubscription?.addonSubtotal,
                                currency: companySubscription?.seatCurrency
                            })}
                        </Typography>
                        <Typography variant="body2" display="inline">
                            /month
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="column" style={{ marginTop: 30 }}>
                    <Grid item>
                        <Typography variant="h4">Pay as you go</Typography>
                    </Grid>
                    <Grid item className={classes.mt2}>
                        <Typography variant="body2" className={classes.midGrey}>
                            Turning on these features does not trigger any extra payment. You will be charged only when
                            you use these features.
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" className={classes.sectionWrapper}>
                        {paygAddons.map((addon, index) => {
                            const addonSubscription = addon?.subscription;
                            const isActive =
                                addonSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE ||
                                addonSubscription?.status === SUBSCRIPTION_STATUS.TRIALING;
                            const price = addon?.subscription?.price || addon?.plan?.prices?.[0];
                            return (
                                <Grid item key={addon.name} direction="column" container>
                                    <Grid item container wrap="nowrap" alignItems="center">
                                        <Grid item style={{ display: 'flex' }} xs={1}>
                                            <Typography variant="body1">{addon?.icon?.(32)}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">{addon.title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="body2"
                                                        display="inline"
                                                        className={classes.midGrey}
                                                    >
                                                        {addon.subtitle}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={2}>
                                            <Typography
                                                variant="body1"
                                                display="inline"
                                                style={{ color: isActive ? '' : '#C9C9CE' }}
                                            >
                                                {beautifyPrice({
                                                    unitAmount: price?.unitAmount,
                                                    currency: price?.currency
                                                })}
                                            </Typography>
                                            <Typography variant="body2" display="inline" className={classes.midGrey}>
                                                /block
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} style={{ display: 'flex' }}>
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <div style={{ width: 300 }}>
                                                        <Typography variant="overline">
                                                            Free first {price?.unitFree}{' '}
                                                            {price?.addonUnit?.toLowerCase()}s each month. Then it will
                                                            be charged by block of{' '}
                                                            {price?.unitPerBlock?.toLocaleString()}{' '}
                                                            {price?.addonUnit?.toLowerCase()}s for{' '}
                                                            {beautifyPrice({
                                                                unitAmount: price?.unitAmount,
                                                                currency: price?.currency
                                                            })}
                                                        </Typography>
                                                    </div>
                                                }
                                            >
                                                <span style={{ display: 'flex' }}>
                                                    <InfoIconSVG />
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IOSSwitch
                                                checked={isActive}
                                                onChange={() => {
                                                    setSelectedAddon(addon);
                                                    isActive
                                                        ? handleOpenUninstallPaygAddonModal()
                                                        : handleOpenInstallModal();
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {index !== paygAddons.length - 1 && (
                                        <Grid item>
                                            <Divider className={classes.divider} />
                                        </Grid>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item container className={classes.subtotal}>
                        <Grid item xs={8}>
                            <Typography variant="body1">Month-to-date total</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1" display="inline" className={classes.priceText}>
                                {beautifyPrice({
                                    unitAmount: companySubscription?.payAsYouGoSubtotal,
                                    currency: companySubscription?.seatCurrency
                                })}
                            </Typography>
                            <Typography variant="body2" display="inline">
                                /month
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {selectedAddon && isOpenAddonCancel && (
                <Dialog open={isOpenAddonCancel} onClose={closeAddonCancel}>
                    <CancelAddon
                        addonName={selectedAddon.title}
                        cancelAt={getFriendlyDateFromUnix(selectedAddon?.subscription?.currentPeriodEnd)}
                        onClose={closeAddonCancel}
                        onConfirm={() => handleCancelAddon(selectedAddon)}
                        isSubmitting={isSubmitting}
                    />
                </Dialog>
            )}
            {selectedAddon && isOpenAddonCancelEnterprise && (
                <Dialog open={isOpenAddonCancelEnterprise} onClose={closeAddonCancelEnterprise}>
                    <CancelAddonEnterprise onClose={closeAddonCancelEnterprise} addonName={selectedAddon.title} />
                </Dialog>
            )}
            {selectedAddon && openInstallPaygAddonModal && (
                <Dialog open={openInstallPaygAddonModal} onClose={handleCloseInstallModal}>
                    <ConfirmBox
                        title={`Install ${selectedAddon.title} Add-on`}
                        body={
                            <Typography component="div" variant="body2">
                                Are you sure you want to install {selectedAddon.title} Add-on?
                            </Typography>
                        }
                        handleCancel={handleCloseInstallModal}
                        onClose={handleCloseInstallModal}
                        handleAgreed={() => handleBuy(selectedAddon)}
                        agreeLabel="Confirm"
                        isLoading={isSubmitting}
                    />
                </Dialog>
            )}
            {selectedAddon && openUninstallPaygAddonModal && (
                <Dialog open={openUninstallPaygAddonModal} onClose={handleCloseUninstallPaygAddonModal}>
                    <ConfirmBox
                        title={`Uninstall ${selectedAddon.title} Add-on`}
                        body={
                            <>
                                <Typography variant="body2">
                                    Are you sure you want to uninstall {selectedAddon.title} Add-on?
                                </Typography>
                                <Typography variant="body2">
                                    Current block counts will be charged and then reset
                                </Typography>
                            </>
                        }
                        handleCancel={handleCloseUninstallPaygAddonModal}
                        onClose={handleCloseUninstallPaygAddonModal}
                        handleAgreed={() => handleCancelAddon(selectedAddon)}
                        agreeLabel="Uninstall"
                        isLoading={isSubmitting}
                    />
                </Dialog>
            )}
            <Dialog open={isOpenNoCard} onClose={handleCloseNoCard}>
                <ConfirmBox
                    title={'Payment info needed'}
                    body={
                        <Typography variant="body2">
                            Please fill in your payment detail before installing this add-on.
                        </Typography>
                    }
                    handleCancel={handleCloseNoCard}
                    onClose={handleCloseNoCard}
                    handleAgreed={handleNoCard}
                    agreeLabel="Go to Billing Details"
                    agreeWidth={180}
                />
            </Dialog>
            <Dialog open={isOpenContact} onClose={handleCloseContact}>
                <ConfirmBox
                    title={'Add-on is managed by account owner'}
                    body={
                        <Typography variant="body2">
                            {isCurrentOwner
                                ? 'Please contact Gridly Support team to install this add-on for your account.'
                                : 'Please contact your account owner.'}
                        </Typography>
                    }
                    handleCancel={handleCloseContact}
                    onClose={handleCloseContact}
                    action={
                        <Button onClick={handleCloseContact} width={100} variant="contained">
                            {t('global_ok')}
                        </Button>
                    }
                />
            </Dialog>
        </PaymentContentLayout>
    );
}

export default React.memo(Overview);
