import React from 'react';

const NextButtonSVG = ({ color = '#2A293A', ...rest }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect width="20" height="20" rx="3" transform="matrix(-1 0 0 1 20 0)" fill="#C9C9CE" />
            <path
                d="M12.996 11.85C13.584 11.1593 14.2 10.6553 14.844 10.338V10.086C14.2 9.75933 13.584 9.25533 12.996 8.574H12.506C12.786 9.162 13.0567 9.61 13.318 9.918H5.156V10.506H13.318C13.0567 10.814 12.786 11.262 12.506 11.85H12.996Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(NextButtonSVG);
