import React from 'react';

function MaintenanceSVG({ color = '#7869B9', ...other }) {
    return (
        <svg width="220" height="199" viewBox="0 0 220 199" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M109.806 137.797C58.5709 136.544 3.02031 149.492 0.096592 169.262C-2.82712 189.032 61.2162 199.613 111.476 198.499C161.876 197.385 215.755 186.525 216.869 171.072C218.122 155.478 181.506 139.607 109.806 137.797Z"
                fill="#F4F5F7"
            />
            <path
                d="M109.392 148.042C75.9792 147.206 39.503 155.699 37.5538 168.647C35.6047 181.594 77.5106 188.555 110.506 187.859C143.502 187.163 178.725 180.063 179.56 169.9C180.396 159.736 156.31 149.295 109.392 148.042Z"
                fill="#E9EAEF"
            />
            <path
                d="M173.144 169.064C172.031 171.709 169.803 173.24 167.297 174.493C166.462 174.911 167.158 176.164 167.993 175.746C170.638 174.354 173.144 172.544 174.397 169.76C174.676 169.064 173.423 168.228 173.144 169.064Z"
                fill="#C9C9CE"
            />
            <path
                d="M171.383 153.57C171.104 153.57 170.965 153.57 170.687 153.57C164.004 153.292 158.853 149.533 156.069 142.85C155.372 141.04 156.208 138.952 158.018 138.117C159.827 137.421 162.055 138.256 162.751 140.066C164.422 144.103 167.206 146.192 170.965 146.331C176.952 146.609 185.862 141.458 191.013 131.156C195.19 122.802 195.746 113.753 192.405 106.792C190.734 103.451 188.507 101.084 186.14 100.666C179.597 99.2741 172.775 105.678 172.636 105.678C171.244 107.07 168.877 107.07 167.485 105.678C166.092 104.286 166.092 102.059 167.485 100.666C167.902 100.249 177.091 91.4777 187.672 93.7053C192.266 94.6798 196.303 98.4388 198.949 103.729C203.264 112.639 202.707 124.055 197.556 134.358C191.709 145.913 181.128 153.57 171.383 153.57Z"
                fill="#68B3F8"
            />
            <path
                d="M45.2133 94.6866C45.2133 94.6866 40.2014 175.992 109.255 175.992C178.309 175.992 172.461 88.8394 172.461 88.8394C172.461 88.8394 125.544 119.05 45.2133 94.6866Z"
                fill="#68B3F8"
            />
            <path
                d="M108.3 150.965C102.592 149.851 96.4658 156.951 93.1245 160.432C89.9224 163.773 87.1379 167.532 84.3535 171.43C89.2263 173.379 94.6559 174.632 100.642 175.328C108.439 165.861 117.627 152.635 108.3 150.965Z"
                fill="#FFBB37"
            />
            <path
                d="M126.248 139.551C117.895 149.575 112.883 167.256 126.248 173.799C127.78 173.381 129.172 172.964 130.703 172.546C130.564 172.546 130.425 172.407 130.286 172.407C114.136 167.952 121.654 149.575 129.172 140.664C139.196 128.691 154.649 125.628 164.256 139.133C164.673 138.158 165.091 137.045 165.509 136.07C154.232 121.87 137.247 126.325 126.248 139.551Z"
                fill="#95E3FC"
            />
            <path
                d="M135.402 170.456C136.516 170.039 137.491 169.482 138.465 168.925C115.911 156.256 148.907 127.298 161.437 145.118C161.994 144.144 162.411 143.169 162.829 142.195C147.793 123.539 112.57 155.699 135.402 170.456Z"
                fill="#695FB3"
            />
            <path
                d="M138.169 152.161C136.499 157.312 139.701 162.324 144.017 165.109C144.852 164.552 145.548 163.856 146.383 163.299C143.042 161.489 140.397 158.147 140.675 154.249C140.815 151.604 143.181 150.351 145.687 149.933C149.307 149.237 153.205 150.769 155.572 153.553C156.129 152.718 156.686 152.022 157.243 151.186C152.092 146.314 140.815 144.225 138.169 152.161Z"
                fill="#B07ADD"
            />
            <path
                d="M47.8691 121.292C48.1476 122.405 48.2868 123.519 48.5652 124.633C53.0203 126.303 56.7793 129.227 59.9814 133.961C63.8796 139.808 62.9051 145.934 59.5637 150.946C60.1206 151.781 60.6775 152.616 61.3736 153.452C65.1326 148.161 66.3856 141.618 63.6012 134.657C60.6775 127.278 54.691 123.38 47.8691 121.292Z"
                fill="#95E3FC"
            />
            <path
                d="M86.6641 135.793C84.8542 135.793 84.8542 138.717 86.6641 138.717C88.6133 138.717 88.6133 135.793 86.6641 135.793Z"
                fill="#983973"
            />
            <path
                d="M97.291 139.829C97.291 139.969 97.291 140.108 97.291 140.247C97.291 142.057 100.215 142.057 100.215 140.247C100.215 140.108 100.215 139.969 100.215 139.829C100.215 137.88 97.291 137.88 97.291 139.829Z"
                fill="#983973"
            />
            <path
                d="M88.2301 145.118C88.0909 145.118 87.9517 145.118 87.8125 145.118C86.0026 145.118 86.0026 148.041 87.8125 148.041C87.9517 148.041 88.0909 148.041 88.2301 148.041C90.04 148.041 90.04 145.118 88.2301 145.118Z"
                fill="#983973"
            />
            <path
                d="M87.5234 141.224C85.7136 141.224 85.7136 144.148 87.5234 144.148C89.3332 144.148 89.3332 141.224 87.5234 141.224Z"
                fill="#983973"
            />
            <path
                d="M80.0916 143.029C80.0916 142.89 80.0916 142.75 80.0916 142.611C80.0916 140.801 77.168 140.801 77.168 142.611C77.168 142.75 77.168 142.89 77.168 143.029C77.3072 144.839 80.0916 144.839 80.0916 143.029Z"
                fill="#983973"
            />
            <path
                d="M74.4238 150.13C72.6139 150.13 72.6139 153.053 74.4238 153.053C76.2337 153.053 76.2337 150.13 74.4238 150.13Z"
                fill="#983973"
            />
            <path
                d="M82.3591 151.661C82.2198 151.661 82.0806 151.661 81.9414 151.661C80.1315 151.661 80.1315 154.584 81.9414 154.584C82.0806 154.584 82.2198 154.584 82.3591 154.584C84.3082 154.445 84.3082 151.661 82.3591 151.661Z"
                fill="#983973"
            />
            <path
                d="M82.0827 159.738C81.3866 158.764 79.4375 159.042 79.4375 160.573C79.4375 161.687 80.1336 162.383 81.2474 162.383C82.7788 162.383 83.0572 160.434 82.0827 159.738Z"
                fill="#983973"
            />
            <path
                d="M95.4531 147.347C93.6432 147.347 93.6432 150.27 95.4531 150.27C97.263 150.27 97.263 147.347 95.4531 147.347Z"
                fill="#983973"
            />
            <path
                d="M134.287 109.268C134.287 109.268 138.325 120.545 153.639 115.533C167.7 110.938 160.321 98.826 159.765 97.8514C159.347 96.8769 153.082 101.193 134.287 109.268Z"
                fill="#FFBB37"
            />
            <path
                d="M109.111 64.368C79.1787 64.368 46.7402 75.7842 45.348 91.6554C43.9558 107.387 81.6847 114.627 111.2 112.678C140.714 110.729 172.178 100.983 172.596 88.7318C173.014 76.3411 151.295 64.368 109.111 64.368Z"
                fill="#97E0FF"
            />
            <path
                d="M117.582 112.122C118.835 115.324 117.582 117.551 112.848 118.386C108.95 119.083 105.052 118.943 101.154 119.222C97.1162 119.5 93.4964 120.475 89.5982 122.006C80.4096 125.487 77.486 117.412 74.98 110.729C73.8662 110.59 72.7524 110.312 71.6387 110.033C74.1447 116.298 75.6761 122.981 82.6372 125.626C85.2824 126.6 88.3452 125.348 90.8512 124.512C95.4455 122.981 99.0653 122.145 103.799 121.867C112.291 121.449 123.429 120.336 120.505 111.704C119.67 111.843 118.695 111.982 117.582 112.122Z"
                fill="#B07ADD"
            />
            <path
                d="M109.521 69.6581C83.7653 69.6581 55.921 78.7074 54.668 90.9589C53.415 103.35 85.8536 108.918 111.192 107.387C136.53 105.856 163.539 98.1984 163.957 88.5921C164.374 78.9859 145.719 69.6581 109.521 69.6581Z"
                fill="#663E11"
            />
            <path
                d="M102.127 85.111C102.544 85.111 102.823 85.111 103.101 84.9718C104.633 84.4149 106.442 82.7443 108.113 80.7952C110.201 78.5676 107.695 77.3146 107.695 77.3146C107.138 76.7578 106.164 76.7578 105.607 77.3146C104.076 79.2637 102.266 80.9344 101.013 83.0227C100.317 84.1365 101.152 84.9718 102.127 85.111Z"
                fill="white"
            />
            <path
                d="M124.408 85.2482C123.851 84.4129 122.598 84.1345 122.041 85.2482C119.396 90.8171 113.27 93.6015 107.562 95.4114C105.335 96.1075 101.019 96.3859 97.26 95.829C95.3109 95.5506 94.3363 92.7662 92.5264 92.7662C90.7166 92.7662 90.4381 89.7033 93.2225 87.0581C96.007 84.5521 93.3618 83.7168 93.0833 83.2991C92.6657 82.603 91.6911 82.1854 90.995 83.0207C88.3498 86.0836 85.5654 88.5895 87.0968 92.9054C88.3498 96.6644 93.7794 98.1958 97.26 98.8919C99.9052 99.4488 103.107 99.3096 106.17 98.7527C113.131 97.6389 118.004 96.2467 118.978 94.7153C119.953 93.1838 120.231 93.7407 123.712 90.9563C127.193 88.1719 124.408 85.2482 124.408 85.2482Z"
                fill="white"
            />
            <path
                d="M111.412 85.1093H111.552H111.691C111.83 85.1093 111.83 85.1093 111.969 85.1093C111.969 85.1093 111.969 85.1093 112.108 85.1093C113.779 85.3878 114.336 83.8563 116.007 82.0465C116.564 81.4896 117.12 81.0719 117.677 80.6543C118.652 79.8189 117.956 78.7052 117.12 78.4267C117.12 78.4267 115.311 77.4522 113.779 78.9836C112.248 80.3758 112.108 81.4896 110.995 82.0465C109.742 82.3249 109.742 84.4132 111.412 85.1093Z"
                fill="white"
            />
            <path
                d="M163.941 88.5925C164.359 78.9862 145.703 69.5192 109.506 69.5192C107.974 69.5192 106.582 69.5192 105.051 69.6584C174.94 72.582 151.551 99.3125 151.551 99.3125C158.929 96.3889 163.802 92.7691 163.941 88.5925Z"
                fill="#A76C35"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M205.593 165.723C206.011 166.001 206.011 166.419 206.011 166.837C206.011 167.811 207.403 167.811 207.403 166.837C207.403 165.862 207.264 165.027 206.289 164.47C205.593 164.052 204.897 165.305 205.593 165.723ZM197.386 177.556C200.867 175.746 204.069 173.24 206.157 169.898C206.575 169.063 207.828 169.759 207.41 170.595C205.183 174.214 201.841 176.86 198.082 178.809C197.247 179.226 196.551 177.973 197.386 177.556ZM30.8415 182.808L30.8416 182.808C32.1325 183.136 33.4148 183.461 34.6652 183.819C35.5005 184.097 35.0828 185.489 34.2475 185.211C33.0735 184.875 31.8657 184.562 30.6446 184.246L30.6446 184.246L30.6445 184.246C25.7839 182.986 20.7112 181.672 16.7056 178.667C15.8703 178.111 16.7056 176.858 17.4017 177.414C21.2324 180.37 26.0956 181.604 30.8415 182.808ZM9.70988 174.351C6.5078 172.263 7.34313 168.086 9.98832 165.72C10.6844 165.163 11.659 166.137 10.9629 166.694C9.01378 168.365 8.03923 171.428 10.406 173.098C11.2413 173.655 10.5452 174.908 9.70988 174.351Z"
                fill="#D7D7DC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M163.566 44.6855C164 45.7128 165.059 46.8183 166.965 47.8984C172.206 51.0418 180.869 48.0619 184.144 43.2676C189.634 35.1191 184.631 21.225 175.752 17.9832C167.756 15.0199 157.851 17.302 149.978 19.8004C145.595 21.181 141.258 22.9416 137.289 25.1974C136.354 25.7291 135.274 26.5024 134.122 27.327C131.45 29.2401 128.391 31.4297 125.869 31.5203C125.024 31.5458 124.086 30.8111 124.222 30.0233C124.247 29.6984 124.49 29.4008 124.725 29.113C124.771 29.0559 124.818 28.9992 124.862 28.9428C130.26 22.5795 135.733 16.8243 143.126 12.9186C156.686 5.79607 171.555 4.37688 186.655 4.85806C192.771 5.04131 199.094 5.66219 204.651 8.22739C210.207 10.7926 215.052 15.7582 216.095 21.8025C217.453 29.814 212.135 37.4787 206.179 43.0612C190.322 57.9447 165.705 65.4838 146.384 55.6417C140.051 52.3994 134.054 45.5682 136.404 38.8806C139.925 29.1199 160.536 26.0781 168.71 29.8683C170.622 32.9923 168.379 35.6192 166.224 38.1425C164.367 40.3166 162.577 42.4137 163.566 44.6855ZM118.909 32.6725C117.379 32.781 115.859 32.9656 114.574 33.8158C113.279 34.59 112.4 36.2395 112.879 37.6469C115.565 38.1693 118.428 36.9733 120.013 34.7754C120.404 34.188 120.616 33.3909 120.18 32.981C119.83 32.6378 119.365 32.6171 118.909 32.6725ZM101.57 40.4853C103.749 39.3724 106.41 39.0494 109.003 38.8117C109.12 38.8092 109.241 38.8046 109.362 38.7999C110.041 38.7736 110.763 38.7457 111.196 39.0855C111.964 39.6866 111.475 40.7486 110.789 41.449C108.071 44.4786 103.031 46.1702 98.4842 45.5652C97.7974 43.7201 99.3911 41.5983 101.57 40.4853Z"
                fill="#F3ECD9"
            />
            <path
                d="M219.677 77.3015C218.97 77.1521 214.463 76.1062 213.123 73.9396C212.117 72.296 210.963 68.822 210.702 68.0749V68.0002V68.0376C210.702 68.0376 210.702 68.0376 210.702 68.0002V68.0376C210.441 68.7847 209.287 72.2586 208.281 73.9022C206.94 76.0688 202.434 77.1147 201.727 77.2641H201.764C202.471 77.4136 206.978 78.4595 208.318 80.626C209.324 82.2696 210.478 85.7436 210.739 86.4907V86.5654V86.528C210.739 86.528 210.739 86.528 210.739 86.5654V86.528C210.963 85.781 212.154 82.307 213.16 80.6634C214.463 78.5342 218.97 77.4509 219.677 77.3015Z"
                fill="#AD74DD"
            />
            <path
                d="M19.4282 125.435C18.7951 125.286 14.7729 124.352 13.5812 122.447C12.6874 120.99 11.6446 117.853 11.4211 117.218V117.143V117.18C11.4211 117.18 11.4211 117.18 11.4211 117.143V117.18C11.1977 117.853 10.1549 120.953 9.26108 122.41C8.06933 124.352 4.04718 125.286 3.41406 125.435C4.04718 125.585 8.06933 126.519 9.26108 128.461C10.1549 129.918 11.1977 133.056 11.4211 133.691V133.765V133.728V133.691C11.6446 133.018 12.6874 129.918 13.5812 128.461C14.8102 126.519 18.8323 125.585 19.4282 125.435Z"
                fill="#17C0FF"
            />
        </svg>
    );
}

export default React.memo(MaintenanceSVG);
