import createReducer from 'utils/createReducer';
import { USER_LOGOUT_SUCCESS } from 'auth/types';
import { keyHandler, billingHandler, cardHandler, planHandler, addonHandler } from './reducers';

const INITIAL_STATE = {
    isFetchingSubscription: false,
    companySubscription: null,
    addonSubscriptions: [],
    addonUsage: [],
    seatSubscription: null,

    plans: null,
    isFetchingPlans: false,
    customPriceList: null,
    isFetchingCustomPrices: false,

    customer: null,

    publicKey: null,
    isFetchingPublicKey: false,

    billingHistory: null,
    isFetchingBillingHistory: false,
    receiptInfo: null,
    isFetchingReceiptInfo: false,
    billingInfo: null,
    isFetchingBillingInfo: false,
    isFetchingCardInfo: false,
    cardInfo: null,
    taxRate: 0,
    //detail
    // subscriptionsByWorkspace: {
    //     workspaceId: {
    //         isFetching: false,
    //         list: null
    //     }
    // },
    usage: {
        workspaceId: {
            apiRequestsPerMonth: -1,
            backupRetentionByDays: 7,
            customUserRole: false,
            dataAccessControl: false,
            gridsPerProject: 10,
            localesPerProject: 10,
            recordsInProject: 5000,
            revisionHistoryByMonths: 1,
            sla: false,
            storageGb: 3,
            versionControlEnvironmentPerProject: 5
        }
    },
    addonPlans: []
};

const commonHandler = {
    [USER_LOGOUT_SUCCESS]() {
        return INITIAL_STATE;
    }
};

export default createReducer(
    INITIAL_STATE,
    Object.assign(commonHandler, keyHandler, billingHandler, cardHandler, planHandler, addonHandler)
);
