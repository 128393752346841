import isArray from 'lodash/isArray';
import * as columnTypes from 'const/columnTypes';

export function getAllNodePropertiesPath(tree) {
    let paths = [];
    for (let treeItem of tree) {
        paths.push(treeItem?.propertyPath);
        const itemChildren = treeItem?.children || [];
        if (itemChildren.length > 0) {
            paths = [...paths, ...getAllNodePropertiesPath(itemChildren)];
        }
    }
    return paths;
}

export function getFirstLevelProperties(items = [], propertyPaths) {
    const columns = [];
    const data = {};
    const records = [];
    const verticalPropertyPaths = [];

    if (!items) {
        return {
            columns,
            data,
            records
        };
    }

    for (const item of items) {
        const propertyPath = item?.propertyPath;
        if (
            propertyPaths?.[propertyPath] === 1 &&
            item?.children?.length > 0 &&
            typeof item?.children?.[0] === 'object'
        ) {
            records.push(propertyPath);
            data[propertyPath] = {};
            for (const child of item?.children) {
                data[propertyPath] = {
                    ...data[propertyPath],
                    [child.name]: {
                        value: child?.value
                    },
                    [columnTypes.RECORD_ID]: {
                        value: propertyPath
                    }
                };

                columns.push(child.name);
                verticalPropertyPaths.push(child.propertyPath);
            }
        }
    }

    return {
        columns: [...new Set([...columns])],
        verticalPropertyPaths: [...new Set([...verticalPropertyPaths])],
        data,
        records
    };
}

export function getAllNodePropertiesPathVertical(tree, parentChecked = false) {
    let propertyPaths = {};
    for (let treeItem of tree) {
        const itemChildren = treeItem?.children || [];

        const isChecked =
            parentChecked ||
            (typeof treeItem === 'object' &&
                itemChildren?.length > 0 &&
                typeof itemChildren?.[0] === 'object' &&
                itemChildren?.[0]?.propertyPath?.includes('/'));

        if (isChecked) {
            propertyPaths = {
                ...propertyPaths,
                [treeItem.propertyPath]: 1
            };
        } else {
            propertyPaths = {
                ...propertyPaths,
                [treeItem.propertyPath]: 2
            };
        }
        if (itemChildren.length > 0) {
            propertyPaths = {
                ...propertyPaths,
                ...getAllNodePropertiesPathVertical(itemChildren, isChecked)
            };
        }
    }
    return propertyPaths;
}

export function getFirstNodeIdsWithLevel(tree, maxLevel) {
    const firstNodeInTree = tree?.[0] || [];

    let ids = [];
    const children = firstNodeInTree?.children || [];

    if (children?.length) {
        ids = [firstNodeInTree?.propertyPath];
    }
    for (let treeItem of children) {
        ids.push(treeItem?.propertyPath);
        const itemChildren = treeItem?.children || [];
        if (itemChildren.length > 0 && maxLevel > 1) {
            ids = [...ids, ...getFirstNodeIdsWithLevel(itemChildren, --maxLevel)];
        }
    }
    return ids;
}

export function getTreeItems(tree) {
    return isArray(tree) ? tree : typeof tree !== 'object' ? null : tree?.propertyPath ? [tree] : tree?.children;
}

export function getSiblingByNodeFullPropertyPath({ tree, propertyPath }) {
    if (typeof propertyPath !== 'string' || !propertyPath) {
        return tree;
    }

    let pathArr = propertyPath.split('/');
    let pathLength = pathArr.length;
    let currentLevelToLookupNodes = tree;
    let matchedTag = [];
    let idx = 0;

    while (idx < pathLength) {
        let currentTagValue = pathArr?.[idx];
        let currentLevelNode = null;
        for (let node of currentLevelToLookupNodes) {
            if (currentTagValue === node.propertyPath) {
                currentLevelNode = node;
                break;
            }
        }
        if (currentLevelNode == null) {
            return null;
        }
        matchedTag = [...matchedTag, currentLevelNode];
        // matchedTag = currentLevelNode;
        currentLevelToLookupNodes = currentLevelNode?.children;
        idx++;
    }
    return matchedTag;
}

export function getSiblingByNodePropertyPath({ tree, propertyPath }) {
    let children = [];
    if (typeof propertyPath !== 'string' || !propertyPath) {
        return tree;
    }
    for (const treeItem of tree) {
        const itemChildren = treeItem?.children || [];

        if (treeItem?.propertyPath === propertyPath) {
            children = itemChildren;
            break;
        } else if (itemChildren?.length > 0) {
            getSiblingByNodePropertyPath({ tree: itemChildren, propertyPath });
        }
    }

    return children;
}
