import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    avatar: {
        marginRight: SPACING_LIST_NAME_WITH_ICON,
        display: 'flex'
    },
    expand: {
        flex: 1,
        overflow: 'hidden'
    },
    title: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));

const CardInfo = React.forwardRef(({ avatar, title, subTitle, action, titleClassName, ...rest }, ref) => {
    const classes = useStyles();

    return (
        <Grid
            ref={ref}
            container
            className={classes.root}
            direction="row"
            wrap="nowrap"
            alignItems="center"
            // justify="space-between"
            {...rest}
        >
            {avatar && (
                <Grid item className={classes.avatar}>
                    {avatar}
                </Grid>
            )}

            <Grid item className={classes.expand}>
                <Grid container direction="column" wrap="nowrap">
                    {title && (
                        <Grid item>
                            <Typography
                                variant="body1"
                                component="div"
                                className={`${classes.title} ${titleClassName}`}
                            >
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    {subTitle && (
                        <Grid item>
                            <Typography variant="caption" component="div">
                                {subTitle}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {action && <Grid item>{action}</Grid>}
        </Grid>
    );
});

export default React.memo(CardInfo);
