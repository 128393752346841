import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RemoveSVG from 'assets/images/svg/RemoveSVG';
import ConditionsSelects from 'gridUI/common/Conditions';
import { ALL_OPERATORS_MAP, getConditionsByType, OPERATOR } from 'gridUI/conditions';
import { useDispatch } from 'react-redux';
import {
    useAutomationNodeTreeOptions,
    useAutomationPayloadTreeOptions,
    useGetAutomationDetail
} from 'hooks/gridUI/automation';
import { generateAutomationConditionFilterField, updateActionNode } from 'gridUI/automations/action';
import InputText from 'components/inputs/InputText';
import { INPUT_HEIGHT, INPUT_PADDING_LEFT, INPUT_RADIUS } from 'const/style';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import TreeSuggesstion from '../suggestion/TreeSuggesstion';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import OverflowTypography from 'components/typography/OverflowTypography';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import ExclaimationMarkSVG from 'assets/images/svg/ExclaimationMarkSVG';
import { getColumnByPublicId } from 'hooks/gridUI';
import ExtraFilterMenu from 'gridUI/filters/components/ExtraFilterMenu';
import SubFieldRender from 'gridUI/filters/components/SubFieldRender';
import { DEPENDENCY_STATUS } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    filterGroup: {
        padding: 12,
        background: theme.colors.background,
        borderRadius: 4
    },
    flex: { display: 'flex' },
    flx: { flex: 1 },
    OR: {
        marginLeft: 4,
        width: 32,
        height: 24,
        background: theme.colors.solitude,
        borderRadius: 4,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            color: theme.palette.primary.main
        },
        '&.disabled': {
            background: 'none',
            cursor: 'unset',
            '& p': {
                color: theme.colors.disabledText
            }
        }
    },
    close: {
        position: 'relative',
        top: 2,
        cursor: 'pointer'
    },
    inputBase: {
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        height: 36
    },
    item: {},
    conditionDropdown: {
        background: theme.colors.white
    },
    control: {
        background: theme.colors.white,
        width: '100%',
        borderRadius: INPUT_RADIUS,
        border: props =>
            props.isOpen ? `1px solid ${theme.palette.primary.main} !important` : `1px solid ${theme.colors.border}`,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText
    },
    controlError: {
        borderColor: `${theme.colors.brightRed} !important`
    },
    fieldName: {
        ...theme.ellipsis(1)
    },
    popperClassName: {
        width: `200px !important`
    },
    and: {
        cursor: 'pointer'
    },
    line: {
        height: 20,
        border: `1px solid ${theme.colors.silver}`
    },
    andContent: {
        color: theme.palette.primary.main
    },
    errortext: {
        margin: `12px 0`
    },
    errorLabel: {
        marginLeft: 8,
        color: theme.colors.brightRed
    },
    errorIcon: {
        verticalAlign: 'text-top'
    },
    addContainer: {
        cursor: 'pointer',
        width: 'fit-content'
    },
    extraFilter: {
        width: 36,
        height: 28,
        padding: 4,
        boxSizing: 'border-box',
        borderLeft: `1px solid ${theme.colors.divider}`
    },
    arrowDown: {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center'
    },
    dependencyWrapper: {
        border: `1px solid rgb(233, 234, 239)`,
        borderRadius: 4,
        background: theme.colors.white,
        height: 36,
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    inputDependency: {
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        border: 0,
        background: 'transparent'
    }
}));

const stopPropagation = e => {
    e.stopPropagation();
    e.preventDefault();
};

const INVALID_OPERATORS_FOR_INPUT = [OPERATOR.isEmpty, OPERATOR.isNotEmpty];

const FilterItem = React.memo(
    ({
        actionNodeOrder,
        handleOr,
        handleDelete,
        handleChangeField,
        handleChangeOperator,
        handleChangeValue,
        options,
        objectOptions,
        filterField
    }) => {
        const theme = useTheme();
        const { t } = useTranslation();
        const { field, operator, values } = filterField;
        const [anchorEl, setAnchorEl] = useState(null);
        const [anchorExtraEl, setAnchorExtraEl] = useState(null);
        const classes = useStyles({ isOpen: Boolean(anchorEl) });
        const [value, setValue] = useState(values?.[0] || '');
        const rootRef = useRef();
        const extraContainerRef = useRef();

        useEffect(() => {
            setValue(values?.[0] || '');
        }, [values]);

        const isDependencyStatus = useMemo(() => {
            return field?.includes('dependencyStatus');
        }, [field]);

        const showSubField = useMemo(() => {
            return (
                isDependencyStatus &&
                [DEPENDENCY_STATUS.OUT_OF_DATE, DEPENDENCY_STATUS.UP_TO_DATE, DEPENDENCY_STATUS.UNSET].includes(value)
            );
        }, [isDependencyStatus, value]);

        const showInput = useMemo(() => {
            return !INVALID_OPERATORS_FOR_INPUT.includes(operator);
        }, [operator]);

        const selectedField = useMemo(() => {
            if (!field) return null;
            if (objectOptions?.[field]) {
                return objectOptions?.[field];
            }
            const nodeParam = field.split('.')[0];
            const first = Object.keys(objectOptions).filter(el => el.indexOf(nodeParam) > -1)[0];
            if (!first) {
                return null;
            }
            return {
                ...objectOptions[first],
                serverParam: field,
                missingOutputField: first?.indexOf('node') > -1
            };
        }, [objectOptions, field]);

        const fieldError = useMemo(() => {
            if (selectedField?.nodeOrder > actionNodeOrder) {
                return t('automation_condition_field_error');
            }
            if (selectedField?.missingOutputField) {
                return t('automation_missing_output_field');
            }
            return null;
        }, [selectedField, actionNodeOrder, t]);

        const fieldName = useMemo(() => {
            if (!selectedField) return `Selecte trigger/action`;
            return `${selectedField.nodeOrder}. ${(selectedField.isDisplayname
                ? selectedField.name
                : selectedField.serverParam
                      .split('.')
                      .slice(1)
                      .join('.')
            ).replace('rawData', 'TriggerData')}`;
        }, [selectedField]);

        const getColumn = useCallback(param => {
            if (param?.includes('rawData.')) {
                const arr = param.split('.');
                const columnPublicId = arr[2];
                if (columnPublicId) {
                    const column = getColumnByPublicId(columnPublicId);
                    return column;
                }
            }
            return null;
        }, []);

        const operatorOptions = useMemo(() => {
            let operators = ALL_OPERATORS_MAP;
            const column = getColumn(selectedField?.serverParam);
            if (column) {
                operators = getConditionsByType(column.type);
            }
            return operators.filter(
                o => ![OPERATOR.true, OPERATOR.false, OPERATOR.in, OPERATOR.between].includes(o.value)
            );
        }, [getColumn, selectedField]);

        const selectedCondition = useMemo(() => {
            return ALL_OPERATORS_MAP.find(opt => opt.value === operator);
        }, [operator]);

        const handleClickAway = useCallback(e => {
            setAnchorEl(null);
        }, []);

        const handleClickAwayExtra = useCallback(e => {
            setAnchorExtraEl(null);
        }, []);

        const handleConditionChange = useCallback(
            option => {
                handleChangeOperator(option.value);
            },
            [handleChangeOperator]
        );

        const handleFieldChange = useCallback(
            option => {
                if (option.serverParam !== field) {
                    handleChangeField(option.serverParam);
                    let operators = ALL_OPERATORS_MAP;
                    const column = getColumn(option.serverParam);
                    if (column) {
                        operators = getConditionsByType(column.type);
                    }
                    operators = operators.filter(
                        o => ![OPERATOR.true, OPERATOR.false, OPERATOR.in, OPERATOR.between].includes(o.value)
                    );
                    if (!operators.find(opt => opt.value === operator)) {
                        handleChangeOperator(OPERATOR.equal);
                    }
                }
                handleClickAway();
            },
            [field, getColumn, handleChangeField, handleChangeOperator, handleClickAway, operator]
        );

        const handleInputChange = useCallback(
            e => {
                const value = e.target.value;
                setValue(value);
                handleChangeValue(value);
            },
            [handleChangeValue]
        );

        const handleClickOr = useCallback(
            e => {
                stopPropagation(e);
                handleOr();
            },
            [handleOr]
        );

        const handleClickDelete = useCallback(
            e => {
                stopPropagation(e);
                handleDelete();
            },
            [handleDelete]
        );

        const toggleOpen = useCallback(e => {
            setAnchorEl(anchorEl => (anchorEl ? null : e.target));
        }, []);

        const handleClick = useCallback(e => {
            setAnchorExtraEl(anchorExtraEl => (anchorExtraEl ? null : e.target));
        }, []);

        const handleExtraFilterChange = useCallback(
            ({ value }) => {
                handleInputChange({ target: { value } });
                handleClickAwayExtra();
            },
            [handleClickAwayExtra, handleInputChange]
        );

        return (
            <>
                <Grid ref={rootRef} container className={classes.item} spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item className={classes.flx}>
                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                            <Grid item xs={4} md={5} onClick={toggleOpen}>
                                <Grid
                                    container
                                    className={classnames(classes.control, {
                                        [classes.controlError]: !anchorEl && !!fieldError
                                    })}
                                    justify="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    {selectedField && (
                                        <Grid item style={{ display: 'flex', marginRight: 8 }}>
                                            <WebHookLabel
                                                type={selectedField.trigger || selectedField.app}
                                                size="smaller"
                                            />
                                        </Grid>
                                    )}
                                    <Grid item className={classnames(classes.flx)}>
                                        <OverflowTypography maxLines={1} variant={selectedField ? 'body2' : 'caption'}>
                                            {fieldName}
                                        </OverflowTypography>
                                    </Grid>
                                    <Grid item style={{ display: 'flex' }}>
                                        <ArrowDownSVG />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.flx}>
                                <ConditionsSelects
                                    dropdownClassName={classes.conditionDropdown}
                                    popperClassName={classes.popperClassName}
                                    options={operatorOptions}
                                    defaultValue={selectedCondition}
                                    handleOptionChange={handleConditionChange}
                                    ddPlaceholder=""
                                    menuPlaceholder=""
                                />
                            </Grid>
                            <Grid item xs={4} md={5}>
                                {showInput &&
                                    (isDependencyStatus ? (
                                        <Grid
                                            container
                                            wrap="nowrap"
                                            alignItems="center"
                                            spacing={1}
                                            className={classes.dependencyWrapper}
                                        >
                                            <Grid item className={classes.flx}>
                                                {showSubField ? (
                                                    <SubFieldRender
                                                        classes={classes}
                                                        t={t}
                                                        subField="_dependencyStatus"
                                                        value={value}
                                                        theme={theme}
                                                    />
                                                ) : (
                                                    <InputText
                                                        placeholder="Value"
                                                        onChange={handleInputChange}
                                                        value={value}
                                                        inputClassName={classes.inputDependency}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {value?.trim() && (
                                                    <RemoveSVG
                                                        onClick={() => handleInputChange({ target: { value: '' } })}
                                                        className={classes.close}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.extraFilter}
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justify="center"
                                                onClick={handleClick}
                                            >
                                                <Grid item className={classes.arrowDown}>
                                                    <ArrowDownSVG />
                                                </Grid>
                                                <div ref={extraContainerRef}></div>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <InputText
                                            placeholder="Value"
                                            onChange={handleInputChange}
                                            value={value}
                                            className={classes.inputBase}
                                        />
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classnames(classes.OR)} onClick={handleClickOr}>
                        <p className="body1">{t('global_or')}</p>
                    </Grid>
                    <Grid item>
                        <RemoveSVG onClick={handleClickDelete} className={classes.close} />
                    </Grid>
                    {anchorEl && (
                        <PopperMenu
                            handleClickAway={handleClickAway}
                            anchorEl={rootRef?.current}
                            container={document.getElementById('react-flow-wrapper')}
                            placement="bottom"
                            paperClassName={classes.paperClassName}
                            style={{ width: rootRef?.current?.offsetWidth }}
                        >
                            <TreeSuggesstion options={options} handleSelect={handleFieldChange} />
                        </PopperMenu>
                    )}
                    {anchorExtraEl && (
                        <PopperMenu
                            anchorEl={anchorExtraEl}
                            placement={'bottom-end'}
                            handleClickAway={handleClickAwayExtra}
                            container={extraContainerRef.current}
                        >
                            <ExtraFilterMenu
                                subField="_dependencyStatus"
                                defaultValue={value}
                                onChange={handleExtraFilterChange}
                                isChildDependency
                            />
                        </PopperMenu>
                    )}
                </Grid>
                {fieldError && (
                    <Grid item container alignItems="center" wrap="nowrap" className={classes.errortext}>
                        <Grid item>
                            <ExclaimationMarkSVG className={classes.errorIcon} />
                        </Grid>
                        <Grid item>
                            <p className="body2 ml-2 text-error">{fieldError}</p>
                        </Grid>
                    </Grid>
                )}
            </>
        );
    }
);

const FilterGroup = React.memo(
    ({
        actionNodeOrder,
        objectOptions,
        options,
        index,
        isLast,
        filterGroup,
        handleAnd,
        handleOr,
        handleDelete,
        handleChangeField,
        handleChangeOperator,
        handleChangeValue
    }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const { filterFields } = filterGroup;
        return (
            <Grid item>
                <Grid item className={classes.filterGroup}>
                    {filterFields?.map((filterField, idx) => (
                        <FilterItem
                            actionNodeOrder={actionNodeOrder}
                            key={filterField.id}
                            filterField={filterField}
                            objectOptions={objectOptions}
                            options={options}
                            handleDelete={() => handleDelete({ filterGroupIdx: index, filterFieldIdx: idx })}
                            handleOr={() => handleOr({ filterGroupIdx: index, filterFieldIdx: idx + 1 })}
                            handleChangeField={handleChangeField({ filterGroupIdx: index, filterFieldIdx: idx })}
                            handleChangeOperator={handleChangeOperator({ filterGroupIdx: index, filterFieldIdx: idx })}
                            handleChangeValue={handleChangeValue({ filterGroupIdx: index, filterFieldIdx: idx })}
                        />
                    ))}
                </Grid>
                <Grid item container direction="column" alignItems="center">
                    <Grid item className={classes.line} />
                    <Grid item>
                        <Grid
                            className={classes.and}
                            container
                            alignItems="center"
                            spacing={isLast ? 1 : 0}
                            wrap="nowrap"
                            onClick={handleAnd}
                        >
                            {isLast && (
                                <Grid item className={classes.flex}>
                                    <ColorAddSVG />
                                </Grid>
                            )}
                            <Grid item>
                                <p className="body1 inline text-text-primary">{t('global_and')}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isLast && <Grid item className={classes.line} />}
                </Grid>
            </Grid>
        );
    }
);

const AutomationFilterGroup = ({ selectedNode, setSelectedNode }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const [filterGroups, setFilterGroups] = useState(
        selectedNode?.params?.filterGroups ? JSON.parse(JSON.stringify(selectedNode.params?.filterGroups)) : []
    );
    const timeout = useRef();
    const tempFilterGroups = useRef(
        selectedNode?.params?.filterGroups ? JSON.parse(JSON.stringify(selectedNode.params?.filterGroups)) : []
    );

    useEffect(() => {
        setFilterGroups(
            selectedNode?.params?.filterGroups ? JSON.parse(JSON.stringify(selectedNode.params?.filterGroups)) : []
        );
        tempFilterGroups.current = selectedNode?.params?.filterGroups
            ? JSON.parse(JSON.stringify(selectedNode.params?.filterGroups))
            : [];
    }, [selectedNode]);

    const treeOptions = useAutomationPayloadTreeOptions();
    const nodeTreeOptions = useAutomationNodeTreeOptions();

    const allOptions = useMemo(() => {
        return [...treeOptions, ...nodeTreeOptions];
    }, [nodeTreeOptions, treeOptions]);

    const options = useMemo(() => {
        return allOptions.filter(node => node.nodeOrder < selectedNode.order);
    }, [allOptions, selectedNode.order]);

    const getObjectTreeOptions = useCallback(options => {
        return options.reduce((acc, option) => {
            acc[option.serverParam] = option;
            if (option.options) {
                acc = { ...acc, ...getObjectTreeOptions(option.options) };
            }
            return acc;
        }, {});
    }, []);

    const objectOptions = useMemo(() => {
        return getObjectTreeOptions(allOptions);
    }, [allOptions, getObjectTreeOptions]);

    const setFilterGroupServer = useCallback(() => {
        setFilterGroups([...tempFilterGroups.current]);
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            const oldFilterGroups = JSON.parse(JSON.stringify(filterGroups));
            dispatch(
                updateActionNode({
                    automationId: automationDetail.id,
                    nodeId: selectedNode.id,
                    body: {
                        params: { ...selectedNode.params, filterGroups: tempFilterGroups.current }
                    },
                    errorCallback: () => {
                        setFilterGroups(oldFilterGroups);
                    }
                })
            );
        }, 800);
    }, [automationDetail.id, dispatch, filterGroups, selectedNode.id, selectedNode.params]);

    const handleAnd = useCallback(
        ({ filterGroupIdx }) => () => {
            tempFilterGroups.current.splice(filterGroupIdx, 0, {
                id: new Date().getTime(),
                type: 'or',
                filterFields: [generateAutomationConditionFilterField()]
            });
            setFilterGroupServer();
        },
        [setFilterGroupServer]
    );

    const handleOr = useCallback(
        ({ filterGroupIdx, filterFieldIdx }) => {
            tempFilterGroups.current[filterGroupIdx].filterFields.splice(
                filterFieldIdx,
                0,
                generateAutomationConditionFilterField()
            );
            setFilterGroupServer();
        },
        [setFilterGroupServer]
    );

    const handleDelete = useCallback(
        ({ filterGroupIdx, filterFieldIdx }) => {
            tempFilterGroups.current[filterGroupIdx].filterFields.splice(filterFieldIdx, 1);
            if (tempFilterGroups.current.length === 1 && !tempFilterGroups.current[0]?.filterFields?.length) {
                tempFilterGroups.current.length = 0;
            } else {
                if (!tempFilterGroups.current[filterGroupIdx].filterFields?.length) {
                    tempFilterGroups.current.splice(filterGroupIdx, 1);
                }
            }
            setFilterGroupServer();
        },
        [setFilterGroupServer]
    );

    const handleChangeField = useCallback(
        ({ filterGroupIdx, filterFieldIdx }) => value => {
            tempFilterGroups.current[filterGroupIdx].filterFields[filterFieldIdx].field = value;
            setFilterGroupServer();
        },
        [setFilterGroupServer]
    );

    const handleChangeOperator = useCallback(
        ({ filterGroupIdx, filterFieldIdx }) => value => {
            tempFilterGroups.current[filterGroupIdx].filterFields[filterFieldIdx].operator = value;
            if (INVALID_OPERATORS_FOR_INPUT.includes(value)) {
                tempFilterGroups.current[filterGroupIdx].filterFields[filterFieldIdx].values = [];
            }
            setFilterGroupServer();
        },
        [setFilterGroupServer]
    );

    const handleChangeValue = useCallback(
        ({ filterGroupIdx, filterFieldIdx }) => value => {
            tempFilterGroups.current[filterGroupIdx].filterFields[filterFieldIdx].values = [value];
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                filterGroups: tempFilterGroups.current
                            }
                        },
                        successCallback: responseData => {
                            setFilterGroups(tempFilterGroups.current);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 800);
        },
        [automationDetail.id, dispatch, selectedNode]
    );

    const handleAddCondition = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            tempFilterGroups.current = [
                {
                    id: new Date().getTime(),
                    type: 'or',
                    filterFields: [generateAutomationConditionFilterField()]
                }
            ];
            setFilterGroupServer();
        },
        [setFilterGroupServer]
    );

    if (!filterGroups?.length) {
        return (
            <Grid item>
                <Grid container wrap="nowrap" className={classes.addContainer} onClick={handleAddCondition}>
                    <Grid item className={classes.flex}>
                        <ColorAddSVG />
                    </Grid>
                    <Grid item>
                        <p className="body1 text-brand-main ml-2">Add condition</p>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item>
            {filterGroups?.map((filterGroup, index) => (
                <FilterGroup
                    key={index}
                    index={index}
                    isLast={index === filterGroups.length - 1}
                    objectOptions={objectOptions}
                    options={options}
                    filterGroup={filterGroup}
                    handleChangeField={handleChangeField}
                    handleChangeOperator={handleChangeOperator}
                    handleChangeValue={handleChangeValue}
                    handleAnd={handleAnd({ filterGroupIdx: index + 1 })}
                    handleOr={handleOr}
                    handleDelete={handleDelete}
                    actionNodeOrder={selectedNode.order}
                />
            ))}
        </Grid>
    );
};

export default React.memo(AutomationFilterGroup);
