import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import MemoQPNG from 'assets/images/svg/addon/MemoQ.png';
import MemoQCheckPNG from 'assets/images/memoQCheck.png';
import InputText from 'components/inputs/InputText';
import { useTranslation, Trans } from 'react-i18next';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import * as addonActions from 'addons/actions';
import { ADD_ON_STATES } from 'const';
import { sendManualTrack } from 'tracker';
import PopperMenu from 'components/menus/Popper';
import ListItem from 'components/list/Item';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    memoqType: {
        cursor: 'pointer',
        background: theme.colors.paleGrey,
        borderRadius: 4,
        padding: 4
    },
    dpFlex: {
        display: 'flex'
    },
    expand: {
        flex: 1
    },

    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700,
        textDecoration: 'none'
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    buttonWrapper: {
        position: 'relative',
        marginTop: 8
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    error: {
        color: theme.colors.brightRed
    }
}));

const MEMOQ_TYPE_OPTIONS = [
    {
        value: 'MEMOQ_CLOUD',
        label: 'memoQ cloud'
    },
    {
        value: 'MEMOQ_SERVER',
        label: 'memoQ server'
    }
];

function MemoQSetting({ integration }) {
    const { id, apiKey, apiBaseUrl, state } = integration;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [baseAddress, setBaseAddress] = React.useState(apiBaseUrl || '');
    const [isConnecting, setIsConnecting] = React.useState(false);
    const [key, setKey] = React.useState(apiKey || '');
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [currentMemoQ, setCurrentMemoQ] = React.useState(MEMOQ_TYPE_OPTIONS[0]);
    const [error, setError] = React.useState('');

    const handleConnect = React.useCallback(() => {
        setIsConnecting(true);
        sendManualTrack({ type: 'Connect memoQ' });
        setError('');
        dispatch(
            addonActions.changeAddon({
                addonId: id,
                newBody: {
                    state: ADD_ON_STATES.ACTIVE,
                    apiKey: key,
                    apiBaseUrl: baseAddress
                },
                oldBody: {
                    state,
                    apiKey,
                    apiBaseUrl
                },
                successCallback: () => {
                    setIsConnecting(false);
                    console.log('done');
                },
                errorCallback: message => {
                    setIsConnecting(false);
                    console.log('failed');
                    setError(message);
                }
            })
        );
    }, [id, apiBaseUrl, apiKey, baseAddress, key, dispatch, state]);

    const handleClick = event => setAnchorEl(anchorEl ? null : event.currentTarget);
    const handleClickAway = () => setAnchorEl(null);

    const setMemoqType = option => {
        handleClickAway();
        setCurrentMemoQ(option);
    };

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h4">{t('global_settings')}</Typography>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        className={classes.memoqType}
                        onClick={handleClick}
                        spacing={1}
                        alignItems="center"
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Typography variant="body1">{currentMemoQ.label}</Typography>
                        </Grid>
                        <Grid item className={classes.dpFlex}>
                            <ArrowDownSVG />
                        </Grid>
                    </Grid>
                    {anchorEl && (
                        <PopperMenu anchorEl={anchorEl} placement={'bottom-center'} handleClickAway={handleClickAway}>
                            <Grid container direction="column" className={classes.roleSelect}>
                                {MEMOQ_TYPE_OPTIONS.map((option, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={() => setMemoqType(option)}
                                        name={option.label}
                                        isSelected={option.value === currentMemoQ.value}
                                    />
                                ))}
                            </Grid>
                        </PopperMenu>
                    )}
                </Grid>
            </Grid>
            {currentMemoQ.value === MEMOQ_TYPE_OPTIONS[0].value && (
                <>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_1')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_1_description"
                                            defaults="<normal>Open the memoQ application and login to server (follow </normal><highlight>this guide</highlight>)"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />,
                                                highlight: (
                                                    <a
                                                        href={
                                                            'https://docs.memoq.com/ggl-tst/Places/log-in-to-server.html'
                                                        }
                                                        target="_blank"
                                                        style={{
                                                            color: theme.colors.dodgerBlue,
                                                            textDecoration: 'none',
                                                            fontWeight: 500
                                                        }}
                                                        variant="body1"
                                                        display="inline"
                                                        rel="noopener noreferrer"
                                                    >
                                                        memoQ cloud
                                                    </a>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_2')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_2_description"
                                            defaults="<normal>Click on</normal> <bold>Server Administrator</bold> <normal>in the desktop app</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <img src={MemoQPNG} alt={'MemoQPNG'} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_3')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_3_description"
                                            defaults="<normal>Under<normal> <bold>Connection</bold> <normal>, click <check /> to connect to the memoQ server</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />,
                                                check: (
                                                    <img
                                                        alt="check"
                                                        src={MemoQCheckPNG}
                                                        style={{
                                                            paddingLeft: 4,
                                                            paddingRight: 4,
                                                            position: 'relative',
                                                            top: 3
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_4')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_4_description"
                                            defaults="<normal>Under<normal> <bold>Web Service Interface</bold><normal>, enter the <bold>Base address</bold> into this box</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="name"
                                            value={baseAddress}
                                            onChange={e => setBaseAddress(e.target.value)}
                                            placeholder={t('addon_memoQ_setting_step_3_input_placeholder')}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_5')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                {
                                    <Trans
                                        t={t}
                                        i18nKey={
                                            isExternal
                                                ? 'addon_memoQ_setting_step_5_description_sh'
                                                : 'addon_memoQ_setting_step_5_description'
                                        }
                                        components={{
                                            normal: <Typography variant="body2" display="inline" />,
                                            bold: <Typography variant="body1" display="inline" />
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_6')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_6_description"
                                            defaults="<normal>Select</normal> <bold>Enable API Key,</bold> <normal>copy the key and paste into this box</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="name"
                                            value={key}
                                            onChange={e => setKey(e.target?.value)}
                                            placeholder={t('addon_memoQ_setting_step_6_input_placeholder')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            {currentMemoQ.value === MEMOQ_TYPE_OPTIONS[1].value && (
                <>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_1')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_1_description_server"
                                            defaults="<normal>Follow</normal> <highlight>this guide</highlight> <normal>to enable Web Service API. In Configure Server window, click on Web Service API tab.</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />,
                                                highlight: (
                                                    <a
                                                        href={
                                                            'https://docs.memoq.com/current/en/memoQ-server-deployment-tool/deptool-configure-server.html'
                                                        }
                                                        target="_blank"
                                                        style={{
                                                            color: theme.colors.dodgerBlue,
                                                            textDecoration: 'none',
                                                            fontWeight: 500
                                                        }}
                                                        variant="body1"
                                                        display="inline"
                                                        rel="noopener noreferrer"
                                                    >
                                                        memoQ cloud
                                                    </a>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_2')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Trans
                                    t={t}
                                    i18nKey={
                                        isExternal
                                            ? 'addon_memoQ_setting_step_2_description_server_sh'
                                            : 'addon_memoQ_setting_step_2_description_server'
                                    }
                                    components={{
                                        normal: <Typography variant="body2" display="inline" />,
                                        bold: <Typography variant="body1" display="inline" />
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_3')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_3_description_server"
                                            defaults="<normal>Copy the</normal> <bold>Base address</bold> <normal>and paste it into this box</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="name"
                                            value={baseAddress}
                                            onChange={e => setBaseAddress(e.target.value)}
                                            placeholder={t('addon_memoQ_setting_step_3_input_placeholder')}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_4')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Trans
                                            t={t}
                                            i18nKey="addon_memoQ_setting_step_4_description_server"
                                            defaults="<normal>Select</normal> <bold>Enable API Key,</bold> <normal>copy the key and paste into this box</normal>"
                                            components={{
                                                normal: <Typography variant="body2" display="inline" />,
                                                bold: <Typography variant="body1" display="inline" />
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputText
                                            name="name"
                                            value={key}
                                            onChange={e => setKey(e.target?.value)}
                                            placeholder={t('addon_memoQ_setting_step_6_input_placeholder')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            {error && (
                <Grid item>
                    <Typography className={classes.error} variant="body2">
                        {error}
                    </Typography>
                </Grid>
            )}
            <Grid item className={`${classes.buttonWrapper}`}>
                <ButtonBase
                    disabled={!key || !baseAddress || isConnecting}
                    onClick={handleConnect}
                    variant="contained"
                    width={230}
                >
                    {isConnecting ? <CircularProgress size={24} className={classes.buttonProgress} /> : t('connect')}
                </ButtonBase>
            </Grid>
        </Grid>
    );
}

export default React.memo(MemoQSetting);
