import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import InviteMemberNoEmailSVG from 'assets/images/svg/inviteMember/InviteMemberNoEmailSVG';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    mt30: { marginTop: 30 },
    root: {
        flex: 1
    },
    noEmailContent: {
        color: theme.colors.midGrey,
        textAlign: 'center'
    },
    uploadZone: {
        textDecoration: 'underline',
        fontWeight: 600,
        color: theme.colors.highlight,
        cursor: 'pointer'
    },
    download: {
        fontWeight: 600,
        color: theme.colors.highlight
    }
}));

const InviteMembersNoData = ({ isLdManager, onOpenBulkInvite }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid
            className={classes.root}
            container
            wrap="nowrap"
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item>
                <InviteMemberNoEmailSVG />
            </Grid>
            <Grid item className={classes.mt30}>
                <Typography className={classes.noEmailContent} variant="body2">
                    {isLdManager ? (
                        `Add email addresses to invite LD members to Gridly.`
                    ) : (
                        <Trans
                            t={t}
                            i18nKey="invite_member_no_email"
                            components={{
                                '<br />': <br />,
                                b: <b className={classes.uploadZone} onClick={onOpenBulkInvite} />
                            }}
                        />
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(InviteMembersNoData);
