import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import UserInfo from './UserInfo';
import ChangePassword from './ChangePassword';
import Accessibility from './Accessibility';
import UserTwoFactor from './UserTwoFactor';
import NotificationSettings from './NotificationSettings';
import ws from 'socket';
import { useCompanyId, useCurrentUserId } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}));

function Profile() {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const userId = useCurrentUserId();
    const companyId = useCompanyId();

    useEffect(() => {
        ws.profileSettingSubscribeTopics({ userId, companyId });
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [userId, companyId]);

    return (
        <Grid container className={classes.root}>
            <Switch>
                <Route path={`${path}/info`}>
                    <UserInfo />
                </Route>
                <Route path={`${path}/change-password`}>
                    <ChangePassword />
                </Route>
                <Route path={`${path}/accessibility`}>
                    <Accessibility />
                </Route>
                <Route path={`${path}/two-factor`}>
                    <UserTwoFactor />
                </Route>
                <Route path={`${path}/notification-setting`}>
                    <NotificationSettings />
                </Route>
            </Switch>
        </Grid>
    );
}

export default React.memo(Profile);
