import React, { useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Grid, IconButton } from '@material-ui/core';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import PopperMenu from 'components/menus/Popper';
import { Handle } from 'react-flow-renderer';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ExclaimationMarkSVG from 'assets/images/svg/ExclaimationMarkSVG';
import { useDispatch } from 'react-redux';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import {
    deleteActionNode,
    updateActionNode,
    setAutomationDetail,
    moveAutomationNode,
    createAutomationNode
} from '../../action';
import { ACTION_STATUS } from '../../const';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { getHookTypeLabel } from 'const/gridUI';
import ArrowMoveUpSVG from 'assets/images/svg/ArrowMoveUpSVG';
import ArrowMoveDownSVG from 'assets/images/svg/ArrowMoveDownSVG';
import { arrayMoveMutable } from 'utils/array';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import { enqueueSnackbar } from 'notifier/actions';
import ActionNodeDescription from './ActionNodeDescription';
import { COLOR_TYPES, WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        width: 440,
        padding: 20,
        background: theme.colors.white,
        borderRadius: 4,
        boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.15)`
    },
    rootDisabled: {
        opacity: 0.4
    },
    flx: { flex: 1 },
    mt12: {
        marginTop: 12
    },
    item: {
        marginTop: 10,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: `9px 16px`,
        cursor: 'pointer',
        transition: `border-color 0.6s linear;`
    },
    itemError: {
        borderColor: theme.colors.brightRed
    },
    itemSelected: {
        borderColor: theme.colors.fuchsiaBlue
    },
    itemDisabled: {},
    iconWrapper: { marginRight: 10 },
    iconMore: {
        cursor: 'pointer'
    },
    morePopup: {
        width: 260
    },
    errorLabel: {
        marginLeft: 8,
        color: theme.colors.brightRed
    },
    errorIcon: {
        verticalAlign: 'text-top'
    },
    triggerText: {
        color: theme.colors.midGrey,
        textTransform: 'uppercase'
    }
}));

const ActionNode = ({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorMoreEl, setAnchorMoreEl] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const automationDetail = useGetAutomationDetail();
    const iconMoreRef = useRef();
    const { id, app, status, selectedNode, setSelectedNode, onClickNode, isError, order } = data;
    const [actionStatus, setActionStatus] = useState(status || ACTION_STATUS.ENABLED);

    const isDisabled = useMemo(() => {
        return actionStatus === ACTION_STATUS.DISABLED;
    }, [actionStatus]);

    const error = useMemo(() => {
        const str = t('automation_invalid_action_configuration');
        if (isError) {
            return str;
        }
        return null;
    }, [isError, t]);

    const showMoveUp = useMemo(() => {
        const idx = automationDetail?.nodes?.findIndex(node => node.id === id);
        return automationDetail.nodes.length > 1 && idx > 0;
    }, [automationDetail.nodes, id]);

    const showMoveDown = useMemo(() => {
        const idx = automationDetail?.nodes?.findIndex(node => node.id === id);
        return automationDetail.nodes.length > 1 && idx < automationDetail.nodes.length - 1;
    }, [automationDetail.nodes, id]);

    const stopPropagation = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        e.persist();
    }, []);

    const onClick = useCallback(
        e => {
            onClickNode(e, data);
        },
        [onClickNode, data]
    );

    const handleClickMoreAction = useCallback(
        e => {
            stopPropagation(e);
            setAnchorMoreEl(anchorMoreEl => (anchorMoreEl ? null : iconMoreRef.current));
        },
        [stopPropagation]
    );

    const handleCloseMore = useCallback(e => {
        setAnchorMoreEl(null);
    }, []);

    const handleDelete = useCallback(
        e => {
            stopPropagation(e);
            setOpenDelete(true);
            handleCloseMore();
        },
        [handleCloseMore, stopPropagation]
    );

    const handleCloseDelete = useCallback(
        e => {
            if (deleting) return;
            setOpenDelete(false);
        },
        [deleting]
    );

    const handleAgreeDelete = useCallback(
        e => {
            stopPropagation(e);
            if (deleting) return;
            setDeleting(true);
            dispatch(
                deleteActionNode({
                    nodeId: id,
                    isSelectedNodeTrigger: !!selectedNode?.trigger,
                    selectedNodeId: selectedNode?.id,
                    successSelectedNodeCallback: newNode => {
                        if (selectedNode?.id === id) return;
                        setSelectedNode(newNode);
                    },
                    successCallback: () => {
                        setDeleting(false);
                        setOpenDelete(false);
                        if (selectedNode?.id === id) {
                            setSelectedNode(null);
                        }
                    },
                    errorCallback: () => {
                        setDeleting(false);
                    }
                })
            );
        },
        [deleting, dispatch, id, setSelectedNode, selectedNode, stopPropagation]
    );

    const handleToggleEnable = useCallback(
        e => {
            stopPropagation(e);
            handleCloseMore();
            const newStatus = isDisabled ? ACTION_STATUS.ENABLED : ACTION_STATUS.DISABLED;
            setActionStatus(newStatus);
            const oldSelectedNode = JSON.parse(JSON.stringify(selectedNode));
            if (selectedNode?.id === id) {
                onClickNode(e, {
                    ...selectedNode,
                    status: newStatus
                });
            }
            dispatch(
                updateActionNode({
                    automationId: automationDetail.id,
                    nodeId: id,
                    body: {
                        status: newStatus
                    },
                    errorCallback: () => {
                        setDeleting(false);
                        setActionStatus(isDisabled ? ACTION_STATUS.DISABLED : ACTION_STATUS.ENABLED);
                        if (selectedNode?.id === id) {
                            onClickNode(e, oldSelectedNode);
                        }
                    }
                })
            );
        },
        [automationDetail, dispatch, id, isDisabled, stopPropagation, handleCloseMore, selectedNode, onClickNode]
    );

    const handleMoveUp = useCallback(
        e => {
            stopPropagation(e);
            handleCloseMore();
            const oldAutomationDetail = JSON.parse(JSON.stringify(automationDetail));
            const newAutomationDetail = JSON.parse(JSON.stringify(automationDetail));
            const idx = automationDetail.nodes.findIndex(node => node.id === id);
            if (idx > -1) {
                arrayMoveMutable(newAutomationDetail.nodes, idx, idx - 1);
                dispatch(setAutomationDetail({ ...newAutomationDetail }));
                dispatch(
                    moveAutomationNode({
                        automationId: automationDetail.id,
                        nodeId: id,
                        selectedNodeId: selectedNode?.id,
                        body: {
                            beforeNodeId: newAutomationDetail.nodes[idx].id
                        },
                        successCallback: response => {
                            setSelectedNode(response);
                        },
                        errorCallback: () => {
                            dispatch(setAutomationDetail({ ...oldAutomationDetail }));
                        }
                    })
                );
            }
        },
        [automationDetail, dispatch, handleCloseMore, id, selectedNode, setSelectedNode, stopPropagation]
    );

    const handleMoveDown = useCallback(
        e => {
            stopPropagation(e);
            handleCloseMore();
            const oldAutomationDetail = JSON.parse(JSON.stringify(automationDetail));
            const newAutomationDetail = JSON.parse(JSON.stringify(automationDetail));
            const idx = automationDetail.nodes.findIndex(node => node.id === id);
            if (idx > -1) {
                arrayMoveMutable(newAutomationDetail.nodes, idx, idx + 1);
                dispatch(setAutomationDetail({ ...newAutomationDetail }));
                dispatch(
                    moveAutomationNode({
                        automationId: automationDetail.id,
                        nodeId: id,
                        selectedNodeId: selectedNode?.id,
                        body: {
                            afterNodeId: newAutomationDetail.nodes[idx].id
                        },
                        successCallback: response => {
                            setSelectedNode(response);
                        },
                        errorCallback: () => {
                            dispatch(setAutomationDetail({ ...oldAutomationDetail }));
                        }
                    })
                );
            }
        },
        [automationDetail, dispatch, handleCloseMore, id, selectedNode, setSelectedNode, stopPropagation]
    );

    const handleDuplicate = useCallback(
        e => {
            stopPropagation(e);
            handleCloseMore();
            const newData = JSON.parse(JSON.stringify(data));
            newData.parentId = data.id;
            delete newData.id;
            delete newData.errors;
            delete newData.isError;
            delete newData.onClickNode;
            delete newData.selectedNode;
            delete newData.setSelectedNode;
            delete newData.type;
            dispatch(
                createAutomationNode({
                    automationId: automationDetail.id,
                    body: {
                        ...newData
                    },
                    successCallback: responseData => {
                        const nodes = [...(automationDetail.nodes || [])];
                        const parentIdx = nodes.findIndex(node => node.id === data.id);
                        if (parentIdx > -1) {
                            nodes.splice(parentIdx + 1, 0, responseData);
                        }
                        dispatch(
                            setAutomationDetail({
                                ...automationDetail,
                                nodes
                            })
                        );
                        dispatch(
                            enqueueSnackbar({
                                message: t('automation_duplicate_action_success'),
                                type: 'info'
                            })
                        );
                    }
                })
            );
        },
        [automationDetail, data, dispatch, handleCloseMore, stopPropagation, t]
    );

    const onContextMenu = React.useCallback(
        e => {
            handleClickMoreAction(e);
        },
        [handleClickMoreAction]
    );

    return (
        <Grid
            id={`automation-action-node-${id}`}
            item
            className={classnames(classes.root, {
                [classes.rootDisabled]: isDisabled
            })}
        >
            <Grid item>
                <p className="body1 uppercase text-grey-mid">
                    {t(app === WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION ? 'automation_condition' : 'automation_action')}
                </p>
            </Grid>
            <Grid
                item
                className={classnames(classes.item, {
                    [classes.itemError]: Boolean(error),
                    [classes.itemSelected]: selectedNode?.id === id,
                    [classes.itemDisabled]: isDisabled
                })}
                onClick={onClick}
                onContextMenu={onContextMenu}
            >
                <Grid item container alignItems="center" wrap="nowrap">
                    <Grid item className={classnames(classes.iconWrapper)}>
                        <WebHookLabel type={app} />
                    </Grid>
                    <Grid item className={classnames(classes.flx)}>
                        <p className="body1">
                            {order}. {getHookTypeLabel(app)}
                        </p>
                    </Grid>
                    <Grid item className={classes.iconMore}>
                        <IconButton ref={iconMoreRef} onClick={handleClickMoreAction}>
                            <IconMoreActionsSVG color="#1A051D" />
                        </IconButton>
                    </Grid>
                </Grid>
                <ActionNodeDescription node={data} />
            </Grid>

            {error && (
                <Grid item container alignItems="center" wrap="nowrap" className={classes.mt12}>
                    <Grid item>
                        <ExclaimationMarkSVG className={classes.errorIcon} />
                    </Grid>
                    <Grid item>
                        <p className="body2 ml-2 text-error">{error}</p>
                    </Grid>
                </Grid>
            )}
            <Handle type="target" position="top" isConnectable={false} />
            <Handle type="source" position="bottom" isConnectable={false} />
            {anchorMoreEl && (
                <PopperMenu
                    handleClickAway={handleCloseMore}
                    anchorEl={anchorMoreEl}
                    container={document.getElementById('react-flow-wrapper')}
                >
                    <Grid item container direction="column" wrap="nowrap" className={classes.morePopup}>
                        <ListItem
                            onClick={handleToggleEnable}
                            icon={<EyeCloseIconSVG />}
                            name={t(isDisabled ? `global_enable` : `global_disable`)}
                        />
                        <ListItem
                            onClick={handleDuplicate}
                            icon={<DuplicateSVG color="#78778B" />}
                            name={t(`automation_duplicate_action`)}
                        />
                        <ListItem onClick={handleDelete} icon={<DeleteSVG />} name={t(`global_delete`)} />
                        {showMoveUp && (
                            <ListItem onClick={handleMoveUp} icon={<ArrowMoveUpSVG />} name={t(`global_move_up`)} />
                        )}
                        {showMoveDown && (
                            <ListItem
                                onClick={handleMoveDown}
                                icon={<ArrowMoveDownSVG />}
                                name={t(`global_move_down`)}
                            />
                        )}
                    </Grid>
                </PopperMenu>
            )}
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <ConfirmBox
                    title={t('automation_confirm_delete_action_title')}
                    body={<p className="body2">{t('automation_confirm_delete_action_content')}</p>}
                    handleCancel={handleCloseDelete}
                    onClose={handleCloseDelete}
                    handleAgreed={handleAgreeDelete}
                    agreeLabel={t('global_delete')}
                    isLoading={deleting}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(ActionNode);
