import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import {
    useAdvancedSearch,
    useAdvancedSearchId,
    useAdvancedSearchSnapshot,
    useClearGrid,
    useColumnIds,
    useColumns,
    useColumnWidthStore,
    useIsBlockedGrid,
    useIsBlockedGridByOwner,
    useIsFetching,
    useIsHasManageAdvancedSearchPermission,
    useIsOpenConfirmation,
    useIsSelectedSharedSearch,
    useNextAdvancedSearchId,
    useSelectedAdvancedSearchById,
    useSelectedAdvancedSearchId,
    useSelectedColumns
} from 'hooks/advanced';
import { useDatabaseList } from 'hooks/database';
import * as columnTypes from 'const/columnTypes';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import * as advancedSearchActions from 'advancedSearch/actions';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import LDBasePortalGroup from 'components/selects/LDBasePortalGroup';
import ButtonBase from 'components/button/Base';
import GridIcon from 'grids/GridIcon';
import { ADVANCED_SEARCH_RECORD_LIMIT, DEFAULT_COLUMN_WIDTH } from 'const/gridUI';
import { DISABLED_OPACITY, INPUT_HEIGHT, INPUT_PADDING_LEFT, INPUT_RADIUS } from 'const/style';
import Dialog from 'components/dialog/Dialog';
import SaveAs from './grid/saveAs/Box';
import PopperMenu from 'components/menus/Popper';
import ListItem from 'components/list/Item';
import { enqueueSnackbar } from 'notifier/actions';
import Search from 'components/search';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as workspaceActions from 'workspaces/actions';
import Spinner from 'components/spinner/Base';
import { isKbEnter } from 'utils/keyboard';
import { isNotContainAllCheckingArray } from 'utils/object';
import ConfirmBox from 'components/confirmBox/Base';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`
    },
    table: {
        marginTop: theme.spacing(2),
        position: 'relative',
        flex: 1,
        '& .active': {
            '& .ReactVirtualized__Table__row': {
                background: theme.colors.ghostwhite
            }
        }
    },
    dropdown: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        minWidth: 200
    },
    control: {
        width: '100%',
        borderRadius: INPUT_RADIUS,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText
    },
    saveDropDown: {
        minHeight: INPUT_HEIGHT,
        background: theme.palette.common.white,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`
        }
    },
    arrow: {
        display: 'flex'
    },
    ml: {
        marginLeft: theme.spacing(3)
    },
    number: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        width: 'fit-content',
        padding: `2px 4px`,
        height: 16,
        borderRadius: 4
    },
    mb4: {
        marginBottom: theme.spacing(4)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    expand: {
        flex: 1
    },
    fullHeight: {
        height: '100%'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    config: {
        flex: 1,
        marginRight: 64
    },
    search: {
        border: `1px solid ${theme.colors.border}`
    }
}));

function ManageSave({ searchTerm }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isBlockedGridByOwner = useIsBlockedGridByOwner();
    const advancedSearchId = useAdvancedSearchId();
    const selectedAdvancedSearchId = useSelectedAdvancedSearchId();
    const selectedAdvancedSearchById = useSelectedAdvancedSearchById();
    const advancedSearch = useAdvancedSearch();
    // const columns = useSelectedColumns();
    const { workspaceId } = useParams();
    const rootRef = React.useRef();
    const columnIds = useColumnIds();
    const columnWidthStore = useColumnWidthStore();

    const [openSaveAs, setOpenSaveAs] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [saving, setSaving] = React.useState(false);

    const isSelectedSharedSearch = useIsSelectedSharedSearch();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleOpenSaveAs = React.useCallback(() => {
        setOpenSaveAs(true);
    }, []);

    const handleCloseSaveAs = React.useCallback(() => {
        setOpenSaveAs(false);
    }, []);

    const handleClick = React.useCallback(
        event => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const isNew = React.useMemo(() => {
        return !(advancedSearchId || selectedAdvancedSearchId);
    }, [advancedSearchId, selectedAdvancedSearchId]);

    const handleSave = React.useCallback(() => {
        let body = {
            name: selectedAdvancedSearchById?.name,
            store: true
        };
        setSaving(true);

        if (columnIds?.length) {
            body = {
                ...body,
                items: columnIds?.map(id => ({
                    id,
                    width: columnWidthStore?.[id] || DEFAULT_COLUMN_WIDTH
                })),
                searchTerm: advancedSearch?.searchTerm || searchTerm
            };
        }

        dispatch(
            advancedSearchActions.updateAdvancedSearch({
                wsId: workspaceId,
                advancedSearchId: selectedAdvancedSearchId || advancedSearchId,
                body,
                takeSnapshot: true,
                success: () => {
                    setSaving(false);
                    handleClickAway();
                    dispatch(enqueueSnackbar({ message: `${selectedAdvancedSearchById?.name} has been saved` }));
                },
                error: error => {
                    setSaving(false);
                    console.log('error update');
                }
            })
        );
    }, [
        columnIds,
        advancedSearch,
        handleClickAway,
        advancedSearchId,
        dispatch,
        searchTerm,
        selectedAdvancedSearchById,
        selectedAdvancedSearchId,
        workspaceId,
        columnWidthStore
    ]);

    if (isBlockedGridByOwner || isNew || !selectedAdvancedSearchId || isSelectedSharedSearch) {
        return (
            <>
                <ButtonBase onClick={handleOpenSaveAs} disabled={isNew} width={100} variant="outlined">
                    Save as
                </ButtonBase>
                <Dialog open={openSaveAs} onClose={handleCloseSaveAs}>
                    <SaveAs isNew={true} searchTerm={searchTerm} onClose={handleCloseSaveAs} />
                </Dialog>
            </>
        );
    } else {
        return (
            <>
                <Grid
                    container
                    direction="row"
                    className={`${classes.control} ${classes.saveDropDown}`}
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                    onClick={handleClick}
                >
                    <Grid item>
                        <Typography variant="body2">Save</Typography>
                    </Grid>
                    <Grid item className={`${classes.arrow} ${classes.ml}`}>
                        <ArrowDownSVG />
                    </Grid>
                </Grid>
                {anchorEl && (
                    <PopperMenu anchorEl={anchorEl} placement={'bottom-start'}>
                        <Box ref={rootRef}>
                            <ListItem
                                disabled={saving}
                                onClick={handleSave}
                                name={
                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body2">Save this search query</Typography>
                                        </Grid>
                                        {saving && (
                                            <Grid item style={{ display: 'flex', marginLeft: theme.spacing(3) }}>
                                                <Spinner size={18} thick={3} />
                                            </Grid>
                                        )}
                                    </Grid>
                                }
                            />
                            <ListItem
                                onClick={e => {
                                    handleOpenSaveAs(e);
                                    handleClickAway(e);
                                }}
                                name="Save as new query"
                            />
                        </Box>
                    </PopperMenu>
                )}
                <Dialog open={openSaveAs} onClose={handleCloseSaveAs}>
                    <SaveAs searchTerm={searchTerm} onClose={handleCloseSaveAs} />
                </Dialog>
            </>
        );
    }
}

function _isDirtyQuery(chooseQuery, queryB) {
    const databaseIds = chooseQuery?.dbIds || [];
    const gridIds = chooseQuery?.gridIds?.map(item => {
        return item?.split('.')?.[1];
    });
    const columnNames = chooseQuery?.columns?.map(c => c?.columnName);
    const searchTerm = chooseQuery?.searchTerm;

    if (!(queryB?.columnNames?.length || queryB?.databaseIds?.length || queryB?.gridIds?.length || queryB?.searchTerm))
        return false;

    const isNotMatchColumnNames =
        queryB?.columnNames?.length !== columnNames?.length
            ? true
            : isNotContainAllCheckingArray({ mainArr: queryB?.columnNames, checkArr: columnNames });
    if (isNotMatchColumnNames) return true;

    const isNotMatchGridIds =
        queryB?.gridIds?.length !== gridIds?.length
            ? true
            : isNotContainAllCheckingArray({ mainArr: queryB?.gridIds, checkArr: gridIds });
    if (isNotMatchGridIds) return true;

    const isNotMatchDbIds =
        queryB?.databaseIds?.length !== databaseIds?.length
            ? true
            : isNotContainAllCheckingArray({ mainArr: queryB?.databaseIds, checkArr: databaseIds });
    if (isNotMatchDbIds) return true;

    return searchTerm !== queryB?.searchTerm;
}

function Controls() {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { workspaceId, aId } = useParams();
    const advancedSearch = useAdvancedSearch();
    const selectedAdvancedSearch = useSelectedAdvancedSearchById();
    const isBlockedGrid = useIsBlockedGrid();
    const columnList = useColumns();
    const databaseList = useDatabaseList();
    const [databases, setDatabases] = React.useState([]);
    const [grids, setGrids] = React.useState([]);
    const columns = useSelectedColumns();
    const selectedAdvancedSearchId = useSelectedAdvancedSearchId();
    const [searchTerm, setSearchTerm] = React.useState(advancedSearch?.searchTerm || ``);
    const clearGrid = useClearGrid();
    const isFetching = useIsFetching();
    const isHasManageAdvancedSearchPermission = useIsHasManageAdvancedSearchPermission();
    const advancedSearchSnapshot = useAdvancedSearchSnapshot();
    const nextAdvancedSearchId = useNextAdvancedSearchId();
    const isOpenConfirmation = useIsOpenConfirmation();
    const history = useHistory();
    const columnWidthStore = useColumnWidthStore();
    const { url } = useRouteMatch();

    const databaseIds = React.useMemo(() => {
        return databases?.map(db => db?.value);
    }, [databases]);

    const gridIds = React.useMemo(() => {
        return grids?.map(grid => grid?.value);
    }, [grids]);

    const columnNames = React.useMemo(() => {
        return columns?.map(c => c?.name);
    }, [columns]);

    const isDirty = React.useMemo(() => {
        if (isFetching) return false;
        if (!advancedSearchSnapshot && !advancedSearch) return false;

        if (advancedSearchSnapshot) {
            return _isDirtyQuery(advancedSearchSnapshot, {
                databaseIds,
                gridIds,
                columnNames,
                searchTerm
            });
        }

        return _isDirtyQuery(advancedSearch, { databaseIds, gridIds, columnNames, searchTerm });
    }, [isFetching, advancedSearchSnapshot, advancedSearch, columnNames, searchTerm, gridIds, databaseIds]);

    React.useMemo(() => {
        window.advancedSearchDirty = isDirty;
    }, [isDirty]);

    React.useEffect(() => {
        if (aId) {
            dispatch(advancedSearchActions.setSelectedAdvancedSearchId(aId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const columnOptions = React.useMemo(() => {
        return columnList?.map(col => {
            const detailColumns = col?.details;

            let columnDetail = {
                ...detailColumns?.[0],
                type: col?.type,
                metadata: detailColumns?.reduce((obj, col) => {
                    obj[col?.columnId] = col;
                    return obj;
                }, {}),
                columnIds: detailColumns?.map(col => col?.columnId)
            };

            if ([columnTypes.RECORD_ID, columnTypes.PATH_TAG]?.includes(columnDetail?.columnId)) {
                columnDetail = {
                    ...columnDetail,
                    type: columnDetail?.columnId
                };
            }

            delete columnDetail?.options;

            return {
                ...columnDetail,
                label: col?.columnName,
                value: columnDetail?.columnId,
                icon: () => <ColumnTypeDisplay {...columnDetail} />,
                hashColumnId: col?.hashColumnId,
                name: col?.columnName
            };
        });
    }, [columnList]);

    const searchTermDefault = React.useMemo(() => {
        return advancedSearch?.searchTerm || ``;
    }, [advancedSearch]);

    React.useEffect(() => {
        setSearchTerm(searchTermDefault);
    }, [searchTermDefault]);

    React.useEffect(() => {
        if (clearGrid) {
            setDatabases([]);
            setGrids([]);
            dispatch(advancedSearchActions.setClearGrid(false));
        }
    }, [clearGrid, dispatch]);

    const databaseOptions = React.useMemo(() => {
        return databaseList?.map(db => ({
            ...db,
            label: db?.name,
            value: db?.id,
            icon: () => <DatabaseSVG />
        }));
    }, [databaseList]);

    const gridOptions = React.useMemo(() => {
        return databaseList
            ?.filter(db => {
                return databaseIds?.includes(db?.id);
            })
            ?.map(db => ({
                label: db?.name,
                options: db?.grids?.map(grid => ({
                    ...grid,
                    label: grid?.name,
                    value: grid?.id,
                    dbId: db?.id,
                    icon: () => <GridIcon size="small" grid={grid} />
                }))
            }));
    }, [databaseIds, databaseList]);

    const gridLength = React.useMemo(() => {
        return gridOptions?.reduce((arr, o) => {
            return arr.concat(o?.options);
        }, [])?.length;
    }, [gridOptions]);

    const fetchRecords = React.useCallback(
        ({ advancedSearchId, lastId }) => {
            // const filters = {};
            // const sorts = {};

            console.log('hehe', advancedSearchId);

            dispatch(
                advancedSearchActions.fetchAdvancedSearchRecords({
                    wsId: workspaceId,
                    advancedSearchId,
                    body: {
                        limit: ADVANCED_SEARCH_RECORD_LIMIT,
                        lastId
                    },
                    success: () => {
                        console.log('fetchAdvancedSearchRecords success');
                    },
                    error: () => {
                        console.log('fetchAdvancedSearchRecords failed');
                    }
                })
            );
        },
        [dispatch, workspaceId]
    );

    const fetchAdvancedSearchDetail = React.useCallback(
        (advancedSearchId, takeSnapshot = false) => {
            dispatch(
                advancedSearchActions.fetchAdvancedSearch({
                    wsId: workspaceId,
                    advancedSearchId,
                    takeSnapshot,
                    success: advancedSearch => {
                        const selectedDs = advancedSearch?.dbIds?.map(dbId => ({
                            label: dbId,
                            value: dbId,
                            icon: () => <DatabaseSVG />
                        }));
                        setDatabases(selectedDs);

                        const selectedGrids = advancedSearch?.gridIds?.map(option => {
                            const [dbId, gridId] = option?.split('.');
                            return {
                                label: gridId,
                                value: gridId,
                                dbId: dbId
                            };
                        });
                        setGrids(selectedGrids);

                        const selectedColumns = advancedSearch?.columns?.map(col => {
                            let columnDetail = {
                                ...col?.details?.[0],
                                type: col?.type,
                                metadata: col?.details?.reduce((obj, col) => {
                                    obj[col?.columnId] = col;
                                    return obj;
                                }, {})
                            };

                            if ([columnTypes.RECORD_ID, columnTypes.PATH_TAG]?.includes(columnDetail?.columnId)) {
                                columnDetail = {
                                    ...columnDetail,
                                    type: columnDetail?.columnId
                                };
                            }

                            return {
                                ...columnDetail,
                                label: col?.columnName,
                                value: columnDetail?.columnId,
                                hashColumnId: col?.hashColumnId,
                                name: col?.columnName
                            };
                        });
                        dispatch(advancedSearchActions.setSelectedColumns(selectedColumns));
                    },
                    error: () => {
                        console.log('fetchAdvancedSearch failed');
                    }
                })
            );
        },
        [dispatch, workspaceId]
    );

    React.useEffect(() => {
        if (selectedAdvancedSearchId) {
            fetchRecords({ advancedSearchId: selectedAdvancedSearchId });
            fetchAdvancedSearchDetail(selectedAdvancedSearchId, true);
        }
    }, [selectedAdvancedSearchId, fetchRecords, fetchAdvancedSearchDetail]);

    React.useEffect(() => {
        if (workspaceId) {
            dispatch(workspaceActions.fetchWorkspaceDetail({ workspaceId }));
        }
    }, [workspaceId, dispatch]);

    React.useEffect(() => {
        if (grids?.length) {
            dispatch(
                advancedSearchActions.fetchColumns({
                    wsId: workspaceId,
                    body: {
                        items: grids?.map(grid => ({
                            dbId: grid?.dbId,
                            gridId: grid?.value
                        }))
                    },
                    success: () => {},
                    error: () => {}
                })
            );
        }
    }, [grids, databases, dispatch, workspaceId]);

    const handleCreateAdvancedSearch = React.useCallback(() => {
        const items = columns?.map(col => {
            const option = columnOptions?.find(opt => opt?.columnIds?.includes(col?.columnId));

            const id = option?.hashColumnId || col?.hashColumnId;
            return {
                id,
                width: columnWidthStore?.[col?.hashColumnId] || DEFAULT_COLUMN_WIDTH
            };
        });

        dispatch(
            advancedSearchActions.createAdvancedSearch({
                wsId: workspaceId,
                body: {
                    items: items,
                    name: 'New query',
                    searchTerm
                },
                error: () => {},
                success: advancedSearchId => {
                    fetchRecords({ advancedSearchId });
                    fetchAdvancedSearchDetail(advancedSearchId);
                }
            })
        );
    }, [
        dispatch,
        workspaceId,
        columns,
        columnOptions,
        searchTerm,
        fetchAdvancedSearchDetail,
        fetchRecords,
        columnWidthStore
    ]);

    const isSelectAllColumns = React.useMemo(() => {
        return columns?.length && columns?.length === columnOptions?.length;
    }, [columns, columnOptions]);

    const isSelectAllDatabases = React.useMemo(() => {
        return databases?.length && databases?.length === databaseOptions?.length;
    }, [databases, databaseOptions]);

    const isSelectAllGrids = React.useMemo(() => {
        return grids?.length && grids?.length === gridLength;
    }, [grids, gridLength]);

    const actualSelectedColumns = React.useMemo(() => {
        return columns?.map(column => ({
            ...column,
            value: columnOptions?.find(col => col?.columnIds?.includes(column?.columnId))?.value || column?.columnId
        }));
    }, [columns, columnOptions]);

    const handleKeyDown = e => {
        if (!isKbEnter(e)) return;
        if (e?.target?.value) {
            handleCreateAdvancedSearch();
        }
    };

    const handleCloseConfirm = React.useCallback(() => {
        dispatch(advancedSearchActions.setOpenConfirmation({ route: null, open: false }));
    }, [dispatch]);

    const handleAgree = React.useCallback(() => {
        if (nextAdvancedSearchId) {
            history.push(`${url}/${nextAdvancedSearchId}`);
            dispatch(advancedSearchActions.setSearch(''));
            dispatch(advancedSearchActions.resetGrid(['list', `sharedList`, 'routeInfo', 'tab']));
            dispatch(advancedSearchActions.setSelectedAdvancedSearchId(nextAdvancedSearchId));
        } else {
            dispatch(advancedSearchActions.setClearGrid(true));
            dispatch(advancedSearchActions.resetGrid(['list', `sharedList`, 'routeInfo', 'clearGrid', 'tab']));
            history.push(`/p/${workspaceId}/s`);
        }

        dispatch(advancedSearchActions.setOpenConfirmation({ route: null, open: false }));
    }, [dispatch, history, nextAdvancedSearchId, url, workspaceId]);

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.mb2}>
                <Grid container alignItems="center" direction="row" spacing={2}>
                    <Grid item>
                        <Grid container wrap="nowrap" direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography noWrap variant="h3">
                                    {selectedAdvancedSearch?.name || advancedSearch?.name || `New query`}
                                </Typography>
                            </Grid>
                            {isDirty && (
                                <Grid
                                    style={{ position: 'relative', top: 1 }}
                                    item
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <div style={{ width: 12, height: 1, backgroundColor: theme.colors.sun }}></div>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="caption"
                                            style={{ fontWeight: 'bold', color: theme.colors.sun }}
                                        >
                                            Edited
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {isHasManageAdvancedSearchPermission && (
                        <Grid item>
                            <ManageSave searchTerm={searchTerm} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item className={`${classes.expand} ${isBlockedGrid && classes.disabled}`}>
                <Grid container justifyContent="space-between" alignItems="center" direction="row">
                    <Grid item className={classes.config}>
                        <Grid container justifyContent="space-around" spacing={4} alignItems="center" direction="row">
                            <Grid item xs={3}>
                                <LDBasePortal
                                    ddPlaceholder={'Select databases'}
                                    menuPlaceholder={'Find database'}
                                    options={databaseOptions}
                                    onChange={options => {
                                        setDatabases(options);
                                        setGrids([]);
                                        dispatch(advancedSearchActions.setSelectedColumns([]));
                                    }}
                                    defaultValue={databases}
                                    isMulti={true}
                                    dropdownClassName={classes.dropdown}
                                    isUsingChildren={true}
                                    closedOnSelect={false}
                                    isAll={databaseOptions?.length > 0}
                                    onSelectAll={setOpenState => {
                                        if (isSelectAllDatabases) {
                                            setDatabases([]);
                                        } else {
                                            setDatabases(databaseOptions);
                                        }

                                        setOpenState(false);
                                        setGrids([]);
                                        dispatch(advancedSearchActions.setSelectedColumns([]));
                                    }}
                                    selectAllText={isSelectAllDatabases ? 'Unselect all' : 'Select all'}
                                    disabledMenu={!isHasManageAdvancedSearchPermission}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        className={classes.control}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <Grid container wrap="nowrap" spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body2">Select database</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.number} variant="body2">
                                                        {isSelectAllDatabases ? 'All' : databases?.length}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.arrow}>
                                            <ArrowDownSVG />
                                        </Grid>
                                    </Grid>
                                </LDBasePortal>
                            </Grid>
                            <Grid item xs={3}>
                                <LDBasePortalGroup
                                    ddPlaceholder={'Select grids'}
                                    menuPlaceholder={'Find grid'}
                                    options={gridOptions}
                                    onChange={options => {
                                        setGrids(options);
                                        dispatch(advancedSearchActions.setSelectedColumns([]));
                                    }}
                                    closedOnSelect={false}
                                    defaultValue={grids}
                                    isMulti={true}
                                    dropdownClassName={classes.dropdown}
                                    isUsingChildren={true}
                                    isDisabled={!databases?.length}
                                    isAll={gridOptions?.length > 0}
                                    onSelectAll={setOpenState => {
                                        if (isSelectAllGrids) {
                                            setGrids([]);
                                        } else {
                                            const allGrids = gridOptions?.reduce((a, opt) => {
                                                a = a?.concat(opt?.options);
                                                return a;
                                            }, []);
                                            setGrids(allGrids);
                                        }

                                        setOpenState(false);
                                        dispatch(advancedSearchActions.setSelectedColumns([]));
                                    }}
                                    selectAllText={isSelectAllGrids ? 'Unselect all' : 'Select all'}
                                    disabledMenu={!isHasManageAdvancedSearchPermission}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        className={classes.control}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <Grid container wrap="nowrap" spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body2">Select grid</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.number} variant="body2">
                                                        {isSelectAllGrids ? 'All' : grids?.length}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.arrow}>
                                            <ArrowDownSVG />
                                        </Grid>
                                    </Grid>
                                </LDBasePortalGroup>
                            </Grid>
                            <Grid item xs={3}>
                                <LDBasePortal
                                    ddPlaceholder={'Select columns'}
                                    menuPlaceholder={'Find column'}
                                    options={columnOptions}
                                    onChange={options => {
                                        dispatch(advancedSearchActions.setSelectedColumns(options));
                                    }}
                                    defaultValue={actualSelectedColumns}
                                    isMulti={true}
                                    dropdownClassName={classes.dropdown}
                                    isUsingChildren={true}
                                    isDisabled={!databases?.length && !grids?.length}
                                    disabledMenu={!isHasManageAdvancedSearchPermission}
                                    closedOnSelect={false}
                                    isAll={columnOptions?.length > 0}
                                    onSelectAll={setOpenState => {
                                        setOpenState(false);
                                        if (isSelectAllColumns) {
                                            dispatch(advancedSearchActions.setSelectedColumns([]));
                                        } else {
                                            dispatch(advancedSearchActions.setSelectedColumns(columnOptions));
                                        }
                                    }}
                                    selectAllText={isSelectAllColumns ? 'Unselect all' : 'Select all'}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        className={classes.control}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        wrap="nowrap"
                                    >
                                        <Grid item>
                                            <Grid container wrap="nowrap" spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body2">Select column</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.number} variant="body2">
                                                        {isSelectAllColumns ? 'All' : columns?.length}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.arrow}>
                                            <ArrowDownSVG />
                                        </Grid>
                                    </Grid>
                                </LDBasePortal>
                            </Grid>
                            <Grid item xs={3}>
                                <Search
                                    name="searchTerm"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    placeholder={'Words to find'}
                                    className={classes.search}
                                    disabled={!isHasManageAdvancedSearchPermission || !columns?.length}
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {isHasManageAdvancedSearchPermission && (
                        <Grid item>
                            <ButtonBase
                                width={200}
                                disabled={!columns?.length || isFetching}
                                variant="contained"
                                onClick={handleCreateAdvancedSearch}
                            >
                                Search
                            </ButtonBase>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Dialog open={isOpenConfirmation} onClose={handleCloseConfirm}>
                <ConfirmBox
                    title={'Confirmation'}
                    body={
                        <Typography component="div" variant="body2">
                            Data unsaved. Are you sure to leave?
                        </Typography>
                    }
                    handleCancel={handleCloseConfirm}
                    onClose={handleCloseConfirm}
                    handleAgreed={handleAgree}
                    disagreeLabel="No"
                    agreeLabel="Yes"
                />
            </Dialog>
        </Grid>
    );
}

export default Controls;
