import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LDBasePortal from 'components/selects/LDBasePortal';
import { METHOD_TYPES } from 'const/gridUI';
import InputText from 'components/inputs/InputText';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { useDispatch } from 'react-redux';
import { updateActionNode } from '../../action';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { ACTION_STATUS } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import { DISABLED_OPACITY } from 'const/style';
import Payloadv2 from './Payloadv2';
import OutputFieldsWebRequest from './OutputFieldsWebRequest';
import Postbacks from './Postbacks';

const useStyles = makeStyles(theme => ({
    root: {},
    flex: { display: 'flex' },
    mb12: {
        marginBottom: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    ml4: {
        marginLeft: 4
    },
    ml12: {
        marginLeft: 12
    },
    addHeader: {
        color: theme.palette.primary.main
    },
    ddPlaceholderClassname: {
        fontSize: 14
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

const ActionWebRequest = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const timeout = useRef();
    const emptyHeader = { key: '', value: '' };
    const automationDetail = useGetAutomationDetail();
    const { params = {} } = selectedNode;
    const [action, setAction] = useState(selectedNode.action);
    const [url, setUrl] = useState(params.url);
    const [headers, setHeaders] = useState(
        Object.keys(params.headers || {}).length > 0
            ? Object.keys(params.headers).map(key => {
                  return { key, value: params.headers[key] };
              })
            : [emptyHeader]
    );
    const nodeError = useGetAutomationDetailError(selectedNode.id);

    const errorMessage = nodeError?.url || '';

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const METHOD_OPTIONS = useMemo(() => {
        return Object.keys(METHOD_TYPES).map(key => {
            return {
                label: key,
                value: key
            };
        });
    }, []);

    useEffect(() => {
        setAction(selectedNode.action);
        setUrl(params.url || '');
        setHeaders(
            Object.keys(params.headers || {}).length > 0
                ? Object.keys(params.headers).map(key => {
                      return { key, value: params.headers[key] };
                  })
                : [emptyHeader]
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const onMethodChange = useCallback(
        value => {
            const oldAction = action;
            setAction(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            action: value.toLocaleLowerCase()
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setAction(oldAction);
                        }
                    })
                );
            }, 100);
        },
        [action, automationDetail.id, dispatch, selectedNode.id, setSelectedNode]
    );

    const onUrlChange = useCallback(
        e => {
            const value = e.target.value;
            setUrl(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                url: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 800);
        },
        [dispatch, automationDetail.id, selectedNode, setSelectedNode]
    );

    const updateHeaders = useCallback(
        (newHeaders, timeoutSecond = 800) => {
            const oldHeaders = JSON.parse(JSON.stringify(headers));
            setHeaders(newHeaders.length ? [...newHeaders] : [emptyHeader]);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                headers: newHeaders.reduce((acc, cur) => {
                                    acc[cur.key] = cur.value;
                                    return acc;
                                }, {})
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setHeaders(oldHeaders);
                        }
                    })
                );
            }, timeoutSecond);
        },
        [automationDetail.id, dispatch, emptyHeader, headers, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const onHeaderKeyChange = useCallback(
        (value, idx) => {
            headers[idx].key = value;
            updateHeaders(headers);
        },
        [headers, updateHeaders]
    );

    const onHeaderValueChange = useCallback(
        (value, idx) => {
            headers[idx].value = value;
            updateHeaders(headers);
        },
        [headers, updateHeaders]
    );

    const onHeaderRemove = useCallback(
        idx => {
            headers.splice(idx, 1);
            updateHeaders(headers, 100);
        },
        [headers, updateHeaders]
    );

    const onHeaderAdd = useCallback(() => {
        headers.push(emptyHeader);
        updateHeaders(headers, 100);
    }, [emptyHeader, headers, updateHeaders]);

    return (
        <Grid item container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item className={classes.mb14}>
                <p className="body1">{t('automation_http_method')}</p>
            </Grid>
            <Grid item className={classes.mb24}>
                <TooltipActionDisable isAction disabled={isDisabled}>
                    <LDBasePortal
                        menuPlaceholder={t('automation_find_a_method')}
                        options={METHOD_OPTIONS}
                        onChange={option => onMethodChange(option.value)}
                        defaultValue={
                            action && { label: action.toLocaleUpperCase(), value: action.toLocaleUpperCase() }
                        }
                        isMulti={false}
                        isUsingContainer
                        ddPlaceholderClassname={classes.ddPlaceholderClassname}
                    />
                </TooltipActionDisable>
            </Grid>
            <Grid item className={classes.mb24}>
                <InputText
                    name="url"
                    label="URL"
                    value={url}
                    error={Boolean(errorMessage)}
                    errorText={errorMessage}
                    onChange={onUrlChange}
                    placeholder={t('automation_webhook_url')}
                    disableErrorAbsolute
                    labelClassName={classes.mb14}
                    inputWrapperClassName={isDisabled ? classes.disabled : ''}
                    tooltipTitle={isDisabled ? t('automation_tooltip_action_disable') : ''}
                    placement="left"
                    required
                />
            </Grid>
            <Grid item className={classes.mb14}>
                <p className="body1">{t('automation_header_optional')}</p>
            </Grid>
            <TooltipActionDisable isAction disabled={isDisabled}>
                <Grid item>
                    {headers?.map((header, index) => {
                        return (
                            <Grid key={index} item container alignItems="center" className={classes.mb12}>
                                <Grid xs item>
                                    <InputText
                                        name="headerKey"
                                        value={header.key}
                                        onChange={e => onHeaderKeyChange(e.target.value, index)}
                                        placeholder="Enter key"
                                    />
                                </Grid>
                                <Grid xs item className={classes.ml4}>
                                    <InputText
                                        name="headerValue"
                                        value={header.value}
                                        onChange={e => onHeaderValueChange(e.target.value, index)}
                                        placeholder="Enter value"
                                    />
                                </Grid>
                                <Grid item style={{ width: 20 }}>
                                    <IconButton size="small" onClick={() => onHeaderRemove(index)}>
                                        <CloseIconSVG />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item container spacing={1} className={classes.mb24}>
                    <Grid item className={classes.flex} onClick={onHeaderAdd} style={{ cursor: 'pointer' }}>
                        <ColorAddSVG />
                    </Grid>
                    <Grid item onClick={onHeaderAdd} style={{ cursor: 'pointer' }}>
                        <p className="body2 inline text-text-primary font-semibold">{t('automation_add_header')}</p>
                    </Grid>
                </Grid>
            </TooltipActionDisable>
            <Grid item className={classes.mb24}>
                <Payloadv2 selectedNode={selectedNode} setSelectedNode={setSelectedNode} showHowToCustomize />
            </Grid>
            <Grid item className={classes.mb24}>
                <OutputFieldsWebRequest selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
            </Grid>
            <Grid item>
                <Postbacks selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
            </Grid>
        </Grid>
    );
};

export default React.memo(ActionWebRequest);
