import React from 'react';
import { useTheme } from '@material-ui/core/styles';
// import { useSelected, useFocused } from 'slate-react';

const Func = ({ attributes, children, element }) => {
    // const selected = useSelected();
    // const focused = useFocused();
    const theme = useTheme();
    return (
        <span
            {...attributes}
            contentEditable={false}
            style={{
                display: 'inline-block',
                borderRadius: '4px',
                fontSize: 14,
                fontFamily: 'Roboto',
                color: theme.colors.lightCyan
                // boxShadow: selected && focused ? `0 0 0 2px ${theme.colors.dodgerBlue}` : 'none'
            }}
        >
            {element.suggestion?.label}
            {children}
        </span>
    );
};

export default Func;
