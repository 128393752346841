import * as types from '../types';
import * as optimisticActions from './optimistic';
import uuidv1 from 'uuid/v1';
import { setViewRecords, getColumnsRecordsApi, getCurrentRecordOffsetApi, getExistViewRecordsApi } from 'services/view';
import { uploadFileForCellApi, deleteFileOfCellApi } from 'services/grid';
import { enqueueSnackbar } from 'notifier/actions';
import chunk from 'lodash/chunk';
import { getServerValueOfSpecialType, getCellData, getCellValueOnly, getCellServerValue } from 'utils/gridUI/cell';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import { setTriggerRefreshRecordHistory } from './recordHistory';
import {
    DATA_QUERY_OPTIONS,
    MAX_SELECTION_RECORDS,
    USER_REALTIME_ACTION,
    MAX_RECORD_LIMIT,
    RANGE_TYPES
} from 'const/gridUI';
import { removeArrayInArray, generateRangeNumber } from 'utils/object';
import * as statusActions from './status';
import {
    getDisabledColumnIds,
    getDisableColumnIdsByType,
    getReferenceDisabledColumns,
    getViewColumnsWithReorderAndLanguagePairs
} from 'utils/gridUI/column';
import * as TMActions from './tm';
import * as realtimeUserActions from './userActiveActions';
import * as rowActions from './row';
import * as dataActions from './data';
import { formatUserRealtimeBody } from 'utils/user';
import { SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG } from 'const';
import * as appActions from 'app/actions';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { combinedData } from 'utils/gridUI/data';
import * as rangeActions from './range';
import { generateDeletedRangeData, findSelectionByRange } from 'utils/gridUI/range';
import { isEmpty } from 'lodash';
import { ignoreErrorApi, ignoreSimilarErrorsApi } from 'services/autoQA';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';

export function updateLastScrollPosition({ currentScrollTop, currentScrollLeft }) {
    return {
        type: types.UPDATE_LAST_SCROLL_POSITION,
        payload: {
            currentScrollTop,
            currentScrollLeft
        }
    };
}
export function uploadFileForCell({ columnId, rowId, formData, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, gridId, data, recordHistoryId } = gridUI;
        const actionId = uuidv1();

        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            const cellData = getCellData({ data, columnId, rowId });
            const file = await uploadFileForCellApi({ formData, dbId, gridId, rowId, columnId });
            const newRowData = [file, ...(cellData?.value || [])];

            dispatch(_updateCellValueOnly({ columnId, rowId, value: newRowData }));

            if (recordHistoryId === rowId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _updateCellValueOnly({ rowId, columnId, value }) {
    return {
        type: types.UPDATE_CELL_VALUE_ONLY,
        payload: {
            rowId,
            columnId,
            value
        }
    };
}

export function deleteFileOfCell({ columnId, rowId, fileId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, gridId, data, recordHistoryId } = gridUI;
        const cellData = getCellData({ data, columnId, rowId });
        const files = cellData?.value || [];

        const newRowData = files?.filter(fileMeta => fileMeta?.id !== fileId);

        dispatch(statusActions.registerDoingAction({ actionId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_DELETE_FILE_OF_CELL,
                body: {
                    cellData,
                    columnId,
                    rowId
                }
            })
        );

        dispatch(_deleteFileOfCellAction({ columnId, rowId, value: newRowData }));
        try {
            await deleteFileOfCellApi({ fileId, dbId, gridId, recordId: rowId, columnId });
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));

            if (recordHistoryId === rowId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteFileOfCellAction({ columnId, rowId, value }) {
    return {
        type: types.DELETE_FILE_OF_CELL,
        payload: {
            columnId,
            rowId,
            value
        }
    };
}

export function deleteCells() {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            viewColumns,
            metaData,
            recordHistoryId
        } = gridUI;

        const referenceDisabledColumns = getReferenceDisabledColumns({ gridUI, auth });

        dispatch(statusActions.registerDoingAction({ actionId }));

        const {
            columnIds: rangeColumnIds,
            recordIds,
            data,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length || !rangeColumnIds?.length) {
            return dispatch(statusActions.registerDoingAction({ actionId }));
        }

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            disabledSourceColumns,
            processingColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });

        const remainColumnCanDelete = removeArrayInArray(rangeColumnIds, [
            ...disabledCols,
            ...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG
        ]);

        if (!remainColumnCanDelete?.length) {
            console.log('Can not delete disabled columns');
            dispatch(statusActions.registerDoingAction({ actionId }));
            return;
        }

        const isDeleteSingle = recordIds?.length === 1 && remainColumnCanDelete?.length === 1;

        const newColumns = ['_recordId', ...remainColumnCanDelete];

        const { undoData, redoData, serverData } = generateDeletedRangeData({
            recordIds,
            columnIds: remainColumnCanDelete,
            data
        });

        const refColumnIds = remainColumnCanDelete?.filter(colId => {
            const column = metaData?.[colId];
            return column?.type === columnTypes.REFERENCE;
        });

        dispatch(dataActions.updateData({ newData: redoData }));

        if (isDeleteSingle) {
            dispatch(_deleteCellInfo({ recordId: recordIds?.[0], columnId: remainColumnCanDelete?.[0] }));
        }

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_DELETE_RANGE_SELECTION,
                body: {
                    oldDeletedData: undoData
                }
            })
        );

        try {
            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await _updateCellInfo({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: newColumns,
                            records
                        }
                    });
                })
            );

            if (refColumnIds?.length) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds,
                        recordIds
                    })
                );
            }

            if (recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

function _deleteCellInfo({ recordId, columnId }) {
    return {
        type: types.DELETE_CELL_INFO,
        payload: {
            recordId,
            columnId
        }
    };
}

export function undoRedoDeleteRangeSelection({ data, columnIds }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId, recordHistoryId, metaData } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        //generate Columns Server
        const newColumns = ['_recordId', ...columnIds];

        //generate Records Server
        const rowIds = Object.keys(data);
        const recordsFormatted = rowIds?.map(rowId => {
            let recordData = columnIds.map(columnId => getCellServerValue({ data, rowId, columnId, metaData })) || [];
            return [rowId, ...recordData];
        });

        const isDeleteSingle = rowIds?.length === 1 && columnIds?.length === 1;

        dispatch(dataActions.updateData({ newData: data, isCareData: true }));

        if (isDeleteSingle) {
            const rowId = rowIds?.[0];
            const columnId = columnIds?.[0];
            dispatch(_cellClickAwayAndStay({ value: getCellValueOnly({ data, rowId, columnId }), rowId, columnId }));
        }

        try {
            const updateChunks = chunk(recordsFormatted, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await _updateCellInfo({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: newColumns,
                            records
                        }
                    });
                })
            );

            if (recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function enableSelecting() {
    return {
        type: types.ENABLE_SELECTING
    };
}

export function stoppingCellSelection() {
    return {
        type: types.STOPPING_CELL_SELECTION
    };
}

const REGEX_LAST_NUMBER = /\d+$/;

function _findGapInFillCorner({ rowIds, data, columnIds, metaData }) {
    const result = {};

    for (const colIndex in columnIds) {
        const columnId = columnIds?.[colIndex];
        const columnType = metaData?.[columnId]?.type;

        const isNotText = ![
            columnTypes.TRANSLATION,
            columnTypes.MULTIPLE_LINES,
            columnTypes.SINGLE_LINE,
            columnTypes.HTML,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT,
            columnTypes.RECORD_ID,
            columnTypes.YAML,
            columnTypes.NUMBER
        ]?.includes(columnType);

        if (isNotText) {
            continue;
        }

        const isNumber = columnType === columnTypes.NUMBER;

        if (!result?.[colIndex]) {
            result[colIndex] = {
                increaseIndexes: []
            };
        }

        for (const [rowIndex, rowId] of rowIds.entries()) {
            let cell = data?.[rowId]?.[columnId]?.value;
            let previousCell = data?.[rowIds?.[rowIndex - 1]]?.[columnId]?.value;

            if (isNumber) {
                cell = !cell ? '' : cell + '';
                previousCell = !previousCell ? '' : previousCell + '';
            }

            if (previousCell) {
                const previousMatches = previousCell?.match(REGEX_LAST_NUMBER);
                const currentMatches = cell?.match(REGEX_LAST_NUMBER);

                if (currentMatches?.length && previousMatches?.length) {
                    //check previous the same content
                    const currentMatchNumber = currentMatches?.[0];
                    const previousMatchNumber = previousMatches?.[0];
                    const previousContent = previousCell?.slice(0, previousCell?.length - previousMatchNumber?.length);
                    const currentContent = previousCell?.slice(0, cell?.length - currentMatchNumber?.length);

                    const gapNumber = +currentMatchNumber - +previousMatchNumber;

                    const increase = gapNumber < 0 ? -1 : 1;
                    const gap = Math.max(gapNumber);

                    if (previousContent === currentContent) {
                        result[colIndex][rowIndex] = gap;
                        result[colIndex][rowIndex - 1] = gap;
                        result[colIndex].increaseIndexes = result[colIndex].increaseIndexes.concat([
                            rowIndex - 1,
                            rowIndex
                        ]);

                        result[colIndex].lastIncreaseValue = +currentMatchNumber;
                    } else {
                        result[colIndex][rowIndex] = 1 * increase;
                    }
                } else {
                    result[colIndex][rowIndex] = 1;
                }
            } else {
                result[colIndex][rowIndex] = 1;
            }
        }
    }

    return result;
}

function _replaceWorldWithLeadingZero(token, gap) {
    const tokenLength = token?.length;
    const gapString = gap + '';
    const gapLength = gapString?.length;

    const isNotStartsWith0 = !token?.startsWith('0');

    if (gapLength <= tokenLength && isNotStartsWith0) {
        return gapString;
    }

    if (gapLength >= tokenLength) return gapString;

    return token?.slice(0, tokenLength - gapLength) + gapString;
}

function _increaseIndex(value, gap, isReplace, columnType) {
    const isNumber = columnType === columnTypes.NUMBER;
    if (isNumber) {
        value = !value ? '' : value + '';
    }

    const matches = value?.match(REGEX_LAST_NUMBER);

    if (!matches?.length) return value;

    const replaceValue = value?.replace(
        REGEX_LAST_NUMBER,
        (value,
        (p1, token) => {
            const gapNumber = isReplace ? gap : +p1 + gap;

            return _replaceWorldWithLeadingZero(p1, isNumber ? gapNumber : Math.abs(gapNumber));
        })
    );

    return isNumber ? +replaceValue : replaceValue;
}

export function stopCellCopySelection(e) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            recordHistoryId,
            cellCopySelection,
            viewColumns,
            rowStartIndex: cellRangeRowStartIndex,
            rowStopIndex: cellRangeRowStopIndex,
            metaData,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            quickFilters,
            quickSorts
        } = gridUI;
        const referenceDisabledColumns = getReferenceDisabledColumns({ gridUI, auth });

        const { isCtrl } = getStatusCtrlOrShiftKey(e);
        dispatch(statusActions.registerDoingAction({ actionId }));

        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        const { rowStartIndex, rowStopIndex } = cellCopySelection;

        const _rangeRowStartIndex = Math.min(cellRangeRowStartIndex, cellRangeRowStopIndex);
        const _rangeRowStopIndex = Math.max(cellRangeRowStartIndex, cellRangeRowStopIndex);

        if (+rowStartIndex === +rowStopIndex) {
            dispatch(_stopCellCopySelection());
            dispatch(statusActions.removeDoingAction({ actionId }));

            return;
        }

        const { recordIds, columnIds, data, isOverRecordLimit, totalSelectedRecords } = await dataActions.getRangeData({
            auth,
            gridUI,
            dataOptions: [DATA_QUERY_OPTIONS.DATA, DATA_QUERY_OPTIONS.COLOR],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });

        const affectedColumnIds = removeArrayInArray(columnIds, [
            ...disabledCols,
            ...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG?.filter(colId => colId !== columnTypes.RECORD_ID)
        ]);

        let isReverse = false;

        if (rowStartIndex < cellRangeRowStartIndex) {
            isReverse = true;
        }

        const newMinRowIndex = isReverse ? rowStartIndex : +_rangeRowStopIndex + 1;
        const newMaxRowIndex = isReverse ? +_rangeRowStartIndex - 1 : rowStopIndex;

        const gapMapping = !isCtrl
            ? {}
            : _findGapInFillCorner({
                  rowIds: isReverse ? recordIds?.reverse() : recordIds,
                  columnIds: affectedColumnIds,
                  data,
                  metaData
              });

        console.log('gapMapping', gapMapping);

        try {
            const ranges = generateRangeNumber(newMinRowIndex, newMaxRowIndex);
            const patches = chunk(ranges, MAX_RECORD_LIMIT);

            let dragRecordIds = [];
            let holdData = {};

            const response = await Promise.all(
                patches?.map(async patch => {
                    const offset = patch?.[0];
                    const limit = patch?.[patch?.length - 1] + 1;
                    return await getColumnsRecordsApi({
                        dbId,
                        defaultAccessViewId,
                        offset,
                        limit,
                        columnIds: affectedColumnIds,
                        filterQuery: quickFiltersFormatted,
                        sortQuery: quickSorts,
                        dataOptions: [DATA_QUERY_OPTIONS.DATA, DATA_QUERY_OPTIONS.COLOR]
                    });
                })
            );

            for (const res of response) {
                const recordIdsRes = res?.recordIds || [];
                const dataRes = res?.data;
                dragRecordIds = dragRecordIds?.concat(recordIdsRes);
                holdData = combinedData({ data: holdData, newData: dataRes });
            }

            const undoData = {};
            const newData = {};
            const affectedRowIds = [];
            const copyRowIds = isReverse ? recordIds?.reverse() : recordIds;
            const dragCopyRecordIdsFormat = isReverse ? dragRecordIds?.reverse() : dragRecordIds;

            for (const rowIndex in dragCopyRecordIdsFormat) {
                const rowId = dragCopyRecordIdsFormat?.[+rowIndex];
                affectedRowIds.push(rowId);
                const columnUndoData = {};

                const rCopiedIndex = rowIndex % copyRowIds?.length;
                const rIndex = Math.floor(rowIndex / copyRowIds?.length) + 1;

                const copyRowId = copyRowIds?.[rCopiedIndex];
                const columnNewData = {};

                for (const columnIndex in affectedColumnIds) {
                    const colObject = gapMapping?.[columnIndex];

                    const keys = !colObject ? [] : Object.keys(colObject);

                    const isIgnoreIncrease = !keys?.length;

                    const gap = gapMapping?.[columnIndex]?.[rCopiedIndex];
                    const isInIncreaseIndexes = colObject?.increaseIndexes?.includes(rCopiedIndex);

                    const lastIncreaseValue = colObject?.lastIncreaseValue;

                    const newGap = isInIncreaseIndexes ? lastIncreaseValue + gap : rIndex;

                    if (isInIncreaseIndexes) {
                        gapMapping[columnIndex] = {
                            ...gapMapping?.[columnIndex],
                            lastIncreaseValue: newGap
                        };
                    }

                    const columnId = affectedColumnIds?.[+columnIndex];
                    const columnType = metaData?.[columnId]?.type;
                    const oldCellData = getCellData({ data: holdData, rowId, columnId });
                    const newCellData = getCellData({ data, rowId: copyRowId, columnId });

                    columnUndoData[columnId] = {
                        ...oldCellData,
                        _color: oldCellData?._color || ''
                    };

                    columnNewData[columnId] = {
                        ...newCellData,
                        value:
                            isIgnoreIncrease || !gap
                                ? newCellData?.value
                                : _increaseIndex(newCellData?.value, newGap, isInIncreaseIndexes, columnType),
                        _ticket: 0,
                        _color: newCellData?._color || ''
                    };
                }
                undoData[rowId] = columnUndoData;
                newData[rowId] = columnNewData;
            }

            dispatch(dataActions.updateData({ newData, isCareData: true }));
            dispatch(_stopCellCopySelection());

            dispatch(
                optimisticActions.commitAction({
                    actionId,
                    type: types.OPTIMISTIC_COPY_CELL_RELEASE,
                    body: {
                        undoData
                    }
                })
            );

            //update server
            const updateChunks = chunk(affectedRowIds, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async patchRowIds => {
                    let updateRecords = [];

                    for (const rowId of patchRowIds) {
                        const columnServerData = [];

                        for (let columnId of affectedColumnIds) {
                            const columnDetail = metaData?.[columnId];
                            const columnType = getCorrectColumnType(columnDetail);
                            const cellValue = getCellData({ data: newData, rowId, columnId });
                            const serverData = getServerValueOfSpecialType({
                                type: columnType,
                                value: cellValue?.value
                            });
                            columnServerData.push(
                                [columnTypes?.PATH_TAG, columnTypes.RECORD_ID].includes(columnType)
                                    ? serverData
                                    : { _dataItem: serverData, _color: cellValue?._color || '' }
                            );
                        }

                        const rowServerData = [rowId, ...columnServerData];
                        updateRecords.push(rowServerData);
                    }

                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...affectedColumnIds],
                            records: updateRecords
                        }
                    });
                })
            );

            if (recordHistoryId && affectedRowIds?.includes(recordHistoryId)) {
                dispatch(setTriggerRefreshRecordHistory());
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.commitAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_stopCellCopySelection());
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }

        dispatch(_stopCellCopySelection());
    };
}

function _stopCellCopySelection() {
    return {
        type: types.STOPPING_CELL_COPY_SELECTION
    };
}

export function selectingCellCopySelection({ rowDragStopIndex }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex } = gridUI;

        const minRowIndex = Math.min(+rowDragStopIndex, +rowStartIndex);
        const maxRowIndex = Math.max(+rowDragStopIndex, +rowStopIndex);
        const minColIndex = +columnStartIndex;
        const maxColIndex = +columnStopIndex;

        dispatch({
            type: types.STARTING_CELL_COPY_SELECTION,
            payload: {
                rowStartIndex: minRowIndex,
                rowStopIndex: maxRowIndex,
                columnStartIndex: minColIndex,
                columnStopIndex: maxColIndex
            }
        });
    };
}

export function selectRangeCell({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, isMoving = false }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            ROW_START_INDEX,
            rows,
            ROW_STOP_INDEX,
            rowIndexMap,
            rowStartIndex: rowStartIndexStore,
            columnStartIndex: columnStartIndexStore
        } = gridUI;

        let rowStartId;
        let rowStopId;

        const rowStartIndexLatest = !isMoving ? rowStartIndex : rowStartIndexStore;
        const rowStopIndexLatest = rowStopIndex;
        const columnStartIndexLatest = !isMoving ? columnStartIndex : columnStartIndexStore;
        const columnStopIndexLatest = columnStopIndex;

        dispatch(
            _selectRangeCell({
                rowStartIndex: rowStartIndexLatest,
                rowStopIndex: rowStopIndexLatest,
                columnStartIndex: columnStartIndexLatest,
                columnStopIndex: columnStopIndexLatest
            })
        );

        if (rowStartIndexLatest >= ROW_START_INDEX && rowStartIndexLatest <= ROW_STOP_INDEX) {
            rowStartId = rows?.[Math.max(rowStartIndexLatest - ROW_START_INDEX, 0)];
        }

        if (rowStopIndexLatest >= ROW_START_INDEX && rowStopIndexLatest <= ROW_STOP_INDEX) {
            rowStopId = rows?.[Math.max(rowStopIndexLatest - ROW_START_INDEX, 0)];
        }

        let newRowIndexMap = {};

        if (rowIndexMap?.hasOwnProperty(rowStartIndexLatest)) {
            newRowIndexMap = {
                [rowStartIndexLatest]: rowIndexMap?.[rowStartIndexLatest]
            };
        }

        if (rowStartId) {
            newRowIndexMap = {
                ...newRowIndexMap,
                [rowStartIndexLatest]: rowStartId
            };
        }

        if (rowStopId) {
            newRowIndexMap = {
                ...newRowIndexMap,
                [rowStopIndexLatest]: rowStopId
            };
        }

        dispatch(
            rangeActions.setRowIndexMap({
                rowIndexMap: newRowIndexMap
            })
        );
    };
}

export function scrollMoreRange({ type = 'row', rowStopIndex, columnStopIndex }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            ROW_START_INDEX,
            rows,
            ROW_STOP_INDEX,
            rowIndexMap,
            rowStartIndex: rowStartIndexStore,
            rowStopIndex: rowStopIndexStore,
            columnStartIndex: columnStartIndexStore,
            columnStopIndex: columnStopIndexStore
        } = gridUI;

        let rowStartId;
        let rowStopId;

        const rowStartIndexLatest = rowStartIndexStore;
        const rowStopIndexLatest = type === 'row' ? rowStopIndex : rowStopIndexStore;
        const columnStartIndexLatest = columnStartIndexStore;
        const columnStopIndexLatest = type === 'row' ? columnStopIndexStore : columnStopIndex;

        dispatch(
            _selectRangeCell({
                rowStartIndex: rowStartIndexLatest,
                rowStopIndex: rowStopIndexLatest,
                columnStartIndex: columnStartIndexLatest,
                columnStopIndex: columnStopIndexLatest
            })
        );

        if (rowStartIndexLatest >= ROW_START_INDEX && rowStartIndexLatest <= ROW_STOP_INDEX) {
            rowStartId = rows?.[Math.max(rowStartIndexLatest - ROW_START_INDEX, 0)];
        }

        if (rowStopIndexLatest >= ROW_START_INDEX && rowStopIndexLatest <= ROW_STOP_INDEX) {
            rowStopId = rows?.[Math.max(rowStopIndexLatest - ROW_START_INDEX, 0)];
        }

        let newRowIndexMap = {};

        if (rowIndexMap?.hasOwnProperty(rowStartIndexLatest)) {
            newRowIndexMap = {
                [rowStartIndexLatest]: rowIndexMap?.[rowStartIndexLatest]
            };
        }

        if (rowStartId) {
            newRowIndexMap = {
                ...newRowIndexMap,
                [rowStartIndexLatest]: rowStartId
            };
        }

        if (rowStopId) {
            newRowIndexMap = {
                ...newRowIndexMap,
                [rowStopIndexLatest]: rowStopId
            };
        }

        dispatch(
            rangeActions.setRowIndexMap({
                rowIndexMap: newRowIndexMap
            })
        );
    };
}

export function _selectRangeCell({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) {
    return function(dispatch, getState) {
        window.dragScrollBar = false;
        const url = new URL(window.location);

        const _rowStartIndex = Math.min(rowStartIndex, rowStopIndex);
        const _rowStopIndex = Math.max(rowStartIndex, rowStopIndex);
        const _columnStartIndex = Math.min(columnStartIndex, columnStopIndex);
        const _columnStopIndex = Math.max(columnStartIndex, columnStopIndex);

        const range = `r${_rowStartIndex + 1}:r${_rowStopIndex + 1}-c${_columnStartIndex + 1}:c${_columnStopIndex + 1}`;
        url.search = `?find=${range}`;
        window.history.pushState({}, '', url);

        dispatch(_setRange({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }));
    };
}

function _setRange({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) {
    return {
        type: types.SELECTING_CELL_SELECTION,
        payload: {
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex
        }
    };
}

export function increaseSelectionLeftRight({ columnStopIndex }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { rowStartIndex, rowStopIndex, columnStartIndex } = gridUI;
        dispatch(selectRangeCell({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }));
    };
}

export function increaseSelectionTopBottom({ rowStopIndex }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { rowStartIndex, columnStopIndex, columnStartIndex } = gridUI;
        dispatch(selectRangeCell({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }));
    };
}

export function resetCellInfo() {
    return {
        type: types.RESET_CELL_INFO
    };
}

export function cellClick({ isShift, rowIndex, columnIndex, columnId, rowId }) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const { rowStartIndex, columnStartIndex } = gridUI;
        const actionId = uuidv1();
        const { currentUser } = auth;

        if (!isShift) {
            dispatch(
                selectRangeCell({
                    rowStartIndex: rowIndex,
                    rowStopIndex: rowIndex,
                    columnStartIndex: columnIndex,
                    columnStopIndex: columnIndex
                })
            );

            dispatch(
                realtimeUserActions.registerRealtimeUser({
                    actionId,
                    body: {
                        action: USER_REALTIME_ACTION.EDIT,
                        rowId,
                        columnId,
                        rowIndex,
                        columnIndex,
                        user: formatUserRealtimeBody(currentUser)
                    }
                })
            );
        } else {
            if (rowStartIndex > -1 && columnStartIndex > -1) {
                dispatch(
                    selectRangeCell({
                        rowStartIndex,
                        rowStopIndex: rowIndex,
                        columnStartIndex,
                        columnStopIndex: columnIndex
                    })
                );
            } else {
                dispatch(
                    selectRangeCell({
                        rowStartIndex: rowIndex,
                        rowStopIndex: rowIndex,
                        columnStartIndex: columnIndex,
                        columnStopIndex: columnIndex
                    })
                );
            }
        }
    };
}

export function openCellEditWithCharacter({ _tm, character }) {
    return function(dispatch, getState) {
        if (_tm) {
            dispatch(TMActions.approveTMStatus());
        }
        dispatch(_setCharacterKey(character));
        dispatch(_openCellEdit());
    };
}

function _setCharacterKey(character) {
    return {
        type: types.SET_CHARACTER_KEY,
        payload: {
            character
        }
    };
}

export function openCellEdit({ columnId, rowId, _tm, rowIndex, columnIndex }) {
    return function(dispatch, getState) {
        const actionId = uuidv1();
        let { auth } = getState();
        const { currentUser } = auth;

        if (_tm) {
            dispatch(TMActions.approveTMStatus());
        }

        dispatch(
            realtimeUserActions.registerRealtimeUser({
                actionId,
                body: {
                    action: USER_REALTIME_ACTION.EDIT,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex,
                    user: formatUserRealtimeBody(currentUser)
                }
            })
        );
        dispatch(_openCellEdit());
    };
}

function _openCellEdit() {
    return {
        type: types.OPEN_CELL_EDIT
    };
}

export function changeBooleanStatus({ columnId, rowId, checked }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();

        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;

        const newColumns = ['_recordId', columnId];
        const defaultValue = getCellValueOnly({ data, rowId, columnId });
        const value = defaultValue === false || !defaultValue ? true : false;
        const updatedRecords = [[rowId, value]];
        const body = {
            columns: newColumns,
            records: updatedRecords
        };
        dispatch(statusActions.registerDoingAction({ actionId }));

        // Fix cell preview not show right current state
        setTimeout(() => {
            dispatch(_changeBooleanStatus({ value, rowId, columnId }));
        }, 0);

        const cellData = getCellData({ data, rowId, columnId });

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body
            });
            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

function _changeBooleanStatus({ value, rowId, columnId }) {
    return {
        type: types.CHANGE_BOOLEAN_STATUS,
        payload: {
            value,
            rowId,
            columnId
        }
    };
}

export function cellClickAway({
    isClickYourSelf,
    value,
    type,
    rowId,
    columnId,
    skipStatusUpdateOnSourceChange,
    validations
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const isShowAutoQA = getIsShowAutoQA();

        const getServerValue = getServerValueOfSpecialType({ type, value });
        const isSettingReference = type === columnTypes.REFERENCE;
        const updatedRecords = [[rowId, getServerValue]];
        const body = {
            columns: newColumns,
            records: updatedRecords,
            skipStatusUpdateOnSourceChange: skipStatusUpdateOnSourceChange || undefined
        };

        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAway({ isClickYourSelf, rowId, columnId, value }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body,
                autoQA: isShowAutoQA
            });

            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: [columnId],
                        recordIds: [rowId]
                    })
                );
            }

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

function _cellClickAway({ columnId, rowId, value, isClickYourSelf }) {
    return {
        type: types.CELL_CLICK_AWAY,
        payload: {
            isClickYourSelf,
            value,
            columnId,
            rowId
        }
    };
}

export function resetCellStatus() {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const { currentUser } = auth;

        const actionId = uuidv1();
        dispatch(
            realtimeUserActions.registerRealtimeUser({
                actionId,
                body: {
                    action: USER_REALTIME_ACTION.RESET,
                    user: formatUserRealtimeBody(currentUser)
                }
            })
        );
        dispatch(_resetCellStatus());
        dispatch(appActions.changeContext({ contextMenuId: null }));
    };
}

function _resetCellStatus() {
    return {
        type: types.RESET_CELL_STATUS
    };
}

export function cellClickAwayAndGoNextRow({
    value,
    rowId,
    columnId,
    rowIndex,
    columnIndex,
    validations,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const isShowAutoQA = getIsShowAutoQA();

        const updatedRecords = [[rowId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };
        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAwayAndStay({ value, rowId, columnId }));
        dispatch(_updateMetadataValidations({ validations, rowId, columnId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToNextRow({ rowIndex, columnIndex }));

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body: dataBody,
                autoQA: isShowAutoQA
            });

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function cellClickAwayAndGoPreviousRow({
    value,
    rowId,
    columnId,
    rowIndex,
    columnIndex,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const updatedRecords = [[rowId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };
        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAwayAndStay({ value, rowId, columnId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToPreviousRow({ rowIndex, columnIndex }));
        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body: dataBody
            });

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function cellClickAwayAndGoNextColumn({ value, rowId, columnId, rowIndex, columnIndex }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const updatedRecords = [[rowId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };
        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAwayAndStay({ value, rowId, columnId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToNextColumn({ rowIndex, columnIndex }));
        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body: dataBody
            });

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function cellClickAwayAndGoPreviousColumn({ value, rowId, columnId, rowIndex, columnIndex }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const updatedRecords = [[rowId, value]];

        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };

        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAwayAndStay({ value, rowId, columnId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToPreviousColumn({ rowIndex, columnIndex }));
        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body: dataBody
            });

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function moveCellToNextColumn({ callback }) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const { rowStartIndex, columnStartIndex } = gridUI;
        const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });
        const maxColumnIndex = viewableColumns?.length - 1;
        dispatch(
            selectRangeCell({
                rowStartIndex: rowStartIndex,
                rowStopIndex: rowStartIndex,
                columnStartIndex: Math.min(columnStartIndex + 1, maxColumnIndex),
                columnStopIndex: Math.min(columnStartIndex + 1, maxColumnIndex)
            })
        );

        callback && callback();
    };
}

export function moveCellToPreviousRow({ callback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { rowStartIndex, columnStartIndex } = gridUI;
        dispatch(
            selectRangeCell({
                rowStartIndex: Math.max(rowStartIndex - 1, 0),
                rowStopIndex: Math.max(rowStartIndex - 1, 0),
                columnStartIndex: columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );

        callback && callback();
    };
}

export function moveCellToNextRow({ callback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { totalRecords, rowStartIndex, columnStartIndex } = gridUI;
        dispatch(
            selectRangeCell({
                rowStartIndex: Math.min(rowStartIndex + 1, totalRecords - 1),
                rowStopIndex: Math.min(rowStartIndex + 1, totalRecords - 1),
                columnStartIndex: columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );

        callback && callback();
    };
}

export function moveCellToPreviousColumn({ callback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { rowStartIndex, columnStartIndex } = gridUI;

        dispatch(
            selectRangeCell({
                rowStartIndex: rowStartIndex,
                rowStopIndex: rowStartIndex,
                columnStartIndex: Math.max(0, columnStartIndex - 1),
                columnStopIndex: Math.max(0, columnStartIndex - 1)
            })
        );

        callback && callback();
    };
}

export function cellClickAwayAndStay({ value, type, rowId, columnId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const isShowAutoQA = getIsShowAutoQA();

        const getServerValue = getServerValueOfSpecialType({ type, value });

        const isSettingReference = type === columnTypes.REFERENCE;

        const updatedRecords = [[rowId, getServerValue]];

        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };
        const cellData = getCellData({ data, columnId, rowId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAwayAndStay({ value, rowId, columnId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body: dataBody,
                autoQA: isShowAutoQA
            });

            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: [columnId],
                        recordIds: [rowId]
                    })
                );
            }

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function _cellClickAwayAndStay({ value, rowId, columnId }) {
    return {
        type: types.CELL_CLICK_AWAY_AND_STAY,
        payload: {
            value,
            rowId,
            columnId
        }
    };
}

export function _updateMetadataValidations({ validations, rowId, columnId }) {
    return {
        type: types.UPDATE_METADATA_VALIDATIONS,
        payload: {
            validations,
            rowId,
            columnId
        }
    };
}

export function cancelCellEdit() {
    return {
        type: types.CANCEL_CELL_EDIT
    };
}

export async function _updateCellInfo({ defaultAccessViewId, dbId, body, autoQA }) {
    try {
        return await setViewRecords({
            defaultAccessViewId,
            dbId,
            body,
            autoQA
        });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function undoUpdateCell({ rowId, columnId, value }) {
    return {
        type: types.UNDO_UPDATE_CELL,
        payload: {
            rowId,
            columnId,
            value
        }
    };
}

export function updateSingleCell({ columnId, rowId, value, isSettingReference }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();
        const { defaultAccessViewId, dbId, metaData, recordHistoryId } = gridUI;
        const column = metaData?.[columnId];
        const columnType = getCorrectColumnType(column);

        const getServerValue = getServerValueOfSpecialType({ type: columnType, value });

        let body = {
            columns: ['_recordId', columnId],
            records: [[rowId, getServerValue]]
        };
        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            await _updateCellInfo({ defaultAccessViewId, dbId, body });

            dispatch(undoUpdateCell({ columnId, rowId, value }));

            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: [columnId],
                        recordIds: [rowId]
                    })
                );
            }

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: `Can not do this action`,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
        }
    };
}

/**
 * value should have to convert before update store
 * @param {Value} param0
 */
export function updateCellValueWithoutCellInfo({ rowId, columnId, value, columnType }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;

        const newColumns = ['_recordId', columnId];
        const getServerValue = getServerValueOfSpecialType({ type: columnType, value });
        const isSettingReference = columnType === columnTypes.REFERENCE;
        const updatedRecords = [[rowId, getServerValue]];

        const body = {
            columns: newColumns,
            records: updatedRecords
        };
        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_updateCellValueWithoutCellInfo({ value, rowId, columnId }));
        // const { isExpandRow, oldRowHeight, newRowHeight } = _isExpandRow({
        //     columnType,
        //     content: value,
        //     columnWidth: getViewColumnWidthByColumnId({ columnId, viewColumns }),
        //     rowHeight: getRowHeightMetadata({ recordMetaData, rowId })
        // });

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                    // isExpandRow,
                    // oldRowHeight
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body
            });

            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: [columnId],
                        recordIds: [rowId]
                    })
                );
            }

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

function _updateCellValueWithoutCellInfo({ value, rowId, columnId }) {
    return {
        type: types.UPDATE_CELL_VALUE_WITHOUT_CELL_INFO,
        payload: {
            value,
            rowId,
            columnId
        }
    };
}

export function updateCellCopyUndoRedo({ data, affectedRowIds, columnIds }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();
        const { metaData, defaultAccessViewId, dbId } = gridUI;

        try {
            dispatch(statusActions.registerDoingAction({ actionId }));
            dispatch(dataActions.updateData({ newData: data, isCareData: true }));

            const updateChunks = chunk(affectedRowIds, MAX_RECORD_LIMIT);
            await Promise.all(
                updateChunks?.map(async patchRowIds => {
                    const updateRecords = [];
                    for (const rowId of patchRowIds) {
                        const columnServerData = [];
                        for (const columnId of columnIds) {
                            const columnDetail = metaData?.[columnId];
                            const columnType = getCorrectColumnType(columnDetail);
                            const serverData = getServerValueOfSpecialType({
                                type: columnType,
                                value: getCellValueOnly({ data, rowId, columnId })
                            });
                            columnServerData.push(serverData);
                        }

                        const rowServerData = [rowId, ...columnServerData];
                        updateRecords.push(rowServerData);
                    }

                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...columnIds],
                            records: updateRecords
                        }
                    });
                })
            );

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.commitAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function searchRecord(isSearchingRecord) {
    return {
        type: types.SEARCHING_RECORD,
        payload: {
            isSearchingRecord
        }
    };
}

export function jumpToRange(range) {
    return async function(dispatch, getState) {
        const { auth, gridUI } = getState();
        try {
            const selection = findSelectionByRange({ range, auth, gridUI, dispatch });
            dispatch(
                selectRangeCell({
                    ...selection
                })
            );
        } catch ({ message }) {
            console.log('jumpToRange error message', message);
        }
    };
}

export function jumpToCell({
    recordId,
    columnId,
    successCallback,
    errorCallback,
    displayDefaultError = true,
    isResetingCellStatus = true
}) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const { currentView, dbId, quickFilters, quickSorts } = gridUI;
        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        try {
            if (isResetingCellStatus) {
                dispatch(resetCellStatus());
            }
            dispatch(searchRecord(true));
            const res = await getCurrentRecordOffsetApi({
                viewId: currentView?.id,
                dbId,
                recordId,
                filterQuery: quickFiltersFormatted,
                sortQuery: quickSorts
            });

            const rowIndex = res?.offset;
            const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });

            const columnIndex = viewableColumns?.findIndex(column => column?.id === columnId);

            dispatch(
                selectRangeCell({
                    rowStartIndex: rowIndex,
                    rowStopIndex: rowIndex,
                    columnStartIndex: columnIndex,
                    columnStopIndex: columnIndex
                })
            );

            dispatch(searchRecord(false));
            successCallback && successCallback();
        } catch (error) {
            if (displayDefaultError) {
                const { message } = error;
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: "Sorry, Gridly cannot locate the data you're searching for, please try again"
                    })
                );
            }
            dispatch(searchRecord(false));
            errorCallback && errorCallback();
        }
    };
}

export function uploadMultipleFilesForCell({ columnId, rowId, uploadFiles, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, gridId, data } = gridUI;
        const actionId = uuidv1();

        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            const cellData = getCellData({ data, columnId, rowId });
            const files = await Promise.all(
                uploadFiles.map(f => {
                    const formData = new FormData();
                    formData.append('file', f);
                    return uploadFileForCellApi({ formData, dbId, gridId, rowId, columnId });
                })
            );
            const newRowData = [...files, ...(cellData?.value || [])];

            dispatch(_updateCellValueOnly({ columnId, rowId, value: newRowData }));

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback(message);
        }
    };
}

export function revertCellInRecordHistory({ type, value, previousValue, columnId, recordHistoryId }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const getServerValue = getServerValueOfSpecialType({ type, value });
        const isSettingReference = type === columnTypes.REFERENCE;
        const updatedRecords = [[recordHistoryId, getServerValue]];
        const body = {
            columns: newColumns,
            records: updatedRecords
        };

        const previousCellData = { value: previousValue };

        dispatch(jumpToCell({ columnId, recordId: recordHistoryId }));

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_updateCellValueWithoutCellInfo({ rowId: recordHistoryId, columnId, value }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId: recordHistoryId,
                    cellData: previousCellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body
            });

            if (isSettingReference) {
                dispatch(
                    rowActions.fetchOtherReferenceDataColumns({
                        refColumnIds: [columnId],
                        recordIds: [recordHistoryId]
                    })
                );
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function resetSelection() {
    return {
        type: types.RESET_SELECTION
    };
}

export function fetchSingleCellData({ columnId, rowId, successCallback, errorCallback, finallyCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewColumns, dbId, defaultAccessViewId } = gridUI;
        const colIds = viewColumns?.filter(col => col?.viewable)?.map(col => col?.id);
        if (!colIds?.includes(columnId)) {
            //Comment is not available in current view
            return;
        }
        try {
            const { data, recordIds } = await getExistViewRecordsApi({
                dbId,
                viewId: defaultAccessViewId,
                columnIds: colIds,
                recordIds: [rowId]
            });
            if (recordIds?.length === 0) {
                //Comment is not available in current view
                return;
            }
            if (!isEmpty(data)) {
                successCallback && successCallback(data?.[rowId]?.[columnId] || null);
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        } finally {
            finallyCallback && finallyCallback();
        }
    };
}

export function openCellContext() {
    return {
        type: types.OPEN_CELL_CONTEXT
    };
}

export function closeCellContext() {
    return {
        type: types.CLOSE_CELL_CONTEXT
    };
}

export function copySource({ columnId, rowId, value, currentCellValue, successCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();
        const { defaultAccessViewId, dbId, metaData, recordHistoryId } = gridUI;
        const column = metaData?.[columnId];
        const columnType = getCorrectColumnType(column);

        const getServerValue = getServerValueOfSpecialType({ type: columnType, value });

        let body = {
            columns: ['_recordId', columnId],
            records: [[rowId, getServerValue]]
        };
        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            await _updateCellInfo({ defaultAccessViewId, dbId, body });

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }

            dispatch(statusActions.removeDoingAction({ actionId }));

            successCallback && successCallback();
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: `Can not do this action`,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
        }
    };
}

function _ignoreAutoQASingleError({ errorId, columnId, rowId }) {
    return {
        type: types.IGNORE_AUTOQA_SINGLE_ERROR,
        payload: {
            errorId,
            columnId,
            rowId
        }
    };
}

export function ignoreAutoQASingleError({ errorId, columnId, rowId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { currentView } = gridUI;
        try {
            const body = { errorId, columnId, recordId: rowId };
            await ignoreErrorApi({ viewId: currentView.id, body });
            dispatch(_ignoreAutoQASingleError({ errorId, columnId, rowId }));

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

// function _ignoreAutoQASimilarErrors({ errorCategory, columnId, rowId }) {
//     return {
//         type: types.IGNORE_AUTOQA_SIMILAR_ERRORS,
//         payload: {
//             errorCategory,
//             columnId
//         }
//     };
// }

export function ignoreAutoQASimilarErrors({ error, rowId, columnId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { currentView } = gridUI;
        try {
            const body = { errorId: error.errorid, columnId, recordId: rowId };
            await ignoreSimilarErrorsApi({ viewId: currentView.id, body });
            // dispatch(_ignoreAutoQASimilarErrors({ errorCategory: error.category, columnId }));

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateCellWhenChooseSpellingSuggestion({ value, rowId, columnId }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { data, defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        const newColumns = ['_recordId', columnId];

        const updatedRecords = [[rowId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };
        const cellData = getCellData({ data, rowId, columnId });

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_cellClickAwayAndStay({ value, rowId, columnId }));

        const newcellData = { ...cellData };
        delete newcellData.metadata.validations;

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData: newcellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId,
                dbId,
                body: dataBody
            });

            if (recordHistoryId && rowId === recordHistoryId) {
                dispatch(setTriggerRefreshRecordHistory());
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function setSelectingCopy(enable) {
    return {
        type: types.SET_SELECTING_COPY,
        payload: {
            enable
        }
    };
}

export function setIsPreviewSelectionRangeFileData(payload) {
    return {
        type: types.SET_IS_PREVIEW_SELECTION_RANGE_FILE_DATA,
        payload
    };
}

export function setSelectionRangeFileData(payload) {
    return {
        type: types.SET_SELECTION_RANGE_FILE_DATA,
        payload
    };
}

export function setIsFetchingSelectionRangeFileData(payload) {
    return {
        type: types.SET_IS_FETCHING_SELECTION_RANGE_FILE_DATA,
        payload
    };
}

export function getCellDataFileType({ fileType = ['pdf'] }) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        try {
            dispatch(setIsFetchingSelectionRangeFileData(true));
            const {
                recordIds,
                columnIds: rangeColumnIds,
                data,
                isOverRecordLimit,
                totalSelectedRecords
            } = await dataActions.getRangeData({
                auth,
                gridUI,
                dataOptions: [DATA_QUERY_OPTIONS.DATA, DATA_QUERY_OPTIONS.COLOR],
                type: RANGE_TYPES.INDEX,
                allowSelectedColumns: true
            });

            if (isOverRecordLimit) {
                dispatch(
                    enqueueSnackbar({
                        type: 'info',
                        message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                    })
                );
                dispatch(setIsFetchingSelectionRangeFileData(false));
                return;
            }

            if (!recordIds?.length || !rangeColumnIds?.length) {
                return dispatch(setIsFetchingSelectionRangeFileData(false));
            }

            const { rowStartIndex, columnStartIndex } = gridUI;
            const fileData = recordIds.reduce((acc, recordId, recordIdx) => {
                const record = data?.[recordId];
                if (record) {
                    rangeColumnIds.forEach((columnId, columnIdx) => {
                        const column = record[columnId];
                        if (Array.isArray(column?.value)) {
                            column.value.forEach(file => {
                                if (
                                    fileType.some(e =>
                                        file?.contentType?.toLocaleLowerCase().includes(e.toLocaleLowerCase())
                                    )
                                ) {
                                    acc.push({
                                        file: {
                                            ...file,
                                            recordId,
                                            columnId,
                                            recordIndex: rowStartIndex + recordIdx + 1,
                                            columnIndex: columnStartIndex + columnIdx + 1
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                return acc;
            }, []);

            if (fileData.length > 0) {
                dispatch(setSelectionRangeFileData(fileData));
                dispatch(setIsPreviewSelectionRangeFileData(true));
            } else {
                dispatch(setIsFetchingSelectionRangeFileData(false));
                dispatch(
                    enqueueSnackbar({
                        message: `Not found ${fileType.join(', ')} in the range selection`,
                        type: 'error'
                    })
                );
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            dispatch(setIsFetchingSelectionRangeFileData(false));
        }
    };
}
