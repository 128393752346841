import React from 'react';

function ZToASVG({ color = '#6E5BBE', ...other }) {
    return (
        <svg width="31" height="10" viewBox="0 0 31 10" fill="none" {...other} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.02863 2.13794V1.2876H0.629944V2.48895H4.18457L0.625 7.61572V8.48584H6.10773V7.29437H2.47894L6.02863 2.13794ZM30.8577 8.48584L28.1633 1.2876H26.7889L24.1094 8.48584H25.6865L26.1808 7.00269H28.7813L29.2806 8.48584H30.8577ZM19.5456 2.4126L21.4564 4.60324L21.4674 4.60294L21.4671 4.61557L22 5.2251L19.5456 8.0376L18.7337 7.10715L19.7705 5.91909L10.75 5.91879V4.60294L19.8322 4.60324L18.7337 3.34304L19.5456 2.4126ZM26.5813 5.80133L27.4761 3.10693L28.3809 5.80133H26.5813Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(ZToASVG);
