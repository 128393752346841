import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
// import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
// import { useIsOverRecords } from 'hooks/payment';
// import LimitPopup from 'payment/components/popups/LimitPopup';
// import ModalBase from 'components/modal/Base';
// import { useCompanyId } from 'hooks/auth';
// import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    addButton: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 65,
            minWidth: 'initial',
            padding: 0
        }
    }
}));

function GridButtonAdd({ buttonClickHandler, disabled, fetchingDisabled }) {
    const classes = useStyles();
    const theme = useTheme();
    // const history = useHistory();
    // const companyId = useCompanyId();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    // const isOverRecord = useIsOverRecords();
    // const [isOpenLimitModal, setIsOpenLimitModal] = React.useState(false);
    const { t } = useTranslation();

    // const handleOpenLimitModal = () => setIsOpenLimitModal(true);
    // const handleCloseLimitModal = e => {
    //     stopPropagation(e);
    //     setIsOpenLimitModal(false);
    // };

    const handleClick = React.useCallback(
        e => {
            // if (isOverRecord) {
            //     handleOpenLimitModal();
            //     return;
            // }
            buttonClickHandler && buttonClickHandler(e);
        },
        [buttonClickHandler]
    );

    // const handleUpgrade = () => {
    //     history.push(`/company-settings/billing/overview`);
    // };

    // const stopPropagation = e => {
    //     e.preventDefault();
    //     e.stopPropagation();
    // };

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item style={{ pointerEvents: fetchingDisabled ? 'none' : '' }}>
                <Button
                    className={`${classes.addButton} addGridBtn`}
                    onClick={handleClick}
                    tr-dt="Open Add Grid Option"
                    variant="outlined"
                    disabled={disabled}
                >
                    {/* {isOverRecord ? (
                        <UpgradeSVG style={{ marginRight: theme.spacing(2) }} />
                    ) : ( */}
                    <AddIconSVG
                        color={disabled ? theme.colors.disabledText : theme.colors.dimGrey}
                        style={{ marginRight: theme.spacing(2), width: 10, height: 10 }}
                    />
                    {/* )} */}
                    {isTablet ? t('global_grid') : t('dashboard_add_grid_button_desktop')}
                </Button>
            </Grid>
            {/* <ModalBase open={isOpenLimitModal} handleClose={handleCloseLimitModal}>
                <LimitPopup
                    title={t('grid_record_add_limit_title')}
                    message={t('grid_record_add_limit_msg')}
                    onCancel={handleCloseLimitModal}
                    onUpgrade={handleUpgrade}
                    onClose={handleCloseLimitModal}
                />
            </ModalBase> */}
        </Grid>
    );
}

export default React.memo(GridButtonAdd);
