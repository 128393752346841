import { ADD_ON_LIST, ADD_ON_NAME } from 'const/inAppAddon';
import { useAddonPlans, useAddonSubscriptions, useAddonUsage } from 'hooks/payment';
import { useSelector } from 'react-redux';

export function useInAppAddonVersion() {
    return useSelector(state => state.addons.version);
}

export function useItegrations() {
    return useSelector(state => state.addons.integrations);
}

export function useAddons() {
    const addonPlanByNameObject = useAddonPlans().reduce((object, sub) => {
        object[sub.name] = sub;
        return object;
    }, {});
    const addonSubscriptionByNameObject = useAddonSubscriptions().reduce((object, sub) => {
        object[sub.product] = sub;
        return object;
    }, {});
    const integrationByProviderObject = useItegrations().reduce((object, integration) => {
        object[integration.provider] = integration;
        return object;
    }, {});
    const addonUsageObject = useAddonUsage().reduce((object, usage) => {
        object[usage.addonType] = usage;
        return object;
    }, {});

    return ADD_ON_LIST.map(localAddon => {
        const plan = addonPlanByNameObject[localAddon.name];
        const subscription = addonSubscriptionByNameObject[localAddon.name];
        const integration = integrationByProviderObject[localAddon.provider];
        const usage = addonUsageObject[localAddon.name];
        return { ...localAddon, plan, subscription, integration, usage };
    });
}

export function useAddonDetailByName(addonName) {
    return useAddons().find(addon => addon.name === addonName);
}

const NORMAL_ADD_ONS = [
    ADD_ON_NAME.TRANSLATION_DASHBOARD,
    ADD_ON_NAME.LQA,
    ADD_ON_NAME.MEMO_Q,
    ADD_ON_NAME.MEM_SOURCE,
    ADD_ON_NAME.AUTO_QA
];

export function useNormalAddons() {
    return useAddons().filter(addon => NORMAL_ADD_ONS.includes(addon.name));
}

export function useAutomationAddons() {
    return useAddons().filter(addon => !NORMAL_ADD_ONS.includes(addon.name));
}

export function usePaygAddons() {
    return useAddons().filter(addon => addon.plan?.payAsYouGo);
}

export function useNonPaygAddons() {
    return useAddons().filter(addon => !addon.plan?.payAsYouGo);
}
