import React from 'react';

function MasterSVG({ ...other }) {
    return (
        <svg {...other} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.8118 19.9996C24.8118 25.7445 20.2254 30.3995 14.5658 30.3995C8.90623 30.3995 4.31982 25.7435 4.31982 19.9996C4.31982 14.2556 8.90735 9.59961 14.5658 9.59961C20.2243 9.59961 24.8118 14.2556 24.8118 19.9996Z"
                fill="#DD3F33"
            />
            <path
                d="M26.8707 9.59961C24.5729 9.59961 22.4575 10.3765 20.7479 11.6734L20.7561 11.6744C21.0924 12.0041 21.4633 12.2361 21.7493 12.6156L19.6174 12.6499C19.2834 12.9921 18.9789 13.3644 18.692 13.7513H22.4513C22.7372 14.0997 23.0016 14.4024 23.2415 14.7871H18.0104C17.8188 15.122 17.6415 15.4663 17.4867 15.824H23.837C24.0031 16.1807 24.1517 16.4501 24.2778 16.8276H17.1106C16.9968 17.1865 16.8995 17.5536 16.8256 17.9279H24.578C24.6539 18.2878 24.7123 18.6205 24.7523 18.9596H16.6719C16.638 19.3018 16.6206 19.6491 16.6206 19.9997H24.8106C24.8106 20.3679 24.785 20.7089 24.7481 21.0397H16.6719C16.7067 21.3923 16.758 21.7386 16.8256 22.0797H24.5657C24.4858 22.4167 24.3935 22.7557 24.2777 23.1072H17.087C17.1956 23.4629 17.3279 23.8082 17.4723 24.1451H23.837C23.6608 24.5237 23.4609 24.8263 23.2406 25.1809H17.9921C18.1992 25.5449 18.4277 25.8922 18.6757 26.2251L22.4514 26.2822C22.1296 26.6743 21.7166 26.9104 21.3403 27.2536C21.3608 27.2703 20.7387 27.2515 19.5139 27.2318C21.3772 29.1829 23.9815 30.3996 26.8706 30.3996C32.5312 30.3996 37.1197 25.7435 37.1197 19.9997C37.1197 14.2558 32.5322 9.59961 26.8707 9.59961Z"
                fill="#F4B459"
            />
        </svg>
    );
}

export default React.memo(MasterSVG);
