import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';

function SingleSelectionCheckDiff({ isHideMarkIcon = false, data, options = [], customProperties = {}, diffBefore }) {
    return (
        <Grid container spacing={1}>
            <Grid item>
                <Option
                    data={data}
                    state={diffBefore ? 'delete' : 'update'}
                    options={options}
                    customProperties={customProperties}
                    isHideMarkIcon={isHideMarkIcon}
                    diffBefore={diffBefore}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(SingleSelectionCheckDiff);
