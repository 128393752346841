import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import StatusIcon from './common/StatusIcon';
import AccessIcon from './common/AccessIcon';
import Select from 'components/selects/Select';
import Search from 'components/search';
import ButtonBase from 'components/button/Base';
import Table from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import { getFriendlyDate } from 'utils/datetime';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import TransformSVG from 'assets/images/svg/TransformSVG';
import RevokeSVG from 'assets/images/svg/RevokeSVG';
import theme from 'theme';
import DropdownMenu from 'components/menus/DropdownMenu';
import ListItem from 'components/list/Item';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import * as clipboardy from 'clipboardy';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useApiKeyList, useIsFetching } from 'hooks/permission/apiKey';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import capitalize from 'lodash/capitalize';
import * as apiKeyActions from '../actions';
import { API_KEY_STATUS, COLOR_TYPES } from 'const';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import NotFoundSVG from 'assets/images/svg/apiKeys/NotFoundSVG';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        position: 'relative'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: hexToRgba(theme.colors.white, 0.6),
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100
    },
    dropdownClassName: {
        minWidth: 230
    },
    dpFlex: {
        display: 'flex'
    },
    listItem: {
        '& .textContent': {
            color: theme.colors.snackBarWarning,
            fontWeight: 500
        }
    },
    deleteKey: {
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderTop: `1px solid ${theme.colors.divider}`
    },
    cursorPointer: {
        cursor: 'pointer'
    }
}));

const STATUS_OPTIONS = [
    { value: 'all', label: i18n.t('all_status') },
    { value: 'active', label: i18n.t('active'), icon: <StatusIcon status={'active'} /> },
    { value: 'revoked', label: i18n.t('revoked'), icon: <StatusIcon status={'revoked'} /> }
];

function NameRender({ rowData }) {
    const classes = useStyles();
    return (
        <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item className={classes.dpFlex}>
                <AccessIcon status={rowData?.type} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{rowData?.name}</Typography>
            </Grid>
        </Grid>
    );
}
const NameRenderMemo = React.memo(NameRender);

function KeyRender({ rowData }) {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCopyKey = async () => {
        try {
            await clipboardy.write(rowData?.value);
            dispatch(
                enqueueSnackbar({
                    message: t('copied'),
                    type: 'info'
                })
            );
        } catch {
            console.log('copy error');
        }
    };

    const hiddenValue = `•••••••••••••••••••••••••••••••`;

    return (
        <Grid container wrap="nowrap" spacing={2} direction="row" alignItems="center">
            <Grid item>
                <Typography variant="body2">{hiddenValue}</Typography>
            </Grid>
            <Tooltip title="Copy key">
                <Grid onClick={handleCopyKey} item className={`${classes.dpFlex} ${classes.cursorPointer}`}>
                    <DuplicateSVG color={theme.colors.lightLavender} />
                </Grid>
            </Tooltip>
        </Grid>
    );
}
const KeyRenderMemo = React.memo(KeyRender);

function StatusRender({ rowData }) {
    return (
        <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
                <StatusIcon status={rowData?.status} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{capitalize(rowData?.status)}</Typography>
            </Grid>
        </Grid>
    );
}
const StatusRenderMemo = React.memo(StatusRender);

function UpdatedRender({ rowData }) {
    return <Typography variant="body2">{rowData?.updatedAt ? getFriendlyDate(rowData?.updatedAt) : '--'}</Typography>;
}
const UpdatedRenderMemo = React.memo(UpdatedRender);

function CreatedRenderer({ rowData, t }) {
    return <Typography variant="body2">{getFriendlyDate(rowData?.createdDate)}</Typography>;
}
const CreatedRendererMemo = React.memo(CreatedRenderer);

const ACTION_OPTIONS = [
    { value: 'edit', label: i18n.t('edit_key'), icon: <EditSVG /> },
    { value: 'duplicate', label: i18n.t('duplicate_key'), icon: <DuplicateSVG color={theme.colors.steel} /> },
    { value: 'regenerate', label: i18n.t('regenerate_key'), icon: <TransformSVG /> },
    { value: 'revoke', label: i18n.t('revoke_key'), icon: <RevokeSVG /> }
];

const REVOKE_ACTIONS = [
    {
        value: 'delete',
        label: (
            <Typography variant="body2" style={{ color: theme.colors.brightRed, fontWeight: 500 }}>
                {i18n.t('delete_key')}
            </Typography>
        ),
        icon: <DeleteSVG color={theme.colors.brightRed} />
    }
];

function ActionRenderer({ rowData, onEdit, onDuplicate }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isShowRegenerateKeyConfirmBox, setIsShowRegenerateKeyConfirmBox] = React.useState(false);
    const [isShowRevokeKeyConfirmBox, setIsShowRevokeKeyConfirmBox] = React.useState(false);
    const [isShowDeleteKeyConfirmBox, setIsShowDeleteKeyConfirmBox] = React.useState(false);

    const [isRevoking, setIsRevoking] = React.useState(false);
    const [isRenewing, setIsRenewing] = React.useState(false);

    const handleOpenShowRegenerateKey = () => setIsShowRegenerateKeyConfirmBox(true);
    const handleCloseShowRegenerateKey = () => setIsShowRegenerateKeyConfirmBox(false);

    const handleOpenShowRevokeKey = () => setIsShowRevokeKeyConfirmBox(true);
    const handleCloseShowRevokeKey = () => setIsShowRevokeKeyConfirmBox(false);

    const handleOpenShowDeleteKey = () => setIsShowDeleteKeyConfirmBox(true);
    const handleCloseShowDeleteKey = () => setIsShowDeleteKeyConfirmBox(false);

    const handleActionChange = option => {
        switch (option.value) {
            case 'duplicate':
                onDuplicate && onDuplicate(rowData?.value);
                break;
            case 'regenerate':
                handleOpenShowRegenerateKey();
                break;
            case 'edit':
                onEdit && onEdit(rowData?.value);
                break;
            case 'revoke':
                handleOpenShowRevokeKey();
                break;
            case 'delete':
                handleOpenShowDeleteKey();
                break;
            default:
                break;
        }
    };

    const DeleteKey = React.useCallback(
        ({ handleClickAway }) => {
            const handleClick = e => {
                handleClickAway();
                handleOpenShowDeleteKey();
            };

            return (
                <Grid container className={classes.deleteKey}>
                    <ListItem
                        name={i18n.t('delete_key')}
                        icon={<DeleteSVG color={theme.colors.snackBarWarning} />}
                        onClick={handleClick}
                        className={`${classes.listItem}`}
                    />
                </Grid>
            );
        },
        [classes]
    );

    const handleDeleteApiKey = () => {
        sendManualTrack({ type: 'Delete API Key' });
        dispatch(
            apiKeyActions.deleteApiKey({
                apiKeyId: rowData?.value,
                successCallback: () => {
                    console.log('delete success');
                },
                errorCallback: () => {
                    console.log('delete failed');
                }
            })
        );
        handleCloseShowDeleteKey();
    };

    const handleRenewApiKey = () => {
        sendManualTrack({ type: 'Regenerate API Key' });
        setIsRenewing(true);
        dispatch(
            apiKeyActions.renewApiKey({
                apiKeyId: rowData?.value,
                successCallback: () => {
                    console.log('renew success');
                    setIsRenewing(false);
                    handleCloseShowRegenerateKey();
                },
                errorCallback: () => {
                    setIsRenewing(false);
                    console.log('renew failed');
                }
            })
        );
    };

    const handleRevokeApiKey = () => {
        setIsRevoking(true);
        sendManualTrack({ type: 'Revoke API Key' });
        dispatch(
            apiKeyActions.revokeApiKey({
                apiKeyId: rowData?.value,
                successCallback: () => {
                    setIsRevoking(false);
                    console.log('revoke success');
                    handleCloseShowRevokeKey();
                },
                errorCallback: () => {
                    setIsRevoking(false);
                    console.log('revoke failed');
                }
            })
        );
    };

    return (
        <Grid container justify="flex-end">
            <Grid item>
                <DropdownMenu
                    onChange={handleActionChange}
                    options={
                        API_KEY_STATUS?.REVOKED === rowData?.status?.toLowerCase() ? REVOKE_ACTIONS : ACTION_OPTIONS
                    }
                    className={classes.moreActionsButton}
                    placement={'bottom-end'}
                    Control={API_KEY_STATUS?.REVOKED === rowData?.status?.toLowerCase() ? undefined : DeleteKey}
                >
                    <IconButton>
                        <IconMoreActionsSVG />
                    </IconButton>
                </DropdownMenu>
            </Grid>

            <Dialog open={isShowRegenerateKeyConfirmBox} onClose={handleCloseShowRegenerateKey}>
                <ConfirmBox
                    title={t('regenerate_key')}
                    body={
                        <Typography variant="body2" component="div">
                            {t('regenerate_key_confirm')}
                        </Typography>
                    }
                    onClose={handleCloseShowRegenerateKey}
                    handleAgreed={handleRenewApiKey}
                    handleCancel={handleCloseShowRegenerateKey}
                    isLoading={isRenewing}
                    agreeLabel={t('global_regenerate')}
                />
            </Dialog>

            <Dialog open={isShowRevokeKeyConfirmBox} onClose={handleCloseShowRevokeKey}>
                <ConfirmBox
                    title={t('revoke_key')}
                    body={
                        <Typography variant="body2" component="div">
                            {t('revoke_key_confirm')}
                        </Typography>
                    }
                    onClose={handleCloseShowRevokeKey}
                    handleAgreed={handleRevokeApiKey}
                    handleCancel={handleCloseShowRevokeKey}
                    isLoading={isRevoking}
                    agreeLabel={t('global_deactivate')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>

            <Dialog open={isShowDeleteKeyConfirmBox} onClose={handleCloseShowDeleteKey}>
                <ConfirmBox
                    title={t('delete_api_key')}
                    body={
                        <Typography variant="body2" component="div">
                            {t('delete_api_key_confirm')}{' '}
                        </Typography>
                    }
                    onClose={handleCloseShowDeleteKey}
                    handleAgreed={handleDeleteApiKey}
                    handleCancel={handleCloseShowDeleteKey}
                    isLoading={false}
                    agreeLabel={t('global_delete')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </Grid>
    );
}

const ActionRendererMemo = React.memo(ActionRenderer);

function ApiKeyTable({ onCreateClick, onEdit, onDuplicate }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedStatus, setSelectedStatus] = React.useState(STATUS_OPTIONS?.[0]?.value);
    const [searchValue, setSearchValue] = React.useState('');
    const apiKeys = useApiKeyList() || [];
    const isFetching = useIsFetching();

    const [sorting, setSorting] = React.useState({});

    const COLUMNS = [
        {
            label: t('api_key_table_column_name'),
            dataKey: 'name',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: props => <NameRenderMemo {...props} />
        },
        {
            label: t('api_key_table_column_key'),
            dataKey: 'value',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: props => <KeyRenderMemo {...props} theme={theme} />
        },
        {
            label: t('api_key_table_column_status'),
            dataKey: 'status',
            width: 150,
            sort: true,
            cellRenderer: props => <StatusRenderMemo {...props} />
        },
        {
            label: t('api_key_table_column_last_used'),
            dataKey: 'lastAccessTime',
            width: 150,
            cellRenderer: props => <UpdatedRenderMemo {...props} />
        },
        {
            label: t('api_key_table_column_created'),
            dataKey: 'createdDate',
            width: 150,
            cellRenderer: props => <CreatedRendererMemo {...props} />
        },
        {
            label: '',
            dataKey: 'id',
            width: 100,
            cellRenderer: props => <ActionRendererMemo {...props} onDuplicate={onDuplicate} onEdit={onEdit} />
        }
    ];

    const filteredApiKeys = React.useMemo(() => {
        let result = apiKeys;

        if (selectedStatus !== 'all') {
            result = result.filter(member => member?.status?.toLowerCase() === selectedStatus);
        }

        if (searchValue.trim() !== '') {
            result = result.filter(member => member?.name?.toLowerCase()?.includes(searchValue?.trim()?.toLowerCase()));
        }

        return result;
    }, [apiKeys, selectedStatus, searchValue]);

    const data = React.useMemo(() => {
        const cloneData = [...filteredApiKeys].sort(
            (a, b) => new Date(b?.audit?.createdDate) - new Date(a?.audit?.createdDate)
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, filteredApiKeys]);

    const handleStatusFilterChange = option => {
        setSelectedStatus(option.value);
    };

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item>
                    <NotFoundSVG />
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t('api_key_not_found')}</Typography>
                </Grid>
            </Grid>
        );
    }, [t]);

    return (
        <Grid container direction="column" spacing={4} className={classes.root}>
            {isFetching && (
                <div className={classes.overlay}>
                    <Spinner size={20} thick={3} />
                </div>
            )}
            <Grid item>
                <Grid container justify="space-between">
                    <Grid item>
                        <Select
                            options={STATUS_OPTIONS}
                            value={selectedStatus}
                            className={classes.dropdownClassName}
                            border
                            onChange={handleStatusFilterChange}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Search
                                    onChange={e => setSearchValue(e.target.value)}
                                    background={theme.colors.paleGrey}
                                    placeholder={t('search_key_placeholder')}
                                    width={220}
                                />
                            </Grid>
                            <Grid item>
                                <ButtonBase onClick={onCreateClick} variant="contained">
                                    + {t('create_api_key')}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <div className={classes.table} style={{ height: '70vh', width: '100%' }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                data={data}
                                columns={COLUMNS}
                                width={width}
                                height={height}
                                onRowClick={handleRowClick}
                                onSort={handleOnSort}
                                noRowsRenderer={_noRowsRenderer}
                            ></Table>
                        )}
                    </AutoSizer>
                </div>
            </Grid>
            {}
        </Grid>
    );
}

export default React.memo(ApiKeyTable);
