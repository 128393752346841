import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import InputViewCopyOnly from 'components/inputs/InputViewCopyOnly';
import SettingSVG from 'assets/images/svg/addon/SettingSVG';
import MemSourceAuthPNG from 'assets/images/svg/addon/MemSourceAuth.png';
import InputText from 'components/inputs/InputText';
import ButtonBase from 'components/button/Base';
import { MEMSOURCE_SETUP_URL, ADD_ON_STATES } from 'const';
import { INTEGRATION_PROVIDER } from 'const/inAppAddon';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAddonByIdApi } from 'services/workflow';
import * as addonActions from 'addons/actions';
import { useTranslation, Trans } from 'react-i18next';
import { sendManualTrack } from 'tracker';
import LDBasePortal from 'components/selects/LDBasePortal';
import ReactCountryFlag from 'react-country-flag';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    expand: {
        flex: 1
    },

    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700,
        textDecoration: 'none'
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    buttonWrapper: {
        position: 'relative',
        marginTop: 8
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const DEFAULT_STYLE = {
    width: 24,
    height: 16,
    borderRadius: 4
};

const WAITING_SECONDS = 60;
const REGION_OPTIONS = [
    {
        icon: () => <ReactCountryFlag countryCode="EU" svg style={DEFAULT_STYLE} />,
        label: 'European Union',
        value: 'EU'
    },
    {
        icon: () => <ReactCountryFlag countryCode="US" svg style={DEFAULT_STYLE} />,
        label: 'United States',
        value: 'US'
    }
];

function MemSourceSetting({ integration }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [clientId, setClientId] = React.useState(integration?.clientId || '');
    const [isConnecting, setIsConnecting] = React.useState(false);
    const dispatch = useDispatch();
    const intervalRef = React.useRef(null);
    const [remainingSecond, setRemainingSecond] = React.useState(WAITING_SECONDS);
    const [windowInstance, setWindowInstance] = React.useState(null);
    const [region, setRegion] = React.useState('');
    const selectedRegionOption = REGION_OPTIONS.find(option => option.value === region);

    React.useEffect(() => {
        setRegion(integration?.memSource?.region);
    }, [integration]);

    const handleConnect = () => {
        setIsConnecting(true);
        sendManualTrack({ type: 'Connect Phrase TMS' });
        dispatch(
            addonActions.enableAddon({
                body: {
                    memSource: { clientId, region },
                    provider: INTEGRATION_PROVIDER.MEM_SOURCE
                },
                successCallback: addon => {
                    const authorizeUrl = `https://cloud.memsource.com/web/oauth/authorize?response_type=code&client_id=${clientId}`;

                    let winInstance = window.open(
                        authorizeUrl,
                        'targetWindow',
                        `menubar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
                    );
                    setWindowInstance(winInstance);
                    const startTime = new Date().getTime();
                    intervalRef.current = setInterval(async function() {
                        const timeDiffInSecond = Math.round((new Date().getTime() - startTime) / 1000);
                        setRemainingSecond(remainingSecond - timeDiffInSecond);
                        if (timeDiffInSecond > WAITING_SECONDS) {
                            clearInterval(intervalRef.current);
                            return;
                        }
                        if (winInstance.closed) {
                            clearInterval(intervalRef.current);
                            setIsConnecting(false);
                            return;
                        }
                        const latest = await getAddonByIdApi(addon?.id);
                        if (latest?.state === ADD_ON_STATES.ACTIVE) {
                            clearInterval(intervalRef.current);
                            setIsConnecting(false);
                            winInstance.close();

                            dispatch(
                                addonActions.changeAddonState({
                                    addonId: latest?.id,
                                    newState: ADD_ON_STATES.ACTIVE,
                                    successCallback: () => {
                                        console.log('done');
                                        setRemainingSecond(WAITING_SECONDS);
                                    },
                                    errorCallback: () => {
                                        console.log('failed');
                                        setRemainingSecond(WAITING_SECONDS);
                                    }
                                })
                            );
                            return;
                        }
                    }, 1000);
                },
                errorCallback: () => {
                    setIsConnecting(false);
                    console.log('Connect failed');
                }
            })
        );
    };

    const onChange = e => {
        setClientId(e.target.value);
    };

    const handleStopConnecting = () => {
        setRemainingSecond(WAITING_SECONDS);
        setIsConnecting(false);
        clearInterval(intervalRef.current);
        windowInstance.close();
    };

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                    <Grid item className={`${classes.numberStep}`}>
                        {t('global_step_1')}
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Trans
                                    t={t}
                                    i18nKey="addon_memsource_setting_step_1_description"
                                    defaults="<normal>Copy the following</normal> <bold>Redirect URI:</bold>"
                                    components={{
                                        normal: <Typography variant="body2" display="inline" />,
                                        bold: <Typography variant="body1" display="inline" />
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <InputViewCopyOnly value={integration?.callbackUrl} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                    <Grid item className={`${classes.numberStep}`}>
                        {t('global_step_2')}
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Trans i18nKey="addon_memsource_setting_step_2_description" t={t}>
                            <p className="body2 inline">Go to Phrase TMS, click on the Setup</p>
                            <SettingSVG />
                            <p className="body2 inline">, scroll down to the Integrations section and click on</p>
                            <Link
                                className="body2 text-dodgerBlue font-bold inline"
                                to={{ pathname: MEMSOURCE_SETUP_URL }}
                                target="_blank"
                            >
                                Registered OAuth Apps
                            </Link>
                            <p className="body2 inline">, and select</p>
                            <p className="body1 inline">New.</p>
                        </Trans>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                    <Grid item className={`${classes.numberStep}`}>
                        {t('global_step_3')}
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body2" display="inline">
                                    {t('addon_memsource_setting_step_3_description')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <img src={MemSourceAuthPNG} alt={'MemSourceAuthPNG'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                    <Grid item className={`${classes.numberStep}`}>
                        {t('global_step_4')}
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Trans
                                    t={t}
                                    i18nKey="addon_memsource_setting_step_4_description_title"
                                    defaults="<normal>Click</normal> <bold>Save.</bold>"
                                    components={{
                                        normal: <Typography variant="body2" display="inline" />,
                                        bold: <Typography variant="body1" display="inline" />
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Trans
                                    t={t}
                                    i18nKey="addon_memsource_setting_step_4_description_subtitle"
                                    defaults="<italic>The Registered OAuth Apps</italic> <normal>page opens with the new connection in the list and an associated</normal> <italic>Client ID.</italic>"
                                    components={{
                                        normal: <Typography variant="body2" display="inline" />,
                                        italic: (
                                            <Typography
                                                style={{ fontStyle: 'italic' }}
                                                variant="body2"
                                                display="inline"
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                    <Grid item className={`${classes.numberStep}`}>
                        {t('global_step_5')}
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Trans
                                    t={t}
                                    i18nKey="addon_memsource_setting_step_5_description"
                                    defaults="<normal>Copy the</normal> <italic>Client ID</italic> <normal> and paste it into this box, and click</normal> <bold>Connect.</bold"
                                    components={{
                                        normal: <Typography variant="body2" display="inline" />,
                                        bold: <Typography variant="body1" display="inline" />,
                                        italic: (
                                            <Typography
                                                style={{ fontStyle: 'italic' }}
                                                variant="body2"
                                                display="inline"
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <InputText
                                    name="name"
                                    value={clientId}
                                    disabled={isConnecting}
                                    onChange={onChange}
                                    placeholder={t('addon_memsource_setting_step_5_input_placeholder')}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                    <Grid item className={`${classes.numberStep}`}>
                        {t('global_step_6')}
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body2" display="inline">
                                    {t('addon_memsource_setting_step_6_description')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    menuPlaceholder={t(`global_label_search`)}
                                    options={REGION_OPTIONS}
                                    onChange={option => setRegion(option.value)}
                                    defaultValue={selectedRegionOption}
                                    isMulti={false}
                                />
                            </Grid>
                            <div className="flex items-center gap-2 mt-2 relative">
                                <ButtonBase
                                    onClick={handleConnect}
                                    disabled={!clientId || isConnecting}
                                    variant="contained"
                                >
                                    {isConnecting ? `${t('connecting')} (${remainingSecond})` : t('connect')}
                                    {isConnecting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </ButtonBase>
                                <ButtonBase variant="outlined" onClick={handleStopConnecting}>
                                    {t('global_stop_connecting')}
                                </ButtonBase>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(MemSourceSetting);
