import React from 'react';

function Small({ color = '#78778B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M17 8.94629V15.1567C17 16.1747 16.1046 17 15 17H3C1.89543 17 1 16.1747 1 15.1567V8.94629"
                stroke={color}
                strokeWidth="1.5"
            />
            <path d="M1 9.69434H17" stroke={color} strokeWidth="1.5" />
        </svg>
    );
}

export default React.memo(Small);
