import React, { useCallback, useState } from 'react';
import { Typography } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import ListCompanyInfo from './ListCompanyInfo';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeAccountCompany } from 'auth/actions';
import CircularProgress from 'components/CircularProgress';
import { resetMembers } from 'permission/actions';

const CannotDeleteAccount = ({ companyInfo, onClose }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleGoToMemberSettings = useCallback(
        company => {
            setLoading(true);
            dispatch(
                changeAccountCompany({
                    companyId: companyInfo?.owners?.[0]?.companyId,
                    successCallback: () => {
                        dispatch(resetMembers());
                        history.push('/company-settings/members');
                    },
                    errorCallback: error => {
                        setLoading(false);
                    }
                })
            );
        },
        [dispatch, companyInfo, history]
    );

    return (
        <div>
            <Typography className="!text-[32px] !leading-8" variant="body2">
                {t('cannot_delete_your_account')}
            </Typography>
            <Typography className="!mt-2 !text-[18px] !text-[#78778B]" variant="body2">
                {t('cannot_delete_your_account_hint')}
            </Typography>
            <Typography className="!mt-10" variant="body1">
                {t('cannot_delete_your_account_instruction')}
            </Typography>
            <div className="mt-3">
                <ListCompanyInfo companies={companyInfo?.owners} showMemberNumber />
            </div>
            <div className="mt-8 flex flex-nowrap gap-8">
                <div className="flex-1">
                    <ButtonBase className="!w-full" variant="outlined" onClick={onClose}>
                        {t('global_cancel')}
                    </ButtonBase>
                </div>
                <div className="flex-1">
                    <ButtonBase
                        className="!w-full"
                        variant="contained"
                        disabled={loading}
                        onClick={handleGoToMemberSettings}
                    >
                        {t('goto_member_settings')}
                        {loading && <CircularProgress />}
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CannotDeleteAccount);
