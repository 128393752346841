import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SettingContentLayout from 'permission/common/Layout';
import { useDispatch } from 'react-redux';
import { useCompanyUsage } from 'hooks/dataCollector';
import { USAGE_PROPERTIES } from 'const';
import { useCompanySubscription, useUsageLimit } from 'hooks/payment';
import * as addonActions from 'addons/actions';
import { useTranslation } from 'react-i18next';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { CustomLinearProgress } from './CustomLinearProgress';
import * as paymentActions from 'payment/actions';
import Divider from 'components/divider/Base';
import { beautifyPrice } from 'utils/payment';
import { usePaygAddons } from 'hooks/addons';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 24,
        height: '100%'
    },
    planUsageWrapper: {
        marginTop: theme.spacing(3),
        border: `1px solid ${theme.colors.divider}`,
        borderRadius: 4
    },
    paygWrapper: {
        padding: theme.spacing(3, 4),
        marginTop: theme.spacing(3),
        border: `1px solid ${theme.colors.divider}`,
        borderRadius: 4
    },
    bar: {
        marginTop: theme.spacing(3)
    },
    item: {
        padding: theme.spacing(3, 4)
    },
    dot: {
        width: 12,
        height: 12,
        display: 'inline-block',
        verticalAlign: -1,
        borderRadius: '50%',
        marginRight: theme.spacing(1)
    },
    shamRock: {
        backgroundColor: theme.colors.shamRock
    },
    dodgerBlue: {
        backgroundColor: theme.colors.dodgerBlue
    },
    dodgerBlueColor: {
        color: theme.colors.dodgerBlue
    },
    sun: {
        backgroundColor: theme.colors.sun
    },
    burntSlena: {
        backgroundColor: theme.colors.burntSlena
    },
    midGrey: {
        color: theme.colors.midGrey
    },
    divider: {
        margin: theme.spacing(3, 0)
    }
}));

function StatisticsSetting() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const companySubscription = useCompanySubscription();
    const paygAddons = usePaygAddons();
    const companyUsage = useCompanyUsage();
    const defaultUsage = useUsageLimit();
    const roles = useRole();
    const canManageBilling = React.useMemo(() => {
        return roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_STATISTICS];
    }, [roles]);

    const classes = useStyles({ canManageBilling });

    React.useEffect(() => {
        dispatch(
            addonActions.getAddonsList({
                successCallback: () => {
                    console.log('getAddonsList Success');
                },
                errorCallback: () => {
                    console.log('getAddonsList failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchAddonUsage({
                successCallback: () => {
                    console.log('fetchAddonUsage Success');
                },
                errorCallback: () => {
                    console.log('fetchAddonUsage failed');
                }
            })
        );
    }, [dispatch]);

    const activeSeat = companySubscription?.activeSeat;
    const totalSeat = companySubscription?.totalSeat;

    const defaultAPIRequest = defaultUsage?.[USAGE_PROPERTIES.MAX_API_REQUESTS_PER_MONTH];
    const actualAPIRequest = companyUsage?.[USAGE_PROPERTIES.MAX_API_REQUESTS_PER_MONTH];

    const defaultStorage = React.useMemo(() => {
        const storage = defaultUsage?.[USAGE_PROPERTIES.MAX_STORAGE_GB];
        return storage;
    }, [defaultUsage]);
    const actualStorage = React.useMemo(() => {
        return companyUsage?.[USAGE_PROPERTIES.MAX_STORAGE_GB];
    }, [companyUsage]);

    const defaultMaxRecord = defaultUsage?.[USAGE_PROPERTIES.MAX_RECORD];
    const actualMaxRecord = companyUsage?.biggestRecordCount;

    return (
        <SettingContentLayout
            name={t(`statistics`)}
            contentClassName={'content-auto'}
            AddComponent={
                <Grid container alignItems="flex-end">
                    <Typography variant="body2" display="inline" className={classes.midGrey}>
                        {t(`statistics_refresh_interval`)}:&nbsp;{' '}
                    </Typography>
                    <Typography variant="body1" display="inline">
                        {t(`global_every_30_mins`)}
                    </Typography>
                </Grid>
            }
        >
            <Grid container direction="column" wrap="nowrap" className={classes.root}>
                <Grid item container direction="column">
                    <Grid item>
                        <Typography variant="h4">Plan usage</Typography>
                    </Grid>
                    <Grid item container className={classes.planUsageWrapper}>
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Typography variant="body2" display="inline">
                                Active Seats:
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                {activeSeat}/{totalSeat}
                            </Typography>
                            <CustomLinearProgress
                                value={Math.min(activeSeat / totalSeat, 1) * 100}
                                className={classes.bar}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Typography variant="body2" display="inline">
                                API Request:
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                {actualAPIRequest}/
                                {defaultAPIRequest === -1 ? t('global_unlimited') : defaultAPIRequest}
                            </Typography>
                            <CustomLinearProgress
                                value={Math.min(actualAPIRequest / defaultAPIRequest, 1) * 100}
                                className={classes.bar}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Typography variant="body2" display="inline">
                                File Storage:
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                {actualStorage}/{defaultStorage === -1 ? t('global_unlimited') : defaultStorage}GB
                            </Typography>
                            <CustomLinearProgress
                                value={Math.min(actualStorage / defaultStorage, 1) * 100}
                                className={classes.bar}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.item}>
                            <Typography variant="body2" display="inline">
                                Biggest records in a Grid:
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {' '}
                                {actualMaxRecord}/{defaultMaxRecord === -1 ? t('global_unlimited') : defaultMaxRecord}
                            </Typography>
                            <CustomLinearProgress
                                value={Math.min(actualMaxRecord / defaultMaxRecord, 1) * 100}
                                className={classes.bar}
                            />
                        </Grid>
                        <Grid item container spacing={5} className={classes.item}>
                            <Grid item>
                                <div className={`${classes.dot} ${classes.shamRock}`} />
                                <Typography variant="body2" display="inline">
                                    {t('global_good')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={`${classes.dot} ${classes.dodgerBlue}`} />
                                <Typography variant="body2" display="inline">
                                    {t('global_normal')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={`${classes.dot} ${classes.sun}`} />
                                <Typography variant="body2" display="inline">
                                    {t('global_warning')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={`${classes.dot} ${classes.burntSlena}`} />
                                <Typography variant="body2" display="inline">
                                    {t('global_critical')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" style={{ marginTop: 30 }}>
                    <Grid item>
                        <Typography variant="h4">Pay as you go</Typography>
                    </Grid>
                    <Grid item container direction="column" className={classes.paygWrapper}>
                        {paygAddons.map((addon, index) => {
                            const addonUsage = addon?.usage;
                            return (
                                <Grid item key={addonUsage?.addonType} direction="column" container>
                                    <Grid item container wrap="nowrap" alignItems="center" spacing={3}>
                                        <Grid item style={{ display: 'flex' }}>
                                            <Typography variant="body1">{addon.icon(32)}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="body1">{addon.title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="body1"
                                                        display="inline"
                                                        className={classes.dodgerBlueColor}
                                                    >
                                                        {addonUsage?.numExecutedElements}
                                                    </Typography>{' '}
                                                    <Typography
                                                        variant="body2"
                                                        display="inline"
                                                        className={classes.midGrey}
                                                    >
                                                        {addonUsage?.addonUnit?.toLowerCase()}s
                                                    </Typography>{' '}
                                                    {!isExternal && (
                                                        <>
                                                            <Typography
                                                                variant="body2"
                                                                display="inline"
                                                                className={classes.midGrey}
                                                            >
                                                                in
                                                            </Typography>{' '}
                                                            <Typography
                                                                variant="body1"
                                                                display="inline"
                                                                className={classes.dodgerBlueColor}
                                                            >
                                                                {addonUsage?.numUsageBlocks}
                                                            </Typography>{' '}
                                                            <Typography
                                                                variant="body2"
                                                                display="inline"
                                                                className={classes.midGrey}
                                                            >
                                                                blocks
                                                            </Typography>{' '}
                                                            <Typography
                                                                variant="body2"
                                                                display="inline"
                                                                className={classes.midGrey}
                                                            >
                                                                (
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                display="inline"
                                                                className={classes.dodgerBlueColor}
                                                            >
                                                                {addonUsage?.freeQuantityUpTo}
                                                            </Typography>{' '}
                                                            <Typography
                                                                variant="body2"
                                                                display="inline"
                                                                className={classes.midGrey}
                                                            >
                                                                free {addonUsage?.addonUnit?.toLowerCase()}s per month
                                                                applied)
                                                            </Typography>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {!isExternal && (
                                            <Grid item style={{ marginLeft: 'auto' }}>
                                                <Typography variant="body1" display="inline">
                                                    {t('total')}:
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    display="inline"
                                                    className={classes.dodgerBlueColor}
                                                >
                                                    {' '}
                                                    {beautifyPrice({
                                                        unitAmount: addonUsage?.totalCost,
                                                        currency: addonUsage?.currency
                                                    })}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {index !== paygAddons.length - 1 && (
                                        <Grid item>
                                            <Divider className={classes.divider} />
                                        </Grid>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
}

export default React.memo(StatisticsSetting);
