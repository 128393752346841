import React from 'react';

function CaseSensitiveSVG({ ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4553_230401)">
                <path
                    d="M1.66797 2.66699V4.66699H5.0013V12.667H7.0013V4.66699H10.3346V2.66699H1.66797ZM14.3346 6.00033H8.33464V8.00033H10.3346V12.667H12.3346V8.00033H14.3346V6.00033Z"
                    fill="#78778B"
                />
            </g>
            <defs>
                <clipPath id="clip0_4553_230401">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(CaseSensitiveSVG);
