import React from 'react';

function ArrowMoveDownSVG({ color = '#78778B', className, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M8.00081 2C7.56397 2 7.20292 2.32692 7.14577 2.75121L7.1379 2.86917L7.13782 12.0324L5.47391 10.3607C5.20454 10.0883 4.80051 10.0338 4.47699 10.1969L4.35987 10.2683L4.25281 10.3601C3.94954 10.6655 3.91902 11.1419 4.16178 11.4817L4.25283 11.5894L7.38942 14.7476C7.47862 14.8375 7.58614 14.9065 7.72076 14.9524L7.86475 14.9904L8.03479 15L8.19859 14.9784L8.3056 14.9457L8.42546 14.8893L8.52634 14.8217L8.61043 14.7476L11.7469 11.5891C12.0843 11.2498 12.0844 10.6992 11.7471 10.3599C11.5834 10.1951 11.3641 10.1055 11.1379 10.1055C10.9561 10.1052 10.7789 10.1627 10.6312 10.2702L10.5263 10.3601L8.86373 12.0336L8.86373 2.86917C8.86373 2.47303 8.60088 2.13894 8.24119 2.03414L8.11794 2.00793L8.00081 2Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowMoveDownSVG);
