import React from 'react';
import { Grid } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { HEADER_HEIGHT } from 'const';
import { useHistory } from 'react-router-dom';
import ProgressLinear from 'components/notify/Import';

function BackingUpConfirmation({ workspaceId, gridId, viewId, dbId, branchId, t }) {
    const history = useHistory();

    const handleGoToDashboard = () => {
        history.push(`/projects/${workspaceId}`);
    };

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            direction="row"
            className={`fixed top-[${HEADER_HEIGHT}px] left-0 right-0 p-3.5 w-full z-[998] shadow bg-transparent`}
            style={{
                height: `calc(100vh - ${HEADER_HEIGHT}px)`
            }}
        >
            <Grid item className={`opacity-40 w-full h-full absolute z-[1] bg-white`} />
            <Grid item className={'w-full h-full absolute z-[2]'}>
                <Grid className={'h-full'} container alignItems="center" justify="center" direction="row">
                    <Grid item className={'p-8 w-[540px] bg-white shadow'}>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <div className="prose prose-lg font-medium">
                                    {t(`object_is_acting_title`, {
                                        object: 'Database',
                                        action: t(`global_backing_up`)?.toLowerCase()
                                    })}
                                </div>
                            </Grid>
                            <Grid item>
                                <p className="body2">{t(`object_is_restoring_subtitle`)}</p>
                            </Grid>
                            <Grid item>
                                <ProgressLinear title={t(`global_backing_up`)} />
                            </Grid>
                            <Grid item>
                                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end">
                                    <Grid item>
                                        <ButtonBase onClick={handleGoToDashboard} variant="contained">
                                            {t(`global_back_to_project`)}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(BackingUpConfirmation);
