import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { FILE_UPLOAD_TYPES } from 'const/gridUI';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const ExtraFiles = ({ fileUploadType, fileUploadTypeChangeHandler }) => {
    const { t } = useTranslation();

    const handleFileUploadTypeChangeHandler = useCallback(
        e => {
            const value = e.target?.value;
            fileUploadTypeChangeHandler({ uploadType: value });
        },
        [fileUploadTypeChangeHandler]
    );

    return (
        <Grid item container direction="column">
            <Grid item>
                <p className="body1">{t('upload_type')}</p>
            </Grid>
            <Grid item>
                <RadioGroup row value={fileUploadType} onChange={handleFileUploadTypeChangeHandler}>
                    <Grid container direction="column">
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item>
                                <FormControlLabel
                                    value={FILE_UPLOAD_TYPES.MULTIPLE}
                                    control={<Radio size="small" />}
                                    label={<p className="body2 !font-normal">{t('multiple_file')}</p>}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item>
                                <FormControlLabel
                                    value={FILE_UPLOAD_TYPES.SINGLE}
                                    control={<Radio size="small" />}
                                    label={<p className="body2 !font-normal">{t('single_file')}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

export default React.memo(ExtraFiles);
