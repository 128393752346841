import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Popper, Paper, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import AddAnotherMemberSVG from 'assets/images/svg/AddAnotherMemberSVG';
import Tabs from 'components/tabs/Base';
import CellBaseVirtualize from 'components/selects/virtualize/CellBaseVirtualize';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import hexToRgba from 'hex-to-rgba';
import { useDispatch } from 'react-redux';
import { useSelectedWorkspaceByWorkspaceIds, useAvailableWorkspaceOptionsInLQA } from 'hooks/workspace';
import { sendManualTrack } from 'tracker';
import * as gridUIActions from 'gridUI/actions';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: `40px ${theme.spacing(4)}px`
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    subRow: {
        padding: theme.spacing(4),
        overflowY: 'auto'
    },
    assignedProject: {
        height: 36,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        border: `1px solid ${theme.colors.border}`,
        borderTop: 'none'
    },
    header: {
        background: theme.colors.paleGrey,
        height: 40,
        border: 'none'
    },
    link: {
        color: theme.colors.dodgerBlue,
        textDecoration: 'underline'
    },
    table: {
        border: `1px solid ${theme.colors.border}`
    },
    indicator: {
        width: 10,
        height: 10,
        borderRadius: 2
    },
    headerRow: {
        background: theme.colors.paleGrey
    },
    dlFlex: {
        display: 'flex'
    },
    addProjectText: {
        color: theme.colors.lightLavender
    },
    openAssignProject: {
        cursor: 'pointer'
    },
    zIndex: {
        zIndex: theme.zIndex.modal + 200
    },
    paper: {
        minWidth: 300
    }
}));

const LQA_DESCRIPTION = {
    LISA: {
        name: 'LISA',
        content: `is one of the default QA models which your company can use to structure the feedback given by reviewers on the quality of translations. This model is based on the LISA QA metric from the Localization Industry Standards Association.`,
        link: `http://producthelp.sdl.com/SDL_TMS_2011/en/Creating_and_Maintaining_Organizations/Managing_QA_Models/LISA_QA_Model.htm`
    },
    'SEA J2450': {
        name: 'SEA J2450',
        content: `is one of the default QA models which your company can use to structure the feedback given by reviewers on the quality of translations.`,
        link: `http://producthelp.sdl.com/SDL_TMS_2011/en/Creating_and_Maintaining_Organizations/Managing_QA_Models/SAE_J2450_QA_Model.htm`
    },
    'TAUS DQF MQM': {
        name: 'The TAUS Dynamic Quality Framework (DQF)',
        content: `was developed over the last couple of years in consultation with its members. DQF includes various tools for the evaluation of translation quality, the error typology being one them.`,
        link: `https://www.taus.net/qt21-project#harmonized-error-typology`
    }
};

function LQAPage({ categories, projects = [], name, id, severities }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const projectIds = projects?.filter(project => !project?.isDeleted)?.map(project => project?.id);

    const selectedOptions = useSelectedWorkspaceByWorkspaceIds(projectIds);
    const options = useAvailableWorkspaceOptionsInLQA(projectIds);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const severityMap = React.useMemo(() => {
        return {
            minor: hexToRgba(theme.colors.atlantis, 0.8),
            major: hexToRgba(theme.colors.sun, 0.8),
            critical: hexToRgba(theme.colors.brightRed, 0.8),
            neutral: hexToRgba(theme.colors.dodgerBlue, 0.8),
            kudos: hexToRgba(theme.colors.crusta, 0.8)
        };
    }, [theme]);

    const getSeverityColor = React.useCallback(
        key => {
            return severityMap?.[key] || severityMap?.minor;
        },
        [severityMap]
    );

    const handleOptionChange = options => {
        const workspaceIds = options?.map(option => option.value);
        sendManualTrack({
            type: `Change LQA Workspace`,
            customData: {
                workspaceIds
            }
        });
        dispatch(
            gridUIActions.addProjectsToLqa({
                addProject: {
                    id: options?.[0]?.value,
                    name: options?.[0]?.label
                },
                id,
                successCallback: () => {
                    console.log('done');
                },
                errorCallback: () => {
                    console.log('error');
                }
            })
        );

        handleClickAway();
    };

    const removeProject = projectId => {
        dispatch(
            gridUIActions.removeProjectsToLqa({
                projectId,
                id,
                successCallback: () => {
                    console.log('done');
                },
                errorCallback: () => {
                    console.log('error');
                }
            })
        );
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const rows = React.useMemo(() => {
        const data = [];
        for (const category of categories) {
            const { subCategories } = category;

            for (const subCategory of subCategories) {
                const { severities } = subCategory;
                let body = {
                    category: category?.name,
                    subCategory: subCategory?.name
                };

                for (const severity of severities) {
                    body = {
                        ...body,
                        ...severity,
                        [severity?.name]: severity?.score
                    };
                }

                data.push(body);
            }
        }

        return data;
    }, [categories]);

    const open = Boolean(anchorEl);

    const tabs = [
        {
            label: 'Assigned Project',
            content: (
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <Typography variant="body2">
                                    Assign this model to a specific project and use it for LQA activity
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid
                                            item
                                            container
                                            alignItems="center"
                                            justify="space-between"
                                            className={`${classes.header} ${classes.assignedProject}`}
                                        >
                                            <Grid item>
                                                <Typography variant="body1">Project assigned</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    onClick={handleClick}
                                                    className={classes.openAssignProject}
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Grid item className={classes.dlFlex}>
                                                        <AddAnotherMemberSVG />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2" className={classes.addProjectText}>
                                                            Add Project
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Popper
                                            className={classes.zIndex}
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            transition
                                        >
                                            <Paper className={classes.paper}>
                                                <CellBaseVirtualize
                                                    options={options}
                                                    placeholder={t('locale_select_search_placeholder')}
                                                    onChange={handleOptionChange}
                                                    maxSize={200}
                                                    defaultValue={[]}
                                                    onBlur={handleClickAway}
                                                    isMulti
                                                />
                                            </Paper>
                                        </Popper>
                                    </Grid>

                                    {selectedOptions?.map(project => {
                                        return (
                                            <Grid item key={project?.value}>
                                                <Grid
                                                    className={`${classes.assignedProject}`}
                                                    container
                                                    direction="row"
                                                    alignItems="center"
                                                    justify="space-between"
                                                >
                                                    <Grid item>
                                                        <Grid container direction="row" spacing={2}>
                                                            <Grid item>
                                                                <WorkspaceSVG />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {project?.label}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item onClick={() => removeProject(project?.value)}>
                                                        <Typography
                                                            style={{ color: theme.colors.brightRed, cursor: 'pointer' }}
                                                            variant="body2"
                                                        >
                                                            Remove
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        },
        {
            label: 'About this model',
            content: (
                <Grid container style={{ width: '80%' }} direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="body1" display="inline">
                            {LQA_DESCRIPTION[name]?.name || name}
                        </Typography>{' '}
                        <Typography variant="body2" display="inline">
                            {LQA_DESCRIPTION[name]?.content}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link href={LQA_DESCRIPTION[name]?.link} target="_blank">
                            <Typography variant="body2" className={classes.link}>
                                See more about {name}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.headerRow}>
                                        <TableCell></TableCell>
                                        <TableCell align="right">Category</TableCell>
                                        <TableCell align="right">Sub-category</TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            Severity
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        {severities?.map(severity => {
                                            return (
                                                <TableCell key={severity?.id} align="right">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="flex-end"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Grid item>
                                                            <Typography variant="body2" display="inline">
                                                                {severity?.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <div
                                                                className={`${classes.indicator}`}
                                                                style={{
                                                                    background: getSeverityColor(
                                                                        severity?.name?.toLowerCase()
                                                                    )
                                                                }}
                                                            ></div>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="right">{row.category}</TableCell>
                                            <TableCell align="right">{row.subCategory}</TableCell>
                                            {severities?.map(severity => {
                                                return (
                                                    <TableCell key={severity?.id} align="right">
                                                        <Typography variant="body2" display="inline">
                                                            {row?.[severity?.name]}
                                                        </Typography>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )
        }
    ];

    return (
        <Grid container className={classes.subRow}>
            <Tabs tabs={tabs} />
        </Grid>
    );
}

export default React.memo(LQAPage);
