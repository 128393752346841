import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import { useMemberMappingByEmail } from 'hooks/permission/member';
import { getFullName } from 'utils/name';
import { useLatestRecordHistoryData } from 'hooks/gridUI';
import { getFriendlyTime } from 'utils/datetime';
import { TEXT_LINE_HEIGHT } from 'const/gridUI';
import { isKbEscape, isKbEnter, isKbTab, isKbShiftTab, isKbPageEnd, isKbPageStart } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import diff_match_patch from 'diff-match-patch';
import TooltipArrows from 'gridUI/extendViews/common/TooltipArrows';
import TokenEditor from 'components/tag';
import ShowSpecialCharacter from '../../common/ShowSpecialCharacter';
import { serializeTag } from 'components/formula/serialize';
import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
// import ShowDiffChange from 'gridUI/extendViews/common/ShowDiffChange';
import ShowCellCannotEdit from 'gridUI/extendViews/common/ShowCellCannotEdit';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { checkContainId } from 'utils/clickAway';
import * as columnTypes from 'const/columnTypes';
import 'diff-match-patch-line-and-word';

const diff = new diff_match_patch();

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '1920px',
        margin: '0 auto',
        padding: `17px 24px`,
        height: 'auto'
    },
    panel: {
        background: theme.colors.white,
        borderRadius: 8,
        height: `auto`
    },
    header: {
        height: '48px',
        paddingLeft: 16,
        paddingRight: 16,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    footer: {
        height: '48px',
        paddingLeft: 16,
        paddingRight: 16,
        borderTop: `1px solid ${theme.colors.border}`
    },
    inputWrapper: {
        padding: 16
    },
    singleText: {
        width: '100%',
        padding: `16px 12px`,
        borderRadius: 8,
        overflow: 'auto',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
        // resize: 'vertical !important',
        border: `1px solid ${theme.colors.border}`,
        '&:focus': {
            border: `1px solid ${theme.colors.highlight}`
        },
        '& *': {
            lineHeight: `${TEXT_LINE_HEIGHT}px`
        },
        '& p': {
            marginTop: 0,
            marginBottom: 0
        }
    },
    disabled: {
        background: theme.colors.ghostwhite,
        border: 0
    },
    icon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        background: theme.colors.solitude,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    diff: {
        margin: 0,
        width: '100%',
        padding: `16px 12px`,
        border: `1px solid ${theme.colors.border}`,
        background: theme.colors.backgroundDisabled,
        borderRadius: 8,
        '& *': {
            lineHeight: `${TEXT_LINE_HEIGHT}px`
        }
    },
    deletedText: {
        background: theme.colors.pinka,
        textDecoration: `line-through`,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    diffText: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    updatedText: {
        background: theme.colors.griptide
    }
}));

function SingleLine({
    column,
    isReadOnly,
    rowId,
    columnId,
    rowIndex,
    columnIndex,
    data,
    originalValue,
    showDiff,
    setShowDiff,
    height
}) {
    const classes = useStyles();
    const latestRecordHistoryData = useLatestRecordHistoryData();
    const memberMappingByEmail = useMemberMappingByEmail();
    const [rawText, setRawText] = React.useState([{ type: 'paragraph', children: [{ text: originalValue || '' }] }]);
    const [showSpecialCharacter, setShowSpecialCharacter] = React.useState(false);

    const isEnterAlready = React.useRef(false);
    const dispatch = useDispatch();
    const editorRef = React.useRef();
    const rootRef = React.useRef();

    const value = React.useMemo(() => {
        if (typeof rawText !== 'object') return rawText;
        return serializeTag(rawText);
    }, [rawText]);

    // const toggleShowDiff = React.useCallback(() => {
    //     setShowDiff(!showDiff);
    //     setShowSpecialCharacter(false);
    // }, [showDiff, setShowDiff]);

    const toggleSpecialCharacter = React.useCallback(() => {
        setShowDiff(false);
        setShowSpecialCharacter(!showSpecialCharacter);
    }, [showSpecialCharacter, setShowDiff]);

    const isSameData = React.useMemo(() => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    }, [originalValue, value]);

    useClickAwaitListener(
        rootRef,
        e => {
            if (!ReactEditor.isFocused(editorRef?.current)) {
                return false;
            }
            if (checkContainId(e, 'translate-special-character')) {
                return false;
            }

            handleCellClickAwayAndStay(e);
        },
        0
    );

    const handleCellClickAwayAndStay = React.useCallback(
        e => {
            if (isSameData) {
                return;
            }
            isEnterAlready.current = true;
            ReactEditor.blur(editorRef.current);
            dispatch(
                gridUIActions.cellClickAwayAndStay({
                    value,
                    rowId: rowId,
                    columnId: columnId,
                    type: columnTypes.SINGLE_LINE
                })
            );
        },
        [dispatch, value, columnId, rowId, isSameData]
    );

    React.useEffect(() => {
        const editor = editorRef.current;
        if (editor) {
            Transforms.select(editor, Editor.start(editor, []));
            // if (!isReadOnly) {
            //     ReactEditor.focus(editor);
            // }
            setRawText([{ type: 'paragraph', children: [{ text: originalValue || '' }] }]);
            setTimeout(() => {
                Transforms.select(editor, Editor.end(editor, []));
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowId, columnId, isReadOnly]);

    const compareTextValue = React.useMemo(() => {
        const matchedColumn = latestRecordHistoryData?.cellHistories?.[columnId];
        if (!matchedColumn) return '';
        return matchedColumn?.previousData || '';
    }, [latestRecordHistoryData, columnId]);

    const handleMoveNextRow = React.useCallback(
        e => {
            e.preventDefault();
            if (isSameData) {
                dispatch(gridUIActions.cancelCellEdit());
                dispatch(gridUIActions.moveCellToNextRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridUIActions.cellClickAwayAndGoNextRow({
                    value,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        },
        [columnId, rowId, columnIndex, rowIndex, dispatch, value, isSameData]
    );

    const handleMovePreviousRow = React.useCallback(
        e => {
            e.preventDefault();
            if (isSameData) {
                dispatch(gridUIActions.cancelCellEdit());
                dispatch(gridUIActions.moveCellToPreviousRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridUIActions.cellClickAwayAndGoPreviousRow({
                    value,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        },
        [columnId, rowId, columnIndex, rowIndex, dispatch, value, isSameData]
    );

    const handleKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                dispatch(gridUIActions.cancelCellEdit());
            }

            if (isKbEnter(e) || isKbTab(e)) {
                e.preventDefault();
                if (isSameData) {
                    dispatch(gridUIActions.cancelCellEdit());
                    dispatch(gridUIActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                    return;
                }
                isEnterAlready.current = true;
                return dispatch(
                    gridUIActions.cellClickAwayAndGoNextColumn({
                        value,
                        rowId,
                        columnId,
                        rowIndex,
                        columnIndex
                    })
                );
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                if (isSameData) {
                    dispatch(gridUIActions.cancelCellEdit());
                    dispatch(gridUIActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
                    return;
                }
                isEnterAlready.current = true;
                return dispatch(
                    gridUIActions.cellClickAwayAndGoPreviousColumn({
                        value,
                        rowId,
                        columnId,
                        rowIndex,
                        columnIndex
                    })
                );
            }

            if (isKbPageEnd(e)) {
                handleMoveNextRow(e);
                return;
            }

            if (isKbPageStart(e)) {
                handleMovePreviousRow(e);
                return;
            }
        },
        [columnId, rowId, columnIndex, isSameData, rowIndex, dispatch, value, handleMoveNextRow, handleMovePreviousRow]
    );

    const diffResult = React.useMemo(() => {
        return diff.diff_wordMode(compareTextValue || '', originalValue || '');
    }, [compareTextValue, originalValue]);

    return (
        <Grid className={classes.root} container ref={rootRef}>
            <Grid container direction="column" className={classes.panel}>
                <Grid
                    item
                    container
                    className={classes.header}
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item alignItems="center" style={{ minHeight: 36, display: 'flex', alignItems: 'center' }}>
                        <ColumnTypeDisplay {...column} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <p className="caption inline">by</p>{' '}
                                <p className="body1 inline">
                                    {getFullName(memberMappingByEmail?.[latestRecordHistoryData?.alteredBy])}
                                </p>{' '}
                                <p className="caption inline">
                                    {getFriendlyTime(latestRecordHistoryData?.alteredTime, `MM-DD-YYYY hh:mm`)}
                                </p>{' '}
                            </Grid>
                            <TooltipArrows handleUp={handleMovePreviousRow} handleDown={handleMoveNextRow} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <div className={classes.inputWrapper}>
                        {showDiff ? (
                            <pre
                                className={classes.diff}
                                style={{
                                    maxHeight: height - 96 - 24 * 2 - 16 * 2,
                                    overflowY: 'auto'
                                }}
                            >
                                {diffResult.map((item, index) => {
                                    const [status, content] = item;
                                    const getClassName = status => {
                                        switch (status) {
                                            case 0:
                                                return;
                                            case 1:
                                                return classes.updatedText;
                                            case -1:
                                                return classes.deletedText;
                                            default:
                                                return;
                                        }
                                    };
                                    return (
                                        <span key={index} className={getClassName(status)}>
                                            {content}
                                        </span>
                                    );
                                })}
                            </pre>
                        ) : (
                            <TokenEditor
                                value={rawText}
                                suggestions={[]}
                                parentTags={[]}
                                suggestionZIndex={1302}
                                onBlur={() => {}}
                                placeholder=""
                                className={`${classes.multiText} ${isReadOnly ? classes.disabled : ``} cell-editor`}
                                predefinedTokens={[]}
                                tokenDetection={[]}
                                style={{
                                    maxHeight: height - 96 - 24 * 2 - 16 * 2
                                }}
                                // autoFocus={!isReadOnly}
                                inputRef={editorRef}
                                isHighlightSymbolCharacter={showSpecialCharacter}
                                readOnly={isReadOnly}
                                onKeyDown={handleKeyDown}
                                onChange={value => setRawText(value)}
                                isSingleLine
                            />
                        )}
                    </div>
                </Grid>
                <Grid item>
                    <Grid
                        className={classes.footer}
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <p className="body2 text-[12px]">{value?.length} characters</p>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="row" alignItems="center">
                                <ShowSpecialCharacter
                                    id="translate-special-character"
                                    showSpecialCharacter={showSpecialCharacter}
                                    onClick={toggleSpecialCharacter}
                                />
                                {/* <ShowDiffChange showDiff={showDiff} onClick={toggleShowDiff} /> */}
                                {isReadOnly && <ShowCellCannotEdit />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SingleLine;
