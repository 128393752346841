import React from 'react';

function CommentNotifySVG({ color = '#EA6A46', ...other }) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0662 0.75C10.8122 0.75 11.4236 1.32589 11.4801 2.05737L11.4844 2.1682V10.882C11.4844 11.1839 11.1432 11.3455 10.9119 11.1799L10.8642 11.1394L9.46078 9.73594C9.40963 9.68531 9.34503 9.6509 9.27529 9.63686L9.20414 9.62977L1.43039 9.60938C0.882449 9.60938 0.431048 9.19021 0.379838 8.65611L0.375 8.55469V2.1682C0.375 1.42222 0.950893 0.810805 1.68237 0.754267L1.7932 0.75H10.0662ZM6.4038 5.672V6.72669H2.91015V5.672H6.4038ZM8.81221 4.61731V3.56263H2.91015V4.61731H8.81221Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(CommentNotifySVG);
