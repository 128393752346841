import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'advancedSearch/actions';
import ViewCellInfo from './ViewCellInfo';
import { CELL_PADDING, BORDER_HIGHLIGHT } from 'const/gridUI';
import { isKbEscape, isKbEnter, isKbTab, isKbShiftTab } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import trim from 'lodash/trim';
import { getCellBackgroundByHex } from 'utils/color';

const useStyles = makeStyles(theme => ({
    singleText: {
        width: '100%',
        height: 32,
        padding: CELL_PADDING,
        fontSize: '0.875rem',
        border: `none`,
        outline: 'none'
    }
}));

function SingleTextEdit({
    columnId,
    value: cellValue,
    rowId,
    width,
    height,
    rowIndex,
    background,
    fillColorBlindness,
    columnIndex,
    originalValue
}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(cellValue);
    const classes = useStyles();
    const theme = useTheme();
    const isEnterAlready = useRef(false);
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const isSameData = () => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    };

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        if (isSameData()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameData()) {
            return dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value: trim(value),
                    rowId,
                    columnId
                })
            );
        }
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            dispatch(gridActions.cancelCellEdit());
        }
        if (isKbEnter(e)) {
            e.preventDefault();
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoNextRow({
                    value: trim(value),
                    rowId,
                    rowIndex,
                    columnId,
                    columnIndex
                })
            );
        }

        if (isKbTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            return dispatch(
                gridActions.cellClickAwayAndGoNextColumn({
                    value: trim(value),
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }

        if (isKbShiftTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            return dispatch(
                gridActions.cellClickAwayAndGoPreviousColumn({
                    value: trim(value),
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }
    };

    const handleInputChange = e => {
        setValue(e.target.value.replace(/\s/, ''));
    };

    return (
        <div ref={rootRef} style={{ position: 'relative', height }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <div
                style={{
                    width,
                    height,
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                    overflow: 'hidden',
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white
                }}
            >
                <input
                    type="text"
                    className={`${classes.singleText} cell-editor`}
                    value={value || ''}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    autoFocus
                    style={{
                        background: background
                            ? fillColorBlindness === 'BLIND'
                                ? `${background} url(${getCellBackgroundByHex(background)})`
                                : background
                            : theme.colors.white
                    }}
                />
            </div>
        </div>
    );
}

export default React.memo(SingleTextEdit);
