import { enqueueSnackbar } from 'notifier/actions';
import {
    createTermApi,
    deleteTBApi,
    deleteTermsApi,
    exportTBApi,
    getListTermsBase,
    getTBApi,
    getTBAuditorsAPI,
    getTermApi,
    getTermsApi,
    importTBApi,
    searchInParagraphApi,
    updateTBApi,
    updateTermApi,
    updateTermDetailApi
} from 'services/tb';
import * as types from '../types';

import axios from 'axios';
const CancelToken = axios.CancelToken;
let sourceCancel = null;

export function fetchListTermsBase({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responseData = await getListTermsBase();
            dispatch(setListTermsBase(responseData));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateTBAction({ tb }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { listTermBases } = gridUI;
        const newListTermBases = listTermBases.map(tbStore => {
            if (tbStore.id === tb.id) {
                return tb;
            }
            return tbStore;
        });
        dispatch(setListTermsBase(newListTermBases));
    };
}

export function updateTB({ oldTB, newTB, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_updateTBAction({ tb: newTB }));
        try {
            await updateTBApi({ tbId: newTB.id, body: newTB });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_updateTBAction({ tb: oldTB }));
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteTB({ tbId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            await deleteTBApi(tbId);
            successCallback && successCallback();
            setTimeout(() => {
                const { gridUI } = getState();
                const { listTermBases } = gridUI;
                const idx = listTermBases.findIndex(tbStore => tbStore.id === tbId);
                if (idx > -1) {
                    listTermBases.splice(idx, 1);
                    dispatch(setListTermsBase([...listTermBases]));
                }
            }, 800);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function getTBDetail({ tbId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responses = await Promise.all([getTBApi(tbId), getTBAuditorsAPI({ tbId })]);
            successCallback && successCallback(responses);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function getTBDetailAndTermDetail({ tbId, termId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responses = await Promise.all([getTBApi(tbId), getTermApi({ tbId, termId })]);
            successCallback && successCallback(responses);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateTermDetail({ tbId, termId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responses = await updateTermDetailApi({ tbId, termId, body });
            successCallback && successCallback(responses);
            dispatch(
                enqueueSnackbar({
                    message: 'Saved',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function createTerm({ tbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await createTermApi(tbId, body);
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Added',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateTerm({ tbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const responseData = await updateTermApi(tbId, body);
            successCallback && successCallback(responseData);
            dispatch(
                enqueueSnackbar({
                    message: 'Saved',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteTerms({ tbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await deleteTermsApi(tbId, body);
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Deleted',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function getTerms({ tbId, params, successCallback, errorCallback }) {
    return async function(dispatch) {
        let isCancel = false;
        try {
            if (sourceCancel) {
                sourceCancel.cancel();
                isCancel = true;
            }

            sourceCancel = CancelToken.source();

            const response = await getTermsApi({ tbId, params, cancelToken: sourceCancel?.token });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            if (!isCancel) {
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
            }
            errorCallback && errorCallback(message);
        }
    };
}

export function setListTermsBase(payload) {
    return {
        type: types.SET_LIST_TERMS_BASE,
        payload
    };
}

export function exportTerms({ tbId, format, langCodes, includeAttributes, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await exportTBApi(tbId, format, langCodes, includeAttributes);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function importTerms({ tbId, file, importOption, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await importTBApi(tbId, file, importOption);
            dispatch(
                enqueueSnackbar({
                    message: 'The import has completed successfully',
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function searchInParagraph({ body, sourceRowId, sourceColumnId, targetLang, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { workspaceId, dataFromTB } = gridUI;
            dispatch(
                setDataFromTB({
                    sourceData: dataFromTB?.sourceData
                })
            );
            const data = await searchInParagraphApi({
                projectId: workspaceId,
                ...body
            });

            let newDataFromTB = {
                sourceData: dataFromTB?.sourceData
            };

            for (let i = 0; i < data.length; i++) {
                const tb = data[i];
                const { sourceTerm, translationTerms } = tb;
                if (!newDataFromTB?.sourceData?.[sourceRowId]) {
                    newDataFromTB.sourceData[sourceRowId] = {};
                }

                if (!newDataFromTB.sourceData[sourceRowId][sourceTerm.lang]) {
                    newDataFromTB.sourceData[sourceRowId][sourceTerm.lang] = {};
                }

                if (i === 0) {
                    newDataFromTB.sourceData[sourceRowId][sourceTerm.lang][targetLang] = [];
                }

                const terms = translationTerms
                    .filter(translation => translation.lang === targetLang)
                    ?.map(t => ({
                        langSourceText: sourceTerm.text,
                        langTargetText: t.text,
                        term: t
                    }));

                newDataFromTB.sourceData[sourceRowId][sourceTerm.lang][targetLang] = [
                    ...newDataFromTB.sourceData[sourceRowId][sourceTerm.lang][targetLang],
                    ...terms
                ];
            }

            dispatch(setDataFromTB(newDataFromTB));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function setDataFromTB(payload) {
    return {
        type: types.SET_DATA_FROM_TB,
        payload
    };
}
