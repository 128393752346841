import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import ExportSVG from 'assets/images/svg/ExportSVG';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/dialog/Dialog';
import ExportView from 'gridUI/importExport/Export';
import { useParams } from 'react-router-dom';
import ListItem from 'components/list/Item';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    }
}));

const ManageExport = ({ handleMoreActionsClickAway, isInIconMore }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { workspaceId, gridId, viewId, dbId, branchId } = useParams();
    const [open, setOpen] = React.useState(false);

    const handleOpenExport = React.useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
        setOpen(false);
        handleMoreActionsClickAway && handleMoreActionsClickAway();
    }, [handleMoreActionsClickAway]);

    return (
        <>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.IMPORT_EXPORT}>
                {isInIconMore ? (
                    <Grid item tr-dt="Open Export Setting" id="g-h-menu-item-export" onClick={handleOpenExport}>
                        <ListItem
                            icon={<ExportSVG height={18} width={18} color={theme.colors.darkness} />}
                            name={t('toolbar_export_data_tooltip')}
                        />
                    </Grid>
                ) : (
                    <Tooltip title={t('toolbar_export_data_tooltip')}>
                        <Grid item className={classes.menuItem}>
                            <ButtonBase
                                tr-dt="Open Export Setting"
                                id="g-h-menu-item-export"
                                className={`${classes.addButton} ${classes.button}`}
                                onClick={handleOpenExport}
                            >
                                <ExportSVG height={18} width={18} color={theme.colors.darkness} />
                            </ButtonBase>
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            <Dialog
                id="dialog-import-export"
                maxWidth={false}
                open={open}
                onClose={handleCloseModal}
                style={{ zIndex: 1301 }}
            >
                <ExportView
                    onClose={handleCloseModal}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                />
            </Dialog>
        </>
    );
};

export default React.memo(ManageExport);
