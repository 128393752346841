import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import UnicornPopup from './UnicornPopup';
import GeneralSettingSVG from 'assets/images/svg/GeneralSettingSVG';
import PopperMenu from 'components/menus/Popper';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { useCompanyInfo } from 'hooks/app';
import { useCompanyId } from 'hooks/auth';
import * as appActions from 'app/actions';
import { useDispatch } from 'react-redux';
import Avatar from 'components/avatar/Company';
import { getCompanyLogoUrl } from 'utils/images';
import * as roleActions from 'permission/actions/role';
import * as workspaceActions from 'workspaces/actions';
import * as paymentActions from 'payment/actions';
import * as groupActions from 'permission/actions/group';
import { HEIGHT_UNICORN } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        paddingLeft: 14,
        paddingRight: 14,
        height: HEIGHT_UNICORN
    },
    avatar: {
        paddingTop: 41,
        paddingBottom: 29,

        [theme.breakpoints.down('xs')]: {
            paddingTop: 14,
            paddingBottom: 14
        }
    },
    settingIcon: {
        position: 'relative',
        cursor: 'pointer'
    },
    companyName: {
        fontWeight: 500,
        minHeight: 30,
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

const COMPANY_TEXT_MENU_ID = 'company_context_menu_id';

function Unicorn() {
    const classes = useStyles();
    const theme = useTheme();
    const companyId = useCompanyId();
    const contextMenuId = useSelector(state => state.app.contextMenuId);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const $arrowRef = React.useRef();
    const companyInfo = useCompanyInfo();

    const dispatch = useDispatch();

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspaceRoles({
                successCallback: () => {
                    console.log('fetch ProjectRoles successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch ProjectRoles');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            roleActions.fetchRoles({
                successCallback: () => {
                    console.log('fetch roles successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch roles');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            appActions.fetchCompanyInfo({
                successCallback: () => {
                    console.log('fetch company info successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch company info');
                }
            })
        );
    }, [dispatch, companyId]);

    React.useEffect(() => {
        dispatch(groupActions.fetchGroups({}));
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchCardInfo({
                errorCallback: () => {
                    console.log('fetchCardInfo failed');
                },
                successCallback: () => {
                    console.log('fetchCardInfo success');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        if (contextMenuId && contextMenuId !== COMPANY_TEXT_MENU_ID) {
            handleClickAway();
        }
    }, [contextMenuId]);

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleOnContextClick = () => {
        dispatch(appActions.changeContext({ contextMenuId: COMPANY_TEXT_MENU_ID }));
        let $arrow = $arrowRef.current;
        if ($arrow) {
            $arrow.click();
        }
    };

    return (
        <Grid container className={classes.root} direction="column" wrap="nowrap">
            <Grid item container justify="center">
                <Grid item className={classes.avatar}>
                    <Avatar size={90} src={getCompanyLogoUrl(companyInfo?.logoUrl)} label={companyInfo?.name} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    onContextMenu={handleOnContextClick}
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid
                        item
                        style={{ width: `calc`, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                        <Typography className={classes.companyName} variant="h3">
                            {companyInfo?.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <span
                            tr-dt="Open General Setting"
                            onClick={handleClick}
                            ref={$arrowRef}
                            style={{ display: 'flex' }}
                        >
                            <GeneralSettingSVG
                                color={anchorEl ? theme.palette.primary.main : undefined}
                                className={classes.settingIcon}
                            />
                        </span>
                        {anchorEl && (
                            <PopperMenu anchorEl={anchorEl} handleClickAway={handleClickAway} placement="bottom-start">
                                <UnicornPopup
                                    company={{
                                        name: 'Localize Direct',
                                        id: companyId
                                    }}
                                    handleClickAway={handleClickAway}
                                />
                            </PopperMenu>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(Unicorn);
