import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SidebarRightLayout from '../layout/rightSidebar';
import CurrentLQASVG from 'assets/images/svg/addon/CurrentLQASVG';
import { useGetLQAModelDefault } from 'hooks/gridUI/lqa';
import hexToRgba from 'hex-to-rgba';
import { useRole } from 'hooks/auth/role';
import { COMPANY_AUTHORITIES } from 'auth/roleConst';
import * as roleConst from 'auth/roleConst';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    box: {
        width: 263,
        height: 56,
        borderRadius: 4,
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.1)
    },
    highlight: {
        color: theme.palette.primary.main
    },
    link: {
        textDecoration: 'none',
        color: theme.colors.dodgerBlue
    }
}));

function LqaModels({ handleClose, ...rest }) {
    const classes = useStyles();
    const defaultLQA = useGetLQAModelDefault();
    const roles = useRole();

    const canManageLQASetting = roles?.[COMPANY_AUTHORITIES.MANAGE_LQA] === roleConst.FULL;

    return (
        <SidebarRightLayout title={'LQA Models'} onClose={handleClose}>
            <Grid
                container
                alignItems={'center'}
                justify={'center'}
                spacing={2}
                className={classes.root}
                direction="column"
            >
                <Grid item>
                    <CurrentLQASVG />
                </Grid>
                <Grid item>
                    <p className="body1">Current LQA Model</p>
                </Grid>
                <Grid item>
                    <Grid container direction="row" className={classes.box} alignItems="center" justify="center">
                        <h3 className="prose prose-2xl font-medium text-text-primary">{defaultLQA?.name}</h3>
                    </Grid>
                </Grid>
                {canManageLQASetting && (
                    <Grid item>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item>
                                <p className="body2">LQA Model can be configured in</p>
                            </Grid>
                            <Grid item>
                                <Link to="/localization-settings/lqa" className={'body1 text-blue-dodger no-underline'}>
                                    Localize setting/LQA Models
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </SidebarRightLayout>
    );
}

export default LqaModels;
