import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import ButtonBase from 'components/button/Base';
import ListCompanyInfo from './ListCompanyInfo';
import InputText from 'components/inputs/InputText';
import { useCurrentUserEmail } from 'hooks/auth';
import InfoContainedIconSVG from 'assets/images/svg/InfoContainedIconSVG';
import DeleteAccountConfirm from './DeleteAccountConfirm';
import ThreeStatesCheckbox from 'components/checkbox/ThreeStateCheckBox';
import { useDispatch } from 'react-redux';
import { requestDeleteAccount } from 'auth/actions';
import CircularProgress from 'components/CircularProgress';

const CanDeleteAccount = ({ companyInfo, onClose }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentUserEmail = useCurrentUserEmail();
    const [email, setEmail] = useState('');
    const [openDeleteAccountConfirm, setOpenDeleteAccountConfirm] = useState(false);
    const [unsubscribe, setUnsubscribe] = useState(false);
    const [requesting, setRequesting] = useState(false);

    const disableDeleteButton = useMemo(() => {
        return !email || email !== currentUserEmail || requesting;
    }, [email, currentUserEmail, requesting]);

    const handleChangeEmail = useCallback(e => {
        setEmail(e?.target?.value);
    }, []);

    const handleRequestDeleteAccount = useCallback(() => {
        setRequesting(true);
        dispatch(
            requestDeleteAccount({
                unsubscribe,
                successCallback: () => {
                    setRequesting(false);
                    setOpenDeleteAccountConfirm(true);
                },
                errorCallback: () => {
                    setRequesting(false);
                }
            })
        );
    }, [dispatch, unsubscribe]);

    const handleChangeSubscribe = useCallback(() => {
        setUnsubscribe(prev => !prev);
    }, []);

    if (openDeleteAccountConfirm) {
        return <DeleteAccountConfirm companyInfo={companyInfo} onClose={onClose} />;
    }

    return (
        <div>
            <Typography className="!text-[32px] !leading-8" variant="body2">
                {t('permanent_delete_your_account')}
            </Typography>
            <Typography className="!mt-2 !text-[18px] !text-[#78778B]" variant="body2">
                {t(
                    companyInfo?.hasOwner
                        ? 'permanent_delete_your_account_owner_hint'
                        : 'permanent_delete_your_account_hint'
                )}
            </Typography>
            {companyInfo?.hasOwner && (
                <>
                    <Typography className="!mt-10" variant="body1">
                        {t('delete_account_owner_will_be_deleted')}
                    </Typography>
                    <div className="mt-3">
                        <ListCompanyInfo companies={companyInfo?.owners} showPlan />
                    </div>
                </>
            )}
            {companyInfo?.members?.length > 0 && (
                <>
                    <Typography
                        className={classnames({
                            '!mt-6': !!companyInfo?.owners,
                            '!mt-10': !companyInfo?.owners
                        })}
                        variant="body1"
                    >
                        {t('delete_account_removed_from_all_companies_below')}
                    </Typography>
                    <div className="mt-2">
                        <ListCompanyInfo
                            companies={companyInfo?.members}
                            maxHeight={!!companyInfo?.owners ? 218 : 374}
                            showRole
                        />
                    </div>
                </>
            )}
            <Typography className="!mt-10" variant="body1">
                {t('delete_account_type_your_email_to_confirm')}
            </Typography>
            <div className="mt-2">
                <InputText
                    error={email && email !== currentUserEmail}
                    value={email}
                    placeholder={currentUserEmail}
                    onChange={handleChangeEmail}
                />
            </div>
            <div className="mt-2 flex flex-nowrap gap-1 relative">
                <div
                    className={classnames('relative left-0', {
                        'top-0.5': !companyInfo?.requireCSConfirm,
                        'top-1': !!companyInfo?.requireCSConfirm
                    })}
                >
                    <InfoContainedIconSVG bgColor="#EB4F5E" />
                </div>
                <div>
                    <Typography variant="body2" className="!text-brightRed">
                        <Trans
                            i18nKey={
                                companyInfo?.requireCSConfirm
                                    ? 'delete_my_account_warning_2'
                                    : 'delete_my_account_warning_1'
                            }
                            components={{
                                br: <br />
                            }}
                        />
                    </Typography>
                </div>
            </div>
            <div className="mt-8 flex flex-nowrap gap-2 items-center cursor-pointer" onClick={handleChangeSubscribe}>
                <div>
                    <ThreeStatesCheckbox className="!p-0 !-top-[1px]" state={unsubscribe ? 1 : 2} />
                </div>
                <div>{t('delete_account_unsubscribe')}</div>
            </div>
            <div className="mt-4 flex flex-nowrap gap-8">
                <div className="flex-1">
                    <ButtonBase className="!w-full" variant="outlined" onClick={onClose}>
                        {t('global_cancel')}
                    </ButtonBase>
                </div>
                <div className="flex-1">
                    <ButtonBase
                        className={classnames('!w-full', {
                            '!bg-burntSlena': !disableDeleteButton
                        })}
                        variant="contained"
                        disabled={disableDeleteButton}
                        onClick={handleRequestDeleteAccount}
                    >
                        {t('delete_my_account')}
                        {requesting && <CircularProgress />}
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CanDeleteAccount);
