import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from 'components/button/Base';
import { useHistory } from 'react-router-dom';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function GuideModal({ onBack, onClose, onRedirect }) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const handlePaymentRedirect = () => {
        onRedirect && onRedirect();
        history.push(`/company-settings/billing/detail`);
    };

    return (
        <>
            <DialogTitle title={t('payment_guide_title')} onClose={onClose || onBack} />
            <DialogContent>
                <Grid container direction="column" className={classes.root} spacing={4}>
                    <Grid item className={classes.content}>
                        <Typography variant="body2" display="inline">
                            {t('payment_guide_were_sorry')}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.content}>
                        <Typography variant="body2" display="inline">
                            To invite more members, you need to{' '}
                        </Typography>
                        <Typography variant="body1" display="inline">
                            add payment method{' '}
                        </Typography>
                        <Typography variant="body2" display="inline">
                            to your company setup.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onBack || onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" width={172} onClick={handlePaymentRedirect}>
                            {t('payment_guide_add_payment_method')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(GuideModal);
