import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Popover, IconButton, Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
// import { HEADER_HEIGHT } from 'const';
import Expansion from './Expansion';
import BackupItemPopup from './BackupItemPopup';
import { POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM, INPUT_HEIGHT } from 'const/style';
import { setRightSidebar } from 'app/actions';
import RestoreConfirmModal from './RestoreConfirmModal';
import ScheduleModal from './ScheduleModal';
import * as databaseActions from 'databases/actions';
import {
    useBackupFiles,
    useDatabaseByDbIdInSidebarRight,
    useIsFetchingBackupFiles,
    useTotalBackupFilesCount,
    useBackupFilesOffset,
    useIsFetchedBackupFiles,
    useIsBackuping,
    useSchduleBackup
} from 'hooks/database';
import TimeSelect from 'components/selects/TimeSelect';
import moment from 'moment';
import NoBackupFileSVG from 'assets/images/svg/backup/NoBackupFileSVG';
import { AutoSizer } from 'react-virtualized-dn';
import ButtonBase from 'components/button/Base';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/spinner/Base';
import Divider from 'components/divider/Base';
import DateTimeSVG from 'components/svg-icon/DateTimeSVG';
import Tooltip from 'components/tooltip/Base';
import WarningRoundSVG from 'assets/images/svg/WarningRoundSVG';
import { getStartDateUTC } from 'utils/datetime';
import i18n from 'i18n';

// const HEADER_HISTORY_HEIGHT = 'auto';
const CONTENT_HEIGHT = `calc(100vh - ${96 + 215 + 145 + 120}px)`;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    header: {
        padding: '24px',
        minHeight: 96
    },
    wrapperHeader: {
        backgroundColor: '#F7F8FA',
        borderRadius: 4,
        padding: 24,
        minHeight: 215
    },
    content: {
        overflowY: 'auto',
        flex: 1,
        maxHeight: CONTENT_HEIGHT
    },
    popover: {
        '& .MuiPopover-paper': {
            boxShadow: theme.shadows[1],
            borderRadius: POPUP_RADIUS,
            paddingTop: POPUP_PADDING_TOP_BOTTOM,
            paddingBottom: POPUP_PADDING_TOP_BOTTOM,
            border: '1px solid #F0F0F0',
            backgroundColor: theme.colors.white,
            marginLeft: 10
        }
    },
    fullHeight: {
        height: '100%'
    },
    control: {
        padding: 24,
        width: '100%'
    },
    filterOptions: {
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        minHeight: INPUT_HEIGHT
    },
    selectSchedule: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: 14
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'unset'
        }
    }
}));

const timeDropDownOptions = [
    { value: 'all', label: i18n.t('global_all'), timestamp: 0 },
    { value: 'today', label: i18n.t('global_today'), timestamp: getStartDateUTC({ offset: 0, unit: 'd' }) },
    { value: 'yesterday', label: i18n.t('global_yesterday'), timestamp: getStartDateUTC({ offset: 1, unit: 'd' }) },
    { value: '7days', label: i18n.t('global_7days'), timestamp: getStartDateUTC({ offset: 7, unit: 'd' }) },
    { value: '15days', label: i18n.t('global_15days'), timestamp: getStartDateUTC({ offset: 15, unit: 'd' }) },
    { value: '30days', label: i18n.t('global_30days'), timestamp: getStartDateUTC({ offset: 30, unit: 'd' }) }
];

const ITEMS_PER_PAGE = 15;

function Backup() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const database = useDatabaseByDbIdInSidebarRight();
    const backupFiles = useBackupFiles() || [];
    const scheduleBackupData = useSchduleBackup() || null;
    const isFetchingBackupFiles = useIsFetchingBackupFiles();
    const [popoverAnchor, setPopoverAnchor] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalScheduleOpen, setModalScheduleOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(timeDropDownOptions[0]);
    const [backupFileSelected, setBackupFileSelected] = React.useState(null);
    const offset = useBackupFilesOffset();
    const total = useTotalBackupFilesCount();
    const isFetchedBackupFiles = useIsFetchedBackupFiles();
    const isBackuping = useIsBackuping();

    const options = React.useMemo(() => {
        return {
            query:
                selected.timestamp === 0
                    ? {}
                    : {
                          alteredTime: ['today'].includes(selected.value)
                              ? { '>=': selected.timestamp }
                              : ['yesterday'].includes(selected.value)
                              ? {
                                    between: [
                                        moment(selected.timestamp).startOf('day'),
                                        moment(selected.timestamp).endOf('day')
                                    ]
                                }
                              : { '>=': selected.timestamp }
                      }
        };
    }, [selected]);

    const handlePopupOpen = React.useCallback(({ target, backupFile }) => {
        setPopoverAnchor(target);
        setBackupFileSelected(backupFile);
    }, []);

    const handleBackupRestore = React.useCallback(() => {
        setPopoverAnchor(null);
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleModalScheduleClose = React.useCallback(() => {
        setModalScheduleOpen(false);
    }, []);

    const handleOptionChange = React.useCallback((e, option) => {
        setSelected(option);
    }, []);

    React.useEffect(() => {
        dispatch(
            databaseActions.getDatabaseBackupFiles({
                dbId: database?.id,
                offset: 0,
                limit: ITEMS_PER_PAGE,
                options
            })
        );
    }, [dispatch, database, options]);

    React.useEffect(() => {
        if (!database) {
            dispatch(setRightSidebar({ type: null }));
        }
    }, [database, dispatch]);

    React.useEffect(() => {
        dispatch(databaseActions.getScheduleBackupDatabaseAction({ dbId: database?.id }));
    }, [dispatch, database]);

    const handleClose = React.useCallback(() => {
        dispatch(setRightSidebar({ type: null }));
    }, [dispatch]);

    const handleRestoreAccept = React.useCallback(() => {
        sendManualTrack({
            type: `Restore Database`,
            customData: {
                dbId: database?.id
            }
        });
        dispatch(databaseActions.restoreDatabase({ dbId: database?.id, backupId: backupFileSelected.id }));
        setModalOpen(false);
        handleClose();
    }, [backupFileSelected, database, dispatch, handleClose]);

    const timeMapByDate = React.useMemo(() => {
        let result = {};
        if (backupFiles) {
            backupFiles.forEach(file => {
                const date = moment(file.alteredTime).startOf('day');
                if (!result[date]) result[date] = [];
                result[date] = [...result[date], file];
            });
        }
        Object.values(result).forEach(files =>
            files.sort((a, b) => new Date(b.alteredTime).getTime() - new Date(a.alteredTime).getTime())
        );
        return result;
    }, [backupFiles]);

    const filteredDates = React.useMemo(() => {
        return Object.keys(timeMapByDate).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    }, [timeMapByDate]);

    const handleScroll = React.useCallback(
        e => {
            const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight === 0;
            if (bottom && offset + ITEMS_PER_PAGE < total && !isFetchingBackupFiles) {
                dispatch(
                    databaseActions.getDatabaseBackupFiles({
                        dbId: database?.id,
                        offset: offset + ITEMS_PER_PAGE,
                        limit: ITEMS_PER_PAGE,
                        options
                    })
                );
            }
        },
        [database, dispatch, isFetchingBackupFiles, offset, options, total]
    );

    const handleBackupDatabase = React.useCallback(() => {
        sendManualTrack({ type: `Back Up Database` });
        dispatch(databaseActions.setIsBackuping({ dbId: database?.id, isBackuping: true }));
        dispatch(
            databaseActions.backupDatabase({
                dbId: database?.id,
                successCallback: () => {
                    console.log('backupDatabase success');
                },
                errorCallback: () => {
                    console.log('backupDatabase failed');
                }
            })
        );
    }, [database, dispatch]);

    const arrTimeLastBackup = React.useMemo(() => timeMapByDate[filteredDates?.[0]], [filteredDates, timeMapByDate]);

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item className={classes.header}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography variant="h4" className={classes.title}>
                            {t(`back_up_database`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseIconSVG size="medium" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid item className={classes.wrapperHeader} style={{ margin: '24px' }}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item container>
                        <Typography variant="h4" style={{ marginBottom: 16 }}>
                            {database?.name}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant="body5" style={{ fontWeight: 'normal', marginBottom: 16 }}>
                            {t(`last_backup`)}{' '}
                            {arrTimeLastBackup ? (
                                <strong>
                                    {moment(arrTimeLastBackup[0].alteredTime)
                                        .utc()
                                        .format('DD-MM-YYYY HH:mm')}{' '}
                                    UTC
                                </strong>
                            ) : (
                                "Don't have any backup"
                            )}{' '}
                        </Typography>
                    </Grid>
                    <Grid item container style={{ marginBottom: 32 }} spacing={1}>
                        <Grid item>
                            <Typography variant="body5" style={{ fontWeight: 'normal' }}>
                                {t(`back_up_schedule`)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {scheduleBackupData?.enabled ? (
                                <strong>
                                    {moment(scheduleBackupData?.scheduledTime)
                                        .utc()
                                        .format('HH:mm')}{' '}
                                    UTC
                                </strong>
                            ) : (
                                <Grid item container alignItems="center" style={{ display: 'inline-flex' }}>
                                    {' '}
                                    <Grid item>
                                        <strong style={{ color: '#EB4F5E', marginRight: 4 }}>
                                            {t(`not_set_backup`)}
                                        </strong>
                                    </Grid>
                                    <Tooltip
                                        maxWidth={false}
                                        title={t(`tooltip_not_yet_backup`)}
                                        placement="bottom-end"
                                    >
                                        <Grid item style={{ display: 'flex' }}>
                                            <WarningRoundSVG />
                                        </Grid>
                                    </Tooltip>{' '}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item direction="row" container alignItems="center" justify="space-between">
                        <Grid item>
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setModalScheduleOpen(!modalScheduleOpen)}
                            >
                                <Grid item style={{ display: 'flex' }}>
                                    <DateTimeSVG color={theme.palette.primary.main} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body5" className={classes.selectSchedule}>
                                        {t(`set_backup_schedule`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase variant="contained" onClick={handleBackupDatabase} disabled={isBackuping}>
                                {isBackuping ? <CircularProgress size={24} /> : t(`back_up`)}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />

            <Grid item className={classes.control}>
                <Grid container direction="column" wrap="nowrap" spacing={3}>
                    <Grid item>
                        <Typography variant="h4" style={{ marginBottom: 22 }}>
                            {t(`back_up_history`)}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.filterOptions}>
                        <AutoSizer style={{ height: '100%' }}>
                            {({ width, height }) => {
                                return (
                                    <TimeSelect
                                        width={width}
                                        options={timeDropDownOptions}
                                        selected={selected}
                                        onChange={handleOptionChange}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.content} onScroll={handleScroll}>
                {filteredDates.length === 0 && isFetchedBackupFiles && (
                    <Grid
                        className={classes.fullHeight}
                        container
                        spacing={4}
                        justify="center"
                        alignItems="center"
                        direction="column"
                        wrap="nowrap"
                    >
                        <Grid item>
                            <NoBackupFileSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" style={{ fontWeight: 'normal' }}>
                                {t(`back_up_you_dont_have_any_data_back_up`)}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {filteredDates.length > 0 &&
                    filteredDates.map((date, index) => (
                        <Grid direction="column" wrap="nowrap" key={index}>
                            <Expansion date={date} timeMapByDate={timeMapByDate} onPopupOpen={handlePopupOpen} />
                        </Grid>
                    ))}
                {isFetchingBackupFiles && <Spinner size={30} thick={4} />}
            </Grid>
            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={() => setPopoverAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                className={classes.popover}
            >
                <BackupItemPopup onBackupRestore={handleBackupRestore} />
            </Popover>
            {/* <Dialog open={modalOpen} onClose={handleModalClose}>
                <RestoreConfirmModal
                    dbName={database?.name}
                    backupTime={backupTimeSelected}
                    onClose={handleModalClose}
                    onAccept={handleRestoreAccept}
                />
            </Dialog> */}
            <Dialog open={modalOpen} onClose={handleModalClose}>
                <RestoreConfirmModal
                    dbName={database?.name}
                    backupTime={backupFileSelected?.alteredTime}
                    onClose={handleModalClose}
                    onAccept={handleRestoreAccept}
                />
            </Dialog>
            <Dialog className={classes.dialog} open={modalScheduleOpen} onClose={handleModalScheduleClose}>
                <ScheduleModal dbId={database?.id} open={modalScheduleOpen} onClose={handleModalScheduleClose} />
            </Dialog>
        </Grid>
    );
}

export default React.memo(Backup);
