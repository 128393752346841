import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { PLAN_TYPES } from 'const';
import PlanIconSVG from 'assets/images/svg/payment/PlanIconSVG';
import { getPlanColor } from 'utils/color';
import { TAG_HEIGHT } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import { capitalize } from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        height: TAG_HEIGHT,
        paddingLeft: 8,
        paddingRight: 8,

        maxWidth: '100%',
        borderRadius: 3,
        width: 'fit-content'
    },
    icon: {
        display: 'flex',
        marginRight: 5
    }
}));

function PlanTag({ planType, ...rest }) {
    const classes = useStyles();
    const theme = useTheme();
    const planColor = getPlanColor({ planType, theme });

    return (
        <Grid
            container
            alignItems="center"
            className={classes.root}
            style={{
                border: `1px solid ${hexToRgba(planColor, 0.8)}`,
                background: hexToRgba(planColor, 0.1),
                whiteSpace: 'nowrap'
            }}
            wrap="nowrap"
            {...rest}
        >
            <Grid item className={classes.icon}>
                <PlanIconSVG color={hexToRgba(planColor, 0.8)} />
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {capitalize(planType === PLAN_TYPES.PROFESSIONAL ? 'Pro' : planType)}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default React.memo(PlanTag);
