import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { GRID_COLOR_OPTIONS, getGridColorKey } from 'utils/color';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateGrid, _updateNameFavoriteGrid } from './actions';
import { getCustomProperties } from 'utils/gridUI/formatData';
import Palette from 'layout/Palette';
import { GRID_PALETTE } from 'const/paletteTypes';
import {
    INPUT_RADIUS,
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    POPUP_PADDING_TOP_BOTTOM,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    SPACING_LIST_NAME_WITH_ICON
} from 'const/style';
import Divider from 'components/divider/Base';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
// import { useHistory, useParams } from 'react-router-dom';
import ListItem from 'components/list/Item';
// import BranchSVG from 'assets/images/svg/BranchSVG';
// import SwitchSVG from 'assets/images/svg/SwitchSVG';
// import Branch from './branch';
import NestedMenuItem from 'components/menus/HoverMenu';
import trim from 'lodash/trim';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import TemplateSVG from 'assets/images/svg/TemplateSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';

import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260
    },
    spacing: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`
    },
    input: {
        backgroundColor: theme.colors.paleGrey,
        borderRadius: INPUT_RADIUS,
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                boxShadow: 'none'
            }
        }
    },
    color: {
        width: 28,
        height: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    checkIcon: {
        fontSize: 14,
        color: theme.colors.white
    },
    delete: {
        marginTop: 8,
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            fontSize: 20,
            color: theme.colors.midGrey,
            marginRight: SPACING_LIST_NAME_WITH_ICON,
            position: 'relative',
            top: 2
        }
    },
    colorPallet: {
        padding: `8px 20px 16px`
    }
}));

function GridPoppup({ dbId, grid, handleClickAway, onDelete, onDuplicate, onDuplicateAnother, onSaveTemplate }) {
    const { t } = useTranslation();
    const rootRef = React.useRef();
    const theme = useTheme();
    const [gridName, setGridName] = useState(grid.name);
    const defaultColor = getGridColorKey(grid);
    const [selectedColor, setSelectedColor] = useState(defaultColor);
    // const params = useParams();
    // const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    const clickAwayHandler = () => {
        if (trim(gridName) === '') {
            handleClickAway();
            return;
        }
        if (gridName === grid.name) {
            handleClickAway();
            return;
        }

        let newCustomPropperties = getCustomProperties(grid) || {};
        newCustomPropperties.color = selectedColor;

        dispatch(
            updateGrid({
                dbId,
                newGrid: {
                    name: gridName,
                    customProperties: newCustomPropperties
                },
                oldGrid: grid,
                successCallback: () => {
                    dispatch(_updateNameFavoriteGrid({ gridId: grid.id, newName: gridName }));
                    console.log('update success');
                },
                errorCallback: error => {
                    console.log('error update');
                }
            })
        );
        handleClickAway();
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };

    const changeColorHandler = color => {
        let newCustomPropperties = getCustomProperties(grid) || {};
        newCustomPropperties.color = color.id;

        dispatch(
            updateGrid({
                dbId,
                newGrid: {
                    name: grid.name,
                    customProperties: newCustomPropperties
                },
                oldGrid: grid,
                successCallback: () => {
                    console.log('update success');
                },
                errorCallback: error => {
                    console.log('error update');
                }
            })
        );
        setSelectedColor(color.id);
    };

    const handleDelete = e => {
        e.stopPropagation();
        onDelete();
    };

    const stopPropagation = e => {
        e.stopPropagation();
    };

    // const goToGridVersionControl = e => {
    //     stopPropagation(e);
    //     e.preventDefault();
    //     history.push(`/projects/${params?.workspaceId}/databases/${dbId}/grids/${grid?.id}/branches`);
    // };

    const handleDuplicate = e => {
        e.stopPropagation();
        onDuplicate();
    };

    const handleDuplicateAnother = e => {
        e.stopPropagation();
        onDuplicateAnother();
    };

    const handleSaveTemplate = e => {
        e.stopPropagation();
        onSaveTemplate();

        sendManualTrack({ type: `Save Grid as template on menu` });
    };

    return (
        <Grid ref={rootRef} id={`grid_popup`} container direction="column" className={classes.root}>
            <Grid item className={classes.spacing}>
                <Grid
                    item
                    style={{
                        width: '100%'
                    }}
                >
                    <InputText
                        id={`gridInput`}
                        name="gridName"
                        value={gridName}
                        onChange={e => setGridName(e.target.value)}
                        placeholder={t('grid_name_input')}
                        onFocus={stopPropagation}
                        onKeyDown={handleKeyDown}
                        onClick={stopPropagation}
                        autoFocus
                        className={classes.input}
                        autoSelect={true}
                        inputProps={{
                            maxLength: 255
                        }}
                    />
                </Grid>
            </Grid>
            <Palette
                className={classes.colorPallet}
                value={selectedColor}
                options={GRID_COLOR_OPTIONS}
                onSelect={changeColorHandler}
                type={GRID_PALETTE}
                onClick={stopPropagation}
            />
            {/* <Grid item style={{ position: 'relative' }}>
                    <NestedMenuItem icon={<SwitchSVG />} label="Switch branch" parentMenuOpen={true}>
                        <Branch />
                    </NestedMenuItem>
                </Grid> */}
            {/* <Grid item>
                    <ListItem onClick={goToGridVersionControl} icon={<BranchSVG />} name={t('version_control')} />
                </Grid> */}
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID}>
                <NestedMenuItem
                    icon={<DuplicateSVG color={theme.colors.steel} />}
                    label={t('global_duplicate')}
                    parentMenuOpen={true}
                    ContainerProps={{
                        onClick: stopPropagation
                    }}
                >
                    <Grid item onClick={handleDuplicate}>
                        <ListItem name={t('global_duplicate_this_database')} />
                    </Grid>
                    <Grid item onClick={handleDuplicateAnother}>
                        <ListItem name={t('global_duplicate_another_database')} />
                    </Grid>
                </NestedMenuItem>
            </AccessControl>

            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID}>
                <Grid item onClick={handleSaveTemplate}>
                    <ListItem name={t('save_grid_as_template')} icon={<TemplateSVG color={'#79778B'} />} />
                </Grid>
            </AccessControl>

            <Grid item>
                <Divider />
            </Grid>
            <Grid item id={`gridDlBtn`} className={`${classes.delete}`} onClick={handleDelete}>
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <DeleteSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('global_delete')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(GridPoppup);
