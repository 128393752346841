import React from 'react';
import { isTempId } from 'utils/uuid';
import { DEPENDENCY_STATUS, SOURCE_STATUS } from 'const/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { getCellData, getCellValueOnly, getCorrectCellData } from './cell';
import { isLDEmpty } from 'utils/object';
import { getCellDependencyStatus, getCellSourceStatus } from 'utils/gridUI/cell';
import i18n from 'i18n';
import DoNotTranslateSVG from 'assets/images/svg/dependencies/column/normal/DoNotTranslateSVG';
import EmptySVG from 'assets/images/svg/dependencies/column/normal/EmptySVG';
import NotReadyForTranslateSVG from 'assets/images/svg/dependencies/column/normal/NotReadyForTranslateSVG';
import ReadyForTranslateSVG from 'assets/images/svg/dependencies/column/normal/ReadyForTranslateSVG';
import LockedSVG from 'assets/images/svg/dependencies/column/normal/LockedSVG';

export function getDependencyColumnIds({ dependencies, viewColumns }) {
    const viewColumnIds = viewColumns?.filter(viewCol => viewCol?.viewable !== false).map(viewCol => viewCol?.id);
    const dpDcs = dependencies.filter(dpDc => !isTempId(dpDc?.id));

    let matchColumnIds = [];
    for (const dpDc of dpDcs) {
        matchColumnIds = matchColumnIds.concat([dpDc?.child, dpDc?.parent]);
    }

    return [...new Set(matchColumnIds)]?.filter(columnId => viewColumnIds?.includes(columnId));
}

export function getDependencyChildColumnIds({ dependencies, viewColumns }) {
    const viewColumnIds = viewColumns?.filter(viewCol => viewCol?.viewable !== false).map(viewCol => viewCol?.id);
    return dependencies
        .filter(dpDc => !isTempId(dpDc?.id))
        .map(dpDc => dpDc.child)
        .filter(columnId => viewColumnIds?.includes(columnId));
}

export function makeDependencyStatus({
    recordIds = [],
    columnIds = [],
    dependencyStatus = DEPENDENCY_STATUS.OUT_OF_DATE,
    data,
    metaData
}) {
    const cellsChanged = {};
    const serverData = [];
    const redoData = {};

    const isMarkUnset = dependencyStatus === DEPENDENCY_STATUS.UNSET;

    for (const recordId of recordIds) {
        for (const columnId of columnIds) {
            const cellDependencyStatus = getCellDependencyStatus({ data, rowId: recordId, columnId });
            const cellData = getCellData({ data, rowId: recordId, columnId });

            if (
                cellDependencyStatus &&
                cellDependencyStatus !== dependencyStatus &&
                [DEPENDENCY_STATUS.UNSET, DEPENDENCY_STATUS.OUT_OF_DATE, DEPENDENCY_STATUS.UP_TO_DATE].includes(
                    cellDependencyStatus
                )
            ) {
                if (!redoData[recordId]) {
                    redoData[recordId] = {};
                }

                if (!isMarkUnset) {
                    redoData[recordId][columnId] = {
                        ...cellData,
                        _dependencyStatus: dependencyStatus
                    };

                    cellsChanged[recordId] = {
                        ...cellsChanged[recordId],
                        [columnId]: cellData
                    };
                } else {
                    const column = metaData?.[columnId];
                    const columnType = getCorrectColumnType(column);
                    const cellValue = getCellValueOnly({ data, rowId: recordId, columnId });
                    const correctCellData = getCorrectCellData({ type: columnType, cellData: cellValue });

                    if (isLDEmpty(correctCellData)) {
                        redoData[recordId][columnId] = {
                            ...cellData,
                            _dependencyStatus: dependencyStatus
                        };
                        cellsChanged[recordId] = {
                            ...cellsChanged[recordId],
                            [columnId]: cellData
                        };
                    }
                }
            }
        }
    }

    const affectedRecordIds = Object.keys(cellsChanged);
    let affectedColumnIds = [];

    for (const recordId of affectedRecordIds) {
        const columnsData = cellsChanged?.[recordId];
        const changedColumnIds = Object.keys(columnsData);
        affectedColumnIds = [...new Set(affectedColumnIds.concat(changedColumnIds))];
    }

    for (const recordId of affectedRecordIds) {
        const rowData = [];
        const rowChangedData = cellsChanged?.[recordId];
        for (const columnId of affectedColumnIds) {
            let rowItemData = { _dependencyStatus: dependencyStatus };

            if (!rowChangedData?.hasOwnProperty(columnId)) {
                rowItemData = { _dependencyStatus: getCellDependencyStatus({ data, columnId, rowId: recordId }) };
            }
            rowData.push(rowItemData);
        }

        serverData.push([recordId, ...rowData]);
    }

    return { redoData, affectedColumnIds, affectedRecordIds, serverData, cellsChanged };
}

export const getDependencyColorByStatus = ({ theme, status }) => {
    switch (status) {
        case DEPENDENCY_STATUS.OUT_OF_DATE:
            return theme.colors.sun;
        case DEPENDENCY_STATUS.UP_TO_DATE:
            return theme.colors.atlantis;
        case DEPENDENCY_STATUS.UNSET:
            return theme.colors.brightRed;
        default:
            return ``;
    }
};

export const getSourceColorByStatus = ({ theme, status }) => {
    switch (status) {
        case SOURCE_STATUS.DO_NOT_TRANSLATE:
            return theme.colors.fuchsiaBlue;
        case SOURCE_STATUS.LOCKED:
            return theme.colors.dimGrey;
        case SOURCE_STATUS.NOT_READY_FOR_TRANSLATION:
            return theme.colors.midGrey;
        case SOURCE_STATUS.READY_FOR_TRANSLATION:
            return theme.colors.dodgerBlue;
        default:
            return theme.colors.dimGrey;
    }
};

export const getDependencyLabelByStatus = ({ status }) => {
    switch (status) {
        case DEPENDENCY_STATUS.OUT_OF_DATE:
            return i18n.t('global_out_of_date');
        case DEPENDENCY_STATUS.UP_TO_DATE:
            return i18n.t('global_up_to_date');
        case DEPENDENCY_STATUS.UNSET:
            return i18n.t('global_unset');
        default:
            return ``;
    }
};

export const getSourceStatusLabelByStatus = ({ status }) => {
    switch (status) {
        case SOURCE_STATUS.DO_NOT_TRANSLATE:
            return i18n.t('do_not_translate');
        case SOURCE_STATUS.LOCKED:
            return i18n.t('locked');
        case SOURCE_STATUS.NOT_READY_FOR_TRANSLATION:
            return i18n.t('not_ready_for_translation');
        case SOURCE_STATUS.READY_FOR_TRANSLATION:
            return i18n.t('ready_for_translation');
        case SOURCE_STATUS.UNSET:
            return i18n.t('notSet');
        default:
            return ``;
    }
};

export function isShowMarkAsOutOfDateByRange({ recordIds, columnIds, data }) {
    let isShow = false;

    for (let recordId of recordIds) {
        for (let columnId of columnIds) {
            const dependencyStatus = getCellDependencyStatus({ data, rowId: recordId, columnId });
            if (
                dependencyStatus &&
                [DEPENDENCY_STATUS.UP_TO_DATE, DEPENDENCY_STATUS.UNSET].includes(dependencyStatus)
            ) {
                isShow = true;
                break;
            }
        }
    }

    return isShow;
}

export function isShowMarkAsUpToDateByRange({ recordIds, columnIds, data }) {
    let isShow = false;

    for (let recordId of recordIds) {
        for (let columnId of columnIds) {
            const dependencyStatus = getCellDependencyStatus({ data, rowId: recordId, columnId });
            if (
                dependencyStatus &&
                [DEPENDENCY_STATUS.OUT_OF_DATE, DEPENDENCY_STATUS.UNSET].includes(dependencyStatus)
            ) {
                isShow = true;
                break;
            }
        }
    }

    return isShow;
}

export function isShowMarkAsUnsetByRange({ recordIds, columnIds, data }) {
    let isShow = false;

    for (const recordId of recordIds) {
        for (const columnId of columnIds) {
            const dependencyStatus = getCellDependencyStatus({ data, rowId: recordId, columnId });
            const cellValue = getCellValueOnly({ data, rowId: recordId, columnId });

            if (
                dependencyStatus &&
                [DEPENDENCY_STATUS.UP_TO_DATE, DEPENDENCY_STATUS.OUT_OF_DATE].includes(dependencyStatus) &&
                isLDEmpty(cellValue)
            ) {
                isShow = true;
                break;
            }
        }
    }

    return isShow;
}

export function isShowMarkSourceStatusByRange({ recordIds, columnIds, data }) {
    let isShow = false;
    console.log('recordIds', recordIds);
    console.log('columnIds', columnIds);
    console.log('data', data);

    for (let recordId of recordIds) {
        for (let columnId of columnIds) {
            const dependencyStatus = getCellDependencyStatus({ data, rowId: recordId, columnId });
            if (
                dependencyStatus &&
                [DEPENDENCY_STATUS.OUT_OF_DATE, DEPENDENCY_STATUS.UNSET].includes(dependencyStatus)
            ) {
                isShow = true;
                break;
            }
        }
    }

    return isShow;
}

export function makeSourceStatus({
    recordIds = [],
    columnIds = [],
    sourceStatus = SOURCE_STATUS.READY_FOR_TRANSLATION,
    data
}) {
    const cellsChanged = {};
    const serverData = [];
    const redoData = {};

    for (const recordId of recordIds) {
        for (const columnId of columnIds) {
            const cellData = getCellData({ data, rowId: recordId, columnId });

            if (sourceStatus) {
                if (!redoData[recordId]) {
                    redoData[recordId] = {};
                }

                redoData[recordId][columnId] = {
                    ...cellData,
                    _sourceStatus: sourceStatus
                };
                cellsChanged[recordId] = {
                    ...cellsChanged[recordId],
                    [columnId]: cellData
                };
            }
        }
    }

    const affectedRecordIds = Object.keys(cellsChanged);
    let affectedColumnIds = [];

    for (const recordId of affectedRecordIds) {
        const columnsData = cellsChanged?.[recordId];
        const changedColumnIds = Object.keys(columnsData);
        affectedColumnIds = [...new Set(affectedColumnIds.concat(changedColumnIds))];
    }

    for (const recordId of affectedRecordIds) {
        const rowData = [];
        const rowChangedData = cellsChanged?.[recordId];
        for (const columnId of affectedColumnIds) {
            let rowItemData = { _sourceStatus: sourceStatus };

            if (!rowChangedData?.hasOwnProperty(columnId)) {
                rowItemData = { _sourceStatus: getCellSourceStatus({ data, columnId, rowId: recordId }) };
            }
            rowData.push(rowItemData);
        }

        serverData.push([recordId, ...rowData]);
    }

    return { redoData, affectedColumnIds, affectedRecordIds, serverData, cellsChanged };
}

export const getSourceStatusIcon = ({ status, size = 18 }) => {
    switch (status) {
        case SOURCE_STATUS.DO_NOT_TRANSLATE:
            return <DoNotTranslateSVG width={size} height={size} />;
        case SOURCE_STATUS.NOT_READY_FOR_TRANSLATION:
            return <NotReadyForTranslateSVG width={size} height={size} />;
        case SOURCE_STATUS.LOCKED:
            return <LockedSVG width={size} height={size} />;
        case SOURCE_STATUS.UNSET:
            return <EmptySVG width={size} height={size} />;
        case SOURCE_STATUS.READY_FOR_TRANSLATION:
            return <ReadyForTranslateSVG width={size} height={size} />;
        default:
            return ``;
    }
};
