import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_MEMBERS](state) {
        const { members } = state;
        const newMembers = produce(members, draft => {
            draft.isFetching = true;
        });
        return {
            ...state,
            members: newMembers
        };
    },
    [types.FETCH_MEMBERS_SUCCESS](state, { payload }) {
        const { members } = state;
        const newMembers = produce(members, draft => {
            draft.list = payload;
            draft.isFetching = false;
        });
        return {
            ...state,
            members: newMembers
        };
    },
    [types.UPDATE_USER_ROLE_SUCCESS](state, { payload }) {
        const { userId, role } = payload;
        const { members } = state;
        const memberList = members?.list || [];
        const newMemberList = memberList.map(member => {
            if (member?.id === userId) {
                return {
                    ...member,
                    roles: [role]
                };
            }
            return member;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newMemberList
            }
        };
    },
    [types.UPDATE_USER_ROLE_FAILED](state, { payload }) {
        const { userId, role } = payload;
        const { members } = state;
        const memberList = members?.list || [];
        const newMemberList = memberList.map(member => {
            if (member?.id === userId) {
                return {
                    ...member,
                    roles: [role]
                };
            }
            return member;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newMemberList
            }
        };
    },
    [types.UPDATE_USER_STATE_SUCCESS](state, { payload }) {
        const { userEmail, userState } = payload;
        const { members } = state;
        const memberList = members?.list || [];
        const newMemberList = memberList.map(member => {
            if (member.email === userEmail) {
                member.state = userState;
            }
            return member;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newMemberList
            }
        };
    },
    [types.UPDATE_USER_STATE_FAILED](state, { payload }) {
        const { userEmail, userState } = payload;
        const { members } = state;
        const list = members?.list || [];
        const newList = list.map(item => {
            if (item.email === userEmail) {
                item.state = userState;
            }
            return item;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newList
            }
        };
    },
    [types.REMOVE_USER_SUCCESS](state, { payload }) {
        const { userEmail } = payload;
        const { members } = state;
        const list = members?.list || [];
        const newList = list.filter(item => item.email !== userEmail);

        return {
            ...state,
            members: {
                ...members,
                list: newList
            }
        };
    },
    [types.ASSIGN_USER_TO_GROUP](state, { payload }) {
        const { userId, group } = payload;
        const { members } = state;
        const list = members?.list || [];
        const newList = list.map(item => {
            if (item.id === userId) {
                const newGroups = [...item.groups];

                newGroups.push(group);
                item.groups = newGroups;
            }
            return item;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newList
            }
        };
    },
    [types.UNASSIGN_USER_OUT_GROUP](state, { payload }) {
        const { userId, groupId } = payload;
        const { members } = state;
        const list = members?.list || [];
        const newList = list.map(item => {
            if (item.id === userId) {
                const newGroups = item.groups.filter(group => group.id !== groupId);

                item.groups = newGroups;
            }
            return item;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newList
            }
        };
    },
    [types.FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER](state, { payload }) {
        const { userId } = payload;
        const { members } = state;
        const { workspacesRoles } = members;

        const newWorkspacesRoles = produce(workspacesRoles, draft => {
            if (!draft[userId]) {
                draft[userId] = {};
            }
            draft[userId].isFetching = true;
        });

        const newState = {
            ...state,
            members: {
                ...members,
                workspacesRoles: newWorkspacesRoles
            }
        };

        return newState;
    },
    [types.FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_SUCCESS](state, { payload }) {
        const { userId, singleWorkspacesRoles } = payload;
        const { members } = state;
        const { workspacesRoles } = members;
        const newWorkspacesRoles = produce(workspacesRoles, draft => {
            if (!draft[userId]) {
                draft[userId] = {};
            }
            draft[userId].isFetching = false;
            draft[userId].list = singleWorkspacesRoles;
        });
        return {
            ...state,
            members: {
                ...members,
                workspacesRoles: newWorkspacesRoles
            }
        };
    },
    [types.FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_FAILED](state, { payload }) {
        const { userId } = payload;
        const { members } = state;
        const { workspacesRoles } = members;
        const newWorkspacesRoles = produce(workspacesRoles, draft => {
            if (!draft[userId]) {
                draft[userId] = {};
            }
            draft[userId].isFetching = false;
        });
        return {
            ...state,
            members: {
                ...members,
                workspacesRoles: newWorkspacesRoles
            }
        };
    },
    [types.CHANGE_USER_WORKSPACE_ROLE](state, { payload }) {
        const { userId, workspaceId, role } = payload;
        const { members } = state;
        const { workspacesRoles } = members;

        if (!workspacesRoles?.[userId]) {
            return state;
        }
        const newWorkspacesRoles = produce(workspacesRoles, draft => {
            const workspaceList = draft[userId].list;
            const newWorkspaceList = workspaceList?.map(workspace => {
                if (workspaceId === workspace?.workspaceId) {
                    return {
                        ...workspace,
                        role
                    };
                }
                return workspace;
            });

            draft[userId].list = newWorkspaceList;
        });

        return {
            ...state,
            members: {
                ...members,
                workspacesRoles: newWorkspacesRoles
            }
        };
    },
    [types.TRANSFER_OWNERSHIP_SUCCESS](state, { payload }) {
        const { currentUser, targetUser } = payload;
        const members = state.members.list;

        const newMembers = members.map(member => {
            if (member.id === currentUser?.id) {
                member = currentUser;
            }
            if (member.id === targetUser?.id) {
                member = targetUser;
            }
            return member;
        });

        return {
            ...state,
            members: {
                ...state.members,
                list: newMembers
            }
        };
    },
    [types.UPDATE_USER_ROLE_AND_LANGUAGES_SUCCESS](state, { payload }) {
        const { userId, role, languagePairs, groups } = payload;
        const members = state.members.list;

        const newMembers = members.map(member => {
            if (member.id === userId) {
                member.languagePairs = languagePairs;
                member.roles = [role];
                member.groups = groups;
                return member;
            }
            return member;
        });

        return {
            ...state,
            members: {
                ...state.members,
                list: newMembers
            }
        };
    },
    [types.INVITE_MEMBERS_TO_COMPANY_SUCCESS](state, { payload }) {
        const { members } = payload;

        return {
            ...state,
            members: {
                ...state.members,
                list: [...state.members.list, ...members],
                invitedMembers: members
            }
        };
    },
    [types.UPDATE_USER_TO_INTERNAL_USER_SUCCESS](state, { payload }) {
        const { email } = payload;

        const newMemberList = state?.members?.list?.map(member => {
            if (member.email === email) {
                return { ...member, ldUser: true };
            }
            return member;
        });

        return {
            ...state,
            members: {
                ...state.members,
                list: newMemberList
            }
        };
    },
    [types.REVOKE_INVITATION_SUCCESS](state, { payload }) {
        const { email } = payload;
        const { members } = state;
        const memberList = members?.list;
        const newMemberList = memberList.filter(member => member?.email !== email);

        return {
            ...state,
            members: {
                ...members,
                list: newMemberList
            }
        };
    },
    [types.UPDATE_SINGLE_USER](state, { payload }) {
        const { newUser } = payload;
        const { members } = state;
        const memberList = members?.list;
        const newMemberList = memberList.map(member => {
            if (member.id === newUser.id) return newUser;
            return member;
        });

        return {
            ...state,
            members: {
                ...members,
                list: newMemberList
            }
        };
    },
    [types.RESET_MEMBERS](state) {
        return {
            ...state,
            members: {
                isFetching: false,
                list: null,
                workspacesRoles: {
                    sample_user_id: {
                        isFetching: false,
                        list: []
                    }
                },
                invitedMembers: []
            }
        };
    }
};

export default handler;
