import React from 'react';
import Select from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import Option from './common/Option';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function SelectBase(props) {
    const theme = useTheme();
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        })
    };

    return <Select components={components} styles={selectStyles} {...props} />;
}

export default React.memo(SelectBase);
