import React from 'react';
import LoginForm from './LoginForm';

import { queryParamsToObject } from 'utils/urlQueryUtils';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import AccountDiffCheck from './AccountDiffCheck';
import { useTranslation } from 'react-i18next';

function Login({ location }) {
    const { t } = useTranslation();
    const queryParams = queryParamsToObject(location?.search);

    return (
        <AccountDiffCheck location={location}>
            <AuthenticationWrapper isSigningIn>
                <AuthFormWrapper title={t(`global_sign_in`)}>
                    <LoginForm username={queryParams.email} defaultCompanyId={queryParams.company} />
                </AuthFormWrapper>
            </AuthenticationWrapper>
        </AccountDiffCheck>
    );
}

export default Login;
