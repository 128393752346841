import React, { useCallback, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import FilterItem from './FilterItem';
import { useDependencyListWithoutFakeId } from 'hooks/gridUI/dependency';
import CircleAddIconSVG from 'assets/images/svg/CircleAddIconSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2)
    },
    group: {
        padding: 12,
        background: theme.colors.background,
        borderRadius: 4
    },
    filterItem: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    and: {
        position: 'relative',
        padding: '20px 0',
        '& p': {
            color: theme.palette.primary.main
        },
        '& svg': {
            verticalAlign: 'text-bottom'
        },
        '&.action': {
            cursor: 'pointer'
        },
        '&.readOnly': {
            cursor: 'unset',
            opacity: DISABLED_OPACITY
        }
    },
    lineTop: {
        position: 'absolute',
        width: 1,
        height: 20,
        padding: '0 !important',
        top: 0,
        background: theme.colors.silver
    },
    lineBottom: {
        position: 'absolute',
        width: 1,
        height: 20,
        padding: '0 !important',
        bottom: 0,
        background: theme.colors.silver
    },
    mr8: {
        marginRight: 8
    }
}));

function FilterList({ t, onCreated, viewFilters }) {
    const classes = useStyles();
    const dependencies = useDependencyListWithoutFakeId();
    const dispatch = useDispatch();

    const groupViewFilters = useMemo(() => {
        if (viewFilters.length) {
            return viewFilters.reduce((acc, cur) => {
                const idx = acc.findIndex(g => g.groupId === cur.groupId);
                if (idx < 0) {
                    acc.push({
                        groupId: cur.groupId || cur.id,
                        views: [cur]
                    });
                } else {
                    acc[idx].views.push(cur);
                }
                return acc;
            }, []);
        }
        return [];
    }, [viewFilters]);

    const addFilterHandler = useCallback(() => {
        sendManualTrack({ type: `Add View Filter` });
        dispatch(
            gridActions.createViewFilter({
                successCallback: () => {
                    console.log('create filter success');
                    onCreated && onCreated();
                },
                errorCallback: () => {
                    console.log('create filter failed');
                }
            })
        );
    }, [dispatch, onCreated]);

    const addOrFilterHandler = useCallback(
        (idOfFilterToOrWith, groupId) => {
            sendManualTrack({ type: `Add Or View Filter` });
            dispatch(
                gridActions.createViewFilter({
                    groupWithFilterId: idOfFilterToOrWith,
                    groupId,
                    successCallback: () => {
                        console.log('create filter success');
                    },
                    errorCallback: () => {
                        console.log('create filter failed');
                    }
                })
            );
        },
        [dispatch]
    );

    if (viewFilters.length === 0)
        return (
            <Grid container className={classes.root}>
                <p className="caption">{t(`view_filter_click_to_create`)}</p>
            </Grid>
        );
    return (
        <Grid container direction="column" className={classes.root}>
            {groupViewFilters.map((group, idx) => {
                const isLastGroup = idx === groupViewFilters.length - 1;
                return (
                    <>
                        <Grid key={group.groupId} item className={classes.group}>
                            {group.views.map((filter, index) => {
                                const isLastFilter = index === group.views.length - 1;
                                return (
                                    <Grid item className={classes.filterItem} key={filter.id}>
                                        <FilterItem
                                            t={t}
                                            dependencies={dependencies}
                                            {...filter}
                                            handleOr={isLastFilter ? addOrFilterHandler : undefined}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {!isLastGroup && (
                            <Grid className={classes.and} container justify="center" alignItems="center">
                                <Grid item>
                                    <p className="body1">{t('global_and')}</p>
                                </Grid>
                                <Grid item className={classes.lineTop} />
                                <Grid item className={classes.lineBottom} />
                            </Grid>
                        )}
                        {isLastGroup && (
                            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_FILTER}>
                                {({ isReadOnly }) => (
                                    <Tooltip
                                        title={
                                            isReadOnly ? (
                                                <Grid container className={classes.permissionTooltip}>
                                                    {t('toolbar_no_permission')}
                                                </Grid>
                                            ) : (
                                                ''
                                            )
                                        }
                                    >
                                        <Grid
                                            className={`${classes.and} ${isLastGroup ? 'action' : ''} ${
                                                isReadOnly ? 'readOnly' : ''
                                            }`}
                                            container
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                className={classes.mr8}
                                                item
                                                onClick={!isReadOnly ? addFilterHandler : null}
                                            >
                                                <CircleAddIconSVG color="#7869B9" />
                                            </Grid>
                                            <Grid item onClick={!isReadOnly ? addFilterHandler : null}>
                                                <p className="body1">{t('global_and')}</p>
                                            </Grid>
                                            <Grid item className={classes.lineTop} />
                                        </Grid>
                                    </Tooltip>
                                )}
                            </AccessControl>
                        )}
                    </>
                );
            })}
        </Grid>
    );
}

export default React.memo(FilterList);
