import React from 'react';

const ApplyIconSVG = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="16" height="16" rx="2" fill="#42B883" />
            <path
                d="M12.6663 4.66667V7.33333H3.88634L6.27301 4.94L5.33301 4L1.33301 8L5.33301 12L6.27301 11.06L3.88634 8.66667H13.9997V4.66667H12.6663Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(ApplyIconSVG);
