import React from 'react';

const YAYSVG = () => {
    return (
        <svg width="129" height="162" viewBox="0 0 129 162" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M64.5 162C90.1812 162 111 156.627 111 150C111 143.373 90.1812 138 64.5 138C38.8188 138 18 143.373 18 150C18 156.627 38.8188 162 64.5 162Z"
                fill="#F4F5F7"
            />
            <g clipPath="url(#clip0_2138_37416)">
                <path
                    d="M118.353 65.1296C122.183 64.4796 126.353 70.0596 127.653 77.6196V77.3796C126.053 67.9696 121.653 60.8796 117.773 61.5296C113.893 62.1796 112.083 70.3496 113.683 79.7496C113.683 79.8296 113.683 79.9096 113.683 79.9896C112.463 72.3896 114.533 65.7796 118.353 65.1296Z"
                    fill="#22C3F3"
                />
                <path
                    d="M123.057 92.8504C119.527 93.4504 115.727 88.7304 114.117 82.0404C115.987 90.2804 119.997 96.2004 123.527 95.6004C127.057 95.0004 128.887 88.0804 127.937 79.6904C128.627 86.5304 126.597 92.2504 123.057 92.8504Z"
                    fill="#FAAF1B"
                />
                <path
                    d="M9.83908 65.1296C6.00908 64.4796 1.83906 70.0596 0.539062 77.6196V77.3796C2.13906 67.9696 6.53907 60.8796 10.4191 61.5296C14.2991 62.1796 16.1091 70.3496 14.5091 79.7496C14.5091 79.8296 14.5091 79.9096 14.4591 79.9896C15.7291 72.3896 13.6591 65.7796 9.83908 65.1296Z"
                    fill="#FAAF1B"
                />
                <path
                    d="M5.17681 92.8504C8.70681 93.4504 12.5068 88.7304 14.1268 82.0404C12.2468 90.2804 8.24681 96.2004 4.70681 95.6004C1.16681 95.0004 -0.653195 88.0804 0.296805 79.6904C-0.443195 86.5304 1.58681 92.2504 5.17681 92.8504Z"
                    fill="#22C3F3"
                />
                <path
                    d="M103.917 59.49C101.506 59.9717 99.3768 61.3698 97.9766 63.39C98.9475 62.6544 100.078 62.1576 101.277 61.94C107.867 60.82 114.737 68.94 116.617 80C118.497 91.06 114.697 101 108.107 102.12C106.901 102.312 105.668 102.216 104.507 101.84C106.497 103.295 108.974 103.922 111.417 103.59C118.647 102.36 122.837 91.48 120.767 79.3C118.697 67.12 111.177 58.26 103.917 59.49Z"
                    fill="#E43383"
                />
                <path
                    d="M24.2683 59.49C26.6677 59.9789 28.7852 61.3762 30.1783 63.39C29.2074 62.6544 28.0769 62.1576 26.8783 61.94C20.2883 60.82 13.4183 68.94 11.5383 80C9.6583 91.06 13.4583 101 20.0483 102.12C21.2536 102.312 22.4872 102.216 23.6483 101.84C21.6585 103.296 19.1814 103.924 16.7383 103.59C9.50832 102.36 5.31831 91.48 7.38831 79.3C9.45831 67.12 17.0383 58.26 24.2683 59.49Z"
                    fill="#E43383"
                />
                <path
                    d="M79.178 123.7L63.958 125.39L48.708 123.7C48.398 124.65 40.058 132.14 40.708 150.32C40.7491 151.39 41.2014 152.403 41.9707 153.148C42.74 153.894 43.767 154.313 44.838 154.32C47.918 154.32 52.198 154.32 55.228 154.24C56.2165 154.224 57.159 153.82 57.8515 153.114C58.544 152.409 58.9307 151.459 58.928 150.47C58.928 149.743 59.2166 149.047 59.7305 148.533C60.2443 148.019 60.9413 147.73 61.668 147.73H66.358C67.0847 147.73 67.7816 148.019 68.2954 148.533C68.8093 149.047 69.098 149.743 69.098 150.47C69.0952 151.459 69.4819 152.409 70.1744 153.114C70.8669 153.82 71.8095 154.224 72.798 154.24C75.798 154.24 80.108 154.34 83.188 154.32C84.2589 154.313 85.2859 153.894 86.0552 153.148C86.8245 152.403 87.2768 151.39 87.318 150.32C87.868 132.14 79.528 124.65 79.178 123.7Z"
                    fill="url(#paint0_linear_2138_37416)"
                />
                <path
                    d="M48.7062 123.7C48.5762 124.09 47.0762 125.6 45.4062 128.52C57.5784 131.595 70.3241 131.595 82.4962 128.52C80.8362 125.62 79.3462 124.12 79.2162 123.73L63.9562 125.39L48.7062 123.7Z"
                    fill="url(#paint1_linear_2138_37416)"
                />
                <path
                    d="M112.543 86.62C111.083 78.87 109.163 70.91 104.803 64.24C104.333 63.52 103.803 62.82 103.313 62.14C93.5631 49.34 76.4231 43.9 60.8031 44.78C46.8031 45.58 32.4731 51.36 24.0931 63C18.4731 70.79 15.8331 80.37 15.2731 89.88C14.8831 96.38 14.8731 104.04 18.9631 109.58C37.3831 134.58 90.7831 134.26 108.963 109.58C113.863 102.95 114.003 94.39 112.543 86.62Z"
                    fill="url(#paint2_linear_2138_37416)"
                />
                <path
                    d="M62.0177 103.73C53.6716 103.824 45.3457 102.891 37.2277 100.95C31.0877 99.4205 27.4477 96.7005 26.4277 92.8705C24.0477 83.9505 25.7677 71.5105 33.6677 64.9805C38.3719 61.0838 44.1015 58.6287 50.1677 57.9105C59.3261 56.7239 68.5993 56.7239 77.7577 57.9105C83.8233 58.6309 89.5523 61.0857 94.2577 64.9805C102.158 71.5105 103.878 83.9805 101.498 92.8705C100.548 96.4105 97.1877 99.0405 91.4977 100.68C83.1377 103.09 73.4977 103.56 64.2677 103.68C63.5177 103.68 62.7677 103.68 62.0177 103.68V103.73Z"
                    fill="#9863A9"
                />
                <path
                    d="M62.1763 100.88C54.5764 100.962 46.9934 100.143 39.5863 98.4401C33.9863 97.1001 30.6763 94.7101 29.7463 91.3501C27.5763 83.5301 29.1463 72.6201 36.3463 66.8901C40.6661 63.4578 45.8639 61.3094 51.3463 60.6901C59.6974 59.6502 68.1453 59.6502 76.4963 60.6901C81.9788 61.3094 87.1766 63.4578 91.4963 66.8901C98.6963 72.6201 100.266 83.5301 98.0963 91.3501C97.2363 94.4601 94.1663 96.7601 88.9863 98.2001C81.3663 100.32 72.5563 100.73 64.1763 100.86C63.4863 100.86 62.8063 100.86 62.1163 100.86L62.1763 100.88Z"
                    fill="#121212"
                />
                <path
                    d="M62.181 98.8904C54.7389 98.9746 47.3133 98.1723 40.061 96.5003C35.221 95.3403 32.401 93.4303 31.671 90.8203C29.671 83.6303 31.071 73.6404 37.591 68.4504C41.64 65.2449 46.5085 63.2421 51.641 62.6704C59.8189 61.6437 68.0931 61.6437 76.271 62.6704C81.386 63.2512 86.236 65.2535 90.271 68.4504C96.791 73.6404 98.181 83.6303 96.191 90.8203C95.521 93.2003 92.931 95.0404 88.461 96.2804C81.071 98.3404 72.461 98.7404 64.151 98.8704H62.151L62.181 98.8904Z"
                    fill="#17171A"
                />
                <path
                    d="M38.4919 60.5604L40.0319 59.3104L34.8219 52.9004C34.2619 53.2604 33.7119 53.6304 33.1719 54.0204L38.4919 60.5604Z"
                    fill="white"
                />
                <path
                    d="M95.0791 54.1503C94.5391 53.7603 93.9891 53.3903 93.4291 53.0303L88.0391 59.6602L89.5791 60.9203L95.0791 54.1503Z"
                    fill="white"
                />
                <path
                    d="M58.5894 27.0397L58.3494 26.624C58.2859 26.514 58.1452 26.4763 58.0352 26.5398L51.4361 30.3498C51.3261 30.4133 51.2884 30.554 51.3519 30.664L51.5919 31.0797C51.6554 31.1897 51.7961 31.2274 51.9061 31.1639L58.5052 27.3539C58.6152 27.2904 58.6529 27.1497 58.5894 27.0397Z"
                    fill="white"
                />
                <path
                    d="M50.4931 32.2304C50.7914 32.2304 51.0331 31.9886 51.0331 31.6904C51.0331 31.3922 50.7914 31.1504 50.4931 31.1504C50.1949 31.1504 49.9531 31.3922 49.9531 31.6904C49.9531 31.9886 50.1949 32.2304 50.4931 32.2304Z"
                    fill="white"
                />
                <path
                    d="M94.678 62.9903C89.6367 58.9859 83.5742 56.4743 77.178 55.7403C68.4084 54.6504 59.5376 54.6504 50.768 55.7403C44.3683 56.4725 38.3021 58.9842 33.258 62.9903C24.688 69.8103 22.148 82.5503 24.968 92.6903C26.698 98.9303 32.578 101.88 38.448 103.29C46.9332 105.226 55.6281 106.086 64.328 105.85C72.888 105.72 82.128 105.31 90.398 103.01C95.868 101.49 101.398 98.5103 102.978 92.6903C105.788 82.5503 103.248 69.8103 94.678 62.9903ZM100.088 91.8903C99.028 95.7103 95.488 98.4803 89.588 100.13C81.758 102.3 72.798 102.73 64.278 102.86H62.178C54.4407 102.948 46.7202 102.119 39.178 100.39C32.818 98.8703 29.018 96.0103 27.878 91.9003C25.528 83.4403 27.278 71.6103 35.158 65.3403C39.7649 61.6768 45.3094 59.3827 51.158 58.7203C59.6756 57.667 68.2905 57.667 76.808 58.7203C82.6569 59.3817 88.2017 61.6758 92.808 65.3403C100.688 71.6003 102.438 83.3903 100.088 91.8903Z"
                    fill="url(#paint3_linear_2138_37416)"
                />
                <path d="M65.9178 137.63H62.0078V140.08H65.9178V137.63Z" fill="white" />
                <path d="M65.9178 134.61H62.0078V137.06H65.9178V134.61Z" fill="white" />
                <path d="M65.9178 140.66H62.0078V143.11H65.9178V140.66Z" fill="white" />
                <path
                    d="M70.41 136.39C70.41 136.155 70.3637 135.922 70.2737 135.705C70.1838 135.487 70.0519 135.29 69.8857 135.124C69.7195 134.958 69.5222 134.826 69.305 134.736C69.0878 134.646 68.8551 134.6 68.62 134.6H66.5V137.05H70.41V136.39Z"
                    fill="white"
                />
                <path
                    d="M59.3134 143.12H61.4334V140.66H57.5234V141.33C57.5261 141.804 57.7155 142.258 58.0506 142.593C58.3858 142.928 58.8395 143.118 59.3134 143.12Z"
                    fill="white"
                />
                <path d="M70.41 137.63H66.5V140.08H70.41V137.63Z" fill="white" />
                <path
                    d="M68.62 143.12C69.0939 143.118 69.5477 142.928 69.8828 142.593C70.2179 142.258 70.4074 141.804 70.41 141.33V140.66H66.5V143.12H68.62Z"
                    fill="white"
                />
                <path
                    d="M59.3134 134.61C58.8387 134.61 58.3834 134.799 58.0477 135.135C57.712 135.47 57.5234 135.926 57.5234 136.4V137.06H61.4334V134.61H59.3134Z"
                    fill="white"
                />
                <path d="M61.4334 137.63H57.5234V140.08H61.4334V137.63Z" fill="white" />
                <path
                    opacity="0.47"
                    d="M59.9788 22.6516L59.5538 21.9155C59.4378 21.7146 59.1809 21.6458 58.98 21.7618L52.6927 25.3918C52.4918 25.5077 52.423 25.7646 52.5389 25.9655L52.9639 26.7016C53.0799 26.9025 53.3368 26.9713 53.5377 26.8553L59.825 23.2253C60.0259 23.1094 60.0947 22.8525 59.9788 22.6516Z"
                    fill="white"
                />
                <path
                    opacity="0.47"
                    d="M51.1103 28.4101C51.6847 28.4101 52.1503 27.9445 52.1503 27.3701C52.1503 26.7957 51.6847 26.3301 51.1103 26.3301C50.5359 26.3301 50.0703 26.7957 50.0703 27.3701C50.0703 27.9445 50.5359 28.4101 51.1103 28.4101Z"
                    fill="white"
                />
                <path
                    d="M60.2912 39.2605C58.5812 39.3205 56.8812 39.4305 55.1712 39.5705H54.8213C52.5513 39.7805 50.2912 40.0505 48.0312 40.4105C48.6237 39.8131 49.1813 39.182 49.7012 38.5205C51.0456 36.8314 52.103 34.9327 52.8312 32.9005C52.8312 32.7605 52.9212 32.6205 52.9612 32.4805L53.1512 32.8005C54.2492 34.5889 55.6501 36.1726 57.2912 37.4805C58.2042 38.2096 59.2138 38.8086 60.2912 39.2605Z"
                    fill="url(#paint4_linear_2138_37416)"
                />
                <path
                    d="M80.1797 40.5001C77.7997 40.1201 75.4197 39.8201 73.0197 39.6001H72.6697C71.0897 39.4701 69.5097 39.3701 67.9297 39.3101C69.0775 38.8412 70.1492 38.2042 71.1097 37.4201C72.651 36.1661 73.9783 34.6699 75.0397 32.9901C75.1997 32.7301 75.2797 32.5801 75.2797 32.5801C75.2797 32.5801 75.3597 32.8501 75.4097 32.9801C76.094 34.9403 77.0901 36.7772 78.3597 38.4201C78.9271 39.1468 79.5347 39.8412 80.1797 40.5001Z"
                    fill="url(#paint5_linear_2138_37416)"
                />
                <path
                    d="M85.3778 36.2905C83.1556 37.3181 80.7988 38.0251 78.3778 38.3905L77.7278 38.4705C75.4705 38.7191 73.1882 38.3457 71.1278 37.3905C68.4179 35.9569 66.1682 33.7869 64.6378 31.1305C64.3978 30.7605 64.1678 30.3805 63.9378 29.9805C63.7178 30.4205 63.4878 30.8405 63.2578 31.2405C61.9139 33.8722 59.8279 36.0521 57.2578 37.5105C55.1425 38.564 52.7605 38.961 50.4178 38.6505L49.6678 38.5505C47.1692 38.1172 44.7384 37.3573 42.4378 36.2905C42.205 36.19 41.9779 36.0765 41.7578 35.9505L42.3578 36.8405C44.9045 40.6405 47.2378 44.5105 49.3578 48.4505C58.9667 46.9563 68.7489 46.9563 78.3578 48.4505C80.5378 44.4505 82.9178 40.5338 85.4978 36.7005V36.6405L85.9678 35.9605L85.3778 36.2905ZM47.7578 43.2905C47.4878 42.4305 47.2178 41.5805 46.9378 40.7205C47.7478 41.2205 48.5478 41.7205 49.3278 42.2505C49.5878 43.1205 49.8378 43.9805 50.0878 44.8405C49.3278 44.3905 48.5478 43.8305 47.7578 43.3205V43.2905ZM55.3578 41.8505L54.8178 39.5705H55.1678C55.7878 40.0305 56.4078 40.4905 57.0078 40.9605C57.1978 41.8405 57.3878 42.7205 57.5678 43.6005C56.8378 43.0205 56.1078 42.4405 55.3578 41.8805V41.8505ZM63.9278 45.3305C63.4178 43.9205 62.9278 42.5105 62.3278 41.1005C62.8378 39.6805 63.3278 38.2605 63.9278 36.8405C64.4812 38.2605 65.0112 39.6805 65.5178 41.1005C64.9712 42.5271 64.4412 43.9571 63.9278 45.3905V45.3305ZM72.4878 41.8505C71.7478 42.4105 71.0078 42.9905 70.2778 43.5705C70.4678 42.6905 70.6478 41.8105 70.8478 40.9305C71.4478 40.4605 72.0578 40.0005 72.6778 39.5405H73.0278L72.4878 41.8505ZM80.1678 42.8505C79.3778 43.3505 78.5978 43.8505 77.8278 44.4005C78.0778 43.5305 78.3278 42.6705 78.5878 41.8105C79.3778 41.2905 80.1778 40.8105 80.9878 40.2705C80.7178 41.1205 80.4378 41.9805 80.1778 42.8405L80.1678 42.8505Z"
                    fill="url(#paint6_linear_2138_37416)"
                />
                <path
                    d="M78.8567 50.8702C68.9693 49.2502 58.8842 49.2502 48.9968 50.8702C48.8403 50.8963 48.6799 50.8594 48.5506 50.7676C48.4214 50.6757 48.3337 50.5364 48.3068 50.3802C48.2924 50.302 48.2937 50.2217 48.3106 50.144C48.3276 50.0663 48.3597 49.9928 48.4053 49.9277C48.4509 49.8626 48.509 49.8071 48.5762 49.7647C48.6434 49.7222 48.7184 49.6935 48.7967 49.6802C58.8134 48.0402 69.0301 48.0402 79.0467 49.6802C79.126 49.6923 79.2021 49.7201 79.2704 49.7621C79.3387 49.8041 79.398 49.8593 79.4446 49.9246C79.4912 49.9898 79.5243 50.0638 79.5418 50.142C79.5593 50.2203 79.561 50.3013 79.5467 50.3802C79.5174 50.5352 79.4292 50.6729 79.3005 50.7643C79.1719 50.8557 79.0128 50.8936 78.8567 50.8702Z"
                    fill="#FFE46B"
                />
                <path
                    d="M61.3125 28.63C62.1425 27.42 63.0125 26.22 63.9025 25.04C64.7925 26.22 65.6525 27.4201 66.4925 28.6201C65.6025 29.7901 64.7325 30.9701 63.9025 32.1501C63.0625 30.9701 62.1825 29.79 61.3125 28.63Z"
                    fill="url(#paint7_linear_2138_37416)"
                />
                <path
                    d="M39.6406 32.9697C40.6406 33.3597 41.5706 33.7497 42.5206 34.1597L42.4606 36.9497C41.5506 35.6097 40.6006 34.2897 39.6406 32.9697Z"
                    fill="url(#paint8_linear_2138_37416)"
                />
                <path
                    d="M88.1791 32.9697C87.1791 33.3597 86.2391 33.7497 85.2891 34.1597C85.2891 35.0864 85.3091 36.0164 85.3491 36.9497C86.3091 35.6097 87.2491 34.2897 88.1791 32.9697Z"
                    fill="url(#paint9_linear_2138_37416)"
                />
                <path
                    d="M73.7266 30.6797C74.3666 29.9397 75.0166 29.1997 75.6766 28.4697C76.1766 29.3197 76.6766 30.1798 77.1266 31.0298C76.4666 31.7498 75.8166 32.4797 75.1866 33.2097C74.7066 32.3897 74.2266 31.5197 73.7266 30.6797Z"
                    fill="url(#paint10_linear_2138_37416)"
                />
                <path
                    d="M51.0391 30.9301C51.5191 30.0701 52.0391 29.2201 52.5091 28.3701C53.1591 29.1101 53.8091 29.8401 54.4491 30.5901C53.9491 31.4201 53.4491 32.2701 52.9791 33.1101C52.3491 32.3901 51.6991 31.6501 51.0391 30.9301Z"
                    fill="url(#paint11_linear_2138_37416)"
                />
                <path d="M73.1197 39.25L73.0297 39.6H72.6797L73.1197 39.25Z" fill="url(#paint12_linear_2138_37416)" />
                <path d="M55.18 39.5697H54.83L54.75 39.2197L55.18 39.5697Z" fill="url(#paint13_linear_2138_37416)" />
                <path
                    d="M73.75 74.3295C73.83 78.3295 76.67 82.3295 80.99 82.3295C85.31 82.3295 88.16 78.3295 88.24 74.3295C88.24 74.1147 88.1547 73.9087 88.0027 73.7568C87.8508 73.6049 87.6448 73.5195 87.43 73.5195C87.2152 73.5195 87.0091 73.6049 86.8572 73.7568C86.7053 73.9087 86.62 74.1147 86.62 74.3295C86.56 77.4795 84.42 80.7495 80.99 80.7495C77.56 80.7495 75.43 77.4795 75.37 74.3295C75.37 74.1147 75.2847 73.9087 75.1328 73.7568C74.9808 73.6049 74.7748 73.5195 74.56 73.5195C74.3452 73.5195 74.1391 73.6049 73.9872 73.7568C73.8353 73.9087 73.75 74.1147 73.75 74.3295Z"
                    fill="white"
                />
                <path
                    d="M85.7474 85.1299C85.0688 85.1038 84.4063 85.3401 83.8974 85.7899L81.0074 88.9199C80.6374 89.3299 80.8574 89.6899 81.5074 89.7299C82.1863 89.7581 82.8497 89.5215 83.3574 89.0699L86.2574 85.9399C86.6274 85.5399 86.3974 85.1699 85.7474 85.1299Z"
                    fill="url(#paint14_linear_2138_37416)"
                />
                <path
                    d="M89.7706 85.1302C89.0916 85.102 88.4283 85.3386 87.9205 85.7902L85.0206 88.9202C84.6506 89.3302 84.8806 89.6902 85.5306 89.7302C86.2092 89.7563 86.8717 89.52 87.3806 89.0702L90.2706 85.9402C90.6406 85.5402 90.4206 85.1702 89.7706 85.1302Z"
                    fill="url(#paint15_linear_2138_37416)"
                />
                <path
                    d="M93.7787 85.1299C93.1001 85.1038 92.4375 85.3401 91.9287 85.7899L89.0387 88.9199C88.6687 89.3299 88.8887 89.6899 89.5387 89.7299C90.2176 89.7581 90.8809 89.5215 91.3887 89.0699L94.2887 85.9399C94.6587 85.5399 94.4287 85.1699 93.7787 85.1299Z"
                    fill="url(#paint16_linear_2138_37416)"
                />
                <path
                    d="M73.9868 85.31C73.8619 85.2666 73.7251 85.2725 73.6044 85.3264C73.4836 85.3802 73.388 85.4781 73.3368 85.6C73.0149 86.4225 72.47 87.1389 71.7634 87.6689C71.0569 88.1988 70.2165 88.5213 69.3368 88.6C68.3791 88.614 67.4249 88.4825 66.5068 88.21C65.6883 87.9789 64.8463 87.8413 63.9968 87.8C63.137 87.8368 62.2845 87.9744 61.4568 88.21C60.5388 88.4831 59.5845 88.6146 58.6268 88.6C57.7471 88.5213 56.9067 88.1988 56.2001 87.6689C55.4935 87.1389 54.9487 86.4225 54.6268 85.6C54.5756 85.4781 54.4799 85.3802 54.3592 85.3264C54.2384 85.2725 54.1017 85.2666 53.9768 85.31C53.8551 85.3611 53.7579 85.4573 53.7057 85.5785C53.6535 85.6997 53.6503 85.8365 53.6968 85.96C54.0892 86.9611 54.7564 87.8311 55.6215 88.4697C56.4866 89.1084 57.5145 89.4897 58.5868 89.57H58.9668C59.897 89.5338 60.8199 89.3895 61.7168 89.14C62.9036 88.8257 64.1417 88.7543 65.3568 88.93C66.5698 89.2474 67.8076 89.4615 69.0568 89.57H69.4368C70.5105 89.4904 71.5399 89.1094 72.4068 88.4708C73.2736 87.8322 73.9426 86.9619 74.3368 85.96C74.3568 85.8943 74.3635 85.8254 74.3567 85.7571C74.3499 85.6888 74.3297 85.6225 74.2971 85.5621C74.2646 85.5017 74.2204 85.4483 74.1672 85.405C74.1139 85.3618 74.0526 85.3295 73.9868 85.31Z"
                    fill="white"
                />
                <path
                    d="M40.6328 148.39C40.6328 149.03 40.6328 149.68 40.6328 150.39C40.674 151.46 41.1263 152.473 41.8956 153.218C42.6649 153.963 43.6919 154.383 44.7628 154.39C46.9728 154.39 49.7628 154.39 52.3228 154.39C42.7228 153.82 40.6628 148.39 40.6328 148.39Z"
                    fill="#22C3F3"
                />
                <path
                    d="M87.2994 148.39C87.2994 149.03 87.2994 149.68 87.2994 150.39C87.2582 151.46 86.8059 152.473 86.0366 153.218C85.2673 153.963 84.2403 154.383 83.1694 154.39C80.9594 154.39 78.1694 154.39 75.6094 154.39C85.1794 153.82 87.2694 148.39 87.2994 148.39Z"
                    fill="#22C3F3"
                />
                <path
                    d="M84.7377 129.39C84.678 129.428 84.6315 129.484 84.6044 129.55C84.5773 129.616 84.571 129.689 84.5862 129.758C84.6014 129.828 84.6375 129.891 84.6895 129.939C84.7416 129.988 84.8073 130.019 84.8777 130.03C86.5777 130.24 88.0577 130.44 89.1477 131.87C89.9577 132.93 89.1477 133.72 88.3377 134.45C87.9203 134.766 87.5751 135.168 87.3251 135.629C87.0751 136.089 86.926 136.597 86.8877 137.12C86.885 138.214 87.3159 139.265 88.0861 140.043C88.8563 140.821 89.9032 141.262 90.9977 141.27C95.3377 141.27 98.6277 135.66 96.4577 131.87C94.0077 127.52 88.7277 126.66 84.7377 129.39Z"
                    fill="#7F55A1"
                />
                <path
                    d="M89.4716 132.66C89.4646 133.026 89.3342 133.378 89.1016 133.66C90.6816 133.97 94.2816 134.95 96.4116 137.53C96.5733 137.206 96.7136 136.872 96.8315 136.53C94.8204 134.533 92.2567 133.185 89.4716 132.66Z"
                    fill="#22C3F3"
                />
                <path
                    d="M42.3097 130.39C38.7271 129.876 35.2498 128.792 32.0097 127.18C31.9587 127.153 31.9138 127.116 31.8777 127.072C31.8416 127.027 31.815 126.975 31.7997 126.92L24.9297 130.13C25.3039 131.348 26.0364 132.425 27.0318 133.221C28.0271 134.016 29.2391 134.493 30.5097 134.59C32.5889 134.613 34.6564 134.275 36.6197 133.59C38.6096 132.945 40.5512 132.16 42.4297 131.24C42.5109 131.195 42.5764 131.126 42.6178 131.043C42.6591 130.96 42.6743 130.866 42.6613 130.774C42.6484 130.682 42.6078 130.596 42.545 130.527C42.4823 130.459 42.4002 130.411 42.3097 130.39Z"
                    fill="#7F55A1"
                />
                <path
                    d="M31.4297 134.57C32.6529 132.518 33.3119 130.179 33.3397 127.79L34.3397 128.2C34.2517 130.392 33.6779 132.537 32.6597 134.48C32.2515 134.531 31.841 134.561 31.4297 134.57Z"
                    fill="#22C3F3"
                />
                <path
                    d="M28.7903 133.26C30.9829 133.26 32.7603 131.483 32.7603 129.29C32.7603 127.098 30.9829 125.32 28.7903 125.32C26.5977 125.32 24.8203 127.098 24.8203 129.29C24.8203 131.483 26.5977 133.26 28.7903 133.26Z"
                    fill="#7F55A1"
                />
                <path
                    d="M29.9228 130.741C31.1652 130.72 32.1389 128.724 32.0976 126.284C32.0563 123.843 31.0156 121.881 29.7731 121.902C28.5307 121.923 27.5569 123.919 27.5983 126.36C27.6396 128.801 28.6803 130.762 29.9228 130.741Z"
                    fill="#7F55A1"
                />
                <path
                    d="M29.2507 130.232C30.2975 129.542 30.0644 127.342 28.7301 125.317C27.3957 123.293 25.4655 122.211 24.4188 122.901C23.372 123.591 23.6051 125.791 24.9394 127.816C26.2737 129.84 28.2039 130.922 29.2507 130.232Z"
                    fill="#7F55A1"
                />
                <path
                    d="M51.6523 62.3904C46.5198 62.9621 41.6513 64.965 37.6023 68.1704C31.0823 73.3604 29.6923 83.3604 31.6823 90.5404C32.4123 93.1504 35.2323 95.0604 40.0723 96.2204L41.1823 96.3904L43.6823 95.2804L45.7623 71.3904C45.8709 70.1448 46.2856 68.9453 46.9695 67.8986C47.6533 66.8518 48.5852 65.9902 49.6823 65.3904L56.0723 61.9004C54.5723 62.0204 53.0923 62.1604 51.6523 62.3904Z"
                    fill="url(#paint17_linear_2138_37416)"
                />
                <path
                    d="M39.7031 74.3295C39.7831 78.3295 42.6231 82.3295 46.9431 82.3295C51.2631 82.3295 54.1031 78.3295 54.1831 74.3295C54.161 74.1315 54.0667 73.9486 53.9182 73.8157C53.7697 73.6828 53.5774 73.6094 53.3781 73.6094C53.1789 73.6094 52.9866 73.6828 52.8381 73.8157C52.6896 73.9486 52.5953 74.1315 52.5732 74.3295C52.5032 77.4795 50.3731 80.7495 46.9431 80.7495C43.5131 80.7495 41.3831 77.4795 41.3131 74.3295C41.291 74.1315 41.1967 73.9486 41.0482 73.8157C40.8997 73.6828 40.7074 73.6094 40.5081 73.6094C40.3089 73.6094 40.1166 73.6828 39.9681 73.8157C39.8196 73.9486 39.7252 74.1315 39.7031 74.3295Z"
                    fill="white"
                />
                <path
                    d="M36.7393 85.1302C36.0604 85.102 35.397 85.3386 34.8893 85.7902L31.9893 88.9202C31.6193 89.3302 31.8493 89.6902 32.4993 89.7302C33.1779 89.7563 33.8405 89.52 34.3493 89.0702L37.2393 85.9402C37.6093 85.5402 37.3893 85.1702 36.7393 85.1302Z"
                    fill="url(#paint18_linear_2138_37416)"
                />
                <path
                    d="M40.7474 85.1299C40.0688 85.1038 39.4063 85.3401 38.8974 85.7899L36.0074 88.9199C35.6374 89.3299 35.8574 89.6899 36.5074 89.7299C37.1863 89.7581 37.8497 89.5215 38.3574 89.0699L41.2574 85.9399C41.6274 85.5399 41.3974 85.1699 40.7474 85.1299Z"
                    fill="url(#paint19_linear_2138_37416)"
                />
                <path
                    d="M44.7706 85.1302C44.0916 85.102 43.4283 85.3386 42.9205 85.7902L40.0206 88.9202C39.6506 89.3302 39.8806 89.6902 40.5306 89.7302C41.2092 89.7563 41.8717 89.52 42.3806 89.0702L45.2706 85.9402C45.6406 85.5402 45.4206 85.1702 44.7706 85.1302Z"
                    fill="url(#paint20_linear_2138_37416)"
                />
                <path
                    d="M50.8416 4.86026C49.6516 5.58026 48.4616 6.28026 47.2816 7.02026C46.834 7.29849 46.4626 7.68357 46.2008 8.1409C45.939 8.59823 45.7949 9.11346 45.7816 9.64026C45.7737 10.2059 45.9192 10.7631 46.2027 11.2526C46.4862 11.7421 46.897 12.1456 47.3915 12.4203L59.4815 20.0803C60.7493 20.9376 62.2411 21.4036 63.7715 21.4203C65.3032 21.4465 66.8089 21.0223 68.1016 20.2003L76.8516 14.9503L80.2816 12.8802C80.788 12.6265 81.2159 12.2396 81.5192 11.7612C81.8225 11.2827 81.9898 10.7307 82.0032 10.1643C82.0165 9.59799 81.8754 9.03869 81.595 8.54645C81.3146 8.05421 80.9055 7.64762 80.4116 7.37027L71.1016 1.44025L67.5716 3.54025C67.0922 3.80523 66.6921 4.19326 66.4126 4.66434C66.1331 5.13542 65.9843 5.6725 65.9815 6.22025C65.9734 6.78338 66.1178 7.33824 66.3995 7.82593C66.6812 8.31361 67.0897 8.71595 67.5815 8.99026L69.1216 9.99026L63.8716 13.1203L59.9815 10.6403L58.6516 9.80026L62.4915 7.57025C62.9415 7.29518 63.3145 6.91054 63.5755 6.45224C63.8365 5.99394 63.977 5.47695 63.984 4.94958C63.991 4.42222 63.8642 3.9017 63.6154 3.43665C63.3667 2.97159 63.0041 2.57716 62.5616 2.29025L58.9915 0.0302734L50.8716 4.87027"
                    fill="url(#paint21_linear_2138_37416)"
                />
                <path
                    opacity="0.47"
                    d="M59.0372 4.51232L58.6672 3.87147C58.5705 3.70407 58.3565 3.64671 58.1891 3.74336L52.8197 6.84335C52.6523 6.94 52.595 7.15407 52.6916 7.32147L53.0616 7.96232C53.1583 8.12972 53.3723 8.18708 53.5397 8.09043L58.9091 4.99044C59.0765 4.89379 59.1338 4.67973 59.0372 4.51232Z"
                    fill="white"
                />
                <path
                    opacity="0.47"
                    d="M51.4681 9.40988C51.9597 9.40988 52.3581 9.01142 52.3581 8.51988C52.3581 8.02835 51.9597 7.62988 51.4681 7.62988C50.9766 7.62988 50.5781 8.02835 50.5781 8.51988C50.5781 9.01142 50.9766 9.40988 51.4681 9.40988Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_2138_37416"
                    x1="63.988"
                    y1="144.9"
                    x2="44.548"
                    y2="102.47"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="0.51" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2138_37416"
                    x1="63.9562"
                    y1="127.26"
                    x2="63.9562"
                    y2="134.76"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#60388C" />
                    <stop offset="0.62" stopColor="#3F2356" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2138_37416"
                    x1="78.3331"
                    y1="117.94"
                    x2="53.4631"
                    y2="71.07"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="1" stopColor="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2138_37416"
                    x1="37.688"
                    y1="67.0903"
                    x2="103.208"
                    y2="104.48"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stopColor="white" />
                    <stop offset="1" stopColor="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_2138_37416"
                    x1="54.1612"
                    y1="2.2205"
                    x2="54.1612"
                    y2="38.9505"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_2138_37416"
                    x1="74.0697"
                    y1="2.22014"
                    x2="74.0697"
                    y2="38.9501"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_2138_37416"
                    x1="63.9278"
                    y1="26.3105"
                    x2="63.9278"
                    y2="88.9905"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_2138_37416"
                    x1="63.9025"
                    y1="26.31"
                    x2="63.9025"
                    y2="88.9902"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_2138_37416"
                    x1="41.0806"
                    y1="26.3097"
                    x2="41.0806"
                    y2="88.9899"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_2138_37416"
                    x1="86.7691"
                    y1="26.3097"
                    x2="86.7691"
                    y2="88.9899"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_2138_37416"
                    x1="75.4266"
                    y1="2.21961"
                    x2="75.4266"
                    y2="38.9498"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_2138_37416"
                    x1="52.7491"
                    y1="2.22017"
                    x2="52.7491"
                    y2="38.9501"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_2138_37416"
                    x1="72.8997"
                    y1="8.14946"
                    x2="72.8997"
                    y2="49.9802"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_2138_37416"
                    x1="54.96"
                    y1="8.13919"
                    x2="54.96"
                    y2="49.9799"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3DA77" />
                    <stop offset="0.04" stopColor="#E8CA63" />
                    <stop offset="0.1" stopColor="#DAB74A" />
                    <stop offset="0.16" stopColor="#D2AB3B" />
                    <stop offset="0.21" stopColor="#CFA736" />
                    <stop offset="0.35" stopColor="#E1C666" />
                    <stop offset="0.56" stopColor="#FBF5AD" />
                    <stop offset="0.6" stopColor="#FAF2A8" />
                    <stop offset="0.66" stopColor="#F6E899" />
                    <stop offset="0.72" stopColor="#F1D980" />
                    <stop offset="0.79" stopColor="#E9C35D" />
                    <stop offset="0.84" stopColor="#E1AC38" />
                    <stop offset="0.89" stopColor="#D7A236" />
                    <stop offset="0.96" stopColor="#BD892F" />
                    <stop offset="1" stopColor="#AC792B" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_2138_37416"
                    x1="96.6774"
                    y1="87.4299"
                    x2="86.9174"
                    y2="87.4299"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F7B9D4" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#E17FAF" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_2138_37416"
                    x1="96.6805"
                    y1="87.4302"
                    x2="86.9206"
                    y2="87.4302"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F7B9D4" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#E17FAF" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_2138_37416"
                    x1="96.6787"
                    y1="87.4299"
                    x2="86.9187"
                    y2="87.4299"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F7B9D4" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#E17FAF" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_2138_37416"
                    x1="52.5823"
                    y1="67.6904"
                    x2="36.0923"
                    y2="86.7904"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.25" stopColor="#353537" />
                    <stop offset="1" stopColor="#17171A" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_2138_37416"
                    x1="47.6693"
                    y1="87.4302"
                    x2="37.9093"
                    y2="87.4302"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F7B9D4" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#E17FAF" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_2138_37416"
                    x1="47.6674"
                    y1="87.4299"
                    x2="37.9074"
                    y2="87.4299"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F7B9D4" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#E17FAF" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_2138_37416"
                    x1="47.6706"
                    y1="87.4302"
                    x2="37.9106"
                    y2="87.4302"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F7B9D4" stopOpacity="0.4" />
                    <stop offset="1" stopColor="#E17FAF" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_2138_37416"
                    x1="70.6516"
                    y1="16.3303"
                    x2="35.3916"
                    y2="-17.3497"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98" />
                    <stop offset="0.67" stopColor="#8964A9" />
                </linearGradient>
                <clipPath id="clip0_2138_37416">
                    <rect width="128.19" height="154.35" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default YAYSVG;
