import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.REGISTER_REALTIME_USER_ACTION](state, { payload }) {
        const { actionId, body } = payload;
        const { userRealtimeActions } = state;
        return {
            ...state,
            userRealtimeActions: [...userRealtimeActions, { actionId, body }],
            currentUserAction: body
        };
    },
    [types.REMOVE_REALTIME_USER_ACTION](state, { payload }) {
        const { actionId } = payload;
        const { userRealtimeActions } = state;
        const newUserRealtimeActions = produce(userRealtimeActions, draft => {
            const actionIndex = draft.findIndex(action => action.actionId === actionId);

            if (actionIndex !== -1) {
                draft = draft.slice(actionIndex + 1);
                return draft;
            }
        });
        return {
            ...state,
            userRealtimeActions: newUserRealtimeActions
        };
    },
    [types.USER_JOIN](state, { payload }) {
        const { activeUsers } = state;
        const { user } = payload;
        const index = activeUsers.findIndex(activeUser => activeUser?.id === user?.id);

        return {
            ...state,
            activeUsers: index === -1 ? [...activeUsers, user] : activeUsers
        };
    },
    [types.USER_LEAVE](state, { payload }) {
        const { activeUsers } = state;
        const { activeUserActions } = state;
        const { user } = payload;
        const newActiveUsers = activeUsers.filter(activeUser => activeUser.id !== user?.id);
        const newActiveUserActions = activeUserActions.filter(
            activeUserAction => activeUserAction?.user?.id !== user?.id
        );

        return {
            ...state,
            activeUsers: newActiveUsers,
            activeUserActions: newActiveUserActions
        };
    },
    [types.USER_DOING](state, { payload }) {
        const { activeUserActions } = state;
        const data = payload?.data;

        const newActiveUserActions = produce(activeUserActions, draft => {
            let index = draft.findIndex(action => action?.user?.id === data?.user?.id);
            if (index !== -1) {
                draft[index] = data;
                return draft;
            }
            draft = [...draft, data];
            return draft;
        });

        return {
            ...state,
            activeUserActions: newActiveUserActions
        };
    },
    [types.CLEAR_ACTIVE_USER](state, { payload }) {
        return {
            ...state,
            activeUsers: [],
            activeUserActions: [],
            userRealtimeActions: [],
            currentUserAction: null
        };
    }
};

export default handler;
