import React from 'react';
import { fade, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PopperMenu from 'components/menus/Popper';
import { useCurrentAdvancedSearchId, usePinnedSearch } from 'hooks/advanced';
import { useParams } from 'react-router-dom';
import { isKbSearch } from 'utils/keyboard';
import { useDocumentEventListener } from 'hooks/useDocumentEventListener';
import Tooltip from 'components/tooltip/Base';
import SearchRecordBox from './searchRecord';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useDispatch } from 'react-redux';
import ReplaceSVG from 'assets/images/svg/ReplaceSVG';

const useStyles = makeStyles(theme => ({
    menuItem: {
        // marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    searchBox: {
        height: 40,
        background: theme.colors.background,
        '& input': {
            fontSize: '14px !important',
            '&::placeholder': {
                color: `${theme.colors.disabledGrey} !important`,
                opacity: 1
            }
        }
    },
    popperSearchMenu: {
        '& > div': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    searchIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        padding: 8,
        borderRadius: 4,
        '& svg': {
            position: 'relative',
            width: 18,
            height: 18,
            '& path': {
                fill: '#606066'
            }
        }
    },
    highlight: {
        background: fade(theme.colors.fuchsiaBlue, 0.3)
    }
}));

const ManageSearch = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { dbId, viewId } = useParams();
    const pinnedSearch = usePinnedSearch();
    const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
    const searchRecordRef = React.useRef();
    const searchRef = React.useRef();
    const timerRef = React.useRef();
    const [searchValue, setSearchValue] = React.useState('');
    const currentAdvancedSearchId = useCurrentAdvancedSearchId();

    const handleSearchValueChange = React.useCallback(
        value => {
            setSearchValue(value);
            dispatch(advancedSearchActions.setSearch(value));
        },
        [dispatch]
    );

    React.useEffect(() => {
        setSearchValue(``);
    }, [currentAdvancedSearchId]);

    const handleSearchChange = React.useCallback(
        e => {
            const value = e?.target?.value;
            handleSearchValueChange(value);
            if (timerRef.current) clearTimeout(timerRef.current);
            // timerRef.current = setTimeout(() => {
            //     sendManualTrack({ type: 'Search in Grid', customData: { searchText: value } });
            // }, DEBOUNCE_TIME_SEARCHING);
        },
        [handleSearchValueChange]
    );

    const handleClearSearch = React.useCallback(() => {
        handleSearchValueChange('');
    }, [handleSearchValueChange]);

    const isHasSearch = React.useMemo(() => {
        return searchValue?.length;
    }, [searchValue]);

    const handleOpenSearchBox = React.useCallback(e => {
        setSearchAnchorEl(searchRecordRef?.current);
    }, []);

    const handleCloseSearchRecordBox = React.useCallback(e => {
        setSearchAnchorEl(null);
    }, []);

    const handleClickAwaySearch = React.useCallback(
        e => {
            if (!pinnedSearch) {
                handleCloseSearchRecordBox();
            }
        },
        [handleCloseSearchRecordBox, pinnedSearch]
    );

    const keydownHandler = React.useCallback(
        e => {
            if (isKbSearch(e)) {
                e.preventDefault();
                handleOpenSearchBox();
                if (searchRef.current) {
                    searchRef.current.focus();
                }
            }
        },
        [handleOpenSearchBox]
    );

    useDocumentEventListener('keydown', keydownHandler);

    return (
        <>
            <Grid
                ref={searchRecordRef}
                item
                id={'search_record'}
                className={classes.menuItem}
                onClick={handleOpenSearchBox}
            >
                <Tooltip title="Find in Grid (Ctrl / Cmd + F)" placement="bottom-end">
                    <Grid item className={`${classes.searchIcon} ${isHasSearch && classes.highlight}`}>
                        <ReplaceSVG />
                    </Grid>
                </Tooltip>
            </Grid>
            {searchAnchorEl && (
                <PopperMenu
                    id={'popper-searchBox'}
                    placement={'bottom-end'}
                    anchorEl={searchAnchorEl}
                    handleClickAway={handleClickAwaySearch}
                    className={classes.popperSearchMenu}
                >
                    <SearchRecordBox
                        dbId={dbId}
                        viewId={viewId}
                        onClose={handleCloseSearchRecordBox}
                        value={searchValue}
                        handleSearchChange={handleSearchChange}
                        handleClearSearch={handleClearSearch}
                    />
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ManageSearch);
