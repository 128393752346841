import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useTheme } from '@material-ui/core/styles';

import { MAX_OPTIONS, DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';

import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import Option from './common/Option';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function LDSelectBaseServer({
    defaultValue,
    selectedColor,
    maxSize = MAX_OPTIONS,
    searchKey = '',
    columnId,
    serverFunction,
    onCallback,
    ...other
}) {
    const theme = useTheme();
    const selectRef = useRef();
    const [inputValue, setInputValue] = useState(searchKey);
    const timer = useRef();

    useEffect(() => {
        setInputValue(searchKey);
    }, [searchKey]);

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        }),
        menu: styles => ({
            ...styles,
            zIndex: 999
        })
    };

    const loadOptions = useCallback(
        (inputValue, callback) => {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(async () => {
                const options = await serverFunction(inputValue);
                callback(options);
                onCallback && onCallback();
            }, DEBOUNCE_TIME_SEARCHING);
        },
        [serverFunction, onCallback]
    );

    useEffect(() => {
        const { select } = selectRef.current;
        const { onInputChange } = select;
        onInputChange(inputValue);
    }, [inputValue]);

    return (
        <AsyncSelect
            ref={selectRef}
            autoFocus
            cacheOptions
            defaultOptions={true}
            inputValue={inputValue}
            defaultInputValue={inputValue}
            onInputChange={newInput => setInputValue(newInput)}
            loadOptions={loadOptions}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            tabSelectsValue={false}
            components={components}
            value={defaultValue}
            defaultValue={defaultValue}
            styles={selectStyles}
            menuIsOpen
            {...other}
        />
    );
}

LDSelectBaseServer.propTypes = {
    ddPlaceholder: PropTypes.string,
    menuPlaceholder: PropTypes.string,
    isMulti: PropTypes.bool,
    maxSize: PropTypes.number,
    searchKey: PropTypes.string
};

export default React.memo(LDSelectBaseServer);
