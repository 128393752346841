import produce from 'immer';
import { deleteObjectProperty } from 'utils/object';
import * as types from '../types';
import deepmerge from 'deepmerge';
import { sortBy } from 'lodash';

const handler = {
    [types.GET_VIEW_COLUMNS](state, action) {
        const { views } = action?.payload;
        return {
            ...state,
            views
        };
    },
    [types.CREATE_VIEW](state) {
        return {
            ...state,
            isAddingView: true
        };
    },

    [types.CREATE_VIEW_FAILED](state, action) {
        const { error } = action.payload;
        return {
            ...state,
            isAddingView: false,
            error
        };
    },

    [types.CREATE_VIEW_SUCCESS](state, action) {
        const { view } = action.payload;

        const newViews = [...(state?.views || []), view];
        const sortedViews = sortBy(newViews, ['name', 'asc']);

        return {
            ...state,
            views: sortedViews,
            isAddingView: false,
            currentView: view
        };
    },
    [types.CREATE_VIEW_REALTIME_SUCCESS](state, action) {
        const { view } = action.payload;
        return {
            ...state,
            views: [...state.views, view]
        };
    },
    [types.UPDATE_VIEW](state, action) {
        const { view } = action.payload;
        const { views, currentView } = state;
        const newViews = views.map(each => {
            if (each.id === view.id) {
                return view;
            }
            return each;
        });
        return {
            ...state,
            views: newViews,
            currentView: currentView.id === view?.id ? view : currentView
        };
    },

    [types.DELETE_VIEW](state, action) {
        const { viewId } = action.payload;
        const viewsCoppied = [...state.views];
        const newViews = viewsCoppied.map(view => {
            if (view.id === viewId) {
                view.isDeleted = true;
            }
            return view;
        });

        return {
            ...state,
            views: newViews
        };
    },
    [types.DELETE_VIEW_FAILED](state, action) {
        const { viewId } = action.payload;
        const viewsCoppied = [...state.views];
        const newViews = viewsCoppied.map(view => {
            if (view.id === viewId) {
                view.isDeleted = false;
            }
            return view;
        });

        return {
            ...state,
            views: newViews
        };
    },
    [types.DELETE_VIEW_SUCCESS](state, action) {
        const { viewId } = action.payload;
        const viewsCoppied = [...state.views];
        const newViews = viewsCoppied.filter(view => view.id !== viewId);

        return {
            ...state,
            views: newViews
        };
    },
    [types.UPDATE_VIEW_COLUMN_PERMISSION](state, action) {
        const { column, viewColumns, metaData } = action.payload;
        const { viewable } = column;
        const { columnsPermission } = state;
        const newColumnsPermission = columnsPermission.map(col => {
            if (col.id === column.id) {
                if (!viewable) {
                    return {
                        ...col,
                        viewable: column.viewable,
                        editable: false
                    };
                }
                return {
                    ...col,
                    viewable: column.viewable
                };
            }
            return col;
        });
        return {
            ...state,
            isUpdatingColumnsPermission: true,
            columnsPermission: newColumnsPermission,
            viewColumns,
            metaData
        };
    },

    [types.UPDATE_EDIT_COLUMN_PERMISSION](state, action) {
        const { column, viewColumns, metaData } = action.payload;
        const { columnsPermission } = state;
        const newColumnsPermission = columnsPermission.map(col => {
            if (col.id === column.id) {
                return {
                    ...col,
                    editable: column.editable
                };
            }
            return col;
        });
        return {
            ...state,
            isUpdatingColumnsPermission: true,
            columnsPermission: newColumnsPermission,
            viewColumns,
            metaData
        };
    },

    [types.UPDATE_VIEW_VIEW_COLUMNS_PERMISSION_SUCCESS](state, action) {
        const { data } = action.payload;
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

        return {
            ...state,
            isUpdatingColumnsPermission: false,
            data: deepmerge({ ...state.data }, data, { arrayMerge: overwriteMerge })
        };
    },

    [types.UPDATE_VIEW_EDIT_COLUMNS_PERMISSION_SUCCESS](state) {
        return {
            ...state,
            isUpdatingColumnsPermission: false
        };
    },

    [types.UPDATE_VIEW_COLUMNS_PERMISSION_FAILED](state, action) {
        const { error } = action.payload;
        return {
            ...state,
            isUpdatingColumnsPermission: false,
            error
        };
    },

    [types.UPDATE_VIEW_COLUMN_PERMISSION_SOCKET](state, action) {
        const { columnId, data } = action.payload;
        const { viewColumns, columnsPermission, metaData } = state;
        let newViewColumns = viewColumns.map(col => {
            if (col.id === columnId) {
                return {
                    ...col,
                    ...data
                };
            }
            return col;
        });

        let newColumnsPermission = columnsPermission.map(col => {
            if (col.id === columnId) {
                return {
                    ...col,
                    editable: data.editable,
                    viewable: col.viewable
                        ? {
                              ...col.viewable,
                              ...data
                          }
                        : data
                };
            }
            return col;
        });

        const newMetaData = produce(metaData, draft => {
            draft[columnId] = {
                ...draft[columnId],
                editable: data.editable
            };
            return draft;
        });

        return {
            ...state,
            viewColumns: newViewColumns,
            columnsPermission: newColumnsPermission,
            metaData: newMetaData
        };
    },

    [types.DELETE_VIEW_COLUMN_PERMISSION_SOCKET](state, action) {
        const { columnId } = action.payload;
        const { viewColumns, columnsPermission, metaData } = state;
        let newViewColumns = viewColumns.map(col => {
            if (col.id === columnId) {
                return {
                    ...col,
                    viewable: false,
                    editable: false
                };
            }
            return col;
        });

        let newColumnsPermission = columnsPermission.map(col => {
            if (col.id === columnId) {
                return {
                    ...col,
                    viewable: false,
                    editable: false
                };
            }
            return col;
        });
        let newMetaData = { ...metaData };
        if (metaData[columnId]) {
            newMetaData = deleteObjectProperty(newMetaData, columnId);
        }

        return {
            ...state,
            viewColumns: newViewColumns,
            columnsPermission: newColumnsPermission,
            metaData: newMetaData
        };
    },
    [types.GET_CURRENT_VIEW_SUCCESS](state, action) {
        const { currentView } = action.payload;
        const fixedColumnCount = currentView?.customProperties?.fixedColumnCount || 0;

        return {
            ...state,
            currentView,
            fixedColumnCount
        };
    },
    [types.CHANGE_FREEZING_COLUMN](state, action) {
        const { index } = action.payload;

        return {
            ...state,
            fixedColumnCount: index
        };
    },
    [types.CHANGE_ORDER_COLUMN_PERMISSION_AFTER_ADD_TO_VIEW](state, { payload }) {
        const { columnId, order } = payload;
        const { viewColumns, columnsPermission } = state;
        const newViewColumns = viewColumns.map(vCol => {
            if (columnId === vCol.id) {
                return {
                    ...vCol,
                    order
                };
            }
            return vCol;
        });
        const newColumnsPermission = columnsPermission.map(colPer => {
            if (columnId === colPer.id) {
                return {
                    ...colPer,
                    order
                };
            }
            return colPer;
        });
        return {
            ...state,
            viewColumns: newViewColumns,
            columnsPermission: newColumnsPermission
        };
    },
    [types.FETCH_CURRENT_VIEW_SUCCESS](state, action) {
        return {
            ...state,
            isFetchingView: false
        };
    },
    [types.FETCH_CURRENT_VIEW](state, action) {
        return {
            ...state,
            isFetchingView: true
        };
    },
    [types.FETCH_CURRENT_VIEW_FAILED](state, action) {
        return {
            ...state,
            isFetchingView: false
        };
    },
    [types.TOGGLE_BLOCK_CURRENT_VIEW](state, action) {
        return {
            ...state,
            isViewBlocked: true
        };
    }
};

export default handler;
