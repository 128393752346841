import React, { useState } from 'react';
import { FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import InputText from 'components/inputs/InputText';
import { useRegionList } from 'hooks/workspace';
import { enabledRegion } from 'config';

const useStyles = makeStyles(theme => ({
    mt3: {
        marginTop: theme.spacing(3)
    },
    regionDescription: {
        color: theme.colors.steel
    },
    regionWarning: {
        color: theme.colors.brightRed
    }
}));

function CreateProjectModal({ onClose, onSubmit }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const regions = useRegionList();
    const [name, setName] = useState(t('new_project'));
    const [description, setDescription] = useState('');
    const [region, setRegion] = useState(regions[0]?.name || '');

    return (
        <>
            <DialogTitle title="Add a new project" onClose={onClose} />
            <DialogContent>
                <Grid container>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{t('edit_project_name_field_label')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText
                                name="projectName"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                height={40}
                                autoFocus={true}
                                placeholder="Enter your project name"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2} className={classes.mt3}>
                        <Grid item>
                            <Typography variant="body1">{t('edit_project_description_field_label')}</Typography>
                        </Grid>
                        <Grid item>
                            <InputText
                                name="projectDescription"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                height={155}
                                rows={6}
                                placeholder="Enter project description"
                                multiline
                            />
                        </Grid>
                    </Grid>
                    {enabledRegion && (
                        <Grid item container direction="column" spacing={1} className={classes.mt3}>
                            <Grid item>
                                <Typography variant="body1">{t('project_region')}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" display="inline" className={classes.regionDescription}>
                                    Please choose the closest region to your members who work on the project for best
                                    performance.
                                </Typography>
                                <Typography variant="body2" display="inline" className={classes.regionWarning}>
                                    {' '}
                                    This region cannot be changed.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <RadioGroup row name="region" value={region}>
                                    {regions.map(region => (
                                        <FormControlLabel
                                            key={region?.name}
                                            value={region?.name}
                                            control={<Radio size="small" />}
                                            label={<Typography variant="body2">{region?.description}</Typography>}
                                            onChange={e => setRegion(e.target.value)}
                                        />
                                    ))}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            width={120}
                            onClick={() => onSubmit({ name: name.trim(), description, ...(region && { region }) })}
                            disabled={!name}
                        >
                            Add Project
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(CreateProjectModal);
