import React from 'react';
import CardInfoItem from 'components/list/CardInfoItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {},
    focused: {
        background: theme.colors.hoverItem
    }
}));

function OptionCardInfo(props) {
    const classes = useStyles();
    const { data, isSelected, innerRef, children, innerProps, isFocused } = props;
    const { icon, subTitle, isDisabled, moreIcon } = data;

    let Icon = typeof icon === 'function' ? icon() : icon;

    return (
        <div className={isFocused ? classes.focused : ``} ref={innerRef} {...innerProps}>
            <CardInfoItem
                disabled={isDisabled}
                ref={innerRef}
                icon={Icon ? Icon : undefined}
                name={children}
                subTitle={subTitle}
                isSelected={isSelected}
                moreIcon={moreIcon}
                {...innerProps}
            />
        </div>
    );
}

export default React.memo(OptionCardInfo);
