import React from 'react';

function CardSecuredSVG({ ...other }) {
    return (
        <svg {...other} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 6V5C10 3 8.209 1 6 1C3.791 1 2 2.97508 2 5V6C1.44772 6 1 6.45098 1 6.99078V11.0092C1 11.5564 1.45566 12 1.9954 12H10.0046C10.5543 12 11 11.549 11 11.0092V6.99078C11 6.44359 10.5468 6 10 6ZM4 6H8V5C8 5 8 3 6 3C4 3 4 5 4 5V6Z"
                fill="#ACADB9"
            />
        </svg>
    );
}

export default React.memo(CardSecuredSVG);
