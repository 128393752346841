import React from 'react';
import About from 'workspaces/about';
import GroupAndMember from 'workspaces/groupsAndMembers';
import Backup from 'databases/backup';
import Details from 'workspaces/details';
import UsageStatistic from 'payment/usage';
import { WORKSPACE_DETAILS, WORKSPACE_ABOUT, WORKSPACE_GROUP_MEMBER, DATABASE_BACKUP, USAGE_STATISTIC } from 'const';
import SidebarRightLayout from './SidebarRightLayout';
import { setRightSidebar } from 'app/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

function RightSidebar({ type }) {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const onBack = () => {
        dispatch(setRightSidebar({ type: WORKSPACE_DETAILS }));
    };

    const onClose = () => {
        dispatch(setRightSidebar({ type: null }));
    };

    switch (type) {
        case WORKSPACE_DETAILS:
            return (
                <SidebarRightLayout title={t('project_detail_title')} onClose={onClose}>
                    <Details />
                </SidebarRightLayout>
            );
        case WORKSPACE_ABOUT:
            return (
                <SidebarRightLayout title={t('project_detail_about_title')} onBack={onBack} onClose={onClose}>
                    <About />
                </SidebarRightLayout>
            );
        case WORKSPACE_GROUP_MEMBER:
            return (
                <SidebarRightLayout title={t('project_detail_groups_members_title')} onBack={onBack} onClose={onClose}>
                    <GroupAndMember />
                </SidebarRightLayout>
            );
        case DATABASE_BACKUP:
            return <Backup />;
        case USAGE_STATISTIC:
            return (
                <SidebarRightLayout title={t('project_detail_usage_statistics')} onBack={onBack} onClose={onClose}>
                    <UsageStatistic />
                </SidebarRightLayout>
            );
        default:
            return null;
    }
}

export default React.memo(RightSidebar);
