import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_TM_ID](state, { payload }) {
        const { tmId, defaultTm } = payload;
        console.log('defaultTm', defaultTm);
        return {
            ...state,
            tmId,
            defaultTm
        };
    },

    [types.FETCH_TM_SUGGESTION_SUCCESS](state, { payload }) {
        const { rowId, columnId, translations } = payload;
        const { dataFromTM } = state;

        const newDataFromTM = produce(dataFromTM, draft => {
            if (!draft[rowId]) {
                draft[rowId] = {};
            }
            draft[rowId][columnId] = translations;
        });

        return {
            ...state,
            dataFromTM: newDataFromTM
        };
    },
    [types.ADD_TM_SUGGESTION](state, { payload }) {
        const { rowId, columnId, value } = payload;
        const { dataFromTM } = state;

        const newDataFromTM = produce(dataFromTM, draft => {
            if (!draft[rowId]) {
                draft[rowId] = {};
            }
            draft[rowId][columnId] = [...(draft[rowId][columnId] || []), value];
        });

        return {
            ...state,
            dataFromTM: newDataFromTM
        };
    },
    [types.ADD_TM_SUGGESTION_FAILED](state, { payload }) {
        const { rowId, columnId, value } = payload;
        const { dataFromTM } = state;

        const newDataFromTM = produce(dataFromTM, draft => {
            if (!draft[rowId]) {
                draft[rowId] = {};
            }
            draft[rowId][columnId] = draft[rowId][columnId]?.filter(unit => unit !== value);
        });

        return {
            ...state,
            dataFromTM: newDataFromTM
        };
    },
    [types.UPDATE_TM_SUGGESTION](state, { payload }) {
        const { rowId, columnId, values } = payload;
        const { dataFromTM } = state;

        const newDataFromTM = produce(dataFromTM, draft => {
            if (!draft[rowId]) {
                draft[rowId] = {};
            }
            draft[rowId][columnId] = values;
        });

        return {
            ...state,
            dataFromTM: newDataFromTM
        };
    },
    [types.SET_IS_APPLYING_TM](state, { payload }) {
        return {
            ...state,
            isApplyingTM: payload
        };
    }
};

export default handler;
