import { DESERIALIZE_TYPES } from '../const';

export const withPayload = editor => {
    const { isInline, isVoid } = editor;

    editor.isInline = element => {
        return element.type === DESERIALIZE_TYPES.PAYLOAD ? true : isInline(element);
    };

    editor.isVoid = element => {
        return element.type === DESERIALIZE_TYPES.PAYLOAD ? true : isVoid(element);
    };

    return editor;
};
