import React from 'react';

function IconDirectionRightSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9">
            <path
                fill={color}
                fillRule="nonzero"
                d="M.5 4.5c0-.436.327-.797.751-.854l.118-.008h9.163L8.861 1.974a.858.858 0 0 1-.164-.997L8.768.86 8.86.753A.874.874 0 0 1 9.982.662l.107.09 3.159 3.137c.09.09.159.197.204.332l.038.144.01.17-.022.164-.032.107-.057.12-.067.1-.074.084-3.159 3.137a.873.873 0 0 1-1.23 0 .858.858 0 0 1-.089-1.116l.09-.105 1.674-1.662H1.369a.868.868 0 0 1-.835-.623l-.026-.123L.5 4.5z"
            />
        </svg>
    );
}

export default React.memo(IconDirectionRightSVG);
