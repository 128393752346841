import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NestedSettingLayout from '../common/NestedSettingLayout';
import AddRoleButton from './AddRoleButton';
import { Grid } from '@material-ui/core';
import Search from 'components/search';
import {
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    INPUT_RADIUS,
    INPUT_PADDING_LEFT
} from 'const/style';
import RoleList from './RoleList';
import RoleContent from './RoleContent';
import { useDispatch } from 'react-redux';
import { useRoleList, useSelectedRoleId, useIsFetchingRoles, useDefaultPrivileges } from 'hooks/permission';
import * as permissionActions from '../actions';
import { useParams, useHistory } from 'react-router-dom';
import RoleListSkeleton from './RoleListSkeleton';
import { useTranslation } from 'react-i18next';
import { isExternal } from 'config';
import { COMPANY_AUTHORITIES } from 'auth/roleConst';

const useStyles = makeStyles(theme => ({
    root: {},
    sidebar: {
        padding: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        flexBasis: 260,
        borderRight: `1px solid ${theme.colors.border}`,

        [theme.breakpoints.down('xs')]: {
            flexBasis: 'inherit'
        }
    },
    listItem: {
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        borderRadius: INPUT_RADIUS,
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT
    },
    active: {
        background: theme.colors.hoverItem
    },
    name: {
        fontSize: '0.875rem',
        color: theme.colors.dimGrey
    },
    search: {
        width: 220,
        [theme.breakpoints.down('xs')]: {
            width: 150
        }
    }
}));

function Roles() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const roles = useRoleList();
    const defaultPrivileges = useDefaultPrivileges();
    const isFetchingRoles = useIsFetchingRoles();
    const params = useParams();
    const history = useHistory();
    const selectedRoleId = useSelectedRoleId();
    const roleId = Number(params.roleId);
    const [searchValue, setSearchValue] = React.useState('');
    const filteredPrivileges = isExternal
        ? defaultPrivileges.filter(privilege => privilege.name !== COMPANY_AUTHORITIES.MANAGE_BILLING)
        : defaultPrivileges;

    React.useEffect(() => {
        if (roleId) {
            dispatch(permissionActions.changeRole({ roleId }));
        }
    }, [dispatch, roleId]);

    React.useEffect(() => {
        permissionActions.fetchRoles({});
    }, [dispatch]);

    React.useEffect(() => {
        if (!roles.length) return;
        history.push(`/company-settings/roles/${roleId || roles[0].id}`);
    }, [history, roleId, roles]);

    React.useEffect(() => {
        if (!selectedRoleId || !roleId) {
            dispatch(permissionActions.fetchPrivileges({}));
        }
    }, [dispatch, selectedRoleId, roleId]);

    const handleRoleSelect = roleId => {
        history.push(`/company-settings/roles/${roleId}`);
    };

    const handleRoleCreated = createdRole => {
        const roleId = createdRole?.id;
        history.push(`/company-settings/roles/${roleId}`);
    };

    const filteredRoles = React.useMemo(() => {
        let result = roles;

        if (searchValue.trim() !== '') {
            result = roles.filter(item => item.name.toLowerCase().includes(searchValue.trim().toLowerCase()));
        }

        return result;
    }, [searchValue, roles]);

    return (
        <NestedSettingLayout
            name={t('company_roles')}
            Sidebar={
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Search
                            className={classes.search}
                            background={theme.colors.paleGrey}
                            onChange={e => setSearchValue(e.target.value)}
                            placeholder={'Find a role'}
                        />
                    </Grid>
                    <Grid item className={classes.roleList}>
                        {isFetchingRoles && roles === null ? (
                            <RoleListSkeleton />
                        ) : (
                            <RoleList
                                roles={filteredRoles}
                                selectedRoleId={selectedRoleId}
                                handleItemClick={handleRoleSelect}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <AddRoleButton onRoleCreated={handleRoleCreated} />
                    </Grid>
                </Grid>
            }
            Content={<RoleContent defaultPrivileges={filteredPrivileges} selectedRoleId={selectedRoleId} />}
        ></NestedSettingLayout>
    );
}

export default React.memo(Roles);
