import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImportNotification from './Import';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import { NOTIFICATION_TYPES } from 'const';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import { useNotificationData } from 'hooks/app';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        width: 850,
        height: 535
    }
}));

function Name({ notificationType, onClose }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const data = useNotificationData();

    const title = React.useMemo(() => {
        switch (notificationType) {
            case NOTIFICATION_TYPES.IMPORT:
                return 'Import log';
            default:
                return '';
        }
    }, [notificationType]);

    const content = React.useMemo(() => {
        switch (notificationType) {
            case NOTIFICATION_TYPES.IMPORT:
                return <ImportNotification data={data} />;
            default:
                return null;
        }
    }, [notificationType, data]);

    return (
        <>
            <DialogTitle title={title} onClose={onClose} />
            <DialogContent className={classes.content}>{content}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} width={120} variant="contained">
                    {t('global_close')}
                </Button>
            </DialogActions>
        </>
    );
}

export default Name;
