import { init } from 'filestack-js';
import { FILE_STACK_API } from 'config';

let filestack;

export const initialize = callback => {
    filestack = init(FILE_STACK_API);
    callback && callback();
};

export const openFileStack = options => {
    if (!filestack) {
        initialize(() => {
            openFileStack(options);
        });
    } else {
        filestack.picker(options).open();
    }
};

export const closeFileStack = options => {
    if (!filestack) {
        initialize(() => {
            openFileStack(options);
        });
    } else {
        filestack.picker(options).close();
    }
};
