import * as types from '../types';

const handler = {
    [types.CHANGE_TAG_MODE](state, { payload }) {
        const { tagMode } = payload;
        return {
            ...state,
            tagMode
        };
    },
    [types.CHANGE_TAG_REGEX](state, { payload }) {
        const { tagRegex } = payload;
        return {
            ...state,
            tagRegex
        };
    },
    [types.TOGGLE_TAG](state, { payload }) {
        const { isShowTag } = state;
        return {
            ...state,
            isShowTag: !isShowTag
        };
    },
    [types.SET_LIST_TAG_MODELS](state, { payload }) {
        return {
            ...state,
            tagModels: payload
        };
    },
    [types.SET_PREDEFINED_TOKENS](state, { payload }) {
        return {
            ...state,
            predefinedTokens: payload
        };
    }
};

export default handler;
