import React from 'react';

function EmptySVG({ color = '#93929E', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M0 0H18V18H0V0Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4286 2.57143H2.57143V15.4286H15.4286V2.57143ZM0 0V18H18V0H0Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(EmptySVG);
