import React from 'react';

function IconImageSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.4444 1.5H2.55556C1.69645 1.5 1 2.14669 1 2.94443V13.0555C1 13.8532 1.69645 14.4999 2.55556 14.4999H13.4444C14.3036 14.4999 15 13.8532 15 13.0555V2.94443C15 2.14669 14.3036 1.5 13.4444 1.5Z"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.27799 6.55434C5.92233 6.55434 6.44466 6.06932 6.44466 5.47102C6.44466 4.87272 5.92233 4.3877 5.27799 4.3877C4.63366 4.3877 4.11133 4.87272 4.11133 5.47102C4.11133 6.06932 4.63366 6.55434 5.27799 6.55434Z"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.9991 10.1667L11.1102 6.55566L2.55469 14.5"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default React.memo(IconImageSVG);
