import request from './request';
import { APP_API_PREFIX_ORGANIZATION } from '../config';

const roleApis = {
    privileges: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/privileges`,
    graphQl: `${APP_API_PREFIX_ORGANIZATION}/graphql`
};

export async function getPrivilegesApi() {
    const response = await request.get(roleApis.privileges());
    return response.data;
}

export async function getWorkspacePrivilegesApi() {
    const query = `query{
        wsPrivileges: privileges(level: WORKSPACE) {
          description
          extra
          id
          label
          level
          name
          order
          serviceId
        }
        companyPrivileges: privileges(level: COMPANY){
          description
          extra
          id
          label
          level
          name
          order
          serviceId
        }
      }`;
    const data = {
        query,
        variables: {},
        operationName: null
    };
    const response = await request.post(roleApis.graphQl, data);
    const wsPrivileges = response?.data?.data?.wsPrivileges;
    const companyPrivileges = response?.data?.data?.companyPrivileges;
    return [...companyPrivileges, ...wsPrivileges];
}
