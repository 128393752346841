import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SettingContentLayout from 'permission/common/Layout';
import { Grid, Typography } from '@material-ui/core';
import Search from 'components/search';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import ButtonBase from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useDispatch } from 'react-redux';
import { fetchTagModels, importTokenSets } from 'gridUI/actions';
import { useListTagModels } from 'hooks/gridUI';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import Spinner from 'components/spinner/Base';
import TagAdd from './TagAdd';
import TableVirtualize from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import TagItemAction from './TagItemAction';
import * as workspaceActions from 'workspaces/actions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    containerTop: {
        marginTop: 48,
        padding: '0 32px 30px'
    },
    searchBox: {
        background: theme.colors.paleGrey,
        '& input': {
            '&::placeholder': {
                fontSize: '14px !important',
                color: `${theme.colors.lightGreyBlue} !important`,
                opacity: 1
            }
        }
    },
    content: {
        flex: 1,
        position: 'relative',
        '& .table-row': {
            cursor: 'pointer',
            position: 'relative',
            '& .table-row-inner': {
                '&:hover': {
                    background: 'rgba(0, 0, 0, 0.04)'
                }
            }
        }
    },
    h100: {
        height: '100%'
    },
    relative: {
        position: 'relative'
    },
    empty: {
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    top: {
        padding: '24px 33px 0 16px'
    },
    settingDescription: {
        marginTop: 8,
        '& p': {
            color: theme.colors.steel
        }
    },
    tableClassName: {
        '& .ReactVirtualized__Table__headerRow': {
            background: 'none'
        }
    },
    rowItem: {
        minWidth: 140
    },
    loadingCell: {
        display: 'flex',
        width: 25,
        height: 25,
        boxShadow: '0px 2px 10px rgb(0 0 0 / 15%)',
        borderRadius: '50%',
        position: 'relative'
    },
    rowCellName: {
        marginRight: 10,
        maxWidth: 'calc(100% - 25px - 10px)',
        ...theme.ellipsis(2)
    },
    cellName: {
        overflow: 'unset !important',
        textOverflow: 'unset',
        whiteSpace: 'unset'
    }
}));

const TermBase = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [open, setOpen] = useState(false);
    const [sorting, setSorting] = useState({});
    const [selectedTB, setSelectedTB] = useState(null);
    const [loadingRows, setLoadingRows] = useState([]);
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();
    const timerRef = useRef();
    const listTags = useListTagModels();
    const langOptions = useLanguageOptionsWithUsageValidate();
    const history = useHistory();

    useEffect(() => {
        setIsFetching(true);
        dispatch(
            fetchTagModels({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
        dispatch(workspaceActions.fetchWorkspaces({}));
    }, [dispatch]);

    const handleSearchChange = useCallback(e => {
        const value = e?.target?.value;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setSearchValue(value);
        }, DEBOUNCE_TIME_SEARCHING);
    }, []);

    const handleClearSearch = React.useCallback(() => {
        setSearchValue('');
    }, []);

    const renderLoading = useMemo(() => {
        if (isFetching && !listTags.length) {
            return (
                <Grid container className={classes.empty} justify="center" alignItems="center" direction="column">
                    <Spinner size={30} thick={4} />
                </Grid>
            );
        }
        return null;
    }, [isFetching, listTags.length, classes]);

    const _onOpenDialog = useCallback(() => {
        setOpen(true);
    }, []);

    const _onCloseDialog = useCallback(() => {
        setOpen(false);
        setSelectedTB(null);
    }, []);

    const data = React.useMemo(() => {
        const cloneData = [...listTags].filter(
            tag => !searchValue?.trim() || tag.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    valueA = valueA ? valueA?.toLowerCase() : '';
                    valueB = valueB ? valueB?.toLowerCase() : '';
                }

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, listTags, searchValue]);

    const renderEmpty = useMemo(() => {
        if (!isFetching && !data.length) {
            return (
                <Grid
                    container
                    className={classes.empty}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <EmptyBoxSVG width="252" height="196" />
                    </Grid>
                    <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">{t('add_a_new_tag_model_now_to_begin')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }, [isFetching, data, classes, t]);

    const handleRowClick = useCallback(
        idx => {
            if (data?.[idx]?.id) {
                history.push(`${window.location.pathname}/${data[idx].id}`, { fromTag: true });
            }
        },
        [history, data]
    );

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const handleSelectTB = useCallback(
        tb => {
            setSelectedTB(tb);
            _onOpenDialog();
        },
        [_onOpenDialog]
    );

    const handleImportTokenSets = useCallback(
        (tokenSetId, files) => {
            setLoadingRows(prev => [...prev, tokenSetId]);
            dispatch(
                importTokenSets({
                    tokenSetId,
                    file: files[0],
                    successCallback: () => {
                        setLoadingRows(prev => prev.filter(el => el !== tokenSetId));
                    },
                    errorCallback: () => {
                        setLoadingRows(prev => prev.filter(el => el !== tokenSetId));
                    }
                })
            );
        },
        [dispatch]
    );

    const COLUMNS = useMemo(() => {
        return [
            {
                dataKey: 'id',
                width: 40,
                cellRenderer: () => ''
            },
            {
                label: t('global_name'),
                dataKey: 'name',
                sort: true,
                className: classes.cellName,
                cellRenderer: ({ rowData }) => (
                    <Grid container alignItems="center">
                        <Grid item className={classes.rowCellName}>
                            <Typography variant="body2">{rowData.name}</Typography>
                        </Grid>
                    </Grid>
                ),
                flexGrow: 1
            },
            {
                label: t('number_of_tags'),
                dataKey: 'tokenCount',
                sort: true,
                className: classes.cellName,
                cellRenderer: ({ rowData }) => (
                    <Grid container alignItems="center">
                        <Grid item className={classes.rowCellName}>
                            <Typography variant="body2">{rowData.tokenCount}</Typography>
                        </Grid>
                    </Grid>
                ),
                flexGrow: 1
            },
            {
                dataKey: 'action',
                cellRenderer: ({ rowData }) => (
                    <TagItemAction
                        item={rowData}
                        handleSelectTB={handleSelectTB}
                        handleImportTokenSets={handleImportTokenSets}
                    />
                ),
                width: 40
            }
        ];
    }, [handleSelectTB, handleImportTokenSets, classes, t]);

    return (
        <SettingContentLayout name={t('custom_tags')}>
            <Grid container direction="column" className={classes.relative}>
                {renderLoading}
                <Grid item>
                    <Grid className={classes.top} container justify="space-between" alignItems="center">
                        <Grid item className={classes.settingDescription}>
                            <Grid item>
                                <Typography variant="body2">{t('predefine_a_custom_tag_list')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase width={200} variant="contained" onClick={_onOpenDialog}>
                                <AddIconSVG
                                    color={theme.colors.white}
                                    style={{ marginRight: 8, width: 10, height: 10 }}
                                />
                                {t('create_a_custom_tag_list')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end" className={classes.containerTop}>
                        <Grid item>
                            <Search
                                className={classes.searchBox}
                                background={theme.colors.paleGrey}
                                onChange={handleSearchChange}
                                defaultValue={searchValue}
                                placeholder={`${t('global_search')}`}
                                width={240}
                                onClear={handleClearSearch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.content}>
                    {renderEmpty}
                    <Grid
                        container
                        style={{
                            height: 'calc(80vh - 186px)',
                            width: '100%',
                            paddingLeft: theme.spacing(5),
                            paddingRight: theme.spacing(5)
                        }}
                        direction="row"
                    >
                        {data?.length > 0 && (
                            <AutoSizer>
                                {({ width, height }) => (
                                    <TableVirtualize
                                        className={classes.tableClassName}
                                        data={data}
                                        columns={COLUMNS}
                                        width={width}
                                        height={height}
                                        onRowClick={handleRowClick}
                                        onSort={handleOnSort}
                                        loadingRows={loadingRows}
                                    ></TableVirtualize>
                                )}
                            </AutoSizer>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {open && (
                <TagAdd
                    open={open}
                    onClose={_onCloseDialog}
                    setIsFetching={setIsFetching}
                    langOptions={langOptions}
                    initData={selectedTB}
                />
            )}
        </SettingContentLayout>
    );
};

export default React.memo(TermBase);
