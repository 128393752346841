import * as types from './types';
import { getAddonsApi, createAddonApi, updateAddonApi } from 'services/workflow';
import { enqueueSnackbar } from 'notifier/actions';
import { publishAllColumnsToProviderApi, publishSelectedColumnsToProviderApi } from 'services/grid';
import i18n from 'i18n';

export function getAddonsList({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_getAddonList());
        try {
            const addons = await getAddonsApi();
            dispatch(_getAddonListSuccess({ addons }));
            successCallback && successCallback();
        } catch (error) {
            const { message, originalMessage } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            dispatch(_getAddonListFailed({ error: originalMessage }));
            errorCallback && errorCallback();
        }
    };
}

function _getAddonList() {
    return {
        type: types.FETCH_ADD_ON_LIST
    };
}

function _getAddonListSuccess({ addons }) {
    return {
        type: types.FETCH_ADD_ON_LIST_SUCCESS,
        payload: {
            addons
        }
    };
}

function _getAddonListFailed({ error }) {
    return {
        type: types.FETCH_ADD_ON_LIST_FAILED,
        payload: {
            error
        }
    };
}

export function enableAddon({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const addon = await createAddonApi({ body });
            dispatch(getAddonsList({}));
            successCallback && successCallback(addon);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function changeAddonState({ addonId, newState, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            await updateAddonApi({
                addonId,
                body: {
                    state: newState
                }
            });
            dispatch(getAddonsList({}));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _updateAddonState({ addonState, addonId }) {
    return {
        type: types.CHANGE_ADD_ON_STATE,
        payload: {
            addonState,
            addonId
        }
    };
}

export function changeAddon({ addonId, newBody, oldBody, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            await updateAddonApi({
                addonId,
                body: newBody
            });
            dispatch(getAddonsList({}));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function publishAllColumnsToProvider({ dbId, viewId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await publishAllColumnsToProviderApi({ dbId, viewId });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('global_published'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function publishSelectedColumnsToProvider({ viewId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await publishSelectedColumnsToProviderApi({ viewId, body });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('global_published'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}
