import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as types from 'socket/types';
import recordHandler from './record';
import { isForAll, isForYou, isForOthers } from 'socket/utils/room';

const { getState } = store;

const gridDetailHandler = message => {
    const state = getState();
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];

    const { subObjectType, objectType, subAction } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ state, headers })) {
        if (objectType === types.GRID && subObjectType === types.RECORD) {
            if ([types.POPULATED_DATA, types.UPDATE, types.AUTO_QA].includes(subAction)) {
                return recordHandler({ body });
            }
        }
    }

    if (isForYou({ state, headers })) {
        if (objectType === types.GRID && subObjectType === types.RECORD) {
            if (
                [
                    types.CLEAR_RECORDS_START,
                    types.CLEAR_RECORDS_END,
                    types.UNDO_CREATE,
                    types.REDO_CREATE,
                    types.UNDO_UPDATE,
                    types.REDO_UPDATE,
                    types.UNDO_DELETE,
                    types.REDO_DELETE,
                    types.UNDO_REORDER,
                    types.REDO_REORDER
                ].includes(subAction)
            ) {
                return recordHandler({ body, isYour: true });
            }
        }
    } else if (isForOthers({ state, headers })) {
        switch (subObjectType) {
            case types.RECORD:
                return recordHandler({ body });

            default:
                break;
        }
    }
};

export default gridDetailHandler;
