import React, { useCallback, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Collapse, Fade, Grid, IconButton, useTheme } from '@material-ui/core';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ButtonBase from 'components/button/Base';
import { ROW_HEIGHT, WEB_HOOK_TRIGGER_ACTIONS } from 'const/gridUI';
import PopperMenu from 'components/menus/Popper';
import AutomationItemPopup from './AutomationItemPopup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { executeAutomation, removeAutomationLogo } from './action';
import { enqueueSnackbar } from 'notifier/actions';
import AutomationSwitch from './AutomationSwitch';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import LogoSVG from 'assets/images/svg/webHook/LogoSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import FolderUploadSVG from 'assets/images/svg/FolderUploadSVG';
import Tooltip from 'components/tooltip/Base';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import InputFile from 'components/inputs/InputFile';
import { toBase64 } from 'utils/fileUtils';
import TextareaAutosize from 'react-autosize-textarea';
import CircularProgress from 'components/CircularProgress';
import OverflowTypography from 'components/typography/OverflowTypography';
import { uploadAutomationLogoApi } from 'services/automation';
import { getAutomationLogoUrl } from 'utils/images';
import Spinner from 'components/spinner/Base';
import { useIsAutomationExecuting } from 'hooks/gridUI/automation';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '8px 16px 10px 10px',
        cursor: props => (props.isAutomationExecuting ? 'auto' : 'pointer'),
        '&:hover': props =>
            props.isAutomationExecuting
                ? {}
                : {
                      background: theme.colors.ghostwhite
                  }
    },
    flx: { flex: 1 },
    mt8: { marginTop: 8 },
    mt12: { marginTop: 12 },
    ml8: {
        marginLeft: 8
    },
    ml52: { marginLeft: 52 },
    borderTop: {
        paddingTop: 7,
        borderTop: `1px solid ${theme.colors.paleGrey}`
    },
    iconMore: {
        paddingRight: 16
    },
    popperRoot: {
        '& .popper-content': {
            borderRadius: 4
        }
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    switchWrapper: {
        marginRight: 12
    },
    empty: {
        width: 56,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.background,
        borderRadius: 12,
        '& p': {
            color: theme.colors.steel
        }
    },
    img: {
        position: 'relative',
        width: 40,
        height: 40,
        borderRadius: '100%',
        marginRight: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: 40,
            height: 40,
            borderRadius: '100%',
            objectFit: 'contain'
        },
        '&:hover': {
            '& .backdrop': {
                opacity: 1,
                transition: 'all 0.3s ease-out'
            },
            '& .editIcon': {
                opacity: 1,
                transition: 'all 0.3s ease-out'
            }
        }
    },
    backdrop: {
        position: 'absolute',
        width: `100%`,
        height: `100%`,
        borderRadius: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        opacity: 0,
        transition: 'all 0.3s ease-in'
    },
    editIcon: {
        position: 'absolute',
        width: `100%`,
        height: `100%`,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: 'all 0.3s ease-in'
    },
    popperImgWrapper: {
        width: 260,
        padding: `12px 0`
    },
    popperImgItem: {
        width: `100%`,
        padding: `0 18px`,
        height: 36,
        cursor: 'pointer',
        '& svg': {
            verticalAlign: 'text-top'
        },
        '&:hover': {
            background: theme.colors.ghostwhite
        },
        '&:not(:first-child)': {
            marginTop: 12
        }
    },
    paperClassName: {
        padding: 0
    },
    inputFile: {
        padding: 0,
        justifyContent: 'baseline',
        textAlign: 'start'
    },
    editArea: {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.colors.lightGreyBlue}`,
        borderRadius: 4,
        padding: `10px 14px`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        resize: 'none !important',
        minHeight: ROW_HEIGHT,
        maxHeight: 80,
        lineHeight: `16px`,
        outline: 'none'
    },
    editLength: {
        fontSize: 12,
        color: theme.colors.steel
    },
    invalidLength: {
        color: theme.colors.brightRed
    },
    description: {
        color: theme.colors.steel
    },
    executionRunning: {
        position: 'absolute',
        top: 0,
        width: `100%`,
        height: `100%`,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1,
        '& p': {
            marginTop: 20
        }
    }
}));

const AUTOMATION_LOGO_FILE_MAX_SIZE = 500 * 1000; //500Kb
const AUTOMATION_UPLOAD_SUPPORTED_EXTENSIONS = ['.png', '.svg', '.jpg', '.jpeg', '.PNG', '.SVG', '.JPG', '.JPEG'];
const DESCRIPTION_VALID_LENGTH = 160;

const AutomationItem = ({
    isFirst,
    automation = {},
    onClick,
    onChangeTitle,
    onChangeStatus,
    onChangeDescription,
    onChangeImage,
    successCloneCallback,
    successDeleteCallback
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { id, title, triggers, apps } = automation;
    const isAutomationExecuting = useIsAutomationExecuting(id);
    const classes = useStyles({ isAutomationExecuting });
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExecuting, setIsExecuting] = useState(false);
    const [isOpenConfirmTrigger, setIsOpenConfirmTrigger] = useState(false);
    const [openImg, setOpenImg] = useState(false);
    const [openUploadWarning, setOpenUploadWarning] = useState(false);
    const [openRemovePhoto, setOpenRemovePhoto] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [img, setImg] = useState(getAutomationLogoUrl(automation.iconFileId) || '');
    const [description, setDescription] = useState(automation?.description || '');
    const [isEditing, setIsEditing] = useState(false);
    const anchorImgEl = useRef();
    const textareaRef = useRef();

    useEffect(() => {
        setDescription(automation?.description || '');
        setImg(getAutomationLogoUrl(automation.iconFileId) || '');
    }, [automation]);

    useEffect(() => {
        if (openEdit) {
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
    }, [openEdit]);

    const stopPropagation = e => {
        if (e) {
            e.stopPropagation();
        }
    };

    const stopPropagationPrevent = useCallback(e => {
        if (e) {
            stopPropagation(e);
            e.preventDefault();
        }
    }, []);

    const handleClickMoreAction = useCallback(
        e => {
            stopPropagationPrevent(e);
            setAnchorEl(anchorEl => (!anchorEl ? e.target : null));
        },
        [stopPropagationPrevent]
    );

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleClick = useCallback(() => {
        onClick(automation);
    }, [onClick, automation]);

    const handleExecute = useCallback(
        e => {
            stopPropagationPrevent(e);
            if (isExecuting) return;
            setIsExecuting(true);
            dispatch(
                executeAutomation({
                    automationId: id,
                    successCallback: () => {
                        setIsExecuting(false);
                        setIsOpenConfirmTrigger(false);
                        dispatch(
                            enqueueSnackbar({
                                message: t('global_succeeded'),
                                type: 'info'
                            })
                        );
                    },
                    errorCallback: () => {
                        setIsExecuting(false);
                    }
                })
            );
        },
        [dispatch, id, isExecuting, t, stopPropagationPrevent]
    );

    const openTriggerConfirm = useCallback(
        e => {
            stopPropagationPrevent(e);
            setIsOpenConfirmTrigger(true);
        },
        [stopPropagationPrevent]
    );

    const closeTriggerConfirm = useCallback(
        e => {
            stopPropagationPrevent(e);
            if (isExecuting) return;
            setIsOpenConfirmTrigger(false);
        },
        [isExecuting, stopPropagationPrevent]
    );

    const handleClickImg = useCallback(
        e => {
            stopPropagationPrevent(e);
            setOpenImg(true);
        },
        [stopPropagationPrevent]
    );

    const handleClickAwayImg = useCallback(e => {
        setOpenImg(false);
    }, []);

    const handleFileImportInput = React.useCallback(
        async files => {
            handleClickAwayImg();
            const base64 = await toBase64(files[0]);
            setImg(base64);
            const formData = new FormData();
            formData.append('file', files[0]);
            formData.append('automationId', automation.id);
            try {
                const responseData = await uploadAutomationLogoApi({ formData });
                onChangeImage(responseData);
                setImg(getAutomationLogoUrl(responseData.iconFileId));
            } catch (error) {
                const { message } = error;
                dispatch(
                    enqueueSnackbar({
                        type: 'info',
                        message
                    })
                );
                setImg(getAutomationLogoUrl(automation.iconFileId));
            }
        },
        [automation, dispatch, handleClickAwayImg, onChangeImage]
    );

    const handleFileImportRejected = React.useCallback(
        errorCode => {
            setOpenUploadWarning(true);
            handleClickAwayImg();
        },
        [handleClickAwayImg]
    );

    const handleCloseOpenUploadWarning = useCallback(
        e => {
            stopPropagationPrevent(e);
            setOpenUploadWarning(false);
        },
        [stopPropagationPrevent]
    );

    const handleOpenRemovePhoto = useCallback(
        e => {
            stopPropagationPrevent(e);
            handleClickAwayImg();
            setOpenRemovePhoto(true);
        },
        [stopPropagationPrevent, handleClickAwayImg]
    );

    const handleCloseRemovePhoto = useCallback(
        e => {
            stopPropagationPrevent(e);
            setOpenRemovePhoto(false);
        },
        [stopPropagationPrevent]
    );

    const onClickEditDescription = useCallback(() => {
        setOpenEdit(true);
        setAnchorEl(null);
    }, []);

    const onCloseEditDescription = useCallback(
        e => {
            stopPropagationPrevent(e);
            if (isEditing) return false;
            setOpenEdit(false);
            setDescription(automation?.description || '');
        },
        [automation, isEditing, stopPropagationPrevent]
    );

    const onSubmitEditDescription = useCallback(
        e => {
            stopPropagationPrevent(e);
            setIsEditing(true);
            onChangeDescription({
                automationId: automation.id,
                description,
                onSuccess: () => {
                    setIsEditing(false);
                    setOpenEdit(false);
                },
                onError: () => {
                    setIsEditing(false);
                }
            });
        },
        [automation.id, description, onChangeDescription, stopPropagationPrevent]
    );

    const handleDescriptionChange = useCallback(e => {
        const value = e.target.value;
        setDescription(value);
    }, []);

    const onRemovePhoto = useCallback(() => {
        setImg('');
        handleClickAwayImg();
        handleCloseRemovePhoto();
        dispatch(
            removeAutomationLogo({
                automationId: automation.id,
                successCallback: responseData => {
                    onChangeImage(responseData);
                },
                errorCallback: () => {
                    setImg(getAutomationLogoUrl(automation.iconFileId));
                }
            })
        );
    }, [automation.iconFileId, automation.id, dispatch, onChangeImage, handleClickAwayImg, handleCloseRemovePhoto]);

    return (
        <Grid
            item
            className={classnames(classes.root, {
                [classes.borderTop]: !isFirst
            })}
        >
            <Fade in={isAutomationExecuting}>
                <Grid
                    item
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.executionRunning}
                >
                    <Grid item>
                        <Spinner size={20} thick={2} rotateColor="#96C060" bgColor="#ECECEC" bgOpacity={1} />
                    </Grid>
                    <Grid item>
                        <p className="body2">{t('automation_running')}</p>
                    </Grid>
                </Grid>
            </Fade>
            <Grid item container wrap="nowrap" alignItems="center" onClick={handleClick}>
                <Grid item>
                    <Grid ref={anchorImgEl} item className={classes.img} onClick={handleClickImg}>
                        {img ? <img src={img} alt="logo" /> : <LogoSVG width={40} height={40} />}
                        <Grid item className={classnames(classes.backdrop, 'backdrop')} />
                        <Grid item className={classnames(classes.editIcon, 'editIcon')}>
                            <EditSVG color={theme.colors.white} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.flx}>
                    <Grid item container direction="column" justifyContent="space-between" wrap="nowrap">
                        <Grid item container alignItems="center" justifyContent="space-between" wrap="nowrap">
                            <Grid item container alignItems="center" wrap="nowrap">
                                <Grid item className={classes.switchWrapper} onClick={stopPropagation}>
                                    <AutomationSwitch automation={automation} onChange={onChangeStatus} />
                                </Grid>
                                <Grid item>
                                    <p className="body1">{title}</p>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.iconMore}>
                                <IconButton onClick={handleClickMoreAction}>
                                    <IconMoreActionsSVG />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {!triggers?.length && (
                            <Grid item className={classes.empty}>
                                <p className="body2">{t('global_empty')}</p>
                            </Grid>
                        )}
                        {triggers?.length > 0 && (
                            <Grid
                                item
                                container
                                alignItems={
                                    triggers.length > 6 ||
                                    (triggers.length > 5 && apps?.length > 0) ||
                                    triggers.length + (apps?.length || 0) > 6
                                        ? 'flex-start'
                                        : 'center'
                                }
                                justifyContent="space-between"
                                wrap="nowrap"
                            >
                                <Grid item container alignItems="center" spacing={1}>
                                    {triggers?.map((trigger, idx) => (
                                        <Grid item key={idx}>
                                            <WebHookLabel type={trigger} />
                                        </Grid>
                                    ))}
                                    {apps?.length > 0 && (
                                        <>
                                            <Grid item style={{ display: 'flex' }}>
                                                <ArrowRightSVG />
                                            </Grid>
                                            {apps?.map((app, idx) => (
                                                <Grid item key={idx}>
                                                    <WebHookLabel type={app} />
                                                </Grid>
                                            ))}
                                        </>
                                    )}
                                </Grid>
                                {triggers?.includes(WEB_HOOK_TRIGGER_ACTIONS.MANUAL) && (
                                    <Grid item className={classnames(classes.ml8, classes.buttonWrapper)}>
                                        <ButtonBase
                                            className={classes.triggerButton}
                                            width={120}
                                            onClick={openTriggerConfirm}
                                            variant="outlined"
                                        >
                                            {t('global_execute')}
                                        </ButtonBase>
                                        <Dialog
                                            open={isOpenConfirmTrigger}
                                            onClose={closeTriggerConfirm}
                                            onClick={stopPropagationPrevent}
                                        >
                                            <ConfirmBox
                                                title="Run trigger"
                                                body={
                                                    <>
                                                        <p className="body2 inline">
                                                            Are you sure you want to run the trigger{' '}
                                                        </p>
                                                        <p className="body1 inline">{title}</p>
                                                        <p className="body2 inline">?</p>
                                                    </>
                                                }
                                                handleCancel={closeTriggerConfirm}
                                                onClose={closeTriggerConfirm}
                                                handleAgreed={handleExecute}
                                                agreeLabel="Run"
                                                isLoading={isExecuting}
                                            />
                                        </Dialog>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {!openEdit && automation?.description && (
                            <Grid item className={classes.mt12}>
                                <OverflowTypography className={classes.description} variant="body2" maxLines={3}>
                                    {automation?.description}
                                </OverflowTypography>
                            </Grid>
                        )}
                        {anchorEl && (
                            <PopperMenu
                                className={classes.popperRoot}
                                anchorEl={anchorEl}
                                placement="left-start"
                                onPaperClick={stopPropagation}
                            >
                                <AutomationItemPopup
                                    id={id}
                                    title={title}
                                    triggers={triggers}
                                    onClickAway={handleClickAway}
                                    onChangeTitle={onChangeTitle}
                                    successCloneCallback={successCloneCallback}
                                    successDeleteCallback={successDeleteCallback}
                                    showEdit
                                    onClickEditDescription={onClickEditDescription}
                                />
                            </PopperMenu>
                        )}
                        {openImg && (
                            <PopperMenu
                                className={classes.popperRoot}
                                paperClassName={classes.paperClassName}
                                anchorEl={anchorImgEl.current}
                                handleClickAway={handleClickAwayImg}
                                onPaperClick={stopPropagation}
                            >
                                <Grid item className={classes.popperImgWrapper}>
                                    <InputFile
                                        className={classes.inputFile}
                                        onAccepted={handleFileImportInput}
                                        onRejected={handleFileImportRejected}
                                        accept={AUTOMATION_UPLOAD_SUPPORTED_EXTENSIONS}
                                        maxSize={AUTOMATION_LOGO_FILE_MAX_SIZE}
                                    >
                                        <Grid item className={classes.popperImgItem}>
                                            <Grid
                                                container
                                                wrap="nowrap"
                                                alignItems="center"
                                                spacing={2}
                                                style={{ height: '100%' }}
                                            >
                                                <Grid item>
                                                    <FolderUploadSVG />
                                                </Grid>
                                                <Grid item className={classes.flx}>
                                                    <p className="body2">Upload photo</p>
                                                </Grid>
                                                <Tooltip title="Available with format PNG, SVG, JPG, JPEG. Maximum size is 500Kb">
                                                    <Grid item>
                                                        <InfoIconSVG size={18} />
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </InputFile>

                                    {img && (
                                        <Grid item className={classes.popperImgItem} onClick={handleOpenRemovePhoto}>
                                            <Grid
                                                container
                                                wrap="nowrap"
                                                alignItems="center"
                                                spacing={2}
                                                style={{ height: '100%' }}
                                            >
                                                <Grid item>
                                                    <DeleteSVG color={theme.colors.steel} />
                                                </Grid>
                                                <Grid item className={classes.flx}>
                                                    <p className="body2">Remove photo</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </PopperMenu>
                        )}
                        <Dialog
                            open={openUploadWarning}
                            onClose={handleCloseOpenUploadWarning}
                            onClick={stopPropagation}
                        >
                            <ConfirmBox
                                title={'Has some problem with your file'}
                                body={
                                    <div className="body2">
                                        This photo is available with format{' '}
                                        <span style={{ color: theme.colors.brightRed }}>PNG, SVG, JPG, JPEG</span>.
                                        <br /> Maximum size is{' '}
                                        <span style={{ color: theme.colors.brightRed }}>500Kb</span>
                                    </div>
                                }
                                onClose={handleCloseOpenUploadWarning}
                                handleAgreed={handleCloseOpenUploadWarning}
                                agreeLabel="OK"
                            />
                        </Dialog>
                        <Dialog open={openRemovePhoto} onClose={handleCloseRemovePhoto} onClick={stopPropagation}>
                            <ConfirmBox
                                title={'Are you sure you want to delete the photo?'}
                                body={<div className="body2">The action will delete the photo on your automation</div>}
                                onClose={handleCloseRemovePhoto}
                                handleCancel={handleCloseRemovePhoto}
                                handleAgreed={onRemovePhoto}
                                agreeLabel={t('global_delete')}
                                colorType={COLOR_TYPES.SECONDARY}
                            />
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>
            <Collapse in={openEdit} onClick={stopPropagation}>
                <Grid className={classnames(classes.mt12, classes.ml52)} item>
                    <TextareaAutosize
                        ref={textareaRef}
                        className={classes.editArea}
                        rows={4}
                        onChange={handleDescriptionChange}
                        value={description || ''}
                        autoFocus
                        placeholder="Add your description here"
                    />
                </Grid>
                <Grid item container direction="column" alignItems="flex-end" wrap="nowrap" className={classes.mt8}>
                    <Grid item>
                        <p
                            className={classnames(classes.editLength, {
                                [classes.invalidLength]: description.length > DESCRIPTION_VALID_LENGTH
                            })}
                        >
                            {description?.length}/{DESCRIPTION_VALID_LENGTH} character
                        </p>
                    </Grid>
                    <Grid item className={classes.mt12}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <ButtonBase variant="outlined" width={110} onClick={onCloseEditDescription}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    variant="contained"
                                    width={110}
                                    disabled={
                                        isEditing ||
                                        (automation?.description || '') === description ||
                                        description.length > DESCRIPTION_VALID_LENGTH
                                    }
                                    onClick={onSubmitEditDescription}
                                >
                                    {t('global_submit')}
                                    {isEditing && <CircularProgress />}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>
        </Grid>
    );
};

export default React.memo(AutomationItem);
