import React from 'react';
import { getOptionColor } from 'utils/color';
import Option from 'components/option/Base';
import { makeStyles } from '@material-ui/core/styles';
import { ROW_HEIGHT } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        }
    }
}));

function SingleSelect({ value: cellValue, customProperties, options = [], isReadOnly }) {
    const classes = useStyles({ isReadOnly });

    if (!cellValue) return <div className={classes.root} />;
    if (typeof cellValue !== 'string' && typeof cellValue !== 'number') {
        return <div className={classes.root} />;
    }
    const color = getOptionColor({ options, data: cellValue, customProperties });

    return (
        <div className={classes.root}>
            <Option size="small" label={cellValue} color={color} />
        </div>
    );
}

export default React.memo(SingleSelect);
