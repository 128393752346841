export function requestData(type, data) {
    return {
        type,
        payload: data
    };
}

export function receiveData(type, data) {
    return {
        type,
        payload: data
    };
}
