import { getFullName } from 'utils/user';
import { DEFAULT_ROLE_USER } from 'const';
import { generateWsAccessId } from 'utils/permission/workspaceAccess';
import { useSelector } from 'react-redux';
import { makeGroupOption } from 'utils/permission/group';
import { useCanCustomGroup } from 'hooks/payment';
import { useCurrentUser } from 'hooks/auth';

export function useGroupList() {
    return useSelector(state => state.permission.groups.list);
}

export function useGroupIsFetchingGroups() {
    return useSelector(state => state.permission.groups?.isFetchingGroups);
}

export function useIsCreatingGroup() {
    return useSelector(state => state.permission.groups?.isCreatingGroup);
}

export function useIsShowLastGroupPopupCreate() {
    return useSelector(state => state.permission.groups?.isShowLastPopupCreate);
}

export function useSelectedGroupId() {
    return useSelector(state => state.permission.groups?.selectedGroupId);
}

export function useGroupMemberAccess() {
    return useSelector(state => state.permission.groups.groupMemberAccess);
}

export function useIsFetchingGroupWorkspaceAccess() {
    const groupMemberAccess = useGroupMemberAccess();
    const selectedGroupId = useSelectedGroupId();
    return groupMemberAccess?.[selectedGroupId]?.isFetchingGroupWorkspaceAccess;
}

export function useIsFetchingMembersInSelectedGroup() {
    const selectedGroupId = useSelectedGroupId();
    const groupMemberAccess = useGroupMemberAccess();
    return groupMemberAccess?.[selectedGroupId]?.isFetchingMembers;
}

export function useMembersInSelectedGroup() {
    const currentUser = useCurrentUser();
    const selectedGroupId = useSelectedGroupId();
    const groupMemberAccess = useGroupMemberAccess();
    const members = groupMemberAccess?.[selectedGroupId]?.members;

    const mappedMembers = members?.map(member => ({
        ...member,
        fullName: getFullName(member),
        role: member?.roles?.[0] || DEFAULT_ROLE_USER
    }));
    return currentUser?.ldManager || currentUser?.ldUser
        ? mappedMembers
        : mappedMembers?.filter(member => !member.ldUser);
}

export function useSelectedGroupDetail() {
    const groupList = useGroupList();
    const selectedGroupId = useSelectedGroupId();
    return groupList?.find(group => group?.id === selectedGroupId);
}

export function useSelectedGroupAccess() {
    const selectedGroupId = useSelectedGroupId();
    const groupMemberAccess = useGroupMemberAccess();
    return groupMemberAccess?.[selectedGroupId]?.workspacesAccess;
}

export function useSelectedGroupAccessIds() {
    const workspacesAccess = useSelectedGroupAccess();
    return (
        workspacesAccess?.accessViews?.map(dbAccess => ({
            ...dbAccess,
            id: generateWsAccessId(dbAccess)
        })) || []
    );
}

export function useGroupOptions() {
    const groupList = useGroupList();
    const canCustomGroup = useCanCustomGroup();

    return groupList?.map(group => {
        const isDisabled = !canCustomGroup && !group?.systemGroup;
        return makeGroupOption({ group, isDisabled });
    });
}

export function useSelectedGroups() {
    return useSelector(state => state.permission.groups?.selectedGroups);
}

export function useMapGroupOptionsById() {
    const groupOptions = useGroupOptions();
    return groupOptions?.reduce((acc, cur) => {
        if (cur) {
            acc[cur?.id] = cur;
        }
        return acc;
    }, {});
}

export function useMapGroupOptionsByName() {
    const groupOptions = useGroupOptions();
    return groupOptions?.reduce((acc, cur) => {
        if (cur) {
            acc[cur?.name] = cur;
        }
        return acc;
    }, {});
}
