import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from 'components/tooltip/Base';
import { RESOURCE_TYPES, USER_SETTINGS } from 'const';
import { DISABLED_OPACITY } from 'const/style';
import { useDependencyListWithoutFakeId } from 'hooks/gridUI';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutoQASVG from 'assets/images/svg/AutoQASVG';
import * as gridUIActions from 'gridUI/actions';
import hexToRgba from 'hex-to-rgba';
import { useIsShowAutoQA, useIsShowAutoQAButtons } from 'hooks/gridUI/autoQA';
import { useUserViewSettings } from 'hooks/workspace';
import * as workspaceActions from 'workspaces/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

const ManageAutoQA = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { dbId, workspaceId, viewId } = useParams();
    const dependencies = useDependencyListWithoutFakeId();
    const isShowAutoQAButtons = useIsShowAutoQAButtons();
    const isShowAutoQA = useIsShowAutoQA();
    const userViewSetting = useUserViewSettings({ wsId: workspaceId, dbId, viewId });

    const toggleAutoQA = React.useCallback(async () => {
        dispatch(
            workspaceActions.setUserSettings({
                dbId,
                workspaceId,
                resourceId: viewId,
                resourceType: RESOURCE_TYPES.VIEW,
                value: {
                    ...userViewSetting,
                    [USER_SETTINGS.VIEW.AUTO_QA]: !Boolean(isShowAutoQA)
                },
                successCallback: () => {
                    dispatch(gridUIActions._fetchRecordMetadata({ dbId, defaultAccessViewId: viewId }));
                }
            })
        );
    }, [dbId, workspaceId, dispatch, isShowAutoQA, userViewSetting, viewId]);

    if (!isShowAutoQAButtons) return null;

    return (
        <Tooltip
            title={
                dependencies.length === 0 ? (
                    <>
                        <p className="text-light">{t('no_dependencies')}</p>
                        <p className="text-light">You must setup depencies to run Auto QA by lexiqa</p>
                    </>
                ) : (
                    t('auto_qa_by_lexiqa')
                )
            }
        >
            <Grid item className={classes.menuItem}>
                <ButtonBase
                    className={`${classes.addButton} ${classes.button} ${
                        dependencies.length === 0 ? classes.disabled : ''
                    }`}
                    style={{
                        background: isShowAutoQA ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                        position: 'relative'
                    }}
                    onClick={toggleAutoQA}
                    tr-dt="Show AutoQA Errors"
                >
                    <AutoQASVG
                        color={isShowAutoQA ? theme.palette.primary.main : theme.colors.darkness}
                        height={18}
                        width={18}
                    />
                </ButtonBase>
            </Grid>
        </Tooltip>
    );
};

export default React.memo(ManageAutoQA);
