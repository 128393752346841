import React from 'react';

const POFILESVG = ({ color = '#78778B', ...rest }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_192_12050)">
                <path
                    d="M20.5 6H15.5C15.224 6 15 5.775 15 5.49998V0.500016C15 0.224016 14.776 0 14.5 0H4.5C3.67298 0 3 0.672984 3 1.5V22.5C3 23.327 3.67298 24 4.5 24H19.5C20.327 24 21 23.327 21 22.5V6.50002C21 6.22402 20.776 6 20.5 6ZM20 22.5C20 22.775 19.776 23 19.5 23H4.5C4.224 23 3.99998 22.775 3.99998 22.5V1.5C3.99998 1.22498 4.224 0.999984 4.5 0.999984H14V5.49998C14 6.327 14.673 6.99998 15.5 6.99998H20V22.5Z"
                    fill={color}
                />
                <path
                    d="M20.8533 6.14674L14.8533 0.146738C14.6583 -0.0482617 14.3413 -0.0482617 14.1463 0.146738C13.9512 0.341738 13.9512 0.658754 14.1463 0.853754L20.1463 6.85375C20.2443 6.95074 20.3723 6.99977 20.5003 6.99977C20.6283 6.99977 20.7563 6.95079 20.8533 6.85375C21.0483 6.65875 21.0483 6.34174 20.8533 6.14674Z"
                    fill={color}
                />
                <rect x="0.0859375" y="11.0076" width="24" height="10.64" rx="1" fill={color} />
                <path
                    d="M6.81216 19.554C6.56016 19.554 6.35916 19.482 6.20916 19.338C6.06516 19.188 5.99316 18.987 5.99316 18.735V13.92C5.99316 13.674 6.05916 13.485 6.19116 13.353C6.32316 13.221 6.51216 13.155 6.75816 13.155H9.06216C9.76416 13.155 10.3102 13.335 10.7002 13.695C11.0902 14.049 11.2852 14.544 11.2852 15.18C11.2852 15.81 11.0872 16.305 10.6912 16.665C10.3012 17.025 9.75816 17.205 9.06216 17.205H7.64916V18.735C7.64916 18.987 7.57416 19.188 7.42416 19.338C7.27416 19.482 7.07016 19.554 6.81216 19.554ZM8.83716 15.963C9.14316 15.963 9.36816 15.9 9.51216 15.774C9.66216 15.648 9.73716 15.453 9.73716 15.189C9.73716 14.667 9.43716 14.406 8.83716 14.406H7.64916V15.963H8.83716Z"
                    fill="white"
                />
                <path
                    d="M15.0111 19.581C14.3631 19.581 13.7991 19.449 13.3191 19.185C12.8451 18.915 12.4791 18.537 12.2211 18.051C11.9631 17.559 11.8341 16.983 11.8341 16.323C11.8341 15.663 11.9631 15.09 12.2211 14.604C12.4791 14.112 12.8451 13.734 13.3191 13.47C13.7991 13.206 14.3631 13.074 15.0111 13.074C15.6591 13.074 16.2201 13.206 16.6941 13.47C17.1741 13.734 17.5401 14.112 17.7921 14.604C18.0501 15.09 18.1791 15.663 18.1791 16.323C18.1791 16.983 18.0501 17.559 17.7921 18.051C17.5341 18.537 17.1651 18.915 16.6851 19.185C16.2111 19.449 15.6531 19.581 15.0111 19.581ZM15.0111 18.312C15.4911 18.312 15.8661 18.144 16.1361 17.808C16.4061 17.466 16.5411 16.971 16.5411 16.323C16.5411 15.675 16.4031 15.183 16.1271 14.847C15.8571 14.511 15.4851 14.343 15.0111 14.343C14.5311 14.343 14.1561 14.511 13.8861 14.847C13.6161 15.177 13.4811 15.669 13.4811 16.323C13.4811 16.977 13.6161 17.472 13.8861 17.808C14.1561 18.144 14.5311 18.312 15.0111 18.312Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_192_12050">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(POFILESVG);
