import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'advancedSearch/actions';
import ViewCellInfo from './ViewCellInfo';
import { CELL_PADDING, BORDER_HIGHLIGHT, NOT_NUMBER_REGEX } from 'const/gridUI';
import { isKbEscape, isKbEnter, isKbTab, isKbShiftTab } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { getFloatNumber } from 'utils/gridUI/cell';
import { getCellBackgroundByHex } from 'utils/color';

const useStyles = makeStyles(theme => ({
    singleText: {
        width: '100%',
        height: 32,
        padding: CELL_PADDING,
        fontSize: '0.875rem',
        border: `none`,
        outline: 'none'
    }
}));

function NumberEdit({
    columnId,
    value: cellValue,
    rowId,
    width,
    height,
    rowIndex,
    background,
    fillColorBlindness,
    columnIndex,
    originalValue
}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(cellValue);
    const classes = useStyles();
    const theme = useTheme();
    const inputRef = useRef();
    const isEnterAlready = useRef(false);
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    }, []);

    const isSameData = () => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    };

    console.log('value', value);
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler, true);
        return document.removeEventListener('keydown', keyDownHandler);
    }, [value]);

    const keyDownHandler = e => {
        inputRef.current && inputRef.current.focus();
    };

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        if (isSameData()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameData()) {
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value: value ? getFloatNumber(value) : null,
                    rowId,
                    columnId
                })
            );
        }
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            dispatch(gridActions.cancelCellEdit());
        }
        if (isKbEnter(e)) {
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoNextRow({
                    value: value ? getFloatNumber(value) : null,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }

        if (isKbTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoNextColumn({
                    value: value ? getFloatNumber(value) : null,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }

        if (isKbShiftTab(e)) {
            e.preventDefault();

            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(
                    gridActions.moveCellToPreviousColumn({
                        rowIndex,
                        columnIndex
                    })
                );
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoPreviousColumn({
                    value: value ? getFloatNumber(value) : null,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }
    };

    const handleInputChange = e => {
        const value = e.target.value.replace(NOT_NUMBER_REGEX, '');
        setValue(value?.toString());
    };

    return (
        <div ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <div
                style={{
                    width,
                    height,
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                }}
            >
                <input
                    ref={inputRef}
                    className={`${classes.singleText} cell-editor`}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    value={value?.toString() || ''}
                    style={{
                        background: background
                            ? fillColorBlindness === 'BLIND'
                                ? `${background} url(${getCellBackgroundByHex(background)})`
                                : background
                            : theme.colors.white
                    }}
                />
            </div>
        </div>
    );
}

export default React.memo(NumberEdit);
