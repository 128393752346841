import React from 'react';

function CloseFolderSVG({ color = '#5DCDE3', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M16.5165 16.533H1.48352C0.692308 16.533 0 15.8407 0 15.0495V2.98352C0 2.19231 0.692308 1.5 1.48352 1.5H5.93407C6.32967 1.5 6.72527 1.6978 7.02198 1.99451L8.7033 3.77473H16.5165C17.3077 3.77473 18 4.46703 18 5.25824V15.0495C18 15.8407 17.3077 16.533 16.5165 16.533Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CloseFolderSVG);
