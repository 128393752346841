import React from 'react';

function CheckedBoolean({ ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#42B883" stroke="#219F66" />
            <path
                d="M8.70766 12.6351C8.23614 13.1216 7.47107 13.1216 6.99978 12.6351L4.35364 9.90491C3.88212 9.41864 3.88212 8.62926 4.35364 8.14299C4.82493 7.65649 5.59 7.65649 6.06152 8.14299L7.6381 9.76943C7.75712 9.89199 7.95032 9.89199 8.06957 9.76943L12.3385 5.36487C12.8098 4.87838 13.5748 4.87838 14.0464 5.36487C14.2728 5.5985 14.4 5.91547 14.4 6.24583C14.4 6.57619 14.2728 6.89317 14.0464 7.12679L8.70766 12.6351Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(CheckedBoolean);
