import React, { useCallback, useMemo, useState } from 'react';
import Tabs from 'components/tabs/Tabs';
import Tab from 'components/tabs/Tab';
import { Grid } from '@material-ui/core';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContentCustom from 'components/dialog/DialogContent';
import SynchronizeTMPushSVG from 'assets/images/svg/SynchronizeTMPushSVG';
import SynchronizeTMPullSVG from 'assets/images/svg/SynchronizeTMPullSVG';
import SynchronizeTMContent from './SynchronizeTMContent';

const SynchronizeTM = ({ onClose }) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState('push');

    const tabs = useMemo(() => {
        return [
            {
                label: (
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <SynchronizeTMPushSVG color={tab !== 'pull' ? '#7869B9' : '#78778B'} />
                        </Grid>
                        <Grid item>
                            <span>{t('update_current_tm')}</span>
                        </Grid>
                    </Grid>
                ),
                value: 'push'
            },
            {
                label: (
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <SynchronizeTMPullSVG color={tab === 'pull' ? '#7869B9' : '#78778B'} />
                        </Grid>
                        <Grid item>{t('fetch_from_current_tm')}</Grid>
                    </Grid>
                ),
                value: 'pull'
            }
        ];
    }, [tab, t]);

    const renderTab = useCallback(() => {
        return tabs.map(tab => <Tab key={tab.value} {...tab} />);
    }, [tabs]);

    const handleChangeTab = useCallback((e, value) => {
        setTab(value);
    }, []);

    return (
        <>
            <DialogTitle title={t(`synchronize_tm`)} onClose={onClose} />

            <DialogContentCustom className="overflow-hidden">
                <Tabs value={tab} onChange={handleChangeTab}>
                    {renderTab()}
                </Tabs>
            </DialogContentCustom>

            <SynchronizeTMContent tab={tab} onClose={onClose} />
        </>
    );
};

export default React.memo(SynchronizeTM);
