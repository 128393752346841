import * as types from '../types';

export function openCellFilePreview({ rowId, columnId, defaultPreviewFile, type }) {
    return {
        type: types.OPEN_CELL_FILE_PREVIEW,
        payload: {
            rowId,
            columnId,
            defaultPreviewFile,
            type
        }
    };
}

export function closeCellFilePreview() {
    return {
        type: types.CLOSE_CELL_FILE_PREVIEW
    };
}
