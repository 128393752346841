import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import { logOut } from './actions';
import Button from 'components/button/Base';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    body: {
        marginTop: 12
    },
    btnSubmit: {
        width: 180,
        position: 'relative'
    }
}));

function AccountSwitchingConfirm({ currentAccountEmail, newAccountEmail, handleConfirmed }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onBtnClick = () => {
        dispatch(logOut({}));

        handleConfirmed && handleConfirmed();
    };

    return (
        <AuthenticationWrapper>
            <AuthFormWrapper
                title={t(`auth_you_already_logged_in`)}
                subTitle={
                    <Grid container direction="column" spacing={2} wrap="nowrap">
                        <Grid item>
                            <Trans
                                t={t}
                                i18nKey={'auth_account_switch_confirm_title'}
                                default={
                                    '<subtitle>Do you want to log out and continue with</subtitle> <h4>{{currentAccount}}</h4>'
                                }
                                components={{
                                    subtitle: <Typography variant="subtitle1" display="inline" />,
                                    h4: <Typography variant="h4" display="inline" />
                                }}
                                values={{
                                    currentAccount: currentAccountEmail || 'unknown'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Trans
                                t={t}
                                i18nKey={'auth_account_switch_confirm_subtitle'}
                                default={
                                    '<subtitle>You are currently logged in as</subtitle> <h4>{{newAccountEmail}}</h4>'
                                }
                                components={{
                                    subtitle: <Typography variant="subtitle1" display="inline" />,
                                    h4: <Typography variant="h4" display="inline" />
                                }}
                                values={{
                                    newAccountEmail: newAccountEmail || 'unknown'
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            >
                <div className={classes.btnSubmit}>
                    <Button fullWidth type="submit" variant="contained" onClick={onBtnClick}>
                        {t(`auth_log_out_and_continue`)}
                    </Button>
                </div>
            </AuthFormWrapper>
        </AuthenticationWrapper>
    );
}

export default React.memo(AccountSwitchingConfirm);
