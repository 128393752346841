import React, { useMemo } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { useFocused, useSelected } from 'slate-react';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import { Grid, IconButton } from '@material-ui/core';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

const useStyles = ({ selected, focused }) =>
    makeStyles(theme => ({
        root: {
            height: 32,
            position: 'relative',
            top: 2,
            padding: '1.5px 4px',
            margin: 1,
            alignItems: 'center',
            display: 'inline-flex',
            fontSize: '14px',
            fontWeight: 400,
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
            background: `rgba(110, 91, 190, 0.1)`,
            border: `1px solid rgba(110, 91, 190, 0.3)`,
            borderRadius: 3
        },
        rootNoIcon: {
            top: -2
        },
        invalid: {
            color: theme.colors.brightRed,
            borderColor: theme.colors.brightRed
        },
        deleteIcon: {
            padding: 4,
            '& .MuiIconButton-label': {
                marginBottom: `0 !important`
            }
        }
    }));

const Payload = ({ attributes, children, element, triggerNodesAndNodesObj, selectedNodeOrder, onRemovePayload }) => {
    const { t } = useTranslation();
    const selected = useSelected();
    const focused = useFocused();
    const classes = useStyles({ selected, focused })();

    const selectedElementNode = useMemo(() => {
        const str = element?.slug?.replace(/[${}]/g, '');
        const arr = str?.split('.');
        const prefix = arr[0];
        return triggerNodesAndNodesObj[prefix];
    }, [element.slug, triggerNodesAndNodesObj]);

    const name = useMemo(() => {
        const str = element?.slug?.replace(/[${}]/g, '');
        const arr = str?.split('.');
        const suffix = arr.slice(1);
        return (selectedElementNode ? `${selectedElementNode.order}. ${suffix.join('.')}` : str).replace(
            'rawData',
            'TriggerData'
        );
    }, [element.slug, selectedElementNode]);

    const isValid = useMemo(() => {
        return (!element?.slug?.includes('${trigger') && !element?.slug?.includes('${node')) || !!selectedElementNode;
    }, [element.slug, selectedElementNode]);

    const invalidOrder = useMemo(() => {
        if (selectedElementNode?.order > selectedNodeOrder) {
            return true;
        }
        return false;
    }, [selectedElementNode, selectedNodeOrder]);

    // const missingColumn = useMemo(() => {
    //     if (element?.slug?.includes('rawData')) {
    //         const str = element?.slug?.replace(/[${}]/g, '');
    //         const arr = str?.split('.');
    //         const suffix = arr.slice(2);
    //         if (suffix[0] && !columnPublicIds?.includes(suffix[0])) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }, [element.slug, columnPublicIds]);

    return (
        <Grid
            item
            {...attributes}
            contentEditable={false}
            className={classnames(classes.root, {
                [classes.rootNoIcon]: !selectedElementNode,
                [classes.invalid]: !isValid || invalidOrder
            })}
        >
            {children}
            <Tooltip title={invalidOrder ? t('automation_condition_field_error') : ''}>
                <Grid container spacing={2} alignItems="center">
                    {selectedElementNode && (
                        <Grid item>
                            <WebHookLabel
                                type={selectedElementNode.trigger || selectedElementNode.app}
                                size="smaller"
                            />
                        </Grid>
                    )}
                    <Grid item>{!isValid ? `Invalid data` : name}</Grid>
                    <Grid item>
                        <IconButton className={classes.deleteIcon} onClick={onRemovePayload}>
                            <CloseIconSVG />
                        </IconButton>
                    </Grid>
                </Grid>
            </Tooltip>
        </Grid>
    );
};

export default React.memo(Payload);
