import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, FormControl, Typography, InputAdornment, Grid } from '@material-ui/core';
import { INPUT_SPACING, INPUT_PADDING_LEFT, INPUT_HEIGHT } from 'const/style';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    inputForm: {
        width: '100%',
        minHeight: INPUT_HEIGHT,
        position: 'relative'
    },
    inputBase: {
        border: props =>
            props.error ? `1px solid ${theme.colors.burntSlena} !important` : `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        width: '100%',
        height: props => props.height || INPUT_HEIGHT,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        padding: `6px ${INPUT_PADDING_LEFT}px`,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        },
        '&.Mui-disabled': {
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        },
        '& > textarea': {
            height: '100%',
            paddingTop: 4,
            lineHeight: `22px`
        }
    },
    label: {
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        color: props => (props.disabled ? theme.colors.disabled_text : '')
    },
    error: {
        fontSize: 11,
        color: theme.colors.burntSlena,
        width: '100%',
        textAlign: 'left',
        marginTop: INPUT_SPACING,
        position: props => (props.disableErrorAbsolute ? 'static' : 'absolute'),
        left: 0,
        bottom: -20
    },
    borderError: {
        border: `1px solid ${theme.colors.burntSlena} !important`
    },
    startAdornment: {
        marginRight: '8px'
    },
    required: {
        color: theme.colors.brightRed
    }
}));

function Input({
    label,
    height,
    disabled,
    autoSelect,
    error = false,
    errorText,
    className,
    isShowErrorMessage = true,
    disableErrorAbsolute,
    autoComplete = 'off',
    startIcon,
    inputClassName,
    labelClassName,
    errorClassName,
    inputWrapperClassName,
    tooltipTitle = '',
    placement = 'bottom',
    ...rest
}) {
    const ref = React.useRef();

    React.useEffect(() => {
        if (autoSelect) {
            let input = ref.current.getElementsByTagName('input')[0];
            input && input.select();
        }
    }, [autoSelect]);

    const classes = useStyles({ error, disableErrorAbsolute, disabled, height });

    const startAdornment = React.useMemo(() => {
        if (!startIcon) return undefined;
        return <InputAdornment className={classes.startAdornment}>{startIcon}</InputAdornment>;
    }, [classes, startIcon]);

    return (
        <FormControl className={`${classes.inputForm} ${className}`}>
            {label && (
                <Typography className={`${classes.label} ${labelClassName || ''}`} variant="body1">
                    {label}
                    {rest.required ? <span className={classes.required}>*</span> : ''}
                </Typography>
            )}
            <Tooltip title={tooltipTitle} placement={placement}>
                <Grid item>
                    <Grid item className={inputWrapperClassName}>
                        <InputBase
                            className={`${classes.inputBase} ${error ? classes.borderError : ``} ${inputClassName}`}
                            ref={ref}
                            disabled={disabled}
                            autoComplete={autoComplete}
                            startAdornment={startAdornment}
                            {...rest}
                        />
                        {error && isShowErrorMessage && (
                            <div className={`${classes.error} ${errorClassName}`}>{errorText}</div>
                        )}
                    </Grid>
                </Grid>
            </Tooltip>
        </FormControl>
    );
}

export default React.memo(Input);
