import React from 'react';

function XLSSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M6 0V36H27L36 27V0H6Z" fill="#EFEEEE" />
            <path d="M27 27V36L36 27H27Z" fill="#ABABAB" />
            <path d="M18 36H27V27L18 36Z" fill="#DEDEDD" />
            <path d="M0 9V19.5H24V9H0Z" fill="#61B565" />
            <path
                d="M4.61169 11.0508L5.43969 13.1298L6.25868 11.0508H7.67168L6.17769 14.2278L7.77968 17.4768H6.32169L5.39619 15.2988L4.47669 17.4768H3.05469L4.65669 14.2278L3.18069 11.0508H4.61169Z"
                fill="white"
            />
            <path d="M9.18359 11.0508H10.4796V16.4148H12.7731V17.4768H9.18359V11.0508Z" fill="white" />
            <path
                d="M15.296 15.6578C15.296 15.8078 15.308 15.9443 15.3305 16.0658C15.356 16.1888 15.3995 16.2923 15.4655 16.3763C15.5315 16.4618 15.6215 16.5278 15.7325 16.5758C15.842 16.6223 15.983 16.6478 16.1495 16.6478C16.349 16.6478 16.526 16.5833 16.685 16.4543C16.8425 16.3253 16.9235 16.1243 16.9235 15.8558C16.9235 15.7118 16.9025 15.5873 16.865 15.4823C16.826 15.3773 16.7615 15.2828 16.6715 15.2003C16.58 15.1148 16.4615 15.0398 16.316 14.9708C16.1705 14.9018 15.9875 14.8313 15.7715 14.7593C15.485 14.6633 15.236 14.5568 15.0245 14.4428C14.8145 14.3303 14.639 14.1968 14.498 14.0423C14.357 13.8893 14.2535 13.7123 14.1875 13.5113C14.1215 13.3088 14.0885 13.0778 14.0885 12.8123C14.0885 12.1763 14.2655 11.7038 14.6195 11.3918C14.972 11.0798 15.4595 10.9238 16.0775 10.9238C16.3655 10.9238 16.631 10.9553 16.874 11.0183C17.117 11.0813 17.327 11.1818 17.504 11.3243C17.681 11.4653 17.819 11.6453 17.918 11.8628C18.0155 12.0818 18.0665 12.3443 18.0665 12.6503V12.8303H16.8245C16.8245 12.5243 16.7705 12.2888 16.664 12.1223C16.556 11.9588 16.376 11.8748 16.124 11.8748C15.98 11.8748 15.86 11.8973 15.764 11.9378C15.6695 11.9798 15.5915 12.0353 15.5345 12.1043C15.4775 12.1733 15.44 12.2528 15.419 12.3428C15.3965 12.4328 15.3875 12.5258 15.3875 12.6218C15.3875 12.8213 15.4295 12.9863 15.5135 13.1213C15.5975 13.2578 15.7775 13.3823 16.0535 13.4948L17.0525 13.9268C17.2985 14.0348 17.4995 14.1473 17.654 14.2658C17.81 14.3828 17.936 14.5088 18.0275 14.6438C18.1205 14.7788 18.185 14.9258 18.221 15.0893C18.257 15.2498 18.275 15.4313 18.275 15.6278C18.275 16.3058 18.0785 16.7993 17.6855 17.1083C17.2925 17.4173 16.745 17.5733 16.043 17.5733C15.311 17.5733 14.7875 17.4143 14.471 17.0963C14.156 16.7783 14 16.3223 14 15.7283V15.4673H15.296V15.6578Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(XLSSVG);
