import React from 'react';

function XLIFFSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M6 0V36H27L36 27V0H6Z" fill="#EFEEEE" />
            <path d="M27 27V36L36 27H27Z" fill="#ABABAB" />
            <path d="M18 36H27V27L18 36Z" fill="#DEDEDD" />
            <path d="M0 9V19H29V9H0Z" fill="#5DCDE3" />
            <path
                d="M4.557 11L5.385 13.079L6.204 11H7.617L6.123 14.177L7.725 17.426H6.267L5.3415 15.248L4.422 17.426H3L4.602 14.177L3.126 11H4.557Z"
                fill="white"
            />
            <path d="M9 11H10.2975V16.364H12.591V17.426H9V11Z" fill="white" />
            <path d="M14 11H15.296V17.426H14V11Z" fill="white" />
            <path d="M20.591 11V12.062H18.296V13.574H20.456V14.636H18.296V17.426H17V11H20.591Z" fill="white" />
            <path d="M25.591 11V12.062H23.296V13.574H25.456V14.636H23.296V17.426H22V11H25.591Z" fill="white" />
        </svg>
    );
}

export default React.memo(XLIFFSVG);
