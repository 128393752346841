import React from 'react';
import isEmpty from 'lodash/isEmpty';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useContextMenuId } from 'hooks/app';
import { useIsCreatingWorkspace, useIsShowLastWorkspacePopupCreate } from 'hooks/workspace';
import Skeleton from 'components/skeleton/Base';
import WorkspaceItem from './WorkspaceItem';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { reorderProjects, _fetchWorkspacesActionSuccess } from './actions';
import { useDispatch } from 'react-redux';
import { arrayMoveImmutable } from 'utils/array';
import AddWorkspaceSVG from 'assets/images/svg/AddWorkspaceSVG';
import { HEIGHT_FAVORITE_LIST_GRID, HEIGHT_UNICORN } from 'const';
import classNames from 'classnames';

const SortableItem = sortableElement(props => <WorkspaceItem {...props} />);

const HEIGHT = HEIGHT_FAVORITE_LIST_GRID + HEIGHT_UNICORN;

const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
});

function WorkspaceList({ workspaces, selectedWorkspaceId, isExpand }) {
    const dispatch = useDispatch();
    const isCreatingWorkspace = useIsCreatingWorkspace();
    const isShowLastPopupCreate = useIsShowLastWorkspacePopupCreate();
    const contextMenuId = useContextMenuId();
    const [isScrolling, setIsScrolling] = React.useState(false);
    const timerRef = React.useRef(null);

    React.useEffect(() => {
        const target = document.getElementById('workspaceList');
        if (!target) return;
        target.addEventListener('mousewheel', handleScroll);
        target.addEventListener('DOMMouseScroll', handleScroll);
        target.addEventListener('scroll', handleScroll);
        return () => {
            target.removeEventListener('mousewheel', handleScroll);
            target.removeEventListener('DOMMouseScroll', handleScroll);
            target.removeEventListener('scroll', handleScroll);
        };
    });

    function handleScroll() {
        setIsScrolling(true);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            console.log('stop scrolling');
            setIsScrolling(false);
        }, 100);
    }

    const onSortEnd = React.useCallback(
        ({ oldIndex, newIndex, ...rest }) => {
            if (oldIndex === newIndex) return;
            const oldWorkspaces = JSON.parse(JSON.stringify(workspaces));
            const newWorkspaces = arrayMoveImmutable(workspaces, oldIndex, newIndex);
            dispatch(_fetchWorkspacesActionSuccess({ workspaces: newWorkspaces }));
            const currentWsId = workspaces[oldIndex].id;
            let targetWsId = null;
            let position = 'after';
            if (newIndex === 0) {
                targetWsId = newWorkspaces[1].id;
                position = 'before';
            } else {
                targetWsId = newWorkspaces[newIndex - 1].id;
            }
            dispatch(
                reorderProjects({
                    projectId: currentWsId,
                    body: {
                        targetProjectId: targetWsId,
                        position
                    },
                    errorCallback: () => {
                        dispatch(_fetchWorkspacesActionSuccess({ workspaces: oldWorkspaces }));
                    }
                })
            );
        },
        [dispatch, workspaces]
    );

    if (isEmpty(workspaces)) {
        return (
            <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE}>
                <div
                    className={classNames(
                        // classes.root,
                        'scrollbar-app flex flex-nowrap flex-col overflow-x-hidden overflow-y-auto mb-5'
                    )}
                    style={{ height: isExpand ? `calc(100vh-${HEIGHT + 200}px)` : `calc(100vh-${HEIGHT + 50}px)` }}
                    id="workspaceList"
                >
                    <p className="caption px-3">
                        Click the
                        <AddWorkspaceSVG className="inline mx-1 mb-1" />
                        button to create a project
                    </p>
                </div>
            </AccessControl>
        );
    }

    return (
        <div
            className={classNames('scrollbar-app flex flex-nowrap flex-col overflow-x-hidden overflow-y-auto mb-5')}
            style={{ height: isExpand ? `calc(100vh - ${HEIGHT + 200}px)` : `calc(100vh - ${HEIGHT + 50}px)` }}
            id="workspaceList"
        >
            <SortableContainer distance={2} onSortEnd={onSortEnd}>
                {workspaces.map((workspace, index) => (
                    <SortableItem
                        key={`item-${workspace.id}`}
                        index={index}
                        isScrolling={isScrolling}
                        contextMenuId={contextMenuId}
                        id={workspace.id}
                        name={workspace.name}
                        workspace={workspace}
                        active={selectedWorkspaceId === workspace.id}
                        isShowLastPopupCreate={
                            isShowLastPopupCreate === workspace.id && index === workspaces.length - 1
                        }
                    />
                ))}
            </SortableContainer>
            <div id={'workspaceListBottom'} />
            {isCreatingWorkspace && <Skeleton height={36} />}
        </div>
    );
}

export default React.memo(WorkspaceList);
