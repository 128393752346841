import React, { useCallback, useMemo, useState } from 'react';
import useFetchMore from 'hooks/useFetchMore';
import { useParams } from 'react-router-dom';
import { dbApis } from 'services/database';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import Expansion from 'databases/backup/Expansion';
import moment from 'moment';
import PopperMenu from 'components/menus/Popper';
import BackupItemPopup from 'databases/backup/BackupItemPopup';
import Dialog from 'components/dialog/Dialog';
import RestoreConfirmModal from './RestoreConfirmModal';
import { Trans, useTranslation } from 'react-i18next';
import RecordPanelEmptyRecordSVG from 'assets/images/svg/RecordPanelEmptyRecordSVG';
import { sendManualTrack } from 'tracker';
import { useDispatch } from 'react-redux';
import { restoreGrid } from 'gridUI/actions';
import { useReferenceColumns } from 'hooks/gridUI';
import GridIcon from 'grids/GridIcon';
import OverflowTypography from 'components/textTruncated/OverflowTypography';

const useStyles = makeStyles(theme => ({
    flx: { flex: 1 },
    dialog: {
        '& .MuiDialog-paper': {
            width: 420
        }
    },
    refreshEl: {
        width: 0,
        height: 0,
        pointerEvents: 'none',
        opacity: 0
    },
    fullHeight: {
        height: '100%'
    },
    fetchingMore: {
        height: 36
    },
    gridName: {
        color: theme.colors.white,
        fontWeight: 400,
        fontSize: 13
    }
}));

const BackupList = ({ time }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { dbId, branchId } = useParams();
    const [backupFileSelected, setBackupFileSelected] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [popoverAnchor, setPopoverAnchor] = useState(null);

    const referenceColumns = useReferenceColumns();

    const options = useMemo(() => {
        return {
            sort: { alteredTime: 'desc' },
            query:
                time.timestamp === 0
                    ? {}
                    : {
                          alteredTime: ['today'].includes(time.value)
                              ? { '>=': time.timestamp }
                              : ['yesterday'].includes(time.value)
                              ? {
                                    between: [
                                        moment(time.timestamp).startOf('day'),
                                        moment(time.timestamp).endOf('day')
                                    ]
                                }
                              : { '>=': time.timestamp }
                      }
        };
    }, [time]);

    const { data, refresh, fetching, fetchingMore } = useFetchMore({
        scrollEl: document.getElementById('grid-backup-list'),
        apiUrl: dbApis.databaseBackupFiles({ dbId, gridId: branchId }),
        paramWrapper: 'page',
        options
    });

    const timeMapByDate = useMemo(() => {
        let result = {};
        if (data) {
            data.forEach(file => {
                const date = moment(file.alteredTime).startOf('day');
                if (!result[date]) result[date] = [];
                result[date] = [...result[date], file];
            });
        }
        Object.values(result).forEach(files =>
            files.sort((a, b) => new Date(b.alteredTime).getTime() - new Date(a.alteredTime).getTime())
        );
        return result;
    }, [data]);

    const filteredDates = useMemo(() => {
        return Object.keys(timeMapByDate).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    }, [timeMapByDate]);

    const iconMoreTooltip = useMemo(() => {
        if (!referenceColumns?.length) return '';
        const referencingGrids = referenceColumns.reduce((acc, col) => {
            if (col?.referencingItems?.length) {
                col.referencingItems.forEach(item => {
                    if (!acc.find(grid => grid.id === item?.grid?.id)) {
                        acc.push(item.grid);
                    }
                });
            }
            return acc;
        }, []);
        return (
            <Grid container direction="column" spacing={2} wrap="nowrap">
                <Grid item>This grid could not be restored since it has been referenced by:</Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2} wrap="nowrap">
                        {referencingGrids?.map(grid => {
                            return (
                                <Grid item key={grid?.id}>
                                    <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <GridIcon grid={grid} size="small" />
                                        </Grid>
                                        <Grid item className={classes.flx}>
                                            <OverflowTypography truncated className={classes.gridName}>
                                                {grid.name}
                                            </OverflowTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [classes, referenceColumns]);

    const handlePopupOpen = useCallback(({ target, backupFile }) => {
        setPopoverAnchor(target);
        setBackupFileSelected(backupFile);
    }, []);

    const handlePopupClose = useCallback(() => {
        setPopoverAnchor(null);
    }, []);

    const handleBackupRestore = useCallback(() => {
        setPopoverAnchor(null);
        setModalOpen(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleRestoreAccept = useCallback(() => {
        sendManualTrack({
            type: `Restore Grid`,
            customData: {
                dbId,
                gridId: branchId
            }
        });
        dispatch(
            restoreGrid({
                type: 'restore',
                restoreOption: 'file',
                backupId: backupFileSelected.id
            })
        );
        handleModalClose();
    }, [backupFileSelected, dbId, dispatch, branchId, handleModalClose]);

    const handleRefresh = useCallback(() => {
        if (document.getElementById('grid-backup-list')) {
            document.getElementById('grid-backup-list').scrollTop = 0;
        }
        refresh();
    }, [refresh]);

    return (
        <>
            <Grid className={classes.refreshEl} id="refresh-backup-grid" onClick={handleRefresh} />
            {filteredDates.length === 0 && !fetching && (
                <Grid
                    className={classes.fullHeight}
                    container
                    spacing={4}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid item>
                        <RecordPanelEmptyRecordSVG />
                    </Grid>
                    <Grid item>
                        <p className="body1 text-steel">
                            <Trans
                                t={t}
                                i18nKey="back_up_grid_no_data"
                                components={{
                                    br: <br />,
                                    bold: <b />
                                }}
                            />
                        </p>
                    </Grid>
                </Grid>
            )}
            {fetching && (
                <Grid
                    className={classes.fullHeight}
                    container
                    spacing={4}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid item>
                        <CircularProgress size={24} />
                    </Grid>
                </Grid>
            )}
            {filteredDates.map((date, index) => (
                <Expansion
                    key={index}
                    date={date}
                    timeMapByDate={timeMapByDate}
                    onPopupOpen={handlePopupOpen}
                    iconMoreDisabled={referenceColumns?.length > 0}
                    iconMoreTooltip={iconMoreTooltip}
                />
            ))}
            <Grid
                className={classes.fetchingMore}
                container
                justify="center"
                alignItems="center"
                direction="column"
                wrap="nowrap"
            >
                {fetchingMore && (
                    <Grid item>
                        <CircularProgress size={24} />
                    </Grid>
                )}
            </Grid>
            <PopperMenu open={Boolean(popoverAnchor)} anchorEl={popoverAnchor} handleClickAway={handlePopupClose}>
                <BackupItemPopup onBackupRestore={handleBackupRestore} />
            </PopperMenu>
            <Dialog className={classes.dialog} open={modalOpen} onClose={handleModalClose}>
                <RestoreConfirmModal
                    backupFile={backupFileSelected}
                    onClose={handleModalClose}
                    onAccept={handleRestoreAccept}
                />
            </Dialog>
        </>
    );
};

export default React.memo(BackupList);
