import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PaymentContentLayout from '../common/PaymentContentLayout';
import BuillingHistoryTable from 'payment/components/billing/Table';
import { useBillingHistory } from 'hooks/payment';
import { useDispatch } from 'react-redux';
import * as paymentActions from 'payment/actions';
import { useTranslation } from 'react-i18next';

function BillingHistory() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const billingHistory = useBillingHistory();

    useEffect(() => {
        dispatch(
            paymentActions.fetchBillingHistory({
                successCallback: () => {
                    console.log('fetchBillingHistory success');
                },
                errorCallback: () => {
                    console.log('fetchBillingHistory failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <PaymentContentLayout title={t('overview')}>
            <Grid container>
                <BuillingHistoryTable billings={billingHistory || []} />
            </Grid>
        </PaymentContentLayout>
    );
}

export default React.memo(BillingHistory);
