import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { PAYMENT_WARNING_TYPES } from 'const';
import { INPUT_RADIUS, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import OverlimitSVG from 'assets/images/svg/payment/OverlimitSVG';
import { getPaymentWarningColor } from 'utils/color';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 8,
        background: props => `${hexToRgba(props.warningColor || theme.colors.fuchsiaBlue, 0.05)}`,
        border: props => `1px solid ${hexToRgba(props.warningColor || theme.colors.fuchsiaBlue, 0.5)}`,
        borderLeft: props => `4px solid ${props.warningColor || theme.colors.fuchsiaBlue}`,
        borderRadius: INPUT_RADIUS
    },
    expand: {
        flex: 1
    },
    icon: {
        display: 'flex',
        marginRight: SPACING_LIST_NAME_WITH_ICON,
        '& svg': {
            width: 20,
            height: 20
        }
    }
}));

function WarningBox({ warningType = PAYMENT_WARNING_TYPES.INFO, message, action, content }) {
    const theme = useTheme();
    const warningColor = getPaymentWarningColor({ warningType, theme });
    const classes = useStyles({ warningColor });

    return (
        <Grid container className={classes.root} wrap="nowrap" spacing={1} alignItems="center">
            <Grid item className={classes.expand}>
                <Grid container direction="row" alignItems="flex-start">
                    <Grid item className={classes.icon}>
                        <OverlimitSVG color={warningColor} />
                    </Grid>
                    <Grid item className={classes.expand}>
                        {content ||
                            (typeof message === 'string' ? (
                                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message }}></Typography>
                            ) : (
                                message
                            ))}
                    </Grid>
                </Grid>
            </Grid>
            {action && <Grid item>{typeof action === 'function' ? action() : action}</Grid>}
        </Grid>
    );
}

export default React.memo(WarningBox);
