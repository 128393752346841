import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIsShareViewLink } from 'hooks/app';
import {
    useIsFetchingSelectionRangeFileData,
    useIsPreviewSelectionRangeFileData,
    useSelectionRangeFileData
} from 'hooks/gridUI/cellFilePreview';
import { Fade, Grid, useTheme } from '@material-ui/core';
import FilesPreview from 'components/filesPreview';
import Dialog from 'components/dialog/Dialog';
import { useDispatch } from 'react-redux';
import { setIsPreviewSelectionRangeFileData } from 'gridUI/actions';
import ProgressLinear from 'components/notify/Import';
import { isFileAudio } from 'utils/images';
import AudioFilesPreview from 'components/filesPreview/AudioFilesPreview';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const SelectionFilePreview = () => {
    const { dbId } = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isShareViewLink = useIsShareViewLink();
    const isPreviewSelectionRangeFileData = useIsPreviewSelectionRangeFileData();
    const selectionRangeFileData = useSelectionRangeFileData();
    const isFetchingSelectionRangeFileData = useIsFetchingSelectionRangeFileData();

    const files = useMemo(() => {
        return selectionRangeFileData?.map(fileData => fileData.file);
    }, [selectionRangeFileData]);

    const isAudio = useMemo(() => {
        return isFileAudio(files?.[0]);
    }, [files]);

    const handleClose = useCallback(() => {
        dispatch(setIsPreviewSelectionRangeFileData(false));
    }, [dispatch]);

    return (
        <>
            {isFetchingSelectionRangeFileData && (
                <Grid
                    item
                    className={
                        'fixed left-0 right-0 !m-auto p-3.5 bottom-[53px] w-[300px] z-[100000] rounded shadow bg-white'
                    }
                >
                    <ProgressLinear
                        background={theme.colors.lightLavender}
                        foreground={theme.colors.solitude}
                        title={'Looking for data...'}
                    />
                </Grid>
            )}
            <Dialog
                id="selection-file-preview"
                className={'dialog'}
                fullScreen
                open={isPreviewSelectionRangeFileData}
                TransitionComponent={Transition}
                onClose={handleClose}
            >
                {isAudio ? (
                    <AudioFilesPreview files={files} onClose={handleClose} dbId={dbId} />
                ) : (
                    <FilesPreview
                        isReadOnly
                        files={files}
                        onClose={handleClose}
                        isShareViewLink={isShareViewLink}
                        dbId={dbId}
                    />
                )}
            </Dialog>
        </>
    );
};

export default React.memo(SelectionFilePreview);
