import React from 'react';
import { Grid } from '@material-ui/core';
import { useIsTmDisabled, useRowHeight, useCharacterKey, useResourceCellByRowId } from 'hooks/advanced';
import { useTranslation } from 'react-i18next';
import * as columnTypes from 'const/columnTypes';
import RecordID from './RecordID';
import SingleTextEdit from './SingleText';
import MultiTextEdit from './MultiText';
import NumberEdit from './Number';
import DateEdit from './Date';
import SingleSelectEdit from './SingleSelect';
import ReferenceSelectEdit from './ReferenceSelect';
import MultiSelectEdit from './MultiSelect';
import RichTextEdit from './RichText';
import MarkdownEdit from './Markdown';
import JsonEdit from './Json';
import YamlEdit from './Yaml';
import HtmlEdit from './Html';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { useFillColorBlindness } from 'hooks/auth';
import { isLDEmpty } from 'utils/object';
import { getCombinedColumnId } from 'utils/gridUI/column';
import BooleanEdit from './Boolean';
import { LOCALIZATION_FONT } from 'const/gridUI';

function checkTypeAndRenderTypeEditor({ type, ...rest }) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
            return <SingleTextEdit {...rest} />;
        case columnTypes.MULTIPLE_LINES:
            return <MultiTextEdit {...rest} />;
        case columnTypes.RICH_TEXT:
            return <RichTextEdit {...rest} />;
        case columnTypes.MARKDOWN:
            return <MarkdownEdit {...rest} />;
        case columnTypes.NUMBER:
            return <NumberEdit {...rest} />;
        case columnTypes.DATETIME:
            return <DateEdit {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelectEdit {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectEdit {...rest} />;
        case columnTypes.REFERENCE:
            return <ReferenceSelectEdit {...rest} />;
        case columnTypes.TRANSLATION:
            return <MultiTextEdit {...rest} />;
        case columnTypes.PATH_TAG:
            return <MultiTextEdit {...rest} />;
        case columnTypes.JSON_LD:
            return <JsonEdit {...rest} />;
        case columnTypes.HTML:
            return <HtmlEdit {...rest} />;
        case columnTypes.YAML:
            return <YamlEdit {...rest} />;
        case columnTypes.RECORD_ID:
            return <RecordID {...rest} />;
        case columnTypes.BOOLEAN:
            return <BooleanEdit {...rest} />;
        default:
            return null;
    }
}

function CellEdit({ column, ...rest }) {
    const isTmDisabled = useIsTmDisabled();
    const { t } = useTranslation();
    const rowHeight = useRowHeight();
    const type = getCorrectColumnType(column);
    const fillColorBlindness = useFillColorBlindness();
    const character = useCharacterKey();
    const resourceCell = useResourceCellByRowId(rest?.rowId);

    const cellValue = React.useMemo(() => {
        return rest?.data?.value;
    }, [rest]);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[column?.group];
    }, [column]);

    const cellValueWithKey = React.useMemo(() => {
        if (
            [
                columnTypes.SINGLE_LINE,
                columnTypes.MULTIPLE_LINES,
                columnTypes.HTML,
                columnTypes.YAML,
                columnTypes.JSON_LD,
                columnTypes.NUMBER,
                columnTypes.TRANSLATION,
                columnTypes.RICH_TEXT
            ]?.includes(type)
        ) {
            return (
                (columnTypes.NUMBER === type ? (isLDEmpty(cellValue) ? '' : cellValue + '') : cellValue || '') +
                character
            );
        } else {
            return cellValue;
        }
    }, [cellValue, type, character]);

    return (
        <Grid container className={fontJP}>
            {checkTypeAndRenderTypeEditor({
                type,
                column: {
                    ...column?.metadata?.[getCombinedColumnId({ ...resourceCell, columnId: rest?.data?.columnId })],
                    type: column?.type
                },
                rowHeight,
                isTmDisabled,
                fillColorBlindness,
                value: cellValueWithKey,
                background: rest?.data?._color,
                dependency: rest?.data?._dependency,
                tm: rest?.data?._tm,
                t,
                character,
                ...rest
            })}
        </Grid>
    );
}

export default CellEdit;
