import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { INDEX_COLUMN_WIDTH, BORDER_HIGHLIGHT } from 'const/gridUI';
import { isSelecting, isSelectingRange } from 'utils/gridUI/range';
import {
    useWindowColumnStopIndex,
    useWindowRowStartIndex,
    useWindowRowStopIndex,
    useFixedColumnCount,
    useViewColumnsWithReOrder,
    useRowHeight,
    useWindowColumnStartIndex,
    useIsOpenCellEdit,
    useSelectedCellData,
    useIsOpenCellPreview,
    useTableInfo,
    useIsOpenViewCellTicket,
    useDisabledColumns,
    useProcessingColumns,
    useDisabledSourceColumns,
    useDisabledColumnsByType,
    useIsSelecting,
    useCellCopySelection,
    useIsOpenCellContext,
    useShowCopySource,
    useIsOpenCellComment,
    useReferenceDisabledColumns,
    useHoldEvent,
    useTotalRecords,
    useIsCellCopying
} from 'hooks/gridUI';
import { getViewColumnWidth } from 'utils/gridUI/column';
import PopperMenu from 'components/menus/Popper';
import { useIsShareViewLink } from 'hooks/app';
import { useTranslation } from 'react-i18next';
import CellContext from './context';
import { checkContainClassName, checkContainId } from 'utils/clickAway';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { cellKeyEventsHandler, rangeKeyEventsHandler } from 'gridUI/keyboardEvents';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import CellEdit from './cellEdit';
import IconLockSVG from 'assets/images/svg/IconLockSVG';
import Tooltip from 'components/tooltip/Base';
import CellPreview from 'gridUI/cellPreview';
import CellPreviewSVG from 'assets/images/svg/CellPreviewSVG';
import * as columnTypes from 'const/columnTypes';
import FileUploadHandler from './FileUploadHandler';
import ActiveUsersHighlight from './realtime';
import ConfirmBoxSmall from 'components/confirmBox/Small';
import hexToRgba from 'hex-to-rgba';
import ReorderRecordHighlight from './ReorderRecordHighlight';
import { CELL_PARENT_OFFSET, setCellParentOffset } from 'utils/usage';
import { moveGrid } from '../scroll';
import ViewCellMode from './viewMode';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';

const CELL_HIGHLIGHT_POSITION = {
    TOP_LEFT: 'topLeft',
    TOP_RIGHT: 'topRight',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};

export function _getStyleInfo({
    type,
    fixedColumnCount,
    rowHeight,
    rowStartIndex,
    rowStopIndex,
    columnStartIndex,
    columnStopIndex,
    viewColumns
}) {
    const rowStart = Math.min(rowStartIndex, rowStopIndex);
    const rowStop = Math.max(rowStartIndex, rowStopIndex);

    const columnStart = Math.min(columnStartIndex, columnStopIndex);
    const columnStop = Math.max(columnStartIndex, columnStopIndex);

    let data = {
        isRemoveEdgeBorder: false,
        left: 0,
        width: 0,
        height: 0,
        top: 0,
        isShow: true,
        cHlWidth: 0,
        cHlPosition: CELL_HIGHLIGHT_POSITION.TOP_LEFT,
        isShowCHl: false,
        isShowDotUI: true
    };

    const isColumnReverse = columnStartIndex > columnStopIndex;

    if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }))
        return {
            ...data,
            isShow: false
        };

    if (type === 'right') {
        if (columnStop < fixedColumnCount && fixedColumnCount > 0)
            return {
                ...data,
                isShow: false
            };

        const leftColumns = viewColumns?.slice(fixedColumnCount, Math.max(fixedColumnCount, columnStart));
        const columns = viewColumns?.slice(Math.max(fixedColumnCount, columnStart), columnStop + 1);

        if (fixedColumnCount > 0 && fixedColumnCount > columnStart) {
            data = {
                ...data,
                isRemoveEdgeBorder: true
            };
        }

        if (rowStartIndex < rowStopIndex) {
            data = {
                ...data,
                cHlPosition:
                    columnStopIndex > columnStartIndex
                        ? CELL_HIGHLIGHT_POSITION.TOP_LEFT
                        : CELL_HIGHLIGHT_POSITION.TOP_RIGHT,
                isShowCHl: columnStartIndex >= fixedColumnCount ? true : false,
                cHlWidth: isColumnReverse
                    ? getViewColumnWidth(columns?.[columns?.length - 1])
                    : getViewColumnWidth(columns?.[0])
            };
        } else {
            data = {
                ...data,
                cHlPosition:
                    columnStopIndex > columnStartIndex
                        ? CELL_HIGHLIGHT_POSITION.BOTTOM_LEFT
                        : CELL_HIGHLIGHT_POSITION.BOTTOM_RIGHT,
                isShowCHl: columnStartIndex >= fixedColumnCount ? true : false,
                cHlWidth: isColumnReverse
                    ? getViewColumnWidth(columns?.[columns?.length - 1])
                    : getViewColumnWidth(columns?.[0])
            };
        }

        data = {
            ...data,
            left: leftColumns?.reduce((t, column) => t + getViewColumnWidth(column), 0),
            width: columns?.reduce((t, column) => t + getViewColumnWidth(column), 0),
            height: (rowStop - rowStart + 1) * rowHeight,
            top: rowStart * rowHeight
        };
    } else {
        if (columnStart >= fixedColumnCount)
            return {
                ...data,
                isShow: false
            };

        if (fixedColumnCount <= columnStop && columnStart < fixedColumnCount) {
            data = {
                ...data,
                isRemoveEdgeBorder: true,
                isShowDotUI: false
            };
        }
        const leftColumns = viewColumns?.slice(0, columnStart);
        const columns = viewColumns?.slice(columnStart, Math.min(columnStop + 1, fixedColumnCount));

        if (rowStartIndex < rowStopIndex) {
            data = {
                ...data,
                cHlPosition:
                    columnStopIndex > columnStartIndex
                        ? CELL_HIGHLIGHT_POSITION.TOP_LEFT
                        : CELL_HIGHLIGHT_POSITION.TOP_RIGHT,
                isShowCHl: columnStartIndex < fixedColumnCount ? true : false,
                cHlWidth: isColumnReverse
                    ? getViewColumnWidth(columns?.[columns?.length - 1])
                    : getViewColumnWidth(columns?.[0])
            };
        } else {
            data = {
                ...data,
                cHlPosition: !isColumnReverse
                    ? CELL_HIGHLIGHT_POSITION.BOTTOM_LEFT
                    : CELL_HIGHLIGHT_POSITION.BOTTOM_RIGHT,
                isShowCHl: columnStartIndex < fixedColumnCount ? true : false,
                cHlWidth: isColumnReverse
                    ? getViewColumnWidth(columns?.[columns?.length - 1])
                    : getViewColumnWidth(columns?.[0])
            };
        }

        data = {
            ...data,
            left: INDEX_COLUMN_WIDTH + leftColumns?.reduce((t, column) => t + getViewColumnWidth(column), 0),
            width: columns?.reduce((t, column) => t + getViewColumnWidth(column), 0),
            height: (rowStop - rowStart + 1) * rowHeight,
            top: rowStart * rowHeight
        };
    }

    return data;
}

function CellOverlay({ type, onCellSelectionMouseDown }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const fixedColumnCount = useFixedColumnCount() || 0;
    const viewColumns = useViewColumnsWithReOrder();
    const rowHeight = useRowHeight();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();
    const rowStartIndex = useWindowRowStartIndex();
    const rowStopIndex = useWindowRowStopIndex();
    const [anchorElContextCell, setAnchorElContextCell] = React.useState(null);
    const [anchorElFillColumnConfirm, setAnchorElFillColumnConfirm] = React.useState(null);
    const isShareViewLink = useIsShareViewLink();
    const roles = useRole();
    const isOpenCellEdit = useIsOpenCellEdit();
    const disabledColumns = useDisabledColumns();
    const referenceDisabledColumns = useReferenceDisabledColumns();
    const processingColumns = useProcessingColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const isSelectRange = useIsSelecting();
    const isCellCopying = useIsCellCopying();

    const isRangeSelecting = React.useMemo(() => {
        return isCellCopying || isSelectRange;
    }, [isSelectRange, isCellCopying]);

    const selectedCellData = useSelectedCellData();
    const isOpenCellPreview = useIsOpenCellPreview();
    const overlayRef = React.useRef();
    const isOpenViewCellTicket = useIsOpenViewCellTicket();
    const isOpenCellContext = useIsOpenCellContext();
    const isOpenCellComment = useIsOpenCellComment();
    const cellCopySelection = useCellCopySelection();
    const totalRecords = useTotalRecords();

    const [isTriggerUpload, setIsTriggerUpload] = React.useState(false);
    const selectedCellWidth = React.useMemo(() => {
        return selectedCellData?.width;
    }, [selectedCellData]);

    const holdEvent = useHoldEvent();

    const { showCopySource, sourceData } = useShowCopySource({ ...selectedCellData });

    let clicks = React.useRef(0);
    const timerRef = React.useRef(null);
    const dotRef = React.useRef(null);

    const contextRef = React.useRef(null);

    const navigateRef = React.useRef(null);

    const accessEditRecords = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    }, [roles]);
    const tableInfo = useTableInfo();

    const {
        isShow,
        left,
        width,
        height,
        isRemoveEdgeBorder,
        top,
        cHlWidth,
        isShowCHl,
        isShowDotUI,
        cHlPosition
    } = React.useMemo(() => {
        return _getStyleInfo({
            type,
            fixedColumnCount,
            rowHeight,
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex,
            viewColumns
        });
    }, [
        type,
        fixedColumnCount,
        rowHeight,
        rowStartIndex,
        rowStopIndex,
        columnStartIndex,
        columnStopIndex,
        viewColumns
    ]);

    const cellCopyStyle = React.useMemo(() => {
        return _getStyleInfo({
            type,
            fixedColumnCount,
            rowHeight,
            ...cellCopySelection,
            viewColumns
        });
    }, [cellCopySelection, fixedColumnCount, type, rowHeight, viewColumns]);

    const cHlStyles = React.useMemo(() => {
        switch (cHlPosition) {
            case CELL_HIGHLIGHT_POSITION.TOP_LEFT:
                return {
                    top: 0,
                    left: 0,
                    width: cHlWidth - BORDER_HIGHLIGHT,
                    height: rowHeight - BORDER_HIGHLIGHT,
                    borderRight:
                        columnStopIndex - columnStartIndex > 0
                            ? `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                            : ``,
                    borderBottom: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                };
            case CELL_HIGHLIGHT_POSITION.TOP_RIGHT:
                return {
                    top: 0,
                    right: 0,
                    width: cHlWidth - BORDER_HIGHLIGHT,
                    height: rowHeight - BORDER_HIGHLIGHT,
                    borderLeft:
                        columnStartIndex - columnStopIndex > 0
                            ? `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                            : ``,
                    borderBottom: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                };
            case CELL_HIGHLIGHT_POSITION.BOTTOM_LEFT:
                return {
                    bottom: 0,
                    left: 0,
                    width: cHlWidth - BORDER_HIGHLIGHT,
                    height: rowHeight - BORDER_HIGHLIGHT,
                    borderRight:
                        columnStopIndex - columnStartIndex > 0
                            ? `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                            : ``,
                    borderTop: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                };
            case CELL_HIGHLIGHT_POSITION.BOTTOM_RIGHT:
                return {
                    bottom: 0,
                    right: 0,
                    width: cHlWidth - BORDER_HIGHLIGHT,
                    height: rowHeight - BORDER_HIGHLIGHT,
                    borderLeft:
                        columnStartIndex - columnStopIndex > 0
                            ? `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                            : ``,
                    borderTop: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
                };

            default:
                return {};
        }
    }, [cHlPosition, cHlWidth, rowHeight, theme, columnStopIndex, columnStartIndex]);

    React.useEffect(() => {
        if (isOpenViewCellTicket) {
            setAnchorElContextCell(overlayRef.current);
            setTimeout(() => {
                dispatch(gridUIActions.closeViewCellTicket());
            }, 0);
        }
    }, [isOpenViewCellTicket, dispatch]);

    React.useEffect(() => {
        if (isOpenCellComment) {
            setAnchorElContextCell(overlayRef.current);
            setTimeout(() => {
                dispatch(gridUIActions.closeCellComment());
            }, 0);
        }
    }, [isOpenCellComment, dispatch]);

    React.useEffect(() => {
        if (isOpenCellContext) {
            setAnchorElContextCell(document.getElementById('virtual-range-popper') || overlayRef.current);
            setTimeout(() => {
                dispatch(gridUIActions.closeCellContext());
            }, 0);
        }
    }, [isOpenCellContext, dispatch]);

    const isRangeSelected = React.useMemo(() => {
        return isSelectingRange({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex });
    }, [rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex]);

    const isRangeSelection = React.useMemo(() => {
        return (
            isRangeSelected &&
            (Math.abs(columnStopIndex - columnStartIndex) > 0 || Math.abs(rowStopIndex - rowStartIndex) > 0)
        );
    }, [columnStopIndex, isRangeSelected, columnStartIndex, rowStopIndex, rowStartIndex]);

    const handleContextMenu = React.useCallback(
        e => {
            if (isRangeSelection && !isShareViewLink && isShowCHl) {
                const el = document.createElement('div');
                el.style.position = 'absolute';
                el.style.left = `${e.clientX}px`;
                el.style.top = `${e.clientY}px`;
                el.id = 'virtual-range-popper';
                document.body.appendChild(el);
                setAnchorElContextCell(el);
                return;
            }
            setAnchorElContextCell(overlayRef.current);
        },
        [isRangeSelection, isShareViewLink, isShowCHl]
    );

    const closeCellContext = React.useCallback(e => {
        if (document.getElementById('virtual-range-popper')) {
            document.getElementById('virtual-range-popper').remove();
        }
        setAnchorElContextCell(null);
    }, []);

    const handleMouseDown = React.useCallback(
        e => {
            const cellOverlays = document.getElementsByClassName('cell-overlay');

            if (!cellOverlays?.length) return;

            closeCellContext();
            if (
                !isOpenCellEdit &&
                !selectedCellData?.isReadOnly &&
                columnTypes.BOOLEAN === selectedCellData?.column?.type
            ) {
                return;
            }

            const clientX = e.clientX;
            const clientY = e.clientY;

            for (let i = 0; i < cellOverlays?.length; i++) {
                cellOverlays[i].style.pointerEvents = 'none';
            }

            const ev = document.createEvent('MouseEvent');
            const el = document.elementFromPoint(clientX, clientY);

            ev.initMouseEvent(
                'mousedown',
                true,
                true,
                window,
                null,
                clientX,
                clientY,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null
            );
            for (let i = 0; i < cellOverlays?.length; i++) {
                cellOverlays[i].style.pointerEvents = 'auto';
            }

            el.dispatchEvent(ev);
        },
        [closeCellContext, isOpenCellEdit, selectedCellData]
    );

    const handleClick = React.useCallback(
        e => {
            const cellOverlays = document.getElementsByClassName('cell-overlay');

            if (!cellOverlays?.length) return;

            closeCellContext();
            if (
                !isOpenCellEdit &&
                !selectedCellData?.isReadOnly &&
                columnTypes.BOOLEAN === selectedCellData?.column?.type
            ) {
                dispatch(
                    gridUIActions.changeBooleanStatus({
                        rowId: selectedCellData.rowId,
                        columnId: selectedCellData.columnId
                    })
                );
                return;
            }

            const clientX = e.clientX;
            const clientY = e.clientY;

            for (let i = 0; i < cellOverlays?.length; i++) {
                cellOverlays[i].style.pointerEvents = 'none';
            }

            const ev = document.createEvent('MouseEvent');
            const el = document.elementFromPoint(clientX, clientY);

            ev.initMouseEvent(
                'click',
                true,
                true,
                window,
                null,
                clientX,
                clientY,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null
            );
            for (let i = 0; i < cellOverlays?.length; i++) {
                cellOverlays[i].style.pointerEvents = 'auto';
            }

            el.dispatchEvent(ev);
        },
        [closeCellContext, isOpenCellEdit, selectedCellData, dispatch]
    );

    const handleDoubleClick = React.useCallback(
        e => {
            if (!isOpenCellEdit && !selectedCellData?.isReadOnly) {
                if (![columnTypes.FILES, columnTypes.BOOLEAN]?.includes(selectedCellData?.column?.type)) {
                    if (overlayRef.current) {
                        const rect = overlayRef.current.getBoundingClientRect();
                        setCellParentOffset({ top: rect.top, left: rect.left });
                    }
                    dispatch(gridUIActions.openCellEdit({ ...selectedCellData }));
                } else if (columnTypes.FILES === selectedCellData?.column?.type) {
                    setIsTriggerUpload(true);
                    setTimeout(() => {
                        setIsTriggerUpload(false);
                    }, 0);
                }
            }
        },
        [dispatch, isOpenCellEdit, selectedCellData]
    );

    const isCellSelecting = React.useMemo(() => {
        return !isRangeSelected && isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex });
    }, [rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, isRangeSelected]);

    const isRangeCellCopySelected = React.useMemo(() => {
        return isSelectingRange({ ...cellCopySelection });
    }, [cellCopySelection]);

    const nonEditableColumnIds = React.useMemo(() => {
        return [
            ...disabledColumns,
            ...processingColumns,
            ...disabledSourceColumns,
            ...disabledColumnIdsByType,
            ...referenceDisabledColumns
        ];
    }, [disabledColumns, processingColumns, disabledSourceColumns, disabledColumnIdsByType, referenceDisabledColumns]);

    const isNotShowDot = React.useMemo(() => {
        const columnStart = Math.min(columnStartIndex, columnStopIndex);
        const columnStop = Math.max(columnStartIndex, columnStopIndex);
        const selectedColumns = viewColumns?.slice(columnStart, columnStop + 1);

        return (
            accessEditRecords !== roleConst?.FULL ||
            !selectedColumns?.some(col => col?.editable && !nonEditableColumnIds?.includes(col?.id))
        );
    }, [viewColumns, columnStartIndex, columnStopIndex, accessEditRecords, nonEditableColumnIds]);

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const openFillColumnsConfirm = React.useCallback(
        e => {
            stopPropagation(e);
            setAnchorElFillColumnConfirm(dotRef.current);
        },
        [stopPropagation]
    );

    const onMouseDownDotHandler = React.useCallback(
        e => {
            clicks.current++;
            stopPropagation(e);
            e.persist();

            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                if (clicks?.current > 1) {
                    openFillColumnsConfirm(e);
                } else {
                    onCellSelectionMouseDown(e);
                }
                clicks.current = 0;
            }, 300);
        },
        [onCellSelectionMouseDown, openFillColumnsConfirm, stopPropagation]
    );

    const fillColumns = React.useCallback(
        e => {
            setAnchorElFillColumnConfirm(null);
            dispatch(gridUIActions.fillColumns());
        },
        [dispatch]
    );

    const handleRepositionContext = React.useCallback(() => {
        if (contextRef.current) {
            contextRef.current.update();
        }
    }, []);

    const keyupHandler = React.useCallback(() => {
        if (holdEvent) {
            dispatch(gridUIActions.registerHoldEvent(null));
        }
    }, [dispatch, holdEvent]);

    React.useEffect(() => {
        if (!holdEvent && navigateRef?.current) {
            clearTimeout(navigateRef?.current);
        }

        if (holdEvent) {
            navigateRef.current = setTimeout(() => {
                cellKeyEventsHandler({
                    e: holdEvent,
                    dispatch,
                    isRangeSelected,
                    selectedCellData,
                    closeCellContext,
                    showCopySource,
                    sourceData
                });
            }, 10);
        }
    }, [holdEvent, dispatch, isRangeSelected, closeCellContext, selectedCellData, showCopySource, sourceData]);

    const keydownHandler = React.useCallback(
        e => {
            let event = document.all ? window.event : e;
            if (
                !(
                    /^(?:input|textarea|select|button)$/i.test(e.target.tagName) ||
                    checkContainClassName(event, 'cell-editor') ||
                    checkContainId(event, 'editable-mention') ||
                    document.getElementById('file-previews')
                )
            ) {
                if (event?.repeat && isCellSelecting) {
                    if (holdEvent) return;
                    return dispatch(gridUIActions.registerHoldEvent(event));
                }
                if (isCellSelecting) {
                    cellKeyEventsHandler({
                        e,
                        dispatch,
                        isRangeSelected,
                        selectedCellData,
                        closeCellContext,
                        showCopySource,
                        sourceData
                    });
                }

                //handle range selection events
                if (isRangeSelected) {
                    rangeKeyEventsHandler({ dispatch, e, accessEditRecords });
                }
            }
        },
        [
            isRangeSelected,
            accessEditRecords,
            isCellSelecting,
            dispatch,
            selectedCellData,
            closeCellContext,
            showCopySource,
            sourceData,
            holdEvent
        ]
    );

    const toggleCellPreview = React.useCallback(
        e => {
            e.stopPropagation();
            dispatch(gridUIActions.toggleCellPreview());
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (!isShow || isOpenCellEdit) {
            window.removeEventListener('keydown', keydownHandler, true);
            window.removeEventListener('keyup', keyupHandler, true);
        } else {
            window.addEventListener('keydown', keydownHandler, true);
            window.addEventListener('keyup', keyupHandler, true);
        }

        return () => {
            window.removeEventListener('keydown', keydownHandler, true);
            window.removeEventListener('keyup', keyupHandler, true);
        };
    }, [keydownHandler, isShow, isOpenCellEdit, keyupHandler]);

    /**
     * TODO: THIS WILL RECALCULATE WHEN GO TO ANOTHER SECTION
     * because overlay will auto initialize every section.
     */

    // auto detect scroll edge.

    const totalHeight = React.useMemo(() => {
        return totalRecords * rowHeight;
    }, [rowHeight, totalRecords]);

    React.useLayoutEffect(() => {
        if (window.dragScrollBar) return;
        const gridRef = tableInfo?.gridRef;
        const state = gridRef?.state;

        const scroll = tableInfo?.scroll;
        const isScrolling = state?.isScrolling;
        if (!isShow || isScrolling || !!moveGrid) return;

        let scrollLeft = state?.scrollLeft;
        let scrollTop = state?.scrollTop;

        const GRID_HEIGHT = gridRef?.props?.height;
        const GRID_WIDTH = gridRef?.props?.width;

        const _rowStopIndex = gridRef?._rowStopIndex;
        const _rowStartIndex = gridRef?._rowStartIndex;
        const _columnStartIndex = gridRef?._columnStartIndex;
        const _columnStopIndex = gridRef?._columnStopIndex;

        //move top down
        if (rowStopIndex > _rowStopIndex - 1) {
            scrollTop = Math.min(top + height - GRID_HEIGHT, totalHeight);
        } else if (rowStopIndex - 1 < _rowStartIndex) {
            scrollTop = Math.max(top - rowHeight, 0);
        }

        if (columnStopIndex <= fixedColumnCount) {
            if (fixedColumnCount === 0 && columnStopIndex === 0) {
                scroll({ scrollLeft: 0, scrollTop });
                return;
            }
        } else {
            //move left right
            if (columnStopIndex > _columnStopIndex) {
                scrollLeft = left + width - GRID_WIDTH;
            } else if (columnStopIndex - 1 - fixedColumnCount < _columnStartIndex) {
                scrollLeft = Math.max(0, left - selectedCellWidth);
            }
        }

        scroll({ scrollLeft, scrollTop });
    }, [
        isShow,
        tableInfo,
        top,
        height,
        left,
        width,
        selectedCellWidth,
        fixedColumnCount,
        columnStopIndex,
        rowStopIndex,
        rowHeight,
        totalHeight
    ]);

    const isShowViewMode = React.useMemo(() => {
        return (
            !isRangeSelecting &&
            isCellSelecting &&
            [columnTypes.TRANSLATION, columnTypes.MULTIPLE_LINES, columnTypes.SINGLE_LINE]?.includes(
                selectedCellData?.column?.type
            )
        );
    }, [isCellSelecting, selectedCellData, isRangeSelecting]);

    const isRtl = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[selectedCellData?.column?.group]?.rtl;
    }, [selectedCellData]);

    React.useEffect(() => {
        if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex })) {
            setAnchorElContextCell(null);
        }
    }, [rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex]);

    if (!isShow) {
        return (
            <>
                <ActiveUsersHighlight
                    rowHeight={rowHeight}
                    viewColumns={viewColumns}
                    type={type}
                    fixedColumnCount={fixedColumnCount}
                />
                <ReorderRecordHighlight />
            </>
        );
    }

    return (
        <>
            {!isOpenCellEdit ? (
                <>
                    <div
                        style={{
                            position: 'relative',
                            top: isCellSelecting ? top - 1 : top,
                            left: isCellSelecting ? left - 1 : left,
                            width
                        }}
                    >
                        {isCellSelecting &&
                            selectedCellData?.column?.type === columnTypes.FILES &&
                            !selectedCellData?.isReadOnly && (
                                <FileUploadHandler
                                    isTriggerUpload={isTriggerUpload}
                                    {...selectedCellData}
                                    height={height}
                                    handleContextMenu={handleContextMenu}
                                />
                            )}
                        <Grid
                            ref={overlayRef}
                            onDoubleClick={handleDoubleClick}
                            onMouseDown={handleMouseDown}
                            onClick={handleClick}
                            onContextMenu={handleContextMenu}
                            className={`cell-overlay border-b border-t border-highlight absolute`}
                            container
                            style={{
                                top: 0,
                                left: 0,
                                width: isShowViewMode && !isRtl ? width + 1 : width,
                                height,
                                borderLeft:
                                    isRemoveEdgeBorder && type === 'right'
                                        ? ``
                                        : `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                                borderRight:
                                    isRemoveEdgeBorder && type === 'left'
                                        ? ``
                                        : `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                                pointerEvents: isRangeSelecting ? 'none' : 'auto',
                                zIndex: 1,
                                background: hexToRgba(theme.colors.highlight, 0.15)
                            }}
                        >
                            {isRangeSelection && !isShareViewLink && isShowCHl && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        pointerEvents: 'none',
                                        ...cHlStyles
                                    }}
                                ></div>
                            )}
                            {!isNotShowDot && isShowDotUI && !isShareViewLink && (
                                <div
                                    className={'w-2 h-2 bg-highlight absolute -right-1 -bottom-1 cursor-pointer'}
                                    onClick={stopPropagation}
                                    onDoubleClick={stopPropagation}
                                    ref={dotRef}
                                    onMouseDown={onMouseDownDotHandler}
                                ></div>
                            )}
                            {isCellSelecting && selectedCellData?.isReadOnly && !isShareViewLink && (
                                <Tooltip title="Read only">
                                    <span
                                        className={'inline-block w-4 h-4 absolute -right-0.5 -bottom-0.5 p-0.5'}
                                        style={{
                                            right: selectedCellData?.tm ? 14 : -1
                                        }}
                                    >
                                        <IconLockSVG />
                                    </span>
                                </Tooltip>
                            )}
                            {isShowViewMode && <ViewCellMode {...selectedCellData} />}
                            {isCellSelecting && (
                                <Tooltip
                                    title={
                                        <div className="flex p-2 items-center gap-2">
                                            <p className="text-light font-medium">{t('expand_cell')}:</p>
                                            <p className={'body2 p-1 bg-white rounded-sm !text-text-primary'}>
                                                {t('space')}
                                            </p>
                                        </div>
                                    }
                                >
                                    <span
                                        onClick={toggleCellPreview}
                                        className={
                                            'inline-block w-4 h-4 bg-highlight absolute -top-px -right-4 cursor-pointer'
                                        }
                                    >
                                        <CellPreviewSVG />
                                    </span>
                                </Tooltip>
                            )}
                        </Grid>
                    </div>

                    {isRangeCellCopySelected && (
                        <Grid
                            onClick={handleClick}
                            className={`cell-overlay absolute`}
                            container
                            style={{
                                top:
                                    cellCopySelection?.rowStartIndex >= rowStartIndex
                                        ? cellCopyStyle?.top + Math.abs(rowStopIndex - rowStartIndex + 1) * rowHeight
                                        : cellCopyStyle.top,
                                left: cellCopyStyle?.left,
                                width: cellCopyStyle?.width,
                                height: cellCopyStyle?.height - Math.abs(rowStopIndex - rowStartIndex + 1) * rowHeight,
                                pointerEvents: isRangeSelecting ? 'none' : 'auto',
                                borderTop:
                                    cellCopySelection?.rowStartIndex >= rowStartIndex
                                        ? 'none'
                                        : `${BORDER_HIGHLIGHT}px dashed ${theme.colors.highlight}`,
                                borderBottom:
                                    cellCopySelection?.rowStartIndex >= rowStartIndex
                                        ? `${BORDER_HIGHLIGHT}px dashed ${theme.colors.highlight}`
                                        : 'none',
                                borderLeft:
                                    cellCopyStyle?.isRemoveEdgeBorder && type === 'right'
                                        ? `none`
                                        : `${BORDER_HIGHLIGHT}px dashed ${theme.colors.highlight}`,
                                borderRight:
                                    cellCopyStyle?.isRemoveEdgeBorder && type === 'left'
                                        ? ``
                                        : `${BORDER_HIGHLIGHT}px dashed ${theme.colors.highlight}`,
                                zIndex: 1
                            }}
                        ></Grid>
                    )}
                </>
            ) : (
                <Grid
                    container
                    style={{
                        top: top - 1,
                        left: left - 1,
                        width,
                        height,
                        position: 'absolute',
                        zIndex: 10
                    }}
                    id="cell-editor-123"
                >
                    {type === 'left' && columnStopIndex <= fixedColumnCount && (
                        <CellEdit
                            width={width}
                            height={height}
                            rowIndex={rowStartIndex}
                            top={CELL_PARENT_OFFSET.top}
                            left={left}
                            columnIndex={columnStartIndex}
                            {...selectedCellData}
                        />
                    )}
                    {type === 'right' && columnStartIndex >= fixedColumnCount && (
                        <CellEdit
                            width={width}
                            height={height}
                            rowIndex={rowStartIndex}
                            columnIndex={columnStartIndex}
                            top={CELL_PARENT_OFFSET.top}
                            left={left}
                            {...selectedCellData}
                        />
                    )}
                </Grid>
            )}

            {isOpenCellPreview && overlayRef.current && !isOpenCellEdit && (
                <PopperMenu anchorEl={overlayRef.current} placement={'right-start'} id={`cell_context_menu`}>
                    <CellPreview />
                </PopperMenu>
            )}

            {anchorElContextCell && !isShareViewLink && (
                <PopperMenu
                    anchorEl={anchorElContextCell}
                    placement={'right-start'}
                    id={`cell_context_menu`}
                    popperRef={contextRef}
                    paperClassName={'overflow-x-hidden'}
                >
                    <CellContext
                        handleClickAway={closeCellContext}
                        onClose={closeCellContext}
                        t={t}
                        rowStartIndex={rowStartIndex}
                        rowStopIndex={rowStopIndex}
                        columnStartIndex={columnStartIndex}
                        columnStopIndex={columnStopIndex}
                        onRePosContext={handleRepositionContext}
                        selectedCellData={selectedCellData}
                    />
                </PopperMenu>
            )}

            {anchorElFillColumnConfirm && !isShareViewLink && (
                <PopperMenu
                    className={'w-[260px] shadow'}
                    anchorEl={anchorElFillColumnConfirm}
                    id={`cell_context_menu`}
                >
                    <ConfirmBoxSmall
                        header={t(`auto_fill`)}
                        title={t(`auto_fill_subtitle`)}
                        onCancel={() => setAnchorElFillColumnConfirm(null)}
                        onAgreed={fillColumns}
                        agreeLabel="Ok"
                        onClickAway={() => setAnchorElFillColumnConfirm(null)}
                    />
                </PopperMenu>
            )}

            <ActiveUsersHighlight
                rowHeight={rowHeight}
                viewColumns={viewColumns}
                type={type}
                fixedColumnCount={fixedColumnCount}
            />

            <ReorderRecordHighlight />
        </>
    );
}

export default React.memo(CellOverlay);
