import React from 'react';

function CommentsSVG({ color = '#78778B', size = 16, ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
            <path
                fill={color}
                fillRule="nonzero"
                d="M13.422 1c.994 0 1.81.768 1.885 1.743l.005.148v11.618a.485.485 0 0 1-.763.398l-.063-.055-1.872-1.87a.49.49 0 0 0-.247-.133l-.095-.01-10.365-.027c-.73 0-1.332-.558-1.4-1.27L.5 11.405V2.891c0-.995.768-1.81 1.743-1.885L2.391 1h11.03zm.092 1.313H2.3a.485.485 0 0 0-.478.397l-.008.087v8.218c0 .239.172.436.399.477l.087.008h10.523c.106 0 .209.035.292.098l.058.053.669.7.159.16V2.797a.485.485 0 0 0-.398-.477l-.088-.007zm-4.976 5.25v1.406H3.88V7.563h4.658zM11.75 4.75v1.406H3.88V4.75h7.87z"
            />
        </svg>
    );
}

export default React.memo(CommentsSVG);
