import React from 'react';

const AutomationLOGOSVG = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                fill="#7869B9"
            />
            <g clipPath="url(#clip0_2831_161358)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.8438 7.04659C17.146 7.16193 17.3314 7.4674 17.2944 7.78866L16.6165 13.6728H21.5669C21.8281 13.6728 22.068 13.8167 22.1911 14.0471C22.3141 14.2775 22.3002 14.5569 22.1549 14.774L15.5211 24.6859C15.3385 24.9587 14.9922 25.0701 14.6848 24.955C14.3773 24.8398 14.1895 24.5283 14.2311 24.2026L15.0228 18.0065L9.93071 17.8127C9.67138 17.8029 9.43825 17.6518 9.32326 17.4192C9.20827 17.1865 9.22986 16.9096 9.37952 16.6976L16.0134 7.2996C16.1999 7.03541 16.5417 6.93126 16.8438 7.04659ZM11.2879 16.4481L15.8496 16.6217C16.0481 16.6293 16.2342 16.7199 16.3626 16.8714C16.491 17.023 16.5498 17.2215 16.5246 17.4185L16.0156 21.4026L20.2418 15.088H15.8227C15.6209 15.088 15.4288 15.0019 15.2945 14.8513C15.1602 14.7007 15.0967 14.4999 15.1197 14.2994L15.5713 10.3799L11.2879 16.4481Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2831_161358">
                    <rect width="18" height="18" fill="white" transform="translate(7 7)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(AutomationLOGOSVG);
