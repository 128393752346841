import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TBDetailTable from './TBDetailTable';
import TBDetailHeader from './TBDetailHeader';
import TBDetailToolbar from './TBDetailToolbar';
import Spinner from 'components/spinner/Base';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '0 24px',
        position: 'relative'
    },
    loadingMore: {
        height: 20,
        position: 'absolute',
        bottom: 25,
        left: 0,
        zIndex: 10
    }
}));

const TBDetailOverview = ({
    searchValue,
    handleSearchChange,
    handleClearSearch,
    glossary,
    listTerms,
    setListTerms,
    isFetching,
    isFetchingMore,
    selectedRows,
    setSelectedRows,
    handleResetFetch,
    isFilteringOption,
    handleChangeFilterOption,
    handleFetchMore,
    filterOptions,
    onClickTerm,
    auditors
}) => {
    const classes = useStyles();
    const tableContainerRef = useRef();

    useEffect(() => {
        const element = tableContainerRef.current;
        const onScroll = e => {
            if (element.scrollTop + element.clientHeight >= element.scrollHeight - 50) {
                handleFetchMore();
            }
        };
        if (element) {
            if (listTerms.length > 0 || isFilteringOption || isFetching) {
                element.addEventListener('scroll', onScroll);
            }
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', onScroll);
            }
        };
    }, [isFetching, isFilteringOption, listTerms.length, handleFetchMore]);

    useEffect(() => {
        if (!listTerms.length && tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = 0;
            tableContainerRef.current.scrollTop = 0;
        }
    }, [listTerms.length]);

    return (
        <Grid item container direction="column" wrap="nowrap" className={classes.root}>
            <TBDetailHeader glossary={glossary} />
            <TBDetailToolbar
                searchValue={searchValue}
                handleSearchChange={handleSearchChange}
                handleClearSearch={handleClearSearch}
                glossary={glossary}
                listTerms={listTerms}
                setListTerms={setListTerms}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleResetFetch={handleResetFetch}
                tableContainerRef={tableContainerRef}
            />
            <TBDetailTable
                glossary={glossary}
                listTerms={listTerms}
                setListTerms={setListTerms}
                isFetching={isFetching}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleResetFetch={handleResetFetch}
                isFilteringOption={isFilteringOption}
                handleChangeFilterOption={handleChangeFilterOption}
                filterOptions={filterOptions}
                onClickTerm={onClickTerm}
                tableContainerRef={tableContainerRef}
                auditors={auditors}
            />
            {isFetchingMore && (
                <Grid container className={classes.loadingMore} justify="center" alignItems="center" direction="column">
                    <Spinner size={30} thick={4} />
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(TBDetailOverview);
