import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_RADIUS } from 'const/style';
import Divider from 'components/divider/Base';
import PlanTag from 'payment/components/tags/Plan';
import PlanList from './PlanList';
import { sendManualTrack } from 'tracker';
import {
    useCurrentSubscriptionIs,
    useIsProActive,
    useIsProTrial,
    useIsSeatExceeded,
    useIsPendingFromAnnualToMonthly
} from 'hooks/payment';
import { PLAN_TYPES, PAYMENT_WARNING_TYPES, PLAN_CYCLE, MAX_SEAT_INPUT, SUBSCRIPTION_STATUS } from 'const';
import { getFriendlyDate } from 'utils/datetime';
import { beautifyPrice, getFriendlyIntervalType, getPlanName } from 'utils/payment';
import CancelPlan from './CancelPlan';
import { useDispatch } from 'react-redux';
import * as paymentActions from 'payment/actions';
import ChangeSeat from './ChangeSeat';
import WarningBox from 'payment/components/warning';
import CancelTrial from './CancelTrial';
import Dialog from 'components/dialog/Dialog';
import FreePlanSVG from 'assets/images/svg/payment/FreePlanSVG';
import ProfessionalPlanSVG from 'assets/images/svg/payment/ProfessionalPlanSVG';
import EnterprisePlanSVG from 'assets/images/svg/payment/EnterprisePlanSVG';
import InputText from 'components/inputs/InputText';
import { enqueueSnackbar } from 'notifier/actions';
import { getChangePlanPreviewApi } from 'services/payment';
import { debounce } from 'lodash';
import PreviewSeat from './PreviewSeat';
import EnterprisePreviewSeat from './EnterprisePreviewSeat';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        borderTopLeftRadius: INPUT_RADIUS,
        borderTopRightRadius: INPUT_RADIUS,
        padding: theme.spacing(3, 4),
        border: `1px solid ${theme.colors.divider}`,
        borderBottomStyle: 'dashed'
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    link: {
        cursor: 'pointer',
        color: theme.colors.dodgerBlue,
        textDecoration: 'underline'
    },
    priceText: {
        color: theme.colors.dodgerBlue
    },
    burntSlena: {
        color: theme.colors.burntSlena
    },
    warningBox: {
        marginTop: theme.spacing(3)
    },
    inputText: {
        width: 119,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    dodgerBlue: {
        color: theme.colors.dodgerBlue
    },
    greyText: {
        color: theme.colors.midGrey
    },
    subtotal: {
        backgroundColor: theme.colors.ghostwhite,
        padding: theme.spacing(3, 4),
        borderBottomLeftRadius: INPUT_RADIUS,
        borderBottomRightRadius: INPUT_RADIUS,
        border: `1px solid ${theme.colors.divider}`,
        borderTop: 'none'
    }
}));

const MODAL_TYPES = {
    CHOOSE_PLAN: 'CHOOSE_PLAN',
    PREVIEW_SUBSCRIPTION: 'PREVIEW_SUBSCRIPTION',
    ENT_PREVIEW_SUBSCRIPTION: 'ENT_PREVIEW_SUBSCRIPTION',
    CHANGE_SEAT: 'CHANGE_SEAT',
    CANCEL_PLAN: 'CANCEL_PLAN',
    CANCEL_TRIAL: 'CANCEL_TRIAL'
};

function CurrentPlanDetail({ companySubscription }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [currentModal, setCurrentModal] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [selectedCycle, setSelectedCycle] = React.useState(PLAN_CYCLE.month);
    const [selectedPlan, setSelectedPlan] = React.useState('');
    const [proSeat, setProSeat] = React.useState(0);
    const [changePlanPreview, setChangePlanPreview] = React.useState(null);
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isEnterprise = useCurrentSubscriptionIs(PLAN_TYPES.ENTERPRISE);

    const isProActive = useIsProActive();
    const isProTrial = useIsProTrial();
    const isCanceling =
        companySubscription?.nextPlan === PLAN_TYPES.FREE &&
        companySubscription?.plan !== PLAN_TYPES.FREE &&
        companySubscription?.status === SUBSCRIPTION_STATUS.ACTIVE;
    const isUpgrading = companySubscription?.nextPlan !== PLAN_TYPES.FREE;
    const isSeatExceeded = useIsSeatExceeded();
    const isPendingFromAnnualToMonthly = useIsPendingFromAnnualToMonthly();
    const isValidSeat = Boolean(proSeat) && proSeat >= companySubscription?.activeSeat;

    const fetchChangePlanPreview = React.useCallback(
        debounce(async ({ totalSeat, annually, currency }) => {
            try {
                const preview = await getChangePlanPreviewApi({ totalSeat, annually, currency });
                setChangePlanPreview(preview);
            } catch ({ message }) {
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
            }
        }, 500),
        [dispatch]
    );

    React.useEffect(() => {
        setProSeat(companySubscription?.totalSeat);
    }, [companySubscription]);

    React.useEffect(() => {
        if (!proSeat) return;
        fetchChangePlanPreview({
            totalSeat: proSeat,
            annually: selectedCycle === PLAN_CYCLE.year,
            currency: companySubscription.seatCurrency
        });
    }, [proSeat, selectedCycle, fetchChangePlanPreview, companySubscription]);

    const handleChangeProSeat = e => {
        if (e.target.value > MAX_SEAT_INPUT) {
            setProSeat(MAX_SEAT_INPUT);
            return;
        }
        setProSeat(parseInt(e.target.value));
    };

    const plans = [
        ...(!isFreePlan
            ? [
                  {
                      type: PLAN_TYPES.FREE,
                      icon: <FreePlanSVG />,
                      description: 'For individuals and small teams, using Gridly free of charge',
                      priceText: (
                          <Typography variant="h3" align="center" className={classes.dodgerBlue}>
                              Free
                          </Typography>
                      ),
                      seat: (
                          <Typography variant="body2" align="center" className={classes.greyText}>
                              Starting from 3 members
                          </Typography>
                      ),
                      benefits: [
                          <>
                              <Typography variant="body1" display="inline">
                                  10,000
                              </Typography>{' '}
                              rows per Grid
                          </>,
                          <>
                              <Typography variant="body1" display="inline">
                                  2GB
                              </Typography>{' '}
                              storage
                          </>
                      ]
                  }
              ]
            : []),
        {
            type: PLAN_TYPES.PROFESSIONAL,
            icon: <ProfessionalPlanSVG />,
            description: 'For small and medium-sized teams',
            priceText: (
                <Typography align="center">
                    <Typography variant="h3" display="inline" className={classes.dodgerBlue}>
                        {beautifyPrice({
                            unitAmount: changePlanPreview?.totalAmount || 0,
                            currency: companySubscription?.seatCurrency
                        })}
                    </Typography>
                    <Typography variant="body2" display="inline">
                        /{selectedCycle}
                    </Typography>{' '}
                    {Boolean(changePlanPreview?.discountAmount) && (
                        <Typography variant="body2" className={classes.dodgerBlue}>
                            (saved{' '}
                            {beautifyPrice({
                                unitAmount: changePlanPreview?.discountAmount,
                                currency: companySubscription?.seatCurrency
                            })}
                            )
                        </Typography>
                    )}
                </Typography>
            ),
            seat: (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography variant="body2" display="inline" className={classes.greyText}>
                            Starting from
                        </Typography>
                    </Grid>
                    <Grid item>
                        <InputText
                            className={classes.inputText}
                            name="seatsNumber"
                            type="number"
                            value={proSeat}
                            onChange={handleChangeProSeat}
                            inputProps={{ min: companySubscription?.activeSeat, max: MAX_SEAT_INPUT }}
                            endAdornment={
                                <Typography variant="body2" className={classes.greyText}>
                                    seats
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            ),
            benefits: [
                <>
                    <Typography variant="body1" display="inline">
                        100,000
                    </Typography>{' '}
                    rows per Grid
                </>,
                <>
                    <Typography variant="body1" display="inline">
                        20GB
                    </Typography>{' '}
                    storage
                </>,
                'Paid Add-ons'
            ]
        },
        {
            type: PLAN_TYPES.ENTERPRISE,
            icon: <EnterprisePlanSVG />,
            description: 'For medium and large companies with advanced needs and workflows',
            priceText: (
                <Typography variant="h3" align="center" className={classes.dodgerBlue}>
                    Custom price
                </Typography>
            ),
            seat: (
                <Typography variant="body2" align="center" className={classes.greyText}>
                    Please contact us for more info
                </Typography>
            ),
            benefits: [
                <>
                    <Typography variant="body1" display="inline">
                        1,000,000
                    </Typography>{' '}
                    rows per Grid
                </>,
                <>
                    <Typography variant="body1" display="inline">
                        1000GB
                    </Typography>{' '}
                    storage
                </>,
                'Paid Add-ons'
            ]
        }
    ];

    const handleCloseCurrentModal = () => {
        setCurrentModal('');
        setProSeat(companySubscription?.totalSeat);
        setSelectedCycle(PLAN_CYCLE.month);
        setSelectedPlan('');
    };

    const handleOpenChoosePlanModal = () => {
        sendManualTrack({ type: `Click On Change Plan` });
        setCurrentModal(MODAL_TYPES.CHOOSE_PLAN);
    };

    const handleOpenCancelTrialModal = () => {
        setCurrentModal(MODAL_TYPES.CANCEL_TRIAL);
    };

    const handleOpenCancelPlanModal = () => {
        setCurrentModal(MODAL_TYPES.CANCEL_PLAN);
    };

    const handleOpenChangeSeatModal = () => {
        setSelectedCycle(companySubscription?.cycle);
        setCurrentModal(MODAL_TYPES.CHANGE_SEAT);
    };

    const handleOpenPreviewSubscriptionModal = () => {
        setCurrentModal(MODAL_TYPES.PREVIEW_SUBSCRIPTION);
    };

    const handleOpenEnterprisePreviewSubscriptionModal = () => {
        setCurrentModal(MODAL_TYPES.ENT_PREVIEW_SUBSCRIPTION);
    };

    const handleOpenNextModal = plan => {
        setSelectedPlan(plan);
        if (plan === PLAN_TYPES.FREE) {
            if (isProTrial) {
                handleOpenCancelTrialModal();
                return;
            }
            if (isProActive) {
                handleOpenCancelPlanModal();
                return;
            }
        }
        if (plan === PLAN_TYPES.PROFESSIONAL) {
            handleOpenPreviewSubscriptionModal();
            return;
        }
    };

    const handleChangePlan = ({ plan, cycle, totalSeat }) => {
        setIsSubmitting(true);
        dispatch(
            paymentActions.changePlan({
                subscriptionId: companySubscription?.id,
                plan,
                annually: cycle === PLAN_CYCLE.year,
                totalSeat,
                successCallback: () => {
                    setIsSubmitting(false);
                    handleCloseCurrentModal();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    handleCloseCurrentModal();
                }
            })
        );
    };

    const handlePurchasePlan = ({ plan, cycle, totalSeat }) => {
        setIsSubmitting(true);
        dispatch(
            paymentActions.changePlan({
                subscriptionId: companySubscription?.id,
                plan,
                annually: cycle === PLAN_CYCLE.year,
                totalSeat,
                successCallback: () => {
                    setIsSubmitting(false);
                    handleCloseCurrentModal();
                    dispatch(
                        enqueueSnackbar({
                            message: "You've all set. Your purchase was successful",
                            type: 'info'
                        })
                    );
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    handleCloseCurrentModal();
                }
            })
        );
    };

    const handleChangeSeat = () => {
        setIsSubmitting(true);
        dispatch(
            paymentActions.changePlan({
                subscriptionId: companySubscription?.id,
                totalSeat: proSeat,
                successCallback: () => {
                    setIsSubmitting(false);
                    handleCloseCurrentModal();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    handleCloseCurrentModal();
                }
            })
        );
    };

    return (
        <>
            <Grid container direction="column" className={classes.root} wrap="nowrap">
                {isProTrial && (
                    <>
                        <Grid item container wrap="nowrap">
                            <Grid item xs={8}>
                                {isUpgrading
                                    ? 'Your subscription will start after trial expires on '
                                    : 'Your trial expires on '}
                                <Typography variant="body1" display="inline">
                                    {getFriendlyDate(companySubscription?.trialEndDate)}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body2"
                                    className={classes.link}
                                    onClick={handleOpenCancelTrialModal}
                                >
                                    Cancel trial
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                    </>
                )}
                {!isFreePlan && !isProTrial && (
                    <>
                        <Grid item container>
                            <Grid item xs={3}>
                                <Typography variant="body2">Billing cycle</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body2" display="inline">
                                    {getFriendlyIntervalType(companySubscription?.cycle)} (
                                    {isCanceling ? 'end' : 'renew'} on
                                </Typography>{' '}
                                <Typography variant="body1" display="inline">
                                    {getFriendlyDate(companySubscription?.currentPeriodEndDate)}
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    )
                                </Typography>
                            </Grid>
                            {isProActive && !isCanceling && (
                                <Grid item xs={4}>
                                    <Typography
                                        variant="body2"
                                        className={classes.link}
                                        onClick={handleOpenCancelPlanModal}
                                    >
                                        Cancel plan
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Divider className={classes.divider} />
                    </>
                )}
                <Grid item container>
                    <Grid item xs={3}>
                        <Typography variant="body2">Plan</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <PlanTag planType={companySubscription?.plan} />
                    </Grid>
                    {!isEnterprise && !isCanceling && (
                        <Grid item xs={4}>
                            <Typography variant="body2" className={classes.link} onClick={handleOpenChoosePlanModal}>
                                {isFreePlan ? t('upgrade_plan') : t('change_plan')}
                            </Typography>
                        </Grid>
                    )}
                    {isEnterprise && (
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                className={classes.link}
                                onClick={handleOpenEnterprisePreviewSubscriptionModal}
                            >
                                Subscription preview
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Divider className={classes.divider} />
                <Grid item container>
                    <Grid item xs={3}>
                        <Typography variant="body2">{t('paid_seat')}</Typography>
                    </Grid>
                    <Grid item xs={5} className={isSeatExceeded ? classes.burntSlena : ''}>
                        {companySubscription?.totalSeat} ({companySubscription?.activeSeat} in use,{' '}
                        {companySubscription?.availableSeat} are available)
                    </Grid>
                    {(isProActive || isEnterprise) && !isCanceling && (
                        <Grid item xs={4}>
                            <Typography variant="body2" className={classes.link} onClick={handleOpenChangeSeatModal}>
                                Manage member seats
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Divider className={classes.divider} />
                <Grid item container>
                    <Grid item xs={3}>
                        <Typography variant="body2">Member seat</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="body1" display="inline">
                            {beautifyPrice({
                                unitAmount: companySubscription?.seatPriceAmount,
                                currency: companySubscription?.seatCurrency
                            })}
                        </Typography>
                        <Typography variant="body2" display="inline">
                            /user/{companySubscription?.cycle}
                        </Typography>
                    </Grid>
                </Grid>
                <Dialog
                    open={currentModal === MODAL_TYPES.CHOOSE_PLAN}
                    onClose={handleCloseCurrentModal}
                    maxWidth="xl"
                    className={classes.createWorkspaceModal}
                >
                    <PlanList
                        plans={plans}
                        onClose={handleCloseCurrentModal}
                        companySubscription={companySubscription}
                        onSelect={handleOpenNextModal}
                        selectedCycle={selectedCycle}
                        setSelectedCycle={setSelectedCycle}
                        isSubmitting={isSubmitting}
                        isValidSeat={isValidSeat}
                        proSeat={proSeat}
                    />
                </Dialog>
                <Dialog
                    maxWidth="xs"
                    open={currentModal === MODAL_TYPES.PREVIEW_SUBSCRIPTION}
                    onClose={handleCloseCurrentModal}
                >
                    <PreviewSeat
                        onClose={handleCloseCurrentModal}
                        onConfirm={() =>
                            handlePurchasePlan({ plan: selectedPlan, cycle: selectedCycle, totalSeat: proSeat })
                        }
                        companySubscription={companySubscription}
                        isSubmitting={isSubmitting}
                        proSeat={proSeat}
                        onChangeProSeat={handleChangeProSeat}
                        changePlanPreview={changePlanPreview}
                        isValidSeat={isValidSeat}
                        cycle={selectedCycle}
                        plan={selectedPlan}
                    />
                </Dialog>
                <Dialog
                    maxWidth="xs"
                    open={currentModal === MODAL_TYPES.ENT_PREVIEW_SUBSCRIPTION}
                    onClose={handleCloseCurrentModal}
                >
                    <EnterprisePreviewSeat
                        onClose={handleCloseCurrentModal}
                        companySubscription={companySubscription}
                        changePlanPreview={changePlanPreview}
                    />
                </Dialog>

                <Dialog open={currentModal === MODAL_TYPES.CANCEL_PLAN} onClose={handleCloseCurrentModal}>
                    <CancelPlan
                        onClose={handleCloseCurrentModal}
                        onConfirm={() => handleChangePlan({ plan: PLAN_TYPES.FREE })}
                        isSubmitting={isSubmitting}
                    />
                </Dialog>
                <Dialog open={currentModal === MODAL_TYPES.CANCEL_TRIAL} onClose={handleCloseCurrentModal}>
                    <CancelTrial
                        onClose={handleCloseCurrentModal}
                        expireDate={getFriendlyDate(companySubscription?.trialEndDate)}
                        onConfirm={() => handleChangePlan({ plan: PLAN_TYPES.FREE })}
                        isSubmitting={isSubmitting}
                    />
                </Dialog>
                <Dialog maxWidth="xs" open={currentModal === MODAL_TYPES.CHANGE_SEAT} onClose={handleCloseCurrentModal}>
                    <ChangeSeat
                        onClose={handleCloseCurrentModal}
                        onConfirm={handleChangeSeat}
                        companySubscription={companySubscription}
                        isSubmitting={isSubmitting}
                        proSeat={proSeat}
                        onChangeProSeat={handleChangeProSeat}
                        changePlanPreview={changePlanPreview}
                        isValidSeat={isValidSeat}
                    />
                </Dialog>
            </Grid>
            <Grid item container className={classes.subtotal}>
                <Grid item xs={8}>
                    <Typography variant="body1">Subscription total</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1" display="inline" className={classes.priceText}>
                        {beautifyPrice({
                            unitAmount: companySubscription?.seatSubtotalAmount,
                            currency: companySubscription?.seatCurrency
                        })}
                    </Typography>
                    <Typography variant="body2" display="inline">
                        /{companySubscription?.cycle} {isProTrial && 'Free on trial'}
                    </Typography>
                </Grid>
            </Grid>
            {isCanceling && (
                <Grid className={classes.warningBox}>
                    <WarningBox
                        warningType={PAYMENT_WARNING_TYPES.ERROR}
                        message={
                            <>
                                <Typography variant="body2" display="inline">
                                    You've downgraded to the Free plan. Your
                                </Typography>{' '}
                                <Typography variant="body1" display="inline">
                                    {getPlanName(companySubscription.plan)}
                                </Typography>{' '}
                                <Typography variant="body2" display="inline">
                                    plan will be expired on
                                </Typography>{' '}
                                <Typography variant="body1" display="inline">
                                    {getFriendlyDate(
                                        companySubscription?.nextStartDate || companySubscription?.currentPeriodEndDate
                                    )}
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    .
                                </Typography>
                            </>
                        }
                    />
                </Grid>
            )}
            {isSeatExceeded && (
                <Grid className={classes.warningBox}>
                    <WarningBox
                        warningType={PAYMENT_WARNING_TYPES.ERROR}
                        message={
                            isFreePlan ? (
                                <>
                                    <Typography variant="body2" display="inline">
                                        You've exceeded the seat limits. Please{' '}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        display="inline"
                                        className={classes.link}
                                        onClick={handleOpenChoosePlanModal}
                                    >
                                        Upgrade to Pro Plan
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        {' '}
                                        to get more seats.
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body2" display="inline">
                                        You're exceeded the seat limits. Please{' '}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        display="inline"
                                        className={classes.link}
                                        onClick={handleOpenChangeSeatModal}
                                    >
                                        Purchase more seats
                                    </Typography>
                                    <Typography variant="body2" display="inline">
                                        {' '}
                                        for your team members.
                                    </Typography>
                                </>
                            )
                        }
                    />
                </Grid>
            )}
            {isPendingFromAnnualToMonthly && (
                <Grid className={classes.warningBox}>
                    <WarningBox
                        warningType={PAYMENT_WARNING_TYPES.INFO}
                        message={
                            <>
                                <Typography variant="body2" display="inline">
                                    Your billing cycle will be changed from{' '}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    Annually
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    {' '}
                                    to{' '}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    Monthly
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    {' '}
                                    on{' '}
                                </Typography>
                                <Typography variant="body1" display="inline">
                                    {getFriendlyDate(companySubscription?.nextStartDate)}
                                </Typography>
                                <Typography variant="body2" display="inline">
                                    .
                                </Typography>
                            </>
                        }
                    />
                </Grid>
            )}
        </>
    );
}

export default React.memo(CurrentPlanDetail);
