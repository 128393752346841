export function encodeURL(params) {
    let type = typeof params;
    if (type === 'string') {
        return encode(params);
    } else if (type === 'object') {
        return encode(JSON.stringify(params));
    } else {
        return null;
    }
}

function encode(string) {
    let query = new URLSearchParams(string).toString();
    /**
     * TODO: improve this to support case &&&
     */
    query = query?.replace(new RegExp(`=&`, 'g'), encodeURIComponent('&'));
    if (query?.endsWith('=')) {
        query = query.slice(0, query?.length - 1);
    }
    return query;
}

export function getParamValue({ pathname, id }) {
    const pathArr = pathname?.split('/');
    const foundIndex = pathArr?.findIndex(variable => variable === id);
    return foundIndex !== -1 ? pathArr?.[foundIndex + 1] : undefined;
}
