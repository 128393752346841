import React from 'react';

function MasterSVG({ color = '#E86E5E', ...other }) {
    return (
        <svg {...other} width="225" height="130" viewBox="0 0 225 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#master0)">
                <rect width="225" height="130" rx="12" fill="#4E4E4E" />
                <g clipPath="url(#master1)">
                    <path
                        opacity="0.04"
                        d="M289.47 79.8569C286.581 83.0099 283.673 86.1001 280.699 89.207C260.445 110.679 276.887 128.976 262.414 138.886C252.854 145.47 233.454 137.35 233.98 150.221C235.05 178.789 209.408 180.865 192.258 170.36C183.514 165.015 180.682 159.597 171.386 149.809C157.185 134.855 141.567 154.284 118.125 157.558C95.9193 160.655 82.1237 145.869 85.9778 129.806C91.9569 104.652 124.046 105.13 127.711 81.6362C134.246 39.6321 150.362 51.2738 178.404 44.0793C202.73 37.8879 206.013 19.0716 222.549 6.62128C235.329 -4.20981 249.402 -7.7526 265.362 -4.42647C306.161 4.03313 315.957 50.4152 289.47 79.8569Z"
                        fill="#010101"
                    />
                    <path
                        opacity="0.03"
                        d="M110.264 32.7702C107.035 34.4143 103.763 35.9818 100.491 37.5494C77.9323 48.4457 85.5244 66.0328 71.0382 69.7796C61.439 72.2648 48.48 61.5213 44.9893 71.156C37.3099 92.5664 16.2352 87.7873 5.76324 75.7822C0.440018 69.6649 -0.214478 64.9623 -4.70868 55.4423C-11.5591 40.8756 -29.7977 51.4279 -49.4325 48.1016C-68.0638 44.9283 -74.6088 30.591 -66.7112 19.6564C-54.4067 2.52805 -29.0123 10.7481 -19.0639 -5.73024C-1.26167 -35.1695 8.07579 -22.6292 32.5103 -21.0234C53.7596 -19.647 62.0062 -32.7226 78.8922 -37.8841C92.3311 -42.7397 104.592 -41.8985 116.286 -35.5136C146.305 -19.2647 140.24 17.5153 110.264 32.7702Z"
                        fill="white"
                    />
                    <g opacity="0.2" clipPath="url(#master2)">
                        <path
                            d="M136.112 36.934C136.112 36.6195 135.865 36.3679 135.556 36.3679C135.247 36.3679 135 36.6195 135 36.934C135 37.2484 135.247 37.5 135.556 37.5C135.865 37.5 136.112 37.2484 136.112 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M136.112 33.1605C136.112 32.8461 135.865 32.5945 135.556 32.5945C135.247 32.5945 135 32.8461 135 33.1605C135 33.475 135.247 33.7266 135.556 33.7266C135.865 33.7266 136.112 33.475 136.112 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M136.112 29.3871C136.112 29.0726 135.865 28.821 135.556 28.821C135.247 28.821 135 29.0726 135 29.3871C135 29.7016 135.247 29.9531 135.556 29.9531C135.865 29.9531 136.112 29.7016 136.112 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M136.112 25.6137C136.112 25.2992 135.865 25.0476 135.556 25.0476C135.247 25.0476 135 25.2992 135 25.6137C135 25.9281 135.247 26.1797 135.556 26.1797C135.865 26.1797 136.112 25.9281 136.112 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M136.112 21.8402C136.112 21.5257 135.865 21.2742 135.556 21.2742C135.247 21.2742 135 21.5257 135 21.8402C135 22.1547 135.247 22.4062 135.556 22.4062C135.865 22.4062 136.112 22.1547 136.112 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M136.112 18.0668C136.112 17.7523 135.865 17.5007 135.556 17.5007C135.247 17.5007 135 17.7523 135 18.0668C135 18.3812 135.247 18.6328 135.556 18.6328C135.865 18.6328 136.112 18.3812 136.112 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M139.818 36.934C139.818 36.6195 139.571 36.3679 139.262 36.3679C138.953 36.3679 138.706 36.6195 138.706 36.934C138.706 37.2484 138.953 37.5 139.262 37.5C139.571 37.5 139.818 37.2484 139.818 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M139.818 33.1605C139.818 32.8461 139.571 32.5945 139.262 32.5945C138.953 32.5945 138.706 32.8461 138.706 33.1605C138.706 33.475 138.953 33.7266 139.262 33.7266C139.571 33.7266 139.818 33.475 139.818 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M139.818 29.3871C139.818 29.0726 139.571 28.821 139.262 28.821C138.953 28.821 138.706 29.0726 138.706 29.3871C138.706 29.7016 138.953 29.9531 139.262 29.9531C139.571 29.9531 139.818 29.7016 139.818 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M139.818 25.6137C139.818 25.2992 139.571 25.0476 139.262 25.0476C138.953 25.0476 138.706 25.2992 138.706 25.6137C138.706 25.9281 138.953 26.1797 139.262 26.1797C139.571 26.1797 139.818 25.9281 139.818 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M139.818 21.8402C139.818 21.5257 139.571 21.2742 139.262 21.2742C138.953 21.2742 138.706 21.5257 138.706 21.8402C138.706 22.1547 138.953 22.4062 139.262 22.4062C139.571 22.4062 139.818 22.1547 139.818 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M139.818 18.0668C139.818 17.7523 139.571 17.5007 139.262 17.5007C138.953 17.5007 138.706 17.7523 138.706 18.0668C138.706 18.3812 138.953 18.6328 139.262 18.6328C139.571 18.6328 139.818 18.3812 139.818 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M143.524 36.934C143.524 36.6195 143.277 36.3679 142.969 36.3679C142.66 36.3679 142.413 36.6195 142.413 36.934C142.413 37.2484 142.66 37.5 142.969 37.5C143.277 37.5 143.524 37.2484 143.524 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M143.524 33.1605C143.524 32.8461 143.277 32.5945 142.969 32.5945C142.66 32.5945 142.413 32.8461 142.413 33.1605C142.413 33.475 142.66 33.7266 142.969 33.7266C143.277 33.7266 143.524 33.475 143.524 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M143.524 29.3871C143.524 29.0726 143.277 28.821 142.969 28.821C142.66 28.821 142.413 29.0726 142.413 29.3871C142.413 29.7016 142.66 29.9531 142.969 29.9531C143.277 29.9531 143.524 29.7016 143.524 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M143.524 25.6136C143.524 25.2992 143.277 25.0476 142.969 25.0476C142.66 25.0476 142.413 25.2992 142.413 25.6136C142.413 25.9281 142.66 26.1797 142.969 26.1797C143.277 26.1797 143.524 25.9281 143.524 25.6136Z"
                            fill="white"
                        />
                        <path
                            d="M143.524 21.8402C143.524 21.5257 143.277 21.2742 142.969 21.2742C142.66 21.2742 142.413 21.5257 142.413 21.8402C142.413 22.1547 142.66 22.4062 142.969 22.4062C143.277 22.4062 143.524 22.1547 143.524 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M143.524 18.0668C143.524 17.7523 143.277 17.5007 142.969 17.5007C142.66 17.5007 142.413 17.7523 142.413 18.0668C142.413 18.3812 142.66 18.6328 142.969 18.6328C143.277 18.6328 143.524 18.3812 143.524 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M147.231 36.934C147.231 36.6195 146.984 36.3679 146.675 36.3679C146.366 36.3679 146.119 36.6195 146.119 36.934C146.119 37.2484 146.366 37.5 146.675 37.5C146.984 37.5 147.231 37.2484 147.231 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M147.231 33.1605C147.231 32.8461 146.984 32.5945 146.675 32.5945C146.366 32.5945 146.119 32.8461 146.119 33.1605C146.119 33.475 146.366 33.7266 146.675 33.7266C146.984 33.7266 147.231 33.475 147.231 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M147.231 29.3871C147.231 29.0726 146.984 28.821 146.675 28.821C146.366 28.821 146.119 29.0726 146.119 29.3871C146.119 29.7016 146.366 29.9531 146.675 29.9531C146.984 29.9531 147.231 29.7016 147.231 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M147.231 25.6136C147.231 25.2992 146.984 25.0476 146.675 25.0476C146.366 25.0476 146.119 25.2992 146.119 25.6136C146.119 25.9281 146.366 26.1797 146.675 26.1797C146.984 26.1797 147.231 25.9281 147.231 25.6136Z"
                            fill="white"
                        />
                        <path
                            d="M147.231 21.8402C147.231 21.5257 146.984 21.2742 146.675 21.2742C146.366 21.2742 146.119 21.5257 146.119 21.8402C146.119 22.1547 146.366 22.4062 146.675 22.4062C146.984 22.4062 147.231 22.1547 147.231 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M147.231 18.0668C147.231 17.7523 146.984 17.5007 146.675 17.5007C146.366 17.5007 146.119 17.7523 146.119 18.0668C146.119 18.3812 146.366 18.6328 146.675 18.6328C146.984 18.6328 147.231 18.3812 147.231 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M150.937 36.934C150.937 36.6195 150.69 36.3679 150.381 36.3679C150.072 36.3679 149.825 36.6195 149.825 36.934C149.825 37.2484 150.072 37.5 150.381 37.5C150.69 37.5 150.937 37.2484 150.937 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M150.937 33.1605C150.937 32.8461 150.69 32.5945 150.381 32.5945C150.072 32.5945 149.825 32.8461 149.825 33.1605C149.825 33.475 150.072 33.7266 150.381 33.7266C150.69 33.7266 150.937 33.475 150.937 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M150.937 29.3871C150.937 29.0726 150.69 28.821 150.381 28.821C150.072 28.821 149.825 29.0726 149.825 29.3871C149.825 29.7016 150.072 29.9531 150.381 29.9531C150.69 29.9531 150.937 29.7016 150.937 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M150.937 25.6136C150.937 25.2992 150.69 25.0476 150.381 25.0476C150.072 25.0476 149.825 25.2992 149.825 25.6136C149.825 25.9281 150.072 26.1797 150.381 26.1797C150.69 26.1797 150.937 25.9281 150.937 25.6136Z"
                            fill="white"
                        />
                        <path
                            d="M150.937 21.8402C150.937 21.5257 150.69 21.2742 150.381 21.2742C150.072 21.2742 149.825 21.5257 149.825 21.8402C149.825 22.1547 150.072 22.4062 150.381 22.4062C150.69 22.4062 150.937 22.1547 150.937 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M150.937 18.0668C150.937 17.7523 150.69 17.5007 150.381 17.5007C150.072 17.5007 149.825 17.7523 149.825 18.0668C149.825 18.3812 150.072 18.6328 150.381 18.6328C150.69 18.6328 150.937 18.3812 150.937 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M154.643 36.934C154.643 36.6195 154.396 36.3679 154.087 36.3679C153.779 36.3679 153.531 36.6195 153.531 36.934C153.531 37.2484 153.779 37.5 154.087 37.5C154.396 37.5 154.643 37.2484 154.643 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M154.643 33.1605C154.643 32.8461 154.396 32.5945 154.087 32.5945C153.779 32.5945 153.531 32.8461 153.531 33.1605C153.531 33.475 153.779 33.7266 154.087 33.7266C154.396 33.7266 154.643 33.475 154.643 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M154.643 29.3871C154.643 29.0726 154.396 28.821 154.087 28.821C153.779 28.821 153.531 29.0726 153.531 29.3871C153.531 29.7016 153.779 29.9531 154.087 29.9531C154.396 29.9531 154.643 29.7016 154.643 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M154.643 25.6137C154.643 25.2992 154.396 25.0476 154.087 25.0476C153.779 25.0476 153.531 25.2992 153.531 25.6137C153.531 25.9281 153.779 26.1797 154.087 26.1797C154.396 26.1797 154.643 25.9281 154.643 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M154.643 21.8402C154.643 21.5257 154.396 21.2742 154.087 21.2742C153.779 21.2742 153.531 21.5257 153.531 21.8402C153.531 22.1547 153.779 22.4062 154.087 22.4062C154.396 22.4062 154.643 22.1547 154.643 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M154.643 18.0668C154.643 17.7523 154.396 17.5007 154.087 17.5007C153.779 17.5007 153.531 17.7523 153.531 18.0668C153.531 18.3812 153.779 18.6328 154.087 18.6328C154.396 18.6328 154.643 18.3812 154.643 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M158.35 36.934C158.35 36.6195 158.103 36.3679 157.794 36.3679C157.485 36.3679 157.238 36.6195 157.238 36.934C157.238 37.2484 157.485 37.5 157.794 37.5C158.103 37.5 158.35 37.2484 158.35 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M158.35 33.1605C158.35 32.8461 158.103 32.5945 157.794 32.5945C157.485 32.5945 157.238 32.8461 157.238 33.1605C157.238 33.475 157.485 33.7266 157.794 33.7266C158.103 33.7266 158.35 33.475 158.35 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M158.35 29.3871C158.35 29.0726 158.103 28.821 157.794 28.821C157.485 28.821 157.238 29.0726 157.238 29.3871C157.238 29.7016 157.485 29.9531 157.794 29.9531C158.103 29.9531 158.35 29.7016 158.35 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M158.35 25.6137C158.35 25.2992 158.103 25.0476 157.794 25.0476C157.485 25.0476 157.238 25.2992 157.238 25.6137C157.238 25.9281 157.485 26.1797 157.794 26.1797C158.103 26.1797 158.35 25.9281 158.35 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M158.35 21.8402C158.35 21.5257 158.103 21.2742 157.794 21.2742C157.485 21.2742 157.238 21.5257 157.238 21.8402C157.238 22.1547 157.485 22.4062 157.794 22.4062C158.103 22.4062 158.35 22.1547 158.35 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M158.35 18.0668C158.35 17.7523 158.103 17.5007 157.794 17.5007C157.485 17.5007 157.238 17.7523 157.238 18.0668C157.238 18.3812 157.485 18.6328 157.794 18.6328C158.103 18.6328 158.35 18.3812 158.35 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M162.056 36.934C162.056 36.6195 161.809 36.3679 161.5 36.3679C161.191 36.3679 160.944 36.6195 160.944 36.934C160.944 37.2484 161.191 37.5 161.5 37.5C161.809 37.5 162.056 37.2484 162.056 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M162.056 33.1605C162.056 32.8461 161.809 32.5945 161.5 32.5945C161.191 32.5945 160.944 32.8461 160.944 33.1605C160.944 33.475 161.191 33.7266 161.5 33.7266C161.809 33.7266 162.056 33.475 162.056 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M162.056 29.3871C162.056 29.0726 161.809 28.821 161.5 28.821C161.191 28.821 160.944 29.0726 160.944 29.3871C160.944 29.7016 161.191 29.9531 161.5 29.9531C161.809 29.9531 162.056 29.7016 162.056 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M162.056 25.6137C162.056 25.2992 161.809 25.0476 161.5 25.0476C161.191 25.0476 160.944 25.2992 160.944 25.6137C160.944 25.9281 161.191 26.1797 161.5 26.1797C161.809 26.1797 162.056 25.9281 162.056 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M162.056 21.8402C162.056 21.5257 161.809 21.2742 161.5 21.2742C161.191 21.2742 160.944 21.5257 160.944 21.8402C160.944 22.1547 161.191 22.4062 161.5 22.4062C161.809 22.4062 162.056 22.1547 162.056 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M162.056 18.0668C162.056 17.7523 161.809 17.5007 161.5 17.5007C161.191 17.5007 160.944 17.7523 160.944 18.0668C160.944 18.3812 161.191 18.6328 161.5 18.6328C161.809 18.6328 162.056 18.3812 162.056 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M165.762 36.934C165.762 36.6195 165.515 36.3679 165.206 36.3679C164.897 36.3679 164.65 36.6195 164.65 36.934C164.65 37.2484 164.897 37.5 165.206 37.5C165.515 37.5 165.762 37.2484 165.762 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M165.762 33.1605C165.762 32.8461 165.515 32.5945 165.206 32.5945C164.897 32.5945 164.65 32.8461 164.65 33.1605C164.65 33.475 164.897 33.7266 165.206 33.7266C165.515 33.7266 165.762 33.475 165.762 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M165.762 29.3871C165.762 29.0726 165.515 28.821 165.206 28.821C164.897 28.821 164.65 29.0726 164.65 29.3871C164.65 29.7016 164.897 29.9531 165.206 29.9531C165.515 29.9531 165.762 29.7016 165.762 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M165.762 25.6137C165.762 25.2992 165.515 25.0476 165.206 25.0476C164.897 25.0476 164.65 25.2992 164.65 25.6137C164.65 25.9281 164.897 26.1797 165.206 26.1797C165.515 26.1797 165.762 25.9281 165.762 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M165.762 21.8402C165.762 21.5257 165.515 21.2742 165.206 21.2742C164.897 21.2742 164.65 21.5257 164.65 21.8402C164.65 22.1547 164.897 22.4062 165.206 22.4062C165.515 22.4062 165.762 22.1547 165.762 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M165.762 18.0668C165.762 17.7523 165.515 17.5007 165.206 17.5007C164.897 17.5007 164.65 17.7523 164.65 18.0668C164.65 18.3812 164.897 18.6328 165.206 18.6328C165.515 18.6328 165.762 18.3812 165.762 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M169.469 36.934C169.469 36.6195 169.221 36.3679 168.913 36.3679C168.604 36.3679 168.357 36.6195 168.357 36.934C168.357 37.2484 168.604 37.5 168.913 37.5C169.221 37.5 169.469 37.2484 169.469 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M169.469 33.1605C169.469 32.8461 169.221 32.5945 168.913 32.5945C168.604 32.5945 168.357 32.8461 168.357 33.1605C168.357 33.475 168.604 33.7266 168.913 33.7266C169.221 33.7266 169.469 33.475 169.469 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M169.469 29.3871C169.469 29.0726 169.221 28.821 168.913 28.821C168.604 28.821 168.357 29.0726 168.357 29.3871C168.357 29.7016 168.604 29.9531 168.913 29.9531C169.221 29.9531 169.469 29.7016 169.469 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M169.469 25.6137C169.469 25.2992 169.221 25.0476 168.913 25.0476C168.604 25.0476 168.357 25.2992 168.357 25.6137C168.357 25.9281 168.604 26.1797 168.913 26.1797C169.221 26.1797 169.469 25.9281 169.469 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M169.469 21.8402C169.469 21.5257 169.221 21.2742 168.913 21.2742C168.604 21.2742 168.357 21.5257 168.357 21.8402C168.357 22.1547 168.604 22.4062 168.913 22.4062C169.221 22.4062 169.469 22.1547 169.469 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M169.469 18.0668C169.469 17.7523 169.221 17.5007 168.913 17.5007C168.604 17.5007 168.357 17.7523 168.357 18.0668C168.357 18.3812 168.604 18.6328 168.913 18.6328C169.221 18.6328 169.469 18.3812 169.469 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M173.175 36.934C173.175 36.6195 172.928 36.3679 172.619 36.3679C172.31 36.3679 172.063 36.6195 172.063 36.934C172.063 37.2484 172.31 37.5 172.619 37.5C172.928 37.5 173.175 37.2484 173.175 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M173.175 33.1605C173.175 32.8461 172.928 32.5945 172.619 32.5945C172.31 32.5945 172.063 32.8461 172.063 33.1605C172.063 33.475 172.31 33.7266 172.619 33.7266C172.928 33.7266 173.175 33.475 173.175 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M173.175 29.3871C173.175 29.0726 172.928 28.821 172.619 28.821C172.31 28.821 172.063 29.0726 172.063 29.3871C172.063 29.7016 172.31 29.9531 172.619 29.9531C172.928 29.9531 173.175 29.7016 173.175 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M173.175 25.6137C173.175 25.2992 172.928 25.0476 172.619 25.0476C172.31 25.0476 172.063 25.2992 172.063 25.6137C172.063 25.9281 172.31 26.1797 172.619 26.1797C172.928 26.1797 173.175 25.9281 173.175 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M173.175 21.8402C173.175 21.5257 172.928 21.2742 172.619 21.2742C172.31 21.2742 172.063 21.5257 172.063 21.8402C172.063 22.1547 172.31 22.4062 172.619 22.4062C172.928 22.4062 173.175 22.1547 173.175 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M173.175 18.0668C173.175 17.7523 172.928 17.5007 172.619 17.5007C172.31 17.5007 172.063 17.7523 172.063 18.0668C172.063 18.3812 172.31 18.6328 172.619 18.6328C172.928 18.6328 173.175 18.3812 173.175 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M176.881 36.934C176.881 36.6195 176.634 36.3679 176.325 36.3679C176.016 36.3679 175.769 36.6195 175.769 36.934C175.769 37.2484 176.016 37.5 176.325 37.5C176.634 37.5 176.881 37.2484 176.881 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M176.881 33.1605C176.881 32.8461 176.634 32.5945 176.325 32.5945C176.016 32.5945 175.769 32.8461 175.769 33.1605C175.769 33.475 176.016 33.7266 176.325 33.7266C176.634 33.7266 176.881 33.475 176.881 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M176.881 29.3871C176.881 29.0726 176.634 28.821 176.325 28.821C176.016 28.821 175.769 29.0726 175.769 29.3871C175.769 29.7016 176.016 29.9531 176.325 29.9531C176.634 29.9531 176.881 29.7016 176.881 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M176.881 25.6137C176.881 25.2992 176.634 25.0476 176.325 25.0476C176.016 25.0476 175.769 25.2992 175.769 25.6137C175.769 25.9281 176.016 26.1797 176.325 26.1797C176.634 26.1797 176.881 25.9281 176.881 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M176.881 21.8402C176.881 21.5257 176.634 21.2742 176.325 21.2742C176.016 21.2742 175.769 21.5257 175.769 21.8402C175.769 22.1547 176.016 22.4062 176.325 22.4062C176.634 22.4062 176.881 22.1547 176.881 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M176.881 18.0668C176.881 17.7523 176.634 17.5007 176.325 17.5007C176.016 17.5007 175.769 17.7523 175.769 18.0668C175.769 18.3812 176.016 18.6328 176.325 18.6328C176.634 18.6328 176.881 18.3812 176.881 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M180.587 36.934C180.587 36.6195 180.34 36.3679 180.032 36.3679C179.723 36.3679 179.476 36.6195 179.476 36.934C179.476 37.2484 179.723 37.5 180.032 37.5C180.34 37.5 180.587 37.2484 180.587 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M180.587 33.1605C180.587 32.8461 180.34 32.5945 180.031 32.5945C179.723 32.5945 179.475 32.8461 179.475 33.1605C179.475 33.475 179.723 33.7266 180.031 33.7266C180.34 33.7266 180.587 33.475 180.587 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M180.587 29.3871C180.587 29.0726 180.34 28.821 180.031 28.821C179.723 28.821 179.475 29.0726 179.475 29.3871C179.475 29.7016 179.723 29.9531 180.031 29.9531C180.34 29.9531 180.587 29.7016 180.587 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M180.587 25.6137C180.587 25.2992 180.34 25.0476 180.031 25.0476C179.723 25.0476 179.475 25.2992 179.475 25.6137C179.475 25.9281 179.723 26.1797 180.031 26.1797C180.34 26.1797 180.587 25.9281 180.587 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M180.587 21.8402C180.587 21.5257 180.34 21.2742 180.031 21.2742C179.723 21.2742 179.475 21.5257 179.475 21.8402C179.475 22.1547 179.723 22.4062 180.031 22.4062C180.34 22.4062 180.587 22.1547 180.587 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M180.587 18.0668C180.587 17.7523 180.34 17.5007 180.031 17.5007C179.723 17.5007 179.475 17.7523 179.475 18.0668C179.475 18.3812 179.723 18.6328 180.031 18.6328C180.34 18.6328 180.587 18.3812 180.587 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M184.294 36.934C184.294 36.6195 184.047 36.3679 183.738 36.3679C183.429 36.3679 183.182 36.6195 183.182 36.934C183.182 37.2484 183.429 37.5 183.738 37.5C184.047 37.5 184.294 37.2484 184.294 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M184.294 33.1605C184.294 32.8461 184.047 32.5945 183.738 32.5945C183.429 32.5945 183.182 32.8461 183.182 33.1605C183.182 33.475 183.429 33.7266 183.738 33.7266C184.047 33.7266 184.294 33.475 184.294 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M184.294 29.3871C184.294 29.0726 184.047 28.821 183.738 28.821C183.429 28.821 183.182 29.0726 183.182 29.3871C183.182 29.7016 183.429 29.9531 183.738 29.9531C184.047 29.9531 184.294 29.7016 184.294 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M184.294 25.6137C184.294 25.2992 184.047 25.0476 183.738 25.0476C183.429 25.0476 183.182 25.2992 183.182 25.6137C183.182 25.9281 183.429 26.1797 183.738 26.1797C184.047 26.1797 184.294 25.9281 184.294 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M184.294 21.8402C184.294 21.5257 184.047 21.2742 183.738 21.2742C183.429 21.2742 183.182 21.5257 183.182 21.8402C183.182 22.1547 183.429 22.4062 183.738 22.4062C184.047 22.4062 184.294 22.1547 184.294 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M184.294 18.0668C184.294 17.7523 184.047 17.5007 183.738 17.5007C183.429 17.5007 183.182 17.7523 183.182 18.0668C183.182 18.3812 183.429 18.6328 183.738 18.6328C184.047 18.6328 184.294 18.3812 184.294 18.0668Z"
                            fill="white"
                        />
                        <path
                            d="M188 36.934C188 36.6195 187.753 36.3679 187.444 36.3679C187.135 36.3679 186.888 36.6195 186.888 36.934C186.888 37.2484 187.135 37.5 187.444 37.5C187.753 37.5 188 37.2484 188 36.934Z"
                            fill="white"
                        />
                        <path
                            d="M188 33.1605C188 32.8461 187.753 32.5945 187.444 32.5945C187.135 32.5945 186.888 32.8461 186.888 33.1605C186.888 33.475 187.135 33.7266 187.444 33.7266C187.753 33.7266 188 33.475 188 33.1605Z"
                            fill="white"
                        />
                        <path
                            d="M188 29.3871C188 29.0726 187.753 28.821 187.444 28.821C187.135 28.821 186.888 29.0726 186.888 29.3871C186.888 29.7016 187.135 29.9531 187.444 29.9531C187.753 29.9531 188 29.7016 188 29.3871Z"
                            fill="white"
                        />
                        <path
                            d="M188 25.6137C188 25.2992 187.753 25.0476 187.444 25.0476C187.135 25.0476 186.888 25.2992 186.888 25.6137C186.888 25.9281 187.135 26.1797 187.444 26.1797C187.753 26.1797 188 25.9281 188 25.6137Z"
                            fill="white"
                        />
                        <path
                            d="M188 21.8402C188 21.5257 187.753 21.2742 187.444 21.2742C187.135 21.2742 186.888 21.5257 186.888 21.8402C186.888 22.1547 187.135 22.4062 187.444 22.4062C187.753 22.4062 188 22.1547 188 21.8402Z"
                            fill="white"
                        />
                        <path
                            d="M188 18.0668C188 17.7523 187.753 17.5007 187.444 17.5007C187.135 17.5007 186.888 17.7523 186.888 18.0668C186.888 18.3812 187.135 18.6328 187.444 18.6328C187.753 18.6328 188 18.3812 188 18.0668Z"
                            fill="white"
                        />
                    </g>
                    <ellipse cx="38.4643" cy="31.0964" rx="14.4643" ry="14.0964" fill="#EB4F5E" />
                    <ellipse cx="59.3571" cy="31.0964" rx="14.4643" ry="14.0964" fill="#F8AD13" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M48.9107 40.8456C51.3994 38.3137 52.9285 34.8787 52.9285 31.0957C52.9285 27.3126 51.3994 23.8776 48.9107 21.3457C46.4219 23.8776 44.8928 27.3126 44.8928 31.0957C44.8928 34.8787 46.4219 38.3137 48.9107 40.8456Z"
                        fill="#F9772A"
                    />
                    <path
                        d="M28.2266 50.7734L28.2461 51.2422C28.556 50.8776 28.974 50.6953 29.5 50.6953C30.0911 50.6953 30.4935 50.9219 30.707 51.375C30.8477 51.1719 31.0299 51.0078 31.2539 50.8828C31.4805 50.7578 31.7474 50.6953 32.0547 50.6953C32.9818 50.6953 33.4531 51.1862 33.4688 52.168V55H32.7461V52.2109C32.7461 51.9089 32.6771 51.6836 32.5391 51.5352C32.401 51.3841 32.1693 51.3086 31.8438 51.3086C31.5755 51.3086 31.3529 51.3893 31.1758 51.5508C30.9987 51.7096 30.8958 51.9245 30.8672 52.1953V55H30.1406V52.2305C30.1406 51.6159 29.8398 51.3086 29.2383 51.3086C28.7643 51.3086 28.4401 51.5104 28.2656 51.9141V55H27.543V50.7734H28.2266Z"
                        fill="white"
                    />
                    <path
                        d="M37.1719 55C37.1302 54.9167 37.0964 54.7682 37.0703 54.5547C36.7344 54.9036 36.3333 55.0781 35.8672 55.0781C35.4505 55.0781 35.1081 54.9609 34.8398 54.7266C34.5742 54.4896 34.4414 54.1901 34.4414 53.8281C34.4414 53.388 34.6081 53.0469 34.9414 52.8047C35.2773 52.5599 35.7487 52.4375 36.3555 52.4375H37.0586V52.1055C37.0586 51.8529 36.9831 51.6523 36.832 51.5039C36.681 51.3529 36.4583 51.2773 36.1641 51.2773C35.9062 51.2773 35.6901 51.3424 35.5156 51.4727C35.3411 51.6029 35.2539 51.7604 35.2539 51.9453H34.5273C34.5273 51.7344 34.6016 51.5312 34.75 51.3359C34.901 51.138 35.1042 50.9818 35.3594 50.8672C35.6172 50.7526 35.8997 50.6953 36.207 50.6953C36.694 50.6953 37.0755 50.8177 37.3516 51.0625C37.6276 51.3047 37.7708 51.6393 37.7812 52.0664V54.0117C37.7812 54.3997 37.8307 54.7083 37.9297 54.9375V55H37.1719ZM35.9727 54.4492C36.1992 54.4492 36.4141 54.3906 36.6172 54.2734C36.8203 54.1562 36.9674 54.0039 37.0586 53.8164V52.9492H36.4922C35.6068 52.9492 35.1641 53.2083 35.1641 53.7266C35.1641 53.9531 35.2396 54.1302 35.3906 54.2578C35.5417 54.3854 35.7357 54.4492 35.9727 54.4492Z"
                        fill="white"
                    />
                    <path
                        d="M41.375 53.8789C41.375 53.6836 41.3008 53.5326 41.1523 53.4258C41.0065 53.3164 40.75 53.2227 40.3828 53.1445C40.0182 53.0664 39.7279 52.9727 39.5117 52.8633C39.2982 52.7539 39.1393 52.6237 39.0352 52.4727C38.9336 52.3216 38.8828 52.1419 38.8828 51.9336C38.8828 51.5872 39.0286 51.2943 39.3203 51.0547C39.6146 50.8151 39.9896 50.6953 40.4453 50.6953C40.9245 50.6953 41.3125 50.819 41.6094 51.0664C41.9089 51.3138 42.0586 51.6302 42.0586 52.0156H41.332C41.332 51.8177 41.2474 51.6471 41.0781 51.5039C40.9115 51.3607 40.7005 51.2891 40.4453 51.2891C40.1823 51.2891 39.9766 51.3464 39.8281 51.4609C39.6797 51.5755 39.6055 51.7253 39.6055 51.9102C39.6055 52.0846 39.6745 52.2161 39.8125 52.3047C39.9505 52.3932 40.1992 52.4779 40.5586 52.5586C40.9206 52.6393 41.2135 52.7357 41.4375 52.8477C41.6615 52.9596 41.8268 53.0951 41.9336 53.2539C42.043 53.4102 42.0977 53.6016 42.0977 53.8281C42.0977 54.2057 41.9466 54.5091 41.6445 54.7383C41.3424 54.9648 40.9505 55.0781 40.4688 55.0781C40.1302 55.0781 39.8307 55.0182 39.5703 54.8984C39.3099 54.7786 39.1055 54.612 38.957 54.3984C38.8112 54.1823 38.7383 53.9492 38.7383 53.6992H39.4609C39.474 53.9414 39.5703 54.1341 39.75 54.2773C39.9323 54.418 40.1719 54.4883 40.4688 54.4883C40.7422 54.4883 40.9609 54.4336 41.125 54.3242C41.2917 54.2122 41.375 54.0638 41.375 53.8789Z"
                        fill="white"
                    />
                    <path
                        d="M44.0195 49.75V50.7734H44.8086V51.332H44.0195V53.9531C44.0195 54.1224 44.0547 54.25 44.125 54.3359C44.1953 54.4193 44.3151 54.4609 44.4844 54.4609C44.5677 54.4609 44.6823 54.4453 44.8281 54.4141V55C44.638 55.0521 44.4531 55.0781 44.2734 55.0781C43.9505 55.0781 43.707 54.9805 43.543 54.7852C43.3789 54.5898 43.2969 54.3125 43.2969 53.9531V51.332H42.5273V50.7734H43.2969V49.75H44.0195Z"
                        fill="white"
                    />
                    <path
                        d="M47.4102 55.0781C46.8372 55.0781 46.3711 54.8906 46.0117 54.5156C45.6523 54.138 45.4727 53.6341 45.4727 53.0039V52.8711C45.4727 52.4518 45.5521 52.0781 45.7109 51.75C45.8724 51.4193 46.0964 51.1615 46.3828 50.9766C46.6719 50.7891 46.9844 50.6953 47.3203 50.6953C47.8698 50.6953 48.2969 50.8763 48.6016 51.2383C48.9062 51.6003 49.0586 52.1185 49.0586 52.793V53.0938H46.1953C46.2057 53.5104 46.3268 53.8477 46.5586 54.1055C46.793 54.3607 47.0898 54.4883 47.4492 54.4883C47.7044 54.4883 47.9206 54.4362 48.0977 54.332C48.2747 54.2279 48.4297 54.0898 48.5625 53.918L49.0039 54.2617C48.6497 54.806 48.1185 55.0781 47.4102 55.0781ZM47.3203 51.2891C47.0286 51.2891 46.7839 51.3958 46.5859 51.6094C46.388 51.8203 46.2656 52.1172 46.2188 52.5H48.3359V52.4453C48.3151 52.0781 48.2161 51.7943 48.0391 51.5938C47.862 51.3906 47.6224 51.2891 47.3203 51.2891Z"
                        fill="white"
                    />
                    <path
                        d="M51.9414 51.4219C51.832 51.4036 51.7135 51.3945 51.5859 51.3945C51.112 51.3945 50.7904 51.5964 50.6211 52V55H49.8984V50.7734H50.6016L50.6133 51.2617C50.8503 50.8841 51.1862 50.6953 51.6211 50.6953C51.7617 50.6953 51.8685 50.7135 51.9414 50.75V51.4219Z"
                        fill="white"
                    />
                    <path
                        d="M56.2891 54.4883C56.5469 54.4883 56.7721 54.4102 56.9648 54.2539C57.1576 54.0977 57.2643 53.9023 57.2852 53.668H57.9688C57.9557 53.9102 57.8724 54.1406 57.7188 54.3594C57.5651 54.5781 57.3594 54.7526 57.1016 54.8828C56.8464 55.013 56.5755 55.0781 56.2891 55.0781C55.7135 55.0781 55.2552 54.8867 54.9141 54.5039C54.5755 54.1185 54.4062 53.5924 54.4062 52.9258V52.8047C54.4062 52.3932 54.4818 52.0273 54.6328 51.707C54.7839 51.3867 55 51.138 55.2812 50.9609C55.5651 50.7839 55.8997 50.6953 56.2852 50.6953C56.7591 50.6953 57.1523 50.8372 57.4648 51.1211C57.7799 51.4049 57.9479 51.7734 57.9688 52.2266H57.2852C57.2643 51.9531 57.1602 51.7292 56.9727 51.5547C56.7878 51.3776 56.5586 51.2891 56.2852 51.2891C55.918 51.2891 55.6328 51.4219 55.4297 51.6875C55.2292 51.9505 55.1289 52.332 55.1289 52.832V52.9688C55.1289 53.4557 55.2292 53.8307 55.4297 54.0938C55.6302 54.3568 55.9167 54.4883 56.2891 54.4883Z"
                        fill="white"
                    />
                    <path
                        d="M61.3906 55C61.349 54.9167 61.3151 54.7682 61.2891 54.5547C60.9531 54.9036 60.5521 55.0781 60.0859 55.0781C59.6693 55.0781 59.3268 54.9609 59.0586 54.7266C58.793 54.4896 58.6602 54.1901 58.6602 53.8281C58.6602 53.388 58.8268 53.0469 59.1602 52.8047C59.4961 52.5599 59.9674 52.4375 60.5742 52.4375H61.2773V52.1055C61.2773 51.8529 61.2018 51.6523 61.0508 51.5039C60.8997 51.3529 60.6771 51.2773 60.3828 51.2773C60.125 51.2773 59.9089 51.3424 59.7344 51.4727C59.5599 51.6029 59.4727 51.7604 59.4727 51.9453H58.7461C58.7461 51.7344 58.8203 51.5312 58.9688 51.3359C59.1198 51.138 59.3229 50.9818 59.5781 50.8672C59.8359 50.7526 60.1185 50.6953 60.4258 50.6953C60.9128 50.6953 61.2943 50.8177 61.5703 51.0625C61.8464 51.3047 61.9896 51.6393 62 52.0664V54.0117C62 54.3997 62.0495 54.7083 62.1484 54.9375V55H61.3906ZM60.1914 54.4492C60.418 54.4492 60.6328 54.3906 60.8359 54.2734C61.0391 54.1562 61.1862 54.0039 61.2773 53.8164V52.9492H60.7109C59.8255 52.9492 59.3828 53.2083 59.3828 53.7266C59.3828 53.9531 59.4583 54.1302 59.6094 54.2578C59.7604 54.3854 59.9544 54.4492 60.1914 54.4492Z"
                        fill="white"
                    />
                    <path
                        d="M65.1758 51.4219C65.0664 51.4036 64.9479 51.3945 64.8203 51.3945C64.3464 51.3945 64.0247 51.5964 63.8555 52V55H63.1328V50.7734H63.8359L63.8477 51.2617C64.0846 50.8841 64.4206 50.6953 64.8555 50.6953C64.9961 50.6953 65.1029 50.7135 65.1758 50.75V51.4219Z"
                        fill="white"
                    />
                    <path
                        d="M65.5898 52.8516C65.5898 52.2031 65.7435 51.6823 66.0508 51.2891C66.3581 50.8932 66.7604 50.6953 67.2578 50.6953C67.7526 50.6953 68.1445 50.8646 68.4336 51.2031V49H69.1562V55H68.4922L68.457 54.5469C68.168 54.901 67.7656 55.0781 67.25 55.0781C66.7604 55.0781 66.3607 54.8776 66.0508 54.4766C65.7435 54.0755 65.5898 53.5521 65.5898 52.9062V52.8516ZM66.3125 52.9336C66.3125 53.4128 66.4115 53.7878 66.6094 54.0586C66.8073 54.3294 67.0807 54.4648 67.4297 54.4648C67.888 54.4648 68.2227 54.2591 68.4336 53.8477V51.9062C68.2174 51.5078 67.8854 51.3086 67.4375 51.3086C67.0833 51.3086 66.8073 51.4453 66.6094 51.7188C66.4115 51.9922 66.3125 52.3971 66.3125 52.9336Z"
                        fill="white"
                    />
                </g>
                <g opacity="0.07">
                    <path
                        d="M230 120C213.45 120 197.84 113.543 186.18 101.883C174.457 90.1598 168 74.55 168 58C168 41.45 174.457 25.8402 186.117 14.18C197.84 2.45703 213.45 -4 230 -4C246.55 -4 262.16 2.45703 273.82 14.1173C285.48 25.7776 291.937 41.3873 291.937 57.9373C291.937 74.4874 285.48 90.0971 273.82 101.757C262.16 113.418 246.55 120 230 120ZM230 -3.68655C195.96 -3.68655 168.313 23.9596 168.313 58C168.313 92.0404 195.96 119.687 230 119.687C264.04 119.687 291.687 92.0404 291.687 58C291.687 23.9596 264.04 -3.68655 230 -3.68655Z"
                        fill="white"
                    />
                    <path
                        d="M230 112.289C200.097 112.289 175.711 87.9657 175.711 58.0001C175.711 28.0345 200.034 3.71094 230 3.71094C259.903 3.71094 284.289 28.0345 284.289 58.0001C284.289 87.9657 259.903 112.289 230 112.289ZM230 4.02439C200.222 4.02439 176.024 28.2226 176.024 58.0001C176.024 87.7777 200.222 111.976 230 111.976C259.778 111.976 283.976 87.7777 283.976 58.0001C283.976 28.2226 259.778 4.02439 230 4.02439Z"
                        fill="white"
                    />
                    <path
                        d="M230 104.516C204.36 104.516 183.484 83.6401 183.484 58C183.484 32.36 204.36 11.4844 230 11.4844C255.64 11.4844 276.516 32.36 276.516 58C276.516 83.6401 255.64 104.516 230 104.516ZM230 11.7978C204.485 11.7978 183.798 32.5481 183.798 58C183.798 83.452 204.548 104.202 230 104.202C255.452 104.202 276.202 83.452 276.202 58C276.202 32.5481 255.515 11.7978 230 11.7978Z"
                        fill="white"
                    />
                    <path
                        d="M230 96.805C208.623 96.805 191.195 79.3773 191.195 58.0002C191.195 36.623 208.623 19.1953 230 19.1953C251.377 19.1953 268.805 36.623 268.805 58.0002C268.805 79.3773 251.377 96.805 230 96.805ZM230 19.5088C208.748 19.5088 191.509 36.7484 191.509 58.0002C191.509 79.2519 208.748 96.4916 230 96.4916C251.252 96.4916 268.491 79.2519 268.491 58.0002C268.491 36.7484 251.252 19.5088 230 19.5088Z"
                        fill="white"
                    />
                    <path
                        d="M230 89.0943C212.886 89.0943 198.906 75.1772 198.906 58.0003C198.906 40.886 212.823 26.9062 230 26.9062C247.114 26.9062 261.094 40.8233 261.094 58.0003C261.094 75.1145 247.114 89.0943 230 89.0943ZM230 27.2197C213.011 27.2197 199.219 41.0114 199.219 58.0003C199.219 74.9892 213.011 88.7809 230 88.7809C246.989 88.7809 260.781 74.9892 260.781 58.0003C260.781 41.0114 246.989 27.2197 230 27.2197Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="master0">
                    <rect width="225" height="130" fill="white" />
                </clipPath>
                <clipPath id="master1">
                    <rect width="225" height="130" rx="12" fill="white" />
                </clipPath>
                <clipPath id="master2">
                    <rect width="20" height="53" fill="white" transform="translate(135 37.5) rotate(-90)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(MasterSVG);
