import React from 'react';

function ProjectMemberSVG({ ...other }) {
    return (
        <svg {...other} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.22656 27.8258L9.23438 25.3625C9.95312 24.7838 10.4062 23.9079 10.4062 22.9148V21.3117C10.4062 19.5756 9 18.1758 7.27344 18.1758C5.54687 18.1758 4.14062 19.5834 4.14062 21.3117V22.9148C4.14062 23.9079 4.60156 24.7916 5.32031 25.3625V27.818L0.90625 31.6186C0.773438 31.7359 0.695312 31.9079 0.695312 32.0878V34.1289C0.695312 34.3947 0.890625 34.6294 1.14844 34.6763C2.96094 35.0047 4.79688 35.1846 6.63281 35.2159L13.9922 34.9031V31.8063L9.22656 27.8258Z"
                fill="white"
            />
            <path
                d="M2.78906 34.4886V32.4554C2.78906 32.2755 2.86719 32.1034 3 31.9861L6.97656 28.5609C7.25781 28.3185 7.41406 27.9744 7.41406 27.599V26.2853C7.41406 25.9646 7.29687 25.644 7.07812 25.4094C6.55469 24.8464 6.23438 24.0956 6.23438 23.2745V21.6714C6.23438 20.1308 7.34375 18.8483 8.8125 18.5824C8.35938 18.3244 7.83594 18.1758 7.27344 18.1758C5.53906 18.1758 4.14062 19.5834 4.14062 21.3117V22.9148C4.14062 23.9079 4.60156 24.7916 5.32031 25.3625V27.818L0.90625 31.6186C0.773438 31.7359 0.695312 31.9079 0.695312 32.0878V34.2149C0.695312 34.4651 0.898438 34.6684 1.14844 34.6684C1.78906 34.7858 2.42969 34.8796 3.07031 34.9578C2.89844 34.8718 2.78906 34.6919 2.78906 34.4886Z"
                fill="white"
            />
            <path
                d="M31.3359 10.5097L32.2812 12.3474C32.4688 12.7071 32.9844 12.715 33.1719 12.3552L34.1484 10.5097H34.4531C37.1875 10.5097 39.4062 8.28877 39.4062 5.55172C39.4062 2.81468 37.1953 0.585938 34.4609 0.585938H5.53906C2.80469 0.585938 0.585938 2.80686 0.585938 5.5439C0.585938 8.28095 2.80469 10.5019 5.53906 10.5019H5.84375L6.82031 12.3474C7.00781 12.7071 7.52344 12.6993 7.71094 12.3396L8.65625 10.5019H31.3359V10.5097Z"
                fill="white"
            />
            <path
                d="M13.0547 10.4471H12.75C10.0156 10.4471 7.79688 8.22621 7.79688 5.48916C7.79688 3.01018 9.61719 0.953484 11.9922 0.585938H5.53906C2.80469 0.585938 0.585938 2.80686 0.585938 5.5439C0.585938 8.28095 2.80469 10.5019 5.53906 10.5019H5.84375L6.82031 12.3474C7.00781 12.7071 7.52344 12.6993 7.71094 12.3396L8.65625 10.5019H13.0703L13.0547 10.4471Z"
                fill="white"
            />
            <path
                d="M9.13161 5.09304C9.04567 5.62481 9.4988 6.08619 10.0379 6.00017C10.3582 5.94543 10.6238 5.68737 10.6785 5.35892C10.7644 4.82715 10.3113 4.36577 9.77224 4.45179C9.44411 4.51435 9.1863 4.77241 9.13161 5.09304Z"
                fill="#4D3F8A"
            />
            <path
                d="M15.8359 5.22342C15.8359 5.65352 16.1875 6.00543 16.6172 6.00543C17.0469 6.00543 17.3984 5.65352 17.3984 5.22342C17.3984 4.79331 17.0469 4.44141 16.6172 4.44141C16.1875 4.44141 15.8359 4.79331 15.8359 5.22342Z"
                fill="#4D3F8A"
            />
            <path
                d="M30.875 5.22342C30.875 4.79331 30.5234 4.44141 30.0938 4.44141C29.6641 4.44141 29.3125 4.79331 29.3125 5.22342C29.3125 5.65352 29.6641 6.00543 30.0938 6.00543C30.5312 6.00543 30.875 5.66135 30.875 5.22342Z"
                fill="#4D3F8A"
            />
            <path
                d="M22.6016 5.22342C22.6016 5.65352 22.9531 6.00543 23.3828 6.00543C23.8125 6.00543 24.1641 5.65352 24.1641 5.22342C24.1641 4.79331 23.8125 4.44141 23.3828 4.44141C22.9531 4.44141 22.6016 4.79331 22.6016 5.22342Z"
                fill="#4D3F8A"
            />
            <path
                d="M38.875 30.9425L34.7266 27.4625L34.7344 24.9992C35.4531 24.4205 35.9063 23.5447 35.9063 22.5515V20.9484C35.9063 19.2123 34.5 17.8125 32.7734 17.8125C31.0469 17.8125 29.6406 19.2201 29.6406 20.9484V22.5515C29.6406 23.5447 30.1016 24.4283 30.8203 24.9992V27.4547L26.4063 31.2553C26.2734 31.3726 26.1953 31.5446 26.1953 31.7245V33.8516C26.1953 34.1018 26.3984 34.3052 26.6484 34.3052C28.4609 34.6336 30.2969 34.8135 32.1328 34.8447L38.4297 34.5789C39.0234 34.5554 39.4922 34.0627 39.4922 33.4684V32.225C39.4766 31.7323 39.2578 31.2553 38.875 30.9425Z"
                fill="white"
            />
            <path
                d="M28.75 34.5554C28.5 34.5554 28.2969 34.3521 28.2969 34.1018V31.9748C28.2969 31.7949 28.375 31.6228 28.5078 31.5055L32.4219 28.1273C32.7344 27.8535 32.9219 27.4547 32.9219 27.0403V25.8516C32.9219 25.4997 32.7891 25.1556 32.5547 24.8897C32.0547 24.3345 31.7422 23.5916 31.7422 22.7861V21.1908C31.7422 19.705 32.7734 18.4616 34.1562 18.1409C33.7344 17.9298 33.2656 17.8125 32.7578 17.8125C31.0234 17.8125 29.625 19.2201 29.625 20.9484V22.5515C29.625 23.5447 30.0859 24.4283 30.8047 24.9992V27.4547L26.3906 31.2553C26.2578 31.3726 26.1797 31.5446 26.1797 31.7245V33.8516C26.1797 34.1018 26.3828 34.3052 26.6328 34.3052C27.7109 34.5007 28.7891 34.6414 29.8828 34.7274C29.5078 34.6805 29.1328 34.618 28.75 34.5554Z"
                fill="white"
            />
            <path
                d="M30.9844 36.0177V38.7704C30.9844 39.1301 30.6953 39.4194 30.3359 39.4194H9.58594C9.22656 39.4194 8.9375 39.1301 8.9375 38.7704V36.0177C8.9375 34.047 9.84375 32.1858 11.3984 30.9815L17.0391 26.5944L17.5938 26.0704L22.2109 25.9297L22.8828 26.5944L28.5234 30.9815C30.0781 32.1858 30.9844 34.047 30.9844 36.0177Z"
                fill="#FFE777"
            />
            <path
                d="M22.8828 26.5885C22.8828 28.1994 21.5781 29.5132 19.9609 29.5132C18.3437 29.5132 17.0391 28.2073 17.0391 26.5885V20.0039H22.8828V26.5885Z"
                fill="#FFE5C2"
            />
            <path
                d="M26.1172 14.8983V13.4829C26.1172 11.3558 24.3906 9.62757 22.2656 9.62757H16.9375L16.4297 8.65787C16.3203 8.45455 16.0625 8.38416 15.8672 8.51711C14.6953 9.30694 13.9844 10.6285 13.9844 12.0518V14.8983L15.2969 18.5347H24.6953L26.1172 14.8983Z"
                fill="#392B76"
            />
            <path
                d="M13.9844 14.8971V18.4865L14.8672 18.6742L15.25 21.5207L19.6172 24.2187L21.4531 24.422L22.8594 24.2187C24.7969 23.202 26.1172 21.1766 26.1172 18.8384V14.9049C22.0469 14.1463 18.0078 14.1385 13.9844 14.8971Z"
                fill="#FFE9E0"
            />
            <path
                d="M17.0391 18.1559V14.4648C16.0234 14.5587 15 14.6994 13.9844 14.8871V18.8207C13.9844 22.1755 16.7031 24.8891 20.0469 24.8891C21.0547 24.8891 22.0234 24.631 22.8594 24.2009C19.6328 24.068 17.0391 21.4169 17.0391 18.1559Z"
                fill="#FFD0BC"
            />
            <path
                d="M28.8828 30.5174L23.4687 26.3101V24.5506C25.4062 23.3854 26.7031 21.2661 26.7031 18.8419V13.4929C26.7031 11.0452 24.7109 9.05108 22.2656 9.05108H17.2969L16.9531 8.39419C16.8281 8.15177 16.6016 7.9719 16.3281 7.90152C16.0625 7.83114 15.7734 7.88588 15.5469 8.03446C14.2031 8.93378 13.4063 10.4431 13.4063 12.0618V18.8419C13.4063 19.5144 13.5078 20.1791 13.7031 20.8126C13.7969 21.1254 14.125 21.2974 14.4375 21.1958C14.75 21.1019 14.9219 20.7735 14.8203 20.4607C14.6562 19.9367 14.5781 19.3893 14.5781 18.8341V15.3854C18.1797 14.7676 21.8594 14.7676 25.5391 15.3854V18.8263C25.5391 21.8526 23.0781 24.3082 20.0625 24.3082C18.4766 24.3082 16.9688 23.62 15.9297 22.4235C15.7187 22.1811 15.3438 22.1498 15.1016 22.3688C14.8594 22.5799 14.8281 22.9553 15.0469 23.1977C15.4688 23.6826 15.9453 24.0892 16.4688 24.4255V26.3023L11.0547 30.5095C9.34375 31.839 8.36719 33.8409 8.36719 36.0149V39.4167C8.36719 39.7373 8.63281 40.0032 8.95312 40.0032C9.27344 40.0032 9.53906 39.7373 9.53906 39.4167V36.0149C9.53906 34.2163 10.3516 32.5428 11.7734 31.4401L16.6328 27.663C17.0859 29.0785 18.4141 30.1029 19.9766 30.1029C21.5391 30.1029 22.8672 29.0785 23.3203 27.663L28.1797 31.4401C29.6016 32.5428 30.4141 34.2163 30.4141 36.0149V39.4167C30.4141 39.7373 30.6797 40.0032 31 40.0032C31.3203 40.0032 31.5859 39.7373 31.5859 39.4167V36.0149C31.5703 33.8487 30.5938 31.8468 28.8828 30.5174ZM14.5703 14.1967V12.054C14.5703 10.9045 15.1094 9.82527 16.0156 9.12928L16.4141 9.89566C16.5156 10.0912 16.7188 10.2085 16.9297 10.2085H22.2656C24.0703 10.2085 25.5312 11.6786 25.5312 13.4773V14.1967C21.8516 13.6024 18.1797 13.6024 14.5703 14.1967ZM19.9609 28.9299C18.6719 28.9299 17.625 27.882 17.625 26.5917V25.0276C18.3906 25.3248 19.2109 25.489 20.0469 25.489C20.8359 25.489 21.5937 25.3482 22.2891 25.098V26.5917C22.2969 27.882 21.25 28.9299 19.9609 28.9299Z"
                fill="#392B76"
            />
            <path
                d="M13.3516 35.8008C13.0312 35.8008 12.7656 36.0667 12.7656 36.3873V39.4137C12.7656 39.7343 13.0312 40.0002 13.3516 40.0002C13.6719 40.0002 13.9375 39.7343 13.9375 39.4137V36.3873C13.9375 36.0667 13.6719 35.8008 13.3516 35.8008Z"
                fill="#4D3F8A"
            />
            <path
                d="M26.5703 35.8008C26.25 35.8008 25.9844 36.0667 25.9844 36.3873V39.4137C25.9844 39.7343 26.25 40.0002 26.5703 40.0002C26.8906 40.0002 27.1562 39.7343 27.1562 39.4137V36.3873C27.1562 36.0667 26.8984 35.8008 26.5703 35.8008Z"
                fill="#4D3F8A"
            />
            <path
                d="M1.28125 32.0688L5.69531 28.2682C5.82812 28.1588 5.89844 27.9945 5.89844 27.8225V26.3758C6.32031 26.5478 6.78906 26.6416 7.27344 26.6416C7.75781 26.6416 8.21875 26.5478 8.64844 26.3758V27.8225C8.64844 27.9945 8.72656 28.1588 8.85156 28.2682L9.99219 29.2536C10.1016 29.3474 10.2422 29.3943 10.375 29.3943C10.5391 29.3943 10.7031 29.324 10.8203 29.191C11.0312 28.9486 11.0078 28.5732 10.7578 28.3621L9.82031 27.5566V25.6328C10.5469 24.9525 11 23.9906 11 22.9193V21.3161C11 19.2594 9.32812 17.5938 7.28125 17.5938C5.22656 17.5938 3.5625 19.2673 3.5625 21.3161V22.9114C3.5625 23.9828 4.01562 24.9447 4.74219 25.625V27.5488L0.523437 31.1851C0.257812 31.4119 0.101562 31.7482 0.101562 32.1001V34.1412C0.101562 34.6964 0.5 35.1656 1.03906 35.2673C2.86719 35.5957 4.74219 35.7834 6.61719 35.8147H6.625C6.94531 35.8147 7.20312 35.5566 7.21094 35.236C7.21875 34.9154 6.96094 34.6416 6.63281 34.6416C4.83594 34.6104 3.03125 34.4305 1.26562 34.1177V32.1079C1.27344 32.0845 1.28125 32.0766 1.28125 32.0688ZM4.71875 21.3161C4.71875 19.9085 5.85938 18.7668 7.26562 18.7668C8.67188 18.7668 9.8125 19.9085 9.8125 21.3161V22.9114C9.8125 24.3191 8.67188 25.4608 7.26562 25.4608C5.85938 25.4608 4.71875 24.3191 4.71875 22.9114V21.3161Z"
                fill="#4D3F8A"
            />
            <path
                d="M34.2031 27.8225C34.2031 27.9945 34.2812 28.1588 34.4062 28.2682L35.375 29.105C35.4844 29.1988 35.625 29.2458 35.7578 29.2458C35.9219 29.2458 36.0859 29.1754 36.2031 29.0424C36.4141 28.8 36.3906 28.4246 36.1406 28.2135L35.375 27.5566V25.6328C36.1016 24.9525 36.5547 23.9906 36.5547 22.9193V21.3161C36.5547 19.2594 34.8828 17.5938 32.8359 17.5938C30.7812 17.5938 29.1172 19.2673 29.1172 21.3161V22.9114C29.1172 23.9828 29.5703 24.9447 30.2969 25.625V27.5488L29.3594 28.3543C29.1172 28.5654 29.0859 28.9329 29.2969 29.1832C29.4141 29.3161 29.5781 29.3865 29.7422 29.3865C29.875 29.3865 30.0156 29.3396 30.125 29.2458L31.2656 28.2604C31.3984 28.1509 31.4687 27.9867 31.4687 27.8147V26.3679C31.8906 26.54 32.3594 26.6338 32.8437 26.6338C33.3281 26.6338 33.7891 26.54 34.2188 26.3679L34.2031 27.8225ZM32.8281 25.4686C31.4219 25.4686 30.2812 24.3269 30.2812 22.9193V21.3161C30.2812 19.9085 31.4219 18.7668 32.8281 18.7668C34.2344 18.7668 35.375 19.9085 35.375 21.3161V22.9114C35.3828 24.3191 34.2344 25.4686 32.8281 25.4686Z"
                fill="#4D3F8A"
            />
            <path
                d="M39.5781 31.1875L38.0625 29.8815C37.8203 29.6704 37.4453 29.6938 37.2344 29.9441C37.0234 30.1865 37.0469 30.5619 37.2969 30.773L38.8125 32.079C38.8203 32.0868 38.8203 32.0946 38.8203 32.1024V34.1122C37.0625 34.425 35.2578 34.6049 33.4531 34.6362C33.1328 34.644 32.875 34.9099 32.875 35.2305C32.8828 35.5511 33.1406 35.8092 33.4609 35.8092H33.4688C35.3359 35.7779 37.2109 35.5902 39.0469 35.2618C39.5938 35.1601 39.9844 34.6909 39.9844 34.1357V32.0946C40 31.7505 39.8438 31.4143 39.5781 31.1875Z"
                fill="#4D3F8A"
            />
            <path
                d="M34.4609 9.92427H34.1562C33.9375 9.92427 33.7422 10.0416 33.6406 10.2371L32.7344 11.9419L31.8594 10.2449C31.7578 10.0494 31.5547 9.92427 31.3359 9.92427H27.7031C27.3828 9.92427 27.1172 10.1901 27.1172 10.5108C27.1172 10.8314 27.3828 11.0973 27.7031 11.0973H30.9766L31.7578 12.6144C31.9453 12.9741 32.3125 13.2009 32.7187 13.2009H32.7265C33.1328 13.2009 33.5 12.9819 33.6875 12.6222L34.5 11.0895C37.539 11.066 40 8.58702 40 5.54499C40 4.51273 39.7109 3.49611 39.1718 2.62026C39 2.34655 38.6406 2.26053 38.3672 2.43257C38.0937 2.60462 38.0078 2.96434 38.1797 3.23805C38.6093 3.92622 38.8359 4.72387 38.8359 5.54499C38.8281 7.96141 36.8672 9.92427 34.4609 9.92427Z"
                fill="#4D3F8A"
            />
            <path
                d="M5.5 11.0968L6.3125 12.6295C6.5 12.9892 6.86719 13.2082 7.27344 13.2082H7.28125C7.6875 13.2082 8.05469 12.9814 8.24219 12.6217L9.02344 11.1046H11.8281C12.1484 11.1046 12.4141 10.8387 12.4141 10.5181C12.4141 10.1975 12.1484 9.93157 11.8281 9.93157H8.67188C8.45312 9.93157 8.25 10.0567 8.14844 10.2522L7.27344 11.9492L6.36719 10.2444C6.26562 10.0567 6.07031 9.93157 5.85156 9.93157H5.54688C3.14062 9.93157 1.17969 7.96872 1.17969 5.56012C1.17969 3.15151 3.13281 1.17302 5.53906 1.17302H34.4531C35.1875 1.17302 35.9141 1.3607 36.5547 1.71261C36.8359 1.86901 37.1953 1.76735 37.3516 1.47801C37.5078 1.18866 37.4062 0.836755 37.1172 0.680352C36.3125 0.234604 35.3906 0 34.4609 0H5.53906C2.48438 0 0 2.4868 0 5.54448C0 8.59433 2.46094 11.0733 5.5 11.0968Z"
                fill="#4D3F8A"
            />
        </svg>
    );
}

export default React.memo(ProjectMemberSVG);
