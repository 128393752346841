export const checkContainId = (e, id) => {
    const paths = e?.path || (e?.composedPath && e?.composedPath());
    return paths && paths?.map(path => path?.id)?.includes(id);
};

export const checkContainClassName = (e, className) => {
    const paths = e?.path || (e?.composedPath && e?.composedPath());
    const classes = paths && paths?.map(path => path?.className);

    const filterClassName = classes?.filter(c => {
        if (!c || typeof c !== 'string') return false;
        return c?.includes(className);
    });

    return !!filterClassName?.length;
};

export const checkGridTableContainsActiveElement = () => {
    let gridTable = document.getElementById(`gridUI-table`);
    return gridTable?.contains(document.activeElement);
};
