import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        width: 440,
        background: theme.colors.white
    },
    delete: {
        background: `${theme.colors.snackBarError} !important`
    }
}));

function ConfirmDeleteLocale({ lang, handleCancel, handleAgreed }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container wrap="nowrap" direction="column" spacing={4} className={classes.root}>
            <Grid item>
                <Typography variant="h4">{t('delete_locale_title')}</Typography>
            </Grid>
            <Grid item>
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{
                        __html: `Are you sure you want to remove <strong>${lang}</strong>?
                    All grids which are using <strong>${lang}</strong> will become view-only until you remove <strong>${lang}</strong> columns`
                    }}
                ></Typography>
            </Grid>
            <Grid item>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase onClick={handleCancel} width={100} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={handleAgreed} width={100} variant="contained" className={classes.delete}>
                            {t('global_delete')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default React.memo(ConfirmDeleteLocale);
