import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ColorBucketSVG from 'assets/images/svg/ColorBucketSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import PopperMenu from 'components/menus/Popper';
import Tooltip from 'components/tooltip/Base';
import { FILL_COLOR_OPTIONS } from 'const/gridUI';
import { useFillColorBlindness } from 'hooks/auth';
import { useCurrentColor, useSelectedColor } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import FillColorMenu from '../FillColorMenu';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    fillColor: {
        '&:hover': {
            backgroundColor: theme.colors.ghostwhite
        },
        cursor: 'pointer',
        borderRadius: 4
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    },
    fillColorLeft: {
        height: 32,
        width: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.colors.divider
        },
        borderRadius: '4px 0 0 4px'
    },
    fillColorRight: {
        height: 32,
        width: 17,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.colors.divider
        },
        borderRadius: '0 4px 4px 0'
    }
}));

const DEFAULT_FILL_COLOR = Object.keys(FILL_COLOR_OPTIONS)?.[0];

const ManageFillColor = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const currentCellColor = useCurrentColor();
    const selectedCellColor = useSelectedColor();
    const fillColorBlindness = useFillColorBlindness();
    const [fillColorAnchorEl, setFillColorAnchorEl] = React.useState(null);

    const handleFillColorClickAway = React.useCallback(() => {
        setFillColorAnchorEl(null);
    }, []);

    const handleFillColorClick = React.useCallback(
        event => {
            setFillColorAnchorEl(fillColorAnchorEl ? null : event.currentTarget);
        },
        [fillColorAnchorEl]
    );

    const setColorHandler = () => {
        dispatch(
            gridActions.setCellsColor({
                color: currentCellColor || DEFAULT_FILL_COLOR
            })
        );
        handleFillColorClickAway();
    };

    return (
        <>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION}>
                <Tooltip title={t('toolbar_fill_color_tooltip')}>
                    <Grid item id={'fill-color-panel-trigger'} className={classes.menuItem}>
                        <Grid wrap="nowrap" container alignItems="center" className={`${classes.fillColor}`}>
                            <Grid
                                item
                                tr-dt="Quick Fill Color"
                                className={classes.fillColorLeft}
                                onClick={setColorHandler}
                            >
                                <ColorBucketSVG
                                    height={18}
                                    width={18}
                                    color={theme.colors.darkness}
                                    selectedColor={currentCellColor || DEFAULT_FILL_COLOR}
                                />
                            </Grid>
                            <Grid
                                item
                                tr-dt="Open Fill Color"
                                className={classes.fillColorRight}
                                onClick={handleFillColorClick}
                            >
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Tooltip>
            </AccessControl>
            {fillColorAnchorEl && (
                <PopperMenu
                    id={'popper-fill-color'}
                    placement={'bottom-start'}
                    anchorEl={fillColorAnchorEl}
                    handleClickAway={handleFillColorClickAway}
                    className={`${classes.popperMenu} small`}
                >
                    <FillColorMenu
                        closeHandler={handleFillColorClickAway}
                        selectedCellColor={selectedCellColor}
                        fillColorBlindness={fillColorBlindness}
                    />
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ManageFillColor);
