import React from 'react';

function LockedSVG({ color = '#93929E', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect width="18" height="18" fill={color} />
        </svg>
    );
}

export default React.memo(LockedSVG);
