import React from 'react';

function BitBucketSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.584161 0.89995C0.499995 0.898865 0.416611 0.916216 0.339864 0.950786C0.263117 0.985356 0.194868 1.03631 0.139907 1.10006C0.0849473 1.16381 0.0446087 1.23883 0.0217239 1.31983C-0.00116084 1.40083 -0.00603703 1.48586 0.00743773 1.56895L2.45563 16.4311C2.48613 16.613 2.57974 16.7783 2.72002 16.898C2.8603 17.0177 3.03827 17.0842 3.22267 17.0857H14.9676C15.1059 17.0875 15.2402 17.0395 15.346 16.9506C15.4519 16.8617 15.5223 16.7377 15.5444 16.6012L17.9926 1.57183C18.006 1.48875 18.0012 1.40371 17.9783 1.32271C17.9554 1.24171 17.9151 1.1667 17.8601 1.10294C17.8051 1.03919 17.7369 0.98824 17.6601 0.95367C17.5834 0.9191 17.5 0.901748 17.4158 0.902834L0.584161 0.89995ZM10.8931 11.6414H7.14439L6.12936 6.33845H11.8014L10.8931 11.6414Z"
                fill="#2684FF"
            />
            <path
                d="M17.2147 6.33838H11.8021L10.8938 11.6414H7.1451L2.71875 16.8953C2.85905 17.0166 3.03792 17.0841 3.22338 17.0856H14.9712C15.1095 17.0874 15.2438 17.0395 15.3496 16.9505C15.4555 16.8616 15.5259 16.7376 15.548 16.6012L17.2147 6.33838Z"
                fill="url(#paint0_linear_bitbucket)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_bitbucket"
                    x1="18.4662"
                    y1="7.82344"
                    x2="9.5212"
                    y2="14.8047"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(BitBucketSVG);
