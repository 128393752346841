import React from 'react';
import { getFileName } from 'utils/fileUtils';

import LocalizationImportSetup from './localization/LocalizationImportSetup';
import GridUploadSetup from './upload/GridUploadSetup';

function GridImport({ onClose, dbId, files }) {
    const [isTranslationOn, setIsTranslationOn] = React.useState(true);
    const [gridName, setGridName] = React.useState(getFileName(files?.[0]?.name) || '');

    const onGridNameChange = React.useCallback(
        name => {
            setGridName(name);
        },
        [setGridName]
    );

    return isTranslationOn ? (
        <LocalizationImportSetup
            dbId={dbId}
            gridName={gridName}
            isMulti={false}
            isUploading={false}
            files={files}
            onNameChange={onGridNameChange}
            onClose={onClose}
            onSetupSwitch={() => setIsTranslationOn(false)}
        />
    ) : (
        <GridUploadSetup
            dbId={dbId}
            gridName={gridName}
            onClose={onClose}
            files={files}
            onNameChange={onGridNameChange}
            onSetupSwitch={() => setIsTranslationOn(true)}
        />
    );
}

export default React.memo(GridImport);
