import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getFriendlyDate } from 'utils/datetime';
import { Grid, Popper, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { turnOffFirstPopup, setSelectedGrid } from './actions';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import GridPoppup from './GridPoppup';
import { getGridColorValue } from 'utils/color';
import GridIcon from 'grids/GridIcon';
import { POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM } from 'const/style';
import { changeContext } from 'app/actions';
import { GRID_STATUS } from 'const/gridUI';
import Spinner from 'components/spinner/Base';
import { isTempId } from 'utils/uuid';
import IconLockSVG from 'assets/images/svg/IconLockSVG';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { deleteGrid } from './actions';
import { sendManualTrack } from 'tracker';
import DuplicateGridForm from './DuplicateGridForm';
import CardInfo from 'components/cardInfo/Base';
import { useTranslation, Trans } from 'react-i18next';
import SaveTemplate from './SaveTemplate';
import GridTemplateDetail from 'template/grid/GridTemplateDetail';
import { useGridTemplateDetail, useSelectedGrid } from 'hooks/grid';
import ChooseWorkspaceDatabase from 'layout/ChooseWorkspaceDatabase';
import * as gridActions from 'grids/actions';
import DuplicateAnotherGrid from './DuplicateAnotherGrid';
import OverflowTypography from 'components/textTruncated/OverflowTypography';
// import OutlineStarSVG from 'assets/images/svg/OutlineStarSVG';
import Tooltip from 'components/tooltip/Base';
import FillStarSVG from 'assets/images/svg/FillStarSVG';
import { useFavoriteGridList } from 'hooks/workspace';
import OutlineStarSVG from 'assets/images/svg/OutlineStarSVG';
import { ADD_FAVORITE_GRID, REMOVE_FAVORITE_GRID } from 'grids/types';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        height: 68,
        maxHeight: 68,
        padding: theme.spacing(2),
        borderRadius: 4,
        cursor: 'pointer',
        position: 'relative'
    },

    records: {
        fontSize: 14,
        color: theme.colors.secondaryText
    },
    title: {
        overflow: 'hidden',
        '& p': {
            fontSize: 16,
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: `nowrap`
        },
        '& input': {
            fontSize: 16,
            fontWeight: 500,
            color: theme.colors.primaryText,
            border: 'none',
            background: 'transparent',
            '&:focus': {
                outline: 'none',
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
    buttons: {
        '& > div': {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        }
    },
    editor: {
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    },
    textWrapper: {
        width: '100%'
    },
    control: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    avatar: {
        position: 'relative'
    },
    spinnerWrapper: {
        position: 'absolute',
        bottom: -4,
        right: -4,
        background: theme.colors.white,
        borderRadius: '50%',
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.shadows[1]
    },
    blockIcon: {
        display: 'flex',
        marginRight: theme.spacing(2)
    },
    dropDownIcon: {
        cursor: 'pointer',
        display: 'flex',
        minWidth: 32,
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center'
    },
    previewWrapper: {},
    textTooltip: {
        color: theme.colors.white
    }
}));

function GridItem({ dbId, name, grid, status, isShowFirstPopupCreate, contextMenuId, isGridBlocked, disabled }) {
    const { t } = useTranslation();
    const rootRef = React.useRef();
    const history = useHistory();
    const { workspaceId } = useParams();
    const { branchId, id, alteredTime } = grid;
    const classes = useStyles({ background: getGridColorValue(grid) });
    const [isShowMore, setIsShowMore] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const roles = useRole();
    const accessManageGrid = roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID];
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [isOpenDuplicateForm, setIsOpenDuplicateForm] = useState(false);
    const [isOpenDuplicateAnotherForm, setIsOpenDuplicateAnotherForm] = useState(false);
    const [isOpenSaveTemplate, setIsOpenSaveTemplate] = useState(false);
    const [createdTemplateId, setCreatedTemplateId] = React.useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = React.useState(null);
    const favoriteGrids = useFavoriteGridList();

    const [favoriteFetched, setFavoriteFetched] = React.useState(false);

    const _gridFavorite = React.useMemo(() => {
        return favoriteGrids?.filter(_grid => _grid?.gridId === grid.id);
    }, [favoriteGrids, grid.id]);

    const isFavorite = React.useMemo(() => {
        return _gridFavorite[0]?.gridId === grid?.id;
    }, [_gridFavorite, grid.id]);

    const templateDetail = useGridTemplateDetail(createdTemplateId);
    const selectedGridId = useSelectedGrid();

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `GRID_CONTEXT_MENU_ID_${id}`;
    }, [id]);

    const handleItemClick = () => {
        if (isTempId(id)) return;

        const errorCallback = () => {
            history.push(
                `/projects/${workspaceId}/databases/${dbId}/grids/${id}/branches/${branchId || id}/views/${
                    grid?.defaultAccessViewId
                }`
            );
        };
        dispatch(
            gridActions.getGridLastView({
                dbId,
                gridId: id,
                errorCallback,
                successCallback: responseData => {
                    const { dbId, gridId, viewId, branchId } = responseData;
                    if (!dbId || !gridId || !viewId || !branchId) {
                        errorCallback();
                        return;
                    }
                    history.push(
                        `/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}`
                    );
                    dispatch(setSelectedGrid(id));
                }
            })
        );
    };

    const handleClick = event => {
        event.stopPropagation();
        setOpenEdit(true);
    };
    const handleClickAway = () => {
        setIsShowMore(false);
        setOpenEdit(false);
    };

    useEffect(() => {
        if (isShowMore && isShowFirstPopupCreate) {
            let $arrow = document.getElementById(`grid_more_${id}`);
            if ($arrow) {
                $arrow.click();
                dispatch(turnOffFirstPopup());
            }
        }
    }, [isShowMore, isShowFirstPopupCreate, id, dispatch]);

    useEffect(() => {
        if (isShowFirstPopupCreate) {
            setIsShowMore(true);
        }
    }, [isShowFirstPopupCreate]);

    React.useEffect(() => {
        if (contextMenuId && contextMenuId !== CONTEXT_MENU_ID) {
            handleClickAway();
        }
    }, [contextMenuId, CONTEXT_MENU_ID]);

    const handleOnContextClick = () => {
        if (accessManageGrid !== roleConst.FULL) return;
        dispatch(changeContext({ contextMenuId: CONTEXT_MENU_ID }));
        setOpenEdit(true);
    };

    const isUploading = React.useMemo(() => {
        return (
            status?.toLowerCase() === GRID_STATUS.UPLOADING ||
            status?.toLowerCase() === GRID_STATUS.IMPORTING ||
            status?.toLowerCase() === GRID_STATUS.DUPLICATING
        );
    }, [status]);

    const isMerging = React.useMemo(() => {
        return status?.toLowerCase() === GRID_STATUS.MERGING;
    }, [status]);

    const isRestoring = React.useMemo(() => {
        return status?.toLowerCase() === GRID_STATUS.RESTORING;
    }, [status]);

    const openDeleteConfirm = () => {
        setIsOpenConfirmModal(true);
    };

    const closeDeleteConfirm = () => {
        setIsOpenConfirmModal(false);
    };

    const openDuplicateForm = () => {
        setIsOpenDuplicateForm(true);
        setOpenEdit(false);
    };

    const openDuplicateAnotherForm = () => {
        setIsOpenDuplicateAnotherForm(true);
        setOpenEdit(false);
    };

    const closeDuplicateForm = () => {
        setIsOpenDuplicateForm(false);
    };

    const closeDuplicateAnotherForm = () => {
        setIsOpenDuplicateAnotherForm(false);
    };

    const openSaveTemplate = () => {
        setIsOpenSaveTemplate(true);
        setOpenEdit(false);
    };

    const closeSaveTemplate = () => {
        setIsOpenSaveTemplate(false);
    };

    const deleteGridHandler = () => {
        sendManualTrack({
            type: t('grid_delete_title'),
            customData: {
                gridId: grid?.id,
                dbId
            }
        });
        dispatch(
            deleteGrid({
                dbId,
                gridId: grid.id,
                successCallback: () => {
                    console.log('delete success');
                    dispatch(gridActions._removeFavoriteGrid({ gridId: grid.id }));
                },
                errorCallback: () => {
                    console.log('error delete');
                }
            })
        );
        handleClickAway();
    };

    const handleOpenTemplatePreview = React.useCallback(template => {
        setCreatedTemplateId(template?.id);
    }, []);

    const handleCloseTemplatePreview = React.useCallback(() => {
        setCreatedTemplateId(null);
    }, []);

    const handleOpenChooseWorkspace = React.useCallback(
        id => {
            setSelectedTemplateId(id);
            handleCloseTemplatePreview();
        },
        [handleCloseTemplatePreview]
    );

    const handleCloseChooseWorkspace = React.useCallback(() => setSelectedTemplateId(null), []);

    const handleFavoriteGrid = React.useCallback(
        event => {
            event.stopPropagation();
            setFavoriteFetched(true);
            dispatch(
                gridActions.changeFavoriteGrid({
                    dbId,
                    gridId: grid.id,
                    action: isFavorite ? REMOVE_FAVORITE_GRID : ADD_FAVORITE_GRID,
                    successCallback: () => {
                        setFavoriteFetched(false);
                    },
                    errorCallback: () => {
                        setFavoriteFetched(false);
                    }
                })
            );
        },
        [isFavorite, dbId, dispatch, grid.id]
    );

    React.useEffect(() => {
        if (rootRef.current && selectedGridId === id) {
            rootRef.current.scrollIntoView({ block: 'center' });
            dispatch(setSelectedGrid(null));
        }
    }, [dispatch, id, selectedGridId]);

    return (
        <>
            <CardInfo
                ref={rootRef}
                id={`grid-${id}`}
                onMouseOver={() => setIsShowMore(true)}
                onMouseLeave={() => setIsShowMore(false)}
                className={`${classes.root} gridItem`}
                style={{
                    background: isShowMore ? theme.colors.hoverItem : ``,
                    cursor: isTempId(id) ? 'default' : 'pointer',
                    pointerEvents: isRestoring ? 'none' : ''
                }}
                component={'div'}
                onClick={handleItemClick}
                onContextMenu={handleOnContextClick}
                avatar={
                    <div style={{ position: 'relative' }}>
                        <GridIcon grid={grid} size="large" />
                        {(isUploading || isRestoring || isMerging) && (
                            <Grid item className={classes.spinnerWrapper}>
                                <Spinner size={18} thick={3} />
                            </Grid>
                        )}
                    </div>
                }
                title={
                    <Grid container>
                        {isGridBlocked && (
                            <Grid item className={classes.blockIcon}>
                                <IconLockSVG color={theme.colors.brightRed} />
                            </Grid>
                        )}
                        <Grid item className={`${classes.title} gridName`}>
                            <OverflowTypography variant="body2">{name}</OverflowTypography>
                        </Grid>
                    </Grid>
                }
                subTitle={
                    <Typography className={classes.records} variant="caption">
                        {getFriendlyDate(alteredTime)}
                    </Typography>
                }
                action={
                    <>
                        <Grid item className={classes.control}>
                            {isShowMore &&
                                !isUploading &&
                                !disabled &&
                                (roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID] === roleConst.FULL ||
                                    roles[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] === roleConst.FULL) && (
                                    <>
                                        {' '}
                                        <Grid
                                            tr-dt="Open Edit Grid"
                                            item
                                            id={`grid_more_${id}`}
                                            onClick={handleClick}
                                            className={`${classes.dropDownIcon} more`}
                                        >
                                            <IconMoreActionsSVG color={theme.colors.steel} />
                                        </Grid>
                                    </>
                                )}
                        </Grid>
                        <Grid item className={classes.control}>
                            {isShowMore && !isUploading && !disabled && (
                                <Tooltip
                                    maxWidth={false}
                                    title={
                                        favoriteGrids?.length === 10 && !isFavorite ? (
                                            <Typography className={classes.textTooltip}>
                                                You can have up to 10 favorites
                                                <br />
                                                Please remove one to add another
                                            </Typography>
                                        ) : !isFavorite ? (
                                            <Typography className={classes.textTooltip}>
                                                {t('add_to_your_favorites')}{' '}
                                            </Typography>
                                        ) : (
                                            <Typography className={classes.textTooltip}>
                                                {t('remove_from_favorites')}{' '}
                                            </Typography>
                                        )
                                    }
                                >
                                    <Grid
                                        tr-dt="Open Favorite Grid"
                                        item
                                        id={`grid_favorite_${id}`}
                                        className={`${classes.dropDownIcon} more`}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <button
                                            disabled={favoriteFetched}
                                            onClick={handleFavoriteGrid}
                                            className="!bg-transparent !hover:bg-transparent !border-none !outline-none focus:outline-none"
                                        >
                                            {isFavorite ? (
                                                <FillStarSVG />
                                            ) : (
                                                <OutlineStarSVG color={theme.colors.steel} />
                                            )}
                                        </button>
                                    </Grid>
                                </Tooltip>
                            )}
                        </Grid>
                        <Popper
                            open={openEdit}
                            anchorEl={rootRef.current}
                            placement="bottom"
                            className={classes.editor}
                            modifiers={{ preventOverflow: { escapeWithReference: true } }}
                        >
                            <GridPoppup
                                dbId={dbId}
                                grid={grid}
                                handleClickAway={handleClickAway}
                                onDelete={openDeleteConfirm}
                                onDuplicate={openDuplicateForm}
                                onDuplicateAnother={openDuplicateAnotherForm}
                                onSaveTemplate={openSaveTemplate}
                            />
                        </Popper>
                    </>
                }
            />

            <Dialog open={isOpenConfirmModal} onClose={closeDeleteConfirm}>
                <ConfirmBox
                    title={t('grid_delete_title')}
                    body={
                        <Trans
                            t={t}
                            i18nKey="grid_delete_comfirm_msg"
                            defaults="Are you sure you want to delete the grid <bold>{{name}}</bold>?"
                            values={{ name: grid.name }}
                            components={{
                                bold: <Typography variant="body1" display="inline" />
                            }}
                        />
                    }
                    handleCancel={closeDeleteConfirm}
                    onClose={closeDeleteConfirm}
                    handleAgreed={deleteGridHandler}
                    agreeLabel={t('global_delete')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
            <Dialog open={isOpenDuplicateForm} onClose={closeDuplicateForm}>
                <DuplicateGridForm clonedGrid={grid} dbId={dbId} onClose={closeDuplicateForm} />
            </Dialog>
            <Dialog open={isOpenDuplicateAnotherForm} onClose={closeDuplicateForm}>
                <DuplicateAnotherGrid clonedGrid={grid} dbId={dbId} onClose={closeDuplicateAnotherForm} />
            </Dialog>
            <Dialog open={isOpenSaveTemplate} onClose={closeSaveTemplate}>
                <SaveTemplate
                    dbId={dbId}
                    grid={grid}
                    onClose={closeSaveTemplate}
                    onTemplateCreated={handleOpenTemplatePreview}
                />
            </Dialog>

            <Dialog maxWidth={false} open={!!createdTemplateId} onClose={handleCloseTemplatePreview}>
                <Grid container className={classes.previewWrapper}>
                    <GridTemplateDetail
                        isShortView
                        // dbId={dbId}
                        {...templateDetail}
                        template={templateDetail}
                        onClose={handleCloseTemplatePreview}
                        onOpenChoosingWorkspace={handleOpenChooseWorkspace}
                    />
                </Grid>
            </Dialog>

            <Dialog maxWidth={false} open={!!selectedTemplateId} onClose={handleCloseChooseWorkspace}>
                <ChooseWorkspaceDatabase selectedTemplateId={selectedTemplateId} onClose={handleCloseChooseWorkspace} />
            </Dialog>
        </>
    );
}

export default React.memo(GridItem);
