import * as types from '../types';

const handler = {
    [types.FETCH_CARD_INFO](state, { payload }) {
        return {
            ...state,
            isFetchingCardInfo: true
        };
    },
    [types.FETCH_CARD_INFO_FAILED](state, { payload }) {
        return {
            ...state,
            isFetchingCardInfo: false
        };
    },
    [types.FETCH_CARD_INFO_SUCCESS](state, { payload }) {
        const { cardInfo } = payload;
        return {
            ...state,
            cardInfo,
            isFetchingCardInfo: false
        };
    }
};

export default handler;
