import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    noOptionsMessage: {
        textAlign: 'center'
    }
}));

function NoOptionsMessage(props) {
    const classes = useStyles();
    return (
        <Typography color="textSecondary" variant="body1" className={classes.noOptionsMessage} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

NoOptionsMessage.propTypes = {
    /**
     * The children to be rendered.
     */
    children: PropTypes.node,
    /**
     * Props to be passed on to the wrapper.
     */
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired
};

export default React.memo(NoOptionsMessage);
