import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Popper } from '@material-ui/core';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import TBPopup from './TBPopup';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sendManualTrack } from 'tracker';
import { deleteTB } from 'gridUI/actions';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: { display: 'flex', position: 'relative', cursor: 'pointer' }
}));

const TBItemAction = ({ item, handleSelectTB, handleImportTerms, handleExportTerms }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState();
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [confirming, setConfirming] = useState(false);

    const handleClick = useCallback(
        event => {
            event.stopPropagation();
            event.preventDefault();
            if (anchorEl === event.currentTarget) return;
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const openConfirmDeleteHandler = useCallback(() => {
        setOpenConfirmDeleteModal(true);
    }, []);

    const closeConfirmDeleteHandler = useCallback(() => {
        setOpenConfirmDeleteModal(false);
    }, []);

    const handleDeleteTB = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setConfirming(true);
            dispatch(
                deleteTB({
                    tbId: item.id,
                    successCallback: () => {
                        setConfirming(false);
                        closeConfirmDeleteHandler();
                        sendManualTrack({ type: `Delete TB Success` });
                        console.log('deleteTB successfully');
                    },
                    errorCallback: () => {
                        sendManualTrack({ type: `Delete TB Failed` });
                        console.log('deleteTB failed');
                        setConfirming(false);
                    }
                })
            );
        },
        [dispatch, item, closeConfirmDeleteHandler]
    );

    return (
        <Grid item className={classes.dFlex} onClick={handleClick}>
            <IconMoreActionsSVG />
            <Popper placement="bottom-end" open={Boolean(anchorEl)} anchorEl={anchorEl} style={{ zIndex: 10 }}>
                <Paper>
                    <TBPopup
                        tb={item}
                        name={item.name}
                        handleClickAway={handleClickAway}
                        onDelete={openConfirmDeleteHandler}
                        onEdit={() => handleSelectTB(item)}
                        handleImportTerms={handleImportTerms}
                        handleExportTerms={handleExportTerms}
                    />
                </Paper>
            </Popper>
            <Dialog open={openConfirmDeleteModal} onClose={closeConfirmDeleteHandler}>
                <ConfirmBox
                    title="Delete Glossary"
                    // body={
                    //     <Trans
                    //         i18nKey="remove_tb_body"
                    //         t={t}
                    //         values={{ name: item.name }}
                    //         components={{ bold: <b /> }}
                    //     />
                    // }
                    body={
                        <>
                            Are your sure you want to delete <b>{item.name}</b>? It will be deleted immediately and this
                            action cannot be undone.
                        </>
                    }
                    handleCancel={closeConfirmDeleteHandler}
                    onClose={closeConfirmDeleteHandler}
                    handleAgreed={handleDeleteTB}
                    isLoading={confirming}
                    agreeLabel={t('global_remove')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(TBItemAction);
