import * as types from '../types';

const handler = {
    [types.OPEN_CELL_ISSUE](state) {
        return {
            ...state,
            isOpenCellIssue: true
        };
    },
    [types.CLOSE_CELL_ISSUE](state) {
        return {
            ...state,
            isOpenCellIssue: false,
            comment: {}
        };
    },
    [types.TOGGLE_CELL_ISSUE](state) {
        const { isOpenCellIssue } = state;
        return {
            ...state,
            isOpenCellIssue: !isOpenCellIssue,
            comment: {}
        };
    },
    [types.OPEN_VIEW_CELL_TICKET](state) {
        return {
            ...state,
            isOpenViewCellTicket: true
        };
    },
    [types.CLOSE_VIEW_CELL_TICKET](state) {
        return {
            ...state,
            isOpenViewCellTicket: false
        };
    },
    [types.TOGGLE_VIEW_CELL_TICKET](state) {
        const { isOpenViewCellTicket } = state;
        return {
            ...state,
            isOpenCellIssue: !isOpenViewCellTicket
        };
    }
};

export default handler;
