import React from 'react';
import { Grid } from '@material-ui/core';
import ConfirmBox from 'components/confirmBox/Base';
import * as automationActions from 'gridUI/automations/action';
import { useDispatch } from 'react-redux';
import { useMatchAutomations } from 'hooks/gridUI/automation';
import Checkbox from 'components/checkbox/Base';
import AvatarBase from 'components/avatar/Base';
import { getAutomationLogoUrl } from 'utils/images';
import LogoSVG from 'assets/images/svg/webHook/LogoSVG';
import { usePublicSelectedColumns } from 'hooks/gridUI';

function MultipleAnimation({ onClose }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const publicColumnsSelected = usePublicSelectedColumns();
    const animations = useMatchAutomations(publicColumnsSelected);

    const [automationIds, setAutomationIds] = React.useState([]);

    const handleInvokeMultipleAutomation = React.useCallback(
        e => {
            e.stopPropagation();
            setIsLoading(true);

            for (const automationId of automationIds) {
                dispatch(
                    automationActions.invokeAutomationRange({
                        automationId,
                        successCallback: () => {},
                        errorCallback: () => {}
                    })
                );
            }

            dispatch(automationActions.setOpenMultipleAutomationDialog(false));
        },
        [dispatch, automationIds]
    );

    const check = React.useCallback(
        aId => {
            if (automationIds?.includes(aId)) {
                setAutomationIds(automationIds?.filter(id => id !== aId));
            } else {
                setAutomationIds([...automationIds, aId]);
            }
        },
        [automationIds]
    );

    return (
        <ConfirmBox
            width={460}
            title="Run Automation on selected cells"
            body={
                <Grid container direction="column">
                    {animations?.map(a => {
                        const checked = automationIds?.includes(a?.id);

                        return (
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                direction="row"
                                item
                                key={a?.id}
                            >
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <AvatarBase src={getAutomationLogoUrl(a.iconFileId) || ''} size={24}>
                                                <LogoSVG />
                                            </AvatarBase>
                                        </Grid>
                                        <Grid item>
                                            <p className="body2">{a?.title}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Checkbox checked={checked} onClick={() => check(a?.id)} />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            }
            handleCancel={onClose}
            onClose={onClose}
            handleAgreed={handleInvokeMultipleAutomation}
            agreeLabel="Run Automation"
            isLoading={isLoading}
            agreeWidth={140}
        />
    );
}

export default MultipleAnimation;
