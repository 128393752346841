import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import ElsaSVG from 'assets/images/svg/ElsaSVG';
import SrSpSVG from 'assets/images/svg/SrSpSVG';
import TwoDigitsLang from 'common/TwoDigitsLang';

const DEFAULT_STYLE = {
    width: 24,
    height: 16,
    borderRadius: 4
};

const COUNTRY_CODE_MAP = {
    esLA: <ElsaSVG />,
    srSP: <SrSpSVG {...DEFAULT_STYLE} />
};

export default function CountryFlag({ languageCountryCode = '', style }) {
    if (languageCountryCode.length === 2) {
        return <TwoDigitsLang lang={languageCountryCode} style={style} />;
    }

    if (COUNTRY_CODE_MAP?.hasOwnProperty(languageCountryCode)) {
        return COUNTRY_CODE_MAP?.[languageCountryCode];
    }

    return (
        <ReactCountryFlag
            countryCode={languageCountryCode.slice(-2).toUpperCase()}
            svg
            style={{ ...(style || DEFAULT_STYLE), minWidth: 24 }}
        />
    );
}
