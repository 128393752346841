import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { INPUT_HEIGHT } from 'const/style';
import { INPUT_PADDING_LEFT, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import PopperMenu from 'components/menus/Popper';
import ListItem from 'components/list/Item';
import { useRoleOptions } from 'hooks/permission';
import SystemRoleFlag from 'permission/roles/SystemRoleFlag';
import { DEFAULT_ROLE_OWNER } from 'const';
import { useCanCustomUserRole } from 'hooks/payment';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: 160,
        height: INPUT_HEIGHT,
        padding: `0px 14px`,
        borderRadius: 4,
        position: 'relative',
        left: (INPUT_PADDING_LEFT + 1) * -1,
        cursor: 'pointer'
    },
    roleSelect: {
        minWidth: 200
    },
    arrowDown: {
        display: 'flex',
        marginLeft: SPACING_LIST_NAME_WITH_ICON
    }
}));

function RoleSelect({ isSystemRole, defaultRoleName, selectedRoleId, onChange, isCurrentUser }) {
    const classes = useStyles();
    const roleOptions = useRoleOptions({ isIgnoreOwner: true });
    const canCustomUserRole = useCanCustomUserRole();
    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleRoleChange = role => {
        onChange && onChange(role);
        handleClickAway();
    };

    const isDefaultRoleOwner =
        defaultRoleName === DEFAULT_ROLE_OWNER?.name && selectedRoleId === DEFAULT_ROLE_OWNER?.id;

    return (
        <>
            <Grid
                container
                className={classes.root}
                direction="row"
                wrap="nowrap"
                alignItems="center"
                onClick={!isDefaultRoleOwner && !isCurrentUser && handleClick}
            >
                <Grid item>
                    <Grid container spacing={2} alignItems="center" wrap="nowrap">
                        {isSystemRole && (
                            <Grid item style={{ display: 'flex' }}>
                                <SystemRoleFlag role={{ id: selectedRoleId, name: defaultRoleName }} />
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant="body2">{defaultRoleName}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!isDefaultRoleOwner && !isCurrentUser && (
                    <Grid item className={classes.arrowDown}>
                        <ArrowDownSVG />
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-center'} handleClickAway={handleClickAway}>
                    <Grid container direction="column" className={classes.roleSelect}>
                        {roleOptions?.map(role => {
                            const isSelected = role?.id === selectedRoleId;
                            const isLockedByPayment = !role?.isSystemRole && !canCustomUserRole;

                            return (
                                <ListItem
                                    icon={<SystemRoleFlag role={{ id: role.id, name: role.name }} />}
                                    onClick={() => handleRoleChange(role)}
                                    key={role?.id}
                                    isSelected={isSelected}
                                    moreIcon={isLockedByPayment ? <UpgradeSVG /> : undefined}
                                    name={role?.label}
                                    disabled={isLockedByPayment}
                                />
                            );
                        })}
                    </Grid>
                </PopperMenu>
            )}
        </>
    );
}

export default React.memo(RoleSelect);
