import React from 'react';

function UploadingErrorSVG({ ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99147 20C15.5096 20 19.9829 15.5228 19.9829 10C19.9829 4.47715 15.5096 0 9.99147 0C4.47333 0 0 4.47715 0 10C0 15.5228 4.47333 20 9.99147 20Z"
                fill="#EB4F5E"
                fillOpacity="0.8"
            />
            <path
                d="M10.0666 12.6768C9.29109 12.6768 8.78433 13.2267 8.80017 13.9606C8.78433 14.6607 9.29109 15.2278 10.0512 15.2278C10.843 15.2278 11.3494 14.6607 11.3498 13.9606C11.3335 13.2272 10.8426 12.6768 10.0666 12.6768Z"
                fill="white"
            />
            <path
                d="M10.0498 4.87646C9.35939 4.87646 8.7998 5.38402 8.7998 6.01025L8.98096 8.25145C9.05342 9.18968 9.09871 9.85436 9.11683 10.2455L9.12589 10.5454C9.12589 11.1716 9.35939 11.5594 10.0498 11.5594C10.6942 11.5594 10.94 11.2216 10.9699 10.6674L10.9731 10.5454L11.2998 6.01025C11.2998 5.38402 10.7402 4.87646 10.0498 4.87646Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(UploadingErrorSVG);
