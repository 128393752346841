import React from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from '../components/Checkbox';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';

function BooleanDelete({ previousData, data, state, isHideMarkIcon = false }) {
    const theme = useTheme();
    return (
        <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item>
                <Checkbox data={previousData} state={state || 'normal'} isHideMarkIcon={isHideMarkIcon} />
            </Grid>
            <Grid item>
                <ArrowNextSVG color={theme.colors.steel} />
            </Grid>
            <Grid item>
                <Checkbox data={data} state={state || 'delete'} isHideMarkIcon={isHideMarkIcon} />
            </Grid>
        </Grid>
    );
}

export default React.memo(BooleanDelete);
