import React from 'react';

function UncheckedBoolean({ ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="#F4F5F7" stroke="#B4BDC8" />
        </svg>
    );
}

export default React.memo(UncheckedBoolean);
