import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Grid, CircularProgress, InputAdornment, IconButton, Typography, Divider } from '@material-ui/core';

import { logIn } from './actions';
import ErrorMessage from './ErrorMessage';
import InputText from 'components/inputs/InputText';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import SamlLoginSVG from 'assets/images/svg/SamlLoginSVG';
import Button from 'components/button/Base';
import { sendManualTrack } from 'tracker';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import GoogleLoginSVG from 'assets/images/svg/GoogleLoginSVG';
import { API_URL } from 'config';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: 470,
        minWidth: 470,

        [theme.breakpoints.down('xs')]: {
            minWidth: 260
        }
    },
    forgetLink: {
        textAlign: 'right',
        color: theme.colors.darkLavender,
        textDecoration: 'none'
    },
    signInWith: {
        color: theme.colors.lightGreyBlue
    }
}));

const LoginSchema = object({
    username: string()
        // .email('Invalid Email')
        .required(i18n.t(`global_this_field_is_required`)),
    password: string()
        .min(4, i18n.t(`global_min_character`, { limit: 4 }))
        .required(i18n.t(`global_this_field_is_required`))
});

function LoginForm({ username }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isShowPassword, setIsShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: username || '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            sendManualTrack({ type: 'Click On Login' });
            const { username, password } = values;
            dispatch(
                logIn({
                    username,
                    password,
                    successCallback: () => {
                        sendManualTrack({ type: 'Login Success' });
                        setSubmitting(false);
                        setStatus({ error: null });
                    },
                    errorCallback: error => {
                        sendManualTrack({ type: 'Login Failed' });
                        setSubmitting(false);
                        setStatus({ error });
                    }
                })
            );
        }
    });
    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        status,
        setStatus
    } = formik;

    useEffect(() => setStatus({ error: location.state?.error }), [location, setStatus]);

    function handleClickShowPassword() {
        setIsShowPassword(prev => !prev);
    }

    const handleGoogleLogin = () => {
        window.location = `${API_URL}/oauth2/authorization/google`;
    };

    return (
        <>
            {status?.error && <ErrorMessage message={status.error} />}
            <form id="sign-in-form" onSubmit={handleSubmit} className={classes.form}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <InputText
                            name="username"
                            type="text"
                            value={values.username || ''}
                            height={36}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.username && touched.username}
                            errorText={errors.username}
                            placeholder={t(`global_email_placeholder_example`)}
                            autoFocus={values.username ? false : true}
                            autoComplete={'on'}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 14 }}>
                        <InputText
                            name="password"
                            value={values.password}
                            height={36}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.password && touched.password}
                            errorText={errors.password}
                            placeholder={t(`global_password`)}
                            autoFocus={values.username ? true : false}
                            type={isShowPassword ? 'text' : 'password'}
                            autoComplete={'on'}
                            disableErrorAbsolute
                            data-openreplay-obscured
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowPassword} edge="end">
                                        {isShowPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item tr-dt="Open Forgot Password" style={{ marginLeft: 'auto' }}>
                        <Link className={classes.forgetLink} to="/reset/init">
                            {t(`auth_forgot_password`)}
                        </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 24 }}>
                        <Button fullWidth type="submit" variant="contained" disabled={isSubmitting || !isValid}>
                            {isSubmitting ? <CircularProgress size={24} /> : t(`global_sign_in`)}
                        </Button>
                    </Grid>
                    <Grid item container alignItems="center" spacing={2} style={{ marginTop: 14 }}>
                        <Grid item xs>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.signInWith} variant="body2">
                                {t('or_sign_in_with')}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} style={{ marginTop: 14 }}>
                        <Grid item xs>
                            <Link to="/saml" className="btn btn-outlined w-full">
                                <span style={{ marginRight: 8 }}>
                                    <SamlLoginSVG />
                                </span>
                                SSO/SAML
                            </Link>
                        </Grid>
                        {!isExternal && (
                            <Grid item xs>
                                <Button onClick={handleGoogleLogin} fullWidth variant="outlined">
                                    <span style={{ marginRight: 8 }}>
                                        <GoogleLoginSVG />
                                    </span>
                                    Sign in with Google
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default LoginForm;
