import { useCurrentUser } from 'hooks/auth';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import * as roleConst from 'auth/roleConst';
import { useIsFetchedWorkspaceRole, useRole } from 'hooks/auth/role';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';
import { fetchMe, fetchUserWorkspaceRole, _setCompanyId } from 'auth/actions';
import { isExternal } from 'config';
import { getSelfHostingLicense } from 'app/actions';
import { fetchRegions } from 'workspaces/actions';
import { enabledRegion } from 'config';

export default function AuthenticatedGridRoute({ children, roles = [], paymentConditions = [], ...rest }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUser = useCurrentUser();
    const permission = useRole();
    const { t } = useTranslation();
    const { workspaceId } = useParams();
    const isFetchedWorkspaceRole = useIsFetchedWorkspaceRole();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isFetchedWorkspaceRole) return;
        setIsLoading(true);
        dispatch(
            fetchUserWorkspaceRole({
                workspaceId,
                successCallback: companyId => {
                    dispatch(_setCompanyId({ companyId }));
                    dispatch(fetchMe({ companyId }));
                    setIsLoading(false);
                },
                errorCallback: () => {
                    console.log('failed to fetch project role');
                    setIsLoading(false);
                }
            })
        );
    }, [dispatch, workspaceId, isFetchedWorkspaceRole]);

    React.useEffect(() => {
        if (!isExternal) return;
        dispatch(getSelfHostingLicense({}));
    }, [dispatch]);

    React.useEffect(() => {
        if (!enabledRegion) return;
        dispatch(
            fetchRegions({
                errorCallback: () => {
                    console.log('fetchRegions failed');
                },
                successCallback: () => {
                    console.log('fetchRegions success');
                }
            })
        );
    }, [dispatch, enabledRegion]);

    if (isLoading) return <span>Loading...</span>;

    const hasPermission = roles?.length === 0 || roles?.some(role => permission?.[role] === roleConst.FULL);
    const hasPaymentPermission = paymentConditions?.length === 0 || paymentConditions.every(Boolean);

    if (!currentUser) {
        return (
            <Redirect
                to={{
                    pathname: '/signin',
                    search: location.search,
                    state: { intendedRoute: location.pathname }
                }}
            />
        );
    }

    if (currentUser?.authorities?.includes('PRE_AUTH') && location?.pathname !== '/signin-user-2fa') {
        return (
            <Redirect
                to={{
                    pathname: '/signin-user-2fa',
                    search: location?.search,
                    state: { intendedRoute: location?.pathname }
                }}
            />
        );
    }

    if (!hasPermission) {
        dispatch(
            enqueueSnackbar({
                message: t(`global_have_no_permission_to_do`),
                type: 'info'
            })
        );
        return <Redirect to={{ search: location.search, pathname: '/' }} />;
    }

    if (!hasPaymentPermission) {
        dispatch(
            enqueueSnackbar({
                message: t(`global_have_no_payment_permission_to_do`),
                type: 'info'
            })
        );
        return <Redirect to={{ search: location.search, pathname: '/' }} />;
    }

    return children;
}
