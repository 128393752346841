import React from 'react';

function IconCaretDownSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7">
            <path
                fill={color}
                fillRule="nonzero"
                d="M.583 0h8.834a.3.3 0 0 1 .244.474L5.244 6.658a.3.3 0 0 1-.488 0L.339.474A.3.3 0 0 1 .583 0z"
            />
        </svg>
    );
}

export default React.memo(IconCaretDownSVG);
