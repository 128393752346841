import React from 'react';

function SettingRoleSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33268 1.75C7.69174 1.75 7.07705 2.00461 6.62384 2.45783C6.17063 2.91104 5.91602 3.52573 5.91602 4.16667V5.08334H3.33268C1.99799 5.08334 0.916016 6.16532 0.916016 7.50001V15.8333C0.916016 17.168 1.99799 18.25 3.33268 18.25H16.666C18.0007 18.25 19.0827 17.168 19.0827 15.8333V7.50001C19.0827 6.16532 18.0007 5.08334 16.666 5.08334H14.0827V4.16667C14.0827 3.52573 13.8281 2.91104 13.3749 2.45783C12.9216 2.00461 12.307 1.75 11.666 1.75H8.33268ZM12.5827 5.08334V4.16667C12.5827 3.92355 12.4861 3.69039 12.3142 3.51849C12.1423 3.34658 11.9091 3.25 11.666 3.25H8.33268C8.08957 3.25 7.85641 3.34658 7.6845 3.51849C7.51259 3.69039 7.41602 3.92355 7.41602 4.16667V5.08334H12.5827ZM7.41602 6.58334H12.5827V16.75H7.41602V6.58334ZM5.91602 6.58334H3.33268C2.82642 6.58334 2.41602 6.99375 2.41602 7.50001V15.8333C2.41602 16.3396 2.82642 16.75 3.33268 16.75H5.91602V6.58334ZM14.0827 16.75V6.58334H16.666C17.1723 6.58334 17.5827 6.99375 17.5827 7.50001V15.8333C17.5827 16.3396 17.1723 16.75 16.666 16.75H14.0827Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SettingRoleSVG);
