import React from 'react';
import { Grid, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getPriorityIcon } from 'utils/gridUI/lqa';
import ButtonBase from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        zIndex: 1000,
        maxWidth: 401,
        margin: '0 auto',
        left: 0,
        right: 0,
        top: 'calc(100vh - 123px)'
    },
    paper: {
        padding: '3px 13px',
        background: theme.colors.white,
        boxShadow: theme.shadows[1],
        width: 'fit-content',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    dlFlex: {
        display: 'flex'
    },
    ticketId: {
        whiteSpace: 'nowrap',
        color: theme.colors.lightGreyBlue
    },
    summary: {
        ...theme.ellipsis(1)
    },
    button: {
        padding: 0,
        marginRight: 6
    },
    close: {
        display: 'flex',
        cursor: 'pointer'
    }
}));

const TicketMinimize = ({ ticket, isOpen, onClose, onOpenTicketDetail }) => {
    const classes = useStyles();
    return (
        <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
            <Grid container className={classes.root} justify="center">
                <Grid container alignItems="center" spacing={2} wrap="nowrap" className={classes.paper}>
                    <Grid item className={classes.dlFlex}>
                        {getPriorityIcon(ticket.priority)}
                    </Grid>
                    <Grid item>
                        <p className={classes.ticketId}>Ticket-{ticket.id}</p>
                    </Grid>
                    <Grid item>
                        <p className="body1 line-clamp-1">{ticket.summary}</p>
                    </Grid>
                    <Grid item>
                        <ButtonBase
                            size="small"
                            variant="contained"
                            width={89}
                            className={classes.button}
                            onClick={onOpenTicketDetail}
                        >
                            View ticket
                        </ButtonBase>
                    </Grid>
                    <Grid item className={classes.close} onClick={onClose}>
                        <CloseIconSVG />
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    );
};

export default React.memo(TicketMinimize);
