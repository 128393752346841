import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import ButtonBase from 'components/button/Base';

function ConfirmResendBox({ onClose, onAccept, isSubmitting }) {
    return (
        <div className={'p-5 w-[400px] bg-white'}>
            <Grid container wrap="nowrap" direction="column" spacing={4}>
                <Grid item>
                    <h3 className="prose font-semibold prose-2xl">Cancel all uploads</h3>
                </Grid>
                <Grid item>
                    <p className="body2">
                        Your uploads are not complete. Would you like to cancel all ongoing uploads?
                    </p>
                </Grid>
                <Grid item>
                    <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                        <Grid item>
                            <ButtonBase onClick={onClose} width={140} variant="outlined">
                                Continue Upload
                            </ButtonBase>
                        </Grid>
                        <Grid item className={'relative'}>
                            <ButtonBase onClick={onAccept} width={160} variant="contained" disabled={isSubmitting}>
                                Yes, Cancel Uploads
                            </ButtonBase>
                            {isSubmitting && (
                                <CircularProgress
                                    size={24}
                                    className={'text-brand-main absolute top-[50%] left-[50%] -ml-3 -mt-3'}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(ConfirmResendBox);
