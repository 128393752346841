import React, { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import { DEBOUNCE_TIME_SEARCHING, NOT_NUMBER_REGEX } from 'const/gridUI';
import { INPUT_QUICK_FILTER_HEIGHT } from 'const/style';
import { NUMBER } from 'const/columnTypes';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { OPERATOR } from 'gridUI/conditions';
import { isValidRegex } from 'utils/regex';
import Tooltip from 'components/tooltip/Base';
import classNames from 'classnames';

function InputQuickFilter({
    columnId,
    type,
    isSelectionActive,
    quickFilters,
    isDisabled,
    t,
    placeholder,
    isRegex,
    ...rest
}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(quickFilters?.[columnId]?.value || '');
    const [isInvalid, setIsInvalid] = React.useState(false);
    const timer = useRef(0);

    React.useEffect(() => {
        setValue(quickFilters?.[columnId]?.value || '');
    }, [quickFilters, columnId]);

    React.useEffect(() => {
        if (!isRegex) {
            setIsInvalid(false);
        }
    }, [isRegex]);

    const handleFilterInputChange = e => {
        const isNumber = type === NUMBER;
        const value = isNumber ? e.target.value.replace(NOT_NUMBER_REGEX, '') : e.target.value;

        setValue(value);

        if (quickFilters?.[columnId]?.value === value || (isNumber && value === '-')) return;

        if (isRegex) {
            const validRegex = isValidRegex(value);
            if (validRegex) {
                setIsInvalid(false);
            } else {
                setIsInvalid(true);
                return;
            }
        }

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(function() {
            dispatch(
                gridActions.QuickFilterChange({
                    columnId,
                    value,
                    type,
                    operator: isNumber ? OPERATOR.equal : OPERATOR.contains
                })
            );
            const isCancel = isRegex && !isValidRegex(value);
            if (isCancel) return;
            setTimeout(() => {
                dispatch(
                    gridActions.fetchRecordsWithFiltersAndSorts({
                        errorCallback: () => {
                            console.log('failed to filter');
                        },
                        successCallback: () => {
                            console.log('filter successfully');
                        }
                    })
                );
            }, DEBOUNCE_TIME_SEARCHING);
        }, DEBOUNCE_TIME_SEARCHING);
    };

    const handleInputFocus = () => {
        if (isSelectionActive) {
            dispatch(gridActions.resetCellStatus());
        }
    };

    const clearFilter = () => {
        setValue('');
        const isNumber = type === NUMBER;

        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: '',
                type,
                operator: isNumber ? OPERATOR.equal : OPERATOR.contains
            })
        );

        if (isRegex) {
            setIsInvalid(false);
        }

        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    return (
        <Grid
            container
            wrap="nowrap"
            className={classNames(
                `h-[${INPUT_QUICK_FILTER_HEIGHT}px] bg-grey-pale rounded border focus-within:border-lavender-light pl-2 pr-[2px]`,
                {
                    'border border-red-900': isInvalid
                }
            )}
            alignItems="center"
        >
            <Tooltip title={value} disableHoverListener={!value}>
                <Grid item className={'flex-1'}>
                    <input
                        className={classNames(
                            `w-full body2 bg-grey-pale outline-none border-none text-text-primary line-clamp-1 placeholder:text-grey-mid`,
                            {
                                disabled: isDisabled
                            }
                        )}
                        value={value}
                        placeholder={t(`column_quick_filter_placeholder`)}
                        onChange={handleFilterInputChange}
                        onFocus={handleInputFocus}
                    />
                </Grid>
            </Tooltip>

            {value && (
                <Grid item className={'m-2 flex cursor-pointer'} onClick={clearFilter}>
                    <CloseIconSVG />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(InputQuickFilter);
