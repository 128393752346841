import * as types from "../types";

export function saveTableInfo({tableInfo}){
    return {
        type: types.SAVE_TABLE_INFO,
        payload: {
            tableInfo
        }
    }
}

export function clearTableData(){
    return {
        type: types.CLEAR_TABLE_DATA
    }
}