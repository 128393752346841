import React from 'react';

function AudioThumbnailSVG({ color = '#93929e', size = 54, ...other }) {
    return (
        <svg {...other} width={size} height={size} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.80005 24.2H11.8V27.7999L7.80005 24.2Z" fill="#327ECA" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3 5.1001C12.6 5.1001 12 5.7001 12 6.4001V47.6001C12 48.3001 12.6 48.9001 13.3 48.9001H44.9C45.6 48.9001 46.2 48.3001 46.2 47.6001V15.7001L35.5 5.1001H13.3Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46 16.5C46 16 45.8001 15.5 45.4001 15.1L35.9001 5.6C35.5001 5.2 35.0001 5 34.4001 5H13.1C12.4 5 11.8 5.6 11.8 6.3V47.5C11.8 48.2 12.4 48.8 13.1 48.8H44.7001C45.4001 48.8 46 48.2 46 47.5V16.5ZM13.1 6H34.8V14.4C34.8 15.1 35.4 15.7 36.1 15.7H44.6L45 16.1V47.6C45 47.8 44.9001 47.9 44.7001 47.9H13.1C12.9 47.9 12.8 47.7 12.8 47.6V6.4C12.7 6.1 12.9 6 13.1 6Z"
                fill="#5DAAF8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.1 24.2001H7.80005V13.1001H31.1C32.2 13.1001 33.2001 14.0001 33.2001 15.2001V22.1001C33.1001 23.3001 32.2 24.2001 31.1 24.2001Z"
                fill="#5DAAF8"
            />
            <path
                d="M13.7 20.0999H12.2L11.9 20.9999H10.7L12.4 16.3999H13.4L15.1 20.9999H14L13.7 20.0999ZM12.4 19.2999H13.4L12.9 17.6999L12.4 19.2999Z"
                fill="white"
            />
            <path
                d="M19.4 16.3999V19.3999C19.4 19.6999 19.3 19.9999 19.2 20.2999C19.1 20.4999 18.8 20.6999 18.6 20.8999C18.3 20.9999 18 21.0999 17.6 21.0999C17 21.0999 16.6 20.9999 16.3 20.6999C16 20.3999 15.8 19.9999 15.8 19.4999V16.4999H16.9V19.5999C16.9 20.0999 17.1 20.3999 17.6 20.3999C17.8 20.3999 18 20.2999 18.1 20.1999C18.2 20.0999 18.3 19.8999 18.3 19.5999V16.5999H19.4V16.3999Z"
                fill="white"
            />
            <path
                d="M20.4001 20.9999V16.3999H21.9001C22.3001 16.3999 22.7001 16.4999 23.0001 16.6999C23.3001 16.8999 23.6001 17.0999 23.8001 17.4999C24.0001 17.7999 24.1001 18.1999 24.1001 18.5999V18.7999C24.1001 19.1999 24.0001 19.5999 23.8001 19.8999C23.6001 20.1999 23.4001 20.4999 23.0001 20.6999C22.7001 20.8999 22.3001 20.9999 21.9001 20.9999H20.4001V20.9999ZM21.5001 17.1999V20.0999H21.9001C22.2001 20.0999 22.5001 19.9999 22.6001 19.7999C22.8001 19.5999 22.9001 19.1999 22.9001 18.7999V18.5999C22.9001 18.1999 22.8001 17.7999 22.6001 17.5999C22.4001 17.3999 22.2001 17.2999 21.9001 17.2999H21.5001V17.1999Z"
                fill="white"
            />
            <path d="M26.0001 20.9999H24.9001V16.3999H26.0001V20.9999Z" fill="white" />
            <path
                d="M31.0001 18.7999C31.0001 19.2999 30.9001 19.6 30.7001 20C30.5001 20.3 30.3001 20.5999 30.0001 20.7999C29.7001 20.9999 29.3001 21.1 28.9001 21.1C28.5001 21.1 28.2001 20.9999 27.9001 20.7999C27.6001 20.5999 27.4001 20.4 27.2001 20C27.0001 19.7 26.9001 19.2999 26.9001 18.7999V18.5C26.9001 18 27.0001 17.5999 27.1001 17.2999C27.3001 16.9999 27.5001 16.7 27.8001 16.5C28.1001 16.3 28.5001 16.2 28.9001 16.2C29.3001 16.2 29.6001 16.3 29.9001 16.5C30.2001 16.7 30.4001 16.9999 30.6001 17.2999C30.8001 17.5999 30.9001 18 30.9001 18.5V18.7999H31.0001ZM29.9001 18.6C29.9001 18.1 29.8001 17.8 29.7001 17.6C29.5001 17.4 29.3001 17.2 29.0001 17.2C28.4001 17.2 28.1001 17.6 28.1001 18.5V18.7999C28.1001 19.2999 28.2001 19.5999 28.3001 19.7999C28.5001 19.9999 28.7001 20.2 29.0001 20.2C29.3001 20.2 29.5001 20.0999 29.7001 19.7999C29.9001 19.5999 29.9001 19.1999 29.9001 18.7999V18.6Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.2 29.1999C28.4 29.2999 28.6 29.4999 28.6 29.7999V40.2999C28.6 40.4999 28.5 40.7999 28.2 40.8999C27.9 40.9999 27.7 40.9999 27.5 40.7999L24 37.8999H21.2C20.8 37.8999 20.5 37.5999 20.5 37.1999V32.6999C20.5 32.2999 20.8 32.0999 21.2 32.0999H24L27.6 29.1999C27.8 29.0999 28 28.9999 28.2 29.1999ZM27.3 31.0999L24.6 33.2999C24.5 33.3999 24.3 33.3999 24.2 33.3999H21.9V36.5999H24.2C24.3 36.5999 24.5 36.6999 24.6 36.6999L27.3 38.8999V31.0999Z"
                fill="#5DAAF8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.6 29.2001C33.9 28.9001 34.3 28.9001 34.5 29.2001C36 30.7001 36.9001 32.8001 36.9001 35.0001C36.9001 37.2001 36 39.2001 34.5 40.8001C34.2 41.1001 33.8 41.1001 33.6 40.8001C33.3 40.5001 33.3 40.1001 33.6 39.9001C34.9 38.6001 35.6 36.9001 35.6 35.1001C35.6 33.3001 34.9 31.5001 33.6 30.3001C33.3 29.9001 33.3 29.5001 33.6 29.2001ZM30.9 31.9001C31.2 31.6001 31.6 31.6001 31.8 31.9001C32.6 32.7001 33.1 33.8001 33.1 35.0001C33.1 36.2001 32.6 37.3001 31.8 38.1001C31.5 38.4001 31.1 38.4001 30.9 38.1001C30.6 37.8001 30.6 37.4001 30.9 37.2001C31.5 36.6001 31.8 35.8001 31.8 35.0001C31.8 34.2001 31.5 33.4001 30.9 32.8001C30.7 32.5001 30.7 32.1001 30.9 31.9001Z"
                fill="#5DAAF8"
            />
        </svg>
    );
}

export default React.memo(AudioThumbnailSVG);
