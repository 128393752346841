import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import CellBaseDropdown from 'components/selects/CellBase';
import { Grid, Popper, Paper, Chip } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { MULTIPLE_SELECTIONS } from 'const/columnTypes';
import { INPUT_PADDING_LEFT, INPUT_QUICK_FILTER_HEIGHT, INPUT_RADIUS, DISABLED_OPACITY } from 'const/style';
import { MAX_OPTIONS } from 'const/gridUI';
import { OPERATOR } from 'gridUI/conditions';

const useStyles = makeStyles(theme => ({
    zIndex: {
        zIndex: theme.zIndex.modal + 200
    },
    selection: {
        fontSize: `0.8125rem !important`,
        color: theme.colors.primaryText,
        background: theme.colors.paleGrey,
        height: INPUT_QUICK_FILTER_HEIGHT,
        overflow: 'hidden',
        paddingLeft: INPUT_PADDING_LEFT,
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: INPUT_RADIUS,
        border: `none`
    },
    focus: {
        borderBottom: `1px solid ${theme.palette.primary.main} !important`
    },
    paper: {
        minWidth: 200,
        padding: 10
    },
    chip: {
        height: 22,
        maxHeight: 22,
        '& svg': {
            marginLeft: 0
        }
    },
    placeholder: {
        color: theme.colors.midGrey,
        fontSize: 13,
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY,
        cursor: 'not-allowed'
    }
}));

function GroupTagQuickFilter({
    column,
    columnId,
    quickFilters,
    isDisabled,
    onQuickFilterChange,
    onRefreshGrid,
    t,
    ...rest
}) {
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState(
        (quickFilters[columnId] && quickFilters[columnId].value) || []
    );
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const singleSelectOptions = useMemo(() => {
        return isEmpty(column.options)
            ? []
            : column.options.map(option => {
                  return {
                      value: option.label,
                      label: option.label
                  };
              });
    }, [column.options]);

    const callServer = options => {
        onQuickFilterChange &&
            onQuickFilterChange({
                columnId,
                value: options,
                type: MULTIPLE_SELECTIONS,
                operator: OPERATOR.contains
            });
        onRefreshGrid && onRefreshGrid();
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleOptionChange = options => {
        setAnchorEl(null);
        setSelectedOptions(options);
        callServer(options);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleDelete = option => {
        let newOptions = selectedOptions.filter(item => item.value !== option.value);
        setSelectedOptions(newOptions);
        callServer(newOptions);
    };

    return (
        <Grid className={classes.dateInput}>
            <div
                onClick={handleClick}
                className={`${classes.selection} ${anchorEl ? classes.focus : ''} ${
                    isDisabled ? classes.disabled : ``
                }`}
            >
                {isEmpty(selectedOptions) && (
                    <span className={classes.placeholder}>{t(`global_label_select_options`)}</span>
                )}
                {!isEmpty(selectedOptions) &&
                    selectedOptions.map(option => (
                        <Chip
                            size="small"
                            key={option.value}
                            onDelete={() => handleDelete(option)}
                            className={classes.chip}
                            label={option.label}
                            variant="outlined"
                            color="primary"
                        />
                    ))}
            </div>
            <Popper className={classes.zIndex} id={id} open={open} anchorEl={anchorEl} transition>
                <Paper className={classes.paper}>
                    <CellBaseDropdown
                        options={singleSelectOptions}
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        maxSize={MAX_OPTIONS}
                        defaultValue={selectedOptions}
                        onBlur={handleClickAway}
                        isMulti
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(GroupTagQuickFilter);
