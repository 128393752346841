import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { object, number } from 'yup';
import InputText from 'components/inputs/InputText';
import classNames from 'classnames';

function CustomRowHeight({ onClose, rowHeight, onHeightChange }) {
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            value: rowHeight
        },
        validationSchema: object().shape({
            value: number()
                .required(t('global_this_field_is_required'))
                .min(36, 'Row height must be a value between 35px and 560px')
                .max(560, 'Row height must be a value between 35px and 560px')
        }),
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });

            const newHeight = values?.value;

            onHeightChange && onHeightChange(newHeight);
        }
    });

    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle title={t('custom_row_height')} onClose={onClose} />
            <DialogContent className={'w-[400px]'}>
                <div className="flex flex-col gap-3.5">
                    <p className="caption">Set a custom row height to suit your needs</p>
                    <div className="relative mb-2.5">
                        <div className="flex flex-row gap-2 items-center">
                            <InputText
                                name="value"
                                type="number"
                                value={values.value}
                                disabled={isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.value && touched.value}
                                placeholder={'px'}
                                autoFocus
                                autoSelect={true}
                                isShowErrorMessage={false}
                            />
                            <div className="flex gap-1">
                                <p className="body1 inline">{t('global_pixels')}</p>{' '}
                                <p className="caption whitespace-nowrap">
                                    {' '}
                                    ({t('global_min')}: 36 - {t('global_max')}: 560)
                                </p>
                            </div>
                        </div>
                        <p
                            item
                            className={classNames(`absolute mt-2 invisible text-error text-sm`, {
                                visible: errors.value
                            })}
                        >
                            {errors.value}
                        </p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Button onClick={onClose} width={120} variant="outlined">
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <div className={'relative'}>
                            <Button width={120} disabled={!isValid || isSubmitting} type="submit" variant="contained">
                                {t('global_set')}
                            </Button>
                            {isSubmitting && (
                                <CircularProgress
                                    size={24}
                                    className={'text-brand-main absolute top-[50%] left-[50%] -mt-3 -ml-3'}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default CustomRowHeight;
