import React from 'react';

const JSONFILESVG = ({ color = '#78778B', ...rest }) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_192_12000)">
                <path
                    d="M20.5 6.39258H15.5C15.224 6.39258 15 6.16758 15 5.89256V0.892594C15 0.616594 14.776 0.392578 14.5 0.392578H4.5C3.67298 0.392578 3 1.06556 3 1.89258V22.8926C3 23.7196 3.67298 24.3926 4.5 24.3926H19.5C20.327 24.3926 21 23.7196 21 22.8926V6.89259C21 6.61659 20.776 6.39258 20.5 6.39258ZM20 22.8926C20 23.1676 19.776 23.3926 19.5 23.3926H4.5C4.224 23.3926 3.99998 23.1676 3.99998 22.8926V1.89258C3.99998 1.61756 4.224 1.39256 4.5 1.39256H14V5.89256C14 6.71958 14.673 7.39256 15.5 7.39256H20V22.8926V22.8926Z"
                    fill={color}
                />
                <path
                    d="M20.8533 6.53932L14.8533 0.539316C14.6583 0.344316 14.3413 0.344316 14.1463 0.539316C13.9512 0.734316 13.9512 1.05133 14.1463 1.24633L20.1463 7.24633C20.2443 7.34332 20.3723 7.39235 20.5003 7.39235C20.6283 7.39235 20.7563 7.34336 20.8533 7.24633C21.0483 7.05133 21.0483 6.73432 20.8533 6.53932Z"
                    fill={color}
                />
                <rect y="11.4504" width="24" height="10.6375" rx="1" fill={color} />
                <path
                    d="M3.07097 19.548C2.83568 19.5583 2.65665 19.5225 2.53389 19.4406C2.41624 19.3588 2.35742 19.2335 2.35742 19.0647C2.35742 18.9112 2.39578 18.7987 2.47251 18.7271C2.54924 18.6503 2.66944 18.6069 2.83312 18.5966L3.07864 18.5813C3.44692 18.5659 3.63106 18.3486 3.63106 17.9291V14.6453C3.63106 14.4663 3.68733 14.323 3.79986 14.2156C3.91239 14.1082 4.05817 14.0545 4.2372 14.0545C4.41622 14.0545 4.55944 14.1082 4.66686 14.2156C4.77939 14.323 4.83565 14.4663 4.83565 14.6453V17.9138C4.83565 18.9266 4.32927 19.4662 3.31649 19.5327L3.07097 19.548Z"
                    fill="white"
                />
                <path
                    d="M7.85828 19.5711C7.45419 19.5711 7.07824 19.525 6.73041 19.4329C6.38259 19.3358 6.09871 19.2002 5.87876 19.0263C5.80203 18.97 5.74577 18.9112 5.70996 18.8498C5.67927 18.7833 5.66393 18.7015 5.66393 18.6043C5.66393 18.4764 5.70229 18.3639 5.77902 18.2667C5.86086 18.1695 5.95293 18.1209 6.05523 18.1209C6.11149 18.1209 6.1652 18.1312 6.21635 18.1516C6.27262 18.167 6.33911 18.1977 6.41584 18.2437C6.63578 18.3818 6.85829 18.4841 7.08335 18.5506C7.30841 18.612 7.55649 18.6427 7.82759 18.6427C8.16518 18.6427 8.42349 18.5864 8.60251 18.4739C8.78154 18.3613 8.87105 18.2002 8.87105 17.9905C8.87105 17.8319 8.78666 17.7041 8.61786 17.6069C8.45418 17.5097 8.16006 17.4176 7.73552 17.3307C7.25982 17.2335 6.87875 17.1158 6.59231 16.9777C6.31098 16.8396 6.10382 16.6683 5.97083 16.4637C5.84296 16.2591 5.77902 16.011 5.77902 15.7194C5.77902 15.3972 5.87109 15.1082 6.05523 14.8524C6.24449 14.5916 6.50279 14.3895 6.83016 14.2463C7.16263 14.098 7.53603 14.0238 7.95035 14.0238C8.67668 14.0238 9.27514 14.2054 9.74572 14.5686C9.82245 14.6299 9.87616 14.6939 9.90685 14.7604C9.94265 14.8217 9.96055 14.8985 9.96055 14.9905C9.96055 15.1184 9.91963 15.231 9.83779 15.3281C9.76107 15.4253 9.67156 15.4739 9.56925 15.4739C9.51299 15.4739 9.45928 15.4662 9.40813 15.4509C9.3621 15.4356 9.2956 15.4023 9.20865 15.3512C8.99893 15.2182 8.80456 15.1184 8.62553 15.0519C8.45162 14.9854 8.22912 14.9522 7.95802 14.9522C7.646 14.9522 7.40048 15.0136 7.22146 15.1363C7.04243 15.254 6.95292 15.4176 6.95292 15.6274C6.95292 15.7501 6.98616 15.8524 7.05266 15.9343C7.12427 16.011 7.23936 16.08 7.39792 16.1414C7.56161 16.2028 7.79178 16.2667 8.08845 16.3332C8.7841 16.4867 9.28281 16.6836 9.5846 16.924C9.8915 17.1644 10.045 17.4944 10.045 17.9138C10.045 18.2411 9.95288 18.5301 9.76874 18.7808C9.58972 19.0314 9.33396 19.2258 9.00149 19.3639C8.67412 19.502 8.29305 19.5711 7.85828 19.5711Z"
                    fill="white"
                />
                <path
                    d="M13.393 19.5711C12.8559 19.5711 12.3879 19.4585 11.9889 19.2335C11.5951 19.0033 11.2907 18.681 11.0759 18.2667C10.8611 17.8473 10.7536 17.3562 10.7536 16.7936C10.7536 16.2309 10.8611 15.7425 11.0759 15.3281C11.2907 14.9087 11.5951 14.5865 11.9889 14.3614C12.3879 14.1363 12.8559 14.0238 13.393 14.0238C13.9301 14.0238 14.3955 14.1363 14.7894 14.3614C15.1884 14.5865 15.4953 14.9087 15.7101 15.3281C15.9249 15.7425 16.0324 16.2309 16.0324 16.7936C16.0324 17.3562 15.9249 17.8473 15.7101 18.2667C15.4953 18.681 15.1884 19.0033 14.7894 19.2335C14.3955 19.4585 13.9301 19.5711 13.393 19.5711ZM13.393 18.6427C13.8431 18.6427 14.1961 18.4841 14.4518 18.167C14.7127 17.8498 14.8431 17.3921 14.8431 16.7936C14.8431 16.1951 14.7152 15.7399 14.4595 15.4279C14.2037 15.1107 13.8482 14.9522 13.393 14.9522C12.9378 14.9522 12.5823 15.1107 12.3265 15.4279C12.0759 15.7399 11.9506 16.1951 11.9506 16.7936C11.9506 17.3921 12.0784 17.8498 12.3342 18.167C12.5899 18.4841 12.9429 18.6427 13.393 18.6427Z"
                    fill="white"
                />
                <path
                    d="M21.0741 14.0545C21.2429 14.0545 21.3784 14.1108 21.4807 14.2233C21.5881 14.3307 21.6418 14.4714 21.6418 14.6453V18.9496C21.6418 19.1286 21.5881 19.2744 21.4807 19.3869C21.3784 19.4943 21.2429 19.548 21.0741 19.548C20.8899 19.548 20.7416 19.4815 20.629 19.3485L18.0587 16.057V18.9496C18.0587 19.1286 18.0076 19.2744 17.9053 19.3869C17.8081 19.4943 17.6751 19.548 17.5063 19.548C17.3375 19.548 17.202 19.4943 17.0997 19.3869C16.9974 19.2744 16.9462 19.1286 16.9462 18.9496V14.6453C16.9462 14.4714 16.9974 14.3307 17.0997 14.2233C17.2071 14.1108 17.3452 14.0545 17.514 14.0545C17.7033 14.0545 17.849 14.121 17.9513 14.254L20.5216 17.5225V14.6453C20.5216 14.4714 20.5728 14.3307 20.6751 14.2233C20.7774 14.1108 20.9104 14.0545 21.0741 14.0545Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_192_12000">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.392578)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(JSONFILESVG);
