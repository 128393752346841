import React, { useCallback, useMemo, useState } from 'react';
import Dialog from 'components/dialog/Dialog';
import ImportLocalization from '.';
import { isFileJSON, isFilePO } from 'utils/fileUtils';
import ImportJson from 'gridUI/importExport/ImportJson';
import ImportPo from 'gridUI/importExport/ImportPo';
import { useGridDetail } from 'hooks/grid';

const ImportLocalizationByType = ({ dbId, viewId, gridId, open, onClose }) => {
    const [files, setFiles] = useState(null);
    const gridDetail = useGridDetail({ dbId, gridId });

    const isValidJSONFile = useMemo(() => {
        return isFileJSON(files?.[0]);
    }, [files]);

    const isValidPoFile = useMemo(() => {
        return isFilePO(files?.[0]);
    }, [files]);

    const handleCloseJsonFile = useCallback(() => {
        setFiles(null);
    }, []);

    const handleSetFiles = useCallback(
        f => {
            setFiles(f);
            onClose();
        },
        [onClose]
    );

    return (
        <>
            <Dialog maxWidth={false} open={open} onClose={onClose}>
                <ImportLocalization gridDetail={gridDetail} onClose={onClose} handleSetFiles={handleSetFiles} />
            </Dialog>
            <Dialog maxWidth={false} open={isValidJSONFile} onClose={handleCloseJsonFile}>
                <ImportJson isImport dbId={dbId} viewId={viewId} onClose={handleCloseJsonFile} files={files} />
            </Dialog>
            <Dialog maxWidth={false} open={isValidPoFile} onClose={handleCloseJsonFile}>
                <ImportPo
                    gridDetail={gridDetail}
                    isImport
                    dbId={dbId}
                    viewId={viewId}
                    onClose={handleCloseJsonFile}
                    files={files}
                />
            </Dialog>
        </>
    );
};

export default React.memo(ImportLocalizationByType);
