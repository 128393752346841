import React from 'react';

function UndoSVG({ color = '#78778B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M9.375 6C7.3875 6 5.5875 6.7425 4.2 7.95L1.5 5.25V12H8.25L5.535 9.285C6.5775 8.415 7.905 7.875 9.375 7.875C12.03 7.875 14.2875 9.6075 15.075 12L16.8525 11.415C15.81 8.2725 12.8625 6 9.375 6Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(UndoSVG);
