import React, { useMemo, useCallback, useState, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PopperMenu from 'components/menus/Popper';
import { makeStyles } from '@material-ui/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { useSortCriteriaProperties, useSortCriteriaWithWorkspaceDbId } from 'hooks/workspace';
import ListItem from 'components/list/Item';
import { useDispatch } from 'react-redux';
import { updateSortCriteria } from 'workspaces/actions';
import { SORT_BY_CRITERIA } from 'const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: 210,
        height: 40,
        padding: `0 14px`,
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        background: theme.colors.white,
        cursor: 'pointer',
        '& svg': {
            verticalAlign: 'middle'
        }
    },
    label: {
        padding: '9px 14px 4px',
        '& span': {
            fontSize: 12
        }
    },
    borderTop: {
        borderTop: `1px solid ${theme.colors.border}`
    },
    paperClassName: {
        padding: `0 0 9px !important`
    }
}));

const DatabaseSelect = ({ workspaceId, dbId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState();
    const wrapperRef = useRef();

    const workspaceSortCriteria = useSortCriteriaProperties(workspaceId);
    const dbSortCriteria = useSortCriteriaWithWorkspaceDbId({ workspaceId, dbId });

    const workspaceOptions = useMemo(() => {
        return [
            {
                label: t('sort_by'),
                options: [
                    { value: SORT_BY_CRITERIA.CREATED_DATE, label: t('created_date'), type: 'sort' },
                    // { value: SORT_BY_CRITERIA.LAST_MODIFIED, label: 'Last modified', type: 'sort' },
                    { value: SORT_BY_CRITERIA.ALPHABETICAL, label: t('alphabetical'), type: 'sort' }
                ]
            }
        ];
    }, [t]);

    const orderOptions = useMemo(() => {
        const sortCriteria = workspaceSortCriteria;
        if (sortCriteria?.field !== SORT_BY_CRITERIA.ALPHABETICAL) {
            return [
                {
                    label: t('order'),
                    options: [
                        { value: 'DESC', label: t('newest_first'), type: 'direction' },
                        { value: 'ASC', label: t('oldest_first'), type: 'direction' }
                    ]
                }
            ];
        }
        return [
            {
                label: t('order'),
                options: [
                    { value: 'ASC', label: 'A - Z', type: 'direction' },
                    { value: 'DESC', label: 'Z - A', type: 'direction' }
                ]
            }
        ];
    }, [workspaceSortCriteria, t]);

    const options = useMemo(() => {
        const temp = workspaceOptions;
        return [...temp, ...orderOptions];
    }, [orderOptions, workspaceOptions]);

    const selectedOptions = useMemo(() => {
        return Object.values(workspaceSortCriteria || {});
    }, [workspaceSortCriteria]);

    const label = useMemo(() => {
        const sortByOpts = options.find(el => el.label === t('sort_by'))?.options || [];
        if (sortByOpts.length > 0) {
            const option = sortByOpts.find(el => {
                return el.value === workspaceSortCriteria?.field;
            });
            if (option) {
                return `${t('sort_by')}: ${option.label}`;
            }
        }
        return '';
    }, [options, workspaceSortCriteria, t]);

    const handleOpen = useCallback(
        e => {
            setAnchorEl(anchorEl ? null : wrapperRef.current);
        },
        [anchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleChange = useCallback(
        option => {
            if (selectedOptions.includes(option.value)) return;
            handleClose();
            let data = {
                ...workspaceSortCriteria
            };
            if (option.type === 'sort') {
                if (data.field === SORT_BY_CRITERIA.ALPHABETICAL) {
                    data.direction = 'DESC';
                } else if (option.value === SORT_BY_CRITERIA.ALPHABETICAL) {
                    data.direction = 'ASC';
                }
                data.field = option.value;
            } else {
                data.direction = option.value;
            }
            dispatch(
                updateSortCriteria({
                    workspaceId,
                    dbId,
                    data,
                    isRemoveNew: !dbId
                })
            );
        },
        [dbId, dispatch, workspaceId, workspaceSortCriteria, handleClose, selectedOptions]
    );

    if (!workspaceSortCriteria && !dbSortCriteria) return null;

    return (
        <Grid item>
            <Grid
                ref={wrapperRef}
                item
                container
                wrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                className={classes.wrapper}
                onClick={handleOpen}
            >
                <Grid item>
                    <Typography variant="body2">{label}</Typography>
                </Grid>
                <Grid item>
                    <ArrowDownSVG />
                </Grid>
            </Grid>
            {anchorEl && (
                <PopperMenu
                    handleClickAway={handleClose}
                    anchorEl={anchorEl}
                    style={{ width: wrapperRef.current.offsetWidth }}
                    paperClassName={classes.paperClassName}
                >
                    <DatabaseSelectList
                        selectedOptions={selectedOptions}
                        options={options}
                        handleChange={handleChange}
                    />
                </PopperMenu>
            )}
        </Grid>
    );
};

export function DatabaseSelectList({ selectedOptions = [], options = [], handleChange }) {
    const classes = useStyles();

    return (
        <Grid item className={classes.list}>
            {options?.map((opt, idx) => (
                <Grid key={idx} item className={classes.item}>
                    <Grid item className={`${classes.label} ${idx > 0 ? classes.borderTop : ''}`}>
                        <Typography variant="caption">{opt.label}</Typography>
                    </Grid>
                    {opt.options.map(el => (
                        <ListItem
                            key={el.value}
                            onClick={() => handleChange(el)}
                            name={el.label}
                            isSelected={selectedOptions.includes(el.value)}
                        />
                    ))}
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(DatabaseSelect);
