import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 480,
        position: 'relative',
        borderRadius: 6,
        padding: theme.spacing(5)
    },
    closeButton: {
        position: 'absolute',
        right: 24,
        top: 24
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function GenerateKeyConfirm({ onClose, onConfirm, isSubmitting }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IconButton size="small" className={classes.closeButton} onClick={onClose}>
                <CloseIconSVG size="medium" />
            </IconButton>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h3" display="inline">
                        {t('regenerate_key')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" display="inline">
                        {t('api_key_regenerate_confirm_msg')}
                    </Typography>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t('api_key_regenerate_confirm_cancel')}
                        </Button>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <Button
                            variant="contained"
                            width={164}
                            onClick={onConfirm}
                            disabled={isSubmitting}
                            className={classes.button}
                        >
                            {t('api_key_regenerate_confirm_ok')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default React.memo(GenerateKeyConfirm);
