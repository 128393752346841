import React from 'react';

function WorkspaceSVG({ color = '#AAAAAE', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
            <g fill="none" fillRule="evenodd">
                <rect width="18.5" height="16.5" x=".75" y=".75" stroke={color} strokeWidth="1.5" rx="2" />
                <path
                    stroke={color}
                    strokeWidth="1.5"
                    d="M.75 7.25h18.5V2c0-.69-.56-1.25-1.25-1.25H2C1.31.75.75 1.31.75 2v5.25z"
                />
                <circle cx="4" cy="4" r="1" fill={color} transform="matrix(1 0 0 -1 0 8)" />
                <circle cx="8" cy="4" r="1" fill={color} transform="matrix(1 0 0 -1 0 8)" />
            </g>
        </svg>
    );
}

export default React.memo(WorkspaceSVG);
