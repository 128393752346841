import * as types from '../types';
import { exportViewApi, getViewRecordsApiV2 } from 'services/view';
import { exportLocalizationApi, exportTranslationApi } from 'services/grid';
import { enqueueSnackbar } from 'notifier/actions';
import { formatQuickFilters } from 'utils/gridUI/filter';
import * as rowActions from './row';
import { getPath } from 'utils/gridUI/pathTag';
import { OPERATOR } from 'gridUI/conditions';

export function resetExportRemovedColumns() {
    return {
        type: types.RESET_EXPORT_REMOVED_COLUMNS
    };
}

export function addExportRemovedColumn({ columnId }) {
    return {
        type: types.ADD_EXPORT_REMOVED_COLUMN,
        payload: {
            columnId
        }
    };
}

export function restoreExportRemovedColumn({ columnId }) {
    return {
        type: types.RESTORE_EXPORT_REMOVED_COLUMN,
        payload: {
            columnId
        }
    };
}

export function exportLocalization({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId, gridId } = gridUI;
            await exportLocalizationApi({
                ...body,
                dbId,
                viewId: defaultAccessViewId,
                gridId
            });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function exportTranslation({ query, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId } = gridUI;
            await exportTranslationApi({
                query,
                dbId,
                viewId: defaultAccessViewId
            });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function exportViewData({ queryParams, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId } = gridUI;
            await exportViewApi({ dbId, viewId: defaultAccessViewId, queryParams });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function fetchExportPreviewData({
    defaultAccessViewId: defaultAccessViewIdParam,
    dbId: dbIdParam,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    withQuickFilterAndSort,
    widthSelectedPaths,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            quickFilters,
            quickSorts,
            defaultAccessViewId: defaultAccessViewIdStore,
            dbId: dbIdStore,
            dragNodeIds,
            tree
        } = gridUI;

        try {
            const dbId = dbIdParam || dbIdStore;
            const defaultAccessViewId = defaultAccessViewIdParam || defaultAccessViewIdStore;

            const pathNames = dragNodeIds?.map(nodeId => getPath({ tree, nodeId })?.pathName);

            const extraFilters = widthSelectedPaths
                ? dragNodeIds?.length
                    ? { _pathTag: { value: pathNames, operator: OPERATOR.startsWith, type: '_pathTag' } }
                    : {}
                : {};

            const filter = withQuickFilterAndSort ? { ...quickFilters } : {};

            const quickFiltersFormatted = formatQuickFilters({ ...filter, ...extraFilters });

            const quickSortsFormatted = withQuickFilterAndSort ? quickSorts : {};

            const { recordIds, data, recordMetaData } = await getViewRecordsApiV2({
                defaultAccessViewId,
                dbId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                filterQuery: quickFiltersFormatted,
                sortQuery: quickSortsFormatted
            });
            dispatch(_fetchExportPreviewDataSuccess({ viewId: defaultAccessViewId, rows: recordIds, data }));
            dispatch(rowActions.updateRecordMetaData({ newRecordMetaData: recordMetaData }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

function _fetchExportPreviewDataSuccess({ viewId, data, rows }) {
    return {
        type: types.FETCH_EXPORT_PREVIEW_DATA,
        payload: {
            viewId,
            data,
            rows
        }
    };
}
