import React from 'react';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useIsShareViewLink } from 'hooks/app';
import PopperMenu from 'components/menus/Popper';
import NewColumnFormat from 'gridUI/table/grid/columns/NewColumnFormat';
import { checkContainId } from 'utils/clickAway';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { DEFAULT_COLUMN_WIDTH } from 'const/gridUI';
import { useToggleFakeColumn } from 'hooks/gridUI/column';
import { useDispatch } from 'react-redux';

const LastColumn = ({ prevColumnId, style, ...rest }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isShareViewLink = useIsShareViewLink();
    const containerRef = React.useRef();
    const rootRef = React.useRef();
    const toggleFakeColumn = useToggleFakeColumn();
    const addRef = React.useRef();
    const dispatch = useDispatch();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleClick = React.useCallback(
        event => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClickAway = React.useCallback(
        e => {
            if (
                checkContainId(e, 'column-setting') ||
                checkContainId(e, 'new-column-format') ||
                checkContainId(e, 'ConfirmSwitchingBox') ||
                checkContainId(e, 'calendar-portal') ||
                checkContainId(e, 'formula-suggestion') ||
                checkContainId(e, 'confirm-delete-column') ||
                checkContainId(e, 'ConfirmSwitchingBox') ||
                checkContainId(e, 'popper-language') ||
                checkContainId(e, 'popper-timezone')
            ) {
                return;
            }

            handleClose && handleClose();
        },
        [handleClose]
    );

    React.useEffect(() => {
        if (toggleFakeColumn) {
            addRef.current.click();
        }
    }, [toggleFakeColumn, dispatch]);

    return (
        <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
            <div
                ref={rootRef}
                style={{
                    ...style,
                    borderBottom: '1px solid rgb(233, 234, 239)'
                }}
                {...rest}
            >
                {!isShareViewLink && (
                    <Tooltip title={t('create_column')}>
                        <div
                            ref={addRef}
                            onClick={handleClick}
                            style={{
                                width: DEFAULT_COLUMN_WIDTH,
                                height: 36,
                                background: '#F4F5F7',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight: '1px solid rgb(233, 234, 239)',
                                borderBottom: '1px solid rgb(233, 234, 239)'
                            }}
                        >
                            <AddIconSVG />
                        </div>
                    </Tooltip>
                )}

                <div ref={containerRef} />
                {anchorEl && (
                    <PopperMenu
                        anchorEl={anchorEl}
                        handleClickAway={handleClickAway}
                        id={'column-setting'}
                        placement={'bottom-end'}
                    >
                        <NewColumnFormat columnId={prevColumnId} onClose={handleClose} onClickAway={handleClickAway} />
                    </PopperMenu>
                )}
            </div>
        </AccessControl>
    );
};

export default React.memo(LastColumn);
