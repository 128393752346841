import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import LinkSVG from 'assets/images/svg/LinkSVG';
import InputViewCopyOnly from 'components/inputs/InputViewCopyOnly';
import OpenNewPageSVG from 'assets/images/svg/OpenNewPageSVG';
import ButtonBase from 'components/button/Base';
import ShareLinkViewIlluSVG from 'assets/images/svg/ShareLinkViewIlluSVG';
import { togglePublicAdvancedSearchUrlApi } from 'services/advanced';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { sendManualTrack } from 'tracker';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    notShare: {
        padding: theme.spacing(4)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    mh200: {
        minHeight: 200
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    linkIcon: {
        display: 'flex',
        marginRight: theme.spacing(2)
    },
    centerText: {
        textAlign: 'center'
    },
    shareIcon: {
        cursor: 'pointer'
    },
    dpFlex: {
        display: 'flex'
    }
}));

function Public({ access, advancedSearchId, onClose, updatePublicId }) {
    const classes = useStyles();
    const theme = useTheme();
    const { workspaceId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [key, setKey] = React.useState(access?.publicId || ``);

    const shareLink = React.useMemo(() => {
        return `${window.location.origin}/search/shared/${key}`;
    }, [key]);

    const openShareLink = React.useCallback(() => {
        window.open(shareLink, '_blank');
        sendManualTrack({ type: 'Open Share Search Link' });
    }, [shareLink]);

    const createShareLink = React.useCallback(async () => {
        try {
            setIsSubmitting(true);
            const res = await togglePublicAdvancedSearchUrlApi({
                wsId: workspaceId,
                aId: advancedSearchId,
                state: !key ? 1 : 0
            });
            setKey(!key ? res?.publicId : ``);
            updatePublicId({ publicId: res?.publicId });
            setIsSubmitting(false);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            setIsSubmitting(false);
        }
    }, [advancedSearchId, dispatch, key, workspaceId, updatePublicId]);

    const disabledLink = React.useCallback(async () => {
        try {
            setIsSubmitting(true);
            await togglePublicAdvancedSearchUrlApi({
                wsId: workspaceId,
                aId: advancedSearchId,
                state: 0
            });
            setKey(``);
            updatePublicId({ publicId: `` });

            setIsSubmitting(false);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            setIsSubmitting(false);
        }
    }, [advancedSearchId, dispatch, workspaceId, updatePublicId]);

    if (!key)
        return (
            <Grid container direction="column" justify="center" alignItems="center" className={classes.notShare}>
                <Grid item className={classes.mb2}>
                    <ShareLinkViewIlluSVG />
                </Grid>
                <Grid item className={classes.mb2}>
                    <Typography variant="h3">Public this query</Typography>
                </Grid>
                <Grid item className={`${classes.centerText} ${classes.mb2}`}>
                    <Typography variant="body2">
                        Create a shareable link for this search to share it with <br /> anyone or embed it on a website
                    </Typography>
                </Grid>
                <Grid item className={classes.buttonWrapper}>
                    <ButtonBase onClick={createShareLink} disabled={isSubmitting} variant="contained" width={212}>
                        {t('create_shareable_link')}
                    </ButtonBase>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
            </Grid>
        );

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.mb3}>
                <Typography variant="body2">
                    Public share to anyone, even outside Gridly. The public shared grid is read-only.
                </Typography>
            </Grid>
            <Grid item className={classes.mb3}>
                <Grid container direction="column">
                    <Grid item className={classes.mb2}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item className={classes.linkIcon}>
                                <LinkSVG />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">Shareable Link</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <InputViewCopyOnly
                            action={
                                <Grid container alignItems="center" direction="row">
                                    <Grid item className={classes.shareIcon}>
                                        <OpenNewPageSVG color={theme.palette.primary.main} onClick={openShareLink} />
                                    </Grid>
                                </Grid>
                            }
                            value={shareLink}
                            secure={false}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(3) }}>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid
                            container
                            onClick={disabledLink}
                            style={{ cursor: 'pointer' }}
                            direction="row"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item className={classes.dpFlex}>
                                <CloseIconSVG color={theme.palette.primary.main} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        color: theme.palette.primary.main
                                    }}
                                    variant="body1"
                                >
                                    Disable Shareable Link
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={onClose} width={122} variant="contained">
                            Done
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Public;
