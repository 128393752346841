import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import GridListSkeleton from 'grids/GridListSkeleton';
import Skeleton from 'components/skeleton/Base';
import Divider from 'components/divider/Base';
import useMediaQueries from '../hooks/useMediaQueries';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 24,
        background: theme.colors.white,
        padding: `20px 8px`,
        borderRadius: 4
    },
    gridList: {
        padding: `0 8px 8px`,
        marginTop: 24
    },

    divider: {
        width: 'calc(100% - 48px)',
        margin: 'auto',
        marginTop: 14
    },

    fullWidth: {
        width: '100%'
    }
}));

function DatabaseItemSkeleton() {
    const classes = useStyles();
    const { isMobile } = useMediaQueries();

    return (
        <Grid container className={`${classes.root}`} direction="row" spacing={0}>
            <Grid item xs={12} style={{ padding: '0 24px' }}>
                <Grid container direction={'row'} alignItems="center">
                    <Grid item xs={9}>
                        <Grid container alignItems="center" justify="flex-start">
                            <Grid item className={classes.fullWidth}>
                                <Grid
                                    container
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    style={{ position: 'relative' }}
                                >
                                    <Grid item>
                                        <Skeleton width={150} height={36} />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton width={10} height={10} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justify={isMobile ? 'flex-start' : 'flex-end'}>
                            <Skeleton width={122} height={36} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid xs={12} item className={classes.gridList}>
                <Grid container direction="column">
                    <GridListSkeleton />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(DatabaseItemSkeleton);
