import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    tooltipFormula: {
        fontSize: '13px',
        padding: '6px 6px',
        '& ul': {
            paddingInlineStart: '23px',
            margin: '5px 0',
            '& li': {
                lineHeight: '20px',
                fontWeight: 400
            }
        }
    },
    white: {
        color: theme.colors.white
    },
    examples: {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '20px'
    },
    tooltip: {
        backgroundColor: theme.colors.tooltip
    },
    tooltipPlacementLeft: {
        margin: '0 7px'
    },
    arrow: {
        color: theme.colors.tooltip
    }
}));

function FormulaTooltip({ children }) {
    const classes = useStyles();

    return (
        <Tooltip
            classes={classes}
            title={
                <Grid container direction="column" className="text-sm p-1.5">
                    <Grid item>
                        <p className={`body2 font-bold text-white`}>Examples:</p>
                    </Grid>
                    <Grid item>
                        <ul
                            style={{
                                paddingInlineStart: '23px'
                            }}
                            className="px-0 py-1"
                        >
                            <li className="font-semibold leading-5">Amount * Price</li>
                            <li className="font-semibold leading-5">Average(Pricing, Amount)</li>
                            <li className="font-semibold leading-5">Concat(FirstName, " ", LastName)</li>
                            <li className="font-semibold leading-5">If(Price * Quantity &gt; 5, "Yes", "No")</li>
                        </ul>
                    </Grid>
                </Grid>
            }
            placement="left-start"
            arrow
        >
            {children}
        </Tooltip>
    );
}

export default React.memo(FormulaTooltip);
