import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getLabelColumnType, TRANSLATION_TYPES } from 'gridUI/column-types';
import SvgIcon from 'components/svg-icon/Base';
import * as gridActions from 'gridUI/actions';
import InputText from 'components/inputs/InputText';
import ColumnTypesSelect from 'gridUI/common/ColumnTypeSelect';
import { Grid, Collapse, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import ExtraOptionsByType from './ExtraOptionsByType';
import trim from 'lodash/trim';
import * as columnTypes from 'const/columnTypes';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import { formatOptions, convertServerDefaultValue } from 'utils/gridUI/formatData';
import Divider from 'components/divider/Base';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import { NUMBER_TYPES, REFERENCE_SELECTION_TYPES, FILE_UPLOAD_TYPES, CREATE_COLUMN_TYPES } from 'const/gridUI';
import { isLDEmpty } from 'utils/object';
import { getFormulaServerValue } from 'utils/gridUI/formula';
import { validateFormulaApi } from 'services/grid';
import CountryFlag from 'components/svg-icon/CountryFlag';
import DefaultValue from './defaultValue';
import { SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH } from 'const/languageData';
import { useTranslation } from 'react-i18next';
import { useMetaData, useColumnsWithData, useViewColumnWidthMetadata } from 'hooks/gridUI';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { generateDefaultName } from 'utils/name';

const useStyles = makeStyles(theme => ({
    root: {
        width: 325,
        background: theme.colors.white
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    dpFlex: {
        display: 'flex',
        cursor: 'pointer'
    },
    showMore: {
        color: theme.colors.dodgerBlue
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    setting: {
        paddingLeft: 20,
        paddingRight: 20
    },
    actions: {
        paddingLeft: 20,
        paddingRight: 20
    },
    exampleValues: {
        background: theme.colors.ghostwhite,
        width: '100%',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderRadius: 4,
        maxHeight: 200,
        overflowY: 'auto'
    },
    bullet: {
        display: 'inline-block',
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: theme.colors.steel,
        marginRight: 8,
        verticalAlign: '10%'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    split: {
        height: 40,
        borderTop: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        marginBottom: theme.spacing(3),
        paddingLeft: 20,
        paddingRight: 20
    },
    spt3: {
        marginTop: theme.spacing(3),
        maxWidth: 260
    },
    scroll: {
        maxHeight: `calc(100vh - 250px)`,
        overflow: 'hidden auto'
    }
}));

function isNeedExtraOption(type) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.BOOLEAN:
        case columnTypes.DATETIME:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
            return false;
        default:
            return true;
    }
}

function isNeedDefaultValue(type) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.BOOLEAN:
        case columnTypes.DATETIME:
        case columnTypes.NUMBER:
        case columnTypes.SINGLE_SELECTION:
        case columnTypes.MULTIPLE_SELECTIONS:
            return true;
        default:
            return false;
    }
}

function isHasOptionEmptyName(options = []) {
    let isHasEmpty = false;
    for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (!option.label) {
            isHasEmpty = true;
            break;
        }
    }
    return isHasEmpty;
}

function NewColumnFormat({ onClickAway, onClose, columnId, type = CREATE_COLUMN_TYPES.RIGHT }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const metaData = useMetaData();
    const dispatch = useDispatch();
    const columns = useColumnsWithData();
    const viewColumns = useViewColumnWidthMetadata();

    const name = React.useMemo(() => {
        return generateDefaultName({
            list: columns?.map(columnId => metaData?.[columnId]),
            property: 'name',
            prefixName: 'Column'
        });
    }, [columns, metaData]);

    const { workspaceId, gridId, branchId, viewId, dbId } = useParams();

    const [columnName, setColumnName] = useState(name);
    const [isShowExtra, setIsShowExtra] = React.useState(true);
    const [isShowFormat, setIsShowFormat] = React.useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formularError, setFormulaError] = React.useState('');
    const [publicIdName, setPublicIdName] = React.useState('');

    const [dataType, setDataType] = useState({
        value: columnTypes.MULTIPLE_LINES,
        label: getLabelColumnType(columnTypes.MULTIPLE_LINES),
        icon: () => <SvgIcon type={columnTypes.MULTIPLE_LINES} />
    });

    const [extraOptions, setExtraOptions] = useState(formatOptions([]));
    const [customPropsOptions, setCustomPropsOptions] = useState([]);

    const [referencedGridId, setReferencedGridId] = useState(null);
    const [referencedBranchId, setReferencedBranchId] = useState(null);
    const [referencedColumnId, setReferencedColumnId] = useState(null);
    const [referenceType, setReferenceType] = useState('row');
    const [referenceSelectionType, setReferenceSelectionType] = useState(REFERENCE_SELECTION_TYPES.MULTIPLE);

    const [primaryReference, setPrimaryReference] = useState(true);
    const [fileUploadType, setFileUploadType] = useState(FILE_UPLOAD_TYPES.MULTIPLE);

    const [gridTMId, setGridTMId] = useState(null);
    const [columnTMId, setColumnTMId] = useState(null);
    const [description, setDescription] = useState(``);
    const [defaultValue, setDefaultValue] = useState(``);

    const [formula, setFormula] = React.useState([{ type: 'paragraph', children: [{ text: '' }] }]);

    const [translationData, setTranslationData] = useState({});

    const [singleResultValue, setSingleResultValue] = useState(REFERENCE_SELECTION_TYPES.MULTIPLE);

    const [numberFormat, setNumberFormat] = React.useState({
        type: NUMBER_TYPES?.DECIMAL,
        decimalPlaces: 0,
        use1000Separator: false
    });

    const handleColumnTypeChange = React.useCallback(
        option => {
            setDataType(option);

            if (option?.value === columnTypes.TRANSLATION) {
                const language = SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH?.find(lang =>
                    lang?.fuzzy?.map(l => l?.toLowerCase())?.includes(columnName?.toLowerCase())
                );

                if (language) {
                    setTranslationData({
                        ...translationData,
                        value: language?.id,
                        type: TRANSLATION_TYPES.TARGET_LANG
                    });
                }
            }
        },
        [columnName, translationData]
    );

    const handlePublicIdKeyChange = e => {
        setPublicIdName(e.target?.value?.replace(/[^A-Z_0-9]/gi, ''));
    };

    const newColumnType = React.useMemo(() => {
        const isLocalizationType = dataType.value === columnTypes.LOCALIZATION;

        const getType = () => {
            if (isLocalizationType) {
                return columnTypes.REFERENCE;
            } else {
                return dataType.value;
            }
        };
        return getType();
    }, [dataType]);

    const updatedColumn = React.useMemo(() => {
        const isLocalizationType = dataType.value === columnTypes.LOCALIZATION;
        const isTranslation = dataType.value === columnTypes.TRANSLATION;
        const isNumber = dataType.value === columnTypes.NUMBER;
        const isFormula = dataType.value === columnTypes.FORMULA;

        const publicId = trim(publicIdName);

        let newColumn = {
            type: newColumnType,
            name: columnName,
            options: extraOptions,
            referenceSettings: {
                referencedGridId,
                referencedBranchId,
                referencedColumnId,
                referenceType,
                primaryReference,
                referenceSelectionType
            },

            group: translationData.value,
            publicId: publicId ? publicId : null,
            description: description,
            defaultValue: isLDEmpty(defaultValue)
                ? null
                : convertServerDefaultValue({
                      defaultValue,
                      columnType: newColumnType,
                      options: extraOptions
                  }),
            file:
                newColumnType === columnTypes.FILES
                    ? {
                          selectionType: fileUploadType
                      }
                    : undefined
        };

        const getNewCustomOptionsProps = () => {
            const obj = {};
            for (const option of extraOptions) {
                const id = option?.id;
                obj[id] = customPropsOptions?.[id];
            }
            return obj;
        };
        const customPropsOptionsFormat = getNewCustomOptionsProps();

        const generateColumnData = () => {
            if (isLocalizationType) {
                return {
                    ...newColumn,
                    customProperties: { options: customPropsOptionsFormat, subType: columnTypes.LOCALIZATION }
                };
            } else if (isTranslation) {
                return {
                    ...newColumn,
                    customProperties: { localizationType: translationData.type }
                };
            } else if (isNumber) {
                return {
                    ...newColumn,
                    numberFormat
                };
            } else if (isFormula) {
                return {
                    ...newColumn,
                    formula: {
                        ...newColumn?.formula,
                        formulaText: getFormulaServerValue(formula),
                        singleResultValue: singleResultValue === REFERENCE_SELECTION_TYPES.SINGLE
                    }
                };
            } else {
                return {
                    ...newColumn,
                    customProperties: { options: customPropsOptionsFormat }
                };
            }
        };

        return generateColumnData();
    }, [
        singleResultValue,
        defaultValue,
        description,
        formula,
        numberFormat,
        columnName,
        referencedColumnId,
        columnTMId,
        dataType,
        customPropsOptions,
        extraOptions,
        referencedGridId,
        gridTMId,
        translationData,
        newColumnType,
        referenceType,
        referenceSelectionType,
        primaryReference,
        fileUploadType,
        publicIdName
    ]);

    const checkFormula = React.useCallback(async () => {
        try {
            await validateFormulaApi({
                gridId: branchId,
                dbId,
                body: {
                    formulaText: getFormulaServerValue(formula)
                }
            });
            setFormulaError('');
            return '';
        } catch (error) {
            setFormulaError(error.originalMessage);
            return error.originalMessage;
        }
    }, [formula, dbId, branchId]);

    const handleCreate = React.useCallback(
        async e => {
            const isFormula = dataType.value === columnTypes.FORMULA;

            if (isFormula) {
                const formulaError = await checkFormula();
                if (formulaError) return;
            }
            if (!trim(columnName)) {
                onClickAway && onClickAway(e);
                return;
            }

            console.log('create column', updatedColumn);
            setIsLoading(true);

            dispatch(
                gridActions.createRelativeGridColumnWithBody({
                    columnId,
                    column: updatedColumn,
                    type,
                    successCallback: () => {
                        console.log('success');
                        setIsLoading(false);
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        console.log('failed');
                        setIsLoading(false);
                    }
                })
            );
        },
        [onClickAway, columnName, checkFormula, dataType, updatedColumn, columnId, dispatch, type, onClose]
    );

    const isValidByType = React.useCallback(
        columnType => {
            if (columnType === columnTypes.SINGLE_SELECTION || columnType === columnTypes.MULTIPLE_SELECTIONS) {
                if (extraOptions.length === 0) return true;
                return extraOptions.length !== 0 && !isHasOptionEmptyName(extraOptions);
            }
            if (columnType === columnTypes.TRANSLATION) {
                return translationData.type && translationData.value;
            }
            if (columnType === columnTypes.REFERENCE) {
                return referencedColumnId && referencedGridId && referencedBranchId;
            }

            if (columnType === columnTypes.NUMBER) {
                return !isLDEmpty(numberFormat);
            }

            if (columnType === columnTypes.FORMULA) {
                const children = formula?.[0]?.children;
                return children?.length > 0;
            }

            if (columnType === columnTypes.FILES) {
                return true;
            }
        },
        [extraOptions, translationData, referencedColumnId, referencedGridId, referencedBranchId, numberFormat, formula]
    );

    const isValid = React.useMemo(() => {
        const isNeedExtra = isNeedExtraOption(dataType.value);
        if (!isNeedExtra) return columnName;

        return columnName && isValidByType(dataType.value);
    }, [columnName, isValidByType, dataType.value]);

    const isColumnNameAlreadyExisted = React.useMemo(() => {
        const columnNames = columns?.map(column => column?.name);
        return (
            columnNames?.filter(name => name?.trim()?.toLowerCase() === columnName?.trim()?.toLowerCase())?.length > 0
        );
    }, [columns, columnName]);

    const isNotValid = React.useMemo(() => {
        return !isValid || isColumnNameAlreadyExisted;
    }, [isValid, isColumnNameAlreadyExisted]);

    const handleOnKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                onClickAway && onClickAway(e);
                return;
            }
            if (isKbEnter(e) && !isNotValid) {
                handleCreate(e);
                return;
            }
            return;
        },
        [handleCreate, onClickAway, isNotValid]
    );

    const handleAddMoreOption = ({ id, label, color }) => {
        setExtraOptions([...extraOptions, { id, label }]);
        const newCustomPropsOptions = { ...customPropsOptions };
        newCustomPropsOptions[id] = { color };
        setCustomPropsOptions(newCustomPropsOptions);
    };

    const onReorderOptionChange = React.useCallback(newOptions => {
        setExtraOptions(newOptions);
    }, []);

    const handleRemoveOption = optionId => {
        // const index = extraOptions.findIndex(option => option.id === optionId);
        const newCustomPropsOptions = { ...customPropsOptions };
        setExtraOptions(extraOptions.filter(option => option.id !== optionId));

        if (newCustomPropsOptions.options) {
            delete newCustomPropsOptions.options[optionId];
        }
        setCustomPropsOptions(newCustomPropsOptions);
    };

    const handleUpdateOption = ({ optionId, label, color }) => {
        let newOptions = [...extraOptions];
        const newCustomPropsOptions = { ...customPropsOptions };
        const index = newOptions.findIndex(option => option.id === optionId);
        if (index > -1) {
            newOptions[index].label = label;
        }
        newCustomPropsOptions[optionId] = color ? { color } : ``;

        setExtraOptions(newOptions);
        setCustomPropsOptions(newCustomPropsOptions);
    };

    const referenceDataChangeHandler = ({
        referencedBranchId,
        referencedGridId,
        referencedColumnId,
        referenceType,
        referenceSelectionType,
        primaryReference
    }) => {
        setReferencedColumnId(referencedColumnId);
        setReferencedGridId(referencedGridId);
        setReferencedBranchId(referencedBranchId);
        setReferenceType(referenceType);
        setReferenceSelectionType(referenceSelectionType);
        setPrimaryReference(primaryReference);
    };

    const onSingleResultValueChange = React.useCallback(e => {
        const value = e?.target?.value;
        setSingleResultValue(value);
    }, []);

    const fileUploadTypeChangeHandler = ({ uploadType }) => {
        setFileUploadType(uploadType);
    };

    const tmDataChangeHandler = ({ gridTMId, columnTMId }) => {
        setColumnTMId(columnTMId);
        setGridTMId(gridTMId);
    };

    const translationDataChangeHandler = updatedTranslationData => {
        const newData = { ...translationData, ...updatedTranslationData };
        setTranslationData(newData);
    };

    const handleNumberFormatChange = format => {
        setNumberFormat(format);
    };

    const onFormulaChange = React.useCallback(value => {
        setFormula(value);
    }, []);

    const handleDefaultValueChange = React.useCallback(value => {
        setDefaultValue(value);
    }, []);

    const handleDescriptionChange = React.useCallback(e => {
        setDescription(e.target?.value);
    }, []);

    const columnSuggestions = React.useMemo(() => {
        return viewColumns
            ?.filter(column => !!column.viewable)
            ?.filter(column => column.type !== columnTypes.PATH_TAG)
            .map(column => {
                const columnType = column?.type;
                const isLangColumn = columnType === columnTypes.TRANSLATION;
                return {
                    type: 'column',
                    label: column?.name,
                    value: `col_val_${column?.id}`,
                    columnType: columnType,
                    group: column?.group,
                    icon: () =>
                        isLangColumn ? (
                            <CountryFlag languageCountryCode={column?.group} />
                        ) : (
                            <SvgIcon type={columnType} />
                        )
                };
            });
    }, [viewColumns]);

    const isNotValidPublicId = React.useMemo(() => {
        if (!publicIdName) return false;
        const publicIds = columns?.filter(col => col?.id !== columnId)?.map(col => col?.publicId);
        return publicIds?.includes(publicIdName);
    }, [publicIdName, columnId, columns]);

    const isNotValidToSave = React.useMemo(() => {
        return isNotValid || isNotValidPublicId || (dataType?.value === columnTypes.FORMULA && Boolean(formularError));
    }, [isNotValid, formularError, dataType, isNotValidPublicId]);

    return (
        <Grid container direction="column" className={`${classes.root} columnFormat`}>
            <Grid item className={classes.scroll}>
                <Grid container direction="column">
                    <Grid item container direction="column" className={classes.setting}>
                        <Grid item className={classes.spacing}>
                            <InputText
                                name="columnName"
                                label={t('column_format_column_name_label')}
                                value={columnName}
                                onChange={e => setColumnName(e.target.value)}
                                onKeyDown={handleOnKeyDown}
                                placeholder="column name"
                                autoFocus
                                autoSelect={true}
                                error={isColumnNameAlreadyExisted}
                                errorText={t(`column_format_column_name_warning`)}
                            />
                        </Grid>
                        <Grid item className={classes.spacing}>
                            <Grid container direction="column">
                                <Grid item style={{ marginBottom: theme.spacing(1) }}>
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item>
                                            <p className="body1">{t('column_format_data_type')}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className="columnDataType">
                                    <ColumnTypesSelect
                                        defaultValue={dataType}
                                        handleOptionChange={handleColumnTypeChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {isNeedExtraOption(dataType.value) && ![columnTypes.NUMBER]?.includes(newColumnType) && (
                            <Grid item className={classes.spacing}>
                                <ExtraOptionsByType
                                    type={dataType.value}
                                    options={extraOptions}
                                    customPropsOptions={customPropsOptions}
                                    handleAddMoreOption={handleAddMoreOption}
                                    handleRemoveOption={handleRemoveOption}
                                    handleUpdateOption={handleUpdateOption}
                                    onReorderOptionChange={onReorderOptionChange}
                                    referenceType={referenceType}
                                    referenceSelectionType={referenceSelectionType}
                                    primaryReference={primaryReference}
                                    referenceDataChangeHandler={referenceDataChangeHandler}
                                    referenceData={{
                                        referencedGridId,
                                        referencedColumnId,
                                        referencedBranchId
                                    }}
                                    tmDataChangeHandler={tmDataChangeHandler}
                                    tmData={{
                                        gridTMId,
                                        columnTMId
                                    }}
                                    translationData={translationData}
                                    translationDataChangeHandler={translationDataChangeHandler}
                                    workspaceId={workspaceId}
                                    gridId={gridId}
                                    viewId={viewId}
                                    dbId={dbId}
                                    branchId={branchId}
                                    t={t}
                                    numberFormat={numberFormat}
                                    onNumberFormatChange={handleNumberFormatChange}
                                    onFormulaChange={onFormulaChange}
                                    formula={formula}
                                    metaData={metaData}
                                    columnSuggestions={columnSuggestions}
                                    formularError={formularError}
                                    onBlurFormula={checkFormula}
                                    singleResultValue={singleResultValue}
                                    onSingleResultValueChange={onSingleResultValueChange}
                                    fileUploadType={fileUploadType}
                                    fileUploadTypeChangeHandler={fileUploadTypeChangeHandler}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {isNeedExtraOption(dataType.value) && [columnTypes.NUMBER]?.includes(newColumnType) && (
                        <Grid item onClick={() => setIsShowFormat(!isShowFormat)}>
                            <Grid
                                container
                                direction="row"
                                className={classes.split}
                                alignItems="center"
                                justify="space-between"
                                style={{ background: isShowFormat ? theme.colors.ghostwhite : theme.colors.white }}
                            >
                                <Grid item>
                                    <p className="body1">Format</p>
                                </Grid>
                                <Grid item className={classes.dpFlex}>
                                    {isShowFormat ? <ArrowUpSVG /> : <ArrowDownSVG />}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Collapse
                        in={isShowFormat && [columnTypes.NUMBER]?.includes(newColumnType)}
                        timeout="auto"
                        unmountOnExit
                    >
                        {isNeedExtraOption(dataType.value) && (
                            <Grid container direction="column" className={classes.setting}>
                                <Grid item className={classes.spacing}>
                                    <ExtraOptionsByType
                                        type={dataType.value}
                                        options={extraOptions}
                                        customPropsOptions={customPropsOptions}
                                        handleAddMoreOption={handleAddMoreOption}
                                        handleRemoveOption={handleRemoveOption}
                                        handleUpdateOption={handleUpdateOption}
                                        referenceType={referenceType}
                                        referenceSelectionType={referenceSelectionType}
                                        primaryReference={primaryReference}
                                        referenceDataChangeHandler={referenceDataChangeHandler}
                                        referenceData={{
                                            referencedGridId,
                                            referencedColumnId,
                                            referencedBranchId
                                        }}
                                        tmDataChangeHandler={tmDataChangeHandler}
                                        tmData={{
                                            gridTMId,
                                            columnTMId
                                        }}
                                        translationData={translationData}
                                        translationDataChangeHandler={translationDataChangeHandler}
                                        workspaceId={workspaceId}
                                        gridId={gridId}
                                        viewId={viewId}
                                        dbId={dbId}
                                        branchId={branchId}
                                        t={t}
                                        numberFormat={numberFormat}
                                        onNumberFormatChange={handleNumberFormatChange}
                                        onFormulaChange={onFormulaChange}
                                        formula={formula}
                                        metaData={metaData}
                                        columnSuggestions={columnSuggestions}
                                        formularError={formularError}
                                        onBlurFormula={checkFormula}
                                        singleResultValue={singleResultValue}
                                        onSingleResultValueChange={onSingleResultValueChange}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Collapse>

                    <Grid item onClick={() => setIsShowExtra(!isShowExtra)}>
                        <Grid
                            container
                            direction="row"
                            className={classes.split}
                            alignItems="center"
                            justify="space-between"
                            style={{ background: isShowExtra ? theme.colors.ghostwhite : theme.colors.white }}
                        >
                            <Grid item>
                                <p className="body1">{t('column_options')}</p>
                            </Grid>
                            <Grid item className={classes.dpFlex}>
                                {isShowExtra ? <ArrowUpSVG /> : <ArrowDownSVG />}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Collapse in={isShowExtra} timeout="auto" unmountOnExit>
                        <Grid container direction="column" className={classes.setting}>
                            {isNeedDefaultValue(dataType?.value) && (
                                <Grid item className={classes.spacing}>
                                    <DefaultValue
                                        type={dataType?.value}
                                        defaultValue={defaultValue}
                                        extraOptions={extraOptions}
                                        customPropsOptions={customPropsOptions}
                                        onChange={handleDefaultValueChange}
                                    />
                                </Grid>
                            )}
                            <Grid item className={classes.spacing}>
                                <InputText
                                    name="description"
                                    label={
                                        <>
                                            <p className="body1 inline">{t('column_description_label')}</p>{' '}
                                            <p className="caption !font-normal inline">({t('global_optional')})</p>
                                        </>
                                    }
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder={t('column_description_placeholder')}
                                />
                            </Grid>
                            <Grid item className={classes.spacing}>
                                <InputText
                                    name="publicId"
                                    label={t('column_format_column_id_label')}
                                    value={publicIdName}
                                    onChange={handlePublicIdKeyChange}
                                    placeholder={t('auto_generate')}
                                    error={isNotValidPublicId}
                                    errorText={'Duplicate public column id'}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>

            <Grid item className={classes.divider}>
                <Divider />
            </Grid>
            <Grid item className={classes.actions}>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <Button fullWidth variant="outlined" size="small" onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.buttonWrapper}>
                        <Button
                            fullWidth
                            variant="contained"
                            size="small"
                            onClick={handleCreate}
                            disabled={isNotValidToSave || isLoading}
                        >
                            {t('global_save')}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(NewColumnFormat);
