import { isMac } from 'utils/os';

function _getLatinKey(key, code) {
    if (key?.length !== 1) {
        return key;
    }

    const capitalHetaCode = 880;
    const isNonLatin = key?.charCodeAt(0) >= capitalHetaCode;

    if (isNonLatin) {
        if (code?.indexOf('Key') === 0 && code?.length === 4) {
            return code.charAt(3);
        }

        if (code?.indexOf('Digit') === 0 && code?.length === 6) {
            return code.charAt(5);
        }
    }

    return key?.toUpperCase();
}

export const getStatusCtrlOrShiftKey = event => {
    let metaData = {
        isShift: false,
        isAlt: false,
        isOption: false,
        isCmd: false,
        isCtrl: false
    };

    if (event) {
        if (event.shiftKey) {
            metaData.isShift = true;
        }

        if (event.ctrlKey || event.metaKey) {
            metaData.isCtrl = true;
        }
        if (event.altKey) {
            metaData.isAlt = true;
            metaData.isOption = true;
        }
    }

    return metaData;
};

export function isKbUndo(e) {
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'Z' && isCtrl && !isShift;
}

export function isKbRedo(e) {
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    return (
        (_getLatinKey(e?.key, e?.code) === 'Z' && isCtrl && isShift) ||
        (_getLatinKey(e?.key, e?.code) === 'Y' && isCtrl)
    );
}

export function isKbRefresh(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'R' && isCtrl;
}

export function isKbEscape(e) {
    return _getLatinKey(e?.key, e?.code) === 'Escape';
}

export function isKbEnter(e) {
    return _getLatinKey(e?.key, e?.code) === 'Enter';
}

export function isKbCtrlEnter(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    return isCtrl && isKbEnter(e);
}

export function isKbShiftEnter(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return isShift && isKbEnter(e);
}

export function isKbTab(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'Tab' && !isShift;
}

export function isKbShiftTab(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'Tab' && isShift;
}

export function isKbCopy(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'C' && isCtrl;
}

export function isKbPaste(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'V' && isCtrl;
}

export function isKbDelete(e) {
    return _getLatinKey(e?.key, e?.code) === 'Backspace' || _getLatinKey(e?.key, e?.code) === 'Delete';
}

export function isKbArrowRight(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowRight' && !isShift;
}

export function isKbShiftArrowRight(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowRight' && isShift;
}

export function isKbArrowUp(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowUp' && !isShift;
}

export function isKbShiftArrowUp(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowUp' && isShift;
}

export function isKbArrowDown(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowDown' && !isShift;
}

export function isKbShiftArrowDown(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowDown' && isShift;
}

export function isKbArrowLeft(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowLeft' && !isShift;
}

export function isKbShiftArrowLeft(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowLeft' && isShift;
}

export function isKbPageUp(e) {
    return _getLatinKey(e?.key, e?.code) === 'PageUp';
}

export function isKbPageDown(e) {
    return _getLatinKey(e?.key, e?.code) === 'PageDown';
}

export function isKbEnd(e) {
    return _getLatinKey(e?.key, e?.code) === 'End';
}

export function isKbHome(e) {
    return _getLatinKey(e?.key, e?.code) === 'Home';
}

export function isKbF2(e) {
    return _getLatinKey(e?.key, e?.code) === 'F2';
}

export function isKbPageStart(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    return isKbArrowUp(e) && isCtrl;
}

export function isKbPageEnd(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    return isKbArrowDown(e) && isCtrl;
}

export function isKbCommandAndSlash(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);

    return _getLatinKey(e?.key, e?.code) === '/' && isCtrl;
}

export function isKbSpace(e) {
    return e?.keyCode === 32 || e?.key?.toLowerCase() === 'space';
}

export function isKbSearch(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    const ltKey = _getLatinKey(e?.key, e?.code);
    return ltKey === 'F' && isCtrl;
}

export function isKbCtrlAndSpace(e) {
    const { isCtrl } = getStatusCtrlOrShiftKey(e);
    const isSpace = isKbSpace(e);
    return isCtrl && isSpace;
}

export function isKBToggleTagShow(e) {
    const { key, keyCode } = e;
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    const isT = keyCode === 84 || key?.toLowerCase() === 't';
    return isCtrl && isShift && isT;
}

export function isKbCtrlShiftC(e) {
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    const ltKey = _getLatinKey(e?.key, e?.code);
    return ['C', 'С', ' ', 'ؤ'].includes(ltKey) && isCtrl && isShift;
}

export function isKbF9(e) {
    return _getLatinKey(e?.key, e?.code) === 'F9';
}

export function isKbShiftF9(e) {
    const { isShift } = getStatusCtrlOrShiftKey(e);
    return isKbF9(e) && isShift;
}

export function isKbCtrlShiftArrowUp(e) {
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowUp' && isCtrl && isShift;
}

export function isKbCtrlShiftU(e) {
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);

    return isMac()
        ? _getLatinKey(e?.key, e?.code) === 'U' && isCtrl
        : _getLatinKey(e?.key, e?.code) === 'U' && isCtrl && isShift;
}

export function isKbCtrlShiftArrowDown(e) {
    const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
    return _getLatinKey(e?.key, e?.code) === 'ArrowDown' && isCtrl && isShift;
}

export function isKbCmdOption_CtrlAltT(e) {
    const { isCmd, isAlt, isCtrl } = getStatusCtrlOrShiftKey(e);
    const ltKey = _getLatinKey(e?.key, e?.code);

    return ['T', '†'].includes(ltKey) && isAlt && (isCmd || isCtrl);
}

export function isNormalKeyboards(e) {
    const { isCmd, isCtrl } = getStatusCtrlOrShiftKey(e);
    const key = _getLatinKey(e?.key, e?.code);
    const reg = /^\d+$/;
    return reg.exec(key) && (isCmd || isCtrl);
}
