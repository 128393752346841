import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import CellBaseDropdown from 'components/selects/CellBase';
import { Grid, Popper, Paper } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { MULTIPLE_SELECTIONS } from 'const/columnTypes';
import { INPUT_QUICK_FILTER_HEIGHT } from 'const/style';
import { MAX_OPTIONS } from 'const/gridUI';
import { OPERATOR } from 'gridUI/conditions';
import classNames from 'classnames';
import { getOptionColor } from 'utils/color';
import Option from 'components/option/Base';

function MultiSelectionQuickFilter({ column, columnId, isSelectionActive, quickFilters, isDisabled, t, ...rest }) {
    const selectedOptions = useMemo(() => {
        return quickFilters?.[columnId]?.value || [];
    }, [quickFilters, columnId]);

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const singleSelectOptions = useMemo(() => {
        return isEmpty(column.options)
            ? []
            : column.options.map(option => {
                  return {
                      value: option.label,
                      label: option.label
                  };
              });
    }, [column.options]);

    const callServer = options => {
        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: options,
                type: MULTIPLE_SELECTIONS,
                operator: OPERATOR.contains
            })
        );
        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleOptionChange = options => {
        setAnchorEl(null);
        callServer(options);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleDelete = option => {
        let newOptions = selectedOptions.filter(item => item.value !== option.value);
        callServer(newOptions);
    };

    return (
        <Grid>
            <div
                onClick={handleClick}
                className={classNames(
                    `body2 bg-grey-pale  overflow-hidden px-3.5 flex flex-nowrap items-center rounded border`,
                    {
                        disabled: isDisabled,
                        'border-b border-text-primary': anchorEl
                    }
                )}
                style={{
                    height: INPUT_QUICK_FILTER_HEIGHT
                }}
            >
                {isEmpty(selectedOptions) && (
                    <span className={'body2 text-grey-mid flex-1 line-clamp-1'}>
                        {t(`global_label_select_options`)}
                    </span>
                )}
                {!isEmpty(selectedOptions) &&
                    selectedOptions.map(option => (
                        <Option
                            size="small"
                            className="mr-1"
                            key={option.value}
                            onDelete={() => handleDelete(option)}
                            label={option.label}
                            color={getOptionColor({ ...column, data: option?.value })}
                        />
                    ))}
            </div>
            <Popper className={`z-[1500]`} id={id} open={open} anchorEl={anchorEl} transition>
                <Paper className={'min-w-[200px] p-2.5 bg-white shadow'}>
                    <CellBaseDropdown
                        options={singleSelectOptions}
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        maxSize={MAX_OPTIONS}
                        defaultValue={selectedOptions}
                        onBlur={handleClickAway}
                        isMulti
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(MultiSelectionQuickFilter);
