import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as types from 'socket/types';
import backupRestoreHandler from './backupRestore';
import { isForAll, isForYou } from 'socket/utils/room';

const { getState } = store;
const databaseDetailHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    const { action, objectType } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ state, headers })) {
        if (objectType === types.DATABASE && [types.RESTORE, types.BACKUP].includes(action)) {
            return backupRestoreHandler({ body, isDatabaseDetail: true });
        }
    }

    if (isForYou({ state, headers })) {
        console.log('IGNORE');
        return;
    }
};

export default databaseDetailHandler;
