import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ConditionsSelects from 'gridUI/common/Conditions';
import isEmpty from 'lodash/isEmpty';
import { getConditionsByType, getOperatorName, OPERATOR } from 'gridUI/conditions';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import RemoveSVG from 'assets/images/svg/RemoveSVG';
import Tooltip from 'components/tooltip/Base';
import * as columnTypes from 'const/columnTypes';
import { DEBOUNCE_TIME_SEARCHING, SPECIAL_SPLIT_KEY } from 'const/gridUI';
import OptionSelection from './OptionSelection';
// import RefOptionSelect from './RefOptionSelect';
import DateTimeInput from './DatetimeInput';
import BooleanInput from './BooleanInput';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { DISABLED_OPACITY } from 'const/style';
import InputFilter from './components/Input';
import { generateDefaultConditionByColumnType } from 'utils/gridUI/filter';
import { getFloatNumber } from 'utils/gridUI/cell';
import { getOptionColor } from 'utils/color';
import LDBasePortal from 'components/selects/LDBasePortal';

const FILTER_COLUMN_WIDTH = 200;
const FILTER_CONDITION_WIDTH = 160;

const useStyles = makeStyles(theme => ({
    close: {
        position: 'relative',
        top: 2,
        cursor: 'pointer'
    },
    dropdownClassName: {
        width: FILTER_COLUMN_WIDTH
    },
    largePopper: {
        '& .popper': {
            width: '300px !important'
        }
    },
    conditionDropdown: {
        width: FILTER_CONDITION_WIDTH
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    dpFlex: {
        display: 'flex'
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    },
    OR: {
        width: 32,
        height: 24,
        background: theme.colors.solitude,
        borderRadius: 4,
        cursor: 'pointer',
        '& p': {
            color: theme.palette.primary.main
        },
        '&.disabled': {
            background: 'none',
            cursor: 'unset',
            '& p': {
                color: theme.colors.disabledText
            }
        }
    }
}));

function SingleSelectionInput({ defaultValue, column, handleSingleSelectionChange, isReadOnly, t }) {
    const [selectedOption, setSelectedOption] = React.useState(null);

    React.useEffect(() => {
        if (isEmpty(defaultValue)) {
            setSelectedOption(null);
        } else {
            setSelectedOption({
                label: defaultValue,
                value: defaultValue
            });
        }
    }, [defaultValue]);

    return (
        <OptionSelection
            disabled={isReadOnly}
            defaultValue={selectedOption}
            column={column}
            handleOptionChange={handleSingleSelectionChange}
            t={t}
        />
    );
}

function MultiSelectionInput({ defaultValue, column, handleMultiSelectionChange, isReadOnly, t }) {
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    React.useEffect(() => {
        if (isEmpty(defaultValue)) {
            setSelectedOptions([]);
        } else {
            let options = defaultValue.split(SPECIAL_SPLIT_KEY);
            let selectedOpts = options.map(opt => {
                const color = getOptionColor({
                    options: column?.options,
                    data: opt,
                    customProperties: column?.customProperties
                });
                return {
                    label: opt,
                    value: opt,
                    color
                };
            });
            setSelectedOptions(selectedOpts);
        }
    }, [defaultValue, column]);

    return (
        <OptionSelection
            disabled={isReadOnly}
            defaultValue={selectedOptions}
            column={column}
            handleOptionChange={handleMultiSelectionChange}
            isMulti={true}
            t={t}
        />
    );
}

function BooleanSelect({ defaultValue, onBooleanChangeHandler, isReadOnly }) {
    return <BooleanInput defaultValue={defaultValue} onChange={onBooleanChangeHandler} disabled={isReadOnly} />;
}

function DateTimeSelect({ defaultValue, onDateTimeChangeHandler, isReadOnly, t }) {
    return <DateTimeInput defaultValue={defaultValue} onChange={onDateTimeChangeHandler} disabled={isReadOnly} t={t} />;
}

// function RefSelectionInput({ defaultValue, column, handleReferenceSelectionChange, isReadOnly, t }) {
//     const [selectedOptions, setSelectedOptions] = React.useState([]);

//     React.useEffect(() => {
//         if (isEmpty(defaultValue)) {
//             setSelectedOptions([]);
//         } else {
//             let options = defaultValue.split(SPECIAL_SPLIT_KEY);
//             let selectedOpts = options.map(opt => ({
//                 label: opt,
//                 value: opt
//             }));
//             setSelectedOptions(selectedOpts);
//         }
//     }, [defaultValue]);

//     return (
//         <RefOptionSelect
//             defaultValue={selectedOptions}
//             column={column}
//             handleOptionChange={handleReferenceSelectionChange}
//             isMulti={true}
//             disabled={isReadOnly}
//             t={t}
//         />
//     );
// }

function generateFilterInput({ type, condition, ...rest }) {
    switch (type) {
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.NUMBER:
        case columnTypes.FILES:
        case columnTypes.SINGLE_LINE:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.RECORD_ID:
            return <InputFilter {...rest} />;

        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectionInput {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelectionInput {...rest} />;
        case columnTypes.DATETIME:
        case columnTypes.ALTERED_TIME:
            return <DateTimeSelect {...rest} />;
        case columnTypes.BOOLEAN:
            return <BooleanSelect {...rest} />;

        case columnTypes.REFERENCE:
            return <InputFilter {...rest} />;
        default:
            return <InputFilter {...rest} />;
    }
}

function FilterItem({
    id,
    hashColumnId,
    groupId,
    operator,
    values,
    subField,
    dependencies,
    t,
    handleOr,
    metaData,
    onDeleteFilter,
    onUpdateFilter,
    columns,
    isReadOnly
}) {
    const classes = useStyles();
    const [selectedColumn, setSelectedColumn] = React.useState(null);
    const [conditionOptions, setConditionOptions] = React.useState([]);
    const [selectedCondition, setSelectedCondition] = React.useState(null);
    const [filterValues, setFilterValues] = React.useState(values);
    const [filterSubField, setFilterSubField] = React.useState(subField);

    const isChildDependencyAndLanguageColumn = React.useMemo(() => {
        return dependencies?.find(dpdc => dpdc?.child === hashColumnId);
    }, [dependencies, hashColumnId]);

    const timer = React.useRef();

    const columnOptions = React.useMemo(() => {
        return columns?.map(column => {
            let columnType = getCorrectColumnType(column);
            return {
                value: column.hashColumnId,
                label: column.name,
                column: column,
                type: columnType,
                icon: () => (
                    <ColumnIcon group={column?.group} type={columnType} customProperties={column.customProperties} />
                )
            };
        });
    }, [columns]);

    React.useEffect(() => {
        setFilterSubField(subField);
    }, [subField]);

    React.useEffect(() => {
        const column = metaData?.[hashColumnId] || {};
        const columnType = getCorrectColumnType(column);
        const defaultSelectedColumn = {
            label: column?.name,
            value: column?.hashColumnId,
            type: columnType,
            column,
            icon: () => <ColumnIcon group={column.group} type={columnType} customProperties={column.customProperties} />
        };
        let validOperators = getConditionsByType(columnType);
        // console.log('validOperators', validOperators);
        if (!validOperators) return setConditionOptions([]);

        let selectedCondition = operator
            ? {
                  value: operator,
                  label: getOperatorName(operator)
              }
            : generateDefaultConditionByColumnType({
                  column,
                  validOperators,
                  isChildDependency: isChildDependencyAndLanguageColumn
              });

        // console.log('selectedCondition', selectedCondition);
        // console.log('operator', operator);
        setSelectedCondition(selectedCondition);
        setConditionOptions(validOperators);
        setSelectedColumn(defaultSelectedColumn);
        setFilterValues(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChildDependencyAndLanguageColumn]);

    const handleUpdateFilterValue = React.useCallback(
        ({ value, subField }) => {
            const isNumber = selectedColumn?.type === columnTypes.NUMBER;
            setFilterValues(value);
            setFilterSubField(subField);
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(function() {
                onUpdateFilter({
                    filterId: id,
                    newFilter: {
                        id,
                        operator,
                        hashColumnId,
                        groupId,
                        values: isNumber ? (value ? getFloatNumber(value) : '') : value,
                        subField
                    }
                });
            }, DEBOUNCE_TIME_SEARCHING);
        },
        [hashColumnId, onUpdateFilter, selectedColumn, id, operator, groupId]
    );

    const handleConditionChange = option => {
        setSelectedCondition(option);
        if (!filterValues) {
            updateViewFilter({
                operator: option.value,
                hashColumnId: selectedColumn.value,
                subField: null
            });
        } else {
            const isNumber = selectedColumn?.type === columnTypes.NUMBER;

            const isKeepSubfield = [OPERATOR.equal, OPERATOR.notEqual].includes(option?.value);

            updateViewFilterAndFetchingData({
                operator: option.value,
                hashColumnId: selectedColumn.value,
                filterValues: !subField
                    ? isNumber
                        ? filterValues
                            ? getFloatNumber(filterValues)
                            : ''
                        : filterValues
                    : isKeepSubfield
                    ? filterValues
                    : '',
                subField: ['_dependencyStatus', '_tm'].includes(subField) && isKeepSubfield ? subField : null
            });
        }
    };

    const handleColumnChange = option => {
        let validOperators = getConditionsByType(option?.type);
        const hashColumnId = option?.value;
        const isChildDependency = dependencies?.find(dpdc => dpdc?.child === hashColumnId);

        if (!validOperators) return setConditionOptions([]);
        let selectedCondition = generateDefaultConditionByColumnType({
            column: option,
            validOperators,
            isChildDependency
        });
        setSelectedCondition(selectedCondition);
        setConditionOptions(validOperators);
        setSelectedColumn(option);

        setFilterValues('');
        if (!filterValues) {
            updateViewFilter({
                operator: selectedCondition.value,
                hashColumnId: option.value,
                subField: null
            });
        } else {
            updateViewFilterAndFetchingData({
                operator: selectedCondition.value,
                hashColumnId: option.value,
                filterValues: '',
                subField: null
            });
        }
    };

    const updateViewFilterAndFetchingData = React.useCallback(
        ({ operator, hashColumnId, filterValues, subField }) => {
            const isNoNeedValue = [OPERATOR.isEmpty, OPERATOR.isNotEmpty].includes(operator);

            onUpdateFilter({
                filterId: id,
                isFetchServer: true,
                newFilter: {
                    id,
                    operator,
                    hashColumnId,
                    values: isNoNeedValue ? null : filterValues,
                    subField,
                    groupId
                }
            });

            if (isNoNeedValue || !filterValues) {
                setFilterValues(null);
            }
        },
        [onUpdateFilter, id, groupId]
    );

    const updateViewFilter = React.useCallback(
        ({ operator, hashColumnId, subField }) => {
            const isNoNeedValue = [OPERATOR.isEmpty, OPERATOR.isNotEmpty].includes(operator);

            onUpdateFilter({
                filterId: id,
                isFetchServer: true,
                newFilter: {
                    id,
                    values: isNoNeedValue ? null : filterValues,
                    operator,
                    hashColumnId,
                    subField,
                    groupId
                }
            });

            if (isNoNeedValue) {
                setFilterValues(null);
            }
        },
        [onUpdateFilter, filterValues, id, groupId]
    );

    const handleSingleSelectionChange = option => {
        let values = !option ? '' : option.value;
        setFilterValues(values);

        onUpdateFilter({
            filterId: id,
            newFilter: {
                id,
                operator,
                hashColumnId,
                values,
                groupId
            }
        });
    };

    const handleReferenceSelectionChange = options => {
        let values = options.length === 0 ? '' : options.map(opt => opt.label).join(SPECIAL_SPLIT_KEY);
        setFilterValues(values);
        onUpdateFilter({
            filterId: id,
            newFilter: {
                id,
                operator,
                hashColumnId,
                values,
                groupId
            }
        });
    };

    const handleMultiSelectionChange = options => {
        let values = options.length === 0 ? '' : options.map(opt => opt.value).join(SPECIAL_SPLIT_KEY);
        setFilterValues(values);
        onUpdateFilter({
            filterId: id,
            newFilter: {
                id,
                operator,
                hashColumnId,
                values,
                groupId
            }
        });
    };

    const onDateTimeChangeHandler = date => {
        let values = !date ? '' : date;
        setFilterValues(date);
        onUpdateFilter({
            filterId: id,
            newFilter: {
                id,
                operator,
                hashColumnId,
                values,
                groupId
            }
        });
    };

    const onBooleanChangeHandler = values => {
        setFilterValues(values);
        onUpdateFilter({
            filterId: id,
            newFilter: {
                id,
                operator,
                hashColumnId,
                values,
                groupId
            }
        });
    };

    return (
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Tooltip
                title={
                    isReadOnly ? (
                        <Grid container className={classes.permissionTooltip}>
                            {t('toolbar_no_permission')}
                        </Grid>
                    ) : (
                        ``
                    )
                }
            >
                <Grid item>
                    <LDBasePortal
                        options={columnOptions}
                        isDisabled={isReadOnly}
                        dropdownClassName={`${classes.dropdownClassName}`}
                        defaultValue={selectedColumn}
                        onChange={handleColumnChange}
                        ddPlaceholder=""
                        menuPlaceholder=""
                        placement="bottom-start"
                        isMulti={false}
                        isUsingContainer={true}
                    />
                </Grid>
            </Tooltip>

            <Tooltip
                title={
                    isReadOnly ? (
                        <Grid container className={classes.permissionTooltip}>
                            {t('toolbar_no_permission')}
                        </Grid>
                    ) : (
                        ``
                    )
                }
            >
                <Grid item>
                    <ConditionsSelects
                        isDisabled={isReadOnly}
                        dropdownClassName={`${classes.conditionDropdown}`}
                        options={conditionOptions}
                        defaultValue={selectedCondition}
                        handleOptionChange={handleConditionChange}
                        ddPlaceholder=""
                        menuPlaceholder=""
                    />
                </Grid>
            </Tooltip>

            {![OPERATOR.isEmpty, OPERATOR.isNotEmpty]?.includes(operator) ? (
                <Tooltip
                    title={
                        isReadOnly ? (
                            <Grid container className={classes.permissionTooltip}>
                                {t('toolbar_no_permission')}
                            </Grid>
                        ) : (
                            ``
                        )
                    }
                >
                    <Grid item style={{ flex: 1 }}>
                        <Grid container className={isReadOnly ? classes.inputDisabled : ''}>
                            {generateFilterInput({
                                type: selectedColumn?.type,
                                condition: selectedCondition?.value,
                                column: selectedColumn?.column,
                                columnType: getCorrectColumnType(selectedColumn?.column),
                                defaultValue: filterValues,
                                onChange: handleUpdateFilterValue,
                                handleSingleSelectionChange: handleSingleSelectionChange,
                                handleMultiSelectionChange: handleMultiSelectionChange,
                                onDateTimeChangeHandler: onDateTimeChangeHandler,
                                onBooleanChangeHandler: onBooleanChangeHandler,
                                handleReferenceSelectionChange: handleReferenceSelectionChange,
                                isReadOnly,
                                isChildDependencyAndLanguageColumn:
                                    isChildDependencyAndLanguageColumn &&
                                    [OPERATOR.equal, OPERATOR.notEqual].includes(selectedCondition?.value),
                                subField: filterSubField,
                                t
                            })}
                        </Grid>
                    </Grid>
                </Tooltip>
            ) : (
                <Grid item style={{ flex: 1 }} />
            )}
            <Grid item>
                <Grid
                    className={`${classes.OR} ${!handleOr ? 'disabled' : ''}`}
                    container
                    justify="center"
                    alignItems="center"
                    onClick={handleOr ? () => handleOr(id, groupId || id) : null}
                >
                    <Typography variant="body1">{t('global_or')}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justify="flex-start">
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ``
                            )
                        }
                    >
                        <Grid item>
                            <RemoveSVG
                                onClick={() => onDeleteFilter(id)}
                                className={`${classes.close} ${isReadOnly ? classes.disabled : ''}`}
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(FilterItem);
