import React from 'react';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HEADER_HEIGHT, SIDEBAR_RIGHT_WIDTH, ADD_ON_STATES } from 'const';
import { useCurrentView } from 'hooks/gridUI';
import hexToRgba from 'hex-to-rgba';
import Annotation from './DependencyStatusGuide';
import SidebarRightLayout from '../layout/rightSidebar';
import { useViewOnlyLanguageColumnsHaveDependency } from 'hooks/gridUI/column';
import TranslationItem from './TranslationItem';
import Spinner from 'components/spinner/Base';
import { useTranslationDashboardIsFetchingByView } from 'hooks/gridUI/translationDashboard';
import * as translationDashboardActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { useAddonDetailByName } from 'hooks/addons';
import { ADD_ON_NAME } from 'const/inAppAddon';
import PercentageSwitch from './PercentageSwitch';
import NoTranslationOverviewSVG from 'assets/images/svg/NoTranslationOverviewSVG';
import ViewIcon from 'gridUI/views/ViewIcon';
import { Trans } from 'react-i18next';
import ButtonBase from 'components/button/Base';
import { enqueueSnackbar } from 'notifier/actions';
import { DISABLED_OPACITY } from 'const/style';

const HEADER_HISTORY_HEIGHT = 72;
const CONTENT_HEIGHT = `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT}px)`;
const FOOTER_HEIGHT = 57;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    tabName: {
        marginRight: theme.spacing(3)
    },
    accessViewIcon: {
        marginRight: theme.spacing(2)
    },
    accessView: {
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.8),
        border: `1px solid ${hexToRgba(theme.colors.fuchsiaBlue, 1)}`,
        borderRadius: '50%',
        '& svg': {
            width: 10,
            color: theme.colors.white
        }
    },
    accessViewName: {
        whiteSpace: 'nowrap'
    },
    closeButton: {
        cursor: 'pointer',
        display: 'flex',
        marginLeft: 'auto'
    },
    contentWrapper: {
        height: CONTENT_HEIGHT,
        overflowY: 'auto',
        position: 'relative',
        width: '100%'
    },
    subHeader: {
        height: 48,
        paddingLeft: 24,
        paddingRight: 24,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    content: {
        height: `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT + 48}px)`,
        overflow: 'hidden auto',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: theme.spacing(4),
        position: 'relative',
        width: '100%'
    },
    section: {
        marginBottom: theme.spacing(4)
    },
    firstSection: {
        marginTop: theme.spacing(3)
    },
    overlay: {
        width: SIDEBAR_RIGHT_WIDTH,
        height: CONTENT_HEIGHT,
        position: 'fixed',
        zIndex: 10,
        background: hexToRgba(theme.colors.white, 0.8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inlineBlock: {
        display: 'inline-block'
    },
    currentViewTag: {
        padding: '4px 8px',
        background: theme.colors.paleGrey,
        borderRadius: 4
    },
    noDependency: {
        height: '100%'
    },
    footer: {
        padding: `10px 24px`,
        borderTop: `1px solid ${theme.colors.border}`
    },
    boxLoading: {
        position: 'relative',
        marginRight: 10
    },
    cir1: {
        color: '#93929E'
    },
    cir2: {
        color: '#ECECEC',
        position: 'absolute',
        left: 0
    }
}));

function TranslationOverview({ handleClose, workspaceId, gridId, viewId, dbId, branchId, t }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentView = useCurrentView();
    const columns = useViewOnlyLanguageColumnsHaveDependency();
    const hasTranslationDataColumns = columns.filter(column => column.translationData);
    const isFetching = useTranslationDashboardIsFetchingByView();
    const translationDashboardIntegration = useAddonDetailByName(ADD_ON_NAME.TRANSLATION_DASHBOARD)?.integration;

    const [switchMode, setSwitchMode] = React.useState('normal');
    const [isRefreshing, setIsRefreshing] = React.useState(false);

    React.useEffect(() => {
        if (translationDashboardIntegration.state === ADD_ON_STATES.INACTIVE) {
            handleClose && handleClose();
        }
    }, [translationDashboardIntegration, handleClose]);

    React.useEffect(() => {
        dispatch(
            translationDashboardActions.fetchTranslationDashboard({
                viewId,
                dbId,
                successCallback: () => {
                    console.log('fetchTranslationDashboard success');
                },
                errorCallback: () => {
                    console.log('fetchTranslationDashboard failed');
                }
            })
        );
    }, [dispatch, viewId, dbId]);

    const subTitle = (
        <div className={'flex items-center w-fit py-1 px-2 bg-grey-pale rounded gap-2'}>
            <ViewIcon view={currentView} size="small" />
            <p className="body2 inline whitespace-nowrap">{currentView && currentView?.name}</p>
        </div>
    );

    const refresh = React.useCallback(() => {
        if (isRefreshing || isFetching) return;
        setIsRefreshing(true);
        dispatch(
            translationDashboardActions.fetchTranslationDashboard({
                viewId,
                dbId,
                successCallback: () => {
                    setIsRefreshing(false);
                    dispatch(
                        enqueueSnackbar({
                            message: t('translation_overview_has_been_updated'),
                            type: 'info'
                        })
                    );
                },
                errorCallback: () => {
                    setIsRefreshing(false);
                }
            })
        );
    }, [dbId, dispatch, isFetching, isRefreshing, viewId, t]);

    return (
        <SidebarRightLayout
            id="translation_overview"
            title={t(`translation_overview`)}
            subTitle={subTitle}
            onClose={handleClose}
        >
            {isFetching && (
                <div className={classes.overlay}>
                    <Spinner size={18} thick={2} />
                </div>
            )}
            {hasTranslationDataColumns.length === 0 && (
                <Grid
                    container
                    direction="column"
                    className={classes.noDependency}
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <NoTranslationOverviewSVG />
                    </Grid>
                    <Grid item style={{ marginTop: 24 }}>
                        <h3 className="prose prose-2xl font-medium text-center">
                            {t(`translation_overview_tracking_not_set`)}
                        </h3>
                    </Grid>
                    <Grid item style={{ textAlign: 'center' }}>
                        <p className="caption">
                            <Trans i18nKey="translation_overview_tracking_not_set_subtitle" t={t}>
                                Add columns with source and target languages and
                                <br />
                                set dependencies to track translation status.
                            </Trans>
                        </p>
                    </Grid>
                </Grid>
            )}
            {hasTranslationDataColumns.length > 0 && (
                <Grid item container direction="column" className={classes.contentWrapper} wrap="nowrap">
                    <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        style={
                            isRefreshing
                                ? {
                                      opacity: DISABLED_OPACITY,
                                      pointerEvents: 'none'
                                  }
                                : {}
                        }
                    >
                        <Grid item>
                            <Grid
                                className={classes.subHeader}
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid item>
                                    <Annotation />
                                </Grid>
                                <Grid item>
                                    <PercentageSwitch value={switchMode} onChange={mode => setSwitchMode(mode)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.content}>
                            {hasTranslationDataColumns?.map(column => (
                                <Grid item key={column?.id}>
                                    <TranslationItem showPercentage={switchMode === 'percent'} {...column} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item className={classes.footer}>
                        <ButtonBase fullWidth variant="contained" onClick={refresh}>
                            {isRefreshing && (
                                <Box className={classes.boxLoading}>
                                    <CircularProgress
                                        variant="determinate"
                                        size={20}
                                        thickness={8}
                                        value={100}
                                        className={classes.cir1}
                                    />
                                    <CircularProgress
                                        variant="indeterminate"
                                        disableShrink
                                        size={20}
                                        thickness={8}
                                        className={classes.cir2}
                                    />
                                </Box>
                            )}
                            <span>{t('refresh_statistics')}</span>
                        </ButtonBase>
                    </Grid>
                </Grid>
            )}
        </SidebarRightLayout>
    );
}

export default React.memo(TranslationOverview);
