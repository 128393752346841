import React from 'react';
import { Grid, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { SELECTION_LIST_ITEM_MIN_HEIGHT, LIST_MENU_MAX_HEIGHT } from 'const/style';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import trim from 'lodash/trim';
import ViewIcon from 'gridUI/views/ViewIcon';
import { VIEW_TYPES } from 'const/gridUI';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { COLOR_TYPES } from 'const';

function ItemView({ view, selectedView, switchView, isCanEdit, updateNewView, deleteView, viewType }) {
    const [value, setValue] = React.useState(view.name || '');
    const [isEdit, setIsEdit] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);
    const inputRef = React.useRef();
    const theme = useTheme();
    const { t } = useTranslation();

    React.useEffect(() => {
        setValue(view.name || '');
    }, [view]);

    React.useEffect(() => {
        if (isCanEdit) {
            setIsEdit(true);
        }
    }, [isCanEdit]);

    React.useEffect(() => {
        if (isEdit) inputRef.current && inputRef.current.focus();
    }, [isEdit]);

    const keyDownHandler = e => {
        if (isKbEnter(e)) {
            updateNewViewHandler();
            return;
        }
        if (isKbEscape(e)) {
            setIsEdit(false);
            return;
        }
    };

    const updateNewViewHandler = () => {
        if (!trim(value)) {
            setValue(view.name);
            setIsEdit(false);
        } else {
            updateNewView({
                newView: {
                    ...view,
                    name: value
                },
                oldView: view,
                callback: () => {
                    setIsEdit(false);
                }
            });
        }
    };

    const preventParentClick = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseOver = () => {
        if (!isEdit) setIsHover(true);
    };
    const handleMouseLeave = () => setIsHover(false);
    const handleEditAction = e => {
        preventParentClick(e);
        setIsEdit(true);
    };

    const handleDeleteAction = e => {
        preventParentClick(e);
        setConfirming(true);
        setIsHover(true);
        deleteView({
            viewId: view.id,
            successCallback: () => {},
            errorCallback: () => {
                setConfirming(false);
            }
        });
    };

    const handleSwitchView = view => {
        if (!isEdit) switchView(view);
    };

    const handleOpenConfirmDelete = e => {
        preventParentClick(e);
        setOpenConfirmDelete(true);
    };

    const handleCloseConfirmDelete = e => {
        preventParentClick(e);
        setOpenConfirmDelete(false);
    };

    return (
        <Grid item>
            <ListItem
                onClick={e => handleSwitchView(view)}
                className={classNames(
                    `min-h-[36px] cursor-pointer hover:bg-hover !focus:bg-transparent rounded py-0 px-3.5`,
                    {
                        selected: view.id === (selectedView && selectedView.id)
                    }
                )}
            >
                <ListItemIcon className={'mr-2 !min-w-[26px]'}>
                    <ViewIcon view={view} size={26} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseLeave}
                            style={{ position: 'relative', top: 2 }}
                        >
                            <Grid item style={{ flex: 1 }}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item style={{ flex: 1 }}>
                                        <input
                                            disabled={!isEdit}
                                            className={
                                                'cursor-pointer bg-transparent border-none font-normal text-sm text-text-primary w-full focus:outline-none'
                                            }
                                            style={{
                                                borderBottom: isEdit ? `1px solid ${theme.colors.border}` : '',
                                                pointerEvents: !isEdit ? 'none' : ''
                                            }}
                                            ref={inputRef}
                                            value={value}
                                            onChange={e => setValue(e.target.value)}
                                            onBlur={updateNewViewHandler}
                                            onKeyDown={keyDownHandler}
                                        />
                                    </Grid>

                                    <Grid item style={{ display: 'flex' }}>
                                        {view.id === (selectedView && selectedView.id) && <CheckIconSVG />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isHover && (
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        {viewType !== VIEW_TYPES.DEFAULT_VIEW && (
                                            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_VIEW}>
                                                {({ isReadOnly }) => (
                                                    <Grid item className="flex">
                                                        <EditSVG
                                                            className={'h-3.5 hover:opacity-50'}
                                                            onClick={handleEditAction}
                                                        />
                                                    </Grid>
                                                )}
                                            </AccessControl>
                                        )}

                                        {(selectedView && selectedView.id) !== view.id &&
                                            viewType !== VIEW_TYPES.DEFAULT_VIEW && (
                                                <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_VIEW}>
                                                    {({ isReadOnly }) => (
                                                        <Grid item className="flex">
                                                            <DeleteSVG
                                                                className={'h-3.5 hover:opacity-50'}
                                                                onClick={handleOpenConfirmDelete}
                                                            />
                                                        </Grid>
                                                    )}
                                                </AccessControl>
                                            )}
                                    </Grid>
                                </Grid>
                            )}
                            <Dialog
                                id="dialog-delete-view"
                                open={openConfirmDelete}
                                onClose={handleCloseConfirmDelete}
                                onClick={preventParentClick}
                                style={{ zIndex: 1301 }}
                            >
                                <ConfirmBox
                                    title={t('delete_view')}
                                    body={<p className="body2">{t('do_you_want_to_permanently_delete_this_view')}</p>}
                                    handleCancel={handleCloseConfirmDelete}
                                    onClose={handleCloseConfirmDelete}
                                    handleAgreed={handleDeleteAction}
                                    agreeLabel="Confirm"
                                    isLoading={confirming}
                                    colorType={COLOR_TYPES.SECONDARY}
                                />
                            </Dialog>
                        </Grid>
                    }
                />
            </ListItem>
        </Grid>
    );
}

function ListView({ views, selectedView, switchView, isUpdateLastItem, updateNewView, deleteView }) {
    const scrollableListRef = React.useRef();

    React.useEffect(() => {
        if (isUpdateLastItem) {
            return;
        }

        const index = views.findIndex(view => view.id === (selectedView && selectedView.id));
        const amountToScroll = SELECTION_LIST_ITEM_MIN_HEIGHT * index;

        scrollableListRef.current && scrollableListRef.current.scrollTo(0, amountToScroll);
    }, [selectedView, isUpdateLastItem, views]);

    return (
        <Grid
            ref={scrollableListRef}
            container
            className={`overflow-y-auto max-h-[calc(${LIST_MENU_MAX_HEIGHT} - ${16 + 54 + 36}px)]`}
            wrap="nowrap"
            direction={'column'}
            spacing={0}
        >
            {views?.map(view => {
                if (view.isDeleted) return null;
                return (
                    <ItemView
                        key={view.id}
                        view={view}
                        selectedView={selectedView}
                        switchView={switchView}
                        isCanEdit={isUpdateLastItem && (selectedView && selectedView.id) === view.id}
                        updateNewView={updateNewView}
                        deleteView={deleteView}
                        viewType={view?.type}
                    />
                );
            })}
        </Grid>
    );
}

export default React.memo(ListView);
