import React from 'react';
import { Editor, Transforms, Range, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import Portal from './components/Portal';
import { withFunc } from './plugins/withFunc';
import { withTag } from './plugins/withTag';
import { insertTag } from './utils';
import Element from './components';
import ListItem from 'components/list/Item';
import { makeStyles } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import { getFormulaServerValue, getSlateDataFromFormula } from 'utils/gridUI/formula';

const useStyles = makeStyles(theme => ({
    root: {
        '& *': {
            fontFamily: 'Roboto Mono, monospace !important'
        }
    }
}));

function _isLetter(ch) {
    return /[a-zA-Z0-9]/i.test(ch);
}

function _getPossibleOffset(word) {
    if (!word) return 0;
    let offset = 0;

    const arr = word?.split('');
    for (const ch of arr) {
        if (_isLetter(ch)) {
            break;
        }
        offset++;
    }
    return offset;
}

function Formula({
    value,
    suggestions = [],
    onChange,
    suggestionZIndex,
    placeholder,
    className,
    metaData,
    onBlur,
    ...rest
}) {
    const classes = useStyles();
    const ref = React.useRef();
    const [target, setTarget] = React.useState();
    const [suggestionIndex, setSuggestionIndex] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const isFocusRef = React.useRef(false);
    const isPreventBlurRef = React.useRef(false);

    const renderElement = React.useCallback(props => <Element {...props} />, []);

    const editor = React.useMemo(() => {
        return withFunc(withTag(withReact(withHistory(createEditor()))));
    }, []);

    const suggestionsFiltered = React.useMemo(() => {
        return suggestions.filter(s => s?.label?.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10);
    }, [suggestions, search]);

    const insertSuggestion = React.useCallback(
        suggestion => {
            if (!suggestion) return;
            Transforms.select(editor, target);
            insertTag(editor, suggestion);
            ReactEditor.focus(editor);
            setTarget(null);
        },
        [editor, target]
    );

    const onKeyDown = React.useCallback(
        event => {
            if (target) {
                switch (event.key) {
                    case 'ArrowDown': {
                        event.preventDefault();
                        const prevIndex = suggestionIndex >= suggestionsFiltered?.length - 1 ? 0 : suggestionIndex + 1;
                        setSuggestionIndex(prevIndex);
                        return;
                    }
                    case 'ArrowUp': {
                        event.preventDefault();
                        const prevIndex = suggestionIndex <= 0 ? suggestionsFiltered?.length - 1 : suggestionIndex - 1;
                        setSuggestionIndex(prevIndex);
                        return;
                    }

                    case 'Tab':
                    case 'Enter': {
                        if (suggestionsFiltered?.length) {
                            event.preventDefault();
                            insertSuggestion(suggestionsFiltered?.[suggestionIndex]);
                            return;
                        }
                        break;
                    }
                    case 'Escape':
                        event.preventDefault();
                        setTarget(null);
                        return;

                    default:
                        return;
                }
            }

            switch (event.key) {
                case 'Enter': {
                    event.preventDefault();
                    return;
                }
                default:
                    return;
            }
        },
        [target, suggestionIndex, suggestionsFiltered, insertSuggestion]
    );

    React.useEffect(() => {
        if (target && suggestionsFiltered?.length > 0) {
            try {
                const el = ref.current;
                const domRange = ReactEditor.toDOMRange(editor, target);
                const rect = domRange.getBoundingClientRect();
                el.style.top = `${rect.top + window.pageYOffset + 24}px`;
                el.style.left = `${rect.left + window.pageXOffset}px`;
            } catch (error) {
                console.log('error', error);
            }
        }
    }, [target, suggestionsFiltered.length, editor]);

    const handleChange = React.useCallback(
        changedValue => {
            if (JSON.stringify(value) === JSON.stringify(changedValue)) return;
            onChange && onChange(changedValue);

            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
                const [start] = Range.edges(selection);

                const firstRange = Editor.range(editor, { path: [0, 0], offset: 0 }, start);
                const firstText = firstRange && Editor.string(editor, firstRange);
                const wordBefore = Editor.before(editor, start, {
                    unit: 'word'
                });
                const before = wordBefore && Editor.before(editor, wordBefore);
                const beforeRange = before && Editor.range(editor, before, start);
                const beforeText = beforeRange && Editor.string(editor, beforeRange);

                if (!!beforeText || !!firstText) {
                    const beforeTextActual = beforeText?.replace(/[^a-zA-Z0-9]/g, '');
                    const specialKeyOffset = _getPossibleOffset(beforeText);

                    if (beforeText) {
                        const newBeforeRange = {
                            ...beforeRange,
                            anchor: {
                                ...beforeRange.anchor,
                                offset: beforeRange.anchor?.offset + specialKeyOffset
                            }
                        };

                        setTarget(newBeforeRange);
                    } else {
                        setTarget(firstRange);
                    }
                    setSearch(beforeTextActual?.trim() || firstText?.trim());
                    setSuggestionIndex(0);
                    return;
                }
            }

            setTarget(null);
        },
        [value, editor, onChange]
    );

    const onPaste = React.useCallback(
        event => {
            if (checkContainId(event, 'editable-formula')) {
                const element = document.querySelector('#editable-formula');
                if (element) {
                    setTimeout(() => {
                        editor.selection = null;
                        isFocusRef.current = true;
                        const children = editor?.children || [];
                        const text = getFormulaServerValue(children);
                        const deserializeValue = getSlateDataFromFormula({ string: text, metaData });
                        onChange(deserializeValue);
                    }, 0);
                }
            }
        },
        [editor, metaData, onChange]
    );

    React.useEffect(() => {
        document.addEventListener('paste', onPaste, true);
        return () => {
            document.removeEventListener('paste', onPaste, true);
        };
    }, [onPaste]);

    React.useEffect(() => {
        if (isFocusRef.current) {
            isFocusRef.current = false;
            const lastCharPosition = Editor.end(editor, []);
            Transforms.select(editor, lastCharPosition);
            ReactEditor.focus(editor);
        }
    }, [value, editor]);

    const handleBlur = React.useCallback(() => {
        setTimeout(() => {
            if (isPreventBlurRef.current) {
                isPreventBlurRef.current = false;
                return;
            }
            setTarget(null);
            onBlur();
        }, 200);
    }, [onBlur]);

    const clickSuggestion = React.useCallback(
        suggestion => () => {
            isPreventBlurRef.current = true;
            insertSuggestion(suggestion);
        },
        [insertSuggestion]
    );

    const preventBlur = React.useCallback(() => {
        isPreventBlurRef.current = true;
    }, []);

    return (
        <>
            <Slate editor={editor} value={value} onChange={handleChange}>
                <Editable
                    id="editable-formula"
                    renderElement={renderElement}
                    onKeyDown={onKeyDown}
                    onPaste={() => {}}
                    placeholder={placeholder || 'Enter your formula'}
                    className={`${classes.root} ${className}`}
                    onBlur={handleBlur}
                    {...rest}
                />

                {target && suggestionsFiltered?.length > 0 && (
                    <Portal>
                        <div
                            ref={ref}
                            id="formula-suggestion"
                            style={{
                                top: '-9999px',
                                left: '-9999px',
                                position: 'absolute',
                                zIndex: suggestionZIndex || 1300,
                                background: 'white',
                                borderRadius: '4px',
                                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                                minWidth: 200
                            }}
                        >
                            {suggestionsFiltered.map((suggestion, i) => (
                                <ListItem
                                    icon={suggestion.icon}
                                    key={i}
                                    isSelected={i === suggestionIndex}
                                    name={suggestion?.label}
                                    onClick={clickSuggestion(suggestion)}
                                    onMouseDown={preventBlur}
                                />
                            ))}
                        </div>
                    </Portal>
                )}
            </Slate>
        </>
    );
}

export default Formula;
