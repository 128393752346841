import React from 'react';

function DeactivateTmSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#activate-tm-clip0)">
                <path
                    d="M11.9 12.2H4.1C1.8 12.2 0 10.3 0 8.1C0 5.9 1.8 4 4.1 4H11.9C14.1 4 16 5.8 16 8.1C16 10.3 14.2 12.2 11.9 12.2ZM4.1 5.3C2.6 5.3 1.3 6.5 1.3 8.1C1.3 9.6 2.5 10.9 4.1 10.9H11.9C13.4 10.9 14.7 9.7 14.7 8.1C14.7 6.6 13.5 5.3 11.9 5.3H4.1Z"
                    fill={color}
                />
                <path
                    d="M4.2002 10.1016C3.1002 10.1016 2.2002 9.20156 2.2002 8.10156C2.2002 7.00156 3.1002 6.10156 4.2002 6.10156C5.3002 6.10156 6.2002 7.00156 6.2002 8.10156C6.2002 9.20156 5.3002 10.1016 4.2002 10.1016ZM4.2002 7.40156C3.8002 7.40156 3.5002 7.70156 3.5002 8.10156C3.5002 8.50156 3.8002 8.80156 4.2002 8.80156C4.6002 8.80156 4.9002 8.50156 4.9002 8.10156C4.9002 7.70156 4.6002 7.40156 4.2002 7.40156Z"
                    fill={color}
                />
                <path
                    d="M5.39995 14.6001C5.29995 14.6001 5.19995 14.6001 5.09995 14.5001C4.79995 14.3001 4.69995 13.9001 4.89995 13.6001L12.2999 1.80015C12.4999 1.50015 12.8999 1.40015 13.1999 1.60015C13.4999 1.80015 13.5999 2.20015 13.3999 2.50015L5.89995 14.3001C5.79995 14.5001 5.59995 14.6001 5.39995 14.6001Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="activate-tm-clip0">
                    <rect width="16" height="13.1" fill="white" transform="translate(0 1.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(DeactivateTmSVG);
