import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DEPENDENCY_STATUS } from 'const/gridUI';
import theme from 'theme';
import DependencySVG from 'assets/images/svg/DependencySVG';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import i18n from 'i18n';
function getDependencyColor({ value }) {
    switch (value) {
        case DEPENDENCY_STATUS.UP_TO_DATE:
            return theme.colors.atlantis;
        case DEPENDENCY_STATUS.OUT_OF_DATE:
            return theme.colors.sun;
        case DEPENDENCY_STATUS.UNSET:
            return theme.colors.brightRed;
        default:
            return null;
    }
}

function getDependencyLabel({ value }) {
    switch (value) {
        case DEPENDENCY_STATUS.UP_TO_DATE:
            return i18n.t(`dependency_label_up_to_date`);
        case DEPENDENCY_STATUS.OUT_OF_DATE:
            return i18n.t(`dependency_label_out_of_date`);
        case DEPENDENCY_STATUS.UNSET:
            return i18n.t(`dependency_label_unset`);
        default:
            return null;
    }
}

function SubFieldRender({ subField, value, t }) {
    switch (subField) {
        case '_dependencyStatus':
            return (
                <Grid alignItems="center" direction="row" spacing={2} container>
                    <Grid item style={{ display: 'flex' }}>
                        <DependencySVG color={getDependencyColor({ value })} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{getDependencyLabel({ value })}</Typography>
                    </Grid>
                </Grid>
            );
        case '_tm':
            return (
                <Grid alignItems="center" wrap="nowrap" direction="row" spacing={2} container>
                    <Grid item style={{ display: 'flex' }}>
                        <TMStatusAprrovedSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t(`tm_label_filled`)}</Typography>
                    </Grid>
                </Grid>
            );
        default:
            return null;
    }
}

export default React.memo(SubFieldRender);
