import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { useColumnsPermission } from 'hooks/gridUI';
import { ACTION_STATUS } from 'gridUI/automations/const';
import { SYSTEM_COLUMN_IDS } from 'const';
import * as columnTypes from 'const/columnTypes';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import TooltipActionDisable from '../TooltipActionDisable';
import LDBasePortal from 'components/selects/LDBasePortal';
import { updateActionNode } from 'gridUI/automations/action';
import MapTranslatedColumn from './MapTranslatedColumn';
import { AUTOMATION_GOOGLE_OCR_ACTIONS } from 'const/automation';

const useStyles = makeStyles(theme => ({
    mt12: {
        marginTop: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    requiredMark: {
        color: theme.colors.brightRed
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    selectError: {
        borderColor: theme.colors.brightRed
    },
    flagWrapper: {
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4
    },
    controlClassName: {
        minHeight: 60
    },
    editor: {
        maxHeight: 280,
        height: 'unset'
    },
    hideControl: {
        '& .MuiFormControl-root': {
            display: 'none'
        }
    }
}));

const styleIcon = {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid #E9EAEF`,
    borderRadius: 4
};

const ActionGoogleOCR = ({ selectedNode, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const columnsPermission = useColumnsPermission();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const [detectType, setDetectType] = useState(selectedNode?.action || '');
    const [columnSource, setColumnSource] = useState(selectedNode?.params?.columnSource?.publicId || '');
    const openDetectRef = useRef();
    const openColumnSourceRef = useRef();
    const timeout = useRef();

    useEffect(() => {
        openDetectRef.current = false;
        openColumnSourceRef.current = false;
        setDetectType(selectedNode?.action || '');
        setColumnSource(selectedNode?.params?.columnSource?.publicId || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const selectedDetectTypeOption = useMemo(() => {
        return AUTOMATION_GOOGLE_OCR_ACTIONS.find(opt => opt.value === detectType);
    }, [detectType]);

    const handleDetectTypeChange = useCallback(
        option => {
            const newValue = option.value;
            if (newValue === detectType) return;
            setDetectType(newValue);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            action: newValue
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setDetectType(selectedNode?.action || '');
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, detectType, dispatch, selectedNode.action, selectedNode.id, setSelectedNode]
    );

    const viewColumnOptions = useMemo(() => {
        return columnsPermission
            .filter(col => !SYSTEM_COLUMN_IDS?.includes(col.id))
            .map(col => {
                let value = '';
                if (col.id === columnTypes.RECORD_ID) value = '_recordId';
                else if (col.id === columnTypes.PATH_TAG) value = '_path';
                else value = col.publicId;

                return {
                    ...col,
                    value,
                    label: col.name,
                    icon: () => (
                        <ColumnIcon
                            group={col?.group}
                            type={col?.type}
                            customProperties={col?.customProperties}
                            styleCountryFlagWrapper={styleIcon}
                            style2Digit={styleIcon}
                            normalWrapper
                            normalWrapperStyle={{
                                ...styleIcon,
                                position: 'relative',
                                left: -4
                            }}
                        />
                    ),
                    options: null
                };
            });
    }, [columnsPermission]);

    const selectedColumnOption = useMemo(() => {
        return viewColumnOptions.find(opt => opt.value === columnSource);
    }, [columnSource, viewColumnOptions]);

    const viewableColumnOptions = useMemo(() => {
        return viewColumnOptions.filter(col => !!col.viewable);
    }, [viewColumnOptions]);

    const viewableFileColumnOptions = useMemo(() => {
        return viewableColumnOptions.filter(col => col?.type === columnTypes.FILES);
    }, [viewableColumnOptions]);

    const errorColumnSourceMessage = useMemo(() => {
        return nodeError.columnSource || '';
    }, [nodeError.columnSource]);

    const handleColumnSourceChange = useCallback(
        option => {
            const newValue = option.value;
            if (newValue === columnSource) return;
            setColumnSource(newValue);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                columnSource: newValue
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setColumnSource(selectedNode?.params?.columnSource?.publicId || '');
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, columnSource, dispatch, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    return (
        <Grid item>
            <Grid item className={classes.mb24}>
                <Grid item className={classes.mb14}>
                    <p className="body1">
                        {t('automation_detect_type')}
                        <span className={classes.requiredMark}>*</span>
                    </p>
                </Grid>
                <Grid item>
                    <TooltipActionDisable isAction disabled={isDisabled}>
                        <Grid item>
                            <LDBasePortal
                                ddPlaceholder={t('automation_choose_detect_type')}
                                options={AUTOMATION_GOOGLE_OCR_ACTIONS}
                                controlClassName={classnames(classes.controlClassName)}
                                popperClassName={classnames(classes.hideControl)}
                                onChange={handleDetectTypeChange}
                                defaultValue={selectedDetectTypeOption}
                                isUsingContainer
                            />
                        </Grid>
                    </TooltipActionDisable>
                </Grid>
            </Grid>
            <Grid item className={classes.mb24}>
                <Grid item className={classes.mb14}>
                    <p className="body1">
                        {t('automation_source_column')}
                        <span className={classes.requiredMark}>*</span>
                    </p>
                </Grid>
                <Grid item>
                    <TooltipActionDisable isAction disabled={isDisabled}>
                        <Grid item>
                            <LDBasePortal
                                ddPlaceholder={'Choose column'}
                                menuPlaceholder={'Find column'}
                                options={viewableFileColumnOptions}
                                controlClassName={classnames(classes.controlClassName, {
                                    [classes.selectError]: !!errorColumnSourceMessage
                                })}
                                onChange={handleColumnSourceChange}
                                defaultValue={selectedColumnOption}
                                isUsingContainer
                            />
                        </Grid>
                        {errorColumnSourceMessage && (
                            <Grid item className={classes.mt12}>
                                <p className="text-error">{errorColumnSourceMessage}</p>
                            </Grid>
                        )}
                    </TooltipActionDisable>
                </Grid>
            </Grid>
            <Grid item>
                <MapTranslatedColumn
                    isRequired
                    label={t('automation_column_to_hold_text_recognition_result')}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    filterColumns={[...columnTypes.AUTOMATION_TEXT_COLUMNS, columnTypes.DATETIME, columnTypes.NUMBER]}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(ActionGoogleOCR);
