import { useSelector } from 'react-redux';
import { useViewSortOrderList } from './viewSortOrder';
import { useQuickSorts } from './quickSort';
import { isTempId } from 'utils/uuid';
import { useGridStatusDetail } from '../grid';
import { useBranchStatus } from '../versionControl';
import isEmpty from 'lodash/isEmpty';

export function useGridUIIsLoading() {
    return useSelector(state => state.gridUI.isLoading);
}

export function useIsSelecting() {
    return useSelector(state => state.gridUI.isSelecting);
}

export function useIsCellCopying() {
    return useSelector(state => state.gridUI.isCellCopying);
}

export function useIsFilteringSorting() {
    return useSelector(state => state.gridUI.isFilteringSorting);
}

export function useFixedColumnCount() {
    return useSelector(state => state.gridUI.fixedColumnCount);
}

export function useLastScrollPosition() {
    return useSelector(state => state.gridUI.lastScrollPosition);
}

export function useIsRecomputedGrid() {
    return useSelector(state => state.gridUI.isRecomputedGrid);
}

export function useGridIdInGridUI() {
    return useSelector(state => state.gridUI.gridId);
}

export function useWorkspaceIdInGridUI() {
    return useSelector(state => state.gridUI.workspaceId);
}

export function useDbIdInGridUI() {
    return useSelector(state => state.gridUI.dbId);
}

export function useParentGridIdInGridUI() {
    return useSelector(state => state.gridUI.parentGridId);
}

export function useDefaultAccessViewIdInGridUI() {
    return useSelector(state => state.gridUI.defaultAccessViewId);
}

export function useTableInfo() {
    return useSelector(state => state.gridUI.tableInfo);
}

export function useGridStatus({ dbId, branchId }) {
    const parentGridId = useParentGridIdInGridUI();
    const masterStatus = useGridStatusDetail({ dbId, gridId: branchId });
    const branchStatus = useBranchStatus({ masterId: parentGridId, branchId });

    if (parentGridId === branchId) return masterStatus;

    return branchStatus;
}

export function useIsSortingIsOn() {
    const quickSorts = useQuickSorts();
    const viewSorts = useViewSortOrderList();

    const isHavingQuickSorts = Object.keys(quickSorts)?.length;
    const isHavingViewSorts = viewSorts?.filter(sort => !isTempId(sort?.id))?.length > 0;

    return isHavingViewSorts || isHavingQuickSorts;
}

export function usePinnedSearch() {
    return useSelector(state => state?.gridUI?.pinnedSearch);
}

export function useGridTotalRecords() {
    return useSelector(state => state.gridUI.gridTotalRecords);
}

export function useIsSearchingRecord() {
    return useSelector(state => state?.gridUI?.isSearchingRecord);
}

export function useIsOpenReplaceSearch() {
    return useSelector(state => state?.gridUI?.isOpenReplaceSearch);
}

export function useIsReplacingText() {
    return useSelector(state => state?.gridUI?.replacingText);
}

export function useSearchRecords() {
    return useSelector(state => state?.gridUI?.searchRecords);
}

export function useSuccessReplaceAll() {
    return useSelector(state => state?.gridUI?.successReplaceAll);
}

export function useReplacingType() {
    return useSelector(state => state?.gridUI?.replacingType);
}

export function useClearingRecords() {
    return useSelector(state => state?.gridUI?.clearingRecords);
}

export function useIsClearingRecords() {
    const clearingRecords = useClearingRecords() || {};
    const { workspaceId: cWorkspaceId, dbId: cDbId, gridId: cGridId } = clearingRecords;
    const workspaceId = useWorkspaceIdInGridUI();
    const dbId = useDbIdInGridUI();
    const gridId = useGridIdInGridUI();
    return !isEmpty(clearingRecords) && workspaceId === cWorkspaceId && dbId === cDbId && gridId === cGridId;
}

export function useHoldEvent() {
    return useSelector(state => state.gridUI.holdEvent);
}

export function useCurrentGrid() {
    return useSelector(state => state.gridUI.currentGrid);
}

export function useMergingTask(taskId) {
    return useSelector(state => state.gridUI.mergingTasks?.[taskId]);
}

export function useOpenRightSidebar() {
    return useSelector(state => state.gridUI.openRightSideBar);
}

export * from './aggregation';
export * from './cellInfo';
export * from './column';
export * from './data';
export * from './metaData';
export * from './quickFilter';
export * from './quickSort';
export * from './row';
export * from './view';
export * from './viewFilter';
export * from './viewSortOrder';
export * from './import';
export * from './recordMetaData';
export * from './apiKey';
export * from './dependency';
export * from './range';
export * from './color';
export * from './doing';
export * from './comment';
export * from './gridHistory';
export * from './export';
export * from './undoRedo';
export * from './recordHistory';
export * from './activeUser';
export * from './pathTag';
export * from './webhook';
export * from './tm';
export * from './translationDashboard';
export * from './localization';
export * from './shareView';
export * from './recordPanel';
export * from './cellIssue';
export * from './lqa';
export * from './tb';
export * from './tag';
export * from './autoQA';
export * from './cellCopied';
