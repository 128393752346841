import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import isArray from 'lodash/isArray';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import AudioPlay from 'components/audio/Base';
import hexToRgba from 'hex-to-rgba';
import PlaySVG from 'assets/images/svg/PlaySVG';
import PauseSVG from 'assets/images/svg/PauseSVG';
import PlayPause from 'components/circularPercentage/PlayPause';
import {
    isFileAudio,
    isFileVideo,
    isFileImage,
    getFilePreview,
    isFilePdf,
    generateAvatarWithHeight
} from 'utils/images';
import { FILE_SPACING, FILE_RATIO, CELL_PADDING } from 'const/gridUI';
import { enqueueSnackbar } from 'notifier/actions';
import { getUploadErrorMessage } from 'utils/upload';
import * as advancedSearchActions from 'advancedSearch/actions';
import { GRID_UPLOAD_MAX_SIZE } from 'const';
import { useDropzone } from 'react-dropzone';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';
import { useIsCellOverlaid } from 'hooks/advanced';

const useStyles = makeStyles(theme => ({
    root: {
        padding: CELL_PADDING,
        paddingLeft: 20,
        height: `100%`,
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
        overflow: 'hidden',
        outline: 'none !important',
        border: `1px solid transparent`
    },
    left: {
        verticalAlign: 'top',
        display: 'table-cell',
        whiteSpace: 'pre-wrap',
        textAlign: 'left'
    },
    avatar: {
        width: '100%',
        height: '100%',
        borderRadius: 4
    },
    loading: {
        width: 25,
        height: 25,
        borderRadius: 4,
        '& .MuiChip-label': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0
        }
    },
    upload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.divider,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        '& svg': {
            width: 10,
            height: 10
        },
        '& input:focus': {
            outlined: 'none'
        },
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    preview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        cursor: 'pointer'
    },
    dialog: {
        '& .MuiDialog-paper': {
            background: hexToRgba(theme.colors.black, 0.2)
        }
    },
    dragActive: {
        border: `2px dashed ${hexToRgba(theme.colors.atlantis, 0.8)}`
    },
    dragReject: {
        border: `2px dashed ${hexToRgba(theme.colors.brightRed, 0.8)}`
    },
    dragAccept: {
        border: `2px dashed ${hexToRgba(theme.colors.dodgerBlue, 0.8)}`
    },
    pointer: {
        cursor: 'pointer'
    },
    zoomIn: {
        cursor: 'zoom-in'
    },
    zoomOut: {
        cursor: 'zoom-out'
    },
    uploadSpinner: {
        borderRadius: 4,
        display: 'none',
        background: theme.colors.paleGrey,
        padding: 0,
        '&.upload_spinner_loading': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    fileItem: {
        minWidth: 20,
        minHeight: 20
    },
    overlaid: {
        position: 'absolute',
        zIndex: 2
    }
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Fade ref={ref} {...props} />;
// });

function AudioThumbnail({ url, style, rowId, columnId, ...rest }) {
    const theme = useTheme();
    // const dispatch = useDispatch();

    const [isPlay, setIsPlay] = React.useState(false);
    const [isPause, setIsPause] = React.useState(true);
    const [isShowPlay, setIsShowPlay] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const handleMouseEnter = () => {
        setIsShowPlay(true);
    };

    const handleMouseLeave = () => {
        setIsShowPlay(false);
    };

    const handleTimeUpdate = e => {
        const duration = e.target.duration;
        const currentTime = e.target.currentTime;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
    };

    // const cancelAllVideo = () => {
    //     const allAudios = document.getElementsByTagName('audio');

    //     if (allAudios?.length) {
    //         for (let i = 0; i < allAudios?.length; i++) {
    //             const audio = allAudios?.[i];

    //             if (audio && audio?.id !== `audio_${url}`) {
    //                 audio.pause();
    //             }
    //         }
    //     }
    // };

    const onToggle = e => {
        // cancelAllVideo();
        stopPropagation(e);
        const audioElement = document.getElementById(`audio_${rowId}_${columnId}_${url}`);
        setIsPlay(true);
        if (audioElement.paused) {
            audioElement.play();
            setIsPause(false);
        } else {
            audioElement.pause();
            setIsPause(true);
        }
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleVideoPlayEnd = () => {
        setIsPlay(false);
        setIsPause(true);
    };

    return (
        <Grid
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                position: 'relative'
            }}
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {(isPlay || isShowPlay) && (
                <>
                    <span
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: hexToRgba(theme.colors.black, 0.5),
                            zIndex: 1
                        }}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={onToggle}
                    >
                        <PlayPause value={isPlay ? progress : 0} icon={isPause ? <PlaySVG /> : <PauseSVG />} />
                    </span>
                </>
            )}
            <AudioThumbnailSVG style={style} />
            <AudioPlay
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleVideoPlayEnd}
                id={`audio_${rowId}_${columnId}_${url}`}
                src={url}
                style={{ width: 0, height: 0 }}
            />
        </Grid>
    );
}

function ImageRender({
    value,
    rowHeight,
    columnWidth,
    rowIndex,
    columnIndex,
    rowId,
    columnId,
    gridId,
    dbId,
    isOpenUpload,
    isReadOnly,
    isShareViewLink,
    isOverFileStorage,
    ...rest
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles({ rowHeight });
    // const [isUploading, setIsUploading] = React.useState(false);
    const { width, height } = generateAvatarWithHeight({ columnWidth, ratio: FILE_RATIO });
    // const [isOpenLimitPopup, setIsOpenLimitPopup] = React.useState(false);
    // const companyId = useCompanyId();
    // const history = useHistory();
    const isCellOverlaid = useIsCellOverlaid({ rowIndex, columnIndex });

    // const handleOpenLimitModal = () => setIsOpenLimitPopup(true);
    // const handleCloseLimitModal = () => {
    //     setIsOpenLimitPopup(false);
    // };

    const handleClickOpen = ({ e, fileMeta }) => {
        stopPropagation(e);
        let body = {
            rowId,
            columnId,
            dbId
        };
        if (fileMeta) {
            body = {
                ...body,
                defaultPreviewFile: fileMeta
            };
        }
        dispatch(advancedSearchActions.openCellFilePreview(body));
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles);
            const fileError = fileErrors?.[0];
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const onDropAccepted = React.useCallback(
        acceptedFiles => {
            let item = acceptedFiles[0];
            let formData = new FormData();
            formData.append('file', item);
            dispatch(
                advancedSearchActions.uploadFileForCell({
                    formData,
                    rowId,
                    columnId,
                    successCallback: () => {
                        console.log('upload file successfully');
                        dispatch(advancedSearchActions.cancelCellEdit());
                    },
                    errorCallback: () => {
                        console.log('Failed to upload file');
                    }
                })
            );
        },
        [columnId, dispatch, rowId]
    );

    const { getRootProps, isDragActive } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: false,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noClick: true,
        noKeyboard: true
    });

    const style = React.useMemo(
        () => ({
            ...(isDragActive
                ? {
                      height: `${rowHeight - (isCellOverlaid ? 2 : 1)}px`,
                      border: `1px dashed ${theme.colors.highlight}`,
                      background: hexToRgba(theme.colors.highlight, 0.05),
                      borderRadius: 4,
                      display: 'block'
                  }
                : {})
        }),
        [isDragActive, theme, rowHeight, isCellOverlaid]
    );

    const dropFullProps = React.useMemo(() => {
        return !isReadOnly ? { ...getRootProps({ style }) } : {};
    }, [isReadOnly, getRootProps, style]);

    return (
        <div className={`${classes.root} ${isCellOverlaid && classes.overlaid}`} {...dropFullProps}>
            <Grid
                container
                spacing={FILE_SPACING}
                style={{ outline: 'none' }}
                direction="row"
                className={`cell_${rowIndex}_${columnIndex}`}
            >
                {isArray(value) &&
                    value.map((fileMeta, index) => {
                        let isImage = isFileImage(fileMeta);
                        let isAudio = isFileAudio(fileMeta);
                        let isVideo = isFileVideo(fileMeta);
                        let isPdf = isFilePdf(fileMeta);

                        let url = getFilePreview({ fileId: fileMeta?.thumbnailId, dbId });

                        return (
                            <Grid item key={fileMeta?.id} style={{ position: 'relative' }}>
                                <Avatar
                                    className={`cell_${rowIndex}_${columnIndex} ${!isAudio ? classes.zoomIn : ``} ${
                                        classes.fileItem
                                    }`}
                                    style={{
                                        lineHeight: `${rowHeight}px`,
                                        height: height,
                                        width: width,
                                        borderRadius: 4,
                                        background: !isImage
                                            ? isAudio
                                                ? '#EEF5FB'
                                                : isVideo
                                                ? '#FDF0ED'
                                                : '#F3F2F9'
                                            : ''
                                    }}
                                    src={url}
                                    alt={fileMeta?.originalName}
                                    onClick={isAudio ? () => {} : e => handleClickOpen({ e, fileMeta })}
                                >
                                    {!isImage &&
                                        (isAudio ? (
                                            <AudioThumbnail
                                                rowId={rowId}
                                                columnId={columnId}
                                                style={{ width, height }}
                                                url={url}
                                            />
                                        ) : isVideo ? (
                                            <VideoThumbnailSVG style={{ width, height }} />
                                        ) : isPdf ? (
                                            <PDFFILESVG style={{ width, height }} />
                                        ) : (
                                            <OtherFileTypeThumbnailSVG style={{ width, height }} />
                                        ))}
                                </Avatar>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}

export default React.memo(ImageRender);
