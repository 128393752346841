import React from 'react';

function MediumSVG({ color = '#F8AD13', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect x="2" y="3" width="12" height="2.5" fill={color} />
            <rect x="2" y="10.5" width="12" height="2.5" fill={color} />
        </svg>
    );
}

export default React.memo(MediumSVG);
