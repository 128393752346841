import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, useTheme, Tabs, Tab } from '@material-ui/core';
import AddonLayout from 'addons/layout';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';
import GridlyShortLogoSVG from 'assets/images/svg/addon/GridlyShortLogoSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import PopperMenu from 'components/menus/Popper';
import MenuOption from 'addons/inapp/MenuOption';
import { useDispatch } from 'react-redux';
import { PAYMENT_WARNING_TYPES, PLAN_TYPES, PAYMENT_METHODS, SUBSCRIPTION_STATUS } from 'const';
import CheckedSVG from 'assets/images/svg/CheckedSVG';
import WarningBox from 'payment/components/warning';
import { useTranslation } from 'react-i18next';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import * as paymentActions from 'payment/actions';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useHistory } from 'react-router-dom';
import { useCardInfo, useCurrentSubscriptionIs, useIsProTrial, usePaymentMethod } from 'hooks/payment';
import Button from 'components/button/Base';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { isOwner } from 'utils/permission/role';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    stepSpacing: {
        marginBottom: theme.spacing(3)
    },
    sendFeedback: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    dlFlex: {
        display: 'flex'
    },
    avatar: {
        '& svg': {
            width: 88,
            height: 88
        }
    },
    more: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 10
    },
    notEnabled: {
        background: `#F1F7FA`,
        height: '100%',
        padding: theme.spacing(3)
    },
    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    buttonWrapper: {
        border: `1px solid ${theme.colors.divider}`,
        borderRadius: 4,
        height: 38
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    center: {
        textAlign: 'center',
        maxWidth: '70%',
        margin: `0 auto`
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    sidebar: {
        padding: theme.spacing(4)
    },
    upgradeIcon: {
        marginRight: theme.spacing(1)
    },
    greyText: {
        color: theme.colors.midGrey
    },
    addonWrapper: {
        padding: 40,
        background: theme.colors.ghostwhite,
        borderRadius: 8,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    tabContent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    SETTING: 'SETTING',
    OVERVIEW: 'OVERVIEW'
};

function AddonDetail({ addon, onBack, onClose }) {
    const { title, icon, description, descriptionItems, priceItems, subscription, plan } = addon;
    const { addonType } = plan;
    const subscriptionId = subscription?.id;
    const subscriptionStatus = subscription?.status;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const cardInfo = useCardInfo();
    const userInfo = useCurrentUserFullInfo();
    const isCurrentOwner = isOwner({ currentUser: userInfo });
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isProTrial = useIsProTrial();
    const isEnterprisePlan = useCurrentSubscriptionIs(PLAN_TYPES.ENTERPRISE);
    const paymentMethod = usePaymentMethod();
    const history = useHistory();
    const [openBuyAddonModal, setOpenBuyAddonModal] = React.useState(false);
    const [openConfirmUninstallModal, setOpenConfirmUninstallModal] = React.useState(false);
    const [isOpenNoCard, setIsOpenNoCard] = React.useState(false);
    const [isOpenContact, setIsOpenContact] = React.useState(false);
    const tabClasses = useTabStyles();
    const [tabValue, setTabValue] = React.useState(TABS.OVERVIEW);

    const handleCloseBuyModal = () => {
        setOpenBuyAddonModal(false);
    };

    const handleOpenBuyModal = () => {
        if (!Boolean(cardInfo) && !isProTrial) {
            handleOpenNoCard();
            return;
        }

        if (isEnterprisePlan || paymentMethod === PAYMENT_METHODS.INVOICE) {
            handleOpenContact();
            return;
        }
        setOpenBuyAddonModal(true);
    };

    const handleOpenConfirmUninstallModal = () => {
        if (isEnterprisePlan || paymentMethod === PAYMENT_METHODS.INVOICE) {
            handleOpenContact();
            return;
        }
        setOpenConfirmUninstallModal(true);
    };

    const handleCloseConfirmUninstallModal = () => {
        setOpenConfirmUninstallModal(false);
    };

    const handleCloseNoCard = () => {
        setIsOpenNoCard(false);
    };

    const handleOpenNoCard = () => {
        setIsOpenNoCard(true);
    };

    const handleCloseContact = () => {
        setIsOpenContact(false);
    };

    const handleOpenContact = () => {
        setIsOpenContact(true);
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const isInstalled =
        subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE || subscriptionStatus === SUBSCRIPTION_STATUS.TRIALING;

    const handleUpgrade = () => {
        onClose();
        history.push(`/company-settings/billing/overview`);
    };

    const handleNoCard = () => {
        onClose();
        history.push(`/company-settings/billing/detail`);
    };

    const handleBuy = () => {
        setIsLoading(true);
        dispatch(
            paymentActions.buyAddon({
                body: { addonType },
                errorCallback: () => {
                    console.log('error');
                    setIsLoading(false);
                },
                successCallback: () => {
                    console.log('success');
                    setIsLoading(false);
                    handleCloseBuyModal();
                }
            })
        );
    };

    const handleRemoveAddon = e => {
        setIsLoading(true);
        dispatch(
            paymentActions.cancelAddonSubscription({
                id: subscriptionId,
                successCallback: () => {
                    handleCloseConfirmUninstallModal();
                    setIsLoading(false);
                },
                errorCallback: () => {
                    console.log('canceled failed');
                    setIsLoading(false);
                }
            })
        );
    };

    const ButtonInstallRender = () => {
        if (isInstalled) {
            return (
                <Grid container alignItems="center" spacing={3} wrap="nowrap">
                    <Grid item className={classes.buttonWrapper} container justify="center" alignItems="center">
                        <Grid item className={classes.dlFlex}>
                            <CheckedSVG
                                color={theme.colors.white}
                                fillColor={hexToRgba(theme.colors.atlantis, 0.8)}
                                style={{ marginRight: 4 }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{t('global_installed')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClick} className={classes.more}>
                            <IconMoreActionsSVG />
                        </IconButton>
                    </Grid>
                    {anchorEl && (
                        <PopperMenu
                            className={classes.commentPopup}
                            anchorEl={anchorEl}
                            placement={'bottom-end'}
                            id={`add-on-setting`}
                        >
                            <MenuOption
                                onRemoveAddon={() => {
                                    handleOpenConfirmUninstallModal();
                                    handleClickAway();
                                }}
                                id={subscriptionId}
                                handleClickAway={handleClickAway}
                            />
                        </PopperMenu>
                    )}
                </Grid>
            );
        }

        if (isFreePlan) {
            return (
                <ButtonBase width={120} onClick={handleUpgrade} variant="contained">
                    <UpgradeSVG className={classes.upgradeIcon} />
                    {t('global_upgrade')}
                </ButtonBase>
            );
        }

        return (
            <ButtonBase width={100} onClick={handleOpenBuyModal} variant="contained">
                {t('global_install')}
            </ButtonBase>
        );
    };

    return (
        <>
            <AddonLayout onBack={onBack}>
                <Grid container direction="column">
                    <Grid item className={classes.sidebar}>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justify="space-between"
                                    direction="row"
                                    wrap="nowrap"
                                >
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" spacing={3}>
                                            <Grid item className={classes.avatar}>
                                                {icon()}
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="h4">{title}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption">
                                                            {t('global_last_update_in')} Sep 14th, 2022
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item className={classes.dlFlex}>
                                                                <GridlyShortLogoSVG />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    {t('global_gridly_text')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>{ButtonInstallRender()}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Tabs
                                    className={classes.tabs}
                                    value={tabValue}
                                    onChange={(e, newValue) => setTabValue(newValue)}
                                >
                                    <Tab classes={tabClasses} value={TABS.OVERVIEW} label={t('overview')} />
                                    {/* <Tab classes={tabClasses} value={TABS.SETTING} label="Settings" /> */}
                                </Tabs>
                            </Grid>
                            <Grid item className={classes.tabContent}>
                                {tabValue === TABS.OVERVIEW && (
                                    <Grid container direction="column" spacing={5}>
                                        <Grid item>
                                            <Grid container direction="column" spacing={4}>
                                                {isFreePlan && (
                                                    <Grid item>
                                                        <WarningBox
                                                            warningType={PAYMENT_WARNING_TYPES.INFO}
                                                            message={t('addon_memsource_install_warning_free')}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {t('global_description')}
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2">{description}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            {descriptionItems.map(item => (
                                                                <li key={item}>{item}</li>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {!isExternal && (
                                                    <Grid item>
                                                        <Grid container direction="column" spacing={1}>
                                                            <Grid item>
                                                                <Typography variant="body1">Price:</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                {priceItems.map(item => (
                                                                    <li key={item}>{item}</li>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AddonLayout>
            <Dialog open={openBuyAddonModal} onClose={handleCloseBuyModal}>
                <ConfirmBox
                    title={`Install ${addon.title} Add-on`}
                    body={
                        <Typography component="div" style={{ marginBottom: theme.spacing(2) }} variant="body2">
                            Are you sure you want to install {addon.title} Add-on?
                        </Typography>
                    }
                    handleCancel={handleCloseBuyModal}
                    onClose={handleCloseBuyModal}
                    handleAgreed={handleBuy}
                    agreeLabel="Confirm"
                    isLoading={isLoading}
                />
            </Dialog>
            <Dialog open={openConfirmUninstallModal} onClose={handleCloseConfirmUninstallModal}>
                <ConfirmBox
                    title={`Uninstall ${addon.title} Add-on`}
                    body={
                        <>
                            <Typography variant="body2">
                                Are you sure you want to uninstall {addon.title} Add-on?
                            </Typography>
                            <Typography variant="body2">Current block counts will be charged and then reset</Typography>
                        </>
                    }
                    handleCancel={handleCloseConfirmUninstallModal}
                    onClose={handleCloseConfirmUninstallModal}
                    handleAgreed={handleRemoveAddon}
                    agreeLabel="Uninstall"
                    isLoading={isLoading}
                />
            </Dialog>
            <Dialog open={isOpenNoCard} onClose={handleCloseNoCard}>
                <ConfirmBox
                    title={'Payment info needed'}
                    body={
                        <Typography variant="body2">
                            Please fill in your payment detail before installing this add-on.
                        </Typography>
                    }
                    handleCancel={handleCloseNoCard}
                    onClose={handleCloseNoCard}
                    handleAgreed={handleNoCard}
                    agreeLabel="Go to Billing Details"
                    agreeWidth={180}
                />
            </Dialog>
            <Dialog open={isOpenContact} onClose={handleCloseContact}>
                <ConfirmBox
                    title={'Add-on is managed by account owner'}
                    body={
                        <Typography variant="body2">
                            {isCurrentOwner
                                ? 'Please contact Gridly Support team to install this add-on for your account.'
                                : 'Please contact your account owner.'}
                        </Typography>
                    }
                    handleCancel={handleCloseContact}
                    onClose={handleCloseContact}
                    action={
                        <Button onClick={handleCloseContact} width={100} variant="contained">
                            {t('global_ok')}
                        </Button>
                    }
                />
            </Dialog>
        </>
    );
}

export default React.memo(AddonDetail);
