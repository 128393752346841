import React from 'react';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import { getTimeFormat, getDateFormat } from 'utils/datetime';
import { isFileAudio, isFileImage, isFileVideo, getFilePreview, isFilePdf } from 'utils/images';
import Option from 'components/option/Base';
import { Grid, Avatar } from '@material-ui/core';
import * as columnTypes from 'const/columnTypes';
import { OPERATOR } from 'gridUI/conditions';
import { isLDEmpty } from 'utils/object';
import { SPECIAL_SPLIT_KEY } from 'const/gridUI';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';
import Checkbox from 'components/checkbox/Base';

function convertNumber(value) {
    if (isNaN(Number(value)) || !value) {
        return Number.MIN_SAFE_INTEGER;
    }
    return Number(value);
}

export function getReferenceOperatorAndValue({ inputValue, columnType }) {
    let operator = OPERATOR.contains;
    let value = inputValue;

    switch (columnType) {
        case columnTypes.NUMBER:
        case columnTypes.DATETIME: {
            operator = OPERATOR.equal;
            value = convertNumber(inputValue);
            break;
        }

        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.TRANSLATION:
        case columnTypes.MARKDOWN:
        case columnTypes.RICH_TEXT:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
            break;
        default:
            break;
    }

    return {
        operator,
        value
    };
}

function _formatNormalRefValue(options) {
    if (options.length === 1) return options[0] && options[0]?.originalLabel;
    return options.map(option => option?.originalLabel).join(SPECIAL_SPLIT_KEY);
}

function _formatFileRefValue(options) {
    if (options.length === 1)
        return options[0] && options[0]?.originalLabel?.map(file => file?.originalName)?.join(SPECIAL_SPLIT_KEY);
    return options
        .map(option => option?.originalLabel?.map(file => file?.originalName)?.join(SPECIAL_SPLIT_KEY))
        .join(SPECIAL_SPLIT_KEY);
}

export function getServerReferenceValueAndOperator({ options, referencedColumnType }) {
    switch (referencedColumnType) {
        case columnTypes.FILES:
            return {
                value: _formatFileRefValue(options),
                operator: OPERATOR.contains
            };

        default:
            return {
                operator: OPERATOR.contains,
                value: _formatNormalRefValue(options)
            };
    }
}

export function formatReferenceOptionByType({ referencedColumnType, options, theme, classes, dbId, t, size = 32 }) {
    const valueOptions = options?.filter(option => !isLDEmpty(option?.label));

    switch (referencedColumnType) {
        case columnTypes.DATETIME: {
            return valueOptions?.map(option => {
                const data = option?.label;
                if (!data || data.length === 0)
                    return {
                        ...option,
                        label: null
                    };
                const [date, time] = formatDateTimeLocal(data);
                return {
                    ...option,
                    originalLabel: option?.label,
                    label: (
                        <div className="flex items-center gap-1 justify-between flex-nowrap">
                            <p className="body2">{getDateFormat(date)}</p>
                            <p variant="body2">{getTimeFormat(time)}</p>
                        </div>
                    )
                };
            });
        }
        case columnTypes.MULTIPLE_SELECTIONS:
            return valueOptions?.map(option => {
                return {
                    ...option,
                    originalLabel: option?.label,
                    label: (
                        <div className="flex gap-1 flex-nowrap truncate">
                            {option?.label?.map(item => (
                                <Option size="small" key={item} label={item} color={theme.colors.dodgerBlue} />
                            ))}
                        </div>
                    )
                };
            });
        case columnTypes.BOOLEAN:
            return valueOptions?.map(option => {
                return {
                    ...option,
                    originalLabel: option?.label,
                    label: <Checkbox checked={option?.label} />
                };
            });
        case columnTypes.FILES:
            return valueOptions?.map(option => {
                return {
                    ...option,
                    originalLabel: option?.label,
                    label: (
                        <Grid container spacing={1} wrap="nowrap">
                            {option?.label?.map(fileMeta => {
                                let isImage = isFileImage(fileMeta);
                                let isAudio = isFileAudio(fileMeta);
                                let isVideo = isFileVideo(fileMeta);
                                let isPdf = isFilePdf(fileMeta);

                                let url = getFilePreview({ dbId, fileId: fileMeta?.thumbnailId });

                                return (
                                    <Grid className={classes.imageItem} item key={fileMeta?.id}>
                                        <Avatar
                                            style={{
                                                height: size,
                                                width: size,
                                                borderRadius: 4,
                                                background: !isImage
                                                    ? isAudio
                                                        ? '#EEF5FB'
                                                        : isVideo
                                                        ? '#FDF0ED'
                                                        : '#F3F2F9'
                                                    : ''
                                            }}
                                            src={url}
                                            alt={fileMeta?.originalName}
                                        >
                                            {!isImage &&
                                                (isAudio ? (
                                                    <AudioThumbnailSVG style={{ width: size, height: size }} />
                                                ) : isVideo ? (
                                                    <VideoThumbnailSVG style={{ width: size, height: size }} />
                                                ) : isPdf ? (
                                                    <PDFFILESVG style={{ width: size, height: size }} />
                                                ) : (
                                                    <OtherFileTypeThumbnailSVG style={{ width: size, height: size }} />
                                                ))}
                                        </Avatar>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )
                };
            });
        default:
            return valueOptions?.map(option => ({
                ...option,
                originalLabel: option?.label
            }));
    }
}

export function isRefImage(options = []) {
    return (
        options instanceof Array &&
        options?.some(option => option.hasOwnProperty('originalName') && option.hasOwnProperty('contentType'))
    );
}

export function getReferenceDataItem(value) {
    return value?.map(item => {
        if (item?.hasOwnProperty('referencedDataItem')) {
            return item?.referencedDataItem;
        }
        if (typeof item === 'string') return item;

        return null;
    });
}
