import i18n from 'i18n';
import { upperFirst } from 'lodash';

export const TB_TERM_STATUS = {
    NEW: 'NEW',
    APPROVED: 'APPROVED'
};

export const tbTermStatusOptions = [
    { value: undefined, label: i18n.t('all_status') },
    ...Object.keys(TB_TERM_STATUS).map(el => ({ value: el, label: upperFirst(el.toLocaleLowerCase()) }))
];

export const TB_TERM_TYPE = {
    ABBREVIATION: 'ABBREVIATION',
    ACRONYM: 'ACRONYM',
    FULL_FORM: 'FULL_FORM',
    PHRASE: 'PHRASE',
    SHORT_FORM: 'SHORT_FORM',
    VARIANT: 'VARIANT'
};

export const tbTermTypeOptions = [
    { value: undefined, label: 'None' },
    ...Object.keys(TB_TERM_TYPE).map(el => ({
        value: el,
        label: upperFirst(
            el
                .split('_')
                .join(' ')
                .toLocaleLowerCase()
        )
    }))
];

export const TB_TERM_PART_OF_SPEECH = {
    ADJECTIVE: 'ADJECTIVE',
    ADVERB: 'ADVERB',
    NOUN: 'NOUN',
    VERB: 'VERB'
};

export const tbTermPartOfSpeechOptions = [
    { value: undefined, label: 'None' },
    ...Object.keys(TB_TERM_PART_OF_SPEECH).map(el => ({
        value: el,
        label: upperFirst(el.toLocaleLowerCase())
    }))
];

export const TB_TERM_GENDER = {
    FEMININE: 'FEMININE',
    MASCULINE: 'MASCULINE',
    NEUTRAL: 'NEUTRAL'
};

export const tbTermGenderOptions = [
    { value: undefined, label: 'None' },
    ...Object.keys(TB_TERM_GENDER).map(el => ({
        value: el,
        label: upperFirst(el.toLocaleLowerCase())
    }))
];

export const TB_TERM_NUMBER = {
    PLURAL: 'PLURAL',
    SINGULAR: 'SINGULAR',
    UNCOUNTABLE: 'UNCOUNTABLE'
};

export const tbTermNumberOptions = [
    { value: undefined, label: 'None' },
    ...Object.keys(TB_TERM_NUMBER).map(el => ({
        value: el,
        label: upperFirst(el.toLocaleLowerCase())
    }))
];

export const TB_TERM_DOMAIN = {
    GAMBLING: 'GAMBLING',
    HORROR: 'HORROR'
};

export const tbTermDomainOptions = [
    { value: undefined, label: 'None' },
    ...Object.keys(TB_TERM_DOMAIN).map(el => ({
        value: el,
        label: upperFirst(el.toLocaleLowerCase())
    }))
];
