import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import Avatar from 'components/avatar/Base';
import * as columnTypes from 'const/columnTypes';
import { isArray } from 'lodash';
import { getFilePreview, isFileAudio, isFileImage, isFilePdf, isFileVideo } from 'utils/images';
import hexToRgba from 'hex-to-rgba';
import { fetchSingleCellData, openCellFilePreview } from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import PlayPause from 'components/circularPercentage/PlayPause';
import PlaySVG from 'assets/images/svg/PlaySVG';
import PauseSVG from 'assets/images/svg/PauseSVG';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import AudioPlay from 'components/audio/Base';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import { getNumberDisplay } from 'utils/gridUI/cell';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';

const useStyles = makeStyles(theme => ({
    root: {},
    zoomIn: {
        cursor: 'zoom-in'
    },
    content: {
        wordBreak: 'break-word',
        cursor: 'context-menu'
    }
}));

const width = 36;
const height = 36;

function AudioThumbnail({ url, style, rowId, columnId, ...rest }) {
    const theme = useTheme();
    // const dispatch = useDispatch();

    const [isPlay, setIsPlay] = React.useState(false);
    const [isPause, setIsPause] = React.useState(true);
    const [isShowPlay, setIsShowPlay] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const handleMouseEnter = () => {
        setIsShowPlay(true);
    };

    const handleMouseLeave = () => {
        setIsShowPlay(false);
    };

    const handleTimeUpdate = e => {
        const duration = e.target.duration;
        const currentTime = e.target.currentTime;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
    };

    const onToggle = e => {
        // cancelAllVideo();
        stopPropagation(e);
        const audioElement = document.getElementById(`tkd_audio_${rowId}_${columnId}_${url}`);
        setIsPlay(true);
        if (audioElement.paused) {
            audioElement.play();
            setIsPause(false);
        } else {
            audioElement.pause();
            setIsPause(true);
        }
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleVideoPlayEnd = () => {
        setIsPlay(false);
        setIsPause(true);
    };

    return (
        <Grid
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                position: 'relative'
            }}
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {(isPlay || isShowPlay) && (
                <>
                    <span
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: hexToRgba(theme.colors.black, 0.5),
                            zIndex: 1
                        }}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={onToggle}
                    >
                        <PlayPause value={isPlay ? progress : 0} icon={isPause ? <PlaySVG /> : <PauseSVG />} />
                    </span>
                </>
            )}
            <AudioThumbnailSVG style={style} />
            <AudioPlay
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleVideoPlayEnd}
                id={`tkd_audio_${rowId}_${columnId}_${url}`}
                src={url}
                style={{ width: 0, height: 0 }}
            />
        </Grid>
    );
}

const TicketDetailContent = ({ dbId, rowId, columnId, setFetchingCellData, column }) => {
    const [cellData, setCellData] = useState();
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        setFetchingCellData(true);
        dispatch(
            fetchSingleCellData({
                columnId,
                rowId,
                successCallback: response => {
                    setCellData(response);
                },
                finallyCallback: () => setFetchingCellData(false)
            })
        );
    }, [dispatch, columnId, rowId, setFetchingCellData]);

    const handleClickOpen = React.useCallback(
        ({ e, fileMeta }) => {
            e.preventDefault();
            e.stopPropagation();
            let body = {
                rowId,
                columnId
            };
            if (fileMeta) {
                body = {
                    ...body,
                    defaultPreviewFile: fileMeta
                };
            }
            dispatch(openCellFilePreview(body));
        },
        [columnId, rowId, dispatch]
    );

    const getContentValue = React.useCallback(
        value => {
            switch (column?.type) {
                case columnTypes.SINGLE_LINE:
                case columnTypes.MULTIPLE_LINES:
                case columnTypes.SINGLE_SELECTION:
                case columnTypes.RECORD_ID:
                case columnTypes.ALTERED_BY:
                case columnTypes.CREATED_BY:
                case columnTypes.HTML:
                case columnTypes.JSON_LD:
                case columnTypes.YAML:
                case columnTypes.TRANSLATION:
                case columnTypes.FORMULA:
                case columnTypes.LOCALIZATION:
                case columnTypes.MARKDOWN:
                case columnTypes.RICH_TEXT:
                    return <p className="body2 cursor-context-menu break-words">{value}</p>;
                case columnTypes.DATETIME:
                case columnTypes.CREATED_TIME:
                case columnTypes.ALTERED_TIME: {
                    if (!value || value?.length === 0) return null;
                    const [date, time] = formatDateTimeLocal(value);
                    return (
                        <Grid container alignItems="flex-start" direction="row" spacing={1}>
                            <Grid item>
                                <p className="body2">{date}</p>
                            </Grid>
                            <Grid item>
                                <p className="body2">{time}</p>
                            </Grid>
                        </Grid>
                    );
                }

                case columnTypes.MULTIPLE_SELECTIONS: {
                    if (!value || value?.length === 0) return null;
                    return <p className="body2 cursor-context-menu break-words">{value?.join(',')}</p>;
                }

                case columnTypes.REFERENCE: {
                    if (column?.referencedColumnType === columnTypes.FILES) {
                        return (
                            <Grid container direction="row" spacing={1} alignItems="center">
                                {value?.map(i => {
                                    const files = i?.referencedDataItem;

                                    return files?.map(fileMeta => {
                                        let isImage = isFileImage(fileMeta);
                                        let isAudio = isFileAudio(fileMeta);
                                        let isVideo = isFileVideo(fileMeta);
                                        let isPdf = isFilePdf(fileMeta);

                                        let url = getFilePreview({
                                            fileId: fileMeta?.thumbnailId,
                                            dbId
                                        });
                                        return (
                                            <Grid item key={fileMeta?.id} style={{ position: 'relative' }}>
                                                <Avatar
                                                    className={`${!isAudio ? classes.zoomIn : ``}`}
                                                    style={{
                                                        lineHeight: `${height}px`,
                                                        height: height,
                                                        width: width,
                                                        borderRadius: 4,
                                                        background: !isImage
                                                            ? isAudio
                                                                ? '#EEF5FB'
                                                                : isVideo
                                                                ? '#FDF0ED'
                                                                : '#F3F2F9'
                                                            : ''
                                                    }}
                                                    src={url}
                                                    alt={fileMeta?.originalName}
                                                    onClick={isAudio ? () => {} : e => handleClickOpen({ e, fileMeta })}
                                                >
                                                    {!isImage &&
                                                        (isAudio ? (
                                                            <AudioThumbnail
                                                                rowId={rowId}
                                                                columnId={columnId}
                                                                style={{ width, height }}
                                                                url={url}
                                                            />
                                                        ) : isVideo ? (
                                                            <VideoThumbnailSVG style={{ width, height }} />
                                                        ) : isPdf ? (
                                                            <PDFFILESVG style={{ width, height }} />
                                                        ) : (
                                                            <OtherFileTypeThumbnailSVG style={{ width, height }} />
                                                        ))}
                                                </Avatar>
                                            </Grid>
                                        );
                                    });
                                })}
                            </Grid>
                        );
                    }

                    return <p className="body2">{value?.map(i => i?.referencedDataItem)?.join(',')}</p>;
                }

                case columnTypes.NUMBER: {
                    let convertedData = '';

                    if (typeof value === 'boolean') {
                        convertedData = value ? 1 : 0;
                    } else if (isNaN(Number(value) || isArray(value))) {
                        return null;
                    } else {
                        if (!value && value + '' !== '0') return null;
                        convertedData = value;
                    }

                    const display = getNumberDisplay({
                        numberFormat: column?.numberFormat,
                        data: convertedData
                    });
                    return <p className="body2">{display}</p>;
                }

                case columnTypes.FILES:
                    return (
                        <Grid container spacing={1}>
                            {isArray(value) &&
                                value.map(fileMeta => {
                                    let isImage = isFileImage(fileMeta);
                                    let isAudio = isFileAudio(fileMeta);
                                    let isVideo = isFileVideo(fileMeta);
                                    let isPdf = isFilePdf(fileMeta);

                                    let url = getFilePreview({
                                        fileId: fileMeta?.thumbnailId,
                                        dbId
                                    });
                                    return (
                                        <Grid item key={fileMeta?.id} style={{ position: 'relative' }}>
                                            <Avatar
                                                className={`${!isAudio ? classes.zoomIn : ``}`}
                                                style={{
                                                    lineHeight: `${height}px`,
                                                    height: height,
                                                    width: width,
                                                    borderRadius: 4,
                                                    background: !isImage
                                                        ? isAudio
                                                            ? '#EEF5FB'
                                                            : isVideo
                                                            ? '#FDF0ED'
                                                            : '#F3F2F9'
                                                        : ''
                                                }}
                                                src={url}
                                                alt={fileMeta?.originalName}
                                                onClick={isAudio ? () => {} : e => handleClickOpen({ e, fileMeta })}
                                            >
                                                {!isImage &&
                                                    (isAudio ? (
                                                        <AudioThumbnail
                                                            rowId={rowId}
                                                            columnId={columnId}
                                                            style={{ width, height }}
                                                            url={url}
                                                        />
                                                    ) : isVideo ? (
                                                        <VideoThumbnailSVG style={{ width, height }} />
                                                    ) : isPdf ? (
                                                        <PDFFILESVG style={{ width, height }} />
                                                    ) : (
                                                        <OtherFileTypeThumbnailSVG style={{ width, height }} />
                                                    ))}
                                            </Avatar>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    );
                default:
                    return null;
            }
        },
        [column, classes, columnId, dbId, handleClickOpen, rowId]
    );

    if (!column) return null;

    return (
        <Grid item>
            <Grid container direction="row">
                <Grid item xs={2}>
                    <p className="caption">Content</p>
                </Grid>
                <Grid item xs={10}>
                    {getContentValue(cellData?.value)}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(TicketDetailContent);
