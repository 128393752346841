import React from 'react';

function HashSymbolSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5793 1.75541C13.6295 1.36236 13.3828 0.982628 12.9893 0.877218C12.5597 0.762181 12.118 1.01716 12.0037 1.44626L11.287 4.12164H6.94898L7.55473 1.86275L7.57592 1.75541C7.6262 1.36236 7.37948 0.982628 6.98592 0.877218L6.8784 0.856228L6.77161 0.849922C6.41815 0.852627 6.09625 1.08884 6.00098 1.44621L5.28298 4.12164L2.96358 4.12253L2.86265 4.12879C2.46593 4.1784 2.15908 4.51662 2.15908 4.92702L2.16535 5.02801L2.18588 5.13387C2.2771 5.47819 2.59066 5.73164 2.96358 5.73164L4.85198 5.73064L3.87798 9.35864L1.65447 9.35884L1.55356 9.3651C1.1569 9.41471 0.849976 9.75297 0.849976 10.1633L0.856244 10.2643L0.876781 10.3701C0.968021 10.7144 1.28164 10.9678 1.65447 10.9678L3.44698 10.9676L2.84159 13.2276L2.8204 13.3349C2.77011 13.728 3.01683 14.1077 3.4104 14.2131C3.47853 14.2314 3.54847 14.2405 3.61807 14.2405L3.72508 14.2334L3.82856 14.2125C4.09878 14.1391 4.31936 13.9272 4.39474 13.6441L5.11198 10.9676H9.44998L8.84493 13.2276L8.82377 13.335C8.77357 13.7281 9.02026 14.1078 9.41387 14.2131C9.48193 14.2314 9.55189 14.2405 9.62213 14.2405L9.72914 14.2334L9.8326 14.2125C10.1028 14.1391 10.3233 13.9272 10.3987 13.6441L11.116 10.9676L13.4361 10.9678L13.537 10.9616C13.9338 10.912 14.2407 10.5737 14.2407 10.1633L14.2344 10.0624L14.2139 9.95653C14.1226 9.61228 13.809 9.35884 13.4361 9.35884L11.548 9.35864L12.52 5.73064L14.7452 5.73164L14.8461 5.72537C15.2429 5.67576 15.5497 5.33751 15.5497 4.92702L15.5434 4.82605L15.5229 4.72021C15.4317 4.37596 15.1181 4.12253 14.7452 4.12253L12.952 4.12164L13.5581 1.86275L13.5793 1.75541ZM6.51598 5.73064H10.855L9.88198 9.35864H5.54398L6.51598 5.73064Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(HashSymbolSVG);
