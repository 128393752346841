import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import { useSeatSubscription } from 'hooks/payment';
import { beautifyPrice } from 'utils/payment';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function InvitationConfirm({ onBack, onConfirm, isSubmitting, inviteSeatQuantity, planCycle }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const seatSubscription = useSeatSubscription();

    return (
        <>
            <DialogTitle title="Add extra seats" onClose={onBack} />
            <DialogContent>
                <Grid item>
                    <Typography variant="body2" display="inline">
                        {t('invitation_confirm_title')}{' '}
                    </Typography>
                    <br />
                    <Typography variant="body1" display="inline">
                        {inviteSeatQuantity} extra seat(s) at{' '}
                        {beautifyPrice({
                            unitAmount: seatSubscription?.price?.unitAmount,
                            currency: seatSubscription?.currency
                        })}
                        /user/{planCycle}{' '}
                    </Typography>
                    <Typography variant="body2" display="inline">
                        will be added to your billing.
                    </Typography>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onBack} disabled={isSubmitting}>
                            {t('global_back')}
                        </Button>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <Button variant="contained" width={120} onClick={onConfirm} disabled={isSubmitting}>
                            {t('global_add')}
                        </Button>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(InvitationConfirm);
