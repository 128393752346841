import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateDatabase } from './actions';
import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SPACING_LIST_NAME_WITH_ICON,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    POPUP_PADDING_TOP_BOTTOM
} from 'const/style';
import Divider from 'components/divider/Base';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import HistorySVG from 'assets/images/svg/HistorySVG';
import ListItem from 'components/list/Item';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import trim from 'lodash/trim';
import { setRightSidebar } from 'app/actions';
import { DATABASE_BACKUP, PLAN_TYPES, SORT_BY_CRITERIA } from 'const';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import { useTranslation } from 'react-i18next';
import SettingGroupSVG from 'assets/images/svg/SettingGroupSVG';
import { useWorkspaceRole } from 'hooks/auth/role';
import NestedMenuItem from 'components/menus/HoverMenu';
import { useSortCriteriaWithWorkspaceDbId } from 'hooks/workspace';
import { updateSortCriteria } from 'workspaces/actions';
import { DatabaseSelectList } from './DatabaseSelect';
import SortFilterSVG from 'assets/images/svg/SortFilterSVG';
import { useCurrentSubscriptionIs } from 'hooks/payment';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260
    },
    spacing: {},
    colorPallet: {
        padding: 12
    },
    color: {
        width: 28,
        height: 28,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    checkIcon: {
        fontSize: 14,
        color: theme.colors.white
    },
    delete: {
        marginTop: 8,
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            fontSize: 20,
            color: theme.colors.midGrey,
            marginRight: SPACING_LIST_NAME_WITH_ICON,
            position: 'relative',
            top: 2
        }
    },
    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        marginBottom: 10
    },
    input: {
        backgroundColor: theme.colors.paleGrey,
        borderRadius: 4,
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                boxShadow: 'none'
            }
        }
    }
}));

function DatabasePopup({
    database,
    workspaceId,
    handleClickAway,
    onDeleteDatabase,
    onDuplicate,
    onShareDatabaseClick,
    isManageDatabaseOrWorkspace
}) {
    const [databaseName, setDatabaseName] = useState(database.name);
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const workspaceRole = useWorkspaceRole();
    const dbSortCriteria = useSortCriteriaWithWorkspaceDbId({ workspaceId, dbId: database.id });
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);

    // const params = useParams();
    // const history = useHistory();

    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    const clickAwayHandler = React.useCallback(() => {
        if (trim(databaseName) === '') {
            handleClickAway();
            return;
        }
        if (databaseName === database.name) {
            handleClickAway();
            return;
        }

        dispatch(
            updateDatabase({
                dbId: database.id,
                newDatabase: {
                    ...database,
                    name: databaseName.trim()
                },
                oldDatabase: database,
                successCallback: () => {
                    console.log('update success');
                },
                errorCallback: error => {
                    console.log('error update');
                }
            })
        );
        handleClickAway();
    }, [database, databaseName, dispatch, handleClickAway]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };

    // const goToDBVersionControl = () => {
    //     history.push(`/projects/${params?.workspaceId}/databases/${database?.id}/branches`);
    // };

    const openDatabaseBackup = e => {
        stopPropagation(e);
        dispatch(setRightSidebar({ type: DATABASE_BACKUP, body: { dbId: database?.id } }));
        handleClickAway();
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleDuplicate = e => {
        stopPropagation(e);
        onDuplicate();
    };

    const isCreator = React.useMemo(() => {
        return workspaceRole === roleConst.CREATOR;
    }, [workspaceRole]);

    const dbOptions = React.useMemo(() => {
        return [
            {
                label: t('sort_by'),
                options: [
                    { value: SORT_BY_CRITERIA.CREATED_DATE, label: t('created_date'), type: 'sort' },
                    // { value: SORT_BY_CRITERIA.LAST_MODIFIED, label: 'Last updated', type: 'sort' },
                    { value: SORT_BY_CRITERIA.ALPHABETICAL, label: t('alphabetical'), type: 'sort' }
                ]
            }
        ];
    }, [t]);

    const orderOptions = React.useMemo(() => {
        if (dbSortCriteria?.field !== SORT_BY_CRITERIA.ALPHABETICAL) {
            return [
                {
                    label: t('order'),
                    options: [
                        { value: 'DESC', label: t('newest_first'), type: 'direction' },
                        { value: 'ASC', label: t('oldest_first'), type: 'direction' }
                    ]
                }
            ];
        }
        return [
            {
                label: t('order'),
                options: [
                    { value: 'ASC', label: 'A - Z', type: 'direction' },
                    { value: 'DESC', label: 'Z - A', type: 'direction' }
                ]
            }
        ];
    }, [dbSortCriteria, t]);

    const options = React.useMemo(() => {
        return [...dbOptions, ...orderOptions];
    }, [dbOptions, orderOptions]);

    const selectedOptions = React.useMemo(() => {
        return Object.values(dbSortCriteria || {});
    }, [dbSortCriteria]);

    const handleChange = React.useCallback(
        option => {
            if (selectedOptions.includes(option.value)) return;
            clickAwayHandler();
            let data = {
                ...dbSortCriteria
            };
            if (option.type === 'sort') {
                if (data.field === SORT_BY_CRITERIA.ALPHABETICAL) {
                    data.direction = 'DESC';
                } else if (option.value === SORT_BY_CRITERIA.ALPHABETICAL) {
                    data.direction = 'ASC';
                }
                data.field = option.value;
            } else {
                data.direction = option.value;
            }
            dispatch(
                updateSortCriteria({
                    workspaceId,
                    dbId: database.id,
                    data,
                    isRemoveNew: !database.id
                })
            );
        },
        [selectedOptions, clickAwayHandler, dbSortCriteria, dispatch, workspaceId, database.id]
    );

    return (
        <Grid ref={rootRef} id={`db_popup`} container direction="column" className={classes.root}>
            {isManageDatabaseOrWorkspace && (
                <>
                    <Grid item className={classes.name}>
                        <InputText
                            id={`dbInput`}
                            name="databaseName"
                            value={databaseName}
                            onChange={e => setDatabaseName(e.target.value)}
                            placeholder={t(`database_name`)}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            className={classes.input}
                            autoSelect={true}
                            inputProps={{
                                maxLength: 255
                            }}
                        />
                    </Grid>
                    {!isFreePlan && (
                        <Grid item onClick={onShareDatabaseClick}>
                            <ListItem
                                icon={<SettingGroupSVG size={16} color={theme.colors.steel} />}
                                name={t('share_to_group')}
                            />
                        </Grid>
                    )}
                    {isCreator && (
                        <Grid item onClick={openDatabaseBackup}>
                            <ListItem icon={<HistorySVG />} name={t('database_dropdown_backup')} />
                        </Grid>
                    )}

                    <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE}>
                        <Grid item onClick={handleDuplicate}>
                            <ListItem icon={<DuplicateSVG color={theme.colors.steel} />} name={t('global_duplicate')} />
                        </Grid>
                    </AccessControl>
                </>
            )}

            <Grid item>
                <NestedMenuItem
                    label={t('sort_by')}
                    parentMenuOpen={true}
                    icon={<SortFilterSVG color="#78778B" />}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    ContainerProps={{
                        onClick: stopPropagation
                    }}
                >
                    <Grid item style={{ width: 210 }}>
                        <DatabaseSelectList
                            selectedOptions={selectedOptions}
                            options={options}
                            handleChange={handleChange}
                        />
                    </Grid>
                </NestedMenuItem>
            </Grid>

            {isManageDatabaseOrWorkspace && (
                <>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item id={`dbDlBtn`} className={`${classes.delete} `} onClick={onDeleteDatabase}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <DeleteSVG />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{t('global_delete')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default React.memo(DatabasePopup);
