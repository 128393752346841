import React, { useState } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { makeStyles } from '@material-ui/core/styles';
import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SPACING_LIST_NAME_WITH_ICON,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    POPUP_PADDING_TOP_BOTTOM
} from 'const/style';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { useDispatch } from 'react-redux';
import * as workspaceActions from './actions';
import cloneDeep from 'lodash/cloneDeep';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import trim from 'lodash/trim';
import { useTranslation } from 'react-i18next';
import Divider from 'components/divider/Base';
import ListItem from 'components/list/Item';
import SettingGroupSVG from 'assets/images/svg/SettingGroupSVG';
import { useCurrentSubscriptionIs } from 'hooks/payment';
import { PLAN_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        width: 200
    },
    projectShare: {
        marginTop: 8,
        marginBottom: 8
    },
    listItem: {
        marginTop: 8,
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            fontSize: 20,
            color: theme.colors.midGrey,
            marginRight: SPACING_LIST_NAME_WITH_ICON,
            position: 'relative',
            top: 2
        }
    },
    disabled: {
        opacity: 0.3
    },
    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        marginBottom: 8
    },
    input: {
        backgroundColor: theme.colors.paleGrey,
        borderRadius: 4,
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                boxShadow: 'none'
            }
        }
    }
}));

function WorkspacePoppup({ workspace, handleClickAway, onDeleteClick, disabledDelete, onShareGroupClick }) {
    const [workspaceName, setWorkspaceName] = useState(workspace.name);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    const clickAwayHandler = () => {
        if (trim(workspaceName) === '') {
            handleClickAway();
            return;
        }
        if (workspaceName === workspace.name) {
            handleClickAway();
            return;
        }

        dispatch(
            workspaceActions.updateWorkspace({
                newWorkspace: {
                    ...workspace,
                    name: workspaceName.trim()
                },
                oldWorkspace: cloneDeep(workspace),
                successCallback: () => {
                    console.log('update project successfully');
                },
                errorCallback: () => {
                    console.log('failed to update project');
                }
            })
        );

        handleClickAway();
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };

    const preventStopPropagation = e => {
        e.stopPropagation();
    };

    return (
        <Grid ref={rootRef} id={`grid_popup`} container direction="column" className={classes.root}>
            <Grid item className={classes.name}>
                <InputText
                    id={`wsInput`}
                    name="workspaceName"
                    value={workspaceName}
                    onChange={e => setWorkspaceName(e.target.value)}
                    placeholder={t('project_name_placeholder')}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    onFocus={preventStopPropagation}
                    onClick={preventStopPropagation}
                    className={classes.input}
                    autoSelect={true}
                    inputProps={{
                        maxLength: 255
                    }}
                />
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            {!isFreePlan && (
                <Grid item className={classes.projectShare} onClick={onShareGroupClick}>
                    <ListItem
                        icon={<SettingGroupSVG size={16} color={theme.colors.steel} />}
                        name={t('share_to_group')}
                    />
                </Grid>
            )}
            <Grid item>
                <Divider />
            </Grid>
            <Grid
                item
                id={`wsDlBtn`}
                className={`${classes.listItem} ${disabledDelete && classes.disabled}`}
                onClick={onDeleteClick}
                tr-dt="Open Delete Workspace Confirm"
            >
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <DeleteSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('global_delete')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(WorkspacePoppup);
