import React, { useCallback, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ButtonBase from 'components/button/Base';
import TimeSelect from 'components/selects/TimeSelect';
import { getStartDateUTC } from 'utils/datetime';
import { AutoSizer } from 'react-virtualized-dn';
import BackupList from './BackupList';
import { sendManualTrack } from 'tracker';
import { useParams } from 'react-router-dom';
import { backupGrid } from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { HEADER_HEIGHT } from 'const';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    header: {
        padding: theme.spacing(0, 4),
        height: 72,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    title: {
        fontSize: 18
    },
    closeIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    backupWrapper: {
        padding: '26px 22px 24px 25px'
    },
    dateFilter: {
        marginTop: 32,
        '& > div': {
            height: '100% !important'
        }
    },
    content: {
        flex: 1
    }
}));

const timeDropDownOptions = [
    { value: 'all', label: i18n.t('global_all'), timestamp: 0 },
    { value: 'today', label: i18n.t('global_today'), timestamp: getStartDateUTC({ offset: 0, unit: 'd' }) },
    { value: 'yesterday', label: i18n.t('global_yesterday'), timestamp: getStartDateUTC({ offset: 1, unit: 'd' }) },
    { value: '7days', label: i18n.t('global_7days'), timestamp: getStartDateUTC({ offset: 7, unit: 'd' }) },
    { value: '15days', label: i18n.t('global_15days'), timestamp: getStartDateUTC({ offset: 15, unit: 'd' }) },
    { value: '30days', label: i18n.t('global_30days'), timestamp: getStartDateUTC({ offset: 30, unit: 'd' }) }
];

const GridBackup = ({ handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { dbId, branchId } = useParams();
    const [selected, setSelected] = useState(timeDropDownOptions[0]);

    const handleOptionChange = useCallback(
        (e, option) => {
            if (selected.value !== option.value) {
                setSelected(option);
            }
        },
        [selected]
    );

    const handleBackupGrid = useCallback(() => {
        sendManualTrack({ type: `Back Up Grid` });
        dispatch(
            backupGrid({
                dbId,
                gridId: branchId,
                successCallback: () => {
                    console.log('backupGrid success');
                },
                errorCallback: () => {
                    console.log('backupGrid failed');
                }
            })
        );
    }, [dbId, dispatch, branchId]);

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item>
                <Grid container className={classes.header} direction="row" alignItems="center" justify="space-between">
                    <Grid item>
                        <h4 className="prose prose-lg font-medium">{t(`right_toolbar_backup_tooltip`)}</h4>
                    </Grid>
                    <Grid item style={{ display: 'flex', cursor: 'pointer' }}>
                        <IconButton size="small" onClick={handleClose}>
                            <CloseIconSVG className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.backupWrapper}>
                <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <h4 className="prose prose-lg font-medium">{t('back_up_history')}</h4>
                    </Grid>
                    <Grid item>
                        <ButtonBase variant="contained" onClick={handleBackupGrid}>
                            {t('back_up')}
                        </ButtonBase>
                    </Grid>
                </Grid>
                <Grid item className={classes.dateFilter}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <TimeSelect
                                    width={width}
                                    options={timeDropDownOptions}
                                    selected={selected}
                                    onChange={handleOptionChange}
                                />
                            );
                        }}
                    </AutoSizer>
                </Grid>
            </Grid>
            <Grid item className={classes.content}>
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <Grid
                                id="grid-backup-list"
                                item
                                style={{ width, height: height - HEADER_HEIGHT, overflow: 'hidden auto' }}
                            >
                                <BackupList time={selected} />
                            </Grid>
                        );
                    }}
                </AutoSizer>
            </Grid>
        </Grid>
    );
};

export default React.memo(GridBackup);
