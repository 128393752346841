import React from 'react';

function RemoveTagSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.930344 0L4 3.06966L7.06966 0H7.20862L8 0.791379V0.930344L4.93034 4L8 7.06966V7.20862L7.20862 8H7.06966L4 4.93034L0.930344 8H0.791379L0 7.20862V7.06966L3.06966 4L0 0.930344V0.791379L0.791379 0H0.930344Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(RemoveTagSVG);
