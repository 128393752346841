import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Collapse } from '@material-ui/core';
import ListSelect from 'components/list/Select';
import ButtonBase from 'components/button/Base';
import CountryFlag from 'components/svg-icon/CountryFlag';
import Checkbox from 'components/checkbox/Base';
import { useViewColumnsWithUserLanguageViewsAndMetadata } from 'hooks/gridUI/column';
import { useDispatch } from 'react-redux';
import * as exportActions from 'gridUI/actions/export';
import { sendManualTrack } from 'tracker';
import { useDependencies } from 'hooks/gridUI';
import { isTempId } from 'utils/uuid';
import * as columnTypes from 'const/columnTypes';
import { ColumnTypeSelectListDisplay } from '../../ColumnTypeDisplay';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {
        width: 780,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    targetList: {
        maxHeight: 300,
        minHeight: 46,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    footer: {},
    steel: {
        color: theme.colors.steel
    },
    unsetText: {
        color: hexToRgba(theme.colors.brightRed, 0.8)
    },
    listItem: {
        boxSizing: 'border-box',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
    }
}));

function ExportLocalization({ onClose, showTitle = true, customTargetList }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const dependencies = useDependencies();
    const dependenciesWithoutFakeIds = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const dependencyColumnIds = React.useMemo(() => {
        const childColumnIds = dependenciesWithoutFakeIds?.map(dpDc => dpDc?.child);
        return [...new Set([...childColumnIds])];
    }, [dependenciesWithoutFakeIds]);

    const dependencyColumnOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isDependency = dependencyColumnIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isDependency && columnType === columnTypes.TRANSLATION;
            })
            ?.map(lang => ({
                ...lang,
                value: lang?.id,
                group: lang?.group,
                label: lang?.name,
                icon: () => <CountryFlag languageCountryCode={lang.group} />,
                isSelected: false
            }));
    }, [viewColumns, dependencyColumnIds]);

    const [selectedColumns, setSelectedColumns] = React.useState([]);

    const handleColumnSelect = option => {
        const found = selectedColumns.some(column => column.value === option.value);
        if (found) {
            const newSelectedColumns = selectedColumns.filter(column => column.value !== option.value);
            setSelectedColumns(newSelectedColumns);
            return;
        }
        setSelectedColumns([...selectedColumns, option]);
    };
    const isAllColumnsSelected = dependencyColumnOptions.length === selectedColumns.length;
    const handleTargetSelectAll = () => setSelectedColumns(isAllColumnsSelected ? [] : dependencyColumnOptions);

    const isValid = selectedColumns.length > 0;

    const handleExportJson = () => {
        sendManualTrack({ type: `Localization Export Json File` });
        setIsSubmitting(true);
        dispatch(
            exportActions.exportTranslation({
                query: {
                    columnIds: selectedColumns.map(column => column.id),
                    type: 'po'
                },
                successCallback: () => {
                    console.log('export LD success');
                    setIsSubmitting(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    console.log('export LD error');
                    setIsSubmitting(false);
                    onClose && onClose();
                }
            })
        );
    };

    return (
        <>
            {showTitle && <DialogTitle title={t(`export_po_localization`)} onClose={onClose} />}
            <DialogContent>
                <Grid container spacing={2} direction="column" wrap="nowrap" className={classes.root}>
                    <Grid item container justify="space-between" alignItems="center">
                        <Grid item>
                            <p className="body1">Select language to export</p>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center">
                                <Grid item />
                                <Grid item>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item>
                                            <Checkbox checked={isAllColumnsSelected} onChange={handleTargetSelectAll} />
                                        </Grid>
                                        <Grid item>
                                            <p className="body2 inline">{t('select_all_languages')}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={`${classes.targetList} ${customTargetList}`}>
                        {dependencyColumnOptions.length === 0 && (
                            <p className="body2 text-steel">{t(`export_localization_explain`)}</p>
                        )}
                        <Collapse in={dependencyColumnOptions.length > 0}>
                            <Grid container alignItems="center">
                                {dependencyColumnOptions?.map(option => {
                                    const isSelected = selectedColumns.some(column => column.value === option.value);
                                    return (
                                        <Grid
                                            className={classes.listItem}
                                            onClick={e => handleColumnSelect(option)}
                                            item
                                            xs={4}
                                            key={option.value}
                                        >
                                            <ListSelect
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={<ColumnTypeSelectListDisplay {...option} />}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Collapse>
                    </Grid>
                    <Grid item>
                        <p className="body1 inline">Note: </p>
                        <p className="body2 inline">if languages contains string that are </p>
                        <p className="body1 inline text-error opacity-80">unset </p>
                        <p className="body2 inline">
                            , these strings will be replaced by source strings when exporting.
                        </p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid className={classes.footer} item container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Grid container spacing={2} direction="row" wrap="nowrap">
                            <Grid item>
                                <ButtonBase variant="outlined" width={120} onClick={onClose}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    width={120}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    onClick={handleExportJson}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            style={{
                                                color: 'white'
                                            }}
                                            size={20}
                                        />
                                    ) : (
                                        t('global_export')
                                    )}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(ExportLocalization);
