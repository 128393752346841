import request from './request';
import { encodeURL } from 'utils/http';

import { APP_API_PREFIX_GRID_UI, APP_API_PREFIX_ORGANIZATION, APP_API_PREFIX_DATABASE_SERVICE } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';
const MAX_DATABASE_SIZE = 500;

export const dbApis = {
    orgDatabases: ({ companyId, workspaceId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces/${workspaceId}/databases`,
    databases: `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases`,
    database: ({ dbId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}`,
    databaseBackupFiles: ({ dbId, gridId }) =>
        gridId
            ? `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/grids/${gridId}/backup-files`
            : `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/backup-files`,
    backupOrRestoreDatabase: ({ dbId, gridId }) =>
        gridId
            ? `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/grids/${gridId}/tasks`
            : `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/tasks`,
    databaseBackupOrRestoreStatus: ({ dbId, taskId }) =>
        `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/tasks/${taskId}`,
    backupSchuduleDatabase: ({ dbId }) => `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/schedule-backup`,
    duplicateDatabase: ({ dbId, workspaceId, companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces/${workspaceId}/databases/${dbId}/duplicate`,
    graphql: () => `${APP_API_PREFIX_ORGANIZATION}/graphql`,
    shareDatabase: ({ companyId, dbId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/databases/${dbId}/groups`
};

export async function getDatabasesApi({ workspaceId, companyId }) {
    const params = {
        offset: 0,
        limit: MAX_DATABASE_SIZE
    };
    const pageParams = encodeURL(params);
    const sort = { createdTime: 'desc' };
    const sortParams = encodeURL(sort);

    let extraUrl = `?page=${pageParams}&sort=${sortParams}&expand=true`;
    const response = await request.get(`${dbApis.orgDatabases({ workspaceId, companyId })}${extraUrl}`, {
        headers: { regional: true }
    });
    return response.data;
}

export async function getDatabaseApi(dbId) {
    const response = await request.get(`${dbApis.databases}/${dbId}`);
    return response.data;
}

export async function createDatabaseApi({ database, companyId, workspaceId }) {
    const response = await request.post(dbApis.orgDatabases({ companyId, workspaceId }), database, {
        headers: { regional: true }
    });
    return response.data;
}

export async function createScheduleBackupDatabaseApi({ dbId, body }) {
    const response = await request.post(dbApis.backupSchuduleDatabase({ dbId }), body);
    return response.data;
}

export async function getScheduleBackupDatabaseApi({ dbId }) {
    const response = await request.get(dbApis.backupSchuduleDatabase({ dbId }));
    return response.data;
}

export async function deleteDatabaseApi({ dbId }) {
    const response = await request.delete(dbApis.database({ dbId }));
    return response.data;
}

export async function updateDatabaseApi({ dbId, newDatabase }) {
    const response = await request.put(dbApis.database({ dbId }), newDatabase);
    return response.data;
}

export async function getDatabaseBackupFilesApi({ dbId, gridId, offset, limit, options }) {
    const query = objectToQueryParams({
        page: { offset, limit },
        sort: { alteredTime: 'desc' },
        ...options
    });
    let extraUrl = `?${query}`;
    const response = await request.get(`${dbApis.databaseBackupFiles({ dbId, gridId })}${extraUrl}`);
    return { files: response.data, total: response.headers['x-total-count'] };
}

export async function backupDatabaseApi({ dbId, gridId }) {
    const body = { type: 'backup' };
    const response = await request.post(dbApis.backupOrRestoreDatabase({ dbId, gridId }), body);
    return response.data;
}

export async function restoreDatabaseApi({ dbId, gridId, backupId }) {
    const body = { backupId, type: 'restore', restoreOption: 'file' };
    const response = await request.post(dbApis.backupOrRestoreDatabase({ dbId, gridId }), body);
    return response.data;
}

export async function getDatabaseBackupStatusApi({ dbId, taskId }) {
    const response = await request.get(dbApis.databaseBackupOrRestoreStatus({ dbId, taskId, type: 'backup' }));
    return response.data;
}

export async function getDatabaseRestoreStatusApi({ dbId, taskId }) {
    const response = await request.get(dbApis.databaseBackupOrRestoreStatus({ dbId, taskId, type: 'restore' }));
    return response.data;
}

export async function duplicateDatabaseApi({ dbId, companyId, workspaceId, body }) {
    const response = await request.post(dbApis.duplicateDatabase({ dbId, companyId, workspaceId }), body, {
        headers: { regional: true }
    });
    return response.data;
}

export async function getSharedGroupsOfDatabaseApi({ companyId, dbId }) {
    const response = await request.get(dbApis.shareDatabase({ companyId, dbId }));
    return response.data;
}

export async function shareDatabaseApi({ companyId, dbId, groupIds }) {
    const response = await request.post(dbApis.shareDatabase({ companyId, dbId }), { groupIds });
    return response.data;
}

export async function unshareDatabaseApi({ companyId, dbId, groupIds }) {
    const response = await request.delete(dbApis.shareDatabase({ companyId, dbId }), { data: { groupIds } });
    return response.data;
}
