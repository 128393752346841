import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { POPUP_PADDING_TOP_BOTTOM } from 'const/style';

const useStyles = makeStyles(theme => ({
    arrow: {
        color: theme.colors.white
    },
    tooltip: {
        backgroundColor: theme.colors.white,
        zIndex: 99999,
        fontSize: 13,
        maxWidth: 500,
        padding: `${POPUP_PADDING_TOP_BOTTOM}px 0`,
        boxShadow: theme.shadows[1]
    },
    tooltipPlacementBottom: {
        marginTop: 5
    },
    popper: {
        pointerEvents: 'auto'
    }
}));

function ToolTipAutoQAClick({ className = '', children, placement = 'bottom', ...rest }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        console.log('fuc');
        if (rest?.title) {
            setOpen(true);
        }
    };

    return (
        <span className={className} onMouseOver={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
            <Tooltip placement={placement} onClose={handleTooltipClose} open={open} classes={classes} {...rest}>
                <span>{children}</span>
            </Tooltip>
        </span>
    );
}

export default React.memo(ToolTipAutoQAClick);
