import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { findChildrenByPath, findTagByPath, getPath, findTagByPathId } from 'utils/gridUI/pathTag';
import ListItem from 'components/list/Item';
import CloseFolderSVG from 'assets/images/svg/CloseFolderSVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ButtonBase from 'components/button/Base';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import isNumber from 'lodash/isNumber';
import { DEFAULT_PATH_TAG_COLOR } from 'utils/color';
import { useTranslation } from 'react-i18next';
import { usePathTagTree } from 'hooks/gridUI';
import { List, AutoSizer } from 'react-virtualized-dn';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width || 368
    },
    header: {
        padding: `8px 20px`,
        borderBottom: `1px solid ${theme.colors.border}`,
        minHeight: 40
    },
    body: {
        height: 150,
        minHeight: 150,
        overflowY: 'auto'
    },
    footer: {
        padding: `8px 20px`,
        borderTop: `1px solid ${theme.colors.border}`
    },
    noPath: {
        padding: `8px 20px`
    },
    arrowBack: {
        cursor: 'pointer',
        marginRight: 5
    },
    path: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    indexSelected: {
        color: theme.palette.primary.main
    }
}));

function getSelectedChildId({ childNodes = [], selectedNodeIds = [] }) {
    let el;
    for (const child of childNodes) {
        if (selectedNodeIds?.includes(child?.id)) {
            el = child;
            break;
        }
    }
    return el;
}

function FolderPath({ defaultPath, onChange, handleClickAway, width, ...rest }) {
    const classes = useStyles({ width });
    const { t } = useTranslation();
    const [currentPath, setCurrentPath] = React.useState();
    const [childNodes, setChildNodes] = React.useState([]);
    const [selectedNodeIds, setSelectedNodeIds] = React.useState([]);
    const [highlightIndex, setHighlightIndex] = React.useState();
    const rootRef = React.useRef();
    const tree = usePathTagTree();

    useClickAwaitListener(rootRef, e => {
        handleClickAway && handleClickAway(e);
    });

    const headerTitleArr = React.useMemo(() => {
        return `Root ${!currentPath ? '' : `/${currentPath}`}`.split('/');
    }, [currentPath]);

    React.useEffect(() => {
        if (defaultPath) {
            const pathArr = defaultPath?.split('/');
            const nodes = findTagByPath({ tree, path: defaultPath });
            if (nodes?.length) {
                setSelectedNodeIds(nodes?.map(node => node?.id));
            }
            setCurrentPath(pathArr?.slice(0, pathArr?.length - 1)?.join('/'));
        }
    }, [defaultPath, tree]);

    React.useEffect(() => {
        const children = findChildrenByPath({ tree, path: currentPath });
        setChildNodes(children);
    }, [currentPath, tree]);

    React.useEffect(() => {
        if (isNumber(highlightIndex)) {
            let path = highlightIndex === 0 ? '' : headerTitleArr?.slice(1, highlightIndex + 1)?.join('/');
            const children = findChildrenByPath({ tree, path });
            setChildNodes(children);
        }
    }, [highlightIndex, tree, headerTitleArr]);

    const handleSelectedNode = React.useCallback(
        node => {
            const selectedChild = getSelectedChildId({ childNodes, selectedNodeIds });

            setHighlightIndex();

            if (selectedChild?.id) {
                const path = getPath({ tree, nodeId: node?.id });
                if (!(!node?.children || node?.children?.length === 0)) {
                    setCurrentPath(path?.pathName);
                    if (selectedChild?.id === node?.id) {
                        setChildNodes(node?.children);
                    }
                } else {
                    const hehe = path?.pathArrIdAndName
                        ?.slice(0, path?.pathArrIdAndName?.length - 1)
                        ?.map(node => node?.name)
                        ?.join('/');
                    setCurrentPath(hehe);
                }
                setSelectedNodeIds(path?.pathArrIdAndName?.map(node => node?.id));
            } else {
                const newPath = !currentPath ? node?.name : `${currentPath}/${node.name}`;

                if (!(!node?.children || node?.children?.length === 0)) {
                    setCurrentPath(newPath);
                }
                if (!selectedNodeIds?.includes(node?.id)) {
                    const nodes = findTagByPath({ tree, path: newPath });
                    setSelectedNodeIds(nodes?.map(node => node?.id));
                }
            }
        },
        [childNodes, currentPath, tree, selectedNodeIds]
    );

    const handlePathClick = React.useCallback(index => {
        setHighlightIndex(index);
    }, []);

    const handleOnSave = React.useCallback(() => {
        if (selectedNodeIds?.length === 0) {
            return;
        }
        const fullPathId = selectedNodeIds?.join('/');
        const nodes = findTagByPathId({ tree, fullPathId });
        const path = nodes?.map(node => node?.name)?.join('/');
        return onChange && onChange(path, fullPathId);
    }, [onChange, tree, selectedNodeIds]);

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style }) => {
            const node = childNodes?.[index];
            return (
                <Tooltip placement="right" title={node?.name} key={key}>
                    <div style={style}>
                        <ListItem
                            // onDoubleClick={() => handleDoubleClick(node)}
                            onClick={() => handleSelectedNode(node)}
                            isSelected={selectedNodeIds?.includes(node?.id)}
                            name={node?.name}
                            moreIcon={node?.children?.length ? <ArrowRightSVG /> : undefined}
                            icon={
                                <CloseFolderSVG
                                    color={
                                        node?.customProperties?.color
                                            ? node?.customProperties?.color
                                            : DEFAULT_PATH_TAG_COLOR
                                    }
                                />
                            }
                        />
                    </div>
                </Tooltip>
            );
        },
        [childNodes, handleSelectedNode, selectedNodeIds]
    );

    return (
        <Grid ref={rootRef} container className={classes.root} direction="column" {...rest}>
            <Grid item container alignItems="center" className={classes.header}>
                {headerTitleArr?.length === 0 && <Typography variant="caption">{t(`path_tag_select_tag`)}</Typography>}
                {headerTitleArr?.length !== 0 && (
                    <Typography component="div" display="inline" variant="body2">
                        {headerTitleArr?.map((item, index) => {
                            const isLastItem = index === headerTitleArr?.length - 1;
                            return (
                                <React.Fragment key={item}>
                                    <Typography
                                        onClick={() => (isLastItem ? () => {} : handlePathClick(index))}
                                        className={`${!isLastItem ? classes.path : ''} ${
                                            index === highlightIndex ? classes.indexSelected : ''
                                        }`}
                                        display="inline"
                                        variant="body2"
                                    >
                                        {item}
                                    </Typography>
                                    {!isLastItem && (
                                        <Typography
                                            style={{ paddingLeft: 2, paddingRight: 2 }}
                                            display="inline"
                                            variant="body2"
                                            key={item}
                                        >
                                            /
                                        </Typography>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Typography>
                )}
            </Grid>
            <Grid item className={classes.body}>
                {childNodes?.length === 0 && (
                    <Typography className={classes.noPath} variant="caption">
                        {t(`path_tag_no_path`)}
                    </Typography>
                )}
                {childNodes?.length !== 0 && (
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <List
                                    className={classes.virtualizeList}
                                    rowCount={childNodes?.length}
                                    rowHeight={36}
                                    width={width}
                                    height={height}
                                    rowRenderer={_rowRenderer}
                                />
                            );
                        }}
                    </AutoSizer>
                )}
            </Grid>
            <Grid item container spacing={2} justify="flex-end" className={classes.footer}>
                <Grid item>
                    <ButtonBase onClick={handleClickAway} width={80} variant="outlined">
                        {t(`global_cancel`)}
                    </ButtonBase>
                </Grid>
                <Grid item>
                    <ButtonBase
                        disabled={selectedNodeIds?.length === 0}
                        onClick={handleOnSave}
                        width={80}
                        variant="contained"
                    >
                        {t(`global_apply`)}
                    </ButtonBase>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(FolderPath);
