import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import NormalSelect from 'gridUI/common/normalSelects';
import { useSelector } from 'react-redux';
import { getGridColorValue } from 'utils/color';
import GridSVG from 'assets/images/svg/GridSVG';
import { getGridColumnsApi } from 'services/grid';

function ExtraOptionsWithTranslation({ tmData, tmDataChangeHandler, t }) {
    const dbId = useSelector(state => state.gridUI.dbId);
    const grids = useSelector(state => {
        let cols = state.gridUI.grids || [];
        return cols.map(col => {
            let color = getGridColorValue(col, 'color');
            return {
                label: col.name,
                value: col.id,
                icon: () => <GridSVG color={color} />
            };
        });
    });

    const [selectedGrid, setSelectedGrid] = useState(null);

    useEffect(() => {
        const { gridTMId } = tmData;
        async function checked() {
            if (gridTMId) {
                let selectedCol = grids.find(col => col.value === gridTMId);
                setSelectedGrid(selectedCol);
            } else if (grids.length > 0) {
                let defaultTMCol = grids[0];
                setSelectedGrid(defaultTMCol);
                let columns = await getGridColumnsApi({ gridId: defaultTMCol.value, dbId });
                let firstColumn = columns && columns[0];
                sendBackParent({
                    gridTMId: defaultTMCol.value,
                    columnTMId: firstColumn.id
                });
            }
        }
        checked();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGridChange = async gridOption => {
        if (gridOption.value === (selectedGrid && selectedGrid.value)) return;
        setSelectedGrid(gridOption);
        let gridId = gridOption.value;
        let columns = await getGridColumnsApi({ gridId: gridId, dbId });
        let firstColumn = columns && columns[0];
        sendBackParent({
            gridTMId: gridOption.value,
            columnTMId: firstColumn && firstColumn.id
        });
    };

    const sendBackParent = ({ gridTMId, columnTMId }) => {
        tmDataChangeHandler({
            gridTMId,
            columnTMId
        });
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item container direction="column">
                <Grid item>
                    <p className="body1 mb-1">{t('global_grid')}</p>
                </Grid>
                <Grid item>
                    <NormalSelect
                        defaultValue={selectedGrid}
                        options={grids}
                        handleOptionChange={handleGridChange}
                        ddPlaceholder={t('select_a_grid')}
                        menuPlaceholder="Find a grid"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ExtraOptionsWithTranslation);
