import React from 'react';

function SlackSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip_slack)">
                <path
                    d="M6.63293 9.48828C5.58894 9.48828 4.74219 10.335 4.74219 11.379V16.1085C4.74219 17.1525 5.58894 17.9993 6.63293 17.9993C7.67693 17.9993 8.52368 17.1525 8.52368 16.1085V11.379C8.52293 10.335 7.67618 9.48828 6.63293 9.48828Z"
                    fill="#E91E63"
                />
                <path
                    d="M0.0136719 11.3791C0.0136719 12.4238 0.861172 13.2713 1.90592 13.2713C2.95067 13.2713 3.79817 12.4238 3.79817 11.3791V9.48682H1.90742C1.90667 9.48682 1.90667 9.48682 1.90592 9.48682C0.861172 9.48682 0.0136719 10.3343 0.0136719 11.3791Z"
                    fill="#E91E63"
                />
                <path
                    d="M6.63473 -0.000732422C6.63398 -0.000732422 6.63323 -0.000732422 6.63248 -0.000732422C5.58773 -0.000732422 4.74023 0.846767 4.74023 1.89152C4.74023 2.93627 5.58773 3.78377 6.63248 3.78377H8.52323V1.89152C8.52323 1.89077 8.52323 1.88927 8.52323 1.88777C8.52248 0.844517 7.67723 -0.000732422 6.63473 -0.000732422Z"
                    fill="#00BCD4"
                />
                <path
                    d="M1.8942 8.52742H6.6327C7.67745 8.52742 8.52495 7.67992 8.52495 6.63517C8.52495 5.59042 7.67745 4.74292 6.6327 4.74292H1.8942C0.849453 4.74292 0.00195312 5.59042 0.00195312 6.63517C0.00195312 7.67992 0.849453 8.52742 1.8942 8.52742Z"
                    fill="#00BCD4"
                />
                <path
                    d="M16.0936 4.74219C15.0503 4.74219 14.2051 5.58744 14.2051 6.63069V6.63444V8.52669H16.0958C17.1406 8.52669 17.9881 7.67919 17.9881 6.63444C17.9881 5.58969 17.1406 4.74219 16.0958 4.74219C16.0951 4.74219 16.0943 4.74219 16.0936 4.74219Z"
                    fill="#4CAF50"
                />
                <path
                    d="M9.48047 1.89148V6.63523C9.48047 7.67924 10.3272 8.52599 11.3712 8.52599C12.4152 8.52599 13.262 7.67924 13.262 6.63523V1.89148C13.262 0.847483 12.4152 0.000732422 11.3712 0.000732422C10.3272 0.000732422 9.48047 0.847483 9.48047 1.89148Z"
                    fill="#4CAF50"
                />
                <path
                    d="M13.262 16.1071C13.262 15.0631 12.4152 14.2163 11.3712 14.2163H9.48047V16.1086C9.48122 17.1518 10.3272 17.9978 11.3712 17.9978C12.4152 17.9978 13.262 17.1511 13.262 16.1071Z"
                    fill="#FF9800"
                />
                <path
                    d="M16.1093 9.48682H11.3708C10.326 9.48682 9.47852 10.3343 9.47852 11.3791C9.47852 12.4238 10.326 13.2713 11.3708 13.2713H16.1093C17.154 13.2713 18.0015 12.4238 18.0015 11.3791C18.0015 10.3343 17.154 9.48682 16.1093 9.48682Z"
                    fill="#FF9800"
                />
            </g>
            <defs>
                <clipPath id="clip_slack">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(SlackSVG);
