import React from 'react';
import { AutoSizer } from 'react-virtualized-dn';
import GridUITable from 'gridUI/table/grid/GridUIViewOnly';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    ROW_HEIGHT,
    RECORDS_RENDER_GRID_PREVIEW,
    TASK_STATUS,
    CONFLICT_TYPES,
    AGG_HEIGHT,
    FAKE_ROW,
    ROW_HEIGHT_OPTIONS
} from 'const/gridUI';
import { generatePreviewImportApi, getImportDiffCheckApi, getPreviewImportAggregationApi } from 'services/view';
import { Grid, CircularProgress, Popper, Paper, Divider, IconButton } from '@material-ui/core';
import Button from 'components/button/Base';
import {
    useMetaData,
    useViewColumnIdsWithReOrder,
    useViewImportFile,
    useViewImportFileContent,
    useExportRemovedColumns,
    useViewImportResourceKey,
    useTaskStatus,
    usePathTagRef,
    usePathTagTree,
    useCurrentGrid
} from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import { isLDEmpty, removeArrayInArray } from 'utils/object';
import IOSSwitch from 'components/switches/IOS';
import ViewsSwitch from 'components/switches/ViewsSwitch';
import ImportHeader from './ImportHeader';
import ColumnMapList from './ColumnMapList';
import { ColumnIcon } from '../ColumnTypeDisplay';
import {
    SYSTEM_COLUMN_IDS,
    PATH_TAG_ID,
    UPLOAD_SETUP_VIEW_TYPES,
    IMPORT_VALID_COLUMN_TYPES,
    IMPORT_INSIDE_WARNING_COLUMN_TYPES,
    PATH_TAG_MATCHES_ID,
    RECORD_IDS_MATCHES
} from 'const';
import { sendManualTrack } from 'tracker';
import WarningSVG from 'assets/images/svg/WarningSVG';
import FileSVG from 'assets/images/svg/FileSVG';
import * as columnTypes from 'const/columnTypes';
import RecordIdSVG from 'components/svg-icon/RecordIdSVG';
import hexToRgba from 'hex-to-rgba';
import Select from 'components/selects/Select';
import Spinner from 'components/spinner/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation, Trans } from 'react-i18next';
import { formatReviewData } from 'utils/gridUI/data';
import { getFuzzyByLanguage, SUPPORTED_LANGUAGES } from 'const/languageData';
import OrganizationSetttingSVG from 'assets/images/svg/OrganizationSetttingSVG';
import ArrowBackSVG from 'assets/images/svg/ArrowBackSVG';
import ImportSVG from 'assets/images/svg/ImportSVG';
import GridSVG from 'assets/images/svg/GridSVG';
import SwapSVG from 'assets/images/svg/SwapSVG';
import AddRecordSVG from 'assets/images/svg/versionControl/AddRecordSVG';
import UpdateRecordSVG from 'assets/images/svg/versionControl/UpdateRecordSVG';
import Checkbox from 'components/checkbox/Base';
import NoDiffCheck from 'assets/images/svg/versionControl/NoDiffCheck';
import { formatDataGridPreview } from 'utils/gridUI/formatData';
import produce from 'immer';
import MergingGrid from 'gridUI/branch/diffCheck/MergingGrid';
import * as roleConst from 'auth/roleConst';
import { useContextMenuId } from 'hooks/app';
import { DEFAULT_TIME_ZONE } from 'const/time';
import { formatQuickFilters } from 'utils/gridUI/filter';
import ShowAllConflictsSVG from 'assets/images/svg/ShowAllConflictsSVG';
import Tooltip from 'components/tooltip/Base';
import FolderPath from 'components/folderPath/Base';
import { getNodesByPath } from 'utils/gridUI/pathTag';
import { DEFAULT_PATH_TAG_COLOR } from 'utils/color';
import Option from 'components/option/Base';
import isEmpty from 'lodash/isEmpty';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { INPUT_HEIGHT } from 'const/style';
import Dialog from 'components/dialog/Dialog';
import CustomRowHeight from 'gridUI/CustomRowHeight';
import RowHeightSVG from 'assets/images/svg/RowHeightSVG';
import ShortSVG from 'assets/images/svg/ShortSVG';
import CustomSVG from 'assets/images/svg/CustomSVG';
import TallSVG from 'assets/images/svg/TallSVG';
import MediumSVG from 'assets/images/svg/MediumSVG';
import ListItem from 'components/list/Item';
import PopperMenu from 'components/menus/Popper';

const PREVIEW_TABLE_HEIGHT = 252;

const useStyles = makeStyles(theme => ({
    root: {
        width: 800,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    advanced: {
        width: `calc(100vw - 240px)`,
        minHeight: 560,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    table: {
        height: PREVIEW_TABLE_HEIGHT,
        overflow: 'hidden',
        userSelect: 'none',
        '& .cell': {
            pointerEvents: 'none'
        },
        position: 'relative',
        borderRight: `1px solid ${theme.colors.divider}`,
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    listView: {
        position: 'relative',
        height: PREVIEW_TABLE_HEIGHT,
        overflow: 'auto'
    },
    dropdown: {
        minWidth: 340
    },
    switch: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'normal'
    },
    switchIcon: {
        marginRight: 10
    },
    inkBox: {
        background: theme.colors.paleGrey,
        padding: `6px 8px`,
        borderRadius: 4
    },
    innerColSpacing: {
        marginBottom: theme.spacing(2)
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    fullWidth: {
        width: '100%'
    },
    radio: {
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    recordIdWarning: {
        borderRadius: 10,
        margin: '0px 4px',
        padding: `0px 9px`,
        position: 'relative',
        top: 4,
        background: theme.colors.paleNavy,
        color: theme.colors.dimGrey,
        fontWeight: 500,
        border: `1px solid ${theme.colors.solitude}`,
        display: 'inline-flex',
        alignItems: 'center'
    },
    recordId: {
        marginRight: theme.spacing(1)
    },
    dlFlex: {
        display: 'flex'
    },
    importOptions: {
        background: theme.colors.ghostwhite,
        padding: theme.spacing(4),
        borderRadius: 4
    },
    showMoreOption: {
        cursor: 'pointer'
    },
    title: {
        minWidth: 140
    },
    blue80: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    delimiter: {
        minWidth: 340
    },
    rowSpacing: {
        marginRight: theme.spacing(3)
    },
    columnInnerSpacing: {
        marginBottom: theme.spacing(2)
    },
    columnSpacing: {
        marginBottom: theme.spacing(3)
    },
    wrapperLoading: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
        background: hexToRgba(theme.colors.white, 0.8)
    },
    dropdownClassName: {
        background: theme.palette.common.white,
        '& .focused': {
            border: `1px solid ${theme.colors.lightLavender}`
        }
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        zIndex: 10
    },
    mappedColumn: {
        borderLeft: `1px solid ${theme.colors.divider}`
    },
    paddingItem: {
        paddingLeft: 8,
        paddingRight: 8
    },
    added: {
        width: 16,
        height: 16,
        border: `1px solid ${theme.colors.shamRock}`,
        borderRadius: 4,
        background: hexToRgba(theme.colors.shamRock, 0.2)
    },
    updated: {
        width: 16,
        height: 16,
        border: `1px solid ${theme.colors.sun}`,
        borderRadius: 4,
        background: hexToRgba(theme.colors.sun, 0.2)
    },
    option: {
        padding: theme.spacing(2),
        minWidth: 265,
        boxSizing: 'border-box',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    active: {
        background: theme.colors.ghostwhite,
        borderRadius: 6
    },
    optionIcon: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(1)
    },
    previewGrid: {
        width: '100%',
        height: 400,
        position: 'relative'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 100
    },
    diffCheckTable: {
        height: `100%`,
        width: '100%',
        overflow: 'hidden',
        userSelect: 'none'
    },
    showAllConflict: {
        width: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    paper: {
        minWidth: 200
    },
    selectPathWrapper: {
        flex: 1,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        background: theme.palette.common.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        cursor: 'pointer',
        height: INPUT_HEIGHT
    },
    wrapperPathTag: {
        ...theme.ellipsis(1)
    }
}));

const IMPORT_OPTIONS = [
    { value: 'update', label: `<strong>Add new and update</strong> existing records`, options: ['update', 'add'] },
    { value: 'updateOnly', label: `<strong>Update</strong> existing records`, options: ['update'] },
    { value: 'add', label: `<strong>Add new</strong> records at bottom`, options: ['add'] }
];

const RECORD_OPTIONS = [
    { value: 'add', label: 'Add', description: 'Add new records at bottom', icon: <AddRecordSVG /> },
    { value: 'update', label: 'Update', description: 'Update existing records', icon: <UpdateRecordSVG /> }
];

const STATES = {
    BASIC: 'BASIC',
    ADVANCED: 'ADVANCED'
};

function ImportView({ files, onClose, workspaceId, gridId, viewId, dbId, branchId }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [currentState, setCurrentState] = React.useState(STATES.BASIC);

    const isAdvanced = React.useMemo(() => {
        return currentState === STATES.ADVANCED;
    }, [currentState]);

    const fileContent = useViewImportFileContent();
    const resourceKey = useViewImportResourceKey();
    const fileColumns = fileContent?.columns || [];
    const fileData = fileContent?.data || {};
    const rows = fileContent?.rows || [];
    const hasRecordId = fileContent?.hasRecordId;
    const dependencyStatusData = fileContent?.dependencyStatusData;
    const file = useViewImportFile();
    const { t } = useTranslation();
    const currentGrid = useCurrentGrid();

    const fileName = React.useMemo(() => {
        return file?.[0]?.name;
    }, [file]);

    const [importOption, setImportOption] = React.useState(IMPORT_OPTIONS?.[0]?.value);
    const [keyColumn, setKeyColumn] = React.useState(hasRecordId ? columnTypes.RECORD_ID : undefined);
    const [isContainFirstRowHeader, setIsContainFirstRowHeader] = React.useState(true);
    const [isImporting, setIsImporting] = React.useState(false);
    const [viewType, setViewType] = React.useState(UPLOAD_SETUP_VIEW_TYPES.TABLE);
    const [columnSettings, setColumnSettings] = React.useState({});
    const metaData = useMetaData();
    const exportRemovedColumns = useExportRemovedColumns();
    const columnIdsWithOrders = useViewColumnIdsWithReOrder();
    let columnIds = removeArrayInArray(columnIdsWithOrders, exportRemovedColumns);
    const [isUploading, setIsUploading] = React.useState(false);
    const [recordOptions, setRecordOptions] = React.useState(['add', 'update']);
    const [taskId, setTaskId] = React.useState();
    const taskStatus = useTaskStatus(taskId);
    const [isGeneratingAdvancedSetting, setIsGeneratingAdvancedSetting] = React.useState(false);
    const [isFetchingDiffCheck, setIsFetchingDiffCheck] = React.useState(false);
    const contextMenuId = useContextMenuId();
    const [resolveConflictData, setResolveConflictData] = React.useState({});
    const [aggregations, setAggregations] = React.useState({});
    const [quickFilters, setQuickFilters] = React.useState({});
    const [showAllConflicts, setShowAllConflicts] = React.useState(false);
    const [rowHeight, setRowHeight] = React.useState(ROW_HEIGHT * 2);
    const [defaultRowHeightAnchorEl, setDefaultRowHeightAnchorEl] = React.useState(null);
    const [openCustomRowHeight, setOpenCustomRowHeight] = React.useState(false);

    const pathRef = usePathTagRef();
    const tree = usePathTagTree();
    const [rootPath, setRootPath] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [previewData, setPreviewData] = React.useState({
        isFetchingMore: false,
        rows: [],
        data: {},
        metaData: {},
        totalRecords: 0,
        ROW_START_INDEX: 0,
        ROW_STOP_INDEX: RECORDS_RENDER_GRID_PREVIEW,
        tree: []
    });

    React.useEffect(() => {
        let gridTable = document.getElementById(`gridUI-table-import`);
        if (gridTable) {
            gridTable.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            });
        }
    }, []);

    const mappedColumnsCount = React.useMemo(() => {
        return Object.keys(columnSettings).filter(key => columnSettings[key]?.targetColId).length;
    }, [columnSettings]);

    const totalColumnsCount = React.useMemo(() => {
        return Object.keys(columnSettings).length;
    }, [columnSettings]);

    const firstRow = React.useMemo(() => {
        return fileData?.[rows?.[0]] || {};
    }, [fileData, rows]);

    const ONLY_SYSTEM_IDS = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS?.filter(id => ![columnTypes?.PATH_TAG]?.includes(id));
    }, []);

    const totalRecords = React.useMemo(() => {
        return rows.length > 5 ? 6 : rows.length;
    }, [rows]);

    const viewColumns = React.useMemo(() => {
        return columnIds.map(gridId => {
            const colObj = metaData?.[gridId] || {};
            return {
                id: gridId,
                ...colObj
            };
        });
    }, [columnIds, metaData]);

    const EXTRA_OPTIONS = React.useMemo(() => {
        return [
            { value: 'none', label: 'None', matches: [] },
            {
                value: columnTypes.RECORD_ID,
                label: 'Record ID',
                icon: () => <ColumnIcon type={columnTypes.RECORD_ID} />,
                isDisabled: false,
                matches: RECORD_IDS_MATCHES
            }
        ];
    }, []);

    const columnIdsHasMapped = React.useMemo(() => {
        return Object.keys(columnSettings)
            ?.filter(key => !!columnSettings?.[key]?.targetColId)
            ?.map(key => columnSettings?.[key]?.targetColId);
    }, [columnSettings]);

    const columnOptions = React.useMemo(() => {
        const options = viewColumns
            ?.filter(
                column =>
                    !ONLY_SYSTEM_IDS?.includes(column?.id) &&
                    [
                        ...IMPORT_VALID_COLUMN_TYPES,
                        columnTypes.TRANSLATION,
                        columnTypes.REFERENCE,
                        ...IMPORT_INSIDE_WARNING_COLUMN_TYPES
                    ]?.includes(column?.type)
            )
            ?.map(col => {
                const label = col.id === PATH_TAG_ID ? 'Path' : col.name;
                const matches =
                    col?.type === columnTypes?.TRANSLATION
                        ? getFuzzyByLanguage({
                              langCode: col?.group,
                              langName: SUPPORTED_LANGUAGES?.find(lang => lang?.id === col?.group)?.name
                          })
                        : col.id === PATH_TAG_ID
                        ? PATH_TAG_MATCHES_ID
                        : [col?.name];

                const isDisabled = !col?.editable;
                const option = {
                    value: col.id,
                    label,
                    icon: () => (
                        <ColumnIcon group={col.group} type={col.type} customProperties={col.customProperties} />
                    ),
                    isDisabled: isDisabled,
                    disabledTitle: isDisabled ? (
                        <p className="body2">
                            <Trans t={t} i18nKey="import_tooltip_not_editable">
                                This column is not editable
                                <br />
                                on column settings.
                            </Trans>
                        </p>
                    ) : (
                        undefined
                    ),
                    matches,
                    type: col?.type,
                    placement: 'right'
                };

                if (IMPORT_INSIDE_WARNING_COLUMN_TYPES?.includes(col?.type)) {
                    return {
                        ...option,
                        isDisabled: true,
                        disabledTitle: (
                            <p className="body2">
                                <Trans t={t} i18nKey="import_tooltip_not_supported">
                                    Uploading this data type is <br /> not supported for now.
                                </Trans>
                            </p>
                        ),
                        placement: 'right'
                    };
                }

                return option;
            });

        return EXTRA_OPTIONS.concat(options);
    }, [viewColumns, ONLY_SYSTEM_IDS, EXTRA_OPTIONS, t]);

    const isPathTagEditable = React.useMemo(() => {
        return !columnOptions?.find(opt => opt?.value === columnTypes.PATH_TAG)?.isDisabled;
    }, [columnOptions]);

    const getSettings = React.useCallback(
        ({ fileColumns, firstRow, metaData }) => {
            const cloneSettings = metaData ? { ...metaData } : {};
            let recordIdColumn = null;

            const isTargetColIdAlreadyMapped = columnId => {
                return !!Object.keys(cloneSettings)
                    ?.map(key => cloneSettings?.[key])
                    ?.find(col => col?.targetColId === columnId);
            };

            fileColumns.forEach((columnId, index) => {
                const colValue = (firstRow?.[columnId] || '')
                    ?.toString()
                    ?.toLowerCase()
                    ?.trim();

                const found = columnOptions.find(opt => {
                    const option = opt?.matches?.map(match => match?.toLowerCase())?.includes(colValue);
                    const isMapped = isTargetColIdAlreadyMapped(opt?.value);
                    const isDisabled = opt?.value === columnTypes.RECORD_ID ? false : opt?.isDisabled;
                    return option && !isMapped && !isDisabled;
                });

                if (cloneSettings[columnId] && found && !IMPORT_INSIDE_WARNING_COLUMN_TYPES?.includes(found?.type)) {
                    cloneSettings[columnId].type = found.type;

                    if (found?.value === columnTypes.RECORD_ID) {
                        if (!recordIdColumn) {
                            cloneSettings[columnId].name = found.label;
                            cloneSettings[columnId].targetColId = found.value;
                            recordIdColumn = columnId;
                        }

                        if (colValue === columnTypes.RECORD_ID?.toLowerCase() && !!recordIdColumn) {
                            if (cloneSettings?.[recordIdColumn]) {
                                delete cloneSettings?.[recordIdColumn]?.name;
                                delete cloneSettings?.[recordIdColumn]?.targetColId;
                            }
                            cloneSettings[columnId].name = found.label;
                            cloneSettings[columnId].targetColId = found.value;
                        }
                    } else {
                        cloneSettings[columnId].name = found.label;
                        cloneSettings[columnId].targetColId = found.value;

                        if (
                            cloneSettings[columnId].type === columnTypes.DATETIME &&
                            !cloneSettings[columnId].timezone
                        ) {
                            cloneSettings[columnId].timezone = { ...DEFAULT_TIME_ZONE };
                        } else if (cloneSettings[columnId].type === columnTypes.PATH_TAG) {
                            cloneSettings[columnId].type = columnTypes.SINGLE_LINE;
                        }
                    }
                }
            });

            return cloneSettings;
        },
        [columnOptions]
    );

    const generateSettingsFromHeader = React.useCallback(
        initSetting => {
            return getSettings({ fileColumns, firstRow, metaData: initSetting });
        },
        [fileColumns, firstRow, getSettings]
    );

    const handleConflictChanged = React.useCallback(
        ({ rowId, columnId, mode }) => {
            setResolveConflictData(
                produce(resolveConflictData, draft => {
                    if (!draft?.[rowId]) {
                        draft[rowId] = {};
                    }
                    draft[rowId][columnId] = mode;
                })
            );
        },
        [resolveConflictData]
    );

    const checkExistedRecordInViewShowImportOption = React.useCallback(hasRecordId => {
        if (!hasRecordId) {
            setImportOption(IMPORT_OPTIONS?.[2]?.value);
            setKeyColumn(undefined);
        } else {
            setKeyColumn(columnTypes.RECORD_ID);
        }
    }, []);

    React.useEffect(() => {
        setIsUploading(true);
        dispatch(
            gridActions.importViewReadFile({
                dbId,
                queryParams: {
                    dbId,
                    gridId: branchId,
                    viewId,
                    file: files
                },
                successCallback: ({ metaData, columns, firstRow, hasRecordId }) => {
                    setIsUploading(false);
                    checkExistedRecordInViewShowImportOption(hasRecordId);
                    const columnSettings = getSettings({ fileColumns: columns, firstRow, metaData });
                    console.log('columnSettings', columnSettings);
                    setColumnSettings(columnSettings);
                },
                errorCallback: () => {
                    setIsUploading(false);
                }
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId, dbId, dispatch, files, viewId]);

    const handleImportData = React.useCallback(() => {
        setIsImporting(true);
        sendManualTrack({ type: `Start Import File` });
        if (isAdvanced) {
            const mergeRecordConflicts = [];

            const recordIds = Object.keys(resolveConflictData);
            for (const recordId of recordIds) {
                const columnIds = Object.keys(resolveConflictData?.[recordId]);

                let cells = [];

                for (const columnId of columnIds) {
                    let cell = {
                        columnId,
                        option: resolveConflictData?.[recordId]?.[columnId]
                    };
                    cells.push(cell);
                }
                mergeRecordConflicts.push({
                    recordId,
                    cells
                });
            }

            let importBody = {
                mergeRecordConflicts,
                mergeDependencyOptions: ['update'],
                mergeRecordOptions: recordOptions,
                mergeTagTreeOptions: ['update'],
                taskId,
                showAllConflicts
            };

            if (!isLDEmpty(quickFilters)) {
                importBody = {
                    ...importBody,
                    query: JSON.stringify(formatQuickFilters(quickFilters))
                };
            }

            dispatch(
                gridActions.mergeImport({
                    dbId,
                    viewId,
                    body: importBody,
                    successCallback: () => {
                        setIsImporting(false);
                        onClose && onClose();
                        sendManualTrack({ type: `Import File Success` });
                    },
                    errorCallback: () => {
                        setIsImporting(false);
                        sendManualTrack({ type: `Import File Failed` });
                    }
                })
            );
        } else {
            let options = {
                columnSettings,
                isFirstRowHeader: isContainFirstRowHeader,
                importOption

                // csvDelimiter: delimiter?.value
                // keyColumn: keyColumnServer
            };

            if (isPathTagEditable && rootPath) {
                options = {
                    ...options,
                    rootPath
                };
            }

            dispatch(
                gridActions.importViewData({
                    queryParams: {
                        dbId,
                        viewId,
                        // file,
                        resourceKey,
                        options
                    },
                    successCallback: () => {
                        setIsImporting(false);
                        onClose && onClose();
                        sendManualTrack({ type: `Import File Success` });
                        // handleOnImportSuccess();
                    },
                    errorCallback: () => {
                        setIsImporting(false);
                        sendManualTrack({ type: `Import File Failed` });
                    }
                })
            );
        }
    }, [
        showAllConflicts,
        quickFilters,
        isPathTagEditable,
        rootPath,
        recordOptions,
        resolveConflictData,
        taskId,
        columnSettings,
        dbId,
        dispatch,
        importOption,
        isContainFirstRowHeader,
        onClose,
        resourceKey,
        viewId,
        isAdvanced
    ]);

    const handleImportOptionChange = React.useCallback(option => {
        let importOption = option.value;

        if (importOption !== IMPORT_OPTIONS?.[2]?.value) {
            setKeyColumn(columnTypes.RECORD_ID);
        }
        setImportOption(importOption);
    }, []);

    const fetchingDiffCheck = React.useCallback(
        async ({
            ROW_START_INDEX = 0,
            ROW_STOP_INDEX = RECORDS_RENDER_GRID_PREVIEW,
            recordOptions,
            quickFilters,
            showAllConflicts
        }) => {
            try {
                setIsFetchingDiffCheck(true);

                const [{ totalRecords, records }, columnAggregations] = await Promise.all([
                    await getImportDiffCheckApi({
                        dbId,
                        viewId,
                        taskId,
                        limit: ROW_STOP_INDEX,
                        offset: ROW_START_INDEX,
                        mergeRecordOptions: recordOptions,
                        query: formatQuickFilters(quickFilters),
                        showAllConflicts
                    }),
                    await getPreviewImportAggregationApi({
                        taskId,
                        dbId,
                        viewId,
                        mergeRecordOptions: recordOptions,
                        query: formatQuickFilters(quickFilters),
                        showAllConflicts,
                        columnIds: columnIdsHasMapped
                    })
                ]);
                const { rows, data } = formatDataGridPreview(records);
                setIsFetchingDiffCheck(false);

                setAggregations(
                    columnAggregations?.reduce((obj, i) => {
                        obj[i?.columnId] = i;
                        return obj;
                    }, {})
                );

                setPreviewData(
                    produce(previewData, draft => {
                        draft.isFetchingMore = false;
                        draft.rows = rows;
                        draft.data = data;
                        draft.totalRecords = totalRecords;
                        draft.ROW_STOP_INDEX = ROW_STOP_INDEX;
                        draft.ROW_START_INDEX = ROW_START_INDEX;
                    })
                );
            } catch ({ message }) {
                setIsFetchingDiffCheck(false);
            }
        },
        [dbId, viewId, taskId, previewData, columnIdsHasMapped]
    );

    const handleFetchMore = React.useCallback(
        async ({ dbId, gridId, parentGridId, ROW_START_INDEX, ROW_STOP_INDEX, successCallback, errorCallback }) => {
            fetchingDiffCheck({ ROW_START_INDEX, ROW_STOP_INDEX, recordOptions, quickFilters, showAllConflicts });
        },
        [fetchingDiffCheck, recordOptions, quickFilters, showAllConflicts]
    );

    React.useEffect(() => {
        if (taskStatus === TASK_STATUS.SUCCESS && currentState === STATES.ADVANCED) {
            fetchingDiffCheck({
                offset: 0,
                limit: RECORDS_RENDER_GRID_PREVIEW,
                recordOptions,
                quickFilters,
                showAllConflicts
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskStatus, currentState]);

    const handleToggleShowAllConflict = React.useCallback(() => {
        const newShowAllConflicts = !showAllConflicts;

        setShowAllConflicts(newShowAllConflicts);

        fetchingDiffCheck({
            offset: 0,
            limit: RECORDS_RENDER_GRID_PREVIEW,
            recordOptions,
            quickFilters,
            showAllConflicts: newShowAllConflicts
        });
    }, [quickFilters, recordOptions, fetchingDiffCheck, showAllConflicts]);

    const handleAggregationChange = React.useCallback(
        ({ columnId, aggregationType }) => {
            const value = 30;

            setAggregations({
                ...aggregations,
                [columnId]: {
                    ...aggregations?.[columnId],
                    type: aggregationType,
                    value: value
                }
            });
        },
        [aggregations]
    );

    const handleExtraFilterChange = React.useCallback(
        ({ extraFilter, columnId }) => {
            const newQuickFilters = {
                ...quickFilters,
                [columnId]: {
                    ...quickFilters[columnId],
                    extraFilter
                }
            };
            setQuickFilters(newQuickFilters);

            fetchingDiffCheck({
                offset: 0,
                limit: RECORDS_RENDER_GRID_PREVIEW,
                recordOptions,
                quickFilters: newQuickFilters,
                showAllConflicts
            });
        },
        [quickFilters, fetchingDiffCheck, recordOptions, showAllConflicts]
    );

    const handleQuickFilterChange = React.useCallback(
        ({ columnId, value, type, currentState, operator }) => {
            const newQuickFilters = {
                ...quickFilters,
                [columnId]: {
                    ...quickFilters[columnId],
                    type,
                    value,
                    currentState,
                    operator
                }
            };
            setQuickFilters(newQuickFilters);

            fetchingDiffCheck({
                offset: 0,
                limit: RECORDS_RENDER_GRID_PREVIEW,
                recordOptions,
                quickFilters: newQuickFilters,
                showAllConflicts
            });
        },
        [quickFilters, fetchingDiffCheck, recordOptions, showAllConflicts]
    );

    const handleSwitchFirstRowChange = React.useCallback(
        e => {
            let checked = e.target.checked;
            setIsContainFirstRowHeader(checked);

            if (checked) {
                setColumnSettings(generateSettingsFromHeader(columnSettings));
            }
        },
        [columnSettings, generateSettingsFromHeader]
    );

    const handleViewChange = type => {
        setViewType(type);
    };

    const handleHeaderChange = React.useCallback(
        ({ columnIndex, column, selectedValue, timezone }) => {
            if (columnSettings[column.id]) {
                if (selectedValue.value === 'none') {
                    setColumnSettings({
                        ...columnSettings,
                        [column.id]: {
                            ...columnSettings[column.id],
                            name: selectedValue.label,
                            targetColId: null
                        }
                    });
                    return;
                }

                Object.keys(columnSettings).forEach(key => {
                    if (columnSettings[key]['targetColId'] === selectedValue.value) {
                        columnSettings[key] = {
                            ...columnSettings[key],
                            targetColId: null
                        };
                    }
                });

                setColumnSettings({
                    ...columnSettings,
                    [column.id]: {
                        ...columnSettings[column.id],
                        name: selectedValue.label,
                        targetColId: selectedValue.value,
                        timezone
                    }
                });
            }
        },
        [columnSettings]
    );

    const handleHeaderChangeTimezone = React.useCallback(
        ({ column, timezone }) => {
            setColumnSettings({
                ...columnSettings,
                [column.id]: {
                    ...columnSettings[column.id],
                    timezone
                }
            });
        },
        [columnSettings]
    );

    const isValid = React.useMemo(() => {
        let valid = false;
        const keys = Object.keys(columnSettings);
        for (let key of keys) {
            const col = columnSettings?.[key];
            if (col?.targetColId) {
                valid = true;
                break;
            }
        }
        return valid;
    }, [columnSettings]);

    const keyColumnServer = React.useMemo(() => {
        let isIncluded = false;

        const columnIds = Object.keys(columnSettings);

        for (const columnId of columnIds) {
            const columnDetail = columnSettings?.[columnId];

            if (columnDetail?.targetColId === keyColumn) {
                isIncluded = true;
                break;
            }
        }
        return isIncluded;
    }, [columnSettings, keyColumn]);

    const isValidKeyColumn = React.useMemo(() => {
        if ([IMPORT_OPTIONS?.[2]?.value].includes(importOption)) return true;
        return keyColumnServer;
    }, [keyColumnServer, importOption]);

    const data = React.useMemo(() => {
        return formatReviewData({ data: fileData, metaData: columnSettings });
    }, [fileData, columnSettings]);

    const isAddNewAndHavingRecordId = React.useMemo(() => {
        return [IMPORT_OPTIONS?.[2]?.value].includes(importOption) && keyColumnServer;
    }, [keyColumnServer, importOption]);

    const gotoAdvancedSetting = React.useCallback(async () => {
        if (importOption === 'update') {
            setRecordOptions(['update', 'add']);
        } else if (importOption === 'add') {
            setRecordOptions(['add']);
        } else {
            setRecordOptions(['update']);
        }

        setIsGeneratingAdvancedSetting(true);
        try {
            let importRequest = {
                withHeader: isContainFirstRowHeader ? true : false,
                firstDataRow: isContainFirstRowHeader ? 1 : 0,
                importOption,
                columnMappings: Object.keys(columnSettings)
                    ?.filter(key => {
                        const setting = columnSettings?.[key];
                        return !!setting?.targetColId;
                    })
                    .map(key => ({
                        columnId: columnSettings?.[key]?.['targetColId'],
                        fileColumnIndex: columnSettings?.[key]?.['order']
                    })),
                keyColumn
            };

            if (isPathTagEditable && rootPath) {
                importRequest = {
                    ...importRequest,
                    rootPath
                };
            }

            const task = await generatePreviewImportApi({
                dbId,
                viewId,
                query: {
                    key: resourceKey,
                    importRequest: JSON.stringify(importRequest)
                }
            });
            setTaskId(task?.id);
            // dispatch(gridActions.setTaskIdStatus({ taskId: task?.id, status: TASK_STATUS.RUNNING }));
            setIsGeneratingAdvancedSetting(false);
            setCurrentState(STATES.ADVANCED);
        } catch (error) {
            setIsGeneratingAdvancedSetting(false);
        }
    }, [
        importOption,
        dbId,
        viewId,
        columnSettings,
        isContainFirstRowHeader,
        keyColumn,
        resourceKey,
        isPathTagEditable,
        rootPath
    ]);

    const handleRecordOptionChange = React.useCallback(
        option => {
            let newRecordOptions = [];
            if (recordOptions.includes(option)) {
                newRecordOptions = recordOptions?.filter(opt => option !== opt);
            } else {
                newRecordOptions = [...recordOptions, option];
            }
            setRecordOptions(newRecordOptions);

            fetchingDiffCheck({
                offset: 0,
                limit: RECORDS_RENDER_GRID_PREVIEW,
                recordOptions: newRecordOptions,
                quickFilters,
                showAllConflicts
            });
        },
        [recordOptions, fetchingDiffCheck, showAllConflicts, quickFilters]
    );

    const handleBack = React.useCallback(() => {
        if (recordOptions?.includes('add') && recordOptions?.includes('update')) {
            setImportOption('update');
        } else if (recordOptions?.includes('add')) {
            setImportOption('add');
        } else if (recordOptions?.includes('update')) {
            setImportOption('updateOnly');
        } else {
            setImportOption('add');
        }
        setCurrentState(STATES.BASIC);
    }, [recordOptions]);

    const pathData = React.useMemo(() => {
        return getNodesByPath({ pathRef, path: rootPath });
    }, [rootPath, pathRef]);

    const handleRowHeightClick = React.useCallback(
        event => {
            setDefaultRowHeightAnchorEl(defaultRowHeightAnchorEl ? null : event.currentTarget);
        },
        [defaultRowHeightAnchorEl]
    );

    const handleCloseRowHeight = React.useCallback(event => {
        setDefaultRowHeightAnchorEl(null);
    }, []);

    const changeDefaultRowHeight = React.useCallback(
        newRowHeight => {
            setRowHeight(newRowHeight);
            handleCloseRowHeight();
        },
        [handleCloseRowHeight]
    );

    const openCustomRowHeightHandler = React.useCallback(() => {
        setOpenCustomRowHeight(true);
        handleCloseRowHeight();
    }, [handleCloseRowHeight]);

    const closeCustomRowHeightHandler = React.useCallback(() => setOpenCustomRowHeight(false), []);

    const handleCustomRowHeightChange = React.useCallback(
        newHeight => {
            setRowHeight(newHeight);
            closeCustomRowHeightHandler();
        },
        [closeCustomRowHeightHandler]
    );

    const renderPathtag = React.useCallback(
        (isTooltip = false) => {
            return pathData?.map((node, index) => {
                return (
                    <React.Fragment key={node?.id}>
                        <Option
                            className={isTooltip ? classes.option : ''}
                            size="small"
                            label={node?.name}
                            color={node?.customProperties?.color || DEFAULT_PATH_TAG_COLOR}
                        />
                        {index !== pathData?.length - 1 && (
                            <p className="body1 inline" style={isTooltip ? { color: 'white' } : {}}>
                                /
                            </p>
                        )}
                    </React.Fragment>
                );
            });
        },
        [classes, pathData]
    );

    const TitleRender = React.useMemo(() => {
        return (
            <div className="flex flex-row items-center gap-1">
                <h3 className="prose prose-2xl font-medium">
                    {isAdvanced ? `Manage import data change` : t(`import_data`)}{' '}
                </h3>
                <div className="flex items-center bg-grey-pale py-1.5 px-2 rounded">
                    <FileSVG />
                    <p className="body2 line-clamp-1">{fileName || 'Default File'}</p>
                </div>
            </div>
        );
    }, [classes, fileName, isAdvanced, t, theme]);

    const ActionRender = React.useMemo(() => {
        return (
            <Grid item container direction="row" wrap="nowrap" justify={'space-between'} alignItems="center">
                <Grid item>
                    {!isUploading && !isValidKeyColumn && !isAdvanced && (
                        <Grid container spacing={2} alignItems="center" direction="row" wrap="nowrap">
                            <Grid item className={classes.dlFlex}>
                                <WarningSVG />
                            </Grid>
                            <Grid item>
                                <p className="body2 inline">Please map the</p>
                                <div className="body2 inline-flex items-center relative top-1 py-0 px-2 my-0 mx-1 rounded-lg bg-navy-pale text-grey-dim font-medium border-solitude border">
                                    <RecordIdSVG className={classes.recordId} color={theme.colors.systemIconColor} />
                                    Record ID
                                </div>
                                <p className="body2 inline">column to update existing records</p>
                            </Grid>
                        </Grid>
                    )}
                    {isAddNewAndHavingRecordId && !isAdvanced && (
                        <>
                            <p className="body1 inline">Note:</p>{' '}
                            <p className="body2 inline">only new record ID values will be imported.</p>
                        </>
                    )}
                </Grid>
                <Grid item>
                    <Grid container spacing={2} direction="row">
                        {isAdvanced && (
                            <Grid item>
                                <Button width={173} variant="outlined" onClick={handleBack}>
                                    <ArrowBackSVG style={{ marginRight: theme.spacing(1) }} /> Re-mapping column
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button width={120} variant="outlined" onClick={onClose}>
                                {t(`global_cancel`)}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                width={120}
                                disabled={!isValid || isImporting || !isValidKeyColumn || isFetchingDiffCheck}
                                variant="contained"
                                onClick={handleImportData}
                                color="primary"
                            >
                                {isImporting ? (
                                    <CircularProgress
                                        style={{
                                            color: 'white'
                                        }}
                                        size={20}
                                    />
                                ) : (
                                    t(`global_import`)
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [
        isFetchingDiffCheck,
        classes,
        isAdvanced,
        t,
        theme,
        isAddNewAndHavingRecordId,
        handleImportData,
        isImporting,
        isValid,
        isUploading,
        onClose,
        isValidKeyColumn,
        handleBack
    ]);

    const isLoadingDiffCheck = React.useMemo(() => {
        return taskStatus === TASK_STATUS.RUNNING || isFetchingDiffCheck;
    }, [isFetchingDiffCheck, taskStatus]);

    console.log('previewData', previewData);

    const ContentRender = React.useMemo(() => {
        const GenerateButton = isGeneratingAdvancedSetting ? (
            <CircularProgress size={20} />
        ) : (
            <>
                <OrganizationSetttingSVG style={{ marginRight: theme.spacing(1) }} />
                <p className="body2" noWrap>
                    Manage import change
                </p>
            </>
        );

        const DiffCheckRender =
            columnIdsHasMapped?.length === 0 ? (
                <div className="flex flex-col gap-2 items-center">
                    <NoDiffCheck width={200} height={200} />
                    <h3 className="prose prose-2xl font-medium">{t('diffcheck_wrapper_text_1')}</h3>
                    <p className="caption">{t('diffcheck_wrapper_text_2')}</p>
                </div>
            ) : (
                <Grid className={classes.diffCheckTable}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <MergingGrid
                                    branchId={branchId}
                                    dbId={dbId}
                                    accessSupportSelectAllCheckbox={roleConst.HIDDEN}
                                    accessSupportAggregation={roleConst.HIDDEN}
                                    accessEditCell={roleConst.READ_ONLY}
                                    accessViewRecordHistory={roleConst.READ_ONLY}
                                    accessManageGridRecord={roleConst.READ_ONLY}
                                    accessEditColumn={true}
                                    dependencies={[]}
                                    metaData={metaData}
                                    rows={previewData?.rows}
                                    totalRecords={previewData?.totalRecords + FAKE_ROW}
                                    data={previewData?.data}
                                    columns={columnIdsHasMapped}
                                    width={width}
                                    maxHeight={height}
                                    rowHeight={rowHeight}
                                    fixedColumnCount={0}
                                    fixedRowCount={2}
                                    ROW_START_INDEX={previewData?.ROW_START_INDEX}
                                    ROW_STOP_INDEX={previewData?.ROW_STOP_INDEX}
                                    isFetchingMore={isFetchingDiffCheck}
                                    contextMenuId={contextMenuId}
                                    pathRef={pathRef}
                                    onFetchingMore={handleFetchMore}
                                    resolveConflictData={resolveConflictData}
                                    onConflictChanged={handleConflictChanged}
                                    conflictType={CONFLICT_TYPES.IMPORT}
                                    isOverride={true}
                                    AGG_HEIGHT={AGG_HEIGHT}
                                    onAggregationChange={handleAggregationChange}
                                    aggregations={aggregations}
                                    quickFilters={quickFilters}
                                    onRefreshGrid={() => {}}
                                    onExtraFilterChange={handleExtraFilterChange}
                                    onQuickFilterChange={handleQuickFilterChange}
                                    tree={tree}
                                />
                            );
                        }}
                    </AutoSizer>
                </Grid>
            );

        return !isAdvanced ? (
            <Grid container direction={'column'} className={classes.root}>
                <Grid item container className={`${classes.importOptions} ${classes.spacing}`} direction="column">
                    <Grid item>
                        <Grid container direction="row" alignItems="center" justify="flex-start" spacing={4}>
                            <Grid item className={classes.title}>
                                <p className="body1">{t(`global_import_options`)}</p>
                            </Grid>

                            <Grid item className={classes.dropdown}>
                                <Select
                                    options={IMPORT_OPTIONS}
                                    value={importOption}
                                    className={classes.dropdownClassName}
                                    border
                                    onChange={handleImportOptionChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {isPathTagEditable && (
                    <Grid
                        item
                        container
                        direction="row"
                        className={`${classes.spacing} ${classes.importOptions}`}
                        alignItems="center"
                    >
                        <Grid item style={{ flex: 1 }}>
                            <Grid container direction="row" alignItems="flex-start" spacing={4}>
                                <Grid item style={{ position: 'relative', top: 8 }}>
                                    <p className="body1">Select import path</p>
                                </Grid>

                                <Grid item style={{ flex: 1 }}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Grid
                                                className={classes.selectPathWrapper}
                                                onClick={event => setAnchorEl(anchorEl ? null : event.currentTarget)}
                                                item
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid item className={classes.wrapperPathTag}>
                                                    {isEmpty(pathData) && (
                                                        <p className="caption">Select path to import data</p>
                                                    )}
                                                    {!isEmpty(pathData) && renderPathtag()}
                                                </Grid>

                                                <Grid
                                                    item
                                                    style={{ display: 'flex' }}
                                                    onClick={
                                                        !isEmpty(pathData)
                                                            ? e => {
                                                                  e.stopPropagation();
                                                                  setRootPath('');
                                                              }
                                                            : () => {}
                                                    }
                                                >
                                                    {!isEmpty(pathData) ? <CloseIconSVG size={12} /> : <ArrowDownSVG />}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ marginTop: theme.spacing(2) }}>
                                            <p className="caption inline">
                                                Apply for new inserted records only. In case there is also path column
                                                in file, this will be set as parent path
                                            </p>{' '}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Popper
                            style={{ zIndex: 2000 }}
                            placement={`bottom-start`}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                        >
                            <Paper className={classes.paper}>
                                <FolderPath
                                    handleClickAway={() => setAnchorEl(null)}
                                    defaultPath={rootPath}
                                    onChange={newPath => {
                                        setRootPath(newPath);
                                        setAnchorEl(null);
                                    }}
                                />
                            </Paper>
                        </Popper>
                    </Grid>
                )}

                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    className={classes.spacing}
                    alignItems="center"
                >
                    <Grid item>
                        <Grid container alignItems="center" direction="row" spacing={3}>
                            <Grid item>
                                <p className="body1">{t(`global_import_column_mapping`)}</p>
                            </Grid>
                            <Grid item className={classes.paddingItem}>
                                <p className="body2 font-normal flex items-center">
                                    <IOSSwitch
                                        checked={isContainFirstRowHeader}
                                        onChange={handleSwitchFirstRowChange}
                                        className={classes.switchIcon}
                                    />
                                    {t(`global_import_first_row_as_header`)}
                                </p>
                            </Grid>
                            <Grid item className={`${classes.paddingItem} ${classes.mappedColumn}`}>
                                <p className="caption">
                                    {t('mapped_columns')} ({mappedColumnsCount}/{totalColumnsCount})
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ViewsSwitch onChange={handleViewChange} />
                    </Grid>
                </Grid>
                {viewType === UPLOAD_SETUP_VIEW_TYPES.TABLE && (
                    <Grid item container className={`${classes.table}`}>
                        {isUploading && (
                            <div className={classes.loadingSpinner}>
                                <Spinner size={30} thick={4} />
                            </div>
                        )}
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <GridUITable
                                        isUseColumnIdByIndex={true}
                                        metaData={columnSettings}
                                        totalRecords={totalRecords}
                                        data={data}
                                        rows={rows}
                                        columns={fileColumns}
                                        width={width}
                                        maxHeight={height}
                                        rowHeight={ROW_HEIGHT}
                                        fixedColumnCount={0}
                                        fixedRowCount={1}
                                        COLUMN_ID_WIDTH={0}
                                        dependencyStatusData={dependencyStatusData}
                                        isFirstRowHeader={isContainFirstRowHeader}
                                        isEmptyIndexHeaderCell={hasRecordId ? false : true}
                                        headerRenderer={headerProps => (
                                            <ImportHeader
                                                {...headerProps}
                                                options={columnOptions}
                                                onChange={handleHeaderChange}
                                                handleHeaderChangeTimezone={handleHeaderChangeTimezone}
                                                t={t}
                                            />
                                        )}
                                        nonHeader
                                        t={t}
                                        dbId={dbId}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </Grid>
                )}
                {viewType === UPLOAD_SETUP_VIEW_TYPES.LIST && (
                    <Grid item container className={`${classes.listView}`}>
                        <ColumnMapList
                            columnSettings={columnSettings}
                            columns={fileColumns}
                            options={columnOptions}
                            firstRow={firstRow}
                            isContainFirstRowHeader={isContainFirstRowHeader}
                            onChange={handleHeaderChange}
                            importOption={importOption}
                            handleHeaderChangeTimezone={handleHeaderChangeTimezone}
                        />
                    </Grid>
                )}
                <Grid
                    style={{ paddingTop: theme.spacing(3) }}
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justify="flex-end"
                    wrap="nowrap"
                >
                    {currentGrid?.globalUniquePublicRecordId && (
                        <Grid item container spacing={2} alignItems="center" direction="row" wrap="nowrap">
                            <Grid item className={classes.dlFlex}>
                                <WarningSVG />
                            </Grid>
                            <Grid item>
                                <p className="body2 inline">
                                    Gridly will generate the record IDs automatically for the being created records
                                </p>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            width={200}
                            disabled={isGeneratingAdvancedSetting}
                            variant="outlined"
                            onClick={gotoAdvancedSetting}
                        >
                            {GenerateButton}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <Grid container direction={'column'} wrap="nowrap" spacing={4} className={classes.advanced}>
                <Grid item container wrap="nowrap" direction="column" spacing={3}>
                    <Grid item>
                        <p className="body1">Import options</p>
                    </Grid>
                    <Grid item>
                        <Grid container wrap="nowrap" alignItems="center" spacing={4} direction="row">
                            {RECORD_OPTIONS.map(option => {
                                const isSelected = recordOptions?.includes(option?.value);
                                return (
                                    <Grid
                                        item
                                        key={option?.value}
                                        onClick={() => handleRecordOptionChange(option?.value)}
                                    >
                                        <Grid
                                            className={`${classes.option} ${isSelected ? classes.active : ``}`}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            wrap="nowrap"
                                        >
                                            <Grid item>
                                                <Checkbox checked={isSelected} size="small" />
                                            </Grid>
                                            <Grid item className={classes.optionIcon}>
                                                {option?.icon}
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <p className="body1">{option?.label}</p>
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="caption">{option?.description}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="row" justify="space-between">
                            <Grid item>
                                <p className="body1">Changes</p>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" direction="row" spacing={3}>
                                    <Grid item className={classes.dpFlex}>
                                        <Tooltip title={t('toolbar_default_rowHeight_tooltip')}>
                                            <IconButton onClick={handleRowHeightClick} size="small">
                                                <RowHeightSVG color={theme.colors.darkness} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    <Tooltip title="Toggle all updated cells">
                                        <Grid
                                            item
                                            className={classes.showAllConflict}
                                            style={{
                                                background: showAllConflicts ? theme.colors.solitude : ``
                                            }}
                                            onClick={handleToggleShowAllConflict}
                                        >
                                            <ShowAllConflictsSVG
                                                color={showAllConflicts ? theme.palette.primary.main : undefined}
                                            />
                                        </Grid>
                                    </Tooltip>

                                    <Grid item>
                                        <Divider orientation="vertical" style={{ height: 24 }} />
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                            <Grid item className={classes.dlFlex}>
                                                <SwapSVG />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">To make decision</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                            <Grid item className={classes.dlFlex}>
                                                <GridSVG color={theme.colors.lightLavender} height={20} width={20} />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">Current data</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                            <Grid item className={classes.dlFlex}>
                                                <ImportSVG color={theme.colors.sun} />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">Imported data</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                            <Grid item className={`${classes.dlFlex}`}>
                                                <span className={classes.added}></span>
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">Added</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center" direction="row" spacing={1}>
                                            <Grid item className={`${classes.dlFlex}`}>
                                                <span className={classes.updated}></span>
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">Updated</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            className={classes.previewGrid}
                            id={'gridUI-table-import'}
                        >
                            {isLoadingDiffCheck && (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    item
                                    className={classes.spinnerWrapper}
                                >
                                    <Spinner size={18} thick={3} />
                                </Grid>
                            )}
                            {DiffCheckRender}

                            {defaultRowHeightAnchorEl && (
                                <PopperMenu
                                    placement={`bottom-end`}
                                    anchorEl={defaultRowHeightAnchorEl}
                                    handleClickAway={handleCloseRowHeight}
                                    className={classes.popperMenu}
                                >
                                    <ListItem
                                        name={t('global_short')}
                                        icon={<ShortSVG />}
                                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.DEFAULT)}
                                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.DEFAULT}
                                    />
                                    <ListItem
                                        name={t('global_medium')}
                                        icon={<MediumSVG />}
                                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMFORTABLE)}
                                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMFORTABLE}
                                    />
                                    <ListItem
                                        icon={<TallSVG />}
                                        name={t('global_tall')}
                                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMPACT)}
                                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMPACT}
                                    />

                                    <ListItem
                                        icon={<CustomSVG />}
                                        name={t('global_custom')}
                                        onClick={openCustomRowHeightHandler}
                                        isSelected={
                                            !Object.keys(ROW_HEIGHT_OPTIONS)
                                                ?.map(key => ROW_HEIGHT_OPTIONS?.[key])
                                                ?.includes(rowHeight)
                                        }
                                    />
                                </PopperMenu>
                            )}

                            <Dialog maxWidth={false} open={openCustomRowHeight} onClose={closeCustomRowHeightHandler}>
                                <CustomRowHeight
                                    rowHeight={rowHeight}
                                    onClose={closeCustomRowHeightHandler}
                                    onHeightChange={handleCustomRowHeightChange}
                                />
                            </Dialog>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [
        changeDefaultRowHeight,
        defaultRowHeightAnchorEl,
        closeCustomRowHeightHandler,
        handleCloseRowHeight,
        handleCustomRowHeightChange,
        openCustomRowHeight,
        openCustomRowHeightHandler,
        showAllConflicts,
        handleToggleShowAllConflict,
        aggregations,
        handleExtraFilterChange,
        quickFilters,
        handleAggregationChange,
        handleQuickFilterChange,
        isPathTagEditable,
        pathData,
        renderPathtag,
        rootPath,
        anchorEl,
        isFetchingDiffCheck,
        metaData,
        branchId,
        columnIdsHasMapped,
        contextMenuId,
        handleConflictChanged,
        resolveConflictData,
        handleFetchMore,
        previewData,
        isLoadingDiffCheck,
        handleRecordOptionChange,
        recordOptions,
        isAdvanced,
        classes,
        columnOptions,
        columnSettings,
        data,
        dbId,
        dependencyStatusData,
        fileColumns,
        firstRow,
        gotoAdvancedSetting,
        handleHeaderChange,
        handleSwitchFirstRowChange,
        handleImportOptionChange,
        hasRecordId,
        importOption,
        isContainFirstRowHeader,
        isUploading,
        rows,
        t,
        mappedColumnsCount,
        theme,
        totalRecords,
        totalColumnsCount,
        viewType,
        isGeneratingAdvancedSetting,
        handleHeaderChangeTimezone,
        pathRef,
        tree,
        rowHeight,
        handleRowHeightClick,
        currentGrid
    ]);

    return (
        <>
            <DialogTitle title={TitleRender} onClose={onClose} />
            <DialogContent>{ContentRender}</DialogContent>
            <DialogActions>{ActionRender}</DialogActions>
        </>
    );
}

export default React.memo(ImportView);
