import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        '& .notistack-snackbar-root': {
            '& .MuiSnackbarContent-message': {
                flex: 1,
                '& svg': {
                    marginRight: theme.spacing(2)
                }
            },
            '& .MuiSnackbarContent-action': {
                paddingLeft: 40,
                paddingRight: theme.spacing(2)
            },
            minWidth: 'initial',
            boxShadow: 'none',
            // paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        '& #notistack-snackbar': {
            flex: 1
        }
    },
    success: {
        background: '#96C060 !important',
        color: theme.colors.white
    },
    error: {
        color: theme.colors.primaryText,
        background: `#FDF8F7`,
        border: `1px solid rgba(234, 106, 70, 0.8)`,
        borderLeftWidth: 4,
        '& p': {
            color: `${theme.colors.primaryText} !important`
        }
    },
    warning: {
        background: '#E86E5E !important',
        color: theme.colors.white
    },
    info: {
        background: '#353445 !important',
        color: theme.colors.white
    },
    anchorOriginBottomRight: {
        '& [class*="variant-info-custom"]': {
            backgroundColor: 'unset !important',
            padding: 0,
            boxShadow: 'unset',
            marginRight: '38px',
            marginBottom: '25px'
        }
    },
    errorInfo: {
        marginRight: 7.5
    }
}));

function NotifierProvider({ children }) {
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            classes={{
                root: classes.root,
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: `${classes.info} variant-info-custom`,
                anchorOriginBottomRight: classes.anchorOriginBottomRight
            }}
            iconVariant={{
                default: null,
                error: <InfoIconSVG className={classes.errorInfo} color="#EE8769" />,
                success: null,
                info: null,
                warning: null
            }}
        >
            {children}
        </SnackbarProvider>
    );
}
export default React.memo(NotifierProvider);
