import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import TwoFactorLayout from 'permission/twoFactor/common/TwoFactorLayout';
import { Grid } from '@material-ui/core';
import EmailVerificationStep from 'permission/twoFactor/common/EmailVerificationStep';
import { enableUserFactorApi } from 'services/company';

const useStyles = makeStyles(theme => ({
    body: {
        width: '35%',
        margin: 'auto',
        marginTop: 50
    },
    stepper: {
        padding: 0
    }
}));

function ProfileFactorSetup() {
    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const backToUserFactorProfile = () => history.push(`/profile/two-factor`);

    const handleVerify = async otp => {
        try {
            setError('');
            setIsLoading(true);
            await enableUserFactorApi(otp);
            setIsLoading(false);
            backToUserFactorProfile();
        } catch (error) {
            setError(error.originalMessage);
            setIsLoading(false);
        }
    };

    return (
        <TwoFactorLayout>
            <Grid item container direction="column" justify="center" className={classes.body}>
                <EmailVerificationStep
                    onVerify={handleVerify}
                    onBack={backToUserFactorProfile}
                    isLoading={isLoading}
                    error={error}
                />
            </Grid>
        </TwoFactorLayout>
    );
}

export default ProfileFactorSetup;
