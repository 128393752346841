import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'gridUI/actions';
import ViewCellOnly from './ViewCellOnly';
import CellBaseDropdown from 'components/selects/CellBaseServer';
import { Paper, Grid, Popper } from '@material-ui/core';
import ViewCellInfo from './ViewCellInfo';
import { get1GridColumnRecordsApi } from 'services/grid';
import * as columnTypes from 'const/columnTypes';
import { formatSelectedOptRef } from 'utils/gridUI/formatData';
import { isKbEscape, isKbTab, isKbShiftTab } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { OPTION_SPACING } from 'const/style';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { formatReferenceOptionByType, getReferenceOperatorAndValue } from 'utils/gridUI/reference';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import IOSSwitch from 'components/switches/IOS';
import uuidV4 from 'uuid/v4';
import groupByReferenceGif from 'assets/images/groupByReference.gif';
import Tooltip from 'components/tooltip/Base';
import { getCellBackgroundByHex } from 'utils/color';
import { REFERENCE_SELECTION_TYPES } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        minWidth: 200
    },
    option: {
        '&:not(:last-child)': {
            marginRight: OPTION_SPACING
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    imageItem: {
        borderRadius: 3,
        '&:not(:last-child)': {
            marginRight: OPTION_SPACING
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    unique: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.colors.border}`
    },
    dlFlex: {
        display: 'flex'
    },
    paddingHoz1: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    gif: {
        width: 252,
        borderRadius: 4,
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    },
    gifImage: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        marginBottom: theme.spacing(2)
    }
}));

function ReferenceSelect({
    columnId,
    column,
    value: cellValue,
    rowId,
    width,
    height,
    character,
    customProperties,
    rowIndex,
    columnIndex,
    background,
    dbId,
    fillColorBlindness,
    originalValue,
    t
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [showUnique, setShowUnique] = React.useState(true);
    const [selectKey, setSelectKey] = React.useState(uuidV4());

    const originalOptions = React.useMemo(() => {
        return formatSelectedOptRef(originalValue)?.options || [];
    }, [originalValue]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorElRef = React.useRef();

    const [selectedOptions, setSelectedOptions] = React.useState(originalOptions);
    const { referenceSettings, referencedColumnType } = column;

    const referencedBranchId = referenceSettings?.referencedBranchId;
    const referencedColumnId = referenceSettings?.referencedColumnId;
    const referenceSelectionType = referenceSettings?.referenceSelectionType;

    console.log('referenceSelectionType', referenceSelectionType);

    const rootRef = React.useRef();
    const popperRef = React.useRef();

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    React.useEffect(() => {
        if (anchorElRef.current) {
            setAnchorEl(anchorElRef.current);
            setTimeout(() => {
                const input = document.getElementById('reference-select-input');
                if (input) {
                    input.focus({ preventScroll: true });
                }
            }, 0);
        }
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;

    React.useEffect(() => {
        const keyDownHandler = e => {
            if (isKbEscape(e)) {
                dispatch(gridActions.cancelCellEdit());
            }

            if (isKbTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
            }
        };
        document.addEventListener('keydown', keyDownHandler, true);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, true);
        };
    }, [dispatch, rowIndex, columnIndex]);

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        const selectedValues = selectedOptions?.map(item => item.value) || null;
        const originalValues = originalOptions?.map(item => item.value);

        if ((isEmpty(selectedValues) && isEmpty(originalValue)) || isEqual(selectedValues, originalValues)) {
            return dispatch(gridActions.cancelCellEdit());
        }
        const values = selectedOptions.map(option => ({
            referencedDataItem: option.label,
            referencedRecordId: option.value
        }));
        dispatch(
            gridActions.cellClickAway({
                isClickYourSelf,
                value: values,
                type: columnTypes.REFERENCE,
                rowId,
                columnId
            })
        );
    };

    const handleOptionChange = options => {
        const opts = options ? (Array.isArray(options) ? options : [options]) : [];
        setSelectedOptions(opts);
        let values = opts.map(option => ({
            referencedDataItem: option?.originLabel,
            referencedRecordId: option?.value
        }));

        dispatch(
            gridActions.cellClickAwayAndStay({
                isClickYourSelf: false,
                value: values,
                type: columnTypes.REFERENCE,
                rowId,
                columnId
            })
        );
    };

    const serverFunction = React.useCallback(
        async inputValue => {
            const serverBody = {
                dbId,
                limit: 50,
                offset: 0,
                gridId: referencedBranchId,
                columnIds: [referencedColumnId],
                groupBy: showUnique ? [referencedColumnId] : []
            };

            if (inputValue) {
                const { operator, value } = getReferenceOperatorAndValue({
                    inputValue,
                    columnType: referencedColumnType
                });

                serverBody.filterValue = { [referencedColumnId]: { [operator]: value } };
            }

            const response = await get1GridColumnRecordsApi(serverBody);

            const options = response?.recordIds.map(recordId => {
                const recordData = getCellValueOnly({
                    data: response?.data,
                    columnId: referencedColumnId,
                    rowId: recordId
                });
                return {
                    label: recordData,
                    value: recordId,
                    originLabel: recordData
                };
            });
            const optionsFormatted = formatReferenceOptionByType({
                options,
                referencedColumnType,
                theme,
                classes,
                dbId
            });
            return optionsFormatted;
        },
        [referencedColumnId, dbId, referencedBranchId, referencedColumnType, theme, classes, showUnique]
    );

    const onCallback = React.useCallback(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    }, []);

    return (
        <Grid ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <div
                ref={anchorElRef}
                style={{
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white
                }}
            >
                <ViewCellOnly
                    dimension={{ width, height }}
                    value={cellValue}
                    type={column.type}
                    referencedColumnType={referencedColumnType}
                    customProperties={customProperties}
                    dbId={dbId}
                />
            </div>

            <Popper
                popperRef={popperRef}
                container={anchorElRef.current}
                id={id}
                placement={`bottom-start`}
                open={open}
                anchorEl={anchorEl}
            >
                <Paper
                    className={classes.paper}
                    style={{
                        width
                    }}
                >
                    <Grid container className={classes.unique} direction="row" alignItems="center">
                        <Grid item>
                            <IOSSwitch
                                checked={showUnique}
                                onChange={e => {
                                    setShowUnique(e?.target?.checked);
                                    setSelectKey(uuidV4());
                                }}
                                className={classes.switchIcon}
                            />
                        </Grid>
                        <Grid item className={classes.paddingHoz1}>
                            <p className="body2">Show unique values</p>
                        </Grid>
                        <Tooltip
                            title={
                                <Grid container className={classes.gif} alignItems={'flex-start'} direction="column">
                                    <Grid item>
                                        <img
                                            className={classes.gifImage}
                                            src={groupByReferenceGif}
                                            alt="reference gif"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p className="text-light font-medium">{t('reference_unique_value_title')}</p>
                                    </Grid>
                                    <Grid item>
                                        <p className="caption">{t('reference_unique_tutorial_text')}</p>
                                    </Grid>
                                </Grid>
                            }
                        >
                            <Grid item className={classes.dlFlex}>
                                <QuestionSVG />
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <CellBaseDropdown
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        searchKey={character}
                        serverFunction={serverFunction}
                        defaultValue={selectedOptions}
                        isMulti={referenceSelectionType === REFERENCE_SELECTION_TYPES.MULTIPLE}
                        key={selectKey}
                        onCallback={onCallback}
                        autoFocus={false}
                        inputId="reference-select-input"
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(ReferenceSelect);
