import * as types from '../types';
import {
    getTagModelApi,
    getTagModelsApi,
    updateTagModelApi,
    deleteTagModelApi,
    exportTokenSetsApi,
    importTokenSetsApi,
    getTokenSetsApi,
    createTokenSetsApi,
    updateTokenSetsApi,
    deleteTokenSetsApi,
    deleteTokensApi
} from 'services/tag';
import { enqueueSnackbar } from 'notifier/actions';

export function setTagModels(payload) {
    return {
        type: types.SET_LIST_TAG_MODELS,
        payload
    };
}

export function fetchTagModels({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const tagModels = await getTagModelsApi();
            dispatch(setTagModels(tagModels));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateTBAction({ tag }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { tagModels } = gridUI;
        const newTagModels = tagModels.map(tagStore => {
            if (tagStore.id === tag.id) {
                return tag;
            }
            return tagStore;
        });
        dispatch(setTagModels(newTagModels));
    };
}

export function updateTagModel({ oldTag, newTag, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_updateTBAction({ tag: newTag }));
        try {
            await updateTagModelApi({ tokenSetId: newTag.id, body: newTag });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_updateTBAction({ tag: oldTag }));
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteTagModel({ tokenSetId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            await deleteTagModelApi(tokenSetId, body);
            successCallback && successCallback();
            setTimeout(() => {
                const { gridUI } = getState();
                const { tagModels } = gridUI;
                const newTagModels = tagModels?.filter(tagModel => tagModel?.id !== tokenSetId);
                dispatch(setTagModels(newTagModels));
            }, 800);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function getTagModelDetail({ tokenSetId, params, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const data = await getTagModelApi({ tokenSetId, params });
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function createTokenSet({ tokenSetId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await createTokenSetsApi({ tokenSetId, body });
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Added',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateTokenSet({ tokenSetId, tokenId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await updateTokenSetsApi({ tokenSetId, tokenId, body });
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Saved',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteTokenSet({ tokenSetId, tokenId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await deleteTokenSetsApi({ tokenSetId, tokenId });
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Deleted',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteTokens({ tokenSetId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await deleteTokensApi({ tokenSetId, body });
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'Deleted',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function getTokenSets({ tokenSetId, params, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const tokens = await getTokenSetsApi({ tokenSetId, params });
            successCallback && successCallback(tokens);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function exportTokenSets({ tokenSetId, format, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await exportTokenSetsApi(tokenSetId, format);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function importTokenSets({ tokenSetId, file, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await importTokenSetsApi(tokenSetId, file);
            dispatch(
                enqueueSnackbar({
                    message: 'The import has completed successfully',
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function setPredefinedTokens(payload) {
    return {
        type: types.SET_PREDEFINED_TOKENS,
        payload
    };
}
