import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import { getCorrectColumnType } from 'utils/gridUI/formatData';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '0 12px'
    },
    headerName: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '& svg': {
            width: 18
        },
        color: theme.colors.primaryText,
        '& p': {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            cursor: 'pointer'
        }
    },
    dropdown: {
        cursor: 'pointer',
        fontSize: 14,
        color: theme.colors.iconHeaderColor
    }
}));

function Header({ column = {} }) {
    const classes = useStyles();
    const columnType = getCorrectColumnType(column);

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.root}>
            <Grid item className={classes.headerName}>
                <ColumnTypeDisplay
                    group={column.group}
                    type={columnType}
                    name={column.name}
                    customProperties={column.customProperties}
                />
            </Grid>
        </Grid>
    );
}

Header.propTypes = {
    column: PropTypes.object.isRequired,
    isSelectionActive: PropTypes.bool,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(Header);
