import React from 'react';

function Text({ value: cellValue }) {
    if (typeof cellValue !== 'string' && typeof cellValue !== 'number') {
        return null;
    }
    return <p className="body2">{cellValue}</p>;
}

export default React.memo(Text);
