import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { sendManualTrack } from 'tracker';
import { useDispatch } from 'react-redux';
import * as gridActions from './actions';
import InputText from 'components/inputs/InputText';
import Button from 'components/button/Base';
import Checkbox from 'components/checkbox/Base';
import { DUPLICATE_RECORD_OPTIONS } from 'const';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 400,
        borderRadius: 4
    },
    spacing3: {
        marginBottom: theme.spacing(3)
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: theme.spacing(2)
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    options: {
        position: 'relative',
        left: -11
    }
}));

function DuplicateGridForm({ onClose, dbId, clonedGrid }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [duplicateRecordOption, setDuplicateRecordOption] = React.useState(true);

    const formik = useFormik({
        initialValues: {
            name: clonedGrid?.name ? t('grid_duplicate_form_name_default', { name: clonedGrid.name }) : ''
        },
        validationSchema: object().shape({
            name: string().required(t('global_this_field_is_required'))
        }),
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            sendManualTrack({
                type: t('grid_duplicate_form_action'),
                customData: {
                    dbId,
                    gridId: clonedGrid?.id
                }
            });
            setSubmitting(true);
            dispatch(
                gridActions.duplicateGrid({
                    ...values,
                    dbId,
                    clonedGrid,
                    duplicateRecordOption: duplicateRecordOption
                        ? DUPLICATE_RECORD_OPTIONS.INCLUDE
                        : DUPLICATE_RECORD_OPTIONS.EXCLUDE,
                    successCallback: () => {},
                    errorCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
            onClose && onClose();
            setStatus({ error: null });
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle
                title={<Typography variant="h4">{t('grid_duplicate_form_title')}</Typography>}
                onClose={onClose}
            />
            <DialogContent>
                <Grid container direction="column" wrap="nowrap" className={classes.root}>
                    <Grid item className={classes.spacing2}>
                        <Grid container direction="column">
                            <Grid item>
                                <InputText
                                    label={t('grid_form_name_label')}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    errorText={errors.name}
                                    placeholder={t('grid_duplicate_form_name_input')}
                                    autoFocus
                                    autoSelect={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" className={`${classes.spacing2} ${classes.options}`}>
                        <Grid item container alignItems="center" direction="row" spacing={2}>
                            <Grid item>
                                <Checkbox
                                    checked={duplicateRecordOption}
                                    onChange={e => setDuplicateRecordOption(e.target.checked)}
                                    name={t('grid_form_check_dup_records')}
                                    size="small"
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{t('grid_form_check_dup_records')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Button onClick={onClose} width={120} variant="outlined">
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <Button width={120} disabled={!isValid || isSubmitting} type="submit" variant="contained">
                                {t('global_text_save')}
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default React.memo(DuplicateGridForm);
