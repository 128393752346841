import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import RecordIdSVG from 'components/svg-icon/RecordIdSVG';
import { Grid, Typography } from '@material-ui/core';
import { useNotificationData } from 'hooks/app';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    flex: {
        display: 'flex'
    }
}));

function ImportNotification() {
    const classes = useStyles();
    const data = useNotificationData();

    const tableData = React.useMemo(() => {
        const results = [];
        const duplicatePublicRecordIds = data?.duplicatePublicRecordIds || [];
        const exceedLengthPublicRecordIds = data?.exceedLengthPublicRecordIds || [];

        for (const i of duplicatePublicRecordIds) {
            results.push({ recordId: i, message: 'A record with this ID already exists' });
        }

        for (const i of exceedLengthPublicRecordIds) {
            results.push({ recordId: i, message: 'Exceeded 500 character limit' });
        }

        return results;
    }, [data]);

    const columns = React.useMemo(() => {
        return [
            {
                label: (
                    <Grid container spacing={2} alignItems="center">
                        <Grid item className={classes.flex}>
                            <RecordIdSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">RecordID</Typography>
                        </Grid>
                    </Grid>
                ),
                dataKey: 'recordId',
                width: 350
            },
            {
                label: 'Reason for failure',
                dataKey: 'message',
                width: 180,
                flexGrow: 1
            }
        ];
    }, [classes]);

    return (
        <div className={classes.root}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={tableData}
                        columns={columns}
                        width={width}
                        height={height}
                        onRowClick={() => {}}
                        onSort={() => {}}
                        rowHeight={36}
                    ></Table>
                )}
            </AutoSizer>
        </div>
    );
}

export default ImportNotification;
