import React from 'react';

function CannotEditSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.2747 3.31231C5.29708 2.49857 6.59175 2.01234 7.99999 2.01234C11.3063 2.01234 13.9867 4.69267 13.9867 7.99901C13.9867 9.40724 13.5004 10.7019 12.6867 11.7243L4.2747 3.31231ZM3.31307 4.274C2.49946 5.29634 2.01332 6.5909 2.01332 7.99901C2.01332 11.3054 4.69364 13.9857 7.99999 13.9857C9.4081 13.9857 10.7027 13.4995 11.725 12.6859L3.31307 4.274ZM7.99999 0.652344C3.94254 0.652344 0.65332 3.94156 0.65332 7.99901C0.65332 12.0565 3.94254 15.3457 7.99999 15.3457C12.0574 15.3457 15.3467 12.0565 15.3467 7.99901C15.3467 3.94156 12.0574 0.652344 7.99999 0.652344Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CannotEditSVG);
