import { Grid, Typography, useTheme, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import React from 'react';
import { getProjectsAndDatabasesApi } from 'services/workspaces';
import ProjectAndDatabaseAccessItem from './ProjectAndDatabaseAccessItem';
import Tooltip from 'components/tooltip/Base';
import Spinner from 'components/spinner/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    table: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        marginTop: theme.spacing(3)
    },
    header: {
        backgroundColor: theme.colors.background
    },
    cell: {
        padding: '17px 16px'
    },
    arrowDown: {
        display: 'flex'
    },
    spinnerWrapper: {
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    panel: {
        '&.MuiAccordion-root': {
            margin: 0,
            boxShadow: 'none'
        }
    },
    panelSummary: {
        padding: 0,
        '&.MuiAccordionSummary-root': {
            display: 'inline-flex',
            minHeight: 0,
            borderRadius: 4,
            '&:hover': {
                background: theme.colors.hoverItem
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        }
    },
    panelDetail: {
        padding: 0
    },
    controls: {
        margin: theme.spacing(2, 0)
    },
    bottom: {
        padding: 16,
        borderTop: `1px solid ${theme.colors.border}`
    }
}));

function ProjectAndDatabaseAccessList({
    projectAccesses,
    onProjectChange,
    onDatabaseChange,
    onAccessAdd,
    onAccessRemove,
    defaultExpanded,
    panelSummaryClassName,
    hideLoading,
    noNeedFetchProjects,
    externalProjects,
    className
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [projects, setProjects] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (noNeedFetchProjects) return;
        const getProjectAndDatabases = async () => {
            try {
                setIsLoading(true);
                const projects = await getProjectsAndDatabasesApi();
                setIsLoading(false);
                setProjects(projects);
            } catch (error) {
                console.log('getProjectAndDatabases failed');
                setIsLoading(false);
            }
        };
        getProjectAndDatabases();
    }, [noNeedFetchProjects]);

    return (
        <Grid container direction="column" wrap="nowrap" className={className}>
            <Accordion className={classes.panel} defaultExpanded={defaultExpanded}>
                <AccordionSummary className={`${classes.panelSummary} ${panelSummaryClassName}`}>
                    <Grid item container alignItems="center" spacing={1} className={classes.controls}>
                        <Grid item style={{ display: 'flex' }}>
                            <ArrowDownSVG />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                {t('projects')} / {t('databases')}
                            </Typography>
                        </Grid>
                        <Tooltip title="Sharing entire Projects or Databases to give members access to all views inside">
                            <Grid item style={{ display: 'flex' }}>
                                <QuestionSVG />
                            </Grid>
                        </Tooltip>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.panelDetail}>
                    {!hideLoading && isLoading ? (
                        <div className={classes.spinnerWrapper}>
                            <Spinner size={40} thick={5} />
                        </div>
                    ) : (
                        <Grid container direction="column">
                            <Grid item container direction="column" className={classes.table}>
                                <Grid item container alignItems="center" className={classes.header}>
                                    <Grid item xs className={classes.cell}>
                                        <Typography variant="body1">Project</Typography>
                                    </Grid>
                                    <Grid item xs className={classes.cell}>
                                        <Typography variant="body1">Database</Typography>
                                    </Grid>
                                </Grid>

                                {projectAccesses.map((access, index) => {
                                    return (
                                        <ProjectAndDatabaseAccessItem
                                            key={index}
                                            index={index}
                                            access={access}
                                            projects={externalProjects || projects}
                                            onProjectChange={onProjectChange}
                                            onDatabaseChange={onDatabaseChange}
                                            onAccessRemove={onAccessRemove}
                                        />
                                    );
                                })}

                                <Grid item container justify="flex-end" className={classes.bottom}>
                                    <Grid item>
                                        <Grid container spacing={1} onClick={onAccessAdd}>
                                            <Grid item style={{ display: 'flex', cursor: 'pointer' }}>
                                                <ColorAddSVG color={theme.palette.primary.main} />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                                    variant="body1"
                                                >
                                                    New assignment
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default React.memo(ProjectAndDatabaseAccessList);
