export const FETCH_ADD_ON_LIST = '[ADD_ONS]FETCH_ADD_ON_LIST';
export const FETCH_ADD_ON_LIST_FAILED = '[ADD_ONS]FETCH_ADD_ON_LIST_FAILED';
export const FETCH_ADD_ON_LIST_SUCCESS = '[ADD_ONS]FETCH_ADD_ON_LIST_SUCCESS';

export const CHANGE_ADD_ON_STATE = '[ADD_ONS]CHANGE_ADD_ON_STATE';

export const GENERATE_MEMSOURCE_REDIRECT_URL = '[ADD_ONS]GENERATE_MEMSOURCE_REDIRECT_URL';
export const CONNECT_MEMSOURCE = '[ADD_ONS]CONNECT_MEMSOURCE';
export const CONNECT_MEMSOURCE_FAILED = '[ADD_ONS]CONNECT_MEMSOURCE_FAILED';
export const CONNECT_MEMSOURCE_SUCCESS = '[ADD_ONS]CONNECT_MEMSOURCE_SUCCESS';

export const CONNECT_MEMO_Q = `[ADD_ONS]CONNECT_MEMO_Q`;
