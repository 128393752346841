import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_RADIUS } from 'const/style';
import { NavLink, useRouteMatch } from 'react-router-dom';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useDispatch } from 'react-redux';
import * as appActions from 'app/actions';
import SettingMemberSVG from 'assets/images/svg/SettingMemberSVG';
import SettingGroupSVG from 'assets/images/svg/SettingGroupSVG';
import SettingInfoSVG from 'assets/images/svg/SettingInfoSVG';
import SettingRoleSVG from 'assets/images/svg/SettingRoleSVG';
import CodeSVG from 'assets/images/svg/CodeSVG';

import isEqual from 'lodash/isEqual';
import SettingBillingSVG from 'assets/images/svg/SettingBillingSVG';
import SettingStatisticSVG from 'assets/images/svg/SettingStatisticSVG';
import { useTranslation } from 'react-i18next';
import ShieldSVG from 'assets/images/svg/ShieldSVG';
import { AutoSizer } from 'react-virtualized-dn';
import InviteMembers from './invitation/inviteMembers';
import { isExternal } from 'config';
import LicenseSVG from 'assets/images/svg/LicenseSVG';
import { useCurrentUserFullInfo } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        '& svg': {
            cursor: 'pointer'
        }
    },
    item: {
        borderRadius: INPUT_RADIUS,
        height: 42,
        padding: `0px 14px`,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        textDecoration: 'none',
        color: theme.colors.primaryText,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '&.active': {
            background: theme.colors.hoverItem,
            '& p': {
                color: theme.palette.primary.main
            },
            '& path': {
                fill: theme.palette.primary.main
            }
        }
    },
    active: {
        background: theme.colors.hoverItem
    },
    settings: {
        width: '100%',
        padding: theme.spacing(3)
    },
    menuItems: {
        width: '100%',
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            maxHeight: `initial`
        }
    },
    inviteIcon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        marginBottom: theme.spacing(3)
    },
    inviteMembersSection: {
        height: 200,
        borderTop: `1px solid ${theme.colors.divider}`,
        [theme.breakpoints.down('xs')]: {
            height: 130
        }
    },
    inviteText: {
        color: theme.colors.lightGreyBlue,
        width: '80%',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: theme.spacing(3)
    }
}));

function PermissionSidebar({ marginTop }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const { t } = useTranslation();
    const currentUser = useCurrentUserFullInfo();
    console.log('isExternal', isExternal);
    console.log('currentUser', currentUser);

    const infoActive = [`info`, 'setting'];
    const groupActive = [`groups`, 'setting'];
    const roleActive = [`roles`, 'setting'];
    const memberActive = [`members`, 'setting'];
    const paymentActive = [`billing`, 'setting'];
    const statisticActive = [`statistics`, 'setting'];
    const codingActive = [`api-keys`, 'setting'];
    const securityActive = [`security`, 'setting'];
    const licenseActive = [`license`, 'setting'];

    const settings = [
        {
            value: infoActive,
            name: t('setting_sidebar_info'),
            path: `${path}/info`,
            icon: color => <SettingInfoSVG color={color} />
        },
        {
            value: groupActive,
            name: t('setting_sidebar_groups'),
            path: `${path}/groups`,
            icon: color => <SettingGroupSVG color={color} />
        },
        {
            value: roleActive,
            name: t('setting_sidebar_roles'),
            path: `${path}/roles`,
            icon: color => <SettingRoleSVG color={color} />
        },
        {
            value: memberActive,
            name: t('global_members'),
            path: `${path}/members`,
            icon: color => <SettingMemberSVG color={color} />
        },
        ...(isExternal && currentUser?.ldManager
            ? [
                  {
                      value: licenseActive,
                      name: t('license'),
                      path: `${path}/license`,
                      icon: color => <LicenseSVG color={color} />
                  }
              ]
            : []),
        ...(!isExternal
            ? [
                  {
                      value: paymentActive,
                      name: t('setting_sidebar_billings'),
                      path: `${path}/billing`,
                      icon: color => <SettingBillingSVG color={color} />
                  }
              ]
            : []),
        {
            value: statisticActive,
            name: t('setting_sidebar_statistics'),
            path: `${path}/statistics`,
            icon: color => <SettingStatisticSVG color={color} />
        },
        {
            value: codingActive,
            name: t('setting_sidebar_apikeys'),
            path: `${path}/api-keys`,
            icon: color => <CodeSVG color={color} width={20} height={20} />
        },
        {
            value: securityActive,
            name: t('security'),
            path: `${path}/security`,
            icon: color => <ShieldSVG color={color} width={20} height={20} />
        }
    ];

    const getPermission = value => {
        if (isEqual(value, infoActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_COMPANY;
        }

        if (isEqual(value, groupActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP;
        }

        if (isEqual(value, roleActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_ROLE;
        }

        if (isEqual(value, memberActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_MEMBER;
        }

        if (isEqual(value, paymentActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING;
        }

        if (isEqual(value, statisticActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_STATISTICS;
        }

        if (isEqual(value, codingActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_API_KEY;
        }

        if (isEqual(value, securityActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_COMPANY;
        }

        return null;
    };

    useEffect(() => {
        dispatch(
            appActions.fetchCompanyInfo({
                successCallback: () => {
                    console.log('[SideBar] fetch company info successfully');
                },
                errorCallback: () => {
                    console.log('[SideBar] failed to fetch company info');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid
            container
            className={classes.root}
            style={{
                marginTop
            }}
            direction="column"
            wrap="nowrap"
        >
            <Grid item className={classes.settings}>
                <Typography variant="h4" style={{ fontWeight: 'normal' }}>
                    {t('settings_routes_title')}
                </Typography>
            </Grid>
            <Grid item className={classes.menuItems}>
                <AutoSizer>
                    {({ width, height }) => {
                        return (
                            <Grid
                                style={{ width, height, overflow: 'hidden auto' }}
                                wrap="nowrap"
                                container
                                direction="column"
                            >
                                {settings.map(setting => {
                                    const viewPermission = getPermission(setting.value);
                                    return (
                                        <AccessControl
                                            key={setting.value}
                                            view={viewPermission}
                                            bypass={setting.value === licenseActive}
                                        >
                                            <Grid item>
                                                <NavLink className={classes.item} to={setting.path}>
                                                    <Grid container spacing={2} direction="row" alignItems="center">
                                                        <Grid item style={{ display: 'flex' }}>
                                                            {setting?.icon(theme.colors.lightGreyBlue)}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant={'body2'}>{setting.name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </NavLink>
                                            </Grid>
                                        </AccessControl>
                                    );
                                })}
                            </Grid>
                        );
                    }}
                </AutoSizer>
            </Grid>
            <InviteMembers />
        </Grid>
    );
}

export default React.memo(PermissionSidebar);
