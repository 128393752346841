import React from 'react';

function BranchTreeSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="113" height="62" viewBox="0 0 113 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M111.5 50C111.5 52.4853 109.485 54.5 107 54.5C104.515 54.5 102.5 52.4853 102.5 50C102.5 47.5147 104.515 45.5 107 45.5C109.485 45.5 111.5 47.5147 111.5 50Z"
                fill="white"
                stroke="#7869B9"
                strokeWidth="3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.1519 11.906C70.1519 11.9049 70.152 11.904 71.9995 12C73.847 12.0959 73.847 12.0953 73.8471 12.0948L73.847 12.0968L73.8463 12.1115L73.8434 12.1813C73.8409 12.2455 73.8376 12.3446 73.8344 12.4764C73.8282 12.7399 73.8228 13.1336 73.827 13.6388C73.8354 14.6498 73.8825 16.1037 74.0376 17.852C74.3489 21.3593 75.0909 25.9981 76.7971 30.6078C78.5037 35.2185 81.1431 39.716 85.1992 43.0563C89.2255 46.3721 94.7699 48.65 102.499 48.65V52.35C93.9793 52.35 87.5863 49.8153 82.847 45.9124C78.1375 42.0339 75.1831 36.9065 73.3272 31.8921C71.4708 26.8768 70.6815 21.8906 70.3521 18.1791C70.1869 16.3181 70.1363 14.7642 70.1271 13.6697C70.1225 13.1222 70.1283 12.6887 70.1355 12.3883C70.1391 12.2381 70.143 12.1211 70.1461 12.0396C70.1477 11.9988 70.149 11.967 70.1501 11.9443L70.1513 11.9172L70.1517 11.9088L70.1519 11.906Z"
                fill="#7869B9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72 6C75.3137 6 78 8.68629 78 12C78 15.3137 75.3137 18 72 18C69.7795 18 67.8408 16.7938 66.8033 15.001L11.6583 15.0007C10.8345 17.3307 8.61218 19 6 19C2.68629 19 0 16.3137 0 13C0 9.68629 2.68629 7 6 7C8.61256 7 10.8351 8.66977 11.6587 11.0003L66.0829 11.0002C66.5589 8.16238 69.0269 6 72 6Z"
                fill="#7A758F"
            />
        </svg>
    );
}

export default React.memo(BranchTreeSVG);
