import React from 'react';

function CodingSVG({ color = '#ACADB9', size = 18, ...other }) {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M3 3.07692H1.13563V14.9231H3V16H0V2H3V3.07692Z"
                    fill={color}
                    stroke={color}
                    strokeWidth="0.2"
                />
                <path
                    d="M15 3.07692H16.8644V14.9231H15V16H18V2H15V3.07692Z"
                    fill={color}
                    stroke={color}
                    strokeWidth="0.2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.92364 5.83325H4.79282L2.42578 11.9333H3.82097L4.30437 10.5813H6.40836L6.89585 11.9333H8.29498L5.92364 5.83325ZM11.0423 5.83325H8.61371V11.9333H9.9437V9.77577H11.03C11.7416 9.77577 12.3158 9.61295 12.7331 9.26784C13.1572 8.91943 13.3644 8.43232 13.3644 7.82645C13.3644 7.23158 13.1517 6.74301 12.7235 6.3753C12.2993 6.00867 11.7333 5.83325 11.0423 5.83325ZM15.3676 5.83325H14.0417V11.9333H15.3676V5.83325ZM9.9437 8.67267V6.94034H11.0698C11.3863 6.94543 11.618 7.03463 11.7812 7.19129C11.9438 7.34522 12.0303 7.55406 12.0303 7.83442C12.0303 8.12176 11.9444 8.32167 11.7898 8.45753C11.6321 8.59346 11.3906 8.67267 11.0423 8.67267H9.9437ZM6.01199 9.47425H4.70044L5.35622 7.64685L6.01199 9.47425Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip_code_0">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(CodingSVG);
