import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { GRID_PALETTE, OPTION_PALETTE } from 'const/paletteTypes';
import hexToRgba from 'hex-to-rgba';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { SELECTION_OPACITY } from 'const/gridUI';

function getOpacityByType(type) {
    switch (type) {
        case GRID_PALETTE:
            return { strokeOpacity: 1, colorOpacity: 0.8 };
        case OPTION_PALETTE:
            return { strokeOpacity: SELECTION_OPACITY.BACKGROUND, colorOpacity: SELECTION_OPACITY.BORDER };
        default:
            return { strokeOpacity: 1, colorOpacity: 0.8 };
    }
}

function Palette({ value, options, onSelect, type, ...other }) {
    const { strokeOpacity, colorOpacity } = getOpacityByType(type);
    const theme = useTheme();

    const handleClick = (e, color) => {
        e.stopPropagation();
        onSelect && onSelect(color);
    };

    return (
        <div className="p-2.5 grid grid-cols-6 gap-2">
            {options.map(color => {
                return (
                    <div
                        key={color.id || color.value}
                        className={'w-7 h-7 rounded-full cursor-pointer flex items-center justify-center'}
                        style={{
                            background: hexToRgba(color.value, colorOpacity),
                            border: `1px solid ${hexToRgba(color.value, strokeOpacity)}`
                        }}
                        onClick={e => handleClick(e, color)}
                    >
                        {(color.id === value || color.value === value) && (
                            <CheckIconSVG color={type === OPTION_PALETTE ? theme.colors.steel : theme.colors.white} />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default React.memo(Palette);
