import React from 'react';
import LDBasePortalServer from 'components/selects/LDBasePortalServer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { get1GridColumnRecordsApi } from 'services/grid';
import { useSelector } from 'react-redux';
import { OPTION_SPACING } from 'const/style';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { formatReferenceOptionByType, getReferenceOperatorAndValue } from 'utils/gridUI/reference';

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        minWidth: 200
    },
    option: {
        '&:not(:last-child)': {
            marginRight: OPTION_SPACING
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    imageItem: {
        borderRadius: 3,
        '&:not(:last-child)': {
            marginRight: OPTION_SPACING
        },
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    fullWidth: {
        width: '100%'
    }
}));

function OptionSelection({ column, defaultValue, handleOptionChange, isMulti = false, disabled, t }) {
    const theme = useTheme();
    const classes = useStyles();
    const { referenceSettings, referencedColumnType } = column;
    const referencedBranchId = referenceSettings?.referencedBranchId;
    const referencedColumnId = referenceSettings?.referencedColumnId;
    const dbId = useSelector(state => state.gridUI.dbId);

    const serverFunction = React.useCallback(
        async inputValue => {
            const serverBody = {
                dbId,
                limit: 50,
                offset: 0,
                gridId: referencedBranchId,
                columnIds: [referencedColumnId]
            };

            if (inputValue) {
                const { operator, value } = getReferenceOperatorAndValue({
                    inputValue,
                    columnType: referencedColumnType
                });

                serverBody.filterValue = { [referencedColumnId]: { [operator]: value } };
            }

            const response = await get1GridColumnRecordsApi(serverBody);

            const options = response?.recordIds.map(recordId => {
                const recordData = getCellValueOnly({
                    data: response?.data,
                    columnId: referencedColumnId,
                    rowId: recordId
                });
                return {
                    label: recordData,
                    value: recordId,
                    originLabel: recordData
                };
            });

            const optionsFormatted = formatReferenceOptionByType({
                options,
                referencedColumnType,
                theme,
                classes,
                dbId
            });
            return optionsFormatted;
        },
        [referencedColumnId, dbId, referencedBranchId, referencedColumnType, theme, classes]
    );

    return (
        <LDBasePortalServer
            ddPlaceholder={t(`global_label_select_an_option`)}
            menuPlaceholder={t(`global_label_find_an_option`)}
            onChange={handleOptionChange}
            defaultValue={defaultValue}
            selectedColor={theme.colors.secondaryText}
            isMulti={isMulti}
            serverFunction={serverFunction}
            isCanClear={false}
            isDisabled={disabled}
            dropdownClassName={classes.fullWidth}
        />
    );
}

export default React.memo(OptionSelection);
