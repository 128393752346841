import React from 'react';

function WelcomePlaySVG({ ...other }) {
    return (
        <svg {...other} width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M33.5 0C17.2327 0 4 13.234 4 29.5C4 45.766 17.2327 59 33.5 59C49.7673 59 63 45.766 63 29.5C63 13.234 49.7673 0 33.5 0ZM45.2275 30.5335L28.0192 41.596C27.8176 41.7268 27.5847 41.7917 27.3542 41.7917C27.1525 41.7917 26.9485 41.7412 26.766 41.6417C26.3699 41.4256 26.125 41.0126 26.125 40.5625V18.4375C26.125 17.9874 26.3699 17.5744 26.766 17.3583C27.155 17.1447 27.6423 17.1578 28.0192 17.404L45.2275 28.4665C45.5781 28.6921 45.7917 29.0823 45.7917 29.5C45.7917 29.9177 45.5781 30.3078 45.2275 30.5335Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="67"
                    height="67"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

export default React.memo(WelcomePlaySVG);
