import React from "react";

function ChatSVG({ color = "#A69FC4", ...other }) {
    return <svg {...other} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill={color} fillRule="evenodd">
            <path d="M3.477 14.032a.763.763 0 0 0 .048-.47.825.825 0 0 0-.198-.452 6.921 6.921 0 0 1 .291-9.467 6.866 6.866 0 0 1 4.889-2.021 6.86 6.86 0 0 1 4.885 2.021 6.86 6.86 0 0 1 2.025 4.887 6.862 6.862 0 0 1-2.021 4.887 6.915 6.915 0 0 1-8.387 1.078.807.807 0 0 0-.96.102l-.03.022c-.443.344-1.885 1.12-2.283 1.237.338-.426 1.579-1.453 1.741-1.824M14.539 2.5A8.471 8.471 0 0 0 8.51 0C6.23 0 4.089.887 2.478 2.5c-3.03 3.028-3.31 7.944-.682 11.292-.227.406-.574.871-1.056 1.108-.437.216-.68.683-.605 1.162.075.48.446.854.925.929.585.09 2.049.191 3.605-.846 1.185.6 2.509.916 3.84.916a8.48 8.48 0 0 0 6.037-2.5 8.475 8.475 0 0 0 2.5-6.031A8.472 8.472 0 0 0 14.54 2.5"/>
            <path d="M5.75 8.53a1.066 1.066 0 1 1-2.13 0 1.066 1.066 0 0 1 2.13 0M9.587 8.53a1.065 1.065 0 1 1-2.13.002 1.065 1.065 0 0 1 2.13-.002M13.423 8.53a1.065 1.065 0 1 1-2.13.002 1.065 1.065 0 0 1 2.13-.002"/>
        </g>
    </svg>
}

export default React.memo(ChatSVG);
