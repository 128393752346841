import React from 'react';

function RowHeightSVG({ color = '#6E5BBE', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <mask id="row-height-toolbar" fill="white">
                <rect width="9" height="18" rx="1" />
            </mask>
            <rect width="9" height="18" rx="1" stroke={color} strokeWidth="3.2" mask="url(#row-height-toolbar)" />
            <rect x="10" width="2" height="1" fill={color} />
            <rect x="10" y="17" width="2" height="1" fill={color} />
            <rect x="13" width="2" height="1" fill={color} />
            <rect x="13" y="17" width="2" height="1" fill={color} />
            <rect x="16" width="2" height="1" fill={color} />
            <rect x="16" y="17" width="2" height="1" fill={color} />
            <path
                d="M11.6094 11.6362L10.7998 12.4429L13.709 15.355L13.7319 15.375L13.7548 15.355L16.6669 12.4429L15.8574 11.6362L14.3041 13.1866V5.18265L15.8574 6.7331L16.6669 5.92354L13.7405 3H13.7233L10.7998 5.92354L11.6094 6.7331L13.1598 5.18265V13.1866L11.6094 11.6362Z"
                fill={color}
                stroke={color}
                strokeWidth="0.3"
            />
        </svg>
    );
}

export default React.memo(RowHeightSVG);
