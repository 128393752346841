import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { CircularProgress, Collapse, Drawer, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AutomationsEmptySVG from 'assets/images/svg/webHook/AutomationsEmptySVG';
import ButtonBase from 'components/button/Base';
import SidebarRightLayout from 'layout/rightSidebar/SidebarRightLayout';
import AutomationItem from './AutomationItem';
import AutomationSuggestedItem from './AutomationSuggestedItem';
import AutomationDetail from './details';
import { HEADER_HEIGHT, WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import {
    getListAutomations,
    setAutomationDetail,
    setOpenAutomationDetail,
    updateAutomation,
    setAutomations,
    setIsFetchingAutomations,
    createAutomationWithEvents
} from './action';
import { useDispatch } from 'react-redux';
import Spinner from 'components/spinner/Base';
import {
    useGetAutomations,
    useGetIsFetchingAutomations,
    useIsShowAutomationExecutions,
    useOpenAutomationDetail
} from 'hooks/gridUI/automation';
import { WEB_HOOK_TRIGGER_ACTIONS } from 'const/gridUI';
import { useGrid } from 'hooks/gridUI';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { DISABLED_OPACITY } from 'const/style';
import AutomationExecutions from './AutomationExecutions';
import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized-dn';
import { List } from 'react-virtualized-dn';
import ResizeObserver from 'rc-resize-observer';
import { useIsShareViewLink } from 'hooks/app';
import Dialog from 'components/dialog/Dialog';
import AutomationAddForm from './AutomationAddForm';
import { uploadAutomationLogoApi } from 'services/automation';
import { enqueueSnackbar } from 'notifier/actions';

const useStyles = makeStyles(theme => ({
    fullHeight: {
        height: '100%'
    },
    fullWidth: {
        width: '100%',
        minWidth: 'unset'
    },
    flx: {
        flex: 1
    },
    contentClassName: {
        padding: 0
    },
    top: {
        background: theme.colors.ghostwhite
    },
    text: {
        textAlign: 'center',
        lineHeight: '17.6px',
        marginBottom: 10,
        padding: '0 24px'
    },
    addBtn: {
        position: 'relative',
        margin: '0 0 20px',
        padding: '0 20px'
    },
    suggestion: {
        padding: '20px 0',
        overflow: 'hidden auto'
    },
    listAutomations: {
        background: theme.colors.white,
        borderBottom: `1px solid ${theme.colors.paleGrey}`,
        marginBottom: 12
    },
    fullListAutomations: {
        maxHeight: 'none'
    },
    suggested: {
        padding: '0 24px',
        marginBottom: 13
    },
    drawer: {
        top: `${HEADER_HEIGHT}px !important`,
        zIndex: `999 !important`
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    disabledSuggesstions: {
        pointerEvents: 'none'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    executions: {
        padding: 14,
        maxHeight: 48,
        textAlign: 'center',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    virtualizeList: {
        outline: 'none',
        background: theme.colors.white,
        '& > .ReactVirtualized__Grid__innerScrollContainer': {
            overflowX: 'hidden !important'
        }
    },
    mt12: {
        marginTop: 12
    }
}));

const DEFAULT_ROW_HEIGHT = 72;

const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: DEFAULT_ROW_HEIGHT
});

const Automations = ({ handleClose, workspaceId, gridId, viewId, dbId, branchId, t }) => {
    const isShareViewLink = useIsShareViewLink();
    const isShowListAutomationExecutions = useIsShowAutomationExecutions();
    const classes = useStyles({ isShowListAutomationExecutions });
    const dispatch = useDispatch();
    const openDetail = useOpenAutomationDetail();
    const [isAdding, setIsAdding] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newAutomationEvents, setNewAutomationEvents] = useState(null);
    const listRef = useRef();
    const automations = useGetAutomations();
    const isFetchingAutomations = useGetIsFetchingAutomations();
    const currentGrid = useGrid({ dbId, branchId });

    const suggestions = useMemo(() => {
        return [
            {
                id: 'ticket-created-slack',
                title: 'Send a Slack message when a ticket is created',
                description: `When a ticket is created in ${currentGrid?.name}, send a Slack message`,
                events: [WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED, WEB_HOOK_EXTERNAL_SYSTEMS.SLACK]
            },
            {
                id: 'record-updated-slack',
                title: 'Send a Slack message when a record is updated',
                description: `When a record is updated in ${currentGrid?.name}, send a Slack message`,
                events: [WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED, WEB_HOOK_EXTERNAL_SYSTEMS.SLACK]
            },
            {
                id: 'record-updated-web',
                title: 'Trigger a webhook when a record is updated',
                description: `When a record is updated in ${currentGrid?.name}, trigger a webhook`,
                events: [WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED, WEB_HOOK_EXTERNAL_SYSTEMS.HTTP]
            }
        ];
    }, [currentGrid]);

    const fetchList = useCallback(() => {
        dispatch(setOpenAutomationDetail(false));
        dispatch(setIsFetchingAutomations(true));
        dispatch(
            getListAutomations({
                body: { dbId, gridId: branchId, viewId },
                successCallback: data => {
                    dispatch(setAutomations(data));
                    dispatch(setIsFetchingAutomations(false));
                },
                errorCallback: () => {
                    dispatch(setIsFetchingAutomations(false));
                }
            })
        );
    }, [dbId, dispatch, branchId, viewId]);

    useEffect(() => {
        dispatch(setOpenAutomationDetail(false));
    }, [dbId, dispatch, branchId, viewId]);

    const isEmpty = useMemo(() => {
        return !automations.length;
    }, [automations]);

    const handleClickItem = useCallback(
        item => {
            dispatch(setAutomationDetail(item));
            dispatch(setOpenAutomationDetail(true));
        },
        [dispatch]
    );

    const successCloneCallback = useCallback(
        newAutomation => {
            dispatch(setAutomations([...automations, newAutomation]));
        },
        [automations, dispatch]
    );

    const successDeleteCallback = useCallback(
        automation => {
            const idx = automations.findIndex(el => el.id === automation.id);
            if (idx > -1) {
                automations.splice(idx, 1);
                dispatch(setAutomations([...automations]));
            }
        },
        [automations, dispatch]
    );

    const onChangeTitle = useCallback(
        ({ automationId, title }) => {
            const oldAutomations = JSON.parse(JSON.stringify(automations));
            const idx = automations.findIndex(el => el.id === automationId);
            if (idx > -1) {
                automations[idx].title = title;
                dispatch(setAutomations([...automations]));
            }
            dispatch(
                updateAutomation({
                    id: automationId,
                    body: {
                        title
                    },
                    errorCallback: () => {
                        dispatch(setAutomations(oldAutomations));
                    }
                })
            );
        },
        [automations, dispatch]
    );

    const onChangeStatus = useCallback(
        ({ automationId, status }) => {
            const oldAutomations = JSON.parse(JSON.stringify(automations));
            const idx = automations.findIndex(el => el.id === automationId);
            if (idx > -1) {
                automations[idx].status = status;
                dispatch(setAutomations([...automations]));
            }
            dispatch(
                updateAutomation({
                    id: automationId,
                    body: {
                        status
                    },
                    errorCallback: () => {
                        dispatch(setAutomations(oldAutomations));
                    }
                })
            );
        },
        [automations, dispatch]
    );

    const onChangeDescription = useCallback(
        ({ automationId, description, onSuccess, onError }) => {
            const oldAutomations = JSON.parse(JSON.stringify(automations));
            const idx = automations.findIndex(el => el.id === automationId);
            if (idx > -1) {
                automations[idx].description = description;
                dispatch(setAutomations([...automations]));
            }
            dispatch(
                updateAutomation({
                    id: automationId,
                    body: {
                        description
                    },
                    successCallback: () => {
                        onSuccess();
                    },
                    errorCallback: () => {
                        dispatch(setAutomations(oldAutomations));
                        onError();
                    }
                })
            );
        },
        [automations, dispatch]
    );

    const onChangeImage = useCallback(
        newAutomationItem => {
            const idx = automations.findIndex(el => el.id === newAutomationItem.id);
            if (idx > -1) {
                automations[idx].iconFileId = newAutomationItem.iconFileId;
                dispatch(setAutomations([...automations]));
            }
        },
        [automations, dispatch]
    );

    const handleAddAutomation = useCallback(() => {
        if (isAdding || isShareViewLink) return;
        setNewAutomationEvents(null);
        setOpenAddDialog(true);
    }, [isAdding, isShareViewLink]);

    const handleAddSuggestion = useCallback(
        ({ events }) => {
            if (isAdding || isShareViewLink) return;
            setNewAutomationEvents(events);
            setOpenAddDialog(true);
        },
        [isAdding, isShareViewLink]
    );

    const handleCloseDetail = useCallback(() => {
        fetchList();
        setAutomationDetail({});
    }, [fetchList]);

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style, parent }) => {
            const item = automations?.[index];
            return (
                <CellMeasurer cache={_cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                    {({ measure }) => (
                        <Grid item style={{ ...style }}>
                            <ResizeObserver onResize={measure}>
                                <AutomationItem
                                    key={item.id}
                                    automation={item}
                                    isFirst={index === 0}
                                    onClick={handleClickItem}
                                    onChangeTitle={onChangeTitle}
                                    onChangeStatus={onChangeStatus}
                                    onChangeDescription={onChangeDescription}
                                    onChangeImage={onChangeImage}
                                    successCloneCallback={successCloneCallback}
                                    successDeleteCallback={successDeleteCallback}
                                />
                            </ResizeObserver>
                        </Grid>
                    )}
                </CellMeasurer>
            );
        },
        [
            automations,
            handleClickItem,
            onChangeTitle,
            onChangeStatus,
            onChangeDescription,
            onChangeImage,
            successCloneCallback,
            successDeleteCallback
        ]
    );

    const _getRowHeight = useCallback(({ index }) => {
        return _cache.rowHeight({ index });
    }, []);

    const handleCloseAddDialog = useCallback(() => {
        setNewAutomationEvents(null);
        setOpenAddDialog(false);
    }, []);

    const handleSave = useCallback(
        newAutomation => {
            setIsAdding(true);
            dispatch(
                createAutomationWithEvents({
                    body: {
                        projectId: Number(workspaceId),
                        dbId,
                        gridId: branchId,
                        viewId,
                        title: newAutomation.title,
                        description: newAutomation.description
                    },
                    events: newAutomationEvents,
                    successCallback: async data => {
                        if (newAutomation.file) {
                            const formData = new FormData();
                            formData.append('file', newAutomation.file);
                            formData.append('automationId', data.id);
                            try {
                                const responseData = await uploadAutomationLogoApi({ formData });
                                setIsAdding(false);
                                handleCloseAddDialog();
                                dispatch(setAutomationDetail(responseData));
                                dispatch(setOpenAutomationDetail(true));
                                dispatch(setAutomations([...automations, responseData]));
                                setTimeout(() => {
                                    const el = listRef?.current;
                                    if (el) {
                                        el.scrollToRow(automations.length);
                                    }
                                }, 100);
                            } catch (error) {
                                setIsAdding(false);
                                handleCloseAddDialog();
                                const { message } = error;
                                dispatch(
                                    enqueueSnackbar({
                                        type: 'info',
                                        message
                                    })
                                );
                            }
                        } else {
                            setIsAdding(false);
                            handleCloseAddDialog();
                            dispatch(setAutomationDetail(data));
                            dispatch(setOpenAutomationDetail(true));
                            dispatch(setAutomations([...automations, data]));
                            setTimeout(() => {
                                const el = listRef?.current;
                                if (el) {
                                    el.scrollToRow(automations.length);
                                }
                            }, 100);
                        }
                    },
                    errorCallback: () => {
                        setIsAdding(false);
                    }
                })
            );
        },
        [automations, branchId, dbId, dispatch, handleCloseAddDialog, newAutomationEvents, viewId, workspaceId]
    );

    return (
        <SidebarRightLayout
            title={t('right_toolbar_trigger_automations')}
            onClose={handleClose}
            contentClassName={classes.contentClassName}
        >
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_TRIGGER}>
                {({ isReadOnly }) => (
                    <Grid
                        container
                        direction="column"
                        className={classnames(classes.fullHeight, {
                            [classes.disabled]: isReadOnly
                        })}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <Collapse in={isShowListAutomationExecutions}>
                                <Grid item className={classes.executions}>
                                    <AutomationExecutions />
                                </Grid>
                            </Collapse>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="column"
                            className={classnames(classes.flx, classes.top)}
                            alignItems="center"
                            wrap="nowrap"
                        >
                            <Grid item className={classnames(classes.flx, classes.fullWidth)}>
                                <AutoSizer>
                                    {({ width, height }) => {
                                        return isFetchingAutomations && isEmpty ? (
                                            <Grid
                                                className={classes.listAutomations}
                                                style={{ width, height }}
                                                item
                                                container
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                wrap="nowrap"
                                            >
                                                <Spinner size={18} thick={3} />
                                            </Grid>
                                        ) : isEmpty ? (
                                            <div style={{ width, height }} className="flex flex-col flex-nowrap">
                                                <div className="flex-1 items-center justify-center flex">
                                                    <AutomationsEmptySVG />
                                                </div>
                                                <p className="body2 text-center mb-2.5 py-0 px-6">
                                                    Add a Trigger and let Gridly automatically perform your most common
                                                    tasks.
                                                </p>
                                            </div>
                                        ) : (
                                            <List
                                                ref={listRef}
                                                className={classes.virtualizeList}
                                                rowCount={automations?.length}
                                                overscanRowCount={5}
                                                rowHeight={_getRowHeight}
                                                width={width}
                                                height={height}
                                                rowRenderer={_rowRenderer}
                                            />
                                        );
                                    }}
                                </AutoSizer>
                            </Grid>
                            {!isShareViewLink && (
                                <Grid item className={classnames(classes.fullWidth, classes.addBtn, classes.mt12)}>
                                    <ButtonBase
                                        variant="contained"
                                        className={classes.fullWidth}
                                        disabled={isAdding || isShareViewLink}
                                        onClick={handleAddAutomation}
                                    >
                                        + Add Automation
                                    </ButtonBase>
                                    {isAdding && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Grid>
                            )}
                        </Grid>

                        {!isShareViewLink && !isFetchingAutomations && isEmpty && (
                            <Grid
                                item
                                container
                                direction="column"
                                wrap="nowrap"
                                className={classnames(classes.flx, classes.suggestion, {
                                    [classes.disabled]: isShareViewLink
                                })}
                            >
                                <Grid item className={classes.suggested}>
                                    <p>Suggested for you:</p>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    wrap="nowrap"
                                    className={classnames({
                                        [classes.disabledSuggesstions]: isAdding
                                    })}
                                >
                                    {suggestions.map(suggestion => (
                                        <AutomationSuggestedItem
                                            key={suggestion.id}
                                            id={suggestion.id}
                                            title={suggestion.title}
                                            description={suggestion.description}
                                            events={suggestion.events}
                                            onClick={() => handleAddSuggestion(suggestion)}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
            </AccessControl>
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={openDetail}
                PaperProps={{
                    style: {
                        top: HEADER_HEIGHT,
                        height: `calc(100% - ${HEADER_HEIGHT}px)`,
                        width: `100%`,
                        overflowY: 'unset'
                    }
                }}
                hideBackdrop
            >
                {openDetail && <AutomationDetail onClose={handleCloseDetail} />}
            </Drawer>
            <Dialog maxWidth="md" open={openAddDialog} onClose={handleCloseAddDialog}>
                <AutomationAddForm onSave={handleSave} onCancel={handleCloseAddDialog} isSaving={isAdding} />
            </Dialog>
        </SidebarRightLayout>
    );
};

export default React.memo(Automations);
