import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useTheme } from '@material-ui/core/styles';
import { MAX_OPTIONS } from 'const/gridUI';

import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import Option from './common/Option';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function LDSelectBase({ defaultValue, selectedColor, options, maxSize = MAX_OPTIONS, searchKey = '', ...other }) {
    const theme = useTheme();
    const selectRef = useRef();
    const [inputValue, setInputValue] = useState(searchKey);

    useEffect(() => {
        setInputValue(searchKey);
    }, [searchKey]);

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        }),
        menu: styles => ({
            ...styles,
            zIndex: 999
        })
    };

    const filterOptions = useCallback(
        inputValue => {
            let newOptions = [...options];
            const filterValues = newOptions.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
            return filterValues.slice(0, maxSize);
        },
        [maxSize, options]
    );

    const loadOptions = useCallback(
        (inputValue, callback) => {
            setTimeout(() => {
                callback(filterOptions(inputValue));
            }, 300);
        },
        [filterOptions]
    );

    const getDefaultOptions = useMemo(() => {
        return options.slice(0, maxSize);
    }, [maxSize, options]);

    useEffect(() => {
        const { select } = selectRef.current;
        const { onInputChange } = select;
        onInputChange(inputValue);
    }, [inputValue]);

    return (
        <AsyncSelect
            ref={selectRef}
            autoFocus
            cacheOptions
            inputValue={inputValue}
            defaultInputValue={inputValue}
            onInputChange={newInput => setInputValue(newInput)}
            defaultOptions={getDefaultOptions}
            loadOptions={loadOptions}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            tabSelectsValue={false}
            components={components}
            value={defaultValue}
            defaultValue={defaultValue}
            styles={selectStyles}
            menuIsOpen
            {...other}
        />
    );
}

LDSelectBase.propTypes = {
    ddPlaceholder: PropTypes.string,
    menuPlaceholder: PropTypes.string,
    isMulti: PropTypes.bool,
    maxSize: PropTypes.number,
    searchKey: PropTypes.string
};

export default React.memo(LDSelectBase);
