import React from 'react';

function InfoIconSVG({ color = '#78778B', size = 20, ...other }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99935 2.41797C5.81119 2.41797 2.41602 5.81314 2.41602 10.0013C2.41602 14.1895 5.81119 17.5846 9.99935 17.5846C14.1875 17.5846 17.5827 14.1895 17.5827 10.0013C17.5827 5.81314 14.1875 2.41797 9.99935 2.41797ZM0.916016 10.0013C0.916016 4.98472 4.98276 0.917969 9.99935 0.917969C15.0159 0.917969 19.0827 4.98472 19.0827 10.0013C19.0827 15.0179 15.0159 19.0846 9.99935 19.0846C4.98276 19.0846 0.916016 15.0179 0.916016 10.0013ZM9.99951 9.00131C10.5518 9.00131 10.9995 9.44903 10.9995 10.0013V13.3346C10.9995 13.8869 10.5518 14.3346 9.99951 14.3346C9.44723 14.3346 8.99951 13.8869 8.99951 13.3346V10.0013C8.99951 9.44903 9.44723 9.00131 9.99951 9.00131ZM9.99951 5.66797C9.44723 5.66797 8.99951 6.11568 8.99951 6.66797C8.99951 7.22025 9.44723 7.66797 9.99951 7.66797H10.0095C10.5618 7.66797 11.0095 7.22025 11.0095 6.66797C11.0095 6.11568 10.5618 5.66797 10.0095 5.66797H9.99951Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(InfoIconSVG);
