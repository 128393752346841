import * as types from './types';
import createReducer from 'utils/createReducer';
import produce from 'immer';

const INITIAL_STATE = {
    connections: [],
    connectionsLoading: true,
    sources: [],
    sourcesLoading: true,
    sourceInfoUrl: null,
    sheetsBySourceId: {},
    syncHistoriesByCId: {},
    connectionsBySourceId: {},
    connectionsByDestinationGridId: {},
    syncHistoriesByDestinationId: {}
};

const integrationHandler = {
    [types.SET_CONNECTIONS_LOADING](state, { payload }) {
        return {
            ...state,
            connectionsLoading: payload
        };
    },
    [types.FETCH_CONNECTIONS_SUCCESS](state, { payload }) {
        const { connections } = payload;
        return {
            ...state,
            connections,
            connectionsLoading: false
        };
    },
    [types.DELETE_CONNECTION](state, { payload }) {
        const { cId } = payload;
        return {
            ...state,
            connections: state?.connections?.filter(c => c?.id !== cId)
        };
    },
    [types.DELETE_CONNECTION_BY_SOURCE_ID](state, { payload }) {
        const { sId, cId } = payload;
        return {
            ...state,
            connectionsBySourceId: {
                ...state?.connectionsBySourceId,
                [sId]: state?.connectionsBySourceId?.[sId]?.filter(c => c?.id !== cId)
            }
        };
    },

    [types.CREATE_CONNECTION](state, { payload }) {
        const { connection } = payload;
        return {
            ...state,
            connections: [connection, ...state?.connections]
        };
    },
    [types.UPDATE_CONNECTION](state, { payload }) {
        const { cId, connection } = payload;
        return {
            ...state,
            connections: state?.connections?.map(c => {
                if (c?.id === cId) {
                    return connection;
                }
                return c;
            })
        };
    },

    [types.SET_CONNECTION_SYNC](state, { payload }) {
        if (payload?.sId) {
            return {
                ...state,
                connections: state?.connections?.map(c => {
                    if (c?.id === payload?.cId) {
                        c.task = payload;
                    }
                    return c;
                }),
                connectionsBySourceId: produce(state?.connectionsBySourceId, draft => {
                    draft[payload?.sId] = draft?.[payload?.sId]?.map(c => {
                        if (c?.id === payload?.cId) {
                            c.task = payload;
                        }
                        return c;
                    });

                    return draft;
                })
            };
        }

        return {
            ...state,
            connections: state?.connections?.map(c => {
                if (c?.id === payload?.cId) {
                    c.task = payload;
                }
                return c;
            })
        };
    },
    [types.CLEAR_CONNECTION_SYNC](state, { payload }) {
        if (payload?.sId) {
            return {
                ...state,
                connections: state?.connections?.map(c => {
                    if (c?.id === payload?.cId) {
                        c.task = null;
                    }
                    return c;
                }),
                connectionsBySourceId: {
                    ...state?.connectionsBySourceId,
                    [payload?.sId]: state?.connectionsBySourceId?.[payload?.sId]?.map(c => {
                        if (c?.id === payload?.cId) {
                            c.task = null;
                        }
                        return c;
                    })
                }
            };
        }

        return {
            ...state,
            connections: state?.connections?.map(c => {
                if (c?.id === payload?.cId) {
                    c.task = null;
                }
                return c;
            })
        };
    },

    [types.FETCH_CONNECTION_SUCCESS](state, { payload }) {
        const { connection } = payload;
        const connections = state?.connections;
        const isExisted = connections?.findIndex(c => c?.id === connection?.id) !== -1;

        return {
            ...state,
            connections: isExisted ? connections : [connection, ...connections]
        };
    },

    [types.FETCH_HISTORIES_BY_CONNECTION_ID](state, { payload }) {
        const { cId, histories } = payload;

        return {
            ...state,
            syncHistoriesByCId: {
                ...state?.syncHistoriesByCId,
                [cId]: histories
            }
        };
    },

    [types.SET_SOURCES_LOADING](state, { payload }) {
        return {
            ...state,
            sourcesLoading: payload
        };
    },

    [types.FETCH_SOURCES_SUCCESS](state, { payload }) {
        const { sources } = payload;
        return {
            ...state,
            sources,
            sourcesLoading: false
        };
    },
    [types.FETCH_SOURCE_SUCCESS](state, { payload }) {
        const { source } = payload;
        const sources = state?.sources;
        const isExisted = sources?.findIndex(s => s?.id === source?.id) !== -1;

        return {
            ...state,
            sources: isExisted ? sources : [source, ...sources]
        };
    },
    [types.DELETE_SOURCE](state, { payload }) {
        const { sId } = payload;
        return {
            ...state,
            sources: state?.sources?.filter(s => s?.id !== sId)
        };
    },
    [types.CREATE_SOURCE](state, { payload }) {
        const { source } = payload;
        return {
            ...state,
            sources: [source, ...state?.sources]
        };
    },
    [types.UPDATE_SOURCE](state, { payload }) {
        const { sId, source } = payload;
        return {
            ...state,
            sources: state?.sources?.map(s => {
                if (s?.id === sId) {
                    return source;
                }
                return s;
            })
        };
    },
    [types.SET_SOURCE_INFO_URL](state, { payload }) {
        const { url } = payload;
        return {
            ...state,
            sourceInfoUrl: url
        };
    },
    [types.FETCH_SHEET_BY_SOURCE_ID](state, { payload }) {
        const { sId, streams } = payload;
        return {
            ...state,
            sheetsBySourceId: {
                ...state.sheetsBySourceId,
                [sId]: streams
            }
        };
    },
    [types.FETCH_CONNECTIONS_BY_SOURCE_ID](state, { payload }) {
        const { sId, connections } = payload;
        return {
            ...state,
            connectionsBySourceId: {
                ...state.connectionsBySourceId,
                [sId]: connections
            }
        };
    },
    [types.FETCH_CONNECTIONS_BY_DESTINATION_GRID_ID](state, { payload }) {
        const { destinationGridId, connections } = payload;
        return {
            ...state,
            connectionsByDestinationGridId: {
                ...state.connectionsByDestinationGridId,
                [destinationGridId]: connections
            }
        };
    },

    [types.FETCH_HISTORIES_BY_DESTINATION_ID](state, { payload }) {
        const { dId, histories } = payload;
        return {
            ...state,
            syncHistoriesByDestinationId: {
                ...state?.syncHistoriesByDestinationId,
                [dId]: histories
            }
        };
    }
};

export default createReducer(INITIAL_STATE, Object.assign(integrationHandler));
