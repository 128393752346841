import { Grid, makeStyles } from '@material-ui/core';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { useTmSourceSelected, useTmTargetsSelected } from 'hooks/tm';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCurrentCompanyTMs, fetchTMSources, getTranslation } from 'tm/actions';
import { fetchWorkspaces } from 'workspaces/actions';
import EditorTmDetail from './EditorTmDetail';
import EditorTmToolbar from './EditorTmToolbar';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

function TabbleTmDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { tmId } = useParams();
    const sourceSeleced = useTmSourceSelected() || null;
    const targetsSeleced = useTmTargetsSelected() || [];
    const [searchValue, setSearchValue] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false);

    const limit = 10;
    const timerRef = React.useRef();

    React.useEffect(() => {
        dispatch(
            fetchCurrentCompanyTMs({
                successCallback: result => {},
                errorCallback: () => {}
            })
        );

        dispatch(
            fetchWorkspaces({
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            fetchTMSources({
                tmId,
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }, [dispatch, tmId]);

    const handleSearchChange = React.useCallback(e => {
        const value = e?.target?.value;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setCurrentPage(1);
            setSearchValue(value);
        }, DEBOUNCE_TIME_SEARCHING);
    }, []);

    const _fetchListTm = React.useCallback(() => {
        if (!sourceSeleced || targetsSeleced.length < 1) {
            return;
        }
        setIsFetching(true);
        dispatch(
            getTranslation({
                tmId,
                lang: sourceSeleced?.value,
                targetLangs: targetsSeleced?.map(t => t.value),
                offset: (currentPage - 1) * limit,
                limit,
                search: searchValue,
                successCallback: ({ data, headers }) => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [sourceSeleced, targetsSeleced, dispatch, tmId, currentPage, searchValue]);

    const handleResetFetch = React.useCallback(
        ({ resetPage, resetSelectedRows }) => {
            if (resetPage) {
                setCurrentPage(currentPage);
                _fetchListTm();
            }
            if (resetSelectedRows) {
                setSelectedRows([]);
                return;
            }
        },
        [_fetchListTm, currentPage]
    );

    const handleClearSearch = React.useCallback(() => {
        setSearchValue('');
    }, []);

    const handleChangePage = React.useCallback((e, page) => {
        setCurrentPage(page);
    }, []);

    React.useEffect(() => {
        _fetchListTm();
    }, [_fetchListTm, currentPage, dispatch, tmId]);

    return (
        <Grid item className={classes.root}>
            <EditorTmToolbar
                searchValue={searchValue}
                handleSearchChange={handleSearchChange}
                handleClearSearch={handleClearSearch}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onRestTable={handleResetFetch}
                source={sourceSeleced}
                targets={targetsSeleced}
                tmId={tmId}
            />

            <EditorTmDetail
                searchValue={searchValue}
                selectedRows={selectedRows}
                currentPage={currentPage}
                limit={limit}
                handleSearchChange={handleSearchChange}
                handleClearSearch={handleClearSearch}
                setSelectedRows={setSelectedRows}
                onRestTable={handleResetFetch}
                onChangePage={handleChangePage}
                isFetching={isFetching}
            />
        </Grid>
    );
}
export default React.memo(TabbleTmDetail);
