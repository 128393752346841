import { useSelector } from 'react-redux';
import * as columnTypes from 'const/columnTypes';
import { useQuickFilters } from './quickFilter';

export function useViewFilters() {
    return useSelector(state => state.gridUI.viewFilters);
}

export function useViewFilterList() {
    const viewFilters = useViewFilters();
    return viewFilters?.filter(filter => !filter.isDeleted);
}

export function useQuickFilterPathTag() {
    const quickFilters = useQuickFilters();
    return quickFilters?.[columnTypes?.PATH_TAG];
}

export function useIsHighlightFilter() {
    let viewFilters = useViewFilterList();
    return viewFilters?.length;
}
