import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';
import isArray from 'lodash/isArray';
import { diffStatusArrayInOrder } from 'utils/object';

function MultipleSelectionCheckDiff({
    previousData = [],
    isHideMarkIcon = false,
    data = [],
    options = [],
    customProperties = {},
    diffBefore
}) {
    const diffOptions = diffStatusArrayInOrder(previousData, data);

    if (!diffOptions || diffOptions?.length === 0 || !isArray(diffOptions)) return null;

    return (
        <Grid container spacing={1} style={{ height: 'fit-content' }}>
            {diffBefore &&
                diffOptions?.map((option, index) => {
                    const status = option?.status;
                    const value = option?.key;
                    return (
                        (status === 'normal' || status === 'delete') && (
                            <Grid item key={index}>
                                <Option
                                    data={value}
                                    isHideMarkIcon={isHideMarkIcon}
                                    state={status}
                                    options={options}
                                    customProperties={customProperties}
                                />
                            </Grid>
                        )
                    );
                })}
            {!diffBefore &&
                diffOptions?.map((option, index) => {
                    const status = option?.status;
                    const value = option?.key;
                    return (
                        (status === 'normal' || status === 'update') && (
                            <Grid item key={index}>
                                <Option
                                    data={value}
                                    isHideMarkIcon={isHideMarkIcon}
                                    state={status}
                                    options={options}
                                    customProperties={customProperties}
                                />
                            </Grid>
                        )
                    );
                })}
        </Grid>
    );
}

export default React.memo(MultipleSelectionCheckDiff);
