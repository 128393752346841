import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getTranslationDataApi } from 'services/view';
import { formatTranslationStatistic } from 'utils/gridUI/formatData';

export function fetchTranslationDashboard({ viewId, dbId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId: dbIdStore, defaultAccessViewId: viewIdStore } = gridUI;

        const viewIdCombined = viewId || viewIdStore;
        const dbIdCombined = dbId || dbIdStore;

        dispatch(_fetchTranslationDashboard({ viewId: viewIdCombined }));

        try {
            const data = await getTranslationDataApi({ dbId: dbIdCombined, viewId: viewIdCombined });
            const formatData = formatTranslationStatistic(data?.columnStatistics);

            dispatch(_fetchTranslationDashboardSuccess({ viewId: viewIdCombined, data: formatData }));

            successCallback && successCallback();
        } catch (error) {
            const { message, originalMessage } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            dispatch(_fetchTranslationDashboardFailed({ error: originalMessage, viewId: viewIdCombined }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchTranslationDashboard({ viewId }) {
    return {
        type: types.FETCH_TRANSLATION_DASHBOARD_DATA,
        payload: {
            viewId
        }
    };
}

function _fetchTranslationDashboardFailed({ error, viewId }) {
    return {
        type: types.FETCH_TRANSLATION_DASHBOARD_DATA_FAILED,
        payload: {
            error,
            viewId
        }
    };
}

function _fetchTranslationDashboardSuccess({ data, viewId }) {
    return {
        type: types.FETCH_TRANSLATION_DASHBOARD_DATA_SUCCESS,
        payload: {
            data,
            viewId
        }
    };
}
