import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import ErrorMessage from './ErrorMessage';
import Grid from '@material-ui/core/Grid';
import { useIsFetching } from 'hooks/permission';
import { useHistory, useLocation } from 'react-router-dom';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';
import { getSamlCompaniesApi } from 'services/company';
import { enqueueSnackbar } from 'notifier/actions';
import CompanyItem from 'permission/companies/CompanyItem';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    progress: {
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    addMoreCompany: {
        marginTop: 20,
        marginLeft: 14,
        cursor: 'pointer'
    },
    avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.8),
        borderRadius: '50%',
        width: 45,
        height: 45,
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        '& svg': {
            width: 20,
            height: 20,
            '& path': {
                fill: theme.colors.white
            }
        }
    },
    companyListWrapper: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 13,
        borderBottom: `1px solid ${theme.colors.border}`,
        maxHeight: 300,
        maxWidth: 300,
        overflow: 'auto'
    }
}));

function SamlSelectCompany() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const email = location.state?.email;
    const [samlCompanies, setSamlCompanies] = useState([]);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isFetchingCompanies = useIsFetching();

    useEffect(() => {
        if (!email) history.push('/saml');
        const fetchSamlCompanies = async () => {
            try {
                setIsSubmitting(true);
                const samlCompanies = await getSamlCompaniesApi(email);
                setSamlCompanies(samlCompanies);
                setIsSubmitting(false);
                if (samlCompanies.length === 0) {
                    setError('SSO may not be available or configured for this account.');
                }
            } catch (error) {
                setIsSubmitting(false);
                setError(error.message);
                dispatch(
                    enqueueSnackbar({
                        message: error.message,
                        type: 'error'
                    })
                );
            }
        };
        fetchSamlCompanies();
    }, [dispatch, history, email]);

    const handleCompanySelect = company => {
        window.location = company.setting.samlLoginUrl;
    };

    return (
        <AuthenticationWrapper>
            <AuthFormWrapper title={t(`auth_choose_company_title`)} subTitle={t(`auth_choose_company_subtitle`)}>
                <Grid className={classes.root} container direction="column" wrap="nowrap">
                    {error && <ErrorMessage message={error} />}
                    {(isSubmitting || isFetchingCompanies) && (
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>
                    )}
                    {samlCompanies.length > 0 && (
                        <Grid className={classes.companyListWrapper} container direction={'column'} wrap="nowrap">
                            {samlCompanies.map(company => {
                                return (
                                    <CompanyItem
                                        key={`company-${company.id}`}
                                        company={company}
                                        onClick={handleCompanySelect}
                                    />
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            </AuthFormWrapper>
        </AuthenticationWrapper>
    );
}

export default React.memo(SamlSelectCompany);
