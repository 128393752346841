import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { CircularProgress, Grid, Typography, Link } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { API_URL } from 'config';
import { getUserFactorApi } from 'services/company';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'normal'
    }
}));

function AppVerificationStep({ onVerify, onBack, isLoading, error }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [otp, setOtp] = React.useState('');
    const [userFactor, setUserFactor] = React.useState({});
    const [isShowTextCode, setIsShowTextCode] = React.useState(false);

    React.useEffect(() => {
        const fetchFactorStatus = async () => {
            try {
                const factorStatus = await getUserFactorApi();
                setUserFactor(factorStatus);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchFactorStatus();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        onVerify(otp);
    };

    return (
        <Grid item container direction="column">
            <form onSubmit={handleSubmit}>
                <Grid item style={{ marginTop: 50 }}>
                    <Typography variant="h2" className={classes.title}>
                        {t('verify_authenticator_app')}
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 24 }}>
                    <Typography variant="body2">
                        {t('download_and_install_an_authenticator_app')}:{' '}
                        <Link
                            target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        >
                            Google {t('authenticator')}
                        </Link>
                        ,{' '}
                        <Link target="_blank" href="https://www.microsoft.com/en-us/account/authenticator/#getapp">
                            Microsoft {t('authenticator')}
                        </Link>{' '}
                        or{' '}
                        <Link target="_blank" href="https://authy.com/download/">
                            Authy
                        </Link>
                        .
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 14 }}>
                    <Typography variant="body2">{t('open_your_authenticator_app')}</Typography>
                </Grid>
                <Grid container alignItems="center" wrap="nowrap">
                    <Grid item style={{ width: 130, height: 130, overflow: 'hidden' }}>
                        <iframe
                            scrolling="no"
                            style={{
                                border: 0,
                                width: '200%',
                                height: '200%',
                                transform: 'scale(0.5)',
                                transformOrigin: '0 0'
                            }}
                            src={`${API_URL}/organization/api/v1/account/factor/${
                                userFactor?.enabledViaApp ? 'requestNewOtpQrCode' : 'requestOtpQrCode'
                            }`}
                            title="QR"
                        />
                    </Grid>
                    <Grid item marginBottom style={{ marginLeft: 10 }}>
                        {isShowTextCode ? (
                            <>
                                <Typography variant="body2">{t('text_code')}:</Typography>
                                <Typography variant="body1">{userFactor.key}</Typography>{' '}
                            </>
                        ) : (
                            <Link style={{ cursor: 'pointer' }} onClick={() => setIsShowTextCode(true)}>
                                {t('cant_scan_use_text_code_instead')}
                            </Link>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="body2">{t('enter_your_verification_code')}</Typography>
                </Grid>
                <Grid item style={{ marginTop: 14 }}>
                    <Typography variant="body1">{t('6_digits_code')}</Typography>
                </Grid>
                <Grid item style={{ marginTop: 8 }}>
                    <InputText
                        name="code"
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                        placeholder={t('enter_code')}
                        error={Boolean(error)}
                        errorText={error}
                    />
                </Grid>
                <div className="flex gap-2 justify-end items-center mt-5 w-full">
                    <ButtonBase variant="outlined" width={100} onClick={onBack}>
                        {t('global_back')}
                    </ButtonBase>
                    <ButtonBase disabled={isLoading} variant="contained" width={100} type="submit">
                        {isLoading ? <CircularProgress size={24} /> : t('verify')}
                    </ButtonBase>
                </div>
            </form>
        </Grid>
    );
}

export default AppVerificationStep;
