import * as roleConst from 'auth/roleConst';
import { getPropertyInCustomProperties } from 'utils/gridUI/formatData';
import { ENV } from 'config';
import { STAGING, PRODUCTION, DEVELOPMENT, INTEGRATION, LOCAL, EXTERNAL } from 'config/env';
import { getWorkspaceRole } from 'utils/workspace';
import { useSelector } from 'react-redux';
import { useCurrentView } from '../gridUI/view';
import { VIEW_TYPES } from 'const/gridUI';
import { useCurrentUserEmail } from './index';
import { useAddonSubscription, useIsUserBlockedGridByPayment } from 'hooks/payment';
import { SUBSCRIPTION_STATUS } from 'const';
import { useIsShareViewLink } from 'hooks/app';

const UI_PERMISSION = {
    [LOCAL]: {
        [roleConst.SUPPORT_GLOBAL_SEARCH]: roleConst.FULL,
        [roleConst.SUPPORT_NOTIFICATION]: roleConst.FULL,
        [roleConst.SUPPORT_GLOBAL_CHAT]: roleConst.FULL,
        [roleConst.SUPPORT_SELECT_ALL_CHECKBOX]: roleConst.FULL,
        [roleConst.SUPPORT_RESTORE_GRID]: roleConst.FULL,
        [roleConst.SUPPORT_PATHTAG_UNIQUE_RECORDID]: roleConst.FULL
    },
    [DEVELOPMENT]: {
        [roleConst.SUPPORT_GLOBAL_SEARCH]: roleConst.FULL,
        [roleConst.SUPPORT_NOTIFICATION]: roleConst.FULL,
        [roleConst.SUPPORT_GLOBAL_CHAT]: roleConst.FULL,
        [roleConst.SUPPORT_SELECT_ALL_CHECKBOX]: roleConst.FULL,
        [roleConst.SUPPORT_RESTORE_GRID]: roleConst.FULL,
        [roleConst.SUPPORT_PATHTAG_UNIQUE_RECORDID]: roleConst.FULL
    },
    [INTEGRATION]: {
        [roleConst.SUPPORT_GLOBAL_SEARCH]: roleConst.FULL,
        [roleConst.SUPPORT_NOTIFICATION]: roleConst.FULL,
        [roleConst.SUPPORT_GLOBAL_CHAT]: roleConst.FULL,
        [roleConst.SUPPORT_SELECT_ALL_CHECKBOX]: roleConst.FULL,
        [roleConst.SUPPORT_RESTORE_GRID]: roleConst.FULL,
        [roleConst.SUPPORT_PATHTAG_UNIQUE_RECORDID]: roleConst.FULL
    },
    [STAGING]: {
        [roleConst.SUPPORT_GLOBAL_SEARCH]: roleConst.FULL,
        [roleConst.SUPPORT_NOTIFICATION]: roleConst.FULL,
        [roleConst.SUPPORT_GLOBAL_CHAT]: roleConst.HIDDEN,
        [roleConst.SUPPORT_SELECT_ALL_CHECKBOX]: roleConst.HIDDEN,
        [roleConst.SUPPORT_RESTORE_GRID]: roleConst.HIDDEN,
        [roleConst.SUPPORT_PATHTAG_UNIQUE_RECORDID]: roleConst.FULL
    },
    [PRODUCTION]: {
        [roleConst.SUPPORT_GLOBAL_SEARCH]: roleConst.FULL,
        [roleConst.SUPPORT_NOTIFICATION]: roleConst.FULL,
        [roleConst.SUPPORT_GLOBAL_CHAT]: roleConst.HIDDEN,
        [roleConst.SUPPORT_SELECT_ALL_CHECKBOX]: roleConst.HIDDEN,
        [roleConst.SUPPORT_RESTORE_GRID]: roleConst.HIDDEN,
        [roleConst.SUPPORT_JIRA_FEEDBACK]: roleConst.FULL,
        [roleConst.SUPPORT_PATHTAG_UNIQUE_RECORDID]: roleConst.FULL
    },
    [EXTERNAL]: {
        [roleConst.SUPPORT_GLOBAL_SEARCH]: roleConst.FULL,
        [roleConst.SUPPORT_NOTIFICATION]: roleConst.FULL,
        [roleConst.SUPPORT_GLOBAL_CHAT]: roleConst.HIDDEN,
        [roleConst.SUPPORT_SELECT_ALL_CHECKBOX]: roleConst.HIDDEN,
        [roleConst.SUPPORT_RESTORE_GRID]: roleConst.HIDDEN,
        [roleConst.SUPPORT_JIRA_FEEDBACK]: roleConst.FULL,
        [roleConst.SUPPORT_PATHTAG_UNIQUE_RECORDID]: roleConst.FULL
    }
};

const WORKSPACE_ROLE_EXTRA_AUTHORITIES = {
    [roleConst.CREATOR]: {
        //PATH TAG
        [roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG]: roleConst.FULL,

        //MANAGE COLUMN PERMISSION
        [roleConst.EXTRA_AUTHORITIES.SHOW_COLUMN_PERMISSION]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_COLUMN_PERMISSION]: roleConst.FULL,

        //MANAGE TRIGGER
        [roleConst.EXTRA_AUTHORITIES.SHOW_TRIGGER]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_TRIGGER]: roleConst.FULL,

        //MANAGE MERGING
        [roleConst.EXTRA_AUTHORITIES.SHOW_MERGING]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_MERGING]: roleConst.FULL,

        //MANAGE FILTER
        [roleConst.EXTRA_AUTHORITIES.SHOW_FILTER]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_FILTER]: roleConst.FULL,

        //MANAGE SORT
        [roleConst.EXTRA_AUTHORITIES.SHOW_SORT]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_SORT]: roleConst.FULL,

        //MANAGE DEPENDENCY
        [roleConst.EXTRA_AUTHORITIES.SHOW_DEPENDENCY]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY]: roleConst.FULL,

        //MANAGE RECORD HISTORY
        [roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY]: roleConst.FULL
    },
    [roleConst.EDITOR]: {
        //PATH TAG
        [roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG]: roleConst.FULL,

        //MANAGE COLUMN PERMISSION
        [roleConst.EXTRA_AUTHORITIES.SHOW_COLUMN_PERMISSION]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_COLUMN_PERMISSION]: roleConst.READ_ONLY,

        //MANAGE TRIGGER
        [roleConst.EXTRA_AUTHORITIES.SHOW_TRIGGER]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_TRIGGER]: roleConst.READ_ONLY,

        //MANAGE MERGING
        [roleConst.EXTRA_AUTHORITIES.SHOW_MERGING]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_MERGING]: roleConst.READ_ONLY,

        //MANAGE FILTER
        [roleConst.EXTRA_AUTHORITIES.SHOW_FILTER]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_FILTER]: roleConst.READ_ONLY,

        //MANAGE SORT
        [roleConst.EXTRA_AUTHORITIES.SHOW_SORT]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_SORT]: roleConst.READ_ONLY,

        //MANAGE DEPENDENCY
        [roleConst.EXTRA_AUTHORITIES.SHOW_DEPENDENCY]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY]: roleConst.READ_ONLY,

        //MANAGE RECORD HISTORY
        [roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY]: roleConst.FULL
    },
    [roleConst.TRANSLATOR]: {
        [roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG]: roleConst.FULL,

        //MANAGE RECORD HISTORY
        [roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY]: roleConst.FULL
    },
    [roleConst.VIEWER]: {
        [roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG]: roleConst.FULL,

        //MANAGE FILTER
        [roleConst.EXTRA_AUTHORITIES.SHOW_FILTER]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_FILTER]: roleConst.READ_ONLY,

        //MANAGE SORT
        [roleConst.EXTRA_AUTHORITIES.SHOW_SORT]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_SORT]: roleConst.READ_ONLY,

        //MANAGE DEPENDENCY
        [roleConst.EXTRA_AUTHORITIES.SHOW_DEPENDENCY]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY]: roleConst.READ_ONLY,

        //MANAGE TRIGGER
        [roleConst.EXTRA_AUTHORITIES.SHOW_TRIGGER]: roleConst.FULL,
        [roleConst.EXTRA_AUTHORITIES.MANAGE_TRIGGER]: roleConst.READ_ONLY
    },
    [roleConst.PAYMENT_LOCKED_USER]: {
        [roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG]: roleConst.HIDDEN,

        //MANAGE RECORD HISTORY
        [roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY]: roleConst.FULL
    },
    [roleConst.LOCALIZATION_MANAGER]: {
        //PATH TAG
        [roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG]: roleConst.FULL,

        //MANAGE RECORD HISTORY
        [roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY]: roleConst.FULL
    }
};

function getAllWorkspaceRoles({ role, viewType = VIEW_TYPES.ACCESS_VIEW, workspaceAuthorities }) {
    const workspaceExtraAuthorities = WORKSPACE_ROLE_EXTRA_AUTHORITIES[role];
    let roles = {};
    for (const key of workspaceAuthorities) {
        roles = {
            ...roles,
            [key]: roleConst.FULL
        };
    }
    return { ...roles, ...workspaceExtraAuthorities };
}

export function useCompanyAuthorities() {
    return useSelector(state => state.auth.currentUser?.authorities) || [];
}

export function useRole() {
    const companyAuthorities = useCompanyAuthorities();
    const currentView = useCurrentView();
    const isShareViewLink = useIsShareViewLink();

    //payment validate
    const authRole = useSelector(state => state.auth?.role);
    const authWorkspaceRole = useSelector(state => state.auth?.workspaceRole);
    const authWsAuthorities = useSelector(state => state.auth.workspaceAuthorities);

    const role = isShareViewLink ? {} : authRole;
    const workspaceRole = isShareViewLink ? roleConst.VIEWER : authWorkspaceRole;
    const wsAuthorities = isShareViewLink ? [] : authWsAuthorities;
    const userBlockedGridByPayment = useIsUserBlockedGridByPayment();
    const isUserBlockedGridByPayment = isShareViewLink ? false : userBlockedGridByPayment;

    const companyRole = {};
    companyAuthorities.forEach(ROLE => {
        companyRole[ROLE] = roleConst.FULL;
    });

    let wsRole = getWorkspaceRole({ role, workspaceRole });

    const manageWorkspaceRole = role?.[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE];

    const defaultAuthorities = manageWorkspaceRole ? roleConst.CREATOR_AUTHORITIES : wsAuthorities;

    const workspaceAuthorities = isUserBlockedGridByPayment
        ? [roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD]
        : defaultAuthorities;

    const workspacePermission = getAllWorkspaceRoles({
        role: wsRole,
        workspaceAuthorities,
        viewType: getPropertyInCustomProperties(currentView, 'type')
    });

    const uiPermissions = UI_PERMISSION?.[ENV];

    let permissionDetail = {
        ...workspacePermission,
        ...companyRole,
        ...uiPermissions,
        [roleConst.WORKSPACE_ROLE]: wsRole
    };

    if (isUserBlockedGridByPayment) {
        const paymentLockUserPermission = getAllWorkspaceRoles({
            role: roleConst.PAYMENT_LOCKED_USER,
            workspaceAuthorities,
            viewType: getPropertyInCustomProperties(currentView, 'type')
        });

        permissionDetail = {
            ...permissionDetail,
            ...paymentLockUserPermission
        };
    }

    return permissionDetail;
}

export function useAuthoritiesByProperty(property) {
    const authorities = useRole();
    return authorities?.[property];
}

export function useWorkspaceRole() {
    const role = useSelector(state => state.auth?.role);
    const workspaceRole = useSelector(state => state.auth?.workspaceRole);
    return getWorkspaceRole({ role, workspaceRole });
}

export function useShowLambdaHook() {
    const currentUserEmail = useCurrentUserEmail();
    const lambdaAddon = useAddonSubscription('LAMBDA');

    const allowedCompanyEmailDomains = [
        'localizedirect.com',
        'scopely.com',
        'netherrealm.com',
        'hazelight.se',
        'jagex.com',
        'chromaway.com',
        'pixeltoys.com',
        'bungie.com',
        'spokko.com',
        'chromaway.com',
        'antlerinteractive.com'
    ];

    return (
        lambdaAddon?.status === SUBSCRIPTION_STATUS.ACTIVE ||
        lambdaAddon?.status === SUBSCRIPTION_STATUS.TRIALING ||
        allowedCompanyEmailDomains?.some(domain => currentUserEmail?.toLowerCase()?.includes(domain))
    );
}

export function useIsFetchedWorkspaceRole() {
    return useSelector(state => state.auth.isFetchedWorkspaceRole);
}
