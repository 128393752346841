import React from 'react';

function ReadyForTranslateSVG({ color = '#77B32B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <circle cx="9" cy="9" r="9" fill={color} />
        </svg>
    );
}

export default React.memo(ReadyForTranslateSVG);
