import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { ROW_RESIZE_HEIGHT } from 'const/gridUI';
import { DISABLED_OPACITY } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import OpenNewPageSVG from 'assets/images/svg/OpenNewPageSVG';
import { isRowIndexInRange, getTotalSelectedRowsByRange } from 'utils/gridUI/range';
import * as roleConst from 'auth/roleConst';
import ThreeStateCheckbox from 'components/checkbox/ThreeStateCheckBox';
import { useRole } from 'hooks/auth/role';
import { useParams } from 'react-router-dom';
import { useResourceCellByRowId, useIsRowInRange } from 'hooks/advanced';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        position: 'relative',
        height: '100%',
        '& ._expand': {
            padding: theme.spacing(1),
            opacity: 0,
            display: 'flex',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(0, -50%)'
        },
        '&:hover ._expand': {
            opacity: 1
        }
    },
    resizeHandler: {
        width: '100%',
        background: theme.colors.resizeColor,
        height: ROW_RESIZE_HEIGHT + 2,
        position: 'absolute',
        bottom: 0,
        left: 0,
        opacity: 0,
        '&:hover': {
            opacity: 1,
            cursor: 'ns-resize'
        }
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    dragging: {
        background: `${hexToRgba(theme.colors.highlight, 0.3)} !important`
    },
    block: {
        opacity: `1 !important`
    },
    selected: {
        background: theme.colors.lightGrey
    }
}));
const HOLD_TIME = 200;

function IndexCell({
    rowId,
    rowIndex,
    setRowHandlerStartPosition,
    isDragging,
    recordHistoryId,
    accessRecordHistory,
    accessManageGridRecord,
    contextMenuId,
    isImporting,
    isPathTagOn,
    accessEditTranslation,
    positionTop,
    positionBottom,
    previousRowId,
    nextRowId,
    accessEditRecords,
    isShareViewLink,
    t,
    isSelected,
    rowsRangeIndexes = [],
    isDisabledCreateRecord
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const timerDragRef = React.useRef();
    const roles = useRole();
    const { workspaceId } = useParams();
    const resource = useResourceCellByRowId(rowId);
    const isRowInRange = useIsRowInRange({ rowIndex });

    const isCreator = React.useMemo(() => {
        return roles?.[roleConst.WORKSPACE_ROLE] === roleConst.CREATOR;
    }, [roles]);

    const totalSelectedRows = React.useMemo(() => {
        return getTotalSelectedRowsByRange(rowsRangeIndexes);
    }, [rowsRangeIndexes]);

    const stopPropagation = React.useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const handleIndexClick = useCallback(
        e => {
            stopPropagation(e);
            const { isShift, isCtrl } = getStatusCtrlOrShiftKey(e);
            dispatch(
                gridUIActions.rowSelection({
                    isCtrl,
                    isShift,
                    rowId,
                    rowIndex
                })
            );
            if (recordHistoryId && recordHistoryId !== rowId && !isShift) {
                dispatch(gridUIActions.openRecordHistory({ rowId, rowIndex }));
            }
        },
        [dispatch, rowId, rowIndex, recordHistoryId, stopPropagation]
    );

    const handleMouseUpOrLeave = () => {
        if (timerDragRef.current) {
            clearTimeout(timerDragRef.current);
        }
    };

    const handleMouseDown = React.useCallback(
        e => {
            stopPropagation(e);

            if (totalSelectedRows === 0) {
                const { isShift, isCtrl } = getStatusCtrlOrShiftKey(e);
                dispatch(
                    gridUIActions.rowSelection({
                        isCtrl,
                        isShift,
                        rowId,
                        rowIndex
                    })
                );
            }
            timerDragRef.current = setTimeout(() => {
                dispatch(gridUIActions.turnOnDraggingRows());
            }, HOLD_TIME);
        },
        [dispatch, stopPropagation, rowIndex, rowId, totalSelectedRows]
    );

    const openNewPageRecord = React.useCallback(
        e => {
            stopPropagation(e);

            window.open(
                `/projects/${workspaceId}/databases/${resource?.dbId}/grids/${resource?.gridId}/branches/${resource?.gridId}/views/${resource?.firstColumnCellData?.viewId}?action=SEARCHCELL-${resource?.recordId}-${resource?.firstColumnCellData?.columnId}`,
                '_blank'
            );
        },
        [workspaceId, resource, stopPropagation]
    );

    const handleCheckbox = useCallback(
        (e, nextState) => {
            stopPropagation(e);
            const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
            dispatch(
                gridUIActions.rowSelection({
                    isCtrl: isShift ? isCtrl : true,
                    isShift,
                    rowId,
                    rowIndex
                })
            );
        },
        [dispatch, rowId, rowIndex, stopPropagation]
    );

    return (
        <>
            <Grid
                onClick={handleIndexClick}
                container
                onMouseDown={handleMouseDown}
                justify="center"
                alignItems="center"
                className={`${classes.root} ${isRowInRange ? classes.selected : ''} ${
                    isDragging && isRowIndexInRange({ ranges: rowsRangeIndexes, rowIndex }) ? classes.dragging : ''
                } ${isImporting ? classes.disabled : ''} RECORD_${rowId}`}
                onMouseUp={handleMouseUpOrLeave}
                pst={positionTop}
                psb={positionBottom}
                prid={previousRowId}
                nrid={nextRowId}
                rindex={rowIndex}
                id={`INDEX_RECORD_${rowId}`}
            >
                <Grid
                    style={{
                        height: `calc(100% - 10px)`,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1
                    }}
                    item
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rindex={rowIndex}
                    className={`RECORD_${rowId}`}
                >
                    {!isSelected || !isCreator ? (
                        <Typography
                            pst={positionTop}
                            psb={positionBottom}
                            prid={previousRowId}
                            nrid={nextRowId}
                            rindex={rowIndex}
                            className={`text RECORD_${rowId}`}
                            variant="body2"
                            onClick={handleCheckbox}
                            onMouseDown={e => stopPropagation(e)}
                        >
                            {rowIndex + 1}
                        </Typography>
                    ) : (
                        <ThreeStateCheckbox
                            state={isSelected ? 1 : 2}
                            onChange={handleCheckbox}
                            className={classes.checkboxIconButton}
                        />
                    )}
                </Grid>
                {!isShareViewLink && (
                    <Grid item className={`_expand`} onClick={openNewPageRecord}>
                        <OpenNewPageSVG color={theme.palette.primary.main} width={20} height={20} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default React.memo(IndexCell);
