import React from 'react';

function JSONSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0)">
                <path d="M6 0V36H27L36 27V0H6Z" fill="white" />
                <path d="M27 27V36L36 27H27Z" fill="#C9C9CE" />
                <path d="M18 36H27V27L18 36Z" fill="#E9EAEF" />
                <path d="M0 9V19.5H24V9H0Z" fill="#CB5641" />
                <path
                    d="M5.14531 15.6768C5.14531 16.3608 4.98781 16.8483 4.66831 17.1408C4.35181 17.4303 3.86581 17.5773 3.21181 17.5773C2.86831 17.5773 2.58781 17.5308 2.36881 17.4363C2.14981 17.3433 1.97431 17.2128 1.84531 17.0403C1.71631 16.8678 1.62781 16.6698 1.58131 16.4418C1.53181 16.2153 1.50781 15.9738 1.50781 15.7233V15.4428H2.69581V15.6423C2.69581 15.9843 2.73181 16.2363 2.80831 16.4028C2.88331 16.5663 3.03631 16.6503 3.27181 16.6503C3.50731 16.6503 3.66181 16.5663 3.73681 16.4028C3.81181 16.2378 3.84931 15.9843 3.84931 15.6423V11.0508H5.14531C5.14531 11.0508 5.14531 15.6768 5.14531 15.6768Z"
                    fill="white"
                />
                <path
                    d="M7.57484 15.6578C7.57484 15.8078 7.58834 15.9443 7.61084 16.0658C7.63484 16.1888 7.67984 16.2923 7.74584 16.3763C7.81184 16.4618 7.90184 16.5278 8.01284 16.5758C8.12234 16.6223 8.26184 16.6478 8.42984 16.6478C8.62784 16.6478 8.80634 16.5833 8.96384 16.4543C9.12284 16.3253 9.20384 16.1243 9.20384 15.8558C9.20384 15.7118 9.18284 15.5873 9.14534 15.4823C9.10634 15.3773 9.04184 15.2828 8.95184 15.2003C8.86034 15.1148 8.74184 15.0398 8.59484 14.9708C8.44784 14.9018 8.26634 14.8313 8.05184 14.7593C7.76384 14.6633 7.51484 14.5568 7.30484 14.4428C7.09484 14.3303 6.91934 14.1968 6.77834 14.0423C6.63734 13.8893 6.53234 13.7123 6.46784 13.5113C6.40034 13.3088 6.36884 13.0778 6.36884 12.8123C6.36884 12.1763 6.54584 11.7038 6.89984 11.3918C7.25384 11.0798 7.74134 10.9238 8.35934 10.9238C8.64584 10.9238 8.91284 10.9553 9.15584 11.0183C9.39884 11.0813 9.60884 11.1818 9.78584 11.3243C9.96134 11.4653 10.1008 11.6453 10.1983 11.8628C10.2973 12.0818 10.3483 12.3443 10.3483 12.6503V12.8303H9.10484C9.10484 12.5243 9.05084 12.2888 8.94434 12.1223C8.83634 11.9588 8.65484 11.8748 8.40284 11.8748C8.25884 11.8748 8.13884 11.8973 8.04434 11.9378C7.94984 11.9798 7.87184 12.0353 7.81484 12.1043C7.75784 12.1733 7.71734 12.2558 7.69634 12.3458C7.67534 12.4358 7.66484 12.5288 7.66484 12.6248C7.66484 12.8243 7.70684 12.9893 7.79084 13.1243C7.87484 13.2608 8.05484 13.3853 8.33084 13.4978L9.32984 13.9298C9.57584 14.0378 9.77534 14.1503 9.93134 14.2688C10.0873 14.3873 10.2118 14.5118 10.3048 14.6468C10.3978 14.7818 10.4653 14.9288 10.4998 15.0923C10.5358 15.2528 10.5538 15.4343 10.5538 15.6308C10.5538 16.3088 10.3573 16.8023 9.96284 17.1113C9.56984 17.4203 9.02234 17.5763 8.32184 17.5763C7.58984 17.5763 7.06484 17.4173 6.74984 17.0993C6.43484 16.7813 6.27734 16.3253 6.27734 15.7313V15.4703H7.57484V15.6578Z"
                    fill="white"
                />
                <path
                    d="M11.673 12.9173C11.7165 12.5078 11.817 12.1538 11.9685 11.8598C12.123 11.5673 12.3435 11.3363 12.63 11.1713C12.9165 11.0078 13.305 10.9238 13.7895 10.9238C14.2755 10.9238 14.6625 11.0078 14.9505 11.1713C15.2385 11.3363 15.459 11.5673 15.6105 11.8598C15.765 12.1538 15.8625 12.5078 15.909 12.9173C15.9525 13.3283 15.9765 13.7768 15.9765 14.2628C15.9765 14.7533 15.9525 15.2048 15.909 15.6113C15.8625 16.0193 15.7635 16.3703 15.6105 16.6643C15.4575 16.9583 15.2385 17.1833 14.9505 17.3393C14.6625 17.4953 14.2755 17.5733 13.7895 17.5733C13.305 17.5733 12.9165 17.4938 12.63 17.3393C12.3435 17.1833 12.1215 16.9583 11.9685 16.6643C11.8155 16.3703 11.7165 16.0193 11.673 15.6113C11.628 15.2048 11.6055 14.7533 11.6055 14.2628C11.6055 13.7783 11.628 13.3298 11.673 12.9173ZM12.933 15.3653C12.954 15.6683 12.996 15.9143 13.059 16.1033C13.122 16.2923 13.212 16.4303 13.329 16.5173C13.446 16.6043 13.602 16.6478 13.7925 16.6478C13.986 16.6478 14.139 16.6043 14.2575 16.5173C14.3745 16.4288 14.4645 16.2923 14.529 16.1033C14.5905 15.9143 14.6325 15.6683 14.655 15.3653C14.676 15.0623 14.6865 14.6963 14.6865 14.2643C14.6865 13.8323 14.676 13.4663 14.655 13.1663C14.6325 12.8663 14.5905 12.6203 14.529 12.4283C14.4645 12.2363 14.3745 12.0968 14.2575 12.0113C14.1405 11.9228 13.986 11.8793 13.7925 11.8793C13.602 11.8793 13.4475 11.9228 13.329 12.0113C13.212 12.0983 13.122 12.2363 13.059 12.4283C12.996 12.6203 12.954 12.8663 12.933 13.1663C12.912 13.4663 12.9015 13.8323 12.9015 14.2643C12.9015 14.6963 12.912 15.0623 12.933 15.3653Z"
                    fill="white"
                />
                <path
                    d="M20.3467 15.4533H20.3647V11.0508H21.5527V17.4768H20.0782L18.4927 12.9768H18.4747V17.4768H17.2852V11.0508H18.7792L20.3467 15.4533Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="36" height="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(JSONSVG);
