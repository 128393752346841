import produce from 'immer';
import uniq from 'lodash/uniq';
import { deleteObjectProperty, removeArrayInArray, mergeObjects } from 'utils/object';
import * as types from '../types';
import { generateNewColumnsOrder } from 'utils/gridUI/column';
import { CREATE_COLUMN_TYPES } from 'const/gridUI';

const handler = {
    [types.COLUMN_SELECTION](state, action) {
        const { columnsSelected, oldColumnIdSelected } = action.payload;

        return {
            ...state,
            columnsSelected,
            oldColumnIdSelected,
            oldRowIdSelected: null,
            oldRowIndexSelected: null,
            isOpenCellEdit: false,
            rowStartIndex: -1,
            rowStopIndex: -1,
            columnStartIndex: -1,
            columnStopIndex: -1,
            rowIndexMap: {},
            rowsRangeIndexes: [],
            isOpenColumnFormat: false
        };
    },

    [types.UPDATE_GRID_COLUMN](state, action) {
        const { column } = action.payload;
        const { id, name, ...restColumnProps } = column;
        const { metaData, columnsPermission } = state;
        let columnsPermissionCoppied = [...columnsPermission];
        let found = [...columnsPermissionCoppied].find(colPer => id === colPer.id);

        if (found) {
            Object.assign(found, { name: name }, restColumnProps);
        }
        const newMetaData = produce(metaData, draft => {
            if (!draft[id]) {
                draft[id] = {};
            }
            draft[id] = { ...draft[id], ...column };
            return draft;
        });

        return {
            ...state,
            metaData: newMetaData,
            columnsPermission: columnsPermissionCoppied
        };
    },
    [types.CLEAR_IS_NEW](state, action) {
        const { columnId } = action.payload;
        const { metaData } = state;

        const newMetaData = produce(metaData, draft => {
            if (!draft[columnId]) {
                draft[columnId] = {};
            }
            delete draft?.[columnId]?.isNew;
            return draft;
        });

        return {
            ...state,
            metaData: newMetaData
        };
    },

    [types.DELETE_GRID_COLUMN](state, action) {
        const { columnId } = action.payload;
        const { columns, metaData, viewColumns, columnsPermission } = state;
        const newColumns = columns.filter(id => id !== columnId);
        let newMetaData = { ...metaData };
        if (metaData[columnId]) {
            newMetaData = deleteObjectProperty(newMetaData, columnId);
        }
        let newViewColumns = viewColumns.map(col => {
            if (col.id === columnId) {
                return {
                    ...col,
                    viewable: false
                };
            }
            return col;
        });
        let newColumnsPermission = columnsPermission.filter(col => col.id !== columnId);
        return {
            ...state,
            columns: newColumns,
            metaData: newMetaData,
            viewColumns: newViewColumns,
            columnsPermission: newColumnsPermission
        };
    },

    [types.CREATE_RELATIVE_GRID_COLUMN_SUCCESS](state, action) {
        const { column, viewColumn, selectedColumnId, type } = action.payload;
        const { viewColumns } = state;

        const metaData = {
            ...state.metaData,
            [column.id]: column
        };

        let newColumnsPermission = [...state.columnsPermission, column];
        const columns = [...(state.columns || []), column.id];

        let newViewColumns = [];
        const selectedIndex = viewColumns?.findIndex(vCol => vCol?.id === selectedColumnId);

        if (type === CREATE_COLUMN_TYPES.LEFT) {
            newViewColumns = [
                ...viewColumns?.slice(0, selectedIndex),
                viewColumn,
                ...viewColumns.slice(selectedIndex)
            ]?.map((vCol, index) => ({ ...vCol, order: index + 1 }));
        } else {
            newViewColumns = [
                ...viewColumns?.slice(0, selectedIndex + 1),
                viewColumn,
                ...viewColumns.slice(selectedIndex + 1)
            ]?.map((vCol, index) => ({ ...vCol, order: index + 1 }));
        }

        return {
            ...state,
            columns,
            metaData,
            columnsPermission: newColumnsPermission,
            viewColumns: newViewColumns
        };
    },

    [types.CREATE_GRID_COLUMN](state) {
        return {
            ...state,
            isCreatingColumn: true
        };
    },

    [types.CREATE_GRID_COLUMN_SUCCESS](state, action) {
        const { column, viewColumn } = action.payload;
        const columns = [...(state?.columns || []), column.id];
        const metaData = {
            ...state.metaData,
            [column.id]: column
        };
        let newViewColumns = [...(state?.viewColumns || []), viewColumn];
        let newColumnsPermission = [...(state?.columnsPermission || []), column];

        return {
            ...state,
            columns,
            metaData,
            columnsPermission: newColumnsPermission,
            viewColumns: newViewColumns
        };
    },
    [types.CREATE_GRID_COLUMN_AND_FINISH_SCROLL](state, action) {
        return {
            ...state,
            isCreatingColumn: false
        };
    },
    [types.CREATE_GRID_COLUMN_FAILED](state, action) {
        const { error } = action.payload;
        return {
            ...state,
            error,
            isCreatingColumn: false
        };
    },

    [types.CREATE_GRID_COLUMN_SOCKET](state, action) {
        const { column } = action.payload;
        const columns = [...(state?.columns || []), column.id];
        const metaData = {
            ...state.metaData,
            [column.id]: column
        };
        let newColumnsPermission = [...(state?.columnsPermission || []), column];
        return {
            ...state,
            columns,
            metaData,
            columnsPermission: newColumnsPermission
        };
    },

    [types.ADD_GRID_COLUMN_TO_VIEW_SOCKET](state, action) {
        const { viewColumn } = action.payload;
        const { viewColumns, columnsPermission, metaData } = state;
        let newViewColumns = generateNewColumnsOrder(
            viewColumns.filter(viewCol => viewCol?.id !== viewColumn?.id)
        )?.concat({ ...viewColumn, viewable: true });

        let foundedColPer = columnsPermission.find(col => col?.id === viewColumn?.id);

        let newColumnsPermission = [...columnsPermission];
        let newMetaData = { ...metaData };
        if (foundedColPer) {
            newColumnsPermission = generateNewColumnsOrder(
                columnsPermission.filter(colPer => colPer.id !== viewColumn.id)
            )?.concat({ ...foundedColPer, viewable: true, order: viewColumn?.order });
            newMetaData = {
                ...newMetaData,
                [foundedColPer.id]: {
                    ...foundedColPer,
                    order: viewColumn?.order,
                    viewable: true
                }
            };
        }

        return {
            ...state,
            viewColumns: newViewColumns,
            metaData: newMetaData,
            columnsPermission: newColumnsPermission
        };
    },

    [types.QUICK_FILTER_INPUT_CHANGE](state, action) {
        const { columnId, value, type, currentState, operator, fullPathId } = action.payload;
        const newQuickFilters = { ...state.quickFilters };
        newQuickFilters[columnId] = {
            ...newQuickFilters[columnId],
            type,
            value,
            currentState,
            operator,
            fullPathId
        };

        return {
            ...state,
            quickFilters: newQuickFilters
        };
    },
    [types.RESET_QUICK_FILTER_VALUE](state, action) {
        const { columnId } = action.payload;
        const newQuickFilters = { ...state.quickFilters };

        newQuickFilters[columnId] = {
            ...newQuickFilters[columnId],
            value: null
        };

        return {
            ...state,
            quickFilters: newQuickFilters
        };
    },
    [types.QUICK_FILTER_EXTRA_CHANGE](state, action) {
        const { extraFilter, columnId } = action.payload;
        const newQuickFilters = { ...state.quickFilters };

        newQuickFilters[columnId] = {
            ...newQuickFilters[columnId],
            extraFilter
        };

        return {
            ...state,
            quickFilters: newQuickFilters
        };
    },

    [types.QUICK_FILTER_REGEX_CHANGE](state, action) {
        const { filterMode, columnId } = action.payload;
        const newQuickFilters = { ...state.quickFilters };

        newQuickFilters[columnId] = {
            ...newQuickFilters[columnId],
            filterMode
        };

        return {
            ...state,
            quickFilters: newQuickFilters
        };
    },
    [types.CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN](state, action) {
        const { columnId } = action.payload;
        const newQuickFilters = { ...state.quickFilters };
        if (newQuickFilters[columnId]) delete newQuickFilters[columnId];
        return {
            ...state,
            quickFilters: newQuickFilters
        };
    },

    [types.CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN](state, action) {
        const { columnId } = action.payload;
        let newQuickSorts = { ...state.quickSorts };
        if (newQuickSorts[columnId]) delete newQuickSorts[columnId];
        return {
            ...state,
            quickSorts: newQuickSorts
        };
    },

    [types.QUICK_SORT_CHANGE](state, action) {
        const { columnId, value } = action.payload;
        let newQuickSorts = { ...state.quickSorts };
        let reOrderQuickSorts = {
            [columnId]: value
        };
        if (value === '') {
            if (newQuickSorts[columnId]) delete newQuickSorts[columnId];
        } else {
            Object.keys(newQuickSorts).forEach(each => {
                if (columnId !== each) {
                    reOrderQuickSorts[each] = newQuickSorts[each];
                }
            });
        }

        return {
            ...state,
            quickSorts: value === '' ? newQuickSorts : reOrderQuickSorts
        };
    },
    [types.RESIZE_COLUMN](state, action) {
        const { columnId, columnWidthChange } = action.payload;
        const { viewColumns } = state;

        const newViewColumns = viewColumns?.map(viewColumn => {
            if (viewColumn?.id === columnId) {
                viewColumn.customProperties = {
                    ...viewColumn?.customProperties,
                    width: columnWidthChange
                };
            }
            return viewColumn;
        });

        return {
            ...state,
            viewColumns: newViewColumns
        };
    },
    [types.IS_FETCHING_DISABLED_COLUMN_DATA](state) {
        return {
            ...state,
            isFetchingDisabledColumnData: true
        };
    },
    [types.STOP_FETCHING_DISABLED_COLUMN_DATA](state) {
        return {
            ...state,
            isFetchingDisabledColumnData: false
        };
    },
    [types.ADD_DISABLED_COLUMNS](state, action) {
        const { columnIds } = action.payload;
        const { disabledColumns } = state;
        const newDisabledColumns = uniq([...disabledColumns, ...columnIds]);

        return {
            ...state,
            disabledColumns: newDisabledColumns
        };
    },
    [types.REMOVE_DISABLED_COLUMNS](state, action) {
        const { columnIds } = action.payload;
        const { disabledColumns } = state;
        const newDisabledColumns = removeArrayInArray(disabledColumns, columnIds);
        return {
            ...state,
            disabledColumns: newDisabledColumns
        };
    },
    [types.ADD_PROCESSING_COLUMNS](state, action) {
        const { columnIds } = action.payload;
        const { processingColumns } = state;
        const newProcessingColumns = uniq([...processingColumns, ...columnIds]);

        return {
            ...state,
            processingColumns: newProcessingColumns
        };
    },
    [types.REMOVE_PROCESSING_COLUMNS](state, action) {
        const { columnIds } = action.payload;
        const { processingColumns } = state;
        const newProcessingColumns = removeArrayInArray(processingColumns, columnIds);
        return {
            ...state,
            processingColumns: newProcessingColumns
        };
    },
    [types.ADD_DISABLED_SOURCE_COLUMNS](state, action) {
        const { columnIds } = action.payload;
        const { disabledSourceColumns } = state;
        const newDisabledSourceColumns = uniq([...disabledSourceColumns, ...columnIds]);

        return {
            ...state,
            disabledSourceColumns: newDisabledSourceColumns
        };
    },
    [types.REMOVE_DISABLED_SOURCE_COLUMNS](state, action) {
        const { columnIds } = action.payload;
        const { disabledSourceColumns } = state;
        const newDisabledSourceColumns = removeArrayInArray(disabledSourceColumns, columnIds);
        return {
            ...state,
            disabledSourceColumns: newDisabledSourceColumns
        };
    },
    [types.RESET_DISABLED_SOURCE_COLUMNS](state, action) {
        return {
            ...state,
            disabledSourceColumns: []
        };
    },
    [types.RESET_DISABLED_PROCESSING_COLUMNS](state, action) {
        const { disabledSourceColumns, processingColumns, disabledColumns } = state;

        return {
            ...state,
            processingColumns,
            disabledColumns,
            disabledSourceColumns
        };
    },
    [types.UPDATE_SINGLE_COLUMN_DATA](state, action) {
        const { data } = action.payload;
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
        const newData = mergeObjects({ ...state.data }, data, { arrayMerge: overwriteMerge });
        return {
            ...state,
            data: newData
        };
    },
    [types.REORDER_COLUMNS](state, action) {
        const { newViewColumns } = action.payload;
        return {
            ...state,
            viewColumns: newViewColumns
        };
    },
    [types.REORDER_COLUMNS_SUCCESS](state, action) {
        const { viewColumns } = action.payload;
        return {
            ...state,
            viewColumns
        };
    },
    [types.SET_COLUMNS_SELECTION](state, action) {
        const { columnsSelected, columnId } = action.payload;
        return {
            ...state,
            columnsSelected,
            oldColumnIdSelected: columnId
        };
    },
    [types.CLEAR_COLUMNS_SELECTION](state) {
        const { columnsSelected } = state;
        return {
            ...state,
            columnsSelected,
            oldColumnIdSelected: null
        };
    },
    [types.UPDATE_REORDER_COLUMNS_REALTIME](state, { payload }) {
        const { newViewColumns } = payload;
        return {
            ...state,
            viewColumns: newViewColumns
        };
    },
    [types.UPLOAD_FILES_START](state, { payload }) {
        const { columnId, files, isCombinedWithPathTag } = payload;

        return {
            ...state,
            columnUploadFolderStatus: [
                ...state.columnUploadFolderStatus.map(column => {
                    return {
                        ...column,
                        isExpanding: false
                    };
                }),
                {
                    id: columnId,
                    isCombinedWithPathTag,
                    isExpanding: true,
                    files: files.map(file => ({ ...file, isUploading: true, error: '' }))
                }
            ]
        };
    },
    [types.RESET_COLUMN_UPLOAD_STATUS](state, { payload }) {
        const { columnId } = payload;
        const newColumnUploadFolderStatus = state.columnUploadFolderStatus.filter(column => column.id !== columnId);
        return {
            ...state,
            columnUploadFolderStatus: newColumnUploadFolderStatus
        };
    },
    [types.HANDLE_COLUMN_UPLOAD_FAILED](state, { payload }) {
        const { columnId, error } = payload;
        const newColumnUploadFolderStatus = state.columnUploadFolderStatus.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    files: column.files.map(file => {
                        return {
                            ...file,
                            isUploading: false,
                            error
                        };
                    })
                };
            }
            return column;
        });
        return {
            ...state,
            columnUploadFolderStatus: newColumnUploadFolderStatus
        };
    },
    [types.UPLOAD_SINGLE_FILE_SUCCESS_SOCKET](state, { payload }) {
        const { columnId, filePath } = payload;
        const newColumnUploadFolderStatus = state.columnUploadFolderStatus.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    files: column.files.map(file => {
                        if (file.path === filePath) {
                            return {
                                ...file,
                                isUploading: false,
                                error: ''
                            };
                        }
                        return file;
                    })
                };
            }
            return column;
        });

        return {
            ...state,
            columnUploadFolderStatus: newColumnUploadFolderStatus
        };
    },
    [types.UPLOAD_SINGLE_FILE_FAILED_SOCKET](state, { payload }) {
        const { columnId, filePath, error } = payload;
        const newColumnUploadFolderStatus = state.columnUploadFolderStatus.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    files: column.files.map(file => {
                        if (file.path === filePath) {
                            return {
                                ...file,
                                isUploading: false,
                                error
                            };
                        }
                        return file;
                    })
                };
            }
            return column;
        });

        return {
            ...state,
            columnUploadFolderStatus: newColumnUploadFolderStatus
        };
    },
    [types.EXPAND_COLUMN_UPLOAD](state, { payload }) {
        const { columnId } = payload;
        const newColumnUploadFolderStatus = state.columnUploadFolderStatus.map(column => {
            if (column.id === columnId) {
                return {
                    ...column,
                    isExpanding: !column.isExpanding
                };
            }
            return { ...column, isExpanding: false };
        });

        return {
            ...state,
            columnUploadFolderStatus: newColumnUploadFolderStatus
        };
    },
    [types.RESET_ALL_UPLOAD_STATUS](state, { payload }) {
        const { columnUploadFolderStatus } = state;
        return {
            ...state,
            columnUploadFolderStatus
        };
    },
    [types.ADD_COLUMN_IN_VIEW_WITH_ORDER](state, { payload }) {
        const { newViewColumnsWithOrders, newColumn, newMetaData, isEditable } = payload;

        const { columnsPermission } = state;

        const newColumnsPermission = columnsPermission?.map(col => {
            if (col?.id === newColumn?.id) {
                return {
                    ...col,
                    viewable: true,
                    editable: isEditable
                };
            }

            return col;
        });

        return {
            ...state,
            columnsPermission: newColumnsPermission,
            viewColumns: newViewColumnsWithOrders,
            metaData: newMetaData
        };
    },
    [types.CHANGE_COLUMN_EDITABLE](state, { payload }) {
        const { newViewColumns, columnId, editable, viewable } = payload;
        const { metaData } = state;
        const newMetaData = produce(metaData, draft => {
            if (!draft?.[columnId]) {
                draft[columnId] = {};
            }

            draft[columnId] = {
                ...draft?.[columnId],
                viewable,
                editable
            };
        });

        return {
            ...state,
            viewColumns: newViewColumns,
            metaData: newMetaData
        };
    },
    [types.CHANGE_COLUMN_VIEWABLE](state, { payload }) {
        const { newViewColumns, columnId, editable, viewable, order } = payload;
        const { metaData } = state;
        const newMetaData = produce(metaData, draft => {
            if (!draft?.[columnId]) {
                draft[columnId] = {};
            }

            draft[columnId] = {
                ...draft?.[columnId],
                viewable,
                editable,
                order
            };
            return draft;
        });

        return {
            ...state,
            viewColumns: newViewColumns,
            metaData: newMetaData
        };
    },
    [types.UPDATE_VIEW_COLUMN_ORDER](state, { payload }) {
        const { columnId, order } = payload;

        const { viewColumns } = state;

        const newViewColumns = viewColumns?.map(viewCol => {
            if (viewCol?.id === columnId) {
                viewCol.order = order;
            }
            return viewCol;
        });
        return {
            ...state,
            viewColumns: newViewColumns
        };
    },
    [types.AUTO_FILL_SUCCESS](state, { payload }) {
        const { totalRecords, rows } = payload;

        return {
            ...state,
            rows,
            totalRecords
        };
    },
    [types.SET_FORMAT_COLUMN_ID](state, { payload }) {
        const { columnId } = payload;

        return {
            ...state,
            formatColumnId: columnId
        };
    },
    [types.UNDO_DELETE_COLUMN_V2](state, { payload }) {
        const { metaData, columnsPermission, viewColumns, dependencies, viewFilters, viewSorts } = payload;

        return {
            ...state,
            metaData,
            columnsPermission,
            viewColumns,
            dependencies,
            viewFilters,
            viewSorts
        };
    },
    [types.SET_OPEN_FAKE_COLUMN](state, { payload }) {
        const { open } = payload;
        return {
            ...state,
            toggleFakeColumn: open
        };
    }
};

export default handler;
