import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from '../components/Checkbox';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';

function BooleanUpdate({ previousData, data, state, isHideMarkIcon = false }) {
    const theme = useTheme();

    return (
        <div className="flex items-center gap-1">
            <Checkbox isHideMarkIcon={isHideMarkIcon} data={previousData} state={state || 'delete'} />
            <ArrowNextSVG className="flex-none" color={theme.colors.steel} />
            <Checkbox isHideMarkIcon={isHideMarkIcon} data={data} state={state || 'update'} />
        </div>
    );
}

export default React.memo(BooleanUpdate);
