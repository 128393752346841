import React from 'react';
import { Grid } from '@material-ui/core';
import DocSchema from 'components/docSchema/Base';
import { useBodyRequestCreateRecord, useBodyResponseCreateRecord } from 'hooks/doc/record';
import { useGetApiKey, useCompositeViewId } from 'hooks/gridUI';
import { usePublicApiUrl } from 'hooks/workspace';

function CreateRecord() {
    const compositeViewId = useCompositeViewId();
    const responseRecordBody = useBodyResponseCreateRecord();
    const apiKey = useGetApiKey();
    const requestRecordBody = useBodyRequestCreateRecord();
    const publicApiUrl = usePublicApiUrl();

    let schema = {
        method: 'POST',
        url: `${publicApiUrl}/v1/views/${compositeViewId}/records`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        Authorization: apiKey,
        body: requestRecordBody
    };

    const response = responseRecordBody;

    return (
        <Grid>
            <DocSchema schema={schema} response={response} />
        </Grid>
    );
}

export default React.memo(CreateRecord);
