import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha, CircularProgress, Grid, Typography } from '@material-ui/core';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import DOMPurify from 'dompurify';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import ButtonBase from 'components/button/Base';
import {
    useIsBlockedGridByOwner,
    useSelectedAdvancedSearchById,
    useAdvancedSearch,
    useSelectedColumns,
    useColumns,
    useColumnWidthStore
    // useColumnIds,
} from 'hooks/advanced';
import InputText from 'components/inputs/InputText';
import { isKbEnter, isKbEscape } from 'utils/keyboard';
import { trim } from 'lodash';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useHistory, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notifier/actions';
import * as columnTypes from 'const/columnTypes';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import { ADVANCED_SEARCH_TAB, DEFAULT_COLUMN_WIDTH } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: 440,
        position: 'relative',
        '&:focus': {
            outline: 'none'
        }
    },

    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        },
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? alpha(props.buttonColor, 0.9) : '')
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function SaveAs({ onClose, searchTerm, isNew }) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const isBlockedGridByOwner = useIsBlockedGridByOwner();
    const selectedAdvancedSearch = useSelectedAdvancedSearchById();
    const advancedSearch = useAdvancedSearch();
    const [name, setName] = React.useState(
        `${isNew ? `` : `Copy of `}${selectedAdvancedSearch?.name || advancedSearch?.name || ''}`
    );
    const columns = useSelectedColumns();
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const columnList = useColumns();
    const history = useHistory();
    const columnWidthStore = useColumnWidthStore();
    // const columnIds = useColumnIds();

    const columnOptions = React.useMemo(() => {
        const opts = columnList?.map(col => {
            const detailColumns = col?.details;

            let columnDetail = {
                ...detailColumns?.[0],
                type: col?.type,
                metadata: detailColumns?.reduce((obj, col) => {
                    obj[col?.columnId] = col;
                    return obj;
                }, {}),
                columnIds: detailColumns?.map(col => col?.columnId)
            };

            if ([columnTypes.RECORD_ID, columnTypes.PATH_TAG]?.includes(columnDetail?.columnId)) {
                columnDetail = {
                    ...columnDetail,
                    type: columnDetail?.columnId
                };
            }

            delete columnDetail?.options;

            return {
                ...columnDetail,
                label: col?.columnName,
                value: columnDetail?.columnId,
                icon: () => <ColumnTypeDisplay {...columnDetail} />,
                hashColumnId: col?.hashColumnId,
                name: col?.columnName
            };
        });

        return [{ value: 0, label: 'All columns' }, ...opts];
    }, [columnList]);

    const handleSaveAs = React.useCallback(() => {
        if (trim(name) === '') {
            // onClose();
            return;
        }

        let body = {
            name: trim(name),
            store: true
        };

        if (columns?.length) {
            const items = columns?.map(col => {
                const option = columnOptions?.find(opt => opt?.columnIds?.includes(col?.columnId));
                const id = option?.hashColumnId || col?.hashColumnId;
                return {
                    id,
                    width: columnWidthStore?.[col?.hashColumnId] || DEFAULT_COLUMN_WIDTH
                };
            });

            body = {
                ...body,
                items,
                searchTerm: advancedSearch?.searchTerm || searchTerm
            };
        }

        setIsLoading(true);

        dispatch(
            advancedSearchActions.createAdvancedSearch({
                wsId: workspaceId,
                body,
                success: createdAdvancedSearchId => {
                    setIsLoading(false);
                    dispatch(advancedSearchActions.setSelectedAdvancedSearchId(createdAdvancedSearchId));
                    history.push(`/p/${workspaceId}/s/${createdAdvancedSearchId}`);
                    onClose && onClose();

                    dispatch(enqueueSnackbar({ message: `Saved as new as ${name}` }));
                    dispatch(advancedSearchActions.setTab(ADVANCED_SEARCH_TAB.OWNER));

                    dispatch(
                        advancedSearchActions.getAdvancedSearchList({
                            wsId: workspaceId,
                            shared: false,
                            params: {
                                limit: 400,
                                offset: 0
                            },
                            success: () => {
                                console.log('getAdvancedSearchList success');
                            },
                            error: () => {
                                console.log('getAdvancedSearchList failed');
                            }
                        })
                    );

                    dispatch(
                        advancedSearchActions.fetchAdvancedSearch({
                            wsId: workspaceId,
                            advancedSearchId: createdAdvancedSearchId,
                            success: () => {
                                console.log('fetchAdvancedSearch success');
                            },
                            error: () => {
                                console.log('fetchAdvancedSearch failed');
                            }
                        })
                    );
                },
                error: error => {
                    console.log('error update');
                    setIsLoading(false);
                }
            })
        );
        onClose();
    }, [
        advancedSearch,
        history,
        columnWidthStore,
        columns,
        columnOptions,
        dispatch,
        name,
        onClose,
        searchTerm,
        workspaceId
    ]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            onClose();
            return;
        }
        if (!isKbEnter(e)) return;
        handleSaveAs();
    };

    return (
        <>
            <DialogTitle
                title={
                    <Typography variant="h4" component="div">
                        Save as new query
                    </Typography>
                }
                onClose={onClose}
            />
            <DialogContent>
                <Grid container direction="column" spacing={4} className={classes.root}>
                    {isBlockedGridByOwner && (
                        <Grid item>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        `This query was created by <strong>${selectedAdvancedSearch?.author}</strong>. You can save a copy of this filter but you cannot modify the original.`,
                                        { USE_PROFILES: { html: true } }
                                    )
                                }}
                            ></Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <InputText
                            label={'Query name'}
                            name="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={'Input name'}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            className={classes.input}
                            autoSelect={true}
                            inputProps={{
                                maxLength: 255
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase width={107} onClick={onClose} variant="outlined">
                            Cancel
                        </ButtonBase>
                    </Grid>

                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase onClick={handleSaveAs} width={107} disabled={isLoading} variant="contained">
                                Save
                            </ButtonBase>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default SaveAs;
