import React from 'react';
import FormulaSVG from 'components/svg-icon/FormulaSVG';
import { UpperCaseFirstCharacter } from 'utils/name';

export const FUNCTIONS = [
    'sum',
    'average',
    'if',
    'ceil',
    'floor',
    'isEven',
    'isOdd',
    'log',
    'power',
    'sqrt',
    'count',
    'min',
    'max',
    'and',
    'or',
    'len',
    'mid',
    'concat',
    'round',
    'roundUp',
    'roundDown',
    'isBlank',
    'ifBlank',
    'startsWith',
    'endsWith',
    'lower',
    'upper',
    'join',
    'left',
    'right',
    'substitute',
    'replace',
    'trim',
    'split',
    'format',
    'regexMatch',
    'regexReplace',
    'encodeAsBase64',
    'wordCount',
    'now',
    'today',
    'isBefore',
    'isAfter',
    'toDateTime',
    'dateTimeFormat',
    'dateTimeTruncate',
    'year',
    'month',
    'day',
    'hour',
    'minute',
    'second',
    'isEqual',
    'list',
    'contains',
    'resultWithColor',
    'pathTagLen',
    'pathTagStartsWith',
    'pathTagEndsWith',
    'pathTagContains',
    'valueAt',
    'valueFirst',
    'valueLast',
    'true',
    'false',
    'isNotBlank',
    'isNotEqual',
    'isChecked',
    'isUnchecked',
    'longest',
    'shortest',
    'lastModifiedTime',
    'status',
    'isStatus',
    'isStatusUpToDate',
    'isStatusOutOfDate',
    'isStatusUnset',
    'extractDigits',
    'pathAsList',
    'color',
    'fuzzyMatching',
    'editDistance'
];

export function _clearTag(key) {
    return ['pathTagLen', 'pathTagStartsWith', 'pathTagEndsWith', 'pathTagContains']?.includes(key)
        ? key?.replace(/Tag/gi, '')
        : key;
}

export const FUNCTION_SUGGESTION_LIST = FUNCTIONS.map(key => ({
    type: 'func',
    label: UpperCaseFirstCharacter(_clearTag(key)),
    value: key,
    icon: () => <FormulaSVG />
}));

export const FUNCTIONS_LOWER_CASE = FUNCTIONS?.map(func => func?.toLowerCase());

export const DESERIALIZE_TYPES = {
    LITERAL: 'LITERAL',
    COMMA: 'COMMA',
    PARENTHESIS: 'PARENTHESIS',
    FUNCTION: 'FUNCTION',
    TEXT: 'TEXT',
    OPERATOR: 'OPERATOR',
    VARIABLE: 'VARIABLE',
    COMPARATOR: 'COMPARATOR',
    QUOTE: 'QUOTE',
    SPACE: 'SPACE',
    POINT: 'POINT',
    SPECIAL: 'SPECIAL',
    MENTION: 'MENTION',
    PAYLOAD: 'PAYLOAD'
};

export const FORMULA_RESULT_TYPE = {
    NA: 'na',
    LIST: 'list',
    STRING: 'string',
    DECIMAL: 'decimal',
    BOOLEAN: 'boolean',
    DATETIME: 'datetime'
};
