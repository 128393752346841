import React from 'react';

function SynchronizeTMPushSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_604_21538)">
                <path
                    d="M21.8824 12.0249C21.8824 12.4147 21.5663 12.7308 21.1765 12.7308C20.7866 12.7308 20.4706 12.4147 20.4706 12.0249V6.70455C20.4706 5.92757 19.8368 5.29446 19.0565 5.29446H11.644C11.0046 5.29446 10.2973 4.88456 9.97932 4.32809L9.26551 3.07892C9.19882 2.96221 8.95955 2.82387 8.82178 2.82387H2.82152C2.04281 2.82387 1.41176 3.45487 1.41176 4.23337V16.9429C1.41176 17.7201 2.04546 18.3533 2.82553 18.3533H16.2353C16.6251 18.3533 16.9412 18.6693 16.9412 19.0592C16.9412 19.449 16.6251 19.7651 16.2353 19.7651H2.82553C1.26601 19.7651 0 18.5 0 16.9429V4.23337C0 2.67516 1.26313 1.41211 2.82152 1.41211H8.82178C9.46531 1.41211 10.1728 1.82116 10.4913 2.37848L11.2051 3.62766C11.2715 3.74398 11.5109 3.8827 11.644 3.8827H19.0565C20.6161 3.8827 21.8824 5.14753 21.8824 6.70455V12.0249ZM20.4706 16.1751V19.0592C20.4706 19.449 20.1546 19.7651 19.7647 19.7651C19.3749 19.7651 19.0588 19.449 19.0588 19.0592V16.1751L18.4991 16.7348C18.2235 17.0104 17.7765 17.0104 17.5009 16.7348C17.2252 16.4591 17.2252 16.0122 17.5009 15.7365L19.2656 13.9718C19.5412 13.6961 19.9882 13.6961 20.2638 13.9718L22.0285 15.7365C22.3042 16.0122 22.3042 16.4591 22.0285 16.7348C21.7529 17.0104 21.3059 17.0104 21.0303 16.7348L20.4706 16.1751ZM16.2353 22.5886C15.8454 22.5886 15.5294 22.2725 15.5294 21.8827C15.5294 21.4928 15.8454 21.1768 16.2353 21.1768H23.2941C23.684 21.1768 24 21.4928 24 21.8827C24 22.2725 23.684 22.5886 23.2941 22.5886H16.2353Z"
                    fill={color}
                />
                <path d="M9.23318 8.86852H7.22529V14.3906H6.1152V8.86852H4.125V7.96875H9.23318V8.86852Z" fill={color} />
                <path
                    d="M11.533 7.96875L13.3905 12.891L15.2436 7.96875H16.6898V14.3906H15.5753V12.2735L15.6859 9.4419L13.7841 14.3906H12.9836L11.0863 9.44631L11.1968 12.2735V14.3906H10.0823V7.96875H11.533Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_604_21538">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(SynchronizeTMPushSVG);
