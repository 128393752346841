import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ButtonGroup, Button } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    buttonGroup: {
        '& > button': {
            border: 'none',
            background: theme.colors.paleGrey,
            color: theme.colors.steel,
            fontWeight: 400,
            '&:hover': {
                border: 'none',
                background: theme.colors.lightLavender,
                color: theme.colors.white
            }
        }
    },
    active: {
        background: `${theme.colors.lightLavender} !important`,
        color: `${theme.colors.white} !important`
    }
}));

function FilterMenu({ handleClickAway, commentAuthor, commentStatus, onStatusChange, onAuthorChange }) {
    const classes = useStyles();
    const rootRef = React.useRef();
    const { t } = useTranslation();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    return (
        <Grid ref={rootRef} direction="column" container className={classes.root}>
            <Grid item className={classes.spacing}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <p className="body1">{t('global_status')}</p>
                    </Grid>
                    <Grid item>
                        <ButtonGroup
                            className={classes.buttonGroup}
                            color="primary"
                            aria-label="contained button group"
                        >
                            <Button
                                onClick={() => onStatusChange('all')}
                                className={commentStatus === 'all' ? classes.active : ``}
                            >
                                {t('global_all')}
                            </Button>
                            <Button
                                onClick={() => onStatusChange('open')}
                                className={commentStatus === 'open' ? classes.active : ``}
                            >
                                {t('global_open')}
                            </Button>
                            <Button
                                onClick={() => onStatusChange('resolved')}
                                className={commentStatus === 'resolved' ? classes.active : ``}
                            >
                                {t('global_resolved')}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <p className="body1">Author</p>
                    </Grid>
                    <Grid item>
                        <ButtonGroup
                            className={classes.buttonGroup}
                            color="primary"
                            aria-label="contained button group"
                        >
                            <Button
                                onClick={() => onAuthorChange('all')}
                                className={commentAuthor === 'all' ? classes.active : ``}
                            >
                                All
                            </Button>
                            <Button
                                onClick={() => onAuthorChange('onlyMe')}
                                className={commentAuthor === 'onlyMe' ? classes.active : ``}
                            >
                                Only me
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    );
}

export default React.memo(FilterMenu);
