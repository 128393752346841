import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';

const searchApis = {
    cellData: projectId => `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${projectId}/search`,
    gridData: projectId => `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${projectId}/grids/search`
};

export const searchCellDataApi = async ({ projectId, queryParams }) => {
    const response = await request.get(`${searchApis.cellData(projectId)}?${objectToQueryParams(queryParams)}`);
    return response;
};

export const searchGridApi = async ({ projectId, queryParams }) => {
    const response = await request.get(`${searchApis.gridData(projectId)}?${objectToQueryParams(queryParams)}`);
    return response;
};
