import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Chip } from '@material-ui/core';

function ViewCellInfo({ rowIndex = 'N/A', overrideStyle = {} }) {
    const theme = useTheme();
    return (
        <Grid
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                transform: 'translateX(-100%)',
                display: 'flex',
                ...overrideStyle
            }}
        >
            <Chip
                style={{
                    borderRadius: 0,
                    fontSize: 12,
                    minWidth: 20,
                    height: 25,
                    background: theme.colors.highlight,
                    color: theme.colors.white
                }}
                size="small"
                label={rowIndex + 1}
            />
        </Grid>
    );
}

ViewCellInfo.propTypes = {
    rowIndex: PropTypes.number.isRequired
};

export default React.memo(ViewCellInfo);
