import React from 'react';

function ExportJsonSVG({ color = '#FFF', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z"
                fill="#2D97F2"
                fillOpacity="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0543 11.75C16.2959 11.75 16.4975 11.9214 16.5441 12.1492L16.5543 12.25V14.1767H20.6111C20.8527 14.1767 21.0543 14.3481 21.101 14.5759L21.1111 14.6767V15.5115C21.1111 15.7531 20.9397 15.9547 20.7119 16.0013L20.6111 16.0115H16.5231C16.9384 17.5681 17.899 19.0712 19.4048 20.5208C19.5251 20.6365 19.5781 20.8028 19.5512 20.9634L19.5255 21.0587L19.2409 21.8068C19.2134 21.8791 19.1694 21.9441 19.1125 21.9966C18.9094 22.1838 18.5931 22.1709 18.406 21.9679C17.1191 20.572 16.2834 19.4053 15.8989 18.4678C15.2378 20.1653 13.6698 21.8777 11.1949 23.605C11.1585 23.6304 11.1188 23.6509 11.0769 23.6659C10.8494 23.7472 10.6018 23.6536 10.4813 23.4546L10.4378 23.3631L10.1355 22.5168C10.057 22.2969 10.141 22.052 10.3379 21.9267C12.9706 20.2505 14.3873 18.4958 14.588 16.6626L14.629 16.4531L14.6816 16.0411H10.5C10.2584 16.0411 10.0568 15.8697 10.0102 15.6419L10 15.5411V14.7063C10 14.4647 10.1714 14.2631 10.3992 14.2164L10.5 14.2063H14.588V12.25C14.588 12.0084 14.7594 11.8068 14.9872 11.7602L15.088 11.75H16.0543ZM24.4444 15.0833C25.1273 15.0833 25.7419 15.4807 26.0263 16.0905L26.0904 16.2473L29.7642 26.6389C29.7832 26.6924 29.7928 26.7487 29.7928 26.8055C29.7928 27.0471 29.6214 27.2487 29.3936 27.2954L29.2928 27.3055H28.5074C28.3234 27.3055 28.1569 27.2048 28.07 27.0479L28.0335 26.9649L26.9135 23.6359H21.9752L20.8552 26.9649C20.7966 27.1394 20.648 27.265 20.4716 27.2973L20.3813 27.3055H19.5959C19.5391 27.3055 19.4828 27.2958 19.4292 27.2769C19.2014 27.1964 19.0685 26.9676 19.1005 26.7372L19.1245 26.6389L22.7983 16.2473C23.0449 15.5497 23.7045 15.0833 24.4444 15.0833ZM22.5926 21.8011L24.4445 16.9181L26.2963 21.8307L22.5926 21.8011Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(ExportJsonSVG);
