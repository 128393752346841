import React from 'react';
import moment from 'moment';
import { js } from 'js-beautify';
import * as columnTypes from 'const/columnTypes';
import UserViewSVG from 'assets/images/svg/UserViewSVG';
import AccessViewSVG from 'assets/images/svg/AccessViewSVG';
import SettingSVG from 'assets/images/svg/SettingSVG';
import { convertArrayToObject } from 'utils/object';
import { generateColumnDataWithoutRecordId } from 'utils/gridUI/column';
import { isJson } from 'utils/checkType';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import { VIEW_TYPES, PREVIEW_STATUS } from 'const/gridUI';
import { isLDEmpty } from 'utils/object';
import { SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH } from 'const/languageData';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import { RECORD_IDS_MATCHES, PATH_TAG_IDS } from 'const';
import { DATE_FORMAT, TIME_FORMAT, getDateWithLocalTimezone, getDateAfter, TIME_FORMAT_24h } from 'utils/datetime';

export function formatDataByType({ type, data }) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
            return;
        case columnTypes.MULTIPLE_LINES:
            return;
        case columnTypes.JSON_LD:
            return;
        case columnTypes.HTML:
            return;
        case columnTypes.YAML:
            return;
        case columnTypes.SINGLE_SELECTION:
            return;
        case columnTypes.FILES:
            return formatFiles(data);
        case columnTypes.MULTIPLE_SELECTIONS:
            return formatMultiSelect(data);
        case columnTypes.GROUP_TAGS:
            return formatGroupTags(data);
        default:
            return;
    }
}

function formatMultiSelect(data) {
    return data.split(',');
}

function formatGroupTags(data) {
    return data.split(',');
}

function formatFiles(data) {
    return JSON.parse(data);
}

export function formatDateTime(datetime) {
    if (!datetime) return null;
    if (typeof datetime === 'boolean') {
        return null;
    }
    if (typeof datetime === 'number') {
        datetime = datetime?.toString();
    }
    const [date, time] = datetime.split('T');
    const timeFormat = time.slice(0, 8);
    return `${date} ${timeFormat}`;
}

export function convertFormatDateTime(datetime) {
    if (!datetime) return null;
    if (typeof datetime === 'boolean') {
        return null;
    }
    if (typeof datetime === 'number') {
        return null;
    }
    if (!datetime || !(datetime?.includes('T') && datetime?.includes('Z'))) return null;
    if (datetime?.includes('Z') && datetime?.includes('T')) return datetime;
    const [date, time] = datetime?.split(' ');
    return `${date}T${time}${time?.includes('.') ? '00Z' : '.000Z'}`;
}

export function generateDateRange(datetime) {
    const startDate = getDateWithLocalTimezone(datetime);

    const nextDate = getDateAfter({ date: startDate, numOfDay: 1 });
    return [startDate, nextDate?.subtract(1, 'second')];
}

export function formatDateTimeLocal(datetime, UTC) {
    if (typeof datetime === 'boolean') {
        return [null, null];
    }

    if (typeof datetime === 'number') {
        datetime = datetime?.toString();
    }

    if (!datetime || !(datetime?.includes('T') && datetime?.includes('Z'))) return [null, null];
    const newTime = convertFormatDateTime(datetime);
    const newTimeLocal = moment(newTime).local();

    return UTC
        ? [newTimeLocal.utc().format(DATE_FORMAT), newTimeLocal.utc().format(TIME_FORMAT_24h)]
        : [newTimeLocal.format(DATE_FORMAT), newTimeLocal.format(TIME_FORMAT)];
}

export function formatMetaData({ meta, viewColumns }) {
    return convertArrayToObject(
        meta?.map(gridColumn => {
            let found = viewColumns.find(viewColumn => viewColumn?.id === gridColumn?.id);
            if (found) {
                gridColumn.editable = found.editable;
                gridColumn.viewable = found.viewable;
            }
            return {
                ...gridColumn
            };
        }),
        'id'
    );
}

function isMatchConvertWord({ record, langName }) {
    if (!record) return false;
    const words = record?.split(' ');
    let match = [];

    for (let word of words) {
        if (
            langName
                ?.toLowerCase()
                ?.split(' ')
                ?.includes(word?.toLowerCase())
        ) {
            match.push(word);
        }
    }

    return words?.length === match?.length;
}

function isMatchFuzzyLanguage(record) {
    let isMatch = false;
    let originalId = null;

    if (isMatchFuzzyRecordId(record)) {
        return {
            isMatch: true,
            originalId: TRANSLATION_TYPES.RECORD_ID,
            isRecordId: true
        };
    }

    if (isMatchFuzzyPathTagId(record)) {
        return {
            isMatch: true,
            originalId: TRANSLATION_TYPES.PATH_TAG,
            isPathTag: true
        };
    }

    for (let language of SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH) {
        if (language?.id?.length === 2) continue;
        const fuzzyLower = language?.fuzzy?.map(i => i?.toLowerCase()) || [];
        if (
            fuzzyLower.includes(record?.toLowerCase()) ||
            isMatchConvertWord({ record, langName: language?.nameWithoutClosingBracket })
        ) {
            isMatch = true;
            originalId = language?.id;
            break;
        }
    }
    return {
        isMatch,
        originalId
    };
}

function isMatchFuzzyRecordId(word) {
    return RECORD_IDS_MATCHES?.map(i => i?.toLowerCase())?.includes(word?.toLowerCase());
}

function isMatchFuzzyPathTagId(word) {
    return PATH_TAG_IDS?.map(i => i?.toLowerCase())?.includes(word?.toLowerCase());
}

export function generateColumnMetaDataByHeaders(headers) {
    const metaData = {};

    let isHavingSource = false;
    let isHavingRecordId = false;
    let isHavingPathTag = false;

    let mapTargetColumn = {};

    for (const header of headers) {
        const { isMatch, originalId, isRecordId, isPathTag } = isMatchFuzzyLanguage(header);

        if (isRecordId) {
            if (!isHavingRecordId) {
                metaData[header] = {
                    name: header,
                    type: columnTypes.RECORD_ID
                };
                isHavingRecordId = true;
            }
        } else if (isPathTag) {
            if (!isHavingPathTag) {
                metaData[header] = {
                    name: header,
                    type: columnTypes.PATH_TAG
                };
                isHavingPathTag = true;
            }
        } else if (isMatch) {
            if (!isHavingSource) {
                metaData[header] = {
                    group: originalId,
                    name: header,
                    type: columnTypes.TRANSLATION,
                    languageType: TRANSLATION_TYPES.SOURCE_LANG
                };
                isHavingSource = true;
            } else {
                if (!mapTargetColumn.hasOwnProperty(header)) {
                    metaData[header] = {
                        group: originalId,
                        name: header,
                        type: columnTypes.TRANSLATION,
                        languageType: TRANSLATION_TYPES.TARGET_LANG
                    };
                    mapTargetColumn = {
                        ...mapTargetColumn,
                        [header]: true
                    };
                }
            }
        } else {
            metaData[header] = {
                name: header,
                type: columnTypes.MULTIPLE_LINES
            };
        }
    }

    return metaData;
}

export function formatGridUIResponseForLocalizationImport({ records = [], columns = [], statusMaps = {} }) {
    const rows = [];
    const data = {};
    let metaData = {};
    let columnMap = {};
    let dependencyStatusData = {};

    const firstRecord = records?.[0];
    const recordIdIndex = firstRecord.findIndex(word => isMatchFuzzyRecordId(word));

    let isHavingSource = false;
    let isHavingRecordId = false;
    let isHavingPathTag = false;

    let mapTargetColumn = {};

    firstRecord.forEach((record, i) => {
        const { isMatch, originalId, isRecordId, isPathTag } = isMatchFuzzyLanguage(record);
        const columnId = columns?.[i];
        if (isRecordId) {
            if (!isHavingRecordId) {
                columnMap[columnId] = {
                    index: columnId,
                    importType: TRANSLATION_TYPES.RECORD_ID
                };
                isHavingRecordId = true;
            }
        } else if (isPathTag) {
            if (!isHavingPathTag) {
                columnMap[columnId] = {
                    index: columnId,
                    importType: TRANSLATION_TYPES.PATH_TAG
                };
                isHavingPathTag = true;
            }
        } else if (isMatch) {
            if (!isHavingSource) {
                columnMap[columnId] = {
                    country: originalId,
                    index: columnId,
                    importType: TRANSLATION_TYPES.SOURCE_LANG
                };
                isHavingSource = true;
            } else {
                if (!mapTargetColumn.hasOwnProperty(record)) {
                    columnMap[columnId] = {
                        country: originalId,
                        index: columnId,
                        importType: TRANSLATION_TYPES.TARGET_LANG
                    };
                    mapTargetColumn = {
                        ...mapTargetColumn,
                        [record]: true
                    };
                }
            }
        } else {
            columnMap[columnId] = {
                index: columnId,
                importType: columnTypes.MULTIPLE_LINES,
                name: record
            };
        }
        if (columnMap[columnId]) {
            columnMap[columnId].name = record;
        }
    });

    for (let i = 0; i < columns.length; i++) {
        const columnId = columns[i];
        metaData = {
            ...metaData,
            [columnId]: {
                id: columnId,
                name: firstRecord?.[i]
            }
        };
    }

    for (let i = 0; i < records.length; i++) {
        const record = records?.[i];
        const recordId = recordIdIndex !== -1 ? record?.[recordIdIndex] : i + 1;

        data[recordId] = generateColumnDataWithoutRecordId(recordId, columns, record);

        if (i >= 1) {
            const recordDependencyStatusData = statusMaps?.[i - 1] || {};
            let newRecordDependencyStatusData = {};
            const keys = Object.keys(recordDependencyStatusData);

            for (const key of keys) {
                newRecordDependencyStatusData[key - 1] = recordDependencyStatusData?.[key];
            }
            dependencyStatusData[recordId] = newRecordDependencyStatusData;
        }

        rows.push(recordId);
    }

    return {
        data,
        rows,
        metaData,
        columnMap,
        firstRecord,
        dependencyStatusData
    };
}

export function formatGridUIResponseForFileImport({ records = [], columns = [] }) {
    const rows = [];
    const data = {};
    let metaData = {};
    let columnMap = {};

    const firstRecord = records?.[0];
    const recordIdIndex = firstRecord.findIndex(word => isMatchFuzzyRecordId(word));

    let isHavingRecordId = false;
    let isHavingPathTag = false;

    firstRecord.forEach((record, i) => {
        const isRecordId = isMatchFuzzyRecordId(record);
        const isPathTag = isMatchFuzzyPathTagId(record);
        const columnId = columns?.[i];

        if (isRecordId) {
            if (!isHavingRecordId) {
                columnMap[columnId] = {
                    index: columnId,
                    columnName: columnTypes.RECORD_ID,
                    columnType: columnTypes.RECORD_ID,
                    originalName: record
                };
                isHavingRecordId = true;
            } else {
                columnMap[columnId] = {
                    index: columnId,
                    columnName: record,
                    columnType: columnTypes.MULTIPLE_LINES,
                    originalName: record
                };
            }
        } else if (isPathTag) {
            if (!isHavingPathTag) {
                columnMap[columnId] = {
                    index: columnId,
                    columnName: columnTypes.PATH_TAG,
                    columnType: columnTypes.PATH_TAG,
                    originalName: record
                };
                isHavingPathTag = true;
            } else {
                columnMap[columnId] = {
                    index: columnId,
                    columnName: record,
                    columnType: columnTypes.MULTIPLE_LINES,
                    originalName: record
                };
            }
        } else {
            columnMap[columnId] = {
                index: columnId,
                columnName: record,
                columnType: columnTypes.MULTIPLE_LINES,
                originalName: record
            };
        }
    });

    for (let i = 0; i < columns.length; i++) {
        const columnId = columns[i];
        metaData = {
            ...metaData,
            [columnId]: {
                id: columnId,
                name: firstRecord?.[i]
            }
        };
    }

    for (let i = 0; i < records.length; i++) {
        const record = records?.[i];
        const recordId = recordIdIndex !== -1 ? record?.[recordIdIndex] : i + 1;

        data[recordId] = generateColumnDataWithoutRecordId(recordId, columns, record);
        rows.push(recordId);
    }
    return {
        data,
        rows,
        metaData,
        columnMap,
        firstRecord
    };
}

export function columnsMappingWithMetaData(columns, metaData) {
    return columns?.reduce((obj, columnId) => {
        const column = metaData?.[columnId];

        const isTransation = column?.type === columnTypes.TRANSLATION;
        const isSource = column?.customProperties?.localizationType === TRANSLATION_TYPES.SOURCE_LANG;

        obj[columnId] = {
            ...column,
            index: columnId,
            columnName: column?.name,
            columnType: column?.type,
            originalName: column?.name,
            importType: isTransation
                ? isSource
                    ? TRANSLATION_TYPES.SOURCE_LANG
                    : TRANSLATION_TYPES.TARGET_LANG
                : columnTypes.RECORD_ID,
            country: column?.group
        };
        return obj;
    }, {});
}

export function getFileServerValue(rowData) {
    if (!rowData) return null;
    if (isEmpty(rowData)) return null;
    if (!isArray(rowData)) return null;
    return rowData?.map(file => file?.id);
}

export function getPathTagServerValue(rowData) {
    if (!rowData) return null;
    if (isEmpty(rowData)) return null;
    if (!isArray(rowData)) return null;
    return rowData?.map(path => path?.name)?.join('/');
}

export function getLocalizationServerValue(rowData) {
    return formatSelectedOptLocalization(rowData);
}

export function formatSelectedOptLocalization(rowData) {
    if (!rowData) return null;
    const getJsonValue = data => {
        if (!isArray(data)) return null;
        let firstData = data && data[0];
        let value = firstData?.referencedRecordId;
        let option = {
            value: value,
            label: firstData?.referencedDataItem
        };
        return [value, option];
    };

    let [value, option] = getJsonValue(rowData);
    if (isEmpty(option)) return null;
    return {
        value,
        option
    };
}

export function getReferenceServerValue(rowData) {
    return formatSelectedOptRef(rowData);
}

export function formatSelectedOptRef(rowData) {
    if (!rowData) return null;
    const getJsonValue = data => {
        if (!isArray(data)) return [null, null];
        let values = [];
        let options = [];
        data.forEach(i => {
            values.push(i.referencedRecordId);
            options.push({
                value: i.referencedRecordId,
                label: i.referencedDataItem,
                originLabel: i.referencedDataItem
            });
        });
        return [values, options];
    };

    let [values, options] = getJsonValue(rowData);
    if (!options || !options.length) return null;
    return {
        values,
        options
    };
}

export function formatOptions(options) {
    if (!options) return [];
    return options.map(option => ({
        id: option.id,
        label: option.label
    }));
}

export function formatGridReferenceId({ column }) {
    let referenceSettings = column?.referenceSettings;
    if (!referenceSettings) return null;
    return referenceSettings?.referencedGridId;
}

export function formatBranchReferenceId({ column }) {
    let referenceSettings = column?.referenceSettings;
    if (!referenceSettings) return null;
    return referenceSettings?.referencedBranchId;
}

export function formatColumnReferenceId({ column }) {
    let referenceSettings = column?.referenceSettings;
    if (!referenceSettings) return null;
    return referenceSettings?.referencedColumnId;
}

function _getSingleOption({ defaultValue, options }) {
    if (typeof defaultValue !== 'string') return null;
    return options?.find(option => option?.label === defaultValue)?.id;
}

function _getMultiOption({ defaultValue, options }) {
    if (!Array.isArray(defaultValue)) return [];

    return defaultValue
        ?.filter(value => {
            return options?.findIndex(option => option?.label === value) !== -1;
        })
        ?.map(value => {
            return options?.find(option => option?.label === value)?.id;
        });
}

export function formatDefaultValue({ column }) {
    const columnType = column?.type;

    switch (columnType) {
        case columnTypes.SINGLE_SELECTION:
            return _getSingleOption({ defaultValue: column?.defaultValue, options: column?.options });
        case columnTypes.MULTIPLE_SELECTIONS:
            return _getMultiOption({ defaultValue: column?.defaultValue, options: column?.options });
        default:
            return column?.defaultValue;
    }
}

export function convertServerDefaultValue({ columnType, defaultValue, options }) {
    switch (columnType) {
        case columnTypes.SINGLE_SELECTION:
            return options?.find(option => option?.id === defaultValue)?.label;
        case columnTypes.MULTIPLE_SELECTIONS: {
            if (!Array.isArray(defaultValue)) return [];
            return defaultValue
                ?.filter(optionId => {
                    return options?.findIndex(option => option?.id === optionId) !== -1;
                })
                ?.map(optionId => {
                    return options?.find(option => option?.id === optionId)?.label;
                });
        }

        default:
            return defaultValue;
    }
}

export const getBooleanState = value => {
    if (value === false) {
        return 2;
    } else if (value === true) {
        return 1;
    } else {
        return 0;
    }
};

export function getViewIconByType(view, color) {
    try {
        let viewType = view?.type;
        switch (viewType) {
            case VIEW_TYPES.ACCESS_VIEW:
                return <AccessViewSVG color={color} />;
            case VIEW_TYPES.USER_VIEW:
                return <UserViewSVG color={color} />;
            case VIEW_TYPES.DEFAULT_VIEW: {
                return <SettingSVG color={color} />;
            }
            default:
                return <SettingSVG color={color} />;
        }
    } catch (error) {
        return <AccessViewSVG color={color} />;
    }
}

export const getBooleanValue = state => {
    if (state === 0) {
        return '';
    } else if (state === 1) {
        return true;
    } else {
        return false;
    }
};

export function getViewBackgroundByType({ view, theme }) {
    try {
        let viewType = view?.type;
        switch (viewType) {
            case VIEW_TYPES.ACCESS_VIEW:
                return theme.colors.fuchsiaBlue;
            case VIEW_TYPES.USER_VIEW:
                return theme.colors.atlantis;
            case VIEW_TYPES.DEFAULT_VIEW:
                return theme.colors.dodgerBlue;
            default:
                return theme.colors.dodgerBlue;
        }
    } catch (error) {
        return theme.colors.fuchsiaBlue;
    }
}

export function getCustomProperties(object) {
    return object?.customProperties;
}

export function getPropertyInCustomProperties(object, property, defaultValue) {
    return getCustomProperties(object)?.[property] || defaultValue;
}

export function getCorrectColumnType(column = {}) {
    const type = column?.type;
    const customProperties = column?.customProperties;
    if (isEmpty(customProperties)) return type;
    let subType = customProperties?.subType;
    if (!subType) return type;
    if (subType === columnTypes.LOCALIZATION && type === columnTypes.REFERENCE) return columnTypes.LOCALIZATION;
    if (subType === columnTypes.FORMULA && type === columnTypes.SINGLE_LINE) return columnTypes.FORMULA;
    return type;
}

export function formatRequest({ body, banProperties = [] }) {
    let newBody = cloneDeep(body);
    banProperties.forEach(property => {
        if (newBody[property]) delete newBody[property];
    });
    return newBody;
}

export function formatFormula(rowData) {
    if (!rowData) return { formula: undefined, value: undefined };
    let isValidJson = isJson(rowData);
    if (!isValidJson) return rowData;
    let newRowData = JSON.parse(rowData);
    if (!isArray(newRowData)) return { formula: undefined, value: undefined };
    let [formula, value] = newRowData;
    return {
        formula,
        value
    };
}

function checkIsUpdateOrDeleteDependencyStatus({
    previousDependencyStatus,
    dependencyStatus,
    sourceStatus,
    previousSourceStatus
}) {
    if (!previousDependencyStatus && !dependencyStatus && !sourceStatus && !previousSourceStatus) {
        return PREVIEW_STATUS.NORMAL;
    }

    if (previousDependencyStatus && dependencyStatus) {
        if (previousDependencyStatus === dependencyStatus) {
            if (sourceStatus && previousSourceStatus) {
                if (sourceStatus !== previousSourceStatus) {
                    return PREVIEW_STATUS.UPDATED;
                }
            }
            return PREVIEW_STATUS.NORMAL;
        }
        return PREVIEW_STATUS.UPDATED;
    }

    if (sourceStatus && previousSourceStatus) {
        if (sourceStatus === previousSourceStatus) {
            if (previousDependencyStatus && dependencyStatus) {
                if (previousDependencyStatus !== dependencyStatus) {
                    return PREVIEW_STATUS.UPDATED;
                }
            }
            return PREVIEW_STATUS.NORMAL;
        }
        return PREVIEW_STATUS.UPDATED;
    }

    return PREVIEW_STATUS.UPDATED;
}

export function checkIsCellUpdateOrDelete({
    previousData,
    data,
    previousDependencyStatus,
    dependencyStatus,
    sourceStatus,
    previousSourceStatus
}) {
    if (!isLDEmpty(data) && !isLDEmpty(previousData)) {
        if (JSON.stringify(previousData) !== JSON.stringify(data)) {
            return PREVIEW_STATUS.UPDATED;
        } else {
            return (
                checkIsUpdateOrDeleteDependencyStatus({
                    previousDependencyStatus,
                    dependencyStatus,
                    sourceStatus,
                    previousSourceStatus
                }) || PREVIEW_STATUS.NORMAL
            );
        }
    } else if (isLDEmpty(previousData) && !isLDEmpty(data)) {
        return PREVIEW_STATUS.UPDATED;
    } else if (!isLDEmpty(previousData) && isLDEmpty(data)) {
        return PREVIEW_STATUS.DELETED;
    } else {
        return (
            checkIsUpdateOrDeleteDependencyStatus({
                previousDependencyStatus,
                dependencyStatus,
                sourceStatus,
                previousSourceStatus
            }) || PREVIEW_STATUS.NORMAL
        );
    }
}

export function formatRecordHistory(recordHistory = []) {
    return recordHistory.map(history => {
        const cellHistories = history.cellHistories;
        let objectColumns = {};
        cellHistories.forEach(cellHistory => {
            const { columnId, ...rest } = cellHistory;
            objectColumns[columnId] = {
                ...rest
            };

            objectColumns[columnTypes.ALTERED_BY] = {
                columnType: columnTypes.ALTERED_BY,
                data: history?.alteredBy,
                previousData: history?.alteredBy,
                previousColumnType: columnTypes.ALTERED_BY
            };

            objectColumns[columnTypes.ALTERED_TIME] = {
                columnType: columnTypes.DATETIME,
                data: history?.alteredTime,
                previousData: history?.alteredTime,
                previousColumnType: columnTypes.DATETIME
            };

            objectColumns[columnTypes.CREATED_BY] = {
                columnType: columnTypes.CREATED_BY,
                data: history?.createdBy,
                previousData: history?.createdBy,
                previousColumnType: columnTypes.CREATED_BY
            };

            objectColumns[columnTypes.CREATED_TIME] = {
                columnType: columnTypes.CREATED_TIME,
                data: history?.createdTime,
                previousData: history?.createdTime,
                previousColumnType: columnTypes.CREATED_TIME
            };
        });
        return {
            ...history,
            cellHistories: objectColumns
        };
    });
}

export function formatDataGridPreview(records = []) {
    let data = {};
    let rows = [];
    for (const record of records) {
        const cells = record.cells;
        const recordId = record?.id || record?.publicId;
        rows = [...rows, recordId];
        let objectColumns = {};
        for (const cell of cells) {
            const columnId = cell?.columnId;
            objectColumns[columnId] = cell;
        }

        // objectColumns[columnTypes.RECORD_ID] = {
        //     childData: record,
        //     parentData: recordId
        // };

        data[recordId] = {
            ...record,
            ...objectColumns
        };
    }

    return {
        rows,
        data
    };
}

export function formatMetaDataGridPreview({ childColumns, parentColumns }) {
    const parentColIds = parentColumns?.map(col => col?.id);
    const childColumnsFormat = childColumns?.map(column => {
        if (!parentColIds?.includes(column?.id)) {
            return {
                ...column,
                diffStatus: PREVIEW_STATUS.ADDED
            };
        }

        const found = parentColumns?.find(col => col?.id === column?.id);

        if (found && JSON.stringify(found) !== JSON.stringify(column)) {
            return {
                ...column,
                diffStatus: PREVIEW_STATUS.UPDATED
            };
        }

        return {
            ...column,
            diffStatus: PREVIEW_STATUS.NORMAL
        };
    });
    return convertArrayToObject(childColumnsFormat, 'id');
}

export function beautifyJSONValue(value) {
    return js(value, {
        indent_size: '4',
        indent_char: ' ',
        max_preserve_newlines: '5',
        preserve_newlines: true,
        keep_array_indentation: false,
        break_chained_methods: false,
        indent_scripts: 'normal',
        brace_style: 'collapse',
        space_before_conditional: true,
        unescape_strings: false,
        jslint_happy: false,
        end_with_newline: false,
        wrap_line_length: '0',
        indent_inner_html: false,
        comma_first: false,
        e4x: false,
        indent_empty_lines: false
    });
}

export function formatTranslationStatistic(columnStatistics = []) {
    const obj = {};
    columnStatistics.forEach(column => {
        obj[column?.columnId] = column?.dependencyStatistic;
    });
    return obj;
}
