import React from 'react';
import ListItem from 'components/list/Item';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 220
    }
}));

function OptionMenu({ options, selectedOption, handleClickAway, onChange }) {
    const classes = useStyles();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    return (
        <Grid ref={rootRef} className={classes.root} container direction="column">
            {options.map(option => (
                <Grid key={option?.value} id={`addGridFromScratch`} onClick={() => onChange(option)} item>
                    <ListItem isSelected={selectedOption?.value === option?.value} name={option?.label} />
                </Grid>
            ))}
        </Grid>
    );
}
export default React.memo(OptionMenu);
