import React from 'react';

const GoogleCalendarSVG = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.125 4.875H4.875V13.125H13.125V4.875Z" fill="white" />
            <path
                d="M9.63086 7.84545L10.0089 8.38545L10.6029 7.95345V11.0854H11.2509V6.98145H10.7109L9.63086 7.84545Z"
                fill="#1E88E5"
            />
            <path
                d="M8.60363 8.90442C8.838 8.68917 8.9835 8.39067 8.9835 8.06105C8.9835 7.40592 8.40862 6.87305 7.70212 6.87305C7.10137 6.87305 6.58762 7.25142 6.45337 7.79292L7.07475 7.9508C7.13662 7.7018 7.40025 7.52105 7.70212 7.52105C8.05537 7.52105 8.343 7.7633 8.343 8.06105C8.343 8.3588 8.05537 8.60105 7.70212 8.60105H7.32825V9.24905H7.70212C8.1075 9.24905 8.4495 9.53067 8.4495 9.86405C8.4495 10.203 8.12475 10.479 7.72538 10.479C7.36463 10.479 7.05638 10.2503 7.00763 9.9473L6.375 10.0508C6.47325 10.6643 7.05375 11.127 7.725 11.127C8.47763 11.127 9.09 10.5604 9.09 9.86405C9.09 9.48042 8.901 9.13617 8.60363 8.90442Z"
                fill="#1E88E5"
            />
            <path d="M12.75 15.75H5.25L4.875 14.25L5.25 12.75H12.75L13.125 14.25L12.75 15.75Z" fill="#FBC02D" />
            <path d="M14.25 13.125L15.75 12.75V5.25L14.25 4.875L12.75 5.25V12.75L14.25 13.125Z" fill="#4CAF50" />
            <path
                d="M12.75 5.25L13.125 3.75L12.75 2.25H3.375C2.75363 2.25 2.25 2.75363 2.25 3.375V12.75L3.75 13.125L5.25 12.75V5.25H12.75Z"
                fill="#1E88E5"
            />
            <path d="M12.75 12.75V15.75L15.75 12.75H12.75Z" fill="#E53935" />
            <path d="M14.625 2.25H12.75V5.25H15.75V3.375C15.75 2.75363 15.2464 2.25 14.625 2.25Z" fill="#1565C0" />
            <path d="M3.375 15.75H5.25V12.75H2.25V14.625C2.25 15.2464 2.75363 15.75 3.375 15.75Z" fill="#1565C0" />
        </svg>
    );
};

export default React.memo(GoogleCalendarSVG);
