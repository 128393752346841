import * as roleConst from 'auth/roleConst';
import { enabledRegion } from 'config';
import { API_URL } from 'config';
import store from 'store/configStore';

export function getWorkspaceRole({ role, workspaceRole }) {
    return role?.[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE]
        ? roleConst.CREATOR
        : workspaceRole || roleConst.VIEWER;
}

export function getUserViewSettingByProperty({ workspace, wsId, dbId, viewId, property }) {
    return workspace.setting?.[wsId]?.[dbId]?.views?.[viewId]?.value?.[property];
}

export function getRegionBaseUrl() {
    if (!enabledRegion) return API_URL;
    const state = store.getState();
    const selectedRegionName = state?.workspace?.selectedWorkspace?.region;
    const region = state?.workspace?.regions?.find(r => r.name === selectedRegionName);
    const baseUrl = region?.baseUrl;
    // if (!region?.enabled) return API_URL;
    return baseUrl || API_URL;
}
