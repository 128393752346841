import React, { useMemo } from 'react';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    }
}));

function OptionSelection({ column, defaultValue, handleOptionChange, isMulti = false, disabled, t }) {
    const theme = useTheme();
    const classes = useStyles();
    const options = useMemo(() => {
        let options = column?.options || [];
        return options.map(opt => ({
            label: opt.label,
            value: opt.label
        }));
    }, [column]);

    return (
        <LDBasePortal
            isUsingContainer={true}
            isDisabled={disabled}
            ddPlaceholder={t(`global_label_select_an_option`)}
            menuPlaceholder={t(`global_label_find_an_option`)}
            options={options}
            onChange={handleOptionChange}
            defaultValue={defaultValue}
            selectedColor={theme.colors.secondaryText}
            dropdownClassName={classes.fullWidth}
            isMulti={isMulti}
            isCanClear={true}
        />
    );
}

export default React.memo(OptionSelection);
