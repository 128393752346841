import { enqueueSnackbar } from 'notifier/actions';
import * as types from '../types';
// import * as rowActions from './row';
import { setViewRecords } from 'services/view';
import { getEditableColumns, getDisableColumnIdsByType } from 'utils/gridUI/column';
import * as statusActions from './status';
import * as optimisticActions from './optimistic';
import uuidv1 from 'uuid/v1';
import { receiveData } from 'api/actions';
import { formatSearchTranslationResponse } from 'utils/gridUI/tm';
import { DATA_QUERY_OPTIONS, MAX_RECORD_LIMIT, MAX_SELECTION_RECORDS, RANGE_TYPES, TM_STATUS } from 'const/gridUI';
import { makeTmStatus } from 'utils/gridUI/tm';
import {
    getCurrentCompanyTMs,
    searchTranslationApi,
    updateTranslationApi,
    bukTMApi,
    pushTMApi,
    pullTMApi
} from 'services/tm';
import * as dataActions from './data';
import { chunk } from 'lodash';

export function fetchTMId() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();

        const { workspaceId } = gridUI;

        try {
            const tms = (await getCurrentCompanyTMs()) || [];
            const defaultTM = tms.filter(tm => tm.isDefault);
            const otherTM = tms.filter(tm => !tm.isDefault);

            let selectedTMId = defaultTM?.[0]?.id;

            let selectedTm = defaultTM?.[0];
            for (let i = 0; i < otherTM.length; i++) {
                const tm = otherTM?.[i];
                const workspaceIds = tm?.workspaceIds || [];
                const workspaceIdNumber = Number(workspaceId);
                if (workspaceIds.includes(workspaceIdNumber)) {
                    selectedTMId = tm?.id;
                    selectedTm = tm;
                    break;
                }
            }

            dispatch(receiveData(types.FETCH_TM_ID, { tmId: selectedTMId, defaultTm: selectedTm }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function searchTranslation({ rowId, columnId, targetLang, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tmId } = gridUI;
        if (!tmId) {
            console.log('tmId cannot be emptY');
        }
        try {
            const data = await searchTranslationApi({ tmId, body });
            const translationFormatted = formatSearchTranslationResponse({
                translations: data?.translations,
                targetLang
            });
            dispatch(
                _searchTranslationSuccess({
                    rowId,
                    columnId,
                    translations: translationFormatted
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function addTranslation({ rowId, columnId, value, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tmId } = gridUI;
        if (!tmId) {
            console.log('tmId cannot be emptY');
            return;
        }
        dispatch(_addTranslationAction({ rowId, columnId, value }));
        try {
            const response = await updateTranslationApi({ tmId, body });
            successCallback && successCallback({ translations: response?.translations });
        } catch (error) {
            const { message } = error;
            dispatch(_addTranslationActionFailed({ rowId, columnId, value }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function updateTranslation({ rowId, columnId, values, oldValues, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tmId } = gridUI;
        if (!tmId) {
            console.log('tmId cannot be emptY');
            return;
        }
        dispatch(_updateTranslationAction({ rowId, columnId, values }));
        try {
            await updateTranslationApi({ tmId, body });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_updateTranslationAction({ rowId, columnId, values: oldValues }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateTranslationAction({ rowId, columnId, values }) {
    return {
        type: types.UPDATE_TM_SUGGESTION,
        payload: {
            rowId,
            columnId,
            values
        }
    };
}

function _addTranslationAction({ rowId, columnId, value }) {
    return {
        type: types.ADD_TM_SUGGESTION,
        payload: {
            rowId,
            columnId,
            value
        }
    };
}

function _addTranslationActionFailed({ rowId, columnId, value }) {
    return {
        type: types.ADD_TM_SUGGESTION_FAILED,
        payload: {
            rowId,
            columnId,
            value
        }
    };
}

export function _searchTranslationSuccess({ rowId, columnId, translations }) {
    return {
        type: types.FETCH_TM_SUGGESTION_SUCCESS,
        payload: {
            rowId,
            columnId,
            translations
        }
    };
}

export function approveTMStatus(tmStatus = TM_STATUS.APPROVED) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            viewColumns,
            metaData
        } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));

        const {
            columnIds: rangeColumnIds,
            recordIds,
            data,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.TM],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length || !rangeColumnIds?.length) {
            return dispatch(statusActions.removeDoingAction({ actionId }));
        }

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const affectedColumnIds = getEditableColumns({
            columnIds: rangeColumnIds,
            viewColumns,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType
        });

        const { redoData, cellsChanged, serverData, affectedColumnIds: columnIds } = makeTmStatus({
            recordIds,
            columnIds: affectedColumnIds,
            data,
            tmStatus: TM_STATUS.APPROVED
        });

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_METADATA_TM_STATUS,
                body: {
                    cellsChanged
                }
            })
        );

        dispatch(dataActions.updateData({ newData: redoData }));
        try {
            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...columnIds],
                            records
                        }
                    });
                })
            );

            //call api to save
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function approveTMStatusColumnIds({ columnIds = [] }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { data, rows } = gridUI;
        const { redoData } = makeTmStatus({ recordIds: rows, data, columnIds });
        dispatch(dataActions.updateData({ newData: redoData }));
    };
}

export function updateBulkTm({ transUnits, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { defaultTm, tmId } = gridUI;

        if (!tmId || defaultTm?.isDisabled) {
            console.log('tmId cannot be emptY');
            return;
        }

        try {
            await bukTMApi({
                tmId,
                body: {
                    transUnits,
                    alternative: true
                }
            });

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'updateBulkTm'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function pushTM({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId, gridId } = gridUI;
            await pushTMApi({
                ...body,
                dbId,
                viewId: defaultAccessViewId,
                gridId
            });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function pullTM({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId, gridId } = gridUI;
            await pullTMApi({
                ...body,
                dbId,
                viewId: defaultAccessViewId,
                gridId
            });
            successCallback && successCallback();
            dispatch(setIsApplyingTM(true));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function setIsApplyingTM(payload) {
    return {
        type: types.SET_IS_APPLYING_TM,
        payload
    };
}

export function handleApplyingTmSuccess() {
    return (dispatch, getState) => {
        const { gridUI } = getState();
        const { isApplyingTM, defaultTm } = gridUI;
        if (isApplyingTM) {
            dispatch(setIsApplyingTM(false));
            dispatch(
                enqueueSnackbar({
                    message: `Success! ${defaultTm?.name || ''} has been applied to Grid`,
                    type: 'info'
                })
            );
        }
    };
}
