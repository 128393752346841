import * as types from './types';
import {
    getConnectionsApi,
    getSourcesApi,
    createSourceApi,
    updateSourceApi,
    deleteSourceApi,
    createConnectionApi,
    updateConnectionApi,
    getSourceApi,
    getSourceInfoApi,
    discoverSourceApi,
    deleteConnectionApi,
    getConnectionApi,
    syncConnectionApi,
    getSyncHistoriesApi,
    getSyncHistoriesDestinationIdApi,
    getSyncStatusApi,
    freshDiscoverSourceApi
} from 'services/workflow';
import { enqueueSnackbar } from 'notifier/actions';
import { SOURCE_TYPE_OPTIONS, SOURCE_STATUS } from 'const';
import { sendManualTrack } from 'tracker';
import i18n from 'i18n';

export function getSourceInfo({ connector = SOURCE_TYPE_OPTIONS.GOOGLE_SHEET, success, error }) {
    return async function(dispatch) {
        try {
            const res = await getSourceInfoApi(connector);
            dispatch(_getSourceInfoSuccess(res?.accountEmail));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getSourceInfoSuccess(url) {
    return {
        type: types.SET_SOURCE_INFO_URL,
        payload: {
            url
        }
    };
}

export function getConnectionsByDestinationGridId({ destinationGridId, success, error }) {
    return async function(dispatch) {
        try {
            const connections = await getConnectionsApi({ destinationGridId });
            dispatch(_getConnectionsByDestinationGridIdSuccess({ destinationGridId, connections }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getConnectionsByDestinationGridIdSuccess({ destinationGridId, connections }) {
    return {
        type: types.FETCH_CONNECTIONS_BY_DESTINATION_GRID_ID,
        payload: {
            destinationGridId,
            connections
        }
    };
}

export function getConnectionsBySourceId({ sId, success, error }) {
    return async function(dispatch) {
        try {
            const connections = await getConnectionsApi({ sourceId: sId });
            dispatch(_getConnectionsBySourceIdSuccess({ sId, connections }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getConnectionsBySourceIdSuccess({ sId, connections }) {
    return {
        type: types.FETCH_CONNECTIONS_BY_SOURCE_ID,
        payload: {
            sId,
            connections
        }
    };
}

export function getConnections({ success, error }) {
    return async function(dispatch) {
        try {
            dispatch(_setConnectionsLoading(true));
            const connections = await getConnectionsApi();
            dispatch(_getConnectionsSuccess(connections));
            success && success();
        } catch ({ message }) {
            dispatch(_setConnectionsLoading(false));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getConnectionsSuccess(connections) {
    return {
        type: types.FETCH_CONNECTIONS_SUCCESS,
        payload: {
            connections
        }
    };
}

function _setConnectionsLoading(payload) {
    return {
        type: types.SET_CONNECTIONS_LOADING,
        payload
    };
}

export function createConnection({ connection, success, error }) {
    return async function(dispatch) {
        try {
            const newConnection = await createConnectionApi(connection);
            dispatch(createConnectionSuccess(newConnection));
            success && success(newConnection);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function createConnectionSuccess(connection) {
    return {
        type: types.CREATE_CONNECTION,
        payload: {
            connection
        }
    };
}

export function updateConnection({ cId, connection, success, error }) {
    return async function(dispatch) {
        try {
            const newConnection = await updateConnectionApi({ cId, connection });

            dispatch(updateConnectionSuccess({ cId, connection: newConnection }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function updateConnectionSuccess({ cId, connection }) {
    return {
        type: types.UPDATE_CONNECTION,
        payload: {
            cId,
            connection
        }
    };
}

export function deleteConnection({ sId, cId, success, error }) {
    return async function(dispatch) {
        try {
            await deleteConnectionApi(cId);
            dispatch(deleteConnectionSuccess(cId));
            if (sId) {
                dispatch(deleteConnectionBySourceId({ cId, sId }));
            }
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function deleteConnectionSuccess(cId) {
    return {
        type: types.DELETE_CONNECTION,
        payload: {
            cId
        }
    };
}

export function deleteConnectionBySourceId({ cId, sId }) {
    return {
        type: types.DELETE_CONNECTION_BY_SOURCE_ID,
        payload: {
            cId,
            sId
        }
    };
}

export function getConnection({ cId, success, error }) {
    return async function(dispatch) {
        try {
            const connection = await getConnectionApi(cId);
            dispatch(_getConnectionSuccess(connection));
            success && success(connection);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getConnectionSuccess(connection) {
    return {
        type: types.FETCH_CONNECTION_SUCCESS,
        payload: {
            connection
        }
    };
}

export function getSources({ success, error }) {
    return async function(dispatch) {
        try {
            dispatch(_setSourcesLoading(true));
            const sources = await getSourcesApi();
            dispatch(_getSourcesSuccess(sources));
            success && success(sources);
        } catch ({ message }) {
            dispatch(_setSourcesLoading(false));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _setSourcesLoading(payload) {
    return {
        type: types.SET_SOURCES_LOADING,
        payload
    };
}

function _getSourcesSuccess(sources) {
    return {
        type: types.FETCH_SOURCES_SUCCESS,
        payload: {
            sources
        }
    };
}

export function getSource({ sId, success, error }) {
    return async function(dispatch) {
        try {
            const source = await getSourceApi(sId);
            dispatch(_getSourceSuccess(source));
            success && success(source);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getSourceSuccess(source) {
    return {
        type: types.FETCH_SOURCE_SUCCESS,
        payload: {
            source
        }
    };
}

export function createSource({ source, success, error }) {
    return async function(dispatch) {
        try {
            const newSource = await createSourceApi(source);
            dispatch(createSourceSuccess(newSource));
            success && success(newSource);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function createSourceSuccess(source) {
    return {
        type: types.CREATE_SOURCE,
        payload: {
            source
        }
    };
}

export function updateSource({ sId, source, success, error }) {
    return async function(dispatch) {
        try {
            const newSource = await updateSourceApi({ sId, source });
            dispatch(updateSourceSuccess({ sId, source: newSource }));
            success && success(newSource);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function updateSourceSuccess({ sId, source }) {
    return {
        type: types.UPDATE_SOURCE,
        payload: {
            sId,
            source
        }
    };
}

export function deleteSource({ sId, success, error }) {
    return async function(dispatch) {
        try {
            await deleteSourceApi(sId);
            dispatch(deleteSourceSuccess(sId));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function deleteSourceSuccess(sId) {
    return {
        type: types.DELETE_SOURCE,
        payload: {
            sId
        }
    };
}

export function getFreshSheetsBySourceId({ sId, success, error }) {
    return async function(dispatch) {
        try {
            const res = await freshDiscoverSourceApi(sId);
            dispatch(_getSheetsBySourceIdSuccess({ sId, streams: res?.streams }));
            success && success(res.streams);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function getSheetsBySourceId({ sId, success, error }) {
    return async function(dispatch) {
        try {
            const res = await discoverSourceApi(sId);
            dispatch(_getSheetsBySourceIdSuccess({ sId, streams: res?.streams }));
            success && success(res.streams);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

function _getSheetsBySourceIdSuccess({ sId, streams }) {
    return {
        type: types.FETCH_SHEET_BY_SOURCE_ID,
        payload: {
            sId,
            streams
        }
    };
}

export function syncConnection({ cId, sId, success, error, ignoreMessage = false }) {
    return async function(dispatch) {
        try {
            const task = await syncConnectionApi(cId);
            dispatch(setConnectionSync({ cId, tId: task?.id, sId }));
            sendManualTrack({ type: 'Sync Connections' });
            success && success();
            if (!ignoreMessage) {
                dispatch(
                    enqueueSnackbar({
                        message: i18n.t('sync_connection_is_in_progress'),
                        type: 'info'
                    })
                );
            }
        } catch ({ message }) {
            dispatch(clearConnectionSync({ cId, sId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function setConnectionSync(payload) {
    return {
        type: types.SET_CONNECTION_SYNC,
        payload
    };
}

export function clearConnectionSync({ cId, sId }) {
    return {
        type: types.CLEAR_CONNECTION_SYNC,
        payload: {
            cId,
            sId
        }
    };
}

export function getSyncHistoriesByCId({ cId, success, error }) {
    return async function(dispatch) {
        try {
            const histories = await getSyncHistoriesApi(cId);
            dispatch(_getSyncHistoriesSuccess({ cId, histories }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function _getSyncHistoriesSuccess({ cId, histories }) {
    return {
        type: types.FETCH_HISTORIES_BY_CONNECTION_ID,
        payload: {
            cId,
            histories
        }
    };
}

export function getSyncHistoriesByDestinationId({ dId, success, error }) {
    return async function(dispatch) {
        try {
            const histories = await getSyncHistoriesDestinationIdApi(dId);
            dispatch(_getSyncHistoriesSuccessByDestiantionId({ dId, histories }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}

export function _getSyncHistoriesSuccessByDestiantionId({ dId, histories }) {
    return {
        type: types.FETCH_HISTORIES_BY_DESTINATION_ID,
        payload: {
            dId,
            histories
        }
    };
}

export function getSyncStatusTask({ cId, sId, tId, success, error }) {
    return async function(dispatch) {
        try {
            const task = await getSyncStatusApi(tId);
            if (task?.status === SOURCE_STATUS.SUCCEEDED) {
                dispatch(clearConnectionSync({ cId, sId }));
                dispatch(
                    enqueueSnackbar({
                        message: 'Sync connection successfully',
                        type: 'info'
                    })
                );
            }
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error(message);
        }
    };
}
