import * as types from '../types';
import { formatQuickFilters } from 'utils/gridUI/filter';
import {
    getFromRecordIdToRecordId,
    getColumnIdsFromRange,
    isSelecting,
    getTotalSelectedRowsByRange,
    getRecordIdsInRowRangeIndexes
} from 'utils/gridUI/range';
import { fetchRangeRecordApi } from 'services/view';
import { DATA_QUERY_OPTIONS, MAX_SELECTION_RECORDS, RANGE_TYPES } from 'const/gridUI';
import { isEmpty } from 'lodash';
import { getViewColumnsWithReorderAndLanguagePairs } from 'utils/gridUI/column';

export function updateData({ newData, isCareData = false }) {
    return {
        type: types.UPDATE_DATA,
        payload: {
            newData,
            isCareData
        }
    };
}

export function deleteRecordsData(recordIds) {
    return {
        type: types.DELETE_RECORDS_DATA,
        payload: {
            recordIds
        }
    };
}

export function updateGridData({
    data,
    totalRecords,
    rows,
    recordMetaData,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    totalRecordsWithoutFilters
}) {
    return {
        type: types.UPDATE_GRID_DATA,
        payload: {
            data,
            totalRecords,
            rows,
            recordMetaData,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        }
    };
}

export async function getRangeDataPaste({
    auth,
    gridUI,
    contentLength,
    type,
    dataOptions = [DATA_QUERY_OPTIONS.DATA]
}) {
    const {
        rowIndexMap,
        quickFilters,
        quickSorts,
        dbId,
        defaultAccessViewId,
        rows,
        data,
        recordMetaData,
        rowStartIndex,
        rowStopIndex,
        columnStartIndex,
        columnStopIndex,
        columnsSelected,
        rowsRangeIndexes,
        ROW_START_INDEX,
        totalRecords
    } = gridUI;
    const query = formatQuickFilters(quickFilters);
    const sort = quickSorts;
    const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });

    try {
        if (columnsSelected?.length) {
            return {
                recordIds: [],
                columnIds: columnsSelected,
                data,
                recordMetaData
            };
        }

        if (rowsRangeIndexes?.length) {
            const rangeIndexes = rowsRangeIndexes?.flat();
            const startRowIndex = Math.min(...rangeIndexes);
            const stopRowIndex = Math.min(startRowIndex + contentLength, totalRecords - 1);
            const rowStartId = rowIndexMap?.[startRowIndex];
            const rowStopId = rows?.[stopRowIndex - ROW_START_INDEX];

            const isUseStoreData = rowStartId && rowStopId && rows?.includes(rowStartId) && rows?.includes(rowStopId);

            const columnIds = viewableColumns?.map(column => column?.id);
            if (isUseStoreData) {
                let recordIds = getRecordIdsInRowRangeIndexes({ ranges: rowsRangeIndexes, ROW_START_INDEX, rows });
                return {
                    recordIds,
                    columnIds,
                    data,
                    recordMetaData
                };
            }

            // call server

            let body = {
                type,
                columnIds
            };

            if (!isEmpty(query)) {
                body.query = JSON.stringify(query);
            }

            if (!isEmpty(sort)) {
                body.sort = JSON.stringify(sort);
            }

            if (type === RANGE_TYPES.INDEX) {
                const fromRecordId = rowIndexMap?.[startRowIndex];
                const toIndex = Math.min(totalRecords, rowStartIndex + contentLength);

                body = {
                    ...body,
                    recordIdRanges: [
                        {
                            fromRecordId,
                            toIndex
                        }
                    ]
                };
            } else {
                body = {
                    ...body,
                    recordIdRanges: rowsRangeIndexes?.map(range => {
                        const start = range?.[0];
                        const stop = range?.[1] || start;
                        return {
                            fromRecordId: rowIndexMap?.[start],
                            toRecordId: rowIndexMap?.[stop]
                        };
                    })
                };
            }

            return await fetchRangeRecordApi({
                ...body,
                query,
                sort,
                dbId,
                viewId: defaultAccessViewId,
                dataOptions
            });
        }

        if (isSelecting({ rowStopIndex, columnStartIndex, rowStartIndex, columnStopIndex })) {
            const { fromRecordId } = getFromRecordIdToRecordId({ rowIndexMap });

            const stopIndex = Math.max(Math.min(rowStartIndex + contentLength - ROW_START_INDEX, totalRecords - 1), 0);

            console.log('stopIndex', stopIndex);
            const toRecordId = rows?.[stopIndex];
            console.log('toRecordId', toRecordId);

            const isUseStoreData = rows?.includes(fromRecordId) && rows?.includes(toRecordId);
            const viewColumnIds = getColumnIdsFromRange({
                viewColumns: viewableColumns,
                columnStartIndex,
                columnStopIndex
            });

            console.log('isUseStoreData', isUseStoreData);
            if (isUseStoreData) {
                return {
                    recordIds: rows?.slice(
                        rows?.findIndex(rowId => rowId === fromRecordId),
                        rows?.findIndex(rowId => rowId === toRecordId) + 1
                    ),
                    columnIds: viewColumnIds,
                    data,
                    recordMetaData
                };
            }

            let body = {
                type,
                columnIds: viewColumnIds
            };

            if (!isEmpty(query)) {
                body.query = JSON.stringify(query);
            }

            if (!isEmpty(sort)) {
                body.sort = JSON.stringify(sort);
            }

            if (type === RANGE_TYPES.INDEX) {
                const fromRecordId = rowIndexMap?.[rowStartIndex];
                const toIndex = Math.min(totalRecords, rowStartIndex + contentLength);

                body = {
                    ...body,
                    recordIdRanges: [
                        {
                            fromRecordId,
                            toIndex
                        }
                    ]
                };
            } else {
                body = {
                    ...body,
                    recordIdRanges: [
                        {
                            fromRecordId,
                            toRecordId
                        }
                    ]
                };
            }

            return await fetchRangeRecordApi({
                ...body,
                query,
                sort,
                dbId,
                viewId: defaultAccessViewId,
                dataOptions
            });
        }

        return {
            recordIds: [],
            columnIds: [],
            data,
            recordMetaData
        };
    } catch (error) {
        return {
            columnIds: [],
            recordIds: [],
            data,
            recordMetaData
        };
    }
}

export async function getRangeData({
    gridUI,
    auth,
    type = RANGE_TYPES.INDEX,
    dataOptions = [DATA_QUERY_OPTIONS.DATA, DATA_QUERY_OPTIONS.COLOR],
    forcedColumnIds = [],
    allowSelectedColumns,
    isUsingSearchRange
}) {
    let {
        rowIndexMap,
        quickFilters,
        quickSorts,
        dbId,
        defaultAccessViewId,
        rows,
        data,
        recordMetaData,
        rowStartIndex,
        rowStopIndex,
        columnStartIndex,
        columnStopIndex,
        columnsSelected,
        rowsRangeIndexes,
        ROW_START_INDEX,
        totalRecords,
        searchRange
    } = gridUI;

    if (isUsingSearchRange && searchRange) {
        rowStartIndex = searchRange.rowStartIndex;
        rowStopIndex = searchRange.rowStopIndex;
        columnStartIndex = searchRange.columnStartIndex;
        columnStopIndex = searchRange.columnStopIndex;
        columnsSelected = searchRange.columnsSelected;
        rowIndexMap = searchRange.rowIndexMap;
        rowsRangeIndexes = searchRange.rowsRangeIndexes;
    }

    const query = formatQuickFilters(quickFilters);
    const sort = quickSorts;
    const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });
    try {
        if (columnsSelected?.length && !allowSelectedColumns) {
            return {
                recordIds: [],
                columnIds: columnsSelected,
                data,
                recordMetaData,
                isOverRecordLimit: false,
                totalSelectedRecords: 0
            };
        } else if (columnsSelected?.length && allowSelectedColumns) {
            rowStartIndex = 0;
            rowStopIndex = totalRecords - 1;
            const columnsIndex = columnsSelected.map(colId => viewableColumns.findIndex(column => column.id === colId));
            columnStartIndex = Math.min(columnsIndex);
            columnStopIndex = Math.max(columnsIndex);
        }

        if (rowsRangeIndexes?.length) {
            const rangeIndexes = rowsRangeIndexes?.flat();
            const startRowIndex = Math.min(...rangeIndexes);
            const stopRowIndex = Math.max(...rangeIndexes);

            const rowStartId = rowIndexMap?.[startRowIndex];
            const rowStopId = rowIndexMap?.[stopRowIndex];

            const isUseStoreData = rowStartId && rowStopId && rows?.includes(rowStartId) && rows?.includes(rowStopId);

            const columnIds = forcedColumnIds?.length ? forcedColumnIds : viewableColumns?.map(column => column?.id);
            if (isUseStoreData) {
                let recordIds = getRecordIdsInRowRangeIndexes({ ranges: rowsRangeIndexes, ROW_START_INDEX, rows });
                return {
                    recordIds,
                    columnIds,
                    data,
                    recordMetaData,
                    isOverRecordLimit: false,
                    totalSelectedRecords: recordIds?.length
                };
            }

            //check overLimit?
            const totalSelectedRecords = getTotalSelectedRowsByRange(rowsRangeIndexes);
            console.log('totalSelectedRecordstotalSelectedRecords', totalSelectedRecords, totalRecords);

            if (totalSelectedRecords > MAX_SELECTION_RECORDS) {
                return {
                    recordIds: [],
                    columnIds,
                    data,
                    recordMetaData,
                    isOverRecordLimit: true,
                    totalSelectedRecords
                };
            }

            // call server
            let body = {
                type,
                columnIds
            };

            if (!isEmpty(query)) {
                body.query = JSON.stringify(query);
            }

            if (!isEmpty(sort)) {
                body.sort = JSON.stringify(sort);
            }

            if (type === RANGE_TYPES.INDEX) {
                body = {
                    ...body,
                    recordIdRanges: rowsRangeIndexes.map(rangeIndexes => ({
                        fromIndex: Math.min(rangeIndexes[0] + 1, rangeIndexes[1] + 1),
                        toIndex: Math.max(rangeIndexes[0] + 1, rangeIndexes[1] + 1)
                    }))
                };
            } else {
                body = {
                    ...body,
                    recordIdRanges:
                        totalSelectedRecords === totalRecords
                            ? [
                                  {
                                      fromIndex: 1,
                                      toIndex: totalRecords
                                  }
                              ]
                            : rowsRangeIndexes?.map(range => {
                                  const start = range?.[0];
                                  const stop = range?.[1] || start;
                                  return {
                                      fromRecordId: rowIndexMap?.[start],
                                      toRecordId: rowIndexMap?.[stop]
                                  };
                              })
                };
            }

            const res = await fetchRangeRecordApi({
                ...body,
                query,
                sort,
                dbId,
                viewId: defaultAccessViewId,
                dataOptions
            });

            return {
                ...res,
                totalSelectedRecords: res?.recordIds?.length
            };
        }

        if (isSelecting({ rowStopIndex, columnStartIndex, rowStartIndex, columnStopIndex })) {
            const { fromRecordId, toRecordId } = getFromRecordIdToRecordId({ rowIndexMap });
            const isUseStoreData = rows?.includes(fromRecordId) && rows?.includes(toRecordId);
            const viewColumnIds = forcedColumnIds?.length
                ? forcedColumnIds
                : getColumnIdsFromRange({
                      viewColumns: viewableColumns,
                      columnStartIndex,
                      columnStopIndex
                  });

            if (isUseStoreData) {
                const recordIds = rows?.slice(
                    rows?.findIndex(rowId => rowId === fromRecordId),
                    rows?.findIndex(rowId => rowId === toRecordId) + 1
                );
                return {
                    recordIds,
                    columnIds: viewColumnIds,
                    data,
                    recordMetaData,
                    isOverRecordLimit: false,
                    totalSelectedRecords: recordIds?.length
                };
            }

            const totalSelectedRecords = Math.abs(rowStopIndex - rowStartIndex) + 1;

            if (totalSelectedRecords > MAX_SELECTION_RECORDS) {
                return {
                    recordIds: [],
                    columnIds: viewColumnIds,
                    data,
                    recordMetaData,
                    isOverRecordLimit: true,
                    totalSelectedRecords: totalSelectedRecords
                };
            }

            let body = {
                type,
                columnIds: viewColumnIds
            };

            if (!isEmpty(query)) {
                body.query = JSON.stringify(query);
            }

            if (!isEmpty(sort)) {
                body.sort = JSON.stringify(sort);
            }

            if (type === RANGE_TYPES.INDEX) {
                body = {
                    ...body,
                    recordIdRanges: [
                        {
                            fromIndex: Math.min(rowStartIndex + 1, rowStopIndex + 1),
                            toIndex: Math.min(totalRecords, Math.max(rowStartIndex + 1, rowStopIndex + 1))
                        }
                    ]
                };
            } else {
                body = {
                    ...body,
                    recordIdRanges: [
                        {
                            fromRecordId,
                            toRecordId
                        }
                    ]
                };
            }

            const res = await fetchRangeRecordApi({
                ...body,
                query,
                sort,
                dbId,
                viewId: defaultAccessViewId,
                dataOptions
            });

            return {
                ...res,
                totalSelectedRecords: res?.recordIds?.length
            };
        }

        return {
            recordIds: [],
            columnIds: [],
            data,
            recordMetaData,
            isOverRecordLimit: false,
            totalSelectedRecords: 0
        };
    } catch (error) {
        return {
            columnIds: [],
            recordIds: [],
            data,
            recordMetaData,
            isOverRecordLimit: false,
            totalSelectedRecords: 0
        };
    }
}
