import React from 'react';
import ActionStatus from 'gridUI/ActionStatus';
import { useDoingActionIds } from 'hooks/advanced';
import { Grid } from '@material-ui/core';

const ManageActionStatus = () => {
    const doingActionIds = useDoingActionIds();

    if (!doingActionIds?.length) return null;

    return (
        <Grid item>
            <ActionStatus doingActionIds={doingActionIds} />
        </Grid>
    );
};

export default React.memo(ManageActionStatus);
