import React from 'react';

const ImportLocalizationFileSVG = props => {
    return (
        <svg width="67" height="72" viewBox="0 0 67 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M46.2722 71.7199L3.47222 60.065C0.972222 59.3677 -0.527778 56.7777 0.172222 54.1877L14.1722 3.48387C14.8722 0.9935 17.4722 -0.50072 20.0722 0.196583L62.8722 11.9511C65.3722 12.6484 66.8722 15.2384 66.1722 17.8284L52.1722 68.5322C51.3722 70.923 48.7722 72.4172 46.2722 71.7199Z"
                fill="#EBEBF6"
            />
            <path
                d="M55.3 66.9661H10.5C8 66.9661 6 64.9738 6 62.4834V9.48846C6 6.99809 8 5.0058 10.5 5.0058H44.9C46.6 5.0058 48.2 5.7031 49.3 6.79886L57.9 15.2661C59.1 16.4615 59.8 18.0553 59.8 19.7488V62.5831C59.9 64.9738 57.8 66.9661 55.3 66.9661Z"
                fill="white"
            />
            <path
                d="M43.3975 37.6279C42.6827 36.7989 41.8487 36.2068 40.7765 35.9699C41.1339 35.4962 41.2531 34.9041 41.2531 34.312C41.2531 33.4831 40.8957 32.7725 40.3 32.062C39.7043 31.4699 38.9895 31.1146 38.0364 31.1146C37.3216 31.1146 36.6068 31.3514 36.0111 31.8251C35.5346 30.6409 34.8197 29.6935 33.7475 28.983C32.6753 28.2724 31.4839 27.9172 30.2926 27.9172C28.6247 27.9172 27.0759 28.5093 25.8846 29.6935C24.6932 30.8778 24.0975 32.4173 24.0975 34.0752C24.0975 34.1936 24.0975 34.312 24.0975 34.5489C23.1444 35.0226 22.4296 35.6147 21.834 36.5621C21.2383 37.5094 21 38.4568 21 39.5226C21 41.0621 21.4765 42.2464 22.5488 43.3122C23.621 44.378 24.9315 44.8517 26.3611 44.8517H39.7043C41.0148 44.8517 42.087 44.378 43.0401 43.4306C43.9932 42.9569 44.4697 41.8911 44.4697 40.5884C44.4697 39.5226 44.1123 38.5752 43.3975 37.6279ZM36.4876 37.391C36.3685 37.5094 36.3685 37.5094 36.2494 37.5094H33.5092V41.7727C33.5092 41.8911 33.5092 42.0095 33.3901 42.0095C33.271 42.1279 33.271 42.1279 33.1518 42.1279H30.7691C30.65 42.1279 30.5309 42.1279 30.5309 42.0095C30.4117 41.8911 30.4117 41.8911 30.4117 41.7727V37.5094H27.6716C27.5525 37.5094 27.4333 37.5094 27.4333 37.391C27.3142 37.2726 27.3142 37.2726 27.3142 37.1542C27.3142 37.0357 27.3142 36.9173 27.4333 36.9173L31.7222 32.6541C31.8413 32.5357 31.8413 32.5357 31.9605 32.5357C32.0796 32.5357 32.1988 32.5357 32.1988 32.6541L36.4876 36.9173C36.6068 37.0357 36.6068 37.0357 36.6068 37.1542C36.6068 37.1542 36.6068 37.2726 36.4876 37.391Z"
                fill="#6E5BBE"
                fillOpacity="0.8"
            />
        </svg>
    );
};

export default React.memo(ImportLocalizationFileSVG);
