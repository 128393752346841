import React from 'react';

function ShieldSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.426 6.01952L18.4254 6.00513C18.417 5.82743 18.4114 5.63942 18.408 5.43025C18.3923 4.4102 17.5459 3.56645 16.4809 3.50943C14.2606 3.39072 12.5431 2.69715 11.0755 1.32678L11.063 1.31535C10.5843 0.894883 9.8622 0.894883 9.38338 1.31535L9.37085 1.32678C7.90328 2.69715 6.18569 3.39072 3.9654 3.50957C2.90063 3.56645 2.05406 4.4102 2.0383 5.43039C2.03505 5.63815 2.02931 5.82616 2.02091 6.00513L2.02003 6.03858C1.97686 8.20753 1.92323 10.9069 2.86586 13.357C3.38417 14.7044 4.16916 15.8756 5.19886 16.8383C6.37162 17.9347 7.9077 18.8052 9.76438 19.4253C9.82478 19.4455 9.88755 19.4619 9.95149 19.4742C10.0417 19.4914 10.1324 19.5 10.2232 19.5C10.3139 19.5 10.4048 19.4914 10.4948 19.4742C10.5588 19.4619 10.622 19.4454 10.6827 19.4251C12.5372 18.8037 14.0716 17.9329 15.2434 16.8366C16.2726 15.8736 17.0576 14.7021 17.5764 13.3545C18.5225 10.897 18.469 8.19257 18.426 6.01952ZM16.5132 12.9789C15.519 15.5612 13.4895 17.3347 10.3088 18.4004C10.2974 18.404 10.2853 18.4073 10.2728 18.4097C10.24 18.4159 10.2065 18.4159 10.1732 18.4095C10.1608 18.4071 10.1488 18.404 10.1376 18.4004C6.95343 17.3367 4.92261 15.5642 3.92915 12.9817C3.06034 10.7233 3.10954 8.24536 3.15301 6.05933L3.1533 6.04719C3.16214 5.85932 3.16804 5.66257 3.17128 5.44634C3.17835 4.99341 3.55493 4.61867 4.0286 4.59341C5.28505 4.52622 6.38886 4.29362 7.40309 3.88233C8.416 3.4716 9.3156 2.89263 10.153 2.11239C10.1956 2.07795 10.2509 2.07781 10.2933 2.11239C11.1309 2.89263 12.0305 3.4716 13.0432 3.88233C14.0575 4.29362 15.1613 4.52622 16.4179 4.59341C16.8916 4.61867 17.2681 4.99341 17.2751 5.44648C17.2784 5.66384 17.2843 5.8606 17.2932 6.04719C17.3365 8.23576 17.3845 10.7157 16.5132 12.9789Z"
                fill={color}
                stroke={color}
                strokeWidth="0.3"
            />
            <path
                d="M10.0002 5.01611C7.25174 5.01611 5.01587 7.25214 5.01587 10.0004C5.01587 12.7488 7.25174 14.9848 10.0002 14.9848C12.7484 14.9848 14.9844 12.7488 14.9844 10.0004C14.9844 7.25214 12.7484 5.01611 10.0002 5.01611ZM10.0002 13.8111C7.89886 13.8111 6.18942 12.1017 6.18942 10.0004C6.18942 7.89911 7.89886 6.18967 10.0002 6.18967C12.1013 6.18967 13.8107 7.89911 13.8107 10.0004C13.8107 12.1017 12.1013 13.8111 10.0002 13.8111Z"
                fill={color}
            />
            <path
                d="M11.4339 8.45277L9.11883 10.7678L8.49047 10.1395C8.26128 9.91029 7.88973 9.91029 7.66055 10.1395C7.43151 10.3687 7.43151 10.7402 7.66055 10.9692L8.70394 12.0126C8.81853 12.1272 8.96868 12.1845 9.11883 12.1845C9.26897 12.1845 9.41912 12.1272 9.53371 12.0126L12.2638 9.28254C12.4929 9.05335 12.4929 8.6818 12.2638 8.45277C12.0346 8.22343 11.6631 8.22343 11.4339 8.45277Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ShieldSVG);
