import React from 'react';

function NoTranslationOverviewSVG({ ...other }) {
    return (
        <svg {...other} width="127" height="127" viewBox="0 0 127 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M63.5 127C98.5701 127 127 98.5701 127 63.5C127 28.4299 98.5701 0 63.5 0C28.4299 0 0 28.4299 0 63.5C0 98.5701 28.4299 127 63.5 127Z"
                fill="#E9EAEF"
            />
            <path
                d="M75.718 74.8858C79.4778 70.6867 84.4762 68.3741 89.7925 68.3741C93.4145 68.3741 96.8881 69.4483 99.9238 71.4603V26.2748C99.9238 23.5134 97.6852 21.2748 94.9238 21.2748H63.8222H32.7207C29.9593 21.2748 27.7207 23.5134 27.7207 26.2748V103.508C27.7207 104.728 28.6145 105.727 29.7069 105.727L80.906 104.76C73.6662 96.0527 68.1341 83.3573 75.718 74.8858Z"
                fill="white"
            />
            <path
                d="M87.6744 38.2461H40.0349C39.3221 38.2461 38.7441 37.578 38.7441 36.754C38.7441 35.93 39.3221 35.262 40.0349 35.262H87.6744C88.3874 35.262 88.9652 35.93 88.9652 36.754C88.9652 37.578 88.3874 38.2461 87.6744 38.2461Z"
                fill="#DBDCE1"
            />
            <path
                d="M87.6739 70.6509H40.0344C39.3216 70.6509 38.7437 69.9828 38.7437 69.1588C38.7437 68.3348 39.3216 67.6667 40.0344 67.6667H87.6739C88.3869 67.6667 88.9647 68.3348 88.9647 69.1588C88.9647 69.9828 88.3869 70.6509 87.6739 70.6509Z"
                fill="#DBDCE1"
            />
            <path
                d="M87.6725 49.05H40.0344C39.3216 49.05 38.7437 48.382 38.7437 47.5579C38.7437 46.7339 39.3216 46.0659 40.0344 46.0659H87.6725C88.3855 46.0659 88.9632 46.7339 88.9632 47.5579C88.9632 48.382 88.3855 49.05 87.6725 49.05Z"
                fill="#DBDCE1"
            />
            <path
                d="M87.6725 81.4505H40.0344C39.3216 81.4505 38.7437 80.7824 38.7437 79.9584C38.7437 79.1344 39.3216 78.4663 40.0344 78.4663H87.6725C88.3855 78.4663 88.9632 79.1344 88.9632 79.9584C88.9632 80.7824 88.3855 81.4505 87.6725 81.4505Z"
                fill="#DBDCE1"
            />
            <path
                d="M87.6736 59.8478H40.0344C39.3216 59.8478 38.7437 59.1798 38.7437 58.3558C38.7437 57.5318 39.3216 56.8637 40.0344 56.8637H87.6736C88.3867 56.8637 88.9644 57.5318 88.9644 58.3558C88.9644 59.1798 88.3867 59.8478 87.6736 59.8478Z"
                fill="#DBDCE1"
            />
            <path
                d="M87.6736 92.2535H40.0344C39.3216 92.2535 38.7437 91.5854 38.7437 90.7614C38.7437 89.9374 39.3216 89.2693 40.0344 89.2693H87.6736C88.3867 89.2693 88.9644 89.9374 88.9644 90.7614C88.9644 91.5854 88.3867 92.2535 87.6736 92.2535Z"
                fill="#DBDCE1"
            />
            <path
                d="M111.357 104.279L106.857 108.779L120.248 122.17C121.513 123.434 123.552 123.502 124.816 122.238C126.081 120.973 126.013 118.934 124.748 117.67L111.357 104.279Z"
                fill="#A2A0AF"
            />
            <path
                d="M113.148 101.564L109.419 97.8347C108.427 99.6925 107.15 101.437 105.585 103.002C104.038 104.548 102.301 105.836 100.426 106.842L104.148 110.564C104.574 110.99 105.27 110.99 105.696 110.564L113.148 103.112C113.574 102.686 113.574 101.99 113.148 101.564Z"
                fill="#AEADB5"
            />
            <path
                d="M106.694 108.013L103.51 104.83C102.532 105.592 101.497 106.266 100.417 106.845L104.139 110.567C104.565 110.993 105.262 110.993 105.687 110.567L108.242 108.013C107.816 108.439 107.119 108.439 106.694 108.013Z"
                fill="#9A98A6"
            />
            <path
                d="M105.891 71.6655C114.629 80.4042 114.629 94.5726 105.891 103.312C97.1523 112.05 82.9841 112.05 74.2448 103.311C65.5061 94.5726 65.5065 80.4042 74.2448 71.6657C82.9841 62.9268 97.1523 62.9266 105.891 71.6655Z"
                fill="#AEADB5"
            />
            <path
                d="M101.362 98.7831C95.2351 104.91 85.3009 104.91 79.1734 98.7827C73.0465 92.6562 73.0463 82.7218 79.1734 76.5947C85.3002 70.4678 95.2349 70.4681 101.362 76.5947C107.489 82.722 107.489 92.656 101.362 98.7831Z"
                fill="#E9EAEF"
            />
            <path
                d="M101.362 98.8234C101.92 98.2654 102.426 97.6751 102.882 97.0597C96.74 101.614 88.0267 101.109 82.4573 95.5391C76.8882 89.9705 76.3825 81.257 80.937 75.115C80.3214 75.5715 79.7314 76.0772 79.1734 76.635C73.0463 82.7621 73.0465 92.6963 79.1734 98.823C85.3009 104.95 95.2351 104.95 101.362 98.8234Z"
                fill="#D7D7DC"
            />
            <path
                d="M92.7629 79.6112C94.2138 79.6112 95.39 78.435 95.39 76.984C95.39 75.5331 94.2138 74.3569 92.7629 74.3569C91.3119 74.3569 90.1357 75.5331 90.1357 76.984C90.1357 78.435 91.3119 79.6112 92.7629 79.6112Z"
                fill="white"
            />
            <path
                d="M98.2788 81.5767C99.3066 81.5767 100.14 80.7436 100.14 79.7159C100.14 78.6881 99.3066 77.855 98.2788 77.855C97.2511 77.855 96.418 78.6881 96.418 79.7159C96.418 80.7436 97.2511 81.5767 98.2788 81.5767Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(NoTranslationOverviewSVG);
