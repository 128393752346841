import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ButtonGroup, Button } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
// import { useTranslation } from 'react-i18next';
import { LQA_TYPES } from 'const/gridUI';
import LDBasePortal from 'components/selects/LDBasePortal';
import { ISSUE_TYPE_OPTIONS } from 'utils/gridUI/lqa';
import { useShareViewGroupMembers } from 'hooks/gridUI';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getFullName } from 'utils/name';
import { getAvatarUrl } from 'utils/images';
import Avatar from 'components/avatar/User';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
        minWidth: 268
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    buttonGroup: {
        '& > button': {
            border: 'none',
            background: theme.colors.paleGrey,
            color: theme.colors.steel,
            fontWeight: 400,
            '&:hover': {
                border: 'none',
                background: theme.colors.lightLavender,
                color: theme.colors.white
            }
        }
    },
    active: {
        background: `${theme.colors.lightLavender} !important`,
        color: `${theme.colors.white} !important`
    }
}));

function FilterMenu({ handleClickAway, query, onQueryChange, lqaModelDefault }) {
    const classes = useStyles();
    const rootRef = React.useRef();
    const { t } = useTranslation();
    const members = useShareViewGroupMembers() || [];

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const isIssueType = React.useMemo(() => {
        return query?.ticketType?.value === LQA_TYPES.LQA_ISSUE;
    }, [query]);

    // const authorStatus = React.useMemo(() => {
    //     return query?.author;
    // }, [query]);

    const sort = React.useMemo(() => {
        return query?.sort;
    }, [query]);

    const categories = React.useMemo(() => {
        return lqaModelDefault?.categories.map(el => ({ ...el, label: el.name, value: el.id })) || [];
    }, [lqaModelDefault]);

    const subCategories = React.useMemo(() => {
        return query.category?.subCategories.map(el => ({ ...el, label: el.name, value: el.id })) || [];
    }, [query.category]);

    const severities = React.useMemo(() => {
        return query.subCategory?.severities.map(el => ({ ...el, label: el.name, value: el.severityId })) || [];
    }, [query.subCategory]);

    const membersOption = React.useMemo(() => {
        let arr = [{ label: 'All', value: '' }];
        const mapMembers = (members || []).map(m => ({
            value: m.user.id,
            label: getFullName(m.user),
            icon: () => (
                <Avatar src={getAvatarUrl(m.user.id)} size={20} color="#DBDCE1">
                    <AvatarIconSVG />
                </Avatar>
            )
        }));
        arr = [...arr, ...mapMembers];
        return arr;
    }, [members]);

    return (
        <Grid ref={rootRef} direction="column" container className={classes.root}>
            <Grid item className={classes.spacing}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <p className="body1">{t('global_author')}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            options={membersOption}
                            defaultValue={query?.author}
                            onChange={option => {
                                if (option.value === query?.author?.value) return;
                                onQueryChange({ author: option });
                            }}
                            dropdownClassName={classes.test}
                            ddPlaceholder={'Select an author'}
                            menuPlaceholder={t('find_an_author')}
                            isUsingContainer={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.spacing}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <p className="body1">{t('assign_to')}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            options={membersOption}
                            defaultValue={query?.assignee}
                            onChange={option => {
                                if (option.value === query?.assignee?.value) return;
                                onQueryChange({ assignee: option });
                            }}
                            dropdownClassName={classes.test}
                            ddPlaceholder={t('select_an_assignee')}
                            menuPlaceholder={t('find_an_assignee')}
                            isUsingContainer={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.spacing}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <p className="body1">{t('ticket_type')}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            options={[{ label: 'All', value: '' }, ...ISSUE_TYPE_OPTIONS]}
                            defaultValue={query?.ticketType}
                            onChange={option => {
                                if (option.value === query?.ticketType?.value) return;
                                onQueryChange({
                                    ticketType: option,
                                    category: null,
                                    subCategory: null,
                                    severity: null
                                });
                            }}
                            dropdownClassName={classes.test}
                            ddPlaceholder={'Select a ticket type'}
                            menuPlaceholder={t('find_a_ticket_type')}
                            isUsingContainer={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {isIssueType && (
                <>
                    <Grid item className={classes.spacing}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <p className="body1">Category</p>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    options={categories}
                                    defaultValue={query?.category}
                                    onChange={option => {
                                        if (option.value === query?.category?.value) return;
                                        onQueryChange({ category: option, subCategory: null, severity: null });
                                    }}
                                    dropdownClassName={classes.test}
                                    ddPlaceholder={'Select a category'}
                                    menuPlaceholder={'Find a category'}
                                    isUsingContainer={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.spacing}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <p className="body1">Sub-category</p>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    options={subCategories}
                                    defaultValue={query?.subCategory}
                                    onChange={option => {
                                        if (option.value === query?.subCategory?.value) return;
                                        onQueryChange({ subCategory: option, severity: null });
                                    }}
                                    dropdownClassName={classes.test}
                                    ddPlaceholder={'Select a sub-category'}
                                    menuPlaceholder={'Find a sub-category'}
                                    isUsingContainer={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.spacing}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <p className="body1">Severity</p>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    options={severities}
                                    defaultValue={query?.severity}
                                    onChange={option => {
                                        if (option.value === query?.severity?.value) return;
                                        onQueryChange({ severity: option });
                                    }}
                                    dropdownClassName={classes.test}
                                    ddPlaceholder={'Select a severity'}
                                    menuPlaceholder={'Find a severity'}
                                    isUsingContainer={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid item className={classes.spacing}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <p className="body1">{t('sort_by')}</p>
                    </Grid>
                    <Grid item>
                        <ButtonGroup
                            className={classes.buttonGroup}
                            color="primary"
                            aria-label="contained button group"
                        >
                            <Button
                                onClick={() => onQueryChange({ sort: 'asc' })}
                                className={sort === 'asc' ? classes.active : ``}
                            >
                                {t('newest')}
                            </Button>
                            <Button
                                onClick={() => onQueryChange({ sort: 'desc' })}
                                className={sort === 'desc' ? classes.active : ``}
                            >
                                {t('oldest')}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(FilterMenu);
