import React, { useCallback, useMemo, useState } from 'react';
import { Drawer, Grid, IconButton, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useAppPromoteFeatures } from 'hooks/app';
import { getWhatNewTitle } from 'WhatNewDialog';
import { getWhatNewCreatedTime } from 'WhatNewDialog';
import { getFriendlyDate } from 'utils/datetime';
import { getWhatNewBrief } from 'WhatNewDialog';
import { addTargetBlankToATagInText, stripHTML } from 'utils/html';
import { getWhatNewContent } from 'WhatNewDialog';
import Button from 'components/button/Base';
import AnchorLeft from 'assets/images/svg/AnchorLeft';
import { getWhatNewThumbnail } from 'WhatNewDialog';
import { useShownPromoteFeatures } from 'hooks/auth';
import * as authActions from 'auth/actions';
import { useDispatch } from 'react-redux';
import { USER_SETTINGS } from 'const';

const useStyles = makeStyles(theme => ({
    root: {},
    wrapper: {
        height: '100%',
        position: 'relative'
    },
    title: {
        padding: '31px 24px',
        boxShadow: `-1px 0px 0px #E9EAEF, 0px 1px 0px #E9EAEF`
    },
    closeIcon: {
        position: 'absolute',
        top: 22,
        right: 17,
        cursor: 'pointer'
    },
    list: {
        position: 'relative',
        flex: 1,
        padding: '0 24px',
        overflow: 'hidden auto'
    },
    whatnewitem: {
        padding: '24px 0',
        borderTop: `1px solid ${theme.colors.border}`,
        cursor: 'pointer'
    },
    whatnewitemtitle: {
        margin: '8px 0'
    },
    thumbnail: {
        marginTop: 16,
        height: 170,
        '& img': {
            width: '100%',
            height: '100%',
            borderRadius: 6,
            objectFit: 'cover'
        }
    },
    wndetailwrapper: {
        position: 'absolute',
        top: 1,
        left: 0,
        height: `calc(100% - 1px)`,
        overflow: 'hidden auto',
        background: theme.colors.white,
        padding: '20px 24px 24px',
        opacity: 0,
        zIndex: -1,
        width: '100%',
        '&.show': {
            opacity: 1,
            zIndex: 'unset'
        },
        '& .whatnewitem': {
            border: 0,
            paddingTop: 12,
            borderBottom: `1px solid ${theme.colors.border}`,
            cursor: 'unset'
        }
    },
    backwrapper: {},
    backButton: {
        minWidth: 'unset',
        width: 'auto',
        padding: 0,
        '& span': {
            fontWeight: 400
        },
        '&:hover': {
            background: 'none'
        }
    }
}));

const WhatNewDrawerItem = ({ wn, onClick, hideContent }) => {
    const classes = useStyles();
    const title = stripHTML(getWhatNewTitle(wn?.cells));
    const brief = stripHTML(getWhatNewBrief(wn?.cells));
    const createdDate = getFriendlyDate(getWhatNewCreatedTime(wn?.cells));
    const thumbnail = getWhatNewThumbnail(wn?.cells);
    const content = addTargetBlankToATagInText(getWhatNewContent(wn?.cells));
    return (
        <Grid item className={`${classes.whatnewitem} whatnewitem`} onClick={onClick}>
            <Grid item>
                <Typography variant="caption">{createdDate}</Typography>
            </Grid>
            <Grid item className={classes.whatnewitemtitle}>
                <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" style={{ fontWeight: 500 }}>
                    {brief}
                </Typography>
            </Grid>
            <Grid item className={classes.thumbnail}>
                <img src={thumbnail} alt={thumbnail} />
            </Grid>
            {!hideContent && (
                <Grid
                    item
                    className={`${classes.content} revert-style`}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            )}
        </Grid>
    );
};

const WhatNewDrawer = ({ open, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedWN, setSelectedWN] = useState(undefined);

    const promoteFeatures = useAppPromoteFeatures();
    const shownPromoteFeatures = useShownPromoteFeatures();

    const filteredPF = useMemo(() => {
        return promoteFeatures
            .filter(el => !!getWhatNewTitle(el.cells))
            .sort(
                (a, b) =>
                    new Date(getWhatNewCreatedTime(b?.cells)).getTime() -
                    new Date(getWhatNewCreatedTime(a?.cells)).getTime()
            );
    }, [promoteFeatures]);

    const handleSetPromoteFeatureServer = useCallback(
        value => {
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.SHOWN_PROMOTE_FEATURES]: value
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleClickWN = useCallback(
        wn => () => {
            setSelectedWN(wn);
            if (!shownPromoteFeatures.includes(wn.id)) {
                shownPromoteFeatures.push(wn.id);
                handleSetPromoteFeatureServer(shownPromoteFeatures);
            }
        },
        [shownPromoteFeatures, handleSetPromoteFeatureServer]
    );

    const closeWNDetail = useCallback(() => {
        setSelectedWN(undefined);
    }, []);

    return (
        <Drawer
            className={classes.root}
            anchor="right"
            open={open}
            PaperProps={{
                style: {
                    top: 60,
                    height: `calc(100% - 60px)`,
                    width: 449
                }
            }}
            onClose={onClose}
        >
            <Grid className={classes.wrapper} container direction="column">
                <Grid className={classes.title} item>
                    <Typography variant="h4">{t('what_new')}?</Typography>
                </Grid>
                <Grid item className={classes.closeIcon} onClick={onClose}>
                    <IconButton size="medium">
                        <CloseIconSVG size={16} />
                    </IconButton>
                </Grid>
                <Grid item className={classes.list}>
                    {filteredPF.map(wn => (
                        <WhatNewDrawerItem key={wn.id} wn={wn} onClick={handleClickWN(wn)} hideContent />
                    ))}
                </Grid>
                <Grid item className={`${classes.wndetailwrapper} ${selectedWN ? 'show' : ''}`}>
                    <Grid item className={classes.backwrapper}>
                        <Button className={classes.backButton} onClick={closeWNDetail} variant="text">
                            <AnchorLeft style={{ marginRight: 8 }} />
                            {t(`global_back`)}
                        </Button>
                    </Grid>
                    <Collapse in={!!selectedWN}>
                        <WhatNewDrawerItem wn={selectedWN} />
                    </Collapse>
                </Grid>
            </Grid>
        </Drawer>
    );
};

export default React.memo(WhatNewDrawer);
