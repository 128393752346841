import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';

import { useIsCreatingGroup } from 'hooks/permission';
import { useDispatch } from 'react-redux';
import Button from 'components/button/Base';
import * as groupActions from '../actions/group';
import { scrollInToView } from 'utils/scroll';
import LimitPopup from 'payment/components/popups/LimitPopup';
import ModalBase from 'components/modal/Base';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useCanCustomGroup } from 'hooks/payment';
import { useHistory } from 'react-router-dom';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

function MemberButtonAdd({ buttonClickHandler, disabled, onGroupCreated }) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const isCreatingGroup = useIsCreatingGroup();
    const [isOpenLimitModal, setIsOpenLimitModal] = React.useState(false);
    const canCustomGroup = useCanCustomGroup();

    const handleOpenLimitModal = () => setIsOpenLimitModal(true);
    const handleCloseLimitModal = e => {
        stopPropagation(e);
        setIsOpenLimitModal(false);
    };

    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const createGroupHandler = () => {
        if (!canCustomGroup) {
            handleOpenLimitModal();
            return;
        }
        sendManualTrack({ type: 'Add Group' });
        dispatch(
            groupActions.createGroup({
                successCallback: createdGroup => {
                    onGroupCreated(createdGroup);
                    const node = document.getElementById('groupListBottom');
                    scrollInToView(node);
                },
                errorCallback: () => {}
            })
        );
    };

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item style={{ width: '100%' }} onClick={createGroupHandler}>
                <Button variant="outlined" fullWidth size="medium" disabled={isCreatingGroup}>
                    {!canCustomGroup ? <UpgradeSVG style={{ marginRight: theme.spacing(2) }} /> : '+'}{' '}
                    {!isTablet && 'Add'} Group
                </Button>
            </Grid>
            <ModalBase open={isOpenLimitModal} handleClose={handleCloseLimitModal}>
                <LimitPopup
                    title={t('add_group_limit_title')}
                    message={t('add_group_limit_message')}
                    onCancel={handleCloseLimitModal}
                    onUpgrade={handleUpgrade}
                    onClose={handleCloseLimitModal}
                />
            </ModalBase>
        </Grid>
    );
}

MemberButtonAdd.propTypes = {
    buttonClickHandler: PropTypes.func
};
export default MemberButtonAdd;
