import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { usePOMessages, usePOResourceKey, usePOMessageKeys, usePoTargetLanguage } from 'hooks/gridUI/import';
import { useDispatch } from 'react-redux';
import * as importActions from 'gridUI/actions/import';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { sendManualTrack } from 'tracker';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import FileSVG from 'assets/images/svg/FileSVG';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import hexToRgba from 'hex-to-rgba';
import { generateCode } from 'utils/language';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import CountryFlag from 'components/svg-icon/CountryFlag';
import Spinner from 'components/spinner/Base';
import { usePoLanguageOptions } from 'hooks/gridUI';
import { enqueueSnackbar } from 'notifier/actions';
import * as gridUIActions from 'gridUI/actions';

const useStyles = makeStyles(theme => ({
    root: {
        width: 740,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'initial',
            width: '100%',
            height: '100%'
        }
    },
    fullWidth: {
        width: '100%'
    },

    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },

    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    box: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        overflowX: 'auto'
    },
    w170: {
        minWidth: 170
    },
    split: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },

    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    search: {
        width: 240
    },
    targetList: {
        padding: theme.spacing(3),
        height: 152,
        overflowY: 'auto',
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`
    },

    spacing: {
        marginBottom: theme.spacing(3)
    },
    spacing4: {
        marginBottom: theme.spacing(4)
    },
    treeBox: {
        height: 320,
        flex: 1,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        position: 'relative'
    },
    treeBoxHeader: {
        flexBasis: 40,
        background: theme.colors.ghostwhite,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    treeBoxContent: {
        flex: 1,
        overflow: 'auto',
        position: 'relative',
        padding: theme.spacing(3)
    },
    messageItem: {},
    messageItemHeader: {
        padding: `4px 0px`
    },
    messageItemContent: {
        padding: `4px 0px`
    },
    addMoreSource: {
        height: 36,
        cursor: 'pointer',
        minWidth: 170,
        border: `1px dashed ${theme.colors.border}`,
        borderRadius: 4,
        '& p': {
            color: theme.colors.lightLavender
        },
        '& svg': {
            marginRight: theme.spacing(2)
        },
        '&:hover': {
            background: theme.colors.solitude
        }
    },
    dlFlex: {
        display: 'flex'
    },
    langSelect: {
        maxWidth: 300
    },
    rowItem: {
        minWidth: 160
    },
    spb3: {
        marginBottom: theme.spacing(3)
    },
    virtualizeList: {
        outline: 'none'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    highlight: {
        color: theme.colors.lightLavender,
        cursor: 'pointer'
    },

    fileIcon: {
        display: 'flex',
        marginRight: theme.spacing(1)
    },
    fileName: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    showPreview: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8),
        cursor: 'pointer'
    },
    pr2: {
        paddingRight: theme.spacing(2)
    },
    preview: {
        width: '100%',
        paddingBottom: theme.spacing(3)
    }
}));

function LocalizationImportPOSetup({ onClose, isMulti, dbId, files, viewId, gridDetail }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [isUploading, setIsUploading] = React.useState(false);
    const columnOptions = usePoLanguageOptions();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [selectedTarget, setSelectedTarget] = React.useState(null);
    const defaultMessageKey = gridDetail?.messageKey
        ? { value: gridDetail?.messageKey, label: gridDetail?.messageKey }
        : null;
    const [messageKey, setMessageKey] = React.useState(defaultMessageKey);

    const resourceKey = usePOResourceKey(dbId);
    const messages = usePOMessages(dbId);
    const messageKeys = usePOMessageKeys(dbId);
    const poTargetLanguage = usePoTargetLanguage(dbId);

    const messageKeyOptions = React.useMemo(() => {
        return messageKeys?.map(key => ({
            label: key,
            value: key
        }));
    }, [messageKeys]);

    const headerCode = React.useMemo(() => {
        return generateCode(poTargetLanguage);
    }, [poTargetLanguage]);

    const fileTarget = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[headerCode];
    }, [headerCode]);

    const defaultTarget = React.useMemo(() => {
        if (!selectedTarget && fileTarget) {
            return columnOptions?.find(option => option?.label === fileTarget?.id);
        }

        return selectedTarget;
    }, [selectedTarget, fileTarget, columnOptions]);

    const isValidReImport = React.useMemo(() => {
        return !!defaultTarget;
    }, [defaultTarget]);

    React.useEffect(() => {
        if (files) {
            setIsUploading(true);
            dispatch(
                importActions.importLocalizationGridReadPOFile({
                    dbId,
                    file: files,
                    successCallback: ({ header }) => {
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                    }
                })
            );
        }
    }, [dispatch, dbId, files]);

    const handleCreateLocalizationGrid = React.useCallback(() => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Import Localization Grid From JSON file` });

        let body = {
            key: resourceKey,
            columnId: defaultTarget?.value
        };

        if (messageKey) {
            body = {
                ...body,
                messageKey: messageKey?.value
            };
        }

        if (!defaultTarget?.value) {
            dispatch(
                enqueueSnackbar({
                    message: 'Please set dependency for source column before importing!',
                    type: 'info'
                })
            );
            return;
        }

        dispatch(
            gridUIActions.importViewJSONData({
                dbId,
                viewId,
                body,
                query: {
                    type: 'po'
                },
                successCallback: newGrid => {
                    setIsSubmitting(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to create localization');
                }
            })
        );
    }, [dbId, dispatch, defaultTarget, viewId, onClose, resourceKey, messageKey]);

    const fileName = React.useMemo(() => {
        return files?.[0]?.name;
    }, [files]);

    const handleMessageKeyChange = React.useCallback(option => {
        setMessageKey(option);
    }, []);

    const contentRender = React.useCallback(() => {
        return (
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid item container direction="column" className={classes.preview}>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction="column" wrap="nowrap" className={classes.treeBox}>
                            {isUploading && (
                                <Grid item className={classes.spinnerWrapper}>
                                    <Spinner size={30} thick={4} />
                                </Grid>
                            )}
                            <Grid
                                item
                                container
                                direction="row"
                                className={classes.treeBoxHeader}
                                alignItems="center"
                                wrap="nowrap"
                            >
                                <Grid item className={classes.fileIcon}>
                                    <FileSVG />
                                </Grid>
                                <Grid item className={classes.fileName}>
                                    <p className={'body1 line-clamp-1'}>{fileName || 'Default File'}</p>
                                </Grid>
                            </Grid>
                            {!messages?.length && (
                                <Grid item container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <p className="caption">No message found</p>
                                    </Grid>
                                </Grid>
                            )}
                            {messages?.length > 0 && (
                                <Grid item className={classes.treeBoxContent}>
                                    <Grid container direction="column">
                                        {messages?.map(message => {
                                            return (
                                                <Grid
                                                    item
                                                    container
                                                    direction="column"
                                                    key={message?.originalString}
                                                    className={classes.messageItem}
                                                >
                                                    <Grid item className={`${classes.messageItemHeader}`}>
                                                        <Grid
                                                            container
                                                            wrap="nowrap"
                                                            alignItems="center"
                                                            direction="row"
                                                        >
                                                            <Grid item className={classes.pr2}>
                                                                <p className="body2 inline whitespace-nowrap line-clamp-1 font-medium">
                                                                    Source:
                                                                </p>
                                                            </Grid>
                                                            <Grid item>
                                                                <p className="body2 inline whitespace-nowrap line-clamp-1">
                                                                    {message?.originalString}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item className={classes.messageItemContent}>
                                                        <Grid container wrap="nowrap" direction="row">
                                                            <Grid item className={classes.pr2}>
                                                                <ArrowNextSVG color={theme.colors.primaryText} />
                                                            </Grid>
                                                            <Grid item className={classes.pr2}>
                                                                <CountryFlag languageCountryCode={fileTarget?.id} />
                                                            </Grid>
                                                            <Grid item>
                                                                <p className="caption line-clamp-1">
                                                                    {message?.translationString}
                                                                </p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container alignItems="center" direction="row" className={classes.fullWidth}>
                    <Grid item xs={4}>
                        <p className="body1">Targeted language to update</p>
                    </Grid>
                    <Grid item xs={4}>
                        <LDBasePortalVirtualize
                            ddPlaceholder={'Select a language'}
                            menuPlaceholder={'Select a language'}
                            options={columnOptions}
                            onChange={option => setSelectedTarget(option)}
                            defaultValue={defaultTarget}
                            isMulti={false}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    direction="row"
                    className={classes.fullWidth}
                    style={{ marginTop: theme.spacing(3) }}
                >
                    <Grid item xs={4}>
                        <p className="body1">{t('global_select_message_key')}</p>
                    </Grid>
                    <Grid item xs={4}>
                        <LDBasePortalVirtualize
                            ddPlaceholder={t('global_select_message_key')}
                            menuPlaceholder={t('global_find_message_key')}
                            options={messageKeyOptions}
                            onChange={handleMessageKeyChange}
                            defaultValue={messageKey}
                            isMulti={false}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [
        columnOptions,
        defaultTarget,
        fileTarget,
        theme,
        classes,
        fileName,
        messages,
        isUploading,
        t,
        messageKeyOptions,
        handleMessageKeyChange,
        messageKey
    ]);

    return (
        <>
            <DialogTitle title={t('import_data')} onClose={onClose} />
            <DialogContent>{contentRender()}</DialogContent>
            <DialogActions>
                <Grid item container justify="flex-end" spacing={2} alignItems="center">
                    <Grid item>
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item>
                                <ButtonBase onClick={onClose} variant="outlined" width={120}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit}>
                                    <ButtonBase
                                        disabled={isSubmitting || !isValidReImport}
                                        onClick={handleCreateLocalizationGrid}
                                        variant="contained"
                                        width={120}
                                    >
                                        {t('global_import')}
                                    </ButtonBase>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(LocalizationImportPOSetup);
