import React from 'react';

function ImportSVG({ color = '#93929e', ...other }) {
    return (
        <svg {...other} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.1844 12.2334H23.5922C23.2077 12.2334 22.8931 12.548 22.8931 12.9324C22.8931 13.3169 23.2077 13.6315 23.5922 13.6315H29.1844C30.3378 13.6315 31.2815 14.5751 31.2815 15.7285V32.5052C31.2815 33.6586 30.3378 34.6023 29.1844 34.6023H6.81546C5.66206 34.6023 4.71837 33.6586 4.71837 32.5052V15.7285C4.71837 14.5751 5.66206 13.6315 6.81546 13.6315H13.1067C13.4912 13.6315 13.8057 13.3169 13.8057 12.9324C13.8057 12.548 13.4912 12.2334 13.1067 12.2334H6.81546C4.89313 12.2334 3.32031 13.8062 3.32031 15.7285V32.5052C3.32031 34.4276 4.89313 36.0004 6.81546 36.0004H29.1844C31.1067 36.0004 32.6795 34.4276 32.6795 32.5052V15.7285C32.6795 13.8062 31.1067 12.2334 29.1844 12.2334Z"
                fill="#4D3F8A"
            />
            <path
                d="M18 2.4466C18.3845 2.4466 18.6991 2.13204 18.6991 1.74757V0.699029C18.6991 0.314563 18.3845 0 18 0C17.6156 0 17.301 0.314563 17.301 0.699029V1.74757C17.301 2.13204 17.6156 2.4466 18 2.4466Z"
                fill="#F67B8E"
            />
            <path
                d="M11.3242 18.4895C11.0446 18.7691 11.0446 19.1885 11.3242 19.4681L17.5106 25.6545C17.6504 25.7943 17.8252 25.8642 17.9999 25.8642C18.1747 25.8642 18.3495 25.7943 18.4893 25.6545L24.6757 19.4681C24.9553 19.1885 24.9553 18.7691 24.6757 18.4895C24.396 18.2099 23.9766 18.2099 23.697 18.4895L18.699 23.4875V4.89337C18.699 4.5089 18.3844 4.19434 17.9999 4.19434C17.6155 4.19434 17.3009 4.5089 17.3009 4.89337V23.4875L12.3029 18.4895C12.0232 18.2099 11.6038 18.2099 11.3242 18.4895Z"
                fill="#F67B8E"
            />
        </svg>
    );
}

export default React.memo(ImportSVG);
