import React from 'react';

function ExtraRecordSVG({ ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8735 4.89189C15.1604 4.8454 15.3809 4.57495 15.3809 4.24957L15.3809 1.27141L15.3744 1.17538C15.3318 0.861966 15.0842 0.621094 14.7856 0.621094L0.976112 0.621093L0.888425 0.628151C0.602117 0.674642 0.380859 0.945172 0.380859 1.27141L0.380859 4.24957L0.38734 4.34537C0.43003 4.65807 0.678356 4.89894 0.976112 4.89894L14.7856 4.89894L14.8735 4.89189ZM14.8735 10.133C15.1604 10.0865 15.3809 9.81605 15.3809 9.49067L15.3809 6.51251L15.3744 6.41648C15.3318 6.10306 15.0842 5.86219 14.7856 5.86219L0.976112 5.86219L0.888425 5.86925C0.602116 5.91574 0.380859 6.18627 0.380859 6.51251L0.380859 9.49067L0.38734 9.58647C0.430029 9.89917 0.678356 10.14 0.976112 10.14L14.7856 10.14L14.8735 10.133ZM15.3809 14.7318C15.3809 15.0571 15.1604 15.3276 14.8735 15.3741L14.7856 15.3811L0.976112 15.3811C0.678356 15.3811 0.430029 15.1403 0.38734 14.8276L0.380859 14.7318L0.380859 11.7536C0.380859 11.4282 0.602116 11.1578 0.888424 11.1113L0.976112 11.1042L14.7856 11.1042C15.0842 11.1042 15.3318 11.3451 15.3744 11.6578L15.3809 11.7536L15.3809 14.7318ZM14.1894 1.92043L14.1894 3.59986L1.57136 3.59986L1.57136 1.92043L14.1894 1.92043ZM14.1894 8.84096L14.1894 7.16153L1.57136 7.16153L1.57136 8.84096L14.1894 8.84096ZM14.1894 12.4026L14.1894 14.0821L1.57136 14.0821L1.57136 12.4026L14.1894 12.4026Z"
                fill="#2D97F2"
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(ExtraRecordSVG);
