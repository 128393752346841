import React from 'react';

function TMStatusNotApprovedSVG({ color = '#7869B9', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5856 10.7939V10.7055C14.5856 10.3519 14.9392 10.0867 15.2044 10.1751C15.6464 10.1751 15.9116 10.4403 15.9116 10.7939V10.8823C15.9116 12.4735 14.5856 13.7994 12.9945 13.7994H2.91713C1.32597 13.7994 0 12.4735 0 10.8823V4.69447C0 3.10331 1.32597 1.77734 2.91713 1.77734H12.9945C14.674 1.77734 15.9116 3.10331 16 4.69447V7.08121C16 7.4348 15.6464 7.7 15.2928 7.7C14.8508 7.7 14.5856 7.4348 14.5856 7.08121V4.60607C14.5856 3.7221 13.8785 3.01491 12.9945 3.01491H2.91713C2.03315 3.01491 1.32597 3.7221 1.32597 4.60607V10.7939C1.32597 11.6779 2.03315 12.3851 2.91713 12.3851H12.9945C13.8785 12.3851 14.5856 11.6779 14.5856 10.7939ZM5.4368 6.15505H6.94576V5.3329H2.93315V6.15505H4.42181V10.259H5.4368V6.15505ZM10.1193 8.90568L8.85395 5.3329H7.52769V10.259H8.54268V8.91245L8.44118 6.59149L9.76744 10.259H10.4644L11.794 6.58811L11.6925 8.91245V10.259H12.7109V5.3329H11.3779L10.1193 8.90568Z"
                fill="#6E5BBE"
                fillOpacity="0.8"
            />
            <rect x="14.3594" y="2" width="1.5" height="16.8279" transform="rotate(52.5495 14.3594 2)" fill="#EB4F5E" />
        </svg>
    );
}

export default React.memo(TMStatusNotApprovedSVG);
