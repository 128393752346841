import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'advancedSearch/actions';
import isEqual from 'lodash/isEqual';
import ViewCellInfo from './ViewCellInfo';
import MarkdownEditor, { SLATE_MD_EMPTY_VALUE } from 'components/editor/markdown/Base';
import serialize from 'components/editor/markdown/serializer/slateToMd';
import deserialize from 'components/editor/markdown/serializer/mdToSlate';

import { isKbEscape, isKbTab, isKbShiftTab, isKbShiftEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { Portal } from 'react-overlays';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: -115,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: 930,
        maxWidth: `calc(100% - 40px)`,
        borderRadius: 6,
        boxShadow: theme.shadows[1],
        height: '100%',
        maxHeight: `calc(100% - 50px)`,
        background: theme.colors.white,
        pointerEvents: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxWidth: `calc(100vw - ${100}px)`
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: `calc(100vw - ${40}px)`
        },
        '& .cell-editor': {
            textAlign: 'left',
            padding: `${theme.spacing(3)}px ${20}px`,
            fontSize: '0.875rem',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            background: props => props.background || theme.colors.white,
            borderRadius: 6
        },
        '& *': {
            lineHeight: `22px`
        },
        '& p': {
            marginTop: 0,
            marginBottom: 0
        },
        '& blockquote': {
            margin: `0px 0px 14px`,
            padding: `12px 24px`,
            borderLeft: `2px solid ${theme.colors.midGrey}`,
            borderRadius: 4,
            background: theme.colors.ghostwhite
        },
        '& ul, & ol': {
            '& li': {
                marginBottom: 3
            },
            marginTop: 0,
            marginBottom: 14,
            borderRadius: 4
        }
    },
    editor: {
        width: '100%',
        height: '100%'
    },
    expand: {
        flex: 1,
        overflowY: 'auto'
    },
    footer: {
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
        background: theme.colors.ghostwhite,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6
    },
    fs10: {
        fontSize: 10
    }
}));

function MarkdownEdit({
    columnId,
    value: cellValue,
    rowId,
    width,
    height,
    rowIndex,
    columnIndex,
    background,
    originalValue,
    ...rest
}) {
    const dispatch = useDispatch();
    const defaultValue = SLATE_MD_EMPTY_VALUE;
    const [value, setValue] = useState(null);

    React.useEffect(() => {
        let fetchData = async () => {
            if (originalValue && originalValue?.length) {
                let data = await deserialize(originalValue ? originalValue : '');

                if (data && data.length === 0) {
                    data = defaultValue;
                }
                setValue(data);
            } else {
                setValue(defaultValue);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classes = useStyles({ width, height, background });
    const isEnterAlready = useRef(false);
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        if (isSameSource()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameSource()) {
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value: valueFormatted,
                    rowId,
                    columnId
                })
            );
        }
    };

    const handleOnChange = content => {
        if (serialize(content) !== value) {
            setValue(content);
        }
    };

    const isSameSource = () => {
        const valueFormatted = serialize(value);
        if (valueFormatted === defaultValue && valueFormatted === originalValue) return true;
        if (valueFormatted === defaultValue && (originalValue === null || !originalValue)) return true;
        if (valueFormatted !== originalValue) return false;
        if (valueFormatted === originalValue) return true;
        return false;
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            dispatch(gridActions.cancelCellEdit());
        }
        if (isKbShiftEnter(e)) {
            e.preventDefault();
            if (isSameSource()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAwayAndGoNextRow({
                    value: valueFormatted,
                    rowId,
                    columnId,
                    columnIndex,
                    rowIndex
                })
            );
        }
        if (isKbTab(e)) {
            e.preventDefault();

            console.log('isSameSource', isSameSource);
            if (isSameSource()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAwayAndGoNextColumn({
                    value: valueFormatted,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }
        if (isKbShiftTab(e)) {
            e.preventDefault();
            if (isSameSource()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAwayAndGoPreviousColumn({
                    value: valueFormatted,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }
    };

    return (
        <Portal container={document.getElementById('scrollOverlay')}>
            <div ref={rootRef} className={classes.root}>
                <ViewCellInfo
                    rowIndex={rowIndex}
                    overrideStyle={{
                        left: 0,
                        top: 10
                    }}
                />

                <Grid container direction="column" className={classes.editor} wrap="nowrap">
                    <Grid item className={classes.expand}>
                        {value && (
                            <MarkdownEditor
                                onKeyDown={handleKeyDown}
                                containerRef={rootRef}
                                value={value}
                                onChange={handleOnChange}
                                className={`cell-editor`}
                                autoFocus={false}
                                initFocus={true}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.footer}>
                        <Grid container direction="row" spacing={2} alignItems="center" justify="flex-end">
                            <Grid item>
                                <Typography className={classes.fs10} variant="body1" display="inline">
                                    Return
                                </Typography>{' '}
                                <Typography className={classes.fs10} variant="body2" display="inline">
                                    to insert a line-break
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.fs10} variant="body1" display="inline">
                                    Shift + Return
                                </Typography>{' '}
                                <Typography className={classes.fs10} variant="body2" display="inline">
                                    to finish cell edit
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Portal>
    );
}

export default React.memo(MarkdownEdit, (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
});
