import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress } from '@material-ui/core';
import CardInfo from 'components/cardInfo/Base';
import AvatarBase from 'components/avatar/Base';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import ButtonBase from 'components/button/Base';
import { useGroupList, useRoleOptions } from 'hooks/permission';
import { SUPPORTED_LANGUAGES } from 'const/languageData';
import CountryFlag from 'components/svg-icon/CountryFlag';
import UsersDropdown from './UsersDropdown';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { getAvatarUrl } from 'utils/images';
import { generateUserBadge } from 'utils/user';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import { ALL_LANGUAGES, GROUP_ADMIN_TYPES } from 'const';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import LDBaseCardInfo from 'components/selects/LDBaseCardInfo';

const useStyles = makeStyles(theme => ({
    root: {
        width: 480
    },
    margin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    dropdown: {
        marginTop: 10,
        paddingLeft: 10,
        height: 36,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        display: 'flex',
        cursor: 'pointer'
    },

    dropdownGroup: {
        marginTop: 10,
        cursor: 'pointer'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    languagePairItem: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    languagePairRemove: {
        display: 'flex',
        width: 20
    },
    addButton: {
        paddingRight: theme.spacing(2),
        display: 'flex',
        cursor: 'pointer'
    },
    addLanguagePairText: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    addLanguagePairButton: {
        display: 'flex',
        cursor: 'pointer'
    },
    languageSelect: {
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: theme.colors.white,
        borderRadius: 4
    },
    leftCircle: {
        position: 'absolute',
        backgroundColor: theme.colors.white,
        borderRadius: '50%',
        border: `1px solid ${theme.colors.border}`,
        top: 6,
        right: -16,
        width: 24,
        height: 24
    },
    rightCircle: {
        position: 'absolute',
        backgroundColor: theme.colors.white,
        borderRadius: '50%',
        border: `1px solid ${theme.colors.border}`,
        top: 6,
        left: -16,
        width: 24,
        height: 24
    },
    split: {
        borderRadius: '50%',
        width: 24,
        height: 24,
        zIndex: 1,
        backgroundColor: theme.colors.white,
        margin: '7px -7px',
        textAlign: 'center'
    }
}));

function EditMemberModal({ member, onSubmit, onClose, isSubmitting }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const groups = useGroupList();
    const roleOptions = useRoleOptions({ isIgnoreOwner: true });

    const groupsList = React.useMemo(() => {
        return groups?.map(group => {
            const isAdminGroup = group?.type === GROUP_ADMIN_TYPES.ADMIN;
            const adminAvatar = isAdminGroup ? <AdminGroupAvatar size={24} /> : <TranslatorGroupAvatar size={24} />;
            return {
                ...group,
                value: group?.id,
                label: group?.name,
                icon: () =>
                    group.systemGroup ? adminAvatar : <AvatarBase size={24}>{group.name.slice(0, 2)}</AvatarBase>,
                systemGroup: group?.systemGroup
            };
        });
    }, [groups]);

    const memberGroups = React.useMemo(
        () =>
            groupsList?.filter(group => {
                return member?.groups?.some(g => g.id === group.id);
            }),
        [groupsList, member]
    );

    const [addToGroupsList, setAddToGroupsList] = React.useState(memberGroups);

    const langOptions = React.useMemo(() => {
        const allLanguageOption = {
            value: ALL_LANGUAGES,
            label: t('global_all')
        };
        const options = SUPPORTED_LANGUAGES.map(lang => ({
            value: lang.id,
            label: lang.id,
            icon: () => <CountryFlag languageCountryCode={lang.id} />
        }));
        return [allLanguageOption, ...options];
    }, [t]);

    const selectedRole = React.useMemo(() => {
        return roleOptions.find(role => role.id === member?.roles?.[0]?.id);
    }, [roleOptions, member]);

    const selectedLanguagePairs = React.useMemo(() => {
        return member?.languagePairs?.map(pair => ({
            ...pair,
            from: langOptions.find(lang => lang.value === pair.from),
            to: langOptions.find(lang => lang.value === pair.to)
        }));
    }, [langOptions, member]);

    const [role, setRole] = React.useState(selectedRole);
    const [languagePairs, setLanguagePairs] = React.useState(selectedLanguagePairs);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleLanguagePairsChange = ({ languagePairIndex, langOption, type }) => {
        const newLanguagePairs = languagePairs?.map((pair, index) => {
            if (languagePairIndex === index) {
                return { ...pair, [type]: langOption };
            }
            return pair;
        });
        setLanguagePairs(newLanguagePairs);
    };

    const handleSubmit = () => {
        onSubmit({
            userId: member.id,
            role,
            languagePairs: languagePairs?.map(pair => ({ from: pair.from.value, to: pair.to.value })),
            groups: addToGroupsList
        });
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleRoleChange = option => {
        setRole && setRole(option);
        handleClickAway();
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleAddLanguagePair = () => {
        setLanguagePairs([...languagePairs, { from: null, to: null }]);
    };

    const handleRemoveLanguagePair = languagePairIndex => {
        const newLanguagePairs = languagePairs?.filter((pair, index) => {
            return languagePairIndex !== index;
        });
        setLanguagePairs(newLanguagePairs);
    };

    const handleAddToGroupsChange = (groups = []) => {
        const mapOptions = groups.map(group => ({
            ...group
        }));
        setAddToGroupsList(mapOptions);
    };

    const isAnyLanguageUnfilled = React.useMemo(() => {
        for (let pair of languagePairs) {
            if (!pair?.from?.value || !pair?.to?.value) {
                return true;
            }
        }
        return false;
    }, [languagePairs]);

    return (
        <Grid container wrap="nowrap" direction="column" className={classes.root}>
            <DialogTitle title={t('edit_member_modal_title')} onClose={onClose} />
            <DialogContent>
                <Grid item>
                    <CardInfo
                        avatar={
                            <AvatarBase
                                src={getAvatarUrl(member?.imageUrl)}
                                size="small"
                                alt="avatar"
                                badgeContent={generateUserBadge(member)}
                            />
                        }
                        title={member?.fullName}
                        subTitle={member?.email}
                    />
                </Grid>
                <Grid item className={classes.margin}>
                    <Typography variant="body1">{t('edit_member_modal_company_role')}</Typography>
                </Grid>
                <div
                    onClick={handleClick}
                    className="flex justify-between items-center flex-nowrap mt-2.5 px-3.5 h-9 border border-app rounded cursor-pointer"
                >
                    <div className="flex items-center gap-2">
                        {role.icon}
                        <p className="body2">{role?.label}</p>
                    </div>
                    <ArrowDownSVG />
                </div>

                {anchorEl && (
                    <PopperMenu anchorEl={anchorEl} placement={'bottom-start'} id={`select-table-options`}>
                        <UsersDropdown
                            selectedOption={role}
                            onChange={handleRoleChange}
                            handleClickAway={handleClickAway}
                            options={roleOptions}
                        />
                    </PopperMenu>
                )}
                <Grid item className={classes.margin}>
                    <Grid container direction="column">
                        <Typography variant="body1">{t('edit_member_modal_company_group')}</Typography>
                        <Grid item className={classes.dropdownGroup}>
                            <LDBaseCardInfo
                                ddPlaceholder={t('add_group')}
                                menuPlaceholder={t('find_group')}
                                options={groupsList}
                                onChange={handleAddToGroupsChange}
                                defaultValue={addToGroupsList}
                                isMulti
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.margin}>
                    <Typography variant="body1">{t('global_language_pairs')}</Typography>
                </Grid>
                <div className="flex flex-col gap-3.5">
                    {languagePairs.length > 0 ? (
                        languagePairs?.map((pair, index) => (
                            <div className="flex items-center flex-nowrap" key={index}>
                                <div className="relative flex-1 overflow-hidden bg-white rounded">
                                    <LDBasePortalVirtualize
                                        menuPlaceholder={`${t('global_search')}...`}
                                        options={langOptions}
                                        onChange={langOption =>
                                            handleLanguagePairsChange({
                                                langOption,
                                                languagePairIndex: index,
                                                type: 'from'
                                            })
                                        }
                                        defaultValue={pair?.from}
                                    />
                                    <div className={classes.leftCircle} />
                                </div>

                                <PathSVG />
                                <div className="relative flex-1 overflow-hidden bg-white rounded">
                                    <LDBasePortalVirtualize
                                        menuPlaceholder={`${t('global_search')}...`}
                                        options={langOptions}
                                        onChange={langOption =>
                                            handleLanguagePairsChange({
                                                langOption,
                                                languagePairIndex: index,
                                                type: 'to'
                                            })
                                        }
                                        defaultValue={pair?.to}
                                    />
                                    <div className={classes.rightCircle} />
                                </div>

                                <IconButton
                                    className="w-5"
                                    size="small"
                                    onClick={() => handleRemoveLanguagePair(index)}
                                >
                                    <CloseIconSVG size="small" />
                                </IconButton>
                            </div>

                            // <Grid item container alignItems="center" key={index} wrap="nowrap">
                            //     <Grid item xs className={classes.languageSelect}>

                            //     </Grid>
                            //     <PathSVG />

                            //     <Grid item className={classes.split}>
                            //         <PathSVG />
                            //     </Grid>
                            //     <Grid item xs className={classes.languageSelect}>

                            //     </Grid>
                            //     <Grid item className={classes.languagePairRemove}>

                            //     </Grid>
                            // </Grid>
                        ))
                    ) : (
                        <Grid item>
                            <Typography variant="body2">{t('edit_member_modal_no_languages')}</Typography>
                        </Grid>
                    )}
                    <Grid item container>
                        <Grid item>
                            <Grid container className={classes.addButton} onClick={handleAddLanguagePair} spacing={2}>
                                <Grid item className={classes.addLanguagePairButton}>
                                    <ColorAddSVG />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" className={classes.addLanguagePairText}>
                                        {t('edit_member_modal_add_another_language')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ flex: 1 }}></Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase onClick={onClose} width={120} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <ButtonBase
                            onClick={handleSubmit}
                            width={120}
                            disabled={isSubmitting || isAnyLanguageUnfilled}
                            variant="contained"
                        >
                            {t('global_save')}
                        </ButtonBase>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </DialogActions>
        </Grid>
    );
}

export default React.memo(EditMemberModal);
