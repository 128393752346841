import React from 'react';
import Prism from 'prismjs';
import { Editor, Transforms, Range, createEditor, Text } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import Portal from 'components/formula/components/Portal';
import { insertTag } from 'components/formula/utils';
import Element from 'components/formula/components';
import { generateRegex } from 'utils/gridUI/tag';
import { useTheme } from '@material-ui/core/styles';
import ListItem from 'components/list/Item';
import Tooltip from 'components/tooltip/Base';
import NonBreakingSpaceSVG from 'assets/images/svg/NonBreakingSpaceSVG';
import { makeStyles } from '@material-ui/core';
import { regexSpecialCharacter, regexZeroWidthSpace } from 'const';

// eslint-disable-next-line
Prism.languages.token=Prism.languages.extend("markup",{}),Prism.languages.insertBefore("token","prolog",{}); // prettier-ignore

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        zIndex: 1,
        height: `100%`
    },
    nonBreakingSpace: {
        position: 'relative',
        zIndex: -1,
        letterSpacing: 9,
        borderRadius: 2,
        background: '#f8ad14',
        '& svg': {
            position: 'absolute',
            bottom: 0,
            left: 0
        }
    },
    zeroWidthSpace: {
        background: '#f8ad14',
        borderRadius: 2,
        '&:before': {
            content: `'•'`
        }
    }
}));

function withSingleLine(editor) {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]) => {
        if (path.length === 0) {
            if (editor.children.length > 1) {
                Transforms.mergeNodes(editor);
            }
        }

        return normalizeNode([node, path]);
    };

    return editor;
}

function TokenEditor({
    value,
    suggestions = [],
    onChange,
    suggestionZIndex,
    placeholder,
    metaData,
    onBlur,
    tokenDetection,
    predefinedTokens = [],
    onKeyDown,
    parentTags,
    isChildDependency,
    inputRef,
    readOnly,
    isHighlightSymbolCharacter,
    isSingleLine,
    enableTag = true,
    ...rest
}) {
    const classes = useStyles();
    const ref = React.useRef();
    const [target, setTarget] = React.useState();
    const [suggestionIndex, setSuggestionIndex] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const isPreventBlurRef = React.useRef(false);
    const renderedZeroWidthSpaceLeafRef = React.useRef({});

    const highlightToken = React.useMemo(() => {
        const regex = generateRegex({ tokenDetection, predefinedTokens });
        const tokenize =
            (Prism.languages.extend('markup', {}),
            Prism.languages.insertBefore('token', 'prolog', {
                tag: { pattern: new RegExp(regex, 'img'), alias: 'punctuation' },
                ...(isHighlightSymbolCharacter
                    ? {
                          symbol: { pattern: new RegExp(regexSpecialCharacter), alias: 'punctuation' },
                          nonBreakingSpace: { pattern: new RegExp(/\u00A0/), alias: 'punctuation' },
                          zeroWidthSpace: { pattern: new RegExp(regexZeroWidthSpace), alias: 'punctuation' }
                      }
                    : {})
            }));
        return tokenize;
    }, [tokenDetection, predefinedTokens, isHighlightSymbolCharacter]);

    const renderLeaf = React.useCallback(
        props => (
            <Leaf
                isHighlightSymbolCharacter={isHighlightSymbolCharacter}
                enableTag={enableTag}
                renderedZeroWidthSpaceLeafRef={renderedZeroWidthSpaceLeafRef}
                {...props}
            />
        ),
        [isHighlightSymbolCharacter, enableTag]
    );

    const renderElement = React.useCallback(
        props => <Element isHighlightSymbolCharacter={isHighlightSymbolCharacter} {...props} />,
        [isHighlightSymbolCharacter]
    );

    const editor = React.useMemo(() => {
        if (isSingleLine) {
            return withSingleLine(withReact(withHistory(createEditor())));
        }
        return withReact(withHistory(createEditor()));
    }, [isSingleLine]);

    const suggestionsFiltered = React.useMemo(() => {
        return suggestions.filter(s => s?.label?.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10);
    }, [suggestions, search]);

    const insertSuggestion = React.useCallback(
        suggestion => {
            if (!suggestion) return;
            Transforms.select(editor, target);
            insertTag(editor, suggestion);
            ReactEditor.focus(editor);
            setTarget(null);
        },
        [editor, target]
    );

    const decorate = React.useCallback(
        ([node, path]) => {
            renderedZeroWidthSpaceLeafRef.current = {};
            const ranges = [];

            if (!Text.isText(node)) {
                return ranges;
            }

            const getLength = token => {
                if (typeof token === 'string') {
                    return token.length;
                } else if (typeof token.content === 'string') {
                    return token.content.length;
                } else {
                    return token.content.reduce((l, t) => l + getLength(t), 0);
                }
            };

            const tokens = Prism.tokenize(node.text, highlightToken);
            let start = 0;
            let i = 0;
            for (const token of tokens) {
                const length = getLength(token);
                const end = start + length;

                if (typeof token !== 'string') {
                    const isExistedInParentTag = !parentTags?.length
                        ? isChildDependency
                            ? false
                            : true
                        : parentTags?.includes(token?.content);
                    ranges.push({
                        [token.type]: true,
                        anchor: { path, offset: start },
                        focus: { path, offset: end },
                        isExistedInParentTag,
                        index: i++
                    });
                }

                start = end;
            }
            return ranges;
        },
        [highlightToken, parentTags, isChildDependency]
    );

    const handleKeyDownChange = React.useCallback(
        event => {
            if (target && suggestionsFiltered?.length) {
                switch (event.key) {
                    case 'ArrowDown': {
                        event.preventDefault();
                        const prevIndex = suggestionIndex >= suggestionsFiltered?.length - 1 ? 0 : suggestionIndex + 1;
                        setSuggestionIndex(prevIndex);
                        return;
                    }
                    case 'ArrowUp': {
                        event.preventDefault();
                        const prevIndex = suggestionIndex <= 0 ? suggestionsFiltered?.length - 1 : suggestionIndex - 1;
                        setSuggestionIndex(prevIndex);
                        return;
                    }

                    case 'Tab':
                    case 'Enter': {
                        if (suggestionsFiltered?.length) {
                            event.preventDefault();
                            insertSuggestion(suggestionsFiltered?.[suggestionIndex]);
                            return;
                        }
                        break;
                    }
                    case 'Escape':
                        event.preventDefault();
                        setTarget(null);
                        return;

                    default:
                        return;
                }
            } else {
                onKeyDown && onKeyDown(event);
            }
        },
        [target, suggestionIndex, suggestionsFiltered, insertSuggestion, onKeyDown]
    );

    React.useEffect(() => {
        if (target && suggestionsFiltered?.length > 0) {
            try {
                const el = ref.current;
                const domRange = ReactEditor.toDOMRange(editor, target);
                const rect = domRange.getBoundingClientRect();
                el.style.top = `${rect.top + window.pageYOffset + 24}px`;
                el.style.left = `${rect.left + window.pageXOffset}px`;
            } catch (error) {
                console.log('error', error);
            }
        }
    }, [target, suggestionsFiltered.length, editor]);

    const handleChange = React.useCallback(
        changedValue => {
            onChange && onChange(changedValue);
            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
                const [start] = Range.edges(selection);

                const firstRange = Editor.range(editor, { path: [0, 0], offset: 0 }, start);
                const firstText = firstRange && Editor.string(editor, firstRange);
                const wordBefore = Editor.before(editor, start, {
                    unit: 'word'
                });
                const before = wordBefore && Editor.before(editor, wordBefore);
                const beforeRange = before && Editor.range(editor, before, start);
                const beforeText = beforeRange && Editor.string(editor, beforeRange);

                if (!!beforeText || !!firstText) {
                    if (beforeText) {
                        const newBeforeRange = {
                            ...beforeRange,
                            anchor: {
                                ...beforeRange.anchor,
                                offset: beforeRange.anchor?.offset
                            }
                        };

                        setTarget(newBeforeRange);
                    } else {
                        setTarget(firstRange);
                    }
                    setSearch(beforeText?.trim() || firstText?.trim());
                    setSuggestionIndex(0);
                    return;
                }
            }

            //prevent when click on suggestion
            if (JSON.stringify(value) === JSON.stringify(changedValue)) return;
            setTarget(null);
        },
        [value, editor, onChange]
    );

    React.useEffect(() => {
        if (inputRef && editor) {
            inputRef.current = editor;
        }
    }, [editor, inputRef]);

    const handleBlur = React.useCallback(() => {
        editor.blurSelection = editor.selection;
        setTimeout(() => {
            if (isPreventBlurRef.current) {
                isPreventBlurRef.current = false;
                return;
            }
            onBlur();
        }, 200);
    }, [onBlur, editor]);

    const clickSuggestion = React.useCallback(
        suggestion => () => {
            isPreventBlurRef.current = true;
            insertSuggestion(suggestion);
        },
        [insertSuggestion]
    );

    const autoFocus = React.useMemo(() => {
        return rest?.autoFocus;
    }, [rest]);

    React.useEffect(() => {
        if (editor && !readOnly) {
            Transforms.select(editor, Editor.end(editor, []));
            if (autoFocus) {
                ReactEditor.focus(editor);
            }

            setTimeout(() => {
                let tagEditor = document.getElementById('editable-tag');
                if (tagEditor) {
                    tagEditor.scroll(0, 10000);
                }
            }, 0);
        }
    }, [editor, readOnly, autoFocus]);

    return (
        <div className={classes.root} style={rest.rootStyle}>
            <Slate editor={editor} value={value} onChange={handleChange}>
                <Editable
                    id="editable-tag"
                    renderElement={renderElement}
                    onKeyDown={handleKeyDownChange}
                    onPaste={() => {}}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    decorate={decorate}
                    renderLeaf={renderLeaf}
                    itemRef={inputRef}
                    readOnly={readOnly}
                    {...rest}
                />

                {target && suggestionsFiltered?.length > 0 && (
                    <Portal>
                        <div
                            ref={ref}
                            id="formula-suggestion"
                            style={{
                                top: '-9999px',
                                left: '-9999px',
                                position: 'absolute',
                                zIndex: suggestionZIndex || 1300,
                                background: 'white',
                                borderRadius: '4px',
                                boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                                minWidth: 200
                            }}
                        >
                            {suggestionsFiltered.map((suggestion, i) => (
                                <ListItem
                                    icon={suggestion.icon}
                                    key={i}
                                    isSelected={i === suggestionIndex}
                                    name={suggestion?.label}
                                    onClick={clickSuggestion(suggestion)}
                                    useDOMPurify={false}
                                />
                            ))}
                        </div>
                    </Portal>
                )}
            </Slate>
        </div>
    );
}

const Leaf = ({ attributes, children, leaf, isHighlightSymbolCharacter, enableTag, renderedZeroWidthSpaceLeafRef }) => {
    const theme = useTheme();
    const classes = useStyles();

    if (leaf.tag && !leaf.isExistedInParentTag) {
        return (
            <Tooltip title="Tag not found in source">
                <span
                    {...attributes}
                    style={{
                        background: leaf.tag
                            ? leaf?.isExistedInParentTag
                                ? theme.colors.token
                                : theme.colors.missingTag
                            : ``,
                        borderRadius: leaf.tag ? 2 : ``,
                        fontWeight: leaf.tag ? 500 : ``,
                        color: leaf.tag ? theme.colors.white : ``,
                        // padding: leaf.tag ? `1px 4px` : ``,
                        caretColor: theme.colors.primaryText
                    }}
                >
                    {children}
                </span>
            </Tooltip>
        );
    }

    if (leaf.zeroWidthSpace && isHighlightSymbolCharacter && !renderedZeroWidthSpaceLeafRef.current[leaf.index]) {
        renderedZeroWidthSpaceLeafRef.current[leaf.index] = true;
        return (
            <span {...attributes} className={classes.zeroWidthSpace}>
                {children}
            </span>
        );
    }

    if (leaf.symbol && leaf.text && isHighlightSymbolCharacter) {
        return (
            <span {...attributes} style={{ background: '#f8ad14', borderRadius: 2 }}>
                {children}
            </span>
        );
    }

    if (leaf.nonBreakingSpace && leaf.text && isHighlightSymbolCharacter) {
        return (
            <span {...attributes} className={classes.nonBreakingSpace}>
                {children}
                <NonBreakingSpaceSVG className="single-revert-style" />
            </span>
        );
    }

    if (enableTag) {
        return (
            <span
                {...attributes}
                style={{
                    background: leaf.tag
                        ? leaf?.isExistedInParentTag
                            ? theme.colors.token
                            : theme.colors.missingTag
                        : ``,
                    borderRadius: leaf.tag ? 2 : ``,
                    fontWeight: leaf.tag ? 500 : ``,
                    color: leaf.tag ? theme.colors.white : ``,
                    // padding: leaf.tag ? `1px 4px` : ``,
                    caretColor: theme.colors.primaryText
                }}
            >
                {children}
            </span>
        );
    }
    return <span {...attributes}>{children}</span>;
};

export default TokenEditor;
