import React from 'react';

function AuthyAuthSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 256 256"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
        >
            <path
                d="M256 127.998C256 198.696 198.694 256 127.997 256 57.304 256 0 198.696 0 127.998 0 57.305 57.304 0 127.997 0 198.694 0 256 57.305 256 127.998z"
                fill="#EC1C24"
            />
            <path
                d="M117.024 100.89l30.691 30.68c4.43 4.436 11.623 4.436 16.064 0 4.431-4.435 4.441-11.622 0-16.058L133.086 84.82C111.125 62.854 75.631 62.5 53.204 83.704a9.536 9.536 0 0 0-.605.548c-.098.098-.178.202-.275.29-.1.096-.202.182-.297.278-.194.193-.371.396-.54.597-21.215 22.437-20.85 57.932 1.113 79.897L83.292 196c4.44 4.433 11.623 4.433 16.062 0 4.442-4.437 4.442-11.633.011-16.06l-30.693-30.691c-13.398-13.393-13.488-35.129-.278-48.637 13.506-13.21 35.23-13.114 48.63.279zm39.612-40.873c-4.435 4.437-4.435 11.623.012 16.059l30.681 30.693c13.39 13.392 13.477 35.125.268 48.636-13.51 13.2-35.227 13.112-48.623-.282l-30.692-30.689c-4.438-4.437-11.633-4.437-16.066 0-4.443 4.433-4.443 11.642 0 16.065l30.682 30.694c21.968 21.965 57.458 22.322 79.889 1.116.208-.184.404-.355.61-.549.094-.095.182-.192.273-.29.1-.095.197-.183.29-.289.197-.183.365-.384.54-.594 21.215-22.428 20.86-57.911-1.102-79.889l-30.692-30.682c-4.436-4.443-11.638-4.443-16.07.001z"
                fill="#FFF"
            />
        </svg>
    );
}

export default React.memo(AuthyAuthSVG);
