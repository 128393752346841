import React from 'react';

function ConnectorSideBarSVG({ color = '#93929E', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M11.5232 16.022C9.17995 17.0069 6.46386 16.5328 4.58622 14.8053C6.17232 13.1253 4.99156 10.3281 2.65591 10.3281C1.19143 10.3281 0 11.5196 0 12.984C0 14.7862 1.7688 16.0721 3.48484 15.5067C5.73775 17.7663 9.12148 18.4084 12.0151 17.1921C12.3382 17.0563 12.49 16.6842 12.3542 16.3611C12.2184 16.0379 11.8464 15.8861 11.5232 16.022V16.022ZM1.26929 12.984C1.26929 12.2194 1.89132 11.5974 2.65591 11.5974C3.42046 11.5974 4.04253 12.2194 4.04253 12.984C4.04253 13.7486 3.4205 14.3707 2.65591 14.3707C1.89132 14.3706 1.26929 13.7486 1.26929 12.984Z"
                fill={color}
            />
            <path
                d="M2.06045 8.16395C2.3911 8.28421 2.75443 8.11262 2.87382 7.78451C3.50589 6.04693 4.87597 4.64326 6.58364 3.95895C7.00267 4.87589 7.92786 5.51494 9.00009 5.51494C10.4645 5.51494 11.656 4.32352 11.656 2.85903C11.656 1.39459 10.4646 0.203125 9.00009 0.203125C7.59261 0.203125 6.43795 1.30384 6.35017 2.68954C4.17711 3.47753 2.46531 5.19449 1.68097 7.35057C1.56118 7.67999 1.73107 8.04412 2.06045 8.16395V8.16395ZM9.00009 1.47241C9.76464 1.47241 10.3867 2.09445 10.3867 2.85903C10.3867 3.62362 9.76468 4.24565 9.00009 4.24565C8.23551 4.24565 7.61347 3.62362 7.61347 2.85903C7.61351 2.09445 8.23555 1.47241 9.00009 1.47241V1.47241Z"
                fill={color}
            />
            <path
                d="M16.7509 10.7338C16.7733 10.608 16.7865 10.2028 16.7865 10.0125C16.7865 7.81228 15.849 5.70458 14.2144 4.22982C13.9541 3.99508 13.5528 4.01567 13.318 4.27591C13.0832 4.53616 13.1039 4.93745 13.3641 5.17227C14.7957 6.46381 15.6055 8.35097 15.509 10.3349C13.9663 10.24 12.6885 11.4707 12.6885 12.9852C12.6885 14.4497 13.8799 15.6411 15.3444 15.6411C16.8089 15.6411 18.0003 14.4497 18.0003 12.9852C18.0003 12.037 17.5005 11.2038 16.7509 10.7338V10.7338ZM15.3444 14.3718C14.5799 14.3718 13.9578 13.7498 13.9578 12.9852C13.9578 12.2206 14.5798 11.5986 15.3444 11.5986C16.109 11.5986 16.7311 12.2206 16.7311 12.9852C16.7311 13.7498 16.109 14.3718 15.3444 14.3718V14.3718Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ConnectorSideBarSVG);
