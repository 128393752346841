import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';

function SingleSelection({ previousData, options = [], customProperties = {}, isHideMarkIcon = false }) {
    return (
        <Grid container style={{ height: 'fit-content' }}>
            <Option
                data={previousData}
                state={'delete'}
                options={options}
                isHideMarkIcon={isHideMarkIcon}
                customProperties={customProperties}
            />
        </Grid>
    );
}

export default React.memo(SingleSelection);
