import * as types from '../types';

import { revertHandler } from './optimistic-handler';

const handler = {
    [types.COMMIT_ACTION](state, action) {
        const { actionId, type, body } = action.payload;
        const { optimistics } = state;
        const newOptimistic = {
            actionId,
            type,
            body
        }
        return {
            ...state,
            optimistics: [...optimistics, newOptimistic]
        }
    },

    [types.REMOVE_ACTION](state, action) {
        const { actionId } = action.payload;
        const { optimistics } = state;
        const newOptimistic = optimistics.filter(optimistic => optimistic.actionId !== actionId);
        return {
            ...state,
            optimistics: newOptimistic
        }
    },

    [types.REVERT_ACTION](state, action) {
        const { actionId } = action.payload;
        const { optimistics } = state;
        const found = optimistics.find(optimistic => optimistic.actionId === actionId);
        let newState = { ...state };
        if (found) {
            const { type, body, actionId } = found;
            newState = {
                ...revertHandler({ type, body, state }),
                optimistics: optimistics.filter(optimistic => optimistic.actionId !== actionId)
            }
        }
        return newState;
    }
};

export default handler;
