import { MAP_DATA_COLLECTOR_PROPERTIES_TO_PAYMENT_PROPERTIES } from 'const';

export function mapDataCollectorToMatchPaymentProperties(usage = {}) {
    let obj = {};
    for (let key in usage) {
        obj[MAP_DATA_COLLECTOR_PROPERTIES_TO_PAYMENT_PROPERTIES[key]] = usage[key];
    }

    return obj;
}

export let CELL_PARENT_OFFSET = {
    top: 0,
    left: 0
};

export const setCellParentOffset = newParentOffset => {
    CELL_PARENT_OFFSET = { ...CELL_PARENT_OFFSET, ...newParentOffset };
};

export const between = (x, min, max) => {
    return x >= min && x <= max;
};

export function setTimeoutInTime({ cb, start, timeout }) {
    const end = new Date().getTime();
    const diff = end - start;
    return setTimeout(cb, diff >= timeout ? 0 : timeout - diff);
}
