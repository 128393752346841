import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ADD_ON_STATES } from 'const';
import { beautifyPrice } from 'utils/payment';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        cursor: 'pointer',
        borderRadius: 8,
        padding: theme.spacing(3),
        '&:hover': {
            backgroundColor: theme.colors.paleGrey
        }
    },
    installedTag: {
        padding: '2px 10px',
        background: theme.colors.seaGreen,
        borderRadius: 4,
        fontSize: 13,
        fontWeight: 500,
        flex: 0
    },
    logo: {
        maxWidth: 64,
        display: 'flex'
    },
    dodgerBlue: {
        color: theme.colors.dodgerBlue
    },
    dlFlex: {
        display: 'flex'
    },
    midGrey: {
        color: theme.colors.midGrey
    }
}));

function AddonItem({ addon }) {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    const { title, icon, subtitle, name, provider, subscription, integration, plan } = addon;
    const state = integration?.state;

    const isInstalled = state === ADD_ON_STATES.ACTIVE || state === ADD_ON_STATES.DRAFT;
    const price = subscription?.price || plan?.prices?.[0];

    return (
        <Grid item className={classes.root} container direction="row" wrap="nowrap" id={`addon-item-${name}`}>
            <Grid item className={classes.logo}>
                {icon(64)}
            </Grid>
            <Grid item container direction="column" style={{ marginLeft: 14 }}>
                <Grid item container justify="space-between" alignItems="flex-start" wrap="nowrap">
                    <Grid item>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>
                    {!isExternal &&
                        (isInstalled ? (
                            <Grid
                                item
                                container
                                justify="center"
                                alignItems="center"
                                className={classes.installedTag}
                                wrap="nowrap"
                            >
                                <Grid item className={classes.dlFlex}>
                                    <CheckIconSVG
                                        color={theme.colors.white}
                                        style={{ marginRight: 4 }}
                                        width={16}
                                        height={16}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{ color: theme.colors.white }}>
                                        {t('global_installed')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : price ? (
                            <Grid item>
                                <Typography variant="body1" display="inline" className={classes.dodgerBlue}>
                                    {beautifyPrice({
                                        unitAmount: price?.unitAmount,
                                        currency: price?.currency
                                    })}
                                </Typography>
                                <Typography variant="body2" display="inline" className={classes.midGrey}>
                                    /{provider ? price?.recurringInterval : 'block'}
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item>
                                <Typography variant="body1" display="inline" className={classes.dodgerBlue}>
                                    {t('global_free')}
                                </Typography>
                            </Grid>
                        ))}
                </Grid>
                <Grid item style={{ marginTop: 4 }}>
                    <Typography variant="caption">{subtitle}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(AddonItem);
