import React from 'react';

function NewVersionNotifySVG({ color = '#93929e', ...other }) {
    return (
        <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#notify-clip0)">
                <path
                    d="M24.5351 32.9148C24.9491 32.9148 25.363 32.8585 25.7546 32.7572C29.8607 31.6534 31.5837 25.2109 29.6929 18.0926C28.0258 11.7853 24.0428 7.20117 20.2277 7.20117C19.8137 7.20117 19.3997 7.25749 19.0081 7.35886C14.9021 8.46264 13.1791 14.9051 15.0699 22.0234C16.7481 28.342 20.7311 32.9148 24.5351 32.9148Z"
                    fill="#FFF2D9"
                />
                <path
                    d="M4.43072 19.9963L6.62361 19.3318C7.1047 19.1854 7.59698 19.1741 8.05569 19.2755C7.72005 19.6021 7.42916 19.9851 7.1942 20.4243C6.62361 21.5281 6.46697 23.0374 7.18301 25.2674C7.72005 26.9457 8.96194 27.7791 10.4164 28.1846C10.4276 28.1846 10.4276 28.1846 10.4388 28.1959C10.5059 28.2071 10.5618 28.2296 10.629 28.2409C10.6402 28.2409 10.6625 28.2522 10.6737 28.2522C10.7297 28.2634 10.7856 28.2747 10.8416 28.286C10.5283 28.6351 10.1143 28.9167 9.63323 29.0856L9.96888 30.1556C10.1031 30.5949 9.85699 31.0567 9.42065 31.2031L9.07382 31.3157L10.1031 34.6496C10.2374 35.0889 9.99125 35.5507 9.55491 35.6971L8.65986 35.9787C8.22352 36.1138 7.7648 35.866 7.61936 35.4268L6.04182 30.3021C5.99707 30.1556 5.99707 30.0092 6.03063 29.8741C4.97894 29.6938 4.0727 28.9392 3.74824 27.8467L2.49516 23.6456C2.03645 22.08 2.89794 20.4581 4.43072 19.9963Z"
                    fill="#6286F1"
                />
                <path
                    d="M1.65605 22.2486L2.46161 22.0459C2.34972 22.4626 2.32735 22.9019 2.41685 23.3412L1.95814 23.4538C1.44348 23.5889 1.13021 24.1183 1.26447 24.6364L1.91338 27.2269C2.02527 27.6549 2.40566 27.959 2.842 27.959C2.92032 27.959 2.99864 27.9478 3.07696 27.9252L3.71468 27.7563L3.73706 27.8239C3.86013 28.2293 4.06152 28.5785 4.31885 28.8826L3.37904 29.1191C2.20428 29.4232 1.00714 28.7024 0.716245 27.5198L0.0673308 24.9293C-0.223563 23.7466 0.481292 22.5415 1.65605 22.2486Z"
                    fill="#FBAF17"
                />
                <path
                    d="M19.7592 18.0022C19.7704 18.0022 19.7816 18.0022 19.7928 18.0022C21.4039 18.081 22.7017 19.4326 22.7353 21.0432C22.7577 21.9217 22.4108 22.7101 21.8514 23.2846C19.4236 25.785 16.8727 17.8895 19.7592 18.0022Z"
                    fill="#6286F1"
                />
                <path
                    d="M19.1332 7.35886C19.536 7.25749 19.9387 7.20117 20.3527 7.20117C24.1567 7.20117 28.1397 11.7853 29.8179 18.0926C31.7199 25.2109 29.9857 31.6534 25.8908 32.7572C25.4993 32.8585 25.0853 32.9148 24.6713 32.9148C20.8673 32.9148 16.8843 28.3308 15.2061 22.0234C13.3041 14.9051 15.0383 8.46264 19.1332 7.35886ZM16.2802 21.7418C17.9808 28.1505 22.154 32.5995 25.6 31.6759C29.0459 30.7523 30.4556 24.7941 28.7438 18.3854C27.032 11.9767 22.87 7.5278 19.4241 8.45138C15.9781 9.37495 14.5684 15.3219 16.2802 21.7418Z"
                    fill="#FBA017"
                />
                <path
                    d="M16.6035 22.812C16.5476 22.643 16.5028 22.4741 16.4469 22.3164C16.4916 22.4741 16.5476 22.643 16.6035 22.812Z"
                    fill="#FBAF17"
                />
                <path
                    d="M16.7825 23.3529C16.7265 23.1953 16.6706 23.0376 16.6258 22.8799C16.6706 23.0376 16.7265 23.1953 16.7825 23.3529Z"
                    fill="#FBAF17"
                />
                <path
                    d="M17.3977 24.9066C17.3306 24.7602 17.2746 24.6137 17.2075 24.4561C17.2634 24.6137 17.3306 24.7602 17.3977 24.9066Z"
                    fill="#FBAF17"
                />
                <path
                    d="M16.9727 23.8817C16.9167 23.724 16.8608 23.5776 16.8048 23.4199C16.8608 23.5776 16.9167 23.724 16.9727 23.8817Z"
                    fill="#FBAF17"
                />
                <path
                    d="M19.7026 28.7818C19.6131 28.6691 19.5236 28.5565 19.4341 28.4326C19.5236 28.5565 19.6131 28.6691 19.7026 28.7818Z"
                    fill="#FBAF17"
                />
                <path
                    d="M20.0046 29.1308C19.9039 29.0182 19.8144 28.9056 19.7249 28.793C19.8144 28.9169 19.9039 29.0295 20.0046 29.1308Z"
                    fill="#FBAF17"
                />
                <path
                    d="M17.1741 24.3997C17.1181 24.2533 17.051 24.0956 16.9951 23.9492C17.0622 24.0956 17.1181 24.2533 17.1741 24.3997Z"
                    fill="#FBAF17"
                />
                <path
                    d="M19.1321 28.027C19.0426 27.9031 18.9643 27.7792 18.8748 27.6553C18.9643 27.7904 19.0538 27.9031 19.1321 28.027Z"
                    fill="#FBAF17"
                />
                <path
                    d="M19.4229 28.4097C19.3333 28.2971 19.2438 28.1732 19.1655 28.0605C19.2438 28.1844 19.3333 28.2971 19.4229 28.4097Z"
                    fill="#FBAF17"
                />
                <path
                    d="M10.6401 17.5072C11.5463 17.0679 13.135 15.6826 14.5671 14.3535C14.2874 16.6287 14.4776 19.2868 15.2048 22.035C15.9097 24.693 17.0285 27.047 18.3711 28.8717C17.9571 28.8153 17.6327 28.7815 17.5208 28.7703C16.1782 28.6126 14.8244 28.545 13.4707 28.5338C10.9198 28.5112 8.07796 28.0607 7.1829 25.2675C5.44873 19.9175 8.83875 18.6448 10.6401 17.5072Z"
                    fill="#FBAF17"
                />
                <path
                    d="M17.6216 25.4134C17.5545 25.267 17.4873 25.1206 17.4202 24.9629C17.4873 25.1093 17.5545 25.267 17.6216 25.4134Z"
                    fill="#FBAF17"
                />
                <path
                    d="M18.8635 27.6329C18.7852 27.509 18.6957 27.3739 18.6174 27.25C18.6957 27.3739 18.7852 27.4978 18.8635 27.6329Z"
                    fill="#FBAF17"
                />
                <path
                    d="M18.595 27.216C18.5167 27.0809 18.4271 26.9457 18.3488 26.8105C18.4383 26.9457 18.5167 27.0809 18.595 27.216Z"
                    fill="#FBAF17"
                />
                <path
                    d="M17.6327 25.4473C17.6998 25.6049 17.7781 25.7514 17.8564 25.8978C17.7781 25.7514 17.711 25.5937 17.6327 25.4473Z"
                    fill="#FBAF17"
                />
                <path
                    d="M18.3489 26.7882C18.1811 26.4954 18.0244 26.2025 17.8678 25.8984C18.0132 26.2025 18.1811 26.5066 18.3489 26.7882Z"
                    fill="#FBAF17"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.8823 2.23367C24.0995 1.87901 23.985 1.42272 23.6365 1.20945C21.7738 0.0677907 19.5778 -0.280832 17.4539 0.228511C17.4492 0.229741 17.4444 0.23091 17.4396 0.23208C17.4348 0.233251 17.4301 0.234421 17.4254 0.235651C15.3139 0.750027 13.5255 2.05255 12.3885 3.90691C12.1731 4.25786 12.2833 4.71628 12.6337 4.93138C12.9842 5.1466 13.4431 5.03666 13.6582 4.68618C14.5889 3.16807 16.0528 2.10208 17.7817 1.68243C17.7849 1.68149 17.7882 1.68071 17.7915 1.67992C17.7948 1.67914 17.7981 1.67836 17.8014 1.67742C19.5387 1.2608 21.3343 1.5457 22.8581 2.47952C23.2092 2.69444 23.6676 2.5843 23.8823 2.23367ZM22.3253 4.77577C22.5392 4.42647 22.4322 3.96756 22.0801 3.75126C20.8962 3.02514 19.5001 2.80367 18.1489 3.12737C18.1478 3.12764 18.1467 3.12789 18.1456 3.12815C18.143 3.12873 18.1403 3.12935 18.1378 3.13026C16.7912 3.45617 15.6521 4.28507 14.9285 5.4662C14.7136 5.8173 14.8237 6.27572 15.1742 6.49089C15.5253 6.7058 15.9837 6.59566 16.1986 6.24457C16.7159 5.40076 17.531 4.80857 18.494 4.57708C18.4944 4.57693 18.4948 4.57682 18.4952 4.57671C18.4957 4.5766 18.4961 4.5765 18.4964 4.57635C19.4605 4.34515 20.4562 4.50292 21.3007 5.02102C21.6512 5.2362 22.1101 5.12626 22.3253 4.77577Z"
                fill="#FBAF17"
            />
            <defs>
                <clipPath id="notify-clip0">
                    <rect width="30.5997" height="28.7998" fill="white" transform="matrix(-1 0 0 1 30.5999 7.20117)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(NewVersionNotifySVG);
