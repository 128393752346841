import request from './request';
import { APP_API_PREFIX_TM, APP_API_PREFIX_GRID_UI } from '../config';
import { getDownloadedFile } from 'utils/fileUtils';
import { objectToQueryParams } from 'utils/urlQueryUtils';
import { isEmpty } from 'lodash';
import { getRegionBaseUrl } from 'utils/workspace';

const tmApis = {
    transmems: `${APP_API_PREFIX_TM}/api/v1/transmems`,
    transmem: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}`,
    cleanup: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/cleanup`,
    export: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/export`,
    import: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/import`,
    search: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/search`,
    sources: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/sources`,
    targets: ({ tmId, source }) => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/targets?source=${source}`,
    translations: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/translations`,
    searchTexts: `${APP_API_PREFIX_TM}/api/v1/texts`,
    bulk: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/translations/bulk`,
    push: (dbId, gridId) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/localizations/${gridId}/pushTranslations`,
    pull: (dbId, gridId) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/localizations/${gridId}/pullTranslations`,
    getSegment: ({ tmId, unitId }) => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/units/${unitId}`,
    createSegment: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/units`,
    deleteSegment: tmId => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/units`,
    addAlternative: ({ tmId, unitId }) => `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/units/${unitId}/segments`,
    changeAlternative: ({ tmId, unitId, segmentId, targetLang }) =>
        `${APP_API_PREFIX_TM}/api/v1/transmems/${tmId}/units/${unitId}/segments/${segmentId}?targetLang=${targetLang}`
};

export async function createTMApi({ body }) {
    const response = await request.post(tmApis.transmems, body);
    return response.data;
}

export async function bukTMApi({ tmId, body }) {
    const response = await request.post(tmApis.bulk(tmId), body);
    return response.data;
}

export async function updateTMApi({ body, tmId }) {
    const response = await request.put(tmApis.transmem(tmId), body);
    return response.data;
}

export async function getTMApi({ tmId }) {
    const response = await request.get(tmApis.transmem(tmId));
    return response.data;
}

export async function deleteTMApi(tmId) {
    const response = await request.delete(tmApis.transmem(tmId));
    return response.data;
}

export async function updateTranslationApi({ tmId, body }) {
    const response = await request.post(tmApis.translations(tmId), body);
    return response.data;
}

export async function getTranslationMem({ tmId, sourceLang, targetLangs, limit, offset, search }) {
    let queryParams = {
        search: search
    };
    let url = `${tmApis.translations(tmId)}?lang=${sourceLang}&limit=${limit}&offset=${offset}`;
    if (targetLangs?.length) {
        let _params = targetLangs?.join('&targetLangs=');
        url += `&targetLangs=${_params}`;
    }
    if (search) {
        url += `&${objectToQueryParams(queryParams)}`;
    }
    const response = await request.get(url);
    return response;
}

export async function getSegmentApi({ tmId, unitId, targetLangs }) {
    let url = `${tmApis.getSegment({ tmId, unitId })}`;
    // if (targetLangs?.length) {
    //     let _params = targetLangs?.join('&targetLangs=');
    //     url += `&targetLangs=${_params}`;
    // }
    const response = await request.get(url);
    return response.data;
}

export async function createSegmentApi({ tmId, body }) {
    const response = await request.post(tmApis.createSegment(tmId), body);
    return response.data;
}

export async function deleteSegmentApi({ tmId, body }) {
    const response = await request.delete(tmApis.createSegment(tmId), { data: body });
    return response.data;
}

export async function addAlternativeApi({ tmId, unitId, body }) {
    const response = await request.post(tmApis.addAlternative({ tmId, unitId }), body);
    return response.data;
}

export async function deleteAlternativeApi({ tmId, unitId, segmentId, targetLang }) {
    const response = await request.delete(tmApis.changeAlternative({ tmId, unitId, segmentId, targetLang }));
    return response.data;
}

export async function updateAlternativeApi({ tmId, unitId, segmentId, targetLang, body }) {
    const response = await request.put(tmApis.changeAlternative({ tmId, unitId, segmentId, targetLang }), body);
    return response.data;
}

export async function searchTranslationApi({ tmId, body }) {
    const response = await request.post(`${tmApis.search(tmId)}`, body);
    return response.data;
}

export async function getCurrentCompanyTMs() {
    const response = await request.get(tmApis.transmems);

    return response.data;
}

export async function cleanupCurrentCompanyTM(tmId) {
    const response = await request.put(tmApis.cleanup(tmId));

    return response.data;
}

export async function exportCurrentCompanyTMFileApi({ tmId, format, sourceLang, targets }) {
    const linkUrl = `${getRegionBaseUrl()}${tmApis.export(tmId)}?format=${format}&sourceLang=${sourceLang}&${targets}`;

    getDownloadedFile(linkUrl);

    return false;
}

export async function importCurrentCompanyTMFile(tmId, file) {
    const formData = new FormData();

    formData.append('file', file[0], file[0].name);

    const response = await request.post(tmApis.import(tmId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export async function fetchTMSourcesApi({ tmId }) {
    const response = await request.get(`${tmApis.sources(tmId)}`);
    return response.data;
}

export async function fetchTMTargetApi({ tmId, source }) {
    const response = await request.get(`${tmApis.targets({ tmId, source })}`);
    return response.data;
}

export async function searchTextsApi({ searchText, sourceLang, targetLangs }) {
    let url = `${tmApis.searchTexts}?text=${searchText}&lang=${sourceLang}`;
    if (targetLangs?.length) {
        let params = targetLangs?.join('&targetLangs=');
        url += `&targetLangs=${params}`;
    }
    const response = await request.get(url);
    return response.data;
}

export async function pushTMApi(queryParams) {
    const { dbId, gridId, query, sort, ...rest } = queryParams;
    let url = `${tmApis.push(dbId, gridId)}?${objectToQueryParams(rest, true)}`;

    if (!isEmpty(query)) {
        const object = { query };
        url += `&${objectToQueryParams(object)}`;
    }
    if (!isEmpty(sort)) {
        const object = { sort };
        url += `&${objectToQueryParams(object)}`;
    }

    const response = await request.put(url);
    return response.data;
}

export async function pullTMApi(queryParams) {
    const { dbId, gridId, query, sort, ...rest } = queryParams;
    let url = `${tmApis.pull(dbId, gridId)}?${objectToQueryParams(rest, true)}`;

    if (!isEmpty(query)) {
        const object = { query };
        url += `&${objectToQueryParams(object)}`;
    }
    if (!isEmpty(sort)) {
        const object = { sort };
        url += `&${objectToQueryParams(object)}`;
    }

    const response = await request.put(url);
    return response.data;
}
