import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { INPUT_RADIUS, INPUT_HEIGHT } from 'const/style';
import VisaSVG from 'assets/images/svg/payment/VisaSVG';
import MasterSVG from 'assets/images/svg/payment/MasterSVG';
import AmexSVG from 'assets/images/svg/payment/AmexSVG';

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: INPUT_RADIUS
    },
    heading: {
        background: theme.colors.ghostwhite,
        borderBottom: `1px solid ${theme.colors.border}`,
        padding: theme.spacing(3, 4)
    },
    nestedHeading: {
        width: 400
    },
    title: {
        lineHeight: `${INPUT_HEIGHT}px`
    },
    supportedCards: {
        marginLeft: 'auto'
    },
    buttonWrapper: {
        marginLeft: 'auto'
    },
    content: {
        padding: theme.spacing(4)
    }
}));

function SectionLayout({ title, buttonText, onDoAction, children, isShowButton, isShowSupportedCard }) {
    const classes = useStyles();
    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item container alignItems="center" className={classes.heading} wrap="nowrap">
                <Grid item container alignItems="center" className={classes.nestedHeading}>
                    <Grid item>
                        <Typography variant="h4" className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                    {isShowSupportedCard && (
                        <Grid item className={classes.supportedCards}>
                            <Grid container spacing={2} wrap="nowrap">
                                <Grid item>
                                    <VisaSVG />
                                </Grid>
                                <Grid item>
                                    <MasterSVG />
                                </Grid>
                                <Grid item>
                                    <AmexSVG />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {buttonText && !isShowButton && (
                    <Grid item className={classes.buttonWrapper}>
                        <ButtonBase variant="contained" width={120} onClick={onDoAction}>
                            {buttonText}
                        </ButtonBase>
                    </Grid>
                )}
            </Grid>
            <Grid item container className={classes.content}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(SectionLayout);
