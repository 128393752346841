import React, { useState } from 'react';
import AuthenticationWrapper from './AuthenticationWrapper';
import CreateCompanyForm from './CreateCompanyForm';
import AuthFormWrapper from './common/AuthFormWrapper';
import IdentifyForm from './IdentifyForm';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createCompany, fetchMe, _setCompanyId } from 'auth/actions';
import { checkExistence } from 'auth/actions';
import LoadingPage from './LoadingPage';
import { useTranslation } from 'react-i18next';
import { SUBJECTS } from './SignUp';
import { _invalidateWorkspaces } from 'workspaces/actions';

const steps = {
    CREATE_COMPANY: 'CREATE_COMPANY',
    INDENTIFY: 'INDENTIFY',
    LOADING: 'LOADING'
};

function CreateCompany() {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(steps.CREATE_COMPANY);
    const [formData, setFormData] = useState({
        companyName: '',
        companyDescription: '',
        subjects: SUBJECTS,
        job: {
            label: 'Select your role',
            value: null
        }
    });
    const [isSubmitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const goNextToIdentify = values => {
        setSubmitting(true);
        dispatch(
            checkExistence({
                companyName: values.name,
                errorCallback: () => {
                    setSubmitting(false);
                },
                successCallback: result => {
                    setSubmitting(false);
                    if (result.company) {
                        setErrorMessage(t(`auth_duplicate_company_name_message`));
                        return;
                    }
                    setFormData({ ...formData, companyName: values.name, companyDescription: values.description });
                    setCurrentStep(steps.INDENTIFY);
                }
            })
        );
    };

    const goBackToCreateCompany = () => setCurrentStep(steps.CREATE_COMPANY);

    const handleSubmit = () => {
        setSubmitting(true);
        setCurrentStep(steps.LOADING);
        dispatch(
            createCompany({
                body: {
                    name: formData.companyName,
                    description: formData.companyDescription
                },
                errorCallback: () => {
                    setSubmitting(false);
                },
                successCallback: newCompanyId => {
                    setSubmitting(false);
                    dispatch(_setCompanyId({ companyId: newCompanyId }));
                    dispatch(
                        fetchMe({
                            companyId: newCompanyId,
                            successCallback: () => {
                                dispatch(_invalidateWorkspaces());
                                history.push('/');
                            }
                        })
                    );
                }
            })
        );
    };

    const handleSubjectChange = newSubject => {
        const newSubjects = formData.subjects.map(subject => {
            if (subject.value === newSubject.value) {
                subject.isSelected = !subject.isSelected;
            }
            return subject;
        });
        setFormData({ ...formData, subjects: newSubjects });
    };

    const handleJobChange = newJob => {
        setFormData({ ...formData, job: newJob });
    };

    const handleGoBackToCompanyList = () => {
        history.push('/companies');
    };

    switch (currentStep) {
        case steps.CREATE_COMPANY:
        default:
            return (
                <AuthenticationWrapper>
                    <AuthFormWrapper title={t(`auth_create_company_title`)}>
                        <CreateCompanyForm
                            initFormData={formData}
                            onNext={goNextToIdentify}
                            isSubmitting={isSubmitting}
                            errorMessage={errorMessage}
                            onBack={handleGoBackToCompanyList}
                        />
                    </AuthFormWrapper>
                </AuthenticationWrapper>
            );
        case steps.INDENTIFY:
            return (
                <AuthenticationWrapper>
                    <IdentifyForm
                        initFormData={formData}
                        onBack={goBackToCreateCompany}
                        onSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        onSubjectChange={handleSubjectChange}
                        onJobChange={handleJobChange}
                    />
                </AuthenticationWrapper>
            );
        case steps.LOADING:
            return (
                <AuthenticationWrapper>
                    <LoadingPage />
                </AuthenticationWrapper>
            );
    }
}

export default CreateCompany;
