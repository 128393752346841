import React, { useCallback, useEffect, useState } from 'react';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import InviteCompanySVG from 'assets/images/svg/InviteCompanySVG';
import { useTranslation } from 'react-i18next';
import ButtonBase from 'components/button/Base';
import Dialog from 'components/dialog/Dialog';
import { useHistory, useLocation } from 'react-router-dom';
import InviteMembersDialog from './InviteMembersDialog';
import { useDispatch } from 'react-redux';
import { useCompanyId } from 'hooks/auth';
import * as memberActions from 'permission/actions/member';
// import { HEIGHT_INVITE } from 'workspaces';

const useStyles = makeStyles(theme => ({
    inviteMembersSection: {
        position: 'relative',
        height: 'auto',
        borderTop: `1px solid ${theme.colors.divider}`,
        padding: 12,
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            padding: 10
        },
        background: theme.colors.white,
        zIndex: 1111
    },
    inviteIcon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        display: 'flex',
        alignItems: 'center'
    },
    inviteText: {
        color: '#4D3F8A',
        margin: 'auto',
        textAlign: 'center',
        fontWeight: '500',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    paper: {
        maxWidth: `1100px !important`
    }
}));

const InviteMembers = ({ width = 140, text, isLdManager, disabled, showTextOnly }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const companyId = useCompanyId();
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [dialogOpen, setDialogOpen] = useState(searchParams.get('open') === 'inviting');
    const [paperFullWidth, setPaperFullWidth] = useState(true);

    useEffect(() => {
        dispatch(memberActions.fetchMembers({ companyId }));
    }, [companyId, dispatch]);

    const openDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const closeDialog = useCallback(() => {
        const params = new URLSearchParams(search);

        if (params.get('open') === 'inviting') {
            params.delete('open');
            history.replace(pathname + params.toString());
        }
        setDialogOpen(false);
    }, [history, pathname, search]);

    return (
        <AccessControl bypass={isLdManager} view={roleConst.COMPANY_AUTHORITIES.INVITE_MEMBER}>
            <Grid item>
                {showTextOnly ? (
                    <Grid item tr-dt="Open Invite LD Member">
                        <ButtonBase onClick={openDialog} width={width} variant="contained" disabled={disabled}>
                            + {text || t('dashboard_invite_member_button')}
                        </ButtonBase>
                    </Grid>
                ) : (
                    <Grid
                        container
                        justify="flex-start"
                        alignContent="center"
                        alignItems="center"
                        className={classes.inviteMembersSection}
                        spacing={2}
                    >
                        <Grid item className={classes.inviteIcon}>
                            <InviteCompanySVG />
                        </Grid>
                        <Grid item onClick={openDialog}>
                            <Typography variant="body1" className={classes.inviteText}>
                                {t('dashboard_invite_member_button')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}

                <Dialog
                    maxWidth="lg"
                    PaperProps={{ className: classes.paper }}
                    fullWidth={paperFullWidth}
                    open={dialogOpen}
                >
                    <InviteMembersDialog
                        isLdManager={isLdManager}
                        setPaperFullWidth={setPaperFullWidth}
                        onClose={closeDialog}
                    />
                </Dialog>
            </Grid>
        </AccessControl>
    );
};

export default React.memo(InviteMembers);
