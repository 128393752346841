import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Option from 'components/option/Base';

function RefMultiple({ value: cellValue }) {
    const theme = useTheme();
    if (isEmpty(cellValue)) return null;

    const getJsonValue = cellValue => {
        if (isArray(cellValue)) {
            let values = cellValue.map(i => i.referencedDataItem);
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(cellValue);

    if (!isArray(newData) || !newData) return null;

    return (
        <div className="flex w-full items-center gap-1 pointer-events-none">
            {newData?.map((items = [], indexItems) => {
                if (!(items instanceof Array)) return null;
                return (
                    <div
                        key={indexItems}
                        className={`rounded px-1 py-0.5 bg-option-fill border inline-flex w-fit gap-1 border-option-stroke`}
                    >
                        {items?.map(item => (
                            <Option
                                className={'py-1'}
                                key={item}
                                size="small"
                                label={item}
                                color={theme.colors.dodgerBlue}
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    );
}

export default React.memo(RefMultiple);
