import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Tabs from 'components/tabs/Tabs';
import Tab from 'components/tabs/Tab';
import { useTranslation } from 'react-i18next';
import { HEADER_HEIGHT } from 'const';
import Trigger from './Trigger';
import Action from './Action';
import AuditLog from './AuditLog';

const useStyles = makeStyles(theme => ({
    root: {},
    tabs: {
        padding: `0 24px`
    },
    tab: {
        minWidth: 'unset',
        fontWeight: 500,
        '&:last-child': {
            marginLeft: 24
        }
    }
}));

const TABS_HEIGHT = 52;

const TABS = {
    AUTOMATION: 'AUTOMATION',
    AUDIT_LOG: 'AUDIT_LOG'
};

const AutomationDetailContent = ({ showTabs, selectedNode, setSelectedNode, reUpdateSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [tab, setTab] = useState(TABS.AUTOMATION);

    useEffect(() => {
        reUpdateSelectedNode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const isTrigger = useMemo(() => {
        return !!selectedNode?.trigger;
    }, [selectedNode]);

    const height = useMemo(() => {
        return `calc(100vh - ${HEADER_HEIGHT}px - ${
            document.getElementById('automation-detail-header')?.offsetHeight
        }px - ${showTabs ? TABS_HEIGHT : 0}px - 2px)`;
    }, [showTabs]);

    const handleChangeTab = useCallback((e, value) => {
        setTab(value);
    }, []);

    const renderContent = useCallback(() => {
        if (!selectedNode) return null;
        if (isTrigger) {
            return <Trigger selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
        }
        return <Action selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
    }, [isTrigger, selectedNode, setSelectedNode]);

    return (
        <Grid item container direction="column" wrap="nowrap">
            {showTabs && (
                <Grid item>
                    <Tabs className={classes.tabs} value={tab} onChange={handleChangeTab}>
                        <Tab className={classes.tab} label={t('global_properties')} value={TABS.AUTOMATION} />
                        <Tab className={classes.tab} label={t('automation_run_history')} value={TABS.AUDIT_LOG} />
                    </Tabs>
                </Grid>
            )}
            {tab === TABS.AUTOMATION && (
                <Grid
                    item
                    style={{
                        overflow: 'hidden auto',
                        height
                    }}
                >
                    {renderContent()}
                </Grid>
            )}
            {tab === TABS.AUDIT_LOG && (
                <Grid
                    id="automationAuditLog"
                    item
                    style={{
                        overflow: 'hidden auto',
                        height
                    }}
                >
                    <AuditLog />
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(AutomationDetailContent);
