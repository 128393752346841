import React from 'react';

function CircleCheckSVG({ color = '#A69FC4', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.6654 7.38527V7.99861C14.6645 9.43622 14.199 10.8351 13.3383 11.9865C12.4775 13.1379 11.2676 13.9803 9.88894 14.3879C8.51032 14.7955 7.03687 14.7465 5.68834 14.2483C4.33982 13.7501 3.18847 12.8293 2.406 11.6233C1.62354 10.4173 1.25189 8.99065 1.34648 7.55615C1.44107 6.12165 1.99684 4.75616 2.93088 3.66332C3.86493 2.57049 5.12722 1.80886 6.52949 1.49204C7.93176 1.17521 9.39888 1.32017 10.712 1.90527"
                    stroke="#7869B9"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6667 2.66797L8 9.3413L6 7.3413"
                    stroke="#7869B9"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </svg>
    );
}

export default React.memo(CircleCheckSVG);
