import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ProgressLinear from 'components/notify/Import';
import { useGlobalAction } from 'hooks/advanced';
import { GLOBAL_SEARCH_TYPES } from 'const/gridUI';
import TMSearchNotFoundSVG from 'assets/images/svg/TMSearchNotFoundSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        left: 0,
        right: 0,
        margin: 'auto',
        padding: 14,
        bottom: 53,
        width: 300,
        zIndex: 100000,
        borderRadius: 6,
        boxShadow: theme.shadows[1],
        background: theme.colors.white
    }
}));

function Progressing() {
    const classes = useStyles();
    const globalAction = useGlobalAction();
    const { t } = useTranslation();

    if (!globalAction) return null;

    return (
        <Grid container alignItems="center" direction="row" className={classes.root}>
            <ProgressLinear
                title={t(
                    globalAction === GLOBAL_SEARCH_TYPES.REPLACING
                        ? `global_replacing`
                        : globalAction === GLOBAL_SEARCH_TYPES.FINDING
                        ? `global_finding`
                        : `global_importing`
                )}
                icon={<TMSearchNotFoundSVG width="60" height="40" />}
            />
        </Grid>
    );
}

export default Progressing;
