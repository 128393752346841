import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Base';
import { Grid, makeStyles } from '@material-ui/core';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

const TooltipActionDisable = ({
    disabled,
    placement = 'left',
    children,
    isAction,
    containerClassName,
    disableClassName
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Tooltip
            title={
                disabled
                    ? isAction
                        ? t('automation_tooltip_action_disable')
                        : t('automation_tooltip_trigger_disable')
                    : ''
            }
            placement={placement}
        >
            <Grid item className={classnames(containerClassName)}>
                <Grid
                    item
                    className={classnames(disableClassName, {
                        [classes.disabled]: disabled
                    })}
                >
                    {children}
                </Grid>
            </Grid>
        </Tooltip>
    );
};

export default React.memo(TooltipActionDisable);
