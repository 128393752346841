import React from 'react';

function FullAPIDoc({ size = 16 }) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.69207 2.64837L9.21058 2.51217C9.14861 2.49085 9.08756 2.49755 9.02819 2.53223C8.96864 2.56694 8.92842 2.61905 8.90775 2.68847L6.0118 13.031C5.99114 13.1005 5.99761 13.1659 6.03125 13.2273C6.06488 13.2887 6.11527 13.3301 6.18266 13.3515L6.66401 13.4877C6.72612 13.5092 6.78695 13.5025 6.84648 13.4677C6.90604 13.4329 6.94617 13.3809 6.96681 13.3116L9.86284 2.9689C9.88348 2.89948 9.87707 2.83399 9.84337 2.77251C9.80971 2.71106 9.75937 2.66967 9.69207 2.64837Z"
                fill="#78778B"
                stroke="#78778B"
                strokeWidth="0.3"
            />
            <path
                d="M0.227601 7.81557L3.84562 4.08237C3.89734 4.029 3.95692 4.0022 4.02409 4.0022C4.09148 4.0022 4.15084 4.029 4.20275 4.08237L4.59083 4.48293C4.64255 4.53632 4.66836 4.59769 4.66836 4.6672C4.66836 4.7367 4.64258 4.7981 4.59083 4.85147L1.53955 7.9999L4.59083 11.1485C4.64255 11.2019 4.66836 11.2633 4.66836 11.3327C4.66836 11.4022 4.64258 11.4636 4.59083 11.517L4.20275 11.9174C4.15084 11.9708 4.09145 11.9974 4.02409 11.9974C3.9569 11.9974 3.89734 11.9708 3.84562 11.9174L0.227601 8.1842C0.175851 8.13083 0.14999 8.06926 0.14999 7.99984C0.14999 7.93039 0.175851 7.86894 0.227601 7.81557Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.31028 3.97778C4.23365 3.89901 4.13678 3.8522 4.02409 3.8522C3.91172 3.8522 3.81451 3.89894 3.7379 3.97798L0.119912 7.71115C0.043098 7.79037 -1.04904e-05 7.88853 -1.04904e-05 7.99984C-1.04904e-05 8.11106 0.0430541 8.20935 0.119912 8.28861L3.73788 12.0217C3.8146 12.1009 3.91184 12.1474 4.02409 12.1474C4.13649 12.1474 4.23351 12.101 4.31039 12.0218L4.69852 11.6214C4.77543 11.5421 4.81836 11.4439 4.81836 11.3327C4.81836 11.2215 4.77535 11.1234 4.69855 11.0441L1.74843 7.9999L4.69852 4.95588C4.77539 4.87659 4.81836 4.77845 4.81836 4.66719C4.81836 4.55588 4.77534 4.45782 4.69857 4.37856L4.31028 3.97778ZM4.09513 4.18686C4.06797 4.15898 4.04615 4.1522 4.02409 4.1522C4.00212 4.1522 3.98018 4.15906 3.95333 4.18676L0.335315 7.91996C0.30863 7.94748 0.29999 7.97225 0.29999 7.99984C0.29999 8.02746 0.308648 8.0523 0.335289 8.07978L3.95333 11.813C3.98005 11.8406 4.00195 11.8474 4.02409 11.8474C4.04641 11.8474 4.06817 11.8407 4.09512 11.8129L4.48313 11.4126C4.5097 11.3852 4.51836 11.3605 4.51836 11.3327C4.51836 11.3051 4.50976 11.2804 4.48311 11.2529L1.33067 7.99989L4.48312 4.74707C4.50973 4.71963 4.51836 4.69494 4.51836 4.66719C4.51836 4.63951 4.50977 4.61484 4.4831 4.5873L4.09513 4.18686Z"
                fill="#78778B"
            />
            <path
                d="M15.5908 7.81557L11.9727 4.08237C11.921 4.029 11.8614 4.0022 11.7943 4.0022C11.7269 4.0022 11.6675 4.029 11.6156 4.08237L11.2275 4.48293C11.1758 4.53632 11.15 4.59769 11.15 4.6672C11.15 4.7367 11.1758 4.7981 11.2275 4.85147L14.2788 7.9999L11.2275 11.1485C11.1758 11.2019 11.15 11.2633 11.15 11.3327C11.15 11.4022 11.1758 11.4636 11.2275 11.517L11.6156 11.9174C11.6675 11.9708 11.7269 11.9974 11.7943 11.9974C11.8615 11.9974 11.921 11.9708 11.9727 11.9174L15.5908 8.1842C15.6425 8.13083 15.6684 8.06926 15.6684 7.99984C15.6684 7.93039 15.6425 7.86894 15.5908 7.81557Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5081 3.97778C11.5847 3.89901 11.6816 3.8522 11.7943 3.8522C11.9066 3.8522 12.0039 3.89894 12.0805 3.97798L15.6984 7.71115C15.7753 7.79037 15.8184 7.88853 15.8184 7.99984C15.8184 8.11106 15.7753 8.20935 15.6984 8.28861L12.0805 12.0217C12.0038 12.1009 11.9065 12.1474 11.7943 12.1474C11.6819 12.1474 11.5849 12.101 11.508 12.0218L11.1198 11.6214C11.0429 11.5421 11 11.4439 11 11.3327C11 11.2215 11.043 11.1234 11.1198 11.0441L14.0699 7.9999L11.1198 4.95588C11.043 4.87659 11 4.77845 11 4.66719C11 4.55588 11.043 4.45782 11.1198 4.37856L11.5081 3.97778ZM11.7232 4.18686C11.7504 4.15898 11.7722 4.1522 11.7943 4.1522C11.8162 4.1522 11.8382 4.15906 11.865 4.18676L15.483 7.91996C15.5097 7.94748 15.5184 7.97225 15.5184 7.99984C15.5184 8.02746 15.5097 8.0523 15.4831 8.07978L11.865 11.813C11.8383 11.8406 11.8164 11.8474 11.7943 11.8474C11.7719 11.8474 11.7502 11.8407 11.7232 11.8129L11.3352 11.4126C11.3087 11.3852 11.3 11.3605 11.3 11.3327C11.3 11.3051 11.3086 11.2804 11.3352 11.2529L14.4877 7.99989L11.3352 4.74707C11.3086 4.71963 11.3 4.69494 11.3 4.66719C11.3 4.63951 11.3086 4.61484 11.3353 4.5873L11.7232 4.18686Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(FullAPIDoc);
