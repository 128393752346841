import { useSelector } from 'react-redux';
import { useSelectedRecordIdsByRange, useRows } from './row';
import { useSelectedCellData } from './cellInfo';
import { useRecordHistoryId } from './recordHistory';

export function useIsTurnOnRecordPanel() {
    return useSelector(state => state.gridUI.isTurnOnRecordPanel);
}

export function useSelectedRecordId() {
    const selectedCellData = useSelectedCellData();
    const rowsSelected = useSelectedRecordIdsByRange();
    const recordHistoryId = useRecordHistoryId();

    if (rowsSelected?.length && rowsSelected?.length > 1) return;

    return selectedCellData?.rowId || rowsSelected?.[0] || recordHistoryId;
}

export function useRecordIndex() {
    const rows = useRows();
    const selectedRecordId = useSelectedRecordId();

    return rows?.findIndex(rowId => rowId === selectedRecordId);
}
