import React from 'react';
import { Grid } from '@material-ui/core';
import Option from 'components/option/Base';
import isArray from 'lodash/isArray';
import { getOptionColor } from 'utils/color';
import { ROW_HEIGHT } from 'const/gridUI';
import { isRefImage } from 'utils/gridUI/reference';

function MultiSelectRender({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex, options = [], customProperties }) {
    if (!value || value.length === 0 || !isArray(value)) return null;

    const isRefData = value?.some(
        option => option.hasOwnProperty('referencedDataItem') && option.hasOwnProperty('referencedRecordId')
    );

    const isImageData = isRefImage(value);

    if (isRefData || isImageData) return null;

    return (
        <Grid
            container
            className={`w-full justify-start items-start pointer-events-none`}
            style={{
                height: rowHeight
            }}
        >
            <Grid item className={` cell_${rowIndex}_${columnIndex} flex flex-wrap gap-2`}>
                {value.map((item, index) => {
                    const color = getOptionColor({ options, data: item, customProperties });
                    return <Option key={index} size="small" label={item} color={color} />;
                })}
            </Grid>
        </Grid>
    );
}

export default React.memo(MultiSelectRender);
