import * as types from './types';
import { USER_LOGOUT_SUCCESS } from '../auth/types';
import createReducer from 'utils/createReducer';
import produce from 'immer';
import { RESOURCE_TYPES } from 'const';
import {
    ADD_FAVORITE_GRID,
    FETCH_GRID_FAVORITE,
    FETCH_GRID_FAVORITE_SUCCESS,
    REMOVE_FAVORITE_GRID,
    REMOVE_FAVORITE_GRID_BY_PROJECT_ID,
    UPDATE_NAME_FAVORITE
} from 'grids/types';

const INITIAL_STATE = {
    list: [],
    listFavorite: [],
    isFetchingFavorite: false,
    isFetchingWorkspaces: false,
    isFetchedWorkspaces: false,
    isSettingWorkspace: false,
    isCreatingWorkspace: false,
    isShowLastPopupCreate: null,
    selectedWorkspace: null,
    isFetchingGroupOfWorkspace: false,
    isFetchingMemberOfWorkspace: false,
    isFetchingWorkspaceRoles: false,
    isFetching: false,
    groups: [],
    members: [],
    selectedGroupId: null,
    workspaceRoles: [],
    shareProjects: {
        sharedGroups: [],
        isFetching: false,
        error: ''
    },
    setting: {},
    isFetchingUserSetting: false,
    sortCriteria: {
        workspaceId: {
            dbSortProperties: {
                field: 'ALPHABETICAL',
                direction: 'DESC'
            },
            gridSortPropertiesByDbId: {
                dbId: {
                    field: 'ALPHABETICAL',
                    direction: 'DESC'
                }
            }
        }
    },
    regions: []
};

const commonHandler = {
    [USER_LOGOUT_SUCCESS]() {
        return INITIAL_STATE;
    },
    [types.FETCH_WORKSPACES](state) {
        return {
            ...state,
            isFetchingWorkspaces: true
        };
    },
    [types.FETCH_WORKSPACES_SUCCESS](state, action) {
        const { workspaces } = action.payload;
        return {
            ...state,
            isFetchingWorkspaces: false,
            isFetchedWorkspaces: true,
            list: workspaces
        };
    },
    [FETCH_GRID_FAVORITE](state) {
        return {
            ...state,
            isFetchingFavorite: false
        };
    },
    [FETCH_GRID_FAVORITE_SUCCESS](state, action) {
        const { listFavorite } = action.payload;
        return {
            ...state,
            listFavorite: listFavorite,
            isFetchingFavorite: true
        };
    },
    [UPDATE_NAME_FAVORITE](state, action) {
        const { listFavorite } = state;
        const { gridId, newName } = action.payload;

        const grids = listFavorite?.map(grid => {
            if (grid.gridId === gridId) {
                grid.name = newName;
            }
            return grid;
        });
        return {
            ...state,
            listFavorite: grids
        };
    },
    [REMOVE_FAVORITE_GRID_BY_PROJECT_ID](state, action) {
        const { listFavorite } = state;
        const { projectId } = action.payload;
        const grids = listFavorite?.filter(grid => grid.projectId !== projectId);
        return {
            ...state,
            listFavorite: grids,
            isFetchingFavorite: true
        };
    },
    [REMOVE_FAVORITE_GRID](state, action) {
        const { listFavorite } = state;
        const { gridId } = action.payload;
        const grids = listFavorite?.filter(grid => grid.gridId !== gridId);
        return {
            ...state,
            listFavorite: grids,
            isFetchingFavorite: true
        };
    },
    [ADD_FAVORITE_GRID](state, action) {
        const { listFavorite } = state;
        const { newGrid } = action.payload;

        return {
            ...state,
            listFavorite: [...listFavorite, newGrid],
            isFetchingFavorite: true
        };
    },
    [types.FETCH_WORKSPACES_FAILED](state, action) {
        const { error } = action.payload;
        return {
            ...state,
            isFetchingWorkspaces: false,
            error
        };
    },
    [types.INVALIDATE_WORKSPACES](state, action) {
        return {
            ...state,
            isFetchingFavorite: false,
            isFetchedWorkspaces: false,
            list: []
        };
    },
    [types.FETCH_WORKSPACE_DETAIL_SUCCESS](state, action) {
        const workspace = action.payload;

        return { ...state, selectedWorkspace: workspace };
    },
    [types.CREATE_WORKSPACE](state) {
        return {
            ...state,
            isCreatingWorkspace: true
        };
    },
    [types.CREATE_WORKSPACE_SUCCESS](state, action) {
        const { workspace } = action.payload;
        return {
            ...state,
            list: [...state.list, workspace],
            isCreatingWorkspace: false
        };
    },
    [types.CREATE_WORKSPACE_FAILED](state) {
        return {
            ...state,
            isCreatingWorkspace: false
        };
    },
    [types.DELETE_WORKSPACE](state, action) {
        const { workspaceId } = action.payload;
        const { list } = state;
        const newListWorkspace = list.map(workspace => {
            if (workspace.id === workspaceId) {
                workspace.isDeleted = true;
            }
            return workspace;
        });
        return {
            ...state,
            list: newListWorkspace
        };
    },

    [types.DELETE_WORKSPACE_FAILED](state, action) {
        const { workspaceId } = action.payload;
        const { list } = state;
        const newListWorkspace = list.map(workspace => {
            if (workspace.id === workspaceId) {
                workspace.isDeleted = false;
            }
            return workspace;
        });
        return {
            ...state,
            list: newListWorkspace
        };
    },
    [types.DELETE_WORKSPACE_SUCCESS](state, action) {
        const { workspaceId } = action.payload;
        const { list } = state;
        const newListWorkspace = list.filter(ws => ws.id !== workspaceId);
        return {
            ...state,
            list: newListWorkspace
        };
    },

    [types.UPDATE_WORKSPACE](state, action) {
        const { workspace } = action.payload;
        const { list } = state;
        const newListWorkspace = list?.map(i => {
            if (i.id === workspace?.id) {
                i.name = workspace?.name;
                i.description = workspace?.description;
                i.locales = workspace?.locales;
            }
            return i;
        });
        return {
            ...state,
            list: newListWorkspace
        };
    },
    [types.UPDATE_WORKSPACE_FAILED](state, action) {
        const { workspace } = action.payload;
        const { list } = state;
        const newListWorkspace = list?.map(i => {
            if (i.id === workspace?.id) {
                i.name = workspace?.name;
                i.name = workspace?.description;
                i.locales = workspace?.locales;
            }
            return i;
        });
        return {
            ...state,
            list: newListWorkspace
        };
    },
    [types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_WORKSPACE](state) {
        return {
            ...state,
            isShowLastPopupCreate: null
        };
    },
    [types.TURN_ON_SHOW_LAST_POP_UP_CREATE_WORKSPACE](state, action) {
        const { workspaceId } = action.payload;
        return {
            ...state,
            isShowLastPopupCreate: workspaceId
        };
    },
    [types.CLEAR_WORKSPACE_INFO](state) {
        return {
            ...state,
            selectedWorkspace: null
        };
    },
    [types.CHANGE_GROUP](state, action) {
        const { groupId } = action.payload;
        return {
            ...state,
            selectedGroupId: groupId
        };
    },
    [types.FETCH_WORKSPACE_GROUPS](state) {
        return {
            ...state,
            isFetchingGroupOfWorkspace: true
        };
    },
    [types.FETCH_WORKSPACE_GROUPS_FAILED](state) {
        return {
            ...state,
            isFetchingGroupOfWorkspace: false
        };
    },
    [types.FETCH_WORKSPACE_GROUPS_SUCCESS](state, action) {
        const { groups } = action.payload;
        return {
            ...state,
            groups,
            isFetchingGroupOfWorkspace: false
        };
    },
    [types.FETCH_MEMBER_GROUPS](state) {
        return {
            ...state,
            isFetchingMemberOfWorkspace: true
        };
    },
    [types.FETCH_MEMBER_GROUPS_FAILED](state) {
        return {
            ...state,
            isFetchingMemberOfWorkspace: false
        };
    },
    [types.FETCH_MEMBER_GROUPS_SUCCESS](state, action) {
        const { members } = action.payload;
        return {
            ...state,
            members,
            isFetchingMemberOfWorkspace: false
        };
    },
    [types.FETCH_WORKSPACE_GROUPS_AND_MEMBERS](state) {
        return {
            ...state,
            isFetching: true
        };
    },
    [types.FETCH_WORKSPACE_GROUPS_AND_MEMBERS_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            isFetching: false,
            error
        };
    },
    [types.FETCH_WORKSPACE_GROUPS_AND_MEMBERS_SUCCESS](state, { payload }) {
        const { workspaceGroups, members, workspaceRoles } = payload;
        return {
            ...state,
            isFetching: false,
            groups: workspaceGroups,
            members,
            workspaceRoles
        };
    },
    [types.UNGRANT_GROUP_OUT_OF_WORKSPACE](state, action) {
        const { groupIds } = action.payload;
        const groups = [...state.groups].map(group => {
            if (groupIds?.includes(group.id)) {
                group.isDeleted = true;
            }
            return group;
        });
        return {
            ...state,
            groups
        };
    },
    [types.UNGRANT_GROUP_OUT_OF_WORKSPACE_SUCCESS](state, action) {
        const { groupIds } = action.payload;
        const groups = [...state.groups].filter(group => !groupIds?.includes(group.id));
        return {
            ...state,
            groups
        };
    },
    [types.UNGRANT_GROUP_OUT_OF_WORKSPACE_FAILED](state, action) {
        const { groupIds } = action.payload;
        const groups = [...state.groups].map(group => {
            if (groupIds?.includes(group?.id)) {
                group.isDeleted = false;
            }
            return group;
        });
        return {
            ...state,
            groups
        };
    },
    [types.SET_WORKSPACE_ROLES](state, action) {
        const { workspaceRoles } = action.payload;

        return {
            ...state,
            workspaceRoles,
            isFetchingWorkspaceRoles: false
        };
    },
    [types.CHANGE_USER_WORKSPACE_ROLE](state, action) {
        const { role, userId } = action.payload;
        const newMembers = state.members.map(member => {
            if (member.user.id === userId) {
                return {
                    ...member,
                    role
                };
            }
            return member;
        });
        return {
            ...state,
            members: newMembers
        };
    },
    [types.FETCH_WORKSPACE_ROLES](state, action) {
        return {
            ...state,
            isFetchingWorkspaceRoles: true
        };
    },
    [types.FETCH_WORKSPACE_ROLES_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            isFetchingWorkspaceRoles: false,
            error
        };
    },
    [types.CHANGE_FILTER_GROUP](state, { payload }) {
        const { groupId, members } = payload;
        const { groupMembers } = state;

        const newGroupMembers = produce(groupMembers, draft => {
            draft[groupId] = members;
        });
        return {
            ...state,
            groupMembers: newGroupMembers
        };
    },
    [types.CHANGE_SELECTED_GROUP_ID](state, { payload }) {
        const { groupId } = payload;
        return {
            ...state,
            selectedGroupId: groupId
        };
    },
    [types.FETCH_PROJECT_GROUPS_SUCCESS](state, { payload }) {
        const { groups } = payload;
        return {
            ...state,
            shareProjects: {
                ...state.shareProjects,
                isFetching: false,
                sharedGroups: groups
            }
        };
    },
    [types.FETCH_PROJECT_GROUPS_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            shareProjects: {
                ...state.shareProjects,
                error,
                isFetching: false
            }
        };
    },
    [types.FETCH_PROJECT_GROUPS](state, { payload }) {
        return {
            ...state,
            shareProjects: {
                ...state.shareProjects,
                isFetching: true
            }
        };
    },
    [types.SHARE_PROJECT_SUCCESS](state, { payload }) {
        const { groups } = payload;
        return {
            ...state,
            shareProjects: {
                ...state.shareProjects,
                sharedGroups: [...state.shareProjects.sharedGroups, ...groups]
            }
        };
    },
    [types.UNSHARE_PROJECT_SUCCESS](state, { payload }) {
        const { groupIds } = payload;
        const newSharedGroups = state.shareProjects.sharedGroups.filter(group => !groupIds.includes(group.id));
        return {
            ...state,
            shareProjects: {
                ...state.shareProjects,
                sharedGroups: newSharedGroups
            }
        };
    },

    [types.FETCH_WORKSPACE_USER_SETTINGS](state, { payload }) {
        return {
            ...state,
            isFetchingUserSetting: true
        };
    },

    [types.FETCH_WORKSPACE_USER_SETTINGS_SUCCESS](state, { payload }) {
        const { setting } = payload;
        return {
            ...state,
            setting,
            isFetchingUserSetting: false
        };
    },
    [types.FETCH_WORKSPACE_USER_SETTINGS_FAILED](state, { payload }) {
        return {
            ...state,
            isFetchingUserSetting: false
        };
    },
    [types.SET_USER_SETTING](state, { payload }) {
        const { resourceType, resourceId, value, workspaceId, dbId } = payload;
        const { setting } = state;

        switch (resourceType) {
            case RESOURCE_TYPES.DATABASE: {
                return {
                    ...state,
                    setting: {
                        ...setting,
                        [workspaceId]: {
                            ...setting?.[workspaceId],
                            [resourceId]: {
                                ...setting?.[workspaceId]?.[resourceId],
                                value
                            }
                        }
                    }
                };
            }

            case RESOURCE_TYPES.GRID: {
                return {
                    ...state,
                    setting: {
                        ...setting,
                        [workspaceId]: {
                            ...setting?.[workspaceId],
                            [dbId]: {
                                ...setting?.[workspaceId]?.[dbId],
                                grids: {
                                    ...setting?.[workspaceId]?.[dbId]?.grids,
                                    [resourceId]: {
                                        ...setting?.[workspaceId]?.[dbId]?.[resourceId],
                                        value
                                    }
                                }
                            }
                        }
                    }
                };
            }

            case RESOURCE_TYPES.VIEW: {
                return {
                    ...state,
                    setting: {
                        ...setting,
                        [workspaceId]: {
                            ...setting?.[workspaceId],
                            [dbId]: {
                                ...setting?.[workspaceId]?.[dbId],
                                views: {
                                    ...setting?.[workspaceId]?.[dbId]?.grids,
                                    [resourceId]: {
                                        ...setting?.[workspaceId]?.[dbId]?.[resourceId],
                                        value
                                    }
                                }
                            }
                        }
                    }
                };
            }

            default:
                return {
                    ...state,
                    setting
                };
        }
    },
    [types.SET_SORT_CRITERIA](state, { payload }) {
        const { workspaceId, data } = payload;
        return {
            ...state,
            sortCriteria: {
                ...state.sortCriteria,
                [workspaceId]: data
            }
        };
    },
    [types.UPDATE_SORT_CRITERIA](state, { payload }) {
        const { workspaceId, dbId, data } = payload;
        if (dbId) {
            return {
                ...state,
                sortCriteria: {
                    ...state.sortCriteria,
                    [workspaceId]: {
                        ...state.sortCriteria[workspaceId],
                        gridSortPropertiesByDbId: {
                            ...state.sortCriteria[workspaceId].gridSortPropertiesByDbId,
                            [dbId]: {
                                ...state.sortCriteria[workspaceId].gridSortPropertiesByDbId[dbId],
                                ...data
                            }
                        }
                    }
                }
            };
        }
        return {
            ...state,
            sortCriteria: {
                ...state.sortCriteria,
                [workspaceId]: {
                    ...state.sortCriteria[workspaceId],
                    dbSortProperties: {
                        ...state.sortCriteria[workspaceId].dbSortProperties,
                        ...data
                    }
                }
            }
        };
    },
    [types.FETCH_REGIONS_SUCCESS](state, { payload }) {
        const { regions } = payload;
        return {
            ...state,
            regions
        };
    }
};

export default createReducer(INITIAL_STATE, Object.assign(commonHandler));
