import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.colors.divider}`,
        borderRadius: 4
    },

    field: {
        height: 64
    },
    header: {
        background: theme.colors.ghostwhite,
        borderBottom: `1px solid ${theme.colors.divider}`,
        padding: '10px 20px'
    },
    divider: {
        height: 36,
        margin: 'auto 0',
        width: 1,
        background: theme.colors.divider
    }
}));

function SourceItem({ languageCode, totalRecords, totalWords }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const languageName = React.useMemo(() => languageCode, [languageCode]);

    return (
        <Grid container direction="direction" className={classes.root}>
            <Grid item container className={classes.header} alignItems="center">
                <Grid item>
                    <CountryFlag languageCountryCode={languageCode} />
                </Grid>
                <Grid item style={{ marginLeft: 8 }}>
                    <p className="body1">{languageName}</p>
                </Grid>
            </Grid>
            <Grid item container wrap="nowrap">
                <Grid item xs={6}>
                    <Grid container direction="column" justify="center" alignItems="center" className={classes.field}>
                        <Grid item>
                            <p className="caption">{t('total_records')}</p>
                        </Grid>
                        <Grid item>
                            <p className="body1">{totalRecords}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.divider}></Grid>
                <Grid item xs={6}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={`${classes.field}`}
                    >
                        <Grid item>
                            <p className="caption">{t('total_words')}</p>
                        </Grid>
                        <Grid item>
                            <p className="body1">{totalWords}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    );
}

export default React.memo(SourceItem);
