import React from 'react';

function TMStatusAprrovedSVG({ color = '#7869B9', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4088 12.1436V12.0442C16.4088 11.6464 16.8066 11.3481 17.105 11.4475C17.6022 11.4475 17.9006 11.7459 17.9006 12.1436V12.2431C17.9006 14.0331 16.4088 15.5249 14.6188 15.5249H3.28177C1.49171 15.5249 0 14.0331 0 12.2431V5.28177C0 3.49171 1.49171 2 3.28177 2H14.6188C16.5083 2 17.9006 3.49171 18 5.28177V7.96685C18 8.36464 17.6022 8.66298 17.2044 8.66298C16.7072 8.66298 16.4088 8.36464 16.4088 7.96685V5.18232C16.4088 4.18785 15.6133 3.39227 14.6188 3.39227H3.28177C2.28729 3.39227 1.49171 4.18785 1.49171 5.18232V12.1436C1.49171 13.1381 2.28729 13.9337 3.28177 13.9337H14.6188C15.6133 13.9337 16.4088 13.1381 16.4088 12.1436ZM6.11641 6.92491H7.81399V6H3.2998V6.92491H4.97455V11.5419H6.11641V6.92491ZM11.3842 10.0194L9.9607 6H8.46866V11.5419H9.61053V10.027L9.49634 7.41592L10.9884 11.5419H11.7725L13.2683 7.41211L13.1541 10.027V11.5419H14.2998V6H12.8002L11.3842 10.0194Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TMStatusAprrovedSVG);
