import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { CircularProgress, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import ColorLinearProgress from 'components/progress/ColorLinearProgress';
import { previewBulkInvitationApi } from 'services/company';
import { useCompanyId } from 'hooks/auth';
import gridly_bulk_members_invite_template from 'assets/files/gridly_bulk_members_invite_template.csv';
import ExportSVG from 'assets/images/svg/ExportSVG';
import { getUploadErrorMessage } from 'utils/upload';
import ImportLocalizationFileSVG from 'assets/images/svg/localization/ImportLocalizationFileSVG';
import UploadZone from 'components/upload/Base';
import BulkInviteTable from '../multiInvitation/BulkInviteTable';
import UploadingErrorSVG from 'assets/images/svg/UploadingErrorSVG';

const useStyles = makeStyles(theme => ({
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative',
        fontWeight: 'bold'
    },
    box: {
        flex: 1,
        background: theme.colors.ghostwhite,
        border: `1px dashed ${theme.colors.lightGrey}`,
        outline: 'none',
        marginTop: 24
    },
    description: {
        marginBottom: theme.spacing(3)
    },
    reject: {
        background: hexToRgba(theme.colors.brightRed, 0.5)
    },
    progressBar: {
        marginTop: theme.spacing(3),
        minWidth: 276
    },
    marginTop: {
        marginTop: theme.spacing(3)
    },
    dialogAction: {
        border: 0
    },
    uploadText: {
        display: 'inline-block',
        padding: 0,
        textDecoration: 'underline',
        color: theme.colors.highlight
    },
    contentRoot: {
        minHeight: 550,
        maxHeight: 700
    },
    dialogContent: {
        paddingBottom: 0
    },
    table: {
        marginTop: 32,
        flex: 1
    }
}));
const BulkInviteMember = ({
    selectedBulkInviteEmails,
    setSelectedBulkInviteEmails,
    isLoading,
    onClose,
    handleSubmit
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isImporting, setIsImporting] = useState(false);
    const companyId = useCompanyId();

    const invalidEmails = useMemo(() => {
        return selectedBulkInviteEmails?.filter(email => !!email?.validation?.invalid);
    }, [selectedBulkInviteEmails]);

    const onDropRejected = useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, ['.csv', '.CSV']);
            const fileError = fileErrors?.[0];

            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const onDropAccepted = useCallback(
        async acceptedFiles => {
            setIsImporting(true);
            let formData = new FormData();
            acceptedFiles.forEach(file => {
                formData.append('file', file);
            });
            formData.append('separator', ',');
            try {
                const userPreviews = await previewBulkInvitationApi({ formData, companyId });
                setIsImporting(false);
                if (userPreviews?.length > 100) {
                    return dispatch(
                        enqueueSnackbar({
                            message: 'The file contains more than 100 rows, please split them into several CSV files',
                            type: 'info'
                        })
                    );
                }
                setSelectedBulkInviteEmails(userPreviews);
            } catch (error) {
                setIsImporting(false);
                dispatch(
                    enqueueSnackbar({
                        message: error.message,
                        type: 'info'
                    })
                );
            }
        },
        [companyId, setSelectedBulkInviteEmails, dispatch]
    );

    if (!selectedBulkInviteEmails?.length) {
        return (
            <>
                <DialogTitle title={t('invite_member_multi')} onClose={onClose} />
                <DialogContent className={classes.dialogContent}>
                    <Grid className={classes.contentRoot} container direction="column" wrap="nowrap">
                        <Grid item>
                            <Typography variant="h6">{t('invite_member_multi_instruction')}</Typography>
                        </Grid>
                        <Grid item container alignItems="center" className={classes.marginTop}>
                            <Grid item className={classes.numberStep}>
                                1
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{t('invite_member_multi_step1')}</Typography>
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                                <a
                                    className="btn btn-contained w-[120px]"
                                    download="Gridly Bulk Members Invite Template.csv"
                                    href={gridly_bulk_members_invite_template}
                                >
                                    <ExportSVG color={theme.colors.white} style={{ marginRight: 8 }} />
                                    {t('global_download')}
                                </a>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems="center" className={classes.marginTop}>
                            <Grid item className={classes.numberStep}>
                                2
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{t('invite_member_multi_step2')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems="center" className={classes.marginTop}>
                            <Grid item className={classes.numberStep}>
                                3
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{t('invite_member_multi_step3')}</Typography>
                            </Grid>
                        </Grid>
                        <UploadZone
                            className={classes.box}
                            accept={['.csv', '.CSV']}
                            disabled={isImporting}
                            onDropRejected={onDropRejected}
                            onDropAccepted={onDropAccepted}
                        >
                            <Grid item container direction="column" alignItems="center" justify="center">
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justify="center"
                                    direction="column"
                                    spacing={3}
                                >
                                    <Grid item>
                                        <ImportLocalizationFileSVG />
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="caption" component="p">
                                            <Trans
                                                t={t}
                                                i18nKey="invite_member_drag_and_drop_preview_message"
                                                components={{ '<br />': <br /> }}
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {isImporting && (
                                    <Grid item className={classes.progressBar}>
                                        <ColorLinearProgress />
                                    </Grid>
                                )}
                            </Grid>
                        </UploadZone>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                    <Grid item container spacing={2} alignItems="center">
                        <Grid item style={{ flex: 1 }}></Grid>
                        <Grid item>
                            <ButtonBase variant="outlined" width={120} onClick={onClose}>
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <DialogTitle title={t('invite_member_multi')} onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <Grid className={classes.contentRoot} container direction="column" wrap="nowrap">
                    <Grid item>
                        <Typography variant="body2">
                            <Trans
                                t={t}
                                i18nKey="invite_member_multi_preview_reupload"
                                components={{
                                    upload: (
                                        <UploadZone
                                            className={classes.uploadText}
                                            hideBorder
                                            accept={['.csv', '.CSV']}
                                            disabled={isImporting}
                                            onDropRejected={onDropRejected}
                                            onDropAccepted={onDropAccepted}
                                        />
                                    )
                                }}
                            />
                        </Typography>
                    </Grid>
                    <BulkInviteTable className={classes.table} noStyle members={selectedBulkInviteEmails} />
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Grid item container spacing={2} alignItems="center">
                    <Grid item style={{ flex: 1 }}>
                        {invalidEmails?.length > 0 && (
                            <Grid container wrap="nowrap" spacing={2}>
                                <Grid item style={{ display: 'flex' }}>
                                    <UploadingErrorSVG />
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        {t('invite_member_multi_preview_error', { count: invalidEmails?.length })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <ButtonBase variant="outlined" width={120} onClick={onClose}>
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase
                            disabled={
                                isLoading ||
                                !selectedBulkInviteEmails?.length ||
                                invalidEmails?.length === selectedBulkInviteEmails?.length
                            }
                            variant="contained"
                            width={120}
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {isLoading ? <CircularProgress size={24} /> : t('global_send_invite')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
};

export default React.memo(BulkInviteMember);
