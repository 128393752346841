import React from 'react';

function VisaSVG({ ...other }) {
    return (
        <svg {...other} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3498 13.7412L14.3423 26.2338H17.5511L19.5573 13.7412C19.5573 13.7412 16.3498 13.7412 16.3498 13.7412ZM26.0286 18.83C24.9073 18.2762 24.2199 17.9025 24.2199 17.3362C24.2336 16.8212 24.8012 16.2937 26.0686 16.2937C27.1111 16.2675 27.8774 16.5125 28.4587 16.7563L28.7498 16.8862L29.1861 14.2725C28.5523 14.0275 27.5474 13.7575 26.3061 13.7575C23.1374 13.7575 20.9061 15.405 20.8923 17.7613C20.8661 19.5001 22.4897 20.465 23.7048 21.0438C24.9461 21.6376 25.3686 22.0226 25.3686 22.5501C25.3549 23.3601 24.3649 23.7338 23.4412 23.7338C22.1612 23.7338 21.4737 23.5413 20.43 23.09L20.0074 22.8975L19.5587 25.6138C20.3125 25.9489 21.6987 26.2438 23.1375 26.2589C26.505 26.2589 28.6962 24.6363 28.7238 22.1251C28.7348 20.7476 27.8786 19.6925 26.0286 18.83ZM37.4123 13.7799H34.9298C34.1648 13.7799 33.5835 13.9999 33.2523 14.7849L28.4859 26.2338H31.8535L32.7809 23.7551H36.5485L37.0297 26.2438H39.9998L37.4123 13.7799ZM33.7148 21.2536C33.7798 21.2599 35.0074 17.2073 35.0074 17.2073L35.9849 21.2536C35.9849 21.2536 34.3486 21.2536 33.7148 21.2536ZM11.6611 13.7412L8.51733 22.2288L8.17486 20.5549C7.59357 18.6236 5.77114 16.525 3.7373 15.4825L6.61607 26.2213H10.0111L15.0562 13.7426H11.6611V13.7412Z"
                fill="#275795"
            />
            <path
                d="M7.11753 15.3548C6.87254 14.4022 6.09122 13.7598 5.03009 13.7461H0.0512743L0 13.9772C3.88382 14.9347 7.14372 17.8809 8.20128 20.6522L7.11753 15.3548Z"
                fill="#F19E39"
            />
        </svg>
    );
}

export default React.memo(VisaSVG);
