import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import { useIsFetchingPathTagTree, usePathTagTree } from 'hooks/gridUI/pathTag';
import { useNodeIdShowPopup } from 'hooks/gridUI';
import NoPathTagSVG from 'assets/images/svg/NoPathTagSVG';
import { useTranslation, Trans } from 'react-i18next';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { useParams } from 'react-router-dom';
import { useGridTypeDetail } from 'hooks/grid';
import { GRID_UI_TYPE, ROW_HEIGHT, GLOBAL_FILTER_HEIGHT } from 'const/gridUI';
import SpeedTree from './SpeedTree';
import { HEADER_HEIGHT } from 'const';
import { useIsUserBlockedGridByPayment } from 'hooks/payment';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT + ROW_HEIGHT + 17}px)`
    },
    noPathTag: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        width: 300
    },
    spb3: {
        marginBottom: theme.spacing(3)
    },
    centerText: {
        textAlign: 'center'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function GroupTagTree({ isShareViewLink, openedNodeIds, setOpenedNodeIds, height, width }) {
    const classes = useStyles();
    const treeItems = usePathTagTree();
    const nodeIdShowPopup = useNodeIdShowPopup();
    const { t } = useTranslation();
    const role = useRole();
    const { gridId, dbId } = useParams();
    const gridTypeDetail = useGridTypeDetail({ dbId, gridId });
    const isUserBlockedGridByPayment = useIsUserBlockedGridByPayment();
    const isFetchingPathTagTree = useIsFetchingPathTagTree();

    const isCanEditPathTag = React.useMemo(() => {
        return (
            role?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD] === roleConst.FULL && !isUserBlockedGridByPayment
        );
    }, [role, isUserBlockedGridByPayment]);

    const isJsonOrPoGrid = React.useMemo(() => {
        return gridTypeDetail === GRID_UI_TYPE.JSON || gridTypeDetail === GRID_UI_TYPE.PO;
    }, [gridTypeDetail]);

    if (isFetchingPathTagTree && !treeItems.length) {
        return (
            <Grid className={classes.root}>
                <CircularProgress size={24} className={classes.buttonProgress} />
            </Grid>
        );
    }

    if (!treeItems?.length)
        return (
            <Grid direction="column" justify="center" container className={classes.noPathTag}>
                <Grid item className={`mb-3.5 text-center flex items-center justify-center`}>
                    <div>
                        <NoPathTagSVG />
                    </div>
                </Grid>
                <Grid item className={`mb-3.5 text-center`}>
                    <h3 className="font-normal prose prose-2xl">{t(`path_tag_no_path_title`)}</h3>
                </Grid>
                <Grid item className="text-center">
                    <p className="caption">
                        <Trans i18nKey="path_tag_no_path_subtitle" t={t}>
                            line1
                            <br />
                            line2
                            <br />
                            <div className="mt-2" />
                            line3
                        </Trans>
                    </p>
                </Grid>
            </Grid>
        );

    return (
        <Grid className={classes.root}>
            <SpeedTree
                height={height}
                width={width}
                t={t}
                data={treeItems}
                nodeIdShowPopup={nodeIdShowPopup}
                isShareViewLink={isShareViewLink}
                isCanEditPathTag={isCanEditPathTag}
                isJsonOrPoGrid={isJsonOrPoGrid}
                openedNodeIds={openedNodeIds}
                setOpenedNodeIds={setOpenedNodeIds}
            />
        </Grid>
    );
}

export default React.memo(GroupTagTree);
