import { enqueueSnackbar } from 'notifier/actions';
import {
    runQAApi,
    getAutoQASettingsApi,
    createAutoQASettingApi,
    updateAutoQASettingApi,
    deleteAutoQASettingApi
} from 'services/autoQA';
import * as types from '../types';
import * as statusActions from './status';
import uuidv1 from 'uuid/v1';
import { getViewRecordsApiV2 } from 'services/view';
import * as dataActions from './data';
import * as rowActions from './row';

export function showQAErrors() {
    return {
        type: types.SHOW_AUTO_QA_ERRORS
    };
}

export function hideQAErrors() {
    return {
        type: types.HIDE_AUTO_QA_ERRORS
    };
}

export function openErrorControlPanel() {
    return {
        type: types.OPEN_ERROR_CONTROL_PANEL
    };
}

export function closeErrorControlPanel() {
    return {
        type: types.CLOSE_ERROR_CONTROL_PANEL
    };
}

export function toggleErrorType(errorType) {
    return {
        type: types.TOGGLE_ERROR_TYPE,
        payload: errorType
    };
}

export function toggleAllErrorType(currentState) {
    return {
        type: types.TOGGLE_ALL_ERROR_TYPE,
        payload: currentState
    };
}

export function setIsRunningAutoQA(boolean) {
    return {
        type: types.SET_IS_RUNNING_AUTO_QA,
        payload: boolean
    };
}

export function runAutoQA({ viewId, sourceColumnId, targetColumnIds, settingId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            dispatch(setIsRunningAutoQA(true));
            const body = { sourceColumnId, targetColumnIds, settingId };
            await runQAApi({ viewId, body });

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateRecordsAutoQASocket() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();

        try {
            const { data, recordMetaData } = await getViewRecordsApiV2({ ...gridUI, isShowAutoQA: true });
            dispatch(dataActions.updateData({ newData: data }));
            dispatch(rowActions.updateRecordMetaData({ newRecordMetaData: recordMetaData }));
            dispatch(setIsRunningAutoQA(false));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _fetchRecordMetadata({ dbId, defaultAccessViewId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        try {
            const { data, recordMetaData } = await getViewRecordsApiV2({
                ...gridUI,
                dbId,
                defaultAccessViewId,
                isShowAutoQA: true
            });
            dispatch(statusActions.registerDoingAction({ actionId }));
            dispatch(dataActions.updateData({ newData: data }));
            dispatch(rowActions.updateRecordMetaData({ newRecordMetaData: recordMetaData }));
            dispatch(showQAErrors());
            dispatch(openErrorControlPanel());
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            dispatch(statusActions.removeDoingAction({ actionId }));
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _getAutoQASettingsActionSuccess({ autoQASettings }) {
    return {
        type: types.GET_AUTOQA_SETTINGS_SUCCESS,
        payload: { autoQASettings }
    };
}

export function getAutoQASettings({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const autoQASettings = await getAutoQASettingsApi();
            dispatch(_getAutoQASettingsActionSuccess({ autoQASettings }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _createAutoQASettingActionSuccess({ autoQASetting }) {
    return {
        type: types.CREATE_AUTOQA_SETTING_SUCCESS,
        payload: { autoQASetting }
    };
}

export function createAutoQASetting({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const autoQASetting = await createAutoQASettingApi(body);
            dispatch(_createAutoQASettingActionSuccess({ autoQASetting }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _updateAutoQASettingActionSuccess({ autoQASetting }) {
    return {
        type: types.UPDATE_AUTOQA_SETTING_SUCCESS,
        payload: { autoQASetting }
    };
}

export function updateAutoQASetting({ settingId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const autoQASetting = await updateAutoQASettingApi({ settingId, body });
            dispatch(_updateAutoQASettingActionSuccess({ autoQASetting }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _deleteAutoQASettingActionSuccess({ settingId }) {
    return {
        type: types.DELETE_AUTOQA_SETTING_SUCCESS,
        payload: { settingId }
    };
}

export function deleteAutoQASetting({ settingId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            await deleteAutoQASettingApi(settingId);
            dispatch(_deleteAutoQASettingActionSuccess({ settingId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function openIgnoreSimilarErrorsConfirm(selectedValidation) {
    return {
        type: types.OPEN_IGNORE_SIMILAR_ERRORS_CONFIRM,
        payload: { selectedValidation }
    };
}

export function closeIgnoreSimilarErrorsConfirm() {
    return {
        type: types.CLOSE_IGNORE_SIMILAR_ERRORS_CONFIRM
    };
}
