import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Search from 'components/search';
import GroupMemberTable from './GroupMemberTable';
import { useMembersInSelectedGroup, useIsFetchingMembersInSelectedGroup } from 'hooks/permission/group';
import MemberListTableSkeleton from './MemberListTableSkeleton';
import isEmpty from 'lodash/isEmpty';
import EmptyMember from './EmptyMember';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    filters: {
        marginBottom: 24
    },
    warningBox: {
        marginTop: 110
    }
}));

function GroupMember() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const groupMembers = useMembersInSelectedGroup();
    const isFetchingMembers = useIsFetchingMembersInSelectedGroup();

    const [filterValue, setFilterValue] = useState('');

    if (isFetchingMembers && !groupMembers) return <MemberListTableSkeleton />;

    if (isEmpty(groupMembers)) return <EmptyMember className={classes.warningBox} />;

    return (
        <Grid container direction="column">
            <Grid item>
                <Grid container justify="flex-end" className={classes.filters}>
                    <Grid item>
                        <Search
                            onChange={e => setFilterValue(e.target.value)}
                            background={theme.colors.paleGrey}
                            placeholder={t('find_group_member_placeholder')}
                            width={240}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <GroupMemberTable
                    members={groupMembers
                        ?.filter(member => !member.isDeleted)
                        ?.filter(member => {
                            return member?.email?.toLowerCase()?.includes(filterValue?.toLowerCase());
                        })}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(GroupMember);
