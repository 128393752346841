import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { makeStyles } from '@material-ui/core/styles';
import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SPACING_LIST_NAME_WITH_ICON,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    POPUP_PADDING_TOP_BOTTOM
} from 'const/style';
import Divider from 'components/divider/Base';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import ListItem from 'components/list/Item';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import trim from 'lodash/trim';
import { useTranslation } from 'react-i18next';
import EditSVG from 'assets/images/svg/EditSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260
    },
    divider: {
        marginTop: 8,
        marginBottom: 8
    },
    delete: {
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            fontSize: 20,
            color: theme.colors.midGrey,
            marginRight: SPACING_LIST_NAME_WITH_ICON,
            position: 'relative',
            top: 2
        }
    },
    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        marginBottom: 10
    },
    input: {
        backgroundColor: theme.colors.paleGrey,
        borderRadius: 4,
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                boxShadow: 'none'
            }
        }
    }
}));

function SettingPopup({ setting, handleClickAway, onDeleteSetting, onClickEdit, onChangeName }) {
    const [settingName, setSettingName] = useState(setting.name);
    const classes = useStyles();
    const { t } = useTranslation();

    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    const clickAwayHandler = () => {
        if (trim(settingName) === '') {
            handleClickAway();
            return;
        }
        if (settingName === setting.name) {
            handleClickAway();
            return;
        }
        onChangeName(settingName);
        handleClickAway();
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };

    return (
        <Grid ref={rootRef} container direction="column" className={classes.root}>
            <Grid item className={classes.name}>
                <InputText
                    name="settingName"
                    value={settingName}
                    onChange={e => setSettingName(e.target.value)}
                    placeholder="Setting name"
                    onKeyDown={handleKeyDown}
                    className={classes.input}
                    autoSelect={true}
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            <Grid item onClick={onClickEdit}>
                <ListItem icon={<EditSVG />} name={t('global_edit')} />
            </Grid>
            <Grid item className={classes.divider}>
                <Divider />
            </Grid>
            <Grid item className={`${classes.delete} `} onClick={onDeleteSetting}>
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <DeleteSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{t('global_delete')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(SettingPopup);
