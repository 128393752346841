import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import CommentsSVG from 'assets/images/svg/CommentsSVG';
import Avatar from 'components/avatar/User';
import { getAvatarUrl } from 'utils/images';
import ThreeStateCheckBox from 'components/checkbox/ThreeStateCheckBox';
import { getPriorityIcon } from 'utils/gridUI/lqa';
import { checkIsTicketOpen, useCellDataAndColumnInfo } from 'hooks/gridUI';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import { LQA_TICKET_STATUS } from 'const/gridUI';
import { useDispatch } from 'react-redux';
import {
    increaseDecreaseLqaTotal,
    setLqaPageNumber,
    setLqaTicket,
    setLQATicketDetail,
    setOpenLQATicketDetail,
    updateLQATicket,
    updateLqaTicketStatus
} from 'gridUI/actions';
import Tooltip from 'components/tooltip/Base';
import { UpperCaseFirstCharacter } from 'utils/name';
// import { getFriendlyTime } from 'utils/datetime';
import { useCurrentUserFullInfo } from 'hooks/auth';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import MenuOptionBase from 'components/menuOption/Base';
// import ShareSVG from 'assets/images/svg/ShareSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import * as gridActions from 'gridUI/actions';
import { POPUP_PADDING_TOP_BOTTOM, POPUP_RADIUS } from 'const/style';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    dpFlex: {
        display: 'flex'
    },
    checkbox: {
        padding: 0
    },
    commentSVG: {
        width: 12,
        height: 12
    },
    summary: {
        flex: 1
    },
    summaryContent: {
        ...theme.ellipsis(2)
    },
    iconMore: {
        position: 'relative',
        top: '-7px',
        right: '-17px'
    },
    deleteTicket: {
        '& .textContent': {
            color: theme.colors.burntSlena
        }
    },
    menuOptions: {
        position: 'absolute',
        zIndex: 10,
        top: 40,
        right: 0,
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    },
    relative: {
        position: 'relative'
    },
    checkIcon: {
        position: 'absolute',
        left: 6,
        top: 10,
        height: 8
    },
    ldPortal: {
        width: 220,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

function TicketItem({
    ticket,
    onChangeStatus = () => {},
    onChangeAssignee = () => {},
    membersOption,
    lqaTab,
    lqaTicket,
    isReadOnly,
    isRightSideBar,
    onClose,
    ...rest
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { id, summary, priority, recordId, columnId, commentCount = 0, audit = {}, status, assignee = {} } = ticket;
    const {
        createdBy = {}
        // createdTime
    } = audit;
    const { name } = assignee;
    const { column } = useCellDataAndColumnInfo({ rowId: recordId, columnId: columnId });
    const userInfo = useCurrentUserFullInfo();

    const isTicketOpened = React.useMemo(() => {
        return checkIsTicketOpen(status);
    }, [status]);

    const checkboxState = React.useMemo(() => {
        switch (status) {
            case LQA_TICKET_STATUS.RESOLVED:
                return 1;
            default:
                return 2;
        }
    }, [status]);

    const changeStatusHandler = React.useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();
            const oldStatus = ticket.status;
            const oldLqaTicket = JSON.parse(JSON.stringify(lqaTicket));
            const newStatus = checkIsTicketOpen(ticket.status)
                ? LQA_TICKET_STATUS.RESOLVED
                : LQA_TICKET_STATUS.REOPENED;
            if (newStatus === LQA_TICKET_STATUS.RESOLVED) {
                sendManualTrack({ type: 'Mark Ticket as Resolved in Quick View' });
            } else {
                sendManualTrack({ type: 'Reopen ticket in Quick View' });
            }
            dispatch(updateLqaTicketStatus({ ticketId: id, currentStatus: oldStatus }));
            onChangeStatus(ticket, newStatus);
            dispatch(
                increaseDecreaseLqaTotal({
                    increaseStatus: checkIsTicketOpen(oldStatus) ? LQA_TICKET_STATUS.RESOLVED : LQA_TICKET_STATUS.OPEN,
                    decreaseStatus: checkIsTicketOpen(oldStatus) ? LQA_TICKET_STATUS.OPEN : LQA_TICKET_STATUS.RESOLVED
                })
            );
            dispatch(
                updateLQATicket({
                    ticket: { id, status: newStatus, columnId: ticket.columnId, recordId: ticket.recordId },
                    updateStatus: true,
                    snackbarMessage: (
                        <>
                            <b style={{ marginRight: '5px' }}>Ticket-{ticket.id}</b> mark as{' '}
                            {newStatus === LQA_TICKET_STATUS.RESOLVED ? t('global_resolved') : t('unresolved')}
                        </>
                    ),
                    errorCallback: () => {
                        onChangeStatus(ticket, oldStatus);
                        dispatch(setLqaTicket(oldLqaTicket));
                        dispatch(
                            increaseDecreaseLqaTotal({
                                increaseStatus: checkIsTicketOpen(oldStatus)
                                    ? LQA_TICKET_STATUS.OPEN
                                    : LQA_TICKET_STATUS.RESOLVED,
                                decreaseStatus: checkIsTicketOpen(oldStatus)
                                    ? LQA_TICKET_STATUS.RESOLVED
                                    : LQA_TICKET_STATUS.OPEN
                            })
                        );
                    },
                    successCallback: () => {
                        if (lqaTab === LQA_TICKET_STATUS.ALL) {
                            dispatch(
                                setLqaPageNumber({
                                    [LQA_TICKET_STATUS.OPEN]: 1,
                                    [LQA_TICKET_STATUS.RESOLVED]: 1
                                })
                            );
                        } else {
                            const tabStatus = checkIsTicketOpen(oldStatus)
                                ? LQA_TICKET_STATUS.RESOLVED
                                : LQA_TICKET_STATUS.OPEN;
                            dispatch(
                                setLqaPageNumber({
                                    [tabStatus]: 1,
                                    [LQA_TICKET_STATUS.ALL]: 1
                                })
                            );
                        }
                    }
                })
            );
        },
        [dispatch, lqaTab, id, lqaTicket, ticket, onChangeStatus, t]
    );

    const onClick = React.useCallback(
        e => {
            onClose && onClose();
            dispatch(setLQATicketDetail(ticket));
            dispatch(setOpenLQATicketDetail(true));
        },
        [dispatch, ticket, onClose]
    );

    const handleClick = React.useCallback(
        event => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const showMenuOption = React.useMemo(() => {
        return userInfo.id === createdBy.id;
    }, [userInfo.id, createdBy.id]);

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const menuOptions = React.useMemo(() => {
        return [
            // {
            //     name: 'Share this ticket',
            //     icon: <ShareSVG color={theme.colors.steel} />,
            //     onClick: e => {
            //         e.stopPropagation();
            //         e.preventDefault();
            //         handleClickAway();
            //     }
            // },
            {
                name: t('delete_ticket'),
                icon: <DeleteSVG color={theme.colors.burntSlena} />,
                className: classes.deleteTicket,
                onClick: e => {
                    e.stopPropagation();
                    e.preventDefault();
                    sendManualTrack({
                        type: isRightSideBar ? 'Delete Ticket in Ticket Center' : 'Delete Ticket in Quick View'
                    });
                    handleClickAway();
                    onClose && onClose();
                    dispatch(
                        gridActions.setOpenConfirmDeleteTicket({
                            isOpen: true,
                            ticket
                        })
                    );
                }
            }
        ];
    }, [dispatch, onClose, handleClickAway, theme, classes, ticket, isRightSideBar, t]);

    const defaultMember = React.useMemo(() => {
        if (!assignee?.id) return { value: '', label: 'Unassigned' };
        return {
            value: assignee.id,
            label: membersOption.find(m => m.value === assignee.id)?.label
        };
    }, [assignee, membersOption]);

    const handleAssigneeChange = React.useCallback(
        assignee => {
            const status = checkIsTicketOpen(ticket.status) ? LQA_TICKET_STATUS.OPEN : LQA_TICKET_STATUS.RESOLVED;
            const oldTicket = { ...ticket };
            const oldLqaTicket = JSON.parse(JSON.stringify(lqaTicket));
            const idx = lqaTicket[status].findIndex(t => t.id === id);
            const allStatus = LQA_TICKET_STATUS.ALL;
            const allIdx = lqaTicket[allStatus].findIndex(t => t.id === id);
            if (idx > -1) {
                lqaTicket[status][idx] = {
                    ...lqaTicket[status][idx],
                    assignee: { id: assignee.value, name: assignee.label }
                };
            }
            if (allIdx > -1) {
                lqaTicket[allStatus][idx] = {
                    ...lqaTicket[allStatus][idx],
                    assignee: { id: assignee.value, name: assignee.label }
                };
            }
            if (idx > -1 || allIdx > -1) {
                dispatch(setLqaTicket({ ...lqaTicket }));
            }
            onChangeAssignee(ticket, { id: assignee.value, name: assignee.label });
            dispatch(
                updateLQATicket({
                    ticket: {
                        id,
                        assigneeId: assignee.value || null,
                        columnId: ticket.columnId,
                        recordId: ticket.recordId
                    },
                    shouldSetLqaTickets: false,
                    errorCallback: () => {
                        dispatch(setLqaTicket(oldLqaTicket));
                        onChangeAssignee(oldTicket, oldTicket.assignee);
                    }
                })
            );
        },
        [dispatch, id, lqaTicket, ticket, onChangeAssignee]
    );

    return (
        <Grid container direction="column" spacing={3} onClick={onClick} {...rest}>
            <Grid item>
                <Grid container direction="row" wrap="nowrap" spacing={2}>
                    <Grid item className={classes.relative} onClick={isReadOnly ? () => {} : changeStatusHandler}>
                        {isReadOnly ? (
                            <Grid item>
                                <ThreeStateCheckBox
                                    state={checkboxState}
                                    className={classes.checkbox}
                                    onChange={() => {}}
                                />
                                {isTicketOpened && <CheckIconSVG className={classes.checkIcon} color="#ACADB9" />}
                            </Grid>
                        ) : (
                            <Tooltip title={`Mark as ${isTicketOpened ? 'resolved' : 'unresolved'}`}>
                                <Grid item>
                                    <ThreeStateCheckBox
                                        state={checkboxState}
                                        className={classes.checkbox}
                                        onChange={() => {}}
                                    />
                                    {isTicketOpened && <CheckIconSVG className={classes.checkIcon} color="#ACADB9" />}
                                </Grid>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item className={classes.summary}>
                        <p className={'body2 line-clamp-2'}>{summary}</p>
                    </Grid>
                    {showMenuOption && !isReadOnly && (
                        <Grid item className={classes.iconMore}>
                            <Grid container>
                                <Grid item>
                                    <IconButton onClick={handleClick}>
                                        <IconMoreActionsSVG />
                                    </IconButton>
                                </Grid>
                                {anchorEl && (
                                    <div className={classes.menuOptions}>
                                        <MenuOptionBase options={menuOptions} handleClickAway={handleClickAway} />
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" direction="row" wrap="nowrap" spacing={1}>
                        <Tooltip title={`${UpperCaseFirstCharacter(priority)} priority`}>
                            <Grid item className={classes.dpFlex}>
                                {getPriorityIcon(priority)}
                            </Grid>
                        </Tooltip>
                        <Grid item>
                            <p className="body2">{`Ticket-${id}`}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" direction="row" wrap="nowrap" spacing={1}>
                        <Grid item className={classes.dpFlex}>
                            <ColumnTypeDisplay {...column} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="flex-end" justify="space-between">
                        <Grid item>
                            <Grid container direction="row" alignItems="center" spacing={1}>
                                <Grid item className={classes.dpFlex}>
                                    <CommentsSVG className={classes.commentSVG} />
                                </Grid>
                                <Grid item>
                                    <p className="caption">{commentCount}</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        {isReadOnly ? (
                            <Grid item>
                                <Avatar src={getAvatarUrl(assignee?.id)} size={32} color="#DBDCE1">
                                    <AvatarIconSVG />
                                </Avatar>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <LDBasePortal
                                    dropdownClassName={classes.ldPortal}
                                    ddPlaceholder={t('select_an_assignee')}
                                    menuPlaceholder={t('find_an_assignee')}
                                    options={membersOption}
                                    onChange={handleAssigneeChange}
                                    defaultValue={defaultMember}
                                    isUsingChildren
                                >
                                    <Tooltip
                                        arrow={false}
                                        title={
                                            <>
                                                <div>{name || 'Unassigned'}</div>
                                                {/* <div>{getFriendlyTime(createdTime, 'DD-MMM-YYYY')}</div> */}
                                            </>
                                        }
                                    >
                                        <Grid item>
                                            <Avatar src={getAvatarUrl(assignee?.id)} size={32} color="#DBDCE1">
                                                <AvatarIconSVG />
                                            </Avatar>
                                        </Grid>
                                    </Tooltip>
                                </LDBasePortal>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TicketItem;
