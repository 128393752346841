import React from 'react';

function IconTableSVG({ color = '#6E5BBE', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...other} xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1table" fill="white">
                <path d="M15.75 0H2.25C1.00912 0 0 1.00912 0 2.25V15.75C0 16.9909 1.00912 18 2.25 18H15.75C16.9909 18 18 16.9909 18 15.75V2.25C18 1.00912 16.9909 0 15.75 0ZM2.25 1.5H15.75C16.1636 1.5 16.5 1.83637 16.5 2.25V5.25H1.5V2.25C1.5 1.83637 1.83637 1.5 2.25 1.5ZM11.25 6.75V16.5H6.75V6.75H11.25ZM1.5 15.75V6.75H5.25V16.5H2.25C1.83637 16.5 1.5 16.1636 1.5 15.75ZM15.75 16.5H12.75V6.75H16.5V15.75C16.5 16.1636 16.1636 16.5 15.75 16.5Z" />
            </mask>
            <path
                d="M15.75 0H2.25C1.00912 0 0 1.00912 0 2.25V15.75C0 16.9909 1.00912 18 2.25 18H15.75C16.9909 18 18 16.9909 18 15.75V2.25C18 1.00912 16.9909 0 15.75 0ZM2.25 1.5H15.75C16.1636 1.5 16.5 1.83637 16.5 2.25V5.25H1.5V2.25C1.5 1.83637 1.83637 1.5 2.25 1.5ZM11.25 6.75V16.5H6.75V6.75H11.25ZM1.5 15.75V6.75H5.25V16.5H2.25C1.83637 16.5 1.5 16.1636 1.5 15.75ZM15.75 16.5H12.75V6.75H16.5V15.75C16.5 16.1636 16.1636 16.5 15.75 16.5Z"
                fill="white"
            />
            <path
                d="M16.5 5.25V13.25H24.5V5.25H16.5ZM1.5 5.25H-6.5V13.25H1.5V5.25ZM11.25 6.75H19.25V-1.25H11.25V6.75ZM11.25 16.5V24.5H19.25V16.5H11.25ZM6.75 16.5H-1.25V24.5H6.75V16.5ZM6.75 6.75V-1.25H-1.25V6.75H6.75ZM1.5 6.75V-1.25H-6.5V6.75H1.5ZM5.25 6.75H13.25V-1.25H5.25V6.75ZM5.25 16.5V24.5H13.25V16.5H5.25ZM12.75 16.5H4.75V24.5H12.75V16.5ZM12.75 6.75V-1.25H4.75V6.75H12.75ZM16.5 6.75H24.5V-1.25H16.5V6.75ZM15.75 -8H2.25V8H15.75V-8ZM2.25 -8C-3.40915 -8 -8 -3.40915 -8 2.25H8C8 5.4274 5.4274 8 2.25 8V-8ZM-8 2.25V15.75H8V2.25H-8ZM-8 15.75C-8 21.4092 -3.40915 26 2.25 26V10C5.4274 10 8 12.5726 8 15.75H-8ZM2.25 26H15.75V10H2.25V26ZM15.75 26C21.4092 26 26 21.4092 26 15.75H10C10 12.5726 12.5726 10 15.75 10V26ZM26 15.75V2.25H10V15.75H26ZM26 2.25C26 -3.40915 21.4092 -8 15.75 -8V8C12.5726 8 10 5.4274 10 2.25H26ZM2.25 9.5H15.75V-6.5H2.25V9.5ZM15.75 9.5C11.7453 9.5 8.5 6.25465 8.5 2.25H24.5C24.5 -2.5819 20.5819 -6.5 15.75 -6.5V9.5ZM8.5 2.25V5.25H24.5V2.25H8.5ZM16.5 -2.75H1.5V13.25H16.5V-2.75ZM9.5 5.25V2.25H-6.5V5.25H9.5ZM9.5 2.25C9.5 6.25465 6.25465 9.5 2.25 9.5V-6.5C-2.5819 -6.5 -6.5 -2.5819 -6.5 2.25H9.5ZM3.25 6.75V16.5H19.25V6.75H3.25ZM11.25 8.5H6.75V24.5H11.25V8.5ZM14.75 16.5V6.75H-1.25V16.5H14.75ZM6.75 14.75H11.25V-1.25H6.75V14.75ZM9.5 15.75V6.75H-6.5V15.75H9.5ZM1.5 14.75H5.25V-1.25H1.5V14.75ZM-2.75 6.75V16.5H13.25V6.75H-2.75ZM5.25 8.5H2.25V24.5H5.25V8.5ZM2.25 8.5C6.25465 8.5 9.5 11.7453 9.5 15.75H-6.5C-6.5 20.5819 -2.5819 24.5 2.25 24.5V8.5ZM15.75 8.5H12.75V24.5H15.75V8.5ZM20.75 16.5V6.75H4.75V16.5H20.75ZM12.75 14.75H16.5V-1.25H12.75V14.75ZM8.5 6.75V15.75H24.5V6.75H8.5ZM8.5 15.75C8.5 11.7453 11.7453 8.5 15.75 8.5V24.5C20.5819 24.5 24.5 20.5819 24.5 15.75H8.5Z"
                fill={color}
                fillOpacity="0.8"
                mask="url(#path-1-inside-1table)"
            />
        </svg>
    );
}

export default React.memo(IconTableSVG);
