import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_HEIGHT, SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT } from 'const/style';
import NestedMenuItem from 'components/menus/HoverMenu';
import CountrySelect from '../common/CountrySelect';
import { getLocalizationColumnTypeLabel, TRANSLATION_TYPES } from 'gridUI/column-types';
import ListItem from 'components/list/Item';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import MultiLinesSVG from 'components/svg-icon/MultiLinesSVG';
import { useTranslation } from 'react-i18next';
import * as columnTypes from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {
        width: 200
    },
    item: {
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        padding: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.hoverItem
        }
    }
}));

function ColumnSetup({ onClickAway, id, group, type, onColumnChange, localizationType }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const rootRef = React.useRef();
    const langOptions = useLanguageOptionsWithUsageValidate();

    useClickAwaitListener(rootRef, e => {
        onClickAway(e);
    });

    const defaultOption = React.useMemo(() => {
        if (!group) return null;
        return langOptions.find(option => option.value === group);
    }, [group, langOptions]);

    const isSource = React.useMemo(() => {
        return type === columnTypes.TRANSLATION && localizationType === TRANSLATION_TYPES.SOURCE_LANG;
    }, [type, localizationType]);

    const isTarget = React.useMemo(() => {
        return type === columnTypes.TRANSLATION && localizationType === TRANSLATION_TYPES.TARGET_LANG;
    }, [type, localizationType]);

    const isMultipleLine = React.useMemo(() => {
        return type === columnTypes.MULTIPLE_LINES;
    }, [type]);

    const options = React.useMemo(() => {
        return langOptions;
    }, [langOptions]);

    const handleSourceLanguageChange = React.useCallback(
        option => {
            onColumnChange({
                id,
                body: {
                    localizationType: TRANSLATION_TYPES.SOURCE_LANG,
                    type: columnTypes.TRANSLATION,
                    group: option?.value
                }
            });
            onClickAway && onClickAway();
        },
        [id, onColumnChange, onClickAway]
    );

    const handleTargetLanguageChange = React.useCallback(
        option => {
            onColumnChange({
                id,
                body: {
                    localizationType: TRANSLATION_TYPES.TARGET_LANG,
                    type: columnTypes.TRANSLATION,
                    group: option?.value
                }
            });
            onClickAway && onClickAway();
        },
        [id, onColumnChange, onClickAway]
    );

    const resetColumn = React.useCallback(() => {
        onColumnChange({
            id,
            body: {
                localizationType: null,
                type: columnTypes.MULTIPLE_LINES,
                group: null
            }
        });
        onClickAway && onClickAway();
    }, [id, onClickAway, onColumnChange]);

    const handleMultipleLineChange = React.useCallback(
        e => {
            onColumnChange({
                id,
                body: {
                    localizationType: null,
                    type: columnTypes.MULTIPLE_LINES,
                    group: null
                }
            });
            onClickAway && onClickAway();
        },
        [id, onColumnChange, onClickAway]
    );

    return (
        <Grid ref={rootRef} container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item className={classes.item} onClick={resetColumn}>
                <Typography variant="caption">{t('localization_setup_reset_action')}</Typography>
            </Grid>

            <Grid item className={classes.content}>
                <Grid container direction="column" wrap="nowrap">
                    <NestedMenuItem
                        label={getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.SOURCE_LANG })}
                        parentMenuOpen={true}
                    >
                        <CountrySelect
                            defaultValue={isSource ? defaultOption : null}
                            options={options}
                            onChange={handleSourceLanguageChange}
                        />
                    </NestedMenuItem>

                    <NestedMenuItem
                        label={getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.TARGET_LANG })}
                        parentMenuOpen={true}
                    >
                        <CountrySelect
                            defaultValue={isTarget ? defaultOption : null}
                            options={options}
                            onChange={handleTargetLanguageChange}
                        />
                    </NestedMenuItem>

                    <ListItem
                        icon={<MultiLinesSVG />}
                        onClick={handleMultipleLineChange}
                        name={'Multiple Lines'}
                        isSelected={isMultipleLine}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ColumnSetup);
