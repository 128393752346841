import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    paper: {
        position: 'absolute',
        top: props => props.offset || 40,
        right: props => (props.left ? 0 : 'auto'),
        left: props => (props.left ? 'auto' : 0),
        zIndex: 1000,
        minWidth: props => props.width || 140,
        boxShadow: theme.shadows[1]
    }
}));

function MenuBase({ Trigger, Content, left = true, offset = 0, width, onClickAway }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles({ left, offset, width });
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
        if (open) {
            onClickAway && onClickAway(e, setOpen);
        }
    });

    const handleClick = e => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <div ref={rootRef} className={`menu-base ${classes.root}`}>
            <div>
                <Trigger handleClick={handleClick} open={open} />
                {open ? (
                    // <Grow in={open}>
                    <Paper className={classes.paper}>
                        <Content setOpen={setOpen} />
                    </Paper>
                ) : // </Grow>
                null}
            </div>
        </div>
    );
}

MenuBase.propTypes = {
    Trigger: PropTypes.func.isRequired,
    Content: PropTypes.func.isRequired,
    left: PropTypes.bool.isRequired,
    offset: PropTypes.number
};

export default React.memo(MenuBase);
