import React from 'react';
import { useSelector } from 'react-redux';
import { useRightSidebarBody } from 'hooks/app';
import { useGroupList } from 'hooks/permission';
import { useCanViewGroupShare } from 'hooks/payment';
import { makeGroupOption } from 'utils/permission/group';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';

export function useDatabaseList() {
    return useSelector(state => state.database.list);
}

export function useDatabaseByDbId(dbId) {
    const dbList = useDatabaseList();
    return dbList?.find(db => db?.id === dbId);
}

export function useBackupFiles() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.files);
}

export function useSchduleBackup() {
    return useSelector(state => state.database.scheduleBackup);
}

export function useIsFetchingBackupFiles() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.isFetching);
}

export function useIsFetchedBackupFiles() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.isFetched);
}

export function useIsFetchingMoreBackupFiles() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.isFetchingMore);
}

export function useIsBackuping() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.isBackuping);
}

export function useBackupFilesOffset() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.offset);
}

export function useTotalBackupFilesCount() {
    const { dbId } = useRightSidebarBody();
    return useSelector(state => state.database.backup?.[dbId]?.total);
}

export function useDatabaseByDbIdInSidebarRight() {
    const { dbId } = useRightSidebarBody();

    return useDatabaseByDbId(dbId);
}

export function useIsCreatingDatabase() {
    return useSelector(state => state.database?.isCreatingDatabase);
}

export function useIsSkeletonLoadingId() {
    return useSelector(state => state.database?.isSkeletonLoadingId);
}

export function useIsShowFirstPopupCreate() {
    return useSelector(state => state.database?.isShowFirstPopupCreate);
}

export function useDatabasesByWorkspaceId(workspaceId) {
    const list = useSelector(state => state.database?.list);
    const dbIds = useSelector(state => state.database?.workspaceDbs?.[workspaceId]?.dbIds) || [];

    return dbIds
        .filter(dbId => {
            return list?.findIndex(db => db.id === dbId) !== -1;
        })
        ?.map(dbId => {
            const found = list?.find(item => item.id === dbId);
            return found;
        });
}

export function useDatabasesOptionsByWorkspaceId(workspaceId) {
    const databases = useDatabasesByWorkspaceId(workspaceId);
    return databases?.map(db => ({
        label: db?.name,
        value: db?.id,
        icon: () => <DatabaseSVG />
    }));
}

export function useDatabaseDetailByWorkspaceId(workspaceId) {
    return useSelector(state => state.database?.workspaceDbs?.[workspaceId]);
}

export function useIsFetchingDatabases() {
    return useSelector(state => state.database.isFetchingDatabases);
}

export function useDatabaseStatusByDbId(dbId) {
    const dbDetail = useDatabaseByDbId(dbId);
    return dbDetail?.status?.toLowerCase();
}

export function useDatabaseGroups() {
    return useSelector(state => state.database.shareDatabases.sharedGroups);
}

export function useDatabaseGroupsIsLoading() {
    return useSelector(state => state.database.shareDatabases.isFetching);
}

export function useDatabaseGroupOptions() {
    const groups = useDatabaseGroups();
    const canViewGroupShare = useCanViewGroupShare();
    return groups?.map(group => {
        const isDisabled = !canViewGroupShare && !group?.systemGroup;
        return makeGroupOption({ group, isDisabled });
    });
}

export function useNotSharedDatabaseGroups() {
    const companyGroups = useGroupList();
    const databaseGroups = useDatabaseGroups();
    const databaseGroupIds = databaseGroups?.map(group => group.id.toString());
    const notSharedGroups = companyGroups?.filter(group => !databaseGroupIds?.includes(group.id));
    return notSharedGroups?.map(group => makeGroupOption({ group }));
}
