import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import TMGuideSVG from 'assets/images/svg/TMGuideSVG';
import TMSearchNotFoundSVG from 'assets/images/svg/TMSearchNotFoundSVG';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import { getFriendlyDate } from 'utils/datetime';
import { Link, useParams } from 'react-router-dom';
import AnimationLoading from 'components/loading/AnimationLoading';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 472,
        borderRadius: 4
    },
    textCenter: {
        textAlign: 'center'
    },
    spinnerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%'
    },
    header: {
        height: 40,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    body: {
        height: 472,
        overflowY: 'auto',
        position: 'relative',
        width: '100%'
    },
    recordItem: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.ghostwhite
        }
    },
    fullWidth: {
        width: '100%'
    },
    showMoreWrapper: {
        padding: theme.spacing(3),
        '&:hover': {
            cursor: 'pointer'
        }
    },
    showMoreText: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    showMoreBadge: {
        padding: `2px 4px`,
        borderRadius: 2,
        background: theme.colors.paleGrey,
        color: theme.colors.dimGrey
    },
    gridWrapper: {
        marginBottom: 8,
        ...theme.ellipsis(1)
    },
    dbwrapper: {
        padding: '4px 5px',
        maxWidth: 170,
        width: 'fit-content',
        background: theme.colors.paleGrey,
        borderRadius: 2,
        '& svg': {
            verticalAlign: 'sub',
            marginRight: 5
        }
    },
    dbNameWrapper: {
        ...theme.ellipsis(1)
    },
    dbName: {
        color: theme.colors.steel
    },
    dot: {
        width: 3,
        height: 3,
        background: '#C4C4C4',
        margin: '0 8px'
    },
    createdTime: {
        color: theme.colors.secondaryText
    },
    animationWrapper: {
        height: 470
    },
    animationLoading: {
        width: '100%',
        height: '100%',
        padding: '0 71px'
    }
}));

function SearchBox({ total, isFirstTime, isSearching, isSearchingMore, gridData, fetchMoreGrids }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { workspaceId } = useParams();

    const showMoreNumber = useMemo(() => {
        return Math.max(0, total - gridData.length);
    }, [total, gridData]);

    const isHavingNoResult = useMemo(() => {
        return !gridData?.length && !isSearching;
    }, [gridData, isSearching]);

    if (isFirstTime)
        return (
            <Grid
                container
                spacing={2}
                className={classes.root}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item>
                    <TMSearchNotFoundSVG />
                </Grid>
                <Grid item container direction="column" spacing={1} className={classes.textCenter}>
                    <Grid item>
                        <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">{t('what_are_you_looking_for')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );

    if (isSearching) {
        return (
            <Grid item className={classes.animationWrapper}>
                <AnimationLoading
                    className={classes.animationLoading}
                    data={{
                        0: 'Wait for it',
                        2.5: 'Here it comes',
                        5: 'Almost there'
                    }}
                    maxSecond={7.5}
                    timeInterval={500}
                />
            </Grid>
        );
    }

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.body}>
                {isHavingNoResult ? (
                    <Grid
                        container
                        spacing={2}
                        className={classes.root}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <TMGuideSVG />
                        </Grid>
                        <Grid item container direction="column" spacing={1} className={classes.textCenter}>
                            <Grid item>
                                <Typography variant="h4">No results were found to match your search</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">Try modifying your search criteria</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column" className={classes.fullWidth}>
                        {gridData?.map(record => {
                            const { dbId, dbName, gridId, gridName, alteredTime, branchId, viewId } = record;
                            return (
                                <Link
                                    key={gridId}
                                    to={`/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}`}
                                    style={{ textDecoration: 'none', width: '100%', color: 'unset' }}
                                    target="_blank"
                                >
                                    <Grid item container direction="column" className={classes.recordItem}>
                                        <Grid item className={classes.gridWrapper}>
                                            <Typography variant="body1">{gridName}</Typography>
                                        </Grid>
                                        <Grid item container wrap="nowrap" alignItems="center">
                                            <Grid
                                                item
                                                container
                                                wrap="nowrap"
                                                alignItems="center"
                                                className={classes.dbwrapper}
                                            >
                                                <Grid item>
                                                    <DatabaseSVG />
                                                </Grid>
                                                <Grid item className={classes.dbNameWrapper}>
                                                    <Typography variant="body2" className={classes.dbName}>
                                                        {dbName}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.dot} />
                                            <Grid item>
                                                <Typography variant="body2" className={classes.createdTime}>
                                                    Created {getFriendlyDate(alteredTime)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Link>
                            );
                        })}
                        {showMoreNumber > 0 && (
                            <Grid item className={classes.showMoreWrapper}>
                                {isSearchingMore && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justify="center"
                                        direction="row"
                                        spacing={2}
                                        style={{ height: 33 }}
                                    >
                                        <Grid item>
                                            <Spinner size={18} thick={3} />
                                        </Grid>
                                    </Grid>
                                )}
                                {!isSearchingMore && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        direction="row"
                                        spacing={2}
                                        onClick={fetchMoreGrids}
                                    >
                                        <Grid item>
                                            <Typography className={classes.showMoreText} variant="body1">
                                                Show more
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.showMoreBadge} variant="body1">
                                                {showMoreNumber}+
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default SearchBox;
