import React from 'react';
import { Grid, IconButton, CircularProgress, useTheme } from '@material-ui/core';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import IOSSwitch from 'components/switches/IOS';
import FilesSVG from 'components/svg-icon/FilesSVG';
import PathTagSVG from 'assets/images/svg/PathTagSVG';
import { useViewColumnsWithReOrderAndMetaData, useRows, useData } from 'hooks/gridUI';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useDropzone } from 'react-dropzone';
import * as gridUIActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';
import ButtonBase from 'components/button/Base';
import InputText from 'components/inputs/InputText';
import { getISOString } from 'utils/datetime';
import { getAbsolutePath } from 'utils/fileUtils';
import { Trans } from 'react-i18next';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { getUploadErrorMessage } from 'utils/upload';
import { GRID_UPLOAD_MAX_SIZE } from 'const';
import Tooltip from 'components/tooltip/Base';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import { useTranslation } from 'react-i18next';

function UploadFolderModal({ column, onClose, isSubmitting }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const data = useData();
    const rowIds = useRows();
    const [selectedColumnOption, setSelectedColumnOption] = React.useState(null);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [isCombinedWithPathTag, setIsCombinedWithPathTag] = React.useState(false);
    const viewColumns = useViewColumnsWithReOrderAndMetaData();
    const { t } = useTranslation();

    const columnId = React.useMemo(() => {
        return column?.id;
    }, [column]);

    const viewColumnOptions = viewColumns
        ?.filter(
            column =>
                column.type === columnTypes.SINGLE_LINE ||
                column.type === columnTypes.MULTIPLE_LINES ||
                column.type === columnTypes.TRANSLATION ||
                column.type === columnTypes.RECORD_ID
        )
        ?.map(col => ({
            ...col,
            options: null,
            value: col.id,
            label: col.name,
            icon: () => <ColumnIcon group={col?.group} type={col?.type} customProperties={col?.customProperties} />
        }));

    const isHavingPathTagColumn = React.useMemo(() => {
        return viewColumns.some(column => column.type === columnTypes.PATH_TAG);
    }, [viewColumns]);

    const getFileName = React.useCallback(
        ({ rowId }) => {
            return getCellValueOnly({ data, rowId, columnId: selectedColumnOption?.id })?.trim();
        },
        [data, selectedColumnOption]
    );

    const getFilePath = React.useCallback(
        ({ rowId }) => {
            return getCellValueOnly({ data, rowId, columnId: columnTypes.PATH_TAG })?.trim();
        },
        [data]
    );

    const onDrop = acceptedFiles => {
        setSelectedFiles(acceptedFiles);
    };

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles);
            const fileError = fileErrors?.[0];

            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const { getInputProps, open } = useDropzone({
        onDrop,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noDragEventsBubbling: true,
        onDropRejected
    });

    const handleUpload = () => {
        dispatch(gridUIActions.addProcessingColumns({ columnIds: [columnId] }));
        let filteredFiles = selectedFiles?.filter(file => file.name !== '.DS_Store');
        onClose();
        dispatch(gridUIActions.resetColumnUploadStatus({ columnId: columnId }));
        let formData = new FormData();
        let fileMetadata;
        const filteredObjectFiles = filteredFiles.map(file => ({
            lastModified: file.lastModified,
            lastModifiedDate: getISOString(file.lastModifiedDate),
            name: file.name,
            size: file.size,
            type: file.type,
            path: getAbsolutePath(file.path)
        }));
        dispatch(gridUIActions.uploadFilesStart({ columnId, files: filteredObjectFiles, isCombinedWithPathTag }));
        filteredFiles.forEach(file => {
            const newPath = getAbsolutePath(file.path);
            const fileObject = {
                lastModified: file.lastModified,
                lastModifiedDate: getISOString(file.lastModifiedDate),
                name: file.name,
                size: file.size,
                type: file.type,
                path: newPath
            };
            formData.append('files', file, newPath);
            fileMetadata = {
                ...fileMetadata,
                [newPath]: fileObject
            };
        });
        const addFileRequest = {
            mappingColumnId: selectedColumnOption?.id,
            fileMetadata,
            withPathTag: isCombinedWithPathTag
        };

        formData.append('addFileRequest', JSON.stringify(addFileRequest));
        dispatch(
            gridUIActions.uploadFileToColumn({
                columnId,
                formData,
                successCallback: () => {
                    console.log('upload files successfully');
                },
                errorCallback: () => {
                    console.log('Failed to upload files');
                    dispatch(gridUIActions.removeProcessingColumns({ columnIds: [columnId] }));
                }
            })
        );
    };

    const handleTogglePathTagOption = () => {
        setIsCombinedWithPathTag(!isCombinedWithPathTag);
        setTimeout(() => {
            if (!isHavingPathTagColumn) {
                setIsCombinedWithPathTag(false);
                dispatch(
                    enqueueSnackbar({
                        message: 'Path column is not available in the current view',
                        type: 'info'
                    })
                );
            }
        }, 500);
    };

    return (
        <Grid container className={'p-8 max-w-[640px] bg-white rounded'}>
            <input webkitdirectory="" multiple {...getInputProps()} />
            <IconButton size="small" className={'absolute top-3.5 right-3.5 cursor-pointer'} onClick={onClose}>
                <CloseIconSVG size="medium" />
            </IconButton>
            <Grid item>
                <h3 className="prose prose-2xl font-medium inline">{t(`upload_folder`)}</h3>
                <span className={'ml-3.5 inline-flex bg-grey-ghost items-center rounded py-1 px-2'}>
                    <FilesSVG />
                    <p className={'ml-1 body2'}>{column?.name}</p>
                </span>
            </Grid>
            <Grid item container direction="column" spacing={4} className={'mt-3.5'}>
                <Grid item>
                    <p className="caption">{t(`upload_folder_description`)}</p>
                </Grid>
                <Grid item container spacing={1} direction="column">
                    <Grid item>
                        <p className="body1">{t(`folder_to_upload`)}</p>
                    </Grid>
                    <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                        <Grid item style={{ flex: 1 }}>
                            <InputText
                                value={selectedFiles?.[0]?.path?.split('/')?.[0]}
                                disabled={true}
                                placeholder={t(`global_label_choose_a_folder`)}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonBase width={120} variant="outlined" onClick={open}>
                                {t(`global_browse`)}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} direction="column">
                    <Grid item>
                        <p className="body1">{t(`upload_folder_column_tp_match_filename`)}</p>
                    </Grid>
                    <Grid item>
                        <LDBasePortal
                            ddPlaceholder={t(`global_label_select_a_column`)}
                            menuPlaceholder={t(`global_label_find_a_column`)}
                            options={viewColumnOptions}
                            onChange={option => setSelectedColumnOption(option)}
                            defaultValue={selectedColumnOption}
                        />
                    </Grid>
                </Grid>
                <Grid item container alignItems="center">
                    <IOSSwitch
                        className={'mr-2'}
                        checked={isCombinedWithPathTag}
                        onChange={handleTogglePathTagOption}
                    />
                    <Trans i18nKey="upload_folder_comebine_with_path_tag" t={t}>
                        <p className="body2 inline">Combine</p>
                        <span className={'mx-1 inline-flex bg-solitude items-center rounded py-1 px-2'}>
                            <PathTagSVG style={{ marginRight: 4 }} />
                            <p className="body1 inline">Path</p>
                        </span>
                        <p className="body2 inline">with the file name</p>
                    </Trans>
                </Grid>
                <Grid item container spacing={1} direction="column">
                    <Grid item container alignItems="center" spacing={1}>
                        <Grid item>
                            <p className="body1">{t(`upload_folder_sample_text`)}</p>
                        </Grid>
                        <Tooltip
                            placement="top"
                            title={
                                <>
                                    Below are just some sample data, Gridly will get the first
                                    <br />
                                    50 records to compare with the file name in the folder
                                </>
                            }
                        >
                            <Grid item style={{ display: 'flex' }}>
                                <QuestionSVG />
                            </Grid>
                        </Tooltip>
                        <Grid item style={{ flex: 1 }} />
                    </Grid>
                    <Grid item>
                        <div
                            className={
                                'bg-grey-ghost border border-grey-border rounded p-3.5 overflow-x-auto min-h-[110px] w-[576px] h-[131px]'
                            }
                        >
                            {selectedColumnOption &&
                                selectedFiles.length &&
                                rowIds
                                    ?.filter(rowId => {
                                        const filePathTag = getFilePath({ rowId });
                                        const filePathName = getFileName({ rowId });
                                        return isCombinedWithPathTag ? filePathTag && filePathName : filePathName;
                                    })
                                    ?.slice(0, 50)
                                    ?.map(rowId => {
                                        const filePathTag = getFilePath({ rowId });
                                        const filePathName = getFileName({ rowId });

                                        const isMultiple = filePathName?.includes(',');

                                        if (isMultiple) {
                                            const childFileNames = filePathName?.split(',')?.map(i => i?.trim());
                                            return (
                                                <div key={rowId}>
                                                    {childFileNames?.map((fileName, index) => {
                                                        const filePathNameFinal = isCombinedWithPathTag
                                                            ? `${filePathTag}/${fileName}`
                                                            : fileName;

                                                        const matched = selectedFiles.some(file => {
                                                            return (
                                                                getAbsolutePath(file?.path)
                                                                    ?.split('.')?.[0]
                                                                    ?.toLowerCase() ===
                                                                filePathNameFinal?.trim()?.toLowerCase()
                                                            );
                                                        });
                                                        return (
                                                            <>
                                                                <p
                                                                    className="body2 inline"
                                                                    key={index}
                                                                    style={{
                                                                        color: matched
                                                                            ? theme.colors.atlantis
                                                                            : theme.colors.brightRed
                                                                    }}
                                                                >
                                                                    {matched ? '(Matched)' : '(Not matched)'}{' '}
                                                                </p>{' '}
                                                                <p className="body2 inline mr-2">{filePathNameFinal}</p>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        }

                                        const filePathNameFinal = isCombinedWithPathTag
                                            ? `${filePathTag}/${filePathName}`
                                            : filePathName;

                                        const matched = selectedFiles.some(file => {
                                            return (
                                                getAbsolutePath(file?.path)
                                                    ?.split('.')?.[0]
                                                    ?.toLowerCase() === filePathNameFinal?.trim()?.toLowerCase()
                                            );
                                        });
                                        return (
                                            <div key={rowId}>
                                                <p
                                                    className="inline-block body2"
                                                    style={{
                                                        color: matched ? theme.colors.atlantis : theme.colors.brightRed
                                                    }}
                                                >
                                                    {matched ? '(Matched)' : '(Not matched)'}{' '}
                                                </p>
                                                <p className="body2 inline">{filePathNameFinal}</p>
                                            </div>
                                        );
                                    })}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container justify="flex-end" spacing={2} className={'mt-8'}>
                <Grid item>
                    <Button variant="outlined" width={100} onClick={onClose}>
                        {t(`global_cancel`)}
                    </Button>
                </Grid>
                <Grid item className={'relative'}>
                    <Button
                        disabled={isSubmitting || !selectedColumnOption || selectedFiles.length === 0}
                        variant="contained"
                        width={120}
                        onClick={handleUpload}
                    >
                        {t(`global_upload`)}
                    </Button>
                    {isSubmitting && (
                        <CircularProgress
                            size={24}
                            className={'absolute text-brand-main top-[50%] left-[50%] -ml-3 -mt-3'}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(UploadFolderModal);
