import { TRIGGER_MATCH_STRING_SERVER_REGEX } from 'gridUI/automations/const';
import { FUNCTIONS_LOWER_CASE, DESERIALIZE_TYPES, FUNCTIONS } from './const';

export const deserialize = string => {
    return tokenize(string);
};

function Token(type, value) {
    this.type = type;
    this.value = value;
}

function tokenize(str) {
    const results = [];
    const characters = str?.split('');
    const length = characters?.length;
    let letterBuffer = [];

    for (const [index, ch] of characters.entries()) {
        // const test = ch;
        // console.log('test', test);
        // console.log('isLetter', _isLetter(test));
        // console.log('_isVariable', _isVariable(test));
        // console.log('_isComma', _isComma(test));
        // console.log('_isPoint', _isPoint(test));
        // console.log('_isOperator', _isOperator(test));
        // console.log('_isSpecialCharacter', _isSpecialCharacter(test));
        // console.log('_isLeftParenthesis', _isLeftParenthesis(test));
        // console.log('_isRightParenthesis', _isRightParenthesis(test));
        // console.log('_isComparator', _isComparator(test));
        // console.log('_isQuotes', _isQuotes(test));
        // console.log('_isSpace', _isSpace(test));
        if (letterBuffer?.length) {
            if (!_isLetter(ch)) {
                const word = letterBuffer?.join('');
                const wordLowerCase = word?.toLowerCase();
                if (FUNCTIONS_LOWER_CASE?.includes(wordLowerCase)) {
                    const match = FUNCTIONS?.find(func => func?.toLowerCase() === wordLowerCase);
                    results.push(new Token(DESERIALIZE_TYPES.FUNCTION, match || word));
                } else if (_isVariable(word)) {
                    results.push(new Token(DESERIALIZE_TYPES.VARIABLE, word));
                } else {
                    results.push(new Token(DESERIALIZE_TYPES.TEXT, word));
                }
                letterBuffer = [];
            }
        }

        if (_isLetter(ch)) {
            letterBuffer.push(ch);
            if (index === length - 1) {
                if (letterBuffer?.length) {
                    const word = letterBuffer?.join('');
                    if (FUNCTIONS_LOWER_CASE?.includes(word?.toLowerCase())) {
                        results.push(new Token(DESERIALIZE_TYPES.FUNCTION, word));
                    } else if (_isVariable(word)) {
                        results.push(new Token(DESERIALIZE_TYPES.VARIABLE, word));
                    } else {
                        results.push(new Token(DESERIALIZE_TYPES.TEXT, word));
                    }
                    letterBuffer = [];
                } else {
                    results.push(new Token(DESERIALIZE_TYPES.TEXT, ch));
                }
            }
            continue;
        }

        if (_isComma(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.COMMA, ch));
            continue;
        }

        if (_isPoint(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.POINT, ch));
            continue;
        }

        if (_isOperator(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.OPERATOR, ch));
            continue;
        }

        if (_isSpecialCharacter(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.SPECIAL, ch));
            continue;
        }

        if (_isLeftParenthesis(ch) || _isRightParenthesis(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.PARENTHESIS, ch));
            continue;
        }

        if (_isComparator(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.COMPARATOR, ch));
            continue;
        }

        if (_isQuotes(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.QUOTE, ch));
            continue;
        }

        if (_isSpace(ch)) {
            results.push(new Token(DESERIALIZE_TYPES.SPACE, ch));
            continue;
        }

        results.push(new Token(DESERIALIZE_TYPES.TEXT, ch));
    }

    return results;
}

export const deserializeMention = string => {
    return tokenizeMention(string);
};

const mentionRegex = new RegExp('@{[0-9]+}');

function tokenizeMention(str) {
    str = str.replace(/@{[0-9]+}/g, match => `[MGLY]${match}[MGLY]`);
    const words = str.split('[MGLY]');
    let results = [];
    words.map(word => {
        if (mentionRegex.test(word)) {
            results.push(new Token(DESERIALIZE_TYPES.MENTION, word));
            return false;
        }
        results.push(new Token(DESERIALIZE_TYPES.TEXT, word));
        return false;
    });
    return results;
}

export const deserializePayload = string => {
    return tokenizePayload(string);
};

const payloadRegex = new RegExp(TRIGGER_MATCH_STRING_SERVER_REGEX);

function tokenizePayload(str) {
    str = str.replace(new RegExp(TRIGGER_MATCH_STRING_SERVER_REGEX, 'g'), match => `[PGLY]${match}[PGLY]`);
    const words = str.split('[PGLY]');
    const results = [];
    words.map(word => {
        if (payloadRegex.test(word)) {
            results.push(new Token(DESERIALIZE_TYPES.PAYLOAD, word));
            return false;
        }
        results.push(new Token(DESERIALIZE_TYPES.TEXT, word));
        return false;
    });
    return results;
}

function _isPoint(ch) {
    return ch === '.';
}

function _isComma(ch) {
    return ch === ',';
}

function _isLetter(ch) {
    return /\w+/i.test(ch);
}

function _isSpecialCharacter(ch) {
    // eslint-disable-next-line
    return /\!|\@|\#|\$|\%|\&|\\|\:|\;/i.test(ch);
}

function _isVariable(ch) {
    return /^col_val_(\w+)$/i.test(ch);
}

function _isQuotes(ch) {
    return /"|'/i.test(ch);
}

function _isSpace(ch) {
    return /\s/i.test(ch);
}

function _isOperator(ch) {
    return /\+|-|\*|\/|\^/.test(ch);
}

function _isComparator(ch) {
    return />|<|=/.test(ch);
}

function _isLeftParenthesis(ch) {
    return ch === '(';
}

function _isRightParenthesis(ch) {
    return ch === ')';
}

// function _isDecimalSymbol(ch) {
//   return ch === ".";
// }

// function _isDigit(ch) {
//   return /\d/.test(ch);
// }
