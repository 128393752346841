import store from 'store/configStore';
import * as types from 'socket/types';
import * as gridActions from 'gridUI/actions';

const { dispatch } = store;

const dependencyHandler = ({ body }) => {
    const { subAction } = body;
    switch (subAction) {
        case types.UPDATE:
            return _updateDependencyHandler({ body });
        case types.CREATE:
            return _createDependencyHandler({ body });
        case types.DELETE:
            return _deleteDependencyHandler({ body });
        default:
            return;
    }
};

function _createDependencyHandler({ body }) {
    dispatch(gridActions.addDependencyRealtimeAction(body?.data));
}

function _deleteDependencyHandler({ body }) {
    dispatch(gridActions._deleteDependencySuccess({ dependencyId: body?.data?.id }));
}

function _updateDependencyHandler({ body }) {
    dispatch(gridActions.updateDependencyRealtimeAction({ dependencyId: body?.data?.id, dependency: body?.data }));
}

export default dependencyHandler;
