import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';
import isArray from 'lodash/isArray';

function MultipleSelection({ previousData, options = [], customProperties = {}, isHideMarkIcon = false }) {
    if (!previousData || previousData?.length === 0 || !isArray(previousData)) return null;

    return (
        <Grid container spacing={1}>
            {previousData?.map((option, index) => {
                return (
                    <Grid item key={index}>
                        <Option
                            data={option}
                            isHideMarkIcon={isHideMarkIcon}
                            state={'delete'}
                            options={options}
                            customProperties={customProperties}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(MultipleSelection);
