import React from 'react';
import Option from 'components/option/Base';
import { getOptionColor } from 'utils/color';
import Plus from 'assets/images/svg/diff/Plus';
import Minus from 'assets/images/svg/diff/Minus';
import classNames from 'classnames';

const defaultState = {
    UPDATE: 'update',
    DELETE: 'delete',
    NORMAL: 'normal'
};

function OptionDiffCheck({ state = defaultState.NORMAL, data, customProperties, options, isHideMarkIcon = false }) {
    const color = getOptionColor({ options, data, customProperties });

    if (!data || typeof data !== 'string') return null;

    return (
        <div
            className={classNames('inline-flex w-fit relative p-[1px] rounded h-fit', {
                'border-[2px]': state !== defaultState.NORMAL,
                'border-brightRed': state === defaultState.DELETE,
                'border-shamRock': state === defaultState.UPDATE
            })}
        >
            <Option
                size="small"
                className={'w-full max-w-[200px]'}
                lineThrough={state === defaultState.DELETE}
                label={data}
                color={color}
            />
            {state === defaultState.UPDATE && (
                <span className={'absolute -top-1 -right-1 flex'}>{!isHideMarkIcon && <Plus />}</span>
            )}
            {state === defaultState.DELETE && (
                <span className={'absolute -top-1 -right-1 flex'}>{!isHideMarkIcon && <Minus />}</span>
            )}
        </div>
    );
}

export default React.memo(OptionDiffCheck);
