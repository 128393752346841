import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';
import Divider from 'components/divider/Base';
import IOSSwitch from 'components/switches/IOS';
import { DISABLED_OPACITY, INPUT_QUICK_FILTER_HEIGHT, INPUT_RADIUS } from 'const/style';
import { useDispatch } from 'react-redux';
import * as databaseActions from 'databases/actions';
import { useSchduleBackup } from 'hooks/database';

import TimePicker from './TimePicker';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '16px 0',
        minWidth: 440
    },
    closeIcon: {
        height: 10,
        width: 10,
        '&:hover:': {
            backgroundColor: theme.colors.hoverItem
        }
    },
    marginTop: {
        marginTop: 24
    },

    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },

    datetime: {
        padding: '10px 12px',
        height: INPUT_QUICK_FILTER_HEIGHT,
        maxWidth: '94px',
        width: 'fix-content',
        fontSize: `13px !important`,
        color: theme.colors.primaryText,
        borderRadius: INPUT_RADIUS,
        border: `1px solid ${theme.buttonColors.outlined.border} !important`,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main} !important`,
            outline: 'none'
        },
        '&::placeholder': {
            color: `${theme.colors.midGrey} !important`
        }
    }
}));

function ScheduleModal({ onClose, dbId }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const scheduleBackupData = useSchduleBackup() || null;

    const { t } = useTranslation();
    const [startDate, setStartDate] = React.useState(scheduleBackupData?.scheduledTime);
    const [checked, setChecked] = React.useState(true);

    const onChange = () => {
        setChecked(!checked);
        setStartDate(scheduleBackupData?.scheduledTime);
    };

    const createScheduleBackup = () => {
        if (checked) {
            const body = { enabled: checked, scheduledTime: startDate };
            dispatch(databaseActions.createScheduleBackupDatabaseAction({ dbId, body }));
        } else {
            const body = { enabled: checked, scheduledTime: null };
            dispatch(databaseActions.createScheduleBackupDatabaseAction({ dbId, body }));
        }

        onClose();
    };

    const convertTime = time => {
        const dataConvert = new Date(new Date(time).getTime() - new Date(time).getTimezoneOffset() * 60 * 1000);
        setStartDate(dataConvert.toISOString());
    };

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item style={{ marginBottom: 32, padding: '0 16px' }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4">{t(`backup_schedule_setting`)}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIconSVG className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item style={{ marginBottom: 32, padding: '0 16px' }}>
                <Grid item container justify="space-between" alignItems="center" style={{ marginBottom: 12 }}>
                    <Grid item>
                        <Typography variant="body5" style={{ fontWeight: 'bolder', marginBottom: 16 }}>
                            {t(`scheduled_backup`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IOSSwitch onChange={onChange} checked={checked} />
                    </Grid>
                </Grid>
                {!checked && (
                    <Grid item style={{ marginBottom: 10 }}>
                        <Typography variant="body6" style={{ color: '#EB4F5E', fontSize: 10 }}>
                            Daily database backup has been stopped. Please enable it to keep data safe in case of
                            failure.
                        </Typography>
                    </Grid>
                )}

                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    style={{ pointerEvents: !checked ? 'none' : '', cursor: !checked ? 'no-drop' : '' }}
                >
                    <Grid item>
                        <Typography
                            variant="body5"
                            style={{ fontWeight: 'normal', marginBottom: 16, color: checked ? '#2A293A' : '#ACADB9' }}
                        >
                            {t(`automatically_backup_at`)}
                        </Typography>
                    </Grid>
                    <Grid item style={{ position: 'relative' }}>
                        <TimePicker
                            onChange={date => {
                                convertTime(date);
                            }}
                            defaultValue={startDate}
                            checked={checked}
                            style={{ color: checked ? '#2A293A' : '#ACADB9' }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Divider />
            <Grid item style={{ marginTop: 24, padding: '0 16px' }}>
                <Grid container spacing={2} justify="space-between" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" size="small" width={130} onClick={onClose}>
                            {t(`back_up_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" width={130} onClick={createScheduleBackup}>
                            {t(`back_up_save`)}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ScheduleModal);
