import { useCompanyUsage, useCompanyUsageValueByProperty } from '../dataCollector';
import { PLAN_TYPES, USAGE_PROPERTIES } from 'const';
import { useCompanySubscription } from './subscription';
import { useCurrentSubscriptionIs } from 'hooks/payment';
import { useIsShareViewLink, useSelfHostingLicense } from 'hooks/app';
import { useGridTotalRecords } from 'hooks/gridUI';
import { isExternal } from 'config';

export function useUsageLimit() {
    const currentSubscription = useCompanySubscription();
    return currentSubscription?.thresholdUsage;
}

export function useIsOverLimitExceeded() {
    const usageLimit = useUsageLimit();
    const usageDataCollector = useCompanyUsage();

    if (!usageLimit || !usageDataCollector) {
        return false;
    }
    let isOver = false;
    const keys = Object.keys(usageDataCollector);
    for (let key of keys) {
        const actualValue = usageDataCollector?.[key];
        const defaultValue = usageLimit?.[key];

        if (key === USAGE_PROPERTIES.BRANCH_RECORDS_PER_COMPANY) continue;
        if (
            typeof actualValue === 'number' &&
            typeof defaultValue === 'number' &&
            actualValue > defaultValue &&
            defaultValue !== -1
        ) {
            isOver = true;
            break;
        }
    }
    return isOver;
}

export function useIsOverThresHoldProperty(property) {
    const usageLimit = useUsageLimit();
    const usageDataCollector = useCompanyUsage();

    if (!usageLimit || !usageDataCollector) {
        return false;
    }
    const defaultValue = usageLimit?.[property];
    const actualValue = usageDataCollector?.[property];
    if (typeof actualValue !== 'number') return false;
    return defaultValue < 0 ? false : actualValue > defaultValue;
}

export function useThresHoldValueByProperty(property) {
    const usageLimit = useUsageLimit();
    const usageDataCollector = useCompanyUsage();

    if (!usageLimit || !usageDataCollector) {
        return { defaultValue: 0, actualValue: 0 };
    }

    const defaultValue = usageLimit?.[property];
    const actualValue = usageDataCollector?.[property];
    return {
        defaultValue,
        actualValue
    };
}

export function useDefaultThresHoldValueByProperty(property) {
    const usageLimit = useUsageLimit();

    if (!usageLimit) {
        return 0;
    }
    const usageValue = usageLimit?.[property];
    return typeof usageValue === 'number' ? (usageValue < 0 ? Number.MAX_SAFE_INTEGER : usageValue) : usageValue;
}

export function useIsOverRecords() {
    const gridTotalRecords = useGridTotalRecords();
    const defaultValue = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.MAX_RECORD);

    if (typeof gridTotalRecords !== 'number') return false;
    return defaultValue < 0 ? false : gridTotalRecords > defaultValue;
}

export function useIsOverBranchRecords() {
    const branchLimitX = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.BRANCH_LIMIT_X);
    const maxRecord = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.MAX_RECORD);
    const limitBranchRecord = branchLimitX * maxRecord || 0;
    const brandRecordUsage = useCompanyUsageValueByProperty(USAGE_PROPERTIES.BRANCH_RECORDS_PER_COMPANY);

    return brandRecordUsage > limitBranchRecord;
}

export function useIsCompanyHasTMPermission() {
    const translationMemory = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.TRANSLATION_MEMORY);
    return translationMemory;
}

export function useIsMemberSeatExceeded(numberSeatToAdd) {
    const companySubscription = useCompanySubscription();
    const usageLimit = useUsageLimit();
    return numberSeatToAdd + companySubscription?.totalSeat > usageLimit?.[USAGE_PROPERTIES.FREE_USERS];
}

export function useCanViewGroupShare() {
    return useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.VIEW_GROUP_SHARE);
}

export function useCanCustomGroup() {
    return useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.CUSTOM_GROUP);
}

export function useCanCustomUserRole() {
    return useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.CUSTOM_USER_ROLE);
}

export function useCanVersionControl() {
    return useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.VERSION_CONTROL);
}

export function useIsFreePlanAndSeatExceeded() {
    const isMemberSeatExceeded = useIsMemberSeatExceeded(0);
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    return isMemberSeatExceeded && isFreePlan;
}

export function useIsUserBlockedGridByPayment() {
    const selfHostingLicense = useSelfHostingLicense();
    const isShareViewLink = useIsShareViewLink();
    const isOverRecord = useIsOverRecords();
    const isFreePlanAndSeatExceeded = useIsFreePlanAndSeatExceeded();
    const isInactiveSelfHostingLicense = isExternal && selfHostingLicense?.status !== 'ACTIVE';
    return isShareViewLink ? false : isOverRecord || isFreePlanAndSeatExceeded || isInactiveSelfHostingLicense;
}

export function useTranslationImportExport() {
    return useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.TRANSLATION_IMPORT_EXPORT);
}
