import React from 'react';
import { Grid, Typography, CircularProgress, Divider, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNotInvitedMembersInSelectedGroup } from 'hooks/permission/member';
import ButtonBase from 'components/button/Base';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import * as memberActions from 'permission/actions/member';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import LDBaseCardInfo from 'components/selects/LDBaseCardInfo';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import { useSelectedGroupId } from 'hooks/permission';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 500,
        borderRadius: 6,
        background: theme.colors.white
    },
    modalContent: {
        padding: theme.spacing(5)
    },
    controls: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    spb1: {
        marginBottom: theme.spacing(1)
    },
    spb4: {
        marginBottom: theme.spacing(4)
    },
    spb3: {
        marginBottom: theme.spacing(3)
    },
    textGrey: {
        color: theme.colors.midGrey
    }
}));

function InviteMemberToGroup({ handleClose }) {
    const classes = useStyles();
    const selectedGroupId = useSelectedGroupId();
    const members = useNotInvitedMembersInSelectedGroup();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [invitedUsers, setInvitedUsers] = React.useState([]);
    const [isInviting, setIsInviting] = React.useState(false);

    const memberList = React.useMemo(() => {
        return members?.map(user => ({
            ...user,
            value: user?.email,
            label: user?.fullName,
            icon: () => <AvatarBase src={getAvatarUrl(user?.imageUrl)} size="small" alt="avatar" />,
            subTitle: user?.email
        }));
    }, [members]);

    React.useEffect(() => {
        dispatch(
            memberActions.fetchMembers({
                successCallback: () => {
                    console.log('fetch members successfully');
                },
                errorCallback: () => {
                    console.log('failed  to fetch members');
                }
            })
        );
    }, [dispatch]);

    const handleUserOptionsChange = (users = []) => {
        const mapOptions = users.map(user => ({
            ...user,
            icon: () => <AvatarBase src={getAvatarUrl(user?.imageUrl)} size={24} alt="avatar" />
        }));
        setInvitedUsers(mapOptions);
    };

    const handleCancel = () => {
        handleClose && handleClose();
    };

    const handleInvite = () => {
        sendManualTrack({ type: `Add Member To Group` });
        setIsInviting(true);

        dispatch(
            memberActions.addMembersToGroup({
                groupId: selectedGroupId,
                userIds: invitedUsers.map(u => u.id),
                successCallback: message => {
                    setIsInviting(false);
                    console.log('successfully add members to group');
                    handleCancel();
                },
                errorCallback: () => {
                    console.log('failed to add members to group');
                    setIsInviting(false);
                }
            })
        );
    };

    return (
        <Grid direction="column" container className={classes.root}>
            <Grid item>
                <Grid container direction="column" className={classes.modalContent}>
                    <Grid item className={classes.spb4}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h3">{t('invite_member_to_group_title')}</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton size="medium" onClick={handleClose}>
                                    <CloseIconSVG size={24} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.spb3}>
                        <Typography variant="body2">{t('invite_member_to_group_add_existing_members')}</Typography>
                        <Typography variant="body2" display="inline">
                            {t('invite_member_to_group_if_not_in_the_company')}
                        </Typography>{' '}
                        <Typography variant="body1" className={classes.textGrey} display="inline">
                            {t('invite_member_to_group_invite_member')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item className={classes.spb1}>
                                <Typography variant="body1">{t('invite_member_to_group_select_members')}</Typography>
                            </Grid>
                            <Grid item>
                                <LDBaseCardInfo
                                    ddPlaceholder={t('add_members')}
                                    menuPlaceholder={t('find_group_member_placeholder')}
                                    options={memberList}
                                    onChange={handleUserOptionsChange}
                                    defaultValue={invitedUsers}
                                    isMulti
                                    isSearchValueLabel={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={classes.divider}>
                <Divider />
            </Grid>
            <Grid item>
                <Grid container item className={classes.controls} spacing={2} justify="flex-end" alignItems="center">
                    <Grid item onClick={handleCancel}>
                        <ButtonBase width={120} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase
                                onClick={handleInvite}
                                width={120}
                                disabled={isEmpty(invitedUsers) || isInviting}
                                variant="contained"
                            >
                                {t('global_add')}
                            </ButtonBase>
                            {isInviting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(InviteMemberToGroup);
