import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoGridlySVG from 'assets/images/svg/LogoGridlySVG';
import AuthBgIconLeftSVG from 'assets/images/auth-bkg-icon-left.svg';
import AuthBgIconCenterSVG from 'assets/images/auth-bkg-icon-center.svg';
import AuthBgIconRightSVG from 'assets/images/auth-bkg-icon-right.svg';
import useMediaQueries from 'hooks/useMediaQueries';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        position: 'relative',
        height: '100vh'
    },
    header: {
        position: 'absolute',
        top: 50,
        left: 60
    },
    footer: {
        width: '100%',
        height: '20vh',
        maxHeight: 200,
        //overflow: 'hidden',
        position: 'absolute',
        left: 0,
        bottom: 0,
        '& .bg-icon': {
            backgroundSize: 'cover'
        },

        '& .left': {
            width: 250,
            height: 180,
            background: `url(${AuthBgIconLeftSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        '& .center': {
            width: 568,
            height: 135,
            background: `url(${AuthBgIconCenterSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        '& .right': {
            width: 248,
            height: 183,
            background: `url(${AuthBgIconRightSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },

        [theme.breakpoints.down('sm')]: {
            bottom: 20
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}));

function TwoFactorAuthSetup({ children }) {
    const { isMobile } = useMediaQueries();
    const classes = useStyles();

    return (
        <Grid className={classes.root} container>
            <LogoGridlySVG className={classes.header} />
            {children}
            {!isMobile && (
                <Grid
                    item
                    className={classes.footer}
                    container
                    direction={'row'}
                    justify="space-between"
                    alignItems="flex-end"
                    wrap="nowrap"
                >
                    <Grid item className="bg-icon left"></Grid>
                    <Grid item className="bg-icon center"></Grid>
                    <Grid item className="bg-icon right"></Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default TwoFactorAuthSetup;
