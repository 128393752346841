import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useTheme } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function PlanBenefitItem({ description }) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Grid className={classes.root} wrap="nowrap" container spacing={2}>
            <Grid item>
                <CheckIconSVG color={theme.colors.atlantis} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{description}</Typography>
            </Grid>
        </Grid>
    );
}

export default React.memo(PlanBenefitItem);
