import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import ListItem from 'components/list/Item';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { DATA_QUERY_OPTIONS, MAX_SELECTION_RECORDS, RANGE_TYPES } from 'const/gridUI';
import { useAllPendingColumnIds, useIsTmDisabled } from 'hooks/gridUI';
import store from 'store/configStore';
import { isShowApproveTmStatusByRange } from 'utils/gridUI/tm';
import {
    isShowMarkAsOutOfDateByRange,
    isShowMarkAsUnsetByRange,
    isShowMarkAsUpToDateByRange
} from 'utils/gridUI/dependency';
import { enqueueSnackbar } from 'notifier/actions';
import Spinner from 'components/spinner/Base';
import Single from './Single';
import Multi from './Multi';
import { checkContainId } from 'utils/clickAway';

function CellContextMenu({
    onClose,
    handleClickAway,
    t,
    rowStartIndex,
    rowStopIndex,
    columnStartIndex,
    columnStopIndex,
    onRePosContext,
    selectedCellData
}) {
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const pendingColumnIds = useAllPendingColumnIds();
    const isTmDisabled = useIsTmDisabled();
    const [rangeData, setRangeData] = React.useState({});
    const [recordIds, setRecordIds] = React.useState([]);
    const [columnIds, setColumnIds] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const { getState } = store;
        const { gridUI, auth } = getState();
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const {
                    data,
                    recordIds: rangeRecordIds,
                    columnIds: rangeColumnIds,
                    isOverRecordLimit,
                    totalSelectedRecords
                } = await gridUIActions.getRangeData({
                    gridUI,
                    auth,
                    dataOptions: [
                        DATA_QUERY_OPTIONS.COLOR,
                        DATA_QUERY_OPTIONS.TM,
                        DATA_QUERY_OPTIONS.DEPENDENCY_STATUS,
                        DATA_QUERY_OPTIONS.DATA,
                        DATA_QUERY_OPTIONS.SOURCE_STATUS
                    ],
                    type: RANGE_TYPES.INDEX
                });
                setIsLoading(false);
                if (isOverRecordLimit) {
                    dispatch(
                        enqueueSnackbar({
                            type: 'info',
                            message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                        })
                    );
                    handleClickAway();
                    return;
                }

                setRangeData(data);
                setRecordIds(rangeRecordIds);
                setColumnIds(rangeColumnIds);
                setTimeout(() => {
                    onRePosContext();
                }, 0);
            } catch (err) {
                setIsLoading(false);
                handleClickAway();
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const isShowApproveTMStatus = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        if (isTmDisabled) return false;
        return isShowApproveTmStatusByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, isTmDisabled, recordIds, pendingColumnIds]);

    const isShowMarkAsOutOfDate = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsOutOfDateByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowMarkAsUnset = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsUnsetByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isShowMarkAsUpToDate = React.useMemo(() => {
        const affectedColumnIds = columnIds?.filter(columnId => !pendingColumnIds?.includes(columnId));
        return isShowMarkAsUpToDateByRange({ data: rangeData, recordIds, columnIds: affectedColumnIds });
    }, [rangeData, columnIds, recordIds, pendingColumnIds]);

    const isContextMultiple = React.useMemo(() => {
        return recordIds?.length > 1 || columnIds?.length > 1;
    }, [recordIds, columnIds]);

    useClickAwaitListener(rootRef, e => {
        if (checkContainId(e, 'portal-mention-users')) {
            return false;
        }
        if (checkContainId(e, 'portal-popper')) {
            return false;
        }
        handleClickAway(e);
    });

    return (
        <Grid ref={rootRef} className={'relative'} container direction={'column'}>
            {isLoading && (
                <Grid item>
                    <ListItem icon={<Spinner size={18} thick={3} className="mr-2" />} name={'loading...'} />
                </Grid>
            )}
            {!isContextMultiple && !isLoading && (
                <Single
                    recordId={recordIds?.[0]}
                    columnId={columnIds?.[0]}
                    rowIndex={rowStartIndex}
                    columnIndex={columnStartIndex}
                    isShowMarkAsUpToDate={isShowMarkAsUpToDate}
                    isShowMarkAsOutOfDate={isShowMarkAsOutOfDate}
                    isShowMarkAsUnset={isShowMarkAsUnset}
                    isShowApproveTMStatus={isShowApproveTMStatus}
                    onClose={onClose}
                    onRePosContext={onRePosContext}
                    selectedCellData={selectedCellData}
                />
            )}
            {isContextMultiple && !isLoading && (
                <Multi
                    recordIds={recordIds}
                    isShowMarkAsUpToDate={isShowMarkAsUpToDate}
                    isShowMarkAsOutOfDate={isShowMarkAsOutOfDate}
                    isShowMarkAsUnset={isShowMarkAsUnset}
                    isShowApproveTMStatus={isShowApproveTMStatus}
                    columnIds={columnIds}
                    onClose={onClose}
                    onRePosContext={onRePosContext}
                />
            )}
        </Grid>
    );
}

export default React.memo(CellContextMenu);
