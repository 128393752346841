import { useSelector } from 'react-redux';
import { useData } from './data';
import { useMetaData } from './metaData';
import { useDisabledColumns, useDisabledSourceColumns, useProcessingColumns } from './column';
import { getCellData, getCellValueOnly } from 'utils/gridUI/cell';
import { PREVIEW_IMAGE_TYPES, SOURCE_STATUS } from 'const/gridUI';
import { useRecordHistory } from './recordHistory';

export function useIsCellPreview() {
    return useSelector(state => !!state.gridUI.cellPreview);
}

export function useCurrentPreviewData() {
    return useSelector(state => state.gridUI.cellPreview);
}

export function useCurrentDefaultPreviewFile() {
    return useSelector(state => state.gridUI.cellPreview?.defaultPreviewFile);
}

export function useCurrentPreviewType() {
    return useSelector(state => state.gridUI.cellPreview?.type);
}

export function useCellPreviewData() {
    const metaData = useMetaData();
    const data = useData();
    const previewData = useCurrentPreviewData();
    const type = useCurrentPreviewType();
    const recordHistory = useRecordHistory();
    const disabledColumnIds = useDisabledColumns();
    const disabledSourceColumnIds = useDisabledSourceColumns();
    const processingColumnIds = useProcessingColumns();

    const rowId = previewData?.rowId;
    const columnId = previewData?.columnId;
    const column = metaData?.[columnId];

    if (!type) return null;

    //return data of record history
    if (type === PREVIEW_IMAGE_TYPES.RECORD_HISTORY) {
        const rowDataPerIndex = recordHistory?.[rowId];
        const cellData = rowDataPerIndex?.[`cellHistories`]?.[columnId];
        const currentData = cellData?.data?.filter(c => c?.id) || [];
        const previousData = cellData?.previousData?.filter(c => c?.id) || [];

        return [...currentData, ...previousData?.filter(pc => !currentData?.map(c => c?.id)?.includes(pc?.id))];
    }

    const cellData = getCellData({ data, rowId, columnId });
    const isStatusLocked = cellData?._sourceStatus === SOURCE_STATUS.LOCKED;
    const isEditable =
        ![...disabledColumnIds, ...processingColumnIds, ...disabledSourceColumnIds]?.includes(columnId) &&
        column?.editable;

    return getCellValueOnly({ data, rowId, columnId }).map(el => ({
        ...el,
        isReadOnly: isStatusLocked || !isEditable
    }));
}

export function useIsPreviewSelectionRangeFileData() {
    return useSelector(state => state.gridUI.isPreviewSelectionRangeFileData);
}

export function useSelectionRangeFileData() {
    return useSelector(state => state.gridUI.selectionRangeFileData);
}

export function useIsFetchingSelectionRangeFileData() {
    return useSelector(state => state.gridUI.isFetchingSelectionRangeFileData);
}
