import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useMemberList } from 'hooks/permission';
import Avatar from 'components/avatar/User';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getAvatarUrl } from 'utils/images';
import { getStartDateFormat } from 'utils/datetime';
import TimeSelect from 'components/selects/TimeSelect';
import { AutoSizer } from 'react-virtualized-dn';
import Select from 'components/selects/Select';
import { useRef } from 'react';
import { tbTermStatusOptions } from 'const/tb';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    selectClassName: {
        '& .select-input': {
            fontWeight: 400
        }
    }
}));

const optionAllUser = {
    value: undefined,
    label: 'All users'
};

const timeDropDownOptions = [
    { label: i18n.t('global_all'), value: undefined },
    { label: i18n.t('global_today'), value: getStartDateFormat({ offset: 0, unit: 'd' }) },
    { label: i18n.t('global_yesterday'), value: getStartDateFormat({ offset: 1, unit: 'd' }) },
    { label: i18n.t('global_7days'), value: getStartDateFormat({ offset: 7, unit: 'd' }) },
    { label: i18n.t('global_15days'), value: getStartDateFormat({ offset: 15, unit: 'd' }) },
    { label: i18n.t('global_30days'), value: getStartDateFormat({ offset: 30, unit: 'd' }) },
    { label: i18n.t('global_3months'), value: getStartDateFormat({ offset: 3, unit: 'M' }) },
    { label: i18n.t('global_6months'), value: getStartDateFormat({ offset: 6, unit: 'M' }) },
    { label: i18n.t('global_12months'), value: getStartDateFormat({ offset: 12, unit: 'M' }) }
];

const TBDetailFilter = ({ columnKey, handleChangeFilterOption, defaultValue, auditors }) => {
    const classes = useStyles();
    const normalMemberList = useMemberList();
    const initRef = useRef();
    const [userOption, setUserOption] = useState({ ...optionAllUser });
    const [timeOption, setTimeOption] = useState(
        defaultValue ? timeDropDownOptions.find(el => el.value === defaultValue) : timeDropDownOptions[0]
    );
    const [statusOption, setStatusOption] = useState(
        defaultValue ? tbTermStatusOptions.find(el => el.value === defaultValue) : tbTermStatusOptions[0]
    );

    const membersOption = useMemo(() => {
        const auditorIds = auditors.split(',');
        if (!auditorIds.length) return [{ ...optionAllUser }];
        const members = (normalMemberList || [])
            .map(m => {
                if (auditorIds.includes(m.id)) {
                    return {
                        value: m.userId,
                        label: m.fullName,
                        icon: () => (
                            <Avatar src={getAvatarUrl(m.id)} size={20} color="#DBDCE1">
                                <AvatarIconSVG />
                            </Avatar>
                        )
                    };
                }
                return undefined;
            })
            .filter(el => !!el);
        return [{ ...optionAllUser }, ...members];
    }, [auditors, normalMemberList]);

    const handleMemberChange = useCallback(
        option => {
            if (userOption?.value !== option.value) {
                setUserOption(option);
                handleChangeFilterOption({ key: columnKey, value: option.value });
            }
        },
        [columnKey, handleChangeFilterOption, userOption]
    );

    const handleTimeChange = useCallback(
        (e, option) => {
            if (timeOption?.value !== option.value) {
                setTimeOption(option);
                handleChangeFilterOption({ key: columnKey, value: option.value });
            }
        },
        [columnKey, handleChangeFilterOption, timeOption]
    );

    const handleStatusChange = useCallback(
        option => {
            if (statusOption?.value !== option.value) {
                setStatusOption(option);
                handleChangeFilterOption({ key: columnKey, value: option.value });
            }
        },
        [columnKey, handleChangeFilterOption, statusOption]
    );

    useEffect(() => {
        if (defaultValue && !initRef.current) {
            switch (columnKey) {
                case 'createdBy':
                case 'alteredBy': {
                    initRef.current = true;
                    setUserOption(membersOption.find(el => el.value === defaultValue));
                    break;
                }
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnKey, membersOption]);

    switch (columnKey) {
        case 'createdBy':
        case 'alteredBy': {
            return (
                <LDBasePortal
                    ddPlaceholder={'Select an user'}
                    menuPlaceholder={'Find an user'}
                    options={membersOption}
                    onChange={handleMemberChange}
                    defaultValue={userOption}
                    placement={columnKey === 'alteredBy' ? 'bottom-end' : 'bottom-start'}
                    popperStyle={{
                        width: 260
                    }}
                />
            );
        }
        case 'createdTime':
        case 'alteredTime': {
            return (
                <AutoSizer style={{ height: '100%' }}>
                    {({ width, height }) => {
                        return (
                            <TimeSelect
                                width={width}
                                options={timeDropDownOptions}
                                selected={timeOption}
                                onChange={handleTimeChange}
                            />
                        );
                    }}
                </AutoSizer>
            );
        }

        case 'status': {
            return (
                <Select
                    options={tbTermStatusOptions}
                    value={statusOption.value}
                    className={classes.selectClassName}
                    border
                    onChange={handleStatusChange}
                />
            );
        }

        default: {
            return null;
        }
    }
};

export default React.memo(TBDetailFilter);
