import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useDispatch } from 'react-redux';
import { BOOLEAN } from 'const/columnTypes';
import ThreeStatesCheckbox from 'components/checkbox/ThreeStateCheckBox';
import { getBooleanValue } from 'utils/gridUI/formatData';
import { OPERATOR } from 'gridUI/conditions';

const useStyles = makeStyles(theme => ({
    root: {},
    checkbox: {
        position: 'relative',
        left: 12
    }
}));

function CheckboxForPresent({ columnId, isSelectionActive, quickFilters, checkboxStyle = {} }) {
    const filterData = quickFilters[columnId];
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = (e, nextState) => {
        dispatch(
            advancedSearchActions.QuickFilterChange({
                columnId,
                value: getBooleanValue(nextState),
                type: BOOLEAN,
                currentState: nextState,
                operator: OPERATOR.equal
            })
        );
        dispatch(advancedSearchActions.resetCellStatus());
        dispatch(
            advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    return (
        <Grid className={classes.root} container justify="center">
            <ThreeStatesCheckbox
                className={classes.checkbox}
                state={(filterData && filterData.currentState) || 0}
                onChange={handleChange}
                style={checkboxStyle}
            />
        </Grid>
    );
}

export default React.memo(CheckboxForPresent);
