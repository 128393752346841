import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { WORK_SPACE_HEIGHT, INPUT_RADIUS, POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM } from 'const/style';

import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import WorkspacePopup from './WorkspacePopup';
import { useDispatch } from 'react-redux';
import * as workspaceActions from './actions';
import * as appActions from 'app/actions';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import { useHistory } from 'react-router-dom';
import * as roleConst from 'auth/roleConst';
import PopperMenu from 'components/menus/Popper';
import WorkspaceDeleteConfirm from './WorkspaceDeleteConfirm';
import { useRole } from 'hooks/auth/role';
import useMediaQueries from 'hooks/useMediaQueries';
import Dialog from 'components/dialog/Dialog';
import { sendManualTrack } from 'tracker';
import ShareProject from './ShareProject';
import { useSelectedWorkspaceId } from 'hooks/workspace';
import DraggingSVG from 'assets/images/svg/DraggingSVG';
import OverflowTypography from 'components/textTruncated/OverflowTypography';
import { _removeFavoriteGridByProjectId } from 'grids/actions';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1000
    },
    listItem: {
        height: WORK_SPACE_HEIGHT,
        borderRadius: INPUT_RADIUS,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    active: {
        background: theme.colors.hoverItem
    },
    editor: {
        position: 'absolute',
        zIndex: 10,
        top: 35,
        left: 0,
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    },
    nameWrapper: {
        flex: 1
    },
    name: {
        fontSize: '0.875rem',
        width: 140,
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        ...theme.ellipsis(1)
    },
    dropDownIcon: {
        cursor: 'pointer',
        display: 'flex',
        minWidth: 32,
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center'
    },
    dragSVG: {
        verticalAlign: 'middle',
        marginRight: 6
    },
    pdl8: {
        paddingLeft: 8
    }
}));

const WorkspaceItem = ({ contextMenuId, id, workspace, name, active, isShowLastPopupCreate, isScrolling }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const { isMobile } = useMediaQueries();
    const dispatch = useDispatch();
    const selectedWorkspaceId = useSelectedWorkspaceId();

    const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = React.useState(false);
    const [isOpenShareProject, setIsOpenShareProject] = React.useState(false);
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const roles = useRole();
    const accessManageWorkspace = roles[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE];
    const fullAccessMangeWorkspace = accessManageWorkspace === roleConst.FULL;
    const rootRef = React.useRef();

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `WORKSPACE_CONTEXT_MENU_ID_${id}`;
    }, [id]);

    const handleCloseDeleteConfirm = () => {
        setIsOpenDeleteConfirm(false);
    };

    const handleOpenDeleteConfirm = e => {
        e.stopPropagation();
        e.preventDefault();
        handleClickAway();
        setIsOpenDeleteConfirm(true);
    };

    const handleCloseShareProject = () => {
        setIsOpenShareProject(false);
    };

    const handleOpenShareProject = e => {
        handleClickAway();
        setIsOpenShareProject(true);
    };

    const deleteWorkspaceHandler = e => {
        sendManualTrack({ type: `Delete Project` });
        e.stopPropagation();
        e.preventDefault();
        dispatch(
            workspaceActions.deleteWorkspace({
                workspaceId: workspace.id,
                successCallback: () => {
                    console.log('delete success');
                    dispatch(_removeFavoriteGridByProjectId({ projectId: workspace.id }));
                    if (selectedWorkspaceId === workspace.id) {
                        history.push(`/projects`);
                    }
                },
                errorCallback: error => {
                    console.log('error delete');
                }
            })
        );
    };

    React.useEffect(() => {
        if ((contextMenuId && contextMenuId !== CONTEXT_MENU_ID) || (isScrolling && !isMobile)) {
            handleClickAway();
        }
    }, [contextMenuId, CONTEXT_MENU_ID, isScrolling, isMobile]);

    const handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClickAway = () => {
        setIsShowMore(false);
        setAnchorEl(null);
    };

    const changeWorkspaceHandler = workspaceId => {
        if (!active) {
            dispatch(workspaceActions.changeSelectedGroupId({ groupId: null }));
            history.push(`/projects/${workspaceId}`);
        }
    };

    // React.useEffect(() => {
    //     if (isShowMore && isShowLastPopupCreate) {
    //         let $arrow = document.getElementById(`workspace_more_${id}`);
    //         if ($arrow) {
    //             setTimeout(() => {
    //                 $arrow.click();
    //                 dispatch(workspaceActions.turnOffLastWorkspacePopup());
    //             }, 50);
    //         }
    //     }
    // }, [isShowMore, isShowLastPopupCreate, id, dispatch]);

    React.useEffect(() => {
        if (isShowLastPopupCreate) {
            setIsShowMore(true);
        }
    }, [isShowLastPopupCreate]);

    React.useEffect(() => {
        if (active) {
            const el = rootRef.current;
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [active]);

    const handleOnContextClick = e => {
        if (!fullAccessMangeWorkspace) return;
        dispatch(appActions.changeContext({ contextMenuId: CONTEXT_MENU_ID }));
        handleClick(e);
    };

    return (
        <Grid
            ref={rootRef}
            item
            onMouseOver={() => setIsShowMore(true)}
            onMouseLeave={() => setIsShowMore(false)}
            className={`${classes.root} wsItem`}
            onClick={() => changeWorkspaceHandler(id)}
            onContextMenu={handleOnContextClick}
        >
            <Grid
                className={`${classes.listItem} ${active ? classes.active : ''} ${
                    fullAccessMangeWorkspace ? classes.pdl8 : ''
                }`}
                container
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
            >
                <Grid item>
                    <DraggingSVG color="#93929E" className={classes.dragSVG} />
                </Grid>
                <Grid item className={classes.nameWrapper}>
                    <Grid container spacing={0} alignItems="center" wrap="nowrap">
                        <Grid item style={{ marginRight: 8, position: 'relative', top: 2 }}>
                            <WorkspaceSVG color={active ? theme.palette.primary.main : undefined} />
                        </Grid>
                        <Grid item>
                            <Grid item>
                                <OverflowTypography
                                    className={`${classes.name} wsName`}
                                    variant={active ? 'body1' : 'body2'}
                                >
                                    {name}
                                </OverflowTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {fullAccessMangeWorkspace && (
                    <Grid
                        id={`workspace_more_${id}`}
                        item
                        className={`${classes.dropDownIcon} dropdown-icon`}
                        onClick={handleClick}
                        tr-dt="Open Edit Workspace"
                    >
                        {(isShowMore || active || anchorEl) && <IconMoreActionsSVG color={theme.colors.steel} />}
                    </Grid>
                )}
            </Grid>
            {anchorEl &&
                (isMobile ? (
                    <div className={classes.editor}>
                        <WorkspacePopup
                            workspace={workspace}
                            handleClickAway={handleClickAway}
                            onDeleteClick={handleOpenDeleteConfirm}
                            onShareGroupClick={handleOpenShareProject}
                        />
                    </div>
                ) : (
                    <PopperMenu anchorEl={anchorEl} className={classes.popper}>
                        <WorkspacePopup
                            workspace={workspace}
                            handleClickAway={handleClickAway}
                            onDeleteClick={handleOpenDeleteConfirm}
                            onShareGroupClick={handleOpenShareProject}
                        />
                    </PopperMenu>
                ))}
            <Dialog open={isOpenShareProject} onClose={handleCloseShareProject}>
                <ShareProject project={workspace} onClose={handleCloseShareProject} />
            </Dialog>
            <Dialog open={isOpenDeleteConfirm} onClose={handleCloseDeleteConfirm}>
                <WorkspaceDeleteConfirm
                    onClose={handleCloseDeleteConfirm}
                    projectName={workspace?.name}
                    onConfirm={deleteWorkspaceHandler}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(WorkspaceItem);
