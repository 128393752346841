import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    sortBy: {
        paddingRight: 18,
        marginLeft: 8,
        position: 'relative',
        '&:before': {
            border: '5px solid transparent',
            content: "''",
            display: 'block',
            height: 0,
            right: 5,
            top: '50%',
            position: 'absolute',
            width: 0,
            borderBottomColor: '#dbdce1',
            marginTop: '-10px'
        },
        '&:after': {
            border: '5px solid transparent',
            content: "''",
            display: 'block',
            height: 0,
            right: 5,
            top: '50%',
            position: 'absolute',
            width: 0,
            borderTopColor: '#dbdce1',
            marginTop: 2
        }
    },

    asc: {
        '&::before': {
            borderBottomColor: '#7869b9'
        }
    },

    desc: {
        '&::after': {
            borderTopColor: '#7869b9'
        }
    }
}));

const SortIcon = ({ type }) => {
    const classes = useStyles();

    const iconClasses = `${classes.sortBy} ${type === 'asc' ? classes.asc : ''} ${type === 'desc' ? classes.desc : ''}`;

    return <span className={iconClasses} />;
};

export default React.memo(SortIcon);
