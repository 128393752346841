import React from 'react';

function StatisticSettingSVG({ size = 20, color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8333 16.75C16.3396 16.75 16.75 16.3396 16.75 15.8333L16.75 4.16667C16.75 3.66041 16.3396 3.25 15.8333 3.25L4.16667 3.25C3.66041 3.25 3.25 3.66041 3.25 4.16667L3.25 15.8333C3.25 16.3396 3.66041 16.75 4.16667 16.75L15.8333 16.75ZM18.25 15.8333C18.25 17.168 17.168 18.25 15.8333 18.25L4.16667 18.25C2.83198 18.25 1.75 17.168 1.75 15.8333V4.16667C1.75 2.83198 2.83198 1.75 4.16667 1.75H15.8333C17.168 1.75 18.25 2.83198 18.25 4.16667L18.25 15.8333ZM14.9165 14.1667C14.9165 14.5809 14.5807 14.9167 14.1665 14.9167H11.6665C11.2523 14.9167 10.9165 14.5809 10.9165 14.1667L10.9165 6.66667C10.9165 6.25246 11.2523 5.91667 11.6665 5.91667L14.1665 5.91667C14.5807 5.91667 14.9165 6.25246 14.9165 6.66667L14.9165 14.1667ZM13.4165 13.4167L13.4165 7.41667H12.4165L12.4165 13.4167H13.4165ZM8.3335 14.9167C8.74771 14.9167 9.0835 14.5809 9.0835 14.1667L9.0835 10C9.0835 9.58579 8.74771 9.25 8.3335 9.25L5.8335 9.25C5.41928 9.25 5.0835 9.58579 5.0835 10L5.0835 14.1667C5.0835 14.5809 5.41928 14.9167 5.8335 14.9167H8.3335ZM7.5835 10.75L7.5835 13.4167H6.5835L6.5835 10.75H7.5835Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(StatisticSettingSVG);
