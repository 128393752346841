import React from 'react';

function UserIconSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0.5C6.11306 0.5 5.35283 0.829431 4.7193 1.4883C4.08577 2.12184 3.76901 2.88206 3.76901 3.76901C3.76901 4.65595 4.08577 5.41618 4.7193 6.04971C5.35283 6.68324 6.11306 7 7 7C7.88694 7 8.64717 6.68324 9.2807 6.04971C9.91423 5.41618 10.231 4.65595 10.231 3.76901C10.231 2.88206 9.91423 2.1155 9.2807 1.4693C8.64717 0.823096 7.88694 0.5 7 0.5ZM7 7.79825C6.18908 7.79825 5.30215 7.91228 4.33918 8.14035C3.37621 8.36842 2.49562 8.74854 1.69737 9.2807C0.899119 9.81287 0.5 10.4084 0.5 11.0673V13.5H13.5V11.0673C13.5 10.4084 13.1009 9.81287 12.3026 9.2807C11.5044 8.74854 10.6238 8.36842 9.66082 8.14035C8.69785 7.91228 7.81092 7.79825 7 7.79825ZM10.307 10.0029C9.21734 9.57212 8.11502 9.35672 7 9.35672C5.88498 9.35672 4.78266 9.56579 3.69298 9.98392C2.60331 10.402 2.05848 10.7632 2.05848 11.0673V11.9415H11.9415V11.0673C11.9415 10.7632 11.3967 10.4084 10.307 10.0029ZM7 2.05848C7.48148 2.05848 7.88694 2.2232 8.21637 2.55263C8.54581 2.88207 8.71053 3.28752 8.71053 3.76901C8.71053 4.25049 8.54581 4.64961 8.21637 4.96637C7.88694 5.28314 7.48148 5.44152 7 5.44152C6.51852 5.44152 6.11306 5.28314 5.78363 4.96637C5.45419 4.64961 5.28947 4.25049 5.28947 3.76901C5.28947 3.28752 5.45419 2.88207 5.78363 2.55263C6.11306 2.2232 6.51852 2.05848 7 2.05848Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(UserIconSVG);
