import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import OverflowTypography from 'components/textTruncated/OverflowTypography';
import TemplateSVG from 'assets/images/svg/TemplateSVG';
import { INPUT_RADIUS, WORK_SPACE_HEIGHT } from 'const/style';
import * as gridActions from 'grids/actions';
import Tooltip from 'components/tooltip/Base';
import FillStarSVG from 'assets/images/svg/FillStarSVG';
import { REMOVE_FAVORITE_GRID } from 'grids/types';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto',
        maxHeight: 126
    },
    listItemWapper: {
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1000
    },
    listItem: {
        height: WORK_SPACE_HEIGHT,
        borderRadius: INPUT_RADIUS,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(4),
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    flex1: {
        flex: 1
    },
    nameFavorite: {
        fontSize: '0.875rem',
        ...theme.ellipsis(1)
    },
    pd8: {
        padding: '0 16px'
    },
    dragSVG: {
        verticalAlign: 'middle',
        marginRight: 6
    }
}));
const FavoriteItem = ({ companyId, dbId, gridId, projectId, name, grid }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleFavoriteGrid = React.useCallback(
        event => {
            event.stopPropagation();
            if (!companyId) return;
            dispatch(
                gridActions.changeFavoriteGrid({
                    dbId: dbId,
                    gridId: gridId,
                    action: REMOVE_FAVORITE_GRID,
                    successCallback: () => {},
                    errorCallback: error => {}
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, gridId, dbId]
    );

    const handleItemClick = () => {
        dispatch(
            gridActions.getGridLastView({
                dbId: dbId,
                gridId: gridId,
                errorCallback: () => {},
                successCallback: responseData => {
                    const { dbId, gridId, viewId, branchId } = responseData;
                    if (!dbId || !gridId || !viewId || !branchId) {
                        history.push(
                            `/projects/${projectId}/databases/${dbId}/grids/${gridId}/branches/${gridId}/views/${grid?.defaultAccessViewId}`
                        );
                        return;
                    }
                    history.push(
                        `/projects/${projectId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}`
                    );
                    dispatch(gridActions.setSelectedGrid(gridId));
                }
            })
        );
    };

    return (
        <Grid item className={`${classes.listItemWapper} wsItem`}>
            <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className={`${classes.listItem} ${classes.pd8} : ''
            }`}
                onClick={handleItemClick}
            >
                <Grid item className={classes.flex1}>
                    <Grid container spacing={0} alignItems="center" justifyContent="space-around" wrap="nowrap">
                        <Grid item container wrap="nowrap">
                            <Grid item style={{ marginRight: 8, position: 'relative', top: 2 }}>
                                <TemplateSVG color={`#93929E`} />
                            </Grid>
                            <Grid item>
                                <OverflowTypography variant="body2" className={`${classes.nameFavorite} wsName`}>
                                    {name}
                                </OverflowTypography>
                            </Grid>
                        </Grid>

                        <Tooltip maxWidth={false} title="Remove from favorites">
                            <Grid
                                item
                                style={{ marginLeft: 8, position: 'relative', top: 2, cursor: 'pointer' }}
                                onClick={handleFavoriteGrid}
                            >
                                <FillStarSVG />
                            </Grid>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(FavoriteItem);
