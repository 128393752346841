import { TRIGGER_MATCH_STRING_SERVER_REGEX, showWatchingColumnsTriggers } from 'gridUI/automations/const';
import * as types from '../types';
import i18n from 'i18n';
import * as columnTypes from 'const/columnTypes';
import isUrl from 'is-url';
import IsValidEmail from 'utils/isValidEmail';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { get } from 'lodash';

const handleCheckNotInViewableColumns = ({
    viewableColumnOptions,
    newAutomationDetailError,
    nodeId,
    param,
    optionKey = 'value',
    errorStr,
    errorKey
}) => {
    if (param && !viewableColumnOptions.find(option => option[optionKey] === param)) {
        newAutomationDetailError[nodeId][errorKey] = errorStr;
        return false;
    }
    return true;
};

const handleCheckNotValidParam = ({ newAutomationDetailError, nodeId, value, errorKey, errorStr, conditionFn }) => {
    if (value && !conditionFn(value)) {
        newAutomationDetailError[nodeId][errorKey] = errorStr;
        return false;
    }
    return true;
};

const handleCheckMessageHasInvalidTrigger = ({
    newAutomationDetailError,
    nodeId,
    triggerIds,
    nodeParams,
    field = 'message',
    errorKey = 'message',
    errorStr = i18n.t('automation_trigger_not_appear_in_current_automation')
}) => {
    const regex = new RegExp(TRIGGER_MATCH_STRING_SERVER_REGEX, 'g');
    const text = nodeParams[field];
    const messages = text.match(regex);
    if (!messages?.length) return;
    let hasInvalid = false;
    if (messages?.length) {
        const messageIds = messages
            .filter(message => message.indexOf('${trigger') === 0)
            .map(message =>
                message
                    .replace(/[${}]/g, '')
                    .split('.')[0]
                    .replace('trigger', '')
            );
        hasInvalid = messageIds.some(id => !triggerIds.includes(id));
    }
    if (hasInvalid) {
        newAutomationDetailError[nodeId][errorKey] = errorStr;
        return false;
    }
};

const NODE_PARAM_REQUIRED = {
    nodeApp: [['paramField', 'errorField']],
    [WEB_HOOK_EXTERNAL_SYSTEMS.HTTP]: [['url', 'url']],
    [WEB_HOOK_EXTERNAL_SYSTEMS.SLACK]: [['incomingWebhookUrl', 'url']],
    [WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA]: [['functionUid', 'lambda']],
    [WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE]: [
        ['message', 'message'],
        ['sourceLang', 'source'],
        ['targetLang', 'target'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE]: [
        ['message', 'message'],
        ['sourceLang', 'source'],
        ['targetLang', 'target'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH]: [
        ['message', 'message'],
        ['voiceId', 'voiceId'],
        ['outputFormat', 'outputFormat'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL]: [
        ['message', 'message'],
        ['voiceId', 'voiceId'],
        ['outputFormat', 'outputFormat'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND]: [
        ['message', 'message'],
        ['lang', 'lang'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.JIRA]: [
        ['email', 'email'],
        ['apiToken', 'apiToken'],
        ['domain', 'domain'],
        ['projectKey', 'projectKey'],
        ['issueType', 'issueType'],
        ['issueSummary', 'issueSummary'],
        ['postbackCellMap.self.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR]: [
        ['columnSource.publicId', 'columnSource'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ],
    [WEB_HOOK_EXTERNAL_SYSTEMS.SET]: [
        // ['message', 'message'],
        ['postbackCellMap.result.publicId', 'mapTranslatedText']
    ]
};

const generateAutomationDetailError = ({ viewColumns, metaData, automationDetail, automationDetailError }) => {
    if (!automationDetail.triggerNodes?.length || !viewColumns?.length) return automationDetailError;
    const viewableColumnOptions = viewColumns
        // .filter(col => !SYSTEM_COLUMN_IDS?.includes(col.id) && col.editable)
        .filter(option => !!option.viewable)
        .map(col => {
            const columnInfo = metaData?.[col.id];
            let value = columnInfo?.publicId;
            if (col.id === columnTypes.RECORD_ID) value = '_recordId';
            else if (col.id === columnTypes.PATH_TAG) value = '_path';
            return {
                ...col,
                ...columnInfo,
                value,
                label: col.name,
                options: null
            };
        });

    const newAutomationDetailError = JSON.parse(JSON.stringify(automationDetailError));
    automationDetail.triggerNodes.forEach(trigger => {
        newAutomationDetailError[trigger.id] = {};
        if (!showWatchingColumnsTriggers.includes(trigger?.trigger)) return;
        if (!trigger?.watchingColumns?.length && !trigger?.watchingAll) {
            newAutomationDetailError[trigger.id].watchingColumns = i18n.t('automation_field_required');
            return;
        }
        if (
            trigger.watchingColumns?.length > 0 &&
            trigger.watchingColumns?.some(col => viewableColumnOptions.findIndex(option => option.value === col) < 0)
        ) {
            newAutomationDetailError[trigger.id].watchingColumns = i18n.t(
                'automation_trigger_watching_columns_not_appear_in_current_view_error'
            );
        }
    });
    if (automationDetail.nodes?.length) {
        const triggerIds = automationDetail?.triggerNodes?.map(trigger => trigger.id);
        const invalidTriggerIds = {};
        automationDetail.nodes.forEach(node => {
            newAutomationDetailError[node.id] = {};
            if (NODE_PARAM_REQUIRED[node.app]) {
                NODE_PARAM_REQUIRED[node.app].forEach(([paramField, errorField]) => {
                    const isPostbackCellMapField = paramField.includes('postbackCellMap.');
                    const isColumnSource = paramField.includes('columnSource.');
                    if (isPostbackCellMapField || isColumnSource) {
                        if (!get(node.params, paramField)) {
                            newAutomationDetailError[node.id][errorField] = i18n.t('automation_field_required');
                        }
                        return;
                    }
                    if (!node.params?.[paramField]?.length) {
                        newAutomationDetailError[node.id][errorField] = i18n.t('automation_field_required');
                    }
                });
            }
            if (triggerIds?.length) {
                if (node?.params?.message) {
                    handleCheckMessageHasInvalidTrigger({
                        newAutomationDetailError,
                        nodeId: node.id,
                        nodeParams: node.params,
                        triggerIds,
                        invalidTriggerIds
                    });
                }
                if (node?.params?.issueSummary) {
                    handleCheckMessageHasInvalidTrigger({
                        newAutomationDetailError,
                        nodeId: node.id,
                        nodeParams: node.params,
                        triggerIds,
                        invalidTriggerIds,
                        field: 'issueSummary',
                        errorKey: 'issueSummary'
                    });
                }
            }
            if (node?.params?.postbackCellMap?.result?.publicId) {
                handleCheckNotInViewableColumns({
                    viewableColumnOptions,
                    newAutomationDetailError,
                    nodeId: node.id,
                    param: node?.params?.postbackCellMap?.result?.publicId,
                    errorStr: i18n.t('automation_column_not_appear_in_current_view_error'),
                    errorKey: 'mapTranslatedText'
                });
            }
            if (node?.params?.url || node?.params?.incomingWebhookUrl || node?.params?.domain) {
                handleCheckNotValidParam({
                    newAutomationDetailError,
                    nodeId: node.id,
                    value: node?.params?.url || node?.params?.incomingWebhookUrl || node?.params?.domain,
                    errorKey: node.app === WEB_HOOK_EXTERNAL_SYSTEMS.JIRA ? 'domain' : 'url',
                    errorStr: i18n.t('automation_invalid_url'),
                    conditionFn: isUrl
                });
            }
            if (node.app === WEB_HOOK_EXTERNAL_SYSTEMS.JIRA) {
                handleCheckNotValidParam({
                    newAutomationDetailError,
                    nodeId: node.id,
                    value: node?.params?.email,
                    errorKey: 'email',
                    errorStr: i18n.t('automation_invalid_email'),
                    conditionFn: IsValidEmail
                });
            }
            if (node.app === WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR) {
                if (
                    node?.params.columnSource?.type &&
                    node?.params.columnSource?.type?.toLocaleLowerCase() !== columnTypes.FILES
                ) {
                    newAutomationDetailError[node.id].columnSource = i18n.t('automation_column_not_file_type');
                }
                handleCheckNotInViewableColumns({
                    viewableColumnOptions,
                    newAutomationDetailError,
                    nodeId: node.id,
                    param: node?.params?.columnSource?.publicId,
                    errorStr: i18n.t('automation_column_not_appear_in_current_view_error'),
                    errorKey: 'columnSource'
                });
            }
            if (node.app === WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION) {
                let error = false;
                if (node?.params?.filterGroups?.length) {
                    for (let filterGroup of node?.params?.filterGroups) {
                        if (filterGroup?.filterFields?.length) {
                            for (let filterField of filterGroup?.filterFields) {
                                const { field } = filterField;
                                if (field) {
                                    if (field.indexOf('trigger') > -1) {
                                        continue;
                                    }
                                    const fieldArr = field.split('.');
                                    const nodeId = fieldArr[0].replace('node', '');
                                    const findNode = automationDetail.nodes.find(n => n.id === nodeId);
                                    const fieldValue = fieldArr[fieldArr.length - 1];
                                    let missingOutputField = false;
                                    const outputFields =
                                        findNode?.params?.outputFields?.filter(field => !!field.trim()) || [];
                                    if (outputFields.length) {
                                        missingOutputField = !outputFields?.includes(fieldValue);
                                    }
                                    if (findNode?.order > node.order || missingOutputField) {
                                        error = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if (error) {
                            break;
                        }
                    }
                }
                if (error) {
                    newAutomationDetailError[node.id].error = i18n.t('automation_condition_field_error');
                }
            }
        });
    }
    return newAutomationDetailError;
};

const defineNodeOrder = (automationDetail = []) => {
    if (automationDetail?.triggerNodes?.length) {
        automationDetail.triggerNodes = automationDetail.triggerNodes.map((node, idx) => ({
            ...node,
            order: idx + 1
        }));
    }
    if (automationDetail?.nodes?.length) {
        const triggerLength = automationDetail.triggerNodes.length;
        automationDetail.nodes = automationDetail.nodes.map((node, idx) => ({
            ...node,
            order: triggerLength + idx + 1
        }));
    }
    return automationDetail;
};

const handler = {
    [types.SET_AUTOMATIONS](state, { payload }) {
        return {
            ...state,
            automations: payload
        };
    },
    [types.SET_IS_FETCHING_AUTOMATIONS](state, { payload }) {
        return {
            ...state,
            isFetchingAutomations: payload
        };
    },
    [types.SET_AUTOMATION_DETAIL](state, { payload }) {
        const newAutomationDetail = defineNodeOrder(payload);
        return {
            ...state,
            automationDetail: newAutomationDetail,
            automationDetailError:
                generateAutomationDetailError({
                    automationDetail: newAutomationDetail,
                    automationDetailError: state?.automationDetailError,
                    viewColumns: state?.viewColumns,
                    metaData: state?.metaData
                }) || {}
        };
    },
    [types.SET_OPEN_AUTOMATION_DETAIL](state, { payload }) {
        return {
            ...state,
            openAutomationDetail: payload
        };
    },
    [types.SET_AUTOMATION_DETAIL_ERROR](state, { payload }) {
        return {
            ...state,
            automationDetailError: payload
        };
    },
    [types.SET_AUTOMATION_LIST_EXECUTIONS](state, { payload }) {
        return {
            ...state,
            listAutomationExecutions: payload
        };
    },
    [types.SET_AUTOMATION_LAMBDAS](state, { payload }) {
        return {
            ...state,
            lambdas: payload
        };
    },
    [types.SET_OPEN_MULTIPLE_AUTOMATION_DIALOG](state, { payload }) {
        return {
            ...state,
            isOpenAnimationDialog: payload
        };
    }
};

export default handler;
