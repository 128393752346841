import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { inviteUsersApi, inviteInternalUsersApi, updateUserToInternalUserApi } from 'services/company';
import { fetchCustomerAndSubscriptions } from 'payment/actions';

export function inviteMembersToCompany({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const members = await inviteUsersApi({ companyId, body });
            dispatch(_inviteMembersToCompanyActionSuccess({ members }));
            dispatch(
                enqueueSnackbar({
                    message: `${members.length} invitations have been sent`,
                    type: 'info'
                })
            );
            dispatch(fetchCustomerAndSubscriptions({}));
            successCallback && successCallback(members);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function inviteInternalMembersToCompany({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const members = await inviteInternalUsersApi({ companyId, body });
            dispatch(_inviteMembersToCompanyActionSuccess({ members }));
            dispatch(
                enqueueSnackbar({
                    message: `${members.length} invitations have been sent`,
                    type: 'info'
                })
            );
            successCallback && successCallback(members);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _inviteMembersToCompanyActionSuccess({ members }) {
    return {
        type: types.INVITE_MEMBERS_TO_COMPANY_SUCCESS,
        payload: {
            members
        }
    };
}

export function updateUserToInternalUser({ email, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            await updateUserToInternalUserApi({ companyId, body: { companyId, email, ldUser: true } });
            dispatch(_updateUserToInternalUserActionSuccess({ email }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _updateUserToInternalUserActionSuccess({ email }) {
    return {
        type: types.UPDATE_USER_TO_INTERNAL_USER_SUCCESS,
        payload: {
            email
        }
    };
}
