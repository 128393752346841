import React from 'react';

function NoTreeItemSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="156" height="113" viewBox="0 0 156 113" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.4795 100.724L18.5 44.7164L50.7695 40.915L43.5047 89.9107L35.4795 100.724Z" fill="#CFD0D7" />
            <path
                d="M79.4068 109C117.477 109 148.339 106.353 148.339 103.087C148.339 99.8213 117.477 97.1738 79.4068 97.1738C41.3368 97.1738 10.475 99.8213 10.475 103.087C10.475 106.353 41.3368 109 79.4068 109Z"
                fill="url(#path-tag-paint0_linear)"
            />
            <path
                d="M35.9863 6.44434L3.37884 18.0174C1.43591 18.6932 0.422206 20.8896 1.09801 22.8325L17.7396 69.6318C18.4154 71.5748 20.6118 72.5885 22.5547 71.9127L59.6394 58.7345C61.5823 58.0587 62.596 55.8624 61.9202 53.9194L46.8836 11.6818L35.9863 6.44434Z"
                fill="#F4F5F7"
            />
            <path
                d="M35.9865 6.44434L37.9294 11.9352C38.4362 13.2868 39.8723 13.9626 41.2239 13.4558L46.7148 11.5129L35.9865 6.44434Z"
                fill="#D7D7DC"
            />
            <path
                d="M110.832 3.66004L76.4501 0.0275999C74.4227 -0.225826 72.5643 1.29473 72.3108 3.40661L67.1578 52.7402C66.9044 54.7676 68.425 56.626 70.5369 56.8794L109.733 60.9343C111.761 61.1877 113.619 59.6671 113.873 57.5552L118.519 12.9523L110.832 3.66004Z"
                fill="#F4F5F7"
            />
            <path
                d="M110.916 3.66211L110.325 9.4909C110.156 10.927 111.17 12.1096 112.606 12.2786L118.434 12.8699L110.916 3.66211Z"
                fill="#D7D7DC"
            />
            <path
                d="M135.667 55.9495C137.813 55.9495 139.553 54.2097 139.553 52.0636C139.553 49.9175 137.813 48.1777 135.667 48.1777C133.521 48.1777 131.781 49.9175 131.781 52.0636C131.781 54.2097 133.521 55.9495 135.667 55.9495Z"
                fill="#E9EAEF"
            />
            <path
                d="M155.434 80.2549C154.666 80.0726 149.786 78.9336 148.34 76.6101C147.256 74.8333 145.991 71.0063 145.72 70.2317V70.1406V70.1862C145.72 70.1862 145.72 70.1862 145.72 70.1406V70.1862C145.449 71.0063 144.184 74.7877 143.099 76.5645C141.653 78.9336 136.773 80.0726 136.005 80.2549C136.773 80.4371 141.653 81.5761 143.099 83.9452C144.184 85.722 145.449 89.5491 145.72 90.3236V90.4147V90.3691V90.3236C145.991 89.5035 147.256 85.722 148.34 83.9452C149.832 81.5761 154.711 80.4371 155.434 80.2549Z"
                fill="#E9EAEF"
            />
            <path
                d="M10.137 73.087C9.73626 72.9882 7.19022 72.3713 6.43583 71.1127C5.87005 70.1503 5.20996 68.0773 5.06851 67.6578V67.6084V67.6331C5.06851 67.6331 5.06851 67.6331 5.06851 67.6084V67.6331C4.92707 68.0773 4.26698 70.1256 3.70119 71.088C2.94681 72.3713 0.400766 72.9882 0 73.087C0.400766 73.1857 2.94681 73.8026 3.70119 75.0859C4.26698 76.0483 4.92707 78.1213 5.06851 78.5408V78.5902V78.5655V78.5408C5.20996 78.0966 5.87005 76.0483 6.43583 75.0859C7.21379 73.8026 9.75984 73.1857 10.137 73.087Z"
                fill="#E9EAEF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M105.148 18.439L104.666 22.9487C104.666 22.9572 104.665 22.9658 104.664 22.9743L104.153 27.7597C104.152 27.7685 104.151 27.7774 104.15 27.7863C104.149 27.7963 104.148 27.8063 104.147 27.8162L103.667 32.2992C103.667 32.3072 103.666 32.3151 103.665 32.323C103.662 32.3508 103.658 32.3781 103.652 32.4049C103.569 32.8069 103.192 33.0881 102.776 33.0436L81.8994 30.8126C81.4562 30.7653 81.1349 30.3671 81.1823 29.9239L81.6972 25.1059L81.6988 25.092L82.1489 20.8802C82.1495 20.8716 82.1503 20.8631 82.1512 20.8545L82.6661 16.0365C82.7134 15.5933 83.1116 15.272 83.5548 15.3193L89.9573 16.0035L89.978 16.0055L89.9985 16.0079L104.431 17.5503C104.874 17.5976 105.196 17.9958 105.148 18.439ZM103.457 19.0704L103.144 21.9998L90.2962 20.6268L90.6093 17.6974L103.457 19.0704ZM89.0034 17.5258L88.6903 20.4552L83.8731 19.9404L84.1861 17.011L89.0034 17.5258ZM83.389 24.4746L83.6717 21.8291L88.4891 22.3439L88.2064 24.9894L83.389 24.4746ZM89.8123 25.161L90.095 22.5155L102.942 23.8884L102.659 26.5339L89.8123 25.161ZM83.1869 26.3643L82.8743 29.2891L87.6917 29.8039L88.0043 26.8791L83.1869 26.3643ZM89.6102 27.0507L89.2976 29.9755L102.145 31.3485L102.457 28.4236L89.6102 27.0507ZM117.799 103.281L41.5182 103.281C37.5479 103.281 33.9999 99.7332 33.9999 95.6784L33.9999 34.5183C33.9999 30.548 37.4634 27 41.5182 27L64.1576 27C66.1005 27 68.1279 28.0137 69.6485 29.5343L78.1805 38.5731L117.799 38.5731C121.77 38.5731 125.318 42.0366 125.318 46.0914L125.318 95.7629C125.318 99.7332 121.854 103.281 117.799 103.281Z"
                fill="#DBDCE1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.0194 27.6704L38.0045 24.9075L25.876 29.3627L26.8909 32.1256L39.0194 27.6704ZM25.3749 32.6825L24.36 29.9196L19.8127 31.5899L20.8277 34.3529L25.3749 32.6825ZM19.6997 35.9645C19.696 35.9555 19.6925 35.9463 19.6891 35.9371L18.0183 31.3888C17.8646 30.9704 18.0795 30.5061 18.4979 30.3524L38.2056 23.1131C38.624 22.9594 39.0883 23.1742 39.242 23.5926L40.8036 27.8439C40.8073 27.8529 40.8109 27.8621 40.8143 27.8714L42.4742 32.39C42.4774 32.3979 42.4804 32.4059 42.4834 32.414C42.4882 32.427 42.4926 32.44 42.4966 32.453L44.042 36.66C44.0473 36.6725 44.0524 36.6853 44.0571 36.6982C44.0771 36.7526 44.0909 36.8077 44.0987 36.8628C44.1542 37.2337 43.944 37.6053 43.5783 37.7396L29.9555 42.7438C29.9485 42.7466 29.9414 42.7493 29.9342 42.752C29.927 42.7546 29.9198 42.7571 29.9126 42.7595L23.8706 44.979C23.4522 45.1327 22.9879 44.9178 22.8342 44.4994L21.1635 39.9511C21.1598 39.941 21.1563 39.9309 21.153 39.9208L19.6997 35.9645ZM21.484 36.138L22.4016 38.6361L26.9484 36.9659L26.0307 34.4678L21.484 36.138ZM27.5467 33.911L28.4643 36.409L40.5933 31.9536L39.6757 29.4556L27.5467 33.911ZM24.0701 43.1799L23.0552 40.4172L27.6026 38.7467L28.6175 41.5095L24.0701 43.1799ZM30.1335 40.9526L29.1186 38.1898L41.2469 33.7347L42.2618 36.4975L30.1335 40.9526Z"
                fill="#DBDCE1"
            />
            <rect x="92.9227" y="87.8809" width="21.1188" height="5.91327" rx="2.95663" fill="white" />
            <defs>
                <linearGradient
                    id="path-tag-paint0_linear"
                    x1="-24.4978"
                    y1="103.087"
                    x2="160.925"
                    y2="103.087"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E9EAEF" />
                    <stop offset="1" stopColor="#E9EAEF" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(NoTreeItemSVG);
