import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import ListItem from 'components/list/Item';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function MenuOption({ id, handleClickAway, onRemoveAddon }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    return (
        <Grid ref={rootRef} container className={classes.root}>
            <Grid item>
                <ListItem onClick={e => onRemoveAddon(e, id)} icon={<CloseIconSVG />} name={t(`addon_uninstall`)} />
            </Grid>
        </Grid>
    );
}

export default React.memo(MenuOption);
