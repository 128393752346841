import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getHookTypeLabel, WEB_HOOK_TRIGGERS_ACTIONS_LABEL, WEB_HOOK_TRIGGER_ACTIONS } from 'const/gridUI';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import AddIconLQASVG from 'assets/images/svg/AddIconLQASVG';
import LQAStatusChangeSVG from 'assets/images/svg/LQAStatusChangeSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import BitbucketSVG from 'assets/images/svg/webHook/BitbucketSVG';
import SlackSVG from 'assets/images/svg/webHook/SlackSVG';
import AutomationSVG from 'assets/images/svg/webHook/AutomationSVG';
import WebRequestSVG from 'assets/images/svg/webHook/WebRequestSVG';
import AddCommentSVG from 'assets/images/svg/webHook/AddCommentSVG';
import ReopenCommentSVG from 'assets/images/svg/webHook/ReopenCommentSVG';
import ResolveCommentSVG from 'assets/images/svg/webHook/ResolveCommentSVG';
import hexToRgba from 'hex-to-rgba';
import ManualTriggerSVG from 'assets/images/svg/webHook/ManualTriggerSVG';
import Tooltip from 'components/tooltip/Base';
import GoogleTranslateSVG from 'assets/images/svg/webHook/GoogleTranslateSVG';
import AmazonTranslateSVG from 'assets/images/svg/webHook/AmazonTranslateSVG';
import AmazonTextToSpeechSVG from 'assets/images/svg/webHook/AmazonTextToSpeechSVG';
import AmazonComprehendSVG from 'assets/images/svg/webHook/AmazonComprehendSVG';
import JiraSVG from 'assets/images/svg/webHook/JiraSVG';
import GoogleOCRSVG from 'assets/images/svg/webHook/GoogleOCRSVG';
import AutomationConditionSVG from 'assets/images/svg/webHook/AutomationConditionSVG';
import AutomationSetSVG from 'assets/images/svg/webHook/AutomationSetSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width,
        height: props => props.height,
        background: theme.colors.white,
        borderRadius: 4,
        border: props => (props.border ? `1px solid ${theme.colors.divider}` : ''),
        '& svg': { width: 12, height: 12 }
    },
    flex: {
        display: 'flex',
        '& svg': {
            width: 18,
            height: 18
        }
    }
}));

const SIZES = {
    smaller: {
        height: 20,
        width: 20
    },
    small: {
        height: 24,
        width: 24
    },
    medium: {
        width: 32,
        height: 32
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function getIcon({ type, theme }) {
    switch (type) {
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED:
        case WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED:
            return <AddIconSVG color={hexToRgba(theme.colors.atlantis, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED:
        case WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED:
            return <DeleteSVG color={hexToRgba(theme.colors.brightRed, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED:
        case WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED:
            return <EditSVG color={hexToRgba(theme.colors.dodgerBlue, 0.8)} />;

        case WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED:
            return <AddCommentSVG color={hexToRgba(theme.colors.atlantis, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.REOPEN_COMMENT:
            return <ReopenCommentSVG color={hexToRgba(theme.colors.brightRed, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.RESOLVE_COMMENT:
            return <ResolveCommentSVG color={hexToRgba(theme.colors.dodgerBlue, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.MANUAL:
            return <ManualTriggerSVG />;

        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED:
            return <AddIconLQASVG color={hexToRgba(theme.colors.atlantis, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED:
            return <DeleteSVG color={hexToRgba(theme.colors.brightRed, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED:
            return <EditSVG color={hexToRgba(theme.colors.dodgerBlue, 0.8)} />;
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED:
            return <LQAStatusChangeSVG color={hexToRgba(theme.colors.brightRed, 0.8)} />;

        case WEB_HOOK_EXTERNAL_SYSTEMS.SLACK:
            return <SlackSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.BITBUCKET:
            return <BitbucketSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.REQUEST:
        case WEB_HOOK_EXTERNAL_SYSTEMS.HTTP:
            return <WebRequestSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA:
            return <AutomationSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE:
            return <GoogleTranslateSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE:
            return <AmazonTranslateSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH:
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL:
            return <AmazonTextToSpeechSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND:
            return <AmazonComprehendSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.JIRA:
            return <JiraSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR:
            return <GoogleOCRSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION:
            return <AutomationConditionSVG />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.SET:
            return <AutomationSetSVG />;
        default:
            return <AutomationSVG />;
    }
}

function WebHookLabel({ type, size = 'medium', border = true }) {
    const theme = useTheme();
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height, size, border });
    const icon = getIcon({ type, theme });

    return (
        <Tooltip title={WEB_HOOK_TRIGGERS_ACTIONS_LABEL[type] || getHookTypeLabel(type)}>
            <Grid container className={classes.root} justify="center" alignItems="center">
                <Grid item className={classes.flex}>
                    {icon}
                </Grid>
            </Grid>
        </Tooltip>
    );
}

export default React.memo(WebHookLabel);
