export function generateAlphabet(index) {
    const ALPHABET_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let fChar = index;
    let sChar = null;
    if (index > ALPHABET_CHARS.length - 1) {
        fChar = Math.floor(index / (ALPHABET_CHARS.length - 1)) - 1;
        sChar = (index % (ALPHABET_CHARS.length - 1)) - 1;
        if (sChar === -1 && fChar > 0) {
            sChar = ALPHABET_CHARS.length - 1;
            fChar--;
        }
    }
    return `Column ${ALPHABET_CHARS[fChar]}${sChar !== null ? ALPHABET_CHARS[sChar] : ''}`;
}
