import React, { useRef, useEffect, useState } from 'react';
import Tooltip from 'components/tooltip/Base';
import { Grid, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = ({ truncated, maxLines }) =>
    makeStyles(theme => ({
        typography: {
            ...(truncated ? theme.ellipsis(maxLines) : {})
        }
    }));

const OverflowTypographyItem = ({
    truncated,
    maxLines = 1,
    title,
    children,
    tooltipClassName,
    variant,
    className,
    placement,
    ...rest
}) => {
    const classes = useStyles({ maxLines, truncated })();
    const [hoverStatus, setHover] = useState(false);
    const textElementRef = useRef();

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth ||
            textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        []
    );

    return (
        <Tooltip
            className={tooltipClassName}
            title={title || children}
            placement={placement}
            interactive
            disableHoverListener={!hoverStatus}
            maxWidth={true}
        >
            <Grid className={classnames(classes.typography, className)} item ref={textElementRef}>
                {React.cloneElement(children, { ellipsis: hoverStatus })}
            </Grid>
        </Tooltip>
    );
};

export default React.memo(OverflowTypographyItem);
