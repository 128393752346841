import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { getFriendlyTime } from 'utils/datetime';
import { sendManualTrack } from 'tracker';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { padding: 32 },
    closeIcon: {
        height: 24,
        width: 24
    }
}));

function RestoreConfirmModal({ backupTime, onClose, onAccept, t }) {
    const classes = useStyles();
    console.log(backupTime);

    const handleRestore = () => {
        sendManualTrack({ type: `Restore Grid` });
        onAccept({ time: backupTime });
        onClose();
    };

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <h4 className="prose prose-lg font-medium">{t(`grid_history_restore_this_action`)}?</h4>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIconSVG className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: 24 }}>
                <Trans
                    t={t}
                    i18nKey="modal_restore_grid_text"
                    defaults="<normal>Your current Grid will revert to the version from</normal> <bold>{{friendlyTime}}.</bold>"
                    values={{ friendlyTime: getFriendlyTime(backupTime) }}
                    components={{
                        normal: <p className="body2 inline" />,
                        bold: <p className="body1 inline" />
                    }}
                />
            </Grid>
            <Grid item style={{ marginTop: 39 }}>
                <Grid container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" size="small" width={100} onClick={onClose}>
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" width={100} onClick={handleRestore}>
                            {t(`global_restore`)}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(RestoreConfirmModal);
