import * as columnTypes from 'const/columnTypes';
import i18n from 'i18n';

export const OPERATOR = {
    contains: 'contains',
    equal: '=',
    notEqual: '!=',
    greaterOrEqual: '>=',
    lessOrEqual: '<=',
    less: '<',
    great: '>',
    in: 'in',
    true: true,
    false: false,
    startsWith: 'startsWith',
    endsWith: 'endsWith',
    both: 'both',
    between: 'between',
    notBetween: 'notBetween',
    regexp: 'regexp',
    isEmpty: 'isEmpty',
    isNotEmpty: 'isNotEmpty',
    notContains: 'notContains',
    notRegexp: 'notRegexp',
    notStartsWith: 'notStartsWith',
    notEndsWith: 'notEndsWith'
};

const typeMetadata = [
    {
        type: columnTypes.SINGLE_LINE,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.MULTIPLE_LINES,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.RICH_TEXT,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.MARKDOWN,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.BOOLEAN,
        validOperators: [OPERATOR.equal, OPERATOR.notEqual]
    },
    {
        type: columnTypes.DATETIME,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.between,
            OPERATOR.notBetween,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.greaterOrEqual,
            OPERATOR.lessOrEqual,
            OPERATOR.less,
            OPERATOR.great
        ]
    },
    {
        type: columnTypes.SINGLE_SELECTION,
        validOperators: [
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.equal,
            OPERATOR.notEqual
        ]
    },
    {
        type: columnTypes.MULTIPLE_SELECTIONS,
        validOperators: [
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.equal,
            OPERATOR.notEqual
        ]
    },
    {
        type: columnTypes.REFERENCE,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.NUMBER,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.greaterOrEqual,
            OPERATOR.lessOrEqual,
            OPERATOR.less,
            OPERATOR.great,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty
        ]
    },
    {
        type: columnTypes.FILES,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.FORMULA,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.TRANSLATION,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.PATH_TAG,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.CREATED_BY,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith
        ]
    },
    {
        type: columnTypes.CREATED_TIME,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.between,
            OPERATOR.notBetween,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.greaterOrEqual,
            OPERATOR.lessOrEqual,
            OPERATOR.less,
            OPERATOR.great
        ]
    },
    {
        type: columnTypes.ALTERED_BY,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.ALTERED_TIME,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.between,
            OPERATOR.notBetween,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.greaterOrEqual,
            OPERATOR.lessOrEqual,
            OPERATOR.less,
            OPERATOR.great
        ]
    },
    {
        type: columnTypes.JSON_LD,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.HTML,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.YAML,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: columnTypes.RECORD_ID,
        validOperators: [
            OPERATOR.equal,
            OPERATOR.notEqual,
            OPERATOR.contains,
            OPERATOR.notContains,
            OPERATOR.isEmpty,
            OPERATOR.isNotEmpty,
            OPERATOR.regexp,
            OPERATOR.notRegexp,
            OPERATOR.startsWith,
            OPERATOR.endsWith,
            OPERATOR.notStartsWith,
            OPERATOR.notEndsWith
        ]
    },
    {
        type: 'list',
        validOperators: [OPERATOR.contains, OPERATOR.notContains, OPERATOR.isEmpty, OPERATOR.isNotEmpty]
    }
];

export function getOperatorName(e) {
    switch (e) {
        case OPERATOR.startsWith:
            return 'starts with';
        case OPERATOR.endsWith:
            return 'ends with';
        case OPERATOR.contains:
            return i18n.t('filter_contains');
        case OPERATOR.notEqual:
            return i18n.t('filter_is_not');
        case OPERATOR.equal:
            return i18n.t('filter_is');
        case OPERATOR.true:
            return 'true';
        case OPERATOR.false:
            return 'false';
        case OPERATOR.both:
            return 'both';
        case OPERATOR.greaterOrEqual:
            return '>=';
        case OPERATOR.lessOrEqual:
            return '<=';
        case OPERATOR.less:
            return '<';
        case OPERATOR.great:
            return '>';
        case OPERATOR.between:
            return 'between';
        case OPERATOR.notBetween:
            return 'does not between';
        case OPERATOR.in:
            return 'in';
        case OPERATOR.regexp:
            return i18n.t('filter_regular_expression');
        case OPERATOR.notRegexp:
            return i18n.t('filter_not_regular_expression');
        case OPERATOR.isEmpty:
            return i18n.t('filter_is_empty');
        case OPERATOR.isNotEmpty:
            return i18n.t('filter_is_filled');
        case OPERATOR.notContains:
            return i18n.t('filter_does_not_contain');
        case OPERATOR.notStartsWith:
            return 'does not start with ';
        case OPERATOR.notEndsWith:
            return 'does not end with ';
        default:
            return null;
    }
}

const mapTypeMetadata = () => {
    let obj = {};
    typeMetadata.forEach(e => {
        obj[e.type] =
            e &&
            e.validOperators.map(operator => ({
                value: operator,
                label: getOperatorName(operator)
            }));
    });
    return obj;
};

export const conditionsByType = mapTypeMetadata();

export const getConditionsByType = type => {
    return conditionsByType?.[type];
};

export const getDefaultValue = () => {
    return [''];
};

export const ALL_OPERATORS_MAP = Object.entries(OPERATOR).map(([key, value]) => {
    return {
        key,
        label: getOperatorName(value),
        value
    };
});
