import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { DISABLED_OPACITY } from 'const/style';
import { useIsPathTagOn } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import PathTagSVG from 'assets/images/svg/PathTagSVG';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    propertyButton: {
        display: 'flex',
        margin: `0px 4px`,
        '&:hover': {
            background: theme.colors.divider
        }
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

const ManagePathTag = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isPathTagOn = useIsPathTagOn();

    const handlePathTagOn = React.useCallback(() => {
        dispatch(gridActions.togglePathTag());
    }, [dispatch]);

    return (
        <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_PATH_TAG}>
            {({ isReadOnly }) => (
                <Tooltip
                    title={
                        isReadOnly ? (
                            <>
                                <p className="text-light">
                                    You do not have the appropriate permission rights to select.
                                </p>
                                <p className="text-light">Please message your admin.</p>
                            </>
                        ) : (
                            t('global_path')
                        )
                    }
                >
                    <Grid item>
                        <ButtonBase
                            className={`${classes.propertyButton} ${classes.button} ${
                                isReadOnly ? classes.disabled : ''
                            }`}
                            style={{
                                background: isPathTagOn ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                                position: 'relative'
                            }}
                            onClick={handlePathTagOn}
                            tr-dt="Open Path Setting"
                        >
                            <PathTagSVG
                                color={isPathTagOn ? theme.palette.primary.main : theme.colors.darkness}
                                height={18}
                                width={18}
                            />
                        </ButtonBase>
                    </Grid>
                </Tooltip>
            )}
        </AccessControl>
    );
};

export default React.memo(ManagePathTag);
