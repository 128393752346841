import React from 'react';
import LDBasePortal from 'components/selects/LDBasePortal';

function ConditionSelect({ defaultValue, handleOptionChange, options = [], dropdownClassName, ...rest }) {
    return (
        <LDBasePortal
            ddPlaceholder={'Select a condition'}
            menuPlaceholder={'Find a condition'}
            options={options}
            onChange={handleOptionChange}
            defaultValue={defaultValue}
            isMulti={false}
            dropdownClassName={dropdownClassName}
            isUsingContainer={true}
            {...rest}
        />
    );
}

export default React.memo(ConditionSelect);
