import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, IconButton } from '@material-ui/core';
import Button from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { GRID_UPLOAD_MAX_SIZE } from 'const';
import { getUploadErrorMessage } from 'utils/upload';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import InputText from 'components/inputs/InputText';
import ButtonBase from 'components/button/Base';
import { createLambdaApi, editLambdaApi } from 'services/automation';
import LDBasePortal from 'components/selects/LDBasePortal';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: 500
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    box: {
        background: theme.colors.ghostwhite,
        minHeight: 250,
        border: `1px dashed ${theme.colors.lightGrey}`,
        borderRadius: 4,
        outline: 'none',
        cursor: 'pointer',
        marginTop: theme.spacing(3)
    },
    addButton: {
        color: theme.palette.primary.main
    },
    deleteButton: {
        '&.MuiButton-root': {
            background: theme.colors.snackBarWarning,
            '&:hover': {
                background: theme.colors.hoverWarningButton
            }
        }
    },
    link: {
        color: `-webkit-link !important`,
        textDecoration: 'underline !important'
    }
}));

const RUNTIMES = [
    'Nodejs12X',
    'Java8',
    'Java11',
    'Python27',
    'Python36',
    'Python37',
    'Python38',
    'Dotnetcore10',
    'Dotnetcore20',
    'Dotnetcore21',
    'Dotnetcore31',
    'Go1X',
    'Ruby25',
    'Ruby27'
];

const RUNTIME_OPTIONS = RUNTIMES.map(runtime => ({ value: runtime, label: runtime }));

function LambdaCreate({ isEditing, lambda, onClose, onAddLambda, onUpdateLambda }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isSubmitting, setSubmitting] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [runtimeOption, setRuntimeOption] = React.useState(null);
    const [lambdaTitle, setLambdaTitle] = React.useState('');
    const [fileName, setFileName] = React.useState('');
    const [handlerName, setHandlerName] = React.useState('');
    const [outputKeys, setOutputKeys] = React.useState(['']);
    const [runtimeOptions, setRuntimeOptions] = React.useState(RUNTIME_OPTIONS);

    const onOutputChange = (value, index) => {
        const newOutputs = outputKeys.map((output, idx) => {
            if (idx === index) return value;
            return output;
        });
        setOutputKeys(newOutputs);
    };

    const onRemoveOutput = index => {
        const newOutputs = outputKeys.filter((_, idx) => idx !== index);
        setOutputKeys(newOutputs);
    };

    const onAddOutput = () => setOutputKeys([...outputKeys, '']);

    const isNormalValid = file && lambdaTitle && fileName && handlerName && runtimeOption;
    const isEdittingValid = lambdaTitle && fileName && handlerName && runtimeOption;
    const isValid = isEditing ? isEdittingValid : isNormalValid;

    React.useEffect(() => {
        if (!isEditing || !lambda) return;
        setRuntimeOption(RUNTIME_OPTIONS.find(option => option.value === lambda.runtime));
        setLambdaTitle(lambda.title);
        setFileName(lambda.handler.split('.')[0]);
        setHandlerName(lambda.handler.split('.')[1]);
        setOutputKeys(lambda.outputFields || ['']);
    }, [lambda, isEditing]);

    const onDrop = acceptedFiles => {
        setFile(acceptedFiles[0]);
        const extension = acceptedFiles[0].name.split('.')[acceptedFiles[0].name.split('.').length - 1];
        if (extension === 'jar' || extension === 'JAR') {
            setRuntimeOptions(RUNTIME_OPTIONS.filter(option => option.value === 'Java8' || option.value === 'Java11'));
            setRuntimeOption(null);
        } else {
            setRuntimeOptions(RUNTIME_OPTIONS);
        }
    };

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles);
            const fileError = fileErrors?.[0];

            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const { getInputProps, open } = useDropzone({
        onDrop,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noDragEventsBubbling: true,
        accept: ['.zip', '.ZIP', '.jar', '.JAR'],
        onDropRejected
    });

    const handleAddLambda = async () => {
        setSubmitting(true);
        let formData = new FormData();
        formData.append('file', file);
        formData.append('title', lambdaTitle);
        formData.append('handler', `${fileName}.${handlerName}`);
        formData.append('runtime', runtimeOption.value);
        formData.append('outputFields', outputKeys.join(','));
        try {
            const newLambda = await createLambdaApi(formData);
            onAddLambda(newLambda);
            onClose();
            setSubmitting(false);
        } catch (error) {
            console.log('error', error);
            setSubmitting(false);
            dispatch(
                enqueueSnackbar({
                    message: error.originalMessage,
                    type: 'error'
                })
            );
        }
    };

    const handleUpdateLambda = async () => {
        setSubmitting(true);
        let formData = new FormData();
        if (file) formData.append('file', file);
        formData.append('title', lambdaTitle);
        formData.append('handler', `${fileName}.${handlerName}`);
        formData.append('runtime', runtimeOption.value);
        formData.append('outputFields', outputKeys.join(','));
        try {
            const newLambda = await editLambdaApi({ lambdaId: lambda.uid, formData });
            onUpdateLambda(newLambda);
            onClose();
            setSubmitting(false);
        } catch (error) {
            console.log('error', error);
            setSubmitting(false);
            dispatch(
                enqueueSnackbar({
                    message: error.originalMessage,
                    type: 'error'
                })
            );
        }
    };

    return (
        <>
            <DialogTitle title={isEditing ? 'Edit function' : 'Add new function'} onClose={onClose} />
            <DialogContent>
                <input {...getInputProps()} />
                <Grid item container direction="column" spacing={3} className={classes.root}>
                    <Grid item container spacing={1} direction="column">
                        <Grid item>
                            <p className="caption">
                                Please make sure your script is compatible with the{' '}
                                <a
                                    className={classes.link}
                                    href="https://docs.aws.amazon.com/lambda/latest/dg/lambda-runtimes.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Latest Amazon Lambda runtime
                                </a>
                            </p>
                        </Grid>
                        <Grid item>
                            <p className="body1">Package</p>
                        </Grid>
                        <Grid item container wrap="nowrap" alignItems="center" spacing={1}>
                            <Grid item style={{ flex: 1 }}>
                                <InputText value={file?.name} disabled={true} placeholder="Choose a zip file" />
                            </Grid>
                            <Grid item>
                                <ButtonBase width={120} variant="contained" onClick={open}>
                                    {t(`global_browse`)}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={6}>
                            <InputText
                                name="functionTitle"
                                label={'Function title'}
                                value={lambdaTitle}
                                onChange={e => setLambdaTitle(e.target.value)}
                                placeholder="Function title"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <p className="body1">Runtime</p>
                                </Grid>
                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder="Select runtime"
                                        menuPlaceholder={'Find a runtime'}
                                        options={runtimeOptions}
                                        onChange={setRuntimeOption}
                                        defaultValue={runtimeOption}
                                        isMulti={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={6}>
                            <InputText
                                name="fileName"
                                label={'Main file name'}
                                value={fileName}
                                onChange={e => setFileName(e.target.value)}
                                placeholder="Main file name"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputText
                                name="handlerName"
                                label={'Handler name'}
                                value={handlerName}
                                onChange={e => setHandlerName(e.target.value)}
                                placeholder="Handler name"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <p className="body1">Output fields</p>
                        </Grid>
                        {outputKeys.map((output, index) => (
                            <Grid item container key={index}>
                                <Grid item style={{ flex: 1 }}>
                                    <InputText
                                        value={output}
                                        onChange={e => onOutputChange(e.target.value, index)}
                                        placeholder="Output fields"
                                    />
                                </Grid>
                                <Grid item style={{ width: 20 }}>
                                    <IconButton
                                        style={{ marginTop: 8 }}
                                        size="small"
                                        onClick={() => onRemoveOutput(index)}
                                    >
                                        <CloseIconSVG size="small" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item container spacing={1}>
                            <Grid item className={classes.flex} onClick={onAddOutput} style={{ cursor: 'pointer' }}>
                                <ColorAddSVG />
                            </Grid>
                            <Grid item>
                                <p className="body1 inline cursor-pointer text-text-primary" onClick={onAddOutput}>
                                    Add value
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <Button
                            variant="contained"
                            width={120}
                            onClick={isEditing ? handleUpdateLambda : handleAddLambda}
                            disabled={isSubmitting || !isValid}
                        >
                            {isEditing ? t('global_update') : t('global_add')}
                        </Button>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(LambdaCreate);
