import React from 'react';

function ActivatedSVG({ color = '#49D0AA', ...other }) {
    return (
        <svg
            {...other}
            width="14px"
            height="14px"
            viewBox="0 0 14 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Localization-Column" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="LOCALIZATION-1-0-Addons" transform="translate(-462.000000, -383.000000)">
                    <g id="Group-14-Copy" transform="translate(462.000000, 383.000000)">
                        <g id="Group-17">
                            <circle id="Oval" fill={color} cx="7" cy="7" r="7"></circle>
                            <polygon
                                id="Path-2"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                                points="9.37380515 4.14917137 10.4595282 5.18416196 6.02413473 9.83696641 3.54047182 7.23156106 4.62619485 6.19657048 6.024 7.663"
                            ></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default React.memo(ActivatedSVG);
