import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import TemplateSVG from 'assets/images/svg/TemplateSVG';
import { useTemplateViewTime } from 'hooks/auth';
import { useTemplates } from 'hooks/grid';

import * as gridActions from 'grids/actions';
import * as authActions from 'auth/actions';
import { USER_SETTINGS } from 'const';

function TemplatesHeaderItem({ onClick }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const templates = useTemplates();
    const isFetching = templates.isFetching;
    const viewedTime = useTemplateViewTime();

    const templateList = templates.list;
    const newestTime = templates.newestTemplateTime;
    const hasNewTemplates = !viewedTime || newestTime > viewedTime;

    React.useEffect(() => {
        if (!isFetching && !templateList) {
            dispatch(
                gridActions.fetchGridTemplates({
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        }
    }, [isFetching, templateList, dispatch]);

    const handleOnClick = React.useCallback(() => {
        if (hasNewTemplates) {
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.TEMPLATE_VIEW_TIME]: newestTime
                    },
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        }

        onClick();
    }, [hasNewTemplates, newestTime, dispatch, onClick]);

    return (
        <>
            <TemplateSVG style={{ cursor: 'pointer', display: 'flex' }} onClick={handleOnClick} />
            {hasNewTemplates && (
                <div
                    style={{
                        width: 6,
                        height: 6,
                        borderRadius: '50%',
                        background: theme.colors.indicator,
                        position: 'absolute',
                        right: 9,
                        top: 9
                    }}
                ></div>
            )}
        </>
    );
}

export default React.memo(TemplatesHeaderItem);
