import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from 'components/button/Base';
import InputText from 'components/inputs/InputText';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 480,
        position: 'relative',
        borderRadius: 6,
        padding: theme.spacing(5)
    },
    closeButton: {
        position: 'absolute',
        right: 24,
        top: 24
    },
    buttonWrapper: {
        position: 'relative'
    },

    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function WorkspaceDeleteConfirm({ onClose, onConfirm, isSubmitting, projectName }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState('');

    const handleChange = e => {
        setValue(e.target.value);
    };

    const isValid = value.trim() === projectName;

    return (
        <>
            <DialogTitle title="Delete Project" onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="body2" display="inline">
                            {t('delete_project_title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Trans i18nKey="delete_project_content" projectName={projectName}>
                            <Typography variant="body2" display="inline">
                                Please type{' '}
                            </Typography>
                            <Typography variant="body1" display="inline">
                                {{ projectName }}{' '}
                            </Typography>
                            <Typography variant="body2" display="inline">
                                to confirm.
                            </Typography>
                        </Trans>
                    </Grid>
                    <Grid item>
                        <InputText
                            name="projectName"
                            type="text"
                            value={value}
                            onChange={handleChange}
                            placeholder="Project name"
                            autoFocus={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <Button
                            variant="contained"
                            width={120}
                            onClick={onConfirm}
                            disabled={isSubmitting || !isValid}
                            color={'secondary'}
                        >
                            {t('global_delete')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(WorkspaceDeleteConfirm);
