import React from 'react';

function SpeakerSVG({ muted, color = 'white', ...other }) {
    if (muted) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
                <path
                    d="M8.00482 12.5391C8.00482 12.821 7.84196 13.0771 7.58709 13.1966C7.48855 13.2432 7.38296 13.2657 7.27806 13.2657C7.11193 13.2657 6.94735 13.2086 6.81442 13.0984L2.84219 9.80684H0.726585C0.325372 9.80718 0 9.48164 0 9.08043V6.18561C0 5.78422 0.325372 5.45885 0.726585 5.45885H2.84237L6.81459 2.16729C7.03162 1.98741 7.33257 1.94906 7.58727 2.06944C7.84196 2.18896 8.00499 2.4452 8.00499 2.72689L8.00482 12.5391Z"
                    fill={color}
                />
                <path
                    d="M14.7664 6.21305C14.4831 5.92898 14.0109 5.92898 13.7276 6.21305L12.4894 7.4545L11.2512 6.21305C10.9679 5.92898 10.4957 5.92898 10.2124 6.21305C9.9292 6.49711 9.9292 6.97054 10.2124 7.2546L11.4507 8.49605L10.2124 9.73751C9.9292 10.0216 9.9292 10.495 10.2124 10.7791C10.3593 10.9262 10.5482 11 10.7371 11C10.926 11 11.1148 10.9264 11.2617 10.7791L12.5 9.53751L13.7381 10.7791C13.885 10.9262 14.074 11 14.2628 11C14.4518 11 14.6406 10.9264 14.7874 10.7791C15.0709 10.495 15.0709 10.0216 14.7874 9.73751L13.5283 8.49605L14.7666 7.2546C15.0498 6.97054 15.0498 6.49711 14.7664 6.21305Z"
                    fill={color}
                />
            </svg>
        );
    }
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M8.00482 12.9876C8.00482 13.2695 7.84196 13.5255 7.58709 13.645C7.48855 13.6916 7.38296 13.7142 7.27806 13.7142C7.11193 13.7142 6.94735 13.6571 6.81442 13.5468L2.84219 10.2553H0.726585C0.325372 10.2556 0 9.93008 0 9.52887V6.63405C0 6.23267 0.325372 5.9073 0.726585 5.9073H2.84237L6.81459 2.61574C7.03162 2.43585 7.33257 2.3975 7.58727 2.51789C7.84196 2.63741 8.00499 2.89365 8.00499 3.17534L8.00482 12.9876ZM10.7971 11.8522C10.7794 11.8534 10.7624 11.8541 10.7449 11.8541C10.5529 11.8541 10.3679 11.7783 10.2312 11.6414L10.134 11.5439C9.87915 11.2895 9.84922 10.8866 10.0638 10.5973C10.608 9.8637 10.8952 8.99403 10.8952 8.08172C10.8952 7.10044 10.5691 6.18005 9.95206 5.41993C9.71715 5.13101 9.73882 4.7114 10.0021 4.44828L10.0991 4.35111C10.2442 4.20597 10.4387 4.12686 10.6492 4.13942C10.8542 4.14973 11.0456 4.24621 11.1756 4.40511C12.0316 5.45243 12.4837 6.72399 12.4837 8.08189C12.4837 9.34658 12.0838 10.5504 11.3272 11.5626C11.2008 11.7313 11.0075 11.8373 10.7971 11.8522ZM13.8012 14.0977C13.6698 14.253 13.4797 14.3462 13.2763 14.3548C13.2663 14.3551 13.2562 14.3555 13.2459 14.3555C13.0534 14.3555 12.8687 14.2794 12.732 14.1427L12.6366 14.0473C12.3698 13.7807 12.3518 13.3544 12.5943 13.0658C13.7661 11.6722 14.4117 9.90222 14.4117 8.08172C14.4117 6.18813 13.7205 4.36522 12.466 2.94885C12.2116 2.66131 12.2245 2.22605 12.4952 1.95433L12.5905 1.85889C12.7318 1.71684 12.9155 1.63859 13.1262 1.6453C13.3262 1.65097 13.5153 1.73936 13.6483 1.88898C15.1647 3.59633 16 5.79586 16 8.08172C16.0003 10.2811 15.2194 12.4177 13.8012 14.0977Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SpeakerSVG);
