import { enqueueSnackbar } from 'notifier/actions';
import * as types from '../types';
import { updateWorkspaceUserRoleApi } from 'services/workspaces';
import { getViewGroupsApi, updateGroupWorkspacesAccessApi, getSingleGroupApi } from 'services/groups';
import * as workspaceActions from 'workspaces/actions';
import { requestShareViewApi, getShareViewDetailApi, deleteShareViewLinkApi } from 'services/shareLink';

export function fetchGroupsByView(viewId) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { workspaceId, currentView, dbId, branchId } = gridUI;

        const viewIdCombined = viewId || currentView?.id;

        dispatch({
            type: types.FETCH_VIEW_GROUPS
        });
        try {
            const data = await getViewGroupsApi({ viewId: viewIdCombined, workspaceId, dbId, gridId: branchId });
            const viewGroups = data?.groups;
            const workspaceMembers = data?.workspace?.workspaceMembers;
            const workspaceRoles = data?.workspaceRoles;

            dispatch({
                type: types.FETCH_VIEW_GROUPS_SUCCESS,
                payload: {
                    viewGroups,
                    workspaceMembers,
                    viewId: viewIdCombined
                }
            });
            dispatch(workspaceActions._setWorkspaceRoles({ workspaceRoles }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch({
                type: types.FETCH_VIEW_GROUPS_FAILED,
                payload: {
                    message
                }
            });
        }
    };
}

export function changeSelectedGroupId({ groupId }) {
    return {
        type: types.CHANGE_SELECTED_GROUP_ID,
        payload: {
            groupId
        }
    };
}

export function changeShareViewUserWorkspaceRole({
    userId,
    workspaceId,
    newRole,
    oldRole,
    errorCallback,
    successCallback
}) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_changeShareViewUserWorkspaceAction({ role: newRole, userId }));
        const body = {
            roleId: newRole?.id,
            projectId: workspaceId,
            userId
        };
        try {
            await updateWorkspaceUserRoleApi({ companyId, body });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_changeShareViewUserWorkspaceAction({ role: oldRole, userId }));
            errorCallback && errorCallback();
        }
    };
}

export function _changeShareViewUserWorkspaceAction({ userId, role }) {
    return {
        type: types.CHANGE_SHARE_VIEW_USER_WORKSPACE_ROLE,
        payload: {
            role,
            userId
        }
    };
}

export function grantViewToGroup({ group, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, gridUI } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const { workspaceId, dbId, branchId, defaultAccessViewId } = gridUI;

        try {
            await updateGroupWorkspacesAccessApi({
                companyId,
                groupId: group.id,
                body: {
                    addViews: [{ workspaceId, databaseId: dbId, gridId: branchId, viewId: defaultAccessViewId }],
                    removeViews: []
                }
            });
            const data = await getSingleGroupApi({ workspaceId, groupId: group.id });
            const grantGroupMembers = (data?.workspace?.groups?.[0]?.members || []).map(member => member.user);
            group.members = grantGroupMembers;
            dispatch({
                type: types.GRANT_GROUP_TO_VIEW_SUCCESS,
                payload: {
                    group,
                    viewId: defaultAccessViewId
                }
            });
            dispatch(
                enqueueSnackbar({
                    message: `View shared to group`,
                    type: 'info'
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch({
                type: types.GRANT_GROUP_TO_VIEW_FAILED,
                payload: { message }
            });

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function removeViewOutOfGroup({ group, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, gridUI } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const { workspaceId, dbId, branchId, defaultAccessViewId } = gridUI;

        try {
            await updateGroupWorkspacesAccessApi({
                companyId,
                groupId: group.id,
                body: {
                    addViews: [],
                    removeViews: [{ workspaceId, databaseId: dbId, gridId: branchId, viewId: defaultAccessViewId }]
                }
            });
            dispatch({
                type: types.REMOVE_VIEW_OUT_OF_GROUP_SUCCESS,
                payload: {
                    group,
                    viewId: defaultAccessViewId
                }
            });
            dispatch(
                enqueueSnackbar({
                    message: `Group removed from view`,
                    type: 'info'
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch({
                type: types.REMOVE_VIEW_OUT_OF_GROUP_FAILED,
                payload: { message }
            });

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function createShareLinkView({ compositeViewId, body, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            const viewDetail = await requestShareViewApi({ compositeViewId, body });
            dispatch(_createShareLinkViewSuccess({ compositeViewId, viewDetail }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function getShareLinkView({ compositeViewId, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            const viewDetail = await getShareViewDetailApi({ compositeViewId });
            dispatch(_createShareLinkViewSuccess({ compositeViewId, viewDetail }));
            successCallback && successCallback();
        } catch (error) {
            console.log('error', error);
        }
    };
}

export function _createShareLinkViewSuccess({ compositeViewId, viewDetail }) {
    return {
        type: types.GET_SHARE_LINK_VIEW,
        payload: {
            compositeViewId,
            viewDetail
        }
    };
}

export function deleteShareLink({ compositeViewId, shareLinkDetail, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_deleteShareViewLink({ compositeViewId, viewDetail: null }));

        try {
            await deleteShareViewLinkApi({ compositeViewId });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteShareViewLink({ compositeViewId, viewDetail: shareLinkDetail }));

            return errorCallback && errorCallback(message);
        }
    };
}

function _deleteShareViewLink({ compositeViewId, viewDetail }) {
    return {
        type: types.DELETE_SHARE_LINK_VIEW,
        payload: {
            compositeViewId,
            viewDetail
        }
    };
}
