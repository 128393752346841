import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import LogoGridUISVG from 'assets/images/svg/LogoGridUISVG';
import DeleteAccountPNG from 'assets/images/deleteAccount.png';
import DeleteAccountFailedPNG from 'assets/images/deleteAccountFailed.png';
import ButtonBase from 'components/button/Base';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteAccount, logOut } from 'auth/actions';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { CRM_EVENT_NAME, track } from 'tracker/crmTracker';
import { enqueueSnackbar } from 'notifier/actions';
import CircularProgress from 'components/CircularProgress';
import { setTimeoutInTime } from 'utils/usage';

const DeleteAccountConfirmationPage = () => {
    const { key } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useCurrentUserFullInfo();
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const currentUserEmail = userInfo?.email;

    const handleLogout = useCallback(() => {
        if (!loading && !isError && !isExpired) {
            dispatch(logOut({}));
        }
    }, [dispatch, loading, isError, isExpired]);

    const handleDeleteAccount = useCallback(() => {
        if (key && currentUserEmail) {
            setLoading(true);
            const start = new Date().getTime();
            dispatch(
                deleteAccount({
                    key,
                    successCallback: () => {
                        track(
                            userInfo?.deleteAccount?.unsubscribe
                                ? CRM_EVENT_NAME.DELETE_ACCOUNT_WITH_UNSUBSCRIBE
                                : CRM_EVENT_NAME.DELETE_ACCOUNT,
                            {
                                email: currentUserEmail
                            }
                        );
                        setTimeoutInTime({
                            start,
                            timeout: 2000,
                            cb: () => {
                                setLoading(false);
                                setIsExpired(false);
                                setIsError(false);
                            }
                        });
                    },
                    errorCallback: error => {
                        setTimeoutInTime({
                            start,
                            timeout: 2000,
                            cb: () => {
                                setLoading(false);
                                const isExpired = error?.error === 'deleteAccountKeyExpired';
                                setIsExpired(isExpired);
                                setIsError(!isExpired);
                                if (!isExpired) {
                                    const { message } = error;
                                    dispatch(
                                        enqueueSnackbar({
                                            message,
                                            type: 'error'
                                        })
                                    );
                                }
                            }
                        });
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, key, currentUserEmail]);

    useEffect(() => {
        handleDeleteAccount();
    }, [handleDeleteAccount]);

    useEffect(() => {
        window.onbeforeunload = () => {
            handleLogout();
        };
        return () => {
            window.onbeforeunload = () => {};
        };
    }, [handleLogout]);

    const navigateToSignIn = useCallback(() => {
        handleLogout();
        history.replace('/signin');
    }, [history, handleLogout]);

    const navigateToRegister = useCallback(() => {
        handleLogout();
        history.replace('/getstarted');
    }, [history, handleLogout]);

    const navigateToDashboard = useCallback(() => {
        if (loading) return;
        handleLogout();
        history.replace('/');
    }, [loading, history, handleLogout]);

    return (
        <div className="w-screen h-screen flex flex-col px-6 md:px-[72px] py-8 overflow-auto">
            <div
                className={classnames('w-fit', {
                    'cursor-pointer': !loading
                })}
                onClick={navigateToDashboard}
            >
                <LogoGridUISVG color="#2A293A" />
            </div>
            <div className="flex-1 flex flex-col flex-nowrap items-center justify-center">
                <div
                    className={classnames({
                        'animate-pulse': loading && !isError
                    })}
                >
                    <img
                        className="max-w-[390px] w-full"
                        src={isError || isExpired ? DeleteAccountFailedPNG : DeleteAccountPNG}
                        alt="delete-account-confirmation"
                    />
                </div>
                <div
                    className={classnames('h-full max-h-[350px] transition-all duration-500 ease-in-out', {
                        '!h-0 overflow-hidden': loading && !isError,
                        'max-h-[100px]': isExpired,
                        'max-h-[300px]': isError
                    })}
                >
                    {isExpired ? (
                        <p className="m-0 mt-12 text-center text-4xl">Sorry, this link is no longer valid</p>
                    ) : isError ? (
                        <div className="text-center">
                            <p className="m-0 mt-12 text-4xl">Failed to delete your account</p>
                            <p className="m-0 mt-6 text-lg">
                                There was a problem with deleting your account. Please try again
                            </p>
                            <div className="mt-[88px] flex justify-center">
                                <ButtonBase
                                    variant="contained"
                                    width={264}
                                    height={36}
                                    onClick={handleDeleteAccount}
                                    disabled={loading}
                                >
                                    Try again
                                    {loading && <CircularProgress />}
                                </ButtonBase>
                            </div>
                        </div>
                    ) : (
                        <>
                            <p className="m-0 mt-8 md:mt-12 text-2xl md:text-4xl text-center">
                                Your account has been deleted
                            </p>
                            <p className="m-0 mt-2 md:mt-6 text-sm md:text-base text-center max-w-[560px]">
                                We are sad to see you go. The account associated with email{' '}
                                <span className="font-semibold">{currentUserEmail}</span> has been deleted succesfully
                            </p>
                            <div className="mt-10 md:mt-[72px]">
                                <div className="-m-2 md:-m-4 flex flex-wrap items-center justify-center">
                                    <div className="m-2 md:m-4">
                                        <ButtonBase
                                            variant="outlined"
                                            width={264}
                                            height={36}
                                            onClick={navigateToSignIn}
                                        >
                                            Sign in to another account
                                        </ButtonBase>
                                    </div>
                                    <div className="m-2 md:m-4">
                                        <ButtonBase
                                            variant="contained"
                                            width={264}
                                            height={36}
                                            onClick={navigateToRegister}
                                        >
                                            Create a new account
                                        </ButtonBase>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(DeleteAccountConfirmationPage);
