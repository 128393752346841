import React from 'react';
import { Grid, Typography, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';
import Spinner from 'components/spinner/Base';
import { useIsFetchingSourcesByTmId, useTmSourcesByTmId } from 'hooks/tm';
import CountryFlag from 'components/svg-icon/CountryFlag';
import * as tmActions from './actions';
import { useDispatch } from 'react-redux';
import LDBasePortal from 'components/selects/LDBasePortal';
import { enqueueSnackbar } from 'notifier/actions';
import { sendManualTrack } from 'tracker';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import { EXPORT_TYPES } from 'const/gridUI';
import Checkbox from 'components/checkbox/Base';
import { AutoSizer, List } from 'react-virtualized-dn';
import ListSelect from 'components/list/Select';
import Tooltip from 'components/tooltip/Base';
import { chunk } from 'lodash';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import Search from 'components/search';

const useStyles = makeStyles(theme => ({
    root: {
        width: 728,
        position: 'relative',
        background: theme.colors.white,
        '&:focus': {
            outline: 'none'
        },
        borderRadius: 6
    },
    mb12: {
        marginBottom: 12
    },
    spacing: {
        marginBottom: theme.spacing(4)
    },
    closeIcon: {
        position: 'absolute',
        top: 12,
        right: 12
    },
    btnSubmit: {
        width: 100,
        position: 'relative',
        '& button svg': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? hexToRgba(props.buttonColor, 0.9) : '')
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    numberStep: {
        width: 18,
        minWidth: 18,
        height: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 12,
        lineHeight: `14px`,
        marginRight: 8,
        position: 'relative'
    },
    exportType: {
        marginLeft: 48
    },
    radio: {
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: theme.colors.lightLavender
        }
    },
    selectSource: {
        width: 266
    },
    rowItem: {
        minWidth: 220
    },
    targetList: {
        margin: `8px -13px 0`,
        minHeight: 168,
        maxHeight: 209,
        overflowY: 'auto'
    },
    hide: {
        opacity: 0,
        pointerEvents: 'none'
    },
    search: {
        border: `1px solid rgb(233, 234, 239)`,
        width: 220
    }
}));

function ExportTM({ onClose, tmId }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const langOptions = useLanguageOptionsWithUsageValidate();
    const isFetching = useIsFetchingSourcesByTmId({ tmId });
    const sources = useTmSourcesByTmId({ tmId });
    const [isExporting, setIsExporting] = React.useState(false);
    const [source, setSource] = React.useState(null);
    const [exportType, setExportType] = React.useState(EXPORT_TYPES.TMX);
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});
    const [targetLangs, setTargetLangs] = React.useState([]);
    const [isFetchingLangOptions, setIsFetchingLangOptions] = React.useState(true);
    const [search, setSearch] = React.useState('');
    const savedLangOptions = React.useRef({});

    const ITEMS_PER_ROW = 3;

    const langObject = React.useMemo(() => {
        return langOptions.reduce((acc, cur) => {
            acc[cur.value] = cur;
            return acc;
        }, {});
    }, [langOptions]);

    const options = React.useMemo(() => {
        return [
            { value: EXPORT_TYPES.TMX, label: t(`global_tmx`) },
            { value: EXPORT_TYPES.CSV, label: t(`global_csv`) },
            { value: EXPORT_TYPES.XLSX, label: t(`global_xlsx`) }
        ];
    }, [t]);

    const handleSourceChange = option => {
        if (source?.value === option?.value) return;
        setSource(option);
        setSelectedTargetOptions({});
    };

    const isNoSources = React.useMemo(() => {
        return sources?.length === 0;
    }, [sources]);

    const sourceOptions = React.useMemo(() => {
        return sources?.map(source => ({
            ...source,
            label: source,
            value: source,
            icon: () => <CountryFlag languageCountryCode={source} />
        }));
    }, [sources]);

    const isAllTargetSelected = React.useMemo(() => {
        const values = Object.values(selectedTargetOptions);
        return values.every(el => !!el) && values.length === targetLangs.length;
    }, [targetLangs.length, selectedTargetOptions]);

    React.useEffect(() => {
        if (sourceOptions?.length) {
            setSource(sourceOptions?.[0]);
        }
    }, [sourceOptions]);

    React.useEffect(() => {
        dispatch(
            tmActions.fetchTMSources({
                tmId,
                successCallback: () => {
                    console.log('fetch sources success');
                },
                errorCallback: () => {
                    console.log('fetch sources failed');
                }
            })
        );
    }, [dispatch, tmId]);

    React.useEffect(() => {
        if (source) {
            if (savedLangOptions.current[source.value]) {
                setTargetLangs(savedLangOptions.current[source.value]);
                return;
            }
            setIsFetchingLangOptions(true);
            dispatch(
                tmActions.fetchTMTarget({
                    tmId,
                    source: source.value,
                    successCallback: targets => {
                        const newLangTargets = (targets || []).map(t => langObject[t]);
                        setTargetLangs(newLangTargets);
                        savedLangOptions.current[source.value] = newLangTargets;
                        setIsFetchingLangOptions(false);
                    },
                    errorCallback: () => {
                        setIsFetchingLangOptions(false);
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, source, tmId]);

    const listTargets = React.useMemo(() => {
        return Object.keys(selectedTargetOptions)?.filter(property => selectedTargetOptions[property]);
    }, [selectedTargetOptions]);

    const handleExport = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(
            enqueueSnackbar({
                message: t('snackbar_export_tm'),
                type: 'info'
            })
        );
        setIsExporting(true);
        dispatch(
            tmActions.exportCurrentCompanyTM({
                tmId,
                format: exportType,
                source: source?.value,
                targets: listTargets.reduce((acc, cur, curIdx) => {
                    acc += `${curIdx > 0 ? '&' : ''}targetLangs=${cur}`;
                    return acc;
                }, ''),
                successCallback: () => {
                    setIsExporting(false);
                    console.log('export tm success');
                },
                errorCallback: () => {
                    setIsExporting(false);
                    console.log('export tm failed');
                }
            })
        );
        sendManualTrack({ type: `Export TM Success` });
        onClose && onClose();
    };

    const handleExportTypeChange = React.useCallback(e => {
        let exportType = e.target.value;
        setExportType(exportType);
    }, []);

    const _noRowsRenderer = React.useCallback(() => {
        return null;
    }, []);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions[targetValue] || false;

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    const targetLangsFiltered = React.useMemo(() => {
        return (
            targetLangs?.filter(
                item =>
                    item.value.toLocaleLowerCase().includes(search.trim().toLocaleLowerCase()) ||
                    item?.tooltip.toLocaleLowerCase().includes(search.trim().toLocaleLowerCase())
            ) || []
        );
    }, [targetLangs, search]);

    const langOptionChunks = React.useMemo(() => {
        return chunk(targetLangsFiltered, ITEMS_PER_ROW);
    }, [targetLangsFiltered]);

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style }) => {
            const items = langOptionChunks?.[index];
            return (
                <Grid style={{ ...style }} key={key} container alignItems="center" direction="row" wrap="nowrap">
                    {items?.map(option => {
                        const isSelected = selectedTargetOptions?.[option?.value];

                        if (option?.isDisabled) {
                            return (
                                <Grid key={option?.value} item xs={4}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                        return (
                            <Tooltip title={option.tooltip} key={option.value}>
                                <Grid item xs={4}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            );
        },
        [handleTargetSelect, langOptionChunks, selectedTargetOptions, classes]
    );

    const handleSelectAllTargets = React.useCallback(
        checked => {
            if (checked) {
                let newSelectedTargetOptions = {};
                targetLangs.map(el => {
                    newSelectedTargetOptions[el.value] = true;
                    return false;
                });
                setSelectedTargetOptions(newSelectedTargetOptions);
                return;
            }
            setSelectedTargetOptions({});
        },
        [targetLangs]
    );

    const onChangeSearch = React.useCallback(e => {
        setSearch(e.target.value);
    }, []);

    return (
        <>
            <DialogTitle title={t('export_tm_title')} onClose={onClose} />
            <DialogContent>
                <Grid container wrap="nowrap" direction="column" className={`${classes.root}`}>
                    <Grid container direction="row" alignItems="center" className={classes.spacing}>
                        <Grid item className={classes.numberStep}>
                            {t('global_1')}
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">TM export file type</Typography>
                        </Grid>
                        <Grid item className={classes.exportType}>
                            <RadioGroup
                                row
                                aria-label="export-type"
                                name="exportType"
                                value={exportType}
                                onChange={handleExportTypeChange}
                            >
                                {options.map(option => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio size="small" className={classes.radio} />}
                                        label={<Typography variant="body1">{option.label}</Typography>}
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" className={classes.mb12}>
                        <Grid item className={classes.numberStep}>
                            {t('global_2')}
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Select source language you want to export</Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.spacing}>
                        {isFetching && (
                            <Grid container alignItems="center" justify="center">
                                <Grid item>
                                    <Spinner size={30} thick={4} />
                                </Grid>
                            </Grid>
                        )}
                        {!isFetching && isNoSources && <Typography variant="body2">{t('export_tm_text_1')}</Typography>}
                        {!isFetching && !isNoSources && (
                            <Grid item className={classes.selectSource}>
                                <LDBasePortal
                                    ddPlaceholder={t('source_select_placeholder')}
                                    menuPlaceholder={t('source_select_search_placeholder')}
                                    options={sourceOptions}
                                    onChange={handleSourceChange}
                                    defaultValue={source}
                                    dropdownClassName={classes.dropdownClassName}
                                    isMulti={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {!isFetching && !isNoSources && (
                        <>
                            <Grid container direction="row" alignItems="center" className={classes.mb12}>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item className={classes.numberStep}>
                                                {t('global_3')}
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    {t('export_localization_select_target')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        className={isFetchingLangOptions || !targetLangs.length ? classes.hide : ''}
                                    >
                                        <Grid container direction="row" alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                <Checkbox
                                                    checked={isAllTargetSelected}
                                                    onChange={e => handleSelectAllTargets(e.target?.checked)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body2"
                                                    display="inline"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    {t('select_all_languages')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isFetchingLangOptions && (
                                <Grid container alignItems="center" justify="center" className={classes.targetList}>
                                    <Grid item>
                                        <Spinner size={30} thick={4} />
                                    </Grid>
                                </Grid>
                            )}
                            {!isFetchingLangOptions && !targetLangs?.length && (
                                <Typography variant="body2">{t('export_tm_text_1')}</Typography>
                            )}
                            {!isFetchingLangOptions && targetLangs?.length > 0 && (
                                <>
                                    {targetLangs?.length > 12 && (
                                        <Grid item>
                                            <Search className={classes.search} onChange={onChangeSearch} />
                                        </Grid>
                                    )}
                                    <Grid item className={classes.targetList}>
                                        <AutoSizer>
                                            {({ width, height }) => {
                                                return (
                                                    <List
                                                        className={classes.virtualizeList}
                                                        rowCount={Math.ceil(
                                                            targetLangsFiltered?.length / ITEMS_PER_ROW
                                                        )}
                                                        rowHeight={42}
                                                        width={width}
                                                        height={height}
                                                        noRowsRenderer={_noRowsRenderer}
                                                        rowRenderer={_rowRenderer}
                                                    />
                                                );
                                            }}
                                        </AutoSizer>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                {isNoSources || !targetLangs?.length ? (
                    <Grid wrap="nowrap" container direction="row" alignItems="center" justify={'flex-end'} spacing={2}>
                        <Grid item>
                            <ButtonBase onClick={onClose} width={100} variant="outlined">
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>

                        <Grid item>
                            <div className={classes.btnSubmit}>
                                <ButtonBase onClick={handleExport} width={100} disabled={true} variant="contained">
                                    {t('global_export')}
                                </ButtonBase>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid wrap="nowrap" container direction="row" alignItems="center" justify={'flex-end'} spacing={2}>
                        <Grid item>
                            <ButtonBase onClick={onClose} width={100} variant="outlined">
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>

                        <Grid item>
                            <div className={classes.btnSubmit}>
                                <ButtonBase
                                    onClick={handleExport}
                                    width={100}
                                    disabled={isExporting || !listTargets.length}
                                    variant="contained"
                                >
                                    {t('global_export')}
                                </ButtonBase>
                                {isExporting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Grid>
                    </Grid>
                )}
            </DialogActions>
        </>
    );
}
export default React.memo(ExportTM);
