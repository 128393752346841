import { SUPPORTED_LANGUAGES } from 'const/languageData';

export function doubleCodes(codes) {
    if (typeof codes === 'string') return codes?.length === 2 ? `${codes}_${codes}` : codes;

    if (typeof codes === 'object') {
        return codes?.map(code => (code?.length === 2 ? `${code}_${code}` : code));
    }
    return codes;
}

export function generateCode(lang) {
    if (typeof lang === 'string' && lang?.length === 2) {
        const code = SUPPORTED_LANGUAGES.find(
            el => el.id.length === 4 && el.id.toLocaleLowerCase().indexOf(lang.toLocaleLowerCase()) > -1
        );
        return code?.id || `${lang}${lang?.toUpperCase()}`;
    }
    return lang;
}
