import React from 'react';
import AuthenticationWrapper from './AuthenticationWrapper';
import ResetPasswordForm from './ResetPasswordForm';
import AuthFormWrapper from './common/AuthFormWrapper';
import AccountDiffCheck from './AccountDiffCheck';
import { useTranslation } from 'react-i18next';

function ResetPassword({ location }) {
    const { t } = useTranslation();
    return (
        <AccountDiffCheck location={location}>
            <AuthenticationWrapper>
                <AuthFormWrapper title={t(`auth_reset_your_password`)}>
                    <ResetPasswordForm />
                </AuthFormWrapper>
            </AuthenticationWrapper>
        </AccountDiffCheck>
    );
}

export default ResetPassword;
