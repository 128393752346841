import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { sendManualTrack } from 'tracker';
import { useDispatch } from 'react-redux';
import * as gridActions from './actions';
import InputText from 'components/inputs/InputText';
import Button from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 400,
        background: theme.colors.white,
        borderRadius: 4
    },
    spacing3: {
        marginBottom: theme.spacing(3)
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: theme.spacing(2)
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    options: {
        position: 'relative',
        left: -11
    }
}));

const DuplicateDatabaseSchema = object().shape({
    name: string().required('This field is required')
});

function DuplicateDatabaseForm({ onClose, workspaceId, clonedDatabase }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const [duplicateRecordOption, setDuplicateRecordOption] = React.useState(true);

    const formik = useFormik({
        initialValues: {
            name: `Copy of ${clonedDatabase?.name}` || ''
        },
        validationSchema: DuplicateDatabaseSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            sendManualTrack({
                type: `Duplicate Database`,
                customData: {
                    dbId: clonedDatabase?.id
                }
            });
            setSubmitting(true);
            dispatch(
                gridActions.duplicateDatabase({
                    ...values,
                    workspaceId,
                    clonedDatabase,
                    successCallback: () => {},
                    errorCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
            onClose && onClose();
            setStatus({ error: null });
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle title={<Typography variant="h4">{t(`duplicate_database`)}</Typography>} onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" wrap="nowrap" className={classes.root}>
                    <Grid item className={classes.spacing2}>
                        <Grid container direction="column">
                            <Grid item>
                                <InputText
                                    label={t(`database_name`)}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    errorText={errors.name}
                                    placeholder={t(`duplicate_database_name_input_placeholder`)}
                                    autoFocus
                                    autoSelect={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Button onClick={onClose} width={120} variant="outlined">
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <Button width={120} disabled={!isValid || isSubmitting} type="submit" variant="contained">
                                {t(`global_save`)}
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default React.memo(DuplicateDatabaseForm);
