import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { INPUT_HEIGHT } from 'const/style';
import { SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import PopperMenu from 'components/menus/Popper';
import i18n from 'i18n';
import { LOCALIZATION_MANAGER } from 'auth/roleConst';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    trigger: {
        height: INPUT_HEIGHT,
        borderRadius: 4,
        cursor: 'pointer',
        background: theme.colors.paleGrey,
        padding: theme.spacing(0, 2)
    },
    roleItem: {
        cursor: 'pointer',
        padding: `8px ${SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT}px`,
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    arrowDown: {
        display: 'flex',
        marginLeft: SPACING_LIST_NAME_WITH_ICON
    },
    disabled: {
        background: `${theme.colors.paleGrey} !important`,
        pointerEvents: 'none'
    }
}));

const DESCRIPTION = {
    Creator: i18n.t('creator_description'),
    Editor: i18n.t('editor_description'),
    Translator: i18n.t('translator_description'),
    Viewer: i18n.t('viewer_description'),
    Commenter: i18n.t(`commenter_description`),
    LocalizationManager: i18n.t(`localization_manager_description`)
};

function RoleSelect({
    defaultRoleName,
    workspaceRoles,
    onRoleChange,
    wrapperClassName = '',
    triggerClassName = '',
    isDisabled
}) {
    const { t } = useTranslation();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleRoleChange = role => {
        onRoleChange && onRoleChange(role);
        handleClickAway();
    };

    return (
        <>
            <Grid container>
                <Grid item className={wrapperClassName}>
                    <Grid
                        item
                        container
                        onClick={handleClick}
                        className={`${classes.trigger} ${triggerClassName} ${Boolean(anchorEl) ? 'open' : ''} ${
                            isDisabled ? classes.disabled : ''
                        }`}
                        // justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="body2">
                                {defaultRoleName === LOCALIZATION_MANAGER ? t('localization_manager') : defaultRoleName}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.arrowDown}>
                            <ArrowDownSVG />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ flex: 1 }} />
            </Grid>
            {anchorEl && (
                <PopperMenu
                    style={{ minWidth: anchorEl.offsetWidth }}
                    anchorEl={anchorEl}
                    placement={'bottom-start'}
                    handleClickAway={handleClickAway}
                >
                    <Grid container direction="column">
                        {workspaceRoles.map(role => (
                            <Grid
                                item
                                key={role?.id}
                                className={classes.roleItem}
                                onClick={e => handleRoleChange(role)}
                            >
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <Grid style={{ height: '100%' }} container direction="column" justify="center">
                                            <Grid item>
                                                <Grid container direction="row" spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {role?.name === LOCALIZATION_MANAGER
                                                                ? t('localization_manager')
                                                                : role?.name}
                                                        </Typography>
                                                    </Grid>
                                                    {defaultRoleName === role?.name && (
                                                        <Grid item>
                                                            <CheckIconSVG />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="caption">{DESCRIPTION[role?.name]}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </PopperMenu>
            )}
        </>
    );
}

export default React.memo(RoleSelect);
