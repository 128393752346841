import React from 'react';

function FilesSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Datatype/File" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M4,11.5349334 L4,3.24373334 C4,1.45493334 5.45493333,0 7.2448,0 C9.0336,0 10.4885333,1.45493334 10.4885333,3.24373334 L10.4885333,10.0917334 C10.4885333,11.2853334 9.68426667,12.2410667 8.49173333,12.2410667 C7.2992,12.2410667 6.47253333,11.3472 6.47253333,10.1546667 L6.47253333,4.3872 L7.91466667,4.3872 L7.91466667,10.1546667 C7.91466667,10.5525334 8.09386667,10.8 8.49173333,10.8 C8.8896,10.8 9.0464,10.4906667 9.0464,10.0917334 L9.0464,3.24373334 C9.0464,2.2496 8.23893333,1.44106667 7.2448,1.44106667 C6.2496,1.44106667 5.44213333,2.2496 5.44213333,3.24373334 L5.44213333,11.5349334 C5.44213333,13.1242667 6.73493333,14.4181334 8.3264,14.4181334 C9.91573333,14.4181334 11.2096,13.1242667 11.2096,11.5349334 L11.2096,7.8816 L12.6506667,7.8816 L12.6506667,11.5349334 C12.6506667,13.9189334 10.7114667,15.8602667 8.3264,15.8602667 C5.94026667,15.8602667 4,13.9189334 4,11.5349334"
                    id="Fill-1"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(FilesSVG);
