import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { AGGREGATIONS_VALUE_TO_LABEL, AGGREGATIONS_DISABLED_COLUMNS, AGGREGATIONS } from 'const';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { convertCorrectValueByType } from 'utils/gridUI/aggregation';
import RefreshSVG from 'assets/images/svg/RefreshSVG';
import Tooltip from 'components/tooltip/Base';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useAggregations } from 'hooks/gridUI';
import PopperMenu from 'components/menus/Popper';
import ListItem from 'components/list/Item';
import { checkContainId } from 'utils/clickAway';
import classNames from 'classnames';

function Footer({ column, columnWidth }) {
    const rootRef = React.useRef();
    const aggregations = useAggregations();
    const dispatch = useDispatch();

    const aggregationDetail = React.useMemo(() => {
        return aggregations?.[column?.id];
    }, [aggregations, column]);

    const columnType = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const supportList = React.useMemo(() => {
        return AGGREGATIONS?.[columnType];
    }, [columnType]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isDisabledAggregation = React.useMemo(() => {
        return AGGREGATIONS_DISABLED_COLUMNS.includes(columnType);
    }, [columnType]);

    const type = React.useMemo(() => {
        return aggregationDetail?.type;
    }, [aggregationDetail]);

    const value = React.useMemo(() => {
        return aggregationDetail?.value;
    }, [aggregationDetail]);

    const isFetching = React.useMemo(() => {
        return aggregationDetail?.isFetching;
    }, [aggregationDetail]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleClick = React.useCallback(
        e => {
            if (isDisabledAggregation) return;
            setAnchorEl(anchorEl ? null : e.currentTarget);
        },
        [anchorEl, isDisabledAggregation]
    );

    const handleClickAway = React.useCallback(e => {
        if (checkContainId(e, 'aggregation-setting')) {
            return false;
        }
        setAnchorEl(null);
    }, []);

    const handleChangeAggregationType = React.useCallback(
        ({ e, item }) => {
            dispatch(gridUIActions.changeAggregation({ columnId: column?.id, oldType: type, newType: item?.value }));
            handleClickAway(e);
        },
        [column, dispatch, type, handleClickAway]
    );

    const handleRefresh = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setIsLoading(true);
            dispatch(
                gridUIActions.changeAggregation({
                    columnId: column?.id,
                    oldType: type,
                    newType: type,
                    successCallback: () => {
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                    }
                })
            );
        },
        [dispatch, column, type]
    );

    return (
        <Grid
            ref={rootRef}
            container
            onClick={handleClick}
            className={`h-full flex-row flex-nowrap items-center justify-end cursor-pointer px-3.5`}
        >
            {isDisabledAggregation ? (
                <Grid item>
                    <p className="body2 text-secondary inline-block">{AGGREGATIONS_VALUE_TO_LABEL?.['none']}</p>
                </Grid>
            ) : (
                <div className="flex w-full justify-between gap-1 flex-nowrap items-center">
                    <Tooltip title="Refresh Aggregation">
                        <RefreshSVG
                            onClick={handleRefresh}
                            className={classNames('flex-none cursor-pointer', {
                                'animate-spin': isLoading
                            })}
                        />
                    </Tooltip>
                    <div
                        style={{ width: columnWidth - 28 - 14 }}
                        className="flex flex-row gap-1 justify-end flex-nowrap items-center"
                    >
                        <p className="caption whitespace-nowrap inline truncate">
                            {AGGREGATIONS_VALUE_TO_LABEL?.[type] || AGGREGATIONS_VALUE_TO_LABEL?.['empty']}
                        </p>
                        {isFetching ? (
                            <p className="caption" />
                        ) : (
                            <p className={'body1 inline whitespace-nowrap'}>
                                &nbsp; {convertCorrectValueByType({ type, value, column })}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div></div>
            {anchorEl && (
                <PopperMenu
                    className={'m-w-[260px] -top-2'}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                    id={`aggregation-setting`}
                >
                    <Grid container direction="column">
                        {supportList.map((item, index) => {
                            return (
                                <Grid item key={index} onClick={e => handleChangeAggregationType({ e, item })}>
                                    <ListItem isSelected={type === item?.value} name={item.label} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </PopperMenu>
            )}
        </Grid>
    );
}

Footer.propTypes = {
    column: PropTypes.object.isRequired
};

export default React.memo(Footer);
