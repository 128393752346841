import * as types from '../types';

const handler = {
    [types.SET_API_KEY](state, { payload }) {
        const { apiKey } = payload;
        return {
            ...state,
            apiKey
        };
    }
};

export default handler;
