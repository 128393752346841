import React from 'react';
function PDFFILESVG({ ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
                fill="#E2E5E7"
            />
            <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
            <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
            <path
                d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
                fill="#F15642"
            />
            <path
                d="M4.76923 14.2109C4.76923 14.0129 4.92523 13.7969 5.17648 13.7969H6.56173C7.34173 13.7969 8.04373 14.3189 8.04373 15.3194C8.04373 16.2674 7.34173 16.7954 6.56173 16.7954H5.56048V17.5874C5.56048 17.8514 5.39248 18.0006 5.17648 18.0006C4.97848 18.0006 4.76923 17.8514 4.76923 17.5874V14.2109ZM5.56048 14.5521V16.0461H6.56173C6.96373 16.0461 7.28173 15.6914 7.28173 15.3194C7.28173 14.9001 6.96373 14.5521 6.56173 14.5521H5.56048Z"
                fill="white"
            />
            <path
                d="M9.2182 18.0013C9.0202 18.0013 8.8042 17.8933 8.8042 17.6301V14.2236C8.8042 14.0083 9.0202 13.8516 9.2182 13.8516H10.5914C13.3319 13.8516 13.2719 18.0013 10.6454 18.0013H9.2182ZM9.5962 14.5836V17.2701H10.5914C12.2107 17.2701 12.2827 14.5836 10.5914 14.5836H9.5962Z"
                fill="white"
            />
            <path
                d="M14.244 14.6323V15.5856H15.7732C15.9892 15.5856 16.2052 15.8016 16.2052 16.0108C16.2052 16.2088 15.9892 16.3708 15.7732 16.3708H14.244V17.6301C14.244 17.8401 14.0947 18.0013 13.8847 18.0013C13.6207 18.0013 13.4595 17.8401 13.4595 17.6301V14.2236C13.4595 14.0083 13.6215 13.8516 13.8847 13.8516H15.99C16.254 13.8516 16.41 14.0083 16.41 14.2236C16.41 14.4156 16.254 14.6316 15.99 14.6316H14.244V14.6323Z"
                fill="white"
            />
            <path
                d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
                fill="#CAD1D8"
            />
        </svg>
    );
}
export default React.memo(PDFFILESVG);
