import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import * as clipboardy from 'clipboardy';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        overflow: 'hidden auto'
    },
    icon: {
        maxWidth: 200,
        cursor: 'pointer',
        '& svg': {
            maxWidth: 200,
            maxHeight: 200
        }
    }
}));

function importAll(r) {
    return r.keys().map((item, index) => ({
        id: item?.replace('./', ''),
        Icon: r(item)?.default
    }));
}

function Icons() {
    const classes = useStyles();
    const icons = importAll(require.context('assets/images', true, /\.[tj]sx$/));

    const copy = React.useCallback(key => {
        // eslint-disable-next-line
        const arr = key?.split(/[\|/]/);
        const filename = arr?.[arr?.length - 1]?.replace('.jsx', '');
        const content = `import ${filename} from "assets/images/${key
            ?.replace('assets/images/', '')
            ?.replace('.jsx', '')}"`;
        clipboardy.write(content);
    }, []);

    return (
        <Grid container wrap="nowrap" direction="column" className={classes.root} spacing={3}>
            <Grid item>
                <Grid container alignItems="center" direction="row" spacing={2}>
                    {icons?.map(item => {
                        const { Icon, id } = item;
                        return (
                            <Grid key={id} item>
                                <Grid
                                    container
                                    wrap="nowrap"
                                    direction="column"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid onClick={() => copy(id)} item className={classes.icon}>
                                        <Icon />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Icons;
