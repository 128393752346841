import React from 'react';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import AvatarBase from 'components/avatar/Base';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { GROUP_ADMIN_TYPES } from 'const';

export function makeGroupOption({ iconSize = 32, group, isDisabled }) {
    return {
        ...group,
        value: group.id,
        label: group.name,
        isDisabled,
        subTitle: `${group?.memberCount} members • ${
            group?.systemGroup && group?.type === GROUP_ADMIN_TYPES.ADMIN ? 'All' : group?.accessViewCount
        } views`,
        icon: () =>
            group.systemGroup ? (
                group?.type === GROUP_ADMIN_TYPES.ADMIN ? (
                    <AdminGroupAvatar size={iconSize} />
                ) : (
                    <TranslatorGroupAvatar size={iconSize} />
                )
            ) : (
                <AvatarBase size={iconSize}>{group.name.slice(0, 2)}</AvatarBase>
            ),
        moreIcon: isDisabled ? () => <UpgradeSVG /> : undefined
    };
}
