import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import * as columnTypes from 'const/columnTypes';
import RichText from 'gridUI/cellDisplay/RichText';
import MarkDown from 'gridUI/cellDisplay/MarkDown';
import MultiLines from 'gridUI/cellDisplay/MultiLines';
import SingleLine from 'gridUI/cellDisplay/SingleLine';
import SingleSelect from 'gridUI/cellDisplay/SingleSelect';
import MultipleSelect from 'gridUI/cellDisplay/MultipleSelect';
import Datetime from 'gridUI/cellDisplay/Datetime';
import LastModifiedDate from 'gridUI/cellDisplay/LastModifiedDate';
import Files from 'gridUI/cellDisplay/Files';
import Boolean from 'gridUI/cellDisplay/Boolean';
import Yaml from 'gridUI/cellDisplay/Yaml';
import Html from 'gridUI/cellDisplay/Html';
import Json from 'gridUI/cellDisplay/Json';
import PathTag from 'gridUI/cellDisplay/PathTag';
import Ref from 'gridUI/cellDisplay/Ref';
import Formula from 'gridUI/cellDisplay/Formula';
import LastModifiedBy from 'gridUI/cellDisplay/LastModifiedBy';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    }
}));

function DisplayDataByType({ columnType, ...rest }) {
    const classes = useStyles();

    const renderColumnTypeData = React.useMemo(() => {
        switch (columnType) {
            case columnTypes.SINGLE_LINE:
            case columnTypes.NUMBER:
            case columnTypes.RECORD_ID:
                return <SingleLine isReadOnly={true} {...rest} />;

            case columnTypes.ALTERED_BY:
            case columnTypes.CREATED_BY:
                return <LastModifiedBy isReadOnly={true} {...rest} />;
            case columnTypes.FORMULA:
                return <Formula isReadOnly={true} {...rest} />;
            case columnTypes.MULTIPLE_LINES:
            case columnTypes.TRANSLATION:
                return <MultiLines isReadOnly={true} {...rest} />;
            case columnTypes.RICH_TEXT:
                return <RichText isReadOnly={true} {...rest} />;
            case columnTypes.MARKDOWN:
                return <MarkDown isReadOnly={true} {...rest} />;
            case columnTypes.SINGLE_SELECTION:
                return <SingleSelect isReadOnly={true} {...rest} />;
            case columnTypes.MULTIPLE_SELECTIONS:
                return <MultipleSelect isReadOnly={true} {...rest} />;
            case columnTypes.DATETIME:
                return <Datetime isReadOnly={true} {...rest} />;
            case columnTypes.ALTERED_TIME:
            case columnTypes.CREATED_TIME:
                return <LastModifiedDate isReadOnly={true} {...rest} />;
            case columnTypes.FILES:
                return <Files isReadOnly={true} {...rest} />;
            case columnTypes.BOOLEAN:
                return <Boolean isReadOnly={true} {...rest} />;
            case columnTypes.YAML:
                return <Yaml isReadOnly={true} {...rest} />;
            case columnTypes.JSON_LD:
                return <Json isReadOnly={true} {...rest} />;
            case columnTypes.HTML:
                return <Html isReadOnly={true} {...rest} />;
            case columnTypes.PATH_TAG:
                return <PathTag isReadOnly={true} {...rest} />;
            case columnTypes.REFERENCE:
                return <Ref isReadOnly={true} {...rest} />;
            default:
                return <SingleLine isReadOnly={true} {...rest} />;
        }
    }, [columnType, rest]);

    return (
        <Grid container direction="column" className={classes.root}>
            {renderColumnTypeData}
        </Grid>
    );
}

export default DisplayDataByType;
