import React from 'react';
import { Grid } from '@material-ui/core';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { ROW_HEIGHT } from 'const/gridUI';
import Checkbox from 'components/checkbox/Base';

function ReferenceSelectRender({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex }) {
    if (isEmpty(value)) return null;
    const getJsonValue = value => {
        if (isArray(value)) {
            let values = value.map(i => {
                const referencedDataItem = i?.referencedDataItem;
                if (typeof referencedDataItem !== 'string') return null;
                return referencedDataItem;
            });
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(value);
    if (!isArray(newData) || !newData) return null;

    return (
        <Grid
            container
            className={`w-full cell_${rowIndex}_${columnIndex} flex flex-wrap gap-2`}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
                height: rowHeight,
                pointerEvents: 'none'
            }}
        >
            {newData?.map((item, index) => {
                const checked = item ? true : false;
                return (
                    <Grid
                        item
                        key={item}
                        className={`rounded px-1 py-0.5 mr-1 mb-1 bg-option-fill border border-option-stroke`}
                    >
                        <Checkbox
                            key={index}
                            checked={checked}
                            style={{
                                maxWidth: 24,
                                maxHeight: 24
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(ReferenceSelectRender);
