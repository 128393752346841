import * as types from '../types';
import { DEFAULT_RECORD_HISTORY_HEIGHT } from 'utils/gridUI';

const handler = {
    [types.OPEN_RECORD_HISTORY](state, { payload }) {
        const { rowId, rowIndex, action, message, type } = payload;
        return {
            ...state,
            isOpenRecordHistory: true,
            recordHistoryIndex: rowIndex,
            recordHistoryId: rowId,
            recordHistoryAction: action,
            recordHistoryMessage: message,
            recordHistoryType: type
        };
    },
    [types.CLOSE_RECORD_HISTORY](state) {
        return {
            ...state,
            isOpenRecordHistory: false,
            recordHistoryIndex: null,
            recordHistoryId: null,
            recordHistoryAction: null,
            recordHistoryMessage: null,
            recordHistoryType: null
        };
    },
    [types.FETCH_RECORD_HISTORY](state) {
        return {
            ...state,
            isFetchingRecordHistory: true
        };
    },
    [types.FETCH_RECORD_HISTORY_FAILED](state) {
        return {
            ...state,
            isFetchingRecordHistory: false
        };
    },
    [types.FETCH_RECORD_HISTORY_SUCCESS](state, { payload }) {
        const {
            recordHistory,
            totalRecordHistory,
            RECORD_HISTORY_ROW_START_INDEX,
            RECORD_HISTORY_ROW_STOP_INDEX
        } = payload;
        return {
            ...state,
            isFetchingRecordHistory: false,
            recordHistory: recordHistory,
            totalRecordHistory: totalRecordHistory,
            RECORD_HISTORY_ROW_START_INDEX,
            RECORD_HISTORY_ROW_STOP_INDEX
        };
    },
    [types.SET_TRIGGER_REFRESH_RECORD_HISTORY](state) {
        return {
            ...state,
            isTriggerRefreshRecordHistory: true
        };
    },
    [types.RESET_TRIGGER_REFRESH_RECORD_HISTORY](state) {
        return {
            ...state,
            isTriggerRefreshRecordHistory: false
        };
    },
    [types.FETCH_MORE_RECORD_HISTORY](state) {
        return {
            ...state,
            isFetchingMoreHistory: true
        };
    },
    [types.FETCH_MORE_RECORD_HISTORY_SUCCESS](state, { payload }) {
        const {
            recordHistory,
            totalRecordHistory,
            RECORD_HISTORY_ROW_START_INDEX,
            RECORD_HISTORY_ROW_STOP_INDEX
        } = payload;
        return {
            ...state,
            isFetchingMoreHistory: false,
            recordHistory: recordHistory,
            totalRecordHistory: totalRecordHistory,
            RECORD_HISTORY_ROW_START_INDEX,
            RECORD_HISTORY_ROW_STOP_INDEX
        };
    },
    [types.FETCH_MORE_RECORD_HISTORY_FAILED](state) {
        return {
            ...state,
            isFetchingMoreHistory: false
        };
    },
    [types.SET_RECORD_HISTORY_HEIGHT](state, { payload }) {
        const height = payload?.height || DEFAULT_RECORD_HISTORY_HEIGHT;
        return {
            ...state,
            recordHistoryHeight: height
        };
    },
    [types.RESET_RECORD_HISTORY](state) {
        return {
            ...state,
            recordHistoryIndex: null,
            recordHistoryId: null,
            recordHistoryAction: null,
            recordHistoryMessage: null,
            recordHistory: []
        };
    }
};

export default handler;
