import React from 'react';

function JsonSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.74547 1.75C2.65769 1.75 1.82335 2.59477 1.82335 3.69615V6.15769C1.82335 6.77939 1.32057 7.28846 0.706549 7.28846C0.50177 7.28846 0.323446 7.35801 0.196068 7.48698C0.0686909 7.61595 0 7.79651 0 8.00385C0 8.21119 0.0686909 8.39174 0.196068 8.52071C0.323446 8.64968 0.50177 8.71923 0.706549 8.71923C1.32057 8.71923 1.82335 9.22831 1.82335 9.85V12.3115C1.82335 13.4129 2.65769 14.2577 3.74547 14.2577C3.95025 14.2577 4.12858 14.1881 4.25595 14.0592C4.38333 13.9302 4.45202 13.7496 4.45202 13.5423C4.45202 13.335 4.38333 13.1544 4.25595 13.0254C4.12858 12.8965 3.95025 12.8269 3.74547 12.8269C3.58558 12.8269 3.46001 12.7734 3.37467 12.687C3.28932 12.6006 3.23645 12.4734 3.23645 12.3115V9.85C3.23645 9.12713 2.9555 8.45659 2.44212 8.00385C2.9555 7.5511 3.23645 6.88057 3.23645 6.15769V3.69615C3.23645 3.53426 3.28932 3.40713 3.37467 3.32071C3.46001 3.2343 3.58558 3.18077 3.74547 3.18077C3.95025 3.18077 4.12858 3.11122 4.25595 2.98225C4.38333 2.85328 4.45202 2.67272 4.45202 2.46538C4.45202 2.25804 4.38333 2.07749 4.25595 1.94852C4.12858 1.81955 3.95025 1.75 3.74547 1.75Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.31909 5.20081C8.04997 5.20081 7.81276 5.28605 7.62152 5.46321L7.61996 5.46466C7.42805 5.6463 7.33549 5.88355 7.33549 6.15764C7.33549 6.42944 7.42868 6.66471 7.61996 6.84575L7.62167 6.84733C7.81107 7.02242 8.04893 7.10471 8.31909 7.10471C8.59165 7.10471 8.83142 7.02292 9.02134 6.84734L9.02303 6.84574C9.21431 6.6647 9.30751 6.42944 9.30751 6.15764C9.30751 5.8825 9.21257 5.64509 9.01744 5.46393C8.82834 5.28533 8.59017 5.20081 8.31909 5.20081Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.01627 12.3288L8.04249 12.8724L8.14411 12.7839C8.44309 12.5234 8.68552 12.1964 8.87285 11.8067C9.06082 11.4157 9.15932 11.0212 9.16283 10.6264L9.16865 9.44885H7.5959V10.7409C7.58981 11.0971 7.4894 11.4606 7.28754 11.8329L7.01627 12.3288Z"
                fill={color}
            />
            <path
                d="M12.2545 1.75C12.0497 1.75 11.8714 1.81955 11.744 1.94852C11.6167 2.07749 11.548 2.25804 11.548 2.46538C11.548 2.67272 11.6167 2.85328 11.744 2.98225C11.8714 3.11122 12.0497 3.18077 12.2545 3.18077C12.4144 3.18077 12.54 3.2343 12.6253 3.32071C12.7107 3.40713 12.7635 3.53426 12.7635 3.69615V6.15769C12.7635 6.88279 13.0462 7.55522 13.5626 8.00801C13.1013 8.45648 12.7635 9.12299 12.7635 9.85V12.3115C12.7635 12.4734 12.7107 12.6006 12.6253 12.687C12.54 12.7734 12.4144 12.8269 12.2545 12.8269C12.0497 12.8269 11.8714 12.8965 11.744 13.0254C11.6167 13.1544 11.548 13.335 11.548 13.5423C11.548 13.7496 11.6167 13.9302 11.744 14.0592C11.8714 14.1881 12.0497 14.2577 12.2545 14.2577C13.3423 14.2577 14.1766 13.4129 14.1766 12.3115V9.85C14.1766 9.22831 14.6794 8.71923 15.2935 8.71923C15.4982 8.71923 15.6766 8.64968 15.8039 8.52071C15.9313 8.39174 16 8.21119 16 8.00385C16 7.79651 15.9313 7.61595 15.8039 7.48698C15.6766 7.35801 15.4982 7.28846 15.2935 7.28846C14.6794 7.28846 14.1766 6.77939 14.1766 6.15769V3.69615C14.1766 2.59477 13.3423 1.75 12.2545 1.75Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(JsonSVG);
