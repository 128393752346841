import React from 'react';

function H3IconSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" {...other} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.7119 17.2021C12.7119 17.6428 12.3547 18 11.914 18C11.4734 18 11.1162 17.6428 11.1162 17.2021V12.1249H5.5957V17.2022C5.5957 17.6428 5.23849 18 4.79785 18C4.35721 18 4 17.6428 4 17.2021V6.79785C4 6.35721 4.35721 6 4.79785 6C5.23849 6 5.5957 6.35721 5.5957 6.79785V10.7065H11.1162V6.79785C11.1162 6.35721 11.4734 6 11.914 6C12.3547 6 12.7119 6.35721 12.7119 6.79785V17.2021Z"
                fill={color}
            />
            <path
                d="M16.6123 18.3584C18.248 18.3584 19.3564 17.5088 19.3564 16.2295C19.3564 15.2578 18.6777 14.6426 17.6279 14.5449V14.5156C18.458 14.3447 19.0977 13.7588 19.0977 12.8652C19.0977 11.7373 18.0918 11 16.6025 11C15.3574 11 14.5566 11.5762 14.2441 12.2646C14.1709 12.4307 14.1367 12.5723 14.1367 12.7383C14.1367 13.0947 14.3467 13.334 14.7568 13.334C15.0889 13.334 15.2598 13.2119 15.4209 12.8799C15.6504 12.3818 16.0117 12.1328 16.6074 12.1328C17.3203 12.1328 17.7012 12.5088 17.7012 13.0752C17.7012 13.6514 17.2227 14.0518 16.4854 14.0518H16.1973C15.8408 14.0518 15.6357 14.2666 15.6357 14.5742C15.6357 14.8916 15.8408 15.1016 16.1973 15.1016H16.5049C17.3594 15.1016 17.8623 15.4824 17.8574 16.166C17.8574 16.752 17.3594 17.1719 16.6367 17.1719C15.9043 17.1719 15.5234 16.8789 15.2744 16.3857C15.1279 16.1172 14.9424 16 14.6494 16C14.2441 16 14 16.2393 14 16.6201C14 16.7666 14.0342 16.9229 14.1123 17.084C14.4443 17.7773 15.2695 18.3584 16.6123 18.3584Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(H3IconSVG);
