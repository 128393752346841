import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ROW_HEIGHT, TEXT_LINE_HEIGHT, RECORD_PANEL_EDITOR_ROW_HEIGHT } from 'const/gridUI';
import { isKbTab, isKbShiftTab, isKbEscape, isKbEnter, isKbShiftEnter } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as gridActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';
import TextareaAutosize from 'react-autosize-textarea';
import { moveCaretAtEnd } from 'utils/cursor';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative',
        overflow: 'hidden'
    },

    input: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        padding: `8px 10px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
        resize: 'none !important',
        minHeight: ROW_HEIGHT,
        maxHeight: RECORD_PANEL_EDITOR_ROW_HEIGHT,
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        border: 'none',
        outline: 'none',
        color: theme.colors.dimGrey
    }
}));

function MultiLines({ value: cellValue, rowId, columnId, isReadOnly, isCellClickAway, onClose }) {
    const classes = useStyles({ isReadOnly });
    const [value, setValue] = React.useState(cellValue);
    const dispatch = useDispatch();
    const isEnterAlready = React.useRef(false);
    const rootRef = React.useRef();
    const inputRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    React.useEffect(() => {
        setValue(cellValue);
    }, [cellValue]);

    const isSameData = React.useCallback(() => {
        if ((cellValue === null || !cellValue) && !value) return true;
        return value === cellValue;
    }, [cellValue, value]);

    const updateCellData = React.useCallback(() => {
        dispatch(
            gridActions.updateCellValueWithoutCellInfo({
                value,
                rowId,
                columnId,
                columnType: columnTypes.MULTIPLE_LINES
            })
        );
    }, [dispatch, value, rowId, columnId]);

    const handleClickAway = React.useCallback(
        e => {
            if (!isSameData()) {
                blurInput();
                updateCellData();
            }

            onClose && onClose();
        },
        [updateCellData, isSameData, onClose]
    );

    React.useEffect(() => {
        if (isCellClickAway) {
            handleClickAway();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCellClickAway]);

    const handleKeyDown = e => {
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            if (isSameData()) {
                blurInput();
                onClose && onClose();
                return;
            }
            isEnterAlready.current = true;
            blurInput();
            onClose && onClose();
            return updateCellData();
        }

        if (isKbTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                blurInput();
                onClose && onClose();
                return;
            }
            isEnterAlready.current = true;
            blurInput();
            onClose && onClose();
            return updateCellData();
        }

        if (isKbShiftTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                blurInput();
                onClose && onClose();
                return;
            }
            isEnterAlready.current = true;
            blurInput();
            onClose && onClose();
            return updateCellData();
        }

        if (isKbEscape(e)) {
            blurInput();
            setValue(cellValue);
            onClose && onClose();
        }
    };

    const blurInput = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    return (
        <div className={classes.root} ref={rootRef}>
            <TextareaAutosize
                rows={4}
                ref={inputRef}
                className={`${classes.input}`}
                disabled={isReadOnly}
                style={{
                    background: 'transparent'
                }}
                onKeyDown={handleKeyDown}
                onFocus={moveCaretAtEnd}
                onChange={e => setValue(e.target.value)}
                value={value || ''}
            />
        </div>
    );
}

export default React.memo(MultiLines);
