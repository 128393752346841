import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { Typography, Chip, Grid } from '@material-ui/core';
import CloseIcon from 'assets/images/svg/CloseIconSVG';
import hexToRgba from 'hex-to-rgba';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { INPUT_PADDING_LEFT, INPUT_RADIUS, INPUT_HEIGHT } from 'const/style';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import Option from './common/Option';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';
import { AutoSizer } from 'react-virtualized-dn';
import PopperMenu from 'components/menus/Popper';
import ConfigTimezone from 'components/timezone/ConfigTimezone';
import * as columnTypes from 'const/columnTypes';
import Tooltip from 'components/tooltip/Base';
import classnames from 'classnames';
import i18n from 'i18n';
// import OverflowTypography from 'components/textTruncated/OverflowTypography';

const useStyles = makeStyles(theme => ({
    dropdown: {
        position: 'relative',
        minWidth: 100
    },
    control: {
        width: '100%',
        borderRadius: INPUT_RADIUS,
        border: props =>
            props.isOpen
                ? props.isHighlight
                    ? `1px solid ${theme.palette.primary.main} !important`
                    : `1px solid ${theme.colors.border}`
                : `1px solid ${theme.colors.border}`,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText
    },
    disabled: {
        background: theme.colors.paleGrey,
        pointerEvents: 'none'
    },
    singleValueStyle: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        '& > svg, & img': {
            minWidth: 16
        },
        '& p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    multiValueDelete: {
        display: 'flex',
        alignItems: 'center'
    },
    closeIcon: {
        color: theme.colors.secondaryText
    },
    iconCenter: {
        display: 'flex'
    },
    expand: {
        flex: 1
    },
    chip: {
        border: `1px solid ${hexToRgba(theme.colors.highlight, 0.3)}`,
        backgroundColor: hexToRgba(theme.colors.highlight, 0.1),
        marginRight: 4,
        marginTop: 2,
        marginBottom: 2,
        padding: 4,
        height: 28,
        '& .MuiAvatar-root': {
            marginLeft: 1,
            color: theme.colors.white,
            minWidth: 22,
            minHeight: 22
        },
        '& .MuiChip-avatar': {
            minWidth: 14,
            minHeight: 14
        },
        '& > svg': {
            marginLeft: theme.spacing(1)
        }
    },
    prefixLabel: {
        flex: 'none'
    }
}));

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function LDBasePortal({
    ddPlaceholder = i18n.t('global_label_select_an_option'),
    menuPlaceholder = i18n.t('global_find_a_type'),
    isMulti = false,
    defaultValue,
    onChange,
    selectedColor,
    options = [],
    maxSize = 200,
    isHighlight = true,
    isCanClear = false,
    dropdownClassName,
    controlClassName,
    disabledMenu,
    isDisabled,
    isUsingContainer = false,
    placement = 'bottom',
    children,
    isUsingChildren = false,
    customNode,
    handleChangeTimezone,
    showTimezoneSetting,
    iconPlaceholder,
    afterToggleOpen,
    id,
    showTooltip = false,
    popperStyle = {},
    closedOnSelect = true,
    ddPlaceholderClassname,
    chipClassName,
    popperClassName,
    endAdorment,
    isNotDisplayTarget = false,
    hideOverflowSelectedOptions = false,
    itemShowOverflow = 2,
    popperContainer = null,
    prefixLabelClassName,
    ...other
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const rootRef = React.useRef();
    const portalRef = React.useRef();

    useClickAwaitListener(portalRef, e => {
        if (isUsingContainer && anchorEl) {
            handleClickAway(e);
        }
    });

    const setAnchorAndAfterToggleOpen = React.useCallback(
        ({ anchorEl, isOpen, defaultValue }) => {
            setAnchorEl(anchorEl);
            afterToggleOpen && afterToggleOpen({ isOpen, defaultValue });
        },
        [afterToggleOpen]
    );

    const toggleOpen = React.useCallback(
        e => {
            if (document.getElementById('popper-config-timezone')) {
                return;
            }
            setAnchorAndAfterToggleOpen({
                anchorEl: anchorEl ? null : e.currentTarget,
                isOpen: !anchorEl,
                defaultValue
            });
        },
        [setAnchorAndAfterToggleOpen, anchorEl, defaultValue]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorAndAfterToggleOpen({
            anchorEl: null,
            isOpen: false,
            defaultValue
        });
    }, [defaultValue, setAnchorAndAfterToggleOpen]);

    const isOpen = Boolean(anchorEl);

    const classes = useStyles({ selectedColor, isOpen, isHighlight });
    const theme = useTheme();
    const selectStyles = React.useMemo(() => {
        return {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit'
                }
            })
        };
    }, [theme]);

    const handleOnChange = React.useCallback(
        option => {
            onChange(option);
            if (closedOnSelect) {
                setAnchorAndAfterToggleOpen({
                    anchorEl: null,
                    isOpen: false,
                    defaultValue: option
                });
            }
        },
        [onChange, closedOnSelect, setAnchorAndAfterToggleOpen]
    );

    const handleDelete = React.useCallback(
        index => {
            const cpArr = [...defaultValue];
            cpArr.splice(index, 1);
            onChange(cpArr);
        },
        [defaultValue, onChange]
    );

    const Target = React.useMemo(() => {
        if (!defaultValue || isNotDisplayTarget)
            return (
                <Grid
                    container
                    className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                >
                    {iconPlaceholder && (
                        <Grid item style={{ display: 'flex', marginRight: 8 }}>
                            {iconPlaceholder}
                        </Grid>
                    )}
                    <Grid item style={{ flex: 1 }}>
                        <Typography variant="caption" className={ddPlaceholderClassname}>
                            {ddPlaceholder}
                        </Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        <ArrowDownSVG />
                    </Grid>
                </Grid>
            );

        if (defaultValue && isMulti) {
            if (defaultValue.length > itemShowOverflow && hideOverflowSelectedOptions) {
                const hiddenOptions =
                    defaultValue.length > itemShowOverflow ? defaultValue.slice(0, itemShowOverflow) : [];
                return (
                    <Grid
                        container
                        wrap="nowrap"
                        justify="space-between"
                        alignItems="center"
                        className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                    >
                        <Grid item style={{ flex: 1 }}>
                            {isEmpty(defaultValue) && (
                                <Typography variant="caption" className={ddPlaceholderClassname}>
                                    {ddPlaceholder}
                                </Typography>
                            )}
                            {!isEmpty(defaultValue) &&
                                hiddenOptions.map((item, index) => {
                                    const itemColor = item?.color;
                                    return (
                                        <Chip
                                            avatar={typeof item.icon === 'function' ? item.icon() : item.icon}
                                            size="small"
                                            key={index}
                                            label={item.label}
                                            onDelete={() => handleDelete(index)}
                                            className={classnames(classes.chip, chipClassName)}
                                            style={{
                                                background: itemColor ? hexToRgba(itemColor, 0.1) : ``,
                                                border: itemColor ? `1px solid ${hexToRgba(itemColor, 0.3)}` : ``
                                            }}
                                            deleteIcon={<CloseIcon className={'flex-none'} size={12} />}
                                        />
                                    );
                                })}
                        </Grid>
                        <Typography variant="caption" className={ddPlaceholderClassname}>
                            <Chip
                                size="small"
                                label={`+${defaultValue.length - itemShowOverflow} selected`}
                                className={classnames(classes.chip, chipClassName)}
                            />
                        </Typography>
                        <Grid item className={classes.multiValueDelete}>
                            <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                                {isCanClear && !isEmpty(defaultValue) && (
                                    <Grid item style={{ display: 'flex' }}>
                                        <CloseIcon
                                            className={classes.closeIcon}
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleOnChange([]);
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid item style={{ display: 'flex' }}>
                                    <ArrowDownSVG />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
            return (
                <Grid
                    container
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                >
                    <Grid item style={{ flex: 1 }}>
                        {isEmpty(defaultValue) && (
                            <Typography variant="caption" className={ddPlaceholderClassname}>
                                {ddPlaceholder}
                            </Typography>
                        )}
                        {!isEmpty(defaultValue) &&
                            defaultValue.map((item, index) => {
                                const itemColor = item?.color;
                                return (
                                    <Chip
                                        avatar={typeof item.icon === 'function' ? item.icon() : item.icon}
                                        size="small"
                                        key={index}
                                        label={item.label}
                                        onDelete={() => handleDelete(index)}
                                        className={classnames(classes.chip, chipClassName)}
                                        style={{
                                            background: itemColor ? hexToRgba(itemColor, 0.1) : ``,
                                            border: itemColor ? `1px solid ${hexToRgba(itemColor, 0.3)}` : ``
                                        }}
                                        deleteIcon={<CloseIcon className={'flex-none'} size={12} />}
                                    />
                                );
                            })}
                    </Grid>
                    <Grid item className={classes.multiValueDelete}>
                        <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                            {isCanClear && !isEmpty(defaultValue) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleOnChange([]);
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item style={{ display: 'flex' }}>
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        if (defaultValue && !isMulti)
            return (
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                    wrap="nowrap"
                >
                    <Grid
                        item
                        style={{ width: `calc(100% - ${isCanClear ? 40 : 16}px)` }}
                        className={classes.singleValueStyle}
                    >
                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                            {defaultValue.prefixLabel && (
                                <Typography
                                    variant="body2"
                                    component="div"
                                    className={`${classes.prefixLabel} ${prefixLabelClassName}`}
                                >
                                    {defaultValue.prefixLabel}
                                </Typography>
                            )}
                            {defaultValue.icon && (
                                <Grid item className={classes.iconCenter}>
                                    {typeof defaultValue.icon === 'function' ? defaultValue.icon() : defaultValue.icon}
                                </Grid>
                            )}
                            <Grid
                                item
                                style={{
                                    width: '100%',
                                    height: 28
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                    // textOverflow: 'ellipsis'
                                }}
                            >
                                {/* <OverflowTypography truncated={true} variant="body2" style={{ display: 'block' }}>
                                    {' '}
                                    {defaultValue.label}
                                </OverflowTypography> */}
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <Tooltip title={showTooltip ? defaultValue.label : ''}>
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    width,
                                                    height
                                                }}
                                                variant="body2"
                                                component="div"
                                            >
                                                {defaultValue.label}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </AutoSizer>
                            </Grid>
                            {endAdorment}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1} wrap="nowrap">
                            {isCanClear && !isEmpty(defaultValue) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleOnChange(null);
                                        }}
                                    />
                                </Grid>
                            )}
                            {showTimezoneSetting && defaultValue.type === columnTypes.DATETIME && (
                                <ConfigTimezone
                                    style={{ display: 'flex' }}
                                    value={defaultValue.timezone}
                                    onChange={handleChangeTimezone}
                                    anchorRef={rootRef}
                                />
                            )}
                            <Grid item style={{ display: 'flex' }}>
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );

        return null;
    }, [
        defaultValue,
        classes.control,
        classes.disabled,
        classes.multiValueDelete,
        classes.closeIcon,
        classes.singleValueStyle,
        classes.iconCenter,
        classes.chip,
        classes.prefixLabel,
        controlClassName,
        isDisabled,
        iconPlaceholder,
        showTooltip,
        ddPlaceholderClassname,
        chipClassName,
        ddPlaceholder,
        isMulti,
        isCanClear,
        showTimezoneSetting,
        handleChangeTimezone,
        handleDelete,
        handleOnChange,
        endAdorment,
        isNotDisplayTarget,
        hideOverflowSelectedOptions,
        itemShowOverflow,
        prefixLabelClassName
    ]);

    const filterOptions = React.useCallback(
        inputValue => {
            const filterValues = options.filter(i =>
                i?.searchLabel
                    ? i?.searchLabel?.toLowerCase()?.includes(inputValue?.toLowerCase())
                    : i?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
            );
            return filterValues?.slice(0, maxSize);
        },
        [maxSize, options]
    );

    const loadOptions = React.useCallback(
        (inputValue, callback) => {
            setTimeout(() => {
                callback(filterOptions(inputValue));
            }, 300);
        },
        [filterOptions]
    );

    return (
        <>
            <Grid
                ref={rootRef}
                onClick={toggleOpen}
                className={`${classes.dropdown} ${dropdownClassName} ${isDisabled ? classes.disabled : ``}`}
            >
                {isUsingChildren
                    ? typeof children === 'function'
                        ? children({ open: Boolean(anchorEl) })
                        : children
                    : Target}
            </Grid>
            <div className={'childPortal'} ref={portalRef}></div>
            {anchorEl && (
                <PopperMenu
                    className={`popper ${popperClassName} ${disabledMenu && classes.disabled}`}
                    style={{ width: anchorEl.offsetWidth, ...popperStyle }}
                    anchorEl={anchorEl}
                    handleClickAway={handleClickAway}
                    container={isUsingContainer ? portalRef.current : popperContainer}
                    placement={placement}
                    id={id || 'portal-popper'}
                >
                    {customNode ? (
                        customNode
                    ) : (
                        <AsyncSelect
                            cacheOptions
                            autoFocus={!disabledMenu}
                            defaultOptions={options.slice(0, maxSize)}
                            loadOptions={loadOptions}
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            placeholder={menuPlaceholder}
                            isClearable={false}
                            tabSelectsValue={false}
                            components={components}
                            defaultValue={defaultValue}
                            styles={selectStyles}
                            onChange={handleOnChange}
                            classes={classes}
                            menuIsOpen
                            isMulti={isMulti}
                            showTooltip={showTooltip}
                            setOpenState={setAnchorEl}
                            {...other}
                        />
                    )}
                </PopperMenu>
            )}
        </>
    );
}

LDBasePortal.propTypes = {
    ddPlaceholder: PropTypes.string,
    menuPlaceholder: PropTypes.string,
    isMulti: PropTypes.bool,
    defaultValue: PropTypes.any
};

export default React.memo(LDBasePortal);
