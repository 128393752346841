import React from 'react';

function PartialAccessSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Common/PartialAccessIcon">
                <circle id="Ellipse 50" cx="8" cy="8" r="7" fill="#2D97F2" fillOpacity="0.8" />
                <path
                    id="Path"
                    d="M10.9172 7.40723C11.2564 7.40723 11.5314 7.68222 11.5314 8.02145V8.10919C11.5314 8.44842 11.2564 8.72341 10.9172 8.72341H5.12594C4.78671 8.72341 4.51172 8.44842 4.51172 8.10919V8.02145C4.51172 7.68222 4.78671 7.40723 5.12594 7.40723H10.9172Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}

export default React.memo(PartialAccessSVG);
