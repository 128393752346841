import * as types from '../types';

const handler = {
    [types.SET_LQA_MODELS](state, { payload }) {
        return {
            ...state,
            lqaModels: payload
        };
    },
    [types.SET_LQA_MODEL_DEFAULT](state, { payload }) {
        return {
            ...state,
            lqaModelDefault: payload
        };
    },
    [types.OPEN_LQA_STICKY](state, { payload }) {
        return {
            ...state,
            openLqaSticky: payload
        };
    },
    [types.SET_LQA_STICKY_DATA](state, { payload }) {
        return {
            ...state,
            lqaStickyData: payload
        };
    },
    [types.SET_OPEN_LQA_TICKET_DETAIL](state, { payload }) {
        return {
            ...state,
            openLQATicketDetail: payload
        };
    },
    [types.SET_LQA_TICKET_DETAIL](state, { payload }) {
        return {
            ...state,
            lqaTicketDetail: payload
        };
    },
    [types.SET_LQA_CONFIRM_DELETE_TICKET](state, { payload }) {
        return {
            ...state,
            confirmDeleteTicket: payload
        };
    },
    [types.ADD_PROJECT_TO_LQA](state, { payload }) {
        const { id, addProject } = payload;
        const { lqaModels } = state;
        const newLqaModels = lqaModels.map(lqa => {
            if (lqa?.id === id) {
                lqa.projects = [...lqa?.projects, addProject];
            }
            return lqa;
        });
        return {
            ...state,
            lqaModels: newLqaModels
        };
    },
    [types.REMOVE_PROJECT_TO_LQA](state, { payload }) {
        const { id, projectId } = payload;
        const { lqaModels } = state;
        const newLqaModels = lqaModels.map(lqa => {
            if (lqa?.id === id) {
                lqa.projects = [...lqa?.projects]?.map(project => {
                    if (project?.id === projectId) {
                        project.isDeleted = true;
                    }
                    return project;
                });
            }
            return lqa;
        });
        return {
            ...state,
            lqaModels: newLqaModels
        };
    },
    [types.REMOVE_PROJECT_TO_LQA_FAILED](state, { payload }) {
        const { id, projectId } = payload;
        const { lqaModels } = state;
        const newLqaModels = lqaModels.map(lqa => {
            if (lqa?.id === id) {
                lqa.projects = [...lqa?.projects]?.map(project => {
                    if (project?.id === projectId) {
                        project.isDeleted = false;
                    }
                    return project;
                });
            }
            return lqa;
        });
        return {
            ...state,
            lqaModels: newLqaModels
        };
    },
    [types.REMOVE_PROJECT_TO_LQA_SUCCESS](state, { payload }) {
        const { id, projectId } = payload;
        const { lqaModels } = state;
        const newLqaModels = lqaModels.map(lqa => {
            if (lqa?.id === id) {
                lqa.projects = [...lqa?.projects]?.filter(project => project?.id !== projectId);
            }
            return lqa;
        });
        return {
            ...state,
            lqaModels: newLqaModels
        };
    },
    [types.SET_LQA_TICKET](state, { payload }) {
        return {
            ...state,
            lqaTicket: { ...state.lqaTicket, ...payload }
        };
    },
    [types.SET_LQA_TOTAL](state, { payload }) {
        return {
            ...state,
            lqaTotal: { ...state.lqaTotal, ...payload }
        };
    },
    [types.SET_LQA_TICKET_AND_TOTAL](state, { payload }) {
        return {
            ...state,
            lqaTicket: { ...state.lqaTicket, ...payload.lqaTicket },
            lqaTotal: { ...state.lqaTotal, ...payload.lqaTotal }
        };
    },
    [types.SET_LQA_RESET_QUERY](state, { payload }) {
        return {
            ...state,
            lqaResetQuery: payload
        };
    },
    [types.SET_LQA_PAGE_NUMBER](state, { payload }) {
        return {
            ...state,
            lqaPageNumber: { ...state.lqaPageNumber, ...payload }
        };
    },
    [types.SET_LQA_TAB](state, { payload }) {
        return {
            ...state,
            lqaTab: payload
        };
    },
    [types.SET_LQA_TOTAL_INCREASE_DECREASE](state, { payload }) {
        const { lqaTotal } = state;
        if (payload.increaseStatus) {
            lqaTotal[payload.increaseStatus] = lqaTotal[payload.increaseStatus] + 1;
        }
        if (payload.decreaseStatus) {
            lqaTotal[payload.decreaseStatus] =
                lqaTotal[payload.decreaseStatus] - 1 >= 0 ? lqaTotal[payload.decreaseStatus] - 1 : 0;
        }
        return {
            ...state,
            lqaTotal: { ...lqaTotal }
        };
    },
    [types.RESET_DEFAULT_LQA](state, { payload }) {
        return {
            ...state,
            ...payload
        };
    },
    [types.SET_LQA_EXPORTING](state, { payload }) {
        return {
            ...state,
            lqaExporting: payload
        };
    },
    [types.SET_LQA_CREATE_TYPE](state, { payload }) {
        return {
            ...state,
            lqaCreateType: payload
        };
    }
};

export default handler;
