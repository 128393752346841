import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AvatarBase from 'components/avatar/Base';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getAvatarUrl } from 'utils/images';
import { generateUserBadge } from 'utils/user';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 240,
        maxWidth: 320
    },
    background: {
        background: theme.colors.lavender,
        height: 90,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6
    },
    info: {
        padding: theme.spacing(4),
        position: 'relative',
        marginTop: theme.spacing(2)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    avatar: {
        position: 'relative',
        top: 30
    }
}));

function ProfileCard({ member }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item container alignItems="center" justify="center" className={classes.background}>
                <Grid item className={classes.avatar}>
                    <AvatarBase
                        badgeContent={generateUserBadge(member?.user)}
                        src={getAvatarUrl(member?.user?.imageUrl)}
                        size={80}
                    >
                        <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                    </AvatarBase>
                </Grid>
            </Grid>
            <Grid item container className={classes.info} direction="column">
                <Grid item>
                    <Grid container direction="column" alignItems="center" justify="center">
                        <Grid item>
                            <h4 className="prose prose-lg font-medium">{member?.user?.fullName}</h4>
                        </Grid>
                        <Grid item>
                            <p className="caption">{member?.role?.name}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.divider}>
                    <Divider />
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item>
                                <p className="caption">Local time</p>
                            </Grid>
                            <Grid item>
                                <p className="body2">11:09 AM (GMT+7)</p>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={1}>
                            <Grid item>
                                <p className="caption">Email</p>
                            </Grid>
                            <Grid item>
                                <p className="body2">{member?.user?.email}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ProfileCard);
