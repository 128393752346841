import React from 'react';
import {
    useRows,
    useMetaDataWithReferenceGridDetail,
    useQuickSorts,
    useQuickFilters,
    useViewColumnsWithReOrder,
    useTotalRecords,
    useSectionRowStartIndex,
    useSectionRowStopIndex,
    useIsFilteringSorting,
    useFixedColumnCount,
    useColumnWidthStore,
    useIsRecomputedGrid,
    useDependencyList,
    useIsPathTagOn,
    useIsTmDisabled,
    useRowHeight,
    useIsDisabledCreateRecord,
    useIsDisabledUpdateColumn,
    useIsDeleteRecordState,
    useIsShowTag,
    useTokenDetection,
    usePredefinedTokens,
    useIsShowAutoQA,
    useTotalRecordsWithoutFilter
} from 'hooks/gridUI';
import { useCurrentUserLanguagePairs, useFillColorBlindness, useDependencyBlindness } from 'hooks/auth';
import { useIsGridImporting } from 'hooks/grid';
import * as roleConst from 'auth/roleConst';
import { useContextMenuId } from 'hooks/app';
import { FIXED_ROW_COUNT, FAKE_ROW, TAG_DETECT_MODE } from 'const/gridUI';
import { USAGE_PROPERTIES } from 'const';
import { useIsOverThresHoldProperty } from 'hooks/payment/usage';
import { useLdUserEmailMap } from 'hooks/permission';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import GridTable from './Grid3';
import { useRole } from 'hooks/auth/role';

function GridTUI({ dbId, branchId, viewId, gridId, workspaceId, isShareViewLink, t, width, height }) {
    const roles = useRole();
    const rows = useRows();
    const metaData = useMetaDataWithReferenceGridDetail(dbId);
    const dispatch = useDispatch();
    const languagePairs = useCurrentUserLanguagePairs();
    const totalRecords = useTotalRecords() || 0;

    const totalRecordWithoutFilters = useTotalRecordsWithoutFilter() || 0;

    const fillColorBlindness = useFillColorBlindness();
    const dependencyBlindness = useDependencyBlindness();

    // const cellCopySelection = useCellCopySelection();

    const isDisabledCreateRecord = useIsDisabledCreateRecord({ dbId, gridId });
    const isDisabledUpdateColumn = useIsDisabledUpdateColumn({ dbId, gridId });

    const isTmDisabled = useIsTmDisabled();
    const rowHeight = useRowHeight();
    const isImporting = useIsGridImporting({ dbId, branchId });
    const quickSorts = useQuickSorts();
    const quickFilters = useQuickFilters();
    const ROW_START_INDEX = useSectionRowStartIndex();
    const ROW_STOP_INDEX = useSectionRowStopIndex();
    const isFilteringSorting = useIsFilteringSorting();
    const fixedColumnCount = useFixedColumnCount() || 0;
    const dependencies = useDependencyList();
    const isRecomputedGrid = useIsRecomputedGrid();
    const isDeletingRecords = useIsDeleteRecordState();

    const accessRecordHistory = roles[roleConst.EXTRA_AUTHORITIES.SHOW_RECORD_HISTORY];
    const accessManageGridRecord = roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD];
    const accessManageGridColumn = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN];
    const accessManageTicket = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_TICKET];
    const accessSupportSelectAllCheckbox = roles[roleConst.SUPPORT_SELECT_ALL_CHECKBOX];
    const contextMenuId = useContextMenuId();

    //Active users
    // const activeUsersOnCell = useActiveUsersOnCell();

    //path Tag
    const isPathTagOn = useIsPathTagOn();
    const viewColumns = useViewColumnsWithReOrder();

    const columns = React.useMemo(() => {
        return viewColumns?.map(column => column?.id);
    }, [viewColumns]);

    const workspaceRole = roles?.[roleConst.WORKSPACE_ROLE];
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];
    const accessRecordCombined = React.useMemo(() => {
        return workspaceRole === roleConst.TRANSLATOR ? accessEditTranslation : accessEditRecords;
    }, [accessEditRecords, accessEditTranslation, workspaceRole]);

    const columnWidthStore = useColumnWidthStore();

    const isOverFileStorage = useIsOverThresHoldProperty(USAGE_PROPERTIES.MAX_STORAGE_GB);
    const ldUserEmailMap = useLdUserEmailMap();
    const isShowTag = useIsShowTag({ dbId, gridId: branchId });

    const isShowAutoQA = useIsShowAutoQA();

    const tokenDetection = useTokenDetection({ dbId, gridId: branchId });
    const predefinedTokens = usePredefinedTokens();

    React.useEffect(() => {
        if (!workspaceRole || !viewColumns?.length === 0) return;

        if (workspaceRole && workspaceRole === roleConst.TRANSLATOR) {
            dispatch(gridUIActions.addGridDisabledSourceColumns({ languagePairs }));
            // dispatch(gridUIActions.openDefaultTranslatorView());
        } else {
            dispatch(gridUIActions.resetDisabledSourceColumns());
        }
    }, [workspaceRole, viewColumns.length, dispatch, languagePairs]);

    React.useEffect(() => {
        if (tokenDetection && tokenDetection?.tokenSetId && tokenDetection?.mode === TAG_DETECT_MODE.PREDEFINED) {
            dispatch(
                gridUIActions.getTokenSets({
                    tokenSetId: tokenDetection?.tokenSetId,
                    params: {
                        limit: -1
                    },
                    successCallback: tokens => {
                        dispatch(gridUIActions.setPredefinedTokens(tokens?.data?.map(token => token?.value)));
                    },
                    errorCallback: () => {
                        console.log('failed');
                    }
                })
            );
        }
    }, [tokenDetection, dispatch]);

    // console.log('quickFilters', quickFilters);

    return (
        <GridTable
            workspaceId={workspaceId}
            gridId={gridId}
            viewId={viewId}
            dbId={dbId}
            branchId={branchId}
            isPathTagOn={isPathTagOn}
            isRecomputedGrid={isRecomputedGrid}
            accessSupportSelectAllCheckbox={accessSupportSelectAllCheckbox}
            accessEditRecords={accessRecordCombined}
            accessRecordHistory={accessRecordHistory}
            accessEditTranslation={accessEditTranslation}
            accessManageGridRecord={accessManageGridRecord}
            accessManageGridColumn={accessManageGridColumn}
            accessManageTicket={accessManageTicket}
            dependencies={dependencies}
            metaData={metaData}
            rows={rows}
            totalRecords={totalRecords + FAKE_ROW}
            totalRecordWithoutFilters={totalRecordWithoutFilters}
            columns={columns}
            width={width}
            maxHeight={height}
            rowHeight={rowHeight}
            fixedColumnCount={Math.max(Math.min((columns?.length || 0) - 1, fixedColumnCount), 0)}
            fixedRowCount={FIXED_ROW_COUNT}
            quickSorts={quickSorts}
            quickFilters={quickFilters}
            ROW_START_INDEX={ROW_START_INDEX}
            ROW_STOP_INDEX={ROW_STOP_INDEX}
            isFilteringSorting={isFilteringSorting}
            viewColumns={viewColumns}
            columnWidthStore={columnWidthStore}
            contextMenuId={contextMenuId}
            isImporting={isImporting}
            // cellCopySelection={cellCopySelection}
            isTmDisabled={isTmDisabled}
            isShareViewLink={isShareViewLink}
            t={t}
            isDisabledCreateRecord={isDisabledCreateRecord}
            dependencyBlindness={dependencyBlindness}
            fillColorBlindness={fillColorBlindness}
            isOverFileStorage={isOverFileStorage}
            isDisabledUpdateColumn={isDisabledUpdateColumn}
            ldUserEmailMap={ldUserEmailMap}
            isDeletingRecords={isDeletingRecords}
            isShowTag={isShowTag}
            tokenDetection={tokenDetection}
            predefinedTokens={predefinedTokens}
            isShowAutoQA={isShowAutoQA}
        />
    );
}

export default React.memo(GridTUI);
