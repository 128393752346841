import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LDBasePortal from 'components/selects/LDBasePortal';
import * as gridActions from 'grids/actions';
import * as databaseActions from 'databases/actions';
import { useSelectedWorkspace, useWorkspaceList } from 'hooks/workspace';
import { useDatabasesOptionsByWorkspaceId } from 'hooks/database';
import * as roleConst from 'auth/roleConst';
import { useGridTemplateDetail } from 'hooks/grid';
import { useParams, useHistory } from 'react-router-dom';
import { fetchWorkspacesByGraphql } from 'workspaces/actions';
import { useCompanyAuthorities } from 'hooks/auth/role';

const useStyles = makeStyles(theme => ({
    root: {
        width: 400,
        borderRadius: 4
    },
    spacing3: {
        marginBottom: theme.spacing(3)
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: theme.spacing(2)
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function ChooseWorkspaceDatabase({ onClose, selectedTemplateId }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const history = useHistory();
    const companyAuthorities = useCompanyAuthorities();

    const templateDetail = useGridTemplateDetail(selectedTemplateId);

    const currentWorkspace = useSelectedWorkspace();
    const [selectedWorkspace, setSelectedWorkspace] = React.useState(
        currentWorkspace ? { label: currentWorkspace?.name, value: currentWorkspace?.id } : null
    );

    const databaseOptions = useDatabasesOptionsByWorkspaceId(selectedWorkspace?.value);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [selectedDatabase, setSelectedDatabase] = React.useState(databaseOptions?.[0]);

    const workspaceList = useWorkspaceList();

    const isCreator = React.useMemo(() => {
        return companyAuthorities?.includes(roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE);
    }, [companyAuthorities]);

    const workspaceOptions = React.useMemo(() => {
        const options = [];
        workspaceList.forEach(ws => {
            const gridPrivileges =
                ws?.role?.privileges?.filter(priv =>
                    isCreator ? true : priv.name === roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID
                ) || [];

            if (gridPrivileges.length > 0) {
                options.push({
                    label: ws?.name,
                    value: ws?.id
                });
            }
        });

        return options;
    }, [workspaceList, isCreator]);

    const handleWorkspaceChange = React.useCallback(async option => {
        setSelectedWorkspace(option);
    }, []);

    React.useEffect(() => {
        if (selectedWorkspace) {
            dispatch(
                databaseActions.getDatabases({
                    workspaceId: selectedWorkspace?.value,
                    successCallback: ({ databases }) => {
                        const defaultDB =
                            databases?.length === 0
                                ? null
                                : {
                                      label: databases?.[0]?.name,
                                      value: databases?.[0]?.id
                                  };
                        setSelectedDatabase(defaultDB);
                    },
                    errorCallback: () => {}
                })
            );
        }
    }, [selectedWorkspace, dispatch]);

    React.useEffect(() => {
        dispatch(
            fetchWorkspacesByGraphql({
                successCallback: () => {
                    console.log('fetch workspaces success');
                },
                errorCallback: () => {
                    console.log('fetch workspaces failed');
                }
            })
        );
    }, [dispatch]);

    const handleDatabaseChange = option => {
        setSelectedDatabase(option);
    };

    const isValid = React.useMemo(() => {
        const foundWs = workspaceOptions.find(option => option.value === selectedWorkspace?.value);
        return selectedDatabase && selectedWorkspace && foundWs;
    }, [selectedDatabase, selectedWorkspace, workspaceOptions]);

    const handleCreateGridFromTemplate = React.useCallback(() => {
        setIsSubmitting(true);
        dispatch(
            gridActions.createGridFromTemplate({
                dbId: selectedDatabase?.value,
                template: templateDetail,
                successCallback: () => {
                    onClose && onClose();
                    console.log('create database from template success');
                    if (selectedWorkspace?.value !== workspaceId) {
                        history.push(`/projects/${selectedWorkspace?.value}`);
                    }
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('create database from template failed');
                }
            })
        );
    }, [dispatch, templateDetail, selectedDatabase, onClose, workspaceId, history, selectedWorkspace]);

    return (
        <>
            <DialogTitle title={<Typography variant="h4">Choose Database</Typography>} onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" wrap="nowrap" className={classes.root}>
                    <Grid item className={classes.spacing2}>
                        <Grid container direction="column">
                            <Grid item container direction="column" spacing={2} className={classes.spacing3}>
                                <Grid item>
                                    <Typography variant="body1">Project</Typography>
                                </Grid>
                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder={'Select workspace'}
                                        menuPlaceholder={'Find a workspace'}
                                        options={workspaceOptions}
                                        onChange={handleWorkspaceChange}
                                        defaultValue={selectedWorkspace}
                                        isMulti={false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography variant="body1">Database</Typography>
                                </Grid>
                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder={'Select database'}
                                        menuPlaceholder={'Find a database'}
                                        options={databaseOptions}
                                        onChange={handleDatabaseChange}
                                        defaultValue={selectedDatabase}
                                        isMulti={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2} wrap="nowrap">
                    <Grid item>
                        <Button onClick={onClose} width={120} variant="outlined">
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <Button
                                width={120}
                                disabled={isSubmitting || !isValid}
                                type="submit"
                                variant="contained"
                                onClick={handleCreateGridFromTemplate}
                            >
                                {t(`global_save`)}
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default ChooseWorkspaceDatabase;
