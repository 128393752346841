import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TagSVG from 'assets/images/svg/TagSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import PopperMenu from 'components/menus/Popper';
import Tooltip from 'components/tooltip/Base';
import { useIsShowTag } from 'hooks/gridUI';
import React from 'react';
import { useParams } from 'react-router-dom';
import Tag from 'gridUI/tag';
import hexToRgba from 'hex-to-rgba';
import { useIsShowAutoQA } from 'hooks/gridUI/autoQA';
import { useTranslation } from 'react-i18next';
import { checkContainId } from 'utils/clickAway';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    }
}));

const ManageTag = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { dbId, branchId } = useParams();
    const isShowAutoQA = useIsShowAutoQA();
    const isShowTag = useIsShowTag({ dbId, gridId: branchId });
    const [tagAnchorEl, setTagAnchorEl] = React.useState(null);

    const handleTagClickAway = React.useCallback(e => {
        if (checkContainId(e, 'tagPopup') || checkContainId(e, 'tag-selection')) return;

        setTagAnchorEl(null);
    }, []);

    const handleTagClick = React.useCallback(
        event => {
            setTagAnchorEl(tagAnchorEl ? null : event.currentTarget);
        },
        [tagAnchorEl]
    );

    return (
        <>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID}>
                <Tooltip title={t('toolbar_tag_setting')}>
                    <Grid item className={classes.menuItem}>
                        <ButtonBase
                            tr-dt="Open Tag Setting"
                            id="g-h-menu-item-tag-setting"
                            className={`${classes.addButton} ${classes.button}`}
                            style={{
                                background:
                                    (tagAnchorEl || isShowTag) && !isShowAutoQA
                                        ? hexToRgba(theme.colors.fuchsiaBlue, 0.3)
                                        : ``
                            }}
                            onClick={handleTagClick}
                            disabled={isShowAutoQA}
                        >
                            <TagSVG />
                        </ButtonBase>
                    </Grid>
                </Tooltip>
            </AccessControl>
            {tagAnchorEl && (
                <PopperMenu
                    placement={'bottom-start'}
                    id="tagPopup"
                    anchorEl={tagAnchorEl}
                    handleClickAway={handleTagClickAway}
                    className={classes.popperMenu}
                >
                    <Tag />
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ManageTag);
