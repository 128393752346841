import store from 'store/configStore';
import * as gridActions from 'grids/actions';
import * as gridUIActions from 'gridUI/actions';
import { GRID_STATUS } from 'const/gridUI';
import { enqueueSnackbar } from 'notifier/actions';

const { dispatch } = store;

const taskHandler = ({ body, isGridDetail }) => {
    const { dbId, gridId, status } = body;

    switch (status?.toLowerCase()) {
        case GRID_STATUS.IN_PROGRESS:
        case 'RUNNING':
            return _updateGridStatus({ dbId, gridId, status: GRID_STATUS.RESTORING, isGridDetail });
        case GRID_STATUS.FAILED:
            return _updateGridStatusFailed({ dbId, gridId, status: GRID_STATUS.ACTIVE, isGridDetail });
        case GRID_STATUS.SUCCESS:
            return _updateGridStatusSuccess({ dbId, gridId, status: GRID_STATUS.ACTIVE, isGridDetail });
        default:
            return;
    }
};

function _updateGridStatus({ dbId, gridId, status, isGridDetail }) {
    if (!isGridDetail) {
        dispatch(
            gridActions.updateGridStatus({
                dbId,
                gridId,
                status
            })
        );
    } else {
        dispatch(gridActions.updateGridBranchStatus({ dbId, gridId, status }));
    }
}

function _updateGridStatusSuccess({ dbId, gridId, status, isGridDetail }) {
    dispatch(
        enqueueSnackbar({
            message: `Restore Grid Success`,
            type: 'info'
        })
    );
    _updateGridStatus({ dbId, gridId, status, isGridDetail });
    if (isGridDetail) {
        _refreshGridAfterRestoreDone();
    }
    if (document.getElementById('refresh-backup-grid')) {
        document.getElementById('refresh-backup-grid').click();
    }
}

function _updateGridStatusFailed({ dbId, gridId, status, isGridDetail }) {
    dispatch(
        enqueueSnackbar({
            message: `Restore Grid Failed`,
            type: 'info'
        })
    );
    _updateGridStatus({ dbId, gridId, status, isGridDetail });
    if (isGridDetail) {
        _refreshGridAfterRestoreDone();
    }
}

function _refreshGridAfterRestoreDone() {
    dispatch(
        gridUIActions.renderGridUITableAfterNetworkConnected({
            successCallback: () => {
                console.log('fetch grid success');
            },
            errorCallback: () => {
                console.log('failed to fetch grid');
            }
        })
    );
}

export default taskHandler;
