import React from 'react';

function VideoThumbnailSVG({ color = '#93929e', ...other }) {
    return (
        <svg {...other} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4001 4.6001C12.7001 4.6001 12.1001 5.2001 12.1001 5.9001V47.1001C12.1001 47.8001 12.7001 48.4001 13.4001 48.4001H45.0001C45.7001 48.4001 46.3001 47.8001 46.3001 47.1001V15.2001L35.5001 4.6001H13.4001Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46 16C46 15.5 45.8 15 45.4 14.6L36 5.1C35.6 4.7 35.1 4.5 34.5 4.5H13.1C12.3 4.5 11.7 5.1 11.7 5.8V47C11.7 47.7 12.3 48.3 13 48.3H44.5999C45.2999 48.3 45.9 47.7 45.9 47L46 16ZM13.1 5.5H34.7999V13.9C34.7999 14.6 35.3999 15.2 36.0999 15.2H44.5999L45 15.6V47.1C45 47.3 44.9 47.4 44.7 47.4H13.2C13 47.4 12.9 47.2 12.9 47.1L12.8 5.8C12.8 5.7 12.9 5.5 13.1 5.5Z"
                fill="#EF727E"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.80005 23.5H12V27L7.80005 23.5Z" fill="#EB4F5E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 23.7001H7.80005V12.6001H31C32.1 12.6001 33.1 13.5001 33.1 14.7001V21.6001C33.2 22.8001 32.2 23.7001 31 23.7001Z"
                fill="#EF727E"
            />
            <path d="M12.9 19.2L13.8 15.8H15L13.6 20.5H12.3L10.8 15.9H12L12.9 19.2Z" fill="white" />
            <path d="M16.9 20.4999H15.8V15.8999H16.9V20.4999Z" fill="white" />
            <path
                d="M18 20.4999V15.8999H19.5C19.9 15.8999 20.3 15.9999 20.6 16.1999C20.9 16.3999 21.2 16.5999 21.4 16.9999C21.6 17.2999 21.7 17.6999 21.7 18.0999V18.2999C21.7 18.6999 21.6 19.0999 21.4 19.3999C21.2 19.6999 21 19.9999 20.6 20.1999C20.3 20.3999 19.9 20.4999 19.5 20.4999H18V20.4999ZM19 16.6999V19.5999H19.4C19.7 19.5999 20 19.4999 20.1 19.2999C20.3 19.0999 20.4 18.6999 20.4 18.2999V18.0999C20.4 17.6999 20.3 17.2999 20.1 17.0999C19.9 16.8999 19.7 16.7999 19.4 16.7999L19 16.6999Z"
                fill="white"
            />
            <path
                d="M25.4 18.4999H23.6V19.5999H25.7V20.4999H22.5V15.8999H25.7V16.7999H23.6V17.7999H25.4C25.3 17.7999 25.3 18.4999 25.4 18.4999Z"
                fill="white"
            />
            <path
                d="M30.4 18.3C30.4 18.8 30.3 19.1 30.1 19.5C29.9 19.8 29.7 20.1 29.4 20.3C29.1 20.5 28.7 20.6 28.3 20.6C27.9 20.6 27.6 20.5 27.3 20.3C27 20.1 26.8 19.9 26.6 19.5C26.4 19.2 26.3 18.8 26.3 18.3V18C26.3 17.5 26.4 17.1 26.5 16.8C26.7 16.5 26.9001 16.2 27.2001 16C27.5001 15.8 27.9 15.7 28.3 15.7C28.7 15.7 29 15.8 29.3 16C29.6 16.2 29.8 16.5 30 16.8C30.2 17.1 30.3 17.5 30.3 18L30.4 18.3ZM29.1 18.1C29.1 17.6 29 17.3 28.9 17.1C28.7 16.9 28.5001 16.7 28.2001 16.7C27.6001 16.7 27.3 17.1 27.3 18V18.3C27.3 18.8 27.4 19.1 27.5 19.3C27.7 19.5 27.9001 19.7 28.2001 19.7C28.5001 19.7 28.7 19.6 28.9 19.3C29.1 19.1 29.1 18.7 29.1 18.3C29.2 18.3 29.2 18.1 29.1 18.1Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.2001 28.0999C20.7001 28.0999 20.3001 28.4999 20.3001 28.9999V30.3999H22.6001V28.0999H21.2001V28.0999ZM19.1001 28.9999V30.9999V34.4999V37.9999V39.9999C19.1001 41.1999 20.0001 42.0999 21.2001 42.0999H23.2001H34.1001H36.1001C37.3001 42.0999 38.2001 41.1999 38.2001 39.9999V37.9999V34.4999V30.9999V28.9999C38.2001 27.7999 37.3001 26.8999 36.1001 26.8999H34.1001H23.2001H21.2001C20.0001 26.8999 19.1001 27.7999 19.1001 28.9999ZM37.0001 30.3999V28.9999C37.0001 28.4999 36.6001 28.0999 36.1001 28.0999H34.8001V30.3999H37.0001V30.3999ZM34.8001 31.5999H37.0001V33.8999H34.7001V31.5999H34.8001ZM33.5001 30.9999V28.0999H23.8001V40.6999H33.5001V37.7999V30.9999ZM37.0001 34.9999V37.2999H34.8001V34.9999H37.0001ZM37.0001 38.4999H34.8001V40.7999H36.2001C36.7001 40.7999 37.1001 40.3999 37.1001 39.8999L37.0001 38.4999ZM20.4001 38.4999V39.8999C20.4001 40.3999 20.8001 40.7999 21.3001 40.7999H22.7001V38.4999H20.4001ZM22.6001 37.2999H20.3001V34.9999H22.6001V37.2999ZM22.6001 31.5999H20.3001V33.8999H22.6001V31.5999ZM27.8001 35.5999L29.4001 34.4999L27.8001 33.3999V35.5999ZM26.8001 32.8999C26.8001 32.2999 27.4001 31.9999 27.9001 32.2999L30.3001 33.8999C30.7001 34.1999 30.7001 34.7999 30.3001 35.0999L27.9001 36.6999C27.4001 36.9999 26.8001 36.6999 26.8001 36.0999V32.8999Z"
                fill="#EF727E"
            />
        </svg>
    );
}

export default React.memo(VideoThumbnailSVG);
