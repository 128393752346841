import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AvatarBase from 'components/avatar/Base';
import { useCurrentUserFullInfo } from 'hooks/auth';
import 'emoji-mart/css/emoji-mart.css';
import PopperMenu from 'components/menus/Popper';
import { isKbEscape, isKbEnter, isKbShiftEnter } from 'utils/keyboard';
import { getAvatarUrl } from 'utils/images';
import CommentEmojiIconSVG from 'assets/images/svg/CommentEmojiIconSVG';
import SendCommentSVG from 'assets/images/svg/SendCommentSVG';
import Tooltip from 'components/tooltip/Base';
import { useTranslation, Trans } from 'react-i18next';
import EmojiBox from 'components/cellComment/Emoji';
import EditorMention from './EditorMention';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        position: 'relative'
    },
    currentUserAvatar: {
        flexBasic: 20
    },
    chatInput: {
        flex: 1,
        marginLeft: theme.spacing(3),
        padding: `0px ${theme.spacing(3)}px`,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    extend: {
        flex: 1
    },
    inputBase: {
        border: 'none',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        width: '100%',
        padding: `6px 0px`,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus-within': {
            borderColor: 'none'
        }
    },
    controls: {
        paddingTop: theme.spacing(2)
    },
    dlFlex: {
        display: 'flex'
    },
    commentPopup: {
        '& .emoji-mart': {
            border: 'none'
        }
    },
    spacing: {
        marginTop: theme.spacing(3)
    },
    strong: {
        fontSize: 10,
        color: theme.colors.midGrey
    },
    normal: {
        fontSize: 10,
        color: theme.colors.midGrey
    },
    noted: {}
}));

const cursorDefault = {
    anchor: {
        offset: 0,
        path: [0, 0]
    },
    focus: {
        offset: 0,
        path: [0, 0]
    }
};

const EditorMentionReplyBox = ({ className, cancelHandler, saveHandler, members }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const currentUser = useCurrentUserFullInfo();
    const editorRef = React.useRef();
    const [text, setText] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const cursorRef = React.useRef({ ...cursorDefault });

    const users = useMemo(() => {
        return members.map(m => m.user);
    }, [members]);

    const handleFocus = React.useCallback(() => {
        if (editorRef.current) {
            if (cursorRef.current) {
                editorRef.current.focusToSelection(cursorRef.current);
                return;
            }
            editorRef.current.focus(false, true);
        }
    }, []);

    const handleClick = event => {
        handleFocus();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleTextChange = val => {
        setText(val);
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            cancelHandler && cancelHandler();
        }
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            e.stopPropagation();
            if (!text) {
                return;
            }
            handleSaveButtonClick();
        }
    };

    const handleSaveButtonClick = () => {
        if (!text?.trim()) {
            handleFocus();
            return;
        }
        saveHandler && saveHandler(text?.trim());
        setText('');
        if (editorRef.current) {
            editorRef.current.blur();
            editorRef.current.reset({ text: '' });
            cursorRef.current = { ...cursorDefault };
        }
        handleClickAway();
    };

    const calculateCursor = () => {
        if (editorRef.current) {
            const rangeSelection = editorRef.current.getRangeSelection();
            cursorRef.current = rangeSelection;
        }
    };

    const handleKeyUp = e => {
        setTimeout(calculateCursor, 20);
    };

    const emojiChange = emoji => {
        if (editorRef.current) {
            editorRef.current.focusToSelection(cursorRef.current);
            editorRef.current.insertText({
                text: emoji?.native,
                selection: cursorRef.current
            });
            handleKeyUp();
        }
    };

    return (
        <Grid container className={`${classes.root} ${className}`} direction="column" justify="center">
            <Grid item>
                <Grid container className={classes.commentChat} direction="row" alignItems="flex-start">
                    <Grid item className={classes.currentUserAvatar}>
                        <AvatarBase size={'small'} src={getAvatarUrl(currentUser?.imageUrl)} />
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="flex-start"
                        justify="space-between"
                        direction="row"
                        className={classes.chatInput}
                    >
                        <Grid item className={classes.extend}>
                            <EditorMention
                                className={`${classes.inputBase}`}
                                ref={editorRef}
                                text={text}
                                onChange={handleTextChange}
                                handleKeyDown={handleKeyDown}
                                placeholder={t(`reply_box_placeholder`)}
                                members={users}
                                onKeyUp={handleKeyUp}
                                onMouseUp={handleKeyUp}
                                afterClickInsertSuggestion={handleKeyUp}
                            />
                        </Grid>
                        <Grid item className={classes.controls}>
                            <Grid container alignItems="center" direction="row" spacing={1}>
                                <Grid item className={classes.dlFlex}>
                                    <Tooltip title="Emoji">
                                        <Grid item onClick={handleClick} style={{ cursor: 'pointer', display: 'flex' }}>
                                            <CommentEmojiIconSVG
                                                color={anchorEl ? theme.colors.lightLavender : undefined}
                                            />
                                        </Grid>
                                    </Tooltip>

                                    {anchorEl && (
                                        <PopperMenu
                                            className={classes.commentPopup}
                                            anchorEl={anchorEl}
                                            placement={'right-end'}
                                            id={`comment-emoji`}
                                        >
                                            <EmojiBox handleClickAway={handleClickAway} onChange={emojiChange} />
                                        </PopperMenu>
                                    )}
                                </Grid>
                                <Tooltip title="Send comment">
                                    <Grid item onClick={handleSaveButtonClick} className={classes.dlFlex}>
                                        <SendCommentSVG color={text?.trim() ? theme.colors.lightLavender : undefined} />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="flex-end">
                <Grid item>
                    <Trans t={t} i18nKey="reply_box_hint" defaults={'<0>Shift + Return</0><1>to add a new line</1>'}>
                        <p className="body1 inline text-[10px] text-grey-mid">Shift + Return</p>
                        <p className="body2 inline text-[10px] text-grey-mid">to add a new line</p>
                    </Trans>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(EditorMentionReplyBox);
