import React from 'react';

function NotFoundBoxSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="154" height="210" viewBox="0 0 154 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path opacity="0.5" d="M153.393 208.448H7.03491L22.2163 180.187H138.212L153.393 208.448Z" fill="#E9EAEF" />
            <path d="M138.27 142.639H22.0537L34.1104 120.198H126.214L138.27 142.639Z" fill="#D99C65" />
            <path d="M138.16 142.639H22.1643V196.201H138.16V142.639Z" fill="#E6BB98" />
            <path d="M138.27 86.9409H22.0537L34.1104 120.198H126.214L138.27 86.9409Z" fill="#F4DCCA" />
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
                <path d="M126.214 120.198V142.639H138.16L126.214 120.198Z" fill="#BD844C" />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
                <path d="M34.1105 120.198V142.639H22.1643L34.1105 120.198Z" fill="#BD844C" />
            </g>
            <path
                d="M130.996 102.361C134.432 101.777 138.15 106.793 139.345 113.591V113.38C137.909 104.919 133.94 98.5337 130.484 99.1279C127.027 99.7222 125.38 107.055 126.816 115.515C126.816 115.585 126.816 115.656 126.816 115.726C125.721 108.948 127.57 102.905 130.996 102.361Z"
                fill="#46BCEC"
            />
            <path
                d="M135.226 127.299C132.051 127.833 128.645 123.583 127.188 117.57C128.866 124.983 132.463 130.301 135.638 129.767C138.813 129.233 140.451 123.009 139.597 115.455C140.22 121.609 138.401 126.755 135.226 127.299Z"
                fill="#FAAF1B"
            />
            <path
                d="M33.6283 102.361C30.2022 101.777 26.4847 106.793 25.2891 113.591V113.38C26.7258 104.919 30.6945 98.5337 34.1508 99.1279C37.607 99.7222 39.2548 107.055 37.818 115.515C37.818 115.585 37.818 115.656 37.818 115.726C38.9131 108.948 37.0645 102.905 33.6283 102.361Z"
                fill="#FAAF1B"
            />
            <path
                d="M29.4084 127.299C32.5733 127.833 35.9893 123.583 37.4462 117.57C35.7582 124.983 32.1714 130.301 28.9964 129.767C25.8215 129.233 24.1838 123.009 25.0378 115.455C24.4149 121.609 26.2334 126.755 29.4084 127.299Z"
                fill="#46BCEC"
            />
            <path
                d="M118.045 97.295C115.878 97.7317 113.967 99.0004 112.72 100.83C113.588 100.16 114.605 99.711 115.684 99.5209C121.592 98.5137 127.741 105.786 129.449 115.767C131.157 125.748 127.721 134.652 121.813 135.659C120.733 135.832 119.628 135.746 118.588 135.407C120.365 136.726 122.588 137.291 124.777 136.978C131.267 135.871 135.025 126.091 133.166 115.132C131.308 104.174 124.536 96.1871 118.045 97.295Z"
                fill="#E43383"
            />
            <path
                d="M46.5791 97.295C48.75 97.7293 50.665 98.9982 51.9142 100.83C51.0462 100.16 50.0292 99.711 48.9502 99.5209C43.0424 98.5137 36.8935 105.786 35.1855 115.767C33.4775 125.748 36.9136 134.652 42.8214 135.659C43.9012 135.832 45.0065 135.746 46.0466 135.407C44.2693 136.725 42.0463 137.29 39.8575 136.978C33.3669 135.871 29.6093 126.091 31.468 115.132C33.3268 104.174 40.0886 96.1871 46.5791 97.295Z"
                fill="#E43383"
            />
            <path
                d="M125.782 121.699C124.475 114.719 122.767 107.599 118.839 101.555C118.417 100.911 117.975 100.276 117.503 99.6619C108.761 88.1497 93.3892 83.2547 79.323 84.0504C66.7338 84.7655 53.9035 89.9626 46.3781 100.427C41.3545 107.478 38.9632 116.059 38.4608 124.6C38.1192 130.452 38.1092 137.331 41.7764 142.317C58.3142 164.807 106.22 164.475 122.546 142.317C126.967 136.374 127.088 128.689 125.782 121.699Z"
                fill="url(#paint0_linear_601_20848)"
            />
            <path
                d="M80.4483 137.079C72.9626 137.165 65.4948 136.326 58.2138 134.581C52.6978 133.201 49.4425 130.754 48.5182 127.309C46.3781 119.252 47.9254 108.102 55.0187 102.23C59.2357 98.7192 64.3752 96.5052 69.8183 95.8545C78.0366 94.7936 86.3566 94.7936 94.5748 95.8545C100.02 96.5004 105.163 98.711 109.384 102.22C116.418 108.092 118.015 119.282 115.875 127.299C115.031 130.482 112.007 132.849 106.903 134.349C99.4075 136.515 90.7367 136.938 82.4779 137.079H80.4483Z"
                fill="#9863A9"
            />
            <path
                d="M80.569 134.521C73.7479 134.592 66.9423 133.855 60.2937 132.325C55.2701 131.116 52.2559 128.971 51.4722 125.95C49.523 118.899 50.9296 109.099 57.39 103.942C61.2757 100.849 65.9521 98.9149 70.8835 98.3624C78.3738 97.4358 85.9493 97.4358 93.4396 98.3624C98.3809 98.9026 103.071 100.826 106.973 103.912C113.434 109.069 114.84 118.879 112.891 125.919C112.117 128.709 109.364 130.784 104.713 132.083C97.8805 133.987 89.9733 134.36 82.4479 134.47H80.6092L80.569 134.521Z"
                fill="#121212"
            />
            <path
                d="M80.569 132.728C73.89 132.801 67.2258 132.081 60.7157 130.583C56.3753 129.575 53.8433 127.813 53.1903 125.466C51.4019 119 52.6477 110.016 58.5053 105.322C62.1308 102.43 66.4988 100.624 71.1045 100.115C78.4444 99.2019 85.8687 99.2019 93.2085 100.115C97.8168 100.626 102.187 102.431 105.818 105.322C111.665 109.986 112.911 118.97 111.123 125.466C110.53 127.611 108.199 129.263 104.19 130.381C97.5589 132.234 89.7924 132.597 82.3876 132.708H80.569V132.728Z"
                fill="#17171A"
            />
            <path
                d="M59.3593 98.0605L60.5348 97.0533L55.9332 91.3828C55.5112 91.6648 55.0892 91.9368 54.6772 92.2389L59.3593 98.0605Z"
                fill="white"
            />
            <path
                d="M110.008 92.4001C109.596 92.108 109.174 91.826 108.752 91.5439L103.989 97.426L105.165 98.4331L110.008 92.4001Z"
                fill="white"
            />
            <path
                d="M77.3736 68.1045L77.1626 67.7381C77.1044 67.6369 76.9753 67.6023 76.8744 67.6607L70.9576 71.0851C70.8567 71.1436 70.8221 71.2729 70.8804 71.3741L71.0914 71.7404C71.1496 71.8416 71.2787 71.8762 71.3796 71.8178L77.2964 68.3934C77.3973 68.335 77.4319 68.2056 77.3736 68.1045Z"
                fill="white"
            />
            <path
                d="M70.0997 72.7696C70.366 72.7696 70.582 72.5532 70.582 72.2862C70.582 72.0192 70.366 71.8027 70.0997 71.8027C69.8333 71.8027 69.6174 72.0192 69.6174 72.2862C69.6174 72.5532 69.8333 72.7696 70.0997 72.7696Z"
                fill="white"
            />
            <path
                d="M109.756 100.437C105.236 96.8295 99.7936 94.5711 94.0523 93.9208C86.1825 92.9336 78.2206 92.9336 70.3508 93.9208C64.6085 94.5673 59.1651 96.8262 54.647 100.437C46.9508 106.571 44.67 118.033 47.2019 127.148C48.7492 132.758 54.0341 135.417 59.2586 136.686C66.8753 138.424 74.6791 139.199 82.4879 138.993C90.164 138.872 98.453 138.499 105.888 136.434C110.791 135.065 115.714 132.406 117.171 127.148C119.753 118.013 117.452 106.571 109.756 100.437ZM114.609 126.423C113.665 129.868 110.49 132.365 105.195 133.836C98.1616 135.8 90.1238 136.173 82.4778 136.293H80.6091C73.6489 136.368 66.7045 135.614 59.9218 134.047C54.2149 132.678 50.8089 130.109 49.7841 126.403C47.6742 118.809 49.2415 108.162 56.3148 102.522C60.457 99.2263 65.438 97.1606 70.6924 96.5596C78.3365 95.6196 86.0666 95.6196 93.7107 96.5596C98.9651 97.1606 103.946 99.2263 108.088 102.522C115.152 108.173 116.739 118.829 114.609 126.423Z"
                fill="url(#paint1_linear_601_20848)"
            />
            <path
                d="M70.7127 65.5382C69.6577 66.1828 68.5827 66.8173 67.5277 67.482C67.1242 67.7302 66.7894 68.0761 66.5541 68.488C66.3188 68.8998 66.1907 69.3644 66.1814 69.8389C66.1722 70.3479 66.3022 70.8498 66.5572 71.2901C66.8122 71.7304 67.1826 72.0923 67.6282 72.3367L78.4692 79.236C79.6109 79.9979 80.9459 80.4172 82.3173 80.4446C83.6927 80.4583 85.0429 80.0736 86.2056 79.3367L94.0525 74.613L97.137 72.7497C97.5927 72.5223 97.9778 72.1745 98.2507 71.7437C98.5235 71.3129 98.6738 70.8156 98.6851 70.3054C98.6965 69.7953 98.5686 69.2917 98.3152 68.8491C98.0618 68.4065 97.6926 68.0418 97.2475 67.7943L88.8982 62.4662L85.7333 64.3597C85.3045 64.5997 84.946 64.9483 84.6939 65.3708C84.4417 65.7932 84.3047 66.2747 84.2966 66.7669C84.2919 67.2739 84.4226 67.773 84.6753 68.2122C84.9279 68.6514 85.2932 69.0149 85.7333 69.2648L87.1199 70.131L82.4077 72.941L78.9213 70.7151L77.7257 69.9597L81.1719 67.9453C81.5757 67.6973 81.9102 67.3507 82.1443 66.938C82.3783 66.5252 82.5042 66.0598 82.5103 65.585C82.5163 65.1103 82.4022 64.6418 82.1787 64.2232C81.9552 63.8046 81.6296 63.4497 81.2322 63.1914L78.0271 61.177L70.7428 65.5281"
                fill="url(#paint2_linear_601_20848)"
            />
            <path
                opacity="0.47"
                d="M78.6293 64.1631L78.2425 63.4914C78.1399 63.3132 77.9125 63.2521 77.7347 63.355L72.0876 66.6234C71.9098 66.7263 71.8489 66.9542 71.9516 67.1324L72.3384 67.8041C72.441 67.9823 72.6684 68.0434 72.8462 67.9405L78.4933 64.6721C78.6711 64.5692 78.732 64.3413 78.6293 64.1631Z"
                fill="white"
            />
            <path
                opacity="0.47"
                d="M70.6624 69.3353C71.1785 69.3353 71.5968 68.9159 71.5968 68.3986C71.5968 67.8813 71.1785 67.4619 70.6624 67.4619C70.1464 67.4619 69.728 67.8813 69.728 68.3986C69.728 68.9159 70.1464 69.3353 70.6624 69.3353Z"
                fill="white"
            />
            <path
                d="M71.5264 100.105C66.5258 100.838 61.8079 102.885 57.8521 106.037C57.6713 106.178 57.5105 106.34 57.3397 106.491C52.5673 111.376 51.6228 119.494 53.2706 125.466C53.9236 127.813 56.4555 129.495 60.796 130.583C62.0217 130.875 63.2776 131.127 64.5436 131.358L66.4425 109.472C66.5555 108.156 66.9918 106.889 67.7124 105.784C68.4329 104.678 69.4154 103.769 70.5719 103.137L77.0122 99.6116C75.1033 99.7224 73.2646 99.8835 71.5264 100.105Z"
                fill="url(#paint3_linear_601_20848)"
            />
            <path d="M153.341 170.901H6.98291L22.1643 142.639H138.16L153.341 170.901Z" fill="#F4DCCA" />
            <path
                d="M68.432 124.65C73.1819 124.65 77.0324 120.79 77.0324 116.029C77.0324 111.267 73.1819 107.407 68.432 107.407C63.6821 107.407 59.8315 111.267 59.8315 116.029C59.8315 120.79 63.6821 124.65 68.432 124.65Z"
                fill="white"
            />
            <path
                d="M98.6842 124.65C103.434 124.65 107.285 120.79 107.285 116.029C107.285 111.267 103.434 107.407 98.6842 107.407C93.9343 107.407 90.0837 111.267 90.0837 116.029C90.0837 120.79 93.9343 124.65 98.6842 124.65Z"
                fill="white"
            />
            <path
                d="M98.654 107.407C98.2875 107.409 97.9216 107.436 97.5588 107.488C99.6641 107.717 101.611 108.718 103.025 110.298C104.439 111.878 105.222 113.926 105.222 116.049C105.222 118.172 104.439 120.22 103.025 121.8C101.611 123.38 99.6641 124.381 97.5588 124.61C97.9223 124.654 98.2879 124.678 98.654 124.681C100.935 124.681 103.123 123.772 104.735 122.155C106.348 120.539 107.254 118.346 107.254 116.059C107.254 113.772 106.348 111.58 104.735 109.963C103.123 108.346 100.935 107.437 98.654 107.437V107.407Z"
                fill="#EDE9EE"
            />
            <path
                d="M68.432 107.407C68.7951 107.408 69.1578 107.435 69.5171 107.488C67.4118 107.717 65.4651 108.718 64.0509 110.298C62.6366 111.878 61.8544 113.926 61.8544 116.049C61.8544 118.172 62.6366 120.22 64.0509 121.8C65.4651 123.38 67.4118 124.381 69.5171 124.61C69.157 124.654 68.7947 124.678 68.432 124.681C66.151 124.681 63.9635 123.772 62.3506 122.155C60.7377 120.539 59.8315 118.346 59.8315 116.059C59.8315 113.772 60.7377 111.58 62.3506 109.963C63.9635 108.346 66.151 107.437 68.432 107.437V107.407Z"
                fill="#EDE9EE"
            />
            <path
                d="M70.6625 117.761C71.6169 117.761 72.3906 116.986 72.3906 116.029C72.3906 115.072 71.6169 114.296 70.6625 114.296C69.708 114.296 68.9343 115.072 68.9343 116.029C68.9343 116.986 69.708 117.761 70.6625 117.761Z"
                fill="#17171A"
            />
            <path
                d="M94.786 117.761C95.7404 117.761 96.5141 116.986 96.5141 116.029C96.5141 115.072 95.7404 114.296 94.786 114.296C93.8316 114.296 93.0579 115.072 93.0579 116.029C93.0579 116.986 93.8316 117.761 94.786 117.761Z"
                fill="#17171A"
            />
            <path
                d="M84.2062 127.078C84.7142 127.09 85.2176 126.979 85.6728 126.752C86.128 126.526 86.5213 126.192 86.8185 125.778C86.9576 125.587 87.0156 125.348 86.9799 125.114C86.9441 124.881 86.8175 124.67 86.6276 124.529C86.5341 124.458 86.4272 124.406 86.3131 124.376C86.1991 124.347 86.0804 124.341 85.964 124.359C85.8477 124.377 85.7361 124.418 85.6361 124.48C85.536 124.543 85.4495 124.624 85.3817 124.721C85.1341 124.995 84.8045 125.182 84.4422 125.252C84.08 125.323 83.7046 125.275 83.3723 125.114C83.1548 125.022 82.9101 125.019 82.6905 125.105C82.4708 125.192 82.2936 125.361 82.1968 125.577C82.1484 125.684 82.1217 125.8 82.1183 125.917C82.1149 126.034 82.1348 126.151 82.1768 126.261C82.2189 126.371 82.2823 126.471 82.3634 126.556C82.4444 126.641 82.5415 126.708 82.6489 126.755C83.1407 126.968 83.6706 127.078 84.2062 127.078Z"
                fill="#EDE9EE"
            />
            <path
                d="M37.7878 76.2345L36.5721 70.8158C36.3572 69.8962 36.3954 68.9355 36.6826 68.036C37.9868 63.9362 37.8124 59.5074 36.1898 55.5236C34.5672 51.5397 31.5996 48.2541 27.8057 46.241C24.0118 44.228 19.6328 43.6155 15.4341 44.5105C11.2354 45.4056 7.48397 47.7514 4.83549 51.1379C2.18701 54.5243 0.8099 58.7361 0.944845 63.0371C1.07979 67.3382 2.7182 71.4549 5.57373 74.6678C8.42927 77.8807 12.3203 79.9855 16.5668 80.6143C20.8134 81.2431 25.1453 80.356 28.8055 78.1079C29.811 77.4718 30.9973 77.1852 32.1814 77.2921L36.4816 77.705C36.6727 77.7259 36.866 77.7007 37.0453 77.6315C37.2247 77.5623 37.385 77.4511 37.5128 77.3072C37.6406 77.1634 37.7322 76.9909 37.7801 76.8043C37.8279 76.6177 37.8305 76.4223 37.7878 76.2345Z"
                fill="#7B67AD"
            />
            <path
                d="M24.3042 55.7785L19.3811 60.7037L14.468 55.7785C14.2282 55.5381 13.9029 55.403 13.5637 55.403C13.2246 55.403 12.8993 55.5381 12.6595 55.7785C12.4197 56.0189 12.2849 56.345 12.2849 56.685C12.2849 57.025 12.4197 57.351 12.6595 57.5914L17.5726 62.5166L12.6595 67.4519C12.4197 67.6923 12.2849 68.0184 12.2849 68.3583C12.2849 68.6983 12.4197 69.0244 12.6595 69.2648C12.8993 69.5052 13.2246 69.6403 13.5637 69.6403C13.9029 69.6403 14.2282 69.5052 14.468 69.2648L19.3811 64.3396L24.3042 69.2648C24.4228 69.3842 24.5637 69.4789 24.7189 69.5436C24.8741 69.6082 25.0405 69.6415 25.2085 69.6415C25.3765 69.6415 25.5429 69.6082 25.6981 69.5436C25.8533 69.4789 25.9942 69.3842 26.1128 69.2648C26.3484 69.0222 26.4803 68.697 26.4803 68.3583C26.4803 68.0197 26.3484 67.6945 26.1128 67.4519L21.1996 62.5166L26.1429 57.5814C26.3786 57.3388 26.5105 57.0135 26.5105 56.6749C26.5105 56.3363 26.3786 56.011 26.1429 55.7684C26.0232 55.6451 25.8799 55.5472 25.7215 55.4807C25.5632 55.4142 25.3931 55.3804 25.2214 55.3814C25.0498 55.3823 24.8801 55.418 24.7225 55.4862C24.5649 55.5544 24.4226 55.6539 24.3042 55.7785Z"
                fill="white"
            />
            <path
                d="M65.3062 43.8768C67.4202 46.6955 71.8588 46.7765 73.1305 48.1616C72.7579 43.8282 70.8464 40.2401 68.4165 39.422C65.9865 38.6039 63.1354 40.9609 65.3062 43.8768Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M65.3062 43.8768C67.4202 46.6955 71.8588 46.7765 73.1305 48.1616C72.7579 43.8282 70.8464 40.2401 68.4165 39.422C65.9865 38.6039 63.1354 40.9609 65.3062 43.8768Z"
                fill="white"
            />
            <path
                d="M62.4443 39.7625C62.8324 39.9274 63.2333 40.0601 63.6431 40.1594C64.1777 40.3214 64.7123 40.4915 65.263 40.6859C66.1471 40.9916 66.9924 41.3993 67.782 41.9008C68.5446 42.398 69.2531 42.9735 69.8961 43.618C70.5415 44.2993 71.1421 45.0217 71.6942 45.7806C71.6942 45.7806 71.7995 45.7806 71.7833 45.7806C70.9879 44.1101 69.7281 42.7041 68.1546 41.7307C67.3221 41.1903 66.4274 40.7524 65.4898 40.4267C64.5372 40.0585 63.5406 39.8162 62.5253 39.7058C62.5253 39.7058 62.3795 39.7301 62.4443 39.7625Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M62.4443 39.7625C62.8324 39.9274 63.2333 40.0601 63.6431 40.1594C64.1777 40.3214 64.7123 40.4915 65.263 40.6859C66.1471 40.9916 66.9924 41.3993 67.782 41.9008C68.5446 42.398 69.2531 42.9735 69.8961 43.618C70.5415 44.2993 71.1421 45.0217 71.6942 45.7806C71.6942 45.7806 71.7995 45.7806 71.7833 45.7806C70.9879 44.1101 69.7281 42.7041 68.1546 41.7307C67.3221 41.1903 66.4274 40.7524 65.4898 40.4267C64.5372 40.0585 63.5406 39.8162 62.5253 39.7058C62.5253 39.7058 62.3795 39.7301 62.4443 39.7625Z"
                fill="black"
            />
            <path
                d="M119.959 26.3077C123.385 25.9027 125.629 22.1768 127.419 21.78C123.572 19.9656 119.595 19.836 117.699 21.4965C115.804 23.1569 116.436 26.7289 119.959 26.3077Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M119.959 26.3077C123.385 25.9027 125.629 22.1768 127.419 21.78C123.572 19.9656 119.595 19.836 117.699 21.4965C115.804 23.1569 116.436 26.7289 119.959 26.3077Z"
                fill="white"
            />
            <path
                d="M115.068 26.7198C115.4 26.476 115.712 26.2051 115.999 25.9099C116.396 25.5373 116.809 25.1647 117.222 24.8083C118.597 23.6252 120.227 22.7769 121.985 22.3298C122.875 22.1124 123.779 21.9554 124.69 21.86C124.69 21.86 124.755 21.779 124.69 21.7709C122.885 21.6314 121.078 22.0108 119.482 22.8644C118.611 23.3062 117.795 23.85 117.052 24.4843C116.273 25.1132 115.579 25.8401 114.987 26.647C114.987 26.647 115.011 26.7603 115.068 26.7198Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M115.068 26.7198C115.4 26.476 115.712 26.2051 115.999 25.9099C116.396 25.5373 116.809 25.1647 117.222 24.8083C118.597 23.6252 120.227 22.7769 121.985 22.3298C122.875 22.1124 123.779 21.9554 124.69 21.86C124.69 21.86 124.755 21.779 124.69 21.7709C122.885 21.6314 121.078 22.0108 119.482 22.8644C118.611 23.3062 117.795 23.85 117.052 24.4843C116.273 25.1132 115.579 25.8401 114.987 26.647C114.987 26.647 115.011 26.7603 115.068 26.7198Z"
                fill="black"
            />
            <path
                d="M34.1521 6.89324C35.9421 9.84154 40.2674 10.3275 41.377 11.8017C41.377 7.54122 39.8867 3.87205 37.5944 2.84339C35.3022 1.81472 32.3053 3.87205 34.1521 6.89324Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M34.1521 6.89324C35.9421 9.84154 40.2674 10.3275 41.377 11.8017C41.377 7.54122 39.8867 3.87205 37.5944 2.84339C35.3022 1.81472 32.3053 3.87205 34.1521 6.89324Z"
                fill="white"
            />
            <path
                d="M31.7486 2.61581C32.1082 2.81856 32.485 2.9893 32.8745 3.12608C33.3821 3.33128 33.8843 3.54998 34.381 3.78217C35.2134 4.16274 35.9989 4.63839 36.7219 5.19962C37.4208 5.75412 38.0589 6.38132 38.6253 7.07065C39.1915 7.79374 39.7083 8.55413 40.1723 9.34667C40.1723 9.34667 40.2776 9.34667 40.2614 9.34667C39.644 7.64354 38.5469 6.15544 37.1025 5.06191C36.3403 4.4569 35.5082 3.94567 34.624 3.53918C33.7295 3.09517 32.7824 2.76588 31.8053 2.55911C31.7648 2.54291 31.6838 2.58341 31.7486 2.61581Z"
                fill="#F8AD13"
            />
            <path
                opacity="0.2"
                d="M31.7486 2.61581C32.1082 2.81856 32.485 2.9893 32.8745 3.12608C33.3821 3.33128 33.8843 3.54998 34.381 3.78217C35.2134 4.16274 35.9989 4.63839 36.7219 5.19962C37.4208 5.75412 38.0589 6.38132 38.6253 7.07065C39.1915 7.79374 39.7083 8.55413 40.1723 9.34667C40.1723 9.34667 40.2776 9.34667 40.2614 9.34667C39.644 7.64354 38.5469 6.15544 37.1025 5.06191C36.3403 4.4569 35.5082 3.94567 34.624 3.53918C33.7295 3.09517 32.7824 2.76588 31.8053 2.55911C31.7648 2.54291 31.6838 2.58341 31.7486 2.61581Z"
                fill="black"
            />
            <path
                d="M125.959 29.0222C124.742 30.275 123.383 31.3813 121.909 32.3188C121.899 32.3274 121.886 32.3321 121.873 32.3321C121.859 32.3321 121.846 32.3274 121.836 32.3188C121.827 32.3073 121.822 32.293 121.822 32.2783C121.822 32.2636 121.827 32.2493 121.836 32.2378C123.333 31.3323 124.716 30.253 125.959 29.0222Z"
                fill="#37474F"
            />
            <path
                d="M117.294 34.8059C115.709 35.5245 114.087 36.1572 112.434 36.7012C112.413 36.7088 112.391 36.7121 112.369 36.7108C112.347 36.7095 112.326 36.7038 112.306 36.694C112.286 36.6841 112.269 36.6703 112.255 36.6535C112.24 36.6366 112.23 36.617 112.223 36.5959C112.213 36.553 112.219 36.5077 112.24 36.4688C112.261 36.4299 112.295 36.4001 112.337 36.3854C114.001 35.8796 115.637 35.2848 117.237 34.6034C117.262 34.5985 117.289 34.6023 117.311 34.6142C117.334 34.6262 117.352 34.6455 117.363 34.6691C117.373 34.6926 117.375 34.719 117.368 34.7439C117.362 34.7687 117.347 34.7906 117.326 34.8059H117.294Z"
                fill="#37474F"
            />
            <path
                d="M107.361 38.1095C105.652 38.5145 103.927 38.8142 102.193 39.0653C102.125 39.0723 102.056 39.0526 102.002 39.0104C101.948 38.9681 101.912 38.9066 101.902 38.8385C101.893 38.7709 101.91 38.7024 101.951 38.6478C101.992 38.5932 102.053 38.5569 102.121 38.5469C103.846 38.3282 105.555 38.0285 107.256 37.6802C107.313 37.6673 107.372 37.6776 107.422 37.7087C107.471 37.7399 107.506 37.7894 107.519 37.8463C107.532 37.9032 107.522 37.9629 107.49 38.0123C107.459 38.0617 107.41 38.0966 107.353 38.1095H107.361Z"
                fill="#37474F"
            />
            <path
                d="M96.9856 39.5664C95.236 39.6555 93.4784 39.6717 91.7289 39.5664C91.6867 39.5653 91.6451 39.5559 91.6066 39.5385C91.5681 39.5212 91.5335 39.4963 91.5049 39.4653C91.4762 39.4343 91.454 39.398 91.4396 39.3583C91.4252 39.3186 91.4189 39.2764 91.4211 39.2343C91.4231 39.1917 91.4336 39.15 91.4518 39.1115C91.4701 39.0729 91.4957 39.0384 91.5273 39.0098C91.5589 38.9812 91.5958 38.9591 91.636 38.9448C91.6761 38.9305 91.7187 38.9243 91.7613 38.9265C93.4946 39.0129 95.2279 39.0129 96.9613 38.9265C96.9992 38.9243 97.0371 38.9298 97.0729 38.9425C97.1086 38.9553 97.1415 38.9751 97.1694 39.0008C97.1974 39.0265 97.2198 39.0575 97.2356 39.092C97.2513 39.1266 97.2599 39.1639 97.261 39.2019C97.272 39.2444 97.2732 39.2889 97.2646 39.3319C97.256 39.375 97.2378 39.4156 97.2113 39.4506C97.1848 39.4857 97.1508 39.5143 97.1117 39.5343C97.0726 39.5544 97.0295 39.5653 96.9856 39.5664Z"
                fill="#37474F"
            />
            <path
                d="M86.4521 39.0731C84.7107 38.7958 82.9917 38.3924 81.3087 37.8663C81.2168 37.8295 81.1424 37.7592 81.1006 37.6695C81.0588 37.5798 81.0528 37.4775 81.0838 37.3835C81.1147 37.2895 81.1804 37.2109 81.2674 37.1636C81.3543 37.1164 81.456 37.1041 81.5517 37.1292C83.1904 37.6664 84.8661 38.0833 86.5655 38.3766C86.6165 38.3776 86.6667 38.3896 86.7126 38.4118C86.7586 38.4339 86.7993 38.4656 86.8319 38.5048C86.8646 38.544 86.8884 38.5898 86.9019 38.639C86.9153 38.6882 86.918 38.7398 86.9098 38.7901C86.9016 38.8405 86.8827 38.8885 86.8543 38.9309C86.826 38.9733 86.7889 39.0092 86.7455 39.036C86.7021 39.0628 86.6534 39.08 86.6028 39.0864C86.5522 39.0928 86.5008 39.0883 86.4521 39.0731Z"
                fill="#37474F"
            />
            <path
                d="M76.4742 35.7353C74.9399 34.829 73.5204 33.7412 72.2462 32.4954C72.1704 32.4157 72.1282 32.3098 72.1282 32.1998C72.1282 32.0898 72.1704 31.9839 72.2462 31.9042C72.325 31.8265 72.4312 31.783 72.5418 31.783C72.6525 31.783 72.7587 31.8265 72.8375 31.9042C74.0588 33.1031 75.4183 34.1526 76.8873 35.0306C76.939 35.054 76.9851 35.0879 77.0228 35.1302C77.0605 35.1725 77.089 35.2222 77.1062 35.2762C77.1235 35.3302 77.1293 35.3872 77.1231 35.4435C77.117 35.4998 77.0991 35.5543 77.0707 35.6032C77.0422 35.6522 77.0037 35.6947 76.9578 35.7279C76.9119 35.7611 76.8595 35.7843 76.8041 35.796C76.7486 35.8077 76.6913 35.8076 76.6359 35.7957C76.5805 35.7839 76.5282 35.7605 76.4823 35.7272L76.4742 35.7353Z"
                fill="#37474F"
            />
            <path
                d="M68.9725 28.3091C68.0789 26.7383 67.514 25.0022 67.312 23.2063C67.3107 23.0971 67.3502 22.9913 67.4228 22.9098C67.4955 22.8283 67.596 22.7769 67.7047 22.7657C67.8133 22.7546 67.9222 22.7844 68.0099 22.8495C68.0976 22.9145 68.1578 23.01 68.1787 23.1172C68.3658 24.7973 68.8892 26.4225 69.7177 27.896C69.7448 27.9455 69.762 27.9998 69.7681 28.0559C69.7743 28.112 69.7693 28.1687 69.7535 28.2229C69.7377 28.277 69.7114 28.3276 69.676 28.3715C69.6407 28.4155 69.597 28.4521 69.5476 28.4792C69.4987 28.5062 69.445 28.5232 69.3895 28.5292C69.3341 28.5353 69.278 28.5302 69.2245 28.5144C69.171 28.4986 69.1211 28.4723 69.0779 28.4371C69.0346 28.4018 68.9988 28.3583 68.9725 28.3091Z"
                fill="#37474F"
            />
            <path
                d="M67.9907 17.86C68.6409 16.1303 69.7634 14.6177 71.2306 13.4943C71.2783 13.4577 71.3328 13.431 71.3909 13.4156C71.4491 13.4003 71.5097 13.3967 71.5692 13.405C71.6287 13.4133 71.686 13.4333 71.7378 13.4639C71.7895 13.4946 71.8346 13.5352 71.8705 13.5834C71.9073 13.6317 71.9342 13.6869 71.9496 13.7457C71.9651 13.8044 71.9688 13.8657 71.9605 13.9259C71.9522 13.9861 71.9321 14.0441 71.9014 14.0965C71.8707 14.1489 71.8299 14.1947 71.7814 14.2314C70.4644 15.2538 69.4576 16.6223 68.8736 18.184C68.862 18.2492 68.8361 18.3109 68.7976 18.3648C68.7592 18.4187 68.7092 18.4634 68.6514 18.4955C68.5935 18.5277 68.5293 18.5465 68.4632 18.5507C68.3971 18.5549 68.331 18.5443 68.2695 18.5197C68.2081 18.4951 68.1529 18.4572 68.1079 18.4086C68.063 18.36 68.0294 18.302 68.0097 18.2388C67.99 18.1757 67.9846 18.1089 67.9939 18.0434C68.0033 17.9778 68.0271 17.9152 68.0636 17.86H67.9907Z"
                fill="#37474F"
            />
            <path
                d="M76.4949 12.3041C78.3451 12.661 79.9852 13.7206 81.0713 15.2605C81.1237 15.3623 81.1372 15.4797 81.1092 15.5908C81.0811 15.7018 81.0135 15.7988 80.919 15.8634C80.8245 15.9281 80.7096 15.956 80.5959 15.9419C80.4823 15.9279 80.3777 15.8727 80.3018 15.7869C79.3532 14.4427 77.9177 13.5216 76.3005 13.2193C76.1806 13.1921 76.0757 13.1196 76.0078 13.017C75.9399 12.9144 75.9142 12.7896 75.936 12.6686C75.9467 12.6074 75.9695 12.549 76.0032 12.4969C76.0369 12.4447 76.0808 12.4 76.1322 12.3652C76.1837 12.3304 76.2416 12.3063 76.3025 12.2944C76.3634 12.2825 76.4261 12.2831 76.4868 12.296L76.4949 12.3041Z"
                fill="#37474F"
            />
            <path
                d="M82.4076 20.5422C82.2136 22.3645 81.5401 24.1029 80.4555 25.5802C80.426 25.6364 80.3848 25.6856 80.3348 25.7246C80.2847 25.7635 80.2268 25.7914 80.1651 25.8062C80.1034 25.821 80.0393 25.8224 79.977 25.8104C79.9146 25.7984 79.8556 25.7732 79.8038 25.7366C79.752 25.6999 79.7087 25.6526 79.6766 25.5978C79.6446 25.543 79.6246 25.4821 79.6181 25.4189C79.6116 25.3558 79.6186 25.292 79.6387 25.2318C79.6588 25.1716 79.6915 25.1164 79.7347 25.0699C80.7159 23.7144 81.3247 22.1254 81.5004 20.4612C81.5055 20.4015 81.5223 20.3435 81.55 20.2904C81.5776 20.2373 81.6155 20.1901 81.6614 20.1517C81.7074 20.1133 81.7604 20.0844 81.8176 20.0666C81.8748 20.0488 81.9349 20.0425 81.9945 20.0481C82.1133 20.0623 82.2222 20.1214 82.299 20.2132C82.3758 20.305 82.4146 20.4227 82.4076 20.5422Z"
                fill="#37474F"
            />
            <path
                d="M76.5116 29.2403C75.0087 30.196 73.3925 30.9605 71.7004 31.5163C71.6478 31.5341 71.5921 31.5412 71.5367 31.5374C71.4813 31.5336 71.4272 31.5188 71.3775 31.494C71.3278 31.4691 71.2835 31.4347 71.2472 31.3927C71.2109 31.3506 71.1832 31.3018 71.1658 31.2491C71.1468 31.197 71.1384 31.1416 71.1411 31.0863C71.1439 31.0309 71.1578 30.9767 71.182 30.9268C71.2062 30.8769 71.2402 30.8324 71.2819 30.7959C71.3237 30.7594 71.3723 30.7318 71.425 30.7145C73.0506 30.1774 74.6018 29.4372 76.0418 28.5114C76.1378 28.4661 76.2469 28.4572 76.3489 28.4863C76.4509 28.5155 76.5388 28.5808 76.5963 28.6699C76.6537 28.7591 76.6768 28.8661 76.6612 28.971C76.6457 29.076 76.5925 29.1717 76.5116 29.2403Z"
                fill="#37474F"
            />
            <path
                d="M66.5388 32.7232C64.7943 32.9905 63.0306 33.1124 61.2659 33.0877C61.2127 33.0867 61.1602 33.0752 61.1115 33.0538C61.0627 33.0325 61.0187 33.0018 60.9818 32.9634C60.945 32.9251 60.9161 32.8798 60.8967 32.8303C60.8773 32.7807 60.8679 32.7278 60.869 32.6747C60.869 32.6218 60.8795 32.5696 60.8999 32.5209C60.9204 32.4722 60.9504 32.4281 60.9881 32.3911C61.0258 32.3541 61.0705 32.3251 61.1196 32.3056C61.1687 32.2862 61.2212 32.2767 61.274 32.2778C62.9957 32.2957 64.716 32.1711 66.4173 31.9052C66.471 31.8971 66.5258 31.8998 66.5785 31.9129C66.6312 31.9261 66.6808 31.9495 66.7244 31.9819C66.768 32.0142 66.8049 32.0549 66.8328 32.1015C66.8607 32.1481 66.8791 32.1997 66.8871 32.2535C66.8963 32.3067 66.8947 32.3613 66.8825 32.414C66.8703 32.4666 66.8477 32.5163 66.816 32.5601C66.7843 32.6039 66.7442 32.6409 66.6979 32.6689C66.6517 32.6969 66.6004 32.7154 66.5469 32.7232H66.5388Z"
                fill="#37474F"
            />
            <path
                d="M56.0015 32.5606C54.2695 32.2371 52.566 31.7766 50.9068 31.1836C50.8608 31.1697 50.8181 31.1466 50.7812 31.1157C50.7443 31.0849 50.7141 31.0469 50.6923 31.004C50.6705 30.9612 50.6576 30.9144 50.6544 30.8664C50.6511 30.8184 50.6577 30.7703 50.6735 30.7249C50.6894 30.6795 50.7143 30.6378 50.7467 30.6023C50.7791 30.5668 50.8183 30.5382 50.862 30.5182C50.9058 30.4983 50.9531 30.4874 51.0012 30.4862C51.0492 30.485 51.097 30.4936 51.1417 30.5113C52.7706 31.0731 54.4417 31.5037 56.1392 31.7992C56.2399 31.817 56.3294 31.8737 56.3886 31.9571C56.4477 32.0405 56.4716 32.1438 56.4551 32.2447C56.4473 32.295 56.4295 32.3432 56.4027 32.3865C56.3759 32.4298 56.3407 32.4673 56.2991 32.4968C56.2576 32.5262 56.2106 32.547 56.1609 32.558C56.1111 32.569 56.0597 32.5698 56.0096 32.5606H56.0015Z"
                fill="#37474F"
            />
            <path
                d="M46.0901 29.0373C44.5423 28.2024 43.0562 27.2577 41.6434 26.2105C41.5936 26.1599 41.5634 26.0932 41.5582 26.0224C41.553 25.9516 41.5731 25.8812 41.6149 25.8239C41.6567 25.7665 41.7175 25.7258 41.7865 25.7091C41.8555 25.6924 41.9282 25.7007 41.9917 25.7326C43.3934 26.7464 44.8659 27.6586 46.3979 28.4622C46.4727 28.5034 46.5287 28.5719 46.5544 28.6533C46.58 28.7348 46.5733 28.823 46.5356 28.8996C46.4943 28.9765 46.4244 29.034 46.3411 29.0598C46.2577 29.0855 46.1676 29.0775 46.0901 29.0373Z"
                fill="#37474F"
            />
            <path
                d="M37.6327 22.8008C36.3718 21.5805 35.1921 20.2791 34.1012 18.9048C34.0572 18.8608 34.0325 18.801 34.0325 18.7388C34.0325 18.6765 34.0572 18.6168 34.1012 18.5727C34.1223 18.5512 34.1475 18.534 34.1754 18.5223C34.2032 18.5107 34.233 18.5046 34.2632 18.5046C34.2934 18.5046 34.3233 18.5107 34.3511 18.5223C34.3789 18.534 34.4041 18.5512 34.4252 18.5727C35.5192 19.9163 36.6989 21.1879 37.9567 22.3796C37.9817 22.4037 38.0015 22.4327 38.0151 22.4647C38.0287 22.4967 38.0357 22.5311 38.0357 22.5659C38.0357 22.6006 38.0287 22.635 38.0151 22.667C38.0015 22.699 37.9817 22.728 37.9567 22.7522C37.9068 22.8007 37.84 22.8278 37.7704 22.8278C37.7008 22.8278 37.634 22.8007 37.5841 22.7522L37.6327 22.8008Z"
                fill="#37474F"
            />
            <path
                d="M31.1065 14.5957C30.2045 13.0986 29.3931 11.5488 28.6766 9.95455C28.6671 9.92426 28.669 9.89154 28.6821 9.86259C28.6951 9.83363 28.7183 9.81046 28.7472 9.79748C28.7762 9.78449 28.8089 9.78258 28.8392 9.79213C28.8695 9.80167 28.8952 9.822 28.9115 9.84925C29.6585 11.4161 30.4997 12.9362 31.4305 14.4013C31.4563 14.4421 31.4648 14.4915 31.4542 14.5386C31.4435 14.5857 31.4146 14.6266 31.3738 14.6524C31.333 14.6782 31.2836 14.6867 31.2365 14.676C31.1894 14.6654 31.1485 14.6365 31.1227 14.5957H31.1065Z"
                fill="#37474F"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_601_20848"
                    x1="95.0872"
                    y1="149.86"
                    x2="72.6867"
                    y2="107.755"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="1" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_601_20848"
                    x1="58.6257"
                    y1="104.124"
                    x2="117.483"
                    y2="137.64"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stop-color="white" />
                    <stop offset="1" stop-color="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_601_20848"
                    x1="88.4863"
                    y1="75.8619"
                    x2="56.7834"
                    y2="45.6432"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="0.67" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_601_20848"
                    x1="73.2345"
                    y1="104.587"
                    x2="57.829"
                    y2="122.387"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.25" stop-color="#353537" />
                    <stop offset="1" stop-color="#17171A" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(NotFoundBoxSVG);
