import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { getHookTypeLabel, METHOD_TYPES, WEB_HOOK_TRIGGER_ACTIONS } from 'const/gridUI';
import { upperFirst } from 'lodash';

export const AUTOMATION_STATUS = {
    DRAFT: 'DRAFT',
    DELETED: 'DELETED',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
};

export const getAutomationStatusName = status => {
    return upperFirst(status?.toLocaleLowerCase());
};

export const AUTOMATION_NODE = {
    TRIGGER: 'trigger',
    ACTION: 'action',
    ADD: 'add',
    ADD_STEP: 'addStep'
};

export const AUTOMATION_APP_ACTION_DEFAULT = {
    [WEB_HOOK_EXTERNAL_SYSTEMS.HTTP]: METHOD_TYPES.POST.toLocaleLowerCase(),
    [WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA]: 'invoke',
    [WEB_HOOK_EXTERNAL_SYSTEMS.SLACK]: 'channelMessage',
    [WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE]: 'translate',
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE]: 'translate',
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH]: 'synthesizeSpeech',
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL]: 'synthesizeSpeech',
    [WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND]: 'detectSentiment',
    [WEB_HOOK_EXTERNAL_SYSTEMS.JIRA]: 'createIssue',
    [WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR]: 'textDetection',
    [WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION]: 'if',
    [WEB_HOOK_EXTERNAL_SYSTEMS.SET]: 'set'
};

export const ACTION_STATUS = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED'
};

export const STATIC_PAYLOAD_VARIABLES = [
    {
        label: 'database',
        variables: ['id', 'name']
    },
    {
        label: 'grid',
        variables: ['id', 'name']
    },
    {
        label: 'view',
        variables: ['id', 'name']
    },
    {
        label: 'event',
        variables: ['type', 'action']
    },
    {
        label: 'user',
        variables: ['id', 'email', 'fullName', 'companyId']
    }
];

export const DYNAMIC_TICKET_PAYLOAD_VARIABLE = [
    'id',
    'summary',
    'description',
    'cellValue',
    'cellDependencyStatus',
    'type',
    'status',
    'priority',
    'gridId',
    'columnId',
    'recordId',
    'comments',
    'commentCount',
    'assignee'
];

export const AUTOMATION_COLUMN_VARIABLES = ['columnId', 'value', 'dependencyStatus', 'referencedIds'];

const DYNAMIC_RECORD_PAYLOAD_VARIABLE = [
    'id',
    'path',
    'columnChanges',
    'pathTag',
    {
        id: 'cells',
        label: 'cells',
        indexType: 'normalColumns',
        variables: AUTOMATION_COLUMN_VARIABLES
    }
];

const DYNAMIC_COLUMN_PAYLOAD_VARIABLE = [
    'id',
    'name',
    'type',
    'status',
    'group',
    'reference',
    'defaultValue',
    'customProperties',
    'metadata',
    'order',
    'viewable',
    'editable'
];

export const DYNAMIC_PAYLOAD_VARIABLE = {
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED]: DYNAMIC_TICKET_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED]: DYNAMIC_TICKET_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED]: DYNAMIC_TICKET_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED]: DYNAMIC_TICKET_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED]: [
        'fullName',
        'rowId',
        'columnId',
        'content',
        'createdBy',
        'createdAt',
        'status',
        'type',
        'cellValue',
        'cellDependencyStatus'
    ],
    [WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED]: DYNAMIC_RECORD_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED]: DYNAMIC_RECORD_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED]: DYNAMIC_RECORD_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED]: DYNAMIC_COLUMN_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED]: DYNAMIC_COLUMN_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED]: DYNAMIC_COLUMN_PAYLOAD_VARIABLE,
    [WEB_HOOK_TRIGGER_ACTIONS.MANUAL]: ['time']
};

export const MAP_TRIGGER_NAME = Object.keys(WEB_HOOK_TRIGGER_ACTIONS).reduce((acc, cur) => {
    acc[cur] =
        `Trigger` +
        cur
            .toLocaleLowerCase()
            .split('_')
            .map(el => upperFirst(el))
            .join('');
    return acc;
}, {});

export const MAP_AUTOMATION_ACTION_NAME = Object.keys(WEB_HOOK_EXTERNAL_SYSTEMS).reduce((acc, cur) => {
    acc[cur] = getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS[cur])
        ?.toLocaleLowerCase()
        .split(' ')
        .map(el => upperFirst(el))
        .join('');
    return acc;
}, {});

export const serverTriggerSymbol = '${';
export const serverSpacerSymbol = '}';
export const triggerSymbol = '{';
export const spacerSymbol = '}';
export const TRIGGER_MATCH_STRING_REGEX = `\\${triggerSymbol}[a-zA-Z][^\\s]*?\\${spacerSymbol}`;
export const TRIGGER_MATCH_STRING_SERVER_REGEX = `\\${serverTriggerSymbol}[a-zA-Z][^\\s]*?\\${serverSpacerSymbol}`;

export const showWatchingColumnsTriggers = [WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED];
