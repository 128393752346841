import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CountryFlag from 'components/svg-icon/CountryFlag';

const useStyles = makeStyles(theme => ({
    statisticItem: {
        borderRadius: 8,
        border: `1px solid ${theme.colors.divider}`,
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.paleNavy
        }
    }
}));

function Statistics({ filteredRunnedQAs, onSelectedQA, viewColumns }) {
    const classes = useStyles();

    return (
        <Grid container direction="column">
            {filteredRunnedQAs.map((pair, index) => {
                const { sourceColumn, targetColumn } = pair;
                return (
                    <Grid
                        key={index}
                        container
                        item
                        direction="column"
                        className={classes.statisticItem}
                        onClick={() => onSelectedQA(pair)}
                    >
                        <Grid item>
                            <p className="body1 text-brand-main">#{index + 1}</p>
                        </Grid>
                        <Grid item style={{ marginTop: 14 }}>
                            <p className="body1 inline">Source language: </p>
                            <p className="body1 inline">
                                <CountryFlag languageCountryCode={sourceColumn?.group} /> {sourceColumn?.group}
                            </p>
                        </Grid>
                        <Grid item style={{ marginTop: 14 }}>
                            <p className="body1 inline">Target language: </p>
                            <p key={targetColumn?.id} className="inline body1 mr-2.5">
                                <CountryFlag languageCountryCode={targetColumn?.group} /> {targetColumn?.group}
                            </p>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(Statistics);
