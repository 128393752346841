import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import ColumnSetup from './ColumnSetup';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import { getFriendlyColumnName } from 'utils/gridUI/column';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: 14,
        paddingLeft: 14,
        height: '100%',
        cursor: 'pointer'
    },
    displayFlex: {
        display: 'flex'
    },
    warning: {
        background: hexToRgba(theme.colors.snackBarWarning, 0.1),
        border: `1px solid ${theme.colors.snackBarWarning}`
    }
}));

function ColumnType({ columnType, columnName }) {
    return (
        <ColumnTypeDisplay
            type={columnType}
            name={getFriendlyColumnName({ columnName, columnType })}
            truncated={true}
        />
    );
}

const ColumnTypeMemo = React.memo(ColumnType);

function ImportHeader({ columnId, isModeSingle, columnMap, columnIndex }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const column = columnMap?.[columnId];
    const columnName = column?.columnName;
    const columnType = column?.columnType;
    const columnTimezone = column?.columnTimezone;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const isDuplicateName = React.useMemo(() => {
        const names = Object.keys(columnMap)
            ?.filter(key => columnMap?.[key]?.index !== `${column?.index}`)
            ?.map(key => columnMap?.[key]?.columnName);
        return names?.includes(column?.columnName);
    }, [column, columnMap]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid
                onClick={handleClick}
                container
                alignItems="center"
                justify="space-between"
                className={`${classes.root} ${!columnName || isDuplicateName ? classes.warning : ``}`}
                wrap="nowrap"
            >
                <Grid item style={{ width: `calc(100% -  16px)` }}>
                    {columnType && <ColumnTypeMemo columnType={columnType} columnName={columnName} />}
                    {!columnType && <Typography variant="caption">{t('coloumn_mapping_selection_guide')}</Typography>}
                </Grid>
                <Grid item className={classes.displayFlex}>
                    <ArrowDownSVG />
                </Grid>
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom'} id={`localization-column-setup`}>
                    <ColumnSetup
                        column={column}
                        columnId={columnId}
                        isModeSingle={isModeSingle}
                        columnMap={columnMap}
                        handleClickAway={handleClickAway}
                        columnType={columnType}
                        columnName={columnName}
                        columnIndex={columnIndex}
                        columnTimezone={columnTimezone}
                    />
                </PopperMenu>
            )}
        </>
    );
}

ImportHeader.propTypes = {
    column: PropTypes.object,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(ImportHeader);
