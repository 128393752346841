import React from 'react';

function DeactivateMemberSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.6375 5.225C3.6375 4.24469 4.43219 3.45 5.4125 3.45C6.39281 3.45 7.1875 4.24469 7.1875 5.225C7.1875 6.20531 6.39281 7 5.4125 7C4.43219 7 3.6375 6.20531 3.6375 5.225ZM5.4125 2C3.63138 2 2.1875 3.44388 2.1875 5.225C2.1875 7.00612 3.63138 8.45 5.4125 8.45C7.19362 8.45 8.6375 7.00612 8.6375 5.225C8.6375 3.44388 7.19362 2 5.4125 2ZM3.225 9.5C2.36968 9.5 1.54939 9.83978 0.944581 10.4446C0.339776 11.0494 0 11.8697 0 12.725V13.975C0 14.3754 0.324594 14.7 0.725 14.7C1.12541 14.7 1.45 14.3754 1.45 13.975V12.725C1.45 12.2542 1.63701 11.8028 1.96989 11.4699C2.30276 11.137 2.75424 10.95 3.225 10.95H7.6C8.07076 10.95 8.52224 11.137 8.85511 11.4699C9.18799 11.8028 9.375 12.2542 9.375 12.725V13.975C9.375 14.3754 9.69959 14.7 10.1 14.7C10.5004 14.7 10.825 14.3754 10.825 13.975V12.725C10.825 11.8697 10.4852 11.0494 9.88042 10.4446C9.27562 9.83978 8.45532 9.5 7.6 9.5H3.225ZM10.8373 5.33735C11.1205 5.05422 11.5795 5.05422 11.8627 5.33735L12.9125 6.3872L13.9623 5.33735C14.2455 5.05422 14.7045 5.05422 14.9877 5.33735C15.2708 5.62048 15.2708 6.07952 14.9877 6.36265L13.9378 7.4125L14.9877 8.46235C15.2708 8.74548 15.2708 9.20452 14.9877 9.48765C14.7045 9.77078 14.2455 9.77078 13.9623 9.48765L12.9125 8.4378L11.8627 9.48765C11.5795 9.77078 11.1205 9.77078 10.8373 9.48765C10.5542 9.20452 10.5542 8.74548 10.8373 8.46235L11.8872 7.4125L10.8373 6.36265C10.5542 6.07952 10.5542 5.62048 10.8373 5.33735Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(DeactivateMemberSVG);
