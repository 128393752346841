import {
    isKbEnter,
    isKbRefresh,
    isKbCopy,
    isKbPaste,
    isKbDelete,
    isKbF2,
    isKbCommandAndSlash,
    isKbShiftArrowLeft,
    isKbArrowLeft,
    isKbShiftArrowDown,
    isKbArrowDown,
    isKbShiftArrowUp,
    isKbArrowUp,
    isKbShiftArrowRight,
    isKbArrowRight,
    isKbShiftTab,
    isKbTab,
    isKbHome,
    isKbPageStart,
    isKbPageEnd,
    isKbPageUp,
    isKbPageDown,
    isKbEnd,
    isKbSpace,
    isKbUndo,
    isKbRedo,
    isKbCtrlShiftC,
    isKbSearch,
    isNormalKeyboards
} from 'utils/keyboard';
import * as appActions from 'app/actions';
import * as columnTypes from 'const/columnTypes';
import * as gridUIActions from '../actions';
import { characters, NOT_NUMBER_REGEX } from 'const/gridUI';
import { setCopyPaseEvt } from 'utils/clipboard';
import now from 'performance-now';
import { sendManualTrack } from 'tracker';

export let isNavigating = false;

export function setIsNavigating(value) {
    isNavigating = value;
}

export async function cellKeyEventsHandler({
    e,
    dispatch,
    selectedCellData,
    isRangeSelected,
    closeCellContext,
    showCopySource,
    sourceData
}) {
    if (isKbRefresh(e)) {
        window.location.reload();
        return;
    }

    if (isKbCtrlShiftC(e)) {
        e.stopPropagation();
        e.preventDefault();
        if (showCopySource) {
            closeCellContext && closeCellContext();
            if (selectedCellData?.data?.value === sourceData.sourceText) {
                dispatch(gridUIActions.openCellEdit({ ...selectedCellData }));
                return;
            }
            dispatch(
                gridUIActions.copySource({
                    columnId: selectedCellData.columnId,
                    rowId: selectedCellData.rowId,
                    value: sourceData.sourceText,
                    successCallback: () => {
                        dispatch(gridUIActions.openCellEdit({ ...selectedCellData }));
                    }
                })
            );
        }
        return;
    }

    if (isKbSearch(e)) {
        return;
    }

    if (isKbSpace(e)) {
        dispatch(gridUIActions.toggleCellPreview());
        return;
    }

    if (isKbCommandAndSlash(e)) {
        dispatch(appActions.toggleKeyboardShortCut());
        return;
    }

    if (isKbPageStart(e)) {
        dispatch(gridUIActions.navigateFirstRow());
        return;
    }

    if (isKbPageEnd(e)) {
        dispatch(gridUIActions.navigateLastRow());
        return;
    }

    if (isNormalKeyboards(e)) {
        return;
    }

    //CELL TAB KEY
    if (isKbTab(e) || isKbArrowRight(e)) {
        e.stopPropagation();
        e.preventDefault();
        if (isNavigating) return;
        const start = now();
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToNextColumn({
                    rowIndex: selectedCellData?.rowIndex,
                    columnIndex: selectedCellData?.columnIndex,
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);

                        sendManualTrack({
                            type: `Scroll Cell to Right`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }

    //CELL SHIFT + TAB KEY
    if ((isKbShiftTab(e) && !document.getElementById('translation-edit-shift-tab')) || isKbArrowLeft(e)) {
        if (isNavigating) return;
        const start = now();

        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToPreviousColumn({
                    rowIndex: selectedCellData?.rowIndex,
                    columnIndex: selectedCellData?.columnIndex,
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Cell to Left`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
    }

    if (isKbArrowDown(e)) {
        if (isNavigating) return;

        isNavigating = true;
        const start = now();

        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToNextRow({
                    rowIndex: selectedCellData?.rowIndex,
                    columnIndex: selectedCellData?.columnIndex,
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Down Cell`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }

    if (isKbArrowUp(e)) {
        if (isNavigating) return;
        isNavigating = true;
        const start = now();
        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToPreviousRow({
                    rowIndex: selectedCellData?.rowIndex,
                    columnIndex: selectedCellData?.columnIndex,
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Up Cell`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }

    /**
     * Cell Range Key Selection
     */

    if (isKbShiftArrowRight(e)) {
        if (isNavigating) return;

        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftRight(() => {
                    isNavigating = false;
                })
            );
        }, 0);

        return;
    }

    if (isKbShiftArrowLeft(e)) {
        if (isNavigating) return;

        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftLeft(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    if (isKbShiftArrowDown(e)) {
        if (isNavigating) return;

        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftDown(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    if (isKbShiftArrowUp(e)) {
        if (isNavigating) return;

        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftUp(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    if (isKbPageUp(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigatePageUp(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    if (isKbPageDown(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigatePageDown(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    if (isKbHome(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateFirstColumn(() => {
                    isNavigating = false;
                })
            );
        }, 0);

        return;
    }

    if (isKbEnd(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateLastColumn(() => {
                    isNavigating = false;
                })
            );
        }, 0);
    }

    // if (isKBToggleTagShow(e)) {
    //     return;
    // }

    if (isKbCopy(e) || isKbPaste(e)) return;
    if (isKbShiftTab(e) && document.getElementById('translation-edit-shift-tab')) return;
    if (isKbUndo(e) || isKbRedo(e)) return;
    e.stopPropagation();
    e.preventDefault();
    cellKeyEventsCopyPasteHandler({ e, dispatch, selectedCellData, isRangeSelected });
}

export function cellKeyEventsCopyPasteHandler({ e, dispatch, selectedCellData, isRangeSelected }) {
    const columnType = selectedCellData?.column?.type;
    const isReadOnly = selectedCellData?.isReadOnly;

    if (e.type === 'copy' && !isRangeSelected) {
        setCopyPaseEvt(e);
    }
    if (e.type === 'paste' && !isReadOnly) {
        setCopyPaseEvt(e);
    }

    switch (columnType) {
        case columnTypes.SINGLE_LINE:
            _singleLineController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.FORMULA:
            _singleLineController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.MULTIPLE_LINES:
            _multipleLineController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.RICH_TEXT:
            _richTextController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.MARKDOWN:
            _markdownController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.NUMBER:
            _numberController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.DATETIME:
            _dateTimeController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.SINGLE_SELECTION:
            _singleSelectController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.REFERENCE:
            _referenceSelectController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.LOCALIZATION:
            _localizationSelectController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.MULTIPLE_SELECTIONS:
            _multipleSelectController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;

        case columnTypes.PATH_TAG:
            _pathTagController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.FILES:
            _filesController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.BOOLEAN:
            _booleanController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.TRANSLATION:
            _translationController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.JSON_LD:
            _jsonController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;

        case columnTypes.HTML:
            _htmlController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.YAML:
            _yamlController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.RECORD_ID:
            _recordIdController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            _alterTimeController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
            _alterByController({ isReadOnly, isRangeSelected, e, dispatch, ...selectedCellData });
            break;
        default:
            break;
    }
}

function _singleLineController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _multipleLineController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _richTextController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }

        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _markdownController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }

        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _numberController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm, rowIndex, columnIndex }) {
    const { key, type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }

        const regex = new RegExp(NOT_NUMBER_REGEX);
        if (!regex.test(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _dateTimeController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
    }
}

function _singleSelectController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _referenceSelectController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    column,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isReferencedBooleanType = column?.referencedColumnType === columnTypes.BOOLEAN;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }

    if (isReadOnly || isReferencedBooleanType) return;

    if (isKbEnter(e) || isKbF2(e)) {
        dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
    }
    if (isKbDelete(e)) {
        dispatch(gridUIActions.deleteCells());
    }
    if (characters.includes(key.toLowerCase())) {
        dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
    }
}

function _localizationSelectController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    columnId,
    rowId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _multipleSelectController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _pathTagController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm, rowIndex, columnIndex }) {
    const { type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
    }
}

function _filesController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm, rowIndex, columnIndex }) {
    const { type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            let uploadTarget = document.getElementById(`upload_${rowId}_${columnId}`);
            if (uploadTarget) {
                uploadTarget.click();
            }
        }

        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
    }
}

function _booleanController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm }) {
    const { type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e)) {
            dispatch(gridUIActions.changeBooleanStatus({ columnId, rowId }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
    }
}

function _translationController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _jsonController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm, rowIndex, columnIndex }) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _htmlController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm, rowIndex, columnIndex }) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _yamlController({ isReadOnly, isRangeSelected, e, dispatch, rowId, columnId, _tm, rowIndex, columnIndex }) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e) || isKbF2(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _recordIdController({
    isReadOnly,
    isRangeSelected,
    e,
    dispatch,
    rowId,
    columnId,
    _tm,
    rowIndex,
    columnIndex
}) {
    const { key, type } = e;

    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
            return;
        }
        if (type === 'paste') {
            if (isReadOnly) {
                dispatch(gridUIActions.cannotPasteWarning());
                return;
            }
            dispatch(gridUIActions.paste());
            return;
        }
        return;
    }
    if (!isReadOnly) {
        if (isKbEnter(e)) {
            dispatch(gridUIActions.openCellEdit({ rowId, columnId, _tm, rowIndex, columnIndex }));
        }
        if (isKbDelete(e) || isKbF2(e)) {
            dispatch(gridUIActions.deleteCells());
        }
        if (characters.includes(key.toLowerCase())) {
            dispatch(gridUIActions.openCellEditWithCharacter({ character: key, _tm }));
        }
    }
}

function _alterTimeController({ isReadOnly, isRangeSelected, e, dispatch }) {
    const { type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
        }
    }
}

function _alterByController({ isReadOnly, isRangeSelected, e, dispatch }) {
    const { type } = e;
    const isCopyPasteEvt = ['copy', 'paste'].indexOf(type) > -1;
    if (isCopyPasteEvt) {
        if (type === 'copy') {
            if (isRangeSelected) {
                return;
            }
            dispatch(gridUIActions.copy());
        }
    }
}
