import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import * as roleConst from 'auth/roleConst';
import { useDispatch } from 'react-redux';
import ws from 'socket';
import { setCurrentPage } from 'app/actions';
import { PERMISSION_SETTING } from 'const';
import Connectors from './connectors';
import * as paymentActions from 'payment/actions';
import * as addonActions from 'addons/actions';
import { useCompanyId, useCurrentUserId } from 'hooks/auth';
import { useRole } from 'hooks/auth/role';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import SourceForm from 'integrations/connectors/sources/form/Source';
import SourceDetail from 'integrations/connectors/sources/detail/index';
import ConnectionDetail from 'integrations/connectors/connections/detail/index';
import ConnectionForm from 'integrations/connectors/connections/form/Connection';
import SyncProvider from './connectors/connections/SyncProvider';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}));

function LocalizationSetting() {
    const classes = useStyles();
    let { path } = useRouteMatch();
    const dispatch = useDispatch();
    const userId = useCurrentUserId();
    const companyId = useCompanyId();

    React.useEffect(() => {
        ws.permissionSettingSubscribeTopics({ userId, companyId });
        dispatch(
            setCurrentPage({
                currentPage: PERMISSION_SETTING
            })
        );
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [dispatch, userId, companyId]);

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchCustomerAndSubscriptions({
                successCallback: () => {
                    console.log('fetchCustomerAndSubscriptions success');
                },
                errorCallback: () => {
                    console.log('fetchCustomerAndSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            addonActions.getAddonsList({
                successCallback: () => {
                    console.log('fetchSubscriptions Success');
                },
                errorCallback: () => {
                    console.log('fetchSubscriptions failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container className={classes.root}>
            <Switch>
                <Route path={path} exact>
                    <LocalizationRedirect path={path} />
                </Route>
                <Route exact path={`${path}/connectors`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <Connectors />
                    </AuthenticatedRoute>
                </Route>

                <Route exact path={`${path}/connectors/source`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <SourceForm />
                    </AuthenticatedRoute>
                </Route>

                <Route exact path={`${path}/connectors/source/:id`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <SourceDetail />
                    </AuthenticatedRoute>
                </Route>

                <Route path={`${path}/connectors/source/:id/edit`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <SourceForm />
                    </AuthenticatedRoute>
                </Route>

                <Route exact path={`${path}/connectors/connection`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <ConnectionForm />
                    </AuthenticatedRoute>
                </Route>
                <Route exact path={`${path}/connectors/connection/:id`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <ConnectionDetail />
                    </AuthenticatedRoute>
                </Route>
                <Route exact path={`${path}/connectors/connection/:id/edit`}>
                    <AuthenticatedRoute roles={[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]}>
                        <ConnectionForm />
                    </AuthenticatedRoute>
                </Route>
            </Switch>
            <SyncProvider />
        </Grid>
    );
}

function LocalizationRedirect({ path }) {
    const roles = useRole();

    if (roles[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON] === roleConst.FULL) {
        return <Redirect to={`${path}/connectors`} />;
    }
}

export default React.memo(LocalizationSetting);
