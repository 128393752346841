import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized-dn';
import Table from 'components/tables/Table';
import RoleSelect from 'workspaces/setting/members/RoleSelect';
import { useWorkspaceRoles } from 'hooks/workspace';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import { DEFAULT_FIRST_TABLE_COLUMN_WIDTH } from 'const/style';
import { useDispatch } from 'react-redux';
import * as memberActions from 'permission/actions/member';
import { useWorkspaceRolesOfSingleUser } from 'hooks/permission/member';
import WorkspaceOfMemberTableSkeleton from './WorkspaceOfMemberTableSkeleton';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    table: {
        border: '1px solid #e9eaef',
        borderRadius: 4,
        overflow: 'hidden',
        color: theme.colors.primaryText,
        '& .header-role': {
            marginLeft: 15
        },
        '& .cell-role': {
            paddingLeft: 15,

            '& .select': {
                marginLeft: -15
            }
        }
    }
}));
const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 56;

function WorkspaceOfMemberTable({ userId, groups }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const workspaceRoles = useWorkspaceRoles();
    const workspacesRolesOfSingleUser = useWorkspaceRolesOfSingleUser({ userId });
    const isFetching = workspacesRolesOfSingleUser?.isFetching;
    const workspacesRoles = workspacesRolesOfSingleUser?.list;

    const [sorting, setSorting] = useState({});

    const workspaces = React.useMemo(() => {
        return (
            workspacesRoles?.map(workspaceRole => ({
                ...workspaceRole,
                name: workspaceRole?.workspace?.name,
                roleName: workspaceRole?.role?.name
            })) || []
        );
    }, [workspacesRoles]);

    React.useEffect(() => {
        dispatch(
            memberActions.fetchWorkspacesRolesOfSingleMember({
                userId,
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }, [dispatch, userId, groups]);

    const data = useMemo(() => {
        const cloneData = [...workspaces];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, workspaces]);

    const nameRenderer = ({ rowData }) => {
        const { name } = rowData;
        return (
            <Grid container spacing={2} direction="row" wrap="nowrap">
                <Grid item style={{ display: 'flex' }}>
                    <WorkspaceSVG />
                </Grid>
                <Grid item>
                    <Typography variant="body2">{name}</Typography>
                </Grid>
            </Grid>
        );
    };

    const roleRenderer = ({ rowData }) => {
        const { role, userId, workspaceId } = rowData;

        const handleRoleChange = newRole => {
            sendManualTrack({ type: `Edit Workspace Role From Company` });
            dispatch(
                memberActions.changeUserWorkspaceRole({
                    userId,
                    workspaceId,
                    oldRole: role,
                    newRole,
                    successCallback: () => {
                        console.log('success changeUserProjectRole');
                    },
                    errorCallback: () => {
                        console.log('changeUserProjectRole failed');
                    }
                })
            );
        };
        return (
            <RoleSelect onRoleChange={handleRoleChange} defaultRoleName={role?.name} workspaceRoles={workspaceRoles} />
        );
    };

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const COLUMNS = [
        { dataKey: 'empty', width: DEFAULT_FIRST_TABLE_COLUMN_WIDTH, cellRenderer: null },
        { label: t('projects'), dataKey: 'name', width: 250, sort: true, cellRenderer: nameRenderer },
        { label: t('project_role'), dataKey: 'roleName', flexGrow: 1, sort: true, cellRenderer: roleRenderer }
    ];
    if (isFetching) return <WorkspaceOfMemberTableSkeleton />;

    return (
        <div
            className={classes.table}
            style={{ width: '100%', height: ROW_HEIGHT * workspaces.length + HEADER_HEIGHT }}
        >
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={data}
                        columns={COLUMNS}
                        width={width}
                        height={height}
                        rowHeight={ROW_HEIGHT}
                        onRowClick={handleRowClick}
                        onSort={handleOnSort}
                    ></Table>
                )}
            </AutoSizer>
        </div>
    );
}

export default React.memo(WorkspaceOfMemberTable);
