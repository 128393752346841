import React from 'react';

function UpdateColumnSVG({ color = 'white', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <mask
                id="mask0UpdateColumn"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
            >
                <circle cx="20" cy="20" r="20" fill="#C9C9CE" />
            </mask>
            <g mask="url(#mask0UpdateColumn)">
                <circle cx="20" cy="20" r="20" fill="#7869B9" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8372 28.3727L11.8372 11.5851L14.0651 11.5851L14.0651 28.3727L11.8372 28.3727ZM15.7871 10.7922L15.7871 29.1651C15.7871 29.6021 15.4005 29.957 14.9257 29.957L10.9737 29.957C10.4989 29.957 10.1122 29.6021 10.1122 29.1651L10.1122 10.7922C10.1122 10.3552 10.4989 10.0003 10.9737 10.0003L14.9257 10.0003C15.4005 10.0003 15.7871 10.3552 15.7871 10.7922Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7955 28.3727L18.7955 11.5851L21.0234 11.5851L21.0234 28.3727L18.7955 28.3727ZM22.7471 10.7922L22.7471 29.1651C22.7471 29.6021 22.3605 29.957 21.8857 29.957L17.9337 29.957C17.4589 29.957 17.0723 29.6021 17.0723 29.1651L17.0723 10.7922C17.0723 10.3552 17.4589 10.0003 17.9337 10.0003L21.8857 10.0003C22.3605 10.0003 22.7471 10.3552 22.7471 10.7922Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.9367 28.4167L25.9367 11.629L28.1646 11.629L28.1646 28.4167L25.9367 28.4167ZM29.8878 10.8352L29.8878 29.208C29.8878 29.6451 29.5011 30 29.0263 30L25.0743 30C24.5995 30 24.2129 29.6451 24.2129 29.208L24.2129 10.8352C24.2129 10.3981 24.5995 10.0432 25.0743 10.0432L29.0263 10.0432C29.5011 10.0432 29.8878 10.3981 29.8878 10.8352Z"
                    fill="white"
                />
                <ellipse cx="25.1835" cy="14.3187" rx="7.02138" ry="6.90949" fill="#7869B9" />
                <g clipPath="url(#clip0UpdateColumn)">
                    <path
                        d="M31.5009 14.6238C31.4987 14.5127 31.4587 14.4189 31.3809 14.3426C31.3031 14.2662 31.2085 14.228 31.097 14.2274L29.9569 14.2189C29.9273 13.6104 29.7858 13.0226 29.5325 12.4557C29.2792 11.8889 28.934 11.391 28.4971 10.9623C27.6743 10.1549 26.7167 9.69281 25.6244 9.57612C24.5321 9.45935 23.5034 9.69602 22.538 10.2862L22.5078 10.3168C22.4704 10.3547 22.4523 10.4014 22.4534 10.457C22.4545 10.5126 22.4745 10.5594 22.5134 10.5976L23.4066 11.474C23.4724 11.5386 23.5504 11.5491 23.6404 11.5056C24.0537 11.3375 24.3012 11.2448 24.3831 11.2276C24.9204 11.1009 25.4561 11.1131 25.9902 11.2643C26.5243 11.4155 26.9918 11.6878 27.3927 12.0812C27.9942 12.6714 28.3193 13.3834 28.3681 14.2172L27.1533 14.2125C27.0419 14.2122 26.9487 14.2499 26.8739 14.3256C26.7991 14.4014 26.7629 14.4949 26.765 14.606C26.7672 14.7172 26.8073 14.8109 26.8851 14.8873L28.8959 16.8604C28.9737 16.9367 29.0683 16.9751 29.1798 16.9755C29.2912 16.9759 29.3844 16.9382 29.4591 16.8624L31.392 14.9041C31.4668 14.8283 31.5032 14.7351 31.5009 14.6238Z"
                        fill="white"
                    />
                    <path
                        d="M27.8235 18.01L26.9618 17.1644C26.8959 17.0998 26.818 17.0892 26.728 17.1328C26.3147 17.301 26.0672 17.3935 25.9854 17.4108C25.4481 17.5374 24.9123 17.5252 24.3783 17.3741C23.8441 17.2229 23.3767 16.9506 22.9758 16.5571C22.6856 16.2724 22.4575 15.9454 22.2917 15.5763C22.1261 15.2071 22.0319 14.822 22.0093 14.4212L23.2153 14.4258C23.3267 14.4262 23.4199 14.3885 23.4947 14.3128C23.5694 14.237 23.6058 14.1435 23.6035 14.0324C23.6014 13.9212 23.5614 13.8274 23.4836 13.7511L21.4728 11.778C21.395 11.7016 21.3003 11.6633 21.1887 11.6629C21.0773 11.6624 20.9841 11.7002 20.9093 11.776L18.9765 13.7343C18.9018 13.81 18.8654 13.9035 18.8676 14.0146C18.87 14.1257 18.9098 14.2196 18.9876 14.296C19.0654 14.3723 19.1602 14.4105 19.2716 14.411L20.4072 14.4152C20.4341 15.0266 20.5726 15.6129 20.8228 16.174C21.073 16.7351 21.4167 17.2299 21.8535 17.6585C22.6734 18.4631 23.628 18.9222 24.7172 19.0359C25.8065 19.1497 26.8323 18.91 27.7947 18.3171C27.8035 18.3142 27.815 18.3054 27.8293 18.2909C27.8667 18.2529 27.8847 18.2063 27.8837 18.1506C27.8825 18.0951 27.8625 18.0482 27.8235 18.01Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0UpdateColumn">
                    <rect
                        width="9.65865"
                        height="9.43355"
                        fill="white"
                        transform="matrix(0.713768 0.700383 -0.702463 0.711721 25.0508 7.58008)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(UpdateColumnSVG);
