import React from 'react';

function WebRequestSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip_web_request)">
                <path
                    d="M8.40526 7.66667C7.65925 8.92076 6.94452 10.1352 6.21489 11.3405C6.0275 11.6499 5.93476 11.902 6.08446 12.2953C6.49775 13.382 5.91472 14.4394 4.81897 14.7264C3.78559 14.9972 2.77879 14.318 2.57375 13.2117C2.39207 12.2324 3.152 11.2724 4.23172 11.1194C4.32214 11.1064 4.41454 11.105 4.56655 11.0936L6.20891 8.33956C5.17595 7.31243 4.56114 6.11185 4.69719 4.62404C4.79338 3.57237 5.20696 2.66351 5.96282 1.91862C7.41055 0.49212 9.61921 0.261144 11.3239 1.35605C12.961 2.40778 13.7108 4.45641 13.0717 6.20979C12.5898 6.07915 12.1045 5.94739 11.571 5.80268C11.7717 4.8278 11.6233 3.95241 10.9658 3.20246C10.5314 2.70732 9.97393 2.44779 9.34014 2.35217C8.06945 2.16021 6.82189 2.97654 6.4517 4.22375C6.03151 5.63914 6.66749 6.79543 8.40526 7.66674V7.66667Z"
                    fill="#C73A63"
                />
                <path
                    d="M10.5359 6.18373C11.0615 7.11095 11.5952 8.05216 12.1241 8.98472C14.7974 8.15763 16.813 9.63751 17.5361 11.2219C18.4095 13.1357 17.8124 15.4025 16.0971 16.5832C14.3365 17.7953 12.1099 17.5882 10.55 16.0312C10.9475 15.6984 11.347 15.3641 11.7739 15.007C13.3146 16.0049 14.6622 15.9579 15.6626 14.7763C16.5157 13.7682 16.4972 12.2651 15.6193 11.2782C14.6063 10.1393 13.2493 10.1046 11.609 11.1978C10.9286 9.99069 10.2364 8.79319 9.57747 7.57733C9.35528 7.16755 9.11003 6.92975 8.60926 6.84306C7.77303 6.69807 7.23316 5.97996 7.20075 5.17544C7.16889 4.37978 7.63767 3.66055 8.37033 3.38035C9.09611 3.10269 9.94781 3.32677 10.436 3.94384C10.8349 4.44799 10.9617 5.01541 10.7518 5.63719C10.6934 5.81058 10.6178 5.97835 10.5359 6.1838V6.18373Z"
                    fill="#4B4B4B"
                />
                <path
                    d="M11.7918 13.7634H8.57362C8.26516 15.0321 7.59888 16.0564 6.45089 16.7078C5.55841 17.214 4.59654 17.3856 3.57279 17.2203C1.68785 16.9164 0.146599 15.2195 0.0108959 13.3089C-0.142667 11.1444 1.34508 9.22047 3.3281 8.78833C3.465 9.2855 3.60337 9.78746 3.74027 10.2834C1.92086 11.2117 1.29115 12.3813 1.80035 13.8437C2.24859 15.1307 3.52188 15.8362 4.90444 15.5634C6.31631 15.2848 7.02815 14.1114 6.94125 12.2283C8.27972 12.2283 9.61931 12.2145 10.9579 12.2352C11.4806 12.2434 11.8841 12.1892 12.2779 11.7283C12.9262 10.9701 14.1195 11.0385 14.8177 11.7546C15.5312 12.4865 15.497 13.6641 14.7419 14.3651C14.0134 15.0415 12.8625 15.0054 12.1809 14.2765C12.0408 14.1263 11.9304 13.9479 11.7918 13.7634Z"
                    fill="#4A4A4A"
                />
            </g>
            <defs>
                <clipPath id="clip_web_request">
                    <rect width="18" height="16.8048" fill="white" transform="translate(0 0.600098)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(WebRequestSVG);
