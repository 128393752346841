import React, { useRef, useState, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIconSVGFilled from 'assets/images/svg/AddIconSVGFilled';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import PopperMenu from 'components/menus/Popper';
import CellBaseCardInfoGroup from 'components/selects/CellBaseCardInfoGroup';
import { Handle } from 'react-flow-renderer';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { WEB_HOOK_TRIGGER_ACTIONS_OPTIONS } from 'const/automation';
import { useDispatch } from 'react-redux';
import { deleteTriggerNode, updateTriggerNode } from '../../action';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { ACTION_STATUS, showWatchingColumnsTriggers } from 'gridUI/automations/const';
import { WEB_HOOK_TRIGGERS_ACTIONS_LABEL } from 'const/gridUI';
import InfoContainedIconSVG from 'assets/images/svg/InfoContainedIconSVG';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import { useColumnsPermission } from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import OverflowTypography from 'components/typography/OverflowTypography';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        width: 440,
        padding: `16px 19px`,
        background: theme.colors.white,
        borderRadius: 4,
        boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.15)`
    },
    flex: { display: 'flex' },
    flx: { flex: 1 },
    triggerText: {
        color: theme.colors.midGrey,
        textTransform: 'uppercase'
    },
    content: {
        marginTop: 2
    },
    addIcon: {
        margin: '0 8px 0 4px'
    },
    item: {
        marginTop: 12,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: `9px 16px`,
        cursor: 'pointer',
        transition: `border-color 0.6s linear;`
    },
    itemError: {
        borderColor: theme.colors.brightRed
    },
    itemSelected: {
        borderColor: theme.colors.fuchsiaBlue
    },
    itemDisabled: {
        opacity: 0.4
    },
    iconWrapper: { marginRight: 14 },
    iconMore: {},
    addTrigger: {
        marginTop: 12
    },
    addTriggerText: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    addIcon2: {
        paddingRight: 8,
        cursor: 'pointer'
    },
    popperWrapper: {
        width: 647
    },
    morePopup: {
        width: 260
    },
    mt12: {
        marginTop: 12
    },
    infoSVG: {
        verticalAlign: 'text-top'
    },
    error: {
        color: theme.colors.brightRed,
        marginLeft: 8
    },
    errorWrapper: {
        height: 20
    },
    description: {
        marginTop: 8,
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.midGrey
    }
}));

const stopPropagation = e => {
    e.stopPropagation();
    e.preventDefault();
    e.persist();
};

function getPrefix(localizationType) {
    let label = null;

    if (localizationType === TRANSLATION_TYPES.SOURCE_LANG) {
        label = 'Source';
    } else if (localizationType === TRANSLATION_TYPES.TARGET_LANG) {
        label = 'Target';
    } else if (localizationType === TRANSLATION_TYPES.ORIGINAL_LANG) {
        label = 'Original';
    }

    return label;
}

const TriggerItem = React.memo(
    ({ automationDetail, trigger, selectedNode, onClickNode, setSelectedNode, showIconMore, viewColumnOptions }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [anchorMoreEl, setAnchorMoreEl] = useState(null);
        const [openDelete, setOpenDelete] = useState(false);
        const [deleting, setDeleting] = useState(false);
        const iconMoreRef = useRef();
        const [actionStatus, setActionStatus] = useState(trigger.status || ACTION_STATUS.ENABLED);

        const { isError, order, watchingColumns, watchingAll, trigger: triggerType } = trigger;

        const isDisabled = useMemo(() => {
            return actionStatus === ACTION_STATUS.DISABLED;
        }, [actionStatus]);

        const errorWatchingColumns = useMemo(() => {
            if (isError) {
                return t('automation_trigger_invalid_configuration');
            }
            return '';
        }, [isError, t]);

        const description = useMemo(() => {
            if (!showWatchingColumnsTriggers.includes(triggerType)) return '';
            if (watchingAll) return 'Watching on all columns';
            if (watchingColumns?.length === 1) {
                const column = viewColumnOptions.find(col => col.value === watchingColumns[0]);
                if (column) {
                    const { customProperties = {} } = column;
                    const { localizationType } = customProperties;
                    let localizeLabel = getPrefix(localizationType);
                    const labelGroup = [localizeLabel, column?.group].filter(el => !!el).join('_');
                    return (
                        <span>
                            Watching on{' '}
                            <b>
                                {localizeLabel ? `(${labelGroup})` : ''}
                                {column.name}
                            </b>
                        </span>
                    );
                }
            } else if (watchingColumns?.length > 1) {
                return (
                    <span>
                        Watching on <b>{watchingColumns.length}</b> columns
                    </span>
                );
            }
            return '';
        }, [triggerType, viewColumnOptions, watchingAll, watchingColumns]);

        const handleClickMoreAction = useCallback(e => {
            stopPropagation(e);
            setAnchorMoreEl(anchorMoreEl => (anchorMoreEl ? null : iconMoreRef.current));
        }, []);

        const handleCloseMore = useCallback(e => {
            setAnchorMoreEl(null);
        }, []);

        const handleDelete = useCallback(
            e => {
                stopPropagation(e);
                setOpenDelete(true);
                handleCloseMore();
            },
            [handleCloseMore]
        );

        const handleCloseDelete = useCallback(
            e => {
                if (deleting) return;
                setOpenDelete(false);
            },
            [deleting]
        );

        const handleAgreeDelete = useCallback(
            e => {
                stopPropagation(e);
                if (deleting) return;
                setDeleting(true);
                dispatch(
                    deleteTriggerNode({
                        triggerId: trigger.id,
                        isSelectedNodeTrigger: !!selectedNode?.trigger,
                        selectedNodeId: selectedNode?.id,
                        successSelectedNodeCallback: newNode => {
                            if (selectedNode?.id === trigger.id) return;
                            setSelectedNode(newNode);
                        },
                        successCallback: () => {
                            setDeleting(false);
                            setOpenDelete(false);
                            if (selectedNode?.id === trigger.id) {
                                setSelectedNode(null);
                            }
                        },
                        errorCallback: () => {
                            setDeleting(false);
                        }
                    })
                );
            },
            [deleting, dispatch, setSelectedNode, selectedNode, trigger.id]
        );

        const handleToggleEnable = useCallback(
            e => {
                stopPropagation(e);
                handleCloseMore();
                const newStatus = isDisabled ? ACTION_STATUS.ENABLED : ACTION_STATUS.DISABLED;
                setActionStatus(newStatus);
                const oldSelectedNode = JSON.parse(JSON.stringify(selectedNode));
                if (selectedNode?.id === trigger.id) {
                    onClickNode(e, {
                        ...selectedNode,
                        status: newStatus
                    });
                }
                dispatch(
                    updateTriggerNode({
                        automationId: automationDetail.id,
                        triggerId: trigger.id,
                        body: {
                            status: newStatus
                        },
                        successCallback: responseData => {},
                        errorCallback: () => {
                            setDeleting(false);
                            setActionStatus(isDisabled ? ACTION_STATUS.DISABLED : ACTION_STATUS.ENABLED);
                            if (selectedNode?.id === trigger.id) {
                                onClickNode(e, oldSelectedNode);
                            }
                        }
                    })
                );
            },
            [handleCloseMore, isDisabled, dispatch, automationDetail, trigger.id, selectedNode, onClickNode]
        );

        const handleClick = useCallback(
            e => {
                onClickNode(e, trigger);
            },
            [onClickNode, trigger]
        );

        const onContextMenu = React.useCallback(
            e => {
                handleClickMoreAction(e);
            },
            [handleClickMoreAction]
        );

        return (
            <>
                <Grid
                    item
                    className={classnames(classes.item, {
                        [classes.itemError]: !!errorWatchingColumns,
                        [classes.itemSelected]: selectedNode?.id === trigger.id && Boolean(selectedNode?.trigger),
                        [classes.itemDisabled]: trigger.status === ACTION_STATUS.DISABLED
                    })}
                    onClick={handleClick}
                    onContextMenu={onContextMenu}
                >
                    <Grid item container alignItems="center" wrap="nowrap">
                        <Grid item className={classnames(classes.iconWrapper)}>
                            <WebHookLabel type={trigger.trigger} />
                        </Grid>
                        <Grid item className={classnames(classes.flx)}>
                            <p className="body2">
                                {order}. When: <b>{WEB_HOOK_TRIGGERS_ACTIONS_LABEL[trigger.trigger]}</b>
                            </p>
                        </Grid>
                        {showIconMore && (
                            <Grid item className={classes.iconMore}>
                                <IconButton ref={iconMoreRef} onClick={handleClickMoreAction}>
                                    <IconMoreActionsSVG color="#1A051D" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                    {description && (
                        <OverflowTypography variant="body2" maxLines={1} className={classes.description}>
                            {description}
                        </OverflowTypography>
                    )}
                </Grid>
                {errorWatchingColumns && (
                    <Grid item container wrap="nowrap" className={classnames(classes.mt12, classes.errorWrapper)}>
                        <Grid item>
                            <InfoContainedIconSVG bgColor="#F6BD42" className={classes.infoSVG} />
                        </Grid>
                        <Grid item>
                            <p className="body2 ml-2 text-error">{errorWatchingColumns}</p>
                        </Grid>
                    </Grid>
                )}
                {anchorMoreEl && (
                    <PopperMenu
                        handleClickAway={handleCloseMore}
                        anchorEl={anchorMoreEl}
                        container={document.getElementById('react-flow-wrapper')}
                    >
                        <Grid item container direction="column" wrap="nowrap" className={classes.morePopup}>
                            <ListItem
                                onClick={handleToggleEnable}
                                icon={<EyeCloseIconSVG />}
                                name={t(isDisabled ? `global_enable` : `global_disable`)}
                            />
                            <ListItem onClick={handleDelete} icon={<DeleteSVG />} name={t(`global_delete`)} />
                        </Grid>
                    </PopperMenu>
                )}
                <Dialog open={openDelete} onClose={handleCloseDelete}>
                    <ConfirmBox
                        title={t('automation_confirm_delete_trigger_title')}
                        body={<p className="body2">{t('automation_confirm_delete_trigger_content')}</p>}
                        handleCancel={handleCloseDelete}
                        onClose={handleCloseDelete}
                        handleAgreed={handleAgreeDelete}
                        agreeLabel={t('global_delete')}
                        isLoading={deleting}
                        colorType={COLOR_TYPES.SECONDARY}
                    />
                </Dialog>
            </>
        );
    }
);

const TriggerNode = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const addRef = useRef();
    const timeout = useRef();
    const automationDetail = useGetAutomationDetail();
    const columnsPermission = useColumnsPermission();
    const { data = {} } = props;
    const { isCreate, triggers, onCreateTrigger, selectedNode, setSelectedNode, onClickNode } = data;

    const INTERNAL_ALL_OPTIONS = useMemo(() => {
        let options = [];
        for (const event of WEB_HOOK_TRIGGER_ACTIONS_OPTIONS) {
            options = options?.concat(event?.options);
        }
        return options;
    }, []);

    const selectedTriggers = useMemo(() => {
        return triggers?.map(trigger => {
            return INTERNAL_ALL_OPTIONS?.find(option => option?.value === trigger.trigger);
        });
    }, [triggers, INTERNAL_ALL_OPTIONS]);

    const viewColumnOptions = useMemo(() => {
        return (
            columnsPermission
                // .filter(col => !SYSTEM_COLUMN_IDS?.includes(col.id) && col.editable)
                .map(col => {
                    let value = '';
                    if (col.id === columnTypes.RECORD_ID) value = '_recordId';
                    else if (col.id === columnTypes.PATH_TAG) value = '_path';
                    else value = col.publicId;
                    return {
                        ...col,
                        value,
                        label: col.name,
                        options: undefined
                    };
                })
        );
    }, [columnsPermission]);

    const handleOpen = useCallback(e => {
        stopPropagation(e);
        setAnchorEl(anchorEl => (anchorEl ? null : e.target));
    }, []);

    const handleClose = useCallback(e => {
        setAnchorEl(null);
    }, []);

    const handleTriggersChange = useCallback(
        option => {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                handleClose();
                onCreateTrigger && onCreateTrigger(option);
            }, 100);
        },
        [handleClose, onCreateTrigger]
    );

    return (
        <Grid item container direction="column" wrap="nowrap" className={classes.root} id="automation-triggers-node">
            <Grid item>
                <p className="body1 uppercase text-grey-mid">{t('automation_trigger')}</p>
            </Grid>
            <Grid item className={classes.content}>
                {isCreate ? (
                    <Grid
                        ref={addRef}
                        item
                        container
                        alignItems="center"
                        className={classnames(classes.item, {
                            [classes.itemSelected]: !!anchorEl
                        })}
                        onClick={handleOpen}
                    >
                        <Grid item className={classnames(classes.flex, classes.addIcon)}>
                            <AddIconSVGFilled />
                        </Grid>
                        <Grid item>
                            <p className="body1 cursor-pointer text-text-primary">{t('automation_add_trigger')}</p>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {triggers.map((trigger, idx) => (
                            <TriggerItem
                                key={trigger.id}
                                trigger={trigger}
                                automationDetail={automationDetail}
                                showIconMore={!(idx === 0 && idx === triggers.length - 1)}
                                selectedNode={selectedNode}
                                onClickNode={onClickNode}
                                setSelectedNode={setSelectedNode}
                                viewColumnOptions={viewColumnOptions}
                            />
                        ))}

                        <Grid ref={addRef} item container alignItems="center" className={classes.addTrigger}>
                            <Grid item className={classnames(classes.flex, classes.addIcon2)} onClick={handleOpen}>
                                <AddIconSVGFilled />
                            </Grid>
                            <Grid item>
                                <p className="body1 cursor-pointer text-text-primary" onClick={handleOpen}>
                                    {t('automation_add_trigger')}
                                </p>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            {!isCreate && <Handle type="source" position="bottom" isConnectable={false} />}
            {anchorEl && (
                <PopperMenu
                    handleClickAway={handleClose}
                    anchorEl={addRef.current}
                    container={document.getElementById('react-flow-wrapper')}
                >
                    <Grid item className={classes.popperWrapper}>
                        <CellBaseCardInfoGroup
                            onChange={handleTriggersChange}
                            options={WEB_HOOK_TRIGGER_ACTIONS_OPTIONS}
                            placeholder={t('automation_find_a_trigger')}
                            value={selectedTriggers}
                            className={classes.dropdown}
                        />
                    </Grid>
                </PopperMenu>
            )}
        </Grid>
    );
};

export default React.memo(TriggerNode);
