import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as gridUIActions from 'gridUI/actions';
import { USER_REALTIME_ACTION } from 'const/gridUI';
import ws from 'socket';
import { formatUserRealtimeBody } from 'utils/user';

const { dispatch, getState } = store;
let listen = false;

const gridUserHandler = message => {
    let { body } = mapResponse(message);
    const { action } = body;
    switch (action) {
        case USER_REALTIME_ACTION.JOIN:
            return _userJoinHandler(body);
        case USER_REALTIME_ACTION.REGISTER:
            return _userRegisterHandler(body);
        case USER_REALTIME_ACTION.LEAVE:
            return _userLeaveHandler(body);
        case USER_REALTIME_ACTION.FOCUS:
        case USER_REALTIME_ACTION.EDIT:
        case USER_REALTIME_ACTION.RESET:
            return _useCellDoingHandler(body);
        default:
            return;
    }
};

function _userJoinHandler(body) {
    const { gridUI, auth } = getState();
    const { currentUser } = auth;
    const companyId = auth.companyId || auth.currentUser?.companyId;

    const pathname = window.location?.pathname;

    const arr = pathname?.split('/');
    const dbId = arr?.[4] || gridUI?.dbId;
    const branchId = arr?.[8] || gridUI?.branchId;
    const topic = `/topic/c/${companyId}/databases/${dbId}/grids/${branchId}/user_connected`;

    return ws.sendMessage({
        topic,
        message: { action: USER_REALTIME_ACTION.REGISTER, user: formatUserRealtimeBody(currentUser) }
    });
}

function appendUserOrNot(user) {
    dispatch(gridUIActions.appendUserToActiveUserList(user));
}

function _userRegisterHandler(body) {
    const { auth } = getState();
    const { currentUser } = auth;

    listen = true;
    if (body?.user?.id === currentUser.id) {
        console.log('SAME USER => IGNORED');
        return;
    }
    appendUserOrNot(body?.user);
}

function _userLeaveHandler(body) {
    const { auth } = getState();
    const { currentUser } = auth;
    listen = false;
    if (body?.user?.id === currentUser.id) {
        console.log('SAME USER => IGNORED');
        return;
    }

    dispatch(gridUIActions.realtimeUserLeave(body?.user));
}

function _useCellDoingHandler(body) {
    const { auth } = getState();
    const { currentUser } = auth;
    if (body?.user?.id === currentUser.id || !listen) {
        console.log('SAME USER => IGNORED');
        return;
    }

    appendUserOrNot(body?.user);
    dispatch(gridUIActions.realtimeUserDoing(body));
}

export default gridUserHandler;
