import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import CellBaseDropdown from 'components/selects/CellBase';
import { Grid, Popper, Paper } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import * as gridActions from 'advancedSearch/actions';
import { SINGLE_SELECTION } from 'const/columnTypes';
import { INPUT_PADDING_LEFT, INPUT_QUICK_FILTER_HEIGHT, INPUT_RADIUS, DISABLED_OPACITY } from 'const/style';
import { MAX_OPTIONS } from 'const/gridUI';
import { OPERATOR } from 'gridUI/conditions';
import { getOptionColor } from 'utils/color';
import Option from 'components/option/Base';

const useStyles = makeStyles(theme => ({
    zIndex: {
        zIndex: theme.zIndex.modal + 200
    },
    selection: {
        fontSize: `13px !important`,
        background: theme.colors.paleGrey,
        border: `none`,
        height: INPUT_QUICK_FILTER_HEIGHT,
        overflow: 'hidden',
        paddingLeft: INPUT_PADDING_LEFT,
        display: 'flex',
        alignItems: 'center',
        borderRadius: INPUT_RADIUS
    },
    focus: {
        border: `1px solid ${theme.palette.primary.main} !important`
    },
    paper: {
        minWidth: 200
    },
    chip: {
        height: 22,
        maxHeight: 22,
        '& svg': {
            marginLeft: 1
        }
    },
    placeholder: {
        color: theme.colors.midGrey,
        fontSize: 13,
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY,
        cursor: 'not-allowed'
    }
}));

function SingleSelectionQuickFilter({ column, columnId, isSelectionActive, quickFilters, isDisabled, t, ...rest }) {
    const [selectedOption, setSelectedOption] = useState(
        (quickFilters[columnId] && quickFilters[columnId].value) || null
    );

    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const singleSelectOptions = useMemo(() => {
        return isEmpty(column.options)
            ? []
            : column.options.map(option => {
                  return {
                      value: option.label,
                      label: option.label
                  };
              });
    }, [column.options]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleOptionChange = option => {
        if ((option && option.value) === (selectedOption && selectedOption.value)) {
            setSelectedOption(null);
            callServer(null);
            setAnchorEl(null);
            return;
        }
        setAnchorEl(null);
        setSelectedOption(option);
        callServer(option);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const callServer = option => {
        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: option,
                type: SINGLE_SELECTION,
                operator: OPERATOR.contains
            })
        );
        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    const handleDelete = () => {
        setSelectedOption(null);
        callServer(null);
    };

    return (
        <Grid className={classes.dateInput}>
            <div
                onClick={handleClick}
                className={`${classes.selection} ${anchorEl ? classes.focus : ''} ${
                    isDisabled ? classes.disabled : ``
                }`}
            >
                {!selectedOption && <span className={classes.placeholder}>{t(`global_label_select_an_option`)}</span>}
                {selectedOption && (
                    <Option
                        size="small"
                        onDelete={handleDelete}
                        label={selectedOption.label}
                        color={getOptionColor({ ...column, data: selectedOption?.value })}
                    />
                )}
            </div>
            <Popper className={classes.zIndex} id={id} open={open} anchorEl={anchorEl} transition>
                <Paper className={classes.paper}>
                    <CellBaseDropdown
                        options={singleSelectOptions}
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        maxSize={MAX_OPTIONS}
                        defaultValue={selectedOption}
                        onBlur={handleClickAway}
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(SingleSelectionQuickFilter);
