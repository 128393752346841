export const mapResponse = (response) => {
    console.log("MESSAGE RESPONSE", response)
    const {headers, body} = response;
    let mapData = {
        headers
    }
    try{
        let newBody = JSON.parse(body);
        mapData.body = {...newBody};
    }catch(error){
        mapData.body = null
    }
    return mapData
}