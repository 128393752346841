import { useSelector } from 'react-redux';

export function useMetaData() {
    return useSelector(state => state.gridUI.metaData);
}

export function useColumnMetaData(columnId) {
    return useSelector(state => state.gridUI.metaData?.[columnId]);
}

export function useMetaDataWithReferenceGridDetail(dbId) {
    return useMetaData();
}
