import React from 'react';

const SaveSVG = ({ color = '#78778B', ...other }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M11.8889 1H2.55556C1.69222 1 1 1.7 1 2.55556V13.4444C1 14.3 1.69222 15 2.55556 15H13.4444C14.3 15 15 14.3 15 13.4444V4.11111L11.8889 1ZM13.4444 13.4444H2.55556V2.55556H11.2433L13.4444 4.75667V13.4444ZM8 8C6.70889 8 5.66667 9.04222 5.66667 10.3333C5.66667 11.6244 6.70889 12.6667 8 12.6667C9.29111 12.6667 10.3333 11.6244 10.3333 10.3333C10.3333 9.04222 9.29111 8 8 8Z"
                fill={color}
            />
            <rect x="3.90459" y="4.5" width="6.54044" height="2" stroke={color} />
        </svg>
    );
};

export default React.memo(SaveSVG);
