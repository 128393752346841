import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import IconCaretRightSVG from 'assets/images/svg/IconCaretRightSVG';
import { useDispatch } from 'react-redux';
import { closeSnackbar } from 'notifier/actions';
import { LQA_TYPES } from 'const/gridUI';
import { useMetaData } from 'hooks/gridUI';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
// import Avatar from 'components/avatar/User';
// import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
// import { getAvatarUrl } from 'utils/images';
import { getPriorityIcon } from 'utils/gridUI/lqa';
import LQATags from './LQATags';
import { setLQATicketDetail, setOpenLQATicketDetail } from 'gridUI/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: '320px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        background: '#ffffff'
    },
    wrapperTop: {
        padding: '16px'
    },
    added: {
        color: theme.colors.secondaryText
    },
    checkIcon: {
        marginRight: theme.spacing(1),
        width: '13px',
        height: '9.75px'
    },
    title: {
        marginTop: '12px',
        lineHeight: '21px',
        color: theme.colors.primaryText,
        ...theme.ellipsis(2)
    },
    lqaTags: {
        marginTop: '12px'
    },
    option: {
        '& p': {
            fontSize: '13px'
        }
    },
    identify: {
        marginTop: '13px'
    },
    rectangleIcon: {
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    identifyLabel: {
        color: theme.colors.lightGreyBlue,
        marginLeft: '5px'
    },
    wrapperBottom: {
        padding: '14px 16px',
        borderTop: `1px solid ${theme.colors.divider}`
    },
    dismiss: {
        cursor: 'pointer',
        color: theme.colors.secondaryText,
        fontSize: '13px'
    },
    viewTicket: {
        cursor: 'pointer',
        paddingRight: '5.5px',
        '& p': {
            color: theme.colors.highlight,
            fontSize: '13px'
        }
    },
    caretIcon: {
        cursor: 'pointer',
        width: '8px',
        height: '8px'
    },
    columnType: {
        marginTop: '15px'
    }
}));

function TicketSticky({ snackBarKey, ticket }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const metaData = useMetaData();
    const { t } = useTranslation();

    const {
        id,
        type,
        priority,
        summary,
        columnId,
        lqaModel,
        lqaModelCategory,
        lqaModelSubCategory,
        lqaModelSeverity
        // audit
    } = ticket;

    // const {
    //     createdBy: { imageUrl }
    // } = audit;

    const column = React.useMemo(() => {
        return metaData?.[columnId] || null;
    }, [metaData, columnId]);

    const isLQAType = React.useMemo(() => {
        return type === LQA_TYPES.LQA_ISSUE;
    }, [type]);

    const handleDismiss = React.useCallback(() => {
        dispatch(closeSnackbar(snackBarKey));
    }, [dispatch, snackBarKey]);

    const handleViewTicket = React.useCallback(() => {
        handleDismiss();
        dispatch(setLQATicketDetail(ticket));
        dispatch(setOpenLQATicketDetail(true));
    }, [dispatch, handleDismiss, ticket]);

    return (
        <Grid container className={classes.root}>
            <Grid className={classes.wrapperTop} container direction="column">
                <Grid container alignItems="center">
                    <Grid item>
                        <CheckIconSVG className={classes.checkIcon} />
                    </Grid>
                    <Grid item>
                        <p className="body2 text-text-secondary"> {t('added_to_ticket_center')}</p>
                    </Grid>
                </Grid>
                <Grid item>
                    <p className="body2 line-clamp-2 mt-3 text-text-primary">{summary}</p>
                </Grid>
                <Grid item className={classes.lqaTags}>
                    {isLQAType && (
                        <LQATags
                            lqaModel={lqaModel}
                            lqaModelCategory={lqaModelCategory}
                            lqaModelSubCategory={lqaModelSubCategory}
                            lqaModelSeverity={lqaModelSeverity}
                        />
                    )}
                </Grid>
                <Grid className={classes.identify} container>
                    <Grid item className={classes.rectangleIcon}>
                        {getPriorityIcon(priority)}
                    </Grid>
                    <Grid item>
                        <p className="text-grey-blue ml-1 body2">Ticket-{id}</p>
                    </Grid>
                </Grid>
                <Grid container alignItems="flex-end" justify="space-between" className={classes.columnType}>
                    <Grid item>
                        <ColumnTypeDisplay {...column} />
                    </Grid>
                    {/* <Grid item>
                        <Avatar src={getAvatarUrl(imageUrl)} size={32}>
                            <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                        </Avatar>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid className={classes.wrapperBottom} container direction="column">
                <Grid container alignItems="center" justify="space-between">
                    <Grid item onClick={handleDismiss}>
                        <p className="body2 cursor-pointer text-text-secondary">{t('global_dismiss')}</p>
                    </Grid>
                    <Grid item onClick={handleViewTicket}>
                        <Grid container alignItems="center">
                            <Grid item className={classes.viewTicket}>
                                <p className="body2">{t('global_view_ticket')}</p>
                            </Grid>
                            <Grid item>
                                <IconCaretRightSVG className={classes.caretIcon} color={theme.colors.highlight} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(TicketSticky);
