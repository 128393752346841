import React, { useCallback, useState } from 'react';
import { CircularProgress, Grid, TableCell, Typography, useTheme } from '@material-ui/core';
import { capitalize, groupBy, isEmpty, upperFirst } from 'lodash';
import TwoDigitsLang from 'common/TwoDigitsLang';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { getFriendlyTime } from 'utils/datetime';
import ColorCloseCircleSVG from 'assets/images/svg/ColorCloseCircleSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { makeStyles } from '@material-ui/styles';
import { getFullName } from 'utils/user';
import { useEffect } from 'react';
import { useRef } from 'react';
import InputText from 'components/inputs/InputText';
import classnames from 'classnames';
import CheckedContainerSVG from 'assets/images/svg/CheckedContainerSVG';
import { useDispatch } from 'react-redux';
import { updateTerm, updateTermDetail } from 'gridUI/actions';
import { isKbEnter } from 'utils/keyboard';
import ButtonBase from 'components/button/Base';
import { useMemo } from 'react';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Base';
import { TB_TERM_STATUS } from 'const/tb';
import PopperMenu from 'components/menus/Popper';
import ListItem from 'components/list/Item';
import hexToRgba from 'hex-to-rgba';
import { LOCALIZATION_FONT } from 'const/gridUI';
import { COLOR_TYPES } from 'const';

const minCellHeight = 36;

const useStyles = makeStyles(theme => ({
    term: {
        marginBottom: 20
    },
    cell: {
        minHeight: minCellHeight
    },
    text: {
        flex: 1,
        borderRadius: `4px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& .controls': {
            display: 'none'
        },
        '&:hover': {
            background: theme.colors.paleNavy,
            cursor: 'pointer',
            '& .controls': {
                display: 'block'
            },
            '& p': {
                textDecoration: 'underline'
            }
        }
    },
    inputWrapper: {
        paddingLeft: `0 !important`,
        paddingRight: `0 !important`,
        paddingBottom: `0 !important`
    },
    newActions: {
        marginTop: 4,
        '& svg': {
            cursor: 'pointer'
        }
    },
    addBtn: {
        marginLeft: 12,
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    errorClassName: {
        bottom: -24,
        fontSize: 14,
        width: 'fit-content'
    },
    textError: {
        color: theme.colors.brightRed
    },
    textItem: {
        ...theme.ellipsis(4),
        wordBreak: 'break-word'
    },
    tableCell: {
        verticalAlign: 'baseline'
    }
}));

const Language = React.memo(({ keys, groups, rowHeight, isNewTerm }) => {
    const classes = useStyles();
    return (
        <Grid container direction="column" wrap="nowrap">
            {keys?.map(lang => {
                const terms = groups?.[lang];
                return (
                    <Grid className={classes.term} item key={lang}>
                        <Grid container direction="column" wrap="nowrap" spacing={3}>
                            {terms?.map((term, index) => {
                                if (index > 0)
                                    return (
                                        <Grid
                                            className={classes.cell}
                                            item
                                            key={term?.id}
                                            style={{ height: rowHeight[term?.id] }}
                                        >
                                            &nbsp;
                                        </Grid>
                                    );
                                const is2Digits = term?.lang?.length === 2;
                                return (
                                    <Grid
                                        item
                                        className={classes.cell}
                                        key={term?.id}
                                        style={{
                                            height: rowHeight[term?.id],
                                            ...(isNewTerm
                                                ? {
                                                      display: 'flex',
                                                      alignItems: 'center'
                                                  }
                                                : {})
                                        }}
                                    >
                                        {is2Digits ? (
                                            <TwoDigitsLang lang={lang} />
                                        ) : (
                                            <Grid item style={{ display: 'flex' }}>
                                                <CountryFlag languageCountryCode={lang} />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
            {isNewTerm && (
                <Grid className={classes.cell} item>
                    &nbsp;
                </Grid>
            )}
        </Grid>
    );
});

const AlterTime = React.memo(({ entityKey, keys, groups, rowHeight }) => {
    const classes = useStyles();
    return (
        <Grid container direction="column" wrap="nowrap">
            {keys?.map(lang => {
                const terms = groups?.[lang];
                return (
                    <Grid className={classes.term} item key={lang}>
                        <Grid container direction="column" wrap="nowrap" spacing={3}>
                            {terms?.map((term, index) => {
                                if (!term?.[entityKey])
                                    return (
                                        <Grid
                                            className={classes.cell}
                                            item
                                            key={term?.id}
                                            style={{ height: rowHeight[term?.id] }}
                                        >
                                            &nbsp;
                                        </Grid>
                                    );
                                return (
                                    <Grid
                                        item
                                        className={classes.cell}
                                        key={term?.id}
                                        style={{ height: rowHeight[term?.id] }}
                                    >
                                        <Typography noWrap variant="body2">
                                            {getFriendlyTime(term?.[entityKey])}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
});

const AlterBy = React.memo(({ entityKey, memberMapping, keys, groups, rowHeight }) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" wrap="nowrap">
            {keys?.map(lang => {
                const terms = groups?.[lang];
                return (
                    <Grid className={classes.term} item key={lang}>
                        <Grid container direction="column" wrap="nowrap" spacing={3}>
                            {terms?.map((term, index) => {
                                if (!term?.[entityKey])
                                    return (
                                        <Grid
                                            className={classes.cell}
                                            item
                                            key={term?.id}
                                            style={{ height: rowHeight[term?.id] }}
                                        >
                                            &nbsp;
                                        </Grid>
                                    );
                                const value = term?.[entityKey];
                                const name = getFullName(memberMapping?.[value]);
                                return (
                                    <Grid
                                        item
                                        className={classes.cell}
                                        key={term?.id}
                                        style={{ height: rowHeight[term?.id] }}
                                    >
                                        <Typography noWrap variant="body2">
                                            {name}
                                        </Typography>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
});

const statusOptions = [TB_TERM_STATUS.NEW, TB_TERM_STATUS.APPROVED].map(el => ({
    value: el,
    label: upperFirst(el.toLocaleLowerCase())
}));

const StatusItem = React.memo(({ term, value, height, onChangeStatus }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState();

    const isApproved = value === TB_TERM_STATUS.APPROVED;

    const onClick = useCallback(
        e => {
            setAnchorEl(anchorEl ? null : e.target);
        },
        [anchorEl]
    );

    const handleClickAway = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onChange = useCallback(
        option => {
            handleClickAway();
            if (option.value !== value) {
                onChangeStatus({
                    term,
                    langId: term.lang,
                    newStatus: option.value
                });
            }
        },
        [value, term, handleClickAway, onChangeStatus]
    );

    return (
        <Grid item className={classes.cell} key={term?.id} style={{ height: height }}>
            <Typography
                style={{
                    border: `1px solid ${hexToRgba(isApproved ? theme.colors.dodgerBlue : theme.colors.atlantis, 0.3)}`,
                    background: hexToRgba(isApproved ? theme.colors.dodgerBlue : theme.colors.atlantis, 0.1),
                    borderRadius: 4,
                    width: 92,
                    height: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}
                noWrap
                variant="body2"
                onClick={onClick}
            >
                {capitalize(value)}
            </Typography>
            {anchorEl && (
                <PopperMenu
                    anchorEl={anchorEl}
                    placement={'bottom-start'}
                    handleClickAway={handleClickAway}
                    style={{ width: 128 }}
                >
                    <Grid container direction="column">
                        {statusOptions.map((option, index) => (
                            <ListItem
                                key={index}
                                onClick={() => onChange(option)}
                                name={option.label}
                                isSelected={option.value === value}
                            />
                        ))}
                    </Grid>
                </PopperMenu>
            )}
        </Grid>
    );
});

const Status = React.memo(({ entityKey, keys, groups, rowHeight, onChangeStatus }) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" wrap="nowrap">
            {keys?.map(lang => {
                const terms = groups?.[lang];
                return (
                    <Grid className={classes.term} item key={lang}>
                        <Grid container direction="column" wrap="nowrap" spacing={3}>
                            {terms?.map((term, index) => {
                                if (!term?.[entityKey])
                                    return (
                                        <Grid
                                            className={classes.cell}
                                            item
                                            key={term?.id}
                                            style={{ height: rowHeight[term?.id] }}
                                        >
                                            &nbsp;
                                        </Grid>
                                    );

                                return (
                                    <StatusItem
                                        key={term.id}
                                        term={term}
                                        value={term[entityKey]}
                                        height={rowHeight[term.id]}
                                        onChangeStatus={onChangeStatus}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
});

const TextItem = React.memo(
    ({
        index,
        entityKey,
        langId,
        term,
        terms,
        rowHeight,
        onSetRowHeight,
        onAddTerm,
        onRemoveTerm,
        onAcceptAddTerm,
        isNewTerm,
        autoFocus = true,
        onTermItemInputChange,
        onClickTerm
    }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const theme = useTheme();
        const rootRef = useRef();

        const termText = term?.[entityKey] || '';
        const [openEdit, setOpenEdit] = useState(term.manuallyAdded ? false : term.isNew || isNewTerm);
        const [text, setText] = useState(termText);
        const [adding, setAdding] = useState(false);
        const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

        const error = useMemo(() => {
            if (terms.filter(t => t.id !== term.id).some(t => t.text === text)) {
                return 'Error! Duplicated';
            }
            return null;
        }, [terms, text, term]);

        useEffect(() => {
            setText(termText);
        }, [termText, openEdit]);

        useEffect(() => {
            if (rootRef.current) {
                console.log('term.id', term.id, rootRef.current.offsetHeight);
                onSetRowHeight(term.id, rootRef.current.offsetHeight, true);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [openEdit]);

        const stopPropagation = useCallback(e => {
            e.stopPropagation();
            e.preventDefault();
        }, []);

        const onClickAdd = useCallback(
            e => {
                stopPropagation(e);
                if (term.manuallyAdded) {
                    setOpenEdit(true);
                    return;
                }
                onAddTerm({
                    langId,
                    atIndex: index + 1
                });
            },
            [stopPropagation, term.manuallyAdded, onAddTerm, langId, index]
        );

        const onClickRemove = useCallback(
            e => {
                stopPropagation(e);
                if (term.manuallyAdded) {
                    setOpenEdit(false);
                    setOpenDeleteDialog(false);
                    return;
                }
                if (term.isNew && terms.length === 1) return;
                onRemoveTerm({
                    langId,
                    term
                });
                setOpenDeleteDialog(false);
            },
            [langId, onRemoveTerm, stopPropagation, term, terms.length]
        );

        const handleOpenDeleteDialog = useCallback(
            e => {
                stopPropagation(e);
                setOpenDeleteDialog(true);
            },
            [stopPropagation]
        );

        const closeDeleteDialog = useCallback(
            e => {
                stopPropagation(e);
                setOpenDeleteDialog(false);
            },
            [stopPropagation]
        );

        const onClickAccept = useCallback(
            e => {
                stopPropagation(e);
                if (adding || !!error) return;
                setAdding(true);
                onAcceptAddTerm({
                    term: {
                        ...term,
                        text
                    },
                    onError: () => {
                        setAdding(false);
                    }
                });
            },
            [adding, onAcceptAddTerm, stopPropagation, term, text, error]
        );

        const onKeyDown = useCallback(
            e => {
                if (isKbEnter(e) && !isNewTerm) {
                    onClickAccept(e);
                }
            },
            [isNewTerm, onClickAccept]
        );

        const onChange = useCallback(
            e => {
                setText(e.target.value);
                if (isNewTerm) {
                    onTermItemInputChange({ langId, text: e.target.value });
                }
            },
            [isNewTerm, langId, onTermItemInputChange]
        );

        const onClick = useCallback(() => {
            if (!openEdit && term.isNew) {
                setOpenEdit(true);
                return;
            }
            if (!openEdit && !isNewTerm) {
                onClickTerm({ term });
            }
        }, [isNewTerm, openEdit, term, onClickTerm]);

        const onMouseEnter = useCallback(() => {
            if (term.isNew || isNewTerm) return;
            onSetRowHeight(term.id, rootRef.current.offsetHeight, true);
        }, [isNewTerm, onSetRowHeight, term.id, term.isNew]);

        const fontJP = React.useMemo(() => {
            return LOCALIZATION_FONT?.[term?.lang];
        }, [term]);
        return (
            <Grid
                ref={rootRef}
                key={term?.id}
                item
                className={classnames(
                    classes.cell,
                    {
                        [classes.text]: !openEdit && !isNewTerm,
                        [classes.inputWrapper]: !!openEdit
                    },
                    fontJP
                )}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseEnter}
            >
                {openEdit ? (
                    <>
                        <Grid item>
                            <InputText
                                autoFocus={autoFocus}
                                value={text}
                                error={!!error}
                                errorText={error}
                                errorClassName={classes.errorClassName}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                            />
                        </Grid>
                        {!isNewTerm && (
                            <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                justifyContent="flex-end"
                                className={classes.newActions}
                            >
                                <Grid item style={{ marginRight: 8 }} onClick={onClickRemove}>
                                    <ColorCloseCircleSVG />
                                </Grid>
                                <Grid item onClick={onClickAccept}>
                                    {adding ? <CircularProgress size={20} /> : <CheckedContainerSVG />}
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Tooltip title={text} enterDelay={500}>
                            <Typography
                                variant="body2"
                                className={`${classes.textItem} ${!!term.forbidden ? classes.textError : ''}`}
                            >
                                {text}
                            </Typography>
                        </Tooltip>
                        {!isNewTerm && (
                            <div className="controls" style={{ height: 18 }}>
                                <Grid container wrap="nowrap" alignItems="center" direction="row">
                                    <Grid item style={{ marginRight: 8 }} onClick={handleOpenDeleteDialog}>
                                        <ColorCloseCircleSVG
                                            width={18}
                                            height={18}
                                            style={{ verticalAlign: 'text-top' }}
                                        />
                                    </Grid>
                                    <Grid item onClick={onClickAdd}>
                                        <ColorAddSVG
                                            color={theme.palette.primary.main}
                                            style={{ verticalAlign: 'text-top' }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </>
                )}
                <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}>
                    <ConfirmBox
                        title="Remove Term"
                        // body={t('delete_terms_content', {
                        //     count: selectedRows.length
                        // })}
                        body={`Are you sure you want to remove  this Term?`}
                        handleCancel={closeDeleteDialog}
                        onClose={closeDeleteDialog}
                        handleAgreed={onClickRemove}
                        agreeLabel={t('global_remove')}
                        colorType={COLOR_TYPES.SECONDARY}
                    />
                </Dialog>
            </Grid>
        );
    }
);

const Text = React.memo(
    ({
        entityKey,
        keys,
        groups,
        rowHeight,
        onSetRowHeight,
        onAddTerm,
        onRemoveTerm,
        onAcceptAddTerm,
        isNewTerm,
        onCancelCreateTerm,
        onCreateTerm,
        onClickTerm
    }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const [values, setValues] = useState({});
        const [adding, setAdding] = useState(false);

        const isValid = useMemo(() => {
            if (!isNewTerm) return false;
            return Object.values(values).some(el => !!el?.trim());
        }, [values, isNewTerm]);

        const onTermItemInputChange = useCallback(
            ({ langId, text }) => {
                values[langId] = text;
                setValues({ ...values });
            },
            [values]
        );

        const onAdd = useCallback(
            e => {
                e.preventDefault();
                e.stopPropagation();
                if (adding) return;
                setAdding(true);
                const newGroups = Object.keys(groups).reduce((acc, cur) => {
                    if (groups.hasOwnProperty(cur)) {
                        acc[cur] = { ...groups[cur][0], text: values[cur] || '', id: undefined };
                    }
                    return acc;
                }, {});
                onCreateTerm({
                    newGroups,
                    onError: () => {
                        setAdding(false);
                    }
                });
            },
            [adding, groups, onCreateTerm, values]
        );

        return (
            <Grid container direction="column" wrap="nowrap">
                {keys?.map(lang => {
                    const terms = groups?.[lang];
                    return (
                        <Grid className={classes.term} item key={lang}>
                            <Grid container direction="column" wrap="nowrap" spacing={3}>
                                {terms?.map((term, index) => {
                                    return (
                                        <TextItem
                                            key={term?.id}
                                            index={index}
                                            langId={lang}
                                            entityKey={entityKey}
                                            term={term}
                                            terms={terms}
                                            rowHeight={rowHeight}
                                            onSetRowHeight={onSetRowHeight}
                                            onAddTerm={onAddTerm}
                                            onRemoveTerm={onRemoveTerm}
                                            onAcceptAddTerm={onAcceptAddTerm}
                                            isNewTerm={isNewTerm}
                                            autoFocus={!isNewTerm}
                                            onTermItemInputChange={onTermItemInputChange}
                                            onClickTerm={onClickTerm}
                                        />
                                    );
                                })}
                            </Grid>
                        </Grid>
                    );
                })}
                {isNewTerm && (
                    <Grid item container wrap="nowrap" justifyContent="flex-end" className={classes.cell}>
                        <Grid item>
                            <ButtonBase onClick={onCancelCreateTerm} width={80} variant="outlined" disabled={adding}>
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.addBtn}>
                            <ButtonBase onClick={onAdd} width={80} variant="contained" disabled={adding || !isValid}>
                                Add
                            </ButtonBase>
                            {adding && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
);

const TBDetailGroupTerm = ({
    glossaryId,
    entryId,
    isNewTerm,
    columns,
    externalGroupTerms,
    isChecked,
    memberMapping,
    onCancelCreateTerm,
    onCreateTerm,
    onClickTerm
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [groupTerms, setGroupTerms] = useState(externalGroupTerms);
    const [rowHeight, setRowHeight] = useState({ id: 0 });

    useEffect(() => {
        setGroupTerms(externalGroupTerms);
    }, [externalGroupTerms]);

    const onSetRowHeight = useCallback(
        (id, height, isReplaced) => {
            if (isReplaced || ((!rowHeight[id] || rowHeight[id] < height) && height >= minCellHeight)) {
                rowHeight[id] = height;
                setRowHeight({ ...rowHeight });
            }
        },
        [rowHeight]
    );

    const onAddTerm = useCallback(
        ({ langId, atIndex }) => {
            const id = `${langId}-${new Date().getTime()}`;
            const temp = JSON.parse(JSON.stringify(groupTerms));
            temp[langId].splice(atIndex, 0, { id, lang: langId, isNew: true });
            temp[langId] = temp[langId].filter(el => !el.isNew || el.id === id);
            setGroupTerms(temp);
        },
        [groupTerms]
    );

    const onRemoveTerm = useCallback(
        ({ langId, term }) => {
            const oldGroupTerms = JSON.parse(JSON.stringify(groupTerms));
            const temp = JSON.parse(JSON.stringify(groupTerms));
            if (term.isNew || temp[langId]?.filter(el => !el.isNew).length > 1) {
                const idx = temp[langId].findIndex(el => el.id === term.id);
                if (idx > -1) {
                    temp[langId].splice(idx, 1);
                }
            } else {
                temp[langId][0] = {
                    ...term,
                    text: ''
                };
            }
            setGroupTerms(temp);
            if (!term.isNew) {
                const body = {
                    entryId,
                    terms: Object.values(temp)
                        .reduce((acc, cur) => {
                            acc = acc.concat(cur);
                            return acc;
                        }, [])
                        .filter(el => !el.isNew)
                };
                dispatch(
                    updateTerm({
                        tbId: glossaryId,
                        body,
                        errorCallback: () => {
                            setGroupTerms(oldGroupTerms);
                        }
                    })
                );
            }
        },
        [dispatch, entryId, glossaryId, groupTerms]
    );

    const onAcceptAddTerm = useCallback(
        ({ term, onError }) => {
            const temp = Object.values(groupTerms);
            const rest = [];
            temp.forEach(tempLang => {
                if (tempLang.length === 1) {
                    const first = tempLang[0];
                    if (first.isNew && first.manuallyAdded && first.id !== term.id) {
                        rest.push(first);
                    }
                }
            });
            const body = {
                entryId,
                terms: temp
                    .reduce((acc, cur) => {
                        acc = acc.concat(cur);
                        return acc;
                    }, [])
                    .filter(el => !el.isNew || el.id === term.id)
                    .map(el => {
                        if (el.isNew && el.id === term.id) {
                            return {
                                text: term.text,
                                lang: term.lang
                            };
                        }
                        return el;
                    })
            };
            dispatch(
                updateTerm({
                    tbId: glossaryId,
                    body,
                    errorCallback: onError,
                    successCallback: responseData => {
                        if (responseData?.terms) {
                            const groupTerms = groupBy([...responseData?.terms, ...rest], 'lang');
                            setGroupTerms(groupTerms);
                        }
                    }
                })
            );
        },
        [dispatch, entryId, glossaryId, groupTerms]
    );

    const onChangeStatus = useCallback(
        ({ langId, term, newStatus }) => {
            const oldGroupTerms = JSON.parse(JSON.stringify(groupTerms));
            const temp = JSON.parse(JSON.stringify(groupTerms));
            const idx = temp[langId].findIndex(el => el.id === term.id);
            if (idx > -1) {
                temp[langId][idx].status = newStatus;
            }
            setGroupTerms(temp);
            dispatch(
                updateTermDetail({
                    tbId: glossaryId,
                    termId: term.id,
                    body: {
                        ...term,
                        status: newStatus
                    },
                    errorCallback: () => {
                        setGroupTerms(oldGroupTerms);
                    }
                })
            );
        },
        [dispatch, glossaryId, groupTerms]
    );

    const langRenderer = useCallback(
        ({ key, groups }) => {
            const keys = Object.keys(groups);
            if (!isEmpty(keys)) {
                switch (key) {
                    case 'language':
                        return <Language keys={keys} groups={groups} rowHeight={rowHeight} isNewTerm={isNewTerm} />;
                    case 'createdTime':
                    case 'alteredTime':
                        return <AlterTime keys={keys} groups={groups} entityKey={key} rowHeight={rowHeight} />;

                    case 'createdBy':
                    case 'alteredBy':
                        return (
                            <AlterBy
                                keys={keys}
                                groups={groups}
                                entityKey={key}
                                memberMapping={memberMapping}
                                rowHeight={rowHeight}
                            />
                        );

                    case 'status':
                        return (
                            <Status
                                keys={keys}
                                groups={groups}
                                entityKey={key}
                                rowHeight={rowHeight}
                                onChangeStatus={onChangeStatus}
                            />
                        );
                    case 'text':
                        return (
                            <Text
                                keys={keys}
                                groups={groups}
                                entityKey={key}
                                rowHeight={rowHeight}
                                onSetRowHeight={onSetRowHeight}
                                onAddTerm={onAddTerm}
                                onRemoveTerm={onRemoveTerm}
                                onAcceptAddTerm={onAcceptAddTerm}
                                isNewTerm={isNewTerm}
                                onCancelCreateTerm={onCancelCreateTerm}
                                onCreateTerm={onCreateTerm}
                                onClickTerm={onClickTerm}
                            />
                        );
                    default:
                        return (
                            <Grid container direction="column" wrap="nowrap">
                                {keys?.map(lang => {
                                    const terms = groups?.[lang];
                                    return (
                                        <Grid className={classes.term} item key={lang}>
                                            <Grid container direction="column" wrap="nowrap" spacing={3}>
                                                {terms?.map((term, idx) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            className={classes.cell}
                                                            key={term?.id}
                                                            style={{
                                                                height: rowHeight[idx]
                                                            }}
                                                        >
                                                            <Typography noWrap variant="body2">
                                                                {term?.[key]}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        );
                }
            }
            return null;
        },
        [
            classes.cell,
            classes.term,
            memberMapping,
            onSetRowHeight,
            rowHeight,
            onAddTerm,
            onRemoveTerm,
            onAcceptAddTerm,
            isNewTerm,
            onCancelCreateTerm,
            onCreateTerm,
            onClickTerm,
            onChangeStatus
        ]
    );

    return columns.map(column => {
        return (
            <TableCell
                className={classes.tableCell}
                key={column.id}
                align={column.align}
                style={{
                    minWidth: column.minWidth,
                    background: isChecked ? `#E0EFFD` : theme.palette.common.white
                }}
            >
                {langRenderer({
                    key: column?.id,
                    groups: groupTerms
                })}
            </TableCell>
        );
    });
};

export default React.memo(TBDetailGroupTerm);
