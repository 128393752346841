import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { generateScratchCodesApi, getUserFactorApi } from 'services/company';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import { Grid, Typography, Link, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notifier/actions';
import { createAndDownloadFile } from 'utils/fileUtils';

const useStyles = makeStyles(theme => ({
    codeWrapper: {
        background: theme.colors.paleGrey,
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        padding: '4px 8px'
    },
    code: {
        fontFamily: 'courier !important'
    }
}));

function ProfileBackupCode({ onClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [factorStatus, setUserFactor] = React.useState({});

    React.useEffect(() => {
        const fetchFactorStatus = async () => {
            try {
                const factorStatus = await getUserFactorApi();
                setUserFactor(factorStatus);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchFactorStatus();
    }, []);

    const generateNewCode = async () => {
        try {
            const newFactorStatus = await generateScratchCodesApi();
            setUserFactor(newFactorStatus);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleCopyToClipboard = () => {
        const stringToCopy = factorStatus?.scratchCodes?.join('\n');
        navigator.clipboard.writeText(stringToCopy);
        dispatch(
            enqueueSnackbar({
                message: t(`global_copied`),
                type: 'info'
            })
        );
    };

    const handleDownload = () => {
        const fileContent = factorStatus.scratchCodes.join('\n');
        createAndDownloadFile(fileContent, 'gridly-backup-codes.txt', 'text/plain');
    };

    return (
        <>
            <DialogTitle title={t('backup_verification_codes')} onClose={onClose} />
            <DialogContent>
                <Grid item container direction="column">
                    <Grid item>
                        <Typography variant="body2" className={classes.title}>
                            {t('with_2fa_enabled_for_your_account')}
                        </Typography>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 14 }}>
                        {(factorStatus.scratchCodes || []).map(code => (
                            <Grid key={code} item xs={4}>
                                <div className={classes.codeWrapper}>
                                    <code className={classes.code}>{code}</code>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider style={{ marginTop: 14 }} />
                    <Grid item container style={{ marginTop: 14 }} spacing={3}>
                        <Grid item>
                            <Link style={{ cursor: 'pointer' }} onClick={generateNewCode}>
                                {t('generate_new_codes')}
                            </Link>
                        </Grid>
                        <Grid item style={{ flex: 1 }} />
                        <Grid item>
                            <Link style={{ cursor: 'pointer' }} onClick={handleCopyToClipboard}>
                                {t('global_copy')}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link style={{ cursor: 'pointer' }} id="hahaha" onClick={handleDownload}>
                                {t('global_download')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 14 }} />
                </Grid>
            </DialogContent>
        </>
    );
}

export default ProfileBackupCode;
