import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FilterList from './FilterList';
import AddFilterButton from './AddFilterButton';
import Divider from 'components/divider/Base';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import { useMetaData, useViewFilters } from 'hooks/advanced';

const useStyles = makeStyles(theme => ({
    root: {
        width: 720
    },
    buttonAdd: {
        padding: `0px 20px`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        width: 'fit-content'
    },
    filterList: {
        padding: `0px 20px`,
        maxHeight: 480,
        overflowY: 'auto'
    },
    whiteText: {
        color: theme.colors.white
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    },
    mt16: {
        marginTop: 16
    }
}));

function FilterMenu({
    addOrFilter,
    addAndFilter,
    dependencies,
    columns,
    onDeleteFilter,
    onUpdateFilter,
    onCreated,
    isReadOnly
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const metaData = useMetaData();
    const viewFilters = useViewFilters();

    return (
        <Grid className={classes.root} wrap="nowrap" container direction="column">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    className={classes.filterList}
                    id={'filterListBoundary'}
                    wrap="nowrap"
                >
                    <FilterList
                        t={t}
                        onCreated={onCreated}
                        viewFilters={viewFilters}
                        addAndFilter={addAndFilter}
                        addOrFilter={addOrFilter}
                        metaData={metaData}
                        dependencies={dependencies}
                        columns={columns}
                        onDeleteFilter={onDeleteFilter}
                        onUpdateFilter={onUpdateFilter}
                        isReadOnly={isReadOnly}
                    />
                    <div id="filterListBottom"></div>
                </Grid>
            </Grid>
            {!viewFilters.length && (
                <>
                    <Grid className={classes.mt16}>
                        <Divider />
                    </Grid>
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ''
                            )
                        }
                    >
                        <Grid item className={classes.buttonAdd}>
                            <AddFilterButton t={t} addAndFilter={addAndFilter} isReadOnly={isReadOnly} />
                        </Grid>
                    </Tooltip>
                </>
            )}
        </Grid>
    );
}

export default React.memo(FilterMenu);
