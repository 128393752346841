import { useSelector } from 'react-redux';
import { useData } from './data';

export function useRecordMetaData() {
    return useSelector(state => state.gridUI.recordMetaData) || {};
}

export function useIsExistRecordMetaDataRecordId({ recordId }) {
    const data = useData();
    return Boolean(data?.[recordId]);
}

export function useCellMetaData({ recordId, columnId }) {
    const recordMetaData = useRecordMetaData();
    return recordMetaData?.[recordId]?._metadata?.value?.[columnId];
}
