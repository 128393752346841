import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import MemSourceSVG from 'assets/images/svg/addon/MemSourceSVG';
import MemoQSVG from 'assets/images/svg/addon/MemoQSVG';
import AvatarBase from 'components/avatar/Base';
import { getFriendlyTime } from 'utils/datetime';
import Diff from './diff';
import { GRID_HISTORY_ACTIONS, GRID_HISTORY_OBJECT_TYPES } from 'const/gridUI';
import { parseJson } from 'utils/object';
import Divider from 'components/divider/Base';
import CardInfo from 'components/cardInfo/Base';
import { getAvatarUrl } from 'utils/images';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { INTEGRATION_PROVIDER } from 'const/inAppAddon';
import AutomationLogoSVG from 'assets/images/svg/webHook/AutomationLogoSVG';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 8,
        marginBottom: 8,
        padding: '10px 24px',
        // borderBottom: `1px solid ${theme.colors.border}`,
        '&:hover': {
            backgroundColor: theme.colors.hoverItem
        }
    },
    diff: {
        marginLeft: 50
    }
}));

function getTypeNameByObjectType(objectType) {
    switch (objectType) {
        case GRID_HISTORY_OBJECT_TYPES.COLUMN:
            return 'column';
        case GRID_HISTORY_OBJECT_TYPES.RECORD:
            return 'record';
        case GRID_HISTORY_OBJECT_TYPES.GRID:
            return 'grid';
        case GRID_HISTORY_OBJECT_TYPES.TAG:
            return 'path';
        case GRID_HISTORY_OBJECT_TYPES.DEPENDENCY:
            return 'dependency';
        default:
            return '';
    }
}

function generateMessage({ action, objectType, length, object, requestParams }) {
    const type = getTypeNameByObjectType(objectType);
    const totalRecords = object?.totalRecords || 0;
    switch (action) {
        case GRID_HISTORY_ACTIONS.CREATE:
            return length > 1 ? `added ${type}s` : `added a new ${type}`;
        case GRID_HISTORY_ACTIONS.UPDATE:
            return length > 1 ? `updated ${type}s` : `updated a ${type}`;
        case GRID_HISTORY_ACTIONS.DELETE:
            length = length || totalRecords;
            return length > 1 ? `deleted ${type}s` : `deleted a ${type}`;
        case GRID_HISTORY_ACTIONS.BULK_DELETE_RECORDS:
            return `deleted ${totalRecords} records`;
        case GRID_HISTORY_ACTIONS.MERGE:
            return `merged a ${type}`;
        case GRID_HISTORY_ACTIONS.IMPORT:
            return `imported a ${type}`;
        case GRID_HISTORY_ACTIONS.RESTORE:
            return `restored a ${type}`;
        case GRID_HISTORY_ACTIONS.UPLOAD_FILES:
            return `uploaded a folder`;
        case GRID_HISTORY_ACTIONS.CLEAR_RECORDS:
            return `clear records`;
        case GRID_HISTORY_ACTIONS.CLEAN_COLUMN:
            return object?.columnIds?.length > 1 ? `cleaned columns` : `cleaned column`;
        case GRID_HISTORY_ACTIONS.AUTOFILL: {
            const columnsCount = object?.fromColumnIds?.length;
            return columnsCount === 1
                ? `auto-filled records on a column`
                : `auto-filled records on ${columnsCount} columns`;
        }
        case GRID_HISTORY_ACTIONS.PASTE_RECORDS:
            return totalRecords > 0 ? (totalRecords === 1 ? 'pasted a record' : 'pasted records') : '';
        case GRID_HISTORY_ACTIONS.APPROVE_TM:
            return totalRecords > 0 ? (totalRecords === 1 ? 'approved tm to a record' : 'aprroved tm to records') : '';
        case GRID_HISTORY_ACTIONS.MARK_TARGET_STATUS:
            return 'marked target status';
        case GRID_HISTORY_ACTIONS.MARK_SOURCE_STATUS:
            return 'marked source status';
        default:
            return '';
    }
}

function LogItem({
    userId,
    time,
    userFullname,
    action,
    objectType,
    extraJson,
    onActionClick,
    t,
    requestParams,
    isShareViewLink,
    source
}) {
    const classes = useStyles();
    let object = parseJson(extraJson);

    const isMemSource = requestParams?.provider === INTEGRATION_PROVIDER.MEM_SOURCE;
    const isMemoQ = requestParams?.provider === INTEGRATION_PROVIDER.MEMO_Q;
    const message = generateMessage({ action, objectType, length: object?.recordIds?.length, object, requestParams });
    const isAutomation = source === 'AUTOMATION';

    const getMessage = React.useCallback(() => {
        if (isMemSource) return `synced a completed job`;
        if (isMemoQ) return `delivered a document`;
        return message;
    }, [isMemSource, message, isMemoQ]);

    const getUserName = React.useCallback(() => {
        if (isMemSource) return 'Phrase TMS';
        if (isMemoQ) return 'memoQ';

        return userFullname;
    }, [isMemSource, userFullname, isMemoQ]);

    return (
        <>
            <Grid item className={classes.root}>
                <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <CardInfo
                                    avatar={
                                        isAutomation ? (
                                            <AutomationLogoSVG />
                                        ) : isMemoQ ? (
                                            <MemoQSVG width={32} height={32} />
                                        ) : (
                                            <AvatarBase size={'small'} src={getAvatarUrl(userId?.toString())}>
                                                {isMemSource ? (
                                                    <MemSourceSVG style={{ width: '100%', height: '100%' }} />
                                                ) : (
                                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                                )}
                                            </AvatarBase>
                                        )
                                    }
                                    title={
                                        <>
                                            <p className="body1 inline">
                                                {isAutomation ? t('global_automation') : getUserName()}
                                            </p>{' '}
                                            <p className="body2 inline !font-normal">{getMessage()}</p>
                                        </>
                                    }
                                    subTitle={getFriendlyTime(time)}
                                />
                            </Grid>
                            <Grid item className={classes.diff}>
                                <Diff t={t} objectType={objectType} action={action} extraJson={extraJson} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isShareViewLink && (
                        <AccessControl view={roleConst.SUPPORT_RESTORE_GRID}>
                            <Grid item>
                                <IconButton onClick={e => onActionClick(e.target, time)}>
                                    <IconMoreActionsSVG />
                                </IconButton>
                            </Grid>
                        </AccessControl>
                    )}
                </Grid>
            </Grid>
            <Divider style={{ marginLeft: 24, marginRight: 24 }} />
        </>
    );
}

export default React.memo(LogItem);
