import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import SortOrderItem from './SortOrderItem';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3)
    },
    filterItem: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    }
}));

function SortOrderList({ deleteViewSort, sorts, updateViewSortState, updateViewSort, metaData, columns, isReadOnly }) {
    const classes = useStyles();
    const { t } = useTranslation();

    if (isEmpty(sorts))
        return (
            <Grid container className={classes.root}>
                <Typography variant="caption">{t(`sort_add_more_button`)}</Typography>
            </Grid>
        );
    return (
        <Grid container direction="column" className={classes.root}>
            {sorts?.map(sortOrder => {
                return (
                    <Grid item className={classes.filterItem} key={sortOrder.id}>
                        <SortOrderItem
                            id={sortOrder.id}
                            direction={sortOrder.direction}
                            sortOrder={sortOrder}
                            deleteViewSort={deleteViewSort}
                            updateViewSortState={updateViewSortState}
                            updateViewSort={updateViewSort}
                            metaData={metaData}
                            columns={columns}
                            isReadOnly={isReadOnly}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(SortOrderList);
