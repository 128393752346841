import * as types from '../types';

const handler = {
    [types.UPDATE_DASHBOARD_COMMENT_THREAD_STATUS](state, { payload }) {
        const { threadId, newStatus } = payload;
        const compositeViewId = state?.currentView?.compositeViewId;

        const currentThreadList = state?.commentDashboard?.[compositeViewId]?.list;
        const newThreadList = currentThreadList?.map(thread => {
            if (thread?.thread?.id === threadId) {
                thread.thread.status = newStatus;
            }
            return thread;
        });

        return {
            ...state,
            commentDashboard: {
                ...state.commentDashboard,
                [compositeViewId]: {
                    ...state?.commentDashboard?.[compositeViewId],
                    list: newThreadList
                }
            }
        };
    }
};

export default handler;
