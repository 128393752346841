import { enqueueSnackbar } from 'notifier/actions';
import {
    SET_AUTOMATIONS,
    SET_AUTOMATION_DETAIL,
    SET_IS_FETCHING_AUTOMATIONS,
    SET_OPEN_AUTOMATION_DETAIL,
    SET_AUTOMATION_DETAIL_ERROR,
    SET_AUTOMATION_LIST_EXECUTIONS,
    SET_AUTOMATION_LAMBDAS,
    SET_OPEN_MULTIPLE_AUTOMATION_DIALOG
} from 'gridUI/types';
import {
    getListAutomationsApi,
    createAutomationApi,
    getListTriggersOfAutomationApi,
    getListNodesOfAutomationApi,
    createAutomationTriggerApi,
    createAutomationNodeApi,
    updateTriggerNodeApi,
    updateNodeApi,
    deleteTriggerNodeApi,
    deleteNodeApi,
    updateAutomationApi,
    cloneAutomationApi,
    deleteAutomationApi,
    executeAutomationApi,
    testAutomationApi,
    invokeAutomationApi,
    getAutomationHistoryApi,
    moveAutomationNodeApi,
    removeAutomationLogoApi,
    getLambdasApi,
    uploadAutomationLogoApi
} from 'services/automation';
import i18n from 'i18n';
import { AUTOMATION_APP_ACTION_DEFAULT } from './const';
import { generateAutomationActionNodeDefaultParams } from 'const/automation';
import { OPERATOR } from 'gridUI/conditions';
import uuidv1 from 'uuid/v1';
import { DATA_QUERY_OPTIONS, MAX_SELECTION_RECORDS, RANGE_TYPES } from 'const/gridUI';
import * as dataActions from 'gridUI/actions/data';
import * as statusActions from 'gridUI/actions/status';

export const setAutomations = payload => {
    return {
        type: SET_AUTOMATIONS,
        payload
    };
};

export const setIsFetchingAutomations = payload => {
    return {
        type: SET_IS_FETCHING_AUTOMATIONS,
        payload
    };
};

export const setOpenAutomationDetail = payload => {
    return {
        type: SET_OPEN_AUTOMATION_DETAIL,
        payload
    };
};

export const setAutomationDetailError = payload => {
    return {
        type: SET_AUTOMATION_DETAIL_ERROR,
        payload
    };
};

let timeout = undefined;
let savedAutomationId = undefined;

export const setAutomationListExecutions = (payload, isClearTimeout) => {
    if (isClearTimeout) {
        clearTimeout(timeout);
    }
    return {
        type: SET_AUTOMATION_LIST_EXECUTIONS,
        payload
    };
};

export const handleAutomationListExecutions = payload => {
    return (dispatch, getState) => {
        const { gridUI } = getState();
        let { listAutomationExecutions } = gridUI;
        if (!listAutomationExecutions) {
            listAutomationExecutions = [];
        }
        const idx = listAutomationExecutions.findIndex(item => item.automationId === payload.automationId);
        if (idx < 0) {
            listAutomationExecutions.push(payload);
            clearTimeout(timeout);
            dispatch(setAutomationListExecutions([...listAutomationExecutions]));
        } else {
            if (payload.executionStatus === listAutomationExecutions[idx].executionStatus) {
                return;
            }
            listAutomationExecutions[idx] = payload;
            if (payload.automationId !== savedAutomationId || payload.executionStatus === 'STARTED') {
                savedAutomationId = payload.automationId;
                clearTimeout(timeout);
                dispatch(setAutomationListExecutions([...listAutomationExecutions]));
                return;
            }
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                savedAutomationId = undefined;
                dispatch(setAutomationListExecutions([...listAutomationExecutions]));
            }, 1000);
        }
    };
};

export const updateAutomationDetailErrorWithKey = ({ key, newValue = {}, isReplaced }) => {
    return (dispatch, getState) => {
        const { gridUI } = getState();
        const { automationDetailError } = gridUI;
        let isDiff = false;
        if (isReplaced) {
            isDiff = true;
            automationDetailError[key] = newValue;
        } else {
            Object.keys(newValue).every(el => {
                if (automationDetailError?.[key]?.[el] !== newValue[el]) {
                    isDiff = true;
                    return false;
                }
                return true;
            });
            automationDetailError[key] = {
                ...automationDetailError[key],
                ...newValue
            };
        }
        if (isDiff) {
            dispatch(setAutomationDetailError(JSON.parse(JSON.stringify(automationDetailError))));
        }
    };
};

export const getListAutomations = ({ body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await getListAutomationsApi(body);
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const setAutomationDetail = payload => {
    return {
        type: SET_AUTOMATION_DETAIL,
        payload
    };
};

export const createAutomation = ({ body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await createAutomationApi(body);
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const createAutomationWithEvents = ({ body, events, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await createAutomationApi(body);
            if (events) {
                const trigger = events[0];
                const node = events[1];
                const triggerData = await createAutomationTriggerApi({
                    automationId: data.id,
                    body: { trigger }
                });
                const defaultActionParams = generateAutomationActionNodeDefaultParams({
                    app: node,
                    trigger: triggerData
                });
                await createAutomationNodeApi({
                    automationId: data.id,
                    body: {
                        app: node,
                        action: AUTOMATION_APP_ACTION_DEFAULT[node],
                        params: defaultActionParams
                    }
                });
            }
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const cloneAutomation = ({ id, body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await cloneAutomationApi(id, body);
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const updateAutomation = ({ id, body, file, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            let data = null;
            if (!file) {
                data = await updateAutomationApi(id, body);
            } else {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('automationId', id);
                const responses = await Promise.all([
                    updateAutomationApi(id, body),
                    uploadAutomationLogoApi({ formData })
                ]);
                data = responses[1];
            }
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const deleteAutomation = ({ id, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            await deleteAutomationApi(id);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const getTriggersAndActionsNode = ({ automationId, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        try {
            const responses = await Promise.all([
                getListTriggersOfAutomationApi({ automationId }),
                getListNodesOfAutomationApi({ automationId })
            ]);
            const response = {
                triggerNodes: responses[0] || [],
                nodes: responses[1] || []
            };
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            dispatch(
                setAutomationDetail({
                    ...automationDetail,
                    ...response
                })
            );
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const createAutomationTrigger = ({ automationId, body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await createAutomationTriggerApi({ automationId, body });
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const createAutomationNode = ({ automationId, body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await createAutomationNodeApi({ automationId, body });
            successCallback && successCallback(data);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const updateTriggerNode = ({ triggerId, body, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        try {
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            const automationId = automationDetail.id;
            const data = await updateTriggerNodeApi({ automationId, triggerId, body });
            successCallback && successCallback(data);
            if (automationDetail?.triggerNodes?.length) {
                const idx = automationDetail.triggerNodes.findIndex(n => n.id === triggerId);
                if (idx > -1) {
                    automationDetail.triggerNodes[idx] = { ...automationDetail.triggerNodes[idx], ...data };
                    dispatch(setAutomationDetail(automationDetail));
                }
            }
            dispatch(getTriggersAndActionsNode({ automationId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const updateActionNode = ({ automationId, nodeId, body, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        try {
            const data = await updateNodeApi({ automationId, nodeId, body });
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            let findNode = null;
            if (automationDetail?.nodes?.length) {
                const idx = automationDetail.nodes.findIndex(n => n.id === nodeId);
                if (idx > -1) {
                    automationDetail.nodes[idx] = { ...automationDetail.nodes[idx], ...data };
                    dispatch(setAutomationDetail(automationDetail));
                    findNode = automationDetail.nodes[idx];
                }
            }
            successCallback &&
                successCallback({
                    ...data,
                    order: findNode?.order
                });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const deleteTriggerNode = ({
    triggerId,
    isSelectedNodeTrigger,
    selectedNodeId,
    successSelectedNodeCallback,
    successCallback,
    errorCallback
}) => {
    return async (dispatch, getState) => {
        try {
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            await deleteTriggerNodeApi({ automationId: automationDetail.id, triggerId });
            successCallback && successCallback();
            const newAutomationDetail = JSON.parse(JSON.stringify(automationDetail));
            const idx = newAutomationDetail.triggerNodes.findIndex(node => node.id === triggerId);
            if (idx > -1) {
                newAutomationDetail.triggerNodes.splice(idx, 1);
                dispatch(setAutomationDetail(newAutomationDetail));
            }
            dispatch(
                getTriggersAndActionsNode({
                    automationId: automationDetail.id,
                    successCallback: () => {
                        if (selectedNodeId && successSelectedNodeCallback) {
                            const { gridUI } = getState();
                            const { automationDetail } = gridUI;
                            const arr = isSelectedNodeTrigger
                                ? automationDetail?.triggerNodes
                                : automationDetail?.nodes;
                            const newNode = arr.find(n => n.id === selectedNodeId);
                            successSelectedNodeCallback(newNode);
                        }
                    }
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const deleteActionNode = ({
    nodeId,
    isSelectedNodeTrigger,
    selectedNodeId,
    successSelectedNodeCallback,
    successCallback,
    errorCallback
}) => {
    return async (dispatch, getState) => {
        try {
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            await deleteNodeApi({ automationId: automationDetail.id, nodeId });
            successCallback && successCallback();
            const newAutomationDetail = JSON.parse(JSON.stringify(automationDetail));
            const idx = newAutomationDetail.nodes.findIndex(node => node.id === nodeId);
            newAutomationDetail.nodes.splice(idx, 1);
            dispatch(setAutomationDetail(newAutomationDetail));
            dispatch(
                getTriggersAndActionsNode({
                    automationId: automationDetail.id,
                    successCallback: () => {
                        if (selectedNodeId && successSelectedNodeCallback) {
                            const { gridUI } = getState();
                            const { automationDetail } = gridUI;
                            const arr = isSelectedNodeTrigger
                                ? automationDetail?.triggerNodes
                                : automationDetail?.nodes;
                            const newNode = arr.find(n => n.id === selectedNodeId);
                            successSelectedNodeCallback(newNode);
                        }
                    }
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const executeAutomation = ({ automationId, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const response = await executeAutomationApi({ automationId });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const testAutomation = ({ automationId, body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const response = await testAutomationApi({ automationId, body });
            const { message, success } = response;
            if (!success) {
                errorCallback && errorCallback(message);
            } else {
                successCallback && successCallback(response);
                dispatch(
                    enqueueSnackbar({
                        message: i18n.t('automation_test_success'),
                        type: 'info'
                    })
                );
            }
        } catch (error) {
            const { originalMessage, message } = error;
            errorCallback && errorCallback(originalMessage || message);
        }
    };
};

export const invokeAutomation = ({ automationId, body, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const response = await invokeAutomationApi({ automationId, body });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback(message);
        }
    };
};

export const invokeAutomationRange = ({ automationId, body, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        const actionId = uuidv1();

        try {
            dispatch(statusActions.registerDoingAction({ actionId }));

            const { gridUI, auth } = getState();

            const {
                columnIds: rangeColumnIds,
                recordIds,
                // data,
                isOverRecordLimit,
                totalSelectedRecords
            } = await dataActions.getRangeData({
                auth,
                gridUI,
                dataOptions: [DATA_QUERY_OPTIONS.COLOR],
                type: RANGE_TYPES.INDEX,
                allowSelectedColumns: true
            });

            if (isOverRecordLimit) {
                dispatch(
                    enqueueSnackbar({
                        type: 'info',
                        message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                    })
                );
                dispatch(statusActions.removeDoingAction({ actionId }));
                return;
            }

            if (!recordIds?.length || !rangeColumnIds?.length) {
                dispatch(statusActions.removeDoingAction({ actionId }));
                return;
            }

            let body = {
                recordIds
            };

            const response = await invokeAutomationApi({ automationId, body });
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(enqueueSnackbar({ message: 'Invoke sucess' }));
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback(message);
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
};

export const getAutomationHistory = ({
    automationId,
    page = 0,
    offset = 0,
    size = 10,
    successCallback,
    errorCallback
}) => {
    return async (dispatch, getState) => {
        try {
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            if (offset === 0 && automationDetail?.history?.length > 0) {
                dispatch(
                    setAutomationDetail({
                        ...automationDetail,
                        history: []
                    })
                );
            }
            const response = await getAutomationHistoryApi({ automationId, page, offset, size });
            successCallback && successCallback(response);
            if (response?.length > 0) {
                if (offset === 0) {
                    dispatch(
                        setAutomationDetail({
                            ...automationDetail,
                            history: response
                        })
                    );
                } else {
                    dispatch(
                        setAutomationDetail({
                            ...automationDetail,
                            history: [...automationDetail.history, ...response]
                        })
                    );
                }
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const moveAutomationNode = ({ automationId, nodeId, selectedNodeId, body, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        try {
            const response = await moveAutomationNodeApi({ automationId, nodeId, body });
            const { gridUI } = getState();
            const { automationDetail } = gridUI;
            let findNode = null;
            if (selectedNodeId) {
                if (automationDetail?.nodes?.length) {
                    findNode = automationDetail.nodes.find(n => n.id === selectedNodeId);
                }
                successCallback &&
                    successCallback({
                        ...response,
                        ...findNode
                    });
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const removeAutomationLogo = ({ automationId, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const response = await removeAutomationLogoApi({ automationId });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
};

export const generateAutomationConditionFilterField = () => {
    return {
        id: new Date().getTime(),
        field: '',
        operator: OPERATOR.equal,
        values: []
    };
};

export const setAutomationLambdas = payload => {
    return {
        type: SET_AUTOMATION_LAMBDAS,
        payload
    };
};

export const fetchAllLambdas = () => {
    return async dispatch => {
        try {
            const lambdas = await getLambdasApi();
            dispatch(setAutomationLambdas(lambdas || []));
        } catch (error) {
            dispatch(setAutomationLambdas([]));
            console.log(error.message);
        }
    };
};

export const setOpenMultipleAutomationDialog = payload => {
    return {
        type: SET_OPEN_MULTIPLE_AUTOMATION_DIALOG,
        payload
    };
};
