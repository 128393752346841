import React from 'react';

function IconGridlySVG({ ...other }) {
    return (
        <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1874 3.51555L12.5242 0.632812L10.7871 1.7019V1.70394C10.3249 1.97255 10.0138 2.47303 10.0138 3.04646C10.0138 3.63691 10.3433 4.15022 10.8284 4.41253L11.5978 4.88293L15.4701 7.30804L17.1646 6.25746C17.661 5.99867 18.0001 5.47946 18.0001 4.88066C18.0001 4.29094 17.6714 3.77814 17.1874 3.51555Z"
                fill="#FBAF17"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4699 7.31183L11.5975 4.88672L9.01428 6.47457V10.6195C9.01593 10.6195 9.01746 10.6195 9.01905 10.6195C9.80957 10.6195 10.545 10.3851 11.1613 9.98318"
                fill="#E53182"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00737 6.47881L7.05871 5.27678L2.47979 2.47266C1.89613 2.83804 1.30663 3.19565 0.728526 3.56995C0.28145 3.85939 0 4.3471 0 4.88432C0 5.47693 0.332045 5.99177 0.820017 6.25311C0.820017 6.25311 6.87324 9.98118 6.87352 9.98089C7.48934 10.3833 8.22428 10.6184 9.0144 10.6193V6.47443"
                fill="#9643DC"
            />
            <path d="M0.0187988 10.5977H0.0566059V10.6355H0.0187988V10.5977Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.47974 2.47001L7.05889 5.27424L6.39292 4.86345L8.28406 3.72744V3.72625C8.71996 3.45157 9.00981 2.96601 9.00981 2.41245C9.00981 1.85884 8.71996 1.37334 8.28406 1.09866L6.49497 0L2.49613 2.45974L2.47974 2.47001Z"
                fill="#17C0FF"
            />
        </svg>
    );
}

export default React.memo(IconGridlySVG);
