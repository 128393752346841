import React from 'react';

function AudioPrevSVG({ color = '#D1D0D6', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M2.66667 1C3.03467 1 3.33333 1.29867 3.33333 1.66667V6.52667L13.0067 1.08533C13.2107 0.970667 13.4653 0.972 13.6693 1.09067C13.8733 1.20933 14 1.42933 14 1.66667V13.6667C14 13.904 13.8733 14.1227 13.6693 14.2427C13.5667 14.3027 13.4493 14.3333 13.3333 14.3333C13.22 14.3333 13.108 14.3053 13.0067 14.248L3.33333 8.80667V13.6667C3.33333 14.0347 3.03467 14.3333 2.66667 14.3333C2.29867 14.3333 2 14.0347 2 13.6667V1.66667C2 1.29867 2.29867 1 2.66667 1Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(AudioPrevSVG);
