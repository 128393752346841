import React from 'react';

function HtmlSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5938 0H1.40625C0.630844 0 0 0.630844 0 1.40625V14.5938C0 15.3692 0.630844 16 1.40625 16H14.5938C15.3692 16 16 15.3692 16 14.5938V1.40625C16 0.630844 15.3692 0 14.5938 0ZM15.0625 14.5938C15.0625 14.8522 14.8522 15.0625 14.5938 15.0625H1.40625C1.14778 15.0625 0.9375 14.8522 0.9375 14.5938V4.6875H15.0625V14.5938ZM15.0625 3.75H0.9375V1.40625C0.9375 1.14778 1.14778 0.9375 1.40625 0.9375H14.5938C14.8522 0.9375 15.0625 1.14778 15.0625 1.40625V3.75Z"
                fill="#78778B"
            />
            <path
                d="M3.97543 9.87502L5.51896 8.33148C5.70202 8.14842 5.70202 7.85161 5.51896 7.66855C5.33593 7.48548 5.03912 7.48548 4.85605 7.66855L2.98105 9.54355C2.79798 9.72661 2.79798 10.0234 2.98105 10.2065L4.85605 12.0815C5.03908 12.2645 5.3359 12.2645 5.51896 12.0815C5.70202 11.8984 5.70202 11.6016 5.51896 11.4185L3.97543 9.87502Z"
                fill="#78778B"
            />
            <path
                d="M13.019 9.54355L11.144 7.66855C10.9609 7.48548 10.6641 7.48548 10.481 7.66855C10.298 7.85161 10.298 8.14839 10.481 8.33145L12.0246 9.87502L10.4811 11.4185C10.298 11.6016 10.298 11.8984 10.4811 12.0815C10.6641 12.2645 10.9609 12.2645 11.144 12.0815L13.019 10.2065C13.2021 10.0234 13.2021 9.72661 13.019 9.54355Z"
                fill="#78778B"
            />
            <path
                d="M9.08587 6.61795C8.84018 6.53604 8.57481 6.66882 8.49293 6.91442L6.61793 12.5394C6.53606 12.785 6.66881 13.0505 6.9144 13.1324C7.16006 13.2142 7.4255 13.0814 7.50734 12.8359L9.38234 7.21088C9.46418 6.96526 9.33147 6.69979 9.08587 6.61795Z"
                fill="#78778B"
            />
            <path
                d="M3.3125 1.875H2.375C2.11612 1.875 1.90625 2.08487 1.90625 2.34375C1.90625 2.60263 2.11612 2.8125 2.375 2.8125H3.3125C3.57138 2.8125 3.78125 2.60263 3.78125 2.34375C3.78125 2.08487 3.57138 1.875 3.3125 1.875Z"
                fill="#78778B"
            />
            <path
                d="M6.125 1.875H5.1875C4.92862 1.875 4.71875 2.08487 4.71875 2.34375C4.71875 2.60263 4.92862 2.8125 5.1875 2.8125H6.125C6.38388 2.8125 6.59375 2.60263 6.59375 2.34375C6.59375 2.08487 6.38388 1.875 6.125 1.875Z"
                fill="#78778B"
            />
            <path
                d="M8.9375 1.875H8C7.74112 1.875 7.53125 2.08487 7.53125 2.34375C7.53125 2.60263 7.74112 2.8125 8 2.8125H8.9375C9.19637 2.8125 9.40625 2.60263 9.40625 2.34375C9.40625 2.08487 9.19637 1.875 8.9375 1.875Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(HtmlSVG);
