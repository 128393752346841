import { Text } from 'slate';
import escapeHtml from 'escape-html';
import TYPES from '../types';

const serializeEach = node => {
    if (Text.isText(node)) {
        const { bold, italic, underline, code, strikethrough, deleted, inserted } = node;
        const escape = escapeHtml(node.text);
        if ((bold || italic || strikethrough) && escape)
            return `${bold ? '**' : ''}${italic ? '*' : ''}${strikethrough ? '~~' : ''}${escape}${
                strikethrough ? '~~' : ''
            }${italic ? '*' : ''}${bold ? '**' : ''}`;
        //if (bold && escape) return `**${escape}**`;
        //if (italic && escape) return `*${escape}*`;
        if (node[TYPES.H1]) return `# ${escape}`;
        if (node[TYPES.H2]) return `## ${escape}`;
        if (node[TYPES.H3]) return `### ${escape}`;
        if (node[TYPES.LI]) return `* ${escape}`;
        if (code && escape) return `\`${escape}\``;
        //if (strikethrough && escape) return `~~${escape}~~`;
        if (deleted) return `~~${escape}~~`;
        if (inserted) return `__${escape}__`;
        if (underline) return `__${escape}__`;
        return escape;
    }

    const children = node.children.map(n => serializeEach(n)).join('');
    switch (node.type) {
        case TYPES.P:
            return `${children}\n`;
        case TYPES.BLOCKQUOTE:
            return `> ${children}\n`;
        case TYPES.UL:
            return children;
        case TYPES.OL:
            return children;
        case TYPES.LI:
            return `* ${children}\n`;
        case TYPES.H1:
            return `# ${children}`;
        case TYPES.H2:
            return `## ${children}`;
        case TYPES.H3:
            return `### ${children}`;
        case TYPES.H4:
            return `#### ${children}`;
        case TYPES.H5:
            return `##### ${children}`;
        case TYPES.H6:
            return `###### ${children}`;
        case TYPES.BR:
            return `---`;
        case TYPES.IMG:
            const title = node?.type;
            const src = node?.url;
            return `![${title}](${src})`;
        case TYPES.LINK:
            const linkTitle = node?.children ? node.children[0]?.text : 'link';
            const linkSrc = node?.url;
            return linkTitle ? `[${linkTitle}](${linkSrc})` : children;
        default:
            return children;
    }
};

const serialize = (data = []) => {
    const result = [];

    if (data && data.length) {
        data.forEach(element => {
            const a = serializeEach(element);
            if (a) {
                result.push(a);
            }
        });
        return result.join('\n');
    }

    return '';
};

export default serialize;
