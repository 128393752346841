import React from 'react';
import { VERSION_CONTROL_GRID } from 'versionControl/const';
import BranchSVG from 'assets/images/svg/BranchSVG';
import { useSelector } from 'react-redux';
import i18n from 'i18n';

export function useBranchType() {
    return useSelector(state => state.versionControl?.branchType);
}

export function useSelectedBranchId() {
    return useSelector(state => state.versionControl?.selectedBranchId);
}

export function useGridBranches() {
    return useSelector(state => state.versionControl?.gridBranches);
}

export function useDatabaseBranches() {
    return useSelector(state => state.versionControl?.databaseBranches);
}

export function useGridBranchesById({ gridId }) {
    const gridBranches = useGridBranches();
    return gridBranches?.[gridId];
}

export function useDatabaseBranchesById({ dbId }) {
    const databaseBranches = useDatabaseBranches();
    return databaseBranches?.[dbId];
}

export function useBranches({ dbId, gridId }) {
    const branchType = useBranchType();
    const gridBranches = useGridBranchesById({ gridId });
    const databaseBranches = useDatabaseBranchesById({ dbId });
    if (branchType === VERSION_CONTROL_GRID) {
        return gridBranches;
    }
    return databaseBranches;
}

export function useBranchesOptions({ dbId, gridId }) {
    const branches = useBranches({ dbId, gridId });
    return branches?.list
        ?.filter(branch => !branch.isDeleted)
        .map(option => {
            return {
                ...option,
                label: option?.id === gridId ? i18n.t('master_branch') : option.name,
                value: option.id,
                icon: () => <BranchSVG />
            };
        });
}

export function useBranchDetail({ masterId, branchId }) {
    const branches = useGridBranchesById({ gridId: masterId });
    return branches?.list?.find(branch => branch?.id === branchId);
}

export function useBranchStatus({ masterId, branchId }) {
    const branch = useBranchDetail({ masterId, branchId });
    return branch?.status?.toLowerCase();
}

export function useMaster({ dbId, gridId }) {
    const branchType = useBranchType();
    const gridBranches = useGridBranchesById({ gridId });
    const databaseBranches = useDatabaseBranchesById({ dbId });

    if (branchType === VERSION_CONTROL_GRID) {
        return gridBranches?.list?.find(branch => branch.id === gridId);
    }
    return databaseBranches?.list?.find(branch => branch.id === dbId);
}

export function useSelectedBranchDetail({ masterGridId, masterDbId, selectedBranchIdOutSide }) {
    const branchType = useBranchType();
    const selectedBranchId = useSelectedBranchId();
    const selectedId = selectedBranchIdOutSide || selectedBranchId;
    const gridBranches = useGridBranchesById({ gridId: masterGridId });
    const databaseBranches = useDatabaseBranchesById({ dbId: masterDbId });

    if (branchType === VERSION_CONTROL_GRID) {
        return gridBranches?.list?.find(branch => branch.id === selectedId);
    }
    return databaseBranches?.list?.find(branch => branch.id === selectedId);
}
