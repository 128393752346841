import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import IconGridlySVG from 'assets/images/svg/IconGridlySVG';
import TemplateDefaultPNG from 'assets/images/templateDefault.png';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import PopperMenu from 'components/menus/Popper';
import GridTemplatePopup from './GridTemplatePopup';
import SaveTemplate from 'grids/SaveTemplate';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useDispatch } from 'react-redux';
import * as gridActions from 'grids/actions';
import { sendManualTrack } from 'tracker';
import * as roleConst from 'auth/roleConst';
import AccessControl from 'auth/AccessControl';
import { getGridlyApiPrefix } from 'api/url';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { automationAddonsKey } from 'const/inAppAddon';
import { COLOR_TYPES } from 'const';

function GridTemplate({
    name,
    description,
    coveredImagePath,
    partsIncludes,
    image,
    id,
    createdBy,
    alteredBy,
    createdTime,
    templateSource,
    automationApps,
    ...rest
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const isAutomationAddonRequired = useMemo(() => {
        return automationApps?.some(el => automationAddonsKey?.includes(el));
    }, [automationApps]);

    const handleMoreIcon = e => {
        stopPropagation(e);
        handleClick(e);
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const stopPropagation = e => {
        if (!e) return;
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOpenDelete = e => {
        stopPropagation(e);
        setOpenDeleteConfirm(true);
        handleClickAway();
    };

    const handleCloseDelete = e => {
        stopPropagation(e);
        setOpenDeleteConfirm(false);
    };

    const handleOpenEdit = e => {
        stopPropagation(e);
        sendManualTrack({ type: 'Edit Template' });
        setOpenEdit(true);
        handleClickAway();
    };
    const handleCloseEdit = e => {
        stopPropagation(e);
        setOpenEdit(false);
    };

    const handleDelete = e => {
        stopPropagation(e);
        sendManualTrack({ type: 'Delete Template' });
        setIsSubmitting(true);
        dispatch(
            gridActions.deleteGridTemplate({
                templateId: id,
                successCallback: () => {
                    setIsSubmitting(false);
                    console.log('delete template success');
                    handleClickAway();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('delete template failed');
                }
            })
        );
    };

    const coverImageUrl = coveredImagePath ? `${getGridlyApiPrefix}${coveredImagePath}` : TemplateDefaultPNG;

    return (
        <>
            <Card elevation={0} className="w-full max-w-[256px] relative" {...rest}>
                {isAutomationAddonRequired && (
                    <div className="absolute top-4 h-[30px] px-1.5 bg-burntSienna flex items-center text-white text-xs font-medium">
                        {t('addon_required')}
                    </div>
                )}
                <CardMedia className="h-[130px] rounded-md" image={coverImageUrl} title="Paella dish" />
                <CardContent className="pb-2">
                    <div className="flex flex-nowrap items-center justify-between">
                        <div item className="flex-1">
                            <h4 className="truncate">{name}</h4>
                        </div>
                        {templateSource !== 'gridly' && (
                            <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE}>
                                <div item onClick={handleMoreIcon} className="cursor-pointer">
                                    <IconMoreActionsSVG />
                                </div>
                            </AccessControl>
                        )}
                    </div>
                    <p className="body2 text-text-secondary pt-[5px] min-h-[45px] line-clamp-2">
                        {description || t('no_description')}
                    </p>
                </CardContent>
                <CardActions className="!px-4">
                    {templateSource === 'gridly' ? (
                        <div className="flex items-center gap-2">
                            <div item>
                                <AvatarBase color={'#4D3F8A'} size={24} alt="gridly">
                                    <IconGridlySVG className="!w-[18px] !h-auto" />
                                </AvatarBase>
                            </div>
                            <div item>{t('global_gridly_text')}</div>
                        </div>
                    ) : (
                        <div className="flex items-center gap-2">
                            <div item>
                                <AvatarBase src={getAvatarUrl(createdBy || alteredBy)} size={24} alt="avatar">
                                    <AvatarIconSVG className="!w-full !h-full" />
                                </AvatarBase>
                            </div>
                            <div item>{createdBy || alteredBy}</div>
                        </div>
                    )}
                </CardActions>
            </Card>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-end'} id={`add-on-setting`}>
                    <GridTemplatePopup
                        onEdit={handleOpenEdit}
                        onDelete={handleOpenDelete}
                        onClickAway={handleClickAway}
                    />
                </PopperMenu>
            )}
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <SaveTemplate
                    template={{ name, description, partsIncludes, image, id, createdBy, createdTime, ...rest }}
                    isEdit={true}
                    dbId={'dbId'}
                    gridId={'grid?.id'}
                    onClose={handleCloseEdit}
                />
            </Dialog>
            <Dialog open={openDeleteConfirm} onClose={openDeleteConfirm}>
                <ConfirmBox
                    title="Delete template"
                    body={<p className="body2">{t('addon_delete', { name })}</p>}
                    onClose={handleCloseDelete}
                    handleAgreed={handleDelete}
                    handleCancel={handleCloseDelete}
                    isLoading={isSubmitting}
                    agreeLabel="Delete"
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </>
    );
}

export default GridTemplate;
