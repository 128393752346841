import React, { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import { DEBOUNCE_TIME_SEARCHING, NOT_NUMBER_REGEX } from 'const/gridUI';
import { INPUT_QUICK_FILTER_HEIGHT, INPUT_RADIUS, DISABLED_OPACITY } from 'const/style';
import { NUMBER } from 'const/columnTypes';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { OPERATOR } from 'gridUI/conditions';
import { isValidRegex } from 'utils/regex';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: INPUT_QUICK_FILTER_HEIGHT,
        background: theme.colors.paleGrey,
        borderRadius: INPUT_RADIUS,
        '&:focus-within': {
            border: `1px solid ${theme.palette.primary.main}`
        },
        paddingLeft: theme.spacing(2)
    },
    inputWrapper: {
        flex: 1
    },
    input: {
        width: '100%',
        fontSize: 13,
        background: theme.colors.paleGrey,
        color: theme.colors.primaryText,
        outline: 'none',
        border: `none`,
        ...theme.ellipsis(1),
        '&::placeholder': {
            color: `${theme.colors.midGrey} !important`
        }
    },
    clearIcon: {
        margin: theme.spacing(2),
        display: 'flex',
        cursor: 'pointer'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY,
        cursor: 'not-allowed'
    },
    invalid: {
        border: `1px solid ${theme.colors.snackBarWarning}`,
        '&:focus-within': {
            border: `1px solid ${theme.colors.snackBarWarning}`
        }
    }
}));

function InputQuickFilter({
    columnId,
    type,
    isSelectionActive,
    quickFilters,
    isDisabled,
    placeholder,
    isRegex,
    ...rest
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(quickFilters?.[columnId]?.value || '');
    const [isInvalid, setIsInvalid] = React.useState(false);
    const timer = useRef(0);
    const { t } = useTranslation();

    React.useEffect(() => {
        setValue(quickFilters?.[columnId]?.value || '');
    }, [quickFilters, columnId]);

    React.useEffect(() => {
        if (!isRegex) {
            setIsInvalid(false);
        }
    }, [isRegex]);

    const handleFilterInputChange = e => {
        const isNumber = type === NUMBER;
        const value = isNumber ? e.target.value.replace(NOT_NUMBER_REGEX, '') : e.target.value;

        setValue(value);

        if (quickFilters?.[columnId]?.value === value || (isNumber && value === '-')) return;

        if (isRegex) {
            const validRegex = isValidRegex(value);
            if (validRegex) {
                setIsInvalid(false);
            } else {
                setIsInvalid(true);
                return;
            }
        }

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(function() {
            dispatch(
                advancedSearchActions.QuickFilterChange({
                    columnId,
                    value,
                    type,
                    operator: isNumber ? OPERATOR.equal : OPERATOR.contains
                })
            );
            const isCancel = isRegex && !isValidRegex(value);
            if (isCancel) return;
            setTimeout(() => {
                dispatch(
                    advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                        errorCallback: () => {
                            console.log('failed to filter');
                        },
                        successCallback: () => {
                            console.log('filter successfully');
                        }
                    })
                );
            }, DEBOUNCE_TIME_SEARCHING);
        }, DEBOUNCE_TIME_SEARCHING);
    };

    const handleInputFocus = () => {
        dispatch(advancedSearchActions.resetCellStatus());
    };

    const clearFilter = () => {
        setValue('');
        const isNumber = type === NUMBER;

        dispatch(
            advancedSearchActions.QuickFilterChange({
                columnId,
                value: '',
                type,
                operator: isNumber ? OPERATOR.equal : OPERATOR.contains
            })
        );

        if (isRegex) {
            setIsInvalid(false);
        }

        dispatch(
            advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    return (
        <Grid
            container
            wrap="nowrap"
            className={`${classes.root} ${isInvalid ? classes.invalid : ``}`}
            alignItems="center"
        >
            <Tooltip title={value} disableHoverListener={!value}>
                <Grid item className={classes.inputWrapper}>
                    <input
                        className={`${classes.input} ${isDisabled ? classes.disabled : ``}`}
                        value={value}
                        placeholder={t(`column_quick_filter_placeholder`)}
                        onChange={handleFilterInputChange}
                        onFocus={handleInputFocus}
                    />
                </Grid>
            </Tooltip>

            {value && (
                <Grid item className={classes.clearIcon} onClick={clearFilter}>
                    <CloseIconSVG />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(InputQuickFilter);
