import React from 'react';

function NoPathTagSVG({ ...other }) {
    return (
        <svg width="168" height="225" {...other} viewBox="0 0 168 225" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M81.7207 0V33.6H108.007V10.4276L97.5793 0C97.5793 0 81.8655 0 81.7207 0Z" fill="#E5E5F3" />
            <path
                d="M55 17.969V48.8172C55 50.4103 56.3034 51.6414 57.8241 51.6414H97.6517C99.2448 51.6414 100.476 50.3379 100.476 48.8172V24.1241C100.476 22.531 99.1724 21.3 97.6517 21.3H81.2862L75.8552 15.869C75.3483 15.3621 74.5517 15 73.8276 15L57.8241 15.0724C56.3034 15.0724 55 16.3759 55 17.969Z"
                fill="#CCCEE3"
            />
            <path
                d="M97.5791 0.000365316V10.6452H108.007C108.007 10.6452 97.5791 -0.0720485 97.5791 0.000365316Z"
                fill="#BFC1D9"
            />
            <path d="M94.1034 4.63281H85.3413V5.71902H94.1034V4.63281Z" fill="#BFC1D9" />
            <path d="M94.1034 10.2109H85.3413V11.2971H94.1034V10.2109Z" fill="#BFC1D9" />
            <path d="M104.314 15.3516H85.3413V16.4378H104.314V15.3516Z" fill="#BFC1D9" />
            <path d="M32 78V94C32 100.075 36.9249 105 43 105H78" stroke="#CCCEE3" strokeWidth="2" />
            <path d="M122 131V147C122 153.075 117.075 158 111 158H77" stroke="#CCCEE3" strokeWidth="2" />
            <path d="M122 73V81C122 86.5228 117.523 91 112 91H77" stroke="#CCCEE3" strokeWidth="2" />
            <path d="M44 149V157C44 162.523 48.4772 167 54 167H78" stroke="#CCCEE3" strokeWidth="2" />
            <path
                opacity="0.6"
                d="M52.221 66.5362C51.408 66.5362 50.5966 66.6165 49.7998 66.7767C49.199 63.7979 47.2564 61.257 44.5291 59.8816C41.8023 58.5063 38.5908 58.4486 35.8151 59.7247C33.3032 52.8518 25.6579 49.3032 18.7389 51.7983C11.8198 54.2935 8.24738 61.8883 10.7593 68.7607C4.69829 68.951 -0.0893744 73.9342 0.001266 79.957C0.0924492 85.9802 5.02883 90.8179 11.0931 90.8276H52.221C58.9735 90.8276 64.4483 85.3898 64.4483 78.6819C64.4483 71.9739 58.9735 66.5362 52.221 66.5362Z"
                fill="#EAEAF3"
            />
            <path
                opacity="0.6"
                d="M100.227 115.536C101.04 115.536 101.852 115.617 102.648 115.777C103.249 112.798 105.192 110.257 107.919 108.882C110.646 107.506 113.857 107.449 116.633 108.725C119.145 101.852 126.79 98.3032 133.709 100.798C140.628 103.293 144.201 110.888 141.689 117.761C147.75 117.951 152.538 122.934 152.447 128.957C152.356 134.98 147.419 139.818 141.355 139.828H100.227C93.4748 139.828 88 134.39 88 127.682C88 120.974 93.4748 115.536 100.227 115.536Z"
                fill="#EAEAF3"
            />
            <path
                opacity="0.6"
                d="M132.937 62.9727C132.202 56.7933 126.915 52 120.5 52C114.085 52 108.797 56.7933 108.063 62.9727C103.61 62.9727 100 66.5605 100 70.9863C100 75.4122 103.61 79 108.063 79H132.937C137.39 79 141 75.4122 141 70.9863C141 66.5605 137.39 62.9727 132.937 62.9727Z"
                fill="#E5E5F3"
            />
            <ellipse cx="78" cy="218" rx="65" ry="7" fill="#F2F2F8" />
            <path
                opacity="0.6"
                d="M55.937 132.973C55.2024 126.793 49.9146 122 43.5 122C37.0853 122 31.7975 126.793 31.0629 132.973C26.6101 132.973 23 136.56 23 140.986C23 145.412 26.6097 149 31.0625 149H55.9375C60.3903 149 64 145.412 64 140.986C64 136.56 60.3899 132.973 55.937 132.973Z"
                fill="#EAEAF3"
            />
            <path d="M78 44V218" stroke="#CCCEE3" strokeWidth="2" />
            <path d="M67 217.844H88.5" stroke="#CCCEE3" strokeWidth="2" />
            <path
                d="M24.6622 59.3367V59.0059C24.6622 57.898 23.7641 57 22.6563 57H17.0059C15.8981 57 15 57.8981 15 59.0059V61.1465V83.8425C15 84.3964 15.449 84.8454 16.0029 84.8454H44.012V61.3427C44.012 60.2348 43.1139 59.3368 42.0061 59.3368L24.6622 59.3367Z"
                fill="#BFC1D9"
            />
            <path
                d="M46.9939 64.1094H20.8973C19.9451 64.1094 19.1242 64.7788 18.9324 65.7114L15.1118 84.299C15.2784 84.6219 15.6146 84.8431 16.0029 84.8431H45.1439C46.1664 84.8431 47.0252 84.074 47.1377 83.0577L48.9877 66.3358C49.1191 65.148 48.1891 64.1094 46.9939 64.1094Z"
                fill="#CCCEE3"
            />
            <path
                d="M114.662 108.337V108.006C114.662 106.898 113.764 106 112.656 106H107.006C105.898 106 105 106.898 105 108.006V110.146V132.842C105 133.396 105.449 133.845 106.003 133.845H134.012V110.343C134.012 109.235 133.114 108.337 132.006 108.337L114.662 108.337Z"
                fill="#BFC1D9"
            />
            <path
                d="M136.994 113.109H110.897C109.945 113.109 109.124 113.779 108.932 114.711L105.112 133.299C105.278 133.622 105.615 133.843 106.003 133.843H135.144C136.166 133.843 137.025 133.074 137.138 132.058L138.988 115.336C139.119 114.148 138.189 113.109 136.994 113.109Z"
                fill="#CCCEE3"
            />
            <path
                d="M38.5393 127.581V127.358C38.5393 126.608 37.9314 126 37.1817 126H33.3575C32.6078 126 32 126.608 32 127.358V128.806V144.167C32 144.542 32.3039 144.845 32.6788 144.845H51.6349V128.939C51.6349 128.189 51.0271 127.582 50.2774 127.582L38.5393 127.581Z"
                fill="#BFC1D9"
            />
            <path
                d="M53.6531 130.812H35.9912C35.3468 130.812 34.7912 131.266 34.6614 131.897L32.0757 144.477C32.1884 144.695 32.4159 144.845 32.6788 144.845H52.401C53.093 144.845 53.6743 144.324 53.7504 143.636L55.0024 132.319C55.0914 131.515 54.462 130.812 53.6531 130.812Z"
                fill="#CCCEE3"
            />
            <path
                d="M116.539 56.5815V56.3575C116.539 55.6078 115.931 55 115.182 55H111.358C110.608 55 110 55.6078 110 56.3576V57.8063V73.1667C110 73.5415 110.304 73.8454 110.679 73.8454H129.635V57.9391C129.635 57.1893 129.027 56.5815 128.277 56.5815L116.539 56.5815Z"
                fill="#BFC1D9"
            />
            <path
                d="M131.653 59.8125H113.991C113.347 59.8125 112.791 60.2655 112.661 60.8968L110.076 73.4766C110.188 73.6951 110.416 73.8448 110.679 73.8448H130.401C131.093 73.8448 131.674 73.3243 131.75 72.6365L133.002 61.3193C133.091 60.5154 132.462 59.8125 131.653 59.8125Z"
                fill="#CCCEE3"
            />
            <path
                d="M22 27.9753C21.1302 27.7775 15.6047 26.5416 13.9674 24.0202C12.7395 22.0921 11.307 17.9393 11 17.0989V17V17.0494C11 17.0494 11 17.0494 11 17V17.0494C10.693 17.9393 9.26047 22.0427 8.03256 23.9708C6.39535 26.5416 0.869767 27.7775 0 27.9753C0.869767 28.173 6.39535 29.409 8.03256 31.9798C9.26047 33.9079 10.693 38.0607 11 38.9011V39V38.9506V38.9011C11.307 38.0112 12.7395 33.9079 13.9674 31.9798C15.6558 29.409 21.1814 28.173 22 27.9753Z"
                fill="#EAEAF3"
            />
            <path
                d="M168 92.4809C167.328 92.3281 163.058 91.373 161.793 89.4247C160.844 87.9348 159.737 84.7258 159.5 84.0764V84V84.0382C159.5 84.0382 159.5 84.0382 159.5 84V84.0382C159.263 84.7258 158.156 87.8966 157.207 89.3865C155.942 91.373 151.672 92.3281 151 92.4809C151.672 92.6337 155.942 93.5888 157.207 95.5753C158.156 97.0652 159.263 100.274 159.5 100.924V101V100.962V100.924C159.737 100.236 160.844 97.0652 161.793 95.5753C163.098 93.5888 167.367 92.6337 168 92.4809Z"
                fill="#EAEAF3"
            />
        </svg>
    );
}

export default React.memo(NoPathTagSVG);
