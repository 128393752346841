import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, CircularProgress, Typography, Divider } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { checkExistence } from 'auth/actions';
import Button from 'components/button/Base';
import { GRIDLY_SITE_URL } from 'config';
import { useTranslation, Trans } from 'react-i18next';
import GoogleLoginSVG from 'assets/images/svg/GoogleLoginSVG';
import { API_URL } from 'config';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: 470
    },
    steel: {
        color: theme.colors.steel
    },
    link: {
        color: theme.palette.primary.main
    },
    or: {
        color: theme.colors.lightGreyBlue
    }
}));

const FormSchema = object().shape({
    email: string()
        .email('Invalid Email')
        .required('This field is required')
});

function GetStartedForm({ handleOnNext }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const query = useLocation().search;

    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: FormSchema,
        onSubmit: ({ email }, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            dispatch(
                checkExistence({
                    email,
                    errorCallback: () => {
                        setSubmitting(false);
                        history.push('/signin');
                    },
                    successCallback: result => {
                        setSubmitting(false);
                        if (result.email) {
                            history.push(`/signin?email=${email}`, {
                                error: 'You have been registered with this email. Please login instead'
                            });
                        } else {
                            const queryString = query ? `${query}&email=${email}` : `?email=${email}`;

                            handleOnNext && handleOnNext({ email });
                            history.push(`/signup${queryString}`);
                        }
                    }
                })
            );
            setStatus({ error: null });
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    const handleGoogleSignup = () => {
        window.location = `${API_URL}/oauth2/authorization/google?register=true`;
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <InputText
                        name="email"
                        type="email"
                        value={values.email}
                        height={40}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        errorText={errors.email}
                        placeholder={t(`global_your_work_email_placeholder`)}
                        autoFocus
                    />
                </Grid>
                <Grid item tr-dt="Get Started">
                    <Button fullWidth type="submit" variant="contained" disabled={isSubmitting || !isValid}>
                        {isSubmitting ? <CircularProgress size={24} /> : t(`global_join_now`)}
                    </Button>
                </Grid>
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.or} variant="body2">
                            {t('global_or')}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Divider />
                    </Grid>
                </Grid>
                {!isExternal && (
                    <Grid item xs>
                        <Button onClick={handleGoogleSignup} fullWidth variant="outlined">
                            <span style={{ marginRight: 8 }}>
                                <GoogleLoginSVG />
                            </span>
                            Sign up with Google
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Trans
                        t={t}
                        i18nKey={'auth_get_started_agree_term_message'}
                        default={"<0>By creating an account, you agree to<0><1>Gridly's Terms of Service.</1>"}
                    >
                        <Typography variant="caption" display="inline" className={classes.steel}>
                            By creating an account, you agree to
                        </Typography>
                        <a
                            className={classes.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${GRIDLY_SITE_URL}/terms`}
                        >
                            Gridly's Terms of Service.
                        </a>
                    </Trans>
                </Grid>
            </Grid>
        </form>
    );
}

export default GetStartedForm;
