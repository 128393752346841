import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

function LDTooltip({ maxWidth = false, ...props }) {
    return (
        <Tooltip
            arrow
            classes={{
                tooltip: classNames(`!bg-tooltip !z-[9999] !text-[0.8125rem] !px-2.5 !py-1.5 ${props.className}`, {
                    '!max-w-[500px]': maxWidth
                }),
                tooltipPlacementBottom: '!mt-1',
                arrow: '!text-tooltip'
            }}
            {...props}
        />
    );
}

export default React.memo(LDTooltip);
