import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ROW_HEIGHT } from 'const/gridUI';
import { isKbTab, isKbShiftTab, isKbEscape } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as gridActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        }
    },

    input: {
        width: '100%',
        height: '100%',
        fontSize: '0.875rem',
        border: `none`,
        outline: 'none',
        background: 'transparent'
    },
    disabled: {
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white)
    }
}));

function Formula({ value: cellValue, rowId, columnId, isReadOnly, isCellClickAway, onClose }) {
    const classes = useStyles({ isReadOnly });
    const [value, setValue] = React.useState(cellValue?.[0]);
    const dispatch = useDispatch();
    const isEnterAlready = React.useRef(false);
    const rootRef = React.useRef();
    const inputRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const isSameData = React.useCallback(() => {
        if ((cellValue === null || !cellValue) && !value) return true;
        return value === cellValue;
    }, [cellValue, value]);

    React.useEffect(() => {
        setValue(cellValue);
    }, [cellValue]);

    const updateCellData = React.useCallback(() => {
        dispatch(
            gridActions.updateCellValueWithoutCellInfo({
                value,
                rowId,
                columnId,
                columnType: columnTypes.SINGLE_LINE
            })
        );
    }, [dispatch, value, rowId, columnId]);

    const handleClickAway = React.useCallback(
        e => {
            if (!isSameData()) {
                blurInput();
                updateCellData();
            }
            onClose && onClose();
        },
        [updateCellData, isSameData, onClose]
    );

    React.useEffect(() => {
        if (isCellClickAway) {
            handleClickAway();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCellClickAway]);

    const handleKeyDown = e => {
        if (isKbTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                blurInput();
                onClose && onClose();
                return;
            }
            isEnterAlready.current = true;
            blurInput();
            onClose && onClose();
            return updateCellData();
        }

        if (isKbShiftTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                blurInput();
                onClose && onClose();
                return;
            }
            isEnterAlready.current = true;
            blurInput();
            onClose && onClose();
            return updateCellData();
        }

        if (isKbEscape(e)) {
            blurInput();
            setValue(cellValue);
            onClose && onClose();
        }
    };

    const blurInput = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const handleInputChange = e => {
        setValue(e.target.value);
    };

    return (
        <div className={classes.root} ref={rootRef}>
            <input
                type="text"
                ref={inputRef}
                className={`${classes.input}`}
                value={value || ''}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                disabled={isReadOnly}
            />
        </div>
    );
}

export default React.memo(Formula);
