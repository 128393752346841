import React from 'react';

function WarningSVG({ color = '#F8AD13', ...other }) {
    return (
        <svg {...other} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Error"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C10.7822 0 11.5098 0.391741 11.9639 1.05397L12.0637 1.21154L19.6657 14.2788C20.1079 15.0361 20.1079 15.9663 19.6867 16.7452C19.274 17.4721 18.5677 17.9352 17.7734 17.9937L17.602 18H2.39804C1.5136 18 0.734448 17.524 0.313287 16.7452C-0.0777915 16.022 -0.105726 15.1682 0.246345 14.4436L0.334345 14.2788L7.93631 1.1899C8.37853 0.454327 9.15768 0 10 0ZM9.96682 12.8C9.19129 12.8 8.68452 13.35 8.70036 14.0838C8.68452 14.7839 9.19129 15.351 9.95143 15.351C10.7432 15.351 11.2496 14.7839 11.25 14.0838C11.2337 13.3504 10.7428 12.8 9.96682 12.8ZM8.7 6.13379C8.7 5.50756 9.25958 5 9.95 5C10.6404 5 11.2 5.50756 11.2 6.13379L10.8733 10.6689L10.8701 10.791C10.8402 11.3451 10.5944 11.683 9.95 11.683C9.25958 11.683 9.02608 11.2952 9.02608 10.6689L9.01702 10.369C8.99891 9.97789 8.95362 9.31322 8.88116 8.37499L8.7 6.13379Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(WarningSVG);
