import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import CellBaseDropdown from 'components/selects/CellBaseServer';
import { Grid, Popper, Paper } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { REFERENCE } from 'const/columnTypes';
import { INPUT_PADDING_LEFT, INPUT_QUICK_FILTER_HEIGHT, INPUT_RADIUS, DISABLED_OPACITY } from 'const/style';
import { MAX_OPTIONS } from 'const/gridUI';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { formatReferenceOptionByType, getReferenceOperatorAndValue } from 'utils/gridUI/reference';
import { get1GridColumnRecordsApi } from 'services/grid';
import { OPERATOR } from 'gridUI/conditions';
import { getOptionColor } from 'utils/color';
import Option from 'components/option/Base';

const useStyles = makeStyles(theme => ({
    zIndex: {
        zIndex: theme.zIndex.modal + 200
    },
    selection: {
        fontSize: `13px !important`,
        color: theme.colors.primaryText,
        background: theme.colors.paleGrey,
        height: INPUT_QUICK_FILTER_HEIGHT,
        overflow: 'hidden',
        paddingLeft: INPUT_PADDING_LEFT,
        display: 'flex',
        alignItems: 'center',
        borderRadius: INPUT_RADIUS,
        border: `none`
    },
    focus: {
        borderBottom: `1px solid ${theme.palette.primary.main} !important`
    },
    paper: {
        minWidth: 200,
        maxWidth: 500
    },
    chip: {
        height: 22,
        maxHeight: 22,
        overflow: 'hidden',
        width: '100%',
        justifyContent: 'flex-start',
        marginRight: 4,
        '& svg': {
            marginLeft: 0
        }
    },
    placeholder: {
        color: theme.colors.midGrey,
        fontSize: 13,
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY,
        cursor: 'not-allowed'
    },
    unique: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.colors.border}`
    },
    dlFlex: {
        display: 'flex'
    }
}));

function MultiSelectionReferenceQuickFilter({
    column,
    columnId,
    isSelectionActive,
    quickFilters,
    isDisabled,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    onQuickFilterChange,
    onRefreshGrid,
    t,
    ...rest
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedOptions, setSelectedOptions] = useState(quickFilters?.[columnId]?.value || []);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [showUnique, setShowUnique] = React.useState(true);
    // const [selectKey, setSelectKey] = React.useState(uuidV4());

    const open = Boolean(anchorEl);
    const { referenceSettings, referencedColumnType } = column;
    const referencedBranchId = referenceSettings?.referencedBranchId;
    const referencedColumnId = referenceSettings?.referencedColumnId;

    const id = open ? 'simple-popper' : undefined;

    React.useEffect(() => {
        setSelectedOptions(quickFilters?.[columnId]?.value || []);
    }, [quickFilters, columnId]);

    const callServer = React.useCallback(
        options => {
            onQuickFilterChange &&
                onQuickFilterChange({
                    columnId,
                    value: options,
                    type: REFERENCE,
                    operator: OPERATOR,
                    currentState: referencedColumnType
                });
            onRefreshGrid && onRefreshGrid();
        },
        [referencedColumnType, columnId, onQuickFilterChange, onRefreshGrid]
    );

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleOptionChange = options => {
        setAnchorEl(null);
        setSelectedOptions(options);
        callServer(options);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleDelete = option => {
        let newOptions = selectedOptions.filter(item => item.value !== option.value);
        setSelectedOptions(newOptions);
        callServer(newOptions);
    };

    const serverFunction = React.useCallback(
        async inputValue => {
            const serverBody = {
                dbId,
                limit: MAX_OPTIONS,
                offset: 0,
                gridId: referencedBranchId,
                columnIds: [referencedColumnId]
                // groupBy: showUnique ? [referencedColumnId] : []
            };

            if (inputValue) {
                const { operator, value } = getReferenceOperatorAndValue({
                    inputValue,
                    columnType: referencedColumnType
                });

                serverBody.filterValue = { [referencedColumnId]: { [operator]: value } };
            }

            const response = await get1GridColumnRecordsApi(serverBody);

            const options = response?.recordIds.map(recordId => {
                const recordData = getCellValueOnly({
                    data: response?.data,
                    columnId: referencedColumnId,
                    rowId: recordId
                });
                return {
                    label: recordData,
                    value: recordId,
                    originLabel: recordData
                };
            });
            const optionsFormatted = formatReferenceOptionByType({
                options,
                referencedColumnType,
                theme,
                classes,
                dbId
            });
            return optionsFormatted;
        },
        [referencedColumnId, dbId, referencedBranchId, referencedColumnType, theme, classes]
    );

    return (
        <Grid className={classes.dateInput}>
            <div
                onClick={handleClick}
                className={`${classes.selection} ${anchorEl ? classes.focus : ''} ${
                    isDisabled ? classes.disabled : ``
                }`}
            >
                {isEmpty(selectedOptions) && (
                    <span className={classes.placeholder}>{t(`global_label_select_options`)}</span>
                )}
                {!isEmpty(selectedOptions) &&
                    selectedOptions.map(option => (
                        <Option
                            size="small"
                            className="mr-1"
                            key={option.value}
                            onDelete={() => handleDelete(option)}
                            label={option.label}
                            color={getOptionColor({ ...column, data: option?.value })}
                        />
                    ))}
            </div>
            <Popper className={classes.zIndex} id={id} open={open} anchorEl={anchorEl} transition>
                <Paper className={classes.paper}>
                    {/* <Grid
                        container
                        className={classes.unique}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <IOSSwitch
                                checked={showUnique}
                                onChange={e => {
                                    setShowUnique(e?.target?.checked);
                                    setSelectKey(uuidV4());
                                }}
                                className={classes.switchIcon}
                            />
                        </Grid>
                        <Grid item style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
                            <p className="body2">Show unique values</p>
                        </Grid>
                        <Grid item className={classes.dlFlex}>
                            <QuestionSVG />
                        </Grid>
                    </Grid> */}
                    <CellBaseDropdown
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        serverFunction={serverFunction}
                        defaultValue={selectedOptions}
                        isMulti
                        onBlur={handleClickAway}
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(MultiSelectionReferenceQuickFilter);
