import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getViewRecordHistoryApi } from 'services/view';
import { RECORD_HISTORY_RENDER } from 'const/gridUI';
import { formatRecordHistory, getCorrectColumnType } from 'utils/gridUI/formatData';
import { EXTEND_VIEW_TYPES } from 'const/gridUI';
import { getViewColumnsWithReorderAndLanguagePairs } from 'utils/gridUI/column';
import * as columnTypes from 'const/columnTypes';

export function openRecordHistory({ rowId, rowIndex, action, message, type = EXTEND_VIEW_TYPES.TRANSLATOR }) {
    return {
        type: types.OPEN_RECORD_HISTORY,
        payload: {
            rowId,
            rowIndex,
            action,
            message,
            type
        }
    };
}

export function closeRecordHistory() {
    return {
        type: types.CLOSE_RECORD_HISTORY
    };
}

export function fetchRecordHistory({
    recordId,
    offset = 0,
    limit = RECORD_HISTORY_RENDER,
    errorCallback,
    successCallback
}) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId } = gridUI;
        if (!recordId) {
            return false;
        }
        dispatch(_fetchingRecordHistoryAction());
        try {
            const { recordHistory, totalRecordHistory } = await getViewRecordHistoryApi({
                defaultAccessViewId,
                dbId,
                recordId,
                offset,
                limit
            });
            const recordHistoryFormatted = formatRecordHistory(recordHistory);
            successCallback && successCallback();
            dispatch(
                _fetchingRecordHistoryActionSuccess({
                    recordHistory: recordHistoryFormatted,
                    totalRecordHistory,
                    RECORD_HISTORY_ROW_START_INDEX: offset,
                    RECORD_HISTORY_ROW_STOP_INDEX: limit
                })
            );
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(_fetchingRecordHistoryActionFailed());
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function fetchMoreRecordHistory({
    RECORD_HISTORY_ROW_STOP_INDEX,
    RECORD_HISTORY_ROW_START_INDEX,
    errorCallback,
    successCallback
}) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId, recordHistoryId } = gridUI;
        if (!recordHistoryId) {
            return false;
        }
        dispatch(_fetchingMoreRecordHistoryAction());
        try {
            const { recordHistory, totalRecordHistory } = await getViewRecordHistoryApi({
                defaultAccessViewId,
                dbId,
                recordId: recordHistoryId,
                offset: RECORD_HISTORY_ROW_START_INDEX,
                limit: RECORD_HISTORY_ROW_STOP_INDEX
            });
            const recordHistoryFormatted = formatRecordHistory(recordHistory);
            successCallback && successCallback();
            dispatch(
                _fetchingMoreRecordHistoryActionSuccess({
                    RECORD_HISTORY_ROW_START_INDEX,
                    RECORD_HISTORY_ROW_STOP_INDEX,
                    recordHistory: recordHistoryFormatted,
                    totalRecordHistory
                })
            );
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(_fetchingMoreRecordHistoryActionFailed());
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _fetchingMoreRecordHistoryAction() {
    return {
        type: types.FETCH_MORE_RECORD_HISTORY
    };
}

function _fetchingMoreRecordHistoryActionFailed() {
    return {
        type: types.FETCH_MORE_RECORD_HISTORY_FAILED
    };
}

function _fetchingMoreRecordHistoryActionSuccess({
    recordHistory,
    totalRecordHistory,
    RECORD_HISTORY_ROW_START_INDEX = 0,
    RECORD_HISTORY_ROW_STOP_INDEX = RECORD_HISTORY_RENDER
}) {
    return {
        type: types.FETCH_MORE_RECORD_HISTORY_SUCCESS,
        payload: {
            recordHistory,
            totalRecordHistory,
            RECORD_HISTORY_ROW_START_INDEX,
            RECORD_HISTORY_ROW_STOP_INDEX
        }
    };
}

function _fetchingRecordHistoryAction() {
    return {
        type: types.FETCH_RECORD_HISTORY
    };
}

function _fetchingRecordHistoryActionSuccess({
    recordHistory,
    totalRecordHistory,
    RECORD_HISTORY_ROW_START_INDEX = 0,
    RECORD_HISTORY_ROW_STOP_INDEX = RECORD_HISTORY_RENDER
}) {
    return {
        type: types.FETCH_RECORD_HISTORY_SUCCESS,
        payload: {
            recordHistory,
            totalRecordHistory,
            RECORD_HISTORY_ROW_START_INDEX,
            RECORD_HISTORY_ROW_STOP_INDEX
        }
    };
}

function _fetchingRecordHistoryActionFailed() {
    return {
        type: types.FETCH_RECORD_HISTORY_FAILED
    };
}

export function setTriggerRefreshRecordHistory() {
    return {
        type: types.SET_TRIGGER_REFRESH_RECORD_HISTORY
    };
}

export function resetTriggerRefreshRecordHistory() {
    return {
        type: types.RESET_TRIGGER_REFRESH_RECORD_HISTORY
    };
}

export function openDefaultTranslatorView() {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const { metaData } = gridUI;
        const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });

        const isHasLanguageColumn = viewableColumns?.some(col => {
            const detail = metaData?.[col?.id];
            const columnType = getCorrectColumnType(detail);
            return columnType === columnTypes.TRANSLATION;
        });
        if (isHasLanguageColumn) {
            dispatch(openRecordHistory({}));
        }
    };
}

export function resetRecordHistory() {
    return {
        type: types.RESET_RECORD_HISTORY
    };
}
