import React from 'react';

function IconMoreActionsSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...other}>
            <path
                fill={color}
                fillRule="nonzero"
                d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            />
        </svg>
    );
}

export default React.memo(IconMoreActionsSVG);
