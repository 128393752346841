import React, { useCallback } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
// import IconLockSVG from 'assets/images/svg/IconLockSVG';
import Tooltip from 'components/tooltip/Base';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
// import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ReferenceSVG from 'components/svg-icon/ReferenceSVG';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { ROW_HEIGHT, COLUMN_RESIZE_WIDTH } from 'const/gridUI';
import { INPUT_PADDING_LEFT, DISABLED_OPACITY } from 'const/style';
import { isDragMultipleColumn } from 'utils/gridUI/column';
import * as roleConst from 'auth/roleConst';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_COLUMN_IDS } from 'const';
import Spinner from 'components/spinner/Base';
import GridIcon from 'grids/GridIcon';
import PopperMenu from 'components/menus/Popper';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as columnTypes from 'const/columnTypes';
// import ColumnSetting from 'gridUI/table/grid/columns';
// import { checkContainId } from 'utils/clickAway';
// import { sendManualTrack } from 'tracker';
import * as adavancedSearchActions from 'advancedSearch/actions';
import { useTranslation } from 'react-i18next';
import ModalBase from 'components/modal/Base';
import UploadFolderModal from 'gridUI/table/grid/UploadFolderModal';
import { useIsColumnProcessing } from 'hooks/gridUI';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import BranchSVG from 'assets/images/svg/BranchSVG';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        height: '100%',
        position: 'relative'
    },
    headerName: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            opacity: 0.8
        },
        color: theme.colors.primaryText,
        '& p': {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    dropdown: {
        cursor: 'pointer',
        '& path': {
            fill: hexToRgba(theme.colors.steel, 0.6)
        }
    },
    resizeHandler: {
        width: COLUMN_RESIZE_WIDTH + 2,
        background: theme.colors.resizeColor,
        height: ROW_HEIGHT,
        position: 'absolute',
        top: 0,
        right: 0,
        opacity: 0,
        '&:hover': {
            opacity: 1,
            cursor: 'ew-resize'
        }
    },
    lockIcon: {
        display: 'inline-block',
        width: 16,
        height: 16
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    referenceIcon: {
        padding: `2px 4px`,
        borderRadius: 2,
        background: theme.colors.solitude,
        marginRight: 4,
        display: 'flex',
        cursor: 'pointer'
    },
    itemsCount: {
        color: theme.colors.lightLavender,
        fontSize: 13,
        paddingLeft: 2
    },
    gridList: {
        maxWidth: 500,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    whiteText: {
        color: theme.colors.white
    },
    columnSetting: {
        minWidth: 220
    },
    columnInfo: {
        display: 'flex',
        marginRight: 4
    },
    tutorial: {
        maxWidth: 280
    },
    caption: {
        color: theme.colors.white
    },
    primary: {
        background: theme.colors.dodgerBlue,
        borderRadius: 4,
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.white
    }
}));

const TooltipInfo = React.memo(({ column, children }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderContent = useCallback(
        (title, content) => {
            return (
                <Grid item>
                    <Typography variant="caption" className={classes.caption}>
                        {title}:{' '}
                    </Typography>
                    <Typography variant="overline">{content}</Typography>
                </Grid>
            );
        },
        [classes]
    );

    const columnType = React.useMemo(() => {
        return column?.type;
    }, [column]);

    const columnId = React.useMemo(() => {
        return column?.publicId || column?.columnId;
    }, [column]);

    return (
        <Grid container className={classes.tutorial} direction="column">
            {renderContent(t('global_id'), columnId === columnTypes.RECORD_ID ? '_recordId' : columnId)}
            {column.name && renderContent(t('global_name'), column.name)}
            {column.type && renderContent(t('global_type'), columnTypes.getColumnLabel(column.type))}
            {columnType === columnTypes.REFERENCE &&
                renderContent(t('reference_grid'), column?.referenceSettings?.referencedGridName)}
            {columnType === columnTypes.REFERENCE &&
                renderContent(t('branch'), column?.referenceSettings?.referencedBranchName)}
            {column.description && renderContent(t('global_description'), column.description)}
        </Grid>
    );
});

function Header({
    id,
    column,
    // position,
    columnIndex,
    isSelectionActive,
    setColumnHandlerStartPosition = () => {},
    setColumnReorderStartPosition = () => {},
    columns,
    accessManageGridColumn,
    accessManageGridRecord,
    contextMenuId,
    isImporting,
    isShareViewLink,
    columnWidth
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const [refAnchorEl, setRefAnchorEl] = React.useState(null);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [currentState, setCurrentState] = React.useState(COLUMN_FORMAT_STATES.MENU);
    const [isOpenUploadFolder, setIsOpenUploadFolder] = React.useState(false);
    // const formatColumnId = useFormatColumnId();
    // const [isClickAwayColumnFormat, setIsClickAwayColumnFormat] = React.useState(false);

    const columnId = React.useMemo(() => {
        return column?.id || column?.columnId;
    }, [column]);

    const hashColumnId = React.useMemo(() => {
        return column?.hashColumnId;
    }, [column]);

    const isProcessing = useIsColumnProcessing(columnId);

    const columnType = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const referencingItems = React.useMemo(() => {
        return column?.referencingItems;
    }, [column]);

    const timerRef = React.useRef(null);
    let columnsSelectedRef = React.useRef([]);

    // const isLockByRole = React.useMemo(() => {
    //     return accessManageGridColumn !== roleConst.FULL || !accessManageGridColumn;
    // }, [accessManageGridColumn]);

    const isSystemColumn = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS.includes(columnId);
    }, [columnId]);

    // const isDisabled = React.useMemo(() => {
    //     return isLockByRole || isSystemColumn || isProcessing;
    // }, [isLockByRole, isSystemColumn, isProcessing]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleRefClick = React.useCallback(
        event => {
            setRefAnchorEl(refAnchorEl ? null : event.currentTarget);
        },
        [refAnchorEl]
    );

    const handleRefClickAway = React.useCallback(() => {
        setRefAnchorEl(null);
    }, []);

    // React.useEffect(() => {
    //     if (formatColumnId && formatColumnId === columnId) {
    //         setAnchorEl(rootRef.current);
    //         setCurrentState(COLUMN_FORMAT_STATES.FORMAT);
    //     } else {
    //         setAnchorEl(null);
    //         setCurrentState(COLUMN_FORMAT_STATES.MENU);
    //     }
    // }, [formatColumnId, columnId]);

    const handleHeaderClick = useCallback(
        e => {
            const { isShift, isCtrl } = getStatusCtrlOrShiftKey(e);
            const eHold = { ...e };
            dispatch(
                adavancedSearchActions.columnSelection({
                    isCtrl,
                    isShift,
                    columnIndex: Math.max(columnIndex - 1, 0),
                    columnSelected: hashColumnId,
                    callback: ({ columnsSelected }) => {
                        columnsSelectedRef.current = columnsSelected;
                    }
                })
            );

            if (accessManageGridColumn === roleConst.FULL && !isShareViewLink) {
                timerRef.current = setTimeout(() => {
                    setColumnReorderStartPosition({
                        isMulti: isDragMultipleColumn({ columns, columnsSelected: columnsSelectedRef.current }),
                        columnsSelected: columnsSelectedRef.current,
                        e: eHold,
                        columnId: hashColumnId
                    });
                }, 200);
            }
        },
        [
            dispatch,
            hashColumnId,
            columnIndex,
            columns,
            accessManageGridColumn,
            isShareViewLink,
            setColumnReorderStartPosition
        ]
    );

    const handleReorderRelease = () => {
        if (timerRef && timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    // const handleColumnMenuClick = useCallback(
    //     e => {
    //         if (isLockByRole || isProcessing) return;
    //         dispatch(adavancedSearchActions.resetSelection());
    //         setAnchorEl(rootRef.current);
    //         setCurrentState(COLUMN_FORMAT_STATES.MENU);
    //     },
    //     [dispatch, isLockByRole, isProcessing]
    // );

    // const handleDoubleClick = React.useCallback(
    //     e => {
    //         if (isDisabled) return;
    //         dispatch(adavancedSearchActions.resetSelection());
    //         setAnchorEl(e.currentTarget);
    //         setCurrentState(COLUMN_FORMAT_STATES.FORMAT);
    //     },
    //     [isDisabled, dispatch]
    // );

    // const handleContext = React.useCallback(
    //     e => {
    //         if (isShareViewLink) return;
    //         setAnchorEl(e.currentTarget);
    //         dispatch(adavancedSearchActions.resetSelection());
    //         setCurrentState(COLUMN_FORMAT_STATES.MENU);
    //     },
    //     [dispatch, isShareViewLink]
    // );

    const handleClickAway = React.useCallback(e => {
        setRefAnchorEl(null);
    }, []);

    // const handleColumnClickAway = React.useCallback(
    //     e => {
    //         console.log('handleColumnClickAway', e);
    //         if (
    //             checkContainId(e, 'column-setting') ||
    //             checkContainId(e, 'new-column-format') ||
    //             checkContainId(e, 'ConfirmSwitchingBox') ||
    //             checkContainId(e, 'calendar-portal') ||
    //             checkContainId(e, 'formula-suggestion') ||
    //             checkContainId(e, 'confirm-delete-column') ||
    //             checkContainId(e, 'ConfirmSwitchingBox') ||
    //             checkContainId(e, 'popper-language') ||
    //             checkContainId(e, 'popper-timezone') ||
    //             checkContainId(e, 'confirm-column-box')
    //         ) {
    //             return;
    //         }

    //         if (currentState === COLUMN_FORMAT_STATES.FORMAT) {
    //             setIsClickAwayColumnFormat(true);

    //             setTimeout(() => {
    //                 setIsClickAwayColumnFormat(false);
    //             }, 0);
    //         } else {
    //             setAnchorEl(null);
    //             dispatch(adavancedSearchActions.setFormatColumnId(null));
    //         }
    //     },
    //     [dispatch, currentState]
    // );

    const isPrimaryReferenceColumn = React.useMemo(() => {
        return column?.referenceSettings?.primaryReference && column?.referenceSettings?.referenceType === 'row';
    }, [column]);

    const iconWidth = React.useMemo(() => {
        const isHaveRefer = referencingItems?.length > 0;

        let width = 16;
        width = !isSystemColumn ? 16 + 4 + 14 : width;

        if (isHaveRefer) {
            width += 33 + 4;
        }

        if (isPrimaryReferenceColumn) {
            width += 16 + 4;
        }

        return width;
    }, [referencingItems, isSystemColumn, isPrimaryReferenceColumn]);

    // const handleDeleteColumn = React.useCallback(
    //     e => {
    //         sendManualTrack({
    //             type: `Delete Column`,
    //             customData: {
    //                 columnId
    //             }
    //         });
    //         dispatch(
    //             adavancedSearchActions.deleteGridColumn({
    //                 columnId,
    //                 columnIndex,
    //                 column,
    //                 errorCallback: () => {
    //                     console.log('failed to delete column');
    //                 },
    //                 successCallback: () => {
    //                     console.log('delete successfully');
    //                 }
    //             })
    //         );
    //     },
    //     [dispatch, columnId, columnIndex, column]
    // );

    // const handleOpenUploadFolder = React.useCallback(e => {
    //     setIsOpenUploadFolder(true);
    //     setAnchorEl(null);
    // }, []);

    const handleCloseUploadFolder = React.useCallback(() => {
        setIsOpenUploadFolder(false);
    }, []);

    return (
        <Grid
            id={id}
            container
            justify="space-between"
            alignItems="center"
            className={`${classes.root} ${isImporting || columnIndex === 0 ? classes.disabled : ''}`}
            // onDoubleClick={handleDoubleClick}
            // onContextMenu={handleContext}
            ref={rootRef}
        >
            <Grid
                style={{ width: `calc(100% - ${iconWidth}px)`, cursor: 'pointer' }}
                item
                className={`${classes.headerName} ${isSystemColumn ? '' : 'columnHeader'}`}
            >
                <Tooltip title={<TooltipInfo column={column} />}>
                    <Grid
                        container
                        onMouseUp={handleReorderRelease}
                        onMouseDown={handleHeaderClick}
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                        wrap="nowrap"
                    >
                        <Grid item style={{ display: 'flex' }}>
                            <ColumnIcon
                                group={column.group}
                                type={columnType}
                                customProperties={column.customProperties}
                                hideLabelCountry={columnWidth <= 200}
                                styleCountryFlagWrapper={{
                                    width: 24
                                }}
                            />
                        </Grid>

                        <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis', flex: 1, marginLeft: 4 }}>
                            <Typography style={{ whiteSpace: 'nowrap' }} variant="body2" component="span">
                                {column.name}
                            </Typography>
                            {columnType === columnTypes.REFERENCE && (
                                <Typography style={{ whiteSpace: 'nowrap' }} variant="caption" component="span">
                                    {' '}
                                    (from {column?.referenceSettings?.referencedGridName})
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Tooltip>
            </Grid>
            <Grid style={{ width: iconWidth }} id={`dropdown_${columnId}`} item>
                <Grid
                    id={`${id}-right-icon`}
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    container
                    justify="flex-end"
                >
                    {isPrimaryReferenceColumn && (
                        <Grid item className={classes.primary}>
                            P
                        </Grid>
                    )}

                    {referencingItems?.length > 0 && (
                        <Grid item>
                            <Grid item onClick={handleRefClick} className={classes.referenceIcon}>
                                <ReferenceSVG color={theme.colors.lightLavender} />
                                <Typography variant="body1" className={classes.itemsCount}>
                                    {referencingItems?.length}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {isProcessing && <Spinner size={18} thick={3} />}
                    {/* {!(isLockByRole || isProcessing) && (
                        <ArrowDownSVG
                            // onClick={handleColumnMenuClick}
                            tr-dt="Open Column Setting"
                            className={classes.dropdown}
                        />
                    )} */}
                </Grid>
            </Grid>

            {accessManageGridRecord === roleConst.FULL && !isShareViewLink && (
                <div
                    className={classes.resizeHandler}
                    onMouseDown={e => setColumnHandlerStartPosition({ e, columnId: hashColumnId })}
                    id={`columnHandler_${columnId}`}
                ></div>
            )}

            {refAnchorEl && (
                <PopperMenu
                    className={classes.commentPopup}
                    anchorEl={refAnchorEl}
                    handleClickAway={handleRefClickAway}
                    placement={'bottom'}
                >
                    <div ref={rootRef} className="flex flex-col max-w-[500px] cursor-default py-2">
                        {referencingItems?.map(item => {
                            return (
                                <div className="flex gap-1 items-center">
                                    <div className="w-full flex items-center gap-2 px-3.5">
                                        <GridIcon grid={item?.grid} size="small" />
                                        <p className="body2 flex-1 truncate">{item?.grid?.name}</p>
                                    </div>
                                    <ArrowRightSVG className={'flex-none'} />
                                    <div className="w-full flex items-center gap-2 px-3.5">
                                        <BranchSVG color="#79778B" width="14" height="14" />
                                        <p className="body2 flex-1 truncate">{item?.branch?.name}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </PopperMenu>
            )}

            {/* {anchorEl && (
                <PopperMenu
                    className={classes.columnSetting}
                    anchorEl={anchorEl}
                    handleClickAway={handleColumnClickAway}
                    placement={'bottom-start'}
                    id={'column-setting'}
                >
                    <ColumnSetting
                        columnIndex={columnIndex}
                        onClickAway={handleColumnClickAway}
                        state={currentState}
                        column={column}
                        onDelete={handleDeleteColumn}
                        onChangeState={state => setCurrentState(state)}
                        onUpload={handleOpenUploadFolder}
                        isClickAwayColumnFormat={isClickAwayColumnFormat}
                        onClose={() => {
                            setAnchorEl(null);
                            dispatch(gridUIActions.setFormatColumnId(null));
                        }}
                    />
                </PopperMenu>
            )} */}

            <ModalBase maxWidth={false} open={isOpenUploadFolder} onClose={handleCloseUploadFolder}>
                <UploadFolderModal column={column} onClose={handleCloseUploadFolder} />
            </ModalBase>
        </Grid>
    );
}

Header.propTypes = {
    column: PropTypes.object.isRequired,
    isSelectionActive: PropTypes.bool,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(Header);
