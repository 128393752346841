import React from 'react';

const LQASettingSVG = ({ color = '#78778B', ...other }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.1335 3.42895C19.0393 2.20982 18.0203 1.25 16.777 1.25H2.98867L2.80395 1.25711C1.58482 1.35134 0.625 2.37037 0.625 3.61367V14.2578L0.633063 14.4268C0.718413 15.317 1.47075 16.0156 2.38398 16.0156L15.3402 16.0496L15.4588 16.0614C15.575 16.0848 15.6827 16.1422 15.768 16.2266L18.107 18.5656L18.1866 18.6332C18.572 18.9092 19.1406 18.6398 19.1406 18.1367V3.61367L19.1335 3.42895ZM2.87383 2.89062H16.893L17.0024 2.90041C17.2861 2.95181 17.5 3.19961 17.5 3.49648V15.6383L17.3008 15.4379L16.4652 14.5637L16.3924 14.4977C16.2887 14.4185 16.1601 14.375 16.027 14.375H2.87383L2.76443 14.3653C2.48069 14.314 2.2668 14.0671 2.2668 13.7691V3.49648L2.27654 3.38774C2.32777 3.10544 2.57487 2.89062 2.87383 2.89062Z"
                fill={color}
            />
            <path
                d="M12.6937 11.3698C12.9313 11.0312 13.1162 10.6406 13.2482 10.1979C13.4067 9.72917 13.4859 9.20833 13.4859 8.6875C13.4859 8.08854 13.4067 7.56771 13.2482 7.07292C13.0898 6.57812 12.8521 6.13542 12.5352 5.77083C12.2183 5.40625 11.8486 5.11979 11.3996 4.91146C10.9507 4.72917 10.4225 4.625 9.86796 4.625C9.28697 4.625 8.78521 4.72917 8.33627 4.9375C7.88732 5.11979 7.4912 5.40625 7.1743 5.77083C6.8838 6.13542 6.64613 6.55208 6.48768 7.04688C6.32923 7.54167 6.25 8.08854 6.25 8.66146C6.25 9.26042 6.32923 9.80729 6.48768 10.3021C6.64613 10.7969 6.8838 11.2135 7.1743 11.5781L7.2007 11.6042C7.51761 11.9427 7.88732 12.2292 8.30986 12.4375H8.33627C8.78521 12.6198 9.28697 12.724 9.86796 12.724C10.5546 12.724 11.162 12.5677 11.6637 12.3073L12.6325 13.2399C12.7106 13.3151 12.8344 13.3143 12.9116 13.2382L13.6283 12.5314C13.7092 12.4517 13.7075 12.3208 13.6247 12.2432L12.6937 11.3698ZM9.47214 9.85782C9.39468 9.93627 9.39531 10.0626 9.47355 10.1403L10.581 11.2396C10.1056 11.4479 9.52465 11.4219 9.0493 11.1875C8.81162 11.0573 8.60035 10.901 8.41549 10.6667C8.23063 10.4323 8.07218 10.1458 7.96655 9.83333C7.86092 9.49479 7.8081 9.10417 7.8081 8.66146C7.8081 8.21875 7.86092 7.82813 7.96655 7.51563C8.07218 7.17708 8.23063 6.89062 8.41549 6.68229C8.60035 6.44792 8.81162 6.26563 9.0493 6.16146C9.28697 6.03125 9.57747 5.97917 9.86796 5.97917C10.1585 5.97917 10.4225 6.03125 10.6602 6.16146H10.6866C10.9243 6.29167 11.1356 6.44792 11.3204 6.68229C11.5053 6.91667 11.6637 7.17708 11.743 7.51563V7.54167C11.8486 7.88021 11.9014 8.27083 11.9014 8.6875C11.9014 9.02604 11.875 9.36458 11.7958 9.65104V9.67708C11.743 9.91146 11.6637 10.0938 11.5845 10.276L10.4514 9.15143C10.3729 9.07347 10.246 9.07411 10.1682 9.15286L9.47214 9.85782Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(LQASettingSVG);
