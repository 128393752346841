import React from 'react';
import DatabaseButtonAdd from './DatabaseButtonAdd';
import uuidv1 from 'uuid/v1';
import { useDispatch } from 'react-redux';
import { createDatabase } from '../databases/actions';
import { useIsCreatingDatabase } from 'hooks/database';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function DatabaseAdd({ fetchingDisabled }) {
    const { workspaceId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isCreatingDatabase = useIsCreatingDatabase();

    const addDatabaseHandler = () => {
        dispatch(
            createDatabase({
                workspaceId,
                database: {
                    name: t('new_database_name'),
                    id: uuidv1()
                }
            })
        );
    };

    return (
        <DatabaseButtonAdd
            buttonClickHandler={addDatabaseHandler}
            fetchingDisabled={fetchingDisabled}
            disabled={isCreatingDatabase}
        />
    );
}

export default DatabaseAdd;
