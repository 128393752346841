import React from 'react';

function PartialCheckedBoolean({ ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="#ACADB9" />
            <rect x="4" y="8" width="10" height="3" rx="1.5" fill="#42B883" />
        </svg>
    );
}

export default React.memo(PartialCheckedBoolean);
