import React from 'react';
import LDBasePortal from 'components/selects/LDBasePortal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Popper, Paper, Typography, Collapse } from '@material-ui/core';
import CardInfo from 'components/cardInfo/Base';
import ButtonBase from 'components/button/Base';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import NewTMSVG from 'assets/images/svg/NewTMSVG';
import { POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM, DISABLED_OPACITY } from 'const/style';
import TMPopup from './TMPopup';
import { useDispatch } from 'react-redux';
import * as tmActions from './actions';
import { TM_UPLOAD_SUPPORTED_EXTENSIONS, GRID_UPLOAD_MAX_SIZE, COLOR_TYPES } from 'const';
import { useDropzone } from 'react-dropzone';
import { getFriendlyDate } from 'utils/datetime';
import { useSelectedWorkspaceByWorkspaceIds, useAvailableWorkspaceOptions } from 'hooks/workspace';
import Spinner from 'components/spinner/Base';
import { sendManualTrack } from 'tracker';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import ExportTM from './ExportTM';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import DeactivateTmSVG from 'assets/images/svg/DeactivateTmSVG';
import ToolTipClickContent from 'components/tooltip/Base';
import { Trans, useTranslation } from 'react-i18next';
import { getUploadErrorMessage } from 'utils/upload';
import { enqueueSnackbar } from 'notifier/actions';
import IOSSwitch from 'components/switches/IOS';
import { DEPENDENCY_STATUS } from 'const/gridUI';
import EditorTM from './EditorTM';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`
    },
    pd4: {
        padding: theme.spacing(4)
    },
    flex: {
        display: 'flex'
    },
    selectDropdown: {
        minWidth: 250
    },
    editor: {
        position: 'absolute',
        zIndex: 10,
        top: 35,
        left: -120,
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    },
    spinnerWrapper: {
        position: 'absolute',
        bottom: -12,
        right: -12,
        background: theme.colors.white,
        borderRadius: '50%',
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: theme.shadows[1]
    },
    showMoreIcon: {
        cursor: 'pointer'
    },
    expandContent: {
        padding: theme.spacing(4),
        borderTop: `1px solid ${theme.colors.border}`
    },
    expandContentDescription: {
        marginTop: 12
    },
    code: {
        fontWeight: 700
    },
    dropdownClassName: {
        minWidth: 130
    }
}));

const TM_TYPES = {
    90: 90,
    100: 100
};

const DEPENDENCY_STATUS_OPTIONS = [
    { value: DEPENDENCY_STATUS.UP_TO_DATE, label: 'Up-to-date' },
    { value: DEPENDENCY_STATUS.OUT_OF_DATE, label: 'Out-of-date' },
    { value: DEPENDENCY_STATUS.UNSET, label: 'Unset' }
];

function TMItem({ isDefault, id, isShowLastPopupCreate, workspaceIds, name, alteredTime, tm }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isUploading, setIsUploading] = React.useState(false);
    const [isCleaning, setIsCleaning] = React.useState(false);
    const selectedWorkspaces = useSelectedWorkspaceByWorkspaceIds(workspaceIds);
    const options = useAvailableWorkspaceOptions(workspaceIds);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const anchorElRef = React.useRef();
    const [openConfirmCleanModal, setOpenConfirmCleanModal] = React.useState(false);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(false);
    const [openExportModal, setOpenExportModal] = React.useState(false);
    const [openEditorTMModal, setOpenEditorTMModal] = React.useState(false);
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [tmType, setTmType] = React.useState(tm.fuzzyRatio);
    const [isPauseConsuming, setIsPauseConsuming] = React.useState(tm.isPausedConsuming);
    const [populateTranslationStatus, setPopulateTranslationStatus] = React.useState(tm.populateTranslationStatus);
    const [isDisabled, setIsDisabled] = React.useState(tm.isDisabled);

    const openConfirmCleanHandler = () => {
        handleClickAway();
        setOpenConfirmCleanModal(true);
    };
    const closeConfirmCleanHandler = () => setOpenConfirmCleanModal(false);

    const openConfirmDeleteHandler = () => {
        handleClickAway();

        setOpenConfirmDeleteModal(true);
    };
    const closeConfirmDeleteHandler = () => setOpenConfirmDeleteModal(false);

    const openExportHandler = () => {
        handleClickAway();

        setOpenExportModal(true);
    };
    const closeExportHandler = () => setOpenExportModal(false);

    const openEditorTMHandler = () => {
        handleClickAway();

        setOpenEditorTMModal(true);
    };
    const closeEditorTMHandler = () => setOpenEditorTMModal(false);

    const open = Boolean(anchorEl);
    const poperId = open ? 'transitions-popper' : undefined;

    React.useEffect(() => {
        if (isShowLastPopupCreate) {
            let $arrow = document.getElementById(`tm_more_${id}`);
            if ($arrow) {
                $arrow.click();
                dispatch(tmActions.turnOffLastPopup());
            }
        }
    }, [isShowLastPopupCreate, id, dispatch]);

    const onUploadAcceptedHandler = React.useCallback(
        acceptedFiles => {
            setIsUploading(true);
            sendManualTrack({
                type: `Start upload TM`
            });
            dispatch(
                tmActions.importCurrentCompanyTM({
                    tmId: id,
                    files: acceptedFiles,
                    successCallback: () => {
                        sendManualTrack({
                            type: `Upload TM Success`
                        });
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        sendManualTrack({
                            type: `Upload TM Failed`
                        });
                        setIsUploading(false);
                    }
                })
            );
        },
        [id, dispatch]
    );

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, TM_UPLOAD_SUPPORTED_EXTENSIONS);
            const fileError = fileErrors?.[0];

            sendManualTrack({
                type: `Upload TM In Reject Mode`
            });
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDropAccepted: onUploadAcceptedHandler,
        onDropRejected,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        accept: TM_UPLOAD_SUPPORTED_EXTENSIONS
    });

    const handleClick = event => {
        if (anchorEl === event.currentTarget) return;
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleOptionChange = options => {
        const workspaceIds = options?.map(option => option.value);

        sendManualTrack({
            type: `Change TM Workspace`,
            customData: {
                workspaceIds
            }
        });

        dispatch(
            tmActions.updateTM({
                oldTM: tm,
                newTM: {
                    ...tm,
                    workspaceIds
                },
                successCallback: () => {
                    console.log('update tm success');
                },
                errorCallback: () => {
                    console.log('update tm failed');
                }
            })
        );
    };

    const onCleanTMClick = React.useCallback(
        e => {
            stopPropagation(e);
            setIsCleaning(true);
            sendManualTrack({ type: `Start Clean TM` });
            dispatch(
                tmActions.cleanCurrentCompanyTM({
                    tmId: id,
                    successCallback: () => {
                        setIsCleaning(false);
                        sendManualTrack({ type: `Clean TM Success` });
                        closeConfirmCleanHandler();
                    },
                    errorCallback: () => {
                        setIsCleaning(false);
                        sendManualTrack({ type: `Clean TM Failed` });
                    }
                })
            );
        },
        [id, dispatch]
    );

    const handleDeleteTM = e => {
        stopPropagation(e);
        sendManualTrack({ type: `Start Delete TM` });
        dispatch(
            tmActions.deleteTM({
                tmId: id,
                successCallback: () => {
                    sendManualTrack({ type: `Delete TM Success` });
                    console.log('deleteTm successfully');
                },
                errorCallback: () => {
                    sendManualTrack({ type: `Delete TM Failed` });
                    console.log('deleteTm failed');
                }
            })
        );

        closeConfirmDeleteHandler();
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleUpdateTMServer = ({ newTM }) => {
        dispatch(
            tmActions.updateTM({
                oldTM: tm,
                newTM,
                successCallback: () => {
                    console.log('update tm success');
                },
                errorCallback: () => {
                    console.log('update tm failed');
                }
            })
        );
    };

    const handleTmTypeChange = e => {
        const newTmType = tmType === TM_TYPES[100] ? TM_TYPES[90] : TM_TYPES[100];
        setTmType(newTmType);
        handleUpdateTMServer({
            newTM: {
                ...tm,
                workspaceIds,
                fuzzyRatio: newTmType
            }
        });
    };

    const handleTMPauseConsumingChange = e => {
        const newPauseConsuming = !e.target.checked;
        setIsPauseConsuming(newPauseConsuming);
        handleUpdateTMServer({
            newTM: {
                ...tm,
                workspaceIds,
                isPausedConsuming: newPauseConsuming
            }
        });
    };

    const handleTranslationStatusChange = opt => {
        const { value } = opt;
        setPopulateTranslationStatus(value);
        handleUpdateTMServer({
            newTM: {
                ...tm,
                workspaceIds,
                populateTranslationStatus: value
            }
        });
    };

    const handleTMDisableChange = e => {
        const checked = !e.target.checked;
        setIsDisabled(checked);
        handleUpdateTMServer({
            newTM: {
                ...tm,
                isDisabled: checked
            }
        });
    };

    const goEditorTM = () => {
        history.push(`/tm-editor/${id}`);
    };

    return (
        <Grid
            style={{
                pointerEvents: isUploading ? 'none' : '',
                opacity: isUploading ? DISABLED_OPACITY : 1
            }}
            container
            direction="column"
            className={classes.root}
            wrap="nowrap"
        >
            <Grid item>
                <Grid
                    wrap="nowrap"
                    container
                    className={classes.pd4}
                    justify="space-between"
                    alignItems="center"
                    direction="row"
                >
                    <Grid item xs={4}>
                        <Grid container alignItems="center" wrap="nowrap" direction="row" spacing={2}>
                            <Grid item className={classes.showMoreIcon} onClick={() => setIsShowMore(!isShowMore)}>
                                {isShowMore ? <ArrowUpSVG /> : <ArrowDownSVG />}
                            </Grid>
                            <Grid item>
                                <CardInfo
                                    avatar={
                                        <Grid container style={{ position: 'relative' }}>
                                            <NewTMSVG />
                                            {isUploading && (
                                                <Grid item className={classes.spinnerWrapper}>
                                                    <Spinner size={18} thick={3} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                    title={
                                        <>
                                            <Typography variant="body2" display="inline">
                                                {name}
                                            </Typography>
                                            {isDisabled && (
                                                <ToolTipClickContent placement="top" title={t('deactivated_tm')}>
                                                    <Typography
                                                        style={{
                                                            position: 'relative',
                                                            top: 2,
                                                            marginLeft: theme.spacing(2)
                                                        }}
                                                        variant="body2"
                                                        display="inline"
                                                    >
                                                        <DeactivateTmSVG />
                                                    </Typography>
                                                </ToolTipClickContent>
                                            )}
                                        </>
                                    }
                                    subTitle={`Last updated: ${getFriendlyDate(alteredTime)}`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container wrap="nowrap" justify="flex-end" spacing={3} alignItems="center">
                            {isDefault ? (
                                <Grid item>
                                    <div className={classes.selectDropdown}></div>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <LDBasePortal
                                        ddPlaceholder={t('tm_workspace_select_ddplaceholder')}
                                        menuPlaceholder={'Find a project'}
                                        options={options}
                                        onChange={handleOptionChange}
                                        defaultValue={selectedWorkspaces}
                                        dropdownClassName={classes.selectDropdown}
                                        isMulti={true}
                                    />
                                </Grid>
                            )}
                            <Grid item className={classes.flex}>
                                <div {...getRootProps()} tr-dt="Click To Update TM">
                                    <input {...getInputProps()} />
                                    <ButtonBase variant="outlined">{t('update_tm')}</ButtonBase>
                                </div>
                            </Grid>
                            <Grid
                                item
                                id={`tm_more_${id}`}
                                className={classes.flex}
                                style={{ position: 'relative', cursor: 'pointer' }}
                                onClick={handleClick}
                            >
                                <IconMoreActionsSVG />
                                {/* <div ref={anchorElRef}></div> */}
                                <Popper
                                    // container={anchorElRef.current}
                                    id={poperId}
                                    placement={`bottom`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    style={{ zIndex: 10 }}
                                >
                                    <Paper>
                                        <TMPopup
                                            isDefault={isDefault}
                                            tm={tm}
                                            name={name}
                                            id={id}
                                            handleClickAway={handleClickAway}
                                            onDelete={openConfirmDeleteHandler}
                                            onClean={openConfirmCleanHandler}
                                            onExport={openExportHandler}
                                            onEditor={openEditorTMHandler}
                                            goToTmEditor={goEditorTM}
                                        />
                                    </Paper>
                                </Popper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Collapse in={isShowMore} timeout="auto" unmountOnExit>
                <Grid item className={classes.expandContent}>
                    <Grid item container spacing={3} wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body1">{t('activate_tm')}</Typography>
                            <Grid item className={classes.expandContentDescription}>
                                <Typography variant="body2">{t('activate_tm_description')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IOSSwitch checked={!isDisabled} onChange={handleTMDisableChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.expandContent}>
                    <Grid item container spacing={3} wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body1">{t('tm_auto_consuming')}</Typography>
                            <Grid item className={classes.expandContentDescription}>
                                <Typography variant="body2">{t('tm_auto_consuming_description')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IOSSwitch checked={!isPauseConsuming} onChange={handleTMPauseConsumingChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.expandContent}>
                    <Grid item container spacing={3} wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body1">{t('tm_fuzzy_match')}</Typography>
                            <Grid item className={classes.expandContentDescription}>
                                <Typography variant="body2">
                                    <Trans
                                        t={t}
                                        i18nKey="tm_fuzzy_match_description"
                                        defaults="Case in-sensitive matching, ignore whitespaces, numbers and special characters. Example the input <span>hello world!</span> would match <span>Hello-World!!!</span> in the TM, but would not match <span>Hello</span>."
                                        components={{
                                            span: <span className={classes.code} />
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <IOSSwitch checked={tmType === TM_TYPES[90]} onChange={handleTmTypeChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.expandContent}>
                    <Grid item container spacing={3} wrap="nowrap" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="body1">{t('tm_translation_status_for_target_cell')}</Typography>
                            <Grid item className={classes.expandContentDescription}>
                                <Typography variant="body2">
                                    {t('tm_translation_status_for_target_cell_description')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <LDBasePortal
                                options={DEPENDENCY_STATUS_OPTIONS}
                                onChange={handleTranslationStatusChange}
                                defaultValue={DEPENDENCY_STATUS_OPTIONS.find(
                                    el => el.value === populateTranslationStatus
                                )}
                                dropdownClassName={classes.dropdownClassName}
                                popperClassName={classes.popperClassName}
                                isMulti={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>

            <Dialog open={openConfirmCleanModal} onClose={closeConfirmCleanHandler}>
                <ConfirmBox
                    title={t('clear_tm_title')}
                    body={t('clear_tm_body')}
                    handleCancel={closeConfirmCleanHandler}
                    onClose={closeConfirmCleanHandler}
                    handleAgreed={onCleanTMClick}
                    agreeLabel={t('clear_tm_agree_button')}
                    colorType={COLOR_TYPES.SECONDARY}
                    isLoading={isCleaning}
                />
            </Dialog>

            <Dialog open={openConfirmDeleteModal} onClose={closeConfirmDeleteHandler}>
                <ConfirmBox
                    title={t('remove_tm_title')}
                    body={t('remove_tm_body')}
                    handleCancel={closeConfirmDeleteHandler}
                    onClose={closeConfirmDeleteHandler}
                    handleAgreed={handleDeleteTM}
                    agreeLabel={t('global_remove')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>

            <Dialog maxWidth="md" open={openExportModal} onClose={closeExportHandler}>
                <ExportTM tmId={id} onClose={closeExportHandler} />
            </Dialog>
            <Dialog maxWidth="md" open={openEditorTMModal} onClose={closeEditorTMHandler}>
                <EditorTM tmId={id} onClose={closeEditorTMHandler} />
            </Dialog>
        </Grid>
    );
}

export default React.memo(TMItem);
