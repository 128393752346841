import React from 'react';

const LQAIconSVG = ({ color = '#F8AD13', ...rest }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
            d="M12.6667 2.00033H9.88C9.6 1.22699 8.86667 0.666992 8 0.666992C7.13333 0.666992 6.4 1.22699 6.12 2.00033H3.33333C2.6 2.00033 2 2.60033 2 3.33366V12.667C2 13.4003 2.6 14.0003 3.33333 14.0003H12.6667C13.4 14.0003 14 13.4003 14 12.667V3.33366C14 2.60033 13.4 2.00033 12.6667 2.00033ZM8 2.00033C8.36667 2.00033 8.66667 2.30033 8.66667 2.66699C8.66667 3.03366 8.36667 3.33366 8 3.33366C7.63333 3.33366 7.33333 3.03366 7.33333 2.66699C7.33333 2.30033 7.63333 2.00033 8 2.00033ZM9.33333 11.3337H4.66667V10.0003H9.33333V11.3337ZM11.3333 8.66699H4.66667V7.33366H11.3333V8.66699ZM11.3333 6.00033H4.66667V4.66699H11.3333V6.00033Z"
            fill={color}
            fillOpacity="0.8"
        />
    </svg>
);

export default LQAIconSVG;
