import request from './request';
import { APP_API_PREFIX_ORGANIZATION } from '../config';

const roleApis = {
    roles: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/roles`,
    role: ({ companyId, roleId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/roles/${roleId}`
};

export async function getRolesApi({ companyId }) {
    const response = await request.get(`${roleApis.roles({ companyId })}`);
    return response.data;
}

export async function createRoleApi({ companyId, role }) {
    const response = await request.post(`${roleApis.roles({ companyId })}`, role);
    return response.data;
}

export async function deleteRoleApi({ companyId, roleId }) {
    const response = await request.delete(roleApis.role({ companyId, roleId }));
    return response.data;
}

export async function updateRoleApi({ companyId, role }) {
    const response = await request.put(roleApis.role({ companyId, roleId: role.id }), role);
    return response.data;
}
