import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import TextareaAutoSize from 'react-autosize-textarea';
import Button from 'components/button/Base';
import PopperMenu from 'components/menus/Popper';
import EmojiBox from 'components/cellComment/Emoji';
import { checkContainId } from 'utils/clickAway';
import { getAvatarUrl } from 'utils/images';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import CommentEmojiIconSVG from 'assets/images/svg/CommentEmojiIconSVG';
import { formatDateFromNow } from 'utils/datetime';
import { addEmojiInText, getCaretPositionWithoutEmoji } from 'utils/emoji';
import { moveCursorToEnd } from 'utils/cursor';
import { isKbEscape, isKbEnter, isKbShiftEnter } from 'utils/keyboard';
import Tooltip from 'components/tooltip/Base';
import CellMenuPopup from './CellMenuPopup';
import { getFriendlyTime } from 'utils/datetime';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { scrollInToView } from 'utils/scroll';

const useStyles = makeStyles(theme => ({
    root: {},

    comment: {
        marginBottom: 20,
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.colors.divider}`
    },

    last: {
        marginBottom: 0,
        borderBottom: `none`
    },
    fullName: {
        fontSize: 14,
        fontWeight: 'bold',
        color: theme.colors.dimGrey
    },
    createdAt: {
        fontSize: 13,
        color: theme.colors.midGrey
    },
    content: {
        fontSize: 14,
        padding: 5,
        marginBottom: props => (props.isOpenEdit ? 12 : 0),
        color: theme.colors.dimGrey,
        lineHeight: `22px`,
        width: '100%',
        resize: `none`,
        border: `none`,
        background: `transparent`,
        '&:focus': {
            outline: 'none'
        }
    },
    small: {
        '& > div': {
            minWidth: 36,
            width: 36,
            minHeight: 36,
            height: 36
        }
    },
    editor: {
        position: 'absolute',
        zIndex: 10,
        top: 20,
        right: 0,
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    },
    arrowDown: {
        cursor: 'pointer'
    },

    box: {
        width: `100%`
    },
    boxHighlight: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        position: 'relative',
        marginTop: 2,
        top: -3,
        left: -5,
        width: '100%'
    },
    center: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        // padding: `0px ${theme.spacing(1)}px  ${theme.spacing(1)}px`,
        position: 'absolute',
        bottom: 5,
        right: 5
    },
    commentPopup: {
        '& .emoji-mart': {
            border: 'none'
        }
    },

    fullWidth: {
        width: '100%'
    },
    avatar: {
        marginRight: SPACING_LIST_NAME_WITH_ICON,
        display: 'flex'
    },
    commentContent: {
        flex: 1
    },
    pointer: {
        cursor: 'pointer'
    }
}));

function CommentItem({
    content,
    id,
    imageUrl,
    fullName,
    createdBy,
    createdAt,
    deleteHandler,
    updateHandler,
    currentUser,
    isThread,
    isLast,
    editedAt,
    t
}) {
    const theme = useTheme();
    const [isOpenThreadMenu, setIsOpenThreadMenu] = React.useState(false);
    const [isOpenEdit, setIsOpenEdit] = React.useState(false);
    const [currentContent, setCurrentContent] = React.useState(content || '');
    const textAreaRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const threadRef = React.useRef();
    const editCommentRef = React.useRef();
    const cursorRef = React.useRef(0);
    const actionRef = React.useRef();
    const classes = useStyles({ isOpenEdit });

    useClickAwaitListener(threadRef, e => {
        handleClickAwayThreadMenu(e);
    });

    useClickAwaitListener(editCommentRef, e => {
        if (!isOpenEdit) return;
        handleClickAwayCommentMenu(e);
    });

    const handleClickAwayCommentMenu = e => {
        if (e && checkContainId(e, 'comment_edit_emoji')) {
            return false;
        }
        setIsOpenEdit(false);
        setCurrentContent(content || '');
    };

    const handleThreadMenuClick = e => {
        setIsOpenThreadMenu(true);
    };

    const handleClickAwayThreadMenu = e => {
        if (e && checkContainId(e, 'comment_edit_emoji')) {
            return false;
        }
        setIsOpenThreadMenu(false);
        setCurrentContent(content || '');
    };

    const handleFocus = React.useCallback(() => {
        const input = textAreaRef.current;
        if (input) {
            input && input.focus();
        }
    }, []);

    const handleKeyUp = e => {
        const startCursor = e.target.selectionStart;
        const caretPos = getCaretPositionWithoutEmoji({ input: e.target, idx: startCursor });
        cursorRef.current = caretPos;
    };

    const handleFirstTimeFocus = e => {
        const startCursor = e.target.selectionStart;
        const caretPos = getCaretPositionWithoutEmoji({ input: e.target, idx: startCursor });
        cursorRef.current = caretPos;
    };

    const handleClick = event => {
        handleFocus();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = e => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const input = textAreaRef.current;
        if (!input) return;
        if (isOpenEdit) {
            if (actionRef.current) {
                scrollInToView(actionRef.current, { block: 'center' });
            }
            setTimeout(() => {
                moveCursorToEnd(input);
                input.focus();
            }, 700);
        } else {
            input.style.height = `${input.scrollHeight}px`;
        }
    }, [isOpenEdit, handleFocus]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            cancelHandler && cancelHandler();
        }
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            e.stopPropagation();
            if (!currentContent) {
                return;
            }
            handleUpdate();
        }
    };

    const editHandler = () => {
        handleClickAwayThreadMenu();
        setIsOpenEdit(true);
    };

    const cancelHandler = e => {
        handleClickAway();
        setCurrentContent(content || '');
        setIsOpenEdit(false);
    };

    const handleValueChange = e => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentContent(e.target.value);
    };

    const handleDelete = () => {
        deleteHandler && deleteHandler({ commentId: id });
        handleClickAwayThreadMenu();
    };

    const handleUpdate = () => {
        handleClickAway();
        updateHandler &&
            updateHandler({
                commentId: id,
                oldContent: content,
                newContent: currentContent,
                editedAt,
                callback: () => {
                    setIsOpenEdit(false);
                }
            });
    };

    const updateTextAtPosition = ({ text, emoji }) => {
        let newText = addEmojiInText({ text, idx: cursorRef.current, emoji });
        cursorRef.current += 1;
        return newText;
    };

    const emojiChange = emoji => {
        const updateText = updateTextAtPosition({ text: currentContent, emoji: emoji?.native || '' });
        setCurrentContent(updateText);
    };

    return (
        <Grid item className={`${classes.comment} ${classes.fullWidth} ${isLast ? classes.last : ''}`}>
            <Grid container direction="column" spacing={2}>
                <Grid item className={classes.fullWidth}>
                    <Grid
                        container
                        className={classes.root}
                        direction="row"
                        wrap="nowrap"
                        alignItems="flex-start"
                        justify="flex-start"
                    >
                        <Grid item className={classes.avatar}>
                            <AvatarBase src={getAvatarUrl(imageUrl || createdBy)} size="small" alt="avatar">
                                <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                            </AvatarBase>
                        </Grid>

                        <Grid item className={classes.commentContent}>
                            <Grid container direction="column">
                                <Grid item container justify="space-between">
                                    <Grid item>
                                        <p className="body1 inline">{fullName}</p>{' '}
                                        <p className="caption inline">{formatDateFromNow(createdAt)}</p>{' '}
                                        {editedAt && (
                                            <Tooltip placement="top" title={getFriendlyTime(editedAt)}>
                                                <p className="caption cursor-pointer inline-block">
                                                    {t('comment_has_edited')}
                                                </p>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {createdBy === currentUser.email ? (
                                            <Grid item style={{ position: 'relative' }}>
                                                <IconMoreActionsSVG
                                                    className={classes.arrowDown}
                                                    color={theme.colors.steel}
                                                    onClick={handleThreadMenuClick}
                                                />
                                                {isOpenThreadMenu && (
                                                    <div ref={threadRef} className={classes.editor}>
                                                        <CellMenuPopup
                                                            isThread={isThread}
                                                            editHandler={editHandler}
                                                            deleteHandler={handleDelete}
                                                            t={t}
                                                        />
                                                    </div>
                                                )}
                                            </Grid>
                                        ) : (
                                            undefined
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid ref={editCommentRef} item style={{ position: 'relative' }}>
                                    <Grid
                                        container
                                        className={`${classes.box} ${isOpenEdit ? classes.boxHighlight : ''}`}
                                    >
                                        <Grid item style={{ width: '100%', position: 'relative' }}>
                                            <TextareaAutoSize
                                                ref={textAreaRef}
                                                className={classes.content}
                                                value={currentContent}
                                                onChange={handleValueChange}
                                                onKeyDown={handleKeyDown}
                                                onFocus={handleFirstTimeFocus}
                                                disabled={!isOpenEdit}
                                                onKeyUp={handleKeyUp}
                                                autoFocus
                                            />
                                        </Grid>
                                        {isOpenEdit && (
                                            <Grid item className={classes.center}>
                                                <CommentEmojiIconSVG
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleClick}
                                                />
                                                {anchorEl && (
                                                    <PopperMenu
                                                        className={classes.commentPopup}
                                                        anchorEl={anchorEl}
                                                        placement={'right-end'}
                                                        id={`comment_edit_emoji`}
                                                    >
                                                        <EmojiBox
                                                            handleClickAway={handleClickAway}
                                                            onChange={emojiChange}
                                                        />
                                                    </PopperMenu>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                    <span ref={actionRef} />
                                    {isOpenEdit && (
                                        <Grid
                                            container
                                            justify="flex-start"
                                            style={{ marginTop: theme.spacing(1), position: 'relative', left: -4 }}
                                        >
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Button
                                                            size="small"
                                                            width={80}
                                                            onClick={cancelHandler}
                                                            variant="outlined"
                                                        >
                                                            {t('global_cancel')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            width={80}
                                                            onClick={handleUpdate}
                                                            size="small"
                                                            variant="contained"
                                                        >
                                                            {t('global_save')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(CommentItem);
