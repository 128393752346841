import React from 'react';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import { useTranslation } from 'react-i18next';
import { useQuickFilters, useQuickSorts } from 'hooks/gridUI';
import { isLDEmpty } from 'utils/object';
import { useDispatch } from 'react-redux';
import * as gridUiActions from 'gridUI/actions';
import { enqueueSnackbar } from 'notifier/actions';

function EmptyQuickFilter() {
    const { t } = useTranslation();
    const quickFilters = useQuickFilters();
    const quickSorts = useQuickSorts();
    const dispatch = useDispatch();

    const isShowClear = React.useMemo(() => {
        return (
            Object.values(quickFilters)?.some(filter => {
                return !isLDEmpty(filter?.value) || !isLDEmpty(filter?.extraFilter) || !isLDEmpty(filter?.extraFilter);
            }) || !isLDEmpty(quickSorts)
        );
    }, [quickFilters, quickSorts]);

    const onClear = React.useCallback(() => {
        dispatch(gridUiActions.clearQuickFilterAndQuickSort());
        dispatch(enqueueSnackbar({ message: 'All filters have been cleared' }));

        dispatch(
            gridUiActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    }, [dispatch]);

    if (!isShowClear) return null;

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <EmptyBoxSVG style={{ width: 252, height: 196 }} />
            <h3 className="prose prose-2xl font-medium">{t(`nothing_matching`)}</h3>
            <p className="body2 text-text-secondary">{t(`no_rows_are_matching_the_active_filter_criteria`)}</p>
            <p className="body2 text-highlight underline cursor-pointer" onClick={onClear}>
                {t(`clear_all_filters_and_sorts`)}
            </p>
        </div>
    );
}

export default React.memo(EmptyQuickFilter);
