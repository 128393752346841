import React from 'react';

function NotReadyForTranslateSVG({ color = '#EB4F5E', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 15.4286C12.5504 15.4286 15.4286 12.5504 15.4286 9C15.4286 5.4496 12.5504 2.57143 9 2.57143C5.4496 2.57143 2.57143 5.4496 2.57143 9C2.57143 12.5504 5.4496 15.4286 9 15.4286ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                fill={color}
            />
            <path
                d="M12.8571 9.00021C12.8571 11.1305 11.1302 12.8574 9 12.8574C6.86975 12.8574 5.14285 11.1305 5.14285 9.00021C5.14285 6.86997 6.86975 5.14307 9 5.14307C11.1302 5.14307 12.8571 6.86997 12.8571 9.00021Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 10.2859C9.71008 10.2859 10.2857 9.71029 10.2857 9.00021C10.2857 8.29013 9.71008 7.71449 9 7.71449C8.28992 7.71449 7.71428 8.29013 7.71428 9.00021C7.71428 9.71029 8.28992 10.2859 9 10.2859ZM9 12.8574C11.1302 12.8574 12.8571 11.1305 12.8571 9.00021C12.8571 6.86997 11.1302 5.14307 9 5.14307C6.86975 5.14307 5.14285 6.86997 5.14285 9.00021C5.14285 11.1305 6.86975 12.8574 9 12.8574Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(NotReadyForTranslateSVG);
