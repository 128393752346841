import React from 'react';

const CheckedContainerSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="white"
            />
            <path
                d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10Z"
                fill="#7869B9"
                stroke="#7869B9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8351 7.34047L8.78989 13.5217C8.73664 13.5761 8.65042 13.5761 8.5973 13.5217L5.16484 10.0118C5.11172 9.95766 5.11172 9.8695 5.16484 9.81506L5.99888 8.96225C6.05213 8.90793 6.13835 8.90793 6.19147 8.96225L8.69372 11.5207L13.8086 6.29074C13.8619 6.23642 13.9479 6.23642 14.0011 6.29074L14.8351 7.14355C14.8883 7.19786 14.8883 7.28589 14.8351 7.34047Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(CheckedContainerSVG);
