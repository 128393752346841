import React from 'react';

function H1IconSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" {...other} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9619 17.2021C13.9619 17.6428 13.6047 18 13.164 18C12.7234 18 12.3662 17.6428 12.3662 17.2021V12.1249H6.8457V17.2022C6.8457 17.6428 6.48849 18 6.04785 18C5.60721 18 5.25 17.6428 5.25 17.2021V6.79785C5.25 6.35721 5.60721 6 6.04785 6C6.48849 6 6.8457 6.35721 6.8457 6.79785V10.7065H12.3662V6.79785C12.3662 6.35721 12.7234 6 13.164 6C13.6047 6 13.9619 6.35721 13.9619 6.79785V17.2021Z"
                fill={color}
            />
            <path
                d="M17.9502 18.2559C18.3848 18.2559 18.6777 17.9629 18.6777 17.5186V11.8252C18.6777 11.3174 18.3652 11 17.8428 11C17.54 11 17.3057 11.0439 16.9639 11.2783L15.5674 12.2451C15.3379 12.4062 15.25 12.5723 15.25 12.7969C15.25 13.1094 15.4697 13.3242 15.7676 13.3242C15.9189 13.3242 16.0215 13.29 16.1582 13.1924L17.1885 12.4746H17.2178V17.5186C17.2178 17.9629 17.5156 18.2559 17.9502 18.2559Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(H1IconSVG);
