import React from 'react';

function RecordPanelEmptyRecordSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="252" height="196" viewBox="0 0 252 196" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="126" cy="165" rx="126" ry="31" fill="url(#paint0_radial)" />
            <path d="M196.31 156.953L107.891 176.997V99.7313L196.31 79.6875V156.953Z" fill="#DCDCED" />
            <path
                opacity="0.46"
                d="M107.891 139.172L199.381 108.622L196.31 79.6875L107.891 99.7313V139.172Z"
                fill="#B9BED8"
            />
            <path d="M108.053 177.003L59.5594 154.372V79.5312L108.214 99.8984L108.053 177.003Z" fill="#EAEAF3" />
            <path d="M96.5758 134.327L53.2552 107.332L61.3374 82.2773L105.79 102.806L96.5758 134.327Z" fill="#D0D7E5" />
            <path d="M108.437 99.8059L59.6105 77.1445L46 106.47L94.8269 129.131L108.437 99.8059Z" fill="#EAEAF3" />
            <path d="M206.171 110.239L115.327 130.93L108.053 99.4092L198.735 78.7188L206.171 110.239Z" fill="#DCDCED" />
            <path d="M147.17 59H147.009L59.7211 77.1041L108.053 99.411L198.573 78.7205L147.17 59Z" fill="#CCCEE3" />
            <path d="M147.17 90.5205L198.573 78.7205L147.17 59V90.5205Z" fill="#BFC2D9" />
            <path
                d="M84.6589 34.9689C85.0607 34.1204 85.41 33.2558 85.7089 32.3771H85.6322C90.8673 16.9893 79.8578 0.816521 63.6045 0.0290511C63.6042 0.0282111 63.6041 0.0273704 63.6038 0.0265304C49.6189 -0.618845 38 10.5942 38 24.4982C38 37.9956 48.9548 48.9772 62.4288 48.9968C66.0836 48.9919 69.5866 48.204 72.8533 46.6523C85.6039 49.1767 84.6095 48.9972 84.9024 48.9972C86.2276 48.9972 87.2175 47.7812 86.9587 46.4856L84.6589 34.9689Z"
                fill="#BFC1D9"
            />
            <path
                d="M62.8218 21.3806L56.7197 15.2785L54.0894 17.9087L60.1916 24.0109L53.2778 30.9246L56.0734 33.7202L62.9871 26.8064L69.0892 32.9086L71.7195 30.2783L65.6173 24.1762L72.005 17.7885L69.2095 14.9929L62.8218 21.3806Z"
                fill="white"
            />
            <circle r="5" transform="matrix(-1 0 0 1 94 56)" fill="#CCCEE3" />
            <defs>
                <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(126 165) rotate(90) scale(31 126)"
                >
                    <stop stopColor="#DCDCED" stopOpacity="0" />
                    <stop offset="1" stopColor="#CCCEE3" stopOpacity="0.21" />
                </radialGradient>
            </defs>
        </svg>
    );
}

export default React.memo(RecordPanelEmptyRecordSVG);
