import * as types from './types';
import { USER_LOGOUT_SUCCESS } from '../auth/types';
import produce from 'immer';

const initialState = {
    selectedGridId: null,
    list: {},
    error: {},
    isShowFirstPopupCreate: null,
    dbIdCreatingGrid: false,
    databaseGrids: {
        sample_database_id: {
            isFetching: false,
            list: null // grid list
        }
    },
    databaseGridViews: {
        sample_database_id: {
            sample_grid_id: {
                isFetching: false,
                list: null // view list
            }
        }
    },
    isCreatingLocalizeGrid: false,
    templates: {
        isFetching: false,
        list: [],
        newestTemplateTime: null
    }
};

export default function Grid(state = initialState, action) {
    switch (action.type) {
        case USER_LOGOUT_SUCCESS:
            return initialState;
        case types.SET_SELECTED_GRID: {
            return {
                ...state,
                selectedGridId: action.payload
            };
        }
        case types.GENERATE_GRIDS_BY_DBID: {
            let { gridListByDbIds } = action.payload;

            return {
                ...state,
                list: gridListByDbIds
            };
        }

        case types.ADD_GRID_AFTER_DATABASE_CREATED: {
            const { database } = action.payload;
            let listCoppied = { ...state.list, [database.id]: database.grids || [] };
            return {
                ...state,
                list: listCoppied
            };
        }

        case types.GET_GRIDS_BY_DBID_SUCCESS: {
            let { dbId, grids } = action.payload;
            return {
                ...state,
                list: { ...state.list, [dbId]: grids || [] },
                error: { ...state.error, [dbId]: '' }
            };
        }
        case types.GET_GRIDS_BY_DBID_FAILED: {
            let { dbId, error } = action.payload;
            return {
                ...state,
                error: { ...state.error, [dbId]: error }
            };
        }

        case types.FETCH_GRID_LIST_BY_DATABASE: {
            const databaseGrids = { ...state.databaseGrids };
            const current = databaseGrids[action.payload] || {};

            databaseGrids[action.payload] = {
                ...current,
                isFetching: true
            };

            return {
                ...state,
                databaseGrids
            };
        }
        case types.FETCH_GRID_LIST_BY_DATABASE_SUCCESS: {
            const { dbId, list } = action.payload;
            const databaseGridsSuc = { ...state.databaseGrids };
            databaseGridsSuc[dbId] = {
                isFetching: false,
                list: list
            };

            return {
                ...state,
                databaseGrids: databaseGridsSuc,
                list: {
                    ...state.list,
                    [dbId]: list
                }
            };
        }

        case types.FETCH_GRID_LIST_BY_DATABASE_FAILED: {
            const { dbId } = action.payload;
            const gridF = state.databaseGrids[dbId] || {};

            return {
                ...state,
                databaseGrids: {
                    ...state.databaseGrids,
                    [dbId]: {
                        isFetching: false,
                        list: gridF.list || []
                    }
                }
            };
        }

        case types.FETCH_GRIDVIEWS_BY_DATABASE: {
            const { dbId, gridId } = action.payload;
            const databaseGridViews = state.databaseGridViews || {};
            const dbGrid = databaseGridViews[dbId] || {};
            const gridView = dbGrid[gridId] || {};

            return {
                ...state,
                databaseGridViews: {
                    ...databaseGridViews,
                    [dbId]: {
                        ...dbGrid,
                        [gridId]: {
                            ...gridView,
                            isFetching: true
                        }
                    }
                }
            };
        }
        case types.FETCH_GRIDVIEWS_BY_DATABASE_SUCCESS: {
            const { dbId, gridId, list } = action.payload;
            const databaseGridViews = state.databaseGridViews || {};
            const dbGrid = databaseGridViews[dbId] || {};

            return {
                ...state,
                databaseGridViews: {
                    ...databaseGridViews,
                    [dbId]: {
                        ...dbGrid,
                        [gridId]: {
                            isFetching: false,
                            list
                        }
                    }
                }
            };
        }
        case types.FETCH_GRIDVIEWS_BY_DATABASE_FAILED: {
            const { dbId, gridId } = action.payload;
            const databaseGridViews = state.databaseGridViews || {};
            const dbGrid = databaseGridViews[dbId] || {};
            const gridView = dbGrid[gridId] || {};

            return {
                ...state,
                databaseGridViews: {
                    ...databaseGridViews,
                    [dbId]: {
                        ...dbGrid,
                        [gridId]: {
                            ...gridView,
                            isFetching: false,
                            list: []
                        }
                    }
                }
            };
        }
        case types.CREATE_GRID: {
            const { dbId, grid } = action.payload;

            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: [grid, ...(state?.list?.[dbId] || [])]
                },
                dbIdCreatingGrid: null,
                isCreatingLocalizeGrid: false
            };
        }

        case types.CREATE_GRID_SUCCESS: {
            const { oldId, newId, dbId, newGrid } = action.payload;
            const grids = [...(state?.list?.[dbId] || [])];
            let founded = grids.find(item => item.id === oldId);
            if (founded) {
                founded.id = newId;
                founded.defaultAccessViewId = newGrid.defaultAccessViewId;
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: grids
                }
            };
        }

        case types.CREATE_GRID_FAILED: {
            const { oldId, dbId, error } = action.payload;
            const grids = [...(state?.list?.[dbId] || [])];
            let newGrids = grids.filter(item => item.id !== oldId);
            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: newGrids
                },
                error: { ...state.error, [oldId]: error },
                dbIdCreatingGrid: null
            };
        }

        case types.CREATE_GRID_MESSAGE_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                dbIdCreatingGrid: null,
                error
            };
        }

        case types.DELETE_GRID: {
            const { dbId, gridId } = action.payload;
            const gridList = state?.list?.[dbId] || [];
            const grids = produce(gridList, draft => {
                const index = draft.findIndex(grid => grid?.id === gridId);
                if (index !== -1) draft[index].isDeleted = true;
            });

            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: grids
                }
            };
        }

        case types.DELETE_GRID_SUCCESS: {
            const { dbId, gridId } = action.payload;
            const grids = [...(state?.list?.[dbId] || [])].filter(grid => grid.id !== gridId);
            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: grids
                }
            };
        }

        case types.DELETE_GRID_FAILED: {
            const { dbId, gridId, error } = action.payload;
            const gridList = state?.list?.[dbId] || [];

            const grids = produce(gridList, draft => {
                const index = draft.findIndex(grid => grid?.id === gridId);
                if (index !== -1) draft[index].isDeleted = false;
            });

            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: grids
                },
                error: { ...state.error, [dbId]: error }
            };
        }

        case types.UPDATE_GRID: {
            const { dbId, gridId, newGrid } = action.payload;
            const { list } = state;
            const grids = [...list[dbId]];
            const newGrids = grids.map(col => {
                if (col.id !== gridId) return col;
                return { ...col, ...newGrid };
            });

            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: newGrids
                }
            };
        }

        case types.UPDATE_GRID_SUCCESS: {
            return state;
        }

        case types.UPDATE_GRID_FAILED: {
            const { dbId, gridId, oldGrid, error } = action.payload;
            const grids = [...(state?.list?.[dbId] || [])];
            const newGrids = grids.map(col => {
                if (col.id !== gridId) return col;
                return { ...col, ...oldGrid };
            });

            return {
                ...state,
                list: {
                    ...state.list,
                    [dbId]: newGrids
                },
                error: { ...state.error, [dbId]: error }
            };
        }

        case types.TURN_OFF_SHOW_FIRST_POP_UP_CREATE_GRID: {
            return {
                ...state,
                isShowFirstPopupCreate: null,
                dbIdCreatingGrid: null
            };
        }

        case types.TURN_ON_SHOW_FIRST_POP_UP_CREATE_GRID: {
            const { gridId } = action.payload;
            return {
                ...state,
                isShowFirstPopupCreate: gridId
            };
        }

        case types.TURN_ON_FIRST_CREATE_SKELETON: {
            const { dbIdCreatingGrid } = action.payload;
            return {
                ...state,
                dbIdCreatingGrid
            };
        }

        case types.UPDATE_GRID_STATUS: {
            const { dbId, gridId, status } = action.payload;
            const { list } = state;
            const newList = produce(list, draft => {
                const dbGrids = draft[dbId] || [];
                const newDbGrids = dbGrids.map(grid => {
                    if (grid.id === gridId) {
                        grid.status = status;
                    }
                    return grid;
                });
                draft[dbId] = newDbGrids;
            });

            return {
                ...state,
                list: newList
            };
        }

        case types.CREATE_LOCALIZATION_GRID: {
            return {
                ...state,
                isCreatingLocalizeGrid: true
            };
        }

        case types.CREATE_LOCALIZATION_GRID_FAILED: {
            const { error } = action.payload;
            return {
                ...state,
                isCreatingLocalizeGrid: false,
                error
            };
        }

        case types.FETCH_GRID_TEMPLATES: {
            const current = state.templates;
            return {
                ...state,
                templates: {
                    ...current,
                    isFetching: true
                }
            };
        }

        case types.FETCH_GRID_TEMPLATES_FAILED: {
            const current = state.templates;
            return {
                ...state,
                templates: {
                    ...current,
                    isFetching: false,
                    list: [],
                    newestTemplateTime: null
                }
            };
        }

        case types.FETCH_GRID_TEMPLATES_SUCCESS: {
            const { templates } = action?.payload || [];
            const { templates: templatesStore } = state;
            let newestTime = null;

            templates.forEach(template => {
                const templateCreatedTime = new Date(template.createdTime).getTime();
                if (!newestTime || newestTime < templateCreatedTime) {
                    newestTime = templateCreatedTime;
                }
            });

            const newTemplates = produce(templatesStore, draft => {
                draft.list = templates || [];
                draft.newestTemplateTime = newestTime;
                draft.isFetching = false;
            });

            return {
                ...state,
                templates: newTemplates
            };
        }

        case types.SAVE_GRID_AS_TEMPLATE: {
            const { template } = action?.payload;
            const { templates } = state;

            return {
                ...state,
                templates: {
                    ...templates,
                    list: [...state?.templates?.list, template],
                    newestTemplateTime: new Date(template.createdTime).getTime()
                }
            };
        }

        case types.UPDATE_TEMPLATE_STATUS: {
            const { id, status } = action?.payload;
            const { templates } = state;

            const newTemplates = produce(templates, draft => {
                draft.list = draft.list?.map(template => {
                    if (template?.id === id) {
                        template.status = status;
                    }
                    return template;
                });

                return draft;
            });

            return {
                ...state,
                templates: newTemplates
            };
        }

        case types.REMOVE_GRID_TEMPLATE: {
            const { id } = action?.payload;
            const { templates } = state;

            const newTemplates = produce(templates, draft => {
                draft.list = draft.list?.filter(template => template?.id !== id);
                return draft;
            });

            return {
                ...state,
                templates: newTemplates
            };
        }

        case types.UPDATE_GRID_TEMPLATE: {
            const { id, newTemplate } = action?.payload;
            const { templates } = state;

            const newTemplates = produce(templates, draft => {
                draft.list = draft.list?.map(template => {
                    if (template?.id === id) {
                        return {
                            ...template,
                            ...newTemplate
                        };
                    }

                    return template;
                });

                return draft;
            });

            return {
                ...state,
                templates: newTemplates
            };
        }

        default:
            return state;
    }
}
