import React from 'react';
import Button from 'components/button/Base';
import { DISABLED_OPACITY } from 'const/style';

function AddFilterButton({ isReadOnly, t, addAndFilter }) {
    return (
        <Button
            width={120}
            style={{
                pointerEvents: isReadOnly ? 'none' : '',
                opacity: isReadOnly ? DISABLED_OPACITY : 1
            }}
            size="small"
            onClick={addAndFilter}
            variant="outlined"
            autoFocus
        >
            {t(`view_filter_add_button`)}
        </Button>
    );
}
export default React.memo(AddFilterButton);
