import React from 'react';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import SubtractSVG from 'assets/images/svg/SubtractSVG';
import Tooltip from 'components/tooltip/Base';
import { useQuickFilters, useQuickSorts } from 'hooks/gridUI';
import { useTranslation } from 'react-i18next';
import { isLDEmpty } from 'utils/object';
import { useDispatch } from 'react-redux';
import * as gridUiActions from 'gridUI/actions';
import { enqueueSnackbar } from 'notifier/actions';

function IndexClearFilterHeader() {
    const theme = useTheme();
    const quickFilters = useQuickFilters();
    const quickSorts = useQuickSorts();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isShowClear = React.useMemo(() => {
        return (
            Object.values(quickFilters)?.some(filter => {
                return !isLDEmpty(filter?.value) || !isLDEmpty(filter?.extraFilter) || !isLDEmpty(filter?.extraFilter);
            }) || !isLDEmpty(quickSorts)
        );
    }, [quickFilters, quickSorts]);

    const onClear = React.useCallback(() => {
        dispatch(gridUiActions.clearQuickFilterAndQuickSort());
        dispatch(enqueueSnackbar({ message: 'All filters have been cleared' }));
        dispatch(
            gridUiActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    }, [dispatch]);

    if (!isShowClear) return null;

    return (
        <Grid className={'h-full'} justify="center" alignItems="center" container>
            <Tooltip placement="bottom-start" title={t(`clear_all_filters_and_sorts`)}>
                <Grid item>
                    <IconButton
                        onClick={onClear}
                        size="small"
                        style={{ background: theme.colors.white, cursor: 'pointer' }}
                    >
                        <SubtractSVG />
                    </IconButton>
                </Grid>
            </Tooltip>
        </Grid>
    );
}

export default React.memo(IndexClearFilterHeader);
