import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridUIActions from 'gridUI/actions';
import ViewCellInfo from './ViewCellInfo';
import {
    TEXT_LINE_HEIGHT,
    MAX_ROW_RESIZE,
    CELL_PADDING,
    AGG_HEIGHT,
    GLOBAL_FILTER_HEIGHT,
    DEFAULT_COLUMN_WIDTH,
    BORDER_HIGHLIGHT,
    ADDITIONAL_SETTING_STATUS,
    LOCALIZATION_FONT
} from 'const/gridUI';
import { HEADER_HEIGHT } from 'const';
import { moveCaretAtEnd } from 'utils/cursor';
import {
    isKbEscape,
    isKbTab,
    isKbShiftTab,
    isKbEnter,
    isKbShiftEnter,
    isKbCtrlEnter,
    isKbUndo,
    isKbCtrlShiftC,
    isKbF9,
    isKbShiftF9
} from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import TextareaAutosize from 'react-autosize-textarea';
import { useSourceTextAndSourceLangueColumns } from 'hooks/gridUI/column';
import { useDefaultTm, useTMSuggestion } from 'hooks/gridUI/tm';
import { Grid, Divider, Tabs, Tab } from '@material-ui/core';
import ListItem from 'components/list/Item';
import Spinner from 'components/spinner/Base';
import UpdateIconSVG from 'assets/images/svg/UpdateIconSVG';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { moveCursorToEnd } from 'utils/cursor';
import { getViewport } from './calculatePosition';
import { getCellBackgroundByHex } from 'utils/color';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import Popper from 'components/menus/Popper';
import TMIconSVG from 'assets/images/svg/TMIconSVG';
import TBIconSVG from 'assets/images/svg/TBIconSVG';
import ApplyIconSVG from 'assets/images/svg/ApplyIconSVG';
import Tooltip from 'components/tooltip/Base';
import {
    useDataFromTBTargetList,
    useTokenDetection,
    usePredefinedTokens,
    useIsShowTag,
    useTokenSuggestions,
    useIsChildDependencyByColumnId,
    useCurrentGrid,
    useIsParentDependencyByColumnId,
    useCurrentViewId,
    useIsOpenRecordHistory
} from 'hooks/gridUI';
import SwitchIconSVG from 'assets/images/svg/SwitchIconSVG';
import CopySourceSVG from 'assets/images/svg/CopySourceSVG';
import { useParams } from 'react-router-dom';
import TokenEditor from 'components/tag';
import { serializeTag } from 'components/formula/serialize';
import { Transforms, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { generateTags } from 'utils/gridUI/tag';
import { removeArrayInArray } from 'utils/object';
import { useIsShowAutoQA } from 'hooks/gridUI/autoQA';
import ButtonBase from 'components/button/Base';
import { liveQAApi } from 'services/autoQA';
import debounce from 'lodash/debounce';
import LQATargetHighlighter from 'components/lqa/LQATargetHighlighter';
import { TB_TERM_STATUS } from 'const/tb';
import { upperFirst } from 'lodash';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import classNames from 'classnames';
import { isMac } from 'utils/os';

const MIN_WIDTH = 200;
const MIN_HEIGHT = 200;

function SuggestionBox({
    texts,
    style,
    selectedText,
    value,
    handleUpdate,
    handleAdd,
    handleTextChange,
    isEditReverseMode,
    t,
    defaultTMPausedConsuming
}) {
    const theme = useTheme();
    const isShowUpdate = React.useMemo(() => {
        return value && !texts?.includes(value) && selectedText;
    }, [texts, value, selectedText]);

    const isShowAdd = React.useMemo(() => {
        return value && !texts?.includes(value);
    }, [texts, value]);

    if (!texts)
        return (
            <Grid
                container
                style={{
                    ...style,
                    marginTop: theme.spacing(2),
                    paddingTop: theme.spacing(4),
                    paddingBottom: theme.spacing(4)
                }}
                justify="center"
                alignItems="center"
            >
                <Spinner size={18} thick={3} />
            </Grid>
        );

    return (
        <Grid
            style={{
                ...style,
                width: isEditReverseMode ? 'auto' : '100%',
                minWidth: DEFAULT_COLUMN_WIDTH
            }}
            container
            direction="column"
            wrap="nowrap"
            className={`max-w-[524px] max-h-[300px] overflow-y-auto rounded mt-2`}
        >
            {texts?.length === 0 && !value && (
                <Grid item className={`!h-[36px] px-5`} container alignItems="center">
                    <p className="caption">{t(`cell_edit_tm_no_suggestion`)}</p>
                </Grid>
            )}
            {texts?.map((text, index) => {
                return (
                    <ListItem
                        className={'group py-0 px-3'}
                        icon={<TMIconSVG />}
                        isSelected={text === selectedText}
                        onClick={() => handleTextChange(text)}
                        name={text}
                        key={index}
                        moreIcon={
                            text === selectedText ? null : (
                                <Tooltip title="Apply this TM">
                                    <Grid item className="hidden group-hover:flex">
                                        <ApplyIconSVG />
                                    </Grid>
                                </Tooltip>
                            )
                        }
                        useDOMPurify={false}
                    />
                );
            })}
            {texts?.length !== 0 && isShowUpdate && isShowAdd && (
                <Grid item className={'my-3 mx-0'}>
                    <Divider />
                </Grid>
            )}

            {isShowUpdate && (
                <ListItem
                    icon={<UpdateIconSVG />}
                    onClick={handleUpdate}
                    name={`Update <strong>to the current one</strong>`}
                    moreIcon={!defaultTMPausedConsuming ? <p className="caption">Enter</p> : undefined}
                />
            )}

            {isShowAdd && (
                <ListItem
                    icon={<AddIconSVG />}
                    onClick={handleAdd}
                    name={!selectedText ? `Add alternative "${value}" to TM` : `Add alternative to <strong>TM</strong>`}
                    useDOMPurify={!selectedText ? false : true}
                    moreIcon={
                        !defaultTMPausedConsuming && selectedText ? <p className="caption">⌘-Enter</p> : undefined
                    }
                />
            )}
        </Grid>
    );
}

const SuggestionBoxMemo = React.memo(SuggestionBox);

export const TooltipTerm = React.memo(({ term, children, placement = 'right-start', arrow }) => {
    const areaRef = React.useRef(null);

    const tags = React.useMemo(() => {
        const arr = [];
        if (term.status === TB_TERM_STATUS.APPROVED) {
            arr.push('Approved');
        }
        if (term.preferred) {
            arr.push('Preferred');
        }
        if (term.caseSensitive) {
            arr.push('Case sensitive');
        }
        if (term.forbidden) {
            arr.push('Forbidden');
        }
        return arr;
    }, [term]);

    const attributes = React.useMemo(() => {
        return [term.partOfSpeech, term.gender, term.number, term.type]
            .filter(el => !!el)
            .map(el =>
                upperFirst(
                    el
                        .split('_')
                        .join(' ')
                        .toLocaleLowerCase()
                )
            );
    }, [term]);

    const isEmpty = React.useMemo(() => {
        return (
            !tags.length &&
            !attributes.length &&
            !term.domain?.trim() &&
            !term.context?.trim() &&
            !term.definition?.trim() &&
            !term.note?.trim()
        );
    }, [term, attributes.length, tags.length]);

    return (
        <Tooltip
            interactive
            classes={{
                tooltip:
                    '!max-w-[356px] !w-[356px] !m-0 !bg-white !shadow !z-[9999] !font-semibold !p-0 text-text-primary',
                arrow: '!text-white'
            }}
            placement={placement}
            arrow={arrow}
            title={
                isEmpty ? (
                    <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        alignItems="center"
                        justifyContent="center"
                        className={'h-[206px]'}
                        spacing={2}
                    >
                        <Grid item>
                            <EmptyBoxSVG />
                        </Grid>
                        <Grid item>
                            <p className="body1">No details found for this glossary</p>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item className={'max-h-[330px] p-3 overflow-x-hidden overflow-y-auto'}>
                        <Grid item>
                            <h5
                                className={classNames('body2 font-normal', {
                                    'line-through': term.forbidden
                                })}
                            >
                                {term.text}
                            </h5>
                        </Grid>
                        {tags.length > 0 && (
                            <Grid item container wrap="nowrap" className={'!mt-1.5'} spacing={2}>
                                {tags.map(tag => (
                                    <Grid item key={tag}>
                                        <Grid
                                            item
                                            className={classNames(
                                                `bg-[#7869B9]/10 border border-[#7869B9]/30 rounded !py-0.5 !px-1`,
                                                {
                                                    'bg-[#EB4F5E]/10 border-[#EB4F5E]/30': tag === 'Forbidden'
                                                }
                                            )}
                                        >
                                            <span
                                                className={classNames('text-text-primary font-normal text-sm', {
                                                    '!text-error': tag === 'Forbidden'
                                                })}
                                            >
                                                {tag}
                                            </span>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {attributes.length > 0 && (
                            <Grid item container alignItems="center" className={'!mt-2'}>
                                {attributes.map((attr, idx) => (
                                    <React.Fragment key={attr}>
                                        <Grid item className={'text-grey-mid'}>
                                            {attr}
                                        </Grid>
                                        {idx < attributes.length - 1 && (
                                            <Grid item className={'my-0 mx-1 w-1 h-1 rounded-full bg-grey-border'} />
                                        )}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        )}
                        <Divider className={'!my-3 !mx-0'} />
                        {(term.domain || term.context?.trim() || term.definition?.trim() || term.note?.trim()) && (
                            <Grid item container direction="column" wrap="nowrap" spacing={2}>
                                {term.domain && (
                                    <Grid item>
                                        <Grid container direction="column" wrap="nowrap" spacing={1}>
                                            <Grid item className={'text-grey-mid'}>
                                                Domain:
                                            </Grid>
                                            <Grid item>{upperFirst(term.domain.toLocaleLowerCase())}</Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {term.context?.trim() && (
                                    <Grid item>
                                        <Grid container direction="column" wrap="nowrap" spacing={1}>
                                            <Grid item className={'text-grey-mid'}>
                                                Context:
                                            </Grid>
                                            <Grid item>{term.context}</Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {term.definition?.trim() && (
                                    <Grid item>
                                        <Grid container direction="column" wrap="nowrap" spacing={1}>
                                            <Grid item className={'text-grey-mid'}>
                                                Definition:
                                            </Grid>
                                            <Grid item>{term.definition}</Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {term.note?.trim() && (
                                    <Grid item>
                                        <Grid container direction="column" wrap="nowrap" spacing={1}>
                                            <Grid item className={'text-grey-mid'}>
                                                Note:
                                            </Grid>
                                            <Grid item>{term.note}</Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )
            }
        >
            <Grid ref={areaRef} item>
                {children}
            </Grid>
        </Tooltip>
    );
});

const TBBox = ({ t, texts, style, onClickTB }) => {
    const theme = useTheme();

    if (!texts)
        return (
            <Grid
                container
                style={{
                    ...style,
                    marginTop: theme.spacing(2),
                    paddingTop: theme.spacing(4),
                    paddingBottom: theme.spacing(4)
                }}
                justify="center"
                alignItems="center"
            >
                <Spinner size={18} thick={3} />
            </Grid>
        );
    return (
        <Grid
            className={`max-w-[524px] max-h-[300px] overflow-y-auto rounded mt-2`}
            container
            direction="column"
            wrap="nowrap"
        >
            {texts?.length === 0 && (
                <Grid item className={`!h-[36px] px-5`} container alignItems="center">
                    <p className="caption">{t(`cell_edit_tm_no_suggestion`)}</p>
                </Grid>
            )}
            {(texts || []).map((text, idx) => {
                const isForbidden = text.term?.forbidden;
                return (
                    <TooltipTerm key={idx} term={text.term}>
                        <Grid
                            key={idx}
                            item
                            className={'group py-1.5 px-3 hover:bg-background'}
                            onDoubleClick={() => (!isForbidden ? onClickTB(text.langTargetText) : {})}
                        >
                            <Grid container alignItems="center" wrap="nowrap">
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <TBIconSVG />
                                        </Grid>
                                        <Grid item>
                                            <p className="body2">{text.langSourceText}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container alignItems="baseline" spacing={2} wrap="nowrap">
                                        <Grid item>
                                            <Grid item className={'w-1 h-1 rounded-full bg-text-primary'} />
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={2}
                                            wrap="nowrap"
                                            justify="space-between"
                                        >
                                            <Grid item className={'break-all'}>
                                                <p
                                                    className={classNames('body2', {
                                                        'line-through': isForbidden
                                                    })}
                                                >
                                                    {text.langTargetText}
                                                </p>
                                            </Grid>
                                            {!isForbidden && (
                                                <Tooltip title="Apply this term">
                                                    <Grid item className={`opacity-0 group-hover:opacity-100`}>
                                                        <ApplyIconSVG
                                                            width="20"
                                                            height="20"
                                                            style={{ verticalAlign: 'text-bottom' }}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                onClickTB(text.langTargetText);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TooltipTerm>
                );
            })}
        </Grid>
    );
};

const TBBoxMemo = React.memo(TBBox);

const TagBox = ({ t, texts, style, onClickTB }) => {
    return (
        <Grid
            className={`max-w-[524px] max-h-[300px] overflow-y-auto rounded mt-2`}
            container
            direction="column"
            wrap="nowrap"
        >
            {texts?.length === 0 && (
                <Grid item className={`!h-[36px] px-5`} container alignItems="center">
                    <p className="caption">{t(`cell_edit_tm_no_suggestion`)}</p>
                </Grid>
            )}
            {(texts || []).map((text, idx) => (
                <Grid
                    key={idx}
                    item
                    className={'group py-0 px-3 hover:bg-background'}
                    onDoubleClick={() => onClickTB(text)}
                >
                    <div className="flex items-center justify-between flex-nowrap mb-1">
                        <p className="body2 bg-token font-medium rounded-sm py-[1px] px-1 text-white">{text}</p>
                        <Tooltip title="Apply this tag">
                            <div className={`opacity-0 group-hover:cursor-pointer group-hover:opacity-100`}>
                                <ApplyIconSVG
                                    width="20"
                                    height="20"
                                    style={{ verticalAlign: 'text-bottom', cursor: 'pointer' }}
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onClickTB(text);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};

const TagBoxMemo = React.memo(TagBox);

function TranslationEdit({
    columnId,
    column,
    top,
    value: cellValue,
    rowId,
    width,
    height,
    rowIndex,
    columnIndex,
    background,
    isTmDisabled,
    fillColorBlindness,
    originalValue,
    data,
    t
}) {
    const dispatch = useDispatch();
    const [selectedText, setSelectedText] = React.useState(undefined);
    const [tabValue, setTabValue] = React.useState('tm');
    const [showReTranslatePopper, setShowReTranslatePopper] = React.useState(false);
    // const tabClasses = useTabStyles();
    const theme = useTheme();
    const inputRef = useRef();
    const editorRef = useRef();
    const isEnterAlready = useRef(false);
    const rootRef = React.useRef();
    const allowMoveCursorAtEnd = useRef(true);
    const oldValue = useRef(undefined);
    const { workspaceId, branchId, dbId } = useParams();
    const tokenDetection = useTokenDetection({ gridId: branchId, dbId });
    const predefinedTokens = usePredefinedTokens();
    // eslint-disable-next-line no-unused-vars
    const [viewPortWidth, viewPortHeight] = getViewport();
    const isShowTag = useIsShowTag({ gridId: branchId, dbId });
    const suggestions = useTokenSuggestions({ dbId, gridId: branchId, columnId, rowId });
    const isChildDependency = useIsChildDependencyByColumnId(columnId);
    const isParentDependency = useIsParentDependencyByColumnId(columnId);
    const isShowAutoQA = useIsShowAutoQA();
    const currentGrid = useCurrentGrid();
    const currentViewId = useCurrentViewId();
    // const validations = useCellValidations({ rowId, columnId });
    const isOpenRecordHistory = useIsOpenRecordHistory();
    const defaultTM = useDefaultTm();

    const editorSelection = useRef({
        anchor: {
            offset: 0,
            path: [0, 0]
        },
        focus: {
            offset: 0,
            path: [0, 0]
        }
    });

    const { sourceData, currentColumnLanguage } = useSourceTextAndSourceLangueColumns({ rowId, columnId });

    const isHavingSourceText = React.useMemo(() => {
        return !!sourceData?.sourceText;
    }, [sourceData]);

    const isShowEditor = React.useMemo(() => {
        return isShowAutoQA || isShowTag;
    }, [isShowAutoQA, isShowTag]);

    const isTmEditing = React.useMemo(() => {
        return !isTmDisabled && isHavingSourceText;
    }, [isHavingSourceText, isTmDisabled]);

    const [rawText, setRawText] = useState(
        isShowEditor ? [{ type: 'paragraph', children: [{ text: cellValue ? cellValue : '' }] }] : cellValue || ''
    );

    const [liveValidations, setLiveValidations] = useState([]);

    useEffect(() => {
        if (isShowAutoQA) setLiveValidations(data?.metadata?.validations || []);
    }, [isShowAutoQA, data]);

    const fetchLiveValidations = useCallback(
        debounce(async text => {
            try {
                const body = {
                    recordId: rowId,
                    columnId,
                    targetText: serializeTag(text),
                    completed: false
                };

                const errors = await liveQAApi({ viewId: currentViewId, body });
                setLiveValidations(errors?.filter(err => !err.insource) || []);
            } catch (error) {
                console.log(error.message);
            }
        }, 500),
        []
    );

    const handleCellChange = text => {
        setRawText(text);
    };

    const handleLQATextChange = React.useCallback(
        value => {
            setRawText(value);
            if (isShowAutoQA) fetchLiveValidations(value);
            if (editorRef?.current?.selection) {
                editorSelection.current = editorRef?.current.selection;
            }
        },
        [fetchLiveValidations, isShowAutoQA]
    );

    const value = React.useMemo(() => {
        if (!isShowEditor) return rawText;
        return serializeTag(rawText);
    }, [isShowEditor, rawText]);

    const isGridAskDependency = React.useMemo(() => {
        return (
            currentGrid?.dependencySetting?.statusUpdateOnSourceChangeOption === ADDITIONAL_SETTING_STATUS.ASK &&
            isParentDependency
        );
    }, [currentGrid, isParentDependency]);

    const handleShowReTranslatePopper = React.useCallback(() => {
        if (isGridAskDependency) {
            if (isShowEditor && editorRef?.current) {
                ReactEditor.blur(editorRef.current);
            }
            setShowReTranslatePopper(true);
        }
    }, [isGridAskDependency, isShowEditor]);

    useEffect(() => {
        let textarea = inputRef.current;
        if (textarea) {
            textarea.scrollTop = textarea.scrollHeight;
            textarea.focus({ preventScroll: true });
        }
    }, []);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[column?.group];
    }, [column]);

    const languageDetail = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[column?.group];
    }, [column]);

    const isRtlDirection = React.useMemo(() => {
        return languageDetail?.rtl;
    }, [languageDetail]);

    const isEditReverseMode = React.useMemo(() => {
        return top + MIN_HEIGHT > viewPortHeight - AGG_HEIGHT - HEADER_HEIGHT - GLOBAL_FILTER_HEIGHT;
    }, [viewPortHeight, top]);

    const texts = useTMSuggestion({ rowId, columnId });
    const tbTexts = useDataFromTBTargetList({
        langSource: sourceData?.sourceLang,
        langTarget: currentColumnLanguage,
        rowId
    });

    let style = React.useMemo(() => {
        if (isEditReverseMode) {
            return {
                position: 'absolute',
                left: 0,
                bottom: 0
            };
        }
        return {};
    }, [isEditReverseMode]);

    const parentTags = React.useMemo(() => {
        return suggestions?.map(s => s?.value);
    }, [suggestions]);

    const _tags = React.useMemo(() => {
        return generateTags({ tokenDetection, predefinedTokens, text: value });
    }, [value, tokenDetection, predefinedTokens]);

    const missingTags = React.useMemo(() => {
        if (!parentTags?.length) return [];
        return [...new Set([...removeArrayInArray(parentTags, _tags)])];
    }, [parentTags, _tags]);

    const showPopper = React.useMemo(() => {
        return (
            (texts?.length || tbTexts?.length || missingTags?.length || (isTmEditing && value)) &&
            inputRef.current &&
            rootRef.current
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [texts, tbTexts, value, isTmEditing]);

    const activeShiftTab = React.useMemo(() => {
        return !!tbTexts?.length && isTmEditing;
    }, [isTmEditing, tbTexts]);

    const tabs = React.useMemo(() => {
        let initialTabs = [
            {
                id: 'tm',
                tab: (
                    <Tab
                        classes={{
                            root: '!min-h-[36px] !min-w-[113px]'
                        }}
                        label={`Translation Memory${!texts ? '' : ` (${texts?.length || 0})`}`}
                        value={'tm'}
                    />
                )
            },
            {
                id: 'tb',
                tab: (
                    <Tab
                        classes={{
                            root: '!min-h-[36px] !min-w-[113px]'
                        }}
                        label={`Glossary ${`(${tbTexts?.length || 0})`}`}
                        value={'tb'}
                    />
                )
            },
            {
                id: 'ms',
                tab: (
                    <Tab
                        classes={{
                            root: '!min-h-[36px] !min-w-[113px]'
                        }}
                        label={`${t('missing_tags')} ${`(${missingTags?.length || 0})`}`}
                        value={'ms'}
                    />
                )
            }
        ];

        if (!isTmEditing) {
            initialTabs = initialTabs?.filter(tag => tag?.id !== 'tm');
        }

        if (!tbTexts?.length) {
            initialTabs = initialTabs?.filter(tag => tag?.id !== 'tb');
        }

        if (!missingTags?.length) {
            initialTabs = initialTabs?.filter(tag => tag?.id !== 'ms');
        }

        return initialTabs;
    }, [missingTags, texts, tbTexts, isTmEditing, t]);

    const toggleTab = React.useCallback(() => {
        const tabIndex = tabs?.findIndex(tab => tab?.id === tabValue);
        const nextTabIndex = tabIndex + 1 > tabs?.length - 1 ? 0 : tabIndex + 1;
        setTabValue(tabs?.[nextTabIndex]?.id);
    }, [tabValue, tabs]);

    React.useEffect(() => {
        const handler = e => {
            if (isKbShiftTab(e)) {
                e.preventDefault();
                toggleTab();
            }
        };
        if (activeShiftTab) {
            document.addEventListener('keydown', handler, false);
        }
        return () => {
            document.removeEventListener('keydown', handler, false);
        };
    }, [activeShiftTab, toggleTab]);

    React.useEffect(() => {
        if (texts?.length && texts?.includes(originalValue)) {
            setSelectedText(originalValue);
        } else {
            setSelectedText();
        }
    }, [texts, originalValue]);

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    useEffect(() => {
        if (isTmEditing) {
            dispatch(
                gridUIActions.searchTranslation({
                    rowId,
                    columnId,
                    targetLang: currentColumnLanguage,
                    body: {
                        lang: sourceData?.sourceLang,
                        text: sourceData?.sourceText,
                        targetLangs: [currentColumnLanguage]
                    },
                    successCallback: () => {
                        console.log('query success');
                    },
                    errorCallback: () => {
                        console.log('query failed');
                    }
                })
            );
        } else {
            setTabValue(1);
        }
        if (sourceData?.sourceText) {
            dispatch(
                gridUIActions.searchInParagraph({
                    sourceRowId: rowId,
                    sourceColumnId: sourceData.columnId,
                    targetLang: currentColumnLanguage,
                    body: {
                        projectId: workspaceId,
                        databaseId: dbId,
                        paragraph: sourceData?.sourceText,
                        sourceTermLang: sourceData?.sourceLang
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        let textarea = inputRef.current;
        if (textarea) {
            textarea.scrollTop = textarea.scrollHeight;
        }
    }, []);

    const isSameData = React.useCallback(() => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    }, [originalValue, value]);

    const updateTextsToTM = React.useCallback(() => {
        if (selectedText && value) {
            const newTexts = [value, ...texts?.filter(text => text !== selectedText)];
            dispatch(
                gridUIActions.addTranslation({
                    rowId,
                    columnId,
                    value,
                    body: {
                        sourceLang: sourceData?.sourceLang,
                        translations: [
                            {
                                lang: sourceData?.sourceLang,
                                texts: [sourceData?.sourceText]
                            },
                            {
                                lang: currentColumnLanguage,
                                texts: newTexts
                            }
                        ]
                    },
                    successCallback: () => {
                        console.log('update text to TM success');
                    },
                    errorCallback: () => {
                        console.log('failed to update text to TM');
                    }
                })
            );
        }
    }, [columnId, currentColumnLanguage, dispatch, rowId, selectedText, sourceData, texts, value]);

    const addTextToTM = React.useCallback(
        (alternative = false) => {
            if (!texts?.includes(value) && value) {
                dispatch(
                    gridUIActions.addTranslation({
                        rowId,
                        columnId,
                        value,
                        body: {
                            sourceLang: sourceData?.sourceLang,
                            alternative,
                            translations: [
                                {
                                    lang: sourceData?.sourceLang,
                                    texts: [sourceData?.sourceText]
                                },
                                {
                                    lang: currentColumnLanguage,
                                    texts: [value]
                                }
                            ]
                        },
                        successCallback: () => {
                            console.log('add text to TM success');
                        },
                        errorCallback: () => {
                            console.log('failed to add text to TM');
                        }
                    })
                );
            }
        },
        [columnId, dispatch, rowId, currentColumnLanguage, sourceData, texts, value]
    );

    const handleFocus = React.useCallback(() => {
        const input = inputRef.current;
        if (input) {
            moveCursorToEnd(input);
            input && input.focus();
        }
    }, []);

    const handleClickAway = React.useCallback(
        e => {
            if (
                checkContainId(e, 'translation-edit-popper') ||
                checkContainId(e, 'copy-source') ||
                checkContainId(e, 'confirm-retranslate-popper') ||
                checkContainId(e, 'lqa-suggestion')
            ) {
                return;
            }

            let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);

            if (isSameData()) {
                return dispatch(gridUIActions.cancelCellEdit());
            }
            if (!isEnterAlready.current && !isSameData()) {
                if (isGridAskDependency) {
                    handleShowReTranslatePopper();
                } else {
                    dispatch(
                        gridUIActions.cellClickAway({
                            isClickYourSelf,
                            value,
                            rowId,
                            columnId,
                            validations: liveValidations
                        })
                    );
                }
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                    }
                }
            }
        },
        [
            addTextToTM,
            value,
            rowId,
            columnId,
            dispatch,
            isHavingSourceText,
            isSameData,
            selectedText,
            updateTextsToTM,
            isTmEditing,
            isGridAskDependency,
            handleShowReTranslatePopper,
            liveValidations,
            defaultTM
        ]
    );

    const handleCopySource = React.useCallback(() => {
        if (isShowEditor && editorRef?.current) {
            ReactEditor.blur(editorRef.current);
        }
        const redoValue = isShowEditor
            ? [
                  {
                      type: 'paragraph',
                      children: [{ text: sourceData.sourceText ? sourceData.sourceText : '' }]
                  }
              ]
            : sourceData.sourceText;
        const undoValue = isShowEditor
            ? [
                  {
                      type: 'paragraph',
                      children: [{ text: value ? value : '' }]
                  }
              ]
            : value;
        oldValue.current = undoValue;
        setRawText(redoValue);

        setTimeout(() => {
            if (isShowEditor && editorRef?.current) {
                const editor = editorRef?.current;
                Transforms.select(editor, Editor.end(editor, []));
                ReactEditor.focus(editor);
                return;
            }

            let textarea = inputRef.current;
            if (textarea) {
                textarea.focus({ preventScroll: true });
            }
        }, 100);
    }, [sourceData, value, isShowEditor]);

    const handleCellClickAwayAndGoNextRow = React.useCallback(() => {
        isEnterAlready.current = true;
        if (isGridAskDependency) {
            handleShowReTranslatePopper();
        } else {
            dispatch(
                gridUIActions.cellClickAwayAndGoNextRow({
                    value,
                    rowId,
                    rowIndex,
                    columnId,
                    columnIndex,
                    validations: liveValidations
                })
            );
        }
    }, [
        dispatch,
        value,
        rowId,
        rowIndex,
        columnId,
        columnIndex,
        isGridAskDependency,
        handleShowReTranslatePopper,
        liveValidations
    ]);

    const handleCellClickAwayAndGoNextColumn = React.useCallback(() => {
        isEnterAlready.current = true;
        if (isGridAskDependency) {
            handleShowReTranslatePopper();
        } else {
            dispatch(
                gridUIActions.cellClickAwayAndGoNextColumn({
                    value,
                    rowId,
                    rowIndex,
                    columnId,
                    columnIndex
                })
            );
        }
    }, [dispatch, value, rowId, rowIndex, columnId, columnIndex, isGridAskDependency, handleShowReTranslatePopper]);

    const handleCellClickAwayAndGoPreviousColumn = React.useCallback(() => {
        isEnterAlready.current = true;
        if (isGridAskDependency) {
            handleShowReTranslatePopper();
        } else {
            dispatch(
                gridUIActions.cellClickAwayAndGoPreviousColumn({
                    value,
                    rowId,
                    rowIndex,
                    columnId,
                    columnIndex
                })
            );
        }
    }, [dispatch, value, rowId, rowIndex, columnId, columnIndex, isGridAskDependency, handleShowReTranslatePopper]);

    const handleKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                dispatch(gridUIActions.cancelCellEdit());
            }

            if (isKbF9(e) && !isKbShiftF9(e) && isShowTag) {
                if (missingTags?.length && editorRef) {
                    const editor = editorRef?.current;
                    ReactEditor.focus(editor);
                    Transforms.insertText(editor, ` ${missingTags?.[0]}`);
                }
            }

            if (isKbShiftF9(e) && isShowTag) {
                if (missingTags?.length && editorRef) {
                    for (const tag of missingTags) {
                        const editor = editorRef?.current;
                        ReactEditor.focus(editor);
                        Transforms.insertText(editor, ` ${tag}`);
                    }
                }
            }

            if (isKbUndo(e)) {
                const editor = editorRef?.current;
                if (editor) {
                    ReactEditor.blur(editor);
                }
                setTimeout(() => {
                    const editor = editorRef?.current;
                    if (editor) {
                        Transforms.select(editor, Editor.end(editor, []));
                        ReactEditor.focus(editor);
                    }
                }, 100);
                if (typeof oldValue.current !== 'undefined') {
                    e.preventDefault();
                    setRawText(oldValue.current);
                    oldValue.current = undefined;
                    return;
                }
            }

            if (isKbCtrlShiftC(e)) {
                e.preventDefault();
                if (sourceData?.sourceText) {
                    handleCopySource();
                }
                return;
            }

            if (isKbCtrlEnter(e)) {
                e.preventDefault();
                if (value === originalValue) {
                    dispatch(gridUIActions.cancelCellEdit());
                    dispatch(gridUIActions.moveCellToNextRow({ rowIndex, columnIndex }));
                    return;
                }
                handleCellClickAwayAndGoNextRow();
                if (isHavingSourceText) {
                    addTextToTM(true);
                    return;
                }
            }

            if (isKbEnter(e) && !isKbShiftEnter(e)) {
                e.preventDefault();
                if (value === originalValue) {
                    dispatch(gridUIActions.cancelCellEdit());
                    dispatch(gridUIActions.moveCellToNextRow({ rowIndex, columnIndex }));
                    return;
                }
                handleCellClickAwayAndGoNextRow();
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                        return;
                    }
                }
            }

            if (isKbTab(e)) {
                e.preventDefault();
                if (isSameData()) {
                    dispatch(gridUIActions.cancelCellEdit());

                    dispatch(gridUIActions.moveCellToNextColumn({ rowIndex, columnIndex }));

                    return;
                }
                handleCellClickAwayAndGoNextColumn();
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                    }
                }
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                if (activeShiftTab) {
                    return;
                }
                if (isSameData()) {
                    dispatch(gridUIActions.cancelCellEdit());
                    dispatch(gridUIActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));

                    return;
                }
                handleCellClickAwayAndGoPreviousColumn();
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                    }
                }
            }
        },
        [
            originalValue,
            rowIndex,
            selectedText,
            value,
            updateTextsToTM,
            activeShiftTab,
            addTextToTM,
            dispatch,
            columnIndex,
            isSameData,
            isHavingSourceText,
            isTmEditing,
            handleCopySource,
            sourceData,
            isShowTag,
            missingTags,
            handleCellClickAwayAndGoNextRow,
            handleCellClickAwayAndGoNextColumn,
            handleCellClickAwayAndGoPreviousColumn,
            defaultTM
        ]
    );

    const handleTextChange = React.useCallback(
        text => {
            oldValue.current = undefined;
            if (inputRef.current && !isShowEditor) {
                setRawText(text);
            } else if (editorRef) {
                if (editorRef?.current) {
                    const editor = editorRef?.current;
                    setRawText([{ type: 'paragraph', children: [{ text }] }]);

                    setTimeout(() => {
                        Transforms.select(editor, Editor.end(editor, []));
                        ReactEditor.focus(editor);
                    }, 0);
                }
            }
            setSelectedText(text);
            handleFocus();
        },
        [handleFocus, isShowEditor]
    );

    const handleAdd = React.useCallback(() => {
        addTextToTM(true);
        isEnterAlready.current = true;
        dispatch(
            gridUIActions.cellClickAwayAndGoNextRow({
                value,
                rowId,
                rowIndex,
                columnId,
                columnIndex
            })
        );
    }, [addTextToTM, columnId, columnIndex, rowId, rowIndex, dispatch, value]);

    const handleUpdate = React.useCallback(() => {
        updateTextsToTM();
        isEnterAlready.current = true;
        dispatch(
            gridUIActions.cellClickAwayAndGoNextRow({
                value,
                rowId,
                rowIndex,
                columnId,
                columnIndex
            })
        );
    }, [value, rowId, rowIndex, columnId, columnIndex, dispatch, updateTextsToTM]);

    const filteredTexts = React.useMemo(() => {
        if (defaultTM?.isPausedConsuming) {
            return texts;
        }
        const filteredValues = texts?.filter(text => {
            return text?.toLowerCase()?.includes(value?.toLowerCase());
        });
        if (!selectedText) return filteredValues;
        if (filteredValues?.includes(selectedText)) {
            return filteredValues;
        } else {
            return filteredValues?.concat(selectedText);
        }
    }, [texts, value, selectedText, defaultTM]);

    const onChangeTabValue = React.useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    const onClickTB = React.useCallback(
        text => {
            if (inputRef.current && !isShowEditor) {
                let { selectionStart, selectionEnd, value } = inputRef.current;
                let textBeforeCursorPosition = value.substring(0, selectionStart);
                let textAfterCursorPosition = value.substring(selectionEnd, value.length);
                let newValue = textBeforeCursorPosition + text + textAfterCursorPosition;
                setRawText(newValue);
                allowMoveCursorAtEnd.current = false;

                setTimeout(() => {
                    inputRef.current.selectionStart = inputRef.current.selectionEnd = selectionStart + text.length;
                    inputRef.current.focus();
                }, 0);
            } else if (editorRef) {
                if (editorRef?.current) {
                    const editor = editorRef?.current;
                    Transforms.select(editor, editorSelection.current);
                    ReactEditor.focus(editor);
                    Transforms.insertText(editor, text);
                    editorSelection.current = editorRef?.current.selection;
                }
            }
        },
        [isShowEditor]
    );

    const onClickTag = React.useCallback(
        text => {
            onClickTB(text);
            if (missingTags?.length === 1) {
                setTabValue('tm');
            }
        },
        [missingTags, onClickTB]
    );

    const onFocus = React.useCallback(e => {
        const moveEnd = allowMoveCursorAtEnd.current;
        allowMoveCursorAtEnd.current = true;
        if (moveEnd) {
            moveCaretAtEnd(e);
        }
    }, []);

    const handleClickNo = React.useCallback(() => {
        dispatch(
            gridUIActions.cellClickAway({
                isClickYourSelf: true,
                value,
                rowId,
                columnId,
                skipStatusUpdateOnSourceChange: true
            })
        );
    }, [dispatch, value, rowId, columnId]);

    const handleClickYes = React.useCallback(() => {
        dispatch(
            gridUIActions.cellClickAway({
                isClickYourSelf: true,
                value,
                rowId,
                columnId,
                skipStatusUpdateOnSourceChange: false
            })
        );
    }, [dispatch, value, rowId, columnId]);

    const handleKeydownConfirm = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (isKbEnter(e)) {
                handleClickYes();
            }
            if (isKbEscape(e)) {
                handleClickNo();
            }
        },
        [handleClickYes, handleClickNo]
    );

    useEffect(() => {
        if (showReTranslatePopper && isParentDependency) {
            setTimeout(() => {
                window.addEventListener('keydown', handleKeydownConfirm, false);
            }, 0);
        }
        return () => {
            window.removeEventListener('keydown', handleKeydownConfirm, false);
        };
    }, [showReTranslatePopper, isParentDependency, handleKeydownConfirm]);

    const handleReplaceSuggestion = React.useCallback((e, validation, suggestion) => {
        const editor = editorRef?.current;

        ReactEditor.focus(editor);
        Transforms.insertText(editor, suggestion || '', {
            at: { anchor: { path: [0, 0], offset: validation.start }, focus: { path: [0, 0], offset: validation.end } }
        });
        ReactEditor.focus(editor);
    }, []);

    const editorRender = React.useMemo(() => {
        const editorContent = (
            <div>
                {isShowAutoQA ? (
                    <LQATargetHighlighter
                        value={rawText}
                        dir={isRtlDirection ? 'rtl' : 'ltr'}
                        onChange={handleLQATextChange}
                        placeholder=""
                        predefinedTokens={predefinedTokens}
                        tokenDetection={tokenDetection}
                        className={classNames(`editor cell-editor`, fontJP)}
                        autoFocus
                        inputRef={editorRef}
                        isChildDependency={isChildDependency}
                        validations={liveValidations}
                        onKeyDown={handleKeyDown}
                        rowId={rowId}
                        columnId={columnId}
                        onReplace={handleReplaceSuggestion}
                        style={{
                            width,
                            padding: CELL_PADDING,
                            minWidth: MIN_WIDTH,
                            border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                            height,
                            overflowY: 'auto',
                            minHeight: MIN_HEIGHT,
                            maxHeight: Math.max(MIN_HEIGHT, height),
                            lineHeight: `${TEXT_LINE_HEIGHT}px`,
                            background: background
                                ? fillColorBlindness === 'BLIND'
                                    ? `${background} url(${getCellBackgroundByHex(background)})`
                                    : background
                                : theme.colors.white,
                            ...style
                        }}
                        rootStyle={
                            isEditReverseMode
                                ? {
                                      position: 'absolute'
                                  }
                                : {}
                        }
                        onBlur={() => {
                            if (editorRef?.current?.selection) {
                                editorSelection.current = editorRef?.current.selection;
                            }
                        }}
                    />
                ) : (
                    <TokenEditor
                        value={rawText}
                        suggestions={[]}
                        parentTags={parentTags}
                        suggestionZIndex={1302}
                        dir={isRtlDirection ? 'rtl' : 'ltr'}
                        onChange={handleLQATextChange}
                        placeholder=""
                        predefinedTokens={predefinedTokens}
                        tokenDetection={tokenDetection}
                        className={classNames(`editor cell-editor`, fontJP)}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        inputRef={editorRef}
                        isChildDependency={isChildDependency}
                        style={{
                            width,
                            padding: CELL_PADDING,
                            minWidth: MIN_WIDTH,
                            border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                            height,
                            overflowY: 'auto',
                            minHeight: MIN_HEIGHT,
                            maxHeight: Math.max(MIN_HEIGHT, height),
                            lineHeight: `${TEXT_LINE_HEIGHT}px`,
                            background: background
                                ? fillColorBlindness === 'BLIND'
                                    ? `${background} url(${getCellBackgroundByHex(background)})`
                                    : background
                                : theme.colors.white,
                            ...style
                        }}
                        readOnly={showReTranslatePopper}
                        rootStyle={
                            isEditReverseMode
                                ? {
                                      position: 'absolute'
                                  }
                                : {}
                        }
                        onBlur={() => {
                            if (editorRef?.current?.selection) {
                                editorSelection.current = editorRef?.current.selection;
                            }
                        }}
                    />
                )}
                <div
                    ref={inputRef}
                    style={{
                        position: 'absolute',
                        width,
                        height,
                        minHeight: MIN_HEIGHT,
                        maxHeight: Math.max(MIN_HEIGHT, height),
                        left: 0,
                        zIndex: -1,
                        ...(isEditReverseMode ? { bottom: 0 } : { top: 0 })
                    }}
                ></div>
            </div>
        );
        return !isShowEditor ? (
            <TextareaAutosize
                rows={4}
                ref={inputRef}
                className={classNames(
                    `w-full h-full px-3.5 py-1 overflow-hidden text-ellipsis text-sm !resize focus:outline-none focus:border focus:border-brand-main cell-editor`,
                    fontJP
                )}
                style={{
                    width,
                    minWidth: MIN_WIDTH,
                    border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                    height,
                    minHeight: MIN_HEIGHT,
                    maxHeight: isEditReverseMode ? MIN_HEIGHT : MAX_ROW_RESIZE,
                    lineHeight: `${TEXT_LINE_HEIGHT}px`,
                    background: isShowAutoQA
                        ? 'transparent'
                        : background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white,
                    ...style
                }}
                dir={isRtlDirection ? 'rtl' : 'ltr'}
                onKeyDown={handleKeyDown}
                onFocus={onFocus}
                onChange={e => handleCellChange(e.target.value)}
                value={rawText || ''}
                readOnly={showReTranslatePopper}
            />
        ) : (
            editorContent
        );
    }, [
        background,
        columnId,
        handleKeyDown,
        fillColorBlindness,
        height,
        handleLQATextChange,
        handleReplaceSuggestion,
        isRtlDirection,
        isShowAutoQA,
        isShowEditor,
        liveValidations,
        onFocus,
        parentTags,
        isEditReverseMode,
        predefinedTokens,
        rowId,
        rawText,
        style,
        isChildDependency,
        theme,
        tokenDetection,
        width,
        showReTranslatePopper,
        fontJP
    ]);

    return (
        <div ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            {editorRender}
            {sourceData?.sourceText && (
                <Tooltip
                    title={
                        <div className="flex items-center flex-row gap-2">
                            <p className="text-light">{t('copy_source')}: </p>
                            <p className={'body2 py-0.5 px-1 bg-white rounded !text-text-primary'}>
                                {!isMac() ? 'Ctrl' : 'Cmd'}
                            </p>
                            <p className={'body2 py-0.5 px-1 bg-white rounded !text-text-primary'}>Shift</p>
                            <p className={'body2 py-0.5 px-1 bg-white rounded !text-text-primary'}>C</p>
                        </div>
                    }
                >
                    <div
                        id="copy-source"
                        className={classNames(
                            `absolute left-0.5 bottom-0.5 cursor-pointer z-[1] w-3.5 h-3.5 bg-grey-border flex items-center justify-center`,
                            {
                                'bottom-[7.02px]': !isShowEditor && !isEditReverseMode
                            }
                        )}
                        onClick={handleCopySource}
                    >
                        <CopySourceSVG className="w-3 h-3" />
                    </div>
                </Tooltip>
            )}

            {showPopper && (
                <Popper
                    id="translation-edit-popper"
                    className={`my-0 mx-1 z-[1300] ${fontJP}`}
                    paperClassName={'pt-0 rounded'}
                    open
                    anchorEl={inputRef.current}
                    placement="right-start"
                    // container={rootRef.current}
                >
                    <Tabs
                        classes={{ root: 'w-[524px] min-h-[36px] h-full' }}
                        value={tabValue}
                        onChange={onChangeTabValue}
                    >
                        {tabs?.map(tab => tab?.tab)}
                    </Tabs>
                    {activeShiftTab && (
                        <Grid
                            id="translation-edit-shift-tab"
                            container
                            className={
                                'absolute !w-fit py-0 px-2 bg-grey-pale rounded top-[9px] right-3 cursor-pointer'
                            }
                            spacing={2}
                            alignItems="center"
                            wrap={false}
                            onClick={toggleTab}
                        >
                            <Grid item>
                                <SwitchIconSVG className="align-middle" />
                            </Grid>
                            <Grid item>
                                <p className={'body2 text-brand-main'}>Shift + Tab</p>
                            </Grid>
                        </Grid>
                    )}
                    {tabValue === 'tm' && (
                        <SuggestionBoxMemo
                            value={value}
                            handleUpdate={handleUpdate}
                            handleAdd={handleAdd}
                            handleTextChange={handleTextChange}
                            selectedText={selectedText}
                            texts={filteredTexts}
                            t={t}
                            defaultTMPausedConsuming={defaultTM.isPausedConsuming}
                        />
                    )}
                    {tabValue === 'tb' && (
                        <TBBoxMemo
                            t={t}
                            texts={tbTexts}
                            // style={{
                            //     ...style,
                            //     bottom: MIN_HEIGHT - height
                            // }}
                            onClickTB={onClickTB}
                        />
                    )}

                    {tabValue === 'ms' && (
                        <TagBoxMemo
                            t={t}
                            texts={missingTags}
                            // style={{
                            //     ...style,
                            //     bottom: MIN_HEIGHT - height
                            // }}
                            onClickTB={onClickTag}
                        />
                    )}
                </Popper>
            )}
            {isGridAskDependency && !isSameData() && <div id="retranslation-processing" />}
            {showReTranslatePopper && isParentDependency && (
                <Popper
                    id="confirm-retranslate-popper"
                    className={'!top-0 z-[1300]'}
                    paperClassName={'w-[395px] bg-text-primary opacity-90 py-3 px-3.5 rounded'}
                    open
                    anchorEl={inputRef.current}
                    placement={isOpenRecordHistory ? 'top-start' : 'bottom-start'}
                    container={isOpenRecordHistory ? null : rootRef.current}
                >
                    <Grid item>
                        <p className="body1">Confirm Re-translation</p>
                    </Grid>
                    <Grid item className={'mt-2 mb-[33px]'}>
                        <p className="body2">
                            Change in Source column detected. Would you like to mark all Dependencies as{' '}
                            <b>Out-of-date</b>?
                        </p>
                    </Grid>

                    <div className="flex justify-end items-center gap-2">
                        <ButtonBase variant="outlined" width={64} onClick={handleClickNo}>
                            No
                        </ButtonBase>
                        <ButtonBase variant="contained" width={64} onClick={handleClickYes}>
                            Yes
                        </ButtonBase>
                    </div>
                </Popper>
            )}
        </div>
    );
}

export default React.memo(TranslationEdit);
