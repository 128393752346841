import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';

import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SPACING_LIST_NAME_WITH_ICON,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    DISABLED_OPACITY
} from 'const/style';
import DOMPurify from 'dompurify';
import OverflowItem from 'components/textTruncated/OverflowItem';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        minHeight: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: props => (props.noPadding ? 0 : SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT),
        paddingRight: props => (props.noPadding ? 0 : SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT),
        '&:hover': {
            background: props => props?.hoverColor || theme.colors.hoverItem
        }
    },
    selected: {
        background: props => props?.hoverColor || theme.colors.hoverItem
    },
    icon: {
        display: 'flex',
        marginRight: SPACING_LIST_NAME_WITH_ICON
    },
    expand: {
        flex: 1,
        width: `calc(100% - 14px)`
    },
    textContent: {
        flex: 1,
        // overflow: 'hidden',
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: theme.colors.dimGrey
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        '& .MuiGrid-item': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    flex: {
        display: 'flex'
    },
    rightIcon: {
        marginLeft: SPACING_LIST_NAME_WITH_ICON
    },
    nameSelected: {
        color: theme.palette.primary.main
    }
}));

function ListItem({
    isSelected,
    className,
    name,
    description,
    icon: Icon,
    moreIcon: MoreIcon,
    selectedIcon: SelectedIcon,
    noPadding = false,
    disabled,
    useDOMPurify = true,
    hoverColor,
    isShowSelectedIcon = true,
    ellipsis,
    ...rest
}) {
    const classes = useStyles({ noPadding, hoverColor });

    return (
        <Grid
            container
            direction="column"
            justify="center"
            wrap="nowrap"
            className={`${classes.root} ${className} ${isSelected ? classes.selected : ''} ${
                disabled ? classes.disabled : ''
            }`}
            {...rest}
        >
            <Grid item>
                <Grid container alignItems="center" justify="space-between" direction="row" wrap="nowrap">
                    <Grid item className={classes.expand}>
                        <Grid container alignItems="center" wrap="nowrap">
                            {Icon && (
                                <Grid item className={classes.icon}>
                                    {typeof Icon === 'function' ? Icon() : Icon}
                                </Grid>
                            )}
                            {typeof name === 'string' && useDOMPurify ? (
                                <>
                                    <Grid
                                        item
                                        className={`${classes.textContent} ${
                                            isSelected ? classes.nameSelected : ''
                                        } textContent ${ellipsis && classes.ellipsis}`}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(name, { USE_PROFILES: { html: true } })
                                        }}
                                    />
                                </>
                            ) : (
                                <Grid
                                    item
                                    className={`${classes.textContent} ${
                                        isSelected ? classes.nameSelected : ''
                                    } textContent ${ellipsis && classes.ellipsis}`}
                                >
                                    {name}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} alignItems="center" wrap="nowrap" justify="flex-end">
                            {isSelected && isShowSelectedIcon && (
                                <Grid item className={`${classes.flex} ${classes.rightIcon}`}>
                                    {SelectedIcon ? SelectedIcon : <CheckIconSVG />}
                                </Grid>
                            )}
                            {MoreIcon && (
                                <Grid item className={`${classes.flex} more-icon`}>
                                    {MoreIcon}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {description && (
                <Grid item>
                    <Typography variant="caption" component="div">
                        {description}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

const WrapperListItem = props => {
    return (
        <OverflowItem truncated={true} placement="right" title={props.name}>
            <ListItem {...props} />
        </OverflowItem>
    );
};

export default React.memo(WrapperListItem);
