import React, { useRef, useEffect, useState } from 'react';
import Tooltip from 'components/tooltip/Base';
import { makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = ({ maxLines }) =>
    makeStyles(theme => ({
        typography: {
            ...(maxLines > 0 ? theme.ellipsis(maxLines) : {})
        }
    }));

const OverflowTypography = ({ maxLines = 0, children, tooltipClassName, variant, className, ...rest }) => {
    const classes = useStyles({ maxLines })();
    const [hoverStatus, setHover] = useState(false);
    const textElementRef = useRef();

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth ||
            textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        []
    );

    return (
        <Tooltip className={tooltipClassName} title={children} interactive disableHoverListener={!hoverStatus}>
            <Typography
                ref={textElementRef}
                variant={variant}
                className={classnames(classes.typography, className)}
                {...rest}
            >
                {children}
            </Typography>
        </Tooltip>
    );
};

export default React.memo(OverflowTypography);
