import React, { useRef, useEffect, useState } from 'react';
import Tooltip from 'components/tooltip/Base';
import { makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = ({ truncated, maxLines }) =>
    makeStyles(theme => ({
        typography: {
            ...(truncated ? theme.ellipsis(maxLines) : {})
        }
    }));

const OverflowTypography = ({
    truncated,
    maxLines = 1,
    children,
    tooltipClassName,
    variant,
    className,
    ellipsis,
    ...rest
}) => {
    const classes = useStyles({ maxLines, truncated })();
    const [hoverStatus, setHover] = useState(false);
    const textElementRef = useRef();

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth ||
            textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
        setHover(compare);
    };

    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        []
    );

    return (
        <Tooltip
            maxWidth={false}
            className={tooltipClassName}
            title={rest?.title || children}
            interactive
            disableHoverListener={!hoverStatus}
            placement={rest?.placement}
            open={ellipsis && ellipsis}
        >
            <Typography
                ref={textElementRef}
                variant={variant}
                className={classnames(classes.typography, className)}
                {...rest}
            >
                {children}
            </Typography>
        </Tooltip>
    );
};

export default React.memo(OverflowTypography);
