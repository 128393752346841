import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import * as roleAction from '../actions/role';
import Button from 'components/button/Base';
import { scrollInToView } from 'utils/scroll';
import { useCanCustomUserRole } from 'hooks/payment';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import LimitPopup from 'payment/components/popups/LimitPopup';
import ModalBase from 'components/modal/Base';
import { useHistory } from 'react-router-dom';
import { sendManualTrack } from 'tracker';

function RoleButtonAdd({ onRoleCreated, disabled }) {
    const theme = useTheme();
    const history = useHistory();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const canCustomUserRole = useCanCustomUserRole();
    const [isOpenLimitModal, setIsOpenLimitModal] = React.useState(false);

    const handleOpenLimitModal = () => setIsOpenLimitModal(true);
    const handleCloseLimitModal = e => {
        stopPropagation(e);
        setIsOpenLimitModal(false);
    };

    const createRoleHandler = () => {
        if (!canCustomUserRole) {
            handleOpenLimitModal();
            return;
        }
        sendManualTrack({ type: `Add Company Role` });
        dispatch(
            roleAction.createRole({
                successCallback: createdRole => {
                    onRoleCreated(createdRole);
                    const node = document.getElementById('roleListBottom');
                    scrollInToView(node);
                },
                errorCallback: () => {
                    console.log('create Role Failed');
                }
            })
        );
    };

    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item style={{ width: '100%' }} onClick={createRoleHandler}>
                <Button variant="outlined" fullWidth size="medium" disabled={disabled}>
                    {!canCustomUserRole ? <UpgradeSVG style={{ marginRight: theme.spacing(2) }} /> : '+'}{' '}
                    {!isTablet && 'Add'} Role
                </Button>
            </Grid>
            <ModalBase open={isOpenLimitModal} handleClose={handleCloseLimitModal}>
                <LimitPopup
                    title={`Upgrade to use Custom Roles`}
                    message={`Custom User Roles are available on the Enterprise plan. Please upgrade to create more Custom Roles and assign them to members`}
                    onCancel={handleCloseLimitModal}
                    onUpgrade={handleUpgrade}
                    onClose={handleCloseLimitModal}
                />
            </ModalBase>
        </Grid>
    );
}

RoleButtonAdd.propTypes = {
    buttonClickHandler: PropTypes.func
};
export default RoleButtonAdd;
