import React from 'react';
import GridUIDoc from 'doc/gridUI';
import { Grid } from '@material-ui/core';
import { RIGHT_SIDEBAR_ACTION_TYPE } from 'const/gridUI';
import GridHistory from 'gridUI/history';
import GridShareView from 'gridUI/shareView';
import GridBranchView from 'gridUI/branch';
// import WebHook from 'gridUI/webHook';
import TranslationOverview from 'gridUI/translationOverview';
import Comments from 'gridUI/comments';
import MemSource from 'gridUI/memSource';
// import GridTypeSetting from 'gridUI/gridTypeSetting';
import MemoQ from 'gridUI/memoQ';
import LQA from 'gridUI/lqa';
import LqaModels from 'gridUI/lqa/LqaModels';
import AutoQA from 'gridUI/autoQA';
import SyncConnection from 'gridUI/syncConnection';
import GridBackup from 'gridUI/backup';
import Automations from './automations';

function ComingSoon({ t }) {
    return (
        <div className="flex justify-center mt-5">
            <p className="body1">{t(`global_coming_soon`)}</p>
        </div>
    );
}

function renderRightSidebarContent({
    action,
    handleClose,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    t,
    isShareViewLink
}) {
    switch (action) {
        case RIGHT_SIDEBAR_ACTION_TYPE.CODE:
            return (
                <GridUIDoc
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.HISTORY:
            return (
                <GridHistory
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                    isShareViewLink={isShareViewLink}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.SHARE_VIEW:
            return (
                <GridShareView
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.BRANCH:
            return (
                <GridBranchView
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.CHAT:
            return (
                <ComingSoon
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        // case RIGHT_SIDEBAR_ACTION_TYPE.WEB_HOOK:
        //     return (
        //         <WebHook
        //             handleClose={handleClose}
        //             workspaceId={workspaceId}
        //             gridId={gridId}
        //             viewId={viewId}
        //             dbId={dbId}
        //             branchId={branchId}
        //             t={t}
        //         />
        //     );
        case RIGHT_SIDEBAR_ACTION_TYPE.AUTOMATIONS:
            return (
                <Automations
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.TRANSLATION_OVERVIEW:
            return (
                <TranslationOverview
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.COMMENT:
            return (
                <Comments
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.MEM_SOURCE:
            return (
                <MemSource
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );

        // case RIGHT_SIDEBAR_ACTION_TYPE.GRID_TYPE_SETTING:
        //     return (
        //         <GridTypeSetting
        //             handleClose={handleClose}
        //             workspaceId={workspaceId}
        //             gridId={gridId}
        //             viewId={viewId}
        //             dbId={dbId}
        //             branchId={branchId}
        //             t={t}
        //         />
        //     );

        case RIGHT_SIDEBAR_ACTION_TYPE.MEMO_Q:
            return (
                <MemoQ
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );

        case RIGHT_SIDEBAR_ACTION_TYPE.LQA:
            return (
                <LQA
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );

        case RIGHT_SIDEBAR_ACTION_TYPE.LQA_MODAL:
            return (
                <LqaModels
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );

        case RIGHT_SIDEBAR_ACTION_TYPE.AUTO_QA:
            return (
                <AutoQA
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );

        case RIGHT_SIDEBAR_ACTION_TYPE.SYNC_CONNECTION:
            return (
                <SyncConnection
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    t={t}
                />
            );
        case RIGHT_SIDEBAR_ACTION_TYPE.BACKUP_GRID:
            return (
                <GridBackup
                    handleClose={handleClose}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                    isShareViewLink={isShareViewLink}
                />
            );
        default:
            return null;
    }
}

function RenderRightSidebarContent({
    className,
    action,
    width,
    handleClose,
    gridId,
    branchId,
    workspaceId,
    dbId,
    viewId,
    t,
    onOpenJsonExport,
    isShareViewLink
}) {
    return (
        <Grid container className={className} style={{ width, height: '100%' }}>
            {renderRightSidebarContent({
                action,
                handleClose,
                gridId,
                branchId,
                workspaceId,
                dbId,
                viewId,
                t,
                onOpenJsonExport,
                isShareViewLink
            })}
        </Grid>
    );
}

export default React.memo(RenderRightSidebarContent);
