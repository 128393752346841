import React, { useState, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/button/Base';
import Spinner from 'components/spinner/Base';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    },
    content: {
        width: 320,
        background: theme.colors.white,
        position: 'relative',
        borderRadius: 6,
        padding: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
            padding: 0,
            margin: 0,
            overflow: 'hidden'
        },
        overflowY: 'auto'
    },
    cropper: {
        position: 'relative'
    },
    full: {
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: 130,
        height: 130,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

let editor = null;
function AvatarCropper({ src, shape, onSave }) {
    const classes = useStyles();
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [scale, setScale] = useState(0.5);
    const [isLoading, setIsLoading] = useState(false);

    const setEditorRef = editorRef => {
        editor = editorRef;
    };

    const onSaveClick = useCallback(() => {
        setIsLoading(true);

        setTimeout(() => {
            const canvas = editor.getImage().toDataURL();
            fetch(canvas)
                .then(res => res.blob())
                .then(blob => {
                    setIsLoading(false);
                    onSave(blob);
                });
        }, 10);
    }, [onSave]);

    const handlePositionChange = useCallback(position => {
        setPosition(position);
    }, []);

    return (
        <div className={classes.root}>
            <Grid
                className={classes.content}
                container
                direction="column"
                justify="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item container direction="column" justify="center" alignItems="stretch" spacing={3}>
                    <Grid item className={classes.cropper}>
                        <div style={{ position: 'relative' }} className={classes.full}>
                            <AvatarEditor
                                ref={setEditorRef}
                                scale={parseFloat(scale)}
                                width={184}
                                height={184}
                                position={position}
                                borderRadius={shape === 'round' ? 184 / 2 : 0}
                                onPositionChange={handlePositionChange}
                                image={src}
                                disableCanvasRotation={true}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Slider value={scale} min={0.5} max={3} step={0.1} onChange={(e, scale) => setScale(scale)} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        className={`${classes.full} ${isLoading ? classes.disabled : ''}`}
                        onClick={onSaveClick}
                        variant="contained"
                    >
                        Save
                        {isLoading && (
                            <Grid item className={classes.spinnerWrapper}>
                                <Spinner size={18} thick={3} />
                            </Grid>
                        )}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default React.memo(AvatarCropper);
