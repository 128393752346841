import React, { useCallback, useMemo, useState } from 'react';
import Tabs from 'components/tabs/Tabs';
import Tab from 'components/tabs/Tab';
import { useGridTypeDetail } from 'hooks/grid';
import { GRID_TYPE_SETTING, SHOW_GRID_TYPES } from 'const/gridUI';
import { Grid } from '@material-ui/core';
import ExportLocalization from '.';
import LocalizationJsonExport from './LocalizationJsonExport';
import LocalizationPoExport from './LocalizationPoExport';
import JSONFILESVG from 'assets/images/svg/files/JSONFILESVG';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContentCustom from 'components/dialog/DialogContent';
import POFILESVG from 'assets/images/svg/files/POFILESVG';
import XLSXFILESVG from 'assets/images/svg/files/XLSXFILESVG';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    customTargetList: {
        minHeight: 210
    }
}));

const ExportTab = ({ onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { dbId, gridId } = useParams();
    const gridDetailType = useGridTypeDetail({ dbId, gridId });
    const [tab, setTab] = useState(gridDetailType || 'xlsx');

    const showTabs = useMemo(() => {
        return SHOW_GRID_TYPES.includes(gridDetailType);
    }, [gridDetailType]);

    const tabs = useMemo(() => {
        if (showTabs) {
            return [
                {
                    label: (
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                {gridDetailType === GRID_TYPE_SETTING.JSON ? (
                                    <JSONFILESVG color={tab !== 'xlsx' ? '#7869B9' : '#78778B'} />
                                ) : (
                                    <POFILESVG color={tab !== 'xlsx' ? '#7869B9' : '#78778B'} />
                                )}
                            </Grid>
                            <Grid item>
                                <span>Export to {gridDetailType.toUpperCase()}</span>
                            </Grid>
                        </Grid>
                    ),
                    value: gridDetailType
                },
                {
                    label: (
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <XLSXFILESVG color={tab === 'xlsx' ? '#7869B9' : '#78778B'} />
                            </Grid>
                            <Grid item>Export to XLSX</Grid>
                        </Grid>
                    ),
                    value: 'xlsx'
                }
            ];
        }
        return [];
    }, [showTabs, gridDetailType, tab]);

    const handleChangeTab = useCallback((e, value) => {
        setTab(value);
    }, []);

    const renderTab = useCallback(() => {
        return tabs.map(tab => <Tab key={tab.value} {...tab} />);
    }, [tabs]);

    const renderTabContent = useCallback(() => {
        switch (tab) {
            case GRID_TYPE_SETTING.JSON:
                return (
                    <LocalizationJsonExport
                        customTargetList={classes.customTargetList}
                        showTitle={false}
                        onClose={onClose}
                    />
                );
            case GRID_TYPE_SETTING.PO:
                return (
                    <LocalizationPoExport
                        customTargetList={classes.customTargetList}
                        showTitle={false}
                        onClose={onClose}
                    />
                );
            default:
                return <ExportLocalization showTitle={false} onClose={onClose} />;
        }
    }, [classes, tab, onClose]);

    return (
        <>
            <DialogTitle title={t(`export_localization`)} onClose={onClose} />
            {showTabs && (
                <DialogContentCustom>
                    <Tabs value={tab} onChange={handleChangeTab}>
                        {renderTab()}
                    </Tabs>
                </DialogContentCustom>
            )}
            {renderTabContent()}
        </>
    );
};

export default React.memo(ExportTab);
