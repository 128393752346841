import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ChooseMethodStep from 'permission/twoFactor/common/ChooseMethodStep';
import AppVerificationStep from 'permission/twoFactor/common/AppVerificationStep';
import TwoFactorLayout from 'permission/twoFactor/common/TwoFactorLayout';
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';
import EmailVerificationStep from 'permission/twoFactor/common/EmailVerificationStep';
import BackupCodeStep from 'permission/twoFactor/common/BackupCodeStep';
import { enableUserFactorApi } from 'services/company';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    body: {
        width: '35%',
        margin: 'auto',
        marginTop: 50
    },
    stepper: {
        padding: 0
    }
}));

const steps = [i18n.t('choose_method'), i18n.t('verify'), i18n.t('backup')];
const VERIFY_TYPE = {
    APP_VERIFY: 'APP_VERIFY',
    EMAIL_VERIFY: 'EMAIL_VERIFY'
};

function ProfileFactorSetup() {
    const classes = useStyles();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const [verificationType, setVerificationType] = React.useState(VERIFY_TYPE.CHOOSE_METHOD);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const backToProfilePage = () => history.push('/profile/two-factor');

    const goToAppVerify = () => {
        setActiveStep(1);
        setVerificationType(VERIFY_TYPE.APP_VERIFY);
    };

    const goToEmailVerify = () => {
        setActiveStep(1);
        setVerificationType(VERIFY_TYPE.EMAIL_VERIFY);
    };

    const goToChooseMethod = () => {
        setActiveStep(0);
        setVerificationType(VERIFY_TYPE.CHOOSE_METHOD);
    };

    const handleVerify = async otp => {
        try {
            setError('');
            setIsLoading(true);
            await enableUserFactorApi(otp);
            setIsLoading(false);
            setActiveStep(2);
        } catch (error) {
            setError(error.originalMessage);
            setIsLoading(false);
        }
    };

    return (
        <TwoFactorLayout>
            <Grid item container direction="column" justify="center" className={classes.body}>
                <Grid item style={{ width: '100%' }}>
                    <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                        {steps.map(label => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
                {activeStep === 0 && (
                    <ChooseMethodStep
                        onChooseApp={goToAppVerify}
                        onChooseEmail={goToEmailVerify}
                        onBack={backToProfilePage}
                        showHello
                    />
                )}
                {activeStep === 1 && verificationType === VERIFY_TYPE.APP_VERIFY && (
                    <AppVerificationStep
                        onVerify={handleVerify}
                        onBack={goToChooseMethod}
                        isLoading={isLoading}
                        error={error}
                    />
                )}
                {activeStep === 1 && verificationType === VERIFY_TYPE.EMAIL_VERIFY && (
                    <EmailVerificationStep
                        onVerify={handleVerify}
                        onBack={goToChooseMethod}
                        isLoading={isLoading}
                        error={error}
                    />
                )}
                {activeStep === 2 && <BackupCodeStep onFinish={backToProfilePage} />}
            </Grid>
        </TwoFactorLayout>
    );
}

export default ProfileFactorSetup;
