import React from 'react';

function BulletListSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M1.25003815,9.99996185 C1.94034577,9.99996185 2.49996185,10.5596924 2.49996185,11.25 C2.49996185,11.9403076 1.94034577,12.5000382 1.25003815,12.5000382 C0.559616074,12.5000382 2.59792188e-13,11.9403076 2.59792188e-13,11.25 C2.59792188e-13,10.5596924 0.559616074,9.99996185 1.25003815,9.99996185 Z M14.3750382,10.6250382 C14.7206497,10.6250382 15,10.9043884 15,11.25 C15,11.5641923 14.769132,11.8236233 14.4674929,11.8681959 L14.3750382,11.8749618 L5.00003815,11.8749618 C4.65442658,11.8749618 4.37496185,11.5956116 4.37496185,11.25 C4.37496185,10.9358077 4.60592444,10.6763767 4.90758073,10.6318041 L5.00003815,10.6250382 L14.3750382,10.6250382 Z M1.25003815,5.00003815 C1.94034577,5.00003815 2.49996185,5.55965423 2.49996185,6.24996185 C2.49996185,6.94038393 1.94034577,7.5 1.25003815,7.5 C0.559616074,7.5 2.59792188e-13,6.94038393 2.59792188e-13,6.24996185 C2.59792188e-13,5.55965423 0.559616074,5.00003815 1.25003815,5.00003815 Z M14.3750382,5.625 C14.7206497,5.625 15,5.90435027 15,6.24996185 C15,6.56415418 14.769132,6.82367972 14.4674929,6.86826955 L14.3750382,6.87503815 L5.00003815,6.87503815 C4.65442658,6.87503815 4.37496185,6.59557342 4.37496185,6.24996185 C4.37496185,5.93576951 4.60592444,5.67633857 4.90758073,5.63176594 L5.00003815,5.625 L14.3750382,5.625 Z M1.25003815,3.99680289e-15 C1.94034577,3.99680289e-15 2.49996185,0.559616074 2.49996185,1.25003815 C2.49996185,1.94034577 1.94034577,2.49996185 1.25003815,2.49996185 C0.559616074,2.49996185 2.59792188e-13,1.94034577 2.59792188e-13,1.25003815 C2.59792188e-13,0.559616074 0.559616074,3.99680289e-15 1.25003815,3.99680289e-15 Z M14.3750382,0.624961846 C14.7206497,0.624961846 15,0.904426582 15,1.25003815 C15,1.56423049 14.769132,1.82366143 14.4674929,1.86823406 L14.3750382,1.875 L5.00003815,1.875 C4.65442658,1.875 4.37496185,1.59564973 4.37496185,1.25003815 C4.37496185,0.935845816 4.60592444,0.676320284 4.90758073,0.631730455 L5.00003815,0.624961846 L14.3750382,0.624961846 Z"
                    transform="translate(5 6)"
                />
            </g>
        </svg>
    );
}

export default React.memo(BulletListSVG);
