import React from 'react';
import MemSourceSVG from 'assets/images/svg/addon/MemSourceSVG';
import MemoQSVG from 'assets/images/svg/addon/MemoQSVG';
import TranslationOverviewSVG from 'assets/images/svg/addon/TranslationOverviewSVG';
import i18n from 'i18n';
import LQAIssueTypeSVG from 'assets/images/svg/LQAIssueTypeSVG';
import GoogleTranslateSVG from 'assets/images/svg/webHook/GoogleTranslateSVG';
import GoogleOCRSVG from 'assets/images/svg/webHook/GoogleOCRSVG';
import AmazonComprehendSVG from 'assets/images/svg/webHook/AmazonComprehendSVG';
import LambdaSVG from 'assets/images/svg/LambdaSVG';
import AutoQASVG from 'assets/images/svg/AutoQASVG';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import AmazonTranslateSVG from 'assets/images/svg/webHook/AmazonTranslateSVG';
import AmazonTextToSpeechSVG from 'assets/images/svg/webHook/AmazonTextToSpeechSVG';

export const INTEGRATION_PROVIDER = {
    MEM_SOURCE: 'MemSource',
    TRANSLATION_DASHBOARD: 'TranslationDashboard',
    MEMO_Q: 'MemoQ',
    LQA: 'Lqa'
};

export const ADD_ON_NAME = {
    LQA: 'LQA',
    TRANSLATION_DASHBOARD: 'TRANSLATION_DASHBOARD',
    MEMO_Q: 'MEMO_Q',
    MEM_SOURCE: 'MEM_SOURCE',
    LAMBDA: 'LAMBDA',
    AUTO_QA: 'AUTO_QA',
    AMZ_TRANSLATE: 'AMZ_TRANSLATE',
    AMZ_SPEECH_STANDARD: 'AMZ_SPEECH_STANDARD',
    AMZ_SPEECH_NEURAL: 'AMZ_SPEECH_NEURAL',
    AMZ_SENTIMENT: 'AMZ_SENTIMENT',
    GOOGLE_TRANSLATE: 'GOOGLE_TRANSLATE',
    GOOGLE_OCR: 'GOOGLE_OCR'
};

export const ADD_ON_LIST = [
    {
        name: ADD_ON_NAME.TRANSLATION_DASHBOARD,
        icon: (size = 64) => <TranslationOverviewSVG width={size} height={size} />,
        title: i18n.t('addon_translation_dashboard_title'),
        description: i18n.t('addon_translation_dashboard_description'),
        subtitle: i18n.t('addon_translation_dashboard_description'),
        provider: INTEGRATION_PROVIDER.TRANSLATION_DASHBOARD
    },
    {
        name: ADD_ON_NAME.LQA,
        icon: (size = 64) => <LQAIssueTypeSVG width={size} height={size} />,
        title: i18n.t('addon_lqa_title'),
        description: i18n.t('addon_lqa_description'),
        subtitle: i18n.t('addon_lqa_description'),
        provider: INTEGRATION_PROVIDER.LQA
    },
    {
        name: ADD_ON_NAME.MEMO_Q,
        icon: (size = 64) => <MemoQSVG width={size} height={size} />,
        title: i18n.t('addon_memoq_title'),
        description: i18n.t('addon_memoq_description'),
        subtitle: i18n.t('addon_memoq_description'),
        provider: INTEGRATION_PROVIDER.MEMO_Q
    },
    {
        name: ADD_ON_NAME.MEM_SOURCE,
        icon: (size = 64) => <MemSourceSVG width={size} height={size} />,
        title: i18n.t('addon_memsource_title'),
        description: i18n.t('addon_memsource_description'),
        subtitle: i18n.t('addon_memsource_description'),
        provider: INTEGRATION_PROVIDER.MEM_SOURCE
    },
    {
        name: ADD_ON_NAME.GOOGLE_TRANSLATE,
        icon: (size = 64) => <GoogleTranslateSVG width={size} height={size} />,
        title: 'Google Text Translate',
        subtitle: 'Power up your translation ability with AutoML Translation from Google.',
        description:
            'This add-on brings content translation services from Google to your grid. Get your content automatically translated between hundreds of language pairs, powered by the Machine Learning algorithm from Google.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time translation.'],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Free the first 10,000 characters each month.',
            '€24,95 per block of 1 million characters.'
        ]
    },
    {
        name: ADD_ON_NAME.AMZ_TRANSLATE,
        title: 'Amazon Text Translate',
        subtitle: 'Fast, high-quality, affordable language translation from Amazon.',
        icon: (size = 64) => <AmazonTranslateSVG width={size} height={size} />,
        description:
            'This add-on brings content translation services from Amazon Translate neural machine translation service to your grid.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time translation.'],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Free the first 10,000 characters each month.',
            '€19.95 per block of 1 million characters.'
        ]
    },
    {
        name: ADD_ON_NAME.AMZ_SPEECH_STANDARD,
        title: 'Amazon Standard Text To Speech',
        subtitle: 'Turn text into speech with Amazon Polly standard voice.',
        icon: (size = 64) => <AmazonTextToSpeechSVG width={size} height={size} />,
        description:
            'This add-on turns text into speech, then saves it as audio files into your grid using the famous Amazon Polly speech generation service.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time translation.'],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Free the first 10,000 characters each month.',
            '€24 per block of 4 million characters.'
        ]
    },
    {
        name: ADD_ON_NAME.AMZ_SPEECH_NEURAL,
        title: 'Amazon Neural Text To Speech',
        subtitle: 'Turn text into speech with Amazon Polly Neural voice.',
        icon: (size = 64) => <AmazonTextToSpeechSVG width={size} height={size} />,
        description: 'Turn text into speech with Amazon Polly Neural voice.',
        descriptionItems: [
            'State-of-the-art voice generation using Machine Learning.',
            'Fully integrate with your grid via Automation.',
            'Real-time generation.'
        ],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Free the first 10,000 characters each month.',
            '€24 per block of 1 million characters.'
        ]
    },
    {
        name: ADD_ON_NAME.AMZ_SENTIMENT,
        title: 'Amazon Sentiment Analysis',
        subtitle: 'Text Sentiment Analysis with Amazon Comprehend.',
        icon: (size = 64) => <AmazonComprehendSVG width={size} height={size} />,
        description:
            'This add-on extract sentiment from your given text string, using the famous Amazon Comprehend service.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time analyzing and extracting.'],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Complying with Amazon Comprehend service, the usage is calculated by the number of units of 100 characters (minimum 3 units if the source text is shorter than 300 characters).',
            'Free first 1,000 units each month.',
            '€20 per block of 10,000 units.'
        ]
    },
    {
        name: ADD_ON_NAME.GOOGLE_OCR,
        title: 'Google OCR',
        subtitle: 'Read text from images with Google Cloud Vision service.',
        icon: (size = 64) => <GoogleOCRSVG width={size} height={size} />,
        description: 'This add-on reads text from your image files, using the famous Google Cloud Vision service.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time analyzing and extracting.'],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Free first 50 requests each month.',
            '€20 per block of 10,000 requests.'
        ]
    },
    {
        name: ADD_ON_NAME.AUTO_QA,
        title: i18n.t('auto_qa_by_lexiqa'),
        subtitle: 'Auto audit your translation quality.',
        icon: (size = 64) => <AutoQASVG width={size} height={size} />,
        description:
            'Audit translation quality with pre-configured grammar and localization rules for each individual locale.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time auditing.'],
        priceItems: ['Pay As you Go, with a monthly billing cycle.', '€20 per block of 20,000 words.']
    },
    {
        name: ADD_ON_NAME.LAMBDA,
        title: 'Lambda Function',
        subtitle: 'Code and run your own Lambda function, and apply it to your grid.',
        icon: (size = 64) => <LambdaSVG width={size} height={size} />,
        description:
            'Lambda function helps you to run your own script, using common program languages such as python, java, etc… Your function can get input from one cell and put output to another cell in the same row in your grid.',
        descriptionItems: ['Fully integrate with your grid via Automation.', 'Real-time auditing.'],
        priceItems: [
            'Pay As you Go, with a monthly billing cycle.',
            'Free first 50 requests each month.',
            '€20 per block of 4,000 requests.'
        ]
    }
];

export const ADD_ONS_OBJECT = ADD_ON_LIST.reduce((object, addon) => {
    object[addon.name] = addon;
    return object;
}, {});

ADD_ONS_OBJECT[WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA] = ADD_ONS_OBJECT[ADD_ON_NAME.LAMBDA];
ADD_ONS_OBJECT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE] = ADD_ONS_OBJECT[ADD_ON_NAME.AMZ_TRANSLATE];
ADD_ONS_OBJECT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH] = ADD_ONS_OBJECT[ADD_ON_NAME.AMZ_SPEECH_STANDARD];
ADD_ONS_OBJECT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL] = ADD_ONS_OBJECT[ADD_ON_NAME.AMZ_SPEECH_NEURAL];
ADD_ONS_OBJECT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND] = ADD_ONS_OBJECT[ADD_ON_NAME.AMZ_SENTIMENT];

export const automationAddonsKey = [
    ADD_ON_NAME.GOOGLE_TRANSLATE,
    ADD_ON_NAME.AMZ_TRANSLATE,
    ADD_ON_NAME.AMZ_SPEECH_STANDARD,
    ADD_ON_NAME.AMZ_SPEECH_NEURAL,
    ADD_ON_NAME.AMZ_SENTIMENT,
    ADD_ON_NAME.GOOGLE_OCR,
    ADD_ON_NAME.AUTO_QA,
    ADD_ON_NAME.LAMBDA
];

export const automationAddons = automationAddonsKey.map(addonName => ADD_ONS_OBJECT[addonName]);

export const addons = [
    ADD_ON_NAME.TRANSLATION_DASHBOARD,
    ADD_ON_NAME.LQA,
    ADD_ON_NAME.MEMO_Q,
    ADD_ON_NAME.MEM_SOURCE,
    ADD_ON_NAME.AUTO_QA
].map(addonName => ADD_ONS_OBJECT[addonName]);
