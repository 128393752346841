import React from 'react';
import { Slide, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChromeNotifySVG from 'assets/images/svg/ChromeNotifySVG';
import { useIsShowChromeNotify } from 'hooks/app';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import * as appActions from 'app/actions';
import { useDispatch } from 'react-redux';
import { isChrome, getChromeVersion } from 'utils/os';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        position: 'absolute',
        zIndex: 1001,
        width: 420,
        margin: '0 auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        background: theme.colors.white,
        borderRadius: 6,
        boxShadow: theme.shadows[1],
        top: theme.spacing(2),
        left: 0,
        right: 0
    },
    spRight3: {
        marginRight: theme.spacing(3)
    },
    expand: {
        flex: 1
    },
    pointer: {
        cursor: 'pointer'
    }
}));

const MINIMUM_CHROME_VERSION = 87;

function Version() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isShowChromeNotify = useIsShowChromeNotify();
    const currentChromeVersion = getChromeVersion();
    const isBrowserChrome = isChrome();

    const isNotChrome = React.useMemo(() => {
        return !isBrowserChrome || (isBrowserChrome && currentChromeVersion < MINIMUM_CHROME_VERSION);
    }, [isBrowserChrome, currentChromeVersion]);

    const [isOpenConfirmRefresh, setIsOpenConfirmRefresh] = React.useState(isNotChrome ? true : false);

    React.useEffect(() => {
        if (
            isShowChromeNotify &&
            (!isBrowserChrome || (isBrowserChrome && currentChromeVersion < MINIMUM_CHROME_VERSION))
        ) {
            setIsOpenConfirmRefresh(true);
        }
    }, [isShowChromeNotify, currentChromeVersion, isBrowserChrome]);

    const closeChromeNotify = () => {
        setIsOpenConfirmRefresh(false);
        dispatch(appActions.setChromeNotify(false));
    };

    return (
        <Slide direction="down" in={isOpenConfirmRefresh} mountOnEnter unmountOnExit>
            <Grid container direction="row" alignItems="center" className={classes.paper}>
                <Grid item className={classes.spRight3}>
                    <ChromeNotifySVG />
                </Grid>
                <Grid item className={classes.expand}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="body1">{t('chrome_title')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{t('chrome_subtitle')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.pointer} onClick={closeChromeNotify}>
                    <CloseIconSVG />
                </Grid>
            </Grid>
        </Slide>
    );
}

export default React.memo(Version);
