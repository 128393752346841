import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { useTagMode, useIsShowTag, useTagRegex, useTagModelOptions, useTokenSetId, useCurrentGrid } from 'hooks/gridUI';
import InputText from 'components/inputs/InputText';
import IOSSwitch from 'components/switches/IOS';
import { useDispatch } from 'react-redux';
import { DEBOUNCE_TIME_SEARCHING, TAG_DETECT_MODE } from 'const/gridUI';
import { isValidRegex } from 'utils/regex';
import { useParams } from 'react-router-dom';
import RegexSVG from 'assets/images/svg/RegexSVG';
import FullAPIDocSVG from 'assets/images/svg/FullAPIDocSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import SmileFaceSVG from 'assets/images/svg/SmileFaceSVG';
import Tooltip from 'components/tooltip/Base';
import * as gridUIActions from 'gridUI/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    spacing: {
        padding: theme.spacing(3)
    },
    expand: {
        flex: 1
    },
    key: {
        borderRadius: 5,
        border: `1px solid ${theme.colors.border}`,
        background: theme.colors.ghostwhite,
        boxSizing: 'border-box',
        boxShadow: `1px 1px 0px rgba(0, 0, 0, 0.2)`,
        minWidth: 26,
        height: 26,
        paddingLeft: 7,
        paddingRight: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.dimGrey
    },
    switchIcon: {
        cursor: 'pointer'
    },
    dFlex: {
        display: 'flex'
    },
    dropdown: {
        width: '100%'
    },
    labelPredefined: {
        marginRight: 0,
        display: 'flex',
        '& > span:last-child': {
            flex: 1
        }
    }
}));

function TagSetting() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { dbId, branchId } = useParams();
    const isShowTag = useIsShowTag({ dbId, gridId: branchId });
    const tagMode = useTagMode({ dbId, gridId: branchId });
    const currentGrid = useCurrentGrid();
    const tagRegex = useTagRegex({ dbId, gridId: branchId });
    const dispatch = useDispatch();
    const [isInvalid, setIsInvalid] = React.useState(false);
    const timer = React.useRef(0);
    const tokenSetId = useTokenSetId({ gridId: branchId, dbId });
    const [regex, setRegex] = React.useState(tagRegex);
    const modelOptions = useTagModelOptions();

    const selectedModel = React.useMemo(() => {
        return modelOptions?.find(option => option?.value === tokenSetId);
    }, [tokenSetId, modelOptions]);

    const handleTagModeChange = React.useCallback(
        e => {
            dispatch(
                gridUIActions.updateCurrentGrid({
                    dbId,
                    newGrid: {
                        ...currentGrid,
                        tokenDetection: {
                            ...currentGrid?.tokenDetection,
                            mode: e?.target?.value
                        }
                    },
                    oldGrid: currentGrid
                })
            );
        },
        [dispatch, currentGrid, dbId]
    );

    const handleTagRegexChange = React.useCallback(
        text => {
            const validRegex = isValidRegex(text);
            setRegex(text);
            if (validRegex) {
                setIsInvalid(false);
            } else {
                setIsInvalid(true);
                return;
            }
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(function() {
                const isCancel = !isValidRegex(text);
                if (isCancel) return;

                dispatch(
                    gridUIActions.updateCurrentGrid({
                        dbId,
                        newGrid: {
                            ...currentGrid,
                            tokenDetection: {
                                ...currentGrid?.tokenDetection,
                                regex: text
                            }
                        },
                        oldGrid: currentGrid
                    })
                );
            }, DEBOUNCE_TIME_SEARCHING);
        },
        [dispatch, currentGrid, dbId]
    );

    const handleChangeTag = React.useCallback(() => {
        dispatch(
            gridUIActions.updateCurrentGrid({
                dbId,
                newGrid: {
                    ...currentGrid,
                    tokenDetection: {
                        ...currentGrid?.tokenDetection,
                        enabled: !isShowTag
                    }
                },
                oldGrid: currentGrid
            })
        );
    }, [dispatch, isShowTag, currentGrid, dbId]);

    const handleModelChange = React.useCallback(
        option => {
            dispatch(
                gridUIActions.updateCurrentGrid({
                    dbId,
                    newGrid: {
                        ...currentGrid,
                        tokenDetection: {
                            ...currentGrid?.tokenDetection,
                            tokenSetId: option?.value
                        }
                    },
                    oldGrid: currentGrid
                })
            );
        },
        [dispatch, currentGrid, dbId]
    );

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.spacing}>
                <Grid container direction="row" alignItems="center" spacing={3}>
                    <Grid item>
                        <IOSSwitch checked={isShowTag} onChange={handleChangeTag} className={classes.switchIcon} />
                    </Grid>
                    <Grid item>
                        <p className="body2">Enable Tag detection</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item>
                <FormControl component="fieldset" className={classes.spacing}>
                    <RadioGroup
                        className={classes.group}
                        aria-label="tag"
                        name="tag"
                        value={tagMode}
                        onChange={handleTagModeChange}
                    >
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <FormControlLabel
                                    style={{ marginRight: 0, marginLeft: -4 }}
                                    value={TAG_DETECT_MODE.AUTO}
                                    control={<Radio size="small" />}
                                    disabled={!isShowTag}
                                    label={
                                        <Grid
                                            container
                                            style={{ marginLeft: 8 }}
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item className={classes.dFlex}>
                                                <SmileFaceSVG />
                                            </Grid>
                                            <Tooltip title={'Gridly will auto detect some tags in your document'}>
                                                <Grid item>
                                                    <p className="body2 !font-normal">
                                                        {' '}
                                                        {t('auto_detect_with_gridly')}
                                                    </p>
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    style={{ marginRight: 0, marginLeft: -4 }}
                                    value={TAG_DETECT_MODE.REGEX}
                                    control={<Radio size="small" />}
                                    disabled={!isShowTag}
                                    label={
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            style={{ marginLeft: 8, width: '100%' }}
                                            spacing={2}
                                        >
                                            <Grid item className={classes.dFlex}>
                                                <RegexSVG />
                                            </Grid>
                                            <Tooltip
                                                title={
                                                    <>
                                                        <p className="text-light font-normal inline">
                                                            Detect tag in your document using your own Regex <br />{' '}
                                                            Example:
                                                        </p>{' '}
                                                        <p className="text-light font-medium inline">
                                                            {'\\{[^\\}]+\\}'}
                                                        </p>{' '}
                                                        <p className="text-light font-normal inline">represents</p>{' '}
                                                        <p className="text-light font-medium inline">{'{variable}'}</p>
                                                    </>
                                                }
                                            >
                                                <Grid item style={{ minWidth: 180 }}>
                                                    <p className="body2 !font-normal">{t('detect_with_regex')}</p>
                                                </Grid>
                                            </Tooltip>

                                            <Grid item className={classes.expand}>
                                                <InputText
                                                    name="name"
                                                    value={regex}
                                                    onChange={e => handleTagRegexChange(e.target?.value)}
                                                    placeholder={t('input_regex')}
                                                    disabled={tagMode !== TAG_DETECT_MODE.REGEX}
                                                    error={isInvalid}
                                                    errorText={'Invalid regex'}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    style={{ marginRight: 0, marginLeft: -4 }}
                                    className={classes.labelPredefined}
                                    value={TAG_DETECT_MODE.PREDEFINED}
                                    control={<Radio size="small" />}
                                    disabled={!isShowTag}
                                    label={
                                        <Grid
                                            style={{ marginLeft: 8, width: '100%' }}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item className={classes.dFlex}>
                                                <FullAPIDocSVG />
                                            </Grid>
                                            <Tooltip title="Detect tag in your document using Custom Tags preset">
                                                <Grid item style={{ minWidth: 180 }}>
                                                    <p className="body2 !font-normal">{t('detect_with_custom_tag')}</p>
                                                </Grid>
                                            </Tooltip>

                                            <Grid item className={classes.expand}>
                                                <LDBasePortal
                                                    dropdownClassName={classes.dropdown}
                                                    ddPlaceholder={t('select_a_model')}
                                                    menuPlaceholder={t('find_a_model')}
                                                    options={modelOptions}
                                                    onChange={handleModelChange}
                                                    defaultValue={selectedModel}
                                                    isDisabled={tagMode !== TAG_DETECT_MODE.PREDEFINED}
                                                    id="tag-selection"
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default TagSetting;
