import React from 'react';

function QuickApplyTmSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8333 3.4375C16.0267 3.4375 16.2122 3.51432 16.3489 3.65107C16.4857 3.78781 16.5625 3.97328 16.5625 4.16667V15.8333C16.5625 16.0267 16.4857 16.2122 16.3489 16.3489C16.2122 16.4857 16.0267 16.5625 15.8333 16.5625H12.5C11.9822 16.5625 11.5625 16.9822 11.5625 17.5C11.5625 18.0178 11.9822 18.4375 12.5 18.4375H15.8333C16.524 18.4375 17.1864 18.1631 17.6748 17.6748C18.1631 17.1864 18.4375 16.524 18.4375 15.8333V4.16667C18.4375 3.476 18.1631 2.81362 17.6748 2.32524C17.1864 1.83687 16.524 1.5625 15.8333 1.5625H12.5C11.9822 1.5625 11.5625 1.98223 11.5625 2.5C11.5625 3.01777 11.9822 3.4375 12.5 3.4375H15.8333ZM7.32978 5.17045C6.96367 4.80433 6.37007 4.80433 6.00396 5.17045L1.84957 9.32483C1.6726 9.49536 1.5625 9.73483 1.5625 10C1.5625 10.2645 1.672 10.5033 1.84813 10.6738L6.00396 14.8296C6.37007 15.1957 6.96367 15.1957 7.32978 14.8296C7.6959 14.4635 7.6959 13.8699 7.32978 13.5038L4.7635 10.9375H12.5C13.0178 10.9375 13.4375 10.5178 13.4375 10C13.4375 9.48223 13.0178 9.0625 12.5 9.0625H4.76355L7.32978 6.49627C7.6959 6.13015 7.6959 5.53656 7.32978 5.17045Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(QuickApplyTmSVG);
