import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.OPEN_CELL_COMMENT](state) {
        return {
            ...state,
            isOpenCellComment: true
        };
    },
    [types.CLOSE_CELL_COMMENT](state) {
        return {
            ...state,
            isOpenCellComment: false,
            comment: {}
        };
    },
    [types.UPDATE_COMMENT_LIST](state, { payload }) {
        const { threadId, newComment } = payload;
        const { comment } = state;
        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }
            const commentList = updateComment?.comments || [];
            draft['comments'] = [...commentList, newComment];
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.SET_COMMENT](state, { payload }) {
        const { comment: cellComment, threadId } = payload;
        const { comment } = state;
        const newComment = produce(comment, draft => {
            if (!draft?.[threadId]) {
                draft[threadId] = {};
            }

            draft[threadId] = cellComment;
        });

        return {
            ...state,
            comment: newComment
        };
    },
    [types.UPDATE_CELL_COMMENT_THREAD_STATUS](state, { payload }) {
        const { newStatus, threadId } = payload;
        const { comment } = state;

        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }
            draft['thread'] = {
                ...draft?.thread,
                status: newStatus
            };
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.DELETE_CELL_COMMENT](state, { payload }) {
        const { commentId, threadId } = payload;
        const { comment } = state;

        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }
            const currentComments = draft?.comments || [];

            draft['comments'] = currentComments?.map(comment => {
                if (comment?.id === commentId) {
                    return {
                        ...comment,
                        isDeleted: true
                    };
                }
                return comment;
            });
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.DELETE_CELL_COMMENT_FAILED](state, { payload }) {
        const { commentId, threadId } = payload;
        const { comment } = state;

        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }
            const currentComments = draft?.comments || [];

            draft['comments'] = currentComments?.map(comment => {
                if (comment?.id === commentId) {
                    return {
                        ...comment,
                        isDeleted: false
                    };
                }
                return comment;
            });
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.DELETE_CELL_COMMENT_SUCCESS](state, { payload }) {
        const { commentId, threadId } = payload;
        const { comment } = state;

        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }
            const currentComments = draft?.comments || [];

            draft['comments'] = currentComments?.filter(comment => comment?.id !== commentId);
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.UPDATE_COMMENT](state, { payload }) {
        const { commentId, content, threadId, editedAt } = payload;
        const { comment } = state;

        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }
            const currentComments = draft?.comments || [];

            draft['comments'] = currentComments?.map(comment => {
                if (comment?.id === commentId) {
                    return {
                        ...comment,
                        content,
                        editedAt
                    };
                }

                return comment;
            });
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.UPDATE_THREAD_COMMENT](state, { payload }) {
        const { content, threadId, editedAt } = payload;
        const { comment } = state;

        const updateComment = comment?.[threadId];

        if (!updateComment) {
            console.log('comment not found');
            return state;
        }

        const newCellComment = produce(updateComment, draft => {
            if (!draft) {
                draft = {};
            }

            draft['thread'] = {
                ...draft?.thread,
                content,
                editedAt
            };
        });

        return {
            ...state,
            comment: {
                ...comment,
                [threadId]: newCellComment
            }
        };
    },
    [types.FETCH_CELL_COMMENT_LIST](state, { payload }) {
        const { compositeViewId } = payload;
        const { commentDashboard } = state;
        const newCommentDashboard = produce(commentDashboard, draft => {
            if (!draft?.[compositeViewId]) {
                draft[compositeViewId] = {};
            }
            draft[compositeViewId].isFetching = true;
        });
        return {
            ...state,
            commentDashboard: newCommentDashboard
        };
    },
    [types.FETCH_CELL_COMMENT_LIST_FAILED](state, { payload }) {
        const { compositeViewId, error } = payload;
        const { commentDashboard } = state;
        const newCommentDashboard = produce(commentDashboard, draft => {
            if (!draft?.[compositeViewId]) {
                draft[compositeViewId] = {};
            }
            draft[compositeViewId].isFetching = false;
            draft[compositeViewId].error = error;
        });
        return {
            ...state,
            commentDashboard: newCommentDashboard
        };
    },
    [types.FETCH_CELL_COMMENT_LIST_SUCCESS](state, { payload }) {
        const { compositeViewId, comments, totalComments, isFetchingMore } = payload;
        const { commentDashboard } = state;
        const newCommentDashboard = produce(commentDashboard, draft => {
            if (!draft?.[compositeViewId]) {
                draft[compositeViewId] = {};
            }

            const oldComments = draft[compositeViewId]?.list || [];
            const oldCurrentPage = draft[compositeViewId]?.currentPage || 0;
            draft[compositeViewId].isFetching = false;
            draft[compositeViewId].list = !isFetchingMore ? comments : [...oldComments, ...comments];
            draft[compositeViewId].totalComments = totalComments;
            draft[compositeViewId].currentPage = !isFetchingMore ? 0 : oldCurrentPage + 1;
            draft[compositeViewId].error = '';
        });
        return {
            ...state,
            commentDashboard: newCommentDashboard
        };
    }
};

export default handler;
