import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { withMention } from 'components/formula/plugins/withMention';
import { createEditor, Editor, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import Element from 'components/formula/components';
import { makeStyles } from '@material-ui/styles';
import { getFormulaServerValue, getSlateDataFromMention } from 'utils/gridUI/formula';
import Portal from 'components/formula/components/Portal';
import { Grid } from '@material-ui/core';
import Avatar from 'components/avatar/User';
import { getAvatarUrl } from 'utils/images';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { useCurrentUserFullInfo } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        '& p': {
            margin: '0 !important'
        },
        '& span': {
            marginBottom: '3px !important'
        }
    },
    listUsers: {
        top: '-9999px',
        left: '-9999px',
        position: 'absolute',
        zIndex: 13000,
        padding: '8px 0',
        background: 'white',
        borderRadius: '4px',
        boxShadow: '0 1px 5px rgba(0,0,0,.2)',
        width: 220,
        maxHeight: 260,
        overflow: 'hidden auto'
    },
    user: {
        padding: '6px 10px',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.paleGrey
        }
    },
    selected: {
        background: theme.colors.paleGrey
    },
    mr8: {
        marginRight: 8
    }
}));

const EditorMention = (
    {
        text,
        onChange,
        placeholder,
        className,
        handleKeyDown,
        members = [],
        mentionClassName,
        afterClickInsertSuggestion,
        ...rest
    },
    ref
) => {
    const membersData = useMemo(() => {
        return members.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});
    }, [members]);
    const classes = useStyles();
    const [value, setValue] = useState(getSlateDataFromMention({ string: text, userData: membersData }));
    const [target, setTarget] = useState();
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const divRef = useRef();
    const currentUser = useCurrentUserFullInfo();

    const renderElement = useCallback(props => <Element {...props} mentionClassName={mentionClassName} />, [
        mentionClassName
    ]);

    const editor = useMemo(() => {
        return withMention(withReact(withHistory(createEditor())));
    }, []);

    const users = useMemo(() => {
        return members.filter(user => {
            const name = user.fullName || user.name;
            return name.toLowerCase().startsWith(search.toLowerCase());
        });
    }, [search, members]);

    const insertMention = useCallback(
        user => {
            const mention = {
                type: 'mention',
                user,
                children: [{ text: '' }]
            };
            Transforms.select(editor, target);
            ReactEditor.focus(editor);
            Transforms.insertNodes(editor, [mention, { text: ' ' }]);
            setTarget(null);
            setTimeout(() => {
                Transforms.move(editor, { distance: 2, unit: 'character' });
            }, 20);
        },
        [editor, target]
    );

    const onClickSuggestion = useCallback(
        user => {
            insertMention(user);
            afterClickInsertSuggestion && afterClickInsertSuggestion();
        },
        [insertMention, afterClickInsertSuggestion]
    );

    const onKeyDown = useCallback(
        event => {
            if (target) {
                switch (event.key) {
                    case 'ArrowDown': {
                        event.preventDefault();
                        const prevIndex = index >= users.length - 1 ? 0 : index + 1;
                        setIndex(prevIndex);
                        break;
                    }
                    case 'ArrowUp': {
                        event.preventDefault();
                        const nextIndex = index <= 0 ? users.length - 1 : index - 1;
                        setIndex(nextIndex);
                        break;
                    }
                    case 'Tab':
                    case 'Enter': {
                        event.preventDefault();
                        if (users[index]) {
                            insertMention(users[index]);
                        } else {
                            setTarget(null);
                        }
                        break;
                    }
                    case 'Escape': {
                        event.preventDefault();
                        setTarget(null);
                        break;
                    }
                    default:
                        break;
                }
            } else {
                handleKeyDown && handleKeyDown(event);
            }
        },
        [users, index, target, insertMention, handleKeyDown]
    );

    const handleChange = useCallback(
        changedValue => {
            const newText = getFormulaServerValue(changedValue);
            onChange(newText);
            setValue(changedValue);

            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
                const [start, end] = Range.edges(selection);
                const wordBefore = Editor.before(editor, start, { unit: 'word' });
                const before = wordBefore && Editor.before(editor, wordBefore);
                let beforeRange = before && Editor.range(editor, before, start);
                const beforeText = beforeRange && Editor.string(editor, beforeRange);
                const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
                const after = Editor.after(editor, start);
                const afterRange = Editor.range(editor, start, after);
                const afterText = Editor.string(editor, afterRange);
                const afterMatch = afterText.match(/^(\s|$)/);

                const firstRange = Editor.range(editor, { path: [0, 0], offset: 0 }, start);
                const endRange = Editor.range(editor, end, end);
                const firstText = firstRange && Editor.string(editor, firstRange);
                if (
                    ((firstText.includes('@') && !beforeText) ||
                        beforeText?.charAt(beforeText.length - 1) === '@' ||
                        beforeMatch) &&
                    afterMatch
                ) {
                    if (firstText?.charAt(firstText.length - 1) === '@' && !beforeText) {
                        setTarget({
                            ...endRange,
                            anchor: {
                                ...endRange.anchor,
                                offset: endRange.anchor.offset - 1
                            }
                        });
                    } else if (beforeText?.charAt(beforeText.length - 1) === '@') {
                        const isSpaceBefore = beforeText.charAt(beforeText.length - 2) === ' ';
                        let newBeforeRange = {};
                        if (!isSpaceBefore) {
                            const offset = beforeRange.focus.offset - 1;
                            newBeforeRange = {
                                ...beforeRange,
                                anchor: {
                                    offset,
                                    path: [...beforeRange.focus.path]
                                }
                            };
                        } else {
                            newBeforeRange = {
                                ...selection,
                                anchor: { ...selection.anchor, offset: selection.anchor.offset - 1 }
                            };
                        }
                        setTarget(newBeforeRange);
                    } else {
                        setTarget(beforeRange);
                    }
                    setSearch(beforeMatch ? beforeMatch[1] : '');
                    setIndex(0);
                    return;
                }
            }

            if (JSON.stringify(value) === JSON.stringify(changedValue)) return;
            setTarget(null);
        },
        [onChange, editor, value]
    );

    useEffect(() => {
        if (target && users.length > 0) {
            const el = divRef.current;
            const domRange = ReactEditor.toDOMRange(editor, target);
            const rect = domRange.getBoundingClientRect();
            el.style.top = `${rect.top + window.pageYOffset + 24}px`;
            el.style.left = `${rect.left + window.pageXOffset}px`;
        }
    }, [users.length, editor, index, search, target]);

    const _transformSelectStart = useCallback(() => {
        Transforms.select(editor, {
            anchor: {
                offset: 0,
                path: [0, 0]
            },
            focus: {
                offset: 0,
                path: [0, 0]
            }
        });
    }, [editor]);

    const _transformSelectEnd = useCallback(() => {
        const lastCharPosition = Editor.end(editor, []);
        Transforms.select(editor, lastCharPosition);
    }, [editor]);

    useImperativeHandle(ref, () => ({
        reset: ({ text }) => {
            setValue(getSlateDataFromMention({ string: text, userData: membersData }));
        },
        transformSelectStart: _transformSelectStart,
        transformSelectEnd: _transformSelectEnd,
        transformMove: () => {
            Transforms.move(editor);
        },
        focus: (moveToStart, moveToEnd = true) => {
            if (!ReactEditor.isFocused(editor)) {
                ReactEditor.focus(editor);
            }
            if (moveToStart) {
                setTimeout(_transformSelectStart, 10);
                return;
            }
            if (moveToEnd) {
                setTimeout(_transformSelectEnd, 10);
                return;
            }
        },
        focusToSelection: selection => {
            if (selection && selection.anchor !== null && selection.focus !== null) {
                Transforms.select(editor, selection);
                ReactEditor.focus(editor);
            }
        },
        blur: () => {
            if (ReactEditor.isFocused(editor)) {
                ReactEditor.blur(editor);
            }
        },
        getRangeSelection: () => {
            const { selection } = editor;
            return selection;
        },
        insertText: ({ text, selection }) => {
            if (text && selection) {
                Transforms.select(editor, selection);
                Transforms.insertText(editor, text);
                setTarget(null);
            }
        }
    }));

    return (
        <Slate editor={editor} value={value} onChange={handleChange}>
            <Editable
                id="editable-mention"
                renderElement={renderElement}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                className={`${classes.root} ${className}`}
                {...rest}
            />
            {target && users.length > 0 && (
                <Portal>
                    <div ref={divRef} className={classes.listUsers} id="portal-mention-users">
                        {users.map((user, i) => (
                            <Grid
                                key={user.id}
                                container
                                alignItems="center"
                                className={`${classes.user} ${i === index ? classes.selected : ''}`}
                                onClick={() => onClickSuggestion(user)}
                            >
                                <Grid item className={classes.mr8}>
                                    <Avatar src={getAvatarUrl(user.id)} size={24} color="#DBDCE1">
                                        <AvatarIconSVG />
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    {user.fullName} {user.id === currentUser.id && <span>(you)</span>}
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                </Portal>
            )}
        </Slate>
    );
};

const EditorMentionRef = forwardRef(EditorMention);

export default React.memo(EditorMentionRef);
