import * as types from '../types';

const handler = {
    [types.REGISTER_DOING_ACTION](state, { payload }) {
        const { actionId } = payload;
        const { doingActionIds } = state;
        return {
            ...state,
            doingActionIds: [...doingActionIds, actionId]
        };
    },

    [types.REMOVE_DOING_ACTION](state, { payload }) {
        const { actionId } = payload;
        const { doingActionIds } = state;
        const updatedDoingActionIds = doingActionIds.filter(dacId => actionId !== dacId);
        return {
            ...state,
            doingActionIds: updatedDoingActionIds
        };
    }
};

export default handler;
