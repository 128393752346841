import React from 'react';

function InvoiceMethodSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7592 2H4.60315C4.27007 2 4 2.26679 4 2.59582V20.2792C4 20.8217 4.32858 21.3019 4.83271 21.5109C5.33683 21.7154 5.91298 21.6043 6.30007 21.2219C6.47111 21.0529 6.69617 20.9595 6.93473 20.9595C7.17329 20.9595 7.40285 21.0529 7.56939 21.2219C8.09602 21.7421 8.78469 22 9.47786 22C10.171 22 10.8597 21.7421 11.3863 21.2219C11.6204 20.9907 11.6204 20.6127 11.3863 20.3771C11.1523 20.1458 10.7697 20.1458 10.5311 20.3771C9.95048 20.9506 9.00524 20.9506 8.4246 20.3771C7.6009 19.5634 6.26406 19.5634 5.44486 20.3771C5.39985 20.4215 5.35034 20.4304 5.29182 20.4082C5.23331 20.386 5.2063 20.3415 5.2063 20.2792V7.89595H18.156V20.2748C18.156 20.337 18.129 20.3771 18.0705 20.4037C18.012 20.426 17.9625 20.4171 17.9175 20.3726C17.0938 19.5589 15.7569 19.5589 14.9377 20.3726L14.4606 20.8439C14.2265 21.0751 14.2265 21.4531 14.4606 21.6888C14.6947 21.92 15.0773 21.92 15.3158 21.6888L15.7929 21.2174C15.964 21.0485 16.189 20.9551 16.4276 20.9551C16.6661 20.9551 16.8957 21.0485 17.0622 21.2174C17.4493 21.5998 18.0255 21.711 18.5296 21.5064C19.0337 21.3019 19.3623 20.8173 19.3623 20.2748V2.59582C19.3623 2.26679 19.0923 2 18.7592 2ZM5.2063 6.70431V3.19164H18.156V6.70431H5.2063Z"
                fill={color}
            />
            <path
                d="M16.3238 12.6311H12.984C12.6509 12.6311 12.3809 12.3643 12.3809 12.0353C12.3809 11.7062 12.6509 11.4395 12.984 11.4395H16.3238C16.6569 11.4395 16.927 11.7062 16.927 12.0353C16.927 12.3643 16.6569 12.6311 16.3238 12.6311Z"
                fill={color}
            />
            <path
                d="M16.3238 15.9123H12.984C12.6509 15.9123 12.3809 15.6456 12.3809 15.3165C12.3809 14.9875 12.6509 14.7207 12.984 14.7207H16.3238C16.6569 14.7207 16.927 14.9875 16.927 15.3165C16.927 15.6456 16.6569 15.9123 16.3238 15.9123Z"
                fill={color}
            />
            <path
                d="M10.5491 13.4619C10.324 13.3107 9.95044 13.1595 9.4013 13.0039V11.501C9.57684 11.5455 9.70738 11.6389 9.8019 11.7811C9.87392 11.9012 9.91893 12.039 9.93693 12.2036C9.94593 12.3103 10.0405 12.3903 10.1485 12.3903H10.8327C10.8912 12.3903 10.9452 12.3681 10.9857 12.3281C11.0262 12.288 11.0487 12.2347 11.0442 12.1769C11.0307 11.6833 10.8642 11.2787 10.5401 10.9719C10.2655 10.7051 9.88292 10.5406 9.4013 10.4783V10.1582C9.4013 10.0426 9.30678 9.94922 9.18975 9.94922H8.81615C8.69913 9.94922 8.6046 10.0426 8.6046 10.1582V10.4783C8.11848 10.5228 7.72238 10.7051 7.42981 11.0208C7.10123 11.3721 6.93469 11.7811 6.93469 12.2258C6.93469 12.7282 7.09673 13.1284 7.41181 13.413C7.67737 13.6575 8.08247 13.8443 8.6046 13.9643V15.6762C8.35704 15.6184 8.186 15.5028 8.08697 15.3205C8.03296 15.2227 7.96544 15.027 7.93844 14.6313C7.92943 14.5201 7.83941 14.4357 7.72688 14.4357H7.03821C6.92118 14.4357 6.82666 14.529 6.82666 14.6446C6.82666 15.1337 6.90768 15.5161 7.07422 15.8096C7.35779 16.3165 7.87542 16.6144 8.6046 16.7033V17.1969C8.6046 17.3125 8.69913 17.4059 8.81615 17.4059H9.18975C9.30678 17.4059 9.4013 17.3125 9.4013 17.1969V16.6989C9.8154 16.6366 10.1485 16.5255 10.396 16.3743C10.9182 16.0408 11.1792 15.485 11.1747 14.7247C11.1747 14.1644 10.9632 13.742 10.5491 13.4619ZM8.06447 12.1769C8.06447 12.0079 8.12298 11.8478 8.24451 11.6878C8.32103 11.5899 8.44256 11.5233 8.6046 11.4877V12.8127C8.46507 12.7638 8.34804 12.7016 8.25351 12.6215C8.12298 12.5148 8.06447 12.3725 8.06447 12.1769ZM9.4013 14.1689C9.59935 14.2356 9.70738 14.2978 9.76589 14.3378C9.95494 14.4668 10.0405 14.6491 10.0405 14.907C10.0405 15.0759 10.0044 15.2227 9.93243 15.3516C9.82891 15.5384 9.65336 15.654 9.4013 15.7029V14.1689Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(InvoiceMethodSVG);
