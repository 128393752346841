import React from 'react';

function WarningRoundSVG({ ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8.00002C0 3.58005 3.58001 4.1008e-05 7.99998 4.1008e-05C12.42 4.1008e-05 16 3.58001 16 8.00002C16 12.42 12.42 16 7.99998 16C3.58001 16 0 12.42 0 8.00002Z"
                fill="white"
            />
            <path
                d="M0 8.00002C0 3.58005 3.58001 4.1008e-05 7.99998 4.1008e-05C12.42 4.1008e-05 16 3.58001 16 8.00002C16 12.42 12.42 16 7.99998 16C3.58001 16 0 12.42 0 8.00002Z"
                fill="#F6BD42"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99999 12.2008C8.55228 12.2008 9 11.7531 9 11.2008C9 10.6485 8.55228 10.2008 7.99999 10.2008C7.4477 10.2008 6.99998 10.6485 6.99998 11.2008C6.99998 11.7531 7.4477 12.2008 7.99999 12.2008ZM7.99999 9.00078C8.55228 9.00078 9 8.55306 9 8.00077L9 4.80079C9 4.2485 8.55228 3.80078 7.99999 3.80078C7.4477 3.80078 6.99998 4.2485 6.99998 4.80079V8.00077C6.99998 8.55306 7.4477 9.00078 7.99999 9.00078Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(WarningRoundSVG);
