import React from 'react';

function WelcomeBoySVG({ ...other }) {
    return (
        <svg {...other} width="111" height="176" viewBox="0 0 111 176" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="55.5" cy="170.5" rx="55.5" ry="5.5" fill="#DBDCE1" />
            <g>
                <path
                    d="M39.3939 40.8341C40.4321 38.7578 55.745 44.5542 54.0148 48.7068C52.371 52.6 37.8366 44.4677 39.3939 40.8341Z"
                    fill="#0252B0"
                />
                <path
                    d="M53.236 32.8757L53.9281 19.293C46.6609 25.349 45.0172 31.6645 39.6533 42.8248C33.1648 49.1403 23.7347 52.9469 13.353 55.8884C12.9205 55.9749 12.5744 56.0614 12.3149 56.2345C9.28689 57.0996 6.43193 59.4355 6.51844 59.868C6.60495 60.3006 8.59478 59.349 8.7678 59.6085C8.94083 59.868 5.73981 61.2523 5.99936 61.6848C6.2589 62.2039 8.33523 61.2523 8.59478 61.4253C8.7678 61.6848 6.08587 62.896 6.43193 63.2421C6.77798 63.5016 8.85432 62.6365 8.94083 62.6365C9.02735 62.723 7.55661 63.6747 7.72964 63.8477C7.90266 64.1072 9.80597 63.5016 9.80597 63.5016C10.152 63.5016 10.4116 63.4151 10.6711 63.4151C12.9205 63.0691 15.2564 62.4635 15.2564 62.4635C32.0401 63.5881 47.8721 60.1276 56.5235 43.7764L53.236 32.8757Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M28.8388 157.456C35.5869 155.034 48.4775 156.158 47.9584 157.889C46.9202 160.225 28.3197 159.532 28.8388 157.456Z"
                    fill="#2A293A"
                />
                <path
                    d="M90.9561 25.3482C85.9383 24.8291 78.4116 31.6637 78.7576 37.8927C79.7093 40.8341 93.7246 26.9054 90.9561 25.3482Z"
                    fill="#0252B0"
                />
                <path
                    d="M56.8697 4.75784C56.1776 6.66115 55.3124 8.2184 55.3124 8.2184L56.5236 8.47794L58.0809 13.9283L52.9766 20.1573L53.2361 32.3558C54.1878 43.3431 56.5236 55.6281 55.6585 67.8265L62.2336 78.2947C62.3201 84.0912 62.6661 89.6281 63.1852 94.7324C63.9638 102.086 65.9537 109.267 68.8951 116.101L71.4906 122.071C74.7781 131.674 76.0758 144.651 76.7679 158.58L69.5007 169.48H93.5517L92.773 159.358C92.9461 147.679 89.572 127.521 85.6789 116.62C83.8621 111.689 82.6509 106.498 82.3048 101.221C81.0071 82.7934 77.3735 74.8342 75.5567 66.3558L73.6534 32.8749L71.7501 15.1395L63.7043 12.7171L59.6381 7.00721C59.6381 4.15225 57.6483 2.94105 56.8697 4.75784Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M55.7457 67.8262L38.962 101.826C36.6261 106.584 35.8475 112.986 35.4149 119.735L29.3589 159.444L19.7559 169.653L43.1146 169.826L44.5854 164.462C45.018 162.992 45.4505 161.348 45.9696 159.877C50.4683 147.159 53.4963 132.539 54.1884 119.475L54.275 118.869C55.0536 109.958 57.649 101.307 61.8882 93.4343C62.2342 92.7422 62.5803 92.0501 63.0129 91.358L62.4073 78.0348L55.7457 67.8262Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M55.3125 8.21785H57.0428C57.1293 8.21785 57.2158 8.13134 57.3023 8.13134C57.5619 7.8718 58.0809 7.17969 58.1675 7.17969C58.254 7.2662 57.8214 8.65042 57.6484 8.90996C57.4754 9.25602 56.9563 9.60207 56.9563 9.60207L56.6967 8.73694L55.3125 8.21785Z"
                    fill="#2A293A"
                />
                <path
                    d="M59.0331 7.0948C59.5522 7.26783 60.1578 5.71057 60.0713 4.32635C59.8982 0.519733 58.687 0.173677 57.4758 0.000648651C56.6107 -0.172379 56.1781 0.0871626 56.0916 0.606247C55.5725 0.519733 55.313 0.692761 55.5725 1.5579L58.1679 5.36452C58.1679 5.36452 57.8219 6.05663 57.9949 6.14314C58.1679 6.22966 58.9466 5.278 59.0331 5.45103C59.5522 5.8836 58.6005 6.92177 59.0331 7.0948Z"
                    fill="#2A293A"
                />
                <path
                    d="M76.1623 68.0875C75.9893 67.4819 75.9028 66.8763 75.7297 66.2707L73.8264 32.7897L71.7501 15.1409L67.857 13.9297C67.0783 16.1791 65.9537 17.9958 64.4829 19.4666C61.5414 22.4081 56.6102 21.1969 55.399 17.3037L52.9766 19.9857L53.3226 32.3572C54.2743 43.2579 56.6102 55.5429 55.745 67.7414C61.628 69.2121 68.6356 69.2121 76.1623 68.0875Z"
                    fill="#2D97F2"
                />
                <path
                    d="M93.6392 169.394L92.8606 159.272C93.0336 147.593 89.6596 127.435 85.7664 116.534C83.9496 111.603 82.7385 106.412 82.3924 101.135C81.2677 84.0914 77.9802 75.9591 76.0769 68.0863C68.5502 69.211 61.5425 69.211 55.7461 67.7402C55.7461 67.7402 55.7461 67.7402 55.7461 67.8267L62.3212 78.2949C62.4077 84.0914 62.7537 89.6283 63.2728 94.7326C64.0514 102.086 66.0413 109.267 68.9827 116.102L71.5782 122.071C74.8657 131.674 76.1634 144.651 76.8555 158.58L69.5883 169.481H93.6392V169.394Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M51.8524 55.3691C52.0254 54.7635 51.9389 53.3793 52.285 53.0332C51.8524 53.3793 51.7659 54.4174 51.8524 55.3691Z"
                    fill="#FED1A0"
                />
                <path
                    d="M53.1505 55.1094C52.891 55.6285 52.5449 56.1476 52.5449 56.1476C52.5449 56.3206 52.5449 56.5801 52.5449 56.7532C52.7179 56.4936 52.9775 55.715 53.1505 55.1094Z"
                    fill="#FED1A0"
                />
                <path
                    d="M54.1876 119.475L54.2741 118.869C55.0528 109.958 57.6482 101.307 61.8874 93.4343C62.2334 92.7422 62.5795 92.0501 63.0121 91.358L62.4065 78.0348L55.7449 67.8262L38.9612 101.826C36.6253 106.584 35.8467 112.986 35.4141 119.735L28.666 157.541C32.0401 155.984 46.5744 156.59 47.8721 157.974C52.1113 145.689 53.4955 132.02 54.1876 119.475Z"
                    fill="#EA6A46"
                />
                <path
                    d="M71.5785 31.5773C71.2324 31.4908 71.5785 33.2211 74.0008 35.384L73.9143 33.3941L71.5785 31.5773Z"
                    fill="#0252B0"
                />
                <path
                    d="M54.1011 47.9291C55.1393 46.4584 56.1774 41.5271 57.1291 39.8833L55.3988 32.2701L53.755 19.293C47.18 24.7433 43.5464 31.578 39.2207 40.835C45.5362 40.4024 51.5922 43.3439 54.1011 47.9291Z"
                    fill="#2D97F2"
                />
                <path
                    d="M71.7516 15.1406C91.9959 23.619 98.2249 33.3951 94.9374 43.9498C94.5913 44.988 94.0723 45.9396 93.2936 46.7182C87.1511 52.9472 73.5684 55.7157 54.7084 56.1483C54.7084 56.1483 53.5837 56.6673 53.4972 56.9269C53.4107 57.1864 54.0162 57.5325 53.6702 57.8785C53.4107 58.2246 52.1995 57.5325 52.459 56.1483C52.459 56.1483 53.2376 55.0236 53.2376 54.591C53.2376 54.1584 52.632 56.9269 52.286 56.7539C51.8534 56.6673 51.4208 53.2933 52.3725 52.8607C51.7669 53.0338 52.1995 55.5427 51.5939 55.6292C50.9883 55.6292 51.6804 51.6495 51.2478 51.39C50.8152 51.217 52.1995 54.5045 50.9017 53.9854C50.0366 51.9956 50.3827 48.7081 52.7185 48.1025C62.1486 45.507 72.0112 42.0465 79.9705 37.8073L71.6651 31.8378C69.0697 30.021 67.2529 27.2526 66.7338 24.1381C66.1282 20.0719 68.118 17.217 71.7516 15.1406Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M91.0436 25.4358C87.237 22.3213 79.1047 16.3518 71.751 15.1406C68.0309 17.217 65.7815 19.8989 66.3871 23.9651C66.8197 27.0796 69.0691 30.021 71.6645 31.9243L78.9317 38.2399C79.0182 33.049 86.4584 30.5401 91.0436 25.4358Z"
                    fill="#2D97F2"
                />
                <path
                    d="M62.4945 92.0501L60.6777 78.554C60.6777 78.4675 60.8508 78.381 60.8508 78.4675C61.3698 79.1596 61.8889 79.5056 62.408 79.3326L63.0136 90.9255L62.4945 92.0501Z"
                    fill="#C43D17"
                />
                <path
                    d="M85.7664 116.534C83.9496 111.603 82.7385 106.412 82.3924 101.135C81.2677 84.0914 77.9802 75.9591 76.0769 68.0863C68.5502 69.211 61.5425 69.211 55.7461 67.7402C55.7461 67.7402 55.7461 67.7402 55.7461 67.8267L62.3212 78.2949C62.4077 84.0914 62.7537 89.6283 63.2728 94.7326C64.0514 102.086 66.0413 109.267 68.9827 116.102L71.5782 122.071C74.8657 131.674 76.0769 143.44 76.769 157.369L92.8606 157.282C92.8606 145.43 89.6596 127.262 85.7664 116.534Z"
                    fill="#EA6A46"
                />
                <path
                    d="M92.8591 159.359H76.4215L69.1543 170.001H93.6378L92.7726 159.878C92.8591 159.705 92.8591 159.532 92.8591 159.359Z"
                    fill="#2A293A"
                />
                <path
                    d="M29.3589 159.445L19.7559 169.654L43.1146 169.827L44.5854 164.463C45.0179 162.992 45.4505 161.348 45.9696 159.877C46.1426 159.358 29.3589 159.445 29.3589 159.445Z"
                    fill="#2A293A"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="89.8015" height="170" fill="white" transform="translate(6)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(WelcomeBoySVG);
