import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'gridUI/actions';
import ViewCellOnly from './ViewCellOnly';
import CellBaseDropdown from 'components/selects/CellBase';
import { Paper, Grid, Popper } from '@material-ui/core';
import ViewCellInfo from './ViewCellInfo';
import isEmpty from 'lodash/isEmpty';
import { isKbEscape, isKbTab, isKbShiftTab } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { getCellBackgroundByHex } from 'utils/color';

function SingleSelect({
    columnId,
    column,
    value: cellValue,
    rowId,
    width,
    height,
    character,
    rowIndex,
    columnIndex,
    background,
    fillColorBlindness,
    originalValue,
    t
}) {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState({ label: cellValue, value: cellValue });
    const theme = useTheme();
    const rootRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorElRef = React.useRef();

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    useEffect(() => {
        if (anchorElRef.current) {
            setAnchorEl(anchorElRef.current);
            setTimeout(() => {
                const input = document.getElementById('single-select-input');
                if (input) {
                    input.focus({ preventScroll: true });
                }
            }, 0);
        }
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'choose-selection' : undefined;

    const singleSelectOptions = useMemo(() => {
        return isEmpty(column.options)
            ? []
            : column.options.map(option => {
                  return {
                      value: option.label,
                      label: option.label
                  };
              });
    }, [column.options]);

    useEffect(() => {
        const keyDownHandler = e => {
            if (isKbEscape(e)) {
                dispatch(gridActions.cancelCellEdit());
            }

            if (isKbTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
            }
        };
        document.addEventListener('keydown', keyDownHandler, true);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, true);
        };
    }, [dispatch, rowIndex, columnIndex]);

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        const selectedValue = selectedOption.label;
        if (checkContainId(e, 'choose-selection')) return false;

        if ((!selectedValue && originalValue === null) || selectedValue === originalValue) {
            return dispatch(gridActions.cancelCellEdit());
        }
        dispatch(
            gridActions.cellClickAway({
                isClickYourSelf,
                value: selectedOption.label,
                rowId,
                columnId
            })
        );
    };

    const handleOptionChange = option => {
        if (option.value === selectedOption.value) {
            setSelectedOption(null);
            dispatch(
                gridActions.cellClickAwayAndStay({
                    isClickYourSelf: false,
                    value: null,
                    rowId,
                    columnId
                })
            );
            return;
        }
        setSelectedOption(option);
        dispatch(
            gridActions.cellClickAwayAndStay({
                isClickYourSelf: false,
                value: option.label,
                rowId,
                columnId
            })
        );
    };

    return (
        <Grid ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <div
                ref={anchorElRef}
                style={{
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white
                }}
            >
                <ViewCellOnly dimension={{ width, height }} value={cellValue} type={column.type} {...column} />
            </div>
            <Popper
                id={id}
                style={{
                    zIndex: 100
                }}
                placement={`bottom-start`}
                open={open}
                anchorEl={anchorEl}
            >
                <Paper className={'min-w-[200px]'}>
                    <CellBaseDropdown
                        options={singleSelectOptions}
                        placeholder={t(`global_label_find_an_option`)}
                        onChange={handleOptionChange}
                        searchKey={character}
                        defaultValue={selectedOption}
                        autoFocus={false}
                        inputId="single-select-input"
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(SingleSelect);
