import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import FavoriteGridItem from './FavoriteGridItem';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto',
        maxHeight: 160,
        minHeight: 160
    }
}));

function FavoriteList({ favoriteGrids }) {
    const { t } = useTranslation();
    const classes = useStyles();

    if (isEmpty(favoriteGrids)) {
        return (
            <Grid className={classes.root} container direction={'column'} style={{ padding: '40px 12px' }}>
                <Typography variant="caption">{t('favorite_instruction')}</Typography>
            </Grid>
        );
    }

    return (
        <Grid
            className={classNames(classes.root, 'scrollbar-app')}
            item
            container
            wrap="nowrap"
            direction={'column'}
            id="favoriteList"
        >
            {favoriteGrids?.map((grid, index) => {
                return (
                    <FavoriteGridItem
                        key={`item-${grid.id}`}
                        index={index}
                        companyId={grid.companyId}
                        dbId={grid.dbId}
                        gridId={grid.gridId}
                        projectId={grid.projectId}
                        name={grid.name}
                        grid={grid}
                    />
                );
            })}
        </Grid>
    );
}

export default React.memo(FavoriteList);
