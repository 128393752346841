import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import ErrorMessage from './ErrorMessage';
import Button from 'components/button/Base';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { sendManualTrack } from 'tracker';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import ArrowBackSVG from 'assets/images/svg/ArrowBackSVG';
import HelpSVG from 'assets/images/svg/HelpSVG';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    createCompanyForm: {
        maxWidth: 470
    },
    btnSubmit: {
        width: 140,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const CreateCompanySchema = object({
    name: string().required(i18n.t(`global_this_field_is_required`)),
    description: string()
});

function CreateCompanyForm({ initFormData, onNext, errorMessage, isSubmitting, onBack }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            name: initFormData?.companyName || '',
            description: initFormData?.companyDescription || ''
        },
        validationSchema: CreateCompanySchema,
        onSubmit: values => {
            console.log('hehe');
            sendManualTrack({ type: `Click On Sign Up Stage 2` });
            onNext(values);
        }
    });
    const { errors, touched, values, handleChange, handleBlur, handleSubmit, isValid } = formik;

    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <form onSubmit={handleSubmit} className={classes.createCompanyForm}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="body1">Create a company name for your workspace</Typography>
                            </Grid>
                            <Tooltip title="Under your company, you can create projects and invite other members to collaborate. You can always change your company name later.">
                                <Grid item style={{ display: 'flex' }}>
                                    <HelpSVG color={theme.colors.steel} />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <InputText
                            name="name"
                            value={values.name || ''}
                            height={40}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.name && touched.name}
                            errorText={errors.name}
                            placeholder={t(`auth_create_company_name_placeholder`)}
                            autoFocus={true}
                        />
                    </Grid>

                    <div className="mt-3.5 gap-2 flex justify-end">
                        <Button
                            width={120}
                            onClick={() => {
                                console.log('damnnnnn');
                                onBack();
                            }}
                            variant="outlined"
                        >
                            <ArrowBackSVG style={{ marginRight: 8 }} />
                            {t(`global_back`)}
                        </Button>
                        <div className={classes.btnSubmit}>
                            <Button fullWidth type="submit" variant="contained" disabled={!isValid || isSubmitting}>
                                {onNext ? t(`global_next`) : t(`global_create`)}
                                <ArrowNextSVG />
                            </Button>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </Grid>
            </form>
        </>
    );
}

export default CreateCompanyForm;
