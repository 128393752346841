import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AvatarBase from 'components/avatar/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import GroupListPopup from './GroupItemPopup';
import * as roleConst from 'auth/roleConst';
import AccessControl from 'auth/AccessControl';
import { DISABLED_OPACITY } from 'const/style';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import { GROUP_ADMIN_TYPES } from 'const';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.background
        },
        padding: 2
    },
    arrow: {
        cursor: 'pointer',
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    groupIcon: {
        border: props => (props.isActive ? '2px solid #7869B9' : '2px solid transparent'),
        opacity: props => props.isAnyGroupSelected && !props.isActive && 0.2,
        borderRadius: '50%',
        padding: 3
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

function GroupItem({ level, group, selectedGroupId, onGroupSelect, onRemoveGroup, disabled }) {
    const isAnyGroupSelected = Boolean(selectedGroupId);
    const isActive = selectedGroupId === group.id;
    const classes = useStyles({ isActive, isAnyGroupSelected });
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    const handleClick = event => {
        event.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    function handleClickAway() {
        setAnchorEl(null);
    }

    function handleGroupSelect() {
        onGroupSelect({ groupId: group.id });
    }

    return (
        <>
            <Grid
                item
                className={`${classes.item} ${disabled ? classes.disabled : ''}`}
                onMouseOver={() => setIsShowMore(true)}
                onMouseLeave={() => setIsShowMore(false)}
                onClick={handleGroupSelect}
            >
                <Tooltip title={group?.name}>
                    <Grid className={classes.groupIcon}>
                        {group.systemGroup ? (
                            group?.type === GROUP_ADMIN_TYPES.ADMIN ? (
                                <AdminGroupAvatar size="large" />
                            ) : (
                                <TranslatorGroupAvatar size="large" />
                            )
                        ) : (
                            <AvatarBase size="large">{group.name.slice(0, 2)}</AvatarBase>
                        )}
                    </Grid>
                </Tooltip>
                {(isShowMore || anchorEl) && group?.type !== GROUP_ADMIN_TYPES.ADMIN && (
                    <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP}>
                        <ArrowDownSVG className={classes.arrow} onClick={handleClick} />
                    </AccessControl>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} handleClickAway={handleClickAway} placement={'bottom-start'}>
                    <GroupListPopup
                        level={level}
                        handleClickAway={handleClickAway}
                        selectedGroupId={selectedGroupId}
                        group={group}
                        onRemoveGroup={onRemoveGroup}
                        t={t}
                    />
                </PopperMenu>
            )}
        </>
    );
}

export default GroupItem;
