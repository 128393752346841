import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import ButtonBase from 'components/button/Base';
import { INPUT_RADIUS } from 'const/style';
import * as paymentActions from 'payment/actions';
import { useDispatch } from 'react-redux';
import { usePublicKey } from 'hooks/payment';
import { createSetupIntentApi } from 'services/payment';
import CardSecuredSVG from 'assets/images/svg/payment/CardSecuredSVG';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    cardInput: {
        padding: `11px 14px`,
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: INPUT_RADIUS
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    error: {
        color: theme.colors.snackBarError
    },
    progress: {
        marginTop: 50,
        marginLeft: 100
    },
    form: {
        width: 400
    },
    cardSecure: {
        marginLeft: 'auto'
    },
    coloredText: {
        color: theme.colors.lightGreyBlue
    }
}));

const CheckoutForm = ({ onSuccess, onBack }) => {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleSubmit = async event => {
        // Block native form submission.
        event.preventDefault();
        sendManualTrack({ type: 'Save payment card' });

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement('card').focus();
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        setIsSubmitting(true);
        // Use your card Element with other Stripe.js APIs
        let setupIntent = await createSetupIntentApi();
        const payload = await stripe.confirmCardSetup(setupIntent.client_secret, {
            payment_method: {
                card: cardElement
            }
        });

        if (payload.error) {
            setIsSubmitting(false);
            setError(payload.error.message);
        } else {
            // The PaymentMethod was successfully set up
            dispatch(
                paymentActions.fetchCardInfoAfterUpdate({
                    errorCallback: () => {
                        console.log('fetchCardInfo failed');
                    },
                    successCallback: () => {
                        console.log('fetchCardInfo success');
                        setIsSubmitting(false);
                        onBack();
                    }
                })
            );
            onSuccess && onSuccess();
        }
    };

    const CARD_ELEMENT_OPTIONS = {
        iconStyle: 'solid',
        hidePostalCode: true,
        style: {
            base: {
                iconColor: theme.colors.lightGrey,
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                color: theme.colors.primaryText,
                '::placeholder': {
                    color: theme.colors.lightGrey
                }
            },
            invalid: {
                color: theme.colors.primaryText,
                iconColor: theme.colors.snackBarError,
                ':focus': {
                    color: '#303238'
                }
            }
        }
    };

    const handleCardChange = result => {
        if (result?.error) {
            setError(result?.error?.message);
        } else {
            setError('');
        }
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={2}>
                <Grid item container>
                    <Grid item>
                        <Typography variant="body1">{t('enter_your_payment_details')}</Typography>
                    </Grid>
                    <Grid item className={classes.cardSecure}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Typography className={classes.coloredText} variant="body2">
                                    Secure form
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CardSecuredSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <div className={classes.cardInput}>
                        <CardElement onChange={handleCardChange} options={CARD_ELEMENT_OPTIONS} />
                    </div>
                    <Typography className={classes.error} variant="caption">
                        &nbsp;{error}
                    </Typography>
                </Grid>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item className={classes.buttonWrapper}>
                        <ButtonBase width={120} variant="contained" type="submit" disabled={!stripe || isSubmitting}>
                            Save
                        </ButtonBase>
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={onBack} variant="outlined" width={120}>
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

function PaymentInfo({ onBack }) {
    const dispatch = useDispatch();
    const publicKey = usePublicKey();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(
            paymentActions.fetchPublicKey({
                errorCallback: () => {
                    console.log('fetchPublicKey success');
                },
                successCallback: () => {
                    console.log('fetchPublicKey failed');
                }
            })
        );
    }, [dispatch]);

    if (!publicKey)
        return (
            <div className={classes.progress}>
                <CircularProgress size={24} />
            </div>
        );

    return (
        <Elements stripe={loadStripe(publicKey)}>
            <CheckoutForm onBack={onBack} />
        </Elements>
    );
}

export default React.memo(PaymentInfo);
