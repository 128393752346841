import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { POPUP_PADDING_TOP_BOTTOM } from 'const/style';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import ListItem from 'components/list/Item';
// import * as tmActions from './actions';
import trim from 'lodash/trim';
// import EditSVG from 'assets/images/svg/EditSVG';
import { updateTagModel } from 'gridUI/actions';
import ExportSVG from 'assets/images/svg/ExportSVG';
import ImportSVG from 'assets/images/svg/ImportSVG';
import { useDropzone } from 'react-dropzone';
import { getUploadErrorMessage } from 'utils/upload';
import { enqueueSnackbar } from 'notifier/actions';
import { GRID_UPLOAD_MAX_SIZE, TB_UPLOAD_SUPPORTED_EXTENSIONS } from 'const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM
    },

    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        marginBottom: theme.spacing(2)
    },
    input: {
        backgroundColor: theme.colors.paleGrey,
        borderRadius: 4,
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            border: 'none',
            '&:focus': {
                boxShadow: 'none'
            }
        }
    },
    footer: {
        borderTop: `1px solid ${theme.colors.divider}`,
        paddingTop: theme.spacing(2)
    },
    listItem: {
        '& .textContent': {
            color: theme.colors.brightRed
        }
    }
}));

function TBPopup({ name, handleClickAway, tag, onEdit, onDelete, handleImportTokenSets, handleExportTag }) {
    const [tagName, setTagName] = useState(name);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    const clickAwayHandler = () => {
        if (trim(tagName) === '') {
            handleClickAway();
            return;
        }
        if (tagName === name) {
            handleClickAway();
            return;
        }

        dispatch(
            updateTagModel({
                newTag: {
                    ...tag,
                    name: tagName
                },
                oldTag: tag,
                successCallback: () => {
                    console.log('update success');
                },
                errorCallback: error => {
                    console.log('error update');
                }
            })
        );
        handleClickAway();
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };

    const _export = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            handleClickAway();
            handleExportTag(tag);
        },
        [handleExportTag, tag, handleClickAway]
    );

    const onDropLocalizationRejected = useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, TB_UPLOAD_SUPPORTED_EXTENSIONS);
            const fileError = fileErrors?.[0];

            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const handleFileImportInput = files => {
        handleClickAway();
        handleImportTokenSets(tag.id, files);
    };

    const {
        getRootProps: getRootPropsLocalization,
        getInputProps: getInputPropsLocalization,
        isDragActive: isDragActiveLocalization
    } = useDropzone({
        noDragEventsBubbling: true,
        onDropAccepted: handleFileImportInput,
        onDropRejected: onDropLocalizationRejected,
        accept: TB_UPLOAD_SUPPORTED_EXTENSIONS,
        multiple: false,
        maxSize: GRID_UPLOAD_MAX_SIZE
    });

    return (
        <Grid ref={rootRef} id={`tb_popup`} container direction="column" className={classes.root}>
            <Grid item className={classes.name}>
                <InputText
                    id={`dbInput`}
                    name="tagName"
                    value={tagName}
                    onChange={e => setTagName(e.target.value)}
                    placeholder={t('global_name')}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    className={classes.input}
                    autoSelect={true}
                />
            </Grid>
            {/* <Grid item>
                <ListItem onClick={onEdit} icon={<EditSVG />} name={t('edit_tag_model')} />
            </Grid> */}
            <Grid
                item
                {...getRootPropsLocalization({
                    style: {
                        outline: 'none',
                        border: isDragActiveLocalization ? `2px dashed #B4B4B4` : '2px dashed transparent',
                        boxSizing: 'content-box'
                    }
                })}
            >
                <input
                    {...getInputPropsLocalization()}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    tr-dt="Import token sets"
                />
                <ListItem icon={<ImportSVG />} name={t('update_tag')} />
            </Grid>
            <Grid item>
                <ListItem onClick={_export} icon={<ExportSVG />} name={t('export_tag')} />
            </Grid>
            <Grid item>
                <ListItem
                    className={classes.listItem}
                    onClick={onDelete}
                    icon={<DeleteSVG color={theme.colors.brightRed} />}
                    name={t('delete_custom_tag')}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(TBPopup);
