import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { beautifyPrice } from 'utils/payment';

const useStyles = makeStyles(theme => ({
    root: {},
    icon: {
        display: 'flex'
    },
    midGrey: {
        color: theme.colors.midGrey
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}));

function AddonSubscription({ addon, onCancel, Warning }) {
    const classes = useStyles();
    const addonSubscription = addon?.subscription;

    return (
        <Grid className={classes.root}>
            <Grid container alignItems="center">
                <Grid item xs={1} style={{ display: 'flex' }}>
                    {addon.icon(32)}
                </Grid>
                <Grid item xs={7}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item style={{ display: 'flex' }}>
                            <Typography variant="body1">{addon.title}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" className={classes.midGrey}>
                                {addon.subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" display="inline">
                        {beautifyPrice({
                            unitAmount: addonSubscription?.price?.unitAmount,
                            currency: addonSubscription?.price?.currency
                        })}
                    </Typography>
                    <Typography variant="body2" display="inline" className={classes.midGrey}>
                        /{addon?.unit || addonSubscription?.price?.recurringInterval}
                    </Typography>
                </Grid>
                {!Boolean(addonSubscription?.endDate) && (
                    <Grid item xs={2}>
                        <ButtonBase variant="outlined" width={100} onClick={onCancel}>
                            Cancel
                        </ButtonBase>
                    </Grid>
                )}
                {Warning && (
                    <Grid item xs={10} style={{ marginTop: 14 }}>
                        {Warning}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(AddonSubscription);
