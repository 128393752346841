import React from 'react';

function GetStartedBkgSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="569" height="940" viewBox="0 0 569 940" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M457.615 661.231C460.164 661.231 462.231 659.164 462.231 656.615C462.231 654.066 460.164 652 457.615 652C455.066 652 453 654.066 453 656.615C453 659.164 455.066 661.231 457.615 661.231Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M473 661.231C475.549 661.231 477.615 659.164 477.615 656.615C477.615 654.066 475.549 652 473 652C470.451 652 468.385 654.066 468.385 656.615C468.385 659.164 470.451 661.231 473 661.231Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M473 676.615C475.549 676.615 477.615 674.549 477.615 672C477.615 669.451 475.549 667.385 473 667.385C470.451 667.385 468.385 669.451 468.385 672C468.385 674.549 470.451 676.615 473 676.615Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M473 692C475.549 692 477.615 689.934 477.615 687.385C477.615 684.836 475.549 682.769 473 682.769C470.451 682.769 468.385 684.836 468.385 687.385C468.385 689.934 470.451 692 473 692Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M488.385 661.231C490.934 661.231 493 659.164 493 656.615C493 654.066 490.934 652 488.385 652C485.836 652 483.769 654.066 483.769 656.615C483.769 659.164 485.836 661.231 488.385 661.231Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M488.385 676.615C490.934 676.615 493 674.549 493 672C493 669.451 490.934 667.385 488.385 667.385C485.836 667.385 483.769 669.451 483.769 672C483.769 674.549 485.836 676.615 488.385 676.615Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M488.385 692C490.934 692 493 689.934 493 687.385C493 684.836 490.934 682.769 488.385 682.769C485.836 682.769 483.769 684.836 483.769 687.385C483.769 689.934 485.836 692 488.385 692Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.3846 9.23077C43.8356 9.23077 41.7692 7.16439 41.7692 4.61539C41.7692 2.06638 43.8356 0 46.3846 0C48.9336 0 51 2.06638 51 4.61539C51 7.16439 48.9336 9.23077 46.3846 9.23077Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 9.23077C28.451 9.23077 26.3846 7.16439 26.3846 4.61539C26.3846 2.06638 28.451 0 31 0C33.549 0 35.6154 2.06638 35.6154 4.61539C35.6154 7.16439 33.549 9.23077 31 9.23077Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 24.6154C28.451 24.6154 26.3846 22.549 26.3846 20C26.3846 17.451 28.451 15.3846 31 15.3846C33.549 15.3846 35.6154 17.451 35.6154 20C35.6154 22.549 33.549 24.6154 31 24.6154Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 40.0001C28.451 40.0001 26.3846 37.9337 26.3846 35.3847C26.3846 32.8357 28.451 30.7693 31 30.7693C33.549 30.7693 35.6154 32.8357 35.6154 35.3847C35.6154 37.9337 33.549 40.0001 31 40.0001Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6153 9.23077C13.0663 9.23077 11 7.16439 11 4.61539C11 2.06638 13.0663 0 15.6153 0C18.1643 0 20.2307 2.06638 20.2307 4.61539C20.2307 7.16439 18.1643 9.23077 15.6153 9.23077Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6153 24.6154C13.0663 24.6154 11 22.549 11 20C11 17.451 13.0663 15.3846 15.6153 15.3846C18.1643 15.3846 20.2307 17.451 20.2307 20C20.2307 22.549 18.1643 24.6154 15.6153 24.6154Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6153 40.0001C13.0663 40.0001 11 37.9337 11 35.3847C11 32.8357 13.0663 30.7693 15.6153 30.7693C18.1643 30.7693 20.2307 32.8357 20.2307 35.3847C20.2307 37.9337 18.1643 40.0001 15.6153 40.0001Z"
                fill="white"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.6154 433.231C95.1644 433.231 97.2308 431.164 97.2308 428.615C97.2308 426.066 95.1644 424 92.6154 424C90.0664 424 88 426.066 88 428.615C88 431.164 90.0664 433.231 92.6154 433.231Z"
                fill="white"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.6154 448.615C95.1644 448.615 97.2308 446.549 97.2308 444C97.2308 441.451 95.1644 439.385 92.6154 439.385C90.0664 439.385 88 441.451 88 444C88 446.549 90.0664 448.615 92.6154 448.615Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108 448.615C110.549 448.615 112.615 446.549 112.615 444C112.615 441.451 110.549 439.385 108 439.385C105.451 439.385 103.385 441.451 103.385 444C103.385 446.549 105.451 448.615 108 448.615Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M401.879 445.197C390.87 462.481 379.468 480.121 374.001 502.049C362.747 485.28 350.72 467.625 334.374 449.847L330.145 443.846C330.145 443.846 326.384 438.446 324.508 439.047C322.628 439.645 326.857 445.496 325.446 446.396C324.036 447.297 313.67 437.487 310.699 440.835C309.386 442.314 317.458 445.496 316.676 445.647C315.893 445.798 305.889 439.668 306.222 443.335C306.51 446.507 314.17 447.898 313.857 448.647C313.545 449.397 307.317 443.572 306.222 445.669C305.124 447.77 311.664 451.346 311.039 452.848C310.411 454.347 307.277 449.397 306.024 451.497C304.771 453.597 352.241 525.469 370.585 537.99C375.719 541.493 382.429 542.024 387.964 539.131C407.033 529.163 411.037 501.918 413 477.747L401.879 445.197Z"
                fill="#FFDECE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M401.848 445C390.809 462.14 379.376 479.632 373.894 501.376C364.761 487.92 355.081 473.885 343.049 459.678C342.081 469.134 331.917 473.785 323 480.966C337.831 502.169 360.303 530.151 370.469 537.015C375.617 540.489 382.345 541.015 387.895 538.147C407.017 528.263 411.031 501.246 413 477.277L401.848 445Z"
                fill="#D31562"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M354 556.902C328.703 496.496 277.478 433.719 237.728 441.687C208.816 447.484 184.362 533.168 172.581 585.456L141 617H223.815C234.743 587.364 243.094 545.308 250.314 497.801C264.372 539.296 289.797 573.937 319.881 606.373L354 556.902Z"
                fill="#FFD29F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M172.699 585.875L141 617H224.125C227.671 607.543 230.946 596.789 234 585H172.902C172.834 585.289 172.764 585.588 172.699 585.875Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M173 585.114H234.133C240.531 560.026 245.961 530.283 250.866 498.016C264.985 539.666 290.52 574.442 320.734 607L355 557.342C329.593 496.707 278.147 433.689 238.226 441.69C209.349 447.473 184.908 532.555 173 585.114Z"
                fill="#FDBC11"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M400.096 407.297L404.406 409.957L403.741 428.575L354.683 556.898C296.888 552.942 249.597 554.619 221.431 566.538C205.254 594.363 209.644 672.587 211.816 731.762L179 767H269.161L272.596 745.107C273.409 739.93 274.019 734.712 274.414 729.485C278.223 679.07 282.328 640.991 273.804 615.075L366.507 617.524C370.874 617.662 375.757 616.684 380.627 615.14C397.808 609.692 412.438 598.091 422.375 583.015C449.171 542.366 458.172 500.757 433.995 453C409.819 405.243 422.305 425.915 422.305 425.915C418.737 417.386 416.3 408.307 416.233 399.058C416.208 395.142 412.395 393.322 408.05 396.992L400.096 407.297Z"
                fill="#FFDECE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M412.255 401L407.581 403.408C406.426 404.003 405.046 403.909 403.977 403.159L403.306 402.531L395.201 405.007C394.288 405.305 393.742 406.339 394.122 407.247C395.265 409.963 398.592 411.382 403.321 411.969C404.298 412.091 405.308 411.852 406.134 411.295C409.772 408.852 412.613 405.925 414 402.124L412.255 401Z"
                fill="#1A1A1A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M211.737 732.448L179 768H268.945L272.371 745.912C273.085 741.298 273.603 736.654 274 732H211.72C211.727 732.149 211.732 732.301 211.737 732.448Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M448.979 460.823C445.979 451.537 440.442 443.271 433.317 436.607L422.335 426.334L418.846 418L404.026 422.348L403.789 428.986L355 556.375L412.7 595C416.274 591.315 419.553 587.324 422.405 583.003C446.264 546.874 462.77 503.538 448.979 460.823Z"
                fill="#E94C8B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M406 396.405C409.476 400.346 412.879 403.122 416.168 404C420.545 395.522 422.582 389.555 419.571 389.005C416.297 385.456 410.899 392.117 406 396.405Z"
                fill="#FDBC11"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M294.375 454.925C294.459 455.316 294.544 455.697 294.625 456.075C294.571 455.658 294.492 455.273 294.375 454.925Z"
                fill="#FFD29F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M308 445.84C308.939 446.344 309.99 446.782 311 447.16C309.981 446.635 308.927 446.134 308 445.84Z"
                fill="#FFC88A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M340 467.923L329.777 475L303 448.077L313.223 441L340 467.923Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M332 473.254L329.421 475L303 448.159L305.127 446L332 473.254Z"
                fill="#0029B8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M294.269 461.095C293.595 461.478 294.086 458.926 293.916 456.863C294.392 459.037 294.706 460.847 294.269 461.095ZM442.979 458.278C441.218 453.313 438.879 449.011 435.995 445.543C431.391 440.01 423.723 439.441 418.437 444.331C403.525 458.125 390.393 489.369 377.723 518.864L346.158 488.778C334.901 478.049 325.303 465.795 316.501 452.977C315.654 451.743 314.894 451.107 314.36 451.507C313.046 452.499 313.492 455.481 313.587 457.732L311.89 456.115C311.89 456.115 304.335 444.777 302.913 444.827C298.091 444.992 304.909 454.953 303.911 455.451C302.913 455.949 300.42 443.167 297.095 444.329C295.2 444.992 299.588 455.949 298.592 456.778C297.594 457.609 297.594 447.982 292.94 448.478C292.033 448.575 292.82 452.016 293.562 455.283C293.251 454.622 292.71 454.244 291.777 454.455C288.118 455.286 298.013 484.408 308.234 501.6C323.312 531.393 338.046 548.603 352.773 559.009C372.116 572.675 398.109 570.481 415.431 554.341C433.129 537.858 443.701 512.972 445.82 482.242C446.379 474.137 445.694 465.935 442.979 458.278Z"
                fill="#FFDECE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M313 441.326L339.108 468L341 463.594L315.516 438L313 441.326Z"
                fill="#D1E5F0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M359 554.541C362.216 561.463 367.701 566.537 376.101 569.233C389.923 573.676 405.024 569.488 415.558 559.501C427.805 547.892 436.003 530.135 440 506C430.557 541.849 385.896 549.811 359 554.541Z"
                fill="#CD3711"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M278 554.741C292.181 550.329 321.599 550.027 348.332 549L354 562L279.889 557.991L278 554.741Z"
                fill="#FDAD11"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M354.264 557.333L354.042 557.913C296.266 553.967 248.991 555.638 220.831 567.537C204.702 595.238 209.028 672.992 211.207 732H273.617C273.682 731.267 273.771 730.536 273.824 729.8C277.628 679.666 281.689 641.785 273.189 615.98L365.862 618.425C370.228 618.563 375.109 617.587 379.977 616.046C392.22 612.169 403.123 605.135 412 595.987L354.264 557.333Z"
                fill="url(#paint0_radial)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M443.635 458.085C441.899 453.278 439.614 449.101 436.815 445.712C432.362 440.321 423.52 437.743 418.379 442.488C403.437 456.278 391.024 489.41 378.329 518.887L335.455 476.275C328.61 486.554 318.448 494.845 308 500.447C308.231 500.844 308.462 501.245 308.694 501.63C323.803 531.41 338.566 548.611 353.326 559.013C372.705 572.673 398.758 570.48 416.12 554.348C433.845 537.877 445.776 514.487 447.904 483.783C448.475 475.589 446.424 465.814 443.635 458.085Z"
                fill="#E94C8B"
            />
            <rect x="273" y="76" width="96" height="96" rx="6" fill="#8B77DF" />
            <rect opacity="0.250581" x="217" y="249" width="96" height="26" rx="4" fill="#D1C6FF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M101 149C98.7909 149 97 150.791 97 153C97 155.209 98.7909 157 101 157H189C191.209 157 193 155.209 193 153C193 150.791 191.209 149 189 149H101ZM101 169C98.7909 169 97 170.791 97 173C97 175.209 98.7909 177 101 177H159C161.209 177 163 175.209 163 173C163 170.791 161.209 169 159 169H101Z"
                fill="#D1C6FF"
            />
            <rect x="49" y="149" width="28" height="28" rx="14" fill="#D1C6FF" />
            <rect opacity="0.183547" x="49" y="204" width="125" height="103" rx="6" fill="#D1C6FF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M454.128 137.75C455.714 138.312 457 140.215 457 142C457 143.785 455.715 145.69 454.129 146.256C452.543 146.819 451.003 148.194 450.556 149.275C450.108 150.356 450.227 152.416 450.95 153.937C451.673 155.456 451.235 157.712 449.973 158.975C448.711 160.236 446.455 160.674 444.935 159.951C443.416 159.227 441.353 159.109 440.272 159.559C439.191 160.009 437.818 161.55 437.255 163.133C436.69 164.716 434.785 166 433 166C431.215 166 429.31 164.716 428.745 163.133C428.181 161.55 426.808 160.007 425.726 159.559C424.645 159.11 422.585 159.227 421.064 159.95C419.544 160.673 417.289 160.235 416.026 158.974C414.764 157.711 414.327 155.455 415.05 153.936C415.773 152.416 415.89 150.356 415.44 149.276C414.991 148.195 413.457 146.818 411.871 146.255C410.285 145.69 409 143.785 409 142C409 140.215 410.285 138.312 411.871 137.75C413.457 137.189 414.989 135.812 415.438 134.729C415.888 133.646 415.773 131.585 415.05 130.064C414.327 128.544 414.764 126.289 416.026 125.026C417.289 123.764 419.545 123.327 421.064 124.05C422.584 124.773 424.645 124.891 425.726 124.443C426.807 123.996 428.181 122.457 428.745 120.871C429.31 119.285 431.215 118 433 118C434.785 118 436.69 119.285 437.255 120.871C437.818 122.457 439.193 123.996 440.274 124.443C441.355 124.891 443.415 124.773 444.935 124.05C446.455 123.327 448.711 123.764 449.973 125.026C451.235 126.289 451.673 128.545 450.95 130.064C450.226 131.584 450.11 133.647 450.558 134.731C451.005 135.815 452.542 137.189 454.128 137.75ZM424.193 142.01C424.193 146.869 428.131 150.807 433 150.807C437.86 150.807 441.797 146.869 441.797 142.01C441.797 137.14 437.859 133.203 433 133.203C428.131 133.203 424.193 137.14 424.193 142.01Z"
                fill="#8C4DD6"
            />
            <path
                d="M454.042 247.125C450.396 229.487 434.771 216 415.5 216C400.396 216 387.375 224.3 381.125 236.75C364.979 238.825 353 251.794 353 267.875C353 284.994 367.062 299 384.25 299H451.958C466.542 299 478 287.588 478 273.062C478 259.575 467.062 248.162 454.042 247.125Z"
                fill="#453488"
            />
            <path
                d="M454.042 240.125C450.396 222.487 434.771 209 415.5 209C400.396 209 387.375 217.3 381.125 229.75C364.979 231.825 353 244.794 353 260.875C353 277.994 367.062 292 384.25 292H451.958C466.542 292 478 280.588 478 266.062C478 252.575 467.062 241.162 454.042 240.125Z"
                fill="url(#paint1_linear)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M343 106C343 101.031 338.97 97 334 97C329.029 97 325 101.031 325 106C325 110.969 329.029 115 334 115C338.969 115 343 110.969 343 106ZM320.999 152H350.356C352.876 152 353.746 150.322 352.304 148.25L342.33 133.97C340.885 131.901 338.277 131.679 336.507 133.476L332.655 137.385C330.883 139.183 328.207 139.012 326.676 137.006L310.93 116.382C309.399 114.376 307.237 114.577 306.102 116.832L290.443 147.918C289.307 150.173 290.43 152 292.95 152H320.999Z"
                fill="#6853BE"
            />
            <rect x="217" y="140" width="96" height="96" rx="6" fill="#B9ECFF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M287 170C287 165.031 282.97 161 278 161C273.029 161 269 165.031 269 170C269 174.969 273.029 179 278 179C282.969 179 287 174.969 287 170ZM264.999 216H294.356C296.876 216 297.746 214.322 296.304 212.25L286.33 197.97C284.885 195.901 282.277 195.679 280.507 197.476L276.655 201.385C274.883 203.183 272.207 203.012 270.676 201.006L254.93 180.382C253.399 178.376 251.237 178.577 250.102 180.832L234.443 211.918C233.307 214.173 234.43 216 236.95 216H264.999Z"
                fill="#F5FCFF"
            />
            <path
                opacity="0.843076"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88 256C88 242.767 98.7656 232 112 232C125.234 232 136 242.767 136 256C136 269.233 125.234 280 112 280C98.7656 280 88 269.233 88 256ZM107.541 265.841L121.541 256.841C121.826 256.657 122 256.34 122 256C122 255.66 121.826 255.343 121.541 255.159L107.541 246.159C107.234 245.959 106.838 245.948 106.522 246.122C106.199 246.298 106 246.634 106 247V265C106 265.366 106.199 265.702 106.522 265.878C106.67 265.959 106.836 266 107 266C107.188 266 107.377 265.947 107.541 265.841Z"
                fill="#D1C6FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M414.217 236.328C414.44 235.554 415.155 235 416 235C417.033 235 417.857 235.824 417.857 236.857L417.854 257.399L424.887 250.689L425.108 250.497L425.364 250.336C426.068 249.966 426.933 250.09 427.532 250.689C428.28 251.437 428.28 252.587 427.532 253.335L417.324 263.49L417.088 263.692L416.849 263.837C416.599 263.962 416.316 264.025 416 264.025C415.474 264.025 415.035 263.849 414.677 263.491L404.575 253.336L404.383 253.114L404.221 252.858C403.851 252.154 403.975 251.288 404.574 250.689C405.322 249.941 406.472 249.941 407.219 250.689L413.916 257.387L414.143 236.857L414.161 236.594L414.217 236.328ZM433.36 262.916C433.583 262.141 434.298 261.587 435.143 261.587C436.176 261.587 437 262.411 437 263.444V270.357L436.982 270.62L436.926 270.886C436.703 271.66 435.988 272.214 435.143 272.214H396.857L396.594 272.197L396.328 272.141C395.554 271.917 395 271.202 395 270.357V263.444L395.018 263.182L395.074 262.916C395.297 262.141 396.012 261.587 396.857 261.587C397.89 261.587 398.714 262.411 398.714 263.444V267.964L399.156 268.462L432.75 268.5L433.247 268.058L433.286 263.444L433.304 263.182L433.36 262.916Z"
                fill="#C1B4F3"
            />
            <path
                opacity="0.296945"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M284.5 1150C441.624 1150 569 1022.62 569 865.5C569 708.374 441.624 581 284.5 581C127.376 581 0 708.374 0 865.5C0 1022.62 127.376 1150 284.5 1150ZM284.5 999.662C210.402 999.662 150.338 939.596 150.338 865.5C150.338 791.403 210.402 731.338 284.5 731.338C358.598 731.338 418.662 791.403 418.662 865.5C418.662 939.596 358.598 999.662 284.5 999.662Z"
                fill="url(#paint2_linear)"
            />
            <defs>
                <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(235.195 576.203) rotate(47.4535) scale(111.366 113.867)"
                >
                    <stop stopColor="#FFEE88" />
                    <stop offset="1" stopColor="#FDBC11" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="272.964"
                    y1="182.094"
                    x2="297.92"
                    y2="323.324"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BFB2E6" />
                    <stop offset="1" stopColor="#705DBC" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="77.8078"
                    y1="614.221"
                    x2="144.25"
                    y2="1027.61"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7D5ED8" />
                    <stop offset="1" stopColor="#58479D" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(GetStartedBkgSVG);
