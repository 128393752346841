import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    fetchApiKeysApi,
    createApiKeyApi,
    updateApiKeyApi,
    deleteApiKeyApi,
    fetchApiKeyByApiKeyApi,
    renewApiKeyApi,
    revokeApiKeyApi,
    duplicateApiKeyApi
} from 'services/apiKeys';

export function fetchApiKeys({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchApiKeys());
        try {
            const apiKeys = await fetchApiKeysApi();
            dispatch(_fetchApiKeysSuccess({ apiKeys }));
            successCallback && successCallback();
        } catch (error) {
            const { message, originalMessage } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            dispatch(_fetchApiKeysFailed({ error: originalMessage }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchApiKeys() {
    return {
        type: types.FETCH_API_KEYS
    };
}

function _fetchApiKeysSuccess({ apiKeys }) {
    return {
        type: types.FETCH_API_KEYS_SUCCESS,
        payload: {
            apiKeys
        }
    };
}

function _fetchApiKeysFailed({ error }) {
    return {
        type: types.FETCH_API_KEYS_FAILED,
        payload: {
            error
        }
    };
}

export function createApiKey({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const apiKey = await createApiKeyApi({ body });
            dispatch(_createApiKeySuccess({ apiKey }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _createApiKeySuccess({ apiKey }) {
    return {
        type: types.CREATE_API_KEY_SUCCESS,
        payload: {
            apiKey
        }
    };
}

export function updateApiKey({ apiKeyId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const apiKey = await updateApiKeyApi({ apiKeyId, body });
            dispatch(_updateApiKeySuccess({ apiKey, apiKeyId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateApiKeySuccess({ apiKeyId, apiKey }) {
    return {
        type: types.UPDATE_API_KEY_SUCCESS,
        payload: {
            apiKeyId,
            apiKey
        }
    };
}

export function renewApiKey({ apiKeyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const apiKey = await renewApiKeyApi({ apiKeyId });
            dispatch(_updateApiKeySuccess({ apiKey, apiKeyId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function duplicateApiKey({ apiKeyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const apiKey = await duplicateApiKeyApi({ apiKeyId });
            dispatch(_createApiKeySuccess({ apiKey }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function revokeApiKey({ apiKeyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const apiKey = await revokeApiKeyApi({ apiKeyId });
            dispatch(_updateApiKeySuccess({ apiKey, apiKeyId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function deleteApiKey({ apiKeyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_deleteApiKey({ apiKeyId }));
        try {
            await deleteApiKeyApi({ apiKeyId });
            dispatch(_deleteApiKeySuccess({ apiKeyId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            dispatch(_deleteApiKeyFailed({ apiKeyId }));
            errorCallback && errorCallback();
        }
    };
}

function _deleteApiKey({ apiKeyId }) {
    return {
        type: types.DELETE_API_KEY,
        payload: {
            apiKeyId
        }
    };
}

function _deleteApiKeySuccess({ apiKeyId }) {
    return {
        type: types.DELETE_API_KEY_SUCCESS,
        payload: {
            apiKeyId
        }
    };
}

function _deleteApiKeyFailed({ apiKeyId }) {
    return {
        type: types.DELETE_API_KEY_FAILED,
        payload: {
            apiKeyId
        }
    };
}

export function fetchApiKeyByApiKeyId({ apiKeyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchApiKeyByApiKeyId({ apiKeyId }));
        try {
            const apiKey = await fetchApiKeyByApiKeyApi({ apiKeyId });
            dispatch(_fetchApiKeyByApiKeyIdSuccess({ apiKeyId, apiKey }));
            successCallback && successCallback();
        } catch (error) {
            const { message, originalMessage } = error;
            dispatch(
                enqueueSnackbar({
                    message: message,
                    type: 'error'
                })
            );
            dispatch(_fetchApiKeyByApiKeyIdFailed({ error: originalMessage }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchApiKeyByApiKeyId() {
    return {
        type: types.FETCH_API_KEY_DETAIL
    };
}

function _fetchApiKeyByApiKeyIdFailed({ error }) {
    return {
        type: types.FETCH_API_KEY_DETAIL_FAILED,
        payload: {
            error
        }
    };
}

function _fetchApiKeyByApiKeyIdSuccess({ apiKey, apiKeyId }) {
    return {
        type: types.FETCH_API_KEY_DETAIL_SUCCESS,
        payload: {
            apiKey,
            apiKeyId
        }
    };
}
