import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ColorLinearProgress from 'components/progress/ColorLinearProgress';

function ProgressLinear({ icon, title = 'Importing ...', foreground, background }) {
    return (
        <Grid container alignItems="center" spacing={3} direction="row">
            {icon && <Grid item>{icon}</Grid>}
            <Grid item style={{ flex: 1 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body2">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <ColorLinearProgress background={background} foreground={foreground} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ProgressLinear);
