import React from 'react';

function AddCommentSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/Add Comment">
                <path
                    id="Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3068 2.24316C15.2314 1.26786 14.4162 0.5 13.4216 0.5H2.39094L2.24316 0.505689C1.26786 0.581074 0.5 1.39629 0.5 2.39094V10.9062L0.506451 11.0415C0.574731 11.7536 1.1766 12.3125 1.90719 12.3125L12.2722 12.3397L12.3671 12.3492C12.46 12.3679 12.5462 12.4137 12.6144 12.4812L14.4856 14.3525L14.5492 14.4066C14.8576 14.6274 15.3125 14.4119 15.3125 14.0094V2.39094L15.3068 2.24316ZM2.29906 1.8125H13.5144L13.6019 1.82033C13.8289 1.86145 14 2.05969 14 2.29719V12.0106L13.8406 11.8503L13.1722 11.1509L13.1139 11.0982C13.031 11.0348 12.9281 11 12.8216 11H2.29906L2.21154 10.9922C1.98455 10.9512 1.81344 10.7536 1.81344 10.5153V2.29719L1.82123 2.2102C1.86221 1.98435 2.0599 1.8125 2.29906 1.8125Z"
                    fill={color}
                />
                <path
                    id="Fill 4"
                    d="M8.50358 3V5.59662H11.1002V6.80338H8.50358V9.4H7.29681V6.80338H4.7002V5.59662H7.29681V3H8.50358Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(AddCommentSVG);
