import * as types from '../types';
import { getRolesApi, createRoleApi, deleteRoleApi, updateRoleApi } from 'services/roles';
import { enqueueSnackbar } from 'notifier/actions';
import i18n from 'i18n';

export function fetchRoles({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_fetchRolesAction());
        try {
            const roles = await getRolesApi({ companyId });
            dispatch(_fetchRolesActionSuccess({ roles }));
            successCallback && successCallback({ roles });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchRolesAction() {
    return {
        type: types.FETCH_ROLES
    };
}

function _fetchRolesActionSuccess({ roles }) {
    return {
        type: types.FETCH_ROLES_SUCCESS,
        payload: {
            roles
        }
    };
}

export function createRole({ level = 'COMPANY', successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const role = {
            name: 'Untitled Role',
            privilegeIds: [],
            type: 'USER_ROLE',
            level
        };
        dispatch(_createRoleAction());
        try {
            const roleResponse = await createRoleApi({ companyId, role });
            dispatch(_createRoleActionSuccess({ role: roleResponse }));
            dispatch(turnOnLastRolePopup({ roleId: roleResponse.id }));
            successCallback && successCallback(roleResponse);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_createRoleActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createRoleAction() {
    return {
        type: types.CREATE_ROLE
    };
}

function _createRoleActionSuccess({ role }) {
    return {
        type: types.CREATE_ROLE_SUCCESS,
        payload: {
            role
        }
    };
}

function _createRoleActionFailed() {
    return {
        type: types.CREATE_ROLE_FAILED
    };
}

export function deleteRole({ roleId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const { roles } = permission;
        const { selectedRoleId } = roles;

        if (!selectedRoleId) {
            console.log('make sure selectedRoleId is not null');
            return;
        }

        dispatch(
            enqueueSnackbar({
                message: i18n.t('role_deleted_success'),
                type: 'info'
            })
        );

        dispatch(_deleteRoleAction({ roleId }));
        const firstRole = roles?.list?.[0];

        if (roleId === selectedRoleId) {
            dispatch(changeRole({ roleId: firstRole?.id }));
        }

        try {
            await deleteRoleApi({ companyId, roleId });
            dispatch(_deleteRoleActionSuccess({ roleId }));
            successCallback && successCallback(firstRole);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteRoleActionFailed({ roleId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteRoleAction({ roleId }) {
    return {
        type: types.DELETE_ROLE,
        payload: {
            roleId
        }
    };
}

function _deleteRoleActionFailed({ roleId }) {
    return {
        type: types.DELETE_ROLE_FAILED,
        payload: {
            roleId
        }
    };
}

function _deleteRoleActionSuccess({ roleId }) {
    return {
        type: types.DELETE_ROLE_SUCCESS,
        payload: {
            roleId
        }
    };
}

export function updateRolePrivilegeIds({ name, oldPrivilegeIds, newPrivilegeIds, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const { roles } = permission;
        const { selectedRoleId } = roles;
        const companyId = auth.companyId || auth.currentUser.companyId;

        if (!selectedRoleId) {
            console.log('[updateRolePrivilegeIds] selectedRoleId cannot be empty');
            return false;
        }

        dispatch(_updateRolePrivilegeIdsAction({ roleId: selectedRoleId, privilegeIds: newPrivilegeIds }));
        try {
            await updateRoleApi({ companyId, role: { name, id: selectedRoleId, privilegeIds: newPrivilegeIds } });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_updateRolePrivilegeIdsAction({ roleId: selectedRoleId, privilegeIds: oldPrivilegeIds }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateRolePrivilegeIdsAction({ roleId, privilegeIds }) {
    return {
        type: types.UPDATE_ROLE_PRIVILEGE_IDS,
        payload: {
            roleId,
            privilegeIds
        }
    };
}

export function updateRole({ oldRole, newRole, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_updateRoleAction({ role: newRole }));
        try {
            await updateRoleApi({ companyId, role: newRole });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_updateRoleActionFailed({ role: oldRole }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateRoleAction({ role }) {
    return {
        type: types.UPDATE_ROLE,
        payload: {
            role
        }
    };
}

function _updateRoleActionFailed({ role }) {
    return {
        type: types.UPDATE_ROLE_FAILED,
        payload: {
            role
        }
    };
}

export function turnOnLastRolePopup({ roleId }) {
    return {
        type: types.TURN_ON_SHOW_LAST_POP_UP_CREATE_ROLE,
        payload: {
            roleId
        }
    };
}

export function turnOffLastRolePopup() {
    return {
        type: types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_ROLE
    };
}

export function changeRole({ roleId }) {
    return {
        type: types.CHANGE_ROLE,
        payload: {
            roleId
        }
    };
}
