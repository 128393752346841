import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Badge } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.dimension || 40,
        height: props => props.dimension || 40,
        minWidth: props => props.dimension || 40,
        minHeight: props => props.dimension || 40,
        fontSize: props => props.fontSize || 14,
        textTransform: `uppercase`,
        fontWeight: `bold`
    },
    svgWithChildren: {
        '& > svg': {
            width: props => props.fontSize || 14,
            height: props => props.fontSize || 14
        }
    },
    badge: {
        background: theme.colors.white,
        borderRadius: '50%'
    }
}));

const SIZES = {
    smaller: {
        dimension: 24,
        fontSize: 14
    },
    small: {
        dimension: 32,
        fontSize: 16
    },
    medium: {
        dimension: 40,
        fontSize: 20
    },
    large: {
        dimension: 48,
        fontSize: 24
    }
};

function getSize(size) {
    if (typeof size === 'number') return { dimension: size, fontSize: size * 0.5 };
    switch (size) {
        case 'smaller':
            return SIZES['smaller'];
        case 'small':
            return SIZES['small'];
        case 'medium':
            return SIZES['medium'];
        case 'large':
            return SIZES['large'];
        default:
            return SIZES['medium'];
    }
}

function AvatarBase({ size = 'medium', color, children, className, src, badgeContent, ...rest }) {
    const { dimension, fontSize } = getSize(size);
    const classes = useStyles({ dimension, fontSize });
    const theme = useTheme();

    const defaultBackgroundColor = React.useMemo(() => {
        return theme.colors.dodgerBlue;
    }, [theme]);

    if (!children && !src) {
        return (
            <Badge
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                badgeContent={badgeContent}
                className={classes.badge}
            >
                <Avatar
                    className={`${classes.root} ${className}`}
                    style={{
                        background: hexToRgba(color || defaultBackgroundColor, 0.8),
                        border: `1px solid ${color || defaultBackgroundColor}`
                    }}
                    {...rest}
                >
                    <AvatarIconSVG />
                </Avatar>
            </Badge>
        );
    }
    return (
        <Badge
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            badgeContent={badgeContent}
        >
            <Avatar
                src={src}
                className={`${classes.root} ${classes.svgWithChildren} ${classes.bold} ${className}`}
                style={{
                    background: src ? `` : hexToRgba(color || defaultBackgroundColor, 0.8),
                    border: src ? `` : `1px solid ${color || defaultBackgroundColor}`
                }}
                {...rest}
            >
                {children}
            </Avatar>
        </Badge>
    );
}

export default React.memo(AvatarBase);
