import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.REGISTER_AUDIO_PLAY](state, { payload }) {
        const { file } = payload;
        const { audio } = state;
        const newAudio = produce(audio, draft => {
            draft.playingFile = file;
        });
        return {
            ...state,
            audio: newAudio
        };
    }
};

export default handler;
