export const SET_SELECTED_GRID = '[GRID]SET_SELECTED_GRID';
export const GET_GRIDS_BY_DBID = '[GRID]GET_GRIDS_BY_DBID';
export const GET_GRIDS_BY_DBID_FAILED = '[GRID]GET_GRIDS_BY_DBID_FAILED';
export const GET_GRIDS_BY_DBID_SUCCESS = '[GRID]GET_GRIDS_BY_DBID_SUCCESS';

export const CREATE_GRID = '[GRID]CREATE_GRID';
export const CREATE_GRID_FAILED = '[GRID]CREATE_GRID_FAILED';
export const CREATE_GRID_SUCCESS = '[GRID]CREATE_GRID_SUCCESS';

export const DELETE_GRID = '[GRID]DELETE_GRID';
export const DELETE_GRID_FAILED = '[GRID]DELETE_GRID_FAILED';
export const DELETE_GRID_SUCCESS = '[GRID]DELETE_GRID_SUCCESS';

export const UPDATE_GRID = '[GRID]UPDATE_GRID';
export const UPDATE_GRID_FAILED = '[GRID]UPDATE_GRID_FAILED';
export const UPDATE_GRID_SUCCESS = '[GRID]UPDATE_GRID_SUCCESS';
export const FETCH_GRID_FAVORITE = '[GRID]FETCH_GRID_FAVORITE';
export const FETCH_GRID_FAVORITE_SUCCESS = '[GRID]FETCH_GRID_FAVORITE_SUCCESS';
export const FETCH_GRID_FAVORITE_FAILED = '[GRID]FETCH_GRID_FAVORITE_FAILED';
export const CHANGE_FAVORITE_GRID = '[GRID]CHANGE_FAVORITE_GRID';
export const ADD_FAVORITE_GRID = 'ADD_FAVORITE';
export const REMOVE_FAVORITE_GRID = 'REMOVE_FAVORITE';
export const REMOVE_FAVORITE_GRID_BY_PROJECT_ID = 'REMOVE_FAVORITE_GRID_BY_PROJECT_ID';
export const UPDATE_NAME_FAVORITE = 'UPDATE_NAME_FAVORITE';

export const GENERATE_GRIDS_BY_DBID = '[GRID]GENERATE_GRIDS_BY_DBID';
export const ADD_GRID_AFTER_DATABASE_CREATED = '[GRID]ADD_GRID_AFTER_DATABASE_CREATED';

export const TURN_ON_SHOW_FIRST_POP_UP_CREATE_GRID = '[GRID]TURN_ON_SHOW_FIRST_POP_UP_CREATE_GRID';
export const TURN_OFF_SHOW_FIRST_POP_UP_CREATE_GRID = '[GRID]TURN_OFF_SHOW_FIRST_POP_UP_CREATE_GRID';
export const TURN_ON_FIRST_CREATE_SKELETON = '[GRID]TURN_ON_FIRST_CREATE_SKELETON';

export const CREATE_GRID_BY_IMPORT_FILE = '[GRID]CREATE_GRID_BY_IMPORT_FILE';
export const CREATE_GRID_BY_IMPORT_FILE_FAILED = '[GRID]CREATE_GRID_BY_IMPORT_FILE_FAILED';
export const CREATE_GRID_BY_IMPORT_FILE_SUCCESS = '[GRID]CREATE_GRID_BY_IMPORT_FILE_SUCCESS';

export const CREATE_GRID_MESSAGE_FAILED = '[GRID]CREATE_GRID_MESSAGE_FAILED';

export const CREATE_GRID_BY_INTEGRATION_CLOUDS = '[GRID]CREATE_GRID_BY_INTEGRATION_CLOUDS';
export const CREATE_GRID_BY_INTEGRATION_CLOUDS_FAILED = '[GRID]CREATE_GRID_BY_INTEGRATION_CLOUDS_FAILED';
export const CREATE_GRID_BY_INTEGRATION_CLOUDS_SUCCESS = '[GRID]CREATE_GRID_BY_INTEGRATION_CLOUDS_SUCCESS';

export const FETCH_GRID_LIST_BY_DATABASE = 'FETCH_GRID_LIST_BY_DATABASE';
export const FETCH_GRID_LIST_BY_DATABASE_SUCCESS = 'FETCH_GRID_LIST_BY_DATABASE_SUCCESS';
export const FETCH_GRID_LIST_BY_DATABASE_FAILED = 'FETCH_GRID_LIST_BY_DATABASE_FAILED';

export const FETCH_GRIDVIEWS_BY_DATABASE = 'FETCH_GRIDVIEWS_BY_DATABASE';
export const FETCH_GRIDVIEWS_BY_DATABASE_SUCCESS = 'FETCH_GRIDVIEWS_BY_DATABASE_SUCCESS';
export const FETCH_GRIDVIEWS_BY_DATABASE_FAILED = 'FETCH_GRIDVIEWS_BY_DATABASE_FAILED';

export const UPDATE_GRID_STATUS = '[GRID]UPDATE_GRID_STATUS';

export const CREATE_LOCALIZATION_GRID = '[GRID]CREATE_LOCALIZATION_GRID';
export const CREATE_LOCALIZATION_GRID_SUCCESS = '[GRID]CREATE_LOCALIZATION_GRID_SUCCESS';
export const CREATE_LOCALIZATION_GRID_FAILED = '[GRID]CREATE_LOCALIZATION_GRID_FAILED';

export const DUPLICATE_GRID = '[GRID]DUPLICATE_GRID';
export const DUPLICATE_GRID_FAILED = '[GRID]DUPLICATE_GRID_FAILED';
export const DUPLICATE_GRID_SUCCESS = '[GRID]DUPLICATE_GRID_SUCCESS';

//TEMPLATEs
export const FETCH_GRID_TEMPLATES = '[GRID]FETCH_GRID_TEMPLATES';
export const FETCH_GRID_TEMPLATES_SUCCESS = '[GRID]FETCH_GRID_TEMPLATES_SUCCESS';
export const FETCH_GRID_TEMPLATES_FAILED = '[GRID]FETCH_GRID_TEMPLATES_FAILED';

export const SAVE_GRID_AS_TEMPLATE = '[GRID]SAVE_GRID_AS_TEMPLATE';

export const REMOVE_GRID_TEMPLATE = '[GRID]REMOVE_GRID_TEMPLATE';

export const UPDATE_GRID_TEMPLATE = '[GRID]UPDATE_GRID_TEMPLATE';
export const CREATE_GRID_FROM_TEMPLATE = '[GRID]CREATE_GRID_FROM_TEMPLATE';
export const UPDATE_TEMPLATE_STATUS = '[GRID]UPDATE_TEMPLATE_STATUS';
