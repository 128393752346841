import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Avatar } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ExportSVG from 'assets/images/svg/ExportSVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import { getDownloadedFile } from 'utils/fileUtils';
import AudioPlay from 'components/audio/Base';
import VideoPlay from 'components/video/Base';
import { isFileAudio, isFileImage, isFileVideo, getFilePreview, isFilePdf } from 'utils/images';
import { DISABLED_OPACITY } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip/Base';
import NextButtonSVG from 'assets/images/svg/NextButtonSVG';
import BackButtonSVG from 'assets/images/svg/BackButtonSVG';
import { isKbArrowDown, isKbArrowLeft, isKbArrowRight, isKbArrowUp } from 'utils/keyboard';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { FixedSizeList as List } from 'react-window';
import { AutoSizer } from 'react-virtualized-dn';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    header: {
        height: HEADER_HEIGHT,
        background: '#1A1434',
        position: 'relative',
        paddingLeft: 29,
        '& *': {
            color: theme.colors.white,
            fill: theme.colors.white
        }
    },
    controlsWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 23,
        margin: 'auto',
        height: '100%'
    },
    controls: {
        height: '100%'
    },
    control: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8
        }
    },
    body: {
        flex: 1,
        height: `calc(100vh - ${HEADER_HEIGHT}px - 130px)`,
        paddingLeft: 30,
        paddingRight: 30,
        position: 'relative'
    },
    footer: {
        backgroundColor: '#1A1434'
    },
    flex: {
        display: 'flex',
        cursor: 'pointer'
    },
    arrowWrapper: {
        width: 48,
        height: 48,
        background: hexToRgba(theme.colors.dimGrey, 0.5),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'absolute',
        '&.left': {
            left: 65
        },
        '&.right': {
            right: 65
        }
    },
    arrow: {
        height: 25,
        width: 25,
        '& path': {
            fill: theme.colors.white
        }
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none',
        cursor: 'not-allowed'
    },
    avatarImage: {
        maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - 126px)`,
        cursor: 'pointer'
    },
    fileName: {},
    arrowUp: {
        marginRight: 10,
        '& svg': {
            width: 25,
            height: 25
        }
    },
    arrowDown: {
        marginRight: 60,
        '& svg': {
            width: 25,
            height: 25
        }
    },
    imageNumberInput: {
        backgroundColor: '#382F58',
        width: 48,
        height: 26,
        borderRadius: 4,
        color: theme.colors.white,
        outline: 'none',
        textAlign: 'center',
        border: 'none'
    },
    filesCount: {
        color: hexToRgba(theme.colors.white, 0.5)
    }
}));

const IMAGE_WIDTH = 100;
const IMAGE_HEIGHT = 100;

const stopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
};

const AvatarThumb = React.memo(({ isImage, isAudio, isVideo, isPdf, url, isShareViewLink, fileMeta }) => {
    if (isImage) {
        return (
            <Avatar
                style={{
                    height: IMAGE_HEIGHT,
                    width: IMAGE_WIDTH,
                    borderRadius: 4,
                    background: !isImage ? (isAudio ? '#EEF5FB' : isVideo ? '#FDF0ED' : '#F3F2F9') : ''
                }}
                src={`${url}${isShareViewLink ? '&' : `?`}downloadFileOption=inline`}
                alt={fileMeta?.originalName}
            >
                {!isImage &&
                    (isAudio ? (
                        <AudioThumbnailSVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
                    ) : isVideo ? (
                        <VideoThumbnailSVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
                    ) : isPdf ? (
                        <PDFFILESVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
                    ) : (
                        <OtherFileTypeThumbnailSVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
                    ))}
            </Avatar>
        );
    }
    return (
        <Grid
            item
            style={{
                height: IMAGE_HEIGHT,
                width: IMAGE_WIDTH,
                borderRadius: 4,
                background: isAudio ? '#EEF5FB' : isVideo ? '#FDF0ED' : '#F3F2F9'
            }}
        >
            {isAudio ? (
                <AudioThumbnailSVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
            ) : isVideo ? (
                <VideoThumbnailSVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
            ) : isPdf ? (
                <PDFFILESVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
            ) : (
                <OtherFileTypeThumbnailSVG style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }} />
            )}
        </Grid>
    );
});

function FilesPreview({ files = [], dbId, onDelete, onClose, isReadOnly, defaultImage, isShareViewLink }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = React.useState(defaultImage || files[0]);
    const [step, setStep] = React.useState(!defaultImage ? 0 : files?.findIndex(file => file?.id === defaultImage?.id));
    const [fileList, setFileList] = React.useState(files);
    const thumbListRef = React.useRef();

    const filesCount = React.useMemo(() => {
        return fileList?.length;
    }, [fileList]);

    const handleSetStep = React.useCallback(
        step => {
            setStep(step);
            setSelectedFile(fileList?.[step]);
            thumbListRef.current.scrollToItem(step);
        },
        [fileList]
    );

    const goNextStep = React.useCallback(
        e => {
            stopPropagation(e);
            if (step < filesCount - 1) {
                handleSetStep(step + 1);
            }
        },
        [step, filesCount, handleSetStep]
    );

    const goPrevStep = React.useCallback(
        e => {
            stopPropagation(e);
            if (step > 0) {
                handleSetStep(step - 1);
            }
        },
        [step, handleSetStep]
    );

    const handleKeyDown = React.useCallback(
        e => {
            if (isKbArrowRight(e) || isKbArrowDown(e)) {
                goNextStep(e);
                return;
            }
            if (isKbArrowLeft(e) || isKbArrowUp(e)) {
                goPrevStep(e);
                return;
            }
        },
        [goPrevStep, goNextStep]
    );

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown, true);
        return () => {
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [handleKeyDown]);

    React.useEffect(() => {
        setFileList(files);
        setSelectedFile(files[step]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    let fileName = React.useMemo(() => {
        if (!selectedFile) return 'Not Found';
        return selectedFile?.originalName;
    }, [selectedFile]);

    let isSelectedImage = React.useMemo(() => {
        return isFileImage(selectedFile);
    }, [selectedFile]);

    let isSelectedAudio = React.useMemo(() => {
        return isFileAudio(selectedFile);
    }, [selectedFile]);

    let isSelectedVideo = React.useMemo(() => {
        return isFileVideo(selectedFile);
    }, [selectedFile]);

    const isSelectedPdf = React.useMemo(() => {
        return isFilePdf(selectedFile);
    }, [selectedFile]);

    const selectedUrl = React.useMemo(() => {
        return getFilePreview({ dbId, fileId: selectedFile?.id });
    }, [selectedFile, dbId]);

    const isSelectedItemReadOnly = React.useMemo(() => {
        return selectedFile?.isReadOnly;
    }, [selectedFile]);

    const handleFileChange = React.useCallback(
        ({ e, index }) => {
            stopPropagation(e);
            handleSetStep(index);
        },
        [handleSetStep]
    );

    const handleDown = React.useCallback(
        e => {
            stopPropagation(e);
            getDownloadedFile(selectedUrl);
        },
        [selectedUrl]
    );

    const handleDelete = React.useCallback(
        e => {
            stopPropagation(e);
            handleSetStep(0);
            if (onDelete) {
                onDelete && onDelete(selectedFile);
            }
            if (fileList.length === 1) {
                onClose && onClose(e);
                setFileList(fileList.filter(i => i !== selectedFile));
            }
        },
        [fileList, handleSetStep, onClose, onDelete, selectedFile]
    );

    return (
        <Grid id={'file-previews'} container className={classes.root} direction="column" wrap="nowrap">
            <Grid item>
                <Grid className={classes.header} container wrap="nowrap" alignItems="center">
                    <Grid
                        item
                        className={`${classes.flex} ${classes.arrowUp} ${step === 0 ? classes.disabled : ''}`}
                        onClick={goPrevStep}
                    >
                        <ArrowUpSVG />
                    </Grid>
                    <Grid
                        item
                        className={`${classes.flex} ${classes.arrowDown} ${
                            step === filesCount - 1 ? classes.disabled : ''
                        }`}
                        onClick={goNextStep}
                    >
                        <ArrowDownSVG />
                    </Grid>
                    <Grid item className={classes.fileName}>
                        <Typography variant="body2">{fileName}</Typography>
                    </Grid>
                    {/* <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <input
                                    className={classes.imageNumberInput}
                                    type="text"
                                    value={step + 1}
                                    onChange={e => handleImageNumberChange(e)}
                                    onClick={e => e.stopPropagation()}
                                    onFocus={e => e.target.select()}
                                />
                            </Grid>
                            <Grid item style={{ marginLeft: 4 }}>
                                <Typography className={classes.filesCount} variant="body2" display="center">
                                    {' '}
                                    / {filesCount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid item className={classes.controlsWrapper}>
                        <Grid container alignItems="center" className={classes.controls} wrap="nowrap">
                            <Grid item style={{ marginRight: 40 }}>
                                <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={3}>
                                    {!isShareViewLink && (
                                        <Grid
                                            className={classes.control}
                                            container
                                            alignItems="center"
                                            item
                                            spacing={2}
                                            wrap="nowrap"
                                            onClick={handleDown}
                                        >
                                            <Grid item className={classes.flex}>
                                                <ExportSVG />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{t(`global_download`)}</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {/* <Grid
                                        className={classes.control}
                                        container
                                        alignItems="center"
                                        item
                                        spacing={2}
                                        wrap="nowrap"
                                    >
                                        <Grid item className={classes.flex}>
                                            <EditSVG />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Rename</Typography>
                                        </Grid>
                                    </Grid> */}
                                    {!isReadOnly && !isSelectedItemReadOnly && !isShareViewLink && (
                                        <Grid
                                            className={classes.control}
                                            container
                                            alignItems="center"
                                            item
                                            spacing={2}
                                            wrap="nowrap"
                                            onClick={handleDelete}
                                        >
                                            <Grid item className={classes.flex}>
                                                <DeleteSVG />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{t('global_delete')}</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item className={classes.flex} onClick={onClose}>
                                <CloseIconSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid className={classes.body} container alignItems="center" justify="center" wrap="nowrap">
                    <Tooltip
                        title={
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="subtitle2">Back</Typography>
                                </Grid>
                                <Grid item className={classes.flex}>
                                    <BackButtonSVG />
                                </Grid>
                            </Grid>
                        }
                    >
                        <Grid item className={`${classes.arrowWrapper} left ${step === 0 ? classes.disabled : ''}`}>
                            <ArrowLeftSVG onClick={goPrevStep} className={`${classes.arrow}`} />
                        </Grid>
                    </Tooltip>

                    <Grid item>
                        {isSelectedImage && selectedUrl && (
                            <img
                                onClick={stopPropagation}
                                className={classes.avatarImage}
                                src={selectedUrl}
                                alt={selectedUrl}
                            />
                        )}
                        {!isSelectedImage && isSelectedAudio && <AudioPlay src={selectedUrl} />}
                        {!isSelectedImage && isSelectedVideo && (
                            <div
                                style={{
                                    display: `flex`,
                                    alignItems: `center`,
                                    justifyContent: `center`
                                }}
                            >
                                <VideoPlay
                                    src={selectedUrl}
                                    style={{
                                        maxWidth: `calc(100vw - 120px - 100px - 72px)`,
                                        maxHeight: `calc(100vh - 60px - 130px)`,
                                        objectFit: `contain`
                                    }}
                                />
                            </div>
                        )}
                        {isSelectedPdf && selectedUrl && (
                            <object
                                data={`${selectedUrl}${isShareViewLink ? '&' : `?`}downloadFileOption=inline`}
                                type="application/pdf"
                                style={{
                                    width: `calc(100vw - 120px - 100px - 72px)`,
                                    height: `calc(100vh - 60px - 130px)`
                                }}
                                aria-labelledby="pdf"
                            />
                        )}
                        {!isSelectedImage && !isSelectedVideo && !isSelectedAudio && !isSelectedPdf && (
                            <OtherFileTypeThumbnailSVG style={{ width: 320, height: 320 }} />
                        )}
                    </Grid>
                    <Tooltip
                        title={
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="subtitle2">{t('global_next')}</Typography>
                                </Grid>
                                <Grid item className={classes.flex}>
                                    <NextButtonSVG />
                                </Grid>
                            </Grid>
                        }
                    >
                        <Grid
                            item
                            onClick={goNextStep}
                            className={`${classes.arrowWrapper} right ${
                                step === filesCount - 1 ? classes.disabled : ''
                            }`}
                        >
                            <ArrowRightSVG className={`${classes.arrow}`} />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item>
                <AutoSizer>
                    {({ width }) => (
                        <List
                            ref={thumbListRef}
                            className={classes.footer}
                            direction="horizontal"
                            width={width}
                            height={IMAGE_HEIGHT + 30}
                            itemCount={fileList?.length}
                            itemSize={IMAGE_WIDTH + 12}
                        >
                            {({ key, index, style }) => {
                                const fileMeta = fileList[index];
                                const url = getFilePreview({ dbId, fileId: fileMeta?.thumbnailId });

                                let isImage = isFileImage(fileMeta);
                                let isAudio = isFileAudio(fileMeta);
                                let isVideo = isFileVideo(fileMeta);
                                let isPdf = isFilePdf(fileMeta);
                                return (
                                    <Grid key={key} container alignItems="center" justifyContent="center" style={style}>
                                        <Grid
                                            item
                                            onClick={e => handleFileChange({ e, index })}
                                            style={{
                                                padding: 3,
                                                borderRadius: 4,
                                                border:
                                                    index === step
                                                        ? `3px solid ${theme.colors.dodgerBlue}`
                                                        : '3px solid transparent',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <AvatarThumb
                                                isImage={isImage}
                                                isAudio={isAudio}
                                                isVideo={isVideo}
                                                isPdf={isPdf}
                                                url={url}
                                                isShareViewLink={isShareViewLink}
                                                fileMeta={fileMeta}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </List>
                    )}
                </AutoSizer>
            </Grid>
        </Grid>
    );
}

export default React.memo(FilesPreview);
