import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    POPUP_PADDING_TOP_BOTTOM
} from 'const/style';
import HistorySVG from 'assets/images/svg/HistorySVG';

const useStyles = makeStyles(theme => ({
    root: {},
    delete: {
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        marginBottom: 10
    }
}));

function LogItemPopover({ onClose, onSelect, t }) {
    const classes = useStyles();

    function handleClick() {
        onSelect();
        onClose();
    }

    return (
        <Grid id={`grid_popup`} container direction="column" className={classes.root}>
            <Grid item className={classes.delete} onClick={handleClick}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item style={{ display: 'flex' }}>
                        <HistorySVG />
                    </Grid>
                    <Grid item>
                        <p className="body2">{t(`grid_history_restore_this_action`)}</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(LogItemPopover);
