import * as types from '../types';
import {
    getAllGroupsApi,
    getCompanyGroupsApi,
    createGroupApi,
    deleteGroupApi,
    updateGroupApi,
    grantToWorkspaceApi,
    ungrantToWorkspaceApi,
    fetchGroupMembersApi,
    createGroupWithAccessApi,
    getGroupWorkspacesAccessApi,
    updateGroupWorkspacesAccessApi,
    uploadGroupAvatarApi
} from 'services/groups';
import { unassignUserApi } from 'services/members';
import { enqueueSnackbar } from 'notifier/actions';
import { requestData, receiveData } from '../../api/actions';
import i18n from 'i18n';

export function updateGroupWithWorkspaceAccess({
    projectIds,
    dbIds,
    addViews,
    removeViews,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const { groups } = permission;
        const { selectedGroupId } = groups;
        const companyId = auth.companyId || auth.currentUser.companyId;

        if (!selectedGroupId) {
            console.log('make sure selectedGroupId is not null');
            return;
        }

        try {
            await updateGroupWorkspacesAccessApi({
                companyId,
                groupId: selectedGroupId,
                body: {
                    projectIds,
                    dbIds,
                    addViews,
                    removeViews
                }
            });
            // dispatch(receiveData(types.UPDATE_WORKSPACE_ACCESSS, { groupId: selectedGroupId, addViews, removeViews,  }));

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchGroupWorkspacesAccess({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const { groups } = permission;
        const { selectedGroupId } = groups;
        const companyId = auth.companyId || auth.currentUser.companyId;

        if (!selectedGroupId) {
            console.log('make sure selectedGroupId is not null');
            return;
        }
        dispatch(requestData(types.FETCH_GROUP_WORKSPACE_ACCESS, { groupId: selectedGroupId }));
        try {
            const workspacesAccess = await getGroupWorkspacesAccessApi({ groupId: selectedGroupId, companyId });
            dispatch(
                receiveData(types.FETCH_GROUP_WORKSPACE_ACCESS_SUCCESS, { workspacesAccess, groupId: selectedGroupId })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                receiveData(types.FETCH_GROUP_WORKSPACE_ACCESS_FAILED, { groupId: selectedGroupId, error: message })
            );
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchCompanyGroups({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchGroupsAction());
        try {
            const { data } = await getCompanyGroupsApi();
            const { groups } = data;
            dispatch(_fetchGroupsActionSuccess({ groups }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchGroups({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_fetchGroupsAction());
        try {
            const { data } = await getAllGroupsApi({ companyId });
            const { groups, workspaces } = data;
            dispatch(_fetchGroupsActionSuccess({ groups }));

            dispatch(receiveData(types.FETCH_FULL_WORKSPACE_SUCCESS, { workspaces }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchGroupsAction() {
    return {
        type: types.FETCH_GROUPS
    };
}

function _fetchGroupsActionSuccess({ groups }) {
    return {
        type: types.FETCH_GROUPS_SUCCESS,
        payload: {
            groups
        }
    };
}

export function uploadGroupException({ error }) {
    return async function(dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `[uploadUserException]: ${error}`,
                type: 'info'
            })
        );
    };
}

export function uploadGroupAvatar({ file, groupId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const newGroup = await uploadGroupAvatarApi({ file, groupId });
            dispatch(_uploadAvatarGroupActionSuccess({ newGroup: newGroup?.entity }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _uploadAvatarGroupActionSuccess({ newGroup }) {
    return {
        type: types.UPLOAD_AVATAR_GROUP_SUCCESS,
        payload: {
            newGroup
        }
    };
}

export function createGroup({ groupName, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const group = {
            name: groupName || i18n.t('new_group')
        };
        dispatch(requestData(types.CREATE_GROUP));
        try {
            const groupResponse = await createGroupApi({ companyId, group });
            dispatch(receiveData(types.CREATE_GROUP_SUCCESS, { group: groupResponse }));
            dispatch(turnOnLastGroupPopup({ groupId: groupResponse.id }));
            successCallback && successCallback(groupResponse);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(receiveData(types.CREATE_GROUP_FAILED));
            errorCallback && errorCallback(message);
        }
    };
}

export function deleteGroup({ groupId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const { groups } = permission;
        const { selectedGroupId } = groups;
        const companyId = auth.companyId || auth.currentUser.companyId;

        if (!selectedGroupId) {
            console.log('make sure selectedGroupId is not null');
            return;
        }
        dispatch(_deleteGroupAction({ groupId }));
        const firstGroup = groups?.list?.[0];

        if (groupId === selectedGroupId) {
            dispatch(changeGroup({ groupId: firstGroup?.id }));
        }

        try {
            await deleteGroupApi({ companyId, groupId });
            dispatch(_deleteGroupActionSuccess({ groupId }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('group_deleted_successfully'),
                    type: 'info'
                })
            );
            successCallback && successCallback(firstGroup);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(receiveData(types.DELETE_GROUP_FAILED, { groupId }));
            errorCallback && errorCallback(message);
        }
    };
}

export function _deleteGroupAction({ groupId }) {
    return {
        type: types.DELETE_GROUP,
        payload: {
            groupId
        }
    };
}

export function _deleteGroupActionSuccess({ groupId }) {
    return {
        type: types.DELETE_GROUP_SUCCESS,
        payload: {
            groupId
        }
    };
}

export function updateGroup({ oldGroup, newGroup, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(requestData(types.UPDATE_GROUP, { group: newGroup }));
        try {
            await updateGroupApi({ companyId, group: newGroup });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(requestData(types.UPDATE_GROUP_FAILED, { group: oldGroup }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function turnOnLastGroupPopup({ groupId }) {
    return {
        type: types.TURN_ON_SHOW_LAST_POP_UP_CREATE_GROUP,
        payload: {
            groupId
        }
    };
}

export function turnOffLastGroupPopup() {
    return {
        type: types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_GROUP
    };
}

export function grantToWorkspace({ groupId, workspaceId, newWorkspace, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            dispatch(receiveData(types.GRANT_GROUP_TO_WORKSPACE_SUCCESS, { groupId, workspaceId, newWorkspace }));

            await grantToWorkspaceApi({ companyId, groupId, workspaceId });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.GRANT_GROUP_TO_WORKSPACE_FAILED, { groupId, workspaceId }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function ungrantToWorkspace({ data, workspaceId, workspace, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            dispatch(receiveData(types.UNGRANT_GROUP_TO_WORKSPACE_SUCCESS, { groupIds: data?.groupIds, workspaceId }));

            await ungrantToWorkspaceApi({ companyId, data, workspaceId });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                receiveData(types.UNGRANT_GROUP_TO_WORKSPACE_FAILED, {
                    groupIds: data?.groupIds,
                    workspaceId,
                    workspace
                })
            );

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function fetchGroupMembers({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const { groups } = permission;
        const { selectedGroupId } = groups;

        if (!selectedGroupId) {
            console.log('selectedGroupId cannot be empty');
            return;
        }
        try {
            dispatch(
                requestData(types.FETCH_GROUP_MEMBERS, {
                    groupId: selectedGroupId
                })
            );

            const members = await fetchGroupMembersApi({ companyId, groupId: selectedGroupId });

            dispatch(
                receiveData(types.FETCH_GROUP_MEMBERS_SUCCESS, {
                    groupId: selectedGroupId,
                    members
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.FETCH_GROUP_MEMBERS_FAILED, { groupId: selectedGroupId, error: message }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function removeUserOutOfGroup({ userId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const { groups } = permission;
        const { selectedGroupId } = groups;
        if (!selectedGroupId) {
            console.log('selectedGroupId cannot be empty');
            return;
        }
        dispatch(requestData(types.REMOVE_USER_OUT_OF_GROUP, { userId, groupId: selectedGroupId }));
        try {
            await unassignUserApi({ companyId, groupId: selectedGroupId, userId });
            dispatch(receiveData(types.REMOVE_USER_OUT_OF_GROUP_SUCCESS, { userId, groupId: selectedGroupId }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('member_removed_from_group_successfully'),
                    type: 'info'
                })
            );
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(receiveData(types.REMOVE_USER_OUT_OF_GROUP_FAILED, { userId, groupId: selectedGroupId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function changeGroup({ groupId }) {
    return {
        type: types.CHANGE_GROUP,
        payload: {
            groupId
        }
    };
}

export function createGroupWithAccess({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            const group = await createGroupWithAccessApi({ body, companyId });
            dispatch(_createGroupWithAccessActionSuccess({ group }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('group_created_successfully'),
                    type: 'info'
                })
            );
            return successCallback && successCallback(group);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

function _createGroupWithAccessActionSuccess({ group }) {
    return {
        type: types.CREATE_GROUP_SUCCESS,
        payload: {
            group
        }
    };
}

export function editGroupWithAccess({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function setSelectedGroups({ groups }) {
    return {
        type: types.SET_SELECTED_GROUPS,
        payload: {
            groups
        }
    };
}
