import React from 'react';

function BoldSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <polygon fill="#FFF" points="0 0 24 0 24 24 0 24" opacity="0" />
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M6,5.0004375 L6,5.8754375 L6.875,5.8754375 C7.35625,5.8754375 7.75,6.2691875 7.75,6.7504375 L7.75,17.2504375 C7.75,17.7316875 7.35625,18.1254375 6.875,18.1254375 L6,18.1254375 L6,19.0004375 L13.4375,19.0004375 C15.625,19.0004375 17.375,17.2504375 17.375,15.0629375 C17.375,13.4004375 16.371375,11.9951875 14.927625,11.426 C15.890125,10.76975 16.5,9.7254375 16.5,8.5 C16.5,6.575 14.925,5 13,5 L6,5 L6,5.0004375 Z M9.9375,6.225437 L11.6879375,6.225437 C13.1316875,6.225437 14.3129375,7.305437 14.3129375,8.625437 C14.3129375,9.945437 13.1316875,11.025437 11.6879375,11.025437 L9.9379375,11.025437 L9.9379375,6.225437 L9.9375,6.225437 Z M9.9375,12.162937 L12.125,12.162937 C13.83125,12.162937 15.1875,13.4250799 15.1875,15.012937 C15.1875,16.6007941 13.83125,17.862937 12.125,17.862937 L9.9375,17.862937 L9.9375,12.162937 Z"
                />
            </g>
        </svg>
    );
}

export default React.memo(BoldSVG);
