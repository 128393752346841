import React from 'react';

function AmexSVG({ ...other }) {
    return (
        <svg {...other} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.10006 5.59961H38.1001C39.4802 5.59961 40.6001 6.71956 40.6001 8.10081V31.8508C40.6001 33.2308 39.4802 34.3508 38.1001 34.3508H3.10006C1.71881 34.3509 0.600098 33.2308 0.600098 31.851V8.10081C0.600098 6.71956 1.72004 5.59961 3.10006 5.59961Z"
                fill="#2E70C9"
            />
            <path
                d="M7.05014 15.5996L3.1001 24.3409H7.82885L8.41508 22.9472H9.75506L10.3413 24.3409H15.5463V23.2772L16.0101 24.3409H18.7026L19.1664 23.2547V24.3409H29.9914L31.3077 22.9834L32.5402 24.3409L38.1001 24.3522L34.1376 19.9947L38.1001 15.5996H32.6264L31.3451 16.9321L30.1514 15.5996H18.3752L17.3639 17.8558L16.329 15.5996H11.6101V16.6272L11.0852 15.5996C11.0852 15.5996 7.05014 15.5996 7.05014 15.5996ZM7.96512 16.8409H10.2701L12.8902 22.7684V16.8409H15.4153L17.4389 21.0909L19.304 16.8409H21.8164V23.1134H20.2877L20.2752 18.1983L18.0464 23.1134H16.6789L14.4376 18.1983V23.1134H11.2926L10.6964 21.7071H7.47514L6.88014 23.1121H5.19508C5.19508 23.1121 7.96512 16.8409 7.96512 16.8409ZM23.2151 16.8409H29.4314L31.3326 18.8946L33.2952 16.8409H35.1964L32.3077 19.9934L35.1964 23.1096H33.2089L31.3077 21.0321L29.3351 23.1096H23.2151V16.8409ZM9.08644 17.9022L8.02517 20.4072H10.1465L9.08644 17.9022ZM24.7502 18.1396V19.2846H28.1414V20.5609H24.7502V21.8109H28.5539L30.3214 19.9697L28.6289 18.1385H24.7502V18.1396Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(AmexSVG);
