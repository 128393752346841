import React from 'react';
import { Grid } from '@material-ui/core';
import BranchTag from './BranchTag';

function CheckoutBranch({ fromBranch, toBranch }) {
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <BranchTag name={fromBranch} />
            </Grid>
            <Grid item>
                <BranchTag name={toBranch} />
            </Grid>
        </Grid>
    );
}

export default React.memo(CheckoutBranch);
