import React from 'react';
import { useSelector } from 'react-redux';
import { getFullName } from 'utils/user';
import { useTMList } from '../tm';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import { makeGroupOption } from 'utils/permission/group';
import * as roleConst from 'auth/roleConst';
import { useCanViewGroupShare } from 'hooks/payment';
import { useGroupList } from 'hooks/permission';
import { useGetLQAModels } from '../gridUI';
import { API_URL } from 'config';

export function useSelectedWorkspaceId() {
    return useSelector(state => state.workspace.selectedWorkspace?.id);
}

export function useSelectedWorkspace() {
    return useSelector(state => state.workspace.selectedWorkspace);
}

export function useWorkspaceList() {
    return useSelector(state => state.workspace.list);
}

export function useFavoriteGridList() {
    return useSelector(state => state.workspace.listFavorite);
}

export function useFetchingFavoriteGridList() {
    return useSelector(state => state.workspace.isFetchingFavorite);
}

export function useWorkspaceMapping() {
    const workspaces = useWorkspaceList();
    return workspaces?.reduce((obj, w) => {
        obj[w?.id] = w;
        return obj;
    }, {});
}

export function useWorkspaceIsFetching() {
    return useSelector(state => state.workspace.isFetching);
}

export function useIsFetchingWorkspaces() {
    return useSelector(state => state.workspace.isFetchingWorkspaces);
}

export function useIsFetchedWorkspaces() {
    return useSelector(state => state.workspace.isFetchedWorkspaces);
}

export function useIsCreatingWorkspace() {
    return useSelector(state => state.workspace.isCreatingWorkspace);
}

export function useIsShowLastWorkspacePopupCreate() {
    return useSelector(state => state.workspace.isShowLastPopupCreate);
}

export function useSelectedGroupIdInWorkspace() {
    return useSelector(state => state.workspace.selectedGroupId);
}

export function useGroupListInWorkspace() {
    return useSelector(state => state.workspace.groups);
}

export function useMemberListInWorkspace() {
    return useSelector(state => state.workspace.members)?.filter(member => !member.ldUser);
}

export function useWorkspaceRoles() {
    const workspaceRoles = useSelector(state => state.workspace.workspaceRoles);

    return [
        roleConst.CREATOR,
        roleConst.EDITOR,
        roleConst.TRANSLATOR,
        roleConst.VIEWER,
        // roleConst.COMMENTER,
        roleConst.LOCALIZATION_MANAGER
    ].map(roleName => workspaceRoles?.find(role => role?.name === roleName));
}

export function useMapWorkspaceRolesById() {
    const workspaceRoles = useWorkspaceRoles();
    return workspaceRoles?.reduce((acc, cur) => {
        if (cur) {
            acc[cur?.id] = cur;
        }
        return acc;
    }, {});
}

export function useMapWorkspaceRolesByName() {
    const workspaceRoles = useWorkspaceRoles();
    return workspaceRoles?.reduce((acc, cur) => {
        if (cur) {
            acc[cur?.name] = cur;
        }
        return acc;
    }, {});
}

export function useWorkspaceAsDropdown() {
    const workspaces = useWorkspaceList();
    return workspaces.map(workspace => ({
        label: workspace.name,
        value: workspace.id
    }));
}

export function useMembersOfGroupsOfWorkspace() {
    const workspaceGroups = useGroupListInWorkspace();
    let members = [];
    workspaceGroups.forEach(group => {
        members = [...members, ...(group.members || [])];
    });
    return members;
}

export function useWorkspaceMembers() {
    const members = useMemberListInWorkspace();
    const groups = useGroupListInWorkspace();
    const selectedGroupId = useSelectedGroupIdInWorkspace();

    let rawMembers;
    if (selectedGroupId) {
        const memberIds = groups
            ?.find(group => group?.id === selectedGroupId)
            ?.members?.map(member => member?.user?.id);
        rawMembers = members?.filter(member => memberIds?.includes(member?.user?.id));
    } else {
        rawMembers = members;
    }
    return rawMembers
        ?.filter(member => !member?.ldUser)
        ?.map(member => ({
            ...member,
            user: {
                ...member?.user,
                fullName: getFullName(member?.user)
            }
        }));
}

export function useIsOpenWorkspaceRoleModal() {
    return useSelector(state => state.workspace.isOpenWorkspaceRoleModal);
}

export function useSelectedWorkspaceByWorkspaceIds(workspaceIds = []) {
    const workspaceList = useWorkspaceList();
    let wsOptions = [];
    workspaceIds.forEach(workspaceId => {
        let workspaceDetail = workspaceList.find(ws => ws.id === workspaceId);

        if (workspaceDetail) {
            wsOptions.push(workspaceDetail);
        }
    });

    return wsOptions?.map(ws => ({
        label: ws.name,
        value: ws.id,
        icon: () => <WorkspaceSVG />
    }));
}

export function useAvailableWorkspaceOptions(workspaceIds = []) {
    const workspaceList = useWorkspaceList();
    const tmList = useTMList();
    const seletedWorkspaceOptions = useSelectedWorkspaceByWorkspaceIds(workspaceIds);

    let selectedWsIds = [];
    tmList.forEach(tm => {
        const workspaceIds = tm?.workspaceIds;
        if (workspaceIds) {
            selectedWsIds = [...selectedWsIds, ...workspaceIds];
        }
    });

    return [
        ...seletedWorkspaceOptions,
        ...workspaceList
            .filter(ws => !selectedWsIds.includes(ws.id))
            ?.map(ws => ({
                label: ws.name,
                value: ws.id,
                icon: () => <WorkspaceSVG />
            }))
    ];
}

export function useAvailableWorkspaceOptionsInLQA() {
    const workspaceList = useWorkspaceList();
    const lqaModels = useGetLQAModels();

    let selectedWsIds = [];
    for (const lqa of lqaModels) {
        const projectIds = lqa?.projects?.map(project => project?.id);
        if (projectIds?.length) {
            selectedWsIds = [...selectedWsIds, ...projectIds];
        }
    }

    return workspaceList
        .filter(ws => !selectedWsIds.includes(ws.id))
        ?.map(ws => ({
            label: ws.name,
            value: ws.id,
            icon: () => <WorkspaceSVG />
        }));
}

export function useProjectGroups() {
    return useSelector(state => state.workspace.shareProjects.sharedGroups);
}

export function useProjectGroupsIsLoading() {
    return useSelector(state => state.workspace.shareProjects.isFetching);
}

export function useProjectGroupOptions() {
    const groups = useProjectGroups();
    const canViewGroupShare = useCanViewGroupShare();
    return groups?.map(group => {
        const isDisabled = !canViewGroupShare && !group?.systemGroup;
        return makeGroupOption({ group, isDisabled });
    });
}

export function useNotSharedProjectGroups() {
    const companyGroups = useGroupList();
    const projectGroups = useProjectGroups();
    const projectGroupIds = projectGroups?.map(group => group.id);
    const notSharedGroups = companyGroups?.filter(group => !projectGroupIds?.includes(group.id));
    return notSharedGroups?.map(group => {
        return makeGroupOption({ group });
    });
}

export function useUserGridSettings({ wsId, dbId, gridId }) {
    return useSelector(state => state.workspace.setting?.[wsId]?.[dbId]?.grids?.[gridId]?.value);
}

export function useGridSettingByProperty({ wsId, dbId, gridId, property }) {
    const value = useUserGridSettings({ wsId, gridId, dbId });
    return value?.[property];
}

export function useUserViewSettings({ wsId, dbId, viewId }) {
    return useSelector(state => {
        return state.workspace.setting?.[wsId]?.[dbId]?.views?.[viewId]?.value;
    });
}

export function useUserViewSettingByProperty({ wsId, dbId, viewId, property }) {
    const value = useUserViewSettings({ wsId, dbId, viewId });

    return value?.[property];
}

export function useIsFetchingUserSetting() {
    return useSelector(state => state.workspace.isFetchingUserSetting);
}

export function useSortCriteriaProperties(workspaceId) {
    return useSelector(state => state.workspace.sortCriteria?.[workspaceId]?.dbSortProperties);
}

export function useSortCriteriaWithWorkspaceDbId({ workspaceId, dbId }) {
    return useSelector(state => state.workspace.sortCriteria?.[workspaceId]?.gridSortPropertiesByDbId?.[dbId]);
}

export function useRegionList() {
    return useSelector(state => state.workspace.regions);
}

export function useProjectRegion() {
    const regions = useRegionList();
    const selectedWorkspace = useSelectedWorkspace();
    return regions.find(region => region?.name === selectedWorkspace?.region);
}

export function usePublicApiUrl() {
    const projectRegion = useProjectRegion();
    return projectRegion ? projectRegion.baseUrl : API_URL;
}
