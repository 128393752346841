import React from 'react';
import { Grid, LinearProgress, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CountryFlag from 'components/svg-icon/CountryFlag';
import SidebarRightLayout from 'gridUI/layout/rightSidebar';
import hexToRgba from 'hex-to-rgba';
import {
    useDependencyListWithoutFakeId,
    useViewColumnsWithUserLanguageViewsAndMetadata,
    useIsRunningAutoQA,
    useAutoQASettings
} from 'hooks/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { getRunnedQAsApi, getQAStatisticsApi } from 'services/autoQA';
import searchGif from 'assets/images/search.gif';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import { useGridDetail } from 'hooks/grid';
import { runAutoQA, showQAErrors, openErrorControlPanel, getAutoQASettings } from 'gridUI/actions';
import RunningAutoQA from './RunningAutoQA';
import Statistics from './Statistics';
import StatisticDetail from './StatisticDetail';
import WarningSVG from 'assets/images/svg/WarningSVG';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import ButtonBase from 'components/button/Base';
import * as workspaceActions from 'workspaces/actions';
import { RESOURCE_TYPES, USER_SETTINGS } from 'const';
import { useUserViewSettings } from 'hooks/workspace';

const useStyles = makeStyles(theme => ({
    tabWrapper: {
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    content: {
        height: 'calc(100% - 48px - 1px)',
        padding: theme.spacing(4, 3)
    },
    progressWrapper: {
        width: '100%',
        marginTop: 150
    },
    progressBar: {
        borderRadius: 4
    },
    successWrapper: {
        backgroundColor: hexToRgba(theme.colors.fuchsiaBlue, 0.1),
        borderRadius: 8,
        padding: 12,
        marginBottom: theme.spacing(3)
    },
    warningWrapper: {
        backgroundColor: hexToRgba(theme.colors.sun, 0.1),
        borderRadius: 8,
        padding: 12,
        marginBottom: theme.spacing(3)
    },
    indicator: {
        backgroundColor: theme.palette.primary.main
    },
    gifImage: {
        width: 120,
        height: 120
    }
}));

const RUNNING = 'Running Auto QA by lexiqa';
const STATISTICS = 'Statistics';

const useTabStyles = makeStyles(theme => ({
    root: {
        fontWeight: 400,
        color: theme.colors.primaryText
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));

function AutoQA({ handleClose, workspaceId, gridId, viewId, dbId, branchId, t }) {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const dispatch = useDispatch();
    const currentGrid = useGridDetail({ dbId, gridId });
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const dependencies = useDependencyListWithoutFakeId();
    const isRunningAutoQA = useIsRunningAutoQA();
    const autoQASettings = useAutoQASettings();
    const [isExecuted, setIsExecuted] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(RUNNING);
    const [selectedSource, setSelectedSource] = React.useState(null);
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState([]);
    const [runnedQAs, setRunnedQAs] = React.useState([]);
    const [selectedSettingId, setSelectedSettingId] = React.useState(null);
    const [selectedQAPair, setSelectedQAPair] = React.useState(null);
    const userViewSetting = useUserViewSettings({ wsId: workspaceId, dbId, viewId });

    React.useEffect(() => {
        dispatch(getAutoQASettings({}));
    }, [dispatch]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleSourceChange = option => {
        setSelectedSource(option);
        setSelectedTargetOptions([]);
    };

    const handleTargetSelect = option => {
        const isSelected = selectedTargetOptions.some(o => o.value === option.value);
        if (isSelected) {
            const newTargetOptions = selectedTargetOptions.filter(o => o.value !== option.value);
            setSelectedTargetOptions(newTargetOptions);
        } else {
            setSelectedTargetOptions([...selectedTargetOptions, option]);
        }
    };

    const hasNoDependencies = dependencies.length === 0;

    const parentDpDcColIds = React.useMemo(() => {
        return dependencies?.map(dpDc => dpDc?.parent);
    }, [dependencies]);

    const sourceOptions = React.useMemo(() => {
        const sources = viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isParent = parentDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isParent && columnType === columnTypes.TRANSLATION;
            })
            ?.map(lang => ({
                ...lang,
                value: lang?.id,
                group: lang?.group,
                label: lang?.name,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }))
            .filter(lang => lang?.group?.length > 2);
        return sources;
    }, [viewColumns, parentDpDcColIds]);

    const childDpDcColIds = React.useMemo(() => {
        if (!selectedSource) return [];
        return dependencies?.filter(dpDc => dpDc?.parent === selectedSource?.value)?.map(dpDc => dpDc?.child);
    }, [dependencies, selectedSource]);

    const targetOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isChild = childDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isChild && columnType === columnTypes.TRANSLATION;
            })
            .map(lang => ({
                ...lang,
                value: lang.id,
                group: lang.group,
                label: `${lang.name}`,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }))
            .filter(lang => lang?.group?.length > 2);
    }, [viewColumns, childDpDcColIds]);

    const handleRunAutoQA = async () => {
        dispatch(
            runAutoQA({
                viewId,
                sourceColumnId: selectedSource.id,
                targetColumnIds: selectedTargetOptions.map(o => o.id),
                settingId: selectedSettingId,
                successCallback: () => {
                    console.log('runAutoQA Success');
                    dispatch(showQAErrors());
                    dispatch(openErrorControlPanel());
                    setIsExecuted(true);
                    dispatch(
                        workspaceActions.setUserSettings({
                            dbId,
                            workspaceId,
                            resourceId: viewId,
                            resourceType: RESOURCE_TYPES.VIEW,
                            value: {
                                ...userViewSetting,
                                [USER_SETTINGS.VIEW.AUTO_QA]: true
                            }
                        })
                    );
                },
                errorCallback: error => {
                    console.log('runAutoQA Failed');
                    dispatch(
                        enqueueSnackbar({
                            message: error.message,
                            type: 'info'
                        })
                    );
                }
            })
        );
    };

    const handleSelectQA = async pair => {
        try {
            const qaStatistic = await getQAStatisticsApi({
                viewId,
                sourceColumnId: pair.sourceColumn.id,
                targetColumnIds: [pair.targetColumn.id]
            });
            setSelectedQAPair(qaStatistic);
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'info'
                })
            );
        }
    };

    React.useEffect(() => {
        const getQAStatistic = async () => {
            try {
                const newRunnedQAs = await getRunnedQAsApi({ viewId });
                setRunnedQAs(newRunnedQAs);
            } catch (error) {
                dispatch(
                    enqueueSnackbar({
                        message: error.message,
                        type: 'info'
                    })
                );
            }
        };
        getQAStatistic();
    }, [dispatch, viewId, selectedSource, selectedTargetOptions, currentTab]);

    const filteredRunnedQAs = React.useMemo(() => {
        const result = [];
        for (const qa of runnedQAs) {
            const sourceColumn = viewColumns?.find(viewColumn => viewColumn.id === qa.sourceColumnId);
            for (const targetColumnId of qa.targetColumnIds) {
                const targetColumn = viewColumns.find(viewColumn => viewColumn.id === targetColumnId);
                if (sourceColumn && targetColumn) result.push({ sourceColumn, targetColumn });
            }
        }
        return result;
    }, [runnedQAs, viewColumns]);

    return (
        <SidebarRightLayout title={t('auto_qa_by_lexiqa')} onClose={handleClose}>
            <Grid className={classes.tabWrapper}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    classes={{
                        indicator: classes.indicator
                    }}
                >
                    <Tab classes={tabClasses} value={RUNNING} label={RUNNING} />
                    <Tab classes={tabClasses} value={STATISTICS} label={STATISTICS} />
                </Tabs>
            </Grid>
            <Grid className={classes.content}>
                {currentTab === RUNNING && isRunningAutoQA && (
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="column"
                        className={classes.progressWrapper}
                    >
                        <Grid item>
                            <img className={classes.gifImage} src={searchGif} alt="Search gif" />
                        </Grid>
                        <Grid item style={{ width: '50%' }}>
                            <LinearProgress className={classes.progressBar} />
                        </Grid>
                        <Grid item style={{ marginTop: 14 }}>
                            <h4 className="prose prose-lg font-medium">Analyzing...</h4>
                        </Grid>
                    </Grid>
                )}

                {currentTab === RUNNING && !isRunningAutoQA && hasNoDependencies && (
                    <Grid container direction="column">
                        <Grid container alignItems="center" className={classes.warningWrapper}>
                            <Grid item xs={1}>
                                <WarningSVG />
                            </Grid>
                            <Grid item>
                                <p className="body2 inline">
                                    {t('no_dependencies')}
                                    <br />
                                    {t('must_dependencies_auto_qa')}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item container justify="center" style={{ paddingTop: 50, paddingBottom: 50 }}>
                            <NotFoundBoxSVG />
                        </Grid>
                        <Grid item>
                            <ButtonBase disabled fullWidth variant="contained">
                                Run Auto QA by lexiqa
                            </ButtonBase>
                        </Grid>
                    </Grid>
                )}

                {currentTab === RUNNING && isExecuted && !isRunningAutoQA && (
                    <Grid container alignItems="center" className={classes.successWrapper}>
                        <Grid item xs={1}>
                            <CheckIconSVG />
                        </Grid>
                        <Grid item>
                            <p className="body2 inline">
                                Auto QA by lexiqa has been executed.
                                <br />
                                You can check the statistics in{' '}
                            </p>
                            <p className="body1 inline">Statistics </p>
                            <p className="body2 inline">tab</p>
                        </Grid>
                    </Grid>
                )}

                {currentTab === RUNNING && !isRunningAutoQA && !hasNoDependencies && (
                    <RunningAutoQA
                        onRunAutoQA={handleRunAutoQA}
                        t={t}
                        selectedSource={selectedSource}
                        targetOptions={targetOptions}
                        selectedTargetOptions={selectedTargetOptions}
                        onSourceChange={handleSourceChange}
                        onTargetSelect={handleTargetSelect}
                        sourceOptions={sourceOptions}
                        autoQASettings={autoQASettings}
                        selectedSettingId={selectedSettingId}
                        onSettingChange={setSelectedSettingId}
                    />
                )}

                {currentTab === STATISTICS && !selectedQAPair && (
                    <Statistics
                        onSelectedQA={handleSelectQA}
                        filteredRunnedQAs={filteredRunnedQAs}
                        viewColumns={viewColumns}
                    />
                )}

                {currentTab === STATISTICS && selectedQAPair && (
                    <StatisticDetail
                        currentGrid={currentGrid}
                        selectedQAPair={selectedQAPair}
                        viewColumns={viewColumns}
                        onBack={() => setSelectedQAPair(null)}
                    />
                )}
                {currentTab === STATISTICS && filteredRunnedQAs.length === 0 && (
                    <div className="flex flex-col items-center justify-center h-full">
                        <NotFoundBoxSVG />
                        <div className="flex flex-col mt-3.5 items-center">
                            <h4 className="prose prose-lg font-medium">No statistics found</h4>
                            <p className="body2 text-center">Please execute Auto QA by lexiqa first</p>
                        </div>
                    </div>
                )}
            </Grid>
        </SidebarRightLayout>
    );
}

export default React.memo(AutoQA);
