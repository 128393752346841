import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ImportSVG from 'assets/images/svg/ImportSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import InputFile, { INPUT_FILE_ERROR_CODE } from 'components/inputs/InputFile';
import Tooltip from 'components/tooltip/Base';
import { GRID_UPLOAD_MAX_SIZE, GRID_UPLOAD_SUPPORTED_EXTENSIONS } from 'const';
import ImportView from 'gridUI/importExport/Import';
import { enqueueSnackbar } from 'notifier/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Dialog from 'components/dialog/Dialog';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItem: {
        // marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: 0
    },
    importBtnMore: {
        textAlign: 'left'
    },
    fullWidth: {
        width: '100%'
    }
}));

const ManageImport = ({ handleMoreActionsClickAway, isInIconMore, notAllowCloseIconMoreRef }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { workspaceId, gridId, viewId, dbId, branchId } = useParams();
    const [open, setOpen] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = React.useState(null);

    const handleOpenImport = React.useCallback(e => {
        setOpen(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
        setOpen(false);
        handleMoreActionsClickAway && handleMoreActionsClickAway();

        if (notAllowCloseIconMoreRef) {
            notAllowCloseIconMoreRef.current = false;
        }
    }, [handleMoreActionsClickAway, notAllowCloseIconMoreRef]);

    const handleFileImportInput = React.useCallback(
        files => {
            handleOpenImport();
            setUploadedFiles(files);
            handleMoreActionsClickAway && handleMoreActionsClickAway();
        },
        [handleMoreActionsClickAway, handleOpenImport]
    );

    const handleFileImportRejected = React.useCallback(
        errorCode => {
            const ERROR_MSGS = {
                [INPUT_FILE_ERROR_CODE.EXCEED_FILE_SIZE]: `Selected file exceeds the maximum file size (${GRID_UPLOAD_MAX_SIZE /
                    (1024 * 1024)} MB)`,
                [INPUT_FILE_ERROR_CODE.UNSUPPORTED_FILE_TYPE]: 'Selected file is unsupported file type'
            };

            dispatch(
                enqueueSnackbar({
                    message: ERROR_MSGS[errorCode],
                    type: 'info'
                })
            );

            if (notAllowCloseIconMoreRef) {
                notAllowCloseIconMoreRef.current = false;
            }
        },
        [dispatch, notAllowCloseIconMoreRef]
    );

    return (
        <>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.IMPORT_EXPORT}>
                {isInIconMore ? (
                    <Grid
                        item
                        id="g-h-menu-item-import"
                        tr-dt="Import File"
                        onClick={e => {
                            if (notAllowCloseIconMoreRef) {
                                notAllowCloseIconMoreRef.current = true;
                            }
                        }}
                    >
                        <InputFile
                            className={`file-input ${classes.addButton} ${classes.button} ${classes.importBtnMore}`}
                            onAccepted={handleFileImportInput}
                            onRejected={handleFileImportRejected}
                            accept={GRID_UPLOAD_SUPPORTED_EXTENSIONS}
                            maxSize={GRID_UPLOAD_MAX_SIZE}
                        >
                            <div className={classes.fullWidth}>
                                <ListItem
                                    icon={<ImportSVG height={18} width={18} color={theme.colors.darkness} />}
                                    name={t('toolbar_import_data_tooltip')}
                                />
                            </div>
                        </InputFile>
                    </Grid>
                ) : (
                    <Tooltip title={t('toolbar_import_data_tooltip')}>
                        <Grid item id="g-h-menu-item-import" tr-dt="Import File" className={classes.menuItem}>
                            <InputFile
                                className={`file-input ${classes.addButton} ${classes.button}`}
                                onAccepted={handleFileImportInput}
                                onRejected={handleFileImportRejected}
                                accept={GRID_UPLOAD_SUPPORTED_EXTENSIONS}
                                maxSize={GRID_UPLOAD_MAX_SIZE}
                            >
                                <ImportSVG height={18} width={18} color={theme.colors.darkness} />
                            </InputFile>
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            <Dialog
                id="dialog-import-export"
                maxWidth={false}
                open={open}
                onClose={handleCloseModal}
                style={{ zIndex: 1301 }}
            >
                <ImportView
                    onClose={handleCloseModal}
                    files={uploadedFiles}
                    workspaceId={workspaceId}
                    gridId={gridId}
                    viewId={viewId}
                    dbId={dbId}
                    branchId={branchId}
                />
            </Dialog>
        </>
    );
};

export default React.memo(ManageImport);
