import React from 'react';
import { Slide, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NewVersionNotifySVG from 'assets/images/svg/NewVersionNotifySVG';
import ButtonBase from 'components/button/Base';
import { useShowAppVersion } from 'hooks/app';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        position: 'absolute',
        zIndex: 10000,
        width: 470,
        margin: '0 auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        background: theme.colors.white,
        borderRadius: 6,
        boxShadow: theme.shadows[1],
        top: theme.spacing(2),
        left: 0,
        right: 0,

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center'
        }
    }
}));

function Version() {
    const classes = useStyles();
    const { t } = useTranslation();
    const showAppVersion = useShowAppVersion();
    const [isOpenConfirmRefresh, setIsOpenConfirmRefresh] = React.useState(showAppVersion);
    const location = useLocation();
    const forceReload = React.useRef(false);

    const refreshBrowser = () => {
        window.location.reload(true);
    };

    React.useLayoutEffect(() => {
        if (forceReload.current) {
            refreshBrowser();
        }
    }, [location.pathname]);

    React.useEffect(() => {
        let unlisten = () => {};
        if (showAppVersion) {
            setIsOpenConfirmRefresh(true);
            forceReload.current = true;
        }
        return unlisten;
    }, [showAppVersion]);

    return (
        <Slide direction="down" in={isOpenConfirmRefresh} mountOnEnter unmountOnExit>
            <Grid container spacing={2} justify="space-between" alignItems="center" className={classes.paper}>
                <Grid item>
                    <NewVersionNotifySVG />
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="body1">
                                {t('new_version_title')}{' '}
                                <span role="img" aria-label="emoji">
                                    ✨
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{t('new_version_text')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ButtonBase width={100} onClick={refreshBrowser} variant="contained" size={'small'}>
                        {t('global_refresh')}
                    </ButtonBase>
                </Grid>
            </Grid>
        </Slide>
    );
}

function VersionWrapper() {
    const showAppVersion = useShowAppVersion();
    // if (window.location.hostname === 'localhost') return null;
    if (!showAppVersion) return null;
    return <Version />;
}

export default React.memo(VersionWrapper);
