import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import ArrowKeyDownSVG from 'assets/images/svg/ArrowKeyDownSVG';
import ArrowKeyUpSVG from 'assets/images/svg/ArrowKeyUpSVG';
import Tooltip from 'components/tooltip/Base';
import { isMac } from 'utils/os';
import { useTranslation } from 'react-i18next';

const TooltipArrows = ({ handleUp, handleDown }) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid item>
                <Tooltip
                    placement="bottom"
                    title={
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <p className="text-light font-medium">{t('previous_cell')}:</p>
                            </Grid>
                            <Grid item>
                                <p className="body2 py-0.5 px-1 bg-white rounded-sm">{isMac() ? 'Cmd' : 'Ctrl'}</p>
                            </Grid>
                            <Grid item>
                                <p className="body2 py-0.5 px-1 bg-white rounded-sm">↑</p>
                            </Grid>
                        </Grid>
                    }
                >
                    <IconButton onClick={handleUp} size="small">
                        <ArrowKeyUpSVG />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip
                    placement="bottom"
                    title={
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <p className="text-light font-medium">{t('next_cell')}:</p>
                            </Grid>
                            <Grid item>
                                <p className="body2 py-0.5 px-1 bg-white rounded-sm">{isMac() ? 'Cmd' : 'Ctrl'}</p>
                            </Grid>
                            <Grid item>
                                <p className="body2 py-0.5 px-1 bg-white rounded-sm">↓</p>
                            </Grid>
                        </Grid>
                    }
                >
                    <IconButton onClick={handleDown} size="small">
                        <ArrowKeyDownSVG />
                    </IconButton>
                </Tooltip>
            </Grid>
        </>
    );
};

export default React.memo(TooltipArrows);
