const _PREFIX = `[ADVANCED_SEARCH]`;

export const FETCH_FILTERS = `${_PREFIX}FETCH_FILTERS_SUCCESS`;
export const FETCH_COLUMNS = `${_PREFIX}FETCH_COLUMN_SUCCESS`;

export const CREATE_ADVANCED_SEARCH = `${_PREFIX}CREATE_ADVANCED_SEARCH`;
export const FETCH_ADVANCED_SEARCH_RECORDS_START = `${_PREFIX}FETCH_ADVANCED_SEARCH_RECORDS_START`;
export const FETCH_ADVANCED_SEARCH_RECORDS_FAILED = `${_PREFIX}FETCH_ADVANCED_SEARCH_RECORDS_FAILED`;
export const FETCH_ADVANCED_SEARCH_RECORDS_SUCCESS = `${_PREFIX}FETCH_ADVANCED_SEARCH_RECORDS_SUCCESS`;
export const FETCH_LIST = `${_PREFIX}FETCH_LIST`;
export const FETCH_LIST_SUCCESS = `${_PREFIX}FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILED = `${_PREFIX}FETCH_LIST_FAILED`;
export const UPDATE_ADVANCED_SEARCH = `${_PREFIX}UPDATE_ADVANCED_SEARCH`;
export const DELETE_ADVANCED_SEARCH = `${_PREFIX}DELETE_ADVANCED_SEARCH`;

export const FETCH_ADVANCED_SEARCH = `${_PREFIX}FETCH_ADVANCED_SEARCH`;
export const CHANGE_FIXED_COLUMN_COUNT = `${_PREFIX}CHANGE_FIXED_COLUMN_COUNT`;
export const SAVE_TABLE_INFO = `${_PREFIX}SAVE_TABLE_INFO`;
export const CLEAR_TABLE_DATA = `${_PREFIX}CLEAR_TABLE_DATA`;
export const SELECTING_CELL_SELECTION = `${_PREFIX}SELECTING_CELL_SELECTION`;
export const STARTING_CELL_COPY_SELECTION = `${_PREFIX}STARTING_CELL_COPY_SELECTION`;
export const REGISTER_HOLD_EVENT = `${_PREFIX}REGISTER_HOLD_EVENT`;
export const REMOVE_DOING_ACTION = `${_PREFIX}REMOVE_DOING_ACTION`;
export const REGISTER_DOING_ACTION = `${_PREFIX}REGISTER_DOING_ACTION`;
export const CHANGE_CELL_DATA = `${_PREFIX}CHANGE_CELL_DATA`;
export const OPEN_CELL_EDIT = `${_PREFIX}OPEN_CELL_EDIT`;
export const DELETE_CELL_INFO = `${_PREFIX}DELETE_CELL_INFO`;
export const SET_CHARACTER_KEY = `${_PREFIX}SET_CHARACTER_KEY`;
export const CANCEL_CELL_EDIT = `${_PREFIX}CANCEL_CELL_EDIT`;

export const FETCH_MORE_ROWS_START = `${_PREFIX}FETCH_MORE_ROWS_START`;
export const FETCH_MORE_ROWS_FAILED = `${_PREFIX}FETCH_MORE_ROWS_FAILED`;
export const FETCH_MORE_ROWS_SUCCESS = `${_PREFIX}FETCH_MORE_ROWS_SUCCESS`;
export const QUICK_SORT_CHANGE = `${_PREFIX}QUICK_SORT_CHANGE`;
export const CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN = `${_PREFIX}CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN`;
export const CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN = `${_PREFIX}CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN`;
export const QUICK_FILTER_EXTRA_CHANGE = `${_PREFIX}QUICK_FILTER_EXTRA_CHANGE`;
export const QUICK_FILTER_INPUT_CHANGE = `${_PREFIX}QUICK_FILTER_INPUT_CHANGE`;
export const QUICK_FILTER_REGEX_CHANGE = `${_PREFIX}QUICK_FILTER_REGEX_CHANGE`;
export const RESET_QUICK_FILTER_VALUE = `${_PREFIX}RESET_QUICK_FILTER_VALUE`;
export const FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS = `${_PREFIX}FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS`;
export const FETCH_EXPORT_PREVIEW_DATA = `${_PREFIX}FETCH_EXPORT_PREVIEW_DATA`;
export const RESET_CELL_STATUS = `${_PREFIX}RESET_CELL_STATUS`;
export const SET_ROUTE_INFO = `${_PREFIX}SET_ROUTE_INFO`;

//optimistic
export const OPTIMISTIC_DELETE_FILE_OF_CELL = `${_PREFIX}OPTIMISTIC_DELETE_FILE_OF_CELL`;
export const OPTIMISTIC_DELETE_RECORD = `${_PREFIX}OPTIMISTIC_DELETE_RECORD`;
export const OPTIMISTIC_RESIZE_RECORD = `${_PREFIX}OPTIMISTIC_RESIZE_RECORD`;
export const OPTIMISTIC_UPDATE_CELL = `${_PREFIX}OPTIMISTIC_UPDATE_CELL`;

export const COMMIT_ACTION = `${_PREFIX}COMMIT_ACTION`;
export const REMOVE_ACTION = `${_PREFIX}REMOVE_ACTION`;
export const REVERT_ACTION = `${_PREFIX}[OPTIMISTIC]REVERT_ACTION`;

export const FETCH_VIEW_FILTERS = `${_PREFIX}FETCH_VIEW_FILTERS`;
export const FETCH_VIEW_FILTERS_SUCCESS = `${_PREFIX}FETCH_VIEW_FILTERS_SUCCESS`;
export const FETCH_VIEW_FILTERS_FAILED = `${_PREFIX}FETCH_VIEW_FILTERS_FAILED`;

export const CREATE_VIEW_FILTER = `${_PREFIX}CREATE_VIEW_FILTER`;
export const CREATE_VIEW_FILTER_SUCCESS = `${_PREFIX}CREATE_VIEW_FILTER_SUCCESS`;
export const CREATE_VIEW_FILTER_FAILED = `${_PREFIX}CREATE_VIEW_FILTER_FAILED`;

export const UPDATE_VIEW_FILTER = `${_PREFIX}UPDATE_VIEW_FILTER`;
export const UPDATE_VIEW_FILTER_SUCCESS = `${_PREFIX}UPDATE_VIEW_FILTER_SUCCESS`;
export const UPDATE_VIEW_FILTER_FAILED = `${_PREFIX}UPDATE_VIEW_FILTER_FAILED`;

export const DELETE_VIEW_FILTER = `${_PREFIX}DELETE_VIEW_FILTER`;
export const DELETE_VIEW_FILTER_SUCCESS = `${_PREFIX}DELETE_VIEW_FILTER_SUCCESS`;
export const DELETE_VIEW_FILTER_FAILED = `${_PREFIX}DELETE_VIEW_FILTER_FAILED`;

export const UPDATE_VIEW_FILTER_ID_AFTER_CREATE = `${_PREFIX}UPDATE_VIEW_FILTER_ID_AFTER_CREATE`;
export const UPDATE_VIEW_FILTER_ONLY = `${_PREFIX}UPDATE_VIEW_FILTER_ONLY`;
export const CREATE_VIEW_SORT = `${_PREFIX}CREATE_VIEW_SORT`;

export const DELETE_VIEW_SORT = `${_PREFIX}DELETE_VIEW_SORT`;
export const UPDATE_VIEW_SORT = `${_PREFIX}UPDATE_VIEW_SORT`;
export const DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET = `${_PREFIX}DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET`;
export const RESET_EXPORT_REMOVED_COLUMNS = `${_PREFIX}RESET_EXPORT_REMOVED_COLUMNS`;
export const ADD_EXPORT_REMOVED_COLUMN = `${_PREFIX}ADD_EXPORT_REMOVED_COLUMN`;
export const RESTORE_EXPORT_REMOVED_COLUMN = `${_PREFIX}RESTORE_EXPORT_REMOVED_COLUMN`;
export const SET_SELECTING = `${_PREFIX}SET_SELECTING`;
export const SET_SELECTING_COPY = `${_PREFIX}SET_SELECTING_COPY`;
export const RESET_GRID = `${_PREFIX}RESET_GRID`;
export const OPEN_CELL_FILE_PREVIEW = `${_PREFIX}OPEN_CELL_FILE_PREVIEW`;
export const CLOSE_CELL_FILE_PREVIEW = `${_PREFIX}CLOSE_CELL_FILE_PREVIEW`;
export const PINNED_SEARCH = `${_PREFIX}PINNED_SEARCH`;
export const UNPINNED_SEARCH = `${_PREFIX}UNPINNED_SEARCH`;
export const SET_SEARCH_RECORDS = `${_PREFIX}SET_SEARCH_RECORDS`;
export const SET_SUCCESS_REPLACE_ALL = `${_PREFIX}SET_SUCCESS_REPLACE_ALL`;

export const SET_REPLACING_TYPE = `${_PREFIX}SET_REPLACING_TYPE`;
export const SET_CLEAR_SEARCH_STATE = `${_PREFIX}SET_CLEAR_SEARCH_STATE`;
export const CLOSE_REPLACE_SEARCH = `${_PREFIX}CLOSE_REPLACE_SEARCH`;
export const OPEN_REPLACE_SEARCH = `${_PREFIX}OPEN_REPLACE_SEARCH`;
export const SET_REPLACING_TEXT = `${_PREFIX}SET_REPLACING_TEXT`;
export const SEARCHING_RECORD = `${_PREFIX}SEARCHING_RECORD`;
export const SET_SEARCH = `${_PREFIX}SET_SEARCH`;

export const SET_SELECTED_COLUMNS = `${_PREFIX}SET_SELECTED_COLUMNS`;
export const SET_MATCHES = `${_PREFIX}SET_MATCHES`;
export const UPDATE_DATA = `${_PREFIX}UPDATE_DATA`;
export const SET_GLOBAL_ACTION = `${_PREFIX}SET_GLOBAL_ACTION`;

export const SET_SELECTED_ADVANCED_SEARCH_ID = `${_PREFIX}SET_SELECTED_ADVANCED_SEARCH_ID`;
export const SET_HIDE_WARNING = `${_PREFIX}SET_HIDE_WARNING`;
export const SET_CLEAR_GRID = `${_PREFIX}SET_CLEAR_GRID`;

export const SET_TAB = `${_PREFIX}SET_TAB`;

export const SET_ADVANCED_SEARCH_SNAPSHOT = `${_PREFIX}SET_ADVANCED_SEARCH_SNAPSHOT`;
export const SET_OPEN_CONFIRMATION = `${_PREFIX}SET_OPEN_CONFIRMATION`;

export const COLUMN_SELECTION = `${_PREFIX}[COLUMN]COLUMN_SELECTION`;
export const CLEAR_COLUMNS_SELECTION = `${_PREFIX}[COLUMNS]CLEAR_COLUMNS_SELECTION`;
export const SET_COLUMNS_SELECTION = `${_PREFIX}[COLUMNS]SET_COLUMNS_SELECTION`;
export const REORDER_COLUMNS = `${_PREFIX}[COLUMNS]REORDER_COLUMNS`;
export const REORDER_COLUMNS_SUCCESS = `${_PREFIX}[COLUMNS]REORDER_COLUMNS_SUCCESS`;
export const OPTIMISTIC_REORDER_COLUMNS = `${_PREFIX}[COLUMNS]OPTIMISTIC_REORDER_COLUMNS`;
export const RESET_TRIGGER_RECOMPUTED_GRID = `${_PREFIX}RESET_TRIGGER_RECOMPUTED_GRID`;
export const TRIGGER_RECOMPUTED_GRID = `${_PREFIX}TRIGGER_RECOMPUTED_GRID`;
export const OPTIMISTIC_RESIZE_COLUMN = `${_PREFIX}[COLUMNS]OPTIMISTIC_RESIZE_COLUMN`;
export const RESIZE_COLUMN = `${_PREFIX}[COLUMNS]RESIZE_COLUMN`;
