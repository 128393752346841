import React from "react";
import GridItemSkeleton from "./GridItemSkeleton";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
} from "@material-ui/core";
import useMediaQueries from "../hooks/useMediaQueries"
let SPACING = 136;

const useStyles = makeStyles(theme => ({
    root: {
        width: `calc(100% + ${SPACING}px)`,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -(SPACING / 2),
        marginRight: -(SPACING / 2),
    },
    gridItem: {
        paddingTop: "0 !important",
        paddingBottom: "8px !important",
        paddingLeft: SPACING / 2,
        paddingRight: SPACING / 2,
        width: "100%"
    },
}))

function GridListSkeleton() {
    const classes = useStyles();
    const {isLargeDesktop} = useMediaQueries();
    
    return <Grid container style={{flexGrow: 0}} className={classes.root} direction="row">
        {Array.from(Array(isLargeDesktop ? 6 : 4).keys()).map((index) =>
            <Grid 
                item
                lg={6}
                xl={4}
                md={6}
                xs={12}
                key={index}
                className={classes.gridItem}
            >
            <GridItemSkeleton />
        </Grid>
        )}
    </Grid>
    
}

export default GridListSkeleton;