import * as columnTypes from 'const/columnTypes';

export const SWITCHING_COLUMN_MAPPING = {
    [columnTypes.BOOLEAN]: {
        LOST_COLUMN_TYPES: [
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {},
        OPTIMISTIC_VALUES: ['yes', 'true', '1', 'checked']
    },
    [columnTypes.SINGLE_LINE]: {
        LOST_COLUMN_TYPES: [
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [columnTypes.BOOLEAN, columnTypes.NUMBER, columnTypes.DATETIME],
        POSSIBLE_CONVERT_VALUES: {
            DEFAULT: ['yes', 'no', 'true', 'false', 'checked', 'unchecked', '1', '0'],
            [columnTypes.BOOLEAN]: ['yes', 'no', 'true', 'false', 'checked', 'unchecked', '1', '0'],
            [columnTypes.NUMBER]: ['1214', '1.24'],
            [columnTypes.DATETIME]: [
                '2011-12-03T10:15:30+01:00[Europe/Paris]',
                '2011-12-03T10:15:30+01:00',
                '2011-12-03T10:15:30',
                '2011-12-03'
            ]
        }
    },
    [columnTypes.MULTIPLE_LINES]: {
        LOST_COLUMN_TYPES: [
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [columnTypes.BOOLEAN, columnTypes.NUMBER, columnTypes.DATETIME],
        POSSIBLE_CONVERT_VALUES: {
            DEFAULT: ['yes', 'no', 'true', 'false', 'checked', 'unchecked', '1', '0'],
            [columnTypes.BOOLEAN]: ['yes', 'no', 'true', 'false', 'checked', 'unchecked', '1', '0'],
            [columnTypes.NUMBER]: ['1214', '1.24'],
            [columnTypes.DATETIME]: [
                '2011-12-03T10:15:30+01:00[Europe/Paris]',
                '2011-12-03T10:15:30+01:00',
                '2011-12-03T10:15:30',
                '2011-12-03'
            ]
        }
    },
    [columnTypes.TRANSLATION]: {
        LOST_COLUMN_TYPES: [
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [columnTypes.BOOLEAN, columnTypes.NUMBER, columnTypes.DATETIME],
        POSSIBLE_CONVERT_VALUES: {
            DEFAULT: ['yes', 'no', 'true', 'false', 'checked', 'unchecked', '1', '0'],
            [columnTypes.BOOLEAN]: ['yes', 'no', 'true', 'false', 'checked', 'unchecked', '1', '0'],
            [columnTypes.NUMBER]: ['1214', '1.24'],
            [columnTypes.DATETIME]: [
                '2011-12-03T10:15:30+01:00[Europe/Paris]',
                '2011-12-03T10:15:30+01:00',
                '2011-12-03T10:15:30',
                '2011-12-03'
            ]
        }
    },
    [columnTypes.NUMBER]: {
        LOST_COLUMN_TYPES: [
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [columnTypes.BOOLEAN],
        POSSIBLE_CONVERT_VALUES: {
            [columnTypes.BOOLEAN]: ['1', '0'],
            DEFAULT: ['1', '0']
        }
    },
    [columnTypes.SINGLE_SELECTION]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.MULTIPLE_SELECTIONS]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.DATETIME]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.REFERENCE]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.FILES,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.FILES]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.MARKDOWN]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION,
            columnTypes.RICH_TEXT
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.RICH_TEXT]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.JSON_LD,
            columnTypes.HTML,
            columnTypes.YAML,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION,
            columnTypes.MARKDOWN
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.JSON_LD]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.HTML]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    },
    [columnTypes.YAML]: {
        LOST_COLUMN_TYPES: [
            columnTypes.BOOLEAN,
            columnTypes.NUMBER,
            columnTypes.SINGLE_SELECTION,
            columnTypes.MULTIPLE_SELECTIONS,
            columnTypes.DATETIME,
            columnTypes.REFERENCE,
            columnTypes.FILES,
            columnTypes.SINGLE_LINE,
            columnTypes.MULTIPLE_LINES,
            columnTypes.TRANSLATION,
            columnTypes.MARKDOWN,
            columnTypes.RICH_TEXT
        ],
        WARNING_COLUMN_TYPES: [],
        POSSIBLE_CONVERT_VALUES: {}
    }
};
