import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InputText from 'components/inputs/InputText';
import ListItem from 'components/list/Item';
import DuplicateSVG from 'assets/images/svg/DuplicateSVG';
import { useTranslation } from 'react-i18next';
import { isKbEnter, isKbEscape } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import NestedMenuItem from 'components/menus/HoverMenu';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { useDispatch } from 'react-redux';
import { cloneAutomation, deleteAutomation, invokeAutomation } from './action';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notifier/actions';
import { useViewList, useQuickFilters, useTotalRecords } from 'hooks/gridUI';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import ViewIcon from 'gridUI/views/ViewIcon';
import PlayCircleSVG from 'assets/images/svg/PlayCircleSVG';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { WEB_HOOK_TRIGGER_ACTIONS } from 'const/gridUI';
import EditPaperSVG from 'assets/images/svg/EditPaperSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import AutomationAddForm from './AutomationAddForm';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260
    },
    name: {
        padding: '0 10px',
        marginBottom: 10
    },
    divider: {
        margin: '13px 10px 10px',
        height: 1,
        backgroundColor: theme.colors.lightGrey
    },
    dialogDelete: {
        zIndex: `1301 !important`
    }
}));

const AutomationItemPopup = ({
    id,
    title,
    description,
    iconFileId,
    triggers,
    onClickAway,
    onChangeTitle,
    onChangeDetail,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'left'
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right'
    },
    successCloneCallback,
    successDeleteCallback,
    showEdit,
    onClickEditDescription,
    hideChangeName
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hookTitle, setHookTitle] = useState(title || '');
    const [openDelete, setOpenDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isOpenConfirmInvoke, setIsOpenConfirmInvoke] = useState(false);
    const [invoking, setInvoking] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const rootRef = useRef();
    const { viewId } = useParams();
    const viewList = useViewList();
    const quickFilters = useQuickFilters();
    const totalRecords = useTotalRecords();

    const viewListFiltered = useMemo(() => {
        return viewList
            .filter(view => view.id !== viewId)
            .map(view => ({
                ...view
            }));
    }, [viewId, viewList]);

    const allowInvoke = useMemo(() => {
        return triggers.every(event => {
            return [
                WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED,
                WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED,
                WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED
            ].includes(event);
        });
    }, [triggers]);

    const stopPropagation = useCallback(e => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const clickAwayHandler = useCallback(() => {
        onClickAway();
        if (hookTitle === '' || hookTitle === title) {
            return;
        }

        onChangeTitle({
            automationId: id,
            title: hookTitle
        });
    }, [hookTitle, id, onChangeTitle, onClickAway, title]);

    useClickAwaitListener(rootRef, e => {
        if (openDelete || isOpenConfirmInvoke || openAddDialog) return;
        clickAwayHandler();
    });

    const handleKeyDown = useCallback(
        e => {
            if (isKbEscape(e)) {
                onClickAway();
                return;
            }
            if (!isKbEnter(e)) return;
            clickAwayHandler();
        },
        [clickAwayHandler, onClickAway]
    );

    const handleDelete = useCallback(
        e => {
            stopPropagation(e);
            setOpenDelete(true);
        },
        [stopPropagation]
    );

    const handleCloseDelete = useCallback(
        e => {
            if (deleting) return;
            setOpenDelete(false);
        },
        [deleting]
    );

    const handleAgreeDelete = useCallback(
        e => {
            stopPropagation(e);
            setDeleting(true);
            dispatch(
                deleteAutomation({
                    id,
                    successCallback: responseData => {
                        successDeleteCallback && successDeleteCallback({ id });
                        dispatch(
                            enqueueSnackbar({
                                message: t('global_succeeded'),
                                type: 'info'
                            })
                        );
                    },
                    errorCallback: () => {
                        setDeleting(false);
                    }
                })
            );
        },
        [dispatch, id, stopPropagation, successDeleteCallback, t]
    );

    const handleDuplicate = useCallback(
        e => {
            stopPropagation(e);
            onClickAway();
            dispatch(
                cloneAutomation({
                    id,
                    body: {
                        toViewId: viewId
                    },
                    successCallback: responseData => {
                        successCloneCallback && successCloneCallback(responseData);
                        dispatch(
                            enqueueSnackbar({
                                message: t('global_succeeded'),
                                type: 'info'
                            })
                        );
                    }
                })
            );
        },
        [dispatch, id, onClickAway, stopPropagation, successCloneCallback, t, viewId]
    );

    const handleDuplicateToAnotherView = useCallback(
        (e, viewId) => {
            stopPropagation(e);
            onClickAway();
            dispatch(
                cloneAutomation({
                    id,
                    body: {
                        toViewId: viewId
                    },
                    successCallback: responseData => {
                        dispatch(
                            enqueueSnackbar({
                                message: t('global_succeeded'),
                                type: 'info'
                            })
                        );
                    }
                })
            );
        },
        [dispatch, id, onClickAway, stopPropagation, t]
    );

    const openInvokeConfirm = useCallback(
        e => {
            stopPropagation(e);
            setIsOpenConfirmInvoke(true);
        },
        [stopPropagation]
    );

    const closeInvokeConfirm = useCallback(
        e => {
            stopPropagation(e);
            setIsOpenConfirmInvoke(false);
        },
        [stopPropagation]
    );

    const handleInvoke = useCallback(
        e => {
            stopPropagation(e);
            if (invoking) return;
            setInvoking(true);
            dispatch(
                invokeAutomation({
                    automationId: id,
                    body: {
                        recordFilter: JSON.stringify(formatQuickFilters(quickFilters))
                    },
                    successCallback: () => {
                        dispatch(enqueueSnackbar({ message: 'Invoke sucess', type: 'info' }));
                        setInvoking(false);
                        closeInvokeConfirm();
                        onClickAway();
                    },
                    errorCallback: () => {
                        setInvoking(false);
                        dispatch(enqueueSnackbar({ message: 'Invoke failed', type: 'info' }));
                    }
                })
            );
        },
        [stopPropagation, invoking, dispatch, id, quickFilters, closeInvokeConfirm, onClickAway]
    );

    const handleOpenEdit = useCallback(
        e => {
            stopPropagation(e);
            onClickEditDescription && onClickEditDescription();
        },
        [stopPropagation, onClickEditDescription]
    );

    const handleOpenAddDialog = useCallback(() => {
        setOpenAddDialog(true);
    }, []);

    const handleCloseAddDialog = useCallback(() => {
        onClickAway();
    }, [onClickAway]);

    const handleSave = useCallback(
        newAutomation => {
            setIsUpdating(true);
            onChangeDetail({
                automationId: id,
                body: newAutomation,
                onSuccess: () => {
                    onClickAway();
                },
                onError: () => {
                    setIsUpdating(false);
                }
            });
        },
        [id, onChangeDetail, onClickAway]
    );

    return (
        <Grid ref={rootRef} container className={classes.root} direction="column">
            {!hideChangeName ? (
                <Grid item className={classes.name}>
                    <InputText
                        name="hookTitle"
                        value={hookTitle}
                        onChange={e => setHookTitle(e.target.value)}
                        placeholder={t(`global_title`)}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        onFocus={stopPropagation}
                        onClick={stopPropagation}
                        className={classes.input}
                        autoSelect={true}
                    />
                </Grid>
            ) : (
                <Grid item onClick={handleOpenAddDialog}>
                    <ListItem icon={<EditSVG color="#78778B" />} name={'Edit detail'} />
                    <Dialog maxWidth="md" open={openAddDialog} onClose={handleCloseAddDialog}>
                        <AutomationAddForm
                            automation={{ id, title, description, iconFileId }}
                            onSave={handleSave}
                            onCancel={handleCloseAddDialog}
                            isSaving={isUpdating}
                        />
                    </Dialog>
                </Grid>
            )}
            <Grid item onClick={handleDuplicate}>
                <ListItem icon={<DuplicateSVG color="#78778B" />} name={t(`global_duplicate`)} />
            </Grid>
            {viewListFiltered?.length > 0 && (
                <Grid item>
                    <NestedMenuItem
                        label={t('global_duplicate_to_another_view')}
                        parentMenuOpen={true}
                        icon={<DuplicateSVG color="#78778B" />}
                        anchorOrigin={anchorOrigin}
                        transformOrigin={transformOrigin}
                        ContainerProps={{
                            onClick: stopPropagation
                        }}
                    >
                        <Grid container direction="column" style={{ width: 215 }}>
                            {viewListFiltered.map(view => (
                                <ListItem
                                    key={view.id}
                                    name={view.name}
                                    icon={() => <ViewIcon view={view} size={24} />}
                                    onClick={e => handleDuplicateToAnotherView(e, view.id)}
                                />
                            ))}
                        </Grid>
                    </NestedMenuItem>
                </Grid>
            )}
            {allowInvoke && (
                <Grid item onClick={openInvokeConfirm}>
                    <ListItem icon={<PlayCircleSVG />} name={t(`automation_invoke_all_records`)} />
                </Grid>
            )}
            {showEdit && (
                <Grid item onClick={handleOpenEdit}>
                    <ListItem icon={<EditPaperSVG />} name={t(`automation_edit_description`)} />
                </Grid>
            )}
            <Grid item className={classes.divider} />
            <Grid item onClick={handleDelete}>
                <ListItem icon={<DeleteSVG />} name={t(`global_delete`)} />
            </Grid>
            <Dialog
                className={classes.dialogDelete}
                open={openDelete}
                onClose={handleCloseDelete}
                onClick={stopPropagation}
            >
                <ConfirmBox
                    title={t('automation_confirm_delete_title')}
                    body={<p className="body2">{t('automation_confirm_delete_content')}</p>}
                    handleCancel={handleCloseDelete}
                    onClose={handleCloseDelete}
                    handleAgreed={handleAgreeDelete}
                    agreeLabel={t('global_delete')}
                    isLoading={deleting}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
            <Dialog
                className={classes.dialogDelete}
                open={isOpenConfirmInvoke}
                onClose={closeInvokeConfirm}
                onClick={stopPropagation}
            >
                <ConfirmBox
                    title="Execute trigger"
                    body={
                        <>
                            <p className="body1 inline">{totalRecords}</p>{' '}
                            <p className="body2 inline">record(s) will be sent via</p>{' '}
                            <p className="body1 inline">{title}</p>{' '}
                            <p className="body2 inline">trigger. Are you sure you want to do it?</p>
                        </>
                    }
                    handleCancel={closeInvokeConfirm}
                    onClose={closeInvokeConfirm}
                    handleAgreed={handleInvoke}
                    agreeLabel="Execute"
                    isLoading={invoking}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(AutomationItemPopup);
