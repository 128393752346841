import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    validationItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 4,
        '& div': {
            width: 6,
            height: 6,
            background: theme.colors.midGrey,
            borderRadius: 6,
            marginRight: 8
        },
        '& p': {
            color: theme.colors.midGrey
        }
    },
    validationItemActive: {
        '& div': {
            width: 6,
            height: 6,
            background: theme.palette.primary.main,
            borderRadius: 6,
            marginRight: 8
        },
        '& p': {
            color: theme.colors.black
        }
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    successMessage: {
        color: theme.colors.atlantis
    }
}));

function PasswordValidationPopup({
    hasValidLength,
    hasAtLeastOneUpperCase,
    hasAtLeastOneLowerCase,
    hasAtLeastOneDigit,
    hasAtleastOneSpecialChar,
    isValidPassword,
    passwordValue
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const hasPasswordValue = Boolean(passwordValue);

    return (
        <div className={classes.root}>
            {isValidPassword && (
                <Typography variant="body2" className={classes.successMessage}>
                    {t('great_that_is_a_strong_password')}
                </Typography>
            )}
            {!isValidPassword && hasPasswordValue && (
                <Typography variant="body2" className={classes.errorMessage}>
                    {t('you_need_to_follow_criteria')}
                </Typography>
            )}
            <Typography variant="body2" style={{ marginTop: 4 }}>
                {t('password_must')}:
            </Typography>
            <div className={`${classes.validationItem} ${hasValidLength && classes.validationItemActive}`}>
                <div />
                <Typography variant="body2" display="inline">
                    {t('password_validation_between')}
                </Typography>
            </div>
            <div className={`${classes.validationItem} ${hasAtLeastOneUpperCase && classes.validationItemActive}`}>
                <div />
                <Typography variant="body2" display="inline">
                    {t('password_validation_uppercase')}
                </Typography>
            </div>
            <div className={`${classes.validationItem} ${hasAtLeastOneLowerCase && classes.validationItemActive}`}>
                <div />
                <Typography variant="body2" display="inline">
                    {t('password_validation_lowercase')}
                </Typography>
            </div>
            <div className={`${classes.validationItem} ${hasAtLeastOneDigit && classes.validationItemActive}`}>
                <div />
                <Typography variant="body2" display="inline">
                    {t('password_validation_digit')}
                </Typography>
            </div>
            <div className={`${classes.validationItem} ${hasAtleastOneSpecialChar && classes.validationItemActive}`}>
                <div />
                <Typography variant="body2" display="inline">
                    {t('password_validation_special_char')}
                </Typography>
            </div>
        </div>
    );
}

export default PasswordValidationPopup;
