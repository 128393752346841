import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Radio } from '@material-ui/core';
import SettingContentLayout from 'permission/common/Layout';
import { useTranslation } from 'react-i18next';
import TableVirtualize from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import MagicWandSVG from 'assets/images/svg/MagicWandSVG';
import hexToRgba from 'hex-to-rgba';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { useGetLQAModelDefault, useGetLQAModels } from 'hooks/gridUI/lqa';
import * as workspaceActions from 'workspaces/actions';
import LQAItem from './LQAItem';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: `40px ${theme.spacing(4)}px`
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    subRow: {
        padding: theme.spacing(4),
        overflowY: 'auto'
    },
    assignedProject: {
        height: 36,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        border: `1px solid ${theme.colors.border}`,
        borderTop: 'none'
    },
    header: {
        background: theme.colors.paleGrey,
        height: 40,
        border: 'none'
    },
    link: {
        color: theme.colors.dodgerBlue,
        textDecoration: 'underline'
    },
    table: {
        border: `1px solid ${theme.colors.border}`
    },
    indicator: {
        width: 10,
        height: 10
    },
    serious: {
        background: hexToRgba(theme.colors.brightRed, 0.8)
    },
    minor: {
        background: hexToRgba(theme.colors.atlantis, 0.8)
    },
    headerRow: {
        background: theme.colors.paleGrey
    }
}));

function LQAPage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [sorting, setSorting] = React.useState({});

    const lqaModelDefault = useGetLQAModelDefault();
    const lqaModels = useGetLQAModels();

    React.useEffect(() => {
        dispatch(gridUIActions.fetchLQADefault({}));
        dispatch(gridUIActions.fetchLQAModels({}));
        dispatch(workspaceActions.fetchWorkspaces({}));
    }, [dispatch]);

    const data = React.useMemo(() => {
        const cloneData = [...lqaModels].sort(
            (a, b) => new Date(b?.audit?.createdDate) - new Date(a?.audit?.createdDate)
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, lqaModels]);

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const handleChangeLQaDefault = React.useCallback(
        newLqa => {
            console.log('body', {
                newLqa,
                oldLqa: lqaModelDefault
            });
            dispatch(
                gridUIActions.setDefaultLqaModel({
                    newLqa,
                    oldLqa: lqaModelDefault,
                    successCallback: () => {
                        console.log('done');
                    },
                    errorCallback: () => {
                        console.log('failed');
                    }
                })
            );
        },
        [dispatch, lqaModelDefault]
    );

    const nameRenderer = React.useCallback(
        ({ rowData }) => {
            const { id, name } = rowData;
            const checked = id === lqaModelDefault?.id;
            return (
                <Grid container justify="space-between" direction="row" alignItems="center">
                    <Grid item>
                        <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item>
                                <MagicWandSVG />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item>
                                <Radio
                                    onChange={() => handleChangeLQaDefault(rowData)}
                                    size="small"
                                    checked={checked}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">Default LQA Model</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        },
        [lqaModelDefault, handleChangeLQaDefault]
    );

    const COLUMNS = [
        {
            label: '',
            dataKey: 'id',
            type: 'collapse',
            width: 40,
            cellRenderer: () => null,
            className: classes.center,
            subRowHeight: 800
        },
        {
            label: 'LQA Model Name',
            dataKey: 'name',
            flexGrow: 1,
            width: 350,
            sort: true,
            cellRenderer: nameRenderer,
            subRowHeight: 800
        }
    ];

    return (
        <SettingContentLayout name={t('lqa_models')}>
            <Grid
                container
                className={classes.root}
                style={{ height: '80vh', width: '100%' }}
                direction="row"
                spacing={3}
            >
                <AutoSizer>
                    {({ width, height }) => (
                        <TableVirtualize
                            data={data}
                            columns={COLUMNS}
                            width={width}
                            height={height}
                            onRowClick={handleRowClick}
                            onSort={handleOnSort}
                            rowExpandedRender={props => <LQAItem {...props} />}
                        ></TableVirtualize>
                    )}
                </AutoSizer>
            </Grid>
        </SettingContentLayout>
    );
}

export default React.memo(LQAPage);
