import React from 'react';
import { Grid } from '@material-ui/core';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useDropzone } from 'react-dropzone';
import { getUploadErrorMessage } from 'utils/upload';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { GRID_UPLOAD_MAX_SIZE, USAGE_PROPERTIES } from 'const';
import * as gridUIActions from 'gridUI/actions';
import { useIsOverThresHoldProperty } from 'hooks/payment/usage';
import PreviewCellSVG from 'assets/images/svg/PreviewCellSVG';
import { useMemo } from 'react';

function FileUploadHandler({
    columnId,
    rowId,
    isReadOnly,
    data,
    isTriggerUpload,
    onReset,
    handleContextMenu,
    height,
    column
}) {
    const dispatch = useDispatch();
    const isOverFileStorage = useIsOverThresHoldProperty(USAGE_PROPERTIES.MAX_STORAGE_GB);
    const uploadFileRef = React.useRef();

    const isFileMultiple = useMemo(() => {
        return column?.file?.selectionType === 'multiple';
    }, [column]);

    React.useEffect(() => {
        if (isTriggerUpload && uploadFileRef.current) {
            uploadFileRef.current.click();
        }
    }, [isTriggerUpload]);

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles);
            const fileError = fileErrors?.[0];

            console.log('fileErrors', fileErrors);
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const onDropAccepted = React.useCallback(
        acceptedFiles => {
            if (isFileMultiple) {
                dispatch(
                    gridUIActions.uploadMultipleFilesForCell({
                        columnId,
                        rowId,
                        uploadFiles: acceptedFiles,
                        successCallback: () => {
                            console.log('upload file successfully');
                            dispatch(gridUIActions.cancelCellEdit());
                        },
                        errorCallback: () => {
                            console.log('Failed to upload file');
                        }
                    })
                );
                return;
            }

            let item = acceptedFiles[0];
            let formData = new FormData();
            formData.append('file', item);
            dispatch(
                gridUIActions.uploadFileForCell({
                    formData,
                    rowId,
                    columnId,
                    successCallback: () => {
                        console.log('upload file successfully');
                        dispatch(gridUIActions.cancelCellEdit());
                    },
                    errorCallback: () => {
                        console.log('Failed to upload file');
                    }
                })
            );
        },
        [columnId, dispatch, rowId, isFileMultiple]
    );

    const { getInputProps, open } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: isFileMultiple,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noClick: true,
        noKeyboard: true
    });

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleOpenFile = React.useCallback(
        file => {
            if (isOverFileStorage) {
                // handleOpenLimitModal();
                return;
            }

            return open();
        },
        [isOverFileStorage, open]
    );

    const handleClickOpen = ({ e, fileMeta }) => {
        stopPropagation(e);
        let body = {
            rowId,
            columnId
        };
        if (fileMeta) {
            body = {
                ...body,
                defaultPreviewFile: fileMeta
            };
        }
        dispatch(gridUIActions.openCellFilePreview(body));
    };

    const previewIcon = () => {
        return (
            <Grid
                id={`upload_${rowId}_${columnId}`}
                style={{
                    position: 'absolute',
                    right: 6,
                    top: height / 2,
                    bottom: 0,
                    margin: 'auto',
                    zIndex: 3,
                    width: 16,
                    height: 16
                }}
                className={'flex justify-center bg-white items-center rounded cursor-pointer'}
                onClick={e => handleClickOpen({ e })}
                onContextMenu={handleContextMenu}
            >
                <PreviewCellSVG />
            </Grid>
        );
    };

    const baseUpload = () => {
        return (
            <Grid
                id={`upload_${rowId}_${columnId}`}
                style={{
                    position: 'absolute',
                    left: 1,
                    top: height / 2,
                    bottom: 0,
                    margin: 'auto',
                    zIndex: 3,
                    width: 20,
                    height: 28,
                    outline: 'none'
                }}
                className={'flex justify-center items-center bg-grey-border hover:bg-grey-ghost rounded-tr rounded-br'}
                onClick={handleOpenFile}
                ref={uploadFileRef}
                onContextMenu={handleContextMenu}
            >
                <input className="focus:outline-none" style={{ outline: 'none' }} {...getInputProps({})} />
                <AddIconSVG className={'w-2.5 h-2.5'} />
            </Grid>
        );
    };

    return (
        <>
            {!isReadOnly && baseUpload()}
            {data?.value?.length > 0 && previewIcon()}
        </>
    );
}

export default React.memo(FileUploadHandler);
