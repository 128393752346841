import React, { useMemo } from 'react';
import { useTheme, fade } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Tooltip from 'components/tooltip/Base';
import { getDependencyLabelByStatus, getSourceStatusLabelByStatus, getSourceStatusIcon } from 'utils/gridUI/dependency';
import SubtractPNG from 'assets/images/patterns/subtract.png';
import { DEPENDENCY_STATUS } from 'const/gridUI';
import { useTranslation } from 'react-i18next';

function LangStatus({ dependencyStatus, dependencyBlindness, sourceStatus }) {
    const theme = useTheme();
    const { t } = useTranslation();

    const getStyle = useMemo(() => {
        switch (dependencyStatus) {
            case DEPENDENCY_STATUS.OUT_OF_DATE:
                return {
                    background:
                        dependencyBlindness === 'BLIND'
                            ? `${theme.colors.white} url(${SubtractPNG}) !important`
                            : fade(theme.colors.sun, 0.8)
                };
            case DEPENDENCY_STATUS.UP_TO_DATE:
                return { background: fade(theme.colors.atlantis, 0.8) };
            case DEPENDENCY_STATUS.UNSET:
                return {
                    background: dependencyBlindness === 'BLIND' ? `` : fade(theme.colors.brightRed, 0.8),
                    border: dependencyBlindness === 'BLIND' ? `1.5px solid ${fade(theme.colors.brightRed, 0.8)}` : ``
                };
            default:
                return {};
        }
    }, [dependencyStatus, dependencyBlindness, theme]);

    return (
        <Tooltip
            placement="bottom"
            title={
                <Grid container direction="column">
                    {sourceStatus && (
                        <Grid item>
                            <Grid container spacing={2} alignItems="center" direction="row" wrap="nowrap">
                                <Grid item>{getSourceStatusIcon({ status: sourceStatus, size: 7 })}</Grid>
                                <Grid item>
                                    <Typography className={'!text-white'} variant="body2">
                                        <strong>Source status: </strong>{' '}
                                        {getSourceStatusLabelByStatus({ status: sourceStatus })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {dependencyStatus && (
                        <Grid item style={{ position: 'relative', left: 2 }}>
                            <Grid container spacing={2} alignItems="center" direction="row" wrap="nowrap">
                                <Grid item>
                                    <div
                                        className={`w-[3px] h-[16px] rounded-none cursor-pointer flex flex-col`}
                                        style={getStyle}
                                    ></div>
                                </Grid>
                                <Grid item>
                                    <Typography className={'!text-white'} variant="body2">
                                        <strong>{t('dependency_status')}:</strong>{' '}
                                        {getDependencyLabelByStatus({ status: dependencyStatus })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            }
        >
            <div className={'flex flex-col items-center'}>
                {sourceStatus && getSourceStatusIcon({ status: sourceStatus, size: 7 })}
                {dependencyStatus && (
                    <div
                        className={`w-[3px] h-[16px] rounded-none cursor-pointer mt-[2px] flex flex-col `}
                        style={getStyle}
                    ></div>
                )}
            </div>
        </Tooltip>
    );
}

export default React.memo(LangStatus);
