import * as types from '../types';
import produce from 'immer';
import { combinedData } from 'utils/gridUI/data';
import { combinedRecordMetaData } from 'utils/gridUI/recordMetadata';

export function revertHandler({ type, body, state }) {
    switch (type) {
        case types.OPTIMISTIC_UPDATE_CELL:
            return optimisticUpdateCellHandler({ state, body });
        case types.OPTIMISTIC_UPDATE_COLUMN:
            return optimisticUpdateColumnHandler({ state, body });
        case types.OPTIMISTIC_RESIZE_COLUMN:
            return optimisticResizeColumnHandler({ state, body });
        case types.OPTIMISTIC_RESIZE_RECORD:
            return optimisticResizeRowHandler({ state, body });
        case types.OPTIMISTIC_DELETE_COLUMN:
            return optimisticDeleteColumnHandler({ state, body });
        case types.OPTIMISTIC_UPDATE_VIEW:
            return optimisticUpdateViewHandler({ state, body });
        case types.OPTIMISTIC_DELETE_VIEW:
            return optimisticDeleteViewHandler({ state, body });
        case types.OPTIMISTIC_DELETE_RANGE_SELECTION:
            return optimisticDeleteRangeSelection({ state, body });
        case types.OPTIMISTIC_UPDATE_VIEW_EDIT_COLUMNS_PERMISSION:
            return optimisticUpdateViewEditColumnsPermissionHandler({ state, body });
        case types.OPTIMISTIC_UPDATE_VIEW_VIEW_COLUMNS_PERMISSION:
            return optimisticUpdateViewViewColumnsPermissionHandler({ state, body });
        case types.OPTIMISTIC_DELETE_RECORD:
            return optimisticDeleteRecordsHandler({ state, body });
        case types.OPTIMISTIC_DELETE_FILE_OF_CELL:
            return optimisticDeleteFileOfCell({ state, body });
        case types.OPTIMISTIC_PASTE:
            return optimisticPaste({ state, body });
        case types.OPTIMISTIC_FILL_CELLS_COLOR:
            return optimisticFillCellColor({ state, body });
        case types.OPTIMISTIC_REORDER_COLUMNS:
            return optimisticReorderColumns({ state, body });
        case types.OPTIMISTIC_REORDER_RECORDS:
            return optimisticReorderRecords({ state, body });
        case types.OPTIMISTIC_CHANGE_FREEZING_INDEX:
            return optimisticChangeFreezingIndex({ state, body });
        case types.OPTIMISTIC_UPDATE_METADATA_TM_STATUS:
            return optimisticUpdateMetadataTMStatus({ state, body });
        case types.OPTIMISTIC_UPDATE_METADATA_DEPENDENCY_STATUS:
        case types.OPTIMISTIC_UPDATE_METADATA_SOURCE_STATUS:
            return optimisticUpdateMetadataDependencyStatus({ state, body });

        case types.OPTIMISTIC_PATH_TAG_DROP_ROW_INTO_TREE:
            return optimisticPathTagDropRowIntoTree({ state, body });
        case types.OPTIMISTIC_ENABLE_COLUMN_IN_VIEW_ORDER:
            return optimisticEnableColumnInViewOrder({ state, body });
        case types.OPTIMISTIC_CHANGE_COLUMN_EDITABLE:
            return optimisticChangeColumnEditable({ state, body });
        case types.OPTIMISTIC_CHANGE_COLUMN_VIEWABLE:
            return optimisticChangeColumnViewable({ state, body });
        case types.OPTIMISTIC_COPY_CELL_RELEASE:
            return optimisticCopyCellRelease({ state, body });
        default:
            return state;
    }
}

function optimisticCopyCellRelease({ state, body }) {
    const { undoData } = body;
    const { data } = state;

    const dataCombined = combinedData({ data, newData: undoData, isCareData: true });
    return {
        ...state,
        data: dataCombined
    };
}

function optimisticChangeColumnEditable({ state, body }) {
    const { oldViewColumns, columnId, editable, viewable } = body;
    const { metaData } = state;
    const newMetaData = produce(metaData, draft => {
        if (!draft?.[columnId]) {
            draft[columnId] = {};
        }

        draft[columnId] = {
            ...draft?.[columnId],
            viewable,
            editable
        };
    });

    return {
        ...state,
        viewColumns: oldViewColumns,
        metaData: newMetaData
    };
}

function optimisticChangeColumnViewable({ state, body }) {
    const { oldViewColumns, columnId, editable, viewable } = body;
    const { metaData } = state;
    const newMetaData = produce(metaData, draft => {
        if (!draft?.[columnId]) {
            draft[columnId] = {};
        }

        draft[columnId] = {
            ...draft?.[columnId],
            viewable,
            editable
        };
    });

    return {
        ...state,
        viewColumns: oldViewColumns,
        metaData: newMetaData
    };
}

function optimisticPathTagDropRowIntoTree({ state, body }) {
    let { oldData } = body;
    const { data } = state;
    const newData = combinedData({ data, newData: oldData });
    return {
        ...state,
        data: newData
    };
}

function optimisticUpdateMetadataDependencyStatus({ state, body }) {
    const { data } = state;
    const { cellsChanged } = body;
    const newData = combinedData({ data, newData: cellsChanged });
    return {
        ...state,
        data: newData
    };
}

function optimisticUpdateMetadataTMStatus({ state, body }) {
    const { data } = state;
    const { cellsChanged } = body;
    const newData = combinedData({ data, newData: cellsChanged });
    return {
        ...state,
        data: newData
    };
}

function optimisticChangeFreezingIndex({ state, body }) {
    const { oldIndex } = body;

    return {
        ...state,
        fixedColumnCount: oldIndex
    };
}

function optimisticResizeColumnHandler({ state, body }) {
    const { viewColumns } = state;
    const { oldColumnWidth, columnId } = body;

    const newViewColumns = viewColumns?.map(viewColumn => {
        if (viewColumn?.id === columnId) {
            viewColumn.customProperties = {
                ...viewColumn?.customProperties,
                width: oldColumnWidth
            };
        }
        return viewColumn;
    });

    return {
        ...state,
        viewColumns: newViewColumns
    };
}

function optimisticResizeRowHandler({ state, body }) {
    const { recordMetaData } = state;
    const { undoRecordMetaData } = body;

    return {
        ...state,
        recordMetaData: combinedRecordMetaData({ recordMetaData, newRecordMetaData: undoRecordMetaData })
    };
}

function optimisticReorderColumns({ state, body }) {
    const { viewColumns } = body;

    return {
        ...state,
        viewColumns
    };
}

function optimisticEnableColumnInViewOrder({ state, body }) {
    const { columnId, viewColumns } = body;
    const { columnsPermission } = state;
    return {
        ...state,
        viewColumns: [...viewColumns],
        columnsPermission: columnsPermission?.filter(col => col?.id !== columnId)
    };
}

function optimisticReorderRecords({ state, body }) {
    const { rows } = body;
    return {
        ...state,
        rows
    };
}

function optimisticFillCellColor({ state, body }) {
    let { colorData } = body;
    const { data } = state;
    return {
        ...state,
        data: combinedData({ data, newData: colorData })
    };
}

function optimisticDeleteFileOfCell({ state, body }) {
    let { rowId, columnId, cellData } = body;
    const { data } = state;
    const newData = combinedData({
        data,
        newData: {
            [rowId]: {
                [columnId]: cellData
            }
        }
    });
    return {
        ...state,
        data: newData
    };
}

function optimisticDeleteRecordsHandler({ state, body }) {
    const { indexes, recordIds } = body;
    const { rows, totalRecords, gridTotalRecords, totalRecordsWithoutFilters } = state;
    let newRows = [...rows];
    for (let i = 0; i < recordIds?.length; i++) {
        let index = indexes[i];
        let recordId = recordIds[i];
        newRows.splice(index, 0, recordId);
    }

    return {
        ...state,
        rows: newRows,
        totalRecords: totalRecords + recordIds?.length,
        totalRecordsWithoutFilters: totalRecordsWithoutFilters + recordIds?.length,
        gridTotalRecords: gridTotalRecords + recordIds?.length
    };
}

function optimisticDeleteRangeSelection({ state, body }) {
    const { oldDeletedData } = body;
    const { data } = state;
    const newData = combinedData({ data, newData: oldDeletedData });
    return {
        ...state,
        data: newData
    };
}

function optimisticPaste({ state, body }) {
    const { oldValuesWasPasted } = body;
    const { data } = state;

    return {
        ...state,
        data: combinedData({ data, newData: oldValuesWasPasted, isCareData: true })
    };
}

function optimisticUpdateViewViewColumnsPermissionHandler({ state, body }) {
    let { columnsPermission } = state;
    const { columnId, oldViewColumns, editable, viewable, oldMetaData } = body;
    let revertColumnPermissions = columnsPermission.map(col => {
        if (columnId === col.id) {
            return {
                ...col,
                viewable,
                editable
            };
        }
        return col;
    });

    return {
        ...state,
        columnsPermission: revertColumnPermissions,
        viewColumns: oldViewColumns,
        metaData: oldMetaData
    };
}

function optimisticUpdateViewEditColumnsPermissionHandler({ state, body }) {
    let { columnsPermission } = state;
    const { columnId, oldViewColumns, editable, oldMetaData } = body;
    let revertColumnPermissions = columnsPermission.map(col => {
        if (columnId === col.id) {
            return {
                ...col,
                editable
            };
        }
        return col;
    });

    return {
        ...state,
        columnsPermission: revertColumnPermissions,
        viewColumns: oldViewColumns,
        metaData: oldMetaData
    };
}

function optimisticDeleteViewHandler({ state, body }) {
    const { viewId } = body;
    const viewsCoppied = [...state.views];
    const found = viewsCoppied.find(view => view.id === viewId);
    if (found) {
        found.isDeleted = false;
    }
    return {
        ...state,
        views: viewsCoppied
    };
}
function optimisticUpdateViewHandler({ state, body }) {
    let { views } = state;
    const { viewId, viewData } = body;
    const undoIndex = viewData?.customProperties?.fixedColumnCount || 0;

    const newViews = views.map(each => {
        if (each.id === viewId) {
            return viewData;
        }
        return each;
    });

    return {
        ...state,
        views: newViews,
        currentView: viewData,
        fixedColumnCount: undoIndex
    };
}

function optimisticUpdateCellHandler({ state, body }) {
    let { data } = state;
    const { columnId, rowId, cellData } = body;

    const newData = combinedData({
        data,
        newData: {
            [rowId]: {
                [columnId]: cellData
            }
        }
    });

    return {
        ...state,
        data: newData
    };
}

function optimisticUpdateColumnHandler({ state, body }) {
    const { metaData, columnsPermission } = state;
    const { columnData, columnId } = body;
    const newMetaData = produce(metaData, draft => {
        if (!draft[columnId]) {
            draft[columnId] = {};
        }
        draft[columnId] = { ...columnData };
        return draft;
    });
    let columnsPermissionCoppied = [...columnsPermission];

    const founded = [...columnsPermissionCoppied].find(colPer => columnId === colPer.id);

    if (founded) {
        founded.name = columnData?.name;
    }
    return {
        ...state,
        metaData: newMetaData,
        columnsPermissionCoppied
    };
}

function optimisticDeleteColumnHandler({ state, body }) {
    const { columns, metaData } = state;
    const { columnIndex, columnId, column, oldViewColumns } = body;
    const newColumns = [...columns.slice(0, columnIndex), columnId, ...columns.slice(columnIndex)];
    const newMetaData = {
        ...metaData,
        [columnId]: column
    };
    return {
        ...state,
        columns: newColumns,
        metaData: newMetaData,
        viewColumns: oldViewColumns
    };
}
