import React, { useState, useEffect } from 'react';
import { Grid, useTheme, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import NormalSelect from 'gridUI/common/normalSelects';
import { useSelector } from 'react-redux';
import { fetchGridBranchesApi, getGridColumnsApi } from 'services/grid';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { DISABLED_REFERENCE_COLUMN_TYPES } from 'const';
import GridIcon from 'grids/GridIcon';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import Tooltip from 'components/tooltip/Base';
import referenceRecordGif from 'assets/images/reference_record.gif';
import referenceCellValueGif from 'assets/images/reference_cell_value.gif';
import { REFERENCE_SELECTION_TYPES } from 'const/gridUI';
import CheckBox from 'components/checkbox/Base';
import { useViewColumnWidthMetadata } from 'hooks/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import BranchSVG from 'assets/images/svg/BranchSVG';
import i18n from 'i18n';

const REFERENCE_TYPES = {
    ROW: 'row',
    CELL: 'cell'
};

function ExtraOptionsWithReference({
    referenceData,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    columnId,
    referenceDataChangeHandler,
    referenceType,
    referenceSelectionType,
    primaryReference,
    t
}) {
    const theme = useTheme();
    const viewColumns = useViewColumnWidthMetadata();

    const options = useSelector(state => {
        let grids = state.grid?.list?.[dbId] || [];
        return grids
            .filter(grid => grid.id !== gridId)
            .map(grid => {
                return {
                    label: grid.name,
                    value: grid.id,
                    icon: () => <GridIcon size="small" grid={grid} />
                };
            });
    });

    const [selectedGrid, setSelectedGrid] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);

    const [columnOptions, setColumnOptions] = useState([]);
    const [branchOptions, setBranchOptions] = useState([]);

    const hasOtherRecordRefColumn = React.useMemo(() => {
        const cols = viewColumns?.filter(col => {
            const type = getCorrectColumnType(col);

            if (col?.id === columnId) return false;
            if (type !== columnTypes.REFERENCE) return false;
            if (!selectedBranch) return false;
            if (type === columnTypes.REFERENCE && col?.referenceSettings?.referencedBranchId !== selectedBranch?.value)
                return false;
            if (col?.referenceType !== REFERENCE_TYPES.ROW) return false;
            return true;
        });

        return cols?.length > 0;
    }, [columnId, selectedBranch, viewColumns]);

    useEffect(() => {
        const { referencedGridId, referencedColumnId, referencedBranchId } = referenceData;
        async function checked() {
            if (referencedGridId) {
                let selectedCol = options.find(option => option.value === referencedGridId);
                setSelectedGrid(selectedCol);

                if (referencedBranchId) {
                    try {
                        const [branches, columns] = await Promise.all([
                            fetchGridBranchesApi({
                                dbId,
                                gridId: referencedGridId,
                                query: {
                                    includeBasedGrid: true
                                }
                            }),
                            getGridColumnsApi({ gridId: referencedBranchId, dbId })
                        ]);
                        const branchOptions = branches
                            ?.filter(branch => !branch.isDeleted)
                            ?.map(option => {
                                return {
                                    ...option,
                                    label: option?.id === referencedGridId ? i18n.t('master_branch') : option.name,
                                    value: option.id,
                                    icon: () => <BranchSVG />
                                };
                            });

                        setBranchOptions(branchOptions);

                        let selectedBranch = branchOptions.find(column => column.value === referencedBranchId);
                        setSelectedBranch(selectedBranch);

                        if (referencedColumnId) {
                            let columnOptions = [
                                {
                                    label: 'Record ID',
                                    value: columnTypes.RECORD_ID,
                                    icon: () => <ColumnIcon type={columnTypes.RECORD_ID} customProperties={{}} />
                                },
                                ...columns
                                    .filter(column => !DISABLED_REFERENCE_COLUMN_TYPES.includes(column?.type))
                                    .map(column => ({
                                        label: column.name,
                                        value: column.id,
                                        icon: () => (
                                            <ColumnIcon
                                                group={column.group}
                                                type={column.type}
                                                customProperties={column.customProperties}
                                            />
                                        )
                                    }))
                            ];
                            setColumnOptions(columnOptions);
                            let selectedCol = columnOptions.find(column => column.value === referencedColumnId);
                            setSelectedColumn(selectedCol);
                        }
                    } catch (error) {
                        console.log('fetch branches, columns error');
                    }
                }
            }
        }
        checked();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendBackParent = React.useCallback(
        ({
            referencedGridId,
            referencedColumnId,
            referencedBranchId,
            referenceType,
            referenceSelectionType,
            primaryReference
        }) => {
            referenceDataChangeHandler({
                referencedBranchId,
                referencedGridId,
                referencedColumnId,
                referenceType,
                referenceSelectionType,
                primaryReference
            });
        },
        [referenceDataChangeHandler]
    );

    const handleGridChange = async gridOption => {
        if (gridOption.value === selectedGrid?.value) return;
        setSelectedColumn(null);
        setSelectedGrid(gridOption);
        let gridId = gridOption.value;

        const [branches, columns] = await Promise.all([
            fetchGridBranchesApi({
                dbId,
                gridId: gridId,
                query: {
                    includeBasedGrid: true
                }
            }),
            getGridColumnsApi({ gridId, dbId })
        ]);

        const branchOptions = branches
            ?.filter(branch => !branch.isDeleted)
            ?.map(option => {
                return {
                    ...option,
                    label: option?.id === gridId ? i18n.t('master_branch') : option.name,
                    value: option.id,
                    icon: () => <BranchSVG />
                };
            });

        setBranchOptions(branchOptions);
        setSelectedBranch(branchOptions?.find(option => option?.value === gridId));
        setColumnOptions([
            {
                label: 'Record ID',
                value: columnTypes.RECORD_ID,
                icon: () => <ColumnIcon type={columnTypes.RECORD_ID} customProperties={{}} />
            },
            ...columns
                .filter(column => !DISABLED_REFERENCE_COLUMN_TYPES.includes(column?.type))
                .map(column => ({
                    label: column.name,
                    value: column.id,
                    icon: () => (
                        <ColumnIcon
                            group={column.group}
                            type={column.type}
                            customProperties={column.customProperties}
                        />
                    )
                }))
        ]);
        sendBackParent({
            referencedBranchId: gridOption?.value,
            referencedGridId: gridOption?.value,
            referencedColumnId: null,
            referenceType,
            referenceSelectionType,
            primaryReference
        });
    };

    const handleBranchChange = React.useCallback(
        async option => {
            if (option.value === (selectedBranch && selectedBranch.value)) return;
            setSelectedColumn(null);
            setSelectedBranch(option);

            let columns = await getGridColumnsApi({ gridId: option?.value, dbId });

            setColumnOptions([
                {
                    label: 'Record ID',
                    value: columnTypes.RECORD_ID,
                    icon: () => <ColumnIcon type={columnTypes.RECORD_ID} customProperties={{}} />
                },
                ...columns
                    .filter(column => !DISABLED_REFERENCE_COLUMN_TYPES.includes(column?.type))
                    .map(column => ({
                        label: column.name,
                        value: column.id,
                        icon: () => (
                            <ColumnIcon
                                group={column.group}
                                type={column.type}
                                customProperties={column.customProperties}
                            />
                        )
                    }))
            ]);

            sendBackParent({
                referencedBranchId: option?.value,
                referencedGridId: selectedGrid?.value,
                referencedColumnId: null,
                referenceType,
                referenceSelectionType,
                primaryReference
            });
        },
        [
            referenceType,
            selectedColumn,
            selectedGrid,
            selectedBranch,
            sendBackParent,
            primaryReference,
            referenceSelectionType
        ]
    );

    const handleColumnChange = React.useCallback(
        columnOption => {
            if (columnOption.value === (selectedColumn && selectedColumn.value)) return;
            setSelectedColumn(columnOption);
            sendBackParent({
                referencedBranchId: selectedBranch?.value,
                referencedGridId: selectedGrid?.value,
                referencedColumnId: columnOption?.value,
                referenceType,
                referenceSelectionType,
                primaryReference
            });
        },
        [
            referenceType,
            selectedColumn,
            selectedGrid,
            selectedBranch,
            sendBackParent,
            primaryReference,
            referenceSelectionType
        ]
    );

    const handleReferenceTypeChange = React.useCallback(
        e => {
            const value = e.target?.value;
            sendBackParent({
                referencedBranchId: selectedBranch?.value,
                referencedGridId: selectedGrid?.value,
                referencedColumnId: selectedColumn?.value,
                referenceType: value,
                referenceSelectionType:
                    value === REFERENCE_TYPES.ROW ? REFERENCE_SELECTION_TYPES.MULTIPLE : referenceSelectionType,
                primaryReference
            });
        },
        [selectedGrid, selectedColumn, sendBackParent, selectedBranch, referenceSelectionType, primaryReference]
    );

    const handleReferenceSelectionTypeChange = React.useCallback(
        e => {
            const value = e.target?.value;
            sendBackParent({
                referencedBranchId: selectedBranch?.value,
                referencedGridId: selectedGrid?.value,
                referencedColumnId: selectedColumn?.value,
                referenceType,
                referenceSelectionType: value,
                primaryReference
            });
        },
        [selectedGrid, selectedColumn, selectedBranch, sendBackParent, referenceType, primaryReference]
    );

    const handlePrimaryReferenceChange = React.useCallback(
        e => {
            const value = e.target?.checked;
            sendBackParent({
                referencedBranchId: selectedBranch?.value,
                referencedGridId: selectedGrid?.value,
                referencedColumnId: selectedColumn?.value,
                referenceType,
                referenceSelectionType,
                primaryReference: value
            });
        },
        [selectedGrid, selectedColumn, selectedBranch, sendBackParent, referenceType, referenceSelectionType]
    );

    return (
        <Grid container direction="column">
            <Grid className={'mb-3.5'} item container direction="column">
                <Grid item>
                    <p className="body1 mb-1">{t('reference_extra_options_reference_grid')}</p>
                </Grid>
                <Grid item>
                    <NormalSelect
                        defaultValue={selectedGrid}
                        options={options}
                        handleOptionChange={handleGridChange}
                        ddPlaceholder={t('select_a_grid')}
                        menuPlaceholder="Find a grid"
                    />
                </Grid>
            </Grid>

            <Grid className={'mb-3.5'} item container direction="column">
                <Grid item>
                    <p className="body1 mb-1">{t('reference_extra_options_reference_branch')}</p>
                </Grid>
                <Grid item>
                    <NormalSelect
                        defaultValue={selectedBranch}
                        options={branchOptions}
                        handleOptionChange={handleBranchChange}
                        ddPlaceholder="Select a column"
                        menuPlaceholder={t('select_branch')}
                    />
                </Grid>
            </Grid>

            <Grid className={'mb-3.5'} item container direction="column">
                <Grid item>
                    <p className="body1 mb-1">{t('reference_extra_options_reference_column')}</p>
                </Grid>
                <Grid item>
                    <NormalSelect
                        defaultValue={selectedColumn}
                        options={columnOptions}
                        handleOptionChange={handleColumnChange}
                        ddPlaceholder="Select a column"
                        menuPlaceholder={t('global_label_find_a_column')}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="column">
                <Grid item>
                    <p className="body1">{t('reference_extra_options_reference_type')}</p>
                </Grid>
                <Grid item>
                    <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        row
                        value={referenceType}
                        onChange={handleReferenceTypeChange}
                    >
                        <Grid container direction="column">
                            <Grid item container direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        value={REFERENCE_TYPES.ROW}
                                        control={<Radio size="small" />}
                                        label={
                                            <p className="body2 !font-normal">
                                                {t('reference_extra_options_reference_type_record')}
                                            </p>
                                        }
                                    />
                                </Grid>
                                <Tooltip
                                    title={
                                        <Grid
                                            container
                                            className={'w-[255px] rounded px-2 py-3.5'}
                                            alignItems={'flex-start'}
                                            direction="column"
                                        >
                                            <Grid item>
                                                <img
                                                    className={'w-full h-full rounded mb-2'}
                                                    src={referenceRecordGif}
                                                    alt="reference gif"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="text-light font-medium">{t('reference_record_title')}</p>
                                            </Grid>
                                            <Grid item>
                                                <p className="caption">{t('reference_record_tutorial_text')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                >
                                    <Grid item className={'flex'}>
                                        <QuestionSVG />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                            {referenceType === REFERENCE_TYPES.ROW && (
                                <Grid item>
                                    <FormControlLabel
                                        style={{
                                            marginLeft: theme.spacing(3),
                                            pointerEvents:
                                                !hasOtherRecordRefColumn && primaryReference ? 'none' : 'auto'
                                        }}
                                        control={
                                            <CheckBox
                                                onChange={handlePrimaryReferenceChange}
                                                checked={primaryReference}
                                                size="small"
                                            />
                                        }
                                        label={<p className="body2 !font-normal">Set as primary column</p>}
                                    />
                                </Grid>
                            )}
                            <Grid item container direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        value={REFERENCE_TYPES.CELL}
                                        control={<Radio size="small" />}
                                        label={
                                            <p className="body2 !font-normal">
                                                {t('reference_extra_options_reference_type_cell')}
                                            </p>
                                        }
                                    />
                                </Grid>
                                <Tooltip
                                    title={
                                        <Grid
                                            container
                                            className={'w-[255px] rounded px-2 py-3.5'}
                                            alignItems={'flex-start'}
                                            direction="column"
                                        >
                                            <Grid item>
                                                <img
                                                    className={'w-full h-full rounded mb-2'}
                                                    src={referenceCellValueGif}
                                                    alt="reference gif"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="text-light font-medium">
                                                    {t('reference_cell_value_title')}
                                                </p>
                                            </Grid>
                                            <Grid item>
                                                <p className="caption">{t('reference_cell_value_tutorial_text')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                >
                                    <Grid item className={'flex'}>
                                        <QuestionSVG />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>

                <Grid item>
                    <p className="body1">{t('reference_extra_options_reference_selection_type')}</p>
                </Grid>
                <Grid item>
                    <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        row
                        value={referenceSelectionType}
                        onChange={handleReferenceSelectionTypeChange}
                    >
                        <Grid container direction="column">
                            <Grid item container direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        value={REFERENCE_SELECTION_TYPES.MULTIPLE}
                                        control={<Radio size="small" />}
                                        label={
                                            <p className="body2 !font-normal">
                                                {t('reference_extra_options_reference_selection_type_multiple')}
                                            </p>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        value={REFERENCE_SELECTION_TYPES.SINGLE}
                                        control={<Radio size="small" />}
                                        label={
                                            <p className="body2 !font-normal">
                                                {t('reference_extra_options_reference_selection_type_single')}
                                            </p>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ExtraOptionsWithReference);
