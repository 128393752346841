import request from './request';
import { encodeURL } from 'utils/http';
import {
    APP_API_PREFIX_GRID_UI,
    APP_API_PREFIX_DATABASE_SERVICE,
    APP_API_PREFIX_ORGANIZATION,
    APP_API_PREFIX_EVENT_LOG
} from '../config';
import { formatRequest } from 'utils/gridUI/formatData';
import isEmpty from 'lodash/isEmpty';
import { objectToQueryParams } from 'utils/urlQueryUtils';
import { downloadFileFromResponse } from 'utils/fileUtils';
import { getRegionBaseUrl } from 'utils/workspace';

const MAX_GRID_SIZE = 500;
const gridApis = {
    grids: dbId => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids`,
    branches: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/branches`,
    grid: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}`,
    mergeGrid: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/merge`,
    gridColumns: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/columns`,
    gridColumn: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/columns/${columnId}`,
    gridRecords: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/records`,
    gridDiffRecords: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/diff-check`,

    createGridRecords: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/records`,
    updateGridColumn: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/columns/${columnId}`,
    deleteGridColumn: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/columns/${columnId}`,
    createGrid: dbId => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids`,
    createGridBranch: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/branch`,
    createGridByImportFile: dbId => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/upload`,
    uploadFileToColumn: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/columns/${columnId}/files`,
    uploadFileForCell: ({ dbId, gridId, columnId, rowId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v2/databases/${dbId}/grids/${gridId}/records/${rowId}/columns/${columnId}/files`,
    deleteFileOfCell: ({ fileId, dbId, gridId, recordId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/records/${recordId}/columns/${columnId}/files/${fileId}`,
    createGridByIntegrationClouds: dbId => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/url-upload`,
    updateGrid: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}`,
    createLocalizationGrid: ({ dbId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/localizations`,
    doBackupOrRestore: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/grids/${gridId}/tasks`,
    getBackupOrRestoreStatus: ({ dbId, gridId, taskId }) =>
        `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/grids/${gridId}/tasks/${taskId}`,
    approveTMStatus: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/localizations/${gridId}/columns/${columnId}/approve`,
    markDependencyStatus: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/localizations/${gridId}/columns/${columnId}/markDependencyStatus`,
    markSourceStatus: ({ dbId, gridId, columnId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/localizations/${gridId}/columns/${columnId}/markSourceStatus`,
    exportTranslation: () => `${APP_API_PREFIX_GRID_UI}/api/v1/translations/export`,
    importTranslation: () => `${APP_API_PREFIX_GRID_UI}/api/v1/translations/import`,
    previewImportTranslations: () => `${APP_API_PREFIX_GRID_UI}/api/v1/translations/databases`,
    getPreviewImportTranslationsTaskId: () => `${APP_API_PREFIX_GRID_UI}/api/v1/translations/databases`,
    reorderRecord: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/records/reorder`,
    duplicateGrid: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/duplicate`,
    publishAllColumnsToProvider: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/publish`,
    publishSelectedColumnsToProvider: viewId => `${APP_API_PREFIX_GRID_UI}/api/v1/views/${viewId}/publish`,
    createGridByImportApi: ({ dbId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/key-upload`,
    createJsonLocalizationGrid: ({ dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/translation-upload`,
    importJSONLocalizationGrid: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/translation-import`,
    translationExportGridType: ({ dbId, viewId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/views/${viewId}/translation-export`,
    createPublicGrid: `${APP_API_PREFIX_GRID_UI}/v1/grids`,
    templateGrids: `${APP_API_PREFIX_GRID_UI}/api/v1/template-grids`,
    templateGrid: templateId => `${APP_API_PREFIX_GRID_UI}/api/v1/template-grids/${templateId}`,
    createTemplateGrid: `${APP_API_PREFIX_GRID_UI}/api/v1/template-grids`,
    gridTotalRecords: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/statistic`,
    validateFormula: ({ gridId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/formulas/validate`,
    getGrid: ({ companyId, dbId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/grid-views?type=access&dbId=${dbId}`,
    gridLastView: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/databases/${dbId}/grids/${gridId}/lastView`,
    favoriteGrid: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/favorites?scope=grid`,
    favoriteGridEdit: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/favorites/edit`,
    affectedReferences: ({ dbId, gridId, backupId }) =>
        `${APP_API_PREFIX_DATABASE_SERVICE}/api/v1/databases/${dbId}/grids/${gridId}/backup-files/${backupId}/affected-references`,
    exportGridHistory: `${APP_API_PREFIX_EVENT_LOG}/api/v1/grid-histories/export`
};

export async function importLocalizationGridApi({ file, dbId, viewId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(`${gridApis.importTranslation()}?dbId=${dbId}&viewId=${viewId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function importPreviewLocalizationGridApi({ file, dbId, viewId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(
        `${gridApis.previewImportTranslations()}/${dbId}/views/${viewId}/preview`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return response.data;
}

export async function importMergeDiffLocalizationGridApi({ dbId, viewId, taskId, body }) {
    const response = await request.post(
        `${gridApis.previewImportTranslations()}/${dbId}/views/${viewId}/preview-translations/${taskId}/merge`,
        body
    );
    return response.data;
}

export async function getPreviewImportTranslationsApi({ dbId, viewId, resourceKey }) {
    const response = await request.post(
        `${gridApis.getPreviewImportTranslationsTaskId()}/${dbId}/views/${viewId}/preview-translations?resourceKey=${resourceKey}`
    );
    return response.data;
}

export async function getPreviewImportTranslationsTaskIdApi({ dbId, viewId, taskId, offset, limit }) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}`;

    const response = await request.get(
        `${gridApis.getPreviewImportTranslationsTaskId()}/${dbId}/views/${viewId}/preview-translations/${taskId}${extraUrl}`
    );
    return {
        records: response.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0)
    };
}

export async function getAggreatesImportLocalizeApi({
    taskId,
    viewId,
    dbId,
    columnIds = [],
    mergeColumnOptions,
    mergeRecordOptions,
    query,
    aggregateType = 'conflicted',
    showAllConflicts = false
}) {
    let extraUrl = `&aggregateType=${aggregateType}`;

    if (columnIds?.length) {
        extraUrl += `&columnIds=${columnIds}`;
    }

    if (showAllConflicts) {
        extraUrl += `&showAllConflicts=${showAllConflicts}`;
    }

    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (mergeColumnOptions?.length) {
        extraUrl += `&mergeColumnOptions=${mergeColumnOptions}`;
    }

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.get(
        `${gridApis.getPreviewImportTranslationsTaskId()}/${dbId}/views/${viewId}/preview-translations/${taskId}/aggregates${extraUrl}`
    );
    return response.data;
}

export async function exportLocalizationApi(queryParams) {
    const { query, sort, ...rest } = queryParams;
    let url = `${gridApis.exportTranslation()}?${objectToQueryParams(rest, true)}`;

    if (!isEmpty(query)) {
        const object = { query };
        url += `&${objectToQueryParams(object)}`;
    }
    if (!isEmpty(sort)) {
        const object = { sort };
        url += `&${objectToQueryParams(object)}`;
    }

    const linkUrl = `${getRegionBaseUrl()}${url}`;
    window.location.href = linkUrl;
}

export async function exportTranslationApi({ query, dbId, viewId }) {
    let url = `${gridApis.translationExportGridType({ dbId, viewId })}`;

    if (!isEmpty(query)) {
        const extra = objectToQueryParams(query, true);
        url += `?${extra}`;
    }

    const response = await request.get(url, {
        responseType: 'blob'
    });
    downloadFileFromResponse(response);
}

export async function fetchGridDetailApi({ dbId, gridId }) {
    const response = await request.get(`${gridApis.grid({ dbId, gridId })}`);
    return response.data;
}

export async function fetchGridBranchesApi({ dbId, gridId, query }) {
    let url = gridApis.branches({ dbId, gridId });

    if (!isEmpty(query)) {
        const extra = objectToQueryParams(query);
        url += `?${extra}`;
    }

    const response = await request.get(url);
    return response.data;
}

export async function createGridBranchApi({ dbId, gridId, body }) {
    const response = await request.post(gridApis.createGridBranch({ dbId, gridId }), body);
    return response.data;
}

export async function approveTMStatusApi({ dbId, gridId, columnId, query, viewId }) {
    let extraUrl = `${gridApis.approveTMStatus({ dbId, gridId, columnId })}?viewId=${viewId}`;

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.put(extraUrl);
    return response.data;
}

export async function markDependencyStatusApi({ dbId, gridId, columnId, status, viewId, query }) {
    let extraUrl = `${gridApis.markDependencyStatus({
        dbId,
        gridId,
        columnId
    })}?viewId=${viewId}&dependencyStatus=${status}`;

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.put(`${extraUrl}`);
    return response.data;
}

export async function markSourceStatusApi({ dbId, gridId, columnId, status, viewId, query }) {
    let extraUrl = `${gridApis.markSourceStatus({
        dbId,
        gridId,
        columnId
    })}?viewId=${viewId}&sourceStatus=${status}`;

    if (!isEmpty(query)) {
        const object = { query };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    const response = await request.put(`${extraUrl}`);
    return response.data;
}

export async function createLocalizationGridByImportApi({ dbId, body, resourceKey }) {
    const formData = new FormData();
    formData.append('importRequest', JSON.stringify(body));

    const response = await request.post(
        `${gridApis.createLocalizationGrid({ dbId })}/upload?key=${resourceKey}`,
        formData
    );
    return response.data;
}

export async function createGridByImportApi({ dbId, body, resourceKey }) {
    const formData = new FormData();
    formData.append('uploadRequest', JSON.stringify(body));

    const response = await request.post(`${gridApis.createGridByImportApi({ dbId })}?key=${resourceKey}`, formData);
    return response.data;
}

export async function createLocalizationGridApi({ dbId, body }) {
    const response = await request.post(gridApis.createLocalizationGrid({ dbId }), body);
    return response.data;
}

export async function createJsonLocalizationGridApi({ dbId, body, query }) {
    let url = gridApis.createJsonLocalizationGrid({ dbId });
    if (!isEmpty(query)) {
        const extra = objectToQueryParams(query);
        url += `?${extra}`;
    }
    const response = await request.post(url, body);
    return response.data;
}

export async function importJSONLocalizationGridApi({ dbId, viewId, body }) {
    const response = await request.post(gridApis.importJSONLocalizationGrid({ dbId, viewId }), body);
    return response.data;
}

export async function getGridsApi({ dbId }) {
    const params = {
        offset: 0,
        limit: MAX_GRID_SIZE
    };
    const pageParams = encodeURL(params);
    const sort = { createdTime: 'desc' };
    const sortParams = encodeURL(sort);
    let extraUrl = `?page=${pageParams}&sort=${sortParams}`;

    const response = await request.get(`${gridApis.createGrid(dbId)}${extraUrl}`);
    return response.data;
}

export async function createGridApi({ dbId, grid, recordIdentifierType, globalUniquePublicRecordId }) {
    const response = await request.post(gridApis.createGrid(dbId), {
        ...grid,
        recordIdentifierType,
        globalUniquePublicRecordId
    });
    return response.data;
}

export async function deleteGridApi({ dbId, gridId }) {
    const response = await request.delete(gridApis.grid({ dbId, gridId }));
    return response.data;
}

export async function updateGridApi({ dbId, gridId, newGrid }) {
    const response = await request.put(gridApis.updateGrid({ dbId, gridId }), newGrid);
    return response.data;
}

export async function getGridColumnsApi({ gridId, dbId }) {
    const response = await request.get(gridApis.gridColumns({ dbId, gridId }));
    return response.data;
}

export async function getGridColumnApi({ gridId, dbId, columnId }) {
    const response = await request.get(gridApis.gridColumn({ dbId, gridId, columnId }));
    return response.data;
}

export async function createGridColumnApi({ gridId, dbId, column }) {
    const response = await request.post(gridApis.gridColumns({ dbId, gridId }), column);
    return response.data;
}

export async function updateGridColumnApi({ gridId, dbId, column }) {
    let newColumn = formatRequest({
        body: column,
        banProperties: ['id']
    });
    const response = await request.put(gridApis.updateGridColumn({ dbId, gridId, columnId: column.id }), newColumn);
    return response.data;
}

export async function deleteGridColumnApi({ gridId, dbId, columnId }) {
    const response = await request.delete(gridApis.deleteGridColumn({ dbId, gridId, columnId }));
    return response.data;
}

export async function get1GridColumnRecordsApi({ dbId, gridId, offset, limit, columnIds, filterValue, groupBy }) {
    const params = {
        offset: offset,
        limit: limit - offset
    };
    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}`;
    extraUrl += `&columnIds=${columnIds}`;

    if (!isEmpty(filterValue)) {
        const object = { query: filterValue };
        extraUrl += `&${objectToQueryParams(object)}`;
    }

    if (!isEmpty(groupBy)) {
        extraUrl += `&groupBy=${groupBy}`;
    }
    const response = await request.get(`${gridApis.gridRecords({ dbId, gridId })}${extraUrl}`);
    return response.data;
}

export async function getGridRecordsDiffCheckApi({
    parentGridId,
    gridId,
    dbId,
    offset,
    limit,
    columnIds = [],
    mergeColumnOptions,
    mergeRecordOptions
}) {
    const params = {
        offset: offset,
        limit: limit - offset
    };

    const pageParams = encodeURL(params);
    let extraUrl = `?page=${pageParams}&parentGridId=${parentGridId}`;
    if (columnIds.length) {
        extraUrl += `&columnIds=${columnIds}`;
    }

    if (mergeRecordOptions?.length) {
        extraUrl += `&mergeRecordOptions=${mergeRecordOptions}`;
    }

    if (mergeColumnOptions?.length) {
        extraUrl += `&mergeColumnOptions=${mergeColumnOptions}`;
    }

    const response = await request.get(`${gridApis.gridDiffRecords({ dbId, gridId })}${extraUrl}`);
    return {
        records: response.data,
        totalRecords: Number(response?.headers?.['x-total-count'] || 0),
        totalRecordsWithoutFilters: Number(response?.headers?.['x-total-sum-count'] || 0)
    };
}

export async function createGridRecordApi({ dbId, gridId, body }) {
    const response = await request.post(`${gridApis.createGridRecords({ dbId, gridId })}`, body);
    return response.data;
}

export async function updateGridRecordApi({ dbId, gridId, body }) {
    const response = await request.put(`${gridApis.createGridRecords({ dbId, gridId })}`, body);
    return response.data;
}
export async function createGridByImportFileApi({ dbId, formData }) {
    const response = await request.post(gridApis.createGridByImportFile(dbId), formData, {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: function(progressEvent) {
            console.log('progressEvent', progressEvent);
        }
    });
    return response.data;
}

export async function createGridByIntegrationCloudsApi({ dbId, url }) {
    let extraUrl = `?url=${encodeURL(url)}`;
    const response = await request.post(`${gridApis.createGridByIntegrationClouds(dbId)}${extraUrl}`);
    return response.data;
}

export async function uploadFileToColumnApi({ dbId, gridId, columnId, formData }) {
    const response = await request.post(gridApis.uploadFileToColumn({ dbId, gridId, columnId }), formData, {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: function(progressEvent) {
            console.log('progressEvent', progressEvent);
        }
    });
    return response.data;
}

export async function uploadFileForCellApi({ dbId, columnId, rowId, gridId, formData }) {
    const response = await request.post(gridApis.uploadFileForCell({ dbId, columnId, rowId, gridId }), formData, {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: function(progressEvent) {
            console.log('progressEvent', progressEvent);
        }
    });
    return response.data;
}

export async function deleteFileOfCellApi({ fileId, dbId, gridId, recordId, columnId }) {
    const response = await request.delete(gridApis.deleteFileOfCell({ fileId, recordId, dbId, gridId, columnId }));
    return response.data;
}

export async function restoreGridApi({ dbId, gridId, body }) {
    const response = await request.post(gridApis.doBackupOrRestore({ dbId, gridId }), body);
    return response.data;
}

export async function mergeGridApi({ dbId, gridId, body }) {
    const response = await request.post(gridApis.mergeGrid({ dbId, gridId }), body);
    return response.data;
}

export async function reorderRecordsApi({ dbId, gridId, body }) {
    const response = await request.post(gridApis.reorderRecord({ dbId, gridId }), body);
    return response.data;
}

export async function duplicateGridApi({ dbId, gridId, body }) {
    const response = await request.post(gridApis.duplicateGrid({ dbId, gridId }), body);
    return response.data;
}

export async function publishAllColumnsToProviderApi({ dbId, viewId }) {
    const response = await request.post(gridApis.publishAllColumnsToProvider({ dbId, viewId }) + '?provider=MemSource');
    return response.data;
}

export async function publishSelectedColumnsToProviderApi({ viewId, body }) {
    const response = await request.post(gridApis.publishSelectedColumnsToProvider(viewId), body);
    return response.data;
}

export async function createGridTemplateApi({ body }) {
    const url = `${gridApis.templateGrids}`;
    const response = await request.post(url, body);
    return response.data;
}

export async function getGridTemplatesApi() {
    const url = `${gridApis.templateGrids}`;
    const response = await request.get(url);
    return response.data;
}

export async function updateGridTemplateApi({ templateId, body }) {
    const url = `${gridApis.templateGrid(templateId)}`;
    const response = await request.patch(url, body);
    return response.data;
}

export async function deleteGridTemplateApi({ templateId, body }) {
    const url = `${gridApis.templateGrid(templateId)}`;
    const response = await request.delete(url);
    return response.data;
}

export async function getGridTotalRecordApi({ dbId, gridId }) {
    const url = `${gridApis.gridTotalRecords({ dbId, gridId })}`;
    const response = await request.get(url);
    return response.data;
}

export async function validateFormulaApi({ gridId, body, dbId }) {
    const url = `${gridApis.validateFormula({ gridId, dbId })}`;
    const response = await request.post(url, body);
    return response.data;
}

export async function getGridApi({ companyId, dbId }) {
    const response = await request.get(gridApis.getGrid({ companyId, dbId }));
    return response.data;
}

export async function getGridLastViewApi({ dbId, gridId }) {
    const url = `${gridApis.gridLastView({ dbId, gridId })}`;
    const response = await request.get(url);
    return response.data;
}

export async function getListGridFavorite({ companyId }) {
    const response = await request.get(`${gridApis.favoriteGrid({ companyId })}`);
    return response.data;
}

export async function hanlderFavoriteGridApi({ companyId, body }) {
    const response = await request.post(`${gridApis.favoriteGridEdit({ companyId })}`, body);
    return response.data;
}

export async function getAffectedReferencesApi({ dbId, gridId, backupId }) {
    const response = await request.get(gridApis.affectedReferences({ dbId, gridId, backupId }));
    return response.data;
}

export async function exportGridHistoryApi(body) {
    const response = await request.post(gridApis.exportGridHistory, body, {
        responseType: 'blob'
    });
    downloadFileFromResponse(response);
}
