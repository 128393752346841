import React from 'react';

function PauseSVG({ color = 'white', ...other }) {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.2 0C0.537258 0 0 0.537258 0 1.2V6.8C0 7.46274 0.537258 8 1.2 8H1.8C2.46274 8 3 7.46274 3 6.8V1.2C3 0.537258 2.46274 0 1.8 0H1.2ZM6.2 0C5.53726 0 5 0.537258 5 1.2V6.8C5 7.46274 5.53726 8 6.2 8H6.8C7.46274 8 8 7.46274 8 6.8V1.2C8 0.537258 7.46274 0 6.8 0H6.2Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(PauseSVG);
