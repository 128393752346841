import { useSelector } from 'react-redux';
import { isSelecting } from 'utils/gridUI/range';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { DEFAULT_COLUMN_WIDTH, GLOBAL_SEARCH_TYPES, PREVIEW_IMAGE_TYPES, SOURCE_STATUS } from 'const/gridUI';
import * as columnTypes from 'const/columnTypes';
import { mapMetaData } from 'utils/gridUI/advanced';
import { between } from 'utils/usage';
import { getCombinedColumnId } from 'utils/gridUI/column';
import { ADVANCED_SEARCH_STATUS } from 'const';

export function useColumns() {
    return useSelector(state => state.advanced.columns);
}

export function useColumnIds() {
    return useSelector(state => state.advanced.columnIds);
}

export function useSelectedColumns() {
    return useSelector(state => state.advanced.selectedColumns);
}

export function useGridColumns() {
    const columnIds = useColumnIds();
    return [columnTypes.RESOURCE, ...columnIds];
}

export function useLastId() {
    return useSelector(state => state.advanced.lastId);
}

export function useAdvancedSearchId() {
    return useSelector(state => state.advanced.advancedSearchId);
}

export function useSelectedAdvancedSearchId() {
    return useSelector(state => state.advanced.selectedAdvancedSearchId);
}

export function useRecordIds() {
    return useSelector(state => state.advanced.recordIds);
}

export function useData() {
    return useSelector(state => state.advanced.data);
}

export function useTotalRecords() {
    return useSelector(state => state.advanced.totalRecords);
}

export function useColumnStopIndex() {
    return useSelector(state => state.advanced.columnStopIndex);
}

export function useColumnStartIndex() {
    return useSelector(state => state.advanced.columnStartIndex);
}

export function useRowStopIndex() {
    return useSelector(state => state.advanced.rowStopIndex);
}

export function useRowStartIndex() {
    return useSelector(state => state.advanced.rowStartIndex);
}

export function useFixedColumnCount() {
    return useSelector(state => state.advanced.fixedColumnCount);
}

export function useRowHeight() {
    return useSelector(state => state.advanced.rowHeight);
}

export function useIsOpenCellEdit() {
    return useSelector(state => state.advanced.isOpenCellEdit);
}

export function useTableInfo() {
    return useSelector(state => state.advanced.tableInfo);
}

export function useCellCopySelection() {
    return useSelector(state => state.advanced.cellCopySelection);
}

export function useHoldEvent() {
    return useSelector(state => state.advanced.holdEvent);
}

export function useAdvancedSearch() {
    return useSelector(state => state.advanced.advancedSearch);
}

export function useAdvancedSearchSnapshot() {
    return useSelector(state => state.advanced.advancedSearchSnapshot);
}

export function useIsOpenConfirmation() {
    return useSelector(state => state.advanced.isOpenConfirmation);
}

export function useNextAdvancedSearchId() {
    return useSelector(state => state.advanced.nextAdvancedSearchId);
}

export function useCurrentAdvancedSearchId() {
    return useSelector(state => state.advanced.advancedSearch?.advancedSearchId);
}

export function useIsFetching() {
    return useSelector(state => state.advanced.isFetching);
}

export function useIsFetchingMore() {
    return useSelector(state => state.advanced.isFetchingMore);
}

export function useAdvancedSearchSelectedColumns() {
    return useSelector(state => state.advanced.advancedSearch?.columns);
}

export function useMetaData() {
    const columns = useAdvancedSearchSelectedColumns();

    return mapMetaData(columns);
}

export function useColumnDetailByColumnId(columnId) {
    const metaData = useMetaData();
    return metaData?.[columnId];
}

export function useViewColumnsWithReOrder() {
    const columnIds = useGridColumns();
    const columnWidthStore = useColumnWidthStore();

    return columnIds?.map(colId => ({
        id: colId,
        customProperties: {
            width: columnWidthStore?.[colId] || DEFAULT_COLUMN_WIDTH
        },
        viewable: true,
        editable: true
    }));
}

export function useSelectedCellData() {
    const data = useData();
    const roles = useRole();
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const columnIds = useGridColumns();
    const recordIds = useRecordIds();
    const rowStartIndex = useRowStartIndex();
    const rowStopIndex = useRowStopIndex();
    const columnStartIndex = useColumnStartIndex();
    const columnStopIndex = useColumnStopIndex();
    const metaData = useMetaData();
    const height = useRowHeight();

    if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex })) {
        return null;
    }

    if (rowStartIndex !== rowStopIndex && columnStartIndex !== columnStopIndex) return null;

    const rowId = recordIds?.[rowStartIndex];
    const columnId = columnIds?.[columnStartIndex];

    if (!rowId || !columnId) return null;
    const cellData = data?.[rowId]?.[columnId];
    const gridColumn = metaData?.[columnId];
    const isReadOnly =
        accessEditRecords !== roleConst.FULL || gridColumn?.type === columnTypes.RESOURCE || !cellData?.editable;

    const resourceCellData = data?.[rowId]?.[columnTypes.RESOURCE];

    const actualColumn =
        gridColumn?.metadata?.[getCombinedColumnId({ ...resourceCellData, columnId: cellData?.columnId })];

    return {
        ...cellData,
        value: cellData?.value,
        columnId,
        actualColumnId: actualColumn?.columnId,
        actualRowId: resourceCellData?.recordId,
        dbId: resourceCellData?.dbId,
        rowId,
        data: cellData,
        isReadOnly,
        width: DEFAULT_COLUMN_WIDTH,
        column: gridColumn,
        tm: data?._tm,
        rowIndex: rowStartIndex,
        columnIndex: columnStartIndex,
        originalValue: cellData?.value || null,
        height
    };
}

export function useQuickFilters() {
    return useSelector(state => state.advanced.quickFilters);
}

export function useQuickSorts() {
    return useSelector(state => state.advanced.quickSorts);
}

export function useDoingActionIds() {
    return useSelector(state => state.advanced.doingActionIds);
}

export function useList() {
    return useSelector(state => state.advanced.list);
}

export function useAdvancedSharedList() {
    return useSelector(state => state.advanced.sharedList);
}

export function useIsFetchingList() {
    return useSelector(state => {
        return state.advanced.isFetchingList;
    });
}

export function useAdvancedInfoById(id) {
    return useSelector(state => {
        const listFound = state.advanced.list?.find(ad => ad?.advancedSearchId === id);
        const shareFound = state.advanced.sharedList?.find(ad => ad?.advancedSearchId === id);
        return listFound || shareFound;
    });
}

export function useAdvancedSearchCreatedTimeAtById(id) {
    return useSelector(state => {
        const isShareViewLink = state?.app.isShareViewLink;

        if (isShareViewLink) return state?.advanced?.advancedSearch?.createdTime;
        const listFound = state.advanced.list?.find(ad => ad?.advancedSearchId === id);
        const shareFound = state.advanced.sharedList?.find(ad => ad?.advancedSearchId === id);
        return listFound?.createdTime || shareFound?.createdTime;
    });
}

export function useSelectedAdvancedSearchById() {
    const selectedAdvancedSearchId = useSelectedAdvancedSearchId();
    return useAdvancedInfoById(selectedAdvancedSearchId);
}

export function useViewFilters() {
    return useSelector(state => state.advanced.viewFilters);
}

export function useViewSorts() {
    return useSelector(state => state.advanced.viewSorts);
}

export function useRouteInfo() {
    return useSelector(state => state.advanced.routeInfo);
}

export function useExportReviewData(advancedSearchId) {
    return useSelector(state => state.advanced.exportPreview?.[advancedSearchId]?.data || {});
}

export function useExportReviewRecordIds(advancedSearchId) {
    return useSelector(state => state.advanced.exportPreview?.[advancedSearchId]?.recordIds || []);
}

export function useExportRemovedColumns() {
    return useSelector(state => state.advanced.exportRemovedColumns) || [];
}

export function useIsSkeleton({ rowIndex }) {
    return useSelector(state => {
        const advanced = state?.advanced;
        const { isFetchingMore, isDeletingRecords, ROW_STOP_INDEX, ROW_START_INDEX } = advanced;
        return (rowIndex < ROW_START_INDEX || rowIndex > ROW_STOP_INDEX) && isFetchingMore && !isDeletingRecords;
    });
}

export function useIsSelectedByRowOrColumn({ columnId, rowIndex }) {
    return useSelector(state => {
        const { advanced } = state;
        const { columnsSelected, rowsRangeIndexes } = advanced;
        return (
            rowsRangeIndexes?.some(range => {
                const start = range?.[0];
                const end = range?.[1] || start;
                return rowIndex >= start && rowIndex <= end;
            }) || columnsSelected.includes(columnId)
        );
    });
}

export function useIsColumnInRange({ columnId, columnIndex }) {
    return useSelector(state => {
        const { advanced } = state;
        const { columnStartIndex, columnStopIndex, columnsSelected } = advanced;
        return (
            columnsSelected.includes(columnId) ||
            between(
                columnIndex,
                Math.min(columnStartIndex, columnStopIndex),
                Math.max(columnStartIndex, columnStopIndex)
            )
        );
    });
}

export function useCellData({ rowId, columnId }) {
    return useSelector(state => {
        const advanced = state?.advanced;
        const { data } = advanced;
        return data?.[rowId]?.[columnId];
    });
}

export function useIsCellReadyOnly({ rowId, columnId }) {
    const roles = useRole();
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const disabledColumnIdsByType = [];
    const referenceDisabledColumns = [];

    return useSelector(state => {
        const advanced = state?.advanced;

        const app = state?.app;
        const {
            metaData,
            disabledColumns,
            disabledSourceColumns,
            processingColumns,
            dependencies = [],
            data
        } = advanced;
        const column = { ...metaData?.[columnId], editable: true };
        const childDependency = dependencies?.find(dpDc => dpDc?.child === columnId);

        const parentColumnId = childDependency?.parent;
        const parentCellData = data?.[rowId]?.[parentColumnId];
        const isParentDisabledStatus = [
            SOURCE_STATUS.DO_NOT_TRANSLATE,
            SOURCE_STATUS.NOT_READY_FOR_TRANSLATION
        ]?.includes(parentCellData?._sourceStatus);
        const cellData = data?.[rowId]?.[columnId];

        const isStatusLocked = cellData?._sourceStatus === SOURCE_STATUS.LOCKED;
        const isCellReadOnly = app?.isShareViewLink || isStatusLocked || isParentDisabledStatus;

        const isDisabled = [
            ...disabledColumns,
            ...disabledSourceColumns,
            ...disabledColumnIdsByType,
            ...referenceDisabledColumns
        ]?.includes(columnId);
        const isProcessing = processingColumns?.includes(columnId);

        const isEditable = column && column.editable && !isDisabled && !isProcessing && cellData?.editable;

        return accessEditRecords !== roleConst.FULL || !isEditable || isCellReadOnly;
    });
}

export function useCellParentText({ rowId, columnId }) {
    return useSelector(state => {
        const advanced = state?.advanced;
        const { data, dependencies = [] } = advanced;

        const childDependency = dependencies?.find(dpDc => dpDc?.child === columnId);

        return data?.[rowId]?.[childDependency?.parent]?.value;
    });
}

export function useCellMetaData({ recordId, columnId }) {
    const recordMetaData = {};
    return recordMetaData?.[recordId]?._metadata?.value?.[columnId];
}

export function useIsSelecting() {
    return useSelector(state => state.advanced.isSelecting);
}

export function useIsCellCopying() {
    return useSelector(state => state.advanced.isCellCopying);
}

export function useResourceCellByRowId(rowId) {
    return useSelector(state => {
        const advanced = state?.advanced;
        const { data } = advanced;
        const cellData = data?.[rowId]?.[columnTypes.RESOURCE];
        cellData['firstColumnCellData'] = data?.[rowId]?.[state?.advanced?.columnIds?.[0]];
        return cellData;
    });
}

export function useIsTmDisabled() {
    return false;
}

export function useCharacterKey() {
    return useSelector(state => state?.advanced?.character);
}

export function useIsCellOverlaid({ rowIndex, columnIndex }) {
    return useSelector(state => {
        const { advanced } = state;
        const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = advanced;
        if (!isSelecting({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex })) {
            return false;
        }

        const _rowStartIndex = Math.min(rowStartIndex, rowStopIndex);
        const _rowStopIndex = Math.max(rowStartIndex, rowStopIndex);
        const _columnStartIndex = Math.min(columnStartIndex, columnStopIndex);
        const _columnStopIndex = Math.max(columnStartIndex, columnStopIndex);

        return (
            between(rowIndex, _rowStartIndex, _rowStopIndex) &&
            between(columnIndex, _columnStartIndex, _columnStopIndex)
        );
    });
}

export function useIsCellPreview() {
    return useSelector(state => !!state.advanced.cellPreview);
}

export function useCurrentPreviewData() {
    return useSelector(state => state.advanced.cellPreview);
}

export function useCurrentDefaultPreviewFile() {
    return useSelector(state => state.advanced.cellPreview?.defaultPreviewFile);
}

export function useCurrentPreviewType() {
    return useSelector(state => state.advanced.cellPreview?.type);
}

export function useIsCellPreviewCanEdit() {
    const metaData = useMetaData();
    const previewData = useCurrentPreviewData();
    const columnId = previewData?.columnId;
    const type = useCurrentPreviewType();

    const column = metaData?.[columnId];

    if (type === PREVIEW_IMAGE_TYPES.RECORD_HISTORY) {
        return false;
    }

    return column?.editable;
}

export function useCellPreviewData() {
    return useSelector(state => {
        const type = state.advanced.cellPreview?.type;
        if (!type) return null;
        const { data } = state?.advanced;
        const cellPreview = state.advanced.cellPreview;
        const rowId = cellPreview?.rowId;
        const columnId = cellPreview?.columnId;

        return data?.[rowId]?.[columnId]?.value;
    });
}

export function usePinnedSearch() {
    return useSelector(state => state?.advanced?.pinnedSearch);
}

export function useIsSearchingRecord() {
    return useSelector(state => state?.advanced?.isSearchingRecord);
}

export function useIsOpenReplaceSearch() {
    return useSelector(state => state?.advanced?.isOpenReplaceSearch);
}

export function useIsReplacingText() {
    return useSelector(state => state?.advanced?.replacingText);
}

export function useSearchRecords() {
    return useSelector(state => state?.advanced?.searchRecords);
}

export function useSuccessReplaceAll() {
    return useSelector(state => state?.advanced?.successReplaceAll);
}

export function useReplacingType() {
    return useSelector(state => state?.advanced?.replacingType);
}

export function useClearingRecords() {
    return useSelector(state => state?.advanced?.clearingRecords);
}

export function useIsHighlightFilter() {
    return useSelector(state => state.advanced.viewFilters?.filter(filter => !filter.isDeleted)?.length);
}

export function useIsHighlightSort() {
    return useSelector(state => state.advanced.viewSorts?.length);
}

export function useGlobalAction() {
    return useSelector(state => state.advanced.globalAction);
}

export function useIsBlockedGrid() {
    return useSelector(state => {
        return state.advanced.globalAction === GLOBAL_SEARCH_TYPES.REPLACING && state.advanced.replacingType === 'all';
    });
}

export function useIsBlockedGridByOwner() {
    return useSelector(state => {
        return [ADVANCED_SEARCH_STATUS.BLOCKED]?.includes(state.advanced.advancedSearch?.status);
    });
}

export function useIsActive() {
    return useSelector(state => {
        return [ADVANCED_SEARCH_STATUS.ACTIVE]?.includes(state.advanced.advancedSearch?.status);
    });
}

export function useHideWarning() {
    return useSelector(state => {
        return state.advanced.hideWarning;
    });
}

export function useClearGrid() {
    return useSelector(state => {
        return state.advanced.clearGrid;
    });
}

export function useIsRowInRange({ rowIndex }) {
    return useSelector(state => {
        const { advanced } = state;
        const { rowStartIndex, rowStopIndex, rowsRangeIndexes } = advanced;
        return (
            rowsRangeIndexes?.some(range => {
                const start = range?.[0];
                const end = range?.[1] || start;
                return between(rowIndex, start, end);
            }) || between(rowIndex, Math.min(rowStartIndex, rowStopIndex), Math.max(rowStartIndex, rowStopIndex))
        );
    });
}

export function useActiveTab() {
    return useSelector(state => {
        return state.advanced.tab;
    });
}

export function useIsAdvancedSearchOwner() {
    return useSelector(state => {
        const { advanced, app, auth } = state;
        const isShareViewLink = app.isShareViewLink;
        const currentUser = isShareViewLink ? {} : auth.currentUser;
        return advanced?.advancedSearch?.userId === currentUser?.id;
    });
}

export function useIsHasManageAdvancedSearchPermission() {
    return useSelector(state => {
        return state?.auth?.currentUser?.authorities?.includes(roleConst.COMPANY_AUTHORITIES.MANAGE_ADVANCED_SEARCH);
    });
}

export function useIsSelectedSharedSearch() {
    return useSelector(state => {
        const selectedAdvancedSearchId = state?.advanced?.selectedAdvancedSearchId;
        const shareFound = state.advanced.sharedList?.find(ad => ad?.advancedSearchId === selectedAdvancedSearchId);
        return !!shareFound;
    });
}

export function useColumnWidthStore() {
    const columns = useAdvancedSearchSelectedColumns();
    return columns?.reduce((obj, c) => {
        obj = {
            ...obj,
            [c?.hashColumnId]: c?.width || DEFAULT_COLUMN_WIDTH
        };
        return obj;
    }, {});
}
