import React from 'react';
import { AutoSizer } from 'react-virtualized-dn';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CONFLICT_TYPES, FAKE_ROW, ROW_HEIGHT, TASK_STATUS } from 'const/gridUI';
import { CircularProgress, Grid } from '@material-ui/core';
import Button from 'components/button/Base';
import { useGridScratchImport, usePathTagRef, useTaskStatus } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import { sendManualTrack } from 'tracker';
import FileSVG from 'assets/images/svg/FileSVG';
import hexToRgba from 'hex-to-rgba';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import { formatReviewData } from 'utils/gridUI/data';
import OrganizationSetttingSVG from 'assets/images/svg/OrganizationSetttingSVG';
import { INPUT_HEIGHT } from 'const/style';
import GridUITable from 'gridUI/table/grid/GridUIViewOnly';
import * as importActions from 'gridUI/actions/import';
import ArrowBackSVG from 'assets/images/svg/ArrowBackSVG';
import ImportHeader from 'grids/localization/ImportHeader';
import * as roleConst from 'auth/roleConst';
import { useContextMenuId } from 'hooks/app';
import MergingGrid from 'gridUI/branch/diffCheck/MergingGrid';
import NoDiffCheck from 'assets/images/svg/versionControl/NoDiffCheck';
import produce from 'immer';
import Spinner from 'components/spinner/Base';

const PREVIEW_TABLE_HEIGHT = 252;
const useStyles = makeStyles(theme => ({
    root: {
        width: 800,
        paddingTop: 24,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    advanced: {
        width: `calc(100vw - 240px)`,
        minHeight: 560,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    table: {
        height: PREVIEW_TABLE_HEIGHT,
        overflow: 'hidden',
        userSelect: 'none',
        // border: `1px solid ${theme.colors.solitude}`,
        // borderRadius: '4px',
        '& .cell': {
            pointerEvents: 'none'
        },
        position: 'relative'
    },
    listView: {
        position: 'relative',
        height: PREVIEW_TABLE_HEIGHT,
        overflow: 'auto'
    },
    dropdown: {
        minWidth: 340
    },
    switch: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'normal'
    },
    switchIcon: {
        marginRight: 10
    },
    inkBox: {
        background: theme.colors.paleGrey,
        padding: `6px 8px`,
        borderRadius: 4
    },
    innerColSpacing: {
        marginBottom: theme.spacing(2)
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    fullWidth: {
        width: '100%'
    },
    radio: {
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    recordIdWarning: {
        borderRadius: 10,
        margin: '0px 4px',
        padding: `0px 9px`,
        position: 'relative',
        top: 4,
        background: theme.colors.paleNavy,
        color: theme.colors.dimGrey,
        fontWeight: 500,
        border: `1px solid ${theme.colors.solitude}`,
        display: 'inline-flex',
        alignItems: 'center'
    },
    recordId: {
        marginRight: theme.spacing(1)
    },
    dlFlex: {
        display: 'flex'
    },
    importOptions: {
        background: theme.colors.ghostwhite,
        padding: theme.spacing(4),
        borderRadius: 4
    },
    showMoreOption: {
        cursor: 'pointer'
    },
    title: {
        minWidth: 140
    },
    blue80: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    delimiter: {
        minWidth: 340
    },
    rowSpacing: {
        marginRight: theme.spacing(3)
    },
    columnInnerSpacing: {
        marginBottom: theme.spacing(2)
    },
    columnSpacing: {
        marginBottom: theme.spacing(3)
    },
    wrapperLoading: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
        background: hexToRgba(theme.colors.white, 0.8)
    },
    dropdownClassName: {
        background: theme.palette.common.white,
        '& .focused': {
            border: `1px solid ${theme.colors.lightLavender}`
        }
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        zIndex: 10
    },
    mappedColumn: {
        borderLeft: `1px solid ${theme.colors.divider}`
    },
    paddingItem: {
        paddingLeft: 8,
        paddingRight: 8
    },
    added: {
        width: 16,
        height: 16,
        border: `1px solid ${theme.colors.shamRock}`,
        borderRadius: 4,
        background: hexToRgba(theme.colors.shamRock, 0.2)
    },
    updated: {
        width: 16,
        height: 16,
        border: `1px solid ${theme.colors.sun}`,
        borderRadius: 4,
        background: hexToRgba(theme.colors.sun, 0.2)
    },
    option: {
        padding: theme.spacing(2),
        minWidth: 265,
        boxSizing: 'border-box',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    active: {
        background: theme.colors.ghostwhite,
        borderRadius: 6
    },
    optionIcon: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(1)
    },
    previewGrid: {
        width: '100%',
        height: 255,
        position: 'relative'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 100
    },
    diffCheckTable: {
        // height: PREVIEW_TABLE_HEIGHT,
        width: '100%',
        // overflow: 'hidden',
        userSelect: 'none'
    },
    showAllConflict: {
        width: 32,
        height: 32,
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    paper: {
        minWidth: 200
    },
    selectPathWrapper: {
        flex: 1,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        background: theme.palette.common.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        cursor: 'pointer',
        height: INPUT_HEIGHT
    },
    wrapperPathTag: {
        ...theme.ellipsis(1)
    }
}));

function LocalizationImportPreview({
    files,
    onClose,
    branchId,
    dbId,
    viewId,
    taskId,
    columnsWithPublicId,
    previewData,
    haveChanged,
    isFetchingDiffCheck,
    manageCheckDiff,
    fetchingDataGridPreview
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const taskStatus = useTaskStatus(taskId);

    const [showDiff, setShowDiff] = React.useState(false);
    const [resolveConflictData, setResolveConflictData] = React.useState({});

    const gridScratchImport = useGridScratchImport();

    const data = React.useMemo(() => {
        return gridScratchImport?.data || {};
    }, [gridScratchImport]);

    const rows = React.useMemo(() => {
        return gridScratchImport?.rows || [];
    }, [gridScratchImport]);

    const totalRecords = React.useMemo(() => {
        return rows.length > 5 ? 6 : rows.length;
    }, [rows]);

    const metaData = React.useMemo(() => {
        return gridScratchImport?.metaData || {};
    }, [gridScratchImport]);

    const columns = React.useMemo(() => {
        return gridScratchImport?.columns || [];
    }, [gridScratchImport]);

    const columnMap = React.useMemo(() => {
        return gridScratchImport?.columnMap || {};
    }, [gridScratchImport]);

    const fileData = React.useMemo(() => {
        return formatReviewData({ data, metaData });
    }, [data, metaData]);

    const fileName = React.useMemo(() => {
        return files?.[0]?.name;
    }, [files]);

    const contextMenuId = useContextMenuId();
    const pathRef = usePathTagRef();

    const handleImportData = React.useCallback(() => {
        sendManualTrack({ type: `Start Import File` });

        if (showDiff) {
            const mergeRecordConflicts = [];

            const recordIds = Object.keys(resolveConflictData);
            for (const recordId of recordIds) {
                const columnIds = Object.keys(resolveConflictData?.[recordId]);

                let cells = [];

                for (const columnId of columnIds) {
                    let cell = {
                        columnId,
                        option: resolveConflictData?.[recordId]?.[columnId]
                    };
                    cells.push(cell);
                }
                mergeRecordConflicts.push({
                    recordId,
                    cells
                });
            }

            let importBody = {
                mergeRecordConflicts,
                showAllConflicts: true
            };

            dispatch(
                importActions.importMergeDiffCheckLocalizationGrid({
                    dbId,
                    viewId,
                    taskId,
                    body: importBody,
                    successCallback: () => {
                        onClose && onClose();
                        sendManualTrack({ type: `Localization Import File Success` });
                    },
                    errorCallback: () => {
                        onClose && onClose();
                        sendManualTrack({ type: `Localization Import File Failed` });
                    }
                })
            );
        } else {
            files.forEach(file => {
                dispatch(
                    importActions.importLocalizationGrid({
                        file,
                        successCallback: () => {
                            onClose && onClose();
                            sendManualTrack({ type: `Localization Import File Success` });
                        },
                        errorCallback: () => {
                            onClose && onClose();
                            sendManualTrack({ type: `Localization Import File Failed` });
                        }
                    })
                );
            });
        }
    }, [dbId, dispatch, files, onClose, resolveConflictData, showDiff, taskId, viewId]);

    const handleRevertPreview = React.useCallback(() => {
        setShowDiff(false);
    }, []);

    const handleShowDiff = React.useCallback(async () => {
        manageCheckDiff && manageCheckDiff();
        setShowDiff(true);
    }, [manageCheckDiff]);

    const handleConflictChanged = React.useCallback(
        ({ rowId, columnId, mode }) => {
            setResolveConflictData(
                produce(resolveConflictData, draft => {
                    if (!draft?.[rowId]) {
                        draft[rowId] = {};
                    }
                    draft[rowId][columnId] = mode;
                })
            );
        },
        [resolveConflictData]
    );

    const isLoadingDiffCheck = React.useMemo(() => {
        return taskStatus === TASK_STATUS.RUNNING || isFetchingDiffCheck;
    }, [isFetchingDiffCheck, taskStatus]);

    const handleFetchMore = React.useCallback(
        async ({ dbId, gridId, parentGridId, ROW_START_INDEX, ROW_STOP_INDEX, successCallback, errorCallback }) => {
            fetchingDataGridPreview({ ROW_START_INDEX, ROW_STOP_INDEX });
        },
        [fetchingDataGridPreview]
    );

    const TitleRender = React.useMemo(() => {
        return (
            <div className="flex flex-row items-center gap-1">
                <h3 className="prose prose-2xl font-medium">
                    {showDiff ? `Manage import data change` : t(`import_data`)}{' '}
                </h3>
                <div className="flex items-center bg-grey-pale py-1.5 px-2 rounded">
                    <FileSVG />
                    <p className="body2 line-clamp-1">{fileName || 'Default File'}</p>
                </div>
            </div>
        );
    }, [showDiff, t, classes.inkBox, theme, fileName]);

    const ActionRender = React.useMemo(() => {
        return (
            <Grid item container direction="row" wrap="nowrap" justify="flex-end" alignItems="center">
                <Grid item>
                    <Grid container spacing={2} direction="row">
                        {showDiff && (
                            <Grid item>
                                <Button width={173} variant="outlined" onClick={handleRevertPreview}>
                                    <ArrowBackSVG style={{ marginRight: theme.spacing(1) }} /> Re-mapping column
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button width={120} variant="outlined" onClick={onClose}>
                                {t(`global_cancel`)}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={haveChanged || isFetchingDiffCheck}
                                width={120}
                                variant="contained"
                                onClick={handleImportData}
                            >
                                {t(`global_import`)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [showDiff, handleRevertPreview, theme, onClose, t, handleImportData, haveChanged, isFetchingDiffCheck]);

    const ContentRender = React.useMemo(() => {
        const GenerateButton = isFetchingDiffCheck ? (
            <CircularProgress size={20} />
        ) : (
            <>
                <OrganizationSetttingSVG style={{ marginRight: theme.spacing(1) }} />
                <p className="body2" noWrap>
                    Manage import change
                </p>
            </>
        );

        const DiffCheckRender = (
            <>
                {showDiff ? (
                    <>
                        {haveChanged ? (
                            <div
                                className="flex flex-col gap-2 items-center overflow-hidden select-none relative"
                                style={{
                                    height: PREVIEW_TABLE_HEIGHT
                                }}
                            >
                                <NoDiffCheck width={200} height={200} />
                                <h3 className="prose prose-2xl font-medium">{t('diffcheck_wrapper_text_1')}</h3>
                            </div>
                        ) : (
                            <Grid container direction="column" spacing={2} justify="center" alignItems="center">
                                <Grid item container className={`${classes.table}`}>
                                    <AutoSizer>
                                        {({ width, height }) => {
                                            return (
                                                <MergingGrid
                                                    branchId={branchId}
                                                    dbId={dbId}
                                                    accessSupportSelectAllCheckbox={roleConst.HIDDEN}
                                                    accessSupportAggregation={roleConst.HIDDEN}
                                                    accessEditCell={roleConst.READ_ONLY}
                                                    accessViewRecordHistory={roleConst.READ_ONLY}
                                                    accessManageGridRecord={roleConst.READ_ONLY}
                                                    accessEditColumn={false}
                                                    dependencies={[]}
                                                    metaData={metaData}
                                                    rows={previewData?.rows}
                                                    totalRecords={previewData?.totalRecords + FAKE_ROW}
                                                    data={previewData?.data}
                                                    columns={columnsWithPublicId}
                                                    width={width}
                                                    maxHeight={height}
                                                    rowHeight={ROW_HEIGHT}
                                                    fixedColumnCount={0}
                                                    fixedRowCount={1}
                                                    ROW_START_INDEX={previewData?.ROW_START_INDEX}
                                                    ROW_STOP_INDEX={previewData?.ROW_STOP_INDEX}
                                                    resolveConflictData={resolveConflictData}
                                                    onConflictChanged={handleConflictChanged}
                                                    onFetchingMore={handleFetchMore}
                                                    isFetchingMore={isFetchingDiffCheck}
                                                    contextMenuId={contextMenuId}
                                                    pathRef={pathRef}
                                                    conflictType={CONFLICT_TYPES.IMPORT}
                                                    isOverride={true}
                                                    AGG_HEIGHT={0}
                                                    noneFooter={true}
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item container className={`${classes.table}`}>
                            <AutoSizer>
                                {({ width, height }) => {
                                    return (
                                        <GridUITable
                                            isUseColumnIdByIndex={true}
                                            metaData={metaData}
                                            totalRecords={totalRecords}
                                            data={fileData}
                                            rows={rows}
                                            columns={columns}
                                            width={width}
                                            maxHeight={height}
                                            rowHeight={ROW_HEIGHT}
                                            fixedColumnCount={0}
                                            fixedRowCount={1}
                                            COLUMN_ID_WIDTH={0}
                                            headerRenderer={headerProps => {
                                                const columnId = columns?.[headerProps?.columnIndex];
                                                return (
                                                    <ImportHeader
                                                        {...headerProps}
                                                        onChange={() => {}}
                                                        columnId={columnId}
                                                        columnMap={columnMap}
                                                        isViewOnly={true}
                                                    />
                                                );
                                            }}
                                            nonHeader
                                            AGG_HEIGHT={0}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        </Grid>
                    </>
                )}
            </>
        );

        return (
            <>
                {' '}
                <Grid container direction={'column'} className={classes.root}>
                    <Grid
                        item
                        container
                        direction="row"
                        justify="space-between"
                        className={classes.spacing}
                        alignItems="center"
                    >
                        <Grid item>
                            <p className="body1">{!showDiff ? `Mapping column preview` : `Changes`}</p>
                        </Grid>
                        {showDiff && (
                            <Grid item>
                                <Grid container alignItems="center" direction="row" spacing={1}>
                                    <Grid item className={`${classes.dlFlex}`}>
                                        <span className={classes.updated}></span>
                                    </Grid>
                                    <Grid item>
                                        <p className="body2">Updated</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            className={classes.previewGrid}
                            id={'gridUI-table-import'}
                        >
                            {isLoadingDiffCheck && (
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    item
                                    className={classes.spinnerWrapper}
                                >
                                    <Spinner size={18} thick={3} />
                                </Grid>
                            )}
                            {DiffCheckRender}
                        </Grid>
                    </Grid>
                    {!showDiff && (
                        <Grid
                            style={{ paddingTop: theme.spacing(3) }}
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justify="flex-end"
                        >
                            <Button width={200} variant="outlined" onClick={handleShowDiff}>
                                {GenerateButton}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    }, [
        classes.spinnerWrapper,
        isLoadingDiffCheck,
        theme,
        showDiff,
        haveChanged,
        classes.table,
        classes.root,
        classes.spacing,
        classes.dlFlex,
        classes.updated,
        classes.previewGrid,
        t,
        handleShowDiff,
        branchId,
        dbId,
        metaData,
        previewData.rows,
        previewData.totalRecords,
        previewData.data,
        previewData.ROW_START_INDEX,
        previewData.ROW_STOP_INDEX,
        columnsWithPublicId,
        resolveConflictData,
        handleConflictChanged,
        handleFetchMore,
        isFetchingDiffCheck,
        contextMenuId,
        pathRef,
        totalRecords,
        fileData,
        rows,
        columns,
        columnMap
    ]);

    return (
        <>
            <DialogTitle title={TitleRender} onClose={onClose} />
            <DialogContent style={{ borderTop: `1px solid ${theme.colors.solitude}` }}>{ContentRender}</DialogContent>
            <DialogActions>{ActionRender}</DialogActions>
        </>
    );
}

export default React.memo(LocalizationImportPreview);
