import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        outline: 'none',
        width: `100%`,
        height: `auto`
    }
}));

function getSupportType(extension) {
    switch (extension) {
        case 'ogg':
            return 'video/ogg';
        case 'mov':
            return 'video/mp4';
        case 'mp4':
            return 'video/mp4';
        case 'webm':
            return 'video/webm';
        default:
            return 'video/mp4';
    }
}

function VideoPlay({ src, width, height, ...rest }) {
    const classes = useStyles({ width, height });
    const file = src?.split('.');
    const extension = file?.pop();
    const type = getSupportType(extension);
    const videoRef = useRef();

    useEffect(() => {
        if (src && videoRef.current) {
            videoRef.current.pause();
            videoRef.current.load();
        }
    }, [src]);

    if (!type || !extension) return null;
    return (
        <video ref={videoRef} className={classes.root} controls {...rest}>
            <source src={src} type={type} />
            Your browser does not support the audio element.
        </video>
    );
}

export default React.memo(VideoPlay);
