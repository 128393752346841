import React, { useState } from 'react';
import { Grid, CircularProgress, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import InputText from 'components/inputs/InputText';
import Button from 'components/button/Base';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import Divider from 'components/divider/Base';

import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { DASHBOARD_TUTORIAL } from 'const';

import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import ErrorMessage from './ErrorMessage';
import { userInit, logIn } from './actions';
import AccountDiffCheck from './AccountDiffCheck';

import { trackUserOnboarding } from 'services/external';
import * as appActions from 'app/actions';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { getCompanyInfoApi, getUserFactorApi } from 'services/company';
import { sendManualTrack } from 'tracker';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import SamlLoginSVG from 'assets/images/svg/SamlLoginSVG';
import GoogleLoginSVG from 'assets/images/svg/GoogleLoginSVG';
import { API_URL } from 'config';
import PasswordValidationPopup from 'components/PasswordValidationPopup';
import { isExternal } from 'config';

const InitAccountSchema = object({
    firstName: string().required(i18n.t(`global_this_field_is_required`)),
    lastName: string().required(i18n.t(`global_this_field_is_required`)),
    password: string().required(i18n.t(`global_this_field_is_required`)),
    email: string()
        .email('Invalid Email')
        .required(i18n.t(`global_this_field_is_required`)),
    key: string().required(i18n.t(`global_this_field_is_required`))
});

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: 470
    },
    btnSubmit: {
        width: 140,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    link: {
        '& a': {
            color: theme.colors.darkLavender,
            textDecoration: 'none'
        }
    }
}));

function InitAccount() {
    const classes = useStyles();
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const queryParams = queryParamsToObject(location.search);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            password: '',
            email: queryParams.email,
            key: queryParams.key
        },
        validationSchema: InitAccountSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            sendManualTrack({ type: `[Gridly] Activate invited account` });
            setStatus({ error: null });

            dispatch(
                userInit({
                    ...values,
                    errorCallback: message => {
                        setSubmitting(false);
                        setStatus({ error: message });
                    },
                    successCallback: ({ email, password }) => {
                        dispatch(
                            logIn({
                                username: email,
                                password,
                                errorCallback: message => {
                                    trackUserOnboarding({
                                        email: email,
                                        utmSource: 'invited',
                                        userInfo: values,
                                        isDone: true
                                    });

                                    setSubmitting(false);
                                    setStatus({ error: message });
                                },
                                successCallback: async userInfo => {
                                    setSubmitting(false);
                                    dispatch(appActions.toggleChromeNotify());
                                    dispatch(appActions.setCurrentTutorial(DASHBOARD_TUTORIAL));
                                    const factorStatus = await getUserFactorApi();
                                    const cInfo = await getCompanyInfoApi({ companyId: userInfo.companyId });

                                    trackUserOnboarding({
                                        email: email,
                                        utmSource: 'invited',
                                        userInfo: {
                                            ...values,
                                            companyName: cInfo?.name,
                                            companyId: cInfo?.id
                                        },
                                        isDone: true
                                    });

                                    if (cInfo?.setting?.factorRequired && !factorStatus?.factorEnabled) {
                                        return history.push('/signin-company-2fa', {
                                            backLocation: '/companies',
                                            showHello: true
                                        });
                                    }
                                    history.push('/');
                                }
                            })
                        );
                    }
                })
            );
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid, status } = formik;

    const passwordValue = values.password;
    const hasValidLength = passwordValue.length >= 12 && passwordValue.length <= 64;
    const hasAtLeastOneUpperCase = /^(?=.*[A-Z])/.test(passwordValue);
    const hasAtLeastOneLowerCase = /^(?=.*[a-z])/.test(passwordValue);
    const hasAtLeastOneDigit = /^(?=.*[0-9])/.test(passwordValue);
    const hasAtleastOneSpecialChar = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(passwordValue);
    const isValidPassword =
        hasValidLength &&
        hasAtLeastOneUpperCase &&
        hasAtLeastOneLowerCase &&
        hasAtLeastOneDigit &&
        hasAtleastOneSpecialChar;

    function handleClickShowPassword() {
        setIsShowPassword(!isShowPassword);
    }

    const handleGoogleLogin = () => {
        window.location = `${API_URL}/oauth2/authorization/google`;
    };

    return (
        <AccountDiffCheck location={location}>
            <AuthenticationWrapper isSigningUp>
                <AuthFormWrapper
                    title={t(`global_welcome_gridly`)}
                    subTitle={
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            <Grid item>
                                <Typography variant="subtitle1">
                                    {t(`auth_init_account_welcome_title`)}{' '}
                                    <span role="img" aria-label="handshake">
                                        👋
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" display="inline">
                                    {t('auth_init_account_description')}
                                </Typography>{' '}
                                <Typography variant="h4" display="inline">
                                    {queryParams.email}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                >
                    {status && status.error && <ErrorMessage message={status.error} />}
                    <form
                        id="activate-account-form"
                        onSubmit={handleSubmit}
                        className={classes.form}
                        autoComplete="off"
                    >
                        <div style={{ display: 'none' }}>
                            <InputText
                                name="email"
                                type="email"
                                value={values.email || ''}
                                height={36}
                                disabled={isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.email && touched.email}
                                errorText={errors.email}
                                placeholder={t(`global_email_placeholder_example`)}
                            />
                        </div>
                        <Grid container direction="column" spacing={4}>
                            <Grid item>
                                <InputText
                                    placeholder={t(`global_first_name`)}
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={values.firstName}
                                    height={40}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.firstName && touched.firstName}
                                    errorText={errors.firstName}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item>
                                <InputText
                                    placeholder={t(`global_last_name`)}
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={values.lastName}
                                    height={40}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.lastName && touched.lastName}
                                    errorText={errors.lastName}
                                    autoFocus={false}
                                />
                            </Grid>
                            <Grid item>
                                <InputText
                                    autoComplete="new-password"
                                    placeholder={t(`global_password`)}
                                    id="password"
                                    name="password"
                                    value={values.password}
                                    data-openreplay-obscured
                                    height={40}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.password && touched.password}
                                    errorText={errors.password}
                                    autoFocus={false}
                                    type={isShowPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClickShowPassword} edge="end">
                                                {isShowPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <PasswordValidationPopup
                                    hasValidLength={hasValidLength}
                                    hasAtLeastOneUpperCase={hasAtLeastOneUpperCase}
                                    hasAtLeastOneLowerCase={hasAtLeastOneLowerCase}
                                    hasAtLeastOneDigit={hasAtLeastOneDigit}
                                    hasAtleastOneSpecialChar={hasAtleastOneSpecialChar}
                                    isValidPassword={isValidPassword}
                                    passwordValue={passwordValue}
                                />
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting || !isValid || !isValidPassword}
                                    >
                                        {t(`global_next`)}
                                        <ArrowNextSVG />
                                    </Button>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                            <Grid item container alignItems="center" spacing={2} style={{ marginTop: 14 }}>
                                <Grid item xs>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.signInWith} variant="body2">
                                        {t('or_sign_in_with')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2} style={{ marginTop: 14 }}>
                                <Grid item xs>
                                    <Link to="/saml" className="btn btn-outlined w-full">
                                        <span style={{ marginRight: 8 }}>
                                            <SamlLoginSVG />
                                        </span>
                                        SSO/SAML
                                    </Link>
                                </Grid>
                                {!isExternal && (
                                    <Grid item xs>
                                        <Button
                                            onClick={handleGoogleLogin}
                                            className="whitespace-nowrap"
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <span style={{ marginRight: 8 }}>
                                                <GoogleLoginSVG />
                                            </span>
                                            Sign in with Google
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </AuthFormWrapper>
            </AuthenticationWrapper>
        </AccountDiffCheck>
    );
}

export default InitAccount;
