import React from 'react';
import store from 'store/configStore';
import * as types from 'socket/types';
import * as gridActions from 'grids/actions';
import * as gridUIActions from 'gridUI/actions';
import * as appActions from 'app/actions';
import { GRID_STATUS, TASK_STATUS } from 'const/gridUI';
import { enqueueSnackbar } from 'notifier/actions';
import { Typography, Grid } from '@material-ui/core';
import { NOTIFICATION_TYPES } from 'const';
import { restoreGrid } from 'gridUI/actions/gridUI';
import { getISOString } from 'utils/datetime';

const { dispatch } = store;

const gridHandler = ({ body, isGridDetail }) => {
    const { action, dbId, gridId, data, subAction, status, gridName } = body;
    const failedMessage = data?.failedMessage;
    switch (action) {
        case types.UPDATE:
            return _updateGrid({ dbId, gridId, data });
        case types.DELETE:
            return _deleteGrid({ dbId, gridId });
        case types.UPLOAD:
            return _uploadHandler({ dbId, gridId, subAction, isGridDetail, data, failedMessage, status, gridName });
        case types.IMPORT:
            return _importHandler({ dbId, gridId, subAction, isGridDetail, status, failedMessage, gridName, data });
        case types.MERGE_GRID:
            return _mergeGridHandler({ dbId, gridId, subAction, isGridDetail, failedMessage, data, gridName, status });

        case types.PREVIEW_MERGE:
            return _previewMergeGridHandler({
                dbId,
                gridId,
                subAction,
                isGridDetail,
                failedMessage,
                data,
                gridName,
                status
            });
        case types.DUPLICATE_GRID:
            return _duplicateGrid({ dbId, gridId, subAction, data, isGridDetail });
        case types.ADD_FILE:
            return _handleColumnFolderUploadFinish({ status, data, failedMessage });
        case types.PREVIEW_IMPORT:
            return _previewImportHandler({
                dbId,
                gridId,
                subAction,
                isGridDetail,
                status,
                failedMessage,
                gridName,
                data
            });
        case types.PREVIEW_TRANSLATION:
            return _previewImportTranslationEndHandler({
                dbId,
                gridId,
                subAction,
                status,
                data
            });
        default:
            return;
    }
};

function _handleColumnFolderUploadFinish({ data, status, failedMessage }) {
    const { fileName: filePath, columnId } = data;
    if (status === GRID_STATUS.FAILED) {
        dispatch(
            gridUIActions.uploadSingleFileFailedSocket({
                columnId,
                error: failedMessage,
                filePath
            })
        );
        return;
    }
    dispatch(
        gridUIActions.uploadSingleFileSuccessSocket({
            columnId,
            filePath
        })
    );
}

function _duplicateGrid({ dbId, gridId, subAction, data, isGridDetail }) {
    switch (subAction) {
        case types.DUPLICATE_GRID_END:
            if (!data?.template) return _updateGridStatus({ dbId, gridId, status: GRID_STATUS.ACTIVE, isGridDetail });
            return _updateGridTemplateStatus({ id: gridId, status: GRID_STATUS.ACTIVE });
        default:
            return;
    }
}

function _previewMergeGridHandler({ dbId, gridId, subAction, isGridDetail, failedMessage, data, gridName, status }) {
    switch (subAction) {
        case types.PREVIEW_MERGE_END: {
            if (status?.toLowerCase() === GRID_STATUS.SUCCESS) {
                dispatch(gridUIActions.registerMergingTask({ ...data, status: GRID_STATUS.SUCCESS }));
            }
            break;
        }

        default:
            return;
    }
}

function _previewImportTranslationEndHandler({ dbId, gridId, subAction, data, status }) {
    switch (subAction) {
        case types.PREVIEW_TRANSLATION_END: {
            if (status?.toLowerCase() === GRID_STATUS.SUCCESS) {
                dispatch(gridUIActions.setTaskIdStatus({ taskId: data?.id, status: TASK_STATUS.SUCCESS }));
            }
            break;
        }

        default:
            return;
    }
}

function _mergeGridHandler({ dbId, gridId, subAction, isGridDetail, failedMessage, data, gridName, status }) {
    switch (subAction) {
        case types.MERGE_GRID_START:
            return _updateGridStatus({ dbId, gridId, status: GRID_STATUS.MERGING, isGridDetail });
        case types.MERGE_GRID_END:
            return _mergeGridEnd({
                dbId,
                gridId,
                status: status === GRID_STATUS.FAILED ? status : GRID_STATUS.ACTIVE,
                isGridDetail,
                failedMessage,
                data,
                gridName
            });
        default:
            return;
    }
}

function _importHandler({ dbId, gridId, subAction, isGridDetail, status, failedMessage, gridName, data }) {
    switch (subAction) {
        case GRID_STATUS.IMPORT_START:
            return _updateGridStatus({ dbId, gridId, status: GRID_STATUS.IMPORTING, isGridDetail });
        case GRID_STATUS.IMPORT_END:
            return _importGridEnd({
                dbId,
                gridId,
                status: status === GRID_STATUS.FAILED ? status : GRID_STATUS.ACTIVE,
                isGridDetail,
                gridName,
                failedMessage,
                data
            });
        default:
            return;
    }
}

function _mergeGridEnd({ dbId, gridId, status, isGridDetail, failedMessage, data, gridName }) {
    _updateGridStatus({ dbId, gridId, status, isGridDetail });

    if (isGridDetail) {
        if (status === GRID_STATUS.FAILED) {
            dispatch(
                enqueueSnackbar({
                    message: (
                        <>
                            <Typography variant="subtitle2" display="inline">
                                Failed to merged &nbsp;
                            </Typography>
                            <Typography variant="overline" display="inline">
                                {gridName}.
                            </Typography>
                            <Typography variant="subtitle2" display="inline">
                                into &nbsp;
                            </Typography>
                            <Typography variant="overline" display="inline">
                                {data?.parentGridName}.
                            </Typography>
                            <Typography variant="subtitle2" display="inline">
                                &nbsp;{failedMessage}
                            </Typography>
                        </>
                    ),
                    type: 'info'
                })
            );
            return;
        }

        dispatch(
            gridUIActions.renderGridUITableAfterNetworkConnected({
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: (
                                <>
                                    <Typography variant="overline" display="inline">
                                        {gridName}
                                    </Typography>
                                    <Typography variant="subtitle2" display="inline">
                                        &nbsp;was merged into &nbsp;
                                    </Typography>
                                    <Typography variant="overline" display="inline">
                                        {data?.parentGridName}
                                    </Typography>
                                    <Typography variant="subtitle2" display="inline">
                                        &nbsp;successfully!
                                    </Typography>
                                </>
                            ),
                            type: 'info'
                        })
                    );
                },
                errorCallback: () => {
                    console.log('_mergeGridEnd errorCallback');
                }
            })
        );
    }
}

function _refreshGridAfterImportUpload() {
    dispatch(
        gridUIActions.fetchTags({
            successCallback: () => {
                console.log('fetch tags success');
            },
            errorCallback: () => {
                console.log('failed fetch tags ');
            }
        })
    );
    dispatch(
        gridUIActions.fetchCurrentSection({
            successCallback: () => {
                console.log('fetch current section success');
            },
            errorCallback: () => {
                console.log('failed fetch current section');
            }
        })
    );
}

function _importGridEnd({ dbId, gridId, status, isGridDetail, failedMessage, gridName, data }) {
    _updateGridStatus({ dbId, gridId, status, isGridDetail });
    const undoTime = data?.undoTime;

    if (status === GRID_STATUS.FAILED) {
        dispatch(
            enqueueSnackbar({
                message: (
                    <>
                        <Typography variant="subtitle2" display="inline">
                            Failed to import &nbsp;
                        </Typography>
                        <Typography variant="overline" display="inline">
                            {gridName}.
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                            &nbsp;{failedMessage}
                        </Typography>
                    </>
                ),
                type: 'info'
            })
        );
        return;
    }

    if (isGridDetail) {
        _refreshGridAfterImportUpload();
    }

    const handleRevert = () => {
        dispatch(restoreGrid({ restoreToTime: getISOString(undoTime), type: 'restore', restoreOption: 'file' }));
    };

    const duplicatePublicRecordIds = data?.duplicatePublicRecordIds;
    const exceedLengthPublicRecordIds = data?.exceedLengthPublicRecordIds;

    if (duplicatePublicRecordIds?.length || exceedLengthPublicRecordIds?.length) {
        const openDetailPage = () => {
            dispatch(
                appActions.openNotificationInfo({
                    type: NOTIFICATION_TYPES.IMPORT,
                    data
                })
            );
        };

        dispatch(
            enqueueSnackbar({
                duration: 10000,
                message: (
                    <Grid container spacing={3} direction="row" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" display="inline">
                                {gridName}
                            </Typography>
                            <Typography variant="subtitle2" display="inline">
                                &nbsp;was imported successfully!
                            </Typography>
                            <Typography variant="overline" display="inline">
                                &nbsp;{duplicatePublicRecordIds?.length + exceedLengthPublicRecordIds?.length} records
                            </Typography>
                            <Typography variant="subtitle2" display="inline">
                                &nbsp;were skipped.
                            </Typography>
                            <Typography
                                onClick={openDetailPage}
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                variant="overline"
                                display="inline"
                            >
                                &nbsp;View import log.
                            </Typography>
                        </Grid>
                        {isGridDetail && undoTime && (
                            <Grid
                                onClick={handleRevert}
                                item
                                style={{
                                    padding: `7px 11px`,
                                    borderRadius: 4,
                                    background: '#000000',
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography variant="overline">Revert</Typography>
                            </Grid>
                        )}
                    </Grid>
                ),
                action: key => <Grid container alignItems="center"></Grid>,
                type: 'info'
            })
        );
    } else {
        dispatch(
            enqueueSnackbar({
                duration: 10000,
                message: (
                    <Grid container spacing={3} direction="row" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" display="inline">
                                {gridName}
                            </Typography>
                            <Typography variant="subtitle2" display="inline">
                                &nbsp;was imported successfully!
                            </Typography>
                        </Grid>
                        {isGridDetail && undoTime && (
                            <Grid
                                onClick={handleRevert}
                                item
                                style={{
                                    padding: `7px 11px`,
                                    borderRadius: 4,
                                    background: '#000000',
                                    cursor: 'pointer'
                                }}
                            >
                                <Typography variant="overline">Revert</Typography>
                            </Grid>
                        )}
                    </Grid>
                ),
                type: 'info'
            })
        );
    }
}

function _uploadHandler({ dbId, gridId, subAction, isGridDetail, data, failedMessage, status, gridName }) {
    switch (subAction) {
        case GRID_STATUS.UPLOAD_START:
            return _updateGridStatus({ dbId, gridId, status: GRID_STATUS.UPLOADING, isGridDetail });
        case GRID_STATUS.UPLOAD_END:
            return _uploadGridEnd({
                dbId,
                gridId,
                status: status === GRID_STATUS.FAILED ? status : GRID_STATUS.ACTIVE,
                isGridDetail,
                failedMessage,
                gridName,
                data
            });
        default:
            return;
    }
}

function _uploadGridEnd({ dbId, gridId, status, isGridDetail, failedMessage, gridName, data }) {
    _updateGridStatus({ dbId, gridId, status, isGridDetail });

    if (status === GRID_STATUS.FAILED) {
        dispatch(
            enqueueSnackbar({
                message: (
                    <>
                        <Typography variant="subtitle2" display="inline">
                            Failed to upload &nbsp;
                        </Typography>
                        <Typography variant="overline">{gridName}.</Typography>
                        <Typography variant="subtitle2" display="inline">
                            &nbsp;{failedMessage}
                        </Typography>
                    </>
                ),
                type: 'info'
            })
        );
        if (!isGridDetail) {
            dispatch(gridActions.deleteGridAction({ gridId, dbId }));
        }
        return;
    }

    if (isGridDetail) {
        _refreshGridAfterImportUpload();
    }

    const duplicatePublicRecordIds = data?.duplicatePublicRecordIds;
    const exceedLengthPublicRecordIds = data?.exceedLengthPublicRecordIds;

    if (duplicatePublicRecordIds?.length || exceedLengthPublicRecordIds?.length) {
        const openDetailPage = () => {
            dispatch(
                appActions.openNotificationInfo({
                    type: NOTIFICATION_TYPES.IMPORT,
                    data
                })
            );
        };

        dispatch(
            enqueueSnackbar({
                duration: 10000,
                message: (
                    <>
                        <Typography variant="overline" display="inline">
                            {gridName}
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                            &nbsp;was uploaded successfully!
                        </Typography>
                        <Typography variant="overline" display="inline">
                            &nbsp;{duplicatePublicRecordIds?.length + exceedLengthPublicRecordIds?.length} records
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                            &nbsp;were skipped.
                        </Typography>
                        <Typography
                            onClick={openDetailPage}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            variant="overline"
                            display="inline"
                        >
                            &nbsp;View import log.
                        </Typography>
                    </>
                ),
                type: 'info'
            })
        );
    } else {
        dispatch(
            enqueueSnackbar({
                message: (
                    <>
                        <Typography variant="overline" display="inline">
                            {gridName}
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                            &nbsp;was uploaded successfully!
                        </Typography>
                    </>
                ),
                type: 'info'
            })
        );
    }
}

function _updateGridStatus({ dbId, gridId, status, isGridDetail }) {
    if (!isGridDetail) {
        dispatch(
            gridActions.updateGridStatus({
                dbId,
                gridId,
                status
            })
        );
    } else {
        dispatch(gridActions.updateGridBranchStatus({ dbId, gridId, status }));
    }
}

function _updateGridTemplateStatus({ id, status }) {
    dispatch(
        gridActions.updateGridTemplateStatus({
            id,
            status
        })
    );
}

function _updateGrid({ dbId, gridId, data }) {
    dispatch(gridActions.updateGridAction({ dbId, gridId, newGrid: data }));
}

function _deleteGrid({ dbId, gridId }) {
    dispatch(gridActions.deleteGridRealtime({ dbId, gridId }));
}

function _previewImportHandler({ dbId, gridId, subAction, isGridDetail, status, failedMessage, gridName, data }) {
    switch (subAction) {
        case GRID_STATUS.PREVIEW_IMPORT_END:
            return _previewImportGridEnd({
                dbId,
                gridId,
                status: status === GRID_STATUS.FAILED ? status : GRID_STATUS.ACTIVE,
                isGridDetail,
                gridName,
                failedMessage,
                data
            });
        default:
            return;
    }
}

function _previewImportGridEnd({ dbId, gridId, status, isGridDetail, failedMessage, gridName, data }) {
    if (status === GRID_STATUS.FAILED) {
        dispatch(
            enqueueSnackbar({
                message: (
                    <>
                        <Typography variant="subtitle2" display="inline">
                            Failed to get diff check preview &nbsp;
                        </Typography>
                        <Typography variant="overline">{gridName}.</Typography>
                        <Typography variant="subtitle2" display="inline">
                            &nbsp;{failedMessage}
                        </Typography>
                    </>
                ),
                type: 'info'
            })
        );
        return;
    }

    dispatch(gridUIActions.setTaskIdStatus({ taskId: data?.id, status: TASK_STATUS.SUCCESS }));
}

export default gridHandler;
