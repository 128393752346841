import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { fetchGridBranchesApi, createGridBranchApi, deleteGridApi, updateGridApi } from 'services/grid';
import { requestData, receiveData } from 'api/actions';
import i18n from 'i18n';
import { mergeViaPreviewMergeApi } from 'services/view';

export function inititalBranchType({ type }) {
    return {
        type: types.INITITAL_BRANCH_TYPE,
        payload: {
            type
        }
    };
}

export function fetchingDBbranches({ workspaceId, dbId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createGridBranch({ dbId, gridId, parentGridId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const branch = await createGridBranchApi({ dbId, gridId: parentGridId, body });
            dispatch(createGridBranchActionSuccess({ branch, gridId }));
            successCallback && successCallback(branch);
        } catch (error) {
            const { message } = error;
            dispatch(receiveData(types.FETCH_BRANCHES_OF_GRID_FAILED, { gridId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createGridBranchActionSuccess({ branch, gridId }) {
    return {
        type: types.CREATE_GRID_BRANCH_SUCCESS,
        payload: {
            branch,
            gridId
        }
    };
}

export function fetchingGridBranches({ dbId, gridId, query, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            dispatch(requestData(types.FETCH_BRANCHES_OF_GRID, { gridId }));
            const branches = await fetchGridBranchesApi({ dbId, gridId, query });

            dispatch(
                _fetchingGridBranchesActionSuccess({
                    gridId,
                    branches: [...branches]
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(receiveData(types.FETCH_BRANCHES_OF_GRID_FAILED, { gridId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchingGridBranchesActionSuccess({ gridId, branches }) {
    return {
        type: types.FETCH_BRANCHES_OF_GRID_SUCCESS,
        payload: {
            gridId,
            branches
        }
    };
}

export function switchBranch({ branchId }) {
    return {
        type: types.SWITCH_BRANCH,
        payload: {
            branchId
        }
    };
}

export function deleteGridBranch({ masterId, dbId, gridId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_deleteGridBranchAction({ masterId, gridId }));
        try {
            await deleteGridApi({ dbId, gridId });
            dispatch(_deleteGridBranchActionSuccess({ masterId, gridId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_deleteGridBranchActionFailed({ masterId, gridId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _deleteGridBranchAction({ masterId, gridId }) {
    return {
        type: types.DELETE_GRID_BRANCH,
        payload: {
            masterId,
            gridId
        }
    };
}

function _deleteGridBranchActionFailed({ masterId, gridId }) {
    return {
        type: types.DELETE_GRID_BRANCH_FAILED,
        payload: {
            masterId,
            gridId
        }
    };
}

function _deleteGridBranchActionSuccess({ masterId, gridId }) {
    return {
        type: types.DELETE_GRID_BRANCH_SUCCESS,
        payload: {
            masterId,
            gridId
        }
    };
}

export function updateGridBranch({ masterId, dbId, gridId, newGrid, oldGrid, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_updateGridBranchAction({ masterId, gridId, grid: newGrid }));
        try {
            await updateGridApi({ dbId, gridId, newGrid });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_updateGridBranchAction({ masterId, gridId, grid: oldGrid }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _updateGridBranchAction({ masterId, gridId, grid }) {
    return {
        type: types.UPDATE_GRID_BRANCH,
        payload: {
            masterId,
            gridId,
            grid
        }
    };
}

export function mergeBranch({ dbId, destinationViewId, taskId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_mergeBranchAction());
        try {
            await mergeViaPreviewMergeApi({ dbId, destinationViewId, taskId, body });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('snackbar_success_merge_branch'),
                    type: 'info'
                })
            );
            dispatch(_mergeBranchActionSuccess());
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_mergeBranchActionFailed());
            errorCallback && errorCallback();
        }
    };
}

function _mergeBranchAction() {
    return {
        type: types.MERGE_BRANCH
    };
}

function _mergeBranchActionSuccess() {
    return {
        type: types.MERGE_BRANCH_SUCCESS
    };
}

function _mergeBranchActionFailed() {
    return {
        type: types.MERGE_BRANCH_FAILED
    };
}

export function changeBranchStatus({ masterId, gridId, status }) {
    return {
        type: types.CHANGE_BRANCH_STATUS,
        payload: {
            masterId,
            gridId,
            status
        }
    };
}
