import React from 'react';

function ErrorSVG({ color = 'white', ...other }) {
    return (
        <svg width="374" height="309" viewBox="0 0 374 309" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M373 306.141H341.081V306.382H373V306.141Z" fill="#EBEBEB" />
            <path d="M258.623 308.759H250.248V309H258.623V308.759Z" fill="#EBEBEB" />
            <path d="M340.117 297.207H321.623V297.448H340.117V297.207Z" fill="#EBEBEB" />
            <path
                d="M158.5 75.4109V97.3315L169.795 98.3904C171.31 105.599 174.17 112.457 178.228 118.607L170.971 127.368L186.477 142.867L195.247 135.608C201.402 139.665 208.269 142.523 215.486 144.032L216.546 155.315H238.49L239.56 144.032C246.775 142.517 253.641 139.659 259.799 135.608L268.559 142.867L284.075 127.368L276.818 118.607C280.877 112.459 283.735 105.599 285.241 98.3904L296.546 97.3315V75.4109L285.241 74.3423C283.736 67.1329 280.878 60.2734 276.818 54.1256L284.075 45.3651L268.559 29.8657L259.857 37.0763C253.701 33.024 246.834 30.1693 239.618 28.6623L238.548 17.3699H216.575L215.515 28.6623C208.298 30.1676 201.431 33.0224 195.276 37.0763L186.506 29.8272L171 45.3266L178.257 54.0775C174.202 60.2285 171.341 67.0867 169.824 74.2941L158.5 75.4109ZM227.571 56.696C233.446 56.696 239.189 58.4362 244.073 61.6964C248.958 64.9566 252.765 69.5905 255.013 75.012C257.261 80.4336 257.849 86.3993 256.703 92.1547C255.557 97.9102 252.728 103.197 248.574 107.346C244.42 111.496 239.128 114.322 233.366 115.467C227.604 116.611 221.632 116.024 216.205 113.778C210.777 111.532 206.138 107.73 202.875 102.85C199.611 97.971 197.869 92.2346 197.869 86.3664C197.869 78.4973 200.998 70.9505 206.568 65.3863C212.139 59.822 219.694 56.696 227.571 56.696Z"
                fill="#E0E0E0"
            />
            <path
                d="M121.426 173.491L127.729 178.699L138.908 167.561L133.665 161.265C136.579 156.839 138.632 151.904 139.717 146.718L147.832 145.958V130.218L139.717 129.448C138.634 124.265 136.581 119.333 133.665 114.911L138.879 108.615L127.729 97.4764L121.426 102.685C116.996 99.7706 112.056 97.7194 106.864 96.6389L106.102 88.533H90.3354L89.5741 96.6389C84.3831 97.7229 79.4429 99.7739 75.0119 102.685L68.7091 97.4764L57.5682 108.615L62.7821 114.911C59.8628 119.331 57.809 124.263 56.7298 129.448L48.6055 130.218V145.967L56.7298 146.728C57.809 151.911 59.8592 156.843 62.7724 161.265L57.5586 167.551L68.6994 178.69L75.0023 173.481C79.432 176.407 84.3764 178.468 89.5741 179.556L90.3354 187.671H106.141L106.902 179.556C112.083 178.469 117.012 176.411 121.426 173.491ZM76.8816 138.093C76.8797 133.874 78.1303 129.75 80.4752 126.242C82.8201 122.734 86.1539 119.999 90.0549 118.384C93.9559 116.769 98.2489 116.346 102.391 117.168C106.532 117.991 110.337 120.022 113.323 123.005C116.309 125.988 118.342 129.788 119.166 133.925C119.989 138.063 119.565 142.351 117.949 146.248C116.332 150.144 113.594 153.475 110.082 155.817C106.57 158.159 102.442 159.409 98.2188 159.407C92.5606 159.404 87.1349 157.158 83.1339 153.161C79.133 149.165 76.8841 143.745 76.8816 138.093Z"
                fill="#E0E0E0"
            />
            <path
                d="M191.499 280.167C197.681 278.871 203.566 276.428 208.847 272.966L216.345 279.166L229.615 265.909L223.409 258.42C226.879 253.148 229.321 247.268 230.608 241.091L240.245 240.177V221.442L230.608 220.528C229.321 214.351 226.879 208.471 223.409 203.199L229.615 195.71L216.335 182.444L208.837 188.643C203.556 185.181 197.672 182.739 191.49 181.442L190.574 171.815H171.82L170.856 181.442C164.673 182.735 158.788 185.178 153.509 188.643L146.069 182.444L132.798 195.7L139.004 203.19C135.531 208.461 133.086 214.34 131.796 220.518L122.158 221.433V240.205L131.796 241.12C133.086 247.298 135.531 253.177 139.004 258.449L132.798 265.938L146.069 279.195L153.567 272.995C158.846 276.46 164.731 278.903 170.914 280.196L171.878 289.823H190.642L191.499 280.167ZM155.841 230.809C155.841 225.788 157.332 220.88 160.125 216.705C162.918 212.53 166.887 209.276 171.532 207.355C176.176 205.434 181.286 204.931 186.216 205.912C191.146 206.892 195.675 209.311 199.228 212.862C202.782 216.413 205.202 220.938 206.181 225.863C207.161 230.788 206.656 235.893 204.731 240.531C202.806 245.17 199.547 249.134 195.367 251.922C191.186 254.71 186.272 256.198 181.245 256.196C177.904 256.202 174.595 255.55 171.506 254.277C168.418 253.004 165.611 251.135 163.247 248.777C160.883 246.419 159.007 243.618 157.728 240.535C156.449 237.452 155.791 234.147 155.793 230.809H155.841Z"
                fill="#E0E0E0"
            />
            <path
                d="M326.403 125.866H347.133C347.583 125.862 348.024 125.741 348.413 125.515C348.803 125.29 349.127 124.967 349.354 124.579C349.581 124.191 349.704 123.751 349.71 123.302C349.716 122.852 349.605 122.409 349.388 122.015L339.018 104.08C338.792 103.684 338.464 103.355 338.069 103.126C337.674 102.897 337.225 102.776 336.768 102.776C336.311 102.776 335.863 102.897 335.467 103.126C335.072 103.355 334.745 103.684 334.518 104.08L324.158 122.015C323.941 122.408 323.831 122.851 323.836 123.299C323.842 123.748 323.964 124.188 324.19 124.575C324.416 124.963 324.738 125.286 325.126 125.512C325.514 125.738 325.954 125.86 326.403 125.866ZM338.161 108.923L337.708 117.856H335.78L335.308 108.923H338.161ZM335.269 120.927C335.268 120.731 335.306 120.536 335.383 120.354C335.459 120.173 335.571 120.009 335.712 119.872C335.854 119.735 336.022 119.629 336.206 119.559C336.39 119.488 336.586 119.456 336.783 119.464C336.981 119.455 337.179 119.486 337.365 119.555C337.551 119.625 337.722 119.731 337.866 119.868C338.01 120.004 338.124 120.169 338.203 120.351C338.282 120.533 338.323 120.729 338.325 120.927C338.326 121.128 338.287 121.327 338.209 121.511C338.132 121.696 338.017 121.864 337.873 122.003C337.729 122.142 337.558 122.251 337.371 122.323C337.183 122.395 336.983 122.427 336.783 122.42C336.582 122.429 336.381 122.397 336.193 122.326C336.005 122.255 335.833 122.146 335.689 122.006C335.545 121.866 335.43 121.699 335.353 121.513C335.276 121.328 335.238 121.128 335.241 120.927H335.269Z"
                fill="#F0F0F0"
            />
            <path
                d="M23.2587 44.9993H43.9791C44.429 44.9949 44.8701 44.8741 45.2593 44.6485C45.6485 44.423 45.9725 44.1006 46.1997 43.7126C46.4269 43.3247 46.5495 42.8845 46.5555 42.4351C46.5616 41.9857 46.4509 41.5424 46.2342 41.1485L35.7873 23.1653C35.5591 22.7694 35.2306 22.4405 34.8346 22.2119C34.4387 21.9832 33.9895 21.8628 33.5321 21.8628C33.0748 21.8628 32.6256 21.9832 32.2297 22.2119C31.8337 22.4405 31.5052 22.7694 31.277 23.1653L20.9553 41.1004C20.7247 41.4992 20.6043 41.952 20.6065 42.4126C20.6086 42.8731 20.7332 43.3249 20.9676 43.7215C21.2019 44.1182 21.5375 44.4455 21.9401 44.67C22.3426 44.8945 22.7977 45.0082 23.2587 44.9993ZM35.0163 28.0654L34.5537 36.9897H32.6262L32.1444 28.0654H35.0163ZM32.1251 40.0703C32.1224 39.8736 32.1596 39.6785 32.2346 39.4967C32.3096 39.3148 32.4207 39.1501 32.5612 39.0124C32.7018 38.8747 32.8688 38.7669 33.0523 38.6956C33.2357 38.6242 33.4318 38.5908 33.6285 38.5974C33.8328 38.5809 34.0383 38.6068 34.2321 38.6736C34.4259 38.7404 34.6037 38.8465 34.7543 38.9854C34.905 39.1242 35.0252 39.2927 35.1075 39.4803C35.1897 39.6678 35.2322 39.8703 35.2322 40.0751C35.2322 40.2798 35.1897 40.4824 35.1075 40.6699C35.0252 40.8575 34.905 41.026 34.7543 41.1648C34.6037 41.3036 34.4259 41.4098 34.2321 41.4766C34.0383 41.5433 33.8328 41.5693 33.6285 41.5528C33.425 41.569 33.2204 41.5424 33.0278 41.4749C32.8351 41.4074 32.6587 41.3004 32.5099 41.1608C32.3611 41.0212 32.2431 40.8521 32.1636 40.6643C32.084 40.4765 32.0447 40.2742 32.048 40.0703H32.1251Z"
                fill="#F0F0F0"
            />
            <path
                d="M78.8186 265.611H99.539C99.9959 265.611 100.445 265.492 100.841 265.264C101.236 265.035 101.565 264.707 101.794 264.312C102.022 263.917 102.143 263.469 102.143 263.012C102.143 262.556 102.023 262.107 101.794 261.712L91.434 243.777C91.2058 243.381 90.8772 243.052 90.4813 242.824C90.0854 242.595 89.6361 242.475 89.1788 242.475C88.7215 242.475 88.2722 242.595 87.8763 242.824C87.4804 243.052 87.1518 243.381 86.9237 243.777L76.5635 261.712C76.335 262.107 76.2148 262.556 76.2148 263.012C76.2149 263.469 76.3353 263.917 76.5639 264.312C76.7925 264.707 77.1212 265.035 77.517 265.264C77.9128 265.492 78.3617 265.611 78.8186 265.611ZM90.5762 248.668L90.1136 257.601H88.1862L87.7043 248.668H90.5762ZM87.685 260.672C87.6836 260.476 87.7221 260.281 87.7982 260.1C87.8744 259.918 87.9865 259.754 88.128 259.617C88.2694 259.48 88.4372 259.374 88.6211 259.304C88.8051 259.233 89.0014 259.201 89.1981 259.209C89.397 259.2 89.5957 259.231 89.7823 259.3C89.969 259.37 90.1397 259.476 90.2845 259.612C90.4293 259.749 90.5451 259.913 90.625 260.095C90.7049 260.277 90.7473 260.474 90.7497 260.672C90.7499 260.873 90.7094 261.072 90.6307 261.257C90.5521 261.442 90.4368 261.609 90.2919 261.749C90.147 261.888 89.9755 261.997 89.7875 262.068C89.5995 262.14 89.3991 262.172 89.1981 262.165C88.9964 262.175 88.7947 262.144 88.6055 262.074C88.4163 262.003 88.2436 261.895 88.0982 261.755C87.9527 261.615 87.8375 261.447 87.7598 261.261C87.6821 261.074 87.6435 260.874 87.6465 260.672H87.685Z"
                fill="#F0F0F0"
            />
            <path
                d="M272.868 239.667H293.598C294.054 239.665 294.501 239.544 294.896 239.316C295.29 239.087 295.618 238.759 295.845 238.364C296.073 237.969 296.192 237.521 296.192 237.066C296.191 236.61 296.071 236.162 295.843 235.768L285.493 217.833C285.264 217.437 284.936 217.108 284.54 216.879C284.144 216.651 283.695 216.53 283.237 216.53C282.78 216.53 282.331 216.651 281.935 216.879C281.539 217.108 281.21 217.437 280.982 217.833L270.622 235.768C270.394 236.162 270.274 236.61 270.273 237.066C270.273 237.521 270.393 237.969 270.62 238.364C270.848 238.759 271.175 239.087 271.569 239.316C271.964 239.544 272.411 239.665 272.868 239.667ZM284.625 222.723L284.163 231.657H282.235L281.753 222.723H284.625ZM281.734 234.728C281.731 234.532 281.768 234.337 281.844 234.156C281.919 233.975 282.03 233.81 282.171 233.673C282.311 233.537 282.478 233.43 282.662 233.359C282.845 233.289 283.041 233.257 283.237 233.265C283.436 233.256 283.635 233.287 283.822 233.356C284.008 233.425 284.179 233.531 284.324 233.668C284.469 233.804 284.584 233.969 284.664 234.151C284.744 234.333 284.787 234.529 284.789 234.728C284.789 234.929 284.749 235.128 284.67 235.313C284.591 235.498 284.476 235.665 284.331 235.804C284.186 235.944 284.015 236.052 283.827 236.124C283.639 236.195 283.438 236.228 283.237 236.22C283.038 236.228 282.839 236.195 282.652 236.123C282.466 236.052 282.296 235.943 282.153 235.803C282.011 235.663 281.898 235.496 281.823 235.311C281.747 235.126 281.711 234.928 281.715 234.728H281.734Z"
                fill="#F0F0F0"
            />
            <path
                d="M287.776 12.8068H299.254C299.507 12.806 299.755 12.7392 299.973 12.613C300.192 12.4868 300.373 12.3057 300.5 12.0876C300.627 11.8695 300.694 11.6221 300.695 11.3699C300.697 11.1178 300.632 10.8697 300.507 10.6504L294.725 0.724968C294.599 0.504809 294.418 0.321755 294.199 0.194417C293.979 0.0670779 293.73 0 293.477 0C293.223 0 292.974 0.0670779 292.755 0.194417C292.536 0.321755 292.354 0.504809 292.229 0.724968L286.494 10.6504C286.365 10.8714 286.298 11.1228 286.299 11.3786C286.3 11.6344 286.369 11.8853 286.5 12.1053C286.631 12.3253 286.818 12.5063 287.042 12.6297C287.267 12.753 287.52 12.8142 287.776 12.8068ZM294.291 3.43015L294.069 8.37841H293.019L292.749 3.43015H294.291ZM292.672 10.1209C292.671 10.0114 292.691 9.9028 292.733 9.80163C292.775 9.70046 292.837 9.60884 292.916 9.53232C292.994 9.45581 293.087 9.396 293.189 9.35651C293.292 9.31702 293.401 9.29868 293.51 9.3026C293.728 9.3026 293.936 9.38881 294.09 9.54227C294.243 9.69573 294.33 9.90387 294.33 10.1209C294.33 10.3379 294.243 10.5461 294.09 10.6995C293.936 10.853 293.728 10.9392 293.51 10.9392C293.401 10.9431 293.292 10.9248 293.189 10.8853C293.087 10.8458 292.994 10.786 292.916 10.7095C292.837 10.6329 292.775 10.5413 292.733 10.4402C292.691 10.339 292.671 10.2304 292.672 10.1209Z"
                fill="#F0F0F0"
            />
            <path
                d="M1.51684 164.634H12.995C13.2485 164.636 13.4981 164.571 13.7184 164.445C13.9387 164.32 14.1218 164.138 14.249 163.919C14.3763 163.7 14.4433 163.451 14.443 163.198C14.4428 162.945 14.3755 162.696 14.2478 162.477L8.4654 152.552C8.34133 152.331 8.16079 152.148 7.94231 152.02C7.72383 151.893 7.47528 151.825 7.22217 151.825C6.96907 151.825 6.72052 151.893 6.50204 152.02C6.28356 152.148 6.10302 152.331 5.97895 152.552L0.196509 162.477C0.0654134 162.701 -0.00247472 162.956 6.89415e-05 163.216C0.00261261 163.475 0.0754921 163.729 0.210953 163.95C0.346414 164.171 0.539391 164.352 0.769343 164.472C0.999296 164.592 1.25762 164.648 1.51684 164.634ZM8.03171 155.257L7.78115 160.205H6.73067L6.47046 155.257H8.03171ZM6.41263 161.909C6.41125 161.8 6.43215 161.691 6.47405 161.59C6.51594 161.489 6.57797 161.397 6.65637 161.321C6.73477 161.244 6.8279 161.184 6.93013 161.145C7.03236 161.105 7.14155 161.087 7.25109 161.091C7.46835 161.091 7.67671 161.177 7.83034 161.331C7.98396 161.484 8.07026 161.692 8.07026 161.909C8.07026 162.126 7.98396 162.334 7.83034 162.488C7.67671 162.641 7.46835 162.728 7.25109 162.728C7.14155 162.731 7.03236 162.713 6.93013 162.674C6.8279 162.634 6.73477 162.574 6.65637 162.498C6.57797 162.421 6.51594 162.33 6.47405 162.229C6.43215 162.127 6.41125 162.019 6.41263 161.909Z"
                fill="#F0F0F0"
            />
            <path
                d="M221.288 91.2669H232.766C233.019 91.2654 233.267 91.1975 233.486 91.0702C233.705 90.9428 233.886 90.7604 234.012 90.5411C234.138 90.3218 234.204 90.0734 234.203 89.8207C234.203 89.5679 234.136 89.3197 234.009 89.1008L228.275 79.1754C228.15 78.9553 227.968 78.7722 227.749 78.6449C227.53 78.5175 227.281 78.4504 227.027 78.4504C226.774 78.4504 226.525 78.5175 226.305 78.6449C226.086 78.7722 225.905 78.9553 225.779 79.1754L219.997 89.1008C219.868 89.3232 219.801 89.576 219.803 89.8329C219.804 90.0898 219.875 90.3416 220.006 90.5623C220.138 90.783 220.326 90.9647 220.551 91.0885C220.777 91.2124 221.031 91.274 221.288 91.2669ZM227.793 81.8806L227.543 86.8289H226.492L226.232 81.8806H227.793ZM226.184 88.5328C226.183 88.4242 226.203 88.3164 226.245 88.2159C226.286 88.1154 226.347 88.0242 226.424 87.9478C226.502 87.8715 226.594 87.8114 226.695 87.7714C226.796 87.7313 226.904 87.712 227.013 87.7145C227.177 87.7146 227.338 87.7634 227.474 87.8548C227.611 87.9462 227.717 88.076 227.779 88.2279C227.842 88.3797 227.858 88.5467 227.825 88.7075C227.792 88.8684 227.712 89.0159 227.595 89.1313C227.478 89.2467 227.33 89.3248 227.168 89.3556C227.007 89.3865 226.84 89.3687 226.689 89.3046C226.538 89.2404 226.409 89.1328 226.319 88.9954C226.229 88.858 226.182 88.697 226.184 88.5328Z"
                fill="#F0F0F0"
            />
            <path d="M127.391 7.55078H97.5732V27.2379H127.391V7.55078Z" fill="#E0E0E0" />
            <path d="M127.449 7.55078H97.5732V10.7854H127.449V7.55078Z" fill="#F0F0F0" />
            <path d="M62.2322 80.552H32.4141V100.239H62.2322V80.552Z" fill="#E0E0E0" />
            <path d="M62.29 80.552H32.4141V83.7867H62.29V80.552Z" fill="#F0F0F0" />
            <path d="M58.1072 220.682H28.2891V240.369H58.1072V220.682Z" fill="#E0E0E0" />
            <path d="M58.165 220.682H28.2891V223.917H58.165V220.682Z" fill="#F0F0F0" />
            <path d="M287.689 169.948H257.871V189.635H287.689V169.948Z" fill="#E0E0E0" />
            <path d="M287.747 169.948H257.871V173.183H287.747V169.948Z" fill="#F0F0F0" />
            <path d="M353.041 195.488H323.223V215.175H353.041V195.488Z" fill="#E0E0E0" />
            <path d="M353.099 195.488H323.223V198.723H353.099V195.488Z" fill="#F0F0F0" />
            <path d="M342.074 48.3977H312.256V68.0849H342.074V48.3977Z" fill="#E0E0E0" />
            <path d="M342.132 48.3977H312.256V51.6324H342.132V48.3977Z" fill="#F0F0F0" />
            <path
                d="M296.798 309C332.151 309 360.811 307.329 360.811 305.267C360.811 303.205 332.151 301.534 296.798 301.534C261.445 301.534 232.786 303.205 232.786 305.267C232.786 307.329 261.445 309 296.798 309Z"
                fill="#C9C9CE"
            />
            <g clip-path="url(#clip0_1274_137645)">
                <path
                    d="M361.338 199.739C365.867 198.968 370.824 205.573 372.366 214.537V214.253C370.468 203.106 365.251 194.687 360.65 195.47C356.049 196.252 353.903 205.917 355.8 217.063C355.794 217.158 355.794 217.253 355.8 217.348C354.353 208.371 356.808 200.509 361.338 199.739Z"
                    fill="#22C3F3"
                />
                <path
                    d="M366.923 232.597C362.738 233.32 358.22 227.711 356.311 219.779C358.54 229.549 363.283 236.569 367.481 235.858C371.678 235.146 373.836 226.953 372.71 216.992C373.528 225.115 371.121 231.885 366.923 232.597Z"
                    fill="#FAAF1B"
                />
                <path
                    d="M232.657 199.739C228.127 198.968 223.17 205.573 221.629 214.537C221.629 214.442 221.629 214.348 221.629 214.253C223.526 203.106 228.744 194.687 233.344 195.47C237.945 196.252 240.08 205.917 238.182 217.063C238.182 217.158 238.182 217.253 238.182 217.348C239.641 208.371 237.198 200.509 232.657 199.739Z"
                    fill="#FAAF1B"
                />
                <path
                    d="M227.095 232.597C231.292 233.32 235.798 227.711 237.707 219.779C235.49 229.549 230.735 236.569 226.549 235.858C222.363 235.146 220.193 226.953 221.32 216.992C220.478 225.115 222.885 231.885 227.095 232.597Z"
                    fill="#22C3F3"
                />
                <path
                    d="M344.227 193.051C341.359 193.621 338.83 195.295 337.184 197.711C338.328 196.827 339.672 196.237 341.097 195.992C348.911 194.664 357.045 204.292 359.287 217.407C361.528 230.522 357.01 242.308 349.196 243.625C347.767 243.856 346.305 243.746 344.927 243.304C347.285 245.034 350.225 245.774 353.121 245.368C361.694 243.909 366.65 231.02 364.196 216.565C361.741 202.11 352.789 191.592 344.227 193.051Z"
                    fill="#E43383"
                />
                <path
                    d="M249.779 193.051C252.646 193.624 255.175 195.297 256.823 197.711C255.673 196.829 254.326 196.239 252.898 195.992C245.096 194.664 236.949 204.292 234.708 217.407C232.467 230.522 236.997 242.308 244.799 243.625C246.227 243.856 247.69 243.746 249.068 243.304C246.714 245.033 243.778 245.774 240.886 245.368C232.301 243.909 227.344 231.02 229.799 216.565C232.254 202.11 241.194 191.592 249.779 193.051Z"
                    fill="#E43383"
                />
                <path
                    d="M314.925 269.213L296.842 271.229L278.759 269.213C278.38 270.34 268.502 279.221 269.213 300.779C269.259 302.049 269.795 303.252 270.708 304.136C271.62 305.02 272.84 305.517 274.111 305.522C277.763 305.522 282.838 305.522 286.431 305.427C287.601 305.409 288.717 304.931 289.538 304.096C290.359 303.262 290.819 302.139 290.818 300.969C290.817 300.542 290.9 300.118 291.062 299.723C291.225 299.329 291.464 298.97 291.766 298.668C292.068 298.366 292.427 298.126 292.822 297.964C293.217 297.801 293.64 297.718 294.067 297.72H299.629C300.49 297.72 301.317 298.062 301.926 298.671C302.535 299.281 302.878 300.107 302.878 300.969C302.878 302.139 303.338 303.262 304.158 304.096C304.979 304.931 306.095 305.409 307.265 305.427C310.823 305.486 315.921 305.546 319.585 305.522C320.854 305.514 322.071 305.016 322.981 304.132C323.891 303.248 324.425 302.047 324.471 300.779C325.182 279.186 315.305 270.304 314.925 269.213Z"
                    fill="url(#paint0_linear_1274_137645)"
                />
                <path
                    d="M278.76 269.213C278.606 269.688 276.827 271.478 274.847 274.929C289.279 278.581 304.395 278.581 318.827 274.929C316.847 271.502 315.08 269.711 314.926 269.249L296.843 271.265L278.76 269.213Z"
                    fill="url(#paint1_linear_1274_137645)"
                />
                <path
                    d="M354.436 225.221C352.717 216.019 350.44 206.58 345.27 198.683C344.701 197.829 344.084 196.987 343.503 196.181C331.942 181.003 311.605 174.552 293.095 175.608C276.494 176.544 259.502 183.398 249.553 197.201C242.901 206.45 239.759 217.81 239.095 229.075C238.644 236.782 238.632 245.865 243.482 252.435C265.324 282.079 328.633 281.7 350.203 252.435C356.001 244.573 356.167 234.434 354.436 225.221Z"
                    fill="url(#paint2_linear_1274_137645)"
                />
                <path
                    d="M269.179 298.419C269.179 299.178 269.179 299.949 269.179 300.791C269.225 302.061 269.76 303.264 270.673 304.148C271.586 305.032 272.805 305.529 274.076 305.534C276.697 305.534 280.005 305.534 283.041 305.534C271.657 304.906 269.214 298.491 269.179 298.419Z"
                    fill="#22C3F3"
                />
                <path
                    d="M324.506 298.419C324.506 299.178 324.506 299.949 324.506 300.791C324.458 302.06 323.921 303.261 323.009 304.145C322.097 305.028 320.879 305.526 319.609 305.534C316.988 305.534 313.68 305.534 310.645 305.534C322.04 304.906 324.483 298.491 324.506 298.419Z"
                    fill="#22C3F3"
                />
                <path
                    d="M267.625 285.293C268.591 281.103 270.233 277.098 272.486 273.435C272.546 273.332 272.572 273.212 272.559 273.094C272.546 272.975 272.495 272.864 272.414 272.776C272.333 272.689 272.226 272.629 272.109 272.607C271.992 272.585 271.87 272.601 271.763 272.652C269.5 273.683 267.313 274.872 265.217 276.21C263.126 277.503 261.269 279.143 259.727 281.06C257.628 283.929 257.652 289.36 261.565 291.103C264.032 292.218 269.119 291.577 269.119 288.198C269.124 287.729 269.011 287.267 268.792 286.852C268.572 286.438 268.252 286.085 267.862 285.826C267.774 285.771 267.704 285.691 267.662 285.596C267.619 285.501 267.606 285.395 267.625 285.293Z"
                    fill="#7F55A1"
                />
                <path
                    d="M260.428 280.23C263.228 280.659 265.863 281.825 268.064 283.609C268.183 283.194 268.313 282.791 268.432 282.423C266.359 280.853 263.967 279.756 261.424 279.21C261.08 279.518 260.748 279.886 260.428 280.23Z"
                    fill="#22C3F3"
                />
                <path
                    d="M326.072 285.293C325.094 281.106 323.453 277.103 321.21 273.435C321.15 273.332 321.125 273.212 321.138 273.094C321.151 272.975 321.202 272.864 321.283 272.776C321.364 272.689 321.471 272.629 321.588 272.607C321.705 272.585 321.826 272.601 321.934 272.652C324.194 273.681 326.377 274.869 328.467 276.21C330.565 277.5 332.426 279.14 333.97 281.06C336.068 283.929 336.045 289.36 332.12 291.103C329.665 292.218 324.578 291.577 324.578 288.198C324.566 287.736 324.668 287.278 324.875 286.864C325.082 286.451 325.387 286.094 325.764 285.826C325.865 285.782 325.95 285.706 326.005 285.61C326.061 285.514 326.084 285.403 326.072 285.293Z"
                    fill="#7F55A1"
                />
                <path
                    d="M333.258 280.23C330.459 280.663 327.824 281.829 325.621 283.609C325.503 283.194 325.384 282.791 325.254 282.423C327.329 280.848 329.726 279.751 332.274 279.21C332.619 279.533 332.948 279.873 333.258 280.23Z"
                    fill="#22C3F3"
                />
                <path
                    d="M290.482 154.573L290.197 154.08C290.122 153.95 289.955 153.905 289.824 153.981L281.999 158.498C281.869 158.574 281.824 158.741 281.899 158.871L282.184 159.364C282.259 159.494 282.426 159.539 282.557 159.464L290.382 154.946C290.512 154.871 290.557 154.704 290.482 154.573Z"
                    fill="white"
                />
                <path
                    d="M280.871 160.714C281.224 160.714 281.511 160.428 281.511 160.074C281.511 159.721 281.224 159.434 280.871 159.434C280.517 159.434 280.23 159.721 280.23 160.074C280.23 160.428 280.517 160.714 280.871 160.714Z"
                    fill="white"
                />
                <path
                    d="M294.531 261.15C284.638 261.262 274.769 260.155 265.147 257.854C257.854 256.04 253.55 252.814 252.34 248.273C249.506 237.708 251.558 222.956 260.926 215.201C266.507 210.585 273.299 207.675 280.491 206.818C291.352 205.427 302.346 205.427 313.207 206.818C320.399 207.674 327.191 210.585 332.772 215.201C342.14 222.945 344.179 237.731 341.357 248.273C340.171 252.471 336.246 255.589 329.499 257.534C319.586 260.403 308.155 260.961 297.211 261.091C296.321 261.091 295.432 261.091 294.543 261.091L294.531 261.15Z"
                    fill="#9863A9"
                />
                <path
                    d="M294.744 257.771C285.732 257.869 276.74 256.898 267.957 254.877C261.317 253.288 257.392 250.466 256.289 246.47C253.716 237.209 255.566 224.26 264.115 217.466C269.24 213.401 275.402 210.854 281.902 210.114C291.8 208.881 301.814 208.881 311.712 210.114C318.211 210.858 324.372 213.405 329.499 217.466C338.037 224.26 339.899 237.209 337.325 246.47C336.294 250.158 332.665 252.885 326.511 254.605C317.487 257.107 307.041 257.605 297.092 257.759L294.744 257.771Z"
                    fill="#121212"
                />
                <path
                    d="M294.743 255.411C285.916 255.523 277.107 254.583 268.502 252.613C262.774 251.237 259.419 248.972 258.565 245.877C256.193 237.352 257.853 225.506 265.585 219.351C270.368 215.558 276.12 213.184 282.186 212.498C291.884 211.296 301.693 211.296 311.392 212.498C317.477 213.178 323.249 215.553 328.052 219.351C335.783 225.506 337.431 237.352 335.06 245.877C334.277 248.711 331.194 250.881 325.906 252.352C317.131 254.794 306.933 255.269 297.079 255.423H294.708L294.743 255.411Z"
                    fill="#17171A"
                />
                <path
                    d="M333.258 212.841C327.283 208.089 320.093 205.11 312.507 204.245C302.104 202.952 291.581 202.952 281.178 204.245C273.594 205.115 266.405 208.093 260.427 212.841C250.253 220.928 247.253 236.035 250.585 248.059C252.649 255.459 259.621 258.957 266.57 260.629C276.635 262.923 286.949 263.943 297.27 263.664C307.408 263.51 318.365 263.024 328.183 260.296C334.669 258.494 341.227 254.96 343.1 248.059C346.444 236.012 343.432 220.928 333.258 212.841ZM339.685 247.111C338.416 251.64 334.23 254.937 327.234 256.881C317.95 259.455 307.313 259.964 297.21 260.119H294.744C285.544 260.216 276.365 259.221 267.4 257.154C259.87 255.352 255.364 251.96 254.012 247.087C251.226 237.067 253.289 223.027 262.633 215.592C268.098 211.252 274.671 208.532 281.605 207.743C291.705 206.494 301.921 206.494 312.021 207.743C318.955 208.532 325.528 211.252 330.993 215.592C340.396 223.051 342.46 237.091 339.744 247.111H339.685Z"
                    fill="url(#paint3_linear_1274_137645)"
                />
                <path
                    d="M281.641 151.193C280.242 152.047 278.819 152.877 277.431 153.754C276.877 154.09 276.42 154.567 276.107 155.135C275.795 155.704 275.637 156.345 275.65 156.993C275.663 157.642 275.847 158.276 276.182 158.831C276.518 159.386 276.994 159.844 277.562 160.157L291.886 169.24C293.418 170.258 295.217 170.799 297.056 170.794C298.871 170.818 300.655 170.315 302.191 169.347L312.471 163.157L316.55 160.703C317.144 160.4 317.645 159.944 318.001 159.381C318.358 158.818 318.556 158.17 318.575 157.504C318.595 156.838 318.434 156.179 318.112 155.597C317.789 155.014 317.315 154.529 316.74 154.193L305.701 147.173L301.527 149.663C300.951 149.982 300.471 150.45 300.137 151.017C299.803 151.584 299.627 152.23 299.627 152.888C299.627 153.547 299.803 154.193 300.137 154.76C300.471 155.327 300.951 155.795 301.527 156.114L303.341 157.3L297.127 161.011L292.515 158.082L290.938 157.086L295.479 154.442C296.013 154.117 296.456 153.661 296.767 153.118C297.077 152.576 297.245 151.963 297.255 151.338C297.264 150.712 297.115 150.095 296.821 149.543C296.527 148.991 296.098 148.522 295.574 148.181L291.329 145.501L281.712 151.24"
                    fill="url(#paint4_linear_1274_137645)"
                />
                <path
                    opacity="0.47"
                    d="M292.112 149.365L291.608 148.493C291.471 148.254 291.166 148.173 290.928 148.31L283.473 152.615C283.234 152.752 283.153 153.057 283.29 153.295L283.794 154.168C283.932 154.406 284.236 154.488 284.475 154.35L291.93 150.046C292.168 149.908 292.25 149.604 292.112 149.365Z"
                    fill="white"
                />
                <path
                    opacity="0.47"
                    d="M281.605 156.197C282.286 156.197 282.839 155.645 282.839 154.963C282.839 154.282 282.286 153.73 281.605 153.73C280.924 153.73 280.372 154.282 280.372 154.963C280.372 155.645 280.924 156.197 281.605 156.197Z"
                    fill="white"
                />
                <path
                    d="M261.305 186.209C261.388 186.292 270.245 194.509 273.969 205.513C274.032 205.696 274.151 205.854 274.308 205.967C274.465 206.079 274.653 206.14 274.846 206.142H275.143C275.259 206.103 275.367 206.042 275.46 205.961C275.553 205.88 275.628 205.781 275.682 205.671C275.736 205.56 275.768 205.44 275.775 205.318C275.782 205.195 275.765 205.072 275.724 204.956C272 193.988 263.593 185.865 262.68 184.976L261.139 185.984C261.181 186.068 261.237 186.144 261.305 186.209Z"
                    fill="white"
                />
                <path
                    d="M318.863 206.142H319.16C319.353 206.141 319.541 206.079 319.698 205.967C319.855 205.854 319.973 205.696 320.037 205.514C323.76 194.521 332.618 186.292 332.701 186.209C332.755 186.158 332.799 186.098 332.832 186.031C332.322 185.675 331.812 185.332 331.29 185C329.998 186.185 321.911 194.225 318.246 204.921C318.2 205.041 318.18 205.17 318.187 205.298C318.193 205.427 318.227 205.553 318.285 205.668C318.343 205.783 318.424 205.884 318.524 205.966C318.623 206.048 318.739 206.108 318.863 206.142Z"
                    fill="white"
                />
                <path
                    d="M297.163 250.146C304.792 250.146 310.977 243.961 310.977 236.332C310.977 228.702 304.792 222.518 297.163 222.518C289.534 222.518 283.349 228.702 283.349 236.332C283.349 243.961 289.534 250.146 297.163 250.146Z"
                    fill="#121212"
                />
                <path
                    d="M297.163 248.19C304.792 248.19 310.977 242.005 310.977 234.375C310.977 226.746 304.792 220.561 297.163 220.561C289.534 220.561 283.349 226.746 283.349 234.375C283.349 242.005 289.534 248.19 297.163 248.19Z"
                    fill="#EF463E"
                />
                <path
                    d="M301.04 233.996C301.04 237.897 299.593 240.055 297.056 240.055C294.815 240.055 293.297 237.945 293.261 234.126C293.226 230.308 294.921 228.198 297.246 228.198C299.57 228.198 301.04 230.344 301.04 233.996ZM294.815 234.174C294.815 237.15 295.728 238.846 297.186 238.846C298.645 238.846 299.558 236.996 299.558 234.103C299.558 231.209 298.834 229.431 297.186 229.431C295.799 229.395 294.815 231.055 294.815 234.174Z"
                    fill="white"
                />
                <path d="M299.167 285.708H294.53V288.613H299.167V285.708Z" fill="white" />
                <path d="M299.167 282.115H294.53V285.02H299.167V282.115Z" fill="white" />
                <path d="M299.167 289.301H294.53V292.206H299.167V289.301Z" fill="white" />
                <path
                    d="M304.479 284.238C304.479 283.675 304.256 283.135 303.858 282.737C303.459 282.339 302.92 282.115 302.357 282.115H299.843V285.02H304.479V284.238Z"
                    fill="white"
                />
                <path
                    d="M291.329 292.206H293.842V289.301H289.206V290.083C289.206 290.646 289.43 291.186 289.828 291.584C290.226 291.982 290.766 292.206 291.329 292.206Z"
                    fill="white"
                />
                <path d="M304.491 285.708H299.854V288.613H304.491V285.708Z" fill="white" />
                <path
                    d="M302.357 292.206C302.92 292.206 303.459 291.982 303.858 291.584C304.256 291.186 304.479 290.646 304.479 290.083V289.301H299.843V292.206H302.357Z"
                    fill="white"
                />
                <path
                    d="M291.329 282.115C290.766 282.115 290.226 282.339 289.828 282.737C289.43 283.135 289.206 283.675 289.206 284.238V285.02H293.842V282.115H291.329Z"
                    fill="white"
                />
                <path d="M293.842 285.708H289.206V288.613H293.842V285.708Z" fill="white" />
                <path
                    d="M282.245 212.616C276.179 213.302 270.428 215.677 265.644 219.47C257.913 225.624 256.253 237.47 258.624 245.996C258.768 246.487 258.975 246.958 259.241 247.395C260.854 249.032 263.498 250.312 267.21 251.202C268.775 251.581 270.387 251.913 271.953 252.21L274.324 224.284C274.47 222.606 275.03 220.99 275.952 219.581C276.875 218.172 278.133 217.014 279.613 216.209L287.107 212.118C285.494 212.296 283.846 212.403 282.245 212.616Z"
                    fill="url(#paint5_linear_1274_137645)"
                />
            </g>
            <path
                d="M282 234C282 225.713 288.713 219 297 219C305.287 219 312 225.713 312 234C312 242.288 305.287 249 297 249C288.713 249 282 242.287 282 234Z"
                fill="white"
            />
            <path
                d="M282 234C282 225.713 288.713 219 297 219C305.287 219 312 225.713 312 234C312 242.288 305.287 249 297 249C288.713 249 282 242.287 282 234Z"
                fill="#EA6A46"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M297 241.874C298.036 241.874 298.875 241.034 298.875 239.999C298.875 238.963 298.036 238.124 297 238.124C295.964 238.124 295.125 238.963 295.125 239.999C295.125 241.034 295.964 241.874 297 241.874ZM297 235.874C298.036 235.874 298.875 235.034 298.875 233.999L298.875 227.999C298.875 226.963 298.036 226.124 297 226.124C295.964 226.124 295.125 226.963 295.125 227.999V233.999C295.125 235.034 295.964 235.874 297 235.874Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1274_137645"
                    x1="296.878"
                    y1="294.316"
                    x2="273.826"
                    y2="244.015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="0.51" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1274_137645"
                    x1="296.831"
                    y1="273.399"
                    x2="296.831"
                    y2="282.293"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#60388C" />
                    <stop offset="0.62" stop-color="#3F2356" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1274_137645"
                    x1="313.882"
                    y1="262.348"
                    x2="284.392"
                    y2="206.782"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="1" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1274_137645"
                    x1="265.692"
                    y1="217.703"
                    x2="343.373"
                    y2="262.051"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stop-color="white" />
                    <stop offset="1" stop-color="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1274_137645"
                    x1="305.155"
                    y1="164.794"
                    x2="263.356"
                    y2="124.857"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="0.67" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_1274_137645"
                    x1="283.146"
                    y1="218.948"
                    x2="263.854"
                    y2="241.288"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.25" stop-color="#353537" />
                    <stop offset="1" stop-color="#17171A" />
                </linearGradient>
                <clipPath id="clip0_1274_137645">
                    <rect width="152.006" height="160.104" fill="white" transform="translate(221 145.43)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ErrorSVG);
