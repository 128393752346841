import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getAvatarUrl } from 'utils/images';
import { formatDateFromNow } from 'utils/datetime';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { RESOLVED } from 'const/commentStatus';
import CommentNotifySVG from 'assets/images/svg/CommentNotifySVG';
// import * as gridUIActions from '../actions';
// import { useDispatch } from 'react-redux';
import hexToRgba from 'hex-to-rgba';
// import Spinner from 'components/spinner/Base';
import { useMetaData } from 'hooks/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import ColumnTypeDisplay from '../ColumnTypeDisplay';
import { TEXT_LINE_HEIGHT } from 'const/gridUI';
import { SYSTEM_COLUMN_IDS } from 'const';
import * as columnTypes from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    avatar: {
        marginRight: SPACING_LIST_NAME_WITH_ICON,
        display: 'flex'
    },
    expand: {
        flex: 1
    },
    dlFlex: {
        display: 'flex'
    },
    overlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 10,
        background: hexToRgba(theme.colors.white, 0.8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    total: {
        color: theme.colors.midGrey,
        fontSize: 13
    },
    commentContent: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    column: {
        '& *': {
            fontSize: 13,
            color: theme.colors.lightGreyBlue
        },
        '& img': {
            width: `20px !important`,
            height: `14px !important`
        },
        '& svg': {
            width: 12
        }
    },
    normal: {
        '& svg': {
            '& path': {
                fill: theme.colors.lightGreyBlue
            }
        }
    },
    content: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    fw500: {
        fontWeight: 500
    }
}));

function CommentItem({ comment, compositeViewId, onClick }) {
    const classes = useStyles();
    // const dispatch = useDispatch();
    const theme = useTheme();
    const thread = comment?.thread;
    const total = comment?.total || 0;
    const imageUrl = thread?.imageUrl;
    const createdBy = thread?.createdBy;
    const fullName = thread?.fullName;
    const status = thread?.status;
    const createdAt = thread?.createdAt;
    const threadId = thread?.id;
    const content = thread?.content;
    const metaData = useMetaData();
    const columnDetail = React.useMemo(() => {
        return metaData?.[thread?.columnId];
    }, [thread, metaData]);

    const columnType = React.useMemo(() => {
        return getCorrectColumnType(columnDetail);
    }, [columnDetail]);

    return (
        <Grid container className={classes.root} onClick={() => onClick(comment)}>
            <Grid container direction="row" alignItems="flex-start" justify="flex-start">
                <Grid item className={classes.avatar}>
                    {
                        <AvatarBase src={getAvatarUrl(imageUrl || createdBy)} size="small" alt="avatar">
                            <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                        </AvatarBase>
                    }
                </Grid>
                <Grid item className={classes.expand}>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item>
                                    <p className="body1 inline">{fullName}</p>{' '}
                                    <p className="caption inline">{formatDateFromNow(createdAt)}</p>
                                </Grid>

                                <Grid item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item className={classes.dlFlex}>
                                            <CommentNotifySVG
                                                width={16}
                                                height={16}
                                                color={
                                                    status !== RESOLVED ? theme.colors.crusta : theme.colors.atlantis
                                                }
                                            />
                                        </Grid>
                                        <Grid item className={classes.fw500}>
                                            {threadId ? `#${threadId}` : `NA`}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={`${classes.column} ${
                                ![...SYSTEM_COLUMN_IDS, columnTypes.RECORD_ID].includes(columnType)
                                    ? classes.normal
                                    : ``
                            }`}
                        >
                            <ColumnTypeDisplay
                                name={columnDetail?.name}
                                group={columnDetail?.group}
                                type={columnType}
                                customProperties={columnDetail?.customProperties}
                            />
                        </Grid>
                        <Grid item className={classes.commentContent}>
                            <pre className={'body2 break-words whitespace-pre-wrap'}>{content}</pre>
                        </Grid>
                        <Grid item>
                            {total > 0 && (
                                <p className="body1 text-grey-mid">
                                    {total} {total > 1 ? `replies` : `reply`}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(CommentItem);
