import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import ListItem from 'components/list/Item';
import SearchSVG from 'assets/images/svg/SearchSVG';
import { useTranslation } from 'react-i18next';

const FindInThisRange = ({ onClose, ...rest }) => {
    const { t } = useTranslation();

    const searchRanges = useCallback(
        e => {
            e.stopPropagation();
            const resetSearchRangeEl = document.getElementById('reset_find_range');
            if (!resetSearchRangeEl) {
                const searchEl = document.getElementById('search_record');
                if (searchEl) {
                    searchEl.click();
                }
            } else {
                resetSearchRangeEl.click();
            }
            onClose && onClose();
        },
        [onClose]
    );

    return (
        <Grid item onClick={searchRanges} {...rest}>
            <ListItem icon={<SearchSVG color="#78778B" width={16} height={16} />} name={t(`find_in_this_range`)} />
        </Grid>
    );
};

export default React.memo(FindInThisRange);
