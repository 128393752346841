import { Text } from 'slate';
import TYPES from 'components/editor/markdown/types';

const _serializeNode = node => {
    if (Text.isText(node)) {
        return node.text;
    }

    const children = node?.children
        ?.map(n => {
            return _serializeNode(n);
        })
        .join('');

    switch (node.type) {
        case 'func':
            return `${node.suggestion.value}`;
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'tag':
            return `${node.suggestion.value}`;
        case 'mention':
            return `@{${node.user.id}}`;
        default:
            return children;
    }
};

export const serialize = nodes => {
    return nodes.map(node => _serializeNode(node)).join('\n');
};

function _serializeNodeTag(node) {
    if (Text.isText(node)) {
        return node.text;
    }

    const children = node.children.map(n => _serializeNodeTag(n)).join('');
    switch (node.type) {
        case TYPES.P:
            return `${children}`;
        default:
            return children;
    }
}
export const serializeTag = nodes => {
    return nodes.map(node => _serializeNodeTag(node)).join('\n');
};

export const getEditorValueBySplitLineBreak = str => {
    if (str) {
        const array = str.split(/\n/gm);
        return array.reduce((acc, cur) => {
            acc.push({ type: 'paragraph', children: [{ text: cur || '' }] });
            return acc;
        }, []);
    }
    return [{ type: 'paragraph', children: [{ text: str || '' }] }];
};
