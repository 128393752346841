import * as types from '../types';
import { PREVIEW_IMAGE_TYPES } from 'const/gridUI';

const handler = {
    [types.OPEN_CELL_FILE_PREVIEW](state, { payload }) {
        const { rowId, columnId, defaultPreviewFile, type } = payload;
        return {
            ...state,
            cellPreview: {
                rowId,
                columnId,
                defaultPreviewFile,
                type: type || PREVIEW_IMAGE_TYPES.DEFAULT
            }
        };
    },
    [types.CLOSE_CELL_FILE_PREVIEW](state) {
        return {
            ...state,
            cellPreview: null
        };
    }
};

export default handler;
