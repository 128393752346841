import React from 'react';
import PartialAccessSVG from 'assets/images/svg/apiKeys/PartialAccessSVG';
import FullAccessSVG from 'assets/images/svg/apiKeys/FullAccessSVG';
import { API_KEY_TYPES } from 'const';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';

function StatusIcon({ status = '', ...rest }) {
    switch (status) {
        case API_KEY_TYPES.FULL:
            return <FullAccessSVG {...rest} />;
        case API_KEY_TYPES.RESTRICTED:
            return <PartialAccessSVG {...rest} />;
        case API_KEY_TYPES.READONLY:
            return <EyeOpenIconSVG {...rest} />;
        default:
            return null;
    }
}

export default React.memo(StatusIcon);
