import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ListItem from 'components/list/Item';
import DependencySVG from 'assets/images/svg/DependencySVG';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import theme from 'theme';
import { DEPENDENCY_STATUS } from 'const/gridUI';
import i18n from 'i18n';
import * as columnTypes from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const DEPENDENCY_OPTIONS = [
    {
        value: DEPENDENCY_STATUS.UP_TO_DATE,
        label: i18n.t(`dependency_label_up_to_date`),
        icon: <DependencySVG color={theme.colors.atlantis} />,
        subField: '_dependencyStatus'
    },
    {
        value: DEPENDENCY_STATUS.OUT_OF_DATE,
        label: i18n.t(`dependency_label_out_of_date`),
        icon: <DependencySVG color={theme.colors.sun} />,
        subField: '_dependencyStatus'
    },
    {
        value: DEPENDENCY_STATUS.UNSET,
        label: i18n.t('dependency_label_unset'),
        icon: <DependencySVG color={theme.colors.brightRed} />,
        subField: '_dependencyStatus'
    }
];

function ExtraFilterMenu({ defaultValue, onChange, subField, columnType }) {
    const classes = useStyles();

    console.log('columnType', columnType);

    const dependencyOptions = React.useMemo(() => {
        return columnType === columnTypes.TRANSLATION
            ? DEPENDENCY_OPTIONS.concat({
                  value: true,
                  label: i18n.t(`tm_label_filled`),
                  icon: <TMStatusAprrovedSVG />,
                  subField: '_tm'
              })
            : DEPENDENCY_OPTIONS;
    }, [columnType]);

    return (
        <Grid container direction="column" className={classes.root}>
            {dependencyOptions?.map(option => {
                return (
                    <Grid
                        item
                        key={option?.value}
                        onClick={() => onChange({ value: option?.value, subField: option?.subField })}
                    >
                        <ListItem
                            name={option?.label}
                            isSelected={subField && defaultValue === option?.value}
                            icon={option?.icon}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(ExtraFilterMenu);
