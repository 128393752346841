import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha, Grid, Typography, Tab, Tabs } from '@material-ui/core';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
// import { useCurrentUser } from 'hooks/auth';
// import MembersWithAccess from './MembersWithAccess';
import Share from './Share';
import Public from './Public';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAdvancedSearchApi } from 'services/advanced';
import { enqueueSnackbar } from 'notifier/actions';
import Spinner from 'components/spinner/Base';
import NotFoundIllustrationSVG from 'assets/images/svg/NotFoundIllustrationSVG';

const useStyles = makeStyles(theme => ({
    root: {
        width: 600,
        minHeight: 200,
        padding: 0,
        position: 'relative',
        '&:focus': {
            outline: 'none'
        }
    },

    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        },
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? alpha(props.buttonColor, 0.9) : '')
            }
        }
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    spinner: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: alpha(theme.palette.common.white, 0.5),
        zIndex: 2
    },
    p3: {
        padding: theme.spacing(3)
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));

const TABS = {
    SHARE: 'SHARE',
    MEMBER_WITH_ACCESS: 'MEMBER_WITH_ACCESS',
    PUBLIC: 'PUBLIC'
};

function ShareWrapper({ onClose, advancedSearchId, isShared }) {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const [tabValue, setTabValue] = React.useState(TABS.SHARE);
    const [advancedSearch, setAdvancedSearch] = React.useState(null);
    const [isFetching, setIsFetching] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);

    const handleChangeTab = React.useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    const fetchDetail = React.useCallback(async () => {
        try {
            setIsFetching(true);
            const res = await getAdvancedSearchApi({ wsId: workspaceId, advancedSearchId });
            setAdvancedSearch(res);
            setIsFetching(false);
        } catch ({ message }) {
            setIsFetching(false);
            setHasError(true);
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    }, [dispatch, advancedSearchId, workspaceId]);

    React.useEffect(() => {
        if (advancedSearchId) {
            fetchDetail();
        }
    }, [advancedSearchId, fetchDetail]);

    const handleUpdatePublicId = React.useCallback(({ publicId }) => {
        setAdvancedSearch(prev => ({
            ...prev,
            access: {
                ...prev?.access,
                publicId
            }
        }));
    }, []);

    return (
        <>
            <DialogTitle
                title={
                    <Typography variant="h4" component="div">
                        Share query
                    </Typography>
                }
                onClose={onClose}
            />
            <DialogContent className={classes.root}>
                {isFetching && (
                    <Grid
                        className={classes.spinner}
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <Spinner size={40} thick={4} />
                        </Grid>
                    </Grid>
                )}
                {hasError ? (
                    <Grid
                        className={`${classes.root} ${classes.p3}`}
                        direction="column"
                        spacing={3}
                        container
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <NotFoundIllustrationSVG />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.fw400} variant="h3">
                                Oops! Looks like you get lose
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" className={classes.disabledText}>
                                Sorry we can't get your share setting.
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column">
                        <Grid item className={classes.mb3}>
                            <Tabs className={classes.tabs} value={tabValue} onChange={handleChangeTab}>
                                <Tab classes={tabClasses} value={TABS.SHARE} label="Share" />
                                {/* <Tab classes={tabClasses} value={TABS.MEMBER_WITH_ACCESS} label="Member with access" /> */}
                                {!isShared && <Tab classes={tabClasses} value={TABS.PUBLIC} label="Public link" />}
                            </Tabs>
                        </Grid>
                        <Grid item className={classes.tabContent}>
                            {tabValue === TABS.SHARE && <Share {...advancedSearch} onClose={onClose} />}
                            {/* {tabValue === TABS.MEMBER_WITH_ACCESS && <MembersWithAccess userId={userId} />} */}
                            {tabValue === TABS.PUBLIC && !isShared && (
                                <Public {...advancedSearch} updatePublicId={handleUpdatePublicId} onClose={onClose} />
                            )}
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </>
    );
}

export default ShareWrapper;
