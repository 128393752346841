import React from 'react';
import Grid from '@material-ui/core/Grid';
import { COLUMN, RECORD, GRID } from 'const/gridUI';
import ColumnDiff from './ColumnDiff';
import RecordDiff from './RecordDiff';
import GridDiff from './GridDiff';

function getDiffByType({ objectType, ...rest }) {
    switch (objectType) {
        case COLUMN:
            return <ColumnDiff {...rest} />;
        case RECORD:
            return <RecordDiff {...rest} />;
        case GRID:
            return <GridDiff {...rest} />;

        default:
            return null;
    }
}

function Diff({ objectType, action, extraJson, t }) {
    return (
        <Grid container>
            {getDiffByType({
                objectType,
                action,
                extraJson,
                t
            })}
        </Grid>
    );
}

export default React.memo(Diff);
