import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { CELL_COMMENT_HEIGHT, COMMENT_HEADER_HEIGHT, COMMENT_CHAT } from 'const/style';
import CommentNotifySVG from 'assets/images/svg/CommentNotifySVG';
import { RESOLVED } from 'const/commentStatus';
import IOSSwitch from 'components/switches/IOS';
import ReplyBox from 'components/cellComment/ReplyBox';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import CommentList from './CommentList';

const useStyles = makeStyles(theme => ({
    root: {},
    header: {
        height: COMMENT_HEADER_HEIGHT,
        padding: `0px 16px`,
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    commentIcon: {
        display: 'flex',
        marginRight: theme.spacing(1)
    },
    fullWidth: {
        width: '100%'
    },
    commentDashboard: {
        position: 'relative',
        height: CELL_COMMENT_HEIGHT
    },
    replyBox: {
        borderTop: `1px solid ${theme.colors.divider}`,
        position: 'absolute',
        background: theme.colors.white,
        bottom: 0,
        left: 0,
        width: '100%'
    }
}));

function CellComment({ commentData, saveHandler, cancelHandler, updateThreadStatusHandler, currentUser, t }) {
    const { comments = [], thread } = commentData;
    const threadStatus = thread?.status;
    const threadId = thread?.id;
    const isChecked = threadStatus === RESOLVED ? true : false;
    const COMMENT_LIST_HEIGHT = isChecked
        ? CELL_COMMENT_HEIGHT - COMMENT_HEADER_HEIGHT
        : CELL_COMMENT_HEIGHT - (COMMENT_CHAT + COMMENT_HEADER_HEIGHT);
    const classes = useStyles();
    const theme = useTheme();

    const dispatch = useDispatch();

    // React.useEffect(() => {
    //     scrollToElementIfneeded();
    // }, []);

    const handleSwitch = e => {
        updateThreadStatusHandler(e.target.checked);
    };

    const updateThreadHandler = ({ commentId, oldContent, newContent, editedAt, callback }) => {
        dispatch(
            gridActions.updateCellThreadComment({
                threadId,
                commentId,
                oldContent,
                newContent,
                editedAt,
                errorCallback: () => {
                    console.log('update failed');
                }
            })
        );
        callback && callback();
    };

    const deleteThreadHandler = () => {
        console.log('delete thread comment');
    };

    const updateCommentHandler = ({ commentId, oldContent, newContent, editedAt, callback }) => {
        dispatch(
            gridActions.updateCellComment({
                threadId,
                commentId,
                oldContent,
                newContent,
                editedAt,
                errorCallback: () => {
                    console.log('update failed');
                }
            })
        );
        callback && callback();
    };

    const deleteCommentHandler = ({ commentId }) => {
        dispatch(gridActions.deleteCellComment({ commentId, threadId }));
    };

    return (
        <Grid container wrap="nowrap" className={`${classes.root} ${classes.commentDashboard}`} direction="column">
            <Grid item className={classes.fullWidth}>
                <Grid container className={classes.header} direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item className={classes.commentIcon}>
                                <CommentNotifySVG
                                    width={16}
                                    height={16}
                                    color={thread?.status !== RESOLVED ? theme.colors.crusta : theme.colors.atlantis}
                                />
                            </Grid>
                            <Grid item>{threadId ? `#${threadId}` : `NA`}</Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>{t(`global_resolved`)}</Grid>
                            <Grid item style={{ marginLeft: 4 }}>
                                <IOSSwitch checked={isChecked} onChange={handleSwitch} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidth}>
                <CommentList
                    comments={comments}
                    updateCommentHandler={updateCommentHandler}
                    updateThreadHandler={updateThreadHandler}
                    deleteThreadHandler={deleteThreadHandler}
                    currentUser={currentUser}
                    thread={thread}
                    deleteCommentHandler={deleteCommentHandler}
                    COMMENT_LIST_HEIGHT={COMMENT_LIST_HEIGHT}
                    t={t}
                />
            </Grid>

            {!isChecked && (
                <Grid item className={classes.replyBox}>
                    <ReplyBox
                        autoFocus={true}
                        saveHandler={saveHandler}
                        cancelHandler={cancelHandler}
                        isHideAction={true}
                        t={t}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(CellComment);
