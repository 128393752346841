import React from 'react';
import { ROW_HEIGHT } from 'const/gridUI';
import DOMPurify from 'dompurify';

function Richtext({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex, filterValue, isCaseSensitive }) {
    let re;
    try {
        re = new RegExp(filterValue, !!isCaseSensitive ? 'g' : 'gi');
    } catch (error) {
        re = filterValue;
    }

    let html = filterValue ? value && value.replace(re, match => `<span class="highlight">${match}</span>`) : value;
    return (
        <p
            className={`body2 cell_${rowIndex}_${columnIndex} pointer-events-none h-[${rowHeight}px] leading-normal w-full overflow-hidden text-ellipsis align-top text-left table-cell break-words whitespace-pre-wrap  editor`}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }) }}
        />
    );
}

export default React.memo(Richtext);
