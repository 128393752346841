import * as types from './types';

const initialState = {
    version: true, // has been used true from starting. Next update, when a new addon added, it should be increased to 1, 2,...
    integrations: [],
    isFetching: false,
    isEnableAddon: false
};

export default function Addons(state = initialState, { payload, type }) {
    switch (type) {
        case types.FETCH_ADD_ON_LIST: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.FETCH_ADD_ON_LIST_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.FETCH_ADD_ON_LIST_SUCCESS: {
            const { addons } = payload;

            return {
                ...state,
                integrations: addons,
                isFetching: false
            };
        }

        case types.CHANGE_ADD_ON_STATE: {
            const { addonState, addonId } = payload;
            const { integrations } = state;

            const newIntegrations = integrations?.map(addon => {
                if (addonId === addon?.id)
                    return {
                        ...addon,
                        state: addonState
                    };

                return addon;
            });

            return {
                ...state,
                integrations: newIntegrations
            };
        }

        default:
            return state;
    }
}
