import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Drawer, Paper } from '@material-ui/core';
import { HEADER_HEIGHT, DRAWER_WIDTH, SIDEBAR_RIGHT_WIDTH } from 'const';
import Unicorn from 'common/Unicorn';
import Header from './Header';
import useMediaQueries from '../hooks/useMediaQueries';
import RightSidebar from './rightSidebar';
import { useRightSidebarBody, useRightSidebarType, useCompanyInfo } from 'hooks/app';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0
    },
    drawerPaper: {
        marginTop: HEADER_HEIGHT,
        width: DRAWER_WIDTH,
        border: 'none',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    wrapper: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: props => (props.isMobile ? 0 : DRAWER_WIDTH),
        width: props => (props.isMobile ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`)
    },
    wrapperShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    fixedHeader: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 100
    },
    content: {
        marginTop: HEADER_HEIGHT,
        background: theme.colors.background
    },
    contentFormat: {
        height: `calc(100vh - ${HEADER_HEIGHT + theme.spacing(3) * 2}px)`,
        background: 'transparent'
    },
    divider: {
        width: '100%',
        position: 'relative',
        top: -1
    },
    rightDrawer: {
        zIndex: 99,
        '& .MuiDrawer-paper': {
            width: SIDEBAR_RIGHT_WIDTH,
            top: HEADER_HEIGHT,
            overflowY: 'hidden',

            [theme.breakpoints.down('xs')]: {
                width: 'inherit'
            }
        }
    },
    expand: {
        flex: 1,
        overflow: 'hidden'
    }
}));

function MainLayout({ leftContentRender, children, forcedLeftContent = false }) {
    const { isMobile, isTablet } = useMediaQueries();
    const [isOpen, setIsOpen] = useState(true);
    const classes = useStyles({ isMobile });
    const type = useRightSidebarType();
    const body = useRightSidebarBody();
    const company = useCompanyInfo();

    useEffect(() => {
        setIsOpen(isMobile ? false : true);
    }, [isMobile]);

    return (
        <Grid container direction={'column'}>
            {company?.name && (
                <Helmet>
                    <title>{`${company.name} | Gridly`}</title>
                </Helmet>
            )}
            <Grid item className={classes.fixedHeader}>
                <Header onToggleDrawer={() => setIsOpen(!isOpen)} isShowToggle={isMobile} isTablet={isTablet} />
            </Grid>
            <Drawer
                className={classes.drawer}
                variant={isMobile ? 'temporary' : 'persistent'}
                anchor="left"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                classes={{
                    paper: classes.drawerPaper
                }}
                style={{
                    zIndex: !isMobile ? 98 : 100
                }}
            >
                <Grid container direction="column" style={{ height: '100%', overflow: 'hidden' }} wrap="nowrap">
                    {!forcedLeftContent && (
                        <Grid item>
                            <Unicorn />
                        </Grid>
                    )}
                    <Grid item className={classes.expand}>
                        {leftContentRender}
                    </Grid>
                </Grid>
            </Drawer>
            <Grid className={`${classes.wrapper} ${isOpen ? classes.wrapperShift : ''}`}>
                <Grid className={`${classes.content}`}>
                    <Paper className={classes.contentFormat} elevation={0}>
                        {children}
                    </Paper>
                </Grid>
            </Grid>
            <Drawer open={Boolean(type)} anchor="right" variant="persistent" className={classes.rightDrawer}>
                <RightSidebar type={type} body={body} />
            </Drawer>
        </Grid>
    );
}

export default React.memo(MainLayout);
