import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        flex: `0 0 auto`,
        margin: 0,
        paddingRight: theme.spacing(2)
    },
    expand: {
        flex: 1
    }
}));

function DialogTitle({ title, onClose, actions, className, showCloseIcon = true }) {
    const classes = useStyles();
    return (
        <Grid
            container
            className={`${classes.root} ${className}`}
            wrap="nowrap"
            direction="row"
            alignItems="center"
            justify="space-between"
        >
            <Grid item className={classes.expand}>
                {typeof title === 'string' ? <Typography variant="h3">{title}</Typography> : title}
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="center">
                    {actions && <Grid item>{actions}</Grid>}
                    {showCloseIcon && (
                        <Grid item>
                            <IconButton className={classes.close} onClick={onClose}>
                                <CloseIconSVG />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DialogTitle;
