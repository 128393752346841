import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import CellBaseCardInfoGroup from 'components/selects/CellBaseCardInfoGroup';
import { WEB_HOOK_TRIGGER_ACTIONS_OPTIONS } from 'const/automation';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useColumnsPermission } from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { useDispatch } from 'react-redux';
import { updateTriggerNode } from '../../action';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { WEB_HOOK_TRIGGERS_ACTIONS_LABEL } from 'const/gridUI';
import { ACTION_STATUS, showWatchingColumnsTriggers } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG } from 'const';

const useStyles = makeStyles(theme => ({
    root: {},
    flx: { flex: 1 },
    trigger: {
        padding: '0 20px 30px',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    watchingColumns: {
        padding: '0 20px 20px',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    triggerDetails: {
        color: theme.colors.steel,
        margin: '20px 0 10px'
    },
    mb12: {
        marginBottom: 12
    },
    mt20: {
        marginTop: 20
    },
    triggerHint: {
        color: theme.colors.steel
    },
    item: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        minHeight: 60,
        padding: `0 16px`,
        cursor: 'pointer'
    },
    iconWrapper: { marginRight: 14 },
    controlClassName: {
        minHeight: 60,
        '&.error': {
            borderColor: theme.colors.brightRed
        }
    },
    iconClassName: {
        width: 'fit-content'
    },
    chipClassName: {
        maxWidth: 192,
        padding: '0 7px'
    },
    containerClassName: {
        width: '100%'
    },
    midGrey: {
        color: theme.colors.midGrey
    },
    infoSVG: {
        verticalAlign: 'text-top'
    },
    error: {
        color: theme.colors.brightRed
    },
    requiredMark: {
        color: theme.colors.brightRed
    }
}));

const allWatchingColumnOption = { label: 'All columns', value: 'all' };

const Trigger = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const itemRef = useRef();
    const [trigger, setTrigger] = useState(selectedNode.trigger);
    const [watchingColumns, setWatchingColumns] = useState(selectedNode.watchingColumns);
    const [watchingAll, setWatchingAll] = useState(selectedNode.watchingAll);
    const [openChangeAction, setOpenChangeAction] = useState(false);
    const [isChanging, setIsChanging] = useState(false);
    const timeout = useRef();
    const tempOption = useRef();
    const columnsPermission = useColumnsPermission();
    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const openRef = useRef(false);

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        openRef.current = false;
        setTrigger(selectedNode.trigger);
        setWatchingColumns(selectedNode.watchingColumns);
        setWatchingAll(selectedNode.watchingAll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const INTERNAL_ALL_OPTIONS = useMemo(() => {
        let options = [];
        for (const event of WEB_HOOK_TRIGGER_ACTIONS_OPTIONS) {
            options = options?.concat(event?.options);
        }
        return options;
    }, []);

    const selectedTriggers = useMemo(() => {
        return automationDetail?.triggerNodes?.map(trigger => {
            return INTERNAL_ALL_OPTIONS?.find(option => option?.value === trigger.trigger);
        });
    }, [automationDetail, INTERNAL_ALL_OPTIONS]);

    const viewColumnOptions = useMemo(() => {
        return columnsPermission
            .filter(col => {
                return (
                    ![...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG]?.includes(col.id) &&
                    ![columnTypes.FORMULA].includes(col.type) &&
                    !(
                        [columnTypes.REFERENCE].includes(col.type) &&
                        (col?.referenceType !== 'row' || !col?.primaryReference)
                    )
                );
            })
            .map(col => {
                let value = '';
                if (col.id === columnTypes.RECORD_ID) value = '_recordId';
                else if (col.id === columnTypes.PATH_TAG) value = '_path';
                else value = col.publicId;
                return {
                    ...col,
                    value,
                    label: col.name,
                    icon: () => (
                        <ColumnIcon
                            group={col?.group}
                            type={col?.type}
                            customProperties={col?.customProperties}
                            // style2Digit={{ flex: 'none' }}
                            // styleCountryFlagWrapper={{ flex: 'none' }}
                        />
                    ),
                    options: undefined
                };
            });
    }, [columnsPermission, classes.iconClassName]);

    const viewableColumnOptions = useMemo(() => {
        return viewColumnOptions.filter(option =>
            typeof option?.viewable === 'object' ? !!option?.viewable?.viewable : !!option?.viewable
        );
    }, [viewColumnOptions]);

    const selectedColumns = useMemo(() => {
        if (watchingAll) return [allWatchingColumnOption];
        if (!watchingColumns?.length) return [];
        return watchingColumns.map(el => viewColumnOptions.find(opt => opt.value === el)).filter(el => !!el);
    }, [watchingAll, viewColumnOptions, watchingColumns]);

    const errorWatchingColumns = useMemo(() => {
        return nodeError.watchingColumns;
    }, [nodeError]);

    const filteredColumnOptions = useMemo(() => {
        return viewableColumnOptions;
    }, [viewableColumnOptions]);

    const showWatchingColumns = useMemo(() => {
        return showWatchingColumnsTriggers.includes(trigger);
    }, [trigger]);

    const handleOpen = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        e.persist();
        setAnchorEl(anchorEl => (anchorEl ? null : e.target));
    }, []);

    const handleClose = useCallback(e => {
        setAnchorEl(null);
    }, []);

    const handleOpenChangeAction = useCallback(
        option => {
            handleClose();
            if (selectedTriggers.some(opt => opt.value === option.value)) return;
            tempOption.current = option;
            setOpenChangeAction(true);
        },
        [handleClose, selectedTriggers]
    );

    const handleCloseChangeAction = useCallback(() => {
        setOpenChangeAction(false);
    }, []);

    const handleTriggersChange = useCallback(() => {
        const option = { ...(tempOption.current || {}) };
        tempOption.current = undefined;
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            const oldTrigger = trigger;
            setTrigger(option.value);
            setIsChanging(true);
            dispatch(
                updateTriggerNode({
                    triggerId: selectedNode.id,
                    body: {
                        ...selectedNode,
                        trigger: option.value,
                        watchingColumns: showWatchingColumnsTriggers.includes(option.value)
                            ? selectedNode.watchingColumns
                            : [],
                        watchingAll: false
                    },
                    successCallback: responseData => {
                        setWatchingColumns(responseData.watchingColumns);
                        setWatchingAll(responseData.watchingAll);
                        setSelectedNode(responseData);
                        setIsChanging(false);
                        handleCloseChangeAction();
                    },
                    errorCallback: () => {
                        setIsChanging(false);
                        setTrigger(oldTrigger);
                    }
                })
            );
        }, 100);
    }, [trigger, dispatch, selectedNode, handleCloseChangeAction, setSelectedNode]);

    const handleChangeWatchingColumns = useCallback(
        options => {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                let isLastOptionAll = false;
                if (options.length) {
                    const lastOption = options[options.length - 1];
                    isLastOptionAll = lastOption.value === 'all';
                    if (isLastOptionAll) {
                        options.length = 0;
                    } else {
                        options = options.filter(option => option.value !== 'all');
                    }
                }
                const oldWatchColumns = [...watchingColumns];
                const oldWatchingAll = watchingAll;
                const optStrings = options.map(option => option.value || option.publicId || option.id);
                setWatchingColumns(optStrings);
                setWatchingAll(isLastOptionAll);
                dispatch(
                    updateTriggerNode({
                        triggerId: selectedNode.id,
                        body: {
                            ...selectedNode,
                            watchingColumns: optStrings,
                            watchingAll: isLastOptionAll
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setWatchingColumns(oldWatchColumns);
                            setWatchingAll(oldWatchingAll);
                        }
                    })
                );
            }, 100);
        },
        [dispatch, selectedNode, setSelectedNode, watchingColumns, watchingAll]
    );

    return (
        <Grid className={classes.root} item container direction="column" wrap="nowrap">
            <Grid item className={classes.trigger}>
                <Grid item>
                    <p className="body2 font-bold text-steel mt-5 mb-2.5 mx-0">{t('automation_configure_trigger')}</p>
                </Grid>
                <Grid item className={classes.mb12}>
                    <p className="body1">{t('automation_trigger_type')}</p>
                </Grid>
                <Grid item className={classes.mb12}>
                    <p className="body2 text-steel">{t('automation_trigger_type_hint')}</p>
                </Grid>
                <TooltipActionDisable disabled={isDisabled}>
                    <Grid
                        ref={itemRef}
                        item
                        container
                        alignItems="center"
                        wrap="nowrap"
                        className={classnames(classes.mb12, classes.item)}
                        onClick={handleOpen}
                    >
                        <Grid item className={classnames(classes.iconWrapper)}>
                            <WebHookLabel type={trigger} />
                        </Grid>
                        <Grid item className={classnames(classes.flx)}>
                            <p className="body2">
                                When: <b>{WEB_HOOK_TRIGGERS_ACTIONS_LABEL[trigger]}</b>
                            </p>
                        </Grid>
                        <Grid item className={classes.iconMore}>
                            <IconButton>
                                <ArrowDownSVG color={theme.colors.steel} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </TooltipActionDisable>

                <Grid item>
                    <p className="body2 text-steel">{t('automation_trigger_fire_hint')}</p>
                </Grid>
            </Grid>
            {showWatchingColumns && (
                <Grid item className={classes.watchingColumns}>
                    <Grid item className={classnames(classes.mb12, classes.mt20)}>
                        <p className="body1">
                            {t('global_watching_columns')}
                            <span className={classes.requiredMark}>*</span>
                        </p>
                    </Grid>
                    <Grid item className={classes.mb12}>
                        <p className="body2 text-steel">{t('automation_watching_columns_hint')}</p>
                    </Grid>
                    <Grid item container direction="column" spacing={2} className={classes.mb12}>
                        <Grid item>
                            <TooltipActionDisable disabled={isDisabled} containerClassName={classes.containerClassName}>
                                <Grid item style={{ flex: 1 }}>
                                    <LDBasePortal
                                        ddPlaceholder={t('global_choose_column')}
                                        menuPlaceholder={t('global_label_find_a_column')}
                                        options={[allWatchingColumnOption, ...filteredColumnOptions]}
                                        onChange={handleChangeWatchingColumns}
                                        defaultValue={selectedColumns}
                                        isMulti
                                        isUsingContainer
                                        controlClassName={classnames(classes.controlClassName, {
                                            error: !!errorWatchingColumns
                                        })}
                                        chipClassName={classes.chipClassName}
                                    />
                                </Grid>
                            </TooltipActionDisable>
                        </Grid>
                    </Grid>
                    {errorWatchingColumns && (
                        <Grid item className={classes.mb12}>
                            <p className="text-error">{errorWatchingColumns}</p>
                        </Grid>
                    )}
                    {watchingAll && (
                        <Grid item className={classes.mb12}>
                            <p className="body2 text-steel">
                                <Trans
                                    t={t}
                                    i18nKey="automation_trigger_all_column_warning"
                                    components={{
                                        b: <b />
                                    }}
                                />
                            </p>
                        </Grid>
                    )}
                </Grid>
            )}
            {anchorEl && (
                <PopperMenu handleClickAway={handleClose} anchorEl={itemRef.current} container={itemRef.current}>
                    <Grid item style={{ width: itemRef.current?.offsetWidth }}>
                        <CellBaseCardInfoGroup
                            onChange={handleOpenChangeAction}
                            options={WEB_HOOK_TRIGGER_ACTIONS_OPTIONS}
                            placeholder={t('automation_find_a_trigger')}
                            value={selectedTriggers}
                            className={classes.dropdown}
                        />
                    </Grid>
                </PopperMenu>
            )}
            <Dialog open={openChangeAction} onClose={handleCloseChangeAction}>
                <ConfirmBox
                    title={t('automation_change_trigger_type_title')}
                    body={<p className="body2">{t('automation_change_trigger_type_content')}</p>}
                    handleCancel={handleCloseChangeAction}
                    onClose={handleCloseChangeAction}
                    handleAgreed={handleTriggersChange}
                    agreeLabel={t('global_change')}
                    isLoading={isChanging}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(Trigger);
