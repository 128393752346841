import React from 'react';
import KeyboardList from './KeyboardList';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import { isMac } from 'utils/os';
import { MAC_KEYBOARD_LIST_HEADER } from './mac';
import { WINDOW_KEYBOARD_LIST_HEADER } from './windows';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    key: {
        borderRadius: 5,
        border: `1px solid ${theme.colors.border}`,
        background: theme.colors.ghostwhite,
        boxSizing: 'border-box',
        boxShadow: `1px 1px 0px rgba(0, 0, 0, 0.2)`,
        minWidth: 26,
        height: 26,
        paddingLeft: 7,
        paddingRight: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.dimGrey
    }
}));

function GridKeyboard({ onClose }) {
    const classes = useStyles();
    const HEADER = isMac() ? MAC_KEYBOARD_LIST_HEADER : WINDOW_KEYBOARD_LIST_HEADER;

    return (
        <>
            <DialogTitle
                title={
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h3">{HEADER.label}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Grid container spacing={1}>
                                        {HEADER?.keys.map(k => {
                                            return (
                                                <Grid key={k} item>
                                                    <Typography className={classes.key} variant="body2">
                                                        {k}
                                                    </Typography>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                onClose={onClose}
            ></DialogTitle>
            <DialogContent>
                <KeyboardList />
            </DialogContent>
        </>
    );
}

export default GridKeyboard;
