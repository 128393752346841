import { PLAN_CYCLE, PLAN_TYPES } from 'const';

const currencyMap = {
    eur: '€',
    usd: '$'
};

export function beautifyPrice({ unitAmount, currency = 'eur' }) {
    const currencyUnit = currencyMap?.[currency.toLowerCase()];
    return currencyUnit + (unitAmount / 100).toFixed(2);
}

export function getFriendlyIntervalType(intervalType) {
    switch (intervalType) {
        case PLAN_CYCLE.month:
            return 'Monthly';
        case PLAN_CYCLE.year:
            return 'Annually';
        default:
            return '';
    }
}

export function getPlanName(planType) {
    switch (planType) {
        case PLAN_TYPES.ENTERPRISE:
            return 'Enterprise';
        case PLAN_TYPES.FREE:
            return 'Free';
        case PLAN_TYPES.PROFESSIONAL:
            return 'Pro';
        default:
            return '';
    }
}
