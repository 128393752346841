import React from 'react';

function NoShareViewGroupSVG({ color = 'white', ...other }) {
    return (
        <svg width="170" height="105" viewBox="0 0 170 105" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M39.97 45.945C40.1654 47.0202 39.3836 47.9977 38.3086 47.9977C38.0643 47.9977 38.895 48.1443 28.4871 46.0916C25.7997 47.3623 22.9656 47.9977 19.985 47.9977C8.94193 47.9488 0 39.0049 0 28.0082C0 16.6694 9.47943 7.52999 20.9134 8.01873C34.2041 8.65409 43.1461 21.8501 38.895 34.4107H38.9438C38.6995 35.1438 38.4063 35.8281 38.0643 36.5123L39.97 45.945Z"
                fill="#E5E2F0"
            />
            <path
                d="M15.9141 33H14.1914V28.5771H9.74121V33H8.01172V23.0469H9.74121V27.1895H14.1914V23.0469H15.9141V33ZM21.0615 33.1367C20.0088 33.1367 19.1543 32.8063 18.498 32.1455C17.8464 31.4801 17.5205 30.596 17.5205 29.4932V29.2881C17.5205 28.5498 17.6618 27.8913 17.9443 27.3125C18.2314 26.7292 18.6325 26.2757 19.1475 25.9521C19.6624 25.6286 20.2367 25.4668 20.8701 25.4668C21.8773 25.4668 22.6543 25.7881 23.2012 26.4307C23.7526 27.0732 24.0283 27.9824 24.0283 29.1582V29.8281H19.1953C19.2454 30.4388 19.4482 30.9219 19.8037 31.2773C20.1637 31.6328 20.6149 31.8105 21.1572 31.8105C21.9183 31.8105 22.5381 31.5029 23.0166 30.8877L23.9121 31.7422C23.6159 32.1842 23.2194 32.5283 22.7227 32.7744C22.2305 33.016 21.6768 33.1367 21.0615 33.1367ZM20.8633 26.7998C20.4076 26.7998 20.0384 26.9593 19.7559 27.2783C19.4779 27.5973 19.3001 28.0417 19.2227 28.6113H22.3877V28.4883C22.3512 27.9323 22.2031 27.513 21.9434 27.2305C21.6836 26.9434 21.3236 26.7998 20.8633 26.7998ZM27.8154 30.6211L29.3193 25.6035H31.0898L28.1504 34.1211C27.6992 35.3652 26.9336 35.9873 25.8535 35.9873C25.612 35.9873 25.3454 35.9463 25.0537 35.8643V34.5791L25.3682 34.5996C25.7874 34.5996 26.1019 34.5221 26.3115 34.3672C26.5257 34.2168 26.6943 33.9616 26.8174 33.6016L27.0566 32.9658L24.459 25.6035H26.25L27.8154 30.6211Z"
                fill="#4D3F8A"
            />
            <g clip-path="url(#clip0_601_22361)">
                <path
                    d="M66.4799 89.56H148.6C149.396 88.7025 150.147 87.8047 150.85 86.87C155.55 80.49 155.68 72.26 154.28 64.79C152.88 57.32 151.03 49.67 146.83 43.25C146.38 42.56 145.9 41.88 145.4 41.25C136.02 28.93 119.52 23.7 104.5 24.55C90.9999 25.31 77.2399 30.87 69.1699 42.07C63.7699 49.57 61.1699 58.79 60.6899 67.94C60.3199 74.19 60.3099 81.56 64.2399 86.89C64.9374 87.8201 65.6852 88.7114 66.4799 89.56Z"
                    fill="url(#paint0_linear_601_22361)"
                />
                <path
                    d="M159.87 44.0699C163.55 43.4399 167.53 48.8099 168.82 56.0699V55.8399C167.28 46.7899 163.02 39.9699 159.31 40.5999C155.6 41.2299 153.84 49.0699 155.38 58.1199C155.38 58.1999 155.38 58.2699 155.38 58.3499C154.21 51.0699 156.19 44.6999 159.87 44.0699Z"
                    fill="#22C3F3"
                />
                <path
                    d="M164.41 70.7401C161 71.3201 157.35 66.74 155.79 60.33C157.6 68.26 161.45 73.9601 164.85 73.3801C168.25 72.8001 170.01 66.1501 169.1 58.0701C169.76 64.6601 167.81 70.1601 164.41 70.7401Z"
                    fill="#FAAF1B"
                />
                <path
                    d="M55.4604 44.0699C51.7804 43.4399 47.8004 48.8099 46.5104 56.0699C46.5056 55.9933 46.5056 55.9165 46.5104 55.8399C48.0404 46.7899 52.3004 39.9699 56.0104 40.5999C59.7204 41.2299 61.4805 49.0699 59.9405 58.1199C59.9405 58.1999 59.9405 58.2699 59.9405 58.3499C61.1305 51.0699 59.1404 44.6999 55.4604 44.0699Z"
                    fill="#FAAF1B"
                />
                <path
                    d="M50.9305 70.7401C54.3305 71.3201 57.9904 66.74 59.5404 60.33C57.7304 68.26 53.8804 73.9601 50.4804 73.3801C47.0804 72.8001 45.3205 66.1501 46.2405 58.0701C45.5405 64.6601 47.5405 70.1601 50.9305 70.7401Z"
                    fill="#22C3F3"
                />
                <path
                    d="M145.98 38.6401C143.657 39.1106 141.61 40.4662 140.27 42.4201C141.205 41.7125 142.295 41.2363 143.45 41.0301C149.79 39.9501 156.39 47.7301 158.21 58.4101C160.03 69.0901 156.36 78.6101 150.02 79.6801C148.862 79.8689 147.676 79.7797 146.56 79.4201C148.471 80.8205 150.852 81.423 153.2 81.1001C160.2 79.9101 164.2 69.4501 162.2 57.7301C160.2 46.0101 152.94 37.4601 145.98 38.6401Z"
                    fill="#E43383"
                />
                <path
                    d="M69.35 38.6401C71.675 39.1101 73.7261 40.4656 75.0701 42.4201C74.1298 41.714 73.0373 41.238 71.8801 41.0301C65.5501 39.9501 58.9401 47.7301 57.1301 58.4101C55.3201 69.0901 58.9801 78.6101 65.3101 79.6801C66.471 79.8686 67.6601 79.7794 68.78 79.4201C66.865 80.8211 64.4807 81.4235 62.1301 81.1001C55.1301 79.9101 51.1301 69.4501 53.1301 57.7301C55.1301 46.0101 62.39 37.4601 69.35 38.6401Z"
                    fill="#E43383"
                />
                <path
                    d="M105.67 81.2101C97.6412 81.31 89.6308 80.4199 81.8199 78.5601C75.9099 77.0901 72.4099 74.4701 71.4299 70.7901C69.1299 62.2101 70.7899 50.2401 78.4299 43.9501C82.9597 40.2044 88.473 37.8435 94.3099 37.1501C103.12 36.0167 112.039 36.0167 120.85 37.1501C126.688 37.8391 132.202 40.2005 136.73 43.9501C144.33 50.2401 145.99 62.2101 143.73 70.7901C142.82 74.1901 139.58 76.7201 134.11 78.3001C126.11 80.6201 116.76 81.0801 107.91 81.2201H105.74L105.67 81.2101Z"
                    fill="#9863A9"
                />
                <path
                    d="M105.83 78.46C98.5191 78.5425 91.2248 77.757 84.0995 76.12C78.7095 74.82 75.5296 72.53 74.6296 69.3C72.5396 61.77 74.0495 51.3 80.9795 45.76C85.1425 42.4388 90.1578 40.3592 95.4495 39.76C103.482 38.76 111.607 38.76 119.64 39.76C124.928 40.361 129.939 42.4406 134.1 45.76C141.03 51.27 142.54 61.76 140.45 69.3C139.62 72.3 136.67 74.5 131.68 75.89C124.36 77.89 115.88 78.32 107.81 78.45H105.81L105.83 78.46Z"
                    fill="#121212"
                />
                <path
                    d="M105.83 76.56C98.6668 76.633 91.5204 75.8576 84.5398 74.25C79.8798 73.13 77.1698 71.25 76.4698 68.78C74.5498 61.87 75.8898 52.25 82.1598 47.26C86.0566 44.1749 90.7408 42.2451 95.6798 41.69C103.55 40.7166 111.51 40.7166 119.38 41.69C124.319 42.2451 129.003 44.1749 132.9 47.26C139.17 52.26 140.51 61.87 138.59 68.78C137.96 71.08 135.45 72.85 131.16 74.04C124.04 76.04 115.71 76.4 107.77 76.53H105.77L105.83 76.56Z"
                    fill="#17171A"
                />
                <path
                    d="M96.0504 41.6501C90.6923 42.4412 85.6365 44.6277 81.3904 47.9901L80.8404 48.4801C75.7204 53.7101 74.7104 62.3901 76.4804 68.7801C77.1804 71.2901 79.8904 73.1301 84.5504 74.2501C85.8837 74.5568 87.2171 74.8368 88.5504 75.0901L90.5504 51.6701C90.6735 50.2626 91.1423 48.9073 91.9151 47.7245C92.6879 46.5417 93.7408 45.5681 94.9804 44.8901L101.89 41.1301C99.9304 41.2401 97.9704 41.4201 96.0504 41.6501Z"
                    fill="url(#paint1_linear_601_22361)"
                />
                <path
                    d="M83.0201 39.78L84.6301 38.47L79.5401 32.26C78.9601 32.63 78.3901 33.02 77.8301 33.42L83.0201 39.78Z"
                    fill="white"
                />
                <path
                    d="M137.54 33.5599C136.98 33.1499 136.4 32.7699 135.83 32.3899L130.6 38.8299L132.2 40.1299L137.54 33.5599Z"
                    fill="white"
                />
                <path
                    d="M102.398 7.45676L102.133 6.9978C102.081 6.90692 101.964 6.87579 101.874 6.92825L95.4736 10.6232C95.3827 10.6757 95.3516 10.7919 95.4041 10.8828L95.6691 11.3418C95.7215 11.4326 95.8377 11.4638 95.9286 11.4113L102.329 7.71631C102.419 7.66385 102.451 7.54764 102.398 7.45676Z"
                    fill="white"
                />
                <path
                    d="M94.5796 12.4001C94.8668 12.4001 95.0996 12.1673 95.0996 11.8801C95.0996 11.5929 94.8668 11.3601 94.5796 11.3601C94.2924 11.3601 94.0596 11.5929 94.0596 11.8801C94.0596 12.1673 94.2924 12.4001 94.5796 12.4001Z"
                    fill="white"
                />
                <path
                    d="M137.09 42.0001C132.244 38.1345 126.409 35.7089 120.25 35.0001C111.809 33.9505 103.271 33.9505 94.8303 35.0001C88.6718 35.7089 82.8366 38.1345 77.9903 42.0001C69.7403 48.5701 67.3003 60.8301 69.9903 70.5801C71.6603 76.5801 77.3203 79.4301 82.9903 80.7801C91.1578 82.6408 99.5264 83.4706 107.9 83.2501C116.12 83.1201 125.02 82.7201 132.98 80.5101C138.24 79.0501 143.53 76.1801 145.08 70.5801C147.78 60.8301 145.34 48.5601 137.09 42.0001ZM142.3 69.8101C141.3 73.4901 137.88 76.1601 132.2 77.7401C124.67 79.8301 116.04 80.2401 107.84 80.3701H105.84C98.3779 80.4527 90.9322 79.6471 83.6603 77.9701C77.5503 76.5101 73.8903 73.7601 72.7903 69.8001C70.5303 61.6701 72.2103 50.2801 79.7903 44.2501C84.2304 40.7214 89.5742 38.5139 95.2103 37.8801C103.405 36.8535 111.696 36.8535 119.89 37.8801C125.526 38.5139 130.87 40.7214 135.31 44.2501C142.88 50.2901 144.54 61.6801 142.3 69.8101Z"
                    fill="url(#paint2_linear_601_22361)"
                />
                <path
                    d="M95.23 4.67993C94.09 5.36993 92.9401 6.04995 91.8101 6.75995C91.3604 7.03231 90.9902 7.41804 90.7365 7.87854C90.4829 8.33904 90.3547 8.85812 90.3648 9.38376C90.3749 9.90939 90.523 10.4231 90.7942 10.8735C91.0654 11.3239 91.4502 11.6951 91.91 11.95L103.54 19.3299C104.764 20.1474 106.198 20.5952 107.67 20.6199C109.145 20.6379 110.593 20.2281 111.84 19.4399L120.25 14.3899L123.56 12.3899C124.05 12.1465 124.464 11.774 124.757 11.3124C125.05 10.8509 125.212 10.3179 125.224 9.77118C125.236 9.22446 125.099 8.68478 124.827 8.21045C124.555 7.73612 124.158 7.34518 123.68 7.07993L114.68 1.37994L111.29 3.37994C110.824 3.64014 110.435 4.02015 110.164 4.48074C109.894 4.94134 109.751 5.4658 109.751 5.99994C109.751 6.53408 109.894 7.05854 110.164 7.51913C110.435 7.97973 110.824 8.35974 111.29 8.61993L112.77 9.54993L107.77 12.5499L104.02 10.1799L102.75 9.35992L106.44 7.20993C106.874 6.94583 107.233 6.57604 107.485 6.13519C107.736 5.69434 107.872 5.19684 107.879 4.68927C107.886 4.1817 107.764 3.68068 107.525 3.23306C107.285 2.78545 106.936 2.40589 106.51 2.12994L103.07 -0.0500488L95.26 4.60992"
                    fill="url(#paint3_linear_601_22361)"
                />
                <path
                    opacity="0.47"
                    d="M103.742 3.25375L103.277 2.44835C103.18 2.28095 102.966 2.22362 102.798 2.32027L96.6583 5.86526C96.4909 5.96191 96.4336 6.17595 96.5302 6.34336L96.9952 7.14875C97.0919 7.31616 97.3059 7.37349 97.4733 7.27684L103.613 3.73184C103.781 3.63519 103.838 3.42115 103.742 3.25375Z"
                    fill="white"
                />
                <path
                    opacity="0.47"
                    d="M95.1797 8.72998C95.732 8.72998 96.1797 8.28227 96.1797 7.72998C96.1797 7.1777 95.732 6.72998 95.1797 6.72998C94.6274 6.72998 94.1797 7.1777 94.1797 7.72998C94.1797 8.28227 94.6274 8.72998 95.1797 8.72998Z"
                    fill="white"
                />
                <g opacity="0.35">
                    <path
                        d="M78.4097 87.01C77.0197 88.01 75.8697 90.25 75.0997 97.34C74.3297 104.43 67.0297 104.98 62.5397 102.41C58.8797 100.32 56.4397 91.41 59.5397 85.27C64.9497 86.05 71.2597 86.65 78.1597 87L78.4097 87.01Z"
                        fill="#371A45"
                    />
                </g>
                <path
                    d="M74.9105 81.7299C73.4805 80.7299 66.6805 77.9299 60.5905 82.1299C53.6505 86.9199 56.1305 98.6899 60.3705 101.03C64.6105 103.37 71.3705 102.93 72.0905 96.4599C72.8905 89.3799 74.0905 87.6399 75.6305 86.9299C76.5353 86.6307 77.4659 86.4165 78.4105 86.2899C77.6686 84.4866 76.4607 82.9128 74.9105 81.7299Z"
                    fill="url(#paint4_linear_601_22361)"
                />
                <path
                    d="M75.3099 87.1001C74.5902 85.6404 73.3844 84.477 71.8998 83.8101C68.7415 82.1981 65.2353 81.3876 61.6899 81.4501C61.3109 81.6563 60.9437 81.8833 60.5898 82.1301C60.2353 82.3912 59.901 82.6787 59.5898 82.9901H59.6699C62.2699 82.0601 67.8298 82.9901 71.3398 84.8501C72.5598 85.5101 74.0698 86.5901 74.3398 87.9601V88.0301C74.6008 87.661 74.9301 87.3454 75.3099 87.1001Z"
                    fill="#22C3F3"
                />
                <g opacity="0.25">
                    <path
                        d="M78.4097 86.2901C77.4651 86.4166 76.5345 86.6308 75.6297 86.93C74.1297 87.64 72.8897 89.38 72.0897 96.46C71.3697 102.93 64.5597 103.34 60.3697 101.03C59.1539 100.241 58.2028 99.1055 57.6396 97.77C58.0125 98.1849 58.4478 98.5392 58.9297 98.8201C63.1197 101.13 69.9297 100.72 70.6497 94.2501C71.4497 87.1701 72.8997 85.7701 74.1897 84.7201C75.0297 84.0301 76.5196 84.4301 77.7596 84.9701C78.0068 85.3944 78.224 85.8355 78.4097 86.2901Z"
                        fill="#371A45"
                    />
                </g>
                <g opacity="0.35">
                    <path
                        d="M133.54 87.25C134.93 88.25 136.08 90.48 136.85 97.57C137.62 104.66 144.92 105.22 149.41 102.65C153.07 100.55 155.51 91.65 152.41 85.51C147 86.29 140.69 86.88 133.79 87.24L133.54 87.25Z"
                        fill="#371A45"
                    />
                </g>
                <path
                    d="M137.03 81.9701C138.45 80.9701 145.26 78.1601 151.35 82.3701C158.29 87.1601 155.81 98.9201 151.57 101.27C147.33 103.62 140.57 103.17 139.85 96.7001C139.05 89.6201 137.85 87.8801 136.31 87.1701C135.409 86.8768 134.481 86.6725 133.54 86.5601C134.275 84.747 135.479 83.1625 137.03 81.9701Z"
                    fill="url(#paint5_linear_601_22361)"
                />
                <path
                    d="M152.36 83.1899C152.046 82.893 151.712 82.6189 151.36 82.3699C151.003 82.1185 150.633 81.8881 150.25 81.6799C146.716 81.643 143.224 82.4558 140.07 84.0499C138.583 84.7213 137.372 85.8828 136.64 87.3399C137.034 87.5773 137.374 87.894 137.64 88.2699C137.64 88.2699 137.64 88.2699 137.64 88.1899C137.9 86.8199 139.42 85.7399 140.64 85.0799C144.18 83.1999 149.7 82.3099 152.36 83.1899Z"
                    fill="#22C3F3"
                />
                <g opacity="0.25">
                    <path
                        d="M133.54 86.5601C134.485 86.6866 135.415 86.9009 136.32 87.2001C137.82 87.9101 139.06 89.6501 139.86 96.7301C140.58 103.2 147.39 103.61 151.58 101.3C152.798 100.509 153.75 99.3698 154.31 98.0301C153.94 98.4507 153.504 98.8087 153.02 99.0901C148.83 101.4 142.02 100.99 141.3 94.5201C140.5 87.4401 139.05 86.0301 137.76 84.9901C136.92 84.3001 135.43 84.7001 134.19 85.2401C133.941 85.6632 133.723 86.1044 133.54 86.5601Z"
                        fill="#371A45"
                    />
                </g>
                <path
                    d="M92.1504 65.71C96.9829 65.71 100.9 61.7925 100.9 56.96C100.9 52.1275 96.9829 48.21 92.1504 48.21C87.3179 48.21 83.4004 52.1275 83.4004 56.96C83.4004 61.7925 87.3179 65.71 92.1504 65.71Z"
                    fill="white"
                />
                <path
                    d="M92.1504 48.21C92.5218 48.2117 92.8926 48.2385 93.2604 48.29C91.1425 48.5497 89.1928 49.5752 87.7789 51.1732C86.3649 52.7712 85.5844 54.8312 85.5844 56.965C85.5844 59.0987 86.3649 61.1587 87.7789 62.7567C89.1928 64.3547 91.1425 65.3802 93.2604 65.64C92.8919 65.6832 92.5214 65.7066 92.1504 65.71C89.8297 65.71 87.6041 64.7881 85.9632 63.1472C84.3223 61.5062 83.4004 59.2806 83.4004 56.96C83.4004 54.6393 84.3223 52.4137 85.9632 50.7728C87.6041 49.1319 89.8297 48.21 92.1504 48.21Z"
                    fill="#EDE9EE"
                />
                <path
                    d="M92.4202 58.72C93.3922 58.72 94.1802 57.932 94.1802 56.9599C94.1802 55.9879 93.3922 55.2 92.4202 55.2C91.4481 55.2 90.6602 55.9879 90.6602 56.9599C90.6602 57.932 91.4481 58.72 92.4202 58.72Z"
                    fill="#17171A"
                />
                <path
                    d="M134.4 59.7501H116.93C116.7 59.7562 116.475 59.683 116.293 59.5427C116.111 59.4025 115.983 59.2038 115.93 58.9801C115.879 58.761 115.904 58.5313 116 58.3281C116.097 58.1249 116.258 57.96 116.46 57.86L126.03 53.17C126.148 53.1005 126.28 53.0562 126.417 53.0398C126.553 53.0235 126.692 53.0354 126.823 53.075C126.955 53.1145 127.077 53.1808 127.182 53.2697C127.287 53.3586 127.372 53.4681 127.433 53.5915C127.494 53.7149 127.528 53.8495 127.535 53.9868C127.541 54.1242 127.519 54.2614 127.47 54.3898C127.421 54.5183 127.345 54.6352 127.249 54.7333C127.153 54.8314 127.037 54.9086 126.91 54.96L121.22 57.7501H134.37C134.635 57.7501 134.889 57.8554 135.077 58.0429C135.264 58.2305 135.37 58.4848 135.37 58.7501C135.37 59.0153 135.264 59.2696 135.077 59.4571C134.889 59.6447 134.635 59.7501 134.37 59.7501H134.4Z"
                    fill="white"
                />
                <path
                    d="M108.18 67.1999C108.721 67.2126 109.258 67.0926 109.742 66.8503C110.227 66.608 110.645 66.2508 110.96 65.8099C111.043 65.7102 111.105 65.5945 111.142 65.47C111.178 65.3454 111.189 65.2147 111.173 65.0858C111.158 64.9569 111.116 64.8326 111.05 64.7205C110.984 64.6085 110.896 64.5111 110.792 64.4344C110.687 64.3577 110.567 64.3033 110.441 64.2746C110.314 64.2458 110.183 64.2433 110.055 64.2673C109.928 64.2912 109.806 64.3411 109.699 64.4137C109.591 64.4864 109.5 64.5804 109.43 64.6899C109.166 64.9875 108.811 65.1899 108.421 65.2658C108.03 65.3417 107.626 65.2869 107.27 65.1099C107.038 65.0219 106.781 65.0234 106.55 65.1139C106.319 65.2045 106.13 65.3777 106.02 65.5999C105.931 65.8307 105.931 66.0862 106.02 66.3171C106.108 66.5479 106.279 66.7378 106.5 66.8499C107.03 67.0807 107.602 67.1998 108.18 67.1999Z"
                    fill="#EDE9EE"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_601_22361"
                    x1="120.46"
                    y1="93.16"
                    x2="97.1399"
                    y2="49.24"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="1" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_601_22361"
                    x1="97.9204"
                    y1="46.4501"
                    x2="81.4604"
                    y2="65.5201"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.25" stop-color="#353537" />
                    <stop offset="1" stop-color="#17171A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_601_22361"
                    x1="82.2603"
                    y1="45.9501"
                    x2="145.3"
                    y2="81.9301"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stop-color="white" />
                    <stop offset="1" stop-color="#D2C5E1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_601_22361"
                    x1="114.29"
                    y1="15.7199"
                    x2="80.3701"
                    y2="-16.69"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#693F98" />
                    <stop offset="0.67" stop-color="#8964A9" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_601_22361"
                    x1="56.2405"
                    y1="91.0999"
                    x2="78.4105"
                    y2="91.0999"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#7C5FA7" />
                    <stop offset="1" stop-color="#5D3885" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_601_22361"
                    x1="151.19"
                    y1="99.9001"
                    x2="140.85"
                    y2="80.2901"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#7C5FA7" />
                    <stop offset="1" stop-color="#5D3885" />
                </linearGradient>
                <clipPath id="clip0_601_22361">
                    <rect width="123.34" height="104.07" fill="white" transform="translate(46)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(NoShareViewGroupSVG);
