export const FETCH_WORKSPACES = '[WORKSPACE]FETCH_WORKSPACES';
export const FETCH_WORKSPACES_SUCCESS = '[WORKSPACE]FETCH_WORKSPACES_SUCCESS';
export const FETCH_WORKSPACES_FAILED = '[WORKSPACE]FETCH_WORKSPACES_FAILED';
export const INVALIDATE_WORKSPACES = '[WORKSPACE]INVALIDATE_WORKSPACES';

export const FETCH_WORKSPACE_DETAIL = '[WORKSPACE]FETCH_WORKSPACE_DETAIL';
export const FETCH_WORKSPACE_DETAIL_SUCCESS = '[WORKSPACE]FETCH_WORKSPACE_DETAIL_SUCCESS';
export const FETCH_WORKSPACE_DETAIL_FAILED = '[WORKSPACE]FETCH_WORKSPACE_DETAIL_FAILED';

export const CREATE_WORKSPACE = '[WORKSPACE]CREATE_WORKSPACE';
export const CREATE_WORKSPACE_SUCCESS = '[WORKSPACE]CREATE_WORKSPACE_SUCCESS';
export const CREATE_WORKSPACE_FAILED = '[WORKSPACE]CREATE_WORKSPACE_FAILED';

export const DELETE_WORKSPACE = '[WORKSPACE]DELETE_WORKSPACE';
export const DELETE_WORKSPACE_SUCCESS = '[WORKSPACE]DELETE_WORKSPACE_SUCCESS';
export const DELETE_WORKSPACE_FAILED = '[WORKSPACE]DELETE_WORKSPACE_FAILED';

export const UPDATE_WORKSPACE = '[WORKSPACE]UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_SUCCESS = '[WORKSPACE]UPDATE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE_FAILED = '[WORKSPACE]UPDATE_WORKSPACE_FAILED';

export const TURN_OFF_SHOW_LAST_POP_UP_CREATE_WORKSPACE = '[WORKSPACE]TURN_OFF_SHOW_LAST_POP_UP_CREATE_WORKSPACE';
export const TURN_ON_SHOW_LAST_POP_UP_CREATE_WORKSPACE = '[WORKSPACE]TURN_ON_SHOW_LAST_POP_UP_CREATE_WORKSPACE';

export const CLEAR_WORKSPACE_INFO = `[WORKSPACE]CLEAR_WORKSPACE_INFO`;

export const CHANGE_GROUP = '[WORKSPACE]CHANGE_GROUP';

export const FETCH_WORKSPACE_GROUPS = '[WORKSPACE]FETCH_WORKSPACE_GROUPS';
export const FETCH_WORKSPACE_GROUPS_SUCCESS = '[WORKSPACE]FETCH_WORKSPACE_GROUPS_SUCCESS';
export const FETCH_WORKSPACE_GROUPS_FAILED = '[WORKSPACE]FETCH_WORKSPACE_GROUPS_FAILED';

export const FETCH_MEMBER_GROUPS = '[WORKSPACE]FETCH_MEMBER_GROUPS';
export const FETCH_MEMBER_GROUPS_SUCCESS = '[WORKSPACE]FETCH_MEMBER_GROUPS_SUCCESS';
export const FETCH_MEMBER_GROUPS_FAILED = '[WORKSPACE]FETCH_MEMBER_GROUPS_FAILED';

export const FETCH_WORKSPACE_GROUPS_AND_MEMBERS = '[WORKSPACE]FETCH_WORKSPACE_GROUPS_AND_MEMBERS';
export const FETCH_WORKSPACE_GROUPS_AND_MEMBERS_SUCCESS = '[WORKSPACE]FETCH_WORKSPACE_GROUPS_AND_MEMBERS_SUCCESS';
export const FETCH_WORKSPACE_GROUPS_AND_MEMBERS_FAILED = '[WORKSPACE]FETCH_WORKSPACE_GROUPS_AND_MEMBERS_FAILED';

export const UNGRANT_GROUP_OUT_OF_WORKSPACE = '[WORKSPACE]UNGRANT_GROUP_OUT_OF_WORKSPACE';
export const UNGRANT_GROUP_OUT_OF_WORKSPACE_SUCCESS = '[WORKSPACE]UNGRANT_GROUP_OUT_OF_WORKSPACE_SUCCESS';
export const UNGRANT_GROUP_OUT_OF_WORKSPACE_FAILED = '[WORKSPACE]UNGRANT_GROUP_OUT_OF_WORKSPACE_FAILED';

export const SET_WORKSPACE_ROLES = '[WORKSPACE]SET_WORKSPACE_ROLES';

export const CHANGE_USER_WORKSPACE_ROLE = '[WORKSPACE]CHANGE_USER_WORKSPACE_ROLE';
export const CHANGE_USER_WORKSPACE_ROLE_FAILED = '[WORKSPACE]CHANGE_USER_WORKSPACE_ROLE_FAILED';

export const FETCH_WORKSPACE_ROLES = '[WORKSPACE]FETCH_WORKSPACE_ROLES';
export const FETCH_WORKSPACE_ROLES_SUCCESS = '[WORKSPACE]FETCH_WORKSPACE_ROLES_SUCCESS';
export const FETCH_WORKSPACE_ROLES_FAILED = '[WORKSPACE]FETCH_WORKSPACE_ROLES_FAILED';

export const CHANGE_FILTER_GROUP = '[WORKSPACE]CHANGE_FILTER_GROUP';

export const CHANGE_SELECTED_GROUP_ID = '[WORKSPACE]CHANGE_SELECTED_GROUP_ID';

export const FETCH_PROJECT_GROUPS = '[WORKSPACE]FETCH_PROJECT_GROUPS';
export const FETCH_PROJECT_GROUPS_SUCCESS = '[WORKSPACE]FETCH_PROJECT_GROUPS_SUCCESS';
export const FETCH_PROJECT_GROUPS_FAILED = '[WORKSPACE]FETCH_PROJECT_GROUPS_FAILED';

export const SHARE_PROJECT_SUCCESS = '[WORKSPACE]SHARE_PROJECT_SUCCESS';

export const UNSHARE_PROJECT_SUCCESS = '[WORKSPACE]UNSHARE_PROJECT_SUCCESS';
export const FETCH_WORKSPACE_USER_SETTINGS = `[USER-SETTING]FETCH_WORKSPACE_USER_SETTINGS`;
export const FETCH_WORKSPACE_USER_SETTINGS_FAILED = `[USER-SETTING]FETCH_WORKSPACE_USER_SETTINGS_FAILED`;
export const FETCH_WORKSPACE_USER_SETTINGS_SUCCESS = `[USER-SETTING]FETCH_WORKSPACE_USER_SETTINGS_SUCCESS`;
export const SET_USER_SETTING = `[USER-SETTING]SET_USER_SETTING`;

export const SET_SORT_CRITERIA = '[WORKSPACE]SET_SORT_CRITERIA';
export const UPDATE_SORT_CRITERIA = '[WORKSPACE]UPDATE_SORT_CRITERIA';

export const FETCH_REGIONS_SUCCESS = '[WORKSPACE]FETCH_REGIONS_SUCCESS';
