import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'gridUI/actions';
import ViewCellInfo from './ViewCellInfo';
import { Checkbox } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

function BooleanEdit({ columnId, value: cellValue, rowId, width, height, rowIndex, originalValue }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(cellValue);
    const valueRef = useRef(cellValue);
    const theme = useTheme();
    const rootRef = useRef();
    const inputRef = useRef();

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true });
        }
    }, []);

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        dispatch(
            gridActions.cellClickAway({
                isClickYourSelf,
                value,
                rowId,
                columnId
            })
        );
    };

    const handleOnChange = e => {
        setValue(e.target.checked);
        valueRef.current = e.target.checked;
    };

    return (
        <div ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <Checkbox
                className={
                    'w-full h-full flex justify-center items-center text-center py-0 px-2 overflow-hidden bg-white text-ellipsis text-sm focus:outline-none focus:border focus:border-brand-main'
                }
                onChange={handleOnChange}
                checked={valueRef.current === true}
                style={{
                    width,
                    height,
                    border: `1px solid ${theme.colors.highlight}`,
                    borderRadius: 3,
                    background: 'white'
                }}
                inputRef={inputRef}
            />
        </div>
    );
}

export default React.memo(BooleanEdit);
