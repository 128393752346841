/**
 * PERMISSION
 */
export const SET_DEFAULT_COMPANY_SETTING = '[PERMISSION]SET_DEFAULT_COMPANY_SETTING';

/**
 * Roles
 */
export const FETCH_PRIVILEGE = '[ROLE]FETCH_PRIVILEGE';
export const FETCH_PRIVILEGE_SUCCESS = '[ROLE]FETCH_PRIVILEGE_SUCCESS';
export const FETCH_PRIVILEGE_FAILED = '[ROLE]FETCH_PRIVILEGE_FAILED';

export const FETCH_WORKSPACE_PRIVILEGE = '[ROLE]FETCH_WORKSPACE_PRIVILEGE';
export const FETCH_WORKSPACE_PRIVILEGE_SUCCESS = '[ROLE]FETCH_WORKSPACE_PRIVILEGE_SUCCESS';
export const FETCH_WORKSPACE_PRIVILEGE_FAILED = '[ROLE]FETCH_WORKSPACE_PRIVILEGE_FAILED';

export const FETCH_ROLES = '[ROLE]FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = '[ROLE]FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = '[ROLE]FETCH_ROLES_FAILED';

export const CREATE_ROLE = '[ROLE]CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = '[ROLE]CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILED = '[ROLE]CREATE_ROLE_FAILED';

export const DELETE_ROLE = '[ROLE]DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = '[ROLE]DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = '[ROLE]DELETE_ROLE_FAILED';

export const UPDATE_ROLE = '[ROLE]UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = '[ROLE]UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = '[ROLE]UPDATE_ROLE_FAILED';

export const UPDATE_ROLE_PRIVILEGE_IDS = '[ROLE]UPDATE_ROLE_PRIVILEGE_IDS';

export const TURN_OFF_SHOW_LAST_POP_UP_CREATE_ROLE = '[ROLE]TURN_OFF_SHOW_LAST_POP_UP_CREATE_ROLE';
export const TURN_ON_SHOW_LAST_POP_UP_CREATE_ROLE = '[ROLE]TURN_ON_SHOW_LAST_POP_UP_CREATE_ROLE';

export const CHANGE_ROLE = '[ROLE]CHANGE_ROLE';

/**
 * Groups
 */

export const FETCH_GROUPS = '[GROUP]FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESS = '[GROUP]FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILED = '[GROUP]FETCH_GROUPS_FAILED';
export const UPLOAD_AVATAR_GROUP_SUCCESS = '[GROUP]UPLOAD_AVATAR_GROUP_SUCCESS';

export const CREATE_GROUP = '[GROUP]CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = '[GROUP]CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = '[GROUP]CREATE_GROUP_FAILED';

export const DELETE_GROUP = '[GROUP]DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = '[GROUP]DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = '[GROUP]DELETE_GROUP_FAILED';

export const UPDATE_GROUP = '[GROUP]UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = '[GROUP]UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = '[GROUP]UPDATE_GROUP_FAILED';

export const CHANGE_GROUP = '[GROUP]CHANGE_GROUP';
export const FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS = '[GROUP]FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS';
export const FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS_SUCCESS = '[GROUP]FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS_SUCCESS';
export const FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS_FAILED = '[GROUP]FETCH_GROUP_MEMBER_AND_WORKSPACE_ACCESS_FAILED';

export const TURN_OFF_SHOW_LAST_POP_UP_CREATE_GROUP = '[GROUP]TURN_OFF_SHOW_LAST_POP_UP_CREATE_GROUP';
export const TURN_ON_SHOW_LAST_POP_UP_CREATE_GROUP = '[GROUP]TURN_ON_SHOW_LAST_POP_UP_CREATE_GROUP';

export const GRANT_GROUP_TO_WORKSPACE_SUCCESS = '[GROUP]GRANT_GROUP_TO_WORKSPACE_SUCCESS';
export const GRANT_GROUP_TO_WORKSPACE_FAILED = '[GROUP]GRANT_GROUP_TO_WORKSPACE_FAILED';

export const UNGRANT_GROUP_TO_WORKSPACE_SUCCESS = '[GROUP]UNGRANT_GROUP_TO_WORKSPACE_SUCCESS';
export const UNGRANT_GROUP_TO_WORKSPACE_FAILED = '[GROUP]UNGRANT_GROUP_TO_WORKSPACE_FAILED';

export const FETCH_GROUP_WORKSPACE_ACCESS = 'FETCH_GROUP_WORKSPACE_ACCESS';
export const FETCH_GROUP_WORKSPACE_ACCESS_SUCCESS = 'FETCH_GROUP_WORKSPACE_ACCESS_SUCCESS';
export const FETCH_GROUP_WORKSPACE_ACCESS_FAILED = 'FETCH_GROUP_WORKSPACE_ACCESS_FAILED';

export const FETCH_GROUP_MEMBERS = 'FETCH_GROUP_MEMBERS';
export const FETCH_GROUP_MEMBERS_SUCCESS = 'FETCH_GROUP_MEMBERS_SUCCESS';
export const FETCH_GROUP_MEMBERS_FAILED = 'FETCH_GROUP_MEMBERS_FAILED';
export const REMOVE_USER_OUT_OF_GROUP = '[GROUP]REMOVE_USER_OUT_OF_GROUP';
export const REMOVE_USER_OUT_OF_GROUP_FAILED = '[GROUP]REMOVE_USER_OUT_OF_GROUP_FAILED';
export const REMOVE_USER_OUT_OF_GROUP_SUCCESS = '[GROUP]REMOVE_USER_OUT_OF_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP';
export const FETCH_FULL_WORKSPACE_SUCCESS = 'FETCH_FULL_WORKSPACE_SUCCESS';

export const UPDATE_WORKSPACE_ACCESSS = '[GROUP]UPDATE_WORKSPACE_ACCESSS';

export const SET_SELECTED_GROUPS = '[GROUP]SET_SELECTED_GROUPS';

/**
 * Members
 */

export const FETCH_MEMBERS = '[MEMBER]FETCH_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = '[MEMBER]FETCH_MEMBERS_SUCCESS';
export const UPDATE_USER_ROLE_SUCCESS = '[MEMBER]UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAILED = '[MEMBER]UPDATE_USER_ROLE_FAILED';
export const UPDATE_USER_STATE_SUCCESS = '[MEMBER]UPDATE_USER_STATE_SUCCESS';
export const UPDATE_USER_STATE_FAILED = '[MEMBER]UPDATE_USER_STATE_FAILED';

export const REMOVE_USER_SUCCESS = '[MEMBER]REMOVE_USER_SUCCESS';

export const ASSIGN_USER_TO_GROUP = '[MEMBER]ASSIGN_USER_TO_GROUP';
export const ASSIGN_USER_TO_GROUP_SUCCESS = '[GROUP]ASSIGN_USER_TO_GROUP_SUCCESS';
export const UNASSIGN_USER_OUT_GROUP = '[MEMBER]UNASSIGN_USER_OUT_GROUP';

export const FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER = '[MEMBER]FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER';
export const FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_FAILED =
    '[MEMBER]FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_FAILED';
export const FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_SUCCESS =
    '[MEMBER]FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_SUCCESS';

export const CHANGE_USER_WORKSPACE_ROLE = '[MEMBER]CHANGE_USER_WORKSPACE_ROLE';
export const CHANGE_USER_WORKSPACE_ROLE_FAILED = '[MEMBER]CHANGE_USER_WORKSPACE_ROLE_FAILED';
export const CHANGE_USER_WORKSPACE_ROLE_SUCCESS = '[MEMBER]CHANGE_USER_WORKSPACE_ROLE_SUCCESS';
export const TRANSFER_OWNERSHIP_SUCCESS = '[MEMBER]TRANSFER_OWNERSHIP_SUCCESS';
export const UPDATE_USER_ROLE_AND_LANGUAGES_SUCCESS = '[MEMBER]UPDATE_USER_ROLE_AND_LANGUAGES_SUCCESS';

export const RESEND_INVITATION_SUCCESS = '[MEMBER]RESEND_INVITATION_SUCCESS';
export const REVOKE_INVITATION_SUCCESS = '[MEMBER]REVOKE_INVITATION_SUCCESS';

export const UPDATE_SINGLE_USER = '[MEMBER]UPDATE_SINGLE_USER';
export const RESET_MEMBERS = '[MEMBER]RESET_MEMBERS';
/**
 * Company
 */
export const FETCH_COMPANIES = '[COMPANY]FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = '[COMPANY]FETCH_COMPANIES_SUCCESS';
export const CHANGE_COMPANIES_LOGO = '[COMPANY]CHANGE_COMPANIES_LOGO';
export const UPLOAD_COMPANY_LOGO_SUCCESS = '[COMPANY]UPLOAD_COMPANY_LOGO_SUCCESS';
/**
 * INVITE
 */

export const INVITE_USERS_TO_COMPANY_SUCCESS = '[INVITE]INVITE_USERS_TO_COMPANY_SUCCESS';
export const INVITE_USERS_TO_GROUP_SUCCESS = '[INVITE]INVITE_USERS_TO_GROUP_SUCCESS';

export const INVITE_MEMBERS_TO_COMPANY_SUCCESS = '[INVITE]INVITE_MEMBERS_TO_COMPANY_SUCCESS';

export const UPDATE_USER_TO_INTERNAL_USER_SUCCESS = '[INVITE]UPDATE_USER_TO_INTERNAL_USER_SUCCESS';
/**
 * WORKSPACE
 */

export const FETCH_WORKSPACE_VIEWS_DETAIL = '[WORKSPACE]FETCH_WORKSPACE_VIEWS_DETAIL';
export const FETCH_WORKSPACE_VIEWS_DETAIL_FAILED = '[WORKSPACE]FETCH_WORKSPACE_VIEWS_DETAIL_FAILED';
export const FETCH_WORKSPACE_VIEWS_DETAIL_SUCCESS = '[WORKSPACE]FETCH_WORKSPACE_VIEWS_DETAIL_SUCCESS';

/**
 * API KEYS
 */

export const FETCH_API_KEYS = '[API_KEYS]FETCH_API_KEYS';
export const FETCH_API_KEYS_SUCCESS = '[API_KEYS]FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_FAILED = '[API_KEYS]FETCH_API_KEYS_FAILED';

export const CREATE_API_KEY = '[API_KEYS]CREATE_API_KEY';
export const CREATE_API_KEY_FAILED = '[API_KEYS]CREATE_API_KEY_FAILED';
export const CREATE_API_KEY_SUCCESS = '[API_KEYS]CREATE_API_KEY_SUCCESS';

export const UPDATE_API_KEY = '[API_KEYS]UPDATE_API_KEY';
export const UPDATE_API_KEY_FAILED = '[API_KEYS]UPDATE_API_KEY_FAILED';
export const UPDATE_API_KEY_SUCCESS = '[API_KEYS]UPDATE_API_KEY_SUCCESS';

export const DELETE_API_KEY = '[API_KEYS]DELETE_API_KEY';
export const DELETE_API_KEY_FAILED = '[API_KEYS]DELETE_API_KEY_FAILED';
export const DELETE_API_KEY_SUCCESS = '[API_KEYS]DELETE_API_KEY_SUCCESS';

export const REVOKE_API_KEY = '[API_KEYS]REVOKE_API_KEY';
export const REVOKE_API_KEY_FAILED = '[API_KEYS]REVOKE_API_KEY_FAILED';
export const REVOKE_API_KEY_SUCCESS = '[API_KEYS]REVOKE_API_KEY_SUCCESS';

export const RENEW_API_KEY = '[API_KEYS]RENEW_API_KEY';
export const RENEW_API_KEY_FAILED = '[API_KEYS]RENEW_API_KEY_FAILED';
export const RENEW_API_KEY_SUCCESS = '[API_KEYS]RENEW_API_KEY_SUCCESS';

export const DUPLICATE_API_KEY = '[API_KEYS]DUPLICATE_API_KEY';
export const DUPLICATE_API_KEY_FAILED = '[API_KEYS]DUPLICATE_API_KEY_FAILED';
export const DUPLICATE_API_KEY_SUCCESS = '[API_KEYS]DUPLICATE_API_KEY_SUCCESS';

export const FETCH_API_KEY_DETAIL = '[API_KEYS]FETCH_API_KEY_DETAIL';
export const FETCH_API_KEY_DETAIL_FAILED = '[API_KEYS]FETCH_API_KEY_DETAIL_FAILED';
export const FETCH_API_KEY_DETAIL_SUCCESS = '[API_KEYS]FETCH_API_KEY_DETAIL_SUCCESS';

export const FETCH_USER_FACTOR = '[API_KEYS]FETCH_USER_FACTOR';
