import { useSelector } from 'react-redux';
import { mapActiveUsersByRowIdColumnId } from 'utils/gridUI/user';
import { useRows } from './row';

export function useActiveUsers() {
    return useSelector(state => state.gridUI?.activeUsers);
}

export function useActiveUsersOnCell() {
    const rows = useRows();
    const activeUserActions = useSelector(state => state.gridUI?.activeUserActions);
    return mapActiveUsersByRowIdColumnId({ users: activeUserActions, rows });
}

export function useUserActiveActions() {
    return useSelector(state => state.gridUI?.userRealtimeActions);
}
