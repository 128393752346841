import React from 'react';

function ChangeSubscriptionContactSVG({ ...other }) {
    return (
        <svg {...other} width="255" height="226" viewBox="0 0 255 226" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M30 198.995C30 198.77 30 198.499 30 198.273C30.4509 186.362 88.1698 186.001 98.496 186.001C132.902 185.956 168.886 187.49 201.714 198.634C207.666 200.665 205.727 205.447 200.721 207.207C154.141 223.72 103.592 224.758 55.4324 214.787C48.0822 213.253 40.5517 211.313 34.7347 206.575C32.3448 204.635 30.2255 201.973 30 198.995Z"
                    fill="#F2F1FF"
                />
                <path
                    d="M102.676 0.40537C105.697 0.721201 108.673 1.17239 111.649 1.84917C131.174 6.22569 148.174 18.3175 162.604 32.1239C168.15 37.4479 173.697 43.2231 180.957 45.84C190.562 49.3141 201.294 49.3593 210.493 53.8711C234.843 65.8727 259.734 99.7118 254.233 128.001C252.294 137.973 246.296 146.726 239.307 154.08C222.848 171.36 205.983 173.571 183.617 167.751C165.761 163.104 147.588 159.088 129.1 158.276C110.026 157.419 92.3944 162.382 73.7711 165.811C61.6862 168.022 49.0602 169.014 37.2459 165.721C10.3255 158.141 0.720712 127.685 0.0443194 102.419C-0.541887 79.6791 6.4475 56.6685 20.2459 38.5307C39.1849 13.6703 71.2008 -2.79806 102.676 0.40537Z"
                    fill="#F2F1FF"
                />
                <g opacity="0.44">
                    <path
                        opacity="0.44"
                        d="M191.825 95.0663C191.825 94.0737 194.576 94.8858 195.432 94.2542C196.289 93.6225 195.432 90.013 196.695 90.1032C197.958 90.1935 197.281 93.5323 197.822 94.209C198.363 94.8858 201.475 94.1639 201.34 95.2468C201.204 96.3296 198.769 95.5626 198.003 96.5101C197.191 97.4576 197.642 101.022 196.515 100.977C195.387 100.932 195.568 97.0966 195.162 96.3747C194.711 95.7431 191.825 95.8333 191.825 95.0663Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M212.026 73.2733C212.026 72.2807 214.777 73.0929 215.634 72.4612C216.491 71.8295 215.634 68.22 216.896 68.3103C218.159 68.4005 217.483 71.7393 218.024 72.4161C218.565 73.0929 221.676 72.371 221.541 73.4538C221.406 74.5367 218.971 73.7696 218.204 74.7171C217.392 75.6646 217.843 79.229 216.716 79.1839C215.589 79.1388 215.769 75.3037 215.363 74.5818C214.867 73.9501 212.026 74.0403 212.026 73.2733Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M29.8965 111.711C29.8965 110.718 32.6471 111.53 33.5039 110.899C34.3607 110.267 33.5039 106.658 34.7665 106.748C36.0291 106.838 35.3527 110.177 35.8938 110.854C36.4349 111.53 39.5463 110.808 39.4111 111.891C39.2758 112.974 36.8408 112.207 36.0742 113.155C35.2625 114.102 35.7135 117.667 34.5861 117.621C33.4588 117.576 33.6392 113.741 33.2333 113.019C32.7824 112.388 29.8965 112.478 29.8965 111.711Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M184.249 81.8007C184.249 81.1239 186.188 81.7105 186.82 81.2593C187.406 80.8081 186.82 78.2363 187.721 78.3265C188.623 78.4168 188.127 80.763 188.533 81.2141C188.894 81.7104 191.103 81.169 191.013 81.936C190.923 82.7031 189.209 82.1616 188.623 82.8384C188.082 83.5152 188.353 86.0419 187.541 85.9967C186.729 85.9516 186.865 83.2445 186.594 82.7482C186.324 82.297 184.249 82.3421 184.249 81.8007Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M196.424 146.547C196.424 145.87 198.363 146.457 198.995 146.005C199.581 145.554 198.995 142.982 199.896 143.073C200.798 143.163 200.302 145.509 200.708 145.96C201.069 146.457 203.278 145.915 203.188 146.682C203.098 147.449 201.385 146.908 200.798 147.585C200.257 148.261 200.528 150.788 199.716 150.743C198.904 150.698 199.04 147.991 198.769 147.494C198.499 147.043 196.424 147.088 196.424 146.547Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M73.1406 38.621C73.1406 37.9442 75.0796 38.5308 75.7109 38.0796C76.2971 37.6284 75.7109 35.0566 76.6128 35.1468C77.5146 35.2371 77.0186 37.5833 77.4245 38.0345C77.7852 38.5308 79.9948 37.9893 79.9046 38.7564C79.8144 39.5234 78.1008 38.9819 77.5146 39.6587C76.9735 40.3355 77.2441 42.8622 76.4324 42.817C75.6207 42.7719 75.756 40.0648 75.4855 39.5685C75.2149 39.0722 73.1406 39.1624 73.1406 38.621Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M47.7983 73.0038C47.7983 72.327 49.7373 72.9136 50.3686 72.4624C50.9548 72.0112 50.3686 69.4394 51.2705 69.5297C52.1724 69.6199 51.6763 71.9661 52.0822 72.4173C52.4429 72.9136 54.6525 72.3721 54.5623 73.1392C54.4721 73.9062 52.7586 73.3648 52.1724 74.0415C51.6312 74.7183 51.9018 77.245 51.0901 77.1999C50.2785 77.1547 50.4137 74.4476 50.1432 73.9513C49.8275 73.5001 47.7983 73.5452 47.7983 73.0038Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M34.4507 74.8517C34.4507 74.3554 35.8035 74.7614 36.2093 74.4456C36.6151 74.1298 36.2093 72.3701 36.8406 72.4604C37.4719 72.5055 37.1112 74.1298 37.3817 74.4456C37.6523 74.7614 39.1403 74.4005 39.0952 74.9419C39.0051 75.4833 37.8326 75.0773 37.4719 75.5736C37.0661 76.0248 37.2915 77.7393 36.7504 77.7393C36.2093 77.6942 36.2995 75.8443 36.074 75.5285C35.8486 75.2126 34.4507 75.2126 34.4507 74.8517Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M45.0476 42.0509C45.0476 41.5546 46.4004 41.9607 46.8062 41.6448C47.2121 41.329 46.8062 39.5694 47.4375 39.6596C48.0688 39.7047 47.7081 41.329 47.9786 41.6448C48.2492 41.9607 49.7373 41.5997 49.6922 42.1411C49.602 42.6826 48.4296 42.2765 48.0688 42.7728C47.663 43.224 47.8885 44.9385 47.3473 44.9385C46.8062 44.8934 46.8964 43.0435 46.6709 42.7277C46.4455 42.4118 45.0476 42.4118 45.0476 42.0509Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M175.637 44.3517C175.637 43.8554 176.989 44.2614 177.395 43.9456C177.801 43.6298 177.395 41.8701 178.027 41.9604C178.658 42.0055 178.297 43.6298 178.568 43.9456C178.838 44.2614 180.326 43.9005 180.281 44.4419C180.191 44.9833 179.019 44.5773 178.658 45.0736C178.252 45.5248 178.478 47.2393 177.936 47.2393C177.395 47.1942 177.486 45.3443 177.26 45.0285C177.035 44.6675 175.637 44.7126 175.637 44.3517Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M135.549 51.208C135.008 51.1629 135.098 49.313 134.873 48.9972C134.692 48.6814 133.294 48.7265 133.294 48.3204C133.294 47.8241 134.647 48.2302 135.053 47.9144C135.459 47.5985 135.053 45.8389 135.684 45.9291C136.316 45.9743 135.955 47.5985 136.225 47.9144C136.496 48.2302 137.984 47.8692 137.939 48.4107C137.894 48.9521 136.676 48.546 136.316 49.0423C135.91 49.4935 136.09 51.208 135.549 51.208Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M231.011 126.966C230.469 126.921 230.56 125.071 230.334 124.755C230.154 124.439 228.756 124.484 228.756 124.078C228.756 123.582 230.109 123.988 230.514 123.672C230.92 123.356 230.514 121.597 231.146 121.687C231.777 121.732 231.416 123.356 231.687 123.672C231.957 123.988 233.446 123.627 233.4 124.168C233.355 124.71 232.138 124.304 231.777 124.8C231.326 125.251 231.552 127.011 231.011 126.966Z"
                        fill="#EBF7F6"
                    />
                    <path
                        opacity="0.44"
                        d="M42.5225 156.2C41.9813 156.155 42.0715 154.305 41.8461 153.989C41.6657 153.674 40.2678 153.719 40.2678 153.313C40.2678 152.816 41.6206 153.222 42.0264 152.907C42.4323 152.591 42.0264 150.831 42.6577 150.921C43.289 150.966 42.9283 152.591 43.1989 152.907C43.4694 153.222 44.9575 152.861 44.9124 153.403C44.8222 153.944 43.6498 153.538 43.289 154.035C42.8832 154.531 43.0636 156.245 42.5225 156.2Z"
                        fill="#EBF7F6"
                    />
                </g>
                <path
                    d="M82.4749 197.983C82.4749 199.562 81.2123 200.826 79.6341 200.826C78.0558 200.826 76.7932 199.562 76.7932 197.983C76.7932 196.404 78.0558 195.141 79.6341 195.141C81.2123 195.141 82.4749 196.404 82.4749 197.983Z"
                    fill="#3D3E3F"
                />
                <path
                    d="M94.6949 197.983C94.6949 199.562 93.4323 200.826 91.854 200.826C90.2758 200.826 89.0132 199.562 89.0132 197.983C89.0132 196.404 90.2758 195.141 91.854 195.141C93.4323 195.141 94.6949 196.404 94.6949 197.983Z"
                    fill="#3D3E3F"
                />
                <path
                    d="M110.613 197.983C110.613 199.562 109.35 200.826 107.772 200.826C106.194 200.826 104.931 199.562 104.931 197.983C104.931 196.404 106.194 195.141 107.772 195.141C109.35 195.141 110.613 196.404 110.613 197.983Z"
                    fill="#3D3E3F"
                />
                <path
                    d="M122.201 197.983C122.201 199.562 120.939 200.826 119.361 200.826C117.782 200.826 116.52 199.562 116.52 197.983C116.52 196.404 117.782 195.141 119.361 195.141C120.939 195.141 122.201 196.404 122.201 197.983Z"
                    fill="#3D3E3F"
                />
                <path d="M103.218 160.129H95.7322V184.358H103.218V160.129Z" fill="#8E8A88" />
                <path d="M104.119 174.387H94.7852V188.689H104.119V174.387Z" fill="#5B5757" />
                <path
                    d="M121.48 197.848H118.459V194.78L102.541 188.464L103.668 185.621L121.48 192.705V197.848Z"
                    fill="#5B5757"
                />
                <path
                    d="M109.576 197.848H106.554V194.78L98.3926 188.464L99.4748 185.621L109.576 192.705V197.848Z"
                    fill="#5B5757"
                />
                <path
                    d="M90.5012 197.848H93.5224V194.78L101.684 188.464L100.602 185.621L90.5012 192.705V197.848Z"
                    fill="#5B5757"
                />
                <path
                    d="M81.3025 197.848H78.2812V192.705L96.138 185.621L97.2202 188.464L81.3025 194.78V197.848Z"
                    fill="#5B5757"
                />
                <path
                    d="M79.5888 194.418C77.5596 194.418 75.9363 196.042 75.9363 198.073H83.2413C83.2413 196.042 81.618 194.418 79.5888 194.418Z"
                    fill="#5B5757"
                />
                <path
                    d="M91.8542 194.418C89.825 194.418 88.2017 196.042 88.2017 198.073H95.5067C95.5067 196.042 93.8383 194.418 91.8542 194.418Z"
                    fill="#5B5757"
                />
                <path
                    d="M107.817 194.418C105.788 194.418 104.164 196.042 104.164 198.073H111.469C111.469 196.042 109.801 194.418 107.817 194.418Z"
                    fill="#5B5757"
                />
                <path
                    d="M119.631 194.418C117.602 194.418 115.979 196.042 115.979 198.073H123.284C123.284 196.042 121.66 194.418 119.631 194.418Z"
                    fill="#5B5757"
                />
                <path
                    d="M132.888 201.774H135.053V194.51C135.053 194.51 138.57 198.345 141.276 201.233C141.501 201.459 141.727 201.639 141.997 201.82H151.963C151.963 199.609 150.565 198.03 149.302 197.082C147.634 195.864 146.101 194.42 144.838 192.751C143.575 191.036 142.989 189.412 141.366 188.058L138.886 186.028C138.886 186.028 135.369 183.727 132.212 186.885C129.011 190.089 132.392 195.187 132.392 195.187L132.888 201.774Z"
                    fill="#2E3435"
                />
                <path
                    d="M140.689 186.16L140.464 149.388C140.554 146.184 139.201 143.071 136.766 140.905L134.422 140.184L123.689 140.454L126.305 147.718L128.559 152.546C127.477 165.856 129.642 169.511 130.904 174.339C132.077 178.805 133.069 183.723 133.926 187.107C134.106 187.829 134.106 188.596 134.557 189.137C136.721 191.754 143.17 199.154 145.154 197.484C147.498 195.409 140.689 186.16 140.689 186.16Z"
                    fill="#EA967D"
                />
                <path
                    d="M120.939 201.774H123.103V194.51C123.103 194.51 126.621 198.345 129.326 201.233C129.552 201.459 129.777 201.639 130.003 201.82H140.013C140.013 199.609 138.615 198.03 137.353 197.082C135.684 195.864 134.151 194.42 132.889 192.751C131.626 191.036 131.04 189.412 129.416 188.058L126.936 186.028C126.936 186.028 123.419 183.727 120.263 186.885C118.324 188.825 118.82 191.487 119.451 193.247C120.082 194.961 120.533 196.721 120.668 198.526L120.939 201.774Z"
                    fill="#2E3435"
                />
                <path d="M85.8566 127.777H79.769V147.584H85.8566V127.777Z" fill="#724141" />
                <path
                    d="M118.684 162.654H77.6495C76.5222 162.654 75.6204 161.752 75.6204 160.624C75.6204 159.496 76.5222 158.594 77.6495 158.594H118.684C119.811 158.594 120.713 159.496 120.713 160.624C120.713 161.752 119.811 162.654 118.684 162.654Z"
                    fill="#724141"
                />
                <path
                    d="M116.926 160.941H78.8672C75.8911 160.941 73.4561 158.505 73.4561 155.527V152.594C73.4561 149.616 75.8911 147.18 78.8672 147.18H116.926C119.902 147.18 122.337 149.616 122.337 152.594V155.527C122.337 158.505 119.902 160.941 116.926 160.941Z"
                    fill="#C11414"
                />
                <path
                    d="M79.2281 157.916V150.246C79.2281 148.576 80.5809 147.223 82.2493 147.223H76.4324C74.7639 147.223 73.4111 148.576 73.4111 150.246V157.916C73.4111 159.585 74.7639 160.939 76.4324 160.939H82.2493C80.626 160.939 79.2281 159.585 79.2281 157.916Z"
                    fill="#994F5B"
                />
                <path
                    d="M122.337 157.916V150.246C122.337 148.576 120.984 147.223 119.316 147.223H82.2944C80.626 147.223 79.2732 148.576 79.2732 150.246V157.916C79.2732 159.585 80.626 160.939 82.2944 160.939H119.316C120.939 160.939 122.337 159.585 122.337 157.916Z"
                    fill="#A5626F"
                />
                <path
                    d="M128.695 186.16L129.777 151.96C129.867 148.756 128.515 145.643 126.08 143.477L122.427 140.184L116.79 150.38C114.987 164.818 118.008 167.841 119.09 172.94C120.127 177.813 121.345 182.956 122.021 187.062C122.156 187.784 122.111 188.506 122.607 189.047C124.772 191.664 131.22 199.064 133.204 197.394C135.549 195.409 128.695 186.16 128.695 186.16Z"
                    fill="#EF9D7D"
                />
                <path
                    d="M76.6127 98.1355C76.6127 97.0526 76.9283 96.1051 77.5145 95.293H76.0715C73.5914 95.293 71.5623 97.3233 71.5623 99.8048V127.869C71.5623 130.35 73.5914 132.381 76.0715 132.381H77.5145C76.9283 131.568 76.6127 130.576 76.6127 129.538V98.1355Z"
                    fill="#F23636"
                />
                <path
                    d="M98.7984 127.822V99.758C98.7984 97.2764 96.7692 95.2461 94.2891 95.2461H77.4695C76.8833 96.0582 76.5676 97.0508 76.5676 98.0886V129.401C76.5676 130.484 76.8833 131.431 77.4695 132.243H94.2891C96.7692 132.289 98.7984 130.303 98.7984 127.822Z"
                    fill="#F23636"
                />
                <path
                    d="M76.6127 98.1355C76.6127 97.0526 76.9283 96.1051 77.5145 95.293H76.0715C73.5914 95.293 71.5623 97.3233 71.5623 99.8048V127.869C71.5623 130.35 73.5914 132.381 76.0715 132.381H77.5145C76.9283 131.568 76.6127 130.576 76.6127 129.538V98.1355Z"
                    fill="#994F5B"
                />
                <path
                    d="M98.7984 127.822V99.758C98.7984 97.2764 96.7692 95.2461 94.2891 95.2461H77.4695C76.8833 96.0582 76.5676 97.0508 76.5676 98.0886V129.401C76.5676 130.484 76.8833 131.431 77.4695 132.243H94.2891C96.7692 132.289 98.7984 130.303 98.7984 127.822Z"
                    fill="#A5626F"
                />
                <path
                    d="M127.387 124.345L135.459 123.082L143.395 127.052L144.703 129.94H125.628L127.387 124.345Z"
                    fill="#EF9D7D"
                />
                <path
                    d="M111.019 98.6317C110.387 97.233 108.493 96.9623 107.546 98.1805C106.69 99.3085 105.743 101.068 105.157 103.775C104.3 107.565 103.714 113.295 103.398 117.446C103.172 120.244 105.382 129.809 108.223 129.809H131.897V122.229L116.385 121.958C114.446 121.958 112.236 118.71 113.363 113.747C114.265 109.55 113.454 104.001 111.019 98.6317Z"
                    fill="#6555A9"
                />
                <path
                    d="M117.106 136.261C115.392 134.862 113.589 133.599 111.695 132.426C109.485 131.027 106.329 128.546 106.599 125.793C106.599 125.658 106.644 125.478 106.644 125.342C106.915 123.222 108.042 121.282 109.801 120.018C112.101 118.304 115.934 113.837 113.138 104.542L111.424 99.038C110.838 97.2333 109.26 95.9248 107.366 95.7443L106.96 95.6992L88.6524 95.7443L82.7903 96.2858C82.7903 96.2858 85.1351 107.385 86.8486 115.867C87.7505 120.244 89.1935 122.635 88.5171 125.162C87.5701 128.816 86.5781 132.516 85.0449 135.9C82.4746 141.585 85.3155 152.639 92.846 152.639H116.79C118.955 146.954 123.374 142.713 129.326 141.495L136.27 140.367L117.106 136.261Z"
                    fill="#6F5FB2"
                />
                <path
                    d="M102.902 91.503C102.902 91.503 106.599 93.037 110.297 90.7811C112.777 89.247 112.777 79.2758 110.477 76.5686C108.177 73.8615 106.148 73.8164 106.148 73.8164L102.902 91.503Z"
                    fill="#AF593A"
                />
                <path
                    d="M101.729 88.3897V94.3905L106.96 95.6539C106.96 95.6539 108.448 114.107 99.5649 123.673C99.5649 123.673 98.0768 104.181 88.6975 95.699L93.8832 94.6612L93.8381 86.3594L101.729 88.3897Z"
                    fill="#EF9D7D"
                />
                <path
                    d="M106.148 73.8149C105.833 71.1077 104.796 68.8969 101.774 67.9043C98.8434 66.9568 94.0635 67.1824 91.6736 69.3932C88.5622 72.2357 88.2015 76.5671 88.472 80.4925C88.923 86.4481 94.6047 92.3136 100.737 92.3587C102.135 92.3587 103.398 91.5917 104.48 90.7795C105.968 89.6967 107.411 88.3882 107.997 86.6286C109.26 82.8837 106.329 79.3194 106.194 75.6196C106.239 75.0331 106.239 74.4014 106.148 73.8149Z"
                    fill="#FFB08D"
                />
                <path
                    d="M89.1034 77.4687L89.5994 78.1906C88.6975 77.0626 87.1644 76.7468 86.2625 77.6491C85.2705 78.6418 85.3606 80.8977 86.4429 82.1159C87.5251 83.3341 89.3288 83.2439 90.0503 81.9354C90.7718 80.627 90.3209 78.4162 89.1034 77.4687Z"
                    fill="#FFB08D"
                />
                <path
                    d="M94.0186 103.051C98.6631 112.21 99.565 123.715 99.565 123.715C104.841 118.03 106.464 109.277 106.915 103.051H94.0186V103.051Z"
                    fill="#F1F1F1"
                />
                <path
                    d="M98.257 67.453C98.257 67.453 89.6443 66.5055 89.1032 77.4693C88.517 88.4332 97.8512 91.9525 97.8512 91.9525C97.8512 91.9525 85.8114 94.0279 83.5568 88.8393C81.257 83.6506 81.8883 63.0764 95.3711 63.0764L97.8512 63.4825C97.8512 63.4825 102.406 60.5949 106.96 65.964C111.514 71.3783 116.925 87.5759 110.071 90.8696L109.395 89.4258C108.538 87.5759 108.223 85.5456 108.538 83.5604L109.44 78.0107C109.485 78.0559 98.257 72.416 98.257 67.453Z"
                    fill="#AF593A"
                />
                <path
                    d="M99.565 68.6731C98.5729 67.7708 97.7613 66.7782 97.0398 65.6953C95.191 66.6879 93.5676 67.7708 92.4403 68.8085C88.5172 72.2827 87.5252 76.1629 87.2546 79.8175C90.8621 80.9906 101.459 76.5689 103.398 71.651C102.045 70.7937 100.782 69.8011 99.565 68.6731Z"
                    fill="#AF593A"
                />
                <path
                    d="M104.39 124.213L112.461 122.949L120.398 126.875L121.75 129.807H102.631L104.39 124.213Z"
                    fill="#EF9D7D"
                />
                <path
                    d="M82.5994 96C82.5994 96 79.2625 96.9475 77.7745 103.49C76.9177 107.28 76.3315 118.559 76.0158 122.71C75.7904 125.508 77.9999 129.613 80.8408 129.613H104.515V122.034L89.0026 121.763C87.0636 121.763 86.0715 119.507 86.9734 113.01C88.1007 104.843 87.1087 100.737 82.5994 96Z"
                    fill="#6555A9"
                />
                <path d="M149.212 126.062H106.78V129.898H149.212V126.062Z" fill="#DCE9F9" />
                <path d="M150.835 113.067L148.22 110.586L141.546 120.467H151.376L150.835 113.067Z" fill="#565656" />
                <path d="M161.613 127.281H152.098V128.319H161.613V127.281Z" fill="#ADADAD" />
                <path
                    d="M179.244 88.2969H133.655C132.888 88.2969 132.212 88.8383 131.987 89.5602L124.005 118.256C123.689 119.384 124.546 120.467 125.674 120.467H171.262C172.029 120.467 172.705 119.925 172.931 119.203L180.912 90.5077C181.228 89.3797 180.371 88.2969 179.244 88.2969Z"
                    fill="#ADADAD"
                />
                <path
                    d="M150.339 128.317V110.676H148.22V127.279H136.766V129.851H138.841V128.317H150.339Z"
                    fill="#F4F4F4"
                />
                <path
                    d="M167.249 128.317H157.734V127.279H155.66V110.676H150.339V128.317H138.841V129.851H167.249V128.317Z"
                    fill="#ADADAD"
                />
                <path
                    d="M128.289 118.256L136.27 89.5602C136.496 88.7932 137.172 88.2969 137.939 88.2969H133.655C132.888 88.2969 132.212 88.8383 131.987 89.5602L124.005 118.256C123.689 119.384 124.546 120.467 125.674 120.467H129.957C128.83 120.467 127.973 119.339 128.289 118.256Z"
                    fill="#F4F4F4"
                />
                <path
                    d="M150.069 91.8633C149.843 91.8633 149.618 91.9986 149.528 92.1791L147.093 98.0445C147.003 98.225 147.138 98.3604 147.363 98.3604C147.589 98.3604 147.814 98.225 147.904 98.0445L150.339 92.1791C150.384 91.9986 150.294 91.8633 150.069 91.8633Z"
                    fill="#565656"
                />
                <path
                    d="M152.414 91.8633C152.188 91.8633 151.963 91.9986 151.872 92.1791L149.437 98.0445C149.347 98.225 149.483 98.3604 149.708 98.3604C149.933 98.3604 150.159 98.225 150.249 98.0445L152.684 92.1791C152.729 91.9986 152.639 91.8633 152.414 91.8633Z"
                    fill="#565656"
                />
                <path
                    d="M154.759 91.8633C154.533 91.8633 154.308 91.9986 154.217 92.1791L151.782 98.0445C151.692 98.225 151.827 98.3604 152.053 98.3604C152.278 98.3604 152.504 98.225 152.594 98.0445L155.029 92.1791C155.074 91.9986 154.984 91.8633 154.759 91.8633Z"
                    fill="#565656"
                />
                <path
                    d="M157.103 91.8633C156.878 91.8633 156.653 91.9986 156.562 92.1791L154.127 98.0445C154.037 98.225 154.172 98.3604 154.398 98.3604C154.623 98.3604 154.849 98.225 154.939 98.0445L157.374 92.1791C157.419 91.9986 157.329 91.8633 157.103 91.8633Z"
                    fill="#565656"
                />
                <path
                    d="M159.448 91.8633C159.223 91.8633 158.997 91.9986 158.907 92.1791L156.472 98.0445C156.382 98.225 156.517 98.3604 156.742 98.3604C156.968 98.3604 157.193 98.225 157.284 98.0445L159.719 92.1791C159.764 91.9986 159.673 91.8633 159.448 91.8633Z"
                    fill="#565656"
                />
                <path
                    d="M161.793 91.8633C161.567 91.8633 161.342 91.9986 161.252 92.1791L158.817 98.0445C158.727 98.225 158.862 98.3604 159.087 98.3604C159.313 98.3604 159.538 98.225 159.628 98.0445L162.063 92.1791C162.109 91.9986 162.018 91.8633 161.793 91.8633Z"
                    fill="#565656"
                />
                <path
                    d="M98.753 133.011V130.98H62.9042V133.011H62.5435V201.095H67.0527V142.26H97.2649V201.095H101.729V133.011H98.753Z"
                    fill="#2E3435"
                />
                <path
                    d="M192.456 133.011V130.98H156.607V133.011H156.292V201.095H160.756V142.26H190.968V201.095H195.432V133.011H192.456Z"
                    fill="#2E3435"
                />
                <path d="M198.949 129.809H67.9097V136.667H198.949V129.809Z" fill="#CC9417" />
                <path d="M104.615 129.809H58.8911V136.667H104.615V129.809Z" fill="#F4BC4A" />
                <path
                    d="M91.4481 79.3616C91.4481 80.8957 90.2306 82.1139 88.6974 82.1139C87.1643 82.1139 85.9468 80.8957 85.9468 79.3616C85.9468 77.8276 87.1643 76.6094 88.6974 76.6094C90.1855 76.6094 91.4481 77.8727 91.4481 79.3616Z"
                    fill="#3F4344"
                />
                <path
                    d="M98.212 91.2736H94.83C93.6576 91.2736 92.5754 90.8224 91.7186 90.0103L89.1483 87.4385C88.3367 86.6264 87.8857 85.4984 87.8857 84.3253V80.9414H88.968V84.3253C88.968 85.1826 89.3287 86.0398 89.9149 86.6264L92.4852 89.1982C93.1165 89.8298 93.9282 90.1456 94.785 90.1456H98.1669V91.2736H98.212Z"
                    fill="#3F4344"
                />
                <path
                    d="M99.1588 89.332H97.8962C97.31 89.332 96.814 89.8283 96.814 90.4149C96.814 91.0014 97.31 91.4977 97.8962 91.4977H99.1588C99.745 91.4977 100.241 91.0014 100.241 90.4149C100.241 89.8283 99.745 89.332 99.1588 89.332Z"
                    fill="#3F4344"
                />
                <path
                    d="M111.379 72.9565V72.2797C111.154 65.6472 106.058 61 99.4747 61C92.8912 61 87.5251 66.3691 87.5251 72.9565V78.7317H88.8779V72.9565C88.8779 67.1362 93.6127 62.3536 99.4747 62.3536C104.39 62.3536 108.584 65.7375 109.711 70.3396C110.162 71.242 110.793 72.5504 111.379 74.2198V72.9565V72.9565Z"
                    fill="#3F4344"
                />
                <path
                    d="M45.5881 192.302C44.551 191.264 43.4237 190.001 43.5139 188.512C43.6491 186.571 45.8587 185.534 46.1293 183.594C46.4449 181.654 44.6863 179.759 44.9568 177.818C45.1372 176.736 45.9038 175.878 46.7606 175.292C47.6173 174.705 48.5643 174.209 49.2858 173.442C50.368 172.269 50.7738 170.509 51.6306 169.111C52.3521 167.892 53.4343 166.945 54.6518 166.358C55.734 165.862 57.3574 165.862 57.8985 167.08C58.3043 167.937 57.9436 168.885 57.4927 169.652C57.0417 170.419 56.5457 171.186 56.5457 172.133C56.5457 173.577 57.8985 174.66 58.5298 175.969C59.116 177.097 59.1611 178.54 58.3945 179.443C57.673 180.3 56.2751 180.661 56.2751 181.834C56.2751 182.782 57.3123 183.458 57.4476 184.406C57.7181 186.12 55.2831 186.887 55.1929 188.602C55.1478 189.685 56.1399 190.768 55.7791 191.805C55.6438 192.256 55.238 192.572 54.8322 192.843C53.1637 193.926 51.2698 194.693 49.3308 194.918C47.7526 195.099 46.7606 193.475 45.5881 192.302Z"
                    fill="#13B797"
                />
                <path
                    d="M50.1424 195.641C48.8798 188.873 49.3758 178.992 54.7869 169.246"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.4502 176.919C52.2168 176.242 53.0284 175.791 53.9303 175.43"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.9622 183.684C50.7287 183.007 51.5404 182.556 52.4423 182.195"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.6013 190.544C50.3679 189.867 51.1796 189.416 52.0814 189.055"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M47.8879 186.48C48.5643 186.977 49.0604 187.473 49.5564 188.105"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.0603 177.773C49.7367 178.27 50.2327 178.766 50.7287 179.398"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.7366 192.349C49.3759 191.492 48.97 190.454 49.421 189.597C49.9621 188.469 51.5403 188.424 52.2167 187.386C52.848 186.348 52.2618 184.769 52.8931 183.732C53.2538 183.145 53.9302 182.829 54.6066 182.694C55.283 182.559 55.9594 182.513 56.5907 182.243C57.5377 181.837 58.2141 180.889 59.0257 180.303C59.7472 179.761 60.6491 179.49 61.5058 179.445C62.2724 179.445 63.2645 179.806 63.3095 180.664C63.3546 181.25 62.9037 181.746 62.4528 182.107C62.0019 182.468 61.5058 182.784 61.2804 183.326C60.9196 184.183 61.4607 185.13 61.596 186.078C61.6862 186.89 61.3706 187.747 60.6942 188.063C60.0629 188.379 59.1159 188.243 58.8454 188.965C58.6199 189.507 59.0708 190.184 58.9355 190.77C58.7101 191.853 56.9966 191.673 56.5456 192.665C56.23 193.297 56.5907 194.199 56.1398 194.695C55.9143 194.921 55.5987 195.011 55.3281 195.056C54.0655 195.282 52.7127 195.237 51.4952 194.876C50.3679 194.515 50.1875 193.342 49.7366 192.349Z"
                    fill="#02C6A1"
                />
                <path
                    d="M51.6755 195.416C52.5323 191.13 55.1928 185.445 60.8745 181.113"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M56.9966 184.771C57.6279 184.545 58.2141 184.5 58.8454 184.5"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.4714 188.38C55.1027 188.155 55.6889 188.109 56.3202 188.109"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.6226 192.306C53.2539 192.08 53.8401 192.035 54.4714 192.035"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.5322 189.508C52.8028 189.959 52.9832 190.41 53.1635 190.906"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M55.3281 184.727C55.5987 185.178 55.7791 185.629 55.9594 186.125"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.6756 189.823C52.3069 188.199 52.9833 186.304 52.2168 184.679C51.2247 182.559 48.474 182.378 47.3467 180.393C46.2194 178.363 47.2565 175.475 46.1292 173.49C45.4979 172.317 44.2804 171.775 43.1531 171.459C42.0258 171.144 40.8083 171.053 39.726 170.512C38.1027 169.655 36.9303 167.94 35.4422 166.722C34.1796 165.684 32.6465 165.098 31.1133 165.008C29.7605 164.917 28.047 165.639 28.0019 167.218C27.9568 168.301 28.7234 169.249 29.49 169.925C30.2565 170.602 31.1584 171.234 31.5642 172.272C32.1955 173.896 31.2486 175.656 31.0682 177.415C30.9329 178.904 31.5191 180.573 32.6916 181.25C33.8189 181.882 35.4422 181.701 35.9382 183.01C36.3441 184.093 35.5775 185.266 35.803 186.394C36.2088 188.424 39.1398 188.199 39.9515 190.048C40.4926 191.267 39.9064 192.891 40.673 193.884C41.0337 194.335 41.5748 194.515 42.116 194.605C44.2804 195.057 46.6703 195.102 48.7897 194.515C50.6385 193.884 50.9542 191.628 51.6756 189.823Z"
                    fill="#00DFB3"
                />
                <path
                    d="M48.3388 195.51C46.8056 187.434 42.0709 176.605 32.1956 168.168"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M38.9593 175.247C37.832 174.841 36.84 174.705 35.7126 174.66"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M43.4237 182.153C42.2964 181.747 41.3043 181.612 40.177 181.566"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.7154 189.641C45.5881 189.235 44.5961 189.1 43.4688 189.055"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.8056 184.32C46.3096 185.132 45.9939 185.945 45.7234 186.892"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M41.8454 175.203C41.3494 176.015 41.0337 176.827 40.7632 177.775"
                    stroke="white"
                    strokeWidth="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M49.6464 203.851C54.4713 203.851 58.3944 199.925 58.3944 195.098H40.8983C40.8532 199.925 44.7763 203.851 49.6464 203.851Z"
                    fill="#E0B594"
                />
                <path
                    d="M51.7658 203.763C51.405 203.808 51.0443 203.808 50.6836 203.808C45.8586 203.808 41.9355 199.882 41.9355 195.055H44.1C44.1 199.521 47.482 203.221 51.7658 203.763Z"
                    fill="#FFEBDC"
                />
                <path
                    d="M58.4846 204.259H40.718C40.718 203.672 41.214 203.176 41.8002 203.176H57.4024C58.0337 203.176 58.4846 203.672 58.4846 204.259Z"
                    fill="#E0B594"
                />
                <path d="M58.5748 195.055H40.5828V195.325H58.5748V195.055Z" fill="#E0B594" />
                <path
                    d="M73.0955 57.3016C70.1194 49.4509 61.3713 45.5256 53.5252 48.5034C45.679 51.4813 41.756 60.2343 44.7321 68.085C47.7082 75.9356 56.4562 79.861 64.3024 76.8831C66.1061 76.2064 67.6392 75.2137 68.992 74.0407L74.9894 75.2589L72.3289 69.7544C74.2679 65.9644 74.6737 61.4976 73.0955 57.3016Z"
                    fill="#FF687B"
                />
                <path d="M58.5755 63.7529L50.9548 57.707V67.0466H66.1511V57.707L58.5755 63.7529Z" fill="white" />
                <path d="M64.663 56.4883H52.4429L58.5755 61.3611L64.663 56.4883Z" fill="white" />
                <path
                    d="M115.528 34.1976C119.226 26.0762 115.663 16.4659 107.546 12.7662C99.4298 9.06643 89.825 12.6308 86.1274 20.7522C82.4298 28.8736 85.9921 38.4839 94.1088 42.1836C95.9576 43.0409 97.8966 43.4921 99.7905 43.6274L103.398 49.0868L105.607 42.9506C109.846 41.5971 113.544 38.529 115.528 34.1976Z"
                    fill="#5BD9EC"
                />
                <path
                    d="M101.324 31.7188L97.2202 31.403V26.3497C97.3555 26.4399 97.581 26.4851 97.9868 26.5753C98.3926 26.6655 98.7534 26.7107 99.0239 26.7107C99.7003 26.7107 100.287 26.5302 100.783 26.1692C101.279 25.8083 101.504 25.312 101.504 24.7254C101.504 24.184 101.324 23.6877 100.963 23.3267C100.602 22.9658 100.061 22.7402 99.4298 22.7402C98.7534 22.7402 98.2574 22.9207 97.8966 23.3267C97.5359 23.7328 97.3104 24.184 97.1751 24.7705L94.0637 23.5072C94.1539 22.5597 94.4696 21.6573 95.0558 20.8903C95.642 20.0782 96.3634 19.4465 97.2653 18.9953C98.1672 18.5441 99.1592 18.2734 100.196 18.2734C101.369 18.2734 102.451 18.5893 103.398 19.2209C104.345 19.8526 105.066 20.6647 105.607 21.6573C106.149 22.65 106.374 23.7328 106.374 24.8157C106.374 26.0339 106.058 27.0716 105.472 27.8837C104.886 28.741 104.165 29.3275 103.308 29.7336C102.496 30.1397 101.775 30.3202 101.143 30.3202L101.324 31.7188ZM97.3555 37.2233C96.8595 36.6819 96.5889 36.0502 96.5889 35.2832C96.5889 34.5162 96.8595 33.8845 97.4006 33.3431C97.9417 32.8017 98.573 32.5761 99.2945 32.5761C99.9709 32.5761 100.557 32.8017 101.008 33.298C101.504 33.7492 101.729 34.3808 101.729 35.193C101.729 36.0051 101.504 36.6368 101.008 37.2233C100.512 37.7648 99.9258 38.0355 99.1592 38.0355C98.4828 38.0806 97.8966 37.7648 97.3555 37.2233Z"
                    fill="white"
                />
                <path
                    d="M122.788 57.2551C122.788 48.4569 129.913 41.3281 138.706 41.3281C147.499 41.3281 154.623 48.4569 154.623 57.2551C154.623 66.0532 147.499 73.182 138.706 73.182C136.722 73.182 134.783 72.821 133.024 72.1443L127.568 75.5733L128.109 69.1664C124.862 66.2337 122.788 61.9925 122.788 57.2551Z"
                    fill="#FFBC02"
                />
                <path
                    d="M147.138 61.5881C145.83 60.5504 145.064 60.009 144.252 59.3773C143.666 58.9261 142.358 60.7309 141.592 61.0016C139.247 61.7235 136.902 59.3322 136.902 59.3322C136.902 59.3322 134.467 56.9409 135.234 54.6398C135.504 53.8728 137.308 52.5644 136.857 51.9778C136.226 51.1657 135.73 50.3987 134.647 49.0902C134.196 48.5488 131.942 50.128 131.04 51.2559C129.507 53.196 130.409 57.1665 134.783 61.543C139.157 65.9195 143.125 66.7768 145.064 65.2879C146.101 64.2953 147.679 62.0393 147.138 61.5881Z"
                    fill="white"
                />
                <g opacity="0.7">
                    <path
                        d="M221.789 154.019C221.469 153.948 219.513 153.486 218.944 152.561C218.517 151.85 218.019 150.356 217.912 150.036V150V150.036C217.806 150.356 217.308 151.85 216.845 152.561C216.276 153.486 214.32 153.948 214 154.019C214.32 154.09 216.276 154.553 216.845 155.477C217.272 156.189 217.806 157.683 217.877 158.003V158.038V158.003C217.984 157.683 218.482 156.189 218.908 155.477C219.549 154.553 221.505 154.09 221.789 154.019Z"
                        fill="url(#paint0_linear)"
                    />
                    <path
                        d="M23.6827 119.948C23.3626 119.877 21.4419 119.45 20.8728 118.525C20.446 117.814 19.948 116.356 19.8413 116.036V116V116.036C19.7346 116.356 19.2367 117.85 18.8099 118.525C18.2408 119.45 16.3201 119.877 16 119.948C16.3201 120.019 18.2408 120.482 18.8099 121.371C19.2367 122.082 19.7346 123.54 19.8413 123.861V123.896V123.861C19.948 123.54 20.446 122.047 20.8728 121.371C21.4775 120.446 23.3981 120.019 23.6827 119.948Z"
                        fill="url(#paint1_linear)"
                    />
                    <path
                        d="M43.0138 96.2914C42.4447 96.1847 38.9234 95.3311 37.892 93.6594C37.1095 92.379 36.1847 89.6402 36.0069 89.0711V89V89.0356V89.0711C35.829 89.6402 34.9043 92.379 34.1218 93.6594C33.0903 95.3667 29.5335 96.1847 29 96.2914C29.5691 96.3981 33.0903 97.2518 34.1218 98.9235C34.9043 100.204 35.829 102.943 36.0069 103.512V103.583V103.547V103.512C36.1847 102.943 37.1095 100.204 37.892 98.9235C38.9234 97.2518 42.4802 96.4337 43.0138 96.2914Z"
                        fill="url(#paint2_linear)"
                    />
                    <path
                        d="M30.1166 132.698C29.9743 132.662 29.0495 132.449 28.765 132.022C28.5516 131.702 28.3382 130.99 28.267 130.812C28.2315 130.955 27.9825 131.666 27.7691 132.022C27.4845 132.449 26.5953 132.662 26.4175 132.698C26.5598 132.733 27.4845 132.947 27.7691 133.373C27.9825 133.694 28.1959 134.405 28.267 134.583C28.3026 134.44 28.5516 133.729 28.765 133.373C29.0495 132.947 29.9743 132.733 30.1166 132.698Z"
                        fill="url(#paint3_linear)"
                    />
                    <path
                        d="M219 81.0151C219.391 81.0862 221.703 81.513 222.45 82.5801C223.019 83.3981 223.695 85.1765 223.837 85.5322V85.5678C223.837 85.5678 223.837 85.5678 223.837 85.5322V85.4967C223.944 85.1054 224.477 83.2914 224.94 82.4378C225.58 81.2996 227.856 80.6594 228.248 80.5527C227.856 80.4816 225.544 80.0547 224.797 78.9877C224.228 78.1696 223.553 76.3912 223.41 76.0356V76C223.41 76 223.41 76 223.41 76.0356V76.0711C223.304 76.4624 222.77 78.2764 222.308 79.13C221.632 80.2682 219.356 80.9084 219 81.0151Z"
                        fill="url(#paint4_linear)"
                    />
                    <path
                        d="M185 64.4501C185.249 64.4856 186.885 64.8058 187.383 65.5527C187.774 66.1218 188.237 67.3311 188.343 67.6156V67.6512C188.415 67.3666 188.77 66.1218 189.126 65.5171C189.553 64.7346 191.153 64.2722 191.402 64.2011C191.153 64.1655 189.517 63.8454 189.019 63.0985C188.628 62.5294 188.166 61.3201 188.059 61.0356V61C187.988 61.2845 187.632 62.5294 187.276 63.1341C186.85 63.9166 185.249 64.3789 185 64.4501Z"
                        fill="url(#paint5_linear)"
                    />
                    <path
                        d="M56 25.0151C56.3913 25.0862 58.7032 25.513 59.4501 26.5801C60.0192 27.3981 60.695 29.1765 60.8373 29.5322V29.5678C60.8373 29.5678 60.8373 29.5678 60.8373 29.5322V29.4967C60.944 29.1054 61.4775 27.2914 61.9399 26.4378C62.5801 25.2996 64.8565 24.6594 65.2477 24.5527C64.8565 24.4816 62.5446 24.0548 61.7976 22.9877C61.2285 22.1697 60.5527 20.3913 60.4105 20.0356V20C60.4105 20 60.4105 20 60.4105 20.0356V20.0711C60.3038 20.4624 59.7702 22.2764 59.3078 23.13C58.632 24.2682 56.3557 24.9084 56 25.0151Z"
                        fill="#E6E7F4"
                    />
                    <path
                        d="M24 58.3475C24.1778 58.383 25.2804 58.5964 25.6361 59.0944C25.8851 59.4856 26.2408 60.3392 26.3119 60.5171V60.5526C26.3475 60.3748 26.5964 59.4856 26.8454 59.0944C27.1655 58.5609 28.2326 58.2408 28.4104 58.2052C28.2326 58.1696 27.1299 57.9562 26.7743 57.4583C26.4897 57.067 26.1696 56.2134 26.0985 56.0356V56C26.0629 56.1778 25.814 57.067 25.565 57.4583C25.2804 57.9918 24.1778 58.3119 24 58.3475Z"
                        fill="url(#paint6_linear)"
                    />
                    <path
                        d="M201 107.347C201.178 107.383 202.28 107.596 202.636 108.094C202.885 108.486 203.241 109.339 203.312 109.517V109.553C203.347 109.375 203.596 108.486 203.845 108.094C204.165 107.561 205.233 107.241 205.41 107.205C205.233 107.17 204.13 106.956 203.774 106.458C203.49 106.067 203.17 105.213 203.098 105.036V105C203.063 105.178 202.814 106.067 202.565 106.458C202.28 106.992 201.178 107.312 201 107.347Z"
                        fill="url(#paint7_linear)"
                    />
                    <path
                        d="M55 90.3475C55.1778 90.3831 56.2804 90.5965 56.6361 91.0944C56.8851 91.4857 57.2408 92.3393 57.3119 92.5171V92.5527C57.3475 92.3749 57.5964 91.4857 57.8454 91.0944C58.1655 90.5609 59.2326 90.2408 59.4104 90.2052C59.2326 90.1696 58.13 89.9562 57.7743 89.4583C57.4897 89.067 57.1696 88.2134 57.0985 88.0356V88C57.0629 88.1778 56.814 89.067 56.565 89.4583C56.2449 89.9918 55.1423 90.3119 55 90.3475Z"
                        fill="url(#paint8_linear)"
                    />
                </g>
                <path
                    d="M228 126C227.774 125.944 226.245 125.611 225.792 124.889C225.453 124.333 225.057 123.222 225 123C224.943 123.222 224.547 124.389 224.208 124.889C223.755 125.611 222.283 125.944 222 126C222.226 126.056 223.755 126.389 224.208 127.111C224.547 127.667 224.943 128.778 225 129C225.057 128.778 225.453 127.611 225.792 127.111C226.245 126.444 227.774 126.056 228 126Z"
                    fill="url(#paint9_linear)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="174.07"
                    y1="106.463"
                    x2="231.441"
                    y2="168.679"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="-10.402"
                    y1="68.8218"
                    x2="26.2715"
                    y2="130.787"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="19.313"
                    y1="68.1346"
                    x2="86.6846"
                    y2="181.969"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="5.01594"
                    y1="93.3525"
                    x2="41.6901"
                    y2="155.319"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="186.389"
                    y1="41.9574"
                    x2="255.854"
                    y2="114.475"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="151.971"
                    y1="26.4604"
                    x2="221.435"
                    y2="98.9781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="-27.5599"
                    y1="2.10423"
                    x2="41.9041"
                    y2="74.6219"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="154.12"
                    y1="56.0222"
                    x2="223.585"
                    y2="128.539"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="6.44224"
                    y1="37.2662"
                    x2="75.9052"
                    y2="109.782"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="155.797"
                    y1="52.3695"
                    x2="245.257"
                    y2="151.215"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="255" height="226" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ChangeSubscriptionContactSVG);
