import request from './request';
import { API_URL, APP_API_PREFIX_ORGANIZATION } from '../config';
import isEmpty from 'lodash/isEmpty';

const memberApis = {
    members: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/users`,
    roles: ({ companyId, userId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/users/${userId}/roles`,
    state: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/users/state`,
    groupMembers: ({ companyId, groupId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/users`,
    groupMember: ({ companyId, groupId, userId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/users/${userId}`,
    getWorkspacesRoles: ({ companyId, userId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/project-roles?userId=${userId}`,
    resendInvitation: companyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/invitation/resend`,
    revokeInvitation: companyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/invitation/revoke`,
    getUserDefaultProjectRole: ({ companyId, userId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/project-roles/default-role?userId=${userId}`,
    setUserDefaultProjectRole: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/project-roles/default-role`,
    exportToSheetFile: companyId =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/users/export?type=xlsx`
};

export async function getWorkspacesRolesOfSingleUser({ companyId, userId, paramOptions = { expand: true } }) {
    let extraUrl = ``;
    if (!isEmpty(paramOptions)) {
        const expand = paramOptions?.expand;
        if (expand) {
            extraUrl += `&expand=${expand}`;
        }
    }
    const response = await request.get(`${memberApis.getWorkspacesRoles({ companyId, userId })}${extraUrl}`);
    return response.data;
}

export async function getUserDefaultProjectRole({ companyId, userId }) {
    const response = await request.get(memberApis.getUserDefaultProjectRole({ companyId, userId }));
    return response.data;
}

export async function setUserDefaultProjectRole({ companyId, userId, roleId }) {
    const body = { userId, roleId };

    const response = await request.put(memberApis.setUserDefaultProjectRole({ companyId }), body);
    return response.data;
}

export async function getUsersApi(companyId) {
    const response = await request.get(memberApis.members({ companyId }));
    return response.data;
}

export async function updateUserRoleApi({ companyId, userId, roleId, languagePairs, groupIds }) {
    const response = await request.put(memberApis.roles({ companyId, userId }), {
        roleIds: [roleId],
        languagePairs,
        groupIds
    });
    return response.data;
}

export async function updateUserStateApi({ companyId, userEmail, action }) {
    const body = {
        email: userEmail,
        action
    };

    const response = await request.put(memberApis.state({ companyId }), body);
    return response.data;
}

export async function addUsersToGroupApi({ companyId, groupId, userIds }) {
    const response = await request.post(memberApis.groupMembers({ companyId, groupId }), { userIds });
    return response.data;
}

export async function unassignUserApi({ companyId, groupId, userId }) {
    const response = await request.delete(memberApis.groupMember({ companyId, groupId, userId }));
    return response.data;
}

export async function resendInvitationApi({ companyId, email }) {
    const response = await request.put(memberApis.resendInvitation(companyId), { email });
    return response.data;
}

export async function revokeInvitationApi({ companyId, email }) {
    const response = await request.delete(memberApis.revokeInvitation(companyId), { data: { email } });
    return response.data;
}

export async function exportToSheetFileApi(companyId) {
    window.open(`${API_URL}${memberApis.exportToSheetFile(companyId)}`, '_blank');
}
