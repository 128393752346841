import React from 'react';

function CommentNotFoundSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="123" height="111" viewBox="0 0 123 111" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M86.9737 40.1756C106.351 39.8796 122.166 55.1307 122.542 74.5065C122.597 77.3512 122.312 80.1201 121.727 82.7761C120.735 87.2732 120.145 91.8495 120.145 96.4548V105.357C120.145 106.72 119.039 107.826 117.675 107.826H108.774C104.168 107.826 99.592 108.416 95.0949 109.408C92.4392 109.993 89.6703 110.278 86.8255 110.223C67.45 109.848 52.1989 94.0327 52.4945 74.6559C52.7828 55.7426 68.0606 40.4646 86.9737 40.1756Z"
                fill="white"
            />
            <path
                d="M87.4256 40.3939C106.803 40.0979 122.618 55.349 122.993 74.7247C123.049 77.5695 122.764 80.3384 122.178 82.9944C121.186 87.4915 120.597 92.0678 120.597 96.6731V105.575C120.597 106.939 119.491 108.044 118.127 108.044H109.226C104.62 108.044 100.044 108.634 95.5468 109.626C92.8911 110.212 90.1222 110.496 87.2774 110.441C67.9019 110.066 52.6508 94.2509 52.9464 74.8742C53.2347 55.9608 68.5125 40.6828 87.4256 40.3939Z"
                fill="#5DCDE3"
                fillOpacity="0.5"
            />
            <path
                d="M71.4966 81.6246C73.8006 81.6246 75.6683 79.7569 75.6683 77.4529C75.6683 75.149 73.8006 73.2812 71.4966 73.2812C69.1927 73.2812 67.325 75.149 67.325 77.4529C67.325 79.7569 69.1927 81.6246 71.4966 81.6246Z"
                fill="white"
            />
            <path
                d="M86.6866 81.3736C88.9905 81.3736 90.8582 79.5059 90.8582 77.2019C90.8582 74.898 88.9905 73.0303 86.6866 73.0303C84.3826 73.0303 82.5149 74.898 82.5149 77.2019C82.5149 79.5059 84.3826 81.3736 86.6866 81.3736Z"
                fill="white"
            />
            <path
                d="M101.876 81.3736C104.18 81.3736 106.048 79.5059 106.048 77.2019C106.048 74.898 104.18 73.0303 101.876 73.0303C99.5723 73.0303 97.7046 74.898 97.7046 77.2019C97.7046 79.5059 99.5723 81.3736 101.876 81.3736Z"
                fill="white"
            />
            <path
                d="M43.3396 0.00516244C19.7329 -0.355486 0.465394 18.2243 0.00815797 41.8294C-0.05879 45.2947 0.287557 48.6685 1.00139 51.9039C2.20977 57.3825 2.92817 62.9578 2.92817 68.5684V79.4131C2.92817 81.0746 4.275 82.4216 5.93668 82.4216H16.7814C22.3918 82.4216 27.9671 83.14 33.4459 84.3484C36.6813 85.062 40.0548 85.4086 43.5202 85.3414C67.1248 84.8844 85.7046 65.6175 85.3448 42.0112C84.9933 18.9697 66.3809 0.356898 43.3396 0.00516244Z"
                fill="white"
            />
            <path
                d="M43.3396 0.00516244C19.7329 -0.355486 0.465394 18.2243 0.00815797 41.8294C-0.05879 45.2947 0.287557 48.6685 1.00139 51.9039C2.20977 57.3825 2.92817 62.9578 2.92817 68.5684V79.4131C2.92817 81.0746 4.275 82.4216 5.93668 82.4216H16.7814C22.3918 82.4216 27.9671 83.14 33.4459 84.3484C36.6813 85.062 40.0548 85.4086 43.5202 85.3414C67.1248 84.8844 85.7046 65.6175 85.3448 42.0112C84.9933 18.9697 66.3809 0.356898 43.3396 0.00516244Z"
                fill="#6A7AB9"
                fillOpacity="0.5"
            />
            <path
                d="M25.8526 48.5333C28.1565 48.5333 30.0242 46.6655 30.0242 44.3616C30.0242 42.0577 28.1565 40.1899 25.8526 40.1899C23.5486 40.1899 21.6809 42.0577 21.6809 44.3616C21.6809 46.6655 23.5486 48.5333 25.8526 48.5333Z"
                fill="white"
            />
            <path
                d="M41.0425 48.2823C43.3465 48.2823 45.2142 46.4146 45.2142 44.1106C45.2142 41.8067 43.3465 39.939 41.0425 39.939C38.7386 39.939 36.8708 41.8067 36.8708 44.1106C36.8708 46.4146 38.7386 48.2823 41.0425 48.2823Z"
                fill="white"
            />
            <path
                d="M56.2322 48.2823C58.5361 48.2823 60.4039 46.4146 60.4039 44.1106C60.4039 41.8067 58.5361 39.939 56.2322 39.939C53.9283 39.939 52.0605 41.8067 52.0605 44.1106C52.0605 46.4146 53.9283 48.2823 56.2322 48.2823Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(CommentNotFoundSVG);
