import React from 'react';

function HelpSVG({ color = '#A69FC4', size = 18, ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" {...other}>
            <g fill={color} fillRule="evenodd">
                <path d="M8.545 11.586a.974.974 0 1 0 .002 1.948.974.974 0 0 0-.002-1.948" />
                <path d="M15.47 8.545a6.933 6.933 0 0 1-6.925 6.926A6.933 6.933 0 0 1 1.62 8.545 6.933 6.933 0 0 1 8.545 1.62a6.933 6.933 0 0 1 6.925 6.925M8.545 0C3.834 0 0 3.833 0 8.545s3.834 8.546 8.545 8.546c4.712 0 8.545-3.834 8.545-8.546S13.257 0 8.545 0" />
                <path d="M8.545 4.212a2.78 2.78 0 0 0-2.777 2.777.81.81 0 0 0 1.62 0c0-.639.52-1.158 1.157-1.158s1.157.52 1.157 1.158c0 .637-.52 1.155-1.157 1.155a.81.81 0 0 0-.81.811v1.638a.81.81 0 0 0 1.62 0v-.949a2.777 2.777 0 0 0-.81-5.432" />
            </g>
        </svg>
    );
}

export default React.memo(HelpSVG);
