import request from './request';
import { APP_API_PREFIX_LQA } from '../config';

const VERSION = 'v1';

const lqaApis = {
    lqaModels: `${APP_API_PREFIX_LQA}/${VERSION}/lqaModels`,
    setDefaultLqaModel: id => `${APP_API_PREFIX_LQA}/${VERSION}/lqaModels/${id}/default`,
    lqaModelsDefault: `${APP_API_PREFIX_LQA}/${VERSION}/lqaModels/default`,
    lqaTickets: `${APP_API_PREFIX_LQA}/${VERSION}/tickets`,
    lqaComments: `${APP_API_PREFIX_LQA}/${VERSION}/comments`,
    lqaProjects: `${APP_API_PREFIX_LQA}/${VERSION}/projects`,
    export: `${APP_API_PREFIX_LQA}/${VERSION}/export/tickets`
};

export async function getLQAModels() {
    const response = await request.get(lqaApis.lqaModels);
    return response.data;
}

export async function getLQAModelDefault(projectId) {
    const response = await request.get(lqaApis.lqaModelsDefault, { params: { projectId } });
    return response.data;
}

export async function createLQA(body, currentViewId) {
    const response = await request.post(`${lqaApis.lqaTickets}?currentViewId=${currentViewId}`, body);
    return response.data;
}

export async function getLQATickets(params) {
    const response = await request.get(lqaApis.lqaTickets, { params: { ...params, pageSize: 1000 } });
    return response.data;
}

export async function getLQATicketsResponse(params) {
    const response = await request.get(lqaApis.lqaTickets, {
        params: { ...params },
        paramsSerializer: params => {
            return Object.entries(Object.assign({}, params))
                .filter(e => !!e[1])
                .map(([key, value]) => {
                    if (Array.isArray(value) && value.length > 0) {
                        let str = `${key}=${value[0]}`;
                        for (let i = 1; i < value.length; i++) {
                            str += `&${key}=${value[i]}`;
                        }
                        return str;
                    }
                    return `${key}=${value}`;
                })
                .join('&');
        }
    });
    return response;
}

export async function requestUpdateLQATicket(body, currentViewId) {
    const response = await request.patch(`${lqaApis.lqaTickets}/${body.id}?currentViewId=${currentViewId}`, body);
    return response.data;
}

export async function getLQATicketDetail(id) {
    const response = await request.get(`${lqaApis.lqaTickets}/${id}`);
    return response.data;
}

export async function deleteTicket(id) {
    const response = await request.delete(`${lqaApis.lqaTickets}/${id}`);
    return response.data;
}

export async function commentLQATicket(ticketId, body, currentViewId) {
    const response = await request.post(
        `${lqaApis.lqaComments}?ticketId=${ticketId}&currentViewId=${currentViewId}`,
        body
    );
    return response.data;
}

export async function deleteComment(id) {
    const response = await request.delete(`${lqaApis.lqaComments}/${id}`);
    return response.data;
}

export async function updateComment(id, body, currentViewId) {
    const response = await request.patch(`${lqaApis.lqaComments}/${id}?currentViewId=${currentViewId}`, body);
    return response.data;
}

export async function addProjectToLQAApi({ id, body }) {
    const response = await request.post(`${lqaApis.lqaProjects}?lqaModelId=${id}`, body);
    return response.data;
}

export async function removeProjectToLQAApi({ id, body }) {
    const response = await request.delete(`${lqaApis.lqaProjects}?lqaModelId=${id}`, { data: body });
    return response.data;
}

export async function setDefaultLqaApi({ id }) {
    const response = await request.post(`${lqaApis.setDefaultLqaModel(id)}`);
    return response.data;
}

export async function exportLQAApi(params) {
    const response = await request.get(lqaApis.export, {
        params,
        responseType: 'blob',
        headers: { accept: '', 'Content-Type': '' }
    });
    return response;
}
