import * as types from '../types';
import {
    getViewFilterApi,
    deleteViewFilterApi,
    deleteViewFiltersApi,
    createViewFilterApi,
    updateViewFilterApi
} from 'services/viewFilter';
import { getViewRecordsApiV2 } from 'services/view';
import { enqueueSnackbar } from 'notifier/actions';
import { getConditionsByType } from '../conditions';
import { RECORDS_RENDER } from 'const/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import {
    generateServerFilterValue,
    convertServerFilterValue,
    generateDefaultConditionByColumnType,
    formatQuickFilters
} from 'utils/gridUI/filter';
import { generateTempId, isTempId } from 'utils/uuid';
import * as statusActions from './status';
import uuidv1 from 'uuid/v1';
import { getExactColumns, getRealColumnType } from 'utils/gridUI/column';
import { PATH_TAG_ID } from 'const';
import * as aggregationActions from './aggregation';
import { clearColumnQuickFilterSocket } from './quickFilter';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

export function fetchViewFilters({ dbId, viewId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId: dbIdStore, defaultAccessViewId, metaData } = gridUI;

        const dbIdCombined = dbId || dbIdStore;
        const viewIdCombined = viewId || defaultAccessViewId;

        dispatch(_getViewFilterAction());
        try {
            let viewFilters = await getViewFilterApi({ dbId: dbIdCombined, defaultAccessViewId: viewIdCombined });
            const viewFiltersMapped = viewFilters.map(viewFilter => {
                let convertValues = convertServerFilterValue({
                    ...viewFilter,
                    type: getCorrectColumnType(metaData?.[viewFilter?.columnId])
                });

                return {
                    ...viewFilter,
                    values: convertValues
                };
            });

            dispatch(_getViewFilterActionSuccess({ viewFilters: viewFiltersMapped }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function generateDefaultFilter(gridUI) {
    const { viewColumns, metaData, dependencies } = gridUI;
    const orderedViewColumnIds = getExactColumns(viewColumns.filter(column => column?.id !== PATH_TAG_ID));
    const firstColumnId = orderedViewColumnIds && orderedViewColumnIds[0];
    if (!firstColumnId) return undefined;
    let column = metaData?.[firstColumnId];

    const isChildDependency = dependencies
        ?.filter(dpDc => !isTempId(dpDc?.id))
        ?.find(dpdc => dpdc?.child === firstColumnId);
    let columnType = getCorrectColumnType(column);
    let validOperators = getConditionsByType(columnType);

    const selectedOperator = generateDefaultConditionByColumnType({ column, validOperators, isChildDependency });
    let filter = {
        id: generateTempId(),
        columnId: column.id,
        operator: selectedOperator?.value,
        values: []
    };
    return filter;
}

export function _getViewFilterAction() {
    return {
        type: types.FETCH_VIEW_FILTERS
    };
}

export function _getViewFilterActionFailed({ error }) {
    return {
        type: types.FETCH_VIEW_FILTERS_FAILED,
        payload: {
            error
        }
    };
}

export function _getViewFilterActionSuccess({ viewFilters }) {
    return {
        type: types.FETCH_VIEW_FILTERS_SUCCESS,
        payload: {
            viewFilters
        }
    };
}

export function removeMultipleFilters({ filterIds = [] }) {
    return async function(dispatch, getState) {
        filterIds.forEach(filterId => {
            dispatch(_deleteViewFilterActionSuccess({ filterId }));
        });
    };
}

export async function deleteViewFiltersAfterColumnIsDeleted({
    filterIds = [],
    gridUI,
    dispatch,
    successCallback,
    errorCallback
}) {
    const { dbId, defaultAccessViewId } = gridUI;

    if (filterIds.length === 0) {
        successCallback && successCallback();
        return;
    }

    filterIds.forEach(filterId => {
        dispatch(_deleteViewFilterActionSuccess({ filterId }));
    });

    try {
        await deleteViewFiltersApi({
            dbId,
            defaultAccessViewId,
            body: {
                ids: filterIds
            }
        });
        successCallback && successCallback();
    } catch (error) {
        const { message } = error;
        dispatch(
            enqueueSnackbar({
                message,
                type: 'info'
            })
        );
        errorCallback && errorCallback();
    }
}

export function updateViewFilterValue({ filterId, filter }) {
    return {
        type: types.UPDATE_VIEW_FILTER,
        payload: {
            filterId,
            filter
        }
    };
}

export function updateViewFilterOnly({ filterId, newFilter }) {
    return {
        type: types.UPDATE_VIEW_FILTER_ONLY,
        payload: {
            filterId,
            newFilter
        }
    };
}

export function createViewFilter({ groupWithFilterId, groupId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId } = gridUI;
        const filter = generateDefaultFilter(gridUI);
        if (!filter) {
            dispatch(
                enqueueSnackbar({
                    message: `Please add column to grid before create view filter`,
                    type: 'info'
                })
            );
            return;
        }
        dispatch(_createViewFilterAction({ filter: { ...filter, groupId } }));
        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            const createdFilter = await createViewFilterApi({
                dbId,
                defaultAccessViewId,
                data: {
                    groupWithFilterId: groupWithFilterId || undefined,
                    operator: filter?.operator,
                    columnId: filter?.columnId,
                    values: filter?.values
                }
            });
            dispatch(
                _createViewFilterActionSuccess({
                    oldFilterId: filter?.id,
                    newFilter: createdFilter,
                    sourceId: groupWithFilterId
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_createViewFilterActionFailed({ filterId: filter?.id }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function _createViewFilterAction({ filter }) {
    return {
        type: types.CREATE_VIEW_FILTER,
        payload: {
            filter
        }
    };
}

export function _createViewFilterActionFailed({ filterId }) {
    return {
        type: types.CREATE_VIEW_FILTER_FAILED,
        payload: {
            filterId
        }
    };
}

export function _createViewFilterActionSuccess({ oldFilterId, newFilter, sourceId }) {
    return {
        type: types.CREATE_VIEW_FILTER_SUCCESS,
        payload: {
            oldFilterId,
            newFilter,
            sourceId
        }
    };
}

export function deleteViewFilter({ filterId, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_deleteViewFilterAction({ filterId }));
        try {
            await deleteViewFilterApi({ dbId, defaultAccessViewId, filterId });
            await _fetchRecordsAfterFilter({ gridUI, dispatch });
            dispatch(_deleteViewFilterActionSuccess({ filterId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteViewFilterActionFailed({ filterId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

function _deleteViewFilterAction({ filterId }) {
    return {
        type: types.DELETE_VIEW_FILTER,
        payload: {
            filterId
        }
    };
}

function _deleteViewFilterActionFailed({ filterId }) {
    return {
        type: types.DELETE_VIEW_FILTER_FAILED,
        payload: {
            filterId
        }
    };
}

export function _deleteViewFilterActionSuccess({ filterId }) {
    return {
        type: types.DELETE_VIEW_FILTER_SUCCESS,
        payload: {
            filterId
        }
    };
}

export function updateViewFilter({ filterId, isFetchServer = true, newFilter, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId, metaData } = gridUI;
        let { values, columnId, operator } = newFilter;
        const realColumnType = getRealColumnType(metaData?.[columnId]);
        let serverValue = generateServerFilterValue({ type: realColumnType, values });
        dispatch(statusActions.registerDoingAction({ actionId }));

        // if (realColumnType === columnTypes.DATETIME && serverValue.length) {
        //     if ([OPERATOR.equal, OPERATOR.notEqual].includes(operator)) {
        //         operator = operator === OPERATOR.equal ? OPERATOR.between : OPERATOR.notBetween;
        //         serverValue = generateDateRange(serverValue[0]);
        //     }
        // }

        try {
            await updateViewFilterApi({
                defaultAccessViewId,
                dbId,
                filterId,
                data: {
                    operator: operator,
                    columnId: newFilter.columnId,
                    values: serverValue,
                    subField: newFilter?.subField
                }
            });
            if (isFetchServer) {
                await _fetchRecordsAfterFilter({ gridUI, dispatch });
            }
            dispatch(_updateViewFilterActionSuccess({ filterId, newFilter }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function _updateViewFilterActionSuccess({ filterId, newFilter }) {
    return {
        type: types.UPDATE_VIEW_FILTER_SUCCESS,
        payload: {
            filterId,
            newFilter
        }
    };
}

export async function _fetchRecordsAfterFilter({ gridUI, dispatch }) {
    const { defaultAccessViewId, dbId, quickFilters, quickSorts } = gridUI;

    const isShowAutoQA = getIsShowAutoQA();

    const quickFiltersFormatted = formatQuickFilters(quickFilters);
    try {
        const {
            data,
            totalRecords,
            rows,
            recordMetaData,
            totalRecordsWithoutFilters
        } = await _getViewRecordsAfterFilter({
            defaultAccessViewId,
            dbId,
            filterQuery: quickFiltersFormatted,
            sortQuery: quickSorts,
            isShowAutoQA
        });
        dispatch(
            aggregationActions.reFetchAggregations({
                successCallback: () => {
                    console.log('reFetch agg success');
                },
                errorCallback: () => {
                    console.log('reFetch agg failed');
                }
            })
        );
        dispatch(_updateRecordsAfterFilter({ data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters }));
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

function _updateRecordsAfterFilter({ data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters }) {
    return {
        type: types.UPDATE_RECORDS_AFTER_FILTER,
        payload: {
            data,
            totalRecords,
            rows,
            recordMetaData,
            totalRecordsWithoutFilters
        }
    };
}

async function _getViewRecordsAfterFilter({ defaultAccessViewId, dbId, filterQuery, sortQuery, isShowAutoQA }) {
    try {
        const { recordIds, data, recordMetaData, totalRecords, totalRecordsWithoutFilters } = await getViewRecordsApiV2(
            {
                defaultAccessViewId,
                dbId,
                offset: 0,
                limit: RECORDS_RENDER,
                filterQuery,
                sortQuery,
                isShowAutoQA
            }
        );

        return { data, totalRecords, rows: recordIds, recordMetaData, totalRecordsWithoutFilters };
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function deleteViewFilterSocket({ filterId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const newQuickFilter = { ...gridUI?.quickFilters };
        const columnId = gridUI?.viewFilters?.find(viewFilter => viewFilter?.id === filterId)?.columnId;
        if (newQuickFilter?.[columnId]) delete newQuickFilter?.[columnId];
        gridUI.quickFilters = newQuickFilter;
        dispatch(clearColumnQuickFilterSocket(columnId));
        dispatch(_deleteViewFilterActionSuccess({ filterId }));
        await _fetchRecordsAfterFilter({ gridUI, dispatch });
        dispatch(
            enqueueSnackbar({
                message: "A user has changed your View 's setting",
                type: 'info'
            })
        );
    };
}

export function updateViewFilterSocket({ filterId, newFilter }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const newQuickFilter = { ...gridUI?.quickFilters };
        const columnId = newFilter?.columnId;
        if (newQuickFilter?.[columnId]) delete newQuickFilter?.[columnId];
        gridUI.quickFilters = newQuickFilter;
        dispatch(_updateViewFilterActionSuccess({ filterId, newFilter }));
        dispatch(clearColumnQuickFilterSocket(columnId));
        await _fetchRecordsAfterFilter({ gridUI, dispatch });
        dispatch(
            enqueueSnackbar({
                message: "A user has changed your View 's setting",
                type: 'info'
            })
        );
    };
}

export function deleteViewFiltersAfterColumnIsDeletedSocket({ columnId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewFilters } = gridUI;
        const newViewFilters = viewFilters.filter(viewFilter => viewFilter?.columnId !== columnId);
        if (viewFilters.length === newViewFilters.length) return;
        dispatch(_deleteViewFiltersAfterColumnIsDeletedSocket({ newViewFilters }));
    };
}

function _deleteViewFiltersAfterColumnIsDeletedSocket({ newViewFilters }) {
    return {
        type: types.DELETE_VIEW_FILTERS_AFTER_COLUMN_IS_DELETED_SOCKET,
        payload: {
            newViewFilters
        }
    };
}
