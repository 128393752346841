import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import i18n from 'i18n';
import { useAvailableWorkspaceOptionsInLQA } from 'hooks/workspace';
import { useDispatch } from 'react-redux';
import ButtonBase from 'components/button/Base';
import { enqueueSnackbar } from 'notifier/actions';
import { createTagModelApi } from 'services/tag';
import { fetchTagModels, updateTagModel } from 'gridUI/actions';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
    root: {
        width: 740
    },
    inputText: {
        width: 430
    },
    search: {
        width: 220
    },
    targetList: {
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        height: 150,
        overflowY: 'auto',
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        marginBottom: 27
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    rowItem: {
        minWidth: 140
    },
    virtualizeList: {
        outline: 'none'
    },
    optional: {
        marginLeft: 4,
        color: theme.colors.steel
    },
    addIcon: {
        cursor: 'pointer',
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    projectContainer: {
        marginTop: 21,
        border: `1px solid ${theme.colors.border}`
    },
    projectContainerHeader: {
        padding: '13px 17px',
        background: '#F4F5F7'
    },
    openAssignProject: {
        cursor: 'pointer'
    },
    addProjectText: {
        color: theme.palette.primary.main
    },
    projectContainerItem: {
        padding: '13px 17px',
        borderTop: `1px solid ${theme.colors.border}`
    },
    workspaceIcon: {
        marginRight: 8,
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    zIndex: {
        zIndex: theme.zIndex.modal + 200,
        width: 200
    },
    listItem: {
        maxHeight: 188,
        overflow: 'auto'
    },
    btnContainer: {
        marginTop: 24
    }
}));

const Schema = object({
    name: string().required(i18n.t(`global_this_field_is_required`))
});

const TBAdd = ({ open, onClose, initData, setIsFetching, langOptions }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(open);
    const [submitting, setSubmitting] = useState(false);
    const initRef = useRef(false);
    const options = useAvailableWorkspaceOptionsInLQA();
    const dispatch = useDispatch();

    const isEdit = useMemo(() => {
        return !isEmpty(initData);
    }, [initData]);

    const optionsObj = useMemo(() => {
        return (options || []).reduce((acc, cur) => {
            acc[cur.value] = cur;
            return acc;
        }, {});
    }, [options]);

    const _handleClose = useCallback(() => {
        setDialogOpen(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }, [onClose]);

    const _fetchListTags = useCallback(() => {
        setIsFetching(true);
        dispatch(
            fetchTagModels({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch, setIsFetching]);

    const formik = useFormik({
        initialValues: { ...(initData ? { id: initData.id, name: initData.name } : {}) },

        validationSchema: Schema,
        onSubmit: async values => {
            setSubmitting(true);
            const body = {
                ...values
            };
            try {
                if (!isEdit) {
                    await createTagModelApi(body);
                    _fetchListTags();
                    _handleClose();
                } else {
                    dispatch(
                        updateTagModel({
                            oldTag: initData,
                            newTag: { ...initData, ...body },
                            successCallback: () => {
                                _fetchListTags();
                                _handleClose();
                            },
                            errorCallback: () => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 800);
                            }
                        })
                    );
                }
            } catch (error) {
                if (!isEdit) {
                    enqueueSnackbar({
                        message: error?.message,
                        type: 'info'
                    });
                }
            } finally {
                if (!isEdit) {
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 800);
                }
            }
        }
    });
    const { errors, values, handleChange, handleSubmit, setValues, isValid } = formik;

    useEffect(() => {
        if (isEdit) {
            if (initRef.current) return;
            initRef.current = true;
            setValues({
                id: initData.id,
                name: initData.name
            });
        }
    }, [initData, setValues, optionsObj, isEdit]);

    return (
        <Dialog maxWidth={false} open={dialogOpen} onClose={_handleClose}>
            <DialogTitle
                title={!isEdit ? t('create_a_custom_tag_list') : t('edit_tag_model')}
                onClose={_handleClose}
                className={classes.title}
            />
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">{t('custom_tag_list_name')}</Typography>
                            </Grid>
                            <Grid item>
                                <InputText
                                    inputClassName={classes.inputText}
                                    placeholder={t('enter_the_custom_tag_list_name')}
                                    name="name"
                                    value={values.name || ''}
                                    error={errors.name}
                                    errorText={errors.name}
                                    disabled={submitting}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className={classes.row} spacing={2}>
                            <Grid
                                className={classes.btnContainer}
                                container
                                alignItems="center"
                                justify="flex-end"
                                spacing={3}
                            >
                                <Grid item>
                                    <ButtonBase width={120} variant="outlined" onClick={_handleClose}>
                                        {t('global_cancel')}
                                    </ButtonBase>
                                </Grid>
                                <Grid item>
                                    <ButtonBase
                                        width={120}
                                        variant="contained"
                                        type="submit"
                                        disabled={!isValid || submitting}
                                    >
                                        {t(!isEdit ? 'global_create' : 'global_save')}{' '}
                                        {submitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default React.memo(TBAdd);
