export const FETCH_PUBLIC_KEY = '[PAYMENT]FETCH_PUBLIC_KEY';

export const SAVE_CARD_INFO = '[PAYMENT]SAVE_CARD_INFO';
export const SAVE_CARD_INFO_SUCCESS = '[PAYMENT]SAVE_CARD_INFO_SUCCESS';
export const SAVE_CARD_INFO_FAILED = '[PAYMENT]SAVE_CARD_INFO_FAILED';

export const FETCH_CARD_INFO = '[PAYMENT]FETCH_CARD_INFO';
export const FETCH_CARD_INFO_SUCCESS = '[PAYMENT]FETCH_CARD_INFO_SUCCESS';
export const FETCH_CARD_INFO_FAILED = '[PAYMENT]FETCH_CARD_INFO_FAILED';

/**
 * PLANS
 */

export const FETCH_PLANS = '[PAYMENT]FETCH_PLANS';
export const FETCH_PLANS_SUCCESS = '[PAYMENT]FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAILED = '[PAYMENT]FETCH_PLANS_FAILED';

/**
 * FETCH BILLING HISTORY
 */

export const FETCH_BILL_HISTORY = '[PAYMENT]FETCH_BILL_HISTORY';
export const FETCH_BILL_HISTORY_SUCCESS = '[PAYMENT]FETCH_BILL_HISTORY_SUCCESS';
export const FETCH_BILL_HISTORY_FAILED = '[PAYMENT]FETCH_BILL_HISTORY_FAILED';

export const FETCH_RECEIPT_INFO = '[PAYMENT]FETCH_RECEIPT_INFO';
export const FETCH_RECEIPT_INFO_SUCCESS = '[PAYMENT]FETCH_RECEIPT_INFO_SUCCESS';
export const FETCH_RECEIPT_INFO_FAILED = '[PAYMENT]FETCH_RECEIPT_INFO_FAILED';

export const FETCH_TAX_RATE_SUCCESS = '[PAYMENT]FETCH_TAX_RATE_SUCCESS';

/**
 * FETCH BILLING INFO
 */

export const FETCH_BILL_INFO = '[PAYMENT]FETCH_BILL_INFO';
export const FETCH_BILL_INFO_SUCCESS = '[PAYMENT]FETCH_BILL_INFO_SUCCESS';
export const FETCH_BILL_INFO_FAILED = '[PAYMENT]FETCH_BILL_INFO_FAILED';

export const CREATE_BILL_INFO = '[PAYMENT]CREATE_BILL_INFO';
export const CREATE_BILL_INFO_SUCCESS = '[PAYMENT]CREATE_BILL_INFO_SUCCESS';
export const CREATE_BILL_INFO_FAILED = '[PAYMENT]CREATE_BILL_INFO_FAILED';

export const UPDATE_BILL_INFO = '[PAYMENT]UPDATE_BILL_INFO';
export const UPDATE_BILL_INFO_SUCCESS = '[PAYMENT]UPDATE_BILL_INFO_SUCCESS';
export const UPDATE_BILL_INFO_FAILED = '[PAYMENT]UPDATE_BILL_INFO_FAILED';

export const FETCH_USAGE_COMBINE_BY_WORKSPACE_ID = '[PAYMENT]FETCH_USAGE_COMBINE_BY_WORKSPACE_ID';
export const FETCH_USAGE_COMBINE_BY_WORKSPACE_ID_FAILED = '[PAYMENT]FETCH_USAGE_COMBINE_BY_WORKSPACE_ID_FAILED';
export const FETCH_USAGE_COMBINE_BY_WORKSPACE_ID_SUCCESS = '[PAYMENT]FETCH_USAGE_COMBINE_BY_WORKSPACE_ID_SUCCESS';

export const FETCH_CUSTOMER_AND_SUBSCRIPTIONS_SUCCESS = '[PAYMENT]FETCH_CUSTOMER_AND_SUBSCRIPTIONS_SUCCESS';

export const FETCH_ADD_ON_PLANS = '[PAYMENT]FETCH_ADD_ON_PLANS';
export const FETCH_ADD_ON_PLANS_SUCCESS = '[PAYMENT]FETCH_ADD_ON_PLANS_SUCCESS';
export const FETCH_ADD_ON_PLANS_FAILED = '[PAYMENT]FETCH_ADD_ON_PLANS_FAILED';

export const BUY_ADD_ON = '[PAYMENT]BUY_ADD_ON';

export const FETCH_ADD_ON_USAGE_SUCCESS = '[PAYMENT]FETCH_ADD_ON_USAGE_SUCCESS';
