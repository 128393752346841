import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import OverflowTypography from 'components/typography/OverflowTypography';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { getOperatorName } from 'gridUI/conditions';
import { useAutomationLambdas } from 'hooks/gridUI/automation';
import {
    AMAZON_LANGS,
    AUTOMATION_GOOGLE_OCR_ACTIONS,
    AUTOMATION_LANG_SENTIMENT_OPTIONS,
    AUTOMATION_VOICE_OPTIONS,
    GOOGLE_LANGS
} from 'const/automation';
import { Grid } from '@material-ui/core';
import ArrowMoveUpSVG from 'assets/images/svg/ArrowMoveUpSVG';

const useStyles = makeStyles(theme => ({
    description: {
        marginTop: 8,
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.midGrey
    },
    arrowRight: {
        transform: `rotate(90deg)`,
        verticalAlign: 'sub'
    }
}));

const displayValue = text => <b>{text}</b>;

const Condition = React.memo(({ filterGroups }) => {
    const classes = useStyles();
    if (filterGroups?.length) {
        const filterLength = filterGroups.reduce((acc, filterGroup) => {
            acc += filterGroup?.filterFields?.length || 0;
            return acc;
        }, 0);
        if (filterLength > 1) {
            return (
                <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
                    {displayValue(filterLength)} conditions
                </OverflowTypography>
            );
        } else if (filterLength === 1) {
            const filterField = filterGroups?.[0]?.filterFields?.[0];
            if (!filterField?.field) return null;
            const prefix =
                filterField.field
                    ?.split('.')
                    .slice(1)
                    .join('.') || '';
            const value = filterField?.values?.[0];
            return (
                <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
                    {displayValue(prefix)} {getOperatorName(filterField?.operator)} {displayValue(value || '')}
                </OverflowTypography>
            );
        }
    }
    return null;
});

const SendWebRequest = React.memo(({ url }) => {
    const classes = useStyles();
    if (url) {
        return (
            <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
                URL: {displayValue(url)}
            </OverflowTypography>
        );
    }
    return null;
});

const Slack = React.memo(({ url }) => {
    const classes = useStyles();
    if (url) {
        return (
            <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
                Webhook URL: {displayValue(url)}
            </OverflowTypography>
        );
    }
    return null;
});

const Lambda = React.memo(({ functionUid }) => {
    const classes = useStyles();
    const lambdas = useAutomationLambdas();
    const lambda = lambdas?.find(lambda => lambda.uid === functionUid);
    if (lambda) {
        return (
            <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
                Lambda package: {displayValue(lambda.title)}
            </OverflowTypography>
        );
    }
    return null;
});

const Translate = React.memo(({ app, sourceLang, targetLang }) => {
    const classes = useStyles();
    if (!sourceLang || !targetLang) return null;
    const langs = app === WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE ? AMAZON_LANGS : GOOGLE_LANGS;
    const sourceLangText =
        sourceLang === 'auto' ? 'Auto Detect Language' : langs.find(lang => lang.value === sourceLang)?.label;
    const targetLangText = langs.find(lang => lang.value === targetLang)?.label;
    return (
        <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
            <Grid container spacing={2}>
                <Grid item>{sourceLangText}</Grid>
                <Grid item>
                    <ArrowMoveUpSVG className={classes.arrowRight} />
                </Grid>
                <Grid item>{targetLangText}</Grid>
            </Grid>
        </OverflowTypography>
    );
});

const TextToSpeech = React.memo(({ engine, voiceId }) => {
    const classes = useStyles();
    const voiceOptions = useMemo(() => {
        return AUTOMATION_VOICE_OPTIONS.filter(el => !!el[engine]);
    }, [engine]);
    const voice = useMemo(() => {
        return voiceOptions.find(el => el.value === voiceId);
    }, [voiceOptions, voiceId]);

    if (!voice) return null;

    return (
        <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
            Voice: {displayValue(`${voice.language} - ${voice.value} (${voice.gender})`)}
        </OverflowTypography>
    );
});

export const AmazonSentiment = React.memo(({ lang }) => {
    const classes = useStyles();

    const selectedLang = useMemo(() => {
        return AUTOMATION_LANG_SENTIMENT_OPTIONS.find(el => el.value === lang);
    }, [lang]);

    if (!lang) return null;

    return (
        <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
            For language: {displayValue(selectedLang.label)}
        </OverflowTypography>
    );
});

const GoogleOCR = React.memo(({ action }) => {
    const classes = useStyles();

    const detectType = useMemo(() => {
        return AUTOMATION_GOOGLE_OCR_ACTIONS.find(el => el.value === action);
    }, [action]);

    return (
        <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
            Detect {displayValue(detectType.label)}
        </OverflowTypography>
    );
});

const Jira = React.memo(({ projectKey }) => {
    const classes = useStyles();

    if (!projectKey?.trim()) return;

    return (
        <OverflowTypography maxLines={1} variant="body2" className={classes.description}>
            Project Key: {displayValue(projectKey)}
        </OverflowTypography>
    );
});

const ActionNodeDescription = ({ node }) => {
    const { app, action, params } = node;
    switch (app) {
        case WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION:
            return <Condition filterGroups={params?.filterGroups || []} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.HTTP:
            return <SendWebRequest url={params?.url || ''} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.SLACK:
            return <Slack url={params?.incomingWebhookUrl || ''} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA:
            return <Lambda functionUid={params?.functionUid} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE:
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE:
            return <Translate app={app} sourceLang={params?.sourceLang} targetLang={params?.targetLang} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH:
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL:
            return <TextToSpeech engine={params?.engine} voiceId={params?.voiceId} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND:
            return <AmazonSentiment lang={params?.lang} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR:
            return <GoogleOCR action={action} />;
        case WEB_HOOK_EXTERNAL_SYSTEMS.JIRA:
            return <Jira projectKey={params?.projectKey} />;
        default:
            return null;
    }
};

export default React.memo(ActionNodeDescription);
