import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
    Column,
    Table as VirtualizedTable,
    defaultTableRowRenderer,
    CellMeasurer,
    CellMeasurerCache
} from 'react-virtualized-dn';
import { array, string, number, func, bool } from 'prop-types';
import hexToRgba from 'hex-to-rgba';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useTheme } from '@material-ui/core';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';

import SortIcon from './SortIcon';

import 'react-virtualized-dn/styles.css';

const DEFAULT_COLUMN_WIDTH = 200;
const DEFAULT_ROW_HEIGHT = 72;
const DEFAULT_SUB_ROW_HEIGHT = 300;

const OVER_SCAN_ROW_COUNT = 20; // Number of rows to render above/below the visible bounds of the list

const useStyles = makeStyles(theme => ({
    root: {
        '& .ReactVirtualized__Grid': {
            outline: 'none'
        },

        '& .ReactVirtualized__Grid.ReactVirtualized__Table__Grid': {
            background: theme.colors.white
        },

        '& .collapsed': {
            '& > .ReactVirtualized__Table__row': {
                background: theme.colors.ghostwhite
            }
        },
        '& .ReactVirtualized__Table__headerRow': {
            color: '#2b2d36',
            backgroundColor: theme.colors.ghostwhite,
            textTransform: 'none',
            borderBottom: `1px solid ${theme.colors.divider}`,
            fontWeight: 500,
            fontSize: 14,
            fontStyle: 'normal'
        },
        '& .ReactVirtualized__Table__row': {
            borderBottom: `1px solid #E9EAEF`,
            outline: 'none',
            background: theme.colors.white
        },
        '& .ReactVirtualized__Table__row:last-child': {
            boxShadow: `none`,
            outline: 'none'
        },
        '& .collapse-icon': {
            display: 'inline-block',
            marginRight: 8,
            minWidth: 10
        },

        '& .selected': {
            '& .ReactVirtualized__Table__row': {
                outline: 'inherit',
                outlineColor: '#4a91e2'
            }
        },

        '& .clickable': {
            cursor: 'pointer'
        }
    },
    collapsedIcon: {
        transform: 'rotate(180deg)'
    }
}));

const Table = ({
    tableKey = 'table-key',
    className,
    data,
    columns,
    rowCount,
    width,
    height,
    rowHeight,
    rowExpandedRender,
    sortKey,
    sortType,
    noRowsRenderer,
    onRowClick,
    onSort,
    onClollapsed,
    headerHeight = 56,
    disableHeader,
    loadingRows = [],
    rowClickable,
    onCollapseByRowIndex,
    ...rest
}) => {
    const classes = useStyles();
    const tableClasses = `Table ${classes.root} ${className}`;
    const _getDatum = index => data[index];
    const _cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: DEFAULT_ROW_HEIGHT
    });
    const tableRef = useRef();
    const [collapsedRows, setCollapsedRows] = useState(new Set());
    const [selectedRow, setSelectedRow] = useState();

    const [currentSortKey, setCurrentSortKey] = useState(sortKey);
    const [currentSortType, setCurrentSortType] = useState(sortType);
    const theme = useTheme();

    useEffect(() => {
        tableRef.current.recomputeRowHeights();
    }, [collapsedRows]);

    const defaultTableWidth = useMemo(() => {
        return columns.reduce((totalWidth, column) => {
            return totalWidth + (column.width || DEFAULT_COLUMN_WIDTH);
        }, 0);
    }, [columns]);

    const defaultTableHeight = useMemo(() => {
        return data.length * DEFAULT_ROW_HEIGHT + 40;
    }, [data]);

    const headerRenderer = (column, props) => {
        const onClick = () => {
            const newSortType =
                currentSortKey === column.dataKey ? (currentSortType === 'asc' ? 'desc' : 'asc') : 'asc';
            setCurrentSortKey(column.dataKey);
            setCurrentSortType(newSortType);

            onSort && onSort(column.dataKey, newSortType);
        };

        return (
            <Grid
                container
                alignItems="center"
                className={`header-${column.dataKey} ${Boolean(column.sort) && 'clickable'}`}
                onClick={column.sort ? onClick : null}
                wrap="nowrap"
            >
                <Grid item>
                    {column.headerRenderer ? column.headerRenderer({ ...column, ...props }) : column.label}
                </Grid>
                <Grid item>
                    {column.sort && <SortIcon type={currentSortKey === column.dataKey ? currentSortType : ''} />}
                </Grid>
            </Grid>
        );
    };

    const cellRenderer = (column, props) => {
        const { rowIndex, columnIndex, rowData, parent, dataKey } = props;
        const isSlected = (rowData.id || rowIndex) === selectedRow;

        const cellClass = `cell cell-${column.dataKey} ${column.className}`;

        switch (column.type) {
            case 'collapse':
                return (
                    <CellMeasurer
                        columnIndex={columnIndex}
                        cache={_cache}
                        rowIndex={rowIndex}
                        key={dataKey}
                        parent={parent}
                    >
                        <div className={cellClass}>
                            <CollapseIcon isCollapsed={collapsedRows.has(rowIndex)} index={rowIndex} />
                            {column.cellRenderer
                                ? column.cellRenderer({ ...column, ...props, isSlected })
                                : rowData[column.dataKey]}
                        </div>
                    </CellMeasurer>
                );
            default:
                return (
                    <CellMeasurer
                        columnIndex={columnIndex}
                        cache={_cache}
                        rowIndex={rowIndex}
                        key={dataKey}
                        parent={parent}
                    >
                        <div className={cellClass}>
                            {column.cellRenderer
                                ? column.cellRenderer({ ...column, ...props, isSlected })
                                : rowData[column.dataKey]}
                        </div>
                    </CellMeasurer>
                );
        }
    };

    const handleCollapseClick = rowIndex => {
        const shallowCopyOfCollapsedRows = new Set([...collapsedRows]);

        if (shallowCopyOfCollapsedRows.has(rowIndex)) {
            shallowCopyOfCollapsedRows.delete(rowIndex);
        } else {
            shallowCopyOfCollapsedRows.add(rowIndex);
        }

        setCollapsedRows(shallowCopyOfCollapsedRows);

        onClollapsed && onClollapsed([...shallowCopyOfCollapsedRows]);

        onCollapseByRowIndex && onCollapseByRowIndex({ rowIndex, collapse: !shallowCopyOfCollapsedRows.has(rowIndex) });
    };

    const CollapseIcon = ({ isCollapsed, index }) => (
        <span
            className="collapse-icon"
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => handleCollapseClick(index)}
        >
            <ArrowDownSVG className={isCollapsed ? classes.collapsedIcon : ''} />
        </span>
    );

    const rowHeightGetter = ({ index }) => {
        const HEIGHT_OF_BORDER = 2;
        const dynamicHeight = _cache.rowHeight({ index });

        const rowData = _getDatum(index);

        return collapsedRows.has(index)
            ? dynamicHeight + (rowData.subRowHeight || DEFAULT_SUB_ROW_HEIGHT) + HEIGHT_OF_BORDER
            : rowData.height || dynamicHeight;
    };

    const rowGetter = props => {
        const { index } = props;

        return _getDatum(index);
    };

    const _noRowsRenderer = () => {
        if (noRowsRenderer) {
            return typeof noRowsRenderer === 'function' ? noRowsRenderer() : noRowsRenderer;
        }
    };

    const rowRenderer = props => {
        const { index, style, className, key, rowData } = props;
        const dynamicHeight = _cache.rowHeight({ index });
        const { validation } = rowData;

        if (collapsedRows.has(index)) {
            const rowClass = `table-row collapsed ${className} ${(rowData.id || index) === selectedRow &&
                'selected'} ${rowData?.isSelected && `active`}`;

            return (
                <div
                    style={{ ...style, display: 'flex', flexDirection: 'column', paddingRight: 0 }}
                    className={rowClass}
                    key={key}
                >
                    {defaultTableRowRenderer({
                        ...props,
                        style: { width: style.width, height: dynamicHeight || DEFAULT_ROW_HEIGHT }
                    })}
                    <div
                        style={{
                            width: '100%',
                            height: rowData.subRowHeight || DEFAULT_SUB_ROW_HEIGHT,
                            display: 'flex',
                            alignItems: 'left',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        {rowExpandedRender ? rowExpandedRender(rowData) : rowData.details}
                    </div>
                </div>
            );
        } else {
            const rowClass = `table-row ${(rowData.id || index) === selectedRow && 'selected'} ${rowData?.isSelected &&
                `active`} ${rowClickable && 'clickable'}`;

            return (
                <div className={rowClass} key={key}>
                    <div
                        style={
                            loadingRows.includes(rowData.id || index)
                                ? {
                                      position: 'absolute',
                                      width: '100%',
                                      height: style.height,
                                      top: 0,
                                      left: 0,
                                      opacity: 0.5,
                                      background: 'white',
                                      zIndex: 1
                                  }
                                : {}
                        }
                        className={loadingRows.includes(rowData.id || index) ? 'loading' : ''}
                    />
                    {defaultTableRowRenderer({
                        ...props,
                        className: `${props.className} table-row-inner`,
                        style: {
                            ...props.style,
                            ...(validation?.invalid && { background: hexToRgba(theme.colors.brightRed, 0.1) })
                        }
                    })}
                </div>
            );
        }
    };

    const handleRowClick = ({ index }) => {
        const rowData = _getDatum(index);

        setSelectedRow(rowData.id || index);

        onRowClick(index);
    };

    const buildColumns = columns => {
        const elements = [];

        columns.forEach(column => {
            if (column.hidden) return;
            const colClass = `cell ${column.className}`;

            elements.push(
                <Column
                    key={column.key || column.dataKey}
                    label={<Typography variant="body2">{column.label}</Typography>}
                    dataKey={column.key || column.dataKey}
                    width={column.width || DEFAULT_COLUMN_WIDTH}
                    flexGrow={column.flexGrow !== undefined ? column.flexGrow : 0}
                    disableSort={!column.sort}
                    headerRenderer={props => headerRenderer(column, props)}
                    cellRenderer={props => cellRenderer(column, props)}
                    className={colClass}
                />
            );
        });

        return elements;
    };

    return (
        <VirtualizedTable
            {...rest}
            key={tableKey}
            gridClassName={'scrollbar-app'}
            className={`${tableClasses}`}
            headerHeight={headerHeight}
            height={height || defaultTableHeight}
            width={width || defaultTableWidth}
            overscanRowCount={OVER_SCAN_ROW_COUNT}
            rowHeight={rowHeight || rowHeightGetter}
            rowGetter={rowGetter}
            rowCount={rowCount || data.length}
            ref={tableRef}
            rowRenderer={rowRenderer}
            noRowsRenderer={_noRowsRenderer}
            headerClassName="header-cell"
            onRowClick={onRowClick ? handleRowClick : null}
            deferredMeasurementCache={_cache}
            estimatedRowSize={DEFAULT_ROW_HEIGHT}
            disableHeader={disableHeader}
        >
            {buildColumns(columns)}
        </VirtualizedTable>
    );
};

Table.propTypes = {
    className: string,
    data: array.isRequired,
    columns: array,
    rowCount: number,
    width: number,
    height: number,
    disableHeader: bool,
    rowHeight: number,
    sortKey: string,
    sortType: string,
    rowExpandedRender: func,
    onRowClick: func,
    onSort: func,
    onClollapsed: func,
    headerHeight: number
};

export default Table;
