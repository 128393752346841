import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Grid, Paper } from '@material-ui/core';
import Tooltip from 'components/tooltip/Base';
import NotificationSVG from 'assets/images/svg/NotificationSVG';
import NotificationMenu from './NotificationMenu';
import { makeStyles } from '@material-ui/styles';
import { useNotificationCenterReadState, useNotificationCenterWithKey } from 'hooks/auth';
import { useDispatch } from 'react-redux';
import {
    fetchNotificationCenterWithKey,
    getNotificationCenterTotal,
    resetNotificationCenterCount,
    setNotificationCenterWithKeyValue
} from 'auth/actions';
import { NOTIFICATION_CENTER_READ_STATE_TYPE, NOTIFICATION_CENTER_TABS } from 'const';
import { useTranslation } from 'react-i18next';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { checkContainId } from 'utils/clickAway';

export let notificationMenuSetOpen = undefined;

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0 !important`,
        position: 'relative'
    },
    icon: {
        cursor: 'pointer',
        display: 'flex'
    },
    paper: {
        position: 'absolute',
        top: 40,
        right: 0,
        left: 'auto',
        zIndex: 999,
        minWidth: 140,
        boxShadow: theme.shadows[1]
    },
    badge: {
        background: theme.colors.froly,
        height: 18,
        width: 18,
        minWidth: 18,
        fontSize: 11,
        color: theme.colors.white,
        fontWeight: 700,
        cursor: 'pointer'
    },
    badgeOversize: {
        background: theme.colors.froly,
        height: 18,
        minWidth: 18,
        fontSize: 11,
        color: theme.colors.white,
        fontWeight: 700,
        cursor: 'pointer'
    }
}));

const NotificationCenter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allowFetch = useNotificationCenterWithKey('allowFetch');
    const notificationCenterReadState = useNotificationCenterReadState();
    const totalUnread = useNotificationCenterWithKey('totalUnread');
    const [open, setOpen] = useState(false);
    const paperRef = useRef();

    const handleClickAway = useCallback(e => {
        if (checkContainId(e, 'header-notification')) return;
        setOpen(false);
    }, []);

    useClickAwaitListener(paperRef, e => {
        handleClickAway(e);
    });

    useEffect(() => {
        dispatch(
            setNotificationCenterWithKeyValue({
                key: 'readState',
                value: notificationCenterReadState || NOTIFICATION_CENTER_READ_STATE_TYPE.ALL
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        notificationMenuSetOpen = setOpen;
        return () => {
            notificationMenuSetOpen = undefined;
        };
    }, [setOpen]);

    useEffect(() => {
        if (!allowFetch) return;
        dispatch(
            getNotificationCenterTotal({
                type: NOTIFICATION_CENTER_TABS.length > 1 ? 'all' : NOTIFICATION_CENTER_TABS[0].key,
                read: NOTIFICATION_CENTER_READ_STATE_TYPE.UNREAD
            })
        );
        dispatch(setNotificationCenterWithKeyValue({ key: 'allowFetch', value: false }));
        NOTIFICATION_CENTER_TABS.map(tab => {
            dispatch(fetchNotificationCenterWithKey({ key: tab.key }));
            return false;
        });
    }, [dispatch, allowFetch]);

    const handleClick = useCallback(() => {
        setOpen(true);
        dispatch(resetNotificationCenterCount());
    }, [dispatch]);

    return (
        <Grid className={classes.root} item id="header-notification" tr-dt="Open Notifications">
            <Tooltip title={t('notifications')}>
                <Grid item style={{ padding: 12, position: 'relative' }} onClick={handleClick}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        badgeContent={totalUnread}
                        classes={{
                            badge: totalUnread > 99 ? classes.badgeOversize : classes.badge
                        }}
                        invisible={open || !totalUnread}
                    >
                        <NotificationSVG className={classes.icon} />
                    </Badge>
                </Grid>
            </Tooltip>
            {open && (
                <Paper ref={paperRef} className={classes.paper}>
                    <NotificationMenu setOpen={setOpen} />
                </Paper>
            )}
        </Grid>
    );
};

export default React.memo(NotificationCenter);
