import React from 'react';

function PlayContainedSVG({ ...other }) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_808_2046)">
                <path
                    d="M14 0C6.27993 0 0 6.28053 0 14C0 21.7195 6.27993 28 14 28C21.7201 28 28 21.7195 28 14C28 6.28053 21.7201 0 14 0ZM19.5656 14.4905L11.399 19.7405C11.3033 19.8026 11.1927 19.8334 11.0834 19.8334C10.9876 19.8334 10.8908 19.8094 10.8042 19.7621C10.6162 19.6596 10.5 19.4636 10.5 19.25V8.75C10.5 8.53639 10.6162 8.34039 10.8042 8.23785C10.9888 8.13646 11.2201 8.1427 11.399 8.25951L19.5656 13.5095C19.732 13.6166 19.8334 13.8018 19.8334 14C19.8334 14.1982 19.732 14.3834 19.5656 14.4905Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_808_2046">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(PlayContainedSVG);
