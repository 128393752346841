import React from 'react';

function SwitchSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8479 1.20382C11.5762 0.932061 11.1355 0.932061 10.8638 1.20382C10.592 1.47557 10.592 1.91618 10.8638 2.18794L12.1501 3.47421H5.17011C4.32934 3.47421 3.52301 3.80821 2.9285 4.40272C2.33399 4.99723 2 5.80356 2 6.64432V7.88144C2 8.26576 2.31155 8.57731 2.69588 8.57731C3.0802 8.57731 3.39175 8.26576 3.39175 7.88144V6.64432C3.39175 6.17267 3.57912 5.72034 3.91262 5.38684C4.24613 5.05333 4.69846 4.86597 5.17011 4.86597H12.1501L10.8638 6.15228C10.592 6.42403 10.592 6.86464 10.8638 7.1364C11.1355 7.40815 11.5762 7.40815 11.8479 7.1364L14.3221 4.66217C14.5939 4.39041 14.5939 3.9498 14.3221 3.67805L11.8479 1.20382ZM5.66217 9.85382C5.93392 10.1256 5.93392 10.5662 5.66217 10.8379L4.36606 12.134H11.3557C11.8273 12.134 12.2797 11.9467 12.6132 11.6132C12.9467 11.2797 13.134 10.8273 13.134 10.3557V9.11857C13.134 8.73425 13.4456 8.4227 13.8299 8.4227C14.2142 8.4227 14.5258 8.73425 14.5258 9.11857V10.3557C14.5258 11.1965 14.1918 12.0028 13.5973 12.5973C13.0028 13.1918 12.1964 13.5258 11.3557 13.5258H4.38568L5.66217 14.8023C5.93392 15.074 5.93392 15.5146 5.66217 15.7864C5.39041 16.0582 4.9498 16.0582 4.67805 15.7864L2.27823 13.3866C2.10928 13.2596 2 13.0575 2 12.8299C2 12.8283 2.00001 12.8266 2.00002 12.825C1.99876 12.6453 2.0667 12.4652 2.20382 12.328L4.67805 9.85382C4.9498 9.58206 5.39041 9.58206 5.66217 9.85382Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SwitchSVG);
