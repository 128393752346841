import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';

const pathTagApis = {
    tags: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/tags`,
    moveTag: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/tags/move`
};

export async function getTagsApi({ dbId, gridId }) {
    const response = await request.get(pathTagApis.tags({ dbId, gridId }));
    return response.data;
}

export async function createTagApi({ dbId, gridId, body }) {
    const response = await request.post(pathTagApis.tags({ dbId, gridId }), body);
    return response.data;
}

export async function updateTagApi({ dbId, gridId, pathTag, body }) {
    const url = pathTagApis.tags({ dbId, gridId });
    const params = objectToQueryParams({ pathTag });
    const response = await request.put(`${url}?${params}`, body);
    return response.data;
}

export async function deleteTagApi({ dbId, gridId, pathTag }) {
    const url = pathTagApis.tags({ dbId, gridId });
    const params = objectToQueryParams({ pathTag });
    const response = await request.delete(`${url}?${params}`);
    return response.data;
}

export async function moveTagApi({ dbId, gridId, body }) {
    const response = await request.post(`${pathTagApis.moveTag({ dbId, gridId })}`, body);
    return response.data;
}
