import produce from 'immer';
import { SYSTEM_COLUMN_IDS } from 'const';
import * as columnTypes from 'const/columnTypes';
import { getCurrentUtc } from 'utils/datetime';
import { isLDEmpty } from 'utils/object';
import { getCellData } from './cell';

export function combinedData({ data, newData, isCareData = false }) {
    return produce(data, draft => {
        const rowIds = Object.keys(newData || {});
        for (const rowId of rowIds) {
            if (!draft?.[rowId]) {
                draft[rowId] = {};
            }
            const newRowData = newData?.[rowId];
            const columnIds = Object.keys(newRowData);
            for (const columnId of columnIds) {
                const cellData = data?.[rowId]?.[columnId];
                const newCellData = newRowData?.[columnId];
                if (isCareData) {
                    draft[rowId][columnId] = {
                        ...cellData,
                        ...newCellData,
                        value: newCellData?.value,
                        _color: newCellData?._color || null
                    };
                } else {
                    draft[rowId][columnId] = {
                        ...cellData,
                        ...newCellData
                    };
                }
            }
        }
        return draft;
    });
}

export function generateSystemData({ viewColumns = [], editedUser, recordIds = [], columnIds = [], isCreate = false }) {
    const systemColumnIdsInView = viewColumns
        .filter(col => col.viewable && SYSTEM_COLUMN_IDS.includes(col?.id))
        ?.map(col => col?.id);

    const publicRecordIdIndex = columnIds?.findIndex(colId => colId === columnTypes.RECORD_ID);
    const systemData = {};

    for (const recordId of recordIds) {
        systemData[recordId] = {};
        for (const systemId of systemColumnIdsInView) {
            if (isCreate) {
                if (systemId === columnTypes.CREATED_BY) {
                    systemData[recordId] = {
                        ...systemData[recordId],
                        [columnTypes.CREATED_BY]: {
                            value: editedUser?.email
                        }
                    };
                }

                if (systemId === columnTypes.CREATED_TIME) {
                    systemData[recordId] = {
                        ...systemData[recordId],
                        [columnTypes.CREATED_TIME]: {
                            value: getCurrentUtc()
                        }
                    };
                }
            }

            if (systemId === columnTypes.ALTERED_BY) {
                systemData[recordId] = {
                    ...systemData[recordId],
                    [columnTypes.ALTERED_BY]: {
                        value: editedUser?.email
                    }
                };
            }

            if (systemId === columnTypes.ALTERED_TIME) {
                systemData[recordId] = {
                    ...systemData[recordId],
                    [columnTypes.ALTERED_TIME]: {
                        value: getCurrentUtc()
                    }
                };
            }

            if (systemId === columnTypes.RECORD_ID && publicRecordIdIndex > 0) {
                systemData[recordId] = {
                    ...systemData[recordId],
                    [columnTypes.RECORD_ID]: {
                        value: recordIds?.[publicRecordIdIndex]
                    }
                };
            }
        }
    }

    return systemData;
}

export function formatReviewData({ data, metaData }) {
    const newData = {};
    const rows = Object.keys(data);

    for (const rowId of rows) {
        if (!newData?.[rowId]) {
            newData[rowId] = {};
        }

        const columnIds = Object.keys(data?.[rowId]);

        for (const columnId of columnIds) {
            const columnType = metaData?.[columnId]?.type;
            const cellValue = data?.[rowId]?.[columnId];

            newData[rowId] = {
                ...newData?.[rowId],
                [columnId]: {
                    value: cellValue
                        ? columnType === columnTypes.REFERENCE
                            ? [{ referencedDataItem: cellValue }]
                            : columnType === columnTypes.MULTIPLE_SELECTIONS
                            ? cellValue.split(',')
                            : cellValue
                        : ``
                }
            };
        }
    }

    return newData;
}

export function isReferenceDataForMulti(array) {
    if (!(array instanceof Array) || isLDEmpty(array)) {
        return false;
    }

    const isHasAtLeaseNotFileData = array?.some(i => {
        const referencedDataItem = i?.referencedDataItem;
        if (!(referencedDataItem instanceof Array) || isLDEmpty(referencedDataItem)) return true;

        return false;
    });

    return !isHasAtLeaseNotFileData;
}

export function markColor({ recordIds, color = '', columnIds, data }) {
    const serverData = [];
    let undoData = {};
    let newData = {};

    for (const recordId of recordIds) {
        let serverRecord = [];

        for (const columnId of columnIds) {
            const cellData = getCellData({ data, rowId: recordId, columnId });
            const oldCellColor = cellData?._color;

            newData = {
                ...newData,
                [recordId]: {
                    ...newData?.[recordId],
                    [columnId]: {
                        ...cellData,
                        _color: color
                    }
                }
            };
            undoData = {
                ...undoData,
                [recordId]: {
                    ...undoData?.[recordId],
                    [columnId]: {
                        ...cellData,
                        _color: oldCellColor ? oldCellColor : ''
                    }
                }
            };

            serverRecord.push({ _color: color });
        }

        serverData.push([recordId, ...serverRecord]);
    }

    return {
        serverData,
        undoData,
        newData
    };
}
