import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { Table, Column, defaultTableRowRenderer } from 'react-virtualized-dn';
import 'react-virtualized-dn/styles.css';
import DraggingSVG from 'assets/images/svg/DraggingSVG';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ColumnTypeDisplay from './ColumnTypeDisplay';
import * as columnActions from './actions/column';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { useViewColumns, useIsUpdatingColumnsPermission, useCurrentGrid } from 'hooks/gridUI';
import { SELECTION_LIST_ITEM_MIN_HEIGHT } from 'const/style';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { useColumnsPermission, useReferenceDisabledColumns } from 'hooks/gridUI/column';
import orderBy from 'lodash/orderBy';
import { SYSTEM_COLUMN_IDS } from 'const';
import * as columnTypes from 'const/columnTypes';
import { MaxNumberInAr } from 'utils/object';
import IOSSwitch from 'components/switches/IOS';
import { enqueueSnackbar } from 'notifier/actions';
import * as gridUIActions from 'gridUI/actions';
import classNames from 'classnames';

const SortableTable = sortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = sortableElement(defaultTableRowRenderer, { withRef: true });

const useStyles = makeStyles(theme => ({
    listItem: {
        minHeight: SELECTION_LIST_ITEM_MIN_HEIGHT,
        maxHeight: SELECTION_LIST_ITEM_MIN_HEIGHT,
        textDecoration: 'none',
        cursor: 'pointer',
        zIndex: 1000,
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    header: {
        fontSize: 14,
        fontWeight: 'bold',
        color: theme.colors.primaryText,
        marginBottom: 10
    },
    center: {
        textAlign: 'center'
    },
    columnCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    name: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& svg': {
            width: 16
        },
        '& p': {
            width: `calc(100% - 16px)`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    draggingIcon: {
        position: 'relative',
        top: 2,
        left: 3
    },
    unchecked: {
        display: 'flex',
        width: 15,
        cursor: 'default'
    },
    whiteText: {
        color: theme.colors.white
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    }
}));

function ColumnsPermissionsTable({ filterValue, t }) {
    const classes = useStyles();
    const theme = useTheme();
    const columnsPermission = useColumnsPermission();
    const viewColumns = useViewColumns();
    const isUpdatingColumnsPermission = useIsUpdatingColumnsPermission();
    const dispatch = useDispatch();
    const referenceDisabledColumns = useReferenceDisabledColumns();
    const currentGrid = useCurrentGrid();

    const onCanEditChange = React.useCallback(
        ({ checked, columnId }) => {
            dispatch(columnActions.changeColumnEditable({ columnId, editable: checked }));
        },
        [dispatch]
    );

    const onCanViewChange = React.useCallback(
        ({ checked, columnId }) => {
            dispatch(gridUIActions.setLqaResetQuery(true));
            dispatch(columnActions.changeColumnViewable({ columnId, viewable: checked }));
        },
        [dispatch]
    );

    const viewColumnOrders = React.useMemo(() => {
        return viewColumns?.map(vCol => vCol?.order);
    }, [viewColumns]);

    const existedViewColumnIds = React.useMemo(() => {
        return viewColumns?.map(viewCol => viewCol?.id);
    }, [viewColumns]);

    const data = React.useMemo(() => {
        let orders = [...viewColumnOrders];
        const columnPermissionMapOrder = columnsPermission?.map(column => {
            const matchViewColumn = viewColumns?.find(viewCol => viewCol?.id === column?.id);

            if (matchViewColumn) {
                return {
                    ...column,
                    order: matchViewColumn?.order,
                    viewable: matchViewColumn?.viewable,
                    editable: matchViewColumn?.editable,
                    isViewColumn: true
                };
            }

            const gridColumnOrder = MaxNumberInAr(orders) + 1;
            orders.push(gridColumnOrder);
            return {
                ...column,
                order: gridColumnOrder,
                viewable: false,
                editable: false
            };
        });
        const newColumnPermissionWithReOrdered = orderBy(columnPermissionMapOrder, ['order'], ['asc']);
        return newColumnPermissionWithReOrdered.map((column = {}) => {
            let { editable, viewable, id, type } = column;
            let columnType = getCorrectColumnType(column);
            let viewChecked = !viewable ? false : true;
            let editChecked = !editable ? false : true;

            const isNotSupportedColumn = [columnTypes.FORMULA].includes(type);
            const isReferenceDisabledColumn = referenceDisabledColumns?.includes(id);

            const notAllowToSeeEditData = SYSTEM_COLUMN_IDS?.filter(id => {
                if (id === columnTypes.PATH_TAG) return false;
                if (id === columnTypes.RECORD_ID) return currentGrid?.globalUniquePublicRecordId;
                return true;
            })?.includes(id);

            return {
                isDisabled: !column?.isViewColumn,
                columnId: id,
                name: (
                    <div className={classes.name}>
                        <ColumnTypeDisplay
                            type={columnType}
                            name={column.name}
                            group={column.group}
                            customProperties={column.customProperties}
                        />
                    </div>
                ),
                canViewData: (
                    <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_COLUMN_PERMISSION}>
                        {({ isReadOnly }) => (
                            <Tooltip
                                title={
                                    isReadOnly ? (
                                        <Grid container className={classes.permissionTooltip}>
                                            {t('toolbar_no_permission')}
                                        </Grid>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                <div>
                                    <IOSSwitch
                                        disabled={isUpdatingColumnsPermission || isReadOnly}
                                        onChange={e => onCanViewChange({ checked: e.target.checked, columnId: id })}
                                        checked={viewChecked}
                                        color={theme.palette.primary.main}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </AccessControl>
                ),
                canEditData: notAllowToSeeEditData ? null : (
                    <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_COLUMN_PERMISSION}>
                        {({ isReadOnly }) => (
                            <Tooltip
                                title={
                                    isReadOnly ? (
                                        <Grid container className={classes.permissionTooltip}>
                                            {t('toolbar_no_permission')}
                                        </Grid>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                <div>
                                    <IOSSwitch
                                        disabled={
                                            isUpdatingColumnsPermission ||
                                            isReadOnly ||
                                            isNotSupportedColumn ||
                                            isReferenceDisabledColumn
                                        }
                                        onChange={e => onCanEditChange({ checked: e.target.checked, columnId: id })}
                                        checked={
                                            isNotSupportedColumn || isReferenceDisabledColumn ? false : editChecked
                                        }
                                        color={theme.palette.primary.main}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </AccessControl>
                ),
                icon: <DraggingSVG className={classes.draggingIcon} />,
                rawName: column.name
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        t,
        theme,
        columnsPermission,
        classes,
        viewColumnOrders,
        onCanViewChange,
        onCanEditChange,
        isUpdatingColumnsPermission,
        viewColumns
    ]);

    const [dataRender, setDataRender] = React.useState(data);

    React.useEffect(() => {
        setDataRender(data.filter(col => col.rawName.includes(filterValue)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue]);

    React.useEffect(() => {
        setDataRender(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const properties = [
        { key: 'name', headerName: t(`global_column`) },
        { key: 'canViewData', headerName: t(`column_permission_view_data`) },
        { key: 'canEditData', headerName: t(`column_permission_edit_data`) },
        { key: 'icon', headerName: '' }
    ];

    const _cellRenderer1 = () => {
        let obj = {};
        properties.forEach(property => {
            obj = {
                ...obj,
                [property.key]: ({ rowData }) => rowData[property.key]
            };
        });
        return obj;
    };

    const _cellRenderer = _cellRenderer1();

    const _rowGetter = ({ index }) => {
        return dataRender[index];
    };

    const _rowRenderer = ({ className, style, ...rest }) => {
        const isDisabled = rest?.rowData?.isDisabled;
        return (
            <SortableTableRowRenderer
                disabled={isDisabled}
                {...rest}
                className={`${className} ${classes.listItem}`}
                style={{ ...style, zIndex: 9999, background: theme.colors.white }}
                key={rest.rowData.columnId}
            />
        );
    };

    const onSortEnd = React.useCallback(
        ({ oldIndex, newIndex, ...rest }) => {
            const isAfter = oldIndex < newIndex;
            const moveColumn = data?.[oldIndex];
            const toColumn = data?.[newIndex];

            const columnId = isAfter ? data?.[newIndex + 1]?.columnId || toColumn?.columnId : toColumn?.columnId;

            if (!existedViewColumnIds?.includes(toColumn?.columnId)) {
                dispatch(
                    enqueueSnackbar({ type: 'info', message: `Sorry, you cannot drag and drop to hidden columns` })
                );
                return;
            }

            if (moveColumn?.columnId === toColumn?.columnId) {
                return;
            }

            dispatch(
                columnActions.reorderColumns({
                    beforeColumn: isAfter ? undefined : toColumn?.columnId,
                    afterColumn: isAfter ? toColumn?.columnId : undefined,
                    columnId,
                    reorderColumns: [moveColumn?.columnId],
                    errorCallback: () => {},
                    successCallback: () => {
                        console.log('reorder done');
                    }
                })
            );
        },
        [data, existedViewColumnIds, dispatch]
    );

    return (
        <SortableTable
            width={350}
            height={250}
            // eslint-disable-next-line react/no-find-dom-node
            getContainer={wrappedInstance => ReactDOM.findDOMNode(wrappedInstance.Grid)}
            rowRenderer={_rowRenderer}
            rowGetter={_rowGetter}
            rowHeight={36}
            rowCount={dataRender.length}
            data={dataRender}
            distance={1}
            onSortEnd={onSortEnd}
            headerRowRenderer={props => {
                const { columns, style } = props;
                return (
                    <div style={{ display: 'flex', width: style.width }}>
                        {columns.map((column, index) => {
                            const columnProps = column.props;
                            const header = properties[index - 1];
                            return (
                                <p
                                    {...columnProps}
                                    className={classNames(`body1`, {
                                        'text-center': index > 1
                                    })}
                                    key={index}
                                >
                                    {index === 0 ? '' : header && header.headerName}
                                </p>
                            );
                        })}
                    </div>
                );
            }}
        >
            <Column
                width={40}
                dataKey="icon"
                flexGrow={1}
                cellRenderer={props => {
                    const isDisabled = props?.rowData?.isDisabled;
                    return isDisabled ? null : _cellRenderer?.icon(props);
                }}
            />
            <Column width={200} dataKey="name" flexGrow={1} cellRenderer={_cellRenderer.name} />
            <Column
                width={75}
                dataKey="canEditData"
                cellRenderer={_cellRenderer.canViewData}
                className={classes.columnCenter}
            />
            <Column
                width={75}
                dataKey="canViewData"
                className={classes.columnCenter}
                cellRenderer={_cellRenderer.canEditData}
            />
        </SortableTable>
    );
}

export default React.memo(ColumnsPermissionsTable);
