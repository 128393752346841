import React from 'react';

function MultiSelectSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Datatype/Multiple-Selection" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M0.282006,11.6659785 L3.831979,11.699044 C4.079003,11.702044 4.200523,12.002044 4.026212,12.177044 L2.267162,13.942044 C2.159587,14.051044 1.982288,14.051044 1.874713,13.942044 L0.082793,12.143044 C-0.094506,11.966044 0.031994,11.663044 0.282006,11.6659785 Z M15.999004,11.955944 L15.999004,13.732944 L6.259487,13.732944 L6.259487,11.955944 L15.999004,11.955944 Z M0.282006,6.33297853 L3.831979,6.367044 C4.079003,6.369044 4.200523,6.669044 4.026212,6.844044 L2.267162,8.609044 C2.159587,8.718044 1.982288,8.718044 1.874713,8.609044 L0.082793,6.810044 C-0.094506,6.633044 0.031994,6.330044 0.282006,6.33297853 Z M15.999801,6.622344 L15.999801,8.401344 L6.259288,8.401344 L6.259288,6.622344 L15.999801,6.622344 Z M0.282006,0.999978531 L3.831979,1.034044 C4.079003,1.036044 4.200523,1.336044 4.026212,1.511044 L2.267162,3.276044 C2.159587,3.385044 1.982288,3.385044 1.874713,3.276044 L0.082793,1.477044 C-0.094506,1.300044 0.031994,0.997044 0.282006,0.999978531 Z M16,1.289944 L16,3.066944 L6.259487,3.066944 L6.259487,1.289944 L16,1.289944 Z"
                    id="Shape"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(MultiSelectSVG);
