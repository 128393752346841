import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { useTranslation } from 'react-i18next';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import AutomationActionSelect from 'gridUI/automations/AutomationActionSelect';
import ActionLambda from './ActionLambda';
import ActionWebRequest from './ActionWebRequest';
import ActionSlack from './ActionSlack';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useDispatch } from 'react-redux';
import { updateActionNode, updateAutomationDetailErrorWithKey } from '../../action';
import { ACTION_STATUS } from '../../const';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import TooltipActionDisable from '../TooltipActionDisable';
import { getHookTypeLabel } from 'const/gridUI';
import { generateAutomationActionNodeDefaultParams } from 'const/automation';
import ActionGoogleTranslate from './ActionGoogleTranslate';
import ActionTextToSpeech from './ActionTextToSpeech';
import ActionAmazonSentiment from './ActionAmazonSentiment';
import ActionJira from './ActionJira';
import ActionGoogleOCR from './ActionGoogleOCR';
import ActionCondition from './ActionCondition';
import { checkContainId } from 'utils/clickAway';
import ActionSet from './ActionSet';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20
    },
    flx: { flex: 1 },
    mb12: {
        marginBottom: 12
    },
    mb24: {
        marginBottom: 24
    },
    actionDetails: {
        color: theme.colors.steel,
        margin: '0 0 10px'
    },
    item: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        minHeight: 60,
        padding: `0 16px`,
        cursor: 'pointer'
    },
    iconWrapper: { marginRight: 14 },
    actionHint: {
        color: theme.colors.steel,
        fontSize: 13
    }
}));

const Action = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const itemRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openChangeAction, setOpenChangeAction] = useState(false);
    const [app, setApp] = useState(selectedNode.app);
    const [isChanging, setIsChanging] = useState(false);
    const [hide, setHide] = useState(false);
    const tempOption = useRef();

    const automationDetail = useGetAutomationDetail();

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        setApp(selectedNode.app);
    }, [selectedNode]);

    const actionUI = useMemo(() => {
        switch (app) {
            case WEB_HOOK_EXTERNAL_SYSTEMS.HTTP:
                return <ActionWebRequest selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA:
                return <ActionLambda selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.SLACK:
                return <ActionSlack selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE:
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE:
                return <ActionGoogleTranslate selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH:
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL:
                return <ActionTextToSpeech selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND:
                return <ActionAmazonSentiment selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.JIRA:
                return <ActionJira selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR:
                return <ActionGoogleOCR selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION:
                return <ActionCondition selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            case WEB_HOOK_EXTERNAL_SYSTEMS.SET:
                return <ActionSet selectedNode={selectedNode} setSelectedNode={setSelectedNode} />;
            default:
                break;
        }
    }, [app, selectedNode, setSelectedNode]);

    const handleOpen = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        e.persist();
        setAnchorEl(anchorEl => (anchorEl ? null : e.target));
    }, []);

    const handleClose = useCallback(e => {
        if (checkContainId(e, 'dialogActionSelectDisable')) return;
        setAnchorEl(null);
    }, []);

    const onChange = useCallback(
        option => {
            handleClose();
            if (option.app === app) return;
            tempOption.current = option;
            setOpenChangeAction(true);
        },
        [app, handleClose]
    );

    const handleCloseChangeAction = useCallback(() => {
        setOpenChangeAction(false);
    }, []);

    const handleChangeAction = useCallback(() => {
        const option = { ...(tempOption.current || {}) };
        tempOption.current = undefined;
        setIsChanging(true);
        const defaultActionParams = generateAutomationActionNodeDefaultParams({ app: option.app });
        dispatch(
            updateActionNode({
                automationId: automationDetail.id,
                nodeId: selectedNode.id,
                body: {
                    ...option,
                    params: defaultActionParams
                },
                successCallback: responseData => {
                    dispatch(
                        updateAutomationDetailErrorWithKey({
                            key: selectedNode.id,
                            isReplaced: true,
                            newValue: {
                                url: undefined
                            }
                        })
                    );
                    setSelectedNode(responseData);
                    setApp(option.app);
                    setIsChanging(false);
                    handleCloseChangeAction();
                    setHide(true);
                    setTimeout(() => {
                        setHide(false);
                    }, 0);
                },
                errorCallback: () => {
                    setIsChanging(false);
                }
            })
        );
    }, [automationDetail, dispatch, handleCloseChangeAction, selectedNode.id, setSelectedNode]);

    if (hide) return null;

    return (
        <Grid className={classes.root} item container direction="column" wrap="nowrap">
            <Grid item className={classes.mb24}>
                <Grid item>
                    <p className="body2 font-bold mb-2.5">{t('automation_configure_action')}</p>
                </Grid>
                <Grid item className={classes.mb12}>
                    <p className="body1">{t('automation_select_an_action')}</p>
                </Grid>
                <TooltipActionDisable isAction disabled={isDisabled}>
                    <Grid
                        ref={itemRef}
                        item
                        container
                        alignItems="center"
                        wrap="nowrap"
                        className={classnames(classes.item, classes.mb12)}
                        onClick={handleOpen}
                    >
                        <Grid item className={classnames(classes.iconWrapper)}>
                            <WebHookLabel type={app} />
                        </Grid>
                        <Grid item className={classnames(classes.flx)}>
                            <p className="body1">{getHookTypeLabel(app)}</p>
                        </Grid>
                        <Grid item className={classes.iconMore}>
                            <IconButton>
                                <ArrowDownSVG color={theme.colors.steel} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </TooltipActionDisable>
                <Grid item>
                    <p className="body2">{t('automation_action_fire_hint')}</p>
                </Grid>
            </Grid>
            {anchorEl && (
                <PopperMenu
                    handleClickAway={handleClose}
                    anchorEl={itemRef.current}
                    container={itemRef.current}
                    paperClassName={classes.paperClassName}
                >
                    <Grid item style={{ width: itemRef?.current?.offsetWidth }}>
                        <AutomationActionSelect layout="mini" onChange={onChange} onClose={handleClose} />
                    </Grid>
                </PopperMenu>
            )}
            <Grid item className={classes.mb24}>
                {actionUI}
            </Grid>
            <Dialog open={openChangeAction} onClose={handleCloseChangeAction}>
                <ConfirmBox
                    title={t('automation_change_action_type_title')}
                    body={<p className="body2">{t('automation_change_action_type_content')}</p>}
                    handleCancel={handleCloseChangeAction}
                    onClose={handleCloseChangeAction}
                    handleAgreed={handleChangeAction}
                    agreeLabel={t('global_change')}
                    isLoading={isChanging}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(Action);
