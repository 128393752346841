import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import theme from 'theme';
import hexToRgba from 'hex-to-rgba';

export function getErrorMessageComponent({ message, code }) {
    const isSingleError = typeof message === 'string';

    return (
        <Grid container direction="column" spacing={1}>
            {isSingleError ? (
                <Grid item>
                    <Typography display="inline" style={{ color: theme.colors.white }} variant="body2">
                        {message}
                    </Typography>
                </Grid>
            ) : (
                <>
                    <Grid item>
                        <Typography display="inline" style={{ color: theme.colors.white }} variant="body2">
                            {code}
                        </Typography>
                    </Grid>
                    <Grid item container direction="column">
                        {message.map(innerMessage => {
                            return (
                                <Grid key={innerMessage} item>
                                    <Typography style={{ color: hexToRgba(theme.colors.white, 0.8) }} variant="body2">
                                        - {innerMessage}
                                    </Typography>
                                </Grid>
                            );
                        })}
                    </Grid>
                </>
            )}
        </Grid>
    );
}
