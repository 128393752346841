import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import isArray from 'lodash/isArray';
import File from 'gridUI/recordHistory/diffCheckComponents/components/File';
import { ROW_HEIGHT } from 'const/gridUI';
import * as gridUIActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative'
    },
    zoomIn: {
        cursor: 'zoom-in'
    },
    zoomOut: {
        cursor: 'zoom-out'
    }
}));

function Files({ value: cellValue = [], dbId, isReadOnly, columnId, rowId, onClose }) {
    const classes = useStyles({ isReadOnly });
    const dispatch = useDispatch();

    if (!isArray(cellValue)) return <div className={classes.root} />;

    const openPreviewHandler = ({ e, fileMeta }) => {
        let body = {
            rowId,
            columnId
        };

        if (fileMeta) {
            body = {
                ...body,
                defaultPreviewFile: fileMeta
            };
        }
        dispatch(gridUIActions.openCellFilePreview(body));
        onClose && onClose();
    };

    return (
        <Grid container className={classes.root} spacing={1} wrap="nowrap">
            {cellValue?.map((fileMeta, index) => {
                return (
                    <Grid
                        item
                        key={index}
                        onClick={e => openPreviewHandler({ e, fileMeta })}
                        className={classes.zoomIn}
                    >
                        <File dbId={dbId} fileMeta={fileMeta} state={'normal'} width={28} height={28} />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(Files);
