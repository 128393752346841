import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';
import isArray from 'lodash/isArray';
import validator from 'validator';
import { isRefImage, getReferenceDataItem } from 'utils/gridUI/reference';
import File from '../components/File';

function NormalReference({ state, data, options = [], isHideMarkIcon = false, customProperties = {}, dbId }) {
    if (!data || data?.length === 0 || !isArray(data)) return null;

    const refOptions = getReferenceDataItem(data);

    return (
        <div className="inline-flex gap-1 flex-wrap">
            {refOptions?.map((values, index) => {
                if (values instanceof Array) {
                    const isRefFile = isRefImage(values);

                    return values?.map((option, index) => {
                        return isRefFile ? (
                            <File
                                key={index}
                                fileMeta={option}
                                isHideMarkIcon={isHideMarkIcon}
                                state={state}
                                width={28}
                                height={28}
                                dbId={dbId}
                            />
                        ) : (
                            <Option
                                key={index}
                                data={option}
                                state={state || 'normal'}
                                options={options}
                                customProperties={customProperties}
                                isHideMarkIcon={isHideMarkIcon}
                            />
                        );
                    });
                }

                if (values && validator.isJSON(values)) {
                    values = JSON.parse(values);
                    if (values instanceof Array) {
                        const isRefFile = isRefImage(values);
                        return (
                            <Grid item container>
                                {values?.map((option, index) => {
                                    return isRefFile ? (
                                        <Grid item key={index}>
                                            <File
                                                fileMeta={option}
                                                isHideMarkIcon={isHideMarkIcon}
                                                state={state}
                                                width={28}
                                                height={28}
                                                dbId={dbId}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item key={index}>
                                            <Option
                                                data={option}
                                                state={state || 'normal'}
                                                options={options}
                                                customProperties={customProperties}
                                                isHideMarkIcon={isHideMarkIcon}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        );
                    }
                }

                const isRefFile = isRefImage(values);

                return isRefFile ? (
                    <File
                        key={index}
                        fileMeta={values}
                        isHideMarkIcon={isHideMarkIcon}
                        state={state}
                        width={28}
                        height={28}
                        dbId={dbId}
                    />
                ) : (
                    <Option
                        key={index}
                        data={values}
                        state={state || 'normal'}
                        options={options}
                        customProperties={customProperties}
                        isHideMarkIcon={isHideMarkIcon}
                    />
                );
            })}
        </div>
    );
}

export default React.memo(NormalReference);
