import projectDetailHandler from './handlers/projects/detail';
import databasesHandler from './handlers/databases';
import databaseDetailHandler from './handlers/databases/detail';
import gridsHandler from './handlers/grids';
import gridDetailHandler from './handlers/grids/detail';
import gridUserHandler from './handlers/grids/user';
import viewHandler from './handlers/views';
import userHandler from './handlers/users';
import branchHandler from './handlers/branch';
import versionHandler from './handlers/version';
import userVersionHandler from './handlers/users/version';
import notificationCenterHandler from './handlers/notificationCenter';
import advancedSearchHandler from './handlers/advancedSearchHandler';
import advancedSearchGridDetailHandler from './handlers/advancedSearchHandler/gridDetail';

import * as types from './types';

const defaultHandlers = [
    {
        handlerType: types.PROJECT_DETAIL_HANDLER,
        handler: projectDetailHandler
    },
    {
        handlerType: types.DATABASES_HANDLER,
        handler: databasesHandler
    },
    {
        handlerType: types.DATABASE_DETAIL_HANDLER,
        handler: databaseDetailHandler
    },
    {
        handlerType: types.GRIDS_HANDLER,
        handler: gridsHandler
    },
    {
        handlerType: types.GRID_DETAIL_HANDLER,
        handler: gridDetailHandler
    },
    {
        handlerType: types.GRID_UI_DETAIL_HANDLER,
        handler: viewHandler
    },
    {
        handlerType: types.USER_HANDLER,
        handler: userHandler
    },
    {
        handlerType: types.GRID_USERS_HANDLER,
        handler: gridUserHandler
    },
    {
        handlerType: types.BRANCH_HANDLER,
        handler: branchHandler
    },
    {
        handlerType: types.VERSION_HANDLER,
        handler: versionHandler
    },
    {
        handlerType: types.USER_VERSION_HANDLER,
        handler: userVersionHandler
    },
    {
        handlerType: types.NOTIFICATION_CENTER_HANDLER,
        handler: notificationCenterHandler
    },
    {
        handlerType: types.ADVANCED_SEARCH_HANDLER,
        handler: advancedSearchHandler
    },
    {
        handlerType: types.ADVANCED_SEARCH_GRIDS_DETAIL_HANDLER,
        handler: advancedSearchGridDetailHandler
    }
];

export const getTopicAndSubscription = topics => {
    return topics.map(topic => {
        let foundedTopic = defaultHandlers.find(defaultHandler => defaultHandler.handlerType === topic.handlerType);
        return {
            topic: topic.name,
            handler: foundedTopic.handler
        };
    });
};
