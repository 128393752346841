import React from 'react';
import Option from '../components/Option';
import isArray from 'lodash/isArray';
import { diffStatusArrayInOrder } from 'utils/object';

function MultipleSelection({
    previousData = [],
    isHideMarkIcon = false,
    data = [],
    options = [],
    customProperties = {}
}) {
    const diffOptions = diffStatusArrayInOrder(previousData, data);

    if (!diffOptions || diffOptions?.length === 0 || !isArray(diffOptions)) return null;

    return (
        <div className="flex gap-1">
            {diffOptions?.map((option, index) => {
                const status = option?.status;
                const value = option?.key;
                return (
                    <Option
                        key={index}
                        data={value}
                        isHideMarkIcon={isHideMarkIcon}
                        state={status}
                        options={options}
                        customProperties={customProperties}
                    />
                );
            })}
        </div>
    );
}

export default React.memo(MultipleSelection);
