import i18n from 'i18n';

export const SINGLE_LINE = 'singleLine';
export const MULTIPLE_LINES = 'multipleLines';
export const BOOLEAN = 'boolean';
export const DATETIME = 'datetime';
export const SINGLE_SELECTION = 'singleSelection';
export const MULTIPLE_SELECTIONS = 'multipleSelections';
export const GROUP_TAGS = 'groupTags';
export const NUMBER = 'number';
export const FILES = 'files';
export const REFERENCE = 'reference';
export const TRANSLATION = 'language';
export const LOCALIZATION = 'localization';
export const FORMULA = 'formula';
export const RICH_TEXT = 'richText';
export const MARKDOWN = 'markdown';
export const PATH_TAG = '_pathTag';
export const ALTERED_BY = '_alteredBy';
export const ALTERED_TIME = '_alteredTime';
export const CREATED_TIME = '_createdTime';
export const CREATED_BY = '_createdBy';
export const RECORD_ID = '_publicRecordId';
export const JSON_LD = 'json';
export const YAML = 'yaml';
export const HTML = 'html';
export const RESOURCE = '_resource';

export const getColumnLabel = type => {
    switch (type) {
        case SINGLE_LINE:
            return i18n.t(`column_single_line`);
        case MULTIPLE_LINES:
            return i18n.t(`column_multi_line`);
        case NUMBER:
            return 'Number';
        case BOOLEAN:
            return i18n.t(`column_boolean`);
        case DATETIME:
            return i18n.t(`column_date_time`);
        case SINGLE_SELECTION:
            return i18n.t(`column_single_selection`);
        case MULTIPLE_SELECTIONS:
            return i18n.t(`column_multiple_selection`);
        case GROUP_TAGS:
            return i18n.t(`column_group_tags`);
        case FILES:
            return i18n.t(`column_files`);
        case REFERENCE:
            return i18n.t(`column_reference`);
        case TRANSLATION:
            return i18n.t(`column_translation`);
        case LOCALIZATION:
            return i18n.t(`column_localization`);
        case FORMULA:
            return i18n.t(`column_formula`);
        case RICH_TEXT:
            return i18n.t(`column_rich_text`);
        case MARKDOWN:
            return i18n.t(`column_markdown`);
        case PATH_TAG:
            return i18n.t(`column_path_tag`);
        case ALTERED_BY:
            return i18n.t(`column_altered_by`);
        case ALTERED_TIME:
            return i18n.t(`column_altered_time`);
        case CREATED_BY:
            return i18n.t(`column_created_by`);
        case CREATED_TIME:
            return i18n.t(`column_created_time`);
        case RECORD_ID:
            return i18n.t(`column_record_id`);
        case JSON_LD:
            return i18n.t(`column_json_ld`);
        case YAML:
            return i18n.t(`column_yaml`);
        case HTML:
            return i18n.t(`column_html`);

        case RESOURCE:
            return i18n.t(`column_resource`);
        default:
            return '';
    }
};

export const AUTOMATION_TEXT_COLUMNS = [
    SINGLE_LINE,
    MULTIPLE_LINES,
    TRANSLATION,
    LOCALIZATION,
    RICH_TEXT,
    MARKDOWN,
    JSON_LD,
    YAML,
    HTML,
    SINGLE_SELECTION,
    MULTIPLE_SELECTIONS
];
