import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';

const viewApis = {
    viewFilters: ({ defaultAccessViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/filters`,
    viewFilter: ({ defaultAccessViewId, dbId, filterId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/filters/${filterId}`
};

export async function getViewFilterApi({ defaultAccessViewId, dbId }) {
    const response = await request.get(viewApis.viewFilters({ defaultAccessViewId, dbId }));
    return response.data;
}

export async function deleteViewFilterApi({ defaultAccessViewId, dbId, filterId }) {
    const response = await request.delete(viewApis.viewFilter({ defaultAccessViewId, dbId, filterId }));
    return response.data;
}

export async function deleteViewFiltersApi({ defaultAccessViewId, dbId, body }) {
    const response = await request.delete(viewApis.viewFilters({ defaultAccessViewId, dbId }), {
        data: body
    });
    return response.data;
}

export async function createViewFilterApi({ defaultAccessViewId, dbId, data }) {
    const response = await request.post(viewApis.viewFilters({ defaultAccessViewId, dbId }), data);
    return response.data;
}

export async function updateViewFilterApi({ defaultAccessViewId, dbId, filterId, data }) {
    const response = await request.put(viewApis.viewFilter({ defaultAccessViewId, dbId, filterId }), data);
    return response.data;
}
