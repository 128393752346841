import * as columnTypes from 'const/columnTypes';
import { getCombinedColumnId } from 'utils/gridUI/column';

export function getResource({ rowId, data }) {
    return data?.[rowId]?.[columnTypes.RESOURCE];
}

export function mapMetaData(columns) {
    const mapCols = mapColumns(columns);

    return mapCols?.reduce(
        (obj, c) => {
            obj[c?.hashColumnId] = c;
            return obj;
        },
        {
            _resource: {
                label: 'Resource',
                type: columnTypes?.RESOURCE,
                name: `Resource`
            }
        }
    );
}

export function mapColumns(columns) {
    return columns?.map(col => {
        let columnDetail = {
            ...col?.details?.[0],
            type: col?.type,
            metadata: col?.details?.reduce((obj, col) => {
                const combinedColumnId = getCombinedColumnId(col);
                obj[combinedColumnId] = col;
                return obj;
            }, {})
        };

        if ([columnTypes.RECORD_ID, columnTypes.PATH_TAG]?.includes(columnDetail?.columnId)) {
            columnDetail = {
                ...columnDetail,
                type: columnDetail?.columnId
            };
        }

        return {
            ...columnDetail,
            label: col?.columnName,
            value: columnDetail?.columnId,
            hashColumnId: col?.hashColumnId,
            name: col?.columnName
        };
    });
}
