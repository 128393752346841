import uuidv1 from 'uuid/v1';

export const TEMP_ID_PREFIX = '_temp_id_';

export function generateTempId() {
    return TEMP_ID_PREFIX + uuidv1();
}

export function isTempId(id = '') {
    return id?.toString()?.includes(TEMP_ID_PREFIX);
}
