import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import { POPUP_PADDING_TOP_BOTTOM, POPUP_RADIUS, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import MenuOptionBase from 'components/menuOption/Base';

const HEADER_HISTORY_HEIGHT = 72;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    header: {
        paddingLeft: 24,
        paddingRight: 24,
        height: HEADER_HISTORY_HEIGHT,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    content: {
        height: `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT}px)`,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    expand: {
        flex: 1
    },
    backButton: {
        display: 'flex',
        cursor: 'pointer',
        marginRight: SPACING_LIST_NAME_WITH_ICON
    },
    iconMore: {
        position: 'relative',
        borderRight: `1px solid ${theme.colors.divider}`
    },
    menuOptions: {
        position: 'absolute',
        zIndex: 10,
        top: 40,
        right: 0,
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    }
}));

function SidebarRightLayout({
    onClose,
    actions,
    onBack,
    children,
    title,
    subTitle,
    showMenuOption,
    menuOptions = [],
    ...rest
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = React.useCallback(
        event => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const mapMenuOptions = React.useMemo(() => {
        return menuOptions.map(option => ({
            ...option,
            onClick: e => {
                option.onClick(e);
                handleClickAway();
            }
        }));
    }, [menuOptions, handleClickAway]);

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root} {...rest}>
            <Grid item>
                <Grid container className={classes.header} alignItems="center">
                    {onBack && (
                        <Grid item className={classes.backButton} onClick={onBack}>
                            <ArrowLeftSVG size="medium" />
                        </Grid>
                    )}
                    <div className="flex flex-col flex-1">
                        {typeof title === 'function' ? (
                            title()
                        ) : typeof title === 'string' ? (
                            <h4 className="prose prose-lg font-medium">{title}</h4>
                        ) : (
                            title
                        )}
                        {subTitle}
                    </div>

                    {showMenuOption && (
                        <Grid item className={classes.iconMore}>
                            <Grid container>
                                <Grid item>
                                    <IconButton onClick={handleClick}>
                                        <IconMoreActionsSVG />
                                    </IconButton>
                                </Grid>
                                {anchorEl && (
                                    <div className={classes.menuOptions}>
                                        <MenuOptionBase options={mapMenuOptions} handleClickAway={handleClickAway} />
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        {!actions && (
                            <IconButton onClick={onClose}>
                                <CloseIconSVG size={'medium'} />
                            </IconButton>
                        )}
                        {actions && typeof actions === 'function' ? actions() : actions}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.content}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(SidebarRightLayout);
