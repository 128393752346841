import React from 'react';

function TransferInternalSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.081 1.78661C12.54 0.0434496 10.2389 0.146608 9.32206 0.271871C8.94311 0.324502 8.58311 0.480292 8.28206 0.722397L6.14522 2.43187C5.75258 2.74555 5.50837 3.21292 5.47469 3.71503C5.44206 4.21608 5.62206 4.71187 5.96943 5.07608L8.68416 7.91082C9.08206 8.32555 9.74522 8.33924 10.1631 7.9424C10.3673 7.74766 10.481 7.48766 10.4852 7.20871C10.4926 6.92977 10.3894 6.66556 10.1968 6.4624L7.70732 3.8624L9.60943 2.34555C10.1926 2.26766 11.64 2.18661 12.5115 3.17292C13.4073 4.18661 13.3368 5.51503 13.2568 6.07608L6.76206 12.4761L1.99364 7.7445C1.7968 7.54871 1.53469 7.44134 1.25153 7.44134C0.971532 7.4424 0.710479 7.55292 0.513637 7.75082C0.316795 7.94977 0.208374 8.21292 0.210479 8.49187C0.210479 8.76977 0.321006 9.03187 0.519953 9.23082L6.6389 15.3034L6.75679 15.4203L14.78 7.51292C15.0621 7.23713 15.2442 6.8845 15.3105 6.49503C15.4494 5.65608 15.6084 3.51608 14.081 1.78661Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(TransferInternalSVG);
