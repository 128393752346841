import * as types from './type';
import { enqueueSnackbar } from 'notifier/actions';
import { getCompanyInfoApi } from 'services/company';
import * as databaseActions from 'databases/actions';
import * as workspaceActions from 'workspaces/actions';
import { getJiraCreateTypesApi, createJiraIssueApi } from 'services/workflow';
import i18n from 'i18n';
import { getItem, setItem } from 'helpers/localstorage';
import store from 'store/configStore';
import { MAINTAIN_TYPES, WEB_COMPONENT_STATUS } from 'const';
import { fetchSelfHostingLicense, getApiStatus } from 'services/app';

const { getState } = store;

export function setAppVersion({ version }) {
    return {
        type: types.SET_APP_VERSION,
        payload: {
            version
        }
    };
}

export function changeCompanyLogo({ logoUrl }) {
    return {
        type: types.CHANGE_COMPANY_LOGO,
        payload: {
            logoUrl
        }
    };
}

export function changeContext({ contextMenuId }) {
    return {
        type: types.CHANGE_CONTEXT_MENU,
        payload: {
            contextMenuId
        }
    };
}

export function saveSocketId({ socketId }) {
    return {
        type: types.SAVE_SOCKET_ID,
        payload: {
            socketId
        }
    };
}

export function setAccessToken({ accessToken }) {
    return {
        type: types.SET_ACCESS_TOKEN,
        payload: {
            accessToken
        }
    };
}

export function fetchCompanyInfo({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { auth } = getState();
            const companyId = auth.companyId || auth.currentUser.companyId;
            const companyInfo = await getCompanyInfoApi({ companyId });
            dispatch(_setCompanyInfo(companyInfo));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _setCompanyInfo(companyInfo) {
    return {
        type: types.SET_COMPANY_INFO,
        payload: {
            companyInfo
        }
    };
}

export function setCurrentPage({ currentPage }) {
    return {
        type: types.SET_CURRENT_PAGE,
        payload: {
            currentPage
        }
    };
}

export function dashboardReconnecting({ history, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { workspace } = getState();
        const { selectedWorkspace } = workspace;

        try {
            if (selectedWorkspace) {
                dispatch(
                    databaseActions.getDatabases({ workspaceId: selectedWorkspace?.id, successCallback, errorCallback })
                );
            } else {
                dispatch(
                    workspaceActions.fetchWorkspaces({
                        successCallback: ({ workspaceIds = [] }) => {
                            if (!workspaceIds.length) {
                                return;
                            }
                            const formatWorkspaceId = workspaceIds && workspaceIds[0];
                            dispatch(workspaceActions.fetchWorkspaceDetail({ workspaceId: formatWorkspaceId }));
                            history.push(`/projects/${formatWorkspaceId}`);
                            successCallback && successCallback();
                        },
                        errorCallback
                    })
                );
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function setRightSidebar({ type, body }) {
    return {
        type: types.SET_RIGHT_SIDEBAR,
        payload: {
            type,
            body
        }
    };
}

export function writeClipboard({ content, raw = [] }) {
    return {
        type: types.WRITE_CLIPBOARD,
        payload: {
            content,
            raw
        }
    };
}

export function fetchJiraCreateTypes({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const createJiraTypes = await getJiraCreateTypesApi();
            dispatch(_fetchJiraCreateTypesSuccess({ createJiraTypes }));
            successCallback && successCallback(createJiraTypes);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function setFeedbackOption(feedbackOption) {
    return {
        type: types.SET_FEEDBACK_OPTION,
        payload: {
            feedbackOption
        }
    };
}

export function setIsOpenFeedback(boolean) {
    return {
        type: types.SET_IS_OPEN_FEEDBACK,
        payload: {
            boolean
        }
    };
}

function _fetchJiraCreateTypesSuccess({ createJiraTypes }) {
    return {
        type: types.GET_JIRA_CREATE_TYPES,
        payload: {
            createJiraTypes
        }
    };
}

export function createJiraIssue({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            await createJiraIssueApi({ body });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`global_feedback_submit_message`),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function openWelcome() {
    const state = getState();
    const email = state.auth.currentUser?.email;
    const identify = getItem(`identify`);
    setItem('identify', {
        ...identify,
        [email]: {
            ...identify?.[email],
            isShowWelcome: true
        }
    });
    return {
        type: types.OPEN_WELCOME
    };
}

export function closeWelcome() {
    const state = getState();
    const email = state.auth.currentUser?.email;
    const identify = getItem(`identify`);
    setItem('identify', {
        ...identify,
        [email]: {
            ...identify?.[email],
            isShowWelcome: false
        }
    });
    return {
        type: types.CLOSE_WELCOME
    };
}

export function openHelpMenuTooltip() {
    return {
        type: types.OPEN_HELP_MENU_TOOTLTIP
    };
}

export function closeHelpMenuTooltip() {
    return {
        type: types.CLOSE_HELP_MENU_TOOTLTIP
    };
}

export function toggleChromeNotify() {
    return {
        type: types.TOGGLE_CHROME_NOTIFY
    };
}

export function setChromeNotify(status) {
    return {
        type: types.SET_STATUS_CHROME_NOTIFY,
        payload: {
            status
        }
    };
}

export function turnOnMaintainPopup({ maintainType = MAINTAIN_TYPES.MAINTAIN }) {
    return {
        type: types.TURN_ON_MAINTAIN_POPUP,
        payload: {
            maintainType
        }
    };
}

export function turnOffMaintainPopup() {
    return {
        type: types.TURN_OFF_MAINTAIN_POPUP
    };
}

export function toggleDisabledSnackbar() {
    return {
        type: types.IS_TOGGLE_DISABLED_SNACKBAR
    };
}

export function saveShareKeyId({ key }) {
    return {
        type: types.SAVE_SHARE_KEY_ID,
        payload: {
            shareViewKey: key
        }
    };
}

export function setCurrentTutorial(tutorialType) {
    return {
        type: types.SET_CURRENT_TUTORIAL,
        payload: {
            tutorialType
        }
    };
}

export function showFreeTrial() {
    const state = getState();
    const email = state.auth.currentUser?.email;
    const identify = getItem(`identify`);
    if (identify?.[email]?.isShowFreeTrialYet) {
        return {
            type: types.HIDE_FREE_TRIAL
        };
    }
    return {
        type: types.SHOW_FREE_TRIAL
    };
}

export function hideFreeTrial() {
    const state = getState();
    const email = state.auth.currentUser?.email;
    const identify = getItem(`identify`);
    setItem('identify', {
        ...identify,
        [email]: {
            ...identify?.[email],
            isShowFreeTrialYet: true
        }
    });
    return {
        type: types.HIDE_FREE_TRIAL
    };
}

export function toggleKeyboardShortCut() {
    return {
        type: types.TOGGLE_KEY_BOARD_SHORT_CUT
    };
}

export function openNotificationInfo({ type, data }) {
    return {
        type: types.OPEN_NOTIFICATION_INFO,
        payload: {
            type,
            data
        }
    };
}

export function closeNotificationInfo() {
    return {
        type: types.CLOSE_NOTIFICATION_INFO
    };
}

export function setShowTemplates(isShow) {
    return {
        type: types.SET_SHOW_TEMPLATES,
        payload: {
            show: isShow
        }
    };
}

export function fetchApiStatus({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const response = await getApiStatus();
            const apiStatus = response?.components?.find(
                component => component?.id === WEB_COMPONENT_STATUS.API_REQUEST
            )?.status;
            dispatch(_setApiStatus(apiStatus));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _setApiStatus(apiStatus) {
    return {
        type: types.SET_API_STATUS,
        payload: {
            apiStatus
        }
    };
}

export function setPromoteFeatures({ promoteFeatures }) {
    return {
        type: types.SET_PROMOTE_FEATURES,
        payload: promoteFeatures
    };
}

export function getSelfHostingLicense({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const selfHostingLicense = await fetchSelfHostingLicense();
            dispatch(_setSelfHostingLicenseStatus(selfHostingLicense));
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
}

function _setSelfHostingLicenseStatus(selfHostingLicense) {
    return {
        type: types.SET_SELF_HOSTING_LICENSE,
        payload: {
            selfHostingLicense
        }
    };
}
