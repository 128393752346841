import React from 'react';
import ColumnPermissionSVG from 'assets/images/svg/ColumnPermissionSVG';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import Tooltip from 'components/tooltip/Base';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import manageColumnGif from 'assets/images/manageColumn.gif';
import { makeStyles, useTheme } from '@material-ui/styles';
import { DISABLED_OPACITY } from 'const/style';
import PopperMenu from 'components/menus/Popper';
import ColumnsPermissionMenu from 'gridUI/ColumnsPermissionMenu';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: theme.colors.tutorialColor,
        padding: theme.spacing(3),
        borderRadius: 4,
        width: 252
    },
    spb2: {
        marginBottom: theme.spacing(1)
    },
    manageColumnGif: {
        borderRadius: 4,
        height: 120
    },
    propertyButton: {
        display: 'flex',
        margin: `0px 4px`,
        '&:hover': {
            background: theme.colors.divider
        }
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    viewIconSpacing: {
        margin: '0px 4px'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

const ManageColumn = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [columnsAnchorEl, setColumnsAnchorEl] = React.useState(null);

    const handleColumnsClick = React.useCallback(
        event => {
            setColumnsAnchorEl(columnsAnchorEl ? null : event.currentTarget);
        },
        [columnsAnchorEl]
    );

    const handleColumnsClickAway = React.useCallback(() => {
        setColumnsAnchorEl(null);
    }, []);

    return (
        <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_COLUMN_PERMISSION}>
            {({ isReadOnly }) => (
                <>
                    <Tooltip
                        title={
                            <Grid
                                container
                                className={classes.tooltip}
                                //style={{ position: 'absolute', top: 0, right: -256 }}
                                direction="column"
                            >
                                <Grid item className={classes.spb2}>
                                    <img
                                        className={classes.manageColumnGif}
                                        src={manageColumnGif}
                                        alt="view manage gif"
                                    />
                                </Grid>
                                <Grid item style={{ marginTop: 8 }}>
                                    <p className="text-light font-medium">{t('manage_column_tutorial_title')}</p>
                                </Grid>
                                <Grid item>
                                    <p className="caption">{t('manage_column_tutorial_text')}</p>
                                </Grid>
                            </Grid>
                        }
                    >
                        <Grid id="g-h-menu-item-columns-access" item>
                            <ButtonBase
                                className={`${classes.propertyButton} ${classes.button} ${classes.viewIconSpacing} ${
                                    isReadOnly ? classes.disabled : ''
                                }`}
                                onClick={handleColumnsClick}
                                tr-dt="Open Column Permissions Setting"
                            >
                                <ColumnPermissionSVG height={18} width={18} color={theme.colors.darkness} />
                            </ButtonBase>
                        </Grid>
                    </Tooltip>
                    {columnsAnchorEl && (
                        <PopperMenu
                            placement={'bottom-start'}
                            anchorEl={columnsAnchorEl}
                            handleClickAway={handleColumnsClickAway}
                            className={`${classes.popperMenu} small`}
                        >
                            <ColumnsPermissionMenu t={t} />
                        </PopperMenu>
                    )}
                </>
            )}
        </AccessControl>
    );
};

export default React.memo(ManageColumn);
