import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getCardApi } from 'services/payment';

export function fetchCardInfo({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchCardInfo());
        try {
            const cardInfo = await getCardApi();
            dispatch(_fetchCardInfoSuccess(cardInfo));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchCardInfoAfterUpdate({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchCardInfo());
        const currentCardInfo = getState()?.payment?.cardInfo;
        try {
            const fetchCardInterval = setInterval(async function() {
                const cardInfo = await getCardApi();
                if ((!currentCardInfo && cardInfo) || currentCardInfo?.attachedTime !== cardInfo?.attachedTime) {
                    clearInterval(fetchCardInterval);
                    dispatch(_fetchCardInfoSuccess(cardInfo));
                    successCallback && successCallback();
                }
            }, 1000);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchCardInfo() {
    return {
        type: types.FETCH_CARD_INFO
    };
}

function _fetchCardInfoSuccess(cardInfo) {
    return {
        type: types.FETCH_CARD_INFO_SUCCESS,
        payload: {
            cardInfo
        }
    };
}
