import React from 'react';

function FreePlanSVG({ ...other }) {
    return (
        <svg {...other} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.4288 6.99969C7.06463 6.99969 7.68618 6.81113 8.21484 6.45787C8.74351 6.10462 9.15554 5.60252 9.39883 5.01508C9.64213 4.42764 9.70576 3.78124 9.58167 3.15764C9.45759 2.53404 9.15136 1.96123 8.70173 1.51167C8.25209 1.06212 7.67923 0.755991 7.05561 0.632015C6.43198 0.508039 5.7856 0.57178 5.1982 0.815179C4.6108 1.05858 4.10878 1.4707 3.75561 1.99943C3.40244 2.52815 3.214 3.14973 3.21411 3.78556C3.21501 4.63782 3.554 5.45492 4.15669 6.0575C4.75939 6.66009 5.57654 6.99894 6.4288 6.99969Z"
                fill="#2D97F2"
            />
            <path
                d="M10.6341 9.65485C9.81946 8.81931 8.77428 8.24555 7.63203 8.00688C6.48977 7.76822 5.3023 7.87546 4.22129 8.31493C3.14029 8.7544 2.21482 9.50613 1.56311 10.4741C0.911396 11.4421 0.563027 12.5824 0.5625 13.7493C0.5625 13.8985 0.621763 14.0415 0.727252 14.147C0.832742 14.2525 0.975816 14.3118 1.125 14.3118H11.7338C11.8829 14.3118 12.026 14.2525 12.1315 14.147C12.237 14.0415 12.2963 13.8985 12.2963 13.7493C12.2984 13.1528 12.2064 12.5598 12.0234 11.992C11.7511 11.1143 11.275 10.3135 10.6341 9.65485Z"
                fill="#2D97F2"
            />
        </svg>
    );
}

export default React.memo(FreePlanSVG);
