import React from 'react';

function AddRecordSVG({ color = 'white', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <circle cx="20" cy="20" r="20" fill="#7869B9" />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 29.999H29.75V10.5653H10V29.999Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5675 12.277H28.1812V14.4882H11.5675V12.277ZM28.9663 16.1982H10.7837C10.3512 16.1982 10 15.8145 10 15.3432V11.4207C10 10.9495 10.3512 10.5657 10.7837 10.5657H28.9663C29.3988 10.5657 29.75 10.9495 29.75 11.4207V15.3432C29.75 15.8145 29.3988 16.1982 28.9663 16.1982Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6375 21.3874H11.5675V19.1761H28.0362V21.3874V23.0986H28.9663C29.3988 23.0986 29.75 22.7149 29.75 22.2436V18.3224C29.75 17.8499 29.3988 17.4661 28.9663 17.4661H10.7837C10.3525 17.4661 10 17.8499 10 18.3224V22.2436C10 22.7149 10.3525 23.0986 10.7837 23.0986H21.6375V21.3874Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6375 28.2888H11.5675V26.0775H17.9387V24.3675H10.7837C10.3525 24.3675 10 24.7512 10 25.2238V29.145C10 29.6162 10.3525 30 10.7837 30H21.6375V28.2888Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.5835 29.7871V26.0884H29.2823V24.3696H25.5835V20.6709H23.8648V24.3696H20.166V26.0884H23.8648V29.7871H25.5835Z"
                    fill="white"
                />
            </g>
        </svg>
    );
}

export default React.memo(AddRecordSVG);
