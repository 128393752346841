import React from 'react';
import { ROW_HEIGHT } from 'const/gridUI';
import * as columnTypes from 'const/columnTypes';
import Ref from './Ref';
import ReferenceTextSelect from './Ref/Text';
import ReferenceFileSelect from './Ref/File';
import ReferenceDateTimeSelect from './Ref/DateTime';
import ReferenceMultipleTextSelect from './Ref/Multiple';
import ReferenceBooleanSelect from './Ref/Boolean';

function ReferenceSelectRender({ value, referencedColumnType, rowHeight = ROW_HEIGHT, rowIndex, ...rest }) {
    switch (referencedColumnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.NUMBER:
        case columnTypes.MARKDOWN:
        case columnTypes.RICH_TEXT:
        case columnTypes.SINGLE_SELECTION:
        case columnTypes.TRANSLATION:
        case columnTypes.PATH_TAG:
        case columnTypes.GROUP_TAGS:
        case columnTypes.LOCALIZATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
            return <ReferenceTextSelect value={value} rowHeight={rowHeight} {...rest} />;
        case columnTypes.DATETIME:
            return <ReferenceDateTimeSelect value={value} rowHeight={rowHeight} {...rest} />;
        case columnTypes.BOOLEAN:
            return <ReferenceBooleanSelect value={value} rowHeight={rowHeight} {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
        case columnTypes.FORMULA:
            return <ReferenceMultipleTextSelect value={value} rowHeight={rowHeight} {...rest} />;
        case columnTypes.REFERENCE:
            return <Ref referencedColumnType={referencedColumnType} value={value} rowHeight={rowHeight} {...rest} />;
        case columnTypes.FILES:
            return <ReferenceFileSelect value={value} rowHeight={rowHeight} {...rest} />;
        default:
            return <ReferenceTextSelect value={value} rowHeight={rowHeight} {...rest} />;
    }
}

export default React.memo(ReferenceSelectRender);
