import React, { useEffect, useMemo, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Search from 'components/search';
import { useSelectedGroupAccess } from 'hooks/permission/group';
import WorkspacesAccessTable from './workspacesAccessTable';
import isEmpty from 'lodash/isEmpty';
import { useSelectedGroupDetail, useIsFetchingGroupWorkspaceAccess } from 'hooks/permission/group';
import AdminAccess from './AdminAccess';
import GroupAccessTableSkeleton from './GroupAccessTableSkeleton';
import { GROUP_ADMIN_TYPES, PLAN_TYPES } from 'const';
import { useTranslation } from 'react-i18next';
import AddAccess from './AddAccess';
import { getProjectsAndDatabasesApi } from 'services/workspaces';
import { useCurrentSubscriptionIs } from 'hooks/payment';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 24
    },
    filters: {
        marginBottom: 24
    },
    warningBox: {
        marginTop: 110
    },
    noAccess: {
        color: theme.colors.lightGreyBlue
    },
    mb60: {
        marginBottom: 60
    }
}));

function GroupAccess() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState('');
    const [projects, setProjects] = useState([]);
    const workspaces = useSelectedGroupAccess();
    const selectedGroupDetail = useSelectedGroupDetail();
    const isFetchingGroupWorkspaceAccess = useIsFetchingGroupWorkspaceAccess();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);

    useEffect(() => {
        const getProjectAndDatabases = async () => {
            try {
                const projects = await getProjectsAndDatabasesApi();
                setProjects(projects);
            } catch (error) {
                console.log('getProjectAndDatabases failed');
            }
        };
        getProjectAndDatabases();
    }, []);

    const isTrueSystemGroup = useMemo(() => {
        return selectedGroupDetail?.systemGroup && selectedGroupDetail?.type === GROUP_ADMIN_TYPES.ADMIN;
    }, [selectedGroupDetail]);

    const isSystemGroup = useMemo(() => {
        return selectedGroupDetail?.systemGroup;
    }, [selectedGroupDetail]);

    const isTranslatorGroup = useMemo(() => {
        return selectedGroupDetail?.systemGroup && selectedGroupDetail?.type === GROUP_ADMIN_TYPES.NORMAL;
    }, [selectedGroupDetail]);

    const accessView = useMemo(() => {
        let listAccessView = [];
        if (!isEmpty(workspaces?.projects)) {
            const accessView = workspaces.projects.map(p => ({
                workspaceId: p.id,
                workspaceName: p.name,
                databaseName: t('all_databases'),
                gridName: t('all_grids'),
                viewName: t('all_views')
            }));
            listAccessView = [...listAccessView, ...accessView];
        }
        if (!isEmpty(workspaces?.databases)) {
            const accessView = workspaces.databases.map(d => ({
                workspaceId: d.project.id,
                workspaceName: d.project.name,
                databaseId: d.id,
                databaseName: d.name,
                gridName: t('all_grids'),
                viewName: t('all_views')
            }));
            listAccessView = [...listAccessView, ...accessView];
        }
        if (!isEmpty(workspaces?.accessViews)) {
            listAccessView = [
                ...listAccessView,
                ...workspaces.accessViews?.map(v => ({
                    ...v,
                    viewName: v.viewName || t('all_views')
                }))
            ];
        }
        return listAccessView;
    }, [workspaces, t]);

    const filterAccessView = useMemo(() => {
        return accessView.filter(workspaceAccess => {
            return (
                workspaceAccess?.workspaceName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
                workspaceAccess?.dbName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
                workspaceAccess?.gridName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
                workspaceAccess?.viewName?.toLowerCase()?.includes(filterValue?.toLowerCase())
            );
        });
    }, [accessView, filterValue]);

    console.log('accessView', accessView);
    console.log('filterAccessView', filterAccessView);

    if (isFetchingGroupWorkspaceAccess && !workspaces?.accessViews) return <GroupAccessTableSkeleton />;

    if (isTrueSystemGroup || (isFreePlan && isTranslatorGroup)) return <AdminAccess className={classes.warningBox} />;

    return (
        <Grid container direction="column">
            <Grid item className={classes.mb60}>
                <Typography variant="body2">{t('grant_group_access')}</Typography>
            </Grid>
            <Grid item>
                <Grid item container direction="column">
                    <Grid item container justify="flex-end" spacing={4} className={classes.filters}>
                        <Grid item>
                            <Search
                                onChange={e => setFilterValue(e.target.value)}
                                background={theme.colors.paleGrey}
                                placeholder={t('find_a_group_access_placeholder')}
                                width={240}
                            />
                        </Grid>
                        {(!isSystemGroup || isTranslatorGroup) && (
                            <Grid item>
                                <AddAccess projects={projects} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <WorkspacesAccessTable
                            rawWorkspaces={workspaces}
                            workspaces={filterAccessView}
                            isFilter={!!filterValue}
                            isFetching={isFetchingGroupWorkspaceAccess}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(GroupAccess);
