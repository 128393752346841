import React from 'react';
import { Grid } from '@material-ui/core';
import { useFixedColumnCount, useViewColumnsWithReOrder, useRowHeight, useCopiedRange } from 'hooks/gridUI';
import ActiveUsersHighlight from './realtime';
import ReorderRecordHighlight from './ReorderRecordHighlight';
import { _getStyleInfo } from './index';
import classNames from 'classnames';

function CellCopiedOverlay({ type }) {
    const fixedColumnCount = useFixedColumnCount() || 0;
    const viewColumns = useViewColumnsWithReOrder();
    const rowHeight = useRowHeight();
    const copiedRange = useCopiedRange();

    const { isShow, left, width, height, isRemoveEdgeBorder, top } = React.useMemo(() => {
        return _getStyleInfo({
            type,
            fixedColumnCount,
            rowHeight,
            ...copiedRange,
            viewColumns
        });
    }, [type, fixedColumnCount, rowHeight, copiedRange, viewColumns]);

    if (!isShow)
        return (
            <>
                <ActiveUsersHighlight
                    rowHeight={rowHeight}
                    viewColumns={viewColumns}
                    type={type}
                    fixedColumnCount={fixedColumnCount}
                />
                <ReorderRecordHighlight />
            </>
        );

    return (
        <Grid
            className={classNames(
                `border-t border-b absolute pointer-events-none bg-transparent z-[1] border-dashed border-highlight`,
                {
                    'border-l': !(isRemoveEdgeBorder && type === 'right'),
                    'border-r': !(isRemoveEdgeBorder && type === 'left')
                }
            )}
            container
            style={{
                top: top,
                left: left,
                width,
                height
            }}
        />
    );
}

export default React.memo(CellCopiedOverlay);
