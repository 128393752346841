import React from 'react';

function AddAnotherMemberSVG({ color = '#7869B9', ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="8.25" stroke={color} strokeWidth="1.5" />
            <path
                d="M12 9.42857H9.42857V12H8.57143V9.42857H6V8.57143H8.57143V6H9.42857V8.57143H12V9.42857Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
        </svg>
    );
}

export default React.memo(AddAnotherMemberSVG);
