import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TemplateSVG from 'assets/images/svg/TemplateSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { enqueueSnackbar } from 'notifier/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Dialog from 'components/dialog/Dialog';
import SaveTemplate from 'grids/SaveTemplate';
import { useGridDetail } from 'hooks/grid';
import { sendManualTrack } from 'tracker';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    }
}));

const ManageTemplate = ({ handleMoreActionsClickAway, isInIconMore }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { dbId, gridId } = useParams();
    const currentGrid = useGridDetail({ dbId, gridId });
    const [isOpenSaveTemplate, setIsOpenSaveTemplate] = React.useState(false);

    const closeSaveTemplate = React.useCallback(() => {
        if (isInIconMore) {
            handleMoreActionsClickAway && handleMoreActionsClickAway();
        } else {
            setIsOpenSaveTemplate(false);
        }
    }, [handleMoreActionsClickAway, isInIconMore]);

    const handleSaveGridAsTemplateClick = React.useCallback(() => {
        sendManualTrack({ type: 'Save Grid as template on toolbar' });
        setIsOpenSaveTemplate(true);
        if (!isInIconMore) {
            handleMoreActionsClickAway && handleMoreActionsClickAway();
        }
    }, [isInIconMore, handleMoreActionsClickAway]);

    const onTemplateCreated = React.useCallback(() => {
        dispatch(
            enqueueSnackbar({
                message: `Your template has been successfully saved`,
                type: 'info'
            })
        );
        if (isInIconMore) {
            handleMoreActionsClickAway && handleMoreActionsClickAway();
        }
    }, [dispatch, isInIconMore, handleMoreActionsClickAway]);

    return (
        <>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID}>
                {isInIconMore ? (
                    <Grid item onClick={handleSaveGridAsTemplateClick}>
                        <ListItem
                            icon={<TemplateSVG width={16} height={16} color={theme.colors.steel} />}
                            name={t('save_grid_as_template')}
                        />
                    </Grid>
                ) : (
                    <Tooltip title={t('save_grid_as_template')}>
                        <Grid item id={'trigger-save-grid-as-template'} className={classes.menuItem}>
                            <ButtonBase
                                onClick={handleSaveGridAsTemplateClick}
                                className={`${classes.addButton} ${classes.button}`}
                            >
                                <TemplateSVG height={18} width={18} color={theme.colors.darkness} />
                            </ButtonBase>
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
            <Dialog id="simple-dialog" open={isOpenSaveTemplate} onClose={closeSaveTemplate} style={{ zIndex: 1301 }}>
                <SaveTemplate
                    dbId={dbId}
                    grid={currentGrid}
                    onClose={closeSaveTemplate}
                    onTemplateCreated={onTemplateCreated}
                />
            </Dialog>
        </>
    );
};

export default React.memo(ManageTemplate);
