import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutoQASVG from 'assets/images/svg/AutoQASVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import LDBasePortal from 'components/selects/LDBasePortal';

const useStyles = makeStyles(theme => ({
    root: {},
    settingItem: {
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        padding: theme.spacing(3, 4),
        marginBottom: theme.spacing(3)
    },
    mb14: {
        marginBottom: 14
    },
    ml14: {
        marginLeft: 14
    },
    mb24: {
        marginBottom: 24
    },
    buttonItem: {
        marginLeft: 'auto',
        marginTop: 60
    }
}));

function AutoQASettingList({ autoQASettings, projects, onClickMore, onChangeProject }) {
    const classes = useStyles();

    const projectOptions = projects.map(p => ({ label: p.name, value: p.id, icon: () => <WorkspaceSVG /> }));

    return (
        <Grid sm={12} md={9} xl={6} container direction="column" className={classes.root}>
            {autoQASettings.map(setting => {
                const selectedProjectOption = projectOptions.find(p => p.value === setting.projectId);

                return (
                    <Grid key={setting.id} item container className={classes.settingItem} alignItems="center">
                        <Grid item>
                            <AutoQASVG />
                        </Grid>
                        <Grid item className={classes.ml14} style={{ maxWidth: 200 }}>
                            <Typography variant="body1" noWrap>
                                {setting.name}
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: 'auto', width: 230 }}>
                            <LDBasePortal
                                modifiers={{ preventOverflow: { escapeWithReference: true } }}
                                fullWidth
                                ddPlaceholder={'Assign this setting to a Project'}
                                menuPlaceholder={'Find a project'}
                                options={projectOptions}
                                onChange={option => onChangeProject(setting.id, option.value)}
                                defaultValue={selectedProjectOption}
                            />
                        </Grid>
                        <Grid item className={classes.ml14}>
                            <IconButton onClick={e => onClickMore(e, setting)} className={classes.more}>
                                <IconMoreActionsSVG />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(AutoQASettingList);
