import React from 'react';
import { DEFAULT_ROLE_OWNER } from 'const';
import SystemRoleFlag from 'permission/roles/SystemRoleFlag';
import { useSelector } from 'react-redux';

export function useRoleList() {
    return useSelector(state => state.permission.roles?.list);
}

export function useIsFetchingRoles() {
    return useSelector(state => state.permission.roles?.isFetchingRoles);
}

export function useSelectedRoleId() {
    return useSelector(state => state.permission.roles?.selectedRoleId);
}

export function useIsCreatingRole() {
    return useSelector(state => state.permission.roles?.isCreatingRole);
}

export function useIsShowLastRolePopupCreate() {
    return useSelector(state => state.permission.roles?.isShowLastPopupCreate);
}

export function useDefaultPrivileges() {
    return useSelector(state => state.permission.roles?.defaultPrivileges);
}

export function useDefaultRole() {
    const roleList = useRoleList();
    return roleList.find(item => {
        return item.isSystemRole === true && item.level === 'COMPANY' && item.name.toLowerCase() === 'user';
    });
}

export function useSelectedRoleDetail() {
    const roleList = useRoleList();
    const selectedRoleId = useSelectedRoleId();
    return roleList?.find(role => role?.id === selectedRoleId);
}

export function useRoleOptions({ isIgnoreOwner = false }) {
    const roleList = useRoleList();
    return roleList
        ?.filter(role => {
            return !isIgnoreOwner
                ? true
                : DEFAULT_ROLE_OWNER?.id !== role?.id && DEFAULT_ROLE_OWNER?.name !== role?.name;
        })
        ?.map(role => {
            const isSystemRole = role?.isSystemRole;

            return {
                ...role,
                value: role?.id,
                icon: isSystemRole ? <SystemRoleFlag role={role} /> : undefined,
                label: role?.name,
                description: `Lorem Ipsum is simply typesetting industry.`
            };
        });
}

export function useMapRoleOptionsById({ isIgnoreOwner = false }) {
    const roleOptions = useRoleOptions({ isIgnoreOwner });
    return roleOptions?.reduce((acc, cur) => {
        if (cur) {
            acc[cur?.id] = cur;
        }
        return acc;
    }, {});
}

export function useMapRoleOptionsByName({ isIgnoreOwner = false }) {
    const roleOptions = useRoleOptions({ isIgnoreOwner });
    return roleOptions?.reduce((acc, cur) => {
        if (cur) {
            acc[cur?.name] = cur;
        }
        return acc;
    }, {});
}
