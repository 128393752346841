import React from 'react';

function PlaySVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.307617 1.55655C0.307617 0.629155 1.31471 0.0522757 2.11468 0.521432L7.98639 3.96499C8.77697 4.42864 8.77697 5.57158 7.98639 6.03523L2.11468 9.47879C1.31471 9.94795 0.307617 9.37107 0.307617 8.44367V1.55655Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(PlaySVG);
