import React from 'react';

function TutorialSVG({ ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.3186 9.5202H15.0701V1.34065C15.0701 0.598504 14.4689 0 13.7315 0H2.27655C1.53106 0 0.92986 0.598504 0.92986 1.34065V9.5202H0.681363C0.304609 9.5202 0 9.86334 0 10.2783C0 10.6933 0.304609 11.0364 0.681363 11.0364H3.93587L3.07816 15.0823C2.98998 15.4893 3.25451 15.8963 3.66333 15.984C3.71944 15.992 3.76754 16 3.82365 16C4.18437 16 4.49699 15.7446 4.56914 15.4015L5.499 11.0364H10.509L11.4389 15.4015C11.511 15.7526 11.8236 16 12.1844 16C12.2405 16 12.2886 15.992 12.3447 15.984C12.7535 15.8963 13.018 15.4973 12.9299 15.0823L12.0641 11.0284H15.3186C15.6954 11.0284 16 10.6853 16 10.2703C16 9.85536 15.6954 9.5202 15.3186 9.5202ZM13.5471 1.51621V9.48828H2.45291V1.51621H13.5471Z"
                fill="#78778B"
            />
            <path
                d="M4.68144 4.38925H7.87182C8.28866 4.38925 8.63335 4.04611 8.63335 3.63115C8.63335 3.21619 8.28866 2.87305 7.87182 2.87305H4.68144C4.26461 2.87305 3.91992 3.21619 3.91992 3.63115C3.91992 4.04611 4.26461 4.38925 4.68144 4.38925Z"
                fill="#78778B"
            />
            <path
                d="M4.68145 7.55918H11.3187C11.7356 7.55918 12.0802 7.21604 12.0802 6.80108C12.0802 6.38611 11.7356 6.04297 11.3187 6.04297H4.68145C4.26461 6.04297 3.91992 6.38611 3.91992 6.80108C3.91992 7.21604 4.26461 7.55918 4.68145 7.55918Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(TutorialSVG);
