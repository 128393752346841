import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import SettingContentLayout from 'permission/common/Layout';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getTBDetailAndTermDetail, updateTermDetail } from 'gridUI/actions';
import * as workspaceActions from 'workspaces/actions';
import LoadingPage from 'auth/LoadingPage';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import { CircularProgress, FormControl, FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import Select from 'components/selects/Select';
import Checkbox from 'components/checkbox/Base';
import Radio from 'components/radio/Base';
import Divider from 'components/divider/Base';
import TextareaAutosize from 'react-autosize-textarea';
import {
    tbTermDomainOptions,
    tbTermGenderOptions,
    tbTermNumberOptions,
    tbTermPartOfSpeechOptions,
    tbTermStatusOptions,
    tbTermTypeOptions
} from 'const/tb';
import { INPUT_PADDING_LEFT } from 'const/style';
import ButtonBase from 'components/button/Base';
import { LOCALIZATION_FONT } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    contentClassName: {
        position: 'relative'
    },
    spinnerWrapperClassName: {
        position: 'absolute'
    },
    container: {
        padding: '24px 24px 0',
        height: '100%',
        overflow: 'auto',
        position: 'relative'
    },
    footer: {
        position: 'sticky',
        display: 'flex',
        width: '100%',
        height: 88,
        bottom: 0,
        left: 0,
        background: theme.colors.white
    },
    footerContainer: {
        borderTop: `1px solid ${theme.colors.divider}`
    },
    wrapper: {
        maxWidth: 441
    },
    fullWidth: {
        width: '100%'
    },
    mb8: {
        marginBottom: 8
    },
    mb24: {
        marginBottom: 24
    },
    mb40: {
        marginBottom: 40
    },
    saveTerm: {
        position: 'relative',
        marginLeft: 16
    },
    labelLeft: {
        width: 100,
        marginRight: 6
    },
    checkbox: {
        padding: 0
    },
    radio: {
        padding: 0,
        marginLeft: 12
    },
    radioLabel: {
        marginLeft: 8
    },
    divider: {
        margin: '32px 0'
    },
    multiText: {
        width: '100%',
        height: '104px !important',
        overflow: 'auto !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        padding: `6px ${INPUT_PADDING_LEFT}px`,
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
        resize: 'none !important',
        border: `1px solid ${theme.colors.border}`,
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const FormTBDetail = ({ glossary, term, onBack }) => {
    const classes = useStyles();
    const { id, tbId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const langOptions = useLanguageOptionsWithUsageValidate();

    const langObject = useMemo(() => {
        return langOptions.reduce((acc, cur) => {
            acc[cur.value] = cur;
            return acc;
        }, {});
    }, [langOptions]);

    const glossaryLangOptions = useMemo(() => {
        return (glossary.langs || []).map(el => langObject[el]);
    }, [glossary.langs, langObject]);

    const formik = useFormik({
        initialValues: {
            ...term
        },
        onSubmit: (values, { setSubmitting }) => {
            dispatch(
                updateTermDetail({
                    tbId,
                    termId: id,
                    body: values,
                    successCallback: () => {
                        setSubmitting(false);
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
        }
    });

    const {
        errors,
        touched,
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty
    } = formik;

    const selectedLanguageOption = useMemo(() => {
        return langObject[values.lang];
    }, [langObject, values.lang]);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[selectedLanguageOption?.value];
    }, [selectedLanguageOption]);

    const matchOptions = useMemo(() => {
        return [
            { value: true, label: 'Fuzzy' },
            { value: false, label: 'Exact' }
        ];
    }, []);

    const firstFields = useMemo(() => {
        return [
            { fieldKey: 'preferred', label: 'Preferred' },
            { fieldKey: 'caseSensitive', label: 'Case sensitive' },
            { fieldKey: 'forbidden', label: 'Forbidden' }
        ];
    }, []);

    const secondFields = useMemo(() => {
        return [
            { fieldKey: 'type', label: 'Type', options: tbTermTypeOptions },
            { fieldKey: 'partOfSpeech', label: 'Part of speech', options: tbTermPartOfSpeechOptions },
            { fieldKey: 'gender', label: 'Gender', options: tbTermGenderOptions },
            { fieldKey: 'number', label: 'Number', options: tbTermNumberOptions }
        ];
    }, []);

    const thirdFields = useMemo(() => {
        return [
            { fieldKey: 'context', label: 'Context', placeholder: 'Provide context for terms' },
            { fieldKey: 'definition', label: 'Definition', placeholder: 'Provide a term definition' },
            { fieldKey: 'note', label: 'Note', placeholder: 'Provide additional information' }
        ];
    }, []);

    const onLanguageSelect = useCallback(
        option => {
            setFieldValue('lang', option.value);
        },
        [setFieldValue]
    );

    const handleSelectChange = useCallback(
        ({ fieldKey, option }) => {
            setFieldValue(fieldKey, option.value);
        },
        [setFieldValue]
    );

    const handleChangeFuzzy = useCallback(
        e => {
            setFieldValue('fuzzyMatch', e.target.value === 'true');
        },
        [setFieldValue]
    );

    return (
        <Grid item className={`${classes.container} ${fontJP}`}>
            <form onSubmit={handleSubmit} className={classes.createCompanyForm}>
                <Grid item className={classes.header}>
                    <Grid item className={classes.wrapper}>
                        <Grid item className={classes.mb24}>
                            <InputText
                                name="text"
                                label="Term"
                                value={values.text || ''}
                                height={40}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.text && touched.text}
                                errorText={errors.text}
                                placeholder={'Provide a text'}
                            />
                        </Grid>
                        <Grid item className={classes.mb24}>
                            <Grid item className={classes.mb8}>
                                <Typography variant="body1">Language</Typography>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    defaultValue={selectedLanguageOption}
                                    options={glossaryLangOptions}
                                    onChange={onLanguageSelect}
                                    ddPlaceholder="Select a language"
                                    menuPlaceholder="Find a language"
                                    maxSize={200}
                                    isUsingContainer={false}
                                    popperId="popper-language"
                                />
                            </Grid>
                        </Grid>
                        <Grid item className={classes.mb24}>
                            <Grid item className={classes.mb8}>
                                <Typography variant="body1">{t('global_status')}</Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    options={tbTermStatusOptions.filter(el => !!el.value)}
                                    value={values.status}
                                    className={classes.selectClassName}
                                    border
                                    onChange={option => handleSelectChange({ fieldKey: 'status', option })}
                                />
                            </Grid>
                        </Grid>
                        {firstFields.map(field => (
                            <Grid
                                key={field.fieldKey}
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                className={classes.mb24}
                            >
                                <Grid item className={classes.labelLeft}>
                                    <Typography variant="body2">{field.label}</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <Checkbox
                                            className={classes.checkbox}
                                            name={field.fieldKey}
                                            checked={values[field.fieldKey]}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item className={classes.labelLeft}>
                                <Typography variant="body2">Match type</Typography>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <RadioGroup
                                        name="fuzzyMatch"
                                        value={values.fuzzyMatch}
                                        onChange={handleChangeFuzzy}
                                    >
                                        <Grid container alignItems="center" direction="row" wrap="nowrap">
                                            {matchOptions.map(option => (
                                                <Grid item key={option.value}>
                                                    <FormControlLabel
                                                        key={option.value}
                                                        value={option.value}
                                                        control={<Radio size="small" className={classes.radio} />}
                                                        label={
                                                            <Typography variant="body2" className={classes.radioLabel}>
                                                                {option.label}
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid item className={classes.wrapper}>
                        {secondFields.map((field, idx) => (
                            <Grid
                                key={field.fieldKey}
                                item
                                className={classnames({
                                    [classes.mb24]: idx < secondFields.length - 1
                                })}
                            >
                                <Grid item className={classes.mb8}>
                                    <Typography variant="body1">{field.label}</Typography>
                                </Grid>
                                <Grid item>
                                    <Select
                                        options={field.options}
                                        value={values[field.fieldKey]}
                                        className={classes.selectClassName}
                                        border
                                        onChange={option => handleSelectChange({ fieldKey: field.fieldKey, option })}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid item className={classes.wrapper}>
                        <Grid item className={classes.mb24}>
                            <Grid item className={classes.mb8}>
                                <Typography variant="body1">Domain</Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    options={tbTermDomainOptions}
                                    value={values.domain}
                                    className={classes.selectClassName}
                                    border
                                    onChange={option => handleSelectChange({ fieldKey: 'domain', option })}
                                />
                            </Grid>
                        </Grid>
                        {thirdFields.map((field, idx) => (
                            <Grid
                                key={field.fieldKey}
                                item
                                className={classnames({
                                    [classes.mb24]: idx < thirdFields.length - 1,
                                    [classes.mb40]: idx === thirdFields.length - 1
                                })}
                            >
                                <Grid item className={classes.mb8}>
                                    <Typography variant="body1">{field.label}</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControl className={classes.fullWidth}>
                                        <TextareaAutosize
                                            className={classes.multiText}
                                            name={field.fieldKey}
                                            placeholder={field.placeholder}
                                            value={values[field.fieldKey]}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item className={classes.footer}>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        wrap="nowrap"
                        className={classes.footerContainer}
                    >
                        <Grid item>
                            <ButtonBase variant="outlined" width={160} onClick={onBack}>
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.saveTerm}>
                            <ButtonBase
                                variant="contained"
                                type="submit"
                                width={160}
                                disabled={isSubmitting || !isValid || !dirty}
                                onClick={handleSubmit}
                            >
                                Save term
                            </ButtonBase>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

const TBTermDetail = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { id, tbId } = useParams();
    const [glossary, setGlossary] = useState(null);
    const [term, setTerm] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        setIsFetching(true);
        dispatch(
            getTBDetailAndTermDetail({
                tbId: tbId,
                termId: id,
                successCallback: responses => {
                    setGlossary(responses[0]);
                    setTerm(responses[1]);
                    dispatch(workspaceActions.fetchWorkspaces({}));
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch, id, tbId]);

    const onBack = useCallback(() => {
        if (location.state?.fromGlossaryDetail) {
            history.goBack();
        } else {
            history.push(`/localization-settings/tb/${tbId}`);
        }
    }, [history, location, tbId]);

    return (
        <SettingContentLayout name={t('term_details')} contentClassName={classes.contentClassName} onBack={onBack}>
            {isFetching && <LoadingPage spinnerWrapperClassName={classes.spinnerWrapperClassName} />}
            {glossary && term && <FormTBDetail glossary={glossary} term={term} onBack={onBack} />}
        </SettingContentLayout>
    );
};

export default React.memo(TBTermDetail);
