import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CommingSoon from './CommingSoon';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    }
}));

function Merge({ handleBack }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} alignItems="center" justify="center">
            <CommingSoon />
        </Grid>
    );
}

export default React.memo(Merge);
