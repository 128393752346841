import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SettingContentLayout from 'permission/common/Layout';
import { Grid, Typography } from '@material-ui/core';
import Search from 'components/search';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import ButtonBase from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useDispatch } from 'react-redux';
import { fetchListTermsBase, importTerms } from 'gridUI/actions';
import { useListTB } from 'hooks/gridUI';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import Spinner from 'components/spinner/Base';
import TBAdd from './TBAdd';
import TableVirtualize from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import ListSelect from 'components/list/Select';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import Tooltip from 'components/tooltip/Base';
import TBItemAction from './TBItemAction';
import * as workspaceActions from 'workspaces/actions';
import { useHistory } from 'react-router';
import ExportTBDialog from './ExportTBDialog';
import { fetchMembers } from 'permission/actions';
import { useCompanyId } from 'hooks/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    containerTop: {
        marginTop: 48,
        padding: '0 32px 30px'
    },
    searchBox: {
        background: theme.colors.paleGrey,
        '& input': {
            '&::placeholder': {
                fontSize: '14px !important',
                color: `${theme.colors.lightGreyBlue} !important`,
                opacity: 1
            }
        }
    },
    content: {
        flex: 1,
        position: 'relative',
        '& .table-row': {
            cursor: 'pointer',
            position: 'relative',
            '& .table-row-inner': {
                '&:hover': {
                    background: 'rgba(0, 0, 0, 0.04)'
                }
            }
        }
    },
    h100: {
        height: '100%'
    },
    relative: {
        position: 'relative'
    },
    empty: {
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    top: {
        padding: '24px 33px 0 16px'
    },
    settingDescription: {
        marginTop: 8,
        '& p': {
            color: theme.colors.steel
        }
    },
    tableClassName: {
        '& .ReactVirtualized__Table__headerRow': {
            background: 'none'
        }
    },
    rowItem: {
        minWidth: 140
    },
    loadingCell: {
        display: 'flex',
        width: 25,
        height: 25,
        boxShadow: '0px 2px 10px rgb(0 0 0 / 15%)',
        borderRadius: '50%',
        position: 'relative'
    },
    rowCellName: {
        marginRight: 10,
        maxWidth: 'calc(100% - 25px - 10px)',
        ...theme.ellipsis(2)
    },
    cellName: {
        overflow: 'unset !important',
        textOverflow: 'unset',
        whiteSpace: 'unset'
    }
}));

const TermBase = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [open, setOpen] = useState(false);
    const [sorting, setSorting] = useState({});
    const [selectedTB, setSelectedTB] = useState(null);
    const [loadingRows, setLoadingRows] = useState([]);
    const [openExport, setOpenExport] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();
    const timerRef = useRef();
    const listTermBases = useListTB();
    const langOptions = useLanguageOptionsWithUsageValidate();
    const history = useHistory();
    const companyId = useCompanyId();

    useEffect(() => {
        dispatch(fetchMembers({ companyId }));
    }, [companyId, dispatch]);

    useEffect(() => {
        setIsFetching(true);
        dispatch(
            fetchListTermsBase({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
        dispatch(workspaceActions.fetchWorkspaces({}));
    }, [dispatch]);

    const langObject = useMemo(() => {
        return langOptions.reduce((acc, cur) => {
            acc[cur.value] = cur;
            return acc;
        }, {});
    }, [langOptions]);

    const langOptionsExport = useMemo(() => {
        return (selectedTB?.langs || []).map(el => langObject[el]);
    }, [selectedTB, langObject]);

    const handleSearchChange = useCallback(e => {
        const value = e?.target?.value;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setSearchValue(value);
        }, DEBOUNCE_TIME_SEARCHING);
    }, []);

    const handleClearSearch = React.useCallback(() => {
        setSearchValue('');
    }, []);

    const renderLoading = useMemo(() => {
        if (isFetching && !listTermBases.length) {
            return (
                <Grid container className={classes.empty} justify="center" alignItems="center" direction="column">
                    <Spinner size={30} thick={4} />
                </Grid>
            );
        }
        return null;
    }, [isFetching, listTermBases.length, classes]);

    const _onOpenDialog = useCallback(() => {
        setOpen(true);
    }, []);

    const _onCloseDialog = useCallback(() => {
        setOpen(false);
        setSelectedTB(null);
    }, []);

    const _openExport = useCallback(() => {
        setOpenExport(true);
    }, []);

    const _closeExport = useCallback(() => {
        setOpenExport(false);
        setSelectedTB(null);
    }, []);

    const data = React.useMemo(() => {
        const cloneData = [...listTermBases].filter(
            tb => !searchValue?.trim() || tb.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, listTermBases, searchValue]);

    const renderEmpty = useMemo(() => {
        if (!isFetching && !data.length) {
            return (
                <Grid
                    container
                    className={classes.empty}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <EmptyBoxSVG width="252" height="196" />
                    </Grid>
                    <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">Add a new Term Bases now to begin</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }, [isFetching, data, classes, t]);

    const handleRowClick = useCallback(
        idx => {
            if (data?.[idx]?.id) {
                history.push(`${window.location.pathname}/${data[idx].id}`, { fromGlossary: true });
            }
        },
        [history, data]
    );

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const renderLangItem = useCallback(
        option => {
            return (
                <Grid container alignItems="center" justify="center">
                    <Grid item className={classes.rowItem}>
                        <ListSelect
                            disabled={option?.isDisabled}
                            disabledTitle={option?.disabledTitle}
                            icon={option?.icon}
                            name={option.value}
                        />
                    </Grid>
                </Grid>
            );
        },
        [classes.rowItem]
    );

    const langsRenderer = useCallback(
        ({ rowData }) => {
            const { langs } = rowData;
            if (langs?.length) {
                return (
                    <Grid container spacing={2}>
                        {langs?.map(lang => {
                            const option = langObject[lang];
                            if (option?.isDisabled) {
                                return (
                                    <Grid key={option?.value} item>
                                        {renderLangItem(option)}
                                    </Grid>
                                );
                            }
                            return (
                                <Tooltip title={option.tooltip} key={option.value}>
                                    <Grid item>{renderLangItem(option)}</Grid>
                                </Tooltip>
                            );
                        })}
                    </Grid>
                );
            }
            return null;
        },
        [langObject, renderLangItem]
    );

    const handleSelectTB = useCallback(
        tb => {
            setSelectedTB(tb);
            _onOpenDialog();
        },
        [_onOpenDialog]
    );

    const handleImportTerms = useCallback(
        (tbId, files) => {
            setLoadingRows(prev => [...prev, tbId]);
            dispatch(
                importTerms({
                    tbId,
                    file: files[0],
                    successCallback: () => {
                        setLoadingRows(prev => prev.filter(el => el !== tbId));
                    },
                    errorCallback: () => {
                        setLoadingRows(prev => prev.filter(el => el !== tbId));
                    }
                })
            );
        },
        [dispatch]
    );

    const handleExportTerms = useCallback(
        tb => {
            setSelectedTB(tb);
            _openExport();
        },
        [_openExport]
    );

    const COLUMNS = useMemo(() => {
        return [
            {
                dataKey: 'id',
                width: 40,
                cellRenderer: () => ''
            },
            {
                label: t('global_name'),
                dataKey: 'name',
                width: 350,
                sort: true,
                className: classes.cellName,
                cellRenderer: ({ rowData }) => (
                    <Grid container alignItems="center">
                        <Grid item className={classes.rowCellName}>
                            <Typography variant="body2">{rowData.name}</Typography>
                        </Grid>
                        {loadingRows.includes(rowData.id) && (
                            <Grid item>
                                <Grid item>
                                    <Spinner className={classes.loadingCell} size={18} thick={3} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )
            },
            {
                label: 'Supported Languages',
                dataKey: 'langs',
                flexGrow: 1,
                cellRenderer: langsRenderer
            },
            {
                dataKey: 'action',
                cellRenderer: ({ rowData }) => (
                    <TBItemAction
                        item={rowData}
                        handleSelectTB={handleSelectTB}
                        handleImportTerms={handleImportTerms}
                        handleExportTerms={handleExportTerms}
                    />
                ),
                width: 40
            }
        ];
    }, [langsRenderer, handleSelectTB, handleImportTerms, classes, loadingRows, handleExportTerms, t]);

    return (
        <SettingContentLayout name={t('global_glossary')}>
            <Grid container direction="column" className={classes.relative}>
                {renderLoading}
                <Grid item>
                    <Grid className={classes.top} container justify="space-between" alignItems="center">
                        <Grid item className={classes.settingDescription}>
                            <Grid item>
                                <Typography variant="body2">
                                    A term base or glossary is a database containing single words or expressions related
                                    to a specific subject.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    Terms in term bases are often bilingual or even multilingual.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase variant="contained" onClick={_onOpenDialog}>
                                <AddIconSVG
                                    color={theme.colors.white}
                                    style={{ marginRight: 8, width: 10, height: 10 }}
                                />
                                Add Glossary
                            </ButtonBase>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end" className={classes.containerTop}>
                        <Grid item>
                            <Search
                                className={classes.searchBox}
                                background={theme.colors.paleGrey}
                                onChange={handleSearchChange}
                                defaultValue={searchValue}
                                placeholder={`${t('global_search')} …`}
                                width={240}
                                onClear={handleClearSearch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.content}>
                    {renderEmpty}
                    <Grid
                        container
                        style={{
                            height: 'calc(80vh - 186px)',
                            width: '100%',
                            paddingLeft: theme.spacing(5),
                            paddingRight: theme.spacing(5)
                        }}
                        direction="row"
                    >
                        {data?.length > 0 && (
                            <AutoSizer>
                                {({ width, height }) => (
                                    <TableVirtualize
                                        className={classes.tableClassName}
                                        data={data}
                                        columns={COLUMNS}
                                        width={width}
                                        height={height}
                                        onRowClick={handleRowClick}
                                        onSort={handleOnSort}
                                        loadingRows={loadingRows}
                                    ></TableVirtualize>
                                )}
                            </AutoSizer>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {open && (
                <TBAdd
                    open={open}
                    onClose={_onCloseDialog}
                    setIsFetching={setIsFetching}
                    langOptions={langOptions}
                    initData={selectedTB}
                />
            )}
            <ExportTBDialog
                open={openExport}
                onClose={_closeExport}
                glossary={selectedTB}
                langOptions={langOptionsExport}
            />
        </SettingContentLayout>
    );
};

export default React.memo(TermBase);
