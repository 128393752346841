import React from 'react';
import { Popper, Paper } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

export let closePopperMenu = null;

function PopperMenu({ children, anchorEl, id, className, handleClickAway, paperClassName, onPaperClick, ...rest }) {
    // const classes = useStyles();
    const open = Boolean(anchorEl);
    const popperId = open ? id || 'simple-popper' : undefined;
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        onClickAway(e);
    });

    const onClickAway = React.useCallback(
        e => {
            handleClickAway && handleClickAway(e);
        },
        [handleClickAway]
    );

    React.useEffect(() => {
        closePopperMenu = onClickAway;
    }, [onClickAway]);

    return (
        <Popper
            ref={rootRef}
            className={`z-[1301] !top-1 ${className}`}
            id={popperId}
            open={open}
            anchorEl={anchorEl}
            transition
            {...rest}
        >
            <Paper
                className={`rounded-md py-2 max-h-[90vh] overflow-y-auto ${paperClassName} popper-content`}
                onClick={onPaperClick}
            >
                {children}
            </Paper>
        </Popper>
    );
}

export default React.memo(PopperMenu);
