import React from 'react';
import Tabs from 'components/tabs/Base';
import { Grid, Typography, IconButton, Dialog } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import GridColumn from './gridColumn';
// import GridRecord from './gridRecord';
// import ViewRecord from './viewRecord';
import StatusCode from './StatusCode';
// import GridSVG from 'assets/images/svg/GridSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import OpenNewPageSVG from 'assets/images/svg/OpenNewPageSVG';
import { HEADER_HEIGHT } from 'const';
import ListRecord from './gridRecord/ListRecord';
import CreateRecord from './gridRecord/CreateRecord';
// import SetRecord from './gridRecord/SetRecord';
import Tooltip from 'components/tooltip/Base';
import InputViewCopyOnly from 'components/inputs/InputViewCopyOnly';
import { useCompositeViewId, useGetApiKey } from 'hooks/gridUI';
import hexToRgba from 'hex-to-rgba';
import RefreshSVG from 'assets/images/svg/RefreshSVG';
import GenerateKeyConfirm from './GenerateKeyConfirm';
import { useDispatch } from 'react-redux';
import { regenerateApiKey } from 'gridUI/actions';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import { useProjectRegion, usePublicApiUrl } from 'hooks/workspace';
import IconCopySVG from 'assets/images/svg/IconCopySVG';
import { enqueueSnackbar } from 'notifier/actions';
import { enabledRegion } from 'config';

const HEADER_HISTORY_HEIGHT = 72;
const INPUT_FIELDS_HEIGHT = 120;
const STATUS_CODE = 88;
const TABS_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
    root: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    header: {
        paddingLeft: 24,
        paddingRight: 24,
        height: HEADER_HISTORY_HEIGHT,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    title: {
        fontSize: 18
    },
    content: {
        height: `calc(100% - ${HEADER_HISTORY_HEIGHT + STATUS_CODE}px)`,
        overflow: 'hidden',
        paddingLeft: 10,
        paddingRight: 10
    },
    tableContent: {
        height: `calc(100vh - ${HEADER_HEIGHT +
            HEADER_HISTORY_HEIGHT +
            STATUS_CODE +
            TABS_HEIGHT +
            INPUT_FIELDS_HEIGHT}px)`,
        overflowY: 'auto'
    },
    statusCode: {
        background: theme.colors.white,
        borderTop: `1px solid ${theme.colors.border}`,
        width: '100%',
        padding: `8px 24px`,
        height: STATUS_CODE
    },
    text: {
        fontSize: 14
    },
    inputFields: {
        height: INPUT_FIELDS_HEIGHT,
        border: `1px solid ${theme.colors.border}`,
        paddingLeft: 24,
        paddingRight: 24
    },
    key: {
        width: '100%',
        marginBottom: 8
    },
    flex: {
        display: 'flex'
    },
    openApiLinkText: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    regenerateKeyWrapper: {
        margin: theme.spacing(3, 0)
    },
    regenerateKeyButton: {
        cursor: 'pointer'
    },
    regenerateKey: {
        color: theme.colors.lightLavender
    },
    pointer: {
        cursor: 'pointer'
    },
    regionNameWrapper: {
        backgroundColor: theme.colors.seaGreen,
        padding: theme.spacing(1, 3),
        borderRadius: 22
    },
    regionName: {
        color: 'white'
    },
    regionWrapper: {
        padding: theme.spacing(3, 4)
    },
    regionEndpointWrapper: {
        backgroundColor: theme.colors.paleNavy,
        borderRadius: 4,
        marginLeft: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflow: 'hidden'
    },
    regionEndpoint: {
        fontFamily: 'courier !important'
    }
}));

function GridDoc({ handleClose }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const apiKey = useGetApiKey();
    const viewId = useCompositeViewId();
    const dispatch = useDispatch();
    const [isOpenRegenerateKeyModal, setIsOpenRegenerateKeyModal] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const projectRegion = useProjectRegion();
    const publicApiUrl = usePublicApiUrl();

    const closeRegenerateKeyModal = () => {
        setIsOpenRegenerateKeyModal(false);
    };

    const openRegenerateKeyModal = () => {
        sendManualTrack({ type: t('api_key_regenerate') });
        setIsOpenRegenerateKeyModal(true);
    };

    const handleRegenerateKey = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: t('api_key_start_regenerate') });

        dispatch(
            regenerateApiKey({
                successCallback: () => {
                    sendManualTrack({ type: t('api_key_start_regenerate_success') });
                    setIsSubmitting(false);
                    closeRegenerateKeyModal();
                },
                errorCallback: () => {
                    sendManualTrack({ type: t('api_key_start_regenerate_failed') });
                    setIsSubmitting(false);
                    closeRegenerateKeyModal();
                }
            })
        );
    };

    const tabs = [
        {
            label: <Typography variant="body2">{t('api_list_record')}</Typography>,
            content: <ListRecord />
        },
        {
            label: <Typography variant="body2">{t('global_create_record')}</Typography>,
            content: <CreateRecord />
        }
        // {
        //     label: <Typography variant="body2">Set record</Typography>,
        //     content: <SetRecord />
        // }
    ];

    const openAPILink = () => {
        window.open(`https://www.gridly.com/docs/api`, '_blank');
        sendManualTrack({ type: t('api_link_open') });
    };

    const handleCopiedApiKey = () => {
        sendManualTrack({ type: t('api_key_copy') });
    };

    const handleCopiedViewId = () => {
        sendManualTrack({ type: t('view_id_copy') });
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(publicApiUrl);
        dispatch(
            enqueueSnackbar({
                message: t(`global_copied`),
                type: 'info'
            })
        );
    };

    return (
        <Grid container direction="column" wrap="nowrap" justify="space-between" className={classes.root}>
            <Grid item>
                <Grid
                    container
                    className={classes.header}
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                >
                    <Grid item>
                        <Typography variant="h3" className={classes.title}>
                            {t('api_doc_title')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex', cursor: 'pointer' }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item className={classes.openApiLink} onClick={openAPILink}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item className={classes.flex}>
                                        <Tooltip title={t('api_doc_full_open')}>
                                            <OpenNewPageSVG
                                                color={hexToRgba(theme.colors.dodgerBlue, 0.8)}
                                                size="medium"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className={classes.openApiLinkText}>
                                            {t('api_doc_full')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton size="small" onClick={handleClose}>
                                    <CloseIconSVG size="medium" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {enabledRegion ? (
                <Grid item container direction="column" className={classes.regionWrapper} alignItems="flex-start">
                    <Grid item className={classes.regionNameWrapper}>
                        <Typography variant="body1" className={classes.regionName}>
                            {t('project_region')}: {projectRegion?.name}
                        </Typography>
                    </Grid>
                    <Grid item container alignItems="center" wrap="nowrap" style={{ marginTop: 8 }}>
                        <Grid item>
                            <Typography variant="body1" noWrap>
                                API endpoint
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.regionEndpointWrapper}
                            alignItems="center"
                            wrap="nowrap"
                        >
                            <Grid item style={{ overflow: 'hidden' }}>
                                <Typography variant="body2" noWrap className={classes.regionEndpoint}>
                                    {publicApiUrl}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleCopyToClipboard}>
                                    <IconCopySVG />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
            <Grid item className={classes.inputFields} justify="center" direction="column" container wrap="nowrap">
                <Grid item container className={classes.regenerateKeyWrapper} justify="flex-end">
                    <Grid item>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            className={classes.regenerateKeyButton}
                            onClick={openRegenerateKeyModal}
                        >
                            <Grid item className={classes.flex}>
                                <RefreshSVG color={theme.colors.lightLavender} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" className={classes.regenerateKey}>
                                    {t('regenerate_key')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.key}>
                    <InputViewCopyOnly
                        label={t('api_key')}
                        onCopied={handleCopiedApiKey}
                        value={apiKey}
                        secure={true}
                    />
                </Grid>
                <Grid item className={classes.key}>
                    <InputViewCopyOnly label={t('view_id')} onCopied={handleCopiedViewId} value={viewId} />
                </Grid>
            </Grid>
            <Grid item className={classes.content}>
                <Tabs tabs={tabs} tabContentClassName={classes.tableContent} />
            </Grid>
            <Grid item className={`${classes.statusCode}`}>
                <StatusCode />
            </Grid>
            <Dialog open={isOpenRegenerateKeyModal} onClose={closeRegenerateKeyModal}>
                <GenerateKeyConfirm
                    onClose={closeRegenerateKeyModal}
                    onConfirm={handleRegenerateKey}
                    isSubmitting={isSubmitting}
                />
            </Dialog>
        </Grid>
    );
}

export default React.memo(GridDoc);
