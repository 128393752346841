import React from 'react';

function UpdateRecordSVG({ color = 'white', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <circle cx="20" cy="20" r="20" fill="#7869B9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6273 11.7239H28.4149V13.9518H11.6273V11.7239ZM29.2078 15.6738H10.8349C10.3979 15.6738 10.043 15.2872 10.043 14.8124V10.8604C10.043 10.3856 10.3979 9.99894 10.8349 9.99894H29.2078C29.6448 9.99894 29.9997 10.3856 29.9997 10.8604V14.8124C29.9997 15.2872 29.6448 15.6738 29.2078 15.6738Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6273 18.6813H28.4149V20.9092H11.6273V18.6813ZM29.2078 22.6329H10.8349C10.3979 22.6329 10.043 22.2463 10.043 21.7715V17.8194C10.043 17.3446 10.3979 16.958 10.8349 16.958H29.2078C29.6448 16.958 29.9997 17.3446 29.9997 17.8194V21.7715C29.9997 22.2463 29.6448 22.6329 29.2078 22.6329Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5833 25.8224H28.371V28.0503H11.5833V25.8224ZM29.1648 29.7735H10.792C10.3549 29.7735 10 29.3869 10 28.9121V24.9601C10 24.4853 10.3549 24.0986 10.792 24.0986H29.1648C29.6019 24.0986 29.9568 24.4853 29.9568 24.9601V28.9121C29.9568 29.3869 29.6019 29.7735 29.1648 29.7735Z"
                fill="white"
            />
            <ellipse cx="26.3847" cy="25.2767" rx="7.02138" ry="6.90949" fill="#7869B9" />
            <g clipPath="url(#clipUpdateRecord)">
                <path
                    d="M32.7021 25.5818C32.6999 25.4708 32.6599 25.3769 32.5821 25.3006C32.5043 25.2242 32.4097 25.186 32.2982 25.1854L31.1581 25.1769C31.1285 24.5684 30.987 23.9806 30.7337 23.4137C30.4804 22.8469 30.1352 22.349 29.6983 21.9203C28.8754 21.1129 27.9179 20.6508 26.8256 20.5341C25.7333 20.4174 24.7045 20.654 23.7392 21.2442L23.709 21.2748C23.6716 21.3127 23.6534 21.3594 23.6546 21.415C23.6557 21.4706 23.6757 21.5174 23.7146 21.5556L24.6077 22.432C24.6736 22.4966 24.7515 22.5071 24.8416 22.4636C25.2549 22.2955 25.5024 22.2028 25.5842 22.1856C26.1216 22.0589 26.6573 22.0711 27.1913 22.2223C27.7254 22.3735 28.1929 22.6458 28.5939 23.0392C29.1954 23.6294 29.5205 24.3414 29.5693 25.1752L28.3545 25.1705C28.2431 25.1702 28.1498 25.2079 28.075 25.2836C28.0003 25.3594 27.9641 25.4529 27.9661 25.564C27.9684 25.6752 28.0084 25.7689 28.0862 25.8453L30.097 27.8184C30.1748 27.8947 30.2695 27.9331 30.381 27.9335C30.4924 27.9339 30.5855 27.8962 30.6603 27.8204L32.5932 25.8621C32.6679 25.7864 32.7044 25.6931 32.7021 25.5818Z"
                    fill="white"
                />
                <path
                    d="M29.0247 28.968L28.1629 28.1224C28.0971 28.0578 28.0192 28.0472 27.9291 28.0908C27.5159 28.259 27.2684 28.3515 27.1866 28.3688C26.6493 28.4954 26.1134 28.4832 25.5795 28.3321C25.0453 28.1809 24.5779 27.9086 24.177 27.5151C23.8867 27.2304 23.6587 26.9034 23.4929 26.5343C23.3272 26.1651 23.2331 25.78 23.2105 25.3792L24.4164 25.3838C24.5279 25.3843 24.6211 25.3465 24.6958 25.2708C24.7706 25.195 24.807 25.1015 24.8047 24.9904C24.8025 24.8792 24.7626 24.7854 24.6848 24.7091L22.674 22.736C22.5962 22.6596 22.5015 22.6213 22.3899 22.6209C22.2785 22.6204 22.1853 22.6582 22.1105 22.734L20.1777 24.6923C20.1029 24.768 20.0666 24.8615 20.0688 24.9726C20.0711 25.0837 20.111 25.1776 20.1888 25.254C20.2666 25.3303 20.3613 25.3685 20.4728 25.3691L21.6084 25.3733C21.6352 25.9846 21.7737 26.5709 22.024 27.132C22.2742 27.6931 22.6178 28.1879 23.0547 28.6165C23.8746 29.4211 24.8291 29.8802 25.9184 29.994C27.0076 30.1078 28.0335 29.868 28.9959 29.2751C29.0046 29.2722 29.0161 29.2634 29.0305 29.2489C29.0679 29.2109 29.0859 29.1643 29.0849 29.1086C29.0836 29.0531 29.0637 29.0062 29.0247 28.968Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clipUpdateRecord">
                    <rect
                        width="9.65865"
                        height="9.43355"
                        fill="white"
                        transform="matrix(0.713768 0.700383 -0.702463 0.711721 26.252 18.5381)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(UpdateRecordSVG);
