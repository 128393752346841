import React from 'react';

function EyeOpenIconSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.9034 3.506C8.2287 3.506 7.554 3.69877 6.97569 4.08431C6.39738 4.46986 5.91545 5.04817 5.62629 5.72287C5.43352 6.30118 5.33713 7.07227 5.43352 7.74696C5.52991 8.42166 5.91545 9.09636 6.39738 9.57829C6.8793 10.0602 7.554 10.4458 8.2287 10.5421C8.42147 10.5421 8.71063 10.6385 8.9034 10.6385C9.38533 10.6385 9.86726 10.5421 10.2528 10.3494C10.9275 10.0602 11.4094 9.67467 11.8914 8.99998C12.2769 8.42166 12.4697 7.74696 12.4697 7.07227C12.4697 6.10841 12.0841 5.24094 11.4094 4.56624C10.8311 3.89154 9.86726 3.506 8.9034 3.506ZM11.1203 7.45781C11.0239 7.93974 10.8311 8.32528 10.542 8.61443C10.2528 8.90359 9.86726 9.09636 9.38533 9.19275C8.9034 9.28913 8.51786 9.19275 8.13232 9.09636C7.74677 8.90359 7.36123 8.61443 7.16846 8.22889C6.8793 7.93974 6.68653 7.45781 6.68653 7.07227C6.68653 6.49395 6.8793 5.91564 7.36123 5.5301C7.74677 5.14455 8.32509 4.8554 8.9034 4.8554C9.38533 4.8554 9.77087 4.95178 10.1564 5.24094C10.542 5.5301 10.8311 5.81925 11.0239 6.2048C11.2167 6.59034 11.2167 7.07227 11.1203 7.45781Z"
                fill={color}
            />
            <path
                d="M16.6145 5.91566C15.9398 4.46988 14.8795 3.21687 13.4337 2.3494C12.1807 1.48193 10.5422 1 8.90361 1C7.36145 1 5.72289 1.48193 4.37349 2.3494C3.0241 3.21687 1.96386 4.46988 1.28916 5.91566C1.09639 6.3012 1 6.59036 1 7.07229C1 7.45783 1.09639 7.84337 1.28916 8.22892C1.96386 9.6747 3.0241 10.9277 4.46988 11.7952C5.81928 12.6627 7.45783 13.1446 9 13.1446C10.6386 13.1446 12.1807 12.6627 13.5301 11.7952C14.8795 10.9277 15.9398 9.6747 16.7108 8.22892C16.9036 7.84337 17 7.45783 17 7.07229C16.9036 6.59036 16.8072 6.3012 16.6145 5.91566ZM15.5542 7.07229C15.5542 7.26506 15.5542 7.45783 15.4578 7.6506C14.8795 8.90361 13.9157 9.96385 12.759 10.6386C10.4458 12.0843 7.36145 12.0843 5.04819 10.6386C3.89157 9.86747 3.0241 8.80723 2.44578 7.55422C2.3494 7.45783 2.3494 7.26506 2.3494 7.07229C2.3494 6.87952 2.3494 6.68675 2.44578 6.49398C3.0241 5.24096 3.89157 4.18072 5.04819 3.40964C6.20482 2.63855 7.55422 2.25301 8.90361 2.25301C10.253 2.25301 11.6024 2.63855 12.759 3.40964C13.9157 4.18072 14.8795 5.14458 15.4578 6.39759C15.5542 6.59036 15.5542 6.78313 15.5542 7.07229Z"
                fill={color}
            />
            <path
                d="M8.9034 3.506C8.2287 3.506 7.554 3.69877 6.97569 4.08431C6.39738 4.46986 5.91545 5.04817 5.62629 5.72287C5.43352 6.30118 5.33713 7.07227 5.43352 7.74696C5.52991 8.42166 5.91545 9.09636 6.39738 9.57829C6.8793 10.0602 7.554 10.4458 8.2287 10.5421C8.42147 10.5421 8.71063 10.6385 8.9034 10.6385C9.38533 10.6385 9.86726 10.5421 10.2528 10.3494C10.9275 10.0602 11.4094 9.67467 11.8914 8.99998C12.2769 8.42166 12.4697 7.74696 12.4697 7.07227C12.4697 6.10841 12.0841 5.24094 11.4094 4.56624C10.8311 3.89154 9.86726 3.506 8.9034 3.506ZM11.1203 7.45781C11.0239 7.93974 10.8311 8.32528 10.542 8.61443C10.2528 8.90359 9.86726 9.09636 9.38533 9.19275C8.9034 9.28913 8.51786 9.19275 8.13232 9.09636C7.74677 8.90359 7.36123 8.61443 7.16846 8.22889C6.8793 7.93974 6.68653 7.45781 6.68653 7.07227C6.68653 6.49395 6.8793 5.91564 7.36123 5.5301C7.74677 5.14455 8.32509 4.8554 8.9034 4.8554C9.38533 4.8554 9.77087 4.95178 10.1564 5.24094C10.542 5.5301 10.8311 5.81925 11.0239 6.2048C11.2167 6.59034 11.2167 7.07227 11.1203 7.45781Z"
                stroke={color}
                strokeWidth="0.1"
            />
            <path
                d="M16.6145 5.91566C15.9398 4.46988 14.8795 3.21687 13.4337 2.3494C12.1807 1.48193 10.5422 1 8.90361 1C7.36145 1 5.72289 1.48193 4.37349 2.3494C3.0241 3.21687 1.96386 4.46988 1.28916 5.91566C1.09639 6.3012 1 6.59036 1 7.07229C1 7.45783 1.09639 7.84337 1.28916 8.22892C1.96386 9.6747 3.0241 10.9277 4.46988 11.7952C5.81928 12.6627 7.45783 13.1446 9 13.1446C10.6386 13.1446 12.1807 12.6627 13.5301 11.7952C14.8795 10.9277 15.9398 9.6747 16.7108 8.22892C16.9036 7.84337 17 7.45783 17 7.07229C16.9036 6.59036 16.8072 6.3012 16.6145 5.91566ZM15.5542 7.07229C15.5542 7.26506 15.5542 7.45783 15.4578 7.6506C14.8795 8.90361 13.9157 9.96385 12.759 10.6386C10.4458 12.0843 7.36145 12.0843 5.04819 10.6386C3.89157 9.86747 3.0241 8.80723 2.44578 7.55422C2.3494 7.45783 2.3494 7.26506 2.3494 7.07229C2.3494 6.87952 2.3494 6.68675 2.44578 6.49398C3.0241 5.24096 3.89157 4.18072 5.04819 3.40964C6.20482 2.63855 7.55422 2.25301 8.90361 2.25301C10.253 2.25301 11.6024 2.63855 12.759 3.40964C13.9157 4.18072 14.8795 5.14458 15.4578 6.39759C15.5542 6.59036 15.5542 6.78313 15.5542 7.07229Z"
                stroke={color}
                strokeWidth="0.1"
            />
        </svg>
    );
}

export default React.memo(EyeOpenIconSVG);
