import { useSelector } from 'react-redux';

export function useViewImportLoading() {
    return useSelector(state => state.gridUI.viewImport?.loading);
}

export function useViewImportFile() {
    return useSelector(state => state.gridUI.viewImport?.file);
}

export function useViewImportFileContent() {
    return useSelector(state => state.gridUI.viewImport?.fileContent);
}

export function useLocalizationImport() {
    return useSelector(state => state.gridUI.localizationImport);
}

export function useGridScratchImport() {
    return useSelector(state => state.gridUI.gridScratchImport);
}

export function useViewImportResourceKey() {
    return useSelector(state => state.gridUI.viewImport?.resourceKey);
}

export function useJsonTree(dbId) {
    return useSelector(state => state.gridUI.localizationJSONImport?.[dbId]?.tree);
}

export function useJsonResourceKey(dbId) {
    return useSelector(state => state.gridUI.localizationJSONImport?.[dbId]?.resourceKey);
}

export function useJsonObjectIds(dbId) {
    return useSelector(state => state.gridUI.localizationJSONImport?.[dbId]?.objectIds);
}

export function usePOHeader(dbId) {
    return useSelector(state => state.gridUI.localizationPOImport?.[dbId]?.headers);
}

export function usePoTargetLanguage(dbId) {
    return useSelector(state => state.gridUI.localizationPOImport?.[dbId]?.poTargetLanguage);
}

export function usePOResourceKey(dbId) {
    return useSelector(state => state.gridUI.localizationPOImport?.[dbId]?.resourceKey);
}

export function usePOMessages(dbId) {
    return useSelector(state => state.gridUI.localizationPOImport?.[dbId]?.messages);
}

export function useTaskStatus(taskId) {
    return useSelector(state => state?.gridUI.importTasks?.[taskId]);
}

export function usePOMessageKeys(dbId) {
    return useSelector(state => state.gridUI.localizationPOImport?.[dbId]?.messageKeys);
}
