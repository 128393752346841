import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';

const commentApis = {
    comments: ({ dbId, gridId }) => `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/comments`,
    getCellCommentList: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/comments/cell`,
    updateCellCommentThreadStatus: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/comments/thread`,
    deleteCellComment: ({ dbId, gridId, commentId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/comments/${commentId}`,
    updateCellComment: ({ dbId, gridId, commentId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/comments/${commentId}`,
    commentListByCompositeViewId: `${APP_API_PREFIX_GRID_UI}/api/v1/comments`,
    getCellCommentListWithCompositeViewId: `${APP_API_PREFIX_GRID_UI}/api/v1/comments/cell`
};

export async function updateCellCommentApi({ currentViewId, dbId, gridId, commentId, data }) {
    const url = `${commentApis.updateCellComment({ dbId, gridId, commentId })}?currentViewId=${currentViewId}`;
    const response = await request.put(url, data);
    return response.data;
}

export async function deleteCellCommentApi({ currentViewId, dbId, gridId, commentId }) {
    const url = `${commentApis.deleteCellComment({ dbId, gridId, commentId })}?currentViewId=${currentViewId}`;
    const response = await request.delete(url);
    return response.data;
}

export async function createCellCommentApi({ currentViewId, dbId, gridId, data }) {
    const url = `${commentApis.comments({ currentViewId, dbId, gridId })}?currentViewId=${currentViewId}`;
    const response = await request.post(url, data);
    return response.data;
}

export async function fetchGridCommentsApi({ query }) {
    let url = commentApis.commentListByCompositeViewId;
    const queryParamsFormatted = objectToQueryParams({ ...query, sort: 'createdAt,desc' });
    url += `?${queryParamsFormatted}`;
    const response = await request.get(url);
    return {
        comments: response.data,
        totalComments: Number(response?.headers?.['x-total-count'] || 0)
    };
}

export async function fetchCellCommentListApi({ compositeViewId, columnId, rowId }) {
    const response = await request.get(
        `${commentApis.getCellCommentListWithCompositeViewId}?viewId=${compositeViewId}&columnId=${columnId}&rowId=${rowId}`
    );
    return response.data;
}

export async function updateCellCommentThreadStatusApi({ dbId, gridId, data }) {
    const response = await request.put(commentApis.updateCellCommentThreadStatus({ dbId, gridId }), data);
    return response.data;
}
