import React from 'react';

function PrivacyPolicySVG({ ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_4915_232220)">
                <path
                    d="M8 2.12675L12.6667 4.20008V7.33342C12.6667 10.3467 10.68 13.1267 8 13.9534C5.32 13.1267 3.33333 10.3467 3.33333 7.33342V4.20008L8 2.12675ZM8 0.666748L2 3.33341V7.33342C2 11.0334 4.56 14.4934 8 15.3334C11.44 14.4934 14 11.0334 14 7.33342V3.33341L8 0.666748ZM7.33333 4.66675H8.66667V6.00008H7.33333V4.66675ZM7.33333 7.33342H8.66667V11.3334H7.33333V7.33342Z"
                    fill="#78778B"
                />
            </g>
            <defs>
                <clipPath id="clip0_4915_232220">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(PrivacyPolicySVG);
