import React from 'react';

function EmptyGridSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00208 0.632812C3.46281 0.632812 2.94562 0.847038 2.5643 1.22836C2.18298 1.60969 1.96875 2.12687 1.96875 2.66615V13.3328C1.96875 13.8721 2.18298 14.3893 2.5643 14.7706C2.94562 15.1519 3.46281 15.3661 4.00208 15.3661H12.0021C12.5414 15.3661 13.0585 15.1519 13.4399 14.7706C13.8212 14.3893 14.0354 13.8721 14.0354 13.3328V5.33281C14.0354 5.27697 14.0287 5.22182 14.0158 5.16842C13.9858 5.04428 13.9222 4.92963 13.8304 4.83784L9.83039 0.837838C9.79392 0.801363 9.75383 0.76933 9.71098 0.742085C9.6069 0.67575 9.48411 0.636166 9.3523 0.633016C9.34668 0.63288 9.34105 0.632812 9.33542 0.632812H9.33525H4.00208ZM8.63525 2.03281H4.00208C3.83411 2.03281 3.67302 2.09954 3.55425 2.21831C3.43548 2.33708 3.36875 2.49818 3.36875 2.66615V13.3328C3.36875 13.5008 3.43548 13.6619 3.55425 13.7806C3.67302 13.8994 3.83411 13.9661 4.00208 13.9661H12.0021C12.1701 13.9661 12.3311 13.8994 12.4499 13.7806C12.5687 13.6619 12.6354 13.5008 12.6354 13.3328V6.03281H9.33525C8.94865 6.03281 8.63525 5.71941 8.63525 5.33281V2.03281ZM11.6455 4.63281L10.0353 3.0226V4.63281H11.6455Z"
                fill={color}
                {...other}
            />
        </svg>
    );
}

export default React.memo(EmptyGridSVG);
