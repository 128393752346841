import React, { useMemo, useRef } from 'react';
import { Grid } from '@material-ui/core';
import Option from 'components/option/Base';
import { ROW_HEIGHT } from 'const/gridUI';
import { DEFAULT_PATH_TAG_COLOR } from 'utils/color';
import useRefDimensions from 'hooks/useRefDimensions';
import { getNodesByPath } from 'utils/gridUI/pathTag';
import { usePathTagRef } from 'hooks/gridUI';
import classNames from 'classnames';

function PathTag({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex, options = [], customProperties }) {
    const ref = useRef();
    const { height: refHeight } = useRefDimensions(ref);
    const pathRef = usePathTagRef();

    const lines = useMemo(() => {
        const _lines = Math.floor(refHeight / 25) - 1;
        return Math.max(1, _lines);
    }, [refHeight]);

    const style = useMemo(() => {
        return {
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': `${lines}`,
            display: '-webkit-box'
        };
    }, [lines]);

    const _value = React.useMemo(() => {
        return Array.isArray(value) ? value : getNodesByPath({ pathRef, path: value });
    }, [pathRef, value]);
    if (!_value) return null;

    return (
        <Grid
            container
            className={`w-full justify-start items-start pointer-events-none`}
            style={{
                height: rowHeight
            }}
        >
            <Grid
                ref={ref}
                item
                className={classNames(`cell_${rowIndex}_${columnIndex} gap-2 flex flex-wrap`)}
                style={style}
            >
                {_value?.map((node, index) => {
                    return (
                        <React.Fragment key={node?.id}>
                            <Option
                                key={node?.id}
                                size="small"
                                label={node?.name}
                                color={node?.customProperties?.color || DEFAULT_PATH_TAG_COLOR}
                            />
                            {index !== _value?.length - 1 && <p className="body1 inline mx-2">/</p>}
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Grid>
    );
}

export default React.memo(PathTag);
