import {
    SET_DEFAULT_ROUTER_PARAMS,
    SAVE_TABLE_INFO,
    CLEAR_TABLE_DATA,
    SCROLL_TO_INDEX,
    REGISTER_HOLD_EVENT,
    REGISTER_MERGING_TASK,
    SET_IS_PREVIEW_SELECTION_RANGE_FILE_DATA,
    SET_SELECTION_RANGE_FILE_DATA,
    SET_IS_FETCHING_SELECTION_RANGE_FILE_DATA,
    SET_OPEN_RIGHT_SIDE_BAR
} from './types';
import { USER_LOGOUT_SUCCESS } from '../auth/types';
import { conditionsByType } from './conditions';
import {
    RECORDS_RENDER,
    DEFAULT_GRID_HISTORY_LIMIT,
    DEFAULT_GRID_HISTORY_OFFSET,
    ROW_HEIGHT,
    LQA_TICKET_STATUS,
    TAG_DETECT_MODE,
    DEFAULT_RECORD_HISTORY_HEIGHT
} from 'const/gridUI';
import { columnTypes } from './column-types';
import createReducer from 'utils/createReducer';
import {
    cellHandler,
    columnHandler,
    rowHandler,
    viewHandler,
    viewFilterSortHandler,
    gridHandler,
    optimisticHandler,
    copyPasteHandler,
    exportHandler,
    importHandler,
    cellColorHandler,
    apiKeyHandler,
    commentDashboardHandler,
    recordHistoryHandler,
    undoRedoHandler,
    statusHandler,
    gridHistoryHandler,
    shareViewHandler,
    dependencyHandler,
    tmHandler,
    userActiveActionsHandler,
    pathTagReducer,
    aggregationHandler,
    quickFilterReducer,
    webhookReducer,
    audioReducer,
    translationDashboardReducer,
    dataReducer,
    cellFilePreviewReducer,
    recordPanelReducer,
    searchReducer,
    cellPreviewHandler,
    cellIssueHandler,
    rangeHandler,
    lqaHandler,
    tbHandler,
    cellCommentHandler,
    tagHandler,
    autoQAHandler,
    cellCopiedHandler,
    automationHandler
} from './reducers';
import { ISSUE_TYPE_OPTIONS } from 'utils/gridUI/lqa';
import i18n from 'i18n';

const INITIAL_STATE = {
    columns: [],
    rows: [],
    data: {},
    metaData: [],
    //aggregation List
    //hold position and column when open aggregation column list
    //Aggregation map between aggregation Value and column,
    aggregations: {
        columnId: {
            type: 'empty',
            value: 20,
            isFetching: false
        }
    },
    viewAggregates: [],
    isCreatingColumn: false,
    isCreatingRecord: false,

    //ROW And COLUMN selection
    columnsSelected: [],
    rowsSelected: [],
    oldRowIdSelected: null,
    oldRowIndexSelected: null,
    oldColumnIdSelected: null,

    //re-order-record
    afterRecordId: null,
    beforeRecordId: null,
    currentRecordId: null,

    conditionsByType,
    totalRecords: 0,
    totalRecordsWithoutFilters: 0,
    ROW_START_INDEX: 0,
    ROW_STOP_INDEX: RECORDS_RENDER,
    isLoading: null,
    isFetchingMore: false,
    error: '',
    optimistics: [],
    tableInfo: null,
    isOpenCellEdit: false,
    character: '',
    quickFilters: {
        // columnId: {
        //     currentState: 'boolean',
        //     value: 'any',
        //     type: 'string',
        //     operator: 'contain',
        //     extraFilter: `EXTRA_QUICK_FILTER`
        // }
        // `${columnId}._cell_metadata`: { hasQaError: true }
    },
    quickSorts: {},
    advanced_filters: {},
    advanced_sorts: {},
    isFilteringSorting: false,

    //format column
    columnTypes,

    //range selection
    isSelecting: false,
    cellCopySelection: {
        rowStartIndex: -1,
        rowStopIndex: -1,
        columnStartIndex: -1,
        columnStopIndex: -1
    },
    cellSelecteds: [],
    cellsCopySelected: [],
    rowStartIndex: -1,
    rowStopIndex: -1,
    columnStartIndex: -1,
    columnStopIndex: -1,
    tempRangeSelection: {},
    isOpenCellContext: false,

    //copy-paste
    copyPasteStack: null,

    //scroll gridUI
    scrollToColumn: 0,
    scrollToRow: 0,
    //view
    views: [],
    isAddingView: false,
    columnsPermission: [],
    viewColumns: [],
    currentView: null,
    isFetchingView: true,
    isViewBlocked: false,

    //columns permission
    isUpdatingColumnsPermission: false,

    //global filters,
    viewFilters: [],

    //view sort orders
    viewSorts: [],

    //grids,
    grids: [],
    //rowHeight Dynamic
    lastScrollPosition: {
        currentScrollTop: 0,
        currentScrollLeft: 0
    },
    fixedColumnCount: 0,
    exportRemovedColumns: [],
    //columnWidth Dynamic
    viewImport: {
        loading: false,
        file: null,
        fileContent: null
    },
    //all disabled columns
    disabledColumns: [],
    isFetchingDisabledColumnData: false,

    //all processing columns,
    processingColumns: [],
    disabledSourceColumns: [],

    //color for cell
    currentCellColor: undefined,
    selectedCellColor: null,
    recordMetaData: {},

    //ApiKey
    apiKey: null,

    //cell comment
    comment: {
        threadId: {}
    },
    //comment dashboard
    commentDashboard: {
        viewId: {
            list: [],
            isFetching: false,
            error: 'Some message',
            totalComments: 0,
            currentPage: 0
        }
    },
    COMMENT_SIZE: 10,

    totalComments: 0,

    //recordHistory
    isOpenRecordHistory: false,
    recordHistoryId: null,
    recordHistoryIndex: null,
    isFetchingRecordHistory: false,
    RECORD_HISTORY_ROW_START_INDEX: 0,
    RECORD_HISTORY_ROW_STOP_INDEX: RECORDS_RENDER,
    totalRecordHistory: 0,
    isTriggerRefreshRecordHistory: false,
    isFetchingMoreHistory: false,
    recordHistory: [],
    //Text wrapping
    isAutoTextWrappingEnable: true,
    recordHistoryHeight: DEFAULT_RECORD_HISTORY_HEIGHT,

    //Undo Redo
    undoStack: [],
    redoStack: [],
    undoRedoData: {},
    jobList: [],

    //register global  event listener
    isRecomputedGrid: false,

    //status
    doingActionIds: [],

    //grid history
    historyOfGrid: {
        branchId: {
            isFetching: false,
            gridHistory: [],
            grid_begin: null,
            GRID_HISTORY_OFFSET: DEFAULT_GRID_HISTORY_OFFSET,
            GRID_HISTORY_LIMIT: DEFAULT_GRID_HISTORY_LIMIT,
            totalGridHistory: 0
        }
    },

    //share view
    shareView: {
        viewId: {
            groups: []
        },
        workspaceMembers: [],
        isFetching: false,
        error: null,
        selectedGroupId: null
    },

    //localization import
    localizationImport: {
        isFetching: false,
        file: null,
        error: null,
        data: {},
        columns: [],
        metaData: {},
        rows: [],
        columnMap: {
            columnId: {
                type: 'Source',
                country: 'arSA'
            }
        },
        resourceKey: null
    },
    localizationJSONImport: {
        dbId: {
            resourceKey: null,
            file: null,
            tree: null
        }
    },
    localizationPOImport: {
        dbId: {
            resourceKey: null,
            file: null,
            messages: [],
            header: null
        }
    },
    //localization import
    gridScratchImport: {
        isFetching: false,
        file: null,
        error: null,
        data: {},
        columns: [],
        metaData: {},
        rows: [],
        columnMap: {
            columnId: {
                columnType: 'multiLines',
                columnName: 'English'
            }
        }
    },
    dependencies: [],
    isFetchingDependencies: false,
    isDeletingDependency: false,
    isCreatingDependency: false,
    isUpdatingDependency: false,
    isOpenNotiDependency: false,
    //Grid
    dataFromTM: {
        rowId: {
            columnId: null
        }
    },
    tmId: null,
    defaultTm: null,
    isApplyingTM: false,

    activeUsers: [],
    activeUserActions: [],
    userRealtimeActions: [],
    currentUserAction: null,

    //path tag
    isPathTagOn: false,
    isFetchingPathTagTree: false,
    expanded: [],
    tree: [],
    pathRef: {},
    nodeIdCreating: null,
    nodeIdShowPopup: null,
    isCreatingNode: false,
    isFilteringPathTag: false,

    //drag and drop path tag
    isDraggingRows: false,
    draggingRows: [],
    draggingRowIndexes: [],
    draggingRowTempData: {},
    dropPath: null,
    draggingPath: null,

    //webhook for grid
    webHooks: {
        viewId: {
            isFetching: false,
            list: [],
            isCreating: false,
            firstCreatedHookId: null,
            isFetchingLog: false,
            logs: null,
            LOG_OFFSET: 0,
            LOG_LIMIT: 50,
            totalLog: 0
        }
    },
    audio: {
        playingFile: null
    },

    // column upload folder
    // columnUploadFolderStatus: {
    // columnId: {
    //     filePath: {
    //         isUploading: false,
    //         error: ''
    //     }
    // }
    // },

    columnUploadFolderStatus: [
        // {
        //     columnId: 23,
        //     isCombinedWithPathTag: false,
        //     isExpanding: true
        //     files: [
        //         {
        //             filePath: 'path1/path2',
        //             isUploading: false,
        //             error: '',
        //         }
        //     ]
        // }
    ],

    translationDashboard: {
        viewId: {
            isFetching: false,
            data: null
            /*columnId: {
                all: {
                    recordCount: 0,
                    wordCount: 0
                },
                outOfDate: {
                    recordCount: 0,
                    wordCount: 0
                },
                unset: {
                    recordCount: 0,
                    wordCount: 0
                },
                upToDate: {
                    recordCount: 0,
                    wordCount: 0
                }
            }*/
        }
    },
    shareLinkDetail: {
        compositeViewId: null
    },
    exportPreview: {
        viewId: {
            isFetching: false,
            data: null,
            rows: null
        }
    },
    cellPreview: null,
    rowHeight: ROW_HEIGHT,
    // columnId: null,
    // rowId: null,
    // defaultPreviewFile: null,
    isTurnOnRecordPanel: false,
    pinnedSearch: false,
    gridTotalRecords: 0,
    importTasks: {},
    isOpenCellPreview: false,
    isOpenCellIssue: false,
    pinnedCellIssueId: null,
    isSearchingRecord: false,
    isOpenCellCellPreview: false,
    isDraggingPathTag: false,
    dragPathId: null,
    dragNodeIds: [],
    rowIndexMap: {},
    rowsRangeIndexes: [],

    //LQA
    lqaModels: [],
    lqaModelDefault: null,
    isLoadingLQATickets: false,
    openLQATicketDetail: false,
    lqaTicketDetail: null,
    isOpenViewCellTicket: false,
    confirmDeleteTicket: {
        isOpen: false,
        ticket: null
    },
    lqaTicket: {
        [LQA_TICKET_STATUS.OPEN]: [],
        [LQA_TICKET_STATUS.RESOLVED]: [],
        [LQA_TICKET_STATUS.ALL]: []
    },
    lqaTotal: {
        [LQA_TICKET_STATUS.OPEN]: 0,
        [LQA_TICKET_STATUS.RESOLVED]: 0,
        [LQA_TICKET_STATUS.ALL]: 0
    },
    lqaPageNumber: {
        [LQA_TICKET_STATUS.OPEN]: 1,
        [LQA_TICKET_STATUS.RESOLVED]: 1,
        [LQA_TICKET_STATUS.ALL]: 1
    },
    lqaResetQuery: false,
    lqaTab: LQA_TICKET_STATUS.OPEN,
    lqaExporting: false,
    isOpenReplaceSearch: false,
    lqaCreateType: ISSUE_TYPE_OPTIONS?.[0],
    replacingText: false,
    searchRecords: [],
    successReplaceAll: {},
    formatColumnId: null,
    //TB
    listTermBases: [],
    dataFromTB: {
        sourceData: {
            rowId: {
                langSource: {
                    langTarget: [{ langSourceText: null, langTargetText: null }]
                }
            }
        }
    },
    replacingType: {},
    isShowTag: true,
    tagMode: TAG_DETECT_MODE.AUTO,
    tagRegex: '',
    tagModels: [],
    predefinedTokens: [],
    // AutoQA
    isShowAutoQA: false,
    isRunningAutoQA: false,
    showErrorControlPanel: false,
    autoQAErrorTypes: [
        // { value: 'all', label: 'All', isActive: true, color: '#77B32B' },
        { value: 'spelling', label: 'Spelling', isActive: true, color: '#8B7CCB' },
        { value: 'specialchardetect', label: 'Symbols', isActive: true, color: '#7DD7E9' },
        { value: 'urls', label: 'Links', isActive: true, color: '#57ACF5' },
        { value: 'numbers', label: 'Numerals', isActive: true, color: '#FA9255' },
        { value: 'punctuation', label: 'Punctuation', isActive: true, color: '#F9BD42' },
        // { value: 'inconsistencies', label: 'Inconsistencies', isActive: true, color: '#1D66AE' },
        // { value: 'untranslated', label: 'Untranslated', isActive: true, color: '#2DD2A3' },
        // { value: 'empty', label: 'Empty', isActive: true, color: '#F67B8E' },
        // { value: 'customchecks', label: 'Custom checks', isActive: true, color: '#04724D' },
        // { value: 'languagetool', label: 'Language tool', isActive: true, color: '#9B7E46' },
        // { value: 'onepolicheck', label: 'One Poli Check', isActive: true, color: '#B1CC74' },
        { value: 'lengthchecks', label: 'Length checks', isActive: true, color: '#F4B266' },
        { value: 'glossary', label: i18n.t('global_glossary'), isActive: true, color: '#EE7674' }
    ],
    autoQASettings: [],
    ignoreSimilarErrors: {
        selectedValidation: null,
        isOpenConfirmModal: false
    },

    clearingRecords: {},
    undoRedoQueue: [],
    isUndoRedoProcessing: false,
    copiedRange: {
        rowStartIndex: -1,
        rowStopIndex: -1,
        columnStartIndex: -1,
        columnStopIndex: -1
    },
    holdEvent: null,
    currentGrid: null,
    mergingTasks: {},
    toggleFakeColumn: false,
    isCellCopying: false,

    //AUTOMATION
    automations: [],
    isFetchingAutomations: false,
    automationDetail: {},
    openAutomationDetail: false,
    automationDetailError: {
        triggerId: { watchingColumns: '' },
        nodeId: { url: '' }
    },
    listAutomationExecutions: [],
    selectionRangeFileData: [],
    isPreviewSelectionRangeFileData: false,
    isFetchingSelectionRangeFileData: false,
    isOpenAnimationDialog: false,
    showSearchRange: false,
    searchRange: null,
    openRightSideBar: false
};

const commonHandler = {
    [USER_LOGOUT_SUCCESS](state, action) {
        console.log('USER_LOGOUT_SUCCESS');
        return {
            ...INITIAL_STATE,
            isPathTagOn: state?.isPathTagOn
        };
    },
    [SET_DEFAULT_ROUTER_PARAMS](state, action) {
        const { defaultAccessViewId, workspaceId, gridId, dbId, branchId, parentGridId } = action.payload;
        return {
            ...state,
            defaultAccessViewId,
            workspaceId,
            gridId,
            dbId,
            branchId,
            parentGridId
        };
    },

    [SAVE_TABLE_INFO](state, action) {
        const { tableInfo } = action.payload;
        return {
            ...state,
            tableInfo: tableInfo
        };
    },

    [CLEAR_TABLE_DATA](state, action) {
        return {
            ...INITIAL_STATE,
            isPathTagOn: state?.isPathTagOn
        };
    },

    [SCROLL_TO_INDEX](state, action) {
        const { columnIndex, rowIndex } = action.payload;
        return {
            ...state,
            scrollToColumn: columnIndex,
            scrollToRow: rowIndex
        };
    },
    [REGISTER_HOLD_EVENT](state, action) {
        const { event } = action.payload;
        return {
            ...state,
            holdEvent: event
        };
    },
    [REGISTER_MERGING_TASK](state, action) {
        const { task } = action.payload;
        return {
            ...state,
            mergingTasks: {
                ...state?.mergingTasks,
                [task.id]: task
            }
        };
    },
    [SET_IS_PREVIEW_SELECTION_RANGE_FILE_DATA](state, action) {
        return {
            ...state,
            isPreviewSelectionRangeFileData: action.payload
        };
    },
    [SET_SELECTION_RANGE_FILE_DATA](state, action) {
        return {
            ...state,
            selectionRangeFileData: action.payload,
            isFetchingSelectionRangeFileData: false
        };
    },
    [SET_IS_FETCHING_SELECTION_RANGE_FILE_DATA](state, action) {
        return {
            ...state,
            isFetchingSelectionRangeFileData: action.payload
        };
    },
    [SET_OPEN_RIGHT_SIDE_BAR](state, action) {
        return {
            ...state,
            openRightSideBar: action.payload
        };
    }
};

export default createReducer(
    INITIAL_STATE,
    Object.assign(
        commonHandler,
        cellHandler,
        columnHandler,
        rowHandler,
        viewHandler,
        viewFilterSortHandler,
        gridHandler,
        optimisticHandler,
        copyPasteHandler,
        exportHandler,
        importHandler,
        cellColorHandler,
        apiKeyHandler,
        commentDashboardHandler,
        recordHistoryHandler,
        undoRedoHandler,
        statusHandler,
        gridHistoryHandler,
        shareViewHandler,
        dependencyHandler,
        tmHandler,
        userActiveActionsHandler,
        pathTagReducer,
        aggregationHandler,
        quickFilterReducer,
        webhookReducer,
        audioReducer,
        translationDashboardReducer,
        dataReducer,
        cellFilePreviewReducer,
        recordPanelReducer,
        searchReducer,
        cellPreviewHandler,
        cellIssueHandler,
        rangeHandler,
        lqaHandler,
        tbHandler,
        cellCommentHandler,
        tagHandler,
        autoQAHandler,
        cellCopiedHandler,
        automationHandler
    )
);
