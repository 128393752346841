import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useDropzone } from 'react-dropzone';
import { getUploadErrorMessage } from 'utils/upload';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { GRID_UPLOAD_MAX_SIZE, USAGE_PROPERTIES } from 'const';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useIsOverThresHoldProperty } from 'hooks/payment/usage';
import PreviewCellSVG from 'assets/images/svg/PreviewCellSVG';
import { useResourceCellByRowId } from 'hooks/advanced';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    upload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.divider,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        '& svg': {
            width: 10,
            height: 10
        },
        '& input:focus': {
            outlined: 'none'
        },
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    preview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        cursor: 'pointer'
    }
}));

function FileUploadHandler({
    actualColumnId,
    actualRowId,
    columnId,
    rowId,
    isReadOnly,
    data,
    isTriggerUpload,
    onReset,
    handleContextMenu,
    height,
    dbId
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isOverFileStorage = useIsOverThresHoldProperty(USAGE_PROPERTIES.MAX_STORAGE_GB);
    const uploadFileRef = React.useRef();
    const resource = useResourceCellByRowId(rowId);

    React.useEffect(() => {
        if (isTriggerUpload && uploadFileRef.current) {
            uploadFileRef.current.click();
        }
    }, [isTriggerUpload]);

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles);
            const fileError = fileErrors?.[0];
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const onDropAccepted = React.useCallback(
        acceptedFiles => {
            let item = acceptedFiles[0];
            let formData = new FormData();
            formData.append('file', item);
            dispatch(
                advancedSearchActions.uploadFileForCell({
                    formData,
                    rowId,
                    columnId,
                    actualColumnId,
                    actualRowId,
                    dbId: resource?.dbId,
                    gridId: resource?.gridId,
                    successCallback: () => {
                        console.log('upload file successfully');
                        dispatch(advancedSearchActions.cancelCellEdit());
                    },
                    errorCallback: () => {
                        console.log('Failed to upload file');
                    }
                })
            );
        },
        [columnId, actualColumnId, actualRowId, resource, dispatch, rowId]
    );

    const { getInputProps, open } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: false,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noClick: true,
        noKeyboard: true
    });

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleOpenFile = React.useCallback(
        file => {
            if (isOverFileStorage) {
                // handleOpenLimitModal();
                return;
            }

            return open();
        },
        [isOverFileStorage, open]
    );

    const handleClickOpen = ({ e, fileMeta }) => {
        stopPropagation(e);
        let body = {
            rowId,
            columnId,
            dbId
        };
        if (fileMeta) {
            body = {
                ...body,
                defaultPreviewFile: fileMeta
            };
        }
        dispatch(advancedSearchActions.openCellFilePreview(body));
    };

    const previewIcon = () => {
        return (
            <Grid
                id={`upload_${rowId}_${columnId}`}
                style={{
                    position: 'absolute',
                    right: 6,
                    top: height / 2,
                    bottom: 0,
                    margin: 'auto',
                    zIndex: 3,
                    width: 16,
                    height: 16,
                    background: theme.colors.white
                }}
                className={classes.preview}
                onClick={e => handleClickOpen({ e })}
                onContextMenu={handleContextMenu}
            >
                <PreviewCellSVG />
            </Grid>
        );
    };

    const baseUpload = () => {
        return (
            <Grid
                id={`upload_${rowId}_${columnId}`}
                style={{
                    position: 'absolute',
                    left: 1,
                    top: height / 2,
                    bottom: 0,
                    margin: 'auto',
                    zIndex: 3,
                    width: 20,
                    height: 28,
                    outline: 'none'
                }}
                className={classes.upload}
                onClick={handleOpenFile}
                ref={uploadFileRef}
                onContextMenu={handleContextMenu}
            >
                <input style={{ outline: 'none' }} {...getInputProps({})} />
                <AddIconSVG />
            </Grid>
        );
    };

    return (
        <>
            {!isReadOnly && baseUpload()}
            {data?.value?.length > 0 && previewIcon()}
        </>
    );
}

export default React.memo(FileUploadHandler);
