import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import Search from 'components/search';
import { getFriendlyDate } from 'utils/datetime';
import Spinner from 'components/spinner/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import {
    useList,
    useIsFetchingList,
    useIsBlockedGrid,
    useActiveTab,
    useAdvancedSharedList,
    useIsHasManageAdvancedSearchPermission
} from 'hooks/advanced';
import { DISABLED_OPACITY } from 'const/style';
import ButtonBase from 'components/button/Base';
import AdvancedSearchSearchItem from './AdvancedSearchSearchItem';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import { HEADER_HEIGHT } from 'const';
import { useHistory, useParams } from 'react-router-dom';
import { ADVANCED_SEARCH_TAB } from 'const/gridUI';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%'
    },
    searchBox: {
        paddingLeft: 20,
        paddingRight: 20
    },
    table: {
        marginTop: theme.spacing(2),
        position: 'relative',
        flex: 1,
        minHeight: 200,
        '& .active': {
            '& .ReactVirtualized__Table__row': {
                background: theme.colors.ghostwhite
            }
        }
    },
    addNew: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: 20,
        paddingRight: 20,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    search: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        height: `calc(100vh - ${HEADER_HEIGHT + 65 + 48}px)`,
        overflowY: 'auto'
    },
    mb4: {
        marginBottom: theme.spacing(4)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    mb1: {
        marginBottom: theme.spacing(1)
    },
    expand: {
        flex: 1
    },
    fullHeight: {
        height: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    tabContent: {
        flex: 1,
        width: '100%'
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue,
        minWidth: 120
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));

function SearchList() {
    const theme = useTheme();
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const isFetchingList = useIsFetchingList();
    const dispatch = useDispatch();
    const history = useHistory();
    const { workspaceId } = useParams();
    const tabValue = useActiveTab();
    const isHasManageAdvancedSearchPermission = useIsHasManageAdvancedSearchPermission();

    const handleChangeTab = React.useCallback(
        (e, newValue) => {
            dispatch(advancedSearchActions.setTab(newValue));
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (!isHasManageAdvancedSearchPermission) {
            dispatch(advancedSearchActions.setTab(ADVANCED_SEARCH_TAB.SHARED));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleCreateNewSearch = React.useCallback(() => {
        if (window.advancedSearchDirty) {
            dispatch(
                advancedSearchActions.setOpenConfirmation({
                    open: true,
                    nextAdvancedSearchId: null
                })
            );
            return;
        }

        dispatch(advancedSearchActions.setClearGrid(true));
        dispatch(advancedSearchActions.resetGrid(['list', `sharedList`, 'routeInfo', 'clearGrid', 'tab']));
        history.push(`/p/${workspaceId}/s`);
    }, [dispatch, workspaceId, history]);

    return (
        <Grid className={classes.root} item container direction="column">
            {isHasManageAdvancedSearchPermission && (
                <Grid item className={`${classes.addNew} ${classes.fullWidth}`}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item className={classes.fullWidth}>
                            <ButtonBase
                                onClick={handleCreateNewSearch}
                                disabled={isFetchingList}
                                fullWidth
                                variant="contained"
                                startIcon={<AddIconSVG color={theme.palette.common.white} />}
                            >
                                New query
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item className={`${classes.expand} ${classes.fullWidth}`}>
                <Grid container direction="column" className={classes.root}>
                    <Grid item className={classes.fullWidth}>
                        <Tabs
                            variant={'fullWidth'}
                            className={classes.tabs}
                            value={tabValue}
                            onChange={handleChangeTab}
                        >
                            {isHasManageAdvancedSearchPermission && (
                                <Tab classes={tabClasses} value={ADVANCED_SEARCH_TAB.OWNER} label="My queries" />
                            )}
                            <Tab
                                classes={tabClasses}
                                value={ADVANCED_SEARCH_TAB.SHARED}
                                label={isHasManageAdvancedSearchPermission ? 'Shared' : 'Shared search queries'}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item className={classes.tabContent}>
                        {tabValue === ADVANCED_SEARCH_TAB.OWNER && isHasManageAdvancedSearchPermission && <Owner />}
                        {tabValue === ADVANCED_SEARCH_TAB.SHARED && <Shared />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

function Owner() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const advancedSearchList = useList();
    const isFetchingList = useIsFetchingList();

    const { workspaceId } = useParams();

    const isBlockedGrid = useIsBlockedGrid();
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        (async () => {
            dispatch(
                advancedSearchActions.getAdvancedSearchList({
                    wsId: workspaceId,
                    shared: false,
                    params: {
                        limit: 1000,
                        offset: 0
                    },
                    success: () => {},
                    error: () => {}
                })
            );
        })();
    }, [workspaceId, dispatch]);

    const data = React.useMemo(() => {
        return [...advancedSearchList]?.filter(adSearch => {
            let searchString = `${adSearch?.name}${adSearch?.author}${getFriendlyDate(adSearch?.createdTime)}`;
            return searchString?.toLowerCase()?.includes(search?.toLowerCase());
        });
    }, [advancedSearchList, search]);

    const renderList = React.useMemo(() => {
        const length = advancedSearchList?.length;

        if (isFetchingList)
            return (
                <Grid
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 100
                    }}
                    alignItems="center"
                    justifyContent="center"
                    container
                >
                    <Grid item>
                        <Spinner size={40} thick={4} />
                    </Grid>
                </Grid>
            );

        if (length > 0)
            return (
                <Grid style={{ width: '100%', height: '100%' }} container wrap="nowrap" direction="column">
                    {data?.map(item => {
                        return <AdvancedSearchSearchItem {...item} key={item?.advancedSearchId} />;
                    })}
                </Grid>
            );

        return (
            <Grid
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justifyContent="center"
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    <Typography align="center" variant="caption">
                        Create a new search and save it to <br />
                        Saved searches for future use.
                    </Typography>
                </Grid>
            </Grid>
        );
    }, [data, isFetchingList, advancedSearchList, t]);

    return (
        <Grid container direction="column" className={classes.search}>
            <Grid item className={`${classes.searchBox} ${classes.fullWidth}`}>
                <Search
                    onChange={e => setSearch(e?.target?.value)}
                    disabled={isBlockedGrid}
                    background={theme.palette.common.white}
                    placeholder="search..."
                />
            </Grid>
            <Grid item className={`${classes.table} ${classes.fullWidth} ${isBlockedGrid && classes.disabled}`}>
                {renderList}
            </Grid>
        </Grid>
    );
}

function Shared() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const advancedSearchList = useAdvancedSharedList();
    const isFetchingList = useIsFetchingList();
    const dispatch = useDispatch();
    const isBlockedGrid = useIsBlockedGrid();
    const [search, setSearch] = React.useState('');
    const { workspaceId } = useParams();

    const isHasManageAdvancedSearchPermission = useIsHasManageAdvancedSearchPermission();

    React.useEffect(() => {
        (async () => {
            dispatch(
                advancedSearchActions.getAdvancedSearchList({
                    wsId: workspaceId,
                    shared: true,
                    params: {
                        limit: 1000,
                        offset: 0
                    },
                    success: () => {},
                    error: () => {}
                })
            );
        })();
    }, [workspaceId, dispatch]);

    const data = React.useMemo(() => {
        return [...advancedSearchList]?.filter(adSearch => {
            let searchString = `${adSearch?.name}${adSearch?.author}${getFriendlyDate(adSearch?.createdTime)}`;
            return searchString?.toLowerCase()?.includes(search?.toLowerCase());
        });
    }, [advancedSearchList, search]);

    const renderList = React.useMemo(() => {
        const length = advancedSearchList?.length;

        if (isFetchingList)
            return (
                <Grid
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 100
                    }}
                    alignItems="center"
                    justifyContent="center"
                    container
                >
                    <Grid item>
                        <Spinner size={40} thick={4} />
                    </Grid>
                </Grid>
            );

        if (length > 0)
            return (
                <Grid style={{ width: '100%', height: '100%' }} container wrap="nowrap" direction="column">
                    {data?.map(item => {
                        return <AdvancedSearchSearchItem {...item} key={item?.advancedSearchId} isShared />;
                    })}
                </Grid>
            );

        if (!isHasManageAdvancedSearchPermission)
            return (
                <Grid style={{ width: '100%', marginTop: 32 }} alignItems="center" container direction="column">
                    <Grid item style={{ textAlign: 'center' }}>
                        <Typography align="center" variant="caption">
                            There is no search query <br /> Please ask the owner/admin of the
                            <br /> Company to assign you to a shared <br /> search query
                        </Typography>
                    </Grid>
                </Grid>
            );

        return (
            <Grid
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justifyContent="center"
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    <Typography align="center" variant="caption">
                        Create a new search and save it to <br />
                        Saved searches for future use.
                    </Typography>
                </Grid>
            </Grid>
        );
    }, [data, isFetchingList, advancedSearchList, isHasManageAdvancedSearchPermission, t]);

    return (
        <Grid container direction="column" className={classes.search}>
            <Grid item className={`${classes.searchBox} ${classes.fullWidth}`}>
                <Search
                    onChange={e => setSearch(e?.target?.value)}
                    disabled={isBlockedGrid}
                    background={theme.palette.common.white}
                    placeholder="search..."
                />
            </Grid>
            <Grid item className={`${classes.table} ${classes.fullWidth} ${isBlockedGrid && classes.disabled}`}>
                {renderList}
            </Grid>
        </Grid>
    );
}

export default SearchList;
