import React from 'react';
import classnames from 'classnames';
import { Dialog, DialogTitle, Typography } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import IconButton from 'components/button/IconButton';

function DialogBase({ onClose, children, title, showTitle = true, titleClassName, ...rest }) {
    return (
        <Dialog
            classes={{ paper: `relative min-w-[320px] !overflow-y-visible ${rest?.className}` }}
            onClose={onClose}
            {...rest}
        >
            <div
                className={classnames('flex flex-nowrap items-center justify-between', {
                    'p-5 pr-2.5': !!showTitle || !!onClose
                })}
            >
                {showTitle && (
                    <div className="flex-1">
                        <DialogTitle className={`!p-0 ${titleClassName}`}>
                            <Typography variant="h3">{typeof title === 'function' ? title() : title}</Typography>
                        </DialogTitle>
                    </div>
                )}
                {onClose && (
                    <div>
                        <IconButton onClick={onClose}>
                            <CloseIconSVG size="medium" />
                        </IconButton>
                    </div>
                )}
            </div>
            {children}
        </Dialog>
    );
}

export default React.memo(DialogBase);
