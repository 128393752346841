import React from 'react';
import DefaultSystemItemSVG from 'assets/images/svg/DefaultSystemItemSVG';
import { getSystemRoleColor } from 'utils/color';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.dimension || 12,
        height: props => props.dimension || 12,
        minWidth: props => props.dimension || 12,
        minHeight: props => props.dimension || 12
    }
}));

const SIZES = {
    small: {
        dimension: 12
    },
    medium: {
        dimension: 18
    },
    large: {
        dimension: 24
    }
};

function getSize(size) {
    if (typeof size === 'number') return { dimension: size };
    switch (size) {
        case 'small':
            return SIZES['small'];
        case 'medium':
            return SIZES['medium'];
        case 'large':
            return SIZES['large'];
        default:
            return SIZES['medium'];
    }
}

function SystemRoleFlag({ role, size = 'small' }) {
    const theme = useTheme();
    const { dimension } = getSize(size);
    const classes = useStyles({ dimension });

    const color = getSystemRoleColor({ role, theme });
    return <DefaultSystemItemSVG className={classes.root} color={color} />;
}

export default React.memo(SystemRoleFlag);
