import React from 'react';
import { Grid } from '@material-ui/core';
import DependencySVG from 'assets/images/svg/DependencySVG';
import TMStatusAprrovedSVG from 'assets/images/svg/localization/TMStatusAprrovedSVG';
import {
    getDependencyColorByStatus,
    getDependencyLabelByStatus,
    getSourceStatusLabelByStatus,
    getSourceStatusIcon
} from 'utils/gridUI/dependency';

function SubFieldRender({ subField, value, t, theme, classes }) {
    switch (subField) {
        case '_dependencyStatus':
            return (
                <Grid alignItems="center" wrap="nowrap" direction="row" spacing={2} container>
                    <Grid item style={{ display: 'flex' }}>
                        <DependencySVG color={getDependencyColorByStatus({ theme, status: value })} />
                    </Grid>
                    <Grid item>
                        <p className="body2">{getDependencyLabelByStatus({ status: value })}</p>
                    </Grid>
                </Grid>
            );
        case '_sourceStatus':
            return (
                <Grid wrap="nowrap" alignItems="center" direction="row" spacing={2} container>
                    <Grid item style={{ display: 'flex' }}>
                        {getSourceStatusIcon({ status: value, size: 14 })}
                    </Grid>
                    <Grid item className={classes.ellipsis}>
                        <p className="body2">{getSourceStatusLabelByStatus({ status: value })}</p>
                    </Grid>
                </Grid>
            );
        case '_tm':
            return (
                <Grid wrap="nowrap" alignItems="center" direction="row" spacing={2} container>
                    <Grid item style={{ display: 'flex' }}>
                        <TMStatusAprrovedSVG />
                    </Grid>
                    <Grid item>
                        <p className="body2">{t(`tm_label_filled`)}</p>
                    </Grid>
                </Grid>
            );
        default:
            return null;
    }
}

export default React.memo(SubFieldRender);
