import store from 'store/configStore';
import * as authActions from 'auth/actions';
import { fetchWorkspaces } from 'workspaces/actions';

const { dispatch } = store;

const userHandler = () => {
    dispatch(
        authActions.updateAuthoritiesSocket({
            errorCallback: () => {
                console.log('failed to updateAuthoritiesSocket');
            },
            successCallback: () => {
                console.log('updateAuthoritiesSocket successfully');
            }
        })
    );

    dispatch(fetchWorkspaces({}));
};

export default userHandler;
