import React, { useCallback, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentUserEmail } from 'hooks/auth';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import { requestDeleteAccount } from 'auth/actions';
import { enqueueSnackbar } from 'notifier/actions';

const DeleteAccountConfirm = ({ companyInfo, onClose }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentUserEmail = useCurrentUserEmail();
    const [requesting, setRequesting] = useState(false);

    const handleRequestDeleteAccount = useCallback(() => {
        setRequesting(true);
        dispatch(
            requestDeleteAccount({
                isResend: true,
                successCallback: data => {
                    setRequesting(false);
                    dispatch(
                        enqueueSnackbar({
                            duration: 1000000,
                            message: (
                                <div>
                                    <Trans
                                        i18nKey="delete_account_resend_success"
                                        components={{ b: <b /> }}
                                        values={{ email: data?.email }}
                                    />
                                </div>
                            ),
                            type: 'info'
                        })
                    );
                },
                errorCallback: () => {
                    setRequesting(false);
                }
            })
        );
    }, [dispatch]);

    return (
        <div>
            <Typography variant="body2" className="!text-[32px] !leading-9">
                {t(
                    companyInfo?.requireCSConfirm
                        ? 'delete_account_confirm_action_wait_cs'
                        : 'delete_account_confirm_action_deletion'
                )}
            </Typography>
            <div className="mt-10">
                <Trans
                    i18nKey={
                        companyInfo?.requireCSConfirm
                            ? 'delete_account_confirm_action_wait_cs_content'
                            : 'delete_account_confirm_action_deletion_content'
                    }
                    values={{ email: currentUserEmail }}
                    components={{
                        br: <div className="mt-8" />,
                        b: <span className="font-semibold" />,
                        hl: <span className="font-medium text-crusta" />
                    }}
                />
            </div>
            <div className="mt-8 flex flex-nowrap gap-8">
                <div className="flex-1">
                    <ButtonBase
                        className="!w-full"
                        variant={companyInfo?.requireCSConfirm ? 'contained' : 'outlined'}
                        onClick={onClose}
                    >
                        {t('goto_my_profile')}
                    </ButtonBase>
                </div>
                <div className="flex-1">
                    {!companyInfo?.requireCSConfirm && (
                        <ButtonBase
                            className="!w-full"
                            variant="contained"
                            disabled={requesting}
                            onClick={handleRequestDeleteAccount}
                        >
                            {t('resend_confirmation_email')}
                        </ButtonBase>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(DeleteAccountConfirm);
