import { useSelector } from 'react-redux';
import { getFullName } from 'utils/user';
import { USER_SETTINGS } from 'const';
import { useIsShareViewLink } from 'hooks/app';

export function useCurrentUser() {
    const isShareViewLink = useIsShareViewLink();
    return useSelector(state => {
        return isShareViewLink ? {} : state.auth.currentUser;
    });
}

export function useIsFetchingCurrentUser() {
    return useSelector(state => state.auth.isFetchingCurrentUser);
}

export function useCompanyId() {
    const companyId = useSelector(state => state.auth.companyId);
    const userCompanyId = useCurrentUser()?.companyId;
    return companyId || userCompanyId;
}

export function useCurrentUserRole() {
    return useCurrentUser()?.roles?.[0];
}

export function useCurrentUserId() {
    return useCurrentUser()?.id;
}

export function useCurrentUserUId() {
    return useCurrentUser()?.userId;
}

export function useCurrentUserLanguagePairs() {
    return useCurrentUser()?.languagePairs;
}

export function useCurrentUserEmail() {
    return useCurrentUser()?.email;
}

export function useCurrentUserFullInfo() {
    const user = useCurrentUser();
    return {
        ...user,
        fullName: getFullName(user)
    };
}

export function useUserSettings() {
    const user = useCurrentUser();
    return user?.setting;
}

export function useFillColorBlindness() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.BLINDNESS_FILL_COLOR];
}

export function useDependencyBlindness() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.BLINDNESS_DEPENDENCY];
}

export function useAddonOpen() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.OPEN_ADD_ON];
}

export function useIsFinishDashboardTutorial() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.FINISH_DASHBOARD_TUTORIAL];
}

export function useIsFinishGridTutorial() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.FINISH_GRID_TUTORIAL];
}

export function useTemplateViewTime() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS.TEMPLATE_VIEW_TIME];
}

export function useTrialBackViewTime() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS.TRIAL_BACK_VIEW_TIME];
}

export function useShownPromoteFeatures() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.SHOWN_PROMOTE_FEATURES] || [];
}

export function useNotificationCenterWithKey(key) {
    return useSelector(state => state.auth.notificationCenter[key]);
}

export function useNotificationCenterReadState() {
    const userSetting = useUserSettings();
    return userSetting?.[USER_SETTINGS?.NOTIFICATION_CENTER_READ_STATE];
}

export function useNotificationCenterPushes() {
    return useSelector(state => state.auth.notificationCenterPushes);
}

export function useDisplayNotificationRealtime() {
    const userSetting = useUserSettings();
    return typeof userSetting?.[USER_SETTINGS?.AUTH_DISPLAY_NOTIFICATION_REALTIME] === 'undefined'
        ? true
        : userSetting?.[USER_SETTINGS?.AUTH_DISPLAY_NOTIFICATION_REALTIME];
}
