import React from 'react';

const WhatNewSVG = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.7143 7.99633L13.9714 6.01062L14.2143 3.38205L11.6357 2.79633L10.2857 0.524902L7.85714 1.56776L5.42857 0.524902L4.07857 2.79633L1.5 3.3749L1.74286 6.00347L0 7.99633L1.74286 9.98204L1.5 12.6178L4.07857 13.2035L5.42857 15.4749L7.85714 14.4249L10.2857 15.4678L11.6357 13.1963L14.2143 12.6106L13.9714 9.98204L15.7143 7.99633ZM12.4929 9.50347L12.6786 11.4963L10.7214 11.9392L9.7 13.6606L7.85714 12.8678L6.01429 13.6606L4.99286 11.9392L3.03571 11.4963L3.22143 9.49633L1.9 7.99633L3.22143 6.48205L3.03571 4.49633L4.99286 4.06062L6.01429 2.33919L7.85714 3.1249L9.7 2.33205L10.7214 4.05347L12.6786 4.49633L12.4929 6.48919L13.8143 7.99633L12.4929 9.50347ZM7.14286 10.1392H8.57143V11.5678H7.14286V10.1392ZM7.14286 4.4249H8.57143V8.71062H7.14286V4.4249Z"
                fill="#F8AD13"
            />
        </svg>
    );
};

export default React.memo(WhatNewSVG);
