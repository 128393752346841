import DialogContent from '@material-ui/core/DialogContent';

import { withStyles } from '@material-ui/core/styles';

const DialogContentCustom = withStyles(theme => ({
    root: {
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    }
}))(DialogContent);

export default DialogContentCustom;
