import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { Divider, Grid, Link, Typography } from '@material-ui/core';
import { generateScratchCodesApi, getUserFactorApi } from 'services/company';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';
import { createAndDownloadFile } from 'utils/fileUtils';
import WarningSwitchingColumnSVG from 'assets/images/svg/WarningSwitchingColumnSVG';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'normal'
    },
    codeWrapper: {
        background: theme.colors.paleGrey,
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        padding: '4px 8px'
    },
    code: {
        fontFamily: 'courier !important'
    }
}));

function BackupCodeStep({ onFinish }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isClicked, setIsClicked] = React.useState(false);
    const [userFactor, setUserFactor] = React.useState({});

    React.useEffect(() => {
        const fetchFactorStatus = async () => {
            try {
                const factorStatus = await getUserFactorApi();
                setUserFactor(factorStatus);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchFactorStatus();
    }, []);

    const generateNewCode = async () => {
        try {
            const newFactorStatus = await generateScratchCodesApi();
            setUserFactor(newFactorStatus);
            setIsClicked(false);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleCopyToClipboard = () => {
        const stringToCopy = userFactor.scratchCodes.join('\n');
        setIsClicked(true);
        navigator.clipboard.writeText(stringToCopy);
        dispatch(
            enqueueSnackbar({
                message: t(`global_copied`),
                type: 'info'
            })
        );
    };

    const handleDownload = () => {
        const fileContent = userFactor.scratchCodes.join('\n');
        setIsClicked(true);
        createAndDownloadFile(fileContent, 'gridly-backup-codes.txt', 'text/plain');
    };

    return (
        <Grid item container direction="column">
            <Grid item style={{ marginTop: 50 }}>
                <Typography variant="h2" className={classes.title}>
                    {t('backup_verification_codes')}
                </Typography>
            </Grid>
            <Grid item style={{ marginTop: 14 }}>
                <Typography variant="body2" className={classes.title}>
                    {t('with_2fa_enabled_for_your_account')}
                </Typography>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 14 }}>
                {(userFactor.scratchCodes || []).map(code => (
                    <Grid key={code} item xs={4}>
                        <div className={classes.codeWrapper}>
                            <code className={classes.code}>{code}</code>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Divider style={{ marginTop: 14 }} />
            <Grid item container style={{ marginTop: 14 }} spacing={3}>
                <Grid item>
                    <Link style={{ cursor: 'pointer' }} onClick={generateNewCode}>
                        {t('generate_new_codes')}
                    </Link>
                </Grid>
                <Grid item style={{ flex: 1 }} />
                <Grid item>
                    <Link style={{ cursor: 'pointer' }} onClick={handleCopyToClipboard}>
                        {t('global_copy')}
                    </Link>
                </Grid>
                <Grid item>
                    <Link style={{ cursor: 'pointer' }} onClick={handleDownload}>
                        {t('global_download')}
                    </Link>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: 14 }} />
            <Grid item container style={{ marginTop: 32 }} wrap="nowrap" alignItems="flex-start">
                <Grid item container alignItems="center">
                    <Grid item style={{ display: 'flex' }}>
                        <WarningSwitchingColumnSVG />
                    </Grid>
                    <Grid item style={{ marginLeft: 4 }}>
                        <Typography variant="body2">{t('copy_or_download_codes_to_finish')}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <ButtonBase disabled={!isClicked} variant="contained" width={100} onClick={onFinish}>
                        {t('global_finish')}
                    </ButtonBase>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BackupCodeStep;
