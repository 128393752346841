import request from './request';
import { API_URL, APP_API_PREFIX_TB } from '../config';
import { getDownloadedFile } from 'utils/fileUtils';

const VERSION = 'v1';

const tbApis = {
    termBases: `${APP_API_PREFIX_TB}/${VERSION}/termbases`,
    termsParagraph: `${APP_API_PREFIX_TB}/${VERSION}/terms/searchInParagraph`,
    getTerm: ({ tbId, termId }) => `${APP_API_PREFIX_TB}/${VERSION}/termbases/${tbId}/terms/${termId}`,
    updateTerm: ({ tbId, termId }) => `${APP_API_PREFIX_TB}/${VERSION}/termbases/${tbId}/terms/${termId}`,
    tbAuditors: ({ tbId }) => `${APP_API_PREFIX_TB}/${VERSION}/termbases/${tbId}/auditors`
};

export async function getListTermsBase() {
    const response = await request.get(tbApis.termBases);
    return response.data;
}

export async function getTBApi(tbId) {
    const response = await request.get(`${tbApis.termBases}/${tbId}`);
    return response.data;
}

export async function getTermApi({ tbId, termId }) {
    const response = await request.get(tbApis.getTerm({ tbId, termId }));
    return response.data;
}

export async function createTermsBase(body) {
    const response = await request.post(tbApis.termBases, body);
    return response.data;
}

export async function updateTBApi({ tbId, body }) {
    const response = await request.put(`${tbApis.termBases}/${tbId}`, body);
    return response.data;
}

export async function deleteTBApi(tbId) {
    const response = await request.delete(`${tbApis.termBases}/${tbId}`);
    return response.data;
}

export async function createTermApi(tbId, body) {
    const response = await request.post(`${tbApis.termBases}/${tbId}/entries`, body);
    return response.data;
}

export async function updateTermApi(tbId, body) {
    const response = await request.post(`${tbApis.termBases}/${tbId}/entries`, body);
    return response.data;
}

export async function getTermsApi({ tbId, params, cancelToken }) {
    const response = await request.get(`${tbApis.termBases}/${tbId}/entries`, { params, cancelToken });
    return response;
}

export async function deleteTermsApi(tbId, body) {
    const response = await request.delete(`${tbApis.termBases}/${tbId}/entries`, { data: body });
    return response.data;
}

export async function exportTBApi(tbId, format, langCodes, includeAttributes) {
    let url = `${API_URL}${tbApis.termBases}/${tbId}/export?format=${format}&${langCodes}&${includeAttributes}`;
    getDownloadedFile(url);
    return false;
}

export async function importTBApi(tbId, file, importOption) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('importOption', importOption);
    const response = await request.post(`${tbApis.termBases}/${tbId}/import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function searchInParagraphApi(body) {
    const response = await request.post(tbApis.termsParagraph, body);
    return response.data;
}

export async function updateTermDetailApi({ tbId, termId, body }) {
    const response = await request.put(tbApis.getTerm({ tbId, termId }), body);
    return response.data;
}

export async function getTBAuditorsAPI({ tbId }) {
    const response = await request.get(tbApis.tbAuditors({ tbId }));
    return response.data;
}
