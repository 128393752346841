import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AddonBanner from 'assets/images/svg/addon/AddonBanner.jpg';
import AddonItem from './AddonItem';
import { useTranslation } from 'react-i18next';
import ButtonBase from 'components/button/Base';

const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        padding: theme.spacing(4)
    },
    banner: {
        position: 'relative',
        width: '100%'
    },
    description: {
        position: 'absolute',
        width: 321,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    descriptionText: {
        color: theme.colors.white
    },
    addonList: {
        marginTop: theme.spacing(3),
        overflow: 'auto'
    },
    bannerImage: {
        width: '100%'
    }
}));

function Overview({ addons, onAddonClick, onAddonRequest }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid item container direction="column" className={classes.content} wrap="nowrap">
            <Grid item className={classes.banner}>
                <img src={AddonBanner} className={classes.bannerImage} alt="addon-banner" />
                <Grid item container justify="center" className={classes.description}>
                    <Grid item>
                        <Typography variant="body2" align="center" className={classes.descriptionText}>
                            {t('addon_description')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 18 }}>
                        <ButtonBase variant="outlined" onClick={onAddonRequest}>
                            {t('suggest_an_addon')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.addonList} container spacing={3}>
                {addons?.map(addon => (
                    <Grid
                        key={addon.name}
                        xs={12}
                        sm={6}
                        md={6}
                        item
                        style={{ position: 'relative' }}
                        onClick={() => onAddonClick(addon)}
                        container
                        className={classes.addonItem}
                    >
                        <AddonItem addon={addon} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default React.memo(Overview);
