import React from 'react';

function ConnectorSVG({ color = 'white', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M15.3643 21.3623C12.2399 22.6756 8.61848 22.0434 6.11497 19.7401C8.22976 17.5001 6.65542 13.7705 3.54122 13.7705C1.58857 13.7705 0 15.3591 0 17.3117C0 19.7146 2.3584 21.4291 4.64645 20.6753C7.65033 23.6881 12.162 24.5442 16.0201 22.9225C16.4509 22.7414 16.6534 22.2453 16.4723 21.8145C16.2912 21.3836 15.7952 21.1812 15.3643 21.3623ZM1.69239 17.3117C1.69239 16.2923 2.52176 15.4629 3.54122 15.4629C4.56061 15.4629 5.39004 16.2923 5.39004 17.3117C5.39004 18.3311 4.56067 19.1605 3.54122 19.1605C2.52176 19.1605 1.69239 18.3311 1.69239 17.3117Z"
                fill={color}
            />
            <path
                d="M2.7471 10.884C3.18797 11.0443 3.67241 10.8155 3.8316 10.378C4.67436 8.06127 6.50113 6.18971 8.77803 5.2773C9.33673 6.49989 10.5703 7.35195 12 7.35195C13.9526 7.35195 15.5412 5.76338 15.5412 3.81074C15.5412 1.85815 13.9526 0.269531 12 0.269531C10.1233 0.269531 8.58378 1.73715 8.46674 3.58476C5.56932 4.63541 3.28692 6.92468 2.24113 9.79946C2.08141 10.2387 2.30793 10.7242 2.7471 10.884ZM12 1.96192C13.0194 1.96192 13.8488 2.79129 13.8488 3.81074C13.8488 4.83019 13.0194 5.65957 12 5.65957C10.9805 5.65957 10.1511 4.83019 10.1511 3.81074C10.1512 2.79129 10.9806 1.96192 12 1.96192Z"
                fill={color}
            />
            <path
                d="M22.3341 14.3098C22.3639 14.142 22.3816 13.6018 22.3816 13.3481C22.3816 10.4144 21.1315 7.60415 18.952 5.63781C18.605 5.32482 18.0699 5.35227 17.7569 5.69926C17.4438 6.04626 17.4713 6.58131 17.8183 6.89441C19.7271 8.61646 20.8069 11.1327 20.6782 13.7779C18.6212 13.6513 16.9175 15.2924 16.9175 17.3117C16.9175 19.2643 18.5061 20.8529 20.4587 20.8529C22.4114 20.8529 23.9999 19.2643 23.9999 17.3117C24 16.0474 23.3335 14.9365 22.3341 14.3098ZM20.4588 19.1605C19.4394 19.1605 18.6099 18.3311 18.6099 17.3116C18.6099 16.2922 19.4393 15.4628 20.4588 15.4628C21.4782 15.4628 22.3076 16.2922 22.3076 17.3116C22.3076 18.3311 21.4782 19.1605 20.4588 19.1605Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ConnectorSVG);
