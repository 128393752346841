import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PaymentContentLayout from '../common/PaymentContentLayout';
import AddCard from './AddCard';
import BillingInfo from './BillingInfo';
import EditBillingInfo from './EditBillingInfo';
import { useDispatch } from 'react-redux';
import SectionLayout from './SectionLayout';
import * as paymentActions from 'payment/actions';
import { useCardInfo, useBillingInfo } from 'hooks/payment';
import UserPaymentCard from 'payment/components/userCard';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function BillingDetails() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const card = useCardInfo();
    const billingInfo = useBillingInfo();

    const [isEditingCard, setIsEditingCard] = React.useState(false);
    const [isEditingBillingInfo, setIsEditingBillingInfo] = React.useState(false);

    const handleEditCard = () => setIsEditingCard(true);
    const handleShowCard = () => setIsEditingCard(false);

    const handleEditBillingInfo = () => setIsEditingBillingInfo(true);
    const handleShowBillingInfo = () => setIsEditingBillingInfo(false);

    useEffect(() => {
        dispatch(
            paymentActions.fetchBillingInfo({
                errorCallback: () => {
                    console.log('fetchBillingInfo failed');
                },
                successCallback: () => {
                    console.log('fetchBillingInfo success');
                }
            })
        );
    }, [dispatch]);

    useEffect(() => {
        if (isEmpty(billingInfo)) {
            setIsEditingBillingInfo(true);
        } else {
            setIsEditingBillingInfo(false);
        }
    }, [billingInfo]);

    return (
        <PaymentContentLayout title={t('billing_details')}>
            <Grid container direction="column" spacing={4} className={classes.root}>
                <Grid item>
                    <SectionLayout
                        title={t('payment_info')}
                        buttonText={t('global_change')}
                        onDoAction={handleEditCard}
                        isShowButton={!card || isEditingCard}
                        isShowSupportedCard={!card || isEditingCard}
                    >
                        {(!card || isEditingCard) && <AddCard onBack={handleShowCard} />}
                        {card && !isEditingCard && (
                            <UserPaymentCard
                                cardType={card?.brand?.toLocaleUpperCase()}
                                expireDate={`${
                                    card?.expMonth < 10 ? `0${card?.expMonth}` : card?.expMonth
                                }/${card?.expYear?.toString()?.slice(2)}`}
                                last4Number={card?.last4}
                            />
                        )}
                    </SectionLayout>
                </Grid>
                <Grid item>
                    <SectionLayout
                        title={t('billing_info')}
                        buttonText={t('edit')}
                        onDoAction={handleEditBillingInfo}
                        isShowButton={isEditingBillingInfo}
                    >
                        {!isEditingBillingInfo && <BillingInfo billingInfo={billingInfo} />}
                        {isEditingBillingInfo && (
                            <EditBillingInfo billingInfo={billingInfo} onBack={handleShowBillingInfo} />
                        )}
                    </SectionLayout>
                </Grid>
            </Grid>
        </PaymentContentLayout>
    );
}

export default React.memo(BillingDetails);
