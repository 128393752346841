import React, { useCallback, useEffect, useMemo } from 'react';
import ReactFlow, { Controls, useNodesState } from 'react-flow-renderer';
import TriggerNode from './nodes/TriggerNode';
import ActionNode from './nodes/ActionNode';
import AddNode from './nodes/AddNode';
import { useRef } from 'react';
import { AUTOMATION_NODE } from '../const';
import { getViewport } from 'gridUI/calculatePosition';
import { closePopperMenu } from 'components/menus/Popper';

const defaultX = 100;
const defaultY = 0;
const defaultNodeWidth = 440;
const defaultAddNodeWidth = 20;
const defaultAddNodeHeight = 20;
const defaultAddStepNodeWidth = 210;
const defaultConnectionLineHeight = 20;
const CONNECTION_PREFIX_ID = `connect-node-`;

const getFlowNodes = ({ nodes, heights = {} }) => {
    // x = upon x + (upon node width / 2) + (node width / 2), y = upon y + upon node height + connection line height
    let y = defaultY;
    return nodes.reduce((acc, node, idx) => {
        if (idx > 0) {
            y += defaultAddNodeHeight + defaultConnectionLineHeight;
        }
        acc.push({
            ...node,
            data: { ...node },
            position: { x: defaultX, y }
        });
        if (node?.data?.isCreate || !heights) {
            return acc;
        }
        const isNodeLast = idx === nodes.length - 1;
        const isTrigger = node.type === AUTOMATION_NODE.TRIGGER;
        let height = isTrigger ? heights.trigger : heights[node.id];
        y += height + defaultConnectionLineHeight;
        const id = `${CONNECTION_PREFIX_ID}${node.id}`;
        if (!isNodeLast) {
            acc.push({
                id,
                type: AUTOMATION_NODE.ADD,
                position: { x: defaultX + defaultNodeWidth / 2 - defaultAddNodeWidth / 2, y },
                data: {
                    parentId: node.type !== AUTOMATION_NODE.TRIGGER ? node.id : undefined,
                    setSelectedNode: node.setSelectedNode
                }
            });
        } else {
            acc.push({
                id,
                type: AUTOMATION_NODE.ADD_STEP,
                position: { x: defaultX - 1 + defaultNodeWidth / 2 - defaultAddStepNodeWidth / 2, y },
                data: {
                    parentId: node.type !== AUTOMATION_NODE.TRIGGER ? node.id : undefined,
                    setSelectedNode: node.setSelectedNode
                }
            });
        }
        return acc;
    }, []);
};

const Flow = ({ automationNodes = [], toggle, onCreateTrigger }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const reactFlowInstance = useRef();
    const timeout = useRef();
    const previousHeights = useRef();

    const edges = useMemo(() => {
        return nodes.length > 1
            ? nodes.reduce((acc, node, idx) => {
                  if (![AUTOMATION_NODE.ADD_STEP].includes(node.type)) {
                      acc.push({
                          id: `e${idx}-${idx + 1}`,
                          source: node.id,
                          target:
                              node.type === AUTOMATION_NODE.ADD
                                  ? nodes[idx + 1].id
                                  : `${CONNECTION_PREFIX_ID}${node.id}`
                      });
                  }
                  return acc;
              }, [])
            : [];
    }, [nodes]);

    const nodeTypes = useMemo(() => {
        return {
            [AUTOMATION_NODE.TRIGGER]: TriggerNode,
            [AUTOMATION_NODE.ACTION]: ActionNode,
            [AUTOMATION_NODE.ADD]: AddNode,
            [AUTOMATION_NODE.ADD_STEP]: AddNode
        };
    }, []);

    const onResetViewPort = useCallback(
        ({ centerOpts = {} }) => {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                const instance = reactFlowInstance.current;
                if (instance) {
                    const nodes = instance.getNodes();
                    if (nodes.length > 0) {
                        const node = nodes[0];
                        const zoom = 1;
                        const [viewPortWidth] = getViewport();
                        const vpw = toggle ? viewPortWidth / 2 : viewPortWidth;
                        instance.setViewport(
                            { x: (vpw - node.width - node.width / 2) / 2, y: 20, zoom },
                            { ...centerOpts }
                        );
                    }
                }
            }, 0);
        },
        [toggle]
    );

    const onInit = useCallback(
        instance => {
            reactFlowInstance.current = instance;
            onResetViewPort({});
        },
        [onResetViewPort]
    );

    useEffect(() => {
        if (automationNodes.length > 0) {
            const flowNodes = getFlowNodes({
                nodes: automationNodes,
                heights: previousHeights.current ? JSON.parse(previousHeights.current) : null
            });
            setNodes(flowNodes);
        } else {
            setNodes([
                {
                    id: '1',
                    type: 'trigger',
                    data: { isCreate: true, onCreateTrigger },
                    position: { x: defaultX, y: defaultY }
                }
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automationNodes, setNodes]);

    useEffect(() => {
        const heights = {};
        const aTriggerNodes = document.getElementById('automation-triggers-node');
        heights.trigger = aTriggerNodes?.offsetHeight;
        const actionNodes = nodes?.filter(node => node.type !== AUTOMATION_NODE.TRIGGER);
        const strNodes = actionNodes?.map(node => `#automation-action-node-${node.id}`).join(', ');
        if (strNodes) {
            const aActionNodes = document.querySelectorAll(
                actionNodes.map(node => `#automation-action-node-${node.id}`).join(', ')
            );
            if (aActionNodes?.length) {
                aActionNodes.forEach(node => {
                    const nodeId = node.id.replace('automation-action-node-', '');
                    heights[nodeId] = node.offsetHeight;
                });
            }
        }
        if (automationNodes.length > 0 && previousHeights.current !== JSON.stringify(heights)) {
            previousHeights.current = JSON.stringify(heights);
            if (automationNodes.length > 0) {
                const flowNodes = getFlowNodes({ nodes: automationNodes, heights });
                setNodes(flowNodes);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes]);

    useEffect(() => {
        if (reactFlowInstance.current) {
            onResetViewPort({ centerOpts: { duration: 800 } });
        }
    }, [onResetViewPort]);

    if (!nodes?.length) return null;

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            zoomOnScroll={false}
            zoomOnDoubleClick={false}
            zoomOnPinch={false}
            panOnScroll
            onNodesChange={onNodesChange}
            onInit={onInit}
            onPaneClick={() => {
                console.log('pane click');
                closePopperMenu && closePopperMenu();
            }}
        >
            <Controls showInteractive={false} />
        </ReactFlow>
    );
};

export default React.memo(Flow);
