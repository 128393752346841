import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import { Grid, Popper, Paper } from '@material-ui/core';
import {
    INPUT_PADDING_LEFT,
    INPUT_QUICK_FILTER_HEIGHT,
    INPUT_RADIUS,
    DISABLED_OPACITY,
    OPTION_SPACING
} from 'const/style';
import { getNodesByPath } from 'utils/gridUI/pathTag';
import FolderPath from '../PathFolder';
import Option from 'components/option/Base';
import { PATH_TAG } from 'const/columnTypes';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { OPERATOR } from 'gridUI/conditions';
import { DEFAULT_PATH_TAG_COLOR } from 'utils/color';
import isEmpty from 'lodash/isEmpty';
import Tooltip from 'components/tooltip/Base';
import { _readClipboard } from 'utils/clipboard';
import { enqueueSnackbar } from 'notifier/actions';

const useStyles = makeStyles(theme => ({
    zIndex: {
        zIndex: theme.zIndex.modal + 200
    },
    selection: {
        fontSize: `0.8125rem !important`,
        color: theme.colors.primaryText,
        background: theme.colors.paleGrey,
        height: INPUT_QUICK_FILTER_HEIGHT,
        overflow: 'hidden',
        paddingLeft: INPUT_PADDING_LEFT,
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: INPUT_RADIUS,
        border: `none`,
        position: 'relative'
    },
    focus: {
        borderBottom: `1px solid ${theme.palette.primary.main} !important`
    },
    paper: {
        minWidth: 200,
        padding: 10
    },
    chip: {
        height: 22,
        maxHeight: 22,
        '& svg': {
            marginLeft: 0
        }
    },
    placeholder: {
        color: theme.colors.midGrey,
        fontSize: 13,
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    option: { background: '#E8F4F8 !important' },
    splitor: {
        marginRight: OPTION_SPACING,
        marginLeft: OPTION_SPACING
    },
    closeIcon: {
        position: 'absolute',
        right: 2,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY,
        cursor: 'not-allowed'
    },
    wrapperPathTag: {
        ...theme.ellipsis(1)
    }
}));

function PathTagQuickFilter({
    column,
    columnId,
    isSelectionActive,
    quickFilters,
    onQuickFilterChange,
    onRefreshGrid,
    pathRef,
    isDisabled,
    tree,
    t,
    ...rest
}) {
    const classes = useStyles();
    const [selectedPath, setSelectedPath] = useState(quickFilters?.[columnId]?.value || '');
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const ref = React.useRef();

    const data = React.useMemo(() => {
        return getNodesByPath({ pathRef, path: quickFilters?.[columnId]?.value });
    }, [columnId, quickFilters, pathRef]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleOnChange = newPath => {
        if (newPath === quickFilters?.[columnId]?.value) {
            handleClickAway();
            return;
        }

        setSelectedPath(newPath);

        onQuickFilterChange &&
            onQuickFilterChange({
                columnId,
                value: newPath,
                type: PATH_TAG,
                operator: OPERATOR.startsWith
            });

        onRefreshGrid && onRefreshGrid();
        handleClickAway();
    };

    const handleClearFilter = e => {
        stopPropagation(e);

        onQuickFilterChange &&
            onQuickFilterChange({
                columnId,
                value: '',
                type: PATH_TAG,
                operator: OPERATOR.startsWith
            });
        setSelectedPath();
        onRefreshGrid && onRefreshGrid();
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const renderPathtag = useCallback(
        (isTooltip = false) => {
            return data?.map((node, index) => {
                return (
                    <React.Fragment key={node?.id}>
                        <Option
                            className={isTooltip ? classes.option : ''}
                            size="small"
                            label={node?.name}
                            color={node?.customProperties?.color || DEFAULT_PATH_TAG_COLOR}
                        />
                        {index !== data?.length - 1 && (
                            <p className={'body2 mx-1 inline'} style={isTooltip ? { color: 'white' } : {}}>
                                /
                            </p>
                        )}
                    </React.Fragment>
                );
            });
        },
        [classes, data]
    );

    const pasteHandler = React.useCallback(
        async e => {
            let res = await _readClipboard(e);
            const status = res.status;
            if (!status) {
                console.log('pasted failed');
                return;
            }

            const content = res.content;
            const path = content?.split('/');
            const nodes = getNodesByPath({ pathRef, path: content });
            const validNodes = [];
            let failedMessage = ``;

            for (const [key, node] of nodes.entries()) {
                if (!node) {
                    failedMessage = `Can not found "${path?.slice(key)?.join('/')}"`;
                    break;
                }
                validNodes.push(node);
            }

            if (failedMessage) {
                dispatch(
                    enqueueSnackbar({
                        message: failedMessage,
                        type: 'info'
                    })
                );
            }

            const validPath = validNodes?.map(node => node?.name)?.join('/');
            if (validPath) {
                setSelectedPath(validPath);
            }
        },
        [pathRef, dispatch]
    );

    React.useEffect(() => {
        if (anchorEl) {
            window.addEventListener('paste', pasteHandler, true);
        }

        return () => {
            window.removeEventListener('paste', pasteHandler, true);
        };
    }, [pasteHandler, dispatch, anchorEl]);

    return (
        <Grid className={classes.dateInput}>
            <div
                ref={ref}
                onClick={handleClick}
                className={`${classes.selection} ${anchorEl ? classes.focus : ''} ${
                    isDisabled ? classes.disabled : ``
                }`}
            >
                {isEmpty(data) && <span className={classes.placeholder}>Select path</span>}
                {!isEmpty(data) && (
                    <Tooltip title={<Grid item>{renderPathtag(true)}</Grid>}>
                        <Grid item className={classes.wrapperPathTag}>
                            {renderPathtag()}
                        </Grid>
                    </Tooltip>
                )}
                {data?.length > 0 && (
                    <span className={classes.closeIcon} onClick={handleClearFilter}>
                        <CloseIconSVG />
                    </span>
                )}
            </div>
            <Popper className={classes.zIndex} id={'path-tag-filter'} open={open} anchorEl={anchorEl} transition>
                <Paper className={classes.paper}>
                    <FolderPath
                        handleClickAway={handleClickAway}
                        defaultPath={selectedPath}
                        onChange={handleOnChange}
                        tree={tree}
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(PathTagQuickFilter);
