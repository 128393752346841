import React from 'react';

function ActivateMemberSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.4125 3.45C4.43219 3.45 3.6375 4.24469 3.6375 5.225C3.6375 6.20531 4.43219 7 5.4125 7C6.39281 7 7.1875 6.20531 7.1875 5.225C7.1875 4.24469 6.39281 3.45 5.4125 3.45ZM2.1875 5.225C2.1875 3.44388 3.63138 2 5.4125 2C7.19362 2 8.6375 3.44388 8.6375 5.225C8.6375 7.00612 7.19362 8.45 5.4125 8.45C3.63138 8.45 2.1875 7.00612 2.1875 5.225ZM0.944581 10.4446C1.54939 9.83978 2.36968 9.5 3.225 9.5H7.6C8.45532 9.5 9.27562 9.83978 9.88042 10.4446C10.4852 11.0494 10.825 11.8697 10.825 12.725V13.975C10.825 14.3754 10.5004 14.7 10.1 14.7C9.69959 14.7 9.375 14.3754 9.375 13.975V12.725C9.375 12.2542 9.18799 11.8028 8.85511 11.4699C8.52224 11.137 8.07076 10.95 7.6 10.95H3.225C2.75424 10.95 2.30276 11.137 1.96989 11.4699C1.63701 11.8028 1.45 12.2542 1.45 12.725V13.975C1.45 14.3754 1.12541 14.7 0.725 14.7C0.324594 14.7 0 14.3754 0 13.975V12.725C0 11.8697 0.339776 11.0494 0.944581 10.4446ZM14.9877 6.98765C15.2708 6.70452 15.2708 6.24548 14.9877 5.96235C14.7045 5.67922 14.2455 5.67922 13.9623 5.96235L11.975 7.9497L11.2377 7.21235C10.9545 6.92922 10.4955 6.92922 10.2123 7.21235C9.92922 7.49548 9.92922 7.95452 10.2123 8.23765L11.4623 9.48765C11.7455 9.77078 12.2045 9.77078 12.4877 9.48765L14.9877 6.98765Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ActivateMemberSVG);
