import React, { useState } from 'react';
import isEqual from 'lodash/isEqual';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getLabelColumnType, TRANSLATION_TYPES } from 'gridUI/column-types';
import SvgIcon from 'components/svg-icon/Base';
import * as gridActions from 'gridUI/actions';
import InputText from 'components/inputs/InputText';
import ColumnTypesSelect from 'gridUI/common/ColumnTypeSelect';
import { Grid, Collapse, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import ExtraOptionsByType from './ExtraOptionsByType';
import Dialog from 'components/dialog/Dialog';
import trim from 'lodash/trim';
import * as columnTypes from 'const/columnTypes';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import { SWITCHING_COLUMN_MAPPING } from './switchColumnMapping';
import ConfirmBox from 'components/confirmBox/Base';
import {
    formatOptions,
    formatGridReferenceId,
    formatColumnReferenceId,
    getCorrectColumnType,
    formatDefaultValue,
    convertServerDefaultValue,
    formatBranchReferenceId
} from 'utils/gridUI/formatData';
import Divider from 'components/divider/Base';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import { NUMBER_TYPES, REFERENCE_SELECTION_TYPES, FILE_UPLOAD_TYPES } from 'const/gridUI';
import { isLDEmpty } from 'utils/object';
import { getCalcViewAggregationsApi } from 'services/aggregation';
import { AGGREGATIONS_VALUES, AGGREGATIONS_DISABLED_COLUMNS } from 'const';
import { getFormulaServerValue, getSlateDataFromFormula } from 'utils/gridUI/formula';
import { validateFormulaApi } from 'services/grid';
import CountryFlag from 'components/svg-icon/CountryFlag';
import DefaultValue from './defaultValue';
import { sendManualTrack } from 'tracker';
import { setDefaultValueNewColumn } from 'utils/gridUI/column';
import { SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH } from 'const/languageData';
import { useTranslation, Trans } from 'react-i18next';
import { useMetaData, useColumnsWithData, useViewColumnWidthMetadata } from 'hooks/gridUI';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LDBasePortalGroup from 'components/selects/LDBasePortalGroup';
import { getServerTimeZoneValue, getTimezoneObj, LIST_TIME_ZONES } from 'const/time';

const useStyles = makeStyles(theme => ({
    root: {
        width: 325,
        background: theme.colors.white
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    dpFlex: {
        display: 'flex',
        cursor: 'pointer'
    },
    showMore: {
        color: theme.colors.dodgerBlue
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    setting: {
        paddingLeft: 20,
        paddingRight: 20
    },
    actions: {
        paddingLeft: 20,
        paddingRight: 20
    },
    exampleValues: {
        background: theme.colors.ghostwhite,
        width: '100%',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        borderRadius: 4,
        maxHeight: 200,
        overflowY: 'auto'
    },
    bullet: {
        display: 'inline-block',
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: theme.colors.steel,
        marginRight: 8,
        verticalAlign: '10%'
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    split: {
        height: 40,
        borderTop: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        marginBottom: theme.spacing(3),
        paddingLeft: 20,
        paddingRight: 20
    },
    spt3: {
        marginTop: theme.spacing(3),
        maxWidth: 260
    },
    scroll: {
        maxHeight: `calc(100vh - 250px)`,
        overflow: 'hidden auto'
    }
}));

function isNeedExtraOption(type) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.BOOLEAN:
        case columnTypes.DATETIME:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
            return false;
        default:
            return true;
    }
}

function isNeedDefaultValue(type) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.BOOLEAN:
        case columnTypes.DATETIME:
        case columnTypes.NUMBER:
        case columnTypes.SINGLE_SELECTION:
        case columnTypes.MULTIPLE_SELECTIONS:
            return true;
        default:
            return false;
    }
}

function isOldOptionChange({ oldOptions = [], newOptions }) {
    let oldOptIds = oldOptions.map(opt => opt.id);
    let arrs = [];
    newOptions.forEach(newOpt => {
        if (oldOptIds.includes(newOpt.id)) {
            arrs.push(newOpt);
        }
    });
    return !isEqual({ options: oldOptions }, { options: arrs });
}

function isHasOptionEmptyName(options = []) {
    let isHasEmpty = false;
    for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (!option.label) {
            isHasEmpty = true;
            break;
        }
    }
    return isHasEmpty;
}

const WARNING_TYPES = {
    WARNING: 'WARNING',
    LOST: 'LOST'
};

function ColumnFormat({ column, onClickAway, onClose, isClickAwayColumnFormat }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const metaData = useMetaData();
    const dispatch = useDispatch();
    const columns = useColumnsWithData();
    const viewColumns = useViewColumnWidthMetadata();

    const { workspaceId, gridId, branchId, viewId, dbId } = useParams();

    const name = React.useMemo(() => {
        return column?.name;
    }, [column]);

    const publicId = React.useMemo(() => {
        return column?.publicId;
    }, [column]);

    const [columnName, setColumnName] = useState(name);
    const [openSwitchingModal, setOpenSwitchingModal] = React.useState(false);
    const [warningType, setWarningType] = React.useState(WARNING_TYPES.WARNING);
    const [isShowExtra, setIsShowExtra] = React.useState(true);
    const [isShowFormat, setIsShowFormat] = React.useState(true);
    const [publicIdName, setPublicIdName] = React.useState(publicId);
    const [isLoading, setIsLoading] = useState(false);
    const [formularError, setFormulaError] = React.useState('');
    const [timezone, setTimezone] = React.useState(getTimezoneObj(''));
    const [openConfirmSave, setOpenConfirmSave] = React.useState(false);

    const columnId = React.useMemo(() => {
        return column?.id;
    }, [column]);

    const isNew = React.useMemo(() => {
        return column?.isNew;
    }, [column]);

    const columnType = React.useMemo(() => column?.type, [column]);

    const isNotValidPublicId = React.useMemo(() => {
        if (!publicIdName) return true;
        const publicIds = columns?.filter(col => col?.id !== columnId)?.map(col => col?.publicId);
        return publicIds?.includes(publicIdName) && publicIdName !== publicId;
    }, [publicIdName, columnId, columns, publicId]);

    const openSwitchingModalHandler = () => {
        setOpenSwitchingModal(true);
    };

    const closeSwitchingModalHandler = () => {
        setOpenSwitchingModal(false);
    };

    const [dataType, setDataType] = useState({
        value: columnType,
        label: getLabelColumnType(columnType),
        icon: () => <SvgIcon type={columnType} />
    });

    const switchingMapping = React.useMemo(() => {
        return isNew ? [] : SWITCHING_COLUMN_MAPPING?.[columnType];
    }, [isNew, columnType]);

    const LOST_COLUMN_TYPES = React.useMemo(() => {
        return switchingMapping?.LOST_COLUMN_TYPES || [];
    }, [switchingMapping]);

    const WARNING_COLUMN_TYPES = React.useMemo(() => {
        return switchingMapping?.WARNING_COLUMN_TYPES || [];
    }, [switchingMapping]);

    const [extraOptions, setExtraOptions] = useState(formatOptions(column.options));
    const [customPropsOptions, setCustomPropsOptions] = useState(column?.customProperties?.options || []);

    const [referencedGridId, setReferencedGridId] = useState(formatGridReferenceId({ column }));
    const [referencedBranchId, setReferencedBranchId] = useState(formatBranchReferenceId({ column }));
    const [referencedColumnId, setReferencedColumnId] = useState(formatColumnReferenceId({ column }));
    const [referenceType, setReferenceType] = useState(column?.referenceType || 'row');
    const [referenceSelectionType, setReferenceSelectionType] = useState(
        column?.referenceSelectionType || REFERENCE_SELECTION_TYPES.MULTIPLE
    );
    const [primaryReference, setPrimaryReference] = useState(
        typeof column?.referenceSettings?.primaryReference === 'boolean' && column?.type === columnTypes.REFERENCE
            ? column?.referenceSettings?.primaryReference
            : true
    );
    const [fileUploadType, setFileUploadType] = useState(column?.file?.selectionType || FILE_UPLOAD_TYPES.MULTIPLE);

    const [gridTMId, setGridTMId] = useState(formatGridReferenceId({ column }));
    const [columnTMId, setColumnTMId] = useState(formatColumnReferenceId({ column }));
    const [description, setDescription] = useState(column?.description);
    const [defaultValue, setDefaultValue] = useState(formatDefaultValue({ column }));

    const [formula, setFormula] = React.useState(
        column?.formula?.formulaText
            ? getSlateDataFromFormula({ string: column?.formula?.formulaText, metaData })
            : [{ type: 'paragraph', children: [{ text: '' }] }]
    );

    const [translationData, setTranslationData] = useState(
        column?.group ? { type: column?.customProperties?.localizationType, value: column.group } : {}
    );

    const [singleResultValue, setSingleResultValue] = useState(
        column?.formula?.singleResultValue ? REFERENCE_SELECTION_TYPES.SINGLE : REFERENCE_SELECTION_TYPES.MULTIPLE
    );

    const [numberFormat, setNumberFormat] = React.useState(
        !column?.numberFormat
            ? { type: NUMBER_TYPES?.DECIMAL, decimalPlaces: 0, use1000Separator: false }
            : column?.numberFormat
    );

    React.useEffect(() => {
        if (column) {
            const columnType = getCorrectColumnType(column);
            setColumnName(column?.name);
            setDataType({
                value: columnType,
                label: getLabelColumnType(columnType),
                icon: () => <SvgIcon type={columnType} />
            });
            setPublicIdName(column?.publicId);
            setExtraOptions(formatOptions(column.options));
            setCustomPropsOptions(column?.customProperties?.options || []);
            setReferencedGridId(formatGridReferenceId({ column }));
            setReferencedBranchId(formatBranchReferenceId({ column }));
            setReferencedColumnId(formatColumnReferenceId({ column }));
            setGridTMId(formatGridReferenceId({ column }));
            setColumnTMId(formatColumnReferenceId({ column }));
            setTranslationData(
                column?.group ? { type: column?.customProperties?.localizationType, value: column.group } : {}
            );
            setDescription(column?.description);
            setDefaultValue(formatDefaultValue({ column }));
            setFormula(
                column?.formula?.formulaText
                    ? getSlateDataFromFormula({ string: column?.formula?.formulaText, metaData })
                    : [{ type: 'paragraph', children: [{ text: '' }] }]
            );
        }
    }, [column, metaData]);

    const handleColumnTypeChange = React.useCallback(
        option => {
            setDataType(option);

            if (option?.value === columnTypes.TRANSLATION && columnType !== columnTypes.TRANSLATION) {
                const language = SUPPORTED_LANGUAGES_WITH_FUZZY_MATCH?.find(lang =>
                    lang?.fuzzy?.map(l => l?.toLowerCase())?.includes(columnName?.toLowerCase())
                );

                if (language) {
                    setTranslationData({
                        ...translationData,
                        value: language?.id,
                        type: TRANSLATION_TYPES.TARGET_LANG
                    });
                }
            }
        },
        [columnType, columnName, translationData]
    );

    const newColumnType = React.useMemo(() => {
        const isLocalizationType = dataType.value === columnTypes.LOCALIZATION;

        const getType = () => {
            if (isLocalizationType) {
                return columnTypes.REFERENCE;
            } else {
                return dataType.value;
            }
        };
        return getType();
    }, [dataType]);

    const newColumn = React.useMemo(() => {
        const isLocalizationType = dataType.value === columnTypes.LOCALIZATION;
        const isTranslation = dataType.value === columnTypes.TRANSLATION;
        const isNumber = dataType.value === columnTypes.NUMBER;
        const isFormula = dataType.value === columnTypes.FORMULA;

        delete column?.reference;
        delete column?.referenceSelectionType;
        delete column?.referenceType;
        delete column?.primaryReference;

        let newColumn = {
            ...column,
            type: newColumnType,
            name: columnName,
            options: extraOptions,
            referenceSettings: {
                ...column?.referenceSettings,
                referencedGridId,
                referencedBranchId,
                referencedColumnId,
                referenceType,
                primaryReference,
                referenceSelectionType
            },
            group: translationData.value,
            publicId: publicIdName,
            description: description,
            defaultValue: isLDEmpty(defaultValue)
                ? null
                : convertServerDefaultValue({
                      defaultValue,
                      columnType: newColumnType,
                      options: extraOptions
                  }),
            file:
                newColumnType === columnTypes.FILES
                    ? {
                          selectionType: fileUploadType
                      }
                    : undefined
        };

        const getNewCustomOptionsProps = () => {
            const obj = {};
            for (const option of extraOptions) {
                const id = option?.id;
                obj[id] = customPropsOptions?.[id];
            }
            return obj;
        };
        const customPropsOptionsFormat = getNewCustomOptionsProps();

        const generateColumnData = () => {
            if (isLocalizationType) {
                return {
                    ...newColumn,
                    customProperties: { options: customPropsOptionsFormat, subType: columnTypes.LOCALIZATION }
                };
            } else if (isTranslation) {
                return {
                    ...newColumn,
                    customProperties: { localizationType: translationData.type }
                };
            } else if (isNumber) {
                return {
                    ...newColumn,
                    numberFormat
                };
            } else if (isFormula) {
                return {
                    ...newColumn,
                    formula: {
                        ...newColumn?.formula,
                        formulaText: getFormulaServerValue(formula),
                        singleResultValue: singleResultValue === REFERENCE_SELECTION_TYPES.SINGLE
                    }
                };
            } else {
                return {
                    ...newColumn,
                    customProperties: { options: customPropsOptionsFormat }
                };
            }
        };

        return generateColumnData();
    }, [
        singleResultValue,
        defaultValue,
        description,
        formula,
        numberFormat,
        column,
        columnName,
        referencedColumnId,
        columnTMId,
        dataType,
        customPropsOptions,
        extraOptions,
        referencedGridId,
        gridTMId,
        publicIdName,
        translationData,
        newColumnType,
        referenceType,
        referenceSelectionType,
        primaryReference,
        fileUploadType
    ]);

    const POSSIBLE_CONVERT_VALUES = React.useMemo(() => {
        const POSSIBLE_CONVERT_VALUES = switchingMapping?.POSSIBLE_CONVERT_VALUES;
        return POSSIBLE_CONVERT_VALUES?.[newColumnType] || POSSIBLE_CONVERT_VALUES?.DEFAULT;
    }, [switchingMapping, newColumnType]);

    const isDirty = React.useMemo(() => {
        let dirty = false;

        let properties = [...Object.keys(column), 'defaultValue', 'description']?.filter(
            key => !['numberFormat', 'primaryReference']?.includes(key)
        );

        const isSameType = column?.type === newColumn?.type;

        if (!isSameType) {
            if (newColumn?.type === columnTypes.NUMBER) {
                properties = properties?.filter(key => !['numberFormat']?.includes(key));
            } else if (newColumn?.type === columnTypes.REFERENCE) {
                properties = properties?.filter(key => !['primaryReference']?.includes(key));
            }
        }

        for (const key of properties) {
            const previousValue = column?.[key];
            const nextValue = newColumn?.[key];

            if (
                (!isLDEmpty(previousValue) || !isLDEmpty(nextValue)) &&
                JSON.stringify(previousValue) !== JSON.stringify(nextValue)
            ) {
                console.log('key', key);
                console.log('previousValue', previousValue);
                console.log('nextValue', nextValue);
                dirty = true;
                break;
            }
        }

        return dirty;
    }, [newColumn, column]);

    React.useEffect(() => {
        if (isClickAwayColumnFormat) {
            if (isDirty) {
                setOpenConfirmSave(true);
            } else {
                onClose && onClose();
            }
        }
    }, [isClickAwayColumnFormat, isDirty, onClose]);

    const checkFormula = React.useCallback(async () => {
        try {
            await validateFormulaApi({
                gridId: branchId,
                dbId,
                body: {
                    formulaText: getFormulaServerValue(formula)
                }
            });
            setFormulaError('');
            return '';
        } catch (error) {
            setFormulaError(error.originalMessage);
            return error.originalMessage;
        }
    }, [formula, dbId, branchId]);

    const handleUpdate = React.useCallback(
        async e => {
            const isFormula = dataType.value === columnTypes.FORMULA;

            if (isFormula) {
                const formulaError = await checkFormula();
                if (formulaError) return;
            }
            if (!trim(columnName)) {
                onClickAway && onClickAway(e);
                return;
            }

            if (isNotValidPublicId) {
                return;
            }

            const isSameType = column.type === newColumnType;

            const isFetchingToUpdateOptionChange =
                isSameType &&
                isOldOptionChange({
                    oldOptions: column.options,
                    newOptions: extraOptions
                }) &&
                [columnTypes.SINGLE_SELECTION, columnTypes.MULTIPLE_SELECTIONS].includes(newColumnType);

            const isReFetchingRef = isSameType && newColumnType === columnTypes.REFERENCE;

            const isNotSupportAggregation = AGGREGATIONS_DISABLED_COLUMNS?.includes(newColumnType);

            dispatch(
                gridActions.updateGridColumn({
                    oldColumn: column,
                    newColumn: {
                        ...newColumn,
                        defaultValue: setDefaultValueNewColumn(column, newColumn),
                        isNew: false,
                        convertingOption:
                            newColumnType === columnTypes.DATETIME
                                ? {
                                      timezone: getServerTimeZoneValue(timezone.value)
                                  }
                                : undefined
                    },
                    isFetchingToUpdateOptionChange,
                    isReFetchingRef,
                    isReFetchingAggregation: !isSameType && !isNotSupportAggregation,
                    errorCallback: () => {
                        console.log('failed to update column');
                    },
                    successCallback: () => {
                        sendManualTrack({ type: `Save column format`, customData: newColumn });
                        onClose && onClose(e);
                    }
                })
            );
        },
        [
            onClickAway,
            onClose,
            column,
            columnName,
            newColumn,
            newColumnType,
            dispatch,
            extraOptions,
            isNotValidPublicId,
            checkFormula,
            dataType,
            timezone
        ]
    );

    const checkColumnData = React.useCallback(
        async e => {
            if ([...WARNING_COLUMN_TYPES, ...LOST_COLUMN_TYPES].includes(dataType?.value)) {
                setIsLoading(true);

                const isNotSupportAggregation = AGGREGATIONS_DISABLED_COLUMNS?.includes(getCorrectColumnType(column));

                try {
                    if (isNotSupportAggregation) {
                        if (LOST_COLUMN_TYPES?.includes(dataType?.value)) {
                            setWarningType(WARNING_TYPES.LOST);
                        } else {
                            setWarningType(WARNING_TYPES.WARNING);
                        }
                        setIsLoading(false);
                        return openSwitchingModalHandler();
                    } else {
                        const filledAggregations = await getCalcViewAggregationsApi({
                            dbId,
                            viewId,
                            columnIds: [column?.id],
                            aggregateTypes: AGGREGATIONS_VALUES.filled
                        });
                        if (filledAggregations?.[0]?.result > 0) {
                            if (LOST_COLUMN_TYPES?.includes(dataType?.value)) {
                                setWarningType(WARNING_TYPES.LOST);
                            } else {
                                setWarningType(WARNING_TYPES.WARNING);
                            }
                            setIsLoading(false);
                            return openSwitchingModalHandler();
                        }
                    }
                } catch (error) {
                    if (LOST_COLUMN_TYPES?.includes(dataType?.value)) {
                        setWarningType(WARNING_TYPES.LOST);
                    } else {
                        setWarningType(WARNING_TYPES.WARNING);
                    }
                    setIsLoading(false);
                    return openSwitchingModalHandler();
                }
            }
            handleUpdate();
        },
        [LOST_COLUMN_TYPES, WARNING_COLUMN_TYPES, dataType, handleUpdate, column, dbId, viewId]
    );

    const isValidByType = React.useCallback(
        columnType => {
            if (columnType === columnTypes.SINGLE_SELECTION || columnType === columnTypes.MULTIPLE_SELECTIONS) {
                if (extraOptions.length === 0) return true;
                return extraOptions.length !== 0 && !isHasOptionEmptyName(extraOptions);
            }
            if (columnType === columnTypes.TRANSLATION) {
                return translationData.type && translationData.value;
            }
            if (columnType === columnTypes.REFERENCE) {
                return referencedColumnId && referencedGridId && referencedBranchId;
            }

            if (columnType === columnTypes.NUMBER) {
                return !isLDEmpty(numberFormat);
            }

            if (columnType === columnTypes.FORMULA) {
                const children = formula?.[0]?.children;
                return children?.length > 0;
            }

            if (columnType === columnTypes.FILES) {
                return true;
            }
        },
        [extraOptions, translationData, referencedColumnId, referencedGridId, referencedBranchId, numberFormat, formula]
    );

    const isValid = React.useMemo(() => {
        const isNeedExtra = isNeedExtraOption(dataType.value);
        if (!isNeedExtra) return columnName;

        return columnName && isValidByType(dataType.value);
    }, [columnName, isValidByType, dataType.value]);

    const isColumnNameAlreadyExisted = React.useMemo(() => {
        const columnNames = columns?.filter(column => column?.id !== columnId)?.map(column => column?.name);
        return (
            columnNames?.filter(name => name?.trim()?.toLowerCase() === columnName?.trim()?.toLowerCase())?.length > 0
        );
    }, [columns, columnName, columnId]);

    const isNotValid = React.useMemo(() => {
        return !isValid || isNotValidPublicId || isColumnNameAlreadyExisted;
    }, [isValid, isNotValidPublicId, isColumnNameAlreadyExisted]);

    const handleOnKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                onClickAway && onClickAway(e);
                return;
            }
            if (isKbEnter(e) && !isNotValid) {
                checkColumnData(e);
                return;
            }
            return;
        },
        [checkColumnData, onClickAway, isNotValid]
    );

    const handlePublicOnKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                onClickAway && onClickAway(e);
                return;
            }
            if (isKbEnter(e) && !isColumnNameAlreadyExisted) {
                handleUpdate(e);
            }
        },
        [onClickAway, isColumnNameAlreadyExisted, handleUpdate]
    );

    const handleAddMoreOption = ({ id, label, color }) => {
        setExtraOptions([...extraOptions, { id, label }]);
        const newCustomPropsOptions = { ...customPropsOptions };
        newCustomPropsOptions[id] = { color };
        setCustomPropsOptions(newCustomPropsOptions);
    };

    const onReorderOptionChange = React.useCallback(newOptions => {
        setExtraOptions(newOptions);
    }, []);

    const handleRemoveOption = optionId => {
        // const index = extraOptions.findIndex(option => option.id === optionId);
        const newCustomPropsOptions = { ...customPropsOptions };
        setExtraOptions(extraOptions.filter(option => option.id !== optionId));

        if (newCustomPropsOptions.options) {
            delete newCustomPropsOptions.options[optionId];
        }
        setCustomPropsOptions(newCustomPropsOptions);
    };

    const handleUpdateOption = ({ optionId, label, color }) => {
        let newOptions = [...extraOptions];
        const newCustomPropsOptions = { ...customPropsOptions };
        const index = newOptions.findIndex(option => option.id === optionId);
        if (index > -1) {
            newOptions[index].label = label;
        }
        newCustomPropsOptions[optionId] = color ? { color } : ``;

        setExtraOptions(newOptions);
        setCustomPropsOptions(newCustomPropsOptions);
    };

    const referenceDataChangeHandler = ({
        referencedBranchId,
        referencedGridId,
        referencedColumnId,
        referenceType,
        referenceSelectionType,
        primaryReference
    }) => {
        setReferencedColumnId(referencedColumnId);
        setReferencedGridId(referencedGridId);
        setReferencedBranchId(referencedBranchId);
        setReferenceType(referenceType);
        setReferenceSelectionType(referenceSelectionType);
        setPrimaryReference(primaryReference);
    };

    const onSingleResultValueChange = React.useCallback(e => {
        const value = e?.target?.value;
        setSingleResultValue(value);
    }, []);

    const fileUploadTypeChangeHandler = ({ uploadType }) => {
        setFileUploadType(uploadType);
    };

    const tmDataChangeHandler = ({ gridTMId, columnTMId }) => {
        setColumnTMId(columnTMId);
        setGridTMId(gridTMId);
    };

    const translationDataChangeHandler = updatedTranslationData => {
        const newData = { ...translationData, ...updatedTranslationData };
        setTranslationData(newData);
    };

    const handleNumberFormatChange = format => {
        setNumberFormat(format);
    };

    const handlePublicIdKeyChange = e => {
        setPublicIdName(e.target?.value?.replace(/[^A-Z_0-9]/gi, ''));
    };

    const onFormulaChange = React.useCallback(value => {
        setFormula(value);
    }, []);

    const handleDefaultValueChange = React.useCallback(value => {
        setDefaultValue(value);
    }, []);

    const handleDescriptionChange = React.useCallback(e => {
        setDescription(e.target?.value);
    }, []);

    const columnSuggestions = React.useMemo(() => {
        return viewColumns
            ?.filter(column => !!column.viewable)
            ?.filter(column => column.type !== columnTypes.PATH_TAG)
            .map(column => {
                const columnType = column?.type;
                const isLangColumn = columnType === columnTypes.TRANSLATION;
                return {
                    type: 'column',
                    label: column?.name,
                    value: `col_val_${column?.id}`,
                    columnType: columnType,
                    group: column?.group,
                    icon: () =>
                        isLangColumn ? (
                            <CountryFlag languageCountryCode={column?.group} />
                        ) : (
                            <SvgIcon type={columnType} />
                        )
                };
            });
    }, [viewColumns]);

    const handleTimezoneTypeChange = React.useCallback(option => {
        setTimezone(option);
    }, []);

    const closeSaveChangesConfirm = React.useCallback(() => {
        setOpenConfirmSave(false);
    }, []);

    const isNotValidToSave = React.useMemo(() => {
        return isNotValid || (dataType?.value === columnTypes.FORMULA && Boolean(formularError));
    }, [isNotValid, formularError, dataType]);

    return (
        <>
            <Grid container direction="column" className={`${classes.root} columnFormat`}>
                <Grid item className={classes.scroll}>
                    <Grid container direction="column">
                        <Grid item container direction="column" className={classes.setting}>
                            <Grid item className={classes.spacing}>
                                <InputText
                                    name="columnName"
                                    label={t('column_format_column_name_label')}
                                    value={columnName}
                                    onChange={e => setColumnName(e.target.value)}
                                    onKeyDown={handleOnKeyDown}
                                    placeholder="column name"
                                    autoFocus
                                    autoSelect={true}
                                    error={isColumnNameAlreadyExisted}
                                    errorText={t(`column_format_column_name_warning`)}
                                />
                            </Grid>
                            <Grid item className={classes.spacing}>
                                <Grid container direction="column">
                                    <Grid item style={{ marginBottom: theme.spacing(1) }}>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item>
                                                <p className="body1">{t('column_format_data_type')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className="columnDataType">
                                        <ColumnTypesSelect
                                            defaultValue={dataType}
                                            handleOptionChange={handleColumnTypeChange}
                                            LOST_COLUMN_TYPES={LOST_COLUMN_TYPES}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isNeedExtraOption(dataType.value) && ![columnTypes.NUMBER]?.includes(newColumnType) && (
                                <Grid item className={classes.spacing}>
                                    <ExtraOptionsByType
                                        type={dataType.value}
                                        options={extraOptions}
                                        customPropsOptions={customPropsOptions}
                                        handleAddMoreOption={handleAddMoreOption}
                                        handleRemoveOption={handleRemoveOption}
                                        handleUpdateOption={handleUpdateOption}
                                        onReorderOptionChange={onReorderOptionChange}
                                        referenceType={referenceType}
                                        referenceSelectionType={referenceSelectionType}
                                        primaryReference={primaryReference}
                                        columnId={columnId}
                                        referenceDataChangeHandler={referenceDataChangeHandler}
                                        referenceData={{
                                            referencedGridId,
                                            referencedColumnId,
                                            referencedBranchId
                                        }}
                                        tmDataChangeHandler={tmDataChangeHandler}
                                        tmData={{
                                            gridTMId,
                                            columnTMId
                                        }}
                                        translationData={translationData}
                                        translationDataChangeHandler={translationDataChangeHandler}
                                        workspaceId={workspaceId}
                                        gridId={gridId}
                                        viewId={viewId}
                                        dbId={dbId}
                                        branchId={branchId}
                                        t={t}
                                        numberFormat={numberFormat}
                                        onNumberFormatChange={handleNumberFormatChange}
                                        onFormulaChange={onFormulaChange}
                                        formula={formula}
                                        metaData={metaData}
                                        columnSuggestions={columnSuggestions}
                                        formularError={formularError}
                                        onBlurFormula={checkFormula}
                                        singleResultValue={singleResultValue}
                                        onSingleResultValueChange={onSingleResultValueChange}
                                        fileUploadType={fileUploadType}
                                        fileUploadTypeChangeHandler={fileUploadTypeChangeHandler}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {isNeedExtraOption(dataType.value) && [columnTypes.NUMBER]?.includes(newColumnType) && (
                            <Grid item onClick={() => setIsShowFormat(!isShowFormat)}>
                                <Grid
                                    container
                                    direction="row"
                                    className={classes.split}
                                    alignItems="center"
                                    justify="space-between"
                                    style={{ background: isShowFormat ? theme.colors.ghostwhite : theme.colors.white }}
                                >
                                    <Grid item>
                                        <p className="body1">Format</p>
                                    </Grid>
                                    <Grid item className={classes.dpFlex}>
                                        {isShowFormat ? <ArrowUpSVG /> : <ArrowDownSVG />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Collapse
                            in={isShowFormat && [columnTypes.NUMBER]?.includes(newColumnType)}
                            timeout="auto"
                            unmountOnExit
                        >
                            {isNeedExtraOption(dataType.value) && (
                                <Grid container direction="column" className={classes.setting}>
                                    <Grid item className={classes.spacing}>
                                        <ExtraOptionsByType
                                            type={dataType.value}
                                            options={extraOptions}
                                            customPropsOptions={customPropsOptions}
                                            handleAddMoreOption={handleAddMoreOption}
                                            handleRemoveOption={handleRemoveOption}
                                            handleUpdateOption={handleUpdateOption}
                                            referenceType={referenceType}
                                            referenceSelectionType={referenceSelectionType}
                                            primaryReference={primaryReference}
                                            columnId={columnId}
                                            referenceDataChangeHandler={referenceDataChangeHandler}
                                            referenceData={{
                                                referencedGridId,
                                                referencedColumnId,
                                                referencedBranchId
                                            }}
                                            tmDataChangeHandler={tmDataChangeHandler}
                                            tmData={{
                                                gridTMId,
                                                columnTMId
                                            }}
                                            translationData={translationData}
                                            translationDataChangeHandler={translationDataChangeHandler}
                                            workspaceId={workspaceId}
                                            gridId={gridId}
                                            viewId={viewId}
                                            dbId={dbId}
                                            branchId={branchId}
                                            t={t}
                                            numberFormat={numberFormat}
                                            onNumberFormatChange={handleNumberFormatChange}
                                            onFormulaChange={onFormulaChange}
                                            formula={formula}
                                            metaData={metaData}
                                            columnSuggestions={columnSuggestions}
                                            formularError={formularError}
                                            onBlurFormula={checkFormula}
                                            singleResultValue={singleResultValue}
                                            onSingleResultValueChange={onSingleResultValueChange}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Collapse>

                        <Grid item onClick={() => setIsShowExtra(!isShowExtra)}>
                            <Grid
                                container
                                direction="row"
                                className={classes.split}
                                alignItems="center"
                                justify="space-between"
                                style={{ background: isShowExtra ? theme.colors.ghostwhite : theme.colors.white }}
                            >
                                <Grid item>
                                    <p className="body1">{t('column_options')}</p>
                                </Grid>
                                <Grid item className={classes.dpFlex}>
                                    {isShowExtra ? <ArrowUpSVG /> : <ArrowDownSVG />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Collapse in={isShowExtra} timeout="auto" unmountOnExit>
                            <Grid container direction="column" className={classes.setting}>
                                {isNeedDefaultValue(dataType?.value) && (
                                    <Grid item className={classes.spacing}>
                                        <DefaultValue
                                            type={dataType?.value}
                                            defaultValue={defaultValue}
                                            extraOptions={extraOptions}
                                            customPropsOptions={customPropsOptions}
                                            onChange={handleDefaultValueChange}
                                        />
                                    </Grid>
                                )}
                                <Grid item className={classes.spacing}>
                                    <InputText
                                        name="description"
                                        label={
                                            <>
                                                <p className="body2 inline">{t('column_description_label')}</p>{' '}
                                                <p className="caption !font-normal inline-block">
                                                    ({t('global_optional')})
                                                </p>
                                            </>
                                        }
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        placeholder={t('column_description_placeholder')}
                                    />
                                </Grid>
                                <Grid item className={classes.spacing}>
                                    <InputText
                                        name="publicId"
                                        label={t('column_format_column_id_label')}
                                        value={publicIdName}
                                        onChange={handlePublicIdKeyChange}
                                        onKeyDown={handlePublicOnKeyDown}
                                        placeholder="Public column id"
                                        error={isNotValidPublicId}
                                        errorText={'Duplicate public column id'}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>

                <Grid item className={classes.divider}>
                    <Divider />
                </Grid>
                <Grid item className={classes.actions}>
                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <Button fullWidth variant="outlined" size="small" onClick={onClose}>
                                {t('global_cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6} className={classes.buttonWrapper}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                onClick={checkColumnData}
                                disabled={isNotValidToSave || isLoading}
                            >
                                {t('global_save')}
                            </Button>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                style={{ zIndex: 1301 }}
                id={'ConfirmSwitchingBox'}
                open={openSwitchingModal}
                onClose={closeSwitchingModalHandler}
            >
                <ConfirmBox
                    width={500}
                    title={t('column_format_convert_data')}
                    body={
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <p className="body2 inline">{t('column_format_what_happens')}</p>{' '}
                                <p className="body1 inline">{columnTypes.getColumnLabel(column?.type)}</p>{' '}
                                <p className="body2 inline">to</p>{' '}
                                <p className="body1 inline">{columnTypes.getColumnLabel(newColumnType)}?</p>
                            </Grid>
                            <Grid item container direction="column" spacing={1}>
                                <Grid item>
                                    <div className={classes.bullet} />
                                    <p className="body2 inline">{t('column_format_cannot_undo')}</p>
                                </Grid>
                                {warningType === WARNING_TYPES.LOST && (
                                    <Grid item>
                                        <div item className={classes.bullet} />
                                        <Trans i18nKey={'switch_column_lost_data_message'} t={t}>
                                            <p className="body2 inline">This action will</p>
                                            <p className="inline-block text-error">clear all existing data</p>
                                            <p className="body2 inline">on the column.</p>
                                        </Trans>
                                    </Grid>
                                )}
                                {warningType === WARNING_TYPES.WARNING && (
                                    <>
                                        <Grid item>
                                            <div item className={classes.bullet} />
                                            <Trans i18nKey={'switch_column_warning_data_message'} t={t}>
                                                <p className="body2 inline">This action may</p>
                                                <p className="body2 inline text-error">
                                                    clear some or all existing data
                                                </p>
                                                <p className="body2 inline">on the column.</p>
                                                <p className="body2 mt-3.5">Data format below can be converted:</p>
                                            </Trans>
                                        </Grid>

                                        <Grid item>
                                            <Grid container direction="column" className={classes.exampleValues}>
                                                {POSSIBLE_CONVERT_VALUES?.map(value => {
                                                    return (
                                                        <Grid item key={value}>
                                                            <p className="body2" style={{ fontStyle: 'italic' }}>
                                                                {`${value?.slice(0, 1)?.toUpperCase()}${value?.slice(
                                                                    1
                                                                )}`}
                                                            </p>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>

                                        {newColumnType === columnTypes.DATETIME && (
                                            <Grid item className={classes.spt3}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <p className="body1">{t('global_time_zone')}</p>
                                                    </Grid>
                                                    <Grid item>
                                                        <LDBasePortalGroup
                                                            ddPlaceholder={t('column_format_time_zone_select')}
                                                            menuPlaceholder={t('column_format_time_zone_search')}
                                                            options={LIST_TIME_ZONES}
                                                            onChange={handleTimezoneTypeChange}
                                                            defaultValue={timezone}
                                                            isMulti={false}
                                                            isUsingContainer={false}
                                                            popperId="popper-timezone"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    }
                    handleCancel={closeSwitchingModalHandler}
                    onClose={closeSwitchingModalHandler}
                    handleAgreed={e => {
                        closeSwitchingModalHandler();
                        handleUpdate(e);
                    }}
                    agreeLabel="Convert"
                />
            </Dialog>

            <Dialog
                style={{ zIndex: 1301 }}
                id={'ConfirmSwitchingBox'}
                open={openConfirmSave}
                onClose={() => setOpenConfirmSave(false)}
            >
                <ConfirmBox
                    width={500}
                    title={
                        isNotValidToSave
                            ? `Unable to convert to new column format`
                            : 'Changes detected in column properties'
                    }
                    body={
                        isNotValidToSave
                            ? `Unable to convert this column to new format. Please complete all requirement fields to continue.`
                            : 'Your current work is not complete. Do you want to save it?'
                    }
                    onClose={closeSaveChangesConfirm}
                    handleAgreed={e => {
                        handleUpdate(e);
                    }}
                    agreeLabel="Save"
                    action={
                        <Grid wrap="nowrap" container direction="row" alignItems="center" justify="space-between">
                            {!isNotValidToSave && (
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            setOpenConfirmSave(false);
                                            onClose && onClose();
                                        }}
                                        width={120}
                                        variant="outlined"
                                    >
                                        Don't save
                                    </Button>
                                </Grid>
                            )}

                            <Grid item container direction="row" spacing={2} alignItems="center" justify="flex-end">
                                <Grid item>
                                    <Button
                                        onClick={closeSaveChangesConfirm}
                                        width={120}
                                        variant={isNotValidToSave ? `contained` : `outlined`}
                                    >
                                        {isNotValidToSave ? `Ok` : t('global_cancel')}
                                    </Button>
                                </Grid>
                                {!isNotValidToSave && (
                                    <Grid item>
                                        <div className={classes.buttonWrapper}>
                                            <Button
                                                onClick={e => {
                                                    closeSaveChangesConfirm(e);
                                                    checkColumnData(e);
                                                }}
                                                width={120}
                                                disabled={isLoading}
                                                variant="contained"
                                            >
                                                Save
                                            </Button>
                                            {isLoading && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    }
                />
            </Dialog>
        </>
    );
}

export default React.memo(ColumnFormat);
