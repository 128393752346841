import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SortOrderList from './SortOrderList';
import AddSortOrderButton from './AddSortOrderButton';
import { LIST_MENU_MAX_HEIGHT } from 'const/style';
import Divider from 'components/divider/Base';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import scrollIntoView from 'scroll-into-view-if-needed';

const useStyles = makeStyles(theme => ({
    root: {
        width: 390
    },
    buttonAdd: {
        padding: `0px 20px`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        width: 'fit-content'
    },
    sortList: {
        padding: `0px 20px`,
        maxHeight: `calc(${LIST_MENU_MAX_HEIGHT} - ${16 + 54}px)`,
        overflowY: 'auto'
    },
    whiteText: {
        color: theme.colors.white
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    }
}));

function SortMenu({ t }) {
    const classes = useStyles();

    const onCreated = () => {
        const node = document.getElementById('sortListBottom');

        scrollIntoView(node, {
            behavior: 'smooth',
            block: 'end',
            duration: 300,
            boundary: document.getElementById('sortListBoundary')
        });
    };

    return (
        <Grid id="view-sort-trigger" container wrap="nowrap" direction="column" className={classes.root}>
            <Grid item>
                <Grid container direction="column" className={classes.sortList} id={'sortListBoundary'} wrap="nowrap">
                    <SortOrderList t={t} />
                    <div id="sortListBottom"></div>
                </Grid>
            </Grid>
            <Grid>
                <Divider />
            </Grid>
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_SORT}>
                {({ isReadOnly }) => (
                    <Tooltip
                        title={
                            isReadOnly ? (
                                <Grid container className={classes.permissionTooltip}>
                                    {t('toolbar_no_permission')}
                                </Grid>
                            ) : (
                                ''
                            )
                        }
                    >
                        <Grid item className={classes.buttonAdd}>
                            <AddSortOrderButton t={t} onCreated={onCreated} isReadOnly={isReadOnly} />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
        </Grid>
    );
}

export default React.memo(SortMenu);
