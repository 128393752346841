import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ShowSpecialCharacterSVG from 'assets/images/svg/ShowSpecialCharacterSVG';
import Tooltip from 'components/tooltip/Base';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    icon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        background: theme.colors.solitude,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}));

const ShowSpecialCharacter = ({ showSpecialCharacter, onClick, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Tooltip title={t('show_special_characters')}>
            <Grid
                item
                onClick={onClick}
                className={classes.icon}
                style={{ background: !showSpecialCharacter ? theme.colors.white : `` }}
                {...rest}
            >
                <ShowSpecialCharacterSVG
                    color={showSpecialCharacter ? theme.palette.primary?.main : undefined}
                    bgColor={showSpecialCharacter ? theme.colors.solitude : 'white'}
                />
            </Grid>
        </Tooltip>
    );
};

export default React.memo(ShowSpecialCharacter);
