import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Checkbox from 'components/checkbox/Base';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    optionList: {
        display: 'flex',
        flexWrap: 'wrap'
    },

    item: {
        background: hexToRgba(theme.colors.dodgerBlue, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        borderRadius: 6,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 2,
        paddingBottom: 2
    }
}));

function RefBoolean({ value: cellValue }) {
    const classes = useStyles();

    if (isEmpty(cellValue)) return null;
    const getJsonValue = cellValue => {
        if (isArray(cellValue)) {
            let values = cellValue.map(i => i.referencedDataItem);
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(cellValue);
    if (!isArray(newData) || !newData) return null;

    return (
        <Grid
            container
            className={`${classes.container}`}
            justify="flex-start"
            alignItems="flex-start"
            style={{
                pointerEvents: 'none'
            }}
            wrap="nowrap"
            spacing={1}
        >
            {newData?.map((item, index) => {
                const checked = item ? true : false;
                return (
                    <Grid item key={item}>
                        <Checkbox
                            key={index}
                            checked={checked}
                            className={classes.checkbox}
                            style={{
                                maxWidth: 24,
                                maxHeight: 24
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(RefBoolean);
