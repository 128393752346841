import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Collapse } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import ButtonBase from 'components/button/Base';
import { AutoSizer } from 'react-virtualized-dn';
import { usePoTargetLanguage, usePOMessages, usePOResourceKey, usePOMessageKeys } from 'hooks/gridUI/import';
import { useDispatch } from 'react-redux';
import * as importActions from 'gridUI/actions/import';
import * as gridActions from '../actions';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { sendManualTrack } from 'tracker';
import PathSVG from 'assets/images/svg/localization/PathSVG';
// import { MaxNumberInAr } from 'utils/object';
import { LOCALIZATION_TYPES } from 'const';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { getFileName } from 'utils/fileUtils';
import { useTranslation } from 'react-i18next';
import LDBasePortalVirtualize from 'components/selects/virtualize/LDBasePortalVirtualize';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import produce from 'immer';
import chunk from 'lodash/chunk';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import Search from 'components/search';
import { List } from 'react-virtualized-dn';
import NoDiffCheck from 'assets/images/svg/versionControl/NoDiffCheck';
import ListSelect from 'components/list/Select';
import Tooltip from 'components/tooltip/Base';
import FileSVG from 'assets/images/svg/FileSVG';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import hexToRgba from 'hex-to-rgba';
import { generateCode } from 'utils/language';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import CountryFlag from 'components/svg-icon/CountryFlag';
import Spinner from 'components/spinner/Base';

const ITEMS_PER_ROW = 4;

const useStyles = makeStyles(theme => ({
    root: {
        width: 740,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'initial',
            width: '100%',
            height: '100%'
        }
    },
    fullWidth: {
        width: '100%'
    },

    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },

    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    box: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        overflowX: 'auto'
    },
    w170: {
        minWidth: 170
    },
    split: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },

    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    search: {
        width: 240
    },
    targetList: {
        padding: theme.spacing(3),
        height: 152,
        overflowY: 'auto',
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`
    },

    spacing: {
        marginBottom: theme.spacing(3)
    },
    spacing4: {
        marginBottom: theme.spacing(4)
    },
    treeBox: {
        height: 320,
        flex: 1,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        position: 'relative'
    },
    treeBoxHeader: {
        flexBasis: 40,
        background: theme.colors.ghostwhite,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    treeBoxContent: {
        flex: 1,
        overflow: 'auto',
        position: 'relative',
        padding: theme.spacing(3)
    },
    messageItem: {},
    messageItemHeader: {
        padding: `4px 0px`
    },
    messageItemContent: {
        padding: `4px 0px`
    },
    addMoreSource: {
        height: 36,
        cursor: 'pointer',
        minWidth: 170,
        border: `1px dashed ${theme.colors.border}`,
        borderRadius: 4,
        '& p': {
            color: theme.colors.lightLavender
        },
        '& svg': {
            marginRight: theme.spacing(2)
        },
        '&:hover': {
            background: theme.colors.solitude
        }
    },
    dlFlex: {
        display: 'flex'
    },
    langSelect: {
        maxWidth: 300
    },
    rowItem: {
        minWidth: 160
    },
    spb3: {
        marginBottom: theme.spacing(3)
    },
    virtualizeList: {
        outline: 'none'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    highlight: {
        color: theme.colors.lightLavender,
        cursor: 'pointer'
    },

    fileIcon: {
        display: 'flex',
        marginRight: theme.spacing(1)
    },
    fileName: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    showPreview: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8),
        cursor: 'pointer'
    },
    pr2: {
        paddingRight: theme.spacing(2)
    }
}));

function LocalizationImportPOSetup({ onClose, isMulti, dbId, files }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [isUploading, setIsUploading] = React.useState(false);
    const [isShowPreview, setIsShowPreview] = React.useState(false);
    const [messageKey, setMessageKey] = React.useState(null);

    const isTypeMultiple = LOCALIZATION_TYPES.SINGLE;

    const [name, setName] = React.useState(getFileName(files?.[0]?.name) || '');
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const langOptions = useLanguageOptionsWithUsageValidate();

    const isModeSingle = React.useMemo(() => {
        return isTypeMultiple === LOCALIZATION_TYPES.MULTIPLE ? false : true;
    }, [isTypeMultiple]);
    const [filterValue, setFilterValue] = React.useState('');

    const [sources, setSources] = React.useState(isModeSingle ? [] : [null]);
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});

    const targetOptions = React.useMemo(() => {
        return langOptions.filter(option => {
            return option?.label?.toLowerCase()?.includes(filterValue?.toLowerCase());
        });
    }, [langOptions, filterValue]);

    const poTargetLanguage = usePoTargetLanguage(dbId);
    const resourceKey = usePOResourceKey(dbId);
    const messages = usePOMessages(dbId);
    const messageKeys = usePOMessageKeys(dbId);

    const headerCode = React.useMemo(() => {
        return generateCode(poTargetLanguage);
    }, [poTargetLanguage]);

    const messageKeyOptions = React.useMemo(() => {
        return messageKeys?.map(key => ({
            label: key,
            value: key
        }));
    }, [messageKeys]);

    const fileTarget = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[headerCode];
    }, [headerCode]);

    const handleAddSource = React.useCallback(() => {
        setSources([...sources, null]);
    }, [sources]);

    const handleMessageKeyChange = React.useCallback(option => {
        setMessageKey(option);
    }, []);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions[targetValue] || false;

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    React.useEffect(() => {
        if (files) {
            setIsUploading(true);
            dispatch(
                importActions.importLocalizationGridReadPOFile({
                    dbId,
                    file: files,
                    successCallback: ({ poTargetLanguage }) => {
                        const code = generateCode(poTargetLanguage);
                        const target = LANGUAGE_DETAIL_BY_CODE?.[code];

                        if (target) {
                            const targetValue = target.id;

                            setSelectedTargetOptions({
                                [targetValue]: true
                            });
                        }

                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                    }
                })
            );
        }
    }, [dispatch, dbId, files]);

    React.useEffect(() => {
        if (isTypeMultiple === LOCALIZATION_TYPES.SINGLE) {
            dispatch(importActions.clearOriginalLocalizationColumn());
        }
    }, [isTypeMultiple, dispatch]);

    const handleSourceChange = React.useCallback(option => {
        setSources([option]);
        // const newSelectedTargetOptions = { ...selectedTargetOptions };
        // if (newSelectedTargetOptions[option?.value]) {
        //     delete newSelectedTargetOptions[option?.value];
        // }
        // setSelectedTargetOptions(newSelectedTargetOptions);
    }, []);

    const handleMultiSourceChange = React.useCallback(
        ({ option, index }) => {
            let updateSources = produce(sources, draft => {
                draft[index] = option;
            });
            setSources(updateSources);
            const newSelectedTargetOptions = { ...selectedTargetOptions };
            if (newSelectedTargetOptions[option?.value]) {
                delete newSelectedTargetOptions[option?.value];
            }
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [sources, selectedTargetOptions]
    );

    const handleMultiSourceRemove = React.useCallback(
        index => {
            let sourcesClone = [...sources];
            sourcesClone.splice(index, 1);
            setSources(sourcesClone);
        },
        [sources]
    );

    const targetOptionChunks = React.useMemo(() => {
        return chunk(targetOptions, ITEMS_PER_ROW);
    }, [targetOptions]);

    const validSelectedTargetOptions = React.useMemo(() => {
        const validSelected = [];
        Object.keys(selectedTargetOptions).forEach(key => {
            if (selectedTargetOptions[key]) {
                const validOption = langOptions.find(option => option.value === key);
                if (validOption) {
                    validSelected.push(validOption);
                }
            }
        });
        return validSelected;
    }, [selectedTargetOptions, langOptions]);

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item>
                    <NoDiffCheck />
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t('global_empty_language_msg')}</Typography>
                </Grid>
            </Grid>
        );
    }, [t]);

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style }) => {
            const items = targetOptionChunks?.[index];
            return (
                <Grid style={{ ...style }} key={key} container alignItems="center" direction="row" wrap="nowrap">
                    {items?.map(option => {
                        const isSelected = selectedTargetOptions?.[option?.value];

                        if (option?.isDisabled) {
                            return (
                                <Grid key={option?.value} item xs={3}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                        return (
                            <Tooltip title={option.tooltip} key={option.value}>
                                <Grid item xs={3}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            );
        },
        [handleTargetSelect, targetOptionChunks, selectedTargetOptions, classes]
    );

    const isCreateValid = React.useMemo(() => {
        const isValidName = !!name;
        const isValidSourceRequest = sources?.filter(source => !!source)?.length;
        const isValidTargetLanguages = validSelectedTargetOptions.length;

        return isValidName && isValidSourceRequest && isValidTargetLanguages;
    }, [name, sources, validSelectedTargetOptions]);

    const handleCreateLocalizationGrid = React.useCallback(() => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Add Import Localization Grid From JSON file` });

        let body = {
            name,
            type: isModeSingle ? 'Single' : 'Multiple',
            description: t('localization_grid_default_description'),
            targetLanguages: validSelectedTargetOptions.map(option => option?.value),
            key: resourceKey,
            poTargetLanguage: headerCode
        };

        if (messageKey) {
            body = {
                ...body,
                messageKey: messageKey?.value
            };
        }

        if (isModeSingle || sources?.length === 1) {
            body = {
                ...body,
                type: LOCALIZATION_TYPES.SINGLE,
                sourceLanguage: sources?.[0]?.value
            };
        } else {
            body = {
                ...body,
                type: LOCALIZATION_TYPES.MULTIPLE,
                sourceLanguages: sources?.filter(source => !!source)?.map(source => source?.value)
            };
        }

        dispatch(
            gridActions.createJsonLocalizationGrid({
                dbId,
                body,
                query: {
                    type: 'po'
                },
                successCallback: newGrid => {
                    console.log('create localization grid success');
                    setIsSubmitting(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('failed to create localization');
                }
            })
        );
    }, [
        t,
        dbId,
        dispatch,
        onClose,
        isModeSingle,
        name,
        sources,
        validSelectedTargetOptions,
        resourceKey,
        headerCode,
        messageKey
    ]);

    const fileName = React.useMemo(() => {
        return files?.[0]?.name;
    }, [files]);

    const contentRender = React.useCallback(() => {
        return (
            <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                <Grid item container alignItems="center" direction="row">
                    <Grid item xs={3}>
                        <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item className={classes.numberStep}>
                                {t('global_1')}
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{t('localization_grid_name_label')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <InputText
                            placeholder={t('localization_grid_name_input')}
                            name="projectName"
                            value={name}
                            error={!name}
                            // errorText={t('global_this_field_is_required')}
                            // disabled={isSubmitting}
                            onChange={e => setName(e.target.value)}
                            autoFocus={true}
                        />
                    </Grid>
                </Grid>

                <Grid item container direction="column" className={classes.fullWidth}>
                    <Grid item className={`${classes.spacing} ${classes.fullWidth}`}>
                        <Typography
                            onClick={() => setIsShowPreview(!isShowPreview)}
                            className={classes.showPreview}
                            variant="body1"
                        >
                            {isShowPreview ? 'Hide preview' : 'Show preview'}
                        </Typography>
                    </Grid>
                    <Collapse in={isShowPreview} className={classes.fullWidth}>
                        <Grid item>
                            <Grid container direction="column" wrap="nowrap" className={classes.treeBox}>
                                {isUploading ? (
                                    <Grid item className={classes.spinnerWrapper}>
                                        <Spinner />
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            className={classes.treeBoxHeader}
                                            alignItems="center"
                                            wrap="nowrap"
                                        >
                                            <Grid item className={classes.fileIcon}>
                                                <FileSVG />
                                            </Grid>
                                            <Grid item className={classes.fileName}>
                                                <Typography className={classes.ellipsis} variant="body1">
                                                    {fileName || 'Default File'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {!messages?.length && (
                                            <Grid
                                                item
                                                container
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item>
                                                    <Typography variant="caption">No message found</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {messages?.length > 0 && (
                                            <Grid item className={classes.treeBoxContent}>
                                                <Grid container direction="column">
                                                    {messages?.map(message => {
                                                        return (
                                                            <Grid
                                                                item
                                                                container
                                                                direction="column"
                                                                key={message?.originalString}
                                                                className={classes.messageItem}
                                                            >
                                                                <Grid item className={`${classes.messageItemHeader}`}>
                                                                    <Grid
                                                                        container
                                                                        wrap="nowrap"
                                                                        alignItems="center"
                                                                        direction="row"
                                                                    >
                                                                        <Grid item className={classes.pr2}>
                                                                            <Typography
                                                                                display="inline"
                                                                                variant="body1"
                                                                                className={classes.ellipsis}
                                                                                noWrap
                                                                            >
                                                                                Source:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                display="inline"
                                                                                variant="body2"
                                                                                className={classes.ellipsis}
                                                                                noWrap
                                                                            >
                                                                                {message?.originalString}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item className={classes.messageItemContent}>
                                                                    <Grid container wrap="nowrap" direction="row">
                                                                        <Grid item className={classes.pr2}>
                                                                            <ArrowNextSVG
                                                                                color={theme.colors.primaryText}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item className={classes.pr2}>
                                                                            <CountryFlag
                                                                                languageCountryCode={fileTarget?.id}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="caption"
                                                                                className={classes.ellipsis}
                                                                            >
                                                                                {message?.translationString}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item container alignItems="center" direction="row">
                    <Grid item xs={3}>
                        <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item className={classes.numberStep}>
                                {t('global_2')}
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{t('global_select_source_lang')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        {isModeSingle ? (
                            <Grid item xs={12} md={5}>
                                <LDBasePortalVirtualize
                                    ddPlaceholder={t('global_languague_select')}
                                    menuPlaceholder={t('global_languague_search')}
                                    options={langOptions}
                                    onChange={handleSourceChange}
                                    defaultValue={sources?.[0]}
                                    isMulti={false}
                                />
                            </Grid>
                        ) : (
                            <Grid item className={`${classes.fullWidth} ${classes.box}`}>
                                <Grid container alignItems="center" direction="row" wrap="nowrap">
                                    {sources?.map((source, index) => {
                                        return (
                                            <>
                                                <Grid item className={classes.w170} key={source?.value || index}>
                                                    <LDBasePortalVirtualize
                                                        ddPlaceholder={t('global_languague_select')}
                                                        menuPlaceholder={t('global_languague_search')}
                                                        options={langOptions}
                                                        onChange={option => handleMultiSourceChange({ option, index })}
                                                        defaultValue={source}
                                                        isMulti={false}
                                                        isCreatable={true}
                                                        onCreateNew={() => handleMultiSourceRemove(index)}
                                                        createText={t('global_delete')}
                                                        createIcon={<DeleteSVG color={theme.palette.primary.main} />}
                                                    />
                                                </Grid>
                                                {index !== sources?.length && (
                                                    <Grid item className={classes.split}>
                                                        <PathSVG />
                                                    </Grid>
                                                )}
                                            </>
                                        );
                                    })}

                                    <Grid item>
                                        <Grid
                                            className={classes.addMoreSource}
                                            container
                                            alignItems="center"
                                            direction="row"
                                            justify="center"
                                            onClick={handleAddSource}
                                        >
                                            <Grid item className={classes.dlFlex}>
                                                <ColorAddSVG />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    {t('localization_add_more_source')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={3}>
                    <Grid item container alignItems="center" direction="row" justify="space-between">
                        <Grid item>
                            <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item className={classes.numberStep}>
                                    {t('global_3')}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">{t('global_select_target_lang')}</Typography>
                                </Grid>
                                <Grid item>|</Grid>
                                <Grid item>
                                    <Typography variant="caption">
                                        {t('localization_selected_langs_note', {
                                            selectedLangs: validSelectedTargetOptions.length,
                                            total: langOptions.length
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Search
                                className={classes.search}
                                onChange={e => setFilterValue(e.target.value)}
                                background={theme.colors.paleGrey}
                                placeholder={t('localization_target_search')}
                            />
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid item className={classes.targetList}>
                            <AutoSizer>
                                {({ width, height }) => {
                                    return (
                                        <List
                                            className={classes.virtualizeList}
                                            rowCount={Math.ceil(targetOptions?.length / ITEMS_PER_ROW)}
                                            rowHeight={42}
                                            width={width}
                                            height={height}
                                            noRowsRenderer={_noRowsRenderer}
                                            rowRenderer={_rowRenderer}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" direction="row">
                    <Grid item xs={3}>
                        <Grid container direction="row" spacing={2} alignItems="center">
                            <Grid item className={classes.numberStep}>
                                {t('global_4')}
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{t('global_select_message_key')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid item xs={12} md={5}>
                            <LDBasePortalVirtualize
                                ddPlaceholder={t('global_select_message_key')}
                                menuPlaceholder={t('global_find_message_key')}
                                options={messageKeyOptions}
                                onChange={handleMessageKeyChange}
                                defaultValue={messageKey}
                                isMulti={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [
        handleMessageKeyChange,
        messageKeyOptions,
        messageKey,
        fileTarget,
        isShowPreview,
        validSelectedTargetOptions,
        handleMultiSourceChange,
        isModeSingle,
        // isTypeMultiple,
        langOptions,
        handleSourceChange,
        handleMultiSourceRemove,
        sources,
        name,
        theme,
        targetOptions,
        _noRowsRenderer,
        _rowRenderer,
        handleAddSource,
        // handleModeChange,
        classes,
        t,
        fileName,
        messages,
        isUploading
    ]);

    return (
        <>
            <DialogTitle title={t('import_data')} onClose={onClose} />
            <DialogContent>{contentRender()}</DialogContent>
            <DialogActions>
                <Grid item container justify="flex-end" spacing={2} alignItems="center">
                    <Grid item>
                        <Grid container spacing={2} justify="center" alignItems="center">
                            <Grid item>
                                <ButtonBase onClick={onClose} variant="outlined" width={120}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <div className={classes.btnSubmit}>
                                    <ButtonBase
                                        disabled={!isCreateValid || isSubmitting || isUploading}
                                        onClick={handleCreateLocalizationGrid}
                                        variant="contained"
                                        width={120}
                                    >
                                        {t('global_create')}
                                    </ButtonBase>
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(LocalizationImportPOSetup);
