import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { hightlightElements, removeHightlightElements } from './helpers';
import fastlane from 'assets/files/fastlane.csv';
import ExportSVG from 'assets/images/svg/ExportSVG';
import theme from 'theme';
import { sendManualTrack } from 'tracker';
import useMediaQueries from 'hooks/useMediaQueries';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    buttonText: {
        color: `${theme.colors.dimGrey} !important`,
        textDecoration: 'underline'
    }
}));

function LastTutorial() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item>
                <Typography variant="h6" display="inline">
                    Upload a CSV, XLS, or JSON file{' '}
                </Typography>
                <Typography variant="body2" display="inline">
                    with your strings or content of your choice. Or start from scratch with an empty Grid and import
                    your data later.
                </Typography>
            </Grid>
            <Grid item style={{ marginTop: 14 }}>
                <Typography variant="body2" display="inline">
                    Don't have any data?
                </Typography>
                <Typography variant="h6" display="inline">
                    {' '}
                    Try out this sample file.
                </Typography>
            </Grid>
            <Grid item style={{ width: '100%', marginTop: 14 }}>
                <a
                    onClick={() => sendManualTrack({ type: 'Dashboard Tutorial Download File' })}
                    className="btn btn-outlined w-full"
                    download="Fastlane.csv"
                    href={fastlane}
                >
                    <span className={classes.buttonText}>{t('global_download')} Fastlane.csv</span>
                    <ExportSVG color={theme.colors.dimGrey} style={{ marginLeft: 8 }} />
                </a>
            </Grid>
        </Grid>
    );
}

export const DASHBOARD_TUTORIAL_STEPS = store => {
    const { t } = useTranslation();
    const { getState } = store;
    const firstName = getState().auth.currentUser?.firstName;
    const { isMobile } = useMediaQueries();
    const defaultPlacement = isMobile ? 'bottom-end' : 'left-start';

    return [
        {
            anchorSelector: `#databases_page`,
            placement: defaultPlacement,
            title: `Welcome to Gridly, ${firstName}👋🏻`,
            content: <Typography variant="body2">{t('youre_moment_away_from_creating')}</Typography>,
            nextButtonText: t('ok_lets_go'),
            computeStyle: {
                enabled: true,
                fn: data => {
                    return {
                        ...data,
                        styles: {
                            ...data.styles,
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    };
                }
            },
            onNext: () => {
                sendManualTrack({ type: 'Dashboard Tutorial Step 1' });
            }
        },
        {
            anchorSelector: '#workspaces_page',
            placement: 'right',
            title: t('here_are_your_projects'),
            content: (
                <>
                    <Typography variant="body2">{t('project_is_a_top_level_organization')}</Typography>
                </>
            ),
            nextButtonText: t('global_next'),
            onMount: () => {
                setTimeout(() => {
                    hightlightElements('#workspaces_page');
                    //hightlightElements('.databaseItem:first-child');
                }, 0);
            },
            onUnmount: () => {
                removeHightlightElements('#workspaces_page');
                //removeHightlightElements('.databaseItem:first-child');
            },
            onNext: () => {
                sendManualTrack({ type: 'Dashboard Tutorial Step 2' });
            }
        },
        {
            anchorSelector: '.databaseItem:first-child',
            placement: 'top',
            title: t('databases_help_organize_your_content'),
            content: (
                <>
                    <Typography variant="body2">{t('within_a_database')}</Typography>
                </>
            ),
            nextButtonText: t('global_next'),
            backButtonText: i18n.t('global_back'),
            onMount: () => {
                setTimeout(() => {
                    //hightlightElements('#workspaces_page');
                    hightlightElements('.databaseItem:first-child');
                }, 0);
            },
            onUnmount: () => {
                //removeHightlightElements('#workspaces_page');
                removeHightlightElements('.databaseItem:first-child');
            },
            onNext: () => {
                sendManualTrack({ type: 'Dashboard Tutorial Step 3' });
            }
        },
        {
            anchorSelector: '#grid-list-0',
            placement: 'bottom',
            title: t('grid_explain'),
            content: <Typography variant="body2">{t('create_organize_translate')}</Typography>,
            nextButtonText: t('global_next'),
            backButtonText: i18n.t('global_back'),
            onMount: () => {
                hightlightElements('#grid-list-0');
            },
            onUnmount: () => {
                removeHightlightElements('#grid-list-0');
            },
            onNext: () => {
                sendManualTrack({ type: 'Dashboard Tutorial Step 4' });
            }
        },

        {
            anchorSelector: '#gridMenu',
            placement: defaultPlacement,
            title: t('now_lets_create_your_first_grid'),
            content: <LastTutorial />,
            //nextButtonText: 'Let's go',
            //backButtonText: i18n.t('global_back'),
            onMount: () => {
                const firstAddGridButton = document.querySelector('.addGridBtn:first-child');
                if (firstAddGridButton) {
                    firstAddGridButton.click();
                    setTimeout(() => {
                        firstAddGridButton.scrollIntoView({ behavior: 'smooth' });
                    }, 0);
                }
                setTimeout(() => {
                    hightlightElements('#gridMenu');

                    const menuItems = document.querySelectorAll('.gridMenuItem') || [];
                    const tutorialCloseBtn = document.querySelector('.tutorialCloseBtn:first-child');
                    menuItems.forEach(item => {
                        item.addEventListener(
                            'click',
                            () => {
                                tutorialCloseBtn && tutorialCloseBtn.click();
                            },
                            false
                        );
                    });
                }, 0);
            },
            onUnmount: () => {
                const firstAddGridButton = document.querySelector('.addGridBtn:first-child');
                firstAddGridButton && firstAddGridButton.click();
                removeHightlightElements('#gridMenu');
            },
            onNext: () => {
                const firstAddGridButton = document.querySelector('.addGridBtn:first-child');
                firstAddGridButton && firstAddGridButton.click();
                sendManualTrack({ type: 'Dashboard Tutorial Step 5' });
            }
        }
    ];
};

export const DASHBOARD_TUTORIAL_FROM_SIGNUP_STEPS = store => {
    const allSteps = [...DASHBOARD_TUTORIAL_STEPS(store)];

    allSteps.shift();

    return allSteps;
};
