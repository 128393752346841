import React from 'react';
import { Grid, Divider, CircularProgress } from '@material-ui/core';
import ListItem from 'components/list/Item';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import { useFormik } from 'formik';
import { object, number } from 'yup';
import InputText from 'components/inputs/InputText';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/Base';

function AddRecordPopup({ onClose }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const addRecordBelow = React.useCallback(
        count => {
            dispatch(gridUIActions.addViewRecordsBelow({ count, successCallback: () => {}, errorCallback: () => {} }));
            onClose && onClose();
        },
        [dispatch, onClose]
    );

    React.useEffect(() => {
        dispatch(gridUIActions.resetSelection());
    }, [dispatch]);

    const formik = useFormik({
        initialValues: {
            value: 100
        },
        validationSchema: object().shape({
            value: number()
                .required(t('global_this_field_is_required'))
                .min(1, 'Num of records must be a value between 1 and 1000')
                .max(1000, 'Num of records must be a value between 1 and 1000')
        }),
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            setSubmitting(true);
            dispatch(
                gridUIActions.addViewRecordsBelow({
                    count: values.value,
                    successCallback: () => {
                        setSubmitting(false);
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
        }
    });

    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column">
                <Grid item>
                    <ListItem onClick={() => addRecordBelow(5)} name={`+ ${t('add_5_records')}`} />
                </Grid>
                <Grid item>
                    <ListItem onClick={() => addRecordBelow(10)} name={`+ ${t('add_10_records')}`} />
                </Grid>
                <Grid item>
                    <ListItem onClick={() => addRecordBelow(15)} name={`+ ${t('add_15_records')}`} />
                </Grid>
                <Grid item className="mt-1">
                    <Divider />
                </Grid>
                <Grid style={{ padding: `10px 20px 16px` }} item>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <p className="body1 inline">Set a custom records</p>{' '}
                            <p className="caption inline">(Min:1 - Max:1000)</p>
                        </Grid>
                        <Grid item>
                            <InputText
                                name="value"
                                type="number"
                                value={values.value}
                                disabled={isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.value && touched.value}
                                placeholder={'Input your custom records here'}
                                autoFocus
                                autoSelect={true}
                                isShowErrorMessage={true}
                                errorText={errors.value}
                                disableErrorAbsolute
                                inputProps={{
                                    max: 1000,
                                    min: 1
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Divider />
                </Grid>

                <Grid item style={{ padding: `16px 20px 8px` }}>
                    <Grid container direction="row" spacing={3} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Button width={120} onClick={onClose} variant="outlined">
                                {t('global_cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <div className={'relative'}>
                                <Button
                                    width={120}
                                    disabled={!isValid || isSubmitting}
                                    type="submit"
                                    variant="contained"
                                >
                                    Add
                                </Button>
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={'text-brand-main absolute top-[50%] left-[50%] -mt-3 -ml-3'}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default AddRecordPopup;
