import React from 'react';

function MultiStepIllustratorSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="224" height="119" viewBox="0 0 224 119" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="224" height="119" rx="4" fill="white" />
            <path
                d="M57 59.0003C57 58.6003 57.3 58.2003 57.8 58.1003H57.9H67.1L65.4 56.4003C65.1 56.1003 65.1 55.7003 65.2 55.4003L65.3 55.3003L65.4 55.2003C65.7 54.9003 66.2 54.9003 66.5 55.1003L66.6 55.2003L69.8 58.3003C69.9 58.4003 70 58.5003 70 58.6003V58.7003V58.9003V59.1003V59.2003L69.9 59.3003L69.8 59.4003L69.7 59.5003L66.5 62.6003C66.2 62.9003 65.6 62.9003 65.3 62.6003C65.1 62.4003 65 62.2003 65 62.0003C65 61.8003 65.1 61.6003 65.2 61.5003L65.3 61.4003L67 59.7003H57.8C57.4 59.7003 57.1 59.4003 57 59.1003V59.0003Z"
                fill="#2A293A"
            />
            <path
                d="M124 59.0003C124 58.6003 124.3 58.2003 124.8 58.1003H124.9H134.1L132.4 56.4003C132.1 56.1003 132.1 55.7003 132.2 55.4003L132.3 55.3003L132.4 55.2003C132.7 54.9003 133.2 54.9003 133.5 55.1003L133.6 55.2003L136.8 58.3003C136.9 58.4003 137 58.5003 137 58.6003V58.7003V58.9003V59.1003V59.2003L136.9 59.3003L136.8 59.4003L136.7 59.5003L133.5 62.6003C133.2 62.9003 132.6 62.9003 132.3 62.6003C132.1 62.4003 132 62.2003 132 62.0003C132 61.8003 132.1 61.6003 132.2 61.5003L132.3 61.4003L134 59.7003H124.8C124.4 59.7003 124.1 59.4003 124 59.1003V59.0003Z"
                fill="#2A293A"
            />
            <path
                d="M173 69C178 69 182 65 182 60C182 55 178 51 173 51C168 51 164 55 164 60C164 65 168 69 173 69Z"
                fill="#6DA544"
            />
            <path d="M173.002 54.5L180.402 60L173.002 65.5L165.602 60L173.002 54.5Z" fill="#FFDA44" />
            <path
                d="M172.998 63.1023C174.698 63.1023 176.098 61.7023 176.098 60.0023C176.098 58.3023 174.698 56.9023 172.998 56.9023C171.298 56.9023 169.898 58.3023 169.898 60.0023C169.898 61.7023 171.298 63.1023 172.998 63.1023Z"
                fill="#F0F0F0"
            />
            <path
                d="M171.401 59.8008C170.901 59.8008 170.301 59.9008 169.801 60.0008C169.801 61.7008 171.201 63.1008 172.901 63.1008C174.001 63.1008 174.901 62.6008 175.501 61.8008C174.601 60.6008 173.101 59.8008 171.401 59.8008Z"
                fill="#0052B4"
            />
            <path
                d="M176.095 60.6023C176.095 60.4023 176.195 60.2023 176.195 60.0023C176.195 58.3023 174.795 56.9023 173.095 56.9023C171.795 56.9023 170.695 57.7023 170.195 58.8023C170.595 58.7023 171.095 58.7023 171.495 58.7023C173.295 58.6023 174.895 59.4023 176.095 60.6023Z"
                fill="#0052B4"
            />
            <path
                d="M156 49C159.871 49 163 45.8706 163 42C163 38.1294 159.871 35 156 35C152.129 35 149 38.1294 149 42C149 45.8706 152.129 49 156 49Z"
                fill="#FFDA44"
            />
            <path
                d="M154.52 41.0941H163.002C162.59 37.6353 159.625 35 156.084 35C155.59 35 155.014 35.0824 154.602 35.1647V41.0941H154.52Z"
                fill="#0052B4"
            />
            <path d="M152.624 41.0948V35.8242C150.729 36.8948 149.329 38.7889 149 41.0948H152.624Z" fill="#0052B4" />
            <path d="M152.624 42.9062H149C149.329 45.1298 150.647 47.1062 152.624 48.1768V42.9062Z" fill="#0052B4" />
            <path
                d="M154.52 42.9062V48.8357C155.014 48.918 155.508 49.0004 156.002 49.0004C159.543 49.0004 162.508 46.3651 162.92 42.9062H154.52Z"
                fill="#0052B4"
            />
            <path
                d="M97 73C104.7 73 111 66.5 111 58.5C111 50.5 104.7 44 97 44C89.3 44 83 50.5 83 58.5C83 66.5 89.3 73 97 73Z"
                fill="#F0F0F0"
            />
            <path
                d="M96.3984 58.4992H110.998C110.998 57.1992 110.798 55.8992 110.498 54.6992H96.3984V58.4992Z"
                fill="#D80027"
            />
            <path
                d="M96.3984 50.9016H108.998C108.098 49.5016 106.998 48.2016 105.798 47.1016H96.4984V50.9016H96.3984Z"
                fill="#D80027"
            />
            <path
                d="M97.0008 73.0008C100.301 73.0008 103.301 71.8008 105.701 69.8008H88.3008C90.7008 71.8008 93.7008 73.0008 97.0008 73.0008Z"
                fill="#D80027"
            />
            <path
                d="M85.1016 66.1008H109.002C109.702 64.9008 110.202 63.7008 110.602 62.3008H83.6016C83.8016 63.6008 84.4016 64.9008 85.1016 66.1008Z"
                fill="#D80027"
            />
            <path
                d="M89.5 46.3H90.8L89.6 47.2L90.1 48.6L88.9 47.7L87.7 48.6L88.1 47.4C87.1 48.3 86.1 49.4 85.4 50.5H85.8L85 51.1C84.9 51.3 84.8 51.5 84.7 51.7L85.1 52.8L84.4 52.3C84.2 52.7 84.1 53 83.9 53.4L84.3 54.7H85.8L84.6 55.6L85 57L83.8 56.1L83.1 56.6C83 57.3 83 57.9 83 58.5H97C97 50.5 97 49.5 97 44C94.2 44 91.7 44.8 89.5 46.3ZM90 57L88.8 56.1L87.7 57L88.2 55.6L87 54.7H88.5L89 53.3L89.5 54.7H91L89.8 55.6L90 57ZM89.6 51.4L90.1 52.8L88.9 51.9L87.7 52.8L88.2 51.4L87 50.5H88.5L89 49.1L89.5 50.5H91L89.6 51.4ZM95 57L93.8 56.1L92.7 57L93.2 55.6L92 54.7H93.5L94 53.3L94.5 54.7H96L94.8 55.6L95 57ZM94.6 51.4L95.1 52.8L93.9 51.9L92.7 52.8L93.2 51.4L92 50.5H93.5L94 49.1L94.5 50.5H96L94.6 51.4ZM94.6 47.2L95.1 48.6L93.9 47.7L92.7 48.6L93.2 47.2L92 46.3H93.5L94 44.9L94.5 46.3H96L94.6 47.2Z"
                fill="#0052B4"
            />
            <path
                d="M32 72C39.7 72 46 65.5 46 57.5C46 49.5 39.7 43 32 43C24.3 43 18 49.5 18 57.5C18 65.5 24.3 72 32 72Z"
                fill="#D80027"
            />
            <path
                d="M25.6984 51.8008L26.8984 55.7008H30.7984L27.5984 58.1008L28.7984 62.0008L25.5984 59.6008L22.3984 62.0008L23.5984 58.1008L20.3984 55.7008H24.2984L25.6984 51.8008Z"
                fill="#FFDA44"
            />
            <path
                d="M34.5992 65.4984L33.6992 64.2984L32.2992 64.7984L33.0992 63.4984L32.1992 62.2984L33.5992 62.6984L34.3992 61.3984L34.4992 62.8984L35.8992 63.2984L34.4992 63.7984L34.5992 65.4984Z"
                fill="#FFDA44"
            />
            <path
                d="M36.4016 61.9992L36.8016 60.4992L35.6016 59.5992H37.1016L37.5016 58.1992L38.0016 59.5992H39.5016L38.3016 60.4992L38.8016 61.8992L37.6016 60.9992L36.4016 61.9992Z"
                fill="#FFDA44"
            />
            <path
                d="M38.9 53.6008L38.3 55.0008L39.4 56.1008L38 55.9008L37.4 57.3008L37 55.7008L35.5 55.5008L36.8 54.8008L36.5 53.3008L37.6 54.4008L38.9 53.6008Z"
                fill="#FFDA44"
            />
            <path
                d="M34.5992 49.5L34.4992 51L35.8992 51.6L34.4992 52L34.3992 53.5L33.5992 52.2L32.1992 52.6L33.0992 51.4L32.2992 50.1L33.6992 50.7L34.5992 49.5Z"
                fill="#FFDA44"
            />
            <path
                d="M178.473 84.4566C179.609 87.6756 182.544 90.0424 186.047 90.0424C189.55 90.0424 192.484 87.7702 193.621 84.4566L186.047 83.6992L178.473 84.4566Z"
                fill="#FFDA44"
            />
            <path
                d="M186.047 73C182.544 73 179.609 75.2722 178.473 78.5858L186.047 79.3432L193.621 78.5858C192.484 75.2722 189.55 73 186.047 73Z"
                fill="black"
            />
            <path
                d="M178.473 78.5859C178.189 79.5327 178 80.4794 178 81.5209C178 82.5623 178.189 83.6037 178.473 84.4558H193.527C193.811 83.509 194 82.5623 194 81.5209C194 80.4794 193.811 79.438 193.527 78.5859H178.473Z"
                fill="#D80027"
            />
            <path
                d="M183 46C188 46 192 42.2 192 37.5C192 32.8 188 29 183 29C178 29 174 32.8 174 37.5C174 42.2 178 46 183 46Z"
                fill="#F0F0F0"
            />
            <path
                d="M192.002 37.5C192.002 33.8 189.602 30.7 186.102 29.5V45.4C189.602 44.3 192.002 41.2 192.002 37.5Z"
                fill="#D80027"
            />
            <path d="M174 37.5C174 41.2 176.4 44.3 179.9 45.5V29.5C176.4 30.7 174 33.8 174 37.5Z" fill="#0052B4" />
            <path
                d="M162 89.5882C164.765 89.5882 167 87.2353 167 84.2941C167 81.3529 164.765 79 162 79C159.235 79 157 81.3529 157 84.2941C157 87.2353 159.235 89.5882 162 89.5882Z"
                fill="#F0F0F0"
            />
            <path
                d="M167.001 84.2927C167.001 81.9986 165.648 80.0574 163.766 79.3516V89.2927C165.648 88.528 167.001 86.5869 167.001 84.2927Z"
                fill="#D80027"
            />
            <path
                d="M157 84.2927C157 86.5869 158.353 88.528 160.235 89.2339V79.3516C158.353 80.0574 157 81.9986 157 84.2927Z"
                fill="#6DA544"
            />
            <path
                d="M152.5 74C157.194 74 161 70.1944 161 65.5C161 60.8056 157.194 57 152.5 57C147.806 57 144 60.8056 144 65.5C144 70.1944 147.806 74 152.5 74Z"
                fill="#F0F0F0"
            />
            <path
                d="M152.5 69C154.433 69 156 67.433 156 65.5C156 63.567 154.433 62 152.5 62C150.567 62 149 63.567 149 65.5C149 67.433 150.567 69 152.5 69Z"
                fill="#D80027"
            />
            <path
                d="M197.5 67C202.194 67 206 63.1944 206 58.5C206 53.8056 202.194 50 197.5 50C192.806 50 189 53.8056 189 58.5C189 63.1944 192.806 67 197.5 67Z"
                fill="#F0F0F0"
            />
            <path
                d="M200.46 58.4995C200.46 59.2386 199.136 61.0864 197.503 61.0864C195.871 61.0864 194.547 59.2386 194.547 58.4995C194.547 56.8666 195.871 55.543 197.503 55.543C199.136 55.543 200.46 56.8666 200.46 58.4995Z"
                fill="#D80027"
            />
            <path
                d="M200.46 58.5C200.46 60.1329 199.136 61.4565 197.503 61.4565C195.871 61.4565 194.547 60.1329 194.547 58.5"
                fill="#0052B4"
            />
            <path d="M201.425 60.3254L200.641 61.1094L201.163 61.632L201.947 60.8481L201.425 60.3254Z" fill="black" />
            <path d="M200.112 61.6379L199.328 62.4219L199.851 62.9445L200.635 62.1606L200.112 61.6379Z" fill="black" />
            <path d="M202.991 61.8957L202.207 62.6797L202.73 63.2023L203.514 62.4184L202.991 61.8957Z" fill="black" />
            <path d="M201.678 63.2082L200.895 63.9922L201.417 64.5148L202.201 63.7309L201.678 63.2082Z" fill="black" />
            <path d="M202.206 61.1145L201.422 61.8984L201.945 62.4211L202.728 61.6371L202.206 61.1145Z" fill="black" />
            <path d="M200.897 62.4192L200.113 63.2031L200.636 63.7258L201.42 62.9418L200.897 62.4192Z" fill="black" />
            <path d="M200.12 53.8001L202.211 55.8906L202.734 55.368L200.643 53.2774L200.12 53.8001Z" fill="black" />
            <path d="M199.329 54.5793L200.113 55.3633L200.636 54.8407L199.852 54.0567L199.329 54.5793Z" fill="black" />
            <path d="M200.638 55.8879L201.422 56.6719L201.944 56.1493L201.161 55.3653L200.638 55.8879Z" fill="black" />
            <path d="M200.9 53.0129L201.684 53.7969L202.206 53.2742L201.422 52.4903L200.9 53.0129Z" fill="black" />
            <path d="M202.204 54.3176L202.988 55.1016L203.511 54.5789L202.727 53.795L202.204 54.3176Z" fill="black" />
            <path d="M194.102 63.989L192.012 61.8984L191.489 62.4211L193.58 64.5116L194.102 63.989Z" fill="black" />
            <path d="M194.893 63.2058L194.109 62.4219L193.587 62.9445L194.371 63.7284L194.893 63.2058Z" fill="black" />
            <path d="M193.577 61.8933L192.793 61.1094L192.27 61.632L193.054 62.416L193.577 61.8933Z" fill="black" />
            <path d="M195.669 62.4187L193.578 60.3281L193.055 60.8508L195.146 62.9413L195.669 62.4187Z" fill="black" />
            <path d="M192.015 55.1023L194.105 53.0117L193.583 52.4891L191.492 54.5796L192.015 55.1023Z" fill="black" />
            <path d="M192.796 55.8874L194.887 53.7969L194.364 53.2742L192.274 55.3648L192.796 55.8874Z" fill="black" />
            <path d="M193.581 56.6687L195.672 54.5781L195.149 54.0555L193.059 56.146L193.581 56.6687Z" fill="black" />
        </svg>
    );
}

export default React.memo(MultiStepIllustratorSVG);
