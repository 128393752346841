import React from 'react';

function HistorySVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill={color}
                fillRule="nonzero"
                d="M8.667.8C4.95.8 1.895 3.671 1.552 7.374l-.02.248-.1.098H.296l-.07.003c-.232.02-.291.121-.146.297l1.724 1.9.068.062c.233.186.529.189.718.018l1.732-1.906.037-.056c.105-.174.045-.29-.171-.314l-.09-.004H2.957l-.1-.114.018-.193C3.199 4.414 5.672 2.13 8.667 2.13c3.176 0 5.81 2.66 5.81 5.871 0 3.21-2.634 5.87-5.81 5.87a5.86 5.86 0 0 1-3.145-.92l-.326-.215c-.273-.149-.689-.067-.867.173-.194.26-.127.732.132.928 1.173.888 2.665 1.364 4.206 1.364 3.904 0 7.133-3.26 7.133-7.2 0-3.94-3.229-7.2-7.133-7.2zm-.381 3.633a.541.541 0 0 0-.56.472l-.006.096V8.84l.004.084c.022.186.125.33.286.394l.084.025 2.677 1.427.123.051.013.055h.114l.085-.005c.19-.023.335-.132.392-.293l.02-.084c.065-.183.016-.416-.112-.596l-.07-.085-2.429-1.28-.055-.093V5.007l-.004-.081a.542.542 0 0 0-.562-.493z"
            />
        </svg>
    );
}

export default React.memo(HistorySVG);
