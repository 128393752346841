import React from 'react';

function AddIconSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 1C5.41421 1 5.75 1.33579 5.75 1.75V4.25H8.2C8.64183 4.25 9 4.60817 9 5.05C9 5.49183 8.64183 5.85 8.2 5.85H5.75V8.25C5.75 8.66421 5.41421 9 5 9C4.58579 9 4.25 8.66421 4.25 8.25L4.249 5.85H1.8C1.35817 5.85 1 5.49183 1 5.05C1 4.60817 1.35817 4.25 1.8 4.25H4.25V1.75C4.25 1.33579 4.58579 1 5 1Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(AddIconSVG);
