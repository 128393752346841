import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Dialog from 'components/dialog/Dialog';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Radio, RadioGroup, FormControlLabel, CircularProgress, IconButton } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';
import { EXPORT_TYPES } from 'const/gridUI';
import Checkbox from 'components/checkbox/Base';
import { AutoSizer, List } from 'react-virtualized-dn';
import { chunk } from 'lodash';
import ListSelect from 'components/list/Select';
import Tooltip from 'components/tooltip/Base';
import { useDispatch } from 'react-redux';
import { exportTerms } from 'gridUI/actions';
import ButtonBase from 'components/button/Base';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPaper-root': {
            width: `100%`,
            maxWidth: `754px !important`
        }
    },
    container: {},
    closeIcon: {
        verticalAlign: 'middle'
    },
    header: {
        padding: `32px 32px 0`
    },
    content: {
        marginTop: 41,
        padding: `0 32px`
    },
    footer: {
        marginTop: 18,
        padding: `18px 32px`,
        borderTop: `1px solid ${theme.colors.border}`
    },
    numberStep: {
        width: 18,
        minWidth: 18,
        height: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 12,
        lineHeight: `14px`,
        marginRight: 8,
        position: 'relative'
    },
    exportType: {
        marginLeft: 48
    },
    radio: {
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: theme.colors.lightLavender
        }
    },
    languages: {
        marginTop: 26
    },
    targetList: {
        margin: `8px -8px 0`,
        minHeight: 168,
        maxHeight: 209,
        overflowY: 'auto'
    },
    rowItem: {
        minWidth: 220
    },
    optional: {
        marginTop: 8,
        '& .MuiCheckbox-root': {
            padding: `0 !important`,
            left: -2,
            top: -1
        }
    },
    ml8: {
        marginLeft: 8
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

const ITEMS_PER_ROW = 3;

const ExportTBDialog = ({ open = true, onClose, glossary, langOptions }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const defaultCheckedOptions = ['conceptId', 'termId'];

    const optionalOptions = useMemo(() => {
        return [
            { value: 'conceptId', label: 'Concept ID', disabled: true },
            { value: 'termId', label: 'Term ID', disabled: true },
            { value: 'status', label: t('global_status') },
            { value: 'forbidden', label: 'Forbidden' },
            { value: 'preferred', label: 'Preferred' },
            { value: 'caseSensitive', label: 'Case sensitive' },
            { value: 'exactMatch', label: 'Match type' },
            { value: 'partOfSpeech', label: 'Part of speech' },
            { value: 'grammaticalGender', label: 'Gender' },
            { value: 'grammaticalNumber', label: 'Number' },
            { value: 'termType', label: 'Term type' },
            { value: 'createdBy', label: 'Created by' },
            { value: 'createdAt', label: 'Created at' },
            { value: 'lastModifiedBy', label: 'Last modified by' },
            { value: 'lastModifiedAt', label: 'Last modified at' }
        ];
    }, [t]);

    const [exportType, setExportType] = useState(EXPORT_TYPES.TBX);
    const [selectedTargetOptions, setSelectedTargetOptions] = useState({});
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [exporting, setExporting] = useState(false);

    const options = useMemo(() => {
        return [
            { value: EXPORT_TYPES.TBX, label: t(`global_tbx`) },
            { value: EXPORT_TYPES.CSV, label: t(`global_csv`) },
            { value: EXPORT_TYPES.XLSX, label: t(`global_xlsx`) }
        ];
    }, [t]);

    const isAllSelected = useMemo(() => {
        const values = Object.values(selectedTargetOptions);
        return values.every(el => !!el) && values.length === langOptions.length;
    }, [langOptions, selectedTargetOptions]);

    const isAllOptionsSelected = useMemo(() => {
        return checkedOptions.every(el => !!el) && checkedOptions.length === optionalOptions.length;
    }, [optionalOptions, checkedOptions]);

    const isDisabledExport = useMemo(() => {
        const values = Object.values(selectedTargetOptions);
        return values.every(el => !el);
    }, [selectedTargetOptions]);

    const handleExportTypeChange = useCallback(
        e => {
            let exportType = e.target.value;
            setExportType(exportType);
            setCheckedOptions(
                exportType === EXPORT_TYPES.TBX ? optionalOptions.map(el => el.value) : [...defaultCheckedOptions]
            );
        },
        [defaultCheckedOptions, optionalOptions]
    );

    const _noRowsRenderer = React.useCallback(() => {
        return null;
    }, []);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions[targetValue] || false;

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    const langOptionChunks = React.useMemo(() => {
        return chunk(langOptions, ITEMS_PER_ROW);
    }, [langOptions]);

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style }) => {
            const items = langOptionChunks?.[index];
            return (
                <Grid style={{ ...style }} key={key} container alignItems="center" direction="row" wrap="nowrap">
                    {items?.map(option => {
                        const isSelected = selectedTargetOptions?.[option?.value];

                        if (option?.isDisabled) {
                            return (
                                <Grid key={option?.value} item xs={4}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                        return (
                            <Tooltip title={option.tooltip} key={option.value}>
                                <Grid item xs={4}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            );
        },
        [handleTargetSelect, langOptionChunks, selectedTargetOptions, classes]
    );

    const handleSelectAll = useCallback(
        checked => {
            if (checked) {
                let newSelectedTargetOptions = {};
                langOptions.map(el => {
                    newSelectedTargetOptions[el.value] = true;
                    return false;
                });
                setSelectedTargetOptions(newSelectedTargetOptions);
                return;
            }
            setSelectedTargetOptions({});
        },
        [langOptions]
    );

    const handleSelectOptionsAll = useCallback(
        checked => {
            if (checked) {
                setCheckedOptions(optionalOptions.map(el => el.value));
                return;
            }
            setCheckedOptions([...defaultCheckedOptions]);
        },
        [defaultCheckedOptions, optionalOptions]
    );

    const handleClose = useCallback(() => {
        if (exporting) return;
        onClose();
    }, [exporting, onClose]);

    const _export = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (exporting) return;
            setExporting(true);
            const langCodes = [];
            for (const property in selectedTargetOptions) {
                if (selectedTargetOptions[property]) {
                    langCodes.push(property);
                }
            }
            dispatch(
                exportTerms({
                    tbId: glossary.id,
                    format: exportType,
                    langCodes: langCodes.reduce((acc, cur, curIdx) => {
                        acc += `${curIdx > 0 ? '&' : ''}langCodes=${cur}`;
                        return acc;
                    }, ''),
                    includeAttributes: checkedOptions.reduce((acc, cur, curIdx) => {
                        acc += `${curIdx > 0 ? '&' : ''}includeAttributes=${cur}`;
                        return acc;
                    }, ''),
                    successCallback: () => {
                        setExporting(false);
                        onClose();
                    },
                    errorCallback: () => {
                        setExporting(false);
                    }
                })
            );
        },
        [dispatch, glossary, exporting, onClose, selectedTargetOptions, exportType, checkedOptions]
    );

    const handleCheckOptionalOption = useCallback(({ checked, value }) => {
        if (checked) {
            setCheckedOptions(prev => [...prev, value]);
        } else {
            setCheckedOptions(prev => prev.filter(el => el !== value));
        }
    }, []);

    useLayoutEffect(() => {
        if (open) {
            setExportType(EXPORT_TYPES.TBX);
            setCheckedOptions(optionalOptions.map(el => el.value));
            handleSelectAll(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} className={classes.root}>
            <Grid container direction="column" className={classes.container}>
                <Grid item container justifyContent="space-between" alignItems="center" className={classes.header}>
                    <Grid item>
                        <Typography variant="h3">Export terms</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseIconSVG className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container direction="column" className={classes.content}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item className={classes.numberStep}>
                            {t('global_1')}
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Glossary export file type:</Typography>
                        </Grid>
                        <Grid item className={classes.exportType}>
                            <RadioGroup
                                row
                                aria-label="export-type"
                                name="exportType"
                                value={exportType}
                                onChange={handleExportTypeChange}
                            >
                                {options.map(option => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio size="small" className={classes.radio} />}
                                        label={<Typography variant="body1">{option.label}</Typography>}
                                    />
                                ))}
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.languages}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            wrap="nowrap"
                        >
                            <Grid item container alignItems="center">
                                <Grid item className={classes.numberStep}>
                                    {t('global_2')}
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">Select languages to export</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <Checkbox
                                            checked={isAllSelected}
                                            onChange={e => handleSelectAll(e.target?.checked)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" display="inline" style={{ whiteSpace: 'nowrap' }}>
                                            {t('select_all_languages')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.targetList}>
                            <AutoSizer>
                                {({ width, height }) => {
                                    return (
                                        <List
                                            className={classes.virtualizeList}
                                            rowCount={Math.ceil(langOptions?.length / ITEMS_PER_ROW)}
                                            rowHeight={42}
                                            width={width}
                                            height={height}
                                            noRowsRenderer={_noRowsRenderer}
                                            rowRenderer={_rowRenderer}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" wrap="nowrap">
                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item className={classes.numberStep}>
                                        {t('global_3')}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">Optional options</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <Checkbox
                                            checked={isAllOptionsSelected}
                                            onChange={e => handleSelectOptionsAll(e.target?.checked)}
                                            disabled={exportType === EXPORT_TYPES.TBX}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" display="inline" style={{ whiteSpace: 'nowrap' }}>
                                            Select all options
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            {optionalOptions.map(option => (
                                <Grid key={option.value} item xs={4}>
                                    <Grid item container wrap="nowrap" alignItems="center" className={classes.optional}>
                                        <Grid item>
                                            <Checkbox
                                                checked={checkedOptions.includes(option.value)}
                                                onChange={e =>
                                                    handleCheckOptionalOption({
                                                        checked: e.target.checked,
                                                        value: option.value
                                                    })
                                                }
                                                disabled={option.disabled || exportType === EXPORT_TYPES.TBX}
                                            />
                                        </Grid>
                                        <Grid item className={classes.ml8}>
                                            <Typography variant="body2">{option.label}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.footer}>
                    <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonBase onClick={onClose} width={140} variant="outlined">
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item className={classes.submitButton}>
                            <ButtonBase
                                onClick={_export}
                                disabled={exporting || isDisabledExport}
                                width={140}
                                variant="contained"
                            >
                                {t('global_export')}
                                {exporting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default React.memo(ExportTBDialog);
