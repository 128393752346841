import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Search from 'components/search';
import ColumnsPermissionTable from './ColumnsPermissionTable';
import { LIST_MENU_MAX_HEIGHT } from 'const/style';

function ColumnsPermissionMenu({ t }) {
    const theme = useTheme();
    const [filterValue, setFilterValue] = useState('');

    return (
        <Grid className={'mt-0 w-[380px] py-0 px-2 sm:min-w-[initial]'} container direction="column">
            <Grid item className={'w-full py-0 !px-2 mb-2'}>
                <Search
                    width={'100%'}
                    onChange={e => setFilterValue(e.target.value)}
                    autoFocus
                    background={theme.colors.paleGrey}
                    placeholder={t(`global_label_find_a_column`)}
                />
            </Grid>
            <Grid item className={`w-full overflow-y-auto max-h-[calc(${LIST_MENU_MAX_HEIGHT} - ${16 + 54 + 36}px)]`}>
                <ColumnsPermissionTable t={t} filterValue={filterValue} />
            </Grid>
        </Grid>
    );
}

export default React.memo(ColumnsPermissionMenu);
