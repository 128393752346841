import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';

const HEADER_HISTORY_HEIGHT = 72;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    header: {
        paddingLeft: 24,
        paddingRight: 24,
        height: HEADER_HISTORY_HEIGHT,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    content: {
        height: `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT}px)`,
        padding: 22
    },
    expand: {
        flex: 1
    },
    backButton: {
        display: 'flex',
        cursor: 'pointer',
        marginRight: SPACING_LIST_NAME_WITH_ICON
    }
}));

function SidebarRightLayout({ onClose, onBack, children, title, contentClassName, ...rest }) {
    const classes = useStyles();

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root} {...rest}>
            <Grid item>
                <Grid container className={classes.header} alignItems="center">
                    {onBack && (
                        <Grid item className={classes.backButton} onClick={onBack}>
                            <ArrowLeftSVG />
                        </Grid>
                    )}
                    <Grid item className={classes.expand}>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <CloseIconSVG size={'medium'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={`${classes.content} ${contentClassName}`}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(SidebarRightLayout);
