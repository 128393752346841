import React from 'react';

function ReplaceSVG({ color = '#78778B' }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.3334 0H8.6666C8.3002 0 8 0.3 8 0.666602V4.6666C8 5.0332 8.3002 5.3332 8.6666 5.3332H15.3334C15.6998 5.3332 16 5.0332 16 4.6666V0.666602C16 0.3 15.6998 0 15.3334 0ZM14.6666 4H9.3334V1.3334H14.6666V4Z"
                fill={color}
            />
            <path
                d="M7.3334 10.6665H0.666602C0.300195 10.6665 0 10.9665 0 11.3329V15.3329C0 15.6997 0.300195 15.9997 0.666602 15.9997H7.3334C7.6998 15.9997 8 15.6997 8 15.3329V11.3329C8 10.9665 7.6998 10.6665 7.3334 10.6665ZM6.6666 14.6665H1.3334V11.9997H6.6666V14.6665Z"
                fill={color}
            />
            <path
                d="M4.66738 3.33296H6.6666V1.99976H4.66738C3.1948 1.99976 2 3.19315 2 4.66577V7.05718L0.942813 6.00054L0 6.94315L2.19539 9.13776C2.4552 9.39835 2.87758 9.39835 3.1382 9.13776L5.33359 6.94257L4.39059 5.99976L3.3334 7.05714L3.33398 4.66573C3.33398 3.92936 3.93102 3.33296 4.66738 3.33296Z"
                fill={color}
            />
            <path
                d="M13.8042 6.86155C13.5444 6.60116 13.122 6.60116 12.8614 6.86155L10.6662 9.05694L11.609 9.99956L12.6662 8.94218L12.6656 11.3336C12.6656 12.07 12.0686 12.6664 11.3322 12.6664H9.33301V13.9996H11.3322C12.8048 13.9996 13.9996 12.8062 13.9996 11.3336V8.94218L15.0568 9.99878L15.9996 9.05616L13.8042 6.86155Z"
                fill={color}
            />
        </svg>
    );
}

export default ReplaceSVG;
