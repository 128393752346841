import React from 'react';
import classnames from 'classnames';
import AutomationSVG from 'assets/images/svg/webHook/AutomationSVG';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useIsAutomationExecutionsRunning, useIsHightlightAutomations } from 'hooks/gridUI/automation';

const useStyles = makeStyles(theme => ({
    '@keyframes beatFlash': {
        '0%': {
            transform: 'scale(1)',
            opacity: 0.1
        },
        '50%': {
            transform: 'scale(1.25)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(1)',
            opacity: 0.1
        }
    },
    '@keyframes shake': {
        '0%': {
            transform: 'scale(1)',
            opacity: 0.1
        },
        '15%': {
            transform: 'scale(1.25)',
            opacity: 1
        },
        '30%': {
            transform: 'scale(1) rotate(-15deg)',
            opacity: 0.1
        },
        '45%': {
            transform: 'scale(1.25)',
            opacity: 1
        },
        '50%': {
            transform: 'scale(1) rotate(-15deg)'
        },
        '54%': {
            transform: 'rotate(15deg)'
        },
        '58%, 74%': {
            transform: 'rotate(-18deg)'
        },
        '62%, 78%': {
            transform: 'rotate(18deg)'
        },
        '66%': {
            transform: 'rotate(-22deg)'
        },
        '70%': {
            transform: 'rotate(22deg)'
        },
        '82%': {
            transform: 'rotate(-12deg)'
        },
        '90%, 100%': {
            transform: 'rotate(0deg)'
        }
    },
    animationShackFlash: {
        animation: `$shake 2s infinite`
    },
    badgeDot: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: theme.colors.burntSlena,
        border: `1px solid ${theme.colors.solitude}`,
        position: 'absolute',
        right: 5,
        top: 5
    }
}));

const SidebarRightAutomationIcon = () => {
    const classes = useStyles();
    const theme = useTheme();

    const isHightlightAutomations = useIsHightlightAutomations();
    const isAutomationExecutionsRunning = useIsAutomationExecutionsRunning();

    return (
        <>
            <AutomationSVG
                className={classnames({
                    [classes.animationShackFlash]: !!isAutomationExecutionsRunning
                })}
                color={theme.colors.darkness}
            />
            {!!isHightlightAutomations && <div className={classes.badgeDot} />}
        </>
    );
};

export default React.memo(SidebarRightAutomationIcon);
