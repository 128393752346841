import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InputText from 'components/inputs/InputText';
import { useTranslation } from 'react-i18next';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { useDispatch } from 'react-redux';
import { updateActionNode } from '../../action';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { ACTION_STATUS } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    flex: { display: 'flex' },
    mb12: {
        marginBottom: 12
    },
    addHeader: {
        color: theme.palette.primary.main
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

const OutputFieldsWebRequest = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const { params = {} } = selectedNode;
    const [outputFields, setOutputFields] = useState(params?.outputFields || []);
    const timeout = useRef();

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        setOutputFields(params?.outputFields || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const updateOutputFields = useCallback(
        (newOutputFields, timeoutSecond = 800) => {
            const oldOutputFields = JSON.parse(JSON.stringify(outputFields));
            setOutputFields([...newOutputFields]);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                outputFields: newOutputFields
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setOutputFields(oldOutputFields);
                        }
                    })
                );
            }, timeoutSecond);
        },
        [automationDetail.id, dispatch, outputFields, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const onOutputFieldChange = useCallback(
        (value, idx) => {
            outputFields[idx] = value;
            updateOutputFields(outputFields);
        },
        [outputFields, updateOutputFields]
    );

    const onRemoveOutputField = useCallback(
        idx => {
            outputFields.splice(idx, 1);
            updateOutputFields(outputFields, 100);
        },
        [outputFields, updateOutputFields]
    );

    const onAddOutputField = useCallback(() => {
        outputFields.push('');
        updateOutputFields(outputFields, 100);
    }, [outputFields, updateOutputFields]);

    return (
        <Grid item container direction="column">
            <Grid item className={classes.mb12}>
                <p className="body1">{t('automation_output_fields')}</p>
            </Grid>
            <TooltipActionDisable isAction disabled={isDisabled}>
                {outputFields.map((output, index) => (
                    <Grid item container key={index} className={classes.mb12}>
                        <Grid item style={{ flex: 1 }}>
                            <InputText
                                value={output}
                                onChange={e => onOutputFieldChange(e.target.value, index)}
                                placeholder={t('automation_output_fields')}
                            />
                        </Grid>
                        <Grid item style={{ width: 20 }}>
                            <IconButton
                                style={{ marginTop: 8 }}
                                size="small"
                                onClick={() => onRemoveOutputField(index)}
                            >
                                <CloseIconSVG size="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Grid item container spacing={1}>
                    <Grid item className={classes.flex} onClick={onAddOutputField} style={{ cursor: 'pointer' }}>
                        <ColorAddSVG />
                    </Grid>
                    <Grid item>
                        <p className={'body1 inline cursor-pointer text-text-primary'} onClick={onAddOutputField}>
                            {t('automation_add_value')}
                        </p>
                    </Grid>
                </Grid>
            </TooltipActionDisable>
        </Grid>
    );
};

export default React.memo(OutputFieldsWebRequest);
