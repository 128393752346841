import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SearchList from './SearchList';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        minHeight: '600px'
    },
    notDisplay: {
        opacity: 0,
        overflow: 'hidden'
    }
}));

function SearchSidebar({ open }) {
    const classes = useStyles();

    return (
        <Grid container className={`${classes.root} ${!open && classes.notDisplay}`} direction="column">
            <SearchList />
        </Grid>
    );
}

export default SearchSidebar;
