import * as types from '../types';

export function turnOnRecordPanel() {
    return {
        type: types.TURN_ON_RECORD_PANEL
    };
}

export function turnOffRecordPanel() {
    return {
        type: types.TURN_OFF_RECORD_PANEL
    };
}
