import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HEADER_HEIGHT } from 'const';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { getAuthShareLinkApi } from 'services/shareLink';
import * as appActions from 'app/actions';
import NotFoundIllustrationSVG from 'assets/images/svg/NotFoundIllustrationSVG';
import { _setCompanyId } from 'auth/actions';
import * as advancedSearchActions from 'advancedSearch/actions';
import { ADVANCED_SEARCH_RECORD_LIMIT } from 'const/gridUI';
import Spinner from 'components/spinner/Base';
import { getAdvancedSearchApi, getAdvancedSearchRecordsApi } from 'services/advanced';
import TableWrapper from 'advancedSearch/grid';

const useStyles = makeStyles(theme => ({
    root: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    fw400: {
        fontWeight: 400
    },
    disabledText: {
        color: theme.colors.midGrey,
        fontWeight: 'normal'
    }
}));

function ShareLinkView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { key } = useParams();
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        const getShareSearchDetail = async () => {
            setIsLoading(true);
            try {
                const authShare = await getAuthShareLinkApi({ shareKey: key });
                if (!authShare) throw new Error('not working');
                dispatch(_setCompanyId({ companyId: authShare?.cid }));

                const [workspaceId, advancedSearchId] = authShare?.rid?.split('_');

                if (!workspaceId || !advancedSearchId) throw new Error('not working');

                dispatch(
                    advancedSearchActions.setRouterInfo({
                        workspaceId
                    })
                );

                dispatch(advancedSearchActions.setSelectedAdvancedSearchId(advancedSearchId));
                dispatch(appActions.saveShareKeyId({ key }));

                const [advancedSearchDetailRes, advancedSearchRecordsRes] = await Promise.all([
                    await getAdvancedSearchApi({ wsId: workspaceId, advancedSearchId }),
                    await getAdvancedSearchRecordsApi({
                        wsId: workspaceId,
                        advancedSearchId,
                        body: {
                            limit: ADVANCED_SEARCH_RECORD_LIMIT,
                            lastId: null
                        }
                    })
                ]);

                dispatch(advancedSearchActions._fetchAdvancedSearchRecordsSuccess(advancedSearchRecordsRes));
                dispatch(advancedSearchActions._fetchAdvancedSearch(advancedSearchDetailRes));

                setIsLoading(false);
            } catch ({ message }) {
                console.log('message', message);
                setHasError(true);
                setIsLoading(false);
            }
        };
        if (key) {
            getShareSearchDetail();
        }

        return () => {
            dispatch(appActions.saveShareKeyId({ key: null }));
        };
    }, [key, dispatch]);

    if (isLoading)
        return (
            <Grid
                className={classes.root}
                direction="column"
                spacing={3}
                container
                alignItems="center"
                justify="center"
            >
                <Grid item>
                    <Spinner size={40} thick={4} />
                </Grid>
            </Grid>
        );

    if (hasError && !isLoading)
        return (
            <Grid
                className={classes.root}
                direction="column"
                spacing={3}
                container
                alignItems="center"
                justify="center"
            >
                <Grid item>
                    <NotFoundIllustrationSVG />
                </Grid>
                <Grid item>
                    <Typography className={classes.fw400} variant="h3">
                        Oops! Looks like you get lose
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" className={classes.disabledText}>
                        Sorry we can't find the page you are looking for.
                    </Typography>
                </Grid>
            </Grid>
        );

    return (
        <Grid container className={classes.root}>
            <TableWrapper isSideBarOn={false} />
        </Grid>
    );
}

export default React.memo(ShareLinkView);
