import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {
        width: 720
    },
    btnSubmit: {
        position: 'relative',
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? hexToRgba(props.buttonColor, 0.9) : '')
            }
        }
    }
}));

const AutomationActionSelectDisable = ({ item, isHavingBillingPersmission, onClose }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        onClose();
        if (isHavingBillingPersmission) {
            const headerAddonEl = document.getElementById('header-addon');
            if (headerAddonEl) {
                headerAddonEl.click();
                setTimeout(() => {
                    const automationActionsEl = document.getElementById('addon-automation-actions');
                    if (automationActionsEl) {
                        automationActionsEl.click();
                    }
                    setTimeout(() => {
                        const addonEl = document.getElementById(`addon-item-${item?.addonType}`);
                        if (addonEl) {
                            addonEl.click();
                        }
                    }, 0);
                }, 0);
            }
        }
    }, [isHavingBillingPersmission, item, onClose]);

    return (
        <Grid item className={classes.root}>
            <DialogTitle
                title={<h4 className="prose prose-lg font-medium">{t('automation_action_not_installed')}</h4>}
                onClose={onClose}
            />
            <DialogContent>
                <div variant="body2">
                    {t(
                        isHavingBillingPersmission
                            ? 'automation_action_not_installed_permission'
                            : 'automation_action_not_installed_no_permission'
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    {isHavingBillingPersmission && (
                        <Grid item>
                            <ButtonBase onClick={onClose} variant="outlined">
                                {t('global_dismiss')}
                            </ButtonBase>
                        </Grid>
                    )}
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase fullWidth onClick={onClick} variant="contained">
                                {t(isHavingBillingPersmission ? t('automation_go_to_add_on') : 'OK')}
                            </ButtonBase>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </Grid>
    );
};

export default React.memo(AutomationActionSelectDisable);
