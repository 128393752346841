import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    createViewRecordApi,
    deleteViewRecordsApi,
    getViewRecordsApiV2,
    setViewRecords,
    getExistViewRecordsApi,
    deleteRecordsApi,
    deleteAllRecordsApi
} from 'services/view';
import { reorderRecordsApi } from 'services/grid';
import { formatQuickFilters } from 'utils/gridUI/filter';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import * as optimisticActions from './optimistic';
import * as recordHistoryActions from './recordHistory';
import uuidv1 from 'uuid/v1';
import * as statusActions from './status';
import {
    getAllExistingEditableColumnIds,
    getExactColumns,
    getDisableColumnIdsByType,
    getDisabledColumnIds,
    getReferenceDisabledColumns,
    getViewColumnsWithReorderAndLanguagePairs
} from 'utils/gridUI/column';
import { getDependencyColumnIds } from 'utils/gridUI/dependency';
import * as aggregationActions from './aggregation';
import { generateSystemData, combinedData } from 'utils/gridUI/data';
import * as columnTypes from 'const/columnTypes';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as gridUIActions from './gridUI';
import * as pathTagActions from './pathTag';
import * as dataActions from './data';
import * as rangeActions from './range';
import { getCellData } from 'utils/gridUI/cell';
import { SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG, HEADER_HEIGHT } from 'const';
import now from 'performance-now';
import { getViewport } from '../calculatePosition';
import { getTotalRowsHeight } from 'utils/gridUI/row';
import { findTagByPathId } from 'utils/gridUI/pathTag';
import {
    checkAndGenerateRange,
    mergeRangesOverlap,
    getRowSelectedIndexes,
    getTotalSelectedRowsByRange,
    generateServerRowRange,
    getRemainingRowIds,
    getRecordIdsInRowRangeIndexes
} from 'utils/gridUI/range';
import { isLDEmpty, removeArrayInArray } from 'utils/object';
import {
    RECORDS_OFFSET_BOTTOM,
    RECORDS_OFFSET_TOP,
    GLOBAL_FILTER_HEIGHT,
    RECORDS_RENDER,
    FAKE_ROW,
    CREATE_RECORD_TYPES,
    MAX_SELECTION_RECORDS,
    DATA_QUERY_OPTIONS,
    RANGE_TYPES
} from 'const/gridUI';
import { sendManualTrack } from 'tracker';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

import axios from 'axios';
import { resetSelection } from '.';
import i18n from 'i18n';
const CancelToken = axios.CancelToken;
let sourceCancel = null;

export function deleteViewRecords({ recordIds, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId, rows, recordHistoryId } = gridUI;
        if (recordIds.includes(recordHistoryId)) {
            dispatch(recordHistoryActions.closeRecordHistory());
        }

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(
            deleteViewRecordsAction({
                recordIds: rows?.filter(recordId => !recordIds?.includes(recordId)),
                deletedCount: recordIds?.length
            })
        );
        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_DELETE_RECORD,
                body: {
                    recordIds,
                    indexes: recordIds.map(rowId => rows.findIndex(index => index === rowId))
                }
            })
        );

        try {
            await deleteViewRecordsApi({ dbId, defaultAccessViewId, data: { ids: recordIds } });
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            return errorCallback && errorCallback(message);
        }
    };
}

export function deleteViewRecordsAction({ recordIds, deletedCount }) {
    return {
        type: types.DELETE_ROWS,
        payload: {
            recordIds,
            deletedCount
        }
    };
}

export function createRecordWithPathTag({ fullPathId, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tree } = gridUI;

        const nodes = findTagByPathId({ tree, fullPathId });
        if (!nodes) {
            console.log('there is some finding problem');
            return;
        }
        const path = nodes?.map(node => node?.name)?.join('/');
        dispatch(
            createViewRecord({
                recordCount: 1,
                columnDataOutside: {
                    _pathTag: path
                },
                successCallback,
                errorCallback
            })
        );
    };
}

export function createRecordsRelative({
    recordCount = 1,
    columnDataOutside = {},
    rowIndex,
    type = CREATE_RECORD_TYPES.ABOVE,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const { viewColumns, dbId, defaultAccessViewId, ROW_START_INDEX, rows } = gridUI;
        const currentUser = auth.currentUser;
        const possibleColumnIds = getAllExistingEditableColumnIds(viewColumns);

        let columnData = { ...columnDataOutside };
        const pathTagQuickFilter = gridUI.quickFilters?.[columnTypes?.PATH_TAG];
        if (pathTagQuickFilter?.value) {
            const columnDataFiltered = { _pathTag: pathTagQuickFilter.value };
            columnData = { ...columnDataFiltered, ...columnDataOutside };
        }
        const defaultRecordsInfo = possibleColumnIds.map(columnId => columnData?.[columnId] || null);

        let body = {
            columns: possibleColumnIds,
            records: [defaultRecordsInfo],
            createRecordPosition: 'relative'
        };

        const selectedRecordId = rows?.[Math.max(rowIndex - ROW_START_INDEX, 0)];

        if (type === CREATE_RECORD_TYPES.ABOVE) {
            body = {
                ...body,
                createBeforeRecordId: selectedRecordId
            };
        } else {
            body = {
                ...body,
                createAfterRecordId: selectedRecordId
            };
        }

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_createViewRecordsAction());
        try {
            const { recordIds, columnIds, data: newData } = await createViewRecordApi({
                dbId,
                defaultAccessViewId,
                body
            });

            if (!recordIds?.length) {
                dispatch(
                    enqueueSnackbar({
                        message: `[createViewRecord] Something is wrong`,
                        type: 'info'
                    })
                );
            } else {
                dispatch(
                    _createRecordsRelativeSuccess({
                        recordIds,
                        type,
                        selectedRecordId
                    })
                );

                const systemData = generateSystemData({
                    viewColumns,
                    editedUser: currentUser,
                    recordIds,
                    columnIds,
                    isCreate: true
                });

                const convertData = combinedData({ data: systemData, newData });
                dispatch(dataActions.updateData({ newData: convertData }));
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createViewRecordsActionFailed({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

function _createRecordsRelativeSuccess({ recordIds, type, selectedRecordId }) {
    return {
        type: types.CREATE_RELATIVE_RECORDS_SUCCESS,
        payload: {
            type,
            recordIds,
            selectedRecordId
        }
    };
}

export function createViewRecord({ recordCount = 1, columnDataOutside = {}, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const { viewColumns, dbId, defaultAccessViewId, tableInfo, rowHeight, totalRecords } = gridUI;
        const currentUser = auth.currentUser;
        const possibleColumnIds = getAllExistingEditableColumnIds(viewColumns);
        const { scroll } = tableInfo;

        let columnData = { ...columnDataOutside };
        const pathTagQuickFilter = gridUI.quickFilters?.[columnTypes?.PATH_TAG];
        if (pathTagQuickFilter?.value) {
            const columnDataFiltered = { _pathTag: pathTagQuickFilter.value };
            columnData = { ...columnDataFiltered, ...columnDataOutside };
        }

        const defaultRecordsInfo = possibleColumnIds.map(columnId => columnData?.[columnId] || null);
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_createViewRecordsAction());
        try {
            const { recordIds, columnIds, data: newData } = await createViewRecordApi({
                dbId,
                defaultAccessViewId,
                body: {
                    columns: possibleColumnIds,
                    records: [defaultRecordsInfo]
                }
            });

            if (!recordIds?.length) {
                dispatch(
                    enqueueSnackbar({
                        message: `[createViewRecord] Something is wrong`,
                        type: 'info'
                    })
                );
            } else {
                dispatch(
                    _createViewRecordsActionSuccess({
                        recordIds,
                        isScroll: true
                    })
                );

                const systemData = generateSystemData({
                    viewColumns,
                    editedUser: currentUser,
                    recordIds,
                    columnIds,
                    isCreate: true
                });

                const convertData = combinedData({ data: systemData, newData: newData });
                dispatch(dataActions.updateData({ newData: convertData }));

                const newTotalRowHeights = getTotalRowsHeight({
                    rowHeight,
                    totalRecords: totalRecords - FAKE_ROW - recordCount
                });

                const [, viewPortHeight] = getViewport();

                const heightWindowOffset = viewPortHeight - HEADER_HEIGHT - GLOBAL_FILTER_HEIGHT - 3 * rowHeight;
                if (newTotalRowHeights > heightWindowOffset) {
                    scroll({ scrollTop: newTotalRowHeights });
                }
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createViewRecordsActionFailed({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

export function addViewRecordsBelow({ count, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const { viewColumns, dbId, defaultAccessViewId } = gridUI;
        const currentUser = auth.currentUser;
        const possibleColumnIds = getAllExistingEditableColumnIds(viewColumns);

        let columnData = {};
        const pathTagQuickFilter = gridUI.quickFilters?.[columnTypes?.PATH_TAG];
        if (pathTagQuickFilter?.value) {
            columnData = { _pathTag: pathTagQuickFilter.value };
        }

        const defaultRecordsInfo = possibleColumnIds.map(columnId => columnData?.[columnId] || null);

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_createViewRecordsAction());

        try {
            const { recordIds, columnIds, data: newData } = await createViewRecordApi({
                dbId,
                defaultAccessViewId,
                body: {
                    columns: possibleColumnIds,
                    records: [...new Array(count)].map(r => defaultRecordsInfo)
                }
            });

            if (!recordIds?.length) {
                dispatch(
                    enqueueSnackbar({
                        message: `[createViewRecord] Something is wrong`,
                        type: 'info'
                    })
                );
            } else {
                dispatch(
                    _createViewRecordsActionSuccess({
                        recordIds,
                        isScroll: false
                    })
                );

                const systemData = generateSystemData({
                    viewColumns,
                    editedUser: currentUser,
                    recordIds,
                    columnIds,
                    isCreate: true
                });

                const convertData = combinedData({ data: systemData, newData: newData });
                dispatch(dataActions.updateData({ newData: convertData }));
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('insert_successful'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createViewRecordsActionFailed({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

export function _populateSystemDataAfterRecordModified({ recordIds = [], systemData }) {
    return {
        type: types.POPULATE_SYSTEM_DATA_AFTER_RECORD_MODIFIED,
        payload: {
            recordIds,
            systemData
        }
    };
}

function _createViewRecordsAction() {
    return {
        type: types.CREATE_ROW
    };
}

function _createViewRecordsActionSuccess({ recordIds, isScroll }) {
    return {
        type: types.CREATE_ROW_SUCCESS,
        payload: {
            recordIds,
            isScroll
        }
    };
}

function _createViewRecordsActionFailed({ error }) {
    return {
        type: types.CREATE_ROW_FAILED,
        payload: {
            error
        }
    };
}

export function createRowsSocket({ recordIds }) {
    return {
        type: types.CREATE_ROWS_SOCKET,
        payload: {
            recordIds
        }
    };
}

export function _toggleDeleteRecordState() {
    return {
        type: types.TOGGLE_DELETING_RECORDS
    };
}

export function checkBulkOrNormalDelete({ callback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { rows, rowsRangeIndexes, rowIndexMap, rowStartIndex, rowStopIndex } = gridUI;

        const rangeIndexes = rowsRangeIndexes?.flat();
        const isRowsSelected = rangeIndexes?.length > 0;

        let startRowIndex = -1;
        let stopRowIndex = -1;

        if (isRowsSelected) {
            startRowIndex = Math.min(...rangeIndexes);
            stopRowIndex = Math.max(...rangeIndexes);
        } else {
            startRowIndex = rowStartIndex;
            stopRowIndex = rowStopIndex;
        }

        const rowStartId = rowIndexMap?.[startRowIndex];
        const rowStopId = rowIndexMap?.[stopRowIndex];

        const isUseStoreData = rowStartId && rowStopId && rows?.includes(rowStartId) && rows?.includes(rowStopId);

        callback && callback({ isUseStoreData });
    };
}

export function deleteViewRecordsByIndex({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const {
            dbId,
            defaultAccessViewId,
            rows,
            rowsRangeIndexes,
            rowIndexMap,
            quickFilters,
            quickSorts,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            tableInfo,
            viewColumns,
            dependencies,
            totalRecords,
            rowStartIndex,
            rowStopIndex
        } = gridUI;

        const rangeIndexes = rowsRangeIndexes?.flat();
        const isRowsSelected = rangeIndexes?.length > 0;
        const isShowAutoQA = getIsShowAutoQA();

        let startRowIndex = -1;
        let stopRowIndex = -1;

        if (isRowsSelected) {
            startRowIndex = Math.min(...rangeIndexes);
            stopRowIndex = Math.max(...rangeIndexes);
        } else {
            startRowIndex = Math.min(rowStartIndex, rowStopIndex);
            stopRowIndex = Math.max(rowStartIndex, rowStopIndex);
        }

        const rowStartId = rowIndexMap?.[startRowIndex];
        const rowStopId = rowIndexMap?.[stopRowIndex];

        const isUseStoreData = rowStartId && rowStopId && rows?.includes(rowStartId) && rows?.includes(rowStopId);
        if (isUseStoreData) {
            let recordIds = isRowsSelected
                ? getRecordIdsInRowRangeIndexes({ ranges: rowsRangeIndexes, ROW_START_INDEX, rows })
                : rows?.slice(
                      rows?.findIndex(rowId => rowId === rowStartId),
                      rows?.findIndex(rowId => rowId === rowStopId) + 1
                  );
            dispatch(dataActions.deleteRecordsData(recordIds));
            return dispatch(
                deleteViewRecords({
                    recordIds,
                    errorCallback: () => {
                        console.log('delete stored records failed');
                    },
                    successCallback
                })
            );
        }

        let body = {};

        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        if (!isLDEmpty(quickFiltersFormatted)) {
            body = {
                ...body,
                query: JSON.stringify(quickFiltersFormatted)
            };
        }

        if (!isLDEmpty(quickSorts)) {
            body = {
                ...body,
                sort: JSON.stringify(quickSorts)
            };
        }

        let totalDeletedRows = 0;
        let deletedIndexes = [];

        if (isRowsSelected) {
            const deletedRanges = [...rowsRangeIndexes];
            totalDeletedRows = getTotalSelectedRowsByRange(deletedRanges);
            body = {
                ...body,
                recordIdRanges: generateServerRowRange({ ranges: deletedRanges })
            };
            deletedIndexes = getRowSelectedIndexes({ rangeIndexes: rowsRangeIndexes });
        } else {
            totalDeletedRows = Math.max(rowStopIndex - rowStartIndex + 1, 0);
            body = {
                ...body,
                recordIdRanges: [
                    {
                        fromIndex: Math.min(rowStartIndex + 1, rowStopIndex + 1),
                        toIndex: Math.max(rowStartIndex + 1, rowStopIndex + 1)
                    }
                ]
            };
            for (let i = rowStartIndex; i <= rowStopIndex; i++) {
                deletedIndexes.push(i);
            }
        }

        try {
            await deleteRecordsApi({ dbId, defaultAccessViewId, body });
            const { remainingRecordIds } = getRemainingRowIds({
                rows,
                deletedIndexes,
                ROW_START_INDEX,
                ROW_STOP_INDEX
            });

            dispatch(statusActions.registerDoingAction({ actionId }));
            dispatch(_toggleDeleteRecordState());
            const { gridRef } = tableInfo;
            const { _rowStartIndex, _rowStopIndex } = gridRef;

            const newTotalRecords = totalRecords - totalDeletedRows;
            const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
            dispatch(_deleteRecordByIndexes({ remainingRecordIds, count: totalDeletedRows }));
            dispatch(_fetchMoreRowsAction());

            const { recordIds, columnIds, recordMetaData, data } = await getViewRecordsApiV2({
                defaultAccessViewId,
                dbId,
                offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                filterQuery: quickFiltersFormatted,
                sortQuery: quickSorts,
                dependencyColumnIds,
                isShowAutoQA
            });

            dispatch(
                _fetchMoreRowsActionSuccess({
                    columns: columnIds,
                    rows: recordIds,
                    data,
                    totalRecords: newTotalRecords,
                    ROW_START_INDEX: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER)
                })
            );

            dispatch(
                updateRecordMetaData({
                    newRecordMetaData: recordMetaData
                })
            );
            dispatch(_toggleDeleteRecordState());
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchMoreRowsActionFailed({ error }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(_toggleDeleteRecordState());
            return errorCallback && errorCallback(message);
        }
    };
}

export function _deleteRecordByIndexes({ remainingRecordIds, count }) {
    return {
        type: types.SET_ROW_IDS,
        payload: {
            recordIds: remainingRecordIds,
            count
        }
    };
}

export function rowSelection({ rowId, rowIndex, isShift, isCtrl }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { rowsRangeIndexes, rows, oldRowIndexSelected, rowIndexMap } = gridUI;

        let newRowsRangeIndexes = JSON.parse(JSON.stringify(rowsRangeIndexes));
        let newIndexRowMap = {
            ...rowIndexMap
        };

        //normal click
        if (!isCtrl && !isShift) {
            newRowsRangeIndexes = [[rowIndex]];
            newIndexRowMap = {
                [rowIndex]: rowId
            };
            //click + ctrl
        } else if (isCtrl) {
            const { isInRange, rangeIndex } = checkAndGenerateRange({ ranges: newRowsRangeIndexes, rowIndex });

            if (!isInRange) {
                newRowsRangeIndexes = [...rowsRangeIndexes, [rowIndex]];
                newIndexRowMap = {
                    ...newIndexRowMap,
                    [rowIndex]: rowId
                };
            } else {
                const foundRange = [...new Set(newRowsRangeIndexes?.[rangeIndex])];
                const restRowsRangeIndexes = newRowsRangeIndexes?.filter((range, index) => index !== rangeIndex);

                if (foundRange?.length === 1) {
                    newRowsRangeIndexes = [...restRowsRangeIndexes];
                } else {
                    const [start, stop] = foundRange;
                    newRowsRangeIndexes = [
                        ...restRowsRangeIndexes,
                        start !== rowIndex ? [...new Set([start, Math.max(0, rowIndex - 1)])] : [],
                        stop !== rowIndex ? [...new Set([Math.min(rowIndex + 1, stop), stop])] : []
                    ];

                    const findIndex = rows?.findIndex(id => id === rowId);
                    newIndexRowMap = {
                        ...newIndexRowMap,
                        [rowIndex]: rowId,
                        [rowIndex - 1]: rows?.[Math.max(findIndex - 1, 0)],
                        [rowIndex + 1]: rows?.[findIndex + 1]
                    };
                }
            }
            //click + shift key
        } else {
            const newRange =
                rowIndex > oldRowIndexSelected ? [oldRowIndexSelected, rowIndex] : [rowIndex, oldRowIndexSelected];

            const overlapRanges = newRowsRangeIndexes?.filter(range => {
                const [startNewRange, stopNewRange] = newRange;
                if (range?.length === 1) {
                    if (range?.[0] >= startNewRange && range?.[0] <= stopNewRange) return false;
                    return true;
                } else {
                    const [start, stop] = range;

                    return !(startNewRange <= start && stopNewRange >= stop);
                }
            });

            newRowsRangeIndexes = [...overlapRanges, newRange];

            newIndexRowMap = {
                ...newIndexRowMap,
                [rowIndex]: rowId
            };
        }

        dispatch(
            _rowSelection({
                rowsRangeIndexes: newRowsRangeIndexes,
                oldRowIndexSelected: rowIndex
            })
        );

        dispatch(rangeActions.setRowIndexMap({ rowIndexMap: newIndexRowMap }));
    };
}

export function _rowSelection({ rowsRangeIndexes, oldRowIndexSelected, isOverride = false }) {
    return async function(dispatch, getState) {
        const { auth, gridUI } = getState();
        const viewColumns = getViewColumnsWithReorderAndLanguagePairs({ auth, gridUI });

        const url = new URL(window.location);
        const rangeIndexes = rowsRangeIndexes?.flat();
        const startRowIndex = Math.min(...rangeIndexes);
        const stopRowIndex = Math.max(...rangeIndexes);

        const _rowStartIndex = Math.min(startRowIndex, stopRowIndex);
        const _rowStopIndex = Math.max(startRowIndex, stopRowIndex);
        const _columnStartIndex = 0;
        const _columnStopIndex = Math.max(viewColumns?.length - 1, 0);

        const range = `r${_rowStartIndex + 1}:r${_rowStopIndex + 1}-c${_columnStartIndex + 1}:c${_columnStopIndex + 1}`;
        url.search = `?find=${range}`;
        window.history.pushState({}, '', url);

        dispatch({
            type: types.ROW_SELECTION,
            payload: {
                oldRowIndexSelected,
                rowsRangeIndexes: isOverride ? rowsRangeIndexes : mergeRangesOverlap(rowsRangeIndexes)
            }
        });
    };
}

export function _resetRowData() {
    return {
        type: types.RESET_ROW_DATA
    };
}

export function fetchRecordsWithFiltersAndSorts({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchRecordsWithFiltersSortsAction());
        const { gridUI } = getState();
        const isShowAutoQA = getIsShowAutoQA();

        try {
            const { quickFilters, quickSorts, advanced_filters, advanced_sorts, defaultAccessViewId, dbId } = gridUI;

            const quickFiltersFormatted = formatQuickFilters(quickFilters);
            let filterQuery = merge(quickFiltersFormatted, advanced_filters);
            let sortQuery = merge(quickSorts, advanced_sorts);
            const {
                recordIds,
                recordMetaData,
                data,
                totalRecords,
                columnIds,
                totalRecordsWithoutFilters
            } = await getViewRecordsApiV2({
                defaultAccessViewId,
                dbId,
                offset: 0,
                limit: RECORDS_RENDER,
                filterQuery,
                sortQuery,
                isShowAutoQA
            });

            dispatch(
                aggregationActions.reFetchAggregations({
                    successCallback: () => {
                        console.log('reFetch agg success');
                    },
                    errorCallback: () => {
                        console.log('reFetch agg failed');
                    }
                })
            );

            dispatch(_resetRowData());
            dispatch(
                _fetchRecordsWithFiltersSortsActionSuccess({
                    columns: columnIds,
                    rows: recordIds,
                    data,
                    totalRecords,
                    recordMetaData,
                    totalRecordsWithoutFilters
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchRecordsWithFiltersSortsActionFailed({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

function _fetchRecordsWithFiltersSortsAction() {
    return {
        type: types.FETCH_RECORDS_WITH_FILTERS_SORTS
    };
}

function _fetchRecordsWithFiltersSortsActionFailed({ error }) {
    return {
        type: types.FETCH_RECORDS_WITH_FILTERS_SORTS_FAILED,
        payload: {
            error
        }
    };
}

export function _fetchRecordsWithFiltersSortsActionSuccess({
    columns,
    rows,
    data,
    totalRecords,
    recordMetaData,
    totalRecordsWithoutFilters
}) {
    return {
        type: types.FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS,
        payload: {
            columns,
            rows,
            data,
            totalRecords,
            ROW_START_INDEX: 0,
            ROW_STOP_INDEX: RECORDS_RENDER,
            recordMetaData,
            totalRecordsWithoutFilters
        }
    };
}

export function fetchMoreRows({
    defaultAccessViewId: defaultAccessViewIdParam,
    dbId: dbIdParam,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    errorCallback,
    successCallback
}) {
    return async function(dispatch, getState) {
        const start = now();

        if (sourceCancel) {
            sourceCancel.cancel();
        }

        sourceCancel = CancelToken.source();

        // dispatch(setSectionScroll({ ROW_START_INDEX, ROW_STOP_INDEX }));
        dispatch(_fetchMoreRowsAction());
        const { gridUI } = getState();
        const {
            quickFilters,
            quickSorts,
            advanced_filters,
            advanced_sorts,
            viewColumns,
            dependencies,
            defaultAccessViewId: defaultAccessViewIdStore,
            dbId: dbIdStore
        } = gridUI;

        const dbId = dbIdParam || dbIdStore;
        const defaultAccessViewId = defaultAccessViewIdParam || defaultAccessViewIdStore;

        const isShowAutoQA = getIsShowAutoQA();

        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        let filterQuery = merge(quickFiltersFormatted, advanced_filters);
        let sortQuery = merge(quickSorts, advanced_sorts);
        const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
        try {
            const {
                recordIds,
                columnIds,
                recordMetaData,
                data,
                totalRecords,
                totalRecordsWithoutFilters
            } = await getViewRecordsApiV2({
                defaultAccessViewId,
                dbId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                filterQuery,
                sortQuery,
                dependencyColumnIds,
                isShowAutoQA,
                token: sourceCancel?.token
            });

            let end = now();
            console.log('CALCULATE FETCHING RECORDS', end - start);

            dispatch(
                _fetchMoreRowsActionSuccess({
                    columns: columnIds,
                    rows: recordIds,
                    data,
                    totalRecords,
                    ROW_START_INDEX,
                    ROW_STOP_INDEX,
                    totalRecordsWithoutFilters
                })
            );

            dispatch(
                updateRecordMetaData({
                    newRecordMetaData: recordMetaData
                })
            );

            setTimeout(() => {
                sendManualTrack({
                    type: `Fetch more`,
                    customData: {
                        render_time: (end - start).toFixed(3)
                    }
                });
            }, 0);

            successCallback && successCallback({ rows: recordIds, data, recordMetaData });
        } catch (error) {
            const { message } = error;

            if (message) {
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
            }

            // dispatch(_fetchMoreRowsActionFailed({ error: message }));

            return errorCallback && errorCallback(message);
        }
    };
}

export function _fetchMoreRowsAction() {
    return {
        type: types.FETCH_MORE_ROWS
    };
}

function _fetchMoreRowsActionFailed({ error }) {
    return {
        type: types.FETCH_MORE_ROWS_FAILED,
        payload: {
            error
        }
    };
}

export function _fetchMoreRowsActionSuccess({
    columns,
    rows,
    data,
    totalRecords,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    totalRecordsWithoutFilters
}) {
    console.log('fetch more', { columns, rows, data, totalRecords, ROW_START_INDEX, ROW_STOP_INDEX });
    return {
        type: types.FETCH_MORE_ROWS_SUCCESS,
        payload: {
            columns,
            rows,
            data,
            totalRecords,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            totalRecordsWithoutFilters
        }
    };
}

export function updateRecordMetaData({ newRecordMetaData }) {
    return {
        type: types.UPDATE_RECORD_METADATA,
        payload: {
            newRecordMetaData
        }
    };
}

export function deleteRowsValue() {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            rowsSelected,
            viewColumns,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            data,
            defaultAccessViewId,
            dbId,
            metaData
        } = gridUI;
        const viewColumnIds = viewColumns.map(vCol => vCol.id);
        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });
        const referenceDisabledColumns = getReferenceDisabledColumns({ gridUI, auth });
        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });
        let remainColumnCanDelete = removeArrayInArray(viewColumnIds, [
            ...disabledCols,
            ...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG
        ]);

        if (isEmpty(remainColumnCanDelete)) {
            console.log('Can not delete Disabled columns');
            return;
        }

        dispatch(statusActions.registerDoingAction({ actionId }));
        const newColumns = ['_recordId', ...remainColumnCanDelete];

        const deletedData = {};
        const undoDeletedData = {};

        const serverData = [];

        for (const rowId of rowsSelected) {
            const rowServerData = [rowId];

            for (const columnId of remainColumnCanDelete) {
                const cellData = getCellData({ data, rowId, columnId });

                deletedData[rowId] = {
                    ...deletedData[rowId],
                    [columnId]: {
                        ...cellData,
                        value: null
                    }
                };
                undoDeletedData[rowId] = {
                    ...undoDeletedData[rowId],
                    [columnId]: cellData
                };
                rowServerData.push(null);
            }
            serverData.push(rowServerData);
        }

        dispatch(dataActions.updateData({ newData: deletedData }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_DELETE_RANGE_SELECTION,
                body: {
                    oldDeletedData: undoDeletedData
                }
            })
        );

        const body = {
            columns: newColumns,
            records: serverData
        };

        try {
            await setViewRecords({
                defaultAccessViewId,
                dbId,
                body
            });

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
        }
    };
}

export function turnOnDraggingRows() {
    return {
        type: types.TURN_ON_DRAGGING_ROWS
    };
}

export function turnOffDraggingRows() {
    return {
        type: types.TURN_OFF_DRAGGING_ROWS
    };
}

export function fetchOtherReferenceDataColumns({ refColumnIds, recordIds }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            viewColumns,
            metaData,
            dbId,
            defaultAccessViewId,
            tableInfo,
            quickFilters,
            dependencies,
            quickSorts,
            rows
        } = gridUI;
        const isShowAutoQA = getIsShowAutoQA();

        const referenceBranchIds = refColumnIds?.map(colId => metaData?.[colId]?.referenceSettings?.referencedBranchId);

        const columnIds = getExactColumns(viewColumns)?.filter(colId => !refColumnIds?.includes(colId));

        const otherRefColIds = columnIds?.filter(columnId => {
            const column = metaData?.[columnId];

            const columnType = getCorrectColumnType(column);
            if (columnType !== columnTypes.REFERENCE) return false;
            const refBranchIdInside = column?.referenceSettings?.referencedBranchId;
            if (!referenceBranchIds?.includes(refBranchIdInside)) return false;
            return true;
        });

        if (!otherRefColIds?.length) return;

        const rowStartId = recordIds?.[0];
        const rowStopId = recordIds?.[recordIds?.length - 1];

        const isUseStoreData = rowStartId && rowStopId && rows?.includes(rowStartId) && rows?.includes(rowStopId);

        try {
            if (isUseStoreData || recordIds?.length === 1) {
                const { data } = await getExistViewRecordsApi({
                    dbId,
                    viewId: defaultAccessViewId,
                    columnIds: otherRefColIds,
                    recordIds
                });

                dispatch(dataActions.updateData({ newData: data, isCareData: true }));
            } else {
                const { gridRef } = tableInfo;
                const { _rowStartIndex, _rowStopIndex } = gridRef;
                const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
                const quickFiltersFormatted = formatQuickFilters(quickFilters);

                const { data } = await getViewRecordsApiV2({
                    defaultAccessViewId,
                    dbId,
                    offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                    limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                    filterQuery: quickFiltersFormatted,
                    sortQuery: quickSorts,
                    dependencyColumnIds,
                    isShowAutoQA
                });
                dispatch(dataActions.updateData({ newData: data, isCareData: true }));
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchOtherReferenceDataColumns] ${message}`,
                    type: 'info'
                })
            );
        }
    };
}

export function setDropRecordId({ beforeRecordId, afterRecordId, currentRecordId, highlight, currentRowIndex }) {
    return {
        type: types.SET_DROP_RECORD_ID,
        payload: {
            beforeRecordId,
            afterRecordId,
            currentRecordId,
            highlight,
            currentRowIndex
        }
    };
}

export function setDraggingRows({ rowIds }) {
    return {
        type: types.SET_ROWS_DRAGGING,
        payload: {
            rowIds
        }
    };
}

export function reOrderRecords({ afterRecordId, beforeRecordId }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const { rows, dbId, branchId } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));

        const { recordIds, data, isOverRecordLimit, totalSelectedRecords } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!afterRecordId && !beforeRecordId) {
            dispatch(statusActions.removeDoingAction({ actionId }));

            dispatch(enqueueSnackbar({ type: 'info', message: 'afterRecordId or beforeRecordId cannot be empty!' }));
            return;
        }

        if (afterRecordId && beforeRecordId) {
            dispatch(statusActions.removeDoingAction({ actionId }));

            console.log('why both of theme have value??');
            return;
        }

        if (!recordIds?.length) {
            dispatch(statusActions.removeDoingAction({ actionId }));

            console.log('do nothing because rowIds is empty');
            return;
        }

        let splitRowIndex = 0;

        if (afterRecordId) {
            if (!rows.includes(afterRecordId)) {
                dispatch(statusActions.removeDoingAction({ actionId }));

                console.log('not in this session => ignore');
                return;
            }
            splitRowIndex = rows.findIndex(recordId => recordId === afterRecordId) + 1;
        } else {
            if (!rows.includes(beforeRecordId)) {
                dispatch(statusActions.removeDoingAction({ actionId }));

                console.log('not in this session => ignore');
                return;
            }
            splitRowIndex = rows.findIndex(recordId => recordId === beforeRecordId);
        }

        let firstArr = rows.slice(0, splitRowIndex);
        let secondArr = rows.slice(splitRowIndex);

        firstArr = firstArr.filter(recordId => !recordIds?.includes(recordId));
        secondArr = secondArr.filter(recordId => !recordIds.includes(recordId));

        const newRows = [...firstArr, ...recordIds, ...secondArr];

        dispatch(_reorderRecords({ newRows }));
        dispatch(dataActions.updateData({ newData: data }));
        dispatch(gridUIActions.triggerRecomputedGrid());

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_REORDER_RECORDS,
                body: {
                    rows: [...rows]
                }
            })
        );

        try {
            await reorderRecordsApi({
                dbId,
                gridId: branchId,
                body: {
                    afterRecordId,
                    beforeRecordId,
                    reorderRecordIds: recordIds
                }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(gridUIActions.triggerRecomputedGrid());

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

async function _getDraggingRowTempData({ dispatch, recordIds = [], data, viewColumns, dbId, defaultAccessViewId }) {
    let draggingRowTempData = {};
    const rowIdsToFetchData = [];

    recordIds.forEach(rowId => {
        if (data.hasOwnProperty(rowId)) {
            draggingRowTempData[rowId] = data?.[rowId];
        } else {
            rowIdsToFetchData.push(rowId);
        }
    });

    const columnIds = viewColumns?.map(viewCol => viewCol?.id);
    if (rowIdsToFetchData?.length) {
        try {
            const { data: newData } = await getExistViewRecordsApi({
                dbId,
                viewId: defaultAccessViewId,
                columnIds,
                recordIds: rowIdsToFetchData
            });
            draggingRowTempData = combinedData({ data: draggingRowTempData, newData });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    }
    return draggingRowTempData;
}

export function reorderRecordsSocket({ beforeRecordId, afterRecordId, recordIds }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { rows, viewColumns, data, dbId, defaultAccessViewId } = gridUI;

        if (!afterRecordId && !beforeRecordId) {
            dispatch(enqueueSnackbar({ type: 'info', message: 'afterRecordId or beforeRecordId cannot be empty!' }));
            return;
        }

        if (afterRecordId && beforeRecordId) {
            console.log('why both of theme have value??');
            return;
        }

        if (!recordIds?.length) {
            console.log('do nothing because rowIds is empty');
            return;
        }

        let splitRowIndex = 0;

        let isOutOfRange = false;

        if (afterRecordId) {
            if (!rows.includes(afterRecordId)) {
                isOutOfRange = true;
            }
            splitRowIndex = rows.findIndex(recordId => recordId === afterRecordId) + 1;
        } else {
            if (!rows.includes(beforeRecordId)) {
                isOutOfRange = true;
            }
            splitRowIndex = rows.findIndex(recordId => recordId === beforeRecordId);
        }

        let firstArr = rows.slice(0, splitRowIndex);
        let secondArr = rows.slice(splitRowIndex);

        firstArr = firstArr.filter(recordId => !recordIds?.includes(recordId));
        secondArr = secondArr.filter(recordId => !recordIds.includes(recordId));

        console.log('isOutOfRange', isOutOfRange);
        if (isOutOfRange) {
            const newRows = [...recordIds, ...firstArr, ...secondArr];
            dispatch(_reorderRecords({ newRows, draggingRowTempData: {} }));
        } else {
            const missingRecordsData = await _getDraggingRowTempData({
                recordIds,
                data,
                viewColumns,
                dbId,
                defaultAccessViewId,
                dispatch
            });

            const newRows = [...firstArr, ...recordIds, ...secondArr];
            dispatch(_reorderRecords({ newRows }));
            dispatch(dataActions.updateData({ newData: missingRecordsData }));
        }

        dispatch(gridUIActions.triggerRecomputedGrid());
    };
}

function _reorderRecords({ newRows }) {
    return {
        type: types.REORDER_RECORDS,
        payload: {
            newRows
        }
    };
}

export function dropRowsIntoRow({ isSortingOn }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { beforeRecordId, afterRecordId, currentRecordId } = gridUI;

        if (isSortingOn) {
            dispatch(
                setDropRecordId({ beforeRecordId: null, afterRecordId: null, currentRecordId: null, highlight: null })
            );
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `Reordering can't be performed when a column is being sorted`
                })
            );
            return;
        }

        if (!beforeRecordId && !afterRecordId) {
            dispatch(
                setDropRecordId({ beforeRecordId: null, afterRecordId: null, currentRecordId: null, highlight: null })
            );
            return;
        }

        dispatch(reOrderRecords({ beforeRecordId, afterRecordId, currentRecordId }));
        dispatch(
            setDropRecordId({ beforeRecordId: null, afterRecordId: null, currentRecordId: null, highlight: null })
        );
    };
}

export function handleDropRows({ isSortingOn }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dropPath, viewColumns } = gridUI;

        if (dropPath) {
            const pathTagFound = viewColumns?.find(viewCol => viewCol?.id === columnTypes.PATH_TAG);

            if (!pathTagFound || !pathTagFound?.editable) {
                dispatch(
                    enqueueSnackbar({
                        message: `Path column is not available to edit in the current view`,
                        type: 'info'
                    })
                );
                dispatch(pathTagActions.setDropPath({ nodeId: null }));
                return;
            }
            dispatch(pathTagActions.setPathTagForMultipleRecords({ nodeId: dropPath }));
            dispatch(pathTagActions.setDropPath({ nodeId: null }));
            return;
        } else {
            pathTagActions.setDropPath({ nodeId: null });
        }
        dispatch(dropRowsIntoRow({ isSortingOn }));
    };
}

export function changeDefaultRowHeight({ height }) {
    return {
        type: types.CHANGE_DEFAULT_ROW_HEIGHT,
        payload: {
            height
        }
    };
}

export function deleteAllRecords({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { quickFilters, quickSorts, dbId, defaultAccessViewId } = gridUI;
        const filterQuery = formatQuickFilters(quickFilters);
        try {
            await deleteAllRecordsApi({
                dbId,
                viewId: defaultAccessViewId,
                filterQuery,
                sortQuery: quickSorts
            });
            dispatch(resetSelection());
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _deleteAllRecords() {
    return {
        type: types.DELETE_ALL_RECORDS
    };
}

export function fetchRecordsData({ recordIds = [], successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId } = gridUI;
        if (!recordIds?.length) return;

        try {
            const { data } = await getExistViewRecordsApi({
                dbId,
                viewId: defaultAccessViewId,
                recordIds
            });

            dispatch(dataActions.updateData({ newData: data, isCareData: true }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function setSectionScroll(payload) {
    return {
        type: types.SET_SECTION_SCROLL,
        payload
    };
}
