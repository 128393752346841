let viewPortWidth;
let viewPortHeight;

export const getViewport = () => {
    if (typeof window.innerWidth != 'undefined') {
        viewPortWidth = window.innerWidth;
        viewPortHeight = window.innerHeight;
    } else if (
        typeof document.documentElement != 'undefined' &&
        typeof document.documentElement.clientWidth != 'undefined' &&
        document.documentElement.clientWidth !== 0
    ) {
        viewPortWidth = document.documentElement.clientWidth;
        viewPortHeight = document.documentElement.clientHeight;
    } else {
        viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
        viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    return [viewPortWidth, viewPortHeight];
};

export const detectPosition = ({ position }) => {
    const [viewPortWidth, viewPortHeight] = getViewport();
    let halfWidth = viewPortWidth / 2;
    let halfHeight = viewPortHeight / 2;
    let { top, left } = position;

    return {
        top: top - halfHeight,
        left: left - halfWidth,
        halfWidth,
        halfHeight
    };
};

export const getDisplayStyle = ({ top, left, dimension, isDatetime }) => {
    const style = {};
    style.position = 'absolute';

    if (top > 0 && left < 0) {
        style.bottom = isDatetime ? 0 : dimension.height;
        style.left = 0;
    }

    if (top < 0 && left < 0) {
        style.top = 'auto';
        style.left = 0;
    }

    if (top > 0 && left > 0) {
        style.bottom = isDatetime ? 0 : dimension.height;
        style.right = isDatetime ? -dimension.width : 0;
    }

    if (top < 0 && left > 0) {
        style.top = 'auto';
        style.right = isDatetime ? -dimension.width : 0;
    }

    return style;
};
