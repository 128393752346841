import { useSelector } from 'react-redux';
import * as roleConst from 'auth/roleConst';

export function useWorkspacePrivileges() {
    return useSelector(state => state.permission?.workspacePrivileges);
}

export function useWorkspacePrivilegesForApiKeys() {
    return useSelector(state => {
        const wsPrivileges = state.permission?.workspacePrivileges;
        return wsPrivileges?.filter(pri =>
            [
                roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE,
                roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE,
                roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID,
                roleConst.WORKSPACE_AUTHORITIES.MANAGE_BRANCH,
                roleConst.WORKSPACE_AUTHORITIES.MANAGE_VIEW,
                roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD,
                roleConst.COMPANY_AUTHORITIES.MANAGE_TM
            ].includes(pri?.name)
        );
    });
}
