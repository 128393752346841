import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CountryCodes from 'components/inputs/CountryCodes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    fieldTitle: {
        color: theme.colors.midGrey
    }
}));

function BillingInfo({ billingInfo }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const countryName = CountryCodes.find(country => country.code === billingInfo?.countryCode)?.name;

    return (
        <Grid container className={classes.root} direction="column" spacing={4}>
            <Grid item container>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('global_first_name')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{billingInfo?.firstName}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('global_last_name')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{billingInfo?.lastName}</Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('company_name')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{billingInfo?.companyName}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        Billing Email
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{billingInfo?.email}</Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('phone_number')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{billingInfo?.phone}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('vat_number')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{billingInfo?.taxCode}</Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('country')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1">{countryName}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('city')}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid item>
                        <Typography variant="body1">{billingInfo?.city}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={2}>
                    <Typography className={classes.fieldTitle} variant="body2">
                        {t('address')}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1">{billingInfo?.address}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(BillingInfo);
