import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { getBGByAction, getIconByAction } from 'utils/color';
import { useTheme } from '@material-ui/core';
import { parseJson } from 'utils/object';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { GRID_HISTORY_ACTIONS } from 'const/gridUI';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    diff: {
        marginTop: 8
    },
    wrapper: {
        padding: `0px 8px`,
        borderRadius: 4,
        background: props => getBGByAction({ action: props.action, theme, opacity: 0.1 }),
        width: 'auto'
    },
    showHideText: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    changes: {
        marginTop: 10,
        borderLeft: `1px solid ${theme.colors.border}`,
        padding: `6px 10px`
    },
    ellipsis: {
        cursor: 'pointer'
    }
}));

function getMessage({ action, length, totalRecords }) {
    switch (action) {
        case GRID_HISTORY_ACTIONS.CLEAN_COLUMN:
            return length > 1 ? `${length} columns cleaned` : `1 column cleaned`;
        case GRID_HISTORY_ACTIONS.AUTOFILL:
            return length > 1 ? `${length} columns auto-filled` : `1 column auto-filled`;
        case GRID_HISTORY_ACTIONS.PASTE_RECORDS:
            return totalRecords > 0
                ? totalRecords > 1
                    ? `${totalRecords} records updated`
                    : i18n.t('1_record_updated')
                : '';
        case GRID_HISTORY_ACTIONS.APPROVE_TM:
            return totalRecords > 0
                ? totalRecords > 1
                    ? `${totalRecords} records approved`
                    : '1 record approved'
                : '';
        case GRID_HISTORY_ACTIONS.MARK_SOURCE_STATUS:
        case GRID_HISTORY_ACTIONS.MARK_TARGET_STATUS:
            return totalRecords > 0 ? (totalRecords > 1 ? `${totalRecords} records marked` : '1 record marked') : '';
        default:
            return `default`;
    }
}

function GridDiff({ action, extraJson, t }) {
    const theme = useTheme();
    const classes = useStyles({ action });
    let object = parseJson(extraJson);
    const columnNames = object?.columnNames;
    const totalRecords = object?.totalRecords || 0;

    const message = getMessage({ action, length: columnNames?.length, totalRecords });
    const [isShowChanges, setIsShowChanges] = React.useState(false);

    if (!object) return null;

    if (
        ![
            GRID_HISTORY_ACTIONS.CLEAN_COLUMN,
            GRID_HISTORY_ACTIONS.AUTOFILL,
            GRID_HISTORY_ACTIONS.PASTE_RECORDS,
            GRID_HISTORY_ACTIONS.MARK_SOURCE_STATUS,
            GRID_HISTORY_ACTIONS.MARK_TARGET_STATUS
        ].includes(action)
    )
        return null;

    if (!message) return null;

    const toggleShowChanges = () => {
        setIsShowChanges(!isShowChanges);
    };

    return (
        <Grid container direction="column" wrap="nowrap">
            <Grid item>
                <Grid className={classes.diff} container direction="row" spacing={3} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Grid className={classes.wrapper} container alignItems="center" wrap="nowrap" spacing={2}>
                            <Grid item>{getIconByAction({ action, theme })}</Grid>
                            <Grid item>
                                <p className="body2">{message}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isEmpty(columnNames) && (
                        <Grid item>
                            <p onClick={toggleShowChanges} className={'body2 cursor-pointer text-text-primary'}>
                                {isShowChanges ? t(`global_hide_changes`) : t(`global_show_changes`)}
                            </p>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {isShowChanges && !isEmpty(columnNames) && isArray(columnNames) && (
                <Grid item className={classes.changes}>
                    <Grid container direction="column" spacing={2} wrap="nowrap">
                        {columnNames.map(name => (
                            <Grid item key={name}>
                                <p className="body2 flex">{name}</p>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(GridDiff);
