import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'courier new',
        backgroundColor: '#eee',
        padding: 3
    }
}));

function CodeElement(props) {
    const classes = useStyles();
    return (
        <code className={classes.root} {...props.attributes}>
            {props.children}
        </code>
    );
}

export default CodeElement;
