import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import hexToRgba from 'hex-to-rgba';

import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogActions from 'components/dialog/DialogActions';
import ButtonBase from 'components/button/Base';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as tmActions from '././actions';
import { useTmSourcesByTmId } from 'hooks/tm';
import CountryFlag from 'components/svg-icon/CountryFlag';
import LDBasePortal from 'components/selects/LDBasePortal';
import { SUPPORTED_LANGUAGES } from 'const/languageData';

const useStyles = makeStyles(theme => ({
    root: {
        width: 500,
        position: 'relative',
        background: theme.colors.white,
        '&:focus': {
            outline: 'none'
        },
        borderRadius: 6
    },
    mb12: {
        marginBottom: 12
    },
    spacing: {
        marginBottom: theme.spacing(4)
    },
    btnSubmit: {
        width: 100,
        position: 'relative',
        '& button svg': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? hexToRgba(props.buttonColor, 0.9) : '')
            }
        }
    },
    numberStep: {
        width: 18,
        minWidth: 18,
        height: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 12,
        lineHeight: `14px`,
        marginRight: 8,
        position: 'relative'
    },
    wFull: {
        maxWidth: '100%',
        marginBottom: 24
    }
}));

function EditorTM({ onClose, tmId }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const sources = useTmSourcesByTmId({ tmId });

    const sourceOptions = React.useMemo(() => {
        return sources?.map(source => {
            const lang = SUPPORTED_LANGUAGES.find(lang => lang.id === source);
            return {
                ...source,
                label: `${lang?.name} - ${source}`,
                value: source,
                languageCountry: lang?.name,
                icon: () => <CountryFlag languageCountryCode={source} />
            };
        });
    }, [sources]);
    const [source, setSource] = React.useState(null);
    const [targets, setTarget] = React.useState([]);
    const [_targets, _setTarget] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        dispatch(
            tmActions.fetchTMSources({
                tmId,
                successCallback: () => {
                    console.log('fetch sources success');
                },
                errorCallback: () => {
                    console.log('fetch sources failed');
                }
            })
        );
    }, [dispatch, tmId]);

    React.useEffect(() => {
        setIsLoading(true);
        if (source) {
            dispatch(
                tmActions.fetchTMTarget({
                    tmId,
                    source: source.value,
                    successCallback: targets => {
                        _setTarget(targets);
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                    }
                })
            );
        }
    }, [dispatch, source, tmId]);

    const targetOptions = React.useMemo(() => {
        return _targets?.map(target => {
            const lang = SUPPORTED_LANGUAGES.find(lang => lang.id === target);
            return {
                ...target,
                label: `${lang?.name} - ${target}`,
                value: target,
                languageCountry: lang?.name,
                icon: () => <CountryFlag languageCountryCode={target} />
            };
        });
    }, [_targets]);

    const targetLenght = React.useMemo(() => {
        return targetOptions?.reduce((arr, o) => {
            return arr.concat(o);
        }, [])?.length;
    }, [targetOptions]);

    const isSelectTarget = React.useMemo(() => {
        return targets?.length && targets?.length === targetLenght;
    }, [targets, targetLenght]);

    const handleSourceChange = option => {
        if (source?.value === option?.value) return;
        dispatch(tmActions.setSelectSourceTm(option));
        setSource(option);
    };

    const handleTargetChange = React.useCallback(
        option => {
            dispatch(tmActions.setSelectTargetsTm(option));

            setTarget(option);
        },
        [dispatch]
    );

    const goEditorTM = () => {
        onClose && onClose();
        history.push(`/tm-editor/${tmId}`);
    };

    return (
        <>
            <DialogTitle title={'Editor TM'} onClose={onClose} />
            <DialogContent>
                <Grid container wrap="nowrap" direction="column" className={`${classes.root}`}>
                    <Grid container direction="row" alignItems="center" className={classes.spacing}>
                        <Grid item className={classes.numberStep}>
                            {t('global_1')}
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{t('select_source_language_edit')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.wFull}>
                        <LDBasePortal
                            ddPlaceholder={t('placeholder_select_source_language')}
                            menuPlaceholder={t('placeholder_find_source_language')}
                            options={sourceOptions}
                            onChange={handleSourceChange}
                            defaultValue={source}
                            dropdownClassName={classes.dropdownClassName}
                            isMulti={false}
                        />
                    </Grid>
                    <Grid container direction="row" alignItems="center" className={classes.mb12}>
                        <Grid item className={classes.numberStep}>
                            {t('global_2')}
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{t('select_target_language_edit')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.wFull}>
                        <LDBasePortal
                            ddPlaceholder={t('placeholder_select_targets_language')}
                            menuPlaceholder={t('placeholder_find_targets_language')}
                            options={targetOptions}
                            onChange={handleTargetChange}
                            defaultValue={targets}
                            dropdownClassName={classes.dropdownClassName}
                            isMulti={true}
                            isAll={targetOptions?.length > 1}
                            onSelectAll={setOpenState => {
                                if (isSelectTarget) {
                                    setTarget([]);
                                    dispatch(tmActions.setSelectTargetsTm([]));
                                } else {
                                    const allTarget = targetOptions?.reduce((a, opt) => {
                                        a = a?.concat(opt);
                                        return a;
                                    }, []);
                                    setTarget(allTarget);
                                    dispatch(tmActions.setSelectTargetsTm(allTarget));
                                }
                                setOpenState(false);
                            }}
                            selectAllText={isSelectTarget ? t(`un_select_all`) : t(`select_all`)}
                            isDisabled={!source ? true : false}
                            customNode={
                                isLoading && (
                                    <Typography variant="caption" style={{ textAlign: 'center', display: 'block' }}>
                                        Loading...
                                    </Typography>
                                )
                            }
                            hideOverflowSelectedOptions={true}
                            itemShowOverflow={1}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase width={107} onClick={onClose} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>

                    <Grid item>
                        {/* onClick={handleSaveAs} width={107} disabled={isLoading} */}
                        <div className={classes.btnSubmit}>
                            <ButtonBase width={107} variant="contained" onClick={goEditorTM}>
                                {t('open_editor_tm')}
                            </ButtonBase>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}
export default React.memo(EditorTM);
