import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as authActions from 'auth/actions';

function Logout() {
    const dispatch = useDispatch();
    const history = useHistory();

    dispatch(
        authActions.logOut({
            successCallback: () => {
                history.push('/signin');
            }
        })
    );

    return <div></div>;
}

export default React.memo(Logout);
