import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ListItem from 'components/list/Item';
import UpdateIconSVG from 'assets/images/svg/UpdateIconSVG';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import TMIconSVG from 'assets/images/svg/TMIconSVG';
import ApplyIconSVG from 'assets/images/svg/ApplyIconSVG';
import QuickApplyTmSVG from 'assets/images/svg/QuickApplyTmSVG';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import { isMac } from 'utils/os';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        height: props => (props.isShowUpdate ? 36 : 0) + (props.isShowAdd ? 36 : 0),
        boxShadow: '0px -1px 0px #D7D7DC'
    },
    footerQuickApply: {
        position: 'absolute',
        bottom: 0,
        height: 36,
        boxShadow: '0px -1px 0px #D7D7DC'
    }
}));

function TM({ texts, selectedText, value, onAdd, onUpdate, onChange, disabled, defaultTMPausedConsuming }) {
    const { t } = useTranslation();

    const isShowUpdate = React.useMemo(() => {
        return !defaultTMPausedConsuming && value && !texts?.includes(value) && selectedText;
    }, [texts, value, selectedText, defaultTMPausedConsuming]);

    const isShowAdd = React.useMemo(() => {
        return !defaultTMPausedConsuming && value && !texts?.includes(value);
    }, [texts, value, defaultTMPausedConsuming]);

    const classes = useStyles({ isShowUpdate, isShowAdd });

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item container direction="column">
                {texts?.length === 0 && !value && (
                    <Grid item style={{ width: '100%', paddingLeft: 20, paddingTop: 20 }} container alignItems="center">
                        <p className="caption text-center">{t(`cell_edit_tm_no_suggestion`)}</p>
                    </Grid>
                )}
                {texts?.map((text, index) => {
                    return (
                        <ListItem
                            disabled={disabled}
                            className={classes.tmItem}
                            icon={<TMIconSVG />}
                            isSelected={text === selectedText}
                            onClick={() => onChange(text)}
                            name={text}
                            key={index}
                            moreIcon={
                                text === selectedText ? null : (
                                    <Tooltip title="Apply this TM">
                                        <Grid item>
                                            <ApplyIconSVG />
                                        </Grid>
                                    </Tooltip>
                                )
                            }
                            useDOMPurify={false}
                        />
                    );
                })}
            </Grid>
            {!isShowUpdate && !isShowAdd && texts?.length > 0 && (
                <Grid item container direction="column" className={classes.footerQuickApply}>
                    <ListItem
                        icon={<QuickApplyTmSVG />}
                        name={t('quick_apply_first_tm')}
                        onClick={() => onChange(texts[0])}
                        moreIcon={<p className="caption">{isMac() ? '⌘ + Option + T' : 'Ctrl + Alt + T'}</p>}
                    />
                </Grid>
            )}
            <Grid item container direction="column" className={classes.footer}>
                {isShowUpdate && !disabled && (
                    <ListItem
                        icon={<UpdateIconSVG />}
                        onClick={onUpdate}
                        name={`Update <strong>to the current one</strong>`}
                        moreIcon={<p className="caption">Enter</p>}
                    />
                )}

                {isShowAdd && !disabled && (
                    <ListItem
                        icon={<AddIconSVG />}
                        onClick={onAdd}
                        name={
                            !selectedText
                                ? `Add alternative "${value}" to TM`
                                : `Add alternative to <strong>TM</strong>`
                        }
                        useDOMPurify={!selectedText ? false : true}
                        moreIcon={selectedText ? <p className="caption">⌘-Enter</p> : undefined}
                    />
                )}
            </Grid>
        </Grid>
    );
}

export default TM;
