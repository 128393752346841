import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as databaseActions from 'databases/actions';
import * as types from 'socket/types';
import backupRestoreHandler from './backupRestore';
import duplicateHandler from './duplicate';
import { isCanAddGridAndDatabaseRealtime } from 'utils/realtime';
import { isForAll, isForYou, isForOthers } from 'socket/utils/room';

const { dispatch, getState } = store;

const databasesHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    const { action, subObjectType, objectType } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ headers, state })) {
        if (objectType === types.DATABASE && [types.RESTORE, types.BACKUP].includes(action)) {
            return backupRestoreHandler({ body, isDatabaseDetail: false });
        }
        if (objectType === types.DATABASE && action === types.DUPLICATE_DATABASE) {
            return duplicateHandler({ body, isDatabaseDetail: false });
        }
    }

    if (isForYou({ state, headers })) {
        console.log('IGNORE');
        return;
    } else if (isForOthers({ state, headers })) {
        if (types.SUB_OBJECT_TYPE_NOT_FOUND !== !!subObjectType) return;

        return _databaseListHandler({ body });
    }
};

function _databaseListHandler({ body }) {
    const { action, data, dbId } = body;

    switch (action) {
        case types.UPDATE:
            return _updateDatabase({ dbId, database: data });
        case types.DELETE:
            return _deleteDatabase({ dbId });
        case types.CREATE:
            return _createDatabase({ database: data });
        default:
            return;
    }
}

function _updateDatabase({ dbId, database }) {
    dispatch(
        databaseActions.updateDatabaseAction({
            dbId,
            newDatabase: database
        })
    );
}

function _deleteDatabase({ dbId }) {
    dispatch(
        databaseActions.deleteDatabaseAction({
            dbId
        })
    );
}

function _createDatabase({ database }) {
    const { auth } = getState();
    if (!isCanAddGridAndDatabaseRealtime(auth)) return;
    dispatch(
        databaseActions.createDatabaseSocketAction({
            database
        })
    );
}

export default databasesHandler;
