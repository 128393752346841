import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { array } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import { AutoSizer } from 'react-virtualized-dn';
import * as memberActions from 'permission/actions/member';
import Table from 'components/tables/Table';
import DropdownMenu from 'components/menus/DropdownMenu';
import RoleSelect from './RoleSelect';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import DeactivateMemberSVG from 'assets/images/svg/DeactivateMemberSVG';
import ActivateMemberSVG from 'assets/images/svg/ActivateMemberSVG';
import SwitchSVG from 'assets/images/svg/SwitchSVG';
import { StatusIcon } from './Icons';
import WorkspaceOfMemberTable from './WorkspaceOfMemberTable';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import {
    DEFAULT_ROLE_USER,
    DEFAULT_ROLE_OWNER,
    ACTIVE,
    INACTIVE,
    PENDING_INVITE,
    PENDING_APPROVAL,
    GROUP_ADMIN_TYPES,
    ALL_LANGUAGES,
    COMPANY_MEMBERS
} from 'const';
import ModalBase from 'components/modal/Base';
import TransferBox from './TransferBox';
import ConfirmActivateBox from './ConfirmActivateBox';
import ConfirmDeactivateBox from './ConfirmDeactivateBox';
import ConfirmDeclineBox from './ConfirmDeclineBox';
import ConfirmApproveBox from './ConfirmApproveBox';
import ConfirmResendBox from './ConfirmResendBox';
import ConfirmRemoveBox from './ConfirmRemoveBox';
import { useCompanyId, useCurrentUserFullInfo } from 'hooks/auth';
import { isOwner } from 'utils/permission/role';
import CardInfo from 'components/cardInfo/Base';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import Tooltip from 'components/tooltip/Base';
import CountryFlag from 'components/svg-icon/CountryFlag';
import EditMemberModal from './EditMemberModal';
import { getAvatarUrl, getGroupAvatarUrl } from 'utils/images';
import ColorStickerSVG from 'assets/images/svg/ColorStickerSVG';
import ColorCloseSVG from 'assets/images/svg/ColorCloseSVG';
import ResendInvitationSVG from 'assets/images/svg/ResendInvitationSVG';
import { enqueueSnackbar } from 'notifier/actions';
import { POPUP_PADDING_TOP_BOTTOM, INPUT_PADDING_LEFT } from 'const/style';
import ListItem from 'components/list/Item';
import { sendManualTrack } from 'tracker';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import { generateUserBadge } from 'utils/user';
import ConfirmTransferInternal from './ConfirmTransferInternal';
import TransferInternalSVG from 'assets/images/svg/TransferInternalSVG';
import * as companyActions from 'permission/actions/invite';
import Dialog from 'components/dialog/Dialog';
import { useTranslation } from 'react-i18next';
import LanguagePair from 'components/tutorial/LanguagePairTutorial';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import { useWorkspaceRoles } from 'hooks/workspace';
import { setUserDefaultProjectRole } from 'services/members';
import ProjectRoleSelect from 'workspaces/setting/members/RoleSelect';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ChairSVG from 'assets/images/svg/ChairSVG';
import SupportAgentSVG from 'assets/images/svg/SupportAgentSVG';
import { useCompanySubscription } from 'hooks/payment';
import { isExternal } from 'config';
import i18n from 'i18n';
import moment from 'moment';
import { useCompanyInfo } from 'hooks/app';

const generateMenuOptions = ({ rowData, userInfo, currentTab }) => {
    const { state, ldManager } = rowData;
    if (state === 'ACTIVE' && !ldManager && userInfo?.ldManager && currentTab === COMPANY_MEMBERS) {
        return [
            { value: 'edit', label: i18n.t('edit'), icon: <EditSVG /> },
            { value: 'deactivate', label: i18n.t('deactivate_member'), icon: <DeactivateMemberSVG /> },
            { value: 'internal', label: 'Transfer to LD member', icon: <TransferInternalSVG /> },
            {
                value: 'delete',
                label: i18n.t('delete_member'),
                icon: <DeleteSVG />,
                isDisabled: true,
                tooltipTitle: 'You must deactivate this member first'
            }
        ];
    }

    if (state === 'ACTIVE') {
        return [
            { value: 'edit', label: i18n.t('edit'), icon: <EditSVG /> },
            { value: 'deactivate', label: i18n.t('deactivate_member'), icon: <DeactivateMemberSVG /> },
            {
                value: 'delete',
                label: i18n.t('delete_member'),
                icon: <DeleteSVG />,
                isDisabled: true,
                tooltipTitle: 'You must deactivate this member first'
            }
        ];
    }

    if (state === 'INACTIVE') {
        return [
            { value: 'activate', label: 'Activate member', icon: <ActivateMemberSVG /> },
            { value: 'delete', label: i18n.t('delete_member'), icon: <DeleteSVG /> }
        ];
    }

    if (state === 'PENDING_APPROVAL' || state === 'PENDING_INVITE') return [];
};

const useStyles = makeStyles(theme => {
    const cellPadding = INPUT_PADDING_LEFT + 1;
    return {
        table: {
            border: '1px solid #e9eaef',
            borderRadius: 4,
            overflow: 'hidden',
            color: theme.colors.primaryText,

            '& .header-role': {
                marginLeft: cellPadding
            },
            '& .cell-role': {
                paddingLeft: cellPadding,

                '& .select': {
                    marginLeft: cellPadding * -1
                }
            }
        },
        roleSelect: {
            width: 'auto',
            border: `1px solid ${theme.colors.silver}`,
            background: 'transparent'
        },
        moreActionsButton: {
            height: 40,
            width: 40,
            borderRadius: '100%',
            cursor: 'pointer',
            '&:hover': {
                background: theme.colors.paleNavy
            },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            float: 'right'
        },
        center: {
            display: 'flex',
            justifyContent: 'center'
        },
        subRow: {
            padding: '24px 50px',
            overflow: 'auto'
        },
        approve: {
            display: 'flex',
            width: 26,
            height: 26,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: `1px solid ${theme.colors.snackBarSuccess}`,
            borderRadius: 4,
            color: `${theme.colors.snackBarSuccess}`,
            '&:hover': {
                background: '#F7FFE9'
            }
        },
        decline: {
            display: 'flex',
            width: 26,
            height: 26,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: `1px solid ${theme.colors.snackBarWarning}`,
            borderRadius: 4,
            color: `${theme.colors.snackBarWarning}`,
            '&:hover': {
                background: '#FFF4F3'
            }
        },
        listItem: {
            '& .textContent': {
                color: theme.colors.snackBarWarning,
                fontWeight: 500
            }
        },
        optionCreate: {
            paddingTop: POPUP_PADDING_TOP_BOTTOM,
            marginTop: POPUP_PADDING_TOP_BOTTOM,
            borderTop: `1px solid ${theme.colors.divider}`
        },
        arrow: {
            margin: theme.spacing(0, 2)
        }
    };
});

const ActionRenderer = ({ rowData, currentTab }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const userInfo = useCurrentUserFullInfo();
    const companyId = useCompanyId();
    const isCurrentOwner = isOwner({ currentUser: userInfo });
    const { email, state, role, fullName } = rowData;
    const [openTransformModal, setOpenTransformModal] = React.useState(false);
    const [openConfirmActivateModal, setOpenConfirmActivateModal] = React.useState(false);
    const [openConfirmDeactivateModal, setOpenConfirmDeactivateModal] = React.useState(false);
    const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = React.useState(false);
    const [openConfirmDeclineModal, setOpenConfirmDeclineModal] = React.useState(false);
    const [openConfirmApproveModal, setOpenConfirmApproveModal] = React.useState(false);
    const [openConfirmResendModal, setOpenConfirmResendModal] = React.useState(false);
    const [openEditMemberModal, setOpenEditMemberModal] = React.useState(false);
    const [openTransferInternalModal, setOpenTransferInternalModal] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const openConfirmDeactivateHandler = () => {
        setOpenConfirmDeactivateModal(true);
    };

    const closeConfirmDeactivateHandler = () => {
        setOpenConfirmDeactivateModal(false);
    };

    const openConfirmActivateHandler = () => {
        setOpenConfirmActivateModal(true);
    };

    const closeConfirmActivateHandler = () => {
        setOpenConfirmActivateModal(false);
    };

    const openConfirmRemoveHandler = () => {
        setOpenConfirmRemoveModal(true);
    };

    const closeConfirmRemoveHandler = () => {
        setOpenConfirmRemoveModal(false);
    };

    const openTransformModalHandler = () => {
        setOpenTransformModal(true);
    };

    const closeTransformModalHandler = () => {
        setOpenTransformModal(false);
    };

    const openConfirmDeclineModalHandler = () => {
        setOpenConfirmDeclineModal(true);
    };

    const closeConfirmDeclineModalHandler = () => {
        setOpenConfirmDeclineModal(false);
    };

    const openConfirmApproveModalHandler = () => {
        setOpenConfirmApproveModal(true);
    };

    const closeConfirmApproveModalHandler = () => {
        setOpenConfirmApproveModal(false);
    };

    const handleOpenEditMemberModal = () => {
        setOpenEditMemberModal(true);
    };

    const handleCloseEditMemberModal = () => {
        setOpenEditMemberModal(false);
    };

    const handleOpenTransferInternalModal = () => {
        setOpenTransferInternalModal(true);
    };

    const handleCloseTransferInternalModal = () => {
        setOpenTransferInternalModal(false);
    };

    const handleActionChange = option => {
        switch (option.value) {
            case 'activate':
                openConfirmActivateHandler();
                break;
            case 'deactivate':
                openConfirmDeactivateHandler();
                break;
            case 'edit':
                handleOpenEditMemberModal();
                break;
            case 'remove':
                openConfirmDeclineModalHandler();
                break;
            case 'delete':
                openConfirmRemoveHandler();
                break;
            case 'internal':
                handleOpenTransferInternalModal();
                break;
            default:
                break;
        }
    };

    const handleActivateMember = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Activate Member`, customData: { affectedEmail: rowData.email } });

        dispatch(
            memberActions.activateUser({
                companyId,
                userId: rowData.id,
                userEmail: rowData.email,
                oldState: state,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Member has been activated`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeConfirmActivateHandler();
                },
                errorCallback: () => {
                    console.log('activateUser failed');
                    setIsSubmitting(false);
                    closeConfirmActivateHandler();
                }
            })
        );
    };
    const handleDeactivateMember = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Deactivate Member`, customData: { affectedEmail: rowData.email } });

        dispatch(
            memberActions.deactivateUser({
                companyId,
                userId: rowData.id,
                userEmail: rowData.email,
                oldState: state,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: t('member_has_been_deactivated'),
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeConfirmDeactivateHandler();
                },
                errorCallback: () => {
                    console.log('deactivateUser failed');
                    setIsSubmitting(false);
                    closeConfirmDeactivateHandler();
                }
            })
        );
    };

    const cancelTranformHandler = () => {
        closeTransformModalHandler();
    };

    const handleApprove = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Approve Member`, customData: { affectedEmail: rowData.email } });
        dispatch(
            memberActions.approveUser({
                companyId,
                userId: rowData.id,
                userEmail: rowData.email,
                oldState: state,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Invitation has been approved`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeConfirmApproveModalHandler();
                },
                errorCallback: () => {
                    console.log('approveUser failed');
                    setIsSubmitting(false);
                    closeConfirmApproveModalHandler();
                }
            })
        );
    };

    const handleDecline = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Decline Member`, customData: { affectedEmail: rowData.email } });
        dispatch(
            memberActions.declineMemberFromCompany({
                companyId,
                userEmail: rowData.email,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Invitation has been removed`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeConfirmDeclineModalHandler();
                },
                errorCallback: () => {
                    console.log('declineMemberFromCompany failed');
                    setIsSubmitting(false);
                    closeConfirmDeclineModalHandler();
                }
            })
        );
    };

    const handleRemoveMember = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: 'Delete Member', customData: { affectedEmail: rowData.email } });
        dispatch(
            memberActions.removeMemberFromCompany({
                companyId,
                userEmail: rowData.email,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `${rowData.fullName} has been deleted succesfully`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeConfirmRemoveHandler();
                },
                errorCallback: () => {
                    console.log('declineMemberFromCompany failed');
                    setIsSubmitting(false);
                    closeConfirmRemoveHandler();
                }
            })
        );
    };

    const handleEditMember = ({ userId, role, languagePairs, groups }) => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Edit Member Info`, customData: { affectedEmail: rowData.email } });
        dispatch(
            memberActions.updateUserRoleAndLanguages({
                companyId,
                userId,
                role,
                languagePairs,
                groups,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Changes saved`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    handleCloseEditMemberModal();
                },
                errorCallback: () => {
                    console.log('updateUserRoleAndLanguages failed');
                    setIsSubmitting(false);
                    handleCloseEditMemberModal();
                }
            })
        );
    };

    const openResendModal = () => {
        setOpenConfirmResendModal(true);
    };

    const closeResendModal = () => {
        setOpenConfirmResendModal(false);
    };

    const handleResendInvitation = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Resend invitation`, customData: { affectedEmail: rowData.email } });
        dispatch(
            memberActions.resendInvitation({
                companyId,
                email,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Invitation has been sent`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeResendModal();
                },
                errorCallback: () => {
                    console.log('resendInvitation failed');
                    setIsSubmitting(false);
                    closeResendModal();
                }
            })
        );
    };

    const handleRevokeInvitation = () => {
        setIsSubmitting(true);
        sendManualTrack({ type: `Remove invitation`, customData: { affectedEmail: rowData.email } });
        dispatch(
            memberActions.revokeInvitation({
                companyId,
                email,
                successCallback: () => {
                    dispatch(
                        enqueueSnackbar({
                            message: `Invitation has been removed`,
                            type: 'info'
                        })
                    );
                    setIsSubmitting(false);
                    closeConfirmDeclineModalHandler();
                },
                errorCallback: () => {
                    console.log('revokeInvitation failed');
                    setIsSubmitting(false);
                    closeConfirmDeclineModalHandler();
                }
            })
        );
    };

    const handleTransferInternal = () => {
        setIsSubmitting(true);
        dispatch(
            companyActions.updateUserToInternalUser({
                email: rowData.email,
                successCallback: () => {
                    console.log('updateUserToInternalUser success');
                    setIsSubmitting(false);
                    handleCloseTransferInternalModal();
                },
                errorCallback: () => {
                    console.log('updateUserToInternalUser failed');
                    setIsSubmitting(false);
                    handleCloseTransferInternalModal();
                }
            })
        );
    };

    const TranferOwnership = React.useCallback(
        ({ handleClickAway }) => {
            const handleClick = () => {
                handleClickAway();
                openTransformModalHandler();
            };
            return (
                <Grid container className={classes.optionCreate}>
                    <ListItem
                        name={t('confirm_transfer_title')}
                        icon={<SwitchSVG color={theme.colors.snackBarWarning} />}
                        className={`${classes.listItem}`}
                        onClick={handleClick}
                    />
                </Grid>
            );
        },
        [classes, theme, t]
    );

    if (role?.id === DEFAULT_ROLE_OWNER?.id) return null;

    if (state === PENDING_INVITE) {
        return (
            <>
                <Grid container direction="row" wrap="nowrap" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Tooltip title="Resend invitation">
                            <div onClick={openResendModal} className={classes.approve} variant="outlined">
                                <ResendInvitationSVG />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Remove invitation">
                            <div
                                onClick={openConfirmDeclineModalHandler}
                                className={classes.decline}
                                variant="outlined"
                            >
                                <ColorCloseSVG />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
                <ModalBase open={openConfirmResendModal} handleClose={closeResendModal}>
                    <ConfirmResendBox
                        isSubmitting={isSubmitting}
                        email={email}
                        handleAgreed={handleResendInvitation}
                        handleCancel={closeResendModal}
                    />
                </ModalBase>
                <ModalBase open={openConfirmDeclineModal} handleClose={closeConfirmDeclineModalHandler}>
                    <ConfirmDeclineBox
                        isSubmitting={isSubmitting}
                        email={email}
                        handleAgreed={handleRevokeInvitation}
                        handleCancel={closeConfirmDeclineModalHandler}
                    />
                </ModalBase>
            </>
        );
    }

    if (state === PENDING_APPROVAL) {
        return (
            <>
                <Grid container direction="row" wrap="nowrap" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Tooltip title="Accept invitation">
                            <div
                                onClick={openConfirmApproveModalHandler}
                                className={classes.approve}
                                variant="outlined"
                            >
                                <ColorStickerSVG />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Remove invitation">
                            <div
                                onClick={openConfirmDeclineModalHandler}
                                className={classes.decline}
                                variant="outlined"
                            >
                                <ColorCloseSVG />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
                <ModalBase open={openConfirmApproveModal} handleClose={closeConfirmApproveModalHandler}>
                    <ConfirmApproveBox
                        isSubmitting={isSubmitting}
                        email={email}
                        handleAgreed={handleApprove}
                        handleCancel={closeConfirmApproveModalHandler}
                    />
                </ModalBase>
                <ModalBase open={openConfirmDeclineModal} handleClose={closeConfirmDeclineModalHandler}>
                    <ConfirmDeclineBox
                        isSubmitting={isSubmitting}
                        email={email}
                        handleAgreed={handleDecline}
                        handleCancel={closeConfirmDeclineModalHandler}
                    />
                </ModalBase>
            </>
        );
    }

    return (
        <>
            {userInfo?.id !== rowData?.id && (
                <DropdownMenu
                    onChange={handleActionChange}
                    options={generateMenuOptions({ rowData, userInfo, currentTab })}
                    className={classes.moreActionsButton}
                    placement={'bottom-end'}
                    Control={isCurrentOwner && rowData.state === ACTIVE ? TranferOwnership : null}
                >
                    <div className={classes.moreActionsButton}>
                        <IconMoreActionsSVG />
                    </div>
                </DropdownMenu>
            )}
            <ModalBase open={openConfirmActivateModal} handleClose={closeConfirmActivateHandler}>
                <ConfirmActivateBox
                    isSubmitting={isSubmitting}
                    name={fullName}
                    handleAgreed={handleActivateMember}
                    handleCancel={closeConfirmActivateHandler}
                />
            </ModalBase>
            <ModalBase open={openConfirmDeactivateModal} handleClose={closeConfirmDeactivateHandler}>
                <ConfirmDeactivateBox
                    isSubmitting={isSubmitting}
                    name={fullName}
                    handleAgreed={handleDeactivateMember}
                    handleCancel={closeConfirmDeactivateHandler}
                />
            </ModalBase>
            <ModalBase open={openConfirmRemoveModal} handleClose={closeConfirmDeactivateHandler}>
                <ConfirmRemoveBox
                    isSubmitting={isSubmitting}
                    name={fullName}
                    handleAgreed={handleRemoveMember}
                    handleCancel={closeConfirmRemoveHandler}
                />
            </ModalBase>
            <Dialog open={openEditMemberModal} onClose={handleCloseEditMemberModal}>
                <EditMemberModal
                    isSubmitting={isSubmitting}
                    member={rowData}
                    onSubmit={handleEditMember}
                    onClose={handleCloseEditMemberModal}
                />
            </Dialog>
            <ModalBase open={openTransformModal} handleClose={cancelTranformHandler}>
                <TransferBox currentUser={rowData} handleCancel={cancelTranformHandler} />
            </ModalBase>
            <ModalBase open={openTransferInternalModal} handleClose={handleCloseTransferInternalModal}>
                <ConfirmTransferInternal
                    onCancel={handleCloseTransferInternalModal}
                    onAgree={handleTransferInternal}
                    isSubmitting={isSubmitting}
                />
            </ModalBase>
        </>
    );
};

const ActionRendererMemo = React.memo(ActionRenderer);

const FOOTER_HEIGHT = 48;

function MemberTable({ members = [], companyId, currentTab, internalMembers }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const companySubscription = useCompanySubscription();
    const userInfo = useCurrentUserFullInfo();
    const projectRoles = useWorkspaceRoles();
    const companyInfo = useCompanyInfo();
    const [sorting, setSorting] = useState({});

    const data = useMemo(() => {
        const cloneData = [...members].sort(
            (a, b) => new Date(b?.audit?.createdDate) - new Date(a?.audit?.createdDate)
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                if (sortKey === 'role') {
                    valueA = valueA ? valueA.name : '';
                    valueB = valueB ? valueB.name : '';
                }

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, members]);

    const nameRenderer = ({ rowData }) => {
        const { fullName, email, state, audit, imageUrl } = rowData;
        const { createdName } = audit;
        switch (state) {
            case ACTIVE:
            case INACTIVE:
                return (
                    <CardInfo
                        avatar={
                            <AvatarBase
                                src={getAvatarUrl(imageUrl)}
                                size="small"
                                alt="avatar"
                                badgeContent={generateUserBadge(rowData)}
                            />
                        }
                        title={fullName}
                        subTitle={email}
                    />
                );
            default:
                return (
                    <CardInfo
                        avatar={
                            <AvatarBase
                                src={getAvatarUrl(imageUrl)}
                                size="small"
                                alt="avatar"
                                badgeContent={generateUserBadge(rowData)}
                            />
                        }
                        title={email}
                        subTitle={`Invited by ${createdName}`}
                    />
                );
        }
    };

    const roleRenderer = ({ rowData }) => {
        const { role, id } = rowData;

        const onRoleChange = newRole => {
            sendManualTrack({ type: `Edit Company Role`, customData: { affectedEmail: rowData.email } });
            dispatch(
                memberActions.setUserRole({
                    companyId,
                    userId: id,
                    oldRole: role,
                    newRole
                })
            );
        };
        return (
            <RoleSelect
                isSystemRole={role?.isSystemRole}
                isEnableAll={false}
                defaultRoleName={role?.name || DEFAULT_ROLE_USER?.name}
                selectedRoleId={role?.id || DEFAULT_ROLE_USER?.id}
                onChange={onRoleChange}
                isCurrentUser={userInfo.id === id}
            />
        );
    };

    const LanguageRenderer = ({ rowData }) => {
        const { languagePairs } = rowData;
        const { t } = useTranslation();

        return (
            <div className="flex flex-col gap-3 py-3">
                {languagePairs?.map(({ from, to }, index) => (
                    <div key={index} className="flex flex-row items-center gap-1">
                        {from !== ALL_LANGUAGES && (
                            <CountryFlag
                                languageCountryCode={from}
                                style={{ width: 30, height: 'auto', borderRadius: 3 }}
                            />
                        )}
                        <p className="body2 inline">{from === ALL_LANGUAGES ? t('global_all') : from}</p>
                        <PathSVG />
                        {to !== ALL_LANGUAGES && (
                            <CountryFlag
                                languageCountryCode={to}
                                style={{ width: 30, height: 'auto', borderRadius: 3 }}
                            />
                        )}
                        <p className="body2 inline">{to === ALL_LANGUAGES ? t('global_all') : to}</p>
                    </div>
                ))}
            </div>
        );
    };

    const statusRenderer = ({ rowData }) => {
        const { state } = rowData;

        const MEMBER_STATE_MAPPING = {
            [ACTIVE]: t('active'),
            [INACTIVE]: t('inactive'),
            [PENDING_INVITE]: t('pending_invitation'),
            [PENDING_APPROVAL]: t('pending_approval')
        };

        return (
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                    <StatusIcon status={state} />
                </Grid>
                <Grid item>
                    <Typography variant="body2">{MEMBER_STATE_MAPPING[state] || state}</Typography>
                </Grid>
            </Grid>
        );
    };

    const groupsRenderer = ({ rowData }) => {
        const { groups } = rowData;
        return (
            <Grid container spacing={2} wrap="nowrap" direction="row">
                {groups?.map(group => {
                    const isSystemGroup = group?.systemGroup;
                    return (
                        <Tooltip key={group?.id} title={group?.name}>
                            <Grid item>
                                {isSystemGroup && group?.type === GROUP_ADMIN_TYPES.ADMIN && (
                                    <>
                                        {group?.logoUrl ? (
                                            <AvatarBase
                                                className={classes.avatar}
                                                size={`small`}
                                                src={getGroupAvatarUrl(group?.logoUrl)}
                                            />
                                        ) : (
                                            <AdminGroupAvatar size={32} />
                                        )}
                                    </>
                                )}
                                {isSystemGroup && group?.type === GROUP_ADMIN_TYPES.NORMAL && (
                                    <>
                                        {group?.logoUrl ? (
                                            <AvatarBase
                                                className={classes.avatar}
                                                size={`small`}
                                                src={getGroupAvatarUrl(group?.logoUrl)}
                                            />
                                        ) : (
                                            <TranslatorGroupAvatar size={32} />
                                        )}
                                    </>
                                )}
                                {!isSystemGroup && (
                                    <>
                                        {' '}
                                        {group?.logoUrl ? (
                                            <AvatarBase size={`small`} src={getGroupAvatarUrl(group?.logoUrl)} />
                                        ) : (
                                            <AvatarBase size="small">{group.name.slice(0, 2)}</AvatarBase>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Tooltip>
                    );
                })}
            </Grid>
        );
    };

    const lastActivityRenderer = ({ rowData }) => {
        const { lastActivityTime } = rowData;
        if (!lastActivityTime) return '';
        return (
            <>
                <Typography variant="body2">{moment(lastActivityTime).format('MMM DD, YYYY')}</Typography>
                <Typography variant="body2">{moment(lastActivityTime).format('hh:mm A')}</Typography>
            </>
        );
    };

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const COLUMNS = [
        { label: '', dataKey: 'id', type: 'collapse', width: 40, cellRenderer: () => null, className: classes.center },
        { label: t('global_name'), dataKey: 'fullName', width: 350, sort: true, cellRenderer: nameRenderer },
        { label: t('company_role'), dataKey: 'role', width: 180, flexGrow: 1, sort: true, cellRenderer: roleRenderer },
        {
            label: (
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item>{t('translation_languages')}</Grid>
                    <Tooltip maxWidth title={<LanguagePair />}>
                        <Grid item style={{ display: 'flex' }}>
                            <InfoIconSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
            ),
            dataKey: 'language',
            width: 250,
            cellRenderer: props => <LanguageRenderer {...props} />
        },
        { label: t('global_status'), dataKey: 'state', width: 200, sort: true, cellRenderer: statusRenderer },
        { label: 'Groups', dataKey: 'groups', width: 200, flexGrow: 1, cellRenderer: groupsRenderer },
        ...(companyInfo?.setting?.featureLastActivity
            ? [
                  {
                      label: 'Lastest Activity',
                      dataKey: 'latestActivity',
                      width: 200,
                      flexGrow: 1,
                      cellRenderer: lastActivityRenderer
                  }
              ]
            : []),
        {
            label: '',
            dataKey: 'id',
            width: 200,
            cellRenderer: props => <ActionRendererMemo {...props} currentTab={currentTab} />
        }
    ];

    const rowExpandedRender = props => {
        const { id, defaultProjectRole, groups } = props;

        const handleRoleChange = async role => {
            try {
                const newUser = await setUserDefaultProjectRole({
                    companyId,
                    userId: id,
                    roleId: role.id
                });
                dispatch(
                    enqueueSnackbar({
                        message: 'Default Project role changed',
                        type: 'info'
                    })
                );
                dispatch(memberActions._updateSingleUser(newUser));
            } catch (error) {
                console.log(error);
            }
        };

        return (
            <Grid container className={classes.subRow} direction="column" wrap="nowrap" spacing={3}>
                <Grid item>
                    <Typography variant="h4">{t('project_role')}</Typography>
                </Grid>
                <Grid item container alignItems="center" spacing={3}>
                    <Grid item>
                        <Typography variant="body2" className={classes.greyText}>
                            {t('you_can_assign_different_project_role')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ flex: 1 }} />
                    <Grid item>
                        <Typography variant="body1">{t('default_project_role')}</Typography>
                    </Grid>
                    <Tooltip placement="bottom" title={t('this_default_role_will_be_set')}>
                        <Grid item style={{ display: 'flex' }}>
                            <QuestionSVG />
                        </Grid>
                    </Tooltip>
                    <Grid item>
                        <Link
                            target="_blank"
                            href="https://help.gridly.com/hc/en-us/articles/6569749026961-Permission-Overview"
                        >
                            {t('global_learn_more')}
                        </Link>
                    </Grid>
                    <Grid item>
                        <ProjectRoleSelect
                            className={classes.roleSelect}
                            onRoleChange={handleRoleChange}
                            defaultRoleName={defaultProjectRole?.name}
                            workspaceRoles={projectRoles}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <WorkspaceOfMemberTable userId={id} groups={groups} />
                </Grid>
            </Grid>
        );
    };

    return (
        <div
            className={classes.table}
            style={{ height: '65vh', width: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <div style={{ height: `calc(100% - ${FOOTER_HEIGHT}px)` }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Grid container direction="column">
                            <Grid item style={{ flex: 1 }}>
                                <Table
                                    data={data}
                                    columns={COLUMNS}
                                    width={width}
                                    height={height}
                                    onRowClick={handleRowClick}
                                    onSort={handleOnSort}
                                    rowExpandedRender={rowExpandedRender}
                                ></Table>
                            </Grid>
                        </Grid>
                    )}
                </AutoSizer>
            </div>

            <div
                style={{
                    height: FOOTER_HEIGHT,
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: `1px solid ${theme.colors.border}`
                }}
            >
                <ChairSVG />
                <Typography variant="body2" style={{ marginLeft: 4, marginRight: 50, color: theme.colors.midGrey }}>
                    <Tooltip title="Total members of the company">
                        <span>
                            {t('global_total_members')}:{' '}
                            <strong style={{ color: theme.colors.primaryText }}>
                                {companySubscription?.activeSeat}
                            </strong>
                        </span>
                    </Tooltip>{' '}
                    |{' '}
                    <Tooltip title="Total amount of seats that you bought">
                        <span>
                            Total paid seats:{' '}
                            <strong style={{ color: theme.colors.primaryText }}>
                                {companySubscription?.totalSeat}
                            </strong>
                        </span>
                    </Tooltip>
                    {!isExternal && (
                        <>
                            {' '}
                            |{' '}
                            <Tooltip title="The number of available seats that could be used">
                                <span>
                                    Available seats:{' '}
                                    <strong style={{ color: theme.colors.primaryText }}>
                                        {companySubscription?.availableSeat}
                                    </strong>
                                </span>
                            </Tooltip>
                        </>
                    )}
                </Typography>
                {!isExternal && userInfo.ldManager && (
                    <>
                        <SupportAgentSVG />
                        <Typography variant="body2" style={{ marginLeft: 4, color: theme.colors.midGrey }}>
                            LD supporters:{' '}
                            <strong style={{ color: theme.colors.primaryText }}>{internalMembers.length}</strong>
                        </Typography>
                    </>
                )}
            </div>
        </div>
    );
}

MemberTable.propTypes = {
    members: array
};

export default React.memo(MemberTable);
