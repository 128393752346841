import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import AvatarBase from 'components/avatar/Base';
import LocalizationSVG from 'components/svg-icon/LocalizationSVG';

function AdminGroupAvatar({ size = 48 }) {
    const theme = useTheme();
    return (
        <AvatarBase size={size} color={theme.colors.dodgerBlue}>
            <LocalizationSVG color={theme.colors.white} />
        </AvatarBase>
    );
}

export default AdminGroupAvatar;
