import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as advancedSearchActions from 'advancedSearch/actions';
import { enqueueSnackbar } from 'notifier/actions';

const { dispatch, getState } = store;
const advancedSearchHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG??? advancedSearchHandler');
    }

    if (body?.subAction === 'REPLACE_RECORD_END') {
        dispatch(advancedSearchActions.setGlobalAction(null));
        dispatch(advancedSearchActions.setReplacingType(null));

        if (body?.status === `FAILED`) {
            dispatch(
                enqueueSnackbar({
                    message: body?.failedMessage
                })
            );
            return;
        }

        dispatch(
            advancedSearchActions.refreshCurrentAdvancedSearch({
                success: () => {
                    console.log('success');
                    dispatch(
                        enqueueSnackbar({
                            message: `Replaced all matched records with "${state?.advanced?.replacingText}"`
                        })
                    );
                },
                error: () => {
                    console.log('error');
                }
            })
        );
    }
};

export default advancedSearchHandler;
