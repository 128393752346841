import React from 'react';

const BackButtonSVG = ({ color = '#2A293A', ...rest }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect width="20" height="20" rx="3" fill="#C9C9CE" />
            <path
                d="M7.004 11.85C6.416 11.1593 5.8 10.6553 5.156 10.338V10.086C5.8 9.75933 6.416 9.25533 7.004 8.574H7.494C7.214 9.162 6.94333 9.61 6.682 9.918H14.844V10.506H6.682C6.94333 10.814 7.214 11.262 7.494 11.85H7.004Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(BackButtonSVG);
