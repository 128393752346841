import * as types from '../types';
import {
    addColumnsToViewApi,
    getColumnsRecordsApi,
    createColumnCombineApi,
    reorderViewColumnsApi,
    updateColumnPermissionViewApi,
    fillColumnsApi,
    getViewRecordsApiV2,
    deleteColumnsValueApi,
    resizeColumnApi
} from 'services/view';
import {
    updateGridColumnApi,
    deleteGridColumnApi,
    approveTMStatusApi,
    getGridColumnApi,
    uploadFileToColumnApi,
    markDependencyStatusApi,
    markSourceStatusApi
} from 'services/grid';
import uuidv1 from 'uuid/v1';
import { enqueueSnackbar } from 'notifier/actions';
import * as optimisticActions from './optimistic';
import {
    getDisabledColumnIds,
    getScrollLeftPx,
    getViewColumnWidthByColumnId,
    getMaxOrder,
    getViewColumnCustomPropertiesByColumnId,
    getAllDisabledSourceColumnIds,
    getReferenceDisabledColumns,
    getDisableColumnIdsByType,
    getViewColumnsWithReorderAndLanguagePairs
} from 'utils/gridUI/column';
import {
    DEFAULT_COLUMN_WIDTH,
    CREATE_COLUMN_TYPES,
    RECORDS_RENDER,
    MAX_SELECTION_RECORDS,
    DATA_QUERY_OPTIONS,
    INDEX_COLUMN_WIDTH,
    RANGE_TYPES
} from 'const/gridUI';
import { REFERENCE, MULTIPLE_LINES } from 'const/columnTypes';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { deleteObjectProperty, MaxNumberInAr, removeArrayInArray } from 'utils/object';
import isEmpty from 'lodash/isEmpty';
import * as statusActions from './status';
import * as viewFilterActions from './viewFilter';
import * as viewSortActions from './viewSort';
import * as dependencyActions from './dependencies';
import * as dataActions from './data';
import * as gridUIActions from './gridUI';
import * as cellActions from './cell';
import { isTempId } from 'utils/uuid';
import { getViewport } from '../calculatePosition';
import * as aggregationActions from './aggregation';
import {
    DEFAULT_AGGREGATION_TYPE,
    AGGREGATIONS_DISABLED_COLUMNS,
    SYSTEM_COLUMN_IDS,
    SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG,
    AGGREGATIONS_VALUES
} from 'const';
import { generateDefaultName } from 'utils/name';
import * as columnTypes from 'const/columnTypes';
import cloneDeep from 'lodash/cloneDeep';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { getCalcViewAggregationsApi } from 'services/aggregation';
import { getNewAggregationTypeByColumnType } from 'utils/gridUI/aggregation';
import { getDependencyColumnIds } from 'utils/gridUI/dependency';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { _updateViewPermissionSocket } from './views';

export function reorderColumns({
    beforeColumn,
    afterColumn,
    columnId,
    reorderColumns,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId, viewColumns: oldViewColumns } = gridUI;

        dispatch(statusActions.registerDoingAction({ actionId }));

        const viewColumnsClone = [...oldViewColumns.map(viewCol => viewCol.id)];
        let splitColumnIndex = 0;
        let correctColumnIds = [];

        if (beforeColumn) {
            splitColumnIndex = viewColumnsClone.findIndex(viewCol => viewCol === beforeColumn);
        } else {
            splitColumnIndex = viewColumnsClone.findIndex(viewCol => viewCol === columnId);
        }

        let firstArr = viewColumnsClone.slice(0, splitColumnIndex);
        firstArr = firstArr.filter(viewColId => !reorderColumns.includes(viewColId));
        let secondArr = viewColumnsClone.slice(splitColumnIndex);
        secondArr = secondArr.filter(viewColId => !reorderColumns.includes(viewColId));
        correctColumnIds = [...firstArr, ...reorderColumns, ...secondArr];

        const newViewColumns = correctColumnIds.map((columnId, index) => {
            let viewColumn = oldViewColumns.find(viewCol => viewCol.id === columnId);
            return {
                ...viewColumn,
                order: index + 1
            };
        });

        dispatch(
            _reorderColumnsAction({
                newViewColumns
            })
        );
        dispatch(gridUIActions.triggerRecomputedGrid());

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_REORDER_COLUMNS,
                body: {
                    viewColumns: [...oldViewColumns]
                }
            })
        );
        successCallback && successCallback();
        try {
            const data = {
                reorderColumns
            };
            if (beforeColumn) {
                data['beforeColumn'] = beforeColumn;
            }
            if (afterColumn) {
                data['afterColumn'] = afterColumn;
            }

            const viewColumnsLatest = await reorderViewColumnsApi({ dbId, viewId: defaultAccessViewId, data });
            dispatch(
                _reorderColumnsActionSuccess({
                    viewColumns: viewColumnsLatest
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(gridUIActions.triggerRecomputedGrid());
            errorCallback && errorCallback();
        }
    };
}

function _reorderColumnsAction({ newViewColumns }) {
    return {
        type: types.REORDER_COLUMNS,
        payload: {
            newViewColumns
        }
    };
}

function _reorderColumnsActionSuccess({ viewColumns }) {
    return {
        type: types.REORDER_COLUMNS_SUCCESS,
        payload: {
            viewColumns
        }
    };
}

export function resizeColumn({ columnId, columnWidthChange }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, viewColumns, defaultAccessViewId } = gridUI;
        const undoColumnWidth = getViewColumnWidthByColumnId({ viewColumns, columnId });

        if (undoColumnWidth === columnWidthChange) {
            console.log('SAME VALUE -> IGNORE');
            return false;
        }

        dispatch(_resizeColumnAction({ columnId, columnWidthChange }));
        dispatch(statusActions.registerDoingAction({ actionId }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_RESIZE_COLUMN,
                body: {
                    oldColumnWidth: undoColumnWidth,
                    columnId
                }
            })
        );

        try {
            await resizeColumnApi({
                dbId,
                viewId: defaultAccessViewId,
                columnId,
                body: {
                    width: columnWidthChange
                }
            });

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function undoRedoResizeColumn({ columnId, columnWidth }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, viewColumns, defaultAccessViewId } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_resizeColumnAction({ columnId, columnWidthChange: columnWidth }));

        try {
            const viewColumnCustomProperties = getViewColumnCustomPropertiesByColumnId({ viewColumns, columnId });
            await updateColumnPermissionViewApi({
                dbId,
                viewId: defaultAccessViewId,
                columnId,
                body: {
                    customProperties: {
                        ...viewColumnCustomProperties,
                        width: columnWidth
                    }
                }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _resizeColumnAction({ columnId, columnWidthChange }) {
    return {
        type: types.RESIZE_COLUMN,
        payload: {
            columnId,
            columnWidthChange
        }
    };
}

export function addProcessingColumns({ columnIds }) {
    return {
        type: types.ADD_PROCESSING_COLUMNS,
        payload: {
            columnIds
        }
    };
}

export function removeProcessingColumns({ columnIds }) {
    return {
        type: types.REMOVE_PROCESSING_COLUMNS,
        payload: {
            columnIds
        }
    };
}

export function addDisabledColumns({ columnIds }) {
    return {
        type: types.ADD_DISABLED_COLUMNS,
        payload: {
            columnIds
        }
    };
}

export function removeDisabledColumns({ columnIds }) {
    return {
        type: types.REMOVE_DISABLED_COLUMNS,
        payload: {
            columnIds
        }
    };
}

export function removeDisabledSourceColumns({ columnIds }) {
    return {
        type: types.REMOVE_DISABLED_SOURCE_COLUMNS,
        payload: {
            columnIds
        }
    };
}

export function addDisabledSourceColumns({ columnIds }) {
    return {
        type: types.ADD_DISABLED_SOURCE_COLUMNS,
        payload: {
            columnIds
        }
    };
}

export function initDisabledColumns() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewColumns, metaData } = gridUI;

        const formulaIds = viewColumns
            ?.filter(column => {
                const columnDetail = metaData?.[column?.id];
                return columnDetail?.type === columnTypes.FORMULA;
            })
            ?.map(col => col?.id);

        dispatch(resetDisabledSourceColumns());
        dispatch(addDisabledSourceColumns({ columnIds: [...formulaIds] }));
    };
}

export function resetDisabledSourceColumns() {
    return {
        type: types.RESET_DISABLED_SOURCE_COLUMNS
    };
}

export function isFetchingDisabledColumnData() {
    return {
        type: types.IS_FETCHING_DISABLED_COLUMN_DATA
    };
}

export function stopFetchingDisabledColumnData() {
    return {
        type: types.STOP_FETCHING_DISABLED_COLUMN_DATA
    };
}

export function updateSingleColumnData({ data }) {
    return {
        type: types.UPDATE_SINGLE_COLUMN_DATA,
        payload: {
            data
        }
    };
}

export function updateGridColumn({
    oldColumn,
    newColumn,
    isFetchingToUpdateOptionChange,
    isReFetchingRef,
    isReFetchingAggregation,
    errorCallback,
    successCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const isSameType = oldColumn.type === newColumn.type;
        const {
            dbId,
            branchId,
            defaultAccessViewId,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            aggregations,
            quickFilters,
            quickSorts,
            dependencies,
            viewColumns
        } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        // dispatch(updateGridColumnAction({ column: newColumn }));
        const isShowAutoQA = getIsShowAutoQA();

        const columnId = oldColumn?.id;
        const isHasQuickFilter = !!quickFilters?.[columnId];
        const isHasQuickSort = !!quickSorts?.[columnId];

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_COLUMN,
                body: {
                    columnId: columnId,
                    columnData: oldColumn
                }
            })
        );
        if (!isSameType) {
            dispatch(addProcessingColumns({ columnIds: [columnId] }));
        }
        try {
            const updatedColumn = await updateGridColumnApi({
                gridId: branchId,
                dbId,
                column: newColumn.type !== REFERENCE ? deleteObjectProperty(newColumn, 'reference') : newColumn
            });

            if (isHasQuickFilter || isHasQuickSort) {
                dispatch(clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
                dispatch(clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));
                //fetch grid
                const newQuickFilters = { ...quickFilters };
                const newQuickSorts = { ...quickSorts };

                if (isHasQuickFilter) {
                    delete newQuickFilters?.[columnId];
                }

                if (isHasQuickSort) {
                    delete newQuickSorts?.[columnId];
                }

                const quickFiltersFormatted = formatQuickFilters(newQuickFilters);

                const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
                const {
                    data,
                    totalRecords,
                    recordIds,
                    recordMetaData,
                    totalRecordsWithoutFilters
                } = await await getViewRecordsApiV2({
                    defaultAccessViewId,
                    dbId,
                    offset: 0,
                    limit: RECORDS_RENDER,
                    filterQuery: quickFiltersFormatted,
                    sortQuery: newQuickSorts,
                    dependencyColumnIds,
                    isShowAutoQA
                });

                dispatch(
                    dataActions.updateGridData({
                        data,
                        totalRecords,
                        rows: recordIds,
                        recordMetaData,
                        ROW_START_INDEX: 0,
                        ROW_STOP_INDEX: RECORDS_RENDER,
                        totalRecordsWithoutFilters
                    })
                );
            } else {
                if (isFetchingToUpdateOptionChange || isReFetchingRef) {
                    const { data } = await getColumnsRecordsApi({
                        dbId,
                        defaultAccessViewId,
                        offset: ROW_START_INDEX,
                        limit: ROW_STOP_INDEX,
                        columnIds: newColumn?.id
                    });
                    dispatch(dataActions.updateData({ newData: data }));
                }
            }

            if (isReFetchingAggregation) {
                const oldAggregationType = aggregations?.[columnId]?.aggregateType || AGGREGATIONS_VALUES.empty;
                const newAggregationType = getNewAggregationTypeByColumnType({
                    oldType: oldAggregationType,
                    columnType: getCorrectColumnType(newColumn)
                });

                dispatch(
                    aggregationActions.changeAggregation({
                        columnId: columnId,
                        oldType: oldAggregationType,
                        newType: newAggregationType
                    })
                );
            }
            dispatch(
                updateGridColumnAction({
                    column: { ...updatedColumn, defaultValue: updatedColumn?.defaultValue || newColumn?.defaultValue }
                })
            );
            dispatch(optimisticActions.removeAction({ actionId }));
            successCallback && successCallback();
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            let message = error.message;
            const originalMessage = error.originalMessage;

            if (originalMessage && originalMessage.includes('referenced')) {
                message = originalMessage.replace(columnId, oldColumn.name);
                const grids = getState().grid?.list?.[dbId] || [];
                grids.forEach(grid => {
                    message = message.replace(grid.id, grid.name);
                });
            }

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(optimisticActions.revertAction({ actionId }));
            if (!isSameType) {
                dispatch(removeProcessingColumns({ columnIds: [columnId] }));
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function updateGridColumnAction({ column }) {
    return {
        type: types.UPDATE_GRID_COLUMN,
        payload: {
            column
        }
    };
}

export function deleteGridColumn({ columnId, column, columnIndex, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { viewColumns, dbId, branchId, viewFilters, viewSorts, dependencies } = gridUI;

        const isHavingParentDependency =
            dependencies?.filter(dpDc => dpDc?.parent === columnId && !isTempId(dpDc?.id))?.length > 0;

        if (isHavingParentDependency) {
            dispatch(
                enqueueSnackbar({
                    message: `Cannot delete column because at least one column is dependent on it`,
                    type: 'info'
                })
            );

            return;
        }

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(deleteGridColumnAction({ columnId }));
        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_DELETE_COLUMN,
                body: {
                    columnId,
                    columnIndex,
                    column,
                    oldViewColumns: viewColumns
                }
            })
        );

        try {
            //remove column
            await deleteGridColumnApi({
                gridId: branchId,
                dbId,
                columnId
            });

            //clear aggregations local
            dispatch(aggregationActions.removeViewAggregateByColumnId({ columnId }));

            //clear view Filters
            let filterIdsRelateToColumnId = viewFilters
                .filter(viewFilter => viewFilter?.columnId === columnId)
                .map(viewFilter => viewFilter.id);

            dispatch(viewFilterActions.removeMultipleFilters({ filterIds: filterIdsRelateToColumnId }));
            //clear view Sorts
            let sortIdsRelateToColumnId = viewSorts
                .filter(viewSort => viewSort?.columnId === columnId)
                .map(viewSort => viewSort.id);

            dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

            //clear quickFilters, quickSorts local
            dispatch(clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
            //clear quickSorts local
            dispatch(clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));

            //clear child dependencies
            let newDependencies = [...dependencies];
            const childDependencyByColumnId = dependencies.find(dpDc => dpDc?.child === columnId);
            if (childDependencyByColumnId) {
                newDependencies = newDependencies?.filter(dpDc => dpDc?.id !== childDependencyByColumnId?.id);
                dispatch(dependencyActions._deleteDependencySuccess({ dependencyId: childDependencyByColumnId?.id }));
            }

            //generate new gridUI sorts, views
            const newQuickFilters = { ...gridUI.quickFilters };
            const newQuickSorts = { ...gridUI.quickSorts };
            const isHaveQuickFiltersToClear = !isEmpty(newQuickFilters[columnId]);
            const isHaveQuickSortsToClear = !isEmpty(newQuickSorts[columnId]);
            if (isHaveQuickFiltersToClear) {
                delete newQuickFilters[columnId];
            }
            if (isHaveQuickSortsToClear) {
                delete newQuickSorts[columnId];
            }

            if (
                filterIdsRelateToColumnId?.length ||
                sortIdsRelateToColumnId?.length ||
                isHaveQuickFiltersToClear ||
                isHaveQuickSortsToClear
            ) {
                //fetching new section
                await viewFilterActions._fetchRecordsAfterFilter({
                    gridUI: {
                        ...gridUI,
                        quickFilters: newQuickFilters,
                        quickSorts: newQuickSorts,
                        dependencies: newDependencies
                    },
                    dispatch
                });
            }

            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function deleteGridColumnAction({ columnId }) {
    return async function(dispatch, getState) {
        dispatch(_deleteGridColumnAction({ columnId }));
        dispatch(gridUIActions.triggerRecomputedGrid());
    };
}

function _deleteGridColumnAction({ columnId }) {
    return {
        type: types.DELETE_GRID_COLUMN,
        payload: {
            columnId
        }
    };
}

export function setFormatColumnId(columnId) {
    return {
        type: types.SET_FORMAT_COLUMN_ID,
        payload: {
            columnId
        }
    };
}

export function resetColumnMenu() {
    return {
        type: types.RESET_COLUMN_MENU
    };
}

export function clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }) {
    return {
        type: types.CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN,
        payload: {
            columnId
        }
    };
}

export function QuickFilterChange({ columnId, value, type, currentState, operator, fullPathId }) {
    return {
        type: types.QUICK_FILTER_INPUT_CHANGE,
        payload: {
            columnId,
            value,
            type,
            currentState,
            operator,
            fullPathId
        }
    };
}

export function resetQuickFilterValue({ columnId }) {
    return {
        type: types.RESET_QUICK_FILTER_VALUE,
        payload: {
            columnId
        }
    };
}

export function quickFilterExtraChange({ columnId, extraFilter }) {
    return {
        type: types.QUICK_FILTER_EXTRA_CHANGE,
        payload: {
            columnId,
            extraFilter
        }
    };
}

export function quickFilterRegexChange({ columnId, filterMode }) {
    return {
        type: types.QUICK_FILTER_REGEX_CHANGE,
        payload: {
            columnId,
            filterMode
        }
    };
}

export function clearQuickFiltersAfterColumnDeletedOrHiddenSocket({ columnId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { quickFilters, quickSorts } = gridUI;
        dispatch(clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
        dispatch(clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));
        try {
            const newQuickFilters = { ...quickFilters };
            const newQuickSorts = { ...quickSorts };
            const isHaveQuickFiltersToClear = !isEmpty(newQuickFilters[columnId]);
            const isHaveQuickSortsToClear = !isEmpty(newQuickSorts[columnId]);

            if (isHaveQuickFiltersToClear) {
                delete newQuickFilters[columnId];
            }
            if (isHaveQuickSortsToClear) {
                delete newQuickSorts[columnId];
            }
            if (isHaveQuickSortsToClear || isHaveQuickFiltersToClear) {
                await viewFilterActions._fetchRecordsAfterFilter({
                    gridUI: {
                        ...gridUI,
                        quickFilters: newQuickFilters,
                        quickSorts: newQuickSorts
                    },
                    dispatch
                });
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function clearQuickSortsAfterColumnDeletedOrHidden({ columnId }) {
    return {
        type: types.CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN,
        payload: {
            columnId
        }
    };
}

export function QuickSortChange({ columnId, value }) {
    return {
        type: types.QUICK_SORT_CHANGE,
        payload: {
            columnId,
            value
        }
    };
}

export function columnSelection({ columnSelected, columnIndex, isShift, isCtrl, callback }) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const viewColumns = getViewColumnsWithReorderAndLanguagePairs({ auth, gridUI });
        const { totalRecords } = gridUI;

        let columnsSelected = [...gridUI?.columnsSelected];
        if (!isCtrl && !isShift) {
            columnsSelected = [columnSelected];
        } else if (isCtrl) {
            if (columnsSelected.includes(columnSelected)) {
                columnsSelected = columnsSelected.filter(item => item !== columnSelected);
            } else {
                columnsSelected = columnsSelected.concat(columnSelected);
            }
        } else {
            let viewColumnsCopied = [...gridUI.viewColumns];
            let filteredColumns = viewColumnsCopied.filter(col => col.viewable);
            let newColumns = orderBy(filteredColumns, ['order'], ['asc']);
            const columnsCopied = newColumns.map(column => column.id);
            let oldColumnIndex = columnsCopied.findIndex(row => row === gridUI?.oldColumnIdSelected);
            if (oldColumnIndex < 0) {
                columnsSelected = [];
            } else {
                const min = Math.min(oldColumnIndex, columnIndex);
                const max = Math.max(oldColumnIndex, columnIndex);
                columnsSelected = columnsCopied.slice(min, max + 1);
            }
        }

        callback && callback({ columnsSelected });

        const columnIndexes = columnsSelected?.map(columnId => viewColumns?.findIndex(vCol => vCol?.id === columnId));

        const sortedIndexes = sortBy(columnIndexes);

        let isNextTogether = sortedIndexes?.length ? true : false;
        let start = sortedIndexes?.[0];
        for (const i of sortedIndexes?.slice(1)) {
            if (i - start === 1) {
                start = i;
            } else {
                isNextTogether = false;
                break;
            }
        }

        if (isNextTogether) {
            const url = new URL(window.location);

            const _rowStartIndex = 0;
            const _rowStopIndex = Math.max(totalRecords - 1, 0);

            const _columnStartIndex = sortedIndexes?.[0];
            const _columnStopIndex = sortedIndexes?.[sortedIndexes?.length - 1];

            const range = `r${_rowStartIndex + 1}:r${_rowStopIndex + 1}-c${_columnStartIndex + 1}:c${_columnStopIndex +
                1}`;
            url.search = `?find=${range}`;
            window.history.pushState({}, '', url);
        }

        dispatch(
            _columnSelection({
                oldColumnIdSelected: !isShift ? columnSelected : gridUI?.oldColumnIdSelected,
                columnsSelected
            })
        );
    };
}

function _columnSelection({ oldColumnIdSelected, columnsSelected }) {
    return {
        type: types.COLUMN_SELECTION,
        payload: {
            oldColumnIdSelected,
            columnsSelected
        }
    };
}

export function clearColumnSelection() {
    return {
        type: types.CLEAR_COLUMNS_SELECTION
    };
}

export function setColumnsSelection({ columnsSelected, columnId }) {
    return {
        type: types.SET_COLUMNS_SELECTION,
        payload: {
            columnsSelected,
            columnId
        }
    };
}

export function createGridColumnWithoutScroll({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_createGridColumnAction());

        try {
            const { gridUI } = getState();
            const { columns, dbId, defaultAccessViewId, metaData } = gridUI;
            const { gridColumn, viewColumn } = await createColumnCombineApi({
                viewId: defaultAccessViewId,
                dbId,
                body: {
                    defaultValue: '',
                    name: generateDefaultName({
                        list: columns?.map(columnId => metaData?.[columnId]),
                        property: 'name',
                        prefixName: 'Column'
                    }),
                    type: MULTIPLE_LINES
                }
            });
            const newColumn = {
                ...gridColumn,
                editable: viewColumn && viewColumn.editable,
                viewable: true
            };
            const newViewColumn = {
                ...viewColumn,
                viewable: true
            };
            dispatch(
                _createGridColumnSuccessAction({
                    column: newColumn,
                    viewColumn: newViewColumn
                })
            );
            dispatch(_createGridAndFinishScrollAction());
            dispatch(aggregationActions.addAggregationAfterNewColumnIsCreated({ columnId: viewColumn?.id }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback &&
                successCallback({
                    ...newColumn,
                    ...newViewColumn
                });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createGridColumnFailedAction({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

export function createRelativeGridColumnWithBody({
    columnId,
    column,
    type = CREATE_COLUMN_TYPES.RIGHT,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));

        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId, tableInfo, viewColumns } = gridUI;
            const { scroll, scrollbarOverlay, gridRef } = tableInfo;

            const scrollLeft = gridRef?.state?.scrollLeft;
            const scrollTop = gridRef?.state?.scrollTop;

            let body = {
                ...column,
                createColumnPosition: 'relative'
            };

            if (type === CREATE_COLUMN_TYPES.LEFT) {
                body = {
                    ...body,
                    createBeforeColumnId: columnId
                };
            } else {
                body = {
                    ...body,
                    createAfterColumnId: columnId
                };
            }

            const { gridColumn, viewColumn } = await createColumnCombineApi({
                viewId: defaultAccessViewId,
                dbId,
                body
            });
            const newColumn = {
                ...gridColumn,
                editable: viewColumn && viewColumn.editable,
                viewable: true,
                isNew: true
            };
            const newViewColumn = {
                ...viewColumn,
                viewable: true
            };
            dispatch(
                _createRelativeGridColumnSuccess({
                    column: newColumn,
                    viewColumn: newViewColumn,
                    selectedColumnId: columnId,
                    type
                })
            );
            dispatch(aggregationActions.addAggregationAfterNewColumnIsCreated({ columnId: viewColumn?.id }));

            let toColumns = [];

            const selectedIndex = viewColumns?.findIndex(vCol => vCol?.id === columnId);

            const isLeftColumnCreated = type === CREATE_COLUMN_TYPES.LEFT;

            if (isLeftColumnCreated) {
                toColumns = [...viewColumns?.slice(0, Math.max(selectedIndex - 1, 0))]?.map((vCol, index) => ({
                    ...vCol,
                    order: index + 1
                }));
            } else {
                toColumns = [...viewColumns?.slice(0, selectedIndex + 1), viewColumn]?.map((vCol, index) => ({
                    ...vCol,
                    order: index + 1
                }));
            }

            const newColumnScrollLeft = getScrollLeftPx({ viewColumns: toColumns });
            const [viewPortWidth] = getViewport();

            const actualViewportWidth = viewPortWidth - INDEX_COLUMN_WIDTH;

            if (newColumnScrollLeft < scrollLeft || newColumnScrollLeft > actualViewportWidth + scrollLeft) {
                const newScrollLeft = isLeftColumnCreated ? newColumnScrollLeft : scrollLeft + DEFAULT_COLUMN_WIDTH;
                scroll({
                    scrollLeft: newScrollLeft,
                    scrollTop
                });
                scrollbarOverlay.scrollLeft = newScrollLeft;
            }
            dispatch(_createGridAndFinishScrollAction());

            //open edit popup here

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createGridColumnFailedAction({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

export function createRelativeGridColumn({
    columnId,
    type = CREATE_COLUMN_TYPES.RIGHT,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));

        try {
            const { gridUI } = getState();
            const { columns, dbId, defaultAccessViewId, metaData, tableInfo, viewColumns } = gridUI;
            const { scroll, scrollbarOverlay, gridRef } = tableInfo;

            const scrollLeft = gridRef?.state?.scrollLeft;
            const scrollTop = gridRef?.state?.scrollTop;

            let body = {
                name: generateDefaultName({
                    list: columns?.map(columnId => metaData?.[columnId]),
                    property: 'name',
                    prefixName: 'Column'
                }),
                type: MULTIPLE_LINES,
                createColumnPosition: 'relative'
            };

            if (type === CREATE_COLUMN_TYPES.LEFT) {
                body = {
                    ...body,
                    createBeforeColumnId: columnId
                };
            } else {
                body = {
                    ...body,
                    createAfterColumnId: columnId
                };
            }

            const { gridColumn, viewColumn } = await createColumnCombineApi({
                viewId: defaultAccessViewId,
                dbId,
                body
            });
            const newColumn = {
                ...gridColumn,
                editable: viewColumn && viewColumn.editable,
                viewable: true,
                isNew: true
            };
            const newViewColumn = {
                ...viewColumn,
                viewable: true
            };
            dispatch(
                _createRelativeGridColumnSuccess({
                    column: newColumn,
                    viewColumn: newViewColumn,
                    selectedColumnId: columnId,
                    type
                })
            );
            dispatch(aggregationActions.addAggregationAfterNewColumnIsCreated({ columnId: viewColumn?.id }));

            let toColumns = [];

            const selectedIndex = viewColumns?.findIndex(vCol => vCol?.id === columnId);

            const isLeftColumnCreated = type === CREATE_COLUMN_TYPES.LEFT;

            if (isLeftColumnCreated) {
                toColumns = [...viewColumns?.slice(0, Math.max(selectedIndex - 1, 0))]?.map((vCol, index) => ({
                    ...vCol,
                    order: index + 1
                }));
            } else {
                toColumns = [...viewColumns?.slice(0, selectedIndex + 1), viewColumn]?.map((vCol, index) => ({
                    ...vCol,
                    order: index + 1
                }));
            }

            const newColumnScrollLeft = getScrollLeftPx({ viewColumns: toColumns });
            const [viewPortWidth] = getViewport();

            const actualViewportWidth = viewPortWidth - INDEX_COLUMN_WIDTH;

            if (newColumnScrollLeft < scrollLeft || newColumnScrollLeft > actualViewportWidth + scrollLeft) {
                const newScrollLeft = isLeftColumnCreated ? newColumnScrollLeft : scrollLeft + DEFAULT_COLUMN_WIDTH;
                scroll({
                    scrollLeft: newScrollLeft,
                    scrollTop
                });
                scrollbarOverlay.scrollLeft = newScrollLeft;
            }
            dispatch(setFormatColumnId(viewColumn?.id));
            dispatch(_createGridAndFinishScrollAction());

            //open edit popup here

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createGridColumnFailedAction({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

export function duplicateRelativeGridColumn({
    columnId,
    type = CREATE_COLUMN_TYPES.RIGHT,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));

        try {
            const { gridUI } = getState();
            const { dbId, defaultAccessViewId, metaData, tableInfo, viewColumns } = gridUI;
            const { scroll, scrollbarOverlay, fakeColumnCount } = tableInfo;

            const duplicateColumn = metaData?.[columnId];

            console.log('duplicateColumn', duplicateColumn);

            let body = {
                ...duplicateColumn,
                name: `${duplicateColumn?.name} Duplicated`,
                type: duplicateColumn?.type,
                createColumnPosition: 'relative'
            };

            if (type === CREATE_COLUMN_TYPES.LEFT) {
                body = {
                    ...body,
                    createBeforeColumnId: columnId
                };
            } else {
                body = {
                    ...body,
                    createAfterColumnId: columnId
                };
            }

            delete body?.order;
            delete body?.viewable;
            delete body?.id;
            delete body?.publicId;

            const { gridColumn, viewColumn } = await createColumnCombineApi({
                viewId: defaultAccessViewId,
                dbId,
                body
            });
            const newColumn = {
                ...gridColumn,
                editable: viewColumn && viewColumn.editable,
                viewable: true,
                isNew: true
            };
            const newViewColumn = {
                ...viewColumn,
                viewable: true
            };
            dispatch(
                _createRelativeGridColumnSuccess({
                    column: newColumn,
                    viewColumn: newViewColumn,
                    selectedColumnId: columnId,
                    type
                })
            );
            dispatch(aggregationActions.addAggregationAfterNewColumnIsCreated({ columnId: viewColumn?.id }));
            let toColumns = [];
            const selectedIndex = viewColumns?.findIndex(vCol => vCol?.id === columnId);

            if (type === CREATE_COLUMN_TYPES.LEFT) {
                toColumns = [...viewColumns?.slice(0, selectedIndex), viewColumn]?.map((vCol, index) => ({
                    ...vCol,
                    order: index + 1
                }));
            } else {
                toColumns = [...viewColumns?.slice(0, selectedIndex + 1), viewColumn]?.map((vCol, index) => ({
                    ...vCol,
                    order: index + 1
                }));
            }
            const scrollLeft = getScrollLeftPx({ viewColumns: toColumns }) + fakeColumnCount * DEFAULT_COLUMN_WIDTH;
            const [viewPortWidth] = getViewport();

            if (scrollLeft > viewPortWidth) {
                scroll({ scrollLeft });
                scrollbarOverlay.scrollLeft = scrollLeft;
            }

            dispatch(setFormatColumnId(viewColumn?.id));
            dispatch(_createGridAndFinishScrollAction());

            //open edit popup here

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createGridColumnFailedAction({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

function _createRelativeGridColumnSuccess({ column, viewColumn, selectedColumnId, type }) {
    return {
        type: types.CREATE_RELATIVE_GRID_COLUMN_SUCCESS,
        payload: {
            column,
            viewColumn,
            selectedColumnId,
            type
        }
    };
}

export function toggleFakeColumn() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tableInfo, viewColumns } = gridUI;

        const { scrollbarOverlay, fakeColumnCount } = tableInfo;

        const newViewColumns = [
            ...viewColumns,
            {
                viewable: true,
                customProperties: {
                    width: DEFAULT_COLUMN_WIDTH
                }
            }
        ];

        const scrollLeft = getScrollLeftPx({ viewColumns: newViewColumns }) + fakeColumnCount * DEFAULT_COLUMN_WIDTH;
        const [viewPortWidth] = getViewport();

        if (scrollLeft > viewPortWidth) {
            // console.log('scrollLeft', scrollLeft);
            // scroll({ scrollLeft });
            scrollbarOverlay.scrollLeft = scrollLeft;
        }

        setTimeout(() => {
            dispatch(_setOpenFakeColumn(true));

            setTimeout(() => {
                dispatch(_setOpenFakeColumn(false));
            }, 50);
        }, 200);
    };
}

function _setOpenFakeColumn(open) {
    return {
        type: types.SET_OPEN_FAKE_COLUMN,
        payload: {
            open
        }
    };
}

export function createGridColumn({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_createGridColumnAction());

        try {
            const { gridUI } = getState();
            const { tableInfo, columns, dbId, defaultAccessViewId, viewColumns, metaData } = gridUI;

            const { gridColumn, viewColumn } = await createColumnCombineApi({
                viewId: defaultAccessViewId,
                dbId,
                body: {
                    name: generateDefaultName({
                        list: columns?.map(columnId => metaData?.[columnId]),
                        property: 'name',
                        prefixName: 'Column'
                    }),
                    type: MULTIPLE_LINES
                }
            });
            const newColumn = {
                ...gridColumn,
                editable: viewColumn && viewColumn.editable,
                viewable: true,
                isNew: true
            };
            const newViewColumn = {
                ...viewColumn,
                viewable: true
            };
            dispatch(
                _createGridColumnSuccessAction({
                    column: newColumn,
                    viewColumn: newViewColumn
                })
            );
            dispatch(aggregationActions.addAggregationAfterNewColumnIsCreated({ columnId: viewColumn?.id }));
            const { scroll, scrollbarOverlay, fakeColumnCount } = tableInfo;
            const newViewColumns = [...viewColumns, newViewColumn];

            const scrollLeft =
                getScrollLeftPx({ viewColumns: newViewColumns }) + fakeColumnCount * DEFAULT_COLUMN_WIDTH;
            const [viewPortWidth] = getViewport();

            if (scrollLeft > viewPortWidth) {
                scroll({ scrollLeft });
                scrollbarOverlay.scrollLeft = scrollLeft;
            }

            dispatch(setFormatColumnId(viewColumn?.id));
            dispatch(_createGridAndFinishScrollAction());
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_createGridColumnFailedAction({ error: message }));
            return errorCallback && errorCallback(message);
        }
    };
}

function _createGridColumnAction() {
    return {
        type: types.CREATE_GRID_COLUMN
    };
}

function _createGridColumnFailedAction({ error }) {
    return {
        type: types.CREATE_GRID_COLUMN_FAILED,
        payload: {
            error
        }
    };
}

function _createGridAndFinishScrollAction() {
    return {
        type: types.CREATE_GRID_COLUMN_AND_FINISH_SCROLL
    };
}

export function _createGridColumnSuccessAction({ column, index, viewColumn }) {
    return {
        type: types.CREATE_GRID_COLUMN_SUCCESS,
        payload: {
            column,
            index,
            viewColumn
        }
    };
}

export function createGridColumnSocket({ column }) {
    return {
        type: types.CREATE_GRID_COLUMN_SOCKET,
        payload: {
            column
        }
    };
}

export function addGridColumnToView({ viewColumn }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { ROW_START_INDEX, ROW_STOP_INDEX, defaultAccessViewId, dbId, branchId, quickFilters } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(aggregationActions.addAggregationAfterNewColumnIsCreated({ columnId: viewColumn?.id }));
        const isSystemId = SYSTEM_COLUMN_IDS.includes(viewColumn?.id);

        try {
            dispatch(_addGridColumnToView({ viewColumn }));

            const columnDetail = !isSystemId
                ? await getGridColumnApi({ dbId, gridId: branchId, columnId: viewColumn?.id })
                : undefined;
            const { data } = await getColumnsRecordsApi({
                dbId,
                defaultAccessViewId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                columnIds: [viewColumn.id]
            });

            if (columnDetail) {
                const columnType = getCorrectColumnType(columnDetail);
                if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnType)) {
                    const quickFiltersFormatted = formatQuickFilters(quickFilters);

                    const aggregations = await getCalcViewAggregationsApi({
                        dbId,
                        viewId: defaultAccessViewId,
                        columnIds: [viewColumn?.id],
                        aggregateTypes: DEFAULT_AGGREGATION_TYPE,
                        quickFilters: quickFiltersFormatted
                    });

                    const agg = aggregations?.[0];
                    const aggregate = {
                        ...agg,
                        value: agg?.result,
                        isFetching: false,
                        type: DEFAULT_AGGREGATION_TYPE
                    };

                    dispatch(aggregationActions.registerAggregation({ columnId: viewColumn?.id, aggregate }));
                }
            }

            dispatch(dataActions.updateData({ newData: data }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _addGridColumnToView({ viewColumn }) {
    return {
        type: types.ADD_GRID_COLUMN_TO_VIEW_SOCKET,
        payload: {
            viewColumn
        }
    };
}

// eslint-disable-next-line no-unused-vars
async function _addSingleColumnToView({ defaultAccessViewId, dbId, column }) {
    try {
        return await addColumnsToViewApi({ defaultAccessViewId, dbId, columns: [column] });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function updateReorderColumnSocket({ newViewColumns }) {
    return {
        type: types.UPDATE_REORDER_COLUMNS_REALTIME,
        payload: {
            newViewColumns
        }
    };
}

export function fetchGridSectionWithColumns({ columnIds = [], successCallback, errorCallback, isCareData = false }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;

        try {
            const { data } = await getColumnsRecordsApi({
                dbId,
                defaultAccessViewId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                columnIds
            });

            dispatch(dataActions.updateData({ newData: data, isCareData }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function approveColumnTMStatus({ columnId, success, error }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { branchId, dbId, defaultAccessViewId, quickFilters } = gridUI;
        try {
            await approveTMStatusApi({
                gridId: branchId,
                dbId,
                columnId,
                viewId: defaultAccessViewId,
                query: formatQuickFilters(quickFilters)
            });
            success && success();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function approveColumnStatus({ columnId, status, success, error }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { branchId, dbId, defaultAccessViewId, quickFilters } = gridUI;

        try {
            await markSourceStatusApi({
                gridId: branchId,
                dbId,
                columnId,
                status,
                viewId: defaultAccessViewId,
                query: formatQuickFilters(quickFilters)
            });
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function approveColumnDependencyStatus({ columnId, status, error, success }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { branchId, dbId, defaultAccessViewId, quickFilters } = gridUI;

        try {
            await markDependencyStatusApi({
                gridId: branchId,
                dbId,
                columnId,
                status,
                viewId: defaultAccessViewId,
                query: formatQuickFilters(quickFilters)
            });
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function addGridDisabledSourceColumns({ languagePairs = [] }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewColumns, metaData, dependencies } = gridUI;

        const disabledColumnIds = getAllDisabledSourceColumnIds({
            viewColumns,
            metaData,
            dependencies: dependencies?.filter(dpDc => !isTempId(dpDc?.id)),
            languagePairs
        });

        dispatch(resetDisabledSourceColumns());
        dispatch(addDisabledSourceColumns({ columnIds: [...disabledColumnIds] }));
    };
}

export function resetDisabledAndPendingColumnIds() {
    return {
        type: types.RESET_DISABLED_PROCESSING_COLUMNS
    };
}

export function uploadFileToColumn({ columnId, formData, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, gridId } = gridUI;
        try {
            await uploadFileToColumnApi({ dbId, gridId, columnId, formData });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(handleColumnUploadFailed({ columnId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
        }
    };
}

export function uploadFilesStart({ columnId, files, isCombinedWithPathTag }) {
    return {
        type: types.UPLOAD_FILES_START,
        payload: {
            columnId,
            files,
            isCombinedWithPathTag
        }
    };
}

export function resetColumnUploadStatus({ columnId }) {
    return {
        type: types.RESET_COLUMN_UPLOAD_STATUS,
        payload: {
            columnId
        }
    };
}

export function handleColumnUploadFailed({ columnId, error }) {
    return {
        type: types.HANDLE_COLUMN_UPLOAD_FAILED,
        payload: {
            columnId,
            error
        }
    };
}

export function uploadSingleFileSuccessSocket({ columnId, filePath }) {
    return {
        type: types.UPLOAD_SINGLE_FILE_SUCCESS_SOCKET,
        payload: {
            columnId,
            filePath
        }
    };
}

export function uploadSingleFileFailedSocket({ columnId, filePath, error }) {
    return {
        type: types.UPLOAD_SINGLE_FILE_FAILED_SOCKET,
        payload: {
            columnId,
            filePath,
            error
        }
    };
}

export function expandColumnUpload({ columnId }) {
    return {
        type: types.EXPAND_COLUMN_UPLOAD,
        payload: {
            columnId
        }
    };
}

export function resetAllUploadStatus() {
    return {
        type: types.RESET_ALL_UPLOAD_STATUS
    };
}

export function changeColumnViewable({ columnId, viewable, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();
        const {
            viewColumns,
            columnsPermission,
            dbId,
            defaultAccessViewId,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            viewFilters,
            viewSorts,
            quickFilters,
            quickSorts
        } = gridUI;

        const columnDetail = cloneDeep(columnsPermission?.find(colPer => colPer?.id === columnId));
        const viewColumnFound = cloneDeep(viewColumns?.find(viewCol => viewCol?.id === columnId));
        dispatch(statusActions.registerDoingAction({ actionId }));

        try {
            if (viewColumnFound) {
                dispatch(
                    _changeColumnViewable({
                        columnId,
                        viewable,
                        editable: false,
                        order: viewColumnFound?.order,
                        newViewColumns: viewColumns?.map(viewCol => {
                            if (viewCol?.id === columnId) {
                                viewCol.editable = false;
                                viewCol.viewable = viewable;
                            }
                            return viewCol;
                        })
                    })
                );

                dispatch(
                    optimisticActions.commitAction({
                        actionId,
                        type: types.OPTIMISTIC_CHANGE_COLUMN_VIEWABLE,
                        body: {
                            oldViewColumns: viewColumns,
                            columnId,
                            editable: viewColumnFound?.editable,
                            viewable: !viewColumnFound?.viewable
                        }
                    })
                );

                await updateColumnPermissionViewApi({
                    dbId,
                    viewId: defaultAccessViewId,
                    columnId: columnId,
                    body: {
                        editable: false,
                        viewable
                    }
                });

                if (viewable) {
                    const { data } = await getColumnsRecordsApi({
                        dbId,
                        defaultAccessViewId,
                        offset: ROW_START_INDEX,
                        limit: ROW_STOP_INDEX,
                        columnIds: [columnId]
                    });
                    dispatch(dataActions.updateData({ newData: data }));

                    if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnDetail?.type)) {
                        const quickFiltersFormatted = formatQuickFilters(quickFilters);

                        const aggregations = await getCalcViewAggregationsApi({
                            dbId,
                            viewId: defaultAccessViewId,
                            columnIds: [columnId],
                            aggregateTypes: DEFAULT_AGGREGATION_TYPE,
                            quickFilters: quickFiltersFormatted
                        });

                        const agg = aggregations?.[0];
                        const aggregate = {
                            ...agg,
                            value: agg?.result,
                            isFetching: false,
                            type: DEFAULT_AGGREGATION_TYPE
                        };

                        dispatch(aggregationActions.registerAggregation({ columnId, aggregate }));
                    }
                } else {
                    let filterIdsRelateToColumnId = viewFilters
                        .filter(viewFilter => viewFilter?.columnId === columnId)
                        .map(viewFilter => viewFilter.id);

                    dispatch(viewFilterActions.removeMultipleFilters({ filterIds: filterIdsRelateToColumnId }));
                    //clear view Sorts
                    let sortIdsRelateToColumnId = viewSorts
                        .filter(viewSort => viewSort?.columnId === columnId)
                        .map(viewSort => viewSort.id);

                    dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

                    //clear quickFilters, quickSorts local
                    dispatch(clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }));
                    //clear quickSorts local
                    dispatch(clearQuickSortsAfterColumnDeletedOrHidden({ columnId }));

                    dispatch(viewSortActions.removeMultipleSorts({ sortIds: sortIdsRelateToColumnId }));

                    //generate new gridUI sorts, views
                    const newQuickFilters = { ...quickFilters };
                    const newQuickSorts = { ...quickSorts };
                    const isHaveQuickFiltersToClear = !isEmpty(newQuickFilters[columnId]);
                    const isHaveQuickSortsToClear = !isEmpty(newQuickSorts[columnId]);
                    if (isHaveQuickFiltersToClear) {
                        delete newQuickFilters[columnId];
                    }
                    if (isHaveQuickSortsToClear) {
                        delete newQuickSorts[columnId];
                    }

                    if (
                        filterIdsRelateToColumnId?.length ||
                        sortIdsRelateToColumnId?.length ||
                        isHaveQuickFiltersToClear ||
                        isHaveQuickSortsToClear
                    ) {
                        //fetching new section
                        await viewFilterActions._fetchRecordsAfterFilter({
                            gridUI: {
                                ...gridUI,
                                quickFilters: newQuickFilters,
                                quickSorts: newQuickSorts
                            },
                            dispatch
                        });
                    }
                }
            } else {
                const nextOrder = MaxNumberInAr(viewColumns?.map(viewCol => viewCol?.order)) + 1;
                const fakeViewColumn = {
                    id: columnId,
                    order: nextOrder,
                    viewable: true,
                    editable: false
                };

                dispatch(
                    _changeColumnViewable({
                        columnId,
                        editable: false,
                        viewable,
                        order: nextOrder,
                        newViewColumns: [...viewColumns, fakeViewColumn]
                    })
                );

                const newViewColumns = await addColumnsToViewApi({
                    defaultAccessViewId,
                    dbId,
                    columns: [
                        {
                            ...columnDetail,
                            order: nextOrder,
                            viewable,
                            editable: false
                        }
                    ]
                });

                dispatch(
                    _updateColumnOrder({
                        columnId,
                        order: newViewColumns?.[0]?.order || nextOrder
                    })
                );

                const columnPermissionResponse = await updateColumnPermissionViewApi({
                    dbId,
                    viewId: defaultAccessViewId,
                    columnId: columnId,
                    body: {
                        editable: false,
                        viewable
                    }
                });

                dispatch(_updateViewPermissionSocket({ columnId, data: columnPermissionResponse }));

                const { data } = await getColumnsRecordsApi({
                    dbId,
                    defaultAccessViewId,
                    offset: ROW_START_INDEX,
                    limit: ROW_STOP_INDEX,
                    columnIds: [columnId]
                });
                dispatch(dataActions.updateData({ newData: data }));

                if (!AGGREGATIONS_DISABLED_COLUMNS.includes(columnDetail?.type)) {
                    const quickFiltersFormatted = formatQuickFilters(quickFilters);

                    const aggregations = await getCalcViewAggregationsApi({
                        dbId,
                        viewId: defaultAccessViewId,
                        columnIds: [columnId],
                        aggregateTypes: DEFAULT_AGGREGATION_TYPE,
                        quickFilters: quickFiltersFormatted
                    });

                    const agg = aggregations?.[0];
                    const aggregate = {
                        ...agg,
                        value: agg?.result,
                        isFetching: false,
                        type: DEFAULT_AGGREGATION_TYPE
                    };

                    dispatch(aggregationActions.registerAggregation({ columnId, aggregate }));
                }
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(gridUIActions.triggerRecomputedGrid());
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function _changeColumnViewable({ columnId, newViewColumns, editable, viewable, order }) {
    return {
        type: types.CHANGE_COLUMN_VIEWABLE,
        payload: {
            newViewColumns,
            columnId,
            editable,
            viewable,
            order
        }
    };
}

export function changeColumnEditable({ columnId, editable, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();
        const { viewColumns, columnsPermission, dbId, defaultAccessViewId, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;

        const columnDetail = cloneDeep(columnsPermission?.find(colPer => colPer?.id === columnId));
        const viewColumnFound = cloneDeep(viewColumns?.find(viewCol => viewCol?.id === columnId));
        dispatch(statusActions.registerDoingAction({ actionId }));

        try {
            // column not in view columns
            if (viewColumnFound) {
                dispatch(
                    _changeColumnEditable({
                        columnId,
                        editable,
                        viewable: true,
                        newViewColumns: viewColumns?.map(viewCol => {
                            if (viewCol?.id === columnId) {
                                viewCol.editable = editable;
                                viewCol.viewable = true;
                            }
                            return viewCol;
                        })
                    })
                );

                dispatch(
                    optimisticActions.commitAction({
                        actionId,
                        type: types.OPTIMISTIC_UPDATE_VIEW_EDIT_COLUMNS_PERMISSION,
                        body: {
                            oldViewColumns: viewColumns,
                            columnId,
                            editable: !editable,
                            viewable: viewColumnFound?.viewable
                        }
                    })
                );

                await updateColumnPermissionViewApi({
                    dbId,
                    viewId: defaultAccessViewId,
                    columnId: columnId,
                    body: {
                        editable,
                        viewable: true
                    }
                });

                if (!viewColumnFound?.viewable) {
                    const { data } = await getColumnsRecordsApi({
                        dbId,
                        defaultAccessViewId,
                        offset: ROW_START_INDEX,
                        limit: ROW_STOP_INDEX,
                        columnIds: [columnId]
                    });
                    dispatch(dataActions.updateData({ newData: data }));
                }
            } else {
                const nextOrder = MaxNumberInAr(viewColumns?.map(viewCol => viewCol?.order)) + 1;
                const fakeViewColumn = {
                    id: columnId,
                    order: nextOrder,
                    viewable: true,
                    editable
                };

                dispatch(
                    _changeColumnEditable({
                        columnId,
                        editable,
                        viewable: true,
                        newViewColumns: [...viewColumns, fakeViewColumn]
                    })
                );
                const newViewColumns = await addColumnsToViewApi({
                    defaultAccessViewId,
                    dbId,
                    columns: [
                        {
                            ...columnDetail,
                            viewable: true,
                            editable
                        }
                    ]
                });

                dispatch(
                    _updateColumnOrder({
                        columnId,
                        order: newViewColumns?.[0]?.order || nextOrder
                    })
                );

                const { data } = await getColumnsRecordsApi({
                    dbId,
                    defaultAccessViewId,
                    offset: ROW_START_INDEX,
                    limit: ROW_STOP_INDEX,
                    columnIds: [columnId]
                });
                dispatch(dataActions.updateData({ newData: data }));
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(gridUIActions.triggerRecomputedGrid());
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function _updateColumnOrder({ columnId, order }) {
    return {
        type: types.UPDATE_VIEW_COLUMN_ORDER,
        payload: {
            order,
            columnId
        }
    };
}

export function _changeColumnEditable({ columnId, newViewColumns, editable, viewable }) {
    return {
        type: types.CHANGE_COLUMN_EDITABLE,
        payload: {
            newViewColumns,
            columnId,
            editable,
            viewable
        }
    };
}

export function enableColumnInViewWithOrder({ columnId, order = 1, isEditable, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();

        const {
            columnsPermission,
            viewColumns,
            metaData,
            currentView,
            dbId,
            tableInfo,
            ROW_START_INDEX,
            ROW_STOP_INDEX
        } = gridUI;
        const columnDetail = columnsPermission?.find(col => col?.id === columnId);
        const isColumnExistedInView = Boolean(viewColumns?.find(viewCol => viewCol?.id === columnId));
        const restViewColumns = viewColumns?.filter(viewCol => viewCol?.id !== columnTypes.PATH_TAG);

        if (!columnDetail) {
            console.log('not found in column permissions || already existed in view columns');
            return;
        }

        const newViewColumnAdded = {
            id: columnId,
            viewable: true,
            editable: isEditable,
            order: getMaxOrder(viewColumns) + 1
        };

        const newViewColumns = [newViewColumnAdded, ...viewColumns];

        const addedViewColumn = {
            ...columnDetail,
            editable: true,
            viewable: true
        };
        const newMetaData = {
            ...metaData,
            [columnId]: addedViewColumn
        };

        const isNeedReorder = viewColumns?.length > 0;

        // const newViewColumnIds = newViewColumns?.map(col => col?.id);
        const newViewColumnsWithOrders = newViewColumns?.map((col, index) => ({
            ...col,
            order: index + 1
        }));

        dispatch(statusActions.registerDoingAction({ actionId }));

        const reOrderBody = {
            reorderColumns: [columnId],
            beforeColumn: restViewColumns?.[0]?.id
        };

        try {
            dispatch(
                _enableColumnInViewWithOrder({
                    newViewColumnsWithOrders,
                    newColumn: columnDetail,
                    newMetaData,
                    isEditable
                })
            );
            //optimistic
            dispatch(
                optimisticActions.commitAction({
                    actionId,
                    type: types.OPTIMISTIC_ENABLE_COLUMN_IN_VIEW_ORDER,
                    body: {
                        viewColumns: [...viewColumns],
                        columnId
                    }
                })
            );

            const { data } = await getColumnsRecordsApi({
                dbId,
                defaultAccessViewId: currentView?.id,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                columnIds: [columnId]
            });

            dispatch(dataActions.updateData({ newData: data }));
            dispatch(gridUIActions.triggerRecomputedGrid());

            if (isNeedReorder) {
                const { scroll, scrollbarOverlay } = tableInfo;

                if (!isColumnExistedInView) {
                    const columnWithoutOrder = {
                        ...addedViewColumn
                    };
                    delete columnWithoutOrder?.order;
                    await addColumnsToViewApi({
                        defaultAccessViewId: currentView?.id,
                        dbId,
                        columns: [columnWithoutOrder]
                    });
                } else {
                    await updateColumnPermissionViewApi({
                        dbId,
                        viewId: currentView?.id,
                        columnId: columnTypes.PATH_TAG,
                        body: {
                            editable: isEditable,
                            viewable: true
                        }
                    });
                }

                await reorderViewColumnsApi({ dbId, viewId: currentView?.id, data: reOrderBody });
                successCallback && successCallback();
                dispatch(statusActions.removeDoingAction({ actionId }));
                scroll({ scrollLeft: 0 });
                scrollbarOverlay.scrollLeft = 0;
                return;
            }

            if (!isColumnExistedInView) {
                const columnWithoutOrder = {
                    ...addedViewColumn
                };
                delete columnWithoutOrder?.order;
                await addColumnsToViewApi({
                    defaultAccessViewId: currentView?.id,
                    dbId,
                    columns: [columnWithoutOrder]
                });
            } else {
                await updateColumnPermissionViewApi({
                    dbId,
                    viewId: currentView?.id,
                    columnId: columnTypes.PATH_TAG,
                    body: {
                        editable: isEditable,
                        viewable: true
                    }
                });
            }

            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(gridUIActions.triggerRecomputedGrid());
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

function _enableColumnInViewWithOrder({ newViewColumnsWithOrders, newColumn, newMetaData, isEditable }) {
    return {
        type: types.ADD_COLUMN_IN_VIEW_WITH_ORDER,
        payload: {
            newViewColumnsWithOrders,
            newColumn,
            newMetaData,
            isEditable
        }
    };
}

export function clearNewColumnStatus(columnId) {
    return {
        type: types.CLEAR_IS_NEW,
        payload: {
            columnId
        }
    };
}

export function fillColumns() {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            viewColumns,
            metaData,
            quickFilters,
            quickSorts
        } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        const referenceDisabledColumns = getReferenceDisabledColumns({ gridUI, auth });

        const {
            columnIds: rangeColumnIds,
            recordIds,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length || !rangeColumnIds?.length) {
            return dispatch(statusActions.registerDoingAction({ actionId }));
        }

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });

        const affectedColumnIds = removeArrayInArray(rangeColumnIds, [
            ...disabledCols,
            ...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG
        ]);
        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        let body = {
            fromColumnIds: affectedColumnIds,
            fromRecordIds: recordIds
        };

        if (!isEmpty(quickFiltersFormatted)) {
            body = {
                ...body,
                query: JSON.stringify(quickFiltersFormatted)
            };
        }

        if (!isEmpty(quickSorts)) {
            body = {
                ...body,
                sort: JSON.stringify(quickSorts)
            };
        }
        try {
            await fillColumnsApi({
                dbId,
                defaultAccessViewId,
                body
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
        }
    };
}

export function fillColumnsSocket({ columnIds }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            defaultAccessViewId,
            dbId,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            quickFilters,
            quickSorts,
            rowStartIndex,
            rowStopIndex
        } = gridUI;

        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        try {
            const { recordIds, data, totalRecords } = await getColumnsRecordsApi({
                dbId,
                defaultAccessViewId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                columnIds,
                filterQuery: quickFiltersFormatted,
                sortQuery: quickSorts
            });

            dispatch(dataActions.updateData({ newData: data, isCareData: true }));
            dispatch(_autoFillSuccess({ rows: recordIds, totalRecords }));
            dispatch(removeProcessingColumns({ columnIds }));

            if (rowStartIndex <= totalRecords - 1 && rowStopIndex <= totalRecords - 1) return;
            dispatch(cellActions.resetSelection());
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _autoFillSuccess({ totalRecords, rows }) {
    return {
        type: types.AUTO_FILL_SUCCESS,
        payload: {
            rows,
            totalRecords
        }
    };
}

export function deleteColumnsValue({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();

        const {
            defaultAccessViewId,
            dbId,
            disabledColumns,
            processingColumns,
            disabledSourceColumns,
            viewColumns,
            metaData,
            quickFilters,
            quickSorts
        } = gridUI;

        const { columnIds: rangeColumnIds } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });
        const referenceDisabledColumns = getReferenceDisabledColumns({ gridUI, auth });

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });

        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });

        const affectedColumnIds = removeArrayInArray(rangeColumnIds, [...disabledCols, columnTypes.PATH_TAG]);
        if (isEmpty(affectedColumnIds)) {
            console.log('affectedColumnIds', affectedColumnIds);
            successCallback && successCallback();
            return;
        }
        dispatch(statusActions.registerDoingAction({ actionId }));

        let body = {};
        const quickFiltersFormatted = formatQuickFilters(quickFilters);

        if (!isEmpty(quickFiltersFormatted)) {
            body = {
                ...body,
                query: JSON.stringify(quickFiltersFormatted)
            };
        }

        if (!isEmpty(quickSorts)) {
            body = {
                ...body,
                sort: JSON.stringify(quickSorts)
            };
        }

        try {
            await deleteColumnsValueApi({
                dbId,
                defaultAccessViewId,
                body: {
                    ...body,
                    columnIds: affectedColumnIds
                }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function undoGridColumnV2({ metaData, columnsPermission, viewColumns, dependencies, viewFilters, viewSorts }) {
    return {
        type: types.UNDO_DELETE_COLUMN_V2,
        payload: {
            metaData,
            columnsPermission,
            viewColumns,
            dependencies,
            viewFilters,
            viewSorts
        }
    };
}
