import React from 'react';

function DeleteIconSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="4" width="8" height="1.69778" rx="0.848889" fill={color} />
        </svg>
    );
}

export default React.memo(DeleteIconSVG);
