import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Node, Range, Transforms, Element as ElementSlate } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import Element from 'components/formula/components';
import { makeStyles } from '@material-ui/styles';
import { getFormulaServerValue, getSlateDataFromPayload } from 'utils/gridUI/formula';
import TreeSuggesstion from './TreeSuggesstion';
import PopperMenu from 'components/menus/Popper';
import { DESERIALIZE_TYPES } from 'components/formula/const';
import { withPayload } from 'components/formula/plugins/withPayload';
import { Grid } from '@material-ui/core';
import { serverSpacerSymbol, serverTriggerSymbol } from 'gridUI/automations/const';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { checkContainId } from 'utils/clickAway';
import classnames from 'classnames';
import {
    useAutomationNodeTreeOptions,
    useAutomationPayloadTreeOptions,
    useGetAutomationDetail
} from 'hooks/gridUI/automation';
import { isKbCopy, isKbDelete, isKbEscape, isKbPaste, isKbRedo, isKbUndo } from 'utils/keyboard';
import { useTranslation } from 'react-i18next';
import * as clipboardy from 'clipboardy';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 14px 30px 14px',
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        height: 280,
        width: `calc(50vw - 40px)`,
        overflow: 'auto',
        '& p': {
            margin: '0 !important'
        },
        '& span': {
            marginBottom: '3px !important'
        }
    },
    flex: {
        display: 'flex'
    },
    addContainer: {
        position: 'absolute',
        top: 5,
        right: 5,
        cursor: 'pointer'
    },
    addTrigger: {
        marginBottom: 12,
        cursor: 'pointer',
        width: 'fit-content'
    },
    addTriggerData: {
        color: theme.palette.primary.main,
        marginLeft: 8
    }
}));

// @refresh reset
const EditorPayload = (
    {
        text,
        onChange,
        placeholder,
        className,
        handleKeyDown,
        mentionClassName,
        afterClickInsertSuggestion,
        onBlur,
        selectedNodeOrder,
        advancedMode,
        ...rest
    },
    ref
) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(getSlateDataFromPayload({ string: text }));
    const [target, setTarget] = useState(false);
    const [search, setSearch] = useState('');
    const rootRef = useRef();
    const automationDetail = useGetAutomationDetail();
    const treeOptions = useAutomationPayloadTreeOptions(advancedMode);
    const nodeTreeOptions = useAutomationNodeTreeOptions(advancedMode);
    const isPasted = useRef();
    const clickedRemovePayload = useRef();

    const editorRef = useRef();
    if (!editorRef.current) editorRef.current = withPayload(withReact(withHistory(createEditor())));
    const editor = editorRef.current;

    const allOptions = useMemo(() => {
        return [...treeOptions, ...nodeTreeOptions];
    }, [nodeTreeOptions, treeOptions]);

    const options = useMemo(() => {
        return allOptions.filter(node => node.nodeOrder < selectedNodeOrder);
    }, [allOptions, selectedNodeOrder]);

    const triggerNodesAndNodesObj = useMemo(() => {
        return [...(automationDetail?.triggerNodes || []), ...(automationDetail?.nodes || [])].reduce((acc, cur) => {
            const isTrigger = !!cur.trigger;
            const prefix = isTrigger ? 'trigger' : 'node';
            acc[`${prefix}${cur.id}`] = cur;
            return acc;
        }, {});
    }, [automationDetail.nodes, automationDetail.triggerNodes]);

    const resetUndoRedo = useCallback(() => {
        if (editor?.history) {
            editor.history = {
                redos: [],
                undos: []
            };
        }
    }, [editor]);

    const onRemovePayload = useCallback(() => {
        clickedRemovePayload.current = true;
        setTimeout(() => {
            clickedRemovePayload.current = false;
            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
                const currentNode = Node.parent(editor, selection.anchor.path);
                if (ElementSlate.isElement(currentNode)) {
                    if (editor.isVoid(currentNode)) {
                        editor.deleteBackward('block');
                        if (ReactEditor.isFocused(editor)) {
                            ReactEditor.blur(editor);
                        }
                        setTarget(null);
                    }
                }
            }
        }, 200);
    }, [editor]);

    const renderElement = useCallback(
        props => (
            <Element
                {...props}
                triggerNodesAndNodesObj={triggerNodesAndNodesObj}
                selectedNodeOrder={selectedNodeOrder}
                onRemovePayload={onRemovePayload}
            />
        ),
        [triggerNodesAndNodesObj, selectedNodeOrder, onRemovePayload]
    );

    const editorSelection = useRef({
        anchor: {
            offset: 0,
            path: [0, 0]
        },
        focus: {
            offset: 0,
            path: [0, 0]
        }
    });

    const handleClose = useCallback(() => {
        setTarget(false);
    }, []);

    const insertTrigger = useCallback(
        slug => {
            slug = `${serverTriggerSymbol}${slug}${serverSpacerSymbol}`;
            const trigger = {
                type: DESERIALIZE_TYPES.PAYLOAD,
                slug,
                children: [{ text: slug }]
            };
            if (search) {
                Transforms.select(editor, {
                    ...target,
                    focus: {
                        ...target.focus,
                        offset: target.anchor.offset + search.length + 1
                    }
                });
            } else {
                Transforms.select(editor, editorSelection.current);
            }
            ReactEditor.focus(editor);
            Transforms.insertNodes(editor, [trigger, { text: '' }]);
            setTimeout(() => {
                Transforms.move(editor, { distance: 1, unit: 'character' });
            }, 20);
        },
        [search, editor, target]
    );

    const onClickSuggestion = useCallback(
        opt => {
            insertTrigger(opt.serverParam);
            afterClickInsertSuggestion && afterClickInsertSuggestion();
        },
        [insertTrigger, afterClickInsertSuggestion]
    );

    const _transformSelectStart = useCallback(() => {
        Transforms.select(editor, {
            anchor: {
                offset: 0,
                path: [0, 0]
            },
            focus: {
                offset: 0,
                path: [0, 0]
            }
        });
    }, [editor]);

    const _transformSelectEnd = useCallback(() => {
        const lastCharPosition = Editor.end(editor, []);
        Transforms.select(editor, lastCharPosition);
    }, [editor]);

    const onKeyDown = useCallback(
        async event => {
            if (isKbPaste(event)) {
                isPasted.current = true;
                setTarget(null);
                return;
            }
            if (isKbCopy(event)) {
                event.preventDefault();
                const selectedFragment = Editor.fragment(editor, editor.selection);
                const copiedText = getFormulaServerValue(selectedFragment);
                try {
                    await clipboardy.write(copiedText);
                } catch (error) {
                    console.log('error', error);
                }
                return;
            }
            if (isKbDelete(event)) {
                const { selection } = editor;
                if (selection && Range.isCollapsed(selection)) {
                    const currentNode = Node.parent(editor, selection.anchor.path);
                    if (ElementSlate.isElement(currentNode)) {
                        if (editor.isVoid(currentNode)) {
                            event.preventDefault();
                            editor.deleteBackward('block');
                        }
                    }
                }
            }
            if (isKbEscape(event)) {
                if (target) {
                    event.preventDefault();
                    handleClose();
                }
            }
            if (isKbUndo(event) || isKbRedo(event)) {
                if (target) {
                    setTimeout(() => {
                        if (!editor.selection) {
                            _transformSelectEnd();
                            ReactEditor.focus(editor);
                        }
                    }, 0);
                }
            }
            handleKeyDown && handleKeyDown(event);
        },
        [target, editor, handleClose, _transformSelectEnd, handleKeyDown]
    );

    const handleChange = useCallback(
        changedValue => {
            try {
                const newText = getFormulaServerValue(changedValue);
                onChange(newText);
                if (isPasted.current) {
                    isPasted.current = false;
                    _transformSelectStart();
                    if (ReactEditor.isFocused(editor)) {
                        ReactEditor.blur(editor);
                    }
                    setValue(getSlateDataFromPayload({ string: newText }));
                    resetUndoRedo();
                } else {
                    setValue(changedValue);
                }
            } catch (e) {
                console.log('editorpayload handleChange', e);
            }
        },
        [_transformSelectStart, editor, onChange, resetUndoRedo]
    );

    useImperativeHandle(ref, () => ({
        reset: ({ text }) => {
            setValue(getSlateDataFromPayload({ string: text }));
        },
        transformSelectStart: _transformSelectStart,
        transformSelectEnd: _transformSelectEnd,
        transformMove: () => {
            Transforms.move(editor);
        },
        focus: (moveToStart, moveToEnd = true) => {
            if (!ReactEditor.isFocused(editor)) {
                ReactEditor.focus(editor);
            }
            if (moveToStart) {
                setTimeout(_transformSelectStart, 10);
                return;
            }
            if (moveToEnd) {
                setTimeout(_transformSelectEnd, 10);
                return;
            }
        },
        focusToSelection: selection => {
            if (selection && selection.anchor !== null && selection.focus !== null) {
                Transforms.select(editor, selection);
                ReactEditor.focus(editor);
            }
        },
        blur: () => {
            if (ReactEditor.isFocused(editor)) {
                ReactEditor.blur(editor);
            }
        },
        getRangeSelection: () => {
            const { selection } = editor;
            return selection;
        },
        insertText: ({ text, selection }) => {
            if (text && selection) {
                Transforms.select(editor, selection);
                Transforms.insertText(editor, text);
                handleClose();
            }
        }
    }));

    const onAddClick = useCallback(() => {
        if (clickedRemovePayload.current) return;
        setTarget(true);
        setSearch('');
    }, []);

    const handleClickAway = useCallback(
        e => {
            if (checkContainId(e, 'add-btn') || checkContainId(e, 'editable-payload')) return;
            handleClose();
        },
        [handleClose]
    );

    return (
        <>
            {target && (
                <PopperMenu
                    handleClickAway={handleClickAway}
                    anchorEl={rootRef.current}
                    container={rootRef.current}
                    placement="bottom"
                    paperClassName={classes.paperClassName}
                    style={{ width: rootRef.current.offsetWidth }}
                >
                    <TreeSuggesstion options={options} handleSelect={onClickSuggestion} />
                </PopperMenu>
            )}
            <Grid item style={{ position: 'relative' }}>
                {treeOptions?.length > 0 && (
                    <Grid
                        className={classes.addTrigger}
                        item
                        container
                        alignItems="center"
                        wrap="nowrap"
                        onClick={onAddClick}
                        id="add-btn"
                    >
                        <Grid item className={classes.flex}>
                            <ColorAddSVG />
                        </Grid>
                        <Grid item>
                            <p className="body1 ml-2 text-text-primary">{t('automation_select_input_value')}</p>
                        </Grid>
                    </Grid>
                )}
                <Grid item ref={rootRef}>
                    <Slate editor={editor} value={value} onChange={handleChange}>
                        <Editable
                            id="editable-payload"
                            renderElement={renderElement}
                            onKeyDown={onKeyDown}
                            placeholder={placeholder}
                            onClick={() => {
                                if (editor.selection) {
                                    editorSelection.current = editor.selection;
                                }
                                onAddClick();
                            }}
                            onBlur={() => {
                                if (editor.selection) {
                                    editorSelection.current = editor.selection;
                                }
                                onBlur && onBlur({ text: getFormulaServerValue(value) });
                            }}
                            className={classnames(classes.root, className)}
                            {...rest}
                        />
                    </Slate>
                </Grid>
            </Grid>
        </>
    );
};

const EditorMentionRef = forwardRef(EditorPayload);

export default React.memo(EditorMentionRef);
