import React from 'react';

function UserPlusSVG({ ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id=" Access View &#38; Member/Invite Member">
                <path
                    id="Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.971 2.5281L13.9714 0.600098H12.5286L12.528 2.5281L10.6 2.52867V3.97153L12.528 3.9711L12.5286 5.9001H13.9714L13.971 3.9711L15.9 3.97153V2.52867L13.971 2.5281ZM8.7807 2.4694C8.14717 1.82319 7.38694 1.5001 6.5 1.5001C5.61306 1.5001 4.85283 1.82953 4.2193 2.4884C3.58577 3.12193 3.26901 3.88216 3.26901 4.7691C3.26901 5.65605 3.58577 6.41627 4.2193 7.04981C4.85283 7.68334 5.61306 8.0001 6.5 8.0001C7.38694 8.0001 8.14717 7.68334 8.7807 7.04981C9.41423 6.41627 9.73099 5.65605 9.73099 4.7691C9.73099 3.88216 9.41423 3.1156 8.7807 2.4694ZM6.5 8.79834C7.31092 8.79834 8.19785 8.91238 9.16082 9.14045C10.1238 9.36852 11.0044 9.74863 11.8026 10.2808C12.6009 10.813 13 11.4085 13 12.0673V14.5001H0V12.0673C0 11.4085 0.399119 10.813 1.19737 10.2808C1.99562 9.74863 2.87621 9.36852 3.83918 9.14045C4.80215 8.91238 5.68908 8.79834 6.5 8.79834ZM6.5 10.3568C5.38498 10.3568 4.28266 10.5659 3.19298 10.984C2.10331 11.4021 1.55848 11.7633 1.55848 12.0673V12.9416H11.4415V12.0673C11.4415 11.7633 10.8967 11.4085 9.80702 11.003C8.71734 10.5722 7.61502 10.3568 6.5 10.3568ZM6.5 3.05858C6.01852 3.05858 5.61306 3.22329 5.28363 3.55273C4.95419 3.88217 4.78947 4.28762 4.78947 4.7691C4.78947 5.25059 4.95419 5.64971 5.28363 5.96647C5.61306 6.28324 6.01852 6.44162 6.5 6.44162C6.98148 6.44162 7.38694 6.28324 7.71637 5.96647C8.04581 5.64971 8.21053 5.25059 8.21053 4.7691C8.21053 4.28762 8.04581 3.88217 7.71637 3.55273C7.38694 3.22329 6.98148 3.05858 6.5 3.05858Z"
                    fill="#78778B"
                />
            </g>
        </svg>
    );
}

export default React.memo(UserPlusSVG);
