import * as types from '../types';

const handler = {
    [types.SET_COPIED_RANGE](state, { payload }) {
        return {
            ...state,
            copiedRange: payload
        };
    }
};

export default handler;
