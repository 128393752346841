import React from 'react';

function UpdateJsonSVG({ color = '#FFF', ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="8" fill="#6E5BBE" fillOpacity="0.8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.643 10.625C25.0448 10.625 25.3127 10.7589 25.5805 11.0268L28.9287 14.375C29.1966 14.6429 29.3305 14.9107 29.3305 15.3125V26.6964C29.3305 28.3036 27.9912 29.5089 26.518 29.5089H17.4109C15.8037 29.5089 14.5984 28.1696 14.5984 26.6964V24.2857H16.3395V26.6964C16.3395 27.2321 16.7412 27.7679 17.4109 27.7679H26.518C27.0537 27.7679 27.5895 27.3661 27.5895 26.6964V16.6518H23.8395C23.4377 16.6518 23.0359 16.25 23.0359 15.8482V12.3661H17.277C16.7412 12.3661 16.2055 12.7679 16.2055 13.4375V19.4643H14.5984V13.4375C14.5984 11.8304 15.9377 10.625 17.4109 10.625H24.643ZM24.777 12.6339V14.9107H27.3216L24.777 12.6339ZM12.0536 21.0715H15.2679H20.625L19.6875 20.134C19.4196 19.8661 19.4196 19.3304 19.6875 19.0625C19.9554 18.7947 20.4911 18.7947 20.7589 19.0625L23.0357 21.4732C23.3036 21.6072 23.3036 21.875 23.3036 22.009C23.3036 22.2768 23.1696 22.4107 23.0357 22.5447L20.8929 24.5536C20.7589 24.8215 20.4911 24.8215 20.3571 24.8215C20.0893 24.8215 19.9554 24.6875 19.8214 24.5536C19.5536 24.2857 19.5536 23.75 19.8214 23.4822L20.4911 22.6786H12.0536C11.6518 22.6786 11.25 22.2768 11.25 21.875C11.25 21.4732 11.6518 21.0715 12.0536 21.0715Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(UpdateJsonSVG);
