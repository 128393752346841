import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Collapse } from '@material-ui/core';
import ListSelect from 'components/list/Select';
import ButtonBase from 'components/button/Base';
import CountryFlag from 'components/svg-icon/CountryFlag';
import Checkbox from 'components/checkbox/Base';
import { useViewColumnsWithUserLanguageViewsAndMetadata } from 'hooks/gridUI/column';
import { DISABLED_OPACITY, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { useDispatch } from 'react-redux';
import * as tmActions from 'gridUI/actions/tm';
import { sendManualTrack } from 'tracker';
import {
    useDependencies,
    useQuickFilters,
    useQuickSorts,
    useDragNodeIds,
    usePathTagTree,
    useDefaultTm
} from 'hooks/gridUI';
import { isTempId } from 'utils/uuid';
import * as columnTypes from 'const/columnTypes';
import { ColumnTypeSelectListDisplay } from '../../ColumnTypeDisplay';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import { isLDEmpty } from 'utils/object';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { getPath } from 'utils/gridUI/pathTag';
import { OPERATOR } from 'gridUI/conditions';
import SynchronizeTMColorSVG from 'assets/images/svg/SynchronizeTMColorSVG';
import ThreeStateCheckBox from 'components/checkbox/ThreeStateCheckBox';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        width: 796,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },

    targetList: {
        maxHeight: 300,
        minHeight: 46,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    otherColumnList: {
        maxHeight: 300,
        overflowY: 'auto',
        overflowX: 'hidden'
    },

    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    fade: {
        opacity: 0.6
    },
    checkbox: {
        padding: 4
    },
    left4: {
        position: 'relative',
        left: 4,
        width: '100%'
    },
    footer: {},
    dlFlex: {
        display: 'flex'
    },
    steel: {
        color: theme.colors.steel
    },
    divider: {
        background: theme.colors.divider
    },
    label: {
        width: `calc(100% - 32px)`
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    otherSettings: {
        position: 'relative',
        left: -12
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    wrapper: {
        padding: `16px 16px 24px !important`,
        background: theme.colors.ghostwhite,
        borderRadius: 4
    },
    tmName: {
        ...theme.ellipsis()
    },
    otherOptions: {
        cursor: 'pointer',
        width: 'fit-content'
    }
}));

function getDefaultSource(sources) {
    if (sources?.length) return sources?.[0];
    return null;
}

function SynchronizeTMContent({ tab, onClose, showTitle = true }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const [includeQuickFilterAndSort] = React.useState(true);

    const dependencies = useDependencies();
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});
    const [exportAllTargets, setExportAllTargets] = React.useState(false);
    const [isOverwrite, setIsOverwrite] = React.useState(false);

    const quickSorts = useQuickSorts();
    const quickFilters = useQuickFilters();
    const dragNodeIds = useDragNodeIds();
    const tree = usePathTagTree();
    const defaultTm = useDefaultTm();

    const [enableSelectedPaths] = React.useState(dragNodeIds?.length > 0);

    const quickFiltersCombined = React.useMemo(() => {
        const filter = includeQuickFilterAndSort ? quickFilters : {};
        const pathNames = dragNodeIds?.map(nodeId => getPath({ tree, nodeId })?.pathName);

        const extraFilters = dragNodeIds?.length
            ? enableSelectedPaths
                ? { _pathTag: { value: pathNames, operator: OPERATOR.startsWith, type: '_pathTag' } }
                : {}
            : {};
        return { ...filter, ...extraFilters };
    }, [includeQuickFilterAndSort, quickFilters, tree, dragNodeIds, enableSelectedPaths]);

    const quickSortsCombined = React.useMemo(() => {
        return includeQuickFilterAndSort ? quickSorts : {};
    }, [includeQuickFilterAndSort, quickSorts]);

    const dependenciesWithoutFakeIds = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const parentDpDcColIds = React.useMemo(() => {
        return dependenciesWithoutFakeIds?.map(dpDc => dpDc?.parent);
    }, [dependenciesWithoutFakeIds]);

    const sourceOptions = React.useMemo(() => {
        const sources = viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isParent = parentDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isParent && columnType === columnTypes.TRANSLATION;
            })
            ?.map(lang => ({
                ...lang,
                value: lang?.id,
                group: lang?.group,
                label: lang?.name,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }));
        return sources;
    }, [viewColumns, parentDpDcColIds]);

    const [selectedSource, setSelectedSource] = React.useState(getDefaultSource(sourceOptions));

    const childDpDcColIds = React.useMemo(() => {
        if (!selectedSource) return [];
        return dependenciesWithoutFakeIds
            ?.filter(dpDc => dpDc?.parent === selectedSource?.value)
            ?.map(dpDc => dpDc?.child);
    }, [dependenciesWithoutFakeIds, selectedSource]);

    const targetOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isChild = childDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isChild && columnType === columnTypes.TRANSLATION;
            })
            .map(lang => ({
                ...lang,
                value: lang.id,
                group: lang.group,
                label: `${lang.name}`,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }));
    }, [viewColumns, childDpDcColIds]);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions?.[targetValue] || false;

            const otherTargets = targetOptions?.filter(opt => opt?.value !== targetValue);

            const isAtLeaseCheckedTarget = otherTargets?.some(option => selectedTargetOptions?.[option?.value]);
            const isAtLeaseUnCheckedTarget = otherTargets?.some(option => !selectedTargetOptions?.[option?.value]);

            if (checked) {
                //check all target is uncheck => setCheckAll -> false
                if (!isAtLeaseCheckedTarget) {
                    setExportAllTargets(false);
                }

                if (!isAtLeaseUnCheckedTarget) {
                    setExportAllTargets(false);
                }
            } else {
                //check all target is checked => setCheckAll => true;
                if (!isAtLeaseUnCheckedTarget) {
                    setExportAllTargets(true);
                }
            }

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions, targetOptions]
    );

    const handleTargetSelectAll = React.useCallback(
        e => {
            const checked = e.target?.checked;
            setExportAllTargets(checked);

            let newSelectedTargetOptions = {};
            targetOptions.forEach(target => {
                newSelectedTargetOptions[target.value] = checked;
            });
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [targetOptions]
    );

    const validTargets = React.useMemo(() => {
        let validTargetCols = [];
        Object.keys(selectedTargetOptions).forEach(key => {
            if (selectedTargetOptions[key]) {
                validTargetCols = [
                    ...validTargetCols,
                    { key, id: targetOptions?.find(option => option.value === key)?.id }
                ];
            }
        });

        return validTargetCols;
    }, [selectedTargetOptions, targetOptions]);

    const isValid = React.useMemo(() => {
        return !!selectedSource && validTargets.length;
    }, [selectedSource, validTargets]);

    const handleSubmit = React.useCallback(() => {
        let body = {
            sourceColumnId: selectedSource?.value,
            targetColumnIds: validTargets.map(validTarget => validTarget.id)
        };

        if (!isLDEmpty(quickFiltersCombined)) {
            body = {
                ...body,
                query: formatQuickFilters(quickFiltersCombined)
            };
        }

        if (!isLDEmpty(quickSortsCombined)) {
            body = {
                ...body,
                sort: quickSorts
            };
        }

        sendManualTrack({ type: tab === 'push' ? `Synchronize_TM Push` : `Synchronize_TM Pull` });
        setIsSubmitting(true);

        if (tab === 'push') {
            dispatch(
                tmActions.pushTM({
                    body,
                    successCallback: () => {
                        console.log('synchronize tm push success');
                        setIsSubmitting(false);
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        console.log('synchronize tm push error');
                        setIsSubmitting(false);
                    }
                })
            );
        } else {
            body.override = isOverwrite;
            dispatch(
                tmActions.pullTM({
                    body,
                    successCallback: () => {
                        console.log('synchronize tm pull success');
                        setIsSubmitting(false);
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        console.log('synchronize tm pull error');
                        setIsSubmitting(false);
                    }
                })
            );
        }
    }, [
        dispatch,
        onClose,
        quickFiltersCombined,
        quickSorts,
        quickSortsCombined,
        selectedSource,
        validTargets,
        tab,
        isOverwrite
    ]);

    const handleSourceSelect = React.useCallback(
        option => {
            setSelectedSource(option);
            if (exportAllTargets) {
                const childColIds = dependenciesWithoutFakeIds
                    ?.filter(dpDc => dpDc?.parent === option?.value)
                    ?.map(dpDc => dpDc?.child);
                const targetOpts = viewColumns
                    .filter(viewCol => {
                        const columnId = viewCol?.id;
                        const isChild = childColIds?.includes(columnId);
                        const columnType = getCorrectColumnType(viewCol);
                        return isChild && columnType === columnTypes.TRANSLATION;
                    })
                    .map(lang => ({
                        ...lang,
                        value: lang.id,
                        group: lang.group,
                        label: `${lang.name}`,
                        icon: () => <CountryFlag languageCountryCode={lang.group} />
                    }));

                let newSelectedTargetOptions = {};
                targetOpts.forEach(target => {
                    newSelectedTargetOptions[target.value] = true;
                });
                setSelectedTargetOptions(newSelectedTargetOptions);
            } else {
                setSelectedTargetOptions({});
            }
        },
        [exportAllTargets, dependenciesWithoutFakeIds, viewColumns]
    );

    return (
        <>
            <DialogContent>
                <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                    <Grid item container direction="column" spacing={4} wrap="nowrap">
                        <Grid item className={classes.wrapper}>
                            <Grid item>
                                <p className="body1">
                                    {tab === 'push' ? t('push_translation_to_tm') : t('apply_translations_from_tm')}
                                </p>
                            </Grid>

                            <div className="flex items-center flex-nowrap gap-3.5 mt-2.5">
                                <p className="font-normal whitespace-nowrap text-steel">{t('current_tm_in_use')}</p>
                                <div className="flex items-center gap-1 flex-nowrap">
                                    <SynchronizeTMColorSVG style={{ verticalAlign: 'middle' }} />
                                    <p className={'flex-1 line-clamp-1 prose prose-sm font-medium'}>
                                        {defaultTm?.name || ''}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <Grid className={classes.source} container spacing={2} direction="column" wrap="nowrap">
                                <Grid item>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_1')}
                                        </Grid>
                                        <Grid item>
                                            <p className="body1">
                                                {tab === 'push'
                                                    ? t('tm_select_source_language')
                                                    : t('select_source_language_lookup')}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={3}>
                                        {sourceOptions?.map(option => {
                                            const isSelected = selectedSource?.value === option?.value;
                                            return (
                                                <Grid
                                                    className={selectedSource && !isSelected ? classes.fade : ``}
                                                    onClick={() => handleSourceSelect(option)}
                                                    item
                                                    xs={4}
                                                    key={option.value}
                                                >
                                                    <ListSelect
                                                        isSelected={isSelected}
                                                        icon={option?.icon}
                                                        name={<ColumnTypeSelectListDisplay {...option} />}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="column" wrap="nowrap">
                                <Grid item container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item className={classes.numberStep}>
                                                {t('global_2')}
                                            </Grid>
                                            <Grid item>
                                                <p className="body1">{t('select_target_language_update')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item></Grid>
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={exportAllTargets}
                                                            onChange={handleTargetSelectAll}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">{t('select_all_languages')}</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    className={classnames(classes.targetList, { '!max-h-[192px]': tab === 'pull' })}
                                >
                                    {targetOptions.length === 0 && (
                                        <p className="body2 text-steel">{t(`export_localization_explain`)}</p>
                                    )}
                                    <Collapse in={targetOptions.length > 0}>
                                        <Grid container alignItems="center" spacing={3}>
                                            {targetOptions?.map(option => {
                                                const isSelected = selectedTargetOptions?.[option?.value];
                                                return (
                                                    <Grid
                                                        onClick={e => handleTargetSelect(option)}
                                                        item
                                                        xs={4}
                                                        key={option.value}
                                                    >
                                                        <ListSelect
                                                            isSelected={isSelected}
                                                            icon={option?.icon}
                                                            name={<ColumnTypeSelectListDisplay {...option} />}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                        {tab === 'pull' && (
                            <Grid item>
                                <Grid container spacing={2} direction="column" wrap="nowrap">
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item className={classes.numberStep}>
                                                {t('global_3')}
                                            </Grid>
                                            <Grid item>
                                                <p className="body1">{t('other_options')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            spacing={2}
                                            wrap="nowrap"
                                            className="cursor-pointer"
                                            onClick={() => setIsOverwrite(!isOverwrite)}
                                        >
                                            <Grid item>
                                                <ThreeStateCheckBox
                                                    state={isOverwrite ? 1 : 2}
                                                    className={classes.checkbox}
                                                    onChange={() => {}}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">{t('tm_overwrite_existing_translations')}</p>
                                                <p className="caption max-w-full">
                                                    {t('tm_overwrite_existing_translations_content')}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid className={classes.footer} item container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Grid container spacing={2} direction="row" wrap="nowrap">
                            <Grid item>
                                <ButtonBase variant="outlined" width={120} onClick={onClose}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    width={120}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            style={{
                                                color: 'white'
                                            }}
                                            size={20}
                                        />
                                    ) : tab === 'push' ? (
                                        t('update_tm')
                                    ) : (
                                        t('apply_tm')
                                    )}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(SynchronizeTMContent);
