import * as types from '../types';
import {
    getViewSortApi,
    deleteViewSortApi,
    deleteViewSortsApi,
    createViewSortApi,
    updateViewSortApi
} from 'services/viewSort';
import { getViewRecordsApiV2 } from 'services/view';
import { enqueueSnackbar } from 'notifier/actions';
import uuidv1 from 'uuid/v1';
import isEmpty from 'lodash/isEmpty';
import { formatQuickFilters } from 'utils/gridUI/filter';
import * as statusActions from './status';
import { getExactColumns } from 'utils/gridUI/column';
import { generateTempId, isTempId } from 'utils/uuid';
import * as columnTypes from 'const/columnTypes';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

export function fetchViewSorts({ dbId, viewId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId: dbIdStore, defaultAccessViewId } = gridUI;

        const dbIdCombined = dbId || dbIdStore;
        const viewIdCombined = viewId || defaultAccessViewId;

        dispatch(_getViewSortAction());
        try {
            let viewSorts = await getViewSortApi({ dbId: dbIdCombined, defaultAccessViewId: viewIdCombined });
            const viewSortsMapped = viewSorts.map(viewSort => {
                return {
                    ...viewSort,
                    oldValue: viewSort.direction
                };
            });

            dispatch(_getViewSortActionSuccess({ viewSorts: viewSortsMapped }));
            // if (!viewSorts.length) {
            //     let sortOrder = generateDefaultViewSort(gridUI);
            //     if (!sortOrder) return;
            //     dispatch(
            //         createViewSortOrder({
            //             sortOrder
            //         })
            //     );
            // }
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _getViewSortAction() {
    return {
        type: types.FETCH_VIEW_SORTS
    };
}

export function _getViewSortActionFailed({ error }) {
    return {
        type: types.FETCH_VIEW_SORTS_FAILED,
        payload: {
            error
        }
    };
}

export function _getViewSortActionSuccess({ viewSorts }) {
    return {
        type: types.FETCH_VIEW_SORTS_SUCCESS,
        payload: {
            viewSorts
        }
    };
}

export function generateDefaultViewSort(gridUI) {
    const { viewColumns, metaData } = gridUI;
    const columnIds = getExactColumns(viewColumns);
    const orderedViewColumnIds = columnIds?.filter(colId => colId !== columnTypes.PATH_TAG);
    const firstColumnId = orderedViewColumnIds && orderedViewColumnIds[0];

    if (!firstColumnId) return undefined;

    let column = metaData?.[firstColumnId];
    let sortOrder = {
        id: generateTempId(),
        columnId: column.id,
        direction: '',
        oldValue: ''
    };
    return sortOrder;
}

export function createViewSortOrder({ sortOrder }) {
    return {
        type: types.CREATE_VIEW_SORT,
        payload: {
            sortOrder
        }
    };
}

export function removeMultipleSorts({ sortIds = [] }) {
    return async function(dispatch, getState) {
        sortIds.forEach(sortId => {
            dispatch(_deleteViewSortOrder({ sortOrderId: sortId }));
        });
    };
}

export async function deleteViewSortsOrderAfterColumnIsDeleted({
    sortOrderIds,
    gridUI,
    dispatch,
    successCallback,
    errorCallback
}) {
    const { dbId, defaultAccessViewId } = gridUI;
    const tempSortIds = sortOrderIds.filter(id => isTempId(id));
    const serverSortIds = sortOrderIds.filter(id => !isTempId(id));

    if (!isEmpty(tempSortIds)) {
        tempSortIds.forEach(sortId => {
            dispatch(_deleteViewSortOrder({ sortOrderId: sortId }));
        });
    }

    if (isEmpty(serverSortIds)) {
        successCallback && successCallback();
        return;
    }

    serverSortIds.forEach(sortId => {
        dispatch(_deleteViewSortOrder({ sortOrderId: sortId }));
    });

    try {
        await _deleteViewSortOrdersCallServer({
            dbId,
            defaultAccessViewId,
            sortOrderIds
        });
        successCallback && successCallback();
    } catch (error) {
        const { message } = error;
        dispatch(
            enqueueSnackbar({
                message,
                type: 'info'
            })
        );
        errorCallback && errorCallback();
    }
}

export function deleteViewSortOrder({ sortOrderId, direction, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId } = gridUI;
        if (!direction) {
            dispatch(_deleteViewSortOrder({ sortOrderId }));
        } else {
            dispatch(statusActions.registerDoingAction({ actionId }));
            dispatch(_deleteViewSortOrder({ sortOrderId }));
            try {
                await _deleteViewSortOrderCallServer({
                    dbId,
                    defaultAccessViewId,
                    sortOrderId
                });
                await _fetchRecordsAfterFilter({ gridUI, dispatch });
                dispatch(statusActions.removeDoingAction({ actionId }));
            } catch (error) {
                const { message } = error;
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
                dispatch(statusActions.removeDoingAction({ actionId }));
                errorCallback && errorCallback();
            }
        }
    };
}

async function _deleteViewSortOrderCallServer({ dbId, defaultAccessViewId, sortOrderId }) {
    try {
        return await deleteViewSortApi({ dbId, defaultAccessViewId, sortOrderId });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

async function _deleteViewSortOrdersCallServer({ dbId, defaultAccessViewId, sortOrderIds }) {
    try {
        return await deleteViewSortsApi({
            dbId,
            defaultAccessViewId,
            body: {
                ids: sortOrderIds
            }
        });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

function _deleteViewSortOrder({ sortOrderId }) {
    return {
        type: types.DELETE_VIEW_SORT,
        payload: {
            sortOrderId
        }
    };
}

export function updateViewSortOrderOnly({ sortOrderId, newSortOrder }) {
    return {
        type: types.UPDATE_VIEW_SORT,
        payload: {
            sortOrderId,
            newSortOrder
        }
    };
}

async function _createViewSortOrderCallServer({ dbId, defaultAccessViewId, data }) {
    try {
        return await createViewSortApi({ dbId, defaultAccessViewId, data });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

async function _updateViewSortOrderCallServer({ dbId, defaultAccessViewId, sortOrderId, data }) {
    try {
        return await updateViewSortApi({ dbId, defaultAccessViewId, sortOrderId, data });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function updateViewSortOrder({ sortOrderId, sortOrder, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId } = gridUI;
        const { direction, columnId, id, oldValue } = sortOrder;
        try {
            dispatch(statusActions.registerDoingAction({ actionId }));
            if (!direction) {
                await _deleteViewSortOrderCallServer({
                    defaultAccessViewId,
                    dbId,
                    sortOrderId
                });
                dispatch(
                    updateViewSortOrderOnly({
                        sortOrderId,
                        newSortOrder: {
                            ...sortOrder,
                            direction,
                            oldValue: direction
                        }
                    })
                );
            } else if (oldValue !== direction && !oldValue) {
                const createdSortOrder = await _createViewSortOrderCallServer({
                    defaultAccessViewId,
                    dbId,
                    data: {
                        columnId,
                        direction
                    }
                });
                dispatch(
                    updateViewSortOrderOnly({
                        sortOrderId: id,
                        newSortOrder: {
                            ...createdSortOrder,
                            oldValue: direction
                        }
                    })
                );
            } else {
                await _updateViewSortOrderCallServer({
                    defaultAccessViewId,
                    dbId,
                    sortOrderId,
                    data: {
                        ...sortOrder,
                        direction
                    }
                });
                dispatch(
                    updateViewSortOrderOnly({
                        sortOrderId,
                        newSortOrder: {
                            ...sortOrder,
                            direction,
                            oldValue: direction
                        }
                    })
                );
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            await _fetchRecordsAfterFilter({ gridUI, dispatch });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

async function _fetchRecordsAfterFilter({ gridUI, dispatch }) {
    const { defaultAccessViewId, dbId, quickFilters, quickSorts, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;
    const quickFiltersFormatted = formatQuickFilters(quickFilters);

    const isShowAutoQA = getIsShowAutoQA();

    try {
        const { data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters } = await _getViewRecordsAfterSort(
            {
                defaultAccessViewId,
                dbId,
                filterQuery: quickFiltersFormatted,
                sortQuery: quickSorts,
                isShowAutoQA,
                ROW_START_INDEX,
                ROW_STOP_INDEX
            }
        );
        dispatch(_updateRecordsAfterSort({ data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters }));
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

function _updateRecordsAfterSort({ data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters }) {
    return {
        type: types.UPDATE_RECORDS_AFTER_SORT,
        payload: {
            data,
            totalRecords,
            rows,
            recordMetaData,
            totalRecordsWithoutFilters
        }
    };
}

async function _getViewRecordsAfterSort({
    defaultAccessViewId,
    dbId,
    filterQuery,
    sortQuery,
    isShowAutoQA,
    ROW_START_INDEX,
    ROW_STOP_INDEX
}) {
    try {
        const { recordIds, data, totalRecords, recordMetaData, totalRecordsWithoutFilters } = await getViewRecordsApiV2(
            {
                defaultAccessViewId,
                dbId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                filterQuery,
                sortQuery,
                isShowAutoQA
            }
        );

        return { data, totalRecords, rows: recordIds, recordMetaData, totalRecordsWithoutFilters };
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function createViewSortOrderSocket({ sortOrder }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        dispatch(createViewSortOrder({ sortOrder }));
        await _fetchRecordsAfterFilter({ gridUI, dispatch });
    };
}

export function deleteViewSortOrderSocket({ sortOrderId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const newQuickSorts = { ...gridUI?.quickSorts };
        const columnId = gridUI?.viewSorts?.find(viewSort => viewSort?.id === sortOrderId)?.columnId;
        if (newQuickSorts?.[columnId]) delete newQuickSorts?.[columnId];
        gridUI.quickSorts = newQuickSorts;
        dispatch(_deleteViewSortOrder({ sortOrderId }));
        dispatch(clearColumnQuickSortSocket(columnId));
        await _fetchRecordsAfterFilter({ gridUI, dispatch });
        dispatch(
            enqueueSnackbar({
                message: "A user has changed your View 's setting",
                type: 'info'
            })
        );
    };
}

export function updateViewSortOrderSocket({ sortOrderId, newSortOrder }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const newQuickSorts = { ...gridUI?.quickSorts };
        const columnId = newSortOrder?.columnId;
        if (newQuickSorts?.[columnId]) delete newQuickSorts?.[columnId];
        gridUI.quickSorts = newQuickSorts;
        dispatch(updateViewSortOrderOnly({ sortOrderId, newSortOrder }));
        dispatch(clearColumnQuickSortSocket(columnId));
        await _fetchRecordsAfterFilter({ gridUI, dispatch });
        dispatch(
            enqueueSnackbar({
                message: "A user has changed your View 's setting",
                type: 'info'
            })
        );
    };
}

export function clearColumnQuickSortSocket(columnId) {
    return {
        type: types.CLEAR_QUICK_SORT_WHEN_VIEW_SORT_IS_UPDATED_SOCKET,
        payload: { columnId }
    };
}

export function deleteViewSortsAfterColumnIsDeletedSocket({ columnId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewSorts } = gridUI;
        const newViewSorts = viewSorts.filter(viewSort => viewSort?.columnId !== columnId);
        if (newViewSorts?.length === viewSorts?.length) return;
        dispatch(_deleteViewSortsAfterColumnIsDeletedSocket({ newViewSorts }));
    };
}

function _deleteViewSortsAfterColumnIsDeletedSocket({ newViewSorts }) {
    return {
        type: types.DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET,
        payload: {
            newViewSorts
        }
    };
}
