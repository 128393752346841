import React from 'react';

function WraptextSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M0.727273 0.5C0.311378 0.5 0 0.858272 0 1.26723V14.6938C0 15.1028 0.311378 15.461 0.727273 15.461C1.14317 15.461 1.45455 15.1028 1.45455 14.6938V1.26723C1.45455 0.858272 1.14317 0.5 0.727273 0.5Z"
                fill={color}
            />
            <path
                d="M6.40778 12.2501L5.75852 11.6009H9.07902C10.1501 11.6009 10.9815 11.2469 11.5426 10.5663C12.101 9.88902 12.3797 8.90286 12.3797 7.66081C12.3797 6.41877 12.101 5.4326 11.5426 4.75531C10.9815 4.07473 10.1501 3.72075 9.07902 3.72075H6.52158C6.11109 3.72075 5.77832 4.05352 5.77832 4.46401C5.77832 4.8745 6.11109 5.20727 6.52158 5.20727H9.07902C9.71681 5.20727 10.1601 5.38831 10.4491 5.76796C10.7432 6.15427 10.8932 6.76545 10.8932 7.66081C10.8932 8.55618 10.7432 9.16735 10.4491 9.55367C10.1601 9.93332 9.71681 10.1144 9.07902 10.1144H5.75852L6.40778 9.4651C6.69804 9.17484 6.69804 8.70423 6.40778 8.41397C6.11752 8.12371 5.64692 8.12371 5.35666 8.41397L3.43858 10.3321C3.14832 10.6223 3.14832 11.0929 3.43858 11.3832L5.35666 13.3013C5.64692 13.5915 6.11752 13.5915 6.40778 13.3013C6.69804 13.011 6.69804 12.5404 6.40778 12.2501Z"
                fill={color}
            />
            <path
                d="M14.5455 1.26723C14.5455 0.858272 14.8568 0.5 15.2727 0.5C15.6886 0.5 16 0.858272 16 1.26723V14.6938C16 15.1028 15.6886 15.461 15.2727 15.461C14.8568 15.461 14.5455 15.1028 14.5455 14.6938V1.26723Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(WraptextSVG);
