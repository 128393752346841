import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-min-noconflict/mode-javascript';
import 'ace-builds/src-min-noconflict/theme-tomorrow_night_eighties';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/ext-spellcheck';
import 'ace-builds/src-min-noconflict/snippets/javascript';
import 'ace-builds/src-min-noconflict/ext-searchbox';
const ace = require('ace-builds/src-noconflict/ace');
ace.config.set('basePath', 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/');
ace.config.setModuleUrl(
    'ace/mode/javascript_worker',
    'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-javascript.js'
);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        '& *': {
            fontFamily: 'monospace !important'
        },
        '& .ace_gutter': {
            background: theme.colors.white,
            borderRight: `1px solid ${theme.colors.divider}`
        },
        '& .ace_content': {
            '& .ace-focus': {
                background: theme.colors.hawkesBlue
            },
            '& .ace_cursor-layer': {
                '& .ace_cursor': {
                    color: `${theme.colors.lightGreyBlue} !important`,
                    opacity: 1,
                    display: props => (props?.disabled ? 'none !important' : '')
                }
            },
            '& .ace_tag': {
                color: '#D14'
            }
        },
        '& .ace_gutter-cell': {
            color: theme.colors.lightGreyBlue,
            fontFamily: 'Roboto',
            '&.ace_gutter-active-line': {
                background: props => (props?.disabled ? theme.colors.white : theme.colors.ghostwhite)
            }
        },
        '& .ace_marker-layer ': {
            '& .ace_bracket': {
                margin: `2px 0 0 0px`,
                border: `1px solid ${theme.colors.border}`,
                display: props => (props?.disabled ? 'none' : '')
            },
            '& .ace_active-line': {
                background: props => (props?.disabled ? theme.colors.white : theme.colors.ghostwhite)
            }
        },

        '& .ace_scrollbar': {
            display: props => (props?.disabled ? 'none' : '')
        }
    }
}));

function Editor({
    value,
    onChange,
    tabSize = 4,
    fontSize = 14,
    onEscape,
    onSaveAndQuit,
    onPaste,
    disabled = false,
    placeholder = '',
    theme = 'github',
    mode = 'json',
    ...rest
}) {
    const classes = useStyles({ disabled });
    return (
        <AceEditor
            style={{
                width: '100%',
                height: '100%',
                borderRadius: 6
            }}
            readOnly={disabled}
            className={`${classes.root} ${disabled ? `ace_editor_disabled` : ``}`}
            value={value}
            mode={mode}
            theme={theme}
            onChange={onChange}
            onPaste={onPaste}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            tabSize={tabSize}
            showPrintMargin={false}
            placeholder={placeholder}
            fontSize={fontSize}
            commands={[
                {
                    name: 'Escape',
                    exec: onEscape,
                    bindKey: { mac: 'esc', win: 'esc' }
                },
                {
                    name: 'Save And Quit',
                    exec: onSaveAndQuit,
                    bindKey: { mac: 'shift-enter', win: 'shift-enter' }
                }
            ]}
            {...rest}
        />
    );
}

export default React.memo(Editor);
