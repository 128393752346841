import React from 'react';

function IconTMSettingSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.03226 0C0.906659 0 0 0.994021 0 2.2069V13.7931C0 15.006 0.906659 16 2.03226 16H13.9677C15.0933 16 16 15.006 16 13.7931V2.2069C16 0.994021 15.0933 0 13.9677 0H2.03226ZM1.48387 2.2069C1.48387 1.87073 1.73463 1.60502 2.03226 1.60502H13.9677C14.2654 1.60502 14.5161 1.87073 14.5161 2.2069V3.04275H1.48387V2.2069ZM1.48387 13.7931V4.64777H14.5161V13.7931C14.5161 14.1293 14.2654 14.395 13.9677 14.395H2.03226C1.73463 14.395 1.48387 14.1293 1.48387 13.7931Z"
                fill={color}
            />
            <path d="M7.14227 7.93063H5.65487V12H4.46018V7.93063H3V7H7.14227V7.93063Z" fill={color} />
            <path
                d="M9.21852 7L10.3247 10.4787L11.4275 7H13V12H11.8019V10.8324L11.9176 8.44231L10.7195 12H9.92988L8.72839 8.43887L8.84411 10.8324V12H7.64942V7H9.21852Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(IconTMSettingSVG);
