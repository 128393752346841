import {
    USER_LOGOUT_SUCCESS,
    USER_SIGNUP_FAILED,
    SET_USER_WORKSPACE_ROLE_SUCCESS,
    UPDATE_CURRENT_USER,
    UPLOAD_USER_AVATAR_SUCCESS,
    SET_CURRENT_USER_FAILED,
    SET_CURRENT_USER_SUCCESS,
    SET_COMPANY_ID,
    RESEND_CHANGE_EMAIL,
    SET_USER_NOTIFICATION_CENTER_WITH_KEY_VALUE,
    ADD_USER_NOTIFICATION_CENTER_PUSHES,
    REMOVE_USER_NOTIFICATION_CENTER_PUSHES,
    SET_USER_NOTIFICATION_CENTER,
    SET_USER_WORKSPACE_ROLE_FAILED
} from './types';
import {
    login,
    logout,
    signup,
    getUser,
    check,
    refresh,
    activate,
    updateUserInfoApi,
    updateUserPasswordApi,
    getUserWorkspaceRoleApi,
    updateAuthoritiesSocketApi,
    resetPasswordInitApi,
    resetPasswordFinishApi,
    uploadAvatarApi,
    setUserSettingsApi,
    resendChangeEmailApi,
    getNotificationCenterApi,
    markAllNotificationCenterAsReadApi,
    getNotificationCenterTotalApi,
    markNotificationCenterAsReadApi,
    markNotificationCenterAsUnreadApi,
    resetNotificationCenterCountApi,
    requestDeleteAccountApi,
    deleteAccountApi,
    getAdminCompanyInfoApi,
    adminDeleteAccountApi
} from 'services/auth';
import { createCompanyApi, getCompaniesApi } from 'services/company';
import { clearWorkspaceInfo, _fetchWorkspacesActionSuccess } from 'workspaces/actions';
import { receiveData } from '../api/actions';
import { enqueueSnackbar } from 'notifier/actions';
import { setAccessToken } from 'app/actions';
import isEmpty from 'lodash/isEmpty';
import * as permissionTypes from 'permission/types';
import { rudder } from 'tracker/rudder';
import uuidV4 from 'uuid/v4';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import { setItem } from 'helpers/localstorage';
import i18n from 'i18n';
import { NOTIFICATION_CENTER_READ_STATE_TYPE, USER_SETTINGS } from 'const';
import * as gridUIActions from 'gridUI/actions';
import * as viewActions from 'gridUI/actions/views';
import { getWorkspacesApi } from 'services/workspaces';
import * as workspaceActions from 'workspaces/actions';
import { setTimeoutInTime } from 'utils/usage';

export function uploadUserException({ error }) {
    return async function(dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `[uploadUserException]: ${error}`,
                type: 'info'
            })
        );
    };
}

export function uploadUserAvatar({ file, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const newUser = await uploadAvatarApi(file);
            dispatch(_uploadAvatarActionSuccess({ imageUrl: newUser?.imageUrl }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _uploadAvatarActionSuccess({ imageUrl }) {
    return {
        type: UPLOAD_USER_AVATAR_SUCCESS,
        payload: {
            imageUrl
        }
    };
}

export function createCompany({ body, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(workspaceActions._fetchWorkspacesAction());
        try {
            const newCompany = await createCompanyApi({ body });
            await refresh({ companyId: newCompany.id });
            const workspaces = await getWorkspacesApi({ companyId: newCompany.id });
            dispatch(_fetchWorkspacesActionSuccess({ workspaces }));
            successCallback && successCallback(newCompany.id);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchMe({ companyId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const currentUser = await getUser(companyId);
            const defaultOpenPathTag = !!currentUser?.setting?.[USER_SETTINGS.OPEN_PATH_TAG];
            dispatch(gridUIActions.setDefaultUserPathTagOn(defaultOpenPathTag));
            dispatch(_setCurrentUserSuccess({ currentUser }));
            successCallback && successCallback(currentUser);
        } catch (error) {
            const { message } = error;
            dispatch(_setCurrentUserFailed({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

function _setCurrentUserSuccess({ currentUser }) {
    return {
        type: SET_CURRENT_USER_SUCCESS,
        payload: {
            currentUser
        }
    };
}

export function _setCurrentUserFailed({ error }) {
    return {
        type: SET_CURRENT_USER_FAILED,
        payload: {
            error
        }
    };
}

export function logIn({ username, password, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await login({ username, password });
            dispatch(
                fetchMe({
                    successCallback: currentUser => {
                        rudder.identify(currentUser.id?.toString(), {
                            email: currentUser?.email
                        });
                        setItem('tr-dt', JSON.stringify(uuidV4()));
                        successCallback && successCallback(currentUser);
                    }
                })
            );
        } catch (error) {
            errorCallback && errorCallback(error?.originalMessage || error?.message);
        }
    };
}

export function logOut({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await logout();
            dispatch(_logoutSuccess());
            dispatch(
                setNotificationCenter({
                    totalAll: 0,
                    totalRead: 0,
                    totalUnread: 0,
                    readState: '',
                    allowFetch: true
                })
            );
            successCallback && successCallback();
        } catch (error) {
            // const { message } = error;
            // dispatch(
            //     enqueueSnackbar({
            //         message,
            //         type: 'info'
            //     })
            // );
            errorCallback && errorCallback();
        }
    };
}

export function _logoutSuccess() {
    return {
        type: USER_LOGOUT_SUCCESS
    };
}

export function updateAuthoritiesSocket({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI, workspace } = getState();
        const { workspaceId } = gridUI;
        const { selectedWorkspace } = workspace;
        const workspaceIdCombined = workspaceId || selectedWorkspace.id;
        try {
            if (workspaceIdCombined) {
                const workspaceRole = await getUserWorkspaceRoleApi({ workspaceId: workspaceIdCombined });
                const { role, authorities } = workspaceRole;
                dispatch(_setUserWorkspaceRoleSuccess({ workspaceRole: role?.name, authorities }));
            }
            await updateAuthoritiesSocketApi();
            const currentUser = await getUser();
            if (!isEmpty(currentUser)) {
                dispatch(_setCurrentUserSuccess({ currentUser }));
            }
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`global_admin_have_changed_your_permission`),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchUserWorkspaceRole({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const workspaceRole = await getUserWorkspaceRoleApi({ workspaceId });
            const { companyId, role, authorities } = workspaceRole;
            dispatch(_setUserWorkspaceRoleSuccess({ workspaceRole: role?.name, authorities }));
            successCallback && successCallback(companyId);
        } catch (error) {
            dispatch(_setUserWorkspaceRoleFailed());
            dispatch(viewActions._toggleBlockCurrentView());
            errorCallback && errorCallback();
        }
    };
}

export function _setUserWorkspaceRoleSuccess({ workspaceRole, authorities }) {
    return {
        type: SET_USER_WORKSPACE_ROLE_SUCCESS,
        payload: {
            workspaceRole,
            authorities
        }
    };
}

export function _setUserWorkspaceRoleFailed() {
    return {
        type: SET_USER_WORKSPACE_ROLE_FAILED
    };
}

export function updateUserPassword({ currentPassword, newPassword, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await updateUserPasswordApi({ currentPassword, newPassword });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`auth_update_user_password_successfully`),
                    type: 'info'
                })
            );

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function updateUserInfo({ userInfo, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const newUser = await updateUserInfoApi({ user: userInfo });
            dispatch(_updateUserInfoAction({ user: newUser }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`global_change_saved`),
                    type: 'info'
                })
            );

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateUserInfoAction({ user }) {
    return {
        type: UPDATE_CURRENT_USER,
        payload: {
            user
        }
    };
}

export function resendChangeEmail({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const user = await resendChangeEmailApi();
            dispatch(_resendChangeEmailAction({ user }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _resendChangeEmailAction({ user }) {
    return {
        type: RESEND_CHANGE_EMAIL,
        payload: {
            user
        }
    };
}

export function userLoginAndCheckCompany({
    history,
    username,
    password,
    defaultCompanyId,
    errorCallback,
    successCallback
}) {
    return async function(dispatch) {
        try {
            const queryParams = queryParamsToObject(history?.location?.search);
            const data = await login({ username, password });
            const { authorities, access_token } = data;
            const user = await getUser();
            dispatch(setAccessToken({ accessToken: access_token }));
            rudder.identify(user?.id?.toString(), {
                email: user?.email
            });

            setItem('tr-dt', JSON.stringify(uuidV4()));

            if (queryParams.next && queryParams.next.includes('/_default')) {
                setTimeout(function() {
                    history.push(queryParams.next);
                }, 50);
            } else if (defaultCompanyId) {
                history.push(`/companies?id=${defaultCompanyId}`);
            } else if (authorities?.includes('PRE_AUTH')) {
                history.push(`/signin-user-2fa`);
            } else {
                const companies = await getCompaniesApi();

                dispatch(receiveData(permissionTypes.FETCH_COMPANIES_SUCCESS, companies));
                dispatch(clearWorkspaceInfo());
                if (companies.length > 1) {
                    history.push('/companies');
                } else {
                    dispatch(_setCompanyId({ companyId: user?.companyId }));
                    history.push('/');
                }
            }

            successCallback && successCallback();
        } catch (error) {
            // const message = error?.message;
            const originalMessage = error.code === 'invalid_grant' ? i18n.t('bad_credentials') : error.message;
            // dispatch(
            //     enqueueSnackbar({
            //         message,
            //         type: 'info'
            //     })
            // );
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function changeAccountCompany({ companyId, errorCallback, successCallback }) {
    return async function(dispatch) {
        try {
            await refresh({ companyId });

            dispatch(
                fetchMe({
                    companyId,
                    successCallback: () => {
                        dispatch(_setCompanyId({ companyId }));
                        successCallback && successCallback();
                    }
                })
            );

            dispatch(
                setNotificationCenter({
                    totalAll: 0,
                    totalRead: 0,
                    totalUnread: 0,
                    readState: '',
                    allowFetch: true
                })
            );
        } catch (error) {
            const originalMessage = error?.originalMessage;
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function _setCompanyId({ companyId }) {
    return {
        type: SET_COMPANY_ID,
        payload: {
            companyId
        }
    };
}

export function userInit({ email, key, firstName, lastName, password, errorCallback, successCallback }) {
    return async function(dispatch) {
        try {
            await activate({ email, key, firstName, lastName, password });

            successCallback && successCallback({ email, firstName, lastName, password });
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function userSignUp({
    email,
    firstName,
    lastName,
    password,
    companyName,
    companyDescription,
    errorCallback,
    successCallback
}) {
    return async function(dispatch) {
        try {
            await signup({ email, firstName, lastName, password, companyName, companyDescription });
            successCallback &&
                successCallback({ email, firstName, lastName, password, companyName, companyDescription });
        } catch (error) {
            const message = error?.message;
            const originalMessage = error?.originalMessage;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(receiveData(USER_SIGNUP_FAILED, { error: originalMessage }));
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function checkExistence({ email, companyName, errorCallback, successCallback }) {
    return async function() {
        try {
            const result = await check({ email, companyName });

            successCallback && successCallback(result);
        } catch (error) {
            const originalMessage = error.originalMessage;
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function resetPasswordInit({ email, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await resetPasswordInitApi({ email });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t(`auth_send_email_message_success`, { email: email }),
                    type: 'info'
                })
            );

            successCallback && successCallback();
        } catch (error) {
            // const { message } = error;
            const originalMessage = error?.originalMessage;
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function resetPasswordFinish({ key, newPassword, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await resetPasswordFinishApi({ key, newPassword });

            successCallback && successCallback();
        } catch (error) {
            // const { message } = error;
            const originalMessage = error?.originalMessage;
            errorCallback && errorCallback(originalMessage);
        }
    };
}

export function setUserSettings({ settings, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const { currentUser } = auth;
        const currentSettings = currentUser?.setting;

        const newSettings = {
            ...currentSettings,
            ...settings
        };

        try {
            const newUser = await setUserSettingsApi({
                settings: newSettings
            });

            dispatch(_setCurrentUserSuccess({ currentUser: newUser }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_setCurrentUserSuccess({ currentUser }));
            errorCallback && errorCallback();
        }
    };
}

export const setNotificationCenter = payload => {
    return {
        type: SET_USER_NOTIFICATION_CENTER,
        payload
    };
};

export const setNotificationCenterWithKeyValue = ({ key, value }) => {
    return {
        type: SET_USER_NOTIFICATION_CENTER_WITH_KEY_VALUE,
        payload: { key, value }
    };
};

export const getNotificationCenterTotal = ({ type, read }) => {
    return async dispatch => {
        try {
            const responseData = await getNotificationCenterTotalApi({ type, read });
            dispatch(
                setNotificationCenterWithKeyValue({
                    key:
                        read === NOTIFICATION_CENTER_READ_STATE_TYPE.ALL
                            ? 'totalAll'
                            : read === NOTIFICATION_CENTER_READ_STATE_TYPE.READ
                            ? 'totalRead'
                            : 'totalUnread',
                    value: responseData?.total || 0
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
};

export const fetchNotificationCenterWithKey = ({ key, isFetchMore }) => {
    return async (dispatch, getState) => {
        try {
            const { auth } = getState();
            const { notificationCenter } = auth;
            const readState = notificationCenter.readState;
            const currentNotificationCenter = notificationCenter[key];
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: {
                        key,
                        isLoading: !isFetchMore,
                        isLoadingMore: isFetchMore
                        // page: isFetchMore ? currentNotificationCenter.page : 0
                    }
                })
            );
            const startTime = new Date().getTime();
            const responses = await Promise.all([
                getNotificationCenterTotalApi({ type: key, read: NOTIFICATION_CENTER_READ_STATE_TYPE.UNREAD }),
                getNotificationCenterApi({
                    type: key,
                    // page: isFetchMore ? currentNotificationCenter.page + 1 : 0,
                    size: 10,
                    read: readState,
                    messageId: isFetchMore
                        ? currentNotificationCenter.data[currentNotificationCenter.data.length - 1].id
                        : undefined
                })
            ]);
            const response = responses?.[1] || {};
            const responseMessages = response?.data?.messages || [];
            setTimeoutInTime({
                start: startTime,
                timeout: 1000,
                cb: () => {
                    dispatch(
                        setNotificationCenterWithKeyValue({
                            key,
                            value: {
                                isLoading: false,
                                isLoadingMore: false,
                                data: isFetchMore
                                    ? [...(currentNotificationCenter?.data || []), ...responseMessages]
                                    : responseMessages,
                                unreadTotal: isFetchMore
                                    ? currentNotificationCenter.unreadTotal
                                    : responses?.[0]?.total || 0,
                                hasNextPage: response?.data?.has_next_page
                            }
                        })
                    );
                }
            });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setNotificationCenterWithKeyValue({ key, value: { isLoading: false, isLoadingMore: false } }));
        }
    };
};

export const markAllNotificationCenterAsRead = ({ key }) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { notificationCenter, currentUser } = auth;
        const totalUnread = notificationCenter.totalUnread;
        const userSettings = currentUser?.setting;
        if (totalUnread !== 0) {
            dispatch(
                setNotificationCenterWithKeyValue({
                    key: 'totalUnread',
                    value: 0
                })
            );
        }
        const currentNotificationCenter = notificationCenter[key];
        if (currentNotificationCenter.unreadTotal === 0) return;
        try {
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: {
                        unreadTotal: 0,
                        data:
                            userSettings?.[USER_SETTINGS?.NOTIFICATION_CENTER_READ_STATE] ===
                            NOTIFICATION_CENTER_READ_STATE_TYPE.UNREAD
                                ? []
                                : currentNotificationCenter.data.map(message => ({
                                      ...message,
                                      read: true
                                  }))
                    }
                })
            );
            await markAllNotificationCenterAsReadApi(key);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(
                setNotificationCenterWithKeyValue({
                    key: 'totalUnread',
                    value: totalUnread
                })
            );
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: {
                        unreadTotal: currentNotificationCenter.unreadTotal,
                        data: [...(currentNotificationCenter?.data || [])]
                    }
                })
            );
        }
    };
};

export const markNotificationCenterAsRead = ({ key, messageId, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { notificationCenter, currentUser } = auth;
        let { totalUnread } = notificationCenter;
        let { unreadTotal, data, total } = notificationCenter[key];
        const oldData = [...data];
        const userSettings = currentUser?.setting;
        try {
            if (
                userSettings?.[USER_SETTINGS?.NOTIFICATION_CENTER_READ_STATE] ===
                NOTIFICATION_CENTER_READ_STATE_TYPE.UNREAD
            ) {
                data = data.filter(message => message?.id !== messageId);
            } else {
                const index = data.findIndex(message => message?.id === messageId);
                if (index > -1) {
                    data[index] = { ...data[index], read: true };
                }
            }
            dispatch(
                setNotificationCenterWithKeyValue({ key: 'totalUnread', value: totalUnread > 0 ? totalUnread - 1 : 0 })
            );
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: {
                        data: [...data],
                        unreadTotal: unreadTotal > 0 ? unreadTotal - 1 : 0,
                        total: total > 0 ? total - 1 : 0
                    }
                })
            );
            await markNotificationCenterAsReadApi(messageId);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setNotificationCenterWithKeyValue({ key: 'totalUnread', value: totalUnread }));
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: { data: [...oldData], unreadTotal, total }
                })
            );
            errorCallback && errorCallback();
        }
    };
};

export const markNotificationCenterAsUnread = ({ key, messageId, successCallback, errorCallback }) => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { notificationCenter } = auth;
        let { totalUnread } = notificationCenter;
        let { unreadTotal, data } = notificationCenter[key];
        const oldData = [...data];
        try {
            const index = data.findIndex(message => message?.id === messageId);
            if (index > -1) {
                data[index] = { ...data[index], read: false };
            }
            dispatch(setNotificationCenterWithKeyValue({ key: 'totalUnread', value: totalUnread + 1 }));
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: { data: [...data], unreadTotal: unreadTotal + 1 }
                })
            );
            await markNotificationCenterAsUnreadApi(messageId);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setNotificationCenterWithKeyValue({ key: 'totalUnread', value: totalUnread }));
            dispatch(
                setNotificationCenterWithKeyValue({
                    key,
                    value: { data: [...oldData], unreadTotal: unreadTotal }
                })
            );
            errorCallback && errorCallback();
        }
    };
};

export const addNotificationCenterPushes = obj => {
    return {
        type: ADD_USER_NOTIFICATION_CENTER_PUSHES,
        payload: obj
    };
};

export const removeNotificationCenterPushes = elementId => {
    return {
        type: REMOVE_USER_NOTIFICATION_CENTER_PUSHES,
        payload: elementId
    };
};

export const pushNotificationCenter = obj => {
    return (dispatch, getState) => {
        const { auth } = getState();
        const { currentUser } = auth;
        const userSettings = currentUser?.setting;
        const displayNotificationSettingRealtime =
            typeof userSettings?.[USER_SETTINGS?.AUTH_DISPLAY_NOTIFICATION_REALTIME] === 'undefined'
                ? true
                : userSettings?.[USER_SETTINGS?.AUTH_DISPLAY_NOTIFICATION_REALTIME];
        if (displayNotificationSettingRealtime) {
            dispatch(addNotificationCenterPushes(obj));
        }
    };
};

export const resetNotificationCenterCount = () => {
    return async dispatch => {
        try {
            await resetNotificationCenterCountApi();
            dispatch(setNotificationCenterWithKeyValue({ key: 'totalUnread', value: 0 }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
};

export const requestDeleteAccount = ({ isResend, unsubscribe, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const user = await requestDeleteAccountApi({ isResend, unsubscribe });
            dispatch(_updateUserInfoAction({ user }));
            successCallback && successCallback(user);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback();
        }
    };
};

export const deleteAccount = ({ key, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            await deleteAccountApi(key);
            successCallback && successCallback();
        } catch (error) {
            errorCallback && errorCallback(error);
        }
    };
};

export const getAdminCompanyInfo = ({ key, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            const data = await getAdminCompanyInfoApi(key);
            successCallback && successCallback(data);
        } catch (error) {
            errorCallback && errorCallback(error);
        }
    };
};

export const adminDeleteAccount = ({ key, successCallback, errorCallback }) => {
    return async dispatch => {
        try {
            await adminDeleteAccountApi(key);
            successCallback && successCallback();
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error?.message,
                    type: 'error'
                })
            );
            errorCallback && errorCallback(error);
        }
    };
};
