import React, { useMemo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as columnTypes from 'const/columnTypes';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import InputQuickFilter from './quickFilters/InputFilter';
import CheckBoxFilter from './quickFilters/CheckboxFilter';
import DatetimeFilter from './quickFilters/DatetimeFilter';
// import PathTagQuickFilter from './quickFilters/PathTagQuickFilter';
import SingleSelectionFilter from './quickFilters/SingleSelectionFilter';
import MultipleSelectionFilter from './quickFilters/MultipleSelectionFilter';
// import ReferenceFilter from './quickFilters/ReferenceFilter';
import SortDescendingSVG from 'assets/images/svg/SortDescendingSVG';
import SortAscendingSVG from 'assets/images/svg/SortAscendingSVG';
import RegexSVG from 'assets/images/svg/RegexSVG';
import NormalSortSVG from 'assets/images/svg/NormalSortSVG';
import QuickFilterSVG from 'assets/images/svg/QuickFilterSVG';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import {
    INPUT_PADDING_LEFT,
    SPACING_LIST_NAME_WITH_ICON,
    DISABLED_OPACITY,
    SPACING_LIST_NAME_WITH_DOTS_ICON
} from 'const/style';
import { sendManualTrack } from 'tracker';
import PopperMenu from 'components/menus/Popper';
import ExtraFilterOptions from './quickFilters/ExtraFilterOptions';
import { EXTRA_QUICK_FILTER, FILTER_MODES } from 'const/gridUI';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_COLUMN_IDS } from 'const';
import isEqual from 'lodash/isEqual';
import { isEmpty } from 'lodash';
import Tooltip from 'components/tooltip/Base';
import { useWindowColumnStopIndex, useWindowColumnStartIndex } from 'hooks/gridUI';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import ListItem from 'components/list/Item';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import { checkContainId } from 'utils/clickAway';
import { useTranslation } from 'react-i18next';
import NormalFIlterModeSVG from 'assets/images/svg/NormalFIlterModeSVG';
import CaseSensitiveSVG from 'assets/images/svg/CaseSensitiveSVG';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        height: '100%',
        display: 'flex',
        position: 'relative'
    },
    showDots: {
        paddingRight: 6
    },
    sortIcon: {},
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    boolean: {},
    booleanSortWrapper: {
        position: 'absolute',
        right: 0,
        marginRight: theme.spacing(3)
    },
    flex: {
        display: 'flex'
    },
    dependencyBoolean: {
        display: 'flex',
        position: 'absolute',
        left: INPUT_PADDING_LEFT,
        top: 0,
        bottom: 0,
        margin: 'auto',
        height: '100%',
        alignItems: 'center'
    },
    quickIconRight: {
        marginLeft: 4
    },
    quickFilterIcon: {
        cursor: 'pointer',
        position: 'relative',
        borderRadius: 3,
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    highlight: {
        background: `${hexToRgba(theme.colors.fuchsiaBlue, 0.3)} !important`
    },
    highlightIconMore: {
        background: '#EBEBF6'
    },
    booleanRelative: {
        position: 'relative',
        left: 14
    },
    selected: {
        background: theme.colors.lightGrey
    },
    moreWrapper: {
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
        padding: '5px 4px'
    },
    popperMenu: {
        maxWidth: 360,
        minWidth: 200
    },
    popperSortMenu: {
        maxWidth: 360,
        minWidth: 156
    }
}));

function checkTypeAndRender({ type, isShowDots, ...rest }) {
    switch (type) {
        case columnTypes.FILES:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.SINGLE_LINE:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.MULTIPLE_LINES:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.NUMBER:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.BOOLEAN:
            return <CheckBoxFilter checkboxStyle={isShowDots ? { left: -4 } : {}} {...rest} />;
        case columnTypes.DATETIME:
            return <DatetimeFilter {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelectionFilter {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelectionFilter {...rest} />;

        case columnTypes.REFERENCE:
            return <InputQuickFilter type={columnTypes.MULTIPLE_LINES} {...rest} />;
        case columnTypes.LOCALIZATION:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.FORMULA:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.PATH_TAG:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.ALTERED_TIME:
            return <DatetimeFilter {...rest} />;
        case columnTypes.ALTERED_BY:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.CREATED_TIME:
            return <DatetimeFilter {...rest} />;
        case columnTypes.CREATED_BY:
            return <InputQuickFilter type={type} {...rest} />;

        case columnTypes.MARKDOWN:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.RICH_TEXT:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.JSON_LD:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.HTML:
            return <InputQuickFilter type={type} {...rest} />;
        case columnTypes.YAML:
            return <InputQuickFilter type={type} {...rest} />;
        default:
            return <InputQuickFilter type={type} {...rest} />;
    }
}

const SUPPORTED_REGEX = [
    columnTypes.ALTERED_BY,
    columnTypes.SINGLE_LINE,
    columnTypes.MULTIPLE_LINES,
    columnTypes.RECORD_ID,
    columnTypes.RICH_TEXT,
    columnTypes.JSON_LD,
    columnTypes.HTML,
    columnTypes.TRANSLATION,
    columnTypes.MARKDOWN,
    columnTypes.YAML
];

function HeaderFilter({
    column,
    quickSorts = {},
    isSelectionActive,
    quickFilters = {},
    isImporting,
    workspaceId,
    gridId,
    viewId,
    dbId,
    branchId,
    dependencies,
    pathRef,
    columnIndex,
    columnWidth
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const columnId = useMemo(() => column.hashColumnId, [column]);
    const isDown = useMemo(() => quickSorts[columnId], [columnId, quickSorts]);
    const columnType = getCorrectColumnType(column);
    const { t } = useTranslation();

    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();

    const isHighlightHeaderFilter = React.useMemo(() => {
        return columnIndex >= columnStartIndex && columnIndex <= columnStopIndex;
    }, [columnStartIndex, columnStopIndex, columnIndex]);

    const quickFilter = useMemo(() => quickFilters?.[columnId], [columnId, quickFilters]);
    const extraFilter = useMemo(() => quickFilter?.extraFilter, [quickFilter]);
    const filterMode = useMemo(() => quickFilter?.filterMode, [quickFilter]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorMoreEl, setAnchorMoreEl] = React.useState(null);
    const [anchorSortEl, setAnchorSortEl] = React.useState(null);
    const [anchorFilterModeEl, setAnchorFilterModeEl] = React.useState(null);

    const sortHandler = useCallback(() => {
        const sortValue = !isDown ? 'asc' : isDown === 'asc' ? 'desc' : '';

        sendManualTrack({
            type: `Click Quick Sort Column`,
            customData: {
                columnId,
                sortValue
            }
        });
        dispatch(
            advancedSearchActions.QuickSortChange({
                columnId,
                value: sortValue
            })
        );
        dispatch(
            advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                successCallback: () => {
                    console.log('sort successfully');
                },
                errorCallback: () => {
                    console.log('failed to sort');
                }
            })
        );
    }, [dispatch, columnId, isDown]);

    const handleSort = useCallback(
        sortValue => {
            sendManualTrack({
                type: `Click Quick Sort Column`,
                customData: {
                    columnId,
                    sortValue
                }
            });
            if (isDown === sortValue) return;
            dispatch(
                advancedSearchActions.QuickSortChange({
                    columnId,
                    value: sortValue
                })
            );
            dispatch(
                advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                    successCallback: () => {
                        console.log('sort successfully');
                    },
                    errorCallback: () => {
                        console.log('failed to sort');
                    }
                })
            );
        },
        [dispatch, columnId, isDown]
    );

    const handleFilterModeClick = event => {
        setAnchorFilterModeEl(anchorFilterModeEl ? null : event.currentTarget);
    };

    const handleFilterModeClickAway = event => {
        setAnchorFilterModeEl(null);
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleClickMore = event => {
        setAnchorMoreEl(anchorMoreEl ? null : event.currentTarget);
    };

    const handleClickSort = event => {
        setAnchorSortEl(anchorSortEl ? null : event.currentTarget);
    };

    const handleMoreClickAway = e => {
        if (checkContainId(e, 'extraFilter') || checkContainId(e, 'sortExtra')) return;
        setAnchorMoreEl(null);
    };

    const handleSortClickAway = e => {
        setAnchorSortEl(null);
    };

    const handleExtraFilterChange = changedFilter => {
        const newExtraFilter = { ...extraFilter, ...changedFilter };
        dispatch(
            advancedSearchActions.quickFilterExtraChange({
                columnId,
                extraFilter: newExtraFilter
            })
        );

        if (changedFilter?._data === EXTRA_QUICK_FILTER.IS_EMPTY) {
            dispatch(advancedSearchActions.resetQuickFilterValue({ columnId }));
        }

        if (!isEqual(extraFilter, newExtraFilter)) {
            dispatch(
                advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                    successCallback: () => {
                        console.log('sort successfully');
                    },
                    errorCallback: () => {
                        console.log('failed to sort');
                    }
                })
            );
        }
    };

    const isHavingExtraFilter = React.useMemo(() => {
        return extraFilter && extraFilter !== EXTRA_QUICK_FILTER.NONE;
    }, [extraFilter]);

    const handleResetExtraFilter = React.useCallback(() => {
        dispatch(
            advancedSearchActions.quickFilterExtraChange({
                columnId,
                extraFilter: null
            })
        );

        if (!isEmpty(extraFilter)) {
            dispatch(
                advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                    successCallback: () => {
                        console.log('sort successfully');
                    },
                    errorCallback: () => {
                        console.log('failed to sort');
                    }
                })
            );
        }

        handleClickAway();
    }, [columnId, dispatch, extraFilter]);

    const handleFilterModeChange = React.useCallback(
        mode => {
            const value = quickFilters?.[columnId]?.value;

            dispatch(
                advancedSearchActions.quickFilterRegexChange({
                    columnId,
                    filterMode: mode
                })
            );

            handleFilterModeClickAway();

            if (value) {
                dispatch(
                    advancedSearchActions.fetchRecordsWithFiltersAndSorts({
                        successCallback: () => {
                            console.log('sort successfully');
                        },
                        errorCallback: () => {
                            console.log('failed to sort');
                        }
                    })
                );
            }
        },
        [columnId, dispatch, quickFilters]
    );

    const isShowExtraFilter = React.useMemo(() => {
        return !SYSTEM_COLUMN_IDS?.filter(id => ![columnTypes.PATH_TAG].includes(id)).includes(columnId);
    }, [columnId]);

    const isShowDots = React.useMemo(() => {
        return columnWidth <= 160;
    }, [columnWidth]);

    const isHighlightFilterMode = React.useMemo(() => {
        return [FILTER_MODES.CASE_SENSITIVE, FILTER_MODES.REGEX]?.includes(filterMode);
    }, [filterMode]);

    const tooltipContent = React.useMemo(() => {
        switch (filterMode) {
            case FILTER_MODES.REGEX:
                return `Filter using Regular Expression`;
            case FILTER_MODES.CASE_SENSITIVE:
                return (
                    <p className="text-light text-[13px] font-medium">
                        <strong className="revert-style">Exact match </strong> is case-sensitive and works with accented
                        characters.
                    </p>
                );
            default:
                return `Default filter`;
        }
    }, [filterMode]);

    return (
        <Grid
            container
            className={`${classes.root} ${isHighlightHeaderFilter ? classes.selected : ``} ${
                isImporting ? classes.disabled : ''
            } ${isShowDots ? classes.showDots : ''}`}
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
        >
            <Grid
                item
                style={{
                    flex: 1,
                    overflow: 'hidden'
                }}
                className={columnType === columnTypes.BOOLEAN ? classes.booleanRelative : ``}
            >
                {checkTypeAndRender({
                    type: columnType,
                    t,
                    columnId,
                    isSelectionActive,
                    quickFilters,
                    column,
                    pathRef,
                    isDisabled: extraFilter === EXTRA_QUICK_FILTER.IS_EMPTY,
                    workspaceId,
                    gridId,
                    viewId,
                    dbId,
                    branchId,
                    isRegex: [FILTER_MODES.REGEX]?.includes(filterMode),
                    isShowDots
                })}
            </Grid>

            <Grid
                item
                style={{ marginLeft: isShowDots ? SPACING_LIST_NAME_WITH_DOTS_ICON : SPACING_LIST_NAME_WITH_ICON }}
            >
                {isShowDots ? (
                    <Grid container direction="row" spacing={2} alignItems="center" justify="flex-end">
                        {SUPPORTED_REGEX?.includes(columnType) && (
                            <Tooltip title={tooltipContent}>
                                <Grid
                                    item
                                    className={`${classes.flex}  ${classes.quickFilterIcon} ${
                                        isHighlightFilterMode ? classes.highlight : ``
                                    }`}
                                    onClick={handleFilterModeClick}
                                    style={{ marginRight: isShowExtraFilter ? 4 : 0 }}
                                >
                                    {filterMode ? (
                                        filterMode === FILTER_MODES.REGEX ? (
                                            <RegexSVG color={theme.palette.primary.main} />
                                        ) : (
                                            <CaseSensitiveSVG color={theme.palette.primary.main} />
                                        )
                                    ) : (
                                        <NormalFIlterModeSVG />
                                    )}
                                </Grid>
                            </Tooltip>
                        )}
                        <Grid
                            item
                            className={`${classes.moreWrapper} ${classes.quickFilterIcon} ${
                                isHavingExtraFilter || isDown || anchorMoreEl ? classes.highlightIconMore : ``
                            }`}
                            onClick={handleClickMore}
                        >
                            <IconMoreActionsSVG
                                color={
                                    isHavingExtraFilter || isDown || anchorMoreEl
                                        ? theme.palette.primary.main
                                        : '#ACADB9'
                                }
                            />
                            {(isHavingExtraFilter || isDown) && (
                                <div
                                    style={{
                                        width: 6,
                                        height: 6,
                                        borderRadius: '50%',
                                        background: theme.colors.indicator,
                                        border: `1px solid ${theme.colors.solitude}`,
                                        position: 'absolute',
                                        right: 2,
                                        top: 2
                                    }}
                                />
                            )}
                        </Grid>
                        <PopperMenu
                            anchorEl={anchorMoreEl}
                            handleClickAway={handleMoreClickAway}
                            placement={'bottom-end'}
                            className={classes.popperMenu}
                        >
                            <Grid container direction="column">
                                {isShowExtraFilter && (
                                    <Grid item onClick={handleClick}>
                                        <ListItem
                                            selectedIcon={<></>}
                                            isSelected={isHavingExtraFilter}
                                            icon={
                                                <QuickFilterSVG
                                                    color={isHavingExtraFilter ? theme.palette.primary.main : undefined}
                                                />
                                            }
                                            name={t('column_filter')}
                                            moreIcon={
                                                <ArrowRightSVG
                                                    color={isHavingExtraFilter ? theme.palette.primary.main : undefined}
                                                />
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item onClick={handleClickSort}>
                                    <ListItem
                                        selectedIcon={<></>}
                                        isSelected={isDown}
                                        icon={
                                            <NormalSortSVG
                                                color={isDown ? theme.palette.primary.main : theme.colors.lightGreyBlue}
                                            />
                                        }
                                        name={t('toolbar_sort_tooltip')}
                                        moreIcon={
                                            <ArrowRightSVG color={isDown ? theme.palette.primary.main : undefined} />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </PopperMenu>
                        <PopperMenu
                            anchorEl={anchorSortEl}
                            handleClickAway={handleSortClickAway}
                            placement={'right-start'}
                            className={classes.popperSortMenu}
                            id="sortExtra"
                        >
                            <Grid container direction="column">
                                <Grid item onClick={() => handleSort('asc')}>
                                    <ListItem
                                        isSelected={isDown === 'asc'}
                                        icon={
                                            <SortAscendingSVG
                                                color={isDown === 'asc' ? theme.palette.primary.main : undefined}
                                            />
                                        }
                                        name="Sort A-Z"
                                    />
                                </Grid>
                                <Grid item onClick={() => handleSort('desc')}>
                                    <ListItem
                                        isSelected={isDown === 'desc'}
                                        icon={
                                            <SortDescendingSVG
                                                color={isDown === 'desc' ? theme.palette.primary.main : undefined}
                                            />
                                        }
                                        name="Sort Z-A"
                                    />
                                </Grid>
                                <Grid item onClick={() => handleSort('')}>
                                    <ListItem
                                        icon={<NormalSortSVG color={theme.colors.lightGreyBlue} />}
                                        name="Back to default"
                                    />
                                </Grid>
                            </Grid>
                        </PopperMenu>
                    </Grid>
                ) : (
                    <Grid container direction="row" spacing={2} alignItems="center" justify="flex-end">
                        {SUPPORTED_REGEX?.includes(columnType) && (
                            <Tooltip title={tooltipContent}>
                                <Grid
                                    item
                                    className={`${classes.flex}  ${classes.quickFilterIcon} ${
                                        isHighlightFilterMode ? classes.highlight : ``
                                    }`}
                                    onClick={handleFilterModeClick}
                                    style={{ marginRight: isShowExtraFilter ? 4 : 0 }}
                                >
                                    {filterMode ? (
                                        filterMode === FILTER_MODES.REGEX ? (
                                            <RegexSVG color={theme.palette.primary.main} />
                                        ) : (
                                            <CaseSensitiveSVG color={theme.palette.primary.main} />
                                        )
                                    ) : (
                                        <NormalFIlterModeSVG />
                                    )}
                                </Grid>
                            </Tooltip>
                        )}

                        {isShowExtraFilter && (
                            <Grid
                                item
                                className={`${classes.flex}  ${classes.quickFilterIcon} ${
                                    isHavingExtraFilter ? classes.highlight : ``
                                }`}
                                onClick={handleClick}
                            >
                                <QuickFilterSVG color={isHavingExtraFilter ? theme.palette.primary.main : undefined} />
                                {isHavingExtraFilter && (
                                    <div
                                        style={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            background: theme.colors.indicator,
                                            border: `1px solid ${theme.colors.solitude}`,
                                            position: 'absolute',
                                            right: 3,
                                            top: 3
                                        }}
                                    ></div>
                                )}
                            </Grid>
                        )}

                        {![columnTypes.PATH_TAG]?.includes(columnType) && (
                            <Grid
                                item
                                className={`${classes.flex} ${classes.quickIconRight} ${classes.quickFilterIcon} ${
                                    isDown ? classes.highlight : ``
                                }`}
                            >
                                {!isDown ? (
                                    <NormalSortSVG
                                        color={theme.colors.lightGreyBlue}
                                        onClick={sortHandler}
                                        className={classes.sortIcon}
                                    />
                                ) : isDown === 'desc' ? (
                                    <SortDescendingSVG color={theme.palette.primary.main} onClick={sortHandler} />
                                ) : (
                                    <SortAscendingSVG color={theme.palette.primary.main} onClick={sortHandler} />
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu
                    anchorEl={anchorEl}
                    handleClickAway={handleClickAway}
                    placement={isShowDots ? 'right-start' : 'bottom'}
                    id={`extraFilter`}
                >
                    <ExtraFilterOptions
                        onChange={handleExtraFilterChange}
                        extraFilter={extraFilter}
                        columnType={columnType}
                        column={column}
                        gridId={gridId}
                        workspaceId={workspaceId}
                        viewId={viewId}
                        dbId={dbId}
                        branchId={branchId}
                        onResetExtraFilter={handleResetExtraFilter}
                        t={t}
                    />
                </PopperMenu>
            )}
            {anchorFilterModeEl && (
                <PopperMenu
                    anchorEl={anchorFilterModeEl}
                    handleClickAway={handleFilterModeClickAway}
                    placement={'bottom'}
                    id={`filterModeExtra`}
                >
                    <>
                        <Tooltip title="Default filter" placement="right">
                            <div>
                                <ListItem
                                    onClick={() => handleFilterModeChange('')}
                                    name={'Simple'}
                                    icon={() => <NormalFIlterModeSVG />}
                                    isSelected={FILTER_MODES.NORMAL === filterMode || !filterMode}
                                />
                            </div>
                        </Tooltip>

                        <Tooltip
                            title={
                                <p className="text-light text-[13px] font-medium">
                                    <strong className="revert-style">Exact match </strong> is case-sensitive and works
                                    with accented characters.
                                </p>
                            }
                            placement="right"
                        >
                            <div>
                                <ListItem
                                    onClick={() => handleFilterModeChange(FILTER_MODES.CASE_SENSITIVE)}
                                    name={'Exact match'}
                                    icon={() => <CaseSensitiveSVG />}
                                    isSelected={FILTER_MODES.CASE_SENSITIVE === filterMode}
                                />
                            </div>
                        </Tooltip>

                        <Tooltip title="Filter using Regular Expression" placement="right">
                            <div>
                                <ListItem
                                    onClick={() => handleFilterModeChange(FILTER_MODES.REGEX)}
                                    name={'Regex mode'}
                                    icon={() => <RegexSVG color={theme.colors.steel} />}
                                    isSelected={FILTER_MODES.REGEX === filterMode}
                                />
                            </div>
                        </Tooltip>
                    </>
                </PopperMenu>
            )}
        </Grid>
    );
}

HeaderFilter.propTypes = {
    column: PropTypes.object.isRequired,
    quickSorts: PropTypes.object,
    isSelectionActive: PropTypes.bool
};

export default React.memo(HeaderFilter);
