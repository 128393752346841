import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { POPUP_PADDING_TOP_BOTTOM, DISABLED_OPACITY } from 'const/style';
import * as gridUIActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { ColumnIcon } from '../ColumnTypeDisplay';
import hexToRgba from 'hex-to-rgba';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '& .select-input': {
            fontSize: 14,
            fontWeight: 'normal'
        }
    },
    select: {
        width: '100%',

        '& .select-input': {
            maxWidth: 130
        }
    },
    listItem: {
        '& .textContent': {
            color: theme.colors.lightLavender,
            fontWeight: 500
        }
    },
    optionCreate: {
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        marginTop: POPUP_PADDING_TOP_BOTTOM,
        borderTop: `1px solid ${theme.colors.divider}`,
        position: 'relative'
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    warning: {
        border: `1px solid red`
    },
    greenHighlight: {
        background: hexToRgba(theme.colors.atlantis, 0.1)
    },
    redHighlight: {
        background: hexToRgba(theme.colors.burntSlena, 0.1)
    },
    errorText: {
        '& span': {
            color: hexToRgba(theme.colors.burntSlena, 0.8)
        }
    },
    controlClassName: {
        border: 'none'
    },
    dropdownClassName: {
        width: '100%'
    }
}));

function ImportHeader({
    column,
    options,
    position,
    columnIndex,
    onChange,
    isHighlightKeyColumn,
    handleHeaderChangeTimezone,
    ...rest
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCreating, setIsCreating] = React.useState(false);

    const handleChange = option => {
        onChange && onChange({ column, position, columnIndex, selectedValue: option });
    };

    const handleChangeTimezone = option => {
        handleHeaderChangeTimezone && handleHeaderChangeTimezone({ column, position, columnIndex, timezone: option });
    };

    const selectedColumn = React.useMemo(() => {
        const col = options?.find(option => option?.value === column?.targetColId);
        if (col && column?.timezone) {
            col.timezone = column?.timezone;
        }
        return col;
    }, [options, column]);

    const handleCreateColumn = React.useCallback(
        handleClickAway => {
            setIsCreating(true);
            dispatch(
                gridUIActions.createGridColumnWithoutScroll({
                    successCallback: newColumn => {
                        onChange &&
                            onChange({
                                column,
                                position,
                                columnIndex,
                                selectedValue: {
                                    value: newColumn?.id,
                                    label: newColumn?.name,
                                    icon: (
                                        <ColumnIcon
                                            group={newColumn?.group}
                                            type={newColumn?.type}
                                            customProperties={newColumn?.customProperties}
                                        />
                                    ),
                                    disabled: !newColumn.editable
                                }
                            });
                        handleClickAway && handleClickAway();
                        setIsCreating(false);
                    },
                    errorCallback: () => {
                        setIsCreating(false);
                    }
                })
            );
        },
        [column, position, columnIndex, dispatch, onChange]
    );

    return (
        <LDBasePortal
            ddPlaceholder={t(`global_what_is_this_column`)}
            menuPlaceholder={t(`global_find_a_type`)}
            options={options}
            onChange={handleChange}
            defaultValue={selectedColumn}
            isMulti={false}
            dropdownClassName={classes.dropdownClassName}
            controlClassName={classes.controlClassName}
            createText={t(`global_create_column`)}
            isCreatable
            isLoadingCreating={isCreating}
            onCreateNew={handleCreateColumn}
            showTimezoneSetting
            handleChangeTimezone={handleChangeTimezone}
        />
    );
}

ImportHeader.propTypes = {
    column: PropTypes.object.isRequired,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(ImportHeader);
