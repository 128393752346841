import React from 'react';

function DuplicateSVG({ color = 'white', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M10.6673 0.666992H2.66732C1.93398 0.666992 1.33398 1.26699 1.33398 2.00033V11.3337H2.66732V2.00033H10.6673V0.666992ZM12.6673 3.33366H5.33398C4.60065 3.33366 4.00065 3.93366 4.00065 4.66699V14.0003C4.00065 14.7337 4.60065 15.3337 5.33398 15.3337H12.6673C13.4007 15.3337 14.0007 14.7337 14.0007 14.0003V4.66699C14.0007 3.93366 13.4007 3.33366 12.6673 3.33366ZM12.6673 14.0003H5.33398V4.66699H12.6673V14.0003Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(DuplicateSVG);
