import React from 'react';

function ShowAllConflictSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M5.5575 10.0575L4.5 9L1.5 12L4.5 15L5.5575 13.9425L4.3725 12.75H15.75V11.25H4.3725L5.5575 10.0575Z"
                fill={color}
            />
            <path
                d="M12.4425 7.9425L13.5 9L16.5 6L13.5 3L12.4425 4.0575L13.6275 5.25H2.25V6.75H13.6275L12.4425 7.9425Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ShowAllConflictSVG);
