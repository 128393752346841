import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useHistory, useParams } from 'react-router-dom';
import Table from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import GridIcon from 'grids/GridIcon';
import { formatDateFromNow, getFriendlyDate } from 'utils/datetime';
import { getConnectorLabel, getConnectorIcon } from 'utils/connector';
import { useConnectionsBySourceId } from 'hooks/integration';
import * as integrationActions from 'integrations/actions';
import { useDispatch } from 'react-redux';
import PopperMenu from 'components/menus/Popper';
import Popup from 'integrations/connectors/connections/Popup';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import SyncSVG from 'assets/images/svg/SyncSVG';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { useWorkspaceMapping } from 'hooks/workspace';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import { COLOR_TYPES, SOURCE_STATUS } from 'const';
import * as gridActions from 'grids/actions';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    connectionTable: {
        padding: theme.spacing(5),
        height: 'calc(100% - 32px)',
        maxHeight: 'calc(100% - 32px)',
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 8
    },
    mb5: {
        marginBottom: theme.spacing(5)
    },
    table: {
        height: 'calc(100% - 32px)',
        minHeight: 400
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    },
    syncSpin: {
        animation: `$spin 500ms infinite linear`
    },
    hoverLink: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

function Destination({ rowData }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const gridName = rowData?.destinationGridName;
    const destinationProjectName = rowData?.destinationProjectName;
    const destinationDatabaseName = rowData?.destinationDatabaseName;

    const handleOpenGrid = React.useCallback(
        async e => {
            e.stopPropagation();

            dispatch(
                gridActions.getGridLastView({
                    dbId: rowData?.destinationDatabaseId,
                    gridId: rowData?.destinationGridId,
                    errorCallback: () => {},
                    successCallback: responseData => {
                        const { dbId, gridId, viewId, branchId } = responseData;
                        if (!dbId || !gridId || !viewId || !branchId) {
                            return;
                        }
                        history.push(
                            `/projects/${rowData?.destinationProjectId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}`
                        );
                        dispatch(gridActions.setSelectedGrid(gridId));
                    }
                })
            );
        },
        [dispatch, rowData, history]
    );

    return (
        <Grid container onClick={handleOpenGrid} direction="column">
            <Grid item>
                <Grid wrap="nowrap" container direction="row" spacing={1}>
                    <Grid item>
                        <GridIcon size="small" />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.hoverLink} variant="body2">
                            {gridName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    {[destinationProjectName, destinationDatabaseName]?.join(' / ')}
                </Typography>
            </Grid>
        </Grid>
    );
}

function ActionsRenderer({ rowData }) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const { id } = useParams();

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleClick = React.useCallback(
        event => {
            stopPropagation(event);
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl, stopPropagation]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleEdit = React.useCallback(() => {
        history.push(`/integration-settings/connectors/connection/${rowData?.id}?tab=setting`);
    }, [rowData, history]);

    const handleDelete = React.useCallback(() => {
        setOpenConfirmDelete(true);
        handleClickAway();
    }, [handleClickAway]);

    const handleCloseDeleteConfirm = React.useCallback(
        e => {
            stopPropagation(e);
            setOpenConfirmDelete(false);
        },
        [stopPropagation]
    );

    const handleAgreeDelete = React.useCallback(
        e => {
            stopPropagation(e);
            setIsDeleting(true);

            dispatch(
                integrationActions.deleteConnection({
                    cId: rowData?.id,
                    sId: id,
                    success: () => {
                        setIsDeleting(false);
                        handleCloseDeleteConfirm(e);
                    },
                    error: () => {
                        setIsDeleting(false);
                        handleCloseDeleteConfirm(e);
                        console.log('err');
                    }
                })
            );
        },
        [stopPropagation, dispatch, rowData, handleCloseDeleteConfirm, id]
    );

    const handleSyncConnection = React.useCallback(
        e => {
            stopPropagation(e);
            dispatch(
                integrationActions.syncConnection({
                    cId: rowData?.id,
                    sId: id,
                    success: () => {
                        console.log('sync okay');
                    },
                    error: () => {
                        console.log('sync failed');
                    }
                })
            );
        },
        [dispatch, rowData, stopPropagation, id]
    );

    return (
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <Grid item>
                <IconButton
                    disabled={rowData?.task}
                    className={rowData?.task && classes.syncSpin}
                    onClick={handleSyncConnection}
                >
                    <SyncSVG />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={handleClick}>
                    <IconMoreActionsSVG />
                </IconButton>
            </Grid>

            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-end'}>
                    <Popup onEdit={handleEdit} onDelete={handleDelete} source={rowData} onClickAway={handleClickAway} />
                </PopperMenu>
            )}
            <Dialog open={openConfirmDelete} onClose={handleCloseDeleteConfirm}>
                <ConfirmBox
                    title={t('delete_connection')}
                    body={
                        <Typography component="div" variant="body2">
                            Delete source <strong>{rowData?.name}</strong> ? Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit.
                        </Typography>
                    }
                    handleCancel={handleCloseDeleteConfirm}
                    onClose={handleCloseDeleteConfirm}
                    handleAgreed={handleAgreeDelete}
                    agreeLabel="Delete"
                    isLoading={isDeleting}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </Grid>
    );
}

function LastSync({ rowData }) {
    return rowData?.lastSync ? formatDateFromNow(rowData?.lastSync) : null;
}

function Connector({ rowData }) {
    return (
        <Grid container wrap="nowrap" direction="row" spacing={2}>
            <Grid item>{getConnectorIcon(rowData?.sourceResponse?.connector)}</Grid>

            <Grid item>
                <Typography variant="body2">{rowData?.sourceResponse?.name}</Typography>
            </Grid>
        </Grid>
    );
}

function LoadingRow({ maxWidth }) {
    return (
        <Grid
            item
            style={{
                width: `100%`,
                maxWidth,
                height: 7,
                background: `#EEF0F5`,
                borderRadius: 20
            }}
        />
    );
}

function Overview({ source }) {
    const classes = useStyles();

    const history = useHistory();
    const { id } = useParams();
    const connections = useConnectionsBySourceId(id) || [];
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [sorting, setSorting] = React.useState({});
    const [fetching, setFetching] = React.useState(true);

    const workspaceMapping = useWorkspaceMapping();

    const connectionsFormatted = React.useMemo(() => {
        return connections?.map(connection => ({
            ...connection,
            destinationProjectName: workspaceMapping?.[connection?.destinationProjectId]?.name
        }));
    }, [connections, workspaceMapping]);

    const COLUMNS = React.useMemo(
        () => [
            {
                label: t('connection_name'),
                dataKey: 'name',
                flexGrow: 1,
                sort: true,
                cellRenderer: fetching ? () => <LoadingRow maxWidth={250} /> : props => props?.rowData?.name
            },
            {
                label: t('source_name'),
                dataKey: 'sourceResponse',
                flexGrow: 1,
                sort: true,
                cellRenderer: fetching ? () => <LoadingRow maxWidth={250} /> : Connector
            },
            {
                label: t('destination'),
                dataKey: 'destination',
                flexGrow: 1,
                cellRenderer: props => (fetching ? () => <LoadingRow maxWidth={250} /> : <Destination {...props} />)
            },
            {
                label: t('last_sync'),
                dataKey: 'lastSync',
                flexGrow: 1,
                sort: true,
                cellRenderer: fetching ? () => <LoadingRow maxWidth={93} /> : LastSync
            },
            {
                label: '',
                dataKey: 'actions',
                width: 150,
                hidden: fetching,
                cellRenderer: props => <ActionsRenderer {...props} />
            }
        ],
        [fetching, t]
    );

    const data = React.useMemo(() => {
        const cloneData = [...connectionsFormatted];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                if (sortKey === 'lastSync') {
                    return sortType === 'desc'
                        ? new Date(valueA) - new Date(valueB)
                        : new Date(valueB) - new Date(valueA);
                }

                if (sortKey === 'sourceResponse') {
                    valueA = dataA?.[sortKey]?.name;
                    valueB = dataB?.[sortKey]?.name;
                }

                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, connectionsFormatted]);

    React.useEffect(() => {
        setFetching(true);
        dispatch(
            integrationActions.getConnectionsBySourceId({
                sId: id,
                success: () => {
                    setFetching(false);
                },
                error: () => {
                    setFetching(false);
                }
            })
        );
    }, [id, dispatch]);

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };
    const CreateNewConnection = React.useCallback(() => {
        sendManualTrack({ type: 'Open Add New Connection' });
        history.push(`/integration-settings/connectors/connection?sourceId=${source?.id}`);
    }, [history, source]);

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
                wrap="nowrap"
                spacing={1}
            >
                <Grid item>
                    <NotFoundBoxSVG />
                </Grid>
                <Grid item>
                    <Typography variant="h3">{t(`no_connection_found`)}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t(`create_new_connection_note`)}</Typography>
                </Grid>
            </Grid>
        );
    }, [t]);

    return (
        <Grid container className={classes.root} wrap="nowrap" direction="column">
            <Grid item className={classes.mb5}>
                <Grid container alignItems="center" direction="row" spacing={3}>
                    <Grid item>{getConnectorIcon(source?.connector, 64)}</Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="h3">{source?.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    <strong>{t('source_type')}:</strong> {getConnectorLabel(source?.connector)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Last updated on {getFriendlyDate(source?.lastCheck)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.connectionTable}>
                <Grid style={{ height: '100%' }} container wrap="nowrap" direction="column" spacing={5}>
                    {source?.lastCheckStatus !== SOURCE_STATUS.FAILED && (
                        <Grid item>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h4">Connection</Typography>
                                </Grid>
                                <Grid item>
                                    <ButtonBase onClick={CreateNewConnection} width={165} variant="contained">
                                        + {t('new_connection')}
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item className={classes.table}>
                        <AutoSizer>
                            {({ width, height }) => (
                                <Table
                                    data={
                                        fetching
                                            ? Array(5)
                                                  .fill()
                                                  .map(() => ({}))
                                            : data
                                    }
                                    columns={COLUMNS}
                                    width={width}
                                    height={height}
                                    onRowClick={() => {}}
                                    onSort={handleOnSort}
                                    noRowsRenderer={_noRowsRenderer}
                                ></Table>
                            )}
                        </AutoSizer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Overview;
