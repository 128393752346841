import {
    SET_USER_WORKSPACE_ROLE_SUCCESS,
    UPDATE_CURRENT_USER,
    UPLOAD_USER_AVATAR_SUCCESS,
    SET_CURRENT_USER_FAILED,
    SET_CURRENT_USER_SUCCESS,
    SET_COMPANY_ID,
    RESEND_CHANGE_EMAIL,
    SET_USER_NOTIFICATION_CENTER_WITH_KEY_VALUE,
    ADD_USER_NOTIFICATION_CENTER_PUSHES,
    REMOVE_USER_NOTIFICATION_CENTER_PUSHES,
    SET_USER_NOTIFICATION_CENTER,
    SET_USER_WORKSPACE_ROLE_FAILED,
    USER_LOGOUT_SUCCESS
} from './types';
import { VIEWER } from './roleConst';

const INITIAL_STATE = {
    currentUser: null,
    companyId: '',
    isFetchingCurrentUser: true,
    error: '',
    workspaceRole: VIEWER,
    workspaceAuthorities: [],
    notificationCenter: {
        totalAll: 0,
        totalRead: 0,
        totalUnread: 0,
        readState: '',
        allowFetch: true,
        key: {
            isLoading: false,
            isLoadingMore: false,
            // page: 0,
            data: [],
            // total: 0,
            unreadTotal: 0,
            hasNextPage: false
        }
    },
    notificationCenterPushes: [],
    isFetchedWorkspaceRole: false
};

export default function Auth(state = INITIAL_STATE, { payload, type }) {
    switch (type) {
        case USER_LOGOUT_SUCCESS:
            return INITIAL_STATE;
        case UPDATE_CURRENT_USER: {
            const { user } = payload;

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...user
                }
            };
        }

        case SET_CURRENT_USER_SUCCESS: {
            const { currentUser } = payload;
            const authorities = currentUser?.authorities || [];
            const roleObject = {};
            authorities.forEach(role => {
                roleObject[role] = true;
            });
            return {
                ...state,
                currentUser,
                isFetchingCurrentUser: false,
                role: roleObject
            };
        }

        case SET_CURRENT_USER_FAILED: {
            const { error } = payload;
            return {
                ...state,
                error,
                isFetchingCurrentUser: false
            };
        }

        case SET_COMPANY_ID: {
            const { companyId } = payload;
            return {
                ...state,
                companyId
            };
        }

        case SET_USER_WORKSPACE_ROLE_SUCCESS: {
            const { workspaceRole, authorities } = payload;
            return {
                ...state,
                workspaceRole,
                workspaceAuthorities: authorities,
                isFetchedWorkspaceRole: true
            };
        }

        case SET_USER_WORKSPACE_ROLE_FAILED: {
            return {
                ...state,
                isFetchedWorkspaceRole: true
            };
        }

        case UPLOAD_USER_AVATAR_SUCCESS: {
            const { imageUrl } = payload;
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    imageUrl
                }
            };
        }
        case RESEND_CHANGE_EMAIL: {
            const { user } = payload;
            const { changeEmail } = user;

            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    changeEmail
                }
            };
        }

        case SET_USER_NOTIFICATION_CENTER_WITH_KEY_VALUE: {
            const { key, value } = payload;
            return {
                ...state,
                notificationCenter: {
                    ...state.notificationCenter,
                    [key]:
                        typeof value !== 'object'
                            ? value
                            : {
                                  ...(state.notificationCenter?.[key] || {}),
                                  ...value
                              }
                }
            };
        }

        case ADD_USER_NOTIFICATION_CENTER_PUSHES: {
            const newArr = [payload, ...state.notificationCenterPushes].filter((_, idx) => idx < 4);
            return {
                ...state,
                notificationCenterPushes: newArr
            };
        }

        case REMOVE_USER_NOTIFICATION_CENTER_PUSHES: {
            const newArr = state.notificationCenterPushes.filter(el => el.id !== payload);
            return {
                ...state,
                notificationCenterPushes: newArr
            };
        }

        case SET_USER_NOTIFICATION_CENTER: {
            return {
                ...state,
                notificationCenter: payload
            };
        }

        default:
            return state;
    }
}
