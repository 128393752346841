import { useSelector } from 'react-redux';

export function useJobList() {
    return useSelector(state => state.gridUI.jobList) || [];
}

export function useUndoRedoQueue() {
    return useSelector(state => state.gridUI.undoRedoQueue);
}

export function useIsUndoRedoProcessing() {
    return useSelector(state => state.gridUI.isUndoRedoProcessing);
}
