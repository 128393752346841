import request from './request';
import { STATUS_AIP, APP_API_PREFIX_ORGANIZATION } from '../config';

export async function getApiStatus() {
    const response = await fetch(STATUS_AIP);
    return await response.json();
}

export async function fetchSelfHostingLicense() {
    const response = await request.get(`${APP_API_PREFIX_ORGANIZATION}/api/v1/licenses/current`);
    return response.data;
}
