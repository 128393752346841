import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { getStatusColor } from 'utils/color';

function StatusIcon({ status = '' }) {
    const theme = useTheme();
    return (
        <div
            style={{
                backgroundColor: getStatusColor({ status: status?.toLowerCase(), theme }),
                display: 'inline-block',
                width: 7,
                height: 7,
                borderRadius: '50%'
            }}
        />
    );
}

export default React.memo(StatusIcon);
