import React from 'react';
import { Grid, Typography, Accordion, AccordionSummary } from '@material-ui/core';
import WorkspaceListTable from './WorkspaceListTable';
import Search from 'components/search';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import { useDispatch } from 'react-redux';
import * as workspaceActions from 'permission/actions/workspace';
import { useListViewDetail, useIsFetchingWorkspaceViewsDetail } from 'hooks/permission/workspace';
import Tooltip from 'components/tooltip/Base';
// import WorkspaceListTableSkeleton from './WorkspaceListTableSkeleton';
import Spinner from 'components/spinner/Base';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},

    wsAmount: {
        fontSize: 14,
        color: theme.colors.lightGrey,
        fontWeight: 'normal'
    },
    controls: {
        margin: theme.spacing(2, 0)
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        zIndex: 10
    },
    panel: {
        '&.MuiAccordion-root': {
            margin: 0,
            boxShadow: 'none'
        }
    },
    panelSummary: {
        padding: 0,
        '&.MuiAccordionSummary-root': {
            display: 'inline-flex',
            minHeight: 0,
            borderRadius: 4,
            '&:hover': {
                background: theme.colors.hoverItem
            }
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        }
    }
}));

function WorkspaceListTableWrapper({ className, onCheckedListChange, checkedListDefaultIds, isEdit }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const data = useListViewDetail();
    const [filterValue, setFiltervalue] = React.useState('');
    const [checkedList, setCheckedList] = React.useState([]);
    const isFetchingWorkspaceViewsDetail = useIsFetchingWorkspaceViewsDetail();

    const dataWithId = React.useMemo(() => {
        return groupBy(data, `id`);
    }, [data]);

    React.useEffect(() => {
        if (isEdit) {
            setCheckedList(checkedListDefaultIds);
        }
    }, [isEdit, checkedListDefaultIds]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspaceViewsDetail({
                successCallback: () => {
                    console.log('fetch project view detail successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch project view detail');
                }
            })
        );
    }, [dispatch]);

    const options = [
        { value: 'all', label: t('global_show_all') },
        { value: 'checked', label: 'Show checked only' },
        { value: 'unchecked', label: 'Show unchecked only' }
    ];
    const [selectedOption, setSelectedOption] = React.useState(options?.[0]);

    const resetCheckedList = workspaceIds => {
        setCheckedList(workspaceIds);
    };

    const removeChecked = id => {
        setCheckedList(checkedList.filter(checkedId => checkedId !== id));
    };

    const addChecked = id => {
        setCheckedList([...checkedList, id]);
    };

    const handleOptionChange = option => {
        setSelectedOption(option);
    };

    React.useEffect(() => {
        let arrCheckListDetail = [];
        checkedList.forEach(id => {
            arrCheckListDetail = arrCheckListDetail.concat(dataWithId?.[id]);
        });

        onCheckedListChange && onCheckedListChange(arrCheckListDetail);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedList]);

    return (
        <Grid container direction="column" wrap="nowrap">
            <Accordion defaultExpanded className={classes.panel}>
                <AccordionSummary className={classes.panelSummary}>
                    <Grid container justify="space-between" alignItems="flex-end" className={classes.controls}>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item style={{ display: 'flex' }}>
                                    <ArrowDownSVG />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">Access Views</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Use this if you want to share specific views instead of sharing the entire project or database?">
                                        <div style={{ display: 'flex' }}>
                                            <QuestionSVG />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <Grid item container direction="column" style={{ position: 'relative' }} className={className}>
                    {isFetchingWorkspaceViewsDetail && data === null && (
                        <Grid item className={`${classes.loadingSpinner} ${className}`}>
                            <Spinner size={40} thick={5} />
                        </Grid>
                    )}
                    {data !== null && (
                        <>
                            <Grid item style={{ marginBottom: 14 }}>
                                <Grid container>
                                    <Grid item style={{ flex: 1 }} />
                                    <Grid item>
                                        <Search
                                            background={theme.colors.paleGrey}
                                            onChange={e => setFiltervalue(e.target.value)}
                                            placeholder={`${t('global_search')}...`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <WorkspaceListTable
                                    className={className}
                                    data={data
                                        ?.filter(item => {
                                            if (selectedOption.value === 'all') return true;
                                            if (selectedOption.value === 'checked')
                                                return checkedList.includes(item.id);
                                            if (selectedOption.value === 'unchecked')
                                                return !checkedList.includes(item.id);
                                            return true;
                                        })
                                        .filter(item => {
                                            const { dbName, gridName, viewName, workspaceName } = item;
                                            return (
                                                workspaceName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
                                                dbName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
                                                gridName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
                                                viewName?.toLowerCase().includes(filterValue?.toLowerCase())
                                            );
                                        })}
                                    checkedList={checkedList}
                                    resetCheckedList={resetCheckedList}
                                    removeChecked={removeChecked}
                                    addChecked={addChecked}
                                    options={options}
                                    selectedOption={selectedOption}
                                    handleOptionChange={handleOptionChange}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Accordion>
        </Grid>
    );
}

export default React.memo(WorkspaceListTableWrapper, (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
});
