import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notifier from 'notifier/Notifier';
import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import { isElectron, isWindows } from 'utils/os';
import history from 'utils/history';
import initTracker, { sendPageLocationInfo } from './tracker';
import Version from './Version';
import ChromeNotify from './ChromeNotify';
import Maintenance from './Maintenance';
import { ENV } from 'config';
import { PRODUCTION } from 'config/env';
import Dashboard from 'Dashboard';
import SwitchCompany from 'auth/SwitchCompany';
import CreateCompany from 'auth/CreateCompany';
import SamlSelectCompany from 'auth/SamlSelectCompany';
import LoginPage from 'auth/Login';
import SamlLogin from 'auth/SamlLogin';
import GetStartedPage from 'auth/GetStarted';
import Profile from 'auth/profile';
import TMPage from 'tm';
import ForgotPasswordPage from 'auth/ForgotPassword';
import ResetPasswordPage from 'auth/ResetPassword';
import ResetPasswordSuccessPage from 'auth/ResetPasswordSuccess';
import SignUpPage from 'auth/SignUp';
import InitAccountPage from 'auth/InitAccount';
// import TestComponents from 'testComponent';
import Icons from 'icons';
import ShareViewLink from 'shareLink/view';
import GridUIShareViewLayout from './layout/GridUIShareViewLayout';
import CompanyFactorSetup from 'permission/twoFactor/setting/CompanyFactorSetup';
import ProfileFactorSetup from 'permission/twoFactor/profile/ProfileFactorSetup';
import ProfileAppFactorSetup from 'permission/twoFactor/profile/ProfileAppFactorSetup';
import ProfileEmailFactorSetup from 'permission/twoFactor/profile/ProfileEmailFactorSetup';
import LoginUserFactorSetup from 'permission/twoFactor/login/LoginUserFactorSetup';
import LoginCompanyFactorSetup from 'permission/twoFactor/login/LoginCompanyFactorSetup';
import SsoRedirect from 'auth/SsoRedirect';
import CantAccessLayout from 'layout/CantAccessLayout';
import NotFound from './NotFound';
import GridlyUI from './GridlyUI';
import GridUILayout from './layout/GridUILayout';
import Permission from 'permission';
import UseNetworking from 'networking';
import { useDispatch } from 'react-redux';
import UnauthenticatedRoute from 'components/routes/UnauthenticatedRoute';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import MainLayout from 'layout/MainLayout';
import AdvancedSearchLayout from 'layout/AdvancedSearchLayout';
import WorkspaceSidebar from 'workspaces';
import ProfileSidebar from 'auth/Sidebar';
import PermissionSidebar from 'permission/PermissionSidebar';
import TMSidebar from 'tm/Sidebar';
import IntegrationSidebar from 'integrations/Sidebar';
import Integration from 'integrations';
import NotificationsPopup from 'notifications';
import Dialog from 'components/dialog/Dialog';
import * as appActions from 'app/actions';
import { useNotificationType } from 'hooks/app';
import initOpenReplay from './openReplay';
import { useWorkspaceRole } from 'hooks/auth/role';
import Logout from 'auth/Logout';
import { useCompanySubscription } from 'hooks/payment/subscription';
import NotificationCenterNoti from 'layout/notificationCenter/NotificationCenterNoti';
import AdvancedSearch from 'advancedSearch';
import ShareSearchLink from 'shareSearch';
import { isExternal } from 'config';
import AuthenticatedProjectRoute from 'components/routes/AuthenticatedProjectRoute';
import WorkspaceRedirect from 'workspaces/WorkspaceRedirect';
import EditorTmLayout from 'layout/EditorTmLayout';
import EditorTMPage from 'tm/EditorTMPage';
import DeleteAccountConfirmationPage from 'components/deleteAccount/DeleteAccountConfirmationPage';
import DeleteAccountCSConfirmationPage from 'components/deleteAccount/DeleteAccountCSConfirmationPage';

function App() {
    const dispatch = useDispatch();
    const notificationType = useNotificationType();
    const workspaceRole = useWorkspaceRole();
    const companySubscription = useCompanySubscription();
    let intervalRef = React.useRef(null);

    const currentPlan = React.useMemo(() => {
        return companySubscription?.plan;
    }, [companySubscription]);

    useEffect(() => {
        if (isExternal) return;
        initTracker();
    }, []);

    const handleCloseNotification = () => {
        dispatch(appActions.closeNotificationInfo());
    };

    React.useEffect(() => {
        if ([PRODUCTION].includes(ENV)) {
            initOpenReplay({ workspaceRole, currentPlan });
        }
    }, [workspaceRole, currentPlan]);

    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            dispatch(appActions.fetchApiStatus({ successCallback: () => {}, errorCallback: () => {} }));
        }, 5 * 1000 * 60);

        dispatch(appActions.fetchApiStatus({ successCallback: () => {}, errorCallback: () => {} }));

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [dispatch]);

    return (
        <Grid container className={isWindows() ? 'window' : ''}>
            <Notifier />
            <Maintenance />
            <CssBaseline />
            {!isElectron() && <ChromeNotify />}
            <Router history={history}>
                {!isElectron() && !window?.location?.hostname.includes('localhost') && <Version />}
                <UseNetworking />
                <Routes />
                <NotificationCenterNoti />
            </Router>
            <Dialog maxWidth={false} onClose={handleCloseNotification} open={Boolean(notificationType)}>
                <NotificationsPopup notificationType={notificationType} onClose={handleCloseNotification} />
            </Dialog>
            <div style={{ position: 'absolute', zIndex: -1 }} id="scl-div" />
            <pre style={{ position: 'absolute', zIndex: -1 }} id="scl-pre" />
        </Grid>
    );
}

function Routes() {
    const location = useLocation();

    React.useEffect(() => {
        sendPageLocationInfo();
    }, [location]);

    return (
        <Switch>
            <Route path="/" exact>
                <AuthenticatedRoute>
                    <Redirect to={{ pathname: '/projects', search: location.search }} />
                </AuthenticatedRoute>
            </Route>
            <Route path="/projects/:workspaceId/databases/:dbId/grids/:gridId/branches/:branchId/views/:viewId">
                <AuthenticatedProjectRoute>
                    <GridUILayout>
                        <GridlyUI />
                    </GridUILayout>
                </AuthenticatedProjectRoute>
            </Route>
            <Route path="/company-settings">
                <AuthenticatedRoute>
                    <MainLayout leftContentRender={<PermissionSidebar />}>
                        <Permission />
                    </MainLayout>
                </AuthenticatedRoute>
            </Route>
            <Route path={'/projects/:workspaceId'}>
                <AuthenticatedProjectRoute>
                    <MainLayout leftContentRender={<WorkspaceSidebar />}>
                        <Dashboard />
                    </MainLayout>
                </AuthenticatedProjectRoute>
            </Route>
            <Route path={'/projects'}>
                <AuthenticatedRoute>
                    <MainLayout leftContentRender={<WorkspaceSidebar />}>
                        <WorkspaceRedirect />
                    </MainLayout>
                </AuthenticatedRoute>
            </Route>
            {!isExternal && (
                <Route path="/getstarted">
                    <UnauthenticatedRoute>
                        <GetStartedPage />
                    </UnauthenticatedRoute>
                </Route>
            )}
            {!isExternal && (
                <Route path="/signup">
                    <UnauthenticatedRoute>
                        <SignUpPage />
                    </UnauthenticatedRoute>
                </Route>
            )}
            <Route path="/signin">
                <UnauthenticatedRoute>
                    <LoginPage />
                </UnauthenticatedRoute>
            </Route>
            <Route path="/delete-account-confirmation/:key">
                <AuthenticatedRoute>
                    <DeleteAccountConfirmationPage />
                </AuthenticatedRoute>
            </Route>
            <Route path="/cs-delete-account/:key">
                <AuthenticatedRoute>
                    <DeleteAccountCSConfirmationPage />
                </AuthenticatedRoute>
            </Route>
            <Route path="/logout">
                <Logout />
            </Route>
            <Route path="/activate">
                <InitAccountPage />
            </Route>
            <Route path="/companies">
                <AuthenticatedRoute>
                    <SwitchCompany />
                </AuthenticatedRoute>
            </Route>
            <Route path="/create-company">
                <AuthenticatedRoute>
                    <CreateCompany />
                </AuthenticatedRoute>
            </Route>
            <Route path="/saml-companies">
                <SamlSelectCompany />
            </Route>
            <Route path="/saml">
                <SamlLogin />
            </Route>
            <Route exact path={`/tm-editor/:tmId`}>
                <AuthenticatedRoute>
                    <EditorTmLayout>
                        <EditorTMPage />
                    </EditorTmLayout>
                </AuthenticatedRoute>
            </Route>
            <Route path="/profile">
                <AuthenticatedRoute>
                    <MainLayout leftContentRender={<ProfileSidebar />} forcedLeftContent>
                        <Profile />
                    </MainLayout>
                </AuthenticatedRoute>
            </Route>
            <Route path="/localization-settings">
                <AuthenticatedRoute>
                    <MainLayout leftContentRender={<TMSidebar />}>
                        <TMPage />
                    </MainLayout>
                </AuthenticatedRoute>
            </Route>
            <Route path="/p/:workspaceId/s">
                <AuthenticatedProjectRoute>
                    <AdvancedSearchLayout>
                        <AdvancedSearch />
                    </AdvancedSearchLayout>
                </AuthenticatedProjectRoute>
            </Route>
            <Route path="/integration-settings">
                <AuthenticatedRoute>
                    <MainLayout leftContentRender={<IntegrationSidebar />}>
                        <Integration />
                    </MainLayout>
                </AuthenticatedRoute>
            </Route>
            <Route path="/reset/init">
                <UnauthenticatedRoute>
                    <ForgotPasswordPage />
                </UnauthenticatedRoute>
            </Route>
            <Route path="/reset/finish">
                <UnauthenticatedRoute>
                    <ResetPasswordPage />
                </UnauthenticatedRoute>
            </Route>
            <Route path="/reset/success">
                <UnauthenticatedRoute>
                    <ResetPasswordSuccessPage />
                </UnauthenticatedRoute>
            </Route>
            {/* <Route path="/test-components">
                <TestComponents />
            </Route> */}
            <Route path="/icons">
                <Icons />
            </Route>
            <Route path="/shared/:key">
                <GridUIShareViewLayout>
                    <ShareViewLink />
                </GridUIShareViewLayout>
            </Route>
            <Route path="/search/shared/:key">
                <GridUIShareViewLayout>
                    <ShareSearchLink />
                </GridUIShareViewLayout>
            </Route>
            <Route path="/company-2fa">
                <CompanyFactorSetup />
            </Route>
            <Route path="/profile-2fa">
                <AuthenticatedRoute>
                    <ProfileFactorSetup />
                </AuthenticatedRoute>
            </Route>
            <Route path="/profile-2fa-app">
                <AuthenticatedRoute>
                    <ProfileAppFactorSetup />
                </AuthenticatedRoute>
            </Route>
            <Route path="/signin-user-2fa">
                <AuthenticatedRoute>
                    <LoginUserFactorSetup />
                </AuthenticatedRoute>
            </Route>
            <Route path="/profile-2fa-email">
                <ProfileEmailFactorSetup />
            </Route>
            <Route path="/signin-company-2fa">
                <LoginCompanyFactorSetup />
            </Route>
            <Route path="/sso-redirect">
                <SsoRedirect />
            </Route>
            <Route path="/logout">
                <Logout />
            </Route>
            <Route path="*">
                <CantAccessLayout>
                    <NotFound />
                </CantAccessLayout>
            </Route>
        </Switch>
    );
}

export default App;
