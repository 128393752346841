import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import ButtonBase from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useTranslation } from 'react-i18next';
import Search from 'components/search';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import TagDetailAdd from './TagDetailAdd';
import ThreeStateCheckBox from 'components/checkbox/ThreeStateCheckBox';
import Spinner from 'components/spinner/Base';
import PaginationBase from 'components/pagination/Base';
import Dialog from 'components/dialog/Dialog';
import ConfirmBox from 'components/confirmBox/Base';
import { useDispatch } from 'react-redux';
import { deleteTokens, importTokenSets } from 'gridUI/actions';
import Tooltip from 'components/tooltip/Base';
import { useDropzone } from 'react-dropzone';
import { enqueueSnackbar } from 'notifier/actions';
import { getUploadErrorMessage } from 'utils/upload';
import { COLOR_TYPES, GRID_UPLOAD_MAX_SIZE, TB_UPLOAD_SUPPORTED_EXTENSIONS } from 'const';
import ImportSVG from 'assets/images/svg/ImportSVG';
import ExportSVG from 'assets/images/svg/ExportSVG';
import TableVirtualize from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import ExportTagDialog from './ExportTagDialog';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100%)',
        padding: theme.spacing(4)
    },
    top: {
        marginBottom: 47
    },
    searchBox: {
        border: `1px solid ${theme.colors.silver}`,
        background: theme.colors.white,
        marginRight: 8,
        '& input': {
            '&::placeholder': {
                fontSize: '14px !important',
                color: `${theme.colors.lightGreyBlue} !important`,
                opacity: 1
            }
        }
    },
    deleteBox: {
        borderRadius: 4,
        margin: '0 8px',
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.5s ease',
        '&.mr-16': {
            marginRight: 16
        },
        '& svg': {
            width: 24,
            height: 24,
            '& path': {
                fill: theme.colors.disabledGrey
            }
        },
        '&.active': {
            background: 'transparent',
            cursor: 'pointer',
            '& path': {
                fill: theme.colors.midGrey
            },
            '&:hover': {
                background: theme.colors.solitude,
                '& path': {
                    fill: theme.palette.primary.main
                }
            }
        }
    },
    empty: {
        height: 'calc(100% - 83px)'
    },
    tHeaderFirstCell: {
        width: 100,
        background: theme.colors.white
    },
    langName: {
        color: theme.colors.steel
    },
    checkbox: {
        '& rect': {
            fill: theme.colors.white
        }
    },
    loading: {
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        background: 'rgba(0, 0, 0, 0.1)'
    },
    pagination: {
        marginTop: 20
    },
    tableClassName: {
        '& .ReactVirtualized__Table__headerRow': {
            background: 'none'
        }
    },
    content: {
        height: 'calc(100% - 36px - 47px)',
        '& .table-row': {
            cursor: 'pointer',
            position: 'relative',
            '& .table-row-inner': {
                '&:hover': {
                    background: 'rgba(0, 0, 0, 0.04)'
                }
            }
        }
    },
    icons: {
        '& svg': {
            width: 20,
            height: 20
        }
    },
    buttonProgress: {
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    }
}));

const TBDetailOverview = ({
    searchValue,
    handleSearchChange,
    handleClearSearch,
    tagModel,
    tokenSets,
    isFetching,
    selectedRows,
    setSelectedRows,
    handleResetFetch,
    currentPage,
    totalTokenSets,
    limit,
    handleChangePage
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [openAdd, setOpenAdd] = useState(false);
    const [selectedToken, setSelectedToken] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [importing, setImporting] = useState(false);
    const tableContainerRef = useRef();
    const [sorting, setSorting] = useState({});
    const [openExport, setOpenExport] = useState(false);

    const _handleOpenAdd = useCallback(() => {
        setOpenAdd(true);
    }, []);

    const _handleCloseAdd = useCallback(() => {
        setOpenAdd(false);
        setSelectedToken(null);
    }, []);

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const renderLoading = useMemo(() => {
        if (isFetching) {
            return (
                <Grid
                    container
                    className={classes.loading}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    style={{ top: tableContainerRef?.current?.scrollTop, left: tableContainerRef?.current?.scrollLeft }}
                >
                    <Spinner size={30} thick={4} />
                </Grid>
            );
        }
        return null;
    }, [isFetching, classes]);

    const handleCheckRow = React.useCallback(
        (e, id) => {
            e.stopPropagation();
            e.preventDefault();
            if (selectedRows.includes(id)) {
                const newSelectedRows = selectedRows.filter(r => r !== id);
                setSelectedRows(newSelectedRows);
                return;
            }
            selectedRows.push(id);
            setSelectedRows([...selectedRows]);
        },
        [selectedRows, setSelectedRows]
    );

    const data = React.useMemo(() => {
        const cloneData = [...tokenSets].filter(tag => !searchValue?.trim() || tag?.value.indexOf(searchValue) > -1);
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, tokenSets, searchValue]);

    const handleOpenEditTag = useCallback(
        idx => {
            setSelectedToken(data?.[idx]);
            _handleOpenAdd(true);
        },
        [_handleOpenAdd, data]
    );

    const handleOpenDeleteDialog = useCallback(() => {
        if (selectedRows.length) {
            setOpenDeleteDialog(true);
        }
    }, [selectedRows]);

    const closeDeleteDialog = useCallback(() => {
        setOpenDeleteDialog(false);
    }, []);

    const handleDeleteTokens = useCallback(() => {
        if (confirming) return;
        setConfirming(true);
        dispatch(
            deleteTokens({
                tokenSetId: tagModel.id,
                body: {
                    tokenIds: selectedRows
                },
                successCallback: () => {
                    setConfirming(false);
                    closeDeleteDialog();
                    handleResetFetch({ resetPage: true });
                    setSelectedRows([]);
                },
                errorCallback: () => {
                    setConfirming(false);
                }
            })
        );
    }, [dispatch, confirming, tagModel, selectedRows, handleResetFetch, closeDeleteDialog, setSelectedRows]);

    const onDropLocalizationRejected = useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, TB_UPLOAD_SUPPORTED_EXTENSIONS);
            const fileError = fileErrors?.[0];

            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const handleOpenExport = useCallback(() => {
        setOpenExport(true);
    }, []);

    const handleCloseExport = useCallback(() => {
        setOpenExport(false);
    }, []);

    const handleFileImportInput = files => {
        setImporting(true);
        dispatch(
            importTokenSets({
                tokenSetId: tagModel?.id,
                file: files[0],
                successCallback: () => {
                    setImporting(false);
                    handleResetFetch({ resetPage: true });
                },
                errorCallback: () => {
                    setImporting(false);
                }
            })
        );
    };

    const {
        getRootProps: getRootPropsLocalization,
        getInputProps: getInputPropsLocalization
        // isDragActive: isDragActiveLocalization
    } = useDropzone({
        noDragEventsBubbling: true,
        onDropAccepted: handleFileImportInput,
        onDropRejected: onDropLocalizationRejected,
        accept: TB_UPLOAD_SUPPORTED_EXTENSIONS,
        multiple: false,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        disabled: importing
    });

    const COLUMNS = useMemo(() => {
        return [
            {
                dataKey: 'id',
                width: 60,
                cellRenderer: ({ rowData }) => {
                    const isChecked = selectedRows.includes(rowData?.id);
                    return (
                        <ThreeStateCheckBox
                            className={!isChecked ? classes.checkbox : ''}
                            state={isChecked ? 1 : 2}
                            onChange={e => handleCheckRow(e, rowData?.id)}
                        />
                    );
                }
            },
            {
                label: t('tag_value'),
                dataKey: 'value',
                sort: true,
                className: classes.cellName,
                cellRenderer: ({ rowData }) => (
                    <Grid container alignItems="center">
                        <Grid item className={classes.rowCellName}>
                            <Typography variant="body2">{rowData.value}</Typography>
                        </Grid>
                    </Grid>
                ),
                flexGrow: 1
            }
        ];
    }, [classes, handleCheckRow, selectedRows, t]);

    return (
        <Grid item className={classes.root}>
            <Grid className={classes.top} container justify="flex-end" alignItems="center">
                <Grid item>
                    <Search
                        className={classes.searchBox}
                        background={theme.colors.paleGrey}
                        onChange={handleSearchChange}
                        defaultValue={searchValue}
                        placeholder={`${t('global_search')}`}
                        width={240}
                        onClear={handleClearSearch}
                    />
                </Grid>
                <Tooltip title={t('import_tags')}>
                    <Grid className={`${classes.deleteBox} active`} item {...getRootPropsLocalization({})}>
                        <input
                            {...getInputPropsLocalization()}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            // tr-dt="Import terms"
                        />
                        {importing ? <CircularProgress className={classes.buttonProgress} /> : <ImportSVG />}
                    </Grid>
                </Tooltip>
                <Grid item>
                    <Tooltip title={t('export_tag')}>
                        <Grid item className={`${classes.deleteBox} active`} onClick={handleOpenExport}>
                            <ExportSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={t('delete_tags')}>
                        <Grid
                            item
                            className={`${classes.deleteBox} ${selectedRows.length ? 'active' : ''} mr-16`}
                            onClick={handleOpenDeleteDialog}
                        >
                            <DeleteSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <ButtonBase variant="contained" width={140} onClick={_handleOpenAdd}>
                        <AddIconSVG color={theme.colors.white} style={{ marginRight: 8, width: 10, height: 10 }} />
                        {t('add_tag')}
                    </ButtonBase>
                </Grid>
            </Grid>
            {!isFetching && !tokenSets?.length && (
                <Grid
                    container
                    className={classes.empty}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <EmptyBoxSVG width="252" height="196" />
                    </Grid>
                    <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">{t('add_a_new_tag_now_to_begin')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {tokenSets.length > 0 && (
                <Grid container direction="column" className={classes.content} wrap="nowrap">
                    <Grid item style={{ height: 'calc(100% - 78px)' }}>
                        {renderLoading}
                        <AutoSizer>
                            {({ width, height }) => (
                                <TableVirtualize
                                    className={classes.tableClassName}
                                    data={data}
                                    columns={COLUMNS}
                                    width={width}
                                    height={height}
                                    onRowClick={handleOpenEditTag}
                                    onSort={handleOnSort}
                                ></TableVirtualize>
                            )}
                        </AutoSizer>
                    </Grid>
                    <Grid item>
                        <Grid container justify="center">
                            <Grid item>
                                <PaginationBase
                                    className={classes.pagination}
                                    count={Math.ceil(totalTokenSets / limit)}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {openAdd && (
                <TagDetailAdd
                    tag={tagModel}
                    initData={selectedToken}
                    open={openAdd}
                    onClose={_handleCloseAdd}
                    handleResetFetch={handleResetFetch}
                />
            )}
            <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}>
                <ConfirmBox
                    title={t('delete_tokens')}
                    body={`Are you sure you want to delete ${selectedRows.length} selected tags? This action cannot be undo`}
                    handleCancel={closeDeleteDialog}
                    onClose={closeDeleteDialog}
                    handleAgreed={handleDeleteTokens}
                    isLoading={confirming}
                    agreeLabel={t('global_remove')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
            <ExportTagDialog open={openExport} tag={tagModel} onClose={handleCloseExport} />
        </Grid>
    );
};

export default React.memo(TBDetailOverview);
