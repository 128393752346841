import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import IOSSwitch from 'components/switches/IOS';
import { useDispatch } from 'react-redux';
import { useRoleList, useIsFetchingRoles } from 'hooks/permission/role';
import { useRole as useAuthorities } from 'hooks/auth/role';
import { useSelectedRoleDetail } from 'hooks/permission/role';
import { useCurrentUserRole } from 'hooks/auth';
import SystemRoleFlag from './SystemRoleFlag';
import RoleContentSkeleton from './RoleContentSkeleton';
import * as roleConst from 'auth/roleConst';
import * as roleActions from 'permission/actions/role';
import { useCanCustomUserRole } from 'hooks/payment';
import ToolTipClickContent from 'components/tooltip/ToolTipClickContent';
import Tooltip from 'components/tooltip/Base';
import LDButton from 'components/button/Base';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import OverLimitTag from 'payment/components/tags/Overlimit';
import { DISABLED_OPACITY } from 'const/style';
import { useHistory } from 'react-router-dom';
import { enqueueSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `40px 20px 0px`
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    ml2: {
        marginLeft: theme.spacing(2)
    },
    mt2: {
        marginTop: theme.spacing(4)
    },
    learnMore: {
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 600
        }
    },
    whiteText: {
        color: theme.colors.white
    },
    warningTooltip: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

function RoleContent({ selectedRoleId, defaultPrivileges }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const groupByDefaultPrivileges = groupBy(defaultPrivileges, 'label');
    const authorities = useAuthorities();
    const roles = useRoleList();
    const isFetchingRoles = useIsFetchingRoles();
    const selectedRoleDetail = useSelectedRoleDetail();
    const canCustomUserRole = useCanCustomUserRole();
    const userCurrentRole = useCurrentUserRole();

    const isBlockCurrentEditPermission = React.useMemo(() => {
        return (
            !selectedRoleDetail?.isSystemRole &&
            !userCurrentRole?.isSystemRole &&
            userCurrentRole?.id === selectedRoleDetail?.id
        );
    }, [selectedRoleDetail, userCurrentRole]);

    const isSystemRole = React.useMemo(() => {
        return selectedRoleDetail?.isSystemRole;
    }, [selectedRoleDetail]);

    const privilegeIds = React.useMemo(() => {
        return selectedRoleDetail?.privilegeIds || [];
    }, [selectedRoleDetail]);

    const handleUpdatePrivileges = ({ privilegeId, checked }) => {
        const { privilegeIds = [] } = selectedRoleDetail;
        let newPrivilegeIds = [...privilegeIds];
        if (checked) {
            newPrivilegeIds = [...newPrivilegeIds, privilegeId];
        } else {
            newPrivilegeIds = newPrivilegeIds.filter(id => id !== privilegeId);
        }
        dispatch(
            roleActions.updateRolePrivilegeIds({
                name: selectedRoleDetail?.name,
                oldPrivilegeIds: privilegeIds,
                newPrivilegeIds,
                successCallback: () => {
                    console.log('set privilege to role successfully');
                    dispatch(
                        enqueueSnackbar({
                            message: `Changes saved`,
                            type: 'info'
                        })
                    );
                },
                errorCallback: () => {
                    console.log('failed to set privilege to role');
                }
            })
        );
    };

    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    const handleLearnMore = () => {
        window.open(`https://www.gridly.com/`, '_blank');
    };

    const isNotSystemRoleAndCantEditRole = React.useMemo(() => {
        return !isSystemRole && !canCustomUserRole;
    }, [isSystemRole, canCustomUserRole]);

    if (isFetchingRoles && roles === null) return <RoleContentSkeleton />;

    return (
        <Grid className={classes.root} direction="column" container>
            <Grid item>
                <Grid direction="column" container spacing={5}>
                    <Grid item>
                        <Grid container direction="column" spacing={1} wrap="nowrap">
                            <Grid item>
                                <Grid direction="row" container alignItems="center" spacing={2}>
                                    {isSystemRole && (
                                        <Grid item style={{ display: 'flex' }}>
                                            <SystemRoleFlag size={16} role={selectedRoleDetail} />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Grid container alignItems="center" direction="row">
                                            <Grid item>
                                                <Typography variant="h3" display="inline">
                                                    {selectedRoleDetail?.name}{' '}
                                                </Typography>
                                                {isSystemRole && (
                                                    <Typography
                                                        style={{ fontSize: 14, fontWeight: 'normal' }}
                                                        variant="caption"
                                                        display="inline"
                                                    >
                                                        ({t('default_role')})
                                                    </Typography>
                                                )}
                                            </Grid>
                                            {isNotSystemRoleAndCantEditRole && (
                                                <Grid item className={classes.ml2}>
                                                    <ToolTipClickContent
                                                        title={
                                                            <Grid
                                                                direction="column"
                                                                container
                                                                className={classes.explainWarning}
                                                            >
                                                                <Grid item>
                                                                    <Typography variant="body1">
                                                                        This company role is locked.Custom User Roles
                                                                        are available on the Enterprise plan
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item className={classes.mt2}>
                                                                    <Grid
                                                                        container
                                                                        justify="space-between"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item>
                                                                            <Typography
                                                                                onClick={handleLearnMore}
                                                                                className={classes.learnMore}
                                                                                variant="body1"
                                                                            >
                                                                                {t('global_learn_more')}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <LDButton
                                                                                width={120}
                                                                                variant="contained"
                                                                                onClick={handleUpgrade}
                                                                            >
                                                                                <UpgradeSVG
                                                                                    className={classes.upgradeIcon}
                                                                                />{' '}
                                                                                Upgrade
                                                                            </LDButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    >
                                                        <Grid item style={{ cursor: 'pointer' }}>
                                                            <OverLimitTag title={'View only'} />
                                                        </Grid>
                                                    </ToolTipClickContent>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item>
                                <Typography variant="caption">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, perspiciatis?
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            className={isNotSystemRoleAndCantEditRole ? classes.disabled : ''}
                            container
                            direction="column"
                            wrap="nowrap"
                            spacing={5}
                        >
                            {Object.keys(groupByDefaultPrivileges).map((label, index) => {
                                const privileges = groupByDefaultPrivileges?.[label];
                                return (
                                    <Grid key={label} item>
                                        <Grid container spacing={4} direction="column" wrap="nowrap">
                                            {index !== 0 && (
                                                <Grid item>
                                                    <Divider />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Typography variant="h4">{label} privileges</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={4} wrap="nowrap">
                                                    {privileges?.map(privilege => {
                                                        let checked =
                                                            privilegeIds.findIndex(priId => priId === privilege.id) !==
                                                            -1
                                                                ? true
                                                                : false;
                                                        const isCanNotChangeRole = isSystemRole
                                                            ? true
                                                            : authorities?.[privilege?.name] !== roleConst.FULL;
                                                        return (
                                                            <Grid key={privilege?.id} item>
                                                                <Grid container direction={'row'} wrap="nowrap">
                                                                    <Grid item xs={10}>
                                                                        <Grid container direction="column" spacing={2}>
                                                                            <Grid item>
                                                                                <Typography variant="body1">
                                                                                    {privilege?.description}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="caption">
                                                                                    {privilege?.extra}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Grid container justify="flex-end">
                                                                            {isCanNotChangeRole ||
                                                                            isBlockCurrentEditPermission ? (
                                                                                <Tooltip
                                                                                    title={
                                                                                        <Grid
                                                                                            container
                                                                                            direction="column"
                                                                                            justify="center"
                                                                                            alignItems="center"
                                                                                            className={
                                                                                                classes.warningTooltip
                                                                                            }
                                                                                        >
                                                                                            {isCanNotChangeRole ? (
                                                                                                <>
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        className={
                                                                                                            classes.whiteText
                                                                                                        }
                                                                                                    >
                                                                                                        Default role
                                                                                                        privileges
                                                                                                        cannot be
                                                                                                        changed.
                                                                                                    </Typography>
                                                                                                    <Typography
                                                                                                        variant="body2"
                                                                                                        className={
                                                                                                            classes.whiteText
                                                                                                        }
                                                                                                    >
                                                                                                        Add new a role
                                                                                                        to set up the
                                                                                                        privileges you
                                                                                                        want
                                                                                                    </Typography>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Typography
                                                                                                    variant="body2"
                                                                                                    className={
                                                                                                        classes.whiteText
                                                                                                    }
                                                                                                >
                                                                                                    You can not change
                                                                                                    your granted
                                                                                                    permission
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Grid>
                                                                                    }
                                                                                    placement="top"
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            cursor: `not-allowed`
                                                                                        }}
                                                                                    >
                                                                                        <IOSSwitch
                                                                                            checked={checked}
                                                                                            className={classes.disabled}
                                                                                        />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center'
                                                                                    }}
                                                                                >
                                                                                    <IOSSwitch
                                                                                        checked={checked}
                                                                                        onChange={e =>
                                                                                            handleUpdatePrivileges({
                                                                                                privilegeId:
                                                                                                    privilege.id,
                                                                                                checked:
                                                                                                    e.target.checked
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(RoleContent);
