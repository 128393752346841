import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import Tooltip from 'components/tooltip/Base';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ReferenceSVG from 'components/svg-icon/ReferenceSVG';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { ROW_HEIGHT, COLUMN_RESIZE_WIDTH, COLUMN_FORMAT_STATES } from 'const/gridUI';
import { isDragMultipleColumn } from 'utils/gridUI/column';
import * as roleConst from 'auth/roleConst';
import { SYSTEM_COLUMN_IDS } from 'const';
import Spinner from 'components/spinner/Base';
import GridIcon from 'grids/GridIcon';
import PopperMenu from 'components/menus/Popper';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as columnTypes from 'const/columnTypes';
import ColumnSetting from './columns';
import { checkContainId } from 'utils/clickAway';
import { sendManualTrack } from 'tracker';
import * as gridUIActions from 'gridUI/actions';
import { useTranslation } from 'react-i18next';
import ModalBase from 'components/modal/Base';
import UploadFolderModal from './UploadFolderModal';
import { useFormatColumnId, useIsColumnProcessing } from 'hooks/gridUI';
import classNames from 'classnames';
import BranchSVG from 'assets/images/svg/BranchSVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import { setSearchRange } from 'gridUI/actions';

const TooltipInfo = React.memo(({ column, children }) => {
    const { t } = useTranslation();

    const renderContent = useCallback((title, content) => {
        return (
            <div className="flex gap-1 items-center">
                <p className="text-light !font-normal">{title}: </p>
                <p className="text-light font-medium">{content}</p>
            </div>
        );
    }, []);

    const columnType = React.useMemo(() => {
        return column?.type;
    }, [column]);

    const columnId = React.useMemo(() => {
        return column?.publicId || column?.columnId || column?.id;
    }, [column]);

    return (
        <Grid container className={'max-[280px]'} direction="column">
            {renderContent(t('global_id'), columnId === columnTypes.RECORD_ID ? '_recordId' : columnId)}
            {column.name && renderContent(t('global_name'), column.name)}
            {column.type && renderContent(t('global_type'), columnTypes.getColumnLabel(column.type))}
            {columnType === columnTypes.REFERENCE &&
                renderContent(t('reference_grid'), column?.referenceSettings?.referencedGridName)}
            {columnType === columnTypes.REFERENCE &&
                renderContent(t('reference_branch'), column?.referenceSettings?.referencedBranchName)}
            {columnType === columnTypes.REFERENCE &&
                renderContent(t('reference_column'), column?.referenceSettings?.referencedColumnName)}
            {column.description && renderContent(t('global_description'), column.description)}
        </Grid>
    );
});

function Header({
    id,
    column,
    // position,
    columnIndex,
    isSelectionActive,
    setColumnHandlerStartPosition = () => {},
    setColumnReorderStartPosition = () => {},
    columns,
    accessManageGridColumn,
    accessManageGridRecord,
    contextMenuId,
    isImporting,
    isShareViewLink,
    columnWidth
}) {
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const [refAnchorEl, setRefAnchorEl] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentState, setCurrentState] = React.useState(COLUMN_FORMAT_STATES.MENU);
    const [isOpenUploadFolder, setIsOpenUploadFolder] = React.useState(false);
    const formatColumnId = useFormatColumnId();
    const [isClickAwayColumnFormat, setIsClickAwayColumnFormat] = React.useState(false);

    const columnId = React.useMemo(() => {
        return column?.id || column?.columnId;
    }, [column]);

    const isProcessing = useIsColumnProcessing(columnId);

    const columnType = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const referencingItems = React.useMemo(() => {
        return column?.referencingItems;
    }, [column]);

    const timerRef = React.useRef(null);
    let columnsSelectedRef = React.useRef([]);

    const isLockByRole = React.useMemo(() => {
        return accessManageGridColumn !== roleConst.FULL || !accessManageGridColumn;
    }, [accessManageGridColumn]);

    const isSystemColumn = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS.includes(columnId);
    }, [columnId]);

    const isDisabled = React.useMemo(() => {
        return isLockByRole || isSystemColumn || isProcessing;
    }, [isLockByRole, isSystemColumn, isProcessing]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleRefClick = React.useCallback(
        event => {
            setRefAnchorEl(refAnchorEl ? null : event.currentTarget);
        },
        [refAnchorEl]
    );

    const handleRefClickAway = React.useCallback(() => {
        setRefAnchorEl(null);
    }, []);

    React.useEffect(() => {
        if (formatColumnId && formatColumnId === columnId) {
            setAnchorEl(rootRef.current);
            setCurrentState(COLUMN_FORMAT_STATES.FORMAT);
        } else {
            setAnchorEl(null);
            setCurrentState(COLUMN_FORMAT_STATES.MENU);
        }
    }, [formatColumnId, columnId]);

    const handleHeaderClick = useCallback(
        e => {
            const { isShift, isCtrl } = getStatusCtrlOrShiftKey(e);
            dispatch(
                gridUIActions.columnSelection({
                    isCtrl,
                    isShift,
                    columnIndex: columnIndex,
                    columnSelected: columnId,
                    callback: ({ columnsSelected }) => {
                        columnsSelectedRef.current = columnsSelected;
                    }
                })
            );
            dispatch(setSearchRange(null));
            if (e && accessManageGridColumn === roleConst.FULL) {
                const eHold = { ...e };
                timerRef.current = setTimeout(() => {
                    setColumnReorderStartPosition({
                        isMulti: isDragMultipleColumn({ columns, columnsSelected: columnsSelectedRef.current }),
                        columnsSelected: columnsSelectedRef.current,
                        e: eHold,
                        columnId: columnId
                    });
                }, 200);
            }
        },
        [dispatch, columnId, columnIndex, columns, setColumnReorderStartPosition, accessManageGridColumn]
    );

    const handleReorderRelease = () => {
        if (timerRef && timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    const handleColumnMenuClick = useCallback(
        e => {
            if (isLockByRole || isProcessing) return;
            setAnchorEl(rootRef.current);
            setCurrentState(COLUMN_FORMAT_STATES.MENU);
            handleHeaderClick();
        },
        [handleHeaderClick, isLockByRole, isProcessing]
    );

    const handleDoubleClick = React.useCallback(
        e => {
            if (isDisabled) return;
            dispatch(gridUIActions.resetSelection());
            setAnchorEl(e.currentTarget);
            setCurrentState(COLUMN_FORMAT_STATES.FORMAT);
        },
        [isDisabled, dispatch]
    );

    const handleContext = React.useCallback(
        e => {
            dispatch(setSearchRange(null));
            if (isShareViewLink) return;
            setAnchorEl(e.currentTarget);
            setCurrentState(COLUMN_FORMAT_STATES.MENU);
        },
        [isShareViewLink]
    );

    const handleClickAway = React.useCallback(e => {
        setRefAnchorEl(null);
    }, []);

    const handleColumnClickAway = React.useCallback(
        e => {
            console.log('handleColumnClickAway', e);
            if (
                checkContainId(e, 'column-setting') ||
                checkContainId(e, 'new-column-format') ||
                checkContainId(e, 'ConfirmSwitchingBox') ||
                checkContainId(e, 'calendar-portal') ||
                checkContainId(e, 'formula-suggestion') ||
                checkContainId(e, 'confirm-delete-column') ||
                checkContainId(e, 'ConfirmSwitchingBox') ||
                checkContainId(e, 'popper-language') ||
                checkContainId(e, 'popper-timezone') ||
                checkContainId(e, 'confirm-column-box') ||
                checkContainId(e, 'find_in_this_column')
            ) {
                return;
            }

            if (currentState === COLUMN_FORMAT_STATES.FORMAT) {
                setIsClickAwayColumnFormat(true);

                setTimeout(() => {
                    setIsClickAwayColumnFormat(false);
                }, 0);
            } else {
                setAnchorEl(null);
                dispatch(gridUIActions.setFormatColumnId(null));
            }
        },
        [dispatch, currentState]
    );

    const isPrimaryReferenceColumn = React.useMemo(() => {
        return column?.referenceSettings?.primaryReference && column?.referenceSettings?.referenceType === 'row';
    }, [column]);

    const iconWidth = React.useMemo(() => {
        const isHaveRefer = referencingItems?.length > 0;

        let width = 16;
        width = !isSystemColumn ? 16 + 4 + 14 : width;

        if (isHaveRefer) {
            width += 33 + 4;
        }

        if (isPrimaryReferenceColumn) {
            width += 16 + 4;
        }

        return width;
    }, [referencingItems, isSystemColumn, isPrimaryReferenceColumn]);

    const handleDeleteColumn = React.useCallback(
        e => {
            sendManualTrack({
                type: `Delete Column`,
                customData: {
                    columnId
                }
            });
            dispatch(
                gridUIActions.deleteGridColumn({
                    columnId,
                    columnIndex,
                    column,
                    errorCallback: () => {
                        console.log('failed to delete column');
                    },
                    successCallback: () => {
                        console.log('delete successfully');
                    }
                })
            );
        },
        [dispatch, columnId, columnIndex, column]
    );

    const handleOpenUploadFolder = React.useCallback(e => {
        setIsOpenUploadFolder(true);
        setAnchorEl(null);
    }, []);

    const handleCloseUploadFolder = React.useCallback(() => {
        setIsOpenUploadFolder(false);
    }, []);

    return (
        <Grid
            id={id}
            container
            justifyContent="space-between"
            alignItems="center"
            className={classNames(`px-3.5 py-0 h-full`, {
                disabled: isImporting
            })}
            onDoubleClick={handleDoubleClick}
            onContextMenu={handleContext}
            ref={rootRef}
        >
            <Grid
                style={{ width: `calc(100% - ${iconWidth}px)`, cursor: 'pointer' }}
                item
                className={classNames(`flex items-center`, {
                    columnHeader: isSystemColumn
                })}
            >
                <Tooltip title={<TooltipInfo column={column} />}>
                    <Grid
                        container
                        onMouseUp={handleReorderRelease}
                        onMouseDown={handleHeaderClick}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        wrap="nowrap"
                    >
                        <Grid item className="flex opacity-80">
                            <ColumnIcon
                                group={column.group}
                                type={columnType}
                                customProperties={column.customProperties}
                                hideLabelCountry={columnWidth <= 200}
                                styleCountryFlagWrapper={{
                                    width: 24
                                }}
                            />
                        </Grid>

                        <Grid item className="overflow-hidden text-ellipsis flex-1 ml-2">
                            <p className="whitespace-nowrap inline body2">{column.name}</p>
                            {columnType === columnTypes.REFERENCE && (
                                <p className="whitespace-nowrap inline caption" component="span">
                                    {' '}
                                    (from {column?.referenceSettings?.referencedGridName})
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </Tooltip>
            </Grid>
            <Grid style={{ width: iconWidth }} id={`dropdown_${columnId}`} item>
                <Grid
                    id={`${id}-right-icon`}
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    container
                    justifyContent="flex-end"
                >
                    {isPrimaryReferenceColumn && (
                        <Grid
                            item
                            className={'rounded bg-blue-dodger w-4 h-4 flex justify-center items-center text-white'}
                        >
                            P
                        </Grid>
                    )}

                    {referencingItems?.length > 0 && (
                        <Grid item>
                            <Grid
                                item
                                onClick={handleRefClick}
                                className={
                                    '[&>svg]:text-lavender-light px-2 py-1 rounded-sm bg-solitude mr-2 flex cursor-pointer'
                                }
                            >
                                <ReferenceSVG />
                                <p className={'body1 text-lavender-light text-sm pl-1'}>{referencingItems?.length}</p>
                            </Grid>
                        </Grid>
                    )}

                    {isProcessing && <Spinner size={18} thick={3} />}
                    {!(isLockByRole || isProcessing) && (
                        <ArrowDownSVG
                            onClick={handleColumnMenuClick}
                            tr-dt="Open Column Setting"
                            className={'cursor-pointer'}
                        />
                    )}
                </Grid>
            </Grid>

            {accessManageGridRecord === roleConst.FULL && (
                <div
                    className={
                        'bg-blue-dodger absolute top-0 right-0 opacity-0 hover:opacity-100 hover:cursor-ew-resize'
                    }
                    style={{
                        width: COLUMN_RESIZE_WIDTH + 2,
                        height: ROW_HEIGHT
                    }}
                    onMouseDown={e => setColumnHandlerStartPosition({ e, columnId })}
                    id={`columnHandler_${columnId}`}
                ></div>
            )}

            {refAnchorEl && (
                <PopperMenu anchorEl={refAnchorEl} handleClickAway={handleRefClickAway} placement={'bottom'}>
                    <div ref={rootRef} className="flex flex-col max-w-[500px] cursor-default py-2">
                        {referencingItems?.map(item => {
                            return (
                                <div className="flex gap-1 items-center">
                                    <div className="w-full flex-1 flex items-center gap-2 px-3.5">
                                        <GridIcon grid={item?.grid} size="small" />
                                        <p className="body2 flex-1 truncate">{item?.grid?.name}</p>
                                    </div>
                                    <ArrowRightSVG className={'flex-none'} />
                                    <div className="w-full flex flex-1 items-center gap-2 px-3.5">
                                        <BranchSVG color="#79778B" width="14" height="14" />
                                        <p className="body2 flex-1 truncate">{item?.branch?.name}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </PopperMenu>
            )}

            {anchorEl && (
                <PopperMenu
                    className={'min-w-[220px]'}
                    anchorEl={anchorEl}
                    handleClickAway={handleColumnClickAway}
                    placement={'bottom-start'}
                    id={'column-setting'}
                >
                    <ColumnSetting
                        columnIndex={columnIndex}
                        onClickAway={handleColumnClickAway}
                        state={currentState}
                        column={column}
                        onDelete={handleDeleteColumn}
                        onChangeState={state => setCurrentState(state)}
                        onUpload={handleOpenUploadFolder}
                        isClickAwayColumnFormat={isClickAwayColumnFormat}
                        onClose={() => {
                            setAnchorEl(null);
                            dispatch(gridUIActions.setFormatColumnId(null));
                        }}
                    />
                </PopperMenu>
            )}

            <ModalBase maxWidth={false} open={isOpenUploadFolder} onClose={handleCloseUploadFolder}>
                <UploadFolderModal column={column} onClose={handleCloseUploadFolder} />
            </ModalBase>
        </Grid>
    );
}

Header.propTypes = {
    column: PropTypes.object.isRequired,
    isSelectionActive: PropTypes.bool,
    columnIndex: PropTypes.any,
    position: PropTypes.object
};

export default React.memo(Header);
