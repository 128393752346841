import React from 'react';
import { Grid, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import SettingContentLayout from 'permission/common/Layout';
import { useDispatch } from 'react-redux';
import {
    fetchListTermsBase,
    getAutoQASettings,
    deleteAutoQASetting,
    updateAutoQASetting,
    createAutoQASetting
} from 'gridUI/actions';
import AutoQASettingList from './AutoQASettingList';
import NewAutoQASetting from './NewAutoQASetting';
import { useAutoQASettings, useListTB } from 'hooks/gridUI';
import SettingPopup from './SettingPopup';
import { POPUP_PADDING_TOP_BOTTOM, POPUP_RADIUS } from 'const/style';
import * as workspaceActions from 'workspaces/actions';
import { useIsFetchedWorkspaces, useWorkspaceList } from 'hooks/workspace';
import Spinner from 'components/spinner/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    editor: {
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    }
}));

const SETTING_PAGES = {
    LIST: 'LIST',
    NEW: 'NEW',
    EDIT: 'EDIT'
};

const AutoQASetting = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const autoQASettings = useAutoQASettings();
    const glossaryList = useListTB();
    const isFetchedWorkspaces = useIsFetchedWorkspaces();
    const projects = useWorkspaceList();
    const [currentPage, setCurrentPage] = React.useState(SETTING_PAGES.LIST);
    const [selectedSetting, setSelectedSetting] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isFetching, setIsFetching] = React.useState(false);
    const [isDoing, setIsDoing] = React.useState(false);

    React.useEffect(() => {
        setIsFetching(true);
        dispatch(
            getAutoQASettings({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        setIsFetching(true);
        dispatch(
            fetchListTermsBase({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        if (isFetchedWorkspaces) return;
        dispatch(workspaceActions.fetchWorkspaces({}));
    }, [dispatch, isFetchedWorkspaces]);

    const handleClickMoreAction = (event, setting) => {
        setSelectedSetting(setting);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClickAway = () => setAnchorEl(null);

    const handleDeleteSetting = () => {
        dispatch(deleteAutoQASetting({ settingId: selectedSetting.id }));
        handleClickAway();
    };

    const handleCreateSetting = body => {
        setIsDoing(true);
        dispatch(
            createAutoQASetting({
                body,
                successCallback: () => {
                    setIsDoing(false);
                    setCurrentPage(SETTING_PAGES.LIST);
                },
                errorCallback: () => {
                    setIsDoing(false);
                }
            })
        );
    };

    const handleUpdateSetting = body => {
        setIsDoing(true);
        dispatch(
            updateAutoQASetting({
                settingId: selectedSetting.id,
                body,
                successCallback: () => {
                    setIsDoing(false);
                    setCurrentPage(SETTING_PAGES.LIST);
                },
                errorCallback: () => {
                    setIsDoing(false);
                }
            })
        );
    };

    const handleChangeProject = (settingId, projectId) => {
        setIsDoing(true);
        const body = { projectId };
        dispatch(
            updateAutoQASetting({
                settingId,
                body,
                successCallback: () => {
                    setIsDoing(false);
                },
                errorCallback: () => {
                    setIsDoing(false);
                }
            })
        );
    };

    return (
        <SettingContentLayout name={t('auto_qa_by_lexiqa')}>
            {!isFetching && (
                <Grid container direction="column" className={classes.root}>
                    {currentPage === SETTING_PAGES.LIST && (
                        <Grid item style={{ marginLeft: 'auto', marginBottom: 24 }}>
                            <ButtonBase
                                width={200}
                                variant="outlined"
                                disabled={currentPage === SETTING_PAGES.NEW}
                                onClick={() => setCurrentPage(SETTING_PAGES.NEW)}
                            >
                                + Create New Setting
                            </ButtonBase>
                        </Grid>
                    )}
                    <Grid item>
                        {currentPage === SETTING_PAGES.LIST && (
                            <AutoQASettingList
                                autoQASettings={autoQASettings}
                                projects={projects}
                                onClickMore={handleClickMoreAction}
                                onChangeProject={handleChangeProject}
                            />
                        )}
                        {currentPage === SETTING_PAGES.NEW && (
                            <NewAutoQASetting
                                glossaryList={glossaryList}
                                onCreateSetting={handleCreateSetting}
                                isNew
                                onBack={() => setCurrentPage(SETTING_PAGES.LIST)}
                                isDoing={isDoing}
                            />
                        )}
                        {currentPage === SETTING_PAGES.EDIT && (
                            <NewAutoQASetting
                                selectedSetting={selectedSetting}
                                glossaryList={glossaryList}
                                onUpdateSetting={handleUpdateSetting}
                                onBack={() => setCurrentPage(SETTING_PAGES.LIST)}
                                isDoing={isDoing}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
            {isFetching && (
                <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
                    <Spinner size={30} thick={4} />
                </Grid>
            )}
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom"
                className={classes.editor}
                modifiers={{ preventOverflow: { escapeWithReference: true } }}
            >
                <SettingPopup
                    handleClickAway={handleClickAway}
                    setting={selectedSetting}
                    onDeleteSetting={handleDeleteSetting}
                    onClickEdit={() => setCurrentPage(SETTING_PAGES.EDIT)}
                    onChangeName={name => handleUpdateSetting({ name })}
                />
            </Popper>
        </SettingContentLayout>
    );
};

export default React.memo(AutoQASetting);
