import store from 'store/configStore';
import * as types from 'socket/types';
import * as dataActions from 'gridUI/actions/data';
import * as gridUIActions from 'gridUI/actions';
import { generateSystemData, combinedData } from 'utils/gridUI/data';
import * as columnTypes from 'const/columnTypes';
import { getColumnsRecordsApi, getViewRecordsApiV2 } from 'services/view';
import { removeArrayInArray } from 'utils/object';
import { DATA_QUERY_OPTIONS, RECORDS_OFFSET_BOTTOM, RECORDS_OFFSET_TOP, RECORDS_RENDER } from 'const/gridUI';
import { getDependencyColumnIds } from 'utils/gridUI/dependency';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { _deleteAllRecords, _updateClearingRecords } from 'gridUI/actions';
import { enqueueSnackbar } from 'notifier/actions';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

const { dispatch, getState } = store;

const recordHandler = ({ body, isYour }) => {
    const { subAction, data, user } = body;
    switch (subAction) {
        case types.UPDATE:
            return _updateRecord({ data, user });
        case types.CREATE:
            return _createRecord({ data, user });
        case types.DELETE:
            return _newDeleteRecords({ data, user });

        case types.POPULATED_DATA:
            return _populatedData({ data, user });
        case types.REORDER_RECORDS:
            return _updateReordersRecord({ data, user });
        case types.UPDATE_PARENT_PATH_END:
            return _updateParentPathEnd({ data, user, isYour });

        case types.CLEAR_RECORDS_START:
            return _clearRecordStart({ body, isYour });
        case types.CLEAR_RECORDS_END:
            return _clearRecordEnd({ body, isYour });

        case types.UNDO_UPDATE:
        case types.REDO_UPDATE:
            return _undoRedoUpdateRecord({ data, isYour });

        case types.UNDO_CREATE:
            return _undoCreateRecord({ data, user, isYour });
        case types.REDO_CREATE:
            return _undoDeleteRecord({ data, user, isYour });

        case types.UNDO_DELETE:
            return _undoDeleteRecord({ data, isYour, user });
        case types.REDO_DELETE:
            return _redoDeleteRecord({ data, isYour, user });
        case types.UNDO_REORDER:
        case types.REDO_REORDER:
            return _undoDeleteRecord({ data, isYour });
        case types.AUTO_QA:
            return _updateAutoQARecord({ data, isYour });
        default:
            return;
    }
};

async function _undoRedoUpdateRecord({ data, isYour }) {
    const { gridUI } = getState();
    const { metaData } = gridUI;

    const newData = data?.data;

    const columnIds = data?.columnIds;
    const recordIds = data?.recordIds;

    const refColumnIds = columnIds?.filter(colId => {
        const column = metaData?.[colId];
        return column?.type === columnTypes.REFERENCE;
    });

    if (refColumnIds?.length) {
        dispatch(
            gridUIActions.fetchOtherReferenceDataColumns({
                refColumnIds,
                recordIds
            })
        );
    }

    dispatch(gridUIActions.updateData({ newData, isCareData: true }));
}

async function _undoCreateRecord({ data, isYour, user }) {
    _deleteRecords({
        data: {
            ids: data
        }
    });
}

async function _undoDeleteRecord({ body, isYour }) {
    const { gridUI } = getState();
    const { tableInfo, defaultAccessViewId, dbId, quickFilters, dependencies, quickSorts, viewColumns } = gridUI;

    const { gridRef } = tableInfo;
    const { _rowStartIndex, _rowStopIndex } = gridRef;
    const quickFiltersFormatted = formatQuickFilters(quickFilters);
    const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
    const isShowAutoQA = getIsShowAutoQA();

    try {
        dispatch(gridUIActions._fetchMoreRowsAction());

        dispatch(gridUIActions._toggleDeleteRecordState());

        const {
            recordIds,
            columnIds,
            recordMetaData,
            data: newSectionData,
            totalRecords,
            totalRecordsWithoutFilters
        } = await getViewRecordsApiV2({
            defaultAccessViewId,
            dbId,
            offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
            limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
            filterQuery: quickFiltersFormatted,
            sortQuery: quickSorts,
            columnIds: dependencyColumnIds,
            isShowAutoQA
        });

        dispatch(
            gridUIActions._fetchMoreRowsActionSuccess({
                columns: columnIds,
                rows: recordIds,
                data: newSectionData,
                totalRecords: totalRecords,
                ROW_START_INDEX: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                ROW_STOP_INDEX: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                totalRecordsWithoutFilters
            })
        );
        dispatch(
            gridUIActions.updateRecordMetaData({
                newRecordMetaData: recordMetaData
            })
        );

        dispatch(gridUIActions._toggleDeleteRecordState());

        dispatch(gridUIActions.removeProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
    } catch (err) {
        dispatch(gridUIActions.removeProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
    }
}

async function _redoDeleteRecord({ data, isYour, user }) {
    _newDeleteRecords({ data, user });
}

async function _clearRecordStart({ body, isYour }) {
    const { gridUI } = getState();
    const viewColumns = gridUI?.viewColumns;
    dispatch(gridUIActions.addProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
    dispatch(_updateClearingRecords({ clearingRecords: { ...body, user: isYour ? {} : body.user } }));
}

async function _clearRecordEnd({ body, isYour }) {
    dispatch(_updateClearingRecords({ clearingRecords: {} }));
    if (isYour) {
        if (body?.status === 'FAILED') {
            dispatch(
                enqueueSnackbar({
                    message: 'Delete failed. One of your columns might be referenced by other grids?',
                    type: 'info'
                })
            );
        } else {
            dispatch(_deleteAllRecords());
        }
    }
    const { gridUI } = getState();
    const { tableInfo, defaultAccessViewId, dbId, quickFilters, dependencies, quickSorts, viewColumns } = gridUI;

    const { gridRef } = tableInfo;
    const { _rowStartIndex, _rowStopIndex } = gridRef;
    const quickFiltersFormatted = formatQuickFilters(quickFilters);
    const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });
    const isShowAutoQA = getIsShowAutoQA();

    try {
        dispatch(gridUIActions._fetchMoreRowsAction());

        dispatch(gridUIActions._toggleDeleteRecordState());

        const {
            recordIds,
            columnIds,
            recordMetaData,
            data: newSectionData,
            totalRecords,
            totalRecordsWithoutFilters
        } = await getViewRecordsApiV2({
            defaultAccessViewId,
            dbId,
            offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
            limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
            filterQuery: quickFiltersFormatted,
            sortQuery: quickSorts,
            columnIds: dependencyColumnIds,
            isShowAutoQA
        });

        dispatch(
            gridUIActions._fetchMoreRowsActionSuccess({
                columns: columnIds,
                rows: recordIds,
                data: newSectionData,
                totalRecords: totalRecords,
                ROW_START_INDEX: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                ROW_STOP_INDEX: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                totalRecordsWithoutFilters
            })
        );
        dispatch(
            gridUIActions.updateRecordMetaData({
                newRecordMetaData: recordMetaData
            })
        );

        dispatch(gridUIActions._toggleDeleteRecordState());

        dispatch(gridUIActions.removeProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
    } catch (err) {
        dispatch(gridUIActions.removeProcessingColumns({ columnIds: viewColumns?.map(col => col?.id) }));
    }
}

async function _updateParentPathEnd({ data, user, isYour }) {
    const { gridUI } = getState();
    const { dbId, defaultAccessViewId, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;
    if (isYour) {
        const { data } = await getColumnsRecordsApi({
            dbId,
            defaultAccessViewId,
            offset: ROW_START_INDEX,
            limit: ROW_STOP_INDEX,
            columnIds: [columnTypes.PATH_TAG],
            dataOptions: [DATA_QUERY_OPTIONS.DATA]
        });
        dispatch(dataActions.updateData({ newData: data, isCareData: true }));
    } else {
        //refresh pathtag tree and pathtag column
        dispatch(
            gridUIActions.fetchTags({
                successCallback: () => {
                    console.log('success moved path');
                },
                errorCallback: error => {
                    console.log('error', error);
                }
            })
        );
        const { data } = await getColumnsRecordsApi({
            dbId,
            defaultAccessViewId,
            offset: ROW_START_INDEX,
            limit: ROW_STOP_INDEX,
            columnIds: [columnTypes.PATH_TAG],
            dataOptions: [DATA_QUERY_OPTIONS.DATA]
        });
        dispatch(dataActions.updateData({ newData: data, isCareData: true }));
    }
}

function _updateAutoQARecord({ data }) {
    dispatch(dataActions.updateData({ newData: data?.data, isCareData: true }));
}

function _populatedData({ data }) {
    dispatch(dataActions.updateData({ newData: data?.data, isCareData: true }));
}

function _updateReordersRecord({ data }) {
    const beforeRecordId = data?.beforeRecordId;
    const afterRecordId = data?.afterRecordId;
    const reorderRecordIds = data?.reorderRecordIds || [];

    dispatch(
        gridUIActions.reorderRecordsSocket({
            beforeRecordId,
            afterRecordId,
            recordIds: reorderRecordIds
        })
    );
}

function _newDeleteRecords({ data, user }) {
    const type = data?.type;
    if (type === 'BULK_DELETE') {
        return _deleteBulkRecords({ data });
    }

    _deleteRecords({ data });
}

function _deleteRecords({ data }) {
    const { ids = [] } = data;
    const { gridUI } = getState();
    const { rows } = gridUI;

    if (!ids?.length) return;

    dispatch(dataActions.deleteRecordsData(ids));
    dispatch(
        gridUIActions.deleteViewRecordsAction({
            recordIds: rows?.filter(recordId => !ids?.includes(recordId)),
            deletedCount: ids?.length
        })
    );
}

async function _deleteBulkRecords({ data }) {
    const { ids = [] } = data;
    const { gridUI } = getState();
    const { rows, tableInfo, defaultAccessViewId, dbId, quickFilters, quickSorts, dependencies, viewColumns } = gridUI;

    if (!ids?.length) return;

    const { gridRef } = tableInfo;
    const { _rowStartIndex, _rowStopIndex } = gridRef;
    const remainingRecordIds = removeArrayInArray(rows, ids);
    const quickFiltersFormatted = formatQuickFilters(quickFilters);
    const dependencyColumnIds = getDependencyColumnIds({ dependencies, viewColumns });

    dispatch(gridUIActions._deleteRecordByIndexes({ remainingRecordIds, count: ids?.length }));
    dispatch(gridUIActions._fetchMoreRowsAction());
    dispatch(gridUIActions._toggleDeleteRecordState());

    const isShowAutoQA = getIsShowAutoQA();

    try {
        const {
            recordIds,
            columnIds,
            recordMetaData,
            data: newSectionData,
            totalRecords,
            totalRecordsWithoutFilters
        } = await getViewRecordsApiV2({
            defaultAccessViewId,
            dbId,
            offset: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
            limit: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
            filterQuery: quickFiltersFormatted,
            sortQuery: quickSorts,
            columnIds: dependencyColumnIds,
            isShowAutoQA
        });

        dispatch(
            gridUIActions._fetchMoreRowsActionSuccess({
                columns: columnIds,
                rows: recordIds,
                data: newSectionData,
                totalRecords: totalRecords,
                ROW_START_INDEX: Math.max(_rowStartIndex - RECORDS_OFFSET_TOP, 0),
                ROW_STOP_INDEX: Math.max(_rowStopIndex + RECORDS_OFFSET_BOTTOM, RECORDS_RENDER),
                totalRecordsWithoutFilters
            })
        );
        dispatch(
            gridUIActions.updateRecordMetaData({
                newRecordMetaData: recordMetaData
            })
        );

        dispatch(gridUIActions._toggleDeleteRecordState());
    } catch ({ message }) {
        dispatch(
            enqueueSnackbar({
                message,
                type: 'info'
            })
        );
    }
}

function _updateRecord({ data, user = {} }) {
    if (!data) return;

    const columnIds = data?.columnIds || [];
    const recordIds = data?.recordIds || [];

    const recordMetaDataIndex = columnIds.findIndex(columnId => columnId === '_metadata');
    if (recordMetaDataIndex !== -1) {
        const recordMetaData = data?.data;
        dispatch(
            gridUIActions.updateRecordMetaData({
                newRecordMetaData: recordMetaData
            })
        );
    } else {
        const { gridUI } = getState();
        const { viewColumns } = gridUI;

        const systemData = generateSystemData({
            viewColumns,
            recordIds,
            editedUser: user,
            columnIds
        });

        const newData = combinedData({ data: systemData, newData: data?.data, isCareData: true });

        dispatch(dataActions.updateData({ newData, isCareData: true }));
    }
}

function _createRecord({ data, user }) {
    if (!data) return;

    const columnIds = data?.columnIds || [];
    const recordIds = data?.recordIds || [];

    const { gridUI } = getState();
    const { viewColumns, quickFilters } = gridUI;

    const pathTagQuickFilter = quickFilters?.[columnTypes?.PATH_TAG];

    const systemData = generateSystemData({
        viewColumns,
        recordIds,
        editedUser: user,
        columnIds,
        isCreate: true
    });

    const newData = combinedData({ data: systemData, newData: data?.data, isCareData: true });
    dispatch(dataActions.updateData({ newData, isCareData: true }));

    if (pathTagQuickFilter?.value) {
        const possibleRecords = recordIds?.filter(recordId => {
            const pathTagData = data?.data?.[recordId]?.[columnTypes?.PATH_TAG]?.value;
            return pathTagData === pathTagQuickFilter?.value;
        });

        dispatch(gridUIActions.createRowsSocket({ recordIds: possibleRecords }));
    } else {
        dispatch(gridUIActions.createRowsSocket({ recordIds }));
    }
}

export default recordHandler;
