import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useTheme } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    firstBar: {
        background: hexToRgba(theme.colors.atlantis, 0.8),
        width: props => `${props.upToDatePercent}%`,
        minWidth: 14,
        height: 14,
        textAlign: 'center'
    },
    secondBar: {
        background: hexToRgba(theme.colors.sun, 0.8),
        width: props => `${props.outOfDatePercent}%`,
        minWidth: 14,
        height: 14,
        textAlign: 'center'
    },
    thirdBar: {
        background: hexToRgba(theme.colors.brightRed, 0.8),
        width: props => `${props.unSetPercent}%`,
        minWidth: 14,
        height: 14,
        textAlign: 'center'
    },
    bar: {
        position: 'relative'
    },
    overlayBar: {
        background: theme.colors.paleGrey,
        width: '100%',
        height: 14,
        borderRadius: 4,
        position: 'absolute',
        zIndex: 0,
        display: 'flex',
        '& div:first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
        },
        '& div:last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
        }
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    text: {
        color: theme.colors.white,
        lineHeight: '14px',
        fontSize: 10,
        fontWeight: 700
    }
}));

function TranslationProgress({ name, upToDate, outOfDate, unSet, showPercentage }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const total = upToDate + outOfDate + unSet;
    const upToDatePercent = total === 0 ? 0 : Math.round((upToDate / total) * 100 * 100) / 100;
    const outOfDatePercent = total === 0 ? 0 : Math.round((outOfDate / total) * 100 * 100) / 100;
    const unSetPercent = total === 0 ? 0 : Math.round((unSet / total) * 100 * 100) / 100;
    const classes = useStyles({ upToDatePercent, outOfDatePercent, unSetPercent });

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid className={classes.spacing2} item container justify="space-between">
                <Grid item>
                    <p className="body2 inline">{name}</p>
                </Grid>
                {outOfDate + unSet === 0 ? (
                    <Grid item>
                        <CheckIconSVG color={hexToRgba(theme.colors.atlantis, 0.8)} />
                    </Grid>
                ) : (
                    <Grid item>
                        <p className="body2 inline">
                            {showPercentage ? (outOfDatePercent + unSetPercent).toFixed(2) + '%' : outOfDate + unSet}{' '}
                        </p>
                        <p className="caption inline">{t('not_completed')}</p>
                    </Grid>
                )}
            </Grid>
            <Grid item container className={classes.bar}>
                <Grid item className={classes.overlayBar}>
                    {upToDate > 0 && (
                        <Grid item className={classes.firstBar}>
                            <p className="body2 text-[10px] text-white font-bold">
                                {showPercentage ? upToDatePercent + '%' : upToDate}
                            </p>
                        </Grid>
                    )}
                    {outOfDate > 0 && (
                        <Grid item className={classes.secondBar}>
                            <p className={'body2 text-[10px] text-white font-bold'}>
                                {showPercentage ? outOfDatePercent + '%' : outOfDate}
                            </p>
                        </Grid>
                    )}
                    {unSet > 0 && (
                        <Grid item className={classes.thirdBar}>
                            <p className="body2 text-[10px] text-white font-bold">
                                {showPercentage ? unSetPercent + '%' : unSet}
                            </p>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(TranslationProgress);
