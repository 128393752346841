import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import DeleteSVG from 'assets/images/svg/DeleteSVG';
import EditSVG from 'assets/images/svg/EditSVG';

import ListItem from 'components/list/Item';

const useStyles = makeStyles(theme => ({
    menuDropdown: {
        minWidth: 120
    }
}));

function MenuDropDown({ editHandler, deleteHandler, isThread, t }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.menuDropdown} direction="column">
            <Grid item onClick={editHandler}>
                <ListItem icon={<EditSVG />} name={t(`global_edit`)} />
            </Grid>
            {!isThread && (
                <Grid item onClick={deleteHandler}>
                    <ListItem icon={<DeleteSVG />} name={t(`global_delete`)} />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(MenuDropDown);
