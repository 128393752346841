import React from 'react';
import { AutoSizer } from 'react-virtualized-dn';
import { IconButton } from '@material-ui/core';
import {
    GLOBAL_FILTER_HEIGHT,
    RECORD_PANEL_WIDTH,
    DEFAULT_RECORD_HISTORY_HEIGHT,
    MAX_EXTEND_VIEW_HEIGHT,
    MIN_EXTEND_VIEW_HEIGHT,
    EXTEND_VIEW_TYPES
} from 'const/gridUI';
import { HEADER_HEIGHT, USER_SETTINGS, RESOURCE_TYPES } from 'const';
import {
    useIsOpenRecordHistory,
    useIsTurnOnRecordPanel,
    useIsOpenIgnoreSimilarErrorsConfirm,
    useIgnoreSimilarErrorsSelectedValidation
} from 'hooks/gridUI';
import RecordPanel from '../recordPanel';
import GridUI from './grid';
import BugIconSVG from 'assets/images/svg/BugIconSVG';
import { useAutoQAErrorTypes, useIsShowAutoQA, useShowErrorControlPanel } from 'hooks/gridUI/autoQA';
import IOSSwitch from 'components/switches/IOS';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useDispatch } from 'react-redux';
import {
    toggleErrorType,
    toggleAllErrorType,
    openErrorControlPanel,
    closeErrorControlPanel,
    ignoreAutoQASimilarErrors
} from 'gridUI/actions';
import ExtendView from '../extendViews';
import { useGridSettingByProperty, useUserGridSettings } from 'hooks/workspace';
import useRefDimensions from 'hooks/useRefDimensions';
import { useWindowSize } from 'hooks/useWindowSize';
import * as workspaceActions from 'workspaces/actions';
import * as gridUIActions from 'gridUI/actions';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import SelectionFilePreview from './grid/cellOverlay/SelectionFilePreview';

function GridUIWrapper({ dbId, branchId, viewId, gridId, workspaceId, isShareViewLink, t }) {
    const dispatch = useDispatch();
    const [isResizing, setIsResizing] = React.useState(false);
    const isOpenIgnoreSimilarErrorsConfirm = useIsOpenIgnoreSimilarErrorsConfirm();
    const selectedValidation = useIgnoreSimilarErrorsSelectedValidation();
    const [isLoading, setIsLoading] = React.useState(false);

    const handleCloseDialog = () => {
        dispatch(gridUIActions.closeIgnoreSimilarErrorsConfirm());
    };

    const handleIgnoreSimilarErrors = () => {
        setIsLoading(true);
        dispatch(
            ignoreAutoQASimilarErrors({
                error: selectedValidation,
                rowId: selectedValidation.rowId,
                columnId: selectedValidation.columnId,
                successCallback: () => {
                    handleCloseDialog();
                    setIsLoading(false);
                },
                errorCallback: () => {
                    handleCloseDialog();
                    setIsLoading(false);
                }
            })
        );
    };

    const recordHistoryHeight =
        useGridSettingByProperty({
            dbId,
            gridId: branchId,
            wsId: workspaceId,
            property: USER_SETTINGS.GRID.RECORD_HISTORY_HEIGHT
        }) || DEFAULT_RECORD_HISTORY_HEIGHT;

    const ref = React.useRef();
    const lastResizeRef = React.useRef();
    const openExtendViewRef = React.useRef();

    const { height: refHeight } = useRefDimensions(ref);
    const windowSize = useWindowSize();
    const userGridSetting = useUserGridSettings({ wsId: workspaceId, dbId, gridId: branchId });

    const RECORD_HISTORY = React.useMemo(() => `${(recordHistoryHeight * refHeight) / 100}`, [
        recordHistoryHeight,
        refHeight
    ]);

    const openExtendView = useGridSettingByProperty({
        dbId,
        wsId: workspaceId,
        gridId: branchId,
        property: USER_SETTINGS.GRID.OPEN_EXTEND_VIEW
    });

    const extendViewType = useGridSettingByProperty({
        dbId,
        wsId: workspaceId,
        gridId: branchId,
        property: USER_SETTINGS.GRID.EXTEND_VIEW_TYPE
    });

    React.useEffect(() => {
        if (openExtendViewRef.current || isShareViewLink) return;
        openExtendViewRef.current = true;
        if (openExtendView) {
            dispatch(
                gridUIActions.openRecordHistory({
                    type: extendViewType || EXTEND_VIEW_TYPES.EDITOR
                })
            );
        }
    }, [dispatch, openExtendView, extendViewType, isShareViewLink]);

    const isOpenRecordHistory = useIsOpenRecordHistory();

    const openRecordHistory = React.useMemo(() => {
        return isOpenRecordHistory;
    }, [isOpenRecordHistory]);

    const isTurnOnRecordPanel = useIsTurnOnRecordPanel();
    const autoQAErrorTypes = useAutoQAErrorTypes();
    const isShowAutoQA = useIsShowAutoQA();
    const showErrorControlPanel = useShowErrorControlPanel();
    const isAllActive = autoQAErrorTypes.every(t => t.isActive);

    React.useEffect(() => {
        let gridTable = document.getElementById(`gridUI-table`);
        if (gridTable) {
            gridTable.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            });
        }
    }, []);

    const recordPanelHeight = React.useMemo(() => {
        return openRecordHistory
            ? `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT - RECORD_HISTORY}px)`
            : `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT}px)`;
    }, [openRecordHistory, RECORD_HISTORY]);

    const handleToggleErrorType = errorType => {
        dispatch(toggleErrorType(errorType));
    };

    const handleToggleAllErrorType = currentState => {
        dispatch(toggleAllErrorType(currentState));
    };

    const EXTRA_HEADER = React.useMemo(() => {
        return windowSize?.height - refHeight;
    }, [refHeight, windowSize]);

    const startResize = React.useCallback(
        e => {
            setIsResizing(true);
            let resizeHandler = document.getElementById('extendViewResizeHighlight');

            if (resizeHandler) {
                const top = e?.clientY;
                resizeHandler.style.top = `${top - EXTRA_HEADER}px`;
            }
        },
        [EXTRA_HEADER]
    );

    const minTop = React.useMemo(() => {
        return (refHeight * (100 - MAX_EXTEND_VIEW_HEIGHT)) / 100;
    }, [refHeight]);

    const maxTop = React.useMemo(() => {
        return (refHeight * (100 - MIN_EXTEND_VIEW_HEIGHT)) / 100;
    }, [refHeight]);

    const draggingResize = React.useCallback(
        e => {
            if (!isResizing) return;
            let resizeHandler = document.getElementById('extendViewResizeHighlight');

            if (resizeHandler) {
                let top = e?.clientY - EXTRA_HEADER;

                if (top >= maxTop) {
                    top = maxTop;
                }

                if (top <= minTop) {
                    top = minTop;
                }

                const newExtendViewHeight = (top / refHeight) * 100;
                lastResizeRef.current = newExtendViewHeight;

                resizeHandler.style.top = `${Math.abs(top)}px`;
            }
        },
        [isResizing, maxTop, minTop, EXTRA_HEADER, refHeight]
    );

    const stopResize = React.useCallback(
        e => {
            setIsResizing(false);
            let resizeHandler = document.getElementById('extendViewResizeHighlight');
            if (resizeHandler) {
                resizeHandler.style.top = `-10000px`;
            }

            if (lastResizeRef.current) {
                dispatch(
                    workspaceActions.setUserSettings({
                        dbId,
                        workspaceId,
                        resourceId: branchId,
                        resourceType: RESOURCE_TYPES.GRID,
                        value: {
                            ...userGridSetting,
                            [USER_SETTINGS.GRID.RECORD_HISTORY_HEIGHT]: 100 - lastResizeRef.current
                        }
                    })
                );

                lastResizeRef.current = null;
            }
        },
        [userGridSetting, branchId, workspaceId, dbId, dispatch]
    );

    const mouseUpHandler = React.useCallback(
        e => {
            if (!isResizing) return;
            if (isResizing) {
                stopResize(e);
            }
        },
        [isResizing, stopResize]
    );

    const mouseMoveHandler = React.useCallback(
        e => {
            if (!isResizing) return;

            if (isResizing) {
                draggingResize(e);
            }
        },
        [isResizing, draggingResize]
    );

    return (
        <div
            className="h-full flex flex-col relative"
            ref={ref}
            onMouseUp={mouseUpHandler}
            onMouseMove={mouseMoveHandler}
        >
            <div
                id={`gridUI-table`}
                className="select-none flex flex-nowrap"
                style={{
                    height: openRecordHistory
                        ? `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT}px - ${RECORD_HISTORY}px)`
                        : `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT}px)`
                }}
            >
                <div className="flex-1" id={'gridUI-only'}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <GridUI
                                    width={width}
                                    height={height}
                                    dbId={dbId}
                                    branchId={branchId}
                                    viewId={viewId}
                                    gridId={gridId}
                                    workspaceId={workspaceId}
                                    isShareViewLink={isShareViewLink}
                                    t={t}
                                />
                            );
                        }}
                    </AutoSizer>
                </div>
                {isTurnOnRecordPanel && (
                    <div style={{ width: RECORD_PANEL_WIDTH, height: recordPanelHeight, zIndex: 100 }}>
                        <RecordPanel dbId={dbId} />
                    </div>
                )}
                {isShowAutoQA && !showErrorControlPanel && (
                    <IconButton
                        className={`z-[999] !absolute bottom-[46px] right-2.5 !bg-white shadow w-[60px] h-[60px]`}
                        onClick={() => dispatch(openErrorControlPanel())}
                    >
                        <BugIconSVG />
                    </IconButton>
                )}

                {isShowAutoQA && showErrorControlPanel && (
                    <div
                        className={`flex flex-col flex-nowrap z-[999] absolute bottom-[46px] right-2.5 bg-white shadow max-w-[214px] h-[333px] rounded`}
                    >
                        <div item container className={'flex items-center justify-between flex-row px-3.5 py-2'}>
                            <p className="body1">Showing error</p>
                            <IconButton size="small" onClick={() => dispatch(closeErrorControlPanel())}>
                                <CloseIconSVG size="small" />
                            </IconButton>
                        </div>

                        <div className={'flex flex-col flex-nowrap overflow-auto py-0 px-3.5'}>
                            <div className={'flex flex-row items-center p-2 gap-2'}>
                                <IOSSwitch
                                    checked={isAllActive}
                                    onChange={() => handleToggleAllErrorType(isAllActive)}
                                    color="#77B32B"
                                />
                                <p className="body2">All</p>
                            </div>
                            {autoQAErrorTypes.map(errorType => (
                                <div key={errorType.value} className={'flex items-center flex-row p-2 gap-2'}>
                                    <IOSSwitch
                                        checked={errorType.isActive}
                                        onChange={() => handleToggleErrorType(errorType)}
                                        color={errorType.color}
                                    />
                                    <p className="body2">{errorType.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <Dialog open={isOpenIgnoreSimilarErrorsConfirm} onClose={handleCloseDialog}>
                    <ConfirmBox
                        title="Ignore similar errors"
                        body={
                            <>
                                <p className="body2">This type of error will not be shown again.</p>
                                <p className="body2">Please proceed with caution.</p>
                            </>
                        }
                        handleCancel={handleCloseDialog}
                        onClose={handleCloseDialog}
                        handleAgreed={handleIgnoreSimilarErrors}
                        agreeLabel="Ignore anyway"
                        agreeWidth={130}
                        isLoading={isLoading}
                    />
                </Dialog>
            </div>

            {openRecordHistory && (
                <div
                    style={{
                        userSelect: isResizing ? 'none' : 'auto',
                        height: RECORD_HISTORY
                    }}
                    className="flex-1"
                >
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <ExtendView
                                    workspaceId={workspaceId}
                                    gridId={gridId}
                                    viewId={viewId}
                                    dbId={dbId}
                                    branchId={branchId}
                                    width={width}
                                    height={height}
                                    fixedColumnCount={0}
                                    fixedRowCount={1}
                                    t={t}
                                    isShareViewLink={isShareViewLink}
                                    onStartResize={startResize}
                                    maxHeight={RECORD_HISTORY - 48 - 24}
                                />
                            );
                        }}
                    </AutoSizer>
                </div>
            )}

            <div
                className={'absolute -top-[10000px] left-0 h-[3px] w-full z-20 opacity-100 bg-resize'}
                id="extendViewResizeHighlight"
            ></div>
            <SelectionFilePreview />
        </div>
    );
}

export default React.memo(GridUIWrapper);
