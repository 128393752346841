import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const ColorLinearProgress = withStyles({
    root: {
        height: 6,
        borderRadius: 4
    },
    colorPrimary: {
        backgroundColor: props => props?.background || '#89C250'
    },
    barColorPrimary: {
        backgroundColor: props => props?.foreground || '#E7F6DB'
    }
})(LinearProgress);

export default ColorLinearProgress;
