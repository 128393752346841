import * as types from '../types';

const handler = {
    [types.FETCH_PUBLIC_KEY](state, { payload }) {
        const { publicKey } = payload;
        return {
            ...state,
            publicKey
        };
    }
};

export default handler;
