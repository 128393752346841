import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { getFriendlyTime } from 'utils/datetime';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { padding: 32 },
    closeIcon: {
        height: 24,
        width: 24,
        '&:hover:': {
            backgroundColor: theme.colors.hoverItem
        }
    },
    marginTop: {
        marginTop: 24
    }
}));

function RestoreConfirmModal({ dbName, backupTime, onClose, onAccept }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h4">{t(`database_restore_this_version`)}?</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIconSVG className={classes.closeIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: 24 }} className={classes.marginTop}>
                <Trans
                    t={t}
                    defaults={
                        '<bold>{{dbName}}</bold> <normal>will be reverted to the version from</normal> <bold>{{time}}</bold>'
                    }
                    components={{
                        bold: <Typography variant="body1" display="inline" />,
                        normal: <Typography variant="body2" display="inline" />
                    }}
                    values={{ dbName: dbName, time: getFriendlyTime(backupTime) }}
                />
            </Grid>
            <Grid item style={{ marginTop: 39 }}>
                <Grid container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" size="small" width={100} onClick={onClose}>
                            {t(`global_cancel`)}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" width={100} onClick={onAccept}>
                            {t(`global_restore`)}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(RestoreConfirmModal);
