import React from 'react';

function NoAccessSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="235" height="184" viewBox="0 0 235 184" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M172.932 50.9759C172.505 50.8804 169.944 50.3077 169.186 49.0667C168.617 48.1121 167.953 46.1551 167.811 45.7256V45.6778V45.7256C167.668 46.1551 167.004 48.1598 166.435 49.0667C165.677 50.3077 163.116 50.8804 162.689 50.9759C163.116 51.0713 165.677 51.6918 166.435 52.8851C167.004 53.8397 167.668 55.7966 167.811 56.2262V56.2739V56.2262C167.953 55.7966 168.617 53.792 169.186 52.8851C169.992 51.6918 172.552 51.0713 172.932 50.9759Z"
                fill="#5E55A4"
            />
            <path
                d="M234.952 120.853C233.293 55.4625 165.059 71.6907 151.593 64.913C137.368 57.7535 126.841 27.6358 84.5923 27.6358C43.197 27.6358 9.57812 59.4718 9.57812 98.8015C9.57812 138.131 39.5933 169.872 81.036 169.872C106.594 169.872 111.051 162.855 126.794 162.855C144.433 162.855 149.175 179.179 180.944 179.179C211.86 179.179 235.806 153.071 234.952 120.853Z"
                fill="#5E55A4"
            />
            <path
                d="M217.408 172.115C216.507 171.924 210.769 170.588 209.062 167.819C207.782 165.719 206.312 161.28 205.98 160.326V160.23V160.278C205.98 160.278 205.98 160.278 205.98 160.23V160.278C205.648 161.233 204.178 165.671 202.898 167.772C201.191 170.54 195.454 171.876 194.553 172.067H194.6C195.501 172.258 201.239 173.595 202.946 176.363C204.226 178.463 205.696 182.902 206.028 183.857V183.952V183.905C206.028 183.905 206.028 183.905 206.028 183.952V183.905C206.312 182.95 207.83 178.511 209.11 176.411C210.769 173.69 216.507 172.306 217.408 172.115Z"
                fill="#F65BA1"
            />
            <path
                d="M20.3894 49.0667C19.5833 48.8757 14.4623 47.6825 12.9449 45.2482C11.8069 43.3868 10.4792 39.3774 10.1947 38.566V38.4706V38.5183C10.1947 38.5183 10.1947 38.5183 10.1947 38.4706V38.5183C9.91022 39.3774 8.58253 43.339 7.44452 45.2005C5.92716 47.6825 0.806094 48.8757 0 49.0667C0.806094 49.2576 5.92716 50.4508 7.44452 52.9328C8.58253 54.7943 9.91022 58.8036 10.1947 59.615V59.7105V59.6628V59.615C10.4792 58.7559 11.8069 54.7943 12.9449 52.9328C14.5097 50.4508 19.6308 49.2576 20.3894 49.0667Z"
                fill="#17C0FF"
            />
            <path
                d="M65.7683 115.125C64.7726 114.934 58.6083 113.455 56.759 110.448C55.3839 108.157 53.7717 103.384 53.4398 102.334V102.238V102.286C53.4398 102.286 53.4398 102.286 53.4398 102.238V102.286C53.1079 103.288 51.4957 108.109 50.1206 110.4C48.2713 113.407 42.0597 114.839 41.1113 115.078H41.1587C42.1545 115.316 48.3188 116.748 50.168 119.755C51.5431 122.046 53.1553 126.819 53.4872 127.869V127.965V127.917C53.4872 127.917 53.4872 127.917 53.4872 127.965V127.917C53.8192 126.915 55.4314 122.094 56.8065 119.803C58.6083 116.796 64.82 115.364 65.7683 115.125Z"
                fill="white"
            />
            <path
                d="M193.082 102.238C192.513 102.095 189.005 101.283 187.961 99.613C187.155 98.3242 186.254 95.6036 186.065 95.0309V94.9832C186.065 94.9832 186.065 94.9832 186.065 95.0309V95.0786C185.875 95.6514 184.974 98.372 184.168 99.6607C183.125 101.379 179.616 102.19 179.047 102.286C179.616 102.429 183.125 103.24 184.168 104.911C184.974 106.2 185.875 108.92 186.065 109.493V109.541C186.065 109.541 186.065 109.541 186.065 109.493V109.445C186.254 108.873 187.155 106.152 187.961 104.863C189.005 103.145 192.561 102.334 193.082 102.238Z"
                fill="white"
            />
            <path
                d="M215.322 111.498C216.815 111.498 218.025 110.28 218.025 108.777C218.025 107.275 216.815 106.057 215.322 106.057C213.829 106.057 212.619 107.275 212.619 108.777C212.619 110.28 213.829 111.498 215.322 111.498Z"
                fill="#17C0FF"
            />
            <path
                d="M68.3747 156.698C69.8674 156.698 71.0775 155.48 71.0775 153.978C71.0775 152.475 69.8674 151.257 68.3747 151.257C66.882 151.257 65.6719 152.475 65.6719 153.978C65.6719 155.48 66.882 156.698 68.3747 156.698Z"
                fill="#71CAEB"
            />
            <path
                d="M48.402 59.8049C49.8947 59.8049 51.1048 58.5868 51.1048 57.0843C51.1048 55.5817 49.8947 54.3636 48.402 54.3636C46.9093 54.3636 45.6992 55.5817 45.6992 57.0843C45.6992 58.5868 46.9093 59.8049 48.402 59.8049Z"
                fill="#FEA6CE"
            />
            <path
                d="M163.353 149.396H81.2259C78.7128 149.396 76.6738 147.343 76.6738 144.813V79.8527C76.6738 77.323 78.7128 75.2706 81.2259 75.2706H163.305C165.818 75.2706 167.857 77.323 167.857 79.8527V144.813C167.905 147.343 165.866 149.396 163.353 149.396Z"
                fill="#F9B34C"
            />
            <path
                d="M162.119 75.2706H100.477V149.396H162.119C165.296 149.396 167.904 146.77 167.904 143.572V81.0937C167.904 77.848 165.296 75.2706 162.119 75.2706Z"
                fill="#FFD178"
            />
            <path
                d="M103.369 75.2705C103.369 75.0319 103.369 74.8409 103.369 74.6023V34.318C103.369 22.7196 111.857 13.269 122.289 13.269C132.72 13.269 141.208 22.7196 141.208 34.318V74.6023C141.208 74.8409 141.208 75.0319 141.208 75.2705H153.157C153.157 75.0319 153.157 74.8409 153.157 74.6023V34.318C153.157 15.4169 139.264 0 122.289 0C105.313 0 91.4199 15.4169 91.4199 34.318V74.6023C91.4199 74.8409 91.4199 75.0319 91.4199 75.2705H103.369Z"
                fill="#FFDFA1"
            />
            <path
                d="M135.708 11.5029C137.795 12.4575 139.312 13.9372 140.355 15.9896C141.446 18.2329 144.812 16.276 143.722 14.0326C142.441 11.4552 140.308 9.30735 137.7 8.16183C135.424 7.11176 133.432 10.4529 135.708 11.5029Z"
                fill="white"
            />
            <path
                d="M145.335 23.2924C146.409 23.2924 147.279 22.4162 147.279 21.3354C147.279 20.2546 146.409 19.3785 145.335 19.3785C144.261 19.3785 143.391 20.2546 143.391 21.3354C143.391 22.4162 144.261 23.2924 145.335 23.2924Z"
                fill="white"
            />
            <path
                d="M141.304 106.152C141.304 102.19 138.127 98.9448 134.144 98.9448C130.161 98.9448 126.984 102.143 126.984 106.152C126.984 109.016 128.691 111.498 131.11 112.643L127.648 125.721H140.261L137.558 112.452C139.834 111.211 141.304 108.825 141.304 106.152Z"
                fill="#37315F"
            />
        </svg>
    );
}

export default React.memo(NoAccessSVG);
