import React from 'react';
import * as columnTypes from 'const/columnTypes';
import ReferenceTextSelect from './Ref/Text';
import ReferenceFileSelect from './Ref/Files';
import ReferenceDateTimeSelect from './Ref/DateTime';
import ReferenceMultipleTextSelect from './Ref/Multiple';
import ReferenceBooleanSelect from './Ref/Boolean';
import { makeStyles } from '@material-ui/core/styles';
import { ROW_HEIGHT } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative'
    }
}));

function Ref({ isReadOnly, ...rest }) {
    const classes = useStyles({ isReadOnly });

    return (
        <div className={classes.root}>
            <div>{ReferenceSelectRender(rest)}</div>
        </div>
    );
}

function ReferenceSelectRender({ referencedColumnType, ...rest }) {
    switch (referencedColumnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.NUMBER:
        case columnTypes.MARKDOWN:
        case columnTypes.RICH_TEXT:
        case columnTypes.FORMULA:
        case columnTypes.SINGLE_SELECTION:
        case columnTypes.TRANSLATION:
        case columnTypes.PATH_TAG:
        case columnTypes.GROUP_TAGS:
        case columnTypes.LOCALIZATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
            return <ReferenceTextSelect {...rest} />;
        case columnTypes.DATETIME:
            return <ReferenceDateTimeSelect {...rest} />;
        case columnTypes.BOOLEAN:
            return <ReferenceBooleanSelect {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <ReferenceMultipleTextSelect {...rest} />;
        case columnTypes.FILES:
            return <ReferenceFileSelect {...rest} />;
        default:
            return <ReferenceTextSelect {...rest} />;
    }
}

export default React.memo(Ref);
