import React from 'react';

function ReferenceSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M9.99453998,3.73666503 L8.48805239,5.24226698 L7.30659768,5.24152574 L7.30659768,9.04981302 L11.7782355,9.04981302 L11.7782355,8.08962596 L13.2847231,6.58313836 L13.2847231,12.593171 C13.2847231,14.060561 12.0951684,15.2501156 10.6277784,15.2501156 L2.65694461,15.2501156 C1.18955462,15.2501156 0,14.060561 0,12.593171 L0,6.3936336 C0,4.92624361 1.18955462,3.73666503 2.65694461,3.73666503 L9.99453998,3.73666503 Z M11.7782355,10.3782853 L7.30659768,10.3782853 L7.30659768,13.7437485 L10.6277784,13.7445137 C11.2636474,13.7445137 11.7791211,13.22904 11.7791211,12.593171 L11.7782355,10.3782853 Z M5.97812538,10.3782853 L1.50560195,10.3782853 L1.50560195,12.593171 C1.50560195,13.22904 2.02107562,13.7445137 2.65694461,13.7445137 L5.97812538,13.7437485 L5.97812538,10.3782853 Z M5.97812538,5.24152574 L2.65694461,5.24229094 C2.02107562,5.24229094 1.50560195,5.75776461 1.50560195,6.3936336 L1.50560195,9.04981302 L5.97812538,9.04981302 L5.97812538,5.24152574 Z M15.0606282,0.8 L16,1.73937178 L15.9851849,1.75295747 L15.9859501,6.17222155 L14.6574778,6.17222155 L14.6567126,3.08142977 L9.73752149,8.00185029 L8.79814971,7.06247851 L13.7188111,2.14175703 L10.6277784,2.14252222 L10.6277784,0.814049916 L15.0472834,0.813284723 L15.0606282,0.8 Z"
                    id="Shape"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(ReferenceSVG);
