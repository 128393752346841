import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useCurrentRowIndex, useReorderHighlight, useRowHeight } from 'hooks/gridUI';

function ReorderRecordHighlight() {
    const theme = useTheme();
    const currentRowIndex = useCurrentRowIndex();
    const highlightPosition = useReorderHighlight();
    const rowHeight = useRowHeight();

    if (!currentRowIndex) return null;

    return (
        <Grid
            container
            style={{
                top: highlightPosition === 'top' ? +currentRowIndex * rowHeight : (+currentRowIndex + 1) * rowHeight,
                left: 0,
                width: '100%',
                height: 1,
                background: theme.colors.highlight,
                position: 'absolute',
                pointerEvents: 'none'
            }}
        ></Grid>
    );
}

export default ReorderRecordHighlight;
