import React from 'react';
import { Grid } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { useTranslation } from 'react-i18next';

function SingleLine({ defaultValue, onChange }) {
    const { t } = useTranslation();

    const value = React.useMemo(() => {
        return typeof defaultValue === 'string' || typeof defaultValue === 'number' ? defaultValue : '';
    }, [defaultValue]);

    return (
        <Grid container>
            <InputText
                name="publicId"
                label={
                    <>
                        <p className="body1 inline">{t('global_default_value')}</p>{' '}
                        <p className="caption inline">({t('global_optional')})</p>
                    </>
                }
                value={value}
                onChange={e => onChange(e?.target?.value)}
                placeholder={t('set_default_value')}
            />
        </Grid>
    );
}

export default SingleLine;
