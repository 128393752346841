import React from 'react';

function SynchronizeTMPullSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_590_18395)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.205 19.5581L18.6453 20.1178H19.7647C21.3241 20.1178 22.5882 18.8537 22.5882 17.2943C22.5882 16.9045 22.9043 16.5884 23.2941 16.5884C23.684 16.5884 24 16.9045 24 17.2943C24 19.6334 22.1038 21.5296 19.7647 21.5296H18.6453L19.205 22.0893C19.4807 22.365 19.4807 22.8119 19.205 23.0876C18.9294 23.3632 18.4824 23.3632 18.2067 23.0876L16.442 21.3228C16.1664 21.0472 16.1664 20.6002 16.442 20.3246L18.2067 18.5599C18.4824 18.2842 18.9294 18.2842 19.205 18.5599C19.4807 18.8355 19.4807 19.2825 19.205 19.5581ZM20.3244 15.0305L20.8841 14.4708H19.7647C18.2053 14.4708 16.9412 15.7349 16.9412 17.2943C16.9412 17.6841 16.6251 18.0002 16.2353 18.0002C15.8454 18.0002 15.5294 17.6841 15.5294 17.2943C15.5294 14.9552 17.4256 13.059 19.7647 13.059H20.8841L20.3244 12.4993C20.0487 12.2236 20.0487 11.7767 20.3244 11.501C20.6001 11.2254 21.047 11.2254 21.3227 11.501L23.0874 13.2658C23.363 13.5414 23.363 13.9884 23.0874 14.264L21.3227 16.0287C21.047 16.3044 20.6001 16.3044 20.3244 16.0287C20.0487 15.7531 20.0487 15.3061 20.3244 15.0305ZM21.8824 8.83335C21.8824 9.2232 21.5663 9.53923 21.1765 9.53923C20.7866 9.53923 20.4706 9.2232 20.4706 8.83335V5.9985C20.4706 5.22152 19.8368 4.58841 19.0565 4.58841H11.644C11.0046 4.58841 10.2973 4.17851 9.97932 3.62203L9.26551 2.37286C9.19883 2.25616 8.95955 2.11782 8.82178 2.11782H2.82152C2.0428 2.11782 1.41176 2.74882 1.41176 3.52731V16.2368C1.41176 17.014 2.04546 17.6472 2.82552 17.6472H13.6779C14.0678 17.6472 14.3838 17.9633 14.3838 18.3531C14.3838 18.743 14.0678 19.059 13.6779 19.059H2.82552C1.266 19.059 0 17.794 0 16.2368V3.52731C0 1.9691 1.26313 0.706055 2.82152 0.706055H8.82178C9.46531 0.706055 10.1728 1.1151 10.4913 1.67243L11.2051 2.92161C11.2715 3.03793 11.5109 3.17664 11.644 3.17664H19.0565C20.6161 3.17664 21.8824 4.44148 21.8824 5.9985V8.83335Z"
                    fill={color}
                />
                <path d="M9.72433 8.38187H7.96559V13.2188H6.99324V8.38187H5.25V7.59375H9.72433V8.38187Z" fill={color} />
                <path
                    d="M11.7387 7.59375L13.3658 11.9052L14.9889 7.59375H16.2557V13.2188H15.2795V11.3644L15.3763 8.8841L13.7105 13.2188H13.0094L11.3475 8.88796L11.4443 11.3644V13.2188H10.4681V7.59375H11.7387Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_590_18395">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(SynchronizeTMPullSVG);
