import React from 'react';

function NewTMSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M39.0632 14.754C39.5785 14.754 40 14.3297 40 13.8112V7.87192C40 3.53529 36.4871 0 32.178 0H7.82201C3.51288 0 0 3.53529 0 7.87192V21.8245C0 26.1612 3.51288 29.6965 7.82201 29.6965H32.1311C36.4403 29.6965 39.9532 26.1612 39.9532 21.8245V21.1175C39.9532 20.599 39.5316 20.1747 39.0164 20.1747C38.5012 20.1747 38.0796 20.599 38.0796 21.1175V21.8245C38.0796 25.1241 35.4098 27.811 32.1311 27.811H7.82201C4.54333 27.8581 1.87354 25.1713 1.87354 21.8245V7.87192C1.87354 4.57231 4.54333 1.88549 7.82201 1.88549H32.1311C35.4098 1.88549 38.0796 4.57231 38.0796 7.87192V13.7641C38.1265 14.3297 38.548 14.754 39.0632 14.754Z"
                fill="#4D3F8A"
            />
            <path d="M17.3506 10.6813H13.6751V21H11.6431V10.6813H8V9H17.3506V10.6813Z" fill="#63C5EA" />
            <path
                d="M21.5604 9L24.9606 18.1978L28.3527 9H31V21H28.9599V17.044L29.1623 11.7527L25.6811 21H24.2158L20.7427 11.761L20.9451 17.044V21H18.905V9H21.5604Z"
                fill="#63C5EA"
            />
        </svg>
    );
}

export default React.memo(NewTMSVG);
