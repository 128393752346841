import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import TMGuideSVG from 'assets/images/svg/TMGuideSVG';
import TMSearchNotFoundSVG from 'assets/images/svg/TMSearchNotFoundSVG';
import Spinner from 'components/spinner/Base';
import RecordNotification from 'components/notification/Record';
import hexToRgba from 'hex-to-rgba';
import { Link, useParams } from 'react-router-dom';
import * as columnTypes from 'const/columnTypes';
import AnimationLoading from 'components/loading/AnimationLoading';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 472,
        borderRadius: 4
    },
    textCenter: {
        textAlign: 'center'
    },
    spinnerWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%'
    },
    header: {
        height: 40,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    body: {
        height: 472,
        overflow: 'hidden auto',
        position: 'relative',
        width: '100%'
    },
    recordItem: {
        padding: `${theme.spacing(2)}px 18px`
    },
    recordIndex: {
        color: theme.colors.secondaryText
    },
    cellItem: {
        width: '100%',
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.ghostwhite
        }
    },
    fullWidth: {
        width: '100%'
    },
    showMoreWrapper: {
        padding: theme.spacing(3),
        '&:hover': {
            cursor: 'pointer'
        }
    },
    showMoreText: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    showMoreBadge: {
        padding: `2px 4px`,
        borderRadius: 2,
        background: theme.colors.paleGrey,
        color: theme.colors.dimGrey
    },
    animationWrapper: {
        height: 470
    },
    animationLoading: {
        width: '100%',
        height: '100%',
        padding: '0 71px'
    }
}));

function SearchBox({ total, isFirstTime, isSearching, isSearchingMore, cellData, fetchMoreRecords }) {
    const classes = useStyles();
    const { workspaceId } = useParams();
    const { t } = useTranslation();

    const showMoreNumber = useMemo(() => {
        return Math.max(0, total - cellData.length);
    }, [total, cellData]);

    const isHavingNoResult = useMemo(() => {
        return !cellData?.length && !isSearching;
    }, [cellData, isSearching]);

    if (isSearching) {
        return (
            <Grid item className={classes.animationWrapper}>
                <AnimationLoading
                    className={classes.animationLoading}
                    data={{
                        0: 'Wait for it',
                        2.5: 'Here it comes',
                        5: 'Almost there'
                    }}
                    maxSecond={7.5}
                    timeInterval={500}
                />
            </Grid>
        );
    }

    if (isFirstTime)
        return (
            <Grid
                container
                spacing={2}
                className={classes.root}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Grid item>
                    <TMSearchNotFoundSVG />
                </Grid>
                <Grid item container direction="column" spacing={1} className={classes.textCenter}>
                    <Grid item>
                        <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">{t('what_are_you_looking_for')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.body}>
                {isHavingNoResult ? (
                    <Grid
                        container
                        spacing={2}
                        className={classes.root}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item>
                            <TMGuideSVG />
                        </Grid>
                        <Grid item container direction="column" spacing={1} className={classes.textCenter}>
                            <Grid item>
                                <Typography variant="h4">No results were found to match your search</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">Try modifying your search criteria</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column" className={classes.fullWidth}>
                        {cellData?.map(record => {
                            const {
                                columnType,
                                data,
                                recordId,
                                columnId,
                                columnName,
                                gridName,
                                recordPublicId,
                                group,
                                branchId,
                                branchName,
                                dbId,
                                gridId,
                                viewId
                            } = record;
                            return (
                                <Link
                                    key={`${branchId}_${recordId}_${columnId}`}
                                    to={`/projects/${workspaceId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}?action=SEARCHCELL-${recordId}-${columnId}`}
                                    style={{ textDecoration: 'none', width: '100%', color: 'unset' }}
                                    target="_blank"
                                >
                                    <Grid item className={classes.cellItem}>
                                        <RecordNotification
                                            group={group}
                                            columnType={
                                                columnId === columnTypes.PATH_TAG ? columnTypes.PATH_TAG : columnType
                                            }
                                            data={data}
                                            columnName={columnName}
                                            dbId={dbId}
                                            showGridContent
                                            gridName={gridName}
                                            branchName={branchName}
                                            recordName={`#${recordPublicId}`}
                                        />
                                    </Grid>
                                </Link>
                            );
                        })}
                        {showMoreNumber > 0 && (
                            <Grid item className={classes.showMoreWrapper}>
                                {isSearchingMore && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justify="center"
                                        direction="row"
                                        spacing={2}
                                        style={{ height: 33 }}
                                    >
                                        <Grid item>
                                            <Spinner size={18} thick={3} />
                                        </Grid>
                                    </Grid>
                                )}
                                {!isSearchingMore && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        direction="row"
                                        spacing={2}
                                        onClick={fetchMoreRecords}
                                    >
                                        <Grid item>
                                            <Typography className={classes.showMoreText} variant="body1">
                                                Show more
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.showMoreBadge} variant="body1">
                                                {showMoreNumber}+
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(SearchBox);
