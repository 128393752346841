import React from 'react';

import DefaultElement from './components/Default';
import ImageElement from './components/Image';
import BlockQuoteElement from './components/Blockquote';
import CodeElement from './components/Code';
import StrikethroughElement from './components/Strikethrough';
import UnderlineElement from './components/Underline';

import H1Element from './components/H1';
import H2Element from './components/H2';
import H3Element from './components/H3';
import H4Element from './components/H4';
import H5Element from './components/H5';
import H6Element from './components/H6';
import OreredListElement from './components/OrderedList';
import BulletedList from './components/BulletedList';

import ListElement from './components/ListItem';
import LinkElement from './components/Link';

import TYPES from './types';

const elementRenderer = props => {
    switch (props.element.type) {
        case TYPES.IMG:
            return <ImageElement {...props} />;
        case TYPES.BLOCKQUOTE:
            return <BlockQuoteElement {...props} />;
        case TYPES.CODE:
            return <CodeElement {...props} />;
        case TYPES.STRIKETHROUGH:
            return <StrikethroughElement {...props} />;
        case TYPES.UNDERLINE:
            return <UnderlineElement {...props} />;
        case TYPES.H1:
            return <H1Element {...props} />;
        case TYPES.H2:
            return <H2Element {...props} />;
        case TYPES.H3:
            return <H3Element {...props} />;
        case TYPES.H4:
            return <H4Element {...props} />;
        case TYPES.H5:
            return <H5Element {...props} />;
        case TYPES.H6:
            return <H6Element {...props} />;
        case TYPES.OL:
            return <OreredListElement {...props} />;
        case TYPES.UL:
            return <BulletedList {...props} />;
        case TYPES.LI:
            return <ListElement {...props} />;
        case TYPES.LINK:
            return <LinkElement {...props} />;
        default:
            return <DefaultElement {...props} />;
    }
};

export default elementRenderer;
