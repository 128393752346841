import React from 'react';

function TextColorSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.0061 19.097L17 19.1539L16.9939 20.2001L7 20.1432L7.0061 19.097Z" fill="white" />
            <path
                d="M14.377 13.1291H9.61514L8.54567 16H7L11.3404 5H12.6518L17 16H15.4621L14.377 13.1291ZM10.0679 11.9354H13.9321L11.9961 6.79052L10.0679 11.9354Z"
                fill={color}
            />
            <path
                d="M24.5121 11.5121C24.3231 11.3231 24.457 11 24.7243 11L29.2757 11C29.543 11 29.6769 11.3231 29.4879 11.5121L27.2121 13.7879C27.095 13.905 26.905 13.905 26.7879 13.7879L24.5121 11.5121Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TextColorSVG);
