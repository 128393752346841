import * as types from '../types';

const handler = {
    [types.SET_CURRENT_CELL_COLOR](state, { payload }) {
        const { color } = payload;
        return {
            ...state,
            currentCellColor: color
        };
    },
    [types.SET_SELECTED_CELL_COLOR](state, { payload }) {
        const { color } = payload;
        return {
            ...state,
            selectedCellColor: color
        };
    },
    [types.RESET_CELL_COLOR](state) {
        return {
            ...state,
            currentCellColor: '#ffffff',
            selectedCellColor: null
        };
    }
};

export default handler;
