import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core';
import * as advancedSearchActions from 'advancedSearch/actions';
import { usePinnedSearch, useIsOpenReplaceSearch, useIsBlockedGridByOwner } from 'hooks/advanced';
import PinSVG from 'assets/images/svg/PinSVG';
import { useDispatch } from 'react-redux';
import Checkbox from 'components/checkbox/Base';
import InputText from 'components/inputs/InputText';
import DropdownSVG from 'assets/images/svg/DropdownSVG';
import ButtonBase from 'components/button/Base';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import Search from 'components/search';
import { useIsShareViewLink } from 'hooks/app';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: props => (props.isOpenReplaceSearch ? `1px solid ${theme.colors.border}` : 'none'),
        padding: `8px 0 8px`
    },
    header: {
        height: 40
    },
    fullWidth: {
        width: '100%'
    },
    pinIcon: {
        display: 'flex',
        cursor: 'pointer',
        padding: 2
    },
    activeIcon: {
        backgroundColor: '#D3CDEB',
        borderRadius: 4
    },
    arrowIcon: {
        display: 'flex',
        cursor: 'pointer',
        padding: 4
    },
    checkbox: {
        marginLeft: '8px',
        padding: '8px'
    },
    replace: {
        padding: '0 16px',
        marginTop: 12
    },
    replaceGroup: {
        padding: '4px 16px 0'
    },
    replaceButton: {
        color: theme.palette.primary.main
    },
    divider: {
        width: '1px',
        height: '36px',
        backgroundColor: theme.colors.lightGrey,
        padding: '0 !important',
        margin: '0 8px'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        width: 20,
        height: 20
    },
    replaceInput: {
        height: 40,
        border: `1px solid ${theme.colors.lightGreyBlue}`,
        '& input': {
            '&::placeholder': {
                color: `${theme.colors.disabledGrey} !important`,
                opacity: 1
            }
        }
    },
    toolbar: {
        padding: props => (!props.isOpenReplaceSearch ? '2px 20px 10px' : '2px 20px 0px'),
        borderBottom: props => (!props.isOpenReplaceSearch ? `1px solid ${theme.colors.border}` : 'none')
    },
    searchContainer: {
        marginTop: 16,
        padding: '0 16px'
    },
    searchBox: {
        height: 40,
        border: `1px solid ${theme.colors.lightGreyBlue}`,
        background: 'unset',
        '& input': {
            fontSize: '14px !important',
            '&::placeholder': {
                color: `${theme.colors.disabledGrey} !important`,
                opacity: 1
            }
        },
        '& svg': {
            '& path': {
                fill: theme.colors.iconGridUIColor
            }
        }
    },
    checkboxContainer: {
        padding: '0 16px'
    },
    leftCheckbox: {
        marginRight: 24
    },
    displayNone: {
        display: 'none'
    }
}));

const useStylesTooltip = makeStyles(theme => ({
    arrow: {
        color: theme.colors.tooltip
    },
    tooltip: {
        backgroundColor: theme.colors.tooltip,
        zIndex: 9999,
        fontSize: 13,
        maxWidth: 500,
        padding: '10px 14px'
    }
}));

const MuiAccordion = withStyles({
    root: {
        flex: 1,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(Accordion);

const MuiAccordionSummary = withStyles({
    root: {
        minHeight: 'unset',
        padding: 0,
        backgroundColor: 'unset !important',
        '&$expanded': {
            minHeight: 'unset'
        }
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0
        }
    },
    expanded: {}
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
    root: {
        padding: 0
    }
})(AccordionDetails);

function SearchRecordToolbar({
    matches,
    handleCheckMatchCase,
    handleReplace,
    replacing,
    searchValue,
    handleSearchChange,
    handleClearSearch,
    replacingText,
    totalRecords
}) {
    const theme = useTheme();
    const tooltipClasses = useStylesTooltip();
    const pinnedSearch = usePinnedSearch();
    const isOpenReplaceSearch = useIsOpenReplaceSearch();
    const classes = useStyles({ isOpenReplaceSearch });
    const dispatch = useDispatch();
    const [value, setValue] = useState(replacingText || '');
    const isShareViewLink = useIsShareViewLink();
    const isBlockedGridByOwner = useIsBlockedGridByOwner();
    const { t } = useTranslation();

    const togglePin = React.useCallback(() => {
        if (!pinnedSearch) {
            dispatch(advancedSearchActions.pinnedSearch());
        } else {
            dispatch(advancedSearchActions.unPinnedSearch());
        }
        sendManualTrack({ type: 'Pin Search Popover' });
    }, [dispatch, pinnedSearch]);

    const toggleOpenReplaceSearch = React.useCallback(() => {
        if (!isOpenReplaceSearch) {
            dispatch(advancedSearchActions.openReplaceSearch());
        } else {
            dispatch(advancedSearchActions.closeReplaceSearch());
        }
        sendManualTrack({ type: 'Open Replace Text' });
    }, [dispatch, isOpenReplaceSearch]);

    const checkboxes = useMemo(() => {
        return [
            { label: 'Match case', value: 'match_case' },
            { label: 'Match entire cell', value: 'match_entire_cell' }
        ];
    }, []);

    const _replace = useCallback(() => {
        handleReplace('single');
    }, [handleReplace]);

    const _replaceAll = useCallback(() => {
        handleReplace('all');
    }, [handleReplace]);

    const _changeValue = useCallback(
        e => {
            setValue(e.target.value);
            dispatch(advancedSearchActions.setReplacingText(e?.target?.value));
        },
        [dispatch]
    );

    const isAllowSearch = React.useMemo(() => {
        return totalRecords > 0 && !!searchValue;
    }, [totalRecords, searchValue]);

    return (
        <Grid container className={classes.root}>
            <MuiAccordion expanded={isOpenReplaceSearch}>
                <MuiAccordionSummary>
                    <Grid container>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify={isShareViewLink ? 'flex-end' : 'space-between'}
                            className={classes.toolbar}
                        >
                            <Tooltip classes={tooltipClasses} title="More options" placement="bottom-end">
                                <Grid
                                    item
                                    className={`${classes.arrowIcon} ${isOpenReplaceSearch ? classes.activeIcon : ''} ${
                                        isShareViewLink ? classes.displayNone : ''
                                    }`}
                                    onClick={toggleOpenReplaceSearch}
                                >
                                    <DropdownSVG
                                        className={classes.replaceIcon}
                                        style={{ transform: isOpenReplaceSearch && 'rotate(180deg)' }}
                                        color={
                                            isOpenReplaceSearch
                                                ? theme.palette.primary.main
                                                : theme.colors.iconGridUIColor
                                        }
                                    />
                                </Grid>
                            </Tooltip>
                            <Tooltip classes={tooltipClasses} title="Pin this window on top">
                                <Grid
                                    item
                                    className={`${classes.pinIcon} ${pinnedSearch ? classes.activeIcon : ''}`}
                                    onClick={togglePin}
                                >
                                    <PinSVG
                                        color={pinnedSearch ? theme.palette.primary.main : theme.colors.steel}
                                        width="20"
                                        height="20"
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.searchContainer} item style={{ flex: 1 }}>
                            <Search
                                className={classes.searchBox}
                                onChange={handleSearchChange}
                                value={searchValue}
                                placeholder={'Find...'}
                                onClear={handleClearSearch}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                    <Grid container direction="column">
                        {!isBlockedGridByOwner && (
                            <Grid container className={classes.replace}>
                                <Grid item className={classes.fullWidth}>
                                    <InputText
                                        inputClassName={classes.replaceInput}
                                        placeholder="Replace with..."
                                        value={value}
                                        onChange={_changeValue}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid className={classes.checkboxContainer} container direction="row" alignItems="center">
                            {checkboxes.map((checkbox, idx) => (
                                <Grid item key={checkbox.value} className={idx === 0 ? classes.leftCheckbox : ''}>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={matches?.includes(checkbox.value)}
                                                onChange={e => handleCheckMatchCase(checkbox.value, e?.target?.checked)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{checkbox.label}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        {!isBlockedGridByOwner && (
                            <div className="flex items-center justify-between flex-nowrap">
                                <ButtonBase
                                    width={176}
                                    onClick={_replace}
                                    isLoading={true}
                                    disabled={replacing || !isAllowSearch}
                                    textPrimary={true}
                                >
                                    {replacing ? (
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    ) : (
                                        t('global_replace')
                                    )}
                                </ButtonBase>
                                <Grid className={classes.divider} item></Grid>

                                <ButtonBase
                                    textPrimary={true}
                                    disabled={replacing || !isAllowSearch}
                                    width={176}
                                    onClick={_replaceAll}
                                >
                                    {t('replace_all')}
                                </ButtonBase>
                            </div>
                        )}
                    </Grid>
                </MuiAccordionDetails>
            </MuiAccordion>
        </Grid>
    );
}

export default React.memo(SearchRecordToolbar);
