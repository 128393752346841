import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ListItem from 'components/list/Item';
import KeyboardSVG from 'assets/images/svg/KeyboardSVG';
import { useDispatch, useSelector } from 'react-redux';
import * as appActions from 'app/actions';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import FullAPIDocSVG from 'assets/images/svg/FullAPIDocSVG';
import { DASHBOARD, DASHBOARD_TUTORIAL, GRID_TUTORIAL } from 'const';
import TutorialSVG from 'assets/images/svg/TutorialSVG';
import ApiStatusSVG from 'assets/images/svg/ApiStatusSVG';
import { useIsOverRecords } from 'hooks/payment';
import { useDatabasesByWorkspaceId } from 'hooks/database';
import { useAppApiStatus } from 'hooks/app';
import * as databaseActions from 'databases/actions';
import uuidv1 from 'uuid/v1';
import { useParams } from 'react-router';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { isAdmin, isOwner } from 'utils/permission/role';
import { getColorByStatusApi } from 'utils/color';
import WhatNewSVG from 'assets/images/svg/WhatNewSVG';
import { isExternal } from 'config';
import HelpSquareSVG from 'assets/images/svg/HelpSquareSVG';
import PrivacyPolicySVG from 'assets/images/svg/PrivacyPolicySVG';

const useStyles = makeStyles(theme => ({
    root: {},
    groupLabel: {
        marginLeft: 20,
        marginTop: 8,
        marginBottom: 8,
        color: theme.colors.lightGreyBlue
    }
}));

function HelpMenuPopup({ isGridUI, onClose, onKeyboardShortcutClick, openWhatNew }) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userInfo = useCurrentUserFullInfo();
    const currentPage = useSelector(state => state.app.currentPage);
    const isOverRecord = useIsOverRecords();
    const { workspaceId } = useParams();
    const projectDatabases = useDatabasesByWorkspaceId(workspaceId);
    const apiStatus = useAppApiStatus();

    const openSupportLink = () => {
        window.open(`https://help.gridly.com/hc/en-us`, '_blank');
        onClose();
    };

    const openAPILink = () => {
        window.open(`https://www.gridly.com/docs/api`, '_blank');
        onClose();
    };

    const openServerStatusLink = () => {
        window.open(`https://status.gridly.com/`, '_blank');
        onClose();
    };

    const openPrivacyPolicyLink = () => {
        window.open(`https://www.gridly.com/privacy/`, '_blank');
        onClose();
    };

    const openTermOfUseLink = () => {
        window.open(`https://www.gridly.com/terms/`, '_blank');
        onClose();
    };

    const handleKeyboardShortcutClick = () => {
        onKeyboardShortcutClick && onKeyboardShortcutClick();
        onClose();
    };

    const handleOpenDashboardTutorial = () => {
        sendManualTrack({ type: 'Reopen Dashboard Tutorial' });
        dispatch(appActions.setCurrentTutorial(DASHBOARD_TUTORIAL));
        if (projectDatabases?.length === 0) {
            dispatch(
                databaseActions.createDatabase({
                    database: {
                        name: t('new_database_name'),
                        id: uuidv1()
                    },
                    isOpenPopup: false
                })
            );
        }
        onClose();
    };

    const handleOpenGridTutorial = () => {
        sendManualTrack({ type: 'Reopen Grid Tutorial' });
        dispatch(appActions.setCurrentTutorial(GRID_TUTORIAL));
        onClose();
    };

    const isCurrentOwner = isOwner({ currentUser: userInfo });
    const isCurrentAdmin = isAdmin({ currentUser: userInfo });

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item>
                <Typography variant="body1" className={classes.groupLabel}>
                    {t('support')}
                </Typography>
            </Grid>
            <Grid item>
                <ListItem
                    onClick={openSupportLink}
                    icon={<HelpSquareSVG />}
                    name={t('header_help_dropdown_support_site')}
                />
            </Grid>
            <Grid item>
                <Typography variant="body1" className={classes.groupLabel}>
                    {t('education')}
                </Typography>
            </Grid>
            {!isExternal && (
                <Grid item>
                    <ListItem onClick={openWhatNew} icon={<WhatNewSVG size={16} />} name={t('what_new')} />
                </Grid>
            )}
            {isGridUI && (
                <Grid item>
                    <ListItem
                        onClick={handleKeyboardShortcutClick}
                        icon={<KeyboardSVG />}
                        name={t('header_help_dropdown_keyboard_shortcut')}
                    />
                </Grid>
            )}
            <Grid item>
                <ListItem
                    onClick={openAPILink}
                    icon={<FullAPIDocSVG />}
                    name={t('header_help_dropdown_api_document')}
                />
            </Grid>
            {isGridUI && !isOverRecord && (isCurrentOwner || isCurrentAdmin) && (
                <Grid item>
                    <ListItem onClick={handleOpenGridTutorial} icon={<TutorialSVG />} name={t('grid_tutorial')} />
                </Grid>
            )}
            {currentPage === DASHBOARD && (isCurrentOwner || isCurrentAdmin) && (
                <Grid item>
                    <ListItem onClick={handleOpenDashboardTutorial} icon={<TutorialSVG />} name="Dashboard tutorial" />
                </Grid>
            )}
            <Grid item>
                <Typography variant="body1" className={classes.groupLabel}>
                    {t('information')}
                </Typography>
            </Grid>
            <Grid item>
                <ListItem onClick={openPrivacyPolicyLink} icon={<PrivacyPolicySVG />} name={t('privacy_policy')} />
            </Grid>
            <Grid item>
                <ListItem onClick={openTermOfUseLink} icon={<TutorialSVG />} name={t('term_of_use')} />
            </Grid>
            {!isExternal && (
                <Grid item>
                    <ListItem
                        onClick={openServerStatusLink}
                        icon={<ApiStatusSVG dotColor={getColorByStatusApi({ theme, apiStatus })} size={16} />}
                        name={t('gridly_status')}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(HelpMenuPopup);
