import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
// import FilesSVG from 'components/svg-icon/FilesSVG';
import LDSelectBase from 'components/selects/LDBase';
import InputText from 'components/inputs/InputText';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
// import { enqueueSnackbar } from 'notifier/actions';
import { useDispatch } from 'react-redux';
// import { useDropzone } from 'react-dropzone';
// import { GRID_UPLOAD_MAX_SIZE } from 'const';
import hexToRgba from 'hex-to-rgba';
import { useJiraCreateTypeOptions, useSelectedFeedbackOption, useCompanyInfo, useSelfHostingLicense } from 'hooks/app';
import * as appActions from 'app/actions';
import packageJson from '../../package.json';
import { useTranslation } from 'react-i18next';
import { createZendeskIssueApi } from 'services/workflow';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        width: 540,
        position: 'relative'
    },
    header: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    body: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    closeIconWrapper: {
        position: 'absolute',
        top: 20,
        right: 20
    },
    closeIcon: {
        height: 24,
        width: 24
    },
    footer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        borderTop: `1px solid ${theme.colors.border}`,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    spacing: {
        marginBottom: theme.spacing(4)
    },
    red: {
        color: theme.colors.burntSlena
    },
    btnSubmit: {
        width: 100,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    uploadPlaceholder: {
        border: `1px dashed ${theme.colors.silver}`,
        padding: 20,
        cursor: 'pointer',
        borderRadius: 4,
        '&:focus': {
            outline: 'none'
        }
    },
    purple: {
        color: theme.palette.primary.main
    },
    fileItem: {
        borderRadius: 3,
        background: hexToRgba(theme.colors.dodgerBlue, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        padding: theme.spacing(1)
    },
    flex: {
        display: 'flex'
    },
    fileName: {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: theme.colors.dimGrey
    },
    fileList: {
        maxHeight: 200,
        overflowY: 'auto'
    }
}));

function FeedbackForm({ onClose }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const companyInfo = useCompanyInfo();
    const userInfo = useCurrentUserFullInfo();
    // const [files, setFiles] = React.useState([]);
    const jiraCreateTypeOptions = useJiraCreateTypeOptions();
    const selectedFeedbackOption = useSelectedFeedbackOption();
    const selfHostingLicense = useSelfHostingLicense();

    const handleFeedbackOptionChange = option => {
        dispatch(appActions.setFeedbackOption(option));
    };

    const formik = useFormik({
        initialValues: {
            description: '',
            summary: ''
        },
        validationSchema: object({
            summary: string().required(t('global_this_field_is_required')),
            description: string().required(t('global_this_field_is_required'))
        }),
        onSubmit: async (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            // const body = {
            //     ...values,
            //     issueTypeId: selectedFeedbackOption?.id,
            //     companyName: companyName,
            //     appVersion: packageJson.version,
            //     agent: navigator.userAgent
            // };

            // dispatch(
            //     appActions.createJiraIssue({
            //         body,
            //         successCallback: () => {
            //             onClose && onClose();
            //         },
            //         errorCallback: () => {
            //             setSubmitting(false);
            //         }
            //     })
            // );
            try {
                setSubmitting(true);
                const comment = `
                    App version: ${packageJson.version}
                    Company name: [${isExternal && selfHostingLicense ? selfHostingLicense.id : ''}] ${
                    companyInfo?.name
                }
                    User Agent: ${navigator.userAgent}
                    ${values.description}
                `;
                await createZendeskIssueApi({
                    name: userInfo.fullName,
                    email: userInfo.email,
                    subject: values.summary,
                    comment
                });
                setSubmitting(false);
                onClose();
                setStatus({ error: null });
            } catch (error) {
                setStatus({ error: error.message });
                console.log(error.message);
                setSubmitting(false);
                onClose();
            }
        }
    });

    React.useEffect(() => {
        return () => dispatch(appActions.setFeedbackOption(null));
    }, [dispatch]);

    // const onRejectedHandler = () => {
    //     dispatch(enqueueSnackbar({ message: `Some files are not supported`, type: 'info' }));
    // };

    // const onDropAcceptedHandler = React.useCallback(
    //     acceptedFiles => {
    //         let newFiles = [...files];
    //         const newFileNames = newFiles?.map(file => file?.name);
    //         acceptedFiles.forEach(file => {
    //             if (!newFileNames.includes(file?.name)) {
    //                 newFiles = [...newFiles, file];
    //             }
    //         });
    //         setFiles(newFiles);
    //     },
    //     [files]
    // );

    // const { getRootProps, getInputProps } = useDropzone({
    //     multiple: true,
    //     onDropAccepted: onDropAcceptedHandler,
    //     onDropRejected: onRejectedHandler,
    //     maxSize: GRID_UPLOAD_MAX_SIZE,
    //     accept: null
    // });

    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid } = formik;

    // const handleRemoveFile = file => {
    //     const newFiles = files.filter(fi => fi?.name !== file?.name);
    //     setFiles(newFiles);
    // };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" className={classes.root}>
                <IconButton className={classes.closeIconWrapper} size="small" onClick={onClose}>
                    <CloseIconSVG className={classes.closeIcon} />
                </IconButton>
                <Grid item className={`${classes.header} ${classes.spacing}`}>
                    <Typography variant="h3">{t('feedback_form_title')}</Typography>
                </Grid>
                <Grid item className={`${classes.body} ${classes.spacing}`}>
                    <Grid container direction="column" spacing={3}>
                        <Grid item container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="body1">{t('feedback_type_label')}</Typography>
                            </Grid>
                            <Grid item>
                                <LDSelectBase
                                    ddPlaceholder={t('feedback_type_input')}
                                    menuPlaceholder={t('feedback_type_input_search')}
                                    options={jiraCreateTypeOptions}
                                    onChange={handleFeedbackOptionChange}
                                    defaultValue={selectedFeedbackOption}
                                    isMulti={false}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="body1">
                                    {t('feedback_subject_label')} <span className={classes.red}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <InputText
                                    style={{ lineHeight: 1.54 }}
                                    name="summary"
                                    type="text"
                                    value={values.summary}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.summary && touched.summary}
                                    errorText={errors.summary}
                                    placeholder={t('feedback_subject_input')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="body1">
                                    {t('feedback_description_label')} <span className={classes.red}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <InputText
                                    style={{ lineHeight: 1.54 }}
                                    name="description"
                                    type="text"
                                    value={values.description}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    height={120}
                                    error={errors.description && touched.description}
                                    errorText={errors.description}
                                    placeholder={t('feedback_description_input')}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="body1">Attachments</Typography>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                    className={classes.uploadPlaceholder}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <Grid item>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item></Grid>
                                            <Grid item>
                                                <Typography variant="caption">
                                                    Drag and drop file here or{' '}
                                                    <span className={classes.purple}>browse</span> your computer
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {files?.length !== 0 && (
                                <Grid item>
                                    <Grid className={classes.fileList} container direction="column" spacing={1}>
                                        {files.map(file => {
                                            return (
                                                <Grid item key={file.name}>
                                                    <Grid
                                                        container
                                                        justify="space-between"
                                                        alignItems="center"
                                                        direction="row"
                                                        className={classes.fileItem}
                                                        wrap="nowrap"
                                                    >
                                                        <Grid item container alignItems="center" spacing={2}>
                                                            <Grid item className={classes.flex}>
                                                                <FilesSVG />
                                                            </Grid>
                                                            <Grid item className={classes.fileName}>
                                                                {file.name}
                                                            </Grid>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            className={classes.flex}
                                                            onClick={() => handleRemoveFile(file)}
                                                        >
                                                            <CloseIconSVG />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item className={classes.footer}>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Button variant="outlined" size="small" width={100} onClick={onClose}>
                                        {t('global_cancel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <div className={classes.btnSubmit}>
                                        <Button
                                            variant="contained"
                                            disabled={isSubmitting || !isValid}
                                            size="small"
                                            width={100}
                                            type="submit"
                                        >
                                            {t('feedback_btn_ok')}
                                        </Button>
                                        {isSubmitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default React.memo(FeedbackForm);
