import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { getAddonPlansApi, buyAddonApi, getAddonUsageApi } from 'services/payment';
import { fetchCustomerAndSubscriptions } from './billing';

export function fetchAddonPlans({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchAddonPlans());

        try {
            const plans = await getAddonPlansApi();
            dispatch(_fetchAddonPlansSuccess(plans));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(_fetchAddonPlansFailed());
        }
    };
}

function _fetchAddonPlans() {
    return {
        type: types.FETCH_ADD_ON_PLANS
    };
}

function _fetchAddonPlansFailed() {
    return {
        type: types.FETCH_ADD_ON_PLANS_FAILED
    };
}

function _fetchAddonPlansSuccess(plans) {
    return {
        type: types.FETCH_ADD_ON_PLANS_SUCCESS,
        payload: {
            plans
        }
    };
}

export function buyAddon({ body, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            await buyAddonApi(body);
            dispatch(
                fetchCustomerAndSubscriptions({
                    successCallback: () => {
                        successCallback && successCallback();
                    }
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchAddonUsage({ errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            const addonUsage = await getAddonUsageApi();
            dispatch(_fetchAddonUsageSuccess(addonUsage));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchAddonUsageSuccess(addonUsage) {
    return {
        type: types.FETCH_ADD_ON_USAGE_SUCCESS,
        payload: {
            addonUsage
        }
    };
}
