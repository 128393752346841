import * as types from '../types';

const handler = {
    [types.OPEN_CELL_PREVIEW](state) {
        return {
            ...state,
            isOpenCellPreview: true
        };
    },
    [types.CLOSE_CELL_PREVIEW](state) {
        return {
            ...state,
            isOpenCellPreview: false
        };
    },
    [types.TOGGLE_CELL_PREVIEW](state) {
        const { isOpenCellPreview } = state;
        return {
            ...state,
            isOpenCellPreview: !isOpenCellPreview
        };
    }
};

export default handler;
