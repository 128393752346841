import React from 'react';
import classnames from 'classnames';
import ButtonBase from './Base';

const IconButton = ({ children, className, ...rest }) => {
    return (
        <ButtonBase className={classnames('flex items-center justify-center w-9 h-9 !p-0', className)} {...rest}>
            {children}
        </ButtonBase>
    );
};

export default React.memo(IconButton);
