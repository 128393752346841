import React from 'react';

function ColorCloseSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.2953 1.7047C14.6845 2.09389 14.6845 2.7249 14.2953 3.11409L9.4094 8L14.2953 12.8859C14.6845 13.2751 14.6845 13.9061 14.2953 14.2953C13.9061 14.6845 13.2751 14.6845 12.8859 14.2953L8 9.4094L3.11409 14.2953C2.7249 14.6845 2.09389 14.6845 1.7047 14.2953C1.3155 13.9061 1.3155 13.2751 1.7047 12.8859L6.5906 8L1.7047 3.11409C1.3155 2.7249 1.3155 2.09389 1.7047 1.7047C2.09389 1.3155 2.7249 1.3155 3.11409 1.7047L8 6.5906L12.8859 1.7047C13.2751 1.3155 13.9061 1.3155 14.2953 1.7047Z"
                fill="#E86E5E"
            />
        </svg>
    );
}

export default React.memo(ColorCloseSVG);
