import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import LQAContainerSVG from 'assets/images/svg/LQAContainerSVG';
import Avatar from 'components/avatar/User';
import React, { useCallback, useMemo } from 'react';
import { getAvatarUrl } from 'utils/images';
import { formatDateFromNow } from 'utils/datetime';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { notificationMenuSetOpen } from '.';
import { UpperCaseFirstCharacter } from 'utils/name';

const useStyles = makeStyles(theme => ({
    itemWrapper: {
        position: 'relative',
        padding: '8px 0 0 16px',
        cursor: 'pointer',
        borderLeft: `2px solid transparent`,
        '&:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.colors.ghostwhite
        }
    },
    info: {
        flex: 1,
        marginLeft: 8,
        padding: '0 16px 12px 0'
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    firstline: {
        color: theme.colors.steel
    },
    pdr20: {
        paddingRight: 20
    },
    username: {
        fontWeight: 500,
        color: theme.colors.primaryText
    },
    date: {
        color: theme.colors.steel,
        fontSize: 12,
        '&.dateNoti': {
            color: theme.colors.midGrey
        }
    },
    ticketWrapper: {
        background: `rgba(110, 91, 190, 0.1)`,
        borderRadius: 3,
        '&.ticketWrapperNoti': {
            marginLeft: 22,
            background: 'transparent',
            '& *': {
                color: `${theme.colors.midGrey} !important`,
                fontSize: `12px !important`
            }
        }
    },
    ticket: {
        fontWeight: 500,
        color: theme.palette.primary.main
    },
    dot: {
        background: theme.palette.primary.main,
        borderRadius: '50%',
        padding: `1.5px !important`,
        margin: `0 1px`,
        '&.dotNoti': {
            background: theme.colors.midGrey
        }
    },
    ticketTitle: {
        color: theme.colors.highlight,
        '&.ticketTitleNoti': {
            marginLeft: 8,
            fontSize: 12,
            color: theme.colors.dimGrey
        }
    },
    mention: {
        padding: 8,
        borderRadius: 4,
        background: theme.colors.ghostwhite,
        color: theme.colors.steel,
        lineHeight: `21px`,
        fontSize: 13,
        ...theme.ellipsis(3),
        wordBreak: 'break-word'
    },
    status: {
        textTransform: 'capitalize'
    },
    lqaContainerSVG: {
        verticalAlign: 'sub'
    }
}));

const params = [
    {
        key: 'ticketSummary',
        messageKey: 'ticket_summary'
    },
    {
        key: 'ticketNumber',
        messageKey: 'ticket_number'
    },
    {
        key: 'ticketStatus',
        messageKey: 'ticket_status'
    },
    {
        key: 'actionName',
        messageKey: 'action_name'
    },
    {
        key: 'ticketMentionedUsers',
        messageKey: 'ticket_mentioned_users',
        isStringified: true
    },
    {
        key: 'ticketModifiedTime',
        messageKey: 'ticket_modified_time'
    },
    {
        key: 'description',
        messageKey: 'description'
    },
    {
        key: 'comment',
        messageKey: 'comment'
    },
    {
        key: 'ticketViewUrl',
        messageKey: 'ticket_view_url'
    },
    {
        key: 'ticketModifiedBy',
        messageKey: 'ticket_modified_by',
        isStringified: true
    },
    {
        key: 'comment',
        messageKey: 'comment',
        isStringified: true
    },
    {
        key: 'currentTicketPriority',
        messageKey: 'ticket_priority'
    },
    {
        key: 'lastTicketPriority',
        messageKey: 'last_ticket_priority'
    },
    {
        key: 'lastTicketStatus',
        messageKey: 'last_ticket_status'
    }
];

const TICKET_ACTION = {
    COMMENT: 'COMMENT',
    MENTION_COMMENT: 'MENTION_COMMENT',
    ASSIGN: 'ASSIGN',
    UNASSIGNED: 'UNASSIGNED',
    MENTION_TICKET: 'MENTION_TICKET',
    CHANGE_PRIORITY: 'CHANGE_PRIORITY',
    UPDATE_SUMMARY: 'UPDATE_SUMMARY',
    UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
    CHANGE_STATUS: 'CHANGE_STATUS',
    DELETE_TICKET: 'DELETE_TICKET'
};

const NotificationCenterTicketItem = ({ className, message = {}, isLast, isNoti, beforeClick, markAsRead }) => {
    const classes = useStyles();

    const { attributes = {} } = message || {};

    const {
        ticketSummary,
        actionName,
        ticketNumber,
        ticketStatus,
        ticketModifiedTime,
        comment,
        description,
        ticketMentionedUsers,
        ticketModifiedBy,
        ticketViewUrl,
        currentTicketPriority,
        lastTicketPriority
    } = useMemo(() => {
        return params.reduce((acc, cur) => {
            acc[cur.key] = attributes?.[cur.messageKey]?.value || undefined;
            if (cur.isStringified && acc[cur.key]) {
                acc[cur.key] = JSON.parse(acc[cur.key]);
            }
            return acc;
        }, {});
    }, [attributes]);

    const isComment = useMemo(() => actionName === TICKET_ACTION.COMMENT, [actionName]);
    const isMentionComment = useMemo(() => actionName === TICKET_ACTION.MENTION_COMMENT, [actionName]);

    const descMentionUsersObj = useMemo(() => {
        if (!isEmpty(ticketMentionedUsers)) {
            return ticketMentionedUsers.reduce((acc, cur) => {
                acc[`@{${cur.id}}`] = cur;
                return acc;
            }, {});
        }
        return {};
    }, [ticketMentionedUsers]);

    const commentMentionUsersObj = useMemo(() => {
        if (!isEmpty(comment?.mentionedUsers)) {
            return comment.mentionedUsers.reduce((acc, cur) => {
                acc[`@{${cur.id}}`] = cur;
                return acc;
            }, {});
        }
        return {};
    }, [comment]);

    const actionMessage = useMemo(() => {
        switch (actionName) {
            case TICKET_ACTION.COMMENT:
                return `commented on an issue`;
            case TICKET_ACTION.MENTION_COMMENT:
                return `mentioned you in a comment`;
            case TICKET_ACTION.ASSIGN:
                return `assigned an issue to you`;
            case TICKET_ACTION.UNASSIGNED:
                return `unassigned you from an issue`;
            case TICKET_ACTION.MENTION_TICKET:
                return `mentioned you on an issue`;
            case TICKET_ACTION.CHANGE_PRIORITY:
                return `changed an issue from <b>${UpperCaseFirstCharacter(
                    lastTicketPriority
                )}</b> to <b>${UpperCaseFirstCharacter(currentTicketPriority)}</b>`;
            case TICKET_ACTION.UPDATE_SUMMARY:
                return `updated an issue’s summary`;
            case TICKET_ACTION.UPDATE_DESCRIPTION:
                return `updated an issue’s description`;
            case TICKET_ACTION.CHANGE_STATUS:
                return `marked an issue as <b>${UpperCaseFirstCharacter(ticketStatus)}</b>`;
            case TICKET_ACTION.DELETE_TICKET:
                return `deleted an issue`;
            default:
                return '';
        }
    }, [actionName, currentTicketPriority, lastTicketPriority, ticketStatus]);

    const sender = useMemo(() => {
        if (isMentionComment || isComment) {
            return comment?.audit?.createdBy || {};
        }
        return ticketModifiedBy;
    }, [isMentionComment, isComment, comment, ticketModifiedBy]);

    const time = useMemo(() => {
        if (isMentionComment || isComment) {
            return comment?.audit?.lastModifiedTime || ticketModifiedTime;
        }
        return ticketModifiedTime;
    }, [isMentionComment, isComment, comment, ticketModifiedTime]);

    const renderSummary = useCallback(() => {
        return (
            <Grid item style={{ marginTop: isNoti ? 6 : 0 }}>
                <Grid container spacing={isNoti ? 0 : 2} wrap="nowrap">
                    <Grid item>
                        <LQAContainerSVG className={classes.lqaContainerSVG} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" className={`${classes.ticketTitle} ${isNoti && 'ticketTitleNoti'}`}>
                            {ticketSummary}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [classes, isNoti, ticketSummary]);

    const renderDesc = useCallback(() => {
        if (!description && isEmpty(comment)) return null;
        if (
            ![
                TICKET_ACTION.COMMENT,
                TICKET_ACTION.MENTION_COMMENT,
                TICKET_ACTION.MENTION_TICKET,
                TICKET_ACTION.ASSIGN,
                TICKET_ACTION.UPDATE_DESCRIPTION
            ].includes(actionName)
        ) {
            return null;
        }

        let str = isMentionComment || isComment ? comment.description : description;
        const mentionObj = isMentionComment || isComment ? commentMentionUsersObj : descMentionUsersObj;
        for (const property in mentionObj) {
            str = str.replaceAll(property, `<b>${mentionObj[property]?.name}</b>`);
        }
        return <Grid item className={classes.mention} dangerouslySetInnerHTML={{ __html: str }}></Grid>;
    }, [
        classes,
        description,
        comment,
        descMentionUsersObj,
        commentMentionUsersObj,
        isMentionComment,
        isComment,
        actionName
    ]);

    const link = useMemo(() => {
        if (ticketViewUrl && ![TICKET_ACTION.DELETE_TICKET].includes(actionName)) {
            return {
                pathname: ticketViewUrl.substr(0, ticketViewUrl.indexOf('?')),
                search: ticketViewUrl.substr(ticketViewUrl.indexOf('?')),
                state: {}
            };
        }
        return {};
    }, [ticketViewUrl, actionName]);

    const onClick = useCallback(() => {
        notificationMenuSetOpen && notificationMenuSetOpen(false);
        beforeClick && beforeClick();
        markAsRead && markAsRead();
    }, [beforeClick, markAsRead]);

    return (
        <Link to={link} onClick={onClick} style={{ textDecoration: 'none' }}>
            <Grid container className={`${classes.itemWrapper} ${className}`} wrap="nowrap">
                <Grid item>
                    <Avatar src={getAvatarUrl(`${sender?.id}`)} size={40} color="#DBDCE1">
                        <AvatarIconSVG />
                    </Avatar>
                </Grid>
                <Grid item className={`${classes.info} ${!isLast ? classes.borderBottom : ''}`}>
                    <Grid container direction="column" spacing={isNoti ? 0 : 2}>
                        <Grid item>
                            <Typography variant="body2" className={`${classes.firstline} ${!isNoti && classes.pdr20}`}>
                                <span className={classes.username}>{sender?.name}</span>{' '}
                                <span dangerouslySetInnerHTML={{ __html: actionMessage }} />
                            </Typography>
                        </Grid>
                        {isNoti && renderSummary()}
                        <Grid item>
                            <Grid
                                container
                                spacing={2}
                                justifyContent={isNoti ? 'space-between' : 'flex-start'}
                                alignItems="center"
                                wrap="nowrap"
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        className={`${classes.ticketWrapper} ${isNoti && 'ticketWrapperNoti'}`}
                                        spacing={1}
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Grid item className={classes.ticket}>
                                            <span>Ticket-{ticketNumber}</span>
                                        </Grid>
                                        <Grid item className={`${classes.dot} ${isNoti && 'dotNoti'}`} />
                                        <Grid item className={`${classes.ticket} ${classes.status}`}>
                                            <span>{ticketStatus}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <span className={`${classes.date} ${isNoti && 'dateNoti'}`}>
                                        {formatDateFromNow(time)}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isNoti && renderSummary()}
                        {!isNoti && renderDesc()}
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    );
};

export default React.memo(NotificationCenterTicketItem);
