import React from 'react';

function ArrowBackSVG({ ...other }) {
    return (
        <svg {...other} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.43789 4.7553L9.4535 4.75201H2.76195L4.86553 6.78257C4.96854 6.88171 5.02504 7.016 5.02504 7.15696C5.02504 7.29791 4.96854 7.43126 4.86553 7.53063L4.53821 7.84605C4.43529 7.94519 4.29813 8 4.15187 8C4.00553 8 3.86829 7.94558 3.76537 7.84644L0.159433 4.37364C0.0560999 4.27411 -0.000404358 4.14154 2.86102e-06 4.00051C-0.000404358 3.8587 0.0560999 3.72605 0.159433 3.62667L3.76537 0.153558C3.86829 0.0545011 4.00545 0 4.15187 0C4.29813 0 4.43529 0.0545793 4.53821 0.153558L4.86553 0.468976C4.96854 0.567955 5.02504 0.700136 5.02504 0.841087C5.02504 0.98196 4.96854 1.10717 4.86553 1.20623L2.73821 3.24814H9.44537C9.74675 3.24814 10 3.49833 10 3.78846V4.23457C10 4.52469 9.73927 4.7553 9.43789 4.7553Z"
                fill="#2A293A"
            />
        </svg>
    );
}

export default React.memo(ArrowBackSVG);
