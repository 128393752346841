import React from 'react';
import { Grid } from '@material-ui/core';
import Option from '../components/Option';
import isArray from 'lodash/isArray';
import { diffStatusRefArrayInOrder, isLDEmpty } from 'utils/object';
import { makeStyles } from '@material-ui/core/styles';
import validator from 'validator';
import hexToRgba from 'hex-to-rgba';
import { OPTION_SPACING } from 'const/style';
import { isRefImage } from 'utils/gridUI/reference';
import File from '../components/File';

const useStyles = makeStyles(theme => ({
    root: {},
    mw100: {
        maxWidth: '100%'
    },
    group: {
        background: hexToRgba(theme.colors.dodgerBlue, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        borderRadius: 6,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: OPTION_SPACING,
        marginBottom: OPTION_SPACING,
        width: 'fit-content'
    }
}));

function RefSelection({
    previousData = [],
    isHideMarkIcon = false,
    data = [],
    options = [],
    customProperties = {},
    dbId
}) {
    const classes = useStyles();
    const arr1 = previousData?.filter(ref => !isLDEmpty(ref?.referencedDataItem))?.map(ref => ref.referencedDataItem);
    const arr2 = data?.filter(ref => !isLDEmpty(ref?.referencedDataItem))?.map(ref => ref.referencedDataItem);

    const diffOptions = diffStatusRefArrayInOrder(arr1, arr2);

    if (!diffOptions || diffOptions?.length === 0 || !isArray(diffOptions)) return null;

    return (
        <Grid container className={classes.mw100} spacing={1}>
            {diffOptions?.map((diffOption, index) => {
                const status = diffOption?.status;
                let values = diffOption?.key;

                if (values instanceof Array) {
                    const isRefFile = isRefImage(values);

                    return values?.map((option, index) => {
                        return isRefFile ? (
                            <Grid className={classes.mw100} item key={index}>
                                <File
                                    fileMeta={option}
                                    isHideMarkIcon={isHideMarkIcon}
                                    state={status}
                                    width={28}
                                    height={28}
                                    dbId={dbId}
                                />
                            </Grid>
                        ) : (
                            <Grid className={classes.mw100} item key={index}>
                                <Option
                                    data={option}
                                    state={status}
                                    options={options}
                                    customProperties={customProperties}
                                    isHideMarkIcon={isHideMarkIcon}
                                />
                            </Grid>
                        );
                    });
                }

                if (values && validator.isJSON(values)) {
                    values = JSON.parse(values);
                    const isRefFile = isRefImage(values);

                    if (values instanceof Array) {
                        return (
                            <Grid item container>
                                {values?.map((option, index) => {
                                    return isRefFile ? (
                                        <Grid className={classes.mw100} item key={index}>
                                            <File
                                                fileMeta={option}
                                                isHideMarkIcon={isHideMarkIcon}
                                                state={status}
                                                width={28}
                                                height={28}
                                                dbId={dbId}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid className={classes.mw100} item key={index}>
                                            <Option
                                                data={option}
                                                state={status}
                                                options={options}
                                                customProperties={customProperties}
                                                isHideMarkIcon={isHideMarkIcon}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        );
                    }
                }
                const isRefFile = isRefImage(values);

                return isRefFile ? (
                    <Grid className={classes.mw100} item key={index}>
                        <File
                            fileMeta={values}
                            isHideMarkIcon={isHideMarkIcon}
                            state={status}
                            width={28}
                            height={28}
                            dbId={dbId}
                        />
                    </Grid>
                ) : (
                    <Grid item className={classes.mw100} key={index}>
                        <Option
                            data={values}
                            isHideMarkIcon={isHideMarkIcon}
                            state={status}
                            options={options}
                            customProperties={customProperties}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(RefSelection);
