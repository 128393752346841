import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import { MAX_OPTIONS } from 'const/gridUI';

import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import OptionCardInfo from './common/OptionCardInfo';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option: OptionCardInfo,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function LDSelectBase({ defaultValue, selectedColor, options, maxSize = MAX_OPTIONS, searchKey = '', ...other }) {
    const theme = useTheme();

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        }),
        menu: styles => ({
            ...styles,
            zIndex: 999
        })
    };

    const filterOption = ({ label, value }, string) => {
        if (label.includes(string) || value.includes(string)) return true;
        const groupOptions = options.filter(group => group.label.toLocaleLowerCase().includes(string));
        if (groupOptions) {
            for (const groupOption of groupOptions) {
                const option = groupOption.options.find(opt => opt.value === value);
                if (option) {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <Select
            cacheOptions
            options={options}
            filterOption={filterOption}
            autoFocus
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            tabSelectsValue={false}
            components={components}
            defaultValue={defaultValue}
            styles={selectStyles}
            menuIsOpen
            {...other}
        />
    );
}

LDSelectBase.propTypes = {
    ddPlaceholder: PropTypes.string,
    menuPlaceholder: PropTypes.string,
    isMulti: PropTypes.bool,
    maxSize: PropTypes.number,
    searchKey: PropTypes.string
};

export default React.memo(LDSelectBase);
