import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';

import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from '../menus/Popper';
import SelectItem from './SelectItem';
import Search from '../search';
import { INPUT_RADIUS, INPUT_HEIGHT, INPUT_PADDING_LEFT, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
        borderRadius: INPUT_RADIUS
    },
    dropDownIcon: {
        color: theme.colors.secondaryText,
        position: 'relative',
        top: 3
    },
    input: {
        borderRadius: INPUT_RADIUS,
        cursor: 'pointer',
        padding: `3px ${INPUT_PADDING_LEFT}px`,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: INPUT_HEIGHT
    },
    inputText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: SPACING_LIST_NAME_WITH_ICON
    },
    border: {
        border: `1px solid ${theme.colors.border}`
    },
    popper: {
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.15)',
        //border: 'solid 1px',
        borderColor: theme.colors.border,
        borderRadius: 6
    },
    list: {
        maxHeight: 250,
        overflow: 'auto',
        paddingTop: 0,
        paddingBottom: 0
    },
    disabledText: {
        color: '#c9c9ce'
    },
    search: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: 0,
        paddingTop: 0
    },
    focused: {
        border: `1px solid ${theme.colors.lightLavender}`
    }
}));

function Select({
    value,
    placeholder,
    options,
    className,
    popperClassName,
    color,
    border,
    search,
    button,
    onChange,
    Control,
    ...rest
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const selectedOption =
        (options && options.find(option => option.value + '' === value + '' && !option.isDisabled)) || {};

    const [filterValue, setFilterValue] = React.useState('');

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleItemSelect = ({ value, label }) => {
        onChange && onChange({ value, label });

        handleClickAway();
    };

    const buildMenuItems = (selectedOption, options, filterValue) => {
        const elements = [];

        options.forEach(option => {
            if (!filterValue || option.label.toLowerCase().includes(filterValue.toLowerCase())) {
                const selected = option.value === selectedOption.value;

                elements.push(
                    <SelectItem
                        key={`select-item-${option.value}`}
                        {...option}
                        selected={selected}
                        onClick={handleItemSelect}
                    />
                );
            }
        });

        return elements;
    };

    return (
        <div className={`select ${classes.root} ${className}`}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                className={`${classes.input} ${border ? classes.border : ''} ${anchorEl ? 'focused' : ``} `}
                onClick={handleClick}
            >
                <Grid item style={{ width: `calc(100% - ${button ? 40 : 16}px)` }}>
                    <Grid container direction="row" alignItems="center" justify="flex-start" wrap="nowrap">
                        {selectedOption.icon && (
                            <Grid item className={classes.icon}>
                                {typeof selectedOption.icon === 'function'
                                    ? selectedOption.icon()
                                    : selectedOption.icon}
                            </Grid>
                        )}
                        <Grid item className={`${classes.inputText} select-input`}>
                            {selectedOption.label ? (
                                typeof selectedOption.label === 'object' ? (
                                    <span className={classes.text}>{selectedOption.label}</span>
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(selectedOption.label, {
                                                USE_PROFILES: { html: true }
                                            })
                                        }}
                                        className={classes.text}
                                    />
                                )
                            ) : (
                                <span className={button ? '' : classes.disabledText}>{placeholder}</span>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {!button && (
                    <Grid item>
                        <ArrowDownSVG className={classes.dropDownIcon} color={color} />
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu
                    anchorEl={anchorEl}
                    {...rest}
                    className={`${classes.popper} ${popperClassName}`}
                    placement={'bottom-end'}
                    style={!button ? { width: `${anchorEl?.clientWidth}px` } : {}}
                    handleClickAway={handleClickAway}
                >
                    {search && (
                        <ListItem className={classes.search}>
                            <Search
                                onChange={e => setFilterValue(e.target.value)}
                                background={theme.colors.paleGrey}
                                placeholder={search}
                            />
                        </ListItem>
                    )}
                    <List className={classes.list}>{buildMenuItems(selectedOption, options, filterValue)}</List>
                    {Control && <Control handleClickAway={handleClickAway} />}
                </PopperMenu>
            )}
        </div>
    );
}

export default React.memo(Select);
