import { useSelector } from 'react-redux';

export function useExportRemovedColumns() {
    return useSelector(state => state.gridUI.exportRemovedColumns) || [];
}

export function useExportData(viewId) {
    return useSelector(state => state.gridUI.exportPreview?.[viewId]?.data);
}
export function useExportIsFetching(viewId) {
    return useSelector(state => state.gridUI.exportPreview?.[viewId]?.isFetching);
}

export function useExportRows(viewId) {
    return useSelector(state => state.gridUI.exportPreview?.[viewId]?.rows);
}
