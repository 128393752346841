import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from 'components/skeleton/Base';

function GroupListSkeleton() {
    return (
        <Grid container>
            {[...Array(6).keys()].map(index => (
                <Grid item key={index} style={{ padding: 7 }}>
                    <Skeleton variant="circle" width={48} height={48} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(GroupListSkeleton);
