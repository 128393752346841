import { combineReducers } from 'redux';
import gridUIReducer from 'gridUI/reducer';
import authReducer from 'auth/reducer';
import databaseReducer from 'databases/reducer';
import gridReducer from 'grids/reducer';
import notifierReducer from 'notifier/reducer';
import appReducer from 'app/reducer';
import addonReducer from 'addons/reducer';
import permissionReducer from 'permission/reducer';
import workspaceReducer from 'workspaces/reducer';
import versionControlReducer from 'versionControl/reducer';
import paymentReducer from 'payment/reducer';
import tmReducer from 'tm/reducer';
import dataCollectorReducer from 'dataCollector/reducer';
import integrationReducer from 'integrations/reducer';
import advancedReducer from 'advancedSearch/reducer';

export default combineReducers({
    gridUI: gridUIReducer,
    auth: authReducer,
    database: databaseReducer,
    grid: gridReducer,
    notifier: notifierReducer,
    app: appReducer,
    addons: addonReducer,
    permission: permissionReducer,
    workspace: workspaceReducer,
    versionControl: versionControlReducer,
    tm: tmReducer,
    payment: paymentReducer,
    dataCollector: dataCollectorReducer,
    integration: integrationReducer,
    advanced: advancedReducer
});
