import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as types from 'socket/types';
import { isForAll, isForYou } from 'socket/utils/room';
import * as databasesActions from 'databases/actions';
import { fetchWorkspaces } from 'workspaces/actions';

const { dispatch, getState } = store;
const projectDetailHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    const { objectType } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ state, headers })) {
        if (objectType === types.GROUP_ACCESS_VIEW) {
            const selectedWorkspaceId = state?.workspace?.selectedWorkspace?.id;
            const projectIds = body?.data?.map(item => item.projectId);

            if (projectIds.includes(selectedWorkspaceId)) {
                dispatch(databasesActions.getDatabases({ workspaceId: selectedWorkspaceId }));
            }

            dispatch(fetchWorkspaces({}));
        }
    }

    if (isForYou({ state, headers })) {
        console.log('IGNORE');
    }
};

export default projectDetailHandler;
