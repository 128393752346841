import * as appActions from 'app/actions';
import GridlyUIPage from 'gridUI';
import { useCompanyId, useCurrentUserId } from 'hooks/auth';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { VERSION_CONTROL_GRID } from 'versionControl/const';
import * as constants from './const';
import ws from './socket';
import * as versionControlActions from './versionControl/actions';

export default function GridlyUI() {
    const { workspaceId, gridId, viewId, dbId, branchId } = useParams();
    const dispatch = useDispatch();
    const ROW_START_INDEX = useSelector(state => state?.gridUI?.ROW_START_INDEX);
    const ROW_STOP_INDEX = useSelector(state => state?.gridUI?.ROW_STOP_INDEX);
    const userId = useCurrentUserId();
    const companyId = useCompanyId();
    const ROW_START_INDEX_Ref = useRef();
    const ROW_STOP_INDEX_Ref = useRef();

    useEffect(() => {
        ROW_START_INDEX_Ref.current = ROW_START_INDEX;
        ROW_STOP_INDEX_Ref.current = ROW_STOP_INDEX;
    }, [ROW_START_INDEX, ROW_STOP_INDEX]);

    dispatch(versionControlActions.inititalBranchType({ type: VERSION_CONTROL_GRID }));

    useEffect(() => {
        ws.gridlySubscribeTopics({
            companyId,
            dbId,
            viewId,
            gridId: branchId,
            parentGridId: gridId,
            userId,
            ROW_START_INDEX: ROW_START_INDEX_Ref.current,
            ROW_STOP_INDEX: ROW_STOP_INDEX_Ref.current
        });
        dispatch(
            appActions.setCurrentPage({
                currentPage: constants.GRID_UI
            })
        );
        return () => {
            ws.unsubscribeMultipleTopic();
        };
    }, [branchId, viewId, dbId, dispatch, userId, companyId, gridId]);

    return <GridlyUIPage workspaceId={workspaceId} gridId={gridId} viewId={viewId} dbId={dbId} branchId={branchId} />;
}
