import React from 'react';

function ExpandRecordHistory({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect width="24" height="24" rx="4" fill="#C9C9CE" />
            <g clipPath="url(#clip0)">
                <path
                    d="M17.4 13.8846L16.3879 14.8967L13.9499 12.4585C13.8127 12.3212 13.5902 12.3211 13.4528 12.4585L12.4582 13.4531C12.3206 13.5906 12.3212 13.8136 12.4582 13.9506L14.8965 16.3882L13.8843 17.4003C13.6638 17.6208 13.8196 18.0004 14.1329 18.0004H17.6485C17.8428 18.0004 18.0001 17.8432 18.0001 17.6488V14.1332C18.0001 13.8215 17.6221 13.6632 17.4 13.8846Z"
                    fill="#606066"
                />
                <path
                    d="M11.5419 10.0502L9.10362 7.61224L10.1157 6.60012C10.3363 6.37962 10.1804 6 9.86719 6H6.35156C6.15724 6 6 6.15724 6 6.35156V9.86719C6 10.1782 6.37706 10.338 6.60014 10.1158L7.61259 9.10399L10.0502 11.5419C10.1874 11.6792 10.41 11.6792 10.5473 11.5419L11.5419 10.5473C11.6786 10.4106 11.6797 10.188 11.5419 10.0502Z"
                    fill="#606066"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="12" height="12" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ExpandRecordHistory);
