import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { Typography, Chip, Grid } from '@material-ui/core';
import CloseIcon from 'assets/images/svg/CloseIconSVG';
import hexToRgba from 'hex-to-rgba';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { INPUT_PADDING_LEFT, INPUT_RADIUS, INPUT_HEIGHT } from 'const/style';

// import { MAX_OPTIONS } from 'const/gridUI';

import Control from './common/Control';
import DropdownIndicator from './common/DropdownIndicator';
import Menu from './common/Menu';
import MultiValue from './common/MultiValue';
import NoOptionsMessage from './common/NoOptionsMessage';
import Placeholder from './common/Placeholder';
import SingleValue from './common/SingleValue';
import ValueContainer from './common/ValueContainer';
import { AutoSizer } from 'react-virtualized-dn';
import PopperMenu from 'components/menus/Popper';
import OptionGridBranch from './common/OptionGridBranch';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import BranchSVG from 'assets/images/svg/BranchSVG';
import Tooltip from 'components/tooltip/Base';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    dropdown: {
        position: 'relative',
        minWidth: 100
    },
    control: {
        width: '100%',
        borderRadius: INPUT_RADIUS,
        border: props =>
            props.isOpen
                ? props.isHighlight
                    ? `1px solid ${theme.palette.primary.main}`
                    : `1px solid ${theme.colors.border}`
                : `1px solid ${theme.colors.border}`,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText
    },
    disabled: {
        background: theme.colors.paleGrey,
        pointerEvents: 'none'
    },
    singleValueStyle: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        '& > svg, & img': {
            minWidth: 16
        },
        '& p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    multiValueDelete: {
        display: 'flex',
        alignItems: 'center'
    },
    closeIcon: {
        color: theme.colors.secondaryText
    },
    iconCenter: {
        display: 'flex'
    },
    expand: {
        flex: 1
    },
    chip: {
        border: `1px solid ${hexToRgba(theme.colors.highlight, 0.3)}`,
        backgroundColor: hexToRgba(theme.colors.highlight, 0.1),
        marginRight: 4,
        marginTop: 2,
        marginBottom: 2,
        height: 28,
        '& .MuiAvatar-root': {
            marginLeft: 1,
            color: theme.colors.white,
            minWidth: 22,
            minHeight: 22
        },
        '& .MuiChip-avatar': {
            minWidth: 14,
            minHeight: 14
        },
        '& > svg': {
            marginLeft: theme.spacing(1)
        }
    },
    parentGridName: {
        padding: '3px 10px 3px 24px',
        background: '#F3F4FC',
        border: `1px solid #EBEBF6`,
        boxSizing: 'border-box',
        borderRadius: 3,
        width: 'fit-content',
        position: 'relative',
        '& svg': {
            position: 'absolute',
            left: 6,
            top: 4
        },
        '& p': {
            fontWeight: 500,
            color: '#7869B9',
            whiteSpace: 'break-spaces',
            ...theme.ellipsis()
        }
    }
}));

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option: OptionGridBranch,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function LDBaseGridBranch({
    ddPlaceholder = i18n.t('global_label_select_an_option'),
    menuPlaceholder = i18n.t('global_find_a_type'),
    isMulti = false,
    defaultValue,
    onChange,
    selectedColor,
    options = [],
    maxSize = 200,
    isHighlight = true,
    isCanClear = false,
    dropdownClassName,
    controlClassName,
    isDisabled,
    isUsingContainer = false,
    placement = 'bottom',
    children,
    isUsingChildren = false,
    customNode,
    iconPlaceholder,
    afterToggleOpen,
    showTooltip = false,
    ...other
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const portalRef = React.useRef();

    const handleClickAway = () => {
        setAnchorEl(null);
    };
    const toggleOpen = e => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const isOpen = Boolean(anchorEl);

    React.useEffect(() => {
        afterToggleOpen && afterToggleOpen({ isOpen });
    }, [isOpen, afterToggleOpen]);

    const classes = useStyles({ selectedColor, isOpen, isHighlight });
    const theme = useTheme();
    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        })
    };

    const handleOnChange = option => {
        onChange(option);
        setAnchorEl(null);
    };

    const handleDelete = index => {
        const cpArr = [...defaultValue];
        cpArr.splice(index, 1);
        onChange(cpArr);
    };

    const Target = () => {
        if (!defaultValue)
            return (
                <Grid
                    container
                    className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    onClick={toggleOpen}
                >
                    {iconPlaceholder && (
                        <Grid item style={{ display: 'flex', marginRight: 8 }}>
                            {iconPlaceholder}
                        </Grid>
                    )}
                    <Grid item style={{ flex: 1 }}>
                        <Typography variant="caption">{ddPlaceholder}</Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex' }}>
                        <ArrowDownSVG />
                    </Grid>
                </Grid>
            );
        if (defaultValue && isMulti)
            return (
                <Grid
                    container
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                    onClick={toggleOpen}
                >
                    <Grid item style={{ flex: 1 }}>
                        {isEmpty(defaultValue) && <Typography variant="caption">{ddPlaceholder}</Typography>}
                        {!isEmpty(defaultValue) &&
                            defaultValue.map((item, index) => {
                                const itemColor = item?.color;
                                return (
                                    <Chip
                                        avatar={item?.icon && item?.icon()}
                                        size="small"
                                        key={index}
                                        label={item.label}
                                        onDelete={() => handleDelete(index)}
                                        className={classes.chip}
                                        style={{
                                            background: itemColor ? hexToRgba(itemColor, 0.1) : ``,
                                            border: itemColor ? `1px solid ${hexToRgba(itemColor, 0.3)}` : ``
                                        }}
                                        deleteIcon={<CloseIcon size={12} />}
                                    />
                                );
                            })}
                    </Grid>
                    <Grid item className={classes.multiValueDelete}>
                        <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
                            {isCanClear && !isEmpty(defaultValue) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleOnChange([]);
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item style={{ display: 'flex' }}>
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        if (defaultValue && !isMulti)
            return (
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.control} ${controlClassName} ${isDisabled ? classes.disabled : ''}`}
                    onClick={toggleOpen}
                    wrap="nowrap"
                >
                    <Grid
                        item
                        className={classes.singleValueStyle}
                        style={{ width: `calc(100% - ${isCanClear ? 40 : 16}px)`, flexWrap: 'wrap' }}
                    >
                        <Grid container spacing={2} alignItems="center" wrap="nowrap">
                            {defaultValue.icon && (
                                <Grid item className={classes.iconCenter}>
                                    <defaultValue.icon></defaultValue.icon>
                                </Grid>
                            )}
                            <Grid
                                item
                                style={{
                                    width: '100%',
                                    height: 28
                                }}
                            >
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <Tooltip
                                            title={showTooltip ? defaultValue.parentGridName || defaultValue.label : ''}
                                        >
                                            <Typography
                                                style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    width,
                                                    height
                                                }}
                                                variant="body2"
                                                component="div"
                                            >
                                                {defaultValue.parentGridName || defaultValue.label}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </AutoSizer>
                            </Grid>
                        </Grid>
                        {defaultValue.parentGridName && (
                            <Tooltip title={defaultValue.label}>
                                <Grid container alignItems="center" wrap="nowrap" style={{ marginTop: 4 }}>
                                    <Grid item style={{ marginRight: 9, display: 'flex' }}>
                                        <ArrowRightSVG />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <Typography variant="body2" component="div" className={classes.parentGridName}>
                                            <BranchSVG color="#7869B9" />
                                            <Typography variant="body2">{defaultValue.label}</Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1} wrap="nowrap">
                            {isCanClear && !isEmpty(defaultValue) && (
                                <Grid item style={{ display: 'flex' }}>
                                    <CloseIcon
                                        className={classes.closeIcon}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleOnChange(null);
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item style={{ display: 'flex' }}>
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
    };

    const filterOptions = inputValue => {
        const filterValues = options.filter(
            i =>
                i.label.toLowerCase().includes(inputValue?.toLowerCase()) ||
                (i.parentGridName && i.parentGridName.toLowerCase().includes(inputValue?.toLowerCase()))
        );
        return filterValues.slice(0, maxSize);
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(filterOptions(inputValue));
        }, 300);
    };

    return (
        <>
            <Grid
                onClick={toggleOpen}
                className={`${classes.dropdown} ${dropdownClassName} ${isDisabled ? classes.disabled : ``}`}
            >
                {isUsingChildren ? children : <Target />}
            </Grid>
            <div className={'childPortal'} ref={portalRef}></div>
            {anchorEl && (
                <PopperMenu
                    className={'popper'}
                    style={{ width: 360 }}
                    anchorEl={anchorEl}
                    handleClickAway={handleClickAway}
                    container={isUsingContainer ? portalRef.current : null}
                    placement={placement}
                >
                    {customNode ? (
                        customNode
                    ) : (
                        <AsyncSelect
                            cacheOptions
                            autoFocus
                            defaultOptions={options.slice(0, maxSize)}
                            loadOptions={loadOptions}
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            placeholder={menuPlaceholder}
                            isClearable={false}
                            tabSelectsValue={false}
                            components={components}
                            defaultValue={defaultValue}
                            styles={selectStyles}
                            onChange={handleOnChange}
                            classes={classes}
                            menuIsOpen
                            isMulti={isMulti}
                            handleClickAway={handleClickAway}
                            showTooltip={showTooltip}
                            {...other}
                        />
                    )}
                </PopperMenu>
            )}
        </>
    );
}

LDBaseGridBranch.propTypes = {
    ddPlaceholder: PropTypes.string,
    menuPlaceholder: PropTypes.string,
    isMulti: PropTypes.bool,
    defaultValue: PropTypes.any
};

export default React.memo(LDBaseGridBranch);
