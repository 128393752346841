import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { INPUT_HEIGHT } from 'const/style';
import Checkbox from 'components/checkbox/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    datetime: {
        width: '100%',
        height: INPUT_HEIGHT,
        border: `1px solid ${theme.colors.border}`,
        padding: `6px ${theme.spacing(3)}px`,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white
    },
    checkbox: {
        marginRight: theme.spacing(2)
    }
}));

function Boolean({ defaultValue, onChange }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const value = React.useMemo(() => {
        return typeof defaultValue === 'boolean' ? defaultValue : false;
    }, [defaultValue]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <p className="body1 inline">{t('global_default_value')}</p>{' '}
                <p className="caption inline">({t('global_optional')})</p>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" direction="row" className={classes.datetime}>
                    <Grid item className={classes.checkbox}>
                        <Checkbox
                            onChange={e => onChange(e?.target?.checked)}
                            checked={value}
                            style={{ maxWidth: 24, maxHeight: 24 }}
                        />
                    </Grid>
                    <Grid item>
                        <p className="body2">{value ? 'Checked' : 'Unchecked'}</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Boolean;
