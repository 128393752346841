import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails, IconButton } from '@material-ui/core';
import Divider from 'components/divider/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import moment from 'moment';
import * as roleConst from 'auth/roleConst';
import { useTranslation } from 'react-i18next';
import { useWorkspaceRole } from 'hooks/auth/role';
import { getDateUTC } from 'utils/datetime';
import Tooltip from 'components/tooltip/Base';

const useStyles = makeStyles(theme => ({
    root: {
        '&.MuiAccordion-root': {
            margin: 0,
            boxShadow: 'none',
            '&:before': {
                backgroundColor: 'transparent'
            }
        }
    },
    panelSummary: {
        padding: '10px 30px',
        '&.MuiAccordionSummary-root': {
            minHeight: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        }
    },
    panelDetail: {
        padding: '8px 27px 8px 55px',
        '&:hover': {
            backgroundColor: theme.colors.hoverItem
        }
    },
    icon: {
        width: 24,
        height: 24,
        transform: props => !props.expanded && 'rotate(-90deg)',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shortest
        })
    },
    shortLine: {
        marginLeft: 24,
        marginRight: 24
    }
}));

function Expansion({ date, timeMapByDate, onPopupOpen, iconMoreDisabled, iconMoreTooltip }) {
    const [expanded, setExpanded] = React.useState(true);
    const classes = useStyles({ expanded });
    const { t } = useTranslation();
    const workspaceRole = useWorkspaceRole();

    const isCreator = React.useMemo(() => {
        return workspaceRole === roleConst.CREATOR;
    }, [workspaceRole]);

    return (
        <Accordion expanded={expanded} className={classes.root}>
            <AccordionSummary className={classes.panelSummary}>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Grid item style={{ display: 'flex' }}>
                        <ArrowDownSVG className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">{moment(date).format('MMMM Do YYYY')}</Typography>
                    </Grid>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Typography variant="caption">
                            {timeMapByDate[date].length} {t(`global_backups`)}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <Divider className={classes.shortLine} />
            {timeMapByDate[date].map((file, index) => (
                <AccordionDetails key={index} className={classes.panelDetail}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body2">{getDateUTC({ date: file.alteredTime })} UTC</Typography>
                        </Grid>
                        {isCreator && (
                            <Tooltip title={iconMoreTooltip || ''}>
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        disabled={iconMoreDisabled}
                                        onClick={e =>
                                            onPopupOpen({
                                                target: e.target,
                                                backupFile: file,
                                                backupTime: file.alteredTime
                                            })
                                        }
                                    >
                                        <IconMoreActionsSVG />
                                    </IconButton>
                                </Grid>
                            </Tooltip>
                        )}
                    </Grid>
                </AccordionDetails>
            ))}
        </Accordion>
    );
}

export default React.memo(Expansion);
