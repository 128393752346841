import * as types from '../types';

const handler = {
    [types.FETCH_USER_FACTOR](state, { payload }) {
        const { userFactor } = payload;
        return { ...state, userFactor };
    }
};

export default handler;
