import React from 'react';

function TranslationOverviewSVG({ color = '#17B7FF', width = 16, height = 16, ...other }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...other}
        >
            <path
                d="M0 9.375C0 4.19733 4.19733 0 9.375 0H90.625C95.8027 0 100 4.19733 100 9.375V90.625C100 95.8027 95.8027 100 90.625 100H9.375C4.19733 100 0 95.8027 0 90.625V9.375Z"
                fill="#4D3F8A"
            />
            <rect x="12.5" y="64.0625" width="22.9167" height="17.1875" rx="1" fill="#92C255" />
            <rect x="39.2361" y="64.0625" width="22.9167" height="17.1875" rx="1" fill="#F6BD42" />
            <rect x="65.9722" y="64.0625" width="22.9167" height="17.1875" rx="1" fill="#EE727E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.2253 16.7383C41.8105 16.7383 42.2987 17.1533 42.4116 17.7051L42.4362 17.9492V22.6154H52.2613C52.8465 22.6154 53.3347 23.0305 53.4476 23.5823L53.4722 23.8263V25.8481C53.4722 26.4333 53.0571 26.9216 52.5053 27.0345L52.2613 27.0591H42.3606C43.3666 30.8289 45.6929 34.4692 49.3398 37.9799C49.6311 38.2603 49.7594 38.6631 49.6944 39.052L49.632 39.2827L48.9428 41.0945C48.8762 41.2698 48.7697 41.4272 48.6318 41.5543C48.1401 42.0076 47.374 41.9765 46.9207 41.4847C43.804 38.1041 41.7801 35.2785 40.8488 33.0079C39.2477 37.1191 35.4502 41.2664 29.4564 45.4496C29.3682 45.5112 29.2721 45.5608 29.1707 45.597C28.6195 45.7939 28.02 45.5672 27.7281 45.0854L27.6229 44.8638L26.8907 42.814C26.7005 42.2816 26.9039 41.6884 27.381 41.3851C33.757 37.3254 37.1881 33.0756 37.6741 28.6359L37.7733 28.1286L37.9009 27.1307H27.7734C27.1883 27.1307 26.7 26.7157 26.5871 26.1639L26.5625 25.9198V23.898C26.5625 23.3128 26.9776 22.8246 27.5294 22.7117L27.7734 22.6871H37.6741V17.9492C37.6741 17.364 38.0892 16.8758 38.641 16.7629L38.885 16.7383H41.2253ZM61.5454 24.8112C63.1995 24.8112 64.6878 25.7736 65.3768 27.2505L65.532 27.6302L74.4295 52.7973C74.4753 52.9269 74.4987 53.0634 74.4987 53.2009C74.4987 53.7861 74.0836 54.2744 73.5318 54.3873L73.2878 54.4119H71.3856C70.9399 54.4119 70.5368 54.168 70.3263 53.7879L70.2378 53.5871L67.5254 45.5245H55.5655L52.853 53.5871C52.7109 54.0095 52.3512 54.3137 51.9239 54.392L51.7053 54.4119H49.803C49.6655 54.4119 49.5291 54.3885 49.3994 54.3426C48.8477 54.1476 48.5257 53.5935 48.6032 53.0356L48.6614 52.7973L57.5588 27.6302C58.1561 25.9407 59.7535 24.8112 61.5454 24.8112ZM57.0604 41.0809L61.5454 29.2549L66.0303 41.1525L57.0604 41.0809Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(TranslationOverviewSVG);
