import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { COLOR_TYPES } from 'const';

function ConfirmBox({
    title = 'Default Title',
    body,
    handleCancel,
    handleAgreed,
    disagreeLabel,
    agreeLabel = 'Merge',
    className,
    onClose,
    isLoading,
    colorType = COLOR_TYPES.PRIMARY,
    agreeWidth = 120,
    disagreeWidth = 120,
    action,
    width,
    buttonRightClassName,
    ...rest
}) {
    const { t } = useTranslation();

    console.log('agreeWidth', agreeWidth);
    return (
        <>
            <DialogTitle title={<h4 className="prose prose-lg font-medium">{title}</h4>} onClose={onClose} />
            <DialogContent>
                <div className="w-[440px] relative focus:none" style={{ width }}>
                    {typeof body === 'function' ? (
                        body()
                    ) : typeof body === 'string' ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(body, { USE_PROFILES: { html: true } })
                            }}
                        ></div>
                    ) : (
                        body
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                {action ? (
                    action
                ) : (
                    <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                        {handleCancel && (
                            <Grid item>
                                <ButtonBase onClick={handleCancel} width={disagreeWidth} variant="outlined">
                                    {disagreeLabel || t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                        )}
                        <Grid item>
                            <div className={'relative'}>
                                <ButtonBase
                                    className={classNames(`[&>svg]:ml-2  ${buttonRightClassName}`)}
                                    onClick={handleAgreed}
                                    width={agreeWidth}
                                    disabled={isLoading}
                                    variant="contained"
                                    color={colorType}
                                >
                                    {agreeLabel}
                                </ButtonBase>
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        className={'text-text-primary absolute top-[50%] left-[50%] -my-3 -ml-3'}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                )}
            </DialogActions>
        </>
    );
}
export default React.memo(ConfirmBox);
