import request from './request';
import { APP_API_PREFIX_ORGANIZATION } from '../config';

const groupApis = {
    groups: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups`,
    groupsGraphql: () => `${APP_API_PREFIX_ORGANIZATION}/graphql`,
    group: ({ companyId, groupId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}`,
    grantAccessToWorkspace: ({ companyId, groupId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/workspaces`,
    ungrantAccessToWorkspace: ({ companyId, groupId, workspaceId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/workspaces/${workspaceId}`,
    access: ({ companyId, groupId, workspaceId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/database-access?workspaceId=${workspaceId}`,
    members: ({ companyId, groupId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/users`,
    getGroupWorkspacesAccess: ({ companyId, groupId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/groups/${groupId}/access`,
    uploadAvaterGroup: ({ groupId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/media/group-avatar?file=&entityId=${groupId}`,
    upgrantAccessWorkspace: ({ projectId, companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/projects/${projectId}/groups`
};

export async function uploadGroupAvatarApi({ file, groupId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(groupApis.uploadAvaterGroup({ groupId }), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function updateGroupWorkspacesAccessApi({ groupId, companyId, body }) {
    const response = await request.put(`${groupApis.getGroupWorkspacesAccess({ companyId, groupId })}`, body);
    return response.data;
}

export async function getGroupWorkspacesAccessApi({ groupId, companyId }) {
    const response = await request.get(`${groupApis.getGroupWorkspacesAccess({ companyId, groupId })}`);
    return response.data;
}

export async function createGroupWithAccessApi({ body, companyId }) {
    const response = await request.post(`${groupApis.groups({ companyId })}?addAccessView`, body);
    return response.data;
}

export async function getCompanyGroupsApi() {
    const query = `query{
        groups{
          accessViewCount
          id
          memberCount
          name
          systemGroup
          type
        }
      }`;

    const data = {
        query,
        operationName: null
    };
    const response = await request.post(groupApis.groupsGraphql(), data);
    return response.data;
}

export async function getAllGroupsApi() {
    const query = `query{
        groups{
          companyId
          id
          name
          systemGroup
          type
          logoUrl
        }
    }`;

    const data = {
        query,
        operationName: null
    };
    const response = await request.post(groupApis.groupsGraphql(), data);
    return response.data;
}

export async function createGroupApi({ companyId, group }) {
    const response = await request.post(groupApis.groups({ companyId }), group);
    return response.data;
}

export async function deleteGroupApi({ companyId, groupId }) {
    const response = await request.delete(groupApis.group({ companyId, groupId }));
    return response.data;
}

export async function updateGroupApi({ companyId, group }) {
    const response = await request.put(groupApis.group({ companyId, groupId: group.id }), group);
    return response.data;
}

export async function grantToWorkspaceApi({ companyId, groupId, workspaceId }) {
    const response = await request.post(groupApis.grantAccessToWorkspace({ companyId, groupId }), {
        workspaceIds: [workspaceId]
    });
    return response.data;
}

export async function ungrantToWorkspaceApi({ companyId, data, workspaceId }) {
    const response = await request.delete(groupApis.upgrantAccessWorkspace({ projectId: workspaceId, companyId }), {
        data
    });
    return response.data;
}

export async function fetchGroupWorkspaceAccessApi({ companyId, groupId, workspaceId }) {
    const response = await request.get(groupApis.access({ companyId, groupId, workspaceId }));
    return response.data;
}

export async function fetchGroupMembersApi({ companyId, groupId }) {
    const response = await request.get(groupApis.members({ companyId, groupId }));
    return response.data;
}

export async function getViewGroupsApi({ viewId, workspaceId, dbId, gridId }) {
    const query = `query($dbId: String, $gridId: String, $viewId: String, $workspaceId: Long){
        groups(dbId: $dbId, accessView: $viewId, gridId: $gridId){
            id,
            name,
            systemGroup
            type
            members{
                id
            }
        }
        workspace(workspaceId: $workspaceId){
            id
            workspaceMembers(dbId: $dbId, gridId: $gridId, viewId: $viewId){
                role{
                    id
                    name
                }
                user{
                    id
                    firstName
                    lastName
                    email
                    imageUrl
                    ldUser
                    ldManager
                    state
                }
            }
        }
        workspaceRoles{
            id
            name
        }
    }`;

    const data = {
        query,
        variables: {
            dbId,
            gridId,
            viewId,
            workspaceId
        },
        operationName: null
    };
    const response = await request.post(groupApis.groupsGraphql(), data);
    return response?.data?.data;
}

export async function getSingleGroupApi({ workspaceId, groupId }) {
    const query = `query($workspaceId: Long, $groupIds: [String]){
        workspace(workspaceId: $workspaceId){
          groups(groupIds: $groupIds){
            members{
              role{
                id
                name
              }
              user{
                id
                firstName
                lastName
                email
                ldUser
                ldManager
              }
            }
          }
        }
      }`;

    const data = {
        query,
        variables: {
            workspaceId: Number(workspaceId),
            groupIds: [groupId]
        },
        operationName: null
    };
    const response = await request.post(groupApis.groupsGraphql(), data);
    return response?.data?.data;
}
