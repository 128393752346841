import React from 'react';

function DropdownSVG({ color = '#78778B', size = 24, ...other }) {
    return (
        <svg {...other} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.40625 8L12 12.962L16.5937 8L18 9.51899L12 16L6 9.51899L7.40625 8Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(DropdownSVG);
