import React, { useState } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { useWorkspaceList, useSelectedWorkspace, useRegionList } from 'hooks/workspace';
import * as workspaceActions from 'workspaces/actions';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { getFriendlyTime } from 'utils/datetime';
import { useRole as useRoleAsAuthorities } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import CardInfo from 'components/cardInfo/Base';
import { getAvatarUrl } from 'utils/images';
import AvatarBase from 'components/avatar/Base';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { useTranslation } from 'react-i18next';
import { isExternal } from 'config';
// import { useSelectedWorkspaceLocalesOptions, useLanguageOptions } from 'hooks/workspace';
// import LDSelectBase from 'components/selects/LDBase';
// import { USAGE_PROPERTIES, PLAN_TYPES } from 'const';
// import { useDefaultThresHoldValueByProperty, useCurrentSubscription } from 'hooks/payment';
// import ConfirmDeleteLocale from './ConfirmDeleteLocale';
// import ModalBase from 'components/modal/Base';
// import LimitPopup from 'payment/components/popups/LimitPopup';

// const useStyles = makeStyles(theme => ({
//     root: {},
//     menuClassName: {
//         '& > div': {
//             maxHeight: 200
//         }
//     }
// }));

function AboutContent() {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const workspaces = useWorkspaceList();
    const selectedWorkspace = useSelectedWorkspace();
    const authorities = useRoleAsAuthorities();
    const [workspaceName, setWorkspaceName] = useState('');
    const [workspaceDescription, setWorkspaceDescription] = useState('');
    const regions = useRegionList();
    // const selectedLocales = useSelectedWorkspaceLocalesOptions();
    // const langOptions = useLanguageOptions();
    // const MAX_LOCALES = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.LOCALES_PER_PROJECT);
    // const [openUpgradeModal, setOpenUpgradeModal] = React.useState(false);
    // const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    // const changesOptionsRef = React.useRef([]);
    // const currentSubscription = useCurrentSubscription();

    // const handleOpenUpgradeModal = () => setOpenUpgradeModal(true);
    // const handleCloseUpgradeModal = () => setOpenUpgradeModal(false);

    // const handleOpenDeleteConfirmModal = () => setOpenDeleteConfirm(true);
    // const handleCloseDeleteConfirmModal = () => setOpenDeleteConfirm(false);

    React.useEffect(() => {
        setWorkspaceName(selectedWorkspace?.name);
        setWorkspaceDescription(selectedWorkspace?.description);
    }, [selectedWorkspace]);

    function handleUpdateWorkspaceDetail() {
        if (selectedWorkspace?.name === workspaceName && selectedWorkspace?.description === workspaceDescription) {
            return;
        }
        dispatch(
            workspaceActions.updateWorkspace({
                newWorkspace: {
                    ...selectedWorkspace,
                    name: workspaceName,
                    description: workspaceDescription
                },
                oldWorkspace: cloneDeep(workspaces),
                successCallback: () => {
                    console.log('update project successfully');
                },
                errorCallback: () => {
                    console.log('failed to update project');
                }
            })
        );
    }

    // const handleLocalesChange = React.useCallback(
    //     options => {
    //         changesOptionsRef.current = options;
    //         const optionLength = options?.length;
    //         const selectedOptionLength = selectedLocales?.length;

    //         if (optionLength > MAX_LOCALES) {
    //             handleOpenUpgradeModal(true);
    //             return;
    //         } else if (optionLength < selectedOptionLength) {
    //             handleOpenDeleteConfirmModal(true);
    //             return;
    //         }

    //         dispatch(
    //             workspaceActions.updateWorkspace({
    //                 oldWorkspace: selectedWorkspace,
    //                 newWorkspace: {
    //                     ...selectedWorkspace,
    //                     locales: options?.map(opt => opt?.value)
    //                 },
    //                 successCallback: () => {
    //                     console.log('update locale success');
    //                 },
    //                 errorCallback: () => {
    //                     console.log('update locales failed');
    //                 }
    //             })
    //         );
    //     },
    //     [MAX_LOCALES, selectedLocales, dispatch, selectedWorkspace]
    // );

    // const handleDeleteLocale = () => {
    //     dispatch(
    //         workspaceActions.updateWorkspace({
    //             oldWorkspace: selectedWorkspace,
    //             newWorkspace: {
    //                 ...selectedWorkspace,
    //                 locales: changesOptionsRef?.current?.map(opt => opt?.value)
    //             },
    //             successCallback: () => {
    //                 console.log('delete locale success');
    //             },
    //             errorCallback: () => {
    //                 console.log('delete locales failed');
    //             }
    //         })
    //     );
    //     handleCloseDeleteConfirmModal();
    // };

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">{t('edit_project_name_field_label')}</Typography>
                    </Grid>
                    <Grid item>
                        <InputText
                            name="workspaceName"
                            value={workspaceName || ''}
                            onChange={e => setWorkspaceName(e.target.value)}
                            onBlur={handleUpdateWorkspaceDetail}
                            height={40}
                            autoFocus={true}
                            placeholder={t('project_name_placeholder')}
                            disabled={authorities?.[roleConst?.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] !== roleConst.FULL}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">{t('edit_project_description_field_label')}</Typography>
                    </Grid>
                    <Grid item>
                        <InputText
                            name="workspaceName"
                            value={workspaceDescription || ''}
                            onChange={e => setWorkspaceDescription(e.target.value)}
                            onBlur={handleUpdateWorkspaceDetail}
                            height={155}
                            rows={6}
                            placeholder={t('edit_project_description_field_placeholder')}
                            disabled={authorities?.[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] !== roleConst.FULL}
                            multiline
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!isExternal && (
                <Grid item container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="body1">{t('project_region')}</Typography>
                    </Grid>
                    <Grid item>
                        <RadioGroup row name="region" value={selectedWorkspace?.region}>
                            {regions.map(region => (
                                <FormControlLabel
                                    key={region?.name}
                                    value={region?.name}
                                    control={<Radio disabled size="small" />}
                                    label={<Typography variant="body2">{region?.description}</Typography>}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                </Grid>
            )}

            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">{t('project_author_label')}</Typography>
                    </Grid>
                    <Grid item>
                        <CardInfo
                            avatar={
                                <AvatarBase src={getAvatarUrl(selectedWorkspace?.audit?.created)}>
                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                </AvatarBase>
                            }
                            title={selectedWorkspace?.audit?.createdName}
                            subTitle={
                                <>
                                    <Typography display="inline" variant="caption">
                                        {t('project_author_created_on')}{' '}
                                    </Typography>
                                    <Typography display="inline" variant="body2">
                                        {getFriendlyTime(selectedWorkspace?.audit?.createdDate)}
                                    </Typography>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">
                            Locales ({selectedLocales?.length}/
                            {MAX_LOCALES === Number.MAX_SAFE_INTEGER ? 'Infinity' : MAX_LOCALES})
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LDSelectBase
                            ddPlaceholder={'Select Locales'}
                            menuPlaceholder={'Find a locale'}
                            options={langOptions}
                            onChange={handleLocalesChange}
                            defaultValue={selectedLocales}
                            isMulti={true}
                            menuClassName={classes.menuClassName}
                        />
                    </Grid>
                </Grid>
            </Grid> */}

            {/* <ModalBase open={openDeleteConfirm} handleClose={handleCloseDeleteConfirmModal}>
                <ConfirmDeleteLocale
                    lang={'deDE'}
                    handleAgreed={handleDeleteLocale}
                    handleCancel={handleCloseDeleteConfirmModal}
                />
            </ModalBase>
            <ModalBase open={openUpgradeModal} handleClose={handleCloseUpgradeModal}>
                <LimitPopup
                    title={`Upgrade for more locales`}
                    message={`Your ${currentSubscription?.plan?.name ||
                        PLAN_TYPES.FREE} Project can only select <strong>${MAX_LOCALES} locales</strong>. Please upgrade your Project to a higher plan to
select more locales`}
                    onCancel={handleCloseUpgradeModal}
                    onUpgrade={handleCloseUpgradeModal}
                    onClose={handleCloseUpgradeModal}
                />
            </ModalBase> */}
        </Grid>
    );
}

export default AboutContent;
