import React from 'react';

function AutomationSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip_automation)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.75009 0.0414174C9.01863 0.143938 9.18351 0.415469 9.15061 0.701031L8.54802 5.93139H12.9483C13.1805 5.93139 13.3938 6.05929 13.5032 6.26409C13.6125 6.46888 13.6002 6.71728 13.471 6.91022L7.57428 15.7208C7.41197 15.9633 7.10417 16.0623 6.8309 15.96C6.55763 15.8576 6.39066 15.5807 6.42765 15.2912L7.13134 9.78355L2.60507 9.61132C2.37456 9.60255 2.16734 9.4683 2.06512 9.26149C1.9629 9.05469 1.9821 8.80853 2.11513 8.62006L8.01189 0.266314C8.17766 0.0314767 8.48154 -0.0611028 8.75009 0.0414174ZM3.81145 8.39834L7.86632 8.55263C8.04274 8.55934 8.20821 8.63988 8.32233 8.77459C8.43644 8.90929 8.4887 9.08576 8.46632 9.26088L8.01385 12.8023L11.7705 7.18936H7.84241C7.66305 7.18936 7.49223 7.1128 7.37288 6.97891C7.25354 6.84503 7.19702 6.66656 7.21755 6.48839L7.61894 3.00439L3.81145 8.39834Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip_automation">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(AutomationSVG);
