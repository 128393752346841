import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';
import InviteEmailsInput from './InviteEmailsInput';
import ButtonBase from 'components/button/Base';
import {
    DEFAULT_PROJECT_ROLE_EDITOR,
    DEFAULT_ROLE_ADMIN,
    DEFAULT_ROLE_USER,
    GROUP_ADMIN_TYPES,
    MAX_SEAT_INPUT,
    PAYMENT_METHODS,
    PLAN_CYCLE,
    PLAN_TYPES,
    SUBSCRIPTION_STATUS
} from 'const';
import InviteMembersNoData from './InviteMembersNoData';
import { useMapWorkspaceRolesByName } from 'hooks/workspace';
import InviteMembersTable from './InviteMembersTable';
import { useCardInfo, useCompanySubscription, useCurrentSubscriptionIs, useIsProActive } from 'hooks/payment';
import * as roleConst from 'auth/roleConst';
import { debounce } from 'lodash';
import { getChangePlanPreviewApi } from 'services/payment';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import * as paymentActions from 'payment/actions';
import ChangeSeat from 'payment/setting/overview/ChangeSeat';
import { sendManualTrack } from 'tracker';
import { useGroupOptions } from 'hooks/permission';
import * as inviteActions from 'permission/actions/invite';
import { useRole } from 'hooks/auth/role';
import { bulkInviteApi, checkInviteUsersApi } from 'services/company';
import { useCompanyId } from 'hooks/auth';
import InvitationConfirm from '../multiInvitation/InvitationConfirm';
import PaymentGuide from '../multiInvitation/PaymentGuide';
import LimitPopup from 'payment/components/popups/LimitPopup';
import { useHistory } from 'react-router-dom';
import BulkInviteMember from './BulkInviteMember';
import { fetchCustomerAndSubscriptions } from 'payment/actions';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    flx: { flex: 1 },
    mt3: { marginTop: 3 },
    mt20: { marginTop: 20 },
    mt30: { marginTop: 30 },
    mt32: { marginTop: 32 },
    dialogContent: {
        paddingBottom: 0
    },
    dialogAction: {
        border: 0
    },
    addMoreSeat: {
        color: theme.colors.highlight,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    contentRoot: {
        minHeight: 550,
        maxHeight: 700
    },
    contentWrapper: {
        flex: 1
    },
    unavailableSeat: {
        color: theme.colors.burntSlena
    }
}));

const InviteMembersForm = ({ isLdManager, setPaperFullWidth, onClose, navigateToSuccess }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const emailsInputRef = useRef();
    const workspaceRolesByName = useMapWorkspaceRolesByName();
    const groupOptions = useGroupOptions();
    const companyId = useCompanyId();
    const card = useCardInfo();
    const roles = useRole();
    const companySubscription = useCompanySubscription();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedBulkInviteEmails, setSelectedBulkInviteEmails] = useState([]);
    const [changePlanPreview, setChangePlanPreview] = useState(null);
    const [proSeat, setProSeat] = useState(0);
    const [openChangeSeat, setOpenChangeSeat] = useState(false);
    const [openAddSeat, setOpenAddSeat] = useState(false);
    const [openCardWarning, setOpenCardWarning] = useState(false);
    const [openOutOfFreeSeats, setOpenOutOfFreeSeats] = useState(false);
    const [openInviteCSV, setOpenInviteCSV] = useState(false);
    const moreSeats = useRef(0);
    const isEnterprise = useCurrentSubscriptionIs(PLAN_TYPES.ENTERPRISE);
    const isProActive = useIsProActive();
    const isCanceling =
        companySubscription?.nextPlan === PLAN_TYPES.FREE &&
        companySubscription?.plan !== PLAN_TYPES.FREE &&
        companySubscription?.status === SUBSCRIPTION_STATUS.ACTIVE;
    const isValidSeat = Boolean(proSeat) && proSeat >= companySubscription?.activeSeat;

    const canManageBilling = useMemo(() => {
        return (
            roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING] === roleConst.FULL &&
            (isProActive || isEnterprise) &&
            !isCanceling
        );
    }, [isCanceling, isEnterprise, isProActive, roles]);

    const fetchChangePlanPreview = useCallback(
        debounce(async ({ totalSeat, annually, currency }) => {
            try {
                const preview = await getChangePlanPreviewApi({ totalSeat, annually, currency });
                setChangePlanPreview(preview);
            } catch ({ message }) {
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
            }
        }, 500),
        [dispatch]
    );

    useEffect(() => {
        setProSeat(companySubscription?.totalSeat);
    }, [companySubscription]);

    useEffect(() => {
        if (!proSeat) return;
        fetchChangePlanPreview({
            totalSeat: proSeat,
            annually: companySubscription?.cycle === PLAN_CYCLE.year,
            currency: companySubscription.seatCurrency
        });
    }, [proSeat, fetchChangePlanPreview, companySubscription, openChangeSeat]);

    const availableSeats = useMemo(() => {
        return companySubscription?.availableSeat;
    }, [companySubscription]);

    const onMapDefaultEmails = useCallback(
        emails => {
            return emails.map(email => ({
                email,
                roleId: DEFAULT_ROLE_USER.id,
                groupIds: [],
                defaultProjectRoleId: workspaceRolesByName[roleConst.EDITOR]?.id || DEFAULT_PROJECT_ROLE_EDITOR.id,
                languagePairs: [{ from: 'all', to: 'all' }],
                isTranslator: false
            }));
        },
        [workspaceRolesByName]
    );

    const onAdd = useCallback(() => {
        if (emailsInputRef.current) {
            setSelectedEmails(prev => [
                ...prev,
                ...onMapDefaultEmails(emailsInputRef.current?.getValidSelectedEmails() || [])
            ]);
            emailsInputRef.current.reset();
        }
    }, [onMapDefaultEmails]);

    const handleOpenChangeSeatModal = useCallback(() => {
        setPaperFullWidth(false);
        setProSeat(companySubscription?.totalSeat);
        setOpenChangeSeat(true);
    }, [companySubscription, setPaperFullWidth]);

    const handleCloseChangeSeatModal = useCallback(
        ({ resetProseat = true }) => {
            if (resetProseat) {
                setProSeat(companySubscription?.totalSeat);
            }
            setPaperFullWidth(true);
            setOpenChangeSeat(false);
        },
        [companySubscription, setPaperFullWidth]
    );

    const handleChangeSeat = useCallback(() => {
        setIsSubmitting(true);
        dispatch(
            paymentActions.changePlan({
                subscriptionId: companySubscription?.id,
                totalSeat: proSeat,
                successCallbackFetchCustomer: () => {
                    setIsSubmitting(false);
                    handleCloseChangeSeatModal({ resetProseat: false });
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                }
            })
        );
    }, [companySubscription, dispatch, handleCloseChangeSeatModal, proSeat]);

    const handleChangeProSeat = useCallback(e => {
        if (e.target.value > MAX_SEAT_INPUT) {
            setProSeat(MAX_SEAT_INPUT);
            return;
        }
        setProSeat(parseInt(e.target.value));
    }, []);

    const handleOpenAddSeat = useCallback(
        newSeats => {
            moreSeats.current = newSeats;
            setIsLoading(false);
            setPaperFullWidth(false);
            setOpenAddSeat(true);
        },
        [setPaperFullWidth]
    );

    const handleCloseAddSeat = useCallback(() => {
        if (isLoading) return;
        moreSeats.current = 0;
        setPaperFullWidth(true);
        setOpenAddSeat(false);
    }, [isLoading, setPaperFullWidth]);

    const handleOpenCardWarning = useCallback(() => {
        setPaperFullWidth(false);
        setOpenCardWarning(true);
    }, [setPaperFullWidth]);

    const handleCloseCardWarning = useCallback(() => {
        setPaperFullWidth(true);
        setOpenCardWarning(false);
    }, [setPaperFullWidth]);

    const handleOpenOutOfFreeSeats = useCallback(() => {
        setPaperFullWidth(false);
        setOpenOutOfFreeSeats(true);
    }, [setPaperFullWidth]);

    const handleCloseOutOfFreeSeats = useCallback(() => {
        setPaperFullWidth(true);
        setOpenOutOfFreeSeats(false);
    }, [setPaperFullWidth]);

    const handleBulkInvite = useCallback(async () => {
        setIsLoading(true);
        const validBulkUsers = selectedBulkInviteEmails.filter(preview => !preview?.validation?.invalid);
        try {
            await bulkInviteApi({ companyId, body: validBulkUsers });
            dispatch(fetchCustomerAndSubscriptions({}));
            dispatch(
                enqueueSnackbar({
                    message: 'Invitations sent',
                    type: 'info'
                })
            );
            navigateToSuccess();
        } catch (error) {
            console.log('bulkInvite failed');
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'info'
                })
            );
            setIsLoading(false);
        }
        setIsSubmitting(false);
    }, [companyId, dispatch, navigateToSuccess, selectedBulkInviteEmails]);

    const handleInvite = useCallback(() => {
        if (openInviteCSV) {
            return handleBulkInvite();
        }
        const adminGroupId = groupOptions?.find(group => group.type === GROUP_ADMIN_TYPES.ADMIN)?.id;
        const body = selectedEmails.map(member => {
            const isAdminRole = member.roleId === DEFAULT_ROLE_ADMIN.id;
            return {
                email: member?.email?.trim(),
                groupIds: isAdminRole ? [adminGroupId] : member.groupIds,
                roleId: member.roleId,
                defaultProjectRoleId: member?.defaultProjectRoleId,
                languagePairs: member.isTranslator && !isAdminRole ? member.languagePairs : []
            };
        });
        const inviteFunc = isLdManager
            ? inviteActions.inviteInternalMembersToCompany
            : inviteActions.inviteMembersToCompany;
        if (isLdManager) {
            sendManualTrack({ type: `Invite Member` });
        } else {
            sendManualTrack({
                type: `Invite Member`,
                customData: {
                    emails: selectedEmails.map(member => member.email)
                }
            });
        }
        setIsLoading(true);
        dispatch(
            inviteFunc({
                body,
                successCallback: () => {
                    navigateToSuccess();
                },
                errorCallback: () => {
                    console.log('failed to invite members');
                    setIsLoading(false);
                }
            })
        );
    }, [dispatch, groupOptions, handleBulkInvite, isLdManager, navigateToSuccess, openInviteCSV, selectedEmails]);

    const handleSubmit = () => {
        if (isLdManager) {
            return handleInvite();
        }
        if (!canManageBilling || companySubscription?.status === SUBSCRIPTION_STATUS?.TRIALING) {
            return handleInvite();
        }

        setIsLoading(true);
        dispatch(
            paymentActions.fetchCustomerAndSubscriptions({
                successCallback: async customer => {
                    setIsSubmitting(false);
                    console.log('fetchCustomerAndSubscriptions Success');
                    const validEmails = openInviteCSV
                        ? selectedBulkInviteEmails.filter(
                              member => !member?.validation?.invalid && !member?.validation?.userInvited
                          )
                        : selectedEmails;
                    if (
                        validEmails.length <= customer.availableSeat ||
                        companySubscription?.paymentMethod === PAYMENT_METHODS.INVOICE
                    ) {
                        return handleInvite();
                    }

                    if (companySubscription?.plan === PLAN_TYPES.FREE) {
                        return handleOpenOutOfFreeSeats();
                    }

                    if (card) {
                        const emails = validEmails?.map(member => member?.email?.trim());
                        const response = await checkInviteUsersApi({
                            companyId,
                            body: {
                                emails
                            }
                        });
                        const newSeats = response?.newSeats;
                        if (newSeats === 0) {
                            return handleInvite();
                        }
                        handleOpenAddSeat(newSeats);
                        return;
                    }
                    handleOpenCardWarning();
                },
                errorCallback: () => {
                    setIsSubmitting(false);
                    console.log('fetchCustomerAndSubscriptions failed');
                }
            })
        );
    };

    const handleUpgrade = useCallback(() => {
        onClose();
        history.push(`/company-settings/billing/overview`);
    }, [history, onClose]);

    const onOpenBulkInvite = useCallback(() => {
        setOpenInviteCSV(true);
    }, []);

    if (openChangeSeat) {
        return (
            <Grid item style={{ maxWidth: 444 }}>
                <ChangeSeat
                    onClose={handleCloseChangeSeatModal}
                    onConfirm={handleChangeSeat}
                    companySubscription={companySubscription}
                    isSubmitting={isSubmitting}
                    proSeat={proSeat}
                    onChangeProSeat={handleChangeProSeat}
                    changePlanPreview={changePlanPreview}
                    isValidSeat={isValidSeat}
                />
            </Grid>
        );
    }

    if (openAddSeat) {
        return (
            <Grid item style={{ maxWidth: 479 }}>
                <InvitationConfirm
                    inviteSeatQuantity={moreSeats.current}
                    onBack={handleCloseAddSeat}
                    onConfirm={handleInvite}
                    isSubmitting={isLoading}
                    planCycle={companySubscription?.cycle}
                />
            </Grid>
        );
    }

    if (openCardWarning) {
        return (
            <Grid item>
                <PaymentGuide onRedirect={onClose} onBack={handleCloseCardWarning} />;
            </Grid>
        );
    }

    if (openOutOfFreeSeats) {
        return (
            <Grid item style={{ overflow: 'hidden' }}>
                <LimitPopup
                    title={t('limit_popup_free_seats_limit_reached_title')}
                    message={t('limit_popup_free_seats_limit_reached_content')}
                    onCancel={handleCloseOutOfFreeSeats}
                    onUpgrade={handleUpgrade}
                    onClose={handleCloseOutOfFreeSeats}
                />
            </Grid>
        );
    }

    if (openInviteCSV) {
        return (
            <BulkInviteMember
                selectedBulkInviteEmails={selectedBulkInviteEmails}
                setSelectedBulkInviteEmails={setSelectedBulkInviteEmails}
                handleSubmit={handleSubmit}
                onClose={onClose}
                isLoading={isLoading}
            />
        );
    }

    return (
        <>
            <DialogTitle title={t('global_invite_members')} onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <Grid className={classes.contentRoot} container wrap="nowrap" direction="column">
                    {!isLdManager && (
                        <Grid item>
                            <Grid container wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography variant="body2" display="inline">
                                        {t('invite_member_send_invite_description')}
                                    </Typography>
                                    {!isExternal && (
                                        <Typography variant="body2" display="inline">
                                            {' '}
                                            <Trans
                                                t={t}
                                                i18nKey="invite_member_send_invite_count"
                                                values={{
                                                    count: `${
                                                        `${availableSeats}`?.length === 1 && availableSeats !== 0
                                                            ? `0${availableSeats}`
                                                            : availableSeats
                                                    }`
                                                }}
                                                components={{
                                                    bold: (
                                                        <b
                                                            className={
                                                                availableSeats === 0 ? classes.unavailableSeat : ''
                                                            }
                                                        />
                                                    )
                                                }}
                                            />
                                        </Typography>
                                    )}
                                </Grid>
                                {!isExternal && canManageBilling && (
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            className={classes.addMoreSeat}
                                            onClick={handleOpenChangeSeatModal}
                                        >
                                            Manage member seats
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item className={classes.mt20}>
                        <Grid item>
                            <Typography variant="body1">{t('invite_member_input_email')}</Typography>
                        </Grid>
                        <Grid item>
                            <InviteEmailsInput
                                ref={emailsInputRef}
                                placeholder={t('invite_member_input_email_placeholder')}
                                existedEmails={selectedEmails.map(el => el.email)}
                                onAdd={onAdd}
                                isLdManager={isLdManager}
                            />
                        </Grid>
                    </Grid>
                    {!selectedEmails?.length && (
                        <InviteMembersNoData isLdManager={isLdManager} onOpenBulkInvite={onOpenBulkInvite} />
                    )}
                    {selectedEmails?.length > 0 && (
                        <InviteMembersTable selectedEmails={selectedEmails} setSelectedEmails={setSelectedEmails} />
                    )}
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Grid item container spacing={2} alignItems="center">
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <ButtonBase variant="outlined" width={120} onClick={onClose}>
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase
                            disabled={isLoading || !selectedEmails?.length}
                            variant="contained"
                            width={120}
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {isLoading ? <CircularProgress size={24} /> : t('global_send_invite')}
                        </ButtonBase>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
};

export default React.memo(InviteMembersForm);
