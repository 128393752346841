import React from 'react';
import { Grid } from '@material-ui/core';
import Checkbox from 'components/checkbox/Base';
import LDBasePortal from 'components/selects/LDBasePortal';
import InputText from 'components/inputs/InputText';
import { isLDEmpty } from 'utils/object';

const decimalPlacesOptions = [
    { value: 0, label: 'None', moreIcon: () => <p className="caption">100</p> },
    { value: 1, label: 1, moreIcon: () => <p className="caption">100.0</p> },
    { value: 2, label: 2, moreIcon: () => <p className="caption">100.00</p> },
    { value: 3, label: 3, moreIcon: () => <p className="caption">100.000</p> },
    { value: 4, label: 4, moreIcon: () => <p className="caption">100.0000</p> },
    { value: 5, label: 5, moreIcon: () => <p className="caption">100.00000</p> },
    { value: 6, label: 6, moreIcon: () => <p className="caption">100.000000</p> },
    { value: 7, label: 7, moreIcon: () => <p className="caption">100.0000000</p> },
    { value: 8, label: 8, moreIcon: () => <p className="caption">100.00000000</p> }
];

function ExtraNumberOptions({ numberFormat, onNumberFormatChange }) {
    const selectedDecimalPlaceOption = React.useMemo(() => {
        return !numberFormat?.decimalPlaces
            ? decimalPlacesOptions?.[0]
            : decimalPlacesOptions?.find(option => option?.value === numberFormat?.decimalPlaces);
    }, [numberFormat]);

    const use1000Separator = React.useMemo(() => {
        return isLDEmpty(numberFormat?.use1000Separator) ? true : numberFormat?.use1000Separator;
    }, [numberFormat]);

    const handleDecimalPlaceChange = option => {
        onNumberFormatChange({
            ...numberFormat,
            decimalPlaces: option?.value
        });
    };

    const handleUse1000SeparatorChange = e => {
        onNumberFormatChange({
            ...numberFormat,
            use1000Separator: e.target?.checked
        });
    };

    const handleCurrencySymbolChange = e => {
        onNumberFormatChange({
            ...numberFormat,
            currencySymbol: e.target?.value
        });
    };

    return (
        <Grid container direction="column">
            <Grid container item direction="column">
                <Grid item>
                    <p className="body1 mb-1">Decimal places</p>
                </Grid>
                <Grid item>
                    <LDBasePortal
                        ddPlaceholder={'Select'}
                        menuPlaceholder={'Find'}
                        options={decimalPlacesOptions}
                        onChange={handleDecimalPlaceChange}
                        defaultValue={selectedDecimalPlaceOption}
                        isMulti={false}
                        isUsingContainer={true}
                        placement="bottom-start"
                    />
                </Grid>
            </Grid>
            <Grid item alignItems="center" className={'relative -left-3 py-1'} container direction="row">
                <Grid item>
                    <Checkbox checked={use1000Separator} onChange={handleUse1000SeparatorChange} />
                </Grid>
                <Grid item>
                    <p className="body2">Use 1000 separator</p>
                </Grid>
            </Grid>
            <Grid item>
                <InputText
                    name="columnName"
                    label={'Currency symbol'}
                    value={numberFormat?.currencySymbol || ''}
                    onChange={handleCurrencySymbolChange}
                    placeholder="Currency"
                />
            </Grid>
        </Grid>
    );
}
export default React.memo(ExtraNumberOptions);
