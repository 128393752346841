import React from 'react';

function LinkIconSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="24" height="24" {...other} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.666 12.667C10.9523 13.0497 11.3176 13.3664 11.737 13.5956C12.1565 13.8248 12.6204 13.961 13.0971 13.9952C13.5739 14.0293 14.0524 13.9605 14.5002 13.7935C14.9481 13.6264 15.3548 13.365 15.6927 13.027L17.6927 11.027C18.2999 10.3983 18.6359 9.55629 18.6283 8.6823C18.6207 7.80831 18.2701 6.97227 17.6521 6.35424C17.0341 5.73621 16.198 5.38565 15.324 5.37806C14.45 5.37046 13.608 5.70644 12.9793 6.31364L11.8327 7.45364"
                stroke={color}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3335 11.3338C13.0472 10.9511 12.6819 10.6344 12.2624 10.4052C11.843 10.176 11.3791 10.0397 10.9024 10.0056C10.4256 9.97144 9.94707 10.0402 9.49924 10.2073C9.0514 10.3743 8.64472 10.6358 8.3068 10.9738L6.3068 12.9738C5.69961 13.6025 5.36363 14.4445 5.37122 15.3185C5.37881 16.1925 5.72938 17.0285 6.3474 17.6465C6.96543 18.2646 7.80147 18.6151 8.67546 18.6227C9.54945 18.6303 10.3915 18.2943 11.0201 17.6871L12.1601 16.5471"
                stroke={color}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default React.memo(LinkIconSVG);
