import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import WarningBox from 'payment/components/warning';
import { PAYMENT_WARNING_TYPES, PLAN_TYPES, USAGE_PROPERTIES } from 'const';
import GridSVG from 'assets/images/svg/GridSVG';
import BranchSVG from 'assets/images/svg/BranchSVG';
import AddRowBelowSVG from 'assets/images/svg/AddRowBelowSVG';
import FilesSVG from 'components/svg-icon/FilesSVG';
import UsageBox from 'payment/components/usage/UsageBox';
import { useCompanySubscription, useThresHoldValueByProperty } from 'hooks/payment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function UsageStatistic() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const grids = useThresHoldValueByProperty(USAGE_PROPERTIES.GRIDS_PER_PROJECT);
    const records = useThresHoldValueByProperty(USAGE_PROPERTIES.RECORDS_IN_PROJECT);
    const storage = useThresHoldValueByProperty(USAGE_PROPERTIES.STORAGE_GB);
    const companySubscription = useCompanySubscription();

    const isOverGrids = React.useMemo(() => {
        return grids?.actualValue > grids.defaultValue;
    }, [grids]);

    const isOverRecords = React.useMemo(() => {
        return records?.actualValue > records.defaultValue;
    }, [records]);

    const isOverStorage = React.useMemo(() => {
        return storage?.actualValue > storage.defaultValue;
    }, [storage]);

    const isShowWarning = React.useMemo(() => {
        return isOverStorage || isOverGrids || isOverRecords;
    }, [isOverStorage, isOverGrids, isOverRecords]);

    return (
        <Grid container className={classes.root} direction="column" spacing={3}>
            {isShowWarning && (
                <Grid item>
                    <WarningBox
                        warningType={PAYMENT_WARNING_TYPES.ERROR}
                        message={`Time to upgrade! This Project has reached the limits the ${
                            companySubscription?.plan === PLAN_TYPES.PROFESSIONAL ? 'Pro' : companySubscription?.plan
                        } plan.`}
                    />
                </Grid>
            )}
            <Grid item>
                <UsageBox
                    title={'Grids'}
                    defaultValue={grids?.defaultValue || 0}
                    actualValue={grids?.actualValue || 0}
                    isOver={isOverGrids}
                    color={theme.colors.fuchsiaBlue}
                    icon={<GridSVG color={theme.colors.fuchsiaBlue} />}
                />
            </Grid>
            <Grid item>
                <UsageBox
                    title={t('global_records')}
                    defaultValue={records.defaultValue || 0}
                    actualValue={records?.actualValue || 0}
                    isOver={isOverRecords}
                    color={theme.colors.dodgerBlue}
                    icon={<AddRowBelowSVG color={theme.colors.dodgerBlue} />}
                />
            </Grid>
            <Grid item>
                <UsageBox
                    title={'Branches'}
                    defaultValue={3}
                    actualValue={2}
                    isOver={2 > 3}
                    color={theme.colors.sun}
                    icon={<BranchSVG color={theme.colors.sun} />}
                />
            </Grid>
            <Grid item>
                <UsageBox
                    title={'Total storage'}
                    defaultValue={storage.defaultValue || 0}
                    unit={'GB'}
                    actualValue={storage.actualValue || 0}
                    isOver={isOverStorage}
                    color={theme.colors.atlantis}
                    icon={<FilesSVG color={theme.colors.atlantis} />}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(UsageStatistic);
