export const USER_LOGOUT_SUCCESS = '[LOGOUT]USER_LOGOUT_SUCCESS';

export const USER_SIGNUP_FAILED = '[SIGNUP]USER_SIGNUP_FAILED';

/**
 * UPDATE USER INFO
 *  */

export const UPDATE_CURRENT_USER = '[USER]UPDATE_CURRENT_USER';
export const UPDATE_USER_PASSWORD = '[USER]UPDATE_USER_PASSWORD';

export const SET_CURRENT_USER_SUCCESS = '[USER]SET_CURRENT_USER_SUCCESS';
export const SET_CURRENT_USER_FAILED = '[USER]SET_CURRENT_USER_FAILED';
export const SET_COMPANY_ID = '[USER]SET_COMPANY_ID';

export const SET_USER_WORKSPACE_ROLE_SUCCESS = '[USER]SET_USER_WORKSPACE_ROLE_SUCCESS';
export const SET_USER_WORKSPACE_ROLE_FAILED = '[USER]SET_USER_WORKSPACE_ROLE_FAILED';

export const UPDATE_AUTHORITIES_SOCKET = '[USER]UPDATE_AUTHORITIES_SOCKET';

export const UPLOAD_USER_AVATAR_SUCCESS = '[USER]UPLOAD_USER_AVATAR_SUCCESS';

export const SET_USER_SETTINGS = '[USER]SET_USER_SETTINGS';

export const RESEND_CHANGE_EMAIL = '[USER]RESEND_CHANGE_EMAIL';

export const SET_USER_NOTIFICATION_CENTER_WITH_KEY_VALUE = '[USER]SET_USER_NOTIFICATION_CENTER_WITH_KEY_VALUE';

export const ADD_USER_NOTIFICATION_CENTER_PUSHES = '[USER]ADD_USER_NOTIFICATION_CENTER_PUSHES';

export const REMOVE_USER_NOTIFICATION_CENTER_PUSHES = '[USER]REMOVE_USER_NOTIFICATION_CENTER_PUSHES';

export const SET_USER_NOTIFICATION_CENTER = '[USER]SET_USER_NOTIFICATION_CENTER';
