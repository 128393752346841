import React from 'react';

function NotFoundIllustrationSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="400" height="238" viewBox="0 0 400 238" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0-404)">
                <path
                    d="M98.806 53.9327C63.2848 53.5412 26.6352 62.2528 24.7709 104.196C21.729 172.665 92.2807 213.727 150.42 228.605C183.635 237.121 219.058 239.128 253.205 237.464C277.933 236.24 317.624 233.695 335.826 213.874C350.889 197.527 340.291 183.481 335.679 166.156C332.196 153.137 338.378 141.587 348.043 133.12C361.731 121.081 378.315 106.055 375.763 85.6469C373.261 65.5808 354.421 52.7581 337.74 44.4381C316.447 33.7198 295.89 32.6921 272.389 34.8944C257.67 36.2648 243.197 39.495 229.214 44.1933C214.152 49.2833 199.678 56.6734 183.831 59.0226C168.916 61.225 153.609 59.1695 138.792 57.2118C127.213 55.6946 113.083 54.0795 98.806 53.9327Z"
                    fill="#5E55A4"
                />
                <path
                    d="M238.831 218.279C238.389 218.181 235.691 217.544 234.906 216.272C234.317 215.293 233.63 213.238 233.483 212.797V212.748V212.797C233.336 213.238 232.649 215.293 232.011 216.272C231.226 217.544 228.528 218.181 228.086 218.279C228.528 218.376 231.226 219.013 232.011 220.285C232.6 221.264 233.336 223.319 233.434 223.76V223.809V223.76C233.581 223.319 234.268 221.264 234.857 220.285C235.74 219.013 238.438 218.376 238.831 218.279Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M302.759 213.042C302.563 212.993 301.238 212.699 300.846 212.063C300.551 211.573 300.208 210.595 300.159 210.399C300.11 210.595 299.766 211.622 299.472 212.063C299.08 212.699 297.804 212.993 297.559 213.042C297.755 213.091 299.08 213.384 299.472 214.021C299.766 214.51 300.11 215.489 300.159 215.685C300.208 215.489 300.551 214.461 300.846 214.021C301.238 213.433 302.563 213.091 302.759 213.042Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M64.953 135.616C64.5114 135.518 61.862 134.931 61.077 133.659C60.4883 132.68 59.8014 130.673 59.6542 130.233V130.184V130.233C59.507 130.673 58.8202 132.729 58.2314 133.659C57.4464 134.931 54.797 135.518 54.3555 135.616C54.797 135.714 57.4464 136.35 58.2314 137.574C58.8202 138.553 59.507 140.559 59.6542 141V141.049V141C59.8014 140.559 60.4883 138.504 61.077 137.574C61.9111 136.301 64.5605 135.714 64.953 135.616Z"
                    fill="url(#paint2_linear)"
                />
                <path
                    d="M134.131 71.0623C133.346 70.9155 128.489 69.7409 127.066 67.4406C125.987 65.6787 124.711 61.9102 124.466 61.1272V61.0293V61.0782V61.1272C124.221 61.9102 122.945 65.6787 121.866 67.4406C120.443 69.7898 115.537 70.9155 114.801 71.0623C115.586 71.2091 120.443 72.3837 121.866 74.684C122.945 76.4459 124.221 80.2144 124.466 80.9975V81.0953V81.0464V80.9975C124.711 80.2144 125.987 76.4459 127.066 74.684C128.489 72.3837 133.395 71.2581 134.131 71.0623Z"
                    fill="url(#paint3_linear)"
                />
                <path
                    d="M75.2061 146.334C75.0098 146.285 73.7342 145.992 73.3417 145.405C73.0473 144.964 72.7529 143.985 72.6548 143.74C72.6057 143.936 72.2623 144.915 71.9679 145.405C71.5754 145.992 70.3488 146.285 70.1035 146.334C70.2998 146.383 71.5754 146.677 71.9679 147.264C72.2623 147.705 72.5567 148.684 72.6548 148.928C72.7039 148.733 73.0473 147.754 73.3417 147.264C73.7342 146.677 75.0098 146.383 75.2061 146.334Z"
                    fill="url(#paint4_linear)"
                />
                <path
                    d="M324.738 67.5875C325.278 67.6854 328.467 68.2727 329.497 69.741C330.282 70.8666 331.214 73.3137 331.411 73.8031V73.8521C331.411 73.8521 331.411 73.8521 331.411 73.8031V73.7542C331.558 73.2158 332.294 70.7198 332.932 69.5452C333.815 67.9791 336.955 67.0981 337.494 66.9513C336.955 66.8534 333.766 66.2661 332.735 64.7979C331.95 63.6722 331.018 61.2251 330.822 60.7357V60.6868C330.822 60.6868 330.822 60.6868 330.822 60.7357V60.7846C330.675 61.323 329.939 63.819 329.301 64.9936C328.369 66.5598 325.229 67.4407 324.738 67.5875Z"
                    fill="url(#paint5_linear)"
                />
                <path
                    d="M292.062 45.5149C292.406 45.5638 294.663 46.0043 295.35 47.0321C295.889 47.8151 296.527 49.4791 296.674 49.8707V49.9196C296.772 49.5281 297.263 47.8151 297.754 46.9831C298.342 45.9064 300.55 45.2702 300.894 45.1723C300.55 45.1234 298.293 44.6829 297.606 43.6551C297.067 42.8721 296.429 41.208 296.282 40.8165V40.7676C296.184 41.1591 295.693 42.8721 295.202 43.7041C294.614 44.7808 292.406 45.417 292.062 45.5149Z"
                    fill="url(#paint6_linear)"
                />
                <path
                    d="M242.41 76.0055C242.95 76.1034 246.139 76.6907 247.169 78.1589C247.954 79.2846 248.886 81.7317 249.083 82.2211V82.27C249.083 82.27 249.083 82.27 249.083 82.2211V82.1722C249.23 81.6338 249.966 79.1378 250.604 77.9632C251.487 76.397 254.627 75.5161 255.167 75.3693C254.627 75.2714 251.438 74.6841 250.407 73.2158C249.622 72.0902 248.69 69.6431 248.494 69.1537V69.1047C248.494 69.1047 248.494 69.1047 248.494 69.1537V69.2026C248.347 69.741 247.611 72.237 246.973 73.4116C246.041 74.9777 242.901 75.8587 242.41 76.0055Z"
                    fill="url(#paint7_linear)"
                />
                <path
                    d="M265.666 89.6602C265.911 89.7091 267.432 90.0027 267.923 90.6879C268.266 91.2263 268.757 92.4009 268.855 92.6456V92.6945C268.904 92.4498 269.248 91.2263 269.591 90.6879C270.033 89.9538 271.504 89.5133 271.75 89.4644C271.504 89.4155 269.983 89.1218 269.493 88.4366C269.1 87.8983 268.659 86.7237 268.561 86.479V86.4301C268.512 86.6748 268.168 87.8983 267.825 88.4366C267.432 89.1707 265.911 89.6112 265.666 89.6602Z"
                    fill="url(#paint8_linear)"
                />
                <path
                    d="M318.801 105.566C319.046 105.615 320.567 105.909 321.058 106.594C321.401 107.132 321.892 108.307 321.99 108.552V108.601C322.039 108.356 322.382 107.132 322.726 106.594C323.167 105.86 324.639 105.419 324.884 105.37C324.639 105.321 323.118 105.028 322.628 104.343C322.235 103.804 321.794 102.63 321.695 102.385V102.336C321.646 102.581 321.303 103.804 320.959 104.343C320.567 105.077 319.046 105.517 318.801 105.566Z"
                    fill="url(#paint9_linear)"
                />
                <path
                    d="M148.947 95.0927C149.193 95.1416 150.714 95.4353 151.204 96.1205C151.548 96.6588 152.038 97.8334 152.136 98.0781V98.1271C152.185 97.8824 152.529 96.6588 152.872 96.1205C153.314 95.3863 154.786 94.9459 155.031 94.8969C154.786 94.848 153.265 94.5543 152.774 93.8692C152.382 93.3308 151.94 92.1562 151.842 91.9115V91.8625C151.793 92.1073 151.449 93.3308 151.106 93.8692C150.664 94.6033 149.144 95.0438 148.947 95.0927Z"
                    fill="url(#paint10_linear)"
                />
                <path
                    d="M262.479 36.8521L291.523 68.9578C291.523 68.9578 294.811 73.0199 295.939 71.8943C297.362 70.475 293.486 66.7554 293.486 66.7554L264.245 36.6564C263.656 36.7053 263.067 36.8032 262.479 36.8521Z"
                    fill="url(#paint11_linear)"
                />
                <path
                    d="M161.703 211.378L143.648 191.409L162.341 210.643C162.341 210.643 163.568 211.818 163.126 212.308C162.783 212.65 161.703 211.378 161.703 211.378Z"
                    fill="url(#paint12_linear)"
                />
                <path
                    d="M48.3689 108.57C64.3829 108.57 77.3648 95.6203 77.3648 79.6458C77.3648 63.6712 64.3829 50.7213 48.3689 50.7213C32.3549 50.7213 19.373 63.6712 19.373 79.6458C19.373 95.6203 32.3549 108.57 48.3689 108.57Z"
                    fill="#C7F5FB"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M48.3704 50.7213C43.2189 50.7213 38.3617 52.0917 34.1914 54.4409C34.3877 54.7834 34.633 55.126 34.8783 55.3707C37.822 58.3072 44.7889 58.454 48.6648 58.6498C54.3561 58.9435 60.0964 58.1604 65.5423 56.3985C60.7342 52.8747 54.7976 50.7213 48.3704 50.7213Z"
                    fill="#73D8FD"
                    fillOpacity="0.6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M70.5452 66.1868C71.5264 65.8442 72.4586 65.5016 73.3417 65.0122C72.6058 63.7397 71.7717 62.5162 70.8395 61.3905C67.9449 62.663 65.0502 63.9844 62.1555 65.2569C59.8986 66.2357 57.6417 67.2635 55.2867 67.8997C51.8033 68.8296 48.1236 68.8785 44.493 68.8296C36.9865 68.7807 29.6271 68.0465 22.2187 67.2635C20.4034 71.032 19.373 75.2409 19.373 79.6457C19.373 81.7502 19.6184 83.8057 20.0599 85.7634C21.5809 85.1761 22.9546 84.1483 24.3284 83.2673C28.008 80.9182 32.2274 79.4499 36.3486 78.0306C47.7311 74.1153 59.1136 70.151 70.5452 66.1868Z"
                    fill="#73D8FD"
                    fillOpacity="0.6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.0109 68.2424C72.754 70.249 71.0859 72.8919 68.6328 74.8006C66.0324 76.8072 63.1868 78.4712 60.145 79.6458C57.9862 80.4778 55.7784 81.114 53.7178 82.1418C52.9328 82.5333 52.1478 83.0717 51.9515 83.9037C51.6081 85.3719 53.3743 86.4486 54.8462 86.7912C57.79 87.4764 60.8318 87.3296 63.8737 87.2317C67.4553 87.0849 71.0368 86.938 74.5693 86.7912C75.2071 86.7423 75.8449 86.6933 76.4827 86.6444C77.0224 84.3931 77.3659 82.0928 77.3659 79.6947C77.3659 75.5836 76.4827 71.7662 75.0109 68.2424Z"
                    fill="#73D8FD"
                    fillOpacity="0.6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.1061 78.8627C43.9545 79.8904 38.9011 81.5545 34.1421 83.7079C30.953 85.1761 27.8621 86.8891 24.5258 87.9169C23.5937 88.2105 22.2199 88.3084 20.748 88.4063C22.4652 93.7899 25.7033 98.4883 29.9718 101.963C30.2661 101.621 30.5605 101.229 30.8549 100.886C35.1724 95.1602 38.1161 87.8679 44.3961 84.3441C45.5245 83.7079 46.7511 83.1695 47.7814 82.3375C48.8117 81.5055 49.4005 80.1352 49.1061 78.8627Z"
                    fill="#73D8FD"
                    fillOpacity="0.6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.5205 92.0279C74.1771 91.979 73.8827 91.979 73.5393 91.979C67.3574 92.3216 61.1756 92.6642 54.9937 93.0068C51.1668 93.2025 47.0947 93.4962 43.9056 95.6496C41.4525 97.3137 39.7844 99.9076 38.2144 102.404C37.6747 103.236 36.7425 104.459 35.8594 105.683C39.4409 107.396 43.415 108.374 47.5853 108.472C48.3212 107.542 49.0081 106.515 49.744 105.536C53.8653 100.054 60.3415 96.4327 67.2102 95.6986C69.0256 95.5028 70.8409 95.5028 72.6562 95.307C73.3921 94.2793 73.9809 93.1536 74.5205 92.0279Z"
                    fill="#73D8FD"
                    fillOpacity="0.6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.9608 91.147C75.6967 89.4341 76.2855 87.6232 76.678 85.7634C69.2205 88.4063 60.3892 90.7555 50.9693 92.5663C41.4021 94.4261 32.2765 95.5028 24.2793 95.7965C25.3587 97.3626 26.5852 98.8309 27.9099 100.152C35.2693 99.7118 43.4136 98.6351 51.8033 97.02C60.1439 95.4539 68.043 93.3983 74.9608 91.147Z"
                    fill="#74C8F7"
                />
                <path
                    d="M19.373 80.7714C19.373 81.0161 19.373 81.2608 19.4221 81.5055C19.4221 81.2608 19.373 81.0161 19.373 80.7714Z"
                    fill="#CBBCDC"
                />
                <path
                    d="M19.4714 76.954C19.3733 77.8349 19.3242 78.7648 19.3242 79.6947C19.3733 78.7159 19.4223 77.8349 19.4714 76.954Z"
                    fill="#CBBCDC"
                />
                <path
                    d="M75.9423 88.5532C76.482 86.8891 76.8745 85.1273 77.1198 83.3654C69.6623 86.0571 60.6348 88.4553 51.0186 90.3151C39.6851 92.5174 30.3142 93.4473 23.0039 93.5941C23.887 95.1603 24.8683 96.6285 25.9967 97.9989C35.1223 97.5095 44.8367 96.1391 51.9017 94.7687C60.5367 93.1047 68.8282 90.9513 75.9423 88.5532Z"
                    fill="#C28EEE"
                />
                <path
                    d="M22.9554 93.5941C11.1804 93.8878 4.85134 92.1748 4.55697 90.6576C4.40978 90.0214 6.71571 86.3507 19.4229 81.5055C19.4229 81.2608 19.3738 81.0161 19.3738 80.7714C19.3738 80.3799 19.3248 80.0373 19.3248 79.6457C19.3248 78.7158 19.3738 77.8349 19.4719 76.905C19.0794 76.8561 18.6869 76.8561 18.3435 77.0029C5.29291 81.8971 -0.839896 86.7912 0.0922899 91.4896C1.07354 96.4817 9.56133 98.1457 19.9135 98.1457C21.876 98.1457 23.8876 98.0967 25.9482 97.9988C24.8197 96.6285 23.7894 95.1602 22.9554 93.5941Z"
                    fill="#C28EEE"
                />
                <path
                    d="M98.6586 72.5002C97.7264 67.704 89.8765 65.4527 75.3049 65.8931C74.8143 65.8931 74.4218 66.04 74.0293 66.3336C74.7162 67.655 75.3049 69.0254 75.7955 70.4447C90.3671 70.0532 94.0468 72.696 94.1449 73.3812C94.243 73.9195 92.673 76.8071 83.1058 80.9671C81.2415 81.7991 79.1809 82.5822 77.0221 83.3653C76.7768 85.1761 76.3843 86.8891 75.8446 88.5531C79.1318 87.4274 82.1737 86.3018 84.9212 85.1272C94.979 80.7714 99.4436 76.6113 98.6586 72.5002Z"
                    fill="#C28EEE"
                />
                <path
                    d="M200.366 200.369C230.47 200.369 254.874 176.025 254.874 145.995C254.874 115.965 230.47 91.6212 200.366 91.6212C170.262 91.6212 145.857 115.965 145.857 145.995C145.857 176.025 170.262 200.369 200.366 200.369Z"
                    fill="#AD74DD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M146.25 139.189C162.441 141.489 189.621 129.939 198.354 128.275C216.066 124.849 234.268 128.911 252.323 129.547C251.096 125.632 249.379 121.864 247.319 118.389C240.695 118.682 233.679 117.508 226.958 117.312C214.251 116.921 201.592 120.297 188.885 119.514C182.998 119.172 169.8 114.033 157.78 112.026C151.647 119.661 147.526 128.96 146.25 139.189Z"
                    fill="#C28EEE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M200.365 91.5688C194.821 91.5688 189.473 92.4008 184.42 93.918C191.24 97.7844 204.928 99.2037 208.117 99.6441C213.808 100.427 220.039 100.672 224.945 97.6865C224.994 97.6375 225.093 97.5886 225.142 97.5397C217.733 93.7223 209.294 91.5688 200.365 91.5688Z"
                    fill="#C28EEE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M254.873 146.726C239.173 142.762 222.541 142.468 206.694 145.894C198.108 147.754 189.767 150.69 181.23 152.795C170.486 155.437 159.397 156.71 148.358 156.612C147.868 156.612 147.426 156.612 146.936 156.563C149.094 167.428 154.491 177.168 162.145 184.656C175.196 180.055 187.756 173.986 199.481 166.596C209.735 160.136 219.99 152.501 232.059 151.375C239.565 150.69 246.925 152.648 254.333 154.165C254.628 151.767 254.824 149.271 254.873 146.726Z"
                    fill="#C28EEE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M251.979 163.464C244.571 162.387 231.373 168.848 228.135 171.588C225.24 174.035 222.787 177.021 219.696 179.174C215.722 181.915 210.963 183.089 206.302 184.264C196.931 186.662 187.561 189.354 178.337 192.29C177.159 192.682 175.884 193.171 175.295 194.248C182.801 198.114 191.289 200.317 200.366 200.317C206.695 200.317 212.779 199.24 218.421 197.234C224.063 189.158 227.448 178.734 236.427 174.965C237.948 174.329 243.246 173.546 248.055 172.322C249.575 169.533 250.9 166.596 251.979 163.464Z"
                    fill="#C28EEE"
                />
                <path
                    d="M331.461 126.073C333.005 126.073 334.257 124.824 334.257 123.283C334.257 121.742 333.005 120.493 331.461 120.493C329.916 120.493 328.664 121.742 328.664 123.283C328.664 124.824 329.916 126.073 331.461 126.073Z"
                    fill="#71CAEB"
                />
                <path
                    d="M130.99 211.084C132.534 211.084 133.786 209.835 133.786 208.294C133.786 206.754 132.534 205.505 130.99 205.505C129.445 205.505 128.193 206.754 128.193 208.294C128.193 209.835 129.445 211.084 130.99 211.084Z"
                    fill="#71CAEB"
                />
                <path
                    d="M102.584 95.0437C104.128 95.0437 105.38 93.7947 105.38 92.254C105.38 90.7133 104.128 89.4644 102.584 89.4644C101.039 89.4644 99.7871 90.7133 99.7871 92.254C99.7871 93.7947 101.039 95.0437 102.584 95.0437Z"
                    fill="#71CAEB"
                />
                <path
                    d="M303.593 144.964V100.868H288.481L260.025 144.23C260.025 144.817 260.074 145.405 260.074 145.992C260.074 149.418 259.78 152.795 259.24 156.074V157.248H290.493V170.805H303.789V157.395H312.08V145.062H303.593V144.964ZM290.591 144.866H273.027L290.591 119.025V144.866Z"
                    fill="white"
                />
                <path
                    d="M140.606 145.992C140.606 145.649 140.606 145.356 140.606 145.013H132.952V100.917H117.841L88.5508 145.502V157.248H119.804V170.805H133.099V157.395H141.391V156.123C140.9 152.795 140.606 149.418 140.606 145.992ZM120 144.866H102.435L120 119.025V144.866Z"
                    fill="white"
                />
                <path
                    d="M231.816 25.7914C231.325 30.9792 221.022 35.286 217.342 36.6564C207.333 40.376 196.638 42.3826 186.089 43.8998C182.164 44.4381 178.19 44.9275 174.265 45.2701C163.177 46.3468 151.893 46.8363 140.805 45.7595C134.672 45.1722 123.976 44.3402 120.297 38.4183C112.839 26.4276 154.003 18.9396 159.547 17.9607C165.287 16.933 171.027 16.1499 176.817 15.6116C188.052 14.5348 199.434 14.2412 210.67 15.5626C216.753 16.2967 225.438 17.1777 230.049 21.7782C231.472 23.1485 231.963 24.5189 231.816 25.7914Z"
                    fill="#FEC04E"
                />
                <path
                    d="M159.988 20.8483C165.728 19.8205 171.468 19.0375 177.258 18.4991C188.493 17.4224 199.875 17.1287 211.111 18.4502C217.194 19.1843 225.878 20.0652 230.49 24.6657C231.03 25.2041 231.423 25.7424 231.717 26.2808C231.766 26.134 231.766 25.9382 231.766 25.7914C231.864 24.47 231.373 23.1485 230 21.7292C225.388 17.1287 216.704 16.2478 210.62 15.5137C199.434 14.1922 188.002 14.4859 176.767 15.5626C170.978 16.101 165.188 16.884 159.497 17.9118C154.051 18.8906 114.458 26.085 119.904 37.6842C121.523 27.8469 155.032 21.7292 159.988 20.8483Z"
                    fill="#FFD480"
                />
                <path
                    d="M198.453 38.2715C198.551 37.88 198.355 37.4395 197.766 36.9991C195.902 35.6287 192.173 35.4819 189.622 35.384C184.863 35.1393 180.006 35.4329 175.198 35.9713C172.745 36.2649 170.242 36.6075 167.789 36.9991C165.385 37.3906 147.772 40.4739 150.715 44.1445C150.765 44.2424 150.863 44.2914 150.912 44.3403L156.995 50.8985C158.369 52.2689 161.607 52.4646 163.521 52.5625C167.2 52.8072 170.978 52.5625 174.658 52.171C175.983 52.0241 177.307 51.8773 178.583 51.6816C182.115 51.1921 185.697 50.507 189.033 49.3813C190.064 49.0387 192.86 48.1578 193.596 46.8853C195.068 44.2914 198.355 38.2715 198.355 38.2715C198.404 38.3205 198.453 38.2715 198.453 38.2715Z"
                    fill="#FCB42C"
                />
                <path
                    d="M159.546 17.9607C165.286 16.933 171.026 16.1499 176.816 15.6115C183.586 14.9753 190.406 14.5838 197.226 14.6816C193.644 4.69756 182.016 -1.56696 169.456 0.341764C157.632 2.1526 148.801 10.6195 147.477 20.4567C153.168 19.0864 157.878 18.2544 159.546 17.9607Z"
                    fill="#FFEDD7"
                />
                <path
                    d="M193.056 14.6816C194.429 14.6816 195.852 14.6816 197.226 14.6816C193.644 4.69756 182.017 -1.56696 169.457 0.341764C168.77 0.439647 168.132 0.586472 167.494 0.733296C179.024 -0.294476 189.425 5.52957 193.056 14.6816Z"
                    fill="#FFDD9E"
                />
                <path
                    d="M193.988 46.2489C193.792 47.7661 190.309 49.0386 189.082 49.479C185.746 50.6047 182.164 51.2899 178.632 51.7793C177.307 51.9751 175.982 52.1219 174.707 52.2687C171.027 52.6602 167.249 52.9049 163.57 52.6602C161.558 52.5134 157.976 52.3666 156.799 50.7026C154.395 47.2767 168.132 44.8296 169.997 44.5359C171.91 44.1934 173.824 43.9486 175.786 43.7529C179.515 43.3614 183.342 43.2145 187.071 43.5082C189.082 43.655 191.977 43.8508 193.498 45.1722C193.841 45.4658 194.037 45.8574 193.988 46.2489Z"
                    fill="#FFD480"
                />
                <path
                    d="M174.657 50.6048C175.638 50.5069 176.619 50.409 177.551 50.2622C180.152 49.8707 182.801 49.4302 185.254 48.5493C186.039 48.3046 187.119 48.0109 187.707 47.4236C189.032 46.1511 184.322 45.9554 183.88 45.9064C181.133 45.7106 178.385 45.8085 175.687 46.1022C174.264 46.249 172.841 46.4448 171.419 46.6895C169.947 46.9342 168.426 47.2768 166.954 47.6683C165.629 48.0109 164.206 48.3535 163.029 48.9897C160.33 50.5069 165.335 50.8006 166.414 50.8495C169.162 51.0453 171.958 50.8985 174.657 50.6048Z"
                    fill="white"
                />
                <path
                    d="M365.79 181.517C377.336 181.517 386.696 172.18 386.696 160.663C386.696 149.145 377.336 139.809 365.79 139.809C354.244 139.809 344.885 149.145 344.885 160.663C344.885 172.18 354.244 181.517 365.79 181.517Z"
                    fill="#E85D9B"
                />
                <path
                    d="M364.479 155.555C364.479 152.022 358.126 149.141 350.272 149.141C349.579 149.141 348.925 149.18 348.27 149.218C346.114 152.483 344.844 156.438 344.844 160.663C344.844 160.932 344.844 161.201 344.882 161.508C346.538 161.815 348.386 162.007 350.272 162.007C358.126 161.969 364.479 159.088 364.479 155.555Z"
                    fill="#E53182"
                />
                <path
                    d="M363.863 143.496C363.863 147.106 370.37 149.986 378.378 149.986C380.187 149.986 381.881 149.832 383.46 149.564C379.879 143.841 373.565 140.001 366.366 139.809C364.787 140.846 363.863 142.113 363.863 143.496Z"
                    fill="#E53182"
                />
                <path
                    d="M378.609 172.991C378.609 171.531 368.753 170.379 356.625 170.379C353.353 170.379 350.234 170.456 347.424 170.61C348.425 172.453 349.695 174.104 351.158 175.525C352.891 175.564 354.739 175.602 356.625 175.602C368.753 175.602 378.609 174.45 378.609 172.991Z"
                    fill="#E53182"
                />
                <path
                    d="M367.637 155.862C367.637 156.899 373.874 157.744 381.535 157.744C383.268 157.744 384.923 157.706 386.424 157.629C386.232 156.4 385.962 155.209 385.577 154.057C384.307 154.019 382.921 153.98 381.535 153.98C373.874 153.98 367.637 154.825 367.637 155.862Z"
                    fill="#E53182"
                />
                <path
                    d="M379.109 163.236C370.1 163.236 362.785 164.426 362.785 165.847C362.785 167.307 370.1 168.459 379.109 168.459C381.265 168.459 383.306 168.382 385.192 168.267C385.808 166.769 386.232 165.156 386.463 163.505C384.268 163.313 381.766 163.236 379.109 163.236Z"
                    fill="#E53182"
                />
                <path
                    d="M359.858 142.651C359.858 142.036 359.434 141.46 358.741 140.999C356.085 141.959 353.66 143.419 351.619 145.301C352.274 145.416 353.005 145.493 353.737 145.493C357.125 145.493 359.858 144.225 359.858 142.651Z"
                    fill="#E53182"
                />
                <path
                    d="M363.863 180.019C363.863 178.982 361.822 178.137 359.32 178.137C357.818 178.137 356.471 178.444 355.662 178.905C357.818 180.096 360.167 180.902 362.708 181.286C363.439 180.941 363.863 180.518 363.863 180.019Z"
                    fill="#E53182"
                />
                <path
                    d="M355.893 166.961C357.254 166.961 358.357 166.566 358.357 166.078C358.357 165.59 357.254 165.195 355.893 165.195C354.533 165.195 353.43 165.59 353.43 166.078C353.43 166.566 354.533 166.961 355.893 166.961Z"
                    fill="#E53182"
                />
                <path
                    d="M168.43 16.3722C170.049 16.1764 171.668 15.9806 173.336 15.7849C174.857 14.2187 176.28 11.3312 175.495 8.54152C174.464 4.57725 171.766 2.76642 167.939 3.59842C163.769 4.52831 163.229 8.88411 163.671 10.9886C164.161 13.191 166.271 15.3444 168.43 16.3722Z"
                    fill="#6CC5A4"
                />
                <path
                    d="M174.415 7.70957C173.286 6.14344 169.607 8.73734 170.686 10.695C171.569 12.3101 176.525 10.6461 174.415 7.70957Z"
                    fill="#2A215B"
                />
                <path
                    d="M164.553 9.86296C164.945 7.95424 169.361 8.83519 169.214 11.0376C169.066 12.8973 163.866 13.4357 164.553 9.86296Z"
                    fill="#2A215B"
                />
                <path
                    d="M173.433 9.42249C173.483 9.0799 173.041 9.03096 172.992 9.37355C172.943 9.61825 173.384 9.6672 173.433 9.42249Z"
                    fill="white"
                />
                <path
                    d="M172.746 8.54148C172.795 8.29677 172.501 8.29677 172.452 8.49254C172.403 8.6883 172.746 8.73724 172.746 8.54148Z"
                    fill="white"
                />
                <path
                    d="M173.777 8.19893C173.728 8.24787 173.679 8.29681 173.63 8.34576C173.581 8.19893 173.482 8.10105 173.433 8.05211C173.286 7.90528 173.188 8.05211 173.335 8.14999C173.433 8.24787 173.482 8.34576 173.531 8.44364C173.482 8.49258 173.384 8.54152 173.237 8.63941C173.139 8.68835 173.188 8.78623 173.286 8.73729C173.335 8.73729 173.433 8.63941 173.581 8.59046C173.679 8.73729 173.728 8.88411 173.777 8.93306C173.826 9.03094 173.924 8.93306 173.875 8.88411C173.875 8.83517 173.777 8.68835 173.728 8.54152C173.777 8.49258 173.826 8.44364 173.875 8.3947C173.973 8.19893 173.875 8.10105 173.777 8.19893Z"
                    fill="white"
                />
                <path
                    d="M167.595 10.9887C167.644 10.6461 167.203 10.5971 167.154 10.9397C167.055 11.2334 167.546 11.2823 167.595 10.9887Z"
                    fill="white"
                />
                <path
                    d="M166.909 10.1566C166.958 9.91188 166.664 9.91188 166.615 10.1076C166.566 10.3034 166.86 10.3034 166.909 10.1566Z"
                    fill="white"
                />
                <path
                    d="M167.939 9.81404C167.89 9.86298 167.841 9.91193 167.792 9.96087C167.742 9.81404 167.644 9.71616 167.595 9.66722C167.448 9.52039 167.35 9.66722 167.497 9.7651C167.595 9.86298 167.644 9.96087 167.693 10.0587C167.644 10.1077 167.546 10.1566 167.399 10.2545C167.301 10.3035 167.35 10.4013 167.448 10.3524C167.497 10.3524 167.595 10.2545 167.742 10.2056C167.841 10.3524 167.89 10.4992 167.939 10.5482C167.988 10.646 168.086 10.5482 168.037 10.4992C168.037 10.4503 167.939 10.3035 167.89 10.1566C167.939 10.1077 167.988 10.0587 168.037 10.0098C168.135 9.81404 168.037 9.71616 167.939 9.81404Z"
                    fill="white"
                />
                <path
                    d="M170.931 12.2611C170.98 12.4079 170.98 12.5547 170.882 12.6526C170.784 12.7994 170.686 12.8484 170.539 12.8484C170.49 12.8484 170.392 12.8484 170.343 12.7994C170.294 12.7994 170.245 12.7505 170.196 12.7015C170.097 12.6036 169.999 12.4079 169.95 12.2611C169.901 12.1632 169.705 12.1632 169.705 12.31C169.705 12.6526 169.999 13.0441 170.343 13.142C170.686 13.2399 171.078 13.0441 171.275 12.7015C171.373 12.5058 171.373 12.31 171.324 12.1142C171.275 12.0163 171.226 11.9674 171.127 11.9674C170.98 12.1142 170.882 12.2121 170.931 12.2611Z"
                    fill="#2A215B"
                />
                <path
                    opacity="0.89"
                    d="M170.393 4.28355C169.657 4.08779 168.872 4.08779 168.136 4.33249C167.989 4.38144 167.891 4.52826 167.891 4.67509C167.94 4.82191 168.087 4.91979 168.234 4.91979C168.872 4.72403 169.559 4.72403 170.197 4.87085C170.344 4.91979 170.54 4.82191 170.589 4.67509C170.638 4.47932 170.54 4.33249 170.393 4.28355Z"
                    fill="#BEE3D3"
                />
                <path
                    opacity="0.89"
                    d="M166.81 4.91979C166.663 4.96873 166.516 5.06662 166.418 5.11556C166.271 5.21344 166.124 5.31132 166.026 5.50709C165.977 5.55603 165.977 5.60497 165.977 5.65391C165.977 5.70286 166.026 5.7518 166.026 5.80074C166.124 5.89862 166.222 5.89862 166.32 5.84968C166.369 5.84968 166.418 5.80074 166.418 5.80074C166.467 5.7518 166.516 5.70286 166.565 5.70286L166.516 5.7518C166.565 5.70286 166.614 5.65391 166.663 5.65391L166.614 5.70286C166.761 5.60497 166.908 5.50709 167.056 5.45815C167.105 5.40921 167.154 5.36027 167.203 5.26238C167.203 5.21344 167.203 5.11556 167.203 5.01767C167.154 4.96873 167.105 4.87085 167.006 4.87085C166.957 4.87085 166.908 4.87085 166.81 4.91979Z"
                    fill="#BEE3D3"
                />
                <path
                    opacity="0.46"
                    d="M169.51 16.2743C170.785 16.1275 172.061 15.9806 173.337 15.8338C174.858 14.2677 176.28 11.3801 175.495 8.59047C174.858 6.14339 173.582 4.52832 171.816 3.84314C172.355 7.46481 172.11 11.1844 170.491 14.6103C170.197 15.1486 169.853 15.7359 169.51 16.2743Z"
                    fill="#6CC5A4"
                />
                <ellipse
                    rx="1.7011"
                    ry="3.06447"
                    transform="matrix(0.555276 -0.831666 0.832931 0.553378 137.739 34.9611)"
                    fill="#C28EEE"
                />
                <ellipse
                    rx="1.70349"
                    ry="3.06016"
                    transform="matrix(0.987397 -0.158261 0.15903 0.987274 173.889 28.5916)"
                    fill="#C28EEE"
                />
                <ellipse
                    rx="1.7011"
                    ry="3.06447"
                    transform="matrix(-0.555274 -0.831667 -0.83293 0.553379 210.472 28.8411)"
                    fill="#C28EEE"
                />
                <ellipse cx="155.367" cy="30.6" rx="1.36286" ry="1.36" fill="white" />
                <ellipse cx="194.209" cy="27.88" rx="1.36286" ry="1.36" fill="white" />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="173.007"
                    y1="152.842"
                    x2="251.933"
                    y2="238.645"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="240.176"
                    y1="148.176"
                    x2="319.104"
                    y2="233.98"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="17.9366"
                    y1="65.2665"
                    x2="68.3394"
                    y2="150.64"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="101.439"
                    y1="32.3184"
                    x2="194.032"
                    y2="189.155"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="40.5822"
                    y1="92.1955"
                    x2="90.9859"
                    y2="177.57"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="279.754"
                    y1="13.8441"
                    x2="375.328"
                    y2="113.864"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="246.502"
                    y1="-6.75892"
                    x2="342.075"
                    y2="93.2608"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="153.634"
                    y1="-23.3427"
                    x2="249.206"
                    y2="76.6782"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="194.544"
                    y1="12.2693"
                    x2="290.117"
                    y2="112.289"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="254.135"
                    y1="34.9425"
                    x2="349.708"
                    y2="134.961"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear"
                    x1="81.9666"
                    y1="22.0527"
                    x2="177.537"
                    y2="122.07"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear"
                    x1="262.485"
                    y1="54.3648"
                    x2="296.273"
                    y2="54.3648"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear"
                    x1="143.679"
                    y1="201.887"
                    x2="163.243"
                    y2="201.887"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6870B5" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <clipPath id="clip0-404">
                    <rect width="400" height="238" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(NotFoundIllustrationSVG);
