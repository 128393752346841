import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getFullName } from 'utils/name';
import Avatar from 'components/avatar/User';
import { getAvatarUrl } from 'utils/images';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    avatarUnassigned: {
        width: '20px',
        height: '20px'
    },
    ldPortal: {
        minWidth: 220
    },
    action: {
        cursor: 'pointer'
    }
}));

const TicketDetailAssignee = ({ assigneeId, members, changeAssignee, isAllowEdit }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const membersOption = useMemo(() => {
        let arr = [
            {
                value: '',
                label: 'Unassigned',
                icon: () => <AvatarIconSVG className={classes.avatarUnassigned} color="#DBDCE1" />
            }
        ];
        const mapMembers = (members || []).map(m => ({
            value: m.user.id,
            label: getFullName(m.user),
            icon: () => (
                <Avatar src={getAvatarUrl(m.user.id)} size={20} color="#DBDCE1">
                    <AvatarIconSVG />
                </Avatar>
            )
        }));
        arr = [...arr, ...mapMembers];
        return arr;
    }, [classes, members]);

    const handleAssigneeChange = useCallback(
        assignee => {
            changeAssignee({
                assignee: { id: assignee.value, name: assignee.label },
                assigneeId: assignee.value || null
            });
        },
        [changeAssignee]
    );

    const defaultMember = React.useMemo(() => {
        if (!assigneeId) return { value: '', label: 'Unassigned' };
        return {
            value: assigneeId,
            label: membersOption.find(m => m.value === assigneeId)?.label
        };
    }, [assigneeId, membersOption]);

    return (
        <Grid item>
            <Grid container direction="row">
                <Grid item xs={2}>
                    <p className="caption">{t('global_assignee')}</p>
                </Grid>
                <Grid item className={isAllowEdit ? classes.action : ''}>
                    {isAllowEdit ? (
                        <LDBasePortal
                            dropdownClassName={classes.ldPortal}
                            ddPlaceholder={t('select_an_assignee')}
                            menuPlaceholder={t('find_an_assignee')}
                            options={membersOption}
                            onChange={handleAssigneeChange}
                            defaultValue={defaultMember}
                            isUsingContainer
                            isUsingChildren
                        >
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    {assigneeId ? (
                                        <Avatar src={getAvatarUrl(assigneeId)} size={20} color="#DBDCE1">
                                            <AvatarIconSVG />
                                        </Avatar>
                                    ) : (
                                        <AvatarIconSVG className={classes.avatarUnassigned} color="#DBDCE1" />
                                    )}
                                </Grid>
                                <Grid item>
                                    <p className="body2">{defaultMember.label}</p>
                                </Grid>
                            </Grid>
                        </LDBasePortal>
                    ) : (
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                {assigneeId ? (
                                    <Avatar src={getAvatarUrl(assigneeId)} size={20} color="#DBDCE1">
                                        <AvatarIconSVG />
                                    </Avatar>
                                ) : (
                                    <AvatarIconSVG className={classes.avatarUnassigned} color="#DBDCE1" />
                                )}
                            </Grid>
                            <Grid item>
                                <p className="body2">{defaultMember.label}</p>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(TicketDetailAssignee);
