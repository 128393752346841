import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, Divider } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import Table from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import GridIcon from 'grids/GridIcon';
import { formatDateFromNow, getFriendlyDateTimeFromUnix } from 'utils/datetime';
import { useSyncHistoriesByCId } from 'hooks/integration';
import { useDispatch } from 'react-redux';
import * as integrationActions from 'integrations/actions';
import { SOURCE_STATUS, BATCH_STATUS } from 'const';
import * as workspaceActions from 'workspaces/actions';
import { useWorkspaceMapping } from 'hooks/workspace';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import WarningSVG from 'assets/images/svg/InfoIconSVG';
import ChipStatus from 'components/chipStatus/ChipStatus';
import { UpperCaseFirstCharacter } from 'utils/name';
import SyncSVG from 'assets/images/svg/SyncSVG';
import { getConnectorIcon } from 'utils/connector';
import { getBatchesApi } from 'services/workflow';
import Tooltip from 'components/tooltip/Base';
import * as gridActions from 'grids/actions';
import UserAvatar from 'components/avatar/User';
import * as memberActions from 'permission/actions/member';
import { useMemberMappingById } from 'hooks/permission/member';
import { getAvatarUrl } from 'utils/images';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(4)
    },
    mb5: {
        marginBottom: theme.spacing(5)
    },
    table: {
        height: 220
    },
    syncTable: {
        height: 'calc(100%)'
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    },
    syncSpin: {
        animation: `$spin 500ms infinite linear`
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    subRow: {
        padding: `24px 34px`
    },
    reset: {
        margin: 0,
        fontFamily: 'Roboto Mono, monospace !important',
        '& *': {
            fontFamily: 'Roboto Mono, monospace !important'
        },
        '& p': {
            margin: 0
        }
    },
    hoverLink: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

function Destination({ rowData }) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const gridName = rowData?.destinationGridName;
    const destinationProjectName = rowData?.destinationProjectName;
    const destinationDatabaseName = rowData?.destinationDatabaseName;

    const handleOpenGrid = React.useCallback(
        async e => {
            e.stopPropagation();

            dispatch(
                gridActions.getGridLastView({
                    dbId: rowData?.destinationDatabaseId,
                    gridId: rowData?.destinationGridId,
                    errorCallback: () => {},
                    successCallback: responseData => {
                        const { dbId, gridId, viewId, branchId } = responseData;
                        if (!dbId || !gridId || !viewId || !branchId) {
                            return;
                        }
                        history.push(
                            `/projects/${rowData?.destinationProjectId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}`
                        );
                        dispatch(gridActions.setSelectedGrid(gridId));
                    }
                })
            );
        },
        [dispatch, rowData, history]
    );

    return (
        <Grid container onClick={handleOpenGrid} direction="column">
            <Grid item>
                <Grid wrap="nowrap" container direction="row" spacing={1}>
                    <Grid item>
                        <GridIcon size="small" />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.hoverLink} variant="body2">
                            {gridName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    {[destinationProjectName, destinationDatabaseName]?.join(' / ')}
                </Typography>
            </Grid>
        </Grid>
    );
}

function Connector({ rowData }) {
    return (
        <Grid container wrap="nowrap" direction="row" spacing={2}>
            <Grid item>{getConnectorIcon(rowData?.sourceResponse?.connector)}</Grid>

            <Grid item>
                <Typography variant="body2">{rowData?.name}</Typography>
            </Grid>
        </Grid>
    );
}

function ActionsRenderer({ rowData }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleSyncConnection = React.useCallback(
        e => {
            stopPropagation(e);
            dispatch(
                integrationActions.syncConnection({
                    cId: rowData?.id,
                    success: () => {
                        console.log('sync okay');
                    },
                    error: () => {
                        console.log('sync failed');
                    }
                })
            );
        },
        [dispatch, rowData, stopPropagation]
    );

    return (
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <Grid item>
                <IconButton
                    disabled={rowData?.task}
                    className={rowData?.task && classes.syncSpin}
                    onClick={handleSyncConnection}
                >
                    <SyncSVG />
                </IconButton>
            </Grid>
        </Grid>
    );
}

const COLUMNS = [
    { label: i18n.t('source_name'), dataKey: 'type', flexGrow: 1, cellRenderer: Connector },
    {
        label: i18n.t('destination'),
        dataKey: 'destination',
        width: 300,
        cellRenderer: props => <Destination {...props} />
    },
    {
        label: i18n.t('last_sync'),
        dataKey: 'lastSync',
        flexGrow: 1,
        cellRenderer: props => (props?.rowData?.lastSync ? formatDateFromNow(props?.rowData?.lastSync) : null)
    },
    {
        label: '',
        dataKey: 'actions',
        width: 150,
        cellRenderer: props => <ActionsRenderer {...props} />
    }
];

function StatusRenderer({ rowData }) {
    const status = rowData?.status === SOURCE_STATUS.SUCCEEDED ? 'success' : rowData?.status;
    const isHasError = rowData?.hasError;
    return (
        <Grid container wrap="nowrap" direction="row" spacing={2}>
            <Grid item>
                <ChipStatus label={UpperCaseFirstCharacter(rowData?.status)} status={status} />
            </Grid>

            {isHasError && (
                <Tooltip title="Some errors occurred">
                    <Grid item>
                        <WarningSVG color={'#F8AD13'} />
                    </Grid>
                </Tooltip>
            )}
        </Grid>
    );
}

function LoadingRow({ maxWidth }) {
    return (
        <Grid
            item
            style={{
                width: `100%`,
                maxWidth,
                height: 7,
                background: `#EEF0F5`,
                borderRadius: 20
            }}
        />
    );
}

function RowExpandedRender({ id, detail, onDetailChange }) {
    const classes = useStyles();

    React.useEffect(() => {
        const fetchTaskDetail = async () => {
            try {
                const batches = await getBatchesApi(id);
                onDetailChange({ id, batches });
            } catch (error) {
                console.log(error);
            }
        };
        if (!detail) {
            fetchTaskDetail();
        }
    }, [id, detail, onDetailChange]);

    return (
        <Grid container className={classes.subRow} direction="column" wrap="nowrap" spacing={2}>
            <Grid item>
                <Typography variant="body1">Connection log</Typography>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            {detail?.length <= 0 ? (
                <Grid item>
                    <pre className={classes.reset}>No log found</pre>
                </Grid>
            ) : (
                <Grid item container direction="column" spacing={1}>
                    {detail?.map((log, index) => {
                        return (
                            <Grid key={index} item container direction="column" spacing={2}>
                                <Grid item>
                                    <pre className={classes.reset}>
                                        [{BATCH_STATUS?.[log?.status]}]
                                        <br />
                                        {log?.status === 'FAILED' ? (
                                            <p>
                                                Message: <strong>{log?.failedMessage}</strong>
                                            </p>
                                        ) : (
                                            ``
                                        )}
                                        Emit records: <strong>{log?.emittedRecordsCount}</strong>
                                        <br />
                                        Commit records: <strong>{log?.committedRecordsCount}</strong>
                                    </pre>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">--------------------------------</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </Grid>
    );
}

function ListSyncHistory({ fetchingSync }) {
    const classes = useStyles();
    const { id } = useParams();
    const memberById = useMemberMappingById();
    const syncHistories = useSyncHistoriesByCId(id);

    const [sorting, setSorting] = React.useState({});
    const [taskDetails, setTaskDetails] = React.useState({});

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const syncData = React.useMemo(() => {
        const cloneData = (syncHistories ? [...syncHistories] : [])?.sort(
            (a, b) => new Date(b?.audit?.createdDate) - new Date(a?.audit?.createdDate)
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA?.toString()?.toLowerCase() : '';
                valueB = valueB ? valueB?.toString()?.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, syncHistories]);
    const SYNC_COLUMNS = React.useMemo(
        () => [
            {
                label: '',
                dataKey: 'id',
                type: 'collapse',
                width: 40,
                cellRenderer: () => null,
                className: classes.center
            },

            {
                label: 'Time',
                sort: true,
                dataKey: 'createdAt',
                flexGrow: 1,
                cellRenderer: fetchingSync
                    ? () => <LoadingRow maxWidth={250} />
                    : props => getFriendlyDateTimeFromUnix(props?.rowData?.createdAt)
            },
            {
                label: 'Executor',
                sort: true,
                dataKey: 'createdBy',
                flexGrow: 1,
                cellRenderer: fetchingSync
                    ? () => <LoadingRow maxWidth={250} />
                    : ({ rowData }) => {
                          const user = memberById?.[rowData?.createdBy];

                          return (
                              <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                      <UserAvatar
                                          size="small"
                                          src={getAvatarUrl(user?.imageUrl)}
                                          alt={user?.fullName}
                                      />
                                  </Grid>
                                  <Grid item>
                                      <Typography variant="body2">{user?.fullName}</Typography>
                                  </Grid>
                              </Grid>
                          );
                      }
            },
            {
                label: 'Commit records',
                sort: true,
                dataKey: 'committedRecordsCount',
                flexGrow: 1,
                cellRenderer: fetchingSync
                    ? () => <LoadingRow maxWidth={120} />
                    : props => props?.rowData?.committedRecordsCount || 0
            },
            {
                label: 'Emit records',
                sort: true,
                dataKey: 'emittedRecordsCount',
                flexGrow: 1,
                width: 100,
                cellRenderer: fetchingSync
                    ? () => <LoadingRow maxWidth={120} />
                    : props => props?.rowData?.emittedRecordsCount || 0
            },
            {
                label: i18n.t('global_status'),
                dataKey: 'status',
                width: 150,
                sort: true,
                cellRenderer: fetchingSync
                    ? () => <LoadingRow maxWidth={120} />
                    : props => <StatusRenderer {...props} />
            }
        ],
        [fetchingSync, classes, memberById]
    );

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
                wrap="nowrap"
                spacing={1}
            >
                <Grid item>
                    <NotFoundBoxSVG />
                </Grid>
                <Grid item>
                    <Typography variant="h3">No sync history found</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">Sync history will be displayed here once it was executed</Typography>
                </Grid>
            </Grid>
        );
    }, []);

    const onDetailChange = React.useCallback(
        ({ id, batches }) => {
            setTaskDetails({
                ...taskDetails,
                [id]: batches
            });
        },
        [taskDetails]
    );

    const handleCollapse = React.useCallback(
        props => {
            const collapse = props?.collapse;
            const rowIndex = props?.rowIndex;
            const id = syncData?.[rowIndex]?.id;

            if (collapse) {
                const newTaskDetails = { ...taskDetails };
                delete newTaskDetails?.[id];
                setTaskDetails(newTaskDetails);
            }
        },
        [syncData, taskDetails]
    );

    return (
        <>
            <AutoSizer>
                {({ width, height }) => {
                    return (
                        <Table
                            data={
                                fetchingSync
                                    ? Array(5)
                                          .fill()
                                          .map(() => ({}))
                                    : syncData
                            }
                            columns={SYNC_COLUMNS}
                            width={width}
                            height={height}
                            onRowClick={() => {}}
                            onSort={handleOnSort}
                            noRowsRenderer={_noRowsRenderer}
                            rowExpandedRender={props => (
                                <RowExpandedRender
                                    {...props}
                                    onDetailChange={onDetailChange}
                                    detail={taskDetails?.[props?.id]}
                                />
                            )}
                            onCollapseByRowIndex={handleCollapse}
                        ></Table>
                    );
                }}
            </AutoSizer>
        </>
    );
}

function TableSyncHistory({ connection }) {
    const [sorting] = React.useState({});
    const workspaceMapping = useWorkspaceMapping();

    const connections = React.useMemo(() => {
        if (!connection) return [];
        return [connection];
    }, [connection]);

    const connectionsFormatted = React.useMemo(() => {
        return connections?.map(connection => ({
            ...connection,
            destinationProjectName: workspaceMapping?.[connection?.destinationProjectId]?.name
        }));
    }, [connections, workspaceMapping]);

    const data = React.useMemo(() => {
        const cloneData = [...connectionsFormatted];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                if (sortKey === 'createdAt') {
                    return sortType === 'desc'
                        ? new Date(valueA) - new Date(valueB)
                        : new Date(valueB) - new Date(valueA);
                }
                let sortVal = 0;

                valueA = valueA ? valueA?.toLowerCase() : '';
                valueB = valueB ? valueB?.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, connectionsFormatted]);
    return (
        <>
            <AutoSizer>
                {({ width, height }) => {
                    return (
                        <Table
                            data={data}
                            columns={COLUMNS}
                            width={width}
                            height={height}
                            onRowClick={() => {}}
                        ></Table>
                    );
                }}
            </AutoSizer>
        </>
    );
}

function ConnectionDetail({ connection }) {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [fetchingSync, setFetchingSync] = React.useState(true);

    React.useEffect(() => {
        if (id) {
            setFetchingSync(true);
            dispatch(
                integrationActions.getSyncHistoriesByCId({
                    cId: id,
                    success: () => {
                        setFetchingSync(false);
                    },
                    error: () => {
                        setFetchingSync(false);
                    }
                })
            );
        }
    }, [id, dispatch]);

    React.useEffect(() => {
        dispatch(memberActions.fetchMembers({}));
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspacesByGraphql({
                successCallback: () => {
                    console.log('fetch workspaces success');
                },
                errorCallback: () => {
                    console.log('fetch workspaces failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item className={classes.table}>
                <TableSyncHistory connection={connection} />
            </Grid>
            <Grid item style={{ height: '100%' }} wrap="nowrap" direction="column" container spacing={2}>
                <Grid item>
                    <Typography variant="body1">{t('sync_history')}</Typography>
                </Grid>
                <Grid item className={classes.syncTable}>
                    <ListSyncHistory fetchingSync={fetchingSync} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ConnectionDetail);
