import React from 'react';

function SyncConnectionIllustratorSVG({ color = '#78778B', ...rest }) {
    return (
        <svg width="207" height="251" viewBox="0 0 207 251" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M127.611 66.185C127.611 57.6201 124.359 49.4888 118.288 43.309C112.216 37.0208 103.976 33.4431 95.1947 33.3347C86.4129 33.2262 78.0648 36.4788 71.7766 42.6585C65.4884 48.7299 61.9107 56.9696 61.8022 65.7514L63.9706 65.7514C64.079 57.5117 67.4399 49.9225 73.2944 44.1764C79.2574 38.4303 86.955 35.3946 95.1947 35.503C112.108 35.7198 125.66 49.814 125.443 66.7271L127.611 66.7271C127.611 66.5103 127.611 66.4019 127.611 66.185Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
                strokeWidth="1.55624"
            />
            <path
                d="M77.0891 57.5108L75.7881 55.7761L63.2117 64.2326L54.7552 51.6562L52.9121 52.8488L62.6696 67.2683L77.0891 57.5108Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
                strokeWidth="1.55624"
            />
            <path
                d="M62.2495 200.936C62.2495 209.501 65.502 217.632 71.5733 223.812C77.6447 230.1 85.8844 233.678 94.6662 233.786C103.448 233.895 111.796 230.642 118.084 224.463C124.372 218.391 127.95 210.152 128.059 201.37L125.89 201.37C125.782 209.609 122.421 217.199 116.566 222.945C110.603 228.691 102.906 231.727 94.6662 231.618C77.7531 231.401 64.201 217.307 64.4178 200.394L62.2495 200.394C62.2495 200.611 62.2495 200.719 62.2495 200.936Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
                strokeWidth="1.55624"
            />
            <path
                d="M112.772 209.61L114.073 211.345L126.649 202.888L135.106 215.465L136.949 214.272L127.191 199.853L112.772 209.61Z"
                fill="#DAE0EC"
                stroke="#DAE0EC"
                strokeWidth="1.55624"
            />
            <path d="M12.5998 86.2695L0.0322266 98.8371H12.5998V86.2695Z" fill="#5DCDE3" fillOpacity="0.8" />
            <path
                d="M65.4506 86.2383H18.5213V104.76H0V172.638H65.4508V86.2383H65.4506ZM53.2289 143.569H12.2218V137.635H53.2289V143.569ZM53.2289 130.962H12.2218V125.028H53.2289V130.962ZM53.2289 118.355H12.2218V112.421H53.2289V118.355Z"
                fill="#5DCDE3"
                fillOpacity="0.8"
            />
            <path d="M71.385 101.113V178.572H14.8755V187.513H80.3261V101.113H71.385Z" fill="#EBEBF6" />
            <path
                d="M183.126 86.2422H136.197V104.763H117.675V172.642H183.126L183.126 86.2422ZM158.675 143.573H129.897V137.639H158.675V143.573ZM143.891 130.966H129.897V125.032H143.891V130.966ZM170.904 118.359H129.897V112.425H170.904V118.359Z"
                fill="#6E5BBE"
                fillOpacity="0.8"
            />
            <path d="M189.059 101.113V178.572H132.549V187.513H198V101.113H189.059Z" fill="#EBEBF6" />
            <path d="M130.753 86.2695L118.186 98.8371H130.753V86.2695Z" fill="#6E5BBE" fillOpacity="0.8" />
            <path
                d="M0 32.9108C0.719232 33.0372 4.96922 33.7955 6.3423 35.6914C7.38846 37.145 8.63078 40.3048 8.89232 40.9368V41C8.89232 41 8.89232 41 8.89232 40.9368V40.8736C9.08848 40.1784 10.0692 36.9554 10.9192 35.4387C12.0962 33.4164 16.2808 32.2788 17 32.0892C16.2808 31.9628 12.0308 31.2045 10.6577 29.3086C9.61154 27.855 8.36924 24.6952 8.1077 24.0632V24C8.1077 24 8.1077 24 8.1077 24.0632V24.1264C7.91154 24.8216 6.93077 28.0446 6.08077 29.5613C4.83846 31.5836 0.653847 32.7212 0 32.9108Z"
                fill="#2D97F2"
            />
            <path
                d="M29 239.58C30.0154 239.758 36.0154 240.829 37.9538 243.506C39.4308 245.558 41.1846 250.019 41.5539 250.911V251C41.5539 251 41.5539 251 41.5539 250.911V250.822C41.8308 249.84 43.2154 245.29 44.4154 243.149C46.0769 240.294 51.9846 238.688 53 238.42C51.9846 238.242 45.9846 237.171 44.0462 234.494C42.5692 232.442 40.8154 227.981 40.4462 227.089V227C40.4462 227 40.4462 227 40.4462 227.089V227.178C40.1692 228.16 38.7846 232.71 37.5846 234.851C35.8308 237.706 29.9231 239.312 29 239.58Z"
                fill="#F9772A"
                fillOpacity="0.4"
            />
            <path
                opacity="0.5"
                d="M183 59.5799C184.015 59.7584 190.015 60.829 191.954 63.5056C193.431 65.5576 195.185 70.0186 195.554 70.9108V71C195.554 71 195.554 71 195.554 70.9108V70.8216C195.831 69.8401 197.215 65.29 198.415 63.1487C200.077 60.2937 205.985 58.6877 207 58.4201C205.985 58.2416 199.985 57.171 198.046 54.4944C196.569 52.4424 194.815 47.9814 194.446 47.0892V47C194.446 47 194.446 47 194.446 47.0892V47.1784C194.169 48.1598 192.785 52.71 191.585 54.8513C189.831 57.7063 183.923 59.3123 183 59.5799Z"
                fill="#F8AD13"
            />
            <path
                d="M189.5 229C191.433 229 193 227.433 193 225.5C193 223.567 191.433 222 189.5 222C187.567 222 186 223.567 186 225.5C186 227.433 187.567 229 189.5 229Z"
                fill="#F9772A"
            />
            <path
                opacity="0.59"
                d="M143 12C146.314 12 149 9.31373 149 6.00002C149 2.68631 146.314 0 143 0C139.686 0 137 2.68631 137 6.00002C137 9.31373 139.686 12 143 12Z"
                fill="#5DCDE3"
            />
        </svg>
    );
}

export default React.memo(SyncConnectionIllustratorSVG);
