import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import EmptyGroupAccessSVG from 'assets/images/svg/EmptyGroupAccessSVG';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { marginTop: 150 }
}));

function NoMember({ className }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid className={`${classes.root} ${className}`} container direction="column" spacing={2} alignItems="center">
            <Grid item>
                <EmptyGroupAccessSVG />
            </Grid>
            <Grid item>
                <Typography variant="h4">{t('nomember_title')}</Typography>
            </Grid>
            <Grid item>
                <Grid container direction="column" wrap="nowrap" justify="center" alignItems="center">
                    <Grid item>
                        <Typography variant="caption">{t('nomember_text')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(NoMember);
