import { useSelector } from 'react-redux';

export function useIsOpenRecordHistory() {
    return useSelector(state => state.gridUI.isOpenRecordHistory);
}

export function useRecordHistory() {
    return useSelector(state => state.gridUI.recordHistory) || [];
}

export function useRecordHistoryId() {
    return useSelector(state => state.gridUI.recordHistoryId);
}

export function useRecordHistoryAction() {
    return useSelector(state => state.gridUI.recordHistoryAction);
}

export function useRecordHistoryType() {
    return useSelector(state => state.gridUI.recordHistoryType);
}

export function useRecordHistoryMessage() {
    return useSelector(state => state.gridUI.recordHistoryMessage);
}

export function useRecordHistoryIndex() {
    return useSelector(state => state.gridUI.recordHistoryIndex);
}

export function useTotalRecordHistory() {
    return useSelector(state => state.gridUI.totalRecordHistory) || 0;
}

export function useIsFetchingRecordHistory() {
    return useSelector(state => state.gridUI.isFetchingRecordHistory);
}

export function useIsTriggerRefreshRecordHistory() {
    return useSelector(state => state.gridUI.isTriggerRefreshRecordHistory);
}

export function useRECORD_HISTORY_ROW_STOP_INDEX() {
    return useSelector(state => state.gridUI.RECORD_HISTORY_ROW_STOP_INDEX);
}

export function useRECORD_HISTORY_ROW_START_INDEX() {
    return useSelector(state => state.gridUI.RECORD_HISTORY_ROW_START_INDEX);
}

export function useLatestRecordHistoryData() {
    const recordHistory = useRecordHistory();
    return recordHistory?.[0];
}
