import React from 'react';

function SmileFaceSVG({ color = '#93929E', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M13.8096 6.18356V4.731C13.8096 3.9321 13.1559 3.27845 12.357 3.27845H10.1782C10.1782 2.07282 9.20495 1.09961 7.99932 1.09961C6.7937 1.09961 5.82049 2.07282 5.82049 3.27845H3.64165C2.84274 3.27845 2.18909 3.9321 2.18909 4.731V6.18356C0.983468 6.18356 0.0102539 7.15678 0.0102539 8.3624C0.0102539 9.56802 0.983468 10.5412 2.18909 10.5412V13.4464C2.18909 14.2453 2.84274 14.8989 3.64165 14.8989H12.357C13.1559 14.8989 13.8096 14.2453 13.8096 13.4464V10.5412C15.0152 10.5412 15.9884 9.56802 15.9884 8.3624C15.9884 7.15678 15.0152 6.18356 13.8096 6.18356ZM12.357 13.4464H3.64165V4.731H12.357V13.4464ZM5.82049 9.08868C5.21767 9.08868 4.73107 8.60207 4.73107 7.99926C4.73107 7.39645 5.21767 6.90984 5.82049 6.90984C6.4233 6.90984 6.9099 7.39645 6.9099 7.99926C6.9099 8.60207 6.4233 9.08868 5.82049 9.08868ZM11.2676 7.99926C11.2676 8.60207 10.781 9.08868 10.1782 9.08868C9.57535 9.08868 9.08874 8.60207 9.08874 7.99926C9.08874 7.39645 9.57535 6.90984 10.1782 6.90984C10.781 6.90984 11.2676 7.39645 11.2676 7.99926ZM5.09421 10.88C5.09421 10.7277 5.25825 10.6287 5.39481 10.6959C5.93749 10.9631 7.10645 11.483 7.99005 11.4847C8.87993 11.4863 10.0586 10.9635 10.6039 10.6955C10.7405 10.6284 10.9044 10.7275 10.9044 10.8796V11.8606C10.9044 11.9413 10.8563 12.0138 10.7812 12.0433C10.3641 12.2072 8.97617 12.7216 7.99005 12.7201C7.01035 12.7186 5.63243 12.2068 5.21726 12.0433C5.14225 12.0138 5.09421 11.9413 5.09421 11.8607V10.88Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SmileFaceSVG);
