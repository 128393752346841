import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Theme from './theme';
import { Provider } from 'react-redux';
import store from './store/configStore';
import './css/main.css';
import { initialize } from './filestack';
import NotifierProvider from './notifier/NotifierProvider';
import { Helmet } from 'react-helmet';
import { envConfig } from './config';
import { DEVELOPMENT, EXTERNAL, INTEGRATION, LOCAL } from 'config/env';
import { ENV } from 'config';
import 'i18n';
import 'react-dates/initialize';

const isEnableConsole = [DEVELOPMENT, INTEGRATION, LOCAL, EXTERNAL].includes(ENV) ? true : false;

if (!isEnableConsole) {
    console.log = function() {};
}

initialize();
const theme = createTheme(Theme);
theme.shadows[1] = '0px 2px 10px rgba(0, 0, 0, 0.15)';

const MyApp = () => {
    return (
        <Provider store={store}>
            <Helmet>
                <title>{envConfig?.title || 'Gridly'}</title>
                <meta property="og:url" content={envConfig?.host} />
            </Helmet>
            <ThemeProvider theme={theme}>
                <NotifierProvider>
                    <App />
                </NotifierProvider>
            </ThemeProvider>
        </Provider>
    );
};

ReactDOM.render(<MyApp />, document.getElementById('root'));

serviceWorker.unregister();
