import React from 'react';

function CloseIconSVG({ color = '#78778B', ...rest }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M13.5 3.60738L9.10738 8L13.5 12.3926L12.3926 13.5L8 9.10738L3.60738 13.5L2.5 12.3926L6.89262 8L2.5 3.60738L3.60738 2.5L8 6.89262L12.3926 2.5L13.5 3.60738Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CloseIconSVG);
