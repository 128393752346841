import React from 'react';

function TMSearchNotFoundSVG({ color = '#93929e', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="208" height="135" fill="none" viewBox="0 0 208 135" {...other}>
            <path
                fill="#E9E9EF"
                d="M124.5 42.37H14.62C6.546 42.37 0 48.916 0 56.99c0 8.074 6.546 14.62 14.62 14.62H124.5c8.074 0 14.62-6.546 14.62-14.62 0-8.074-6.546-14.62-14.62-14.62z"
            ></path>
            <path
                fill="#F4F4F5"
                d="M124.5 38.36H14.62C6.546 38.36 0 44.906 0 52.98 0 61.054 6.546 67.6 14.62 67.6H124.5c8.074 0 14.62-6.546 14.62-14.62 0-8.074-6.546-14.62-14.62-14.62z"
            ></path>
            <path
                fill="#DAD7E2"
                d="M26.09 61.42l-5-5a7 7 0 10-1 1l5 5a.72.72 0 00.52.22.702.702 0 00.52-.22.72.72 0 00-.04-1zm-10.41-3.88a5.5 5.5 0 115.5-5.5 5.49 5.49 0 01-5.5 5.5z"
            ></path>
            <path
                fill="#E9E9EF"
                d="M145.449 57.75H42.159a11.63 11.63 0 00-11.63 11.63v4.92h54.4v1.81h-54.4v47a11.631 11.631 0 0011.63 11.63h103.29a11.63 11.63 0 0011.63-11.63V69.38a11.63 11.63 0 00-11.63-11.63z"
            ></path>
            <path
                fill="#fff"
                d="M37.24 69.41a1.91 1.91 0 100-3.82 1.91 1.91 0 000 3.82zM43.81 69.41a1.91 1.91 0 100-3.82 1.91 1.91 0 000 3.82zM50.78 69.41a1.91 1.91 0 100-3.82 1.91 1.91 0 000 3.82z"
            ></path>
            <path
                fill="#7F55A1"
                d="M84.24 111.45a36.156 36.156 0 01-11-3.43.426.426 0 01-.22-.28l-7.36 3.44a6.783 6.783 0 006 4.77 19.424 19.424 0 006.54-1 47.205 47.205 0 006.23-2.52.496.496 0 00.367-.579.5.5 0 00-.557-.401z"
            ></path>
            <path
                fill="#22C3F3"
                d="M72.59 115.92a14.647 14.647 0 002-7.26c.35.16.7.3 1.05.44a16.08 16.08 0 01-1.8 6.73c-.39.09-.85.09-1.25.09z"
            ></path>
            <path fill="#7F55A1" d="M69.76 114.54a4.25 4.25 0 100-8.5 4.25 4.25 0 000 8.5z"></path>
            <mask
                id="mask0_2146_37440"
                style={{ maskType: 'alpha' }}
                width="117"
                height="135"
                x="52"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M52 0H169V135H52z"></path>
            </mask>
            <g mask="url(#mask0_2146_37440)">
                <path
                    fill="url(#paint0_linear_2146_37440)"
                    d="M120.76 110.7l-16.33 1.82-16.34-1.82c-.34 1-9.26 9-8.62 28.51a4.45 4.45 0 004.42 4.32c3.3 0 7.88 0 11.13-.09a4.001 4.001 0 004-4 2.941 2.941 0 012.94-2.93h5a2.927 2.927 0 012.93 2.93 4 4 0 004 4c3.24.05 7.82.11 11.12.09a4.448 4.448 0 004.42-4.32c.56-19.47-8.33-27.49-8.67-28.51z"
                ></path>
                <path
                    fill="#fff"
                    d="M106.53 125.63h-4.19v2.63h4.19v-2.63zM106.53 122.38h-4.19v2.63h4.19v-2.63zM106.53 128.87h-4.19v2.63h4.19v-2.63zM111.331 124.3a1.922 1.922 0 00-1.92-1.92h-2.27v2.63h4.19v-.71zM99.45 131.5h2.269v-2.58h-4.19v.71a1.92 1.92 0 001.92 1.87zM111.331 125.63h-4.19v2.63h4.19v-2.63zM109.411 131.5a1.919 1.919 0 001.92-1.92v-.66h-4.19v2.63l2.27-.05zM99.45 122.38a1.92 1.92 0 00-1.92 1.92v.71h4.189v-2.63h-2.27zM101.719 125.63h-4.19v2.63h4.19v-2.63z"
                ></path>
                <path
                    fill="url(#paint1_linear_2146_37440)"
                    d="M116.779 7.12c-1.35.61-2.72 1.2-4.06 1.84a3.337 3.337 0 00-1.946 2.708 3.335 3.335 0 001.366 3.042l11.87 9.7a8.36 8.36 0 004.39 2 8.518 8.518 0 004.76-.74l10-4.47 3.92-1.75a3.342 3.342 0 001.955-4.448 3.335 3.335 0 00-1.115-1.402l-9.13-7.5-4 1.78a3.34 3.34 0 00-.69 5.79l1.51 1.23-6 2.65-3.81-3.13-1.31-1.06 4.37-1.88a3.337 3.337 0 001.736-4.254 3.338 3.338 0 00-.976-1.356l-3.51-2.86-9.25 4.1"
                ></path>
                <path
                    fill="#fff"
                    d="M126.311 6.625l-.381-.892a.42.42 0 00-.551-.222l-7.202 3.07a.42.42 0 00-.222.552l.38.892a.42.42 0 00.551.222l7.203-3.071a.42.42 0 00.222-.551z"
                    opacity="0.47"
                ></path>
                <path
                    fill="#FAAF1B"
                    d="M67.52 45.67c-4-1.18-9.1 4.21-11.49 12.07l.06-.24c2.92-9.8 8.53-16.77 12.55-15.58 4.02 1.19 4.91 10.11 2 19.9a2.28 2.28 0 00-.08.24c2.29-7.89.94-15.2-3.04-16.39z"
                ></path>
                <path
                    fill="#22C3F3"
                    d="M58.99 74.53c3.69 1.1 8.34-3.44 10.91-10.34-3.05 8.52-8.07 14.3-11.76 13.2-3.69-1.1-4.73-8.68-2.63-17.48-1.67 7.19-.25 13.53 3.48 14.62z"
                ></path>
                <path
                    fill="#E43383"
                    d="M83.6 41.53a10.17 10.17 0 015.81 4.95 8.22 8.22 0 00-3.33-2c-6.86-2-15.2 5.67-18.64 17.23-3.44 11.56-.65 22.57 6.2 24.6a8 8 0 003.86.17 10.14 10.14 0 01-7.56 1c-7.53-2.24-10.58-14.34-6.81-27C66.9 47.82 76.06 39.3 83.6 41.53z"
                ></path>
                <path
                    fill="url(#paint2_linear_2146_37440)"
                    d="M173.991 81.75c-.54-8.43-1.561-17.15-5.341-24.79-.41-.83-.85-1.64-1.31-2.44-8.72-14.86-26.25-22.85-43-23.92-15-1-31 3.34-41.38 14.64-7 7.56-11 17.41-12.84 27.44-1.25 6.86-2.24 15 1.39 21.42 16.37 29 73.18 35.49 95.7 11.59 6.03-6.42 7.291-15.49 6.781-23.94z"
                ></path>
                <path
                    fill="#9863A9"
                    d="M117.991 93.43a108.717 108.717 0 01-26-6.15c-6.33-2.42-9.84-5.78-10.44-10-1.38-9.79 2-22.79 11.29-28.72a34 34 0 0118.45-5.39 115.098 115.098 0 0129.34 3.55 34.003 34.003 0 0116.63 9.64c7.56 8 7.79 21.41 4.11 30.58-1.47 3.65-5.38 6-11.64 7-9.2 1.49-19.54.75-29.34-.28-.8-.08-1.6-.17-2.4-.27v.04z"
                ></path>
                <path
                    fill="#121212"
                    d="M118.58 90.42a103.38 103.38 0 01-23.7-5.5c-5.78-2.15-9-5.11-9.55-8.81-1.3-8.59 1.77-20 10.16-25.16a31.581 31.581 0 0116.79-4.65 104.26 104.26 0 0113.47.79c4.46.536 8.882 1.35 13.24 2.44a31.65 31.65 0 0115.19 8.54c6.91 7 7.18 18.82 3.86 26.85-1.32 3.19-4.87 5.25-10.57 6.11-8.37 1.27-17.79.58-26.72-.36l-2.19-.25h.02z"
                ></path>
                <path
                    fill="#17171A"
                    d="M118.839 88.3a100.894 100.894 0 01-23.22-5.38c-5-1.85-7.74-4.25-8.18-7.12-1.2-7.89 1.57-18.34 9.17-23a29.63 29.63 0 0115.68-4.34c4.412-.022 8.821.239 13.2.78 4.379.523 8.721 1.32 13 2.39a29.42 29.42 0 0114.19 8c6.27 6.35 6.46 17.15 3.41 24.54-1 2.45-4 4.07-8.91 4.81-8.13 1.24-17.39.55-26.18-.38-.72-.07-1.44-.15-2.15-.24l-.01-.06z"
                ></path>
                <path
                    fill="#fff"
                    d="M98.66 44.14l1.36-.86-4.6-7.28c-.48.24-1 .47-1.43.73l4.67 7.41zM159.38 44.79c-.4-.36-.82-.71-1.22-1l-6.42 6.13 1.12 1.16 6.52-6.29z"
                ></path>
                <path
                    fill="url(#paint3_linear_2146_37440)"
                    d="M157.99 54.32a36.814 36.814 0 00-17.68-10 114.506 114.506 0 00-28.08-3.4 36.72 36.72 0 00-19.54 5.45c-10 6.15-14.34 19.36-12.65 30.5 1 6.86 6.91 10.76 13 13a111.507 111.507 0 0027.19 6.06c9.11 1 19 1.71 28.08.33 6-.91 12.24-3.37 14.71-9.35 4.27-10.36 3.22-24.24-5.03-32.59zm2 31.42c-1.62 3.93-5.73 6.42-12.22 7.41-8.6 1.3-18.19.6-27.27-.36l-2.22-.25a105.549 105.549 0 01-24.15-5.62c-6.56-2.44-10.23-6-10.91-10.49-1.41-9.29 2-21.64 11.16-27.3a33.65 33.65 0 0117.88-5 111.84 111.84 0 0127.27 3.3 33.705 33.705 0 0116.19 9.11c7.57 7.7 7.9 20.51 4.27 29.2z"
                ></path>
                <path
                    fill="#fff"
                    d="M126.268 6.976l-.381-.892a.42.42 0 00-.551-.222l-7.202 3.071a.42.42 0 00-.222.551l.38.893a.42.42 0 00.551.221l7.203-3.07a.42.42 0 00.222-.552zM116.27 11.95a1.11 1.11 0 100-2.22 1.11 1.11 0 000 2.22z"
                    opacity="0.47"
                ></path>
                <path
                    fill="url(#paint4_linear_2146_37440)"
                    d="M112.341 48.1a29.46 29.46 0 00-15.69 4.34c-7.6 4.67-10.36 15.12-9.17 23 .44 2.86 3.19 5.26 8.18 7.12l1.16.4 2.8-.86 5.29-25.18a8.122 8.122 0 015-5.88l7.23-2.88c-1.65-.03-3.25-.08-4.8-.06z"
                ></path>
                <path
                    fill="url(#paint5_linear_2146_37440)"
                    d="M88.99 110.8l-.88-.1a13.94 13.94 0 01-1.23 1.68c11.52 6.67 26.181 10 40.211 9.65-.21-.61-.43-1.19-.65-1.74-13.17-.06-26.67-3.37-37.45-9.49z"
                ></path>
            </g>
            <path
                fill="#E1EDF9"
                d="M195.48 105.67a29.713 29.713 0 00-20.469-50.942 29.709 29.709 0 00-21.531 50.502 29.706 29.706 0 0020.909 8.914 29.706 29.706 0 0021.091-8.474z"
            ></path>
            <path
                fill="#22C3F3"
                d="M131.3 123.92c.176.751.554 1.44 1.093 1.992a4.24 4.24 0 001.967 1.138 5 5 0 002.21.1 3.16 3.16 0 001.46-.66c.74-.68 1.44-1.4 2.14-2.11.7-.71 1.4-1.45 2.08-2.19.68-.74 1.35-1.49 2-2.24 1.32-1.52 2.61-3.07 3.83-4.69.56-.73 1.11-1.48 1.64-2.24a2.755 2.755 0 002.9-.6l1.13-1.11a2.83 2.83 0 00.49-.66 33.169 33.169 0 10-6.16-6.28 2.522 2.522 0 00-.66.48l-1.13 1.1a2.758 2.758 0 00-.67 2.88c-.77.52-1.53 1-2.27 1.6a91.088 91.088 0 00-4.77 3.73c-.767.667-1.527 1.334-2.28 2-.76.66-1.5 1.34-2.23 2-.73.66-1.47 1.37-2.16 2.1-.343.421-.58.918-.69 1.45a4.912 4.912 0 00.08 2.21zm21.29-17.78a30.943 30.943 0 01-6.362-33.786 30.942 30.942 0 0145.915-13.414 30.937 30.937 0 0112.828 31.896 30.938 30.938 0 01-8.631 15.754 30.938 30.938 0 01-43.75-.5v.05z"
            ></path>
            <path fill="#000" d="M149.741 112.97l-4.1-4.19a7.187 7.187 0 004.1 4.19z" opacity="0.3"></path>
            <path
                fill="#22C3F3"
                d="M141.609 65.92c-.49.87-1 1.78-1.37 2.71l-.2.45 1.17.53.2-.46c.4-.88.84-1.76 1.32-2.6l.24-.44-1.12-.63-.24.44zM147.919 57.76l-.36.35a35.285 35.285 0 00-4.76 5.8l-.27.42 1.07.7.28-.42a33.008 33.008 0 014.58-5.58l.36-.35-.9-.92z"
            ></path>
            <path
                fill="#fff"
                d="M174.24 102.67c10.776 0 19.51-8.735 19.51-19.51 0-10.775-8.734-19.51-19.51-19.51-10.775 0-19.51 8.735-19.51 19.51 0 10.775 8.735 19.51 19.51 19.51z"
            ></path>
            <path
                fill="#E5E5E5"
                d="M174.241 63.65c.826.006 1.651.063 2.47.17a19.501 19.501 0 00-12.151 32.217 19.502 19.502 0 0012.151 6.463 20.2 20.2 0 01-2.47.17 19.508 19.508 0 01-18.886-19.5 19.509 19.509 0 0118.886-19.5v-.02z"
            ></path>
            <path
                fill="#17171A"
                d="M182.91 92.25a8.83 8.83 0 008.83-8.83 8.83 8.83 0 00-8.83-8.83 8.83 8.83 0 00-8.83 8.83 8.83 8.83 0 008.83 8.83z"
            ></path>
            <path
                fill="#fff"
                d="M105.16 68.72l17.09 3.58a1 1 0 00.82-1.77l-8.4-6.55a1.012 1.012 0 00-.743-.204.996.996 0 00-.667.384 1 1 0 00.18 1.4l5 3.9-12.87-2.7a1.003 1.003 0 00-1.038.417.982.982 0 00-.152.363 1.007 1.007 0 00.42 1.029 1 1 0 00.36.15zM109.629 80.4a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2z"
            ></path>
            <path fill="#7F55A1" d="M141.091 127.41a3.96 3.96 0 100-7.92 3.96 3.96 0 000 7.92z"></path>
            <path fill="#fff" d="M186.05 83.73a2.29 2.29 0 100-4.58 2.29 2.29 0 000 4.58z"></path>
            <defs>
                <linearGradient
                    id="paint0_linear_2146_37440"
                    x1="104.46"
                    x2="83.64"
                    y1="133.41"
                    y2="87.97"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98"></stop>
                    <stop offset="0.51" stopColor="#8964A9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2146_37440"
                    x1="136.321"
                    x2="103.185"
                    y1="21.913"
                    y2="-18.437"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98"></stop>
                    <stop offset="0.67" stopColor="#8964A9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2146_37440"
                    x1="133.502"
                    x2="113.094"
                    y1="110.678"
                    y2="57.648"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#693F98"></stop>
                    <stop offset="1" stopColor="#8964A9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2146_37440"
                    x1="96.84"
                    x2="161.682"
                    y1="51.377"
                    y2="99.578"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.31" stopColor="#fff"></stop>
                    <stop offset="1" stopColor="#D2C5E1"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_2146_37440"
                    x1="112.597"
                    x2="92.605"
                    y1="53.941"
                    y2="72.118"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.25" stopColor="#353537"></stop>
                    <stop offset="1" stopColor="#17171A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_2146_37440"
                    x1="107.941"
                    x2="95.831"
                    y1="119.18"
                    y2="92.74"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#60388C"></stop>
                    <stop offset="0.62" stopColor="#3F2356"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(TMSearchNotFoundSVG);
