import * as types from '../types';

export function setRowIndexMap({ rowIndexMap }) {
    return {
        type: types.SET_ROW_INDEX_MAP,
        payload: {
            rowIndexMap
        }
    };
}

export function updateRowStopIndexMap({ rows }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { ROW_START_INDEX, rowStopIndex, rowIndexMap, ROW_STOP_INDEX } = gridUI;

        if (rowStopIndex >= ROW_START_INDEX && rowStopIndex <= ROW_STOP_INDEX) {
            const rowStopId = rows?.[Math.max(rowStopIndex - ROW_START_INDEX, 0)];
            const newRowIndexMap = {
                ...rowIndexMap,
                [rowStopIndex]: rowStopId
            };
            dispatch(
                setRowIndexMap({
                    rowIndexMap: newRowIndexMap
                })
            );
        }
    };
}
