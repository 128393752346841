import React from 'react';

function UnderlineSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M7.00602542,19.096991 L16.8790056,19.1538559 L16.8729802,20.1999995 L7,20.1431346 L7.00602542,19.096991 Z M8.98365039,5 L8.98365039,12.2682032 C8.98365039,13.1975429 9.23734442,13.9115477 9.74473248,14.4102178 C10.2521205,14.9088878 10.998382,15.1582228 11.9835169,15.1582228 C12.9233182,15.1582228 13.6469128,14.908016 14.1543009,14.4076024 C14.661689,13.9071887 14.915383,13.1896967 14.915383,12.2551262 L14.915383,5 L16.202161,5 L16.202161,12.208049 C16.202161,13.4773909 15.8185686,14.4756028 15.0513839,15.2026847 C14.2841992,15.9297666 13.2301921,16.2924357 11.8893624,16.2907045 C10.5485328,16.2907045 9.51108987,15.9245357 8.7770336,15.1922231 C8.04297734,14.4599104 7.6759492,13.4547241 7.6759492,12.1766641 L7.6759492,5 L8.98365039,5 Z"
                />
            </g>
        </svg>
    );
}

export default React.memo(UnderlineSVG);
