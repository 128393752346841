import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 470
    },
    body: {
        marginTop: 12
    }
}));

function AuthFormWrapper({ title = 'Form Title', subTitle, className, children }) {
    const classes = useStyles();
    return (
        <Grid container direction="column" alignItems="center" className={`${classes.root} ${className || ''}`}>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item className={classes.title}>
                        <Typography variant="h1">{title}</Typography>
                    </Grid>
                    {subTitle && (
                        <Grid item>
                            <Typography variant="subtitle1">{subTitle}</Typography>
                        </Grid>
                    )}
                    <Grid item className={classes.body}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

AuthFormWrapper.propTypes = {
    title: PropTypes.any.isRequired,
    children: PropTypes.object.isRequired
};

export default AuthFormWrapper;
