import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Badge, Grid } from '@material-ui/core';
import { SIDEBAR_LEFT_CONTENT, ROW_HEIGHT, GLOBAL_FILTER_HEIGHT } from 'const/gridUI';
import Tree from './Tree';
import * as gridUIActions from '../actions';
import { useDispatch } from 'react-redux';
import { useIsCreatingNode, useIsDraggingPathTag } from 'hooks/gridUI/pathTag';
import { useRole } from 'hooks/auth/role';
import { sendManualTrack } from 'tracker';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import { DISABLED_OPACITY } from 'const/style';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import MultipleRowSVG from 'assets/images/svg/MultipleRowSVG';
import { useDragNodeIds } from 'hooks/gridUI';
import { HEADER_HEIGHT } from 'const';
import { AutoSizer } from 'react-virtualized-dn';
import { useIsUserBlockedGridByPayment } from 'hooks/payment';
import { pathNodesObj } from 'utils/gridUI/pathTag';

const SPACING = 10;
const useStyles = makeStyles(theme => ({
    root: {
        width: SIDEBAR_LEFT_CONTENT,
        userSelect: 'none',
        height: `calc(100vh - ${HEADER_HEIGHT + GLOBAL_FILTER_HEIGHT}px)`
    },
    header: {
        height: ROW_HEIGHT,
        borderBottom: `1px solid ${theme.colors.border}`,
        // borderTop: `1px solid ${theme.colors.border}`,
        background: `${theme.colors.paleGrey} !important`,
        width: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderRight: `1px solid ${theme.colors.border}`
    },
    spacing: {
        paddingTop: SPACING,
        paddingBottom: SPACING
    },
    groupTagContent: {
        flex: 1,
        maxWidth: SIDEBAR_LEFT_CONTENT
    },
    footer: {
        height: ROW_HEIGHT,
        borderTop: `1px solid ${theme.colors.border}`
    },
    addButton: {
        width: 272,

        [theme.breakpoints.down('xs')]: {
            width: 150
        }
    },
    highlight: {
        color: theme.colors.lightLavender
    },
    pointer: {
        cursor: 'pointer'
    },
    dlFlex: {
        display: 'flex'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    key: {
        borderRadius: 5,
        border: `1px solid ${theme.colors.border}`,
        background: theme.colors.ghostwhite,
        boxSizing: 'border-box',
        boxShadow: `1px 1px 0px rgba(0, 0, 0, 0.2)`,
        minWidth: 26,
        height: 26,
        paddingLeft: 7,
        paddingRight: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.dimGrey
    }
}));

function PathTag({ isShareViewLink, t }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isCreatingNode = useIsCreatingNode();
    const isDraggingPathTag = useIsDraggingPathTag();
    const roles = useRole();
    const accessEditRecords = roles[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const [openedNodeIds, setOpenedNodeIds] = React.useState([]);
    const dragNodeIds = useDragNodeIds();
    const isDraggingRef = React.useRef(false);
    const isUserBlockedGridByPayment = useIsUserBlockedGridByPayment();

    useEffect(() => {
        isDraggingRef.current = isDraggingPathTag;
    }, [isDraggingPathTag]);

    const handleAddRootTag = () => {
        sendManualTrack({ type: `Add Path` });
        dispatch(
            gridUIActions.addRootNode({
                successCallback: () => {
                    console.log('add root node success');
                },
                errorCallback: () => {
                    console.log('failed to add root  node');
                }
            })
        );
    };

    const handleMouseMove = React.useCallback(
        e => {
            if (!isDraggingPathTag || !isDraggingRef.current) return;
            let dragPathReview = document.getElementById('dragPathReview');
            const left = e.clientX;
            const top = e.clientY;

            if (dragPathReview) {
                dragPathReview.style.left = `${left - 14}px`;
                dragPathReview.style.top = `${top - HEADER_HEIGHT - 36 - 20}px`;
            }

            const value = e.target && e.target.classList && e.target.classList.value;
            const matchesPathTag = value.match(/PATH_TAG([^&]*)_([^&]*) ?/);
            const pathTagClassName = matchesPathTag && matchesPathTag[0].split(' ');
            console.log('pathTagClassName', pathTagClassName);

            if (pathTagClassName && pathTagClassName[0].includes('PATH_TAG_')) {
                const currentSelected = pathTagClassName[0];
                const nodeId = currentSelected?.split(`PATH_TAG_`)?.[1];
                console.log('nodeId', nodeId);
                console.log('currentSelected', currentSelected);
                if (nodeId && accessEditRecords === roleConst.FULL) {
                    dispatch(gridUIActions.setDraggingPath(nodeId));
                    dispatch(gridUIActions.handleSetDropPath({ nodeId }));
                    const hasChildren = pathNodesObj[nodeId]?.children?.length > 0;
                    if (
                        !openedNodeIds.includes(nodeId) &&
                        hasChildren &&
                        !['BEFORE', 'AFTER'].some(e => nodeId.includes(e))
                    ) {
                        setOpenedNodeIds([...openedNodeIds, nodeId]);
                    }
                }
            } else {
                dispatch(gridUIActions.setDropPath({ nodeId: null }));
            }
        },
        [isDraggingPathTag, dispatch, accessEditRecords, openedNodeIds]
    );

    const handleMouseStop = React.useCallback(
        e => {
            isDraggingRef.current = false;
            let dragPathReview = document.getElementById('dragPathReview');
            if (dragPathReview) {
                dragPathReview.style.left = `-10000px`;
                dragPathReview.style.top = `-10000px`;
            }
            if (isDraggingPathTag) {
                dispatch(gridUIActions.turnOffDraggingPathTag());
                dispatch(gridUIActions.handleDropPath());
            }
        },
        [isDraggingPathTag, dispatch]
    );

    return (
        <Grid
            onMouseUp={handleMouseStop}
            onMouseMove={handleMouseMove}
            container
            className={classes.root}
            direction="column"
            wrap="nowrap"
        >
            <Grid item className={classes.header} container alignItems="center" justify="center">
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <p className="body1">{t(`path_directory`)}</p>
                    </Grid>
                    {!isUserBlockedGridByPayment && (
                        <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD}>
                            {({ isReadOnly }) => (
                                <Grid
                                    item
                                    className={`${classes.pointer} ${isCreatingNode ? classes.disabled : ``}`}
                                    onClick={handleAddRootTag}
                                >
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item className={classes.dlFlex}>
                                            <ColorAddSVG />
                                        </Grid>
                                        <Grid item>
                                            <p className="body1 text-lavender-light">{t(`path_tag_add_new_path`)}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </AccessControl>
                    )}
                </Grid>
            </Grid>
            <Grid item className={classes.groupTagContent}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Tree
                            isShareViewLink={isShareViewLink}
                            openedNodeIds={openedNodeIds}
                            setOpenedNodeIds={setOpenedNodeIds}
                            height={height}
                            width={width}
                        />
                    )}
                </AutoSizer>
            </Grid>

            <div
                id="dragPathReview"
                style={{
                    position: 'absolute',
                    top: -10000,
                    left: -10000,
                    zIndex: theme.zIndex.drawer + 1,
                    boxShadow: theme.shadows[1],
                    pointerEvents: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Badge color="primary" badgeContent={dragNodeIds.length}>
                    <MultipleRowSVG />
                </Badge>
            </div>
        </Grid>
    );
}

export default React.memo(PathTag);
