import React from 'react';

function EmptyGroupAccessSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="160" height="128" viewBox="0 0 160 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.281 27.5323C17.2445 23.4997 23.2245 21.2748 29.5522 21.7615C59.7304 23.8473 87.0576 48.8772 117.305 31.2868C127.527 25.3074 137.192 20.9967 147.692 30.1743C167.788 47.6257 144.911 58.1939 138.653 73.3509C135.663 80.5122 137.818 89.5508 143.729 94.6263C149.639 99.7018 168.135 106.863 155.897 116.805C153.116 119.1 149.431 120.073 145.884 120.838C121.477 126.261 96.3058 128.625 71.2732 128.069C51.8729 127.582 17.3836 128.347 4.03286 111.382C-4.58948 100.397 1.04286 83.78 13.9764 79.3997C18.7047 77.8006 24.8238 77.1749 26.7013 72.5861C31.082 61.8788 11.8903 52.7707 9.38705 43.9407C7.44007 37.2661 9.31752 31.4953 13.281 27.5323Z"
                fill="#F7F8FA"
            />
            <path
                d="M142.06 98.7988H145.745V102.484C145.745 103.179 146.302 103.735 146.997 103.735C147.692 103.735 148.249 103.179 148.249 102.484V98.7988H151.934C152.629 98.7988 153.186 98.2426 153.186 97.5473C153.186 96.852 152.629 96.2958 151.934 96.2958H148.249V92.6109C148.249 91.9156 147.692 91.3594 146.997 91.3594C146.302 91.3594 145.745 91.9156 145.745 92.6109V96.2958H142.06C141.365 96.2958 140.808 96.852 140.808 97.5473C140.808 98.2426 141.365 98.7988 142.06 98.7988Z"
                fill="#E9EAF0"
                fillOpacity="0.8"
            />
            <path
                d="M34.9064 33.6505H31.221V29.896C31.221 29.2008 30.6647 28.6445 29.9694 28.6445C29.274 28.6445 28.7177 29.2008 28.7177 29.896V33.581H25.0324C24.337 33.581 23.7808 34.1372 23.7808 34.8325C23.7808 35.5277 24.337 36.084 25.0324 36.084H28.7177V39.7689C28.7177 40.4642 29.274 41.0204 29.9694 41.0204C30.6647 41.0204 31.221 40.4642 31.221 39.7689V36.084H34.9064C35.6017 36.084 36.158 35.5277 36.158 34.8325C36.0885 34.1372 35.5322 33.6505 34.9064 33.6505Z"
                fill="#E9EAF0"
            />
            <path
                d="M22.1813 99.4252C23.0646 99.4252 23.7806 98.7091 23.7806 97.8259C23.7806 96.9426 23.0646 96.2266 22.1813 96.2266C21.2981 96.2266 20.582 96.9426 20.582 97.8259C20.582 98.7091 21.2981 99.4252 22.1813 99.4252Z"
                fill="#E9EAF0"
                fillOpacity="0.8"
            />
            <path
                d="M148.874 52.7713C149.796 52.7713 150.543 52.0241 150.543 51.1024C150.543 50.1808 149.796 49.4336 148.874 49.4336C147.953 49.4336 147.206 50.1808 147.206 51.1024C147.206 52.0241 147.953 52.7713 148.874 52.7713Z"
                fill="#E9EAF0"
                fillOpacity="0.8"
            />
            <path
                d="M82.8854 0C80.6602 0 78.9219 1.80791 78.9219 3.96349V9.03955H86.8489C86.8489 9.03955 86.8489 5.00652 86.8489 3.96349C86.8489 1.80791 85.0409 0 82.8854 0ZM82.8854 6.25815C81.6337 6.25815 80.6602 5.28466 80.6602 4.03303C80.6602 2.7814 81.6337 1.80791 82.8854 1.80791C84.137 1.80791 85.1105 2.7814 85.1105 4.03303C85.1105 5.28466 84.0675 6.25815 82.8854 6.25815Z"
                fill="#93929F"
            />
            <path
                d="M114.176 110.479H50.065C46.3101 110.479 43.3201 107.42 43.3201 103.735V14.0449C43.3201 10.2905 46.3796 7.30078 50.065 7.30078H114.246C118.001 7.30078 120.991 10.36 120.991 14.0449V103.735C120.991 107.49 117.931 110.479 114.176 110.479Z"
                fill="#ACADBA"
            />
            <path
                d="M111.533 105.264H52.6372C49.9254 105.264 47.7698 103.108 47.7698 100.397V16.5466C47.7698 13.835 49.9254 11.6797 52.6372 11.6797H111.533C114.245 11.6797 116.401 13.835 116.401 16.5466V100.397C116.401 103.108 114.245 105.264 111.533 105.264Z"
                fill="white"
            />
            <path
                d="M106.18 15.0183H58.0615V11.1247C58.0615 8.41316 60.2171 6.25781 62.929 6.25781H101.312C104.024 6.25781 106.18 8.41316 106.18 11.1247V15.0183Z"
                fill="#93929F"
            />
            <path
                d="M63.8328 42.8284C68.1724 42.8284 71.6903 39.3109 71.6903 34.9718C71.6903 30.6328 68.1724 27.1152 63.8328 27.1152C59.4932 27.1152 55.9753 30.6328 55.9753 34.9718C55.9753 39.3109 59.4932 42.8284 63.8328 42.8284Z"
                fill="#ACADBA"
            />
            <path
                d="M88.935 31.7034H77.114C76.4882 31.7034 76.0015 31.2167 76.0015 30.591C76.0015 29.9652 76.4882 29.4785 77.114 29.4785H88.935C89.5608 29.4785 90.0475 29.9652 90.0475 30.591C90.0475 31.2167 89.4912 31.7034 88.935 31.7034Z"
                fill="#C9C9CE"
            />
            <path
                d="M104.65 36.7796H77.114C76.4882 36.7796 76.0015 36.2929 76.0015 35.6671C76.0015 35.0414 76.4882 34.5547 77.114 34.5547H104.65C105.276 34.5547 105.762 35.0414 105.762 35.6671C105.693 36.2929 105.206 36.7796 104.65 36.7796Z"
                fill="#E9EAF0"
            />
            <path
                d="M104.65 41.8557H77.114C76.4882 41.8557 76.0015 41.369 76.0015 40.7433C76.0015 40.1176 76.4882 39.6309 77.114 39.6309H104.65C105.276 39.6309 105.762 40.1176 105.762 40.7433C105.762 41.369 105.206 41.8557 104.65 41.8557Z"
                fill="#E9EAF0"
            />
            <path
                d="M63.8328 66.8167C68.1724 66.8167 71.6903 63.2992 71.6903 58.9601C71.6903 54.621 68.1724 51.1035 63.8328 51.1035C59.4932 51.1035 55.9753 54.621 55.9753 58.9601C55.9753 63.2992 59.4932 66.8167 63.8328 66.8167Z"
                fill="#ACADBA"
            />
            <path
                d="M88.935 55.6917H77.114C76.4882 55.6917 76.0015 55.205 76.0015 54.5792C76.0015 53.9535 76.4882 53.4668 77.114 53.4668H88.935C89.5608 53.4668 90.0475 53.9535 90.0475 54.5792C90.0475 55.205 89.4912 55.6917 88.935 55.6917Z"
                fill="#C9C9CE"
            />
            <path
                d="M104.65 60.7678H77.114C76.4882 60.7678 76.0015 60.2812 76.0015 59.6554C76.0015 59.0297 76.4882 58.543 77.114 58.543H104.65C105.276 58.543 105.762 59.0297 105.762 59.6554C105.693 60.2812 105.206 60.7678 104.65 60.7678Z"
                fill="#E9EAF0"
            />
            <path
                d="M104.65 65.7737H77.114C76.4882 65.7737 76.0015 65.287 76.0015 64.6613C76.0015 64.0355 76.4882 63.5488 77.114 63.5488H104.65C105.276 63.5488 105.762 64.0355 105.762 64.6613C105.693 65.287 105.206 65.7737 104.65 65.7737Z"
                fill="#E9EAF0"
            />
            <path
                d="M63.8328 90.803C68.1724 90.803 71.6903 87.2855 71.6903 82.9464C71.6903 78.6074 68.1724 75.0898 63.8328 75.0898C59.4932 75.0898 55.9753 78.6074 55.9753 82.9464C55.9753 87.2855 59.4932 90.803 63.8328 90.803Z"
                fill="#ACADBA"
            />
            <path
                d="M88.935 79.678H77.114C76.4882 79.678 76.0015 79.1913 76.0015 78.5656C76.0015 77.9398 76.4882 77.4531 77.114 77.4531H88.935C89.5608 77.4531 90.0475 77.9398 90.0475 78.5656C89.978 79.1913 89.4912 79.678 88.935 79.678Z"
                fill="#C9C9CE"
            />
            <path
                d="M104.65 84.7542H77.114C76.4882 84.7542 76.0015 84.2675 76.0015 83.6417C76.0015 83.016 76.4882 82.5293 77.114 82.5293H104.65C105.276 82.5293 105.762 83.016 105.762 83.6417C105.762 84.2675 105.206 84.7542 104.65 84.7542Z"
                fill="#E9EAF0"
            />
            <path
                d="M104.65 89.76H77.114C76.4882 89.76 76.0015 89.2733 76.0015 88.6476C76.0015 88.0218 76.4882 87.5352 77.114 87.5352H104.65C105.276 87.5352 105.762 88.0218 105.762 88.6476C105.762 89.2733 105.206 89.76 104.65 89.76Z"
                fill="#E9EAF0"
            />
            <path
                d="M113.064 114.72C120.245 114.72 126.067 108.899 126.067 101.718C126.067 94.5378 120.245 88.7168 113.064 88.7168C105.882 88.7168 100.061 94.5378 100.061 101.718C100.061 108.899 105.882 114.72 113.064 114.72Z"
                fill="#7C66BF"
            />
            <path
                d="M113.064 115.415C105.554 115.415 99.3652 109.297 99.3652 101.718C99.3652 94.1399 105.484 88.0215 113.064 88.0215C120.573 88.0215 126.762 94.1399 126.762 101.718C126.762 109.297 120.573 115.415 113.064 115.415ZM113.064 89.412C106.249 89.412 100.756 94.9047 100.756 101.718C100.756 108.532 106.249 114.025 113.064 114.025C119.878 114.025 125.371 108.532 125.371 101.718C125.371 94.9742 119.878 89.412 113.064 89.412Z"
                fill="white"
            />
            <path
                d="M116.819 100.885H113.968V98.0336C113.968 97.5469 113.62 97.1992 113.133 97.1992C112.647 97.1992 112.299 97.5469 112.299 98.0336V100.885H109.448C108.961 100.885 108.614 101.232 108.614 101.719C108.614 102.206 108.961 102.553 109.448 102.553H112.299V105.404C112.299 105.891 112.647 106.239 113.133 106.239C113.62 106.239 113.968 105.891 113.968 105.404V102.553H116.819C117.305 102.553 117.653 102.206 117.653 101.719C117.653 101.302 117.305 100.885 116.819 100.885Z"
                fill="white"
            />
            <path
                d="M63.4853 38.9353C63.2767 38.9353 63.0681 38.8658 62.8595 38.6572L59.939 35.7367C59.5913 35.389 59.5913 34.8327 59.939 34.4851C60.2867 34.1374 60.8429 34.1374 61.1906 34.4851L63.4853 36.7797L67.2402 32.8858C67.5878 32.5381 68.1441 32.5381 68.4918 32.8858C68.8395 33.2334 68.8395 33.7897 68.4918 34.1374L64.1111 38.6572C63.9025 38.8658 63.6939 38.9353 63.4853 38.9353Z"
                fill="white"
            />
            <path
                d="M63.4853 62.574C63.2767 62.574 63.0681 62.5044 62.8595 62.2958L59.939 59.3754C59.5913 59.0277 59.5913 58.4714 59.939 58.1237C60.2867 57.7761 60.8429 57.7761 61.1906 58.1237L63.4853 60.4184L67.2402 56.5244C67.5878 56.1768 68.1441 56.1768 68.4918 56.5244C68.8395 56.8721 68.8395 57.4284 68.4918 57.7761L64.1111 62.2958C63.9025 62.5044 63.6939 62.574 63.4853 62.574Z"
                fill="white"
            />
            <path
                d="M63.4853 86.2146C63.2767 86.2146 63.0681 86.1451 62.8595 85.9365L59.939 83.016C59.5913 82.6683 59.5913 82.112 59.939 81.7644C60.2867 81.4167 60.8429 81.4167 61.1906 81.7644L63.4853 84.059L67.2402 80.1651C67.5878 79.8174 68.1441 79.8174 68.4918 80.1651C68.8395 80.5127 68.8395 81.069 68.4918 81.4167L64.1111 85.9365C63.9025 86.1451 63.6939 86.2146 63.4853 86.2146Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(EmptyGroupAccessSVG);
