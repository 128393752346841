import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Avatar } from '@material-ui/core';
import CountryFlag from 'components/svg-icon/CountryFlag';
import SvgIcon from 'components/svg-icon/Base';
import * as columnTypes from 'const/columnTypes';
// import hexToRgba from 'hex-to-rgba';
import Option from 'components/option/Base';
import { isFileAudio, isFileVideo, isFileImage, getFilePreview, isFilePdf } from 'utils/images';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import Checkbox from 'components/checkbox/Base';
import { getFormatDateTime } from 'utils/datetime';
import { SWITCHING_COLUMN_MAPPING } from 'gridUI/table/grid/columns/switchColumnMapping';
import TemplateSVG from 'assets/images/svg/TemplateSVG';
import BranchSVG from 'assets/images/svg/BranchSVG';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';

const imageSize = 24;
const useStyles = makeStyles(theme => ({
    root: {
        // width: '100%'
    },
    columnIcon: {
        position: 'relative'
    },
    notTranslation: {
        borderRadius: 2
    },
    columnName: {
        padding: `3px 5px`,
        background: theme.colors.paleGrey,
        color: theme.colors.steel
    },
    ellipsis: {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    fullWidth: {
        width: '100%'
    },
    icon: {
        '& svg': {
            maxWidth: 20
        }
    },
    mr3: {
        marginRight: theme.spacing(3)
    },
    contentText: {
        maxWidth: `calc(100% - ${theme.spacing(2)}px)`
    },
    gridName: {
        maxWidth: 160,
        height: 22,
        padding: '3px 5px',
        background: '#F4F5F7',
        borderRadius: 2,
        color: theme.colors.steel,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        marginBottom: 5,
        '& svg': {
            marginRight: 4
        }
    },
    gridNameContent: {
        flex: 1,
        ...theme.ellipsis(1)
    },
    gridDivider: {
        margin: '0 6px'
    }
}));

function Reference({ data, referencedColumnType, dbId }) {
    switch (referencedColumnType) {
        case columnTypes.DATETIME:
            return (
                <Grid container direction="row" spacing={1}>
                    {data?.map(item => {
                        const datetime = getFormatDateTime(item);
                        if (datetime === 'Invalid date') return null;
                        return (
                            <Grid item key={datetime}>
                                <Option size={'small'} label={datetime} />
                            </Grid>
                        );
                    })}
                </Grid>
            );
        case columnTypes.BOOLEAN:
            return (
                <Grid container direction="row" spacing={1}>
                    {data?.map((item, index) => {
                        const checked = item ? true : false;
                        return (
                            <Grid item key={item}>
                                <Checkbox
                                    key={index}
                                    checked={checked}
                                    style={{
                                        maxWidth: 24,
                                        maxHeight: 24
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            );
        case columnTypes.MULTIPLE_SELECTIONS:
            return (
                <Grid container direction="row" spacing={2}>
                    {data?.map((items, index) => {
                        return (
                            <Grid item key={index}>
                                <Grid wrap="nowrap" container direction="row" spacing={1}>
                                    {items?.map(label => {
                                        return (
                                            <Grid item key={label}>
                                                <Option size={'small'} label={label} />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            );

        case columnTypes.FILES:
            return (
                <Grid container direction="row" spacing={2}>
                    {data?.map((items, index) => {
                        return (
                            <Grid item key={index}>
                                <Grid wrap="nowrap" container direction="row" spacing={1}>
                                    {items?.map(fileMeta => {
                                        let isImage = isFileImage(fileMeta);
                                        let isAudio = isFileAudio(fileMeta);
                                        let isVideo = isFileVideo(fileMeta);
                                        let isPdf = isFilePdf(fileMeta);
                                        let url = getFilePreview({ fileId: fileMeta?.thumbnailId, dbId });

                                        const videoBackground = isVideo ? '#FDF0ED' : '#F3F2F9';
                                        const otherFileBackground = isAudio ? '#EEF5FB' : videoBackground;
                                        const background = !isImage ? otherFileBackground : '';

                                        const videoRender = isVideo ? (
                                            <VideoThumbnailSVG style={{ width: imageSize, height: imageSize }} />
                                        ) : (
                                            <OtherFileTypeThumbnailSVG
                                                style={{ width: imageSize, height: imageSize }}
                                            />
                                        );
                                        const audioRender = isAudio ? (
                                            <AudioThumbnailSVG style={{ width: imageSize, heigt: imageSize }} />
                                        ) : isPdf ? (
                                            <PDFFILESVG style={{ width: imageSize, height: imageSize }} />
                                        ) : (
                                            videoRender
                                        );

                                        return (
                                            <Grid item key={fileMeta?.id}>
                                                <Avatar
                                                    style={{
                                                        height: imageSize,
                                                        width: imageSize,
                                                        borderRadius: 4,
                                                        background
                                                    }}
                                                    src={url}
                                                    alt={fileMeta?.originalName}
                                                >
                                                    {!isImage && audioRender}
                                                </Avatar>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            );
        default:
            return (
                <Grid container direction="row" spacing={1}>
                    {data?.map(label => {
                        return (
                            <Grid item key={label}>
                                <Option size={'small'} label={label} />
                            </Grid>
                        );
                    })}
                </Grid>
            );
    }
}

// const getColumnColor = ({ columnType, theme }) => {
//     switch (columnType) {
//         case columnTypes.RECORD_ID:
//         case columnTypes.PATH_TAG:
//             return theme.colors.burntSlena;

//         case columnTypes.MULTIPLE_LINES:
//             return theme.colors.atlantis;
//         case columnTypes.SINGLE_LINE:
//         case columnTypes.ALTERED_BY:
//             return theme.colors.sun;
//         case columnTypes.MARKDOWN:
//             return theme.colors.dodgerBlue;
//         case columnTypes.RICH_TEXT:
//             return theme.colors.funBlue;
//         case columnTypes.NUMBER:
//             return theme.colors.lightCyan;
//         case columnTypes.DATETIME:
//         case columnTypes.ALTERED_TIME:
//             return theme.colors.shipCove;
//         case columnTypes.FILES:
//             return theme.colors.shamRock;
//         case columnTypes.JSON_LD:
//             return theme.colors.froly;
//         case columnTypes.HTML:
//             return theme.colors.darkLavender;
//         case columnTypes.YAML:
//             return theme.colors.fuchsiaBlue;
//         case columnTypes.REFERENCE:
//             return theme.colors.brightRed;
//         default:
//             return theme.colors.crusta;
//     }
// };

function ColumnTypeTitle({ columnType, data, dbId, referencedColumnType }) {
    const classes = useStyles();

    const getData = () => {
        switch (columnType) {
            case columnTypes.SINGLE_SELECTION:
                return <Option size={'small'} label={data} />;
            case columnTypes.MULTIPLE_SELECTIONS:
                return (
                    <Grid container direction="row" spacing={1}>
                        {Array.isArray(data) &&
                            data?.map(label => {
                                return (
                                    <Grid item key={label}>
                                        <Option size={'small'} label={data} />
                                    </Grid>
                                );
                            })}
                    </Grid>
                );
            case columnTypes.FILES: {
                return (
                    <Grid container direction="row" spacing={1}>
                        {Array.isArray(data) &&
                            data?.map(fileMeta => {
                                let isImage = isFileImage(fileMeta);
                                let isAudio = isFileAudio(fileMeta);
                                let isVideo = isFileVideo(fileMeta);
                                let isPdf = isFilePdf(fileMeta);
                                let url = getFilePreview({ fileId: fileMeta?.thumbnailId, dbId });

                                const videoBackground = isVideo ? '#FDF0ED' : '#F3F2F9';
                                const otherFileBackground = isAudio ? '#EEF5FB' : videoBackground;
                                const background = !isImage ? otherFileBackground : '';

                                const videoRender = isVideo ? (
                                    <VideoThumbnailSVG style={{ width: imageSize, height: imageSize }} />
                                ) : (
                                    <OtherFileTypeThumbnailSVG style={{ width: imageSize, height: imageSize }} />
                                );
                                const audioRender = isAudio ? (
                                    <AudioThumbnailSVG style={{ width: imageSize, heigt: imageSize }} />
                                ) : isPdf ? (
                                    <PDFFILESVG style={{ width: imageSize, height: imageSize }} />
                                ) : (
                                    videoRender
                                );

                                return (
                                    <Grid item key={fileMeta?.id}>
                                        <Avatar
                                            style={{
                                                height: imageSize,
                                                width: imageSize,
                                                borderRadius: 4,
                                                background
                                            }}
                                            src={url}
                                            alt={fileMeta?.originalName}
                                        >
                                            {!isImage && audioRender}
                                        </Avatar>
                                    </Grid>
                                );
                            })}
                    </Grid>
                );
            }

            case columnTypes.REFERENCE:
                return <Reference data={data} referencedColumnType={referencedColumnType} />;

            case columnTypes.DATETIME: {
                const [date, time] = formatDateTimeLocal(data);
                return (
                    <Grid container alignItems="flex-start" spacing={1}>
                        <Grid item>
                            <Typography variant="body1">{date}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{time}</Typography>
                        </Grid>
                    </Grid>
                );
            }

            case columnTypes.BOOLEAN: {
                let checked = false;
                const OPTIMISTIC_VALUES = SWITCHING_COLUMN_MAPPING?.[columnTypes.BOOLEAN]?.OPTIMISTIC_VALUES;

                if (typeof data === 'number' || typeof data === 'string' || typeof data === 'boolean') {
                    checked = OPTIMISTIC_VALUES?.includes(data?.toString().toLowerCase());
                }

                return (
                    <Checkbox
                        checked={checked}
                        style={{
                            maxWidth: 24,
                            maxHeight: 24
                        }}
                    />
                );
            }

            default:
                return (
                    <Typography variant="body1" className={classes.ellipsis}>
                        {data}
                    </Typography>
                );
        }
    };

    return getData();
}

function RecordNotification({
    updatedAt,
    data,
    columnType,
    columnName,
    _tm,
    _dependency,
    updatedBy,
    group,
    customProperties,
    referencedColumnType,
    dbId,
    containerProps = {},
    showGridContent = false,
    gridName,
    branchName,
    recordName,
    ...rest
}) {
    const classes = useStyles();
    const theme = useTheme();

    const isTranslation = React.useMemo(() => {
        return columnTypes.TRANSLATION === columnType;
    }, [columnType]);

    // const color = getColumnColor({ columnType, theme });

    return (
        <Grid className={classes.root} container wrap="nowrap" direction="row" alignItems="center" {...rest}>
            {isTranslation ? (
                <Grid item className={`${classes.columnIcon} ${classes.mr3}`}>
                    <CountryFlag languageCountryCode={group} />
                </Grid>
            ) : (
                <Grid item className={classes.mr3}>
                    <Grid
                        // style={{ background: hexToRgba(color, 0.1) }}
                        className={`${classes.notTranslation} `}
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                    >
                        <SvgIcon
                            type={columnType}
                            // color={color}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid item className={classes.contentText}>
                <Grid container direction="column" className={classes.fullWidth}>
                    <Grid item className={classes.fullWidth} style={{ paddingBottom: theme.spacing(1) }}>
                        <ColumnTypeTitle
                            columnType={columnType}
                            data={data}
                            dbId={dbId}
                            referencedColumnType={referencedColumnType}
                        />
                    </Grid>
                    {!showGridContent && (
                        <Grid item>
                            <Typography className={classes.columnName} variant="caption" display="inline">
                                {columnName}
                            </Typography>{' '}
                            {updatedAt && (
                                <>
                                    <Typography
                                        style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}
                                        variant="caption"
                                        display="inline"
                                    >
                                        &#x02022;
                                    </Typography>{' '}
                                    <Typography variant="caption" display="inline">
                                        {updatedAt || 'Updated 26 May 2021'}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    )}
                    {showGridContent && (
                        <Grid container alignItems="center">
                            {gridName && (
                                <>
                                    <Grid item className={classes.gridName}>
                                        <TemplateSVG color="#79778B" width="14" height="14" />
                                        <div className={classes.gridNameContent}>{gridName}</div>
                                    </Grid>
                                    <Grid item className={classes.gridDivider}>
                                        /
                                    </Grid>
                                </>
                            )}
                            {branchName && (
                                <>
                                    <Grid item className={classes.gridName}>
                                        <BranchSVG color="#79778B" width="14" height="14" />
                                        <div className={classes.gridNameContent}>{branchName}</div>
                                    </Grid>
                                    <Grid item className={classes.gridDivider}>
                                        /
                                    </Grid>
                                </>
                            )}
                            <Grid item className={classes.gridName}>
                                <div className={classes.gridNameContent}>Record {recordName}</div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RecordNotification;
