import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PopperMenu from 'components/menus/Popper';
import { INPUT_PADDING_LEFT, INPUT_RADIUS, INPUT_HEIGHT } from 'const/style';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import DateTimeSVG from 'components/svg-icon/DateTimeSVG';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width || 160,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: INPUT_RADIUS,
        border: props =>
            props.isOpen
                ? props.isHighlight
                    ? `1px solid ${theme.palette.primary.main}`
                    : `1px solid ${theme.colors.border}`
                : `1px solid ${theme.colors.border}`,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText
    },
    timeDropdown: {
        width: props => props.width || 160
    }
}));

function TimeSelect({ options, selected, onChange, width, popperProps = {} }) {
    const classes = useStyles({ width });
    const theme = useTheme();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container className={classes.root} onClick={handleClick} justify="space-between">
            <Grid item>
                <Grid container spacing={2} alignItems="center">
                    <Grid item style={{ display: 'flex' }}>
                        <DateTimeSVG color={theme.palette.primary.main} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{selected.label || t('global_all')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ display: 'flex' }}>
                <ArrowDownSVG />
            </Grid>
            {anchorEl && (
                <PopperMenu
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    handleClickAway={handleClickAway}
                    {...popperProps}
                >
                    <Grid container direction="column" className={classes.timeDropdown}>
                        {options.map(option => {
                            const isSelected = (selected && selected.value) === option.value;
                            return (
                                <Grid item key={option.value}>
                                    <ListItem
                                        onClick={e => onChange(e, option)}
                                        name={option.label}
                                        isSelected={isSelected}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </PopperMenu>
            )}
        </Grid>
    );
}

export default React.memo(TimeSelect);
