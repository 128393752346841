/**
 * WORKSPACE ROLES
 */

export const CREATOR = 'Creator';
export const EDITOR = 'Editor';
export const TRANSLATOR = 'Translator';
export const VIEWER = 'Viewer';
export const LOCALIZATION_MANAGER = 'LocalizationManager';
export const COMMENTER = 'Commenter';
export const PAYMENT_LOCKED_USER = 'PAYMENT_LOCKED_USER';

/**
 * ROLE STATUS
 */

export const READ_ONLY = 'READ_ONLY';
export const FULL = 'FULL';
export const HIDDEN = 'HIDDEN';

/**
 * COMPANY ROLES
 */

export const COMPANY_AUTHORITIES = {
    MANAGE_WORKSPACE: 'MANAGE_WORKSPACE',
    MANAGE_API_KEY: 'MANAGE_API_KEY',
    MANAGE_GROUP: 'MANAGE_GROUP',
    MANAGE_LOCALE: 'MANAGE_LOCALE',
    MANAGE_ADD_ON: 'MANAGE_ADD_ON',
    MANAGE_BILLING: 'MANAGE_BILLING',
    MANAGE_MEMBER: 'MANAGE_MEMBER',
    MANAGE_COMPANY: 'MANAGE_COMPANY',
    MANAGE_TM: 'MANAGE_TM',
    MANAGE_ROLE: 'MANAGE_ROLE',
    MANAGE_LQA: `MANAGE_LQA`,
    MANAGE_GLOSSARY: 'MANAGE_GLOSSARY',
    MANAGE_CUSTOM_TAGS: 'MANAGE_CUSTOM_TAGS',
    MANAGE_STATISTICS: 'MANAGE_STATISTICS',
    INVITE_MEMBER: 'INVITE_MEMBER',
    MANAGE_ADVANCED_SEARCH: 'MANAGE_ADVANCED_SEARCH'
};
/**
 * PERMISSION FOR LAYOUT UI WITH DIFFERENT ENVIROMENT
 */

export const SUPPORT_GLOBAL_SEARCH = 'SUPPORT_GLOBAL_SEARCH';
export const SUPPORT_NOTIFICATION = 'SUPPORT_NOTIFICATION';
export const SUPPORT_GLOBAL_CHAT = 'SUPPORT_GLOBAL_CHAT';
export const SUPPORT_SELECT_ALL_CHECKBOX = 'SUPPORT_SELECT_ALL_CHECKBOX';
export const SUPPORT_RESTORE_GRID = 'SUPPORT_RESTORE_GRID';
export const SUPPORT_JIRA_FEEDBACK = 'SUPPORT_JIRA_FEEDBACK';
export const SUPPORT_PATHTAG_UNIQUE_RECORDID = 'SUPPORT_PATHTAG_UNIQUE_RECORDID';

/**
 * LOCALIZATION
 */

export const WORKSPACE_ROLE = 'WORKSPACE_ROLE';

export const EXTRA_AUTHORITIES = {
    MANAGE_COLUMN_PERMISSION: 'MANAGE_COLUMN_PERMISSION',
    SHOW_COLUMN_PERMISSION: 'SHOW_COLUMN_PERMISSION',

    SHOW_PATH_TAG: 'SHOW_PATH_TAG',

    MANAGE_TRIGGER: 'MANAGE_TRIGGER',
    SHOW_TRIGGER: 'SHOW_TRIGGER',

    MANAGE_FILTER: 'MANAGE_FILTER',
    SHOW_FILTER: 'SHOW_FILTER',

    MANAGE_DEPENDENCY: 'MANAGE_DEPENDENCY',
    SHOW_DEPENDENCY: 'SHOW_DEPENDENCY',

    MANAGE_SORT: 'MANAGE_SORT',
    SHOW_SORT: 'SHOW_SORT',

    SHOW_RECORD_HISTORY: 'SHOW_RECORD_HISTORY',

    SHOW_MERGING: 'SHOW_MERGING',
    MANAGE_MERGING: 'MANAGE_MERGING'
};

export const WORKSPACE_AUTHORITIES = {
    MANAGE_DATABASE: 'MANAGE_DATABASE', //done
    MANAGE_GRID: 'MANAGE_GRID', //done
    MANAGE_VIEW: 'MANAGE_VIEW', //done
    MANAGE_GRID_COLUMN: 'MANAGE_GRID_COLUMN', //done
    MANAGE_GRID_RECORD: 'MANAGE_GRID_RECORD', //only create, delete records, reorder records, format records.
    EDIT_RECORDS: 'EDIT_RECORDS', //done
    ACCESS_GRID_HISTORY: 'ACCESS_GRID_HISTORY',
    ACCESS_RECORD_HISTORY: 'ACCESS_RECORD_HISTORY',
    EDIT_TRANSLATION: 'EDIT_TRANSLATION',
    MANAGE_BRANCH: 'MANAGE_BRANCH',
    MANAGE_GRID_DEPENDENCY: 'MANAGE_GRID_DEPENDENCY',
    IMPORT_EXPORT: 'IMPORT_EXPORT',
    MANAGE_TICKET: `MANAGE_TICKET`,
    MANAGE_SOURCE_STATUS: `MANAGE_SOURCE_STATUS`
};

export const CREATOR_AUTHORITIES = Object.keys(WORKSPACE_AUTHORITIES);
