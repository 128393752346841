import React from 'react';

function HighSVG({ color = '#49D0AA', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect
                x="2.06066"
                y="8"
                width="8.29447"
                height="8.29447"
                transform="rotate(-45 2.06066 8)"
                stroke="#F9772A"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default React.memo(HighSVG);
