import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import Table from 'components/tables/Table';
import { AutoSizer } from 'react-virtualized-dn';
import { formatDateFromNow } from 'utils/datetime';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import SyncSVG from 'assets/images/svg/SyncSVG';
import GridIcon from 'grids/GridIcon';
import { useHistory } from 'react-router-dom';
import { useConnections, useConnectionsLoading } from 'hooks/integration';
import { useDispatch } from 'react-redux';
import * as integrationActions from 'integrations/actions';
import PopperMenu from 'components/menus/Popper';
import Popup from './Popup';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import * as workspaceActions from 'workspaces/actions';
import { useWorkspaceMapping } from 'hooks/workspace';
import { getConnectorIcon } from 'utils/connector';
import * as gridActions from 'grids/actions';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    content: {
        flex: 1,
        minHeight: 400
    },
    notFound: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mt5: { marginTop: theme.spacing(4) },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    },
    syncSpin: {
        animation: `$spin 500ms infinite linear`
    },
    hoverLink: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

function Destination({ rowData }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const gridName = rowData?.destinationGridName;
    const destinationProjectName = rowData?.destinationProjectName;
    const destinationDatabaseName = rowData?.destinationDatabaseName;

    const handleOpenGrid = React.useCallback(
        async e => {
            e.stopPropagation();

            dispatch(
                gridActions.getGridLastView({
                    dbId: rowData?.destinationDatabaseId,
                    gridId: rowData?.destinationGridId,
                    errorCallback: () => {},
                    successCallback: responseData => {
                        const { dbId, gridId, viewId, branchId } = responseData;
                        if (!dbId || !gridId || !viewId || !branchId) {
                            return;
                        }
                        history.push(
                            `/projects/${rowData?.destinationProjectId}/databases/${dbId}/grids/${gridId}/branches/${branchId}/views/${viewId}`
                        );
                        dispatch(gridActions.setSelectedGrid(gridId));
                    }
                })
            );
        },
        [dispatch, rowData, history]
    );

    return (
        <Grid container onClick={handleOpenGrid} direction="column">
            <Grid item>
                <Grid wrap="nowrap" container direction="row" spacing={1}>
                    <Grid item>
                        <GridIcon size="small" />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.hoverLink} variant="body2">
                            {gridName}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    {[destinationProjectName, destinationDatabaseName]?.join(' / ')}
                </Typography>
            </Grid>
        </Grid>
    );
}

function ActionsRenderer({ rowData }) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleClick = React.useCallback(
        event => {
            stopPropagation(event);
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl, stopPropagation]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleEdit = React.useCallback(() => {
        history.push(`/integration-settings/connectors/connection/${rowData?.id}?tab=setting`);
    }, [rowData, history]);

    const handleDelete = React.useCallback(() => {
        setOpenConfirmDelete(true);
        handleClickAway();
    }, [handleClickAway]);

    const handleCloseDeleteConfirm = React.useCallback(
        e => {
            stopPropagation(e);
            setOpenConfirmDelete(false);
        },
        [stopPropagation]
    );

    const handleAgreeDelete = React.useCallback(
        e => {
            stopPropagation(e);
            setIsDeleting(true);

            dispatch(
                integrationActions.deleteConnection({
                    cId: rowData?.id,
                    success: () => {
                        setIsDeleting(false);
                        handleCloseDeleteConfirm(e);
                    },
                    error: () => {
                        setIsDeleting(false);
                        console.log('err');
                    }
                })
            );
        },
        [stopPropagation, dispatch, rowData, handleCloseDeleteConfirm]
    );

    const handleSyncConnection = React.useCallback(
        e => {
            stopPropagation(e);
            dispatch(
                integrationActions.syncConnection({
                    cId: rowData?.id,
                    success: () => {
                        console.log('sync okay');
                    },
                    error: () => {
                        console.log('sync failed');
                    }
                })
            );
        },
        [dispatch, rowData, stopPropagation]
    );

    return (
        <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <Grid item>
                <IconButton
                    disabled={rowData?.task}
                    className={rowData?.task && classes.syncSpin}
                    onClick={handleSyncConnection}
                >
                    <SyncSVG />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={handleClick}>
                    <IconMoreActionsSVG />
                </IconButton>
            </Grid>

            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-end'}>
                    <Popup onEdit={handleEdit} onDelete={handleDelete} source={rowData} onClickAway={handleClickAway} />
                </PopperMenu>
            )}
            <Dialog open={openConfirmDelete} onClose={handleCloseDeleteConfirm}>
                <ConfirmBox
                    title={t('delete_connection')}
                    body={
                        <Typography component="div" variant="body2">
                            Delete source <strong>{rowData?.name}</strong> ? All related connection with this source
                            will be halted.
                        </Typography>
                    }
                    handleCancel={handleCloseDeleteConfirm}
                    onClose={handleCloseDeleteConfirm}
                    handleAgreed={handleAgreeDelete}
                    agreeLabel="Delete"
                    isLoading={isDeleting}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </Grid>
    );
}

function LastSync({ rowData }) {
    return rowData?.lastSync ? formatDateFromNow(rowData?.lastSync) : null;
}

function Connector({ rowData }) {
    return (
        <Grid container wrap="nowrap" direction="row" spacing={2}>
            <Grid item>{getConnectorIcon(rowData?.sourceResponse?.connector)}</Grid>

            <Grid item>
                <Typography variant="body2">{rowData?.sourceResponse?.name}</Typography>
            </Grid>
        </Grid>
    );
}

function LoadingRow({ maxWidth }) {
    return (
        <Grid
            item
            style={{
                width: `100%`,
                maxWidth,
                height: 7,
                background: `#EEF0F5`,
                borderRadius: 20
            }}
        />
    );
}

function Connections() {
    const classes = useStyles();
    const history = useHistory();
    const connections = useConnections();
    const connectionsLoading = useConnectionsLoading();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [sorting, setSorting] = React.useState({});

    const workspaceMapping = useWorkspaceMapping();

    const connectionsFormatted = React.useMemo(() => {
        return connections?.map(connection => ({
            ...connection,
            destinationProjectName: workspaceMapping?.[connection?.destinationProjectId]?.name
        }));
    }, [connections, workspaceMapping]);

    const COLUMNS = React.useMemo(
        () => [
            {
                label: t('connection_name'),
                dataKey: 'name',
                flexGrow: 1,
                sort: true,
                cellRenderer: connectionsLoading ? () => <LoadingRow maxWidth={250} /> : props => props?.rowData?.name
            },
            {
                label: t('source_name'),
                dataKey: 'sourceResponse',
                flexGrow: 1,
                sort: true,
                cellRenderer: connectionsLoading ? () => <LoadingRow maxWidth={250} /> : Connector
            },
            {
                label: t('destination'),
                dataKey: 'destination',
                width: 300,
                cellRenderer: props =>
                    connectionsLoading ? () => <LoadingRow maxWidth={250} /> : <Destination {...props} />
            },
            {
                label: t('last_sync'),
                dataKey: 'lastSync',
                flexGrow: 1,
                sort: true,
                cellRenderer: connectionsLoading ? () => <LoadingRow maxWidth={93} /> : LastSync
            },
            {
                label: '',
                dataKey: 'actions',
                width: 150,
                hidden: connectionsLoading,
                cellRenderer: props => <ActionsRenderer {...props} />
            }
        ],
        [connectionsLoading, t]
    );

    const data = React.useMemo(() => {
        const cloneData = [...connectionsFormatted];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                if (sortKey === 'lastSync') {
                    return sortType === 'desc'
                        ? new Date(valueA) - new Date(valueB)
                        : new Date(valueB) - new Date(valueA);
                }

                if (sortKey === 'sourceResponse') {
                    valueA = dataA?.[sortKey]?.name;
                    valueB = dataB?.[sortKey]?.name;
                }

                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, connectionsFormatted]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspacesByGraphql({
                successCallback: () => {
                    console.log('fetch workspaces success');
                },
                errorCallback: () => {
                    console.log('fetch workspaces failed');
                }
            })
        );
    }, [dispatch]);

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const CreateNewConnection = React.useCallback(() => {
        sendManualTrack({ type: 'Open Add New Connection' });
        history.push('/integration-settings/connectors/connection');
    }, [history]);

    React.useEffect(() => {
        dispatch(
            integrationActions.getConnections({
                success: () => {},
                error: error => {
                    console.log('error', error);
                }
            })
        );
    }, [dispatch]);

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
                spacing={1}
                wrap="nowrap"
            >
                <Grid item>
                    <NotFoundBoxSVG />
                </Grid>
                <Grid item>
                    <Typography variant="h3">{t(`no_connection_found`)}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t(`create_new_connection_note`)}</Typography>
                </Grid>
            </Grid>
        );
    }, [t]);

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap">
            <Grid item className={classes.mb3}>
                <Grid container direction="row" justifyContent="flex-end">
                    <ButtonBase onClick={CreateNewConnection} width={165} variant="contained">
                        + {t('new_connection')}
                    </ButtonBase>
                </Grid>
            </Grid>
            <Grid item className={`${classes.content} ${classes.mt5}`}>
                <AutoSizer>
                    {({ width, height }) => (
                        <Table
                            data={
                                connectionsLoading
                                    ? Array(10)
                                          .fill()
                                          .map(() => ({}))
                                    : data
                            }
                            columns={COLUMNS}
                            width={width}
                            height={height}
                            onRowClick={index => {
                                if (connectionsLoading) return;
                                const id = data?.[index]?.id;
                                history.push(`/integration-settings/connectors/connection/${id}`);
                            }}
                            onSort={handleOnSort}
                            noRowsRenderer={_noRowsRenderer}
                            rowClickable
                        />
                    )}
                </AutoSizer>
            </Grid>
        </Grid>
    );
}

export default Connections;
