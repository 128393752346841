import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';

import { resetPasswordInit } from './actions';
import InputText from 'components/inputs/InputText';
import ErrorMessage from './ErrorMessage';
import Button from 'components/button/Base';
import { Grid, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: 470
    },
    instruction: {
        color: '#251555',
        fontSize: 14,
        fontWeight: 'normal',
        marginBottom: 25
    },
    input: {
        minHeight: 68,
        marginBottom: 10
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    submitButton: {
        position: 'relative',
        marginBottom: 20
    },
    link: {
        '& a': {
            color: theme.colors.darkLavender,
            textDecoration: 'none'
        }
    }
}));

const LoginSchema = object({
    email: string()
        .email('Invalid Email')
        .required('This field is required')
});

function ForgotPasswordForm() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: LoginSchema,
        onSubmit: ({ email }, { setSubmitting, setStatus }) => {
            dispatch(
                resetPasswordInit({
                    email,
                    errorCallback: message => {
                        setSubmitting(false);
                        setStatus({ error: message });
                    },
                    successCallback: () => {
                        setSubmitting(false);
                    }
                })
            );
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, status } = formik;

    return (
        <>
            {status?.error && <ErrorMessage message={status.error} />}
            <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container direction="column" spacing={2}>
                    <Grid item className={classes.input}>
                        <InputText
                            height={40}
                            name="email"
                            value={values.email}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.email && touched.email}
                            errorText={errors.email}
                            placeholder={t(`global_your_email_address_placeholder`)}
                            autoFocus
                        />
                    </Grid>

                    <Grid item className={classes.submitButton}>
                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || !isValid}>
                            {t(`global_reset_link`)}
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default ForgotPasswordForm;
