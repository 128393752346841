import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import SidebarRightLayout from '../layout/rightSidebar';
import Spinner from 'components/spinner/Base';
import hexToRgba from 'hex-to-rgba';
import CommentNotFoundSVG from 'assets/images/svg/CommentNotFoundSVG';
import QuickFilterSVG from 'assets/images/svg/QuickFilterSVG';
import Search from 'components/search';
import PopperMenu from 'components/menus/Popper';
import FilterMenu from './FilterMenu';
import * as commentDashboardActions from 'gridUI/actions/commentDashboard';
import { useDispatch } from 'react-redux';
import {
    useIsFetchingGridCommentByCompositeViewId,
    useGridCommentByCompositeViewId,
    useCOMMENT_SIZE,
    useCOMMENT_CURRENT_PAGE,
    useTotalComments,
    useCompositeViewId
} from 'hooks/gridUI';
import CommentItem from './CommentItem';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import ModalBase from 'components/modal/Base';
import CellComment from './CellComment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        height: '100%'
    },
    fullWidthHeight: {
        width: '100%',
        height: '100%'
    },
    loadingWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: hexToRgba(theme.colors.white, 0.8)
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    expand: {
        flex: 1
    },
    w400: {
        fontWeight: 400
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    search: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    filterButton: {
        background: theme.colors.paleGrey,
        borderRadius: 4,
        padding: 10
    },

    commentItem: {
        paddingTop: 24,
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        '&:not(:last-child)': {
            paddingBottom: 24,
            borderBottom: `1px solid ${theme.colors.border}`
        },
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.ghostwhite
        }
    },
    commentList: {
        height: 'calc(100% - 68px)',
        overflowY: 'auto',
        width: '100%'
    }
}));

function Comments({ handleClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const compositeViewId = useCompositeViewId();
    const isLoading = useIsFetchingGridCommentByCompositeViewId(compositeViewId);
    const comments = useGridCommentByCompositeViewId(compositeViewId);
    const COMMENT_SIZE = useCOMMENT_SIZE();
    const currentPage = useCOMMENT_CURRENT_PAGE(compositeViewId);
    const totalComments = useTotalComments(compositeViewId);
    const OFFSET_FETCH = 1000;
    const timerSearch = React.useRef(0);

    const [filterValue, setFilterValue] = React.useState('');
    const [commentAuthor, setCommentAuthor] = React.useState('all');
    const [commentStatus, setCommentStatus] = React.useState('all');
    //cell comment detail
    const [isOpenModalCellCommentDetail, setIsOpenModalCellCommentDetail] = React.useState(false);
    const [selectedComment, setSelectedComment] = React.useState(null);

    const [isStopFetchMore, setIsStopFetchMore] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenModalCellCommentDetail = () => setIsOpenModalCellCommentDetail(true);
    const handleCloseModalCellCommentDetail = () => {
        setIsOpenModalCellCommentDetail(false);
        setSelectedComment(null);
    };

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleAuthorChange = value => {
        setCommentAuthor(value);
    };

    const handleStatusChange = value => {
        setCommentStatus(value);
        handleClickAway();
    };

    const openModalCellCommentDetail = comment => {
        setSelectedComment(comment);
        handleOpenModalCellCommentDetail();
    };

    const handleChangeThread = comment => setSelectedComment(comment);

    React.useEffect(() => {
        if (timerSearch.current) clearTimeout(timerSearch.current);
        timerSearch.current = setTimeout(function() {
            let query = {
                page: 0,
                size: COMMENT_SIZE,
                search: filterValue
            };
            if (commentStatus && commentStatus !== 'all') {
                query = {
                    ...query,
                    status: commentStatus?.toUpperCase()
                };
            }
            setIsFetching(true);
            dispatch(
                commentDashboardActions.fetchGridCommentList({
                    compositeViewId,
                    isFetchingMore: false,
                    query,
                    successCallback: () => {
                        setIsFetching(false);
                        setIsStopFetchMore(false);
                    },
                    errorCallback: () => {
                        setIsFetching(false);
                        setIsStopFetchMore(false);
                        console.log('failed to fetch comment list');
                    }
                })
            );
        }, DEBOUNCE_TIME_SEARCHING);
    }, [dispatch, compositeViewId, COMMENT_SIZE, filterValue, commentStatus]);

    const handleScroll = React.useCallback(() => {
        const target = document.getElementById('commentListDashboard');

        if (target.scrollTop === 0 || target.scrollTop < target.scrollHeight - OFFSET_FETCH || isFetching) return;
        setIsFetching(true);
    }, [isFetching, setIsFetching]);

    React.useEffect(() => {
        const target = document.getElementById('commentListDashboard');
        target.addEventListener('scroll', handleScroll);
        return () => target.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const fetchMoreListItems = React.useCallback(() => {
        if (comments?.length < totalComments) {
            let query = {
                page: currentPage + 1,
                size: COMMENT_SIZE,
                search: filterValue
            };

            if (commentStatus && commentStatus !== 'all') {
                query = {
                    ...query,
                    status: commentStatus?.toUpperCase()
                };
            }
            dispatch(
                commentDashboardActions.fetchGridCommentList({
                    compositeViewId,
                    isFetchingMore: true,
                    query,
                    successCallback: () => {
                        setIsFetching(false);
                        console.log('fetch comment list success');
                    },
                    errorCallback: () => {
                        setIsFetching(false);
                        console.log('failed to fetch comment list');
                    }
                })
            );
        } else {
            setIsFetching(false);
            setIsStopFetchMore(true);
            return;
        }
    }, [compositeViewId, dispatch, COMMENT_SIZE, currentPage, filterValue, comments, totalComments, commentStatus]);

    const timerRef = React.useRef(null);
    React.useEffect(() => {
        if (!isFetching || isStopFetchMore) {
            setIsFetching(false);
            return;
        }
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetchMoreListItems();
        }, 100);
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [isFetching, fetchMoreListItems, isStopFetchMore]);

    const isHighlightFilter = React.useMemo(() => {
        return commentStatus !== 'all';
    }, [commentStatus]);

    return (
        <SidebarRightLayout title={'Comments'} onClose={handleClose}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Grid item className={classes.search}>
                    <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item className={classes.expand}>
                            <Search
                                value={filterValue}
                                onChange={e => setFilterValue(e.target.value)}
                                onClear={() => setFilterValue('')}
                                background={theme.colors.paleGrey}
                                placeholder={t('comment_dashboard_search_placeholder')}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={handleClick}
                                className={classes.filterButton}
                                style={{
                                    background: isHighlightFilter ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                                    position: 'relative'
                                }}
                            >
                                <QuickFilterSVG
                                    color={isHighlightFilter ? theme.palette.primary.main : theme.colors.darkness}
                                />

                                {!!isHighlightFilter && (
                                    <div
                                        style={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            background: theme.colors.indicator,
                                            border: `1px solid ${theme.colors.solitude}`,
                                            position: 'absolute',
                                            right: 3,
                                            top: 3
                                        }}
                                    ></div>
                                )}
                            </IconButton>
                            {anchorEl && (
                                <PopperMenu
                                    className={classes.commentPopup}
                                    anchorEl={anchorEl}
                                    placement={'bottom-end'}
                                    id={`filter-comment-menu`}
                                >
                                    <FilterMenu
                                        commentAuthor={commentAuthor}
                                        commentStatus={commentStatus}
                                        handleClickAway={handleClickAway}
                                        onStatusChange={handleStatusChange}
                                        onAuthorChange={handleAuthorChange}
                                    />
                                </PopperMenu>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.commentList} id="commentListDashboard">
                    {isLoading && (
                        <Grid container justify="center" alignItems="center" className={classes.loadingWrapper}>
                            <Grid item>
                                <Spinner size={30} thick={4} />
                            </Grid>
                        </Grid>
                    )}
                    {comments?.length === 0 && !isLoading && (
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            direction="column"
                            spacing={2}
                            className={classes.fullWidthHeight}
                        >
                            <Grid item>
                                <CommentNotFoundSVG />
                            </Grid>
                            <Grid item>
                                <h3 className="prose prose-2xl font-normal">{t('comment_index_no_comments')}</h3>
                            </Grid>
                            <Grid item>
                                <p className="body2 font-normal text-text-secondary">
                                    {t('comment_index_could_not_find_comments')}
                                </p>
                            </Grid>
                        </Grid>
                    )}
                    {comments?.length > 0 && (
                        <Grid container direction="column" wrap="nowrap">
                            {comments?.map(comment => (
                                <Grid item key={comment?.thread?.id} className={classes.commentItem}>
                                    <CommentItem
                                        onClick={openModalCellCommentDetail}
                                        compositeViewId={compositeViewId}
                                        comment={comment}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
                <ModalBase open={isOpenModalCellCommentDetail} handleClose={handleCloseModalCellCommentDetail}>
                    <CellComment
                        defaultComment={selectedComment}
                        compositeViewId={compositeViewId}
                        commentList={comments}
                        onChangeThread={handleChangeThread}
                        onClose={handleCloseModalCellCommentDetail}
                    />
                </ModalBase>
            </Grid>
        </SidebarRightLayout>
    );
}

export default React.memo(Comments);
