import React from 'react';

const AnchorLeft = ({ color = '#78778B', ...rest }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M10 4L6 8L10 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default React.memo(AnchorLeft);
