import * as types from './types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    createAdvancedSearchApi,
    getAdvancedColumnsApi,
    getAdvancedSearchRecordsApi,
    getAdvancedSearchApi,
    getAdvancedSearchListApi,
    updateAdvancedSearchApi,
    createViewFilterApi,
    updateViewFilterApi,
    deleteViewFilterApi,
    createViewSortApi,
    updateViewSortApi,
    deleteViewSortApi,
    exportApi,
    replaceSearchApi,
    deleteAdvancedSearchApi,
    duplicateAdvancedSearchApi,
    reorderAdvancedSearchApi,
    resizeAdvancedSearchApi
} from 'services/advanced';
import { uploadFileForCellApi } from 'services/grid';
import uuidv1 from 'uuid/v1';
import { getCellData, getCellValueOnly, getServerValueOfSpecialType } from 'utils/gridUI/cell';
// import * as columnTypes from 'const/columnTypes';
import { ADVANCED_SEARCH_RECORD_LIMIT, GLOBAL_SEARCH_TYPES } from 'const/gridUI';
import {
    formatQuickFilters,
    generateDefaultConditionByColumnType,
    generateServerFilterValue
} from 'utils/gridUI/filter';
import { isEmpty } from 'lodash';
import { getResource, mapColumns, mapMetaData } from 'utils/gridUI/advanced';
import { setViewRecords } from 'services/view';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { getConditionsByType } from 'gridUI/conditions';
import { generateTempId } from 'utils/uuid';
import { isSelecting } from 'utils/gridUI/range';
import { isLDEmpty } from 'utils/object';

export function registerDoingAction({ actionId }) {
    return {
        type: types.REGISTER_DOING_ACTION,
        payload: {
            actionId
        }
    };
}

export function removeDoingAction({ actionId }) {
    return {
        type: types.REMOVE_DOING_ACTION,
        payload: {
            actionId
        }
    };
}

export function fetchColumns({ wsId, body, success, error }) {
    return async function(dispatch, getState) {
        try {
            const res = await getAdvancedColumnsApi({ wsId, body });
            dispatch(_fetchColumnsSuccess(res?.columns));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

function _fetchColumnsSuccess(columns) {
    return {
        type: types.FETCH_COLUMNS,
        payload: {
            columns
        }
    };
}

export function duplicateAdvancedSearch({ wsId, adId, name, store, success, error }) {
    return async function(dispatch, getState) {
        try {
            const res = await duplicateAdvancedSearchApi({ wsId, adId, name, store });
            dispatch(setAdvancedSearchId(res?.advancedSearchId));
            success && success(res?.advancedSearchId);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function createAdvancedSearch({ wsId, body, success, error }) {
    return async function(dispatch, getState) {
        try {
            const res = await createAdvancedSearchApi({ wsId, body });
            dispatch(setAdvancedSearchId(res?.advancedSearchId));
            success && success(res?.advancedSearchId);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function setAdvancedSearchId(advancedSearchId) {
    return {
        type: types.CREATE_ADVANCED_SEARCH,
        payload: {
            advancedSearchId
        }
    };
}

export function updateAdvancedSearch({ wsId, advancedSearchId, body, success, error, takeSnapshot }) {
    return async function(dispatch, getState) {
        try {
            const advancedSearch = await updateAdvancedSearchApi({ wsId, advancedSearchId, body });
            if (takeSnapshot) {
                dispatch(updateAdvancedSearchSnapshot(advancedSearch));
            }
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function updateAdvancedSearchSnapshot(advancedSearch) {
    return {
        type: types.SET_ADVANCED_SEARCH_SNAPSHOT,
        payload: {
            advancedSearch
        }
    };
}

export function updateNewName({ aId, name }) {
    return {
        type: types.UPDATE_ADVANCED_SEARCH,
        payload: {
            aId,
            name
        }
    };
}

export function getAdvancedSearchList({ wsId, shared, params, success, error }) {
    return async function(dispatch, getState) {
        try {
            dispatch(_getAdvancedSearchList());
            const list = await getAdvancedSearchListApi({ wsId, params, shared });
            dispatch(_getAdvancedSearchListSuccess({ list, shared }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
            dispatch(_getAdvancedSearchListFailed());
        }
    };
}

function _getAdvancedSearchListFailed() {
    return {
        type: types.FETCH_LIST_FAILED
    };
}

function _getAdvancedSearchList() {
    return {
        type: types.FETCH_LIST
    };
}

function _getAdvancedSearchListSuccess({ list, shared }) {
    return {
        type: types.FETCH_LIST_SUCCESS,
        payload: {
            list,
            shared
        }
    };
}

export function fetchMoreRecords({ wsId, success, error }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const {
            quickFilters,
            quickSorts,
            advancedSearch,
            routeInfo,
            lastId,
            searchValue,
            matches = [],
            selectedAdvancedSearchId
        } = advanced;
        const workspaceIdCombined = wsId || routeInfo?.workspaceId;
        const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;

        try {
            let body = {
                lastId,
                limit: ADVANCED_SEARCH_RECORD_LIMIT
            };
            if (!isEmpty(quickFilters)) {
                body = {
                    ...body,
                    filter: formatQuickFilters(quickFilters)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sorts: quickSorts
                };
            }

            if (!!searchValue) {
                body = {
                    ...body,
                    q: searchValue
                };
            }

            if (matches?.length) {
                body = {
                    ...body,
                    modes: matches
                };
            }

            dispatch(_fetchMoreRecordsStart());
            const res = await getAdvancedSearchRecordsApi({
                wsId: workspaceIdCombined,
                advancedSearchId: combinedAdvancedSearchId,
                body
            });
            dispatch(_fetchMoreRecordsSuccess(res));
            success && success();
        } catch ({ message }) {
            dispatch(_fetchMoreRecordsFailed());
            error && error();
        }
    };
}

function _fetchMoreRecordsStart() {
    return {
        type: types.FETCH_MORE_ROWS_START
    };
}

function _fetchMoreRecordsFailed() {
    return {
        type: types.FETCH_MORE_ROWS_FAILED
    };
}

function _fetchMoreRecordsSuccess(payload) {
    return {
        type: types.FETCH_MORE_ROWS_SUCCESS,
        payload
    };
}

export function fetchAdvancedSearchRecords({ wsId, advancedSearchId, body, success, error }) {
    return async function(dispatch, getState) {
        try {
            dispatch(_fetchAdvancedSearchRecordsStart());
            const res = await getAdvancedSearchRecordsApi({ wsId, advancedSearchId, body });
            dispatch(_fetchAdvancedSearchRecordsSuccess(res));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchAdvancedSearchRecordsFailed());
            error && error();
        }
    };
}

export function fetchGlobalSearchRecords({ wsId, body, success, error }) {
    return async function(dispatch, getState) {
        try {
            const { advanced } = getState();
            const {
                quickFilters,
                quickSorts,
                advancedSearch,
                searchValue,
                matches = [],
                routeInfo,
                selectedAdvancedSearchId
            } = advanced;

            const workspaceIdCombined = wsId || routeInfo?.workspaceId;
            const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;

            if (isSelecting({ ...advanced })) {
                dispatch(resetCellStatus());
            }

            if (!isEmpty(quickFilters)) {
                body = {
                    ...body,
                    filter: formatQuickFilters(quickFilters)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sorts: quickSorts
                };
            }

            if (!!searchValue) {
                body = {
                    ...body,
                    q: searchValue
                };
            }

            if (matches?.length) {
                body = {
                    ...body,
                    modes: matches
                };
            }

            dispatch(_fetchAdvancedSearchRecordsStart());
            const res = await getAdvancedSearchRecordsApi({
                wsId: workspaceIdCombined,
                advancedSearchId: combinedAdvancedSearchId,
                body
            });
            dispatch(_fetchAdvancedSearchRecordsSuccess(res));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchAdvancedSearchRecordsFailed());
            error && error();
        }
    };
}

export function fetchPreviewAdvancedSearchRecords({
    wsId,
    withQuickFilterAndSort,
    advancedSearchId,
    body,
    success,
    error
}) {
    return async function(dispatch, getState) {
        try {
            const { advanced } = getState();
            const { quickFilters, quickSorts, searchValue, matches = [] } = advanced;

            if (withQuickFilterAndSort) {
                if (!isEmpty(quickFilters)) {
                    body = {
                        ...body,
                        filter: formatQuickFilters(quickFilters)
                    };
                }

                if (!isEmpty(quickSorts)) {
                    body = {
                        ...body,
                        sorts: quickSorts
                    };
                }

                if (!!searchValue) {
                    body = {
                        ...body,
                        q: searchValue
                    };
                }

                if (matches?.length) {
                    body = {
                        ...body,
                        modes: matches
                    };
                }
            }

            const res = await getAdvancedSearchRecordsApi({ wsId, advancedSearchId, body });
            dispatch(
                _fetchPreviewAdvancedSearchRecords({
                    ...res,
                    advancedSearchId
                })
            );
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchAdvancedSearchRecordsFailed());
            error && error();
        }
    };
}

function _fetchPreviewAdvancedSearchRecords(payload) {
    return {
        type: types.FETCH_EXPORT_PREVIEW_DATA,
        payload
    };
}

function _fetchAdvancedSearchRecordsStart() {
    return {
        type: types.FETCH_ADVANCED_SEARCH_RECORDS_START
    };
}

function _fetchAdvancedSearchRecordsFailed() {
    return {
        type: types.FETCH_ADVANCED_SEARCH_RECORDS_FAILED
    };
}

export function _fetchAdvancedSearchRecordsSuccess(payload) {
    return {
        type: types.FETCH_ADVANCED_SEARCH_RECORDS_SUCCESS,
        payload
    };
}

export function fetchAdvancedSearch({ wsId, advancedSearchId, takeSnapshot, success, error }) {
    return async function(dispatch, getState) {
        try {
            const res = await getAdvancedSearchApi({ wsId, advancedSearchId });
            dispatch(_fetchAdvancedSearch(res, takeSnapshot));
            success && success(res);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

export function _fetchAdvancedSearch(advancedSearch, takeSnapshot) {
    return {
        type: types.FETCH_ADVANCED_SEARCH,
        payload: {
            advancedSearch,
            takeSnapshot
        }
    };
}

export function deleteAdvancedSearch({ wsId, aId, success, error }) {
    return async function(dispatch, getState) {
        try {
            const { advanced } = getState();
            const { selectedAdvancedSearchId, routeInfo } = advanced;

            const workspaceIdCombined = wsId || routeInfo?.workspaceId;
            await deleteAdvancedSearchApi({ wsId: workspaceIdCombined, aId });

            //clear advancedSearch list
            dispatch(_clearAdvancedSearch(aId));

            if (selectedAdvancedSearchId === aId) {
                dispatch(setClearGrid(true));
                dispatch(resetGrid(['list', `sharedList`, 'routeInfo', 'clearGrid']));
            }

            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

function _clearAdvancedSearch(advancedSearchId) {
    return {
        type: types.DELETE_ADVANCED_SEARCH,
        payload: {
            advancedSearchId
        }
    };
}

export function resizeColumn({ columnId, columnWidthChange, wsId }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, selectedAdvancedSearchId, routeInfo } = advanced;
        const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;
        const workspaceIdCombined = wsId || routeInfo?.workspaceId;

        const undoColumnWidth = advancedSearch?.columns?.find(column => column?.hashColumnId === columnId)?.width;

        if (undoColumnWidth === columnWidthChange) {
            console.log('SAME VALUE -> IGNORE');
            return false;
        }

        dispatch(_resizeColumnAction({ columnId, columnWidthChange }));
        dispatch(registerDoingAction({ actionId }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_RESIZE_COLUMN,
                body: {
                    oldColumnWidth: undoColumnWidth,
                    columnId
                }
            })
        );

        try {
            await resizeAdvancedSearchApi({
                wsId: workspaceIdCombined,
                adId: combinedAdvancedSearchId,
                body: {
                    width: columnWidthChange,
                    hashColumnId: columnId
                }
            });

            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _resizeColumnAction({ columnId, columnWidthChange }) {
    return {
        type: types.RESIZE_COLUMN,
        payload: {
            columnId,
            columnWidthChange
        }
    };
}

export function changeFixedColumnCount({ index, success, error }) {
    return async function(dispatch, getState) {
        try {
            dispatch(_changeFixedColumnCount(index));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
        }
    };
}

function _changeFixedColumnCount(index) {
    return {
        type: types.CHANGE_FIXED_COLUMN_COUNT,
        payload: {
            index
        }
    };
}

export function saveTableInfo({ tableInfo }) {
    return {
        type: types.SAVE_TABLE_INFO,
        payload: {
            tableInfo
        }
    };
}

export function clearTableData() {
    return {
        type: types.CLEAR_TABLE_DATA
    };
}

export function selectingCellCopySelection({ rowDragStopIndex }) {
    return function(dispatch, getState) {
        const { gridUI } = getState();
        const { columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex } = gridUI;

        const minRowIndex = Math.min(+rowDragStopIndex, +rowStartIndex);
        const maxRowIndex = Math.max(+rowDragStopIndex, +rowStopIndex);
        const minColIndex = +columnStartIndex;
        const maxColIndex = +columnStopIndex;

        dispatch({
            type: types.STARTING_CELL_COPY_SELECTION,
            payload: {
                rowStartIndex: minRowIndex,
                rowStopIndex: maxRowIndex,
                columnStartIndex: minColIndex,
                columnStopIndex: maxColIndex
            }
        });
    };
}

export function selectRangeCell({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, isMoving = false }) {
    return function(dispatch, getState) {
        const { advanced } = getState();
        const { rowStartIndex: rowStartIndexStore, columnStartIndex: columnStartIndexStore } = advanced;

        const rowStartIndexLatest = !isMoving ? rowStartIndex : rowStartIndexStore;
        const rowStopIndexLatest = rowStopIndex;
        const columnStartIndexLatest = !isMoving ? columnStartIndex : columnStartIndexStore;
        const columnStopIndexLatest = columnStopIndex;

        dispatch(
            _selectRangeCell({
                rowStartIndex: rowStartIndexLatest,
                rowStopIndex: rowStopIndexLatest,
                columnStartIndex: columnStartIndexLatest,
                columnStopIndex: columnStopIndexLatest
            })
        );
    };
}

export function _selectRangeCell({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) {
    return {
        type: types.SELECTING_CELL_SELECTION,
        payload: {
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex
        }
    };
}

export function cellClick({ isShift, rowIndex, columnIndex }) {
    return async function(dispatch, getState) {
        // const { advanced } = getState();
        // const { rowStartIndex, columnStartIndex } = advanced;

        dispatch(
            selectRangeCell({
                rowStartIndex: rowIndex,
                rowStopIndex: rowIndex,
                columnStartIndex: columnIndex,
                columnStopIndex: columnIndex
            })
        );

        // if (!isShift) {
        //     dispatch(
        //         selectRangeCell({
        //             rowStartIndex: rowIndex,
        //             rowStopIndex: rowIndex,
        //             columnStartIndex: columnIndex,
        //             columnStopIndex: columnIndex
        //         })
        //     );
        // } else {
        //     if (rowStartIndex > -1 && columnStartIndex > -1) {
        //         dispatch(
        //             selectRangeCell({
        //                 rowStartIndex,
        //                 rowStopIndex: rowIndex,
        //                 columnStartIndex,
        //                 columnStopIndex: columnIndex
        //             })
        //         );
        //     } else {
        //         dispatch(
        //             selectRangeCell({
        //                 rowStartIndex: rowIndex,
        //                 rowStopIndex: rowIndex,
        //                 columnStartIndex: columnIndex,
        //                 columnStopIndex: columnIndex
        //             })
        //         );
        //     }
        // }
    };
}

export function registerHoldEvent(event) {
    return {
        type: types.REGISTER_HOLD_EVENT,
        payload: {
            event
        }
    };
}

export async function _updateCellInfo({ defaultAccessViewId, dbId, body, autoQA }) {
    try {
        return await setViewRecords({
            defaultAccessViewId,
            dbId,
            body,
            autoQA
        });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function changeBooleanStatus({ columnId, rowId, checked }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;
        const cellData = getCellData({ data, rowId, columnId });

        const resource = getResource({ rowId, data });

        const newColumns = ['_recordId', cellData?.columnId];
        const defaultValue = getCellValueOnly({ data, rowId, columnId });
        const value = defaultValue === false || !defaultValue ? true : false;
        const updatedRecords = [[resource?.recordId, value]];
        const body = {
            columns: newColumns,
            records: updatedRecords
        };
        dispatch(registerDoingAction({ actionId }));

        // Fix cell preview not show right current state
        setTimeout(() => {
            dispatch(_changeCellData({ value, rowId, columnId }));
        }, 0);

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body
            });

            dispatch(removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
        }
    };
}

function _changeCellData({ value, rowId, columnId }) {
    return {
        type: types.CHANGE_CELL_DATA,
        payload: {
            value,
            rowId,
            columnId
        }
    };
}

export function openCellEdit() {
    return {
        type: types.OPEN_CELL_EDIT
    };
}

export function cannotPasteWarning() {
    return async function(dispatch, getState) {
        dispatch(
            enqueueSnackbar({
                message: "You don't have permission to do this action",
                type: 'info'
            })
        );
    };
}

export function deleteCells({ recordIds, columnIds }) {
    return {
        type: types.DELETE_CELL_INFO,
        payload: {
            recordIds,
            columnIds
        }
    };
}

export function moveCellToNextColumn({ callback }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const { rowStartIndex, columnStartIndex, columnIds } = advanced;

        const maxColumnIndex = columnIds?.length - 1;
        dispatch(
            selectRangeCell({
                rowStartIndex: rowStartIndex,
                rowStopIndex: rowStartIndex,
                columnStartIndex: Math.min(columnStartIndex + 1, maxColumnIndex),
                columnStopIndex: Math.min(columnStartIndex + 1, maxColumnIndex)
            })
        );

        callback && callback();
    };
}

export function moveCellToPreviousRow({ callback }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const { rowStartIndex, columnStartIndex } = advanced;
        dispatch(
            selectRangeCell({
                rowStartIndex: Math.max(rowStartIndex - 1, 0),
                rowStopIndex: Math.max(rowStartIndex - 1, 0),
                columnStartIndex: columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );

        callback && callback();
    };
}

export function moveCellToNextRow({ callback }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const { totalRecords, rowStartIndex, columnStartIndex } = advanced;
        dispatch(
            selectRangeCell({
                rowStartIndex: Math.min(rowStartIndex + 1, totalRecords - 1),
                rowStopIndex: Math.min(rowStartIndex + 1, totalRecords - 1),
                columnStartIndex: columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );

        callback && callback();
    };
}

export function moveCellToPreviousColumn({ callback }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const { rowStartIndex, columnStartIndex } = advanced;

        dispatch(
            selectRangeCell({
                rowStartIndex: rowStartIndex,
                rowStopIndex: rowStartIndex,
                columnStartIndex: Math.max(0, columnStartIndex - 1),
                columnStopIndex: Math.max(0, columnStartIndex - 1)
            })
        );

        callback && callback();
    };
}

export function navigateShiftRight(callback) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        let { rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex, columnIds } = advanced;
        const maxColumnIndex = columnIds?.length - 1;
        const newColumnStopIndex = columnStopIndex + 1;

        if (columnStopIndex === maxColumnIndex) {
            callback && callback();
            return;
        }

        dispatch(
            selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex: newColumnStopIndex,
                rowStopIndex
            })
        );
        callback && callback();
    };
}

export function navigateShiftLeft(callback) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        let { rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = advanced;
        const newColumnStopIndex = columnStopIndex - 1;

        if (columnStopIndex === 0) {
            callback && callback();
            return;
        }

        dispatch(
            selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex: newColumnStopIndex,
                rowStopIndex
            })
        );
        callback && callback();
    };
}

export function navigateShiftDown(callback) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        let { totalRecords, rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = advanced;
        const newRowStopIndex = rowStopIndex + 1;

        if (rowStopIndex >= totalRecords - 1) {
            callback && callback();
            return;
        }

        dispatch(
            selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex,
                rowStopIndex: newRowStopIndex
            })
        );

        callback && callback();
    };
}

export function navigateShiftUp(callback) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        let { rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = advanced;

        const newRowStopIndex = rowStopIndex - 1;
        if (rowStopIndex === 0) {
            callback && callback();
            return;
        }

        dispatch(
            selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex,
                rowStopIndex: newRowStopIndex
            })
        );
        callback && callback();
    };
}

export function openCellEditWithCharacter({ _tm, character }) {
    return function(dispatch, getState) {
        // if (_tm) {
        //     dispatch(TMActions.approveTMStatus());
        // }
        dispatch(_setCharacterKey(character));
        dispatch(openCellEdit());
    };
}

function _setCharacterKey(character) {
    return {
        type: types.SET_CHARACTER_KEY,
        payload: {
            character
        }
    };
}

export function cancelCellEdit() {
    return {
        type: types.CANCEL_CELL_EDIT
    };
}

export function cellClickAway({ isClickYourSelf, value, type, rowId, columnId, skipStatusUpdateOnSourceChange }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;

        const cellData = getCellData({ data, rowId, columnId });

        const resource = getResource({ rowId, data });

        const newColumns = ['_recordId', cellData?.columnId];

        const getServerValue = getServerValueOfSpecialType({ type, value });
        const updatedRecords = [[resource?.recordId, getServerValue]];

        const body = {
            columns: newColumns,
            records: updatedRecords,
            skipStatusUpdateOnSourceChange: skipStatusUpdateOnSourceChange || undefined
        };

        dispatch(registerDoingAction({ actionId }));
        dispatch(_changeCellData({ rowId, columnId, value }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body,
                autoQA: false
            });

            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
        }
    };
}

export function cellClickAwayAndGoNextRow({
    value,
    rowId,
    columnId,
    rowIndex,
    columnIndex,
    validations,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;

        const cellData = getCellData({ data, rowId, columnId });
        const resource = getResource({ rowId, data });
        const newColumns = ['_recordId', cellData?.columnId];

        // const isShowAutoQA = getIsShowAutoQA();

        const updatedRecords = [[resource?.recordId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };

        dispatch(registerDoingAction({ actionId }));
        dispatch(_changeCellData({ value, rowId, columnId }));
        // dispatch(_updateMetadataValidations({ validations, rowId, columnId }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToNextRow({ rowIndex, columnIndex }));

        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body: dataBody,
                autoQA: false
            });

            // if (recordHistoryId && rowId === recordHistoryId) {
            //     dispatch(setTriggerRefreshRecordHistory());
            // }
            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function cellClickAwayAndGoPreviousRow({
    value,
    rowId,
    columnId,
    rowIndex,
    columnIndex,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;
        const cellData = getCellData({ data, rowId, columnId });
        const resource = { rowId, data };

        const newColumns = ['_recordId', cellData?.columnId];

        const updatedRecords = [[resource?.recordId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };

        dispatch(registerDoingAction({ actionId }));
        dispatch(_changeCellData({ value, rowId, columnId }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToPreviousRow({ rowIndex, columnIndex }));
        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body: dataBody
            });

            // if (recordHistoryId && rowId === recordHistoryId) {
            //     dispatch(setTriggerRefreshRecordHistory());
            // }
            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function cellClickAwayAndGoNextColumn({ value, rowId, columnId, rowIndex, columnIndex }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;
        const cellData = getCellData({ data, rowId, columnId });

        const newColumns = ['_recordId', cellData?.columnId];
        const resource = getResource({ rowId, data });

        const updatedRecords = [[resource?.recordId, value]];
        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };

        dispatch(registerDoingAction({ actionId }));
        dispatch(_changeCellData({ value, rowId, columnId }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToNextColumn({ rowIndex, columnIndex }));
        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body: dataBody
            });

            // if (recordHistoryId && rowId === recordHistoryId) {
            //     dispatch(setTriggerRefreshRecordHistory());
            // }
            dispatch(removeAction({ actionId }));
            dispatch(removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
        }
    };
}

export function cellClickAwayAndGoPreviousColumn({ value, rowId, columnId, rowIndex, columnIndex }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;
        const cellData = getCellData({ data, rowId, columnId });
        const resource = getResource({ rowId, data });

        const newColumns = ['_recordId', cellData?.columnId];

        const updatedRecords = [[resource?.recordId, value]];

        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };

        dispatch(registerDoingAction({ actionId }));
        dispatch(_changeCellData({ value, rowId, columnId }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        dispatch(moveCellToPreviousColumn({ rowIndex, columnIndex }));
        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body: dataBody
            });

            // if (recordHistoryId && rowId === recordHistoryId) {
            //     dispatch(setTriggerRefreshRecordHistory());
            // }
            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
        }
    };
}

export function cellClickAwayAndStay({ value, type, rowId, columnId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { data } = advanced;
        const cellData = getCellData({ data, rowId, columnId });
        const resource = getResource({ rowId, data });

        const newColumns = ['_recordId', cellData?.columnId];

        // const isShowAutoQA = getIsShowAutoQA();

        const getServerValue = getServerValueOfSpecialType({ type, value });

        // const isSettingReference = type === columnTypes.REFERENCE;

        const updatedRecords = [[resource?.recordId, getServerValue]];

        const dataBody = {
            columns: newColumns,
            records: updatedRecords
        };

        dispatch(registerDoingAction({ actionId }));
        dispatch(_changeCellData({ value, rowId, columnId }));

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_UPDATE_CELL,
                body: {
                    columnId,
                    rowId,
                    cellData
                }
            })
        );

        try {
            await _updateCellInfo({
                defaultAccessViewId: cellData?.viewId,
                dbId: resource?.dbId,
                body: dataBody,
                autoQA: false
            });

            // if (isSettingReference) {
            //     dispatch(
            //         rowActions.fetchOtherReferenceDataColumns({
            //             refColumnIds: [columnId],
            //             recordIds: [rowId]
            //         })
            //     );
            // }

            // if (recordHistoryId && rowId === recordHistoryId) {
            //     dispatch(setTriggerRefreshRecordHistory());
            // }
            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
            dispatch(removeDoingAction({ actionId }));
            dispatch(revertAction({ actionId }));
        }
    };
}

export function commitAction({ actionId, type, body }) {
    return {
        type: types.COMMIT_ACTION,
        payload: {
            actionId,
            type,
            body
        }
    };
}

export function removeAction({ actionId }) {
    return {
        type: types.REMOVE_ACTION,
        payload: {
            actionId
        }
    };
}

export function revertAction({ actionId }) {
    return {
        type: types.REVERT_ACTION,
        payload: {
            actionId
        }
    };
}

export function QuickSortChange({ columnId, value }) {
    return {
        type: types.QUICK_SORT_CHANGE,
        payload: {
            columnId,
            value
        }
    };
}

export function clearQuickFiltersAfterColumnDeletedOrHidden({ columnId }) {
    return {
        type: types.CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN,
        payload: {
            columnId
        }
    };
}

export function QuickFilterChange({ columnId, value, type, currentState, operator, fullPathId }) {
    return {
        type: types.QUICK_FILTER_INPUT_CHANGE,
        payload: {
            columnId,
            value,
            type,
            currentState,
            operator,
            fullPathId
        }
    };
}

export function resetQuickFilterValue({ columnId }) {
    return {
        type: types.RESET_QUICK_FILTER_VALUE,
        payload: {
            columnId
        }
    };
}

export function quickFilterExtraChange({ columnId, extraFilter }) {
    return {
        type: types.QUICK_FILTER_EXTRA_CHANGE,
        payload: {
            columnId,
            extraFilter
        }
    };
}

export function quickFilterRegexChange({ columnId, filterMode }) {
    return {
        type: types.QUICK_FILTER_REGEX_CHANGE,
        payload: {
            columnId,
            filterMode
        }
    };
}

export function fetchRecordsWithFiltersAndSorts({ wsId, success, error }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const {
            quickFilters,
            quickSorts,
            advancedSearch,
            routeInfo,
            searchValue,
            matches = [],
            selectedAdvancedSearchId
        } = advanced;
        const workspaceIdCombined = wsId || routeInfo?.workspaceId;
        const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;

        try {
            let body = {
                limit: ADVANCED_SEARCH_RECORD_LIMIT
            };

            if (!isEmpty(quickFilters)) {
                body = {
                    ...body,
                    filter: formatQuickFilters(quickFilters)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sorts: quickSorts
                };
            }

            if (!!searchValue) {
                body = {
                    ...body,
                    q: searchValue
                };
            }

            if (matches?.length) {
                body = {
                    ...body,
                    modes: matches
                };
            }

            dispatch(registerDoingAction({ actionId }));

            const res = await getAdvancedSearchRecordsApi({
                wsId: workspaceIdCombined,
                advancedSearchId: combinedAdvancedSearchId,
                body
            });
            dispatch(_fetchRecordsWithFiltersAndSortsSuccess(res));
            dispatch(removeDoingAction({ actionId }));
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            error && error();
            dispatch(removeDoingAction({ actionId }));
        }
    };
}

export function _fetchRecordsWithFiltersAndSortsSuccess(payload) {
    return {
        type: types.FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS,
        payload
    };
}

export function resetCellStatus() {
    return async function(dispatch, getState) {
        // const { auth } = getState();
        // const { currentUser } = auth;

        // const actionId = uuidv1();
        // dispatch(
        //     realtimeUserActions.registerRealtimeUser({
        //         actionId,
        //         body: {
        //             action: USER_REALTIME_ACTION.RESET,
        //             user: formatUserRealtimeBody(currentUser)
        //         }
        //     })
        // );
        dispatch(_resetCellStatus());
        // dispatch(appActions.changeContext({ contextMenuId: null }));
    };
}

function _resetCellStatus() {
    return {
        type: types.RESET_CELL_STATUS
    };
}

export function setRouterInfo(routeInfo) {
    return {
        type: types.SET_ROUTE_INFO,
        payload: {
            routeInfo
        }
    };
}

function generateDefaultFilter(columns) {
    const firstColumn = columns?.[0];
    if (!firstColumn) return undefined;

    let columnType = getCorrectColumnType(firstColumn);
    let validOperators = getConditionsByType(columnType);

    const selectedOperator = generateDefaultConditionByColumnType({
        column: firstColumn,
        validOperators,
        isChildDependency: false
    });
    return {
        id: generateTempId(),
        hashColumnId: firstColumn?.hashColumnId,
        operator: selectedOperator?.value,
        values: []
    };
}

export function createViewFilter({ groupWithFilterId, groupId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, routeInfo } = advanced;
        const columns = mapColumns(advancedSearch?.columns);
        const filter = generateDefaultFilter(columns);

        if (!filter) {
            dispatch(
                enqueueSnackbar({
                    message: `Please add column to grid before create view filter`,
                    type: 'info'
                })
            );
            return;
        }
        dispatch(_createViewFilterAction({ filter: { ...filter, groupId } }));
        dispatch(registerDoingAction({ actionId }));
        try {
            const createdFilter = await createViewFilterApi({
                wsId: routeInfo?.workspaceId,
                advancedSearchId: advancedSearch?.advancedSearchId,
                body: {
                    groupWithFilterId: groupWithFilterId || undefined,
                    operator: filter?.operator,
                    hashColumnId: filter?.hashColumnId,
                    values: filter?.values
                }
            });
            dispatch(
                _createViewFilterActionSuccess({
                    oldFilterId: filter?.id,
                    newFilter: createdFilter,
                    sourceId: groupWithFilterId
                })
            );
            dispatch(removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_createViewFilterActionFailed({ filterId: filter?.id }));
            dispatch(removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function _createViewFilterAction({ filter }) {
    return {
        type: types.CREATE_VIEW_FILTER,
        payload: {
            filter
        }
    };
}

export function _createViewFilterActionFailed({ filterId }) {
    return {
        type: types.CREATE_VIEW_FILTER_FAILED,
        payload: {
            filterId
        }
    };
}

export function _createViewFilterActionSuccess({ oldFilterId, newFilter, sourceId }) {
    return {
        type: types.CREATE_VIEW_FILTER_SUCCESS,
        payload: {
            oldFilterId,
            newFilter,
            sourceId
        }
    };
}

export function deleteViewFilter({ filterId, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, routeInfo } = advanced;

        dispatch(registerDoingAction({ actionId }));
        dispatch(_deleteViewFilterAction({ filterId }));
        try {
            await deleteViewFilterApi({
                wsId: routeInfo?.workspaceId,
                advancedSearchId: advancedSearch?.advancedSearchId,
                filterId
            });
            dispatch(_fetchRecordsAfterFilter());
            dispatch(_deleteViewFilterActionSuccess({ filterId }));
            dispatch(removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteViewFilterActionFailed({ filterId }));
            dispatch(removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

function _deleteViewFilterAction({ filterId }) {
    return {
        type: types.DELETE_VIEW_FILTER,
        payload: {
            filterId
        }
    };
}

function _deleteViewFilterActionFailed({ filterId }) {
    return {
        type: types.DELETE_VIEW_FILTER_FAILED,
        payload: {
            filterId
        }
    };
}

export function _deleteViewFilterActionSuccess({ filterId }) {
    return {
        type: types.DELETE_VIEW_FILTER_SUCCESS,
        payload: {
            filterId
        }
    };
}

export function updateViewFilter({ filterId, isFetchServer = true, newFilter, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, routeInfo } = advanced;
        const { values, hashColumnId } = newFilter;
        const metaData = mapMetaData(advancedSearch?.columns);
        const columnType = getCorrectColumnType(metaData?.[hashColumnId]);
        const serverValue = generateServerFilterValue({ type: columnType, values });
        dispatch(registerDoingAction({ actionId }));

        try {
            await updateViewFilterApi({
                wsId: routeInfo?.workspaceId,
                advancedSearchId: advancedSearch?.advancedSearchId,
                filterId,
                body: {
                    operator: newFilter.operator,
                    hashColumnId: newFilter?.hashColumnId,
                    values: serverValue,
                    subField: newFilter?.subField
                }
            });
            if (isFetchServer) {
                dispatch(_fetchRecordsAfterFilter());
            }
            dispatch(_updateViewFilterActionSuccess({ filterId, newFilter }));
            dispatch(removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            errorCallback && errorCallback();
        }
    };
}

export function _updateViewFilterActionSuccess({ filterId, newFilter }) {
    return {
        type: types.UPDATE_VIEW_FILTER_SUCCESS,
        payload: {
            filterId,
            newFilter
        }
    };
}

export function createViewSortOrder(sortOrder) {
    return {
        type: types.CREATE_VIEW_SORT,
        payload: {
            sortOrder
        }
    };
}

export function updateViewSortOrder({ sortOrderId, sortOrder, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, routeInfo } = advanced;
        const { direction, hashColumnId, id, oldValue } = sortOrder;
        try {
            dispatch(registerDoingAction({ actionId }));
            if (!direction) {
                await deleteViewSortApi({
                    wsId: routeInfo?.workspaceId,
                    advancedSearchId: advancedSearch?.advancedSearchId,
                    sortOrderId
                });
                dispatch(
                    updateViewSortOrderOnly({
                        sortOrderId,
                        newSortOrder: {
                            ...sortOrder,
                            direction,
                            oldValue: direction
                        }
                    })
                );
            } else if (oldValue !== direction && !oldValue) {
                const createdSortOrder = await createViewSortApi({
                    wsId: routeInfo?.workspaceId,
                    advancedSearchId: advancedSearch?.advancedSearchId,
                    body: {
                        hashColumnId,
                        direction
                    }
                });
                dispatch(
                    updateViewSortOrderOnly({
                        sortOrderId: id,
                        newSortOrder: {
                            ...createdSortOrder,
                            oldValue: direction
                        }
                    })
                );
            } else {
                await updateViewSortApi({
                    wsId: routeInfo?.workspaceId,
                    advancedSearchId: advancedSearch?.advancedSearchId,
                    sortOrderId,
                    body: {
                        ...sortOrder,
                        direction
                    }
                });
                dispatch(
                    updateViewSortOrderOnly({
                        sortOrderId,
                        newSortOrder: {
                            ...sortOrder,
                            direction,
                            oldValue: direction
                        }
                    })
                );
            }
            dispatch(removeDoingAction({ actionId }));
            dispatch(_fetchRecordsAfterFilter());
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function deleteViewSortOrder({ sortOrderId, direction, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, routeInfo } = advanced;
        if (!direction) {
            dispatch(_deleteViewSortOrder(sortOrderId));
        } else {
            dispatch(registerDoingAction({ actionId }));
            dispatch(_deleteViewSortOrder(sortOrderId));
            try {
                await deleteViewSortApi({
                    wsId: routeInfo?.workspaceId,
                    advancedSearchId: advancedSearch?.advancedSearchId,
                    sortOrderId
                });
                dispatch(_fetchRecordsAfterFilter());
                dispatch(removeDoingAction({ actionId }));
            } catch (error) {
                const { message } = error;
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
                dispatch(removeDoingAction({ actionId }));
                errorCallback && errorCallback();
            }
        }
    };
}

function _deleteViewSortOrder(sortOrderId) {
    return {
        type: types.DELETE_VIEW_SORT,
        payload: {
            sortOrderId
        }
    };
}

export function updateViewSortOrderOnly({ sortOrderId, newSortOrder }) {
    return {
        type: types.UPDATE_VIEW_SORT,
        payload: {
            sortOrderId,
            newSortOrder
        }
    };
}

function _fetchRecordsAfterFilter() {
    return async function(dispatch, getState) {
        const actionId = uuidv1();

        try {
            dispatch(registerDoingAction({ actionId }));
            const { advanced } = getState();
            const {
                quickFilters,
                quickSorts,
                advancedSearch,
                routeInfo,
                searchValue,
                matches = [],
                selectedAdvancedSearchId
            } = advanced;
            const workspaceIdCombined = routeInfo?.workspaceId;
            const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;

            let body = {
                lastId: null,
                limit: ADVANCED_SEARCH_RECORD_LIMIT
            };
            if (!isEmpty(quickFilters)) {
                body = {
                    ...body,
                    filter: formatQuickFilters(quickFilters)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sorts: quickSorts
                };
            }

            if (!!searchValue) {
                body = {
                    ...body,
                    q: searchValue
                };
            }

            if (matches?.length) {
                body = {
                    ...body,
                    modes: matches
                };
            }

            const res = await getAdvancedSearchRecordsApi({
                wsId: workspaceIdCombined,
                advancedSearchId: combinedAdvancedSearchId,
                body
            });
            dispatch(_fetchAdvancedSearchRecordsSuccess(res));
            dispatch(removeDoingAction({ actionId }));
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
        }
    };
}

export function resetExportRemovedColumns() {
    return {
        type: types.RESET_EXPORT_REMOVED_COLUMNS
    };
}

export function addExportRemovedColumn({ hashColumnId }) {
    return {
        type: types.ADD_EXPORT_REMOVED_COLUMN,
        payload: {
            hashColumnId
        }
    };
}

export function restoreExportRemovedColumn({ hashColumnId }) {
    return {
        type: types.RESTORE_EXPORT_REMOVED_COLUMN,
        payload: {
            hashColumnId
        }
    };
}

export function exportViewData({ queryParams, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const { advanced, app } = getState();
            const { routeInfo, advancedSearch } = advanced;
            const shareViewKey = app?.shareViewKey;
            await exportApi({
                wsId: routeInfo?.workspaceId,
                advancedSearchId: advancedSearch?.advancedSearchId,
                queryParams: {
                    ...queryParams,
                    q: advanced?.searchValue,
                    shareViewKey
                }
            });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function setSelecting(enabled) {
    return {
        type: types.SET_SELECTING,
        payload: {
            enabled
        }
    };
}

export function setSelectingCopy(enabled) {
    return {
        type: types.SET_SELECTING_COPY,
        payload: {
            enabled
        }
    };
}

export function resetGrid(ignoreFields) {
    return {
        type: types.RESET_GRID,
        payload: {
            ignoreFields
        }
    };
}

export function openCellFilePreview({ rowId, columnId, dbId, defaultPreviewFile, type }) {
    return {
        type: types.OPEN_CELL_FILE_PREVIEW,
        payload: {
            rowId,
            columnId,
            defaultPreviewFile,
            type,
            dbId
        }
    };
}

export function closeCellFilePreview() {
    return {
        type: types.CLOSE_CELL_FILE_PREVIEW
    };
}

export function uploadFileForCell({
    columnId,
    actualColumnId,
    rowId,
    actualRowId,
    dbId,
    gridId,
    formData,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const { data } = advanced;
        const actionId = uuidv1();

        dispatch(registerDoingAction({ actionId }));
        try {
            const cellData = getCellData({ data, columnId, rowId });
            const file = await uploadFileForCellApi({
                formData,
                dbId,
                gridId,
                rowId: actualRowId,
                columnId: actualColumnId
            });
            const newRowData = [file, ...(cellData?.value || [])];

            dispatch(_changeCellData({ columnId, rowId, value: newRowData }));

            dispatch(removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            errorCallback && errorCallback(message);
        }
    };
}

export function pinnedSearch() {
    return {
        type: types.PINNED_SEARCH
    };
}

export function unPinnedSearch() {
    return {
        type: types.UNPINNED_SEARCH
    };
}

export function openReplaceSearch() {
    return {
        type: types.OPEN_REPLACE_SEARCH
    };
}

export function closeReplaceSearch() {
    return {
        type: types.CLOSE_REPLACE_SEARCH
    };
}

export function setReplacingText(text) {
    return {
        type: types.SET_REPLACING_TEXT,
        payload: {
            text
        }
    };
}

export function setSearchRecords(payload) {
    return {
        type: types.SET_SEARCH_RECORDS,
        payload
    };
}

export function setSuccessReplaceAll(payload) {
    return {
        type: types.SET_SUCCESS_REPLACE_ALL,
        payload
    };
}

export function setReplacingType(payload) {
    return {
        type: types.SET_REPLACING_TYPE,
        payload
    };
}

export function clearSearchState() {
    return {
        type: types.SET_CLEAR_SEARCH_STATE
    };
}

export function jumpToCell() {
    return function(dispatch, getState) {};
}

export function setSearch(value) {
    return {
        type: types.SET_SEARCH,
        payload: {
            value
        }
    };
}

export function setMatches(matches) {
    return {
        type: types.SET_MATCHES,
        payload: {
            matches
        }
    };
}

export function setSelectedColumns(columns) {
    return {
        type: types.SET_SELECTED_COLUMNS,
        payload: {
            columns
        }
    };
}

export function handleReplace({ replaceMode, success, error }) {
    return async function(dispatch, getState) {
        try {
            dispatch(setReplacingType(replaceMode));
            dispatch(setGlobalAction(GLOBAL_SEARCH_TYPES.REPLACING));
            const { advanced } = getState();
            const {
                advancedSearch,
                routeInfo,
                quickFilters,
                quickSorts,
                searchValue,
                replacingText,
                matches
            } = advanced;
            let body = {
                replaceMode,
                data: replacingText || ''
            };

            if (!isEmpty(quickFilters)) {
                body = {
                    ...body,
                    filter: formatQuickFilters(quickFilters)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sorts: quickSorts
                };
            }

            if (!!searchValue) {
                body = {
                    ...body,
                    q: searchValue
                };
            }

            if (matches?.length) {
                body = {
                    ...body,
                    modes: matches
                };
            }

            const response = await replaceSearchApi({
                wsId: routeInfo?.workspaceId,
                advancedSearchId: advancedSearch?.advancedSearchId,
                body
            });
            if (replaceMode === 'single') {
                if (isLDEmpty(response?.cellData)) {
                    dispatch(
                        enqueueSnackbar({
                            message: `Nothing left to replace.`,
                            type: 'info'
                        })
                    );
                    dispatch(setReplacingType(``));
                    dispatch(setGlobalAction(null));
                } else {
                    dispatch(
                        updateData({
                            newData: {
                                [response?.recordId]: {
                                    [response?.hashColumnId]: response?.cellData
                                }
                            },
                            isCareData: true
                        })
                    );
                    dispatch(setReplacingType(``));
                    dispatch(setGlobalAction(null));
                    dispatch(
                        enqueueSnackbar({
                            message: `Replaced "${searchValue}" with "${replacingText}" in one record.`,
                            type: 'info'
                        })
                    );
                }
            }
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(setReplacingType(``));
            dispatch(setGlobalAction(null));
            error && error();
        }
    };
}

export function updateData({ newData, isCareData = false }) {
    return {
        type: types.UPDATE_DATA,
        payload: {
            newData,
            isCareData
        }
    };
}

export function setGlobalAction(action) {
    return {
        type: types.SET_GLOBAL_ACTION,
        payload: {
            action
        }
    };
}

export function refreshCurrentAdvancedSearch({ success, error }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const {
            quickFilters,
            quickSorts,
            advancedSearch,
            routeInfo,
            searchValue,
            matches = [],
            selectedAdvancedSearchId
        } = advanced;
        const workspaceIdCombined = routeInfo?.workspaceId;
        const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;

        try {
            let body = {
                lastId: null,
                limit: ADVANCED_SEARCH_RECORD_LIMIT
            };

            if (!isEmpty(quickFilters)) {
                body = {
                    ...body,
                    filter: formatQuickFilters(quickFilters)
                };
            }

            if (!isEmpty(quickSorts)) {
                body = {
                    ...body,
                    sorts: quickSorts
                };
            }

            if (!!searchValue) {
                body = {
                    ...body,
                    q: searchValue
                };
            }

            if (matches?.length) {
                body = {
                    ...body,
                    modes: matches
                };
            }

            const res = await getAdvancedSearchRecordsApi({
                wsId: workspaceIdCombined,
                advancedSearchId: combinedAdvancedSearchId,
                body
            });
            dispatch(_fetchAdvancedSearchRecordsSuccess(res));
            success && success();
        } catch ({ message }) {
            dispatch(_fetchMoreRecordsFailed());
            error && error();
        }
    };
}

export function setSelectedAdvancedSearchId(aId) {
    return {
        type: types.SET_SELECTED_ADVANCED_SEARCH_ID,
        payload: {
            aId
        }
    };
}

export function setHideWarning(value) {
    return {
        type: types.SET_HIDE_WARNING,
        payload: {
            value
        }
    };
}

export function setClearGrid(value) {
    return {
        type: types.SET_CLEAR_GRID,
        payload: {
            value
        }
    };
}

export function setTab(value) {
    return {
        type: types.SET_TAB,
        payload: {
            value
        }
    };
}

export function setOpenConfirmation({ nextAdvancedSearchId, open }) {
    return {
        type: types.SET_OPEN_CONFIRMATION,
        payload: {
            nextAdvancedSearchId,
            open
        }
    };
}

export function columnSelection({ columnSelected, columnIndex, isShift, isCtrl, callback }) {
    return async function(dispatch, getState) {
        const { advanced } = getState();
        const columnIds = advanced?.columnIds;
        const oldColumnIdSelected = advanced?.oldColumnIdSelected;

        let columnsSelected = [...advanced?.columnsSelected];

        if (!isCtrl && !isShift) {
            columnsSelected = [columnSelected];
        } else if (isCtrl) {
            if (columnsSelected.includes(columnSelected)) {
                columnsSelected = columnsSelected.filter(item => item !== columnSelected);
            } else {
                columnsSelected = columnsSelected.concat(columnSelected);
            }
        } else {
            let oldColumnIndex = columnIds.findIndex(colId => colId === oldColumnIdSelected);
            if (oldColumnIndex < 0) {
                columnsSelected = [];
            } else {
                const min = Math.min(oldColumnIndex, columnIndex);
                const max = Math.max(oldColumnIndex, columnIndex);
                columnsSelected = columnIds.slice(min, max + 1);
            }
        }

        callback && callback({ columnsSelected });

        dispatch(
            _columnSelection({
                oldColumnIdSelected: !isShift ? columnSelected : oldColumnIdSelected,
                columnsSelected
            })
        );
    };
}

function _columnSelection({ oldColumnIdSelected, columnsSelected }) {
    return {
        type: types.COLUMN_SELECTION,
        payload: {
            oldColumnIdSelected,
            columnsSelected
        }
    };
}

export function clearColumnSelection() {
    return {
        type: types.CLEAR_COLUMNS_SELECTION
    };
}

export function setColumnsSelection({ columnsSelected, columnId }) {
    return {
        type: types.SET_COLUMNS_SELECTION,
        payload: {
            columnsSelected,
            columnId
        }
    };
}

export function reorderColumns({
    beforeColumn,
    afterColumn,
    columnId,
    reorderColumns,
    successCallback,
    errorCallback,
    wsId
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { advanced } = getState();
        const { advancedSearch, selectedAdvancedSearchId, columnIds, routeInfo } = advanced;
        const combinedAdvancedSearchId = advancedSearch?.advancedSearchId || selectedAdvancedSearchId;
        const workspaceIdCombined = wsId || routeInfo?.workspaceId;

        dispatch(registerDoingAction({ actionId }));

        let splitColumnIndex = 0;
        let correctColumnIds = [];

        if (beforeColumn) {
            splitColumnIndex = columnIds.findIndex(colId => colId === beforeColumn);
        } else {
            splitColumnIndex = columnIds.findIndex(colId => colId === columnId);

            if (afterColumn === columnIds?.at(-1)) {
                splitColumnIndex += 1;
            }
        }

        let firstArr = columnIds.slice(0, splitColumnIndex);
        firstArr = firstArr.filter(viewColId => !reorderColumns.includes(viewColId));
        let secondArr = columnIds.slice(splitColumnIndex);
        secondArr = secondArr.filter(viewColId => !reorderColumns.includes(viewColId));
        correctColumnIds = [...firstArr, ...reorderColumns, ...secondArr];

        dispatch(
            _reorderColumnsActionSuccess({
                columnIds: correctColumnIds
            })
        );
        dispatch(triggerRecomputedGrid());

        dispatch(
            commitAction({
                actionId,
                type: types.OPTIMISTIC_REORDER_COLUMNS,
                body: {
                    columnIds
                }
            })
        );
        successCallback && successCallback();
        try {
            const data = {
                reorderColumns
            };
            if (beforeColumn) {
                data['beforeColumn'] = beforeColumn;
            }
            if (afterColumn) {
                data['afterColumn'] = afterColumn;
            }

            const newColumnIds = await reorderAdvancedSearchApi({
                wsId: workspaceIdCombined,
                adId: combinedAdvancedSearchId,
                body: data
            });
            if (newColumnIds?.length) {
                dispatch(
                    _reorderColumnsActionSuccess({
                        columnIds: newColumnIds
                    })
                );
            }

            dispatch(removeDoingAction({ actionId }));
            dispatch(removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(revertAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(removeDoingAction({ actionId }));
            dispatch(triggerRecomputedGrid());
            errorCallback && errorCallback();
        }
    };
}

export function triggerRecomputedGrid() {
    return {
        type: types.TRIGGER_RECOMPUTED_GRID
    };
}

export function resetTriggerRecomputedGrid() {
    return {
        type: types.RESET_TRIGGER_RECOMPUTED_GRID
    };
}

function _reorderColumnsActionSuccess({ columnIds }) {
    return {
        type: types.REORDER_COLUMNS_SUCCESS,
        payload: {
            columnIds
        }
    };
}
