import React from 'react';

const ParagraphSVG = ({ color = '#2A293A', ...rest }) => {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M3.90101 10.2111L0.471008 6.40306L3.90101 2.59506V4.82106H6.36501V0.789062H9.52901V6.37506C9.52901 6.9444 9.41701 7.35506 9.19301 7.60706C8.97834 7.85906 8.55367 7.98506 7.91901 7.98506H3.90101V10.2111ZM3.31301 8.68506V7.39706H7.91901C8.34834 7.39706 8.62368 7.3364 8.74501 7.21506C8.87568 7.0844 8.94101 6.8044 8.94101 6.37506V1.37706H6.95301V5.40906H3.31301V4.12106L1.25501 6.40306L3.31301 8.68506Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(ParagraphSVG);
