export const defaultProjectAccess = {
    project: { value: 0, label: '' },
    database: { value: 0, label: '' },
    isFake: true
};

export const allDatabaseOption = { value: -1, label: '(All Databases)' };

export const getProjectsServer = projectAccesses => {
    const servedProjects = [];
    return projectAccesses.reduce((acc, cur) => {
        if (cur?.project?.value !== 0 && !servedProjects.includes(cur.project.value)) {
            servedProjects.push(cur.project.value);
            const project = { projectId: cur.project.value };
            const filterProjects = projectAccesses.filter(p => p.project?.value === cur.project.value);
            for (let i = 0; i < filterProjects.length; i++) {
                const p = filterProjects[i];
                if (p.database.value === -1) {
                    break;
                }
                if (!project.databaseIds) {
                    project.databaseIds = [];
                }
                if (!project.databaseIds.includes(p.database.value)) {
                    project.databaseIds.push(p.database.value);
                }
            }
            acc.push(project);
        }
        return acc;
    }, []);
};

export const getProjectAccessedFromServer = ({ serverProjects, projectOptions }) => {
    return serverProjects.reduce((acc, cur) => {
        if (!cur?.projectId || !projectOptions?.[cur.projectId]) return acc;
        const project = {
            ...projectOptions[cur.projectId],
            value: cur.projectId,
            label: projectOptions[cur.projectId].name
        };
        if (!cur.databaseIds?.length) {
            acc.push({
                project,
                database: allDatabaseOption
            });
        } else {
            const dbOptions =
                project.databases?.reduce((acc, cur) => {
                    acc[cur.id] = { ...cur, value: cur.id, label: cur.name };
                    return acc;
                }, {}) || {};
            cur.databaseIds.map(databaseId => {
                if (dbOptions[databaseId]) {
                    acc.push({
                        project,
                        database: dbOptions[databaseId]
                    });
                }
                return false;
            });
        }
        return acc;
    }, []);
};
