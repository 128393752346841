import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useCompanyInfo } from 'hooks/app';
import { SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON } from 'const';
import Header from './Header';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        height: '100vh'
    },

    leftContent: {
        width: SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON
    },

    rightContent: {
        flex: 1
    }
}));

function EditorTmLayout({ children }) {
    const classes = useStyles();
    const company = useCompanyInfo();

    return (
        <Grid container direction={'column'} wrap="nowrap" className={classes.root}>
            {company?.name && (
                <Helmet>
                    <title>{`${company.name} | Gridly`}</title>
                </Helmet>
            )}
            <Grid item className={classes.fixedHeader}>
                <Header />
            </Grid>
            <Grid item className={classes.content}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(EditorTmLayout);
