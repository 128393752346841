import { jsx } from 'slate-hyperscript';
import isEmpty from 'lodash/isEmpty';
import isHtml from 'is-html';
import { COLORING } from 'const';

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'block-quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' })
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
    SPAN: el => {
        let color = el?.style?.color;
        if (color)
            return {
                color,
                [COLORING]: true
            };
        return { [COLORING]: true };
    }
};

const deserializeEach = el => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        return '\n';
    }

    const { nodeName } = el;
    let parent = el;

    if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
        parent = el.childNodes[0];
    }

    const children = Array.from(parent.childNodes)
        .map(deserializeEach)
        .flat();

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        const isEmptyChildren = isEmpty(children);
        let childrenUpdated = isEmptyChildren ? [''] : children;
        return jsx('element', attrs, childrenUpdated);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map(child => jsx('text', attrs, child));
    }

    return children;
};

const deserialize = rawHtml => {
    let html = rawHtml;
    if (!isHtml(rawHtml)) {
        html = `<p>${rawHtml}</p>`;
    }

    const document = new DOMParser().parseFromString(html, 'text/html');
    return deserializeEach(document.body);
};

export default deserialize;
