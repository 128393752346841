import React from 'react';
import { Grid } from '@material-ui/core';
import { useActiveUsersOnCell } from 'hooks/gridUI';
import { INDEX_COLUMN_WIDTH, DEFAULT_COLUMN_WIDTH } from 'const/gridUI';
import { getColorByUserId } from 'utils/color';

function RealtimeUsers({ viewColumns, rowHeight, type, fixedColumnCount }) {
    const activeUsersOnCell = useActiveUsersOnCell();

    const cells = React.useMemo(() => {
        return Object.keys(activeUsersOnCell);
    }, [activeUsersOnCell]);

    if (!cells?.length) return null;

    return cells?.map(cell => {
        const [rowIndex, columnId] = cell?.split('.');
        const users = activeUsersOnCell?.[cell];
        const top = rowIndex * rowHeight;
        const foundedColumn = viewColumns?.find(col => col?.id === columnId);

        if (!foundedColumn) return null;

        const width = foundedColumn?.customProperties?.width || DEFAULT_COLUMN_WIDTH;
        const currentColumnIndex = viewColumns?.findIndex(col => col?.id === columnId);

        let left = 0;

        if (currentColumnIndex >= fixedColumnCount) {
            if (type === 'left') return null;
            const offsetColumns = viewColumns?.slice(fixedColumnCount, currentColumnIndex);
            left = offsetColumns?.reduce((t, col) => t + (+col?.customProperties?.width || DEFAULT_COLUMN_WIDTH), 0);
        } else {
            if (type === 'right') return null;
            left =
                INDEX_COLUMN_WIDTH +
                viewColumns
                    ?.slice(0, currentColumnIndex)
                    ?.reduce((t, col) => t + (+col?.customProperties?.width || DEFAULT_COLUMN_WIDTH), 0);
        }

        const userColor = getColorByUserId(users?.[0]?.userId);

        return (
            <Grid
                key={cell}
                container
                style={{
                    top,
                    left,
                    width,
                    height: rowHeight,
                    border: `2px solid ${userColor}`,
                    position: 'absolute',
                    pointerEvents: 'none'
                }}
            >
                <p
                    className={
                        'absolute body2 pointer-events-none top-0 right-0 text-[8px] w-auto font-bold pl-1 pr-1 h-3 text-white rounded-bl'
                    }
                    style={{ background: userColor }}
                >
                    {users?.map(user => user.name)?.join(',')}
                </p>
            </Grid>
        );
    });
}

export default React.memo(RealtimeUsers);
