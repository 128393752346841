import React from 'react';

function SettingInfoSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99935 2.41699C5.81119 2.41699 2.41602 5.81217 2.41602 10.0003C2.41602 14.1885 5.81119 17.5837 9.99935 17.5837C14.1875 17.5837 17.5827 14.1885 17.5827 10.0003C17.5827 5.81217 14.1875 2.41699 9.99935 2.41699ZM0.916016 10.0003C0.916016 4.98374 4.98276 0.916992 9.99935 0.916992C15.0159 0.916992 19.0827 4.98374 19.0827 10.0003C19.0827 15.0169 15.0159 19.0837 9.99935 19.0837C4.98276 19.0837 0.916016 15.0169 0.916016 10.0003ZM9.99951 9.00034C10.5518 9.00034 10.9995 9.44805 10.9995 10.0003V13.3337C10.9995 13.886 10.5518 14.3337 9.99951 14.3337C9.44723 14.3337 8.99951 13.886 8.99951 13.3337V10.0003C8.99951 9.44805 9.44723 9.00034 9.99951 9.00034ZM9.99951 5.66699C9.44723 5.66699 8.99951 6.11471 8.99951 6.66699C8.99951 7.21928 9.44723 7.66699 9.99951 7.66699H10.0095C10.5618 7.66699 11.0095 7.21928 11.0095 6.66699C11.0095 6.11471 10.5618 5.66699 10.0095 5.66699H9.99951Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SettingInfoSVG);
