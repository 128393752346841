import { Grid, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SettingContentLayout from '../common/Layout';
import IOSSwitch from 'components/switches/IOS';
import { makeStyles } from '@material-ui/styles';
import { useDisplayNotificationRealtime } from 'hooks/auth';
import * as authActions from 'auth/actions';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { USER_SETTINGS } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `40px 20px 0px`
    }
}));

const NotificationSettings = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const displayNotificationRealtime = useDisplayNotificationRealtime();
    const [checked, setChecked] = useState(displayNotificationRealtime);

    const onChange = useCallback(
        (e, value) => {
            setChecked(value);
            dispatch(
                authActions.setUserSettings({
                    settings: {
                        [USER_SETTINGS.AUTH_DISPLAY_NOTIFICATION_REALTIME]: value
                    }
                })
            );
        },
        [dispatch]
    );

    return (
        <SettingContentLayout name={t('global_notification_setting')}>
            <Grid className={classes.root} container direction="column" wrap="nowrap">
                <Grid item>
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item>
                            <Typography variant="body1">{t('display_the_message_previews')}</Typography>
                        </Grid>
                        <Grid item>
                            <IOSSwitch checked={checked} onChange={onChange} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
};

export default React.memo(NotificationSettings);
