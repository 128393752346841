import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Tabs, Tab } from '@material-ui/core';
import SettingContentLayout from '../common/Layout';
import Select from 'components/selects/Select';
import Search from 'components/search';
import * as memberActions from '../actions/member';
import MemberTable from './MemberTable';
import { StatusIcon } from './Icons';
import LDSelectBase from 'components/selects/LDBase';
import { useRoleList, useMemberList, useInternalMemberList, useIsFetchingMemberList } from 'hooks/permission';
import MemberTableSkeleton from './MemberTableSkeleton';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { COMPANY_MEMBERS, INTERNAL_MEMBERS, PLAN_TYPES } from 'const';
import { useTranslation } from 'react-i18next';
import WarningBox from 'payment/components/warning';
import ButtonBase from 'components/button/Base';
import { PAYMENT_WARNING_TYPES } from 'const';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useHistory } from 'react-router-dom';
import { useCurrentSubscriptionIs, useIsMemberSeatExceeded } from 'hooks/payment';
import { useCompanyId } from 'hooks/auth';
import InviteMembers from 'permission/invitation/inviteMembers';
import { isExternal } from 'config';
import i18n from 'i18n';
import { exportToSheetFileApi } from 'services/members';
import { isAdmin, isOwner } from 'utils/permission/role';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 24
    },
    filters: {
        marginBottom: 24
    },
    dropdownClassName: {
        minWidth: 230
    },
    indicator: {
        backgroundColor: theme.palette.primary.main
    },
    tabRoot: {
        minWidth: 120,
        '& *': {
            color: theme.colors.primaryText,
            opacity: 0.5
        }
    },
    selected: {
        '& *': {
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 500,
            opacity: 1
        }
    },
    addIcon: {
        marginRight: theme.spacing(2)
    },
    upgradeIcon: {
        marginRight: theme.spacing(1)
    }
}));

const STATUS_OPTIONS = [
    { value: 'all', label: i18n.t('all_status') },
    { value: 'active', label: i18n.t('active'), icon: <StatusIcon status={'active'} /> },
    { value: 'inactive', label: i18n.t('inactive'), icon: <StatusIcon status={'inactive'} /> },
    { value: 'pending_invite', label: i18n.t('pending_invitation'), icon: <StatusIcon status={'pending_invite'} /> },
    { value: 'pending_approval', label: i18n.t('pending_approval'), icon: <StatusIcon status={'pending_approval'} /> }
];

const tabs = [COMPANY_MEMBERS, INTERNAL_MEMBERS];

function Members() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const roles = useRoleList();
    const isFetching = useIsFetchingMemberList();
    const userInfo = useCurrentUserFullInfo();
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const history = useHistory();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isMemberSeatExceeded = useIsMemberSeatExceeded(0);

    const companyId = useCompanyId();
    const isCurrentOwner = isOwner({ currentUser: userInfo });
    const isCurrentAdmin = isAdmin({ currentUser: userInfo });

    const normalMemberList = useMemberList();
    const internalMemberList = useInternalMemberList();

    const memberList = currentTab === COMPANY_MEMBERS ? normalMemberList : internalMemberList;

    const [selectedRole, setSelectedRole] = useState({ value: 'all', label: t('all_company_roles') });
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(memberActions.fetchMembers({ companyId }));
    }, [companyId, dispatch]);

    const filteredMembers = useMemo(() => {
        let result = memberList;

        if (selectedRole?.value !== 'all') {
            result = memberList.filter(member => member?.role?.name === selectedRole?.name);
        }

        if (selectedStatus !== 'all') {
            result = result.filter(member => member?.state?.toLowerCase() === selectedStatus);
        }

        if (searchValue.trim() !== '') {
            result = result.filter(
                member =>
                    member?.fullName?.toLowerCase()?.includes(searchValue?.trim()?.toLowerCase()) ||
                    member?.email?.toLowerCase()?.includes(searchValue?.trim()?.toLowerCase())
            );
        }

        return result;
    }, [selectedRole, selectedStatus, searchValue, memberList]);

    const countStatus = useMemo(() => {
        return STATUS_OPTIONS?.map(status => {
            const count = memberList?.filter((member, idx) => member?.state?.toLowerCase() === status?.value);
            return {
                ...status,
                label: (
                    <Grid container alignItems="center" wrap="nowrap" spacing={1}>
                        <Grid item>{status?.label} </Grid>
                        {status?.label !== t('all_status') && (
                            <Grid item style={{ color: theme.colors.secondaryText, overflow: 'unset' }}>
                                ({count?.length}){' '}
                            </Grid>
                        )}
                    </Grid>
                )
            };
        });
    }, [memberList, theme.colors.secondaryText, t]);

    const roleOptions = React.useMemo(() => {
        const roleList =
            roles === null
                ? [{ label: t('all_company_roles'), value: 'all' }]
                : [{ name: t('all_company_roles'), value: 'all' }, ...roles];
        return roleList?.map(role => {
            const count = memberList?.filter((member, idx) => member?.role?.name === role?.name);
            return {
                value: role?.name,
                label: (
                    <Grid container alignItems="center" wrap="nowrap" spacing={1}>
                        <Grid item>{role?.name} </Grid>
                        {role?.name !== t('all_company_roles') && (
                            <Grid item style={{ color: theme.colors.secondaryText, overflow: 'unset' }}>
                                ({count?.length}){' '}
                            </Grid>
                        )}
                    </Grid>
                ),
                ...role
            };
        });
    }, [roles, memberList, theme.colors.secondaryText, t]);

    const handleRoleFilterChange = role => {
        setSelectedRole(role);
    };

    const handleStatusFilterChange = option => {
        setSelectedStatus(option.value);
    };

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleUpgrade = React.useCallback(() => {
        history.push(`/company-settings/billing/overview`);
    }, [history]);

    const handleExportUserList = async () => {
        await exportToSheetFileApi(companyId);
    };

    return (
        <SettingContentLayout
            name={t('global_members')}
            contentClassName={'content-medium'}
            AddComponent={
                <Grid container alignItems="center" spacing={3}>
                    {!isExternal && userInfo?.ldManager && (
                        <>
                            <Grid item>
                                <Tabs
                                    value={currentTab}
                                    onChange={handleChange}
                                    textColor="primary"
                                    classes={{
                                        indicator: classes.indicator
                                    }}
                                >
                                    {tabs.map(tab => (
                                        <Tab value={tab} label={tab} key={tab} />
                                    ))}
                                </Tabs>
                            </Grid>
                            <Grid item>
                                <InviteMembers
                                    width={220}
                                    text={t('edit_member_modal_invite_member')}
                                    isLdManager={userInfo?.ldManager}
                                    showTextOnly
                                    disabled={currentTab !== INTERNAL_MEMBERS}
                                />
                            </Grid>
                        </>
                    )}
                    {(isCurrentOwner || isCurrentAdmin) && (
                        <Grid item>
                            <ButtonBase variant="contained" onClick={handleExportUserList}>
                                Export User List
                            </ButtonBase>
                        </Grid>
                    )}
                </Grid>
            }
        >
            <div className={classes.root}>
                {isFreePlan && isMemberSeatExceeded && (
                    <Grid item style={{ marginBottom: 14 }}>
                        <WarningBox
                            warningType={PAYMENT_WARNING_TYPES.ERROR}
                            message="We're sorry, but your company exceeding 3 member seats. Please click the three dots icon to the right of the member you'd like to deactivate and keep only 3 members"
                            action={
                                <ButtonBase variant="contained" width={120} onClick={handleUpgrade}>
                                    <UpgradeSVG className={classes.upgradeIcon} />
                                    Upgrade
                                </ButtonBase>
                            }
                        />
                    </Grid>
                )}
                <Grid container justify="space-between" className={classes.filters}>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <LDSelectBase
                                    ddPlaceholder={t('select_a_role')}
                                    menuPlaceholder={'Find a role'}
                                    options={roleOptions}
                                    onChange={handleRoleFilterChange}
                                    defaultValue={selectedRole}
                                    isMulti={false}
                                    dropdownClassName={classes.dropdownClassName}
                                />
                            </Grid>
                            <Grid item>
                                <Select
                                    options={countStatus}
                                    value={selectedStatus}
                                    className={classes.dropdownClassName}
                                    border
                                    onChange={handleStatusFilterChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.search}>
                        <Search
                            onChange={e => setSearchValue(e.target.value)}
                            background={theme.colors.paleGrey}
                            placeholder={t('global_find_a_member')}
                            width={220}
                        />
                    </Grid>
                </Grid>
                {isFetching && !memberList ? (
                    <MemberTableSkeleton />
                ) : (
                    <MemberTable
                        members={filteredMembers}
                        companyId={companyId}
                        currentTab={currentTab}
                        internalMembers={internalMemberList}
                    />
                )}
            </div>
        </SettingContentLayout>
    );
}

export default React.memo(Members);
