import React from 'react';
import { ROW_HEIGHT } from 'const/gridUI';
import DOMPurify from 'dompurify';
import { isValidRegex } from 'utils/regex';

function FormulaTextRender({
    value = [],
    rowHeight = ROW_HEIGHT,
    rowIndex,
    columnIndex,
    filterValue,
    isCaseSensitive
}) {
    const formulaValue = Array.isArray(value) ? value?.join(',') : null;
    if (!formulaValue) return null;

    let re = null;

    if (isValidRegex(filterValue)) {
        re = new RegExp(filterValue, !!isCaseSensitive ? 'g' : 'gi');
    } else {
        re = filterValue;
    }

    let html = filterValue
        ? formulaValue && formulaValue.replace(re, match => `<span class="highlight">${match}</span>`)
        : formulaValue;
    return (
        <p
            className={`body2 cell_${rowIndex}_${columnIndex} pointer-events-none w-full leading-6 overflow-hidden text-ellipsis whitespace-pre-wrap table-cell align-top text-center break-words`}
            style={{
                height: rowHeight
            }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, { USE_PROFILES: { html: true } }) }}
        />
    );
}

export default React.memo(FormulaTextRender);
