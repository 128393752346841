import React from 'react';
import { ROW_HEIGHT, HIGHLIGHT_TYPES } from 'const/gridUI';
import Highlighter from 'components/highlighter';
import isNaN from 'lodash/isNaN';
import isArray from 'lodash/isArray';
import { getHighlightWords, getNumberDisplay } from 'utils/gridUI/cell';

function NumberRender({
    value,
    rowHeight = ROW_HEIGHT,
    rowIndex,
    columnIndex,
    filterValue,
    columnWidth,
    numberFormat,
    highlightPositions = [],
    isCaseSensitive
}) {
    let convertedData = '';

    if (typeof value === 'boolean') {
        convertedData = value ? 1 : 0;
    } else if (isNaN(Number(value) || isArray(value))) {
        return null;
    } else {
        if (!value && value + '' !== '0') return null;
        convertedData = value;
    }

    const display = getNumberDisplay({
        numberFormat,
        data: convertedData
    });

    const highlightWords = getHighlightWords(display, highlightPositions);

    return (
        <p
            className={`body2 cell_${rowIndex}_${columnIndex} pointer-events-none w-full leading-6 text-right overflow-hidden text-ellipsis whitespace-pre-wrap align-top break-words`}
            style={{
                height: rowHeight
            }}
        >
            <Highlighter
                highlightClassName="highlight"
                searchWords={[
                    { key: filterValue?.toString(), type: HIGHLIGHT_TYPES.DEFAULT },
                    ...highlightWords?.map(value => ({
                        key: value?.toString(),
                        type: HIGHLIGHT_TYPES.DEFAULT
                    }))
                ]}
                autoEscape={true}
                textToHighlight={display + '' || ''}
                caseSensitive={!!isCaseSensitive}
            />
        </p>
    );
}

export default React.memo(NumberRender);
