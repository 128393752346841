import * as types from '../types';

export function openViewCellTicket() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { isOpenViewCellTicket } = gridUI;

        if (isOpenViewCellTicket) {
            return false;
        }
        dispatch(_openViewCellTicket());
    };
}

export function toggleCellIssue() {
    return {
        type: types.TOGGLE_CELL_ISSUE
    };
}

export function closeCellIssue() {
    return {
        type: types.CLOSE_CELL_ISSUE
    };
}

function _openViewCellTicket() {
    return {
        type: types.OPEN_VIEW_CELL_TICKET
    };
}

export function toggleViewCellTicket() {
    return {
        type: types.TOGGLE_VIEW_CELL_TICKET
    };
}

export function closeViewCellTicket() {
    return {
        type: types.CLOSE_VIEW_CELL_TICKET
    };
}
