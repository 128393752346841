import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import i18n from 'i18n';
import Search from 'components/search';
import { AutoSizer, List } from 'react-virtualized-dn';
import NoDiffCheck from 'assets/images/svg/versionControl/NoDiffCheck';
import chunk from 'lodash/chunk';
import ListSelect from 'components/list/Select';
import Tooltip from 'components/tooltip/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { useDispatch } from 'react-redux';
import ButtonBase from 'components/button/Base';
import { enqueueSnackbar } from 'notifier/actions';
import { createTermsBase } from 'services/tb';
import { fetchListTermsBase, updateTB } from 'gridUI/actions';
import isEmpty from 'lodash/isEmpty';
import ProjectAndDatabaseAccessList from 'permission/groups/createGroup/ProjectAndDatabaseAccessList';
import { getProjectsAndDatabasesApi } from 'services/workspaces';
import { allDatabaseOption, defaultProjectAccess, getProjectAccessedFromServer, getProjectsServer } from './utils';
import { useMemberList } from 'hooks/permission';
import { getAvatarUrl } from 'utils/images';
import Avatar from 'components/avatar/User';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useCurrentUserUId } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        width: 740
    },
    inputText: {
        width: 430
    },
    search: {
        width: 220
    },
    targetList: {
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        height: 150,
        overflowY: 'auto',
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        marginBottom: 27
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    rowItem: {
        minWidth: 140
    },
    virtualizeList: {
        outline: 'none'
    },
    optional: {
        marginLeft: 4,
        color: theme.colors.steel
    },
    addIcon: {
        cursor: 'pointer',
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    projectContainer: {},
    projectContainerHeader: {
        padding: '13px 17px',
        background: '#F4F5F7'
    },
    openAssignProject: {
        cursor: 'pointer'
    },
    addProjectText: {
        color: theme.palette.primary.main
    },
    projectContainerItem: {
        padding: '13px 17px',
        borderTop: `1px solid ${theme.colors.border}`
    },
    workspaceIcon: {
        marginRight: 8,
        '& svg': {
            verticalAlign: 'text-top'
        }
    },
    zIndex: {
        zIndex: theme.zIndex.modal + 200,
        width: 200
    },
    listItem: {
        maxHeight: 188,
        overflow: 'auto'
    },
    btnContainer: {
        marginTop: 24
    },
    projectDtbList: {
        '& .closeButton': {
            right: `-24px !important`
        }
    },
    panelSummaryClassName: {
        display: 'none !important'
    }
}));

const MuiAccordion = withStyles({
    root: {
        flex: 1,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(Accordion);

const MuiAccordionSummary = withStyles({
    root: {
        flexDirection: 'row-reverse',
        minHeight: 'unset',
        padding: 0,
        '&$expanded': {
            minHeight: 'unset'
        },
        '& .MuiAccordionSummary-expandIcon': {
            padding: 0,
            marginRight: 13
        }
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0
        }
    },
    expanded: {}
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
    root: {
        padding: 0
    }
})(AccordionDetails);

const ITEMS_PER_ROW = 4;

const Schema = object({
    name: string().required(i18n.t(`global_this_field_is_required`))
});

const TBAdd = ({ open, onClose, initData, setIsFetching, langOptions }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(open);
    const [filterValue, setFilterValue] = useState('');
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});
    const [submitting, setSubmitting] = useState(false);
    const [projectAccesses, setProjectAccesses] = useState([defaultProjectAccess]);
    const [projects, setProjects] = useState([]);
    const initRef = useRef(false);
    const dispatch = useDispatch();
    const normalMemberList = useMemberList();
    const currentUserUId = useCurrentUserUId();
    const [owner, setOwner] = useState(initData?.owner || currentUserUId);
    const hasTargetOption = Object.values(selectedTargetOptions).some(Boolean);

    const membersOption = useMemo(() => {
        return (normalMemberList || []).map(m => ({
            value: m.userId,
            label: m.fullName,
            icon: () => (
                <Avatar src={getAvatarUrl(m.id)} size={20} color="#DBDCE1">
                    <AvatarIconSVG />
                </Avatar>
            )
        }));
    }, [normalMemberList]);

    const defaultMember = useMemo(() => {
        return membersOption.find(m => m.value === owner) || undefined;
    }, [owner, membersOption]);

    const optionsObj = useMemo(() => {
        return projects.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});
    }, [projects]);

    useEffect(() => {
        const getProjectAndDatabases = async () => {
            try {
                const projects = await getProjectsAndDatabasesApi();
                setProjects(projects);
            } catch (error) {
                console.log('getProjectAndDatabases failed');
            }
        };
        getProjectAndDatabases();
    }, []);

    const isEdit = useMemo(() => {
        return !isEmpty(initData);
    }, [initData]);

    const _handleClose = useCallback(() => {
        setDialogOpen(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }, [onClose]);

    const langOptionsFilter = useMemo(() => {
        return langOptions.filter(option => {
            return option?.label?.toLowerCase()?.includes(filterValue?.toLowerCase());
        });
    }, [langOptions, filterValue]);

    const _noRowsRenderer = React.useCallback(() => {
        return (
            <Grid
                container
                style={{ width: '100%', height: '100%' }}
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item>
                    <NoDiffCheck />
                </Grid>
                <Grid item>
                    <Typography variant="caption">{t('global_empty_language_msg')}</Typography>
                </Grid>
            </Grid>
        );
    }, [t]);

    const targetOptionChunks = React.useMemo(() => {
        return chunk(langOptionsFilter, ITEMS_PER_ROW);
    }, [langOptionsFilter]);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions[targetValue] || false;

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions]
    );

    const _rowRenderer = React.useCallback(
        ({ index, isScrolling, key, style }) => {
            const items = targetOptionChunks?.[index];
            return (
                <Grid style={{ ...style }} key={key} container alignItems="center" direction="row" wrap="nowrap">
                    {items?.map(option => {
                        const isSelected = selectedTargetOptions?.[option?.value];

                        if (option?.isDisabled) {
                            return (
                                <Grid key={option?.value} item xs={3}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                        return (
                            <Tooltip title={option.tooltip} key={option.value}>
                                <Grid item xs={3}>
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item className={classes.rowItem}>
                                            <ListSelect
                                                onClick={() => handleTargetSelect(option)}
                                                disabled={option?.isDisabled}
                                                disabledTitle={option?.disabledTitle}
                                                isSelected={isSelected}
                                                icon={option?.icon}
                                                name={option.value}
                                                moreIcon={option?.moreIcon}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        );
                    })}
                </Grid>
            );
        },
        [handleTargetSelect, targetOptionChunks, selectedTargetOptions, classes]
    );

    const validSelectedTargetOptions = React.useMemo(() => {
        const validSelected = [];
        Object.keys(selectedTargetOptions).forEach(key => {
            if (selectedTargetOptions[key]) {
                const validOption = langOptions.find(option => option.value === key);
                if (validOption) {
                    validSelected.push(validOption);
                }
            }
        });
        return validSelected;
    }, [selectedTargetOptions, langOptions]);

    const handleProjectChange = (index, newProjectOption) => {
        const newProjectAccess = projectAccesses.map((access, id) => {
            if (id === index)
                return { ...access, project: newProjectOption, database: allDatabaseOption, isFake: false };
            return access;
        });
        setProjectAccesses(newProjectAccess);
    };

    const handleDatabaseChange = (index, newDatabaseOption) => {
        const newProjectAccess = projectAccesses.map((access, id) => {
            if (id === index) return { ...access, database: newDatabaseOption, isFake: false };
            return access;
        });
        setProjectAccesses(newProjectAccess);
    };

    const handleAccessAdd = () => {
        setProjectAccesses([...projectAccesses, defaultProjectAccess]);
    };

    const handleAccessRemove = index => {
        const newProjectAccess = projectAccesses.filter((_, id) => id !== index);
        setProjectAccesses(newProjectAccess);
    };

    const _fetchListTB = useCallback(() => {
        setIsFetching(true);
        dispatch(
            fetchListTermsBase({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch, setIsFetching]);

    const formik = useFormik({
        initialValues: {
            ...(initData
                ? {
                      id: initData.id,
                      name: initData.name,
                      owner: initData.owner || 0,
                      description: initData.description
                  }
                : {
                      owner: currentUserUId || 0
                  })
        },

        validationSchema: Schema,
        onSubmit: async values => {
            setSubmitting(true);
            const body = {
                ...values,
                langs: validSelectedTargetOptions.map(option => option?.value),
                projects: getProjectsServer(projectAccesses)
            };
            try {
                if (!isEdit) {
                    await createTermsBase(body);
                    _fetchListTB();
                    _handleClose();
                } else {
                    dispatch(
                        updateTB({
                            oldTB: initData,
                            newTB: { ...initData, ...body },
                            successCallback: () => {
                                _fetchListTB();
                                _handleClose();
                            },
                            errorCallback: () => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 800);
                            }
                        })
                    );
                }
            } catch (error) {
                if (!isEdit) {
                    enqueueSnackbar({
                        message: error?.message,
                        type: 'info'
                    });
                }
            } finally {
                if (!isEdit) {
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 800);
                }
            }
        }
    });
    const { errors, values, handleChange, handleSubmit, setValues } = formik;

    const handleMemberChange = useCallback(
        data => {
            setOwner(data.value);
            setValues({
                ...values,
                owner: data.value
            });
        },
        [setValues, values]
    );

    useEffect(() => {
        if (isEdit) {
            if (initRef.current) return;
            initRef.current = true;
            setValues({
                id: initData.id,
                name: initData.name,
                description: initData.description,
                owner: initData.owner
            });
            if (initData.langs.length) {
                setSelectedTargetOptions(
                    initData.langs.reduce((acc, cur) => {
                        acc[cur] = true;
                        return acc;
                    }, {})
                );
            }
        }
    }, [initData, setValues, isEdit]);

    useEffect(() => {
        if (!isEmpty(initData?.projects) && !isEmpty(optionsObj)) {
            setProjectAccesses(
                getProjectAccessedFromServer({
                    serverProjects: initData.projects,
                    projectOptions: optionsObj
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsObj]);

    return (
        <Dialog maxWidth={false} open={dialogOpen} onClose={_handleClose}>
            <DialogTitle
                title={!isEdit ? 'Add new Glossary' : 'Edit Glossary'}
                onClose={_handleClose}
                className={classes.title}
            />
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">Glossary name</Typography>
                            </Grid>
                            <Grid item>
                                <InputText
                                    inputClassName={classes.inputText}
                                    placeholder="Input Glossary name"
                                    name="name"
                                    value={values.name || ''}
                                    error={errors.name}
                                    errorText={errors.name}
                                    disabled={submitting}
                                    onChange={handleChange}
                                    autoFocus={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">{t('owner')}</Typography>
                            </Grid>
                            <Grid item>
                                <LDBasePortal
                                    dropdownClassName={classes.inputText}
                                    ddPlaceholder={'Select an owner'}
                                    menuPlaceholder={'Find an owner'}
                                    options={membersOption}
                                    onChange={handleMemberChange}
                                    defaultValue={defaultMember}
                                    isDisabled={submitting}
                                    isUsingContainer
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="body1">Note</Typography>
                            </Grid>
                            <Grid item>
                                <InputText
                                    inputClassName={classes.inputText}
                                    placeholder="A short note to let people know the purpose of this Glossary"
                                    name="description"
                                    value={values.description || ''}
                                    error={errors.description}
                                    errorText={errors.description}
                                    disabled={submitting}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className={classes.row} spacing={2}>
                            <Grid item container alignItems="center" direction="row" justify="space-between">
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body1">Languages supported</Typography>
                                        </Grid>
                                        <Grid item>|</Grid>
                                        <Grid item>
                                            <Typography variant="caption">
                                                {t('localization_selected_langs', {
                                                    selectedLangs: validSelectedTargetOptions.length,
                                                    total: langOptions.length
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Search
                                        className={classes.search}
                                        onChange={e => setFilterValue(e.target.value)}
                                        background={theme.colors.paleGrey}
                                        placeholder={t('localization_target_search')}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid item className={classes.targetList}>
                                    <AutoSizer>
                                        {({ width, height }) => {
                                            return (
                                                <List
                                                    className={classes.virtualizeList}
                                                    rowCount={Math.ceil(langOptionsFilter?.length / ITEMS_PER_ROW)}
                                                    rowHeight={42}
                                                    width={width}
                                                    height={height}
                                                    noRowsRenderer={_noRowsRenderer}
                                                    rowRenderer={_rowRenderer}
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <MuiAccordion defaultExpanded>
                                    <MuiAccordionSummary expandIcon={<ArrowDownSVG sx={{ fontSize: '0.9rem' }} />}>
                                        <Typography variant="body1">Quick assign project</Typography>
                                        <Typography variant="body2" className={classes.optional}>
                                            (Optional)
                                        </Typography>
                                    </MuiAccordionSummary>
                                    <MuiAccordionDetails>
                                        <Grid container direction="column" className={classes.projectContainer}>
                                            <Grid item>
                                                <ProjectAndDatabaseAccessList
                                                    noNeedFetchProjects
                                                    defaultExpanded
                                                    hideLoading
                                                    className={classes.projectDtbList}
                                                    panelSummaryClassName={classes.panelSummaryClassName}
                                                    projectAccesses={projectAccesses}
                                                    externalProjects={projects}
                                                    onProjectChange={handleProjectChange}
                                                    onDatabaseChange={handleDatabaseChange}
                                                    onAccessAdd={handleAccessAdd}
                                                    onAccessRemove={handleAccessRemove}
                                                />
                                            </Grid>
                                        </Grid>
                                    </MuiAccordionDetails>
                                </MuiAccordion>
                            </Grid>
                            <Grid
                                className={classes.btnContainer}
                                container
                                alignItems="center"
                                justify="flex-end"
                                spacing={3}
                            >
                                <Grid item>
                                    <ButtonBase width={120} variant="outlined" onClick={_handleClose}>
                                        {t('global_cancel')}
                                    </ButtonBase>
                                </Grid>
                                <Grid item>
                                    <ButtonBase
                                        width={120}
                                        variant="contained"
                                        type="submit"
                                        disabled={submitting || !values.name || !hasTargetOption}
                                    >
                                        {t(!isEdit ? 'global_create' : 'global_save')}{' '}
                                        {submitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default React.memo(TBAdd);
