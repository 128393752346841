import React from 'react';

function CommentResolvedSVG({ color = '#00BC7E', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...other}>
            <g fill="none" fillRule="nonzero">
                <rect width="18" height="18" fill={color} rx="9" />
                <path
                    fill="#FFF"
                    d="M8.821 11.726a.925.925 0 0 1-1.313 0L5.472 9.68a.938.938 0 0 1 0-1.322.925.925 0 0 1 1.314 0l1.213 1.22c.091.092.24.092.331 0l3.284-3.303a.925.925 0 0 1 1.314 0 .937.937 0 0 1 0 1.321l-4.107 4.131z"
                />
            </g>
        </svg>
    );
}

export default React.memo(CommentResolvedSVG);
