import React from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import { getRoleColor, getStatusColor } from 'utils/color';

const StatusIcon = React.memo(({ status = '' }) => {
    const theme = useTheme();
    return (
        <div
            style={{
                backgroundColor: getStatusColor({ status: status?.toLowerCase(), theme }),
                display: 'inline-block',
                width: 7,
                height: 7,
                borderRadius: '50%'
            }}
        />
    );
});

const RoleIcon = React.memo(({ role }) => {
    const roleName = role?.name?.toLowerCase();
    const theme = useTheme();

    const roleColor = getRoleColor({ theme, roleName });
    if (!roleColor) return null;

    return (
        <Typography
            variant="caption"
            style={{
                background: hexToRgba(roleColor, 0.8),
                border: roleColor,
                padding: `0 5px`,
                height: 16,
                lineHeight: `16px`,
                borderRadius: 4,
                color: theme.colors.white,
                fontSize: 10,
                marginLeft: SPACING_LIST_NAME_WITH_ICON
            }}
        >
            {roleName}
        </Typography>
    );
});

export { StatusIcon, RoleIcon };
