import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getOptionColor } from 'utils/color';
import Option from 'components/option/Base';
import isArray from 'lodash/isArray';
import { ROW_HEIGHT } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflow: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative'
    }
}));

function MultipleSelect({ value: cellValue, customProperties, options = [], isReadOnly }) {
    const classes = useStyles({ isReadOnly });

    if (!cellValue || cellValue?.length === 0 || !isArray(cellValue)) return <div className={classes.root} />;

    const isRefData = cellValue?.some(
        option => option.hasOwnProperty('referencedDataItem') && option.hasOwnProperty('referencedRecordId')
    );

    if (isRefData) return <div className={classes.root} />;

    return (
        <Grid container spacing={1} className={classes.root} wrap="nowrap" justify="flex-start" alignItems="center">
            {cellValue?.map((item, index) => {
                const color = getOptionColor({ options, data: item, customProperties });
                return (
                    <Grid item key={index}>
                        <Option className={classes.option} size="small" label={item} color={color} />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(MultipleSelect);
