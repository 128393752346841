import React from 'react';

function SingleRowSVG({ color = '#7869B9', ...other }) {
    return (
        <svg {...other} width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.54167 34H24.7917C26.7438 34 28.3333 32.4105 28.3333 30.4583V3.54167C28.3333 1.5895 26.7438 0 24.7917 0H3.54167C1.5895 0 0 1.5895 0 3.54167V30.4583C0 32.4105 1.5895 34 3.54167 34ZM1.41667 3.54167C1.41667 2.37008 2.37008 1.41667 3.54167 1.41667H24.7917C25.9633 1.41667 26.9167 2.37008 26.9167 3.54167V30.4583C26.9167 31.6299 25.9633 32.5833 24.7917 32.5833H3.54167C2.37008 32.5833 1.41667 31.6299 1.41667 30.4583V3.54167ZM10.375 19.667H23.125C23.516 19.667 23.8333 19.3497 23.8333 18.9587V18.9583V14.7083C23.8333 14.3173 23.516 14 23.125 14H10.375H4.70833C4.31733 14 4 14.3173 4 14.7083V18.9583V18.9587C4 19.3497 4.31733 19.667 4.70833 19.667H10.375ZM22.4167 15.4167V18.25H11.0834V15.4167H22.4167ZM9.66669 15.4167V18.25H5.41667V15.4167H9.66669Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SingleRowSVG);
