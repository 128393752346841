import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/button/Base';
import InputText from 'components/inputs/InputText';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import AuthenticationWrapper from './AuthenticationWrapper';
import AuthFormWrapper from './common/AuthFormWrapper';
import { object, string } from 'yup';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import ArrowBackSVG from 'assets/images/svg/ArrowBackSVG';
import { getSamlCompaniesApi } from 'services/company';
import { enqueueSnackbar } from 'notifier/actions';
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 550
    }
}));

const FormSchema = object().shape({
    email: string()
        .email('Invalid Email')
        .required('This field is required')
});

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ForgotPassword() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();

    const dispatch = useDispatch();

    const fetchSamlCompanies = async email => {
        try {
            const samlCompanies = await getSamlCompaniesApi(email);
            console.log('samlCompanies', samlCompanies);
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'error'
                })
            );
        }
    };

    React.useEffect(() => {
        const email = query.get('email');
        if (email) {
            history.push('/saml-companies', { email });
        }
    }, [history, query]);

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: FormSchema,
        onSubmit: async ({ email }, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            fetchSamlCompanies(email);
            try {
                const samlCompanies = await getSamlCompaniesApi(email);
                console.log('samlCompanies', samlCompanies);
                setSubmitting(false);
                if (samlCompanies.length > 0) {
                    history.push('/saml-companies', { email });
                } else {
                    setStatus({ error: 'SSO may not be available or configured for this account.' });
                }
            } catch (error) {
                setSubmitting(false);
                setStatus({ error: error.message });
                dispatch(
                    enqueueSnackbar({
                        message: error.message,
                        type: 'error'
                    })
                );
            }
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid, status } = formik;

    return (
        <AuthenticationWrapper isSigningIn>
            <AuthFormWrapper title="Sign in with SSO/SAML" className={classes.root}>
                {status?.error && <ErrorMessage message={status.error} />}
                <form onSubmit={handleSubmit} className={classes.form}>
                    <Grid item>
                        <InputText
                            name="email"
                            type="email"
                            value={values.email}
                            height={40}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.email && touched.email}
                            errorText={errors.email}
                            placeholder={t(`global_email_placeholder_example`)}
                            autoFocus
                            autoComplete="on"
                        />
                    </Grid>
                    <Grid item container spacing={2} justify="flex-end" style={{ marginTop: 24 }}>
                        <Grid item>
                            <Link to="/signin" className="btn btn-outlined w-[100px]">
                                <ArrowBackSVG style={{ marginRight: 8 }} />
                                {t(`global_back`)}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Button width={100} type="submit" variant="contained" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <>
                                        {t(`global_next`)}
                                        <ArrowNextSVG style={{ marginLeft: 8 }} />
                                    </>
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </AuthFormWrapper>
        </AuthenticationWrapper>
    );
}

export default ForgotPassword;
