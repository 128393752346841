import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import ListItem from 'components/list/Item';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    pb2: {
        paddingBottom: theme.spacing(2)
    }
}));

function GridTemplatePopup({ id, onClickAway, onEdit, onDelete }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        onClickAway(e);
    });

    return (
        <Grid container direction="column" ref={rootRef} className={classes.root}>
            <Grid item>
                <ListItem onClick={onEdit} icon={<EditSVG />} name={t('edit_template')} />
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item>
                <ListItem onClick={onDelete} icon={<DeleteSVG />} name={t(`global_delete`)} />
            </Grid>
        </Grid>
    );
}

export default React.memo(GridTemplatePopup);
