import * as types from './types';
import createReducer from 'utils/createReducer';
import produce from 'immer';

const INITIAL_STATE = {
    // TM data of current company
    isFetching: false,
    isActionInProgress: false,
    list: null,
    isShowLastPopupCreate: null,
    isCreatingTM: false,
    tmSources: {
        tmId: {
            isFetching: false,
            sources: []
        }
    },
    listSegments: {
        totalSegment: 0,
        list: []
    },
    sourceSelected: null,
    targetsSelected: null
};

const commonHandler = {
    [types.FETCH_COMPANY_TMS](state) {
        return {
            ...state,
            isFetching: true
        };
    },
    [types.FETCH_COMPANY_TMS_SUCCESS](state, { payload }) {
        return {
            ...state,
            isFetching: false,
            list: payload
        };
    },
    [types.CREATE_TM](state) {
        return {
            ...state,
            isCreatingTM: true
        };
    },
    [types.CREATE_TM_SUCCESS](state, action) {
        const { tm } = action.payload;
        return {
            ...state,
            list: [...state.list, tm],
            isCreatingTM: false
        };
    },
    [types.CREATE_TM_FAILED](state) {
        return {
            ...state,
            isCreatingTM: false
        };
    },
    [types.DELETE_TM](state, action) {
        const { tmId } = action.payload;
        const { list } = state;
        const newListTM = list.map(tm => {
            if (tm.id === tmId) {
                tm.isDeleted = true;
            }
            return tm;
        });
        return {
            ...state,
            list: newListTM
        };
    },

    [types.DELETE_TM_FAILED](state, action) {
        const { tmId } = action.payload;
        const { list } = state;
        const newListTM = list.map(tm => {
            if (tm.id === tmId) {
                tm.isDeleted = false;
            }
            return tm;
        });
        return {
            ...state,
            list: newListTM
        };
    },
    [types.UPDATE_TM](state, action) {
        const { tm } = action.payload;
        const { list } = state;
        const newListTM = list.map(tmStore => {
            if (tmStore?.id === tm?.id) {
                return tm;
            }
            return tmStore;
        });
        return {
            ...state,
            list: newListTM
        };
    },
    [types.UPDATE_TM_FAILED](state, action) {
        const { tm } = action.payload;
        const { list } = state;
        const newListTM = list.map(i => {
            if (i.id === tm.id) {
                i.name = tm.name;
            }
            return i;
        });
        return {
            ...state,
            list: newListTM
        };
    },
    [types.DELETE_TM_SUCCESS](state, action) {
        const { tmId } = action.payload;
        const { list } = state;
        const newListTM = list.filter(tm => tm.id !== tmId);
        return {
            ...state,
            list: newListTM
        };
    },
    [types.EXECUTE_COMPANY_TM_ACTION](state) {
        return {
            ...state,
            isActionInProgress: true
        };
    },
    [types.EXECUTE_COMPANY_TM_ACTION_SUCCESS](state) {
        return {
            ...state,
            isActionInProgress: false
        };
    },
    [types.EXECUTE_COMPANY_TM_ACTION_FAILED](state) {
        return {
            ...state,
            isActionInProgress: false
        };
    },
    [types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_TM](state) {
        return {
            ...state,
            isShowLastPopupCreate: null
        };
    },
    [types.TURN_ON_SHOW_LAST_POP_UP_CREATE_TM](state, action) {
        const { tmId } = action.payload;
        return {
            ...state,
            isShowLastPopupCreate: tmId
        };
    },
    [types.FETCH_TM_SOURCES](state, { payload }) {
        const { tmId } = payload;
        const { tmSources } = state;

        const newTmSources = produce(tmSources, draft => {
            if (!draft?.[tmId]) {
                draft[tmId] = {};
            }
            draft[tmId].isFetching = true;
        });
        return {
            ...state,
            tmSources: newTmSources
        };
    },
    [types.FETCH_TM_SOURCES_FAILED](state, { payload }) {
        const { tmId, error } = payload;
        const { tmSources } = state;

        const newTmSources = produce(tmSources, draft => {
            if (!draft?.[tmId]) {
                draft[tmId] = {};
            }
            draft[tmId].isFetching = false;
            draft[tmId].error = error;
        });
        return {
            ...state,
            tmSources: newTmSources
        };
    },
    [types.FETCH_TM_SOURCES_SUCCESS](state, { payload }) {
        const { tmId, sources } = payload;
        const { tmSources } = state;

        const newTmSources = produce(tmSources, draft => {
            if (!draft?.[tmId]) {
                draft[tmId] = {};
            }
            draft[tmId].isFetching = false;
            draft[tmId].sources = sources;
        });
        return {
            ...state,
            tmSources: newTmSources
        };
    },
    [types.SET_SELECTED_SOURCE_TM](state, { payload }) {
        return {
            ...state,
            sourceSelected: payload?.sourceSelected
        };
    },
    [types.SET_SELECTED_TARGETS_TM](state, { payload }) {
        return {
            ...state,
            targetsSelected: payload?.targetsSelected
        };
    },
    [types.SET_LIST_SEGMENT](state, { payload }) {
        return {
            ...state,
            listSegments: {
                totalSegment: payload?.segments?.headers?.['x-total-count'],
                list: payload?.segments?.data
            }
        };
    },
    [types.UPDATE_NEW_ALTERNATIVE](state, { payload }) {
        // const { alternative } = payload;
        // const { listSegments } = state;
        return {
            ...state
        };
    }
};

export default createReducer(INITIAL_STATE, Object.assign(commonHandler));
