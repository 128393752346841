import React from 'react';

function AutoQASVG2({ ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_2919_63646)">
                <path
                    d="M32.0069 33.7834C32.2557 34.0314 32.5926 34.1706 32.9438 34.1706C33.2951 34.1706 33.632 34.0314 33.8809 33.7834C34.1288 33.5346 34.268 33.1977 34.268 32.8464C34.268 32.4952 34.1288 32.1582 33.8809 31.9094L30.5623 28.598L29.1888 30.9724L32.0069 33.7834Z"
                    fill="#00A657"
                />
                <path
                    d="M35.3823 15.5579L31.1942 8.30322L27.1551 12.3387C28.4091 14.1871 29.0441 16.3858 28.9689 18.6181C28.8936 20.8504 28.1119 23.0013 26.7363 24.7611L30.5695 28.5872L35.3823 20.25C35.7931 19.5363 36.0093 18.7274 36.0093 17.9039C36.0093 17.0805 35.7931 16.2715 35.3823 15.5579Z"
                    fill="#2F2F2F"
                />
                <path
                    d="M11.9394 9.86851C13.8807 8.37914 16.2782 7.60867 18.7238 7.68826C21.1693 7.76786 23.5116 8.69261 25.352 10.3051L29.7885 5.86851L28.7238 4.0371C28.3118 3.32281 27.719 2.7296 27.005 2.31715C26.2909 1.90469 25.4809 1.68753 24.6563 1.6875H11.3609C10.5357 1.68726 9.72511 1.90428 9.01049 2.31673C8.29586 2.72919 7.70242 3.32253 7.28987 4.0371L6.86041 4.78954L11.9394 9.86851Z"
                    fill="#2F2F2F"
                />
                <path
                    d="M18.3813 28.8712C16.3988 28.8717 14.456 28.3158 12.7738 27.2666C11.0917 26.2175 9.73774 24.7173 8.86611 22.9367C7.99447 21.156 7.64012 19.1665 7.84336 17.1945C8.0466 15.2224 8.79929 13.347 10.0158 11.7815L5.44078 7.22076L0.628007 15.5579C0.216571 16.272 0 17.0817 0 17.9058C0 18.7299 0.216571 19.5395 0.628007 20.2536L7.27929 31.7745C7.69185 32.489 8.28528 33.0824 8.99991 33.4948C9.71454 33.9073 10.5252 34.1243 11.3503 34.124H24.6529C25.4774 34.124 26.2875 33.9069 27.0015 33.4944C27.7155 33.0819 28.3083 32.4887 28.7203 31.7745L29.1852 30.9652L24.8587 26.6316C23.0106 28.0812 20.7301 28.8697 18.3813 28.8712Z"
                    fill="#2F2F2F"
                />
                <path
                    d="M18.8641 16.793L23.4781 12.179C22.1406 11.0582 20.4708 10.4098 18.7275 10.3341C16.9841 10.2584 15.2645 10.7596 13.8348 11.7602L18.8641 16.793Z"
                    fill="#00A657"
                />
                <path
                    d="M18.8853 20.6086L18.864 20.5873L18.8427 20.6086L11.9075 13.6733C11.0786 14.8378 10.5791 16.2044 10.4617 17.6289C10.3443 19.0535 10.6132 20.4833 11.2403 21.7678C11.8673 23.0524 12.8292 24.144 14.0246 24.9276C15.2201 25.7113 16.6047 26.1581 18.0327 26.2208C19.4607 26.2836 20.8792 25.96 22.1388 25.2842C23.3983 24.6084 24.4523 23.6054 25.1896 22.3808C25.9269 21.1562 26.3202 19.7555 26.3282 18.3261C26.3362 16.8967 25.9585 15.4917 25.2349 14.259L18.8853 20.6086Z"
                    fill="#00A657"
                />
            </g>
            <defs>
                <clipPath id="clip0_2919_63646">
                    <rect width="36" height="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(AutoQASVG2);
