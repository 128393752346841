import React, { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from 'components/tabs/Base';
import GridSearch from './Grid';
import RecordSearch from './Record';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { searchCellData, searchGrid } from './actions';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import ListItem from 'components/list/Item';
import SearchIcon from 'assets/images/svg/SearchSVG';
import OpenNewPageSVG from 'assets/images/svg/OpenNewPageSVG';
import { Typography, Grid } from '@material-ui/core';
import BetaTag from 'components/BetaTag';

const useStyles = makeStyles(theme => ({
    tableContent: {
        width: 480,
        height: 472,
        borderRadius: 4,
        paddingTop: 2,
        '& > div': {
            padding: 0
        }
    }
}));

function SearchBox({ value, setSearchValue }) {
    const classes = useStyles();
    const theme = useTheme();
    const { workspaceId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const timerRef = useRef();
    const lastValue = useRef();
    const [isFirstTime, setIsFirstTime] = useState(true);
    const history = useHistory();

    //Grid State
    const [isSearchingGrid, setIsSearchingGrid] = useState(false);
    const [isSearchingMoreGrid, setIsSearchingMoreGrid] = useState(false);
    const [limitGrid] = useState(10);
    const [pageGrid, setPageGrid] = useState(1);
    const [gridData, setGridData] = useState([]);
    const [totalGrid, setTotalGrid] = useState(0);

    //Record State
    const [isSearching, setIsSearching] = useState(false);
    const [isSearchingMore, setIsSearchingMore] = useState(false);
    const [limit] = useState(10);
    const [page, setPage] = useState(1);
    const [cellData, setCellData] = useState([]);
    const [total, setTotal] = useState(0);

    const fetchMoreGrids = useCallback(() => {
        if (isSearchingMoreGrid) return;
        if (value !== lastValue.current && lastValue.current) {
            setSearchValue(lastValue.current);
        }
        setIsSearchingMoreGrid(true);
        dispatch(
            searchGrid({
                projectId: workspaceId,
                queryParams: {
                    limit: limitGrid,
                    page: pageGrid + 1,
                    q: lastValue.current
                },
                successCallback: response => {
                    setPageGrid(pageGrid + 1);
                    setIsSearchingMoreGrid(false);
                    setTotalGrid(response?.headers?.['x-total-count'] || 0);
                    if (response?.data?.grids) {
                        setGridData(prev => [...prev, ...response?.data?.grids]);
                    }
                },
                errorCallback: () => {
                    setIsSearchingMoreGrid(false);
                }
            })
        );
    }, [dispatch, limitGrid, pageGrid, workspaceId, isSearchingMoreGrid, value, setSearchValue]);

    const fetchMoreRecords = useCallback(() => {
        if (isSearchingMore) return;
        if (value !== lastValue.current && lastValue.current) {
            setSearchValue(lastValue.current);
        }
        setIsSearchingMore(true);
        dispatch(
            searchCellData({
                projectId: workspaceId,
                queryParams: {
                    limit,
                    page: page + 1,
                    q: lastValue.current
                },
                successCallback: response => {
                    setPage(page + 1);
                    setIsSearchingMore(false);
                    setTotal(response?.headers?.['x-total-count'] || 0);
                    if (response?.data?.cells) {
                        setCellData(prev => [...prev, ...response?.data?.cells]);
                    }
                },
                errorCallback: () => {
                    setIsSearchingMore(false);
                }
            })
        );
    }, [dispatch, limit, page, workspaceId, isSearchingMore, value, setSearchValue]);

    const tabs = React.useMemo(() => {
        return [
            {
                label: `${t('global_grid')}${totalGrid > 0 ? ` (${totalGrid})` : ''}`,
                content: (
                    <GridSearch
                        isFirstTime={isFirstTime}
                        isSearching={isSearchingGrid}
                        isSearchingMore={isSearchingMoreGrid}
                        gridData={gridData}
                        total={totalGrid}
                        fetchMoreGrids={fetchMoreGrids}
                    />
                )
            },
            {
                label: `${t('cell_data')}${total > 0 ? ` (${total})` : ''}`,
                content: (
                    <RecordSearch
                        isFirstTime={isFirstTime}
                        isSearching={isSearching}
                        isSearchingMore={isSearchingMore}
                        cellData={cellData}
                        total={total}
                        fetchMoreRecords={fetchMoreRecords}
                    />
                )
            }
        ];
    }, [
        total,
        isFirstTime,
        isSearching,
        isSearchingMore,
        cellData,
        fetchMoreRecords,
        gridData,
        isSearchingGrid,
        totalGrid,
        isSearchingMoreGrid,
        fetchMoreGrids,
        t
    ]);

    const initFetchGrid = useCallback(() => {
        setPageGrid(1);
        setTotalGrid(0);
        setGridData([]);
        setIsSearchingGrid(true);
        dispatch(
            searchGrid({
                projectId: workspaceId,
                queryParams: {
                    limit: limitGrid,
                    page: 1,
                    q: value
                },
                successCallback: response => {
                    setIsSearchingGrid(false);
                    setTotalGrid(response?.headers?.['x-total-count'] || 0);
                    setGridData(response?.data?.grids || []);
                },
                errorCallback: () => {
                    setIsSearchingGrid(false);
                }
            })
        );
    }, [dispatch, limitGrid, value, workspaceId]);

    const initFetchRecord = useCallback(() => {
        setPage(1);
        setTotal(0);
        setCellData([]);
        setIsSearching(true);
        dispatch(
            searchCellData({
                projectId: workspaceId,
                queryParams: {
                    limit,
                    page: 1,
                    q: value
                },
                successCallback: response => {
                    setIsSearching(false);
                    setTotal(response?.headers?.['x-total-count'] || 0);
                    setCellData(response?.data?.cells || []);
                },
                errorCallback: () => {
                    setIsSearching(false);
                }
            })
        );
    }, [dispatch, limit, value, workspaceId]);

    useEffect(() => {
        if (value === lastValue.current) return;
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(async () => {
            if (value?.trim()) {
                lastValue.current = value?.trim();
                setIsFirstTime(false);
                initFetchGrid();
                initFetchRecord();
                sendManualTrack({
                    type: 'Search in Global Text',
                    customData: {
                        searchText: value
                    }
                });
            }
        }, DEBOUNCE_TIME_SEARCHING);
    }, [dispatch, value, initFetchGrid, initFetchRecord]);

    const openAdvancedSearch = useCallback(() => {
        history.push(`/p/${workspaceId}/s`);
    }, [workspaceId, history]);

    return (
        <Tabs
            tabs={tabs}
            extraContent={
                <ListItem
                    style={{
                        borderTop: `1px solid ${theme.colors.border}`
                    }}
                    onClick={openAdvancedSearch}
                    name={
                        <Grid container alignItems="center" direction="row" spacing={1}>
                            <Grid item>
                                <Typography variant="caption">Queries</Typography>
                            </Grid>
                            <Grid item>
                                <BetaTag />
                            </Grid>
                        </Grid>
                    }
                    icon={() => <SearchIcon />}
                    moreIcon={<OpenNewPageSVG color={theme.colors.steel} />}
                />
            }
            defaultValue={0}
            variant="fullWidth"
            tabContentClassName={classes.tableContent}
        />
    );
}

export default SearchBox;
