import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import {
    createCellCommentApi,
    fetchCellCommentListApi,
    updateCellCommentThreadStatusApi,
    deleteCellCommentApi,
    updateCellCommentApi
} from 'services/cellComment';
import { getExistViewRecordsApi } from 'services/view';
import { RESOLVED, REOPENED } from 'const/commentStatus';
import uuidv1 from 'uuid/v1';
import * as statusActions from './status';
import { getCurrentUtc } from 'utils/datetime';
import * as dataActions from './data';
import * as rowActions from './row';
import * as commentDashboardActions from 'gridUI/actions/commentDashboard';

export function updateCellComment({ commentId, threadId, oldContent, newContent, editedAt, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { gridId, dbId, currentView } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_updateCellCommentAction({ threadId, commentId, content: newContent, editedAt: getCurrentUtc() }));
        try {
            await updateCellCommentApi({
                currentViewId: currentView?.id,
                gridId,
                dbId,
                commentId,
                data: { content: newContent }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(_updateCellCommentAction({ threadId, commentId, content: oldContent, editedAt }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function updateCellThreadComment({ commentId, threadId, oldContent, newContent, editedAt, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { gridId, dbId, currentView } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_updateCellThreadCommentAction({ content: newContent, threadId, editedAt: getCurrentUtc() }));
        try {
            await updateCellCommentApi({
                currentViewId: currentView?.id,
                gridId,
                dbId,
                commentId,
                data: { content: newContent }
            });
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(_updateCellThreadCommentAction({ content: oldContent, threadId, editedAt }));
            dispatch(statusActions.removeDoingAction({ actionId }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _updateCellCommentAction({ threadId, commentId, content, editedAt }) {
    return {
        type: types.UPDATE_COMMENT,
        payload: {
            commentId,
            content,
            threadId,
            editedAt
        }
    };
}

export function _updateCellThreadCommentAction({ content, threadId, editedAt }) {
    return {
        type: types.UPDATE_THREAD_COMMENT,
        payload: {
            content,
            threadId,
            editedAt
        }
    };
}

export function deleteCellComment({ commentId, threadId, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { gridId, dbId, currentView } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_deleteCellCommentAction({ commentId, threadId }));
        try {
            await deleteCellCommentApi({ currentViewId: currentView?.id, gridId, dbId, commentId });
            dispatch(_deleteCellCommentActionSuccess({ commentId, threadId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_deleteCellCommentActionFailed({ commentId, threadId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _deleteCellCommentAction({ commentId, threadId }) {
    return {
        type: types.DELETE_CELL_COMMENT,
        payload: {
            commentId,
            threadId
        }
    };
}

function _deleteCellCommentActionSuccess({ commentId, threadId }) {
    return {
        type: types.DELETE_CELL_COMMENT_SUCCESS,
        payload: {
            commentId,
            threadId
        }
    };
}

function _deleteCellCommentActionFailed({ commentId, threadId }) {
    return {
        type: types.DELETE_CELL_COMMENT_FAILED,
        payload: {
            commentId,
            threadId
        }
    };
}

export function openCellComment() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { isOpenCellComment } = gridUI;

        if (isOpenCellComment) {
            return false;
        }
        dispatch(_openCellComment());
    };
}

function _openCellComment() {
    return {
        type: types.OPEN_CELL_COMMENT
    };
}

export function closeCellComment() {
    return {
        type: types.CLOSE_CELL_COMMENT
    };
}

export function fetchCellCommentList({ threadId, compositeViewId, columnId, rowId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const columnIdCombined = columnId;
        const rowIdCombined = rowId;

        dispatch(statusActions.registerDoingAction({ actionId }));
        try {
            const comment = await fetchCellCommentListApi({
                compositeViewId,
                columnId: columnIdCombined,
                rowId: rowIdCombined
            });
            dispatch(setComment({ comment, threadId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function createComment({ threadId, columnId, rowId, content, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { gridId, dbId, currentView } = gridUI;

        console.log('This is gridUI', { gridUI });

        dispatch(statusActions.registerDoingAction({ actionId }));

        const data = {
            columnId,
            rowId,
            content
        };

        if (threadId) {
            data.threadId = threadId;
        }

        try {
            const comment = await createCellCommentApi({ currentViewId: currentView?.id, dbId, gridId, data });
            const { thread, comments } = comment;

            if (!threadId) {
                const newRecordMetaData = {
                    [rowId]: {
                        _metadata: {
                            value: {
                                [columnId]: {
                                    comment: {
                                        id: thread.id,
                                        status: thread.status
                                    }
                                }
                            }
                        }
                    }
                };

                dispatch(rowActions.updateRecordMetaData({ newRecordMetaData }));
                dispatch(setComment({ comment, threadId: thread?.id }));
            } else {
                const newComment = comments && comments[0];
                dispatch(updateCommentList({ threadId, newComment }));
            }
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function updateCommentList({ threadId, newComment }) {
    return {
        type: types.UPDATE_COMMENT_LIST,
        payload: {
            threadId,
            newComment
        }
    };
}

function setComment({ comment, threadId }) {
    return {
        type: types.SET_COMMENT,
        payload: {
            threadId,
            comment
        }
    };
}

export function updateCellCommentThreadStatus({
    threadId,
    columnId,
    rowId,
    newStatus,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { gridId, dbId, cellInfo } = gridUI;
        const columnIdStore = cellInfo?.columnId;
        const rowIdStore = cellInfo?.rowId;
        const columnIdCombined = columnId || columnIdStore;
        const rowIdCombined = rowId || rowIdStore;
        const newStatusString = newStatus ? RESOLVED : REOPENED;

        dispatch(statusActions.registerDoingAction({ actionId }));

        const data = {
            threadId,
            status: newStatusString
        };
        try {
            const { thread, comments } = await updateCellCommentThreadStatusApi({ dbId, gridId, data });
            dispatch(updateCommentList({ threadId, newComment: comments && comments[0] }));
            dispatch(_updateThreadCommentStatus({ newStatus: thread.status, threadId }));
            dispatch(
                commentDashboardActions._updateDashboardCommentThreadStatus({ threadId, newStatus: newStatusString })
            );

            const newRecordMetaData = {
                [rowIdCombined]: {
                    _metadata: {
                        value: {
                            [columnIdCombined]: {
                                comment: {
                                    id: thread.id,
                                    status: thread.status
                                }
                            }
                        }
                    }
                }
            };

            dispatch(rowActions.updateRecordMetaData({ newRecordMetaData }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            errorCallback && errorCallback();
            dispatch(statusActions.removeDoingAction({ actionId }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _updateThreadCommentStatus({ newStatus, threadId }) {
    return {
        type: types.UPDATE_CELL_COMMENT_THREAD_STATUS,
        payload: {
            newStatus,
            threadId
        }
    };
}

export function checkCanOpenComment({ callback, columnId, rowId, compositeViewId, threadId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewColumns, dbId, defaultAccessViewId } = gridUI;

        const colIds = viewColumns?.filter(col => col?.viewable)?.map(col => col?.id);

        if (!colIds?.includes(columnId)) {
            dispatch(
                enqueueSnackbar({
                    message: 'Comment is not available in current view',
                    type: 'info'
                })
            );
            callback && callback({ isCanOpen: false });
            return;
        }

        try {
            const { data, recordIds } = await getExistViewRecordsApi({
                dbId,
                viewId: defaultAccessViewId,
                columnIds: colIds,
                recordIds: [rowId]
            });

            if (recordIds?.length === 0) {
                dispatch(
                    enqueueSnackbar({
                        message: 'Comment is not available in current view',
                        type: 'info'
                    })
                );
                callback && callback({ isCanOpen: false });
                return;
            }

            dispatch(dataActions.updateData({ newData: data }));

            dispatch(
                fetchCellCommentList({
                    compositeViewId,
                    threadId,
                    columnId,
                    rowId,
                    successCallback: () => {
                        return callback && callback({ isCanOpen: true });
                    },
                    errorCallback: () => {
                        return callback && callback({ isCanOpen: false });
                    }
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            callback && callback({ isCanOpen: false });
        }
    };
}
