import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from 'components/tooltip/Base';
import { Grid } from '@material-ui/core';
import CellDiffSVG from 'assets/images/svg/CellDiffSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    icon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        background: theme.colors.solitude,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}));

const ShowDiffChange = ({ showDiff, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Tooltip title={t('show_changes')}>
            <Grid
                item
                onClick={onClick}
                className={classes.icon}
                style={{ background: !showDiff ? theme.colors.white : `` }}
            >
                <CellDiffSVG width={16} height={16} color={showDiff ? theme.palette.primary?.main : undefined} />
            </Grid>
        </Tooltip>
    );
};

export default React.memo(ShowDiffChange);
