import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/core/styles';

function SkeletonBase({ ...props }) {
    const theme = useTheme();
    return (
        <Skeleton
            variant={props?.variant || 'rect'}
            style={{ background: theme.colors.skeletonColor }}
            animation={props?.animation || 'wave'}
            {...props}
        />
    );
}

export default React.memo(SkeletonBase);
