import mime from 'mime';

export function isFileJSON(fileMeta) {
    const contentType = fileMeta?.type || mime?.getType(fileMeta?.originalName) || mime?.getType(fileMeta?.name);
    const isJSon = contentType?.match('json.*')?.length > 0;
    return isJSon;
}

export function isFilePO(fileMeta) {
    const contentType =
        fileMeta?.type || mime?.getType(fileMeta?.originalName) || mime?.getType(fileMeta?.name) || fileMeta?.name;

    const isJSon = contentType?.match('po.*')?.length > 0;
    return isJSon;
}

export const getFileExt = name => {
    let matched = name.match(/.[a-z0-9]{3,4}$/i);
    return matched ? matched[0] : null;
};

export const getDownloadedFile = url => {
    const a = document.createElement('a');

    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.click();
    document.body.removeChild(a);
};

export const downloadFileFromResponse = response => {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
        }
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
};

export function getFileName(filePath) {
    const arr = filePath?.split('.');
    return arr?.slice(0, arr?.length - 1)?.join('.');
}

export function getAbsolutePath(path) {
    const arr = path?.split('/');
    return arr.length > 1 ? arr.slice(1).join('/') : arr[0];
}

export function createAndDownloadFile(data, filename, type) {
    const file = new Blob([data], { type: type });
    const a = document.createElement('a'),
        url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

export function retrieveImageFromClipboardAsBlob(pasteEvent) {
    return new Promise((resolve, reject) => {
        const arr = [];
        if (!pasteEvent.clipboardData) {
            reject();
        }

        var items = pasteEvent.clipboardData.items;

        if (!items) {
            reject();
        }

        for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf('image') === -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();
            arr.push(blob);
        }
        resolve(arr);
    });
}

export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
