import React from 'react';
import { Grid } from '@material-ui/core';
import Datetime from '../components/Datetime';

function BooleanUpdate({ previousData, data }) {
    return (
        <Grid container align="center" spacing={1}>
            <Grid item>
                <Datetime data={previousData} state={'delete'} />
            </Grid>
            <Grid item>
                <Datetime data={data} state={'update'} />
            </Grid>
        </Grid>
    );
}

export default React.memo(BooleanUpdate);
