import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import OverlimitSVG from 'assets/images/svg/payment/OverlimitSVG';
import { TAG_HEIGHT } from 'const/style';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {
        height: TAG_HEIGHT,
        paddingLeft: 8,
        paddingRight: 8,

        maxWidth: '100%',
        borderRadius: 3,
        width: 'auto'
    },
    icon: {
        display: 'flex',
        marginRight: 5
    }
}));

function Overlimit({ title = 'Overlimit', ...rest }) {
    const classes = useStyles();
    const theme = useTheme();
    const planColor = theme.colors.snackBarWarning;

    return (
        <Grid
            container
            alignItems="center"
            className={classes.root}
            style={{
                border: `1px solid ${hexToRgba(planColor, 0.8)}`,
                background: hexToRgba(planColor, 0.1)
            }}
            {...rest}
        >
            <Grid item className={classes.icon}>
                <OverlimitSVG color={hexToRgba(planColor, 0.8)} />
            </Grid>
            <Grid item>
                <Typography variant="body2">{title}</Typography>
            </Grid>
        </Grid>
    );
}

export default React.memo(Overlimit);
