import * as types from '../types';

const handler = {
    [types.FETCH_FULL_WORKSPACE_SUCCESS](state, { payload }) {
        const { workspaces } = payload;
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                isFetching: false,
                list: workspaces
            }
        };
    },
    [types.FETCH_WORKSPACE_VIEWS_DETAIL_SUCCESS](state, { payload }) {
        const { workspaceViewsDetail } = payload;
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                isFetchingWorkspaceViewsDetail: false,
                listViewsDetail: workspaceViewsDetail
            }
        };
    },
    [types.FETCH_WORKSPACE_VIEWS_DETAIL_FAILED](state, { payload }) {
        const { error } = payload;
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                error: error,
                isFetchingWorkspaceViewsDetail: false
            }
        };
    },
    [types.FETCH_WORKSPACE_VIEWS_DETAIL](state, { payload }) {
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                isFetchingWorkspaceViewsDetail: true
            }
        };
    }
};

export default handler;
