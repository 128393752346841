import React from 'react';

function AudioPlaySVG({ ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#AudioPlaySVG)">
                <path
                    d="M9.3963 0.160327C9.03256 0.00514841 8.6299 -0.0390373 8.24037 0.0334822C7.85085 0.106002 7.49238 0.291892 7.2113 0.56712C7.19541 0.582508 7.18036 0.598725 7.16623 0.615702L3.66865 4.61206H2.62878C1.93188 4.61283 1.26375 4.88571 0.770934 5.37084C0.278121 5.85597 0.000870683 6.51374 0 7.19986V8.79788C0.000783635 9.48406 0.277995 10.1419 0.770818 10.6271C1.26364 11.1123 1.93183 11.3852 2.62878 11.386H3.66865L7.16623 15.3846C7.1804 15.4012 7.19544 15.417 7.2113 15.4319C7.58355 15.7944 8.08552 15.9984 8.60909 16C8.88148 15.9996 9.151 15.9452 9.40157 15.84C9.76698 15.6924 10.079 15.4402 10.2973 15.1161C10.5156 14.792 10.6301 14.4109 10.626 14.0221C10.626 13.8245 10.5463 13.635 10.4044 13.4953C10.2625 13.3556 10.07 13.2771 9.86936 13.2771C9.66869 13.2771 9.47624 13.3556 9.33434 13.4953C9.19245 13.635 9.11273 13.8245 9.11273 14.0221C9.11512 14.1177 9.08763 14.2118 9.03398 14.2917C8.98033 14.3715 8.9031 14.4331 8.81272 14.4684C8.72581 14.5066 8.62944 14.5188 8.53553 14.5036C8.44163 14.4884 8.35431 14.4464 8.28439 14.3829L4.58811 10.1562C4.51717 10.0751 4.42929 10.0099 4.33045 9.96517C4.23162 9.92046 4.12415 9.89725 4.01538 9.89713H2.62878C2.33214 9.89713 2.04765 9.78112 1.83789 9.5746C1.62813 9.36809 1.51029 9.088 1.51029 8.79594V7.20018C1.51029 6.90813 1.62813 6.62804 1.83789 6.42152C2.04765 6.21501 2.33214 6.09899 2.62878 6.09899H4.01505C4.12382 6.09887 4.23129 6.07567 4.33013 6.03095C4.42896 5.98624 4.51685 5.92107 4.58778 5.83989L8.28571 1.6152C8.35787 1.55288 8.44674 1.51236 8.54168 1.49849C8.63662 1.48461 8.73362 1.49797 8.82105 1.53696C8.90848 1.57595 8.98265 1.63892 9.03467 1.71834C9.08669 1.79775 9.11437 1.89024 9.11438 1.98474V10.9491C9.11438 11.1467 9.19409 11.3361 9.33599 11.4758C9.47788 11.6155 9.67033 11.694 9.871 11.694C10.0717 11.694 10.2641 11.6155 10.406 11.4758C10.5479 11.3361 10.6276 11.1467 10.6276 10.9491V1.98474C10.6314 1.59424 10.5161 1.21161 10.2966 0.886354C10.077 0.561096 9.76343 0.308179 9.3963 0.160327Z"
                    fill="#78778B"
                />
                <path
                    d="M12.8452 9.92402L12.8603 9.89681C13.038 9.57585 13.3686 8.97893 13.3686 7.9992C13.3686 6.98254 13.0107 6.36101 12.8386 6.06207C12.7322 5.88915 12.5614 5.76389 12.3627 5.71286C12.1639 5.66184 11.9528 5.68908 11.7741 5.7888C11.5955 5.88852 11.4633 6.05286 11.4057 6.24695C11.348 6.44105 11.3695 6.64961 11.4655 6.82837C11.5951 7.05282 11.7925 7.39225 11.7925 8.00017C11.7925 8.58315 11.6027 8.92679 11.4767 9.15383L11.4606 9.18298C11.3612 9.36372 11.3385 9.57587 11.3976 9.77301C11.4566 9.97016 11.5926 10.1362 11.7757 10.2349C11.9595 10.3331 12.1753 10.3554 12.3758 10.2972C12.5762 10.2389 12.7451 10.1047 12.8452 9.92402Z"
                    fill="#78778B"
                />
                <path
                    d="M13.793 2.80189C13.7201 2.72644 13.6327 2.66616 13.5358 2.62461C13.4389 2.58307 13.3345 2.5611 13.2288 2.56002C13.1231 2.55895 13.0182 2.57878 12.9204 2.61834C12.8227 2.6579 12.734 2.71639 12.6596 2.79033C12.5852 2.86428 12.5267 2.95217 12.4874 3.04881C12.4482 3.14545 12.4291 3.24886 12.4312 3.35291C12.4333 3.45695 12.4567 3.55952 12.4998 3.65453C12.5429 3.74954 12.605 3.83505 12.6824 3.906C13.2331 4.44082 13.6705 5.07806 13.969 5.78071C14.2675 6.48336 14.4212 7.2374 14.4212 7.99903C14.4212 8.76066 14.2675 9.5147 13.969 10.2173C13.6705 10.92 13.2331 11.5572 12.6824 12.0921C12.605 12.163 12.5429 12.2485 12.4998 12.3435C12.4567 12.4385 12.4333 12.5411 12.4312 12.6452C12.4291 12.7492 12.4482 12.8526 12.4874 12.9492C12.5267 13.0459 12.5852 13.1338 12.6596 13.2077C12.734 13.2817 12.8227 13.3402 12.9204 13.3797C13.0182 13.4193 13.1231 13.4391 13.2288 13.438C13.3345 13.437 13.4389 13.415 13.5358 13.3734C13.6327 13.3319 13.7201 13.2716 13.793 13.1962C14.4921 12.5169 15.0472 11.7078 15.4261 10.8156C15.8049 9.92342 16.0001 8.96604 16.0001 7.99903C16.0001 7.03202 15.8049 6.07464 15.4261 5.18247C15.0472 4.2903 14.4921 3.48111 13.793 2.80189Z"
                    fill="#78778B"
                />
            </g>
            <defs>
                <clipPath id="AudioPlaySVG">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(AudioPlaySVG);
