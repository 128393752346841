import React from 'react';
import { WORKSPACE_GROUP_MEMBER, WORKSPACE_ABOUT } from 'const';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import { useDispatch } from 'react-redux';
import { setRightSidebar } from 'app/actions';
import CardInfo from 'components/cardInfo/Base';
import ProjectAboutSVG from 'assets/images/svg/ProjectAboutSVG';
import ProjectMemberSVG from 'assets/images/svg/ProjectMemberSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    item: {
        cursor: 'pointer',
        '&:not(:first-child)': {
            paddingTop: 24
        },
        paddingBottom: 24,
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48
    },
    closeIcon: {
        height: 24,
        width: 24
    }
}));

function Details() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Grid container direction="column">
            <Grid
                item
                tr-dt="Open About Workspace"
                className={classes.item}
                onClick={() => dispatch(setRightSidebar({ type: WORKSPACE_ABOUT }))}
            >
                <CardInfo
                    avatar={
                        <Grid item className={classes.icon}>
                            <ProjectAboutSVG />
                        </Grid>
                    }
                    title={t('project_detail_about_title')}
                    subTitle={t('project_detail_about_description')}
                    action={<ArrowRightSVG />}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
                tr-dt="Open Groups & Members Setting"
                onClick={() => dispatch(setRightSidebar({ type: WORKSPACE_GROUP_MEMBER }))}
            >
                <CardInfo
                    avatar={
                        <Grid item className={classes.icon}>
                            <ProjectMemberSVG />
                        </Grid>
                    }
                    title={t('project_detail_groups_members_title')}
                    subTitle={t('project_detail_groups_members_description')}
                    action={<ArrowRightSVG />}
                />
            </Grid>
            {/* <Grid item className={classes.item} onClick={() => dispatch(setRightSidebar({ type: USAGE_STATISTIC }))}>
                <CardInfo
                    avatar={
                        <Grid item className={classes.icon}>
                            <ProjectUsageSVG />
                        </Grid>
                    }
                    title={'Usage statistics'}
                    subTitle="Current usage statistics of the Project"
                    action={<ArrowRightSVG />}
                />
            </Grid> */}
        </Grid>
    );
}

export default React.memo(Details);
