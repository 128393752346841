import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import UploadIconSVG from 'assets/images/svg/ImportFileSVG';
import { GRID_UPLOAD_MAX_SIZE } from 'const';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {
        border: props =>
            props.hideBorder
                ? 0
                : props.isDragActive
                ? `2px dashed ${theme.colors.highlight} !important`
                : `2px dashed #b4b4b4`,
        cursor: 'pointer',
        borderRadius: 4,
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:focus': props => ({
            outline: 'none',
            border: props.hideBorder ? 0 : `2px dashed #b4b4b4`
        }),
        '& p': {
            margin: 0
        }
    },
    file: {
        width: '48px',
        height: '48px'
    }
}));

function UploadZone({
    hideBorder = false,
    onDropAccepted,
    onDropRejected,
    className = '',
    multiple = false,
    accept,
    children,
    maxSize
}) {
    const theme = useTheme();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: multiple,
        onDropAccepted,
        onDropRejected,
        maxSize: maxSize || GRID_UPLOAD_MAX_SIZE,
        accept: accept || null
    });

    const classes = useStyles({ isDragActive, hideBorder });

    const style = useMemo(
        () => ({
            ...(isDragActive
                ? {
                      border: `1px dashed ${theme.colors.highlight}`,
                      background: hexToRgba(theme.colors.highlight, 0.05),
                      borderRadius: 4
                  }
                : {})
        }),
        [isDragActive, theme]
    );

    return (
        <div className={`${classes.root} ${className}`} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {children ? (
                children
            ) : (
                <React.Fragment>
                    <UploadIconSVG className={classes.file} />
                    <p>Drop or Click to upload files</p>
                </React.Fragment>
            )}
        </div>
    );
}

export default React.memo(UploadZone);
