import React from 'react';

function CSVSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M6 0V36H27L36 27V0H6Z" fill="white" />
            <path d="M27 27V36L36 27H27Z" fill="#C9C9CE" />
            <path d="M18 36H27V27L18 36Z" fill="#E9EAEF" />
            <path d="M0 9V19.5H24V9H0Z" fill="#4A91E2" />
            <path
                d="M6.02991 12.1982C5.91891 11.9837 5.71641 11.8772 5.42241 11.8772C5.26041 11.8772 5.12691 11.9207 5.02191 12.0092C4.91691 12.0962 4.83441 12.2357 4.77441 12.4307C4.71441 12.6257 4.67241 12.8807 4.64841 13.1972C4.62291 13.5122 4.61241 13.8977 4.61241 14.3537C4.61241 14.8397 4.62891 15.2327 4.66191 15.5327C4.69341 15.8327 4.74591 16.0637 4.81491 16.2302C4.88391 16.3967 4.97091 16.5077 5.07591 16.5632C5.18091 16.6202 5.30241 16.6502 5.44041 16.6502C5.55441 16.6502 5.66091 16.6307 5.75991 16.5917C5.85891 16.5527 5.94441 16.4747 6.01641 16.3577C6.08991 16.2407 6.14691 16.0757 6.18741 15.8627C6.22941 15.6497 6.25041 15.3692 6.25041 15.0212H7.54491C7.54491 15.3677 7.51791 15.6992 7.46391 16.0112C7.40991 16.3232 7.30791 16.5962 7.16241 16.8302C7.01391 17.0627 6.80841 17.2457 6.54141 17.3777C6.27441 17.5097 5.92791 17.5772 5.50191 17.5772C5.01741 17.5772 4.62891 17.4977 4.34241 17.3432C4.05441 17.1872 3.83391 16.9622 3.68091 16.6682C3.52791 16.3742 3.42891 16.0232 3.38391 15.6152C3.34041 15.2087 3.31641 14.7572 3.31641 14.2667C3.31641 13.7807 3.34041 13.3322 3.38391 12.9212C3.42891 12.5117 3.52941 12.1577 3.68091 11.8637C3.83541 11.5712 4.05441 11.3402 4.34241 11.1752C4.62891 11.0117 5.01741 10.9277 5.50191 10.9277C5.96541 10.9277 6.32841 11.0027 6.59541 11.1527C6.86241 11.3027 7.06191 11.4932 7.19241 11.7242C7.32291 11.9552 7.40841 12.2042 7.43991 12.4712C7.47291 12.7382 7.48941 12.9887 7.48941 13.2227H6.19491C6.19491 12.7502 6.13941 12.4097 6.02991 12.1982Z"
                fill="white"
            />
            <path
                d="M10.2257 15.6578C10.2257 15.8078 10.2392 15.9443 10.2617 16.0658C10.2872 16.1888 10.3307 16.2923 10.3967 16.3763C10.4627 16.4618 10.5527 16.5278 10.6637 16.5758C10.7732 16.6223 10.9142 16.6478 11.0807 16.6478C11.2802 16.6478 11.4572 16.5833 11.6162 16.4543C11.7737 16.3253 11.8547 16.1243 11.8547 15.8558C11.8547 15.7118 11.8337 15.5873 11.7962 15.4823C11.7572 15.3773 11.6927 15.2828 11.6027 15.2003C11.5112 15.1148 11.3927 15.0398 11.2472 14.9708C11.1017 14.9018 10.9187 14.8313 10.7027 14.7593C10.4162 14.6633 10.1672 14.5568 9.95569 14.4428C9.74569 14.3303 9.57019 14.1968 9.42919 14.0423C9.28819 13.8893 9.18469 13.7123 9.11869 13.5113C9.05269 13.3088 9.01969 13.0778 9.01969 12.8123C9.01969 12.1763 9.19669 11.7038 9.55069 11.3918C9.90319 11.0798 10.3907 10.9238 11.0087 10.9238C11.2967 10.9238 11.5622 10.9553 11.8052 11.0183C12.0482 11.0813 12.2582 11.1818 12.4352 11.3243C12.6122 11.4653 12.7502 11.6453 12.8492 11.8628C12.9467 12.0818 12.9977 12.3443 12.9977 12.6503V12.8303H11.7572C11.7572 12.5243 11.7032 12.2888 11.5967 12.1223C11.4887 11.9588 11.3087 11.8748 11.0567 11.8748C10.9127 11.8748 10.7927 11.8973 10.6967 11.9378C10.6022 11.9798 10.5242 12.0353 10.4672 12.1043C10.4102 12.1733 10.3697 12.2558 10.3487 12.3458C10.3262 12.4358 10.3172 12.5288 10.3172 12.6248C10.3172 12.8243 10.3592 12.9893 10.4432 13.1243C10.5272 13.2608 10.7072 13.3853 10.9832 13.4978L11.9822 13.9298C12.2282 14.0378 12.4292 14.1503 12.5837 14.2688C12.7397 14.3858 12.8657 14.5118 12.9572 14.6468C13.0502 14.7818 13.1147 14.9288 13.1507 15.0923C13.1867 15.2528 13.2047 15.4343 13.2047 15.6308C13.2047 16.3088 13.0082 16.8023 12.6152 17.1113C12.2222 17.4203 11.6747 17.5763 10.9727 17.5763C10.2407 17.5763 9.71719 17.4173 9.40069 17.0993C9.08569 16.7813 8.92969 16.3253 8.92969 15.7313V15.4703H10.2257V15.6578Z"
                fill="white"
            />
            <path
                d="M16.6264 15.8028H16.6549L17.6089 11.0508H18.9514L17.4754 17.4768H15.8104L14.3359 11.0508H15.7219L16.6264 15.8028Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(CSVSVG);
