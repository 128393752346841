import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import diff_match_patch from 'diff-match-patch';
import { TEXT_LINE_HEIGHT } from 'const/gridUI';
import 'diff-match-patch-line-and-word';

const diff = new diff_match_patch();

const useStyles = makeStyles(theme => ({
    deletedText: {
        background: theme.colors.pinka,
        textDecoration: `line-through`,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        display: props => (props.diffBefore !== undefined && !props.diffBefore ? 'none' : 'inline')
    },
    diffText: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    updatedText: {
        background: theme.colors.griptide,
        display: props => (props.diffBefore !== undefined && props.diffBefore ? 'none' : 'inline')
    }
}));

function HighlightDiffTextOnly({ data = '', previousData = '', diffBefore = undefined }) {
    const classes = useStyles({ diffBefore });
    const diffResult = diff.diff_wordMode(previousData?.toString(), data?.toString());
    return (
        <div className="body2 break-words whitespace-pre-wrap">
            {diffResult.map((item, index) => {
                const [status, content] = item;
                const getClassName = status => {
                    switch (status) {
                        case 0:
                            return;
                        case 1:
                            return classes.updatedText;
                        case -1:
                            return classes.deletedText;
                        default:
                            return;
                    }
                };
                return (
                    <span key={index} className={getClassName(status)}>
                        {content}
                    </span>
                );
            })}
        </div>
    );
}

export default React.memo(HighlightDiffTextOnly);
