import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import DatabaseItem from './DatabaseItem';
import { useIsShowFirstPopupCreate, useDatabasesByWorkspaceId } from 'hooks/database';
import { useContextMenuId } from 'hooks/app';
import { useSortCriteriaProperties } from 'hooks/workspace';
import { SORT_BY_CRITERIA } from 'const';

function DatabaseList({ fetchingDisabled }) {
    const { workspaceId } = useParams();
    const contextMenuId = useContextMenuId();
    const dbs = useDatabasesByWorkspaceId(workspaceId);
    const isShowFirstPopupCreate = useIsShowFirstPopupCreate();
    const workspaceSortCriteria = useSortCriteriaProperties(workspaceId);

    const sortedDbs = React.useMemo(() => {
        if (dbs?.length > 0 && workspaceSortCriteria) {
            const newDbs = dbs.filter(el => !!el.new);
            let oldDbs = dbs.filter(el => !el.new);

            switch (workspaceSortCriteria.field) {
                case SORT_BY_CRITERIA.CREATED_DATE: {
                    oldDbs = oldDbs.sort((a, b) => {
                        const aTime = new Date(a.createdTime).getTime();
                        const bTime = new Date(b.createdTime).getTime();
                        return workspaceSortCriteria.direction === 'DESC' ? bTime - aTime : aTime - bTime;
                    });
                    break;
                }
                case SORT_BY_CRITERIA.LAST_MODIFIED: {
                    oldDbs = oldDbs.sort((a, b) => {
                        const aTime = new Date(a.alteredTime).getTime();
                        const bTime = new Date(b.alteredTime).getTime();
                        return workspaceSortCriteria.direction === 'DESC' ? bTime - aTime : aTime - bTime;
                    });
                    break;
                }
                case SORT_BY_CRITERIA.ALPHABETICAL: {
                    oldDbs = oldDbs.sort((a, b) => {
                        const compare = a.name.normalize().localeCompare(b.name.normalize());
                        return workspaceSortCriteria.direction === 'DESC' ? -1 * compare : compare;
                    });
                    break;
                }
                default: {
                    break;
                }
            }
            return [...newDbs, ...oldDbs];
        }
        return dbs;
    }, [dbs, workspaceSortCriteria]);

    return (
        <Grid container>
            {sortedDbs?.map((database, index) => {
                if (database.isDeleted) return null;
                return (
                    <DatabaseItem
                        index={index}
                        contextMenuId={contextMenuId}
                        key={database.id}
                        database={database}
                        id={database.id}
                        name={database.name}
                        status={database.status}
                        workspaceId={workspaceId}
                        isShowFirstPopupCreate={isShowFirstPopupCreate === database.id && index === 0}
                        fetchingDisabled={fetchingDisabled}
                    />
                );
            })}
        </Grid>
    );
}

export default React.memo(DatabaseList);
