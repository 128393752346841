import React, { useCallback, useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HEADER_HEIGHT } from 'const';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNotificationCenterPushes } from 'hooks/auth';
import { useDispatch } from 'react-redux';
import * as authActions from 'auth/actions';
import NotificationCenterItemWrapper from './NotificationCenterItemWrapper';
import { notificationMenuSetOpen } from '.';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        right: 0,
        top: HEADER_HEIGHT,
        zIndex: 9999999,
        overflow: 'hidden auto',
        padding: `5px 19px 0`,
        '& .slideEnter': {
            transform: `translateX(100%)`
        },
        '& .slideEnterActive': {
            transform: `translateX(0%)`,
            transition: `transform 500ms ease-in-out`
        },
        '& .slideExit': {
            transform: `translateX(0%)`
        },
        '& .slideExitActive': {
            transform: `translateX(100%)`,
            transition: `transform 500ms ease-in-out`
        }
    }
}));

const useItemStyles = makeStyles(theme => ({
    root: {
        margin: '10px 0',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        backgroundColor: theme.colors.white,
        width: 340,
        minHeight: 90,
        position: 'relative'
    },
    item: {
        padding: '16px 14px 5px 12px'
    },
    closeIcon: {
        position: 'absolute',
        width: 24,
        height: 24,
        borderRadius: '50%',
        top: -12,
        left: -12,
        backgroundColor: theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        cursor: 'pointer'
    }
}));

const TIME_OUT = 4000;

const Item = ({ message }) => {
    const classes = useItemStyles();
    const dispatch = useDispatch();
    const timeoutRef = useRef();

    const onClose = useCallback(() => {
        dispatch(authActions.removeNotificationCenterPushes(message?.id));
    }, [dispatch, message]);

    const beforeClick = useCallback(() => {
        onClose();
        notificationMenuSetOpen && notificationMenuSetOpen(false);
    }, [onClose]);

    useEffect(() => {
        if (timeoutRef.current) return;
        timeoutRef.current = true;
        setTimeout(() => {
            onClose();
        }, TIME_OUT);
    }, [onClose]);

    return (
        <Grid item className={classes.root}>
            <NotificationCenterItemWrapper
                key={message.id}
                keyNotification={message?.type}
                message={message}
                isNoti
                isLast
                beforeClick={onClose}
            />
            <Grid item className={classes.closeIcon} onClick={beforeClick}>
                <CloseIconSVG />
            </Grid>
        </Grid>
    );
};

const ItemMemo = React.memo(Item);

const NotificationCenterNoti = () => {
    const classes = useStyles();
    const notificationCenterPushes = useNotificationCenterPushes();

    return (
        <Grid item className={classes.root}>
            <TransitionGroup>
                {notificationCenterPushes.map(
                    (m, idx) =>
                        idx < 3 && (
                            <CSSTransition
                                key={m?.id}
                                classNames={{
                                    enter: 'slideEnter',
                                    enterActive: 'slideEnterActive',
                                    exit: 'slideExit',
                                    exitActive: 'slideExitActive'
                                }}
                                timeout={{ enter: 1000, exit: 500 }}
                            >
                                <ItemMemo message={m} />
                            </CSSTransition>
                        )
                )}
            </TransitionGroup>
        </Grid>
    );
};

export default React.memo(NotificationCenterNoti);
