import React from 'react';
import AccessView from './AccessView';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from 'components/tooltip/Base';
import { Grid, Button as ButtonBase } from '@material-ui/core';
import { USER_ACTION_TYPES } from 'const/gridUI';
import { useDispatch } from 'react-redux';
import { useIsCanExportImportLocalization, useIsPathTagExistedInView } from 'hooks/gridUI';
import useMediaQueries from 'hooks/useMediaQueries';
import { useRole } from 'hooks/auth/role';
import { INPUT_RADIUS, INPUT_PADDING_LEFT } from 'const/style';
import * as roleConst from 'auth/roleConst';
import UndoSVG from 'assets/images/svg/UndoSVG';
import RedoSVG from 'assets/images/svg/RedoSVG';
import * as gridUIActions from 'gridUI/actions';
import { useIsVerticalJSON } from 'hooks/grid';
import IconLockSVG from 'assets/images/svg/IconLockSVG';
import ManageColumn from './toolbar/ManageColumn';
import ManageFilter from './toolbar/ManageFilter';
import ManageSort from './toolbar/ManageSort';
import ManagePathTag from './toolbar/ManagePathTag';
import ManageCreateColumn from './toolbar/ManageCreateColumn';
import ManageCreateRecord from './toolbar/ManageCreateRecord';
import ManageExport from './toolbar/ManageExport';
import ManageImport from './toolbar/ManageImport';
import ManageDependencies from './toolbar/ManageDependencies';
import ManageHeight from './toolbar/ManageHeight';
import ManageFillColor from './toolbar/ManageFillColor';
import ManageAutoQA from './toolbar/ManageAutoQA';
import ManageTag from './toolbar/ManageTag';
import ManageTemplate from './toolbar/ManageTemplate';
import ManageLocalization from './toolbar/ManageLocalization';
import ManageToolbarLock from './toolbar/ManageToolbarLock';
import ManageMoreActions from './toolbar/ManageMoreActions';
import ManageActionStatus from './toolbar/ManageActionStatus';
import ManageUserAvatarListPreview from './toolbar/ManageUserAvatarListPreview';
import ManageSearch from './toolbar/ManageSearch';

const useStyles = makeStyles(theme => ({
    root: {
        //[theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        overflowY: 'hidden',
        //}

        [theme.breakpoints.down('md')]: {
            minHeight: 50
        }
    },
    container: {
        // minWidth: 1200,
        // overflowX: 'auto',
        // overflowY: 'hidden'
    },
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(3),
        '& span': {
            width: 1,
            height: 25,
            background: theme.colors.lightGrey
        },

        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    dividerViewSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: theme.spacing(1),
        '& span': {
            width: 1,
            height: 25,
            background: theme.colors.lightGrey
        },

        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    viewSection: {
        background: theme.colors.ghostwhite,
        borderRadius: INPUT_RADIUS,
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT,
        border: `1px solid ${theme.colors.lightGrey}`,
        minHeight: 40
    },
    mt2: {
        marginTop: theme.spacing(3)
    },
    spacing: {
        marginBottom: theme.spacing(1)
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    actionStatus: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    viewOnly: {
        padding: '0 18px',
        height: 36,
        borderRadius: 4,
        background: theme.colors.lightGrey,
        '& svg': {
            marginRight: 8,
            verticalAlign: 'middle'
        },
        '& p': {
            color: `${theme.colors.steel} !important`
        }
    }
}));

function GlobalFilter({
    onOpenTranslationOverview,
    branchId,
    viewId,
    dbId,
    workspaceId,
    gridId,
    isShareViewLink,
    t,
    onOpenGridTypeSetting
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { isDesktop } = useMediaQueries();
    const isPathTagExistedInView = useIsPathTagExistedInView();
    const isCanExportImportLocalization = useIsCanExportImportLocalization();
    const isVerticalJSON = useIsVerticalJSON({ dbId, gridId });
    const roles = useRole();

    const handleUndo = React.useCallback(() => {
        dispatch(gridUIActions.registerUndoRedo(USER_ACTION_TYPES.UNDO));
    }, [dispatch]);

    const handleRedo = React.useCallback(() => {
        dispatch(gridUIActions.registerUndoRedo(USER_ACTION_TYPES.REDO));
    }, [dispatch]);

    const isShowAccessDivider = React.useMemo(() => {
        return roles[roleConst.WORKSPACE_AUTHORITIES?.MANAGE_VIEW] === roleConst.FULL;
    }, [roles]);

    return (
        <div id="grid-header-menu-container" className={`${classes.root} scrollbar-app`}>
            <Grid container className={classes.container} alignItems="center" justify="space-between" wrap="nowrap">
                <Grid item style={{ flex: 1 }}>
                    <Grid container wrap="nowrap" className={classes.iconsContainer} alignItems={'center'}>
                        <Grid id="toolbarViewSettings" item className={classes.menuItem}>
                            <Grid container wrap="nowrap" className={classes.viewSection} alignItems="center">
                                <Grid id="g-h-menu-item-views-access" item tr-dt="Open View Setting">
                                    <AccessView
                                        workspaceId={workspaceId}
                                        gridId={gridId}
                                        viewId={viewId}
                                        dbId={dbId}
                                        branchId={branchId}
                                        isShareViewLink={isShareViewLink}
                                        t={t}
                                    />
                                </Grid>
                                {isShowAccessDivider && (
                                    <Grid item className={classes.dividerViewSection}>
                                        <span></span>
                                    </Grid>
                                )}
                                <ManageColumn />
                                <ManageFilter />
                                <ManageSort />
                                {(!isShareViewLink || (isShareViewLink && isPathTagExistedInView)) && <ManagePathTag />}
                            </Grid>
                        </Grid>

                        {!isShareViewLink && (
                            <>
                                <Tooltip
                                    title={
                                        <p className="text-light font-medium">
                                            {t('keyboard_undo')}{' '}
                                            <span className="text-white text-bold">(Ctrl/Cmd + Z)</span>
                                        </p>
                                    }
                                >
                                    <Grid item className={classes.menuItem}>
                                        <ButtonBase
                                            id="g-h-menu-item-undo"
                                            className={`${classes.addButton} ${classes.button}`}
                                            onClick={handleUndo}
                                            tr-dt="Undo"
                                        >
                                            <UndoSVG height={18} width={18} color={theme.colors.darkness} />
                                        </ButtonBase>
                                    </Grid>
                                </Tooltip>

                                <Tooltip
                                    title={
                                        <p className="text-light font-medium">
                                            {t('keyboard_redo')}{' '}
                                            <span className="text-white text-bold">(Ctrl/Cmd + Shift + Z)</span>
                                        </p>
                                    }
                                >
                                    <Grid item className={classes.menuItem}>
                                        <ButtonBase
                                            id="g-h-menu-item-redo"
                                            className={`${classes.addButton} ${classes.button}`}
                                            onClick={handleRedo}
                                            tr-dt="Redo"
                                        >
                                            <RedoSVG height={18} width={18} color={theme.colors.darkness} />
                                        </ButtonBase>
                                    </Grid>
                                </Tooltip>
                            </>
                        )}

                        {!isShareViewLink &&
                            [roleConst.READ_ONLY, roleConst.FULL]?.includes(
                                roles?.[roleConst.WORKSPACE_AUTHORITIES.IMPORT_EXPORT]
                            ) && (
                                <Grid item className={classes.divider}>
                                    <span></span>
                                </Grid>
                            )}

                        <ManageCreateColumn />
                        <ManageCreateRecord />

                        {isDesktop && (
                            <>
                                {!isShareViewLink &&
                                    [roleConst.READ_ONLY, roleConst.FULL]?.includes(
                                        roles?.[roleConst.WORKSPACE_AUTHORITIES.IMPORT_EXPORT]
                                    ) && (
                                        <Grid item className={classes.divider}>
                                            <span></span>
                                        </Grid>
                                    )}
                                <ManageExport />
                                <ManageImport />
                            </>
                        )}

                        {!isShareViewLink &&
                            [roleConst.READ_ONLY, roleConst.FULL].includes(
                                roles?.[roleConst.EXTRA_AUTHORITIES?.SHOW_DEPENDENCY]
                            ) && (
                                <Grid item className={classes.divider}>
                                    <span></span>
                                </Grid>
                            )}

                        <ManageDependencies />

                        {!isShareViewLink &&
                            isDesktop &&
                            ([roleConst.READ_ONLY, roleConst.FULL].includes(
                                roles?.[roleConst.EXTRA_AUTHORITIES?.SHOW_DEPENDENCY]
                            ) ||
                                [roleConst.READ_ONLY, roleConst.FULL]?.includes(
                                    roles?.[roleConst.WORKSPACE_AUTHORITIES.IMPORT_EXPORT]
                                )) && (
                                <Grid item className={classes.divider}>
                                    <span></span>
                                </Grid>
                            )}

                        {(isDesktop || isShareViewLink) && (
                            <ManageHeight isDesktop={isDesktop} isShareViewLink={isShareViewLink} />
                        )}

                        <ManageFillColor />

                        {(isCanExportImportLocalization ||
                            isVerticalJSON ||
                            [roleConst.READ_ONLY, roleConst.FULL].includes(
                                roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE]
                            ) ||
                            [roleConst.READ_ONLY, roleConst.FULL].includes(
                                roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID]
                            )) &&
                            !isShareViewLink && (
                                <Grid item className={classes.divider}>
                                    <span></span>
                                </Grid>
                            )}

                        {!isShareViewLink && <ManageAutoQA />}
                        {!isShareViewLink && <ManageTag />}
                        <ManageTemplate />
                        <ManageLocalization
                            onOpenTranslationOverview={onOpenTranslationOverview}
                            onOpenGridTypeSetting={onOpenGridTypeSetting}
                        />

                        {!isDesktop && !isShareViewLink && <ManageMoreActions />}

                        {isShareViewLink && (
                            <Grid item>
                                <Grid item container wrap="nowrap" alignItems="center" className={classes.viewOnly}>
                                    <Grid item>
                                        <IconLockSVG width="20" height="20" />
                                    </Grid>
                                    <Grid item>
                                        <p className="body1">{t('global_view_only')}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item className={classes.scrollableShadowContainer}>
                            &nbsp;
                        </Grid>

                        <ManageToolbarLock />
                    </Grid>
                </Grid>

                <div className={classes.scrollableShadow} />
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        <Grid item className={classes.actionStatus}>
                            <ManageActionStatus />
                        </Grid>
                        <Grid item>
                            <ManageUserAvatarListPreview />
                        </Grid>
                        <Grid item>
                            <ManageSearch />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

GlobalFilter.propTypes = {};

export default React.memo(GlobalFilter);
