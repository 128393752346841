import { enqueueSnackbar } from 'notifier/actions';
import { cancelSubscriptionApi, changePlanApi } from 'services/payment';
import { fetchCustomerAndSubscriptions } from './billing';

export function cancelAddonSubscription({ id, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            await cancelSubscriptionApi(id);
            dispatch(
                fetchCustomerAndSubscriptions({
                    successCallback: () => {
                        successCallback && successCallback();
                    }
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function changePlan({
    subscriptionId,
    plan,
    annually,
    totalSeat,
    errorCallback,
    successCallback,
    successCallbackFetchCustomer
}) {
    return async function(dispatch, getState) {
        try {
            const newSubscription = await changePlanApi({ subscriptionId, plan, annually, totalSeat });
            dispatch(fetchCustomerAndSubscriptions({ successCallback: successCallbackFetchCustomer }));
            successCallback && successCallback(newSubscription);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}
