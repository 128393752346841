import React from 'react';
import { Grid } from '@material-ui/core';

import {
    useSelectedRecordId,
    useSelectedCellData,
    useChildDependenciesWithData,
    useParentDependenciesWithData,
    useDependencies,
    useMetaData,
    useViewColumnsWithReOrder,
    useDisabledColumns,
    useProcessingColumns,
    useDisabledSourceColumns,
    useDisabledColumnsByType,
    useData
} from 'hooks/gridUI';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import * as columnTypes from 'const/columnTypes';
import TranslatorContent from './Content';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { SOURCE_STATUS } from 'const/gridUI';

function TranslatorView({ height, showDiff, setShowDiff }) {
    const recordId = useSelectedRecordId();
    const selectedCellData = useSelectedCellData();
    const childColumns = useChildDependenciesWithData();
    const parentColumns = useParentDependenciesWithData();
    const dependencies = useDependencies();
    const metaData = useMetaData();
    const dispatch = useDispatch();
    const viewColumns = useViewColumnsWithReOrder();
    const disabledColumns = useDisabledColumns();
    const processingColumns = useProcessingColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const roles = useRole();
    const data = useData();

    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];

    const canSeeColumnIds = React.useMemo(() => {
        return viewColumns?.map(col => col?.id);
    }, [viewColumns]);

    const editableMappingCols = React.useMemo(() => {
        return viewColumns?.reduce((obj, current) => {
            obj[current?.id] = current?.editable;
            return obj;
        }, {});
    }, [viewColumns]);

    React.useEffect(() => {
        if (recordId) {
            dispatch(
                gridActions.fetchRecordHistory({
                    recordId,
                    errorCallback: () => {
                        console.log('failed to refresh recordHistory');
                    }
                })
            );

            dispatch(
                gridActions.fetchRecordsData({
                    recordIds: [recordId],
                    errorCallback: () => {
                        console.log('failed to fetch records data');
                    }
                })
            );
        }
    }, [dispatch, recordId]);

    const targetOptions = React.useMemo(() => {
        if (!recordId || !selectedCellData) return [];

        return childColumns
            ?.filter(col => {
                return col?.type === columnTypes.TRANSLATION && canSeeColumnIds?.includes(col?.id);
            })
            ?.map(column => ({
                icon: () => <ColumnIcon {...column} />,
                label: column?.name,
                value: column?.id
            }));
    }, [recordId, selectedCellData, childColumns, canSeeColumnIds]);

    const targetSelected = React.useMemo(() => {
        if (!targetOptions?.length) return null;

        const found = targetOptions?.find(option => option?.value === selectedCellData?.columnId);

        if (found) {
            const matchParentDpDc = dependencies?.find(
                dpDc => dpDc?.child === found?.value && metaData?.[dpDc?.parent]?.type === columnTypes.TRANSLATION
            );
            const select = metaData?.[matchParentDpDc?.child];
            if (!matchParentDpDc) return null;

            return {
                icon: () => <ColumnIcon {...select} />,
                label: select?.name,
                value: matchParentDpDc?.child,
                ...metaData?.[matchParentDpDc?.child]
            };
        }

        const column = selectedCellData?.column;
        const isTranslation = column?.type === columnTypes.TRANSLATION;

        if (!isTranslation) {
            const selectedParent = parentColumns?.filter(col => {
                const isTranslation = col?.type === columnTypes.TRANSLATION;
                const children = dependencies?.filter(dpDc => {
                    return dpDc?.parent === col?.id && metaData?.[dpDc?.child]?.type === columnTypes.TRANSLATION;
                });

                return isTranslation && children?.length;
            })?.[0];

            if (!selectedParent) return null;

            const selectedChild = dependencies?.filter(dpDc => {
                return dpDc?.parent === selectedParent?.id && metaData?.[dpDc?.child]?.type === columnTypes.TRANSLATION;
            })?.[0];

            if (!selectedChild) return null;

            return {
                icon: () => <ColumnIcon {...metaData?.[selectedChild?.child]} />,
                label: metaData?.[selectedChild?.child]?.name,
                value: selectedChild?.child,
                ...metaData?.[selectedChild?.child]
            };
        } else {
            const child = dependencies?.filter(dpDc => {
                return (
                    dpDc?.parent === selectedCellData?.columnId &&
                    metaData?.[dpDc?.child]?.type === columnTypes.TRANSLATION
                );
            })?.[0]?.child;

            if (!child) return null;

            return {
                icon: () => <ColumnIcon {...metaData?.[child]} />,
                label: metaData?.[child]?.name,
                value: child,
                ...metaData?.[child]
            };
        }
    }, [targetOptions, selectedCellData, dependencies, metaData, parentColumns]);

    const isTargetStatusLocked = React.useMemo(() => {
        const parentCellData = data?.[selectedCellData?.rowId]?.[targetSelected?.id];

        return [SOURCE_STATUS.LOCKED]?.includes(parentCellData?._sourceStatus);
    }, [targetSelected, selectedCellData, data]);

    const isParentTargetDisabled = React.useMemo(() => {
        const matchParentDpDc = dependencies?.find(dpDc => dpDc?.child === targetSelected?.id);

        if (!matchParentDpDc) return false;

        const parentColumnId = matchParentDpDc?.parent;
        const parentCellData = data?.[selectedCellData?.rowId]?.[parentColumnId];

        return [SOURCE_STATUS.DO_NOT_TRANSLATE, SOURCE_STATUS.NOT_READY_FOR_TRANSLATION]?.includes(
            parentCellData?._sourceStatus
        );
    }, [targetSelected, data, dependencies, selectedCellData]);

    const disabledTarget = React.useMemo(() => {
        if (!targetSelected) return true;
        if (
            [...disabledColumns, ...processingColumns, ...disabledSourceColumns, ...disabledColumnIdsByType]?.includes(
                targetSelected?.value
            )
        ) {
            return true;
        }

        if (accessEditRecords !== roleConst.FULL) return true;

        return !editableMappingCols?.[targetSelected?.value] || isParentTargetDisabled || isTargetStatusLocked;
    }, [
        isTargetStatusLocked,
        accessEditRecords,
        targetSelected,
        editableMappingCols,
        disabledColumns,
        processingColumns,
        disabledSourceColumns,
        disabledColumnIdsByType,
        isParentTargetDisabled
    ]);

    if (!recordId || !targetSelected)
        return (
            <Grid
                style={{ height, padding: `12px 24px 0px` }}
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={3}
            >
                <p className="caption">Please select a matched language pair</p>
            </Grid>
        );

    return (
        <TranslatorContent
            disabledTarget={disabledTarget}
            targetSelected={targetSelected}
            targetOptions={targetOptions}
            height={height}
            showDiff={showDiff}
            setShowDiff={setShowDiff}
        />
    );
}

export default TranslatorView;
