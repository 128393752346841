import React from 'react';
import { Grid } from '@material-ui/core';
import Checkbox from 'components/checkbox/Base';
import { ROW_HEIGHT } from 'const/gridUI';
import * as gridUIActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { SWITCHING_COLUMN_MAPPING } from 'gridUI/table/grid/columns/switchColumnMapping';
import * as columnTypes from 'const/columnTypes';

function BooleanRender({
    value,
    rowHeight = ROW_HEIGHT,
    rowIndex,
    columnIndex,
    rowId,
    columnId,
    isReadOnly,
    isProcessing,
    ...rest
}) {
    const dispatch = useDispatch();

    const OPTIMISTIC_VALUES = SWITCHING_COLUMN_MAPPING?.[columnTypes.BOOLEAN]?.OPTIMISTIC_VALUES;

    let checked = false;

    if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
        checked = OPTIMISTIC_VALUES?.includes(value?.toString().toLowerCase());
    }

    const handleClick = e => {
        stopPropagation(e);
        if (isReadOnly) return;
        dispatch(gridUIActions.changeBooleanStatus({ rowId, columnId }));
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            className={`cell_${rowIndex}_${columnIndex} RECORD_${rowId}`}
            {...rest}
            style={{
                height: rowHeight
            }}
        >
            <Grid item onClick={handleClick} style={{ cursor: isReadOnly ? 'not-allowed' : 'pointer' }}>
                <Checkbox
                    disableTouchRipple
                    checked={checked}
                    style={{
                        maxWidth: 24,
                        maxHeight: 24
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(BooleanRender);
