import React from 'react';

function AToZSVG({ color = '#6E5BBE', ...other }) {
    return (
        <svg width="32" height="10" viewBox="0 0 32 10" fill="none" {...other} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.49835 8.59814L4.80396 1.3999H3.42957L0.75 8.59814H2.32709L2.82147 7.11499H5.42194L5.92126 8.59814H7.49835ZM30.9036 2.25024V1.3999H25.5049V2.60126H29.0596L25.5 7.72803V8.59814H30.9827V7.40668H27.3539L30.9036 2.25024ZM19.6706 2.5249L21.5814 4.71554L21.5924 4.71525L21.5921 4.72788L22.125 5.3374L19.6706 8.1499L18.8587 7.21946L19.8955 6.03139L10.875 6.03109V4.71525L19.9572 4.71554L18.8587 3.45535L19.6706 2.5249ZM3.22192 5.91364L4.11676 3.21924L5.02148 5.91364H3.22192Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(AToZSVG);
