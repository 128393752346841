import { GRID_UPLOAD_MAX_SIZE } from 'const';
import i18n from 'i18n';

export function getUploadErrorMessage(rejectedFiles, accept) {
    return rejectedFiles?.map(file => {
        const errors = file?.errors;
        return errors?.map(error => {
            return _mapCodeWithLabel(error?.code, accept) || error?.message;
        });
    });
}

function _mapCodeWithLabel(code, accept) {
    const lowercaseExtensions = accept?.map(ext => ext.toLowerCase());
    const uniqueExtensions = [...new Set(lowercaseExtensions)];
    switch (code) {
        case 'file-invalid-type':
            if (!accept) return;
            return `File type must be one of ${uniqueExtensions.join(', ')}`;
        case 'file-too-large':
            return i18n.t('global_input_file_size_exceeded', { maxSize: GRID_UPLOAD_MAX_SIZE / (1024 * 1024) });
        default:
            return null;
    }
}
