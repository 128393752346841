import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';

const useStyles = makeStyles(theme => ({
    cancelButton: {
        '&.MuiButton-root': {
            background: theme.colors.snackBarWarning,
            '&:hover': {
                background: theme.colors.hoverWarningButton
            }
        }
    }
}));

function CancelTrial({ onClose, onConfirm, isSubmitting, expireDate }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle title="Cancel trial" onClose={onClose} />
            <DialogContent>
                <Typography variant="body2" display="inline">
                    Your trial period is available until
                </Typography>{' '}
                <Typography variant="body1" display="inline">
                    {expireDate}
                </Typography>{' '}
                <Typography variant="body2" display="inline">
                    . Are you sure you want to cancel? If you cancel, you will return to
                </Typography>{' '}
                <Typography variant="body1" display="inline">
                    Free
                </Typography>{' '}
                <Typography variant="body2" display="inline">
                    plan.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isSubmitting}
                            variant="contained"
                            width={120}
                            className={classes.cancelButton}
                            onClick={onConfirm}
                        >
                            {isSubmitting ? <CircularProgress size={24} /> : 'OK'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(CancelTrial);
