import React from 'react';

function AddColumnRightSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/CreateColumnRight">
                <path
                    id="Combined Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.9893 2.52401L13.6714 0L11.3534 2.52401L12.1202 3.35901L13.1584 2.22742L13.1586 5.43767H14.2431L14.2429 2.29237L15.2225 3.35901L15.9893 2.52401ZM4.64763 1.58243C4.59712 1.27076 4.30329 1.03122 3.9498 1.03122H0.714197L0.609865 1.03824C0.269361 1.08448 0.00766715 1.3535 0.00766715 1.67793L0 12.1309L0.00766792 12.2262C0.0581778 12.5373 0.352093 12.7776 0.70653 12.7776H3.94213L4.0462 12.7706C4.38593 12.7242 4.64763 12.4544 4.64763 12.1309L4.65529 1.67793L4.64763 1.58243ZM10.3422 1.58243C10.2917 1.27076 9.99782 1.03122 9.64432 1.03122H6.40873L6.30439 1.03824C5.96389 1.08448 5.7022 1.3535 5.7022 1.67793L5.69453 12.1309L5.7022 12.2262C5.75271 12.5373 6.04662 12.7776 6.40106 12.7776H9.63666L9.74073 12.7706C10.0805 12.7242 10.3422 12.4544 10.3422 12.1309L10.3498 1.67793L10.3422 1.58243ZM3.24327 2.32567H1.41866L1.411 11.4842H3.2356L3.24327 2.32567ZM7.11319 2.32567H8.93779L8.93013 11.4842H7.10553L7.11319 2.32567ZM15.9923 6.73979C15.9418 6.42811 15.648 6.18858 15.2945 6.18858H12.0589L11.9548 6.1956C11.6151 6.24184 11.3534 6.51086 11.3534 6.83529V15.158L11.3611 15.2533C11.4116 15.5643 11.7054 15.8047 12.0589 15.8047H15.2945L15.3986 15.7977C15.7383 15.7513 16 15.4815 16 15.158V6.83529L15.9923 6.73979ZM12.7644 7.48303H14.589V14.5113H12.7644V7.48303Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(AddColumnRightSVG);
