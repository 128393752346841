import { useSelector } from 'react-redux';
import { GRID_UI_TYPE, GRID_STATUS } from 'const/gridUI';
import { useGridStatus } from '../gridUI';

export function useGrid() {
    return useSelector(state => state.grid);
}

export function useSelectedGrid() {
    return useSelector(state => state.grid.selectedGridId);
}

export function useIsSkeletonLoadingId() {
    return useSelector(state => state.grid?.isSkeletonLoadingId);
}

export function useIsShowFirstPopupCreate() {
    return useSelector(state => state.grid?.isShowFirstPopupCreate);
}

export function useDbIdCreatingGrid() {
    return useSelector(state => state.grid?.dbIdCreatingGrid);
}

export function useGridsListDbObject() {
    return useSelector(state => state.grid?.list);
}

export function useGridsByDbId(dbId) {
    const girds = useGridsListDbObject();
    return girds?.[dbId];
}

export function useGridDetail({ dbId, gridId }) {
    const gridsById = useGridsByDbId(dbId);
    return gridsById?.find(grid => grid?.id === gridId);
}

export function useGridTypeDetail({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return gridDetail?.type;
}

export function useGridStatusDetail({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return gridDetail?.status?.toLowerCase();
}

export function useIsGridImporting({ dbId, branchId }) {
    const gridStatus = useGridStatus({ dbId, branchId });
    return gridStatus?.toLowerCase() === GRID_STATUS.UPLOADING || gridStatus?.toLowerCase() === GRID_STATUS.IMPORTING;
}

export function useIsJsonGrid({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return gridDetail?.type === GRID_UI_TYPE.JSON;
}

export function useIsVerticalJSON({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return gridDetail?.type === GRID_UI_TYPE.JSON && gridDetail?.mappingType === 'vertical';
}

export function useIsPoGrid({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return gridDetail?.type === GRID_UI_TYPE.PO;
}

export function useGridTemplates() {
    return useSelector(state => state.grid.templates.list);
}

export function useGridTemplateDetail(id) {
    const templates = useGridTemplates();

    return templates?.find(template => template?.id === id);
}

export function useTemplates() {
    return useSelector(state => state?.grid?.templates) || {};
}
