import React from 'react';

function Plus({ color = '#2DD2A3', width = 16, height = 14, ...other }) {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
            <circle cx="4.5" cy="4.5" r="4.5" fill="#2DD2A3" fillOpacity="0.8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.04232 2.20801H3.95898V3.95801H2.20898V5.04134H3.95898V6.79134H5.04232V5.04134H6.79232V3.95801H5.04232V2.20801Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(Plus);
