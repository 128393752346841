import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import { Avatar, Grid, makeStyles, useTheme } from '@material-ui/core';
import UploadZone from 'components/upload/Base';
import { getUploadErrorMessage } from 'utils/upload';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import PopperMenu from 'components/menus/Popper';
import FolderUploadSVG from 'assets/images/svg/FolderUploadSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import Tooltip from 'components/tooltip/Base';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import i18n from 'i18n';
import InputText from 'components/inputs/InputText';
import { INPUT_HEIGHT } from 'const/style';
import Button from 'components/button/Base';
import CircularProgress from 'components/CircularProgress';
import { useTranslation } from 'react-i18next';
import { getAutomationLogoUrl } from 'utils/images';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: { width: 450, padding: 20 },
    mb8: { marginBottom: 8 },
    mb20: { marginBottom: 20 },
    mr10: { marginRight: 10 },
    mr12: { marginRight: 12 },
    mr20: { marginRight: 20 },
    avatarHint: {
        fontSize: 12,
        color: theme.colors.steel,
        '&.error': {
            color: theme.colors.brightRed
        }
    },
    box: {
        padding: 0,
        borderRadius: `50%`,
        background: theme.colors.border,
        border: `2px dashed ${theme.colors.disabledGrey}`,
        width: 40,
        height: 40,
        '&.noBorder': {
            border: 0
        }
    },
    avatar: {
        backgroundColor: 'transparent'
    },
    relative: {
        position: 'relative',
        '&:hover': {
            '& .avatarBackdrop': {
                display: 'flex'
            }
        }
    },
    avatarBackdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        background: `rgba(0, 0, 0, 0.6)`,
        zIndex: 1,
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transform: `opacity 0.3 ease-in`,
        '&.active': {
            display: 'flex'
        }
    },
    paperClassName: {
        padding: `12px 0`
    },
    inputFile: {
        padding: 0,
        justifyContent: 'baseline',
        textAlign: 'start'
    },
    popperImgItem: {
        width: `100%`,
        padding: `0 18px`,
        height: 36,
        cursor: 'pointer',
        '& svg': {
            verticalAlign: 'text-top'
        },
        '&:hover': {
            background: theme.colors.ghostwhite
        },
        '&:not(:first-child)': {
            marginTop: 12
        }
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        height: INPUT_HEIGHT,
        padding: 0,
        paddingLeft: 8,
        fontSize: '0.875rem',
        fontWeight: 'normal',
        width: '100%',
        borderRadius: 4,
        background: theme.colors.paleGrey
    },
    btnSubmit: {
        width: 140,
        position: 'relative'
    }
}));

const AUTOMATION_LOGO_FILE_MAX_SIZE = 500 * 1000; //500Kb
const AUTOMATION_UPLOAD_SUPPORTED_EXTENSIONS = ['.png', '.svg', '.jpg', '.jpeg', '.PNG', '.SVG', '.JPG', '.JPEG'];

const CreateSchema = object({
    title: string().required(i18n.t(`global_this_field_is_required`)),
    description: string().max(160, 'Description must be at most 160 characters')
});

const stopPropagation = e => {
    if (e) {
        e.stopPropagation();
    }
};
const stopPropagationPrevent = e => {
    if (e) {
        stopPropagation(e);
        e.preventDefault();
    }
};

const AutomationAddForm = ({ automation, isSaving, onSave, onCancel }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [avatar, setAvatar] = useState(null);
    const [previewAvatar, setPreviewAvatar] = useState(
        automation?.iconFileId ? getAutomationLogoUrl(automation.iconFileId) : null
    );
    const [openImg, setOpenImg] = useState(false);
    const anchorImgEl = useRef();

    const handleClickImg = useCallback(e => {
        stopPropagationPrevent(e);
        setOpenImg(true);
    }, []);

    const handleClickAwayImg = useCallback(e => {
        setOpenImg(false);
    }, []);

    const onDropRejected = useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, AUTOMATION_UPLOAD_SUPPORTED_EXTENSIONS);
            let fileError = fileErrors?.[0];
            if (rejectedFiles?.[0]?.errors?.[0]?.code === 'file-too-large') {
                fileError = ['File size is larger than 500Kb'];
            }
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'error'
                })
            );
        },
        [dispatch]
    );

    const onDropAccepted = useCallback(
        async acceptedFiles => {
            setPreviewAvatar(URL.createObjectURL(acceptedFiles[0]));
            setAvatar(acceptedFiles[0]);
            handleClickAwayImg();
        },
        [handleClickAwayImg]
    );

    const handleRemoveAvatar = useCallback(
        e => {
            setAvatar(null);
            setPreviewAvatar(null);
            handleClickAwayImg();
        },
        [handleClickAwayImg]
    );

    const openUpload = useCallback(() => {
        const input = document.querySelector('#uploadzone-wrapper input');
        input.click();
    }, []);

    const formik = useFormik({
        initialValues: {
            title: automation?.title || '',
            description: automation?.description || ''
        },
        validationSchema: CreateSchema,
        onSubmit: values => {
            onSave({
                ...values,
                file: avatar
            });
        }
    });
    const { errors, touched, values, handleChange, handleBlur, handleSubmit, isValid } = formik;

    return (
        <Grid item className={classes.root}>
            <Grid item container justifyContent="center" className={classes.mb20}>
                <Grid item>
                    <h4 className="prose prose-lg font-medium">Automation detail</h4>
                </Grid>
            </Grid>
            <Grid item container wrap="nowrap" alignItems="center" className={classes.mb20}>
                <Grid item className={classes.mr20}>
                    <p className="body1">Avatar</p>
                </Grid>
                <Grid id="uploadzone-wrapper" item className={classnames(classes.mr12, classes.relative)}>
                    <UploadZone
                        className={classnames(classes.box, {
                            noBorder: !!previewAvatar
                        })}
                        accept={AUTOMATION_UPLOAD_SUPPORTED_EXTENSIONS}
                        maxSize={AUTOMATION_LOGO_FILE_MAX_SIZE}
                        onDropRejected={onDropRejected}
                        onDropAccepted={onDropAccepted}
                    >
                        <Avatar className={classes.avatar} src={previewAvatar}>
                            <AddIconSVG width="9" height="9" color="#ACADB9" />
                        </Avatar>
                    </UploadZone>
                    {!!previewAvatar && (
                        <>
                            <Grid
                                ref={anchorImgEl}
                                item
                                className={classnames(classes.avatarBackdrop, 'avatarBackdrop', {
                                    active: !!openImg
                                })}
                                onClick={handleClickImg}
                            >
                                <EditSVG color="#FFFFFF" />
                            </Grid>
                            {openImg && (
                                <PopperMenu
                                    placement="bottom-end"
                                    className={classes.popperRoot}
                                    paperClassName={classes.paperClassName}
                                    anchorEl={anchorImgEl.current}
                                    handleClickAway={handleClickAwayImg}
                                    onPaperClick={stopPropagation}
                                >
                                    <Grid item className={classes.popperImgWrapper}>
                                        <Grid item className={classes.popperImgItem} onClick={openUpload}>
                                            <Grid
                                                container
                                                wrap="nowrap"
                                                alignItems="center"
                                                spacing={2}
                                                style={{ height: '100%' }}
                                            >
                                                <Grid item>
                                                    <FolderUploadSVG />
                                                </Grid>
                                                <Grid item className={classes.flx}>
                                                    <p className="body2">Upload photo</p>
                                                </Grid>
                                                <Tooltip title="Available with format PNG, SVG, JPG, JPEG. Maximum size is 500Kb">
                                                    <Grid item>
                                                        <InfoIconSVG size={18} />
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item className={classes.popperImgItem} onClick={handleRemoveAvatar}>
                                            <Grid
                                                container
                                                wrap="nowrap"
                                                alignItems="center"
                                                spacing={2}
                                                style={{ height: '100%' }}
                                            >
                                                <Grid item>
                                                    <DeleteSVG color={theme.colors.steel} />
                                                </Grid>
                                                <Grid item className={classes.flx}>
                                                    <p className="body2">Remove photo</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PopperMenu>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item>
                    <p className={classNames(`text-[12px]`)}>
                        Available with format PNG, SVG, JPG, JPEG. Maximum size is 500Kb
                    </p>
                </Grid>
            </Grid>
            <Grid item>
                <form onSubmit={handleSubmit} className={classes.form}>
                    <Grid item className={classes.mb20}>
                        <InputText
                            name="title"
                            label="Automation name"
                            required
                            value={values.title || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.title && touched.title}
                            errorText={errors.title}
                            placeholder="Enter your automation name"
                        />
                    </Grid>
                    <Grid item className={classes.mb20}>
                        <InputText
                            multiline
                            rows={5}
                            height={104}
                            name="description"
                            label="Automation description"
                            value={values.description || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.description && touched.description}
                            errorText={errors.description}
                            placeholder="Enter your automation description"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
                            <Grid item className={classes.mr20}>
                                <Button width={150} onClick={onCancel} variant="outlined">
                                    {t(`global_cancel`)}
                                </Button>
                            </Grid>
                            <Grid item className={classes.btnSubmit}>
                                <Button fullWidth type="submit" variant="contained" disabled={!isValid || isSaving}>
                                    {t('global_save')}
                                </Button>
                                {isSaving && <CircularProgress />}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default React.memo(AutomationAddForm);
