import * as columnTypes from 'const/columnTypes';
import { MAX_ROW_RESIZE, ROW_HEIGHT, TEXT_LINE_HEIGHT, CELL_PADDING, FAKE_ROW, BORDER_HIGHLIGHT } from 'const/gridUI';
import { OPTION_SPACING } from 'const/style';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { FILE_RATIO, FILE_SPACING, ROW_HEIGHT_OPTIONS } from 'const/gridUI';
import { TEXT_COMPONENTS, SELECTION_COMPONENTS, SUPPORT_TEXT_WRAPPING_COMPONENTS, EDITOR_COMPONENTS } from 'const';
import { generateAvatarWithHeight } from 'utils/images';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { isLDEmpty } from 'utils/object';
import now from 'performance-now';
import { getRowHeightMetadata } from 'utils/gridUI/recordMetadata';
import { getViewColumnWidthByColumnId } from './column';

export function isHeightsChanged({ newRecordMetaData, recordMetaData = {} }) {
    let isChanged = false;
    const rowIds = Object.keys(newRecordMetaData);
    for (let i = 0; i < rowIds.length; i++) {
        const rowId = rowIds[i];
        const rowHeight = getRowHeightMetadata({ recordMetaData: newRecordMetaData, rowId });
        if (rowHeight !== (getRowHeightMetadata({ recordMetaData, rowId }) || ROW_HEIGHT)) {
            isChanged = true;
            break;
        }
    }
    return isChanged;
}

// export function getTotalRowsHeight({ recordMetaData = {}, totalRecords, rows = [] }) {
//     const currentRowsHeight = rows?.reduce((total, rowId) => {
//         total += getRowHeightMetadata({ recordMetaData, rowId }) || ROW_HEIGHT;
//         return total;
//     }, 0);

//     const totalRowHeightLefts = (totalRecords - rows?.length) * ROW_HEIGHT;
//     return totalRowHeightLefts + currentRowsHeight;
// }

export function getTotalRowsHeight({ rowHeight = ROW_HEIGHT_OPTIONS.DEFAULT, totalRecords }) {
    return totalRecords * rowHeight + FAKE_ROW * ROW_HEIGHT;
}

const MULTI_LINES_TEXT = [
    columnTypes.MULTIPLE_LINES,
    columnTypes.JSON_LD,
    columnTypes.HTML,
    columnTypes.YAML,
    columnTypes?.TRANSLATION
];

export function getHeight({ width, content, columnType = columnTypes.MULTIPLE_LINES }) {
    const isMultipleLines = MULTI_LINES_TEXT.includes(columnType);
    const $cell = isMultipleLines ? document.getElementById('scl-pre') : document.getElementById('scl-div');
    let height = ROW_HEIGHT;
    $cell.style.width = `${width}px`;
    $cell.style.padding = CELL_PADDING;
    $cell.style.border = `${BORDER_HIGHLIGHT}px solid transparent`;
    $cell.style.fontSize = '14px';
    $cell.style.whiteSpace = `pre-wrap`;
    $cell.style.wordWrap = `break-word`;
    $cell.style.wordBreak = `break-word`;
    $cell.style.margin = `0px`;
    $cell.style.fontFamily = 'Roboto !important';
    $cell.style.lineHeight = `${TEXT_LINE_HEIGHT}px`;
    $cell.style.boxSizing = 'border-box';
    $cell.textContent = content;
    $cell.style.position = 'absolute';
    $cell.style.zIndex = -1;
    height = $cell.offsetHeight;
    $cell.textContent = '';
    return Math.max(height, ROW_HEIGHT);
}

export function getEditorElementHeight({ width, content, columnType }) {
    const $cell = document.getElementById(`scl-pre`);
    let height = ROW_HEIGHT;
    $cell.style.width = `${width}px`;
    $cell.style.padding = CELL_PADDING;
    $cell.style.border = `${BORDER_HIGHLIGHT}px solid transparent`;
    $cell.style.fontSize = '14px';
    $cell.style.wordWrap = `break-word`;
    $cell.style.wordBreak = `break-word`;
    $cell.style.margin = `0px`;
    $cell.style.fontFamily = 'Roboto !important';
    $cell.style.lineHeight = `16px`;
    $cell.style.boxSizing = 'border-box';
    $cell.textContent = content;
    $cell.style.position = 'absolute';
    $cell.style.zIndex = -1;
    height = $cell.offsetHeight;
    $cell.textContent = '';
    return Math.max(height, ROW_HEIGHT);
}

export function getSelectionElementHeight({ width, content, columnType = columnTypes.MULTIPLE_SELECTIONS }) {
    const isSingleSelection = columnType === columnTypes.SINGLE_SELECTION;
    if (isSingleSelection) return ROW_HEIGHT;

    const contentFormat = _generateSelectionContent(content);
    const $cell = document.getElementById(`scl-pre`);
    let height = ROW_HEIGHT;
    $cell.style.width = `${width}px`;
    $cell.style.padding = CELL_PADDING;
    $cell.style.border = `${BORDER_HIGHLIGHT}px solid transparent`;
    $cell.style.fontSize = '14px';
    $cell.style.whiteSpace = `pre-wrap`;
    $cell.style.wordWrap = `break-word`;
    $cell.style.wordBreak = `break-word`;
    $cell.style.margin = `0px`;
    $cell.style.fontFamily = 'Roboto !important';
    $cell.style.lineHeight = `${TEXT_LINE_HEIGHT}px`;
    $cell.innerHTML = contentFormat;
    $cell.style.position = 'absolute';
    $cell.style.display = 'flex';
    $cell.style.flexWrap = 'wrap';
    $cell.style.zIndex = -1;

    let children = $cell.children;
    for (let i = 0; i < children.length; i++) {
        if (children[i].tagName === 'DIV') {
            children[i].style.background = 'white';
            children[i].style.marginRight = `5px`;
            children[i].style.marginBottom = `5px`;
            children[i].style.lineHeight = `${TEXT_LINE_HEIGHT}px`;
            children[i].style.borderRadius = `3px`;
            children[i].style.height = `20px`;
            children[i].style.paddingRight = `${OPTION_SPACING}px`;
            children[i].style.paddingLeft = `${OPTION_SPACING}px`;
            children[i].style.fontSize = `14px`;
        }
    }

    height = $cell.offsetHeight;
    $cell.innerHTML = '';
    return height > ROW_HEIGHT ? Math.max(height - OPTION_SPACING, ROW_HEIGHT) : ROW_HEIGHT;
}

export function getImageElementHeight({ width, content }) {
    let height = ROW_HEIGHT;
    if (!isArray(content)) return height;
    const itemDimension = generateAvatarWithHeight({ columnWidth: width, ratio: FILE_RATIO });
    const $cell = document.getElementById('scl-div');

    [...Array(content?.length).keys()].forEach(i => {
        let div = document.createElement('div');
        div.className = 'child';
        div.style.width = `${itemDimension?.width}px`;
        div.style.height = `${itemDimension?.height}px`;
        div.style.backgroundColor = 'green';
        div.style.margin = `${FILE_SPACING * 2}px`;
        div.style.display = `inline-block`;
        $cell.appendChild(div);
    });

    $cell.style.width = `${width}px`;
    $cell.style.padding = `5px 10px`;
    $cell.style.border = `${BORDER_HIGHLIGHT}px solid transparent`;
    $cell.style.whiteSpace = `pre-wrap`;
    $cell.style.wordWrap = `break-word`;
    $cell.style.wordBreak = `break-word`;
    $cell.style.margin = `0px`;
    $cell.style.fontFamily = 'Roboto !important';
    $cell.style.boxSizing = 'border-box';
    $cell.style.display = 'flex';
    $cell.style.flexWrap = 'wrap';
    $cell.style.position = 'absolute';
    $cell.style.zIndex = -1;
    height = $cell.offsetHeight;
    $cell.innerHTML = '';
    return Math.max(height - 5, ROW_HEIGHT);
}

export function getPathTagElementHeight({ width, content, columnType }) {
    const contentFormat = _generatePathTagContent(content);
    const $cell = document.getElementById('scl-pre');
    let height = ROW_HEIGHT;
    $cell.style.width = `${width}px`;
    $cell.style.padding = CELL_PADDING;
    $cell.style.border = `${BORDER_HIGHLIGHT}px solid transparent`;
    $cell.style.fontSize = '14px';
    $cell.style.whiteSpace = `pre-wrap`;
    $cell.style.wordWrap = `break-word`;
    $cell.style.wordBreak = `break-word`;
    $cell.style.margin = `0px`;
    $cell.style.fontFamily = 'Roboto !important';
    $cell.style.lineHeight = `${TEXT_LINE_HEIGHT}px`;
    $cell.innerHTML = contentFormat;
    $cell.style.position = 'absolute';
    $cell.style.display = 'flex';
    $cell.style.flexWrap = 'wrap';
    $cell.style.zIndex = -1;

    let children = $cell.children;
    for (let i = 0; i < children.length; i++) {
        if (children[i].tagName === 'DIV') {
            children[i].style.background = 'white';
            children[i].style.marginBottom = `5px`;
            children[i].style.lineHeight = `${TEXT_LINE_HEIGHT}px`;
            children[i].style.borderRadius = `3px`;
            children[i].style.height = `20px`;
            children[i].style.paddingRight = `${OPTION_SPACING}px`;
            children[i].style.paddingLeft = `${OPTION_SPACING}px`;
            children[i].style.fontSize = `14px`;
        }
        if (children[i].tagName === 'SPAN') {
            children[i].style.marginLeft = `5px`;
            children[i].style.marginRight = `5px`;
            children[i].style.lineHeight = `${TEXT_LINE_HEIGHT}px`;
            children[i].style.borderRadius = `3px`;
            children[i].style.height = `20px`;
            children[i].style.fontSize = `14px`;
        }
    }
    height = $cell.offsetHeight;
    $cell.innerHTML = '';
    return height > ROW_HEIGHT ? Math.max(height - OPTION_SPACING, ROW_HEIGHT) : ROW_HEIGHT;
}

export function getHeightRichText({ width, content }) {
    const $cell = document.createElement('scl-div');
    let height = ROW_HEIGHT;
    $cell.style.width = `${width}px`;
    $cell.style.fontSize = '14px';
    $cell.style.fontFamily = 'roboto';
    $cell.style.padding = CELL_PADDING;
    $cell.style.border = `${BORDER_HIGHLIGHT}px solid transparent`;
    $cell.innerHTML = content;
    $cell.style.color = 'white';
    $cell.style.position = 'absolute';
    $cell.style.zIndex = -1;
    $cell.style.whiteSpace = `pre-wrap`;
    $cell.style.wordWrap = `break-word`;
    $cell.style.wordBreak = `break-word`;

    let children = $cell.children;
    for (let i = 0; i < children.length; i++) {
        if (children[i].tagName === 'P') {
            children[i].style.color = 'white';
            children[i].style.marginTop = 0;
            children[i].style.marginBottom = 0;
            children[i].style.lineHeight = `22px`;
        }

        if (children[i].tagName === 'UL' || children[i].tagName === 'OL') {
            children[i].style.color = 'red';
            children[i].style.marginBottom = `14px`;
            children[i].style.marginTop = `0px`;
            children[i].style.borderRadius = `4px`;
            children[i].style.lineHeight = `22px`;

            let otherChildren = children[i].children;
            if (otherChildren && otherChildren.length);
            for (let i = 0; i < otherChildren.length; i++) {
                if (otherChildren[i].tagName === 'LI') {
                    otherChildren[i].style.color = 'green';
                    otherChildren[i].style.marginBottom = '3px';
                }
            }
        }

        if (children[i].tagName === 'BLOCKQUOTE') {
            children[i].style.color = 'blue';
            children[i].style.margin = `0px 0px 14px`;
            children[i].style.padding = `12px 24px`;
            children[i].style.borderLeft = `2px solid blue`;
            children[i].style.backgroundColor = `white`;
            children[i].style.borderRadius = `4px`;
            children[i].style.lineHeight = `22px`;
        }
    }

    height = $cell.offsetHeight;
    $cell.innerHTML = '';
    return Math.max(height, ROW_HEIGHT);
}

function _generateSelectionContent(content) {
    if (!content) return content;

    if (content instanceof Array) {
        return content?.map(option => `<div>${option}</div>`).join('');
    }

    return `<div>${content}</div>`;
}

function _generatePathTagContent(content) {
    if (!content) return content;

    if (typeof content !== 'string' && typeof content !== 'number') {
        return '';
    }

    let pathArr = content.split('/');

    return pathArr
        ?.map((path, index) => `<div>${path}</div>${index !== pathArr?.length - 1 ? `<span>/</span>` : ``}`)
        ?.join('');
}

export function getHeightOfRecordsAndColumns({ rowIds, columnIds, metaData, data, viewColumns }) {
    let newRecordMetaData = {};
    for (const rowId of rowIds) {
        const heightStore = [];
        for (const columnId of columnIds) {
            const columnType = metaData?.[columnId]?.type;
            if (SUPPORT_TEXT_WRAPPING_COMPONENTS.includes(columnType)) {
                const columnWidth = getViewColumnWidthByColumnId({ viewColumns, columnId });

                const content = getCellValueOnly({ data, rowId, columnId });

                if (isLDEmpty(content)) {
                    heightStore.push(ROW_HEIGHT);
                } else {
                    let height = ROW_HEIGHT;
                    if (TEXT_COMPONENTS.includes(columnType)) {
                        height = [columnTypes.RICH_TEXT, columnTypes.MARKDOWN].includes(columnType)
                            ? getHeightRichText({
                                  width: columnWidth,
                                  content
                              })
                            : getHeight({
                                  width: columnWidth,
                                  content,
                                  columnType
                              });
                    } else if (EDITOR_COMPONENTS.includes(columnType)) {
                        height = getEditorElementHeight({ width: columnWidth, content, columnType });
                    } else if (SELECTION_COMPONENTS.includes(columnType)) {
                        height = getSelectionElementHeight({ width: columnWidth, content, columnType });
                    } else if (columnTypes.PATH_TAG === columnType) {
                        height = getPathTagElementHeight({ width: columnWidth, content, columnType });
                    } else if (columnTypes.FILES === columnType) {
                        height = getImageElementHeight({ width: columnWidth, content });
                    }

                    heightStore.push(height);
                }
            }
        }

        if (!(isArray(heightStore) && isEmpty(heightStore))) {
            if (!newRecordMetaData?.[rowId]?._metadata?.value) {
                newRecordMetaData[rowId] = {
                    _metadata: {
                        value: {}
                    }
                };
            }

            newRecordMetaData[rowId]['_metadata']['value']['_rowHeight'] = Math.min(
                Math.max.apply(Math, heightStore),
                MAX_ROW_RESIZE
            );
        }
    }

    return newRecordMetaData;
}

export function getDynamicRowsHeightMetaData({ gridUI, rowIds }) {
    const { viewColumns, data, metaData, recordMetaData } = gridUI;

    let rowIdNeedExpands = rowIds?.filter(rowId => !getRowHeightMetadata({ recordMetaData, rowId }));
    const start = now();

    const columnIds = viewColumns?.filter(col => col.viewable)?.map(column => column.id);
    const newRecordMetaData = getHeightOfRecordsAndColumns({
        rowIds: rowIdNeedExpands,
        columnIds,
        data,
        metaData,
        viewColumns
    });
    const end = now();
    console.log('CALCULATE ROW_HEIGHT TIME', (end - start).toFixed(3));

    return newRecordMetaData;
}
