import React, { useEffect } from 'react';
import store from '../store/configStore';
import { enqueueSnackbar } from 'notifier/actions';
import { DASHBOARD, GRID_UI } from '../const';
import * as gridUIActions from '../gridUI/actions';
// import * as databasesActions from '../databases/actions';
import * as appActions from 'app/actions';
import ws from '../socket';
import { useCurrentUserId } from 'hooks/auth';
import { useParams, useHistory } from 'react-router-dom';
import { useCompanyId } from 'hooks/auth';

const { dispatch, getState } = store;

function UseNetworking() {
    const { branchId, viewId, dbId, gridId } = useParams();
    const history = useHistory();
    const userId = useCurrentUserId();
    const companyId = useCompanyId();

    useEffect(() => {
        const handlerOffline = function() {
            dispatch(
                enqueueSnackbar({
                    message: `No internet connection`,
                    type: 'info'
                })
            );
            dispatch(appActions.toggleDisabledSnackbar());
        };
        window.addEventListener('offline', handlerOffline);

        const handlerOnline = function() {
            console.log('CONNECTED ONLINE MODE');
            dispatch(appActions.toggleDisabledSnackbar());

            ws.disconnect();
            const state = getState();
            const currentPage = state?.app?.currentPage;

            if (currentPage === GRID_UI) {
                const ROW_START_INDEX = state?.gridUI?.ROW_START_INDEX;
                const ROW_STOP_INDEX = state?.gridUI?.ROW_STOP_INDEX;
                dispatch(
                    gridUIActions.renderGridUITableAfterNetworkConnected({
                        successCallback: () => {
                            dispatch(
                                gridUIActions.fetchViewFilters({
                                    successCallback: () => {
                                        console.log('fetching view filters done');
                                    }
                                })
                            );

                            dispatch(
                                gridUIActions.fetchViewSorts({
                                    successCallback: () => {
                                        console.log('fetching view sorts done');
                                    }
                                })
                            );
                            ws.gridlySubscribeTopics({
                                companyId,
                                userId,
                                dbId,
                                viewId,
                                parentGridId: gridId,
                                gridId: branchId,
                                ROW_START_INDEX,
                                ROW_STOP_INDEX
                            });
                        }
                    })
                );
            }

            if (currentPage === DASHBOARD) {
                dispatch(
                    appActions.dashboardReconnecting({
                        history,
                        errorCallback: err => {
                            console.log(err);
                        },
                        successCallback: () => {
                            ws.dashboardSubscribeTopics({ userId, companyId });
                        }
                    })
                );
            }
        };
        window.addEventListener('online', handlerOnline);

        return () => {
            window.removeEventListener('offline', handlerOffline);
            window.removeEventListener('online', handlerOnline);
        };
    }, [viewId, dbId, branchId, userId, history, gridId, companyId]);
    return null;
}

export default React.memo(UseNetworking);
