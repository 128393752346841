import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import { AutoSizer } from 'react-virtualized-dn';
import * as groupActions from '../actions/group';
import Table from 'components/tables/Table';
import DropdownMenu from 'components/menus/DropdownMenu';
import KickoutSVG from 'assets/images/svg/KickoutSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import { StatusIcon } from '../members/Icons';
import SystemRoleFlag from 'permission/roles/SystemRoleFlag';
import { DEFAULT_FIRST_TABLE_COLUMN_WIDTH } from 'const/style';
import CardInfo from 'components/cardInfo/Base';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { getAvatarUrl } from 'utils/images';
import {
    DEFAULT_ROLE_OWNER,
    DEFAULT_ROLE_ADMIN,
    ACTIVE,
    INACTIVE,
    PENDING_INVITE,
    PENDING_APPROVAL,
    GROUP_ADMIN_TYPES,
    ALL_LANGUAGES
} from 'const';
import { useSelectedGroupId } from 'hooks/permission/group';
import { sendManualTrack } from 'tracker';
import { generateUserBadge } from 'utils/user';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { isOwner } from 'utils/permission/role';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import { useTranslation, Trans } from 'react-i18next';
import LanguagePair from 'components/tutorial/LanguagePairTutorial';
import Tooltip from 'components/tooltip/Base';
import i18n from 'i18n';

const USER_ACTION_OPTIONS = [
    { value: 'remove', label: i18n.t('remove_from_group'), isDanger: true, icon: <KickoutSVG /> }
];

const useStyles = makeStyles(theme => ({
    table: {
        border: '1px solid #e9eaef',
        borderRadius: 4,
        overflow: 'hidden',
        color: theme.colors.primaryText,
        '& .header-role': {
            marginLeft: 15
        },
        '& .cell-role': {
            paddingLeft: 15,

            '& .select': {
                marginLeft: -15
            }
        }
    },
    nowrap: {
        flexWrap: 'nowrap'
    },
    roleSelect: {
        width: '100%'
    },
    moreActionsButton: {
        height: 40,
        width: 40,
        borderRadius: '100%',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.paleNavy
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right'
    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    arrow: {
        margin: theme.spacing(0, 2)
    }
}));

function GroupMemberTable({ members = [] }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedGroupId = useSelectedGroupId();
    const [sorting, setSorting] = useState({});
    const userInfo = useCurrentUserFullInfo();
    const isCurrentOwner = isOwner({ currentUser: userInfo });

    const data = useMemo(() => {
        const cloneData = [...members].sort(
            (a, b) => new Date(b?.audit?.createdDate) - new Date(a?.audit?.createdDate)
        );
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                if (sortKey === 'role') {
                    valueA = valueA ? valueA.name : '';
                    valueB = valueB ? valueB.name : '';
                }

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, members]);

    const nameRenderer = ({ rowData }) => {
        const { fullName, email, state, audit, imageUrl } = rowData;
        const createdName = audit?.createdName;

        switch (state) {
            case ACTIVE:
            case INACTIVE:
                return (
                    <CardInfo
                        avatar={
                            <AvatarBase
                                src={getAvatarUrl(imageUrl)}
                                size="small"
                                alt="avatar"
                                badgeContent={generateUserBadge(rowData)}
                            />
                        }
                        title={fullName}
                        subTitle={email}
                    />
                );

            default:
                return (
                    <CardInfo
                        avatar={
                            <AvatarBase
                                src={getAvatarUrl(imageUrl)}
                                size="small"
                                alt="avatar"
                                badgeContent={generateUserBadge(rowData)}
                            />
                        }
                        title={email}
                        subTitle={
                            <Trans i18nKey="group_member_invited_by" t={t} createdName={createdName}>
                                Invited by {{ createdName }}
                            </Trans>
                        }
                    />
                );
        }
    };
    const roleRenderer = ({ rowData, isSlected }) => {
        const { role } = rowData;

        return (
            <Grid container spacing={2} wrap="nowrap" alignItems="center">
                {role?.isSystemRole && (
                    <Grid item style={{ display: 'flex' }}>
                        <SystemRoleFlag role={role} />
                    </Grid>
                )}
                <Grid item>
                    <Typography variant="body2">{role?.name}</Typography>
                </Grid>
            </Grid>
        );
    };

    const LanguageRenderer = ({ rowData }) => {
        const { languagePairs } = rowData;
        const { t } = useTranslation();

        return (
            <div className="flex flex-col gap-3 py-3">
                {languagePairs?.map(({ from, to }, index) => (
                    <div key={index} className="flex flex-row items-center gap-1">
                        {from !== ALL_LANGUAGES && (
                            <CountryFlag
                                languageCountryCode={from}
                                style={{ width: 30, height: 'auto', borderRadius: 3 }}
                            />
                        )}
                        <p className="body2 inline">{from === ALL_LANGUAGES ? t('global_all') : from}</p>
                        <PathSVG />
                        {to !== ALL_LANGUAGES && (
                            <CountryFlag
                                languageCountryCode={to}
                                style={{ width: 30, height: 'auto', borderRadius: 3 }}
                            />
                        )}
                        <p className="body2 inline">{to === ALL_LANGUAGES ? t('global_all') : to}</p>
                    </div>
                ))}
            </div>
        );
    };

    const statusRenderer = ({ rowData }) => {
        const { state } = rowData;

        const MEMBER_STATE_MAPPING = {
            [ACTIVE]: t('active'),
            [INACTIVE]: t('inactive'),
            [PENDING_APPROVAL]: t('pending_approval'),
            [PENDING_INVITE]: 'Pending invite'
        };

        return (
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                    <StatusIcon status={state} />
                </Grid>
                <Grid item>
                    <Typography variant="body2">{MEMBER_STATE_MAPPING[state] || state}</Typography>
                </Grid>
            </Grid>
        );
    };

    const actionRenderer = ({ rowData }) => {
        const { id, role, groups } = rowData;

        const handleRemoveUserOutOfGroup = () => {
            sendManualTrack({ type: `Remove Member From Group` });
            dispatch(
                groupActions.removeUserOutOfGroup({
                    userId: id
                })
            );
        };

        const handleActionChange = option => {
            switch (option.value) {
                case 'remove':
                    handleRemoveUserOutOfGroup();
                    break;
                default:
                    break;
            }
        };
        const isOwner = role.id === DEFAULT_ROLE_OWNER.id;
        const isAdmin = role.id === DEFAULT_ROLE_ADMIN.id;
        const selectedGroup = groups?.find(group => group.id === selectedGroupId);
        const isFullAccessGroup = selectedGroup?.systemGroup && selectedGroup?.type === GROUP_ADMIN_TYPES.ADMIN;

        if (isFullAccessGroup && (isOwner || (!isCurrentOwner && isAdmin))) return;
        return (
            <DropdownMenu
                onChange={handleActionChange}
                options={USER_ACTION_OPTIONS}
                className={classes.moreActionsButton}
                placement={'bottom-end'}
            >
                <div className={classes.moreActionsButton}>
                    <IconMoreActionsSVG />
                </div>
            </DropdownMenu>
        );
    };

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const COLUMNS = [
        { dataKey: 'empty', width: DEFAULT_FIRST_TABLE_COLUMN_WIDTH, cellRenderer: null },
        { label: t('global_name'), dataKey: 'fullName', width: 350, sort: true, cellRenderer: nameRenderer },
        { label: t('company_role'), dataKey: 'role', width: 180, flexGrow: 1, sort: true, cellRenderer: roleRenderer },
        {
            label: (
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item>{t('global_language_pairs')}</Grid>
                    <Tooltip title={<LanguagePair />}>
                        <Grid item style={{ display: 'flex' }}>
                            <InfoIconSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
            ),
            dataKey: 'language',
            width: 300,
            cellRenderer: props => <LanguageRenderer {...props} />
        },
        {
            label: t('global_status'),
            dataKey: 'state',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: statusRenderer
        },
        { label: '', dataKey: 'id', width: 150, cellRenderer: actionRenderer }
    ];

    return (
        <div className={classes.table} style={{ height: '55vh', width: '100%' }}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={data}
                        columns={COLUMNS}
                        width={width}
                        height={height}
                        onRowClick={handleRowClick}
                        onSort={handleOnSort}
                    ></Table>
                )}
            </AutoSizer>
        </div>
    );
}

export default React.memo(GroupMemberTable);
