import React from 'react';
import Checkbox from 'components/checkbox/Base';
import { makeStyles } from '@material-ui/core/styles';
import { ROW_HEIGHT } from 'const/gridUI';
import * as gridUIActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    }
}));

function Boolean({ value: cellValue, rowId, columnId, isReadOnly }) {
    const checked = cellValue ? true : false;
    const classes = useStyles({ isReadOnly });
    const dispatch = useDispatch();

    const handleClick = e => {
        stopPropagation(e);
        if (isReadOnly) return;
        dispatch(gridUIActions.changeBooleanStatus({ rowId, columnId }));
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className={classes.root} onClick={handleClick}>
            <Checkbox
                checked={checked}
                style={{
                    maxWidth: 24,
                    maxHeight: 24
                }}
            />
        </div>
    );
}

export default React.memo(Boolean);
