import React from 'react';

function EditPaperSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M14.1827 10.2146V2.22056C14.1827 1.48473 13.5862 0.888225 12.8504 0.888225H3.07994C2.34411 0.888225 1.7476 1.48473 1.7476 2.22056V13.7675C1.7476 14.5033 2.34411 15.0998 3.07994 15.0998H9.29751C10.0333 15.0998 10.6298 14.5033 10.6298 13.7675C10.6298 12.5411 11.624 11.5469 12.8504 11.5469C13.5862 11.5469 14.1827 10.9504 14.1827 10.2146ZM13.7399 12.2498C13.4676 12.369 13.1667 12.4351 12.8504 12.4351C12.1146 12.4351 11.5181 13.0317 11.5181 13.7675C11.5181 14.0838 11.4519 14.3846 11.3327 14.657C12.3951 14.1695 13.2524 13.3122 13.7399 12.2498ZM0.859375 2.22056C0.859375 0.994179 1.85355 0 3.07994 0H12.8504C14.0768 0 15.071 0.994179 15.071 2.22056V10.2146C15.071 13.4032 12.4861 15.988 9.29751 15.988H3.07994C1.85355 15.988 0.859375 14.9939 0.859375 13.7675V2.22056ZM4.85639 5.32935C4.61111 5.32935 4.41227 5.13051 4.41227 4.88524C4.41227 4.63996 4.61111 4.44112 4.85639 4.44112H11.074C11.3192 4.44112 11.5181 4.63996 11.5181 4.88524C11.5181 5.13051 11.3192 5.32935 11.074 5.32935H4.85639ZM4.85639 7.99402C4.61111 7.99402 4.41227 7.79519 4.41227 7.54991C4.41227 7.30463 4.61111 7.1058 4.85639 7.1058H11.074C11.3192 7.1058 11.5181 7.30463 11.5181 7.54991C11.5181 7.79519 11.3192 7.99402 11.074 7.99402H4.85639ZM4.85639 10.6587C4.61111 10.6587 4.41227 10.4599 4.41227 10.2146C4.41227 9.96931 4.61111 9.77047 4.85639 9.77047H9.29751C9.54278 9.77047 9.74162 9.96931 9.74162 10.2146C9.74162 10.4599 9.54278 10.6587 9.29751 10.6587H4.85639Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(EditPaperSVG);
