import React from 'react';

function DoneSVG({ ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="#96C060"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5897 6.31019C14.9719 6.62839 15.0235 7.1959 14.7051 7.57775L9.70079 13.5778C9.53877 13.772 9.30244 13.8892 9.04962 13.9007C8.7968 13.9121 8.55081 13.8168 8.37186 13.638L5.3693 10.638C5.01753 10.2865 5.01753 9.71666 5.3693 9.36519C5.72107 9.01372 6.2914 9.01372 6.64318 9.36519L8.94827 11.6683L13.3211 6.42542C13.6396 6.04357 14.2076 5.99198 14.5897 6.31019Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(DoneSVG);
