import React from 'react';

const XLSXFILESVG = ({ color = '#78778B', ...rest }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M20.5 6H15.5C15.224 6 15 5.775 15 5.49998V0.500016C15 0.224016 14.776 0 14.5 0H4.5C3.67298 0 3 0.672984 3 1.5V22.5C3 23.327 3.67298 24 4.5 24H19.5C20.327 24 21 23.327 21 22.5V6.50002C21 6.22402 20.776 6 20.5 6ZM20 22.5C20 22.775 19.776 23 19.5 23H4.5C4.224 23 3.99998 22.775 3.99998 22.5V1.5C3.99998 1.22498 4.224 0.999984 4.5 0.999984H14V5.49998C14 6.327 14.673 6.99998 15.5 6.99998H20V22.5Z"
                fill={color}
            />
            <path
                d="M20.8533 6.14674L14.8533 0.146738C14.6583 -0.0482617 14.3413 -0.0482617 14.1463 0.146738C13.9512 0.341738 13.9512 0.658754 14.1463 0.853754L20.1463 6.85375C20.2443 6.95074 20.3723 6.99977 20.5003 6.99977C20.6283 6.99977 20.7563 6.95079 20.8533 6.85375C21.0483 6.65875 21.0483 6.34174 20.8533 6.14674Z"
                fill={color}
            />
            <rect y="11.0063" width="24" height="10.64" rx="1" fill={color} />
            <path
                d="M7.05268 18.2066C7.15117 18.3213 7.20042 18.4412 7.20042 18.5663C7.20042 18.7174 7.14081 18.8504 7.02158 18.965C6.90754 19.0745 6.77536 19.1292 6.62503 19.1292C6.46952 19.1292 6.33734 19.0641 6.22848 18.9338L4.76669 17.1355L3.28935 18.9338C3.17531 19.0641 3.04313 19.1292 2.8928 19.1292C2.74766 19.1292 2.61548 19.0745 2.49626 18.965C2.38222 18.8504 2.3252 18.7174 2.3252 18.5663C2.3252 18.436 2.37185 18.3161 2.46515 18.2066L4.05913 16.299L2.51958 14.446C2.42109 14.3314 2.37185 14.2115 2.37185 14.0864C2.37185 13.9352 2.42887 13.8049 2.54291 13.6955C2.66213 13.5808 2.79432 13.5235 2.93946 13.5235C3.09497 13.5235 3.22974 13.5912 3.34378 13.7267L4.76669 15.4546L6.17405 13.7267C6.28809 13.5912 6.42287 13.5235 6.57838 13.5235C6.72352 13.5235 6.85311 13.5808 6.96715 13.6955C7.08638 13.8049 7.14599 13.9352 7.14599 14.0864C7.14599 14.2167 7.09934 14.3366 7.00603 14.446L5.46649 16.299L7.05268 18.2066Z"
                fill="white"
            />
            <path
                d="M8.4974 19.0823C8.31597 19.0823 8.17602 19.0328 8.07753 18.9338C7.97904 18.8347 7.92979 18.694 7.92979 18.5116V14.1333C7.92979 13.9509 7.98422 13.8049 8.09308 13.6955C8.20193 13.586 8.34967 13.5313 8.53628 13.5313C8.72289 13.5313 8.87062 13.586 8.97948 13.6955C9.08834 13.8049 9.14277 13.9509 9.14277 14.1333V18.0894H11.1333C11.5117 18.0894 11.7009 18.2562 11.7009 18.5897C11.7009 18.9181 11.5117 19.0823 11.1333 19.0823H8.4974Z"
                fill="white"
            />
            <path
                d="M16.6809 18.2066C16.7794 18.3213 16.8286 18.4412 16.8286 18.5663C16.8286 18.7174 16.769 18.8504 16.6498 18.965C16.5358 19.0745 16.4036 19.1292 16.2533 19.1292C16.0977 19.1292 15.9656 19.0641 15.8567 18.9338L14.3949 17.1355L12.9176 18.9338C12.8035 19.0641 12.6714 19.1292 12.521 19.1292C12.3759 19.1292 12.2437 19.0745 12.1245 18.965C12.0104 18.8504 11.9534 18.7174 11.9534 18.5663C11.9534 18.436 12.0001 18.3161 12.0934 18.2066L13.6873 16.299L12.1478 14.446C12.0493 14.3314 12.0001 14.2115 12.0001 14.0864C12.0001 13.9352 12.0571 13.8049 12.1711 13.6955C12.2904 13.5808 12.4225 13.5235 12.5677 13.5235C12.7232 13.5235 12.858 13.5912 12.972 13.7267L14.3949 15.4546L15.8023 13.7267C15.9163 13.5912 16.0511 13.5235 16.2066 13.5235C16.3517 13.5235 16.4813 13.5808 16.5954 13.6955C16.7146 13.8049 16.7742 13.9352 16.7742 14.0864C16.7742 14.2167 16.7276 14.3366 16.6343 14.446L15.0947 16.299L16.6809 18.2066Z"
                fill="white"
            />
            <path
                d="M19.4571 19.1527C19.0475 19.1527 18.6665 19.1058 18.3141 19.0119C17.9616 18.9129 17.6739 18.7748 17.451 18.5976C17.3732 18.5402 17.3162 18.4803 17.2799 18.4177C17.2488 18.35 17.2333 18.2666 17.2333 18.1676C17.2333 18.0372 17.2721 17.9226 17.3499 17.8235C17.4328 17.7245 17.5261 17.675 17.6298 17.675C17.6868 17.675 17.7413 17.6854 17.7931 17.7063C17.8501 17.7219 17.9175 17.7532 17.9953 17.8001C18.2182 17.9408 18.4436 18.0451 18.6717 18.1128C18.8998 18.1754 19.1512 18.2066 19.4259 18.2066C19.7681 18.2066 20.0298 18.1493 20.2113 18.0346C20.3927 17.92 20.4834 17.7558 20.4834 17.5421C20.4834 17.3805 20.3979 17.2502 20.2268 17.1512C20.0609 17.0521 19.7629 16.9583 19.3326 16.8697C18.8506 16.7707 18.4644 16.6508 18.1741 16.5101C17.889 16.3693 17.6791 16.1947 17.5443 15.9862C17.4147 15.7777 17.3499 15.525 17.3499 15.2279C17.3499 14.8995 17.4432 14.605 17.6298 14.3444C17.8216 14.0786 18.0834 13.8727 18.4151 13.7267C18.7521 13.5756 19.1305 13.5 19.5504 13.5C20.2864 13.5 20.8929 13.685 21.3698 14.0551C21.4476 14.1176 21.502 14.1828 21.5331 14.2506C21.5694 14.3131 21.5875 14.3913 21.5875 14.4851C21.5875 14.6154 21.5461 14.7301 21.4631 14.8291C21.3854 14.9282 21.2947 14.9777 21.191 14.9777C21.134 14.9777 21.0795 14.9698 21.0277 14.9542C20.981 14.9386 20.9137 14.9047 20.8255 14.8526C20.613 14.7171 20.416 14.6154 20.2346 14.5477C20.0584 14.4799 19.8329 14.446 19.5581 14.446C19.2419 14.446 18.9931 14.5086 18.8117 14.6337C18.6303 14.7535 18.5395 14.9203 18.5395 15.134C18.5395 15.2591 18.5732 15.3634 18.6406 15.4468C18.7132 15.525 18.8298 15.5953 18.9905 15.6579C19.1564 15.7204 19.3897 15.7856 19.6903 15.8533C20.3953 16.0097 20.9007 16.2104 21.2065 16.4553C21.5176 16.7003 21.6731 17.0365 21.6731 17.4639C21.6731 17.7975 21.5798 18.092 21.3931 18.3474C21.2117 18.6028 20.9525 18.8008 20.6156 18.9416C20.2838 19.0823 19.8977 19.1527 19.4571 19.1527Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(XLSXFILESVG);
