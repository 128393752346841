import React from 'react';
import AvatarBase from './Base';

const getShortLabel = label => {
    const words = label.split(' ');

    return words.length > 1 ? `${words[0][0]}${words[1][0]}` : words[0][0];
};

const CompanyAvatar = ({ label, children, ...rest }) => {
    return (
        <AvatarBase {...rest} color={'#D1D3E3'} variant="rounded">
            {label ? getShortLabel(label) : children}
        </AvatarBase>
    );
};

export default React.memo(CompanyAvatar);
