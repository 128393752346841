import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as appActions from 'app/actions';

const { dispatch } = store;

const userVersionHandler = message => {
    let { body } = mapResponse(message);

    if (body?.version) {
        dispatch(appActions.setAppVersion({ version: body?.version }));
    }
};

export default userVersionHandler;
