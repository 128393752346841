import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getLQAPriorityLabel, getPriorityIcon, PRIORITY_OPTIONS } from 'utils/gridUI/lqa';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    ldPriority: {
        width: 150
    },
    priority: {
        cursor: 'pointer'
    },
    dlFlex: {
        '& svg': {
            verticalAlign: 'text-top'
        }
    }
}));

const TicketDetailPriority = ({ priority, isAllowEdit, onChange }) => {
    const [anchorEl, setAnchorEl] = useState();
    const menuRef = useRef();
    const arrowRef = useRef();
    const classes = useStyles();
    const { t } = useTranslation();

    useClickAwaitListener(menuRef, e => {
        handleClickAway(e);
    });

    const handleClickAway = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = useCallback(
        event => {
            if (!isAllowEdit) return;
            setAnchorEl(anchorEl ? null : arrowRef.current);
        },
        [isAllowEdit, anchorEl]
    );

    const handlePriorityTypeChange = useCallback(
        e => {
            onChange(e.value);
        },
        [onChange]
    );

    const defaultValue = useMemo(() => {
        if (!priority) return {};
        return { value: priority, label: PRIORITY_OPTIONS.find(e => e.value === priority)?.label };
    }, [priority]);

    return (
        <Grid container direction="row">
            <Grid item xs={2}>
                <p className="caption">{t('global_priority')}</p>
            </Grid>
            <Grid item xs={10}>
                {!isAllowEdit ? (
                    <Grid container className={classes.priority} direction="row" spacing={1} alignItems="center">
                        <Grid item className={classes.dlFlex}>
                            {getPriorityIcon(priority)}
                        </Grid>
                        <Grid item>
                            <p className="body2">{getLQAPriorityLabel(priority)}</p>
                        </Grid>
                    </Grid>
                ) : (
                    <LDBasePortal
                        dropdownClassName={classes.ldPriority}
                        ddPlaceholder={'Select a priority'}
                        menuPlaceholder={t('find_a_priority')}
                        options={PRIORITY_OPTIONS}
                        onChange={handlePriorityTypeChange}
                        defaultValue={defaultValue}
                        isUsingContainer
                        isUsingChildren
                    >
                        <Grid
                            container
                            className={classes.priority}
                            direction="row"
                            spacing={1}
                            onClick={handleClick}
                            alignItems="center"
                        >
                            <Grid item className={classes.dlFlex}>
                                {getPriorityIcon(priority)}
                            </Grid>
                            <Grid item>
                                <p className="body2">{getLQAPriorityLabel(priority)}</p>
                            </Grid>
                            <Grid item className={classes.dlFlex} ref={arrowRef}>
                                <ArrowDownSVG />
                            </Grid>
                        </Grid>
                    </LDBasePortal>
                )}
            </Grid>
        </Grid>
    );
};

export default React.memo(TicketDetailPriority);
