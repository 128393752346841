import uuidV4 from 'uuid/v4';
import { getItem, setItem } from 'helpers/localstorage';
import OpenReplay from '@openreplay/tracker';
import trackerAxios from '@openreplay/tracker-axios';
import instance from 'services/request';

function initOpenReplay({ workspaceRole, currentPlan }) {
    const tracker = new OpenReplay({
        projectKey: '9wjYroMBfF4aRVJHQd88',
        onStart: ({ sessionID }) => console.log('OpenReplay tracker started with session: ', sessionID)
    });

    const user = getItem('user');
    const TR_DT_COOKIE = getItem(`tr-dt`);
    const DEFAULT_SESSION_ID = `default_${uuidV4()}`;

    if (!TR_DT_COOKIE) {
        setItem('tr-dt', JSON.stringify(DEFAULT_SESSION_ID));
    }

    if (user) {
        tracker.start({
            userID: user?.email,
            verbose: true,
            metadata: {
                companyId: user?.companyId,
                role: user?.roles?.[0]?.name,
                email: user?.email,
                workspaceRole,
                currentPlan
            }
        });
    } else {
        tracker.start({
            verbose: true
        });
    }

    tracker.use(
        trackerAxios({
            instance,
            ignoreHeaders: ['Cookie', 'Set-Cookie', 'Authorization']
        })
    );
}

export default initOpenReplay;
