import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
import GridView from './GridView';
import Header from './Header';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import { HEADER_RECORD_HISTORY_HEIGHT } from 'const';
import Spinner from 'components/spinner/Base';
import { DISABLED_OPACITY } from 'const/style';
import { useLdUserEmailMap } from 'hooks/permission';
import { RECORD_HISTORY_RENDER } from 'const/gridUI';
import {
    useMetaDataWithReferenceGridDetail,
    useViewColumnsWithReOrder,
    useColumnWidthStore,
    // useLastScrollPosition,
    useDisabledColumns,
    useProcessingColumns,
    useRecordHistory,
    useRecordHistoryIndex,
    useTotalRecordHistory,
    useIsFetchingRecordHistory,
    // useIsTriggerRefreshRecordHistory,
    useRECORD_HISTORY_ROW_START_INDEX,
    useRECORD_HISTORY_ROW_STOP_INDEX,
    // useActiveUsersOnCell,
    useRecordHistoryAction,
    useRecordHistoryMessage,
    useDisabledSourceColumns,
    useDisabledColumnsByType,
    useSelectedRecordId
} from 'hooks/gridUI';

const useStyles = makeStyles(theme => ({
    root: {},
    header: {
        height: HEADER_RECORD_HISTORY_HEIGHT,
        padding: `0px 24px`,
        borderTop: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        background: theme.colors.white
    },
    recordLabel: {
        padding: `3px 10px`,
        borderRadius: 4
    },
    close: {
        color: theme.colors.paleLavender,
        cursor: 'pointer'
    },
    spinner: {
        width: '100%',
        height: 248,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        background: theme.colors.white,
        opacity: DISABLED_OPACITY,
        zIndex: 8000
    },
    fullWidth: {
        width: '100%'
    },
    pagination: {
        minWidth: 350,
        '& ul': {
            justifyContent: 'flex-end'
        }
    },
    tableRecordHistory: {
        position: 'relative'
    }
}));

function RecordHistory(props) {
    const recordHistory = useRecordHistory();
    const recordHistoryAction = useRecordHistoryAction();
    const recordHistoryMessage = useRecordHistoryMessage();
    const recordHistoryIndex = useRecordHistoryIndex();
    const totalRecordHistory = useTotalRecordHistory();
    const isFetchingRecordHistory = useIsFetchingRecordHistory();
    // const isTriggerRefreshRecordHistory = useIsTriggerRefreshRecordHistory();
    const RECORD_HISTORY_ROW_STOP_INDEX = useRECORD_HISTORY_ROW_STOP_INDEX();
    const RECORD_HISTORY_ROW_START_INDEX = useRECORD_HISTORY_ROW_START_INDEX();
    const disabledColumns = useDisabledColumns();
    const processingColumns = useProcessingColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const columnWidthStore = useColumnWidthStore();
    const viewColumns = useViewColumnsWithReOrder();
    const recordId = useSelectedRecordId();

    const columns = React.useMemo(() => {
        return viewColumns?.map(column => column?.id);
    }, [viewColumns]);

    const totalRecords = React.useMemo(() => {
        return Math.min(recordHistory?.length, RECORD_HISTORY_RENDER);
    }, [recordHistory]);
    const data = recordHistory;
    const rows = React.useMemo(() => {
        return recordHistory.map(i => i?.version);
    }, [recordHistory]);

    const {
        width,
        // height,
        dbId,
        // totalRecordHistory,
        t
    } = props;

    const metaData = useMetaDataWithReferenceGridDetail(dbId);

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const ldUserEmailMap = useLdUserEmailMap();

    React.useEffect(() => {
        if (recordId) {
            dispatch(
                gridActions.fetchRecordHistory({
                    recordId,
                    errorCallback: () => {
                        console.log('failed to refresh recordHistory');
                    }
                })
            );
        }
    }, [dispatch, recordId]);

    return (
        <Grid
            style={{
                width,
                // height,
                background: theme.colors.white,
                border: `1p solid ${theme.colors.border}`
            }}
            container
            direction="column"
            wrap="nowrap"
        >
            <Grid item className={classes.tableRecordHistory}>
                {isFetchingRecordHistory && (
                    <div className={classes.spinner}>
                        <Spinner size={30} thick={4} />
                    </div>
                )}
                <GridView
                    t={t}
                    {...{
                        ...props,
                        recordHistory,
                        recordHistoryAction,
                        recordHistoryMessage,
                        recordHistoryIndex,
                        totalRecordHistory,
                        isFetchingRecordHistory,
                        RECORD_HISTORY_ROW_STOP_INDEX,
                        RECORD_HISTORY_ROW_START_INDEX,
                        disabledColumns,
                        processingColumns,
                        disabledColumnIdsByType,
                        disabledSourceColumns,
                        columnWidthStore,
                        columns,
                        totalRecords,
                        data,
                        rows,
                        metaData,
                        recordHistoryId: recordId
                    }}
                    headerRenderer={props => <Header {...props} />}
                    ldUserEmailMap={ldUserEmailMap}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(RecordHistory);
