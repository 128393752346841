import React from 'react';

function EnterprisePlanSVG({ ...other }) {
    return (
        <svg {...other} width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4288 8.99969C12.0646 8.99969 12.6862 8.81113 13.2148 8.45787C13.7435 8.10462 14.1555 7.60252 14.3988 7.01508C14.6421 6.42764 14.7058 5.78124 14.5817 5.15764C14.4576 4.53404 14.1514 3.96123 13.7017 3.51167C13.2521 3.06212 12.6792 2.75599 12.0556 2.63201C11.432 2.50804 10.7856 2.57178 10.1982 2.81518C9.6108 3.05858 9.10878 3.4707 8.75561 3.99943C8.40244 4.52815 8.214 5.14973 8.21411 5.78556C8.21501 6.63782 8.554 7.45492 9.15669 8.0575C9.75939 8.66009 10.5765 8.99894 11.4288 8.99969Z"
                fill="#EF727E"
            />
            <path
                d="M15.6341 11.6549C14.8195 10.8193 13.7743 10.2456 12.632 10.0069C11.4898 9.76822 10.3023 9.87546 9.22129 10.3149C8.14029 10.7544 7.21482 11.5061 6.56311 12.4741C5.9114 13.4421 5.56303 14.5824 5.5625 15.7493C5.5625 15.8985 5.62176 16.0415 5.72725 16.147C5.83274 16.2525 5.97582 16.3118 6.125 16.3118H16.7338C16.8829 16.3118 17.026 16.2525 17.1315 16.147C17.237 16.0415 17.2963 15.8985 17.2963 15.7493C17.2984 15.1528 17.2064 14.5598 17.0234 13.992C16.7511 13.1143 16.275 12.3135 15.6341 11.6549Z"
                fill="#EF727E"
            />
            <path
                d="M18.2188 9.28125C19.5391 9.28125 20.6094 8.21093 20.6094 6.89062C20.6094 5.57032 19.5391 4.5 18.2188 4.5C16.8984 4.5 15.8281 5.57032 15.8281 6.89062C15.8281 8.21093 16.8984 9.28125 18.2188 9.28125Z"
                fill="#FFBDC3"
            />
            <path
                d="M4.21875 9.28125C2.89844 9.28125 1.82812 8.21093 1.82812 6.89062C1.82812 5.57032 2.89844 4.5 4.21875 4.5C5.53906 4.5 6.60938 5.57032 6.60938 6.89062C6.60938 8.21093 5.53906 9.28125 4.21875 9.28125Z"
                fill="#FFBDC3"
            />
            <path
                d="M18.2188 9.93945C17.4648 9.94229 16.7254 10.1476 16.0779 10.534C16.1999 10.6431 16.3231 10.7506 16.4384 10.8687C17.2044 11.6559 17.7733 12.6131 18.0989 13.6621C18.2105 14.0074 18.2927 14.3616 18.3448 14.7207H21.875C22.0242 14.7207 22.1673 14.6614 22.2728 14.556C22.3782 14.4505 22.4375 14.3074 22.4375 14.1582C22.4363 13.0397 21.9915 11.9673 21.2005 11.1764C20.4096 10.3855 19.3373 9.94064 18.2188 9.93945Z"
                fill="#FFBDC3"
            />
            <path
                d="M4.21874 9.93945C4.97275 9.94229 5.71214 10.1476 6.35962 10.534C6.23756 10.6431 6.11437 10.7506 5.99906 10.8687C5.23314 11.6559 4.66417 12.6131 4.33856 13.6621C4.22704 14.0074 4.14481 14.3616 4.09274 14.7207H0.562494C0.41331 14.7207 0.270236 14.6614 0.164746 14.556C0.059257 14.4505 -5.72205e-06 14.3074 -5.72205e-06 14.1582C0.00118542 13.0397 0.446042 11.9673 1.23695 11.1764C2.02786 10.3855 3.10023 9.94064 4.21874 9.93945Z"
                fill="#FFBDC3"
            />
        </svg>
    );
}

export default React.memo(EnterprisePlanSVG);
