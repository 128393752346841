import React from 'react';

function CodeSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" {...other} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.69207 2.64837L9.21058 2.51217C9.14861 2.49085 9.08756 2.49755 9.02819 2.53223C8.96864 2.56694 8.92842 2.61905 8.90775 2.68847L6.0118 13.031C5.99114 13.1005 5.99761 13.1659 6.03125 13.2273C6.06488 13.2887 6.11527 13.3301 6.18266 13.3515L6.66401 13.4877C6.72612 13.5092 6.78695 13.5025 6.84648 13.4677C6.90604 13.4329 6.94617 13.3809 6.96681 13.3116L9.86284 2.9689C9.88348 2.89948 9.87707 2.83399 9.84337 2.77251C9.80971 2.71106 9.75937 2.66967 9.69207 2.64837Z"
                fill={color}
                stroke={color}
                strokeWidth="0.3"
            />
            <path
                d="M0.227601 7.81554L3.84562 4.08234C3.89734 4.02897 3.95692 4.00217 4.02409 4.00217C4.09148 4.00217 4.15084 4.02897 4.20275 4.08234L4.59083 4.4829C4.64255 4.53629 4.66836 4.59766 4.66836 4.66717C4.66836 4.73667 4.64258 4.79807 4.59083 4.85144L1.53955 7.99987L4.59083 11.1485C4.64255 11.2018 4.66836 11.2633 4.66836 11.3326C4.66836 11.4022 4.64258 11.4636 4.59083 11.517L4.20275 11.9173C4.15084 11.9708 4.09145 11.9974 4.02409 11.9974C3.9569 11.9974 3.89734 11.9707 3.84562 11.9173L0.227601 8.18416C0.175851 8.13079 0.14999 8.06923 0.14999 7.99981C0.14999 7.93036 0.175851 7.86891 0.227601 7.81554Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.31028 3.97775C4.23365 3.89898 4.13678 3.85217 4.02409 3.85217C3.91172 3.85217 3.81451 3.89891 3.7379 3.97795L0.119912 7.71112C0.043098 7.79034 -1.04904e-05 7.8885 -1.04904e-05 7.99981C-1.04904e-05 8.11103 0.0430541 8.20932 0.119912 8.28858L3.73788 12.0217C3.8146 12.1009 3.91184 12.1474 4.02409 12.1474C4.13649 12.1474 4.23351 12.101 4.31039 12.0218L4.69852 11.6214C4.77543 11.5421 4.81836 11.4439 4.81836 11.3326C4.81836 11.2215 4.77535 11.1233 4.69855 11.0441L1.74843 7.99987L4.69852 4.95585C4.77539 4.87656 4.81836 4.77842 4.81836 4.66716C4.81836 4.55584 4.77534 4.45779 4.69857 4.37853L4.31028 3.97775ZM4.09513 4.18683C4.06797 4.15894 4.04615 4.15217 4.02409 4.15217C4.00212 4.15217 3.98018 4.15903 3.95333 4.18673L0.335315 7.91993C0.30863 7.94745 0.29999 7.97222 0.29999 7.99981C0.29999 8.02743 0.308648 8.05227 0.335289 8.07974L3.95333 11.8129C3.98005 11.8405 4.00195 11.8474 4.02409 11.8474C4.04641 11.8474 4.06817 11.8406 4.09512 11.8129L4.48313 11.4126C4.5097 11.3852 4.51836 11.3604 4.51836 11.3326C4.51836 11.3051 4.50976 11.2803 4.48311 11.2529L1.33067 7.99986L4.48312 4.74704C4.50973 4.7196 4.51836 4.69491 4.51836 4.66716C4.51836 4.63948 4.50977 4.61481 4.4831 4.58727L4.09513 4.18683Z"
                fill={color}
            />
            <path
                d="M15.5908 7.81554L11.9727 4.08234C11.921 4.02897 11.8614 4.00217 11.7943 4.00217C11.7269 4.00217 11.6675 4.02897 11.6156 4.08234L11.2275 4.4829C11.1758 4.53629 11.15 4.59766 11.15 4.66717C11.15 4.73667 11.1758 4.79807 11.2275 4.85144L14.2788 7.99987L11.2275 11.1485C11.1758 11.2018 11.15 11.2633 11.15 11.3326C11.15 11.4022 11.1758 11.4636 11.2275 11.517L11.6156 11.9173C11.6675 11.9708 11.7269 11.9974 11.7943 11.9974C11.8615 11.9974 11.921 11.9707 11.9727 11.9173L15.5908 8.18416C15.6425 8.13079 15.6684 8.06923 15.6684 7.99981C15.6684 7.93036 15.6425 7.86891 15.5908 7.81554Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5081 3.97775C11.5847 3.89898 11.6816 3.85217 11.7943 3.85217C11.9066 3.85217 12.0039 3.89891 12.0805 3.97795L15.6984 7.71112C15.7753 7.79034 15.8184 7.8885 15.8184 7.99981C15.8184 8.11103 15.7753 8.20932 15.6984 8.28858L12.0805 12.0217C12.0038 12.1009 11.9065 12.1474 11.7943 12.1474C11.6819 12.1474 11.5849 12.101 11.508 12.0218L11.1198 11.6214C11.0429 11.5421 11 11.4439 11 11.3326C11 11.2215 11.043 11.1233 11.1198 11.0441L14.0699 7.99987L11.1198 4.95585C11.043 4.87656 11 4.77842 11 4.66716C11 4.55584 11.043 4.45779 11.1198 4.37853L11.5081 3.97775ZM11.7232 4.18683C11.7504 4.15894 11.7722 4.15217 11.7943 4.15217C11.8162 4.15217 11.8382 4.15903 11.865 4.18673L15.483 7.91993C15.5097 7.94745 15.5184 7.97222 15.5184 7.99981C15.5184 8.02743 15.5097 8.05227 15.4831 8.07974L11.865 11.8129C11.8383 11.8405 11.8164 11.8474 11.7943 11.8474C11.7719 11.8474 11.7502 11.8406 11.7232 11.8129L11.3352 11.4126C11.3087 11.3852 11.3 11.3604 11.3 11.3326C11.3 11.3051 11.3086 11.2803 11.3352 11.2529L14.4877 7.99986L11.3352 4.74704C11.3086 4.7196 11.3 4.69491 11.3 4.66716C11.3 4.63948 11.3086 4.61481 11.3353 4.58727L11.7232 4.18683Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CodeSVG);
