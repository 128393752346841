import * as types from '../types';
import { getViewColumnsApi, addColumnsToViewApi, setViewRecords, getViewApi, getViewRecordsApiV2 } from 'services/view';
import { backupDatabaseApi, getDatabaseApi } from 'services/database';
import {
    getGridColumnsApi,
    createGridColumnApi,
    restoreGridApi,
    getGridTotalRecordApi,
    fetchGridDetailApi,
    updateGridApi,
    exportGridHistoryApi
} from 'services/grid';
import { formatMetaData } from 'utils/gridUI/formatData';
import { enqueueSnackbar } from 'notifier/actions';
import { RECORDS_RENDER, GRID_STATUS, COLUMN_STATUS } from 'const/gridUI';
import { formatColumnPermission } from 'utils/gridUI/column';
import * as columnActions from './column';
import * as rowActions from './row';
import { SYSTEM_COLUMNS } from 'const';
import * as gridActions from 'grids/actions';
import history from 'utils/history';
import now from 'performance-now';
import * as viewActions from './views';
import * as autoQAActions from './autoQA';
import { getIsShowAutoQA } from 'utils/gridUI/lqa';

export function triggerRecomputedGrid() {
    return {
        type: types.TRIGGER_RECOMPUTED_GRID
    };
}

export function resetTriggerRecomputedGrid() {
    return {
        type: types.RESET_TRIGGER_RECOMPUTED_GRID
    };
}

export function renderGridUITable({
    dbId,
    wsId,
    defaultAccessViewId,
    gridId,
    offset = 0,
    limit = RECORDS_RENDER,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const isShowAutoQA = getIsShowAutoQA();

        dispatch(_renderGridUITableAction());
        fetchingGridUIData({
            isShowAutoQA,
            defaultAccessViewId,
            limit,
            gridId,
            dbId,
            offset,
            dispatch,
            successCallback,
            errorCallback
        });
    };
}

export function renderGridUITableAfterNetworkConnected({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId, branchId, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;

        const isShowAutoQA = getIsShowAutoQA();

        dispatch(_renderGridUITableAfterNetworkConnectedAction());
        fetchingGridUIData({
            defaultAccessViewId,
            offset: ROW_START_INDEX,
            limit: ROW_STOP_INDEX,
            gridId: branchId,
            dbId,
            dispatch,
            isShowAutoQA,
            isNotOpenQAPanel: true,
            successCallback,
            errorCallback
        });
    };
}

export function fetchCurrentView({ dbId, defaultAccessViewId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchCurrentView());
        try {
            const view = await getViewApi({ dbId, viewId: defaultAccessViewId });
            dispatch(_setCurrentView(view));
            dispatch(fetchCurrentViewSuccess());
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(viewActions._toggleBlockCurrentView());
            errorCallback && errorCallback();
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );

            dispatch(_fetchCurrentViewFailed());
        }
    };
}

function _fetchCurrentView() {
    return {
        type: types.FETCH_CURRENT_VIEW
    };
}

function _fetchCurrentViewFailed() {
    return {
        type: types.FETCH_CURRENT_VIEW_FAILED
    };
}

export function fetchCurrentViewSuccess() {
    return {
        type: types.FETCH_CURRENT_VIEW_SUCCESS
    };
}

async function fetchingGridUIData({
    defaultAccessViewId,
    gridId,
    dbId,
    offset,
    limit,
    dispatch,
    isShowAutoQA,
    isNotOpenQAPanel,
    successCallback,
    errorCallback
}) {
    try {
        const start = now();
        const [viewColumns, gridColumns] = await Promise.all([
            _getViewColumns({ defaultAccessViewId, dbId }),
            _getGridColumns({ gridId, dbId })
        ]);
        const pendingColumnIds = gridColumns
            .filter(gridCol => {
                return [
                    COLUMN_STATUS.AUTO_FILL,
                    COLUMN_STATUS.SWITCH,
                    COLUMN_STATUS.PASTE,
                    COLUMN_STATUS.APPROVE_POPULATED_TEXT
                ]?.includes(gridCol?.status);
            })
            .map(gridCol => gridCol?.id);
        dispatch(columnActions.addProcessingColumns({ columnIds: pendingColumnIds }));

        // const dependencyColumnIds = getDependencyChilds({ dependencies, viewColumns });

        const {
            recordIds,
            totalRecords,
            data,
            recordMetaData,
            columnIds,
            totalRecordsWithoutFilters
        } = await getViewRecordsApiV2({
            defaultAccessViewId,
            dbId,
            offset,
            limit,
            isShowAutoQA
        });

        console.log('totalRecordsWithoutFilters', totalRecordsWithoutFilters);

        const gridColumnsCombined = [...gridColumns, ...SYSTEM_COLUMNS.filter(sysCol => sysCol?.show)];
        let metaData = formatMetaData({ meta: gridColumnsCombined, viewColumns });
        let columnsPermission = formatColumnPermission({ viewColumns, gridColumnsCombined });

        const end = now();

        console.log('CALCULATE FETCHING TABLE TIME', (end - start).toFixed(3));

        dispatch(
            _renderGridUITableSuccessAction({
                viewColumns,
                columnsPermission,
                columns: columnIds,
                metaData,
                rows: recordIds,
                data,
                totalRecords,
                recordMetaData,
                totalRecordsWithoutFilters
            })
        );

        if (!isNotOpenQAPanel && isShowAutoQA) {
            dispatch(autoQAActions.showQAErrors());
            dispatch(autoQAActions.openErrorControlPanel());
        }

        // dispatch(rowActions.expandRowsDynamic({ rowIds: recordIds }));

        return successCallback && successCallback();
    } catch (error) {
        const { message } = error;
        dispatch(
            enqueueSnackbar({
                message,
                type: 'info'
            })
        );
        dispatch(_renderGridUITableFailedAction({ error: message }));
        return errorCallback && errorCallback(message);
    }
}

async function _getViewColumns({ defaultAccessViewId, dbId }) {
    try {
        return await getViewColumnsApi({ defaultAccessViewId, dbId });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

async function _getGridColumns({ gridId, dbId }) {
    try {
        return await getGridColumnsApi({ gridId, dbId });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

// eslint-disable-next-line no-unused-vars
async function _createGridColumn({ gridId, dbId, column }) {
    try {
        return await createGridColumnApi({ gridId, dbId, column });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}
// eslint-disable-next-line no-unused-vars
async function _addSingleColumnToView({ defaultAccessViewId, dbId, column }) {
    try {
        return await addColumnsToViewApi({ defaultAccessViewId, dbId, columnIds: [column] });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

// eslint-disable-next-line no-unused-vars
async function _addDefault5Records({ dbId, defaultAccessViewId, body }) {
    try {
        return await setViewRecords({ defaultAccessViewId, dbId, body });
    } catch (error) {
        const { message } = error;
        const errorMessage = {
            message,
            type: 'info'
        };
        throw errorMessage;
    }
}

export function resetGridLoadingState() {
    return {
        type: types.RESET_GRID_LOADING_STATE
    };
}

function _renderGridUITableAfterNetworkConnectedAction() {
    return {
        type: types.RENDER_GRID_UI_TABLE_AFTER_NETWORK_CONNECTED
    };
}

function _renderGridUITableAction() {
    return {
        type: types.RENDER_GRID_UI_TABLE
    };
}

function _renderGridUITableFailedAction({ error }) {
    return {
        type: types.RENDER_GRID_UI_TABLE_FAILED,
        payload: {
            error
        }
    };
}

function _renderGridUITableSuccessAction({
    columns,
    rows,
    metaData,
    data,
    totalRecords,
    columnsPermission,
    viewColumns,
    recordMetaData,
    totalRecordsWithoutFilters
}) {
    return {
        type: types.RENDER_GRID_UI_TABLE_SUCCESS,
        payload: {
            columns,
            rows,
            metaData,
            data,
            totalRecords,
            columnsPermission,
            viewColumns,
            recordMetaData,
            totalRecordsWithoutFilters
        }
    };
}

export function setDefaultRouterParams({ defaultAccessViewId, workspaceId, dbId, gridId, branchId, parentGridId }) {
    return {
        type: types.SET_DEFAULT_ROUTER_PARAMS,
        payload: {
            defaultAccessViewId,
            workspaceId,
            dbId,
            gridId,
            branchId,
            parentGridId
        }
    };
}

export function _setCurrentView(currentView) {
    return {
        type: types.GET_CURRENT_VIEW_SUCCESS,
        payload: {
            currentView
        }
    };
}

export function fetchCurrentSection({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { defaultAccessViewId, dbId, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;
        dispatch(
            rowActions.fetchMoreRows({
                defaultAccessViewId,
                dbId,
                ROW_START_INDEX,
                ROW_STOP_INDEX,
                errorCallback,
                successCallback
            })
        );
    };
}

export function restoreGrid(body) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, branchId } = gridUI;

        try {
            await restoreGridApi({ dbId, gridId: branchId, body });
            dispatch(gridActions.updateGridBranchStatus({ dbId, gridId: branchId, status: GRID_STATUS.RESTORING }));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(gridActions.updateGridBranchStatus({ dbId, gridId: branchId, status: GRID_STATUS.ACTIVE }));
        }
    };
}

export function gridRefreshOrDirectHome(dbId) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { workspaceId, branchId } = gridUI;

        try {
            const grids = await getDatabaseApi(dbId);
            const gridIds = grids?.map(grid => grid.id);
            if (gridIds.includes(branchId)) {
                dispatch(
                    renderGridUITableAfterNetworkConnected({
                        successCallback: () => {
                            console.log('fetch grid success');
                        },
                        errorCallback: () => {
                            console.log('failed to fetch grid');
                        }
                    })
                );
            } else {
                history.push(`/projects/${workspaceId}`);
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function getTotalGridRecords({ dbId, branchId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId: dbIdStore, branchId: branchIdStore } = gridUI;
        const branchIdCombined = branchId || branchIdStore;
        const dbIdCombined = dbId || dbIdStore;

        try {
            const res = await getGridTotalRecordApi({ dbId: dbIdCombined, gridId: branchIdCombined });
            const gridTotalRecords = res?.recordCount || 0;

            console.log('');
            dispatch(_getTotalGridRecordSuccess(gridTotalRecords));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function _getTotalGridRecordSuccess(gridTotalRecords) {
    return {
        type: types.GET_TOTAL_GRID_RECORDS_SUCCESS,
        payload: {
            gridTotalRecords
        }
    };
}

export function _updateClearingRecords({ clearingRecords }) {
    return {
        type: types.SET_GRID_CLEARING_RECORDS,
        payload: { clearingRecords }
    };
}

export function fetchCurrentGrid({ dbId, gridId }) {
    return async function(dispatch, getState) {
        try {
            const grid = await fetchGridDetailApi({ dbId, gridId });
            dispatch(updateCurrentGridAction(grid));
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function updateCurrentGrid({ dbId, oldGrid, newGrid, error, success }) {
    return async function(dispatch, getState) {
        try {
            dispatch(updateCurrentGridAction(newGrid));
            await updateGridApi({ gridId: oldGrid?.id, dbId, newGrid });
            success && success();
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(updateCurrentGridAction(oldGrid));
            error && error();
        }
    };
}

export function updateCurrentGridAction(grid) {
    return {
        type: types.UPDATE_CURRENT_GRID,
        payload: {
            grid
        }
    };
}

export function backupGrid({ dbId, gridId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            dispatch(gridActions.updateGridBranchStatus({ dbId, gridId, status: GRID_STATUS.BACKING_UP }));
            await backupDatabaseApi({ dbId, gridId });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function exportGridHistory({ dbId, gridId, fromDateTime, toDateTime, emails, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await exportGridHistoryApi({ dbId, gridId, fromDateTime, toDateTime, emails });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function setOpenRightSideBar(payload) {
    return {
        type: types.SET_OPEN_RIGHT_SIDE_BAR,
        payload
    };
}
