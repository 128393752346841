import React from 'react';

function NotFoundSVG({ color = '#93929e', width = 16, height = 14, ...other }) {
    return (
        <svg width="132" height="96" viewBox="0 0 132 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0)">
                <path
                    d="M115.561 18.503H15V7.68954C15 6.2462 16.17 5.07617 17.6134 5.07617H112.947C114.391 5.07617 115.561 6.2462 115.561 7.68954V18.503Z"
                    fill="#BFC1D9"
                />
                <path
                    d="M112.947 82.9516H17.6134C16.17 82.9516 15 81.7816 15 80.3382V18.5029H115.561V80.3382C115.561 81.7816 114.391 82.9516 112.947 82.9516Z"
                    fill="#F3F4FC"
                />
                <path
                    d="M34.4409 15.1464C36.2949 15.1464 37.7978 13.6435 37.7978 11.7895C37.7978 9.93556 36.2949 8.43262 34.4409 8.43262C32.5869 8.43262 31.084 9.93556 31.084 11.7895C31.084 13.6435 32.5869 15.1464 34.4409 15.1464Z"
                    fill="white"
                />
                <path
                    d="M23.727 15.1464C25.581 15.1464 27.0839 13.6435 27.0839 11.7895C27.0839 9.93556 25.581 8.43262 23.727 8.43262C21.8731 8.43262 20.3701 9.93556 20.3701 11.7895C20.3701 13.6435 21.8731 15.1464 23.727 15.1464Z"
                    fill="white"
                />
                <path
                    d="M45.2104 15.1464C47.0644 15.1464 48.5673 13.6435 48.5673 11.7895C48.5673 9.93556 47.0644 8.43262 45.2104 8.43262C43.3565 8.43262 41.8535 9.93556 41.8535 11.7895C41.8535 13.6435 43.3565 15.1464 45.2104 15.1464Z"
                    fill="white"
                />
                <path
                    d="M34.4409 15.1464C36.2949 15.1464 37.7978 13.6435 37.7978 11.7895C37.7978 9.93556 36.2949 8.43262 34.4409 8.43262C32.5869 8.43262 31.084 9.93556 31.084 11.7895C31.084 13.6435 32.5869 15.1464 34.4409 15.1464Z"
                    fill="#EED7A7"
                    fillOpacity="0.8"
                />
                <path
                    d="M23.727 15.1464C25.581 15.1464 27.0839 13.6435 27.0839 11.7895C27.0839 9.93556 25.581 8.43262 23.727 8.43262C21.8731 8.43262 20.3701 9.93556 20.3701 11.7895C20.3701 13.6435 21.8731 15.1464 23.727 15.1464Z"
                    fill="#F1C6CB"
                    fillOpacity="0.8"
                />
                <path
                    d="M45.2104 15.1464C47.0644 15.1464 48.5673 13.6435 48.5673 11.7895C48.5673 9.93556 47.0644 8.43262 45.2104 8.43262C43.3565 8.43262 41.8535 9.93556 41.8535 11.7895C41.8535 13.6435 43.3565 15.1464 45.2104 15.1464Z"
                    fill="#BDE4D9"
                    fillOpacity="0.8"
                />
                <path
                    d="M127.808 78.3833L132.154 77.3139V71.8393L127.808 70.7699C127.357 68.7186 126.546 66.806 125.449 65.0906L127.77 61.2551L123.899 57.3839L120.063 59.7047C118.348 58.6082 116.435 57.7976 114.384 57.3462L113.314 53H107.84L106.77 57.3462C104.719 57.7976 102.806 58.6085 101.091 59.705L97.255 57.3844L93.3838 61.2558L95.7045 65.0914C94.6082 66.8068 93.7973 68.7191 93.346 70.7702L89 71.8397V77.3143L93.346 78.3838C93.7974 80.435 94.6082 82.3475 95.7047 84.0629L93.3842 87.8983L97.2555 91.7696L101.091 89.4491C102.806 90.5456 104.719 91.3567 106.77 91.8079L107.84 96.1533H113.314L114.384 91.8079C116.435 91.3565 118.348 90.5456 120.064 89.449L123.899 91.7691L127.77 87.8979L125.449 84.0629C126.546 82.3475 127.357 80.4347 127.808 78.3833Z"
                    fill="#DCDCED"
                />
                <path
                    d="M110.093 88.6086C117.676 88.6086 123.824 82.4611 123.824 74.8778C123.824 67.2945 117.676 61.147 110.093 61.147C102.51 61.147 96.3623 67.2945 96.3623 74.8778C96.3623 82.4611 102.51 88.6086 110.093 88.6086Z"
                    fill="#F4F5F7"
                />
                <path
                    d="M110.577 82.4222C114.91 82.4222 118.423 78.9096 118.423 74.5766C118.423 70.2436 114.91 66.731 110.577 66.731C106.244 66.731 102.731 70.2436 102.731 74.5766C102.731 78.9096 106.244 82.4222 110.577 82.4222Z"
                    fill="#DCDCED"
                />
                <path
                    d="M44.8992 56.334L42.8454 61.0001H39.1475L48.1851 40.489H51.883L60.9207 61.0001H57.2241L55.1693 56.334H44.8992ZM53.7613 53.1366L50.0346 44.6853L46.3079 53.1366H53.7613Z"
                    fill="#BFC1D9"
                />
                <path
                    d="M77.6182 42.2777C79.0463 43.4716 79.7603 45.3104 79.7603 47.794C79.7603 50.2798 79.0306 52.0998 77.5741 53.2531C76.1162 54.4066 73.8809 54.9845 70.8685 54.9845H67.2304V61.0001H63.7676V40.489H70.81C73.9203 40.489 76.1901 41.086 77.6182 42.2777ZM75.1681 50.7435C75.8617 50.0103 76.2092 48.9357 76.2092 47.5173C76.2092 46.0989 75.7685 45.0957 74.8885 44.5084C74.0086 43.9208 72.6293 43.6297 70.7511 43.6297H67.2298V51.8447H71.2501C73.1668 51.8441 74.4731 51.4788 75.1681 50.7435Z"
                    fill="#BFC1D9"
                />
                <path d="M83.8389 40.489H87.3017V61.0001H83.8389V40.489Z" fill="#BFC1D9" />
                <path
                    opacity="0.3"
                    d="M104.56 14.4025H73.6134C72.17 14.4025 71 13.2325 71 11.7892C71 10.3458 72.17 9.17578 73.6134 9.17578H104.56C106.003 9.17578 107.173 10.3458 107.173 11.7892C107.173 13.2325 106.003 14.4025 104.56 14.4025Z"
                    fill="#F4F5F7"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="132" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(NotFoundSVG);
