import React, { useCallback, useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import ButtonBase from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import Search from 'components/search';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import Tooltip from 'components/tooltip/Base';
import AddSegment from './AddSegment';
import ConfirmBox from 'components/confirmBox/Base';
import { useDispatch } from 'react-redux';
import { deleteSegment } from 'tm/actions';
import { useTranslation } from 'react-i18next';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    toolbar: {
        marginBottom: 40
    },
    searchBox: {
        border: `1px solid ${theme.colors.silver}`,
        background: theme.colors.white,
        marginRight: 8,
        '& input': {
            '&::placeholder': {
                fontSize: '14px !important',
                color: `${theme.colors.lightGreyBlue} !important`,
                opacity: 1
            }
        }
    },
    deleteBox: {
        borderRadius: 4,
        margin: '0 8px',
        width: 36,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.5s ease',
        '&.mr-16': {
            marginRight: 16
        },
        '& svg': {
            width: 24,
            height: 24,
            '& path': {
                fill: theme.colors.disabledGrey
            }
        },
        '&.active': {
            background: 'transparent',
            cursor: 'pointer',
            '& path': {
                fill: theme.colors.midGrey
            },
            '&:hover': {
                background: theme.colors.solitude,
                '& path': {
                    fill: theme.palette.primary.main
                }
            }
        }
    },
    buttonProgress: {
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    }
}));

const EditorTmToolbar = ({
    source,
    targets,
    tmId,
    selectedRows,
    onRestTable,
    setSelectedRows,
    handleSearchChange,
    handleClearSearch
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [openAddSegment, setOpenAddSegment] = useState(false);

    const handleOpenDeleteDialog = useCallback(() => {
        if (selectedRows.length) {
            setOpenDeleteDialog(true);
        }
    }, [selectedRows]);

    const closeDeleteDialog = useCallback(() => {
        setOpenDeleteDialog(false);
    }, []);

    const handleDeleteSegment = useCallback(() => {
        if (confirming) return;
        setConfirming(true);
        dispatch(
            deleteSegment({
                tmId: tmId,
                body: { unitIds: selectedRows },
                successCallback: () => {
                    setConfirming(false);
                    closeDeleteDialog();
                    onRestTable({ resetPage: true, resetSelectedRows: [] });
                    setSelectedRows([]);
                },
                errorCallback: () => {
                    setConfirming(false);
                }
            })
        );
    }, [confirming, dispatch, tmId, selectedRows, closeDeleteDialog, onRestTable, setSelectedRows]);

    const handleOpenAddSegment = React.useCallback(() => {
        setOpenAddSegment(true);
    }, []);

    const handleCloseAddSegment = React.useCallback(() => {
        setOpenAddSegment(false);
    }, []);

    return (
        <>
            <Grid className={classes.toolbar} container justify="flex-end" alignItems="center">
                <Grid item>
                    <Search
                        className={classes.searchBox}
                        background={theme.colors.paleGrey}
                        onChange={handleSearchChange}
                        defaultValue={''}
                        placeholder={'Search source language...'}
                        width={240}
                        onClear={handleClearSearch}
                        disabled={targets?.length > 0 ? false : true}
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Delete translation unit">
                        <Grid
                            item
                            className={`${classes.deleteBox} ${selectedRows.length ? 'active' : ''} mr-16`}
                            onClick={handleOpenDeleteDialog}
                        >
                            <DeleteSVG />
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Add a new translation unit">
                        <ButtonBase
                            variant="contained"
                            width={140}
                            onClick={handleOpenAddSegment}
                            disabled={targets?.length < 1 ? true : false}
                        >
                            <AddIconSVG color={theme.colors.white} style={{ marginRight: 8, width: 10, height: 10 }} />
                            Add
                        </ButtonBase>
                    </Tooltip>
                </Grid>
            </Grid>

            <Dialog open={openAddSegment} onClose={handleCloseAddSegment}>
                <AddSegment
                    open={openAddSegment}
                    onClose={handleCloseAddSegment}
                    source={source}
                    targets={targets}
                    tmId={tmId}
                    onRestTable={onRestTable}
                />
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}>
                <ConfirmBox
                    title={t(`delete_transition_unit`)}
                    body={t(`are_you_sure_you_want_to_delete_selected_terms`)}
                    handleCancel={closeDeleteDialog}
                    onClose={closeDeleteDialog}
                    handleAgreed={handleDeleteSegment}
                    isLoading={confirming}
                    agreeLabel={t('global_remove')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </>
    );
};

export default React.memo(EditorTmToolbar);
