import React from 'react';
import { ScrollSync, Grid, defaultCellRangeRenderer } from 'react-virtualized-dn';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from './Header';
import CellRow from './Cell';
import HeaderFilter from './HeaderFilter';
import { getWidthDistanceToIndex, getColumnData, getWidthOfColumnList } from 'utils/gridUI/column';
import IndexCell from './IndexCell';
import { SmoothScroll } from 'gridUI/table/grid/scroll';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import { getTotalRowsHeight } from 'utils/gridUI/row';
import { DISABLED_OPACITY, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import {
    LIMIT_ROWS,
    FREEZING_BAR_WIDTH,
    FREEZING_BAR_HANDLER_WIDTH,
    TOTAL_GRID_UI_HEADER,
    ROW_RESIZE_HEIGHT,
    // MAX_ROW_RESIZE,
    // MIN_ROW_RESIZE,
    DEFAULT_COLUMN_WIDTH,
    COLUMN_RESIZE_WIDTH,
    FAKE_COLUMN_COUNT,
    ROW_HEIGHT,
    BORDER_HIGHLIGHT,
    ROW_HEIGHT_OPTIONS,
    SIDEBAR_RIGHT_CONTENT,
    SIDEBAR_RIGHT_WIDTH,
    OFFSET_FETCH_ADVANCED_SEARCH,
    ADVANCED_SEARCH_RECORD_LIMIT,
    INDEX_COLUMN_WIDTH_ADVANCED_SEARCH,
    FILTER_MODES,
    CELL_PADDING_LEFT_RIGHT,
    MIN_COLUMN_RESIZE,
    MAX_COLUMN_RESIZE
} from 'const/gridUI';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import CellOverlay from './cellOverlay';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import {
    HEADER_HEIGHT,
    SCROLLBAR_RELEASE_TIME,
    SIDE_BAR_ADVANCED_SEARCH_WIDTH_OFF,
    SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON
} from 'const';
import { isChrome, isMac, isWindows } from 'utils/os';
import HeaderWrapper from './HeaderWrapper';
import * as columnTypes from 'const/columnTypes';
import {
    useFixedColumnCount,
    useIsFetchingMore,
    useMetaData,
    useQuickFilters,
    useQuickSorts,
    useRecordIds,
    useTotalRecords
} from 'hooks/advanced';
import { getElementOffsetPosition } from 'utils/gridUI/cell';
import isEmpty from 'lodash/isEmpty';

const FAKE_ROW = 0;
const AGG_HEIGHT = 0;

const useStyles = makeStyles(theme => ({
    GridUIRow: {},
    leftCell: {
        outline: 'none',
        overflow: 'hidden !important'
    },
    rightCell: {
        outline: 'none',
        overflow: 'hidden !important',

        [theme.breakpoints.down('sm')]: {
            overflow: 'auto !important'
        },
        '& .ReactVirtualized__Grid__innerScrollContainer': {
            overflow: 'unset !important'
        }
    },
    rightCellGridUI: {
        scrollBehavior: 'smooth',
        outline: 'none'
    },
    HeaderGridUI: {
        width: '100%',
        overflow: 'hidden !important',
        outline: 'none',
        background: theme.colors.background,
        // borderBottom: '1px solid rgb(233, 234, 239)',
        '& .ReactVirtualized__Grid__innerScrollContainer': {
            overflow: 'unset !important'
        }
    },
    LeftSideGridUI: {
        overflow: 'hidden !important',
        outline: 'none'
    },
    GridUIColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    BodyGridUI: {
        width: '100%',
        outline: 'none'
    },
    cell: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        // border: `${BORDER_HIGHLIGHT}px solid transparent`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    cellRow: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: theme.colors.white,
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },

    cellHeader: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    indexCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        background: theme.palette.common.white,
        overflow: 'hidden'
    },
    cellSelected: {
        border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight} !important`
    },
    cellBackgroundSelected: {
        background: theme.colors.selectionColor
    },
    header: {
        fontWeight: 'bold',
        background: `${theme.colors.paleGrey} !important`
    },
    headerSelected: {
        background: `${theme.colors.lightGrey} !important`
    },
    footer: {
        background: `${theme.colors.white} !important`
    },
    uiSelected: {
        background: theme.colors.selectionColor
    },
    reorderCellHighLight: {
        boxShadow: `inset 1px 0px 0px 0px ${theme.colors.highlight}`
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    scrollOverLay: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 100
    },
    simpleBar: {
        '& .simplebar-track': {
            '&.simplebar-horizontal': {
                '& .simplebar-scrollbar': {
                    height: isWindows() && isChrome() && `11px !important`,
                    top: isWindows() && isChrome() ? `0 !important` : `4px !important`
                }
            },
            '&.simplebar-vertical': {
                '& .simplebar-scrollbar':
                    isWindows() && isChrome()
                        ? {
                              width: `11px !important`,
                              '&::before': {
                                  width: '100%',
                                  left: 0
                              }
                          }
                        : {}
            },
            pointerEvents: 'auto'
        },
        '& .simplebar-scrollbar': {
            '&:hover': {
                cursor: 'pointer',
                '&::before': {
                    background: theme.colors.lightGreyBlue
                }
            },
            '&::before': {
                background: theme.colors.lightGrey,
                opacity: 1
            }
        },
        '& .simplebar-content-wrapper':
            isWindows() && isChrome()
                ? {
                      /* Handle */
                      '&::-webkit-scrollbar-thumb': {
                          background: 'transparent'
                      }
                  }
                : {}
    },
    recordHighLightTop: {
        borderTop: props => `${props.borderNumber || 1}px solid ${theme.colors.highlight} !important`
    },
    recordHighLightBottom: {
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.highlight} !important`
    },
    popper: {
        width: 260,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
        '& .MuiPaper-root': {
            padding: theme.spacing(3)
        }
    },
    approve: {
        display: 'inline-flex',
        width: 26,
        height: 26,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${theme.colors.snackBarSuccess}`,
        borderRadius: 4,
        color: `${theme.colors.snackBarSuccess}`,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(3)
    },
    decline: {
        display: 'inline-flex',
        width: 26,
        height: 26,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        color: `${theme.colors.silver}`
        // marginTop: theme.spacing(3),
    },
    addMultiRecords: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.ghostwhite,
        flexBasis: '35%',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.lightGrey
        }
    },
    freezingHandler: {
        '& #freezingHandler': {
            display: 'none'
        },
        '&:hover': {
            '& #freezingHandler': {
                display: 'block'
            }
        }
    },
    nonClick: {
        pointerEvents: 'none'
    }
}));

function GridUIExample({
    width,
    maxHeight,
    rowHeight = ROW_HEIGHT * 2,
    HEADER_ROW_HEIGHT = 36,
    fixedRowCount = 2,
    overscanColumnCount = 0,
    overscanRowCount = 0,
    dependencies,
    ROW_START_INDEX,
    isFilteringSorting,
    columnWidthStore = {},
    accessEditRecords,
    accessRecordHistory,
    accessManageGridRecord,
    accessManageGridColumn,
    contextMenuId,
    isImporting,
    isSideBarOn,
    accessEditTranslation,
    isTmDisabled,
    isShareViewLink,
    t,
    isDisabledCreateRecord,
    fillColorBlindness,
    dependencyBlindness,
    isOverFileStorage,
    ldUserEmailMap,
    isDeletingRecords,
    accessManageTicket,
    isShowTag,
    tokenDetection,
    predefinedTokens,
    isShowAutoQA,
    fetchMoreRows,
    prefix = '_table',
    isViewOnly,
    INDEX_COLUMN_WIDTH = INDEX_COLUMN_WIDTH_ADVANCED_SEARCH,
    columns = []
}) {
    const rows = useRecordIds();

    const metaData = useMetaData();
    const quickFilters = useQuickFilters();
    const quickSorts = useQuickSorts();
    const isFetchingMore = useIsFetchingMore();
    const actualTotalRecords = useTotalRecords();
    const fixedColumnCount = useFixedColumnCount();

    const totalRecords = React.useMemo(() => {
        return rows?.length;
    }, [rows]);

    const isRowOverLimit = React.useMemo(() => {
        return totalRecords > LIMIT_ROWS;
    }, [totalRecords]);
    const theme = useTheme();

    const WIDTH_WHEN_PATH_TAG_ON = React.useMemo(
        () => (isSideBarOn ? SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON : SIDE_BAR_ADVANCED_SEARCH_WIDTH_OFF),
        [isSideBarOn]
    );

    const classes = useStyles({ borderNumber: isRowOverLimit ? 2 : 1 });
    const columnCount = React.useMemo(() => columns.length + FAKE_COLUMN_COUNT, [columns.length]);
    const heightLeftGridUI = React.useMemo(() => HEADER_ROW_HEIGHT * fixedRowCount, [HEADER_ROW_HEIGHT, fixedRowCount]);
    const height = React.useMemo(() => maxHeight - AGG_HEIGHT, [maxHeight]);
    const topRef = React.useRef(0);
    const leftRef = React.useRef(0);
    const onScrollRef = React.useRef();
    const scrollPropsRef = React.useRef();
    const dispatch = useDispatch();

    //GridUILayoutRef
    const gridRef = React.useRef({
        state: {}
    });
    const freezingGridUIRef = React.useRef();
    const leftHeaderRef = React.useRef();
    const rightHeaderRef = React.useRef();

    //scrolling
    const scrollStartIndexRef = React.useRef(0);
    const scrollStopIndexRef = React.useRef(0);

    const isCellCopyDraggingRef = React.useRef(false);

    //freezing handler
    const freezingHighlightRef = React.useRef();
    const isDraggingFreezingRef = React.useRef(false);

    const isFetchingMoreRecordsRef = React.useRef(false);
    const fillColumnsConfirmRef = React.useRef();
    const selectingRef = React.useRef(false);
    const addRecordRef = React.useRef();

    const isColumnReorderDraggingRef = React.useRef(false);
    const timerFetchingRef = React.useRef();
    const columnReorderRef = React.useRef();
    const columnResizeRef = React.useRef();

    const [scrollLeftMore, setScrollLeftMore] = React.useState(false);
    const [scrollRightMore, setScrollRightMore] = React.useState(false);

    const getWidthDistanceFrom0ToIndex = React.useCallback(
        index => {
            return getWidthDistanceToIndex({ fromIndex: 0, toIndex: index, columnWidthStore, columns });
        },
        [columns, columnWidthStore]
    );

    React.useEffect(() => {
        if (scrollBarRef.current) {
            scrollBarRef.current.recalculate();
        }
    }, [columnCount, columnWidthStore, totalRecords, rowHeight]);

    const getColumn = React.useCallback(
        columnIndex => {
            return getColumnData({ columnIndex, columns, metaData });
        },
        [columns, metaData]
    );

    const getColumnWidth = React.useCallback(
        ({ index }) => {
            const column = metaData?.[columns?.[index]];

            if (!column) return DEFAULT_COLUMN_WIDTH;
            const hashColumnId = column?.hashColumnId;
            return columnWidthStore?.[hashColumnId] || DEFAULT_COLUMN_WIDTH;
        },
        [columns, metaData, columnWidthStore]
    );

    const getLeftGridColumnWidth = React.useCallback(
        ({ index }) => {
            if (index === 0) {
                return INDEX_COLUMN_WIDTH;
            }
            const newIndex = index - 1;
            const column = metaData?.[columns?.[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const hashColumnId = column?.hashColumnId;
            return columnWidthStore?.[hashColumnId] || DEFAULT_COLUMN_WIDTH;
        },
        [columns, metaData, columnWidthStore, INDEX_COLUMN_WIDTH]
    );

    const getRightSectionColumnWidth = React.useCallback(
        ({ index }) => {
            let newIndex = index + fixedColumnCount;
            if (newIndex >= columnCount - FAKE_COLUMN_COUNT) {
                return FAKE_COLUMN_COUNT * DEFAULT_COLUMN_WIDTH;
            }
            const column = metaData?.[columns?.[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const hashColumnId = column?.hashColumnId;
            return columnWidthStore?.[hashColumnId] || DEFAULT_COLUMN_WIDTH;
        },
        [columns, columnCount, metaData, columnWidthStore, fixedColumnCount]
    );

    const getTotalFreezingWidth = React.useCallback(() => {
        let arrs = Array.from(Array(fixedColumnCount).keys());
        let total = arrs.reduce((total, columnIndex) => total + getColumnWidth({ index: columnIndex }), 0);
        return total + INDEX_COLUMN_WIDTH;
    }, [fixedColumnCount, INDEX_COLUMN_WIDTH, getColumnWidth]);

    const getRowHeight = React.useCallback(
        ({ index }) => {
            return rowHeight;
        },
        [rowHeight]
    );

    const freeWith = React.useMemo(() => {
        const arr = Array.from(Array(fixedColumnCount).keys());
        return arr.reduce((total, num) => total + getColumnWidth({ index: num }), INDEX_COLUMN_WIDTH);
    }, [fixedColumnCount, getColumnWidth, INDEX_COLUMN_WIDTH]);

    const totalLeftOfColumns = React.useMemo(() => {
        return columns?.reduce((t, colId) => {
            return t + columnWidthStore?.[colId] || DEFAULT_COLUMN_WIDTH;
        }, 0);
    }, [columns, columnWidthStore]);

    //cell range selection

    const scrollToPosition = React.useCallback(({ scrollLeft, scrollTop }) => {
        onScrollRef.current({ scrollLeft, scrollTop });
    }, []);

    const startingTopFreezingHandleWhenDragging = React.useRef();
    const columnRangePositionRef = React.useRef([]);
    const freezingColumnPosition = React.useRef(fixedColumnCount);

    let isDraggingFreeze = React.useRef(false);
    const startDraggingFreezing = React.useCallback(
        e => {
            startingTopFreezingHandleWhenDragging.current = `${e.clientY - TOTAL_GRID_UI_HEADER - 10}px`;
            isDraggingFreezingRef.current = true;
            freezingHighlightRef.current.style.display = 'block';
            const dragHandler = document.getElementById(`${prefix}_freezingHandler`);

            if (dragHandler) {
                dragHandler.style.top = `${e.clientY - TOTAL_GRID_UI_HEADER - 10}px`;
                const { state } = gridRef.current;
                const { scrollTop, scrollLeft } = state;
                if (scrollLeft !== 0) {
                    scrollToPosition({
                        scrollTop,
                        scrollLeft: 0
                    });
                }
            }
            for (let i = 0; i <= columns?.length - 2; i++) {
                let widthFrom0ToIndex = getWidthDistanceFrom0ToIndex(i);
                let columnWidth = getColumnWidth({ index: i });
                let range = {
                    left: widthFrom0ToIndex + INDEX_COLUMN_WIDTH,
                    right: widthFrom0ToIndex + columnWidth + INDEX_COLUMN_WIDTH,
                    index: i + 1,
                    haftWidth: widthFrom0ToIndex + columnWidth / 2
                };

                const POSSIBLE_FREEZING_WIDTH = width - SIDEBAR_RIGHT_CONTENT - SIDEBAR_RIGHT_WIDTH;

                if (range.right < POSSIBLE_FREEZING_WIDTH) {
                    columnRangePositionRef.current.push(range);
                }
            }
        },
        [
            columns.length,
            getColumnWidth,
            width,
            INDEX_COLUMN_WIDTH,
            getWidthDistanceFrom0ToIndex,
            scrollToPosition,
            prefix
        ]
    );

    const freezingStopHandler = React.useCallback(
        e => {
            if (!isDraggingFreeze.current) {
                freezingColumnPosition.current = {
                    index: fixedColumnCount,
                    left: getWidthDistanceFrom0ToIndex(fixedColumnCount) + INDEX_COLUMN_WIDTH
                };
            }
            isDraggingFreezingRef.current = false;
            freezingHighlightRef.current.style.display = 'none';

            let freezingDom = document.getElementById(`${prefix}_freezing`);
            let left = freezingColumnPosition?.current?.left || INDEX_COLUMN_WIDTH;
            let index = freezingColumnPosition?.current?.index || 0;

            if (freezingDom) {
                freezingDom.style.left = `${left}px`;
            }
            dispatch(
                advancedSearchActions.changeFixedColumnCount({
                    index: Math.max(Math.min(columns.length - 1, index), 0)
                })
            );
            freezingColumnPosition.current = null;
            isDraggingFreeze.current = false;
        },
        [dispatch, columns.length, fixedColumnCount, INDEX_COLUMN_WIDTH, getWidthDistanceFrom0ToIndex, prefix]
    );

    const freezingDraggingHandler = React.useCallback(
        e => {
            isDraggingFreeze.current = true;
            if (!isDraggingFreezingRef.current) return;
            let freezingDom = document.getElementById(`${prefix}_freezing`);
            let freezingHighlight = freezingHighlightRef.current;

            if (freezingDom) {
                freezingDom.style.left = `${Math.max(0, e.clientX - WIDTH_WHEN_PATH_TAG_ON)}px`;

                let realLeft = Math.max(0, e.clientX - INDEX_COLUMN_WIDTH - WIDTH_WHEN_PATH_TAG_ON);

                if (realLeft === 0) {
                    if (freezingHighlight && freezingColumnPosition.current) {
                        freezingHighlight.style.left = `${INDEX_COLUMN_WIDTH - 1}px`;
                    }
                    freezingColumnPosition.current = {
                        index: 0,
                        left: INDEX_COLUMN_WIDTH
                    };
                    return;
                }

                const columnRangePosition = columnRangePositionRef.current;
                columnRangePosition.forEach(range => {
                    const { left, right, index, haftWidth } = range;
                    if (realLeft > left && realLeft < right) {
                        if (realLeft < haftWidth) {
                            freezingHighlight.style.left = `${left - 1}px`;
                            freezingColumnPosition.current = {
                                index: index - 1,
                                left
                            };
                        } else {
                            freezingHighlight.style.left = `${right - 1}px`;
                            freezingColumnPosition.current = {
                                index,
                                left: right
                            };
                        }
                    }
                });
            }
        },
        [WIDTH_WHEN_PATH_TAG_ON, prefix, INDEX_COLUMN_WIDTH]
    );

    const setFreezingTopPosition = e => {
        let dragHandler = document.getElementById(`freezingHandler`);
        if (dragHandler && !isDraggingFreezingRef.current) {
            dragHandler.style.top = `${
                e.clientY - isShareViewLink ? HEADER_HEIGHT : HEADER_HEIGHT + 108 + 52 + 37 - 10
            }px`;
        }
    };

    /**
     * SELECTION:
     */

    const recomputeGridUI = () => {
        gridRef.current.recomputeGridSize();
        freezingGridUIRef.current.recomputeGridSize();
        leftHeaderRef.current.recomputeGridSize();
        rightHeaderRef.current.recomputeGridSize();
    };

    React.useEffect(() => {
        recomputeGridUI();
    }, [rowHeight, columnWidthStore]);

    React.useEffect(() => {
        const MainGridUI = document.getElementById(`${prefix}_RightCell`);
        const HeaderGridUI = document.getElementById(`${prefix}_RightCellHeader`);
        const LeftGridUI = document.getElementById(`${prefix}_LeftCell`);
        const FooterGridUI = document.getElementById(`${prefix}_RightCellFooter`);

        const scrollbarOverlay = scrollBarRef.current.getScrollElement();

        const { _rowStartIndex, _rowStopIndex } = gridRef.current;
        scrollStartIndexRef.current = _rowStartIndex;
        scrollStopIndexRef.current = _rowStopIndex;

        dispatch(
            advancedSearchActions.saveTableInfo({
                tableInfo: {
                    scroll: onScrollRef.current,
                    width,
                    height,
                    gridRef: gridRef.current,
                    scrollbarOverlay,
                    fakeColumnCount: FAKE_COLUMN_COUNT
                }
            })
        );

        const normalSpeed = 60;
        const speed = isMac()
            ? normalSpeed
            : normalSpeed *
              (rowHeight <= ROW_HEIGHT_OPTIONS.DEFAULT ? 2 : rowHeight <= ROW_HEIGHT_OPTIONS.COMFORTABLE ? 4 : 6);

        SmoothScroll({
            target: scrollbarOverlay,
            speed,
            smooth: 8,
            moveTopTargets: [LeftGridUI, MainGridUI],
            moveLeftTargets: [HeaderGridUI, FooterGridUI, MainGridUI],
            isNeedExtra: true
        });
        SmoothScroll({
            target: MainGridUI,
            speed,
            smooth: 8,
            moveTopTargets: [LeftGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, FooterGridUI, scrollbarOverlay],
            isNeedExtra: true
        });

        SmoothScroll({
            target: LeftGridUI,
            speed,
            smooth: 8,
            moveTopTargets: [MainGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, FooterGridUI, scrollbarOverlay],
            isIgnoreLeftScroll: true,
            isNeedExtra: true
        });
    }, [dispatch, width, height, rowHeight, prefix]);

    React.useEffect(() => {
        if (!isFilteringSorting && ROW_START_INDEX === 0) {
            const { _rowStartIndex, _rowStopIndex } = gridRef.current;
            scrollStartIndexRef.current = _rowStartIndex;
            scrollStopIndexRef.current = _rowStopIndex;
            isFetchingMoreRecordsRef.current = false;
        }
    }, [isFilteringSorting, ROW_START_INDEX]);

    const generateDurationBaseDistance = React.useCallback(distance => {
        //1s = 400px
        const WIDTH_DEFAULT = 400;
        return Math.round((distance / WIDTH_DEFAULT) * 1000, 0);
    }, []);

    const scrollXTimerLeftRef = React.useRef();

    const highlightBar = React.useCallback(
        ({ scrollLeft }) => {
            let columnReorderReleaseHighlight = document.getElementById(`${prefix}_columnReorderReleaseHighlight`);
            const columnReorderRangePosition = columnReorderRangePositionRef.current;
            const moveLeft = moveLeftRef.current;
            if (!isEmpty(columnReorderRangePositionRef.current) && !isEmpty(columnReorderRef.current)) {
                const { columnsSelected, isMulti, columnId } = columnReorderRef.current;
                columnReorderRangePosition.forEach(range => {
                    const { left, right, columnId: columnIdRange, haftWidth, columnIdLeft, columnIdRight } = range;
                    const leftColumn = Math.abs(scrollLeft - left);
                    const rightColumn = Math.abs(scrollLeft - right);
                    const haftColumm = Math.abs(scrollLeft - haftWidth);

                    if (moveLeft > leftColumn && moveLeft < rightColumn) {
                        if (isMulti) {
                            if (!columnsSelected.includes(columnIdRange)) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        } else {
                            if (columnId !== columnIdRange) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        }
                    }
                });
            }
        },
        [WIDTH_WHEN_PATH_TAG_ON, prefix]
    );

    const scrollTo = React.useCallback(
        ({ element, to, duration, isDisabledScroll, isRight }) => {
            let start = element.scrollLeft,
                change = to - start,
                currentTime = 0,
                increment = 10;
            const changeABS = Math.abs(change);

            let animateScroll = function() {
                if (isDisabledScroll) {
                    clearTimeout(scrollXTimerLeftRef && scrollXTimerLeftRef.current);
                    return;
                }
                if (currentTime < changeABS) {
                    currentTime += increment;
                    let val = Math.easeInOutQuad(currentTime, start, change, changeABS);
                    element.scrollLeft = val;
                    highlightBar({ scrollLeft: val });
                    scrollXTimerLeftRef.current = setTimeout(animateScroll, increment);
                }
            };
            animateScroll();
        },
        [highlightBar]
    );

    Math.easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    Math.linearTween = function(t, b, c, d) {
        return (c * t) / d + b;
    };

    React.useEffect(() => {
        const { state } = gridRef.current;
        const { scrollLeft } = state;
        const MainGridUI = document.getElementById(`${prefix}_RightCell`);
        if (scrollLeft !== 0) {
            scrollTo({
                element: MainGridUI,
                to: 0,
                duration: generateDurationBaseDistance(scrollLeft),
                isDisabledScroll: !scrollLeftMore,
                isRight: false
            });
        }
    }, [scrollLeftMore, scrollTo, generateDurationBaseDistance, prefix]);

    //scroll left right
    React.useEffect(() => {
        const { state } = gridRef.current;
        const { scrollLeft } = state;
        const MainGridUI = document.getElementById(`${prefix}_RightCell`);
        const ALL_COLUMNS_WIDTH = totalLeftOfColumns;

        if (scrollLeft !== ALL_COLUMNS_WIDTH) {
            scrollTo({
                element: MainGridUI,
                to: ALL_COLUMNS_WIDTH,
                duration: generateDurationBaseDistance(ALL_COLUMNS_WIDTH),
                isDisabledScroll: !scrollRightMore,
                isRight: true
            });
        }
    }, [scrollRightMore, totalLeftOfColumns, scrollTo, prefix, generateDurationBaseDistance]);

    const OFFSET_MORE_LEFT = SPACING_LIST_NAME_WITH_ICON + CELL_PADDING_LEFT_RIGHT + 18;
    const columnReorderRangePositionRef = React.useRef([]);
    const isColumnResizeDraggingRef = React.useRef(false);

    const getWidthDistanceOfColumnList = React.useCallback(
        columnIds => {
            return getWidthOfColumnList({ columnIds, columnWidthStore });
        },
        [columnWidthStore]
    );

    const setColumnReorderStartPosition = React.useCallback(
        ({ e, columnId, columnsSelected, isMulti }) => {
            isColumnReorderDraggingRef.current = true;
            let reorderHandler = document.getElementById(`${prefix}_columnReorderHighlight`);

            if (reorderHandler) {
                let width = 200;
                let newLeft = 0;
                let MORE_LEFT = 0;
                const { left } = getElementOffsetPosition(e);
                const currentLeft = e.clientX;
                if (!isMulti) {
                    width = columnWidthStore?.[columnId] || DEFAULT_COLUMN_WIDTH;
                    newLeft = left - OFFSET_MORE_LEFT;
                    MORE_LEFT = OFFSET_MORE_LEFT + (currentLeft - left);
                } else {
                    width = getWidthDistanceOfColumnList(columnsSelected);
                    let offsetLeft = 0;
                    if (columnId !== columnsSelected[0]) {
                        let currentColumnIndex = columnsSelected.findIndex(id => id === columnId);
                        let offsetColumns = columnsSelected.slice(0, currentColumnIndex);
                        offsetLeft = getWidthDistanceOfColumnList(offsetColumns);
                    }
                    MORE_LEFT = OFFSET_MORE_LEFT + offsetLeft + (currentLeft - left);
                    newLeft = left - offsetLeft - OFFSET_MORE_LEFT;
                }

                for (let i = 0; i <= columns.length - 1; i++) {
                    if (i === 0) continue;
                    let widthFrom0ToIndex = getWidthDistanceFrom0ToIndex(i);
                    let columnWidth = getColumnWidth({ index: i });
                    const leftSide = widthFrom0ToIndex + INDEX_COLUMN_WIDTH + WIDTH_WHEN_PATH_TAG_ON;
                    const rightSide = widthFrom0ToIndex + columnWidth + INDEX_COLUMN_WIDTH + WIDTH_WHEN_PATH_TAG_ON;
                    let range = {
                        left: leftSide,
                        right: rightSide,
                        columnId: columns[i],
                        columnIdLeft: columns[i - 1] || null,
                        columnIdRight: columns[i + 1] || null,
                        haftWidth: leftSide + (rightSide - leftSide) / 2
                    };
                    columnReorderRangePositionRef.current.push(range);
                }

                reorderHandler.style.width = `${width}px`;
                reorderHandler.style.left = `${newLeft - WIDTH_WHEN_PATH_TAG_ON}px`;
                columnReorderRef.current = {
                    isMulti,
                    columnsSelected,
                    columnId,
                    width,
                    left,
                    MORE_LEFT
                };
            }
        },
        [
            prefix,
            INDEX_COLUMN_WIDTH,
            OFFSET_MORE_LEFT,
            getWidthDistanceOfColumnList,
            columnWidthStore,
            columns,
            getWidthDistanceFrom0ToIndex,
            getColumnWidth,
            WIDTH_WHEN_PATH_TAG_ON
        ]
    );

    const columnReorderStopHandler = React.useCallback(
        e => {
            setScrollLeftMore(false);
            setScrollRightMore(false);
            isColumnReorderDraggingRef.current = false;

            let columnReorderHighlight = document.getElementById(`${prefix}_columnReorderHighlight`);
            let columnReorderReleaseHighlight = document.getElementById(`${prefix}_columnReorderReleaseHighlight`);

            if (columnReorderHighlight) {
                columnReorderHighlight.style.left = `-10000px`;
            }

            if (columnReorderReleaseHighlight) {
                columnReorderReleaseHighlight.style.left = `-10000px`;
            }

            if (columnIdReleaseRef.current) {
                const { columnId, columnsSelected, columnIdLeft, columnIdRight, isLeft } = columnIdReleaseRef.current;

                if (
                    (!isLeft && columnsSelected.includes(columnIdRight)) ||
                    (isLeft && columnsSelected.includes(columnIdLeft))
                ) {
                    return false;
                }

                const { columnId: draggingColumnId } = columnReorderRef.current;

                const body = {};
                if (isLeft && !columnIdLeft) {
                    body['beforeColumn'] = columnId;
                } else if (isLeft && columnIdLeft) {
                    if (columnIdLeft === columnTypes.RESOURCE) {
                        body['beforeColumn'] = columnId;
                    } else {
                        body['afterColumn'] = columnIdLeft;
                    }
                } else if (!isLeft && !columnIdRight) {
                    body['afterColumn'] = columnId;
                } else if (!isLeft && columnIdRight) {
                    body['beforeColumn'] = columnIdRight;
                }

                console.log('body', body);

                dispatch(
                    advancedSearchActions.reorderColumns({
                        ...body,
                        columnId,
                        isLeft,
                        reorderColumns: columnsSelected,
                        successCallback: () => {
                            recomputeGridUI();
                            dispatch(
                                advancedSearchActions.setColumnsSelection({
                                    columnsSelected,
                                    columnId: draggingColumnId
                                })
                            );
                        }
                    })
                );
            }
            columnReorderRef.current = null;
            columnReorderRangePositionRef.current = [];
            columnIdReleaseRef.current = null;
        },
        [prefix, dispatch]
    );

    const columnIdReleaseRef = React.useRef(null);
    const moveLeftRef = React.useRef();

    const columnReorderDraggingHandler = React.useCallback(
        e => {
            if (!isColumnReorderDraggingRef.current) return;
            let columnReorderHighlight = document.getElementById(`${prefix}_columnReorderHighlight`);
            let columnReorderReleaseHighlight = document.getElementById(`${prefix}_columnReorderReleaseHighlight`);

            if (columnReorderHighlight && columnReorderReleaseHighlight) {
                const FREEZING_WIDTH = getTotalFreezingWidth();
                const ALL_COLUMNS_WIDTH = totalLeftOfColumns;
                const moveLeft = e.clientX;
                const { MORE_LEFT, width: reorderWidth, columnsSelected, isMulti, columnId } = columnReorderRef.current;
                const leftDragging = moveLeft - MORE_LEFT;
                const rightDragging = leftDragging + reorderWidth;
                columnReorderHighlight.style.left = `${leftDragging - WIDTH_WHEN_PATH_TAG_ON}px`;
                const { state } = gridRef.current;
                const { scrollLeft } = state;
                const WIDTH = Math.abs(width + WIDTH_WHEN_PATH_TAG_ON);
                const LEFT_EGDE_SIDE = FREEZING_WIDTH + WIDTH_WHEN_PATH_TAG_ON;

                moveLeftRef.current = moveLeft;
                if (leftDragging < LEFT_EGDE_SIDE && scrollLeft !== 0) {
                    setScrollLeftMore(true);
                } else {
                    setScrollLeftMore(false);
                }

                if (rightDragging > WIDTH && scrollLeft !== ALL_COLUMNS_WIDTH) {
                    setScrollRightMore(true);
                } else {
                    setScrollRightMore(false);
                }
                const columnReorderRangePosition = columnReorderRangePositionRef.current;

                columnReorderRangePosition.forEach(range => {
                    const { left, right, columnId: columnIdRange, haftWidth, columnIdLeft, columnIdRight } = range;
                    const leftColumn = Math.abs(scrollLeft - left);
                    const rightColumn = Math.abs(scrollLeft - right);
                    const haftColumm = Math.abs(scrollLeft - haftWidth);

                    if (moveLeft > leftColumn && moveLeft < rightColumn) {
                        if (isMulti) {
                            if (!columnsSelected.includes(columnIdRange)) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: columnsSelected,
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        } else {
                            if (columnId !== columnIdRange) {
                                if (moveLeft < haftColumm) {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: true
                                    };
                                    columnReorderReleaseHighlight.style.left = `${leftColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                } else {
                                    columnIdReleaseRef.current = {
                                        columnId: columnIdRange,
                                        columnIdLeft,
                                        columnIdRight,
                                        columnsSelected: [columnId],
                                        isLeft: false
                                    };
                                    columnReorderReleaseHighlight.style.left = `${rightColumn -
                                        WIDTH_WHEN_PATH_TAG_ON}px`;
                                }
                            } else {
                                columnIdReleaseRef.current = null;
                                columnReorderReleaseHighlight.style.left = `${-10000}px`;
                            }
                        }
                    }
                });
            }
        },
        [width, totalLeftOfColumns, getTotalFreezingWidth, WIDTH_WHEN_PATH_TAG_ON, prefix]
    );

    const setColumnHandlerStartPosition = React.useCallback(
        ({ e, columnId }) => {
            isColumnResizeDraggingRef.current = true;
            let resizeHandler = document.getElementById(`${prefix}_columnResizeHighlight`);
            let columnResizeHighlightNumber = document.getElementById(`${prefix}_columnResizeHighlightNumber`);

            if (resizeHandler) {
                const { left } = getElementOffsetPosition(e);
                let currentColumnWidth = columnWidthStore?.[columnId] || DEFAULT_COLUMN_WIDTH;
                console.log('left - WIDTH_WHEN_PATH_TAG_ON', left - WIDTH_WHEN_PATH_TAG_ON);
                resizeHandler.style.left = `${left - WIDTH_WHEN_PATH_TAG_ON}px`;
                columnResizeRef.current = {
                    leftColumnX: left - currentColumnWidth,
                    rightColumnX: left,
                    columnId,
                    currentColumnWidth
                };

                if (columnResizeHighlightNumber) {
                    columnResizeHighlightNumber.textContent = `${Math.round(currentColumnWidth)}px`;
                }
            }
        },
        [columnWidthStore, WIDTH_WHEN_PATH_TAG_ON, prefix]
    );

    const columnResizeDraggingHandler = React.useCallback(
        e => {
            if (!isColumnResizeDraggingRef.current) return;
            let columnResizeHighlight = document.getElementById(`${prefix}_columnResizeHighlight`);
            let columnResizeHighlightNumber = document.getElementById(`${prefix}_columnResizeHighlightNumber`);
            if (columnResizeHighlight) {
                let { leftColumnX } = columnResizeRef.current;
                let left = e.clientX;
                if (left - leftColumnX < MIN_COLUMN_RESIZE) {
                    columnResizeHighlight.style.left = `${leftColumnX - WIDTH_WHEN_PATH_TAG_ON + MIN_COLUMN_RESIZE}px`;
                } else {
                    columnResizeHighlight.style.left = `${left - WIDTH_WHEN_PATH_TAG_ON}px`;
                }
                columnResizeRef.current.stopPostion = left;
            }

            if (columnResizeHighlightNumber) {
                const { leftColumnX, rightColumnX, currentColumnWidth, stopPostion } = columnResizeRef.current;
                let columnWidthChange = 0;
                if (stopPostion > rightColumnX) {
                    columnWidthChange = currentColumnWidth + (stopPostion - rightColumnX);
                } else {
                    if (stopPostion - leftColumnX < MIN_COLUMN_RESIZE) {
                        columnWidthChange = MIN_COLUMN_RESIZE;
                    } else {
                        columnWidthChange = currentColumnWidth - (rightColumnX - stopPostion);
                    }
                }
                columnWidthChange = Math.min(columnWidthChange, MAX_COLUMN_RESIZE);
                columnResizeHighlightNumber.textContent = `${Math.round(columnWidthChange)}px`;
            }
        },
        [WIDTH_WHEN_PATH_TAG_ON, prefix]
    );

    const columnResizeStopHandler = React.useCallback(() => {
        isColumnResizeDraggingRef.current = false;
        let columnResizeHighlight = document.getElementById(`${prefix}_columnResizeHighlight`);
        if (columnResizeHighlight) {
            columnResizeHighlight.style.left = `-10000px`;
        }
        const { leftColumnX, rightColumnX, columnId, currentColumnWidth, stopPostion } = columnResizeRef.current;
        let columnWidthChange = 0;
        if (stopPostion > rightColumnX) {
            columnWidthChange = currentColumnWidth + (stopPostion - rightColumnX);
        } else {
            if (stopPostion - leftColumnX < MIN_COLUMN_RESIZE) {
                columnWidthChange = MIN_COLUMN_RESIZE;
            } else {
                columnWidthChange = currentColumnWidth - (rightColumnX - stopPostion);
            }
        }
        columnWidthChange = Math.min(columnWidthChange, MAX_COLUMN_RESIZE);
        dispatch(
            advancedSearchActions.resizeColumn({
                columnId,
                columnWidthChange
            })
        );
        columnResizeRef.current = null;
    }, [dispatch, prefix]);

    const _renderHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const isLastColumn = columnIndex >= columnCount - FAKE_COLUMN_COUNT;

            const column = getColumn(columnIndex);

            if (!column) return <span key={key} />;

            const columnWidth = getColumnWidth({ index: columnIndex });

            return (
                <HeaderWrapper
                    key={key}
                    style={style}
                    isLastColumn={isLastColumn}
                    columnId={column?.hashColumnId}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                >
                    {rowIndex === 0 ? (
                        <Header
                            id={`${prefix}_grid-header-${columnIndex}`}
                            accessManageGridColumn={accessManageGridColumn}
                            accessManageGridRecord={accessManageGridRecord}
                            columnIndex={columnIndex}
                            column={column}
                            setColumnHandlerStartPosition={setColumnHandlerStartPosition}
                            setColumnReorderStartPosition={setColumnReorderStartPosition}
                            columns={columns}
                            isImporting={isImporting}
                            t={t}
                            isShareViewLink={isShareViewLink}
                            columnWidth={columnWidth}
                        />
                    ) : (
                        columnTypes.RESOURCE !== column?.type && (
                            <HeaderFilter
                                id={`${prefix}_grid-header-filter-${columnIndex}`}
                                column={column}
                                quickSorts={quickSorts}
                                quickFilters={quickFilters}
                                columnWidth={columnWidth}
                                isImporting={isImporting}
                                dependencies={dependencies}
                                t={t}
                                columnIndex={columnIndex}
                            />
                        )
                    )}
                </HeaderWrapper>
            );
        },
        [
            setColumnHandlerStartPosition,
            setColumnReorderStartPosition,
            prefix,
            accessManageGridRecord,
            accessManageGridColumn,
            isShareViewLink,
            // columnStartIndex,
            // columnStopIndex,
            dependencies,

            t,
            isImporting,
            // contextMenuId,
            getColumn,
            quickSorts,
            // isSelectionActive,
            quickFilters,
            getColumnWidth,
            columns,
            columnCount
        ]
    );

    const handleCellClick = React.useCallback(
        (e, { rowIndex, columnIndex, rowId, columnId }) => {
            if (document.getElementById(`${prefix}_retranslation-processing`)) {
                return;
            }
            const { isShift } = getStatusCtrlOrShiftKey(e);

            dispatch(advancedSearchActions.cellClick({ isShift, rowId, columnId, rowIndex, columnIndex }));
        },
        [dispatch, prefix]
    );

    const handleCellMouseDown = React.useCallback(
        (e, { rowIndex, columnIndex }) => {
            if (document.getElementById(`${prefix}_retranslation-processing`)) {
                return;
            }
            if (isCellCopyDraggingRef.current) return;
            timerRef.current = setTimeout(() => {
                selectingRef.current = true;
                dispatch(advancedSearchActions.setSelecting(true));
                dispatch(
                    advancedSearchActions.selectRangeCell({
                        rowStopIndex: +rowIndex,
                        rowStartIndex: +rowIndex,
                        columnStartIndex: +columnIndex,
                        columnStopIndex: +columnIndex
                    })
                );
            }, 150);
        },
        [dispatch, prefix]
    );

    // const startDraggingCellCopy = React.useCallback(
    //     e => {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         isCellCopyDraggingRef.current = true;
    //         dispatch(advancedSearchActions.setSelectingCopy(true));
    //     },
    //     [dispatch]
    // );

    const handleCellMouseMove = React.useCallback(
        (e, { rowIndex, columnIndex }) => {
            if (selectingRef.current) {
                dispatch(
                    advancedSearchActions.selectRangeCell({
                        columnStartIndex: +columnIndex,
                        columnStopIndex: +columnIndex,
                        rowStartIndex: +rowIndex,
                        rowStopIndex: +rowIndex,
                        isMoving: true
                    })
                );
            }

            // if (isCellCopyDraggingRef.current) {
            //     dispatch(
            //         advancedSearchActions.selectingCellCopySelection({
            //             rowDragStopIndex: +rowIndex
            //         })
            //     );
            // }
        },
        [dispatch]
    );

    // const handleStopCellCopy = React.useCallback(
    //     e => {
    //         if (isCellCopyDraggingRef.current) {
    //             isCellCopyDraggingRef.current = false;
    //             setScrollBottomMore(false);
    //             setScrollTopMore(false);
    //             dispatch(gridUIActions.setSelectingCopy(false));
    //             dispatch(gridUIActions.stopCellCopySelection(e));
    //         }
    //     },
    //     [dispatch]
    // );

    const doingNothing = () => {
        console.log('hehe');
    };

    const _renderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style, parent }) => {
            const columnId = columns[columnIndex];
            const filter = quickFilters?.[columnId];
            const filterValue = filter?.value;
            const isCaseSensitive = filter?.filterMode === FILTER_MODES.CASE_SENSITIVE;

            const rowId = rows?.[rowIndex - ROW_START_INDEX];
            const previousRowId = rows?.[rowIndex - ROW_START_INDEX - 1];
            const nextRowId = rows?.[rowIndex - ROW_START_INDEX + 1];

            const realColumnWidth = getColumnWidth({ index: columnIndex });

            const maxRowIndex = totalRecords - FAKE_ROW;
            const isLastRow = rowIndex === maxRowIndex;
            const isLastColumn = columnIndex >= columnCount - FAKE_COLUMN_COUNT;

            const positionTop = style.top;
            const positionBottom = style.top + style.height;

            if (rowIndex > maxRowIndex) return;

            if (isLastRow || isLastColumn)
                return (
                    <div
                        pst={positionTop}
                        psb={positionBottom}
                        prid={previousRowId}
                        nrid={nextRowId}
                        rindex={rowIndex}
                        key={key}
                        className={`RECORD_${rowId}`}
                        style={{
                            ...style,
                            pointerEvents: 'none',
                            background: 'transparent'
                        }}
                    />
                );

            return (
                <div
                    onClick={e =>
                        isViewOnly ? doingNothing : handleCellClick(e, { rowIndex, columnIndex, rowId, columnId })
                    }
                    onMouseDown={e => (isViewOnly ? doingNothing : handleCellMouseDown(e, { rowIndex, columnIndex }))}
                    onMouseEnter={e => (isViewOnly ? doingNothing : handleCellMouseMove(e, { rowIndex, columnIndex }))}
                    key={key}
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rindex={rowIndex}
                    className={`${classes.cellRow} 
                        ${isImporting ? classes.disabled : ''}
                        cell_${rowIndex}_${columnIndex} 
                         RECORD_${rowId} ${isViewOnly && classes.nonClick}`}
                    style={style}
                >
                    <CellRow
                        accessManageTicket={accessManageTicket}
                        isOverFileStorage={isOverFileStorage}
                        accessManageGridRecord={accessManageGridRecord}
                        isShareViewLink={isShareViewLink}
                        positionTop={positionTop}
                        positionBottom={positionBottom}
                        previousRowId={previousRowId}
                        nextRowId={nextRowId}
                        dependencies={dependencies}
                        isTmDisabled={isTmDisabled}
                        isShowTag={isShowTag}
                        fillColorBlindness={fillColorBlindness}
                        dependencyBlindness={dependencyBlindness}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        columnId={columnId}
                        rowId={rowId}
                        rowHeight={rowHeight}
                        columnWidth={realColumnWidth}
                        isRowOverLimit={isRowOverLimit}
                        filterValue={filterValue}
                        accessRecordHistory={accessRecordHistory}
                        accessEditTranslation={accessEditTranslation}
                        t={t}
                        ldUserEmailMap={ldUserEmailMap}
                        tokenDetection={tokenDetection}
                        predefinedTokens={predefinedTokens}
                        isShowAutoQA={isShowAutoQA}
                        isForcedShowDependencyStatus={true}
                        isCaseSensitive={isCaseSensitive}
                    />
                </div>
            );
        },
        [
            isViewOnly,
            isShowAutoQA,
            predefinedTokens,
            tokenDetection,
            isShowTag,
            rowHeight,
            handleCellClick,
            handleCellMouseMove,
            handleCellMouseDown,
            accessManageTicket,
            ldUserEmailMap,
            isOverFileStorage,
            fillColorBlindness,
            dependencyBlindness,
            accessManageGridRecord,
            isShareViewLink,
            t,
            isTmDisabled,
            accessEditTranslation,
            dependencies,
            isImporting,
            accessRecordHistory,
            totalRecords,
            getColumnWidth,
            quickFilters,
            classes,
            columns,
            columnCount,
            rows,
            ROW_START_INDEX,
            isRowOverLimit
        ]
    );

    const _renderIndexHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            return (
                <div
                    key={key}
                    style={style}
                    className={`${classes.cellHeader} ${rowIndex === 0 ? classes.header : ''}`}
                />
            );
        },
        [classes]
    );

    const _renderLeftHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            //render index column
            if (columnIndex === 0) {
                return _renderIndexHeaderCell({ columnIndex, rowIndex, key, style });
            }
            //render rest column of left grid.
            const leftColumnIndex = columnIndex - 1;
            // const columnWidth = getColumnWidth({ index: leftColumnIndex });
            // const position = {
            //     left: getWidthDistanceFrom0ToIndex(leftColumnIndex) + INDEX_COLUMN_WIDTH + WIDTH_WHEN_PATH_TAG_ON,
            //     top: fixedRowCount * rowHeight
            // };

            return _renderHeaderCell({
                columnIndex: leftColumnIndex,
                key,
                rowIndex,
                // position,
                style
            });
        },
        [
            _renderIndexHeaderCell,
            _renderHeaderCell
            // rowHeight,
            // getColumnWidth,
            // getWidthDistanceFrom0ToIndex,
            // WIDTH_WHEN_PATH_TAG_ON
        ]
    );

    const _renderRightHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            // const columnWidth = getColumnWidth({ index: newColumnIndex });
            // const position = {
            //     left:
            //         getTotalFreezingWidth() +
            //         getWidthDistanceFromIndexToIndex({ fromIndex: fixedColumnCount, toIndex: newColumnIndex }) -
            //         leftRef.current +
            //         WIDTH_WHEN_PATH_TAG_ON,
            //     top: fixedRowCount * rowHeight
            // };
            return _renderHeaderCell({
                columnIndex: newColumnIndex,
                rowIndex,
                key,
                style
                // position
            });
        },
        [
            _renderHeaderCell,
            fixedColumnCount
            // rowHeight,
            // fixedRowCount,
            // getWidthDistanceFromIndexToIndex,
            // getTotalFreezingWidth,
            // getColumnWidth,
            // WIDTH_WHEN_PATH_TAG_ON
        ]
    );

    const _renderIndexCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            // const { state } = gridRef.current;
            // const { scrollTop } = state;
            const rowId = rows?.[rowIndex - ROW_START_INDEX];
            const previousRowId = rows?.[rowIndex - ROW_START_INDEX - 1];
            const nextRowId = rows?.[rowIndex - ROW_START_INDEX + 1];

            const maxRowIndex = totalRecords - FAKE_ROW;

            const positionTop = style.top;
            const positionBottom = style.top + style.height;
            // const highlightReOrder = highLightReOrderRecordClassName({ rowId });

            if (rowIndex > maxRowIndex) return;

            return (
                <div
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rindex={rowIndex}
                    key={key}
                    style={style}
                    className={`${classes.indexCell} RECORD_${rowId}`}
                >
                    <IndexCell
                        accessEditRecords={accessEditRecords}
                        isShareViewLink={isShareViewLink}
                        positionTop={positionTop}
                        positionBottom={positionBottom}
                        previousRowId={previousRowId}
                        nextRowId={nextRowId}
                        isPathTagOn={isSideBarOn}
                        isImporting={isImporting}
                        contextMenuId={contextMenuId}
                        // recordHistoryId={recordHistoryId}
                        // isDragging={isRowResizeDragging}
                        rowId={rowId}
                        rowIndex={rowIndex}
                        // setRowHandlerStartPosition={setRowHandlerStartPosition}
                        accessManageGridRecord={accessManageGridRecord}
                        accessRecordHistory={accessRecordHistory}
                        accessEditTranslation={accessEditTranslation}
                        t={t}
                        isDisabledCreateRecord={isDisabledCreateRecord}
                    />
                </div>
            );
        },
        [
            // rowStartIndex,
            // rowStopIndex,
            accessEditRecords,
            isShareViewLink,
            t,
            // highLightReOrderRecordClassName,
            accessEditTranslation,
            isSideBarOn,
            isImporting,
            contextMenuId,
            accessRecordHistory,
            accessManageGridRecord,
            // recordHistoryId,
            totalRecords,
            classes,
            // getRowHeight,
            rows,
            ROW_START_INDEX,
            // isRowResizeDragging,
            // setRowHandlerStartPosition,
            isDisabledCreateRecord
        ]
    );

    const _renderLeftCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            //render index cell body
            if (columnIndex === 0) {
                return _renderIndexCell({ columnIndex, rowIndex, key, style, parent });
            }
            const newColumnIndex = columnIndex - 1;

            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style,
                parent
            });
        },
        [_renderIndexCell, _renderCell]
    );

    const _renderRightCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style,
                // position,
                parent
            });
        },
        [_renderCell, fixedColumnCount]
    );

    const handleOnSectionRendered = React.useCallback(
        ({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, ...other }) => {
            if (isDeletingRecords) return;

            if (totalRecords < ADVANCED_SEARCH_RECORD_LIMIT) return;

            if (totalRecords >= actualTotalRecords) return;

            if (isFetchingMore) return;

            if (!isFetchingMore && rowStopIndex > totalRecords - OFFSET_FETCH_ADVANCED_SEARCH) {
                fetchServer();
                console.log('fetch server');
            }

            function fetchServer() {
                if (timerFetchingRef.current) clearTimeout(timerFetchingRef.current);

                timerFetchingRef.current = setTimeout(() => {
                    console.log('fetch More rows');
                    fetchMoreRows && fetchMoreRows();
                }, 100);
            }
        },
        [totalRecords, isFetchingMore, fetchMoreRows, isDeletingRecords, actualTotalRecords]
    );
    const timerSimpleBarScroll = React.useRef();

    const handleOverlayScroll = React.useCallback(e => {
        const gridState = gridRef.current?.state;
        const scrollRef = scrollableNodeRef?.current;

        const isScrollHorizontalOnly =
            gridState?.scrollTop === scrollRef?.scrollTop && gridState?.scrollLeft !== scrollRef?.scrollLeft;

        if (timerSimpleBarScroll.current) clearTimeout(timerSimpleBarScroll.current);

        timerSimpleBarScroll.current = setTimeout(
            () => {
                const target = scrollableNodeRef?.current;
                onScrollRef.current({ scrollTop: target?.scrollTop, scrollLeft: target?.scrollLeft });
            },
            isScrollHorizontalOnly ? 0 : SCROLLBAR_RELEASE_TIME
        );
    }, []);

    const timerRef = React.useRef();

    const stoppingCellSelectionHandler = React.useCallback(
        e => {
            if (timerRef.current) clearTimeout(timerRef.current);
            if (isDraggingFreezingRef.current) {
                freezingStopHandler();
            }

            if (isColumnReorderDraggingRef.current) {
                columnReorderStopHandler();
            }

            if (isColumnResizeDraggingRef.current) {
                columnResizeStopHandler();
            }

            if (selectingRef.current) {
                selectingRef.current = false;
            }
        },
        [freezingStopHandler, columnReorderStopHandler, columnResizeStopHandler]
    );

    const selectingCellSelectionHandler = React.useCallback(
        e => {
            if (isDraggingFreezingRef.current) {
                freezingDraggingHandler(e);
            }

            if (isColumnResizeDraggingRef.current) {
                columnResizeDraggingHandler(e);
            }

            if (isColumnReorderDraggingRef.current) {
                columnReorderDraggingHandler(e);
            }
        },
        [freezingDraggingHandler, columnReorderDraggingHandler, columnResizeDraggingHandler]
    );

    const scrollBarRef = React.useRef();
    const scrollableNodeRef = React.useRef();

    const cellRangeRenderer = React.useCallback(
        ({ type, ...rest }) => {
            const children = defaultCellRangeRenderer(rest);
            children.push(<CellOverlay isViewOnly={isViewOnly} key="cell-overlay" type={type} />);
            return children;
        },
        [isViewOnly]
    );

    return (
        <ScrollSync>
            {({ clientHeight, clientWidth, onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth }) => {
                return (
                    <div
                        onMouseUp={stoppingCellSelectionHandler}
                        // onMouseLeave={stoppingCellSelectionHandler}
                        // onMouseDown={startingCellSelectionHandler}
                        onMouseMove={selectingCellSelectionHandler}
                        style={{
                            width,
                            height: height
                        }}
                        className={classes.gridRow}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: heightLeftGridUI,
                                width
                            }}
                            id={'gridTable'}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: freeWith
                                }}
                            >
                                <Grid
                                    ref={leftHeaderRef}
                                    id={`${prefix}_LeftCellHeader`}
                                    cellRenderer={_renderLeftHeaderCell}
                                    className={classes.HeaderGridUI}
                                    width={freeWith}
                                    height={heightLeftGridUI}
                                    rowHeight={HEADER_ROW_HEIGHT}
                                    columnWidth={getLeftGridColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={fixedColumnCount + 1}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: freeWith,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: width - freeWith
                                }}
                            >
                                <Grid
                                    ref={rightHeaderRef}
                                    id={`${prefix}_RightCellHeader`}
                                    className={classes.HeaderGridUI}
                                    scrollLeft={scrollLeft}
                                    cellRenderer={_renderRightHeaderCell}
                                    width={width - freeWith}
                                    overscanColumnCount={overscanColumnCount}
                                    height={heightLeftGridUI}
                                    rowHeight={HEADER_ROW_HEIGHT}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: height - heightLeftGridUI,
                                width
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: freeWith
                                }}
                            >
                                <Grid
                                    id={`${prefix}_LeftCell`}
                                    ref={freezingGridUIRef}
                                    scrollTop={scrollTop}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderLeftCell}
                                    className={classes.leftCell}
                                    width={freeWith}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getLeftGridColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={fixedColumnCount + 1}
                                    cellRangeRenderer={props => cellRangeRenderer({ ...props, type: 'left' })}
                                />
                            </div>
                            <div
                                id={`scrollOverlay`}
                                className={classes.scrollOverLay}
                                style={{
                                    left: freeWith,
                                    width: width - freeWith,
                                    height: height - 2 * HEADER_ROW_HEIGHT
                                }}
                            >
                                <SimpleBar
                                    autoHide={false}
                                    className={classes.simpleBar}
                                    style={{
                                        maxWidth: width - freeWith,
                                        maxHeight: height - heightLeftGridUI
                                    }}
                                    ref={scrollBarRef}
                                    scrollableNodeProps={{ ref: scrollableNodeRef, onScroll: handleOverlayScroll }}
                                >
                                    <div
                                        style={{
                                            width:
                                                totalLeftOfColumns -
                                                freeWith +
                                                INDEX_COLUMN_WIDTH +
                                                FAKE_COLUMN_COUNT * DEFAULT_COLUMN_WIDTH,
                                            height: getTotalRowsHeight({ rowHeight, totalRecords }),
                                            minHeight: height - 2 * HEADER_ROW_HEIGHT
                                        }}
                                    ></div>
                                </SimpleBar>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: freeWith,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: width - freeWith
                                }}
                                className={classes.rightCellGridUI}
                            >
                                <Grid
                                    id={`${prefix}_RightCell`}
                                    ref={gridRef}
                                    scrollTop={scrollTop}
                                    scrollLeft={scrollLeft}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderRightCell}
                                    className={classes.rightCell}
                                    onSectionRendered={handleOnSectionRendered}
                                    cellRangeRenderer={props => cellRangeRenderer({ ...props, type: 'right' })}
                                    onScroll={props => {
                                        onScroll(props);
                                        onScrollRef.current = onScroll;
                                        scrollPropsRef.current = props;
                                        topRef.current = props.scrollTop;
                                        leftRef.current = props.scrollLeft;

                                        scrollableNodeRef.current.scrollTop = props?.scrollTop;
                                        scrollableNodeRef.current.scrollLeft = props?.scrollLeft;
                                    }}
                                    width={width - freeWith}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>

                        {!isViewOnly && (
                            <div
                                className={classes.freezingHandler}
                                id={`${prefix}_freezing`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    height: height + AGG_HEIGHT,
                                    width: FREEZING_BAR_WIDTH,
                                    background: theme.colors.freezingColor,
                                    zIndex: 20,
                                    left: freeWith - FREEZING_BAR_WIDTH
                                }}
                                onMouseDown={isShareViewLink ? () => {} : startDraggingFreezing}
                                onMouseMove={setFreezingTopPosition}
                            >
                                {!isShareViewLink && (
                                    <div
                                        id={`freezingHandler`}
                                        style={{
                                            position: 'absolute',
                                            width: FREEZING_BAR_HANDLER_WIDTH,
                                            height: 30,
                                            borderRadius: FREEZING_BAR_HANDLER_WIDTH / 2,
                                            background: theme.colors.freezingColor,
                                            left: -FREEZING_BAR_HANDLER_WIDTH / 2 + 1,
                                            cursor: 'pointer'
                                        }}
                                    />
                                )}
                            </div>
                        )}

                        <div
                            ref={freezingHighlightRef}
                            style={{
                                display: 'none',
                                position: 'absolute',
                                top: 0,
                                left: -10000,
                                height: height + AGG_HEIGHT,
                                width: 0,
                                borderRight: `1px solid ${theme.colors.freezingColor}`,
                                zIndex: 20
                            }}
                        />

                        {
                            <div
                                style={{
                                    position: 'absolute',
                                    top: -10000,
                                    height: ROW_RESIZE_HEIGHT,
                                    width: '100vw',
                                    background: `${theme.colors.resizeColor}`,
                                    zIndex: 20,
                                    opacity: 1,
                                    cursor: 'ns-resize'
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 20,
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                        fontSize: 13,
                                        color: theme.colors.white,
                                        borderRadius: 4,
                                        background: `${theme.colors.resizeColor}`,
                                        left: INDEX_COLUMN_WIDTH,
                                        top: -10,
                                        cursor: 'pointer'
                                    }}
                                >
                                    36px
                                </div>
                            </div>
                        }
                        {
                            <div
                                id={`${prefix}_columnResizeHighlight`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: -10000,
                                    height: height + AGG_HEIGHT,
                                    width: COLUMN_RESIZE_WIDTH,
                                    background: `${theme.colors.resizeColor}`,
                                    zIndex: 20,
                                    opacity: 1,
                                    cursor: 'ew-resize'
                                }}
                            >
                                <div
                                    id={`${prefix}_columnResizeHighlightNumber`}
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 20,
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                        fontSize: 13,
                                        color: theme.colors.white,
                                        borderRadius: 4,
                                        background: `${theme.colors.resizeColor}`,
                                        transform: `translateX(-50%)`,
                                        transformOrigin: 'center center',
                                        top: rowHeight,
                                        cursor: 'pointer'
                                    }}
                                >
                                    36px
                                </div>
                            </div>
                        }
                        {
                            <div
                                id={`${prefix}_columnReorderHighlight`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: -10000,
                                    height: height + AGG_HEIGHT,
                                    width: 200,
                                    background: `${theme.colors.steel}`,
                                    zIndex: 20,
                                    opacity: 0.2,
                                    cursor: 'grabbing'
                                }}
                            />
                        }
                        {
                            <div
                                id={`${prefix}_columnReorderReleaseHighlight`}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: -10000,
                                    height: height + AGG_HEIGHT,
                                    width: 1,
                                    background: `${theme.colors.highlight}`,
                                    zIndex: 20,
                                    opacity: 1,
                                    cursor: 'grabbing'
                                }}
                            />
                        }
                        <div ref={fillColumnsConfirmRef} />
                        <div ref={addRecordRef} />
                    </div>
                );
            }}
        </ScrollSync>
    );
}

export default React.memo(GridUIExample);
