import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Grid, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@material-ui/core';
import * as gridUIActions from 'gridUI/actions';
import {
    usePinnedSearch,
    useIsOpenReplaceSearch,
    useWindowColumnStartIndex,
    useWindowColumnStopIndex,
    useWindowRowStartIndex,
    useWindowRowStopIndex,
    useColumnsSelected,
    useTotalRecords,
    useRowsRangeIndexes,
    useViewColumnsWithReOrder
} from 'hooks/gridUI';
import PinSVG from 'assets/images/svg/PinSVG';
import { useDispatch } from 'react-redux';
import Checkbox from 'components/checkbox/Base';
import InputText from 'components/inputs/InputText';
import DropdownSVG from 'assets/images/svg/DropdownSVG';
import ButtonBase from 'components/button/Base';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import Search from 'components/search';
import { useIsShareViewLink } from 'hooks/app';
import { useTranslation } from 'react-i18next';
import LDBasePortal from 'components/selects/LDBasePortal';
import ColumnPermissionSVG from 'assets/images/svg/ColumnPermissionSVG';
import GridIconSVG from 'assets/images/svg/GridIconSVG';
import { useSearchRange } from 'hooks/gridUI/search';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: props => (props.isOpenReplaceSearch ? `1px solid ${theme.colors.border}` : 'none'),
        padding: props => (props.isOpenReplaceSearch ? '8px 0 16px' : '8px 0')
    },
    header: {
        height: 40
    },
    fullWidth: {
        width: '100%'
    },
    pinIcon: {
        display: 'flex',
        cursor: 'pointer',
        padding: 2,
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeIcon: {
        backgroundColor: '#D3CDEB',
        borderRadius: 4
    },
    arrowIcon: {
        display: 'flex',
        cursor: 'pointer',
        padding: 4
    },
    checkbox: {
        marginLeft: '8px',
        padding: '8px'
    },
    replace: {
        padding: '0 16px',
        marginTop: 16
    },
    replaceGroup: {
        padding: '4px 16px 0',
        marginTop: 14
    },
    replaceButton: {
        color: theme.palette.primary.main
    },
    divider: {
        width: '1px',
        height: '36px',
        backgroundColor: theme.colors.lightGrey,
        padding: '0 !important',
        margin: '0 8px'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        width: 20,
        height: 20
    },
    replaceInput: {
        height: 40,
        border: `1px solid ${theme.colors.lightGreyBlue}`,
        '& input': {
            '&::placeholder': {
                color: `${theme.colors.disabledGrey} !important`,
                opacity: 1
            }
        }
    },
    toolbar: {
        padding: props => (!props.isOpenReplaceSearch ? '2px 20px 10px' : '2px 20px 0px'),
        borderBottom: props => (!props.isOpenReplaceSearch ? `1px solid ${theme.colors.border}` : 'none')
    },
    searchContainer: {
        marginTop: 16,
        padding: '0 16px'
    },
    searchBox: {
        height: 40,
        border: `1px solid ${theme.colors.lightGreyBlue}`,
        background: 'unset',
        '& input': {
            fontSize: '14px !important',
            '&::placeholder': {
                color: `${theme.colors.disabledGrey} !important`,
                opacity: 1
            }
        },
        '& svg': {
            '& path': {
                fill: theme.colors.iconGridUIColor
            }
        }
    },
    checkboxContainer: {
        padding: '0 4px',
        marginTop: 8
    },
    leftCheckbox: {
        marginRight: 24
    },
    displayNone: {
        display: 'none'
    },
    controlClassName: {
        borderColor: theme.colors.lightGreyBlue,
        minHeight: 40
    },
    rangesInfo: {
        padding: '0 16px',
        marginTop: 12
    }
}));

const useStylesTooltip = makeStyles(theme => ({
    arrow: {
        color: theme.colors.tooltip
    },
    tooltip: {
        backgroundColor: theme.colors.tooltip,
        zIndex: 9999,
        fontSize: 13,
        maxWidth: 500,
        padding: '10px 14px'
    }
}));

const MuiAccordion = withStyles({
    root: {
        flex: 1,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(Accordion);

const MuiAccordionSummary = withStyles({
    root: {
        minHeight: 'unset',
        padding: 0,
        backgroundColor: 'unset !important',
        '&$expanded': {
            minHeight: 'unset'
        }
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0
        }
    },
    expanded: {}
})(AccordionSummary);

const MuiAccordionDetails = withStyles({
    root: {
        padding: 0
    }
})(AccordionDetails);

function SearchRecordToolbar({
    matches,
    handleCheckMatchCase,
    handleReplace,
    handleReplaceAll,
    replacing,
    searchValue,
    handleSearchChange,
    handleClearSearch,
    selectedArea,
    handleChangeArea,
    AREA,
    selectedRecord
}) {
    const theme = useTheme();
    const { t } = useTranslation();
    const tooltipClasses = useStylesTooltip();
    const pinnedSearch = usePinnedSearch();
    const isOpenReplaceSearch = useIsOpenReplaceSearch();
    const classes = useStyles({ isOpenReplaceSearch });
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const isShareViewLink = useIsShareViewLink();
    const columnStartIndex = useWindowColumnStartIndex();
    const columnStopIndex = useWindowColumnStopIndex();
    const rowStartIndex = useWindowRowStartIndex();
    const rowStopIndex = useWindowRowStopIndex();
    const columnsSelected = useColumnsSelected();
    const rowsRangeIndexes = useRowsRangeIndexes();
    const totalRecords = useTotalRecords();
    const searchRange = useSearchRange();
    const viewColumns = useViewColumnsWithReOrder();

    useEffect(() => {
        if (selectedArea === AREA.RANGES) {
            dispatch(gridUIActions.openReplaceSearch());
        }
    }, [selectedArea, AREA]);

    const togglePin = React.useCallback(() => {
        if (!pinnedSearch) {
            dispatch(gridUIActions.pinnedSearch());
        } else {
            dispatch(gridUIActions.unPinnedSearch());
        }
        sendManualTrack({ type: 'Pin Search Popover' });
    }, [dispatch, pinnedSearch]);

    const toggleOpenReplaceSearch = React.useCallback(() => {
        if (!isOpenReplaceSearch) {
            dispatch(gridUIActions.openReplaceSearch());
        } else {
            dispatch(gridUIActions.closeReplaceSearch());
        }
        sendManualTrack({ type: 'Open Replace Text' });
    }, [dispatch, isOpenReplaceSearch]);

    const checkboxes = useMemo(() => {
        return [
            { label: t('match_case'), value: 'match_case' },
            { label: t('match_entire_cell'), value: 'match_entire_cell' }
        ];
    }, [t]);

    const rangesInfo = useMemo(() => {
        let {
            rowStartIndex: _rowStartIndex,
            rowStopIndex: _rowStopIndex,
            columnStartIndex: _columnStartIndex,
            columnStopIndex: _columnStopIndex,
            columnsSelected: _columnsSelected,
            rowsRangeIndexes: _rowsRangeIndexes
        } = searchRange || {};
        if (isEmpty(searchRange)) {
            _rowStartIndex = rowStartIndex;
            _rowStopIndex = rowStopIndex;
            _columnStartIndex = columnStartIndex;
            _columnStopIndex = columnStopIndex;
            _columnsSelected = columnsSelected;
            _rowsRangeIndexes = rowsRangeIndexes;
        }
        if (!isEmpty(_columnsSelected)) {
            return _columnsSelected.length * totalRecords;
        }
        if (!isEmpty(_rowsRangeIndexes)) {
            return _rowsRangeIndexes.reduce((t, range) => {
                const first = range[0];
                const last = range[range.length - 1];
                t += (last - first + 1) * viewColumns.length;
                return t;
            }, 0);
        }
        if (_rowStartIndex === -1) {
            return 0;
        }
        const diffColumn = Math.abs(_columnStopIndex - _columnStartIndex) + 1;
        const diffRecord = Math.abs(_rowStopIndex - _rowStartIndex) + 1;
        return diffRecord * diffColumn || 0;
    }, [
        columnStartIndex,
        columnStopIndex,
        rowStartIndex,
        rowStopIndex,
        columnsSelected,
        totalRecords,
        searchRange,
        rowsRangeIndexes
    ]);

    const areaOptions = useMemo(() => {
        return [
            {
                value: AREA.GRID,
                prefixLabel: `${t('search_in')}:`,
                label: t('this_grid'),
                icon: <GridIconSVG width="16" height="16" />
            },
            {
                value: AREA.RANGES,
                prefixLabel: `${t('search_in')}:`,
                label: t('selected_area'),
                icon: <ColumnPermissionSVG />,
                isDisabled: rangesInfo <= 1,
                disabledTitle: t('search_record_no_selection_range_tooltip')
            }
        ];
    }, [t, AREA, rangesInfo]);

    const selectedAreaOption = useMemo(() => {
        return areaOptions.find(o => o.value === selectedArea);
    }, [areaOptions, selectedArea]);

    const _replace = useCallback(() => {
        handleReplace(value);
    }, [handleReplace, value]);

    const _replaceAll = useCallback(() => {
        handleReplaceAll(value);
    }, [handleReplaceAll, value]);

    const _changeValue = useCallback(e => {
        setValue(e.target.value);
    }, []);

    return (
        <Grid container className={classes.root}>
            <MuiAccordion expanded={isOpenReplaceSearch}>
                <MuiAccordionSummary>
                    <Grid container>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify={'space-between'}
                            className={classes.toolbar}
                        >
                            <Tooltip classes={tooltipClasses} title={t('more_options')} placement="bottom-end">
                                <Grid
                                    item
                                    className={`${classes.arrowIcon} ${isOpenReplaceSearch ? classes.activeIcon : ''}`}
                                    onClick={toggleOpenReplaceSearch}
                                >
                                    <DropdownSVG
                                        className={classes.replaceIcon}
                                        style={{ transform: isOpenReplaceSearch && 'rotate(180deg)' }}
                                        color={
                                            isOpenReplaceSearch
                                                ? theme.palette.primary.main
                                                : theme.colors.iconGridUIColor
                                        }
                                    />
                                </Grid>
                            </Tooltip>
                            <Tooltip classes={tooltipClasses} title={t('pin_window_on_top')}>
                                <Grid
                                    item
                                    className={`${classes.pinIcon} ${pinnedSearch ? classes.activeIcon : ''}`}
                                    onClick={togglePin}
                                >
                                    <PinSVG
                                        color={pinnedSearch ? theme.palette.primary.main : theme.colors.steel}
                                        width="20"
                                        height="20"
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.searchContainer} item style={{ flex: 1 }}>
                            <Search
                                className={classes.searchBox}
                                onChange={handleSearchChange}
                                value={searchValue}
                                placeholder={'Search…'}
                                onClear={handleClearSearch}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                    <Grid container direction="column">
                        <Grid className={classes.checkboxContainer} container direction="row" alignItems="center">
                            {checkboxes.map((checkbox, idx) => (
                                <Grid item key={checkbox.value} className={idx === 0 ? classes.leftCheckbox : ''}>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={matches?.includes(checkbox.value)}
                                                onChange={e => handleCheckMatchCase(checkbox.value, e?.target?.checked)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <p className="body2">{checkbox.label}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        {!isShareViewLink && (
                            <Grid item className={classes.replace}>
                                <Grid item className={classes.fullWidth}>
                                    <InputText
                                        inputClassName={classes.replaceInput}
                                        placeholder={`${t('replace_with')}...`}
                                        value={value}
                                        onChange={_changeValue}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid item className={classes.replace}>
                            <LDBasePortal
                                controlClassName={classes.controlClassName}
                                options={areaOptions}
                                onChange={option => handleChangeArea(option.value)}
                                defaultValue={selectedAreaOption}
                                isUsingContainer
                            />
                        </Grid>
                        {selectedArea === AREA.RANGES && (
                            <Grid item className={classes.rangesInfo}>
                                <p className="body1">
                                    {t('search_record_selected_cell_in_range', { count: rangesInfo })}
                                </p>
                            </Grid>
                        )}
                        {!isShareViewLink && (
                            <>
                                <Grid
                                    container
                                    className={classes.replaceGroup}
                                    alignItems="center"
                                    justify="space-between"
                                    wrap="nowrap"
                                >
                                    <Grid item>
                                        <ButtonBase textPrimary={true} width={176} onClick={_replace} isLoading={true}>
                                            {replacing ? (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            ) : (
                                                t('global_replace')
                                            )}
                                        </ButtonBase>
                                    </Grid>
                                    <Grid className={classes.divider} item></Grid>
                                    <Grid item>
                                        <ButtonBase textPrimary={true} width={176} onClick={_replaceAll}>
                                            {t('replace_all')}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </MuiAccordionDetails>
            </MuiAccordion>
        </Grid>
    );
}

export default React.memo(SearchRecordToolbar);
