import React from 'react';

function MemsourceSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4" fill="white" />
        </svg>
    );
}

export default React.memo(MemsourceSVG);
