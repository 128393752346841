import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Collapse } from '@material-ui/core';
import ListSelect from 'components/list/Select';
import ButtonBase from 'components/button/Base';
import CountryFlag from 'components/svg-icon/CountryFlag';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from 'components/checkbox/Base';
import { useViewColumnsWithUserLanguageViewsAndMetadata } from 'hooks/gridUI/column';
import { DISABLED_OPACITY, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { useDispatch } from 'react-redux';
import * as exportActions from 'gridUI/actions/export';
import { TRANSLATION_IMPORT_EXPORT_OPTIONS } from 'const';
import { sendManualTrack } from 'tracker';
import { useDependencies, useQuickFilters, useQuickSorts, useDragNodeIds, usePathTagTree } from 'hooks/gridUI';
import { isTempId } from 'utils/uuid';
import * as columnTypes from 'const/columnTypes';
import ColumnTypeDisplay, { ColumnTypeSelectListDisplay } from '../../ColumnTypeDisplay';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import { isLDEmpty } from 'utils/object';
import { formatQuickFilters } from 'utils/gridUI/filter';
import { useTranslationImportExport } from 'hooks/payment/usage';
import { useGridDetail } from 'hooks/grid';
import { useParams } from 'react-router-dom';
import { getPath } from 'utils/gridUI/pathTag';
import { OPERATOR } from 'gridUI/conditions';
import { SOURCE_STATUS } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: 880,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },

    targetList: {
        maxHeight: 300,
        minHeight: 46,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    otherColumnList: {
        maxHeight: 300,
        overflowY: 'auto',
        overflowX: 'hidden'
    },

    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    fade: {
        opacity: 0.6
    },
    checkbox: {
        padding: 4
    },
    left4: {
        position: 'relative',
        left: 4,
        width: '100%'
    },
    footer: {},
    dlFlex: {
        display: 'flex'
    },
    steel: {
        color: theme.colors.steel
    },
    divider: {
        background: theme.colors.divider
    },
    label: {
        width: `calc(100% - 32px)`
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    otherSettings: {
        position: 'relative',
        left: -12
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

function getDefaultSource(sources) {
    if (sources?.length) return sources?.[0];
    return null;
}

function ExportLocalization({ onClose, showTitle = true }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const [highlightSourceChanged, setHighlightSourceChanged] = React.useState(true);
    const [includeUpToDateRecords, setIncludeUpToDateRecords] = React.useState(false);
    const [includeNotReadyForTranslationRecords, setIncludeNotReadyForTranslationRecords] = React.useState(false);
    const [includeDoNotTranslate, setIncludeDoNotTranslate] = React.useState(false);
    const [includeQuickFilterAndSort, setIncludeQuickFilterAndSort] = React.useState(true);
    const [exportSameSheet, setExportSameSheet] = React.useState(false);
    const translationImportExport = useTranslationImportExport();
    const { dbId, branchId } = useParams();

    const dependencies = useDependencies();
    const [selectedTargetOptions, setSelectedTargetOptions] = React.useState({});
    const [exportAllTargets, setExportAllTargets] = React.useState(false);

    const quickSorts = useQuickSorts();
    const quickFilters = useQuickFilters();
    const dragNodeIds = useDragNodeIds();
    const tree = usePathTagTree();

    const [enableSelectedPaths, setEnableSelectedPaths] = React.useState(dragNodeIds?.length > 0);

    const gridDetail = useGridDetail({ dbId, gridId: branchId });

    const isNonUnique = React.useMemo(() => {
        return gridDetail?.recordIdentifierType === 'pathTagRecordId';
    }, [gridDetail]);

    const quickFiltersCombined = React.useMemo(() => {
        const filter = includeQuickFilterAndSort ? quickFilters : {};
        const pathNames = dragNodeIds?.map(nodeId => getPath({ tree, nodeId })?.pathName);

        const extraFilters = dragNodeIds?.length
            ? enableSelectedPaths
                ? { _pathTag: { value: pathNames, operator: OPERATOR.startsWith, type: '_pathTag' } }
                : {}
            : {};
        return { ...filter, ...extraFilters };
    }, [includeQuickFilterAndSort, quickFilters, tree, dragNodeIds, enableSelectedPaths]);

    const quickSortsCombined = React.useMemo(() => {
        return includeQuickFilterAndSort ? quickSorts : {};
    }, [includeQuickFilterAndSort, quickSorts]);

    const isShowSwitchFilter = React.useMemo(() => {
        return !isLDEmpty(quickFilters) || !isLDEmpty(quickSorts);
    }, [quickFilters, quickSorts]);

    const dependenciesWithoutFakeIds = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const parentDpDcColIds = React.useMemo(() => {
        return dependenciesWithoutFakeIds?.map(dpDc => dpDc?.parent);
    }, [dependenciesWithoutFakeIds]);

    const sourceOptions = React.useMemo(() => {
        const sources = viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isParent = parentDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isParent && columnType === columnTypes.TRANSLATION;
            })
            ?.map(lang => ({
                ...lang,
                value: lang?.id,
                group: lang?.group,
                label: lang?.name,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }));
        return sources;
    }, [viewColumns, parentDpDcColIds]);

    const [selectedSource, setSelectedSource] = React.useState(getDefaultSource(sourceOptions));

    const childDpDcColIds = React.useMemo(() => {
        if (!selectedSource) return [];
        return dependenciesWithoutFakeIds
            ?.filter(dpDc => dpDc?.parent === selectedSource?.value)
            ?.map(dpDc => dpDc?.child);
    }, [dependenciesWithoutFakeIds, selectedSource]);

    const targetOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isChild = childDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isChild && columnType === columnTypes.TRANSLATION;
            })
            .map(lang => ({
                ...lang,
                value: lang.id,
                group: lang.group,
                label: `${lang.name}`,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }));
    }, [viewColumns, childDpDcColIds]);

    const otherColumnsOptions = React.useMemo(() => {
        const targetOptionIds = targetOptions?.map(option => option?.value);

        const otherSources = !selectedSource
            ? []
            : sourceOptions?.filter(
                  option => option?.value !== selectedSource?.value && !targetOptionIds?.includes(option?.value)
              );

        const otherTargets = viewColumns
            ?.filter(viewCol => {
                const columnType = getCorrectColumnType(viewCol);
                return (
                    columnType !== columnTypes.TRANSLATION ||
                    (!childDpDcColIds?.includes(viewCol?.id) && !parentDpDcColIds?.includes(viewCol?.id))
                );
            })
            .map(col => ({
                ...col,
                value: col.id,
                label: col.name
            }));
        // ?.filter(col => !SYSTEM_COLUMN_IDS?.includes(col?.value));

        return [...otherTargets, ...otherSources];
    }, [viewColumns, selectedSource, childDpDcColIds, sourceOptions, targetOptions, parentDpDcColIds]);

    const defaultOtherColumnChecked = React.useMemo(() => {
        const includeRecordId = otherColumnsOptions?.find(c => c?.value === columnTypes.RECORD_ID);
        const defaultC = {};
        if (isNonUnique) {
            defaultC[columnTypes.PATH_TAG] = true;
        }

        if (includeRecordId) {
            defaultC[columnTypes.RECORD_ID] = true;
        }

        return defaultC;
    }, [isNonUnique, otherColumnsOptions]);

    const [otherColumns, setOtherColumns] = React.useState(defaultOtherColumnChecked);

    const handleTargetSelect = React.useCallback(
        option => {
            const targetValue = option.value;
            const checked = selectedTargetOptions?.[targetValue] || false;

            const otherTargets = targetOptions?.filter(opt => opt?.value !== targetValue);

            const isAtLeaseCheckedTarget = otherTargets?.some(option => selectedTargetOptions?.[option?.value]);
            const isAtLeaseUnCheckedTarget = otherTargets?.some(option => !selectedTargetOptions?.[option?.value]);

            if (checked) {
                //check all target is uncheck => setCheckAll -> false
                if (!isAtLeaseCheckedTarget) {
                    setExportAllTargets(false);
                }

                if (!isAtLeaseUnCheckedTarget) {
                    setExportAllTargets(false);
                }
            } else {
                //check all target is checked => setCheckAll => true;
                if (!isAtLeaseUnCheckedTarget) {
                    setExportAllTargets(true);
                }
            }

            let newSelectedTargetOptions = {};

            newSelectedTargetOptions = {
                ...selectedTargetOptions,
                [targetValue]: !checked
            };
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [selectedTargetOptions, targetOptions]
    );

    const handleTargetSelectAll = React.useCallback(
        e => {
            const checked = e.target?.checked;
            setExportAllTargets(checked);

            let newSelectedTargetOptions = {};
            targetOptions.forEach(target => {
                newSelectedTargetOptions[target.value] = checked;
            });
            setSelectedTargetOptions(newSelectedTargetOptions);
        },
        [targetOptions]
    );

    const handleOtherColumnChange = React.useCallback(
        ({ checked, value }) => {
            setOtherColumns({
                ...otherColumns,
                [value]: checked
            });
        },
        [otherColumns]
    );

    const validTargets = React.useMemo(() => {
        let validTargetCols = [];
        Object.keys(selectedTargetOptions).forEach(key => {
            if (selectedTargetOptions[key]) {
                validTargetCols = [
                    ...validTargetCols,
                    { key, id: targetOptions?.find(option => option.value === key)?.id }
                ];
            }
        });

        return validTargetCols;
    }, [selectedTargetOptions, targetOptions]);

    const validOtherColumns = React.useMemo(() => {
        let validOtherCols = [];
        Object.keys(otherColumns).forEach(key => {
            if (otherColumns[key]) {
                validOtherCols = [...validOtherCols, key];
            }
        });
        return validOtherCols;
    }, [otherColumns]);

    const isValid = React.useMemo(() => {
        return !!selectedSource && validTargets.length;
    }, [selectedSource, validTargets]);

    const handleExport = React.useCallback(() => {
        let exportBody = {
            sourceColumnId: selectedSource?.value,
            targetColumnIds: validTargets.map(validTarget => validTarget.id),
            targetStatuses: includeUpToDateRecords ? [] : ['outOfDate', 'unset'],
            sourceStatuses: includeNotReadyForTranslationRecords
                ? []
                : [SOURCE_STATUS.READY_FOR_TRANSLATION, SOURCE_STATUS.LOCKED],
            highlightSourceChanged,
            singleTranslationFile: exportSameSheet ? true : false,
            includeDoNotTranslate: includeDoNotTranslate ? true : false
        };

        if (validOtherColumns.length > 0) {
            exportBody = {
                ...exportBody,
                additionalColumnIds: validOtherColumns
            };
        }

        if (!isLDEmpty(quickFiltersCombined)) {
            exportBody = {
                ...exportBody,
                query: formatQuickFilters(quickFiltersCombined)
            };
        }

        if (!isLDEmpty(quickSortsCombined)) {
            exportBody = {
                ...exportBody,
                sort: quickSorts
            };
        }

        sendManualTrack({ type: `Localization Export File` });
        setIsSubmitting(true);
        dispatch(
            exportActions.exportLocalization({
                body: exportBody,
                successCallback: () => {
                    console.log('export LD success');
                    setIsSubmitting(false);
                    onClose && onClose();
                },
                errorCallback: () => {
                    console.log('export LD error');
                    setIsSubmitting(false);
                    onClose && onClose();
                }
            })
        );
    }, [
        includeDoNotTranslate,
        includeNotReadyForTranslationRecords,
        dispatch,
        highlightSourceChanged,
        includeUpToDateRecords,
        onClose,
        quickFiltersCombined,
        quickSorts,
        quickSortsCombined,
        selectedSource,
        validOtherColumns,
        validTargets,
        exportSameSheet
    ]);

    const handleSourceSelect = React.useCallback(
        option => {
            setSelectedSource(option);
            if (exportAllTargets) {
                const childColIds = dependenciesWithoutFakeIds
                    ?.filter(dpDc => dpDc?.parent === option?.value)
                    ?.map(dpDc => dpDc?.child);
                const targetOpts = viewColumns
                    .filter(viewCol => {
                        const columnId = viewCol?.id;
                        const isChild = childColIds?.includes(columnId);
                        const columnType = getCorrectColumnType(viewCol);
                        return isChild && columnType === columnTypes.TRANSLATION;
                    })
                    .map(lang => ({
                        ...lang,
                        value: lang.id,
                        group: lang.group,
                        label: `${lang.name}`,
                        icon: () => <CountryFlag languageCountryCode={lang.group} />
                    }));

                let newSelectedTargetOptions = {};
                targetOpts.forEach(target => {
                    newSelectedTargetOptions[target.value] = true;
                });
                setSelectedTargetOptions(newSelectedTargetOptions);
            } else {
                setSelectedTargetOptions({});
            }
            setOtherColumns({});
        },
        [exportAllTargets, dependenciesWithoutFakeIds, viewColumns]
    );

    const isShowOtherSettings = React.useMemo(() => {
        return translationImportExport !== TRANSLATION_IMPORT_EXPORT_OPTIONS.NO;
    }, [translationImportExport]);

    const isDisabledOtherSettings = React.useMemo(() => {
        return translationImportExport === TRANSLATION_IMPORT_EXPORT_OPTIONS.LIMITED;
    }, [translationImportExport]);

    const isExportingAllOtherColumns = React.useMemo(() => {
        return otherColumnsOptions.every(col => !!otherColumns[col.id]);
    }, [otherColumns, otherColumnsOptions]);

    const isExportingAllSettings = React.useMemo(() => {
        const arr = [
            highlightSourceChanged,
            includeUpToDateRecords,
            includeDoNotTranslate,
            includeNotReadyForTranslationRecords,
            exportSameSheet
        ];
        if (isShowSwitchFilter) {
            arr.push(includeQuickFilterAndSort);
        }
        if (dragNodeIds?.length > 0) {
            arr.push(enableSelectedPaths);
        }
        return arr.every(el => !!el);
    }, [
        dragNodeIds,
        enableSelectedPaths,
        exportSameSheet,
        highlightSourceChanged,
        includeDoNotTranslate,
        includeNotReadyForTranslationRecords,
        includeQuickFilterAndSort,
        includeUpToDateRecords,
        isShowSwitchFilter
    ]);

    const handleSettingSelectAll = React.useCallback(
        e => {
            const checked = e?.target?.checked;
            setHighlightSourceChanged(checked);
            setIncludeUpToDateRecords(checked);
            setIncludeDoNotTranslate(checked);
            setIncludeNotReadyForTranslationRecords(checked);
            setExportSameSheet(checked);
            if (isShowSwitchFilter && checked) {
                setIncludeQuickFilterAndSort(checked);
            }
            if (dragNodeIds?.length > 0 && checked) {
                setEnableSelectedPaths(checked);
            }
        },
        [dragNodeIds, isShowSwitchFilter]
    );

    const handleOtherColumnsSelectAll = React.useCallback(
        e => {
            const checked = e?.target?.checked;
            setOtherColumns(prev => {
                otherColumnsOptions.forEach(col => {
                    prev[col.id] = checked;
                });
                return { ...prev };
            });
        },
        [otherColumnsOptions]
    );

    return (
        <>
            {showTitle && <DialogTitle title={t(`export_localization`)} onClose={onClose} />}
            <DialogContent>
                <Grid container className={classes.root} direction="column" spacing={4} wrap="nowrap">
                    <Grid item container direction="column" spacing={4} wrap="nowrap">
                        <Grid item>
                            <Grid className={classes.source} container spacing={2} direction="column" wrap="nowrap">
                                <Grid item>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item className={classes.numberStep}>
                                            {t('global_1')}
                                        </Grid>
                                        <Grid item>
                                            <p className="body1">{t(`global_source_language`)}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={3}>
                                        {sourceOptions?.map(option => {
                                            const isSelected = selectedSource?.value === option?.value;
                                            return (
                                                <Grid
                                                    className={selectedSource && !isSelected ? classes.fade : ``}
                                                    onClick={() => handleSourceSelect(option)}
                                                    item
                                                    xs={4}
                                                    key={option.value}
                                                >
                                                    <ListSelect
                                                        isSelected={isSelected}
                                                        icon={option?.icon}
                                                        name={<ColumnTypeSelectListDisplay {...option} />}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="column" wrap="nowrap">
                                <Grid item container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item className={classes.numberStep}>
                                                {t('global_2')}
                                            </Grid>
                                            <Grid item>
                                                <p className="body1">{t('export_localization_select_target')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item></Grid>
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={exportAllTargets}
                                                            onChange={handleTargetSelectAll}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">
                                                            {t('export_localization_select_all')}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.targetList}>
                                    {targetOptions.length === 0 && (
                                        <p className="body2 text-steel">{t(`export_localization_explain`)}</p>
                                    )}
                                    <Collapse in={targetOptions.length > 0}>
                                        <Grid container alignItems="center" spacing={3}>
                                            {targetOptions?.map(option => {
                                                const isSelected = selectedTargetOptions?.[option?.value];
                                                return (
                                                    <Grid
                                                        onClick={e => handleTargetSelect(option)}
                                                        item
                                                        xs={4}
                                                        key={option.value}
                                                    >
                                                        <ListSelect
                                                            isSelected={isSelected}
                                                            icon={option?.icon}
                                                            name={<ColumnTypeSelectListDisplay {...option} />}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isShowOtherSettings && (
                            <Grid item>
                                <Grid container spacing={2} direction="column" wrap="nowrap">
                                    <Grid item container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Grid container direction="row" alignItems="center" wrap="nowrap">
                                                <Grid item className={classes.numberStep}>
                                                    {t('global_3')}
                                                </Grid>
                                                <Grid item>
                                                    <p className="body1">{t('localization_export_other_options')}</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item></Grid>
                                                <Grid item>
                                                    <Grid container direction="row" alignItems="center">
                                                        <Grid item>
                                                            <Checkbox
                                                                checked={isExportingAllSettings}
                                                                onChange={handleSettingSelectAll}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="body2 inline">Export all settings</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            className={`${classes.otherSettings} ${
                                                isDisabledOtherSettings ? classes.disabled : ``
                                            }`}
                                            container
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={highlightSourceChanged}
                                                            onChange={e => setHighlightSourceChanged(e.target?.checked)}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">
                                                            {t('localization_export_highlight_source_change')}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={includeUpToDateRecords}
                                                            onChange={e => setIncludeUpToDateRecords(e.target?.checked)}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">Include up-to-date record</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container wrap="nowrap" direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={includeDoNotTranslate}
                                                            onChange={e => setIncludeDoNotTranslate(e.target?.checked)}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">Include do-not-translate</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container wrap="nowrap" direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={includeNotReadyForTranslationRecords}
                                                            onChange={e =>
                                                                setIncludeNotReadyForTranslationRecords(
                                                                    e.target?.checked
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">
                                                            {t('localization_export_include_notready')}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={exportSameSheet}
                                                            onChange={e => setExportSameSheet(e.target?.checked)}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">
                                                            {t('localization_export_same_sheet')}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {isShowSwitchFilter && (
                                                <Grid item xs={4}>
                                                    <Grid container direction="row" alignItems="center">
                                                        <Grid item>
                                                            <Checkbox
                                                                checked={includeQuickFilterAndSort}
                                                                onChange={e =>
                                                                    setIncludeQuickFilterAndSort(e.target?.checked)
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="body2 inline">
                                                                Include quick filters and sorts
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {dragNodeIds?.length > 0 && (
                                                <Grid item xs={4}>
                                                    <Grid container direction="row" alignItems="center">
                                                        <Grid item>
                                                            <Checkbox
                                                                checked={enableSelectedPaths}
                                                                onChange={e =>
                                                                    setEnableSelectedPaths(e.target?.checked)
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="body2 inline">Included selected paths</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {otherColumnsOptions?.length !== 0 && (
                        <Grid item>
                            <Grid
                                container
                                className={classes.otherColumns}
                                spacing={2}
                                direction="column"
                                wrap="nowrap"
                            >
                                <Grid item container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item className={classes.numberStep}>
                                                {t('global_4')}
                                            </Grid>
                                            <Grid item>
                                                <p className="body1">{t('localization_export_select_other')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item></Grid>
                                            <Grid item>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        <Checkbox
                                                            checked={isExportingAllOtherColumns}
                                                            onChange={handleOtherColumnsSelectAll}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body2 inline">Export all other columns</p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.otherColumnList}>
                                    <Grid container spacing={2}>
                                        {otherColumnsOptions.map(col => {
                                            const checked = !!otherColumns[col.id];
                                            return (
                                                <Grid key={col.id} item xs={4}>
                                                    <FormControlLabel
                                                        className={classes.left4}
                                                        classes={{
                                                            label: classes.label
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                className={classes.checkbox}
                                                                checked={checked}
                                                                onChange={e =>
                                                                    handleOtherColumnChange({
                                                                        checked: e.target.checked,
                                                                        value: col.id
                                                                    })
                                                                }
                                                                name={col.name}
                                                            />
                                                        }
                                                        label={<ColumnTypeDisplay truncated={true} {...col} />}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid className={classes.footer} item container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Grid container spacing={2} direction="row" wrap="nowrap">
                            <Grid item>
                                <ButtonBase variant="outlined" width={120} onClick={onClose}>
                                    {t('global_cancel')}
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase
                                    width={120}
                                    disabled={!isValid || isSubmitting}
                                    variant="contained"
                                    onClick={handleExport}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress
                                            style={{
                                                color: 'white'
                                            }}
                                            size={20}
                                        />
                                    ) : (
                                        t('global_export')
                                    )}
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(ExportLocalization);
