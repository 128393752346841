import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DependencySVG from 'assets/images/svg/DependencySVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import PopperMenu from 'components/menus/Popper';
import Tooltip from 'components/tooltip/Base';
import { useIsHighlightDependency, useIsOpenNotiDependency } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import DependencyMenu from '../dependencies';
import dependencyGif from 'assets/images/dependency.gif';
import * as gridUIActions from 'gridUI/actions';
import hexToRgba from 'hex-to-rgba';
import { useCurrentTutorial } from 'hooks/app';
import { useTranslation } from 'react-i18next';
import { defaultBorder } from 'tutorials/helpers';
import DependencyNotification from '../DependencyNotification';

const useStyles = makeStyles(theme => ({
    spb2: {
        marginBottom: theme.spacing(1)
    },
    tooltip: {
        backgroundColor: theme.colors.tutorialColor,
        padding: theme.spacing(3),
        borderRadius: 4
    },
    viewGift: {
        width: '100%',
        height: 'auto',
        borderRadius: 4
    },
    annotation: {
        display: 'inline-block'
    },
    symbol: {
        display: 'inline-block',
        borderRadius: 2,
        width: 8,
        height: 8,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(3)
    },
    description: {
        marginRight: theme.spacing(2),
        color: theme.colors.white
    },
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    },
    dependencyNotiPopup: {
        '& .popper-content': {
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#6158BA'
        },
        borderRadius: 4
    }
}));

const ManageDependencies = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isHighLightDependency = useIsHighlightDependency();
    const currentTutorial = useCurrentTutorial();
    const isOpenNotiDependency = useIsOpenNotiDependency();
    const dependencyRef = React.useRef();
    const [dependencyAnchorEl, setDependencyAnchorEl] = React.useState(null);
    const [dependencyNotiAnchorEl, setDependencyNotiAnchorEl] = React.useState(null);

    const handleLocalizationNotiClickAway = React.useCallback(() => {
        setDependencyNotiAnchorEl(null);
        dispatch(gridUIActions.closeNotiDependency());
    }, [dispatch]);

    React.useEffect(() => {
        if (currentTutorial) {
            handleLocalizationNotiClickAway();
        }

        if (isOpenNotiDependency && !currentTutorial) {
            setTimeout(() => setDependencyNotiAnchorEl(dependencyRef.current), 2000);
        }
    }, [isOpenNotiDependency, currentTutorial, handleLocalizationNotiClickAway]);

    const ANNOTATIONS = React.useMemo(() => {
        return [
            { color: theme.colors.atlantis, description: t('up_to_date') },
            { color: theme.colors.sun, description: t('out_of_date') },
            { color: theme.colors.brightRed, description: t('unset') }
        ];
    }, [theme, t]);

    const handleDependencyClick = React.useCallback(
        event => {
            setDependencyAnchorEl(dependencyAnchorEl ? null : event.currentTarget);
            handleLocalizationNotiClickAway();
        },
        [dependencyAnchorEl, handleLocalizationNotiClickAway]
    );

    const handleDependencyClickAway = () => {
        setDependencyAnchorEl(null);
    };

    return (
        <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_DEPENDENCY}>
            {({ isReadOnly }) => (
                <>
                    <Tooltip
                        title={
                            <Grid container className={classes.tooltip} direction="column">
                                <Grid item className={classes.spb2}>
                                    <img className={classes.viewGift} src={dependencyGif} alt="view dependency gif" />
                                </Grid>
                                <Grid item>
                                    <p className="text-light font-medium">{t('global_dependencies')}</p>
                                </Grid>
                                <Grid item>
                                    <p className="caption">{t('dependencies_popup_tutorial')}</p>
                                </Grid>
                                <div className="flex flex-row items-center gap-3">
                                    {ANNOTATIONS.map((annotate, index) => (
                                        <div className="flex items-center gap-1" key={index}>
                                            <div
                                                style={{ background: hexToRgba(annotate.color, 0.8) }}
                                                className={'w-2 h-2 rounded-sm'}
                                            />
                                            <p className="inline text-light">{annotate.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        }
                    >
                        <Grid item className={classes.menuItem}>
                            <ButtonBase
                                ref={dependencyRef}
                                id="g-h-menu-item-dependency-add"
                                className={`${classes.addButton} ${classes.button}`}
                                style={{
                                    background: isHighLightDependency ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                                    position: 'relative',
                                    ...(isOpenNotiDependency && {
                                        border: `2px solid ${theme.colors.highlight}`
                                    }),
                                    border: defaultBorder
                                }}
                                onClick={handleDependencyClick}
                                tr-dt="Open Dependency Setting"
                            >
                                <DependencySVG
                                    height={18}
                                    width={18}
                                    color={isHighLightDependency ? theme.palette.primary.main : theme.colors.darkness}
                                />
                                {!!isHighLightDependency && (
                                    <div
                                        style={{
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            background: theme.colors.indicator,
                                            border: `1px solid ${theme.colors.solitude}`,
                                            position: 'absolute',
                                            right: 3,
                                            top: 3
                                        }}
                                    ></div>
                                )}
                            </ButtonBase>
                        </Grid>
                    </Tooltip>
                    {dependencyAnchorEl && (
                        <PopperMenu
                            placement={'bottom-start'}
                            id="dependencyPopup"
                            anchorEl={dependencyAnchorEl}
                            handleClickAway={handleDependencyClickAway}
                            className={classes.popperMenu}
                        >
                            <DependencyMenu t={t} />
                        </PopperMenu>
                    )}
                    <PopperMenu
                        open={Boolean(dependencyNotiAnchorEl) && !currentTutorial}
                        placement={'bottom-start'}
                        anchorEl={dependencyNotiAnchorEl}
                        style={{ background: '#6158BA' }}
                        className={classes.dependencyNotiPopup}
                    >
                        <DependencyNotification onClose={handleLocalizationNotiClickAway} />
                    </PopperMenu>
                </>
            )}
        </AccessControl>
    );
};

export default React.memo(ManageDependencies);
