import { enqueueSnackbar } from 'notifier/actions';
import {
    importViewReadFileApi,
    importViewApi,
    importPreviewWithResourceKeyApi,
    importViewReadFileJSONApi,
    importViewReadFilePOApi,
    importJSONLocalizationGridApi,
    mergeImportApi
} from 'services/view';
import {
    importLocalizationGridApi,
    importMergeDiffLocalizationGridApi,
    importPreviewLocalizationGridApi
} from 'services/grid';
import { requestData, receiveData } from 'api/actions';
import * as types from '../types';
import {
    formatGridUIResponseForLocalizationImport,
    formatGridUIResponseForFileImport,
    columnsMappingWithMetaData
} from 'utils/gridUI/formatData';
import { TRANSLATION_TYPES } from 'gridUI/column-types';
import * as columnTypes from 'const/columnTypes';
import { generateTempId } from 'utils/uuid';
import { RECORD_IDS_MATCHES } from 'const';

export function saveImportGridColumn({ columnId, index, columnName, columnType, columnTimezone }) {
    return {
        type: types.SAVE_IMPORT_GRID_COLUMN,
        payload: {
            columnId,
            index,
            columnName,
            columnType,
            columnTimezone
        }
    };
}

export function setLocalizationColumn({ columnId, body }) {
    return {
        type: types.SET_LOCALIZATION_COLUMN,
        payload: {
            columnId,
            body
        }
    };
}

export function resetLocalizationColumn({ columnId }) {
    return {
        type: types.RESET_LOCALIZATION_COLUMN,
        payload: {
            columnId
        }
    };
}

export function clearOriginalLocalizationColumn() {
    return {
        type: types.CLEAR_ORIGINAL_OPTION_COLUMN
    };
}

export function importLocalizationGrid({ file, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, currentView } = gridUI;
        try {
            await importLocalizationGridApi({ file, dbId, viewId: currentView.id });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importMergeDiffCheckLocalizationGrid({ taskId, viewId, dbId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await importMergeDiffLocalizationGridApi({ dbId, viewId, taskId, body });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importPreviewLocalizationGrid({ file, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, currentView } = gridUI;
        try {
            const { records, columns, resourceKey } = await importPreviewLocalizationGridApi({
                file,
                dbId,
                viewId: currentView.id
            });

            const metaData = gridUI.metaData;

            const columnMap = columnsMappingWithMetaData(columns, metaData);

            const { data, rows, firstRecord } = formatGridUIResponseForLocalizationImport({
                records,
                columns
            });

            const _columnsWithPublicId = columns?.map(columnId =>
                columnId === '_recordId' ? columnTypes.RECORD_ID : columnId
            );

            dispatch(
                receiveData(types.IMPORT_FILE_CREATE_GRID_SUCCESS, {
                    file,
                    data,
                    columns: columns,
                    metaData,
                    rows,
                    resourceKey,
                    columnMap,
                    firstRecord
                })
            );

            return (
                successCallback &&
                successCallback({ metaData, _columnsWithPublicId, firstRow: data?.[rows?.[0]] || {}, resourceKey })
            );
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importLocalizationGridReadFile({ dbId, file, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.IMPORT_GRID_FOR_LOCALIZATION));
            const { records, columns, resourceKey, statusMaps } = await importViewReadFileApi({ file, dbId });

            const columnIds = columns?.map(v => v - 1);
            const {
                data,
                rows,
                metaData,
                columnMap,
                firstRecord,
                dependencyStatusData
            } = formatGridUIResponseForLocalizationImport({
                records,
                columns: columnIds,
                statusMaps
            });

            dispatch(
                receiveData(types.IMPORT_GRID_FOR_LOCALIZATION_SUCCESS, {
                    file,
                    data,
                    columns: columnIds,
                    metaData,
                    rows,
                    resourceKey,
                    columnMap,
                    firstRecord,
                    dependencyStatusData
                })
            );
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.IMPORT_GRID_FOR_LOCALIZATION_FAILED, { error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importLocalizationGridReadJSONFile({ dbId, file, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.IMPORT_LOCALIZATION_JSON_FILE, { dbId }));
            const { groupedJsonNode, resourceKey } = await importViewReadFileJSONApi({ file, dbId });

            console.log('groupedJsonNode', groupedJsonNode);
            console.log('resourceKey', resourceKey);

            dispatch(
                receiveData(types.IMPORT_LOCALIZATION_JSON_FILE_SUCCESS, {
                    file,
                    dbId,
                    resourceKey,
                    tree: groupedJsonNode?.children,
                    objectIds: groupedJsonNode?.objectIds
                })
            );
            return successCallback && successCallback({ tree: groupedJsonNode });
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.IMPORT_LOCALIZATION_JSON_FILE_FAILED, { dbId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importLocalizationGridReadPOFile({ dbId, file, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.IMPORT_LOCALIZATION_PO_FILE, { dbId }));
            const { messages, resourceKey, headers, poTargetLanguage, messageKeys } = await importViewReadFilePOApi({
                file,
                dbId
            });

            dispatch(
                receiveData(types.IMPORT_LOCALIZATION_PO_FILE_SUCCESS, {
                    file,
                    dbId,
                    resourceKey,
                    messages,
                    headers,
                    poTargetLanguage,
                    messageKeys
                })
            );
            return successCallback && successCallback({ poTargetLanguage });
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.IMPORT_LOCALIZATION_PO_FILE_FAILED, { dbId, error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importGridReadFile({ dbId, file, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.IMPORT_FILE_CREATE_GRID));
            const { records, columns, resourceKey } = await importViewReadFileApi({ file, dbId });
            const { data, rows, metaData, columnMap, firstRecord } = formatGridUIResponseForFileImport({
                records,
                columns
            });

            dispatch(
                receiveData(types.IMPORT_FILE_CREATE_GRID_SUCCESS, {
                    file,
                    data,
                    columns,
                    metaData,
                    rows,
                    resourceKey,
                    columnMap,
                    firstRecord
                })
            );
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.IMPORT_FILE_CREATE_GRID_FAILED, { error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

// function getCorrectColumnId({ isHasRecordId, columnId }) {
//     return isHasRecordId ? (Number(columnId) - 1)?.toString() : columnId;
// }

export function importPreviewWithSourceKey({ file, resourceKey, importRequest, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.IMPORT_REVIEW_FILE_REQUEST));

            const resData = await importPreviewWithResourceKeyApi({ key: resourceKey, importRequest });
            const fileColumns = resData?.columns || [];
            const records = resData?.records || [];
            const statusMaps = resData?.statusMaps;

            const firstRecord = records[0] || [];
            const recordIdIndex = firstRecord.findIndex(cell =>
                RECORD_IDS_MATCHES?.map(i => i?.toLowerCase())?.includes(cell?.toLowerCase())
            );
            const hasRecordId = recordIdIndex > -1;
            const data = {};
            const rows = [];
            let dependencyStatusData = {};

            records.forEach((record, index) => {
                const fakeRecordId = generateTempId();
                const id = hasRecordId ? record[recordIdIndex] || fakeRecordId : index;
                const row = {};

                fileColumns.forEach((column, colIndex) => {
                    row[column] = record[colIndex];
                });
                if (index >= 1) {
                    dependencyStatusData[id] = statusMaps?.[index - 1] || {};
                }
                rows.push(id);
                data[id] = row;
            });

            let columns = fileColumns;

            const metaData = {};
            columns.forEach((columnId, index) => {
                metaData[columnId] = {
                    id: columnId,
                    type: columnTypes.SINGLE_LINE,
                    editable: true,
                    order: index
                };
            });

            dispatch(
                receiveData(types.IMPORT_READ_FILE_SUCCESS, {
                    file,
                    data,
                    columns,
                    metaData,
                    rows,
                    hasRecordId,
                    resourceKey,
                    dependencyStatusData
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.IMPORT_READ_FILE_FAILED));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importViewReadFile({ dbId, queryParams, successCallback, errorCallback }) {
    const { file } = queryParams;

    return async function(dispatch) {
        try {
            dispatch(requestData(types.IMPORT_REVIEW_FILE_REQUEST));
            const resData = await importViewReadFileApi({ file, dbId });
            const fileColumns = resData?.columns || [];
            const records = resData?.records || [];
            const resourceKey = resData?.resourceKey;
            const statusMaps = resData?.statusMaps;

            const firstRecord = records[0] || [];
            const recordIdIndex = firstRecord.findIndex(cell =>
                RECORD_IDS_MATCHES?.map(i => i?.toLowerCase())?.includes(cell?.toLowerCase())
            );
            const hasRecordId = recordIdIndex > -1;
            const data = {};
            const rows = [];
            let dependencyStatusData = {};

            records.forEach((record, index) => {
                const fakeRecordId = generateTempId();
                const id = hasRecordId ? record[recordIdIndex] || fakeRecordId : index;
                const row = {};

                fileColumns.forEach((column, colIndex) => {
                    row[column] = record[colIndex];
                });
                if (index >= 1) {
                    dependencyStatusData[id] = statusMaps?.[index - 1] || {};
                }
                rows.push(id);
                data[id] = row;
            });

            let columns = fileColumns;

            const metaData = {};

            columns.forEach((columnId, index) => {
                metaData[columnId] = {
                    id: columnId,
                    type: columnTypes.SINGLE_LINE,
                    editable: true,
                    order: index
                };
            });

            dispatch(
                receiveData(types.IMPORT_READ_FILE_SUCCESS, {
                    file,
                    data,
                    columns,
                    metaData,
                    rows,
                    hasRecordId,
                    resourceKey,
                    dependencyStatusData
                })
            );

            return (
                successCallback &&
                successCallback({ metaData, columns, firstRow: data?.[rows?.[0]] || {}, hasRecordId })
            );
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.IMPORT_READ_FILE_FAILED));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function importViewData({ queryParams, successCallback, errorCallback }) {
    //const { dbId, viewId, file, options } = queryParams;

    return async function(dispatch) {
        try {
            await importViewApi(queryParams);

            // dispatch(receiveData(types.IMPORT_FILE_SUCCESS, resData));

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function mergeImport({ dbId, viewId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await mergeImportApi({ dbId, viewId, body });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function checkCanOpenImportOrExport(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dependencies, metaData } = gridUI;
        let isValid = false;
        for (let i = 0; i < dependencies.length; i++) {
            const dpDc = dependencies[i];
            const parent = dpDc?.parent;
            const child = dpDc?.child;

            const parentDetail = metaData?.[parent];
            const childDetail = metaData?.[child];

            const isParentIsSource =
                parentDetail?.type === columnTypes.TRANSLATION &&
                parentDetail?.customProperties?.localizationType === TRANSLATION_TYPES.SOURCE_LANG;

            const isChildLanguage = childDetail?.type === columnTypes.TRANSLATION;
            if (isParentIsSource && isChildLanguage) {
                isValid = true;
                break;
            }
        }

        return callback && callback({ isValid });
    };
}

export function importViewJSONData({ dbId, viewId, body, query, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await importJSONLocalizationGridApi({ dbId, viewId, body, query });
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function setTaskIdStatus({ taskId, status }) {
    return {
        type: types.SET_TASK_ID_STATUS,
        payload: {
            taskId,
            status
        }
    };
}
