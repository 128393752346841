import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BackIOSSVG from 'assets/images/svg/BackIOSSVG';
import SettingSVG from 'assets/images/svg/SettingSVG';
import { ReactFlowProvider } from 'react-flow-renderer';
import AutomationDetailContent from './contents';
import Flow from './Flow';
import PopperMenu from 'components/menus/Popper';
import AutomationItemPopup from '../AutomationItemPopup';
import { useDispatch } from 'react-redux';
import {
    getTriggersAndActionsNode,
    createAutomationTrigger,
    setAutomationDetail,
    updateAutomation,
    setAutomationDetailError,
    fetchAllLambdas
} from '../action';
import { AUTOMATION_NODE, AUTOMATION_STATUS } from '../const';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import TestAutomation from './TestAutomation';
import AutomationSwitch from '../AutomationSwitch';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        '& .react-flow__node': {
            cursor: 'unset'
        },
        '& .react-flow__handle': {
            background: '#b1b1b7',
            width: 1,
            height: 4,
            border: 0,
            borderRadius: 0
        },
        '& .react-flow__attribution': {
            width: 0,
            height: 0,
            padding: 0
        }
    },
    icon: {
        display: 'flex',
        cursor: 'pointer'
    },
    header: {
        padding: '8px 25px',
        borderBottom: `1px solid ${theme.colors.silver}`
    },
    title: {
        maxWidth: 400,
        fontSize: `18px !important`,
        margin: '0 4.5px 0 16px',
        '& p': {
            wordBreak: 'break-word'
        }
    },
    flx: {
        flex: 1
    },
    content: {
        background: '#E5E5E5',
        position: 'relative'
    },
    rightsidebar: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.border}`
    },
    popperRoot: {
        '& .popper-content': {
            borderRadius: 4
        }
    },
    testAutomation: {
        marginLeft: 10
    },
    switchWrapper: {
        marginLeft: 14
    },
    loading: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 5,
        marginTop: 40,
        display: 'flex',
        justifyContent: 'center'
    },
    buttonProgress: {
        marginBottom: 20,
        textAlign: 'center'
    },
    hide: {
        visibility: 'hidden',
        opacity: 0,
        pointerEvents: 'none'
    }
}));

const AutomationDetail = ({ onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState();
    const [toggle, setToggle] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [isFlow, setIsFlow] = useState(false);
    const automationDetail = useGetAutomationDetail();
    const automationDetailError = useGetAutomationDetailError();
    const settingRef = useRef();

    const triggers = useMemo(() => {
        return (automationDetail.triggerNodes || []).map(el => el.trigger);
    }, [automationDetail.triggerNodes]);

    useEffect(() => {
        if (selectedNode) {
            setToggle(true);
        }
    }, [selectedNode]);

    useEffect(() => {
        if (automationDetail?.id) {
            setIsFetching(true);
            dispatch(setAutomationDetailError({}));
            dispatch(fetchAllLambdas());
            dispatch(
                getTriggersAndActionsNode({
                    automationId: automationDetail.id,
                    successCallback: response => {
                        setIsFlow(true);
                        if (response?.triggerNodes?.length) {
                            setToggle(true);
                            setSelectedNode(response.triggerNodes[0]);
                        }
                        setTimeout(() => {
                            setIsFetching(false);
                        }, DEBOUNCE_TIME_SEARCHING);
                    },
                    errorCallback: () => {
                        setTimeout(() => {
                            setIsFetching(false);
                        }, DEBOUNCE_TIME_SEARCHING);
                    }
                })
            );
        }
    }, [automationDetail.id, dispatch]);

    const stopPropagation = useCallback(e => {
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        e.persist();
    }, []);

    const reUpdateSelectedNode = useCallback(() => {
        if (!selectedNode) return;
        const el = [...automationDetail.triggerNodes, ...(automationDetail.nodes || [])].find(
            el => el.id === selectedNode.id
        );
        if (el) {
            setSelectedNode(el);
        }
    }, [automationDetail, selectedNode]);

    const onCreateTrigger = useCallback(
        option => {
            if (
                automationDetail.triggerNodes &&
                automationDetail.triggerNodes.find(trigger => trigger.trigger === option.value)
            ) {
                return;
            }

            dispatch(
                createAutomationTrigger({
                    automationId: automationDetail.id,
                    body: {
                        trigger: option.value
                    },
                    successCallback: responseData => {
                        dispatch(
                            setAutomationDetail({
                                ...automationDetail,
                                triggerNodes: [...(automationDetail.triggerNodes || []), responseData]
                            })
                        );
                        setTimeout(() => {
                            setSelectedNode(responseData);
                        }, 100);
                    }
                })
            );
        },
        [dispatch, automationDetail]
    );

    const onClickNode = useCallback(
        (e, node) => {
            stopPropagation(e);
            setSelectedNode(node);
        },
        [stopPropagation]
    );

    const automationNodes = useMemo(() => {
        const arr = [];
        if (automationDetail?.triggerNodes?.length) {
            arr.push({
                id: 'trigger',
                type: AUTOMATION_NODE.TRIGGER,
                triggers: automationDetail.triggerNodes.map(trigger => {
                    return {
                        ...trigger,
                        isError: Object.values(automationDetailError[trigger.id] || {}).filter(el => !!el).length > 0,
                        errors: automationDetailError[trigger.id]
                    };
                }),
                onCreateTrigger,
                selectedNode,
                onClickNode,
                setSelectedNode
            });
        }
        return [
            ...arr,
            ...(automationDetail.nodes || []).map(el => ({
                ...el,
                type: AUTOMATION_NODE.ACTION,
                isError: Object.values(automationDetailError[el.id] || {}).filter(el => !!el).length > 0,
                errors: automationDetailError[el.id],
                selectedNode,
                onClickNode,
                setSelectedNode
            }))
        ];
    }, [automationDetail, onClickNode, onCreateTrigger, selectedNode, automationDetailError]);

    const onClickSetting = useCallback(
        e => {
            stopPropagation(e);
            setAnchorEl(anchorEl => (anchorEl ? null : e.target));
        },
        [stopPropagation]
    );

    const handleClickAway = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleToggle = useCallback(
        e => {
            stopPropagation(e);
            if (automationDetail?.triggerNodes?.length) {
                setToggle(toggle => !toggle);
            }
        },
        [stopPropagation, automationDetail]
    );

    const onChangeTitle = useCallback(
        ({ automationId, title }) => {
            const oldAutomationDetail = { ...automationDetail };
            dispatch(
                setAutomationDetail({
                    ...automationDetail,
                    title
                })
            );
            dispatch(
                updateAutomation({
                    id: automationId,
                    body: {
                        title
                    },
                    errorCallback: () => {
                        dispatch(setAutomationDetail(oldAutomationDetail));
                    }
                })
            );
        },
        [automationDetail, dispatch]
    );

    const onChangeDetail = useCallback(
        ({ automationId, body, onSuccess, onError }) => {
            dispatch(
                updateAutomation({
                    id: automationId,
                    body: {
                        ...body,
                        file: undefined
                    },
                    file: body.file,
                    successCallback: async data => {
                        onSuccess();
                        dispatch(
                            setAutomationDetail({
                                ...automationDetail,
                                ...data
                            })
                        );
                    },
                    errorCallback: () => {
                        onError();
                    }
                })
            );
        },
        [automationDetail, dispatch]
    );

    const onChangeStatus = useCallback(
        ({ automationId, status }) => {
            const oldAutomationDetail = { ...automationDetail };
            dispatch(
                setAutomationDetail({
                    ...automationDetail,
                    status
                })
            );
            dispatch(
                updateAutomation({
                    id: automationId,
                    body: {
                        status
                    },
                    errorCallback: () => {
                        dispatch(setAutomationDetail(oldAutomationDetail));
                    }
                })
            );
        },
        [automationDetail, dispatch]
    );

    const successDeleteCallback = useCallback(
        automation => {
            onClose();
        },
        [onClose]
    );

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap">
            <Grid
                item
                container
                wrap="nowrap"
                alignItems="center"
                className={classes.header}
                id="automation-detail-header"
            >
                <Grid item className={classes.icon}>
                    <BackIOSSVG onClick={onClose} width="10" height="17" viewBox="0 0 10 17" />
                </Grid>
                <Grid item className={classes.title} onClick={handleToggle}>
                    <p className="body1 line-clamp-1">{automationDetail.title}</p>
                </Grid>
                <Grid ref={settingRef} item className={classes.icon} onClick={onClickSetting}>
                    <SettingSVG />
                </Grid>
                <Grid item className={classes.switchWrapper}>
                    <AutomationSwitch automation={automationDetail} onChange={onChangeStatus} />
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classnames(classes.flx, classes.testAutomation)}
                >
                    <TestAutomation />
                </Grid>
            </Grid>
            <Grid
                item
                container
                className={classnames(classes.flx, classes.content)}
                wrap="nowrap"
                id="react-flow-wrapper"
            >
                {isFetching && (
                    <Grid item className={classes.loading}>
                        <Grid item className={classes.buttonProgress}>
                            <Grid item>
                                <CircularProgress size={40} />
                            </Grid>
                            <Grid item>
                                <h4 className="prose prose-lg font-medium">Loading ... wait a second</h4>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {isFlow && (
                    <Grid
                        item
                        className={classnames(classes.flx, {
                            [classes.hide]: isFetching
                        })}
                    >
                        <ReactFlowProvider>
                            <Flow
                                automationNodes={automationNodes}
                                toggle={toggle}
                                onCreateTrigger={onCreateTrigger}
                                setSelectedNode={setSelectedNode}
                            />
                        </ReactFlowProvider>
                    </Grid>
                )}
                {toggle && isFlow && !isFetching && (
                    <Grid item className={classnames(classes.flx, classes.rightsidebar)}>
                        <AutomationDetailContent
                            showTabs={automationDetail.status !== AUTOMATION_STATUS.DRAFT}
                            selectedNode={selectedNode}
                            setSelectedNode={setSelectedNode}
                            reUpdateSelectedNode={reUpdateSelectedNode}
                        />
                    </Grid>
                )}
            </Grid>

            {anchorEl && (
                <PopperMenu className={classes.popperRoot} anchorEl={anchorEl} container={settingRef.current}>
                    <AutomationItemPopup
                        id={automationDetail.id}
                        title={automationDetail.title}
                        description={automationDetail.description}
                        iconFileId={automationDetail.iconFileId}
                        triggers={triggers}
                        hideChangeName
                        onClickAway={handleClickAway}
                        onChangeTitle={onChangeTitle}
                        onChangeDetail={onChangeDetail}
                        successDeleteCallback={successDeleteCallback}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    />
                </PopperMenu>
            )}
        </Grid>
    );
};

export default React.memo(AutomationDetail);
