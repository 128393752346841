import * as types from '../types';
import uniq from 'lodash/uniq';
import {
    findAndAddNode,
    findAndUpdateNode,
    findAndRemoveNode,
    findAndUpdateDeletedNode,
    getPath,
    findNode,
    generatePathTagRef
} from 'utils/gridUI/pathTag';

const handler = {
    [types.TOGGLE_PATH_TAG](state, { payload }) {
        const { isPathTagOn, nodeIdCreating, nodeIdShowPopup } = state;
        return {
            ...state,
            isPathTagOn: !isPathTagOn,
            nodeIdCreating: !isPathTagOn ? null : nodeIdCreating,
            nodeIdShowPopup: !isPathTagOn ? null : nodeIdShowPopup
        };
    },
    [types.SET_PATH_TAG_ON](state, { payload }) {
        const { isPathTagOn } = payload;
        return {
            ...state,
            isPathTagOn
        };
    },
    [types.TOGGLE_NODES](state, { payload }) {
        const { nodeIds } = payload;
        return {
            ...state,
            expanded: nodeIds
        };
    },
    [types.TURN_ON_NODE_POP_UP](state, { payload }) {
        const { nodeId } = payload;
        return {
            ...state,
            nodeIdShowPopup: nodeId
        };
    },
    [types.TURN_OFF_NODE_POP_UP](state, { payload }) {
        return {
            ...state,
            nodeIdShowPopup: null
        };
    },
    [types.TURN_ON_FIRST_SKELETON](state, { payload }) {
        const { nodeId } = payload;
        return {
            ...state,
            nodeIdCreating: nodeId
        };
    },

    [types.FETCH_PATH_TAG_TREE](state, { payload }) {
        return {
            ...state,
            isFetchingPathTagTree: true
        };
    },
    [types.FETCH_PATH_TAG_TREE_FAILED](state, { payload }) {
        return {
            ...state,
            isFetchingPathTagTree: false
        };
    },
    [types.FETCH_PATH_TAG_TREE_SUCCESS](state, { payload }) {
        const { tree } = payload;
        return {
            ...state,
            tree,
            isFetchingPathTagTree: false,
            pathRef: generatePathTagRef({ tree })
        };
    },
    [types.ADD_ROOT_NODE](state, { payload }) {
        return {
            ...state,
            isCreatingNode: true
        };
    },
    [types.ADD_ROOT_NODE_SUCCESS](state, { payload }) {
        const { node } = payload;
        const { tree } = state;
        const newTree = [node, ...tree];
        return {
            ...state,
            tree: newTree,
            nodeIdCreating: null,
            isCreatingNode: false,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },
    [types.ADD_ROOT_NODE_FAILED](state, { payload }) {
        return {
            ...state,
            nodeIdCreating: null,
            isCreatingNode: false
        };
    },
    [types.ADD_CHILD_NODE](state, { payload }) {
        const { parentNodeId } = payload;
        const { expanded } = state;
        return {
            ...state,
            expanded: uniq([...expanded, parentNodeId]),
            isCreatingNode: true
        };
    },
    [types.ADD_CHILD_NODE_SUCCESS](state, { payload }) {
        const { node, parentNodeId } = payload;
        const { tree } = state;
        const newTree = findAndAddNode({ tree, parentNodeId, node, isLast: true });

        return {
            ...state,
            tree: newTree,
            nodeIdCreating: null,
            isCreatingNode: false,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },
    [types.ADD_CHILD_NODE_FAILED](state, { payload }) {
        return {
            ...state,
            nodeIdCreating: null,
            isCreatingNode: false
        };
    },
    [types.UPDATE_NODE](state, { payload }) {
        const { nodeId, node: updatedNode } = payload;
        const { tree } = state;
        const { pathArr } = getPath({ tree, nodeId });
        let newTree = [];

        const level = pathArr.length;
        if (level === 1) {
            newTree = tree.map(node => {
                if (node.id === nodeId) {
                    return updatedNode;
                }
                return node;
            });
        } else {
            const parentNodeId = pathArr[level - 2];
            newTree = findAndUpdateNode({
                tree,
                parentNodeId,
                nodeId,
                updatedNode
            });
        }

        return {
            ...state,
            tree: newTree,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },
    [types.DELETE_NODE](state, { payload }) {
        const { nodeId } = payload;
        const { tree } = state;
        const { pathArr } = getPath({ tree, nodeId });
        let newTree = [];
        const level = pathArr.length;
        if (level === 1) {
            newTree = tree.map(node => {
                if (node.id === nodeId) {
                    node.isDeleted = true;
                }
                return node;
            });
        } else {
            const parentNodeId = pathArr[level - 2];
            newTree = findAndUpdateDeletedNode({
                tree,
                parentNodeId,
                nodeId,
                isDeleted: true
            });
        }

        return {
            ...state,
            tree: newTree,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },
    [types.DELETE_NODE_FAILED](state, { payload }) {
        const { nodeId } = payload;
        const { tree } = state;
        const { pathArr } = getPath({ tree, nodeId });
        let newTree = [];
        const level = pathArr.length;
        if (level === 1) {
            newTree = tree.map(node => {
                if (node.id === nodeId) {
                    node.isDeleted = false;
                }
                return node;
            });
        } else {
            const parentNodeId = pathArr[level - 2];
            newTree = findAndUpdateDeletedNode({
                tree,
                parentNodeId,
                nodeId,
                isDeleted: false
            });
        }
        return {
            ...state,
            tree: newTree,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },
    [types.DELETE_NODE_SUCCESS](state, { payload }) {
        const { nodeId } = payload;
        const { tree } = state;
        const { pathArr } = getPath({ tree, nodeId });
        let newTree = [];
        const level = pathArr.length;
        if (level === 1) {
            newTree = tree.filter(node => node.id !== nodeId);
        } else {
            const parentNodeId = pathArr?.[level - 2];
            newTree = findAndRemoveNode({
                tree,
                parentNodeId,
                nodeId
            });
        }
        return {
            ...state,
            tree: newTree,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },

    [types.MOVE_NODE_SUCCESS](state, { payload }) {
        const { toPathId, dragNodeIds } = payload;
        const { tree } = state;
        /**
         * Delete old node
         */

        let newTree = [];
        dragNodeIds.map(id => {
            const currentNode = findNode({ tree, nodeId: id });
            const { pathArr } = getPath({ tree, nodeId: id });
            const level = pathArr.length;
            if (level === 1) {
                newTree = tree.filter(node => node.id !== id);
            } else {
                const parentNodeId = pathArr?.[level - 2];
                newTree = findAndRemoveNode({
                    tree,
                    parentNodeId,
                    nodeId: id
                });
            }
            /**
             * Insert new node
             */
            newTree = findAndAddNode({ tree: newTree, parentNodeId: toPathId, node: currentNode, isLast: true });
            return false;
        });

        return {
            ...state,
            tree: newTree,
            pathRef: generatePathTagRef({ tree: newTree })
        };
    },

    [types.FILTER_PATH_TAG](state, { payload }) {
        return {
            ...state,
            isFilteringPathTag: true
        };
    },
    [types.FILTER_PATH_TAG_FAILED](state, { payload }) {
        return {
            ...state,
            isFilteringPathTag: false
        };
    },
    [types.FILTER_PATH_TAG_SUCCESS](state, { payload }) {
        return {
            ...state,
            isFilteringPathTag: false
        };
    },

    [types.SET_ROWS_DRAGGING](state, { payload }) {
        const { rowIds = [] } = payload;
        const { rows, data, ROW_START_INDEX } = state;

        let draggingRowIndexes = rowIds
            ?.filter(rowId => rows.findIndex(row => row === rowId) !== -1)
            ?.map(rowId => rows.findIndex(row => row === rowId) + ROW_START_INDEX);

        let draggingRowTempData = {};
        rowIds.forEach(rowId => {
            draggingRowTempData[rowId] = data?.[rowId];
        });

        return {
            ...state,
            draggingRows: rowIds,
            draggingRowIndexes: draggingRowIndexes,
            draggingRowTempData
        };
    },
    [types.SET_DROP_PATH](state, { payload }) {
        const { nodeId } = payload;
        return {
            ...state,
            dropPath: nodeId
        };
    },
    [types.TURN_ON_DRAGGING_PATH_TAG](state, { payload }) {
        return {
            ...state,
            isDraggingPathTag: true
        };
    },
    [types.TURN_OFF_DRAGGING_PATH_TAG](state, { payload }) {
        return {
            ...state,
            isDraggingPathTag: false
        };
    },
    [types.SET_DRAGGING_PATH](state, { payload }) {
        const { nodeId } = payload;
        return {
            ...state,
            dragPathId: nodeId
        };
    },
    [types.SET_DRAG_NODE_IDS](state, { payload }) {
        return {
            ...state,
            dragNodeIds: payload
        };
    },
    [types.SET_DRAGGING_NODE_PATH](state, { payload }) {
        return {
            ...state,
            draggingPath: payload
        };
    },
    [types.RESET_DROP_PATH_AND_DRAG_NODE_IDS](state, { payload }) {
        return {
            ...state,
            dragPathId: null,
            dropPath: null,
            dragNodeIds: [],
            draggingPath: null
        };
    }
};

export default handler;
