import React, { useEffect, useLayoutEffect, useRef } from 'react';
import classnames from 'classnames';
import { CircularProgress, Collapse, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LogoSVG from 'assets/images/svg/webHook/LogoSVG';
import hexToRgba from 'hex-to-rgba';
import { getFriendlyTime } from 'utils/datetime';
import { useTranslation } from 'react-i18next';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { upperFirst } from 'lodash';
import { useShareViewGroupMembers } from 'hooks/gridUI';
import { getAvatarUrl } from 'utils/images';
import Avatar from 'components/avatar/User';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import { useDispatch } from 'react-redux';
import { getAutomationHistory } from 'gridUI/automations/action';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        padding: '0 12px'
    },
    primaryColor: { color: theme.palette.primary.main },
    flex: { display: 'flex' },
    flx: { flex: 1 },
    pr2: { paddingRight: 2 },
    pointer: { cursor: 'pointer' },
    fullHeight: { height: '100%' },
    item: {
        marginTop: 20,
        paddingBottom: 12,
        borderBottom: `1px solid ${theme.colors.lightGrey}`
    },
    iconWrapper: {
        position: 'relative',
        top: 5,
        marginRight: 7,
        width: 30,
        height: 30,
        borderRadius: '50%'
    },
    fs12: {
        fontSize: 12
    },
    mb5: {
        marginBottom: 5
    },
    status: {
        margin: '5px 0 6px',
        padding: '0 9px',
        width: 'fit-content',
        height: 20,
        borderRadius: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.success': {
            background: theme.colors.seaGreen
        },
        '&.fail': {
            background: hexToRgba(theme.colors.brightRed, 0.8)
        }
    },
    statusText: {
        color: theme.colors.white
    },
    mt8: {
        marginTop: 8
    },
    mt40: {
        marginTop: 40
    },
    avatar: {
        '& > svg': {
            width: 'unset',
            height: 'unset'
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    loadingMore: {
        position: 'absolute',
        bottom: 12,
        width: '100%'
    }
}));

const AUDIT_STATUS = {
    STARTED: 'STARTED',
    RUNNING: 'RUNNING',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS'
};

const AuditItem = React.memo(({ data = {} }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const members = useShareViewGroupMembers() || [];
    const [openViewMore, setOpenViewMore] = useState(false);
    const preRef = useRef();

    const { createdDate, status = '', user = {}, nodeExecutions = [] } = data;

    const isFailed = useMemo(() => {
        return status === AUDIT_STATUS.FAILED;
    }, [status]);

    const itemUser = useMemo(() => {
        if (!user?.id) return null;
        return members.find(m => m?.user?.id === user?.id?.toString()) || null;
    }, [members, user]);

    const lastExecution = useMemo(() => {
        if (!nodeExecutions?.length) return null;
        const last = nodeExecutions[nodeExecutions.length - 1];
        if (!last.receiveData && !last.lastError) return null;
        return nodeExecutions[nodeExecutions.length - 1];
    }, [nodeExecutions]);

    const toggleViewMore = useCallback(() => {
        setOpenViewMore(open => !open);
    }, []);

    useEffect(() => {
        if (lastExecution && preRef.current) {
            let content = lastExecution.receiveData || lastExecution.lastError;
            try {
                const json = JSON.parse(content);
                try {
                    const nextJson = JSON.parse(json);
                    preRef.current.innerHTML = JSON.stringify(nextJson, undefined, 4);
                } catch (e) {
                    preRef.current.innerHTML = JSON.stringify(json, undefined, 4);
                }
            } catch (e) {
                preRef.current.innerHTML = content;
            }
        }
    }, [lastExecution]);

    return (
        <Grid item container wrap="nowrap" className={classes.item}>
            <Grid item container alignItems="center" justifyContent="center" className={classes.iconWrapper}>
                <Avatar
                    className={classnames({
                        [classes.avatar]: !itemUser
                    })}
                    src={itemUser ? getAvatarUrl(itemUser?.user?.id) : ''}
                    size={30}
                    color={theme.colors.lavender}
                >
                    {itemUser ? <AvatarIconSVG /> : <LogoSVG />}
                </Avatar>
            </Grid>
            <Grid item className={classes.flx}>
                <Grid item>
                    <p className="body2 font-bold text-[12px] inline">
                        {itemUser ? itemUser?.user?.fullName : 'Gridly automation'}
                    </p>
                    <p className="body2 text-[12px] inline"> ran this rule</p>
                </Grid>
                <Grid item>
                    <p className="caption text-[12px]">{getFriendlyTime(createdDate)}</p>
                </Grid>
                <Grid item>
                    <Grid
                        item
                        className={classnames(classes.status, {
                            success: !isFailed,
                            fail: isFailed
                        })}
                    >
                        <p className="body1 text-[12px] text-white">{upperFirst(status.toLocaleLowerCase())}</p>
                    </Grid>
                </Grid>
                {lastExecution && (
                    <>
                        <Grid item container wrap="nowrap" alignItems="center">
                            <Grid item className={classnames(classes.pr2, classes.pointer)} onClick={toggleViewMore}>
                                <p className="body1 text-[12px] text-text-primary">{t('global_view_detail')}</p>
                            </Grid>
                            <Grid item className={classnames(classes.flex, classes.pointer)} onClick={toggleViewMore}>
                                {openViewMore ? (
                                    <ArrowUpSVG color={theme.palette.primary.main} width="14" height="14" />
                                ) : (
                                    <ArrowDownSVG color={theme.palette.primary.main} width="14" height="14" />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Collapse in={openViewMore}>
                                <p className="body2 mt-2 text-[12px]">
                                    <pre ref={preRef} style={{ whiteSpace: 'pre-wrap' }} className="revert-style" />
                                </p>
                            </Collapse>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
});

const AuditLog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const { id, history = [] } = automationDetail;
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [page, setPage] = useState(0);
    const size = 10;

    const handleScroll = React.useCallback(
        e => {
            const { target } = e;
            if (target.scrollTop + target.clientHeight < target.scrollHeight - 300 || isFetching || isFetchingMore) {
                return;
            }
            setIsFetchingMore(true);
            dispatch(
                getAutomationHistory({
                    automationId: id,
                    page: page + 1,
                    offset: (page + 1) * size,
                    size,
                    successCallback: response => {
                        setIsFetchingMore(false);
                        if (response?.length) {
                            setPage(page => page + 1);
                        }
                    },
                    errorCallback: () => {
                        setIsFetchingMore(false);
                    }
                })
            );
        },
        [dispatch, id, isFetching, isFetchingMore, page]
    );

    useLayoutEffect(() => {
        setIsFetching(true);
        dispatch(
            getAutomationHistory({
                automationId: id,
                page: 0,
                offset: 0,
                size,
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch, id]);

    useEffect(() => {
        const el = document.getElementById('automationAuditLog');
        el.addEventListener('scroll', handleScroll);
        return () => {
            el.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Grid
            item
            container
            direction="column"
            wrap="nowrap"
            className={classnames(classes.root, {
                [classes.fullHeight]: isFetching && !history?.length
            })}
        >
            {isFetching && !history?.length && <CircularProgress size={24} className={classes.buttonProgress} />}
            {!isFetching && history.length === 0 && (
                <Grid
                    item
                    container
                    direction="column"
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.mt40}
                >
                    <Grid item>
                        <EmptyBoxSVG />
                    </Grid>
                    <Grid item>
                        <p className="body2">No data</p>
                    </Grid>
                </Grid>
            )}
            {history?.map((h, idx) => (
                <AuditItem key={h.id} data={h} />
            ))}
            {isFetchingMore && (
                <Grid item className={classes.loadingMore}>
                    <CircularProgress size={24} className={classes.buttonProgress} />
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(AuditLog);
