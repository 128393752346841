import React from 'react';

function AccessViewSVG({ color = '#4A91E2', width = 16, height = 14, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M16 1C16 0.447715 15.5523 0 15 0H0V16H15C15.5523 16 16 15.5523 16 15V1Z" fill={color} />
            <rect
                x="-0.4"
                y="0.4"
                width="10.4894"
                height="10.4813"
                rx="0.6"
                transform="matrix(-1 0 0 1 12.8447 2.35938)"
                stroke="white"
                strokeWidth="0.8"
            />
            <rect width="5.10463" height="5.10463" transform="matrix(-1 0 0 1 7.45984 8.53638)" fill={color} />
            <mask id="path-4-inside-1" fill="white">
                <path d="M6.83545 9.46089C6.83545 9.2952 6.70113 9.16089 6.53545 9.16089H2.65521C2.48953 9.16089 2.35521 9.2952 2.35521 9.46089V13.3411C2.35521 13.5068 2.48953 13.6411 2.65521 13.6411H6.53545C6.70113 13.6411 6.83545 13.5068 6.83545 13.3411V9.46089Z" />
            </mask>
            <path
                d="M6.83545 9.46089C6.83545 9.2952 6.70113 9.16089 6.53545 9.16089H2.65521C2.48953 9.16089 2.35521 9.2952 2.35521 9.46089V13.3411C2.35521 13.5068 2.48953 13.6411 2.65521 13.6411H6.53545C6.70113 13.6411 6.83545 13.5068 6.83545 13.3411V9.46089Z"
                stroke="white"
                strokeWidth="1.6"
                mask="url(#path-4-inside-1)"
            />
            <path d="M7.94885 5.54004L11.176 5.54004L11.176 8.76709" stroke="white" strokeWidth="0.8" />
            <path d="M11.1761 5.53979L7.94896 8.95646" stroke="white" strokeWidth="0.8" />
        </svg>
    );
}

export default React.memo(AccessViewSVG);
