import { enqueueSnackbar } from 'notifier/actions';
import { requestData, receiveData } from '../api/actions';
import {
    getCurrentCompanyTMs,
    cleanupCurrentCompanyTM,
    exportCurrentCompanyTMFileApi,
    importCurrentCompanyTMFile,
    createTMApi,
    updateTMApi,
    deleteTMApi,
    fetchTMSourcesApi,
    fetchTMTargetApi,
    getTranslationMem,
    createSegmentApi,
    deleteSegmentApi,
    addAlternativeApi,
    deleteAlternativeApi,
    updateAlternativeApi,
    getTMApi,
    getSegmentApi
} from 'services/tm';
import * as types from './types';
import i18n from 'i18n';

export function createTM({ successCallback, errorCallback }) {
    return async function(dispatch) {
        const tmData = {
            name: 'New TM',
            description: 'Default',
            workspaceIds: []
        };
        dispatch(_createTMAction());
        try {
            const newTM = await createTMApi({ body: tmData });
            dispatch(_createTMActionSuccess({ tm: newTM }));
            dispatch(turnOnLastPopup({ tmId: newTM.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_createTMActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createTMAction() {
    return {
        type: types.CREATE_TM
    };
}

function _createTMActionSuccess({ tm }) {
    return {
        type: types.CREATE_TM_SUCCESS,
        payload: {
            tm
        }
    };
}

function _createTMActionFailed() {
    return {
        type: types.CREATE_TM_FAILED
    };
}

export function deleteTM({ tmId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_deleteTmAction(tmId));
        try {
            await deleteTMApi(tmId);
            dispatch(_deleteTmActionSuccess(tmId));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteTmActionFailed(tmId));
            errorCallback && errorCallback(message);
        }
    };
}

export function _deleteTmAction(tmId) {
    return {
        type: types.DELETE_TM,
        payload: {
            tmId
        }
    };
}

export function _deleteTmActionSuccess(tmId) {
    return {
        type: types.DELETE_TM_SUCCESS,
        payload: {
            tmId
        }
    };
}

export function _deleteTmActionFailed(tmId) {
    return {
        type: types.DELETE_TM_FAILED,
        payload: {
            tmId
        }
    };
}

export function updateTM({ oldTM, newTM, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_updateTmAction({ tm: newTM }));
        try {
            await updateTMApi({ tmId: newTM?.id, body: newTM });
            successCallback && successCallback();
            dispatch(
                enqueueSnackbar({
                    message: 'TM setting saved',
                    type: 'info'
                })
            );
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_updateTmAction({ tm: oldTM }));
            errorCallback && errorCallback(message);
        }
    };
}

export function getTM({ tmId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const tm = await getTMApi({ tmId });
            successCallback && successCallback({ tm });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function _updateTmAction({ tm }) {
    return {
        type: types.UPDATE_TM,
        payload: {
            tm
        }
    };
}

export function turnOnLastPopup({ tmId }) {
    return {
        type: types.TURN_ON_SHOW_LAST_POP_UP_CREATE_TM,
        payload: {
            tmId
        }
    };
}

export function turnOffLastPopup() {
    return {
        type: types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_TM
    };
}

export function onTMFileInputRejected() {
    return function(dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `Invalid TM file to upload`,
                type: 'info'
            })
        );
    };
}

export function fetchCurrentCompanyTMs({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.FETCH_COMPANY_TMS));

            const result = await getCurrentCompanyTMs();

            dispatch(receiveData(types.FETCH_COMPANY_TMS_SUCCESS, result));

            return successCallback && successCallback(result);
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );

            dispatch(receiveData(types.FETCH_COMPANY_TMS_FAILED));
            errorCallback && errorCallback(message);
        }
    };
}

export function cleanCurrentCompanyTM({ tmId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.EXECUTE_COMPANY_TM_ACTION));

            const result = await cleanupCurrentCompanyTM(tmId);

            dispatch(receiveData(types.EXECUTE_COMPANY_TM_ACTION_SUCCESS, result));

            dispatch(
                enqueueSnackbar({
                    message: i18n.t('cleanCurrentCompanyTM_success'),
                    type: 'info'
                })
            );

            successCallback && successCallback(result);
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );

            dispatch(receiveData(types.EXECUTE_COMPANY_TM_ACTION_FAILED));
            errorCallback && errorCallback(message);
        }
    };
}

export function exportCurrentCompanyTM({ tmId, format, source, targets, successCallback, errorCallback }) {
    return async function(dispatch) {
        if (!source) {
            console.log('source cannot be empty');
            return;
        }
        try {
            dispatch(requestData(types.EXECUTE_COMPANY_TM_ACTION));

            const result = await exportCurrentCompanyTMFileApi({ tmId, format, sourceLang: source, targets });

            dispatch(receiveData(types.EXECUTE_COMPANY_TM_ACTION_SUCCESS, result));

            successCallback && successCallback(result);
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );

            dispatch(receiveData(types.EXECUTE_COMPANY_TM_ACTION_FAILED));
            errorCallback && errorCallback(message);
        }
    };
}

export function importCurrentCompanyTM({ tmId, files, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.EXECUTE_COMPANY_TM_ACTION));

            const result = await importCurrentCompanyTMFile(tmId, files);

            dispatch(receiveData(types.EXECUTE_COMPANY_TM_ACTION_SUCCESS, result));

            dispatch(
                enqueueSnackbar({
                    message: `The import has completed successfully`,
                    type: 'info'
                })
            );
            successCallback && successCallback(result);
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );

            dispatch(receiveData(types.EXECUTE_COMPANY_TM_ACTION_FAILED));
            errorCallback && errorCallback(message);
        }
    };
}

export function fetchTMSources({ tmId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_fetchTmSources({ tmId }));
        try {
            const sources = await fetchTMSourcesApi({ tmId });
            dispatch(_fetchTmSourcesSuccess({ tmId, sources }));
            successCallback && successCallback();
        } catch (error) {
            const { message, originalMessage } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchTmSourcesFailed({ tmId, error: originalMessage }));

            errorCallback && errorCallback(message);
        }
    };
}

export function fetchTMTarget({ tmId, source, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const targets = await fetchTMTargetApi({ tmId, source });
            successCallback && successCallback(targets);
        } catch (error) {
            const { message, originalMessage } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchTmSourcesFailed({ tmId, error: originalMessage }));

            errorCallback && errorCallback(message);
        }
    };
}

function _fetchTmSources({ tmId }) {
    return {
        type: types.FETCH_TM_SOURCES,
        payload: {
            tmId
        }
    };
}

function _fetchTmSourcesFailed({ tmId, error }) {
    return {
        type: types.FETCH_TM_SOURCES_FAILED,
        payload: {
            tmId,
            error
        }
    };
}

function _fetchTmSourcesSuccess({ tmId, sources }) {
    return {
        type: types.FETCH_TM_SOURCES_SUCCESS,
        payload: {
            tmId,
            sources
        }
    };
}

export function getTranslation({ tmId, offset, limit, targetLangs, lang, search, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const response = await getTranslationMem({ tmId, offset, limit, targetLangs, sourceLang: lang, search });
            dispatch(setListSegment({ response }));
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function setListSegment({ response }) {
    return {
        type: types.SET_LIST_SEGMENT,
        payload: {
            segments: response
        }
    };
}

export function setSelectSourceTm(source) {
    return {
        type: types.SET_SELECTED_SOURCE_TM,
        payload: {
            sourceSelected: source
        }
    };
}

export function setSelectTargetsTm(targets) {
    return {
        type: types.SET_SELECTED_TARGETS_TM,
        payload: {
            targetsSelected: targets
        }
    };
}

export function getSegment({ tmId, unitId, targetLangs, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const segments = await getSegmentApi({ tmId, unitId, targetLangs });
            successCallback && successCallback({ segments });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function createSegment({ tmId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await createSegmentApi({ tmId, body });
            dispatch(
                enqueueSnackbar({
                    message: 'Create segment successfully',
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function deleteSegment({ tmId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await deleteSegmentApi({ tmId, body });
            dispatch(
                enqueueSnackbar({
                    message: 'Delete segment successfully',
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function addAlternative({ tmId, unitId, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await addAlternativeApi({ tmId, unitId, body });
            dispatch(
                enqueueSnackbar({
                    message: 'Add alternative successfully',
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function deleteAlternative({ tmId, unitId, segmentId, targetLang, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await deleteAlternativeApi({ tmId, unitId, segmentId, targetLang });
            dispatch(
                enqueueSnackbar({
                    message: 'Delete alternative successfully',
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function updateAlternative({ tmId, unitId, segmentId, targetLang, body, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            const alternative = await updateAlternativeApi({ tmId, unitId, segmentId, targetLang, body });
            dispatch(_updateAlternative({ alternative }));
            successCallback && successCallback({ alternative });
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _updateAlternative({ alternative }) {
    return {
        type: types.UPDATE_NEW_ALTERNATIVE,
        payload: {
            alternative
        }
    };
}
