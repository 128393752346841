import React from 'react';

function AddIconLQASVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M14.8971 2.26054H11.3757C11.0219 1.28332 10.0952 0.575684 9.00006 0.575684C7.9049 0.575684 6.97823 1.28332 6.62441 2.26054H3.10307C2.1764 2.26054 1.41821 3.01872 1.41821 3.94539V15.7394C1.41821 16.666 2.1764 17.4242 3.10307 17.4242H14.8971C15.8237 17.4242 16.5819 16.666 16.5819 15.7394V3.94539C16.5819 3.01872 15.8237 2.26054 14.8971 2.26054ZM9.00006 2.26054C9.4634 2.26054 9.84249 2.63963 9.84249 3.10297C9.84249 3.5663 9.4634 3.94539 9.00006 3.94539C8.53673 3.94539 8.15763 3.5663 8.15763 3.10297C8.15763 2.63963 8.53673 2.26054 9.00006 2.26054ZM10.6849 14.0545H4.78792V12.3697H10.6849V14.0545ZM13.2122 10.6848H4.78792V8.99996H13.2122V10.6848ZM13.2122 7.3151H4.78792V5.63025H13.2122V7.3151Z"
                fill="#77B32B"
                fillOpacity="0.8"
            />
            <rect x="2.89258" y="4.26172" width="12.2136" height="11.7656" rx="0.4" fill="white" />
            <path
                d="M9.6792 6.54456V9.46575H12.6004V10.8234H9.6792V13.7446H8.32159V10.8234H5.40039V9.46575H8.32159V6.54456H9.6792Z"
                fill="#77B32B"
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(AddIconLQASVG);
