import { useMetaData } from './metaData';
import { useSelector } from 'react-redux';
import { useData } from './data';
import { getCellData } from 'utils/gridUI/cell';

export function useCellDataAndColumnInfo({ rowId, columnId }) {
    const data = useData();
    const metaData = useMetaData();
    const column = metaData?.[columnId];
    const cellData = getCellData({ data, rowId, columnId });

    return {
        column,
        cellData
    };
}

export function usePinnedCellIssueId() {
    return useSelector(state => state?.gridUI.pinnedCellIssueId);
}
