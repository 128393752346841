import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import { INPUT_RADIUS, INPUT_PADDING_LEFT, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import GroupPopup from './GroupPopup';
import PopperMenu from 'components/menus/Popper';
import { useDispatch } from 'react-redux';
import * as groupActions from '../actions/group';
import { changeContext } from 'app/actions';
import AdminGroupAvatar from './AdminGroupAvatar';
import TranslatorGroupAvatar from './TranslatorGroupAvatar';
import ModalBase from 'components/modal/Base';
import ConfirmDeleteGroupBox from './ConfirmDeleteGroupBox';
import { useHistory } from 'react-router-dom';
import { GROUP_ADMIN_TYPES } from 'const';
import { sendManualTrack } from 'tracker';
import OverflowTypography from 'components/textTruncated/OverflowTypography';
import { getGroupAvatarUrl } from 'utils/images';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.paleNavy
        },
        height: 42,
        display: 'flex'
    },
    listItem: {
        borderRadius: INPUT_RADIUS,
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT
    },
    active: {
        background: theme.colors.hoverItem
    },
    name: {
        fontSize: '0.875rem',
        color: theme.colors.dimGrey,
        width: 136
    },
    avatar: {
        marginRight: SPACING_LIST_NAME_WITH_ICON
    },
    dropDownIcon: {
        display: 'flex'
    }
}));

function GroupItem({ contextMenuId, group, active, isShowLastPopupCreate, onSelect, isScrolling }) {
    const classes = useStyles();
    const theme = useTheme();
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const closeConfirmDeleteModalHandler = () => {
        setOpenConfirmDeleteModal(false);
    };

    const openConfirmDeleteModalHandler = e => {
        handleClickAway();
        setOpenConfirmDeleteModal(true);
    };

    const isSystemGroup = React.useMemo(() => {
        return group?.systemGroup;
    }, [group]);

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `GROUP_CONTEXT_MENU_ID_${group.id}`;
    }, [group.id]);

    const handleClick = e => {
        stopPropagation(e);
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClickAway = () => {
        setIsShowMore(false);
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (isShowMore && isShowLastPopupCreate) {
            let $arrow = document.getElementById(`group_more_${group.id}`);
            if ($arrow) {
                $arrow.click();
                dispatch(groupActions.turnOffLastGroupPopup());
            }
        }
    }, [isShowMore, isShowLastPopupCreate, group.id, dispatch]);

    React.useEffect(() => {
        if (isShowLastPopupCreate) {
            setIsShowMore(true);
        }
    }, [isShowLastPopupCreate]);

    const changeGroupHandler = groupId => {
        onSelect({ id: groupId });
    };

    const handleAction = React.useCallback(
        group => {
            onSelect(group);
        },
        [onSelect]
    );

    React.useEffect(() => {
        if ((contextMenuId && contextMenuId !== CONTEXT_MENU_ID) || isScrolling) {
            handleClickAway();
        }
    }, [contextMenuId, CONTEXT_MENU_ID, isScrolling]);

    const handleOnContextClick = e => {
        if (isSystemGroup) return;
        dispatch(changeContext({ contextMenuId: CONTEXT_MENU_ID }));
        handleClick(e);
    };

    const handleDeleteGroup = e => {
        stopPropagation(e);
        sendManualTrack({
            type: `Delete Group`,
            customData: {
                groupId: group?.id
            }
        });
        dispatch(
            groupActions.deleteGroup({
                groupId: group.id,
                successCallback: firstGroup => {
                    history.push(`/company-settings/groups/${firstGroup?.id}`);
                },
                errorCallback: error => {}
            })
        );
        handleClickAway();
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDeleteHandler = e => {
        stopPropagation(e);
        openConfirmDeleteModalHandler();
    };

    const cancelHandler = e => {
        stopPropagation(e);
        closeConfirmDeleteModalHandler();
    };

    return (
        <Grid
            item
            onMouseOver={() => setIsShowMore(true)}
            onMouseLeave={() => setIsShowMore(false)}
            className={classes.root}
            onClick={() => changeGroupHandler(group.id)}
            onContextMenu={handleOnContextClick}
        >
            <Grid
                className={`${classes.listItem} ${active ? classes.active : ''}`}
                container
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item className={classes.avatar}>
                            {isSystemGroup && group?.type === GROUP_ADMIN_TYPES.ADMIN && (
                                <>
                                    {group?.logoUrl ? (
                                        <AvatarBase
                                            className={classes.avatar}
                                            size={`small`}
                                            src={getGroupAvatarUrl(group?.logoUrl)}
                                        />
                                    ) : (
                                        <AdminGroupAvatar size={32} />
                                    )}
                                </>
                            )}
                            {isSystemGroup && group?.type === GROUP_ADMIN_TYPES.NORMAL && (
                                <>
                                    {group?.logoUrl ? (
                                        <AvatarBase
                                            className={classes.avatar}
                                            size={`small`}
                                            src={getGroupAvatarUrl(group?.logoUrl)}
                                        />
                                    ) : (
                                        <TranslatorGroupAvatar size={32} />
                                    )}
                                </>
                            )}
                            {/* {isSystemGroup && group?.type === GROUP_ADMIN_TYPES.ADMIN && <AdminGroupAvatar size={32} />}
                            {isSystemGroup && group?.type === GROUP_ADMIN_TYPES.NORMAL && (
                                <TranslatorGroupAvatar size={32} />
                            )} */}
                            {!isSystemGroup && (
                                <>
                                    {' '}
                                    {group?.logoUrl ? (
                                        <AvatarBase size={`small`} src={getGroupAvatarUrl(group?.logoUrl)} />
                                    ) : (
                                        <AvatarBase size="small">{group.name.slice(0, 2)}</AvatarBase>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item className={classes.name}>
                            <Typography noWrap variant={active ? 'body1' : 'body2'}>
                                <OverflowTypography truncated={true} style={{ display: 'block' }}>
                                    {group.name}
                                </OverflowTypography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!isSystemGroup && (
                    <Grid id={`group_more_${group.id}`} item className={classes.dropDownIcon} onClick={handleClick}>
                        {(isShowMore || anchorEl) && <IconMoreActionsSVG color={theme.colors.steel} />}
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl}>
                    <GroupPopup
                        group={group}
                        handleClickAway={handleClickAway}
                        onAction={handleAction}
                        onDeleteGroup={onDeleteHandler}
                    />
                </PopperMenu>
            )}
            <ModalBase open={openConfirmDeleteModal} handleClose={cancelHandler}>
                <ConfirmDeleteGroupBox
                    groupName={group?.name}
                    handleCancel={cancelHandler}
                    handleAgreed={handleDeleteGroup}
                />
            </ModalBase>
        </Grid>
    );
}

export default React.memo(GroupItem);
