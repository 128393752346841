import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import ListItem from 'components/list/Item';
import InputText from 'components/inputs/InputText';
import SettingSVG from 'assets/images/svg/SettingSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import { isKbEnter } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import * as integrationActions from 'integrations/actions';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    pAll: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

function Popup({ source, onClickAway, onEdit, onDelete }) {
    const [name, setName] = React.useState(source?.name);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isUpdating, setIsUpdating] = React.useState(false);
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        onClickAway(e);
    });

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onEditHandler = React.useCallback(
        e => {
            stopPropagation(e);
            onEdit && onEdit();
        },
        [stopPropagation, onEdit]
    );

    const onDeleteHandler = React.useCallback(
        e => {
            stopPropagation(e);
            onDelete && onDelete();
        },
        [stopPropagation, onDelete]
    );

    const handleKeyDown = React.useCallback(
        e => {
            if (!isKbEnter(e)) return;
            setIsUpdating(true);
            dispatch(
                integrationActions.updateSource({
                    sId: source?.id,
                    source: {
                        name,
                        connector: source.connector,
                        configuration: {
                            ...source?.configuration,
                            spreadsheetId: source?.configuration.spreadsheetId
                        }
                    },
                    success: () => {
                        onClickAway && onClickAway();
                        setIsUpdating(false);
                    },
                    error: () => {
                        setIsUpdating(false);
                    }
                })
            );
        },
        [name, dispatch, source, onClickAway]
    );

    return (
        <Grid ref={rootRef} container direction="column">
            <Grid item>
                <InputText
                    className={classes.pAll}
                    autoFocus
                    name="name"
                    height={36}
                    placeholder={'e.g Google sheets #1'}
                    value={name}
                    onKeyDown={handleKeyDown}
                    onChange={e => setName(e?.target?.value)}
                    error={!name}
                    disabled={isUpdating}
                    style={{ background: theme.colors.ghostwhite }}
                    onClick={stopPropagation}
                />
            </Grid>
            <Grid item onClick={onEditHandler}>
                <ListItem icon={() => <SettingSVG />} name={t('edit_source')} />
            </Grid>
            <Grid item onClick={onDeleteHandler}>
                <ListItem
                    icon={() => <DeleteSVG color={theme.colors.snackBarError} />}
                    name={
                        <Typography variant="body2" style={{ color: theme.colors.snackBarError }}>
                            {t('delete_source')}
                        </Typography>
                    }
                />
            </Grid>
        </Grid>
    );
}

export default Popup;
