import * as types from './types';
import {
    getWorkspacesApi,
    createWorkspaceApi,
    deleteWorkspaceApi,
    updateWorkspaceApi,
    getWorkspaceDetailApi,
    getGroupsWorkspaceApi,
    getMembersWorkspaceApi,
    updateWorkspaceUserRoleApi,
    getWorkspaceRolesApi,
    getGroupsAndMemberOfWorkspacesApi,
    getSharedGroupsOfProject,
    unshareProjectApi,
    shareProjectApi,
    getWorkspacesByGraphqlApi,
    reorderProjectsApi,
    getSortCriteriaApi,
    updateSortCriteriaApi,
    getRegionList
} from 'services/workspaces';
import { ungrantToWorkspaceApi } from 'services/groups';
import { enqueueSnackbar } from 'notifier/actions';
import { requestData, receiveData } from '../api/actions';
import uniqBy from 'lodash/uniqBy';
import i18n from 'i18n';
import { getUserSettingsApi, setUserSettingApi } from 'services/auth';
import { USER_SETTINGS } from 'const';
import * as gridUIActions from 'gridUI/actions';
import { ROW_HEIGHT_OPTIONS } from 'const/gridUI';
import { getDatabasesActionSuccess } from 'databases/actions';

export function fetchWorkspaceRoles({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchWorkspaceRolesAction());
        try {
            const { workspaceRoles } = await getWorkspaceRolesApi();
            dispatch(_setWorkspaceRoles({ workspaceRoles }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchWorkspaceRolesActionFailed({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchWorkspaceRolesAction() {
    return {
        type: types.FETCH_WORKSPACE_ROLES
    };
}

function _fetchWorkspaceRolesActionFailed({ error }) {
    return {
        type: types.FETCH_WORKSPACE_ROLES_FAILED,
        payload: {
            error
        }
    };
}

export function changeUserWorkspaceRole({ userId, workspaceId, newRole, oldRole, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_changeUserWorkspaceAction({ role: newRole, userId }));
        const body = {
            roleId: newRole?.id,
            projectId: workspaceId,
            userId
        };
        try {
            await updateWorkspaceUserRoleApi({ companyId, body });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('snackbar_success_change_project_role'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_changeUserWorkspaceAction({ role: oldRole, userId }));
            errorCallback && errorCallback();
        }
    };
}

function _changeUserWorkspaceAction({ userId, role }) {
    return {
        type: types.CHANGE_USER_WORKSPACE_ROLE,
        payload: {
            role,
            userId
        }
    };
}

export function ungrantGroupOutOfWorkspace({ data, workspaceId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_ungrantGroupOutOfWorkspaceAction({ groupIds: data?.groupIds }));
        try {
            await ungrantToWorkspaceApi({ companyId, data, workspaceId });
            dispatch(_ungrantGroupOutOfWorkspaceActionSuccess({ groupIds: data?.groupIds }));

            dispatch(
                enqueueSnackbar({
                    message: i18n.t('snackbar_success_remove_group_from_project'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_ungrantGroupOutOfWorkspaceActionFailed({ groupIds: data?.groupIds }));
            errorCallback && errorCallback();
        }
    };
}

function _ungrantGroupOutOfWorkspaceAction({ groupIds }) {
    return {
        type: types.UNGRANT_GROUP_OUT_OF_WORKSPACE,
        payload: {
            groupIds
        }
    };
}

function _ungrantGroupOutOfWorkspaceActionSuccess({ groupIds }) {
    return {
        type: types.UNGRANT_GROUP_OUT_OF_WORKSPACE_SUCCESS,
        payload: {
            groupIds
        }
    };
}

function _ungrantGroupOutOfWorkspaceActionFailed({ groupIds }) {
    return {
        type: types.UNGRANT_GROUP_OUT_OF_WORKSPACE_FAILED,
        payload: {
            groupIds
        }
    };
}

export function fetchMembersOfWorkspace({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_fetchMemberOfWorkspaceAction());
        try {
            const { members, workspaceRoles } = await getMembersWorkspaceApi({ workspaceId });
            dispatch(_fetchMemberOfWorkspaceActionSuccess({ members }));
            dispatch(_setWorkspaceRoles({ workspaceRoles }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchMemberOfWorkspaceActionFailed({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchMemberOfWorkspaceAction() {
    return {
        type: types.FETCH_MEMBER_GROUPS
    };
}

function _fetchMemberOfWorkspaceActionSuccess({ members }) {
    return {
        type: types.FETCH_MEMBER_GROUPS_SUCCESS,
        payload: {
            members
        }
    };
}

function _fetchMemberOfWorkspaceActionFailed({ error }) {
    return {
        type: types.FETCH_MEMBER_GROUPS_FAILED,
        payload: {
            error
        }
    };
}

export function fetchGroupsAndMembersOfWorkspace({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch({ type: types.FETCH_WORKSPACE_GROUPS_AND_MEMBERS });
        try {
            const data = await getGroupsAndMemberOfWorkspacesApi({ workspaceId: Number(workspaceId) });
            const workspaceGroups = data?.workspace.groups;
            const workspaceRoles = data?.workspaceRoles;
            let allMembers = [];
            workspaceGroups.forEach(group => {
                if (group.members) {
                    allMembers = allMembers.concat(group.members);
                }
            });
            const members = uniqBy(allMembers, 'user.id');
            dispatch({
                type: types.FETCH_WORKSPACE_GROUPS_AND_MEMBERS_SUCCESS,
                payload: { workspaceGroups, members, workspaceRoles }
            });
            dispatch(changeFilterGroup);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch({ type: types.FETCH_WORKSPACE_GROUPS_AND_MEMBERS_FAILED, payload: { error: message } });
            errorCallback && errorCallback();
        }
    };
}

export function fetchGroupsOfWorkspace({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch) {
        dispatch(_fetchGroupsOfWorkspaceAction());
        try {
            const groups = await getGroupsWorkspaceApi({ workspaceId });
            dispatch(_fetchGroupsOfWorkspaceActionSuccess({ groups }));
            dispatch(changeFilterGroup);
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchGroupsOfWorkspaceActionFailed({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

export function _setWorkspaceRoles({ workspaceRoles }) {
    return {
        type: types.SET_WORKSPACE_ROLES,
        payload: {
            workspaceRoles
        }
    };
}

function _fetchGroupsOfWorkspaceAction() {
    return {
        type: types.FETCH_WORKSPACE_GROUPS
    };
}

function _fetchGroupsOfWorkspaceActionSuccess({ groups }) {
    return {
        type: types.FETCH_WORKSPACE_GROUPS_SUCCESS,
        payload: {
            groups
        }
    };
}

function _fetchGroupsOfWorkspaceActionFailed({ error }) {
    return {
        type: types.FETCH_WORKSPACE_GROUPS_SUCCESS,
        payload: {
            error
        }
    };
}

export function changeGroup({ groupId }) {
    return {
        type: types.CHANGE_GROUP,
        payload: {
            groupId
        }
    };
}

export function fetchWorkspacesByGraphql({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const workspaces = await getWorkspacesByGraphqlApi();
            dispatch(_fetchWorkspacesActionSuccess({ workspaces }));
            successCallback && successCallback(workspaces);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchWorkspaces({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_fetchWorkspacesAction());
        try {
            const workspaces = await getWorkspacesApi({ companyId });
            dispatch(_fetchWorkspacesActionSuccess({ workspaces }));
            successCallback && successCallback(workspaces);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchWorkspacesActionFailed({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

export function _fetchWorkspacesActionSuccess({ workspaces }) {
    return {
        type: types.FETCH_WORKSPACES_SUCCESS,
        payload: {
            workspaces
        }
    };
}

function _fetchWorkspacesActionFailed({ error }) {
    return {
        type: types.FETCH_WORKSPACES_FAILED,
        payload: {
            error
        }
    };
}

export function _invalidateWorkspaces() {
    return {
        type: types.INVALIDATE_WORKSPACES
    };
}

export function fetchWorkspaceDetail({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        dispatch(requestData(types.FETCH_WORKSPACE_DETAIL, workspaceId));
        try {
            const data = await getWorkspaceDetailApi({ workspaceId, companyId });

            dispatch(receiveData(types.FETCH_WORKSPACE_DETAIL_SUCCESS, data));

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(receiveData(types.FETCH_WORKSPACE_DETAIL_FAILED, { workspaceId, error: message }));
            errorCallback && errorCallback(message);
        }
    };
}

export function _fetchWorkspacesAction() {
    return {
        type: types.FETCH_WORKSPACES
    };
}

export function createWorkspace({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_createWorkspaceAction());
        try {
            const newWorkspace = await createWorkspaceApi({ companyId, body });
            dispatch(_createWorkspaceActionSuccess({ workspace: newWorkspace }));
            dispatch(turnOnLastWorkspacePopup({ workspaceId: newWorkspace.id }));

            successCallback && successCallback(newWorkspace);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_createWorkspaceActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createWorkspaceAction() {
    return {
        type: types.CREATE_WORKSPACE
    };
}

function _createWorkspaceActionSuccess({ workspace }) {
    return {
        type: types.CREATE_WORKSPACE_SUCCESS,
        payload: {
            workspace
        }
    };
}

function _createWorkspaceActionFailed() {
    return {
        type: types.CREATE_WORKSPACE_FAILED
    };
}

export function deleteWorkspace({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_deleteWorkspaceAction({ workspaceId }));
        try {
            await deleteWorkspaceApi({ companyId, workspaceId });
            dispatch(_deleteWorkspaceActionSuccess({ workspaceId }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('snackbar_success_delete_project'),
                    type: 'info'
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_deleteWorkspaceActionFailed({ workspaceId }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteWorkspaceAction({ workspaceId }) {
    return {
        type: types.DELETE_WORKSPACE,
        payload: {
            workspaceId
        }
    };
}

function _deleteWorkspaceActionFailed({ workspaceId }) {
    return {
        type: types.DELETE_WORKSPACE_FAILED,
        payload: {
            workspaceId
        }
    };
}

function _deleteWorkspaceActionSuccess({ workspaceId }) {
    return {
        type: types.DELETE_WORKSPACE_SUCCESS,
        payload: {
            workspaceId
        }
    };
}

export function updateWorkspace({ oldWorkspace, newWorkspace, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_updateWorkspaceAction({ workspace: newWorkspace }));
        try {
            await updateWorkspaceApi({ companyId, workspace: newWorkspace });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_updateWorkspaceActionFailed({ workspace: oldWorkspace }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateWorkspaceAction({ workspace }) {
    return {
        type: types.UPDATE_WORKSPACE,
        payload: {
            workspace
        }
    };
}

function _updateWorkspaceActionFailed({ workspace }) {
    return {
        type: types.UPDATE_WORKSPACE_FAILED,
        payload: {
            workspace
        }
    };
}

export function turnOnLastWorkspacePopup({ workspaceId }) {
    return {
        type: types.TURN_ON_SHOW_LAST_POP_UP_CREATE_WORKSPACE,
        payload: {
            workspaceId
        }
    };
}

export function turnOffLastWorkspacePopup() {
    return {
        type: types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_WORKSPACE
    };
}

export function clearWorkspaceInfo() {
    return {
        type: types.CLEAR_WORKSPACE_INFO
    };
}

export function changeFilterGroup({ groupId, members }) {
    return {
        type: types.CHANGE_FILTER_GROUP,
        payload: {
            groupId,
            members
        }
    };
}

export function changeSelectedGroupId({ groupId }) {
    return {
        type: types.CHANGE_SELECTED_GROUP_ID,
        payload: {
            groupId
        }
    };
}

export function fetchProjectGroups({ projectId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchProjectGroupsAction());
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const groups = await getSharedGroupsOfProject({ companyId, projectId });
            dispatch(_fetchProjectGroupsActionSuccess({ groups }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_fetchProjectGroupsActionFailed({ error: message }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchUserSettings({ dbId, viewId, workspaceId, gridId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            dispatch(_fetchUserSetting());
            const { grid, view } = await getUserSettingsApi({ dbId, viewId, workspaceId, gridId });
            const setting = {
                [workspaceId]: {
                    [dbId]: {
                        grids: {
                            [gridId]: grid
                        },
                        views: {
                            [viewId]: view
                        }
                    }
                }
            };

            const height = view?.value?.[USER_SETTINGS.VIEW.DEFAULT_ROW_HEIGHT] || ROW_HEIGHT_OPTIONS.COMFORTABLE;
            dispatch(gridUIActions.changeDefaultRowHeight({ height }));
            dispatch(_fetchUserSettingSuccess({ setting }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_fetchUserSettingFailed());
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _fetchProjectGroupsAction() {
    return {
        type: types.FETCH_PROJECT_GROUPS
    };
}

function _fetchProjectGroupsActionSuccess({ groups }) {
    return {
        type: types.FETCH_PROJECT_GROUPS_SUCCESS,
        payload: { groups }
    };
}

function _fetchProjectGroupsActionFailed({ error }) {
    return {
        type: types.FETCH_PROJECT_GROUPS_FAILED,
        payload: { error }
    };
}

export function shareProject({ projectId, groups, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const groupIds = groups.map(group => group.id);
        try {
            await shareProjectApi({ companyId, projectId, groupIds });
            dispatch(__shareProjectActionSuccess({ groups }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _fetchUserSetting() {
    return {
        type: types.FETCH_WORKSPACE_USER_SETTINGS
    };
}

export function _fetchUserSettingSuccess({ setting }) {
    return {
        type: types.FETCH_WORKSPACE_USER_SETTINGS_SUCCESS,
        payload: {
            setting
        }
    };
}

function __shareProjectActionSuccess({ groups }) {
    return {
        type: types.SHARE_PROJECT_SUCCESS,
        payload: { groups }
    };
}

export function unshareProject({ projectId, groupIds, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            await unshareProjectApi({ companyId, projectId, groupIds });
            dispatch(__unshareProjectActionSuccess({ groupIds }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _fetchUserSettingFailed() {
    return {
        type: types.FETCH_WORKSPACE_USER_SETTINGS_FAILED
    };
}

export function setUserSettings({
    value,
    resourceType,
    dbId,
    workspaceId,
    resourceId,
    successCallback,
    errorCallback
}) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_setUserSetting({ resourceId, resourceType, value, dbId, workspaceId }));

        try {
            await setUserSettingApi({ companyId, body: { value, resourceId, resourceType, dbId } });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function __unshareProjectActionSuccess({ groupIds }) {
    return {
        type: types.UNSHARE_PROJECT_SUCCESS,
        payload: { groupIds }
    };
}

function _setUserSetting({ resourceType, resourceId, value, dbId, workspaceId }) {
    return {
        type: types.SET_USER_SETTING,
        payload: {
            resourceType,
            resourceId,
            value,
            dbId,
            workspaceId
        }
    };
}

export function reorderProjects({ projectId, body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        dispatch(_fetchProjectGroupsAction());
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            await reorderProjectsApi({ companyId, projectId, body });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(_fetchProjectGroupsActionFailed({ error: message }));
        }
    };
}

export function setSortCriteria(payload) {
    return {
        type: types.SET_SORT_CRITERIA,
        payload
    };
}

export function getSortCriteria({ workspaceId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            const data = await getSortCriteriaApi({ workspaceId, companyId });
            dispatch(
                setSortCriteria({
                    workspaceId,
                    data
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function updateSortCriteria({ workspaceId, dbId, data, isRemoveNew, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, workspace, database } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        const { sortCriteria } = workspace;
        const oldWorkspaceCriteria = JSON.parse(JSON.stringify(sortCriteria[workspaceId]));

        let dbs = null;
        if (isRemoveNew) {
            const { list } = database;
            const dbIds = database?.workspaceDbs?.[workspaceId]?.dbIds || [];
            dbs = dbIds
                .filter(dbId => {
                    return list?.findIndex(db => db.id === dbId) !== -1;
                })
                ?.map(dbId => {
                    const found = list?.find(item => item.id === dbId);
                    return found;
                });
        }

        try {
            if (isRemoveNew) {
                dispatch(
                    getDatabasesActionSuccess({
                        workspaceId,
                        dbs: dbs.map(db => {
                            delete db.new;
                            return db;
                        })
                    })
                );
            }
            dispatch({
                type: types.UPDATE_SORT_CRITERIA,
                payload: { workspaceId, dbId, data }
            });
            await updateSortCriteriaApi({ companyId, workspaceId, dbId, body: data });
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            if (isRemoveNew) {
                dispatch(
                    getDatabasesActionSuccess({
                        workspaceId,
                        dbs
                    })
                );
            }
            dispatch(
                setSortCriteria({
                    workspaceId,
                    data: oldWorkspaceCriteria
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchRegions({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            const regionObject = await getRegionList();
            dispatch(_fetchRegionsActionSuccess(regionObject.regions || []));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function _fetchRegionsActionSuccess(regions) {
    return {
        type: types.FETCH_REGIONS_SUCCESS,
        payload: {
            regions
        }
    };
}
