import React from 'react';
import ButtonBase from 'components/button/Base';
import { useCurrentTutorial } from 'hooks/app';

function DependencyNotification({ onClose }) {
    const currentTutorial = useCurrentTutorial();
    return (
        !currentTutorial && (
            <div className="flex flex-col w-[350px] p-6 bg-[#6158BA] rounded">
                <h4 className="prose prose-lg text-white">Dependencies added</h4>
                <p className={'text-light mt-3.5'}>
                    Dependencies have been automatically added to your Grid to track changes between the source and
                    target languages.
                </p>
                <div className="flex mt-6 justify-end">
                    <ButtonBase width={70} onClick={onClose} variant="contained" color="primary">
                        Close
                    </ButtonBase>
                </div>
            </div>
        )
    );
}

export default React.memo(DependencyNotification);
