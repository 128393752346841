import React from 'react';
import IdentifySVG from 'assets/images/svg/IdentifySVG';
import Button from 'components/button/Base';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import useMediaQueries from 'hooks/useMediaQueries';
import { useTranslation } from 'react-i18next';
import ArrowBackSVG from 'assets/images/svg/ArrowBackSVG';
import ListSelect from 'components/list/Select';
import IdentifySelect from './IdentifySelect';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    btnSubmit: {
        width: 140,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        },

        [theme.breakpoints.down('xs')]: {
            width: 120
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    coverImg: {
        width: 549,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    identifyButton: {
        '&.MuiButton-root': {
            justifyContent: 'flex-start',
            fontWeight: 'normal'
        }
    }
}));

const jobs = [
    { label: 'Localization manager', value: 'Localization manager' },
    { label: 'Developer', value: 'Developer' },
    { label: 'Content creator', value: 'Content creator' },
    { label: 'Designer', value: 'Designer' },
    { label: 'Translator', value: 'Translator' },
    { label: 'LQA manager', value: 'LQA manager' },
    { label: 'Other', value: 'Other' }
];

function IdentifyForm({ initFormData, onBack, onSubmit, isSubmitting, onSubjectChange, onJobChange }) {
    const { isMobile } = useMediaQueries();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSubmit = () => {
        sendManualTrack({
            type: `Click On Sign Up Stage 3`,
            customData: {
                userFields: initFormData?.subjects
                    ?.filter(subject => subject?.isSelected)
                    ?.map(subject => subject?.value),
                userRole: initFormData?.job?.value
            }
        });
        onSubmit();
    };

    return (
        <Grid container justify="center" alignItems="center" wrap="nowrap">
            {!isMobile && (
                <Grid className={classes.coverImg} item sm={5} md={6} container justify="flex-end">
                    <IdentifySVG />
                </Grid>
            )}
            <Grid item sm={1} />
            <Grid item xs={12} sm={6} md={5}>
                <Grid container direction="column" spacing={3} wrap="nowrap">
                    <Grid item>
                        <Typography variant="h1">What will you use Gridly for?</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{t(`auth_identify_form_subtitle`)}</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            {initFormData?.subjects?.map(subject => (
                                <Grid xs={6} item key={subject.value}>
                                    <ListSelect
                                        onClick={() => onSubjectChange(subject)}
                                        isSelected={subject.isSelected}
                                        name={subject.label}
                                    />
                                    {/* <Button
                                        className={classes.identifyButton}
                                        onClick={() => handleSubjectChanged(subject)}
                                        fullWidth
                                        variant={initFormData?.subject === subject?.value ? 'contained' : 'outlined'}
                                    >
                                        {subject.label}
                                    </Button> */}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">What is your primary role?</Typography>
                    </Grid>
                    <Grid item>
                        <IdentifySelect jobs={jobs} selectedJob={initFormData.job} onChange={onJobChange} />
                    </Grid>
                    <Grid
                        item
                        style={{ marginTop: 8 }}
                        container
                        spacing={2}
                        alignItems="center"
                        direction="row"
                        wrap="nowrap"
                    >
                        <Grid item style={{ flex: 1 }} />
                        <Grid item onClick={onBack}>
                            <div className={classes.btnSubmit}>
                                <Button width={'100%'} type="submit" variant="outlined">
                                    <ArrowBackSVG style={{ marginRight: 8 }} />
                                    {t(`global_back`)}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.btnSubmit}>
                                <Button
                                    disabled={
                                        !initFormData?.subjects?.some(subject => subject.isSelected) ||
                                        !initFormData.job.value ||
                                        isSubmitting
                                    }
                                    width={'100%'}
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    {t(`global_finish`)}
                                </Button>
                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default IdentifyForm;
