import React from 'react';

function PreviewSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path d="M10.5 7.2V9.5L2.5 1.5H4.8V0H0V4.8H1.5V2.5L9.5 10.5H7.2V12H12V7.2H10.5Z" fill={color} />
        </svg>
    );
}

export default React.memo(PreviewSVG);
