import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import { useTranslation } from 'react-i18next';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import DialogActions from 'components/dialog/DialogActions';

const useStyles = makeStyles(theme => ({
    cancelButton: {
        '&.MuiButton-root': {
            background: theme.colors.snackBarWarning,
            '&:hover': {
                background: theme.colors.hoverWarningButton
            }
        }
    }
}));

function CancelPlan({ onClose, onConfirm, isSubmitting }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle title={t('global_cancel_plan')} onClose={onClose} />
            <DialogContent>
                <Typography variant="body2" display="inline">
                    Are you sure you want to cancel your
                </Typography>{' '}
                <Typography variant="body1" display="inline">
                    Pro
                </Typography>{' '}
                <Typography variant="body2" display="inline">
                    plan? If you cancel, you will return to the
                </Typography>{' '}
                <Typography variant="body1" display="inline">
                    Free
                </Typography>{' '}
                <Typography variant="body2" display="inline">
                    plan feature set with limited functionality at the end of your current billing cycle.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            Keep My Plan
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={isSubmitting}
                            variant="contained"
                            width={160}
                            className={classes.cancelButton}
                            onClick={onConfirm}
                        >
                            {isSubmitting ? <CircularProgress size={24} /> : 'Downgrade To Free'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(CancelPlan);
