import React from 'react';

function IconListSVG({ color = '#6E5BBE', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...other} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 2.25V4.5H4.5V2.25H18ZM2.25 10.1638V7.83621H0V10.1638H2.25ZM18 7.875V10.125H4.5V7.875H18ZM2.25 15.75V13.4224H0V15.75H2.25ZM18 15.75V13.5H4.5V15.75H18ZM2.25 4.57759V2.25H0V4.57759H2.25Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(IconListSVG);
