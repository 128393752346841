import React from 'react';
import UserAvatarListPreview from 'components/userAvatarListPreview/Base';
import { useActiveUsers } from 'hooks/gridUI';

const ManageUserAvatarListPreview = () => {
    const activeUsers = useActiveUsers();
    return <UserAvatarListPreview users={activeUsers} />;
};

export default React.memo(ManageUserAvatarListPreview);
