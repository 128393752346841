import produce from 'immer';

export function combinedRecordMetaData({ recordMetaData = {}, newRecordMetaData = {} }) {
    return produce(recordMetaData, draft => {
        const recordIds = Object.keys(newRecordMetaData);
        for (const recordId of recordIds) {
            if (!draft?.[recordId]?._metadata?.value) {
                draft[recordId] = {
                    _metadata: {
                        value: {}
                    }
                };
            }

            const updateRowMetaData = newRecordMetaData?.[recordId]?._metadata?.value;
            const columnIds = !updateRowMetaData ? [] : Object.keys(updateRowMetaData);

            if (!columnIds?.length) {
                draft[recordId]._metadata.value = {};
            }

            for (const columnId of columnIds) {
                const oldColumnMetaData = recordMetaData?.[recordId]?._metadata?.value?.[columnId];
                const newColumnMetaData = newRecordMetaData?.[recordId]?._metadata?.value?.[columnId];

                if (!newColumnMetaData) {
                    draft[recordId]._metadata.value[columnId] = {};
                } else {
                    draft[recordId]._metadata.value[columnId] = {
                        ...oldColumnMetaData,
                        ...newColumnMetaData
                    };
                }
            }
        }
        return draft;
    });
}

export function getRowMetadataValue({ recordMetaData, rowId }) {
    return recordMetaData?.[rowId]?._metadata?.value;
}

export function getRowHeightMetadata({ recordMetaData, rowId }) {
    return recordMetaData?.[rowId]?._metadata?.value?._rowHeight;
}

export function getCellMetadata({ recordMetaData, rowId, columnId }) {
    return recordMetaData?.[rowId]?._metadata?.value?.[columnId];
}

export function getCellCommentMetadata({ recordMetaData, rowId, columnId }) {
    return recordMetaData?.[rowId]?._metadata?.value?.[columnId]?.comment;
}

export function getCellFormatMetadata({ recordMetaData, rowId, columnId }) {
    return recordMetaData?.[rowId]?._metadata?.value?.[columnId]?.format;
}

export function generateRecordMetaDataByColor({ recordIds, columnIds, recordMetaData, color }) {
    const newRecordMetaData = {};
    const undoRecordMetaData = {};

    for (const recordId of recordIds) {
        const oldRecordMetadata = { ...recordMetaData?.[recordId] };
        newRecordMetaData[recordId] = {
            _metadata: {
                value: {}
            }
        };
        undoRecordMetaData[recordId] = oldRecordMetadata;

        for (const columnId of columnIds) {
            newRecordMetaData[recordId]['_metadata'].value = {
                ...newRecordMetaData?.[recordId]?.['_metadata']?.value,
                [columnId]: {
                    ...recordMetaData?.[recordId]?.['_metadata']?.value?.[columnId],
                    format: {
                        background: color
                    }
                }
            };
        }
    }

    const combinedMetaData = combinedRecordMetaData({ recordMetaData, newRecordMetaData });
    const serverRecordMetaData = recordIds?.map(recordId => {
        const recordMetaDataByRecordId = getRowMetadataValue({ recordMetaData: combinedMetaData, rowId: recordId });
        return [recordId, recordMetaDataByRecordId];
    });

    return {
        newRecordMetaData,
        undoRecordMetaData,
        serverRecordMetaData
    };
}
