import React from 'react';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useGetColumnSelectList } from 'hooks/gridUI/column';
import { useTranslation } from 'react-i18next';

function ColumnsSelect({
    defaultValue,
    handleOptionChange,
    dropdownClassName,
    ddPlaceholder,
    menuPlaceholder,
    showTooltip,
    ...rest
}) {
    const options = useGetColumnSelectList();
    const { t } = useTranslation();

    return (
        <LDBasePortal
            ddPlaceholder={ddPlaceholder || 'Select a column'}
            menuPlaceholder={menuPlaceholder || t('global_label_find_a_column')}
            options={options}
            onChange={handleOptionChange}
            defaultValue={defaultValue}
            dropdownClassName={dropdownClassName}
            isMulti={false}
            isUsingContainer={true}
            showTooltip={showTooltip}
            {...rest}
        />
    );
}

export default React.memo(ColumnsSelect);
