import { DEFAULT_AGGREGATION_TYPE, AGGREGATIONS_VALUES, AGGREGATIONS_DISABLED_COLUMNS, AGGREGATIONS } from 'const';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import { getDateTime } from 'utils/datetime';
import isNaN from 'lodash/isNaN';
import * as columnTypes from 'const/columnTypes';
import { getNumberDisplay } from 'utils/gridUI/cell';

export function getSupportedAggregationColumns({ metaData, viewColumns }) {
    return viewColumns
        .filter(viewCol => {
            const column = metaData?.[viewCol?.id];
            const type = getCorrectColumnType(column);
            return !AGGREGATIONS_DISABLED_COLUMNS?.includes(type) && viewCol?.viewable !== false;
        })
        ?.map(viewCol => viewCol?.id);
}

export function generateAggregationTypes({ columnIds, viewAggregates, aggregations }) {
    return columnIds?.map(columnId => {
        const viewAggType = viewAggregates?.find(agg => agg?.columnId === columnId)?.type;
        const currentAggregateType = aggregations?.[columnId]?.type;
        return currentAggregateType || viewAggType || DEFAULT_AGGREGATION_TYPE;
    });
}

export function generateAggregationTypesAtInitialState({ columnIds, viewAggregates }) {
    return columnIds?.map(columnId => {
        const viewAggType = viewAggregates?.find(agg => agg?.columnId === columnId)?.type;
        return viewAggType || DEFAULT_AGGREGATION_TYPE;
    });
}

export function convertCorrectValueByType({ type, value, column }) {
    switch (column?.type) {
        case columnTypes.NUMBER:
            return _convertCorrectNumberType({ type, value, column });
        default:
            return _getCorrectValueByType({ type, value });
    }
}

function _convertCorrectNumberType({ type, value, column }) {
    switch (type) {
        case AGGREGATIONS_VALUES.percentEmpty:
        case AGGREGATIONS_VALUES.percentFilled:
        case AGGREGATIONS_VALUES.percentUnique:
            return `${Number(Number(value) * 100).toFixed(2)}%`;
        case AGGREGATIONS_VALUES.empty:
        case AGGREGATIONS_VALUES.filled:
        case AGGREGATIONS_VALUES.unique:
            return isNaN(Number(value)) ? '' : Number(value).toLocaleString();

        default:
            return isNaN(Number(value)) ? '' : getNumberDisplay({ numberFormat: column?.numberFormat, data: value });
    }
}

function _getCorrectValueByType({ type, value }) {
    switch (type) {
        case AGGREGATIONS_VALUES.percentEmpty:
        case AGGREGATIONS_VALUES.percentFilled:
        case AGGREGATIONS_VALUES.percentUnique:
            return `${Number(Number(value) * 100).toFixed(2)}%`;
        case AGGREGATIONS_VALUES.earliest:
        case AGGREGATIONS_VALUES.latest:
            return getDateTime(value);
        default:
            return isNaN(Number(value)) ? '' : Number(value).toLocaleString();
    }
}

export function getNewAggregationTypeByColumnType({ oldType, columnType }) {
    const aggregationsByNewColumnType = AGGREGATIONS?.[columnType];

    return aggregationsByNewColumnType?.some(aggregate => aggregate?.value === oldType)
        ? oldType
        : aggregationsByNewColumnType?.[0]?.value;
}
