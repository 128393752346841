import React from 'react';

function AddColumnLeftSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.63593 2.52401L2.31797 0L0 2.52401L0.76684 3.35901L1.80497 2.22742L1.80521 5.43767H2.88968L2.88945 2.29237L3.86909 3.35901L4.63593 2.52401ZM16.001 1.58243C15.9505 1.27076 15.6567 1.03122 15.3032 1.03122H12.0676L11.9633 1.03824C11.6228 1.08448 11.3611 1.3535 11.3611 1.67793L11.3534 12.1309L11.3611 12.2262C11.4116 12.5373 11.7055 12.7776 12.0599 12.7776H15.2955L15.3996 12.7706C15.7393 12.7242 16.001 12.4544 16.001 12.1309L16.0087 1.67793L16.001 1.58243ZM3.9411 6.18858C4.2946 6.18858 4.58843 6.42811 4.63893 6.73979L4.6466 6.83529V15.158C4.6466 15.4815 4.3849 15.7513 4.04517 15.7977L3.9411 15.8047H0.705499C0.351999 15.8047 0.0581693 15.5643 0.00766715 15.2533L0 15.158V6.83529C0 6.51086 0.261694 6.24184 0.601422 6.1956L0.705499 6.18858H3.9411ZM3.2356 7.48303H1.411V14.5113H3.2356V7.48303ZM9.64432 1.03122C9.99782 1.03122 10.2917 1.27076 10.3422 1.58243L10.3498 1.67793L10.3422 12.1309C10.3422 12.4544 10.0805 12.7242 9.74073 12.7706L9.63666 12.7776H6.40106C6.04662 12.7776 5.75271 12.5373 5.7022 12.2262L5.69453 12.1309L5.7022 1.67793C5.7022 1.3535 5.96389 1.08448 6.30439 1.03824L6.40873 1.03122H9.64432ZM12.7721 2.32567H14.5967L14.589 11.4842H12.7644L12.7721 2.32567ZM8.93779 2.32567H7.11319L7.10553 11.4842H8.93013L8.93779 2.32567Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(AddColumnLeftSVG);
