import React from 'react';

function CheckedSVG({ color = '#7869B9', fillColor = '#7869B9', ...other }) {
    return (
        <svg {...other} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill={fillColor} />
            <path
                d="M11.2362 4.66645L10.5888 3.97509C10.5025 3.93188 10.4161 3.88867 10.3298 3.88867C10.2435 3.88867 10.1572 3.93188 10.0708 3.97509L6.18632 7.9504L4.37355 6.09238C4.28723 6.04917 4.20091 6.00596 4.11458 6.00596C4.02826 6.00596 3.94194 6.04917 3.89878 6.09238L3.2082 6.78373C3.07871 6.91336 3.07871 7.12941 3.2082 7.25904L5.92736 10.0245C6.01368 10.1109 6.1 10.1109 6.18632 10.1109C6.27265 10.1109 6.35897 10.0677 6.44529 10.0245L11.2362 5.14176C11.3657 5.01213 11.3657 4.79608 11.2362 4.66645Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(CheckedSVG);
