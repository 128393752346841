import {
    isKbShiftArrowLeft,
    isKbArrowLeft,
    isKbShiftArrowDown,
    isKbArrowDown,
    isKbShiftArrowUp,
    isKbArrowUp,
    isKbShiftArrowRight,
    isKbArrowRight,
    isKbShiftTab,
    isKbTab,
    isKbRefresh,
    isKbDelete,
    // isKbPageUp,
    // isKbPageDown,
    // isKbEnd,
    // isKbHome,
    // isKbPageStart,
    // isKbPageEnd,
    isKbCommandAndSlash
} from 'utils/keyboard';
import * as appActions from 'app/actions';
import * as gridUIActions from 'advancedSearch/actions';
import * as roleConst from 'auth/roleConst';
import { sendManualTrack } from 'tracker';
import now from 'performance-now';

let isNavigating = false;

export const rangeKeyEventsHandler = ({ dispatch, e, accessEditRecords }) => {
    // if (isKbPageStart(e)) {
    //     dispatch(gridUIActions.navigateFirstRow());
    //     return;
    // }

    // if (isKbPageEnd(e)) {
    //     dispatch(gridUIActions.navigateLastRow());
    //     return;
    // }

    if (isKbTab(e) || isKbArrowRight(e)) {
        if (isNavigating) return;
        isNavigating = true;
        const start = now();
        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToNextColumn({
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Cell to Right`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }
    if (isKbShiftTab(e) || isKbArrowLeft(e)) {
        if (isNavigating) return;
        isNavigating = true;
        const start = now();
        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToPreviousColumn({
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Cell to Left`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }
    if (isKbArrowDown(e)) {
        if (isNavigating) return;
        isNavigating = true;
        const start = now();
        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToNextRow({
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Down Cell`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }

    if (isKbArrowUp(e)) {
        if (isNavigating) return;
        isNavigating = true;
        const start = now();

        setTimeout(() => {
            dispatch(
                gridUIActions.moveCellToPreviousRow({
                    callback: () => {
                        isNavigating = false;
                        const end = now();
                        const time = (end - start).toFixed(3);
                        sendManualTrack({
                            type: `Scroll Up Cell`,
                            customData: {
                                render_time: time
                            }
                        });
                    }
                })
            );
        }, 0);
        return;
    }

    if (isKbDelete(e)) {
        if (accessEditRecords === roleConst.FULL) {
            dispatch(gridUIActions.deleteCells());
        }
        return;
    }

    if (isKbRefresh(e)) {
        window.location.reload();
        return;
    }

    if (isKbCommandAndSlash(e)) {
        dispatch(appActions.toggleKeyboardShortCut());
        return;
    }

    if (isKbShiftArrowRight(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftRight(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        //refactor with range selection by indexes

        return;
    }

    if (isKbShiftArrowLeft(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftLeft(() => {
                    isNavigating = false;
                })
            );
        }, 0);

        return;
    }

    if (isKbShiftArrowDown(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftDown(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    if (isKbShiftArrowUp(e)) {
        if (isNavigating) return;
        isNavigating = true;
        setTimeout(() => {
            dispatch(
                gridUIActions.navigateShiftUp(() => {
                    isNavigating = false;
                })
            );
        }, 0);
        return;
    }

    // if (isKbPageUp(e)) {
    //     dispatch(gridUIActions.navigatePageUp());
    //     return;
    // }

    // if (isKbPageDown(e)) {
    //     dispatch(gridUIActions.navigatePageDown());
    //     return;
    // }

    // if (isKbHome(e)) {
    //     dispatch(gridUIActions.navigateFirstColumn());
    //     return;
    // }

    // if (isKbEnd(e)) {
    //     dispatch(gridUIActions.navigateLastColumn());
    // }
};
