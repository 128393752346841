import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Grid, CircularProgress, Typography, Divider, IconButton } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import ErrorMessage from 'auth/ErrorMessage';
import Button from 'components/button/Base';
import LDSelectBase from 'components/selects/LDBase';
import BranchTreeSVG from 'assets/images/svg/versionControl/BranchTreeSVG';
import CheckoutBranch from './sharedComponents/CheckoutBranch';
import { useBranchesOptions } from 'hooks/versionControl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import * as branchActions from './actions/branch';
import { sendManualTrack } from 'tracker';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import CheckBox from 'components/checkbox/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 450,
        boxShadow: theme.shadows[1],
        paddingTop: theme.spacing(5),
        background: theme.colors.white,
        borderRadius: 4
    },
    content: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    spb4: {
        marginBottom: theme.spacing(4)
    },
    spb5: {
        marginBottom: theme.spacing(5)
    },
    footer: {
        padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`
    },
    divider: {
        paddingTop: theme.spacing(4)
    },
    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    dropdownClassName: {
        background: theme.colors.backgroundDisabled,
        pointerEvents: 'none'
    }
}));

const CreateBranchSchema = object().shape({
    branchName: string().required('This field is required')
});

function CreateBranchForm({ onClose, onBrandCreated }) {
    const classes = useStyles();
    const theme = useTheme();
    const { gridId, dbId, branchId } = useParams();
    const branchOptions = useBranchesOptions({ dbId, gridId });
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [inheritGroupAccess, setInheritGroupAccess] = React.useState(true);

    const formik = useFormik({
        initialValues: {
            branchName: ''
        },
        validationSchema: CreateBranchSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });
            sendManualTrack({
                type: `Create Branch`,
                customData: {
                    dbId,
                    gridId
                }
            });
            setSubmitting(true);
            dispatch(
                branchActions.createGridBranch({
                    dbId,
                    gridId,
                    parentGridId: fromBranch.id,
                    body: {
                        name: values.branchName,
                        inheritGroupAccess
                    },
                    successCallback: newBranch => {
                        // onClose && onClose();
                        onBrandCreated && onBrandCreated(newBranch);
                    },
                    errorCallback: () => {
                        console.log('failed to branch');
                        setSubmitting(false);
                    }
                })
            );
            setStatus({ error: null });
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, status, isValid } = formik;

    const defaultFromBranch = React.useMemo(() => {
        return branchOptions?.find(option => option?.id === branchId) || branchOptions?.[0];
    }, [branchOptions, branchId]);

    const [fromBranch, setFromBranch] = React.useState(defaultFromBranch);

    const handleOptionChange = option => {
        setFromBranch(option);
    };

    return (
        <>
            {status && status.error && <ErrorMessage message={status.error} />}
            <form onSubmit={handleSubmit} className={classes.root}>
                <Grid container direction="column" wrap="nowrap">
                    <Grid item>
                        <Grid container direction="column" wrap="nowrap" className={classes.content}>
                            <Grid item className={classes.spb5}>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h3">Create branch</Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton size="medium" onClick={onClose}>
                                            <CloseIconSVG size={20} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item className={classes.spb4}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography style={{ marginBottom: theme.spacing(1) }} variant="body1">
                                                    From branch
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <LDSelectBase
                                                    ddPlaceholder={'Select a branch'}
                                                    menuPlaceholder={'Find a branch'}
                                                    options={branchOptions}
                                                    onChange={handleOptionChange}
                                                    defaultValue={fromBranch}
                                                    dropdownClassName={classes.dropdownClassName}
                                                    isMulti={false}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.spb4}>
                                        <InputText
                                            label="Branch name"
                                            name="branchName"
                                            value={values.branchName}
                                            disabled={isSubmitting}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.branchName && touched.branchName}
                                            errorText={errors.branchName}
                                            placeholder="Branch name"
                                            type="text"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row">
                                            <Grid item style={{ marginRight: theme.spacing(3) }}>
                                                <BranchTreeSVG />
                                            </Grid>
                                            <Grid item>
                                                <CheckoutBranch
                                                    fromBranch={fromBranch?.label}
                                                    toBranch={values.branchName}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ position: 'relative', top: theme.spacing(2), left: -12 }}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBox
                                            checked={inheritGroupAccess}
                                            onChange={e => setInheritGroupAccess(!inheritGroupAccess)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">Include shared groups</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item className={classes.divider}>
                        <Divider />
                    </Grid>
                    <Grid item className={classes.footer}>
                        <Grid container justify="flex-end" spacing={2} direction="row" wrap="nowrap">
                            <Grid item>
                                <Button onClick={onClose} size="small" width={110} variant="outlined">
                                    {t('global_cancel')}
                                </Button>
                            </Grid>
                            <Grid item className={classes.btnSubmit}>
                                <Button
                                    disabled={!isValid || isSubmitting}
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    width={110}
                                >
                                    Save
                                </Button>
                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default CreateBranchForm;
