import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import AddWorkspaceSVG from 'assets/images/svg/AddWorkspaceSVG';
import { makeStyles } from '@material-ui/styles';
import { Handle } from 'react-flow-renderer';
import ButtonBase from 'components/button/Base';
import PopperMenu from 'components/menus/Popper';
import AutomationActionSelect from 'gridUI/automations/AutomationActionSelect';
import { useDispatch } from 'react-redux';
import { createAutomationNode, setAutomationDetail } from '../../action';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { generateAutomationActionNodeDefaultParams } from 'const/automation';
import { checkContainId } from 'utils/clickAway';

const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer'
    },
    add: {
        width: 20,
        height: 20
    },
    addStep: {
        width: 212,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        border: `1px solid #D7D7DC`
    },
    popperWrapper: {
        width: 680
    },
    paperClassName: {
        paddingBottom: 0
    }
}));

const AddNode = ({ type, data }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState();
    const addRef = useRef();
    const automationDetail = useGetAutomationDetail();

    const { parentId, setSelectedNode } = data;

    const handleOpen = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        e.persist();
        setAnchorEl(anchorEl => (anchorEl ? null : e.target));
    }, []);

    const handleClose = useCallback(e => {
        if (checkContainId(e, 'dialogActionSelectDisable')) return;
        setAnchorEl(null);
    }, []);

    const onCreateNode = useCallback(
        option => {
            dispatch(
                createAutomationNode({
                    automationId: automationDetail.id,
                    body: {
                        ...option
                    },
                    successCallback: responseData => {
                        const nodes = [...(automationDetail.nodes || [])];
                        if (parentId) {
                            const parentIdx = nodes.findIndex(node => node.id === parentId);
                            if (parentIdx > -1) {
                                nodes.splice(parentIdx + 1, 0, responseData);
                            }
                        } else {
                            nodes.unshift(responseData);
                        }
                        dispatch(
                            setAutomationDetail({
                                ...automationDetail,
                                nodes
                            })
                        );
                        let order = 0;
                        if (parentId) {
                            const parentNode = nodes.find(node => node.id === parentId);
                            if (parentNode) {
                                order = parentNode.order + 1;
                            }
                        } else {
                            order = automationDetail?.triggerNodes?.length + 1;
                        }
                        setSelectedNode({ ...responseData, order });
                    }
                })
            );
        },
        [dispatch, automationDetail, parentId, setSelectedNode]
    );

    const onChange = useCallback(
        option => {
            const defaultActionParams = generateAutomationActionNodeDefaultParams({ app: option.app });
            onCreateNode({
                ...option,
                parentId,
                params: defaultActionParams
            });
            handleClose();
        },
        [parentId, onCreateNode, handleClose]
    );

    return (
        <Grid
            item
            className={classnames(classes.root, {
                [classes.add]: type === 'add',
                [classes.addStep]: type !== 'add'
            })}
            ref={addRef}
        >
            {type === 'add' ? (
                <ButtonBase
                    variant="outlined"
                    style={{
                        border: 'none',
                        padding: 0,
                        minWidth: 'unset',
                        minHeight: 'unset',
                        width: 'auto',
                        height: 'auto',
                        background: 'transparent',
                        position: 'relative',
                        top: -1
                    }}
                    onClick={handleOpen}
                >
                    <AddWorkspaceSVG width="20" height="20" />
                </ButtonBase>
            ) : (
                <ButtonBase style={{ width: `100%` }} onClick={handleOpen}>
                    <p className="body1">+ Add Step</p>
                </ButtonBase>
            )}
            <Handle type="target" position="top" isConnectable={false} />
            {type === 'add' && <Handle type="source" position="bottom" isConnectable={false} />}
            {anchorEl && (
                <PopperMenu
                    handleClickAway={handleClose}
                    anchorEl={addRef.current}
                    container={document.getElementById('react-flow-wrapper')}
                    paperClassName={classes.paperClassName}
                >
                    <Grid item className={classes.popperWrapper}>
                        <AutomationActionSelect onChange={onChange} onClose={handleClose} />
                    </Grid>
                </PopperMenu>
            )}
        </Grid>
    );
};

export default React.memo(AddNode);
