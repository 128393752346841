import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import Search from 'components/search';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { SUBSCRIPTION_STATUS, WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { useRole } from 'hooks/auth/role';
import { AUTOMATION_APP_ACTION_DEFAULT } from './const';
import { DISABLED_OPACITY } from 'const/style';
import Tooltip from 'components/tooltip/Base';
import { getHookTypeLabel, getHookTypeSubtitle } from 'const/gridUI';
import { ADD_ON_NAME } from 'const/inAppAddon';
import * as roleConst from 'auth/roleConst';
import Dialog from 'components/dialog/Dialog';
import AutomationActionSelectDisable from './AutomationActionSelectDisable';
import { useAutomationAddons } from 'hooks/addons';

const useStyles = makeStyles(theme => ({
    root: { padding: '12px 0 0' },
    flex: { display: 'flex' },
    cursorPointer: { cursor: 'pointer' },
    searchWrapper: {
        flex: 1
    },
    closeIconWrapper: {},
    header: { padding: '0 20px' },
    content: {
        padding: '0 20px',
        marginTop: 12,
        maxHeight: 434,
        overflow: 'hidden auto'
    },
    label: {
        fontSize: 13,
        lineHeight: `15px`,
        color: theme.colors.midGrey
    },
    optionsWrapper: {
        margin: '10px 0 24px'
    },
    options: {
        width: `calc(100% + 10px) !important`,
        margin: `-5px !important`
    },
    option: {
        padding: '5px !important'
    },
    badge: {
        position: 'absolute',
        top: 5,
        right: -26,
        width: 77,
        height: 16,
        transform: 'rotate(45deg)',
        '&.hot': {
            background: theme.colors.brightRed
        },
        '&.group': {
            background: theme.colors.fuchsiaBlue
        }
    },
    badgeText: {
        fontSize: 10,
        fontWeight: 700,
        color: theme.colors.white
    },
    item: {
        overflow: 'hidden',
        position: 'relative',
        padding: '10px 10px 16px',
        // border: `1px solid ${theme.colors.silver}`,
        boxShadow: `0px 2px 10px rgba(0, 0, 0, 0.15)`,
        borderRadius: 4,
        height: '100%',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    itemIcon: {
        marginRight: 6
    },
    itemSubtitle: {
        marginTop: 4
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    fullHeight: {
        height: '100%'
    }
}));

const useStylesMini = makeStyles(theme => ({
    root: { padding: '12px 0' },
    content: {
        padding: 0,
        maxHeight: 300,
        overflow: 'hidden auto',
        cursor: 'initial',
        marginTop: 12
    },
    item: {
        padding: '0 20px',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
        '&:not(:first-child)': {
            marginTop: 20
        },
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    itemDisabled: {
        cursor: 'initial',
        '&:hover': {
            background: 'transparent'
        }
    },
    itemIcon: {
        marginRight: 14
    },
    labelWrapper: {
        '*': {
            lineHeight: 'unset !important'
        }
    }
}));

const Item = React.memo(({ isMini, opt, onClickOpt }) => {
    const classes = useStyles();
    const classesMini = useStylesMini();

    const handleClick = useCallback(() => {
        onClickOpt(opt);
    }, [onClickOpt, opt]);

    if (isMini) {
        return (
            <Tooltip key={opt.value} title={opt.tooltip || ''} placement="left">
                <Grid item className={classesMini.item}>
                    <Grid item onClick={handleClick}>
                        {(opt.isHot || opt.isGroup) && (
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classnames(classes.badge, {
                                    hot: opt.isHot,
                                    group: opt.isGroup
                                })}
                            >
                                <p className="font-bold body2 text-[10px] text-white">{opt.isHot ? 'Hot' : 'Group'}</p>
                            </Grid>
                        )}
                        <Grid item container wrap="nowrap" alignItems="center">
                            {opt.icon && (
                                <Grid item className={classesMini.itemIcon}>
                                    {opt.icon}
                                </Grid>
                            )}
                            <Grid item className={classesMini.labelWrapper}>
                                <Grid item>
                                    <p className="body2 font-bold">{opt.label}</p>
                                </Grid>
                                <Grid item>
                                    <p className="caption">{opt.subTitle}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Tooltip>
        );
    }

    return (
        <Grid key={opt.value} item xs={12} md={6} lg={4} className={classes.option}>
            <Tooltip title={opt.tooltip || ''}>
                <Grid item className={classes.fullHeight}>
                    <Grid item className={classes.item} onClick={handleClick}>
                        {(opt.isHot || opt.isGroup) && (
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classnames(classes.badge, {
                                    hot: opt.isHot,
                                    group: opt.isGroup
                                })}
                            >
                                <p className="font-bold body2 text-[10px] text-white">{opt.isHot ? 'Hot' : 'Group'}</p>
                            </Grid>
                        )}
                        <Grid item container wrap="nowrap" alignItems="flex-start">
                            {opt.icon && (
                                <Grid item className={classes.itemIcon}>
                                    {opt.icon}
                                </Grid>
                            )}
                            <Grid item>
                                <p className="body2 font-bold">{opt.label}</p>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.itemSubtitle}>
                            <p className="caption">{opt.subTitle}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Tooltip>
        </Grid>
    );
});

const AutomationActionSelect = ({ onChange, onClose, layout = 'normal' }) => {
    const classes = useStyles();
    const classesMini = useStylesMini();
    const theme = useTheme();
    const { t } = useTranslation();
    const roles = useRole();
    const automationsAddons = useAutomationAddons();

    const isMini = layout === 'mini';

    const automationsAddonsObj = useMemo(() => {
        return (automationsAddons || []).reduce((acc, cur) => {
            acc[cur.name] = cur;
            return acc;
        }, {});
    }, [automationsAddons]);

    const [search, setSearch] = useState();
    const [disabledOption, setDisabledOption] = useState();

    const isHavingBillingPersmission = useMemo(() => {
        return [
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING],
            roles[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON]
        ].includes(roleConst.FULL);
    }, [roles]);

    const checkAddonTypeDisable = useCallback(
        addonType => {
            return (
                !automationsAddonsObj[addonType] ||
                ![SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.TRIALING].includes(
                    automationsAddonsObj[addonType]?.subscription?.status
                )
            );
        },
        [automationsAddonsObj]
    );

    const INTERNAL_OPTIONS = useMemo(() => {
        const iconSize = isMini ? 'medium' : 'smaller';
        return [
            {
                label: 'Actions',
                options: [
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.HTTP,
                        icon: <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.HTTP} size={iconSize} />,
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.HTTP),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.HTTP),
                        // isHot: true,
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.HTTP,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.HTTP]
                        }
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA,
                        icon: <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA} size={iconSize} />,
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.LAMBDA]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.LAMBDA),
                        addonType: ADD_ON_NAME.LAMBDA
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.SLACK,
                        icon: <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.SLACK} size={iconSize} />,
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.SLACK),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.SLACK),
                        // isGroup: true,
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.SLACK,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.SLACK]
                        }
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.SET,
                        icon: <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.SET} size={iconSize} />,
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.SET),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.SET),
                        // isGroup: true,
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.SET,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.SET]
                        }
                    }
                ]
            },
            {
                label: 'Advanced actions',
                options: [
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE,
                        icon: (
                            <WebHookLabel
                                type={WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE}
                                size={iconSize}
                                border={isMini}
                            />
                        ),
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.GOOGLE_TRANSLATE),
                        addonType: ADD_ON_NAME.GOOGLE_TRANSLATE
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE,
                        icon: (
                            <WebHookLabel
                                type={WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE}
                                size={iconSize}
                                border={isMini}
                            />
                        ),
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.AMZ_TRANSLATE),
                        addonType: ADD_ON_NAME.AMZ_TRANSLATE
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH,
                        icon: (
                            <WebHookLabel
                                type={WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH}
                                size={iconSize}
                                border={isMini}
                            />
                        ),
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.AMZ_SPEECH_STANDARD),
                        addonType: ADD_ON_NAME.AMZ_SPEECH_STANDARD
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL,
                        icon: (
                            <WebHookLabel
                                type={WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL}
                                size={iconSize}
                                border={isMini}
                            />
                        ),
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL,
                            action:
                                AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.AMZ_SPEECH_NEURAL),
                        addonType: ADD_ON_NAME.AMZ_SPEECH_NEURAL
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND,
                        icon: (
                            <WebHookLabel
                                type={WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND}
                                size={iconSize}
                                border={isMini}
                            />
                        ),
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.AMZ_SENTIMENT),
                        addonType: ADD_ON_NAME.AMZ_SENTIMENT
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.JIRA,
                        icon: <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.JIRA} size={iconSize} border={isMini} />,
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.JIRA),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.JIRA),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.JIRA,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.JIRA]
                        }
                    },
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR,
                        icon: (
                            <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR} size={iconSize} border={isMini} />
                        ),
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR),
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR]
                        },
                        disabled: checkAddonTypeDisable(ADD_ON_NAME.GOOGLE_OCR),
                        addonType: ADD_ON_NAME.GOOGLE_OCR
                    }
                ]
            },
            {
                label: 'Logical blocks',
                options: [
                    {
                        value: WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION,
                        icon: <WebHookLabel type={WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION} size={iconSize} />,
                        label: getHookTypeLabel(WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION),
                        subTitle: getHookTypeSubtitle(WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION),
                        // isHot: true,
                        defaultCreate: {
                            app: WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION,
                            action: AUTOMATION_APP_ACTION_DEFAULT[WEB_HOOK_EXTERNAL_SYSTEMS.CONDITION]
                        }
                    }
                ]
            }
        ]
            .map(el => {
                if (search) {
                    el.options = el.options.filter(opt =>
                        opt.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    );
                }
                return el;
            })
            .filter(el => el.options.length > 0);
    }, [isMini, checkAddonTypeDisable, search]);

    const onSearch = useCallback(e => {
        setSearch(e.target.value);
    }, []);

    const onClickOpt = useCallback(
        opt => {
            if (opt.disabled) {
                setDisabledOption({
                    ...opt,
                    isHavingBillingPersmission
                });
                return;
            }
            onChange({
                value: opt.value,
                ...(opt.defaultCreate || {})
            });
        },
        [onChange, isHavingBillingPersmission]
    );

    const handleCloseDisableAutomationActionSelect = useCallback(() => {
        setDisabledOption();
    }, []);

    return (
        <Grid
            item
            className={classnames({
                [classesMini.root]: isMini,
                [classes.root]: !isMini
            })}
        >
            <Grid item container wrap="nowrap" alignItems="center" className={classes.header}>
                <Grid item className={classes.searchWrapper}>
                    <Search
                        autoFocus
                        background={theme.colors.paleGrey}
                        placeholder={t('global_find_an_action')}
                        onChange={onSearch}
                    />
                </Grid>
                {!isMini && onClose && (
                    <Grid item className={classnames(classes.flex, classes.closeIconWrapper)}>
                        <IconButton onClick={onClose}>
                            <CloseIconSVG className={classes.cursorPointer} />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            {isMini ? (
                <Grid item className={classesMini.content}>
                    {INTERNAL_OPTIONS.map(el =>
                        el.options.map(opt => <Item key={opt.value} opt={opt} isMini onClickOpt={onClickOpt} />)
                    )}
                </Grid>
            ) : (
                <Grid item container direction="column" wrap="nowrap" className={classes.content}>
                    {INTERNAL_OPTIONS.map(el => (
                        <Grid key={el.label} item>
                            <Grid item className={classes.label}>
                                {el.label}
                            </Grid>
                            <Grid item className={classes.optionsWrapper}>
                                <Grid container spacing={2} alignItems="stretch" className={classes.options}>
                                    {el.options.map(opt => (
                                        <Item key={opt.value} opt={opt} onClickOpt={onClickOpt} />
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Dialog
                id="dialogActionSelectDisable"
                maxWidth={false}
                onClose={handleCloseDisableAutomationActionSelect}
                open={Boolean(disabledOption)}
            >
                <AutomationActionSelectDisable
                    item={disabledOption}
                    isHavingBillingPersmission={isHavingBillingPersmission}
                    onClose={handleCloseDisableAutomationActionSelect}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(AutomationActionSelect);
