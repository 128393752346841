import React from 'react';
import { Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'components/spinner/Base';

const defaultStyle = {
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 22,
    borderWidth: 0,
    height: 20,
    minWidth: 64
};

const style = {
    success: {
        ...defaultStyle,
        color: '#FFFFFF',
        background: '#42B883'
    },
    failed: {
        ...defaultStyle,
        color: '#FFFFFF',
        background: `rgba(235, 79, 94, 0.8)`
    },
    pending: {
        ...defaultStyle,
        color: '#FFFFFF',
        background: `rgb(248, 173, 19)`
    }
};

const useStyles = ({ status }) =>
    makeStyles(theme => ({
        root: {
            ...(style[status] || defaultStyle),
            '& .MuiChip-label': {
                padding: `0 9px !important`
            }
        }
    }));

const ChipStatus = ({ label, status, ...rest }) => {
    const classes = useStyles({ status })();

    if (status === 'running') {
        return (
            <Grid container alignItems="center">
                <Grid
                    item
                    style={{ width: 16, height: 16, display: 'flex', justifyContent: 'center', marginRight: 10 }}
                >
                    <Spinner size={16} thick={3} rotateColor="#96C060" bgColor="#ECECEC" bgOpacity={1} />
                </Grid>
                <Grid item>
                    <Typography variant="body2">{label}</Typography>
                </Grid>
            </Grid>
        );
    }

    return <Chip className={classes.root} label={label} {...rest} />;
};

export default React.memo(ChipStatus);
