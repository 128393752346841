import React from 'react';
import Option from '../components/Option';

function SingleSelection({ isHideMarkIcon = false, data, options = [], customProperties = {}, state = 'update' }) {
    return (
        <Option
            data={data}
            state={state}
            isHideMarkIcon={isHideMarkIcon}
            options={options}
            customProperties={customProperties}
        />
    );
}

export default React.memo(SingleSelection);
