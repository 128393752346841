import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getBooleanState } from 'utils/gridUI/formatData';
import ThreeStatesCheckbox from 'components/checkbox/ThreeStateCheckBox';
import { getBooleanValue } from 'utils/gridUI/formatData';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function BooleanInput({ defaultValue, onChange, disabled }) {
    const classes = useStyles();

    const handleChange = (e, nextState) => {
        onChange(getBooleanValue(nextState));
    };

    return (
        <Grid className={classes.root} container alignItems="center" justify="center">
            <ThreeStatesCheckbox state={getBooleanState(defaultValue) || 0} onChange={handleChange} />
        </Grid>
    );
}

export default React.memo(BooleanInput);
