import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import DateTimeInput from 'components/datetime/Base';
import { Grid } from '@material-ui/core';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { useTranslation } from 'react-i18next';
import DateTimeSVG from 'components/svg-icon/DateTimeSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';

const DefaultCustomInput = ({ className, onClick, value, onFocus, onBlur, ...rest }) => {
    const inputRef = useRef();
    const [isFocused, setIsFocused] = useState(false);

    const handleOnClick = useCallback(() => {
        onClick();
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [onClick]);

    const handleFocus = useCallback(
        e => {
            onFocus(e);
            setIsFocused(true);
        },
        [onFocus]
    );

    const handleBlur = useCallback(
        e => {
            onBlur(e);
            setIsFocused(false);
        },
        [onBlur]
    );

    return (
        <div
            className={classnames(className, 'flex flex-nowrap gap-2 px-[15px] items-center cursor-pointer')}
            onClick={handleOnClick}
        >
            <div>
                <DateTimeSVG />
            </div>
            <div className="flex-1">
                <input
                    className="bg-inherit placeholder:text-grey-disabled"
                    ref={inputRef}
                    value={value?.trim()}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    {...rest}
                />
            </div>
            <div>
                {isFocused ? (
                    <ArrowUpSVG className="pointer-events-none" />
                ) : (
                    <ArrowDownSVG className="pointer-events-none" />
                )}
            </div>
        </div>
    );
};

function DateInput({ onChange, defaultValue, disabled, ...rest }) {
    const { t } = useTranslation();
    const [dateTime, setDateTime] = useState(defaultValue);
    const timer = useRef(0);

    const handleDateTimeChange = useCallback(
        date => {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(function() {
                onChange(date);
            }, DEBOUNCE_TIME_SEARCHING);
            setDateTime(date);
        },
        [onChange]
    );

    const handleOnClickOutSide = useCallback(() => {
        console.log('handleOnClickOutSide');
    }, []);

    const handleInputClick = useCallback(() => {}, []);

    return (
        <Grid className="h-9 w-full" id={'datepicker-nested'}>
            <DateTimeInput
                placeholder={t(`global_label_pick_a_date`)}
                selectedDate={dateTime}
                autoFocus={false}
                utcOffset={0}
                isShowTimeSelect={false}
                onEnter={handleDateTimeChange}
                onClickOutside={handleOnClickOutSide}
                onInputClick={handleInputClick}
                className={classnames(
                    `h-9 pl-2.5 text-text-primary rounded border border-grey-border focus:border-brand-main focus:outline-none focus-within:border-brand-main focus-within:outline-none`,
                    {
                        disabled: disabled
                    }
                )}
                customInput={<DefaultCustomInput />}
                {...rest}
            />
        </Grid>
    );
}

export default React.memo(DateInput);
