import React from 'react';

function DependencySVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/Dependency-Final">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M13.5999 2.66667L11.6443 0.711111C11.111 0.266667 10.4888 0 9.86654 0C9.24431 0 8.62209 0.266667 8.17765 0.711111L4.26654 4.62222C3.28876 5.51111 3.28876 7.11111 4.26654 8.08889L6.22209 10.0444C6.66654 10.4889 7.28876 10.7556 7.91098 10.7556C8.5332 10.7556 9.15543 10.4889 9.59987 10.0444L10.3999 9.24444L9.3332 8.17778L8.62209 8.88889C8.26654 9.24444 7.7332 9.24444 7.37765 8.88889L5.42209 6.93333C5.06654 6.57778 5.06654 6.04444 5.42209 5.68889L9.3332 1.77778C9.51098 1.6 9.68876 1.51111 9.95543 1.51111C10.2221 1.51111 10.3999 1.6 10.5776 1.77778L12.4443 3.82222C12.6221 4 12.711 4.17778 12.711 4.44444C12.711 4.71111 12.6221 4.88889 12.4443 5.06667L11.7332 5.77778L12.8888 6.93333L13.5999 6.22222C14.0443 5.77778 14.311 5.15556 14.311 4.53333C14.311 3.73333 14.0443 3.11111 13.5999 2.66667Z"
                        fill={color}
                    />
                    <path
                        id="Vector_2"
                        d="M10.5776 6.04442C10.1332 5.59998 9.59984 5.33331 8.88873 5.33331C8.17761 5.33331 7.64428 5.59998 7.19984 6.04442L6.6665 6.66665L7.64428 7.64442L8.2665 7.0222C8.44428 6.84442 8.71095 6.75554 8.88873 6.75554C9.0665 6.75554 9.33317 6.84442 9.51095 7.0222L11.4665 8.97776C11.8221 9.33331 11.8221 9.95554 11.4665 10.3111L7.64428 14.2222C7.4665 14.4 7.19984 14.4889 7.02206 14.4889C6.75539 14.4889 6.48873 14.4 6.39984 14.2222L4.44428 12.2666C4.2665 12.0889 4.17761 11.8222 4.17761 11.6444C4.17761 11.3778 4.2665 11.2 4.44428 11.0222L4.97761 10.4889L3.99984 9.51109L3.4665 10.0444C2.93317 10.4 2.6665 10.9333 2.6665 11.5555C2.6665 12.1778 2.93317 12.8 3.37761 13.2444L5.33317 15.2C5.77761 15.6444 6.39984 15.9111 7.02206 15.9111C7.64428 15.9111 8.2665 15.6444 8.71095 15.2L12.6221 11.2889C13.0665 10.8444 13.3332 10.2222 13.3332 9.59998C13.3332 8.97776 12.9776 8.44442 12.5332 7.99998L10.5776 6.04442Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(DependencySVG);
