import React from 'react';

function ColorStickerSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.08492 11.0339L13.79 3.31114C14.2041 2.89628 14.8753 2.8963 15.2892 3.31111C15.7036 3.72646 15.7035 4.39989 15.2892 4.81494L6.83466 13.2888C6.4205 13.7037 5.74958 13.7037 5.33542 13.2888L1.31073 9.25477C0.896378 8.8397 0.896448 8.16626 1.3108 7.75119C1.72469 7.33615 2.39579 7.33619 2.80968 7.75122L6.08492 11.0339Z"
                fill="#96C060"
            />
        </svg>
    );
}

export default React.memo(ColorStickerSVG);
