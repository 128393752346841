import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExportSVG from 'assets/images/svg/ExportSVG';
import Dialog from 'components/dialog/Dialog';
import Export from '../export';

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    }
}));

const MODAL_TYPES = {
    IMPORT: 1,
    EXPORT: 2
};

const ManageExport = ({ handleMoreActionsClickAway, isInIconMore }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [openModal, setOpenModal] = React.useState(null);

    const handleOpenExport = React.useCallback(() => {
        setOpenModal(MODAL_TYPES.EXPORT);
    }, []);

    return (
        <>
            <Grid item>
                <Button
                    tr-dt="Open Export Setting"
                    id="g-h-menu-item-export"
                    className={`${classes.button}`}
                    onClick={handleOpenExport}
                >
                    <ExportSVG height={18} width={18} color={theme.colors.darkness} />
                </Button>
            </Grid>
            <Dialog maxWidth={false} open={openModal === MODAL_TYPES.EXPORT} onClose={() => setOpenModal(false)}>
                <Export onClose={() => setOpenModal(false)} />
            </Dialog>
        </>
    );
};

export default React.memo(ManageExport);
