import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateTimeInput from 'components/datetime/Base';
import { Grid } from '@material-ui/core';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { INPUT_HEIGHT } from 'const/style';

const useStyles = makeStyles(theme => ({
    dateInput: {
        height: INPUT_HEIGHT,
        width: '100%'
    },
    datetime: {
        height: INPUT_HEIGHT,
        paddingLeft: 10,
        fontSize: `0.875rem !important`,
        color: theme.colors.primaryText,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border} !important`,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main} !important`,
            outline: 'none'
        }
    },
    disabled: {
        pointerEvents: 'none',
        background: theme.colors.paleGrey
    }
}));

function DateInput({ onChange, defaultValue, disabled, t, ...rest }) {
    const classes = useStyles();
    const [dateTime, setDateTime] = useState(defaultValue);
    const timer = useRef(0);

    const handleDateTimeChange = date => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(function() {
            onChange(date);
        }, DEBOUNCE_TIME_SEARCHING);
        setDateTime(date);
    };

    const handleOnClickOutSide = () => {
        console.log('handleOnClickOutSide');
    };

    const handleInputClick = () => {};

    return (
        <Grid className={classes.dateInput} id={'datepicker-nested'}>
            <DateTimeInput
                placeholder={t(`global_label_pick_a_date`)}
                selectedDate={dateTime}
                autoFocus={false}
                utcOffset={0}
                isShowTimeSelect={false}
                onEnter={handleDateTimeChange}
                onClickOutside={handleOnClickOutSide}
                onInputClick={handleInputClick}
                className={`${classes.datetime} ${disabled ? classes.disabled : ''}`}
                {...rest}
            />
        </Grid>
    );
}

export default React.memo(DateInput);
