import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';

const autoQAApis = {
    runQA: viewId => `${APP_API_PREFIX_GRID_UI}/api/v1/views/${viewId}/lqa`,
    runnedQAs: viewId => `${APP_API_PREFIX_GRID_UI}/api/v1/views/${viewId}/lqa`,
    qaStatistic: (viewId, sourceColumnId) => `${APP_API_PREFIX_GRID_UI}/api/v1/views/${viewId}/lqa/${sourceColumnId}`,
    ignoreError: viewId => `${APP_API_PREFIX_GRID_UI}/api/v1/views/${viewId}/lqa/ignore`,
    liveQA: viewId => `${APP_API_PREFIX_GRID_UI}/api/v1/views/${viewId}/lqa/live`,
    autoQASettings: () => `/lqa/v1/qa/settings`,
    autoQASettingDetail: id => `/lqa/v1/qa/settings/${id}`
};

export async function runQAApi({ viewId, body }) {
    const response = await request.post(`${autoQAApis.runQA(viewId)}`, body);
    return response.data;
}

export async function getRunnedQAsApi({ viewId }) {
    const response = await request.get(`${autoQAApis.runnedQAs(viewId)}`);
    return response.data;
}

export async function getQAStatisticsApi({ viewId, sourceColumnId, targetColumnIds }) {
    const response = await request.get(
        `${autoQAApis.qaStatistic(viewId, sourceColumnId)}?targetColumnIds=${targetColumnIds.join(',')}`
    );
    return response.data;
}

export async function ignoreErrorApi({ viewId, body }) {
    const response = await request.put(`${autoQAApis.ignoreError(viewId)}`, body);
    return response.data;
}

export async function ignoreSimilarErrorsApi({ viewId, body }) {
    const response = await request.put(`${autoQAApis.ignoreError(viewId)}`, { ...body, ignoreSimilar: true });
    return response.data;
}

export async function liveQAApi({ viewId, body }) {
    const response = await request.put(`${autoQAApis.liveQA(viewId)}`, body);
    return response.data;
}

export async function getAutoQASettingsApi() {
    const response = await request.get(autoQAApis.autoQASettings());
    return response.data;
}

export async function createAutoQASettingApi(body) {
    const response = await request.post(autoQAApis.autoQASettings(), body);
    return response.data;
}

export async function getAutoQASettingApi(settingId) {
    const response = await request.get(autoQAApis.autoQASettings(settingId));
    return response.data;
}

export async function updateAutoQASettingApi({ settingId, body }) {
    const response = await request.put(autoQAApis.autoQASettingDetail(settingId), body);
    return response.data;
}

export async function deleteAutoQASettingApi(settingId) {
    const response = await request.delete(autoQAApis.autoQASettingDetail(settingId));
    return response.data;
}
