import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 480,
        position: 'relative',
        borderRadius: 6,
        padding: theme.spacing(5)
    },
    closeButton: {
        position: 'absolute',
        right: 24,
        top: 24
    },
    buttonWrapper: {
        position: 'relative'
    },
    button: {
        '&.MuiButton-contained': {
            background: theme.colors.burntSlena
        },
        '&.MuiButton-contained:hover': {
            background: theme.colors.burntSlena
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function LambdaDelete({ lambdaName, onClose, onDeleteLambda }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle title="Delete Function" onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <p className="body2 inline">Are you sure you want to delete the function</p>{' '}
                        <p className="body1 inline">{lambdaName}</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={120} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <Button variant="contained" width={120} onClick={onDeleteLambda} color="secondary">
                            {t('global_delete')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(LambdaDelete);
