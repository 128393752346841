import React from 'react';

function GoogleSheetSVG({ color = '#28B446', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M17.2428 5.47793V18.7116C17.2428 19.4232 16.6656 20 15.9544 20H4.04597C3.33429 20 2.75757 19.4232 2.75757 18.7116V1.2884C2.75753 0.576719 3.33425 0 4.04597 0H11.7649L17.2428 5.47793Z"
                fill={color}
            />
            <path d="M12.512 5.35941L17.2425 7.30827V5.47843L14.5604 4.68652L12.512 5.35941Z" fill={color} />
            <path
                d="M17.2431 5.47793H13.0535C12.3418 5.47793 11.7651 4.90121 11.7651 4.18953V0L17.2431 5.47793Z"
                fill={color}
            />
            <path
                d="M13.1687 9.95898H6.83124C6.6039 9.95898 6.41968 10.1432 6.41968 10.3705V15.8849C6.41968 16.1122 6.6039 16.2965 6.83124 16.2965H13.1687C13.396 16.2965 13.5803 16.1122 13.5803 15.8849V10.3705C13.5803 10.1433 13.396 9.95898 13.1687 9.95898ZM7.2428 12.6049H9.58843V13.6507H7.2428V12.6049ZM10.4115 12.6049H12.7571V13.6507H10.4115V12.6049ZM12.7572 11.7818H10.4116V10.7821H12.7572V11.7818ZM9.58843 10.7821V11.7818H7.2428V10.7821H9.58843ZM7.2428 14.4738H9.58843V15.4735H7.2428V14.4738ZM10.4115 15.4735V14.4738H12.7571V15.4735H10.4115Z"
                fill={'white'}
            />
        </svg>
    );
}

export default React.memo(GoogleSheetSVG);
