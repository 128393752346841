import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string, ref } from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';

import { resetPasswordFinish } from './actions';
import ErrorMessage from './ErrorMessage';
import InputText from 'components/inputs/InputText';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import Button from 'components/button/Base';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import PasswordValidationPopup from 'components/PasswordValidationPopup';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: 470
    },
    input: {
        minHeight: 68
    },
    forgetLink: {
        textAlign: 'right'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const ResetPasswordSchema = object({
    newPassword: string()
        .min(4, i18n.t(`global_min_character`, { limit: 4 }))
        .required(i18n.t(`global_this_field_is_required`)),
    confirmPassword: string()
        .min(4, i18n.t(`global_min_character`, { limit: 4 }))
        .oneOf([ref('newPassword'), null], i18n.t(`global_password_must_match`))
        .required(i18n.t(`global_this_field_is_required`))
});

function ResetPasswordForm() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const key = query.get('key');
    const dispatch = useDispatch();
    const [isShowPassword, setIsShowPassword] = useState({
        newPassword: false,
        confirmPassword: false
    });

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: ResetPasswordSchema,
        onSubmit: ({ newPassword }, { setSubmitting, setStatus, resetForm }) => {
            setStatus({ error: null });
            setTimeout(() => setSubmitting(false), 3000);
            dispatch(
                resetPasswordFinish({
                    key,
                    newPassword,
                    errorCallback: message => {
                        setSubmitting(false);
                        resetForm();
                        setStatus({ error: message });
                    },
                    successCallback: () => {
                        setSubmitting(false);
                        history.push('/reset/success');
                    }
                })
            );
            setStatus({ error: null });
        }
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, status } = formik;

    const passwordValue = values.newPassword;
    const hasValidLength = passwordValue.length >= 12 && passwordValue.length <= 64;
    const hasAtLeastOneUpperCase = /^(?=.*[A-Z])/.test(passwordValue);
    const hasAtLeastOneLowerCase = /^(?=.*[a-z])/.test(passwordValue);
    const hasAtLeastOneDigit = /^(?=.*[0-9])/.test(passwordValue);
    const hasAtleastOneSpecialChar = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(passwordValue);
    const isValidPassword =
        hasValidLength &&
        hasAtLeastOneUpperCase &&
        hasAtLeastOneLowerCase &&
        hasAtLeastOneDigit &&
        hasAtleastOneSpecialChar;

    function handleClickShowPassword(fieldName) {
        setIsShowPassword(prev => ({
            ...prev,
            [fieldName]: !prev[fieldName]
        }));
    }

    return (
        <>
            {status?.error && <ErrorMessage message={status.error} />}
            <form id="reset-pass-form" onSubmit={handleSubmit} className={classes.form}>
                <Grid container direction="column" spacing={2}>
                    <Grid item className={classes.input}>
                        <InputText
                            autoComplete="new-password"
                            name="newPassword"
                            value={values.newPassword}
                            height={40}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.newPassword && touched.newPassword}
                            errorText={errors.newPassword}
                            placeholder={t(`auth_change_password_new_password`)}
                            autoFocus={true}
                            data-openreplay-obscured
                            type={isShowPassword.newPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleClickShowPassword('newPassword')} edge="end">
                                        {isShowPassword.newPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item className={classes.input}>
                        <InputText
                            autoComplete="new-password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            height={40}
                            disabled={isSubmitting}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.confirmPassword && touched.confirmPassword}
                            errorText={errors.confirmPassword}
                            placeholder={t(`auth_change_password_confirm_password`)}
                            data-openreplay-obscured
                            autoFocus={values.username ? true : false}
                            type={isShowPassword.confirmPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleClickShowPassword('confirmPassword')} edge="end">
                                        {isShowPassword.confirmPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item style={{ marginBottom: 14 }}>
                        <PasswordValidationPopup
                            hasValidLength={hasValidLength}
                            hasAtLeastOneUpperCase={hasAtLeastOneUpperCase}
                            hasAtLeastOneLowerCase={hasAtLeastOneLowerCase}
                            hasAtLeastOneDigit={hasAtLeastOneDigit}
                            hasAtleastOneSpecialChar={hasAtleastOneSpecialChar}
                            isValidPassword={isValidPassword}
                            passwordValue={passwordValue}
                        />
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting || !isValid || !isValidPassword}
                                >
                                    {t(`auth_reset_my_password`)}
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default ResetPasswordForm;
