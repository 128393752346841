import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import ListItem from 'components/list/Item';

const useStyles = makeStyles(theme => ({
    root: {}
}));

/*
 ** OPTION = { name, icon, onClick}
 */

function MenuOptionBase({ options, handleClickAway }) {
    const classes = useStyles();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    return (
        <Grid ref={rootRef} container className={classes.root} direction="column">
            {options.map(({ name, icon, onClick, ...rest }, idx) => (
                <Grid key={idx} item>
                    <ListItem onClick={onClick} icon={icon} name={name} {...rest} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(MenuOptionBase);
