import React from 'react';

function LQASVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M7.2866 10.8519H8.71299V12.2783H7.2866V10.8519ZM7.2866 5.14635H8.71299V9.4255H7.2866V5.14635ZM12.9921 2.29357H10.011C9.71146 1.46627 8.92694 0.867188 7.99979 0.867188C7.07264 0.867188 6.28813 1.46627 5.98859 2.29357H3.00744C2.90759 2.29357 2.81488 2.30071 2.72216 2.3221C2.44402 2.37916 2.1944 2.5218 2.00184 2.71436C1.87346 2.84273 1.76648 2.99963 1.69517 3.1708C1.62385 3.33484 1.58105 3.52027 1.58105 3.71996V13.7047C1.58105 13.8972 1.62385 14.0898 1.69517 14.261C1.76648 14.4321 1.87346 14.5819 2.00184 14.7174C2.1944 14.91 2.44402 15.0526 2.72216 15.1097C2.81488 15.1239 2.90759 15.1311 3.00744 15.1311H12.9921C13.7767 15.1311 14.4185 14.4892 14.4185 13.7047V3.71996C14.4185 2.93545 13.7767 2.29357 12.9921 2.29357ZM7.99979 2.11528C8.2922 2.11528 8.53469 2.35776 8.53469 2.65017C8.53469 2.94258 8.2922 3.18507 7.99979 3.18507C7.70738 3.18507 7.4649 2.94258 7.4649 2.65017C7.4649 2.35776 7.70738 2.11528 7.99979 2.11528ZM12.9921 13.7047H3.00744V3.71996H12.9921V13.7047Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(LQASVG);
