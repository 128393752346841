import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { isLDEmpty } from 'utils/object';
import * as columnTypes from 'const/columnTypes';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import MultipleSelectionDelete from '../../diffCheckComponents/delete/MultipleSelection';
import DatetimeDelete from '../../diffCheckComponents/delete/Datetime';
import ReferenceDelete from '../../diffCheckComponents/delete/Reference';
import FileDelete from '../../diffCheckComponents/delete/File';
import Checkbox from '../../diffCheckComponents/components/Checkbox';
import TextDelete from '../../diffCheckComponents/delete/TextDelete';
import Option from '../../diffCheckComponents/components/Option';

function PreviousColumn({ previousData, previousColumnType, options, customProperties, isHideMarkIcon, ...rest }) {
    switch (previousColumnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.NUMBER:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.TRANSLATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.ALTERED_BY:
        case columnTypes.RECORD_ID:
            return <TextDelete previousData={previousData} {...rest} />;

        case columnTypes.MULTIPLE_SELECTIONS:
            return (
                <MultipleSelectionDelete
                    previousData={previousData}
                    options={options}
                    customProperties={customProperties}
                    isHideMarkIcon={isHideMarkIcon}
                    {...rest}
                />
            );
        case columnTypes.DATETIME:
            return <DatetimeDelete previousData={previousData} {...rest} />;
        case columnTypes.REFERENCE:
            return (
                <ReferenceDelete
                    isHideMarkIcon={isHideMarkIcon}
                    previousData={previousData}
                    options={options}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.BOOLEAN:
            return <Checkbox isHideMarkIcon={isHideMarkIcon} data={previousData} state={'delete'} {...rest} />;
        case columnTypes.FILES:
            return <FileDelete previousData={previousData} isHideMarkIcon={isHideMarkIcon} {...rest} />;

        default:
            return null;
    }
}

function DiffSingleSelectionWithOtherTypes({
    previousData,
    data,
    options,
    customProperties,
    previousColumnType,
    isHideMarkIcon,
    ...rest
}) {
    const theme = useTheme();

    return (
        <div className="w-full inline-flex">
            {!isLDEmpty(previousData) && (
                <div className="inline">
                    <PreviousColumn
                        options={options}
                        customProperties={customProperties}
                        previousData={previousData}
                        previousColumnType={previousColumnType}
                        isHideMarkIcon={isHideMarkIcon}
                        {...rest}
                    />
                </div>
            )}
            {!isLDEmpty(previousData) && !isLDEmpty(data) && (
                <div className="inline relative top-2 px-2">
                    <ArrowNextSVG color={theme.colors.steel} />
                </div>
            )}
            {!isLDEmpty(data) && (
                <div className="inline">
                    <Option
                        isHideMarkIcon={isHideMarkIcon}
                        state={'update'}
                        customProperties={customProperties}
                        options={options}
                        data={data}
                        {...rest}
                    />
                </div>
            )}
        </div>
    );
}

export default React.memo(DiffSingleSelectionWithOtherTypes);
