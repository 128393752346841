export const GET_MORE_ROWS = '[GRID_UI]GET_MORE_ROWS';

/**
 * TABLE BUILT-IN FUNCTIONS
 */

export const SAVE_TABLE_INFO = '[TABLE]SAVE_TABLE_INFO';

/**
 * TODO: GRID_UI
 */
export const RENDER_GRID_UI_TABLE_AFTER_NETWORK_CONNECTED = '[GRID_UI]RENDER_GRID_UI_TABLE_AFTER_NETWORK_CONNECTED';
export const RENDER_GRID_UI_TABLE = '[GRID_UI]RENDER_GRID_UI_TABLE';
export const RESET_GRID_LOADING_STATE = '[GRID_UI]RESET_GRID_LOADING_STATE';
export const RENDER_GRID_UI_TABLE_FAILED = '[GRID_UI]RENDER_GRID_UI_TABLE_FAILED';
export const RENDER_GRID_UI_TABLE_SUCCESS = '[GRID_UI]RENDER_GRID_UI_TABLE_SUCCESS';
export const RESET_ROW_DATA = '[GRID_UI]RESET_ROW_DATA';
export const CLEAR_TABLE_DATA = '[GRID_UI]CLEAR_TABLE_DATA';
export const SET_DEFAULT_ROUTER_PARAMS = '[GRID_UI]SET_DEFAULT_ROUTER_PARAMS';
export const TRIGGER_RECOMPUTED_GRID = '[GRID_UI]TRIGGER_RECOMPUTED_GRID';
export const RESET_TRIGGER_RECOMPUTED_GRID = '[GRID_UI]RESET_TRIGGER_RECOMPUTED_GRID';
export const FETCH_GRID_LIST_SUCCESS = '[GRID_UI]FETCH_GRID_LIST_SUCCESS';
export const OPEN_NOTI_DEPENDENCY = '[GRID]OPEN_NOTI_DEPENDENCY';
export const CLOSE_NOTI_DEPENDENCY = '[GRID]CLOSE_NOTI_DEPENDENCY';
export const SET_OPEN_RIGHT_SIDE_BAR = '[GRID_UI]SET_OPEN_RIGHT_SIDE_BAR';

/**
 * TODO: AGGREGATION
 */
export const AGGREGATION_CHANGE_TYPE = '[AGGREGATION]AGGREGATION_CHANGE_TYPE';
export const REGISTER_AGGREGATION = '[AGGREGATION]REGISTER_AGGREGATION';

/**
 * TODO: HEADER
 */

export const HEADER_CLICK = '[HEADER]HEADER_CLICK';

/**
 * TODO: CELL
 */

export const CELL_CLICK = '[CELL]CELL_CLICK';
export const OPEN_CELL_EDIT = '[CELL]OPEN_CELL_EDIT';
export const CHANGE_BOOLEAN_STATUS = '[CELL]CHANGE_BOOLEAN_STATUS';
export const CELL_CLICK_AWAY = '[CELL]CELL_CLICK_AWAY';
export const CELL_CLICK_AWAY_AND_STAY = '[CELL]CELL_CLICK_AWAY_AND_STAY';
export const UPDATE_METADATA_VALIDATIONS = '[CELL]UPDATE_METADATA_VALIDATIONS';
export const CANCEL_CELL_EDIT = '[CELL]CANCEL_CELL_EDIT';
export const DELETE_CELL_INFO = '[CELL]DELETE_CELL_INFO';
export const DELETE_CELL_INFO_SUCCESS = '[CELL]DELETE_CELL_INFO_SUCCESS';
export const DELETE_CELL_INFO_FAILED = '[CELL]DELETE_CELL_INFO_FAILED';
export const UPDATE_CELL_INFO = '[CELL]UPDATE_CELL_INFO';
export const UPDATE_CELL_INFO_SUCCESS = '[CELL]UPDATE_CELL_INFO_SUCCESS';
export const UPDATE_CELL_INFO_FAILED = '[CELL]UPDATE_CELL_INFO_FAILED';
export const RESET_CELL_STATUS = '[CELL]RESET_CELL_STATUS';
export const DELETE_FILE_OF_CELL = '[CELL]DELETE_FILE_OF_CELL';
export const CREATE_UPDATE_RECORD_AND_CELL = '[CELL]CREATE_UPDATE_RECORD_AND_CELL';
export const RESET_CELL_INFO = '[CELL]RESET_CELL_INFO';
export const UNDO_UPDATE_CELL = '[UNDO_CELL]UNDO_UPDATE_CELL';
export const UPDATE_CELL_VALUE_ONLY = '[UNDO_CELL]UPDATE_CELL_VALUE_ONLY';
export const UPDATE_CELL_VALUE_WITHOUT_CELL_INFO = '[CELL]UPDATE_CELL_VALUE_WITHOUT_CELL_INFO';

export const RESET_ROW_COLUMN_SELECTION = '[RANGE]RESET_ROW_COLUMN_SELECTION';
export const RESET_SELECTION = '[CELL]RESET_SELECTION';
export const SET_CHARACTER_KEY = '[CELL]SET_CHARACTER_KEY';
export const OPEN_CELL_CONTEXT = '[CELL]OPEN_CELL_CONTEXT';
export const CLOSE_CELL_CONTEXT = '[CELL]CLOSE_CELL_CONTEXT';
export const IGNORE_AUTOQA_SINGLE_ERROR = '[CELL]IGNORE_AUTOQA_SINGLE_ERROR';
export const IGNORE_AUTOQA_SIMILAR_ERRORS = '[CELL]IGNORE_AUTOQA_SIMILAR_ERRORS';

/**
 * RANGE SELECTION
 */
export const ENABLE_SELECTING = '[CELL]ENABLE_SELECTING';
export const STOPPING_CELL_SELECTION = '[CELL]STOPPING_CELL_SELECTION';
export const SELECTING_CELL_SELECTION = '[CELL]SELECTING_CELL_SELECTION';
export const DELETE_RANGE_SELECTION_INFO = '[CELL]DELETE_RANGE_SELECTION_INFO';
export const INCREASE_SELECTION_RIGHT = '[CELL]INCREASE_SELECTION_RIGHT';
export const INCREASE_SELECTION_LEFT = '[CELL]INCREASE_SELECTION_LEFT';
export const INCREASE_SELECTION_TOP = '[CELL]INCREASE_SELECTION_TOP';
export const INCREASE_SELECTION_BOTTOM = '[CELL]INCREASE_SELECTION_BOTTOM';

export const STARTING_CELL_COPY_SELECTION = `[CELL]STARTING_CELL_COPY_SELECTION`;
export const STOPPING_CELL_COPY_SELECTION = `[CELL]STOPPING_CELL_COPY_SELECTION`;

/**
 * ROW
 */
export const ROW_SELECTION = '[ROW]ROW_SELECTION';
export const FETCH_MORE_ROWS = '[ROW]FETCH_MORE_ROWS';
export const FETCH_MORE_ROWS_SUCCESS = '[ROW]FETCH_MORE_ROWS_SUCCESS';
export const FETCH_MORE_ROWS_FAILED = '[ROW]FETCH_MORE_ROWS_FAILED';
export const CREATE_ROW = '[ROW]CREATE_ROW';
export const CREATE_ROW_SUCCESS = '[ROW]CREATE_ROW_SUCCESS';
export const CREATE_ROW_FAILED = '[ROW]CREATE_ROW_FAILED';
export const FETCH_RECORDS_WITH_FILTERS_SORTS = '[ROW]FETCH_RECORDS_WITH_FILTERS_SORTS';
export const FETCH_RECORDS_WITH_FILTERS_SORTS_FAILED = '[ROW]FETCH_RECORDS_WITH_FILTERS_SORTS_FAILED';
export const FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS = '[ROW]FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS';
export const SCROLL_TOP = '[ROW]SCROLL_TOP';
export const DELETE_ROWS = '[ROW]DELETE_ROWS';
export const DELETE_ROWS_SUCCESS = '[ROW]DELETE_ROWS_SUCCESS';
export const DELETE_ROWS_FAILED = '[ROW]DELETE_ROWS_FAILED';
export const UPDATE_LAST_SCROLL_POSITION = '[ROW]UPDATE_LAST_SCROLL_POSITION';
export const UPDATE_RECORD_METADATA = '[ROW]UPDATE_RECORD_METADATA';
export const DELETE_ROWS_SELECTION_INFO = '[ROW]DELETE_ROWS_SELECTION_INFO';
export const REORDER_RECORDS = '[ROW]REORDER_RECORDS';
export const POPULATE_SYSTEM_DATA_AFTER_RECORD_MODIFIED = '[ROW]POPULATE_SYSTEM_DATA_AFTER_RECORD_MODIFIED';
export const CREATE_ROWS_SOCKET = '[ROW]CREATE_ROWS_SOCKET';
export const CHANGE_DEFAULT_ROW_HEIGHT = '[ROW]CHANGE_DEFAULT_ROW_HEIGHT';

export const CREATE_RELATIVE_RECORDS = '[ROW]CREATE_RELATIVE_RECORDS';
export const CREATE_RELATIVE_RECORDS_FAILED = '[ROW]CREATE_RELATIVE_RECORDS_FAILED';
export const CREATE_RELATIVE_RECORDS_SUCCESS = '[ROW]CREATE_RELATIVE_RECORDS_SUCCESS';

export const DELETE_ALL_RECORDS = `[ROW]DELETE_ALL_RECORDS`;
/**
 * COLUMN
 */
export const CREATE_GRID_COLUMN = '[COLUMN]CREATE_GRID_COLUMN';
export const CREATE_GRID_COLUMN_SUCCESS = '[COLUMN]CREATE_GRID_COLUMN_SUCCESS';
export const CREATE_GRID_COLUMN_AND_FINISH_SCROLL = '[COLUMN]CREATE_GRID_COLUMN_AND_FINISH_SCROLL';
export const CREATE_GRID_COLUMN_FAILED = '[COLUMN]CREATE_GRID_COLUMN_FAILED';
export const GET_GRID_COLUMNS = '[COLUMN]GET_GRID_COLUMNS';
export const QUICK_FILTER_INPUT_CHANGE = '[COLUMN]QUICK_FILTER_CHANGE';
export const RESET_QUICK_FILTER_VALUE = '[COLUMN]RESET_QUICK_FILTER_VALUE';
export const QUICK_FILTER_EXTRA_CHANGE = '[COLUMN]QUICK_FILTER_EXTRA_CHANGE';
export const QUICK_FILTER_REGEX_CHANGE = '[COLUMN]QUICK_FILTER_REGEX_CHANGE';
export const CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN =
    '[COLUMN]CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN';
export const QUICK_SORT_CHANGE = '[COLUMN]QUICK_SORT_CHANGE';
export const CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN =
    '[COLUMN]CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN';
export const COLUMN_MENU_CLICK_DELETE = '[COLUMN]COLUMN_MENU_CLICK_DELETE';
export const COLUMN_MENU_CLICK_AWAY_FORMAT_COLUMN = '[COLUMN]COLUMN_MENU_CLICK_AWAY_FORMAT_COLUMN';
export const RESET_COLUMN_MENU = '[COLUMN]RESET_COLUMN_MENU';
export const UPDATE_GRID_COLUMN = '[COLUMN]UPDATE_GRID_COLUMN';
export const UPDATE_GRID_COLUMN_SUCCESS = '[COLUMN]UPDATE_GRID_COLUMN_SUCCESS';
export const UPDATE_GRID_COLUMN_FAILED = '[COLUMN]UPDATE_GRID_COLUMN_FAILED';
export const DELETE_GRID_COLUMN = '[COLUMN]DELETE_GRID_COLUMN';
export const DELETE_GRID_COLUMN_SUCCESS = '[COLUMN]DELETE_GRID_COLUMN_SUCCESS';
export const DELETE_GRID_COLUMN_FAILED = '[COLUMN]DELETE_GRID_COLUMN_FAILED';
export const CREATE_GRID_COLUMN_SOCKET = '[COLUMN]CREATE_GRID_COLUMN_SOCKET';
export const ADD_GRID_COLUMN_TO_VIEW_SOCKET = '[COLUMN]ADD_GRID_COLUMN_TO_VIEW_SOCKET';
export const RESIZE_COLUMN = '[COLUMN]RESIZE_COLUMN';
export const ADD_DISABLED_COLUMNS = '[COLUMN]ADD_DISABLED_COLUMNS';
export const REMOVE_DISABLED_COLUMNS = '[COLUMN]REMOVE_DISABLED_COLUMNS';
export const ADD_PROCESSING_COLUMNS = '[COLUMN]ADD_PROCESSING_COLUMNS';
export const REMOVE_PROCESSING_COLUMNS = '[COLUMN]REMOVE_PROCESSING_COLUMNS';
export const RESET_DISABLED_PROCESSING_COLUMNS = `RESET_DISABLED_PROCESSING_COLUMNS`;

export const ADD_DISABLED_SOURCE_COLUMNS = '[COLUMN]ADD_DISABLED_SOURCE_COLUMNS';
export const REMOVE_DISABLED_SOURCE_COLUMNS = '[COLUMN]REMOVE_DISABLED_SOURCE_COLUMNS';
export const RESET_DISABLED_SOURCE_COLUMNS = `RESET_DISABLED_SOURCE_COLUMNS`;

export const IS_FETCHING_DISABLED_COLUMN_DATA = '[COLUMN]IS_FETCHING_DISABLED_COLUMN_DATA';
export const STOP_FETCHING_DISABLED_COLUMN_DATA = '[COLUMN]STOP_FETCHING_DISABLED_COLUMN_DATA';
export const UPDATE_SINGLE_COLUMN_DATA = '[COLUMN]UPDATE_SINGLE_COLUMN_DATA';
export const REORDER_COLUMNS = '[COLUMNS]REORDER_COLUMNS';
export const REORDER_COLUMNS_SUCCESS = '[COLUMNS]REORDER_COLUMNS_SUCCESS';
export const SET_COLUMNS_SELECTION = '[COLUMNS]SET_COLUMNS_SELECTION';
export const CLEAR_COLUMNS_SELECTION = '[COLUMNS]CLEAR_COLUMNS_SELECTION';
export const CHANGE_ORDER_COLUMN_PERMISSION_AFTER_ADD_TO_VIEW =
    '[COLUMNS]CHANGE_ORDER_COLUMN_PERMISSION_AFTER_ADD_TO_VIEW';
export const UPDATE_REORDER_COLUMNS_REALTIME = '[COLUMNS]UPDATE_REORDER_COLUMNS_REALTIME';

export const UPLOAD_FILES_START = '[COLUMNS]UPLOAD_FILES_START';
export const RESET_COLUMN_UPLOAD_STATUS = '[COLUMNS]RESET_COLUMN_UPLOAD_STATUS';
export const HANDLE_COLUMN_UPLOAD_FAILED = '[COLUMNS]HANDLE_COLUMN_UPLOAD_FAILED';
export const UPLOAD_SINGLE_FILE_SUCCESS_SOCKET = '[COLUMNS]UPLOAD_SINGLE_FILE_SUCCESS_SOCKET';
export const UPLOAD_SINGLE_FILE_FAILED_SOCKET = '[COLUMNS]UPLOAD_SINGLE_FILE_FAILED_SOCKET';
export const EXPAND_COLUMN_UPLOAD = '[COLUMNS]EXPAND_COLUMN_UPLOAD';
export const RESET_ALL_UPLOAD_STATUS = '[COLUMNS]RESET_ALL_UPLOAD_STATUS';
export const ADD_COLUMN_IN_VIEW_WITH_ORDER = `[COLUMN]ADD_COLUMN_IN_VIEW_WITH_ORDER`;
export const CHANGE_COLUMN_EDITABLE = `[COLUMN]CHANGE_COLUMN_EDITABLE`;
export const CHANGE_COLUMN_VIEWABLE = `[COLUMN]CHANGE_COLUMN_VIEWABLE`;
export const UPDATE_VIEW_COLUMN_ORDER = `[COLUMN]UPDATE_VIEW_COLUMN_ORDER`;
export const CLEAR_IS_NEW = `[COLUMN]CLEAR_IS_NEW`;

export const CREATE_RELATIVE_GRID_COLUMN_SUCCESS = `[COLUMN]CREATE_RELATIVE_GRID_COLUMN_SUCCESS`;

export const SET_FORMAT_COLUMN_ID = `[COLUMN]SET_FORMAT_COLUMN_ID`;

/**
 * GRID VIEW
 */
export const GET_VIEW_COLUMNS = '[COLUMN]GET_VIEW_COLUMNS';
export const COLUMN_SELECTION = '[COLUMN]COLUMN_SELECTION';

/**
 *  OPTIMISTIC
 */
export const COMMIT_ACTION = '[OPTIMISTIC]COMMIT_ACTION';
export const REMOVE_ACTION = '[OPTIMISTIC]REMOVE_ACTION';
export const REVERT_ACTION = '[OPTIMISTIC]REVERT_ACTION';
export const OPTIMISTIC_UPDATE_CELL = '[OPTIMISTIC]OPTIMISTIC_UPDATE_CELL';
export const OPTIMISTIC_UPDATE_COLUMN = '[OPTIMISTIC]OPTIMISTIC_UPDATE_COLUMN';
export const OPTIMISTIC_DELETE_COLUMN = '[OPTIMISTIC]OPTIMISTIC_DELETE_COLUMN';
export const OPTIMISTIC_UPDATE_VIEW = '[OPTIMISTIC]OPTIMISTIC_UPDATE_VIEW';
export const OPTIMISTIC_DELETE_VIEW = '[OPTIMISTIC]OPTIMISTIC_DELETE_VIEW';
export const OPTIMISTIC_DELETE_RANGE_SELECTION = '[OPTIMISTIC]OPTIMISTIC_DELETE_RANGE_SELECTION';
export const OPTIMISTIC_UPDATE_VIEW_EDIT_COLUMNS_PERMISSION =
    '[OPTIMISTIC]OPTIMISTIC_UPDATE_VIEW_EDIT_COLUMNS_PERMISSION';
export const OPTIMISTIC_UPDATE_VIEW_VIEW_COLUMNS_PERMISSION =
    '[OPTIMISTIC]OPTIMISTIC_UPDATE_VIEW_VIEW_COLUMNS_PERMISSION';
export const OPTIMISTIC_DELETE_RECORD = '[OPTIMISTIC]OPTIMISTIC_DELETE_RECORD';
export const OPTIMISTIC_DELETE_FILE_OF_CELL = '[OPTIMISTIC]OPTIMISTIC_DELETE_FILE_OF_CELL';
export const OPTIMISTIC_PASTE = '[OPTIMISTIC]OPTIMISTIC_PASTE';
export const OPTIMISTIC_FILL_CELLS_COLOR = '[OPTIMISTIC]OPTIMISTIC_FILL_CELLS_COLOR';
export const OPTIMISTIC_REORDER_COLUMNS = '[OPTIMISTIC]OPTIMISTIC_REORDER_COLUMNS';
export const OPTIMISTIC_REORDER_RECORDS = '[OPTIMISTIC]OPTIMISTIC_REORDER_RECORDS';
export const OPTIMISTIC_RESIZE_COLUMN = '[OPTIMISTIC]OPTIMISTIC_REORDER_COLUMN';
export const OPTIMISTIC_RESIZE_RECORD = '[OPTIMISTIC]OPTIMISTIC_RESIZE_RECORD';
export const OPTIMISTIC_CHANGE_FREEZING_INDEX = '[OPTIMISTIC]OPTIMISTIC_CHANGE_FREEZING_INDEX';
export const OPTIMISTIC_UPDATE_METADATA_TM_STATUS = '[OPTIMISTIC]OPTIMISTIC_UPDATE_METADATA_TM_STATUS';
export const OPTIMISTIC_UPDATE_METADATA_DEPENDENCY_STATUS = '[OPTIMISTIC]OPTIMISTIC_UPDATE_METADATA_DEPENDENCY_STATUS';
export const OPTIMISTIC_UPDATE_METADATA_SOURCE_STATUS = '[OPTIMISTIC]OPTIMISTIC_UPDATE_METADATA_SOURCE_STATUS';
export const OPTIMISTIC_PATH_TAG_DROP_ROW_INTO_TREE = '[OPTIMISTIC]OPTIMISTIC_PATH_TAG_DROP_ROW_INTO_TREE';
export const OPTIMISTIC_ENABLE_COLUMN_IN_VIEW_ORDER = `[OPTIMISTIC]OPTIMISTIC_ENABLE_COLUMN_IN_VIEW_ORDER`;
export const OPTIMISTIC_COPY_CELL_RELEASE = `[OPTIMISTIC]OPTIMISTIC_COPY_CELL_RELEASE`;

export const OPTIMISTIC_CHANGE_COLUMN_EDITABLE = `[OPTIMISTIC]OPTIMISTIC_CHANGE_COLUMN_EDITABLE`;
export const OPTIMISTIC_CHANGE_COLUMN_VIEWABLE = `[OPTIMISTIC]OPTIMISTIC_CHANGE_COLUMN_VIEWABLE`;
/**
 * SCROLL CREATOR
 */

export const SCROLL_TO_INDEX = '[SCROLL]SCROLL_TO_INDEX';

/**
 * VIEWS
 */

export const CREATE_VIEW = '[VIEWS]CREATE_VIEW';
export const CREATE_VIEW_SUCCESS = '[VIEWS]CREATE_VIEW_SUCCESS';
export const CREATE_VIEW_FAILED = '[VIEWS]CREATE_VIEW_FAILED';

export const CREATE_VIEW_REALTIME_SUCCESS = '[VIEWS]CREATE_VIEW_REALTIME_SUCCESS';

export const DELETE_VIEW = '[VIEWS]DELETE_VIEW';
export const DELETE_VIEW_REALTIME = '[VIEWS]DELETE_VIEW_REALTIME';
export const DELETE_VIEW_SUCCESS = '[VIEWS]DELETE_VIEW_SUCCESS';
export const DELETE_VIEW_FAILED = '[VIEWS]DELETE_VIEW_FAILED';

export const UPDATE_VIEW = '[VIEWS]UPDATE_VIEW';
export const UPDATE_VIEW_SUCCESS = '[VIEWS]UPDATE_VIEW_SUCCESS';
export const UPDATE_VIEW_FAILED = '[VIEWS]UPDATE_VIEW_FAILED';

export const UPDATE_EDIT_COLUMN_PERMISSION = '[VIEW]UPDATE_EDIT_COLUMN_PERMISSION';
export const UPDATE_VIEW_COLUMN_PERMISSION = '[VIEW]UPDATE_VIEW_COLUMN_PERMISSION';
export const UPDATE_VIEW_COLUMNS_PERMISSION_SUCCESS = '[VIEW]UPDATE_VIEW_COLUMNS_PERMISSION_SUCCESS';
export const UPDATE_VIEW_VIEW_COLUMNS_PERMISSION_SUCCESS = '[VIEW]UPDATE_VIEW_VIEW_COLUMNS_PERMISSION_SUCCESS';
export const UPDATE_VIEW_EDIT_COLUMNS_PERMISSION_SUCCESS = '[VIEW]UPDATE_VIEW_EDIT_COLUMNS_PERMISSION_SUCCESS';
export const UPDATE_VIEW_COLUMNS_PERMISSION_FAILED = '[VIEW]UPDATE_VIEW_COLUMNS_PERMISSION_FAILED';
export const UPDATE_VIEW_COLUMN_PERMISSION_SOCKET = '[VIEW]UPDATE_VIEW_COLUMN_PERMISSION_SOCKET';
export const DELETE_VIEW_COLUMN_PERMISSION_SOCKET = '[VIEW]DELETE_VIEW_COLUMN_PERMISSION_SOCKET';
export const GET_CURRENT_VIEW_SUCCESS = '[VIEWS]GET_CURRENT_VIEW_SUCCESS';
export const HIDE_COLUMN_ON_VIEW = `[VIEWS]HIDE_COLUMN_ON_VIEW`;

export const FETCH_CURRENT_VIEW = `[VIEWS]FETCH_CURRENT_VIEW`;
export const FETCH_CURRENT_VIEW_FAILED = `[VIEWS]FETCH_CURRENT_VIEW_FAILED`;
export const FETCH_CURRENT_VIEW_SUCCESS = `[VIEWS]FETCH_CURRENT_VIEW_SUCCESS`;
export const TOGGLE_BLOCK_CURRENT_VIEW = `[VIEWS]TOGGLE_BLOCK_CURRENT_VIEW`;
/**
 * VIEW FILTERS
 */

export const FETCH_VIEW_FILTERS = '[VIEW FILTER]FETCH_VIEW_FILTERS';
export const FETCH_VIEW_FILTERS_SUCCESS = '[VIEW FILTER]FETCH_VIEW_FILTERS_SUCCESS';
export const FETCH_VIEW_FILTERS_FAILED = '[VIEW FILTER]FETCH_VIEW_FILTERS_FAILED';

export const CREATE_VIEW_FILTER = '[VIEW FILTER]CREATE_VIEW_FILTER';
export const CREATE_VIEW_FILTER_SUCCESS = '[VIEW FILTER]CREATE_VIEW_FILTER_SUCCESS';
export const CREATE_VIEW_FILTER_FAILED = '[VIEW FILTER]CREATE_VIEW_FILTER_FAILED';

export const UPDATE_VIEW_FILTER = '[VIEW FILTER]UPDATE_VIEW_FILTER';
export const UPDATE_VIEW_FILTER_SUCCESS = '[VIEW FILTER]UPDATE_VIEW_FILTER_SUCCESS';
export const UPDATE_VIEW_FILTER_FAILED = '[VIEW FILTER]UPDATE_VIEW_FILTER_FAILED';

export const DELETE_VIEW_FILTER = '[VIEW FILTER]DELETE_VIEW_FILTER';
export const DELETE_VIEW_FILTER_SUCCESS = '[VIEW FILTER]DELETE_VIEW_FILTER_SUCCESS';
export const DELETE_VIEW_FILTER_FAILED = '[VIEW FILTER]DELETE_VIEW_FILTER_FAILED';
export const UPDATE_VIEW_FILTER_ID_AFTER_CREATE = '[VIEW FILTER]UPDATE_VIEW_FILTER_ID_AFTER_CREATE';
export const UPDATE_VIEW_FILTER_ONLY = '[VIEW FILTER]UPDATE_VIEW_FILTER_ONLY';
export const UPDATE_RECORDS_AFTER_FILTER = '[VIEW FILTER]UPDATE_RECORDS_AFTER_FILTER';

export const DELETE_VIEW_FILTERS_AFTER_COLUMN_IS_DELETED_SOCKET =
    '[VIEW FILTER]DELETE_VIEW_FILTERS_AFTER_COLUMN_IS_DELETED_SOCKET';

export const CLEAR_QUICK_FILTER_WHEN_VIEW_FILTER_IS_UPDATED_SOCKET =
    '[QUICK_FILTER]CLEAR_QUICK_FILTER_WHEN_VIEW_FILTER_IS_UPDATED_SOCKET';

/**
 * VIEW SORTS
 */

export const FETCH_VIEW_SORTS = '[VIEW SORT]FETCH_VIEW_SORTS';
export const FETCH_VIEW_SORTS_FAILED = '[VIEW SORT]FETCH_VIEW_SORTS_FAILED';
export const FETCH_VIEW_SORTS_SUCCESS = '[VIEW SORT]FETCH_VIEW_SORTS_SUCCESS';

export const CREATE_VIEW_SORT = '[VIEW SORT]CREATE_VIEW_SORT';
export const CREATE_VIEW_SORT_SUCCESS = '[VIEW SORT]CREATE_VIEW_SORT_SUCCESS';
export const CREATE_VIEW_SORT_FAILED = '[VIEW SORT]CREATE_VIEW_SORT_FAILED';

export const UPDATE_VIEW_SORT = '[VIEW SORT]UPDATE_VIEW_SORT';
export const UPDATE_VIEW_SORT_SUCCESS = '[VIEW SORT]UPDATE_VIEW_SORT_SUCCESS';
export const UPDATE_VIEW_SORT_FAILED = '[VIEW SORT]UPDATE_VIEW_SORT_FAILED';

export const DELETE_VIEW_SORT = '[VIEW SORT]DELETE_VIEW_SORT';
export const DELETE_VIEW_SORT_SUCCESS = '[VIEW SORT]DELETE_VIEW_SORT_SUCCESS';
export const DELETE_VIEW_SORT_FAILED = '[VIEW SORT]DELETE_VIEW_SORT_FAILED';
export const UPDATE_RECORDS_AFTER_SORT = '[VIEW SORT]UPDATE_RECORDS_AFTER_SORT';

export const DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET =
    '[VIEW SORT]DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET';

export const CLEAR_QUICK_SORT_WHEN_VIEW_SORT_IS_UPDATED_SOCKET =
    '[QUICK_SORT]CLEAR_QUICK_SORT_WHEN_VIEW_SORT_IS_UPDATED_SOCKET';

/**
 * VIEW COPY PASTE
 */
export const COPY = '[COPY/PASTE]COPY';
export const PASTE = '[COPY/PASTE]PASTE';

/**
 * EXPORT VIEW
 */
export const RESET_EXPORT_REMOVED_COLUMNS = '[EXPORT]RESET_EXPORT_REMOVED_COLUMNS';
export const RESTORE_EXPORT_REMOVED_COLUMN = '[EXPORT]RESTORE_EXPORT_REMOVED_COLUMN';
export const ADD_EXPORT_REMOVED_COLUMN = '[EXPORT]ADD_EXPORT_REMOVED_COLUMN';
export const EXPORT_VIEW = '[EXPORT]EXPORT_VIEW';
export const FETCH_EXPORT_PREVIEW_DATA = '[EXPORT]FETCH_EXPORT_PREVIEW_DATA';

/**
 * IMPORT VIEW
 */
export const IMPORT_READ_FILE_SUCCESS = '[IMPORT]IMPORT_READ_FILE_SUCCESS';
export const IMPORT_READ_FILE_FAILED = '[IMPORT]IMPORT_READ_FILE_FAILED';
export const IMPORT_FILE_SUCCESS = '[IMPORT]IMPORT_FILE_SUCCESS';
export const IMPORT_REVIEW_FILE_REQUEST = '[IMPORT]REVIEW_FILE_REQUEST';
export const CHANGE_IMPORT_PREVIEW = '[IMPORT]CHANGE_IMPORT_PREVIEW';

export const IMPORT_FILE_CREATE_GRID = '[IMPORT]IMPORT_FILE_CREATE_GRID';
export const IMPORT_FILE_CREATE_GRID_FAILED = '[IMPORT]IMPORT_FILE_CREATE_GRID_FAILED';
export const IMPORT_FILE_CREATE_GRID_SUCCESS = '[IMPORT]IMPORT_FILE_CREATE_GRID_SUCCESS';

/**
 * IMPORT GRID FOR LOCALIZATION
 */

export const IMPORT_GRID_FOR_LOCALIZATION = '[IMPORT]IMPORT_GRID_FOR_LOCALIZATION';
export const IMPORT_GRID_FOR_LOCALIZATION_SUCCESS = '[IMPORT]IMPORT_GRID_FOR_LOCALIZATION_SUCCESS';
export const IMPORT_GRID_FOR_LOCALIZATION_FAILED = '[IMPORT]IMPORT_GRID_FOR_LOCALIZATION_FAILED';
export const RESET_IMPORT_GRID_FOR_LOCALIZATION = '[IMPORT]RESET_IMPORT_GRID_FOR_LOCALIZATION';
export const SET_LOCALIZATION_COLUMN = '[IMPORT]SET_LOCALIZATION_COLUMN';
export const CLEAR_ORIGINAL_OPTION_COLUMN = '[IMPORT]CLEAR_ORIGINAL_OPTION_COLUMN';
export const RESET_LOCALIZATION_COLUMN = '[IMPORT]RESET_LOCALIZATION_COLUMN';
export const SAVE_IMPORT_GRID_COLUMN = '[IMPORT]SAVE_IMPORT_GRID_COLUMN';

export const IMPORT_LOCALIZATION_JSON_FILE = '[IMPORT]IMPORT_LOCALIZATION_JSON_FILE';
export const IMPORT_LOCALIZATION_JSON_FILE_SUCCESS = '[IMPORT]IMPORT_LOCALIZATION_JSON_FILE_SUCCESS';
export const IMPORT_LOCALIZATION_JSON_FILE_FAILED = '[IMPORT]IMPORT_LOCALIZATION_JSON_FILE_FAILED';

export const IMPORT_LOCALIZATION_PO_FILE = '[IMPORT]IMPORT_LOCALIZATION_PO_FILE';
export const IMPORT_LOCALIZATION_PO_FILE_SUCCESS = '[IMPORT]IMPORT_LOCALIZATION_PO_FILE_SUCCESS';
export const IMPORT_LOCALIZATION_PO_FILE_FAILED = '[IMPORT]IMPORT_LOCALIZATION_PO_FILE_FAILED';

/**
 * IMPORT JSON LOCALIZATION
 */
export const IMPORT_LOCALIZATION_JSON_TO_VIEW = `[IMPORT]IMPORT_LOCALIZATION_JSON_TO_VIEW`;
export const IMPORT_LOCALIZATION_JSON_TO_VIEW_SUCCESS = `[IMPORT]RE_IMPORT_LOCALIZATION_JSON_TO_VIEW_SUCCESS`;
export const IMPORT_LOCALIZATION_JSON_TO_VIEW_FAILED = `[IMPORT]RE_IMPORT_LOCALIZATION_JSON_TO_VIEW_FAILED`;

/**
 * FREEZING
 */

export const CHANGE_FREEZING_COLUMN = '[FREEZING]CHANGE_FREEZING_COLUMN';

/**
 * CELL COLOR
 */

export const CLEAR_CELLS_COLOR = '[CELL-COLOR]CLEAR_CELLS_COLOR';
export const SET_CURRENT_CELL_COLOR = '[CELL-COLOR]SET_CURRENT_CELL_COLOR';
export const SET_SELECTED_CELL_COLOR = '[CELL-COLOR]SET_SELECTED_CELL_COLOR';
export const RESET_CELL_COLOR = '[CELL-COLOR]RESET_CELL_COLOR';

/**
 * API KEY
 *
 */
export const SET_API_KEY = '[API_KEY]SET_API_KEY';

/**
 * COMMENT
 */

export const OPEN_CELL_COMMENT = '[CELL_COMMENT]OPEN_CELL_COMMENT';
export const CLOSE_CELL_COMMENT = '[CELL_COMMENT]CLOSE_CELL_COMMENT';

export const FETCH_CELL_COMMENT_LIST = '[CELL_COMMENT]FETCH_CELL_COMMENT_LIST';
export const FETCH_CELL_COMMENT_LIST_SUCCESS = '[CELL_COMMENT]FETCH_CELL_COMMENT_LIST_SUCCESS';
export const FETCH_CELL_COMMENT_LIST_FAILED = '[CELL_COMMENT]FETCH_CELL_COMMENT_LIST_FAILED';

export const SET_COMMENT = '[CELL_COMMENT]SET_COMMENT';
export const UPDATE_COMMENT_LIST = '[CELL_COMMENT]UPDATE_COMMENT_LIST';
export const UPDATE_CELL_COMMENT_THREAD_STATUS = '[CELL_COMMENT]UPDATE_CELL_COMMENT_THREAD_STATUS';

export const DELETE_CELL_COMMENT = '[CELL_COMMENT]DELETE_CELL_COMMENT';
export const DELETE_CELL_COMMENT_SUCCESS = '[CELL_COMMENT]DELETE_CELL_COMMENT_SUCCESS';
export const DELETE_CELL_COMMENT_FAILED = '[CELL_COMMENT]DELETE_CELL_COMMENT_FAILED';

export const UPDATE_COMMENT = '[CELL_COMMENT]UPDATE_COMMENT';

export const UPDATE_THREAD_COMMENT = '[CELL_COMMENT]UPDATE_THREAD_COMMENT';

/**
 * RECORD HISTORY
 */

export const OPEN_RECORD_HISTORY = '[RECORD_HISTORY]OPEN_RECORD_HISTORY';
export const CLOSE_RECORD_HISTORY = '[RECORD_HISTORY]CLOSE_RECORD_HISTORY';
export const CHANGE_RECORD_HISTORY = '[RECORD_HISTORY]CHANGE_RECORD_HISTORY';
export const RESET_RECORD_HISTORY = '[RECORD_HISTORY]RESET_RECORD_HISTORY';

export const FETCH_RECORD_HISTORY = '[RECORD_HISTORY]FETCH_RECORD_HISTORY';
export const FETCH_RECORD_HISTORY_SUCCESS = '[RECORD_HISTORY]FETCH_RECORD_HISTORY_SUCCESS';
export const FETCH_RECORD_HISTORY_FAILED = '[RECORD_HISTORY]FETCH_RECORD_HISTORY_FAILED';

export const SET_TRIGGER_REFRESH_RECORD_HISTORY = '[RECORD_HISTORY]SET_TRIGGER_REFRESH_RECORD_HISTORY';
export const RESET_TRIGGER_REFRESH_RECORD_HISTORY = '[RECORD_HISTORY]RESET_TRIGGER_REFRESH_RECORD_HISTORY';

export const FETCH_MORE_RECORD_HISTORY = '[RECORD_HISTORY]FETCH_MORE_RECORD_HISTORY';
export const FETCH_MORE_RECORD_HISTORY_SUCCESS = '[RECORD_HISTORY]FETCH_MORE_RECORD_HISTORY_SUCCESS';
export const FETCH_MORE_RECORD_HISTORY_FAILED = '[RECORD_HISTORY]FETCH_MORE_RECORD_HISTORY_FAILED';
export const SET_DROP_RECORD_ID = '[RECORD]SET_DROP_RECORD_ID';
export const DROP_RECORD_INTO_ROW = '[RECORD]DROP_RECORD_INTO_ROW';

/**
 * UNDO REDOS
 *
 */

export const UNDO_REDO_COLUMN = 'UNDO_REDO_COLUMN';
export const UNDO_REDO_CELL = 'UNDO_REDO_CELL';
export const UNDO_REDO_VIEW = 'UNDO_REDO_VIEW';
export const UNDO_REDO_RECORD = 'UNDO_REDO_RECORD';
export const UNDO_REDO_SWITCH_COLUMN = 'UNDO_REDO_SWITCH_COLUMN';

export const REGISTER_UNDO_TO_JOB_LIST = '[UNDO]REGISTER_UNDO_TO_JOB_LIST';
export const REMOVE_JOB_LIST = '[UNDO]REMOVE_JOB_LIST';
export const POP_STACK_ID_OUT_OF_UNDO_STACK = '[UNDO]POP_STACK_ID_OUT_OF_UNDO_STACK';
export const POP_STACK_ID_OUT_OF_REDO_STACK = '[UNDO]POP_STACK_ID_OUT_OF_REDO_STACK';
export const RESET_UNDO_STACK = '[UNDO]RESET_UNDO_STACK';
export const RESET_REDO_STACK = '[UNDO]RESET_REDO_STACK';
export const REGISTER_UNDO_REDO = '[UNDO]REGISTER_UNDO_REDO';
export const TOGGLE_PROCESSING_UNDO_REDO = '[UNDO]TOGGLE_PROCESSING_UNDO_REDO';
export const UNDO_DELETE_COLUMN_V2 = '[UNDO]UNDO_DELETE_COLUMN_V2';
/**
 * ACTIONS UNDO
 */

//CELL
export const UNDO_CHANGE_CELL_INFO = '[UNDO_CELL]UNDO_CHANGE_CELL_INFO'; //SUPPORTED
export const UNDO_UPDATE_CELL_INFO = '[UNDO_CELL]UNDO_UPDATE_CELL_INFO'; //SUPPORTED
export const UNDO_RANGE_CELL_DELETE = '[UNDO_CELL]UNDO_RANGE_CELL_DELETE'; //SUPPORTED
export const UNDO_CELL_COLOR_FORMATTING = '[UNDO_CELL]UNDO_CELL_COLOR_FORMATTING'; //SUPPORTED

export const UNDO_CHANGE_CELL_COPY = '[UNDO_CELLS]UNDO_CHANGE_CELL_COPY'; //SUPPORTED

//DRAG DROP PATH TAG
export const UNDO_PATH_TAG_DATA = '[UNDO_RECORD]UNDO_PATH_TAG_DATA';

//COPY PASTE
export const UNDO_PASTE_RANGE_CELL = '[UNDO_CELL]UNDO_PASTE_RANGE_CELL'; // SUPPORTED

//COLUNN
export const UNDO_DELETE_COLUMN = '[UNDO_COLUMN]UNDO_DELETE_COLUMN'; //ONLY SHOW MESSAGE
export const UNDO_SWITCH_COLUMN = '[UNDO_COLUMN]UNDO_SWITCH_COLUMN'; // NO SUPPORT
export const UNDO_RESIZE_COLUMN = '[UNDO_COLUMN]UNDO_RESIZE_COLUMN'; //SUPPORTED
export const UNDO_TM_STATUS = '[UNDO_TM]UNDO_TM_STATUS'; //SUPPORTED

//RECORD
export const UNDO_DELETE_RECORD = '[UNDO_RECORD]UNDO_DELETE_RECORD'; //ONLY SHOW MESSAGE
export const UNDO_RESIZE_RECORD = '[UNDO_RECORD]UNDO_RESIZE_RECORD'; //SUPPORTED
export const UNDO_TEXT_WRAPPING = '[UNDO_RECORD]UNDO_TEXT_WRAPPING'; //SUPPORTED

//FREEZING
export const UNDO_FREEZING_POSITION = '[UNDO_VIEW]UNDO_FREEZING_POSITION'; //SUPPORTED

/**
 * ACTIONS REDO
 */

//CELL
export const REDO_CHANGE_CELL_INFO = '[REDO_CELL]REDO_CHANGE_CELL_INFO'; //SUPPORTED
export const REDO_UPDATE_CELL_INFO = '[REDO_CELL]REDO_UPDATE_CELL_INFO'; //SUPPORTED
export const REDO_RANGE_CELL_DELETE = '[REDO_CELL]REDO_RANGE_CELL_DELETE'; //SUPPORTED
export const REDO_CELL_COLOR_FORMATTING = '[REDO_CELL]REDO_CELL_COLOR_FORMATTING'; //SUPPORTED
export const REDO_CHANGE_CELL_COPY = '[REDO_CELLS]REDO_CHANGE_CELL_COPY'; //SUPPORTED

//DRAG DROP PATH TAG
export const REDO_PATH_TAG_DATA = '[REDO_RECORD]REDO_PATH_TAG_DATA';

//COPY PASTE
export const REDO_PASTE_RANGE_CELL = '[REDO_CELL]REDO_PASTE_RANGE_CELL'; //SUPPORTED

//COLUNN
export const REDO_DELETE_COLUMN = '[REDO_COLUMN]REDO_DELETE_COLUMN'; //ONLY SHOW MESSAGE
export const REDO_SWITCH_COLUMN = '[REDO_COLUMN]REDO_SWITCH_COLUMN'; //NO SUPPORT
export const REDO_RESIZE_COLUMN = '[REDO_COLUMN]REDO_RESIZE_COLUMN'; //SUPPORTED
export const REDO_TM_STATUS = '[REDO_TM]REDO_TM_STATUS'; //SUPPORTED

//RECORD
export const REDO_DELETE_RECORD = '[REDO_RECORD]REDO_DELETE_RECORD'; //ONLY SHOW MESSAGE
export const REDO_RESIZE_RECORD = '[REDO_RECORD]REDO_RESIZE_RECORD'; //SUPPORTED
export const REDO_TEXT_WRAPPING = '[REDO_RECORD]REDO_TEXT_WRAPPING'; //SUPPORTED

//FREEZING
export const REDO_FREEZING_POSITION = '[REDO_VIEW]REDO_FREEZING_POSITION'; //SUPPORTED

//STATUS
export const REGISTER_DOING_ACTION = '[STATUS]REGISTER_DOING_ACTION';
export const REMOVE_DOING_ACTION = '[STATUS]REMOVE_DOING_ACTION';

/**
 * EVENT LOG
 */
export const FETCH_EVENT_LOGS = '[EVENT-LOG]FETCH_EVENT_LOGS';
export const FETCH_EVENT_LOGS_SUCCESS = '[EVENT-LOG]FETCH_EVENT_LOGS_SUCCESS';
export const FETCH_EVENT_LOGS_FAILED = '[EVENT-LOG]FETCH_EVENT_LOGS_FAILED';
export const RESET_GRID_HISTORY = '[EVENT-LOG]RESET_GRID_HISTORY';
export const REFRESH_EVENT_LOGS = '[EVENT-LOG]REFRESH_EVENT_LOGS';
export const REFRESH_EVENT_LOGS_SUCCESS = '[EVENT-LOG]REFRESH_EVENT_LOGS_SUCCESS';
export const REFRESH_EVENT_LOGS_FAILED = '[EVENT-LOG]REFRESH_EVENT_LOGS_FAILED';

export const CHANGE_BEGIN_FILTER = `[EVENT-LOG]CHANGE_BEGIN_FILTER`;

//SHARE VIEW
export const FETCH_VIEW_GROUPS = '[SHARE_VIEW]FETCH_VIEW_GROUPS';
export const FETCH_VIEW_GROUPS_SUCCESS = '[SHARE_VIEW]FETCH_VIEW_GROUPS_SUCCESS';
export const FETCH_VIEW_GROUPS_FAILED = '[SHARE_VIEW]FETCH_VIEW_GROUPS_FAILED';

export const CHANGE_SELECTED_GROUP_ID = '[SHARE_VIEW]CHANGE_SELECTED_GROUP_ID';

export const CHANGE_SHARE_VIEW_USER_WORKSPACE_ROLE = '[SHARE_VIEW]CHANGE_SHARE_VIEW_USER_WORKSPACE_ROLE';

export const GRANT_GROUP_TO_VIEW_SUCCESS = '[SHARE_VIEW]GRANT_GROUP_TO_VIEW_SUCCESS';
export const GRANT_GROUP_TO_VIEW_FAILED = '[SHARE_VIEW]GRANT_GROUP_TO_VIEW_FAILED';

export const REMOVE_VIEW_OUT_OF_GROUP_SUCCESS = '[SHARE_VIEW]REMOVE_VIEW_OUT_OF_GROUP_SUCCESS';
export const REMOVE_VIEW_OUT_OF_GROUP_FAILED = '[SHARE_VIEW]REMOVE_VIEW_OUT_OF_GROUP_FAILED';

//DEPENDENCIES
export const FETCH_DEPENDENCIES = '[DEPENDENCY]FETCH_DEPENDENCIES';
export const FETCH_DEPENDENCIES_FAILED = '[DEPENDENCY]FETCH_DEPENDENCIES_FAILED';
export const FETCH_DEPENDENCIES_SUCCESS = '[DEPENDENCY]FETCH_DEPENDENCIES_SUCCESS';

export const DELETE_DEPENDENCY = '[DEPENDENCY]DELETE_DEPENDENCY';
export const DELETE_DEPENDENCY_SUCCESS = '[DEPENDENCY]DELETE_DEPENDENCY_SUCCESS';
export const DELETE_DEPENDENCY_FAILED = '[DEPENDENCY]DELETE_DEPENDENCY_FAILED';
export const DELETE_DEPENDENCY_SOCKET = '[DEPENDENCY]DELETE_DEPENDENCY_SOCKET';

export const CREATE_DEPENDENCY = '[DEPENDENCY]CREATE_DEPENDENCY';
export const CREATE_DEPENDENCY_FAILED = '[DEPENDENCY]CREATE_DEPENDENCY_FAILED';
export const CREATE_DEPENDENCY_SUCCESS = '[DEPENDENCY]CREATE_DEPENDENCY_SUCCESS';
export const CREATE_DEPENDENCY_SOCKET = '[DEPENDENCY]CREATE_DEPENDENCY_SOCKET';

export const UPDATE_DEPENDENCY = '[DEPENDENCY]UPDATE_DEPENDENCY';
export const UPDATE_DEPENDENCY_FAILED = '[DEPENDENCY]UPDATE_DEPENDENCY_FAILED';
export const UPDATE_DEPENDENCY_SUCCESS = '[DEPENDENCY]UPDATE_DEPENDENCY_SUCCESS';
export const UPDATE_DEPENDENCY_SOCKET = '[DEPENDENCY]UPDATE_DEPENDENCY_SOCKET';

export const CREATE_FAKE_DEPENDENCY = '[DEPENDENCY]CREATE_FAKE_DEPENDENCY';
export const CHANGE_PARENT = '[DEPENDENCY]CHANGE_PARENT';
export const CHANGE_CHILD = '[DEPENDENCY]CHANGE_CHILD';
//TM
export const APPROVED_TM_STATUS = '[TM]APPROVED_TM_STATUS';
export const APPROVED_TM_STATUS_FAILED = '[TM]APPROVED_TM_STATUS_FAILED';
export const SET_IS_APPLYING_TM = '[TM]SET_IS_APPLYING_TM';

//TM FOR GRID
export const FETCH_TM_SUGGESTION = '[TM-GRID]FETCH_TM_SUGGESTION';
export const FETCH_TM_SUGGESTION_SUCCESS = '[TM-GRID]FETCH_TM_SUGGESTION_SUCCESS';
export const ADD_TM_SUGGESTION = '[TM_GRID]ADD_TM_SUGGESTION';
export const ADD_TM_SUGGESTION_SUCCESS = '[TM_GRID]ADD_TM_SUGGESTION_SUCCESS';
export const ADD_TM_SUGGESTION_FAILED = '[TM_GRID]ADD_TM_SUGGESTION_FAILED';
export const UPDATE_TM_SUGGESTION = '[TM_GRID]UPDATE_TM_SUGGESTION';
export const UPDATE_TM_SUGGESTION_SUCCESS = '[TM_GRID]UPDATE_TM_SUGGESTION_SUCCESS';
export const UPDATE_TM_SUGGESTION_FAILED = '[TM_GRID]UPDATE_TM_SUGGESTION_FAILED';
export const FETCH_TM_ID = '[TM_GRID]FETCH_TM_ID';

//REALTIME USER ACTIONS
export const REGISTER_REALTIME_USER_ACTION = '[USER-REALTIME]REGISTER_REALTIME_USER_ACTION';
export const REMOVE_REALTIME_USER_ACTION = '[USER-REALTIME]REMOVE_REALTIME_USER_ACTION';
export const USER_LEAVE = '[USER-REALTIME]USER_LEAVE';
export const USER_DOING = '[USER-REALTIME]USER_DOING';
export const USER_JOIN = '[USER-REALTIME]USER_JOIN';

//PATH TAG

export const TOGGLE_PATH_TAG = '[PATH-TAG]TOGGLE_PATH_TAG';
export const SET_PATH_TAG_ON = '[PATH-TAG]SET_PATH_TAG_ON';
export const TOGGLE_NODES = '[PATH-TAG]TOGGLE_NODES';

export const FETCH_PATH_TAG_TREE = '[PATH-TAG]FETCH_PATH_TAG_TREE';
export const FETCH_PATH_TAG_TREE_FAILED = '[PATH-TAG]FETCH_PATH_TAG_TREE_FAILED';
export const FETCH_PATH_TAG_TREE_SUCCESS = '[PATH-TAG]FETCH_PATH_TAG_TREE_SUCCESS';

export const ADD_ROOT_NODE = '[PATH-TAG]ADD_ROOT_NODE';
export const ADD_ROOT_NODE_SUCCESS = '[PATH-TAG]ADD_ROOT_NODE_SUCCESS';
export const ADD_ROOT_NODE_FAILED = '[PATH-TAG]ADD_ROOT_NODE_FAILED';

export const ADD_CHILD_NODE = '[PATH-TAG]ADD_CHILD_NODE';
export const ADD_CHILD_NODE_SUCCESS = '[PATH-TAG]ADD_CHILD_NODE_SUCCESS';
export const ADD_CHILD_NODE_FAILED = '[PATH-TAG]ADD_CHILD_NODE_FAILEDV';

export const DELETE_NODE = '[PATH-TAG]DELETE_NODE';
export const DELETE_NODE_FAILED = '[PATH-TAG]DELETE_NODE_FAILED';
export const DELETE_NODE_SUCCESS = '[PATH-TAG]DELETE_NODE_SUCCESS';

export const UPDATE_NODE = '[PATH-TAG]UPDATE_NODE';
export const UPDATE_NODE_FAILED = '[PATH-TAG]UPDATE_NODE_FAILED';
export const UPDATE_NODE_SUCCESS = '[PATH-TAG]UPDATE_NODE_SUCCESS';

export const MOVE_NODE = '[PATH-TAG]MOVE_NODE';
export const MOVE_NODE_FAILED = '[PATH-TAG]MOVE_NODE_FAILED';
export const MOVE_NODE_SUCCESS = '[PATH-TAG]MOVE_NODE_SUCCESS';

export const SET_DRAG_NODE_IDS = '[PATH-TAG]SET_DRAG_NODE_IDS';
export const SET_DRAGGING_NODE_PATH = '[PATH-TAG]SET_DRAGGING_NODE_PATH';
export const RESET_DROP_PATH_AND_DRAG_NODE_IDS = '[PATH-TAG]RESET_DROP_PATH_AND_DRAG_NODE_IDS';

export const TURN_ON_FIRST_SKELETON = '[PATH-TAG]TURN_ON_FIRST_SKELETON';
export const TURN_ON_NODE_POP_UP = '[PATH-TAG]TURN_ON_NODE_POP_UP';
export const TURN_OFF_NODE_POP_UP = '[PATH-TAG]TURN_OFF_NODE_POP_UP';

export const FILTER_PATH_TAG = '[PATH-TAG]FILTER_PATH_TAG';
export const FILTER_PATH_TAG_FAILED = '[PATH-TAG]FILTER_PATH_TAG_FAILED';
export const FILTER_PATH_TAG_SUCCESS = '[PATH-TAG]FILTER_PATH_TAG_SUCCESS';

export const DROP_PATH_TAG_ROW_INTO_TREE = '[PATH-TAG]DROP_PATH_TAG_ROW_INTO_TREE';

export const SET_ROWS_DRAGGING = '[PATH-TAG]SET_ROWS_DRAGGING';
//AGGREGATION
export const FETCH_AGGREGATION_LIST = '[AGGREGATION]FETCH_AGGREGATION_LIST';
export const FETCH_AGGREGATION_LIST_SUCCESS = '[AGGREGATION]FETCH_AGGREGATION_LIST_SUCCESS';
export const FETCH_AGGREGATION_LIST_FAILED = '[AGGREGATION]FETCH_AGGREGATION_LIST_FAILED';

export const FETCH_VIEW_AGGREGATION_LIST = '[AGGREGATION]FETCH_VIEW_AGGREGATION_LIST';
export const FETCH_VIEW_AGGREGATION_LIST_FAILED = '[AGGREGATION]FETCH_VIEW_AGGREGATION_LIST_FAILED';
export const FETCH_VIEW_AGGREGATION_LIST_SUCCESS = '[AGGREGATION]FETCH_VIEW_AGGREGATION_LIST_SUCCESS';

export const CREATE_VIEW_AGGREGATE = '[AGGREGATION]CREATE_VIEW_AGGREGATE';
export const CREATE_VIEW_AGGREGATE_SUCCESS = '[AGGREGATION]CREATE_VIEW_AGGREGATE_SUCCESS';
export const CREATE_VIEW_AGGREGATE_FAILED = '[AGGREGATION]CREATE_VIEW_AGGREGATE_FAILED';

export const UPDATE_VIEW_AGGREGATE = '[AGGREGATION]UPDATE_VIEW_AGGREGATE';
export const UPDATE_VIEW_AGGREGATE_SUCCESS = '[AGGREGATION]UPDATE_VIEW_AGGREGATE_SUCCESS';
export const UPDATE_VIEW_AGGREGATE_FAILED = '[AGGREGATION]UPDATE_VIEW_AGGREGATE_FAILED';

export const DELETE_VIEW_AGGREGATE = '[AGGREGATION]DELETE_VIEW_AGGREGATE';
export const DELETE_VIEW_AGGREGATE_SUCCESS = '[AGGREGATION]DELETE_VIEW_AGGREGATE_SUCCESS';
export const DELETE_VIEW_AGGREGATE_FAILED = '[AGGREGATION]DELETE_VIEW_AGGREGATE_FAILED';

export const CHANGE_AGGREGATION = '[AGGREGATION]CHANGE_AGGREGATION';
export const CHANGE_AGGREGATION_SUCCESS = '[AGGREGATION]CHANGE_AGGREGATION_SUCCESS';
export const CHANGE_AGGREGATION_FAILED = '[AGGREGATION]CHANGE_AGGREGATION_FAILED';

export const DELETE_VIEW_AGGREGATE_BY_COLUMN_ID = '[AGGREGATION]DELETE_VIEW_AGGREGATE_BY_COLUMN_ID';

export const ADD_AGGREGATION_AFTER_NEW_COLUMN_CREATED = '[AGGREGATION]ADD_AGGREGATION_AFTER_NEW_COLUMN_CREATED';

export const CLEAR_WHEN_SWITCH_VIEW_OR_SWITCH_GRID = '[SWITCH_VIEW]CLEAR_WHEN_SWITCH_VIEW_OR_SWITCH_GRID';

export const FETCH_WEB_HOOKS = '[WEB-HOOKS]FETCH_WEB_HOOKS';
export const FETCH_WEB_HOOKS_SUCCESS = '[WEB-HOOKS]FETCH_WEB_HOOKS_SUCCESS';
export const FETCH_WEB_HOOKS_FAILED = '[WEB-HOOKS]FETCH_WEB_HOOKS_FAILED';

export const CREATE_WEB_HOOK = '[WEB-HOOKS]CREATE_WEB_HOOK';
export const CREATE_WEB_HOOK_SUCCESS = '[WEB-HOOKS]CREATE_WEB_HOOK_SUCCESS';
export const CREATE_WEB_HOOK_FAILED = '[WEB-HOOKS]CREATE_WEB_HOOK_FAILED';

export const UPDATE_WEB_HOOK = '[WEB-HOOKS]UPDATE_WEB_HOOK';
export const UPDATE_WEB_HOOK_FAILED = '[WEB-HOOKS]UPDATE_WEB_HOOK_FAILED';
export const UPDATE_WEB_HOOK_SUCCESS = '[WEB-HOOKS]UPDATE_WEB_HOOK_SUCCESS';

export const DELETE_WEB_HOOK = '[WEB-HOOKS]DELETE_WEB_HOOK';
export const DELETE_WEB_HOOK_FAILED = '[WEB-HOOKS]DELETE_WEB_HOOK_FAILED';
export const DELETE_WEB_HOOK_SUCCESS = '[WEB-HOOKS]DELETE_WEB_HOOK_SUCCESS';
export const UPDATE_HOOK_ID_AFTER_CREATE = '[WEB-HOOKS]UPDATE_HOOK_ID_AFTER_CREATE';

export const WEB_HOOKS_TURN_ON_FIRST_CREATE_POP_UP = '[WEB-HOOKS]WEB_HOOKS_TURN_ON_FIRST_CREATE_POP_UP';
export const WEB_HOOKS_TURN_OFF_FIRST_CREATE_POP_UP = '[WEB-HOOKS]WEB_HOOKS_TURN_OFF_FIRST_CREATE_POP_UP';

export const PREVIEW_HOOK = '[WEB-HOOKS]PREVIEW_HOOK';
export const TEST_HOOK = '[WEB-HOOKS]TEST_HOOK';

export const FETCH_WEB_HOOK_LOGS = '[WEB-HOOKS]FETCH_WEB_HOOK_LOGS';
export const FETCH_WEB_HOOK_LOGS_SUCCESS = '[WEB-HOOKS]FETCH_WEB_HOOK_LOGS_SUCCESS';
export const FETCH_WEB_HOOK_LOGS_FAILED = '[WEB-HOOKS]FETCH_WEB_HOOK_LOGS_FAILED';

export const FETCH_MORE_WEB_HOOK_LOGS = '[WEB-HOOKS]FETCH_MORE_WEB_HOOK_LOGS';
export const FETCH_MORE_WEB_HOOK_LOGS_SUCCESS = '[WEB-HOOKS]FETCH_MORE_WEB_HOOK_LOGS_SUCCESS';
export const FETCH_MORE_WEB_HOOK_LOGS_FAILED = '[WEB-HOOKS]FETCH_MORE_WEB_HOOK_LOGS_FAILED';
export const CLEAR_ACTIVE_USER = '[USER-ACTIVE]CLEAR_ACTIVE_USER';

/**
 * PATH TAG DRAG DROP
 */
export const TURN_ON_DRAGGING_ROWS = '[PATH-TAG]TURN_ON_DRAGGING_ROWS';
export const TURN_OFF_DRAGGING_ROWS = '[PATH-TAG]TURN_OFF_DRAGGING_ROWS';
export const SET_DROP_PATH = '[PATH-TAG]SET_DROP_PATH';
export const DROP_ROWS_INTO_PATH_TAG = '[PATH-TAG]DROP_ROWS_INTO_PATH_TAG';

export const TURN_ON_DRAGGING_PATH_TAG = `[PATH-TAG]TURN_ON_DRAGGING_PATH_TAG`;
export const TURN_OFF_DRAGGING_PATH_TAG = `[PATH-TAG]TURN_OFF_DRAGGING_PATH_TAG`;
export const DROP_PATH_TAG_INTO_PATH_TAG = `[PATH]DROP_PATH_TAG_INTO_PATH_TAG`;
export const SET_DRAGGING_PATH = `[PATH]SET_DRAGGING_PATH`;
/**
 * REF
 */

export const FETCH_UPDATE_OTHER_REF_CELL = '[REF]FETCH_UPDATE_OTHER_REF_CELL';

/**
 * AUDIO
 */

export const REGISTER_AUDIO_PLAY = `[AUDIO]REGISTER_AUDIO_PLAY`;

/**
 * COMMENT DASHBOARD
 */

export const FETCH_GIRD_COMMENT_LIST = `[COMMENT-DASHBOARD]FETCH_GIRD_COMMENT_LIST`;
export const FETCH_GIRD_COMMENT_LIST_SUCCESS = `[COMMENT-DASHBOARD]FETCH_GIRD_COMMENT_LIST_SUCCESS`;
export const FETCH_GIRD_COMMENT_LIST_FAILED = `[COMMENT-DASHBOARD]FETCH_GIRD_COMMENT_LIST_FAILED`;

export const ADD_NEW_COMMENT_TO_GRID_COMMENT = `[COMMENT-DASHBOARD]ADD_NEW_COMMENT_TO_GRID_COMMENT`;

export const UPDATE_DASHBOARD_COMMENT_THREAD_STATUS = `[COMMENT-DASHBOARD]UPDATE_DASHBOARD_COMMENT_THREAD_STATUS`;

/**
 * TRANSLATION DASHBOARD
 */

export const FETCH_TRANSLATION_DASHBOARD_DATA = `[TRANSLATION DASHBOARD]FETCH_TRANSLATION_DASHBOARD_DATA`;
export const FETCH_TRANSLATION_DASHBOARD_DATA_SUCCESS = `[TRANSLATION DASHBOARD]FETCH_TRANSLATION_DASHBOARD_DATA_SUCCESS`;
export const FETCH_TRANSLATION_DASHBOARD_DATA_FAILED = `[TRANSLATION DASHBOARD]FETCH_TRANSLATION_DASHBOARD_DATA_FAILED`;
export const UPDATE_DATA = `UPDATE_DATA`;
export const DELETE_RECORDS_DATA = `DELETE_RECORDS_DATA`;
export const UPDATE_GRID_DATA = `[GRID]UPDATE_GRID_DATA`;

/**
 * SHARE LINK VIEW
 */

export const GET_SHARE_LINK_VIEW = '[SHARE_VIEW]GET_SHARE_LINK_VIEW';
export const DELETE_SHARE_LINK_VIEW = '[SHARE_VIEW]DELETE_SHARE_LINK_VIEW';
export const AUTO_FILL_SUCCESS = `[AUTO_FILL]AUTO_FILL_SUCCESS`;

/**
 * PREVIEW CELL
 */

export const OPEN_CELL_FILE_PREVIEW = '[CELL_PREVIEW]OPEN_CELL_FILE_PREVIEW';
export const CLOSE_CELL_FILE_PREVIEW = '[CELL_PREVIEW]CLOSE_CELL_FILE_PREVIEW';

export const TURN_ON_RECORD_PANEL = '[RECORD-PANEL]TURN_ON_RECORD_PANEL';
export const TURN_OFF_RECORD_PANEL = '[RECORD-PANEL]TURN_OFF_RECORD_PANEL';

export const PINNED_SEARCH = '[SEARCH]PINNED_SEARCH';
export const UNPINNED_SEARCH = '[SEARCH]UNPINNED_SEARCH';
export const SEARCHING_RECORD = '[SEARCH]SEARCHING_RECORD';
export const OPEN_REPLACE_SEARCH = '[SEARCH]OPEN_REPLACE_SEARCH';
export const CLOSE_REPLACE_SEARCH = '[SEARCH]CLOSE_REPLACE_SEARCH';
export const SET_REPLACING_TEXT = '[SEARCH]SET_REPLACING_TEXT';
export const SET_SEARCH_RECORDS = '[SEARCH]SET_SEARCH_RECORDS';
export const SET_SUCCESS_REPLACE_ALL = '[SEARCH]SET_SUCCESS_REPLACE_ALL';
export const SET_REPLACING_TYPE = '[SEARCH]SET_REPLACING_TYPE';
export const SET_CLEAR_SEARCH_STATE = '[SEARCH]SET_CLEAR_SEARCH_STATE';
export const SET_SEARCH_RANGE = '[SEARCH]SET_SEARCH_RANGE';
export const SET_SHOW_SEARCH_RANGE = '[SEARCH]SET_SHOW_SEARCH_RANGE';

export const GET_TOTAL_GRID_RECORDS_SUCCESS = `[STATISTIC]GET_TOTAL_GRID_RECORDS_SUCCESS`;
export const SET_TASK_ID_STATUS = `[IMPORT]SET_TASK_ID_STATUS`;
export const GENERATE_PREVIEW_IMPORT = `[IMPORT]GENERATE_PREVIEW_IMPORT`;
export const MERGE_IMPORT = `[MERGE-IMPORT]MERGE_IMPORT`;

export const OPEN_CELL_PREVIEW = `[CELL_PREVIEW]OPEN_CELL_PREVIEW`;
export const TOGGLE_CELL_PREVIEW = `[CELL_PREVIEW]TOGGLE_CELL_PREVIEW`;
export const CLOSE_CELL_PREVIEW = `[CELL_PREVIEW]CLOSE_CELL_PREVIEW`;

export const OPEN_CELL_ISSUE = `[CELL_ISSUE]OPEN_CELL_ISSUE`;
export const TOGGLE_CELL_ISSUE = `[CELL_ISSUE]TOGGLE_CELL_ISSUE`;
export const CLOSE_CELL_ISSUE = `[CELL_ISSUE]CLOSE_CELL_ISSUE`;
export const OPEN_VIEW_CELL_TICKET = `[CELL_ISSUE]OPEN_VIEW_CELL_TICKET`;
export const TOGGLE_VIEW_CELL_TICKET = `[CELL_ISSUE]TOGGLE_VIEW_CELL_TICKET`;
export const CLOSE_VIEW_CELL_TICKET = `[CELL_ISSUE]CLOSE_VIEW_CELL_TICKET`;

export const PINNED_CELL_ISSUE = '[CELL_ISSUE]PINNED_CELL_ISSUE';

export const SET_GRID_CLEARING_RECORDS = 'SET_GRID_CLEARING_RECORDS';
/**
 * REFACTOR RANGE INDEXES
 */

export const SET_ROW_INDEX_MAP = `[RANGES]SET_ROW_INDEX_MAP`;
export const UPDATE_ROW_STOP_INDEX_MAP = `[RANGES]UPDATE_ROW_STOP_INDEX_MAP`;

export const TOGGLE_DELETING_RECORDS = `[RECORD]TOGGLE_DELETING_RECORDS`;

export const SET_ROW_IDS = `[RECORD]SET_ROW_IDS`;
/**
 * LQA
 */
export const SET_LQA_MODELS = `[LQA]SET_LQA_MODELS`;
export const SET_LQA_MODEL_DEFAULT = `[LQA]SET_LQA_MODEL_DEFAULT`;
export const OPEN_LQA_STICKY = `[LQA]OPEN_LQA_STICKY`;
export const SET_LQA_STICKY_DATA = `[LQA]SET_LQA_STICKY_DATA`;
export const SET_OPEN_LQA_TICKET_DETAIL = `[LQA]SET_OPEN_LQA_TICKET_DETAIL`;
export const SET_LQA_TICKET_DETAIL = `[LQA]SET_LQA_TICKET_DETAIL`;
export const SET_LQA_CONFIRM_DELETE_TICKET = `[LQA]SET_LQA_CONFIRM_DELETE_TICKET`;
export const ADD_PROJECT_TO_LQA = `[LQA]ADD_PROJECT_TO_LQA`;
export const ADD_PROJECT_TO_LQA_SUCCESS = `[LQA]ADD_PROJECT_TO_LQA_SUCCESS`;
export const ADD_PROJECT_TO_LQA_FAILED = `[LQA]ADD_PROJECT_TO_LQA_FAILED`;

export const REMOVE_PROJECT_TO_LQA = `[LQA]REMOVE_PROJECT_TO_LQA`;
export const REMOVE_PROJECT_TO_LQA_SUCCESS = `[LQA]REMOVE_PROJECT_TO_LQA_SUCCESS`;
export const REMOVE_PROJECT_TO_LQA_FAILED = `[LQA]REMOVE_PROJECT_TO_LQA_FAILED`;
export const SET_LQA_TICKET = `[LQA]SET_LQA_TICKET`;
export const SET_LQA_TOTAL = `[LQA]SET_LQA_TOTAL`;
export const SET_LQA_PAGE_NUMBER = `[LQA]SET_LQA_PAGE_NUMBER`;
export const SET_LQA_RESET_QUERY = `[LQA]SET_LQA_RESET_QUERY`;
export const SET_LQA_TICKET_AND_TOTAL = `[LQA]SET_LQA_TICKET_AND_TOTAL`;
export const SET_LQA_TAB = `[LQA]SET_LQA_TAB`;
export const SET_LQA_TOTAL_INCREASE_DECREASE = `[LQA]SET_LQA_TOTAL_INCREASE_DECREASE`;
export const RESET_DEFAULT_LQA = `[LQA]RESET_DEFAULT_LQA`;
export const SET_LQA_EXPORTING = `[LQA]SET_LQA_EXPORTING`;
export const SET_LQA_CREATE_TYPE = `[LQA]SET_LQA_CREATE_TYPE`;

/**
 * TermsBase
 */
export const SET_LIST_TERMS_BASE = '[TB]SET_LIST_TERMS_BASE';
export const SET_DATA_FROM_TB = '[TB]SET_DATA_FROM_TB';

/**
 * TAG
 */

export const TOGGLE_TAG = `[TAG]TOGGLE_TAG`;
export const CHANGE_TAG_REGEX = `[TAG]CHANGE_TAG_REGEX`;
export const CHANGE_TAG_MODE = `[TAG]CHANGE_REGEX`;
export const SET_LIST_TAG_MODELS = `[TAG]SET_LIST_TAG_MODELS`;
export const SET_PREDEFINED_TOKENS = `[TAG]SET_PREDEFINED_TOKENS`;
/**
 * AutoQA
 */
export const SHOW_AUTO_QA_ERRORS = '[AUTO_QA]SHOW_AUTO_QA_ERRORS';
export const HIDE_AUTO_QA_ERRORS = '[AUTO_QA]HIDE_AUTO_QA_ERRORS';
export const OPEN_ERROR_CONTROL_PANEL = '[AUTO_QA]OPEN_ERROR_CONTROL_PANEL';
export const CLOSE_ERROR_CONTROL_PANEL = '[AUTO_QA]CLOSE_ERROR_CONTROL_PANEL';
export const SET_IS_RUNNING_AUTO_QA = '[AUTO_QA]SET_IS_RUNNING_AUTO_QA';
export const TOGGLE_ERROR_TYPE = '[AUTO_QA]TOGGLE_ERROR_TYPE';
export const TOGGLE_ALL_ERROR_TYPE = '[AUTO_QA]TOGGLE_ALL_ERROR_TYPE';

export const GET_AUTOQA_SETTINGS_SUCCESS = '[AUTO_QA]GET_AUTOQA_SETTINGS_SUCCESS';
export const CREATE_AUTOQA_SETTING_SUCCESS = '[AUTO_QA]CREATE_AUTOQA_SETTING_SUCCESS';
export const UPDATE_AUTOQA_SETTING_SUCCESS = '[AUTO_QA]UPDATE_AUTOQA_SETTING_SUCCESS';
export const DELETE_AUTOQA_SETTING_SUCCESS = '[AUTO_QA]DELETE_AUTOQA_SETTING_SUCCESS';

export const OPEN_IGNORE_SIMILAR_ERRORS_CONFIRM = '[AUTO_QA]OPEN_IGNORE_SIMILAR_ERRORS_CONFIRM';
export const CLOSE_IGNORE_SIMILAR_ERRORS_CONFIRM = '[AUTO_QA]CLOSE_IGNORE_SIMILAR_ERRORS_CONFIRM';

/**
 * CELLS COPY
 */

export const SET_COPIED_RANGE = '[COPIED-RANGE]SET_COPIED_RANGE';

export const SET_RECORD_HISTORY_HEIGHT = `[RECORD_HISTORY]SET_RECORD_HISTORY_HEIGHT`;

export const FETCH_LATEST_RECORD = `[EXTEND_VIEW]FETCH_LATEST_RECORD`;

export const REGISTER_HOLD_EVENT = `REGISTER_HOLD_EVENT`;

export const SET_SECTION_SCROLL = `SET_SECTION_SCROLL`;

export const FETCH_CURRENT_GRID = `FETCH_CURRENT_GRID`;
export const UPDATE_CURRENT_GRID = `UPDATE_CURRENT_GRID`;
export const REGISTER_MERGING_TASK = `REGISTER_MERGING_TASK`;
export const TOGGLE_FAKE_COLUMN = `TOGGLE_FAKE_COLUMN`;

export const SET_SELECTING_COPY = `SET_SELECTING_COPY`;
export const SET_OPEN_FAKE_COLUMN = `SET_OPEN_FAKE_COLUMN`;

export const SET_AUTOMATIONS = `SET_AUTOMATIONS`;
export const SET_IS_FETCHING_AUTOMATIONS = `SET_IS_FETCHING_AUTOMATIONS`;
export const SET_AUTOMATION_DETAIL = `SET_AUTOMATION_DETAIL`;
export const SET_OPEN_AUTOMATION_DETAIL = `SET_OPEN_AUTOMATION_DETAIL`;
export const SET_AUTOMATION_DETAIL_ERROR = `SET_AUTOMATION_DETAIL_ERROR`;
export const SET_AUTOMATION_LIST_EXECUTIONS = `SET_AUTOMATION_LIST_EXECUTIONS`;
export const SET_AUTOMATION_LAMBDAS = `SET_AUTOMATION_LAMBDAS`;

export const SET_IS_PREVIEW_SELECTION_RANGE_FILE_DATA = `SET_IS_PREVIEW_SELECTION_RANGE_FILE_DATA`;
export const SET_SELECTION_RANGE_FILE_DATA = `SET_SELECTION_RANGE_FILE_DATA`;
export const SET_IS_FETCHING_SELECTION_RANGE_FILE_DATA = `SET_IS_FETCHING_SELECTION_RANGE_FILE_DATA`;
export const SET_OPEN_MULTIPLE_AUTOMATION_DIALOG = `SET_OPEN_MULTIPLE_AUTOMATION_DIALOG`;
