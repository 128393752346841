import React from 'react';
import { isTempId } from 'utils/uuid';

function ColumnIdCell({ rowId, rowIndex, isUseColumnIdByIndex }) {
    return (
        <div className="w-full cursor-pointer flex justify-center items-center">
            <p className={'text body2'}>{isUseColumnIdByIndex ? rowIndex + 1 : isTempId(rowId) ? '' : rowId}</p>
        </div>
    );
}

export default React.memo(ColumnIdCell);
