import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import AuthenticationWrapper from './AuthenticationWrapper';
import GetStartedForm from './GetStartedForm';
import AuthFormWrapper from './common/AuthFormWrapper';
import GetStartedBkgSVG from 'assets/images/svg/GetStartedBkgSVG';

import useMediaQueries from '../hooks/useMediaQueries';
import AuthBgIconRightSVG from 'assets/images/auth-started-bkg.svg';

import { trackUserOnboarding } from 'services/external';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    left: {
        background: `url(${AuthBgIconRightSVG})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%'
    }
}));

function GetStarted({ location }) {
    const { isMobile } = useMediaQueries();
    const classes = useStyles();
    const { t } = useTranslation();

    const queryParams = queryParamsToObject(location?.search);

    const onFormNext = ({ email }) => {
        trackUserOnboarding({
            email,
            step: 1,
            utmSource: queryParams?.landing_source,
            queryParams
        });
    };

    return (
        <Grid container direction="row">
            {!isMobile && (
                <Grid item xs={12} sm={4}>
                    <Grid className={classes.left} container direction="row" justify="center" alignItems="flex-end">
                        <Grid item>
                            <GetStartedBkgSVG />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} sm={8}>
                <AuthenticationWrapper isGetStarted>
                    <AuthFormWrapper title={t(`auth_get_started_title`)} subTitle={t(`auth_get_started_subtitle`)}>
                        <GetStartedForm handleOnNext={onFormNext} />
                    </AuthFormWrapper>
                </AuthenticationWrapper>
            </Grid>
        </Grid>
    );
}

export default GetStarted;
