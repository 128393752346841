import React from 'react';

function SingleLineSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Datatype/SingleLine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M0,11.1111111 L0,9.33333333 L9.77777778,9.33333333 L9.77777778,11.1111111 L0,11.1111111 Z M0,4 L16,4 L16,5.77777778 L0,5.77777778 L0,4 Z"
                    id="s-copy-2"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(SingleLineSVG);
