import React from 'react';

function CleanSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clean)">
                <path
                    d="M15.7867 14.711C15.6488 14.5678 15.4683 14.4893 15.2714 14.4893H8.75651L15.6652 7.31117C16.1116 6.8474 16.1116 6.09719 15.6652 5.63342L10.5715 0.347826C10.1251 -0.115942 9.40308 -0.115942 8.95672 0.347826L0.817231 8.80477C0.292103 9.35379 0 10.0835 0 10.861C0 11.6385 0.292103 12.3683 0.820513 12.9173L3.2722 15.4646C3.60369 15.809 4.04677 16 4.51938 16H15.2747C15.6751 16 16 15.6624 16 15.2464C16 15.0452 15.9245 14.8542 15.7867 14.711ZM9.76738 1.64365L14.4181 6.4757L8.65149 12.4672L3.98441 7.64876L9.76738 1.64365ZM4.51938 14.4928C4.43733 14.4928 4.35856 14.4587 4.29949 14.3973L1.84779 11.85C1.59508 11.5874 1.45395 11.2361 1.45395 10.861C1.45395 10.4859 1.59508 10.1347 1.84779 9.87212L2.95713 8.71952L7.62092 13.5379L6.70195 14.4928H4.51938Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clean">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(CleanSVG);
