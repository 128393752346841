import React from 'react';

function SettingPaymentSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8002 0.833203C10.8002 0.391375 10.442 0.0332031 10.0002 0.0332031C9.55837 0.0332031 9.2002 0.391375 9.2002 0.833203V3.36655H7.91686C6.93114 3.36655 5.98579 3.75812 5.28878 4.45513C4.59177 5.15214 4.2002 6.09749 4.2002 7.08321C4.2002 8.06894 4.59177 9.01428 5.28878 9.71129C5.98579 10.4083 6.93114 10.7999 7.91686 10.7999H9.2002V15.0332H5.0002C4.55837 15.0332 4.2002 15.3914 4.2002 15.8332C4.2002 16.275 4.55837 16.6332 5.0002 16.6332H9.2002V19.1665C9.2002 19.6084 9.55837 19.9665 10.0002 19.9665C10.442 19.9665 10.8002 19.6084 10.8002 19.1665V16.6332H12.0835C13.0692 16.6332 14.0146 16.2416 14.7116 15.5446C15.4086 14.8476 15.8002 13.9023 15.8002 12.9165C15.8002 11.9308 15.4086 10.9855 14.7116 10.2885C14.0146 9.59146 13.0693 9.19988 12.0835 9.19988H10.8002V4.96655H14.1669C14.6087 4.96655 14.9669 4.60837 14.9669 4.16655C14.9669 3.72472 14.6087 3.36655 14.1669 3.36655H10.8002V0.833203ZM9.2002 4.96655H7.91686C7.35549 4.96655 6.8171 5.18955 6.42015 5.5865C6.0232 5.98346 5.8002 6.52184 5.8002 7.08321C5.8002 7.64459 6.0232 8.18297 6.42015 8.57992C6.8171 8.97688 7.35549 9.19988 7.91686 9.19988H9.2002V4.96655ZM10.8002 10.7999V15.0332H12.0835C12.6449 15.0332 13.1833 14.8102 13.5802 14.4133C13.9772 14.0163 14.2002 13.4779 14.2002 12.9165C14.2002 12.3552 13.9772 11.8168 13.5802 11.4198C13.1833 11.0229 12.6449 10.7999 12.0835 10.7999H10.8002Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SettingPaymentSVG);
