import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from 'components/tooltip/Base';
import { Grid } from '@material-ui/core';
import IconLockSVG from 'assets/images/svg/IconLockSVG';

const useStyles = makeStyles(theme => ({
    icon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        background: theme.colors.solitude,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}));

const ShowCellCannotEdit = ({ showDiff, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Tooltip title="Cell cannot edit">
            <Grid item className={classes.icon} style={{ background: theme.colors.white }}>
                <IconLockSVG width={20} height={20} />
            </Grid>
        </Tooltip>
    );
};

export default React.memo(ShowCellCannotEdit);
