import React from 'react';

function EyeCloseIconSVG({ color = `#78778B`, ...other }) {
    return (
        <svg {...other} width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5904 11.6988L13.5301 10.8313L7.07229 5.43374L6.68675 5.04819L4.85542 3.50603L4.37349 3.12048L1.86747 1L1.09638 1.96386L5.91566 6.01205L6.68675 6.68675L15.747 14.3012L16.5181 13.3374L14.5904 11.6988Z"
                fill={color}
            />
            <path
                d="M16.6145 6.68675C15.9398 5.14458 14.8795 3.89157 13.5301 3.0241C12.1807 2.15663 10.5422 1.6747 9 1.6747C7.6506 1.6747 6.3012 2.06024 5.04819 2.63855L6.10843 3.60241C6.9759 3.21687 7.93976 3.0241 9 3.0241C10.3494 3.0241 11.6988 3.40964 12.8554 4.18072C14.012 4.95181 14.9759 5.91566 15.5542 7.16868C15.6506 7.36145 15.6506 7.55422 15.6506 7.74699C15.6506 7.93976 15.6506 8.13253 15.5542 8.3253C15.1687 9.09639 14.6867 9.77109 14.1084 10.3494L15.0723 11.2169C15.747 10.5422 16.3253 9.77109 16.7108 8.90362C16.9036 8.51807 17 8.13253 17 7.74699C16.9036 7.36145 16.8072 6.97591 16.6145 6.68675Z"
                fill={color}
            />
            <path
                d="M5.04819 11.3133C3.89157 10.6386 3.0241 9.57832 2.44578 8.3253C2.3494 8.13253 2.3494 7.93976 2.3494 7.74699C2.3494 7.55422 2.3494 7.36145 2.44578 7.16868C2.83133 6.39759 3.31325 5.72289 3.89157 5.14458L2.92771 4.27711C2.15663 5.04819 1.6747 5.81928 1.28916 6.68675C1.09639 6.97591 1 7.36145 1 7.74699C1 8.13253 1.09639 8.51807 1.28916 8.90362C1.96386 10.3494 3.0241 11.6024 4.37349 12.4699C5.72289 13.3374 7.36145 13.8193 8.90362 13.8193C10.253 13.8193 11.6024 13.4337 12.8554 12.8554L11.7952 11.8916C9.6747 12.759 7.07229 12.6627 5.04819 11.3133Z"
                fill={color}
            />
            <path
                d="M10.1566 5.91566C10.5422 6.20482 10.8313 6.49398 11.0241 6.87952C11.1205 7.16868 11.2169 7.55422 11.1205 7.84338L12.2771 8.80723C12.3735 8.42169 12.4699 8.03615 12.4699 7.65061C12.4699 6.68675 12.0843 5.81928 11.4096 5.14458C10.7349 4.46988 9.86747 4.08434 8.90362 4.08434C8.3253 4.18072 7.74699 4.3735 7.26506 4.66265L8.42169 5.62651C8.61446 5.62651 8.80723 5.53012 9 5.53012C9.38554 5.53012 9.86747 5.62651 10.1566 5.91566Z"
                fill={color}
            />
            <path
                d="M5.62651 6.68675C5.43374 7.26506 5.33736 7.84338 5.43374 8.42169C5.53013 9.09639 5.91567 9.77109 6.3976 10.253C6.87953 10.7349 7.55423 11.1205 8.22892 11.2169C8.4217 11.2169 8.71085 11.3133 8.90362 11.3133C9.38555 11.3133 9.86748 11.2169 10.253 11.0241C10.3494 11.0241 10.4458 10.9277 10.5422 10.8313L9.38555 9.86747H9.28917C8.80724 9.96386 8.4217 9.86747 8.03615 9.77109C7.65061 9.6747 7.36145 9.38555 7.0723 9C6.87953 8.61446 6.68676 8.22892 6.68676 7.74699C6.68676 7.6506 6.68676 7.6506 6.68676 7.55422L5.62651 6.68675Z"
                fill={color}
            />
            <path
                d="M14.5904 11.6988L13.5301 10.8313L7.07229 5.43374L6.68675 5.04819L4.85542 3.50603L4.37349 3.12048L1.86747 1L1.09638 1.96386L5.91566 6.01205L6.68675 6.68675L15.747 14.3012L16.5181 13.3374L14.5904 11.6988Z"
                stroke={color}
                strokeWidth="0.1"
            />
            <path
                d="M16.6145 6.68675C15.9398 5.14458 14.8795 3.89157 13.5301 3.0241C12.1807 2.15663 10.5422 1.6747 9 1.6747C7.6506 1.6747 6.3012 2.06024 5.04819 2.63855L6.10843 3.60241C6.9759 3.21687 7.93976 3.0241 9 3.0241C10.3494 3.0241 11.6988 3.40964 12.8554 4.18072C14.012 4.95181 14.9759 5.91566 15.5542 7.16868C15.6506 7.36145 15.6506 7.55422 15.6506 7.74699C15.6506 7.93976 15.6506 8.13253 15.5542 8.3253C15.1687 9.09639 14.6867 9.77109 14.1084 10.3494L15.0723 11.2169C15.747 10.5422 16.3253 9.77109 16.7108 8.90362C16.9036 8.51807 17 8.13253 17 7.74699C16.9036 7.36145 16.8072 6.97591 16.6145 6.68675Z"
                stroke={color}
                strokeWidth="0.1"
            />
            <path
                d="M5.04819 11.3133C3.89157 10.6386 3.0241 9.57832 2.44578 8.3253C2.3494 8.13253 2.3494 7.93976 2.3494 7.74699C2.3494 7.55422 2.3494 7.36145 2.44578 7.16868C2.83133 6.39759 3.31325 5.72289 3.89157 5.14458L2.92771 4.27711C2.15663 5.04819 1.6747 5.81928 1.28916 6.68675C1.09639 6.97591 1 7.36145 1 7.74699C1 8.13253 1.09639 8.51807 1.28916 8.90362C1.96386 10.3494 3.0241 11.6024 4.37349 12.4699C5.72289 13.3374 7.36145 13.8193 8.90362 13.8193C10.253 13.8193 11.6024 13.4337 12.8554 12.8554L11.7952 11.8916C9.6747 12.759 7.07229 12.6627 5.04819 11.3133Z"
                stroke={color}
                strokeWidth="0.1"
            />
            <path
                d="M10.1566 5.91566C10.5422 6.20482 10.8313 6.49398 11.0241 6.87952C11.1205 7.16868 11.2169 7.55422 11.1205 7.84338L12.2771 8.80723C12.3735 8.42169 12.4699 8.03615 12.4699 7.65061C12.4699 6.68675 12.0843 5.81928 11.4096 5.14458C10.7349 4.46988 9.86747 4.08434 8.90362 4.08434C8.3253 4.18072 7.74699 4.3735 7.26506 4.66265L8.42169 5.62651C8.61446 5.62651 8.80723 5.53012 9 5.53012C9.38554 5.53012 9.86747 5.62651 10.1566 5.91566Z"
                stroke={color}
                strokeWidth="0.1"
            />
            <path
                d="M5.62651 6.68675C5.43374 7.26506 5.33736 7.84338 5.43374 8.42169C5.53013 9.09639 5.91567 9.77109 6.3976 10.253C6.87953 10.7349 7.55423 11.1205 8.22892 11.2169C8.4217 11.2169 8.71085 11.3133 8.90362 11.3133C9.38555 11.3133 9.86748 11.2169 10.253 11.0241C10.3494 11.0241 10.4458 10.9277 10.5422 10.8313L9.38555 9.86747H9.28917C8.80724 9.96386 8.4217 9.86747 8.03615 9.77109C7.65061 9.6747 7.36145 9.38555 7.0723 9C6.87953 8.61446 6.68676 8.22892 6.68676 7.74699C6.68676 7.6506 6.68676 7.6506 6.68676 7.55422L5.62651 6.68675Z"
                stroke={color}
                strokeWidth="0.1"
            />
        </svg>
    );
}

export default React.memo(EyeCloseIconSVG);
