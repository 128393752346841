import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import { isKbEnter, isKbSpace, isKbTab } from 'utils/keyboard';
import { INPUT_HEIGHT } from 'const/style';
import { Chip, Grid, Typography } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import IsValidEmail from 'utils/isValidEmail';
import { useLdMemberMappingByEmail, useMemberMappingByEmail } from 'hooks/permission';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/avatar/User';
import { getAvatarUrl } from 'utils/images';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import ButtonBase from 'components/button/Base';
import { _readClipboard } from 'utils/clipboard';

const useStyles = makeStyles(theme => ({
    flx: { flex: 1 },
    chip: {
        margin: `3px 4px`,
        maxWidth: 500,
        height: 24,
        background: `rgba(74, 145, 226, 0.1)`,
        border: `1px solid rgba(74, 145, 226, 0.3)`,
        borderRadius: 15
    },
    chipError: {
        background: `rgba(235, 79, 94, 0.1)`,
        border: `1px solid rgba(235, 79, 94, 0.8)`
    },
    textField: {
        width: `100%`,
        maxHeight: INPUT_HEIGHT * 3,
        overflow: 'hidden auto',
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        padding: '3px 0',
        '&:focus': {
            borderColor: theme.palette.primary.main
        },
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        },
        '& .MuiInputBase-root': {
            flexWrap: 'wrap'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 150,
        minHeight: 30,
        padding: `0 8px`,
        fontSize: '0.875rem',
        fontWeight: 'normal',
        flex: 1
    },
    mt12: {
        marginTop: 12
    },
    hint: {
        color: theme.colors.midGrey
    },
    tag: {
        display: 'inline-block',
        padding: `4px 10px`,
        border: `1px solid #D7D7DC`,
        borderRadius: 4,
        fontWeight: '500',
        color: theme.colors.primaryText
    }
}));

const ChipCustom = React.memo(({ item, onDelete }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isInvalid = item?.isMemberExisted || item?.isExistedInExistedList || item?.isInvalidEmail;
    const [open, setOpen] = useState(isInvalid);
    const timeout = useRef(
        isInvalid
            ? setTimeout(() => {
                  setOpen(false);
              }, 4000)
            : null
    );

    const titleTooltip = useMemo(() => {
        if (item?.isMemberExisted) {
            return (
                <Grid container wrap="nowrap" direction="column" spacing={2}>
                    <Grid item>{t('invite_member_input_email_invited')}</Grid>
                    {item?.member && (
                        <Grid item>
                            <Grid container wrap="nowrap" spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar src={getAvatarUrl(item?.member?.id)} size={24} color="#DBDCE1">
                                        <AvatarIconSVG />
                                    </Avatar>
                                </Grid>
                                <Grid item>{item?.member?.fullName}</Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            );
        }
        if (item?.isExistedInExistedList) {
            return t('invite_member_input_email_added');
        }
        if (item?.isInvalidEmail) {
            return t('invite_member_input_invalid_email');
        }
        return '';
    }, [item, t]);

    const onInteract = useCallback(
        state => () => {
            if (!isInvalid) return;
            setOpen(state);
            clearTimeout(timeout.current);
        },
        [isInvalid]
    );

    return (
        <Tooltip open={open} title={titleTooltip} onOpen={onInteract(true)} onClose={onInteract(false)}>
            <Chip
                tabIndex={-1}
                label={item?.email}
                className={`${classes.chip} ${titleTooltip && classes.chipError}`}
                onDelete={onDelete}
                deleteIcon={<CloseIconSVG size="small" />}
            />
        </Tooltip>
    );
});

const InviteEmailsInput = ({ isLdManager, placeholder, existedEmails, onAdd, ...other }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [selectedItem, setSelectedItem] = useState([]);

    const membersMappingByEmail = useMemberMappingByEmail();
    const ldUserEmailMap = useLdMemberMappingByEmail();

    const disabledAdd = useMemo(() => {
        return (
            !selectedItem?.length ||
            selectedItem?.filter(item => item?.isMemberExisted || item?.isExistedInExistedList || item?.isInvalidEmail)
                ?.length > 0
        );
    }, [selectedItem]);

    const handleEvent = useCallback(
        event => {
            const value = event.target.value.trim();
            if (!value.replace(/\s/g, '').length) {
                return;
            }
            const newSelectedItem = [...selectedItem];
            const theArray = value.split(/\s+/);
            theArray.forEach(val => {
                const duplicatedValues = newSelectedItem.findIndex(item => item.email === val);
                if (duplicatedValues !== -1) {
                    return;
                }
                newSelectedItem.push({
                    email: val,
                    isMemberExisted: isLdManager ? Boolean(ldUserEmailMap[val]) : Boolean(membersMappingByEmail[val]),
                    member: isLdManager ? ldUserEmailMap[val] : membersMappingByEmail[val],
                    isExistedInExistedList: existedEmails?.includes(val),
                    isInvalidEmail: val && !IsValidEmail(val)
                });
            });
            setSelectedItem(newSelectedItem);
            setInputValue('');
        },
        [existedEmails, isLdManager, ldUserEmailMap, membersMappingByEmail, selectedItem]
    );

    const handleKeyDown = useCallback(
        event => {
            if (isKbEnter(event) || isKbTab(event) || isKbSpace(event)) {
                event.preventDefault();
                handleEvent(event);
            }
            // if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
            //     setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
            // }
        },
        [handleEvent]
    );

    const handleChange = useCallback(
        item => {
            if (item && selectedItem.findIndex(el => el?.email === item?.email) === -1) {
                let newSelectedItem = [...selectedItem];
                newSelectedItem = [...newSelectedItem, item];
                setSelectedItem(newSelectedItem);
            }
            setInputValue('');
        },
        [selectedItem]
    );

    const handleDelete = useCallback(
        email => () => {
            setSelectedItem(selectedItem => selectedItem.filter(item => item?.email !== email));
        },
        []
    );

    const handleInputChange = useCallback(event => {
        setInputValue(event.target.value);
    }, []);

    const handlePaste = useCallback(
        async event => {
            event.preventDefault();
            const { content } = await _readClipboard(event);
            if (!!content?.trim()) {
                handleEvent({ target: { value: content } });
            }
        },
        [handleEvent]
    );

    useImperativeHandle(ref, () => ({
        reset: () => {
            setInputValue('');
            setSelectedItem([]);
        },
        getValidSelectedEmails: () => {
            return selectedItem
                .filter(item => !item?.isExistedInExistedList && !item?.isMemberExisted && !item?.isInvalidEmail)
                .map(item => item.email);
        }
    }));

    return (
        <>
            <Grid className={classes.mt3} container wrap="nowrap" spacing={3}>
                <Grid item className={classes.flx}>
                    <Downshift
                        id="invite-emails-downshift-multiple"
                        inputValue={inputValue}
                        onChange={handleChange}
                        selectedItem={selectedItem}
                    >
                        {({ getInputProps }) => {
                            const { onBlur, onChange, onFocus, onPaste, ...inputProps } = getInputProps({
                                onKeyDown: handleKeyDown,
                                onBlur: handleEvent,
                                onPaste: handlePaste,
                                placeholder
                            });
                            return (
                                <div>
                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        InputProps={{
                                            inputProps: {
                                                className: `cell-editor ${classes.input}`
                                            },
                                            startAdornment: selectedItem.map((item, index) => (
                                                <ChipCustom
                                                    key={item?.email}
                                                    item={item}
                                                    onDelete={handleDelete(item?.email)}
                                                />
                                            )),
                                            onBlur,
                                            onChange: event => {
                                                handleInputChange(event);
                                                onChange(event);
                                            },
                                            onFocus,
                                            onPaste
                                        }}
                                        {...other}
                                        {...inputProps}
                                    />
                                </div>
                            );
                        }}
                    </Downshift>
                </Grid>
                <Grid item>
                    <ButtonBase variant="contained" width={248} onClick={onAdd} disabled={disabledAdd}>
                        {t('global_add')}
                    </ButtonBase>
                </Grid>
            </Grid>
            <Grid item className={classes.mt12}>
                <Typography variant="body2" className={classes.hint}>
                    Enter email address then press <span className={classes.tag}>Tab</span>,{' '}
                    <span className={classes.tag}>{t('space')}</span> or <span className={classes.tag}>Enter</span> to
                    validate
                </Typography>
            </Grid>
        </>
    );
};

const InviteEmailsInputRef = forwardRef(InviteEmailsInput);

export default React.memo(InviteEmailsInputRef);
