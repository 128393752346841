import * as rudder from 'rudder-sdk-js';
import {
    REACT_APP_RUDDER_STACK_WRITE_KEY,
    REACT_APP_RUDDER_STACK_HOST,
    REACT_APP_RUDDER_STACK_CONTROL_PLANE_URL
} from 'config';

export function rudderInitialize() {
    rudder.ready(() => {
        console.log('we are all set!!!');
    });
    rudder.load(REACT_APP_RUDDER_STACK_WRITE_KEY, REACT_APP_RUDDER_STACK_HOST, {
        configUrl: REACT_APP_RUDDER_STACK_CONTROL_PLANE_URL
    });
}
export { rudder };

// load
// ready
// identify
// alias
// page
// track
// group
// reset
// getAnonymousId
// setAnonymousId
