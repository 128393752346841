import escapeHtml from 'escape-html';
import { Text } from 'slate';

const serializeEach = node => {
    if (Text.isText(node)) {
        const { bold, italic, underline, code, color } = node;

        let string = ``;
        if (code) {
            string += `<code>`;
        }
        if (bold) {
            string += `<strong>`;
        }
        if (italic) {
            string += `<em>`;
        }
        if (underline) {
            string += `<u>`;
        }
        if (color) {
            string += `<span style='color: ${color}'>`;
        }

        let text = `${string}${escapeHtml(node.text)}${code ? '</code>' : ''}${bold ? '</strong>' : ''}${
            italic ? '</em>' : ''
        }${underline ? '</u>' : ''}${color ? '</span>' : ''}`;

        return text;
    }

    const children = node.children.map(n => serializeEach(n)).join('');
    switch (node.type) {
        default:
            return `<p>${children}</p>`;
        case 'block-quote':
            return `<blockquote>${children}</blockquote>`;
        case 'code':
            return `<code>${children}</code>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'heading-one':
            return `<h1>${children}</h1>`;
        case 'heading-two':
            return `<h2>${children}</h2>`;
        case 'heading-three':
            return `<h3>${children}</h3>`;
        case 'heading-four':
            return `<h4>${children}</h4>`;
        case 'heading-five':
            return `<h5>${children}</h5>`;
        case 'heading-six':
            return `<h6>${children}</h6>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'link':
            return `<a href='${escapeHtml(node.url)}'>${children}</a>`;
    }
};

const serialize = (data = []) => {
    return data
        .map(node => {
            let a = serializeEach(node);
            return a;
        })
        .join('');
};

export default serialize;
