import React, { useCallback, useEffect, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import withImages from './plugins/image';
import withHtml from './plugins/pasteHtml';
import withShortcut from './plugins/shortcut';
import withLinks from './plugins/link';

import editorController from './editorController';
import HoverToolbar from './toolbar/Hover';
import decorater from './decorater';
import elementRenderer from './elementRenderer';
import leafRenderer from './leafRenderer';
//import { ContextIcon } from './toolbar/Helper';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code'
};

export const SLATE_MD_EMPTY_VALUE = [{ type: 'paragraph', children: [{ text: '' }] }];

const MarkdownEditor = ({ value, onChange, containerRef, onKeyDown, autoFocus = true, initFocus, ...rest }) => {
    const renderElement = useCallback(props => elementRenderer(props), []);
    const renderLeaf = useCallback(props => leafRenderer(props), []);
    const editor = useMemo(
        () => withHtml(withShortcut(withImages(withLinks(withHistory(withReact(createEditor())))))),
        []
    );

    useEffect(() => {
        if (editor && initFocus) {
            Transforms.select(editor, Editor.end(editor, []));
            ReactEditor.focus(editor);
        }
    }, [editor, initFocus]);

    const handleKeyDown = event => {
        onKeyDown(event);
        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault();
                editorController.toggleMark(editor, HOTKEYS[hotkey]);
            }
        }
    };

    const handleOnChange = value => {
        onChange(value);
    };

    return (
        <>
            <Slate editor={editor} value={value} onChange={handleOnChange} {...rest} id={'slate-markdown'}>
                <HoverToolbar containerRef={containerRef} />
                <Editable
                    style={rest.style}
                    className={rest.className}
                    placeholder=""
                    autoFocus={autoFocus}
                    spellCheck
                    decorate={decorater}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={handleKeyDown}
                />
            </Slate>
        </>
    );
};

export default React.memo(MarkdownEditor);
