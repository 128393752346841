import React from 'react';

function LogoSVG({ color = '#77B32B', width = 21, height = 14, ...other }) {
    return (
        <svg width={width} height={height} viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.60416 0L3.0918 3.54245L8.10442 6.62953L10.3889 4.83613C11.6192 3.87016 11.4962 2.39651 10.1142 1.5455L7.60416 0Z"
                fill="#63C5EA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.10495 6.6295L8.10749 6.63103L10.6164 8.17597L6.14466 11.6866L3.63575 10.1415L1.12386 8.59436C-0.259857 7.74251 -0.382983 6.26775 0.849828 5.29996L3.08993 3.54102L3.09233 3.54255L8.10495 6.6295ZM8.6607 13.2276L6.15234 11.6829L10.6166 8.17814V13.8004C9.91565 13.7636 9.2264 13.5759 8.6607 13.2276Z"
                fill="#8C4DD6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9026 6.38316L10.6182 8.17671L10.6171 8.17754L10.6152 8.17894V13.8012C10.6946 13.8054 10.7742 13.8076 10.8536 13.8079H10.8848C11.8087 13.8053 12.7287 13.5402 13.3917 13.0198L15.6284 11.2635L15.6295 11.2624L17.9138 9.46927L17.9151 9.46843L12.9037 6.38232L12.9026 6.38316Z"
                fill="#E94C8B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8755 4.42442L17.3645 2.87809L14.8557 1.33301L12.6243 3.0847C11.3928 4.05179 11.5155 5.52655 12.8993 6.37896L12.9029 6.38077L12.9046 6.38189L17.9158 9.46814L17.919 9.47009L20.1495 7.71882C21.3822 6.75131 21.2589 5.27627 19.8755 4.42442Z"
                fill="#FCB424"
            />
        </svg>
    );
}

export default React.memo(LogoSVG);
