import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import { makeStyles } from '@material-ui/core/styles';
import { useSelectedGroupDetail } from 'hooks/permission/group';
import ButtonBase from 'components/button/Base';
import ModalBase from 'components/modal/Base';
import InviteMemberToGroup from './InviteMemberToGroup';
// import CreateGroup from './createGroup';
import AdminGroupAvatar from './AdminGroupAvatar';
import TranslatorGroupAvatar from './TranslatorGroupAvatar';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import OverLimitTag from 'payment/components/tags/Overlimit';
import ToolTipClickContent from 'components/tooltip/ToolTipClickContent';
import { DISABLED_OPACITY } from 'const/style';
import { useHistory, useParams } from 'react-router-dom';
import { AVATAR_EXTENSIONS, GROUP_ADMIN_TYPES } from 'const';
import Dialog from 'components/dialog/Dialog';
import Upload from 'components/upload/Base';
import { useTranslation } from 'react-i18next';
import { getGroupAvatarUrl, readFile } from 'utils/images';
import { useDispatch } from 'react-redux';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import Avatar from 'components/avatar/User';
import AvatarCropper from 'components/avatar/Cropper';
import Spinner from 'components/spinner/Base';
import * as groupActions from '../actions/group';

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: 24
    },
    groupAdminIcon: {
        border: `1px solid #2d97f2`,
        backgroundColor: '#2d97f2',
        borderRadius: '100%'
    },
    defaultText: {
        fontSize: 14,
        color: theme.colors.lightGreyBlue,
        fontWeight: 'normal',
        fontStyle: 'normal'
    },
    ml2: {
        marginLeft: theme.spacing(2)
    },
    mt2: {
        marginTop: theme.spacing(4)
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    learnMore: {
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 600
        }
    },

    spinnerWrapper: {
        position: 'absolute',
        width: 48,
        height: 48,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fileInput: {
        borderRadius: '50%',
        width: 48,
        height: 48,
        position: 'relative',
        padding: 0,
        '& .hoverWrapper': {
            position: 'absolute',
            width: 48,
            height: 48,
            borderRadius: '50%',
            opacity: 0.6,
            background: 'black',
            display: 'none',
            '& path': {
                fill: theme.colors.white
            }
        },
        '&:hover': {
            '& .hoverWrapper': {
                display: 'block'
            }
        },
        overflow: 'hidden',
        outline: 'none'
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    }
}));

function GroupTeam({ isNotSystemGroupAndCantEditGroup }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useParams();

    const selectedGroupDetail = useSelectedGroupDetail();
    const [openInviteModal, setOpenInviteModal] = React.useState(false);
    const [selectedImg, setSelectedImg] = React.useState(null);
    const imageUrl = React.useMemo(() => selectedGroupDetail?.logoUrl, [selectedGroupDetail]);
    const [thumbnail, setThumbnail] = React.useState(imageUrl);
    const [isUploading, setIsUploading] = React.useState(false);

    React.useEffect(() => {
        if (imageUrl) {
            setThumbnail(getGroupAvatarUrl(imageUrl));
        }
    }, [imageUrl]);

    // const [openEditAccessModal, setOpenEditAccessModal] = React.useState(false);
    // const checkedListDefault = useSelectedGroupAccessIds();

    const openInviteModalHandler = () => {
        setOpenInviteModal(true);
    };
    const closeInviteModalHandler = () => {
        setOpenInviteModal(false);
    };

    // const openEditAccessModalHandler = () => {
    //     setOpenEditAccessModal(true);
    // };

    // const closeEditAccessModalHandler = () => {
    //     setOpenEditAccessModal(false);
    // };

    const onRejectedHandler = React.useCallback(
        rejectedFiles => {
            dispatch(groupActions.uploadGroupException({ error: '' }));
        },
        [dispatch]
    );

    const onDropAcceptedHandler = React.useCallback(async acceptedFiles => {
        const file = acceptedFiles?.[0];

        const imageDataUrl = await readFile(file);

        setSelectedImg(imageDataUrl);
    }, []);

    const onAvatarSave = React.useCallback(
        file => {
            setIsUploading(true);
            setSelectedImg(null);
            if (file) {
                if (thumbnail) {
                    window.URL.revokeObjectURL(thumbnail);
                }
                setThumbnail(window.URL.createObjectURL(file));
            }
            dispatch(
                groupActions.uploadGroupAvatar({
                    file,
                    groupId,
                    successCallback: () => {
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                    }
                })
            );
        },
        [dispatch, groupId, thumbnail]
    );

    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    const isSystemGroup = React.useMemo(() => {
        return selectedGroupDetail?.systemGroup;
    }, [selectedGroupDetail]);

    const handleLearnMore = () => {
        window.open(`https://www.gridly.com/`, '_blank');
    };

    const isTranslatorGroup = React.useMemo(() => {
        return selectedGroupDetail?.systemGroup && selectedGroupDetail?.type === GROUP_ADMIN_TYPES.NORMAL;
    }, [selectedGroupDetail]);

    return (
        <Grid className={classes.root} container justify="space-between" alignItems="center">
            <Grid item>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Upload
                            onDropAccepted={onDropAcceptedHandler}
                            onDropRejected={onRejectedHandler}
                            className={`${classes.fileInput} `}
                            accept={AVATAR_EXTENSIONS}
                            hideBorder={true}
                        >
                            {isSystemGroup && selectedGroupDetail?.type === GROUP_ADMIN_TYPES.ADMIN && (
                                <>
                                    {selectedGroupDetail?.logoUrl ? (
                                        <Avatar className={classes.avatar} size={48} src={thumbnail} />
                                    ) : (
                                        <AdminGroupAvatar size={48} />
                                    )}
                                </>
                            )}
                            {isTranslatorGroup && (
                                <>
                                    {selectedGroupDetail?.logoUrl ? (
                                        <Avatar className={classes.avatar} size={48} src={thumbnail} />
                                    ) : (
                                        <TranslatorGroupAvatar size={48} />
                                    )}
                                </>
                            )}
                            {!isSystemGroup && (
                                <>
                                    {' '}
                                    {selectedGroupDetail?.logoUrl ? (
                                        <AvatarBase size={48} src={getGroupAvatarUrl(selectedGroupDetail?.logoUrl)} />
                                    ) : (
                                        <AvatarBase size={48}>{selectedGroupDetail.name.slice(0, 2)}</AvatarBase>
                                    )}
                                </>
                            )}

                            {!isUploading && (
                                <Grid item className={`hoverWrapper`}>
                                    <div className={classes.center}>
                                        <IconCameraSVG style={{ width: 28 }} />
                                    </div>
                                </Grid>
                            )}
                            {isUploading && (
                                <Grid
                                    item
                                    className={`${classes.spinnerWrapper} ${isUploading ? classes.uploading : ''}`}
                                >
                                    <Spinner size={18} thick={3} />
                                </Grid>
                            )}

                            {!thumbnail && <IconCameraSVG />}
                        </Upload>
                        <Dialog
                            open={!!selectedImg}
                            onClose={e => {
                                setSelectedImg(null);
                            }}
                        >
                            {selectedImg && <AvatarCropper src={selectedImg} shape={'round'} onSave={onAvatarSave} />}
                        </Dialog>
                    </Grid>
                    {/* <Grid item>
                        {!isSystemGroup && (
                            <AvatarBase size={48}>{(selectedGroupDetail?.name || '').slice(0, 2)}</AvatarBase>
                        )}
                    </Grid> */}

                    <Grid item>
                        <Typography variant="h4" component="div">
                            {selectedGroupDetail?.name}{' '}
                            {isSystemGroup ? <span className={classes.defaultText}>({t('default_group')})</span> : ''}
                        </Typography>
                    </Grid>
                    {isNotSystemGroupAndCantEditGroup && (
                        <Grid item className={classes.ml2}>
                            <ToolTipClickContent
                                title={
                                    <Grid direction="column" container className={classes.explainWarning}>
                                        <Grid item>
                                            <Typography variant="body1">{t('group_team_locked')}</Typography>
                                        </Grid>

                                        <Grid item className={classes.mt2}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography
                                                        onClick={handleLearnMore}
                                                        className={classes.learnMore}
                                                        variant="body1"
                                                    >
                                                        {t('global_learn_more')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <ButtonBase width={120} variant="contained" onClick={handleUpgrade}>
                                                        <UpgradeSVG className={classes.upgradeIcon} />{' '}
                                                        {t('global_upgrade')}
                                                    </ButtonBase>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            >
                                <Grid item style={{ cursor: 'pointer' }}>
                                    <OverLimitTag title={'View only'} />
                                </Grid>
                            </ToolTipClickContent>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    className={isNotSystemGroupAndCantEditGroup ? classes.disabled : ''}
                >
                    <Grid item>
                        <ButtonBase
                            onClick={openInviteModalHandler}
                            variant={isNotSystemGroupAndCantEditGroup ? 'contained' : 'outlined'}
                            width={140}
                        >
                            + {t('group_team_add_member')}
                        </ButtonBase>
                    </Grid>
                    {/* {(!isSystemGroup || isTranslatorGroup) && (
                        <Grid item>
                            <ButtonBase
                                onClick={openEditAccessModalHandler}
                                variant={isNotSystemGroupAndCantEditGroup ? 'contained' : 'outlined'}
                                width={200}
                            >
                                {t('group_team_edit_access')}
                            </ButtonBase>
                        </Grid>
                    )} */}
                </Grid>
            </Grid>
            <ModalBase open={openInviteModal} handleClose={closeInviteModalHandler}>
                <InviteMemberToGroup handleClose={closeInviteModalHandler} />
            </ModalBase>
            {/* <Dialog maxWidth={false} open={openEditAccessModal} onClose={closeEditAccessModalHandler}>
                <CreateGroup
                    onClose={closeEditAccessModalHandler}
                    defaultGroupName={selectedGroupDetail?.name}
                    checkedListDefaultIds={checkedListDefault?.map(dbAccess => dbAccess.id)}
                    checkedListDefault={checkedListDefault}
                    isEdit={true}
                />
            </Dialog> */}
        </Grid>
    );
}

export default React.memo(GroupTeam);
