import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import { isKbEnter, isKbEscape } from 'utils/keyboard';
import { trim } from 'lodash';
import { useDispatch } from 'react-redux';
import InputText from 'components/inputs/InputText';
import ListItem from 'components/list/Item';
import CopySourceSVG from 'assets/images/svg/CopySourceSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ShareSVG from 'assets/images/svg/ShareSVG';
import {
    POPUP_PADDING_TOP_BOTTOM,
    SELECTION_LIST_ITEM_MIN_HEIGHT,
    SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
    SPACING_LIST_NAME_WITH_ICON
} from 'const/style';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'hooks/auth';
import { useParams } from 'react-router-dom';
import * as advancedSearchActions from 'advancedSearch/actions';

const useStyles = makeStyles(theme => ({
    root: {
        width: 220
    },
    name: {
        padding: `0px ${POPUP_PADDING_TOP_BOTTOM}px`,
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    flex: {
        display: 'flex'
    },
    delete: {
        marginTop: 8,
        cursor: 'pointer',
        display: 'flex',
        height: SELECTION_LIST_ITEM_MIN_HEIGHT,
        paddingLeft: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            fontSize: 20,
            marginRight: SPACING_LIST_NAME_WITH_ICON
        },
        '& path': {
            fill: theme.colors.brightRed
        },
        '& p': {
            color: theme.colors.brightRed
        }
    }
}));

function PopupItem({ name, advancedSearchId, onClickAway, userId, onDelete, onOpenNew, onShare }) {
    const [newName, setNewName] = React.useState(name || ``);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const rootRef = React.useRef();
    const currentUser = useCurrentUser();
    const { workspaceId } = useParams();

    const isOwner = React.useMemo(() => {
        return currentUser?.id === userId;
    }, [currentUser, userId]);

    useClickAwaitListener(rootRef, e => {
        clickAwayHandler();
    });

    // const onShare = React.useCallback(() => {
    //     console.log('onShare');
    // }, []);

    const handleClickAway = React.useCallback(() => {
        onClickAway && onClickAway();
    }, [onClickAway]);

    const clickAwayHandler = React.useCallback(() => {
        if (trim(newName) === '') {
            handleClickAway();
            return;
        }
        if (newName === name) {
            handleClickAway();
            return;
        }

        dispatch(advancedSearchActions.updateNewName({ aId: advancedSearchId, name: newName }));

        dispatch(
            advancedSearchActions.updateAdvancedSearch({
                wsId: workspaceId,
                advancedSearchId: advancedSearchId,
                body: {
                    name: newName
                },
                success: () => {
                    handleClickAway();
                },
                error: error => {
                    console.log('error update');
                    dispatch(advancedSearchActions.updateNewName({ aId: advancedSearchId, name }));
                }
            })
        );

        console.log('update');
        handleClickAway();
    }, [name, newName, handleClickAway, dispatch, advancedSearchId, workspaceId]);

    const handleKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                onClickAway();
                return;
            }
            if (!isKbEnter(e)) return;
            clickAwayHandler();
        },
        [clickAwayHandler, onClickAway]
    );

    const handleChange = React.useCallback(e => {
        setNewName(e.target.value);
    }, []);

    return (
        <Grid ref={rootRef} container className={classes.root} direction="column">
            {isOwner && (
                <Grid item className={classes.name}>
                    <InputText
                        name="name"
                        value={newName}
                        onChange={handleChange}
                        placeholder={t('global_name')}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        className={classes.input}
                        autoSelect={true}
                        inputProps={{
                            maxLength: 255
                        }}
                    />
                </Grid>
            )}
            <Grid item onClick={onOpenNew}>
                <ListItem icon={<CopySourceSVG size={16} color={theme.colors.steel} />} name="Save as new" />
            </Grid>
            <Grid item onClick={onShare}>
                <ListItem icon={<ShareSVG size={16} color={theme.colors.steel} />} name="Share" />
            </Grid>
            {isOwner && (
                <>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item id={`dbDlBtn`} className={`${classes.delete} `} onClick={onDelete}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item className={classes.flex}>
                                <DeleteSVG />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{t('global_delete')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default PopupItem;
