import React from 'react';

function LitePlanSVG({ ...other }) {
    return (
        <svg {...other} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#5DCDE3" fillOpacity="0.8" />
            <path
                d="M26.5293 35.6171C26.2381 35.6171 26.002 35.381 26.002 35.0897V32.4785C26.002 32.1873 26.2381 31.9512 26.5293 31.9512C26.8205 31.9512 27.0567 32.1873 27.0567 32.4785V35.0897C27.0567 35.3809 26.8205 35.6171 26.5293 35.6171Z"
                fill="#6B888D"
            />
            <path
                d="M20.8633 35.6171C20.572 35.6171 20.3359 35.381 20.3359 35.0897V32.4785C20.3359 32.1873 20.572 31.9512 20.8633 31.9512C21.1545 31.9512 21.3906 32.1873 21.3906 32.4785V35.0897C21.3906 35.3809 21.1545 35.6171 20.8633 35.6171Z"
                fill="#6B888D"
            />
            <path
                d="M25.4202 42.0012H22.0134C20.5045 42.0012 19.2812 40.778 19.2812 39.2691V35.7447C19.2812 35.383 19.5744 35.0898 19.9361 35.0898H27.4974C27.859 35.0898 28.1522 35.383 28.1522 35.7447V39.2691C28.1523 40.778 26.9291 42.0012 25.4202 42.0012Z"
                fill="#226FAF"
            />
            <path
                d="M28.153 35.7639V39.1808C28.153 40.7373 26.8916 41.9994 25.3351 41.9994H22.4121C23.9686 41.9994 25.2307 40.7373 25.2307 39.1808V35.7639C25.2307 35.3903 24.9282 35.0879 24.5554 35.0879H27.4778C27.8505 35.0879 28.153 35.3904 28.153 35.7639Z"
                fill="#124774"
            />
            <path
                d="M35.7143 17.9978C35.7143 11.3451 30.2997 5.95716 23.637 6.00026C17.0515 6.04301 11.7524 11.3499 11.7189 17.9355C11.6983 21.9861 13.6854 25.5735 16.7426 27.7609C17.9806 28.6468 18.7061 30.0832 18.7061 31.6056V31.6058C18.7061 32.0418 19.0595 32.3952 19.4955 32.3952H27.9376C28.3736 32.3952 28.727 32.0417 28.727 31.6058C28.727 30.0771 29.463 28.642 30.7049 27.7506C33.7382 25.5734 35.7143 22.0166 35.7143 17.9978Z"
                fill="#B091E5"
            />
            <path
                d="M35.7159 17.9977C35.7159 22.0167 33.7401 25.5737 30.7068 27.7503C30.0859 28.1957 29.5912 28.7774 29.2525 29.4389C28.9131 30.1004 28.7288 30.8416 28.7288 31.6054C28.7288 32.0414 28.3756 32.3953 27.9397 32.3953H25.5201C25.956 32.3953 26.3092 32.0414 26.3092 31.6054C26.3092 30.8417 26.4934 30.1004 26.8329 29.4389C27.1716 28.7774 27.6663 28.1957 28.2871 27.7503C31.3204 25.5737 33.2969 22.0167 33.2969 17.9977C33.2969 11.7796 28.5656 6.66681 22.5078 6.06044C22.8799 6.02275 23.2571 6.00243 23.6386 6.00025C30.3014 5.95743 35.7159 11.3451 35.7159 17.9977Z"
                fill="#9371CC"
            />
            <path
                opacity="0.5"
                d="M21.8971 32.394C21.1693 29.2673 20.6992 24.3116 20.6992 18.7331C20.6992 13.8059 21.066 9.36478 21.653 6.24045C21.653 6.24045 22.9619 5.85338 25.7696 6.17393C26.3644 9.3015 26.7365 13.7709 26.7365 18.7328C26.7365 24.3119 26.2657 29.2673 25.5383 32.3941L21.8971 32.394Z"
                fill="white"
            />
            <path
                d="M26.7353 18.7345C26.7353 24.3137 26.2645 29.269 25.5371 32.3959H23.7216C24.4491 29.269 24.9199 24.3137 24.9199 18.7345C24.9199 13.7726 24.5478 9.30324 23.953 6.17568C23.5425 6.12927 23.1639 6.09735 22.8164 6.07703C23.4924 6.03063 24.4702 6.02774 25.7685 6.17568C26.3632 9.30324 26.7353 13.7726 26.7353 18.7345Z"
                fill="#9371CC"
            />
            <path
                d="M38.0106 22.2333C38.0106 22.2325 38.0107 22.2318 38.0107 22.231C38.0107 20.8532 36.8937 19.7363 35.516 19.7363C34.1382 19.7363 33.0213 20.8532 33.0213 22.231C33.0213 22.2318 33.0214 22.2325 33.0214 22.2333C31.764 22.2802 30.75 23.3225 30.75 24.5908C30.75 25.8887 31.8119 26.9506 33.1098 26.9506H37.9221C39.22 26.9506 40.282 25.8887 40.282 24.5908C40.282 23.3225 39.2678 22.2802 38.0106 22.2333Z"
                fill="white"
            />
            <path
                d="M14.2606 33.4969C14.2606 33.4962 14.2607 33.4955 14.2607 33.4947C14.2607 32.1169 13.1437 31 11.766 31C10.3882 31 9.2713 32.1169 9.2713 33.4947C9.2713 33.4955 9.27137 33.4962 9.27137 33.4969C8.01404 33.5439 7 34.5862 7 35.8544C7 37.1523 8.06193 38.2143 9.35975 38.2143H14.1721C15.47 38.2143 16.532 37.1523 16.532 35.8544C16.532 34.5862 15.5178 33.5439 14.2606 33.4969Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(LitePlanSVG);
