import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import * as roleConst from 'auth/roleConst';
import PopperMenu from 'components/menus/Popper';
import LocalizationSVG from 'components/svg-icon/LocalizationSVG';
import { useRole } from 'hooks/auth/role';
import { useIsCanExportImportLocalization } from 'hooks/gridUI';
import { useParams } from 'react-router-dom';
import ExportTab from '../localization/export/ExportTab';
import ImportLocalizationByType from '../localization/import/ImportLocalizationByType';
import LocalizationPopup from '../LocalizationPopup';
import Dialog from 'components/dialog/Dialog';
import { useIsJsonGrid, useIsPoGrid, useIsVerticalJSON } from 'hooks/grid';
import { useTranslation } from 'react-i18next';
import SynchronizeTM from '../localization/synchronizeTM';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    localizationButton: {
        background: theme.colors.ghostwhite,
        border: `1px solid ${theme.colors.lightGrey}`,
        height: 40,
        maxHeight: 40,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        '& > span': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    spacing: {
        marginBottom: theme.spacing(1)
    },
    dlFlex: {
        display: 'flex'
    },
    controlsGroup: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    }
}));

const ManageLocalization = ({ onOpenTranslationOverview, onOpenGridTypeSetting }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isCanExportImportLocalization = useIsCanExportImportLocalization();
    const { dbId, gridId, viewId } = useParams();
    const isJsonGrid = useIsJsonGrid({ dbId, gridId });
    const isVerticalJSON = useIsVerticalJSON({ dbId, gridId });
    const isPoGrid = useIsPoGrid({ dbId, gridId });
    const roles = useRole();
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];

    const [localizationAnchorEl, setLocalizationAnchorEl] = React.useState(null);
    const [openExportLocalization, setOpenExportLocalization] = React.useState(false);
    const [openImportLocalization, setOpenImportLocalization] = React.useState(false);
    const [openSynchronizeTM, setOpenSynchronizeTM] = React.useState(false);

    const handleLocalizationClickAway = React.useCallback(() => {
        setLocalizationAnchorEl(null);
    }, []);

    const handleLocalizationClick = React.useCallback(
        event => {
            setLocalizationAnchorEl(localizationAnchorEl ? null : event.currentTarget);
        },
        [localizationAnchorEl]
    );

    const openLocalizationImportHandler = React.useCallback(() => {
        handleLocalizationClickAway();
        setOpenImportLocalization(true);
    }, [handleLocalizationClickAway]);

    const closeLocalizationImportHandler = React.useCallback(() => setOpenImportLocalization(false), []);

    const openLocalizationExportHandler = React.useCallback(() => {
        handleLocalizationClickAway();
        setOpenExportLocalization(true);
    }, [handleLocalizationClickAway]);

    const closeLocalizationExportHandler = React.useCallback(() => setOpenExportLocalization(false), []);

    const openSynchronizeTMHandler = React.useCallback(() => {
        handleLocalizationClickAway();
        setOpenSynchronizeTM(true);
    }, [handleLocalizationClickAway]);

    const closeSynchronizeTMHandler = React.useCallback(() => setOpenSynchronizeTM(false), []);

    return (
        <div id="toolbarImportActions" className={`${classes.controlsGroup}`}>
            {(isCanExportImportLocalization || isVerticalJSON) && accessEditTranslation === roleConst.FULL && (
                <Grid item id={`localization-panel-trigger`} className={classes.menuItem}>
                    <ButtonBase
                        onClick={handleLocalizationClick}
                        className={`${classes.localizationButton} ${classes.addButton}`}
                        tr-dt="Open Localization Export & Import Setting"
                        variant="outlined"
                    >
                        <Grid container alignItems="center" wrap="nowrap" direction="row">
                            <Grid item className={classes.dlFlex}>
                                <LocalizationSVG height={18} width={18} color={theme.colors.darkness} />
                            </Grid>
                            <Grid
                                item
                                style={{
                                    paddingRight: theme.spacing(2),
                                    paddingLeft: theme.spacing(2)
                                }}
                            >
                                <p className="body2">{t('toolbar_localization_dropdown')}</p>
                            </Grid>
                            <Grid item className={classes.dlFlex}>
                                <ArrowDownSVG color={theme.colors.darkness} />
                            </Grid>
                        </Grid>
                    </ButtonBase>
                </Grid>
            )}
            {localizationAnchorEl && (
                <PopperMenu
                    id={'popper-localization'}
                    placement={'bottom-start'}
                    anchorEl={localizationAnchorEl}
                    handleClickAway={handleLocalizationClickAway}
                >
                    <LocalizationPopup
                        isJsonGrid={isJsonGrid}
                        isPoGrid={isPoGrid}
                        handleImportLocalization={openLocalizationImportHandler}
                        handleExportLocalization={openLocalizationExportHandler}
                        handleSynchronizeTM={openSynchronizeTMHandler}
                        hideOtherTranlationColumnsHandler={() => {
                            handleLocalizationClickAway();
                        }}
                        handleClose={handleLocalizationClickAway}
                        onOpenTranslationOverview={() => {
                            handleLocalizationClickAway();
                            onOpenTranslationOverview();
                        }}
                        onOpenGridTypeSetting={() => {
                            onOpenGridTypeSetting();
                            handleLocalizationClickAway();
                        }}
                    />
                </PopperMenu>
            )}
            <Dialog maxWidth={false} open={openExportLocalization} onClose={closeLocalizationExportHandler}>
                <ExportTab onClose={closeLocalizationExportHandler} />
            </Dialog>
            <ImportLocalizationByType
                gridId={gridId}
                viewId={viewId}
                dbId={dbId}
                open={openImportLocalization}
                onClose={closeLocalizationImportHandler}
            />

            <Dialog maxWidth={false} open={openSynchronizeTM} onClose={closeSynchronizeTMHandler}>
                <SynchronizeTM onClose={closeSynchronizeTMHandler} />
            </Dialog>
        </div>
    );
};

export default React.memo(ManageLocalization);
