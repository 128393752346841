import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Popper from './Popper';

const useStyles = makeStyles(theme => ({
    trigger: {
        cursor: 'pointer'
    },
    popper: {
        top: '3px !important'
    }
}));

function TriggerMenu({ className, children, contentRenderer, container }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleTriggerClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handlePopperClick = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div className={className}>
            <div className={classes.trigger} onClick={handleTriggerClick}>
                {children}
            </div>

            {anchorEl && (
                <Popper
                    className={classes.popper}
                    anchorEl={anchorEl}
                    onClick={handlePopperClick}
                    handleClickAway={handleClickAway}
                    container={container}
                >
                    {contentRenderer && contentRenderer()}
                </Popper>
            )}
        </div>
    );
}

export default React.memo(TriggerMenu);
