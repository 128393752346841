import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import { Grid, Popper, Paper } from '@material-ui/core';
import { INPUT_QUICK_FILTER_HEIGHT } from 'const/style';
import { getNodesByPath } from 'utils/gridUI/pathTag';
import FolderPath from 'components/folderPath/Base';
import Option from 'components/option/Base';
import { PATH_TAG } from 'const/columnTypes';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { OPERATOR } from 'gridUI/conditions';
import { DEFAULT_PATH_TAG_COLOR } from 'utils/color';
import isEmpty from 'lodash/isEmpty';
import Tooltip from 'components/tooltip/Base';
import { _readClipboard } from 'utils/clipboard';
import { enqueueSnackbar } from 'notifier/actions';
import classNames from 'classnames';

function PathTagQuickFilter({ column, columnId, isSelectionActive, quickFilters, pathRef, isDisabled, t, ...rest }) {
    const [selectedPath, setSelectedPath] = useState(quickFilters?.[columnId]?.value || '');
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const ref = React.useRef();

    const data = React.useMemo(() => {
        return getNodesByPath({ pathRef, path: quickFilters?.[columnId]?.value });
    }, [columnId, quickFilters, pathRef]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        dispatch(gridActions.resetCellStatus());
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleOnChange = (newPath, newFullPathId) => {
        if (newPath === quickFilters?.[columnId]?.value) {
            handleClickAway();
            return;
        }

        setSelectedPath(newPath);
        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: newPath,
                type: PATH_TAG,
                operator: OPERATOR.startsWith,
                fullPathId: newFullPathId
            })
        );

        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
        handleClickAway();
    };

    const handleClearFilter = e => {
        stopPropagation(e);
        dispatch(gridActions.resetSelection());
        dispatch(
            gridActions.QuickFilterChange({
                columnId,
                value: '',
                type: PATH_TAG,
                operator: OPERATOR.startsWith
            })
        );
        setSelectedPath();
        dispatch(
            gridActions.fetchRecordsWithFiltersAndSorts({
                errorCallback: () => {
                    console.log('failed to filter');
                },
                successCallback: () => {
                    console.log('filter successfully');
                }
            })
        );
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const renderPathtag = useCallback(
        (isTooltip = false) => {
            return data?.map((node, index) => {
                return (
                    <React.Fragment key={node?.id}>
                        <Option
                            className={classNames({
                                '!bg-[#E8F4F8]': isTooltip
                            })}
                            size="small"
                            label={node?.name}
                            color={node?.customProperties?.color || DEFAULT_PATH_TAG_COLOR}
                        />
                        {index !== data?.length - 1 && (
                            <p
                                className={classNames('mx-1 body1 inline', {
                                    '!text-white': isTooltip
                                })}
                            >
                                /
                            </p>
                        )}
                    </React.Fragment>
                );
            });
        },
        [data]
    );

    const pasteHandler = React.useCallback(
        async e => {
            let res = await _readClipboard(e);
            const status = res.status;
            if (!status) {
                console.log('pasted failed');
                return;
            }

            const content = res.content;
            const path = content?.split('/');
            const nodes = getNodesByPath({ pathRef, path: content });
            const validNodes = [];
            let failedMessage = ``;

            for (const [key, node] of nodes.entries()) {
                if (!node) {
                    failedMessage = `Can not found "${path?.slice(key)?.join('/')}"`;
                    break;
                }
                validNodes.push(node);
            }

            if (failedMessage) {
                dispatch(
                    enqueueSnackbar({
                        message: failedMessage,
                        type: 'info'
                    })
                );
            }

            const validPath = validNodes?.map(node => node?.name)?.join('/');
            if (validPath) {
                setSelectedPath(validPath);
            }
        },
        [pathRef, dispatch]
    );

    React.useEffect(() => {
        if (anchorEl) {
            window.addEventListener('paste', pasteHandler, true);
        }

        return () => {
            window.removeEventListener('paste', pasteHandler, true);
        };
    }, [pasteHandler, dispatch, anchorEl]);

    return (
        <Grid>
            <div
                ref={ref}
                onClick={handleClick}
                className={classNames(
                    `body2 text-brand-main bg-grey-pale h-[${INPUT_QUICK_FILTER_HEIGHT}px] overflow-hidden pl-2 flex flex-nowrap items-center rounded relative border border-app`,
                    {
                        disabled: isDisabled,
                        'border border-brand-main': anchorEl
                    }
                )}
            >
                {isEmpty(data) && (
                    <span className="text-grey-mid body2 flex-1 line-clamp-1">{t(`global_label_select_options`)}</span>
                )}
                {!isEmpty(data) && (
                    <Tooltip title={<Grid item>{renderPathtag(true)}</Grid>}>
                        <Grid item className={'line-clamp-1'}>
                            {renderPathtag()}
                        </Grid>
                    </Tooltip>
                )}
                {data?.length > 0 && (
                    <div
                        className={'absolute right-0.5 top-0 bottom-0 flex items-center cursor-pointer'}
                        onClick={handleClearFilter}
                    >
                        <CloseIconSVG />
                    </div>
                )}
            </div>
            <Popper className={`z-[1500]`} id={'path-tag-filter'} open={open} anchorEl={anchorEl} transition>
                <Paper className={'min-w-[200px] p-2.5 bg-white shadow'}>
                    <FolderPath
                        handleClickAway={handleClickAway}
                        defaultPath={selectedPath}
                        onChange={handleOnChange}
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(PathTagQuickFilter);
