import React from 'react';

const AutomationTriggerSVG = ({ color = '#78778B', ...rest }) => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_17_2000)">
                <path
                    d="M2.31591 12.857C2.45065 12.9863 2.63003 13.0587 2.81677 13.0592C3.0034 13.0581 3.18233 12.9847 3.316 12.8545C3.44967 12.7242 3.52766 12.5472 3.5336 12.3607C3.53064 12.1704 3.45309 11.9889 3.31764 11.8553C2.43381 11.0207 1.82313 9.93835 1.56582 8.75034C1.30851 7.56233 1.41662 6.32426 1.87594 5.19883C2.33526 4.0734 3.1243 3.11324 4.13939 2.44453C5.15448 1.77582 6.34812 1.42983 7.56347 1.45204C7.66212 1.45995 7.76134 1.44736 7.85489 1.41504C7.94843 1.38272 8.03427 1.33139 8.10699 1.26426C8.17972 1.19714 8.23776 1.11568 8.27746 1.02503C8.31715 0.934368 8.33765 0.836474 8.33765 0.737505C8.33765 0.638537 8.31715 0.540641 8.27746 0.449983C8.23776 0.359325 8.17972 0.277868 8.10699 0.210745C8.03427 0.143621 7.94843 0.0922843 7.85489 0.0599678C7.76134 0.0276513 7.66212 0.0150559 7.56347 0.0229733C5.56668 0.0194806 3.64993 0.807661 2.2332 2.21482C1.52574 2.90575 0.963689 3.73126 0.580158 4.64274C0.196627 5.55422 -0.000631542 6.53323 1.51893e-06 7.52211C0.00544495 8.53719 0.226279 9.53956 0.647905 10.4629C1.04861 11.3586 1.61449 12.1708 2.31591 12.857Z"
                    fill={color}
                />
                <path
                    d="M16.7949 13.776L15.8116 12.8202C15.6966 12.7015 15.5439 12.6265 15.3797 12.6081C15.2155 12.5896 15.05 12.6288 14.9115 12.7189C14.773 12.8091 14.6702 12.9446 14.6206 13.1022C14.5711 13.2599 14.5779 13.4298 14.6399 13.583H13.0546C12.875 13.5974 12.7074 13.6789 12.5852 13.8114C12.4629 13.9438 12.3951 14.1173 12.3951 14.2975C12.3951 14.4777 12.4629 14.6513 12.5852 14.7837C12.7074 14.9161 12.875 14.9976 13.0546 15.0121H14.6812C14.6342 15.1207 14.6147 15.2393 14.6247 15.3573C14.6347 15.4753 14.6737 15.589 14.7384 15.6882C14.803 15.7874 14.8912 15.8691 14.9951 15.9259C15.099 15.9827 15.2153 16.0129 15.3337 16.0138C15.5232 16.0089 15.7031 15.9297 15.8346 15.7932L16.8041 14.7915C16.8704 14.7243 16.9227 14.6447 16.9581 14.5572C16.9935 14.4698 17.0112 14.3762 17.0104 14.2818C17.0095 14.1875 16.9901 14.0942 16.9531 14.0074C16.9161 13.9206 16.8624 13.842 16.7949 13.776Z"
                    fill={color}
                />
                <path
                    d="M15.0672 7.49913C15.0652 6.2918 14.7717 5.10281 14.2117 4.0332C13.6517 2.9636 12.8418 2.04502 11.8507 1.35554C11.9299 1.29835 11.9957 1.22467 12.0437 1.13957C12.0949 1.06012 12.1294 0.971091 12.1452 0.877889C12.161 0.784687 12.1577 0.689249 12.1356 0.597355C12.1129 0.50657 12.0718 0.421402 12.0149 0.347111C11.958 0.27282 11.8865 0.210983 11.8047 0.165418C11.7238 0.117543 11.6342 0.0863708 11.5411 0.0737428C11.4479 0.0611148 11.3532 0.0672878 11.2625 0.0918973L9.93912 0.445717C9.84726 0.470224 9.76115 0.512646 9.68574 0.570545C9.61033 0.628444 9.54711 0.700678 9.49971 0.783093C9.45231 0.865509 9.42166 0.956481 9.40954 1.05078C9.39742 1.14508 9.40406 1.24084 9.42907 1.33256L9.78289 2.65594C9.81813 2.78451 9.88853 2.90069 9.98617 2.99145C10.0838 3.08222 10.2048 3.14395 10.3356 3.16973C10.4664 3.1955 10.6018 3.1843 10.7266 3.13737C10.8513 3.09044 10.9605 3.00965 11.0419 2.90407C11.1068 2.82066 11.1509 2.723 11.1706 2.61918C12.1983 3.37906 12.9602 4.44374 13.3478 5.6617C13.7353 6.87967 13.7289 8.18885 13.3293 9.40292C12.9297 10.617 12.1573 11.6741 11.1221 12.4238C10.0869 13.1734 8.84162 13.5775 7.56347 13.5784H0.716835C0.618184 13.5705 0.51896 13.5831 0.425417 13.6154C0.331874 13.6477 0.246036 13.699 0.17331 13.7662C0.100583 13.8333 0.0425442 13.9148 0.00284775 14.0054C-0.0368487 14.0961 -0.0573425 14.194 -0.0573425 14.2929C-0.0573425 14.3919 -0.0368487 14.4898 0.00284775 14.5805C0.0425442 14.6711 0.100583 14.7526 0.17331 14.8197C0.246036 14.8868 0.331874 14.9382 0.425417 14.9705C0.51896 15.0028 0.618184 15.0154 0.716835 15.0075H7.56347C9.55401 15.0062 11.4626 14.2147 12.8697 12.8067C14.2768 11.3987 15.0672 9.48967 15.0672 7.49913Z"
                    fill={color}
                />
                <path
                    d="M2.97301 7.49914C2.97301 8.40796 3.2425 9.29637 3.74741 10.052C4.25233 10.8077 4.96998 11.3966 5.80961 11.7444C6.64925 12.0922 7.57316 12.1832 8.46452 12.0059C9.35587 11.8286 10.1746 11.391 10.8173 10.7483C11.4599 10.1057 11.8975 9.28695 12.0748 8.3956C12.2521 7.50424 12.1611 6.58033 11.8133 5.74069C11.4656 4.90105 10.8766 4.1834 10.1209 3.67849C9.36529 3.17358 8.47688 2.90408 7.56807 2.90408C6.34938 2.90408 5.18061 3.3882 4.31887 4.24995C3.45713 5.11169 2.97301 6.28046 2.97301 7.49914ZM7.56807 10.6605C6.943 10.6596 6.33223 10.4735 5.81294 10.1255C5.29366 9.77759 4.88917 9.28353 4.65059 8.70578C4.41202 8.12803 4.35006 7.49252 4.47257 6.87957C4.59507 6.26662 4.89653 5.70375 5.33884 5.26208C5.78115 4.82041 6.34447 4.51977 6.95759 4.39816C7.57072 4.27654 8.20613 4.33942 8.78354 4.57883C9.36094 4.81825 9.85441 5.22345 10.2016 5.74324C10.5488 6.26303 10.7341 6.87407 10.7341 7.49914C10.7335 7.91491 10.651 8.32648 10.4913 8.71037C10.3316 9.09425 10.0979 9.44293 9.80352 9.7365C9.5091 10.0301 9.15974 10.2628 8.7754 10.4213C8.39105 10.5799 7.97924 10.6611 7.56347 10.6605H7.56807Z"
                    fill={color}
                />
                <path
                    d="M8.49166 6.48822L7.08098 7.89891L6.62147 7.4394C6.55316 7.36687 6.47099 7.3088 6.37982 7.26861C6.28865 7.22843 6.19035 7.20696 6.09073 7.20547C5.99111 7.20398 5.8922 7.2225 5.79987 7.25993C5.70754 7.29737 5.62367 7.35296 5.55322 7.42341C5.48277 7.49386 5.42718 7.57774 5.38974 7.67007C5.3523 7.7624 5.33378 7.8613 5.33527 7.96092C5.33676 8.06054 5.35824 8.15885 5.39842 8.25002C5.4386 8.34118 5.49668 8.42336 5.56921 8.49167L6.53876 9.46123C6.67815 9.59928 6.86641 9.67673 7.0626 9.67673C7.25879 9.67673 7.44705 9.59928 7.58644 9.46123L9.52555 7.52211C9.59808 7.4538 9.65616 7.37163 9.69634 7.28046C9.73652 7.18929 9.758 7.09098 9.75949 6.99136C9.76098 6.89174 9.74245 6.79284 9.70502 6.70051C9.66758 6.60818 9.61199 6.52431 9.54154 6.45386C9.47109 6.38341 9.38721 6.32781 9.29488 6.29038C9.20255 6.25294 9.10365 6.23442 9.00403 6.23591C8.90441 6.2374 8.8061 6.25887 8.71494 6.29906C8.62377 6.33924 8.54159 6.39732 8.47328 6.46984L8.49166 6.48822Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_17_2000">
                    <rect width="17.0017" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(AutomationTriggerSVG);
