import React from 'react';

function OverlimitSVG({ color = '#E86E5E', ...other }) {
    return (
        <svg {...other} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#overlimit0)">
                <g clipPath="url(#overlimit1)">
                    <path
                        d="M8.47995 14.6654C12.2723 14.6654 15.3466 11.6806 15.3466 7.9987C15.3466 4.3168 12.2723 1.33203 8.47995 1.33203C4.68759 1.33203 1.61328 4.3168 1.61328 7.9987C1.61328 11.6806 4.68759 14.6654 8.47995 14.6654Z"
                        stroke={color}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.47998 5.33203V7.9987"
                        stroke={color}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.47998 10.668H8.49028"
                        stroke={color}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="overlimit0">
                    <rect width="16.48" height="16" fill="white" transform="translate(0.23999)" />
                </clipPath>
                <clipPath id="overlimit1">
                    <rect width="16.48" height="16" fill="white" transform="translate(0.23999)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(OverlimitSVG);
