import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { hightlightElements, removeHightlightElements } from './helpers';
import * as gridUIActions from 'gridUI/actions';
import store from 'store/configStore';
import dependencyGif from 'assets/images/dependency.gif';
import Annotation from 'gridUI/translationOverview/DependencyStatusGuide';
import { sendManualTrack } from 'tracker';
import { SYSTEM_COLUMN_IDS } from 'const';
import { enqueueSnackbar } from 'notifier/actions';
import i18n from 'i18n';

const { dispatch, getState } = store;

export const GRID_TUTORIAL_STEPS = [
    {
        anchorSelector: `#root`,
        placement: `left-start`,
        title: i18n.t('welcome_to_a_grid'),
        content: <Typography variant="body2">{i18n.t('lets_quickly_show_you')}</Typography>,
        nextButtonText: i18n.t('show_me'),
        computeStyle: {
            enabled: true,
            fn: data => {
                return {
                    ...data,
                    styles: {
                        ...data.styles,
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                };
            }
        },
        onNext: () => {
            sendManualTrack({ type: 'Grid Tutorial Step 1' });
        }
    },
    {
        anchorSelector: '.columnFormat',
        placement: 'right-start',
        title: i18n.t('define_column_types'),
        content: <Typography variant="body2">{i18n.t('here_you_can_define_various_types')}</Typography>,
        nextButtonText: i18n.t('global_next'),
        onMount: () => {
            const { gridUI } = getState();
            const viewColumns = gridUI?.viewColumns;

            /**
             * TODO: will refactor after have way to control language pairs.
             */

            const possibleColumns = viewColumns?.filter(viewCol => {
                return viewCol?.editable && viewCol?.viewable && !SYSTEM_COLUMN_IDS.includes(viewCol?.id);
            });

            const columnId = possibleColumns?.[0]?.id;

            if (!columnId) {
                dispatch(enqueueSnackbar({ type: 'info', message: 'We can not find any possible column' }));
                return;
            }

            dispatch(gridUIActions.setFormatColumnId(columnId));

            setTimeout(() => {
                hightlightElements('.columnDataType');
            }, 0);
        },
        onUnmount: () => {
            dispatch(gridUIActions.setFormatColumnId(null));
        },
        onNext: () => {
            sendManualTrack({ type: 'Grid Tutorial Step 2' });
        }
    },
    {
        anchorSelector: '#g-h-menu-item-dependency-add',
        placement: 'left-start',
        title: i18n.t('create_dependencies'),
        nextButtonText: i18n.t('global_next'),
        backButtonText: i18n.t('global_back'),
        content: (
            <Grid container spacing={3}>
                <Grid item>
                    <Typography variant="body2">{i18n.t('dependencies_help_you')}</Typography>
                </Grid>
                <Grid item>
                    <img
                        style={{ borderRadius: 4, width: '100%', height: '100%' }}
                        src={dependencyGif}
                        alt="view sort gif"
                    />
                </Grid>
                <Grid item>
                    <Annotation />
                </Grid>
            </Grid>
        ),
        onMount: () => {
            document.querySelector('#g-h-menu-item-dependency-add').click();
            setTimeout(() => {
                hightlightElements('#g-h-menu-item-dependency-add');
                hightlightElements('#dependencyPopup');
            }, 50);
        },
        onUnmount: () => {
            document.querySelector('#g-h-menu-item-dependency-add').click();
            removeHightlightElements('#g-h-menu-item-dependency-add');
        },
        onNext: () => {
            sendManualTrack({ type: 'Grid Tutorial Step 3' });
        }
    },
    {
        anchorSelector: '#toolbarViewSettings',
        placement: 'bottom-start',
        title: i18n.t('manage_content_with_views'),
        content: <Typography variant="body2">{i18n.t('define_which_columns_are_displayed')}</Typography>,
        nextButtonText: i18n.t('global_next'),
        backButtonText: i18n.t('global_back'),
        onMount: () => {
            hightlightElements('#toolbarViewSettings');
        },
        onUnmount: () => {
            removeHightlightElements('#toolbarViewSettings');
        },
        onNext: () => {
            sendManualTrack({ type: 'Grid Tutorial Step 4' });
        }
    },
    {
        anchorSelector: '#toolbarImportActions',
        placement: 'bottom-start',
        title: i18n.t('yipee_youre_getting'),
        content: <Typography variant="body2">{i18n.t('now_its_time_to_focus_on_your_data')}</Typography>,
        nextButtonText: i18n.t('lets_go'),
        backButtonText: i18n.t('global_back'),
        onMount: () => {
            hightlightElements('#toolbarImportActions');
        },
        onUnmount: () => {
            removeHightlightElements('#toolbarImportActions');
        },
        onNext: () => {
            sendManualTrack({ type: 'Grid Tutorial Step 5' });
        }
    }
];
