import { useSelector } from 'react-redux';
import { DEFAULT_GRID_HISTORY_OFFSET, DEFAULT_GRID_HISTORY_LIMIT } from 'const/gridUI';

export function useIsFetchingGridHistory({ branchId }) {
    return useSelector(state => state.gridUI?.historyOfGrid?.[branchId]?.isFetching);
}

export function useGridHistory({ branchId }) {
    return useSelector(state => state.gridUI?.historyOfGrid?.[branchId]?.gridHistory || []);
}

export function useGRID_HISTORY_OFFSET({ branchId }) {
    return useSelector(
        state => state.gridUI?.historyOfGrid?.[branchId]?.GRID_HISTORY_OFFSET || DEFAULT_GRID_HISTORY_OFFSET
    );
}

export function useGRID_HISTORY_LIMIT({ branchId }) {
    return useSelector(
        state => state.gridUI?.historyOfGrid?.[branchId]?.GRID_HISTORY_LIMIT || DEFAULT_GRID_HISTORY_LIMIT
    );
}

export function useTotalGridHistory({ branchId }) {
    return useSelector(state => state.gridUI?.historyOfGrid?.[branchId]?.totalGridHistory);
}

export function useGridBegin({ branchId }) {
    return useSelector(state => state.gridUI?.historyOfGrid?.[branchId]?.grid_begin);
}
