import React from 'react';

function ResendInvitationSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9999 3.22153V11.8958C15.9999 13.112 15.0079 14.104 13.7917 14.104H2.20813C0.99192 14.104 0 13.112 0 11.8958V3.20813C0 1.99192 0.99192 1 2.20813 1H13.7917C15.0018 1 15.9898 1.98193 15.9998 3.18972C16 3.20033 16.0001 3.21094 15.9999 3.22153ZM1.60839 2.8739C1.72685 2.66387 1.95243 2.52035 2.20813 2.52035H13.7917C14.0475 2.52035 14.2731 2.6639 14.3915 2.87396L8 7.34802L1.60839 2.8739ZM1.52035 4.66809V11.8958C1.52035 12.2724 1.83159 12.5836 2.20813 12.5836H13.7917C14.1683 12.5836 14.4795 12.2724 14.4795 11.8958V4.66818L8.43593 8.8987C8.17419 9.08192 7.82581 9.08192 7.56407 8.8987L1.52035 4.66809Z"
                fill="#96C060"
            />
        </svg>
    );
}

export default React.memo(ResendInvitationSVG);
