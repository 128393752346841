import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Option from 'components/option/Base';
import { OPTION_SPACING } from 'const/style';
import { getFormatDateTime } from 'utils/datetime';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    optionList: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    option: {
        marginRight: OPTION_SPACING,
        marginBottom: OPTION_SPACING
    }
}));

function RefDatetime({ value: cellValue }) {
    const classes = useStyles();
    const theme = useTheme();
    if (isEmpty(cellValue)) return null;

    const getJsonValue = cellValue => {
        if (isArray(cellValue)) {
            let values = cellValue.map(i => i.referencedDataItem);
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(cellValue);
    if (!isArray(newData) || !newData) return null;

    return (
        <Grid
            container
            className={classes.container}
            justify="flex-start"
            alignItems="flex-start"
            style={{
                pointerEvents: 'none'
            }}
            wrap="nowrap"
            spacing={1}
        >
            {newData.map((item, index) => {
                if (!item || item.length === 0) return null;
                const datetime = getFormatDateTime(item);
                if (datetime === 'Invalid date') return null;
                return (
                    <Grid item key={index}>
                        <Option
                            className={classes.option}
                            size="small"
                            label={datetime}
                            color={theme.colors.dodgerBlue}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(RefDatetime);
