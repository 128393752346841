import React from 'react';
import { ROW_HEIGHT } from 'const/gridUI';
import JsonEditor from 'components/jsoneditor';

function YamlTextRender({ value, rowHeight = ROW_HEIGHT, columnWidth, rowIndex, columnIndex, filterValue }) {
    React.useEffect(() => {
        if (value) {
            window.dispatchEvent(new Event('resize'));
        }
    }, [columnWidth, rowHeight, value]);

    if (typeof value !== 'string' && typeof value !== 'number') {
        return null;
    }

    const mapValue = value?.toString();

    return (
        <div
            className={`body2 cell_${rowIndex}_${columnIndex} pointer-events-none w-full leading-6 overflow-hidden text-ellipsis whitespace-pre-wrap table-cell align-top text-center break-words h-[${rowHeight}px] w-[${columnWidth}px]`}
            style={{
                width: columnWidth,
                height: rowHeight
            }}
        >
            <JsonEditor mode="yaml" value={mapValue} disabled={true} />
        </div>
    );
}

export default React.memo(YamlTextRender);
