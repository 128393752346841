import React from 'react';

function GridlyShortLogoSVG({ color = '#17B7FF', width = 16, height = 16, ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#392B76" />
            <g clipPath="url(#gridly-logo-clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.79296 6.28528L6.35471 8.9845L10.1741 11.3367L11.9149 9.97024C12.8523 9.2342 12.7586 8.11133 11.7055 7.46289L9.79296 6.28528Z"
                    fill="#63C5EA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1757 11.3431L10.1776 11.3442L12.0837 12.5179L12.0877 12.5147V12.5204L12.0894 12.5214L12.0877 12.5227V16.7987C11.5536 16.7707 11.0284 16.6277 10.5974 16.3622L8.69177 15.1888L8.68205 15.1964L6.77034 14.0191L4.85637 12.8402C3.80203 12.1911 3.70821 11.0674 4.64757 10.33L6.35445 8.98975L6.35627 8.99092L10.1757 11.3431Z"
                    fill="#8C4DD6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8338 11.1535L12.0932 12.5201L12.0923 12.5207L12.0909 12.5218V16.8058C12.1514 16.8089 12.2121 16.8106 12.2726 16.8109H12.2963C13.0003 16.8088 13.7013 16.6069 14.2065 16.2104L15.9108 14.8721L15.9117 14.8713L17.6522 13.505L17.6531 13.5043L13.8347 11.1528L13.8338 11.1535Z"
                    fill="#E94C8B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.1441 9.65083L17.2308 8.47258L15.3192 7.29529L13.619 8.63001C12.6806 9.3669 12.7741 10.4906 13.8285 11.1401L13.8312 11.1415L13.8325 11.1424L17.6509 13.494L17.6533 13.4955L19.3529 12.161C20.2921 11.4238 20.1982 10.2999 19.1441 9.65083Z"
                    fill="#FCB424"
                />
            </g>
            <defs>
                <clipPath id="gridly-logo-clip0">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(GridlyShortLogoSVG);
