import React from 'react';

function YamlSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.57617 3.60315L4.9752 0.574228C5.02425 0.468026 5.13057 0.400024 5.24755 0.400024H6.16524C6.38983 0.400024 6.53478 0.637738 6.43194 0.837398L4.32324 4.93127V7.2094C4.32324 7.37508 4.18893 7.5094 4.02324 7.5094H3.13398C2.9683 7.5094 2.83398 7.37508 2.83398 7.2094V4.93127L0.725285 0.837397C0.622443 0.637738 0.767395 0.400024 0.991984 0.400024H1.91402C2.03128 0.400024 2.13779 0.46834 2.18669 0.574914L3.57617 3.60315Z"
                fill={color}
            />
            <path
                d="M10.5879 6.04456H8.01953L7.59963 7.30427C7.55879 7.42677 7.44415 7.5094 7.31502 7.5094H6.40542C6.19627 7.5094 6.0513 7.30075 6.12427 7.10474L8.5474 0.595365C8.59112 0.47792 8.70323 0.400024 8.82855 0.400024H9.76951C9.89462 0.400024 10.0066 0.477677 10.0505 0.594857L12.487 7.10423C12.5604 7.30033 12.4154 7.5094 12.206 7.5094H11.2966C11.1678 7.5094 11.0534 7.42719 11.0123 7.30512L10.5879 6.04456ZM8.41504 4.85803H10.1924L9.29883 2.1969L8.41504 4.85803Z"
                fill={color}
            />
            <path
                d="M3.82705 8.40002C3.95413 8.40002 4.06742 8.48009 4.10984 8.59987L5.86523 13.5563L7.61107 8.60035C7.65336 8.48032 7.76677 8.40002 7.89403 8.40002H9.30547C9.47115 8.40002 9.60547 8.53434 9.60547 8.70002V15.2094C9.60547 15.3751 9.47115 15.5094 9.30547 15.5094H8.43574C8.27006 15.5094 8.13574 15.3751 8.13574 15.2094V13.566L8.28223 10.2115L6.43494 15.3116C6.39193 15.4303 6.27916 15.5094 6.15287 15.5094H5.56795C5.4416 15.5094 5.3288 15.4302 5.28583 15.3114L3.44336 10.2164L3.58984 13.566V15.2094C3.58984 15.3751 3.45553 15.5094 3.28984 15.5094H2.425C2.25931 15.5094 2.125 15.3751 2.125 15.2094V8.70002C2.125 8.53434 2.25931 8.40002 2.425 8.40002H3.82705Z"
                fill={color}
            />
            <path
                d="M12.3496 14.3326H15.16C15.3256 14.3326 15.46 14.467 15.46 14.6326V15.2094C15.46 15.3751 15.3256 15.5094 15.16 15.5094H11.1848C11.0191 15.5094 10.8848 15.3751 10.8848 15.2094V8.70002C10.8848 8.53434 11.0191 8.40002 11.1848 8.40002H12.0496C12.2153 8.40002 12.3496 8.53434 12.3496 8.70002V14.3326Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(YamlSVG);
