import React from 'react';

function KickoutSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33333 2.75C3.17862 2.75 3.03025 2.81146 2.92085 2.92085C2.81146 3.03025 2.75 3.17862 2.75 3.33333V12.6667C2.75 12.8214 2.81146 12.9697 2.92085 13.0791C3.03025 13.1885 3.17862 13.25 3.33333 13.25H6C6.41421 13.25 6.75 13.5858 6.75 14C6.75 14.4142 6.41421 14.75 6 14.75H3.33333C2.7808 14.75 2.25089 14.5305 1.86019 14.1398C1.46949 13.7491 1.25 13.2192 1.25 12.6667V3.33333C1.25 2.7808 1.46949 2.2509 1.86019 1.86019C2.2509 1.46949 2.7808 1.25 3.33333 1.25H6C6.41421 1.25 6.75 1.58579 6.75 2C6.75 2.41421 6.41421 2.75 6 2.75H3.33333ZM10.1362 4.13636C10.4291 3.84346 10.9039 3.84346 11.1968 4.13636L14.5203 7.45986C14.6619 7.59628 14.75 7.78786 14.75 8C14.75 8.21155 14.6624 8.40265 14.5215 8.539L11.1968 11.8637C10.9039 12.1566 10.4291 12.1566 10.1362 11.8637C9.84328 11.5708 9.84328 11.0959 10.1362 10.803L12.1892 8.75H6C5.58579 8.75 5.25 8.41421 5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H12.1892L10.1362 5.19702C9.84328 4.90412 9.84328 4.42925 10.1362 4.13636Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(KickoutSVG);
