import {
    SET_CURRENT_CELL_COLOR,
    SET_SELECTED_CELL_COLOR,
    OPTIMISTIC_FILL_CELLS_COLOR,
    RESET_CELL_COLOR
} from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import * as optimisticActions from './optimistic';
import { setViewRecords } from 'services/view';
import uuidv1 from 'uuid/v1';
import * as statusActions from './status';
import * as dataActions from './data';
import { combinedData, markColor } from 'utils/gridUI/data';
import { getCellData } from 'utils/gridUI/cell';
import { getDisabledColumnIds } from 'utils/gridUI/column';
import { removeArrayInArray } from 'utils/object';
import { SYSTEM_COLUMN_IDS } from 'const';
import { DATA_QUERY_OPTIONS, MAX_SELECTION_RECORDS, MAX_RECORD_LIMIT, RANGE_TYPES } from 'const/gridUI';
import { chunk } from 'lodash';

export function setCurrentCellColor({ color }) {
    return {
        type: SET_CURRENT_CELL_COLOR,
        payload: {
            color
        }
    };
}

export function setSelectedCellColor({ color }) {
    return {
        type: SET_SELECTED_CELL_COLOR,
        payload: {
            color
        }
    };
}

export function setCellsColor({ color }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const {
            defaultAccessViewId,
            dbId,
            viewColumns,
            disabledSourceColumns,
            processingColumns,
            disabledColumns
        } = gridUI;

        dispatch(statusActions.registerDoingAction({ actionId }));

        const {
            columnIds: rangeColumnIds,
            recordIds,
            data,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            auth,
            gridUI,
            dataOptions: [DATA_QUERY_OPTIONS.COLOR],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length || !rangeColumnIds?.length) {
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            disabledSourceColumns,
            processingColumns
        });

        const affectedColumnIds = removeArrayInArray(rangeColumnIds, [...disabledCols, ...SYSTEM_COLUMN_IDS]);

        const { newData, undoData, serverData } = markColor({ columnIds: affectedColumnIds, recordIds, data, color });

        dispatch(dataActions.updateData({ newData }));

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: OPTIMISTIC_FILL_CELLS_COLOR,
                body: {
                    colorData: undoData
                }
            })
        );

        try {
            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...affectedColumnIds],
                            records
                        }
                    });
                })
            );

            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.removeAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(optimisticActions.revertAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function undoCellFormatting({ recordIds, data, columnIds }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const actionId = uuidv1();
        const { defaultAccessViewId, dbId, data: dataStore } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(dataActions.updateData({ newData: data }));

        console.log('undoData', data);
        try {
            const dataCombined = combinedData({ data: dataStore, newData: data });
            const serverData = [];

            for (const recordId of recordIds) {
                let recordServer = [];
                for (const columnId of columnIds) {
                    const cellData = getCellData({ data: dataCombined, rowId: recordId, columnId });
                    const cellColor = cellData?._color || '';
                    recordServer.push({ _color: cellColor });
                }

                serverData.push([recordId, ...recordServer]);
            }

            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns: ['_recordId', ...columnIds],
                            records
                        }
                    });
                })
            );

            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function resetCellColor() {
    return {
        type: RESET_CELL_COLOR
    };
}
