import React from 'react';

function ExtendViewSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M8.435 9.535L7.165 8.28L7.18 8.265C8.05 7.295 8.67 6.18 9.035 5H10.5V4H7V3H6V4H2.5V4.995H8.085C7.75 5.96 7.22 6.875 6.5 7.675C6.035 7.16 5.65 6.595 5.345 6H4.345C4.71 6.815 5.21 7.585 5.835 8.28L3.29 10.79L4 11.5L6.5 9L8.055 10.555L8.435 9.535ZM11.25 7H10.25L8 13H9L9.56 11.5H11.935L12.5 13H13.5L11.25 7ZM9.94 10.5L10.75 8.335L11.56 10.5H9.94V10.5Z"
                fill={color}
            />
            <rect width="16" height="1.2" rx="0.6" fill={color} />
            <rect y="14.8" width="16" height="1.2" rx="0.6" fill={color} />
        </svg>
    );
}

export default React.memo(ExtendViewSVG);
