import React from 'react';

const EmptyBoxSVG = props => {
    return (
        <svg width="136" height="106" viewBox="0 0 136 106" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <ellipse cx="68" cy="88.7027" rx="68" ry="16.6656" fill="url(#paint0_radial)" />
            <path d="M105.945 84.3788L58.2271 95.1543V53.6164L105.945 42.8408V84.3788Z" fill="#DCDCED" />
            <path
                opacity="0.46"
                d="M58.2271 74.8198L107.603 58.3958L105.945 42.8408L58.2271 53.6164V74.8198Z"
                fill="#B9BED8"
            />
            <path d="M58.314 95.1543L32.1431 82.9884V42.7539L58.4012 53.7032L58.314 95.1543Z" fill="#EAEAF3" />
            <path
                d="M52.1201 72.2121L28.7407 57.6999L33.1025 44.2305L57.0926 55.2667L52.1201 72.2121Z"
                fill="#D0D7E5"
            />
            <path d="M58.5214 53.6544L32.1704 41.4717L24.8251 57.237L51.1761 69.4197L58.5214 53.6544Z" fill="#EAEAF3" />
            <path
                d="M111.267 59.2618L62.2401 70.3849L58.3145 53.4395L107.254 42.3164L111.267 59.2618Z"
                fill="#DCDCED"
            />
            <path
                d="M79.4253 31.7178H79.3381L32.2305 41.4505L58.3141 53.4426L107.167 42.3195L79.4253 31.7178Z"
                fill="#CCCEE3"
            />
            <path d="M79.4253 48.6632L107.166 42.3195L79.4253 31.7178V48.6632Z" fill="#BFC2D9" />
            <path
                d="M45.6883 18.7992C45.9051 18.3431 46.0937 17.8783 46.255 17.4059H46.2136C49.0389 9.1334 43.0972 0.43896 34.3256 0.0156178C34.3255 0.0151662 34.3254 0.0147143 34.3253 0.0142627C26.7779 -0.33269 20.5073 5.69541 20.5073 13.1702C20.5073 20.4264 26.4194 26.3301 33.6911 26.3406C35.6635 26.338 37.5541 25.9144 39.317 25.0802C46.1983 26.4373 45.6617 26.3408 45.8197 26.3408C46.5349 26.3408 47.0691 25.6871 46.9295 24.9906L45.6883 18.7992Z"
                fill="#BFC1D9"
            />
            <path
                d="M33.9036 11.4944L30.6104 8.21389L29.1909 9.62789L32.4841 12.9084L28.7529 16.6252L30.2616 18.1281L33.9928 14.4113L37.286 17.6918L38.7055 16.2778L35.4123 12.9973L38.8596 9.56325L37.3509 8.06037L33.9036 11.4944Z"
                fill="white"
            />
            <ellipse rx="2.69841" ry="2.68799" transform="matrix(-1 0 0 1 50.7298 30.105)" fill="#CCCEE3" />
            <defs>
                <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(68 88.7027) rotate(90) scale(16.6656 68)"
                >
                    <stop stopColor="#DCDCED" stopOpacity="0" />
                    <stop offset="1" stopColor="#CCCEE3" stopOpacity="0.21" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default React.memo(EmptyBoxSVG);
