export const defaultBorder = '2px solid transparent';

export function hightlightElements(querySelector = '') {
    if (!querySelector) return;
    const elements = document.querySelectorAll(querySelector);
    elements.forEach(element => {
        element.style.border = '2px solid #4A91E2';
        element.style.borderRadius = '4px';
        element.style.boxShadow = '0px 0px 5px #5AB3FF';
    });
}

export function removeHightlightElements(querySelector = '') {
    if (!querySelector) return;
    const elements = document.querySelectorAll(querySelector);
    elements.forEach(element => {
        element.style.border = defaultBorder;
        element.style.borderRadius = null;
        element.style.boxShadow = null;
    });
}
