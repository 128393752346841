import React from 'react';

function ShuffleSVG({ color = '#D1D0D6', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_808_1917)">
                <path
                    d="M16 12.1147L12.6689 8.78198V11.4287H12.3428C10.8189 11.4287 9.83651 10.5302 8.94604 9.27476C8.70054 9.67555 8.44166 10.0922 8.16125 10.5056C9.17154 11.7885 10.445 12.8001 12.3428 12.8001H12.6689V15.4465L16 12.1147Z"
                    fill={color}
                />
                <path
                    d="M16 3.88612L12.6689 0.553467V3.20019H12.3428C9.444 3.20019 8.00169 5.56031 6.72919 7.64262C5.48763 9.67415 4.41544 11.4287 2.28572 11.4287H0V12.8002H2.28572C5.18456 12.8002 6.62687 10.44 7.89937 8.35772C9.14094 6.32612 10.2131 4.57156 12.3428 4.57156H12.6689V7.218L16 3.88612Z"
                    fill={color}
                />
                <path
                    d="M2.28572 3.20007H0V4.57151H2.28572C3.80966 4.57151 4.79206 5.47001 5.68253 6.72542C5.92803 6.32464 6.18691 5.90795 6.46731 5.49457C5.457 4.21164 4.1835 3.20007 2.28572 3.20007Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_808_1917">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ShuffleSVG);
