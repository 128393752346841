import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Collapse, Grid } from '@material-ui/core';
import Search from 'components/search';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import { ACTION_STATUS } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';

const useStyles = makeStyles(theme => ({
    root: {},
    searchWrapper: {
        padding: '0 10px'
    },
    tree: {
        marginTop: 14,
        height: 220,
        overflow: 'auto'
    },
    showAll: {
        cursor: 'pointer'
    },
    expand: {
        marginLeft: 40,
        marginRight: 2,
        cursor: 'pointer'
    },
    arrow: {
        verticalAlign: 'middle'
    },
    fuchsiaBlue: {
        color: theme.colors.fuchsiaBlue
    },
    iconWrapper: {
        marginRight: 10
    },
    treeItem: {
        paddingRight: 20,
        height: 44,
        cursor: 'pointer',
        '& .tree-action': {
            display: 'none'
        },
        '&:hover': {
            background: theme.colors.paleGrey,
            '& .tree-action': {
                display: 'flex'
            }
        }
    }
}));

const styleIcon = {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid #E9EAEF`,
    borderRadius: 4
};

const filter = (array, text) => {
    const getOptions = (result, object) => {
        if (
            object.id?.toLocaleLowerCase().indexOf(text?.toLocaleLowerCase()) > -1 ||
            object.name?.toLocaleLowerCase().indexOf(text?.toLocaleLowerCase()) > -1
        ) {
            result.push(object);
            return result;
        }
        if (Array.isArray(object.commonOptions)) {
            const commonOptions = object.commonOptions.reduce(getOptions, []);
            if (commonOptions.length) result.push({ ...object, commonOptions });
        }
        if (Array.isArray(object.options)) {
            const options = object.options.reduce(getOptions, []);
            if (options.length) result.push({ ...object, options });
        }
        return result;
    };

    return array.reduce(getOptions, []);
};

const TreeSuggesstionItem = ({ item = {}, level = 0, parentShowAll = false, handleSelect }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const hasChildren = useMemo(() => {
        return item.options?.length > 0;
    }, [item.options]);

    const [collapsed, setCollapsed] = useState(level === 0 && hasChildren);
    const [showAll, setShowAll] = useState(parentShowAll);

    const paddingLeftLevel1 = level * 30 + 9 + 10;

    const paddingLeft = useMemo(() => {
        if (level === 0) {
            return 9;
        }
        if (level === 1) {
            return paddingLeftLevel1;
        }
        return level * 30 + 9;
    }, [level, paddingLeftLevel1]);

    const disabled = useMemo(() => {
        return !!item?.isTrigger && item?.status === ACTION_STATUS.DISABLED;
    }, [item]);

    const handleShowAll = useCallback(() => {
        if (hasChildren) {
            setCollapsed(true);
            setShowAll(true);
        }
    }, [hasChildren]);

    const toggleCollapse = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setCollapsed(prev => !prev);
    }, []);

    const onSelect = useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (level === 0 && hasChildren) return;
            handleSelect(item);
        },
        [level, handleSelect, item, hasChildren]
    );

    useEffect(() => {
        if (parentShowAll) {
            handleShowAll();
        }
    }, [handleShowAll, parentShowAll]);

    return (
        <TooltipActionDisable disabled={disabled}>
            <Grid
                item
                container
                wrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                className={classnames(classes.treeItem)}
                style={{ paddingLeft }}
                onClick={onSelect}
            >
                <Grid item container alignItems="center" wrap="nowrap">
                    {(item.trigger || item.app) && (
                        <Grid item className={classnames(classes.iconWrapper)}>
                            <WebHookLabel type={item.trigger || item.app} />
                        </Grid>
                    )}
                    {item?.isDisplayColumnIcon && (
                        <Grid item>
                            <ColumnIcon
                                group={item?.column?.group}
                                type={item?.column?.type}
                                customProperties={item?.column?.customProperties}
                                styleCountryFlagWrapper={styleIcon}
                                style2Digit={styleIcon}
                                normalWrapper
                                normalWrapperStyle={{
                                    ...styleIcon,
                                    position: 'relative',
                                    left: -4
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <p className="body1">
                            {level === 0 && item.nodeOrder ? `${item.nodeOrder}. ` : ''}
                            {item.name === 'rawData' ? 'TriggerData' : item.name}
                        </p>
                    </Grid>
                </Grid>
                <Grid item container wrap="nowrap" justifyContent="flex-end" className="tree-action">
                    {level !== 0 && (
                        <Grid item className={classes.showAll} onClick={onSelect}>
                            <p className="body2 text-fuchsiaBlue">{t('global_select')}</p>
                        </Grid>
                    )}
                    {hasChildren && (
                        <Grid item onClick={toggleCollapse}>
                            <Grid item container wrap="nowrap" alignItems="center">
                                <Grid item className={classes.expand}>
                                    <p className="body2 text-fuchsiaBlue">
                                        {t(collapsed ? 'global_collapse' : 'global_expand')}
                                    </p>
                                </Grid>
                                <Grid item>
                                    {collapsed ? (
                                        <ArrowUpSVG color={theme.colors.steel} className={classes.arrow} />
                                    ) : (
                                        <ArrowDownSVG color={theme.colors.steel} className={classes.arrow} />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {hasChildren && !disabled && (
                <Collapse in={collapsed}>
                    {collapsed && level === 0 && item.commonOptions?.length > 0 && (
                        <>
                            <Grid item style={{ paddingLeft: paddingLeftLevel1 }}>
                                <p className="caption">Common</p>
                            </Grid>
                            {item.commonOptions.map(opt => (
                                <TreeSuggesstionItem
                                    key={opt.id}
                                    item={opt}
                                    level={level + 1}
                                    parentShowAll={showAll}
                                    handleSelect={handleSelect}
                                />
                            ))}
                            <Grid item style={{ paddingLeft: paddingLeftLevel1 }}>
                                <p className="caption">Advance</p>
                            </Grid>
                        </>
                    )}
                    {collapsed &&
                        item.options.map(opt => (
                            <TreeSuggesstionItem
                                key={opt.id}
                                item={opt}
                                level={level + 1}
                                parentShowAll={showAll}
                                handleSelect={handleSelect}
                            />
                        ))}
                </Collapse>
            )}
        </TooltipActionDisable>
    );
};

const TreeSuggesstion = ({ options, handleSelect, query = '' }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const timeout = useRef();
    const inputRef = useRef();

    const [showAll, setShowAll] = useState(false);
    const [search, setSearch] = useState(query);

    useEffect(() => {
        if (query) {
            inputRef.current.value = query;
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                setSearch(query);
                if (query) {
                    setShowAll(true);
                }
            }, 800);
        }
    }, [query]);

    const filterOptions = useMemo(() => {
        return !!search.trim() ? filter(options, search) : options;
    }, [options, search]);

    const onSearch = useCallback(e => {
        const value = e.target.value;
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setSearch(value);
            if (value) {
                setShowAll(true);
            }
        }, 800);
    }, []);

    return (
        <Grid className={classes.root} item container direction="column" wrap="nowrap">
            <Grid item className={classes.searchWrapper}>
                <Search
                    inputRef={inputRef}
                    background={theme.colors.paleGrey}
                    placeholder={t('automation_payload_search_variables')}
                    onChange={onSearch}
                />
            </Grid>
            <Grid item className={classes.tree}>
                {filterOptions.map(item => (
                    <TreeSuggesstionItem
                        key={item.id}
                        item={item}
                        level={0}
                        parentShowAll={showAll}
                        handleSelect={handleSelect}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(TreeSuggesstion);
