import React from 'react';
import { useUserActiveActions } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import { removeLastActionsFromServer } from 'gridUI/actions';
import * as userActiveAction from 'gridUI/actions/userActiveActions';

const DEBOUCE_TIME = 1000;

function ActiveUserActionsProvider() {
    const actions = useUserActiveActions();
    const dispatch = useDispatch();
    const timerRef = React.useRef();

    React.useEffect(() => {
        if (actions.length === 0) return;

        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let lastest = actions?.[actions?.length - 1];

            if (lastest) {
                const { actionId, body } = lastest;
                dispatch(userActiveAction.sendCurrentAction(body));
                dispatch(removeLastActionsFromServer({ actionId }));
            }
        }, DEBOUCE_TIME);
    }, [actions, dispatch]);
    return null;
}

export default React.memo(ActiveUserActionsProvider);
