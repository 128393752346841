import React from 'react';
import { Grid } from '@material-ui/core';
import DatabaseItemSkeleton from './DatabaseItemSkeleton';

function DatabaseListSkeleton() {
    return (
        <Grid item container spacing={2}>
            {Array.from(Array(5).keys()).map(index => (
                <Grid item key={index} style={{ width: '100%' }}>
                    <DatabaseItemSkeleton />
                </Grid>
            ))}
        </Grid>
    );
}

export default DatabaseListSkeleton;
