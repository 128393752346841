import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import PlanBenefitItem from 'payment/components/PlanBenefitItem';
import Button from 'components/button/Base';
import FreeTrialBannerSVG from 'assets/images/svg/FreeTrialBannerSVG';
import ProfessionalPlanSVG from 'assets/images/svg/payment/ProfessionalPlanSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    root: {
        width: 472,
        position: 'relative',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
            overflow: 'hidden'
        }
    },
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2)
    },
    contentWrapper: {
        padding: theme.spacing(0, 5, 4, 5)
    },
    welcomeText: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    benefitItem: {
        marginBottom: theme.spacing(2)
    },
    buttonWrapper: {
        marginTop: theme.spacing(3),
        margin: 'auto'
    }
}));

const benefits = [
    'Unlimited members',
    '20 GB storage',
    'Unlimited Grids',
    '100K rows per Grid',
    i18n.t('global_translation_memory'),
    'Data access control',
    'Advanced translation import & export',
    'Add-ons: Phrase TMS and memoQ'
];

function FreeTrialBack({ onClose }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const firstName = useSelector(state => state?.auth?.user?.firstName);

    return (
        <Grid container direction="column" className={classes.root}>
            <IconButton className={classes.closeIcon} onClick={onClose}>
                <CloseIconSVG color={theme.colors.white} />
            </IconButton>
            <Grid item>
                <FreeTrialBannerSVG className={classes.freeTrialBanner} />
            </Grid>
            <Grid item container direction="column" className={classes.contentWrapper}>
                <Grid item container alignItems="center" spacing={3} wrap="nowrap">
                    <Grid item>
                        <ProfessionalPlanSVG />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">{`${t('retrial_dialog_header')}, ${firstName}!`}</Typography>
                    </Grid>
                </Grid>
                <Grid item className={classes.welcomeText}>
                    {t('retrial_dialog_description')}
                </Grid>
                <Grid item container spacing={2}>
                    {benefits.map((benefit, index) => (
                        <Grid item xs={6} key={index} className={classes.benefitItem}>
                            <PlanBenefitItem description={benefit} />
                        </Grid>
                    ))}
                </Grid>
                <Grid item className={classes.buttonWrapper} onClick={onClose}>
                    <Button variant="contained">{t('retrial_dialog_btn')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(FreeTrialBack);
