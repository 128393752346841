import React from 'react';

const AddButtonContainerSVG = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="16" height="16" rx="4" fill="#7869B9" />
            <path
                d="M10.6663 8.38061H8.38061V10.6663H7.61871V8.38061H5.33301V7.61871H7.61871V5.33301H8.38061V7.61871H10.6663V8.38061Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(AddButtonContainerSVG);
