import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { fetchGridCommentsApi } from 'services/cellComment';

export function fetchGridCommentList({ compositeViewId, successCallback, errorCallback, query, isFetchingMore }) {
    return async function(dispatch, getState) {
        dispatch(_fetchGridCommentList({ compositeViewId }));
        try {
            const { totalComments, comments } = await fetchGridCommentsApi({
                query: { ...query, viewId: compositeViewId }
            });
            dispatch(_fetchGridCommentListSuccess({ compositeViewId, comments, totalComments, isFetchingMore }));
            successCallback && successCallback();
        } catch (error) {
            const { message, originalMessage } = error;
            errorCallback && errorCallback();
            dispatch(_fetchGridCommentListFailed({ compositeViewId, error: originalMessage }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

function _fetchGridCommentList({ compositeViewId }) {
    return {
        type: types.FETCH_CELL_COMMENT_LIST,
        payload: {
            compositeViewId
        }
    };
}

function _fetchGridCommentListFailed({ compositeViewId, error }) {
    return {
        type: types.FETCH_CELL_COMMENT_LIST_FAILED,
        payload: {
            compositeViewId,
            error
        }
    };
}

function _fetchGridCommentListSuccess({ compositeViewId, comments, totalComments, isFetchingMore }) {
    return {
        type: types.FETCH_CELL_COMMENT_LIST_SUCCESS,
        payload: {
            compositeViewId,
            comments,
            totalComments,
            isFetchingMore
        }
    };
}

export function _updateDashboardCommentThreadStatus({ threadId, newStatus }) {
    return {
        type: types.UPDATE_DASHBOARD_COMMENT_THREAD_STATUS,
        payload: {
            threadId,
            newStatus
        }
    };
}
