import React from 'react';

function SynchronizeTMColorSVG({ ...other }) {
    return (
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M21.4848 7.94921C21.7682 7.94921 22 7.72064 22 7.44127V4.24127C22 1.90476 20.0679 0 17.6979 0H4.30211C1.93208 0 0 1.90476 0 4.24127V11.7587C0 14.0952 1.93208 16 4.30211 16H17.6721C20.0422 16 21.9742 14.0952 21.9742 11.7587V11.3778C21.9742 11.0984 21.7424 10.8698 21.459 10.8698C21.1756 10.8698 20.9438 11.0984 20.9438 11.3778V11.7587C20.9438 13.5365 19.4754 14.9841 17.6721 14.9841H4.30211C2.49883 15.0095 1.03044 13.5619 1.03044 11.7587V4.24127C1.03044 2.46349 2.49883 1.01587 4.30211 1.01587H17.6721C19.4754 1.01587 20.9438 2.46349 20.9438 4.24127V7.41587C20.9696 7.72064 21.2014 7.94921 21.4848 7.94921Z"
                fill="#4D3F8A"
            />
            <path d="M9.54321 5.75743H7.5217V11.317H6.40409V5.75743H4.40039V4.85156H9.54321V5.75743Z" fill="#63C5EA" />
            <path
                d="M11.8586 4.85156L13.7287 9.8072L15.5944 4.85156H17.0504V11.317H15.9283V9.18552L16.0396 6.3347L14.125 11.317H13.3191L11.4089 6.33914L11.5202 9.18552V11.317H10.3981V4.85156H11.8586Z"
                fill="#63C5EA"
            />
        </svg>
    );
}

export default React.memo(SynchronizeTMColorSVG);
