import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha, CircularProgress, Grid, Typography } from '@material-ui/core';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import DOMPurify from 'dompurify';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import ButtonBase from 'components/button/Base';
import InputText from 'components/inputs/InputText';
import { isKbEnter, isKbEscape } from 'utils/keyboard';
import { trim } from 'lodash';
import { useDispatch } from 'react-redux';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useHistory, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notifier/actions';
import { useCurrentUser } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        width: 440,
        position: 'relative',
        '&:focus': {
            outline: 'none'
        }
    },

    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        },
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? alpha(props.buttonColor, 0.9) : '')
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function SaveAs({ onClose, name, advancedSearchId, author, userId }) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const currentUser = useCurrentUser();
    const [newName, setNewName] = React.useState(`Copy of ${name}`);
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const history = useHistory();

    const isShowWarning = React.useMemo(() => {
        return currentUser?.id !== userId?.toString();
    }, [currentUser, userId]);

    const handleSaveAs = React.useCallback(() => {
        if (trim(newName) === '') {
            // onClose();
            return;
        }

        setIsLoading(true);

        dispatch(
            advancedSearchActions.duplicateAdvancedSearch({
                wsId: workspaceId,
                adId: advancedSearchId,
                name: newName,
                store: true,
                success: createdAdvancedSearchId => {
                    setIsLoading(false);
                    dispatch(advancedSearchActions.setSelectedAdvancedSearchId(createdAdvancedSearchId));
                    history.push(`/p/${workspaceId}/s/${createdAdvancedSearchId}`);
                    onClose && onClose();
                    dispatch(enqueueSnackbar({ message: `Saved as new as ${newName}` }));
                    dispatch(
                        advancedSearchActions.getAdvancedSearchList({
                            wsId: workspaceId,
                            params: {
                                limit: 400,
                                offset: 0
                            },
                            success: () => {
                                console.log('getAdvancedSearchList success');
                            },
                            error: () => {
                                console.log('getAdvancedSearchList failed');
                            }
                        })
                    );

                    dispatch(
                        advancedSearchActions.fetchAdvancedSearch({
                            wsId: workspaceId,
                            advancedSearchId: createdAdvancedSearchId,
                            success: () => {
                                console.log('fetchAdvancedSearch success');
                            },
                            error: () => {
                                console.log('fetchAdvancedSearch failed');
                            }
                        })
                    );
                },
                error: error => {
                    console.log('error update');
                    setIsLoading(false);
                }
            })
        );
        onClose();
    }, [dispatch, onClose, history, workspaceId, advancedSearchId, newName]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            onClose();
            return;
        }
        if (!isKbEnter(e)) return;
        handleSaveAs();
    };

    return (
        <>
            <DialogTitle
                title={
                    <Typography variant="h4" component="div">
                        Save as new query
                    </Typography>
                }
                onClose={onClose}
            />
            <DialogContent>
                <Grid container direction="column" spacing={4} className={classes.root}>
                    {isShowWarning && (
                        <Grid item>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        `This query was created by <strong>${author}</strong>. You can save a copy of this filter but you cannot modify the original.`,
                                        { USE_PROFILES: { html: true } }
                                    )
                                }}
                            ></Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <InputText
                            label={'Query name'}
                            name="name"
                            value={newName}
                            onChange={e => setNewName(e.target.value)}
                            placeholder={'Input name'}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            className={classes.input}
                            autoSelect={true}
                            inputProps={{
                                maxLength: 255
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase width={107} onClick={onClose} variant="outlined">
                            Cancel
                        </ButtonBase>
                    </Grid>

                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase onClick={handleSaveAs} width={107} disabled={isLoading} variant="contained">
                                Save
                            </ButtonBase>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default SaveAs;
