import React from 'react';
import { Grid } from '@material-ui/core';
import DocSchema from 'components/docSchema/Base';
import { useRecordArray } from 'hooks/doc/record';
import { useGetApiKey, useCompositeViewId } from 'hooks/gridUI';
import { usePublicApiUrl } from 'hooks/workspace';

function ListRecord() {
    const compositeViewId = useCompositeViewId();
    const records = useRecordArray(2);
    const apiKey = useGetApiKey();
    const publicApiUrl = usePublicApiUrl();

    let schema = {
        method: 'GET',
        url: `${publicApiUrl}/v1/views/${compositeViewId}/records`,
        Authorization: apiKey
    };

    const response = records;

    return (
        <Grid>
            <DocSchema schema={schema} response={response} />
        </Grid>
    );
}

export default React.memo(ListRecord);
