import * as types from '../types';
import { enqueueSnackbar } from 'notifier/actions';
import { DEFAULT_PATH_TAG_COLOR } from 'utils/color';
import { getTagsApi, createTagApi, updateTagApi, deleteTagApi, moveTagApi } from 'services/pathTag';
import {
    findNode,
    getPath,
    findTagByPathId,
    findChildrenByPath,
    getAllNodeIds,
    generateNewPathTagName,
    getDropRecordIntoPathData,
    pathNodesObj
} from 'utils/gridUI/pathTag';
import { getColumnsRecordsApi } from 'services/view';
import * as columnActions from './column';
import { PATH_TAG_ID, USER_SETTINGS } from 'const';
import uuidv1 from 'uuid/v1';
import * as statusActions from './status';
import { OPERATOR } from 'gridUI/conditions';
import {
    deleteViewFilterApi,
    createViewFilterApi,
    updateViewFilterApi,
    deleteViewFiltersApi
} from 'services/viewFilter';
import {
    _fetchRecordsAfterFilter,
    _updateViewFilterActionSuccess,
    _deleteViewFilterActionSuccess,
    _createViewFilterAction
} from './viewFilter';
import { setViewRecords } from 'services/view';
import * as optimisticActions from './optimistic';
import * as rowActions from './row';
import * as columnTypes from 'const/columnTypes';
import * as viewFilterActions from './viewFilter';
import { getCellValueOnly } from 'utils/gridUI/cell';
import * as dataActions from './data';
import * as authActions from 'auth/actions';
import { getFirstNodeIdsWithLevel } from 'utils/gridUI/pathTag';
import { DATA_QUERY_OPTIONS, MAX_RECORD_LIMIT, MAX_SELECTION_RECORDS, RANGE_TYPES } from 'const/gridUI';
import { chunk } from 'lodash';
import { fetchRecordsWithFiltersAndSorts } from '.';
import { formatQuickFilters } from 'utils/gridUI/filter';
import * as exportActions from './export';

export function fetchTags({ dbId: dbIdExternal, branchId: branchIdExternal, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId: dbIdStore, branchId: branchIdStore } = gridUI;
        const dbId = dbIdExternal || dbIdStore;
        const branchId = branchIdExternal || branchIdStore;

        dispatch(_fetchTagsAction());
        try {
            const tree = await getTagsApi({ dbId, gridId: branchId });
            const expandedIds = getFirstNodeIdsWithLevel(tree, 1);
            dispatch(fetchTagsActionSuccess({ tree }));
            dispatch(_toggleNodes({ nodeIds: expandedIds }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchTagsActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchTagsAction() {
    return {
        type: types.FETCH_PATH_TAG_TREE
    };
}

export function fetchTagsActionSuccess({ tree }) {
    return {
        type: types.FETCH_PATH_TAG_TREE_SUCCESS,
        payload: {
            tree
        }
    };
}

function _fetchTagsActionFailed() {
    return {
        type: types.FETCH_PATH_TAG_TREE_FAILED
    };
}

export function togglePathTag() {
    return async function(dispatch, getState) {
        const { gridUI, app } = getState();
        const { viewColumns, isPathTagOn } = gridUI;
        const { isShareViewLink } = app;

        console.log('isShareViewLink', isShareViewLink);
        if (isShareViewLink) {
            dispatch(_togglePathTag());
            return;
        }

        const isHavingPathTagInView = Boolean(
            viewColumns?.find(viewCol => viewCol?.id === columnTypes.PATH_TAG && viewCol?.viewable)
        );

        if (!isPathTagOn && !isHavingPathTagInView) {
            dispatch(
                columnActions.enableColumnInViewWithOrder({
                    columnId: columnTypes.PATH_TAG,
                    isEditable: true,
                    successCallback: () => {
                        console.log('enable and reOrder success');
                    },
                    errorCallback: () => {
                        console.log('enable and reOrder failed');
                    }
                })
            );
        }

        dispatch(_togglePathTag());

        dispatch(
            authActions.setUserSettings({
                settings: {
                    [USER_SETTINGS.OPEN_PATH_TAG]: !isPathTagOn
                },
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    };
}

function _togglePathTag() {
    return {
        type: types.TOGGLE_PATH_TAG
    };
}

export function setDefaultUserPathTagOn(isPathTagOn) {
    return {
        type: types.SET_PATH_TAG_ON,
        payload: {
            isPathTagOn
        }
    };
}

export function toggleNodes({ nodeId, highlightNodeId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tree, expanded } = gridUI;
        const path = getPath({ tree, nodeId });

        let highlighNodes = [];
        if (highlightNodeId) {
            const highlightPath = getPath({ tree, nodeId: highlightNodeId });
            const pathArr = highlightPath?.pathArr;
            highlighNodes = pathArr?.filter(nodeId => nodeId !== highlightNodeId) || [];

            //check children
            const children = findChildrenByPath({ tree, path: path?.pathName });

            if (!path?.pathArr?.includes(highlightNodeId)) {
                const allNodeChildIds = getAllNodeIds(children) || [];
                if (allNodeChildIds?.includes(highlightNodeId)) {
                    highlighNodes = [];
                    dispatch(
                        columnActions.clearQuickFiltersAfterColumnDeletedOrHiddenSocket({
                            columnId: columnTypes.PATH_TAG
                        })
                    );
                }
            }
        }

        if (expanded?.includes(nodeId)) {
            const nodeIds = path?.pathArr?.filter(id => nodeId !== id);
            dispatch(_toggleNodes({ nodeIds: [...nodeIds, ...highlighNodes] }));
            return;
        }
        const nodeIds = [...path?.pathArr, nodeId, ...highlighNodes];
        dispatch(_toggleNodes({ nodeIds }));
    };
}

export function expandWhenHover({ nodeId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tree } = gridUI;
        const path = getPath({ tree, nodeId });
        let highlighNodes = [];
        const nodeIds = [...path?.pathArr, nodeId, ...highlighNodes];
        dispatch(_toggleNodes({ nodeIds }));
    };
}

function _toggleNodes({ nodeIds = [] }) {
    return {
        type: types.TOGGLE_NODES,
        payload: {
            nodeIds
        }
    };
}

export function addRootNode({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dbId, branchId, tree, isCreatingNode } = gridUI;

        if (isCreatingNode) {
            return;
        }
        dispatch(_addRootNodeAction());
        dispatch(turnOnFirstCreateSkeleton({ nodeId: 1 }));
        try {
            const newPathTagName = generateNewPathTagName(tree);

            const node = await createTagApi({
                dbId,
                gridId: branchId,
                body: {
                    name: newPathTagName,
                    customProperties: {
                        color: DEFAULT_PATH_TAG_COLOR
                    }
                }
            });
            dispatch(_addRootNodeActionSuccess({ node }));
            dispatch(turnOnFirstPopup({ nodeId: node.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_addRootNodeActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _addRootNodeAction() {
    return {
        type: types.ADD_ROOT_NODE
    };
}

function _addRootNodeActionSuccess({ node }) {
    return {
        type: types.ADD_ROOT_NODE_SUCCESS,
        payload: {
            node
        }
    };
}

function _addRootNodeActionFailed() {
    return {
        type: types.ADD_ROOT_NODE_FAILED
    };
}

export function addChildNode({ parentNodeId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tree, dbId, branchId, isCreatingNode } = gridUI;
        if (isCreatingNode) {
            return;
        }
        dispatch(_addChildNodeAction({ parentNodeId }));
        dispatch(turnOnFirstCreateSkeleton({ nodeId: parentNodeId }));
        const nodeDetail = findNode({ tree, nodeId: parentNodeId });
        const children = nodeDetail?.children || [];
        const path = getPath({ tree, nodeId: parentNodeId });
        const newPathTagName = generateNewPathTagName(children);

        try {
            let body = {
                name: newPathTagName,
                customProperties: {
                    color: DEFAULT_PATH_TAG_COLOR
                },
                parentPathTag: path.pathName
            };
            const node = await createTagApi({
                dbId,
                gridId: branchId,
                body
            });
            dispatch(_addChildNodeActionSuccess({ parentNodeId, node }));
            dispatch(turnOnFirstPopup({ nodeId: node.id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
            dispatch(_addChildNodeActionFailed());
        }
    };
}

function _addChildNodeAction({ parentNodeId }) {
    return {
        type: types.ADD_CHILD_NODE,
        payload: {
            parentNodeId
        }
    };
}

function _addChildNodeActionSuccess({ parentNodeId, node }) {
    return {
        type: types.ADD_CHILD_NODE_SUCCESS,
        payload: {
            node,
            parentNodeId
        }
    };
}

function _addChildNodeActionFailed() {
    return {
        type: types.ADD_CHILD_NODE_FAILED
    };
}

export function turnOnFirstPopup({ nodeId }) {
    return {
        type: types.TURN_ON_NODE_POP_UP,
        payload: {
            nodeId
        }
    };
}

export function turnOffFirstPopup() {
    return {
        type: types.TURN_OFF_NODE_POP_UP
    };
}

export function turnOnFirstCreateSkeleton({ nodeId }) {
    return {
        type: types.TURN_ON_FIRST_SKELETON,
        payload: {
            nodeId
        }
    };
}

export function updateNode({ nodeId, parentNodeId, newNode, oldNode, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const {
            tree,
            dbId,
            branchId,
            defaultAccessViewId,
            ROW_START_INDEX,
            ROW_STOP_INDEX,
            quickFilters,
            quickSorts
        } = gridUI;

        /**
         * Validate same name
         */
        let children = [];
        if (!parentNodeId) {
            children = tree;
        } else {
            const nodeDetail = findNode({ tree, nodeId: parentNodeId });
            children = nodeDetail?.children || [];
        }

        const childrenName = children?.filter(node => node.id !== nodeId)?.map(node => node.name);
        if (childrenName?.includes(newNode?.name)) {
            dispatch(
                enqueueSnackbar({
                    message: `A duplicate name exists on the current path`,
                    type: 'info'
                })
            );
            return;
        }

        const path = getPath({ tree, nodeId }) || {};
        dispatch(_updateNodeAction({ nodeId, node: newNode }));
        dispatch(columnActions.addProcessingColumns({ columnIds: [PATH_TAG_ID] }));
        try {
            await updateTagApi({
                dbId,
                gridId: branchId,
                pathTag: path.pathName,
                body: {
                    name: newNode?.name,
                    customProperties: newNode?.customProperties
                }
            });

            let fullPathId = quickFilters?.[columnTypes.PATH_TAG]?.fullPathId;

            if (fullPathId?.includes(nodeId)) {
                dispatch(quickFilterPathTagChange({ fullPathId, nodeId: nodeId }));
            }

            const pathName = path.pathArrIdAndName
                ?.map(node => {
                    if (node.id === newNode.id) {
                        return newNode.name;
                    }
                    return node.name;
                })
                ?.join('/');

            let newQuickFilters = { ...quickFilters };

            newQuickFilters[columnTypes.PATH_TAG] = {
                ...newQuickFilters[columnTypes.PATH_TAG],
                value: pathName,
                type: columnTypes.PATH_TAG,
                operator: OPERATOR.startsWith,
                currentState: nodeId,
                fullPathId
            };

            const { data } = await getColumnsRecordsApi({
                dbId,
                defaultAccessViewId,
                offset: ROW_START_INDEX,
                limit: ROW_STOP_INDEX,
                columnIds: [PATH_TAG_ID],
                dataOptions: [DATA_QUERY_OPTIONS.DATA],
                filterQuery: formatQuickFilters(newQuickFilters),
                sortQuery: quickSorts
            });

            dispatch(dataActions.updateData({ newData: data, isCareData: true }));

            dispatch(columnActions.removeProcessingColumns({ columnIds: [PATH_TAG_ID] }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(columnActions.removeProcessingColumns({ columnIds: [PATH_TAG_ID] }));
            errorCallback && errorCallback(message);
            dispatch(_updateNodeAction({ nodeId, node: oldNode }));
        }
    };
}

function _updateNodeAction({ nodeId, node }) {
    return {
        type: types.UPDATE_NODE,
        payload: {
            nodeId,
            node
        }
    };
}

export function deleteNode({ nodeId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tree, dbId, branchId, defaultAccessViewId, quickFilters, viewFilters } = gridUI;
        const path = getPath({ tree, nodeId });
        dispatch(_deleteNodeAction({ nodeId }));

        const pathName = path?.pathName;

        const outOfDateViewFilterIds = viewFilters
            ?.filter(
                viewFilter =>
                    viewFilter?.columnId === columnTypes.PATH_TAG && pathName?.includes(viewFilters?.values?.[0])
            )
            ?.map(filter => filter?.id);
        const pathTagQuickFilter = quickFilters?.[columnTypes.PATH_TAG];

        const highlightNodeId = pathTagQuickFilter?.currentState;

        let isOutOfDateQuickFilter = false;

        if (highlightNodeId === nodeId) {
            isOutOfDateQuickFilter = true;
        } else {
            if (!highlightNodeId) {
                isOutOfDateQuickFilter = false;
            } else {
                const children = findChildrenByPath({ tree, path: path?.pathName });
                const allNodeChildIds = getAllNodeIds(children) || [];
                if (allNodeChildIds?.includes(highlightNodeId)) {
                    isOutOfDateQuickFilter = true;
                }
            }
        }

        try {
            if (isOutOfDateQuickFilter || outOfDateViewFilterIds?.length > 0) {
                const newQuickFilters = { ...quickFilters };

                if (isOutOfDateQuickFilter) {
                    delete newQuickFilters?.[columnTypes.PATH_TAG];
                    dispatch(
                        columnActions.clearQuickFiltersAfterColumnDeletedOrHidden({ columnId: columnTypes.PATH_TAG })
                    );
                }

                if (outOfDateViewFilterIds?.length > 0) {
                    await deleteViewFiltersApi({
                        dbId,
                        defaultAccessViewId,
                        body: {
                            ids: outOfDateViewFilterIds
                        }
                    });
                    dispatch(viewFilterActions.removeMultipleFilters({ filterIds: outOfDateViewFilterIds }));
                }

                await _fetchRecordsAfterFilter({
                    gridUI: {
                        ...gridUI,
                        quickFilters: newQuickFilters
                    },
                    dispatch
                });
            }

            await deleteTagApi({ dbId, gridId: branchId, pathTag: path.pathName });
            dispatch(_deleteNodeActionSuccess({ nodeId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
            dispatch(_deleteNodeActionFailed({ nodeId }));
        }
    };
}

function _deleteNodeAction({ nodeId }) {
    return {
        type: types.DELETE_NODE,
        payload: {
            nodeId
        }
    };
}

function _deleteNodeActionFailed({ nodeId }) {
    return {
        type: types.DELETE_NODE_FAILED,
        payload: {
            nodeId
        }
    };
}

function _deleteNodeActionSuccess({ nodeId }) {
    return {
        type: types.DELETE_NODE_SUCCESS,
        payload: {
            nodeId
        }
    };
}

export function checkPathTagOnOrOff(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { viewColumns } = gridUI;
        const isHasPathTagInView = Boolean(viewColumns?.find(viewCol => viewCol?.id === columnTypes.PATH_TAG));
        if (!isHasPathTagInView) {
            dispatch(
                enqueueSnackbar({
                    message: `Please add the Path column to the current view to filter with Path`,
                    type: 'info'
                })
            );
            return;
        }
        return callback && callback(isHasPathTagInView);
    };
}

export function quickFilterPathTagChange({ fullPathId, nodeId }) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tree } = gridUI;
        const nodes = findTagByPathId({ tree, fullPathId });
        if (!nodes) {
            console.log('there is some finding problem');
            return;
        }
        const path = nodes?.map(node => node?.name)?.join('/');

        dispatch(
            columnActions.QuickFilterChange({
                columnId: columnTypes.PATH_TAG,
                value: path,
                type: columnTypes.PATH_TAG,
                operator: OPERATOR.startsWith,
                currentState: nodeId,
                fullPathId
            })
        );
    };
}

export function filterPathTag({ fullPathId, pathTagFilter, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { tree, dbId, defaultAccessViewId, isFilteringPathTag, viewColumns } = gridUI;

        const isHasPathTagInView = Boolean(viewColumns?.find(viewCol => viewCol?.id === columnTypes.PATH_TAG));

        if (!isHasPathTagInView) {
            dispatch(
                enqueueSnackbar({
                    message: `Please add the Path column to the current view to filter with Path`,
                    type: 'info'
                })
            );
            return;
        }
        const nodes = findTagByPathId({ tree, fullPathId });
        if (!nodes) {
            console.log('there is some finding problem');
            return;
        }
        if (isFilteringPathTag) {
            console.log('wait for process complete');
            return;
        }
        const path = nodes?.map(node => node?.name)?.join('/');

        const pathTagFilterValue =
            typeof pathTagFilter?.values === 'string' ? pathTagFilter?.values : pathTagFilter?.values?.[0];
        const pathTagFilterId = pathTagFilter?.id;

        dispatch(statusActions.registerDoingAction({ actionId }));
        dispatch(_filterPathTagAction());

        try {
            if (pathTagFilterValue && pathTagFilterValue === path) {
                await deleteViewFilterApi({ defaultAccessViewId, dbId, filterId: pathTagFilterId });
                await _fetchRecordsAfterFilter({ gridUI, dispatch });
                dispatch(_deleteViewFilterActionSuccess({ filterId: pathTagFilterId }));
                dispatch(statusActions.removeDoingAction({ actionId }));
                dispatch(_filterPathTagActionSuccess());
                return;
            }

            if (!pathTagFilterValue) {
                const data = {
                    columnId: PATH_TAG_ID,
                    operator: OPERATOR.startsWith,
                    values: [path]
                };
                const createdFilter = await createViewFilterApi({
                    dbId,
                    defaultAccessViewId,
                    data
                });
                dispatch(
                    _createViewFilterAction({
                        filter: {
                            ...createdFilter,
                            values:
                                typeof createdFilter?.values === 'string'
                                    ? createdFilter?.values
                                    : createdFilter?.values?.[0]
                        }
                    })
                );
                await _fetchRecordsAfterFilter({ gridUI, dispatch });
                dispatch(statusActions.removeDoingAction({ actionId }));
                dispatch(_filterPathTagActionSuccess());
                successCallback && successCallback();
            } else {
                const data = {
                    columnId: PATH_TAG_ID,
                    operator: OPERATOR.startsWith,
                    values: [path]
                };
                const newViewFilter = await updateViewFilterApi({
                    defaultAccessViewId,
                    dbId,
                    filterId: pathTagFilterId,
                    data
                });
                await _fetchRecordsAfterFilter({ gridUI, dispatch });
                dispatch(
                    _updateViewFilterActionSuccess({
                        filterId: pathTagFilterId,
                        newFilter: {
                            ...newViewFilter,
                            values:
                                typeof newViewFilter?.values === 'string'
                                    ? newViewFilter?.values
                                    : newViewFilter?.values?.[0]
                        }
                    })
                );
                dispatch(statusActions.removeDoingAction({ actionId }));
                dispatch(_filterPathTagActionSuccess());
                successCallback && successCallback();
            }
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(_filterPathTagActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _filterPathTagAction() {
    return {
        type: types.FILTER_PATH_TAG
    };
}

function _filterPathTagActionFailed() {
    return {
        type: types.FILTER_PATH_TAG_FAILED
    };
}

function _filterPathTagActionSuccess() {
    return {
        type: types.FILTER_PATH_TAG_SUCCESS
    };
}

export function setPathTagForMultipleRecords({ nodeId }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI, auth } = getState();
        const { tree, dbId, defaultAccessViewId } = gridUI;
        dispatch(statusActions.registerDoingAction({ actionId }));

        const { recordIds, data, isOverRecordLimit, totalSelectedRecords } = await dataActions.getRangeData({
            gridUI,
            auth,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            dispatch(statusActions.removeDoingAction({ actionId }));
            return;
        }

        if (!recordIds?.length) {
            return dispatch(statusActions.removeDoingAction({ actionId }));
        }

        const path = getPath({ tree, nodeId });
        const value = path?.pathName;
        const { redoData, undoData, serverData } = getDropRecordIntoPathData({ recordIds, data, path: value });
        const columns = ['_recordId', PATH_TAG_ID];

        dispatch(
            optimisticActions.commitAction({
                actionId,
                type: types.OPTIMISTIC_PATH_TAG_DROP_ROW_INTO_TREE,
                body: {
                    oldData: undoData
                }
            })
        );
        dispatch(dataActions.updateData({ newData: redoData }));

        try {
            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns,
                            records
                        }
                    });
                })
            );

            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function undoRedoPathTagData({ recordIds, columnId, data }) {
    return async function(dispatch, getState) {
        const actionId = uuidv1();
        const { gridUI } = getState();
        const { dbId, defaultAccessViewId } = gridUI;

        let serverData = [];

        if (!recordIds.length) {
            console.log('there is a weired bug');
            return;
        }
        dispatch(statusActions.registerDoingAction({ actionId }));

        recordIds.forEach(recordId => {
            let rowData = [recordId, getCellValueOnly({ data, rowId: recordId, columnId })];
            serverData.push(rowData);
        });
        const columns = ['_recordId', PATH_TAG_ID];

        try {
            const updateChunks = chunk(serverData, MAX_RECORD_LIMIT);

            await Promise.all(
                updateChunks?.map(async records => {
                    return await setViewRecords({
                        defaultAccessViewId,
                        dbId,
                        body: {
                            columns,
                            records
                        }
                    });
                })
            );

            dispatch(dataActions.updateData({ newData: data }));
            dispatch(optimisticActions.removeAction({ actionId }));
            dispatch(statusActions.removeDoingAction({ actionId }));
        } catch (error) {
            const { message } = error;
            dispatch(statusActions.removeDoingAction({ actionId }));
            dispatch(optimisticActions.revertAction({ actionId }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}

export function setDropPath({ nodeId }) {
    return {
        type: types.SET_DROP_PATH,
        payload: {
            nodeId
        }
    };
}

export function dropRowsIntoPathTag() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dropPath, draggingRows } = gridUI;
        if (!dropPath) {
            // dispatch(rowActions.setDraggingRows([]));
            dispatch(setDropPath({ nodeId: null }));
            return;
        }
        dispatch(setPathTagForMultipleRecords({ nodeId: dropPath, rowIds: draggingRows }));
        dispatch(rowActions.setDraggingRows([]));
        dispatch(setDropPath({ nodeId: null }));
    };
}

export function turnOnDraggingPathTag() {
    return {
        type: types.TURN_ON_DRAGGING_PATH_TAG
    };
}

export function turnOffDraggingPathTag() {
    return {
        type: types.TURN_OFF_DRAGGING_PATH_TAG
    };
}

export function setDraggingPathId(nodeId) {
    return {
        type: types.SET_DRAGGING_PATH,
        payload: {
            nodeId
        }
    };
}

function _movePathHandler() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        let { dbId, branchId, tree, dropPath: movePathId, dragNodeIds, draggingPath } = gridUI;

        // remove nodeId if its parents are included
        for (let nodeId of dragNodeIds) {
            const node = pathNodesObj[nodeId];
            if (node && node?.parentNodeIds?.length) {
                let valid = false;
                for (let pNodeId of node.parentNodeIds) {
                    if (dragNodeIds.includes(pNodeId)) {
                        valid = true;
                        break;
                    }
                }
                if (valid) {
                    dragNodeIds = dragNodeIds.filter(el => el !== nodeId);
                }
            }
        }

        const movePath = getPath({ tree, nodeId: movePathId });

        if (!draggingPath) return;
        const newParentPath = movePath?.pathArrIdAndName
            ?.slice(0, movePath?.pathArrIdAndName?.length)
            ?.map(path => path?.name)
            ?.join('/');
        let movePaths = [];
        let isBreak = false;
        if (Array.isArray(dragNodeIds) && dragNodeIds.length > 0) {
            for (let i = 0; i < dragNodeIds.length; i++) {
                const nodeId = dragNodeIds[i];
                const dragPath = getPath({ tree, nodeId });
                /**
                 * TODO: prevent if same path or move parent to child path.
                 **/
                if (dragPath?.pathName === movePath?.pathName) {
                    isBreak = true;
                    break;
                }
                const oldParentPath = dragPath?.pathArrIdAndName
                    ?.slice(0, dragPath?.pathArrIdAndName?.length - 1)
                    ?.map(path => path?.name)
                    ?.join('/');
                if (oldParentPath === newParentPath && !['BEFORE', 'AFTER'].some(e => draggingPath.includes(e))) {
                    continue;
                }
                movePaths.push({
                    childrenPathToMove: dragPath?.name,
                    oldParentPath
                });
            }
        }

        if (isBreak || !dragNodeIds?.length) return;

        try {
            // dispatch(
            //     _moveNodeSuccess({
            //         toPathId: movePath?.pathId,
            //         dragNodeIds
            //     })
            // );

            let targetPathName = undefined;
            if (['BEFORE', 'AFTER'].some(e => draggingPath.includes(e))) {
                const { gridUI } = getState();
                const { tree } = gridUI;
                const targetPath = draggingPath.replace('BEFORE_', '').replace('AFTER_', '');
                const targetPathDetail = getPath({ tree, nodeId: targetPath });
                targetPathName = targetPathDetail?.name;
            }

            await moveTagApi({
                dbId,
                gridId: branchId,
                body: {
                    movePaths,
                    newParentPath,
                    moveBefore: draggingPath.includes('BEFORE') ? targetPathName : undefined,
                    moveAfter: draggingPath.includes('AFTER') ? targetPathName : undefined
                }
            });

            //update quickFilter pathTag
            const { gridUI } = getState();
            const { tree, quickFilters } = gridUI;
            const columnPathTagQuickFilter = quickFilters[columnTypes.PATH_TAG];
            if (dragNodeIds.includes(columnPathTagQuickFilter?.currentState)) {
                const dragPath = getPath({ tree, nodeId: columnPathTagQuickFilter.currentState });
                if (dragPath?.pathArr.length) {
                    const fullPathId = dragPath?.pathArr.join('/');
                    dispatch(quickFilterPathTagChange({ fullPathId, nodeId: columnPathTagQuickFilter.currentState }));
                    dispatch(
                        fetchRecordsWithFiltersAndSorts({
                            errorCallback: () => {
                                console.log('failed to filter');
                            },
                            successCallback: () => {
                                console.log('filter successfully');
                            }
                        })
                    );
                }
            }
        } catch (error) {
            //set old tree
            dispatch(fetchTagsActionSuccess({ tree: [...tree] }));
            dispatch(
                enqueueSnackbar({
                    message: error?.message || 'Something went wrong!',
                    type: 'info'
                })
            );
        }
    };
}

// function _moveNodeSuccess({ toPathId, dragNodeIds }) {
//     return {
//         type: types.MOVE_NODE_SUCCESS,
//         payload: {
//             toPathId,
//             dragNodeIds
//         }
//     };
// }

export function handleDropPath() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { dropPath, dragPathId, viewColumns } = gridUI;
        if (dragPathId && dragPathId !== dropPath) {
            const pathTagFound = viewColumns?.find(viewCol => viewCol?.id === columnTypes.PATH_TAG);

            if (!pathTagFound || !pathTagFound?.editable) {
                dispatch(
                    enqueueSnackbar({
                        message: `Path column is not available to edit in the current view`,
                        type: 'info'
                    })
                );
                dispatch(resetDropPathAndDragNodeIds());
                return;
            }
            dispatch(_movePathHandler());
            dispatch(resetDropPathAndDragNodeIds());
        } else {
            dispatch(resetDropPathAndDragNodeIds());
        }
    };
}

export function setDragNodeIds(dragNodeIds) {
    return {
        type: types.SET_DRAG_NODE_IDS,
        payload: dragNodeIds
    };
}

export function updateDragNodeIds(nodeId) {
    return (dispatch, getState) => {
        const { gridUI } = getState();
        let { dragNodeIds } = gridUI;
        if (dragNodeIds.includes(nodeId)) {
            dragNodeIds = dragNodeIds.filter(id => id !== nodeId);
        } else {
            dragNodeIds.push(nodeId);
        }
        dispatch(setDragNodeIds([...dragNodeIds]));
    };
}

export function resetDropPathAndDragNodeIds() {
    return {
        type: types.RESET_DROP_PATH_AND_DRAG_NODE_IDS
    };
}

export function setDraggingPath(draggingPath) {
    return {
        type: types.SET_DRAGGING_NODE_PATH,
        payload: draggingPath
    };
}

export function handleSetDropPath({ nodeId }) {
    return (dispatch, getState) => {
        if (!['BEFORE', 'AFTER'].some(e => nodeId.includes(e))) {
            dispatch(setDropPath({ nodeId }));
            return;
        }
        const { gridUI } = getState();
        const { tree } = gridUI;
        const nodePathDetail = getPath({ tree, nodeId: nodeId.replace('BEFORE_', '').replace('AFTER_', '') });
        dispatch(setDropPath({ nodeId: nodePathDetail?.parentPathId }));
    };
}

export function exportMultiplePaths({ columnIds, successCallback, errorCallback }) {
    return (dispatch, getState) => {
        const { gridUI } = getState();
        const { dragNodeIds, tree } = gridUI;

        if (!dragNodeIds?.length) return;

        const pathNames = dragNodeIds?.map(nodeId => getPath({ tree, nodeId })?.pathName);

        const quickFilters = {
            _pathTag: { value: pathNames, operator: OPERATOR.regexp, type: '_pathTag' }
        };
        dispatch(
            exportActions.exportViewData({
                queryParams: {
                    exportFormat: 'csv',
                    columnIds: columnIds,
                    csvDelimiter: ',',
                    query: formatQuickFilters(quickFilters),
                    sort: {}
                },
                successCallback,
                errorCallback
            })
        );
    };
}
