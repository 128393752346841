import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useColumnsPermission } from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import { SYSTEM_COLUMN_IDS } from 'const';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { ACTION_STATUS, showWatchingColumnsTriggers } from 'gridUI/automations/const';
import { useTranslation } from 'react-i18next';
import TooltipActionDisable from '../TooltipActionDisable';
import Tooltip from 'components/tooltip/Base';
import ExclaimationMarkSVG from 'assets/images/svg/ExclaimationMarkSVG';
import classnames from 'classnames';
import { DISABLED_OPACITY } from 'const/style';
import LDBasePortal from 'components/selects/LDBasePortal';
import { updateActionNode } from 'gridUI/automations/action';
import { useDispatch } from 'react-redux';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';

const useStyles = makeStyles(theme => ({
    ml8: {
        marginLeft: 8
    },
    mt12: {
        marginTop: 12
    },
    mb14: {
        marginBottom: 14
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    requiredMark: {
        color: theme.colors.brightRed
    },
    controlClassName: {
        minHeight: 60,
        '&.error': {
            borderColor: theme.colors.brightRed
        }
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    exclaimationMark: {
        border: `1px solid #78778B`,
        borderRadius: `50%`
    }
}));

const styleIcon = {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid #E9EAEF`,
    borderRadius: 4
};

const MapTranslatedColumn = ({
    label,
    selectedNode = {},
    setSelectedNode,
    isRequired,
    postbackCellField = 'result',
    filterColumns = []
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const columnsPermission = useColumnsPermission();
    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const { params = {} } = selectedNode;
    const [columnId, setColumnId] = useState(params?.postbackCellMap?.[postbackCellField]?.publicId);
    const timeout = useRef();

    useEffect(() => {
        setColumnId(params?.postbackCellMap?.[postbackCellField]?.publicId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const viewColumnOptions = useMemo(() => {
        return columnsPermission
            .filter(
                col =>
                    !SYSTEM_COLUMN_IDS?.includes(col.id) && (!filterColumns.length || filterColumns.includes(col.type))
            )
            .map(col => {
                let value = '';
                if (col.id === columnTypes.RECORD_ID) value = '_recordId';
                else if (col.id === columnTypes.PATH_TAG) value = '_path';
                else value = col.publicId;

                return {
                    ...col,
                    value,
                    label: col.name,
                    icon: () => (
                        <ColumnIcon
                            group={col?.group}
                            type={col?.type}
                            customProperties={col?.customProperties}
                            styleCountryFlagWrapper={styleIcon}
                            style2Digit={styleIcon}
                            normalWrapper
                            normalWrapperStyle={{
                                ...styleIcon,
                                position: 'relative',
                                left: -4
                            }}
                        />
                    ),
                    options: null
                };
            });
    }, [columnsPermission, filterColumns]);

    const selectedColumnOption = useMemo(() => {
        return viewColumnOptions.find(opt => opt.value === columnId);
    }, [columnId, viewColumnOptions]);

    const viewableColumnOptions = useMemo(() => {
        return viewColumnOptions.filter(col =>
            typeof col.viewable === 'object' ? col?.viewable?.viewable : !!col?.viewable
        );
    }, [viewColumnOptions]);

    const errorMessage = nodeError?.mapTranslatedText || '';

    const watchingColumns = useMemo(() => {
        if (automationDetail?.triggerNodes?.length) {
            const arr = automationDetail.triggerNodes.reduce((acc, cur) => {
                if (cur?.watchingColumns?.length) {
                    acc = [...acc, ...cur.watchingColumns];
                }
                return acc;
            }, []);
            return new Set(arr);
        }
        return [];
    }, [automationDetail.triggerNodes]);

    const disabledPostback = useMemo(() => {
        if (
            automationDetail?.triggerNodes?.length &&
            automationDetail?.triggerNodes.some(
                trigger => showWatchingColumnsTriggers.includes(trigger.trigger) && trigger.watchingAll
            )
        ) {
            return true;
        }
        return false;
    }, [automationDetail.triggerNodes]);

    const filteredColumnOptions = useMemo(() => {
        return viewableColumnOptions.filter(option => {
            return ![...(watchingColumns || [])].includes(option.value);
        });
    }, [viewableColumnOptions, watchingColumns]);

    const handleChange = useCallback(
        option => {
            setColumnId(option.value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                postbackCellMap: {
                                    [postbackCellField]: option.value
                                }
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setColumnId(params?.postbackCellMap?.[postbackCellField]?.publicId);
                        }
                    })
                );
            }, 100);
        },
        [
            automationDetail.id,
            dispatch,
            params.postbackCellMap,
            selectedNode.id,
            selectedNode.params,
            setSelectedNode,
            postbackCellField
        ]
    );

    return (
        <Grid item>
            <Grid item container wrap="nowrap" className={classes.mb14}>
                <Grid item>
                    <p className="body1">
                        {label || t('automation_translate_result_column')}
                        {isRequired && <span className={classes.requiredMark}>*</span>}
                    </p>
                </Grid>
                {disabledPostback && (
                    <Tooltip title={t('automation_map_translated_text_hint')}>
                        <Grid item className={classes.ml8}>
                            <ExclaimationMarkSVG color="#78778B" bgColor="white" className={classes.exclaimationMark} />
                        </Grid>
                    </Tooltip>
                )}
            </Grid>
            <Grid item>
                <TooltipActionDisable isAction disabled={isDisabled}>
                    <Grid
                        item
                        className={classnames({
                            [classes.disabled]: !!disabledPostback
                        })}
                    >
                        <LDBasePortal
                            ddPlaceholder={'Choose column'}
                            menuPlaceholder={'Find column'}
                            options={filteredColumnOptions}
                            controlClassName={classnames(classes.controlClassName, {
                                error: !!errorMessage
                            })}
                            onChange={handleChange}
                            defaultValue={selectedColumnOption}
                            isUsingContainer
                            noOptionsMessage={() => 'No column with required data type available'}
                        />
                    </Grid>
                    {errorMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorMessage}</p>
                        </Grid>
                    )}
                </TooltipActionDisable>
            </Grid>
        </Grid>
    );
};

export default React.memo(MapTranslatedColumn);
