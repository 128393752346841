import validator from 'validator';

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isValidJSON(str) {
    try {
        return validator.isJSON(str);
    } catch (err) {
        return false;
    }
}
