import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from 'components/skeleton/Base';

function MemberTableSkeleton() {
    return (
        <Grid container direction="column" spacing={1} alignItems="center" wrap="nowrap">
            <Grid item style={{ width: '100%' }}>
                <Skeleton width="100%" height={56} />
            </Grid>
            {Array.from(Array(8).keys()).map(index => (
                <Grid item key={index} style={{ width: '100%' }}>
                    <Skeleton width="100%" height={72} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(MemberTableSkeleton);
