import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.colors.divider}`,
        padding: `18px ${theme.spacing(4)}px`,
        flex: `0 0 auto`
    }
}));

function DialogActions({ children, className, ...rest }) {
    const classes = useStyles();
    return (
        <Grid
            container
            wrap="nowrap"
            direction="row"
            className={`${classes.root} ${className}`}
            alignItems="center"
            justify="flex-end"
            {...rest}
        >
            {children}
        </Grid>
    );
}

export default DialogActions;
