import React from 'react';

function LQASVG({ color = '#17B7FF', width = 16, height = 16, ...other }) {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M56.2303 12.367C57.5977 12.5586 58.5517 13.8226 58.3612 15.1901L51.9838 60.9597C51.7932 62.3273 50.5303 63.2805 49.1629 63.0889L11.1007 57.7546C9.73331 57.5629 8.7793 56.299 8.96985 54.9315L15.3473 9.16185C15.5378 7.79432 16.8008 6.84107 18.1681 7.0327L56.2303 12.367Z"
                fill="white"
                stroke="#EBEBF6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.2806 17.3342C28.5897 17.6885 28.5538 18.227 28.2005 18.537L22.9153 23.1736L20.3983 19.8306C20.1154 19.4549 20.1898 18.9203 20.5644 18.6366C20.939 18.3529 21.472 18.4275 21.7549 18.8032L23.1711 20.6842L27.0812 17.2538C27.4346 16.9439 27.9715 16.9798 28.2806 17.3342Z"
                fill="#05BD6B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.1001 19.1544C31.1651 18.6881 31.5947 18.363 32.0596 18.4282L50.7426 21.0491C51.2075 21.1144 51.5316 21.5452 51.4666 22.0114C51.4016 22.4777 50.972 22.8028 50.5071 22.7376L31.8241 20.1167C31.3592 20.0514 31.0351 19.6206 31.1001 19.1544Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.6704 22.2559C30.7354 21.7897 31.165 21.4646 31.6299 21.5298L44.3288 23.3111C44.7937 23.3763 45.1179 23.8072 45.0529 24.2734C44.9878 24.7397 44.5583 25.0648 44.0934 24.9995L31.3944 23.2182C30.9295 23.153 30.6054 22.7222 30.6704 22.2559Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.6619 30.6294C24.9708 30.9839 24.9346 31.5225 24.5811 31.8322L19.9834 35.8613C19.63 36.1711 19.093 36.1348 18.7841 35.7803C18.4753 35.4258 18.5114 34.8873 18.8649 34.5775L23.4626 30.5484C23.8161 30.2387 24.353 30.2749 24.6619 30.6294Z"
                fill="#ED295C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.1524 30.2599C19.5055 29.9498 20.0425 29.9855 20.3517 30.3397L24.3736 34.9463C24.6828 35.3005 24.6472 35.839 24.294 36.1491C23.9409 36.4593 23.4039 36.4235 23.0947 36.0694L19.0728 31.4628C18.7636 31.1086 18.7992 30.5701 19.1524 30.2599Z"
                fill="#ED295C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.2173 32.6877C29.2822 32.2215 29.7117 31.8963 30.1766 31.9614L48.8552 34.5779C49.3201 34.643 49.6443 35.0738 49.5794 35.54C49.5145 36.0063 49.0849 36.3315 48.62 36.2663L29.9415 33.6499C29.4766 33.5848 29.1523 33.154 29.2173 32.6877Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.7832 35.789C28.8483 35.3228 29.278 34.9977 29.7428 35.063L38.978 36.3601C39.4429 36.4254 39.767 36.8563 39.7019 37.3226C39.6368 37.7888 39.2072 38.1138 38.7423 38.0485L29.5071 36.7514C29.0422 36.6861 28.7181 36.2552 28.7832 35.789Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5064 44.4042C24.8156 44.7584 24.78 45.2969 24.4268 45.607L19.1455 50.2448L16.6283 46.8959C16.3458 46.52 16.4206 45.9855 16.7954 45.7021C17.1702 45.4187 17.7032 45.4937 17.9858 45.8697L19.4018 47.7537L23.307 44.3244C23.6601 44.0143 24.1971 44.05 24.5064 44.4042Z"
                fill="#05BD6B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.3305 46.2248C27.3955 45.7586 27.825 45.4334 28.2899 45.4985L32.2852 46.0583C32.7501 46.1234 33.0743 46.5542 33.0094 47.0204C32.9444 47.4867 32.5149 47.8118 32.05 47.7467L28.0547 47.187C27.5898 47.1219 27.2656 46.6911 27.3305 46.2248Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.8965 49.3264C26.9614 48.8602 27.3909 48.535 27.8558 48.6001L42.9244 50.7101C43.3893 50.7752 43.7136 51.206 43.6487 51.6722C43.5838 52.1385 43.1543 52.4637 42.6894 52.3986L27.6207 50.2885C27.1558 50.2234 26.8315 49.7927 26.8965 49.3264Z"
                fill="#D4D4D4"
            />
            <path
                d="M49 0.5C50.3807 0.5 51.5 1.61929 51.5 3V53C51.5 54.3807 50.3807 55.5 49 55.5H8C6.61929 55.5 5.5 54.3807 5.5 53V3C5.5 1.61929 6.61929 0.5 8 0.5H49Z"
                fill="white"
                stroke="#EBEBF6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9395 30.4337C20.1477 30.6143 20.1705 30.9293 19.9905 31.138L16.2797 35.4394C16.0897 35.6597 15.7525 35.6718 15.5471 35.4658L13.352 33.2644C13.1574 33.0693 13.1574 32.7535 13.352 32.5583L13.457 32.4531C13.6524 32.2571 13.9697 32.2571 14.1651 32.4531L15.8669 34.1598L19.1212 30.3875C19.3019 30.178 19.6185 30.1551 19.8274 30.3364L19.9395 30.4337Z"
                fill="#2D97F2"
                fillOpacity="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9556 31.4355C22.9556 31.1594 23.1794 30.9355 23.4556 30.9355H40.6511C40.9273 30.9355 41.1511 31.1594 41.1511 31.4355V31.5859C41.1511 31.862 40.9273 32.0859 40.6511 32.0859H23.4556C23.1794 32.0859 22.9556 31.862 22.9556 31.5859V31.4355Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9556 34.2656C22.9556 33.9895 23.1794 33.7656 23.4556 33.7656H35.1942C35.4703 33.7656 35.6942 33.9895 35.6942 34.2656V34.4159C35.6942 34.6921 35.4703 34.9159 35.1942 34.9159H23.4556C23.1794 34.9159 22.9556 34.6921 22.9556 34.4159V34.2656Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9396 40.4259C20.1477 40.6065 20.1705 40.9215 19.9905 41.1302L16.2797 45.4316C16.0897 45.6519 15.7525 45.664 15.5471 45.458L13.352 43.2566C13.1574 43.0615 13.1574 42.7457 13.352 42.5505L13.457 42.4453C13.6524 42.2493 13.9697 42.2493 14.1651 42.4453L15.8669 44.152L19.1212 40.3797C19.3019 40.1702 19.6185 40.1473 19.8275 40.3286L19.9396 40.4259Z"
                fill="#2D97F2"
                fillOpacity="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9556 41.4297C22.9556 41.1535 23.1794 40.9297 23.4556 40.9297H40.6511C40.9273 40.9297 41.1511 41.1535 41.1511 41.4297V41.58C41.1511 41.8561 40.9273 42.08 40.6511 42.08H23.4556C23.1794 42.08 22.9556 41.8561 22.9556 41.58V41.4297Z"
                fill="#D4D4D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9556 44.2598C22.9556 43.9836 23.1794 43.7598 23.4556 43.7598H37.3513C37.6274 43.7598 37.8513 43.9836 37.8513 44.2598V44.4101C37.8513 44.6862 37.6274 44.9101 37.3513 44.9101H23.4556C23.1794 44.9101 22.9556 44.6862 22.9556 44.4101V44.2598Z"
                fill="#D4D4D4"
            />
            <path
                opacity="0.59"
                d="M40.7065 47.8857C43.5014 47.8857 46.0306 46.7485 47.8599 44.9139C49.6892 43.0793 50.8231 40.5428 50.8231 37.7397C50.8231 32.1381 46.2963 27.5938 40.7065 27.5938C35.1166 27.5938 30.5898 32.1381 30.5898 37.7397C30.5898 43.3413 35.1211 47.8857 40.7065 47.8857Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 37.7405C29 31.2587 34.2382 26 40.7065 26C47.1749 26 52.4131 31.2587 52.4131 37.7405C52.4131 40.9837 51.0992 43.921 48.9842 46.0421C46.8691 48.1633 43.9404 49.481 40.7065 49.481C34.2431 49.481 29 44.2227 29 37.7405ZM40.7065 29.189C35.9952 29.189 32.1798 33.0191 32.1798 37.7405C32.1798 42.4615 35.9992 46.2919 40.7065 46.2919C43.0625 46.2919 45.1921 45.3352 46.7357 43.7871C48.2793 42.2391 49.2333 40.1033 49.2333 37.7405C49.2333 33.0191 45.4178 29.189 40.7065 29.189Z"
                fill="#4D3F8A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.8463 42.6602L59.3806 54.09C60.0039 54.7077 60.01 55.7133 59.3941 56.3384C58.7757 56.9661 57.765 56.9722 57.1391 56.352L45.6113 44.9286L47.8463 42.6602Z"
                fill="#4D3F8A"
            />
            <path
                d="M40.7862 30.9882C40.44 30.8932 40.0823 31.0969 39.9874 31.4431C39.8924 31.7893 40.0961 32.1469 40.4423 32.2419C40.7885 32.3368 41.1461 32.1331 41.2411 31.7869C41.336 31.4407 41.1324 31.0831 40.7862 30.9882Z"
                fill="#8C84AF"
            />
            <path
                d="M39.287 31.7687C39.2205 31.4159 38.8807 31.1838 38.5279 31.2502C37.0526 31.5281 36.0506 32.4849 35.4102 33.4955C34.7736 34.5001 34.4563 35.6122 34.3557 36.3496C34.3071 36.7053 34.5561 37.0329 34.9118 37.0815C35.2675 37.13 35.5952 36.881 35.6437 36.5253C35.7234 35.9416 35.9873 35.0135 36.5083 34.1913C37.0255 33.3751 37.7576 32.7181 38.7685 32.5278C39.1213 32.4614 39.3534 32.1215 39.287 31.7687Z"
                fill="#8C84AF"
            />
            <rect x="10" y="7" width="12" height="13" rx="1" fill="#77B32B" fillOpacity="0.8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9245 15.3208C17.8264 15.7843 18.7294 15.7977 18.7485 15.7978C19.047 15.7978 19.2893 16.0398 19.2893 16.3395C19.2893 16.6382 19.0458 16.8811 18.7463 16.8811C18.7055 16.8811 17.5338 16.8741 16.3632 16.2497C16.2439 16.1861 16.1219 16.114 15.9991 16.0338L15.9085 15.9746L15.818 16.0338C15.6953 16.114 15.5732 16.186 15.4539 16.2497C14.2833 16.8742 13.1116 16.8811 13.0708 16.8811C12.7718 16.8811 12.5293 16.6386 12.5293 16.3395C12.5293 16.0403 12.7718 15.7978 13.0708 15.7978L13.0734 15.7978L13.0747 15.7978L13.0775 15.7977L13.0868 15.7975L13.1198 15.7962C13.1478 15.7949 13.1879 15.7925 13.2382 15.7882C13.3388 15.7797 13.4807 15.7636 13.6502 15.7331C13.9883 15.6722 14.4401 15.5534 14.8928 15.3208L15.0706 15.2294L14.9475 15.0718C14.4709 14.4613 14.091 13.6283 13.9808 12.4892L13.9664 12.3397H13.0708C12.7718 12.3397 12.5293 12.0972 12.5293 11.7981C12.5293 11.4989 12.7718 11.2564 13.0708 11.2564H15.3672V10.6627C15.3672 10.3636 15.6096 10.1211 15.9087 10.1211C16.2077 10.1211 16.4502 10.3636 16.4502 10.6627V11.2564H18.7464C19.0455 11.2564 19.2879 11.4989 19.2879 11.7981C19.2879 12.0972 19.0455 12.3397 18.7464 12.3397H17.8509L17.8364 12.4892C17.7263 13.6283 17.3464 14.4613 16.8697 15.0718L16.7467 15.2294L16.9245 15.3208ZM15.7766 14.369L15.9087 14.5439L16.0407 14.369C16.4193 13.8674 16.6523 13.2497 16.7427 12.5257L16.7659 12.3397H15.0513L15.0746 12.5257C15.165 13.2497 15.398 13.8674 15.7766 14.369Z"
                fill="white"
            />
            <rect x="23" y="7" width="12" height="13" rx="1" fill="#F8AD13" fillOpacity="0.8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.0187 16.3439L28.33 9.72132C28.4397 9.50186 28.664 9.36328 28.9093 9.36328C29.1546 9.36328 29.3788 9.50188 29.4886 9.7213L32.7998 16.3439C32.9597 16.6639 32.83 17.0529 32.5101 17.2129C32.1902 17.3729 31.8012 17.2432 31.6412 16.9232L30.9924 15.6256H26.826L26.1772 16.9232C26.0638 17.1499 25.8353 17.2814 25.5973 17.2814C25.5002 17.2814 25.4016 17.2595 25.3084 17.2129C24.9884 17.0528 24.8588 16.6639 25.0187 16.3439ZM30.3449 14.3303L28.9093 11.4591L27.4737 14.3303H30.3449Z"
                fill="white"
            />
            <rect x="36" y="7" width="12" height="13" rx="1" fill="#EB4F5E" fillOpacity="0.8" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.3594 9.50311C43.8419 9.70702 44.2728 10.1647 44.2728 10.7636C44.2728 11.09 44.0082 11.3546 43.6819 11.3546C43.3555 11.3546 43.091 11.09 43.091 10.7636L43.0906 10.761C43.0903 10.7592 43.0896 10.756 43.0879 10.7516C43.0846 10.7425 43.0774 10.7279 43.0631 10.7095C43.0338 10.6717 42.9802 10.6259 42.8993 10.5917C42.7459 10.5268 42.5004 10.5082 42.2122 10.6804C42.1027 10.7458 41.9868 10.9319 41.98 11.3311C41.9765 11.5393 42.0054 11.7458 42.0451 11.9157H43.7055C44.0319 11.9157 44.2964 12.1802 44.2964 12.5066C44.2964 12.8329 44.0319 13.0975 43.7055 13.0975H41.6255C41.3521 13.0975 40.9431 13.1682 40.6244 13.3551C40.3358 13.5243 40.1364 13.7713 40.1364 14.2002C40.1364 14.7133 40.3201 15.0115 40.5153 15.1931C40.7144 15.3783 40.9525 15.4657 41.1008 15.4911H44.2728C44.5991 15.4911 44.8637 15.7557 44.8637 16.082C44.8637 16.4084 44.5991 16.6729 44.2728 16.6729H41.0216L40.9853 16.6684C40.6255 16.6237 40.1272 16.4463 39.7102 16.0583C39.2751 15.6534 38.9546 15.042 38.9546 14.2002C38.9546 13.2743 39.4486 12.6744 40.0267 12.3355C40.2953 12.178 40.5839 12.0741 40.8578 12.009C40.8169 11.7856 40.7943 11.5466 40.7983 11.311C40.8073 10.7819 40.9593 10.0522 41.6061 9.6658C42.2082 9.30613 42.8491 9.28742 43.3594 9.50311Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(LQASVG);
