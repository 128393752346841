import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TextareaAutosize from 'react-autosize-textarea';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    multiText: {
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.colors.border}`,
        padding: `6px ${theme.spacing(3)}px`,
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`
        },
        borderRadius: 4,
        resize: 'none',
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        backgroundColor: theme.palette.common.white
    }
}));

function MultipleLine({ defaultValue, onChange }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const value = React.useMemo(() => {
        return typeof defaultValue === 'string' || typeof defaultValue === 'number' ? defaultValue : '';
    }, [defaultValue]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <p className="body1 inline">{t('global_default_value')}</p>{' '}
                <p className="caption inline">({t('global_optional')})</p>
            </Grid>
            <Grid item>
                <TextareaAutosize
                    rows={4}
                    className={`${classes.multiText}`}
                    onChange={e => onChange(e?.target?.value)}
                    value={value || ''}
                    placeholder={t('set_default_value')}
                />
            </Grid>
        </Grid>
    );
}

export default MultipleLine;
