import React from 'react';

function QuestionSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 7.00002C0 3.13254 3.13251 3.5882e-05 6.99998 3.5882e-05C10.8675 3.5882e-05 14 3.13251 14 7.00002C14 10.8675 10.8675 14 6.99998 14C3.13251 14 0 10.8675 0 7.00002Z"
                fill="white"
            />
            <path
                d="M0 7.00002C0 3.13254 3.13251 3.5882e-05 6.99998 3.5882e-05C10.8675 3.5882e-05 14 3.13251 14 7.00002C14 10.8675 10.8675 14 6.99998 14C3.13251 14 0 10.8675 0 7.00002Z"
                fill="#ACADB9"
            />
            <path
                d="M7.08752 9.69922C7.5211 9.69922 7.87502 10.0524 7.87502 10.4867C7.87502 10.9211 7.5211 11.2742 7.08752 11.2742C6.65316 11.2742 6.30078 10.9211 6.30078 10.4867C6.30078 10.0524 6.65316 9.69922 7.08752 9.69922Z"
                fill="white"
            />
            <path
                d="M7 3.0625C8.20607 3.0625 9.1875 4.04393 9.1875 5.25C9.1875 6.16977 8.61345 6.9819 7.76708 7.29828L7.63811 7.34197V8.08979C7.63811 8.44206 7.35152 8.72791 7 8.72791C6.67552 8.72791 6.407 8.48435 6.36761 8.16981L6.36263 8.08979V6.79939C6.36263 6.47421 6.60619 6.20501 6.92015 6.16551L7 6.16053C7.50228 6.16053 7.91127 5.75228 7.91127 5.25C7.91127 4.74697 7.50228 4.33798 7 4.33798C6.52911 4.33798 6.14022 4.69745 6.09344 5.15686L6.08873 5.25C6.08873 5.60152 5.80288 5.88811 5.45061 5.88811C5.09909 5.88811 4.8125 5.60152 4.8125 5.25C4.8125 4.04393 5.79393 3.0625 7 3.0625Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(QuestionSVG);
