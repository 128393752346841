import * as types from './types';
import { USER_LOGOUT_SUCCESS } from '../auth/types';
import produce from 'immer';

const initialState = {
    list: [],
    isFetchingDatabases: false,
    workspaceDbs: {
        workspace_id: {
            dbIds: null
        }
    },
    isDoing: false,
    isCreatingDatabase: false,
    error: '',
    isShowFirstPopupCreate: null,
    isSkeletonLoadingId: null,
    workspaceDatabases: {
        sampleWorkspaceId: {
            isFetching: false,
            list: null
        }
    },
    backup: {
        dbId: {
            files: [],
            isFetching: false,
            isFetched: false,
            isBackuping: false,
            error: null,
            total: 0,
            offset: 0
        }
    },
    shareDatabases: {
        sharedGroups: [],
        isFetching: false,
        error: ''
    },
    scheduleBackup: {}
};

export default function Database(state = initialState, { type, payload }) {
    switch (type) {
        case USER_LOGOUT_SUCCESS:
            return initialState;
        case types.FETCH_DATABASE_LIST_BY_WORKSPACE: {
            const newWorkspaceDatabases = { ...state.workspaceDatabases };
            const current = newWorkspaceDatabases[payload] || {};

            newWorkspaceDatabases[payload] = {
                ...current,
                isFetching: true
            };

            return {
                ...state,
                workspaceDatabases: newWorkspaceDatabases
            };
        }
        case types.FETCH_DATABASE_LIST_BY_WORKSPACE_SUCCESS: {
            const newWorkspaceDatabases = { ...state.workspaceDatabases };
            newWorkspaceDatabases[payload.workspaceId] = {
                isFetching: false,
                list: payload.list
            };

            return {
                ...state,
                workspaceDatabases: newWorkspaceDatabases
            };
        }
        case types.FETCH_DATABASE_LIST_BY_WORKSPACE_FAILED: {
            const { workspaceId } = payload;
            const db = state.workspaceDatabases[workspaceId] || {};

            return {
                ...state,
                workspaceDatabases: {
                    ...state.workspaceDatabases,
                    [workspaceId]: {
                        isFetching: false,
                        list: db.list || []
                    }
                }
            };
        }

        case types.GET_DATABASE_LIST: {
            return {
                ...state,
                isFetchingDatabases: true
            };
        }

        case types.GET_DATABASE_LIST_FAILED: {
            const { error } = payload;
            return {
                ...state,
                isFetchingDatabases: false,
                error
            };
        }
        case types.GET_DATABASE_LIST_SUCCESS: {
            const { dbs = [], workspaceId, isForced } = payload;
            const { workspaceDbs } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {};
                }
                draft[workspaceId].dbIds = (dbs?.length && dbs?.map(db => db.id)) || [];
            });
            return {
                ...state,
                workspaceDbs: newWorkspaceDbs,
                list: isForced ? dbs : [...(state?.list || []), ...dbs],
                isFetchingDatabases: false
            };
        }

        case types.GET_DATABASE_DETAIL_SUCCESS: {
            const dbDetail = payload;

            return {
                ...state,
                list: [dbDetail, ...(state?.list || [])]
            };
        }

        case types.CREATE_DATABASE_SOCKET: {
            const { database, workspaceId } = payload;
            const { workspaceDbs } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {
                        dbIds: []
                    };
                }
                draft[workspaceId].dbIds = [database.id, ...draft[workspaceId].dbIds];
            });

            return {
                ...state,
                list: [database, ...state.list],
                workspaceDbs: newWorkspaceDbs
            };
        }

        case types.CREATE_DATABASE: {
            const { database, workspaceId } = payload;
            const { workspaceDbs } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {
                        dbIds: []
                    };
                }
                draft[workspaceId].dbIds = [database.id, ...draft[workspaceId].dbIds];
            });

            return {
                ...state,
                list: [database, ...state.list],
                workspaceDbs: newWorkspaceDbs,
                isCreatingDatabase: true
            };
        }

        case types.CREATE_DATABASE_FAILED: {
            const { id, workspaceId } = payload;
            const { workspaceDbs } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {
                        dbIds: []
                    };
                }
                draft[workspaceId].dbIds = draft?.[workspaceId]?.dbIds?.filter(dbId => dbId !== id);
            });

            return {
                ...state,
                workspaceDbs: newWorkspaceDbs,
                list: [...(state?.list || [])].filter(db => db.id !== id),
                isCreatingDatabase: false
            };
        }

        case types.CREATE_DATABASE_SUCCESS: {
            const { oldId, newId, newDatabase, workspaceId } = payload;
            const { workspaceDbs } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {
                        dbIds: []
                    };
                }
                draft[workspaceId].dbIds = draft?.[workspaceId]?.dbIds?.map(dbId => {
                    if (dbId === oldId) {
                        return newId;
                    }
                    return dbId;
                });
            });

            const newList = [...(state?.list || [])].map(db => {
                if (db.id === oldId) {
                    db = {
                        ...newDatabase,
                        new: true
                    };
                    console.log('dbdbdbdb', db);
                }
                return db;
            });

            return {
                ...state,
                list: newList,
                workspaceDbs: newWorkspaceDbs,
                isCreatingDatabase: false
            };
        }

        case types.DELETE_DATABASE: {
            const { dbId } = payload;
            const newList = [...(state?.list || [])].map(db => {
                if (db.id === dbId) {
                    db.isDeleted = true;
                }
                return db;
            });

            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_DATABASE_FAILED: {
            const { dbId } = payload;
            const newList = [...(state?.list || [])].map(db => {
                if (db.id === dbId) {
                    db.isDeleted = true;
                }
                return db;
            });

            return {
                ...state,
                list: newList
            };
        }

        case types.DELETE_DATABASE_SUCCESS: {
            const { dbId, workspaceId } = payload;
            const { workspaceDbs, list } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {
                        dbIds: []
                    };
                }
                draft[workspaceId].dbIds = draft?.[workspaceId]?.dbIds?.filter(id => id !== dbId);
            });

            const newList = list.filter(db => db.id !== dbId);
            return {
                ...state,
                list: newList,
                workspaceDbs: newWorkspaceDbs
            };
        }

        case types.UPDATE_DATABASE: {
            const { dbId, newDatabase } = payload;
            const listCopied = [...(state?.list || [])];

            let newDatabases = listCopied.map(database => {
                if (database.id !== dbId) return database;
                return { ...database, ...newDatabase };
            });

            return {
                ...state,
                list: newDatabases
            };
        }

        case types.UPDATE_DATABASE_WITH_TEMP_ID: {
            const { dbId, workspaceId, newDatabase } = payload;

            const { workspaceDbs, list = [] } = state;

            const newWorkspaceDbs = produce(workspaceDbs, draft => {
                if (!draft?.[workspaceId]) {
                    draft[workspaceId] = {
                        dbIds: []
                    };
                }

                const newDbIds = draft?.[workspaceId]?.dbIds?.filter(id => id !== dbId) || [];
                draft[workspaceId].dbIds = [newDatabase?.id, ...newDbIds];
            });

            let newDatabases = list?.map(database => {
                if (database.id === dbId) {
                    return {
                        ...database,
                        ...newDatabase
                    };
                }
                return database;
            });

            return {
                ...state,
                list: newDatabases,
                workspaceDbs: newWorkspaceDbs
            };
        }

        case types.UPDATE_DATABASE_SUCCESS: {
            return state;
        }

        case types.UPDATE_DATABASE_FAILED: {
            const { dbId, oldDatabase, error } = payload;
            const listCopied = [...(state?.list || [])];
            let found = listCopied.find(item => item.id === dbId);
            if (found) {
                found.name = oldDatabase.name;
            }
            return {
                ...state,
                list: listCopied,
                error: error
            };
        }

        case types.TURN_OFF_SHOW_FIRST_POP_UP_CREATE_DATABASE: {
            return {
                ...state,
                isShowFirstPopupCreate: null,
                isSkeletonLoadingId: null
            };
        }

        case types.TURN_ON_SHOW_FIRST_POP_UP_CREATE_DATABASE: {
            const { databaseId } = payload;
            return {
                ...state,
                isShowFirstPopupCreate: databaseId
            };
        }

        case types.TURN_ON_FIRST_CREATE_SKELETON: {
            const { isSkeletonLoadingId } = payload;
            return {
                ...state,
                isSkeletonLoadingId
            };
        }

        case types.FETCH_DATABASE_BACKUP_FILES: {
            const { dbId } = payload;
            return {
                ...state,
                backup: {
                    ...state.backup,
                    [dbId]: {
                        ...state.backup[dbId],
                        isFetching: true
                    }
                }
            };
        }

        case types.FETCH_DATABASE_BACKUP_FILES_SUCCESS: {
            const { dbId, files, total, offset } = payload;
            return {
                ...state,
                backup: {
                    ...state.backup,
                    [dbId]: {
                        ...state.backup[dbId],
                        files: [...(offset === 0 ? [] : state.backup?.[dbId]?.files || []), ...files],
                        isFetching: false,
                        isFetched: true,
                        total,
                        offset
                    }
                }
            };
        }

        case types.FETCH_DATABASE_BACKUP_FILES_FAILED: {
            const { dbId, message } = payload;
            return {
                ...state,
                backup: {
                    ...state.backup,
                    [dbId]: {
                        ...state.backup[dbId],
                        error: message,
                        isFetching: false
                    }
                }
            };
        }

        case types.BACKUP_DATABASE_SOCKET_SUCCESS: {
            const { dbId, backupFile } = payload;
            return {
                ...state,
                backup: {
                    ...state.backup,
                    [dbId]: {
                        ...state.backup[dbId],
                        files: [backupFile, ...(state.backup?.[dbId]?.files || [])]
                    }
                }
            };
        }

        case types.SET_DATABASE_BACKUP_SCHEDULE: {
            const { data } = payload;
            return {
                ...state,
                scheduleBackup: data
            };
        }

        case types.SET_IS_BACKUPING: {
            const { dbId, isBackuping } = payload;

            return {
                ...state,
                backup: {
                    ...state.backup,
                    [dbId]: {
                        ...state.backup[dbId],
                        isBackuping
                    }
                }
            };
        }

        case types.SET_DATABASE_STATUS: {
            const { status, dbId } = payload;
            const { list } = state;

            const newList = list?.map(db => {
                if (db?.id === dbId) {
                    db.status = status;
                }
                return db;
            });

            return {
                ...state,
                list: newList
            };
        }

        case types.FETCH_DATABASE_GROUPS_SUCCESS: {
            const { groups } = payload;
            return {
                ...state,
                shareDatabases: {
                    ...state.shareDatabases,
                    isFetching: false,
                    sharedGroups: groups
                }
            };
        }

        case types.FETCH_DATABASE_GROUPS_FAILED: {
            const { error } = payload;
            return {
                ...state,
                shareDatabases: {
                    ...state.shareDatabases,
                    error,
                    isFetching: false
                }
            };
        }

        case types.FETCH_DATABASE_GROUPS: {
            return {
                ...state,
                shareDatabases: {
                    ...state.shareDatabases,
                    isFetching: true
                }
            };
        }

        case types.SHARE_DATABASE_SUCCESS: {
            const { groups } = payload;
            return {
                ...state,
                shareDatabases: {
                    ...state.shareDatabases,
                    sharedGroups: [...state.shareDatabases.sharedGroups, ...groups]
                }
            };
        }

        case types.UNSHARE_DATABASE_SUCCESS: {
            const { groupIds } = payload;
            const newSharedGroups = state.shareDatabases.sharedGroups.filter(group => !groupIds.includes(group.id));
            return {
                ...state,
                shareDatabases: {
                    ...state.shareDatabases,
                    sharedGroups: newSharedGroups
                }
            };
        }

        default:
            return state;
    }
}
