import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ApplyIconSVG from 'assets/images/svg/ApplyIconSVG';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import TBIconSVG from 'assets/images/svg/TBIconSVG';
import { DISABLED_OPACITY } from 'const/style';
import { TooltipTerm } from 'gridUI/table/grid/cellOverlay/cellEdit/TranslationEdit';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'auto'
    },
    tbItem: {
        padding: '8px 12px',
        '&:hover': {
            background: theme.colors.background,
            '& .apply-icon': {
                opacity: 1,
                cursor: 'pointer'
            }
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    lineThrough: {
        textDecoration: 'line-through'
    }
}));

function Glossary({ texts, value, onChange, disabled }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item container direction="column">
                {(texts?.length === 0 || !texts) && !value && (
                    <Grid item style={{ width: '100%', paddingLeft: 20, paddingTop: 20 }} container alignItems="center">
                        <p className="body2 text-center text-grey-blue">{t(`cell_edit_tm_no_suggestion`)}</p>
                    </Grid>
                )}
                {(texts || []).map((text, idx) => {
                    const isForbidden = text.term?.forbidden;
                    return (
                        <TooltipTerm key={idx} term={text.term} placement="top" arrow>
                            <Grid
                                item
                                className={`${classes.tbItem} ${disabled && classes.disabled}`}
                                onDoubleClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (isForbidden) return;
                                    onChange(text.langTargetText);
                                }}
                            >
                                <Grid container alignItems="center" wrap="nowrap">
                                    <Grid item xs={6}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <TBIconSVG />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2">{text.langSourceText}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container alignItems="baseline" spacing={2} wrap="nowrap">
                                            <Grid item>
                                                <Grid item className={classes.middleDot} />
                                            </Grid>
                                            <Grid
                                                container
                                                alignItems="center"
                                                spacing={2}
                                                wrap="nowrap"
                                                justify="space-between"
                                            >
                                                <Grid item className={classes.langTargetText}>
                                                    <p
                                                        className={classNames(`body2`, {
                                                            'line-through': isForbidden
                                                        })}
                                                    >
                                                        {text.langTargetText}
                                                    </p>
                                                </Grid>
                                                {!isForbidden && (
                                                    <Tooltip title="Apply this term">
                                                        <Grid item className={`${classes.applyIcon} apply-icon`}>
                                                            <ApplyIconSVG
                                                                width="20"
                                                                height="20"
                                                                style={{ verticalAlign: 'text-bottom' }}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    onChange(text.langTargetText);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TooltipTerm>
                    );
                })}
            </Grid>
        </Grid>
    );
}

export default Glossary;
