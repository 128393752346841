import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//import { useCompany } from 'hooks/permission';
import CompanyItem from './CompanyItem';

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 13,
        borderBottom: `1px solid ${theme.colors.border}`,
        maxHeight: 300,
        maxWidth: 300,
        overflow: 'auto'
    }
}));

function CompanyList({ companies, onItemClick }) {
    const classes = useStyles();

    return (
        <Grid className={classes.root} container direction={'column'} wrap="nowrap">
            {companies.map(company => {
                return <CompanyItem key={`company-${company.id}`} company={company} onClick={onItemClick} />;
            })}
        </Grid>
    );
}

export default React.memo(CompanyList);
