import React from 'react';

function MemoQGridlySVG({ color = '#17B7FF', width = 16, height = 16, ...other }) {
    return (
        <svg width="302" height="212" viewBox="0 0 302 212" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path
                    d="M123.654 182C134.425 182 143.157 173.279 143.157 162.521V144.316C143.157 142.107 144.964 140.354 147.137 139.955C157.3 138.086 165 129.191 165 118.5C165 107.809 157.3 98.9138 147.137 97.0453C144.964 96.6458 143.157 94.8932 143.157 92.684L143.157 74.4785C143.157 63.7208 134.425 55 123.654 55L35.5026 55C24.7316 55 16 63.7208 16 74.4785L16 162.521C16 173.279 24.7316 182 35.5026 182L123.654 182Z"
                    fill="white"
                />
                <path
                    d="M142.657 162.521C142.657 173.002 134.15 181.5 123.654 181.5L35.5026 181.5C25.0072 181.5 16.5 173.002 16.5 162.521L16.5 74.4785C16.5 63.9976 25.0072 55.5 35.5026 55.5L123.654 55.5C134.15 55.5 142.657 63.9976 142.657 74.4785L142.657 92.684C142.657 95.1873 144.692 97.1043 147.046 97.537C156.977 99.3628 164.5 108.055 164.5 118.5C164.5 128.945 156.977 137.637 147.046 139.463C144.692 139.896 142.657 141.813 142.657 144.316V162.521Z"
                    stroke="#E9EDF1"
                />
            </g>
            <g filter="url(#filter1_d)">
                <path
                    d="M277.521 182C288.279 182 297 173.279 297 162.521L297 74.4785C297 63.7208 288.279 55 277.521 55L189.479 55C178.721 55 170 63.7208 170 74.4785L170 92.684C170 94.8932 171.807 96.6457 173.979 97.0461C184.128 98.9166 191.816 107.81 191.816 118.5C191.816 129.19 184.128 138.083 173.979 139.954C171.807 140.354 170 142.107 170 144.316L170 162.521C170 173.279 178.721 182 189.479 182L277.521 182Z"
                    fill="white"
                />
                <path
                    d="M296.5 162.521C296.5 173.003 288.003 181.5 277.521 181.5L189.479 181.5C178.997 181.5 170.5 173.003 170.5 162.521L170.5 144.316C170.5 142.401 172.078 140.813 174.07 140.446C184.451 138.532 192.316 129.435 192.316 118.5C192.316 107.565 184.451 98.4678 174.07 96.5544C172.078 96.1873 170.5 94.599 170.5 92.684L170.5 74.4785C170.5 63.997 178.997 55.5 189.479 55.5L277.521 55.5C288.003 55.5 296.5 63.997 296.5 74.4785L296.5 162.521Z"
                    stroke="#E9EDF1"
                />
            </g>
            <path
                d="M252.667 118C252.667 114.26 252.024 110.67 250.845 107.333H250.841C248.834 101.657 245.271 96.7148 240.665 93.0212C240.657 93.0148 240.649 93.0084 240.641 93.0021C240.191 92.6425 239.732 92.2949 239.263 91.9594C239.244 91.9453 239.224 91.9313 239.204 91.9173C238.978 91.7566 238.751 91.5985 238.521 91.4436C238.516 91.4401 238.511 91.4366 238.506 91.4332C238.271 91.2755 238.034 91.121 237.796 90.9695C237.767 90.9515 237.739 90.9337 237.71 90.9158C237.497 90.7815 237.282 90.6497 237.066 90.5202C237.037 90.5026 237.007 90.4847 236.978 90.4671C236.742 90.3273 236.505 90.1905 236.266 90.0565C236.232 90.0378 236.198 90.0195 236.164 90.0009C235.956 89.8853 235.747 89.7723 235.536 89.6614C235.489 89.637 235.443 89.6124 235.396 89.5882C235.158 89.4643 234.918 89.343 234.676 89.225C234.643 89.2089 234.61 89.1934 234.577 89.1775C234.365 89.0754 234.153 88.9759 233.939 88.8783C233.881 88.8517 233.823 88.8251 233.765 88.7989C233.521 88.6896 233.277 88.5827 233.031 88.4793C233.007 88.4694 232.983 88.4601 232.96 88.4502C232.735 88.3567 232.509 88.2661 232.282 88.1775C232.217 88.1525 232.153 88.1276 232.089 88.1029C231.839 88.0074 231.588 87.9137 231.335 87.8242H231.334C218.906 92.2169 210 104.068 210 118C210 121.741 210.644 125.33 211.823 128.667C216.217 116.239 228.068 107.334 242 107.334C247.891 107.333 252.667 112.109 252.667 118Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M231.349 118.553C231.339 118.37 231.334 118.185 231.334 118C231.334 117.994 231.335 117.989 231.335 117.983C231.344 112.1 236.115 107.333 241.999 107.333C241.554 107.333 241.11 107.343 240.668 107.361H240.668C239.838 107.395 239.016 107.461 238.204 107.557C238.198 107.558 238.192 107.558 238.185 107.559C237.395 107.653 236.614 107.778 235.842 107.929C235.808 107.935 235.773 107.941 235.739 107.947C234.989 108.096 234.25 108.274 233.521 108.474C233.468 108.488 233.413 108.501 233.36 108.516C232.648 108.715 231.947 108.941 231.256 109.188C231.189 109.212 231.121 109.234 231.054 109.258C230.377 109.505 229.711 109.776 229.056 110.066C228.981 110.1 228.905 110.131 228.831 110.165C228.187 110.456 227.555 110.77 226.934 111.102C226.854 111.145 226.775 111.186 226.695 111.23C226.084 111.563 225.485 111.918 224.899 112.289C224.818 112.341 224.736 112.392 224.655 112.444C224.076 112.819 223.51 113.212 222.957 113.622C222.878 113.681 222.799 113.74 222.72 113.799C222.172 114.214 221.639 114.645 221.119 115.093C221.044 115.158 220.969 115.224 220.894 115.289C220.379 115.742 219.877 116.211 219.392 116.696C219.324 116.764 219.256 116.833 219.189 116.902C218.705 117.394 218.236 117.901 217.784 118.423C217.725 118.492 217.668 118.561 217.609 118.631C217.158 119.162 216.722 119.706 216.306 120.266C216.256 120.333 216.209 120.401 216.16 120.468C215.743 121.038 215.342 121.621 214.962 122.22C214.924 122.28 214.888 122.341 214.851 122.401C214.468 123.013 214.103 123.637 213.762 124.276C213.737 124.324 213.713 124.372 213.688 124.42C213.342 125.076 213.015 125.745 212.714 126.427C212.701 126.456 212.69 126.485 212.677 126.514C212.368 127.219 212.082 127.936 211.824 128.666C216.217 141.094 228.068 149.999 242.001 149.999C245.742 149.999 251.334 149.999 255.334 149.999C243.071 145.664 231.649 132.293 231.349 118.553Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M252.666 118C252.666 123.805 248.028 128.523 242.256 128.66C242.275 128.664 242.214 128.666 241.999 128.666H251.332C258.071 128.666 264.076 131.794 267.986 136.674C271.769 131.418 273.999 124.97 273.999 118C273.999 117.743 273.995 117.488 273.989 117.232C273.987 117.146 273.984 117.06 273.981 116.974C273.975 116.806 273.97 116.638 273.962 116.47C273.957 116.367 273.951 116.263 273.945 116.159C273.937 116.011 273.927 115.863 273.917 115.715C273.909 115.603 273.901 115.491 273.891 115.38C273.88 115.242 273.868 115.105 273.855 114.968C273.844 114.85 273.833 114.733 273.821 114.616C273.807 114.489 273.792 114.362 273.777 114.236C273.763 114.111 273.749 113.986 273.733 113.862C273.731 113.847 273.729 113.833 273.727 113.818C273.678 113.44 273.62 113.064 273.558 112.69C273.546 112.62 273.535 112.55 273.523 112.481C273.494 112.314 273.463 112.148 273.431 111.981C273.418 111.914 273.406 111.847 273.393 111.78C273.359 111.608 273.323 111.437 273.287 111.266C273.275 111.211 273.263 111.157 273.251 111.103C273.211 110.919 273.169 110.736 273.126 110.553C273.12 110.529 273.114 110.506 273.109 110.483C269.727 96.437 257.083 86 241.999 86C238.258 86 234.669 86.644 231.332 87.8234C243.76 92.2165 252.666 104.067 252.666 118Z"
                fill="url(#paint2_linear)"
            />
            <path
                d="M251.333 128.666H242C236.295 128.666 231.636 124.187 231.348 118.554C231.617 130.832 240.767 142.81 251.462 148.314C251.716 148.445 251.971 148.573 252.227 148.697C252.329 148.746 252.432 148.793 252.535 148.842C252.768 148.952 253.002 149.06 253.237 149.164C253.399 149.235 253.562 149.303 253.724 149.371C253.906 149.447 254.087 149.526 254.269 149.598C254.623 149.739 254.978 149.874 255.333 150H272.667C272.667 138.218 263.116 128.666 251.333 128.666Z"
                fill="url(#paint3_linear)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120.061 109.858L97.2516 95.7578L88.755 100.987V100.997C86.4941 102.311 84.9727 104.759 84.9727 107.564C84.9727 110.452 86.5846 112.962 88.957 114.245L92.7204 116.546L111.661 128.408L119.949 123.27C122.377 122.004 124.036 119.464 124.036 116.535C124.036 113.651 122.428 111.142 120.061 109.858Z"
                fill="#FBAF17"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M111.662 128.409L92.7213 116.547L80.0859 124.313V144.588C80.094 144.588 80.1015 144.588 80.1092 144.588C83.9759 144.588 87.5732 143.441 90.5878 141.475"
                fill="#E53182"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M80.0498 124.334L70.5184 118.455L48.1215 104.739C45.2667 106.526 42.3833 108.276 39.5556 110.106C37.3688 111.522 35.9922 113.908 35.9922 116.535C35.9922 119.434 37.6163 121.952 40.0031 123.231C40.0031 123.231 69.6111 141.466 69.6125 141.464C72.6247 143.433 76.2194 144.582 80.0842 144.587V124.313"
                fill="#9643DC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.1211 104.74L70.519 118.456L67.2616 116.447L76.5117 110.89V110.884C78.6438 109.541 80.0615 107.166 80.0615 104.458C80.0615 101.75 78.6438 99.3756 76.5117 98.0321L67.7607 92.6582L48.2013 104.689L48.1211 104.74Z"
                fill="#17C0FF"
            />
            <circle cx="36.5" cy="9.5" r="9.5" fill="#FFDD99" />
            <circle cx="8.5" cy="206.5" r="5.5" fill="#E1C8F5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2727 31.8364C10.7636 30.3818 10.6182 30.0909 9.16364 25.7273C9.01818 25.1455 8.58182 25 8 25C7.56364 25 7.12727 25.2909 6.83636 25.7273C5.38182 30.0909 5.23636 30.3818 0.727273 31.8364C0.290909 31.9818 0 32.4182 0 33C0 33.5818 0.290909 33.8727 0.727273 34.1636C4.94545 35.4727 5.38182 35.9091 6.83636 40.2727C7.12727 40.7091 7.56364 41 8 41C8.43636 41 8.87273 40.7091 9.16364 40.2727C10.6182 35.9091 11.0545 35.4727 15.2727 34.1636C15.7091 33.8727 16 33.4364 16 33C16 32.5636 15.7091 31.9818 15.2727 31.8364Z"
                fill="#17B7FF"
                fillOpacity="0.54"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M244.455 12.1273C241.073 11.0364 240.964 10.8182 239.873 7.54545C239.764 7.10909 239.436 7 239 7C238.673 7 238.345 7.21818 238.127 7.54545C237.036 10.8182 236.927 11.0364 233.545 12.1273C233.218 12.2364 233 12.5636 233 13C233 13.4364 233.218 13.6545 233.545 13.8727C236.709 14.8545 237.036 15.1818 238.127 18.4545C238.345 18.7818 238.673 19 239 19C239.327 19 239.655 18.7818 239.873 18.4545C240.964 15.1818 241.291 14.8545 244.455 13.8727C244.782 13.6545 245 13.3273 245 13C245 12.6727 244.782 12.2364 244.455 12.1273Z"
                fill="#17B7FF"
                fillOpacity="0.76"
            />
            <defs>
                <filter
                    id="filter0_d"
                    x="11"
                    y="51"
                    width="159"
                    height="137"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.891667 0 0 0 0 0.939815 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                    id="filter1_d"
                    x="165"
                    y="51"
                    width="137"
                    height="137"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.891667 0 0 0 0 0.939815 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="240.122"
                    y1="91.0214"
                    x2="224.713"
                    y2="133.358"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="224.586"
                    y1="111.612"
                    x2="240.52"
                    y2="155.391"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="252.666"
                    y1="136.674"
                    x2="252.666"
                    y2="86"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="231.348"
                    y1="134.277"
                    x2="272.667"
                    y2="134.277"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(MemoQGridlySVG);
