import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress as CircularProgressMUI } from '@material-ui/core';

const CircularProgress = withStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}))(props => <CircularProgressMUI size={24} {...props} />);

export default React.memo(CircularProgress);
