import React from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useSyncingConnectionTasks } from 'hooks/integration';
import * as integrationActions from 'integrations/actions';

function UndoProvider() {
    const dispatch = useDispatch();
    const tasks = useSyncingConnectionTasks();

    console.log('tasks', tasks);
    const intervalRef = React.useRef();

    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (!isEmpty(tasks)) {
                const loop = async () => {
                    await Promise.all(
                        tasks?.map(task => {
                            return dispatch(integrationActions.getSyncStatusTask({ ...task }));
                        })
                    );
                };
                loop();
            }
        }, 5000);

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [tasks, dispatch]);
    return null;
}

export default React.memo(UndoProvider);
