import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';

const viewApis = {
    viewSorts: ({ defaultAccessViewId, dbId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/sort-orders`,
    viewSort: ({ defaultAccessViewId, dbId, sortOrderId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/views/${defaultAccessViewId}/sort-orders/${sortOrderId}`
};

export async function getViewSortApi({ defaultAccessViewId, dbId }) {
    const response = await request.get(viewApis.viewSorts({ defaultAccessViewId, dbId }));
    return response.data;
}

export async function deleteViewSortApi({ defaultAccessViewId, dbId, sortOrderId }) {
    const response = await request.delete(viewApis.viewSort({ defaultAccessViewId, dbId, sortOrderId }));
    return response.data;
}

export async function deleteViewSortsApi({ defaultAccessViewId, dbId, body }) {
    const response = await request.delete(viewApis.viewSorts({ defaultAccessViewId, dbId }), {
        data: body
    });
    return response.data;
}

export async function createViewSortApi({ defaultAccessViewId, dbId, data }) {
    const response = await request.post(viewApis.viewSorts({ defaultAccessViewId, dbId }), data);
    return response.data;
}

export async function updateViewSortApi({ defaultAccessViewId, dbId, sortOrderId, data }) {
    const response = await request.put(viewApis.viewSort({ defaultAccessViewId, dbId, sortOrderId }), data);
    return response.data;
}
