import React from 'react';
import * as columnTypes from 'const/columnTypes';
import SingleLine from './SingleLine';
import MultipleLine from './MultipleLine';
import Datetime from './Datetime';
import Boolean from './Boolean';
import Number from './Number';
import SingleSelection from './SingleSelection';
import MultipleSelection from './MultipleSelection';

function DefaultValue({ type, ...rest }) {
    switch (type) {
        case columnTypes.SINGLE_LINE:
            return <SingleLine {...rest} />;

        case columnTypes.MULTIPLE_LINES:
            return <MultipleLine {...rest} />;

        case columnTypes.DATETIME:
            return <Datetime {...rest} />;

        case columnTypes.BOOLEAN:
            return <Boolean {...rest} />;

        case columnTypes.NUMBER:
            return <Number {...rest} />;

        case columnTypes.SINGLE_SELECTION:
            return <SingleSelection {...rest} />;

        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelection {...rest} />;

        default:
            return null;
    }
}

export default DefaultValue;
