import React from 'react';

function ResolveCommentSVG({ color = '#2D97F2', ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2202 2.52355C17.1354 1.42634 16.2182 0.5625 15.0993 0.5625H2.6898L2.52355 0.5689C1.42634 0.653708 0.5625 1.57083 0.5625 2.6898V12.2695L0.569757 12.4217C0.646572 13.2228 1.32367 13.8516 2.14559 13.8516L13.8062 13.8821L13.9129 13.8928C14.0175 13.9139 14.1144 13.9655 14.1912 14.0414L16.2963 16.1466L16.3679 16.2074C16.7148 16.4558 17.2266 16.2134 17.2266 15.7605V2.6898L17.2202 2.52355ZM2.58645 2.03906H15.2037L15.3021 2.04787C15.5575 2.09413 15.75 2.31715 15.75 2.58434V13.512L15.5707 13.3316L14.8187 12.5448L14.7532 12.4854C14.6598 12.4141 14.5441 12.375 14.4243 12.375H2.58645L2.48799 12.3662C2.23262 12.3201 2.04012 12.0979 2.04012 11.8297V2.58434L2.04889 2.48647C2.09499 2.2324 2.31738 2.03906 2.58645 2.03906Z"
                fill={color}
            />
            <path
                d="M8.23399 9.8431C8.16335 9.91173 8.06698 9.95002 7.96687 9.95002C7.86676 9.95002 7.77038 9.91173 7.69975 9.8431L5.56643 7.78223C5.34504 7.56839 5.34504 7.22163 5.56643 7.0082L5.83356 6.75012C6.05502 6.53628 6.4136 6.53628 6.63499 6.75012L7.96687 8.03663L11.5658 4.5604C11.7872 4.34656 12.1462 4.34656 12.3672 4.5604L12.6343 4.81848C12.8557 5.03232 12.8557 5.37901 12.6343 5.59251L8.23399 9.8431Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ResolveCommentSVG);
