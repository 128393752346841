import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { Table, Column, defaultTableRowRenderer } from 'react-virtualized-dn';
import 'react-virtualized-dn/styles.css';

const useStyles = makeStyles(theme => ({
    table: {
        '& .ReactVirtualized__Table__Grid': {
            outline: 'none'
        }
    },
    listItem: {
        zIndex: 1000
    }
}));

const SortableTable = sortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = sortableElement(props => {
    return defaultTableRowRenderer(props);
});

function SortableList({
    items = [],
    itemHeight = 30,
    height,
    width,
    maxHeight = 600,
    dataKey = 'id',
    itemRenderer,
    ...rest
}) {
    const classes = useStyles({ itemHeight });

    const rowGetter = ({ index }) => {
        return items[index];
    };

    const cellRenderer = props => {
        return itemRenderer(props);
    };

    const rowRenderer = ({ className, ...rest }) => {
        return <SortableTableRowRenderer {...rest} className={`${className} ${classes.listItem}`} />;
    };

    if (!items || !items.length) {
        return <span />;
    }

    return (
        <div className={classes.table} style={{ height: `${height}px`, width: '100%' }}>
            <SortableTable
                width={width}
                height={height}
                rowRenderer={rowRenderer}
                rowGetter={rowGetter}
                rowHeight={itemHeight}
                rowCount={items.length}
                data={items}
                distance={2}
                disableHeader
                {...rest}
            >
                <Column width={width} dataKey={dataKey} flexGrow={1} cellRenderer={cellRenderer} />
            </SortableTable>
        </div>
    );
}

export default React.memo(SortableList);
