import { memo } from 'react';
import { Grid } from '@material-ui/core';

function ColumnIdHeader({ isEmptyIndexHeaderCell = false }) {
    if (isEmptyIndexHeaderCell) return null;
    return (
        <Grid className="w-full flex justify-center items-center">
            <p className="body2">ID</p>
        </Grid>
    );
}

export default memo(ColumnIdHeader);
