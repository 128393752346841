import React from 'react';

function ImportSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7144 0.5C12.0359 0.5 12.2501 0.607143 12.4644 0.821429L15.143 3.5C15.3573 3.71429 15.4644 3.92857 15.4644 4.25V13.3571C15.4644 14.6429 14.393 15.6071 13.2144 15.6071H5.92871C4.643 15.6071 3.67871 14.5357 3.67871 13.3571V11.4286H5.07157V13.3571C5.07157 13.7857 5.393 14.2143 5.92871 14.2143H13.2144C13.643 14.2143 14.0716 13.8929 14.0716 13.3571V5.32143H11.0716C10.7501 5.32143 10.4287 5 10.4287 4.67857V1.89286H5.82157C5.393 1.89286 4.96443 2.21429 4.96443 2.75V7.57143H3.67871V2.75C3.67871 1.46429 4.75014 0.5 5.92871 0.5H11.7144ZM11.8216 2.10714V3.92857H13.8573L11.8216 2.10714ZM1.64286 8.85715H4.21429H8.5L7.75 8.10715C7.53572 7.89287 7.53572 7.46429 7.75 7.25001C7.96429 7.03572 8.39286 7.03572 8.60714 7.25001L10.4286 9.17858C10.6429 9.28572 10.6429 9.50001 10.6429 9.60715C10.6429 9.82144 10.5357 9.92858 10.4286 10.0357L8.71429 11.6429C8.60714 11.8572 8.39286 11.8572 8.28572 11.8572C8.07143 11.8572 7.96429 11.75 7.85714 11.6429C7.64286 11.4286 7.64286 11 7.85714 10.7857L8.39286 10.1429H1.64286C1.32143 10.1429 1 9.82144 1 9.50001C1 9.17858 1.32143 8.85715 1.64286 8.85715Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ImportSVG);
