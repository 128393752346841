import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as authActions from 'auth/actions';

const { dispatch, getState } = store;
const notificationCenterHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    const userId = state.auth.currentUser?.userId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    const { message: messageBody } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG??? notificationCenterHandler');
    }

    if (messageBody?.userId === userId) {
        dispatch(authActions.setNotificationCenterWithKeyValue({ key: 'allowFetch', value: true }));
        dispatch(authActions.pushNotificationCenter(messageBody));
        return;
    }
};

export default notificationCenterHandler;
