import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from 'components/dialog/Dialog';
import DialogTitle from 'components/dialog/DialogTitle';
import { useTranslation } from 'react-i18next';
import DialogContent from 'components/dialog/DialogContent';
import { FormControlLabel, Grid, TextareaAutosize, Typography } from '@material-ui/core';
import { object, string } from 'yup';
import i18n from 'i18n';
import { useFormik } from 'formik';
import ButtonBase from 'components/button/Base';
import { useDispatch } from 'react-redux';
import { addAlternative, updateAlternative } from 'tm/actions';
import CircularProgress from 'components/CircularProgress';
import Checkbox from 'components/checkbox/Base';
import { useParams } from 'react-router-dom';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

const useStyles = makeStyles(theme => ({
    root: {
        width: 520
    },
    input: {
        width: '100%',
        height: '100px !important',
        padding: 8,
        overflowY: 'auto !important',
        resize: 'none',
        outline: 'none',
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        zIndex: 2,
        border: `1px solid #D7D7DC !important`,
        '&.Mui-disabled': {
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        }
    },
    title: {
        padding: `20px`
    },
    flex1: {
        flex: 1,
        width: 360
    },
    mb16: {
        marginBottom: 16
    }
}));

const Schema = object({
    targetText: string().required(i18n.t(`global_this_field_is_required`))
});

const EditAlternaive = ({
    open,
    onClose,
    unitId,
    sourceText,
    target,
    targetLang,
    alternative,
    setNewAlternative,
    source,
    isEditing,
    onRestTable,
    fontJP
}) => {
    const { t } = useTranslation();
    // const theme = useTheme();
    const { tmId } = useParams();

    const dispatch = useDispatch();
    const classes = useStyles();
    const rootRef = React.useRef();

    const formik = useFormik({
        initialValues: {
            sourceText: '',
            targetText: isEditing ? alternative?.value : '',
            isPrimary: alternative?.primary
        },
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            if (!isEditing) {
                let body = {
                    targetLang: target?.value,
                    value: values?.targetText,
                    primary: values?.isPrimary
                };
                dispatch(
                    addAlternative({
                        tmId,
                        unitId,
                        body,
                        successCallback: () => {
                            onRestTable({ resetPage: true, resetSelectedRows: [] });
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 800);
                        }
                    })
                );
            } else {
                let body = {
                    value: values?.targetText,
                    primary: values?.isPrimary
                };
                dispatch(
                    updateAlternative({
                        tmId: tmId,
                        segmentId: alternative?.id,
                        unitId,
                        body: body,
                        targetLang,
                        successCallback: ({ alternative }) => {
                            setNewAlternative(alternative);
                            onRestTable({ resetPage: true, resetSelectedRows: [] });
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 800);
                        }
                    })
                );
            }
        }
    });
    const { errors, handleChange, handleSubmit, isSubmitting, isValid, dirty } = formik;

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleClickAway = React.useCallback(
        e => {
            if (!dirty) return;
            handleSubmit();
        },
        [handleSubmit, dirty]
    );

    return (
        <Dialog maxWidth={true} open={open} onClose={onClose}>
            <DialogTitle
                title={isEditing ? `Edit alternative segment` : 'Add alternative segment'}
                onClose={onClose}
                className={classes.title}
            />
            <form onSubmit={handleSubmit} ref={rootRef}>
                <DialogContent>
                    <Grid
                        container
                        className={`${classes.root} ${fontJP}`}
                        direction="column"
                        spacing={4}
                        wrap="nowrap"
                    >
                        <Grid item container direction="column" spacing={2}>
                            <Grid item container direction="column" spacing={2} className={classes.mb16}>
                                <Grid item container justifyContent="center" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">Source:</Typography>
                                    </Grid>
                                    <Grid item className={classes.icon}>
                                        {source?.icon()}
                                    </Grid>
                                    <Grid item>
                                        {source?.languageCountry} - {source?.value}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextareaAutosize
                                        className={classes.input}
                                        placeholder="Input item"
                                        name="sourceText"
                                        defaultValue={sourceText}
                                        error={errors.sourceText}
                                        errorText={errors.sourceText}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container direction="column" spacing={2}>
                                <Grid item container justifyContent="center" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography variant="body1">Target:</Typography>
                                    </Grid>
                                    <Grid item className={classes.icon}>
                                        {target?.icon()}
                                    </Grid>
                                    <Grid item>
                                        {target?.languageCountry} - {target?.value}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextareaAutosize
                                        className={classes.input}
                                        placeholder="Input target"
                                        name="targetText"
                                        defaultValue={isEditing ? alternative?.value : null}
                                        error={errors.targetText}
                                        errorText={errors.targetText}
                                        onChange={handleChange}
                                        autoFocus={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <FormControlLabel
                                            style={{ margin: 0 }}
                                            control={<Checkbox checked={formik.values.isPrimary} />}
                                            name="isPrimary"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">Use as the default translation</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" className={classes.row} spacing={2}>
                            <Grid
                                className={classes.btnContainer}
                                container
                                alignItems="center"
                                justify="flex-end"
                                spacing={3}
                            >
                                <Grid item>
                                    <ButtonBase width={120} variant="outlined" onClick={onClose}>
                                        {t('global_cancel')}
                                    </ButtonBase>
                                </Grid>
                                <Grid item>
                                    <ButtonBase
                                        width={120}
                                        variant="contained"
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        {isEditing ? `Save` : `Add`}
                                        {isSubmitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default React.memo(EditAlternaive);
