export const withFunc = editor => {
    const { isInline, isVoid } = editor;

    editor.isInline = element => {
        return element.type === 'func' ? true : isInline(element);
    };

    editor.isVoid = element => {
        return element.type === 'func' ? true : isVoid(element);
    };

    return editor;
};
