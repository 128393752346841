import React from 'react';

function IdentifySVG({ color = '#78778B', ...other }) {
    return (
        <svg width="100%" height="428" viewBox="0 0 549 428" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M83.3846 9.23077C80.8356 9.23077 78.7692 7.16439 78.7692 4.61539C78.7692 2.06638 80.8356 0 83.3846 0C85.9336 0 88 2.06638 88 4.61539C88 7.16439 85.9336 9.23077 83.3846 9.23077Z"
                fill="#BDB4E2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.9998 9.23077C65.4508 9.23077 63.3845 7.16439 63.3845 4.61539C63.3845 2.06638 65.4508 0 67.9998 0C70.5489 0 72.6152 2.06638 72.6152 4.61539C72.6152 7.16439 70.5489 9.23077 67.9998 9.23077Z"
                fill="#BDB4E2"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.9998 24.6154C65.4508 24.6154 63.3845 22.549 63.3845 20C63.3845 17.451 65.4508 15.3846 67.9998 15.3846C70.5489 15.3846 72.6152 17.451 72.6152 20C72.6152 22.549 70.5489 24.6154 67.9998 24.6154Z"
                fill="#BDB4E2"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.9998 40.0001C65.4508 40.0001 63.3845 37.9337 63.3845 35.3847C63.3845 32.8357 65.4508 30.7693 67.9998 30.7693C70.5489 30.7693 72.6152 32.8357 72.6152 35.3847C72.6152 37.9337 70.5489 40.0001 67.9998 40.0001Z"
                fill="#BDB4E2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.6151 9.23077C50.0661 9.23077 47.9997 7.16439 47.9997 4.61539C47.9997 2.06638 50.0661 0 52.6151 0C55.1641 0 57.2305 2.06638 57.2305 4.61539C57.2305 7.16439 55.1641 9.23077 52.6151 9.23077Z"
                fill="#BDB4E2"
            />
            <path
                opacity="0.132045"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.6151 24.6154C50.0661 24.6154 47.9997 22.549 47.9997 20C47.9997 17.451 50.0661 15.3846 52.6151 15.3846C55.1641 15.3846 57.2305 17.451 57.2305 20C57.2305 22.549 55.1641 24.6154 52.6151 24.6154Z"
                fill="#BDB4E2"
            />
            <path
                opacity="0.648414"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.6151 40.0001C50.0661 40.0001 47.9997 37.9337 47.9997 35.3847C47.9997 32.8357 50.0661 30.7693 52.6151 30.7693C55.1641 30.7693 57.2305 32.8357 57.2305 35.3847C57.2305 37.9337 55.1641 40.0001 52.6151 40.0001Z"
                fill="#BDB4E2"
            />
            <path
                d="M253.315 346H246.143C235.452 346 226.791 337.356 226.791 326.686V310.614H272.667V326.686C272.667 337.356 264.006 346 253.315 346Z"
                fill="#222023"
            />
            <path
                d="M271.991 328.307H228.009C220.025 328.307 213.394 321.824 213.394 313.72V260.776H286.471V313.72C286.471 321.824 279.975 328.307 271.991 328.307Z"
                fill="#A59AA5"
            />
            <path
                d="M286.742 291.975H212.582C207.71 291.975 203.785 288.058 203.785 283.196C203.785 278.334 207.71 274.417 212.582 274.417H286.742C291.613 274.417 295.538 278.334 295.538 283.196C295.538 288.058 291.613 291.975 286.742 291.975Z"
                fill="#C2B8C2"
            />
            <path
                d="M242.354 291.975H226.791C221.92 291.975 217.995 288.058 217.995 283.196C217.995 278.334 221.92 274.417 226.791 274.417H242.354C247.226 274.417 251.151 278.334 251.151 283.196C251.151 288.058 247.226 291.975 242.354 291.975Z"
                fill="#DBD5DB"
            />
            <path
                d="M286.742 318.582H212.582C207.71 318.582 203.785 314.665 203.785 309.803C203.785 304.941 207.71 301.024 212.582 301.024H286.742C291.613 301.024 295.538 304.941 295.538 309.803C295.538 314.665 291.613 318.582 286.742 318.582Z"
                fill="#C2B8C2"
            />
            <path
                d="M235.046 318.582H234.099C229.227 318.582 225.303 314.665 225.303 309.803C225.303 304.941 229.227 301.024 234.099 301.024H235.046C239.918 301.024 243.843 304.941 243.843 309.803C243.843 314.665 239.918 318.582 235.046 318.582Z"
                fill="#DBD5DB"
            />
            <path
                d="M354 139.894C354 86.9499 307.447 44 249.932 44C192.418 44 146 86.9499 146 139.894C146 171.634 162.781 199.727 188.493 217.285C188.493 217.285 200.537 223.903 201.891 241.867V254.833C201.891 260.911 207.169 265.773 213.8 265.773H286.336C292.967 265.773 298.245 260.911 298.245 254.833V241.867C299.598 223.903 311.372 217.42 311.372 217.42C337.219 199.862 354 171.769 354 139.894Z"
                fill="#FDC72F"
            />
            <path
                d="M251.15 223.903C292.631 223.903 326.258 190.343 326.258 148.944C326.258 107.544 292.631 73.9839 251.15 73.9839C209.67 73.9839 176.043 107.544 176.043 148.944C176.043 190.343 209.67 223.903 251.15 223.903Z"
                fill="#FFD56C"
            />
            <path
                d="M238.971 265.773V157.588C238.971 149.619 232.475 143.136 224.491 143.136C216.507 143.136 210.011 149.619 210.011 157.588V165.691C210.011 173.66 216.507 180.143 224.491 180.143H249.527"
                stroke="white"
                strokeWidth="2.2052"
                strokeMiterlimit="10"
            />
            <path
                d="M260.353 265.773V157.588C260.353 149.619 266.848 143.136 274.833 143.136C282.817 143.136 289.313 149.619 289.313 157.588V165.691C289.313 173.66 282.817 180.143 274.833 180.143H247.902"
                stroke="white"
                strokeWidth="2.2052"
                strokeMiterlimit="10"
            />
            <path
                d="M187.952 94.5134C194.854 86.1395 203.38 79.1163 212.988 73.9839"
                stroke="white"
                strokeWidth="13.1376"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M171.442 162.45C170.359 157.047 169.683 151.51 169.683 145.837"
                stroke="white"
                strokeWidth="9.0321"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M332.618 145.837C332.618 153.401 331.535 160.829 329.641 167.852"
                stroke="white"
                strokeWidth="9.0321"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse cx="76.5" cy="415.5" rx="76.5" ry="12.5" fill="#F4F5F7" />
            <path
                d="M77.4 186.547C77.4 186.547 77.2618 192.903 76.9855 193.041C76.7092 193.179 75.742 192.903 75.742 192.903L77.5382 203.128L90.526 228.412L86.7955 257.842C100.198 280.364 109.179 302.747 110.008 324.716C107.797 357.185 105.31 388.688 109.87 400.432L122.443 415.492C109.593 416.045 96.3291 416.321 85.1374 414.249L84.0321 396.978C80.5779 379.292 81.2687 359.396 84.7229 338.118C85.6901 331.9 85.1374 325.545 83.0649 319.742C78.7817 307.859 73.8076 293.351 70.3534 275.942L60.2671 269.448L61.787 259.362L56.1221 221.366L69.5244 202.989L71.4588 194.423C71.8733 193.041 71.7351 191.245 71.8733 189.863C72.5641 182.817 77.1237 181.021 77.4 186.547Z"
                fill="#FED1A0"
            />
            <path
                d="M77.4 186.547C77.4 186.547 77.2618 192.903 76.9855 193.041C76.7092 193.179 75.742 192.903 75.742 192.903L77.5382 203.128L90.526 228.412L86.7955 257.842C100.198 280.364 109.179 302.747 110.008 324.716C107.797 357.185 105.31 388.688 109.87 400.432L122.443 415.492C109.593 416.045 96.3291 416.321 85.1374 414.249L84.0321 396.978C80.5779 379.292 81.2687 359.396 84.7229 338.118C85.6901 331.9 85.1374 325.545 83.0649 319.742C78.7817 307.859 73.8076 293.351 70.3534 275.942L60.2671 269.448L61.787 259.362L56.1221 221.366L69.5244 202.989L71.4588 194.423C71.8733 193.041 71.7351 191.245 71.8733 189.863C72.5641 182.817 77.1237 181.021 77.4 186.547Z"
                fill="#FFDDCD"
            />
            <path
                d="M60.2669 269.448C59.4379 273.041 59.0234 276.357 59.4379 279.258C59.9906 283.127 60.2669 287.134 59.8524 291.141L57.7799 313.662C57.2272 320.018 55.7073 326.236 53.2203 332.177C40.9233 361.883 36.2256 388.826 39.4035 413.143C49.9043 415.492 62.6158 415.492 76.2944 414.525L65.241 404.163C63.9974 402.919 63.1684 401.399 62.8921 399.603C61.6486 386.201 69.386 367.962 76.0181 348.343C81.2685 336.598 83.4792 325.268 83.0647 314.077L70.0768 275.804L60.2669 269.448Z"
                fill="#FFDDCD"
            />
            <path
                d="M72.8404 201.193C42.0289 211.97 11.2173 227.722 5.41427 250.796C4.58526 253.974 4.8616 257.29 6.79596 260.191C12.8754 269.31 41.4762 272.488 59.2999 275.113C60.2671 275.252 61.0961 275.666 61.5106 276.357C62.0633 277.048 62.8923 278.015 63.445 277.739C64.4122 277.462 62.3396 274.146 63.445 274.008C64.5503 273.732 67.1755 278.291 68.2809 277.877C71.1824 276.771 64.6885 272.903 66.0702 272.488C67.4519 272.074 72.8404 277.6 73.2549 276.081C73.8076 274.699 68.5572 270.277 68.8336 269.587C69.1099 268.896 72.9786 273.041 73.8076 271.797C74.2221 271.245 72.2878 268.067 68.8336 264.613C63.3068 258.809 56.1221 254.803 48.3846 252.73L34.2915 248.861L58.8854 233.939L72.8404 201.193Z"
                fill="#FFDDCD"
            />
            <path
                d="M72.0116 193.041C71.1826 190.416 70.6299 188.205 71.3207 184.475C71.5971 183.231 72.1497 181.988 72.9788 181.021C73.5314 180.468 74.0841 180.33 74.7749 181.021C76.1566 180.053 77.5383 179.777 78.6437 181.159C80.4399 180.744 80.9925 182.402 80.578 184.06C80.7162 186.409 79.6109 186.133 77.4002 185.995L75.1895 186.547C75.3276 189.725 73.6696 192.35 72.0116 193.041Z"
                fill="#002DB3"
            />
            <path
                d="M76.9852 193.041L75.3272 192.489C75.189 192.489 75.189 192.35 75.189 192.35C75.0508 191.936 74.6363 190.278 74.6363 190.278C74.4982 190.278 74.7745 192.903 74.7745 192.903C74.9127 193.732 75.4654 194.147 76.1562 194.561L76.018 193.318C76.2944 193.179 76.847 193.179 76.9852 193.041Z"
                fill="#002DB3"
            />
            <path
                d="M110.422 401.261H84.1699L84.9989 414.249C96.1906 416.321 109.317 416.183 122.304 415.492L110.422 401.261Z"
                fill="#081013"
            />
            <path
                d="M65.3799 404.163C64.4127 403.333 63.86 402.228 63.4455 401.123H38.5752C38.7134 405.13 38.9897 409.137 39.5424 413.143C50.0432 415.492 62.7547 415.492 76.4333 414.525L65.3799 404.163Z"
                fill="#081013"
            />
            <path
                d="M47.9699 273.87C50.1806 264.613 93.1509 266.685 93.0127 268.343C95.9143 272.074 46.45 286.305 47.9699 273.87Z"
                fill="#01729C"
            />
            <path
                d="M63.0309 397.945C63.7218 385.233 70.492 365.475 76.1569 348.343C81.4073 336.598 83.618 325.268 83.2035 314.077L70.2157 275.804L60.2676 269.448C59.4386 273.041 59.0241 276.357 59.4386 279.258C59.9912 283.127 60.2676 287.134 59.8531 291.141L57.7806 313.662C57.2279 320.018 55.708 326.236 53.221 332.177C44.2401 354.007 38.5752 368.93 38.2988 396.84C47.1416 396.701 55.5699 397.116 63.0309 397.945Z"
                fill="#E94C8B"
            />
            <path
                d="M75.0512 190.139C74.4989 189.862 74.2227 189.31 74.2227 188.758C74.3608 188.067 74.9131 187.791 75.3274 188.205L75.0512 190.139Z"
                fill="#FED1A0"
            />
            <path
                d="M89.2823 228.551C100.336 235.045 111.666 238.499 122.719 239.19C133.496 239.88 144.273 236.564 152.425 229.38C162.512 220.537 169.42 207.134 174.947 192.074C176.052 189.034 176.881 187.515 179.23 184.613C179.23 184.613 181.993 182.402 181.717 181.85C181.441 181.297 178.539 182.541 178.539 182.264C178.539 181.988 181.993 179.915 181.855 179.086C181.717 178.257 177.019 180.883 176.881 180.192C176.743 179.639 180.197 177.566 179.644 176.599C179.092 175.632 173.841 178.81 173.565 178.257C173.289 177.705 176.743 175.908 176.052 175.218C175.361 174.527 165.828 176.876 162.235 182.264C151.734 194.285 140.405 209.898 131.976 214.457C129.904 215.563 127.002 215.701 124.653 214.872C110.422 209.483 94.3945 205.338 76.709 202.16L89.2823 228.551Z"
                fill="#FFDDCD"
            />
            <path
                d="M34.4296 248.723L58.8854 233.939L72.8404 201.055C42.0289 211.97 11.2173 227.722 5.41427 250.796C4.58526 253.974 4.8616 257.29 6.79596 260.191C10.8028 266.271 24.6197 269.725 38.7129 272.074C43.4106 266.685 45.6213 259.915 46.1739 252.039L34.4296 248.723Z"
                fill="#5BB382"
            />
            <path
                d="M82.9269 323.334C77.4002 312.557 73.946 304.819 72.7025 290.588C68.8337 289.483 66.8994 284.923 66.8994 279.258L66.623 272.903C68.2811 280.916 70.3536 287.548 73.6696 290.312L84.3086 316.564L82.9269 323.334Z"
                fill="#C9185F"
            />
            <path
                d="M108.627 396.149C105.725 382.056 107.936 353.869 110.008 324.854C109.179 303.576 100.751 281.884 88.0394 260.191C79.1966 262.54 70.3539 262.816 61.6493 261.02L60.2676 269.448L70.2157 275.804C73.6699 293.213 78.644 307.721 82.9272 319.604C85.1379 325.545 85.5524 331.762 84.5852 337.98C81.2692 359.12 80.5783 378.878 83.8944 396.425C92.3226 395.458 100.475 395.32 108.627 396.149Z"
                fill="#E94C8B"
            />
            <path
                d="M92.5991 268.481C92.1846 267.79 90.5265 228.412 90.5265 228.412L77.5387 203.128L76.986 199.95H70.354L69.6631 202.989L56.2608 221.366L47.9707 274.699C58.886 269.034 84.7235 267.376 92.5991 268.481Z"
                fill="#5BB382"
            />
            <path
                d="M90.9405 238.637C86.3809 239.466 83.3412 233.525 82.6504 230.07C87.9008 234.63 88.3153 233.248 91.0786 235.044L90.9405 238.637Z"
                fill="#01729C"
            />
            <path
                d="M131.838 214.596C129.766 215.701 126.864 215.839 124.515 215.01C110.284 209.622 94.2565 205.477 76.5709 202.299C67.3136 217.221 70.2152 227.445 84.7229 233.387C85.8282 233.801 86.7954 234.354 87.7626 234.768C98.816 240.295 112.357 238.361 122.995 239.19C133.911 240.019 144.688 236.426 152.702 229.103C158.09 224.129 162.65 218.05 166.519 211.003C160.715 205.477 154.636 201.47 147.866 199.397C142.063 206.167 136.536 211.97 131.838 214.596Z"
                fill="#5BB382"
            />
            <ellipse cx="437" cy="415.5" rx="112" ry="12.5" fill="#F4F5F7" />
            <g clipPath="url(#clip0)">
                <path
                    d="M430.931 274.66C425.116 274.66 420.402 269.937 420.402 264.112C420.402 258.286 425.116 253.563 430.931 253.563C436.746 253.563 441.46 258.286 441.46 264.112C441.46 269.937 436.746 274.66 430.931 274.66Z"
                    fill="#FF4100"
                />
                <path
                    d="M427.421 349.768C416.331 335.281 405.1 326.561 393.588 323.326C385.586 321.075 377.023 324.592 373.092 332.046C365.231 347.096 369.582 373.117 370.706 396.184L354.421 412.64L388.815 412.218C394.852 392.245 394.992 371.147 389.938 347.799C397.098 366.787 406.363 382.822 417.032 396.887L427.421 349.768Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M354 412.921L369.863 396.465L392.605 397.309C391.763 402.654 390.64 407.295 388.955 412.499L354 412.921Z"
                    fill="#FF4100"
                />
                <path
                    d="M429.106 276.489C447.496 295.055 458.306 312.355 473.748 343.439C480.627 356.098 500.281 379.727 500 380.853C499.579 383.103 498.315 380.993 496.771 379.446C496.49 379.165 495.788 379.024 496.21 379.305C498.736 380.853 499.438 381.837 499.438 382.681C499.438 383.806 496.35 382.822 493.261 380.009C492.981 379.727 493.121 379.868 493.261 380.149C494.244 382.259 496.911 382.259 496.49 383.666C496.21 384.369 494.385 383.806 492.56 382.822C491.296 382.118 490.173 381.415 489.05 380.571C489.05 380.571 491.577 383.103 491.015 383.384C489.611 384.088 486.242 382.118 486.242 382.118C473.888 375.93 464.342 366.084 456.34 353.988L444.408 302.65L429.106 276.489Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M473.748 343.439C475.433 346.533 480.627 347.799 483.575 351.878C481.329 358.63 473.608 365.1 466.869 367.772C462.939 363.693 459.289 359.052 456.06 354.129L444.127 302.791L429.106 276.489C447.496 295.055 458.306 312.355 473.748 343.439Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M460.973 303.916C450.023 302.087 440.056 307.01 431.071 318.966C443.846 318.684 461.394 312.636 460.973 303.916Z"
                    fill="#FF4100"
                />
                <path
                    d="M384.744 279.443C382.077 274.379 368.179 281.552 362.283 288.022C372.671 288.022 384.042 288.304 384.744 279.443Z"
                    fill="#FF4100"
                />
                <path
                    d="M422.086 258.204L421.244 264.393L422.227 264.674L423.49 276.911C422.507 284.365 421.244 289.851 421.244 295.617C421.244 300.822 422.929 305.744 425.877 310.105C428.684 314.183 431.211 318.544 433.317 323.466C434.861 326.842 435.282 330.64 434.44 334.297C432.896 341.47 428.684 346.674 421.525 349.909C396.256 361.583 376.461 373.96 375.338 388.729C374.917 393.792 377.163 398.715 380.954 402.091C397.379 416.859 424.052 414.046 456.34 412.358L479.925 417L466.588 391.12C436.967 384.369 417.173 386.057 403.415 387.885L433.457 386.338C443.144 385.916 451.567 379.165 453.954 369.882C455.498 364.115 456.059 357.504 456.2 350.472C454.936 326.28 448.479 301.525 427.842 274.942L427.14 271.566C426.157 267.065 426.017 264.393 426.719 259.892C427.14 257.36 426.438 256.798 425.315 256.376C423.911 255.813 422.367 256.798 422.086 258.204Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M421.245 264.393H423.35C423.771 263.83 424.333 262.986 424.333 263.127C424.333 263.268 424.052 264.111 424.052 264.111C423.771 264.815 423.07 265.518 422.368 265.659L422.227 264.815L421.245 264.393Z"
                    fill="#060022"
                />
                <path
                    d="M423.35 274.942L427.842 273.817L428.123 275.082C432.334 280.568 437.809 285.913 441.74 290.554C443.565 292.664 458.727 297.868 461.113 303.916C442.021 302.65 435.984 314.465 431.211 318.966C429.667 315.871 427.842 312.918 426.017 310.105C423.069 305.885 421.384 300.822 421.384 295.618C421.384 289.991 422.648 284.365 423.631 276.911L423.35 274.942Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M428.264 291.398C427.983 297.868 426.86 303.353 424.333 307.714C424.052 307.292 423.491 306.166 422.929 305.041L428.264 291.398Z"
                    fill="#002DB3"
                />
                <path
                    d="M373.092 332.046C377.023 324.592 385.446 321.075 393.588 323.326C405.1 326.561 416.471 335.281 427.421 349.768L417.173 396.887C406.504 382.822 397.238 366.787 390.079 347.799C393.729 364.678 394.571 380.712 392.606 395.902H370.846C369.582 372.835 365.231 347.096 373.092 332.046Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M425.456 347.237L394.992 359.614C395.554 360.88 396.115 362.146 396.677 363.271L425.736 347.659L425.456 347.237Z"
                    fill="#002DB3"
                />
                <path
                    d="M375.198 388.729C376.321 373.961 396.256 361.583 421.385 349.768C426.719 347.377 430.369 344.002 432.616 339.36C440.477 340.485 448.058 340.767 455.358 339.923C455.779 343.439 456.2 346.955 456.341 350.472C456.2 357.504 455.779 365.1 454.235 370.866C451.989 379.446 444.548 385.635 435.142 386.619C442.021 387.041 448.76 387.604 457.323 389.151L457.885 412.78L456.2 412.499C423.771 414.046 397.239 416.859 380.814 402.232C377.023 398.715 374.777 393.792 375.198 388.729Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M406.504 386.338C414.084 384.369 422.648 383.666 434.3 386.479C425.736 386.057 416.471 386.479 406.644 387.322C406.082 387.322 405.942 386.479 406.504 386.338Z"
                    fill="#002DB3"
                />
                <path
                    d="M466.588 391.12L479.925 417L459.85 413.062L458.727 389.432C461.254 389.995 463.921 390.557 466.588 391.12Z"
                    fill="#FF4100"
                />
                <path d="M360.879 257.22L362.985 262.564L364.81 261.72L362.142 255.672L360.879 257.22Z" fill="white" />
                <path
                    d="M425.737 275.645C412.4 285.772 400.327 296.18 392.887 305.463L370.847 270.3C370.285 269.316 368.881 265.94 367.898 264.955C367.898 264.955 365.512 261.158 364.389 260.595C364.389 260.595 363.547 259.048 362.845 259.751C362.283 260.314 363.827 261.439 363.125 262.002C362.564 262.564 362.423 261.158 361.581 260.173C361.441 259.892 360.037 256.938 359.335 257.641C358.773 258.204 359.195 259.751 359.896 261.72C359.896 261.861 359.756 261.861 359.756 261.861L357.791 258.063C355.264 256.798 357.791 263.268 357.089 263.268C356.668 263.268 356.948 259.189 355.825 259.892C354.702 260.595 355.685 266.081 355.685 265.659C355.545 263.83 355.545 262.424 354.983 262.424C353.72 262.564 354.702 267.206 356.387 272.691C356.808 273.957 357.931 276.77 358.352 278.036C366.495 300.259 375.76 318.825 388.395 325.858C391.343 327.405 394.712 327.827 397.8 326.842C407.206 323.748 418.156 317.278 425.316 302.791C432.475 291.539 431.212 281.974 425.737 275.645Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M384.604 279.302L393.869 303.353C397.238 304.9 394.29 303.635 394.43 303.494C401.871 294.352 412.4 284.928 425.455 275.082C425.596 274.942 425.736 274.942 425.877 275.082C431.211 281.412 433.317 291.679 426.298 302.65C419.138 316.997 408.188 323.467 398.923 326.702C395.694 327.827 390.5 328.108 387.552 326.42C377.163 320.513 369.723 306.307 362.563 289.147C362.563 289.007 363.546 288.585 363.686 288.444C364.669 287.882 367.617 285.772 375.619 286.194C379.971 286.475 383.48 282.959 384.604 279.302Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M427.562 264.533C424.383 264.533 421.806 261.952 421.806 258.767C421.806 255.582 424.383 253 427.562 253C430.74 253 433.317 255.582 433.317 258.767C433.317 261.952 430.74 264.533 427.562 264.533Z"
                    fill="#002DB3"
                />
                <path
                    d="M428.544 258.204H428.685C429.106 258.063 429.246 257.641 429.106 257.36C429.106 257.219 429.106 257.079 429.106 256.938C429.246 256.798 429.386 256.657 429.808 256.657C430.229 256.516 430.369 256.235 430.369 255.813C430.229 255.391 429.948 255.25 429.527 255.25C428.825 255.391 428.263 255.813 427.983 256.235C427.702 256.657 427.702 257.219 427.842 257.782C427.983 257.923 428.263 258.204 428.544 258.204Z"
                    fill="#FF4100"
                />
            </g>
            <rect x="469" y="119" width="67" height="67" rx="6" fill="#8B77DF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M517.869 140.342C517.869 136.877 515.058 134.066 511.592 134.066C508.126 134.066 505.316 136.877 505.316 140.342C505.316 143.808 508.126 146.618 511.592 146.618C515.058 146.618 517.869 143.808 517.869 140.342ZM502.526 172.421H522.999C524.756 172.421 525.363 171.251 524.357 169.806L517.401 159.847C516.394 158.405 514.575 158.25 513.341 159.503L510.654 162.229C509.419 163.483 507.553 163.364 506.485 161.965L495.504 147.582C494.436 146.183 492.928 146.324 492.137 147.896L481.217 169.574C480.425 171.147 481.208 172.421 482.965 172.421H502.526Z"
                fill="#6853BE"
            />
            <rect x="430" y="164" width="67" height="67" rx="6" fill="#B9ECFF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M478.816 184.974C478.816 181.508 476.005 178.697 472.54 178.697C469.073 178.697 466.263 181.508 466.263 184.974C466.263 188.439 469.073 191.25 472.54 191.25C476.005 191.25 478.816 188.439 478.816 184.974ZM463.473 217.053H483.946C485.703 217.053 486.31 215.882 485.304 214.438L478.348 204.479C477.341 203.036 475.522 202.881 474.288 204.135L471.602 206.861C470.366 208.114 468.5 207.995 467.432 206.596L456.452 192.214C455.384 190.815 453.876 190.955 453.085 192.527L442.165 214.206C441.372 215.778 442.155 217.053 443.912 217.053H463.473Z"
                fill="#F5FCFF"
            />
            <defs>
                <clipPath id="clip0">
                    <rect width="146" height="164" transform="matrix(-1 0 0 1 500 253)" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(IdentifySVG);
