import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, alpha, Collapse } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import LDBasePortal from 'components/selects/LDBasePortal';
import ButtonBase from 'components/button/Base';
import * as integrationActions from 'integrations/actions';
import { useDispatch } from 'react-redux';
import Spinner from 'components/spinner/Base';
import { useSheetsBySourceId, useSourceOptions } from 'hooks/integration';
import { SYSTEM_COLUMNS, SOURCE_TYPE_OPTIONS } from 'const';
import * as databaseActions from 'databases/actions';
import * as workspaceActions from 'workspaces/actions';
import { useDatabasesOptionsByWorkspaceId } from 'hooks/database';
import * as roleConst from 'auth/roleConst';
import { useWorkspaceList } from 'hooks/workspace';
import * as gridActions from 'grids/actions';
import { useGridsByDbId } from 'hooks/grid';
import { getGridColumnsApi } from 'services/grid';
import * as columnTypes from 'const/columnTypes';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import RefreshSVG from 'assets/images/svg/RefreshSVG';
import GridIcon from 'grids/GridIcon';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import DatabaseSVG from 'assets/images/svg/DatabaseSVG';
import { getConnectorIcon } from 'utils/connector';
import { useCompanyAuthorities } from 'hooks/auth/role';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { enqueueSnackbar } from 'notifier/actions';
import InputText from 'components/inputs/InputText';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';
import WarningSVG from 'assets/images/svg/WarningSVG';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        position: 'relative'
    },
    formContentWrapper: {
        height: 'calc(100% - 84px)',
        maxHeight: 'calc(100% - 84px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
        position: 'relative'
    },
    footer: {
        height: 84,
        borderTop: `1px solid ${theme.colors.border}`,
        width: '100%'
    },
    formContent: {
        maxWidth: 700
    },
    name: {
        background: theme.colors.paleGrey,
        borderRadius: 4,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        border: `1px solid ${theme.colors.silver}`,
        height: 36,
        display: 'flex',
        alignItems: 'center'
    },
    loading: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: alpha(theme.colors.white, 0.5),
        zIndex: 2
    }
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Connection({ onBack }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sourceOptions = useSourceOptions();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [selectedSource, setSelectedSource] = React.useState(null);
    const [selectedSheet, setSelectedSheet] = React.useState(null);
    const [selectedProject, setSelectedProject] = React.useState(null);
    const [selectedDatabase, setSelectedDatabase] = React.useState(null);
    const [selectedGrid, setSelectedGrid] = React.useState(null);
    const databaseOptions = useDatabasesOptionsByWorkspaceId(selectedProject?.value);
    const workspaceList = useWorkspaceList();
    const sheets = useSheetsBySourceId(selectedSource?.value);
    const dbGridList = useGridsByDbId(selectedDatabase?.value);

    const dbGridListArray = React.useMemo(() => {
        return dbGridList || [];
    }, [dbGridList]);

    const selectedGridDetail = React.useMemo(() => {
        return dbGridListArray.find(grid => grid?.id === selectedGrid?.value);
    }, [dbGridListArray, selectedGrid]);

    const [gridColumnOptions, setGridColumnOptions] = React.useState([]);
    const [columnMappings, setColumnMappings] = React.useState({});
    const connectionRef = React.useRef(null);
    const companyAuthorities = useCompanyAuthorities();
    const [isSheetLoading, setIsSheetLoading] = React.useState(false);
    const { id } = useParams();
    const [connectionName, setConnectionName] = React.useState('');

    const query = useQuery();

    const defaultSourceId = React.useMemo(() => {
        return query?.get('sourceId');
    }, [query]);

    const isEdit = React.useMemo(() => {
        return !!id;
    }, [id]);

    const selectedSourceLabel = React.useMemo(() => {
        switch (selectedSource?.connector) {
            case SOURCE_TYPE_OPTIONS.MYSQL:
            case SOURCE_TYPE_OPTIONS.POSTGRES:
                return 'Select a table you want to sync to Gridly';
            default:
                return 'Select a worksheet to be connected with Gridly';
        }
    }, [selectedSource]);

    const isCreatingNewGrid = React.useMemo(() => {
        return selectedGrid?.isNew;
    }, [selectedGrid]);

    const gridOptions = React.useMemo(() => {
        return dbGridList?.map(grid => ({
            label: grid?.name,
            value: grid?.id,
            icon: () => <GridIcon grid={grid} size="small" />,
            ...grid
        }));
    }, [dbGridList]);

    const sheetOptions = React.useMemo(() => {
        return sheets?.map(sheet => ({
            label: sheet?.stream?.name,
            value: sheet?.config?.aliasName,
            ...sheet
        }));
    }, [sheets]);

    const isCreator = React.useMemo(() => {
        return companyAuthorities?.includes(roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE);
    }, [companyAuthorities]);

    const workspaceOptions = React.useMemo(() => {
        const options = [];
        workspaceList.forEach(ws => {
            const gridPrivileges =
                ws?.role?.privileges?.filter(priv =>
                    isCreator ? true : priv.name === roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID
                ) || [];

            if (gridPrivileges.length > 0) {
                options.push({
                    label: ws?.name,
                    value: ws?.id,
                    icon: () => <WorkspaceSVG />
                });
            }
        });

        return options;
    }, [workspaceList, isCreator]);

    React.useEffect(() => {
        const fetchGridData = async () => {
            try {
                const gridColumns = await getGridColumnsApi({
                    gridId: selectedGrid?.value,
                    dbId: selectedDatabase?.value
                });

                setGridColumnOptions(
                    [
                        ...SYSTEM_COLUMNS?.filter(sysCol =>
                            [columnTypes.PATH_TAG, columnTypes.RECORD_ID]?.includes(sysCol?.id)
                        ),
                        ...gridColumns
                    ]
                        ?.filter(col => ![columnTypes.FILES, columnTypes.FORMULA].includes(col?.type))
                        ?.map(col => ({
                            ...col,
                            label: col?.name,
                            value: col?.id,
                            icon: () => <ColumnIcon {...col} />,
                            options: undefined
                        }))
                );
            } catch (error) {
                setGridColumnOptions([]);
            }
        };

        if (selectedGrid && !isCreatingNewGrid) {
            fetchGridData();
        }
    }, [isCreatingNewGrid, selectedDatabase, selectedGrid]);

    const sheetColumnOptions = React.useMemo(() => {
        if (!selectedSheet) return [];
        const properties = selectedSheet?.stream?.jsonSchema?.properties || {};
        return Object.keys(properties)?.map(key => ({
            label: key,
            value: key
        }));
    }, [selectedSheet]);

    React.useEffect(() => {
        dispatch(
            integrationActions.getSources({
                success: sources => {
                    if (defaultSourceId) {
                        const foundSource = sources?.find(s => s?.id?.toString() === defaultSourceId);
                        if (foundSource) {
                            setSelectedSource({
                                value: foundSource?.id,
                                label: foundSource?.name,
                                icon: () => getConnectorIcon(foundSource?.connector, '16px')
                            });
                        }
                    }
                },
                error: () => {}
            })
        );
    }, [dispatch, defaultSourceId]);

    React.useEffect(() => {
        if (selectedProject) {
            dispatch(
                databaseActions.getDatabases({
                    workspaceId: selectedProject?.value,
                    successCallback: ({ databases }) => {
                        if (!connectionRef.current) {
                            const defaultDB =
                                databases?.length === 0
                                    ? null
                                    : {
                                          label: databases?.[0]?.name,
                                          value: databases?.[0]?.id,
                                          icon: () => <DatabaseSVG />
                                      };
                            setSelectedDatabase(defaultDB);
                        } else {
                            const foundDb = databases?.find(
                                db => db?.id === connectionRef?.current?.destinationDatabaseId
                            );
                            if (foundDb) {
                                setSelectedDatabase({
                                    label: foundDb?.name,
                                    value: foundDb?.id,
                                    icon: () => <DatabaseSVG />
                                });
                            } else {
                                setSelectedDatabase(
                                    databases?.length === 0
                                        ? null
                                        : {
                                              label: databases?.[0]?.name,
                                              value: databases?.[0]?.id
                                          }
                                );
                            }
                        }
                    },
                    errorCallback: () => {}
                })
            );
        }
    }, [selectedProject, dispatch]);

    React.useEffect(() => {
        if (selectedDatabase) {
            dispatch(
                gridActions.fetchGridsByDatabase({
                    dbId: selectedDatabase?.value,
                    successCallback: () => {
                        console.log('fetchGridList success');
                    },
                    errorCallback: () => {
                        console.log('fetchGridList failed');
                    }
                })
            );
        }
    }, [dispatch, selectedDatabase]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspacesByGraphql({
                successCallback: workspaces => {
                    if (isEdit) {
                        dispatch(
                            integrationActions.getConnection({
                                cId: id,
                                success: connection => {
                                    setSelectedProject({
                                        value: connection?.destinationProjectId,
                                        label: workspaces?.find(w => w?.id === connection?.destinationProjectId)?.name,
                                        icon: () => <WorkspaceSVG />
                                    });

                                    setSelectedDatabase({
                                        value: connection?.destinationDatabaseId,
                                        label: connection.destinationDatabaseName,
                                        icon: () => <DatabaseSVG />
                                    });

                                    setSelectedGrid({
                                        value: connection?.destinationGridId,
                                        label: connection.destinationGridName,
                                        icon: () => <GridIcon size="small" />
                                    });

                                    setSelectedSource({
                                        value: connection?.sourceId,
                                        label: connection.sourceResponse?.name,
                                        connector: connection?.sourceResponse?.connector,
                                        icon: () => getConnectorIcon(connection?.sourceResponse?.connector, '16px')
                                    });

                                    setColumnMappings(
                                        connection?.columnMappings?.reduce((obj, i) => {
                                            obj[i?.columnName] = i?.gridColumnId;
                                            return obj;
                                        }, {})
                                    );

                                    setConnectionName(connection?.name);

                                    connectionRef.current = connection;
                                },
                                error: () => {}
                            })
                        );
                    }
                },
                errorCallback: () => {
                    console.log('fetch workspaces failed');
                }
            })
        );
    }, [dispatch, isEdit, id]);

    React.useEffect(() => {
        if (selectedSource) {
            setIsSheetLoading(true);
            dispatch(
                integrationActions.getSheetsBySourceId({
                    sId: selectedSource?.value,
                    success: streams => {
                        setIsSheetLoading(false);

                        if (connectionRef.current) {
                            const foundSelectedSheet = streams?.find(
                                s => s?.config?.aliasName === connectionRef.current?.configuration?.selectedSheet
                            );

                            if (foundSelectedSheet) {
                                setSelectedSheet({
                                    ...foundSelectedSheet,
                                    value: foundSelectedSheet?.config?.aliasName,
                                    label: foundSelectedSheet?.stream?.name
                                });
                            }
                        }
                    },
                    error: () => {
                        setIsSheetLoading(false);
                        console.log('fetch sheetsBySourceId failed');
                    }
                })
            );
        }
    }, [dispatch, selectedSource]);

    const sheetColumnOptionNames = React.useMemo(() => {
        return sheetColumnOptions?.map(i => i?.label);
    }, [sheetColumnOptions]);

    const isValid = React.useMemo(() => {
        return (
            selectedSource &&
            selectedSheet &&
            selectedDatabase &&
            selectedProject &&
            selectedGrid &&
            Object.keys(columnMappings)?.length
        );
    }, [selectedSource, selectedSheet, selectedDatabase, selectedProject, selectedGrid, columnMappings]);

    const handleUpdate = React.useCallback(() => {
        setIsSubmitting(true);

        const connectionBody = {
            name: connectionName,
            sourceId: selectedSource?.value,
            destinationProjectId: selectedProject?.value,
            destinationDatabaseId: selectedDatabase?.value,
            destinationGridId: selectedGrid?.value,
            columnMappings: Object.keys(columnMappings)
                ?.filter(columnName => {
                    return !isEdit ? true : sheetColumnOptionNames?.includes(columnName);
                })
                ?.map(columnName => ({
                    columnName, // column name in sheet, not grid colum name
                    gridColumnId: columnMappings?.[columnName]
                })),
            configuration: {
                selectedSheet: selectedSheet.config.aliasName
            }
        };

        if (isEdit) {
            dispatch(
                integrationActions.updateConnection({
                    cId: +id,
                    connection: connectionBody,
                    success: () => {
                        dispatch(
                            enqueueSnackbar({
                                message: 'Your connection settings have been saved',
                                type: 'info'
                            })
                        );
                        sendManualTrack({ type: 'Save And Check Connection' });
                        onBack && onBack();
                    },
                    error: () => {
                        console.log('failed create connection');
                        setIsSubmitting(false);
                    }
                })
            );
        }
    }, [
        connectionName,
        onBack,
        sheetColumnOptionNames,
        id,
        isEdit,
        columnMappings,
        selectedDatabase,
        selectedGrid,
        selectedProject,
        selectedSheet,
        selectedSource,
        dispatch
    ]);

    const refreshDiscover = React.useCallback(() => {
        if (selectedSource) {
            setIsSheetLoading(true);
            dispatch(
                integrationActions.getFreshSheetsBySourceId({
                    sId: selectedSource?.value,
                    success: streams => {
                        setIsSheetLoading(false);

                        if (connectionRef.current) {
                            const foundSelectedSheet = streams?.find(
                                s => s?.config?.aliasName === connectionRef.current?.configuration?.selectedSheet
                            );

                            if (foundSelectedSheet) {
                                setSelectedSheet({
                                    ...foundSelectedSheet,
                                    value: foundSelectedSheet?.config?.aliasName,
                                    label: foundSelectedSheet?.stream?.name
                                });
                            }
                        }
                    },
                    error: () => {
                        setIsSheetLoading(false);
                        console.log('fetch sheetsBySourceId failed');
                    }
                })
            );
        }
    }, [dispatch, selectedSource]);

    return (
        <div className={classes.root}>
            <Grid
                style={{ width: '100%', height: '100%' }}
                container
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                wrap="nowrap"
            >
                <Grid item className={classes.formContentWrapper}>
                    {(isSubmitting || isSheetLoading) && (
                        <Grid item className={`${classes.loading}`}>
                            <Spinner size={55} thick={7} />
                        </Grid>
                    )}
                    <Grid container className={classes.formContent} direction="column" spacing={5}>
                        <Grid item container direction="column" spacing={4}>
                            <Grid item style={{ width: '100%' }}>
                                <Grid container alignItems="center" justifyContent="space-between" direction="row">
                                    <Grid item>
                                        <Typography variant="h4">Source</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ButtonBase
                                            width={270}
                                            height={36}
                                            variant="outlined"
                                            onClick={refreshDiscover}
                                        >
                                            <RefreshSVG style={{ marginRight: theme.spacing(2) }} />
                                            Update latest source schema
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" spacing={4}>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body1">{t('connection_name')}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <InputText
                                                name="name"
                                                value={connectionName || ''}
                                                height={40}
                                                onChange={e => setConnectionName(e?.target?.value)}
                                                placeholder={'Enter connection name'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body1">Select your data source type</Typography>
                                        </Grid>
                                        <Grid item>
                                            <LDBasePortal
                                                isDisabled
                                                ddPlaceholder={'Select source'}
                                                menuPlaceholder={'Find source'}
                                                options={sourceOptions}
                                                onChange={option => {
                                                    if (option?.value !== selectedSource?.value) {
                                                        setSelectedSource(option);
                                                        setSelectedSheet(null);
                                                    }
                                                }}
                                                defaultValue={selectedSource}
                                                isMulti={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Collapse in={selectedSource}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="body1">{selectedSourceLabel}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <LDBasePortal
                                                    isDisabled={isSheetLoading}
                                                    ddPlaceholder={selectedSourceLabel}
                                                    menuPlaceholder={'Find sheet'}
                                                    options={sheetOptions}
                                                    onChange={option => setSelectedSheet(option)}
                                                    defaultValue={selectedSheet}
                                                    isMulti={false}
                                                    isLoading={isSheetLoading}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={4}>
                            <Grid item>
                                <Typography variant="h4">{t('destination')}</Typography>
                            </Grid>
                            <Grid item container direction="column" spacing={4}>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body1">Project</Typography>
                                        </Grid>
                                        <Grid item>
                                            <LDBasePortal
                                                isDisabled
                                                ddPlaceholder={'Select project'}
                                                menuPlaceholder={'Find project'}
                                                options={workspaceOptions}
                                                onChange={option => setSelectedProject(option)}
                                                defaultValue={selectedProject}
                                                isMulti={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Collapse in={selectedProject}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="body1">Database</Typography>
                                            </Grid>
                                            <Grid item>
                                                <LDBasePortal
                                                    isDisabled
                                                    ddPlaceholder={'Select database'}
                                                    menuPlaceholder={'Find database'}
                                                    options={databaseOptions}
                                                    onChange={option => setSelectedDatabase(option)}
                                                    defaultValue={selectedDatabase}
                                                    isMulti={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                                <Grid item>
                                    <Collapse in={selectedDatabase}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="body1">Grid</Typography>
                                            </Grid>
                                            <Grid item>
                                                <LDBasePortal
                                                    isDisabled
                                                    ddPlaceholder={'Select grid'}
                                                    menuPlaceholder={'Find grid'}
                                                    options={gridOptions}
                                                    onChange={option => setSelectedGrid(option)}
                                                    defaultValue={selectedGrid}
                                                    isMulti={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" spacing={4}>
                            <Grid item>
                                <Typography variant="h4">Mapping column configuration</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" wrap="nowrap" spacing={3}>
                                    {sheetColumnOptions?.map(sheetOpt => {
                                        const sheetColumnNames = Object.keys(columnMappings);
                                        const selectedColumnId = columnMappings?.[sheetOpt?.value];
                                        return (
                                            <Grid item key={sheetOpt?.value}>
                                                <Grid container wrap="nowrap" direction="row" alignItems="center">
                                                    <Grid item xs={5} className={classes.name}>
                                                        <Typography variant="body2">{sheetOpt?.label}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} alignItems="center" justify="center" container>
                                                        <PathSVG />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <LDBasePortal
                                                            ddPlaceholder={'Select column'}
                                                            menuPlaceholder={'Find column'}
                                                            options={gridColumnOptions}
                                                            onChange={option => {
                                                                for (const key of sheetColumnNames) {
                                                                    if (columnMappings?.[key] === option?.value) {
                                                                        delete columnMappings?.[key];
                                                                    }
                                                                }

                                                                setColumnMappings({
                                                                    ...columnMappings,
                                                                    [sheetOpt?.value]: option?.value
                                                                });
                                                            }}
                                                            defaultValue={
                                                                !selectedColumnId
                                                                    ? null
                                                                    : gridColumnOptions?.find(
                                                                          c => c?.value === selectedColumnId
                                                                      )
                                                            }
                                                            isMulti={false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                    <Grid
                        className={classes.footer}
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        wrap="nowrap"
                    >
                        {selectedGridDetail?.globalUniquePublicRecordId && (
                            <Grid item container spacing={2} alignItems="center" direction="row" wrap="nowrap">
                                <Grid item className={classes.dlFlex}>
                                    <WarningSVG />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" display="inline" component="div">
                                        Gridly will generate the record IDs automatically for the being created records
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <ButtonBase
                                disabled={!isValid || isSubmitting}
                                width={100}
                                height={36}
                                variant="outlined"
                                onClick={onBack}
                            >
                                {t('global_cancel')}
                            </ButtonBase>
                        </Grid>
                        <Grid item>
                            <ButtonBase
                                disabled={!isValid || isSubmitting}
                                width={200}
                                height={36}
                                variant="contained"
                                onClick={handleUpdate}
                            >
                                {t('save_and_check_connection')}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default Connection;
