import React from 'react';

function MultipleRowSVG({ color = '#7869B9', ...other }) {
    return (
        <svg {...other} width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.54167 34H24.7917C26.7438 34 28.3333 32.4105 28.3333 30.4583V3.54167C28.3333 1.5895 26.7438 0 24.7917 0H3.54167C1.5895 0 0 1.5895 0 3.54167V30.4583C0 32.4105 1.5895 34 3.54167 34ZM1.41667 3.54167C1.41667 2.37008 2.37008 1.41667 3.54167 1.41667H24.7917C25.9633 1.41667 26.9167 2.37008 26.9167 3.54167V30.4583C26.9167 31.6299 25.9633 32.5833 24.7917 32.5833H3.54167C2.37008 32.5833 1.41667 31.6299 1.41667 30.4583V3.54167ZM23.8333 18.7083V14.9587V14.9583V14.7083V10.7083C23.8333 10.3173 23.516 10 23.125 10H10.375H4.70833C4.31733 10 4 10.3173 4 10.7083V14.7083V14.9583V14.9587V18.7083V18.9583V18.9587V22.9583V22.9587C4 23.3497 4.31733 23.667 4.70833 23.667H10.375H23.125C23.516 23.667 23.8333 23.3497 23.8333 22.9587V22.9583V18.9587V18.9583V18.7083ZM5.41667 14H9.66669V11.4167H5.41667V14ZM11.0834 14H22.4167V11.4167H11.0834V14ZM5.41667 18V15.667H9.66669V18H5.41667ZM11.0834 18V15.667H22.4167V18H11.0834ZM5.41667 19.667V22.25H9.66669V19.667H5.41667ZM11.0834 19.667V22.25H22.4167V19.667H11.0834Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(MultipleRowSVG);
