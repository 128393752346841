import React from 'react';

function TagSVG({ color = '#78778B', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.577125 2.80575C0.961875 2.43562 1.40737 2.25 1.91138 2.25H12.312C12.9634 2.25 13.482 2.51775 13.8668 3.051L17.5432 8.23725C17.8166 8.62313 17.8166 9.1395 17.5432 9.5265L13.8668 14.7116C13.482 15.246 12.9634 15.5126 12.312 15.5126H1.91138C1.40737 15.5126 0.961875 15.3281 0.577125 14.9569C0.1935 14.5856 0 14.148 0 13.644V4.11975C0 3.61462 0.1935 3.17812 0.577125 2.80575ZM12.1208 4.1445H1.8945V13.6181H12.1208L15.6049 8.88075L12.1208 4.1445Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TagSVG);
