import React, { useState } from 'react';

import { useCurrentUserFullInfo } from 'hooks/auth';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import AccountSwitchingConfirm from './AccountSwitchingConfirm';

const AccountDiffCheck = props => {
    const { location, children } = props;
    const queryParams = queryParamsToObject(location?.search);
    const paramEmail = queryParams?.email;
    const userInfo = useCurrentUserFullInfo();
    const [currentAccountEmail, setCurrentAccountEmail] = useState(userInfo?.email);

    const handleConfirmed = () => {
        setCurrentAccountEmail('');
    };

    if (paramEmail && currentAccountEmail) {
        return (
            <AccountSwitchingConfirm
                currentAccountEmail={currentAccountEmail}
                newAccountEmail={paramEmail}
                handleConfirmed={handleConfirmed}
            />
        );
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default AccountDiffCheck;
