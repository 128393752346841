import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress, useTheme, Tabs, Tab } from '@material-ui/core';
import AddonLayout from 'addons/layout';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';
import GridlyShortLogoSVG from 'assets/images/svg/addon/GridlyShortLogoSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import TranslationOverviewGridlyPNG from 'assets/images/svg/addon/TranslationOverviewGridly.png';
import TranslationOverviewGridlyPNG2 from 'assets/images/svg/addon/TranslationOverviewGridly2.png';
import TranslationOverviewSVG from 'assets/images/svg/addon/TranslationOverviewSVG';
import PopperMenu from 'components/menus/Popper';
import MenuOption from '../MenuOption';
import * as addonActions from 'addons/actions';
import { useDispatch } from 'react-redux';
import { ADD_ON_STATES } from 'const';
import CheckedSVG from 'assets/images/svg/CheckedSVG';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    stepSpacing: {
        marginBottom: theme.spacing(3)
    },
    sendFeedback: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    dlFlex: {
        display: 'flex'
    },
    avatar: {
        '& svg': {
            width: 88,
            height: 88
        }
    },
    expand: {
        flex: 1
    },
    more: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 10
    },
    notEnabled: {
        background: `#F1F7FA`,
        height: '100%'
    },
    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700
    },
    settingIcon: {
        display: 'inline'
    },
    dot: {
        width: 8,
        height: 8,
        minWidth: 8,
        display: 'inline-block',
        marginRight: theme.spacing(2),
        position: 'relative',
        top: -1,
        borderRadius: 2
    },
    upToDate: {
        background: hexToRgba(theme.colors.atlantis, 0.8)
    },
    outOfDate: {
        background: hexToRgba(theme.colors.sun, 0.8)
    },
    unset: {
        background: hexToRgba(theme.colors.brightRed, 0.8)
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    addonWrapper: {
        padding: 40,
        background: theme.colors.ghostwhite,
        borderRadius: 8,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    tabContent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    SETTING: 'SETTING',
    OVERVIEW: 'OVERVIEW'
};

function TranslationOverview({ addon, onBack }) {
    const { icon, title, description, provider, integration } = addon;
    const integrationId = integration?.id;
    const integrationState = integration?.state;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const tabClasses = useTabStyles();
    const [isDoing, setIsDoing] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tabValue, setTabValue] = React.useState(TABS.OVERVIEW);

    const handleClick = React.useCallback(
        event => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const isInstalled = React.useMemo(() => {
        return integrationState === ADD_ON_STATES.ACTIVE || integrationState === ADD_ON_STATES.DRAFT;
    }, [integrationState]);

    const handleEnableAddon = React.useCallback(() => {
        setIsDoing(true);
        if (!integrationId) {
            dispatch(
                addonActions.enableAddon({
                    body: {
                        title,
                        description,
                        provider,
                        state: ADD_ON_STATES.ACTIVE
                    },
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('Enable add on success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);

                        console.log('Enable add on failed');
                    }
                })
            );
        } else {
            dispatch(
                addonActions.changeAddonState({
                    addonId: integrationId,
                    newState: ADD_ON_STATES.DRAFT,
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Failed');
                    }
                })
            );
        }
    }, [description, title, dispatch, integrationId, provider]);

    const handleRemoveAddon = React.useCallback(
        (e, addonId) => {
            setIsDoing(true);

            dispatch(
                addonActions.changeAddonState({
                    addonId,
                    newState: ADD_ON_STATES.INACTIVE,
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Failed');
                    }
                })
            );
            handleClickAway();
        },
        [dispatch, handleClickAway]
    );

    const renderContent = React.useCallback(() => {
        switch (integrationState) {
            case ADD_ON_STATES.DRAFT:
            case ADD_ON_STATES.ACTIVE:
                return (
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="body2">{t('addon_translation_active_title')}</Typography>
                        </Grid>
                        <Grid item className={classes.expand}>
                            <Typography variant="body2" display="inline">
                                Click on the icon{' '}
                            </Typography>
                            <TranslationOverviewSVG className={classes.settingIcon} />
                            <Typography variant="body2" display="inline">
                                {' '}
                                on the right side bar of a Grid to access the dashboard
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 14 }}>
                            <img
                                style={{ width: '100%' }}
                                src={TranslationOverviewGridlyPNG2}
                                alt="translation-overview"
                            />
                        </Grid>
                    </Grid>
                );
            case ADD_ON_STATES.INACTIVE:
                return (
                    <Grid container alignItems="center" justify="center" className={classes.notEnabled}>
                        <Grid item>
                            <img
                                style={{ width: '100%' }}
                                src={TranslationOverviewGridlyPNG}
                                alt={t(`addon_translation_image_alt`)}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return (
                    <Grid container alignItems="center" justify="center" className={classes.notEnabled}>
                        <Grid item>
                            <img src={TranslationOverviewGridlyPNG} alt={t(`addon_translation_image_alt`)} />
                        </Grid>
                    </Grid>
                );
        }
    }, [integrationState, classes, t]);

    return (
        <AddonLayout onBack={onBack}>
            <Grid container direction="column">
                <Grid item>
                    <Grid container className={classes.addonWrapper} direction="row" justifyContent="space-between">
                        <Grid item>
                            <Grid container direction="row" wrap="nowrap" spacing={3}>
                                <Grid item className={classes.avatar}>
                                    {icon()}
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h4">{title}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="caption">
                                                {t(`global_last_update_in`)} Sep 14th, 2020
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item className={classes.dlFlex}>
                                                    <GridlyShortLogoSVG />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1">{t(`global_gridly_text`)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="row" alignItems="center">
                                <Grid item className={`${classes.expand} ${classes.buttonWrapper}`}>
                                    <ButtonBase
                                        onClick={handleEnableAddon}
                                        disabled={isInstalled || isDoing}
                                        variant="contained"
                                        fullWidth
                                    >
                                        {isInstalled ? (
                                            <Grid container justify="center" alignItems="center">
                                                <Grid item>
                                                    <CheckedSVG
                                                        color={theme.colors.white}
                                                        fillColor={hexToRgba(theme.colors.atlantis, 0.8)}
                                                        style={{ marginRight: 4 }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1"> {t(`global_installed`)}</Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Typography variant="body1" style={{ color: theme.colors.white }}>
                                                {t(`global_install`)}
                                            </Typography>
                                        )}
                                    </ButtonBase>
                                    {isDoing && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Grid>
                                {isInstalled && (
                                    <Grid item>
                                        <IconButton onClick={handleClick} className={classes.more}>
                                            <IconMoreActionsSVG />
                                        </IconButton>
                                    </Grid>
                                )}
                                {anchorEl && (
                                    <PopperMenu
                                        className={classes.commentPopup}
                                        anchorEl={anchorEl}
                                        placement={'bottom-end'}
                                        id={`add-on-setting`}
                                    >
                                        <MenuOption
                                            onRemoveAddon={handleRemoveAddon}
                                            id={integrationId}
                                            handleClickAway={handleClickAway}
                                        />
                                    </PopperMenu>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Tabs
                                className={classes.tabs}
                                value={tabValue}
                                onChange={(e, newValue) => setTabValue(newValue)}
                            >
                                <Tab classes={tabClasses} value={TABS.OVERVIEW} label={t('overview')} />
                                <Tab classes={tabClasses} value={TABS.SETTING} label={t('global_settings')} />
                            </Tabs>
                        </Grid>
                        <Grid item className={classes.tabContent}>
                            {tabValue === TABS.OVERVIEW ? (
                                <Grid container direction="column" spacing={5}>
                                    <Grid item>
                                        <Grid container direction="column" spacing={4}>
                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {t(`global_description`)}:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            {t(`addon_translation_install_description`)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column">
                                                    <Grid item>
                                                        <div className={`${classes.dot} ${classes.upToDate}`}></div>
                                                        <Trans
                                                            t={t}
                                                            i18nKey="addon_translation_up_to_date"
                                                            default={
                                                                '<bold>Up to date:</bold> <normal>the latest version of the source language is translated.</normal>'
                                                            }
                                                            components={{
                                                                bold: <Typography variant="body1" display="inline" />,
                                                                normal: <Typography variant="body2" display="inline" />
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <div className={`${classes.dot} ${classes.outOfDate}`}></div>
                                                        <Trans
                                                            t={t}
                                                            i18nKey="addon_translation_out_of_date"
                                                            default={
                                                                '<bold>Out of date:</bold> <normal>translation needs to be updated to match the latest source language version.</normal>'
                                                            }
                                                            components={{
                                                                bold: <Typography variant="body1" display="inline" />,
                                                                normal: <Typography variant="body2" display="inline" />
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <div className={`${classes.dot} ${classes.unset}`}></div>
                                                        <Trans
                                                            t={t}
                                                            i18nKey="addon_translation_unset"
                                                            default={
                                                                '<bold>Unset:</bold> <normal>translation is missing.</normal>'
                                                            }
                                                            components={{
                                                                bold: <Typography variant="body1" display="inline" />,
                                                                normal: <Typography variant="body2" display="inline" />
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="body1">{t(`global_notes`)}:</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Trans
                                                            t={t}
                                                            i18nKey="addon_translation_note_description"
                                                            default={
                                                                '<normal>• Percentages and numbers are calculated for the active view.</normal> <normal>• Dependencies between the source and target languages have to be set to show the translation status.</normal>'
                                                            }
                                                            components={{
                                                                normal: <Typography variant="body2" />
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="body1">
                                                            {t(`global_permission`)}:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            {t(`addon_translation_permission_description`)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                renderContent()
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AddonLayout>
    );
}

export default React.memo(TranslationOverview);
