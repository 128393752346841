import { TM_STATUS } from 'const/gridUI';
import { getCellData, getCellTmStatus } from 'utils/gridUI/cell';

export function formatSearchTranslationResponse({ translations, targetLang }) {
    let texts = [];
    translations
        .filter(translation => translation?.lang === targetLang)
        .forEach(unit => {
            texts = [...texts, ...unit.texts];
        });

    return texts;
}

export function makeTmStatus({ recordIds = [], columnIds = [], data = {} }) {
    const cellsChanged = {};
    const redoData = {};
    const serverData = [];

    for (const recordId of recordIds) {
        for (const columnId of columnIds) {
            const cellData = getCellData({ data, columnId, rowId: recordId });
            const cellTmStatus = getCellTmStatus({ data, rowId: recordId, columnId });

            if (TM_STATUS.APPROVED === cellTmStatus) {
                if (!redoData[recordId]) {
                    redoData[recordId] = {};
                }

                redoData[recordId][columnId] = {
                    ...cellData,
                    _tm: false
                };

                cellsChanged[recordId] = {
                    ...cellsChanged[recordId],
                    [columnId]: cellData
                };
            }
        }
    }

    const affectedRecordIds = Object.keys(cellsChanged);
    let affectedColumnIds = [];

    for (const recordId of affectedRecordIds) {
        const columnsData = cellsChanged?.[recordId];
        const changedColumnIds = Object.keys(columnsData);
        affectedColumnIds = [...new Set(affectedColumnIds.concat(changedColumnIds))];
    }

    for (const recordId of affectedRecordIds) {
        const rowData = [];
        const rowChangedData = cellsChanged?.[recordId];

        for (const columnId of affectedColumnIds) {
            let rowItemData = { _tm: false };

            if (!rowChangedData?.hasOwnProperty(columnId)) {
                rowItemData = { _tm: getCellTmStatus({ data, columnId, rowId: recordId }) };
            }
            rowData.push(rowItemData);
        }

        serverData.push([recordId, ...rowData]);
    }

    return { redoData, cellsChanged, serverData, affectedColumnIds, affectedRecordIds };
}

export function isShowApproveTmStatusByRange({ columnIds, recordIds, data }) {
    let isShow = false;

    for (let recordId of recordIds) {
        for (let columnId of columnIds) {
            const tmStatus = getCellTmStatus({ data, rowId: recordId, columnId });
            if (tmStatus) {
                isShow = true;
                break;
            }
        }
    }

    return isShow;
}
