import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CommentItem from './CommentItem';
import { RESOLVED, REOPENED, COMMENT } from 'const/commentStatus';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { formatDateFromNow } from 'utils/datetime';
import CommentReOpenSVG from 'assets/images/svg/CommentReOpenSVG';
import CommentResolvedSVG from 'assets/images/svg/CommentResolvedSVG';
import AvatarBase from 'components/avatar/Base';
import { getAvatarUrl } from 'utils/images';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        height: props => props.COMMENT_LIST_HEIGHT || '100%',
        overflowY: 'auto',
        paddingTop: 20,
        paddingLeft: 16,
        paddingRight: 16
    },
    commentResolveOrReOpen: {
        marginBottom: 20,
        paddingBottom: 20,
        borderBottom: `1px solid ${theme.colors.divider}`
    },
    fullWidth: {
        width: '100%'
    },
    last: {
        marginBottom: 0,
        borderBottom: `none`
    },
    avatar: {
        marginRight: SPACING_LIST_NAME_WITH_ICON,
        display: 'flex'
    },
    commentContent: {
        flex: 1
    },
    dlFlex: {
        display: 'flex'
    },
    resolvedText: {
        color: theme.colors.atlantis
    },
    reopenedText: {
        color: theme.colors.crusta
    }
}));

function CommentList({
    comments,
    updateCommentHandler,
    updateThreadHandler,
    deleteThreadHandler,
    currentUser,
    thread,
    deleteCommentHandler,
    scrollCommentId = 'scrollToHere',
    className,
    COMMENT_LIST_HEIGHT,
    t
}) {
    const classes = useStyles({ COMMENT_LIST_HEIGHT });
    const theme = useTheme();

    return (
        <Grid container className={`${classes.root} ${className}`} direction="column" wrap="nowrap">
            <CommentItem
                updateHandler={updateThreadHandler}
                deleteHandler={deleteThreadHandler}
                currentUser={currentUser}
                isThread={true}
                t={t}
                {...thread}
            />
            {comments.map((comment, index) => {
                const isLast = index === comments?.length - 1;
                if (comment.isDeleted) return null;
                if (comment.type === COMMENT)
                    return (
                        <CommentItem
                            key={comment.id}
                            {...comment}
                            updateHandler={updateCommentHandler}
                            deleteHandler={deleteCommentHandler}
                            currentUser={currentUser}
                            isLast={isLast}
                            t={t}
                        />
                    );
                if (comment.type === RESOLVED)
                    return (
                        <Grid
                            item
                            key={comment.id}
                            className={`${classes.commentResolveOrReOpen} ${classes.fullWidth} ${
                                isLast ? classes.last : ''
                            }`}
                        >
                            <Grid container direction="row" alignItems="flex-start">
                                <Grid item className={classes.avatar}>
                                    <AvatarBase
                                        src={getAvatarUrl(comment?.imageUrl || comment?.createdBy)}
                                        size="small"
                                    >
                                        <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                    </AvatarBase>
                                </Grid>

                                <Grid item className={classes.commentContent}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <p className="body1 inline">{comment?.fullName}</p>{' '}
                                            <p className="caption inline">{formatDateFromNow(comment.createdAt)}</p>
                                        </Grid>
                                        <Grid item container alignItems="center" spacing={1}>
                                            <Grid item className={classes.dlFlex}>
                                                <CommentResolvedSVG
                                                    width={12}
                                                    height={12}
                                                    color={theme.colors.atlantis}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2  text-atlantis">{t('resolved_this_comment')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                if (comment.type === REOPENED)
                    return (
                        <Grid
                            item
                            key={comment.id}
                            className={`${classes.commentResolveOrReOpen} ${classes.fullWidth} ${
                                isLast ? classes.last : ''
                            }`}
                        >
                            <Grid container direction="row" alignItems="flex-start">
                                <Grid item className={classes.avatar}>
                                    <AvatarBase
                                        src={getAvatarUrl(comment?.imageUrl || comment?.createdBy)}
                                        size="small"
                                    >
                                        <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                    </AvatarBase>
                                </Grid>

                                <Grid item className={classes.commentContent}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <p className="body1 inline">{comment?.fullName}</p>{' '}
                                            <p className="caption inline">{formatDateFromNow(comment.createdAt)}</p>
                                        </Grid>
                                        <Grid item container alignItems="center" spacing={1}>
                                            <Grid item className={classes.dlFlex}>
                                                <CommentReOpenSVG width={12} height={12} color={theme.colors.crusta} />
                                            </Grid>
                                            <Grid item>
                                                <p className="body2 text-atlantis">{t('reopened_this_comment')}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                return null;
            })}
            <span id={scrollCommentId} style={{ height: 1 }}>
                &nbsp;
            </span>
        </Grid>
    );
}

export default React.memo(CommentList);
