import isEmpty from 'lodash/isEmpty';
import * as columnTypes from 'const/columnTypes';
import { isLDEmpty } from 'utils/object';
import { NUMBER_DEFAULT_VALUE } from 'const/gridUI';
import {
    getReferenceServerValue,
    getLocalizationServerValue,
    getFileServerValue,
    getCorrectColumnType
} from 'utils/gridUI/formatData';
import isArray from 'lodash/isArray';
import store from 'store/configStore';
import { between } from 'utils/usage';
import { alpha } from '@material-ui/core';
import { getCellBackgroundByHex } from 'utils/color';

export function isCellTM({ recordMetaData, rowId, columnId }) {
    return recordMetaData?.[rowId]?.['_metadata']?.[columnId]?.tm;
}

export function isOutOfRange({ columnStartIndex, rowStartIndex, gridRef, rowStopIndex, columnStopIndex }) {
    const { _rowStartIndex, _rowStopIndex, _columnStartIndex, _columnStopIndex } = gridRef;

    return (
        (+rowStartIndex < +_rowStartIndex && +rowStopIndex < +_rowStartIndex) ||
        (+rowStopIndex > +_rowStopIndex && +rowStopIndex > +_rowStopIndex) ||
        (+columnStartIndex < +_columnStartIndex && +columnStopIndex < +_columnStartIndex) ||
        (+columnStopIndex > +_columnStopIndex && +columnStopIndex > +_columnStopIndex)
    );
}

export function isCellOverlaid({ rowIndex, columnIndex }) {
    const { getState } = store;
    const { gridUI } = getState();
    const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = gridUI;
    const _rowStartIndex = Math.min(rowStartIndex, rowStopIndex);
    const _rowStopIndex = Math.max(rowStartIndex, rowStopIndex);
    const _columnStartIndex = Math.min(columnStartIndex, columnStopIndex);
    const _columnStopIndex = Math.max(columnStartIndex, columnStopIndex);
    return (
        between(rowIndex, _rowStartIndex, _rowStopIndex) && between(columnIndex, _columnStartIndex, _columnStopIndex)
    );
}

export function getElementOffsetPosition(e) {
    const el = e.target;
    const childPos = el.getBoundingClientRect();
    const parentPos = document.getElementById('gridUI-table').getBoundingClientRect();
    return {
        top: childPos.top - parentPos.top,
        left: childPos.left
    };
}

export function getElementHeight(elmID) {
    // eslint-disable-next-line no-unused-vars
    let elmHeight;
    // let elmMargin;
    let elm = document.getElementById(elmID);
    if (document.all) {
        // IE
        elmHeight = elm.currentStyle.height;
        // elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10) + "px";
    } else {
        // Mozilla
        elmHeight = document.defaultView.getComputedStyle(elm, '').getPropertyValue('height');
        // elmMargin =
        //     parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top')) +
        //     parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom')) +
        //     'px';
    }
    return elmHeight;
}

export function getCorrectCellData({ type, cellData }) {
    switch (type) {
        case columnTypes.REFERENCE: {
            return cellData?.referencedDataItem;
        }
        default:
            return cellData;
    }
}

export function getNumberDisplay({ numberFormat, data }) {
    if (isLDEmpty(data)) return '';

    const use1000Separator = numberFormat?.use1000Separator || NUMBER_DEFAULT_VALUE?.use1000Separator;
    const decimalPlaces = numberFormat?.decimalPlaces || NUMBER_DEFAULT_VALUE?.decimalPlaces;
    const currencySymbol = numberFormat?.currencySymbol?.trim() || NUMBER_DEFAULT_VALUE?.currencySymbol;
    const formatValue = parseFloat(data)?.toFixed(decimalPlaces);
    const arr = formatValue?.split('.');
    const first = use1000Separator ? Number(arr?.[0])?.toLocaleString() : arr?.[0];
    const second = arr?.[1];
    const isNegative = formatValue < 0;

    return `${isNegative ? `-` : ``}${currencySymbol}${isNegative ? first?.replace(/[-]/g, '') : first}${
        decimalPlaces === 0 ? '' : `.${second}`
    }`;
}

export function getFloatNumber(value) {
    const arr = value?.toString().split('.');
    return parseFloat(arr?.slice(0, 2)?.join('.'));
}

export function getCellValueOnly({ data, rowId, columnId }) {
    return data?.[rowId]?.[columnId]?.value;
}

export function getCellServerValue({ data, rowId, columnId, metaData }) {
    const cellValue = getCellValueOnly({ data, rowId, columnId });
    const column = metaData?.[columnId];
    const columnType = getCorrectColumnType(column);

    switch (columnType) {
        case columnTypes.REFERENCE: {
            if (isArray(cellValue) && !isEmpty(cellValue)) {
                const items = data?.filter(
                    obj => obj.hasOwnProperty('referencedRecordId') && obj.hasOwnProperty('referencedDataItem')
                );
                if (!items?.length) return null;
                return items.map(item => item?.referencedRecordId);
            } else {
                return null;
            }
        }
        case columnTypes.FILES: {
            if (isArray(cellValue)) {
                return cellValue?.map(value => value?.id)?.filter(Boolean);
            }
            break;
        }

        default:
            return cellValue;
    }
}

export function getCellDependencyStatus({ data, rowId, columnId }) {
    return data?.[rowId]?.[columnId]?._dependencyStatus;
}

export function getCellSourceStatus({ data, rowId, columnId }) {
    return data?.[rowId]?.[columnId]?._sourceStatus;
}

export function getCellTmStatus({ data, rowId, columnId }) {
    return data?.[rowId]?.[columnId]?._tm;
}

export function getCellData({ data, rowId, columnId }) {
    return data?.[rowId]?.[columnId];
}

export function getServerValueOfSpecialType({ type, value }) {
    switch (type) {
        case columnTypes.REFERENCE: {
            const refValues = getReferenceServerValue(value)?.values;
            return refValues || null;
        }

        case columnTypes.LOCALIZATION: {
            let convertValue = Number(getLocalizationServerValue(value)?.value);
            return isNaN(convertValue) ? null : convertValue;
        }

        case columnTypes.FILES: {
            const convertValue = getFileServerValue(value);
            return convertValue;
        }

        default:
            return value ?? null;
    }
}

export function getHighlightWords(str, highlightPositions = []) {
    if (!highlightPositions?.length || !str) return [];

    const arr = highlightPositions?.flat(1);
    return arr?.map(chunk => str?.slice(chunk?.[0], chunk?.[1] + 1));
}

export function getBackgroundStyle({
    background,
    fillColorBlindness,
    isCellCopyHighlight,
    isSelectedByRowOrColumn,
    isReadOnly,
    theme
}) {
    const isSelect = isSelectedByRowOrColumn || isCellCopyHighlight;
    let bg = `transparent`;
    bg = isSelectedByRowOrColumn
        ? background
            ? fillColorBlindness === 'BLIND'
                ? `${alpha(background, 0.8)} url(${getCellBackgroundByHex(background)})`
                : alpha(background, 0.8)
            : theme.colors.selectionColor
        : bg;

    bg = isCellCopyHighlight
        ? background
            ? fillColorBlindness === 'BLIND'
                ? `${alpha(background, 0.8)} url(${getCellBackgroundByHex(background)})`
                : alpha(background, 0.8)
            : theme.colors.solitude
        : bg;

    if (isSelect) {
        if (!isReadOnly) return bg;
        return `repeating-linear-gradient(-60deg,${bg},${bg} 4px, #D4D4D9 5px)`;
    }

    if (background) {
        if (fillColorBlindness === 'BLIND') {
            return `${background} url(${getCellBackgroundByHex(background)})`;
        } else if (isReadOnly) {
            return `repeating-linear-gradient(-60deg,${background},${background} 4px, #D4D4D9 5px)`;
        } else {
            return background;
        }
    } else if (isReadOnly) {
        return `repeating-linear-gradient(-60deg,transparent ,transparent 4px, #D4D4D9 5px)`;
    } else {
        return `transparent`;
    }
}
