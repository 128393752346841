import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import ImageRender from './components/Files';
import SingleText from './components/SingleText';
import MultiText from './components/MultiText';
import Richtext from './components/Richtext';
import JsonText from './components/JsonText';
import YamlText from './components/YamlText';
import RecordId from './components/RecordId';
import HtmlText from './components/HtmlText';
import Markdown from './components/Markdown';
import Number from './components/Number';
import BooleanRender from './components/Boolean';
import Date from './components/Date';
import MultiSelect from './components/MultiSelect';
import SingleSelect from './components/SingleSelect';
import Ref from './components/Ref';
import FormulaTextRender from './components/FormulaText';
import TranslationText from './components/TranslationText';
import PathTag from './components/PathTag';
import AlterBy from './components/AlterBy';
import * as columnTypes from 'const/columnTypes';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import Tooltip from 'components/tooltip/Base';
import { RESOLVED } from 'const/commentStatus';
import CommentNotifySVG from 'assets/images/svg/CommentNotifySVG';
import TMToolTipPreviewSVG from 'assets/images/svg/localization/TMToolTipPreviewSVG';
import { SYSTEM_COLUMN_IDS, PATH_TAG_ID } from 'const';
import { Trans } from 'react-i18next';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import LQAIconSVG from 'assets/images/svg/LQAIconSVG';
import {
    useCellMetaData,
    useIsSelectedByRowOrColumn,
    useCellData,
    useIsCellReadyOnly,
    useCellParentText,
    useIsExistRecordMetaDataRecordId
} from 'hooks/gridUI';
import { generateTags } from 'utils/gridUI/tag';
import { removeArrayInArray } from 'utils/object';
import { capitalize } from 'lodash';
import LangStatus from 'components/langStatus';
import { isTempId } from 'utils/uuid';
import { getBackgroundStyle, isCellOverlaid } from 'utils/gridUI/cell';
import classNames from 'classnames';
import { LOCALIZATION_FONT } from 'const/gridUI';
import { isHexColor } from 'utils/color';

function checkTypeAndRender({ type, ...rest }) {
    if (rest?.isProcessing)
        return (
            <Grid
                container
                justify="center"
                alignItems="flex-start"
                style={{
                    height: rest?.rowHeight
                }}
                className={`cell_${rest?.rowIndex}_${rest?.columnIndex} RECORD_${rest?.rowId}`}
                {...rest}
            >
                <div
                    style={{
                        width: '100%',
                        borderRadius: 9,
                        background: `#F4F5F7`,
                        height: 12,
                        marginTop: 5
                    }}
                ></div>
            </Grid>
        );

    switch (type) {
        case columnTypes.FILES:
            return <ImageRender data={rest?.value} rowHeight={rest?.rowHeight} {...rest} />;
        case columnTypes.SINGLE_LINE:
            return <SingleText {...rest} />;
        case columnTypes.MULTIPLE_LINES:
            return <MultiText {...rest} />;
        case columnTypes.RICH_TEXT:
            return <Richtext {...rest} />;
        case columnTypes.MARKDOWN:
            return <Markdown {...rest} />;
        case columnTypes.NUMBER:
            return <Number {...rest} />;
        case columnTypes.BOOLEAN:
            return <BooleanRender {...rest} />;
        case columnTypes.DATETIME:
            return <Date {...rest} />;
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <Date {...rest} />;
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
            return <AlterBy {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelect {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelect {...rest} />;
        case columnTypes.REFERENCE:
            return <Ref {...rest} />;

        case columnTypes.FORMULA:
            return <FormulaTextRender {...rest} />;
        case columnTypes.TRANSLATION:
            return <TranslationText {...rest} />;
        case columnTypes.PATH_TAG:
            return <PathTag {...rest} />;
        case columnTypes.JSON_LD:
            return <JsonText {...rest} />;
        case columnTypes.HTML:
            return <HtmlText {...rest} />;
        case columnTypes.YAML:
            return <YamlText {...rest} />;
        case columnTypes.RECORD_ID:
            return <RecordId {...rest} />;
        default:
            return <SingleText {...rest} />;
    }
}

function CellRow({
    className,
    rowIndex,
    columnIndex,
    rowId,
    columnId,
    rowHeight,
    filterValue,
    gridId,
    defaultAccessViewId,
    dbId,
    columnWidth,
    isRowOverLimit,
    dependencies,
    positionTop,
    positionBottom,
    previousRowId,
    nextRowId,
    isForcedShowDependencyStatus,
    isCellCopyHighlight,
    isTmDisabled,
    isShareViewLink,
    t,
    fillColorBlindness,
    dependencyBlindness,
    isOverFileStorage,
    ldUserEmailMap,
    isShowTag,
    tokenDetection,
    predefinedTokens,
    isShowAutoQA,
    isShowLockCell,
    rawCellData,
    isCaseSensitive,
    viewColumnIds,
    column,
    isViewOnly
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const cellMetaData = useCellMetaData({ columnId, recordId: rowId });
    const isSelectedByRowOrColumn = useIsSelectedByRowOrColumn({ columnId, rowIndex });
    const cellData = useCellData({ rawCellData, rowId, columnId });

    const parentText = useCellParentText({ rowId, columnId });

    const dependencyStatus = React.useMemo(() => {
        return cellData?._dependencyStatus;
    }, [cellData]);

    const sourceStatus = React.useMemo(() => {
        return cellData?._sourceStatus;
    }, [cellData]);

    const tmStatus = React.useMemo(() => {
        return cellData?._tm;
    }, [cellData]);

    const background = React.useMemo(() => {
        const color = cellData?._color;
        const isHex = isHexColor(color);
        return isHex ? color : null;
    }, [cellData]);

    const value = React.useMemo(() => {
        return cellData?.value;
    }, [cellData]);

    const type = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const isReadOnly = useIsCellReadyOnly({ rowId, columnId });

    const cellThreadId = React.useMemo(() => {
        return cellMetaData?.comment?.id;
    }, [cellMetaData]);

    const cellThreadStatus = React.useMemo(() => {
        return cellMetaData?.comment?.status;
    }, [cellMetaData]);

    const parentTokens = React.useMemo(() => {
        return !parentText ? [] : generateTags({ tokenDetection, predefinedTokens, text: parentText });
    }, [parentText, tokenDetection, predefinedTokens]);

    const referencedColumnType = React.useMemo(() => {
        return column?.referencedColumnType;
    }, [column]);

    const isSystemColumn = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS.filter(id => ![PATH_TAG_ID, columnTypes.RECORD_ID].includes(id))?.includes(column?.id);
    }, [column]);

    const dependenciesFiltered = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const isChildDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => dpdc?.child === columnId);
        return Boolean(dpdc) && dpdc?.parent;
    }, [columnId, dependenciesFiltered]);

    const isParentDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => dpdc?.parent === columnId);
        return Boolean(dpdc) && dpdc?.child;
    }, [columnId, dependenciesFiltered]);

    const isHasDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => {
            return dpdc?.child === columnId || dpdc?.parent === columnId;
        });
        return Boolean(dpdc) && (dpdc?.parent || dpdc?.child);
    }, [columnId, dependenciesFiltered]);

    const isShowDependencyStatus = React.useMemo(() => {
        return isForcedShowDependencyStatus ? true : isHasDependency;
    }, [isHasDependency, isForcedShowDependencyStatus]);

    const isRtl = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[column?.group]?.rtl;
    }, [column]);

    const _tokens = React.useMemo(() => {
        return generateTags({ tokenDetection, predefinedTokens, text: value });
    }, [tokenDetection, predefinedTokens, value]);

    const tagsMissingFromSources = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(parentTokens, _tokens)])];
    }, [parentTokens, _tokens]);

    const customTags = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(_tokens, parentTokens)])];
    }, [parentTokens, _tokens]);

    const warningTags = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => dpdc?.child === columnId);
        const parentId = viewColumnIds?.find(vId => vId === dpdc?.parent);
        const isChildDependency = Boolean(dpdc) && dpdc?.parent && Boolean(parentId);

        return (
            [columnTypes.TRANSLATION]?.includes(type) &&
            Boolean(isChildDependency) &&
            (customTags?.length > 0 || tagsMissingFromSources?.length > 0)
        );
    }, [type, customTags, tagsMissingFromSources, dependenciesFiltered, columnId, viewColumnIds]);

    const ignoredAutoQAErrors = React.useMemo(() => {
        return (cellData?.metadata?.validations || []).filter(error => error.ignored);
    }, [cellData]);

    const uniqueErrorCategories = React.useMemo(() => {
        return [...new Set(ignoredAutoQAErrors.map(err => err.category))];
    }, [ignoredAutoQAErrors]);

    const viewTicketHandler = React.useCallback(() => {
        dispatch(gridActions.openViewCellTicket());
    }, [dispatch]);

    const selectSelf = React.useCallback(() => {
        dispatch(
            gridActions.selectRangeCell({
                rowStartIndex: rowIndex,
                rowStopIndex: rowIndex,
                columnStartIndex: columnIndex,
                columnStopIndex: columnIndex
            })
        );
    }, [dispatch, rowIndex, columnIndex]);

    const handleContext = React.useCallback(
        e => {
            if (!isCellOverlaid({ rowIndex, columnIndex })) {
                selectSelf();
            }
            const el = document.createElement('div');
            el.style.position = 'absolute';
            el.style.left = `${e.clientX}px`;
            el.style.top = `${e.clientY}px`;
            el.id = 'virtual-range-popper';
            document.body.appendChild(el);
            dispatch(gridActions.openCellContext());
        },
        [dispatch, selectSelf, rowIndex, columnIndex]
    );

    const handleOnCommentClick = React.useCallback(() => {
        dispatch(gridActions.openCellComment());
    }, [dispatch]);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[column?.group];
    }, [column]);

    return (
        <>
            <Grid
                id={`cell_${rowIndex}_${columnIndex}`}
                className={classNames(
                    `cell_${rowIndex}_${columnIndex} RECORD_${rowId} cell m-0 w-full h-full overflow-hidden cursor-default relative ${className} ${fontJP}`,
                    {
                        'p-0': type === columnTypes.FILES,
                        'py-2 px-3.5': type !== columnTypes.FILES,
                        '': background && fillColorBlindness === 'BLIND'
                    }
                )}
                style={{
                    background: getBackgroundStyle({
                        background,
                        fillColorBlindness,
                        isCellCopyHighlight,
                        isSelectedByRowOrColumn,
                        isReadOnly: isViewOnly ? false : isReadOnly,
                        theme
                    })
                }}
                rindex={rowIndex}
                pst={positionTop}
                psb={positionBottom}
                prid={previousRowId}
                nrid={nextRowId}
                dir={isRtl ? 'rtl' : 'ltr'}
                onContextMenu={handleContext}
            >
                {checkTypeAndRender({
                    type,
                    value,
                    rowHeight,
                    columnWidth,
                    rowIndex,
                    columnIndex,
                    filterValue,
                    isCaseSensitive,
                    rowId,
                    columnId,
                    gridId,
                    defaultAccessViewId,
                    dbId,
                    customProperties: column?.customProperties || {},
                    options: column?.options || [],
                    isReadOnly: isReadOnly || isSystemColumn,
                    referencedColumnType,
                    positionTop,
                    positionBottom,
                    previousRowId,
                    nextRowId,
                    t,
                    isShareViewLink,
                    numberFormat: column?.numberFormat,
                    isOverFileStorage,
                    group: column?.group,
                    ldUserEmailMap,
                    isShowTag,
                    tokenDetection,
                    predefinedTokens,
                    parentTokens,
                    validations: cellData?.metadata?.validations,
                    targetValidations: cellData?.metadata?.targetValidations,
                    isChildDependency,
                    isFileMultiple: column?.file?.selectionType === 'multiple'
                })}

                {!isShareViewLink && (
                    <div
                        className={'absolute top-0 left-0 w-full flex justify-end items-start py-0 px-2 z-[2]'}
                        dir="ltr"
                    >
                        {cellThreadId && cellThreadStatus !== RESOLVED && (
                            <span className={'w-4 h-4'} onClick={handleOnCommentClick}>
                                <CommentNotifySVG width="14" height="14" />
                            </span>
                        )}
                        {cellData?._ticket > 0 && (
                            <span className={'w-3 h-3 pr-2'} onClick={viewTicketHandler}>
                                <LQAIconSVG width="14" height="14" />
                            </span>
                        )}
                    </div>
                )}
                {isShowAutoQA && ignoredAutoQAErrors.length > 0 && (
                    <Tooltip
                        title={
                            <Grid container direction="column" spacing={1}>
                                <p className="text-light font-medium">You ignored some errors:</p>
                                <ul className={'m-0 pl-4'}>
                                    {uniqueErrorCategories.map(category => (
                                        <li key={category}>
                                            <p className="text-light font-normal">{capitalize(category)}</p>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        }
                    >
                        <div
                            className={
                                'absolute bottom-0 left-0 w-0 h-0 inline-block border-r-[12px] border-b-[12px] border-r-transparent border-b-missingTag'
                            }
                            dir="ltr"
                        ></div>
                    </Tooltip>
                )}

                {!isShowAutoQA && isShowTag && warningTags && (
                    <Tooltip
                        title={
                            <Grid container direction="column" spacing={2}>
                                {tagsMissingFromSources?.length > 0 && (
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <p className="text-light font-medium">Missing Tag from source:</p>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1}>
                                            {tagsMissingFromSources?.map(tag => {
                                                return (
                                                    <Grid item key={tag}>
                                                        <p className="font-normal text-white body2">• {tag}</p>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                                {customTags?.length > 0 && (
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <p className="text-light font-medium">Tags not found in source:</p>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1}>
                                            {customTags?.map(tag => {
                                                return (
                                                    <Grid item key={tag}>
                                                        <p className="font-normal text-white body2">• {tag}</p>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        }
                    >
                        <div
                            className={
                                'absolute bottom-0 left-0 w-0 h-0 inline-block border-r-[12px] border-b-[12px] border-r-transparent border-b-missingTag'
                            }
                            dir="ltr"
                        ></div>
                    </Tooltip>
                )}

                {tmStatus && !isTmDisabled && (
                    <Tooltip
                        placement="right"
                        title={
                            <Grid
                                container
                                spacing={1}
                                alignItems="flex-start"
                                direction="column"
                                className={'!max-w-[270px] p-2'}
                            >
                                <Grid item>
                                    <Grid>
                                        <TMToolTipPreviewSVG />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <p className="text-light font-medium">TM Text</p>
                                </Grid>
                                <Grid item>
                                    <Trans i18nKey="tm_tooltip_helper_description" t={t}>
                                        <p className="caption">This text is auto-populated from the TM</p>
                                        <p className="caption">⮕ Edit cell or right click to hide this icon</p>
                                        <p className="caption">
                                            ⮕ Go to Home/Localization Settings to config the Translation Memory
                                        </p>
                                    </Trans>
                                </Grid>
                            </Grid>
                        }
                    >
                        <span
                            className={
                                'absolute bottom-0 right-0 text-[7px] w-[14px] h-[9px] font-bold uppercase text-white flex justify-center items-center cursor-pointer bg-[#7869B9]'
                            }
                        >
                            TM
                        </span>
                    </Tooltip>
                )}

                {isShowDependencyStatus && (
                    <div className={'absolute top-1 left-1'}>
                        <LangStatus
                            dependencyBlindness={dependencyBlindness}
                            dependencyStatus={dependencyStatus}
                            sourceStatus={isChildDependency && !isParentDependency ? null : sourceStatus}
                        />
                    </div>
                )}
            </Grid>
        </>
    );
}

CellRow.propTypes = {
    column: PropTypes.object,
    rowData: PropTypes.any
};

const CellRowMemo = React.memo(CellRow);

const CellWrapper = props => {
    const { columnWidth, rowHeight, rowId, isViewOnly } = props;
    const isExistRecordMetaDataRecordId = useIsExistRecordMetaDataRecordId({ recordId: rowId });
    if (!isExistRecordMetaDataRecordId && !isViewOnly)
        return (
            <div
                style={{
                    width: columnWidth,
                    height: rowHeight,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    className={`rounded bg-[#E6EAF1] animate-pulse`}
                    style={{
                        width: columnWidth / 2,
                        height: 7
                    }}
                />
            </div>
        );

    return <CellRowMemo {...props} />;
};

export default React.memo(CellWrapper);
