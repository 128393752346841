import { useCurrentUser, useIsFetchingCurrentUser } from 'hooks/auth';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useTranslation } from 'react-i18next';
import { getSelfHostingLicense } from 'app/actions';
import { isExternal } from 'config';
import { fetchMe } from 'auth/actions';

export default function AuthenticatedRoute({ children, roles = [], paymentConditions = [], ...rest }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUser = useCurrentUser();
    const permission = useRole();
    const { t } = useTranslation();
    const isFetchingCurrentUser = useIsFetchingCurrentUser();

    useEffect(() => {
        if (currentUser) return;
        dispatch(fetchMe({}));
    }, [dispatch, currentUser]);

    const hasPermission = roles?.length === 0 || roles?.some(role => permission?.[role] === roleConst.FULL);
    const hasPaymentPermission = paymentConditions?.length === 0 || paymentConditions.every(Boolean);

    React.useEffect(() => {
        if (!isExternal) return;
        dispatch(getSelfHostingLicense({}));
    }, [dispatch]);

    if (isFetchingCurrentUser) return <span>Loading...</span>;

    if (!currentUser) {
        return (
            <Redirect
                to={{
                    pathname: '/signin',
                    search: location.search,
                    state: { intendedRoute: location.pathname }
                }}
            />
        );
    }

    if (currentUser?.authorities?.includes('PRE_AUTH') && location?.pathname !== '/signin-user-2fa') {
        return (
            <Redirect
                to={{
                    pathname: '/signin-user-2fa',
                    search: location?.search,
                    state: { intendedRoute: location?.pathname }
                }}
            />
        );
    }

    if (!hasPermission) {
        dispatch(
            enqueueSnackbar({
                message: t(`global_have_no_permission_to_do`),
                type: 'info'
            })
        );
        return <Redirect to={{ search: location.search, pathname: '/' }} />;
    }

    if (!hasPaymentPermission) {
        dispatch(
            enqueueSnackbar({
                message: t(`global_have_no_payment_permission_to_do`),
                type: 'info'
            })
        );
        return <Redirect to={{ search: location.search, pathname: '/' }} />;
    }

    return children;
}
