import React from 'react';

function IconFullAccessSVG({ color = '#00D6A0', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill="#00D6A0"
                fillRule="evenodd"
                d="M7.978 1c.367 0 .647.28.647.646 0 .34-.242.606-.57.642l-.077.004-.174.003a5.68 5.68 0 0 0-5.512 5.683 5.68 5.68 0 0 0 5.686 5.687 5.68 5.68 0 0 0 5.687-5.687c0-.366.28-.646.646-.646.34 0 .605.242.642.57l.004.076-.003.196a6.977 6.977 0 0 1-6.976 6.783A6.977 6.977 0 0 1 1 7.978 6.977 6.977 0 0 1 7.978 1zm5.644 1.895a.64.64 0 1 1 .959.837l-.055.068-6.57 6.57a.565.565 0 0 1-.366.188l-.085.005h-.022a.605.605 0 0 1-.402-.163L4.425 7.526a.644.644 0 0 1 .043-.904.644.644 0 0 1 .842-.017l.062.06 2.154 2.347 6.096-6.117z"
            />
        </svg>
    );
}

export default React.memo(IconFullAccessSVG);
