import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { EXTRA_PREVIEW_STATUS, CONFLICT_TYPES } from 'const/gridUI';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 220,
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    dlFlex: {
        display: 'flex'
    },
    highlight: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    mb2: {
        marginBottom: theme.spacing(2)
    },
    color: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    colorItem: {
        marginRight: theme.spacing(2)
    }
}));

function ExtraFilterConflictOptions({ extraFilter, onChange, conflictType }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleOnChange = option => {
        onChange && onChange(option);
    };

    const PREVIEW_STATUS_OPTIONS = React.useMemo(
        () => [
            {
                name: 'All',
                value: EXTRA_PREVIEW_STATUS.ALL,
                isShow: true
            },
            {
                name: conflictType === CONFLICT_TYPES.IMPORT ? 'is updated' : 'Conflict',
                value: EXTRA_PREVIEW_STATUS.CONFLICT,
                isShow: true
            },
            {
                name: conflictType === CONFLICT_TYPES.IMPORT ? 'is unchanged' : 'Non-Conflicting',
                value: EXTRA_PREVIEW_STATUS.NONE_CONFLICT,
                isShow: true
            }
        ],
        [conflictType]
    );

    return (
        <Grid container className={classes.root} direction="column">
            <Grid item className={classes.mb2}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                    <Grid item>
                        <h4 className="prose prose-lg font-medium">{t('column_filter')}</h4>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <p className="caption">Preview status:</p>
                </Grid>
                <Grid item>
                    <RadioGroup
                        defaultValue={extraFilter?._previewStatus || EXTRA_PREVIEW_STATUS.ALL}
                        onChange={e => handleOnChange({ _previewStatus: e?.target?.value })}
                    >
                        <Grid container direction="column">
                            {PREVIEW_STATUS_OPTIONS.map(option => {
                                return (
                                    <Grid item key={option?.value}>
                                        <FormControlLabel
                                            value={option?.value}
                                            control={<Radio size="small" />}
                                            label={<p className="body2 !font-normal">{option?.name}</p>}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ExtraFilterConflictOptions);
