import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';

function useMediaQueries() {
    const isMobile = useMediaQuery(
        json2mq({
            minWidth: 0,
            maxWidth: 600
        })
    );
    const isTablet = useMediaQuery(
        json2mq({
            minWidth: 600,
            maxWidth: 960
        })
    );

    const isWindowLaptop = useMediaQuery(
        json2mq({
            minWidth: 961,
            maxWidth: 1280
        })
    );
    const isMacOSLaptop = useMediaQuery(
        json2mq({
            minWidth: 1281,
            maxWidth: 1440
        })
    );

    const isLaptop = useMediaQuery(
        json2mq({
            minWidth: 960,
            maxWidth: 1440
        })
    );
    const isDesktop = useMediaQuery('(min-width:1441px)');
    const isLargeDesktop = useMediaQuery(theme => theme.breakpoints.up('xl'));

    const isMediumHeight = useMediaQuery('(max-height:800px)');

    return {
        isWindowLaptop,
        isMacOSLaptop,
        isMobile,
        isTablet,
        isLaptop,
        isDesktop,
        isLargeDesktop,
        isMediumHeight
    };
}

export default useMediaQueries;
