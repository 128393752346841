import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import CloseIcon from 'assets/images/svg/CloseIconSVG';
import { HEADER_HEIGHT } from 'const';
import { useDispatch } from 'react-redux';
import LDSelectBase from 'components/selects/LDBase';
import Button from 'components/button/Base';
import NoContent from './NoContent';
import AvatarBase from 'components/avatar/Base';
import GroupList from './groupList';
import * as shareViewActions from 'gridUI/actions/shareView';
import { useHistory } from 'react-router-dom';
import {
    useNotSharedGroups,
    useShareViewGroupMembers,
    useIsFetchingInShareView,
    useSelectedGroupIdInShareView,
    useShareViewGroups,
    useCompositeViewId,
    useShareLinkDetail
} from 'hooks/gridUI';
import Search from 'components/search';
import GroupTag from './groupTag';
import MemberList from './memberList';
import Spinner from 'components/spinner/Base';
import NoMember from './NoMember';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import * as groupActions from 'permission/actions/group';
import CreateGroup from 'permission/groups/createGroup';
import { useSelectedGroupDetail, useSelectedGroupAccessIds } from 'hooks/permission/group';
import { useCurrentView } from 'hooks/gridUI';
// import { getViewBackgroundByType, getViewIconByType } from 'utils/gridUI/formatData';
import hexToRgba from 'hex-to-rgba';
import { useCanViewGroupShare } from 'hooks/payment';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import ButtonBase from 'components/button/Base';
import { GROUP_ADMIN_TYPES } from 'const';
import { sendManualTrack } from 'tracker';
import * as roleConst from 'auth/roleConst';
import { useRole as useUserAuthorities } from 'hooks/auth/role';
import Dialog from 'components/dialog/Dialog';
import SettingGroupSVG from 'assets/images/svg/SettingGroupSVG';
import ShareSVG from 'assets/images/svg/ShareSVG';
import ShareLinkView from './ShareLinkView';
import { useTranslation } from 'react-i18next';
import NoShareViewGroupSVG from 'assets/images/svg/NoShareViewGroupSVG';
import classNames from 'classnames';
import ViewIcon from 'gridUI/views/ViewIcon';
import SidebarRightLayout from '../layout/rightSidebar';

const HEADER_HISTORY_HEIGHT = 72;

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    header: {
        paddingLeft: 24,
        paddingRight: 24,
        height: HEADER_HISTORY_HEIGHT,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    tabName: {
        marginRight: theme.spacing(3)
    },
    accessViewIcon: {
        marginRight: theme.spacing(2)
    },
    accessViewName: {
        whiteSpace: 'nowrap'
    },
    closeButton: {
        cursor: 'pointer',
        display: 'flex',
        marginLeft: 'auto'
    },
    contentWrapper: {
        paddingLeft: 24,
        paddingRight: 24
    },
    tabWrapper: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 24
    },
    select: {
        padding: 24,
        backgroundColor: theme.colors.background
    },
    item: {
        paddingTop: 24
    },
    test: {
        backgroundColor: theme.colors.white
    },
    section: {
        paddingTop: 24
    },
    groupList: {
        minHeight: 66,
        maxHeight: 'calc((66*2)px)',
        overflow: 'auto'
    },
    memberList: {
        height: `calc(100vh - ${HEADER_HISTORY_HEIGHT + HEADER_HEIGHT + 370 + 24 + 36}px)`,
        overflow: 'auto'
    },
    spinner: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    accessView: {
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.8),
        border: `1px solid ${hexToRgba(theme.colors.fuchsiaBlue, 1)}`,
        borderRadius: '50%',
        '& svg': {
            width: 10,
            color: theme.colors.white
        }
    },
    inlineBlock: {
        display: 'inline-block'
    },
    currentViewTag: {
        padding: '4px 8px',
        background: theme.colors.paleGrey,
        borderRadius: 4
    },
    tabContainer: {
        background: theme.colors.divider,
        padding: 1,
        borderRadius: 4,
        overflow: 'hidden'
    },
    tabItem: {
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        color: theme.colors.dimGrey,
        whiteSpace: 'nowrap',
        flex: 1,
        background: theme.colors.white,

        '&:first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
        },
        '&:last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4
        }
    },
    tabActive: {
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        '& svg': {
            fill: theme.colors.white
        }
    },
    dpFlex: {
        display: 'flex'
    },
    tabNormalText: {
        color: theme.colors.lightLavender
    },
    tabActiveText: {
        color: theme.colors.white
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        borderTop: `1px solid ${theme.colors.divider}`,
        height: 54
    },
    limit: {
        marginTop: theme.spacing(6)
    },
    upgradeIcon: {
        marginRight: theme.spacing(1)
    }
}));

const SHARE_VIEW_STATES = {
    SHARE_TO_GROUP: 'SHARE_TO_GROUP',
    PUBLISH_VIEW: 'PUBLISH_VIEW'
};

function GridShareView({ handleClose, workspaceId, gridId, viewId, dbId, branchId }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [searchMemberValue, setSearchMemberValue] = React.useState('');
    const [shareViewGroup, setShareViewGroup] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openEditAccessModal, setOpenEditAccessModal] = React.useState(false);
    const isFetching = useIsFetchingInShareView();
    const selectedGroupId = useSelectedGroupIdInShareView();
    const [notSharedGroups, isFetchingGroups] = useNotSharedGroups();
    const members = useShareViewGroupMembers() || [];
    const selectedGroupDetail = useSelectedGroupDetail();
    const checkedListDefault = useSelectedGroupAccessIds();
    const currentView = useCurrentView();
    const canViewGroupShare = useCanViewGroupShare();
    const authorities = useUserAuthorities();
    const compositeViewId = useCompositeViewId();
    const shareLinkDetail = useShareLinkDetail(compositeViewId);
    const viewGroups = useShareViewGroups(viewId);

    const [currentState, setCurrentState] = React.useState(SHARE_VIEW_STATES?.SHARE_TO_GROUP);

    const handleUpgrade = React.useCallback(() => {
        history.push(`/company-settings/billing/overview`);
    }, [history]);

    const groupOptions = React.useMemo(() => {
        return notSharedGroups?.map(group => {
            const isAdminGroup = group?.type === GROUP_ADMIN_TYPES.ADMIN;
            const adminAvatar = isAdminGroup ? <AdminGroupAvatar size={24} /> : <TranslatorGroupAvatar size={24} />;
            return {
                value: group.id,
                label: group.name,
                icon: () =>
                    group.systemGroup ? adminAvatar : <AvatarBase size={24}>{group.name.slice(0, 2)}</AvatarBase>,
                systemGroup: group?.systemGroup
            };
        });
    }, [notSharedGroups]);

    React.useEffect(() => {
        dispatch(groupActions.fetchCompanyGroups({ successCallback: () => {}, errorCallback: () => {} }));
    }, [dispatch, viewId]);

    React.useEffect(() => {
        dispatch(shareViewActions.fetchGroupsByView(viewId));
    }, [dispatch, viewId]);

    function handleSelectedGroupChange(group) {
        setShareViewGroup(group);
    }

    const filteredMembers = React.useMemo(() => {
        return members?.filter(member =>
            member?.user?.fullName?.toLowerCase().includes(searchMemberValue.trim().toLowerCase())
        );
    }, [members, searchMemberValue]);

    const handleGroupSelect = React.useCallback(
        ({ groupId }) => {
            setSearchMemberValue('');
            if (selectedGroupId === groupId) {
                dispatch(shareViewActions.changeSelectedGroupId({ groupId: null }));
            } else {
                dispatch(shareViewActions.changeSelectedGroupId({ groupId }));
            }
        },
        [dispatch, selectedGroupId]
    );

    const handleGroupTagClose = React.useCallback(() => {
        dispatch(shareViewActions.changeSelectedGroupId({ groupId: null }));
    }, [dispatch]);

    const handleShareViewToGroup = React.useCallback(() => {
        if (!shareViewGroup) return;
        sendManualTrack({
            type: `Share View`,
            customData: {
                group: {
                    id: shareViewGroup?.value,
                    name: shareViewGroup?.label,
                    systemGroup: shareViewGroup?.systemGroup
                },
                workspaceId,
                viewId
            }
        });
        setIsLoading(true);
        dispatch(
            shareViewActions.grantViewToGroup({
                group: {
                    id: shareViewGroup?.value,
                    name: shareViewGroup?.label,
                    systemGroup: shareViewGroup?.systemGroup
                },
                workspaceId,
                viewId,
                successCallback: () => {
                    setIsLoading(false);
                },
                errorCallback: () => {
                    setIsLoading(false);
                }
            })
        );
        setShareViewGroup(null);
    }, [dispatch, shareViewGroup, viewId, workspaceId]);

    const handleOpenAddGroupModal = React.useCallback(() => {
        setOpenEditAccessModal(true);
    }, []);

    const handleCloseAddGroupModal = React.useCallback(() => {
        setOpenEditAccessModal(false);
    }, []);

    const handleBack = React.useCallback(() => {
        dispatch(shareViewActions.fetchGroupsByView());
        setOpenEditAccessModal(false);
    }, [dispatch]);

    const handleRemoveGroup = React.useCallback(
        ({ group }) => {
            sendManualTrack({ type: `Remove Group From View` });
            if (selectedGroupId === group.id) {
                dispatch(shareViewActions.changeSelectedGroupId({ groupId: null }));
            }
            setIsLoading(true);
            dispatch(
                shareViewActions.removeViewOutOfGroup({
                    group,
                    workspaceId,
                    viewId,
                    successCallback: () => {
                        setIsLoading(false);
                        console.log('removeViewOutOfGroupSuccess');
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                        console.log('removeViewOutOfGroupFailed');
                    }
                })
            );
        },
        [dispatch, selectedGroupId, viewId, workspaceId]
    );

    const handleRoleChange = React.useCallback(
        ({ member, role }) => {
            sendManualTrack({ type: `Edit Workspace Role From Workspace` });
            dispatch(
                shareViewActions.changeShareViewUserWorkspaceRole({
                    userId: member?.user?.id,
                    workspaceId,
                    newRole: role,
                    oldRole: member.role,
                    successCallback: () => {
                        console.log('success handleRoleChange');
                    },
                    errorCallback: () => {
                        console.log('handleRoleChange failed');
                    }
                })
            );
        },
        [dispatch, workspaceId]
    );

    const selectedGroup = React.useMemo(() => {
        return viewGroups?.find(group => group.id === selectedGroupId);
    }, [viewGroups, selectedGroupId]);

    const memberLength = React.useMemo(() => {
        return members?.length;
    }, [members]);

    React.useEffect(() => {
        if (compositeViewId) {
            dispatch(
                shareViewActions.getShareLinkView({
                    compositeViewId,
                    successCallback: () => {
                        console.log('getShareLink success');
                    }
                })
            );
        }
    }, [compositeViewId, dispatch]);

    const renderContent = React.useCallback(() => {
        switch (currentState) {
            case SHARE_VIEW_STATES.SHARE_TO_GROUP:
                return (
                    <Grid container direction="column" wrap="nowrap" className={classes.contentWrapper}>
                        {canViewGroupShare ? (
                            <>
                                <Grid item className={classes.item}>
                                    <Grid container direction="column" spacing={2} className={classes.select}>
                                        <Grid item>
                                            <p className="body1">{t(`share_view_select_group_to_share_the_view`)}</p>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                <Grid item style={{ flex: 1 }}>
                                                    <LDSelectBase
                                                        dropdownClassName={classes.test}
                                                        ddPlaceholder={'Select a group'}
                                                        menuPlaceholder={t('global_label_find_a_group')}
                                                        options={groupOptions || []}
                                                        onChange={handleSelectedGroupChange}
                                                        defaultValue={shareViewGroup}
                                                        isCreatable={
                                                            authorities?.[
                                                                roleConst.COMPANY_AUTHORITIES.MANAGE_GROUP
                                                            ] === roleConst.FULL
                                                        }
                                                        createText="Add New Group"
                                                        onCreateNew={handleOpenAddGroupModal}
                                                        isLoading={isFetchingGroups}
                                                        // createMoreIcon={!canDataAccessControl && <UpgradeSVG />}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        width={90}
                                                        type="submit"
                                                        variant="contained"
                                                        onClick={handleShareViewToGroup}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? <CircularProgress size={24} /> : t('global_share')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {Boolean(viewGroups?.length) && (
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item className={classes.section}>
                                                <Grid container direction="column" spacing={3}>
                                                    <Grid item>
                                                        <p className="body1">Groups ({viewGroups?.length})</p>
                                                    </Grid>
                                                    <Grid item className={classes.groupList}>
                                                        <GroupList
                                                            groups={viewGroups}
                                                            selectedGroupId={selectedGroupId}
                                                            onGroupSelect={handleGroupSelect}
                                                            onRemoveGroup={handleRemoveGroup}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={classes.section}>
                                                <Grid container wrap="nowrap" direction="column" spacing={3}>
                                                    <Grid item>
                                                        {!selectedGroupId ? (
                                                            <p className="body1">All members ({memberLength})</p>
                                                        ) : (
                                                            <GroupTag
                                                                members={members}
                                                                selectedGroup={selectedGroup}
                                                                onClose={handleGroupTagClose}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <Search
                                                            value={searchMemberValue}
                                                            onChange={e => setSearchMemberValue(e.target.value)}
                                                            placeholder={t('global_find_a_member')}
                                                            background={theme.colors.paleGrey}
                                                            width="100%"
                                                        />
                                                    </Grid>
                                                    <Grid item className={classes.memberList}>
                                                        {memberLength !== 0 && (
                                                            <MemberList
                                                                members={filteredMembers}
                                                                onRemoveGroup={handleRemoveGroup}
                                                                onRoleChange={handleRoleChange}
                                                            />
                                                        )}

                                                        {!!selectedGroupId && !memberLength && <NoMember />}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {!viewGroups?.length && !isFetching && (
                                    <Grid item className={classes.item}>
                                        <NoContent />
                                    </Grid>
                                )}
                                {isFetching && (
                                    <Grid item style={{ flex: 1 }}>
                                        <div className={classes.spinner}>
                                            <Spinner size={30} thick={4} />
                                        </div>
                                    </Grid>
                                )}
                                <Dialog maxWidth={false} open={openEditAccessModal} onClose={handleCloseAddGroupModal}>
                                    <CreateGroup
                                        defaultGroupName={selectedGroupDetail?.name}
                                        checkedListDefaultIds={checkedListDefault?.map(dbAccess => dbAccess.id)}
                                        checkedListDefault={checkedListDefault}
                                        onClose={handleCloseAddGroupModal}
                                        isEdit={false}
                                        onBack={handleBack}
                                    />
                                </Dialog>
                            </>
                        ) : (
                            <>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    spacing={3}
                                    className={classes.limit}
                                >
                                    <Grid item>
                                        <NoShareViewGroupSVG />
                                    </Grid>
                                    <Grid item>
                                        <h3 className="prose prose-2xl text-center font-medium">
                                            {t('share_view_with_group')}
                                        </h3>
                                    </Grid>
                                    <Grid item>
                                        <p className="body2 text-center">{t('want_to_share_this_view')}</p>
                                    </Grid>
                                    <Grid item>
                                        <ButtonBase width={120} onClick={handleUpgrade} variant="contained">
                                            <UpgradeSVG className={classes.upgradeIcon} />
                                            {t('global_upgrade')}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                );
            case SHARE_VIEW_STATES.PUBLISH_VIEW:
                return <ShareLinkView shareLinkDetail={shareLinkDetail} compositeViewId={compositeViewId} />;
            default:
                return null;
        }
    }, [
        canViewGroupShare,
        t,
        compositeViewId,
        shareLinkDetail,
        currentState,
        authorities,
        classes,
        checkedListDefault,
        filteredMembers,
        groupOptions,
        handleBack,
        handleCloseAddGroupModal,
        handleGroupSelect,
        handleGroupTagClose,
        handleOpenAddGroupModal,
        handleRoleChange,
        handleRemoveGroup,
        handleShareViewToGroup,
        handleUpgrade,
        isFetching,
        isFetchingGroups,
        memberLength,
        members,
        openEditAccessModal,
        searchMemberValue,
        selectedGroup,
        selectedGroupDetail,
        selectedGroupId,
        shareViewGroup,
        theme,
        viewGroups,
        isLoading
    ]);

    const subTitle = (
        <div className={'flex items-center w-fit py-1 px-2 bg-grey-pale rounded gap-2'}>
            <ViewIcon view={currentView} size="small" />
            <p className="body2 inline whitespace-nowrap">{currentView && currentView?.name}</p>
        </div>
    );

    return (
        <SidebarRightLayout id="share_view" title={t(`share_view`)} subTitle={subTitle} onClose={handleClose}>
            <Grid container direction="column" wrap="nowrap">
                <Grid item className={classes.tabWrapper}>
                    <Grid container className={classes.tabContainer}>
                        <Grid
                            item
                            className={`${classes.tabItem} ${
                                currentState === SHARE_VIEW_STATES.SHARE_TO_GROUP ? classes.tabActive : ''
                            }`}
                            onClick={() => setCurrentState(SHARE_VIEW_STATES.SHARE_TO_GROUP)}
                        >
                            <Grid container justify="center" direction="row" alignItems="center" spacing={1}>
                                <Grid item className={classes.dpFlex}>
                                    <SettingGroupSVG
                                        color={
                                            currentState === SHARE_VIEW_STATES.SHARE_TO_GROUP
                                                ? theme.colors.white
                                                : theme.colors.lightLavender
                                        }
                                        width={16}
                                        height={16}
                                    />
                                </Grid>
                                <Grid item>
                                    <p
                                        className={classNames(`body2`, {
                                            'text-white': currentState === SHARE_VIEW_STATES.SHARE_TO_GROUP,
                                            'text-lavender-light': currentState !== SHARE_VIEW_STATES.SHARE_TO_GROUP
                                        })}
                                    >
                                        Share to Group
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            className={`${classes.tabItem} ${
                                currentState === SHARE_VIEW_STATES.PUBLISH_VIEW ? classes.tabActive : ''
                            }`}
                            onClick={() => setCurrentState(SHARE_VIEW_STATES.PUBLISH_VIEW)}
                        >
                            <Grid container justify="center" direction="row" alignItems="center" spacing={1}>
                                <Grid item className={classes.dpFlex}>
                                    <ShareSVG
                                        color={
                                            currentState === SHARE_VIEW_STATES.PUBLISH_VIEW
                                                ? theme.colors.white
                                                : theme.colors.lightLavender
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <p
                                        className={classNames(`body2`, {
                                            'text-white': currentState === SHARE_VIEW_STATES.PUBLISH_VIEW,
                                            'text-lavender-light': currentState !== SHARE_VIEW_STATES.PUBLISH_VIEW
                                        })}
                                    >
                                        {t('publish_view')}
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>{renderContent()}</Grid>
            </Grid>
        </SidebarRightLayout>
        // <Grid container direction="column" wrap="nowrap" className={classes.root}>
        //     <Grid item container className={classes.header} alignItems="center" wrap="nowrap">
        //         <Grid item container direction="column" className={classes.tabName} spacing={2}>
        //             <Grid item>
        //                 <h4 className="prose prose-lg font-medium">{t(`share_view`)}</h4>
        //             </Grid>
        //             <Grid item>
        //                 <div className={classes.inlineBlock}>
        //                     <Grid container alignItems="center" className={classes.currentViewTag}>
        //                         <Grid item className={`${classes.accessViewIcon} ${classes.inlineBlock}`}>
        //                             <span
        //                                 className={classes.accessView}
        //                                 style={{
        //                                     background: hexToRgba(
        //                                         getViewBackgroundByType({ view: currentView, theme }),
        //                                         0.8
        //                                     ),
        //                                     border: `1px solid ${hexToRgba(
        //                                         getViewBackgroundByType({ view: currentView, theme }),
        //                                         1
        //                                     )}`
        //                                 }}
        //                             >
        //                                 {getViewIconByType(currentView, 'white')}
        //                             </span>
        //                         </Grid>
        //                         <Grid item>
        //                             <p className="body2 whitespace-nowrap inline-block">
        //                                 {currentView && currentView.name}
        //                             </p>
        //                         </Grid>
        //                     </Grid>
        //                 </div>
        //             </Grid>
        //         </Grid>
        //         <Grid item className={classes.closeButton}>
        //             <IconButton size="small" onClick={handleClose}>
        //                 <CloseIcon size="medium" />
        //             </IconButton>
        //         </Grid>
        //     </Grid>

        //     <Grid item style={{ flex: 1 }}>
        //     <Grid container direction="column" wrap="nowrap">
        //             <Grid item className={classes.tabWrapper}>
        //                 <Grid container className={classes.tabContainer}>
        //                     <Grid
        //                         item
        //                         className={`${classes.tabItem} ${
        //                             currentState === SHARE_VIEW_STATES.SHARE_TO_GROUP ? classes.tabActive : ''
        //                         }`}
        //                         onClick={() => setCurrentState(SHARE_VIEW_STATES.SHARE_TO_GROUP)}
        //                     >
        //                         <Grid container justify="center" direction="row" alignItems="center" spacing={1}>
        //                             <Grid item className={classes.dpFlex}>
        //                                 <SettingGroupSVG
        //                                     color={
        //                                         currentState === SHARE_VIEW_STATES.SHARE_TO_GROUP
        //                                             ? theme.colors.white
        //                                             : theme.colors.lightLavender
        //                                     }
        //                                     width={16}
        //                                     height={16}
        //                                 />
        //                             </Grid>
        //                             <Grid item>
        //                                 <p
        //                                     className={classNames(`body2`, {
        //                                         'text-white': currentState === SHARE_VIEW_STATES.SHARE_TO_GROUP,
        //                                         'text-lavender-light': currentState !== SHARE_VIEW_STATES.SHARE_TO_GROUP
        //                                     })}
        //                                 >
        //                                     Share to Group
        //                                 </p>
        //                             </Grid>
        //                         </Grid>
        //                     </Grid>

        //                     <Grid
        //                         item
        //                         className={`${classes.tabItem} ${
        //                             currentState === SHARE_VIEW_STATES.PUBLISH_VIEW ? classes.tabActive : ''
        //                         }`}
        //                         onClick={() => setCurrentState(SHARE_VIEW_STATES.PUBLISH_VIEW)}
        //                     >
        //                         <Grid container justify="center" direction="row" alignItems="center" spacing={1}>
        //                             <Grid item className={classes.dpFlex}>
        //                                 <ShareSVG
        //                                     color={
        //                                         currentState === SHARE_VIEW_STATES.PUBLISH_VIEW
        //                                             ? theme.colors.white
        //                                             : theme.colors.lightLavender
        //                                     }
        //                                 />
        //                             </Grid>
        //                             <Grid item>
        //                                 <p
        //                                     className={classNames(`body2`, {
        //                                         'text-white': currentState === SHARE_VIEW_STATES.PUBLISH_VIEW,
        //                                         'text-lavender-light': currentState !== SHARE_VIEW_STATES.PUBLISH_VIEW
        //                                     })}
        //                                 >
        //                                     {t('publish_view')}
        //                                 </p>
        //                             </Grid>
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //             </Grid>
        //             <Grid item>{renderContent()}</Grid>
        //         </Grid>
        //     </Grid>
        // </Grid>
    );
}

export default React.memo(GridShareView);
