import React, { useRef, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CloseIcon from 'assets/images/svg/CloseIconSVG';

import { SELECTION_COLORS, SELECTION_COLOR_OPTIONS } from 'utils/color';
import TriggerMenu from 'components/menus/TriggerMenu';
import Palette from 'layout/Palette';
import hexToRgba from 'hex-to-rgba';
import DraggingSVG from 'assets/images/svg/DraggingSVG';
import { OPTION_PALETTE } from 'const/paletteTypes';
import { isKbEnter } from 'utils/keyboard';
import { SELECTION_OPACITY } from 'const/gridUI';

function UpdateOptionItem({
    handleUpdateOption,
    handleRemoveOption,
    containerRef,
    index,
    option,
    color,
    isFocusLastOption,
    setIsFocusLastOption,
    isLast,
    handleAddOption,
    t,
    ...rest
}) {
    const inputRef = useRef();

    useEffect(() => {
        if (isFocusLastOption && isLast) {
            inputRef.current && inputRef.current.focus();
        }
    }, [isFocusLastOption, isLast]);

    const handleOnKeyDown = React.useCallback(
        e => {
            if (!isKbEnter(e)) return;
            handleAddOption({ colorState: color });
        },
        [color, handleAddOption]
    );

    const handleChange = React.useCallback(
        changedObj => {
            handleUpdateOption(
                Object.assign(
                    {
                        optionId: option.id,
                        label: option.label,
                        color: color
                    },
                    changedObj
                )
            );
        },
        [color, handleUpdateOption, option]
    );

    const handleColorChange = React.useCallback(
        color => {
            handleChange({ color: color.id });
        },
        [handleChange]
    );

    const colorSelectionRenderer = () => {
        return (
            <Palette
                className={'max-w-[260px] p-2.5'}
                value={color}
                options={SELECTION_COLOR_OPTIONS}
                onSelect={handleColorChange}
                type={OPTION_PALETTE}
            />
        );
    };

    return (
        <Grid className={'min-h-[30px]'} container justify="space-between" alignItems="center" {...rest}>
            <Grid container direction="row" alignItems="center">
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <DraggingSVG />
                </Grid>
                <TriggerMenu
                    className="color-menu"
                    id={`color-selection-${index}`}
                    contentRenderer={colorSelectionRenderer}
                    container={containerRef.current}
                >
                    <Grid
                        item
                        className={'w-5 h-5 rounded-full'}
                        style={{
                            background: hexToRgba(SELECTION_COLORS?.[color], SELECTION_OPACITY.BACKGROUND),
                            border: `1px solid ${hexToRgba(SELECTION_COLORS?.[color], SELECTION_OPACITY.BORDER)}`
                        }}
                    ></Grid>
                </TriggerMenu>

                <Grid
                    item
                    style={{
                        marginLeft: 4,
                        flex: 1
                    }}
                >
                    <input
                        ref={inputRef}
                        className={
                            'border-none w-full text-sm text-text-primary rounded hover:bg-hover focus:outline-none'
                        }
                        value={option?.label}
                        placeholder={t('global_empty')}
                        onChange={e => {
                            handleChange({ label: e.target.value });
                        }}
                        onKeyDown={handleOnKeyDown}
                        onBlur={() => setIsFocusLastOption(false)}
                    />
                </Grid>
                <Grid item className={'h-4.5 cursor-pointer'}>
                    <CloseIcon className="h-[18px]" onClick={() => handleRemoveOption(index, option)} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(UpdateOptionItem);
