import { useSelector } from 'react-redux';
import { useCurrentViewId } from './view';

export function useTranslationDashboard() {
    return useSelector(state => state.gridUI?.translationDashboard);
}

export function useTranslationDashboardDataByView(payload) {
    const viewId = payload?.viewId;
    const currentViewId = useCurrentViewId();
    const viewIdCombined = viewId || currentViewId;
    const translationDashboard = useTranslationDashboard();
    return translationDashboard?.[viewIdCombined]?.data;
}

export function useTranslationDashboardIsFetchingByView(payload) {
    const viewId = payload?.viewId;
    const currentViewId = useCurrentViewId();
    const viewIdCombined = viewId || currentViewId;
    const translationDashboard = useTranslationDashboard();
    return translationDashboard?.[viewIdCombined]?.isFetching;
}
