import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CheckedSVG from 'assets/images/svg/CheckedSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import hexToRgba from 'hex-to-rgba';
import { AutoSizer } from 'react-virtualized-dn';
import { INPUT_PADDING_LEFT, SPACING_LIST_NAME_WITH_ICON, DISABLED_OPACITY } from 'const/style';
import ToolTipClickContent from 'components/tooltip/ToolTipClickContent';

const useStyles = makeStyles(theme => ({
    root: {
        height: 32,
        cursor: 'pointer',
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT,
        borderRadius: 4,
        background: props => (props.isSelected ? hexToRgba(theme.colors.fuchsiaBlue, 0.1) : theme.colors.white),
        border: props =>
            `1px solid ${props.isSelected ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : theme.colors.divider}`
    },
    expand: {
        flex: 1
    },
    displayFlex: {
        display: 'flex'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

function ListSelect({ icon, moreIcon, name, isSelected, disabled, disabledTitle, onClose, ...rest }) {
    const classes = useStyles({ isSelected });

    return (
        <ToolTipClickContent title={disabled ? disabledTitle : undefined}>
            <div>
                <Grid
                    {...rest}
                    container
                    wrap="nowrap"
                    justify="space-between"
                    alignItems="center"
                    className={`${classes.root} ${disabled ? classes.disabled : ''}`}
                >
                    <Grid item className={classes.expand}>
                        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                            {icon && (
                                <Grid item className={classes.displayFlex}>
                                    {icon()}
                                </Grid>
                            )}
                            <Grid
                                item
                                style={{
                                    width: `calc(100% - ${16 + SPACING_LIST_NAME_WITH_ICON}px)`,
                                    height: 28
                                }}
                            >
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <div
                                            className="prose prose-sm whitespace-nowrap text-ellipsis overflow-hidden"
                                            style={{
                                                width,
                                                height
                                            }}
                                        >
                                            {name}
                                        </div>
                                    )}
                                </AutoSizer>
                            </Grid>
                        </Grid>
                    </Grid>
                    {isSelected && (
                        <Grid item className={classes.displayFlex}>
                            <CheckedSVG />
                        </Grid>
                    )}
                    {moreIcon && (
                        <Grid item className={classes.displayFlex}>
                            {typeof moreIcon === 'function' ? moreIcon() : moreIcon}
                        </Grid>
                    )}
                    {onClose && (
                        <Grid item onClick={onClose} className={classes.displayFlex}>
                            <CloseIconSVG />
                        </Grid>
                    )}
                </Grid>
            </div>
        </ToolTipClickContent>
    );
}

export default React.memo(ListSelect);
