import React, { useRef } from 'react';
import { Button as ButtonBase } from '@material-ui/core';
import { getFileExt } from 'utils/fileUtils';

export const INPUT_FILE_ERROR_CODE = {
    EXCEED_FILE_SIZE: 1,
    UNSUPPORTED_FILE_TYPE: 2
};

function InputFile({ className, maxSize = 0, accept, onAccepted, onRejected, children }) {
    const fileInputRef = useRef();
    const handleImportClick = () => {
        fileInputRef.current.click();
    };

    const handleInputChange = e => {
        const files = e.target?.files;
        const file = files[0];
        const fileExt = getFileExt(file?.name);
        const acceptedExt =
            typeof accept === 'string' ? accept?.split(',')?.map(el => el.trim()) : Array.isArray(accept) ? accept : [];

        if (files[0] && files[0].size > maxSize) {
            onRejected(INPUT_FILE_ERROR_CODE.EXCEED_FILE_SIZE);
        } else if (!acceptedExt.includes(fileExt.toLowerCase())) {
            onRejected(INPUT_FILE_ERROR_CODE.UNSUPPORTED_FILE_TYPE);
        } else {
            onAccepted([...files]);
        }

        fileInputRef.current.value = null;
    };

    return (
        <ButtonBase fullWidth className={className} onClick={handleImportClick}>
            {children}
            <input
                type="file"
                id="import-upload-file"
                name="import-upload-file"
                accept={accept}
                multiple
                style={{ display: 'none' }}
                onChange={handleInputChange}
                ref={fileInputRef}
            />
        </ButtonBase>
    );
}

export default React.memo(InputFile);
