import React from 'react';
import Skeleton from 'components/skeleton/Base';
import { Grid } from '@material-ui/core';

function GroupListSkeleton() {
    return (
        <Grid container spacing={1}>
            {Array.from(Array(5).keys()).map(index => (
                <Grid item key={index} style={{ width: '100%' }}>
                    <Skeleton width="100%" height={42} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(GroupListSkeleton);
