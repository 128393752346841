import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import hexToRgba from 'hex-to-rgba';
import PlaySVG from 'assets/images/svg/PlaySVG';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    text: {
        fontSize: 12
    },
    top: {
        color: props => props.color || theme.colors.dodgerBlue,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0
    },
    bottom: {
        color: props => hexToRgba(props.color || theme.colors.white)
    },

    circle: {
        strokeLinecap: 'round'
    }
}));

function CircularProgressWithIcon({ color, icon = <PlaySVG />, thickness = 4, size = 20, ...rest }) {
    const classes = useStyles({ color });
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={size}
                thickness={thickness}
                {...rest}
                value={100}
            />
            <CircularProgress
                size={size}
                className={classes.top}
                classes={{
                    circle: classes.circle
                }}
                thickness={thickness}
                variant="static"
                {...rest}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {typeof icon === 'function' ? icon() : icon}
            </Box>
        </Box>
    );
}

export default React.memo(CircularProgressWithIcon);
