import { SET_API_KEY } from '../types';
import { getApiKeyApi, regenerateApiKeyApi } from 'services/auth';
import { enqueueSnackbar } from 'notifier/actions';

export function fetchApiKey({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const { value } = await getApiKeyApi({ companyId });
            dispatch(_setApiKey({ apiKey: value }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function regenerateApiKey({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const { value: currentApiKey } = await getApiKeyApi({ companyId });
            const { value: newApiKey } = await regenerateApiKeyApi({ companyId, currentApiKey });
            dispatch(_setApiKey({ apiKey: newApiKey }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _setApiKey({ apiKey }) {
    return {
        type: SET_API_KEY,
        payload: {
            apiKey
        }
    };
}
