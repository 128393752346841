import React, { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AutomationFilterGroup from './AutomationFilterGroup';
import TooltipActionDisable from '../TooltipActionDisable';
import { ACTION_STATUS } from 'gridUI/automations/const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    mb12: {
        marginBottom: 12
    }
}));

const ActionCondition = ({ selectedNode, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    return (
        <>
            <Grid item className={classes.mb12}>
                <p className="body1">{t('automation_condition')}</p>
            </Grid>
            <TooltipActionDisable isAction disabled={isDisabled}>
                <Grid item>
                    <AutomationFilterGroup selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
                </Grid>
            </TooltipActionDisable>
        </>
    );
};

export default React.memo(ActionCondition);
