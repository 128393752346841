export function isForYou({ state, headers }) {
    const socketId = state?.app?.socketId;
    const email = state.auth.currentUser?.email;
    const serverSocketId = headers?.['X-Socket-Id'];
    return socketId === serverSocketId || email === serverSocketId;
}

export function isForOthers({ state, headers }) {
    const socketId = state?.app?.socketId;
    const email = state.auth.currentUser?.email;
    const serverSocketId = headers?.['X-Socket-Id'];
    return socketId !== serverSocketId && email !== serverSocketId;
}

export function isForAll({ state, headers }) {
    // const socketId = state?.app?.socketId;
    // const email = state.auth.currentUser?.email;
    // const serverSocketId = headers?.['X-Socket-Id'];
    // return !!serverSocketId;
    return true;
}
