import * as types from '../types';

const handler = {
    [types.PINNED_SEARCH](state, action) {
        return {
            ...state,
            pinnedSearch: true
        };
    },
    [types.UNPINNED_SEARCH](state, action) {
        return {
            ...state,
            pinnedSearch: false
        };
    },
    [types.SEARCHING_RECORD](state, { payload }) {
        const { isSearchingRecord } = payload;
        return {
            ...state,
            isSearchingRecord
        };
    },
    [types.OPEN_REPLACE_SEARCH](state, action) {
        return {
            ...state,
            isOpenReplaceSearch: true
        };
    },
    [types.CLOSE_REPLACE_SEARCH](state, action) {
        return {
            ...state,
            isOpenReplaceSearch: false
        };
    },
    [types.SET_REPLACING_TEXT](state, { payload }) {
        return {
            ...state,
            replacingText: payload
        };
    },
    [types.SET_SEARCH_RECORDS](state, { payload }) {
        return {
            ...state,
            searchRecords: payload
        };
    },
    [types.SET_SUCCESS_REPLACE_ALL](state, { payload }) {
        return {
            ...state,
            successReplaceAll: payload
        };
    },
    [types.SET_REPLACING_TYPE](state, { payload }) {
        return {
            ...state,
            replacingType: payload
        };
    },
    [types.SET_CLEAR_SEARCH_STATE](state, action) {
        return {
            ...state,
            searchRecords: [],
            successReplaceAll: {},
            replacingType: {}
        };
    },
    [types.SET_SHOW_SEARCH_RANGE](state, { payload }) {
        return {
            ...state,
            showSearchRange: payload
        };
    },
    [types.SET_SEARCH_RANGE](state, { payload }) {
        return {
            ...state,
            searchRange: payload
        };
    }
};

export default handler;
