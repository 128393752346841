import React from 'react';
import { Grid } from '@material-ui/core';
import File from '../components/File';
import isArray from 'lodash/isArray';
import * as gridUIActions from 'gridUI/actions';
import { PREVIEW_IMAGE_TYPES } from 'const/gridUI';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            cursor: 'zoom-in'
        }
    }
}));

function Images({
    previousData = [],
    isHideMarkIcon = false,
    dbId,
    rowIndex,
    columnId,
    onOpenFilePreview,
    conflictType
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const allowedPreview = React.useMemo(() => {
        return !conflictType;
    }, [conflictType]);

    const handleClickOpen = React.useCallback(
        ({ e, fileMeta }) => {
            e.stopPropagation();
            let body = {
                rowId: rowIndex,
                columnId,
                type: PREVIEW_IMAGE_TYPES.RECORD_HISTORY
            };
            if (fileMeta) {
                body = {
                    ...body,
                    defaultPreviewFile: fileMeta
                };
            }
            dispatch(gridUIActions.openCellFilePreview(body));
            onOpenFilePreview && onOpenFilePreview();
        },
        [columnId, onOpenFilePreview, rowIndex, dispatch]
    );

    if (!previousData || previousData?.length === 0 || !isArray(previousData)) return null;

    return (
        <Grid container spacing={1}>
            {previousData?.map(fileMeta => {
                return (
                    <Grid
                        onClick={e => (allowedPreview ? handleClickOpen({ e, fileMeta }) : () => {})}
                        item
                        className={allowedPreview && classes.root}
                        key={fileMeta?.id}
                    >
                        <File
                            fileMeta={fileMeta}
                            isHideMarkIcon={isHideMarkIcon}
                            state={'delete'}
                            width={28}
                            height={28}
                            dbId={dbId}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(Images);
