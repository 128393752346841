import React from 'react';
import { AutoSizer } from 'react-virtualized-dn';
import GridUITable from 'gridUI/table/grid/GridUIViewOnly';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, CircularProgress, Radio, RadioGroup, FormControlLabel, Collapse } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { ROW_HEIGHT, EXPORT_TYPES } from 'const/gridUI';
import Button from 'components/button/Base';
import {
    useMetaData,
    useViewColumnIdsWithReOrder,
    useViewColumnOptionsWithReOrder,
    useExportRemovedColumns,
    useQuickSorts,
    useQuickFilters,
    useDragNodeIds,
    usePathTagTree
} from 'hooks/gridUI';
import Spinner from 'components/spinner/Base';

import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import { isLDEmpty, removeArrayInArray } from 'utils/object';
import SelectOnOff from 'components/selects/SelectOnOff';
import PopperMenu from 'components/menus/Popper';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ArrowUpSVG from 'assets/images/svg/ArrowUpSVG';
import LDSelectBase from 'components/selects/LDBase';
import { INPUT_PADDING_LEFT, INPUT_RADIUS, INPUT_HEIGHT } from 'const/style';
import ExportHeader from './ExportHeader';
import { SYSTEM_COLUMN_IDS, PATH_TAG_ID, DELIMITER_OPTIONS } from 'const';
import { sendManualTrack } from 'tracker';
import hexToRgba from 'hex-to-rgba';
import * as columnTypes from 'const/columnTypes';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import { useExportData, useExportRows } from 'hooks/gridUI/export';
import { formatQuickFilters } from 'utils/gridUI/filter';
import Checkbox from 'components/checkbox/Base';
import { getPath } from 'utils/gridUI/pathTag';
import { OPERATOR } from 'gridUI/conditions';

const useStyles = makeStyles(theme => ({
    root: {
        width: 800,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            minWidth: 'initial'
        }
    },
    table: {
        height: 260,
        overflow: 'hidden',
        userSelect: 'none',
        '& .cell': {
            pointerEvents: 'none'
        },
        position: 'relative'
    },
    exportOptions: {
        background: theme.colors.ghostwhite,
        padding: theme.spacing(4),
        borderRadius: 4
    },
    expand: {
        flex: 1
    },
    title: {
        minWidth: 140
    },
    dropdown: {
        minWidth: 320,
        border: `1px solid ${theme.colors.border}`,
        padding: `0px ${INPUT_PADDING_LEFT}px`,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        fontWeight: 'normal',
        borderRadius: INPUT_RADIUS,
        height: INPUT_HEIGHT
    },
    active: {
        border: `1px solid ${theme.colors.lightLavender}`
    },
    bold: {
        fontWeight: 'bold'
    },
    format: {
        '& > div': {
            color: theme.colors.primaryText,
            fontWeight: 'normal',
            marginLeft: 10
        },
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    switch: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'normal'
    },
    switchIcon: {
        marginRight: 10
    },
    flex: {
        display: 'flex'
    },
    blue80: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    delimiter: {
        minWidth: 344
    },
    rowSpacing: {
        marginRight: theme.spacing(3)
    },
    columnInnerSpacing: {
        marginBottom: theme.spacing(2)
    },
    columnSpacing: {
        marginBottom: theme.spacing(3)
    },
    spacing: {
        marginBottom: theme.spacing(4)
    },
    showMoreOption: {
        cursor: 'pointer'
    },
    bgWhite: {
        background: theme.colors.white
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        zIndex: 10
    }
}));

function ExportView({ onClose, viewId, dbId }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isExporting, setIsExporting] = React.useState(false);
    const [exportType, setExportType] = React.useState(EXPORT_TYPES.CSV);
    const [isShowMoreSetting, setIsShowMoreSetting] = React.useState(false);
    const [delimiter, setDelimiter] = React.useState(DELIMITER_OPTIONS?.[0]);
    const data = useExportData(viewId);
    const rows = useExportRows(viewId);
    const metaData = useMetaData();
    const quickSorts = useQuickSorts();
    const quickFilters = useQuickFilters();
    const dragNodeIds = useDragNodeIds();
    const tree = usePathTagTree();

    const [isFetching, setIsFetching] = React.useState(true);

    const [enabledSortFilter, setEnabledSortFilter] = React.useState(true);
    const [enableSelectedPaths, setEnableSelectedPaths] = React.useState(dragNodeIds?.length > 0);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const quickFiltersCombined = React.useMemo(() => {
        const filter = enabledSortFilter ? quickFilters : {};
        const pathNames = dragNodeIds?.map(nodeId => getPath({ tree, nodeId })?.pathName);

        const extraFilters = dragNodeIds?.length
            ? enableSelectedPaths
                ? { _pathTag: { value: pathNames, operator: OPERATOR.startsWith, type: '_pathTag' } }
                : {}
            : {};
        return { ...filter, ...extraFilters };
    }, [enabledSortFilter, quickFilters, tree, dragNodeIds, enableSelectedPaths]);

    const quickSortsCombined = React.useMemo(() => {
        return enabledSortFilter ? quickSorts : {};
    }, [enabledSortFilter, quickSorts]);

    React.useEffect(() => {
        setIsFetching(true);
        dispatch(
            gridActions.fetchExportPreviewData({
                defaultAccessViewId: viewId,
                dbId,
                ROW_START_INDEX: 0,
                ROW_STOP_INDEX: 5,
                withQuickFilterAndSort: enabledSortFilter,
                widthSelectedPaths: enableSelectedPaths,
                successCallback: () => {
                    setIsFetching(false);
                    console.log('fetch export preview failed');
                },
                errorCallback: () => {
                    setIsFetching(false);
                    console.log('fetch export preview success');
                }
            })
        );
    }, [enabledSortFilter, dbId, viewId, dispatch, enableSelectedPaths]);

    const exportRemovedColumns = useExportRemovedColumns();
    const columnIdsWithOrders = useViewColumnIdsWithReOrder();

    const columnSystemsWithoutPathTag = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS?.filter(id => ![PATH_TAG_ID, columnTypes?.RECORD_ID].includes(id));
    }, []);

    let columnIds = React.useMemo(() => {
        const columnIdsFinal = removeArrayInArray(
            columnIdsWithOrders,
            exportRemovedColumns.concat(columnSystemsWithoutPathTag)
        );
        return columnIdsFinal;
    }, [columnIdsWithOrders, exportRemovedColumns, columnSystemsWithoutPathTag]);
    const columnOptions = useViewColumnOptionsWithReOrder();

    const handleExportData = () => {
        setIsExporting(true);
        sendManualTrack({ type: `Export File` });
        dispatch(
            gridActions.exportViewData({
                queryParams: {
                    exportFormat: exportType,
                    columnIds: columnIds,
                    csvDelimiter: delimiter?.value,
                    query: formatQuickFilters(quickFiltersCombined),
                    sort: quickSorts
                },
                successCallback: () => {
                    setIsExporting(false);
                    console.log('export successfully');
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsExporting(false);
                    console.log('failed to export');
                }
            })
        );
    };

    const handleOnItemChange = ({ value, checked }) => {
        if (!checked) {
            dispatch(
                gridActions.addExportRemovedColumn({
                    columnId: value
                })
            );
        } else {
            dispatch(
                gridActions.restoreExportRemovedColumn({
                    columnId: value
                })
            );
        }
    };

    const handleExportTypeChange = e => {
        let exportType = e.target.value;
        setExportType(exportType);
    };

    const toggleSettingHandler = () => {
        setIsShowMoreSetting(!isShowMoreSetting);
    };

    const handleDelimiterChange = option => {
        setDelimiter(option);
    };

    const isShowSwitchFilter = React.useMemo(() => {
        return !isLDEmpty(quickFilters) || !isLDEmpty(quickSorts);
    }, [quickFilters, quickSorts]);

    const options = React.useMemo(() => {
        return [
            { value: EXPORT_TYPES.CSV, label: t(`global_csv`) },
            { value: EXPORT_TYPES.XLSX, label: t(`global_xlsx`) },
            { value: EXPORT_TYPES.TSV, label: t(`global_tsv`) }
        ];
    }, [t]);

    return (
        <>
            <DialogTitle title={t(`toolbar_export_data_tooltip`)} onClose={onClose} />
            <DialogContent>
                <Grid container direction={'column'} className={classes.root}>
                    <Grid item container direction="column" className={classes.spacing}>
                        <Grid className={classes.columnInnerSpacing} item container alignItems="center" direction="row">
                            <Grid item className={classes.rowSpacing}>
                                <p className="body1">{t(`global_type`)}</p>
                            </Grid>
                            <Grid item className={classes.rowSpacing}>
                                <RadioGroup
                                    row
                                    aria-label="export-type"
                                    name="exportType"
                                    value={exportType}
                                    onChange={handleExportTypeChange}
                                >
                                    {options.map(option => (
                                        <FormControlLabel
                                            key={option.value}
                                            value={option.value}
                                            control={<Radio size="small" className={classes.radio} />}
                                            label={<p className="body1">{option.label}</p>}
                                        />
                                    ))}
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container className={classes.exportOptions} direction="column">
                                <Grid item className={isShowMoreSetting ? classes.columnSpacing : ``}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="flex-start"
                                        spacing={4}
                                    >
                                        <Grid item className={classes.title}>
                                            <p className="body1">{t(`export_column_to_export`)}</p>
                                        </Grid>
                                        <Grid item>
                                            <p onClick={handleClick} className={`body1 bg-white`}>
                                                <span
                                                    className={`${classes.dropdown} ${anchorEl ? classes.active : ``}`}
                                                >
                                                    {columnIds.length} {t(`export_column_selected`)} <ArrowDownSVG />
                                                </span>
                                            </p>
                                            {anchorEl && (
                                                <PopperMenu
                                                    anchorEl={anchorEl}
                                                    handleClickAway={handleClickAway}
                                                    placement={'bottom-end'}
                                                >
                                                    <SelectOnOff
                                                        options={columnOptions?.filter(
                                                            option =>
                                                                !columnSystemsWithoutPathTag.includes(option?.value)
                                                        )}
                                                        handleClickAway={handleClickAway}
                                                        onChange={handleOnItemChange}
                                                        selectedValues={columnIds}
                                                    />
                                                </PopperMenu>
                                            )}
                                        </Grid>

                                        {exportType === EXPORT_TYPES.CSV && (
                                            <Grid item onClick={toggleSettingHandler}>
                                                <Grid
                                                    className={classes.showMoreOption}
                                                    container
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <Grid item className={classes.flex}>
                                                        {isShowMoreSetting ? (
                                                            <ArrowUpSVG
                                                                color={hexToRgba(theme.colors.dodgerBlue, 0.8)}
                                                            />
                                                        ) : (
                                                            <ArrowDownSVG
                                                                color={hexToRgba(theme.colors.dodgerBlue, 0.8)}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <p className="body1 text-blue-dodger opacity-80">
                                                            {isShowMoreSetting
                                                                ? t(`global_show_less_setting`)
                                                                : t(`global_show_more_setting`)}
                                                        </p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Collapse in={isShowMoreSetting && exportType === EXPORT_TYPES.CSV}>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justify="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item className={classes.title}>
                                                <p className="body1">{t(`global_separated_by`)}</p>
                                            </Grid>
                                            <Grid item className={classes.delimiter}>
                                                <LDSelectBase
                                                    ddPlaceholder={t(`global_label_choose_a_delimiter`)}
                                                    menuPlaceholder={t(`global_label_search`)}
                                                    options={DELIMITER_OPTIONS}
                                                    onChange={handleDelimiterChange}
                                                    defaultValue={delimiter}
                                                    isMulti={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Grid>

                    {(isShowSwitchFilter || dragNodeIds?.length > 0) && (
                        <Grid item container wrap="nowrap" direction="column" className={classes.spacing}>
                            <Grid item>
                                <Grid item className={classes.rowSpacing}>
                                    <p className="body1">Export option</p>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row">
                                    {dragNodeIds?.length > 0 && (
                                        <Grid item xs={4}>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item>
                                                    <Checkbox
                                                        checked={enableSelectedPaths}
                                                        onChange={e => setEnableSelectedPaths(e.target?.checked)}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <p className="body2 inline">Included selected paths</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {isShowSwitchFilter && (
                                        <Grid item xs={4}>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item>
                                                    <Checkbox
                                                        checked={enabledSortFilter}
                                                        onChange={e => setEnabledSortFilter(e.target?.checked)}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <p className="body2 inline">Apply quick filters and quick sorts</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item container direction="column" spacing={2}>
                        <Grid item container direction="row" alignItems="center" justify="space-between">
                            <Grid item>
                                <p className="body1">{t(`global_preview_data`)}</p>
                            </Grid>
                        </Grid>
                        <Grid item container className={`${classes.table}`}>
                            {isFetching && (
                                <div className={classes.loadingSpinner}>
                                    <Spinner size={30} thick={4} />
                                </div>
                            )}
                            {!isFetching && (
                                <AutoSizer>
                                    {({ width, height }) => {
                                        return (
                                            <GridUITable
                                                metaData={metaData}
                                                totalRecords={rows?.length > 5 ? 6 : rows?.length}
                                                data={data}
                                                rows={rows}
                                                columns={columnIds}
                                                width={width}
                                                maxHeight={height}
                                                rowHeight={ROW_HEIGHT}
                                                AGG_HEIGHT={0}
                                                fixedColumnCount={0}
                                                fixedRowCount={1}
                                                COLUMN_ID_WIDTH={0}
                                                headerRenderer={props => <ExportHeader {...props} />}
                                                quickSorts={quickSortsCombined}
                                                quickFilters={quickFiltersCombined}
                                                t={t}
                                            />
                                        );
                                    }}
                                </AutoSizer>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Grid container spacing={2} direction="row" wrap="nowrap">
                            <Grid item>
                                <Button variant="outlined" width={120} onClick={onClose}>
                                    {t(`global_cancel`)}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    width={120}
                                    disabled={isEmpty(columnIds)}
                                    variant="contained"
                                    onClick={handleExportData}
                                >
                                    {isExporting ? (
                                        <CircularProgress
                                            style={{
                                                color: 'white'
                                            }}
                                            size={20}
                                        />
                                    ) : (
                                        t(`global_export`)
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default React.memo(ExportView);
