import request from './request';
import { APP_API_PREFIX_DATA_COLLECTOR } from 'config';

const dataCollectorApis = {
    companyUsage: `${APP_API_PREFIX_DATA_COLLECTOR}/v1/company-usages`
};

export async function getUsageApi() {
    const response = await request.get(dataCollectorApis.companyUsage);
    return response.data;
}
