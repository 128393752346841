import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateActionNode } from 'gridUI/automations/action';
import { Trans, useTranslation } from 'react-i18next';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import LDBasePortal from 'components/selects/LDBasePortal';
import Payloadv2 from './Payloadv2';
import MapTranslatedColumn from './MapTranslatedColumn';
import TooltipActionDisable from '../TooltipActionDisable';
import { ACTION_STATUS } from 'gridUI/automations/const';
import * as columnTypes from 'const/columnTypes';
import { AUTOMATION_VOICE_OPTIONS } from 'const/automation';

const ActionTextToSpeech = ({ selectedNode, setSelectedNode }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const automationDetail = useGetAutomationDetail();
    const [engine, setEngine] = useState(selectedNode?.params?.engine || '');
    const [voiceId, setVoiceId] = useState(selectedNode?.params?.voiceId || '');
    const [outputFormat, setOutputFormat] = useState(selectedNode?.params?.outputFormat || '');
    const [textType, setTextType] = useState(selectedNode?.params?.textType || 'text');

    const openEngineRef = useRef();
    const openVoiceRef = useRef();
    const openOutputFormatRef = useRef();
    const timeout = useRef();

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        setEngine(selectedNode?.params?.engine || '');
        setVoiceId(selectedNode?.params?.voiceId || '');
        setOutputFormat(selectedNode?.params?.outputFormat || '');
        openEngineRef.current = false;
        openVoiceRef.current = false;
        openOutputFormatRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const OUTPUT_FORMAT_OPTIONS = useMemo(() => {
        return [
            { value: 'json', label: 'JSON' },
            { value: 'mp3', label: 'MP3' },
            { value: 'ogg_vorbis', label: 'OGG_VORBIS' },
            { value: 'pcm', label: 'PCM' }
        ];
    }, []);

    const voiceOptionsGroupByEngine = useMemo(() => {
        if (engine) {
            return AUTOMATION_VOICE_OPTIONS.filter(el => !!el[engine]).map(el => ({
                ...el,
                searchLabel: `${el.language} - ${el.value} (${el.gender})`,
                label: (
                    <Trans
                        defaults={`${el.language} - <span>${el.value} (${el.gender})</span>`}
                        components={{
                            span: <span className="text-steel" />
                        }}
                    />
                )
                // icon: () => <CustomCountryFlag languageCountryCode={el.group} />
            }));
        }
        return [];
    }, [engine]);

    const selectedVoice = useMemo(() => {
        return voiceOptionsGroupByEngine.find(el => el.value === voiceId);
    }, [voiceOptionsGroupByEngine, voiceId]);

    const selectedOutputFormat = useMemo(() => {
        return OUTPUT_FORMAT_OPTIONS.find(el => el.value === outputFormat);
    }, [OUTPUT_FORMAT_OPTIONS, outputFormat]);

    const errorVoiceMessage = nodeError?.voiceId || '';

    const errorOutputFormatMessage = nodeError?.outputFormat || '';

    const handleVoiceChange = useCallback(
        option => {
            if (voiceId === option.value) return;
            setVoiceId(option.value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                voiceId: option.value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setVoiceId(selectedNode?.params?.voiceId);
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode, voiceId]
    );

    const handleOutputChange = useCallback(
        option => {
            if (outputFormat === option.value) return;
            setOutputFormat(option.value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                outputFormat: option.value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setOutputFormat(selectedNode?.params?.outputFormat);
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, outputFormat, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const handleTextTypeChange = useCallback(
        value => {
            setTextType(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                textType: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setTextType(selectedNode?.params?.textType);
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, textType, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    return (
        <div item>
            <div item className="!mb-6">
                <Payloadv2
                    isRequired
                    label={t('automation_select_cell_value_from_a_column')}
                    placeholder={t('automation_select_cell_value_from_a_column')}
                    editorClassName="max-h-[280px] !h-auto"
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />
            </div>
            <div item className="!mb-6">
                <div item>
                    <p className="body1">
                        {t('automation_cell_value')}
                        <span className="text-brightRed">*</span>
                    </p>
                </div>
                <div item>
                    <RadioGroup defaultValue={textType} onChange={e => handleTextTypeChange(e?.target?.value)}>
                        <div className="flex items-center">
                            <div className="flex-1">
                                <FormControlLabel value="text" control={<Radio size="small" />} label="Text" />
                            </div>
                            <div className="flex-1">
                                <FormControlLabel value="ssml" control={<Radio size="small" />} label="SSML" />
                            </div>
                        </div>
                    </RadioGroup>
                </div>
            </div>
            <div item className="!mb-6">
                <div item className="!mb-[14px]">
                    <p className="body1">
                        {t('automation_voice')}
                        <span className="text-error">*</span>
                    </p>
                </div>
                <div item>
                    <TooltipActionDisable isAction disabled={isDisabled}>
                        <LDBasePortal
                            ddPlaceholder={t('automation_choose_voice')}
                            menuPlaceholder={t('automation_search_voice')}
                            options={voiceOptionsGroupByEngine}
                            onChange={handleVoiceChange}
                            defaultValue={selectedVoice}
                            isDisabled={!engine}
                            isUsingContainer
                            controlClassName={classnames('!min-h-[60px]', {
                                '!border-brightRed': !!errorVoiceMessage
                            })}
                        />
                        {errorVoiceMessage && (
                            <div item className="!mt-3">
                                <p className="text-error">{errorVoiceMessage}</p>
                            </div>
                        )}
                    </TooltipActionDisable>
                </div>
            </div>
            <div item className="!mb-6">
                <div item className="!mb-[14px]">
                    <p className="body1">
                        {t('automation_output_format')}
                        <span className="text-error">*</span>
                    </p>
                </div>
                <div item>
                    <TooltipActionDisable isAction disabled={isDisabled}>
                        <LDBasePortal
                            ddPlaceholder={t('automation_choose_output_format')}
                            options={OUTPUT_FORMAT_OPTIONS}
                            onChange={handleOutputChange}
                            defaultValue={selectedOutputFormat}
                            isUsingContainer
                            controlClassName={classnames('!min-h-[60px]', {
                                '!border-brightRed': !!errorOutputFormatMessage
                            })}
                            popperClassName={classnames('[&_.MuiFormControl-root]:hidden')}
                        />
                        {errorOutputFormatMessage && (
                            <div item className="!mt-3">
                                <p className="text-error">{errorOutputFormatMessage}</p>
                            </div>
                        )}
                    </TooltipActionDisable>
                </div>
            </div>
            <div item>
                <MapTranslatedColumn
                    isRequired
                    label={t('automation_column_to_hold_speech_result_file')}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    filterColumns={[columnTypes.FILES]}
                />
            </div>
            <div item>
                <p className="body2">{t('automation_column_to_hold_speech_result_file_description')}</p>
            </div>
        </div>
    );
};

export default React.memo(ActionTextToSpeech);
