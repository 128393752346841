import React from 'react';
import ButtonBase from 'components/button/Base';
import { DISABLED_OPACITY } from 'const/style';

function AddSortOrderButton({ isReadOnly, onAddSort, t }) {
    return (
        <ButtonBase
            style={{
                pointerEvents: isReadOnly ? 'none' : '',
                opacity: isReadOnly ? DISABLED_OPACITY : 1
            }}
            size="small"
            onClick={onAddSort}
            variant="outlined"
            autoFocus
        >
            {t(`sort_add_button`)}
        </ButtonBase>
    );
}
export default React.memo(AddSortOrderButton);
