import * as types from '../types';

const handler = {
    [types.FETCH_DEPENDENCIES](state) {
        return {
            ...state,
            isFetchingDependencies: true
        };
    },
    [types.FETCH_DEPENDENCIES_FAILED](state, { payload }) {
        const { error } = payload;

        return {
            ...state,
            isFetchingDependencies: false,
            error
        };
    },
    [types.FETCH_DEPENDENCIES_SUCCESS](state, { payload }) {
        const { dependencies } = payload;
        return {
            ...state,
            isFetchingDependencies: false,
            dependencies
        };
    },
    [types.CREATE_DEPENDENCY](state, { payload }) {
        return {
            ...state,
            isCreatingDependency: true
        };
    },
    [types.CREATE_DEPENDENCY_SUCCESS](state, { payload }) {
        const { dependencyId, dependency } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                return dependency;
            }
            return dpdc;
        });
        return {
            ...state,
            dependencies: newList,
            isCreatingDependency: false
        };
    },
    [types.CREATE_DEPENDENCY_FAILED](state, { payload }) {
        const { dependencyId, dependency } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                return dependency;
            }
            return dpdc;
        });
        return {
            ...state,
            dependencies: newList,
            isCreatingDependency: false
        };
    },
    [types.CREATE_DEPENDENCY_SOCKET](state, { payload }) {
        const { dependency } = payload;
        const { dependencies } = state;
        return {
            ...state,
            dependencies: [...dependencies, dependency]
        };
    },
    [types.CREATE_FAKE_DEPENDENCY](state, { payload }) {
        const { dependency } = payload;
        const { dependencies } = state;

        return {
            ...state,
            dependencies: [...dependencies, dependency]
        };
    },
    [types.CHANGE_PARENT](state, { payload }) {
        const { dependencyId, value } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                dpdc.parent = value;
            }
            return dpdc;
        });
        return {
            ...state,
            dependencies: newList
        };
    },
    [types.CHANGE_CHILD](state, { payload }) {
        const { dependencyId, value } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                dpdc.child = value;
            }
            return dpdc;
        });

        return {
            ...state,
            dependencies: newList
        };
    },
    [types.DELETE_DEPENDENCY](state, { payload }) {
        const { dependencyId } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                dpdc.isDeleted = true;
            }
            return dpdc;
        });

        return {
            ...state,
            dependencies: newList,
            isDeletingDependency: true
        };
    },
    [types.DELETE_DEPENDENCY_FAILED](state, { payload }) {
        const { dependencyId } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                dpdc.isDeleted = false;
            }
            return dpdc;
        });

        return {
            ...state,
            dependencies: newList,
            isDeletingDependency: false
        };
    },
    [types.DELETE_DEPENDENCY_SUCCESS](state, { payload }) {
        const { dependencyId } = payload;
        const { dependencies } = state;

        const newList = dependencies.filter(dpdc => dpdc?.id !== dependencyId);

        return {
            ...state,
            dependencies: newList,
            isDeletingDependency: false
        };
    },
    [types.UPDATE_DEPENDENCY](state, { payload }) {
        const { dependencyId, dependency } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                return dependency;
            }
            return dpdc;
        });
        return {
            ...state,
            dependencies: newList,
            isUpdatingDependency: true
        };
    },
    [types.UPDATE_DEPENDENCY_FAILED](state, { payload }) {
        const { dependencyId, dependency } = payload;
        const { dependencies } = state;

        const newList = dependencies.map(dpdc => {
            if (dpdc?.id === dependencyId) {
                return dependency;
            }
            return dpdc;
        });
        return {
            ...state,
            dependencies: newList,
            isUpdatingDependency: false
        };
    },
    [types.UPDATE_DEPENDENCY_SUCCESS](state, { payload }) {
        return {
            ...state,
            isUpdatingDependency: false
        };
    },
    [types.OPEN_NOTI_DEPENDENCY](state) {
        return {
            ...state,
            isOpenNotiDependency: true
        };
    },
    [types.CLOSE_NOTI_DEPENDENCY](state) {
        return {
            ...state,
            isOpenNotiDependency: false
        };
    }
};

export default handler;
