import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import AddRowBelowSVG from 'assets/images/svg/AddRowBelowSVG';
import OpenFolderSVG from 'assets/images/svg/OpenFolderSVG';
import CloseFolderSVG from 'assets/images/svg/CloseFolderSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import PopperMenu from 'components/menus/Popper';
import TreeEditPopup from './TreeEditPopup';
import * as gridUIActions from '../actions';
import { useDispatch } from 'react-redux';
import { useQuickFilterPathTag, useDragNodeIds, useDraggingPath } from 'hooks/gridUI';
import { DROP_ITEM_TYPES } from 'const';
import { sendManualTrack } from 'tracker';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import * as columnTypes from 'const/columnTypes';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import ThreeStatesCheckbox from 'components/checkbox/ThreeStateCheckBox';
import Tooltip from 'components/tooltip/Base';
import { pathNodesObj } from 'utils/gridUI/pathTag';

const distanceLevel = 17;

const useTreeItemStyles = makeStyles(theme => ({
    wrapper: {
        marginLeft: props => distanceLevel * (props.level + 1) + 42,
        flex: 1
    },
    root: {
        height: `100%`,
        color: theme.palette.text.secondary,
        background: 'transparent !important',
        '& .actionWrapper': {
            display: 'none'
        },
        '&:hover': {
            '& .actionWrapper': {
                display: 'block'
            }
        }
    },
    content: {
        color: theme.palette.text.secondary,
        paddingRight: 4,
        fontWeight: theme.typography.fontWeightMedium,
        background: 'transparent !important',
        width: 300,
        '& .MuiTreeItem-iconContainer': {
            width: 16
        },
        '&:hover': {
            background: `${theme.colors.paleNavy} !important`
        }
    },
    group: {
        paddingLeft: theme.spacing(4),
        marginLeft: 0
    },
    expanded: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
        marginRight: theme.spacing(1)
    },
    icon: {
        display: 'flex',
        marginRight: theme.spacing(2)
    },
    radio: {
        padding: 0,
        color: theme.colors.lightGreyBlue
    },
    iconAction: {
        display: 'flex',
        marginLeft: SPACING_LIST_NAME_WITH_ICON,
        maxWidth: 16,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            width: '100%',
            height: '100%'
        },
        '&:hover': {
            opacity: 0.5
        }
    },
    addIcon: {
        border: `1px solid ${theme.colors.steel}`,
        borderRadius: `50%`,
        padding: 3
    },
    pathNameWrapper: {
        maxWidth: 150
    },
    pathName: {
        maxWidth: 190,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: theme.colors.dimGrey
    },
    isDragOver: {
        background: `${theme.colors.solitude} !important`
    },
    unHightLight: {
        background: `transparent !important`,
        border: `1px dashed ${theme.colors.highlight}`
    },
    treeItem: {
        paddingTop: 2,
        paddingBottom: 2,
        cursor: 'pointer',
        position: 'relative',
        height: `100%`
    },
    spr2: {
        marginRight: theme.spacing(2)
    },
    spr1: {
        marginRight: theme.spacing(1)
    },
    dlFlex: {
        display: 'flex',
        justifyContent: 'center'
    },
    checkboxWrapper: {
        position: 'absolute',
        left: props => distanceLevel * props.level + 37,
        top: 11
    },
    checkboxIconButton: {
        padding: 0
    },
    checkBox: {
        padding: 0,
        width: 24,
        height: 24
    },
    arrow: {
        minWidth: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'absolute',
        left: props => distanceLevel * props.level + 15,
        top: 9
    },
    foldIcon: {
        minWidth: 24,
        height: 24,
        marginRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textHighlight: {
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    before: {
        background: 'transparent !important',
        marginLeft: props =>
            props.level === 0 ? 0 : props.children > 0 ? 8 * (props.level + 1) + 16 : 8 * (props.level + 1) + 30,
        height: 3
    },
    after: {
        background: 'transparent !important',
        marginLeft: props => props.level * 20,
        height: 3
    },
    dropHighLightBg: {
        background: `${theme.colors.highlight} !important`
    },
    filterHighlight: {
        background: `${theme.colors.paleNavy} !important`
    },
    displayNone: {
        opacity: 0
    },
    displayBlock: {
        opacity: 1
    }
}));

function StyledTreeItem(props) {
    const dispatch = useDispatch();
    const classes = useTreeItemStyles({ level: props?.level, children: props?.node?.children?.length || 0 });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const labelRef = React.useRef();
    const pathTagQuickFilter = useQuickFilterPathTag();
    const draggingPath = useDraggingPath();
    const dragNodeIds = useDragNodeIds();
    const timer = React.useRef(0);
    const isDown = React.useRef();

    const highlightNodeIdByFilter = React.useMemo(() => {
        return pathTagQuickFilter?.currentState;
    }, [pathTagQuickFilter]);

    const handleClick = event => {
        stopPropagation(event);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        node,
        labelText,
        color,
        nodeIdShowPopup,
        parentIdPath,
        isShareViewLink,
        t,
        isCanEditPathTag,
        collapsed,
        onToggle,
        onOpen,
        ...other
    } = props;

    const onEditHandler = e => {
        stopPropagation(e);
        if (labelRef.current) {
            labelRef.current.click();
        }
    };

    React.useEffect(() => {
        if (!!nodeIdShowPopup && nodeIdShowPopup === props.nodeId) {
            dispatch(gridUIActions.turnOffFirstPopup());
            setTimeout(() => {
                let $arrow = document.getElementById(`label_${props.nodeId}`);
                if ($arrow) {
                    $arrow.click();
                }
            }, 100);
        } else {
            handleClose();
        }
    }, [dispatch, nodeIdShowPopup, props.nodeId]);

    const onAddHandler = e => {
        stopPropagation(e);
        if (props.node) {
            onOpen(props.node);
        }
        sendManualTrack({
            type: `Add Sub Path`,
            customData: {
                parentNodeId: props?.nodeId
            }
        });
        dispatch(
            gridUIActions.addChildNode({
                parentNodeId: props.nodeId,
                successCallback: () => {
                    console.log('add child node success');
                },
                errorCallback: () => {
                    console.log('add child node faileds');
                }
            })
        );
    };

    const stopPropagation = e => {
        e.stopPropagation();
    };

    const handleFilterPathTag = React.useCallback(
        e => {
            if (draggingPath) return;
            // e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            stopPropagation(e);
            const { isCtrl } = getStatusCtrlOrShiftKey(e);
            if (isCtrl) {
                return;
            }

            let fullPathId = !parentIdPath ? props.nodeId : `${parentIdPath}/${props.nodeId}`;

            dispatch(
                gridUIActions.checkPathTagOnOrOff(isHasPathTagInView => {
                    if (!isHasPathTagInView) return;

                    if (highlightNodeIdByFilter === props?.nodeId) {
                        dispatch(
                            gridUIActions.clearQuickFiltersAfterColumnDeletedOrHidden({
                                columnId: columnTypes?.PATH_TAG
                            })
                        );
                    } else {
                        dispatch(
                            gridUIActions.checkPathTagOnOrOff(isHasPathTagInView => {
                                if (isHasPathTagInView) {
                                    dispatch(
                                        gridUIActions.quickFilterPathTagChange({ fullPathId, nodeId: props?.nodeId })
                                    );
                                    dispatch(gridUIActions.resetSelection());
                                }
                            })
                        );
                    }
                    if (timer.current) clearTimeout(timer.current);
                    timer.current = setTimeout(function() {
                        dispatch(
                            gridUIActions.fetchRecordsWithFiltersAndSorts({
                                errorCallback: () => {
                                    console.log('failed to filter');
                                },
                                successCallback: () => {
                                    console.log('filter successfully');
                                }
                            })
                        );
                    }, DEBOUNCE_TIME_SEARCHING);
                })
            );
        },
        [dispatch, parentIdPath, props.nodeId, highlightNodeIdByFilter, draggingPath]
    );

    const PATH_TAG_DROP_CLASS_NAME = React.useMemo(() => `${DROP_ITEM_TYPES.PATH_TAG}_${props.nodeId}`, [props.nodeId]);
    const PATH_TAG_DROP_CLASS_NAME_BEFORE = React.useMemo(() => `${DROP_ITEM_TYPES.PATH_TAG}_BEFORE_${props.nodeId}`, [
        props.nodeId
    ]);
    // const PATH_TAG_DROP_CLASS_NAME_AFTER = React.useMemo(() => `${DROP_ITEM_TYPES.PATH_TAG}_AFTER_${props.nodeId}`, [
    //     props.nodeId
    // ]);

    const children = React.useMemo(() => {
        return props?.node?.children;
    }, [props]);

    const onAddRecordBelow = React.useCallback(
        e => {
            stopPropagation(e);
            let fullPathId = !parentIdPath ? props.nodeId : `${parentIdPath}/${props.nodeId}`;

            dispatch(
                gridUIActions.createRecordWithPathTag({
                    fullPathId,
                    errorCallback: error => {
                        console.log('failed to create record');
                    },
                    successCallback: () => {
                        console.log('success');
                    }
                })
            );
        },
        [parentIdPath, props, dispatch]
    );

    const handleMouseDown = React.useCallback(e => {
        stopPropagation(e);
        isDown.current = true;
    }, []);

    const handleMouseUpOrLeave = React.useCallback(() => {
        isDown.current = false;
    }, []);

    const handleToggle = e => {
        e.stopPropagation();
        e.preventDefault();
        onToggle();
    };

    const isDragNode = React.useMemo(() => {
        return dragNodeIds?.includes(props.nodeId);
    }, [dragNodeIds, props.nodeId]);

    const isChildChecked = React.useMemo(() => {
        const childrenNodeIds = pathNodesObj?.[node.id]?.childrenNodeIds || [];
        return childrenNodeIds?.some(id => dragNodeIds?.includes(id));
    }, [dragNodeIds, node.id]);

    const isHighLightByFilter = React.useMemo(() => {
        return highlightNodeIdByFilter === props?.nodeId;
    }, [highlightNodeIdByFilter, props.nodeId]);

    const isHightLightBefore = React.useMemo(() => {
        return draggingPath?.includes(props.nodeId) && draggingPath?.includes('BEFORE');
    }, [draggingPath, props.nodeId]);

    const handleCheckbox = useCallback(
        (e, nextState) => {
            e.stopPropagation();
            let tempDragNodeIds = [...dragNodeIds];
            if (nextState === 0 || nextState === 1) {
                if (!tempDragNodeIds.includes(props.nodeId)) {
                    tempDragNodeIds.push(props.nodeId);
                }
            } else {
                tempDragNodeIds = tempDragNodeIds.filter(id => id !== props.nodeId);
            }
            dispatch(gridUIActions.setDragNodeIds(tempDragNodeIds));
        },
        [dispatch, dragNodeIds, props.nodeId]
    );

    const handleMouseMove = React.useCallback(() => {
        if (isDown.current) {
            isDown.current = false;
            if (!dragNodeIds?.includes(props.nodeId)) {
                // addNodeIdToDragNodeIds();
                let tempDragNodeIds = [...dragNodeIds];
                tempDragNodeIds.push(props.nodeId);
                dispatch(gridUIActions.setDragNodeIds(tempDragNodeIds));
            }
            dispatch(gridUIActions.setDraggingPathId(props.nodeId));
            dispatch(gridUIActions.turnOnDraggingPathTag());
        }
    }, [dispatch, props.nodeId, dragNodeIds]);

    return (
        <Grid container direction="column" style={{ height: `100%` }}>
            <Grid
                item
                className={`${classes.before} ${
                    !isDragNode ? classes.displayBlock : classes.displayNone
                } ${PATH_TAG_DROP_CLASS_NAME_BEFORE} ${isHightLightBefore && classes.dropHighLightBg}`}
            />

            {children?.length > 0 && (
                <Grid onClick={handleToggle} item className={`${classes.arrow} ${classes.dlFlex}`}>
                    {!collapsed ? <ArrowDownSVG /> : <ArrowRightSVG />}
                </Grid>
            )}

            <Grid item className={classes.checkboxWrapper}>
                <ThreeStatesCheckbox
                    state={isDragNode ? 1 : isChildChecked ? 0 : 2}
                    onChange={handleCheckbox}
                    className={classes.checkboxIconButton}
                />
            </Grid>
            <Tooltip placement="right" title={labelText}>
                <Grid item className={classes.wrapper} onClick={handleFilterPathTag}>
                    <Grid
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        id={`${props.nodeId}`}
                        className={`${PATH_TAG_DROP_CLASS_NAME} ${classes.root} ${classes.content} ${
                            isHighLightByFilter ? classes.filterHighlight : ``
                        } ${isDragNode && !!draggingPath ? classes.unHightLight : ``}`}
                        style={{
                            background: 'red'
                        }}
                        {...other}
                    >
                        <Grid
                            wrap="nowrap"
                            className={`${classes.treeItem} ${PATH_TAG_DROP_CLASS_NAME}`}
                            container
                            direction="row"
                            alignItems="center"
                        >
                            <div
                                id={`label_${props.nodeId}`}
                                onClick={handleClick}
                                ref={labelRef}
                                style={{ height: 20 }}
                            ></div>

                            <Grid item className={`${classes.foldIcon} ${PATH_TAG_DROP_CLASS_NAME}`}>
                                {!collapsed ? (
                                    <OpenFolderSVG
                                        color={
                                            props?.node?.customProperties?.color
                                                ? props?.node?.customProperties?.color
                                                : undefined
                                        }
                                    />
                                ) : (
                                    <CloseFolderSVG
                                        color={
                                            props?.node?.customProperties?.color
                                                ? props?.node?.customProperties?.color
                                                : undefined
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item className={`${classes.pathNameWrapper}  ${PATH_TAG_DROP_CLASS_NAME}`}>
                                <p
                                    className={`body2 ${classes.pathName} ${
                                        highlightNodeIdByFilter === props?.nodeId ? classes.textHighlight : ``
                                    }  ${PATH_TAG_DROP_CLASS_NAME}`}
                                >
                                    {labelText}
                                </p>
                            </Grid>
                            {!props.isJsonOrPoGrid &&
                                !anchorEl &&
                                !draggingPath &&
                                !isShareViewLink &&
                                isCanEditPathTag && (
                                    <Grid item className="actionWrapper">
                                        <Grid container>
                                            <Tooltip title="Edit path name">
                                                <Grid
                                                    onClick={onEditHandler}
                                                    item
                                                    className={`${classes.iconAction} ${PATH_TAG_DROP_CLASS_NAME}`}
                                                >
                                                    <EditSVG style={{ pointerEvents: 'none' }} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Add a child path">
                                                <Grid
                                                    item
                                                    onClick={onAddHandler}
                                                    className={`${classes.iconAction} ${classes.addIcon} ${PATH_TAG_DROP_CLASS_NAME}`}
                                                >
                                                    <AddIconSVG style={{ pointerEvents: 'none' }} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Add a record inside this path">
                                                <Grid
                                                    item
                                                    onClick={onAddRecordBelow}
                                                    className={`${classes.iconAction} ${classes.addIcon} ${PATH_TAG_DROP_CLASS_NAME}`}
                                                >
                                                    <AddRowBelowSVG style={{ pointerEvents: 'none' }} />
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )}
                            {anchorEl && (
                                <PopperMenu anchorEl={anchorEl} handleClickAway={null} placement="bottom-start">
                                    <TreeEditPopup
                                        handleClickAway={handleClose}
                                        name={labelText}
                                        nodeId={props.nodeId}
                                        color={color}
                                        node={props.node}
                                        t={t}
                                    />
                                </PopperMenu>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Tooltip>

            {/* {!isDragNode && collapsed && (
                <Grid
                    item
                    className={`${classes.after} ${PATH_TAG_DROP_CLASS_NAME_AFTER} ${isHightlightAfter &&
                        classes.dropHighlightbg}`}
                />
            )} */}
        </Grid>
    );
}
export default React.memo(StyledTreeItem);
