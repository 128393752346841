import React from 'react';
import { useSelector } from 'react-redux';
import { useGridDetail } from 'hooks/grid';
import TagSVG from 'assets/images/svg/TagSVG';
import { useDependencies } from './dependency';
import { useData } from './data';
import { getCellValueOnly } from 'utils/gridUI/cell';
import { generateTags } from 'utils/gridUI/tag';
import { useParentGridIdInGridUI } from './index';
import { useBranchDetail } from '../versionControl/branch';
import { useIsShareViewLink } from '../app';
import { useCurrentGrid } from 'hooks/gridUI';

export function useGrid({ branchId, dbId }) {
    const parentGridId = useParentGridIdInGridUI();
    const master = useGridDetail({ gridId: branchId, dbId });
    const branch = useBranchDetail({ masterId: parentGridId, branchId });
    if (parentGridId === branchId) return master;
    return branch;
}

export function useTokenDetection({ gridId, dbId }) {
    const currentGrid = useCurrentGrid();
    // const gridDetail = useGrid({ branchId: gridId, dbId });
    return currentGrid?.tokenDetection;
}

export function useIsShowTag({ gridId, dbId }) {
    const isShareViewLink = useIsShareViewLink();
    const tokenDetection = useTokenDetection({ gridId, dbId });
    return !isShareViewLink && tokenDetection?.enabled;
}

export function useTagMode({ gridId, dbId }) {
    const tokenDetection = useTokenDetection({ gridId, dbId });
    return tokenDetection?.mode;
}

export function useTagRegex({ gridId, dbId }) {
    const tokenDetection = useTokenDetection({ gridId, dbId });
    return tokenDetection?.regex;
}

export function useTokenSetId({ gridId, dbId }) {
    const tokenDetection = useTokenDetection({ gridId, dbId });
    return tokenDetection?.tokenSetId;
}

export function useListTagModels() {
    return useSelector(state => state.gridUI.tagModels);
}

export function useTagModelOptions() {
    const models = useListTagModels();
    return models?.map(model => ({
        value: model?.id,
        label: model?.name,
        icon: () => <TagSVG />
    }));
}

export function usePredefinedTokens() {
    return useSelector(state => state.gridUI.predefinedTokens);
}

export function useTokenSuggestions({ columnId, rowId, gridId, dbId }) {
    const dependencies = useDependencies();
    const data = useData();
    const founded = dependencies?.find(dpDc => dpDc?.child === columnId);
    const tokenDetection = useTokenDetection({ gridId, dbId });
    const predefinedTokens = usePredefinedTokens();

    const parent = founded?.parent;

    if (!founded || !parent) return [];

    const parentValue = getCellValueOnly({ data, rowId, columnId: parent });

    const tags = generateTags({ tokenDetection, predefinedTokens, text: parentValue });

    return tags?.map(tag => ({
        label: tag,
        value: tag,
        type: 'token'
    }));
}
