import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Grid, Typography } from '@material-ui/core';
import { SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON } from 'const';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useListTranslationMem, useTmSourcesByTmId, useTmSourceSelected, useTmTargetsSelected } from 'hooks/tm';
import { useDispatch } from 'react-redux';
import * as tmActions from './actions';
import LDBasePortal from 'components/selects/LDBasePortal';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { getFriendlyDate } from 'utils/datetime';
import * as workspaceActions from 'workspaces/actions';
import { useSelectedWorkspaceByWorkspaceIds } from 'hooks/workspace';
import { isEmpty } from 'lodash';
import hexToRgba from 'hex-to-rgba';
import TabbleTmDetail from './EditorTm/TabbleTmDetail';
import ArrowLeft2SVG from 'assets/images/svg/ArrowLeft2SVG';
import { SUPPORTED_LANGUAGES } from 'const/languageData';

const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'hidden',
        width: '100vw',
        height: '100vh'
    },
    leftContent: {
        width: SIDE_BAR_ADVANCED_SEARCH_WIDTH_ON,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: `transparent`,
        borderRadius: 4
    },
    topLeftContent: {
        overflow: 'hidden',
        padding: '34px 20px',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    bottomLeftContent: {
        overflow: 'hidden',
        padding: '34px 20px'
    },
    name: {
        fontSize: 24
    },
    text: {
        fontSize: 14,
        margin: '16px 0'
    },
    rightContent: {
        flex: 1,
        flexGrow: 1,
        padding: '0 24px',
        background: theme.palette.common.white,
        borderLeft: `1px solid ${theme.colors.border}`
    },
    topRightContent: {
        padding: '0 20px 20px 20px',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    w260: {
        minWidth: 300
    },
    content: {
        padding: theme.spacing(4)
    },
    chip: {
        border: `1px solid ${hexToRgba(theme.colors.highlight, 0.3)}`,
        backgroundColor: hexToRgba(theme.colors.highlight, 0.1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: 28,
        width: 'fit-content',
        '& .MuiAvatar-root': {
            marginLeft: 2,
            color: theme.colors.white
        }
    },
    dropdownClassName: {
        maxWidth: 600
    },
    leftArrow: {
        verticalAlign: 'bottom',
        cursor: 'pointer',
        marginRight: 8
    },
    spinner: {
        height: 36,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function LeftContainer() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { tmId } = useParams();
    const [tmDetal, setTmDetal] = useState([]);

    const segments = useListTranslationMem() || [];
    const selectedWorkspaces = useSelectedWorkspaceByWorkspaceIds(tmDetal?.workspaceIds);

    React.useEffect(() => {
        dispatch(
            tmActions.getTM({
                tmId,
                successCallback: ({ tm }) => {
                    setTmDetal(tm);
                },
                errorCallback: () => {
                    console.log('fetch getTM failed');
                }
            })
        );
        dispatch(
            tmActions.fetchTMSources({
                tmId,
                successCallback: () => {
                    console.log('fetch sources success');
                },
                errorCallback: () => {
                    console.log('fetch sources failed');
                }
            })
        );
    }, [dispatch, tmId]);

    return (
        <Grid item className={classes.leftContent}>
            <Grid container direction={'column'} wrap="nowrap" className={classes.topLeftContent}>
                <Grid item>
                    <Typography variant="body1" className={classes.name}>
                        {tmDetal?.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.text}>
                        {t(`last_modified`)} <strong> {getFriendlyDate(tmDetal?.alteredTime)}</strong>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction={'column'} wrap="nowrap" className={classes.bottomLeftContent}>
                <Grid item>
                    <Typography variant="body1" className={classes.text}>
                        <strong>
                            {t(`total_segment`)} {segments?.totalSegment}
                        </strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" className={classes.text}>
                        <strong>{t(`assign_project`)}</strong>
                    </Typography>
                </Grid>
                <Grid item container>
                    {!isEmpty(selectedWorkspaces) &&
                        selectedWorkspaces.map(option => (
                            <Chip
                                size="small"
                                key={option.value}
                                // onDelete={() => handleDelete(option)}
                                className={classes.chip}
                                label={option.label}
                                variant="outlined"
                                color="inherit"
                            />
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

function EditorTMPage() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { tmId } = useParams();

    const sources = useTmSourcesByTmId({ tmId });
    const sourceSeleced = useTmSourceSelected() || null;
    const targetsSeleced = useTmTargetsSelected() || [];

    const sourceOptions = React.useMemo(() => {
        return sources?.map(source => {
            const lang = SUPPORTED_LANGUAGES.find(lang => lang.id === source);
            return {
                ...source,
                label: `${lang?.name} - ${source}`,
                value: source,
                languageCountry: lang?.name,
                icon: () => <CountryFlag languageCountryCode={source} />
            };
        });
    }, [sources]);

    const [source, setSource] = React.useState(sourceSeleced);
    const [targets, setTarget] = React.useState(targetsSeleced);
    const [_targets, _setTarget] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const onBack = React.useCallback(() => {
        history.push('/localization-settings/tm');
    }, [history]);

    React.useEffect(() => {
        dispatch(
            tmActions.fetchCurrentCompanyTMs({
                successCallback: result => {},
                errorCallback: () => {
                    console.log('fetchCurrentCompanyTMs failed');
                }
            })
        );

        dispatch(
            workspaceActions.fetchWorkspaces({
                successCallback: () => {
                    console.log('fetch projects success');
                },
                errorCallback: () => {
                    console.log('failed to fetch project');
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        setIsLoading(true);
        if (source) {
            dispatch(
                tmActions.fetchTMTarget({
                    tmId,
                    source: source.value,
                    successCallback: targets => {
                        _setTarget(targets);
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                    }
                })
            );
        }
    }, [dispatch, source, tmId]);

    const targetOptions = React.useMemo(() => {
        return _targets?.map(target => {
            const lang = SUPPORTED_LANGUAGES.find(lang => lang.id === target);
            return {
                ...target,
                label: `${lang?.name} - ${target}`,
                value: target,
                languageCountry: lang?.name,
                icon: () => <CountryFlag languageCountryCode={target} />
            };
        });
    }, [_targets]);

    const handleSourceChange = option => {
        if (source?.value === option?.value) return;
        dispatch(tmActions.setSelectSourceTm(option));
        setSource(option);
        setTarget([]);
    };

    const handleTargetChange = React.useCallback(
        option => {
            dispatch(tmActions.setSelectTargetsTm(option));
            setTarget(option);
        },
        [dispatch]
    );

    const targetLenght = React.useMemo(() => {
        return targetOptions?.reduce((arr, o) => {
            return arr.concat(o);
        }, [])?.length;
    }, [targetOptions]);

    const isSelectTarget = React.useMemo(() => {
        return targets?.length && targets?.length === targetLenght;
    }, [targets, targetLenght]);

    return (
        <Grid container wrap="nowrap" className={classes.root}>
            <LeftContainer />
            <Grid item className={classes.rightContent}>
                <Grid item container alignItems="center" style={{ marginTop: '18px' }}>
                    <Grid item>
                        <ArrowLeft2SVG className={classes.leftArrow} size="medium" color="#78778B" onClick={onBack} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">{'Editor TM'}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" className={classes.topRightContent} spacing={2}>
                    <Grid item className={classes.w260}>
                        <Grid item className={classes.text}>
                            <Typography variant="body1">{t(`select_source_language`)}</Typography>
                        </Grid>
                        <Grid item className={classes.w260}>
                            <LDBasePortal
                                ddPlaceholder={t('placeholder_select_source_language')}
                                menuPlaceholder={t('placeholder_find_source_language')}
                                options={sourceOptions}
                                onChange={handleSourceChange}
                                defaultValue={source}
                                dropdownClassName={classes.dropdownClassName}
                                isMulti={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.w260} style={{ marginLeft: 32 }}>
                        <Grid item className={classes.text}>
                            <Typography variant="body1">{t(`select_target_language`)}</Typography>
                        </Grid>
                        <Grid item className={classes.w260}>
                            <LDBasePortal
                                ddPlaceholder={t('placeholder_select_targets_language')}
                                menuPlaceholder={t('placeholder_find_targets_language')}
                                options={targetOptions}
                                onChange={handleTargetChange}
                                defaultValue={targets}
                                dropdownClassName={classes.dropdownClassName}
                                isMulti={true}
                                isAll={targetOptions?.length > 1}
                                onSelectAll={setOpenState => {
                                    if (isSelectTarget) {
                                        setTarget([]);
                                        dispatch(tmActions.setSelectTargetsTm([]));
                                    } else {
                                        const allTarget = targetOptions?.reduce((a, opt) => {
                                            a = a?.concat(opt);
                                            return a;
                                        }, []);
                                        setTarget(allTarget);
                                        dispatch(tmActions.setSelectTargetsTm(allTarget));
                                    }
                                    setOpenState(false);
                                }}
                                selectAllText={isSelectTarget ? t(`un_select_all`) : t(`select_all`)}
                                isDisabled={!source ? true : false}
                                customNode={
                                    isLoading && (
                                        <Typography variant="caption" style={{ textAlign: 'center', display: 'block' }}>
                                            Loading...
                                        </Typography>
                                    )
                                }
                                hideOverflowSelectedOptions={true}
                                itemShowOverflow={1}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <TabbleTmDetail />
            </Grid>
        </Grid>
    );
}

export default React.memo(EditorTMPage);
