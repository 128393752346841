import { RECORDS_RENDER } from 'const/gridUI';
import deepmerge from 'deepmerge';
import cloneDeep from 'lodash/cloneDeep';
import * as types from '../types';

const handler = {
    // FILTER

    [types.CREATE_VIEW_FILTER](state, { payload }) {
        let { filter } = payload;
        let { viewFilters } = state;
        let newViewFilters = [...viewFilters, filter];
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },
    [types.CREATE_VIEW_FILTER_FAILED](state, { payload }) {
        let { filterId } = payload;
        let { viewFilters } = state;
        let newViewFilters = [...viewFilters].filter(filter => filter.id !== filterId);
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    [types.CREATE_VIEW_FILTER_SUCCESS](state, { payload }) {
        let { oldFilterId, newFilter, sourceId } = payload;
        let { viewFilters } = state;
        let newViewFilters = [...viewFilters].map(filter => {
            if (filter.id === sourceId) {
                filter = { ...filter, groupId: newFilter.groupId };
            }
            if (filter.id === oldFilterId) {
                filter = newFilter;
            }
            return filter;
        });
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    [types.DELETE_VIEW_FILTER](state, action) {
        let { filterId } = action.payload;
        let { viewFilters } = state;
        let newViewFilters = viewFilters.map(filter => {
            if (filter.id === filterId) {
                filter.isDeleted = true;
            }
            return filter;
        });
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },
    [types.DELETE_VIEW_FILTER_FAILED](state, action) {
        let { filterId } = action.payload;
        let { viewFilters } = state;
        let newViewFilters = viewFilters.map(filter => {
            if (filter.id === filterId) {
                filter.isDeleted = false;
            }
            return filter;
        });
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },
    [types.DELETE_VIEW_FILTER_SUCCESS](state, action) {
        let { filterId } = action.payload;
        let { viewFilters } = state;
        let newViewFilters = viewFilters.filter(filter => filter?.id !== filterId);
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    [types.UPDATE_VIEW_FILTER_SUCCESS](state, action) {
        let { filterId, newFilter } = action.payload;
        const { viewFilters } = state;
        let newViewFilters = viewFilters.map(filter => {
            if (filter.id === filterId) {
                return newFilter;
            }
            return filter;
        });
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    [types.UPDATE_VIEW_FILTER_ID_AFTER_CREATE](state, action) {
        let { filter, oldFilterId } = action.payload;
        let viewFilters = cloneDeep(state.viewFilters);

        let newViewFilters = viewFilters.map(each => {
            if (each.id === oldFilterId) {
                return filter;
            }
            return each;
        });

        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    [types.FETCH_VIEW_FILTERS_SUCCESS](state, action) {
        const { viewFilters } = action.payload;
        return {
            ...state,
            viewFilters
        };
    },

    [types.UPDATE_VIEW_FILTER_ONLY](state, action) {
        let { filterId, newFilter } = action.payload;
        let newViewFilters = state.viewFilters.map(each => {
            if (each.id === filterId) {
                return {
                    oldValues: each.values,
                    ...newFilter
                };
            }
            return each;
        });

        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    [types.UPDATE_RECORDS_AFTER_FILTER](state, action) {
        const { data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters } = action.payload;
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
        let newData = deepmerge({ ...state.data }, data, { arrayMerge: overwriteMerge });
        return {
            ...state,
            data: newData,
            totalRecords,
            rows,
            recordMetaData,
            ROW_START_INDEX: 0,
            ROW_STOP_INDEX: RECORDS_RENDER,
            totalRecordsWithoutFilters
        };
    },
    [types.DELETE_VIEW_FILTERS_AFTER_COLUMN_IS_DELETED_SOCKET](state, action) {
        const { newViewFilters } = action.payload;
        return {
            ...state,
            viewFilters: newViewFilters
        };
    },

    // SORT

    [types.UPDATE_RECORDS_AFTER_SORT](state, action) {
        const { data, totalRecords, rows, recordMetaData, totalRecordsWithoutFilters } = action.payload;
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
        let newData = deepmerge({ ...state.data }, data, { arrayMerge: overwriteMerge });

        return {
            ...state,
            data: newData,
            totalRecords,
            rows,
            recordMetaData,
            ROW_START_INDEX: 0,
            ROW_STOP_INDEX: RECORDS_RENDER,
            totalRecordsWithoutFilters
        };
    },

    [types.FETCH_VIEW_SORTS_SUCCESS](state, action) {
        const { viewSorts } = action.payload;
        return {
            ...state,
            viewSorts
        };
    },

    [types.CREATE_VIEW_SORT](state, action) {
        let { sortOrder } = action.payload;
        let { viewSorts } = state;
        let newViewSorts = [...viewSorts, { ...sortOrder, oldValue: sortOrder.direction }];

        return {
            ...state,
            viewSorts: newViewSorts
        };
    },

    [types.DELETE_VIEW_SORT](state, action) {
        let { sortOrderId } = action.payload;
        let { viewSorts } = state;
        let newViewSorts = viewSorts.filter(sort => sort.id !== sortOrderId);

        return {
            ...state,
            viewSorts: newViewSorts
        };
    },

    [types.UPDATE_VIEW_SORT](state, action) {
        let { sortOrderId, newSortOrder } = action.payload;
        const { viewSorts } = state;
        let newViewSorts = viewSorts.map(each => {
            if (each.id === sortOrderId) {
                return { oldValue: each.direction, ...newSortOrder };
            }
            return each;
        });
        return {
            ...state,
            viewSorts: newViewSorts
        };
    },
    [types.DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET](state, action) {
        const { newViewSorts } = action.payload;
        return {
            ...state,
            viewSorts: newViewSorts
        };
    },
    [types.CLEAR_QUICK_SORT_WHEN_VIEW_SORT_IS_UPDATED_SOCKET](state, { payload }) {
        const { columnId } = payload;
        const newQuickSorts = { ...state?.quickSorts };
        delete newQuickSorts[columnId];
        return {
            ...state,
            quickSorts: newQuickSorts
        };
    }
};

export default handler;
