import { getViewColumnsWithReorderAndLanguagePairs } from 'utils/gridUI/column';
import * as cellActions from './cell';
import now from 'performance-now';
import { sendManualTrack } from 'tracker';

export function navigateShiftRight(callback) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        let { rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = gridUI;
        const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });
        const maxColumnIndex = viewableColumns?.length - 1;
        const newColumnStopIndex = columnStopIndex + 1;

        if (columnStopIndex === maxColumnIndex) {
            callback && callback();
            return;
        }

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex: newColumnStopIndex,
                rowStopIndex
            })
        );
        callback && callback();
    };
}

export function navigateShiftLeft(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        let { rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = gridUI;
        const newColumnStopIndex = columnStopIndex - 1;

        if (columnStopIndex === 0) {
            callback && callback();
            return;
        }

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex: newColumnStopIndex,
                rowStopIndex
            })
        );
        callback && callback();
    };
}

export function navigateShiftDown(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        let { totalRecords, rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = gridUI;
        const newRowStopIndex = rowStopIndex + 1;

        if (rowStopIndex >= totalRecords - 1) {
            callback && callback();
            return;
        }

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex,
                rowStopIndex: newRowStopIndex
            })
        );

        callback && callback();
    };
}

export function navigateShiftUp(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        let { rowStopIndex, rowStartIndex, columnStopIndex, columnStartIndex } = gridUI;

        const newRowStopIndex = rowStopIndex - 1;
        if (rowStopIndex === 0) {
            callback && callback();
            return;
        }

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex,
                columnStartIndex,
                columnStopIndex,
                rowStopIndex: newRowStopIndex
            })
        );
        callback && callback();
    };
}

export function navigatePageUp(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tableInfo, columnStartIndex } = gridUI;
        const { gridRef } = tableInfo;
        const { _rowStartIndex, _rowStopIndex } = gridRef;

        if (_rowStartIndex <= 0) {
            callback && callback();
            return;
        }
        const items = Math.max(_rowStopIndex - _rowStartIndex);

        const { _rowStartIndex: updatedRowStartIndex } = gridRef;
        const nextRowIndex = Math.max(0, updatedRowStartIndex - (items - 2));
        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex: Math.max(nextRowIndex, 0),
                rowStopIndex: Math.max(nextRowIndex, 0),
                columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );
        callback && callback();
    };
}

export function navigatePageDown(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { tableInfo, totalRecords, columnStartIndex, rowHeight } = gridUI;
        const { gridRef, scroll } = tableInfo;
        const { _rowStopIndex, _rowStartIndex, state } = gridRef;

        if (_rowStopIndex >= totalRecords) {
            callback && callback();
            return;
        }

        const items = Math.max(_rowStopIndex - _rowStartIndex);

        const nextRowIndex = Math.max(0, _rowStartIndex + items);

        scroll({ scrollTop: (_rowStopIndex + items) * rowHeight, scrollLeft: state?.scrollLeft });

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex: Math.min(nextRowIndex, totalRecords - 1),
                rowStopIndex: Math.min(nextRowIndex, totalRecords - 1),
                columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );
        callback && callback();
    };
}

export function navigateLastColumn(callback) {
    return async function(dispatch, getState) {
        const { gridUI, auth } = getState();
        const { fixedColumnCount, tableInfo, rowStartIndex } = gridUI;
        const { gridRef } = tableInfo;
        const { _columnStopIndex } = gridRef;
        const viewableColumns = getViewColumnsWithReorderAndLanguagePairs({ gridUI, auth });
        const possibleColumns = viewableColumns?.slice(fixedColumnCount);
        const start = now();

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex,
                rowStopIndex: rowStartIndex,
                columnStartIndex: viewableColumns?.length - 1,
                columnStopIndex: viewableColumns?.length - 1
            })
        );

        callback && callback();

        setTimeout(() => {
            const end = now();
            const time = (end - start).toFixed(3);

            sendManualTrack({
                type: `Scroll Grid to Right`,
                customData: {
                    render_time: time
                }
            });
        }, 0);

        if (_columnStopIndex + fixedColumnCount >= possibleColumns.length - 1) return;
    };
}

export function navigateFirstColumn(callback) {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { rowStartIndex } = gridUI;
        const start = now();
        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex,
                rowStopIndex: rowStartIndex,
                columnStartIndex: 0,
                columnStopIndex: 0
            })
        );

        callback && callback();

        setTimeout(() => {
            const end = now();
            const time = (end - start).toFixed(3);

            sendManualTrack({
                type: `Scroll Grid to Left`,
                customData: {
                    render_time: time
                }
            });
        }, 0);
    };
}

export function navigateFirstRow() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { columnStartIndex } = gridUI;
        const start = now();
        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex: 0,
                rowStopIndex: 0,
                columnStartIndex: columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );
        setTimeout(() => {
            const end = now();
            const time = (end - start).toFixed(3);

            sendManualTrack({
                type: `Scroll Up Grid`,
                customData: {
                    render_time: time
                }
            });
        }, 0);
    };
}

export function navigateLastRow() {
    return async function(dispatch, getState) {
        const { gridUI } = getState();
        const { totalRecords, columnStartIndex } = gridUI;
        const start = now();

        const nextRowIndex = totalRecords - 1;

        dispatch(
            cellActions.selectRangeCell({
                rowStartIndex: nextRowIndex,
                rowStopIndex: nextRowIndex,
                columnStartIndex: columnStartIndex,
                columnStopIndex: columnStartIndex
            })
        );

        setTimeout(() => {
            const end = now();
            const time = (end - start).toFixed(3);

            sendManualTrack({
                type: `Scroll Down Grid`,
                customData: {
                    render_time: time
                }
            });
        }, 0);
    };
}
