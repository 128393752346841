import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { checkContainClassName, checkContainId } from 'utils/clickAway';
import { isKbEscape, isKbEnter, isKbTab, isKbShiftTab } from 'utils/keyboard';
import * as gridActions from 'gridUI/actions';
import { Grid } from '@material-ui/core';
import ViewCellInfo from './ViewCellInfo';
import DateTimeInput from 'components/datetime/Base';
import { convertFormatDateTime } from 'utils/gridUI/formatData';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { getCellBackgroundByHex } from 'utils/color';
import { ROW_HEIGHT, BORDER_HIGHLIGHT } from 'const/gridUI';
import { Portal } from 'react-overlays';

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children}</Portal>;
};

function DateEdit({
    columnId,
    value: cellValue,
    rowId,
    width,
    height,
    top,
    left,
    rowIndex,
    background,
    rowHeight,
    fillColorBlindness,
    columnIndex,
    originalValue
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const convertDateTime = convertFormatDateTime(cellValue);
    const [dateTime, setDateTime] = useState(convertDateTime);
    const isEnterAlready = useRef(false);
    const rootRef = useRef();
    const dateRef = useRef();

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    useEffect(() => {
        if (dateRef.current?.input) {
            dateRef.current.input.focus({ preventScroll: true });
        }
    }, []);

    const isSameDate = React.useCallback(() => {
        return dateTime === convertDateTime;
    }, [convertDateTime, dateTime]);

    useEffect(() => {
        const keyDownHandler = e => {
            if (isKbEscape(e)) {
                dispatch(gridActions.cancelCellEdit());
            }

            if (isKbEnter(e)) {
                if (isSameDate()) {
                    dispatch(gridActions.cancelCellEdit());
                    dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
                    return;
                }
                isEnterAlready.current = true;
                dispatch(
                    gridActions.cellClickAwayAndGoNextRow({
                        value: dateTime,
                        rowId,
                        columnId,
                        rowIndex,
                        columnIndex
                    })
                );
            }

            if (isKbTab(e)) {
                e.preventDefault();
                if (isSameDate()) {
                    dispatch(gridActions.cancelCellEdit());
                    dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                    return;
                }
                isEnterAlready.current = true;
                dispatch(
                    gridActions.cellClickAwayAndGoNextColumn({
                        value: dateTime,
                        rowId,
                        columnId,
                        rowIndex,
                        columnIndex
                    })
                );
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                if (isSameDate()) {
                    dispatch(gridActions.cancelCellEdit());
                    dispatch(
                        gridActions.moveCellToPreviousColumn({
                            rowIndex,
                            columnIndex
                        })
                    );
                    return;
                }
                isEnterAlready.current = true;
                dispatch(
                    gridActions.cellClickAwayAndGoPreviousColumn({
                        value: dateTime,
                        columnId,
                        rowId,
                        rowIndex,
                        columnIndex
                    })
                );
            }
        };
        document.addEventListener('keydown', keyDownHandler, true);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, true);
        };
    }, [dispatch, isSameDate, dateTime, columnId, rowId, rowIndex, columnIndex]);

    const handleClickAway = e => {
        if (checkContainClassName(e, 'react-datepicker-popper')) return;
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        if (isSameDate()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameDate()) {
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value: dateTime,
                    columnId,
                    rowId
                })
            );
        }
    };

    const handleDateTimeChange = date => {
        setDateTime(date);
    };

    return (
        <Grid ref={rootRef} className={'datepicker'} style={{ position: 'relative', height }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <Grid
                className={left > 0 ? 'right-cell' : ''}
                container
                justifyContent="space-between"
                alignItems={'center'}
                style={{
                    width,
                    height: ROW_HEIGHT,
                    border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white,
                    padding: '0 0.5rem'
                }}
            >
                <DateTimeInput
                    dateRef={dateRef}
                    selectedDate={dateTime}
                    utcOffset={0}
                    onEnter={handleDateTimeChange}
                    className={'bg-transparent'}
                    autoFocus={false}
                    popperContainer={CalendarContainer}
                    popperProps={{ className: 'popper-date-cell-edit' }}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(DateEdit);
