import { enqueueSnackbar } from 'notifier/actions';
import { replaceSearchApi, searchRecordsApi } from 'services/view';
import * as types from '../types';
import isEmpty from 'lodash/isEmpty';
import * as dataActions from './data';
import { DATA_QUERY_OPTIONS, MAX_SELECTION_RECORDS, RANGE_TYPES } from 'const/gridUI';

export function pinnedSearch() {
    return {
        type: types.PINNED_SEARCH
    };
}

export function unPinnedSearch() {
    return {
        type: types.UNPINNED_SEARCH
    };
}

export function openReplaceSearch() {
    return {
        type: types.OPEN_REPLACE_SEARCH
    };
}

export function closeReplaceSearch() {
    return {
        type: types.CLOSE_REPLACE_SEARCH
    };
}

export function searchRecordsFnc({ body, area, successCallback, errorCallback }) {
    return async (dispatch, getState) => {
        try {
            body.columnIds = [];
            body.recordIds = [];

            if (area === 'RANGES') {
                const { gridUI, auth } = getState();

                let {
                    rowIndexMap,
                    rowsRangeIndexes,
                    rowStartIndex,
                    rowStopIndex,
                    columnStartIndex,
                    columnStopIndex,
                    columnsSelected,
                    searchRange
                } = gridUI;

                const {
                    columnIds,
                    recordIds,
                    isOverRecordLimit,
                    totalSelectedRecords
                } = await dataActions.getRangeData({
                    auth,
                    gridUI,
                    dataOptions: [DATA_QUERY_OPTIONS.DATA],
                    type: RANGE_TYPES.INDEX,
                    isUsingSearchRange: !isEmpty(searchRange)
                });

                if (isOverRecordLimit) {
                    dispatch(
                        enqueueSnackbar({
                            type: 'info',
                            message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                        })
                    );
                    errorCallback && errorCallback();
                    return;
                }

                body.columnIds = columnIds;
                body.recordIds = recordIds;

                if (isEmpty(searchRange)) {
                    dispatch(
                        setSearchRange({
                            rowIndexMap,
                            rowsRangeIndexes,
                            rowStartIndex,
                            rowStopIndex,
                            columnStartIndex,
                            columnStopIndex,
                            columnsSelected
                        })
                    );
                }
            }

            const responseData = await searchRecordsApi(body);
            successCallback && successCallback(responseData);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(error);
        }
    };
}

export function replaceSearchText({ dbId, viewId, body, isReplaceSingle, area, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        try {
            if (area === 'RANGES' && !isReplaceSingle) {
                const { gridUI, auth } = getState();

                const {
                    columnIds,
                    recordIds,
                    isOverRecordLimit,
                    totalSelectedRecords
                } = await dataActions.getRangeData({
                    auth,
                    gridUI,
                    dataOptions: [DATA_QUERY_OPTIONS.DATA],
                    type: RANGE_TYPES.INDEX,
                    isUsingSearchRange: true
                });

                if (isOverRecordLimit) {
                    dispatch(
                        enqueueSnackbar({
                            type: 'info',
                            message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                        })
                    );
                    errorCallback && errorCallback();
                    return;
                }

                body.columnIds = columnIds;
                body.recordIds = recordIds;
            }
            const response = await replaceSearchApi({ dbId, viewId, body });
            successCallback && successCallback(response);
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function setReplacingText({ replacing, records, replacedTotal, status, failedMessage }) {
    return function(dispatch, getState) {
        dispatch({
            type: types.SET_REPLACING_TEXT,
            payload: replacing
        });
        const { gridUI } = getState();
        const { searchRecords, replacingType } = gridUI;
        if (!isEmpty(records)) {
            const { id: recordId, cells } = records[0];
            if (!isEmpty(cells)) {
                const { columnId, value } = cells[0];
                const recordIdx = searchRecords.findIndex(r => r.recordId === recordId);
                if (recordIdx > -1) {
                    const cellIdx = searchRecords[recordIdx].cells?.findIndex(c => c.columnId === columnId);
                    if (cellIdx > -1) {
                        searchRecords[recordIdx].cells[cellIdx].data = value;
                        dispatch(setSearchRecords([...searchRecords]));
                    }
                }
            }
        }
        if (!replacing) {
            if (status === 'FAILED') {
                if (failedMessage) {
                    dispatch(
                        enqueueSnackbar({
                            message: failedMessage,
                            type: 'info'
                        })
                    );
                }
                return;
            }
            if (replacingType.type === 'one') {
                const { message } = replacingType;
                if (message) {
                    dispatch(
                        enqueueSnackbar({
                            message,
                            type: 'info'
                        })
                    );
                    return;
                }
                dispatch(
                    enqueueSnackbar({
                        message: 'Replaced',
                        type: 'info'
                    })
                );
                return;
            }
            dispatch(setSuccessReplaceAll({ show: true, total: replacedTotal || 0 }));
            dispatch(setSearchRecords([]));
            dispatch(
                enqueueSnackbar({
                    message: 'Replaced',
                    type: 'info'
                })
            );
        }
    };
}

export function setSearchRecords(payload) {
    return {
        type: types.SET_SEARCH_RECORDS,
        payload
    };
}

export function setSuccessReplaceAll(payload) {
    return {
        type: types.SET_SUCCESS_REPLACE_ALL,
        payload
    };
}

export function setReplacingType(payload) {
    return {
        type: types.SET_REPLACING_TYPE,
        payload
    };
}

export function clearSearchState() {
    return {
        type: types.SET_CLEAR_SEARCH_STATE
    };
}

export function setIsShowSearchRange(payload) {
    return {
        type: types.SET_SHOW_SEARCH_RANGE,
        payload
    };
}

export function setSearchRange(payload) {
    return {
        type: types.SET_SEARCH_RANGE,
        payload
    };
}
