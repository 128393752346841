import { useSelector } from 'react-redux';
import { useGridDetail } from '../grid';
import { DISABLED_CREATE_RECORD_GRID_TYPES } from 'const/gridUI';
import { getRecordIdsInRowRangeIndexes, getRowSelectedIndexes, getTotalSelectedRowsByRange } from 'utils/gridUI/range';
import { between } from 'utils/usage';

export function useRows() {
    return useSelector(state => state.gridUI.rows);
}

// export function useRowsSelected() {
//     return useSelector(
//         state => state.gridUI.rowsSelected,
//         (prev, current) => {
//             return JSON.stringify(prev) === JSON.stringify(current);
//         }
//     );
// }

export function useIsCreatingRecord() {
    return useSelector(state => state.gridUI.isCreatingRecord);
}

export function useIsFetchingMore() {
    return useSelector(state => state.gridUI.isFetchingMore);
}

export function useTotalRecords() {
    return useSelector(state => state.gridUI.totalRecords);
}

export function useTotalRecordsWithoutFilter() {
    return useSelector(state => state.gridUI.totalRecordsWithoutFilters);
}

export function useSectionRowStartIndex() {
    return useSelector(state => state.gridUI.ROW_START_INDEX);
}

export function useSectionRowStopIndex() {
    return useSelector(state => state.gridUI.ROW_STOP_INDEX);
}

export function useWindowRowStartIndex() {
    return useSelector(state => state.gridUI.rowStartIndex);
}

export function useWindowRowStopIndex() {
    return useSelector(state => state.gridUI.rowStopIndex);
}

export function useReorderBeforeRecordId() {
    return useSelector(state => state.gridUI.beforeRecordId);
}

export function useReorderAfterRecordId() {
    return useSelector(state => state.gridUI.afterRecordId);
}

export function useReorderCurrentRecordId() {
    return useSelector(state => state.gridUI.currentRecordId);
}

export function useCurrentRowIndex() {
    return useSelector(state => state.gridUI.currentRowIndex);
}

export function useReorderHighlight() {
    return useSelector(state => state.gridUI.reorderHighlight);
}

export function useRowIdByRowIndex({ rowIndex }) {
    const ROW_START_INDEX = useSectionRowStartIndex();
    const rows = useRows();
    return rows?.[rowIndex - ROW_START_INDEX];
}

export function useIsLastRow({ rowIndex }) {
    const totalRecords = useTotalRecords();
    return rowIndex === totalRecords - 1;
}

export function useIsDraggingRows() {
    return useSelector(state => state.gridUI.isDraggingRows);
}

export function useRowHeightStore() {
    return useSelector(state => state.gridUI.isDraggingRows);
}

export function useRowHeight() {
    return useSelector(state => state.gridUI.rowHeight);
}

export function useIsDisabledCreateRecord({ dbId, gridId }) {
    const gridDetail = useGridDetail({ dbId, gridId });
    return DISABLED_CREATE_RECORD_GRID_TYPES?.includes(gridDetail?.type);
}

export function useRowsRangeIndexes() {
    return useSelector(
        state => state?.gridUI?.rowsRangeIndexes,
        (prev, current) => {
            return JSON.stringify(prev) === JSON.stringify(current);
        }
    );
}

export function useRowSelectedIndexes() {
    const rowsRangeIndexes = useRowsRangeIndexes();
    return getRowSelectedIndexes({ rangeIndexes: rowsRangeIndexes });
}

export function useIsDeleteRecordState() {
    return useSelector(state => state?.gridUI.isDeletingRecords);
}

export function useSelectedRecordIdsByRange() {
    const rowsRangeIndexes = useRowsRangeIndexes();
    const ROW_START_INDEX = useSectionRowStartIndex();
    const rows = useRows();
    return getRecordIdsInRowRangeIndexes({ ranges: rowsRangeIndexes, ROW_START_INDEX, rows });
}

export function useTotalSelectedRowsByRange() {
    return useSelector(state => {
        const { gridUI } = state;
        const { rowsRangeIndexes } = gridUI;
        return getTotalSelectedRowsByRange(rowsRangeIndexes);
    });
}

export function useIsRowInRange({ rowIndex }) {
    return useSelector(state => {
        const { gridUI } = state;
        const { rowStartIndex, rowStopIndex, rowsRangeIndexes } = gridUI;
        return (
            rowsRangeIndexes?.some(range => {
                const start = range?.[0];
                const end = range?.[1] || start;
                return between(rowIndex, start, end);
            }) || between(rowIndex, Math.min(rowStartIndex, rowStopIndex), Math.max(rowStartIndex, rowStopIndex))
        );
    });
}

export function useIsRowSelected({ rowIndex }) {
    return useSelector(state => {
        const { gridUI } = state;
        const { rowsRangeIndexes } = gridUI;
        return rowsRangeIndexes?.some(range => {
            const start = range?.[0];
            const end = range?.[1] || start;
            return between(rowIndex, start, end);
        });
    });
}

export function useRowDataById(rowId) {
    return useSelector(state => {
        const { gridUI } = state;
        const { data } = gridUI;
        return data?.[rowId];
    });
}
