import React from 'react';

function GlobalUniqueRecordIdSVG({ ...rest }) {
    return (
        <svg width="38" height="20" viewBox="0 0 38 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="0.5" y="0.5" width="37" height="19" rx="9.5" fill="#92C255" stroke="#C9E1AA" />
            <path
                d="M16.3 16.3167C15.4222 16.2722 14.6 16.0667 13.8333 15.7C13.0667 15.3333 12.4 14.8528 11.8333 14.2583C11.2667 13.6639 10.8194 12.9722 10.4917 12.1833C10.1639 11.3944 10 10.5556 10 9.66667C10 8.74444 10.175 7.87778 10.525 7.06667C10.875 6.25556 11.35 5.55 11.95 4.95C12.55 4.35 13.2556 3.875 14.0667 3.525C14.8778 3.175 15.7444 3 16.6667 3C18.3222 3 19.7611 3.52222 20.9833 4.56667C22.2056 5.61111 22.9556 6.92222 23.2333 8.5H22.2167C22.0278 7.56667 21.6333 6.73333 21.0333 6C20.4333 5.26667 19.6833 4.71667 18.7833 4.35V4.65C18.7833 5.03889 18.65 5.37778 18.3833 5.66667C18.1167 5.95556 17.7889 6.1 17.4 6.1H15.95V7.55C15.95 7.73889 15.875 7.89444 15.725 8.01667C15.575 8.13889 15.4056 8.2 15.2167 8.2H13.8333V9.66667H15.6667V11.75H14.55L11.15 8.35C11.0944 8.57222 11.0556 8.79167 11.0333 9.00833C11.0111 9.225 11 9.44444 11 9.66667C11 11.1667 11.5056 12.4611 12.5167 13.55C13.5278 14.6389 14.7889 15.2278 16.3 15.3167V16.3167Z"
                fill="white"
            />
            <g clipPath="url(#clip0_2826_13888)">
                <path
                    d="M19.4879 16.2833H19.5529V16.2183V15.7168V15.6649L19.5023 15.6534L18.7429 15.4816V10.0787L19.503 9.89769L19.5529 9.88579V9.83445V9.33301V9.26801H19.4879H17H16.935V9.33301V9.83445V9.88579L16.9849 9.89769L17.745 10.0787V15.4816L16.9857 15.6534L16.935 15.6649V15.7168V16.2183V16.2833H17H19.4879ZM25.4229 15.3884L25.4229 15.3883C26.0766 14.7808 26.3983 13.8834 26.3983 12.7081C26.3983 11.9611 26.2587 11.3323 25.9755 10.8251C25.6928 10.3124 25.2911 9.92381 24.7716 9.66075C24.2528 9.39808 23.6412 9.26801 22.9389 9.26801H20.8174H20.7524V9.33301V16.2183V16.2833H20.8174H22.7364C23.879 16.2833 24.7774 15.9877 25.4229 15.3884ZM24.6876 10.7686L24.6876 10.7686L24.6883 10.7692C25.1263 11.1764 25.3522 11.8272 25.3522 12.7371C25.3522 13.6396 25.1219 14.3046 24.6726 14.7446C24.2231 15.1849 23.5432 15.4107 22.6207 15.4107H21.7503V10.1405H22.8136C23.6318 10.1405 24.2533 10.3529 24.6876 10.7686Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.13"
                />
            </g>
            <defs>
                <clipPath id="clip0_2826_13888">
                    <rect width="10.6667" height="10.6667" fill="white" transform="translate(16.334 7.33301)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(GlobalUniqueRecordIdSVG);
