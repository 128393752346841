import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Table from 'components/tables/Table';
import ButtonBase from 'components/button/Base';
import { AutoSizer } from 'react-virtualized-dn';
import { beautifyPrice } from 'utils/payment';
import { useDispatch } from 'react-redux';
import * as paymentActions from 'payment/actions';
// import { pdf } from '@react-pdf/renderer';
// import Invoice from 'payment/components/billing/Invoice';
// import { saveAs } from 'file-saver';
// import { useBillingInfo } from 'hooks/payment';
import { downloadAllPdfInvoiceFortnoxApi, getPdfInvoiceApi } from 'services/payment';
import { sendManualTrack } from 'tracker';
import TableFortnox from './TableFortnox';
import DownloadSVG from 'assets/images/svg/DownloadSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { height: '65vh', width: '100%' },
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    subRow: {
        padding: '20px 0',
        overflow: 'auto'
    },
    px72: {
        padding: '0 72px'
    },
    subTable: {
        padding: '20px 50px'
    },
    downloadAll: {
        color: '#4A91E2',
        cursor: 'pointer'
    }
}));

function BillingHistoryTable({ billings = [] }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    // const billingInfo = useBillingInfo();

    useEffect(() => {
        dispatch(
            paymentActions.fetchBillingInfo({
                errorCallback: () => {
                    console.log('fetchBillingInfo success');
                },
                successCallback: () => {
                    console.log('fetchBillingInfo failed');
                }
            })
        );
    }, [dispatch]);

    const [sorting, setSorting] = React.useState({});

    const data = React.useMemo(() => {
        const cloneData = [...billings];
        const { sortKey, sortType } = sorting;

        if (sortKey && sortType) {
            cloneData.sort((dataA, dataB) => {
                let valueA = dataA[sortKey];
                let valueB = dataB[sortKey];
                let sortVal = 0;

                valueA = valueA ? valueA.toLowerCase() : '';
                valueB = valueB ? valueB.toLowerCase() : '';

                if (valueA > valueB) {
                    sortVal = 1;
                }
                if (valueA < valueB) {
                    sortVal = -1;
                }
                if (sortVal !== 0 && sortType === 'desc') {
                    sortVal = sortVal * -1;
                }

                return sortVal;
            });
        }
        return cloneData;
    }, [sorting, billings]);

    const handleDownloadReceipt = rowData => {
        // const { month, year } = rowData;

        // const downloadPdf = async receiptInfo => {
        //     const blobPdf = await pdf(<Invoice receiptInfo={receiptInfo} billingInfo={billingInfo} />).toBlob();
        //     saveAs(blobPdf, `invoice_${month}_${year}.pdf`);
        // };

        // dispatch(
        //     paymentActions.fetchReceiptInfo({
        //         month,
        //         year,
        //         successCallback: receiptInfo => {
        //             console.log('fetchReceiptInfo success');
        //             downloadPdf(receiptInfo);
        //         },
        //         errorCallback: () => {
        //             console.log('fetchReceiptInfo failed');
        //         }
        //     })
        // );
        sendManualTrack({ type: 'Download Invoice' });
        const { id } = rowData;
        getPdfInvoiceApi(id);
    };

    const descriptionRenderer = ({ rowData }) => {
        const { month, year } = rowData;

        return (
            <Typography variant="body2">
                {t('monthly_statement')} {month}/{year}
            </Typography>
        );
    };

    const totalRenderer = ({ rowData }) => {
        const { totalAmount, currency } = rowData;
        const friendlyPrice = beautifyPrice({ unitAmount: totalAmount, currency });
        return <Typography variant="body2">{friendlyPrice}</Typography>;
    };

    const actionRenderer = ({ rowData }) => {
        return (
            <ButtonBase variant="text" width={64} size="small" onClick={() => handleDownloadReceipt(rowData)}>
                <DownloadSVG />
            </ButtonBase>
        );
    };

    const COLUMNS = [
        { label: '', dataKey: 'id', type: 'collapse', width: 40, cellRenderer: () => null, className: classes.center },
        {
            label: t('monthly_statement'),
            dataKey: 'description',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: descriptionRenderer
        },
        {
            label: t('total_amount_paid'),
            dataKey: 'total',
            width: 200,
            flexGrow: 1,
            sort: true,
            cellRenderer: totalRenderer
        },
        { label: '', dataKey: 'empty', width: 46, cellRenderer: () => null },
        { label: '', dataKey: 'action', width: 64, cellRenderer: actionRenderer }
    ];

    const handleRowClick = () => {};

    const handleOnSort = (sortKey, sortType) => {
        setSorting({
            sortKey,
            sortType
        });
    };

    const handleDownloadAll = useCallback(({ month, year }) => {
        sendManualTrack({ type: 'Download All Invoice Fortnox' });
        downloadAllPdfInvoiceFortnoxApi({ month, year });
    }, []);

    const rowExpandedRender = useCallback(
        props => {
            const { invoices = [], month, year } = props;
            return (
                <Grid container className={classes.subRow} direction="column" wrap="nowrap">
                    <Grid item>
                        <Grid container className={classes.px72} justify="space-between">
                            <Grid item>
                                <Typography variant="body1">{t('invoice_list')}</Typography>
                            </Grid>
                            <Grid item onClick={() => handleDownloadAll({ month, year })}>
                                <Typography variant="body2" className={classes.downloadAll}>
                                    {t('download_all')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.subTable}>
                        <TableFortnox data={invoices} />
                    </Grid>
                </Grid>
            );
        },
        [classes, handleDownloadAll, t]
    );

    return (
        <Grid container className={classes.root}>
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        data={data}
                        columns={COLUMNS}
                        width={width}
                        height={height}
                        onRowClick={handleRowClick}
                        onSort={handleOnSort}
                        rowExpandedRender={rowExpandedRender}
                    ></Table>
                )}
            </AutoSizer>
        </Grid>
    );
}

export default React.memo(BillingHistoryTable);
