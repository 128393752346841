import * as types from '../types';

const handler = {
    [types.SET_LIST_TERMS_BASE](state, { payload }) {
        return {
            ...state,
            listTermBases: payload
        };
    },
    [types.SET_DATA_FROM_TB](state, { payload }) {
        return {
            ...state,
            dataFromTB: payload
        };
    }
};

export default handler;
