import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ImageRender from 'gridUI/table/grid/components/Files';
import Date from 'gridUI/table/grid/components/Date';
import MultiSelect from 'gridUI/table/grid/components/MultiSelect';
import SingleSelect from 'gridUI/table/grid/components/SingleSelect';
import Ref from 'gridUI/table/grid/components/Ref';
import ReferenceSelect from 'gridUI/table/grid/components/ReferenceSelect';
import * as columnTypes from 'const/columnTypes';
import { CELL_PADDING, BORDER_HIGHLIGHT } from 'const/gridUI';
import { isSelectionColumnType } from 'utils/gridUI/column';
import PathTag from 'gridUI/table/grid/components/PathTag';
import { usePathTagTree, useRowHeight } from 'hooks/gridUI';

function checkTypeAndRender({ type, tree, rowHeight, ...rest }) {
    switch (type) {
        case columnTypes.FILES:
            return <ImageRender rowHeight={rowHeight} {...rest} />;
        case columnTypes.DATETIME:
            return <Date rowHeight={'auto'} {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelect rowHeight={'auto'} {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelect rowHeight={'auto'} {...rest} />;
        case columnTypes.REFERENCE:
            return <Ref rowHeight={'auto'} {...rest} />;
        case columnTypes.LOCALIZATION:
            return <ReferenceSelect rowHeight={'auto'} {...rest} />;
        case columnTypes.PATH_TAG:
            return <PathTag rowHeight={'auto'} {...rest} />;
        default:
            return null;
    }
}

function ViewCellOnly({ dimension, type, value, referencedColumnType, ...rest }) {
    const theme = useTheme();
    const tree = usePathTagTree();
    const rowHeight = useRowHeight();

    return (
        <Grid
            id={'viewCellOnly'}
            style={{
                width: dimension.width,
                height: isSelectionColumnType(type) ? 'auto' : dimension.height - 2 * BORDER_HIGHLIGHT,
                minHeight: rowHeight,
                border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                background: 'transparent',
                padding: CELL_PADDING,
                overflow: 'hidden'
            }}
        >
            {checkTypeAndRender({ type, value, tree, referencedColumnType, ...rest })}
        </Grid>
    );
}

ViewCellOnly.propTypes = {
    dimension: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    rowData: PropTypes.any
};

export default React.memo(ViewCellOnly);
