import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import BranchSVG from 'assets/images/svg/BranchSVG';
import classNames from 'classnames';

function BranchTag({ name, className }) {
    const theme = useTheme();
    return (
        <div
            className={classNames(
                `px-2.5 min-h-[24px] w-fit flex items-center gap-1 rounded bg-navy-pale border border-solitude`,
                className
            )}
        >
            <BranchSVG color={theme.palette.primary.main} />
            <p className="body2">{name}</p>
        </div>
    );
}
export default React.memo(BranchTag);
