import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useClickAwaitListener from 'hooks/useClickAwaitListener';

import TextDelete from './diffCheckComponents/delete/TextDelete';
import SingleSelectionDelete from './diffCheckComponents/delete/SingleSelection';
import MultipleSelectionDelete from './diffCheckComponents/delete/MultipleSelection';
import BooleanDelete from './diffCheckComponents/delete/Boolean';
import DatetimeDelete from './diffCheckComponents/delete/Datetime';
import FileDelete from './diffCheckComponents/delete/File';
import ReferenceDelete from './diffCheckComponents/delete/Reference';

import TextUpdate from './diffCheckComponents/update/TextUpdate';
import FileCheckDiff from './diffCheckComponents/update/FileCheckDiff';
import ReferenceUpdate from './diffCheckComponents/update/Reference';

import ComingSoon from './diffCheckComponents/ComingSoon';

import DiffTextWithOtherTypes from './components/diffType/Text';
import DiffDatetimeWithOtherTypes from './components/diffType/Datetime';
import DiffBooleanWithOtherTypes from './components/diffType/Boolean';
import DiffMultipleSelectionWithOtherTypes from './components/diffType/MultipleSelection';
import DiffSingleSelectionWithOtherTypes from './components/diffType/SingleSelection';
import DiffRefWithOtherTypes from './components/diffType/Ref';
import DiffFilesWithOtherTypes from './components/diffType/Files';
import * as columnTypes from 'const/columnTypes';
import { MAX_ROW_RESIZE, PREVIEW_STATUS } from 'const/gridUI';
import { getNumberDisplay } from 'utils/gridUI/cell';
import hexToRgba from 'hex-to-rgba';

import Conflict from './diffCheckComponents/conflict';
import Tooltip from 'components/tooltip/Base';
import RevertSVG from 'assets/images/svg/RevertSVG';
import PopperMenu from 'components/menus/Popper';
import ConfirmRevert from 'components/confirmBox/Small';
import LangStatus from 'components/langStatus';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import { useTranslation } from 'react-i18next';
import Checkbox from './diffCheckComponents/components/Checkbox';
import Datetime from './diffCheckComponents/components/Datetime';
import NumberCheckDiff from './diffCheckComponents/update/NumberCheckDiff';
import SingleSelectionCheckDiff from './diffCheckComponents/update/SingleSelectionCheckDiff';
import MultipleSelectionCheckDiff from './diffCheckComponents/update/MultipleSelectionCheckDiff';
import { useDependencyBlindness } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: 500,
        minWidth: 320,
        maxHeight: MAX_ROW_RESIZE,
        overflow: 'hidden auto'
    },
    fullWidth: {
        width: '100%',
        flex: 1
    },
    revertIcon: {
        marginLeft: theme.spacing(2),
        position: 'absolute',
        top: 12,
        right: 12
    },
    iconAction: {
        cursor: 'pointer'
    },
    convert: {
        paddingTop: 0,
        paddingBottom: 0
    },
    diffDependency: {
        padding: 3,
        borderRadius: 1,
        display: 'inline-flex'
    },
    dependencyStatus: {
        width: 3,
        height: 18,
        pointerEvents: 'none',
        borderRadius: 0
    },
    outOfDate: {
        background: hexToRgba(theme.colors.sun, 0.8)
    },
    upToDate: {
        background: hexToRgba(theme.colors.atlantis, 0.8)
    },
    notTranslated: {
        background: hexToRgba(theme.colors.brightRed, 0.8)
    },
    sourceContent: {
        padding: 12,
        paddingRight: 30,
        background: theme.colors.ghostwhite,
        maxHeight: '156px',
        minHeight: '156px',
        minWidth: '216px',
        borderRadius: 8,
        overflowY: 'auto',
        position: 'relative'
    }
}));

function RenderDiffOtherTypes({
    columnType,
    previousColumnType,
    data,
    previousData,
    options,
    customProperties,
    numberFormat,
    ...rest
}) {
    switch (columnType) {
        case columnTypes.SINGLE_LINE:
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.RICH_TEXT:
        case columnTypes.MARKDOWN:
        case columnTypes.TRANSLATION:
        case columnTypes.JSON_LD:
        case columnTypes.HTML:
        case columnTypes.YAML:
        case columnTypes.ALTERED_BY:
        case columnTypes.RECORD_ID:
        case columnTypes.FORMULA:
            return (
                <DiffTextWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );

        case columnTypes.NUMBER: {
            const displayData = getNumberDisplay({ numberFormat, data });
            return (
                <DiffTextWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={displayData}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        }

        case columnTypes.BOOLEAN:
            return (
                <DiffBooleanWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.DATETIME:
            return (
                <DiffDatetimeWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.SINGLE_SELECTION:
            return (
                <DiffSingleSelectionWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.MULTIPLE_SELECTIONS:
            return (
                <DiffMultipleSelectionWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.REFERENCE:
            return (
                <DiffRefWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        case columnTypes.FILES:
            return (
                <DiffFilesWithOtherTypes
                    previousColumnType={previousColumnType}
                    data={data}
                    options={options}
                    previousData={previousData}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        default:
            return (
                <ComingSoon
                    previousColumnType={previousColumnType}
                    columnType={columnType}
                    isSameType={previousColumnType === columnType}
                    {...rest}
                />
            );
    }
}

function DiffCheckUIBeforeAfter({
    status,
    columnType,
    previousColumnType,
    data,
    previousData,
    handleClickAway,
    options = [],
    customProperties = {},
    numberFormat,
    showRevertIcon,
    onRevert,
    onRevertBefore,
    previousDependencyStatus,
    dependencyStatus,
    sourceStatus,
    previousSourceStatus,
    columnId,
    ...rest
}) {
    const classes = useStyles();
    const rootRef = React.useRef();
    const [revertAnchorEl, setRevertAnchorEl] = React.useState(null);
    const [revertAnchorElBefore, setRevertAnchorElBefore] = React.useState(null);
    const containerRef = React.useRef();
    const containerRefBefore = React.useRef();
    const { t } = useTranslation();
    const dependencyBlindness = useDependencyBlindness();

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleRevertCellClick = event => {
        setRevertAnchorEl(revertAnchorEl ? null : event.currentTarget);
        stopPropagation(event);
    };
    const handleRevertCellBeforeClick = event => {
        setRevertAnchorElBefore(revertAnchorElBefore ? null : event.currentTarget);
        stopPropagation(event);
    };

    const handleRevertClickAway = e => {
        stopPropagation(e);
        setRevertAnchorEl(null);
    };

    const handleRevertBeforeClickAway = e => {
        stopPropagation(e);
        setRevertAnchorElBefore(null);
    };

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
        setRevertAnchorElBefore(null);
        setRevertAnchorEl(null);
    });

    const isSameType = columnType === previousColumnType;

    const renderDeleteDiffSameType = React.useCallback(
        diffBefore => {
            switch (columnType) {
                case columnTypes.SINGLE_LINE:
                case columnTypes.MULTIPLE_LINES:
                case columnTypes.RICH_TEXT:
                case columnTypes.MARKDOWN:
                case columnTypes.TRANSLATION:
                case columnTypes.JSON_LD:
                case columnTypes.HTML:
                case columnTypes.YAML:
                case columnTypes.ALTERED_BY:
                case columnTypes.CREATED_BY:
                case columnTypes.RECORD_ID:
                case columnTypes.FORMULA:
                    return <TextDelete previousData={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.NUMBER: {
                    const displayPrevious = getNumberDisplay({ numberFormat, data: diffBefore ? previousData : data });
                    return <TextDelete previousData={displayPrevious} {...rest} />;
                }

                case columnTypes.SINGLE_SELECTION:
                    return <SingleSelectionDelete previousData={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.MULTIPLE_SELECTIONS:
                    return <MultipleSelectionDelete previousData={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.BOOLEAN:
                    return <BooleanDelete previousData={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.DATETIME:
                case columnTypes.ALTERED_TIME:
                case columnTypes.CREATED_TIME:
                    return <DatetimeDelete previousData={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.FILES:
                    return <FileDelete previousData={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.REFERENCE:
                    return <ReferenceDelete previousData={previousData} {...rest} />;
                default:
                    return null;
            }
        },
        [columnType, previousData, rest, numberFormat, data]
    );

    const renderUpdateDiffSameType = React.useCallback(
        diffBefore => {
            switch (columnType) {
                case columnTypes.SINGLE_LINE:
                case columnTypes.MULTIPLE_LINES:
                case columnTypes.RICH_TEXT:
                case columnTypes.MARKDOWN:
                case columnTypes.TRANSLATION:
                case columnTypes.JSON_LD:
                case columnTypes.HTML:
                case columnTypes.YAML:
                case columnTypes.ALTERED_BY:
                case columnTypes.CREATED_BY:
                case columnTypes.RECORD_ID:
                case columnTypes.FORMULA:
                    return <TextUpdate diffBefore={diffBefore} data={data} previousData={previousData} {...rest} />;

                case columnTypes.NUMBER: {
                    const displayData = getNumberDisplay({ numberFormat, data });
                    const displayPrevious = getNumberDisplay({ numberFormat, data: previousData });
                    return (
                        <NumberCheckDiff
                            diffBefore={diffBefore}
                            data={diffBefore ? displayPrevious : displayData}
                            {...rest}
                        />
                    );
                }

                case columnTypes.SINGLE_SELECTION:
                    return (
                        <SingleSelectionCheckDiff
                            diffBefore={diffBefore}
                            data={diffBefore ? previousData : data}
                            customProperties={customProperties}
                            {...rest}
                        />
                    );
                case columnTypes.MULTIPLE_SELECTIONS:
                    return (
                        <MultipleSelectionCheckDiff
                            diffBefore={diffBefore}
                            data={data}
                            previousData={previousData}
                            options={options}
                            customProperties={customProperties}
                            {...rest}
                        />
                    );
                case columnTypes.BOOLEAN:
                    return <Checkbox data={diffBefore ? previousData : data} {...rest} />;
                case columnTypes.DATETIME:
                case columnTypes.ALTERED_TIME:
                case columnTypes.CREATED_TIME:
                    return (
                        <Datetime data={diffBefore ? previousData : data} state={diffBefore ? 'delete' : 'update'} />
                    );
                case columnTypes.FILES:
                    return <FileCheckDiff diffBefore={diffBefore} data={data} previousData={previousData} {...rest} />;
                case columnTypes.REFERENCE:
                    return <ReferenceUpdate data={data} previousData={previousData} {...rest} />;
                default:
                    return null;
            }
        },
        [columnType, previousData, data, rest, numberFormat, customProperties, options]
    );

    const handleRevert = React.useCallback(
        e => {
            onRevert && onRevert(e);
            handleClickAway(e);
        },
        [onRevert, handleClickAway]
    );

    const handleRevertBefore = React.useCallback(() => {
        onRevertBefore && onRevertBefore();
    }, [onRevertBefore]);

    const handleRevertBeforeClick = React.useCallback(
        e => {
            handleRevertBefore(columnType, previousData, columnId);
            handleClickAway(e);
        },
        [columnId, columnType, handleClickAway, handleRevertBefore, previousData]
    );

    const RenderBoxDiff = React.useCallback(
        ({ diffBefore, handleRevertCellClick }) => {
            return (
                <>
                    {columnTypes.REFERENCE === columnType || (
                        <h5 className="body1 mb-2">{diffBefore ? t(`before_record`) : t(`after_record`)}</h5>
                    )}

                    <Grid
                        container
                        wrap="nowrap"
                        className={
                            columnTypes.REFERENCE === columnType
                                ? `${classes.sourceReference}`
                                : `${classes.sourceContent}`
                        }
                    >
                        {(previousDependencyStatus !== dependencyStatus || sourceStatus !== previousSourceStatus) && (
                            <Grid
                                item
                                style={{
                                    marginRight: 2,
                                    position: 'relative',
                                    top: -2,
                                    display: 'inline-flex',
                                    height: 'fit-content'
                                }}
                            >
                                <Grid alignItems="center" className={classes.diffDependency} container direction="row">
                                    <Grid item>
                                        <LangStatus
                                            dependencyBlindness={dependencyBlindness}
                                            sourceStatus={diffBefore ? previousSourceStatus : sourceStatus}
                                            dependencyStatus={diffBefore ? previousDependencyStatus : dependencyStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {isSameType && status === PREVIEW_STATUS.DELETED && renderDeleteDiffSameType(diffBefore)}
                        {isSameType && status === PREVIEW_STATUS.UPDATED && renderUpdateDiffSameType(diffBefore)}
                        {isSameType && status === PREVIEW_STATUS.CONFLICT && (
                            <Conflict
                                columnType={columnType}
                                previousColumnType={previousColumnType}
                                data={data}
                                options={options}
                                customProperties={customProperties}
                                previousData={previousData}
                                numberFormat={numberFormat}
                                {...rest}
                            />
                        )}
                        {!isSameType && (
                            <RenderDiffOtherTypes
                                columnType={columnType}
                                previousColumnType={previousColumnType}
                                data={data}
                                options={options}
                                customProperties={customProperties}
                                previousData={previousData}
                                numberFormat={numberFormat}
                                {...rest}
                            />
                        )}
                        {showRevertIcon && (
                            <Grid item className={classes.revertIcon}>
                                <Tooltip title={t(`tooltip_record_content`)}>
                                    <span onClick={handleRevertCellClick} className={`${classes.iconAction}`}>
                                        <RevertSVG />
                                    </span>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </>
            );
        },
        [
            dependencyBlindness,
            classes.sourceReference,
            classes.diffDependency,
            classes.iconAction,
            classes.revertIcon,
            classes.sourceContent,
            columnType,
            customProperties,
            data,
            dependencyStatus,
            isSameType,
            numberFormat,
            options,
            previousColumnType,
            previousData,
            previousDependencyStatus,
            previousSourceStatus,
            renderDeleteDiffSameType,
            renderUpdateDiffSameType,
            rest,
            showRevertIcon,
            sourceStatus,
            status,
            t
        ]
    );

    return (
        <Grid ref={rootRef} container direction="row" className={classes.root}>
            <Grid item className={classes.fullWidth}>
                {columnTypes.REFERENCE === columnType ? (
                    <Grid container alignItems="center" wrap="nowrap" justify="center">
                        <RenderBoxDiff diffBefore={true} handleRevertCellClick={handleRevertCellBeforeClick} />
                    </Grid>
                ) : (
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item alignItems="flex-start">
                            <RenderBoxDiff diffBefore={true} handleRevertCellClick={handleRevertCellBeforeClick} />
                        </Grid>
                        <Grid item style={{ marginTop: 22 }}>
                            <ArrowRightSVG />
                        </Grid>
                        <Grid item alignItems="flex-start">
                            <RenderBoxDiff diffBefore={false} handleRevertCellClick={handleRevertCellClick} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <React.Fragment>
                <div ref={containerRefBefore}></div>
                {showRevertIcon && (
                    <PopperMenu
                        className={classes.convert}
                        anchorEl={revertAnchorElBefore}
                        placement={'right-end'}
                        id={`replace_cell_data_before`}
                        container={containerRefBefore.current}
                    >
                        <ConfirmRevert
                            title={t('replace_cell_data_before')}
                            onCancel={handleRevertBeforeClickAway}
                            onAgreed={handleRevertBeforeClick}
                        />
                    </PopperMenu>
                )}
            </React.Fragment>

            <React.Fragment>
                <div ref={containerRef}></div>
                {showRevertIcon && (
                    <PopperMenu
                        className={classes.convert}
                        anchorEl={revertAnchorEl}
                        placement={'right-end'}
                        id={`replace_cell_data`}
                        container={containerRef.current}
                    >
                        <ConfirmRevert
                            title={'Replace cell data after?'}
                            onCancel={handleRevertClickAway}
                            onAgreed={handleRevert}
                        />
                    </PopperMenu>
                )}
            </React.Fragment>
        </Grid>
    );
}

export default React.memo(DiffCheckUIBeforeAfter);
