import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import Checkbox from 'components/checkbox/Base';
import WorkspaceSVG from 'assets/images/svg/WorkspaceSVG';
import { INPUT_HEIGHT, INPUT_PADDING_LEFT, INPUT_RADIUS } from 'const/style';
import LDBasePortal from 'components/selects/LDBasePortal';
import { SHARED_MODES } from 'const/gridUI';
import ButtonBase from 'components/button/Base';
import DialogActions from 'components/dialog/DialogActions';
import { useGroupList, useMemberList } from 'hooks/permission';
import { GROUP_ADMIN_TYPES } from 'const';
import AvatarBase from 'components/avatar/Base';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import { getAvatarUrl } from 'utils/images';
import { isLDEmpty } from 'utils/object';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { shareAdvancedSearchApi } from 'services/advanced';
import { useParams } from 'react-router-dom';
import { useSelectedWorkspace } from 'hooks/workspace';
import { useCurrentUserId } from 'hooks/auth';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    box: {
        borderRadius: 4,
        height: INPUT_HEIGHT,
        background: theme.colors.ghostwhite,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        width: `calc(100% + 12px)`
    },
    flex: {
        display: 'flex'
    },
    expand: {
        flex: 1
    },
    control: {
        width: '100%',
        borderRadius: INPUT_RADIUS,
        minHeight: INPUT_HEIGHT,
        padding: `0 ${INPUT_PADDING_LEFT}px`,
        cursor: 'pointer',
        color: props => props.selectedColor || theme.colors.primaryText,
        border: `1px solid ${theme.colors.border}`,
        background: theme.palette.common.white
    },
    disabled: {
        background: `${theme.colors.paleNavy}`,
        pointerEvents: 'none'
    },
    dropdown: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        minWidth: 200
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    btnSubmit: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function Share({ onClose, access, advancedSearchId, userId }) {
    const classes = useStyles();
    const theme = useTheme();
    const groups = useGroupList();
    const members = useMemberList();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const { workspaceId } = useParams();
    const workspace = useSelectedWorkspace();
    const currentUserId = useCurrentUserId();

    const isOwner = React.useMemo(() => {
        return userId === currentUserId;
    }, [userId, currentUserId]);

    const validMembers = React.useMemo(() => {
        return members?.filter(member => {
            return ![currentUserId]?.includes(member?.id);
        });
    }, [members, currentUserId]);

    const defaultRestrictions = React.useMemo(() => {
        if (isLDEmpty(access?.restrictions)) return [];

        let data = [];

        const users = isOwner
            ? access?.restrictions?.user
            : access?.restrictions?.user?.filter(u => u?.id !== currentUserId);

        if (users?.length) {
            data = data.concat(users);
        }

        if (access?.restrictions?.group?.length) {
            data = data.concat(access?.restrictions?.group);
        }

        return data?.map(user => {
            const isUser = user?.type === 'user';
            const isGroup = user?.type === 'group';
            const isSystemGroup = user?.systemGroup;

            return {
                label: user?.name,
                value: user?.id?.toString(),
                type: user?.type,
                icon: () =>
                    isGroup ? (
                        isSystemGroup ? (
                            user?.groupType === GROUP_ADMIN_TYPES.ADMIN ? (
                                <AdminGroupAvatar size={32} />
                            ) : (
                                <TranslatorGroupAvatar size={32} />
                            )
                        ) : (
                            <AvatarBase size={32} alt={user?.name}>
                                {(user?.name || '').slice(0, 1)}
                            </AvatarBase>
                        )
                    ) : (
                        <AvatarBase size={32} src={isUser && getAvatarUrl(user?.id?.toString())} alt={user?.name} />
                    )
            };
        });
    }, [access, currentUserId, isOwner]);

    const [restrictions, setRestrictions] = React.useState(isLDEmpty(access) ? [] : defaultRestrictions);
    const [modes, setModes] = React.useState(!isLDEmpty(access?.modes) ? access?.modes : []);

    React.useEffect(() => {
        if (access) {
            setRestrictions(isLDEmpty(access) ? [] : defaultRestrictions);
            setModes(!isLDEmpty(access?.modes) ? access?.modes : []);
        }
    }, [access, defaultRestrictions]);

    const options = React.useMemo(() => {
        let data = [];

        if (groups?.length) {
            data = data?.concat(
                groups?.map(group => {
                    const isSystemGroup = group?.systemGroup;
                    return {
                        label: group?.name,
                        value: group?.id,
                        type: 'group',
                        icon: () =>
                            isSystemGroup ? (
                                group?.type === GROUP_ADMIN_TYPES.ADMIN ? (
                                    <AdminGroupAvatar size={32} />
                                ) : (
                                    <TranslatorGroupAvatar size={32} />
                                )
                            ) : (
                                <AvatarBase size={32} alt={group?.name}>
                                    {(group?.name || '').slice(0, 1)}
                                </AvatarBase>
                            )
                    };
                })
            );
        }

        if (validMembers?.length) {
            data = data.concat(
                validMembers?.map(user => {
                    return {
                        label: user?.fullName,
                        value: user?.id,
                        icon: () => <AvatarBase size={32} src={getAvatarUrl(user?.id?.toString())} alt={user?.name} />,
                        type: 'user'
                    };
                })
            );
        }

        return data;
    }, [groups, validMembers]);

    const handleShare = React.useCallback(async () => {
        try {
            setIsLoading(true);
            let body = {
                modes
            };

            if (modes?.includes(SHARED_MODES.SPECIFIC) && restrictions?.length) {
                let restrictionsServer = isOwner
                    ? restrictions?.map(option => ({
                          id: option?.value,
                          type: option?.type
                      }))
                    : [options?.find(option => option?.value === currentUserId), ...restrictions]
                          ?.filter(Boolean)
                          ?.map(option => ({
                              id: option?.value,
                              type: option?.type
                          }));

                body = {
                    ...body,
                    restrictions: restrictionsServer
                };
            }

            const res = await shareAdvancedSearchApi({ wsId: workspaceId, aId: advancedSearchId, body });
            setIsLoading(false);
            onClose && onClose();
            dispatch(
                enqueueSnackbar({
                    message: 'Search query has been shared successfully',
                    type: 'info'
                })
            );
            console.log('res', res);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            setIsLoading(false);
        }
    }, [dispatch, modes, advancedSearchId, workspaceId, restrictions, onClose, isOwner, currentUserId, options]);

    const addMode = React.useCallback(
        mode => {
            if (modes?.includes(mode)) {
                setModes(prev => prev.filter(pMode => pMode !== mode));
            } else {
                setModes(prev => [...prev, mode]);
            }
        },
        [modes]
    );

    return (
        <>
            <Grid container direction="column" className={classes.root}>
                <Grid item className={classes.expand}>
                    <Grid container direction="column">
                        <Grid item className={classes.mb3}>
                            <Grid style={{ position: 'relative', left: -12 }} spacing={2} container direction="column">
                                <Grid item>
                                    <Grid container alignItems="center" direction="row" spacing={3}>
                                        <Grid item>
                                            <Grid container alignItems="center" direction="row" spacing={1}>
                                                <Grid item>
                                                    <Checkbox
                                                        checked={modes?.includes(SHARED_MODES.PROJECT)}
                                                        onChange={e => addMode(SHARED_MODES.PROJECT)}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        Share with all the member of project
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.expand}>
                                            <Grid className={classes.box} container alignItems="center" direction="row">
                                                <Grid item style={{ display: 'flex', marginRight: theme.spacing(2) }}>
                                                    <WorkspaceSVG />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{workspace?.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                        <Grid item>
                                            <Checkbox
                                                checked={modes.includes(SHARED_MODES.SPECIFIC)}
                                                onChange={e => addMode(SHARED_MODES.SPECIFIC)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                Share with these specific groups & members below
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.mb3}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="body1">Select group or member</Typography>
                                </Grid>
                                <Grid item>
                                    <LDBasePortal
                                        options={options}
                                        onChange={options => {
                                            setRestrictions(options);
                                        }}
                                        defaultValue={restrictions}
                                        isMulti={true}
                                        dropdownClassName={`${classes.dropdown} ${!modes?.includes(
                                            SHARED_MODES.SPECIFIC
                                        ) && classes.disabled}`}
                                        ddPlaceholder={'Add member and groups'}
                                        menuPlaceholder={'find member or group'}
                                        closedOnSelect={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase width={107} onClick={onClose} variant="outlined">
                            Cancel
                        </ButtonBase>
                    </Grid>

                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase disabled={isLoading} onClick={handleShare} width={107} variant="contained">
                                Share
                            </ButtonBase>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default Share;
