import React from 'react';

function HighlightDiffTextOnly({ data = '', previousData = '' }) {
    return (
        <p className="body2 whitespace-pre-wrap break-words">
            {previousData && (
                <p className="body2 inline bg-pinka line-through break-words whitespace-pre-wrap">{previousData}</p>
            )}
            {data && <p className="body2 inline bg-griptide">{data}</p>}
        </p>
    );
}

export default React.memo(HighlightDiffTextOnly);
