import React from 'react';
import { Modal, Backdrop } from '@material-ui/core';

function ModalBase({ open, children, handleClose, ...rest }) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={'flex items-center justify-center'}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
            {...rest}
        >
            {children}
        </Modal>
    );
}

export default React.memo(ModalBase);
