import React from 'react';

function ChromeNotifySVG({ color = '#7869B9', ...other }) {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M2.89819 4.18102C8.47735 -2.31174 18.8226 -1.03059 22.6885 6.5543C19.9657 6.5558 15.7037 6.5528 13.3949 6.5543C11.7207 6.5543 10.6391 6.5168 9.46897 7.13337C8.09331 7.85796 7.05369 9.20062 6.69214 10.7788L2.89819 4.18102Z"
                fill="#F44336"
            />
            <path
                d="M8.00928 11.9983C8.00928 14.1991 9.799 15.9888 11.9983 15.9888C14.1976 15.9888 15.9873 14.1991 15.9873 11.9983C15.9873 9.79754 14.1976 8.00781 11.9983 8.00781C9.7975 8.00631 8.00928 9.79754 8.00928 11.9983Z"
                fill="#2196F3"
            />
            <path
                d="M13.5448 17.2276C11.3065 17.8921 8.68572 17.1556 7.25155 14.6773C6.15643 12.787 3.26108 7.74494 1.94693 5.45117C-2.66162 12.514 1.30935 22.1376 9.67434 23.7803L13.5448 17.2276Z"
                fill="#4CAF50"
            />
            <path
                d="M15.702 8.00834C17.5668 9.74254 17.9733 12.5509 16.7102 14.7276C15.759 16.3688 12.7212 21.4949 11.2495 23.9762C19.868 24.5073 26.1508 16.0613 23.3124 8.00684L15.702 8.00834Z"
                fill="#FFC107"
            />
        </svg>
    );
}

export default React.memo(ChromeNotifySVG);
