import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { OPTION_SPACING } from 'const/style';
import { isJson } from 'utils/checkType';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import hexToRgba from 'hex-to-rgba';
import { isFileAudio, isFileImage, isFileVideo, getFilePreview, isFilePdf } from 'utils/images';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';

const IMAGE_FIXED_HEIGHT = 28;
const IMAGE_FIXED_WIDTH = 28;

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    item: {
        fontSize: 14,
        borderRadius: 3,
        '&:not(:last-child)': {
            marginRight: OPTION_SPACING
        },
        paddingTop: 2,
        paddingBottom: 2
    },
    items: {
        background: hexToRgba(theme.colors.dodgerBlue, 0.1),
        border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        borderRadius: 6,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: OPTION_SPACING,
        marginBottom: OPTION_SPACING
    }
}));

function ReferenceFileRender({ value: cellValue, dbId }) {
    const classes = useStyles();
    const width = IMAGE_FIXED_WIDTH;
    const height = IMAGE_FIXED_HEIGHT;

    if (isEmpty(cellValue)) return null;

    const getJsonValue = cellValue => {
        if (isArray(cellValue)) {
            let values = cellValue.map(i => {
                const childFiles = isJson(i?.referencedDataItem)
                    ? JSON.parse(i?.referencedDataItem)
                    : isArray(i?.referencedDataItem)
                    ? i?.referencedDataItem
                    : [];
                return childFiles;
            });
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(cellValue);
    if (!isArray(newData) || !newData) return null;

    return (
        <Grid
            container
            className={`${classes.container} ${classes.optionList}`}
            style={{
                pointerEvents: 'none'
            }}
            direction="row"
            wrap="nowrap"
        >
            {newData.map((items, indexItems) => (
                <Grid item key={indexItems} className={classes.items}>
                    <Grid container direction="row" wrap="nowrap">
                        {items?.map(fileMeta => {
                            let isImage = isFileImage(fileMeta);
                            let isAudio = isFileAudio(fileMeta);
                            let isVideo = isFileVideo(fileMeta);
                            let isPdf = isFilePdf(fileMeta);

                            let url = getFilePreview({ dbId, fileId: fileMeta?.thumbnailId });

                            return (
                                <Grid className={classes.item} item key={fileMeta?.id}>
                                    <Avatar
                                        style={{
                                            height: height,
                                            width: width,
                                            borderRadius: 4,
                                            background: !isImage
                                                ? isAudio
                                                    ? '#EEF5FB'
                                                    : isVideo
                                                    ? '#FDF0ED'
                                                    : '#F3F2F9'
                                                : ''
                                        }}
                                        src={url}
                                        alt={fileMeta?.originalName}
                                    >
                                        {!isImage &&
                                            (isAudio ? (
                                                <AudioThumbnailSVG style={{ width, height }} />
                                            ) : isVideo ? (
                                                <VideoThumbnailSVG style={{ width, height }} />
                                            ) : isPdf ? (
                                                <PDFFILESVG style={{ width, height }} />
                                            ) : (
                                                <OtherFileTypeThumbnailSVG style={{ width, height }} />
                                            ))}
                                    </Avatar>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(ReferenceFileRender);
