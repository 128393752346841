import React from 'react';

function ArrowKeyDownSVG({ color = '#78778B', className, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M13.3333 8.00033L12.3933 7.06033L8.66666 10.7803V2.66699H7.33333L7.33333 10.7803L3.61333 7.05366L2.66666 8.00033L8 13.3337L13.3333 8.00033Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowKeyDownSVG);
