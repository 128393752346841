import React from 'react';
import { Grid, Typography, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import TransformOwnerShipSVG from 'assets/images/svg/TransformOwnerShipSVG';
import InputText from 'components/inputs/InputText';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import { useDispatch } from 'react-redux';
import * as memberActions from 'permission/actions/member';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 20,
        width: 440,
        background: theme.colors.white
    },
    btnSubmit: {
        width: 160,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    transferButton: {
        '&.MuiButton-root': {
            background: theme.colors.snackBarWarning,
            '&:hover': {
                background: theme.colors.hoverWarningButton
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

const TransferOwnerShipSchema = object({
    password: string().required('This field is required')
});

function TransferBox({ currentUser, handleCancel }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [isShowPassword, setIsShowPassword] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            password: ''
        },
        validationSchema: TransferOwnerShipSchema,
        onSubmit: ({ password }, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            sendManualTrack({ type: `Approve Member`, customData: { affectedEmail: currentUser.email } });
            dispatch(
                memberActions.transferOwner({
                    body: {
                        currentPassword: password,
                        userId: currentUser?.id
                    },
                    successCallback: () => {
                        console.log('transfer success');
                        setSubmitting(false);
                        handleCancel();
                    },
                    errorCallback: () => {
                        setSubmitting(false);
                        console.log('failed to transfer owner');
                        handleCancel();
                    }
                })
            );
        }
    });

    function handleClickShowPassword() {
        setIsShowPassword(prev => !prev);
    }

    const { values, errors, touched, handleChange, handleSubmit, isSubmitting, isValid } = formik;

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container direction="column" spacing={4} className={classes.root}>
                <Grid item>
                    <Typography variant="h4">{t('confirm_transfer_title')}</Typography>
                </Grid>
                <Grid item>
                    <Grid container justify="center">
                        <Grid item>
                            <TransformOwnerShipSVG />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="body2" display="inline">
                        {t('confirm_transfer_want_to_transfer')}{' '}
                    </Typography>
                    <Typography variant="body1" display="inline">
                        {currentUser?.fullName} ({currentUser?.email})?{' '}
                    </Typography>
                    <Typography variant="body2" display="inline">
                        {t('confirm_transfer_one_way_street')}
                    </Typography>
                </Grid>
                <Grid item>
                    <InputText
                        name="password"
                        value={values.password}
                        height={36}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        data-openreplay-obscuredgit
                        st
                        placeholder={t('global_password')}
                        label={t('your_password_to_confirm_the_transfer')}
                        type={isShowPassword ? 'text' : 'password'}
                        error={errors.password && touched.password}
                        autoFocus
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword} edge="end">
                                    {isShowPassword ? <EyeOpenIconSVG /> : <EyeCloseIconSVG />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
                <Grid item container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase onClick={handleCancel} width={100} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase
                                type="submit"
                                disabled={!isValid || isSubmitting}
                                width={160}
                                variant="contained"
                                className={classes.transferButton}
                            >
                                {t('global_transfer_ownership')}
                            </ButtonBase>
                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
export default React.memo(TransferBox);
