import * as types from '../types';
import produce from 'immer';

const handler = {
    [types.FETCH_WEB_HOOKS](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetching = true;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_WEB_HOOKS_FAILED](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetching = false;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_WEB_HOOKS_SUCCESS](state, { payload }) {
        const { viewId, hooks } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetching = false;
            draft[viewId].list = hooks;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.CREATE_WEB_HOOK](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isCreating = true;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.CREATE_WEB_HOOK_FAILED](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isCreating = false;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.CREATE_WEB_HOOK_SUCCESS](state, { payload }) {
        const { viewId, hook } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isCreating = false;
            const list = draft[viewId].list || [];
            draft[viewId].list = [hook, ...list];
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.UPDATE_WEB_HOOK](state, { payload }) {
        const { viewId, hookId, hook } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].list = draft?.[viewId]?.list?.map(item => {
                if (item?.id === hookId) {
                    return hook;
                }
                return item;
            });

            return draft;
        });

        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.DELETE_WEB_HOOK](state, { payload }) {
        const { viewId, hookId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].list = draft?.[viewId]?.list?.map(hook => {
                if (hook?.id === hookId) {
                    hook.isDeleted = true;
                }
                return hook;
            });

            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.DELETE_WEB_HOOK_FAILED](state, { payload }) {
        const { viewId, hookId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].list = draft?.[viewId]?.list?.map(hook => {
                if (hook?.id === hookId) {
                    hook.isDeleted = false;
                }
                return hook;
            });

            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.DELETE_WEB_HOOK_SUCCESS](state, { payload }) {
        const { viewId, hookId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].list = draft?.[viewId]?.list?.filter(hook => hook?.id !== hookId);

            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },

    [types.WEB_HOOKS_TURN_ON_FIRST_CREATE_POP_UP](state, { payload }) {
        const { viewId, hookId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].firstCreatedHookId = hookId;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.WEB_HOOKS_TURN_OFF_FIRST_CREATE_POP_UP](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].firstCreatedHookId = null;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_MORE_WEB_HOOK_LOGS](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetchingMoreLog = true;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_MORE_WEB_HOOK_LOGS_FAILED](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetchingMoreLog = false;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_MORE_WEB_HOOK_LOGS_SUCCESS](state, { payload }) {
        const { viewId, logs = [], newOffset, totalLog } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            const oldLogs = draft?.[viewId]?.logs || [];
            draft[viewId].isFetchingMoreLog = false;
            draft[viewId].LOG_OFFSET = newOffset;
            draft[viewId].logs = [...oldLogs, ...logs];
            draft[viewId].totalLog = totalLog;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_WEB_HOOK_LOGS](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetchingLog = true;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_WEB_HOOK_LOGS_FAILED](state, { payload }) {
        const { viewId } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetchingLog = false;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    },
    [types.FETCH_WEB_HOOK_LOGS_SUCCESS](state, { payload }) {
        const { viewId, logs = [], totalLog } = payload;
        const { webHooks } = state;
        const newWebHooks = produce(webHooks, draft => {
            if (!draft?.[viewId]) {
                draft[viewId] = {};
            }
            draft[viewId].isFetchingLog = false;
            draft[viewId].LOG_OFFSET = 0;
            draft[viewId].logs = logs;
            draft[viewId].totalLog = totalLog;
            return draft;
        });
        return {
            ...state,
            webHooks: newWebHooks
        };
    }
};

export default handler;
