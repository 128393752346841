import React from 'react';

function BooleanSVG({ color = '#78778B', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Datatype/Boolean" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M13,0 C14.6568542,0 16,1.34314575 16,3 L16,13 C16,14.6568542 14.6568542,16 13,16 L3,16 C1.34314575,16 0,14.6568542 0,13 L0,3 C0,1.34314575 1.34314575,0 3,0 L13,0 Z M13,1.7 L3,1.7 C2.32690296,1.7 1.77328475,2.21154965 1.70671175,2.86708255 L1.7,3 L1.7,13 C1.7,13.673097 2.21154965,14.2267153 2.86708255,14.2932882 L3,14.3 L13,14.3 C13.673097,14.3 14.2267153,13.7884503 14.2932882,13.1329174 L14.3,13 L14.3,3 C14.3,2.32690296 13.7884503,1.77328475 13.1329174,1.70671175 L13,1.7 Z M11.3137519,5.57658292 C11.4685747,5.73233153 11.5555556,5.94364874 11.5555556,6.16388824 C11.5555556,6.38412773 11.4685747,6.59544494 11.3137519,6.75119355 L7.66335772,10.4234171 C7.34095291,10.7477499 6.8178231,10.7477499 6.49557389,10.4234171 L4.68624805,8.6032715 C4.36384324,8.27909525 4.36384324,7.75283713 4.68624805,7.42866088 C5.00849726,7.10432809 5.53162707,7.10432809 5.85403188,7.42866088 L6.93203407,8.51295296 C7.01341327,8.59466228 7.14551833,8.59466228 7.22705314,8.51295296 L10.1459681,5.57658292 C10.4682173,5.25225014 10.9913471,5.25225014 11.3137519,5.57658292 Z"
                    id="Shape"
                    fill={color}
                    fillRule="nonzero"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(BooleanSVG);
