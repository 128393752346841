import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import Overview from './overview';
import BillingHistory from './billingHistory';
import BillingDetails from './billingDetails';
import NestedSettingLayout from 'permission/common/NestedSettingLayout';
import { NavLink } from 'react-router-dom';
import { INPUT_RADIUS } from 'const/style';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        borderRadius: INPUT_RADIUS,
        height: 42,
        padding: `0px 8px`,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        textDecoration: 'none',
        color: theme.colors.primaryText,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '&.active': {
            background: theme.colors.hoverItem,
            '& p': {
                color: theme.palette.primary.main
            },
            '& path': {
                fill: theme.palette.primary.main
            }
        }
    }
}));

function PaymentSetting() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { path } = useRouteMatch();

    const sidebarOptions = [
        { path: `${path}/overview`, name: t('overview') },
        { path: `${path}/history`, name: t('billing_history') },
        { path: `${path}/detail`, name: t('billing_details') }
    ];

    return (
        <NestedSettingLayout
            name={'Billing'}
            Content={
                <Switch>
                    <Route path={path} exact>
                        {/* handle redirect by role here, create another component if neccessary */}
                        <Redirect to={`${path}/overview`} />
                    </Route>
                    <Route path={`${path}/overview`}>
                        <Overview />
                    </Route>
                    <Route path={`${path}/history`}>
                        <BillingHistory />
                    </Route>
                    <Route path={`${path}/detail`}>
                        <BillingDetails />
                    </Route>
                </Switch>
            }
            Sidebar={
                <Grid container direction="column">
                    {sidebarOptions.map(option => (
                        <Grid item key={option.path}>
                            <NavLink className={classes.item} to={option.path}>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <Typography variant={'body2'}>{option.name}</Typography>
                                    </Grid>
                                </Grid>
                            </NavLink>
                        </Grid>
                    ))}
                </Grid>
            }
        ></NestedSettingLayout>
    );
}

export default React.memo(PaymentSetting);
