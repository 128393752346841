import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import QuickFilterSVG from 'assets/images/svg/QuickFilterSVG';
import FilterMenu from '../filters';
import { Button as ButtonBase, Grid, Typography } from '@material-ui/core';
import PopperMenu from 'components/menus/Popper';
import Tooltip from 'components/tooltip/Base';
import { DISABLED_OPACITY } from 'const/style';
import {
    useIsAdvancedSearchOwner,
    useIsBlockedGridByOwner,
    useIsHighlightFilter,
    useSelectedColumns
} from 'hooks/advanced';
import viewFilterGif from 'assets/images/viewFilter.gif';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'react-i18next';
import scrollIntoView from 'scroll-into-view-if-needed';
import * as advancedSearchActions from 'advancedSearch/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: theme.colors.tutorialColor,
        padding: theme.spacing(3),
        borderRadius: 4,
        width: 252
    },
    spb2: {
        marginBottom: theme.spacing(1)
    },
    viewGift: {
        width: '100%',
        height: 'auto',
        borderRadius: 4
    },
    propertyButton: {
        display: 'flex',
        margin: `0px 4px`,
        '&:hover': {
            background: theme.colors.divider
        }
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

const ManageFilter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const isHighLightFilter = useIsHighlightFilter();
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const dependencies = [];
    const columns = useSelectedColumns();
    const isBlockedGridByOwner = useIsBlockedGridByOwner();
    const isAdvancedSearchOwner = useIsAdvancedSearchOwner();

    // const isShowWarning = React.useMemo(() => {
    //     return currentUser?.id !== userId?.toString();
    // }, [currentUser, userId]);

    const handleFilterClick = React.useCallback(
        event => {
            setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
        },
        [filterAnchorEl]
    );

    const handleFilterClickAway = React.useCallback(() => {
        setFilterAnchorEl(null);
    }, []);

    const onCreated = React.useCallback(() => {
        const node = document.getElementById('filterListBottom');
        scrollIntoView(node, {
            behavior: 'smooth',
            block: 'end',
            duration: 300,
            boundary: document.getElementById('filterListBoundary')
        });
    }, []);

    const addFilterHandler = React.useCallback(() => {
        dispatch(
            advancedSearchActions.createViewFilter({
                successCallback: () => {
                    console.log('create filter success');
                    onCreated && onCreated();
                },
                errorCallback: () => {
                    console.log('create filter failed');
                }
            })
        );
    }, [dispatch, onCreated]);

    const addOrFilterHandler = React.useCallback(
        (idOfFilterToOrWith, groupId) => {
            dispatch(
                advancedSearchActions.createViewFilter({
                    groupWithFilterId: idOfFilterToOrWith,
                    groupId,
                    successCallback: () => {
                        console.log('create filter success');
                    },
                    errorCallback: () => {
                        console.log('create filter failed');
                    }
                })
            );
        },
        [dispatch]
    );

    const handleDeleteFilter = React.useCallback(
        id => {
            dispatch(
                advancedSearchActions.deleteViewFilter({
                    filterId: id
                })
            );
        },
        [dispatch]
    );

    const handleUpdateViewFilter = React.useCallback(
        body => {
            dispatch(advancedSearchActions.updateViewFilter(body));
        },
        [dispatch]
    );

    if (isBlockedGridByOwner) return null;

    return (
        <>
            <Tooltip
                title={
                    <Grid container className={classes.tooltip} direction="column">
                        <Grid item className={classes.spb2}>
                            <img className={classes.viewGift} src={viewFilterGif} alt="view filter gif" />
                        </Grid>
                        <Grid item>
                            <Typography variant="overline">{t('view_filter_tutorial_title')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">{t('view_filter_tutorial_text')}</Typography>
                        </Grid>
                    </Grid>
                }
            >
                <Grid item>
                    <ButtonBase
                        id="g-h-menu-item-filters-access"
                        className={`${classes.propertyButton} ${classes.button}`}
                        style={{
                            // background: isHighLightFilter ? theme.colors.solitude : '',
                            background: isHighLightFilter ? hexToRgba(theme.colors.fuchsiaBlue, 0.3) : '',
                            position: 'relative'
                        }}
                        onClick={handleFilterClick}
                        tr-dt="Open Column Filters Setting"
                    >
                        <QuickFilterSVG
                            height={18}
                            width={18}
                            color={isHighLightFilter ? theme.palette.primary.main : theme.colors.darkness}
                        />
                        {!!isHighLightFilter && (
                            <div
                                style={{
                                    width: 6,
                                    height: 6,
                                    borderRadius: '50%',
                                    background: theme.colors.indicator,
                                    border: `1px solid ${theme.colors.solitude}`,
                                    position: 'absolute',
                                    right: 3,
                                    top: 3
                                }}
                            ></div>
                        )}
                    </ButtonBase>
                </Grid>
            </Tooltip>
            {filterAnchorEl && (
                <PopperMenu
                    placement={'bottom-start'}
                    anchorEl={filterAnchorEl}
                    handleClickAway={handleFilterClickAway}
                    className={classes.popperMenu}
                >
                    <FilterMenu
                        addOrFilter={addOrFilterHandler}
                        addAndFilter={addFilterHandler}
                        onCreated={onCreated}
                        dependencies={dependencies}
                        onDeleteFilter={handleDeleteFilter}
                        onUpdateFilter={handleUpdateViewFilter}
                        columns={columns}
                        isReadOnly={!isAdvancedSearchOwner}
                    />
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ManageFilter);
