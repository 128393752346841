import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import PlanCard from 'payment/components/PlanCard';
import { useRole } from 'hooks/auth/role';
import { useCardInfo, useCurrentSubscriptionIs, useIsProActive, useIsProTrial } from 'hooks/payment';
import { PAYMENT_WARNING_TYPES, PLAN_CYCLE, PLAN_TYPES } from 'const';
import WarningBox from 'payment/components/warning';
import { Link } from 'react-router-dom';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,
        background: theme.colors.ghostwhite
    },
    closeButton: {
        position: 'absolute',
        top: 15,
        right: 15,
        cursor: 'pointer'
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    warningBox: {
        marginTop: theme.spacing(4)
    },
    bottomLink: {
        textAlign: 'center',
        marginTop: theme.spacing(4)
    },
    cycleSwitchWrapper: {
        borderRadius: 4,
        background: '#E4E6EC',
        margin: 'auto',
        width: 290,
        height: 40,
        marginBottom: theme.spacing(4)
    },
    cycleSwitchContainer: {
        height: 34,
        width: 284
    },
    cycleSwitchItem: {
        height: '100%',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    activeCycle: {
        backgroundColor: theme.colors.white
    },
    annualOff: {
        color: theme.colors.dodgerBlue,
        background: '#EAF5FE',
        borderRadius: 4,
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }
}));

function PlanList({
    onClose,
    plans,
    onSelect,
    isSubmitting,
    proSeat,
    companySubscription,
    selectedCycle,
    setSelectedCycle,
    isValidSeat
}) {
    const classes = useStyles();
    const roles = useRole();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isProActive = useIsProActive();
    const isProTrial = useIsProTrial();
    const { t } = useTranslation();
    const cardInfo = useCardInfo();

    const isValid = plan => {
        const targetPlan = plan.type;
        if (isFreePlan) {
            if (targetPlan === PLAN_TYPES.FREE) {
                return false;
            }
            if (targetPlan === PLAN_TYPES.PROFESSIONAL) {
                if (cardInfo) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        if (isProActive) {
            if (targetPlan === PLAN_TYPES.FREE) {
                return true;
            }
            if (targetPlan === PLAN_TYPES.PROFESSIONAL) {
                if (!isValidSeat) return false;
                return true;
            }
        }

        if (isProTrial) {
            if (targetPlan === PLAN_TYPES.FREE) {
                return true;
            }
            if (targetPlan === PLAN_TYPES.PROFESSIONAL) {
                if (!isValidSeat) return false;
                if (cardInfo) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };

    return (
        <Grid container direction="column" className={classes.root}>
            <IconButton className={classes.closeButton} onClick={onClose}>
                <CloseIconSVG />
            </IconButton>
            <Grid item className={classes.title}>
                <Typography variant="h3" align="center">
                    Choose your plan
                </Typography>
            </Grid>
            <Grid item container className={classes.cycleSwitchWrapper} justifyContent="center" alignItems="center">
                <Grid
                    item
                    container
                    className={classes.cycleSwitchContainer}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        onClick={() => setSelectedCycle(PLAN_CYCLE.month)}
                        item
                        xs
                        className={`${classes.cycleSwitchItem} ${
                            selectedCycle === PLAN_CYCLE.month ? classes.activeCycle : ''
                        }`}
                    >
                        <Typography variant="body2">Monthly</Typography>
                    </Grid>
                    <Grid
                        onClick={() => setSelectedCycle(PLAN_CYCLE.year)}
                        item
                        xs
                        className={`${classes.cycleSwitchItem} ${
                            selectedCycle === PLAN_CYCLE.year ? classes.activeCycle : ''
                        }`}
                    >
                        <Typography variant="body2" display="inline">
                            Annually
                        </Typography>
                        <Typography variant="body1" display="inline" className={classes.annualOff}>
                            20% off
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item container spacing={3} wrap="nowrap" justify="center">
                        {plans.map(plan => (
                            <Grid item key={plan?.id}>
                                <PlanCard
                                    plan={plan}
                                    onSelect={() => onSelect(plan.type)}
                                    isDisabled={!isValid(plan)}
                                    isSubmitting={isSubmitting}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {!Boolean(cardInfo) && (
                        <Grid item className={classes.warningBox}>
                            <WarningBox
                                warningType={PAYMENT_WARNING_TYPES.ERROR}
                                message={
                                    roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING] === roleConst.FULL
                                        ? t('choose_plan_to_select_a_plan')
                                        : t('choose_plan_no_permission')
                                }
                                action={
                                    <AccessControl view={roleConst.COMPANY_AUTHORITIES.MANAGE_BILLING}>
                                        <Link
                                            to={`/company-settings/billing/detail`}
                                            className="btn btn-contained btn-primary w-[172px]"
                                        >
                                            {t('choose_plan_add_payment_method')}
                                        </Link>
                                    </AccessControl>
                                }
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.bottomLink}>
                        <a href="https://www.gridly.com/pricing" target="_blank" rel="noopener noreferrer">
                            {t('choose_plan_see_all_features')}
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(PlanList);
