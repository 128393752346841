import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Option from 'components/option/Base';
import { OPTION_SPACING } from 'const/style';
import { ROW_HEIGHT } from 'const/gridUI';
import { usePathTagRef } from 'hooks/gridUI';
import { getNodesByPath } from 'utils/gridUI/pathTag';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `8px 10px`,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        borderRadius: 4,
        maxHeight: 160,
        overflowY: 'auto',
        minHeight: ROW_HEIGHT,
        border: `1px solid ${theme.colors.silver}`,
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        position: 'relative'
    },
    container: {
        width: '100%'
    },
    optionList: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    option: {
        marginBottom: OPTION_SPACING
    },
    splitor: {
        marginRight: OPTION_SPACING,
        marginLeft: OPTION_SPACING
    }
}));

function PathTag({ value: cellValue, options = [], customProperties, isReadOnly }) {
    const classes = useStyles({ isReadOnly });
    const theme = useTheme();

    const pathRef = usePathTagRef();
    const _value = React.useMemo(() => {
        return Array.isArray(cellValue) ? cellValue : getNodesByPath({ pathRef, path: cellValue });
    }, [pathRef, cellValue]);

    if (!_value) return <div className={classes.root} />;
    return (
        <Grid
            container
            className={classes.root}
            justify="flex-start"
            alignItems="flex-start"
            style={{
                pointerEvents: 'none'
            }}
        >
            <Grid item className={` ${classes.optionList}`}>
                {_value?.map((node, index) => {
                    return (
                        <>
                            <Option
                                className={classes.option}
                                key={node?.id}
                                size="small"
                                label={node?.name}
                                color={node?.customProperties?.color || theme.colors.atlantis}
                            />
                            {index !== _value?.length - 1 && <p className="body1 inline mx-1">/</p>}
                        </>
                    );
                })}
            </Grid>
        </Grid>
    );
}

export default React.memo(PathTag);
