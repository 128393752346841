import React from 'react';

function CellDiffSVG({ color = '#78778B', bgColor = 'white', ...rest }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect width="24" height="24" fill={bgColor} />
            <path
                d="M18 4H16H14H12H10C7.791 4 6 5.791 6 8C6 10.209 7.791 12 10 12V20H12V6H14V20H16V6H18V4Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(CellDiffSVG);
