import { useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { getFullName } from 'utils/user';
import { useGroupIsFetchingGroups, useGroupList } from 'hooks/permission/group';
import { useCurrentViewId } from './view';
import { useCurrentUser } from 'hooks/auth';
import { ACTIVE } from 'const';

export function useIsFetchingInShareView() {
    return useSelector(state => state.gridUI.shareView?.isFetching);
}

export function useSelectedGroupIdInShareView() {
    return useSelector(state => state.gridUI.shareView?.selectedGroupId);
}

export function useShareViewGroups(viewId) {
    return useSelector(state => state.gridUI.shareView?.[viewId]?.groups) || [];
}

export function useWorkspaceMembersInShareView() {
    return useSelector(state => state.gridUI.shareView?.workspaceMembers);
}

export function useNotSharedGroups() {
    const isFetchingGroups = useGroupIsFetchingGroups();
    const groups = useGroupList();
    const currentViewId = useCurrentViewId();
    const viewGroups = useShareViewGroups(currentViewId);
    const sharedGroupIds = viewGroups?.map(group => group.id);
    const notSharedGroups = groups?.filter(group => !sharedGroupIds.includes(group.id));
    return [notSharedGroups, isFetchingGroups];
}

export function useShareViewGroupMembers() {
    const currentUser = useCurrentUser();
    const selectedGroupId = useSelectedGroupIdInShareView();
    const workspaceMembers = useWorkspaceMembersInShareView();
    const currentViewId = useCurrentViewId();
    const viewGroups = useShareViewGroups(currentViewId) || [];

    let members = [];
    if (!selectedGroupId) {
        viewGroups.forEach(group => {
            members = [...members, ...(group?.members || [])];
        });
    } else {
        let groupSelected = viewGroups?.find(group => group?.id === selectedGroupId);
        members = groupSelected?.members;
    }
    const memberIds = uniq(members?.map(member => member?.id));

    const mappedMembers = workspaceMembers
        ?.filter(member => memberIds.includes(member?.user?.id) && member?.user?.state === ACTIVE)
        ?.map(member => ({
            ...member,
            user: {
                ...member.user,
                fullName: getFullName(member.user)
            }
        }));

    return currentUser?.ldManager ? mappedMembers : mappedMembers?.filter(member => !member.ldUser);
}

export function useShareLinkDetail(compositeViewId) {
    return useSelector(state => state.gridUI.shareLinkDetail?.[compositeViewId]);
}
