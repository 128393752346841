import React from 'react';

function GridTMSVG({ color = '#FFF', ...other }) {
    return (
        <svg {...other} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.64503 3.3999H7.83218C8.06883 3.3999 8.26628 3.56777 8.31194 3.79092L8.32189 3.88962V6.07377H12.8531C13.0898 6.07377 13.2872 6.24163 13.3329 6.46479L13.3428 6.56348V7.60576C13.3428 7.84241 13.175 8.03986 12.9518 8.08552L12.8531 8.09547H8.2875C8.74777 9.82036 9.81566 11.4856 11.4912 13.0913C11.6095 13.2047 11.6617 13.3679 11.6354 13.5256L11.61 13.619L11.247 14.5734C11.2202 14.6438 11.1776 14.7071 11.1223 14.7583C10.924 14.9422 10.6142 14.9305 10.4303 14.7323C8.97365 13.1615 8.03013 11.8514 7.5997 10.8019C6.8653 12.6877 5.1153 14.59 2.34969 16.509C2.31431 16.5335 2.27585 16.5533 2.23529 16.5678C2.0125 16.6474 1.77016 16.5558 1.65203 16.3611L1.60943 16.2715L1.23284 15.2173C1.1558 15.0016 1.23848 14.7614 1.43196 14.6389C4.35872 12.7841 5.93317 10.8421 6.15532 8.81285L6.20045 8.58205L6.25849 8.12808H1.58969C1.35304 8.12808 1.15559 7.96021 1.10992 7.73706L1.09998 7.63836V6.59609C1.09998 6.35944 1.26784 6.16199 1.491 6.11633L1.58969 6.10638H6.15532V3.88962C6.15532 3.65296 6.32318 3.45552 6.54634 3.40985L6.64503 3.3999ZM17.0157 7.07276C17.7727 7.07276 18.4534 7.51586 18.7644 8.19457L18.8294 8.35529L22.9063 19.887C22.9248 19.9394 22.9343 19.9946 22.9343 20.0502C22.9343 20.2869 22.7664 20.4843 22.5433 20.53L22.4446 20.5399H21.4486C21.2683 20.5399 21.1053 20.4413 21.0202 20.2876L20.9844 20.2064L19.7363 16.4965H14.2951L13.047 20.2064C12.9895 20.3772 12.844 20.5002 12.6712 20.5319L12.5828 20.5399H11.5868C11.5312 20.5399 11.476 20.5304 11.4236 20.5119C11.2005 20.433 11.0703 20.209 11.1016 19.9833L11.1251 19.887L15.202 8.35529C15.4737 7.58665 16.2004 7.07276 17.0157 7.07276ZM14.9752 14.4748L17.0157 9.09447L19.0562 14.5074L14.9752 14.4748Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(GridTMSVG);
