import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import Button from 'components/button/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useDispatch } from 'react-redux';
import * as workspaceActions from 'workspaces/actions';
import { useNotSharedProjectGroups, useProjectGroupOptions, useProjectGroupsIsLoading } from 'hooks/workspace';
import * as groupActions from 'permission/actions/group';
import LDBasePortal from 'components/selects/LDBasePortal';
import Spinner from 'components/spinner/Base';
import { GROUP_ADMIN_TYPES } from 'const';
import { sendManualTrack } from 'tracker';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 500
    },
    text: {
        color: theme.colors.lightGreyBlue
    },
    select: {
        padding: 20,
        backgroundColor: theme.colors.background,
        borderRadius: 4
    },
    item: {
        paddingTop: 24
    },
    test: {
        backgroundColor: theme.colors.white
    },
    groupItem: {
        padding: '4px 0',
        borderRadius: 4,
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    spinnerWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}));

function ShareProject({ project, onClose }) {
    const classes = useStyles();
    const notSharedProjectGroups = useNotSharedProjectGroups();
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const projectGroupOptions = useProjectGroupOptions();
    const projectGroupsIsLoading = useProjectGroupsIsLoading();
    console.log('projectGroupsIsLoading', projectGroupsIsLoading);

    const handleSelectedGroupChange = group => {
        setSelectedGroup(group);
    };

    React.useEffect(() => {
        if (!notSharedProjectGroups) {
            dispatch(
                groupActions.fetchGroups({
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        }
    }, [dispatch, notSharedProjectGroups]);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchProjectGroups({
                projectId: project.id,
                successCallback: () => {
                    console.log('create project success');
                },
                errorCallback: () => {
                    console.log('create project Failed');
                }
            })
        );
    }, [dispatch, project]);

    const handleShareProject = group => {
        sendManualTrack({ type: 'Share Project to Group' });
        dispatch(workspaceActions.shareProject({ projectId: project.id, groups: [group] }));
        setSelectedGroup(null);
    };

    const handleRemoveProjectFromGroup = group => {
        dispatch(workspaceActions.unshareProject({ projectId: project.id, groupIds: [group.id] }));
        setSelectedGroup(null);
    };

    return (
        <>
            <DialogTitle title={`Share ${project.name}`} onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" spacing={3} className={classes.root}>
                    <Grid item>
                        <Typography variant="body2" className={classes.text}>
                            Sharing project to groups which will give them access to all views within this project.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.item}>
                        <Grid container direction="column" spacing={2} className={classes.select}>
                            <Grid item>
                                <Typography variant="body1">Select a group to share</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item style={{ flex: 1 }}>
                                        <LDBasePortal
                                            dropdownClassName={classes.test}
                                            ddPlaceholder={'Select a group'}
                                            menuPlaceholder={t('global_label_find_a_group')}
                                            options={notSharedProjectGroups || []}
                                            onChange={handleSelectedGroupChange}
                                            defaultValue={selectedGroup}
                                            // isCreatable={authorities?.[roleConst.MANAGE_GROUP] === roleConst.FULL}
                                            createText="Add New Group"
                                            // onCreateNew={handleOpenAddGroupModal}
                                            // isLoading={isFetchingGroups}
                                            // createMoreIcon={!canDataAccessControl && <UpgradeSVG />}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            width={90}
                                            type="submit"
                                            variant="contained"
                                            onClick={() => handleShareProject(selectedGroup)}
                                            disabled={!Boolean(selectedGroup)}
                                        >
                                            Share
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">Shared with</Typography>
                        </Grid>
                        {projectGroupsIsLoading ? (
                            <Grid item className={classes.spinnerWrapper}>
                                <Spinner size={30} thick={4} />
                            </Grid>
                        ) : (
                            <Grid item>
                                {projectGroupOptions.map(group => (
                                    <Grid
                                        key={group.id}
                                        item
                                        container
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.groupItem}
                                    >
                                        <Grid item>{group.icon()}</Grid>
                                        <Grid item>
                                            <Typography variant="body2">{group.label}</Typography>
                                        </Grid>
                                        <Grid item style={{ flex: 1 }} />
                                        {group?.type !== GROUP_ADMIN_TYPES.ADMIN && (
                                            <Grid item>
                                                <IconButton
                                                    onClick={() => handleRemoveProjectFromGroup(group)}
                                                    size="small"
                                                >
                                                    <CloseIconSVG size="small" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}

export default React.memo(ShareProject);
