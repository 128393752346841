import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zh from './zh.json';
import en from './en.json';
import dev from './dev.json';

const resources = {
    dev: {
        translation: dev
    },
    en: {
        translation: en
    },
    zh: {
        translation: zh
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: ['en', 'dev'],
    debug: true,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
