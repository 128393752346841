import { useSelector } from 'react-redux';
import { useDefaultAccessViewIdInGridUI } from './index';

export function useWebHooksList() {
    return useSelector(state => state.gridUI.webHooks);
}

export function useViewHooks(externalViewId) {
    const webHooks = useWebHooksList();
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    return webHooks?.[viewId]?.list?.filter(hook => !hook?.isDeleted);
}

export function useIsHighlightTrigger(externalViewId) {
    const viewHooks = useViewHooks(externalViewId);
    return viewHooks?.length;
}

export function useFirstCreatedHookId(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId]?.firstCreatedHookId;
}

export function useViewHookDetail(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId];
}

export function useViewHookLogs(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId]?.logs;
}

export function useViewHookLOG_OFFSET(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId]?.LOG_OFFSET;
}

export function useViewHookLOG_LIMIT(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId]?.LOG_LIMIT;
}

export function useViewHookTotalLog(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId]?.totalLog;
}

export function useViewHookIsFetchingLog(externalViewId) {
    const currentViewId = useDefaultAccessViewIdInGridUI();
    const viewId = currentViewId || externalViewId;
    const webHooks = useWebHooksList();
    return webHooks?.[viewId]?.isFetchingLog;
}
