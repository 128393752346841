import { SUBSCRIPTION_STATUS, PLAN_TYPES } from 'const';
import { useSelector } from 'react-redux';
import { useCurrentSubscriptionIs, useIsProActive } from './subscription';

export function useAddonPlans() {
    return useSelector(state => state.payment.addonPlans);
}

export function useAddonSubscriptions() {
    return useSelector(state => state.payment.addonSubscriptions);
}

export function useAddonSubscription(addonType) {
    const currentSubscriptions = useAddonSubscriptions();

    return currentSubscriptions?.find(subscription => subscription.price.plan.name === addonType);
}

export function useIsAddonPaid(addonType) {
    const addonSubscription = useAddonSubscription(addonType);
    if (!addonSubscription) return false;
    return [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.TRIALING].includes(addonSubscription?.status);
}

export function useIsBlockedAddonContent(addonType) {
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isProActive = useIsProActive();
    const isHavePaidExtraAddonSubscription = useIsAddonPaid(addonType);

    return isFreePlan || (isProActive && !isHavePaidExtraAddonSubscription);
}

export function useAddonUsage() {
    return useSelector(state => state.payment.addonUsage);
}
