import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_RADIUS } from 'const/style';
import { NavLink } from 'react-router-dom';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { useDispatch } from 'react-redux';
import * as appActions from 'app/actions';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import ConnectorSVG from 'assets/images/svg/ConnectorSVG';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '29px 14px',
        '& svg': {
            cursor: 'pointer'
        }
    },
    item: {
        borderRadius: INPUT_RADIUS,
        height: 42,
        padding: `0px 8px`,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        textDecoration: 'none',
        color: theme.colors.primaryText,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '&.active': {
            background: theme.colors.hoverItem,
            '& p': {
                color: theme.palette.primary.main
            },
            '& path': {
                fill: theme.palette.primary.main
            }
        }
    },
    active: {
        background: theme.colors.hoverItem
    },
    fullWidth: {
        width: '100%'
    },
    textActive: {
        color: theme.palette.primary.main,
        fontWeight: 500
    }
}));

function Sidebar({ marginTop }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const connectorsActive = ['integration-settings', 'connectors'];

    const settings = [
        {
            value: connectorsActive,
            name: t('gridly_connector'),
            path: '/integration-settings/connectors',
            icon: color => <ConnectorSVG color={color} />
        }
    ];

    const getPermission = value => {
        if (isEqual(value, connectorsActive)) {
            return roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON;
        }

        return null;
    };

    useEffect(() => {
        dispatch(
            appActions.fetchCompanyInfo({
                successCallback: () => {
                    console.log('[SideBar] fetch company info successfully');
                },
                errorCallback: () => {
                    console.log('[SideBar] failed to fetch company info');
                }
            })
        );
    }, [dispatch]);

    return (
        <Grid
            container
            className={classes.root}
            style={{
                marginTop
            }}
            direction="column"
        >
            <Grid container alignItems="flex-start" direction="column" spacing={4} wrap="nowrap">
                <Grid item className={classes.fullWidth}>
                    <Typography variant="h4" style={{ fontWeight: 'normal' }}>
                        {t('global_settings')}
                    </Typography>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <Grid container direction="column" spacing={0}>
                        {settings.map(setting => {
                            const viewPermission = getPermission(setting.value);
                            return (
                                <AccessControl key={setting.value} view={viewPermission}>
                                    <Grid item>
                                        <NavLink className={classes.item} to={setting.path}>
                                            <Grid container spacing={2} direction="row" alignItems="center">
                                                <Grid item style={{ display: 'flex' }}>
                                                    {setting?.icon()}
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant={'body2'}>{setting.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </NavLink>
                                    </Grid>
                                </AccessControl>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(Sidebar);
