import React from 'react';

function SortAscendingSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.700053 11.9943L1.6944 11L2.99976 12.3054L2.99976 3.24479e-07L4.39976 2.02087e-07L4.39976 12.3173L5.71702 11L6.71136 11.9943L3.70571 15L0.700053 11.9943Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2206 4.78228C13.6046 4.39828 13.7966 3.93322 13.7966 3.38708C13.7966 2.84095 13.6046 2.37802 13.2206 1.99828C12.8366 1.61855 12.3758 1.42868 11.8382 1.42868C11.3006 1.42868 10.8398 1.61855 10.4558 1.99828C10.0718 2.37802 9.87977 2.84095 9.87977 3.38708C9.87977 3.93322 10.0718 4.39615 10.4558 4.77588C10.8398 5.15562 11.3006 5.34548 11.8382 5.34548C12.3758 5.34548 12.8366 5.15775 13.2206 4.78228ZM15.1918 0.391881V6.38228H13.835V5.53748C13.2974 6.20308 12.555 6.53588 11.6078 6.53588C10.7544 6.53588 10.0291 6.23295 9.43177 5.62708C8.83443 5.02121 8.53577 4.27455 8.53577 3.38708C8.53577 2.49961 8.83443 1.75295 9.43177 1.14708C10.0291 0.541212 10.7544 0.238281 11.6078 0.238281C12.555 0.238281 13.2974 0.571078 13.835 1.23668V0.391881H15.1918Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6648 7.92773L14.8142 8.34587L11.8275 13.4531H14.9934V14.9165H9.63228L9.46802 14.5133L12.38 9.3912H9.96082V7.92773H14.6648Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(SortAscendingSVG);
