import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import store from 'store/configStore';
import * as appActions from 'app/actions';
import * as clipboardy from 'clipboardy';
import { isSafari, isWindows } from './os';

const PREFIX_CSV = ``;
const NEW_LINE = isWindows() ? `\r\n` : `\n`;

const { dispatch, getState } = store;

export let copyPasteEvt = undefined;
export let contentBeforeConfirm = undefined;

export function setCopyPaseEvt(value = undefined) {
    copyPasteEvt = value;
}

export function setContentBeforeConfirm(value = undefined) {
    contentBeforeConfirm = value;
}

export async function copyToClipboard({ copyValue, internalValue = [] }) {
    const copiedValue = processCopiedValue(copyValue);

    if (isSafari()) {
        if (copyPasteEvt?.clipboardData) {
            console.log('-----copiedValue-----');
            console.log(copiedValue);
            copyPasteEvt.clipboardData.setData('text/plain', copiedValue);
            setCopyPaseEvt();
        }
    } else {
        await _writeToClipboard(copiedValue);
    }
    dispatch(
        appActions.writeClipboard({
            content: copiedValue,
            raw: internalValue
        })
    );
}

export function processCopiedValue(raw) {
    const isString = typeof raw === 'string';
    let content = '';
    let csvFile = PREFIX_CSV;
    let rows = isArray(raw) ? raw : [[raw]];

    for (const row of rows) {
        csvFile += processRow(row);
    }

    content = csvFile;
    return isString ? content : removeLastLineBreak(content);
}

async function _writeToClipboard(value) {
    await clipboardy.write(value);
}

export async function _readClipboard(event) {
    try {
        const content = await event.clipboardData?.getData('text');

        return {
            status: true,
            content
        };
    } catch (err) {
        throw new Error(isSafari() ? 'Copy-and-Paste in Safari is not supported yet' : err?.message);
    }
}

function removeLastLineBreak(content) {
    if (content.lastIndexOf(NEW_LINE) > 0) {
        return content.substring(0, content.lastIndexOf(NEW_LINE));
    } else {
        return content;
    }
}

function getInnerValue(value) {
    if (typeof value === 'number' || typeof value === 'boolean') return value.toString();
    return isEmpty(value) ? '' : value.toString();
}

function _replaceWindow(text) {
    if (text?.includes(`\n`) && !text?.includes(`\r\n`)) {
        return text?.replace(/\n/g, NEW_LINE);
    }
    return text;
}

function processRow(row) {
    let finalVal = '';

    for (let j = 0; j < row.length; j++) {
        let innerValue = getInnerValue(row[j]);
        if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
        }

        if (isWindows()) {
            innerValue = _replaceWindow(innerValue);
        }

        if (innerValue.search(/(\t|\r\n|\n\r|\n|\r)/g) >= 0) innerValue = '"' + innerValue + '"';
        if (j > 0) finalVal += `\t`;
        finalVal += innerValue;
    }

    return finalVal + NEW_LINE;
}

// function _processPasteV3(content) {
//     const isEndsWithWindow = isWindows() && content?.endsWith('\r\n');

//     const splitRegex = isWindows() ? /\r\n/g : /\r\n|\n\r|\n|\r/g;

//     const newContent = isEndsWithWindow ? content?.slice(0, content?.length - 2) : content;
//     return (
//         newContent
//             .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, function(match, p1) {
//                 console.log('P1', JSON.stringify(p1));
//                 return p1.replace(/""/g, '"').replace(splitRegex, SECRET_KEY);
//             })
//             // Split each line into rows
//             .split(splitRegex)
//             ?.map(row => {
//                 row = row?.replaceAll(SECRET_KEY, NEW_LINE);
//                 return row?.split(`\t`);
//             })
//     );
// }

function _countQuotes(str) {
    return str.split('"').length - 1;
}

function _replaceDoubleQuote(str) {
    return str?.replace(/""/g, '"');
}

function _processPaste(str) {
    const isEndsWithWindow = isWindows() && str?.endsWith('\r\n');
    str = isEndsWithWindow ? str?.slice(0, str?.length - 2) : str;

    let arr = [];
    let multiline = false;
    let last;
    let a = 0;

    let rows = str.split(/\r\n|\n\r|\n|\r/g);
    const rowLength = rows.length;

    for (let rIndex = 0; rIndex < rowLength; rIndex += 1) {
        rows[rIndex] = rows[rIndex].split('\t');
        let cellLength = rows?.[rIndex]?.length;
        const firstCellValue = rows?.[rIndex]?.[0];

        for (let cIndex = 0; cIndex < cellLength; cIndex += 1) {
            if (!arr?.[a]) {
                arr[a] = [];
            }
            if (multiline && cIndex === 0) {
                last = arr[a]?.length - 1;

                arr[a][last] = arr[a][last] + '\n' + firstCellValue;

                if (multiline && _countQuotes(firstCellValue) & 1) {
                    multiline = false;
                    arr[a][last] = _replaceDoubleQuote(arr[a][last].substring(0, arr[a][last].length - 1));
                }
            } else {
                const cellValue = rows?.[rIndex]?.[cIndex];
                if (cIndex === cellLength - 1 && cellValue.indexOf('"') === 0 && _countQuotes(cellValue) & 1) {
                    arr[a].push(_replaceDoubleQuote(cellValue.substring(1)));
                    multiline = true;
                } else {
                    arr[a].push(_replaceDoubleQuote(cellValue));
                    multiline = false;
                }
            }
        }
        if (!multiline) {
            a += 1;
        }
    }
    return arr;
}

export async function getClipboardContent() {
    let clipboardContent;
    let { status, content } = await _readClipboard(copyPasteEvt);
    const { app } = getState();
    const internalClipboardContent = app.clipboard?.content;
    const raw = app.clipboard?.raw;

    if (!status) {
        clipboardContent = internalClipboardContent;
    } else {
        clipboardContent = content;
    }

    if (internalClipboardContent === clipboardContent) {
        console.log('USING INTERNAL PASTE', raw);
        return raw;
    }

    return _processPaste(content);
}
