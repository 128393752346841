import React, { useCallback } from 'react';
import { useMatchAutomations } from 'hooks/gridUI/automation';
import NestedMenuItem from 'components/menus/HoverMenu';
import { useTheme } from '@material-ui/styles';
import AutomationTriggerSVG from 'assets/images/svg/AutomationTriggerSVG';
import { useTranslation } from 'react-i18next';
import { Divider, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as automationActions from 'gridUI/automations/action';
import ListItem from 'components/list/Item';
import AvatarBase from 'components/avatar/Base';
import { getAutomationLogoUrl } from 'utils/images';
import LogoSVG from 'assets/images/svg/webHook/LogoSVG';

const PlayAutomationMenu = ({ defaultColumnIds, closePopup }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const matchAutomations = useMatchAutomations(defaultColumnIds);

    const handleInvoke = useCallback(
        automationId => {
            dispatch(
                automationActions.invokeAutomationRange({
                    automationId,
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        },
        [dispatch]
    );

    const openMultipleAutomation = useCallback(
        e => {
            e.stopPropagation();
            dispatch(automationActions.setOpenMultipleAutomationDialog(true));
            closePopup && closePopup();
        },
        [dispatch, closePopup]
    );

    if (!matchAutomations?.length) return null;

    return (
        <NestedMenuItem
            icon={<AutomationTriggerSVG width={16} height={16} />}
            label={t(`run_automations`)}
            parentMenuOpen={true}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
        >
            <Grid container direction="column">
                {matchAutomations?.map(a => {
                    return (
                        <Grid
                            key={a?.id}
                            onClick={e => {
                                e.stopPropagation();
                                handleInvoke(a?.id);
                                closePopup && closePopup();
                            }}
                            item
                        >
                            <ListItem
                                icon={
                                    <AvatarBase src={getAutomationLogoUrl(a.iconFileId) || ''} size={24}>
                                        <LogoSVG />
                                    </AvatarBase>
                                }
                                name={a?.title}
                            />
                        </Grid>
                    );
                })}
                <Grid item style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                    <Divider />
                </Grid>
                <Grid item onClick={openMultipleAutomation}>
                    <ListItem
                        icon={<AutomationTriggerSVG width={16} height={16} />}
                        name={t(`run_multiple_automations`)}
                    />
                </Grid>
            </Grid>
        </NestedMenuItem>
    );
};

export default React.memo(PlayAutomationMenu);
