import { rudderInitialize, rudder } from './rudder';
import uuidV4 from 'uuid/v4';
import store from 'store/configStore';
import { getKeyValueOnly } from 'utils/object';
import { getItem, setItem } from 'helpers/localstorage';
import * as crmTracker from './crmTracker';
import { isExternal } from 'config';

const { getState } = store;
const PREFIX = '[Gridly] ';

function initTracker() {
    rudderInitialize();
    const wrapper = document.getElementById('root');
    const user = getState().auth.currentUser;
    const TR_DT_COOKIE = getItem(`tr-dt`);
    const DEFAULT_SESSION_ID = `default_${uuidV4()}`;

    if (!TR_DT_COOKIE) {
        setItem('tr-dt', JSON.stringify(DEFAULT_SESSION_ID));
    }

    if (user) {
        rudder.identify(user?.id?.toString(), {
            email: user?.email
        });
    }

    rudder.page(document.title, {
        path: window.location.pathname,
        search: window.location.search,
        url: window.location.href
    });

    wrapper.addEventListener('mousedown', e => {
        var event = document.all ? window.event : e;
        if (!/^(?:input|textarea|select)$/i.test(e.target.tagName)) {
            const rdData = getRdData(event?.target);

            if (rdData) {
                const extraBody = getStoreInfo();
                console.log('CLICK EVENT', {
                    type: `${PREFIX}${rdData}`,
                    SESSION_ID: getItem('tr-dt') || DEFAULT_SESSION_ID,
                    ...extraBody
                });
                rudder.track(`${PREFIX}${rdData}`, {
                    SESSION_ID: getItem('tr-dt') || DEFAULT_SESSION_ID,
                    ...extraBody
                });

                crmTracker.track(`${rdData}`, extraBody);
            }
        }
    });
}

export function sendPageLocationInfo() {
    rudder.page(document.title, {
        path: window.location.pathname,
        search: window.location.search,
        url: window.location.href
    });
}

export function sendManualTrack({ type, customData = {} }) {
    if (isExternal) return;
    const extraBody = getStoreInfo();
    const data = {
        ...extraBody,
        ...customData
    };
    console.log(`[MANUAL TRACKER] ${type}`, {
        SESSION_ID: getItem('tr-dt'),
        ...data
    });
    rudder.track(`${PREFIX}${type}`, {
        SESSION_ID: getItem('tr-dt'),
        ...data
    });

    crmTracker.track(`${type}`, data);
}

function getRdData(node, level = 8) {
    try {
        let rdData = node?.getAttribute('tr-dt');
        if (rdData) return rdData;
        if (node?.parentNode && level > 0) {
            rdData = getRdData(node?.parentNode, --level);
        }
        return rdData;
    } catch (err) {
        return null;
    }
}

function getStoreInfo() {
    const { getState } = store;
    const state = getState();
    const app = state?.app;
    const gridUI = state?.gridUI;
    const workspace = state?.workspace;
    const user = state.auth.currentUser;

    const companyId = user?.companyId;
    const companyName = app?.companyInfo?.name;
    const dashboardWsId = workspace?.selectedWorkspace?.id;

    const dbId = gridUI?.dbId;
    const viewId = gridUI?.defaultAccessViewId;
    const branchId = gridUI?.branchId;
    const gridId = gridUI?.parentGridId;
    const gridWorkspaceId = gridUI?.workspaceId;
    const userName = user?.fullName;
    const email = user?.email;

    const workspaceId = dashboardWsId || gridWorkspaceId;

    const obj = getKeyValueOnly({
        companyId,
        companyName,
        workspaceId,
        dbId,
        branchId,
        viewId,
        gridId,
        userName,
        email
    });
    return obj;
}

export default initTracker;
