import { enqueueSnackbar } from 'notifier/actions';
import {
    getUsersApi,
    updateUserRoleApi,
    updateUserStateApi,
    addUsersToGroupApi,
    unassignUserApi,
    getWorkspacesRolesOfSingleUser,
    resendInvitationApi,
    revokeInvitationApi
} from 'services/members';
import { updateWorkspaceUserRoleApi } from 'services/workspaces';
import { requestData, receiveData } from '../../api/actions';
import * as types from '../types';
import { transferOwnerApi } from 'services/auth';
import i18n from 'i18n';

export function changeUserWorkspaceRole({ userId, workspaceId, oldRole, newRole, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            dispatch(requestData(types.CHANGE_USER_WORKSPACE_ROLE, { userId, workspaceId, role: newRole }));
            await updateWorkspaceUserRoleApi({
                companyId,
                body: {
                    roleId: newRole?.id,
                    projectId: workspaceId,
                    userId
                }
            });

            dispatch(
                enqueueSnackbar({
                    message: i18n.t('project_role_changed_success'),
                    type: 'info'
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(requestData(types.CHANGE_USER_WORKSPACE_ROLE, { userId, workspaceId, role: oldRole }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function fetchWorkspacesRolesOfSingleMember({ userId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            dispatch(requestData(types.FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER, { userId }));
            const singleWorkspacesRoles = await getWorkspacesRolesOfSingleUser({ companyId, userId });
            dispatch(
                receiveData(types.FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_SUCCESS, {
                    userId,
                    singleWorkspacesRoles
                })
            );
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(receiveData(types.FETCH_WORKSPACES_AND_ROLES_OF_SINGLE_USER_FAILED, { userId }));
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function fetchMembers({ companyId, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const { currentUser } = auth;

        try {
            dispatch(requestData(types.FETCH_MEMBERS));

            const members = await getUsersApi(companyId || currentUser.companyId);

            dispatch(receiveData(types.FETCH_MEMBERS_SUCCESS, members));

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function setUserRole({ companyId, userId, oldRole, newRole, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(receiveData(types.UPDATE_USER_ROLE_SUCCESS, { companyId, userId, role: newRole }));
            await updateUserRoleApi({ companyId, userId, roleId: newRole.id });
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('company_role_changed'),
                    type: 'info'
                })
            );
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.UPDATE_USER_ROLE_FAILED, { companyId, userId, role: oldRole }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function activateUser({ companyId, userEmail, oldState, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(receiveData(types.UPDATE_USER_STATE_SUCCESS, { companyId, userEmail, userState: 'ACTIVE' }));

            await updateUserStateApi({ companyId, userEmail, action: 'ACTIVATE' });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.UPDATE_USER_STATE_FAILED, { companyId, userEmail, userState: oldState }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function deactivateUser({ companyId, userEmail, oldState, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(receiveData(types.UPDATE_USER_STATE_SUCCESS, { companyId, userEmail, userState: 'INACTIVE' }));

            await updateUserStateApi({ companyId, userEmail, action: 'DEACTIVATE' });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.UPDATE_USER_STATE_FAILED, { companyId, userEmail, userState: oldState }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function approveUser({ companyId, userEmail, oldState, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(
                receiveData(types.UPDATE_USER_STATE_SUCCESS, { companyId, userEmail, userState: 'PENDING_INVITE' })
            );

            await updateUserStateApi({ companyId, userEmail, action: 'ACTIVATE' });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.UPDATE_USER_STATE_FAILED, { companyId, userEmail, userState: oldState }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function declineMemberFromCompany({ companyId, userEmail, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await updateUserStateApi({ companyId, userEmail, action: 'DECLINE_INVITE' });
            dispatch(_declineMemberFromCompanyActionSuccess({ companyId, userEmail }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function _declineMemberFromCompanyActionSuccess({ userEmail }) {
    return {
        type: types.REMOVE_USER_SUCCESS,
        payload: {
            userEmail
        }
    };
}

export function removeMemberFromCompany({ companyId, userEmail, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            await updateUserStateApi({ companyId, userEmail, action: 'DELETE' });
            dispatch(_removeMemberFromCompanyActionSuccess({ companyId, userEmail }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function _removeMemberFromCompanyActionSuccess({ userEmail }) {
    return {
        type: types.REMOVE_USER_SUCCESS,
        payload: {
            userEmail
        }
    };
}

export function inviteMembersToGroupSuccessAction({ members, groupId }) {
    return {
        type: types.INVITE_USERS_TO_GROUP_SUCCESS,
        payload: {
            members,
            groupId
        }
    };
}

export function addMembersToGroup({ groupId, userIds, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth, permission } = getState();
        const members = permission.members.list;
        const companyId = auth.companyId || auth.currentUser?.companyId;
        try {
            const addUsersToGroupData = await addUsersToGroupApi({ companyId, groupId, userIds });
            const inviteMembers = members.filter(member =>
                addUsersToGroupData?.assignedIds.includes(Number(member.id))
            );

            dispatch(inviteMembersToGroupSuccessAction({ members: inviteMembers, groupId }));
            dispatch(
                enqueueSnackbar({
                    message: i18n.t('member_added_to_group_success'),
                    type: 'info'
                })
            );

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function unassignUser({ companyId, groupId, group, userId, successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(receiveData(types.UNASSIGN_USER_OUT_GROUP, { companyId, groupId, userId }));

            await unassignUserApi({ companyId, groupId, userId });

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(receiveData(types.ASSIGN_USER_TO_GROUP, { companyId, groupId, group, userId }));

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function transferOwner({ body, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            const { currentUser, targetUser } = await transferOwnerApi({ companyId, body });
            console.log('targetUser', targetUser);
            console.log('currentUser', currentUser);
            dispatch(transferOwnershipActionSuccess({ currentUser, targetUser }));

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function transferOwnershipActionSuccess({ currentUser, targetUser }) {
    return {
        type: types.TRANSFER_OWNERSHIP_SUCCESS,
        payload: {
            currentUser,
            targetUser
        }
    };
}

export function updateUserRoleAndLanguages({
    companyId,
    userId,
    role,
    languagePairs,
    groups,
    successCallback,
    errorCallback
}) {
    return async function(dispatch) {
        try {
            await updateUserRoleApi({
                companyId,
                userId,
                roleId: role.id,
                languagePairs,
                groupIds: groups?.map(g => g?.id)
            });
            dispatch(updateUserRoleAndLanguagesActionSuccess({ userId, role, languagePairs, groups }));
            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function updateUserRoleAndLanguagesActionSuccess({ userId, role, languagePairs, groups }) {
    return {
        type: types.UPDATE_USER_ROLE_AND_LANGUAGES_SUCCESS,
        payload: {
            userId,
            role,
            languagePairs,
            groups
        }
    };
}

export function resendInvitation({ companyId, email, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            await resendInvitationApi({ companyId, email });
            // dispatch(_resendInvitationAction(email));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

// function _resendInvitationAction(email) {
//     return {
//         type: types.RESEND_INVITATION_SUCCESS,
//         payload: { email }
//     };
// }

export function revokeInvitation({ companyId, email, errorCallback, successCallback }) {
    return async function(dispatch, getState) {
        try {
            await revokeInvitationApi({ companyId, email });
            dispatch(_revokeInvitationAction(email));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

function _revokeInvitationAction(email) {
    return {
        type: types.REVOKE_INVITATION_SUCCESS,
        payload: { email }
    };
}

export function _updateSingleUser(newUser) {
    return {
        type: types.UPDATE_SINGLE_USER,
        payload: { newUser }
    };
}

export function resetMembers() {
    return {
        type: types.RESET_MEMBERS
    };
}
