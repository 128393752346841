import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as types from 'socket/types';
import columnHandler from '../gridUI/column';
import viewHandler from '../views';
import recordHandler from '../gridUI/record';
import gridHandler from '../gridUI/grid';
import dependencyHandler from '../gridUI/dependency';
import dependencyAllHandler from '../gridUI/dependencyAll';
import taskHandler from '../tasks';
import * as gridActions from 'gridUI/actions';
import { isForAll, isForYou, isForOthers } from 'socket/utils/room';
import { handleApplyingTmSuccess, updateRecordsAutoQASocket } from 'gridUI/actions';
import { enqueueSnackbar } from 'notifier/actions';
import * as gridUIActions from 'gridUI/actions';
import { getColumnsRecordsApi } from 'services/view';
import * as columnTypes from 'const/columnTypes';
import * as dataActions from 'gridUI/actions/data';
import { DATA_QUERY_OPTIONS, GRID_STATUS } from 'const/gridUI';
import { updateGridBranchStatus } from 'grids/actions';

const { dispatch, getState } = store;

const gridDetailHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);
    console.log('body', body);
    const { subObjectType, objectType, action, subAction, data, status, failedMessage, dbId, gridId } = body;

    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ state, headers })) {
        if (objectType === types.GRID) {
            if (action === types.UPDATE) {
                if (subObjectType === types.TAG) {
                    if (subAction === types.CREATE_PATH_VIA_SET_RECORD || subAction === types.DELETE) {
                        console.log('fetchTagsAndColumnsRecord');
                        return fetchTagsAndColumnsRecord();
                    }
                }
            }
            if (action === types.BACKUP) {
                if (status === 'RUNNING') {
                    dispatch(updateGridBranchStatus({ dbId, gridId, status: GRID_STATUS.BACKING_UP }));
                } else {
                    dispatch(updateGridBranchStatus({ dbId, gridId, status: GRID_STATUS.ACTIVE }));
                    if (status === 'SUCCESS' && document.getElementById('refresh-backup-grid')) {
                        document.getElementById('refresh-backup-grid').click();
                    }
                }
            }
        }

        if (objectType === types.GRID && subObjectType === types.DEPENDENCY) {
            dependencyAllHandler({ body });
        }

        if (objectType === types.GRID && action === types.RESTORE) {
            return taskHandler({ body, isGridDetail: true });
        }

        if (objectType === types.GRID && action === types.AUTOFILL) {
            if (subAction === types.AUTOFILL_START) {
                return dispatch(gridActions.addProcessingColumns({ columnIds: data?.fromColumnIds }));
            }
            if (subAction === types.AUTOFILL_END) {
                if (status === 'FAILED') {
                    dispatch(
                        enqueueSnackbar({
                            message: 'Autofill failed! your changes have been reverted',
                            type: 'info'
                        })
                    );
                    return;
                }
                return dispatch(gridActions.fillColumnsSocket({ columnIds: data?.fromColumnIds }));
            }
        }

        if (objectType === types.GRID && action === types.CLEAN_COLUMN) {
            if (subAction === types.CLEAN_COLUMN_START) {
                return dispatch(gridActions.addProcessingColumns({ columnIds: data?.columnIds }));
            }
            if (subAction === types.CLEAN_COLUMN_END) {
                return dispatch(gridActions.fillColumnsSocket({ columnIds: data?.columnIds }));
            }
        }

        if (objectType === types.GRID && action === types.REPLACE_RECORD) {
            if (subAction === types.REPLACE_RECORD_START) {
                return dispatch(gridActions.setReplacingText({ replacing: true }));
            }
            if (subAction === types.REPLACE_RECORD_END) {
                return dispatch(
                    gridActions.setReplacingText({
                        replacing: false,
                        records: data?.records,
                        replacedTotal: data?.replacedTotal,
                        status,
                        failedMessage
                    })
                );
            }
        }

        if (
            objectType === types.GRID &&
            [
                types.UPLOAD,
                types.IMPORT,
                types.MERGE_GRID,
                types.PREVIEW_MERGE,
                types.PREVIEW_IMPORT,
                types.PREVIEW_TRANSLATION
            ].includes(action)
        ) {
            return gridHandler({ body, isGridDetail: true });
        }

        if (objectType === types.GRID && subObjectType === types.RECORD) {
            if ([types.POPULATED_DATA, types.UPDATE, types.AUTO_QA].includes(subAction)) {
                return recordHandler({ body });
            }
        }

        if (objectType === types.GRID && subObjectType === types.COLUMN) {
            if (
                [
                    types.PASTE_COLUMNS_END,
                    types.PASTE_COLUMNS_START,
                    types.APPROVE_POPULATED_TEXT_COLUMN_END,
                    types.APPROVE_POPULATED_TEXT_COLUMN_START,
                    types.MARK_COLUMN_DEPENDENCY_STATUS_END,
                    types.MARK_COLUMN_DEPENDENCY_STATUS_START,
                    types.MARK_COLUMN_SOURCE_STATUS_START,
                    types.MARK_COLUMN_SOURCE_STATUS_END,
                    types.SWITCH_TYPE_END,
                    types.SWITCH_TYPE_START,
                    types.EXECUTE_FORMULA_COLUMN_END,
                    types.EXECUTE_FORMULA_COLUMN_START,
                    types.SWITCH_TYPE_START,
                    types.UNDO_UPDATE,
                    types.REDO_UPDATE,
                    types.UNDO_DELETE,
                    types.REDO_DELETE,
                    types.UNDO_CREATE,
                    types.REDO_CREATE,
                    types.UNDO_REORDER,
                    types.REDO_REORDER,
                    types.UNDO_PASTE_COLUMN_END,
                    types.REDO_PASTE_COLUMN_END,
                    types.UNDO_CLEAN_COLUMN_START,
                    types.UNDO_CLEAN_COLUMN_END
                ].includes(subAction)
            ) {
                return columnHandler({ body });
            }
        }

        if (objectType === types.GRID && action === types.AUTO_QA_END) {
            return dispatch(updateRecordsAutoQASocket());
        }

        if (objectType === types.VIEW && subObjectType === types.COLUMN) {
            if (
                [
                    types.UNDO_UPDATE,
                    types.REDO_UPDATE,
                    types.UNDO_DELETE,
                    types.REDO_DELETE,
                    types.UNDO_CREATE,
                    types.REDO_CREATE,
                    types.UNDO_REORDER,
                    types.REDO_REORDER,
                    types.UNDO_PASTE_COLUMN_END,
                    types.REDO_PASTE_COLUMN_END,
                    types.UNDO_CLEAN_COLUMN_START,
                    types.UNDO_CLEAN_COLUMN_END
                ].includes(subAction)
            ) {
                return columnHandler({ body });
            }
        }
    }

    if (isForYou({ state, headers })) {
        if (objectType === types.GRID && subAction === 'PULL_TRANSLATIONS_FROM_TM_END') {
            dispatch(handleApplyingTmSuccess());
        }
        switch (action) {
            case types.ADD_FILE:
                return gridHandler({ body, isGridDetail: true });
            default:
                break;
        }

        if (subAction === types.UPDATE && subObjectType === types.COLUMN) {
            return columnHandler({ body });
        }

        if (objectType === types.GRID && subObjectType === types.RECORD) {
            if (
                [
                    types.CLEAR_RECORDS_START,
                    types.CLEAR_RECORDS_END,
                    types.UNDO_CREATE,
                    types.REDO_CREATE,
                    types.UNDO_UPDATE,
                    types.REDO_UPDATE,
                    types.UNDO_DELETE,
                    types.REDO_DELETE,
                    types.UNDO_REORDER,
                    types.REDO_REORDER
                ].includes(subAction)
            ) {
                return recordHandler({ body, isYour: true });
            }
        }
        return;
    } else if (isForOthers({ state, headers })) {
        switch (subObjectType) {
            case types.COLUMN:
                return columnHandler({ body });
            case types.VIEW:
                return viewHandler(message);
            case types.RECORD:
                return recordHandler({ body });
            case types.DEPENDENCY:
                return dependencyHandler({ body });
            default:
                break;
        }

        switch (objectType) {
            case types.GRID:
                return gridHandler({ body });
            default:
                return;
        }
    }
};

async function fetchTagsAndColumnsRecord() {
    const { gridUI } = getState();
    const { dbId, defaultAccessViewId, ROW_START_INDEX, ROW_STOP_INDEX } = gridUI;
    //refresh pathtag tree and pathtag column
    dispatch(
        gridUIActions.fetchTags({
            successCallback: () => {
                console.log('success moved path');
            },
            errorCallback: error => {
                console.log('error', error);
            }
        })
    );
    const { data } = await getColumnsRecordsApi({
        dbId,
        defaultAccessViewId,
        offset: ROW_START_INDEX,
        limit: ROW_STOP_INDEX,
        columnIds: [columnTypes.PATH_TAG],
        dataOptions: [DATA_QUERY_OPTIONS.DATA]
    });
    dispatch(dataActions.updateData({ newData: data, isCareData: true }));
}

export default gridDetailHandler;
