import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import ImageRender from './Files';
import SingleText from 'gridUI/table/grid/components/SingleText';
import MultiText from 'gridUI/table/grid/components/MultiText';
import Richtext from 'gridUI/table/grid/components/Richtext';
import JsonText from 'gridUI/table/grid/components/JsonText';
import YamlText from 'gridUI/table/grid/components/YamlText';
import RecordId from 'gridUI/table/grid/components/RecordId';
import HtmlText from 'gridUI/table/grid/components/HtmlText';
import Markdown from 'gridUI/table/grid/components/Markdown';
import Number from 'gridUI/table/grid/components/Number';
import BooleanRender from './Boolean';
import Date from 'gridUI/table/grid/components/Date';
import MultiSelect from 'gridUI/table/grid/components/MultiSelect';
import SingleSelect from 'gridUI/table/grid/components/SingleSelect';
import Ref from 'gridUI/table/grid/components/Ref';
import FormulaTextRender from 'gridUI/table/grid/components/FormulaText';
import TranslationText from 'gridUI/table/grid/components/TranslationText';
import AlterBy from 'gridUI/table/grid/components/AlterBy';
import * as columnTypes from 'const/columnTypes';
import { CELL_PADDING, BORDER_HIGHLIGHT, LOCALIZATION_FONT } from 'const/gridUI';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
// import { getElementOffsetPosition } from 'utils/gridUI/cell';
import hexToRgba from 'hex-to-rgba';
import Tooltip from 'components/tooltip/Base';
import { RESOLVED } from 'const/commentStatus';
// import IconLockSVG from 'assets/images/svg/IconLockSVG';
import CommentNotifySVG from 'assets/images/svg/CommentNotifySVG';
import TMToolTipPreviewSVG from 'assets/images/svg/localization/TMToolTipPreviewSVG';
// import { changeContext } from 'app/actions';
// import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import { getCellBackgroundByHex } from 'utils/color';
import { SYSTEM_COLUMN_IDS, PATH_TAG_ID } from 'const';
import { Trans } from 'react-i18next';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
// import CellPreviewSVG from 'assets/images/svg/CellPreviewSVG';
// import { isSelectedByRange } from 'utils/gridUI/range';
import LQAIconSVG from 'assets/images/svg/LQAIconSVG';
import {
    useCellMetaData,
    useIsSelectedByRowOrColumn,
    useIsSkeleton,
    useCellData,
    useIsCellReadyOnly,
    useCellParentText,
    useColumnDetailByColumnId,
    useResourceCellByRowId
} from 'hooks/advanced';
import { generateTags } from 'utils/gridUI/tag';
import { removeArrayInArray } from 'utils/object';
import { capitalize } from 'lodash';
import LangStatus from 'components/langStatus';
import { isTempId } from 'utils/uuid';
import { isCellOverlaid } from 'utils/gridUI/cell';
import { getCombinedColumnId } from 'utils/gridUI/column';

const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        '0%': {
            opacity: 0.2
        },
        '100%': {
            opacity: 0.8
        }
    },
    root: {
        padding: props => (props.type === columnTypes.FILES ? 0 : CELL_PADDING),
        // border: `${BORDER_HIGHLIGHT}px solid transparent`,
        // borderBottom: props => `${props.isRowOverLimit ? 2 : 1}px solid ${theme.colors.border}`,
        // borderRight: props => `${props.isRowOverLimit ? 2 : 1}px solid ${theme.colors.border}`,
        margin: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'default',
        background: props =>
            props.background
                ? props.fillColorBlindness === 'BLIND'
                    ? `${props.background} url(${getCellBackgroundByHex(props?.background)})`
                    : props.isReadOnly
                    ? `repeating-linear-gradient(-60deg,${props.background},${props.background} 4px, #D4D4D9 5px)`
                    : props.background
                : props.isReadOnly
                ? `repeating-linear-gradient(-60deg,transparent ,transparent 4px, #D4D4D9 5px)`
                : `transparent`,
        '& .highlight': {
            background: theme.colors.sun,
            color: theme.colors.white
        },
        '& .highlight-tb': {
            background: 'rgba(45, 151, 242, 0.25)'
        },
        '& .highlight-token, & .highlight-tb-token': {
            background: theme.colors.token,
            borderRadius: 2,
            fontWeight: 500,
            color: theme.colors.white
            // padding: `1px 4px`
        },
        '& .highlight-token-new, & .highlight-tb-token-new': {
            background: theme.colors.missingTag,
            borderRadius: 2,
            fontWeight: 500,
            color: theme.colors.white
        },
        position: 'relative',
        '& .showUserName': {
            position: 'absolute',
            pointerEvents: 'none',
            opacity: 0,
            top: 0,
            right: 0,
            fontSize: 8,
            width: 'auto',
            fontWeight: 'bold',
            paddingLeft: 3,
            paddingRight: 3,
            height: 12,
            color: theme.colors.white,
            borderBottomLeftRadius: 2
        },
        '&:hover': {
            '& .showUserName': {
                opacity: 1
            }
        }
    },
    cellSelected: {
        border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight} !important`
    },
    cursorPointer: {
        // cursor: 'pointer'
    },
    loading: {
        animation: '$blinker 1s infinite alternate linear',
        background: '#E6EAF1',
        borderRadius: 5
    },
    cellHighlight: {
        background: props =>
            props.background
                ? props.fillColorBlindness === 'BLIND'
                    ? `${hexToRgba(props.background, 0.8)} url(${getCellBackgroundByHex(props?.background)})`
                    : hexToRgba(props.background, 0.8)
                : theme.colors.selectionColor
    },
    cellCopyHighlight: {
        background: props =>
            props.background
                ? props.fillColorBlindness === 'BLIND'
                    ? `${hexToRgba(props.background, 0.8)} url(${getCellBackgroundByHex(props?.background)})`
                    : hexToRgba(props.background, 0.8)
                : theme.colors.solitude
    },
    cellContextMenu: {
        position: 'relative'
    },
    commentActiveWrapper: {
        width: 16,
        height: 16
    },
    cellTMStatus: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        fontSize: 7,
        width: 14,
        height: 9,
        fontWeight: 'bold',
        textTransform: 'upperCase',
        color: theme.colors.white,
        padding: 2,
        background: theme.colors.lightLavender,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer'
    },
    commentActive: {
        width: 0,
        height: 0,
        borderStyle: `solid`,
        borderWidth: `0 10px 10px 0`,
        borderColor: `transparent ${theme.colors.crusta} transparent transparent`
    },
    missingToken: {
        display: 'inline-block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
        borderRight: `12px solid transparent`,
        borderBottom: `12px solid ${theme.colors.missingTag}`
    },
    dependencyStatus: {
        position: 'absolute',
        top: 4,
        left: 4
    },

    textWhite: {
        color: theme.colors.white
    },
    dpFlex: {
        display: 'flex'
    },
    tmToolTip: {
        width: 270,
        padding: theme.spacing(2)
    },
    textLightGrey: {
        color: theme.colors.lightGreyBlue,
        textAlign: 'left'
    },
    previewTooltip: {
        width: 165,
        padding: theme.spacing(2)
    },
    key: {
        padding: theme.spacing(1),
        background: theme.colors.white,
        borderRadius: 2,
        color: theme.colors.primaryText
    },
    iconTop: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: '0 3px',
        zIndex: 2
    },
    lqaIcon: {
        width: 12,
        height: 12,
        paddingRight: 4
    },
    ignoredErrorTriangle: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        borderStyle: 'solid',
        borderWidth: '10px 0 0 10px',
        borderColor: `transparent transparent transparent ${theme.colors.brightRed}`
    },
    errorList: {
        margin: 0,
        paddingLeft: theme.spacing(4)
    }
}));

function checkTypeAndRender({ type, ...rest }) {
    if (rest?.isProcessing)
        return (
            <Grid
                container
                justify="center"
                alignItems="flex-start"
                style={{
                    height: rest?.rowHeight
                }}
                className={`cell_${rest?.rowIndex}_${rest?.columnIndex} RECORD_${rest?.rowId}`}
                {...rest}
            >
                <div
                    style={{
                        width: '100%',
                        borderRadius: 9,
                        background: `#F4F5F7`,
                        height: 12,
                        marginTop: 5
                    }}
                ></div>
            </Grid>
        );

    switch (type) {
        case columnTypes.FILES:
            return <ImageRender data={rest?.value} rowHeight={rest?.rowHeight} {...rest} />;
        case columnTypes.SINGLE_LINE:
            return <SingleText {...rest} />;
        case columnTypes.MULTIPLE_LINES:
        case columnTypes.PATH_TAG:
        case columnTypes.RESOURCE:
            return <MultiText {...rest} />;
        case columnTypes.RICH_TEXT:
            return <Richtext {...rest} />;
        case columnTypes.MARKDOWN:
            return <Markdown {...rest} />;
        case columnTypes.NUMBER:
            return <Number {...rest} />;
        case columnTypes.BOOLEAN:
            return <BooleanRender {...rest} />;
        case columnTypes.DATETIME:
            return <Date {...rest} />;
        case columnTypes.ALTERED_TIME:
            return <Date {...rest} />;
        case columnTypes.ALTERED_BY:
            return <AlterBy {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultiSelect {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelect {...rest} />;
        case columnTypes.REFERENCE:
            return <Ref {...rest} />;

        case columnTypes.FORMULA:
            return <FormulaTextRender {...rest} />;
        case columnTypes.TRANSLATION:
            return <TranslationText {...rest} />;
        case columnTypes.JSON_LD:
            return <JsonText {...rest} />;
        case columnTypes.HTML:
            return <HtmlText {...rest} />;
        case columnTypes.YAML:
            return <YamlText {...rest} />;

        case columnTypes.RECORD_ID:
            return <RecordId {...rest} />;
        default:
            return <SingleText {...rest} />;
    }
}

function CellRow({
    className,
    rowIndex,
    columnIndex,
    rowId,
    columnId,
    rowHeight,
    filterValue,
    columnWidth,
    isRowOverLimit,
    dependencies,
    positionTop,
    positionBottom,
    previousRowId,
    nextRowId,
    isForcedShowDependencyStatus,
    isCellCopyHighlight,
    isTmDisabled,
    isShareViewLink,
    t,
    fillColorBlindness,
    dependencyBlindness,
    isOverFileStorage,
    ldUserEmailMap,
    isShowTag,
    tokenDetection,
    predefinedTokens,
    isShowAutoQA,
    isCaseSensitive
}) {
    const dispatch = useDispatch();

    const cellMetaData = useCellMetaData({ columnId, recordId: rowId });

    const isUseSkeleton = useIsSkeleton({ rowIndex, columnIndex });

    const isSelectedByRowOrColumn = useIsSelectedByRowOrColumn({ columnId, rowIndex });

    const cellData = useCellData({ rowId, columnId });

    const column = useColumnDetailByColumnId(columnId);
    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[column?.group];
    }, [column]);

    const resource = useResourceCellByRowId(rowId);

    const isResource = React.useMemo(() => {
        return column?.type === columnTypes.RESOURCE;
    }, [column]);

    const highlightPositions = React.useMemo(() => {
        return cellData?.highlightPositions;
    }, [cellData]);

    const parentText = useCellParentText({ rowId, columnId });

    const dependencyStatus = React.useMemo(() => {
        return cellData?._dependencyStatus;
    }, [cellData]);

    const sourceStatus = React.useMemo(() => {
        return cellData?._sourceStatus;
    }, [cellData]);

    const tmStatus = React.useMemo(() => {
        return cellData?._tm;
    }, [cellData]);

    const background = React.useMemo(() => {
        return cellData?._color;
    }, [cellData]);

    const value = React.useMemo(() => {
        return isResource
            ? cellData?.dbName && cellData?.gridName
                ? `${cellData?.dbName}/${cellData?.gridName}`
                : ``
            : cellData?.value;
    }, [cellData, isResource]);

    const type = React.useMemo(() => {
        return getCorrectColumnType(column);
    }, [column]);

    const metaData = React.useMemo(() => {
        return column?.metadata?.[getCombinedColumnId({ ...resource, columnId: cellData?.columnId })];
    }, [column, resource, cellData]);

    const isReadOnly = useIsCellReadyOnly({ rowId, columnId });

    const classes = useStyles({
        background,
        isRowOverLimit,
        fillColorBlindness,
        dependencyBlindness,
        type,
        isReadOnly: isReadOnly && !isShareViewLink
    });

    const cellThreadId = React.useMemo(() => {
        return cellMetaData?.comment?.id;
    }, [cellMetaData]);

    const cellThreadStatus = React.useMemo(() => {
        return cellMetaData?.comment?.status;
    }, [cellMetaData]);

    const parentTokens = React.useMemo(() => {
        return !parentText ? [] : generateTags({ tokenDetection, predefinedTokens, text: parentText });
    }, [parentText, tokenDetection, predefinedTokens]);

    const referencedColumnType = React.useMemo(() => {
        return metaData?.referencedColumnType;
    }, [metaData]);

    const isSystemColumn = React.useMemo(() => {
        return SYSTEM_COLUMN_IDS.filter(id => ![PATH_TAG_ID, columnTypes.RECORD_ID].includes(id))?.includes(column?.id);
    }, [column]);

    const dependenciesFiltered = React.useMemo(() => {
        return dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    }, [dependencies]);

    const isChildDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => dpdc?.child === columnId);
        return Boolean(dpdc) && dpdc?.parent;
    }, [columnId, dependenciesFiltered]);

    const isParentDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => dpdc?.parent === columnId);
        return Boolean(dpdc) && dpdc?.child;
    }, [columnId, dependenciesFiltered]);

    const isHasDependency = React.useMemo(() => {
        const dpdc = dependenciesFiltered?.find(dpdc => {
            return dpdc?.child === columnId || dpdc?.parent === columnId;
        });
        return Boolean(dpdc) && (dpdc?.parent || dpdc?.child);
    }, [columnId, dependenciesFiltered]);

    const isShowDependencyStatus = React.useMemo(() => {
        return isForcedShowDependencyStatus ? true : isHasDependency;
    }, [isHasDependency, isForcedShowDependencyStatus]);

    const isRtl = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[column?.group]?.rtl;
    }, [column]);

    const _tokens = React.useMemo(() => {
        return generateTags({ tokenDetection, predefinedTokens, text: value });
    }, [tokenDetection, predefinedTokens, value]);

    const tagsMissingFromSources = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(parentTokens, _tokens)])];
    }, [parentTokens, _tokens]);

    const customTags = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(_tokens, parentTokens)])];
    }, [parentTokens, _tokens]);

    const warningTags = React.useMemo(() => {
        return (
            [columnTypes.TRANSLATION]?.includes(type) &&
            Boolean(isChildDependency) &&
            (customTags?.length > 0 || tagsMissingFromSources?.length > 0)
        );
    }, [isChildDependency, type, customTags, tagsMissingFromSources]);

    const ignoredAutoQAErrors = React.useMemo(() => {
        return (cellData?.metadata?.validations || []).filter(error => error.ignored);
    }, [cellData]);

    const uniqueErrorCategories = React.useMemo(() => {
        return [...new Set(ignoredAutoQAErrors.map(err => err.category))];
    }, [ignoredAutoQAErrors]);

    const viewTicketHandler = React.useCallback(() => {
        dispatch(gridActions.openViewCellTicket());
    }, [dispatch]);

    const selectSelf = React.useCallback(() => {
        dispatch(
            gridActions.selectRangeCell({
                rowStartIndex: rowIndex,
                rowStopIndex: rowIndex,
                columnStartIndex: columnIndex,
                columnStopIndex: columnIndex
            })
        );
    }, [dispatch, rowIndex, columnIndex]);

    const handleContext = React.useCallback(
        e => {
            if (!isCellOverlaid({ rowIndex, columnIndex })) {
                selectSelf();
            }
            const el = document.createElement('div');
            el.style.position = 'absolute';
            el.style.left = `${e.clientX}px`;
            el.style.top = `${e.clientY}px`;
            el.id = 'virtual-range-popper';
            document.body.appendChild(el);
            dispatch(gridActions.openCellContext());
        },
        [dispatch, selectSelf, rowIndex, columnIndex]
    );

    const handleOnCommentClick = React.useCallback(() => {
        dispatch(gridActions.openCellComment());
    }, [dispatch]);

    if (isUseSkeleton)
        return (
            <div
                style={{
                    width: columnWidth,
                    height: rowHeight,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    className={classes.loading}
                    style={{
                        width: columnWidth / 2,
                        height: 7
                    }}
                />
            </div>
        );

    return (
        <>
            <Grid
                id={`cell_${rowIndex}_${columnIndex}`}
                className={`
            ${isSelectedByRowOrColumn ? classes.cellHighlight : ''}
            ${isCellCopyHighlight ? classes.cellCopyHighlight : ``}
            ${className}
            ${classes.root} 
            cell_${rowIndex}_${columnIndex}
            ${fontJP}
            RECORD_${rowId}`}
                rindex={rowIndex}
                pst={positionTop}
                psb={positionBottom}
                prid={previousRowId}
                nrid={nextRowId}
                dir={isRtl ? 'rtl' : 'ltr'}
                onContextMenu={handleContext}
            >
                {checkTypeAndRender({
                    type,
                    value,
                    rowHeight,
                    columnWidth,
                    rowIndex,
                    columnIndex,
                    filterValue,
                    rowId,
                    columnId,
                    gridId: resource?.gridId,
                    defaultAccessViewId: resource?.viewId,
                    dbId: resource?.dbId,
                    customProperties: metaData?.customProperties || {},
                    options: metaData?.options || [],
                    isReadOnly: isReadOnly || isSystemColumn,
                    referencedColumnType,
                    positionTop,
                    positionBottom,
                    previousRowId,
                    nextRowId,
                    t,
                    isShareViewLink,
                    numberFormat: metaData?.numberFormat,
                    isOverFileStorage,
                    group: metaData?.group,
                    ldUserEmailMap,
                    isShowTag,
                    tokenDetection,
                    predefinedTokens,
                    parentTokens,
                    validations: metaData?.validations,
                    targetValidations: metaData?.targetValidations,
                    isChildDependency,
                    highlightPositions,
                    isCaseSensitive
                })}

                {!isShareViewLink && (
                    <div className={classes.iconTop} dir="ltr">
                        {cellThreadId && cellThreadStatus !== RESOLVED && (
                            <span className={classes.commentActiveWrapper} onClick={handleOnCommentClick}>
                                <CommentNotifySVG width="14" height="14" />
                            </span>
                        )}
                        {cellData?._ticket > 0 && (
                            <span className={classes.lqaIcon} onClick={viewTicketHandler}>
                                <LQAIconSVG width="14" height="14" />
                            </span>
                        )}
                    </div>
                )}
                {isShowAutoQA && ignoredAutoQAErrors.length > 0 && (
                    <Tooltip
                        title={
                            <Grid container direction="column" spacing={1}>
                                <Typography variant="overline">You ignored some errors:</Typography>
                                <ul className={classes.errorList}>
                                    {uniqueErrorCategories.map(category => (
                                        <li key={category}>
                                            <Typography style={{ fontWeight: 'normal' }} variant="overline">
                                                {capitalize(category)}
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>
                        }
                    >
                        <div className={classes.missingToken} dir="ltr"></div>
                    </Tooltip>
                )}

                {!isShowAutoQA && isShowTag && warningTags && (
                    <Tooltip
                        title={
                            <Grid container direction="column" spacing={2}>
                                {tagsMissingFromSources?.length > 0 && (
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <Typography variant="overline">Missing Tag from source:</Typography>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1}>
                                            {tagsMissingFromSources?.map(tag => {
                                                return (
                                                    <Grid item key={tag}>
                                                        <Typography style={{ fontWeight: 'normal' }} variant="overline">
                                                            • {tag}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                                {customTags?.length > 0 && (
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <Typography variant="overline">Tags not found in source:</Typography>
                                        </Grid>
                                        <Grid item container direction="column" spacing={1}>
                                            {customTags?.map(tag => {
                                                return (
                                                    <Grid item key={tag}>
                                                        <Typography style={{ fontWeight: 'normal' }} variant="overline">
                                                            • {tag}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        }
                    >
                        <div className={classes.missingToken} dir="ltr"></div>
                    </Tooltip>
                )}

                {tmStatus && !isTmDisabled && (
                    <Tooltip
                        placement="right"
                        title={
                            <Grid
                                container
                                spacing={1}
                                alignItems="flex-start"
                                direction="column"
                                className={classes.tmToolTip}
                            >
                                <Grid item>
                                    <Grid className={classes.previewImage}>
                                        <TMToolTipPreviewSVG />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" className={classes.textWhite}>
                                        TM Text
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Trans i18nKey="tm_tooltip_helper_description" t={t}>
                                        <Typography variant="body2" className={classes.textLightGrey}>
                                            This text is auto-populated from the TM
                                        </Typography>
                                        <Typography variant="body2" className={classes.textLightGrey}>
                                            ⮕ Edit cell or right click to hide this icon
                                        </Typography>
                                        <Typography variant="body2" className={classes.textLightGrey}>
                                            ⮕ Go to Home/Localization Settings to config the Translation Memory
                                        </Typography>
                                    </Trans>
                                </Grid>
                            </Grid>
                        }
                    >
                        <span className={classes.cellTMStatus}>TM</span>
                    </Tooltip>
                )}

                {isShowDependencyStatus && (
                    <div className={classes.dependencyStatus}>
                        <LangStatus
                            dependencyBlindness={dependencyBlindness}
                            dependencyStatus={dependencyStatus}
                            sourceStatus={isChildDependency && !isParentDependency ? null : sourceStatus}
                        />
                    </div>
                )}
            </Grid>
        </>
    );
}

CellRow.propTypes = {
    column: PropTypes.object,
    rowData: PropTypes.any
};

export default React.memo(CellRow);
