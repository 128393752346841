export const GET_DATABASE_LIST = '[DATABASE]GET_DATABASE_LIST';

export const GET_DATABASE_LIST_FAILED = '[DATABASE]GET_DATABASE_LIST_FAILED';
export const GET_DATABASE_LIST_SUCCESS = '[DATABASE]GET_DATABASE_LIST_SUCCESS';
export const GET_DATABASE_DETAIL_SUCCESS = '[DATABASE]GET_DATABASE_DETAIL_SUCCESS';

export const CREATE_DATABASE_SOCKET = '[DATABASE]CREATE_DATABASE_SOCKET';
export const CREATE_DATABASE = '[DATABASE]CREATE_DATABASE';
export const CREATE_DATABASE_FAILED = '[DATABASE]CREATE_DATABASE_FAILED';
export const CREATE_DATABASE_SUCCESS = '[DATABASE]CREATE_DATABASE_SUCCESS';

export const DELETE_DATABASE = '[DATABASE]DELETE_DATABASE';
export const DELETE_DATABASE_FAILED = '[DATABASE]DELETE_DATABASE_FAILED';
export const DELETE_DATABASE_SUCCESS = '[DATABASE]DELETE_DATABASE_SUCCESS';

export const UPDATE_DATABASE = '[DATABASE]UPDATE_DATABASE';
export const UPDATE_DATABASE_FAILED = '[DATABASE]UPDATE_DATABASE_FAILED';
export const UPDATE_DATABASE_SUCCESS = '[DATABASE]UPDATE_DATABASE_SUCCESS';
export const UPDATE_DATABASE_WITH_TEMP_ID = '[DATABASE]UPDATE_DATABASE_WITH_TEMP_ID';

export const TURN_ON_SHOW_FIRST_POP_UP_CREATE_DATABASE = '[DATABASE]TURN_ON_SHOW_FIRST_POP_UP_CREATE_DATABASE';
export const TURN_OFF_SHOW_FIRST_POP_UP_CREATE_DATABASE = '[DATABASE]TURN_OFF_SHOW_FIRST_POP_UP_CREATE_DATABASE';
export const TURN_ON_FIRST_CREATE_SKELETON = '[DATABASE]TURN_ON_FIRST_CREATE_SKELETON';

export const FETCH_DATABASE_LIST_BY_WORKSPACE = 'FETCH_DATABASE_LIST_BY_WORKSPACE';
export const FETCH_DATABASE_LIST_BY_WORKSPACE_SUCCESS = 'FETCH_DATABASE_LIST_BY_WORKSPACE_SUCCESS';
export const FETCH_DATABASE_LIST_BY_WORKSPACE_FAILED = 'FETCH_DATABASE_LIST_BY_WORKSPACE_FAILED';

export const FETCH_DATABASE_BACKUP_FILES = 'FETCH_DATABASE_BACKUP_FILES';
export const FETCH_DATABASE_BACKUP_FILES_SUCCESS = 'FETCH_DATABASE_BACKUP_FILES_SUCCESS';
export const FETCH_DATABASE_BACKUP_FILES_FAILED = 'FETCH_DATABASE_BACKUP_FILES_FAILED';
export const BACKUP_DATABASE_SOCKET_SUCCESS = 'BACKUP_DATABASE_SOCKET_SUCCESS';

export const SET_IS_BACKUPING = '[DATABASE]SET_IS_BACKUPING';
export const SET_DATABASE_STATUS = '[DATABASE]SET_DATABASE_STATUS';

export const SET_DATABASE_BACKUP_SCHEDULE = 'SET_DATABASE_BACKUP_SCHEDULE';

export const FETCH_DATABASE_GROUPS = '[WORKSPACE]FETCH_DATABASE_GROUPS';
export const FETCH_DATABASE_GROUPS_SUCCESS = '[WORKSPACE]FETCH_DATABASE_GROUPS_SUCCESS';
export const FETCH_DATABASE_GROUPS_FAILED = '[WORKSPACE]FETCH_DATABASE_GROUPS_FAILED';

export const SHARE_DATABASE_SUCCESS = '[WORKSPACE]SHARE_DATABASE_SUCCESS';

export const UNSHARE_DATABASE_SUCCESS = '[WORKSPACE]UNSHARE_DATABASE_SUCCESS';
