import classNames from 'classnames';
import { useIsColumnInRange } from 'hooks/gridUI';
import React from 'react';

const HeaderWrapper = ({ style, rowIndex, isLastColumn, columnId, columnIndex, children }) => {
    const isColumnSelected = useIsColumnInRange({ columnId, columnIndex });

    return (
        <div
            className={classNames(`grid-cell bg-white`, {
                '!bg-[#DBDCE1]': isColumnSelected,
                header: rowIndex < 1 || isLastColumn
            })}
            style={style}
        >
            {children}
        </div>
    );
};

export default React.memo(HeaderWrapper);
