import React from 'react';
import ActionStatus from 'gridUI/ActionStatus';
import { useDoingActionIds } from 'hooks/gridUI';

const ManageActionStatus = () => {
    const doingActionIds = useDoingActionIds();
    return <ActionStatus doingActionIds={doingActionIds} />;
};

export default React.memo(ManageActionStatus);
