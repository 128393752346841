import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FilterList from './FilterList';
import AddFilterButton from './AddFilterButton';
import Divider from 'components/divider/Base';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useViewFilterList } from 'hooks/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        width: 720
    },
    buttonAdd: {
        padding: `0px 20px`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        width: 'fit-content'
    },
    filterList: {
        padding: `0px 20px`,
        maxHeight: 480,
        overflowY: 'auto'
    },
    whiteText: {
        color: theme.colors.white
    },
    secondaryText: {
        color: theme.colors.secondaryText
    },
    permissionTooltip: {
        width: 300,
        textAlign: 'center'
    },
    mt16: {
        marginTop: 16
    }
}));

function FilterMenu({ t }) {
    const classes = useStyles();
    const viewFilters = useViewFilterList();

    const onCreated = () => {
        const node = document.getElementById('filterListBottom');

        scrollIntoView(node, {
            behavior: 'smooth',
            block: 'end',
            duration: 300,
            boundary: document.getElementById('filterListBoundary')
        });
    };

    return (
        <Grid className={classes.root} wrap="nowrap" container direction="column">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    className={classes.filterList}
                    id={'filterListBoundary'}
                    wrap="nowrap"
                >
                    <FilterList t={t} onCreated={onCreated} viewFilters={viewFilters} />
                    <div id="filterListBottom"></div>
                </Grid>
            </Grid>
            {!viewFilters.length && (
                <>
                    <Grid className={classes.mt16}>
                        <Divider />
                    </Grid>
                    <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_FILTER}>
                        {({ isReadOnly }) => (
                            <Tooltip
                                title={
                                    isReadOnly ? (
                                        <Grid container className={classes.permissionTooltip}>
                                            {t('toolbar_no_permission')}
                                        </Grid>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                <Grid item className={classes.buttonAdd}>
                                    <AddFilterButton t={t} onCreated={onCreated} isReadOnly={isReadOnly} />
                                </Grid>
                            </Tooltip>
                        )}
                    </AccessControl>
                </>
            )}
        </Grid>
    );
}

export default React.memo(FilterMenu);
