import React from 'react';

function AutoQASVG({ color = '#78778B', ...other }) {
    return (
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M5.75406 11.5461L5.99922 11.1647L9.90952 17.2778L9.25883 17.2788L5.75406 11.5461Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.89404 11.5368L6.0007 9.8149L11.2319 17.993L8.84343 17.9967L4.89404 11.5368ZM9.90952 17.2778L9.25883 17.2788L5.75406 11.5461L5.99922 11.1647L9.90952 17.2778Z"
                fill="#78778B"
            />
            <path
                d="M5.99922 11.1647L5.46915 11.1767L1.36169 17.2808L2.05938 17.2798L5.99922 11.1647Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.34783 10.4168L2.46428 17.9965L0 18L5.06806 10.4683L7.34783 10.4168ZM2.05938 17.2798L1.36169 17.2808L5.46915 11.1767L5.99922 11.1647L2.05938 17.2798Z"
                fill="#78778B"
            />
            <path
                d="M2.84588 5.91539L2.24831 5.92337L5.46915 11.1767L5.86851 10.5776L2.84588 5.91539Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.24556 5.19273L6.7402 10.583L5.44308 12.5288L0.964136 5.22323L3.24556 5.19273ZM2.24831 5.92337L5.46915 11.1767L5.86851 10.5776L2.84588 5.91539L2.24831 5.92337Z"
                fill="#78778B"
            />
            <path
                d="M13.3254 0.729833L12.4337 0.737821L5.466 11.1787L5.99922 11.1647L13.3254 0.729833Z"
                fill="#78778B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7273 0L6.39214 11.8719L4.08749 11.9323L12.0344 0.0241228L14.7273 0ZM5.99922 11.1647L5.466 11.1787L12.4337 0.737821L13.3254 0.729833L5.99922 11.1647Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(AutoQASVG);
