import store from 'store/configStore';
import * as socketTypes from 'socket/types';
import * as databaseActions from 'databases/actions';
import * as gridActions from 'grids/actions';
import * as gridUIActions from 'gridUI/actions';
import { DATABASE_STATUS } from 'const';
import { enqueueSnackbar } from 'notifier/actions';

const { dispatch } = store;

const backupRestoreHandler = ({ body, isDatabaseDetail }) => {
    const { type } = body;
    switch (type) {
        case socketTypes.BACKUP:
            return _backupHandler({ body, isDatabaseDetail });
        case socketTypes.RESTORE:
            return _restoreHandler({ body, isDatabaseDetail });
        default:
            return;
    }
};

function _backupHandler({ body }) {
    const { status, dbId } = body;
    switch (status?.toLowerCase()) {
        case DATABASE_STATUS.RUNNING:
            return _setDatabaseStatus({ dbId, status: DATABASE_STATUS.BACKING_UP });
        case DATABASE_STATUS.SUCCESS:
            return _databaseBackupSuccess(body);
        case DATABASE_STATUS.FAILED:
            return _databaseBackupFailed({ dbId });
        default:
            return;
    }
}

function _restoreHandler({ body, isDatabaseDetail }) {
    const { status, dbId } = body;
    switch (status?.toLowerCase()) {
        case DATABASE_STATUS.RUNNING:
            return _setDatabaseStatus({ dbId, status: DATABASE_STATUS.RESTORING });
        case DATABASE_STATUS.SUCCESS:
            return _databaseRestoreSuccess({ dbId, isDatabaseDetail });
        case DATABASE_STATUS.FAILED:
            return _databaseRestoreFailed({ dbId, isDatabaseDetail });
        default:
            return;
    }
}

function _databaseBackupSuccess(body) {
    const { dbId, alteredBy, endTime, result } = body;
    const backupFile = { id: dbId, alteredBy, alteredTime: endTime, backupFile: result };
    _setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE });
    dispatch(databaseActions._backupDatabaseSuccessSocket({ dbId, backupFile }));
    dispatch(databaseActions.setIsBackuping({ dbId, isBackuping: false }));
}

function _databaseBackupFailed({ dbId }) {
    _setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE });
    dispatch(
        enqueueSnackbar({
            message: 'Backup failed',
            type: 'info'
        })
    );
}

function _databaseRestoreSuccess({ dbId, isDatabaseDetail }) {
    _setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE });
    if (isDatabaseDetail) {
        dispatch(gridUIActions.gridRefreshOrDirectHome(dbId));
    } else {
        dispatch(
            gridActions.fetchGridsByDatabase({
                dbId,
                successCallback: () => {
                    console.log('fetch grids of Db success');
                },
                errorCallback: () => {
                    console.log('failed to fetch grids of Db');
                }
            })
        );
    }
}

function _databaseRestoreFailed({ dbId }) {
    _setDatabaseStatus({ dbId, status: DATABASE_STATUS.ACTIVE });
    dispatch(
        enqueueSnackbar({
            message: 'RESTORE failed',
            type: 'info'
        })
    );
}

function _setDatabaseStatus({ dbId, status }) {
    dispatch(databaseActions.setDatabaseStatus({ dbId, status }));
}

export default backupRestoreHandler;
