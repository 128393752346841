import request from './request';
import { APP_API_PREFIX_ORGANIZATION } from '../config';
import isEmpty from 'lodash/isEmpty';
//import { encodeURL } from 'utils/http';

const workspaceApis = {
    workspaces: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces`,
    workspace: ({ companyId, workspaceId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces/${workspaceId}`,
    graphql: () => `${APP_API_PREFIX_ORGANIZATION}/graphql`,
    projectRole: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/project-roles`,
    getWorkspaceViewsDetail: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/grid-views`,
    shareProject: ({ companyId, projectId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/projects/${projectId}/groups`,
    reorder: ({ companyId, projectId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces/${projectId}/reorder`,
    sortCriteria: ({ companyId, workspaceId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces/${workspaceId}/sort-criteria`,
    sortCriteriaDatabase: ({ companyId, workspaceId, dbId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/workspaces/${workspaceId}/databases/${dbId}/sort-criteria`,
    regionList: `${APP_API_PREFIX_ORGANIZATION}/api/v1/workspaces/regions`
};

export async function getWorkspaceRolesApi() {
    const query = `query{
        workspaceRoles{
            id 
            name
        }
      }`;

    const data = {
        query,
        operationName: null
    };
    const response = await request.post(workspaceApis.graphql(), data);
    return {
        workspaceRoles: response?.data?.data?.workspaceRoles || []
    };
}

export async function getWorkspaceViewsDetailApi({ companyId, paramOptions = {} }) {
    let extraUrl = `?`;
    if (!isEmpty(paramOptions)) {
        const type = paramOptions?.type;
        if (type) {
            extraUrl += `type=${type}`;
        }
    }
    const response = await request.get(`${workspaceApis.getWorkspaceViewsDetail({ companyId })}${extraUrl}`);
    return response.data;
}

export async function updateWorkspaceUserRoleApi({ companyId, body }) {
    const response = await request.put(`${workspaceApis.projectRole({ companyId })}`, body);
    return response.data;
}

export async function getGroupsAndMemberOfWorkspacesApi({ workspaceId }) {
    const query = `query($wspId: Long!){
        workspace(workspaceId: $wspId){
            groups{
                id
                name
                systemGroup
                type
                members{
                    user{
                        id
                        firstName
                        lastName
                        email
                        imageUrl
                        ldUser
                        ldManager
                    }
                    role{
                        id
                        name
                    }
                }
            }
        }
        workspaceRoles{
            id
            name
        }
    }`;

    const data = {
        query,
        variables: {
            wspId: workspaceId
        },
        operationName: null
    };
    const response = await request.post(workspaceApis.graphql(), data);
    return response.data.data;
}

export async function getGroupsWorkspaceApi({ workspaceId }) {
    const query = `query($wspId: Long!){
        workspace(workspaceId: $wspId){
            groups{
                id
                name
                members{
                    id
                    firstName
                    lastName
                    imageUrl
                }
            }
        }
    }`;

    const data = {
        query,
        variables: {
            wspId: workspaceId
        },
        operationName: null
    };
    const response = await request.post(workspaceApis.graphql(), data);
    return response?.data?.data?.workspace?.groups || [];
}

export async function getMembersWorkspaceApi({ workspaceId }) {
    const query = `query($wspId: Long!){
        workspace(workspaceId: $wspId){
            members: workspaceMembers{
                user{
                    id
                    firstName
                    lastName
                    email
                    imageUrl
                }
                role{
                    id
                    name
                }
            }
        }
        workspaceRoles{
            id 
            name
        }
    }`;

    const data = {
        query,
        variables: {
            wspId: workspaceId
        },
        operationName: null
    };
    const response = await request.post(workspaceApis.graphql(), data);
    return {
        members: (response?.data?.data?.workspace?.members || []).map(member => {
            const { user, role } = member;
            return {
                ...user,
                roleName: role.name,
                roleId: role.id
            };
        }),
        workspaceRoles: response?.data?.data?.workspaceRoles || []
    };
}

export async function getWorkspacesApi({ companyId }) {
    const response = await request.get(`${workspaceApis.workspaces({ companyId })}`);
    return response.data;
}

export async function getWorkspacesByGraphqlApi() {
    const query = `query{
        projects{
            description
            id
            name
            role{
                id
                name
                privileges{
                    name
                }
            }
        }
    }`;

    const data = {
        query,
        operationName: null
    };
    const response = await request.post(workspaceApis.graphql(), data);
    return response?.data?.data?.projects;
}

export async function getWorkspaceDetailApi({ companyId, workspaceId }) {
    const response = await request.get(workspaceApis.workspace({ companyId, workspaceId }));
    return response.data;
}

export async function createWorkspaceApi({ companyId, body }) {
    const response = await request.post(`${workspaceApis.workspaces({ companyId })}`, body);
    return response.data;
}

export async function deleteWorkspaceApi({ companyId, workspaceId }) {
    const response = await request.delete(workspaceApis.workspace({ companyId, workspaceId }));
    return response.data;
}

export async function updateWorkspaceApi({ companyId, workspace }) {
    const response = await request.put(workspaceApis.workspace({ companyId, workspaceId: workspace.id }), workspace);
    return response.data;
}

export async function getProjectsAndDatabasesApi() {
    const query = `query {
        projects {
            id name
            databases {
                id name
            }
        }
    }`;

    const data = {
        query,
        operationName: null
    };
    const response = await request.post(workspaceApis.graphql(), data);
    return response?.data?.data?.projects || [];
}

export async function getSharedGroupsOfProject({ companyId, projectId }) {
    const response = await request.get(workspaceApis.shareProject({ companyId, projectId }));
    return response.data;
}

export async function shareProjectApi({ companyId, projectId, groupIds }) {
    const response = await request.post(workspaceApis.shareProject({ companyId, projectId }), { groupIds });
    return response.data;
}

export async function unshareProjectApi({ companyId, projectId, groupIds }) {
    const response = await request.delete(workspaceApis.shareProject({ companyId, projectId }), { data: { groupIds } });
    return response.data;
}

export async function reorderProjectsApi({ companyId, projectId, body }) {
    const response = await request.put(workspaceApis.reorder({ companyId, projectId }), body);
    return response.data;
}

export async function getSortCriteriaApi({ companyId, workspaceId }) {
    const response = await request.get(workspaceApis.sortCriteria({ companyId, workspaceId }));
    return response.data;
}
export async function updateSortCriteriaApi({ companyId, workspaceId, dbId, body }) {
    let response = null;
    if (dbId) {
        response = await request.put(workspaceApis.sortCriteriaDatabase({ companyId, workspaceId, dbId }), body);
    } else {
        response = await request.put(workspaceApis.sortCriteria({ companyId, workspaceId }), body);
    }
    return response.data;
}

export async function getRegionList() {
    const response = await request.get(workspaceApis.regionList);
    return response.data;
}
