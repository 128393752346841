import React from 'react';
import Skeleton from 'components/skeleton/Base';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function MemberListTableSkeleton() {
    const classes = useStyles();

    return (
        <Grid className={classes.root} direction="column" spacing={4} container wrap="nowrap">
            <Grid item>
                <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                        <Skeleton width={240} height={36} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item style={{ width: '100%' }}>
                        <Skeleton width="100%" height={56} />
                    </Grid>
                    {Array.from(Array(8).keys()).map(index => (
                        <Grid item key={index} style={{ width: '100%' }}>
                            <Skeleton width="100%" height={72} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(MemberListTableSkeleton);
