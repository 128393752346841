import React from 'react';
import { Grid } from '@material-ui/core';
import { formatDateTimeLocal } from 'utils/gridUI/formatData';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import hexToRgba from 'hex-to-rgba';

const defaultState = {
    UPDATE: 'update',
    DELETE: 'delete',
    NORMAL: 'normal'
};

const useStyles = makeStyles(theme => ({
    spacing: {
        marginRight: theme.spacing(2)
    }
}));

function DateRender({ data, state = defaultState.NORMAL }) {
    const theme = useTheme();
    const classes = useStyles();
    if (!data || data.length === 0) return null;
    const [date, time] = formatDateTimeLocal(data);
    return (
        <Grid
            style={{
                background:
                    state === defaultState.UPDATE
                        ? hexToRgba(theme.colors.shamRock, 0.2)
                        : state === defaultState.DELETE
                        ? hexToRgba(theme.colors.brightRed, 0.2)
                        : ``,
                textDecoration: state === defaultState.DELETE ? 'line-through' : ``,
                height: 'fit-content'
            }}
            container
            wrap="nowrap"
        >
            <Grid item className={classes.spacing}>
                <p className="body2">{date}</p>
            </Grid>
            <Grid item>
                <p className="body2">{time}</p>
            </Grid>
        </Grid>
    );
}

export default React.memo(DateRender);
