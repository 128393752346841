import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import LDBasePortal from 'components/selects/LDBasePortal';
import CopySourceSVG from 'assets/images/svg/CopySourceSVG';
import SubtractSVG from 'assets/images/svg/SubtractSVG';
import InfoIconSVG from 'assets/images/svg/workspace/InfoIconSVG';
import IconLockSVG from 'assets/images/svg/IconLockSVG';
import { useParams } from 'react-router-dom';
import {
    useSelectedCellData,
    useDependencies,
    useMetaData,
    useViewColumnsWithReOrder,
    useData,
    useIsShowAutoQA,
    // useDataFromTBCurrentSource,
    useTokenDetection,
    usePredefinedTokens,
    useIsShowTag,
    useIsTmDisabled,
    useTMSuggestion,
    useDataFromTBTargetList,
    useIsChildDependencyByColumnId,
    useDefaultTm
} from 'hooks/gridUI';
import ColumnTypeDisplay from 'gridUI/ColumnTypeDisplay';
import * as columnTypes from 'const/columnTypes';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import LQATargetHighlighter from 'components/lqa/LQATargetHighlighter';
import { LANGUAGE_DETAIL_BY_CODE } from 'const/languageData';
import { generateTags } from 'utils/gridUI/tag';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TM from './tm';
import Glossary from './glossary';
import MissingTag from './missingTag';
import { getEditorValueBySplitLineBreak, serializeTag } from 'components/formula/serialize';
import { isNavigating, setIsNavigating } from 'gridUI/keyboardEvents/cell';
import { removeArrayInArray } from 'utils/object';
import TokenEditor from 'components/tag';
import { TEXT_LINE_HEIGHT, BORDER_HIGHLIGHT, LOCALIZATION_FONT } from 'const/gridUI';
import { Transforms, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { moveCursorToEnd } from 'utils/cursor';
import {
    isKbEscape,
    isKbTab,
    isKbShiftTab,
    isKbEnter,
    isKbShiftEnter,
    isKbCtrlEnter,
    isKbUndo,
    isKbCtrlShiftC,
    isKbF9,
    isKbShiftF9,
    isKbPageStart,
    isKbPageEnd,
    isKbCmdOption_CtrlAltT,
    isKbCtrlShiftU
} from 'utils/keyboard';
import { formatSearchTranslationResponse } from 'utils/gridUI/tm';
import Tooltip from 'components/tooltip/Base';
import diff_match_patch from 'diff-match-patch';
// import { getFriendlyTime } from 'utils/datetime';
// import { useMemberMappingByEmail } from 'hooks/permission/member';
// import { getFullName } from 'utils/name';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { checkContainId } from 'utils/clickAway';
import debounce from 'lodash/debounce';
import { liveQAApi } from 'services/autoQA';
import { isMac } from 'utils/os';
import TooltipArrows from '../common/TooltipArrows';
import ShowSpecialCharacter from '../common/ShowSpecialCharacter';
import ShowDiffChange from '../common/ShowDiffChange';
import 'diff-match-patch-line-and-word';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const diff = new diff_match_patch();

const FOOTER_PANEL_HEIGHT = 32;
const MIN_WIDTH = 200;

const useStyles = makeStyles(theme => ({
    root: {},
    panel: {
        // flexBasis: '35%',
        background: theme.colors.white,
        borderRadius: 4,
        height: `calc(100%)`,
        boxSizing: 'border-box',
        position: 'relative',
        overflow: 'hidden'
    },
    extraPanel: {
        // flexBasis: '25%',
        background: theme.colors.white,
        borderRadius: 4,
        height: '100%',
        boxSizing: 'border-box'
    },
    fullHeight: {
        height: '100%'
    },
    header: {
        padding: `8px 16px`,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    dropdown: {
        minWidth: 200,
        background: theme.colors.ghostwhite
    },
    content: {
        position: 'relative',
        height: `calc(100% - ${53 + FOOTER_PANEL_HEIGHT}px)`,
        padding: `12px`,
        borderRadius: '12px',
        '& .highlight': {
            background: theme.colors.sun,
            color: theme.colors.white
        },
        '& .highlight-tb': {
            background: 'rgba(45, 151, 242, 0.25)'
        },
        '& .highlight-token, & .highlight-tb-token': {
            background: theme.colors.token,
            borderRadius: 2,
            fontWeight: 500,
            color: theme.colors.white
            // padding: `1px 4px`
        },
        '& .highlight-token-new, & .highlight-tb-token-new': {
            background: theme.colors.missingTag,
            borderRadius: 2,
            fontWeight: 500,
            color: theme.colors.white
        }
    },
    targetContent: {
        position: 'relative',
        flex: 1,
        overflowY: 'auto',
        padding: `12px`,
        borderRadius: '12px',
        '& .highlight': {
            background: theme.colors.sun,
            color: theme.colors.white
        },
        '& .highlight-tb': {
            background: 'rgba(45, 151, 242, 0.25)'
        },
        '& .highlight-token, & .highlight-tb-token': {
            background: theme.colors.token,
            borderRadius: 2,
            fontWeight: 500,
            color: theme.colors.white
            // padding: `1px 4px`
        },
        '& .highlight-token-new, & .highlight-tb-token-new': {
            background: theme.colors.missingTag,
            borderRadius: 2,
            fontWeight: 500,
            color: theme.colors.white
        }
    },
    footer: {
        padding: `4px 14px`
    },
    extraHeader: {
        width: '100%',
        maxWidth: '100%',
        borderBottom: `1px solid ${theme.colors.border}`
    },
    extraContent: {
        height: 'calc(100% - 48px)',
        overflow: 'hidden'
    },
    lqaEditorSource: {
        '& *': {
            lineHeight: `22px`
        },
        '& p': {
            marginTop: 0,
            marginBottom: 0
        },
        '& blockquote': {
            margin: `0px 0px 14px`,
            padding: `12px 24px`,
            borderLeft: `2px solid ${theme.colors.midGrey}`,
            borderRadius: 4,
            background: theme.colors.ghostwhite
        },
        '& ul, & ol': {
            '& li': {
                marginBottom: 3
            },
            marginTop: 0,
            marginBottom: 14,
            borderRadius: 4
        }
    },
    tokenEditor: {
        width: '100%',
        minHeight: '100%',
        height: '100%',
        padding: `12px`,
        minWidth: MIN_WIDTH,
        overflowY: 'auto',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.border}`,
        borderRadius: 8,
        '&:focus-within': {
            border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`
        },
        '& .cell-editor': {
            textAlign: 'left',
            padding: `${theme.spacing(3)}px ${20}px`,
            fontSize: '0.875rem',
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            background: props => props.background || theme.colors.white,
            borderRadius: 6
        },
        '& *': {
            lineHeight: `22px`
        },
        '& p': {
            marginTop: 0,
            marginBottom: 0
        },
        '& blockquote': {
            margin: `0px 0px 14px`,
            padding: `12px 24px`,
            borderLeft: `2px solid ${theme.colors.midGrey}`,
            borderRadius: 4,
            background: theme.colors.ghostwhite
        },
        '& ul, & ol': {
            '& li': {
                marginBottom: 3
            },
            marginTop: 0,
            marginBottom: 14,
            borderRadius: 4
        }
    },
    sourceContent: {
        padding: 12,
        background: theme.colors.ghostwhite,
        minHeight: '100%',
        borderRadius: 8,
        maxHeight: `calc(100% - ${53 + FOOTER_PANEL_HEIGHT}px)`,
        overflowY: 'auto'
    },
    multiText: {
        width: '100%',
        height: '100%',
        padding: 12,
        borderRadius: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
        resize: 'auto !important',
        border: `1px solid ${theme.colors.border}`,
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.colors.highlight}`
        }
    },
    key: {
        padding: `2px 4px`,
        background: theme.colors.white,
        borderRadius: 2
    },
    deletedText: {
        background: theme.colors.pinka,
        textDecoration: `line-through`,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    diffText: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        lineHeight: `${TEXT_LINE_HEIGHT}px`
    },
    updatedText: {
        background: theme.colors.griptide
    },
    icon: {
        width: 24,
        height: 24,
        borderRadius: 4,
        background: theme.colors.solitude,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    lockIcon: {
        position: 'absolute',
        bottom: 12,
        right: 16,
        zIndex: 1
    },
    tabs: {
        '& .MuiTabScrollButton-root.Mui-disabled': {
            opacity: `0.4 !important`
        }
    },
    disabled: {
        background: theme.colors.ghostwhite,
        border: 0
    }
}));

function TranslatorContent({ height, targetSelected, targetOptions, disabledTarget, showDiff, setShowDiff }) {
    const classes = useStyles();
    const theme = useTheme();
    const { dbId, branchId, viewId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const viewColumns = useViewColumnsWithReOrder();
    const selectedCellData = useSelectedCellData();
    const dependencies = useDependencies();
    const metaData = useMetaData();
    const isShowAutoQA = useIsShowAutoQA();
    const data = useData();
    const tokenDetection = useTokenDetection({ dbId, gridId: branchId });
    const predefinedTokens = usePredefinedTokens();
    const isShowTag = useIsShowTag({ dbId, gridId: branchId });
    const [tabIndex, setTabIndex] = React.useState(0);
    const isTmDisabled = useIsTmDisabled();
    const oldValue = React.useRef(undefined);
    const isEnterAlready = React.useRef(false);
    const inputRef = React.useRef();
    const editorRef = React.useRef();
    const allowMoveCursorAtEnd = React.useRef(true);
    const [showSpecialCharacter, setShowSpecialCharacter] = React.useState(false);
    const [showSpecialCharacter2, setShowSpecialCharacter2] = React.useState(false);
    const rootRef = React.useRef();
    const undo = React.useRef([]);
    const redo = React.useRef([]);
    const undoTimeout = React.useRef();

    const defaultTM = useDefaultTm();

    // const memberMappingByEmail = useMemberMappingByEmail();

    const toggleShowDiff = React.useCallback(() => {
        setShowSpecialCharacter(false);
        setShowDiff(!showDiff);
    }, [showDiff, setShowDiff]);

    const toggleSpecialCharacter = React.useCallback(() => {
        setShowDiff(false);
        setShowSpecialCharacter(!showSpecialCharacter);
    }, [showSpecialCharacter, setShowDiff]);

    const fontJP = React.useMemo(() => {
        return LOCALIZATION_FONT?.[selectedCellData?.column?.group];
    }, [selectedCellData]);

    const toggleSpecialCharacter2 = React.useCallback(() => {
        setShowSpecialCharacter2(!showSpecialCharacter2);
    }, [showSpecialCharacter2]);

    const sourceSelected = React.useMemo(() => {
        if (!targetSelected) return null;

        const matchParentDpDc = dependencies?.find(
            dpDc => dpDc?.child === targetSelected?.value && metaData?.[dpDc?.parent]?.type === columnTypes.TRANSLATION
        );

        if (!matchParentDpDc) return null;

        const parentDetail = metaData?.[matchParentDpDc?.parent];

        return {
            label: <ColumnTypeDisplay {...parentDetail} />,
            value: matchParentDpDc?.parent,
            ...parentDetail
        };
    }, [targetSelected, dependencies, metaData]);

    const handleTargetChange = React.useCallback(
        option => {
            const newColumnIndex = viewColumns?.findIndex(col => col?.id === option?.value);

            dispatch(
                gridUIActions.selectRangeCell({
                    rowStartIndex: selectedCellData?.rowIndex,
                    rowStopIndex: selectedCellData?.rowIndex,
                    columnStartIndex: newColumnIndex,
                    columnStopIndex: newColumnIndex
                })
            );
        },
        [dispatch, selectedCellData, viewColumns]
    );

    const sourceCellData = React.useMemo(() => {
        return data?.[selectedCellData?.rowId]?.[sourceSelected?.value];
    }, [selectedCellData, data, sourceSelected]);

    const targetCellData = React.useMemo(() => {
        return data?.[selectedCellData?.rowId]?.[targetSelected?.value];
    }, [selectedCellData, data, targetSelected]);

    const languageDetail = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[targetSelected?.group];
    }, [targetSelected]);

    const oldSourceValue = React.useMemo(() => {
        return targetCellData?._dependencyPreviousValue;
    }, [targetCellData]);

    const languageSourceDetail = React.useMemo(() => {
        return LANGUAGE_DETAIL_BY_CODE?.[sourceSelected?.group];
    }, [sourceSelected]);

    // const isAccentInsensitive = React.useMemo(() => {
    //     return languageDetail?.accentInsensitive;
    // }, [languageDetail]);

    const sourceTokens = React.useMemo(() => {
        return generateTags({ tokenDetection, predefinedTokens, text: sourceCellData?.value });
    }, [tokenDetection, predefinedTokens, sourceCellData]);

    const sourceChildDependency = React.useMemo(() => {
        return dependencies?.find(
            dpDc => dpDc?.child === sourceSelected?.value && metaData?.[dpDc?.parent]?.type === columnTypes.TRANSLATION
        );
    }, [dependencies, sourceSelected, metaData]);

    // const originTokens = React.useMemo(() => {
    //     const parentText = data?.[selectedCellData?.rowId]?.[sourceChildDependency?.parent];
    //     return !sourceChildDependency ? [] : generateTags({ tokenDetection, predefinedTokens, text: parentText });
    // }, [data, sourceChildDependency, predefinedTokens, selectedCellData, tokenDetection]);

    const handleChange = React.useCallback((event, newValue) => {
        setTabIndex(newValue);
    }, []);

    const isHavingSourceText = React.useMemo(() => {
        return !!sourceCellData?.value;
    }, [sourceCellData]);

    const isTmEditing = React.useMemo(() => {
        return !isTmDisabled && isHavingSourceText;
    }, [isHavingSourceText, isTmDisabled]);

    const targetInfo = React.useMemo(() => {
        return {
            rowId: selectedCellData?.rowId,
            columnId: targetSelected?.value,
            group: targetSelected?.group,
            value: targetCellData?.value,
            rowIndex: selectedCellData?.rowIndex,
            columnIndex: viewColumns?.findIndex(col => col?.id === targetSelected?.value),
            type: targetSelected?.type,
            validations: targetCellData?.metadata?.validations
        };
    }, [selectedCellData, targetSelected, targetCellData, viewColumns]);

    const sourceInfo = React.useMemo(() => {
        return {
            rowId: selectedCellData?.rowId,
            columnId: sourceSelected?.value,
            group: sourceSelected?.group,
            value: sourceCellData?.value
        };
    }, [selectedCellData, sourceSelected, sourceCellData]);

    // const tbSource = useDataFromTBCurrentSource({ rowId: sourceInfo?.rowId, langSource: sourceInfo?.group });

    // const isTbSource = React.useMemo(() => {
    //     return tbSource?.length;
    // }, [tbSource]);

    const isShowEditor = React.useMemo(() => {
        return true || isShowAutoQA || isShowTag;
    }, [isShowAutoQA, isShowTag]);

    const sourceRawText = React.useMemo(() => {
        return getEditorValueBySplitLineBreak(sourceInfo.value);
    }, [sourceInfo.value]);

    const [rawText, setRawText] = React.useState(
        isShowEditor ? getEditorValueBySplitLineBreak(targetInfo.value) : targetInfo.value || ''
    );

    const [selectedText, setSelectedText] = React.useState(null);
    const [liveValidations, setLiveValidations] = React.useState([]);

    React.useEffect(() => {
        setSelectedText(null);
        oldValue.current = undefined;
        clearTimeout(undoTimeout);
        undo.current = [];
        redo.current = [];
    }, [targetInfo.rowId, targetInfo.columnId]);

    useClickAwaitListener(rootRef, e => {
        if (checkContainId(e, 'translator-delete')) {
            return false;
        }

        if (checkContainId(e, 'translator-copy')) {
            return false;
        }

        if (checkContainId(e, 'translate-special-character')) {
            return false;
        }

        handleClickOutSideTarget(e);
    });

    React.useEffect(() => {
        const editor = editorRef.current;
        if (editor) {
            Transforms.select(editor, Editor.start(editor, []));
            // ReactEditor.focus(editor);
            setRawText(getEditorValueBySplitLineBreak(targetInfo.value));
            setTimeout(() => {
                Transforms.select(editor, Editor.end(editor, []));
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

        // if (isShowEditor) {
        //     Transforms.select(editorRef.current, { path: [0, 0], offset: targetInfo.value?.length || 0 });
        // }
    }, [targetInfo.value, targetInfo.rowId, targetInfo.columnId, isShowEditor]);

    const value = React.useMemo(() => {
        if (!isShowEditor) return rawText;

        if (typeof rawText !== 'object') return rawText;
        return serializeTag(rawText);
    }, [isShowEditor, rawText]);

    const diffResult = React.useMemo(() => {
        return diff.diff_wordMode(oldSourceValue || '', sourceInfo.value || '');
    }, [oldSourceValue, sourceInfo]);

    const texts = useTMSuggestion({ rowId: targetInfo?.rowId, columnId: targetInfo.columnId });
    const tbTexts = useDataFromTBTargetList({
        langSource: sourceInfo?.group,
        langTarget: targetInfo.group,
        rowId: targetInfo?.rowId
    });

    const isChildDependency = useIsChildDependencyByColumnId(targetInfo?.columnId);
    // const isParentDependency = useIsParentDependencyByColumnId(targetInfo?.columnId);

    const filteredTexts = React.useMemo(() => {
        if (defaultTM?.isPausedConsuming) {
            return texts;
        }
        const filteredValues = texts?.filter(text => {
            return text?.toLowerCase()?.includes(value?.toLowerCase());
        });
        if (!selectedText) return filteredValues;
        if (filteredValues?.includes(selectedText)) {
            return filteredValues;
        } else {
            return filteredValues?.concat(selectedText);
        }
    }, [texts, value, selectedText, defaultTM]);

    React.useEffect(() => {
        if (isShowAutoQA) setLiveValidations(targetInfo.validations || []);
    }, [isShowAutoQA, targetInfo.validations]);

    const fetchLiveValidations = React.useCallback(
        debounce(async text => {
            try {
                const body = {
                    recordId: targetInfo?.rowId,
                    columnId: targetInfo.columnId,
                    targetText: serializeTag(text),
                    completed: false
                };

                const errors = await liveQAApi({ viewId, body });
                setLiveValidations(errors?.filter(err => !err.insource) || []);
            } catch (error) {
                console.log(error.message);
            }
        }, 500),
        []
    );

    const onClickTB = React.useCallback(
        text => {
            if (inputRef.current && !isShowEditor) {
                let { selectionStart, selectionEnd, value } = inputRef.current;
                let textBeforeCursorPosition = value.substring(0, selectionStart);
                let textAfterCursorPosition = value.substring(selectionEnd, value.length);
                let newValue = textBeforeCursorPosition + text + textAfterCursorPosition;

                setRawText(newValue);
                allowMoveCursorAtEnd.current = false;
                setTimeout(() => {
                    inputRef.current.selectionStart = inputRef.current.selectionEnd = selectionStart + text.length;
                    inputRef.current.focus();
                }, 0);
                inputRef.current.focus();
            } else if (editorRef) {
                if (editorRef?.current) {
                    setTimeout(() => {
                        const editor = editorRef?.current;
                        if (editor?.blurSelection) {
                            editor.selection = editor?.blurSelection;
                        }

                        ReactEditor.focus(editor);
                        Transforms.insertText(editor, text);
                    }, 0);
                }
            }
        },
        [isShowEditor]
    );

    const addTextToTM = React.useCallback(
        (alternative = false, onSuccess) => {
            if (!texts?.includes(value) && value) {
                dispatch(
                    gridUIActions.addTranslation({
                        rowId: targetInfo?.rowId,
                        columnId: targetInfo.columnId,
                        value,
                        body: {
                            sourceLang: sourceInfo?.group,
                            alternative,
                            translations: [
                                {
                                    lang: sourceInfo?.group,
                                    texts: [sourceInfo?.value]
                                },
                                {
                                    lang: targetInfo.group,
                                    texts: [value]
                                }
                            ]
                        },
                        successCallback: () => {
                            console.log('add text to TM success');
                            onSuccess && onSuccess();
                        },
                        errorCallback: () => {
                            console.log('failed to add text to TM');
                        }
                    })
                );
            }
        },
        [dispatch, sourceInfo, targetInfo, texts, value]
    );

    const updateTextsToTM = React.useCallback(() => {
        if (selectedText && value) {
            const newTexts = [value, ...texts?.filter(text => text !== selectedText)];
            dispatch(
                gridUIActions.addTranslation({
                    rowId: targetInfo?.rowId,
                    columnId: targetInfo.columnId,
                    value,
                    body: {
                        sourceLang: sourceInfo?.group,
                        translations: [
                            {
                                lang: sourceInfo?.group,
                                texts: [sourceInfo?.value]
                            },
                            {
                                lang: targetInfo.group,
                                texts: newTexts
                            }
                        ]
                    },
                    successCallback: ({ translations }) => {
                        console.log('update text to TM success');
                        setSelectedText(value);

                        const translationFormatted = formatSearchTranslationResponse({
                            translations,
                            targetLang: targetInfo.group
                        });
                        dispatch(
                            gridUIActions._searchTranslationSuccess({
                                rowId: targetInfo?.rowId,
                                columnId: targetInfo.columnId,
                                translations: translationFormatted
                            })
                        );
                    },
                    errorCallback: () => {
                        console.log('failed to update text to TM');
                    }
                })
            );
        }
    }, [dispatch, selectedText, sourceInfo, targetInfo, texts, value]);

    const handleAdd = React.useCallback(() => {
        addTextToTM(true, () => {
            setSelectedText(value);
        });
        isEnterAlready.current = true;
    }, [addTextToTM, value]);

    const handleUpdate = React.useCallback(() => {
        updateTextsToTM();
        isEnterAlready.current = true;
    }, [updateTextsToTM]);

    const handleFocus = React.useCallback(() => {
        const input = inputRef.current;
        if (input) {
            moveCursorToEnd(input);
            input && input.focus();
        }
    }, []);

    const handleTextChange = React.useCallback(
        text => {
            oldValue.current = undefined;
            if (inputRef.current && !isShowEditor) {
                setRawText(text);
                handleFocus();
            } else if (editorRef) {
                if (editorRef?.current) {
                    const editor = editorRef?.current;

                    setRawText([{ type: 'paragraph', children: [{ text }] }]);

                    setTimeout(() => {
                        Transforms.select(editor, Editor.end(editor, []));
                        ReactEditor.focus(editor);
                    }, 0);
                }
            }
            setSelectedText(text);
        },
        [handleFocus, isShowEditor]
    );

    React.useEffect(() => {
        if (isTmEditing) {
            dispatch(
                gridUIActions.searchTranslation({
                    rowId: targetInfo?.rowId,
                    columnId: targetInfo?.columnId,
                    targetLang: targetInfo?.group,
                    body: {
                        lang: sourceInfo?.group,
                        text: sourceInfo?.value,
                        targetLangs: [targetInfo?.group]
                    },
                    successCallback: () => {
                        console.log('query success');
                    },
                    errorCallback: () => {
                        console.log('query failed');
                    }
                })
            );
        }

        if (sourceInfo?.value) {
            dispatch(
                gridUIActions.searchInParagraph({
                    sourceRowId: sourceInfo.rowId,
                    sourceColumnId: sourceInfo.columnId,
                    targetLang: targetInfo.group,
                    body: {
                        paragraph: sourceInfo?.value,
                        sourceTermLang: sourceInfo?.group
                    }
                })
            );
        }
    }, [
        dispatch,
        sourceInfo.rowId,
        sourceInfo.columnId,
        sourceInfo.value,
        sourceInfo.group,
        targetInfo.group,
        targetInfo.rowId,
        targetInfo.columnId,
        isTmEditing
    ]);

    const _tags = React.useMemo(() => {
        return generateTags({ tokenDetection, predefinedTokens, text: value });
    }, [value, tokenDetection, predefinedTokens]);

    const missingTags = React.useMemo(() => {
        if (!sourceTokens?.length) return [];
        return [...new Set([...removeArrayInArray(sourceTokens, _tags)])];
    }, [sourceTokens, _tags]);

    const isRtlDirectionTarget = React.useMemo(() => {
        return languageDetail?.rtl;
    }, [languageDetail]);

    const isRtlDirectionSource = React.useMemo(() => {
        return languageSourceDetail?.rtl;
    }, [languageSourceDetail]);

    // const onFocus = React.useCallback(e => {
    //     const moveEnd = allowMoveCursorAtEnd.current;
    //     allowMoveCursorAtEnd.current = true;

    //     if (moveEnd) {
    //         moveCaretAtEnd(e);
    //     }
    // }, []);

    const isSameData = React.useCallback(() => {
        if ((targetInfo?.value === null || !targetInfo?.value) && !value) return true;
        return value === targetInfo?.value;
    }, [targetInfo, value]);

    const handleCellClickAwayAndStay = React.useCallback(
        focus => {
            isEnterAlready.current = true;
            dispatch(
                gridUIActions.cellClickAwayAndStay({
                    value,
                    rowId: targetInfo.rowId,
                    columnId: targetInfo.columnId,
                    type: targetInfo.type,
                    successCallback: () => {
                        if (focus) {
                            handleFocus();
                        }
                    }
                })
            );
        },
        [dispatch, value, targetInfo, handleFocus]
    );

    const handleCellClickAwayAndGoNextColumn = React.useCallback(
        focus => {
            isEnterAlready.current = true;

            dispatch(
                gridUIActions.cellClickAwayAndGoNextRow({
                    value,
                    rowId: targetInfo.rowId,
                    rowIndex: targetInfo.rowIndex,
                    columnId: targetInfo?.columnId,
                    columnIndex: targetInfo.columnIndex,
                    successCallback: () => {
                        if (focus) {
                            handleFocus();
                        }
                    }
                })
            );
        },
        [dispatch, value, targetInfo, handleFocus]
    );

    const handleUpdateAndNextRow = React.useCallback(
        focus => {
            isEnterAlready.current = true;

            dispatch(
                gridUIActions.cellClickAwayAndGoNextRow({
                    value: filteredTexts[0],
                    rowId: targetInfo.rowId,
                    rowIndex: targetInfo.rowIndex,
                    columnId: targetInfo?.columnId,
                    columnIndex: targetInfo.columnIndex,
                    successCallback: () => {
                        if (focus) {
                            handleFocus();
                        }
                    }
                })
            );
        },
        [dispatch, filteredTexts, targetInfo, handleFocus]
    );

    const handleDeleteSource = React.useCallback(
        e => {
            if ((targetInfo?.value === null || !targetInfo?.value) && !value) {
                handleFocus();
                return true;
            }

            if (!oldValue.current) {
                oldValue.current = isShowEditor ? [...rawText] : value;
            }

            isEnterAlready.current = true;

            setRawText(
                isShowEditor
                    ? [{ type: 'paragraph', children: [{ text: targetInfo.value ? targetInfo.value : '' }] }]
                    : ''
            );

            dispatch(
                gridUIActions.cellClickAwayAndStay({
                    value: null,
                    rowId: targetInfo.rowId,
                    columnId: targetInfo.columnId,
                    type: targetInfo.type
                })
            );

            handleFocus();
        },
        [dispatch, targetInfo, handleFocus, isShowEditor, value, rawText]
    );

    const handleSave = React.useCallback(
        e => {
            if (e) {
                e.preventDefault();
            }
            if (isSameData()) {
                handleFocus();
                return;
            }
            handleCellClickAwayAndStay(true);
            if (!defaultTM?.isPausedConsuming) {
                if (isTmEditing && selectedText) {
                    updateTextsToTM();
                    return;
                }
                if (isHavingSourceText) {
                    addTextToTM();
                }
            }
        },
        [
            handleFocus,
            isTmEditing,
            selectedText,
            handleCellClickAwayAndStay,
            isHavingSourceText,
            isSameData,
            addTextToTM,
            updateTextsToTM,
            defaultTM
        ]
    );

    const handleClickOutSideTarget = React.useCallback(
        e => {
            handleSave();
            if (editorRef.current) {
                ReactEditor.blur(editorRef.current);
            }
        },
        [handleSave]
    );

    const handleMoveNextRow = React.useCallback(
        e => {
            handleSave(e);
            if (isNavigating) return;

            setIsNavigating(true);
            setTimeout(() => {
                dispatch(
                    gridUIActions.moveCellToNextRow({
                        rowIndex: selectedCellData?.rowIndex,
                        columnIndex: selectedCellData?.columnIndex,
                        callback: () => {
                            setIsNavigating(false);
                        }
                    })
                );
            }, 0);
        },
        [dispatch, selectedCellData, handleSave]
    );

    const handleMovePreviousRow = React.useCallback(
        e => {
            handleSave(e);
            if (isNavigating) return;

            setIsNavigating(true);
            setTimeout(() => {
                dispatch(
                    gridUIActions.moveCellToPreviousRow({
                        rowIndex: selectedCellData?.rowIndex,
                        columnIndex: selectedCellData?.columnIndex,
                        callback: () => {
                            setIsNavigating(false);
                        }
                    })
                );
            }, 0);
        },
        [dispatch, selectedCellData, handleSave]
    );

    const handleCopySource = React.useCallback(
        e => {
            if (isShowEditor && editorRef?.current) {
                ReactEditor.blur(editorRef.current);
            }
            const redoValue = isShowEditor ? [...sourceRawText] : sourceInfo.value;
            setRawText(redoValue);

            setTimeout(() => {
                if (isShowEditor && editorRef?.current) {
                    const editor = editorRef?.current;
                    Transforms.select(editor, Editor.end(editor, []));
                    ReactEditor.focus(editor);
                    return;
                }

                let textarea = inputRef.current;
                if (textarea) {
                    textarea.focus({ preventScroll: true });
                }
            }, 100);
        },
        [sourceInfo, isShowEditor, sourceRawText]
    );

    const handleKeyDown = React.useCallback(
        e => {
            if (isKbEscape(e)) {
                dispatch(gridUIActions.cancelCellEdit());
            }

            if (isKbF9(e) && !isKbShiftF9(e) && isShowTag) {
                if (missingTags?.length && editorRef) {
                    const editor = editorRef?.current;
                    ReactEditor.focus(editor);
                    Transforms.insertText(editor, ` ${missingTags?.[0]}`);
                }
            }

            if (isKbShiftF9(e) && isShowTag) {
                if (missingTags?.length && editorRef) {
                    for (const tag of missingTags) {
                        const editor = editorRef?.current;
                        ReactEditor.focus(editor);
                        Transforms.insertText(editor, ` ${tag}`);
                    }
                }
            }

            if (isKbUndo(e)) {
                e.preventDefault();
                let lastUndo = undo.current[undo.current.length - 1] || [
                    { children: [{ text: '' }], type: 'paragraph' }
                ];
                undo.current = undo.current.slice(0, undo.current.length - 1);
                const editor = editorRef?.current;
                if (editor) {
                    Transforms.select(editor, Editor.start(editor, []));
                }
                clearTimeout(undoTimeout.current);
                undoTimeout.current = setTimeout(() => {
                    const editor = editorRef?.current;
                    if (editor) {
                        Transforms.select(editor, Editor.end(editor, []));
                        ReactEditor.focus(editor);
                    }
                }, 500);
                setRawText(lastUndo);
                redo.current.push(lastUndo);
                return;
            }

            if (isKbPageEnd(e)) {
                handleMoveNextRow(e);
                return;
            }

            if (isKbPageStart(e)) {
                handleMovePreviousRow(e);
                return;
            }

            if (isKbCtrlShiftC(e)) {
                e.preventDefault();
                if (sourceInfo?.value) {
                    handleCopySource();
                }
                return;
            }

            if (isKbCtrlEnter(e)) {
                e.preventDefault();
                if (isSameData()) {
                    return;
                }
                handleCellClickAwayAndGoNextColumn(true);
                if (isHavingSourceText) {
                    addTextToTM(true);
                    return;
                }
            }

            if (isKbEnter(e) && !isKbShiftEnter(e)) {
                e.preventDefault();
                if (isSameData()) {
                    dispatch(gridUIActions.cancelCellEdit());
                    dispatch(gridUIActions.moveCellToNextRow({ callback: () => {} }));
                    return;
                }
                handleCellClickAwayAndGoNextColumn(true);
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                        return;
                    }
                }
            }

            if (isKbTab(e)) {
                e.preventDefault();
                if (isSameData()) {
                    return;
                }
                handleCellClickAwayAndStay();
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                    }
                }
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();

                if (isSameData()) {
                    dispatch(gridUIActions.cancelCellEdit());

                    return;
                }
                handleCellClickAwayAndStay();
                if (!defaultTM?.isPausedConsuming) {
                    if (isTmEditing && selectedText) {
                        updateTextsToTM();
                        return;
                    }
                    if (isHavingSourceText) {
                        addTextToTM();
                    }
                }
            }
            if (isKbCmdOption_CtrlAltT(e)) {
                if (filteredTexts.length > 0) handleTextChange(filteredTexts[0]);
                return;
            }
            if (isKbCtrlShiftU(e)) {
                e.preventDefault();
                if (filteredTexts.length > 0) {
                    handleTextChange(filteredTexts[0]);
                    handleUpdateAndNextRow(true);
                }
                return;
            }
        },
        [
            handleMovePreviousRow,
            handleMoveNextRow,
            selectedText,
            updateTextsToTM,
            addTextToTM,
            dispatch,
            isSameData,
            isHavingSourceText,
            isTmEditing,
            handleCopySource,
            isShowTag,
            missingTags,
            handleCellClickAwayAndStay,
            sourceInfo,
            handleCellClickAwayAndGoNextColumn,
            handleTextChange,
            filteredTexts,
            handleUpdateAndNextRow,
            defaultTM
        ]
    );

    const tagsMissingFromSources = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(sourceTokens, _tags)])];
    }, [sourceTokens, _tags]);

    const customTags = React.useMemo(() => {
        return [...new Set([...removeArrayInArray(_tags, sourceTokens)])];
    }, [_tags, sourceTokens]);

    const warningTags = React.useMemo(() => {
        return (
            [columnTypes.TRANSLATION]?.includes(targetInfo?.type) &&
            isChildDependency &&
            (customTags?.length > 0 || tagsMissingFromSources?.length > 0)
        );
    }, [isChildDependency, targetInfo, customTags, tagsMissingFromSources]);

    const handleReplaceSuggestion = React.useCallback((e, validation, suggestion) => {
        const editor = editorRef?.current;

        ReactEditor.focus(editor);
        Transforms.insertText(editor, suggestion || '', {
            at: { anchor: { path: [0, 0], offset: validation.start }, focus: { path: [0, 0], offset: validation.end } }
        });
        ReactEditor.focus(editor);
    }, []);

    const targetEditorRender = React.useMemo(() => {
        const className = `${classes.tokenEditor} ${disabledTarget ? classes.disabled : ''} cell-editor`;
        const editorContent = isShowAutoQA ? (
            <LQATargetHighlighter
                value={rawText}
                onBlur={() => {}}
                dir={isRtlDirectionTarget ? 'rtl' : 'ltr'}
                onChange={value => {
                    setRawText(value);
                    if (serializeTag(value) !== serializeTag(rawText)) {
                        undo.current.push(rawText);
                    }
                    if (isShowAutoQA) fetchLiveValidations(value);
                }}
                placeholder=""
                predefinedTokens={predefinedTokens}
                tokenDetection={tokenDetection}
                className={className}
                inputRef={editorRef}
                isChildDependency={isChildDependency}
                validations={liveValidations}
                onKeyDown={handleKeyDown}
                rowId={targetInfo.rowId}
                columnId={targetInfo.columnId}
                onReplace={handleReplaceSuggestion}
                readOnly={disabledTarget}
                showTooltip={!disabledTarget}
                style={{
                    minHeight: '100%'
                }}
                isHighlightSymbolCharacter={showSpecialCharacter2}
            />
        ) : (
            <TokenEditor
                value={rawText}
                suggestions={[]}
                parentTags={sourceTokens}
                suggestionZIndex={1302}
                onBlur={() => {}}
                dir={isRtlDirectionTarget ? 'rtl' : 'ltr'}
                onChange={value => {
                    setRawText(value);
                    if (serializeTag(value) !== serializeTag(rawText)) {
                        undo.current.push(rawText);
                    }
                }}
                placeholder=""
                predefinedTokens={predefinedTokens}
                tokenDetection={tokenDetection}
                className={className}
                onKeyDown={handleKeyDown}
                inputRef={editorRef}
                isChildDependency={isChildDependency}
                style={{
                    minHeight: '100%'
                }}
                readOnly={disabledTarget}
                disabled={disabledTarget}
                isHighlightSymbolCharacter={showSpecialCharacter2}
                enableTag={isShowTag}
            />
        );
        return editorContent;
    }, [
        classes,
        disabledTarget,
        handleKeyDown,
        handleReplaceSuggestion,
        isChildDependency,
        isRtlDirectionTarget,
        isShowAutoQA,
        liveValidations,
        predefinedTokens,
        rawText,
        sourceTokens,
        targetInfo,
        tokenDetection,
        fetchLiveValidations,
        showSpecialCharacter2,
        isShowTag
    ]);

    return (
        <Grid
            style={{ height, padding: `12px 24px 0px`, width: '100%' }}
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            ref={rootRef}
            className={fontJP}
        >
            <Grid xs={4} item className={classes.fullHeight}>
                <Grid container direction="column" className={classes.panel} wrap="nowrap">
                    <Grid
                        item
                        container
                        className={classes.header}
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <Grid item alignItems="center" style={{ minHeight: 36, display: 'flex', alignItems: 'center' }}>
                            <ColumnTypeDisplay {...sourceSelected} />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.content}>
                        <p
                            className={classNames(
                                `body2 p-3 bg-grey-ghost min-h-full max-w-full rounded-lg overflow-y-auto max-h-[calc(100% - ${53 +
                                    FOOTER_PANEL_HEIGHT}px)]`
                            )}
                        >
                            {showDiff ? (
                                diffResult.map((item, index) => {
                                    const [status, content] = item;
                                    const getClassName = status => {
                                        switch (status) {
                                            case 0:
                                                return;
                                            case 1:
                                                return classes.updatedText;
                                            case -1:
                                                return classes.deletedText;
                                            default:
                                                return;
                                        }
                                    };
                                    return (
                                        <span key={index} className={getClassName(status)}>
                                            {content}
                                        </span>
                                    );
                                })
                            ) : isShowAutoQA ? (
                                <LQATargetHighlighter
                                    value={sourceRawText}
                                    onBlur={() => {}}
                                    dir={isRtlDirectionSource ? 'rtl' : 'ltr'}
                                    onChange={() => {}}
                                    placeholder=""
                                    className={`${classes.lqaEditorSource} cell-editor`}
                                    // autoFocus
                                    isChildDependency={isChildDependency}
                                    validations={[]}
                                    readOnly
                                    columnId={sourceInfo.columnId}
                                    rowId={sourceInfo.rowId}
                                    isHighlightSymbolCharacter={showSpecialCharacter}
                                />
                            ) : (
                                <TokenEditor
                                    value={sourceRawText}
                                    suggestions={[]}
                                    parentTags={[]}
                                    suggestionZIndex={1302}
                                    onBlur={() => {}}
                                    dir={isRtlDirectionSource ? 'rtl' : 'ltr'}
                                    placeholder=""
                                    className={`${classes.lqaEditorSource} cell-editor`}
                                    predefinedTokens={[]}
                                    tokenDetection={[]}
                                    isChildDependency={sourceChildDependency}
                                    style={{
                                        minHeight: '100%'
                                    }}
                                    readOnly
                                    isHighlightSymbolCharacter={showSpecialCharacter}
                                    enableTag={isShowTag}
                                />
                            )}
                        </p>
                        <Tooltip title={t('source_string_could_not_be_edited')}>
                            <Grid item className={classes.lockIcon}>
                                <IconLockSVG width={16} height={16} />
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid item className={classes.footer}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <p className="body2" style={{ fontSize: 12, color: theme.colors.midGrey }}>
                                    {sourceCellData?.value?.length || 0} characters
                                </p>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" alignItems="center">
                                    <ShowSpecialCharacter
                                        showSpecialCharacter={showSpecialCharacter}
                                        onClick={toggleSpecialCharacter}
                                    />
                                    <ShowDiffChange showDiff={showDiff} onClick={toggleShowDiff} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={4}
                item
                className={classes.fullHeight}
                style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
            >
                <Grid container direction="column" className={classes.panel} wrap="nowrap">
                    <Grid
                        item
                        container
                        className={classes.header}
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <Grid item className={classes.dropdown}>
                            <LDBasePortal
                                ddPlaceholder={'Select column'}
                                menuPlaceholder={t('global_label_find_a_column')}
                                options={targetOptions}
                                onChange={handleTargetChange}
                                defaultValue={targetSelected}
                                isMulti={false}
                                isDisabled={false}
                                id={'select-target-language'}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={1}>
                                <TooltipArrows handleUp={handleMovePreviousRow} handleDown={handleMoveNextRow} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.targetContent}>
                        {targetEditorRender}
                        {disabledTarget && (
                            <Tooltip title="Target strings could not be edited">
                                <Grid item className={classes.lockIcon}>
                                    <IconLockSVG width={16} height={16} />
                                </Grid>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item className={classes.footer}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <p className="body2" style={{ fontSize: 12, color: theme.colors.midGrey }}>
                                    {value?.length || 0} characters
                                </p>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                    {!isShowAutoQA && isShowTag && warningTags && (
                                        <Grid item>
                                            <Tooltip
                                                title={
                                                    <Grid container direction="column" spacing={2}>
                                                        {tagsMissingFromSources?.length > 0 && (
                                                            <Grid item container spacing={1}>
                                                                <Grid item>
                                                                    <p className="text-light font-medium">
                                                                        Missing Tag from source:
                                                                    </p>
                                                                </Grid>
                                                                <Grid item container direction="column" spacing={1}>
                                                                    {tagsMissingFromSources?.map(tag => {
                                                                        return (
                                                                            <Grid item key={tag}>
                                                                                <p
                                                                                    style={{ fontWeight: 'normal' }}
                                                                                    variant="overline"
                                                                                >
                                                                                    • {tag}
                                                                                </p>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        {customTags?.length > 0 && (
                                                            <Grid item container spacing={1}>
                                                                <Grid item>
                                                                    <p className="text-light font-medium">
                                                                        Tags not found in source:
                                                                    </p>
                                                                </Grid>
                                                                <Grid item container direction="column" spacing={1}>
                                                                    {customTags?.map(tag => {
                                                                        return (
                                                                            <Grid item key={tag}>
                                                                                <p
                                                                                    style={{ fontWeight: 'normal' }}
                                                                                    variant="overline"
                                                                                >
                                                                                    • {tag}
                                                                                </p>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                }
                                            >
                                                <IconButton size="small">
                                                    <InfoIconSVG
                                                        size={16}
                                                        height={16}
                                                        color={theme.colors.snackBarError}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    <ShowSpecialCharacter
                                        id="translate-special-character"
                                        showSpecialCharacter={showSpecialCharacter2}
                                        onClick={toggleSpecialCharacter2}
                                    />
                                    {!disabledTarget && (
                                        <Grid item>
                                            <Tooltip
                                                placement="bottom"
                                                title={
                                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <p className="text-light font-medium">
                                                                {t('copy_source')}:{' '}
                                                            </p>
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="body2 bg-white py-0.5 px-1 rounded">
                                                                {!isMac() ? 'Ctrl' : 'Cmd'}
                                                            </p>
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="body2 bg-white py-0.5 px-1 rounded">Shift</p>
                                                        </Grid>
                                                        <Grid item>
                                                            <p className="body2 bg-white py-0.5 px-1 rounded">C</p>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            >
                                                <IconButton
                                                    id="translator-copy"
                                                    size="small"
                                                    className={`${classes.icon} translator-trigger`}
                                                    onClick={handleCopySource}
                                                    disabled={disabledTarget}
                                                    style={{ background: theme.colors.white }}
                                                >
                                                    <CopySourceSVG />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    {!disabledTarget && (
                                        <Grid item>
                                            <Tooltip
                                                placement="bottom"
                                                title={
                                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <p className="text-light font-medium">Clear text:</p>
                                                        </Grid>
                                                        {isMac() ? (
                                                            <>
                                                                <Grid item>
                                                                    <p className="body2 bg-white py-0.5 px-1 rounded">
                                                                        ⌘
                                                                    </p>
                                                                </Grid>
                                                                <Grid item>
                                                                    <p className="body2 bg-white py-0.5 px-1 rounded">
                                                                        Backspace
                                                                    </p>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid item>
                                                                    <p className="body2 bg-white py-0.5 px-1 rounded">
                                                                        Ctrl
                                                                    </p>
                                                                </Grid>
                                                                <Grid item>
                                                                    <p className="body2 bg-white py-0.5 px-1 rounded">
                                                                        Backspace
                                                                    </p>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                }
                                            >
                                                <IconButton
                                                    id="translator-delete"
                                                    className={`${classes.icon} translator-trigger`}
                                                    onClick={handleDeleteSource}
                                                    size="small"
                                                    disabled={disabledTarget}
                                                    style={{ background: theme.colors.white }}
                                                >
                                                    <SubtractSVG />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={4} item className={classes.fullHeight}>
                <Grid container direction="column" wrap="nowrap" className={classes.extraPanel}>
                    <Grid item className={classes.extraHeader}>
                        <Tabs
                            className={classes.tabs}
                            value={tabIndex}
                            variant="scrollable"
                            onChange={handleChange}
                            aria-label="simple tabs example"
                        >
                            <Tab
                                style={{ minWidth: 160, padding: `6px 0` }}
                                label={
                                    <p className="body1" style={{ fontSize: 13 }}>
                                        {t('global_translation_memory')} ({filteredTexts?.length || 0})
                                    </p>
                                }
                            />
                            <Tab
                                style={{ minWidth: 124, padding: `6px 0` }}
                                label={
                                    <p className="body1" style={{ fontSize: 13 }}>
                                        Glossary ({tbTexts?.length || 0})
                                    </p>
                                }
                            />

                            <Tab
                                style={{ minWidth: 124, padding: `6px 0` }}
                                label={
                                    <p className="body1" style={{ fontSize: 13 }}>
                                        {t('missing_tags')} ({missingTags?.length})
                                    </p>
                                }
                            />
                        </Tabs>
                    </Grid>
                    <Grid item className={classes.extraContent} onKeyDown={handleKeyDown}>
                        {tabIndex === 0 && (
                            <TM
                                texts={filteredTexts}
                                value={value}
                                selectedText={selectedText}
                                onAdd={handleAdd}
                                onUpdate={handleUpdate}
                                onChange={handleTextChange}
                                disabled={disabledTarget}
                                defaultTMPausedConsuming={defaultTM.isPausedConsuming}
                            />
                        )}
                        {tabIndex === 1 && <Glossary disabled={disabledTarget} texts={tbTexts} onChange={onClickTB} />}
                        {tabIndex === 2 && (
                            <MissingTag disabled={disabledTarget} texts={missingTags} onChange={onClickTB} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TranslatorContent;
