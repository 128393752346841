import { mapResponse } from 'utils/socket';
import store from 'store/configStore';
import * as types from 'socket/types';
import cloneHandler from './clone';
import { isForYou } from 'socket/utils/room';

const { getState } = store;

const branchHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);

    const { action, objectType } = body;

    if (!serverSocketId) {
        console.log('OMG OMG OMG WHY THIS MISSING? ARE YOU OK?');
        return;
    }

    if (isForYou({ headers, state })) {
        if (objectType === types.GRID && action === types.BRANCH_GRID) {
            return cloneHandler({ body });
        }
        return;
    }
};

export default branchHandler;
