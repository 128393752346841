import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SystemGroupSVG from 'assets/images/svg/SystemGroupSVG';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    textCenter: {
        textAlign: 'center',
        overflowWrap: `break-word`,
        maxWidth: 300
    }
}));

function AdminAccess({ className }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            container
            className={`${classes.root} ${className}`}
            direction="column"
            wrap="nowrap"
            justify="center"
            spacing={2}
            alignItems="center"
        >
            <Grid item>
                <SystemGroupSVG />
            </Grid>
            <Grid item>
                <Typography variant="h4">{t('full_access_permission')}</Typography>
            </Grid>

            <Grid item>
                <Grid container direction="column" wrap="nowrap" justify="center" alignItems="center">
                    <Grid item className={classes.textCenter}>
                        <Typography variant="caption">{t('full_access_permission_text')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(AdminAccess);
