import React, { useCallback, useEffect, useState } from 'react';
import ButtonBase from 'components/button/Base';
import Dialog from 'components/dialog';
import { useTranslation } from 'react-i18next';
import SadToSeeYouGo from 'assets/images/sad-to-see-you-go.png';
import GridlyLoading from '!file-loader!assets/images/svg/GridlyLoading.svg';
import PreloadImages from 'components/preload/Image';
import { getUserCompanyApi } from 'services/company';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import CannotDeleteAccount from './CannotDeleteAccount';
import LogoGridUISVG from 'assets/images/svg/LogoGridUISVG';
import CanDeleteAccount from './CanDeleteAccount';
import LoadingPage from 'auth/LoadingPage';
import { setTimeoutInTime } from 'utils/usage';
import { AutoSizer } from 'react-virtualized-dn';

const DeleteAccount = () => {
    const { t } = useTranslation();
    const { dispatch } = useDispatch();
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
        if (openDeleteAccount) {
            const fetch = async () => {
                setFetching(true);
                const start = new Date().getTime();
                try {
                    const data = await getUserCompanyApi();
                    setCompanyInfo(data);
                    setCanDelete(!data?.hasOwner || data?.owners?.some(item => item?.totalMember <= 1));
                    setTimeoutInTime({
                        start,
                        timeout: 1500,
                        cb: () => {
                            setFetching(false);
                        }
                    });
                } catch (e) {
                    dispatch(
                        enqueueSnackbar({
                            message: e?.message,
                            type: 'error'
                        })
                    );
                    setTimeoutInTime({
                        start,
                        timeout: 1500,
                        cb: () => {
                            setOpenDeleteAccount(false);
                            setFetching(false);
                        }
                    });
                }
            };
            fetch();
        }
    }, [dispatch, openDeleteAccount]);

    const handleOpenDeleteAccount = useCallback(() => {
        setOpenDeleteAccount(true);
    }, []);

    const handleCloseDeleteAccount = useCallback(() => {
        setOpenDeleteAccount(false);
    }, []);

    return (
        <>
            <ButtonBase
                className="!bg-[#E86E5E]"
                variant="contained"
                width={150}
                height={36}
                onClick={handleOpenDeleteAccount}
            >
                {t(`delete_my_account`)}
            </ButtonBase>
            <PreloadImages paths={[GridlyLoading, SadToSeeYouGo]} />
            <Dialog
                className="!p-0"
                fullScreen
                open={openDeleteAccount}
                scroll="body"
                showTitle={false}
                onClose={fetching ? handleCloseDeleteAccount : null}
            >
                {fetching ? (
                    <LoadingPage />
                ) : (
                    <div className="p-8 pl-[72px] flex flex-nowrap gap-8 items-start bg-inherit">
                        <div className="flex-1">
                            <div className="cursor-pointer" onClick={handleCloseDeleteAccount}>
                                <LogoGridUISVG color="#2A293A" />
                            </div>
                            <div className="mt-[112px]">
                                {canDelete ? (
                                    <CanDeleteAccount companyInfo={companyInfo} onClose={handleCloseDeleteAccount} />
                                ) : (
                                    <CannotDeleteAccount companyInfo={companyInfo} onClose={handleCloseDeleteAccount} />
                                )}
                            </div>
                        </div>
                        <div className="flex-1 relative">
                            <div
                                className="absolute top-0 left-0 w-full pointer-events-none"
                                style={{ height: `calc(100vh - 64px)` }}
                            >
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <div
                                            className="bg-[#EBEAF3] flex items-center justify-center rounded-[40px] fixed"
                                            style={{ width, height }}
                                        >
                                            <img
                                                style={{
                                                    maxWidth: 560,
                                                    maxHeight: '100%',
                                                    height: 'auto',
                                                    width: 'auto'
                                                }}
                                                src={SadToSeeYouGo}
                                                alt="SadToSeeYouGo"
                                            />
                                        </div>
                                    )}
                                </AutoSizer>
                            </div>
                        </div>
                    </div>
                )}
            </Dialog>
        </>
    );
};

export default React.memo(DeleteAccount);
