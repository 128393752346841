import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DropdownSVG from 'assets/images/svg/DropdownSVG';
import CountryFlag from 'components/svg-icon/CountryFlag';
import hexToRgba from 'hex-to-rgba';
import { getFriendlyTime } from 'utils/datetime';

const useStyles = makeStyles(theme => ({
    statisticDetail: {
        padding: theme.spacing(3),
        borderRadius: 8,
        border: `1px solid ${theme.colors.divider}`
    },
    fieldLabelLang: {
        padding: theme.spacing(2, 3, 2, 0)
    },
    fieldLabel: {
        padding: theme.spacing(2, 3)
    },
    fieldContent: {
        padding: theme.spacing(2, 3),
        background: theme.colors.ghostwhite,
        borderRadius: 2
    },
    fieldError: {
        padding: theme.spacing(2, 3),
        background: hexToRgba(theme.colors.burntSlena, 0.1),
        borderRadius: 2
    },
    fieldErrorText: {
        color: hexToRgba(theme.colors.crusta, 0.8)
    },
    backIcon: {
        width: 24,
        height: 24,
        transform: 'rotate(90deg)'
    },
    backButton: {
        marginBottom: theme.spacing(3),
        cursor: 'pointer',
        width: 'fit-content'
    }
}));

function StatisticDetail({ currentGrid, selectedQAPair, viewColumns, onBack }) {
    const classes = useStyles();

    const selectedQAsourceColumn = selectedQAPair
        ? viewColumns?.find(o => o.id === selectedQAPair?.sourceColumnId)
        : null;
    const selectedQAtargetColumns = selectedQAPair
        ? viewColumns.filter(o => selectedQAPair.targetColumnIds.includes(o.id))
        : null;

    return (
        <>
            <Grid item container alignItems="center" className={classes.backButton} onClick={onBack}>
                <Grid item style={{ display: 'flex' }}>
                    <DropdownSVG className={classes.backIcon} />
                </Grid>
                <Grid item>
                    <p className="body2">Statistics</p>
                </Grid>
                <Grid item style={{ flex: 1 }} />
            </Grid>
            <Grid container className={classes.statisticDetail} direction="column">
                <Grid item>
                    <p className="body1 text-brand-main">Auto QA by lexiqa ID: {selectedQAPair.sourceColumnId}</p>
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item xs={4}>
                        <p className="body1 text-right py-2 pr-3.5">Source language:</p>
                    </Grid>
                    <Grid item xs={8}>
                        <p className="body1">
                            <CountryFlag languageCountryCode={selectedQAsourceColumn?.group} />{' '}
                            {selectedQAsourceColumn?.group}
                        </p>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item xs={4}>
                        <p className="body1 text-right py-2 pr-3.5">Target languages:</p>
                    </Grid>
                    <Grid item xs={8}>
                        {selectedQAtargetColumns.map(column => (
                            <p className="body1 inline mr-2.5" key={column?.id}>
                                <CountryFlag languageCountryCode={column?.group} /> {column?.group}
                            </p>
                        ))}
                    </Grid>
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item xs={4} className={classes.fieldLabel}>
                        <p className="body2 text-right">Project</p>
                    </Grid>
                    <Grid item xs={8} className={classes.fieldContent}>
                        <p className="body2">{currentGrid.name}</p>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" style={{ marginTop: 8 }}>
                    <Grid item xs={4} className={classes.fieldLabel}>
                        <p className="body2 text-right">Last updated</p>
                    </Grid>
                    <Grid item xs={8} className={classes.fieldContent}>
                        <p className="body2">{getFriendlyTime(selectedQAPair.lastRunTime)}</p>
                    </Grid>
                </Grid>
                <Grid item container alignItems="center" style={{ marginTop: 8 }}>
                    <Grid item xs={4} className={classes.fieldLabel}>
                        <p className="body2 text-right">Total errors</p>
                    </Grid>
                    <Grid item xs={8} className={classes.fieldError}>
                        <p className="body1 opacity-80 text-crusta">
                            {selectedQAPair.summary.totalErrorCount || selectedQAPair.summary.errorCount}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default React.memo(StatisticDetail);
