import React from 'react';

function IconAddMemberSVG({ color = '#fff', ...other }) {
    return (
        <svg {...other} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0589 2.43077L14.0593 0.5H12.6074L12.6068 2.43077L10.6667 2.43135V3.87628L12.6068 3.87585L12.6074 5.80763H14.0593L14.0589 3.87585L16 3.87628V2.43135L14.0589 2.43077ZM8.31617 2.4764C7.71615 1.90316 6.99614 1.61655 6.15612 1.61655C5.3161 1.61655 4.59609 1.90878 3.99608 2.49326C3.39606 3.05526 3.09606 3.72964 3.09606 4.51644C3.09606 5.30324 3.39606 5.97763 3.99608 6.53962C4.59609 7.10162 5.3161 7.38262 6.15612 7.38262C6.99614 7.38262 7.71615 7.10162 8.31617 6.53962C8.91618 5.97763 9.21618 5.30324 9.21618 4.51644C9.21618 3.72964 8.91618 3.04964 8.31617 2.4764ZM5.0041 3.43741C5.31611 3.14517 5.70011 2.99905 6.15612 2.99905C6.61213 2.99905 6.99614 3.14517 7.30815 3.43741C7.62015 3.72965 7.77615 4.08932 7.77615 4.51644C7.77615 4.94356 7.62015 5.29761 7.30815 5.57861C6.99614 5.85961 6.61213 6.00011 6.15612 6.00011C5.70011 6.00011 5.31611 5.85961 5.0041 5.57861C4.69209 5.29761 4.53609 4.94356 4.53609 4.51644C4.53609 4.08932 4.69209 3.72965 5.0041 3.43741ZM8.67617 8.63421C7.76415 8.43189 6.92414 8.33073 6.15612 8.33073C5.3881 8.33073 4.5481 8.43189 3.63607 8.63421C2.72405 8.83653 1.89004 9.17372 1.13402 9.6458C0.378004 10.1179 0 10.6461 0 11.2306V13.3887H12.3122V11.2306C12.3122 10.6461 11.9342 10.1179 11.1782 9.6458C10.4222 9.17372 9.5882 8.83653 8.67617 8.63421ZM3.02406 10.2696C4.05609 9.89869 5.1001 9.71324 6.15612 9.71324C7.21215 9.71324 8.25616 9.90431 9.28819 10.2865C10.3202 10.6462 10.8362 10.9609 10.8362 11.2306V12.0062H1.47603V11.2306C1.47603 10.9609 1.99203 10.6405 3.02406 10.2696Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(IconAddMemberSVG);
