import React from 'react';
import { TEXT_LINE_HEIGHT } from 'const/gridUI';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import * as gridActions from 'gridUI/actions';
import * as columnTypes from 'const/columnTypes';
import { beautifyJSONValue } from 'utils/gridUI/formatData';

import JsonEditor from 'components/jsoneditor';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        height: 500,
        background: props => (props?.isReadOnly ? theme.colors.paleGrey : theme.colors.white),
        pointerEvents: props => (props?.isReadOnly ? 'none' : ''),
        '& .ace_content, & .ace_gutter': {
            background: props => (props?.isReadOnly ? theme.colors.paleGrey : '')
        },
        '&:focus-within': {
            borderColor: theme.colors.highlight
        },
        '&  textarea': {
            width: '100%',
            overflow: 'hidden',
            borderRadius: 4,
            padding: `8px 10px`,
            lineHeight: `${TEXT_LINE_HEIGHT}px`
        }
    },
    jsonInput: {},
    editor: {
        width: '100%',
        height: '100%'
    }
}));

function Yaml({ value: cellValue, rowId, columnId, isReadOnly, onClose, isCellClickAway }) {
    const classes = useStyles({ isReadOnly });
    const [value, setValue] = React.useState(cellValue);
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const pasteRef = React.useRef(false);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    React.useEffect(() => {
        setValue(cellValue);
    }, [cellValue]);

    const isSameData = React.useCallback(() => {
        if ((cellValue === null || !cellValue) && !value) return true;
        return value === cellValue;
    }, [cellValue, value]);

    const updateCellData = React.useCallback(() => {
        dispatch(
            gridActions.updateCellValueWithoutCellInfo({
                value: value,
                rowId,
                columnId,
                columnType: columnTypes.JSON_LD
            })
        );
    }, [dispatch, value, rowId, columnId]);

    const handleClickAway = React.useCallback(
        e => {
            if (!isSameData()) {
                blurInput();
                updateCellData();
            }
            onClose && onClose();
        },
        [updateCellData, isSameData, onClose]
    );

    React.useEffect(() => {
        if (isCellClickAway) {
            handleClickAway();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCellClickAway]);

    const blurInput = () => {
        if (rootRef.current) {
            const parent = rootRef.current;
            const textarea = parent.querySelector('textarea');
            if (textarea) {
                textarea.blur();
            }
        }
    };

    const handleSaveAndQuit = e => {
        if (isSameData()) {
            blurInput();
            return;
        }
        updateCellData();
    };

    const handlePaste = () => {
        pasteRef.current = true;
    };

    const handleEscape = () => {
        blurInput();
        setValue(cellValue);
    };

    const handleChange = value => {
        let formatValue = value;
        if (pasteRef.current) {
            formatValue = beautifyJSONValue(formatValue);
            pasteRef.current = false;
        }
        setValue(formatValue);
    };
    return (
        <div className={classes.root} ref={rootRef}>
            <Grid container direction="column" className={classes.editor}>
                <JsonEditor
                    onEscape={handleEscape}
                    onSaveAndQuit={handleSaveAndQuit}
                    onPaste={handlePaste}
                    tabSize={2}
                    value={value}
                    onChange={handleChange}
                    placeholder={'Input your value'}
                    mode="yaml"
                />
            </Grid>
        </div>
    );
}

export default React.memo(Yaml);
