import React from 'react';

function RichtextSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill={color}
                d="M2.12586985,0 L2.29370202,0.167832168 L2.29258741,0.977902098 L13.7062937,0.977902098 L13.706298,0.167832168 L13.8741301,0 L15.8321678,0 L16,0.167832168 L16,2.12586985 L15.8321678,2.29370202 L15.020979,2.29258741 L15.020979,13.7062937 L15.8321678,13.706298 L16,13.8741301 L16,15.8321678 L15.8321678,16 L13.8741301,16 L13.706298,15.8321678 L13.7062937,15.020979 L2.29258741,15.020979 L2.29370202,15.8321678 L2.12586985,16 L0.167832168,16 L0,15.8321678 L0,13.8741301 L0.167832168,13.706298 L0.977902098,13.7062937 L0.977902098,2.29258741 L0.167832168,2.29370202 L0,2.12586985 L0,0.167832168 L0.167832168,0 L2.12586985,0 Z M13.7051748,2.29258741 L2.29258741,2.29258741 L2.29258741,13.7051748 L13.7051748,13.7051748 L13.7051748,2.29258741 Z M12.1398687,3.86013132 L12.1398687,7.24474029 L10.7132974,7.24474029 L10.7132867,5.28559441 L8.71272727,5.28559441 L8.71272727,10.7121678 L10.1817968,10.7132632 L10.1817968,12.1398345 L5.818169,12.1398345 L5.818169,10.7132632 L7.28615385,10.7121678 L7.28615385,5.28559441 L5.28559441,5.28559441 L5.28670261,7.24474029 L3.86013132,7.24474029 L3.86013132,3.86013132 L12.1398687,3.86013132 Z"
            />
        </svg>
    );
}

export default React.memo(RichtextSVG);
