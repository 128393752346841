import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'gridUI/actions';
import isEqual from 'lodash/isEqual';
import ViewCellInfo from './ViewCellInfo';
import RichText from 'components/richText/Base';
import deserialize from 'utils/deserializeHtmlToRichText';
import serialize from 'utils/serializeRichTextToHtml';
import { isKbEscape, isKbTab, isKbShiftTab, isKbShiftEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { AutoSizer } from 'react-virtualized-dn';
import { Portal } from 'react-overlays';

function RichTextEdit({
    columnId,
    column,
    value: cellValue,
    rowId,
    width,
    height,
    rowIndex,
    position,
    background,
    columnIndex,
    originalValue,
    ...rest
}) {
    const dispatch = useDispatch();
    const defaultValue = `<p></p>`;
    const [value, setValue] = useState(!cellValue ? deserialize(defaultValue) : deserialize(cellValue));

    const isEnterAlready = useRef(false);
    const rootRef = React.useRef();

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);
        if (isSameSource()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameSource()) {
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value: valueFormatted,
                    rowId,
                    columnId
                })
            );
        }
    };

    const handleOnChange = content => {
        if (serialize(content) !== value) {
            setValue(content);
        }
    };

    const isSameSource = () => {
        const valueFormatted = serialize(value);
        if (valueFormatted === defaultValue && valueFormatted === originalValue) return true;
        if (valueFormatted === defaultValue && (originalValue === null || !originalValue)) return true;
        if (valueFormatted !== originalValue) return false;
        if (valueFormatted === originalValue) return true;
        return false;
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            dispatch(gridActions.cancelCellEdit());
        }
        if (isKbShiftEnter(e)) {
            e.preventDefault();
            if (isSameSource()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAwayAndGoNextRow({
                    value: valueFormatted,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }
        if (isKbTab(e)) {
            e.preventDefault();

            console.log('isSameSource', isSameSource);
            if (isSameSource()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAwayAndGoNextColumn({
                    value: valueFormatted,
                    rowId,
                    rowIndex,
                    columnId,
                    columnIndex
                })
            );
        }
        if (isKbShiftTab(e)) {
            e.preventDefault();
            if (isSameSource()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            const valueFormatted = serialize(value);
            dispatch(
                gridActions.cellClickAwayAndGoPreviousColumn({
                    value: valueFormatted,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }
    };

    return (
        <Portal container={document.getElementById('scrollOverlay')}>
            <div
                ref={rootRef}
                className={
                    'absolute -top-[115px] bottom-0 h-full left-0 right-0 m-auto w-[930px] rounded-md shadow  bg-white pointer-events-auto md:max-w-[calc(100vw - 100px)] sm:calc(100vw - 40px)'
                }
                style={{
                    maxHeight: `calc(100% - 50px)`,
                    maxWidth: `calc(100% - 40px)`
                }}
            >
                <ViewCellInfo
                    rowIndex={rowIndex}
                    overrideStyle={{
                        left: 0,
                        top: 10
                    }}
                />

                <AutoSizer style={{ height: '100%', width: '100%' }}>
                    {({ width, height }) => {
                        return (
                            <div style={{ width, height }} className="flex flex-col">
                                <div
                                    style={{
                                        maxHeight: height - 30,
                                        height: height - 30
                                    }}
                                    className="flex-1 editor px-5 py-5 overflow-y-auto scrollbar-app"
                                >
                                    {value && (
                                        <RichText
                                            onKeyDown={handleKeyDown}
                                            containerRef={rootRef}
                                            value={value}
                                            onChange={handleOnChange}
                                            className={`cell-editor h-full`}
                                            autoFocus={false}
                                            initFocus={true}
                                        />
                                    )}
                                </div>

                                <div className="w-full h-[30px] flex justify-end items-center py-1 px-6 bg-grey-ghost rounded-bl-md rounded-br-md">
                                    <div>
                                        <p className={'body1 inline text-xs'}>Return</p>{' '}
                                        <p className={'body2 inline text-xs'}>to insert a line-break</p>
                                    </div>
                                    <div>
                                        <p className={'body1 inline text-xs'}>Shift + Return</p>{' '}
                                        <p className={'body2 inline text-xs'}>to finish cell edit</p>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </AutoSizer>
            </div>
        </Portal>
    );
}

export default React.memo(RichTextEdit, (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
});
