import React from 'react';

function ReopenCommentSVG({ color = '#EB4F5E', ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3289 1.35429C13.0232 1.04857 12.5275 1.04857 12.2218 1.35429C11.9161 1.66002 11.9161 2.1557 12.2218 2.46143L13.6689 3.90849H5.81637C4.87051 3.90849 3.96339 4.28423 3.29457 4.95306C2.62574 5.62188 2.25 6.52901 2.25 7.47487V8.86662C2.25 9.29898 2.6005 9.64948 3.03286 9.64948C3.46522 9.64948 3.81572 9.29898 3.81572 8.86662V7.47487C3.81572 6.94426 4.02651 6.43539 4.4017 6.06019C4.77689 5.685 5.28577 5.47422 5.81637 5.47422H13.6689L12.2218 6.92131C11.9161 7.22704 11.9161 7.72272 12.2218 8.02845C12.5275 8.33417 13.0232 8.33417 13.3289 8.02845L16.1124 5.24494C16.4182 4.93921 16.4182 4.44353 16.1124 4.1378L13.3289 1.35429ZM6.36994 11.0855C6.67566 11.3913 6.67566 11.887 6.36994 12.1927L4.91183 13.6508H12.7751C13.3057 13.6508 13.8146 13.44 14.1898 13.0648C14.565 12.6896 14.7758 12.1807 14.7758 11.6501V10.2584C14.7758 9.82603 15.1263 9.47553 15.5587 9.47553C15.991 9.47553 16.3415 9.82603 16.3415 10.2584V11.6501C16.3415 12.596 15.9658 13.5031 15.2969 14.172C14.6281 14.8408 13.721 15.2165 12.7751 15.2165H4.93389L6.36994 16.6526C6.67566 16.9583 6.67566 17.454 6.36994 17.7597C6.06421 18.0654 5.56853 18.0654 5.2628 17.7597L2.56299 15.0599C2.37293 14.9171 2.25 14.6897 2.25 14.4337C2.25 14.4318 2.25001 14.43 2.25002 14.4281C2.24861 14.2259 2.32503 14.0233 2.47929 13.8691L5.2628 11.0855C5.56853 10.7798 6.06421 10.7798 6.36994 11.0855Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(ReopenCommentSVG);
