import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function LinkElement({ element, children }) {
    const classes = useStyles();

    return (
        <a className={classes.root} href={element?.url || '#/'} title={element?.title || element?.url}>
            {children}
        </a>
    );
}

export default LinkElement;
