import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Grid, CircularProgress, InputAdornment, IconButton, Typography } from '@material-ui/core';

import InputText from 'components/inputs/InputText';
import ErrorMessage from './ErrorMessage';
import Button from 'components/button/Base';
import ArrowNextSVG from 'assets/images/svg/ArrowNextSVG';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import { sendManualTrack } from 'tracker';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import PasswordValidationPopup from 'components/PasswordValidationPopup';
import Checkbox from 'components/checkbox/Base';
import { GRIDLY_SITE_URL } from 'config';

const SignupSchema = object({
    firstName: string().required(i18n.t(`global_this_field_is_required`)),
    lastName: string().required(i18n.t(`global_this_field_is_required`)),
    password: string().required(i18n.t(`global_this_field_is_required`))
});

const useStyles = makeStyles(theme => ({
    signupForm: {
        width: 470,

        [theme.breakpoints.down('xs')]: {
            width: 'inherit'
        }
    },
    tellUs: {
        marginBottom: theme.spacing(3)
    },
    btnSubmit: {
        width: 120,
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    link: {
        color: `#7869B9`,
        textDecoration: 'none'
    }
}));

function SignUpForm({ initFormData, onNext, onBack }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isShowPassword, setIsShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: initFormData,
        validationSchema: SignupSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (!values.argeeTerm) {
                setSubmitting(false);
                return;
            }
            setStatus({ error: null });
            sendManualTrack({ type: `Click On Sign Up Stage 1` });
            onNext({
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password
            });
        }
    });
    const { errors, touched, values, isSubmitting, handleChange, handleBlur, handleSubmit, isValid, status } = formik;

    const passwordValue = values.password;
    const hasValidLength = passwordValue.length >= 12 && passwordValue.length <= 64;
    const hasAtLeastOneUpperCase = /^(?=.*[A-Z])/.test(passwordValue);
    const hasAtLeastOneLowerCase = /^(?=.*[a-z])/.test(passwordValue);
    const hasAtLeastOneDigit = /^(?=.*[0-9])/.test(passwordValue);
    const hasAtleastOneSpecialChar = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(passwordValue);
    const argeeTerm = values.argeeTerm;

    const isValidPassword =
        hasValidLength &&
        hasAtLeastOneUpperCase &&
        hasAtLeastOneLowerCase &&
        hasAtLeastOneDigit &&
        hasAtleastOneSpecialChar;

    function handleClickShowPassword() {
        setIsShowPassword(prev => !prev);
    }

    return (
        <>
            {status && status.error && <ErrorMessage message={status.error} />}
            <form id="sign-up-form" onSubmit={handleSubmit} className={classes.signupForm} autoComplete="off">
                <Typography className={classes.tellUs} variant="body1">
                    Tell us a little bit about you
                </Typography>
                <Grid container direction="column" spacing={4}>
                    <Grid item container spacing={2}>
                        <Grid item xs>
                            <InputText
                                placeholder={t(`global_first_name`)}
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={values.firstName}
                                height={40}
                                disabled={isSubmitting && argeeTerm}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.firstName && touched.firstName}
                                errorText={errors.firstName}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item xs>
                            <InputText
                                placeholder={t(`global_last_name`)}
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={values.lastName}
                                height={40}
                                disabled={isSubmitting && argeeTerm}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.lastName && touched.lastName}
                                errorText={errors.lastName}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <InputText
                            autoComplete="new-password"
                            placeholder={t(`global_password`)}
                            id="password"
                            name="password"
                            value={values.password}
                            height={40}
                            disabled={isSubmitting && argeeTerm}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-openreplay-obscured
                            error={errors.password && touched.password}
                            errorText={errors.password}
                            type={isShowPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowPassword} edge="end">
                                        {isShowPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item>
                        <PasswordValidationPopup
                            hasValidLength={hasValidLength}
                            hasAtLeastOneUpperCase={hasAtLeastOneUpperCase}
                            hasAtLeastOneLowerCase={hasAtLeastOneLowerCase}
                            hasAtLeastOneDigit={hasAtLeastOneDigit}
                            hasAtleastOneSpecialChar={hasAtleastOneSpecialChar}
                            isValidPassword={isValidPassword}
                            passwordValue={passwordValue}
                        />
                    </Grid>
                    <Grid item>
                        <Grid item container alignItems="center">
                            <Grid item>
                                <Checkbox
                                    name="argeeTerm"
                                    value={values.argeeTerm}
                                    height={40}
                                    disabled={isSubmitting && argeeTerm}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={values.argeeTerm}
                                    error={errors.argeeTerm && touched.argeeTerm}
                                    style={{ padding: 0, marginRight: 8 }}
                                />
                            </Grid>
                            <Grid item style={{ marginRight: 8 }}>
                                <Typography variant="body2">
                                    I have read and agree with the{' '}
                                    <a
                                        className={classes.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${GRIDLY_SITE_URL}/terms`}
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        className={classes.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`${GRIDLY_SITE_URL}/privacy`}
                                    >
                                        Privacy Policy
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item style={{ flex: 1 }} />
                        {/*<Grid item>
                            <Button width={120} onClick={onBack} variant="outlined">
                                <ArrowBackSVG style={{ marginRight: 8 }} />
                                {t(`global_back`)}
                            </Button>
                        </Grid>*/}
                        <Grid item>
                            <div className={classes.btnSubmit}>
                                <Button
                                    fullWidth
                                    type={argeeTerm && `submit`}
                                    variant="contained"
                                    disabled={
                                        isSubmitting ||
                                        !isValid ||
                                        initFormData?.isInputting ||
                                        !isValidPassword ||
                                        !argeeTerm
                                    }
                                >
                                    {t(`global_next`)}
                                    <ArrowNextSVG />
                                </Button>
                                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default SignUpForm;
