import React from 'react';
import classNames from 'classnames';

function NumberCheckDiff({ data = '', diffBefore }) {
    return (
        <div className="body2 break-words whitespace-pre-wrap">
            <p
                className={classNames(`body2 inline`, {
                    'bg-griptide': !diffBefore,
                    'bg-pinka line-through break-words whitespace-pre-wrap': diffBefore
                })}
            >
                {data}
            </p>
        </div>
    );
}

export default React.memo(NumberCheckDiff);
