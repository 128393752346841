import request from './request';
import { APP_API_PREFIX_ORGANIZATION } from '../config';

const companyApis = {
    list: `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies`,
    invite: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/account/multiple-invite`,
    updateCompany: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}`,
    getCompanyDetail: ({ companyId }) => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}`,
    createCompany: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies`,
    uploadLogo: () => `${APP_API_PREFIX_ORGANIZATION}/api/v1/media/company-logo`,
    internalInvite: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/ldusers/multiple-invite`,
    updateUserToInternalUser: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/ldusers/ldInvited`,
    previewBulkInvitation: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/invitation/preview-invite`,
    bulkInvite: ({ companyId }) =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/invitation/bulk-invite`,
    userFactor: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/factor`,
    requestOtpEmail: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/factor/requestOtpEmail`,
    generateScratchCodes: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/factor/generateScratchCodes`,
    samlConfig: companyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/saml/config`,
    samlCompanies: email => `${APP_API_PREFIX_ORGANIZATION}/public/api/sso-companies?email=${email}`,
    checkInvite: companyId => `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/invitation/preview`,
    enforcePasswordSetting: companyId =>
        `${APP_API_PREFIX_ORGANIZATION}/api/v1/companies/${companyId}/settings/password`,
    userCompany: `${APP_API_PREFIX_ORGANIZATION}/api/v1/account/company-info`
};

export async function createCompanyApi({ body }) {
    const response = await request.post(companyApis.createCompany(), body);
    return response.data;
}

export async function getCompanyInfoApi({ companyId }) {
    const response = await request.get(companyApis.getCompanyDetail({ companyId }));
    return response.data;
}

export async function updateCompanyInfoApi({ companyId, body }) {
    const response = await request.put(companyApis.updateCompany({ companyId }), body);
    return response.data;
}

export async function getCompaniesApi() {
    const response = await request.get(companyApis.list);
    return response.data;
}

export async function inviteUsersApi({ companyId, body }) {
    const response = await request.post(companyApis.invite({ companyId }), body);
    return response.data;
}

export async function checkInviteUsersApi({ companyId, body }) {
    const response = await request.post(companyApis.checkInvite(companyId), body);
    return response.data;
}

export async function uploadCompanyLogoApi(file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(companyApis.uploadLogo(), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function inviteInternalUsersApi({ companyId, body }) {
    const response = await request.post(companyApis.internalInvite({ companyId }), body);
    return response.data;
}

export async function updateUserToInternalUserApi({ companyId, body }) {
    const response = await request.put(companyApis.updateUserToInternalUser({ companyId }), body);
    return response.data;
}

export async function previewBulkInvitationApi({ formData, companyId }) {
    const response = await request.post(companyApis.previewBulkInvitation({ companyId }), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function bulkInviteApi({ companyId, body }) {
    const response = await request.post(companyApis.bulkInvite({ companyId }), body);
    return response.data;
}

export async function getUserFactorApi() {
    const response = await request.get(companyApis.userFactor);
    return response.data;
}

export async function enableUserFactorApi(otp) {
    const body = { enable: true, otp };
    const response = await request.put(companyApis.userFactor, body);
    return response.data;
}

export async function disableUserFactorApi(currentPassword) {
    const body = { enable: false, currentPassword };
    const response = await request.put(companyApis.userFactor, body);
    return response.data;
}

export async function requestOtpEmailApi() {
    const response = await request.put(companyApis.requestOtpEmail);
    return response.data;
}

export async function generateScratchCodesApi() {
    const response = await request.put(companyApis.generateScratchCodes);
    return response.data;
}

export async function getCompanySamlConfigApi(companyId) {
    const response = await request.get(companyApis.samlConfig(companyId));
    return response.data;
}

export async function updateCompanySamlUrlApi({ companyId, url, enforceSso, excludedUserIds, includedDomains }) {
    const body = { enabled: true, url, enforceSso, excludedUserIds, includedDomains };
    const response = await request.put(companyApis.samlConfig(companyId), body);
    return response.data;
}

export async function disableCompanySamlApi(companyId) {
    const body = { enabled: false, url: '' };
    const response = await request.put(companyApis.samlConfig(companyId), body);
    return response.data;
}

export async function getSamlCompaniesApi(email) {
    const response = await request.get(companyApis.samlCompanies(email));
    return response.data;
}

export async function getCompanyEnforcePasswordSetting(companyId) {
    const response = await request.get(companyApis.enforcePasswordSetting(companyId));
    return response.data;
}

export async function updateCompanyEnforcePasswordSetting(companyId, body) {
    const response = await request.put(companyApis.enforcePasswordSetting(companyId), body);
    return response.data;
}

export async function getUserCompanyApi() {
    const response = await request.get(companyApis.userCompany);
    return response.data;
}
