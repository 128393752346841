import React from 'react';
import ButtonBase from 'components/button/Base';
import { DISABLED_OPACITY } from 'const/style';

function AddDependencyButton({ isReadOnly, addDependencyHandler, t }) {
    return (
        <ButtonBase
            style={{
                pointerEvents: isReadOnly ? 'none' : '',
                opacity: isReadOnly ? DISABLED_OPACITY : 1
            }}
            width={160}
            onClick={addDependencyHandler}
            variant="outlined"
        >
            + {t('add_dependency_button')}
        </ButtonBase>
    );
}
export default React.memo(AddDependencyButton);
