export function getPossibleLinks({ str = '' }) {
    let links = [];
    if (!str) return links;

    const characters = str?.split('');
    const letterBuffer = [];
    for (const [index, c] of characters.entries()) {
        letterBuffer.push(c);

        if (_isSpace(c) || index === characters?.length - 1) {
            const word = letterBuffer?.join('');
            if (_isValidUrl(word?.trim())) {
                links.push(word?.trim());
            }
            letterBuffer.length = 0;
        }
    }
    return links;
}

function _isSpace(ch) {
    return /\s/i.test(ch);
}

function _isValidUrl(url) {
    // eslint-disable-next-line
    const linkRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi;
    return linkRegex.test(url);
}

export function stripHTML(originalString) {
    if (!originalString) return '';
    return originalString.replace(/(<([^>]+)>)/gi, '');
}

export function addTargetBlankToATagInText(text) {
    if (!text) return '';
    return text?.split('<a').join('<a target="_blank"');
}
