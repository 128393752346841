import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EggSearchGif from 'assets/images/egg_search.gif';
import ColorLinearProgress from 'components/progress/ColorLinearProgress';

const useStyles = makeStyles(theme => ({
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0,0,0,0)',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)'
        },
        '40%': {
            color: 'black',
            textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)'
        },
        '60%': {
            textShadow: '.25em 0 0 black, .5em 0 0 rgba(0,0,0,0)'
        },
        '80%, 100%': {
            textShadow: '.25em 0 0 black, .5em 0 0 black;'
        }
    },
    root: {
        width: '100%'
    },
    gifWrapper: {
        marginBottom: 24,
        textAlign: 'center'
    },
    labelWrapper: {
        marginBottom: 24,
        textAlign: 'center'
    },
    label: {
        '&:after': {
            content: "'.'",
            animation: `$dots 1s steps(5, end) infinite`
        }
    }
}));

const AnimationLoading = ({ className, data = {}, timeInterval = 1000, maxSecond }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [label, setLabel] = useState(data[0]);

    useEffect(() => {
        let second = 0;
        let interval = setInterval(() => {
            if (second > maxSecond) {
                second = 0;
            }
            if (data[second]) {
                setLabel(data[second]);
            }
            second += Math.min(timeInterval / 1000, 1000);
        }, timeInterval);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [data, maxSecond, timeInterval]);

    return (
        <Grid container className={`${classes.root} ${className}`} direction="column" justifyContent="center">
            <Grid item className={classes.gifWrapper}>
                <img className={classes.viewGift} src={EggSearchGif} alt="searching gif" />
            </Grid>
            <Grid item className={classes.labelWrapper}>
                <Typography variant="h4" className={classes.label}>
                    {label}
                </Typography>
            </Grid>
            <Grid item className={classes.progressWrapper}>
                <ColorLinearProgress background={theme.colors.lightLavender} foreground={theme.colors.solitude} />
            </Grid>
        </Grid>
    );
};

export default React.memo(AnimationLoading);
