import { useSelector } from 'react-redux';
import { findNodeByFullPath } from 'utils/gridUI/pathTag';
import { useViewColumnIds } from './column';
import * as columnTypes from 'const/columnTypes';

export function useIsPathTagOn() {
    return useSelector(state => state?.gridUI?.isPathTagOn);
}

export function usePathTagExpanded() {
    return useSelector(state => state.gridUI?.expanded);
}

export function usePathTagTree() {
    return useSelector(state => state.gridUI?.tree);
}

export function useNodeIdShowPopup() {
    return useSelector(state => state.gridUI?.nodeIdShowPopup);
}

export function useIsCreatingNode() {
    return useSelector(state => state.gridUI.isCreatingNode);
}

export function useHighlightNodeId(fullPath) {
    const tree = usePathTagTree();
    const node = findNodeByFullPath({ tree, fullPath: typeof fullPath === 'string' ? fullPath : fullPath?.[0] });
    return node?.id;
}

export function useDraggingRows() {
    return useSelector(state => state.gridUI.draggingRows);
}

export function useDraggingRowIndexes() {
    return useSelector(state => state.gridUI.draggingRowIndexes);
}

export function useDropPath() {
    return useSelector(state => state.gridUI?.dropPath);
}

export function useIsDraggingPathTag() {
    return useSelector(state => state.gridUI.isDraggingPathTag);
}

export function useIsPathTagExistedInView() {
    const viewIds = useViewColumnIds();
    return viewIds?.includes(columnTypes.PATH_TAG);
}

export function usePathTagRef() {
    return useSelector(state => state.gridUI.pathRef);
}

export function useDragNodeIds() {
    return useSelector(state => state.gridUI.dragNodeIds);
}

export function useDraggingPath() {
    return useSelector(state => state.gridUI?.draggingPath);
}

export function useIsFetchingPathTagTree() {
    return useSelector(state => state.gridUI?.isFetchingPathTagTree);
}
