import React from 'react';

const AutomationSetSVG = ({ color = '#78778B', ...other }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M10.5384 9.77327V1.50593C10.5384 1.22486 10.3069 1 10.0176 1C9.72824 1 9.49677 1.22486 9.49677 1.50593V9.77327C8.28929 10.0094 7.375 11.0475 7.375 12.2917C7.375 13.5359 8.28929 14.574 9.49677 14.8101V18.4903C9.49677 18.7714 9.72824 18.9963 10.0176 18.9963C10.3069 18.9963 10.5384 18.7714 10.5384 18.4903V14.8101C11.7459 14.574 12.6601 13.5359 12.6601 12.2917C12.6601 11.0512 11.7497 10.0094 10.5384 9.77327ZM10.0176 13.847C9.13414 13.847 8.4166 13.1499 8.4166 12.2917C8.4166 11.4335 9.13414 10.7364 10.0176 10.7364C10.901 10.7364 11.6185 11.4335 11.6185 12.2917C11.6185 13.1499 10.901 13.847 10.0176 13.847Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
            <path
                d="M4.16337 5.53092V1.50593C4.16337 1.22486 3.93191 1 3.64257 1C3.35324 1 3.12177 1.22486 3.12177 1.50593V5.53092C1.91429 5.76702 1 6.80512 1 8.04935C1 9.29357 1.91429 10.3317 3.12177 10.5678V18.4903C3.12177 18.7714 3.35324 18.9963 3.64257 18.9963C3.93191 18.9963 4.16337 18.7714 4.16337 18.4903V10.564C5.37086 10.3279 6.28515 9.28982 6.28515 8.0456C6.28515 6.80137 5.37086 5.76702 4.16337 5.53092ZM3.64257 9.60462C2.75914 9.60462 2.0416 8.90756 2.0416 8.04935C2.0416 7.19113 2.75914 6.49407 3.64257 6.49407C4.526 6.49407 5.24355 7.19113 5.24355 8.04935C5.24355 8.90756 4.52215 9.60462 3.64257 9.60462Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
            <path
                d="M16.8782 5.53092V1.50593C16.8782 1.22486 16.6467 1 16.3574 1C16.0681 1 15.8366 1.22486 15.8366 1.50593V5.53092C14.6291 5.76702 13.7148 6.80512 13.7148 8.04934C13.7148 9.29357 14.6291 10.3317 15.8366 10.5678V18.4941C15.8366 18.7751 16.0681 19 16.3574 19C16.6467 19 16.8782 18.7751 16.8782 18.4941V10.564C18.0857 10.3279 19 9.28982 19 8.0456C19 6.80137 18.0896 5.76702 16.8782 5.53092ZM16.3574 9.60462C15.474 9.60462 14.7564 8.90756 14.7564 8.04934C14.7564 7.19113 15.474 6.49407 16.3574 6.49407C17.2408 6.49407 17.9584 7.19113 17.9584 8.04934C17.9584 8.90756 17.2408 9.60462 16.3574 9.60462Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default React.memo(AutomationSetSVG);
