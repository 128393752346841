import React from 'react';

function ShareLinkOptionSVG({ ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.625 5.1875H10.1289C10.4167 6.52539 11.6086 7.53125 13.0312 7.53125C14.6682 7.53125 16 6.19946 16 4.5625C16 2.92554 14.6682 1.59375 13.0312 1.59375C11.6086 1.59375 10.4167 2.59961 10.1289 3.9375H0.625C0.279785 3.9375 0 4.21729 0 4.5625C0 4.90771 0.279785 5.1875 0.625 5.1875ZM13.0312 2.84375C13.979 2.84375 14.75 3.61475 14.75 4.5625C14.75 5.51025 13.979 6.28125 13.0312 6.28125C12.0835 6.28125 11.3125 5.51025 11.3125 4.5625C11.3125 3.61475 12.0835 2.84375 13.0312 2.84375ZM15.375 10.8125H5.87109C5.58325 9.47461 4.39136 8.46875 2.96875 8.46875C1.33179 8.46875 0 9.80054 0 11.4375C0 13.0745 1.33179 14.4062 2.96875 14.4062C4.39136 14.4062 5.58325 13.4004 5.87109 12.0625H15.375C15.7202 12.0625 16 11.7827 16 11.4375C16 11.0923 15.7202 10.8125 15.375 10.8125ZM2.96875 13.1562C2.021 13.1562 1.25 12.3853 1.25 11.4375C1.25 10.4897 2.021 9.71875 2.96875 9.71875C3.9165 9.71875 4.6875 10.4897 4.6875 11.4375C4.6875 12.3853 3.9165 13.1562 2.96875 13.1562Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(ShareLinkOptionSVG);
