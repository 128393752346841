import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import Connections from './connections';
import Sources from './sources';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        height: '100%'
    },
    fullHeight: {
        height: 'calc(100% - 48px - 32px - 86px)'
    },
    py4: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    CONNECTIONS: 'CONNECTIONS',
    SOURCE: 'SOURCE'
};

function Connector({ onClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const tabClasses = useTabStyles();
    const { t } = useTranslation();

    const [tabValue, setTabValue] = React.useState(TABS.CONNECTIONS);

    const handleChangeTab = React.useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    return (
        <Grid container className={classes.root} wrap="nowrap" direction="column">
            <Grid item style={{ marginBottom: theme.spacing(5) }}>
                <Tabs className={classes.tabs} value={tabValue} onChange={handleChangeTab}>
                    <Tab classes={tabClasses} value={TABS.CONNECTIONS} label={t('connections')} />
                    <Tab classes={tabClasses} value={TABS.SOURCE} label={t('sources')} />
                </Tabs>
            </Grid>
            <Grid item className={classes.fullHeight}>
                {tabValue === TABS.CONNECTIONS ? <Connections onClose={onClose} /> : <Sources onClose={onClose} />}
            </Grid>
        </Grid>
    );
}

export default Connector;
