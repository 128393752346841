import React from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useTranslation } from 'react-i18next';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `18px`
    },

    btnSubmit: {
        position: 'relative',
        '& button svg': {
            marginLeft: SPACING_LIST_NAME_WITH_ICON
        },
        '& .MuiButton-root': {
            background: props => (props.buttonColor ? props.buttonColor : ''),
            '&:hover': {
                background: props => (props?.buttonColor ? hexToRgba(props.buttonColor, 0.9) : '')
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    fullWidth: {
        width: '100%'
    }
}));

function ConfirmBoxSmall({
    title = 'Default Title',
    onCancel,
    onAgreed,
    notAgreeLabel,
    agreeLabel = 'Apply',
    className,
    isLoading,
    colorType = COLOR_TYPES.PRIMARY,
    agreeWidth = 80,
    notAgreeWidth = 80,
    btnSize = 'medium',
    onClickAway,
    header,
    ...rest
}) {
    const classes = useStyles();
    const rootRef = React.useRef();
    const { t } = useTranslation();

    useClickAwaitListener(rootRef, e => {
        onClickAway(e);
    });

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={`${classes.root} ${className}`}
            spacing={3}
            {...rest}
            ref={rootRef}
        >
            {header && (
                <Grid item className={classes.fullWidth}>
                    <Typography variant="h4">{header}</Typography>
                </Grid>
            )}
            <Grid item>
                <Typography variant="body2">{title}</Typography>
            </Grid>
            <Grid item>
                <Grid container direction="row" spacing={3} wrap="nowrap" justify="center" alignItems="center">
                    <Grid item>
                        <ButtonBase onClick={onCancel} variant="outlined" width={notAgreeWidth} size={btnSize}>
                            {notAgreeLabel || t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <div className={classes.btnSubmit}>
                            <ButtonBase
                                size={btnSize}
                                onClick={onAgreed}
                                width={agreeWidth}
                                disabled={isLoading}
                                variant="contained"
                                color={colorType}
                            >
                                {agreeLabel}
                            </ButtonBase>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default React.memo(ConfirmBoxSmall);
