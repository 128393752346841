import React from 'react';
import Skeleton from 'components/skeleton/Base';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function WorkspaceOfMemberTableSkeleton() {
    const classes = useStyles();

    return (
        <Grid className={classes.root} container direction="column" spacing={1} alignItems="center" wrap="nowrap">
            <Grid item style={{ width: '100%' }}>
                <Skeleton width="100%" height={56} />
            </Grid>
            {Array.from(Array(3).keys()).map(index => (
                <Grid item key={index} style={{ width: '100%' }}>
                    <Skeleton width="100%" height={48} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(WorkspaceOfMemberTableSkeleton);
