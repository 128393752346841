import React from 'react';

function ArrowKeyUpSVG({ color = '#78778B', className, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M2.66667 7.99967L3.60667 8.93967L7.33334 5.21968L7.33334 13.333L8.66667 13.333V5.21968L12.3867 8.94634L13.3333 7.99967L8 2.66634L2.66667 7.99967Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowKeyUpSVG);
