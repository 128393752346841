import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomSVG from 'assets/images/svg/CustomSVG';
import MediumSVG from 'assets/images/svg/MediumSVG';
import RowHeightSVG from 'assets/images/svg/RowHeightSVG';
import ShortSVG from 'assets/images/svg/ShortSVG';
import TallSVG from 'assets/images/svg/TallSVG';
import PopperMenu from 'components/menus/Popper';
import Tooltip from 'components/tooltip/Base';
import { RESOURCE_TYPES, USER_SETTINGS } from 'const';
import { ROW_HEIGHT_OPTIONS } from 'const/gridUI';
import { useRowHeight } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as gridActions from '../actions';
import Dialog from 'components/dialog/Dialog';
import ListItem from 'components/list/Item';
import { useUserViewSettings } from 'hooks/workspace';
import { useTranslation } from 'react-i18next';
import * as workspaceActions from 'workspaces/actions';
import CustomRowHeight from '../CustomRowHeight';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    popperMenu: {
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto',
            overflowY: 'hidden',
            width: '90%',
            maxWidth: 550,

            '& .popper-content': {
                overflow: 'auto'
            },
            '&.small': {
                maxWidth: 360
            }
        }
    }
}));

const ManageHeight = ({ isDesktop, handleMoreActionsClickAway, isShareViewLink, isInIconMore }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dbId, workspaceId, viewId } = useParams();
    const rowHeight = useRowHeight();
    const userViewSetting = useUserViewSettings({ wsId: workspaceId, dbId, viewId });
    const [defaultRowHeightAnchorEl, setDefaultRowHeightAnchorEl] = React.useState(null);
    const [openCustomRowHeight, setOpenCustomRowHeight] = React.useState(false);

    const closeCustomRowHeightHandler = React.useCallback(() => {
        if (isInIconMore) {
            handleMoreActionsClickAway && handleMoreActionsClickAway(null);
        } else {
            setOpenCustomRowHeight(false);
        }
    }, [handleMoreActionsClickAway, isInIconMore]);

    const handleDefaultRowHeightClickAway = React.useCallback(event => {
        setDefaultRowHeightAnchorEl(null);
    }, []);

    const handleDefaultRowHeightClick = React.useCallback(
        event => {
            setDefaultRowHeightAnchorEl(defaultRowHeightAnchorEl ? null : event.currentTarget);
        },
        [defaultRowHeightAnchorEl]
    );

    const changeDefaultRowHeight = React.useCallback(
        height => {
            dispatch(gridActions.changeDefaultRowHeight({ height }));
            if (!isShareViewLink) {
                dispatch(
                    workspaceActions.setUserSettings({
                        dbId,
                        workspaceId,
                        resourceId: viewId,
                        resourceType: RESOURCE_TYPES.VIEW,
                        value: {
                            ...userViewSetting,
                            [USER_SETTINGS.VIEW.DEFAULT_ROW_HEIGHT]: height
                        }
                    })
                );
            }

            handleDefaultRowHeightClickAway();
            handleMoreActionsClickAway && handleMoreActionsClickAway();
        },
        [
            dispatch,
            viewId,
            userViewSetting,
            dbId,
            workspaceId,
            isShareViewLink,
            handleMoreActionsClickAway,
            handleDefaultRowHeightClickAway
        ]
    );

    const openCustomRowHeightHandler = React.useCallback(() => {
        setOpenCustomRowHeight(true);
        handleDefaultRowHeightClickAway();
        if (!isInIconMore) {
            handleMoreActionsClickAway && handleMoreActionsClickAway(null);
        }
    }, [isInIconMore, handleDefaultRowHeightClickAway, handleMoreActionsClickAway]);

    const handleCustomRowHeightChange = React.useCallback(
        newRowHeight => {
            handleMoreActionsClickAway && handleMoreActionsClickAway(null);
            dispatch(gridActions.changeDefaultRowHeight({ height: newRowHeight }));
            if (!isShareViewLink) {
                dispatch(
                    workspaceActions.setUserSettings({
                        dbId,
                        workspaceId,
                        resourceId: viewId,
                        resourceType: RESOURCE_TYPES.VIEW,
                        value: {
                            ...userViewSetting,
                            [USER_SETTINGS.VIEW.DEFAULT_ROW_HEIGHT]: newRowHeight
                        },
                        successCallback: () => {
                            setOpenCustomRowHeight(false);
                        },
                        errorCallback: () => {
                            setOpenCustomRowHeight(false);
                        }
                    })
                );
            }
        },
        [dbId, viewId, workspaceId, dispatch, isShareViewLink, userViewSetting, handleMoreActionsClickAway]
    );

    return (
        <>
            {isInIconMore ? (
                <Grid
                    item
                    id={'cell_default_row_height'}
                    tr-dt="Text wrapping on toolbar"
                    onClick={handleDefaultRowHeightClick}
                >
                    <ListItem
                        icon={<RowHeightSVG height={18} width={18} color={theme.colors.darkness} />}
                        name={t('toolbar_default_rowHeight_tooltip')}
                    />
                </Grid>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center' }} id="toolbarmoreActions">
                    <Tooltip title={t('toolbar_default_rowHeight_tooltip')}>
                        <Grid item id={'cell_default_row_height'} className={classes.menuItem}>
                            <ButtonBase
                                tr-dt="Text wrapping on toolbar"
                                className={`${classes.addButton} ${classes.button}`}
                                onClick={handleDefaultRowHeightClick}
                            >
                                <RowHeightSVG height={18} width={18} color={theme.colors.darkness} />
                            </ButtonBase>
                        </Grid>
                    </Tooltip>
                </div>
            )}
            {defaultRowHeightAnchorEl && (
                <PopperMenu
                    placement={isDesktop ? 'bottom-start' : 'right'}
                    anchorEl={defaultRowHeightAnchorEl}
                    handleClickAway={handleDefaultRowHeightClickAway}
                    className={classes.popperMenu}
                >
                    <ListItem
                        name={t('global_short')}
                        icon={<ShortSVG />}
                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.DEFAULT)}
                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.DEFAULT}
                    />
                    <ListItem
                        name={t('global_medium')}
                        icon={<MediumSVG />}
                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMFORTABLE)}
                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMFORTABLE}
                    />
                    <ListItem
                        icon={<TallSVG />}
                        name={t('global_tall')}
                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMPACT)}
                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMPACT}
                    />

                    <ListItem
                        icon={<CustomSVG />}
                        name={t('global_custom')}
                        onClick={openCustomRowHeightHandler}
                        isSelected={
                            !Object.keys(ROW_HEIGHT_OPTIONS)
                                ?.map(key => ROW_HEIGHT_OPTIONS?.[key])
                                ?.includes(rowHeight)
                        }
                    />
                </PopperMenu>
            )}
            <Dialog
                id="simple-dialog"
                maxWidth={false}
                open={openCustomRowHeight}
                onClose={closeCustomRowHeightHandler}
                style={{ zIndex: 1301 }}
            >
                <CustomRowHeight
                    viewId={viewId}
                    workspaceId={workspaceId}
                    dbId={dbId}
                    onHeightChange={handleCustomRowHeightChange}
                    rowHeight={rowHeight}
                    onClose={closeCustomRowHeightHandler}
                />
            </Dialog>
        </>
    );
};

export default React.memo(ManageHeight);
