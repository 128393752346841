import React from 'react';

function OpenFolderSVG({ color = '#5DCDE3', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#open-folder-clip0)">
                <path
                    d="M15.6 16.5H2.4C1.6 16.5 1 15.9 1 15.1V2.9C1 2.1 1.6 1.5 2.4 1.5H15.6C16.4 1.5 17 2.1 17 2.9V15.1C17 15.9 16.4 16.5 15.6 16.5Z"
                    fill={color}
                />
                <path
                    d="M13.8 11.3H4.2C3.6 11.3 3 10.7 3 10.1V4.2C3 3.6 3.5 3 4.2 3H13.8C14.4 3 15 3.5 15 4.2V10.1C15 10.7 14.4 11.3 13.8 11.3Z"
                    fill="#D6DAED"
                />
                <path
                    d="M14.9998 12.2H2.9998C2.3998 12.2 1.7998 11.7 1.7998 11V5.2C1.7998 4.5 2.2998 4 2.9998 4H14.9998C15.5998 4 16.1998 4.5 16.1998 5.2V11.1C16.1998 11.7 15.6998 12.2 14.9998 12.2Z"
                    fill="white"
                />
                <path
                    d="M2.5 16.4998H15.5C16.3 16.4998 17 15.9998 17 15.3998L18 8.0998C18 7.4998 17.3 6.9998 16.5 6.9998H8.7L7 5.6998C6.7 5.3998 6.3 5.2998 5.9 5.2998H1.5C0.7 5.2998 0 5.7998 0 6.3998L1 15.3998C1 15.9998 1.7 16.4998 2.5 16.4998Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="open-folder-clip0">
                    <rect width="18" height="15" fill={color} transform="translate(0 1.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(OpenFolderSVG);
