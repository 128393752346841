import request from './request';
import { APP_API_PREFIX_GRID_UI } from '../config';
import { objectToQueryParams } from 'utils/urlQueryUtils';
import { encodeURL } from 'utils/http';
import isEmpty from 'lodash/isEmpty';
import { getRegionBaseUrl } from 'utils/workspace';

const advancedApi = {
    columns: wsId => `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/columns`,
    createAdvancedSearch: wsId =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/columns/create`,
    advancedSearch: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}`,
    records: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/records`,
    list: wsId => `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/list`,
    filters: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/filters`,
    sorts: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/sort-orders`,
    export: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/export`,
    replace: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/replace`,
    share: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/access`,
    getPublicUrl: ({ wsId, advancedSearchId, state }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/public/${state}`,
    reorder: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/reorder`,
    resize: ({ wsId, advancedSearchId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/app/workspaces/${wsId}/search/advanced/${advancedSearchId}/resize`
};

export async function togglePublicAdvancedSearchUrlApi({ wsId, aId, state }) {
    let url = `${advancedApi.getPublicUrl({ wsId, advancedSearchId: aId, state })}`;
    const response = await request.put(url);
    return response.data;
}

export async function shareAdvancedSearchApi({ wsId, aId, body }) {
    let url = `${advancedApi.share({ wsId, advancedSearchId: aId })}`;
    const response = await request.post(url, body);
    return response.data;
}

export async function getAdvancedSearchListApi({ wsId, params, shared = false }) {
    const encodeParams = objectToQueryParams(params);
    let url = `${advancedApi.list(wsId)}?${encodeParams}&shared=${shared}`;
    const response = await request.get(url);
    return response.data;
}

export async function getAdvancedColumnsApi({ wsId, body }) {
    let url = advancedApi.columns(wsId);

    const response = await request.post(url, body);
    return response.data;
}

export async function createAdvancedSearchApi({ wsId, body }) {
    let url = advancedApi.createAdvancedSearch(wsId);
    const response = await request.post(url, body);
    return response.data;
}

export async function getAdvancedSearchRecordsApi({ wsId, advancedSearchId, body }) {
    let url = advancedApi.records({ wsId, advancedSearchId });
    const response = await request.post(url, body);
    return { ...response.data, totalRecords: Number(response?.headers?.['x-total-count'] || 0) };
}

export async function getAdvancedSearchApi({ wsId, advancedSearchId }) {
    let url = advancedApi.advancedSearch({ wsId, advancedSearchId });
    const response = await request.get(url);
    return response.data;
}

export async function updateAdvancedSearchApi({ wsId, advancedSearchId, body }) {
    let url = advancedApi.advancedSearch({ wsId, advancedSearchId });
    const response = await request.put(url, body);
    return response.data;
}

export async function createViewFilterApi({ wsId, advancedSearchId, body }) {
    let url = advancedApi.filters({ wsId, advancedSearchId });
    const response = await request.post(url, body);
    return response.data;
}

export async function updateViewFilterApi({ wsId, advancedSearchId, filterId, body }) {
    let url = `${advancedApi.filters({ wsId, advancedSearchId })}/${filterId}`;
    const response = await request.put(url, body);
    return response.data;
}

export async function deleteViewFilterApi({ wsId, advancedSearchId, filterId }) {
    let url = `${advancedApi.filters({ wsId, advancedSearchId })}/${filterId}`;
    const response = await request.delete(url);
    return response.data;
}

export async function createViewSortApi({ wsId, advancedSearchId, body }) {
    let url = advancedApi.sorts({ wsId, advancedSearchId });
    const response = await request.post(url, body);
    return response.data;
}

export async function updateViewSortApi({ wsId, advancedSearchId, sortOrderId, body }) {
    let url = `${advancedApi.sorts({ wsId, advancedSearchId })}/${sortOrderId}`;
    const response = await request.put(url, body);
    return response.data;
}

export async function deleteViewSortApi({ wsId, advancedSearchId, sortOrderId }) {
    let url = `${advancedApi.sorts({ wsId, advancedSearchId })}/${sortOrderId}`;
    const response = await request.delete(url);
    return response.data;
}

export async function exportApi({ wsId, advancedSearchId, queryParams }) {
    const { exportFormat, columnIds, csvDelimiter, query, sort, q, shareViewKey } = queryParams;
    let url = advancedApi.export({ wsId, advancedSearchId });

    if (!isEmpty(queryParams)) {
        url += `?exportFormat=${exportFormat}&hashColumnIds=${columnIds}&csvDelimiter=${encodeURL(
            csvDelimiter
        )}&q=${encodeURL(q)}`;
        if (!isEmpty(query)) {
            const object = { query };
            url += `&${objectToQueryParams(object)}`;
        }
        if (!isEmpty(sort)) {
            const object = { sort };
            url += `&${objectToQueryParams(object)}`;
        }

        if (shareViewKey) {
            url += `&x_share_key=${shareViewKey}`;
        }
    }

    const linkUrl = `${getRegionBaseUrl()}${url}`;
    window.location.href = linkUrl;
    return false;
}

export async function replaceSearchApi({ wsId, advancedSearchId, body }) {
    let url = `${advancedApi.replace({ wsId, advancedSearchId })}`;
    const response = await request.put(url, body);
    return response.data;
}

export async function deleteAdvancedSearchApi({ wsId, aId }) {
    let url = `${advancedApi.advancedSearch({ wsId, advancedSearchId: aId })}`;
    const response = await request.delete(url);
    return response.data;
}

export async function duplicateAdvancedSearchApi({ wsId, adId, name, store }) {
    let url = `${advancedApi.advancedSearch({ wsId, advancedSearchId: adId })}/duplicate?name=${name}&store=${store}`;
    const response = await request.post(url);
    return response.data;
}

export async function reorderAdvancedSearchApi({ wsId, adId, body }) {
    let url = `${advancedApi.reorder({ wsId, advancedSearchId: adId })}`;
    const response = await request.put(url, body);
    return response.data;
}

export async function resizeAdvancedSearchApi({ wsId, adId, body }) {
    let url = `${advancedApi.resize({ wsId, advancedSearchId: adId })}`;
    const response = await request.put(url, body);
    return response.data;
}
