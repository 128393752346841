import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import LDBasePortal from 'components/selects/LDBasePortal';
// import CountryFlag from 'components/svg-icon/CountryFlag';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { AMAZON_LANGS, GOOGLE_LANGS } from 'const/automation';
import { updateActionNode } from 'gridUI/automations/action';
import { ACTION_STATUS } from 'gridUI/automations/const';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TooltipActionDisable from '../../TooltipActionDisable';

const useStyles = makeStyles(theme => ({
    mt12: {
        marginTop: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    requiredMark: {
        color: theme.colors.brightRed
    },
    controlClassName: {
        minHeight: 60,
        '&.error': {
            borderColor: theme.colors.brightRed
        }
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    flagWrapper: {
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4
    }
}));

// const CustomCountryFlag = ({ languageCountryCode }) => {
//     const classes = useStyles();
//     return (
//         <Grid item className={classes.flagWrapper}>
//             <CountryFlag languageCountryCode={languageCountryCode} />
//         </Grid>
//     );
// };

const AutomationSelectSourceTarget = ({ selectedNode, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const openSourceRef = useRef(false);
    const openTargetRef = useRef(false);
    const timeout = useRef();
    const [sourceLang, setSourceLang] = useState(selectedNode?.params?.sourceLang);
    const [targetLang, setTargetLang] = useState(selectedNode?.params?.targetLang);

    useEffect(() => {
        openSourceRef.current = false;
        openTargetRef.current = false;
        setSourceLang(selectedNode?.params?.sourceLang);
        setTargetLang(selectedNode?.params?.targetLang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const langOptions = useMemo(() => {
        const langs = selectedNode.app === WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE ? AMAZON_LANGS : GOOGLE_LANGS;
        return langs.map(lang => ({
            ...lang
            // icon: () => <CustomCountryFlag languageCountryCode={lang.value.replace('-', '')} />
        }));
    }, [selectedNode.app]);

    const sourceOptions = useMemo(() => {
        return [
            { value: 'auto', label: 'Detect language' },
            ...langOptions?.filter(option => option.value !== targetLang)
        ];
    }, [langOptions, targetLang]);

    const targetOptions = useMemo(() => {
        return langOptions?.filter(option => option.value !== sourceLang);
    }, [langOptions, sourceLang]);

    const selectedSource = useMemo(() => {
        if (sourceLang) {
            const options = [{ value: 'auto', label: 'Detect language' }, ...langOptions];
            const source = options?.find(option => option.value === sourceLang);
            if (source) return source;
        }
        return null;
    }, [langOptions, sourceLang]);

    const errorSourceMessage = nodeError?.source || '';

    const handleChangeSourceOptions = useCallback(
        option => {
            const newValue = option.value !== sourceLang ? option.value : '';
            setSourceLang(newValue);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                sourceLang: newValue
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setSourceLang(selectedNode?.params?.sourceLang);
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode, sourceLang]
    );

    const selectedTarget = useMemo(() => {
        if (targetLang) {
            const target = langOptions?.find(option => option.value === targetLang);
            if (target) return target;
        }
        return null;
    }, [langOptions, targetLang]);

    const errorTargetMessage = nodeError?.target || '';

    const handleChangeTargetOptions = useCallback(
        option => {
            const newValue = option.value !== targetLang ? option.value : '';
            setTargetLang(newValue);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                targetLang: newValue
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setSourceLang(selectedNode?.params?.targetLang);
                        }
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode, targetLang]
    );

    return (
        <Grid item>
            <Grid item className={classes.mb14}>
                <p className="body1">
                    {t('automation_select_original_language_of_the_text')}
                    <span className={classes.requiredMark}>*</span>
                </p>
            </Grid>
            <Grid item className={classes.mb24}>
                <TooltipActionDisable isAction disabled={isDisabled} containerClassName={classes.containerClassName}>
                    <Grid item style={{ flex: 1 }}>
                        <LDBasePortal
                            ddPlaceholder={t('automation_choose_original_language')}
                            menuPlaceholder={t('automation_select_original_language_of_the_text')}
                            options={sourceOptions}
                            onChange={handleChangeSourceOptions}
                            defaultValue={selectedSource}
                            isUsingContainer
                            controlClassName={classnames(classes.controlClassName, {
                                error: !!errorSourceMessage
                            })}
                        />
                    </Grid>
                    {errorSourceMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorSourceMessage}</p>
                        </Grid>
                    )}
                </TooltipActionDisable>
            </Grid>
            <Grid item className={classes.mb14}>
                <p className="body1">
                    {t('automation_select_language_to_translate_to')}
                    <span className={classes.requiredMark}>*</span>
                </p>
            </Grid>
            <Grid item>
                <TooltipActionDisable isAction disabled={isDisabled} containerClassName={classes.containerClassName}>
                    <Grid item style={{ flex: 1 }}>
                        <LDBasePortal
                            ddPlaceholder={t('automation_choose_language_to_translate_to')}
                            menuPlaceholder={t('automation_select_language_to_translate_to')}
                            options={targetOptions}
                            onChange={handleChangeTargetOptions}
                            defaultValue={selectedTarget}
                            isUsingContainer
                            controlClassName={classnames(classes.controlClassName, {
                                error: !!errorTargetMessage
                            })}
                        />
                    </Grid>
                    {errorTargetMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorTargetMessage}</p>
                        </Grid>
                    )}
                </TooltipActionDisable>
            </Grid>
        </Grid>
    );
};

export default React.memo(AutomationSelectSourceTarget);
