import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { INPUT_HEIGHT } from 'const/style';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import ImportHeader from './ImportHeader';
import { generateAlphabet } from 'utils/gridUI/localization';

const useStyles = makeStyles(theme => ({
    root: {
        height: 250,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            minWidth: 500
        }
    },
    listItem: {
        height: INPUT_HEIGHT
    },
    name: {
        background: theme.colors.paleGrey,
        borderRadius: 4,
        height: '100%',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        border: `1px solid ${theme.colors.silver}`
    },
    ellipsis: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    whatIsThis: {
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        height: '100%'
    },
    lightGrey: {
        color: theme.colors.secondaryText
    },
    spb3: {
        marginBottom: theme.spacing(3),
        width: '100%'
    },
    expand: {
        flex: 1
    }
}));

function ColumnMapList({
    columnSettings,
    columns,
    firstRow,
    options,
    onChange,
    isContainFirstRowHeader,
    isModeSingle,
    handleHeaderChangeTimezone
}) {
    const classes = useStyles();

    return (
        <Grid container direction="column" alignItems="center" wrap="nowrap" className={classes.root}>
            {columns.map((columnId, index) => {
                return (
                    <Grid key={columnId} item className={classes.spb3}>
                        <Grid className={classes.listItem} container alignItems="center">
                            <Grid xs={5} item container alignItems="center" className={`${classes.name}`}>
                                {isContainFirstRowHeader && (
                                    <p className="body2 line-clamp-1">{firstRow?.[columnId]}</p>
                                )}
                                {!isContainFirstRowHeader && (
                                    <p className="body2 line-clamp-1">
                                        {generateAlphabet(index)}{' '}
                                        <span className={classes.lightGrey}>({firstRow?.[columnId]})</span>
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={2} container alignItems="center" justify="center">
                                <PathSVG />
                            </Grid>
                            <Grid xs={5} item container alignItems="center" className={`${classes.whatIsThis}`}>
                                <ImportHeader
                                    columnIndex={index}
                                    columnMap={columnSettings}
                                    columnId={columnId}
                                    isModeSingle={isModeSingle}
                                    options={options}
                                    onChange={onChange}
                                    column={columnSettings?.[columnId]}
                                    handleHeaderChangeTimezone={handleHeaderChangeTimezone}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(ColumnMapList);
