import React from 'react';
import { SOURCE_TYPE_OPTIONS } from 'const';
import GoogleSheetSVG from 'assets/images/svg/GoogleSheetSVG';
import MySqlSVG from 'assets/images/svg/MySqlSVG';
import PostgresSVG from 'assets/images/svg/PostgresSVG';

export function getConnectorIcon(connector, size = '20px') {
    switch (connector) {
        case SOURCE_TYPE_OPTIONS.GOOGLE_SHEET:
            return <GoogleSheetSVG width={size} height={size} />;

        case SOURCE_TYPE_OPTIONS.MYSQL:
            return <MySqlSVG width={size} height={size} />;

        case SOURCE_TYPE_OPTIONS.POSTGRES:
            return <PostgresSVG width={size} height={size} />;

        default:
            return <GoogleSheetSVG width={size} height={size} />;
    }
}

export function getConnectorLabel(connector) {
    switch (connector) {
        case SOURCE_TYPE_OPTIONS.GOOGLE_SHEET:
            return 'Google Sheets';
        case SOURCE_TYPE_OPTIONS.MYSQL:
            return 'MySQL';
        case SOURCE_TYPE_OPTIONS.GRIDLY:
            return 'Gridly';
        case SOURCE_TYPE_OPTIONS.FLUTTER:
            return 'Flutter';
        case SOURCE_TYPE_OPTIONS.POSTGRES:
            return 'PostgreSQL';
        default:
            return 'Google Sheets';
    }
}
