import { ROW_HEIGHT, HIGHLIGHT_TYPES } from 'const/gridUI';
import React, { useMemo } from 'react';
import isArray from 'lodash/isArray';
import { isRefImage } from 'utils/gridUI/reference';
import { getPossibleLinks } from 'utils/html';
import Highlighter from 'components/highlighter';
import { getHighlightWords } from 'utils/gridUI/cell';
import { getLines } from 'utils/text';
import classNames from 'classnames';

function MultiTextRender({
    value,
    rowHeight = ROW_HEIGHT,
    rowIndex,
    columnIndex,
    filterValue,
    isCaseSensitive,
    highlightPositions = []
}) {
    const lines = useMemo(() => {
        return getLines(rowHeight);
    }, [rowHeight]);

    const style = useMemo(() => {
        return {
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': `${lines}`,
            display: '-webkit-box'
        };
    }, [lines]);

    let convertedData = '';

    if (typeof value === 'boolean') {
        convertedData = value ? 'true' : 'false';
    }

    if (typeof value === 'string' || typeof value === 'number') {
        convertedData = value?.toString();
    }

    if (typeof davalueta === 'object') {
        const isRefData = value?.some(
            option => option.hasOwnProperty('referencedDataItem') && option.hasOwnProperty('referencedRecordId')
        );
        const isImageData = isRefImage(value);

        if (isRefData || isImageData || !isArray(value)) return null;

        convertedData = value?.join(',');
    }

    const links = getPossibleLinks({ str: convertedData, searchKey: filterValue });

    const highlightWords = getHighlightWords(convertedData, highlightPositions);

    return (
        <p
            className={classNames(
                `body2 cell_${rowIndex}_${columnIndex} w-full whitespace-pre-wrap align-top text-left leading-6 break-words child-link-highlight overflow-hidden`
            )}
            style={style}
        >
            <Highlighter
                highlightClassName="highlight"
                searchWords={[
                    {
                        key: filterValue?.toString(),
                        type: HIGHLIGHT_TYPES.DEFAULT
                    },
                    ...highlightWords?.map(value => ({
                        key: value?.toString(),
                        type: HIGHLIGHT_TYPES.DEFAULT
                    })),
                    ...links?.map(link => ({
                        type: HIGHLIGHT_TYPES.LINK,
                        key: link
                    }))
                ]}
                autoEscape={true}
                textToHighlight={convertedData + '' || ''}
                caseSensitive={!!isCaseSensitive}
            />
        </p>
    );
}

export default React.memo(MultiTextRender);
