import React from 'react';
import { Grid } from '@material-ui/core';
import LQAIssueTypeSVG from 'assets/images/svg/LQAIssueTypeSVG';
import { makeStyles } from '@material-ui/core/styles';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    root: {},
    tag: {
        borderRadius: 3,
        padding: `0px 4px`
    },
    category: {
        // border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`,
        background: hexToRgba(theme.colors.dodgerBlue, 0.1)
    },
    subCategory: {
        // border: `1px solid ${hexToRgba(theme.colors.fuchsiaBlue, 0.3)}`,
        background: hexToRgba(theme.colors.fuchsiaBlue, 0.1)
    },
    severity: {
        background: hexToRgba(theme.colors.burntSlena, 0.1)
    },
    lqaIssueIcon: {
        width: '15.41px',
        height: '13.86px',
        verticalAlign: 'middle'
    }
}));

const LQATags = ({ lqaModel, lqaModelCategory, lqaModelSubCategory, lqaModelSeverity, iconSize = 'small' }) => {
    const classes = useStyles();

    const lqaOptions = React.useMemo(() => {
        return [
            { ...lqaModelCategory, className: classes.category },
            { ...lqaModelSubCategory, className: classes.subCategory },
            { ...lqaModelSeverity, className: classes.severity }
        ];
    }, [classes, lqaModelCategory, lqaModelSubCategory, lqaModelSeverity]);

    return (
        <Grid className={classes.root} container spacing={2} alignItems="center">
            <Grid item>
                <LQAIssueTypeSVG className={iconSize === 'small' ? classes.lqaIssueIcon : ''} />
            </Grid>
            {lqaOptions.map(option => (
                <Grid key={option.id} item>
                    <p className={`body2 rounded px-1 py-0 ${option.className}`}>{option.name}</p>
                </Grid>
            ))}
        </Grid>
    );
};

export default LQATags;
