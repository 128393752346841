import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import Button from 'components/button/Base';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogActions from 'components/dialog/DialogActions';
import { useTranslation } from 'react-i18next';
import InputText from 'components/inputs/InputText';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';

const useStyles = makeStyles(theme => ({
    root: {}
}));

function StopUserFactorConfirm({ onClose, onConfirm, isLoading, error }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [isShowPassword, setIsShowPassword] = React.useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        onConfirm(currentPassword);
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogTitle title={t('disable_2fa')} onClose={onClose} />
            <DialogContent>
                <Grid container direction="column" className={classes.root}>
                    <Grid item>
                        <Typography variant="body2">{t('are_you_sure_disable_2fa')}</Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 14 }}>
                        <Typography variant="body1">{t('you_password_to_confirm_the_action')}</Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 8 }}>
                        <InputText
                            disabled={isLoading}
                            name="currentPassword"
                            value={currentPassword}
                            height={40}
                            onChange={e => setCurrentPassword(e.target.value)}
                            placeholder={t('global_password')}
                            type={isShowPassword ? 'text' : 'password'}
                            error={Boolean(error)}
                            errorText={error}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setIsShowPassword(!isShowPassword)} edge="end">
                                        {isShowPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container spacing={2}>
                    <Grid item style={{ flex: 1 }}></Grid>
                    <Grid item>
                        <Button variant="outlined" width={100} onClick={onClose}>
                            {t('global_cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={isLoading || !currentPassword} variant="contained" width={100} type="submit">
                            {isLoading ? <CircularProgress size={24} /> : t('global_confirm')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
}

export default React.memo(StopUserFactorConfirm);
