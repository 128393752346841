import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AvatarBase from 'components/avatar/Base';
import { useCurrentUserFullInfo } from 'hooks/auth';
import 'emoji-mart/css/emoji-mart.css';
import PopperMenu from 'components/menus/Popper';
import EmojiBox from './Emoji';
import isEqual from 'lodash/isEqual';
import { isKbEscape, isKbEnter, isKbShiftEnter } from 'utils/keyboard';
import { getAvatarUrl } from 'utils/images';
import CommentEmojiIconSVG from 'assets/images/svg/CommentEmojiIconSVG';
import SendCommentSVG from 'assets/images/svg/SendCommentSVG';
import { InputBase, Typography } from '@material-ui/core';
import { addEmojiInText, getCaretPositionWithoutEmoji } from 'utils/emoji';
import Tooltip from 'components/tooltip/Base';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        position: 'relative'
    },
    currentUserAvatar: {
        flexBasic: 20
    },
    chatInput: {
        flex: 1,
        marginLeft: theme.spacing(3),
        padding: `0px ${theme.spacing(3)}px`,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        '&:focus-within': {
            borderColor: theme.palette.primary.main
        }
    },
    extend: {
        flex: 1
    },
    inputBase: {
        border: 'none',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        width: '100%',
        padding: `6px 0px`,
        fontWeight: 'normal',
        color: theme.colors.dimGrey,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus-within': {
            borderColor: 'none'
        },
        '&.Mui-disabled': {
            background: theme.colors.paleGrey,
            color: theme.colors.dimGrey
        },
        '& > textarea': {
            height: '100%',
            lineHeight: `22px`
        }
    },
    controls: {
        paddingTop: theme.spacing(2)
    },
    dlFlex: {
        display: 'flex'
    },
    commentPopup: {
        '& .emoji-mart': {
            border: 'none'
        }
    },
    spacing: {
        marginTop: theme.spacing(3)
    },
    strong: {
        fontSize: 10,
        color: theme.colors.midGrey
    },
    normal: {
        fontSize: 10,
        color: theme.colors.midGrey
    },
    noted: {}
}));

function ReplyBox({ cancelHandler, saveHandler, autoFocus = false, autoSelect, className, isHideAction, ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const currentUser = useCurrentUserFullInfo();
    const inputRef = React.useRef();
    const [text, setText] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const cursorRef = React.useRef(0);

    React.useEffect(() => {
        if (autoSelect) {
            const input = inputRef.current.getElementsByTagName('textarea')?.[0];
            input && input.select();
        }
    }, [autoSelect]);

    const handleFocus = React.useCallback(() => {
        const input = inputRef.current.getElementsByTagName('textarea')?.[0];
        if (input && autoFocus) {
            input && input.focus();
        }
    }, [autoFocus]);

    const handleClick = event => {
        handleFocus();
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (autoFocus) {
            handleFocus();
        }
    }, [handleFocus, autoFocus]);

    const handleTextChange = e => {
        e.preventDefault();
        e.stopPropagation();
        setText(e.target.value);
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            cancelHandler && cancelHandler();
        }
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            e.stopPropagation();
            if (!text) {
                return;
            }
            handleSaveButtonClick();
        }
    };

    const handleKeyUp = e => {
        const startCursor = e.target.selectionStart;
        const caretPos = getCaretPositionWithoutEmoji({ input: e.target, idx: startCursor });
        cursorRef.current = caretPos;
    };

    const handleFirstTimeFocus = e => {
        const startCursor = e.target.selectionStart;
        const caretPos = getCaretPositionWithoutEmoji({ input: e.target, idx: startCursor });
        cursorRef.current = caretPos;
    };

    const handleSaveButtonClick = () => {
        if (!text?.trim()) {
            handleFocus();
            return;
        }
        saveHandler && saveHandler(text?.trim());
        setText('');
        handleClickAway();
    };

    const updateTextAtPosition = ({ text, emoji }) => {
        let newText = addEmojiInText({ text, idx: cursorRef.current, emoji });
        cursorRef.current += 1;
        return newText;
    };

    const emojiChange = emoji => {
        const updateText = updateTextAtPosition({ text, emoji: emoji?.native || '' });
        setText(updateText);
    };

    return (
        <Grid container className={`${classes.root} ${className}`} direction="column" justify="center">
            <Grid item>
                <Grid container className={classes.commentChat} direction="row" alignItems="flex-start">
                    <Grid item className={classes.currentUserAvatar}>
                        <AvatarBase size={'small'} src={getAvatarUrl(currentUser?.imageUrl)} />
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems="flex-start"
                        justify="space-between"
                        direction="row"
                        className={classes.chatInput}
                    >
                        <Grid item className={classes.extend}>
                            <InputBase
                                className={classes.inputBase}
                                ref={inputRef}
                                value={text}
                                onChange={handleTextChange}
                                placeholder={t(`reply_box_placeholder`)}
                                onKeyDown={handleKeyDown}
                                multiline
                                rowsMax={7}
                                onKeyUp={handleKeyUp}
                                onFocus={handleFirstTimeFocus}
                            />
                        </Grid>
                        <Grid item className={classes.controls}>
                            <Grid container alignItems="center" direction="row" spacing={1}>
                                <Grid item className={classes.dlFlex}>
                                    <Tooltip title="Emoji">
                                        <Grid item onClick={handleClick} style={{ cursor: 'pointer', display: 'flex' }}>
                                            <CommentEmojiIconSVG
                                                color={anchorEl ? theme.colors.lightLavender : undefined}
                                            />
                                        </Grid>
                                    </Tooltip>

                                    {anchorEl && (
                                        <PopperMenu
                                            className={classes.commentPopup}
                                            anchorEl={anchorEl}
                                            placement={'right-end'}
                                            id={`comment-emoji`}
                                        >
                                            <EmojiBox handleClickAway={handleClickAway} onChange={emojiChange} />
                                        </PopperMenu>
                                    )}
                                </Grid>
                                <Tooltip title="Send comment">
                                    <Grid item onClick={handleSaveButtonClick} className={classes.dlFlex}>
                                        <SendCommentSVG color={text?.trim() ? theme.colors.lightLavender : undefined} />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="flex-end">
                <Grid item>
                    <Trans t={t} i18nKey="reply_box_hint" defaults={'<0>Shift + Return</0><1>to add a new line</1>'}>
                        <Typography variant="body1" display="inline" className={classes.strong}>
                            Shift + Return
                        </Typography>
                        <Typography variant="body2" display="inline" className={classes.normal}>
                            to add a new line
                        </Typography>
                    </Trans>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ReplyBox, (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
});
