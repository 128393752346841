import * as types from '../types';

const handler = {
    [types.FETCH_ROLES](state) {
        return {
            ...state,
            roles: {
                ...state.roles,
                isFetchingRoles: true
            }
        };
    },
    [types.FETCH_ROLES_SUCCESS](state, action) {
        const { roles } = action.payload;
        return {
            ...state,
            roles: {
                ...state.roles,
                isFetchingRoles: false,
                list: roles
            }
        };
    },
    [types.CREATE_ROLE](state) {
        return {
            ...state,
            roles: {
                ...state.roles,
                isCreatingRole: true
            }
        };
    },
    [types.CREATE_ROLE_SUCCESS](state, action) {
        const { role } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        return {
            ...state,
            roles: {
                ...state.roles,
                isCreatingRole: false,
                list: [...listRole, role]
            }
        };
    },
    [types.CREATE_ROLE_FAILED](state) {
        return {
            ...state,
            roles: {
                ...state.roles,
                isCreatingRole: false
            }
        };
    },
    [types.DELETE_ROLE](state, action) {
        const { roleId } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        const newListRole = listRole.map(role => {
            if (role.id === roleId) {
                role.isDeleted = true;
            }
            return role;
        });
        return {
            ...state,
            roles: {
                ...state.roles,
                list: newListRole
            }
        };
    },

    [types.DELETE_ROLE_FAILED](state, action) {
        const { roleId } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        const newListRole = listRole.map(role => {
            if (role.id === roleId) {
                role.isDeleted = false;
            }
            return role;
        });
        return {
            ...state,
            roles: {
                ...state.roles,
                list: newListRole
            }
        };
    },

    [types.DELETE_ROLE_SUCCESS](state, action) {
        const { roleId } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        const newListRole = listRole.filter(role => roleId !== role?.id);

        return {
            ...state,
            roles: {
                ...state.roles,
                list: newListRole
            }
        };
    },

    [types.UPDATE_ROLE_PRIVILEGE_IDS](state, action) {
        const { roleId, privilegeIds } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        const newListRole = listRole.map(role => {
            if (roleId === role?.id) {
                return {
                    ...role,
                    privilegeIds
                };
            }
            return role;
        });
        return {
            ...state,
            roles: {
                ...state.roles,
                list: newListRole
            }
        };
    },

    [types.UPDATE_ROLE](state, action) {
        const { role } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        const newListRole = listRole.map(i => {
            if (i.id === role.id) {
                i.name = role.name;
                i.privilegeIds = role.privilegeIds;
            }
            return i;
        });
        return {
            ...state,
            roles: {
                ...state.roles,
                list: newListRole
            }
        };
    },
    [types.UPDATE_ROLE_FAILED](state, action) {
        const { role } = action.payload;
        const { roles } = state;
        const listRole = roles?.list || [];
        const newListRole = listRole.map(i => {
            if (i.id === role.id) {
                i.name = role.name;
                i.privilegeIds = role.privilegeIds;
            }
            return i;
        });
        return {
            ...state,
            roles: {
                ...state.roles,
                list: newListRole
            }
        };
    },
    [types.TURN_OFF_SHOW_LAST_POP_UP_CREATE_ROLE](state) {
        return {
            ...state,
            roles: {
                ...state.roles,
                isShowLastPopupCreate: null
            }
        };
    },
    [types.TURN_ON_SHOW_LAST_POP_UP_CREATE_ROLE](state, action) {
        const { roleId } = action.payload;
        return {
            ...state,
            roles: {
                ...state.roles,
                isShowLastPopupCreate: roleId
            }
        };
    },
    [types.CHANGE_ROLE](state, action) {
        const { roleId } = action.payload;
        return {
            ...state,
            roles: {
                ...state.roles,
                selectedRoleId: roleId
            }
        };
    }
};

export default handler;
