import React from 'react';

function GridSVG({ color = '#FFF', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5 3H4.5C3.4 3 2.5 3.9 2.5 5V19C2.5 20.1 3.4 21 4.5 21H19.5C20.6 21 21.5 20.1 21.5 19V5C21.5 3.9 20.6 3 19.5 3ZM19.5 5V8H4.5V5H19.5ZM9.5 19H14.5V10H9.5V19ZM4.5 10H7.5V19H4.5V10ZM16.5 10V19H19.5V10H16.5Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(GridSVG);
