import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import classnames from 'classnames';

const SPACING = 5;

const useStyles = makeStyles(theme => ({
    noDatabase: {
        marginTop: 70
    },
    memberContent: {
        height: props => props.height || `calc(100vh - 88px - ${HEADER_HEIGHT}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: `0 ${SPACING * 4}px ${SPACING * 4}px !important`,
        background: theme.colors.white
    },
    header: {
        minHeight: HEADER_HEIGHT
    }
}));

function SettingContentLayout({ name, AddComponent, children, height, headerClassName, nameClassName }) {
    const classes = useStyles({ height });

    return (
        <Grid container direction="column">
            <Grid className={classnames(classes.header, headerClassName)} item container alignItems="center">
                <Grid item className={nameClassName}>
                    <Typography variant="h3">{name}</Typography>
                </Grid>
                {AddComponent && (
                    <Grid item style={{ marginBottom: 14 }}>
                        {AddComponent}
                    </Grid>
                )}
            </Grid>
            <Grid item className={classes.memberContent}>
                {children}
            </Grid>
        </Grid>
    );
}

export default React.memo(SettingContentLayout);
