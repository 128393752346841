import React from 'react';
import { Avatar, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as gridActions from 'gridUI/actions';
import isArray from 'lodash/isArray';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import AudioPlay from 'components/audio/Base';
import hexToRgba from 'hex-to-rgba';
import PlaySVG from 'assets/images/svg/PlaySVG';
import PauseSVG from 'assets/images/svg/PauseSVG';
import PlayPause from 'components/circularPercentage/PlayPause';
import { isFileAudio, isFileVideo, isFileImage, getFilePreview, isFilePdf } from 'utils/images';
import { generateAvatarWithHeight } from 'utils/images';
import { FILE_SPACING, FILE_RATIO } from 'const/gridUI';
import { enqueueSnackbar } from 'notifier/actions';
import { getUploadErrorMessage } from 'utils/upload';
import * as gridUIActions from 'gridUI/actions';
import { GRID_UPLOAD_MAX_SIZE } from 'const';
import { useDropzone } from 'react-dropzone';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';
import { useIsCellOverlaid } from 'hooks/gridUI';
import classNames from 'classnames';

function AudioThumbnail({ url, style, rowId, columnId, ...rest }) {
    const theme = useTheme();
    const [isPlay, setIsPlay] = React.useState(false);
    const [isPause, setIsPause] = React.useState(true);
    const [isShowPlay, setIsShowPlay] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const handleMouseEnter = () => {
        setIsShowPlay(true);
    };

    const handleMouseLeave = () => {
        setIsShowPlay(false);
    };

    const handleTimeUpdate = e => {
        const duration = e.target.duration;
        const currentTime = e.target.currentTime;
        const progress = (currentTime / duration) * 100;
        setProgress(progress);
    };

    const onToggle = e => {
        stopPropagation(e);
        const audioElement = document.getElementById(`audio_${rowId}_${columnId}_${url}`);
        setIsPlay(true);
        if (audioElement.paused) {
            audioElement.play();
            setIsPause(false);
        } else {
            audioElement.pause();
            setIsPause(true);
        }
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleVideoPlayEnd = () => {
        setIsPlay(false);
        setIsPause(true);
    };

    return (
        <Grid
            className="flex items-center justify-center rounded relative"
            style={style}
            {...rest}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {(isPlay || isShowPlay) && (
                <>
                    <span
                        className="absolute top-0 left-0 w-full h-full z-[1]"
                        style={{
                            background: hexToRgba(theme.colors.black, 0.5)
                        }}
                    />
                    <span
                        className="absolute top-0 left-0 w-full h-full z-[2] flex justify-center items-center"
                        onClick={onToggle}
                    >
                        <PlayPause value={isPlay ? progress : 0} icon={isPause ? <PlaySVG /> : <PauseSVG />} />
                    </span>
                </>
            )}
            <AudioThumbnailSVG style={style} />
            <AudioPlay
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleVideoPlayEnd}
                id={`audio_${rowId}_${columnId}_${url}`}
                src={url}
                style={{ width: 0, height: 0 }}
            />
        </Grid>
    );
}

function ImageRender({
    value,
    rowHeight,
    columnWidth,
    rowIndex,
    columnIndex,
    rowId,
    columnId,
    gridId,
    dbId,
    isOpenUpload,
    isReadOnly,
    isShareViewLink,
    isOverFileStorage,
    isFileMultiple,
    ...rest
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    // const [isUploading, setIsUploading] = React.useState(false);
    const { width, height } = generateAvatarWithHeight({ columnWidth, ratio: FILE_RATIO });
    // const [isOpenLimitPopup, setIsOpenLimitPopup] = React.useState(false);
    // const companyId = useCompanyId();
    // const history = useHistory();
    const isCellOverlaid = useIsCellOverlaid({ rowIndex, columnIndex });

    // const handleOpenLimitModal = () => setIsOpenLimitPopup(true);
    // const handleCloseLimitModal = () => {
    //     setIsOpenLimitPopup(false);
    // };

    const handleClickOpen = ({ e, fileMeta }) => {
        stopPropagation(e);
        let body = {
            rowId,
            columnId
        };
        if (fileMeta) {
            body = {
                ...body,
                defaultPreviewFile: fileMeta
            };
        }
        dispatch(gridActions.openCellFilePreview(body));
    };

    const stopPropagation = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles);
            const fileError = fileErrors?.[0];
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch]
    );

    const onDropAccepted = React.useCallback(
        acceptedFiles => {
            if (isFileMultiple) {
                dispatch(
                    gridUIActions.uploadMultipleFilesForCell({
                        columnId,
                        rowId,
                        uploadFiles: acceptedFiles,
                        successCallback: () => {
                            console.log('upload file successfully');
                            dispatch(gridUIActions.cancelCellEdit());
                        },
                        errorCallback: () => {
                            console.log('Failed to upload file');
                        }
                    })
                );
                return;
            }

            let item = acceptedFiles[0];
            let formData = new FormData();
            formData.append('file', item);
            dispatch(
                gridUIActions.uploadFileForCell({
                    formData,
                    rowId,
                    columnId,
                    successCallback: () => {
                        console.log('upload file successfully');
                        dispatch(gridUIActions.cancelCellEdit());
                    },
                    errorCallback: () => {
                        console.log('Failed to upload file');
                    }
                })
            );
        },
        [columnId, dispatch, rowId]
    );

    const { getRootProps, isDragActive } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: isFileMultiple,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noClick: true,
        noKeyboard: true
    });

    const style = React.useMemo(
        () => ({
            ...(isDragActive
                ? {
                      height: `${rowHeight - (isCellOverlaid ? 2 : 1)}px`,
                      border: `1px dashed ${theme.colors.highlight}`,
                      background: hexToRgba(theme.colors.highlight, 0.05),
                      borderRadius: 4,
                      display: 'block'
                  }
                : {})
        }),
        [isDragActive, theme, rowHeight, isCellOverlaid]
    );

    const dropFullProps = React.useMemo(() => {
        return !isReadOnly ? { ...getRootProps({ style }) } : {};
    }, [isReadOnly, getRootProps, style]);

    return (
        <div
            className={classNames(
                `table w-full h-full table-fixed overflow-hidden outline-none border border-transparent py-1.5 px-3.5`,
                {
                    absolute: isCellOverlaid,
                    'z-[2]': isCellOverlaid
                }
            )}
            {...dropFullProps}
        >
            <Grid
                container
                spacing={FILE_SPACING}
                style={{ outline: 'none' }}
                direction="row"
                className={`cell_${rowIndex}_${columnIndex}`}
            >
                {isArray(value) &&
                    value.map((fileMeta, index) => {
                        let isImage = isFileImage(fileMeta);
                        let isAudio = isFileAudio(fileMeta);
                        let isVideo = isFileVideo(fileMeta);
                        let isPdf = isFilePdf(fileMeta);

                        let url = getFilePreview({ fileId: fileMeta?.thumbnailId, dbId });

                        return (
                            <Grid item key={fileMeta?.id} style={{ position: 'relative' }}>
                                <Avatar
                                    className={classNames(`cell_${rowIndex}_${columnIndex} min-w-[20px] min-h-[20px]`, {
                                        'cursor-zoom-in': !isAudio
                                    })}
                                    style={{
                                        lineHeight: `${rowHeight}px`,
                                        height: height,
                                        width: width,
                                        borderRadius: 4,
                                        background: !isImage
                                            ? isAudio
                                                ? '#EEF5FB'
                                                : isVideo
                                                ? '#FDF0ED'
                                                : '#F3F2F9'
                                            : ''
                                    }}
                                    src={url}
                                    alt={fileMeta?.originalName}
                                    onClick={isAudio ? () => {} : e => handleClickOpen({ e, fileMeta })}
                                >
                                    {!isImage &&
                                        (isAudio ? (
                                            <AudioThumbnail
                                                rowId={rowId}
                                                columnId={columnId}
                                                style={{ width, height }}
                                                url={url}
                                            />
                                        ) : isVideo ? (
                                            <VideoThumbnailSVG style={{ width, height }} />
                                        ) : isPdf ? (
                                            <PDFFILESVG style={{ width, height }} />
                                        ) : (
                                            <OtherFileTypeThumbnailSVG style={{ width, height }} />
                                        ))}
                                </Avatar>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}

export default React.memo(ImageRender);
