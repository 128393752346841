import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LDBasePortal from 'components/selects/LDBasePortal';
import ColorAddSVG from 'assets/images/svg/ColorAddSVG';
import EditSVG from 'assets/images/svg/EditSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import Dialog from 'components/dialog/Dialog';
import LambdaCreate from './LambdaCreate';
import LambdaDelete from './LambdaDelete';
import { deleteLambdaApi } from 'services/automation';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { setAutomationLambdas, updateActionNode } from 'gridUI/automations/action';
import { useAutomationLambdas, useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { ACTION_STATUS } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import Payloadv2 from './Payloadv2';
import Postbacks from './Postbacks';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    flex: { display: 'flex' },
    mb14: {
        marginBottom: 14
    },
    mb18: {
        marginBottom: 18
    },
    mb24: {
        marginBottom: 24
    },
    ml4: {
        marginLeft: 4
    },
    ml12: {
        marginLeft: 12
    },
    mt12: {
        marginTop: 12
    },
    addHeader: {
        color: theme.palette.primary.main
    },
    ddPlaceholderClassname: {
        fontSize: 14
    },
    required: {
        color: theme.colors.brightRed
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    controlClassNameError: {
        borderColor: theme.colors.brightRed
    }
}));

const ActionLambda = ({ selectedNode = {}, setSelectedNode }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const lambdas = useAutomationLambdas();
    const [isOpenLambdaCreate, setIsOpenLambdaCreate] = useState(false);
    const [isOpenLambdaEdit, setIsOpenLambdaEdit] = useState(false);
    const [isOpenLambdaDelete, setIsOpenLambdaDelete] = useState(false);
    const { params = {} } = selectedNode;
    const [functionUid, setFunctionUid] = useState(params.functionUid);
    const timeout = useRef();
    const openRef = useRef();

    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);

    const errorMessage = nodeError?.lambda || '';

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        openRef.current = false;
        setIsOpenLambdaCreate(false);
        setIsOpenLambdaEdit(false);
        setIsOpenLambdaDelete(false);
        setFunctionUid(params.functionUid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const lambdaOptions = useMemo(() => {
        return lambdas.map(lambda => ({ ...lambda, value: lambda.uid, label: lambda.title }));
    }, [lambdas]);

    const selectedLambdaOption = useMemo(() => {
        return lambdaOptions.find(lambda => lambda.uid === functionUid);
    }, [functionUid, lambdaOptions]);

    const openLambdaCreate = () => setIsOpenLambdaCreate(true);
    const closeLambdaCreate = () => setIsOpenLambdaCreate(false);

    const openLambdaEdit = () => setIsOpenLambdaEdit(true);
    const closeLambdaEdit = () => setIsOpenLambdaEdit(false);

    const openLambdaDelete = () => setIsOpenLambdaDelete(true);
    const closeLambdaDelete = () => setIsOpenLambdaDelete(false);

    const handleLambdaChange = useCallback(
        lambda => {
            setFunctionUid(lambda.value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                functionUid: lambda.value,
                                outputFields: lambda.outputFields,
                                postbackCellMap: undefined
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 100);
        },
        [automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const onAddLambda = useCallback(
        newLambda => {
            dispatch(setAutomationLambdas([...lambdas, newLambda]));
        },
        [dispatch, lambdas]
    );

    const onUpdateLambda = useCallback(
        newLambda => {
            const newLambdas = lambdas.map(lambda => {
                if (lambda.uid === newLambda.uid) return newLambda;
                return lambda;
            });
            dispatch(setAutomationLambdas(newLambdas));
            handleLambdaChange({
                ...newLambda,
                value: newLambda.uid
            });
        },
        [lambdas, dispatch, handleLambdaChange]
    );

    const onDeleteLambda = useCallback(async () => {
        try {
            await deleteLambdaApi({ lambdaId: selectedLambdaOption.uid });
            const newLambdas = lambdas.filter(lambda => lambda.uid !== selectedLambdaOption.uid);
            dispatch(setAutomationLambdas(newLambdas));
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error.originalMessage,
                    type: 'error'
                })
            );
        }
    }, [dispatch, lambdas, selectedLambdaOption]);

    return (
        <Grid item className={classes.root}>
            <Grid item className={classes.mb14}>
                <p className="body1">
                    Lambda function<span className={classes.required}>*</span>
                </p>
            </Grid>
            <TooltipActionDisable isAction disabled={isDisabled}>
                <Grid item className={classes.mb18}>
                    <Grid item>
                        <LDBasePortal
                            ddPlaceholder={'Choose Lambda function'}
                            menuPlaceholder={'Find a function'}
                            options={lambdaOptions}
                            onChange={handleLambdaChange}
                            defaultValue={selectedLambdaOption}
                            isMulti={false}
                            isUsingContainer
                            ddPlaceholderClassname={classes.ddPlaceholderClassname}
                            controlClassName={classnames({
                                [classes.controlClassNameError]: !!errorMessage
                            })}
                        />
                    </Grid>
                    {errorMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorMessage}</p>
                        </Grid>
                    )}
                </Grid>
                <Grid item container justify="space-between" className={classes.mb24}>
                    <Grid item className={classes.flex} style={{ cursor: 'pointer' }} onClick={openLambdaCreate}>
                        <ColorAddSVG />
                        <p className="body1 text-brand-main ml-1 inline">Add function</p>
                    </Grid>
                    {Boolean(selectedLambdaOption) && (
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid
                                    item
                                    onClick={openLambdaEdit}
                                    style={{ cursor: 'pointer', display: 'flex', paddingRight: 2, paddingLeft: 2 }}
                                >
                                    <EditSVG color={theme.palette.primary.main} />
                                </Grid>
                                <Grid
                                    item
                                    onClick={openLambdaEdit}
                                    style={{ cursor: 'pointer', paddingRight: 8, paddingLeft: 2 }}
                                >
                                    <p className="body2 inline text-text-primary font-semibold">{t('edit')}</p>
                                </Grid>
                                <Grid
                                    item
                                    onClick={openLambdaDelete}
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        borderLeft: '1px solid #C4C4C4',
                                        paddingRight: 2,
                                        paddingLeft: 8
                                    }}
                                >
                                    <DeleteSVG color={theme.palette.primary.main} />
                                </Grid>
                                <Grid
                                    item
                                    onClick={openLambdaDelete}
                                    style={{ cursor: 'pointer', paddingRight: 2, paddingLeft: 2 }}
                                >
                                    <p className="body2 inline text-text-primary font-semibold">Delete</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </TooltipActionDisable>
            <Grid item className={classes.mb24}>
                <Payloadv2 selectedNode={selectedNode} setSelectedNode={setSelectedNode} showHowToCustomize />
            </Grid>
            <Grid item>
                <Postbacks selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
            </Grid>
            <Dialog open={isOpenLambdaCreate} onClose={closeLambdaCreate}>
                <LambdaCreate onClose={closeLambdaCreate} onAddLambda={onAddLambda} />
            </Dialog>
            <Dialog open={isOpenLambdaEdit} onClose={closeLambdaEdit}>
                <LambdaCreate
                    isEditing
                    lambda={selectedLambdaOption}
                    onClose={closeLambdaEdit}
                    onUpdateLambda={onUpdateLambda}
                />
            </Dialog>
            <Dialog open={isOpenLambdaDelete} onClose={closeLambdaDelete}>
                <LambdaDelete
                    lambdaName={selectedLambdaOption?.label}
                    onClose={closeLambdaDelete}
                    onDeleteLambda={() => {
                        onDeleteLambda();
                        closeLambdaDelete();
                    }}
                />
            </Dialog>
        </Grid>
    );
};

export default React.memo(ActionLambda);
