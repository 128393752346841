import { enqueueSnackbar } from 'notifier/actions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import request from 'services/request';
import { objectToQueryParams } from 'utils/urlQueryUtils';

export default function useFetchMore({
    scrollEl,
    apiUrl,
    limit = 50,
    willFetchMore = true,
    options = {},
    paramWrapper = '',
    distance = 0
}) {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [fetching, setFetching] = useState(false);
    const [fetchingMore, setFetchingMore] = useState(false);

    const fetch = useCallback(
        async page => {
            if (!apiUrl) return;
            if (page === 0) {
                setData([]);
            }
            setFetching(false);
            setFetchingMore(false);
            const setFetchingFn = page > 0 ? setFetchingMore : setFetching;
            try {
                setFetchingFn(true);
                const query = objectToQueryParams({
                    ...options,
                    ...(paramWrapper
                        ? { [paramWrapper]: { offset: page * limit, limit } }
                        : { offset: page * limit, limit })
                });
                const response = await request.get(`${apiUrl}?${query}`);
                const responseData = response.data;
                setTimeout(
                    () => {
                        setFetchingFn(false);
                        if (page > 0) {
                            if (responseData?.length > 0) {
                                setData(prev => [...prev, ...responseData]);
                                setPage(page);
                            }
                        } else {
                            setData(responseData);
                        }
                    },
                    page > 0 ? 800 : 0
                );
            } catch (error) {
                setFetchingFn(false);
                const { message } = error;
                dispatch(
                    enqueueSnackbar({
                        message,
                        type: 'info'
                    })
                );
            }
        },
        [apiUrl, dispatch, limit, options, paramWrapper]
    );

    const refresh = useCallback(() => {
        fetch(0);
    }, [fetch]);

    const handleFetchMore = useCallback(() => {
        if (fetching || fetchingMore) return;
        fetch(page + 1);
    }, [fetch, fetching, fetchingMore, page]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        const fetchMore = e => {
            const element = e.target;
            if (element.scrollTop + element.clientHeight >= element.scrollHeight - distance) {
                handleFetchMore();
            }
        };
        if (willFetchMore && scrollEl) {
            scrollEl.addEventListener('scroll', fetchMore);
        }
        return () => {
            if (scrollEl) {
                scrollEl.removeEventListener('scroll', fetchMore);
            }
        };
    }, [distance, handleFetchMore, scrollEl, willFetchMore]);

    return {
        data,
        page,
        fetching,
        fetchingMore,
        setData,
        setPage,
        setFetching,
        setFetchingMore,
        fetchMore: handleFetchMore,
        refresh
    };
}
