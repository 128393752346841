import React from 'react';
import { ScrollSync, Grid } from 'react-virtualized-dn';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CellRow from './Cell';
import { getWidthDistanceToIndex, getColumnData } from 'utils/gridUI/column';
import { SmoothScroll } from 'gridUI/table/grid/scroll';
import { useDispatch } from 'react-redux';
import {
    LIMIT_ROWS,
    DEFAULT_COLUMN_WIDTH,
    INDEX_COLUMN_WIDTH,
    ROW_HEIGHT,
    RECORD_HISTORY_OFFSET_BOTTOM,
    RECORD_HISTORY_OFFSET_TOP,
    RECORD_HISTORY_OFFSET_FETCH,
    RECORD_HISTORY_RENDER,
    BORDER_HIGHLIGHT,
    PREVIEW_STATUS
} from 'const/gridUI';
import { checkIsCellUpdateOrDelete } from 'utils/gridUI/formatData';
import Avatar from 'components/avatar/Base';
import Tooltip from 'components/tooltip/Base';
import { Grid as MTGrid } from '@material-ui/core';
import { getFriendlyTime } from 'utils/datetime';
import CardInfo from 'components/cardInfo/Base';
import * as gridActions from '../actions';
import { isLDEmpty } from 'utils/object';
import AvatarIconSVG from 'assets/images/svg/AvatarIconSVG';
import { getAvatarUrl } from 'utils/images';

const useStyles = makeStyles(theme => ({
    GridUIRow: {},
    leftCell: {
        outline: 'none',
        overflow: 'hidden !important'
    },
    rightCell: {
        outline: 'none',
        overflow: 'overlay !important'
    },
    rightCellGridUI: {
        scrollBehavior: 'smooth',
        outline: 'none'
    },
    HeaderGridUI: {
        width: '100%',
        overflow: 'hidden !important',
        outline: 'none'
    },
    LeftSideGridUI: {
        overflow: 'hidden !important',
        outline: 'none'
    },
    GridUIColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    BodyGridUI: {
        width: '100%',
        outline: 'none'
    },
    cell: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        border: `${BORDER_HIGHLIGHT}px solid transparent`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    cellRow: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },

    cellHeader: {
        width: '100%',
        height: '100%',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    indexCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        background: `transparent`,
        overflow: 'hidden'
    },
    createRecordIndexCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        background: `transparent`,
        overflow: 'hidden',
        '&:hover': {
            background: theme.colors.hoverItem,
            cursor: 'pointer'
        }
    },
    createRecordCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        background: `transparent`,
        overflow: 'hidden',
        '&:last-child': {
            borderRight: `1px solid ${theme.colors.border}`
        }
    },
    cellSelected: {
        border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight} !important`
    },
    cellBackgroundSelected: {
        background: theme.colors.selectionColor
    },
    header: {
        fontWeight: 'bold',
        background: `${theme.colors.paleGrey} !important`
    },
    headerSelected: {
        background: `${theme.colors.lightGrey} !important`,
        transition: 'background 0.1s ease-in-out'
    },
    footer: {
        background: `${theme.colors.white} !important`
    },
    headerFilter: {
        background: theme.colors.white
    },
    avatar: {
        minHeight: 20,
        minWidth: 20,
        width: 20,
        height: 20,
        marginRight: 4,
        fontSize: `11px !important`
    },
    time: {
        color: `#93929E`,
        fontSize: 11,
        position: 'relative',
        top: 2
    },
    userInfo: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    },
    title: {
        color: theme.colors.white,
        textAlign: 'left'
    },
    subTitle: {
        color: theme.colors.midGrey
    }
}));

const PREFIX_GRID_RECORD_HISTORY = `GRID_UI_RECORD_HISTORY_VIEW_ONLY_`;

function GridUIExample({
    width,
    maxHeight,
    columns,
    totalRecords,
    rows,
    metaData,
    data,
    rowHeight = ROW_HEIGHT,
    HEADER_ROW_HEIGHT = ROW_HEIGHT,
    fixedColumnCount = 2,
    fixedRowCount = 1,
    overscanColumnCount = 0,
    overscanRowCount = 0,
    dbId,
    // defaultAccessViewId,
    // gridId,
    isSelectionActive,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    isFetchingMore,
    recordMetaData,
    columnWidthStore,
    headerRenderer,
    isTriggerRefreshRecordHistory,
    t,
    ldUserEmailMap,
    disabledColumns,
    processingColumns,
    disabledSourceColumns,
    disabledColumnIdsByType,
    isShareViewLink,
    recordHistoryId
}) {
    const isRowOverLimit = totalRecords > LIMIT_ROWS;
    const classes = useStyles({ borderNumber: isRowOverLimit ? 2 : 1 });
    const columnCount = React.useMemo(() => columns.length, [columns.length]);
    const heightLeftGridUI = React.useMemo(() => HEADER_ROW_HEIGHT * fixedRowCount, [HEADER_ROW_HEIGHT, fixedRowCount]);
    const height = React.useMemo(() => maxHeight, [maxHeight]);
    const topRef = React.useRef(0);
    const leftRef = React.useRef(0);
    const onScrollRef = React.useRef();
    const scrollPropsRef = React.useRef();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isFetchingMoreRecords, setIsFetchingMoreRecords] = React.useState(false);
    const [scrollStartIndex, setScrollStartIndex] = React.useState(0);
    const [scrollStopIndex, setScrollStopIndex] = React.useState(0);

    //GridUILayoutRef
    const gridRef = React.useRef();
    const indexGridUIRef = React.useRef();
    const freezingGridUIRef = React.useRef();
    const leftHeaderRef = React.useRef();
    const rightHeaderRef = React.useRef();

    const scrollToPosition = React.useCallback(({ scrollLeft, scrollTop }) => {
        onScrollRef.current({ scrollLeft, scrollTop });
    }, []);

    const getWidthDistanceFrom0ToIndex = React.useCallback(
        index => {
            return getWidthDistanceToIndex({ fromIndex: 0, toIndex: index, columnWidthStore, columns });
        },
        [columns, columnWidthStore]
    );

    const getWidthDistanceFromIndexToIndex = React.useCallback(
        ({ fromIndex, toIndex }) => {
            return getWidthDistanceToIndex({ fromIndex, toIndex, columnWidthStore, columns });
        },
        [columns, columnWidthStore]
    );

    const getColumn = React.useCallback(
        columnIndex => {
            return getColumnData({ columnIndex, columns, metaData });
        },
        [columns, metaData]
    );

    const getRow = React.useCallback(
        ({ columnIndex, rowIndex }) => {
            const columnId = columns[columnIndex];
            const rowId = rows[rowIndex];
            if (!rowId) return null;
            const rowDataPerIndex = data[rowIndex];
            const rowData = rowDataPerIndex?.[`cellHistories`]?.[columnId];
            return rowData;
        },
        [rows, data, columns]
    );

    const getAllCellByRowId = React.useCallback(
        ({ rowIndex }) => {
            const rowDataPerIndex = data[rowIndex];
            return rowDataPerIndex?.cellHistories;
        },
        [data]
    );

    const getColumnWidth = React.useCallback(
        ({ index }) => {
            const column = metaData[columns[index]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            const realColumnWidth = columnWidthStore[id] ? columnWidthStore[id] : DEFAULT_COLUMN_WIDTH;
            return realColumnWidth;
        },
        [columns, metaData, columnWidthStore]
    );

    const getRightSectionColumnWidth = React.useCallback(
        ({ index }) => {
            let newIndex = index + fixedColumnCount;
            const column = metaData[columns[newIndex]];
            if (!column) return DEFAULT_COLUMN_WIDTH;
            const { id } = column;
            const realColumnWidth = columnWidthStore[id] ? columnWidthStore[id] : DEFAULT_COLUMN_WIDTH;
            return realColumnWidth;
        },
        [columns, metaData, columnWidthStore, fixedColumnCount]
    );

    const getRowHeight = React.useCallback(
        ({ index }) => {
            return rowHeight;
        },
        [rowHeight]
    );

    const getTotalFreezingWidth = React.useCallback(() => {
        let arrays = Array.from(Array(fixedColumnCount).keys());
        let total = arrays.reduce((total, columnIndex) => (total += getColumnWidth({ index: columnIndex })), 0);
        return total;
    }, [fixedColumnCount, getColumnWidth]);

    const getTotalWidthWithIndex = React.useMemo(() => {
        let arrays = Array.from(Array(fixedColumnCount).keys());
        let total = arrays.reduce((total, num) => (total += getColumnWidth({ index: num })), INDEX_COLUMN_WIDTH);
        return total;
    }, [fixedColumnCount, getColumnWidth]);

    const recomputeGridUI = () => {
        gridRef.current.recomputeGridSize();
        freezingGridUIRef.current.recomputeGridSize();
        indexGridUIRef.current.recomputeGridSize();
        leftHeaderRef.current.recomputeGridSize();
        rightHeaderRef.current.recomputeGridSize();
    };

    React.useEffect(() => {
        recomputeGridUI();
    }, [columnWidthStore, rowHeight]);

    React.useEffect(() => {
        const MainGridUI = document.getElementById(`${PREFIX_GRID_RECORD_HISTORY}RightCell`);
        const HeaderGridUI = document.getElementById(`${PREFIX_GRID_RECORD_HISTORY}RightCellHeader`);
        const IndexGridUI = document.getElementById(`${PREFIX_GRID_RECORD_HISTORY}IndexCell`);
        const LeftGridUI = document.getElementById(`${PREFIX_GRID_RECORD_HISTORY}LeftCell`);
        const FooterGridUI = document.getElementById(`${PREFIX_GRID_RECORD_HISTORY}RightCellFooter`);
        SmoothScroll({
            target: MainGridUI,
            speed: 40,
            smooth: 8,
            moveTopTargets: [IndexGridUI, LeftGridUI],
            moveLeftTargets: [HeaderGridUI, FooterGridUI]
        });
        SmoothScroll({
            target: LeftGridUI,
            speed: 40,
            smooth: 8,
            moveTopTargets: [IndexGridUI, MainGridUI],
            moveLeftTargets: [HeaderGridUI, FooterGridUI],
            isIgnoreLeftScroll: true
        });
    }, [dispatch, width, height]);

    React.useEffect(() => {
        if (isTriggerRefreshRecordHistory) {
            const { state } = gridRef.current;
            const { scrollLeft } = state;
            scrollToPosition({
                scrollTop: 0,
                scrollLeft
            });
        }
    }, [isTriggerRefreshRecordHistory, scrollToPosition]);

    const _renderHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style, position }) => {
            const column = getColumn(columnIndex);
            if (!column) return null;
            const headerClassName = rowIndex < 1 ? classes.header : `${classes.headerFilter}`;

            return (
                <div
                    className={`
                        ${classes.cellHeader} 
                        ${headerClassName} 
                    `}
                    key={key}
                    style={style}
                >
                    {rowIndex === 0 &&
                        headerRenderer({
                            columnIndex,
                            position,
                            key,
                            column,
                            isSelectionActive
                        })}
                </div>
            );
        },
        [headerRenderer, getColumn, classes, isSelectionActive]
    );

    const handleRevert = React.useCallback(
        ({ type, value, columnId, previousValue }) => {
            dispatch(gridActions.revertCellInRecordHistory({ type, value, columnId, previousValue, recordHistoryId }));
        },
        [dispatch, recordHistoryId]
    );

    const handleRevertBefore = React.useCallback(
        ({ type, value, columnId }) => {
            dispatch(gridActions.revertCellInRecordHistory({ type, value, columnId, recordHistoryId }));
        },
        [dispatch, recordHistoryId]
    );

    const _renderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style, position, parent }) => {
            const column = getColumn(columnIndex);
            const rowData = getRow({ columnIndex, rowIndex });
            const rowDataByRowId = getAllCellByRowId({ rowIndex });
            const lastedRowData = getRow({ columnIndex, rowIndex: 0 });

            const columnId = columns[columnIndex];
            const rowId = rows[rowIndex - ROW_START_INDEX];
            const { state } = parent;
            const { isScrolling } = state;
            const isUseSkeleton = (rowIndex < ROW_START_INDEX || rowIndex > ROW_STOP_INDEX) && isFetchingMore;
            const realRowHeight = getRowHeight({ index: rowIndex });
            const realColumnWidth = getColumnWidth({ index: columnIndex });

            const isColDisabled = [...disabledColumns, ...disabledSourceColumns, ...disabledColumnIdsByType]?.includes(
                columnId
            );

            const isDisabled = isColDisabled || !column?.editable;

            const previousData = rowData?.previousData;
            const currentData = rowData?.data;
            const getBackground = status => {
                switch (status) {
                    case PREVIEW_STATUS.DELETED:
                        return theme.colors.cherub;
                    case PREVIEW_STATUS.UPDATED:
                        return theme.colors.hawkesBlue;
                    default:
                        return undefined;
                }
            };
            const columnType = rowData?.columnType;
            const previousColumnType = rowData?.previousColumnType;

            //check dependency status
            const previousDependencyStatus = rowData?.parentDependencyStatus || rowData?.previousDependencyStatus;
            const dependencyStatus = rowData?.childDependencyStatus || rowData?.dependencyStatus;

            //check source status
            const previousSourceStatus = rowData?.previousSourceStatus;
            const sourceStatus = rowData?.sourceStatus;

            const status =
                columnType !== previousColumnType && !isLDEmpty(previousData) && !isLDEmpty(currentData)
                    ? PREVIEW_STATUS.UPDATED
                    : checkIsCellUpdateOrDelete({
                          previousData,
                          data: currentData,
                          dependencyStatus,
                          previousDependencyStatus,
                          previousSourceStatus,
                          sourceStatus
                      });

            const background = getBackground(status);

            return (
                <div
                    key={key}
                    className={`${classes.cellRow} 
                        cell_${rowIndex}_${columnIndex} 
                    `}
                    style={style}
                >
                    <CellRow
                        dbId={dbId}
                        status={status}
                        position={position}
                        columnIndex={columnIndex}
                        background={background}
                        rowIndex={rowIndex}
                        columnId={columnId}
                        rowId={rowId}
                        column={column}
                        fullData={rowData}
                        rowData={rowData}
                        isScrolling={isScrolling}
                        rowHeight={realRowHeight}
                        columnWidth={realColumnWidth}
                        isRowOverLimit={isRowOverLimit}
                        isUseSkeleton={isUseSkeleton}
                        editable={true}
                        isViewOnly={true}
                        rowDataByRowId={rowDataByRowId}
                        previousData={previousData}
                        currentData={currentData}
                        columnType={columnType}
                        previousColumnType={previousColumnType}
                        t={t}
                        ldUserEmailMap={ldUserEmailMap}
                        onRevert={props => handleRevert({ ...props, previousValue: lastedRowData?.data })}
                        onRevertBefore={handleRevertBefore}
                        showRevert
                        isDisabled={isDisabled}
                        isShareViewLink={isShareViewLink}
                        sourceStatus={sourceStatus}
                        previousSourceStatus={previousSourceStatus}
                        dependencyStatus={dependencyStatus}
                        previousDependencyStatus={previousDependencyStatus}
                    />
                </div>
            );
        },
        [
            isShareViewLink,
            disabledSourceColumns,
            disabledColumns,
            disabledColumnIdsByType,
            handleRevert,
            ldUserEmailMap,
            dbId,
            getColumnWidth,
            getAllCellByRowId,
            getRowHeight,
            getRow,
            getColumn,
            classes,
            columns,
            rows,
            ROW_START_INDEX,
            isRowOverLimit,
            ROW_STOP_INDEX,
            isFetchingMore,
            theme,
            t,
            handleRevertBefore
        ]
    );

    const _renderIndexHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            return (
                <div
                    key={key}
                    style={style}
                    className={`${classes.cellHeader} ${rowIndex === 0 ? classes.header : ''}`}
                >
                    {/* {rowIndex === 0 && <IndexHeader />} */}
                </div>
            );
        },
        [classes]
    );

    const _renderIndexCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            // const rowId = rows?.[rowIndex - ROW_START_INDEX];
            const userEmail = data?.[rowIndex]?.alteredBy;
            const userFullName = data?.[rowIndex]?.userFullname;
            const alteredTime = data?.[rowIndex]?.alteredTime;
            const cellHeight = getRowHeight({ index: rowIndex });
            const time = getFriendlyTime(alteredTime, 'MM/DD');

            return (
                <div
                    key={key}
                    style={{
                        ...style,
                        height: cellHeight
                    }}
                    className={`${classes.indexCell}`}
                >
                    <MTGrid container direction="row" wrap="nowrap" justify="center" alignItems="center" spacing={1}>
                        <Tooltip
                            title={
                                <MTGrid container className={classes.userInfo}>
                                    <CardInfo
                                        avatar={
                                            <Avatar size="small" alt="avatar" src={getAvatarUrl(userEmail)}>
                                                <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                            </Avatar>
                                        }
                                        title={
                                            <p className="body1 text-white text-left">{userFullName || userEmail}</p>
                                        }
                                        subTitle={
                                            <p className="body2 text-grey-mid">
                                                {`${t(`global_modified_time`)}: ${getFriendlyTime(alteredTime)}`}
                                            </p>
                                        }
                                    />
                                </MTGrid>
                            }
                        >
                            <MTGrid item>
                                <Avatar size={20} src={getAvatarUrl(userEmail)}>
                                    <AvatarIconSVG style={{ width: '100%', height: '100%' }} />
                                </Avatar>
                            </MTGrid>
                        </Tooltip>
                        <MTGrid item>
                            <p className={classes.time}>{time}</p>
                        </MTGrid>
                    </MTGrid>
                </div>
            );
        },
        [data, classes, getRowHeight, t]
    );

    const _renderLeftHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const columnWidth = getColumnWidth({ index: columnIndex });
            const position = {
                left: getWidthDistanceFrom0ToIndex(columnIndex) + INDEX_COLUMN_WIDTH,
                top: fixedRowCount * rowHeight
            };
            if (columnIndex > fixedColumnCount || rowIndex > fixedRowCount) return;

            return _renderHeaderCell({
                columnIndex,
                key,
                rowIndex,
                position,
                style: {
                    ...style,
                    width: columnWidth
                    // borderRight: columnIndex === fixedColumnCount - 1 ? `1px solid ${theme.colors.disabled_text}` : ''
                }
            });
        },
        [_renderHeaderCell, fixedColumnCount, fixedRowCount, rowHeight, getColumnWidth, getWidthDistanceFrom0ToIndex]
    );

    const _renderRightHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;
            const columnWidth = getColumnWidth({ index: newColumnIndex });
            const position = {
                left:
                    getTotalFreezingWidth() +
                    getWidthDistanceFromIndexToIndex({ fromIndex: fixedColumnCount, toIndex: newColumnIndex }) +
                    INDEX_COLUMN_WIDTH -
                    leftRef.current,
                top: fixedRowCount * rowHeight
            };
            return _renderHeaderCell({
                columnIndex: newColumnIndex,
                rowIndex,
                key,
                style: { ...style, width: columnWidth },
                position
            });
        },
        [
            _renderHeaderCell,
            fixedColumnCount,
            rowHeight,
            fixedRowCount,
            getWidthDistanceFromIndexToIndex,
            getTotalFreezingWidth,
            getColumnWidth
        ]
    );

    const _renderLeftCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const position = {
                left: getWidthDistanceFrom0ToIndex(columnIndex) + INDEX_COLUMN_WIDTH,
                top: rowIndex * rowHeight + fixedRowCount * rowHeight - topRef.current
            };
            const cellHeight = getRowHeight({ index: rowIndex });
            const columnWidth = getColumnWidth({ index: columnIndex });

            return _renderCell({
                columnIndex,
                key,
                rowIndex,
                position,
                style: {
                    ...style,
                    height: cellHeight,
                    width: columnWidth
                    // borderRight: columnIndex === fixedColumnCount - 1 ? `1px solid ${theme.colors.disabled_text}` : ''
                },
                isHeader: true,
                parent
            });
        },
        [_renderCell, rowHeight, fixedRowCount, getRowHeight, getColumnWidth, getWidthDistanceFrom0ToIndex]
    );

    const _renderRightCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;
            const cellHeight = getRowHeight({ index: rowIndex });
            const columnWidth = getColumnWidth({ index: newColumnIndex });

            const position = {
                left:
                    getTotalFreezingWidth() +
                    getWidthDistanceFromIndexToIndex({ fromIndex: fixedColumnCount, toIndex: newColumnIndex }) +
                    INDEX_COLUMN_WIDTH -
                    leftRef.current,
                top: rowIndex * rowHeight + fixedRowCount * rowHeight - topRef.current
            };
            return _renderCell({
                columnIndex: newColumnIndex,
                key,
                rowIndex,
                style: { ...style, height: cellHeight, width: columnWidth },
                position,
                parent
            });
        },
        [
            _renderCell,
            fixedColumnCount,
            fixedRowCount,
            rowHeight,
            getTotalFreezingWidth,
            getRowHeight,
            getColumnWidth,
            getWidthDistanceFromIndexToIndex
        ]
    );
    let fetchMoreRequests = React.useRef([]);
    let timerFetchingRef = React.useRef();
    let isDragScrollbar = React.useRef(false);
    const timerScroll = React.useRef();

    const handleOnSectionRendered = React.useCallback(
        ({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex, ...other }) => {
            const { state } = gridRef.current;
            const { scrollDirectionVertical } = state;

            /**
             * Dragging scrollbar to another place.
             */

            if (rowStopIndex > ROW_STOP_INDEX || rowStartIndex < ROW_START_INDEX) {
                isDragScrollbar.current = true;
                let request = {
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORD_HISTORY_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORD_HISTORY_OFFSET_BOTTOM, RECORD_HISTORY_RENDER)
                };
                fetchMoreRequests.current.push(request);
                if (timerScroll.current) clearTimeout(timerScroll.current);
                timerScroll.current = setTimeout(() => {
                    const data = fetchMoreRequests.current[fetchMoreRequests.current.length - 1];
                    if (data) {
                        fetchServer({
                            ...data,
                            successCallback: () => {
                                fetchMoreRequests.current = [];
                            },
                            errorCallback: () => {
                                fetchMoreRequests.current = [];
                            }
                        });
                    }
                }, 150);
            }

            if (
                scrollDirectionVertical === 1 &&
                !isFetchingMoreRecords &&
                rowStopIndex > scrollStopIndex + RECORD_HISTORY_OFFSET_FETCH &&
                !isDragScrollbar.current
            ) {
                isDragScrollbar.current = false;
                setIsFetchingMoreRecords(true);
                fetchServer({
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORD_HISTORY_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORD_HISTORY_OFFSET_BOTTOM, RECORD_HISTORY_RENDER)
                });
            }

            function isUpFetching() {
                const value = Math.max(scrollStartIndex - RECORD_HISTORY_OFFSET_FETCH, 0);
                return rowStartIndex > RECORD_HISTORY_OFFSET_FETCH && rowStartIndex < value;
            }

            if (
                scrollDirectionVertical === -1 &&
                !isFetchingMoreRecords &&
                isUpFetching() &&
                !isDragScrollbar.current
            ) {
                console.log('FETCH UP');
                isDragScrollbar.current = false;
                setIsFetchingMoreRecords(true);
                fetchServer({
                    ROW_START_INDEX: Math.max(rowStartIndex - RECORD_HISTORY_OFFSET_TOP, 0),
                    ROW_STOP_INDEX: Math.max(rowStopIndex + RECORD_HISTORY_OFFSET_BOTTOM, RECORD_HISTORY_RENDER)
                });
            }

            function fetchServer({ ROW_START_INDEX, ROW_STOP_INDEX, successCallback, errorCallback }) {
                if (timerFetchingRef.current) clearTimeout(timerFetchingRef.current);
                timerFetchingRef.current = setTimeout(() => {
                    dispatch(
                        gridActions.fetchMoreRecordHistory({
                            RECORD_HISTORY_ROW_START_INDEX: ROW_START_INDEX,
                            RECORD_HISTORY_ROW_STOP_INDEX: ROW_STOP_INDEX,
                            successCallback: () => {
                                setScrollStartIndex(rowStartIndex);
                                setScrollStopIndex(rowStopIndex);
                                isDragScrollbar.current = false;
                                setIsFetchingMoreRecords(false);
                                successCallback && successCallback();
                            },
                            errorCallback: () => {
                                console.log('failed to load more');
                                // recomputeGridUI();
                                errorCallback && errorCallback();
                            }
                        })
                    );
                }, 100);
            }
        },
        [dispatch, ROW_START_INDEX, ROW_STOP_INDEX, isFetchingMoreRecords, scrollStopIndex, scrollStartIndex]
    );

    return (
        <ScrollSync>
            {({ clientHeight, clientWidth, onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth }) => {
                return (
                    <div
                        style={{
                            width,
                            height: height
                        }}
                        className={classes.gridRow}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: heightLeftGridUI,
                                width
                            }}
                            id={'gridTable'}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: INDEX_COLUMN_WIDTH
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_RECORD_HISTORY}IndexHeader`}
                                    cellRenderer={_renderIndexHeaderCell}
                                    className={classes.HeaderGridUI}
                                    width={INDEX_COLUMN_WIDTH}
                                    height={heightLeftGridUI}
                                    rowHeight={ROW_HEIGHT}
                                    columnWidth={INDEX_COLUMN_WIDTH}
                                    rowCount={fixedRowCount}
                                    columnCount={1}
                                />
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    left: INDEX_COLUMN_WIDTH,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    ref={leftHeaderRef}
                                    id={`${PREFIX_GRID_RECORD_HISTORY}LeftCellHeader`}
                                    cellRenderer={_renderLeftHeaderCell}
                                    className={classes.HeaderGridUI}
                                    width={getTotalFreezingWidth()}
                                    height={heightLeftGridUI}
                                    rowHeight={ROW_HEIGHT}
                                    columnWidth={getColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={fixedColumnCount}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalFreezingWidth() + INDEX_COLUMN_WIDTH,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                            >
                                <Grid
                                    ref={rightHeaderRef}
                                    id={`${PREFIX_GRID_RECORD_HISTORY}RightCellHeader`}
                                    className={classes.HeaderGridUI}
                                    scrollLeft={scrollLeft}
                                    cellRenderer={_renderRightHeaderCell}
                                    width={width - getTotalWidthWithIndex}
                                    overscanColumnCount={overscanColumnCount}
                                    height={heightLeftGridUI}
                                    rowHeight={ROW_HEIGHT}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={fixedRowCount}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: height - heightLeftGridUI,
                                width
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: INDEX_COLUMN_WIDTH
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_RECORD_HISTORY}IndexCell`}
                                    ref={indexGridUIRef}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    scrollTop={scrollTop}
                                    cellRenderer={_renderIndexCell}
                                    className={classes.leftCell}
                                    width={INDEX_COLUMN_WIDTH}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={INDEX_COLUMN_WIDTH}
                                    rowCount={totalRecords}
                                    columnCount={1}
                                />
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    left: INDEX_COLUMN_WIDTH,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_RECORD_HISTORY}LeftCell`}
                                    ref={freezingGridUIRef}
                                    scrollTop={scrollTop}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderLeftCell}
                                    className={classes.leftCell}
                                    width={getTotalFreezingWidth()}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={fixedColumnCount}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalWidthWithIndex,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                                className={classes.rightCellGridUI}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_RECORD_HISTORY}RightCell`}
                                    ref={gridRef}
                                    scrollTop={scrollTop}
                                    scrollLeft={scrollLeft}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderRightCell}
                                    className={classes.rightCell}
                                    onSectionRendered={handleOnSectionRendered}
                                    onScroll={props => {
                                        onScroll(props);
                                        onScrollRef.current = onScroll;
                                        scrollPropsRef.current = props;
                                        topRef.current = props.scrollTop;
                                        leftRef.current = props.scrollLeft;
                                    }}
                                    width={width - getTotalWidthWithIndex}
                                    height={height - heightLeftGridUI}
                                    rowHeight={getRowHeight}
                                    columnWidth={getRightSectionColumnWidth}
                                    rowCount={totalRecords}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                    </div>
                );
            }}
        </ScrollSync>
    );
}

export default React.memo(GridUIExample);
