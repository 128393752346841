import React from 'react';

function ImportFileSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.8 4.5712L7.04606 0.369563C7.55271 -0.0795053 8.22864 -0.11871 8.74043 0.24864L8.85547 0.340629L13.2607 4.53503C13.6751 4.93391 13.8042 5.53532 13.5893 6.0675C13.4008 6.53449 12.9796 6.84953 12.4944 6.89879L12.3606 6.90555H11.9483V11.3996C11.9483 12.102 11.427 12.6826 10.7607 12.7525L10.6256 12.7595H5.50843C4.83513 12.7595 4.25992 12.2119 4.19065 11.5363L4.18366 11.3996V6.90555H3.71658C3.18131 6.90555 2.71733 6.59244 2.50652 6.08713C2.30924 5.61461 2.38696 5.07888 2.6993 4.68493L2.8 4.5712ZM12.8473 14.2226C13.2452 14.2226 13.5753 14.5196 13.6276 14.9042L13.6348 15.0111V15.2115C13.6348 15.6094 13.3378 15.9404 12.954 15.9928L12.8473 16H3.24228C2.84349 16 2.51329 15.7022 2.46103 15.3182L2.45383 15.2115V15.0111C2.45383 14.6123 2.75079 14.2821 3.13537 14.2298L3.24228 14.2226H12.8473ZM4.21156 5.35552L7.96885 1.63543L11.9163 5.35552H11.721L11.5859 5.36256C10.9196 5.4324 10.3983 6.01305 10.3983 6.71542V11.2095H5.7337V6.71542L5.72686 6.57668C5.65902 5.89228 5.09496 5.35552 4.411 5.35552H4.21156Z"
                fill={color}
                {...other}
            />
        </svg>
    );
}

export default React.memo(ImportFileSVG);
