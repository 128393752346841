import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TEXT_LINE_HEIGHT, PREVIEW_STATUS } from 'const/gridUI';
import TextDelete from '../diffCheckComponents/delete/TextDelete';
import TextUpdate from '../diffCheckComponents/update/TextUpdate';

import DiffTextWithOtherTypes from './diffType/Text';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'left',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        paddingLeft: props => (!props.isShowDiffDependencies ? 'unset' : '20px')
    }
}));

function TextRender({
    rowIndex,
    columnIndex,
    rowHeight,
    status,
    options,
    customProperties,
    columnType,
    previousColumnType,
    data,
    previousData,
    isOverride,
    conflictMode,
    isShowDiffDependencies,
    dbId
}) {
    const classes = useStyles({ isShowDiffDependencies });

    const isSameColumnType = columnType === previousColumnType;

    return (
        <div
            className={`body2 pointer-events-none cell_${rowIndex}_${columnIndex} ${classes.root}`}
            style={{
                height: rowHeight
            }}
        >
            {isSameColumnType && status === PREVIEW_STATUS.DELETED && <TextDelete previousData={previousData} />}
            {isSameColumnType && status === PREVIEW_STATUS.UPDATED && (
                <TextUpdate data={data} previousData={previousData} />
            )}
            {isSameColumnType && [PREVIEW_STATUS.NORMAL, PREVIEW_STATUS.CONFLICT].includes(status) && data}
            {!isSameColumnType && (
                <DiffTextWithOtherTypes
                    previousData={previousData}
                    data={data}
                    options={options}
                    customProperties={customProperties}
                    previousColumnType={previousColumnType}
                    isHideMarkIcon={true}
                    dbId={dbId}
                />
            )}
        </div>
    );
}

export default React.memo(TextRender);
