import React from 'react';

function AddIconSVGFilled({ color = '#7869B9', ...other }) {
    return (
        <svg {...other} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.67857 5.75H8.32143V8.32143H5.75V9.67857H8.32143V12.25H9.67857V9.67857H12.25V8.32143H9.67857V5.75Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(AddIconSVGFilled);
