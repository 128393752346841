import axios from 'axios';
import {
    API_URL,
    APP_API_PREFIX_DATABASE_SERVICE,
    APP_API_PREFIX_GRID_UI,
    APP_API_PREFIX_LQA,
    APP_API_WORK_FLOW
} from '../config';
import store from '../store/configStore';
// import { clearTokenCookie } from 'auth/actions';
import { getErrorMessageComponent } from 'utils/error';
import { ERROR_MESSAGES } from 'const/messages';
import * as appActions from 'app/actions';
import { _logoutSuccess } from 'auth/actions';
import { MAINTAIN_TYPES } from 'const';
import { getRegionBaseUrl } from 'utils/workspace';

const { getState } = store;

let instance = axios.create({
    baseURL: API_URL
});

export const DEFAULT_HEADERS = {
    Accept: 'application/json;',
    'Content-Type': 'application/json;',
    'Access-Control-Expose-Headers': 'Content-Disposition',
    'Custom-Header': 'Own-Data'
};

instance.defaults.withCredentials = true;

instance.interceptors.request.use(
    config => {
        let state = getState();
        let socketId = state?.app?.socketId;
        let shareViewKey = state?.app?.shareViewKey;
        let companyId = state.auth.companyId || state.auth.currentUser?.companyId;
        let newHeader = { ...DEFAULT_HEADERS };
        if (socketId) {
            newHeader['X-Socket-Id'] = socketId;
        }
        if (shareViewKey) {
            newHeader['X-Share-Key'] = shareViewKey;
        }
        if (companyId) {
            newHeader['X-Company'] = companyId;
        }
        config['headers']['common'] = newHeader;

        if (
            config.url.includes(APP_API_PREFIX_GRID_UI) ||
            config.url.includes(APP_API_PREFIX_LQA) ||
            config.url.includes(APP_API_WORK_FLOW) ||
            config.url.includes(APP_API_PREFIX_DATABASE_SERVICE) ||
            config.url.includes('/shares') ||
            config.headers?.['regional']
        ) {
            config.baseURL = getRegionBaseUrl();
        }
        return config;
    },
    error => {
        throw error;
    }
);

instance.interceptors.response.use(
    response => {
        return parseBody(response);
    },
    error => {
        return parseError(error);
    }
);

function redirect(path) {
    const { origin } = window.location;
    const redirectUrl = origin + path;
    return window.location.replace(redirectUrl);
}

window.redirect = redirect;

function parseBody(response) {
    if (response?.status === 200 && response?.data === 'Gridly API is down for maintenance. We will back soon.') {
        const { dispatch } = store;
        dispatch(appActions.turnOnMaintainPopup({ maintainType: MAINTAIN_TYPES.MAINTAIN }));
        console.log('MAINTAIN MODE ON');
        throw new axios.Cancel('Operation canceled by the user.');
    }

    if (response.status === 204) {
        return 204;
    }

    return response;
}

function parseError(error) {
    const { response } = error;
    const { dispatch } = store;
    if (response && response.status === 401) {
        dispatch(_logoutSuccess());
    }

    // if (response && response.status === 403) {
    //     dispatch(_toggleBlockCurrentView());
    // }

    // //Api 404
    // if (response?.status === 404) {
    //     redirect('/not-found');
    //     return;
    // }

    if (response && response.data) {
        throw formatError(response);
    }

    throw error;
}

function getResponseError(responseData) {
    return {
        code: responseData?.code,
        message: responseData?.message || responseData?.messages,
        error: responseData?.error
    };
}

function formatError(response) {
    const responseData = response?.data || {};
    const { code, message, error } = getResponseError(responseData);

    const errorMessage = message || responseData.title || ERROR_MESSAGES[`${code}`] || ERROR_MESSAGES.unknown;

    const messageComponent = getErrorMessageComponent({
        message: errorMessage,
        code
    });

    return {
        status: response.status,
        message: messageComponent,
        originalMessage: errorMessage,
        code,
        error
    };
}

export default instance;
