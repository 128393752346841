import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NOTIFICATION_CENTER_MESSAGE_TYPE_KEY } from 'const';
import NotificationCenterTicketItem from './NotificationCenterTicketItem';
import Tooltip from 'components/tooltip/Base';
import * as authActions from 'auth/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    '@keyframes blinker': {
        '50%': {
            opacity: 0
        }
    },
    root: {
        position: 'relative'
    },
    unread: {
        position: 'absolute',
        padding: 4,
        top: 8,
        right: 11,
        borderRadius: '50%',
        cursor: 'pointer',
        border: `1px solid transparent`,
        '&:after': {
            content: `""`,
            display: 'block',
            width: 8,
            height: 8,
            borderRadius: '50%',
            background: theme.palette.primary.main,
            animation: '$blinker 1s infinite alternate linear'
        },
        '&:hover': {
            borderColor: theme.colors.silver,
            '&:after': {
                animation: 'unset'
            }
        }
    },
    read: {
        '&:after': {
            content: `""`,
            display: 'block',
            width: 8,
            height: 8,
            borderRadius: '50%',
            background: 'transparent',
            animation: 'unset'
        },
        '&:hover': {
            borderColor: theme.colors.silver,
            '&:after': {
                background: theme.palette.primary.main
            }
        }
    }
}));

const NotificationCenterItemWrapper = props => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { keyNotification, message = {}, isNoti } = props;
    const { id, read } = message;

    const markAsRead = useCallback(
        e => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            dispatch(
                authActions.markNotificationCenterAsRead({
                    key: keyNotification,
                    messageId: id
                })
            );
        },
        [dispatch, keyNotification, id]
    );

    const markAsUnread = useCallback(
        e => {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }
            dispatch(
                authActions.markNotificationCenterAsUnread({
                    key: keyNotification,
                    messageId: id
                })
            );
        },
        [dispatch, keyNotification, id]
    );

    const itemUI = useMemo(() => {
        const { message } = props;
        const { key } = message;
        switch (key) {
            case NOTIFICATION_CENTER_MESSAGE_TYPE_KEY.TICKET:
                return <NotificationCenterTicketItem {...props} markAsRead={markAsRead} />;
            default:
                return null;
        }
    }, [props, markAsRead]);

    return (
        <Grid item className={classes.root}>
            {itemUI}
            {!isNoti && (
                <Tooltip
                    title={read ? t('global_mark_as_unread') : t('global_mark_as_read')}
                    placement="top"
                    TransitionProps={{
                        style: {
                            margin: 0
                        }
                    }}
                >
                    <Grid
                        item
                        className={`${classes.unread} ${read ? classes.read : ''} unread`}
                        onClick={read ? markAsUnread : markAsRead}
                    />
                </Tooltip>
            )}
        </Grid>
    );
};

export default React.memo(NotificationCenterItemWrapper);
