import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'advancedSearch/actions';
import ViewCellInfo from './ViewCellInfo';
import { Grid, Typography } from '@material-ui/core';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { beautifyJSONValue } from 'utils/gridUI/formatData';
import JsonEditor from 'components/jsoneditor';
import { Portal } from 'react-overlays';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: -115,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: 930,
        maxWidth: `calc(100% - 40px)`,
        borderRadius: 6,
        boxShadow: theme.shadows[1],
        height: '100%',
        maxHeight: `calc(100% - 50px)`,
        background: theme.colors.white,
        pointerEvents: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxWidth: `calc(100vw - ${100}px)`
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: `calc(100vw - ${40}px)`
        }
    },
    editor: {
        width: '100%',
        height: '100%'
    },
    expand: {
        flex: 1
    },
    footer: {
        padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
        background: theme.colors.ghostwhite,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6
    },
    fs10: {
        fontSize: 10
    }
}));

function HtmlTextEdit({ columnId, value: cellValue, rowId, rowIndex, columnIndex, originalValue }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(cellValue);
    const isEnterAlready = React.useRef(false);
    const rootRef = React.useRef();
    const pasteRef = React.useRef(false);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const isSameData = () => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    };

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);

        if (isSameData()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameData()) {
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value,
                    rowId,
                    columnId
                })
            );
        }
    };
    const handleSaveAndQuit = editor => {
        const lastedValue = editor?.getValue();
        if (lastedValue === originalValue) {
            dispatch(gridActions.cancelCellEdit());
            dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
            return;
        }
        isEnterAlready.current = true;
        dispatch(
            gridActions.cellClickAwayAndGoNextRow({
                value: lastedValue,
                rowId,
                columnId,
                rowIndex,
                columnIndex
            })
        );
    };

    const handlePaste = () => {
        pasteRef.current = true;
    };

    const handleEscape = () => {
        dispatch(gridActions.cancelCellEdit());
    };

    const handleChange = value => {
        let formatValue = value;
        if (pasteRef.current) {
            formatValue = beautifyJSONValue(formatValue);
            pasteRef.current = false;
        }

        setValue(formatValue);
    };

    return (
        <Portal container={document.getElementById('scrollOverlay')}>
            <div ref={rootRef} className={classes.root}>
                <ViewCellInfo
                    rowIndex={rowIndex}
                    overrideStyle={{
                        left: 0,
                        top: 10
                    }}
                />

                <Grid container direction="column" className={classes.editor}>
                    <Grid item className={classes.expand}>
                        <JsonEditor
                            onEscape={handleEscape}
                            onSaveAndQuit={handleSaveAndQuit}
                            onPaste={handlePaste}
                            tabSize={2}
                            value={value}
                            onChange={handleChange}
                            placeholder={'Input your value'}
                            focus={true}
                            mode="html"
                        />
                    </Grid>
                    <Grid item className={classes.footer}>
                        <Grid container direction="row" spacing={2} alignItems="center" justify="flex-end">
                            <Grid item>
                                <Typography className={classes.fs10} variant="body1" display="inline">
                                    Return
                                </Typography>{' '}
                                <Typography className={classes.fs10} variant="body2" display="inline">
                                    to insert a line-break
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.fs10} variant="body1" display="inline">
                                    Shift + Return
                                </Typography>{' '}
                                <Typography className={classes.fs10} variant="body2" display="inline">
                                    to finish cell edit
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Portal>
    );
}

export default React.memo(HtmlTextEdit);
