import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    singleValue: {
        fontSize: '0.875rem',
        color: theme.colors.primaryText
    }
}));
function SingleValue(props) {
    const classes = useStyles();
    return (
        <Typography className={classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.any,
    selectProps: PropTypes.object.isRequired
};

export default React.memo(SingleValue);
