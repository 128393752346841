import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Grid, Radio, useTheme } from '@material-ui/core';
import AddDependencyButton from './AddDependencyButton';
import Divider from 'components/divider/Base';
// import DependencyList from './DependencyList';
import {
    useDependencyList,
    useIsDeletingDependency,
    useIsCreatingDependency,
    useIsUpdatingDependency
} from 'hooks/gridUI/dependency';
import { useDependencyOptions } from 'hooks/gridUI/column';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { sendManualTrack } from 'tracker';
import scrollIntoView from 'scroll-into-view-if-needed';
import Dependency from './Dependency';
import { DISABLED_OPACITY, LIST_MENU_MAX_HEIGHT } from 'const/style';
import ButtonBase from 'components/button/Base';
import SettingSVG from 'assets/images/svg/SettingSVG';
// import * as gridActions from 'grids/actions';
import { useParams } from 'react-router-dom';
import { useCurrentGrid } from 'hooks/gridUI';
import { ADDITIONAL_SETTING_STATUS } from 'const/gridUI';
// import * as branchActions from 'versionControl/actions/branch';

const useStyles = makeStyles(theme => ({
    root: {
        width: 630
    },
    dependencyList: {
        padding: `0px 20px`,
        maxHeight: `calc(${LIST_MENU_MAX_HEIGHT} - ${16 + 54 + 76}px)`,
        overflowY: 'auto'
    },
    buttonAdd: {
        padding: `0px 20px`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2)
    },
    dependencyItem: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    listWrapper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3)
    },
    title: {
        padding: `${theme.spacing(2)}px ${20}px`
    },
    buttonAdditionalSetting: {
        fontSize: 13,
        '& svg': {
            marginRight: theme.spacing(2)
        },
        '&.active': {
            background: 'rgba(110, 91, 190, 0.1)'
        }
    },
    additionalSetting: {
        padding: '0 10px',
        '& .Mui-checked': {
            color: theme.palette.primary.main
        }
    },
    radioWrapper: {
        cursor: 'pointer',
        '&.disabled': {
            pointerEvents: 'none'
        }
    }
}));

const TooltipReadonly = ({ isReadOnly, children }) => {
    return (
        <Tooltip
            title={
                isReadOnly ? (
                    <>
                        <p className="text-light">You do not have the appropriate permission rights to select.</p>
                        <p className="text-light">Please message your admin.</p>
                    </>
                ) : (
                    ''
                )
            }
        >
            {children}
        </Tooltip>
    );
};

function DependenciesMenu({ t }) {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const dependencies = useDependencyList();
    const dependencyOptions = useDependencyOptions();
    const isDeletingDependency = useIsDeletingDependency();
    const isCreatingDependency = useIsCreatingDependency();
    const isUpdatingDependency = useIsUpdatingDependency();
    const [isLoading, setIsLoading] = React.useState(true);
    const timerRef = React.useRef();
    const { dbId } = useParams();
    const currentGrid = useCurrentGrid();

    const [openAdditionalSetting, setOpenAdditionalSetting] = React.useState(
        currentGrid?.dependencySetting?.statusUpdateOnSourceChangeOption === ADDITIONAL_SETTING_STATUS.ASK
    );
    const [statusUpdateOnSourceChangeOption, setStatusUpdateOnSourceChangeOption] = React.useState(
        currentGrid?.dependencySetting?.statusUpdateOnSourceChangeOption || ADDITIONAL_SETTING_STATUS.DEFAULT
    );

    const isDoingOnDependencies = React.useMemo(() => {
        return isDeletingDependency || isCreatingDependency || isUpdatingDependency;
    }, [isDeletingDependency, isCreatingDependency, isUpdatingDependency]);

    const addDependencyHandler = React.useCallback(() => {
        sendManualTrack({ type: `Add Dependency` });
        dispatch(gridUIActions.createFakeDependency());
        const node = document.getElementById('dependencyListBottom');

        if (node) {
            scrollIntoView(node, {
                behavior: 'smooth',
                block: 'end',
                duration: 300,
                boundary: document.getElementById('dependencyListBoundary')
            });
        }
    }, [dispatch]);

    const updateChildDependency = React.useCallback(
        ({ id, value, parent, child }) => {
            if (child && parent) {
                dispatch(
                    gridUIActions.updateDependency({
                        dependencyId: id,
                        newDependency: {
                            id,
                            parent,
                            child: value
                        },
                        oldDependency: {
                            id,
                            parent,
                            child
                        }
                    })
                );
            } else if (parent) {
                dispatch(
                    gridUIActions.createDependency({
                        dependencyId: id,
                        parent,
                        child: value,
                        oldDependency: { id, parent },
                        successCallback: () => {
                            console.log('create dependencies success');
                        }
                    })
                );
            } else {
                dispatch(gridUIActions.updateChild({ dependencyId: id, value }));
            }
        },
        [dispatch]
    );

    const updateParentDependency = React.useCallback(
        ({ id, value, child, parent }) => {
            if (parent && child) {
                dispatch(
                    gridUIActions.updateDependency({
                        dependencyId: id,
                        newDependency: {
                            id,
                            parent: value,
                            child
                        },
                        oldDependency: {
                            id,
                            parent,
                            child
                        }
                    })
                );
            } else if (child) {
                dispatch(
                    gridUIActions.createDependency({
                        dependencyId: id,
                        parent: value,
                        child,
                        oldDependency: { id, child }
                    })
                );
            } else {
                dispatch(gridUIActions.updateParent({ dependencyId: id, value }));
            }
        },
        [dispatch]
    );

    const removeDependency = React.useCallback(
        ({ id }) => {
            sendManualTrack({
                type: `Delete Dependency`,
                customData: {
                    dependencyId: id
                }
            });
            dispatch(gridUIActions.deleteDependency({ dependencyId: id }));
        },
        [dispatch]
    );

    const toggleAdditionalSetting = React.useCallback(() => {
        setOpenAdditionalSetting(prev => !prev);
    }, []);

    const onChangeAdditionalSetting = React.useCallback(
        value => () => {
            setStatusUpdateOnSourceChangeOption(value);

            const successCallback = () => {};
            const errorCallback = () => {
                setStatusUpdateOnSourceChangeOption(
                    value === ADDITIONAL_SETTING_STATUS.DEFAULT
                        ? ADDITIONAL_SETTING_STATUS.ASK
                        : ADDITIONAL_SETTING_STATUS.DEFAULT
                );
            };

            dispatch(
                gridUIActions.updateCurrentGrid({
                    dbId,
                    newGrid: {
                        ...currentGrid,
                        dependencySetting: {
                            statusUpdateOnSourceChangeOption: value
                        }
                    },
                    oldGrid: currentGrid,
                    error: errorCallback,
                    success: successCallback
                })
            );
        },
        [dispatch, dbId, currentGrid]
    );

    React.useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        setTimeout(() => {
            setIsLoading(false);
        }, 0);
    }, []);

    if (isLoading) return null;
    return (
        <Grid container wrap="nowrap" direction="column" className={classes.root}>
            <Grid item>
                {dependencies?.length === 0 ? (
                    <Grid container className={`${classes.listWrapper} ${classes.dependencyList}`}>
                        <p className="caption">{t('dependencies_click_add_to_create')}</p>
                    </Grid>
                ) : (
                    <Grid container direction="column" wrap="nowrap">
                        <Grid item className={classes.title}>
                            <p className="body1">{t('show_changes')}</p>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="column"
                                className={`${classes.listWrapper} ${classes.dependencyList}`}
                                id={'dependencyListBoundary'}
                                wrap="nowrap"
                            >
                                {dependencies.map(dependency => {
                                    return (
                                        <Grid item className={classes.dependencyItem} key={dependency?.id}>
                                            <Dependency
                                                removeDependency={removeDependency}
                                                updateParentDependency={updateParentDependency}
                                                updateChildDependency={updateChildDependency}
                                                isDoingOnDependencies={isDoingOnDependencies}
                                                {...dependency}
                                                dependencies={dependencies}
                                                dependencyOptions={dependencyOptions}
                                                t={t}
                                            />
                                        </Grid>
                                    );
                                })}
                                <div id="dependencyListBottom"></div>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <AccessControl view={roleConst.EXTRA_AUTHORITIES.MANAGE_DEPENDENCY}>
                {({ isReadOnly }) => (
                    <>
                        <Grid className={classes.buttonAdd} container justify="space-between" alignItems="center">
                            <TooltipReadonly isReadOnly={isReadOnly}>
                                <Grid item>
                                    <AddDependencyButton
                                        addDependencyHandler={addDependencyHandler}
                                        isReadOnly={isReadOnly || isDoingOnDependencies}
                                        t={t}
                                    />
                                </Grid>
                            </TooltipReadonly>
                            <TooltipReadonly isReadOnly={isReadOnly}>
                                <Grid item>
                                    <ButtonBase
                                        className={`${classes.buttonAdditionalSetting} ${
                                            openAdditionalSetting ? 'active' : ''
                                        } text-brand-main`}
                                        variant="link"
                                        width={160}
                                        style={{
                                            pointerEvents: isReadOnly ? 'none' : '',
                                            opacity: isReadOnly ? DISABLED_OPACITY : 1
                                        }}
                                        onClick={toggleAdditionalSetting}
                                    >
                                        <SettingSVG color={theme.palette.primary.main} /> {t('additional_setting')}
                                    </ButtonBase>
                                </Grid>
                            </TooltipReadonly>
                        </Grid>
                        <Collapse in={openAdditionalSetting}>
                            <Grid
                                className={classes.additionalSetting}
                                container
                                direction="column"
                                spacing={theme.spacing(3)}
                            >
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    spacing={theme.spacing(2)}
                                    className={`${classes.radioWrapper} ${isReadOnly ? 'disabled' : ''}`}
                                    onClick={onChangeAdditionalSetting(ADDITIONAL_SETTING_STATUS.DEFAULT)}
                                >
                                    <Grid item>
                                        <Radio
                                            size={'small'}
                                            checked={
                                                statusUpdateOnSourceChangeOption === ADDITIONAL_SETTING_STATUS.DEFAULT
                                            }
                                            disabled={isReadOnly}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p className="body2">{t('additional_setting_change_in_source')}</p>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    spacing={theme.spacing(2)}
                                    className={`${classes.radioWrapper} ${isReadOnly ? 'disabled' : ''}`}
                                    onClick={onChangeAdditionalSetting(ADDITIONAL_SETTING_STATUS.ASK)}
                                >
                                    <Grid item>
                                        <Radio
                                            size={'small'}
                                            checked={statusUpdateOnSourceChangeOption === ADDITIONAL_SETTING_STATUS.ASK}
                                            disabled={isReadOnly}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <p className="body2">{t('additional_setting_always_show_prompt')}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </>
                )}
            </AccessControl>
        </Grid>
    );
}

export default React.memo(DependenciesMenu);
