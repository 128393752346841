import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import ImportFileSVG from 'assets/images/svg/ImportFileSVG';
import TemplateSVG from 'assets/images/svg/TemplateSVG';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
    GRID_UPLOAD_SUPPORTED_EXTENSIONS,
    GRID_UPLOAD_LOCALIZATION_SUPPORTED_EXTENSIONS,
    GRID_UPLOAD_MAX_SIZE
} from 'const';
import { enqueueSnackbar } from 'notifier/actions';
import Tooltip from 'components/tooltip/Base';
import ListItem from 'components/list/Item';
import { sendManualTrack } from 'tracker';
import EditSVG from 'assets/images/svg/EditSVG';
import { Trans, useTranslation } from 'react-i18next';
import { defaultBorder } from 'tutorials/helpers';
import { getUploadErrorMessage } from 'utils/upload';
import { useCurrentTutorial } from 'hooks/app';

const useStyles = makeStyles(theme => ({
    menu: {
        minWidth: 220,
        cursor: 'pointer',
        borderRadius: 6
    },
    uploadSection: {
        outline: 'none'
    },
    spWAuto: {
        paddingLeft: 20,
        paddingRight: 20
    },

    groupItem: {
        border: defaultBorder,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    spt2: {
        paddingTop: theme.spacing(2)
    }
}));

function GridMenu({ openUploadLocalizationSetup, openEmptyLocalizationSetup, addGridFromTemplate }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isTutorial = useCurrentTutorial();

    const onDropLocalizationRejected = useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, GRID_UPLOAD_SUPPORTED_EXTENSIONS);
            const fileError = fileErrors?.[0];

            sendManualTrack({ type: t('grid_upload_localization_rejected_mode') });
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch, t]
    );

    const handleFileImportInput = files => {
        openUploadLocalizationSetup(files);
    };

    const {
        getRootProps: getRootPropsLocalization,
        getInputProps: getInputPropsLocalization,
        isDragActive: isDragActiveLocalization
    } = useDropzone({
        noDragEventsBubbling: true,
        onDropAccepted: handleFileImportInput,
        onDropRejected: onDropLocalizationRejected,
        accept: GRID_UPLOAD_LOCALIZATION_SUPPORTED_EXTENSIONS,
        multiple: false,
        maxSize: GRID_UPLOAD_MAX_SIZE
    });

    return (
        <Paper className={classes.menu} id="gridMenu">
            <Grid container direction="column">
                <Grid item className={classes.groupItem}>
                    <Tooltip
                        disableHoverListener={isTutorial}
                        title={
                            <Trans i18nKey="tooltip_menu_add_grid_import" t={t}>
                                xxx
                                <br />
                                yyy
                            </Trans>
                        }
                        placement={'left'}
                    >
                        <Grid
                            {...getRootPropsLocalization({
                                style: {
                                    outline: 'none',
                                    border: isDragActiveLocalization ? `2px dashed #B4B4B4` : '2px dashed transparent',
                                    boxSizing: 'content-box'
                                }
                            })}
                            item
                            className={`gridMenuItem ${classes.uploadSection}`}
                        >
                            <input
                                {...getInputPropsLocalization()}
                                onClick={() => {
                                    sendManualTrack({ type: `Grid Click To Upload Translation Grid` });
                                }}
                                tr-dt="Upload Translation Grid"
                            />
                            <ListItem icon={<ImportFileSVG width={16} height={16} />} name={t('import_data')} />
                        </Grid>
                    </Tooltip>

                    <Tooltip
                        disableHoverListener={isTutorial}
                        title={
                            <Trans i18nKey="tooltip_menu_add_grid_from_scratch" t={t}>
                                xxx
                                <br />
                                yyy
                            </Trans>
                        }
                        placement={'left'}
                    >
                        <Grid
                            item
                            id="createEmptyTranslationGrid"
                            className={`gridMenuItem`}
                            onClick={openEmptyLocalizationSetup}
                            tr-dt="Start from scratch"
                        >
                            <ListItem icon={<EditSVG />} name={t('start_from_scratch')} />
                        </Grid>
                    </Tooltip>
                    <Tooltip
                        disableHoverListener={isTutorial}
                        title={
                            <Trans i18nKey="tooltip_menu_add_grid_from_template" t={t}>
                                xxx
                                <br />
                                yyy
                            </Trans>
                        }
                        placement={'left'}
                    >
                        <Grid
                            className={`gridMenuItem`}
                            onClick={addGridFromTemplate}
                            item
                            tr-dt="Create Grid from template"
                        >
                            <ListItem
                                icon={<TemplateSVG color={theme.colors.steel} width={16} height={16} />}
                                name={t('use_template')}
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    );
}

GridMenu.propTypes = {
    setOpen: PropTypes.func.isRequired,
    dbId: PropTypes.string.isRequired
};

export default React.memo(GridMenu);
