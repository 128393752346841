import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import { SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT } from 'const/style';
const useStyles = makeStyles(theme => ({
    noDatabase: {
        marginTop: 70
    },
    memberContentWrapper: {
        height: `calc(100vh - ${28 + 2 * theme.spacing(3) + 14 * 2}px - ${HEADER_HEIGHT}px)`,
        overflow: 'hidden',
        background: theme.colors.white,

        [theme.breakpoints.down('lg')]: {
            overflowX: 'auto'
        }
    },
    memberContent: {
        [theme.breakpoints.down('lg')]: {
            minWidth: 1000
        }
    },
    header: {
        minHeight: 28,
        marginBottom: theme.spacing(3)
    },
    sidebar: {
        padding: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        flexBasis: 260,
        borderRight: `1px solid ${theme.colors.border}`,
        height: `calc(100vh - ${28 + 2 * theme.spacing(3) + 14 * 2}px - ${HEADER_HEIGHT}px)`,
        overflow: 'auto',

        [theme.breakpoints.down('xs')]: {
            flexBasis: 'inherit'
        }
    },
    roleContent: {
        height: `calc(100vh - ${28 + 2 * theme.spacing(3) + 14 * 2}px - ${HEADER_HEIGHT}px)`,
        overflow: 'auto',
        flex: 1
    }
}));

function NestedSettingLayout({ name, AddComponent, Content, Sidebar }) {
    const classes = useStyles();

    React.useEffect(() => {
        let permissions = document.getElementById(`nestedSettingLayout`);
        permissions.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
    }, []);

    return (
        <Grid id={'nestedSettingLayout'} container direction="column" wrap="nowrap">
            <Grid className={classes.header} item container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h3">{name}</Typography>
                </Grid>
                <Grid item>{typeof AddComponent === 'function' ? AddComponent() : AddComponent}</Grid>
            </Grid>
            <Grid item className={classes.memberContentWrapper}>
                <Grid container className={classes.memberContent}>
                    <Grid item className={classes.sidebar}>
                        {typeof Sidebar === 'function' ? Sidebar() : Sidebar}
                    </Grid>
                    <Grid item className={`${classes.roleContent} scrollbar-app`}>
                        {typeof Content === 'function' ? Content() : Content}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(NestedSettingLayout);
