import { useSelector } from 'react-redux';

export function useThreadId({ rowId, columnId }) {
    return useSelector(state => state.gridUI.recordMetaData?.[rowId]?.['_metadata']?.value?.[columnId]?.comment?.id);
}

export function useComment({ rowId, columnId }) {
    const threadId = useThreadId({ rowId, columnId });
    return useSelector(state => state.gridUI.comment?.[threadId]);
}

export function useCommentByThreadId({ threadId }) {
    return useSelector(state => state.gridUI.comment?.[threadId]);
}

export function useGridCommentByCompositeViewId(compositeViewId) {
    return useSelector(state => state.gridUI.commentDashboard?.[compositeViewId]?.list);
}

export function useIsFetchingGridCommentByCompositeViewId(compositeViewId) {
    return useSelector(state => state.gridUI.commentDashboard?.[compositeViewId]?.isFetching);
}

export function useCommentRecordDataByRowId({ rowId }) {
    return useSelector(state => state.gridUI.data?.[rowId]);
}

export function useCOMMENT_SIZE() {
    return useSelector(state => state.gridUI.COMMENT_SIZE);
}

export function useCOMMENT_CURRENT_PAGE(compositeViewId) {
    return useSelector(state => state.gridUI.commentDashboard?.[compositeViewId]?.currentPage || 0);
}

export function useTotalComments(compositeViewId) {
    return useSelector(state => state.gridUI.commentDashboard?.[compositeViewId]?.totalComments);
}
