import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CountryFlag from 'components/svg-icon/CountryFlag';
import TranslationProgress from './TranslationProgress';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.colors.divider}`,
        borderRadius: 4
    },
    field: {
        height: 64
    },
    header: {
        background: theme.colors.ghostwhite,
        borderBottom: `1px solid ${theme.colors.divider}`,
        padding: '10px 20px'
    },
    body: {
        padding: theme.spacing(4)
    }
}));

function TargetItem({
    languageCode,
    upToDateRecords,
    outOfDateRecords,
    unSetRecords,
    upToDateWords,
    outOfDateWords,
    unSetWords,
    sourceLanguageCode,
    showPercentage
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const languageName = React.useMemo(() => languageCode, [languageCode]);

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item>
                <Grid container className={classes.header}>
                    <Grid item>
                        <CountryFlag languageCountryCode={languageCode} />
                    </Grid>
                    <Grid item style={{ marginLeft: 8 }}>
                        <p className="body1 inline">{languageName}</p>
                        <p className="caption inline"> (Target of </p>
                        <p className="body1 inline">{sourceLanguageCode}</p>
                        <p className="body2 inline">)</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.body}>
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <TranslationProgress
                            name={t('global_records')}
                            upToDate={upToDateRecords}
                            outOfDate={outOfDateRecords}
                            unSet={unSetRecords}
                            showPercentage={showPercentage}
                        />
                    </Grid>
                    <Grid item>
                        <TranslationProgress
                            name="Words"
                            upToDate={upToDateWords}
                            outOfDate={outOfDateWords}
                            unSet={unSetWords}
                            showPercentage={showPercentage}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(TargetItem);
