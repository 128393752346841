import React from 'react';

function NoPreviewDiffSVG({ color = 'white', ...other }) {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill="#E9EAEF"
            />
            <path
                d="M28.6175 28.3033C30.0386 26.7163 31.9277 25.8422 33.937 25.8422C35.306 25.8422 36.6188 26.2482 37.7661 27.0086V9.93066C37.7661 8.88697 36.9201 8.04089 35.8764 8.04089H24.1215H12.3666C11.3229 8.04089 10.4768 8.88697 10.4768 9.93066V39.1212C10.4768 39.5824 10.8146 39.9597 11.2275 39.9597L30.5783 39.5942C27.842 36.3034 25.7512 31.5052 28.6175 28.3033Z"
                fill="white"
            />
            <path
                d="M33.1366 14.4552H15.1312C14.8617 14.4552 14.6433 14.2027 14.6433 13.8913C14.6433 13.5798 14.8617 13.3273 15.1312 13.3273H33.1366C33.4061 13.3273 33.6245 13.5798 33.6245 13.8913C33.6245 14.2027 33.4061 14.4552 33.1366 14.4552Z"
                fill="#DBDCE1"
            />
            <path
                d="M33.1366 26.7026H15.1312C14.8617 26.7026 14.6433 26.4501 14.6433 26.1387C14.6433 25.8273 14.8617 25.5748 15.1312 25.5748H33.1366C33.4061 25.5748 33.6245 25.8273 33.6245 26.1387C33.6245 26.4501 33.4061 26.7026 33.1366 26.7026Z"
                fill="#DBDCE1"
            />
            <path
                d="M33.1361 18.5386H15.1312C14.8617 18.5386 14.6433 18.2861 14.6433 17.9746C14.6433 17.6632 14.8617 17.4107 15.1312 17.4107H33.1361C33.4056 17.4107 33.6239 17.6632 33.6239 17.9746C33.6239 18.2861 33.4056 18.5386 33.1361 18.5386Z"
                fill="#DBDCE1"
            />
            <path
                d="M33.1361 30.7844H15.1312C14.8617 30.7844 14.6433 30.5319 14.6433 30.2205C14.6433 29.909 14.8617 29.6566 15.1312 29.6566H33.1361C33.4056 29.6566 33.6239 29.909 33.6239 30.2205C33.6239 30.5319 33.4056 30.7844 33.1361 30.7844Z"
                fill="#DBDCE1"
            />
            <path
                d="M33.1365 22.6196H15.1312C14.8617 22.6196 14.6433 22.3671 14.6433 22.0557C14.6433 21.7442 14.8617 21.4918 15.1312 21.4918H33.1365C33.406 21.4918 33.6244 21.7442 33.6244 22.0557C33.6244 22.3671 33.406 22.6196 33.1365 22.6196Z"
                fill="#DBDCE1"
            />
            <path
                d="M33.1365 34.8674H15.1312C14.8617 34.8674 14.6433 34.6149 14.6433 34.3035C14.6433 33.9921 14.8617 33.7396 15.1312 33.7396H33.1365C33.406 33.7396 33.6244 33.9921 33.6244 34.3035C33.6244 34.6149 33.406 34.8674 33.1365 34.8674Z"
                fill="#DBDCE1"
            />
            <path
                d="M42.0878 39.4124L40.387 41.1133L45.4481 46.1745C45.926 46.6523 46.6967 46.6779 47.1745 46.2001V46.2001C47.6524 45.7222 47.6268 44.9514 47.1489 44.4735L42.0878 39.4124Z"
                fill="#A2A0AF"
            />
            <path
                d="M42.7645 38.3863L41.355 36.9769C40.9801 37.679 40.4974 38.3384 39.906 38.9297C39.3213 39.5144 38.6647 40.0008 37.9561 40.3811L39.3629 41.7879C39.5238 41.9489 39.7871 41.9489 39.948 41.7879L42.7645 38.9714C42.9254 38.8106 42.9254 38.5473 42.7645 38.3863Z"
                fill="#AEADB5"
            />
            <path
                d="M40.3249 40.824L39.1218 39.6209C38.7519 39.9089 38.3609 40.1634 37.9526 40.3825L39.3595 41.7893C39.5204 41.9503 39.7837 41.9503 39.9446 41.7893L40.91 40.8239C40.749 40.985 40.4858 40.985 40.3249 40.824Z"
                fill="#9A98A6"
            />
            <path
                d="M40.0215 27.0861C43.3243 30.3889 43.3243 35.7439 40.0214 39.0468C36.7188 42.3494 31.3638 42.3495 28.0608 39.0467C24.758 35.7439 24.7581 30.3889 28.0608 27.0862C31.3638 23.7833 36.7188 23.7832 40.0215 27.0861Z"
                fill="#AEADB5"
            />
            <path
                d="M38.31 37.3354C35.9944 39.6511 32.2397 39.6511 29.9238 37.3352C27.6081 35.0197 27.608 31.2649 29.9238 28.9492C32.2395 26.6335 35.9943 26.6336 38.31 28.9492C40.6258 31.265 40.6258 35.0196 38.31 37.3354Z"
                fill="#E9EAEF"
            />
            <path
                d="M38.31 37.3506C38.521 37.1396 38.7121 36.9166 38.8845 36.684C36.5632 38.4055 33.27 38.2142 31.165 36.1092C29.0601 34.0046 28.869 30.7113 30.5904 28.3899C30.3577 28.5624 30.1347 28.7536 29.9238 28.9644C27.608 31.2801 27.6081 35.0348 29.9238 37.3504C32.2397 39.6663 35.9944 39.6663 38.31 37.3506Z"
                fill="#D7D7DC"
            />
            <path
                d="M35.0601 30.0892C35.6084 30.0892 36.053 29.6447 36.053 29.0963C36.053 28.5479 35.6084 28.1034 35.0601 28.1034C34.5117 28.1034 34.0671 28.5479 34.0671 29.0963C34.0671 29.6447 34.5117 30.0892 35.0601 30.0892Z"
                fill="white"
            />
            <path
                d="M37.1447 30.8322C37.5332 30.8322 37.8481 30.5173 37.8481 30.1289C37.8481 29.7404 37.5332 29.4255 37.1447 29.4255C36.7563 29.4255 36.4414 29.7404 36.4414 30.1289C36.4414 30.5173 36.7563 30.8322 37.1447 30.8322Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(NoPreviewDiffSVG);
