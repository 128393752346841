import each from 'lodash/each';
import deepmerge from 'deepmerge';

export function deleteObjectProperty(target, prop) {
    return Object.keys(target).reduce((object, key) => {
        if (key !== prop) {
            object[key] = target[key];
        }
        return object;
    }, {});
}

export function convertArrayToObject(metas, property) {
    let obj = {};
    each(metas, meta => {
        obj[meta[property]] = meta;
    });
    return obj;
}

export function removeArrayInArray(mainArr, removedArr) {
    return mainArr.filter(function(e) {
        return this.indexOf(e) < 0;
    }, removedArr);
}

export function MaxNumberInAr(arr) {
    return Math.max.apply(Math, arr);
}

export function isNotContainAllCheckingArray({ mainArr = [], checkArr = [] }) {
    return checkArr?.some(i => !mainArr.includes(i));
}

export function parseJson(extraJson) {
    try {
        let object = JSON.parse(extraJson);
        return object;
    } catch (e) {
        return undefined;
    }
}

export function mergeObjects(object1, object2, options) {
    return deepmerge(object1, object2, options);
}

export function getKeyValueOnly(object) {
    const newObj = {};
    Object.keys(object).forEach(key => {
        if (object?.[key]) {
            newObj[key] = object[key]?.toString();
        }
    });
    return newObj;
}

export function diffStatusRefArrayInOrder(arr1 = [], arr2 = []) {
    const addedOptions = arr2.filter(x => !arr1.includes(x));
    const deletedOptions = arr1.filter(x => !arr2.includes(x));

    let arr = arr1.map(option => {
        if (deletedOptions?.includes(option)) {
            return { key: option, status: 'delete' };
        }
        return { key: option, status: 'normal' };
    });

    let offsetIndex = 1;
    addedOptions.forEach((option, index) => {
        const insertIndex = arr2.findIndex(item => item === option);
        if (insertIndex !== -1) {
            arr.splice(insertIndex + offsetIndex, 0, { key: option, status: 'update' });
            offsetIndex++;
        }
    });

    return [...arr];
}

// let arr1 = undefined;
// let arr2 = [['Option 1', 'Option 5']];

// console.log('diffStatusRefArrayInOrder', diffStatusRefArrayInOrder(arr1, arr2));

export function diffStatusArrayInOrder(arr1 = [], arr2 = []) {
    const addedOptions = arr2.filter(x => !arr1.includes(x));
    const deletedOptions = arr1.filter(x => !arr2.includes(x));

    let arr = arr1.map(option => {
        if (deletedOptions?.includes(option)) {
            return { key: option, status: 'delete' };
        }
        return { key: option, status: 'normal' };
    });

    let offsetIndex = 1;
    addedOptions.forEach((option, index) => {
        const insertIndex = arr2.findIndex(item => item === option);
        if (insertIndex !== -1) {
            arr.splice(insertIndex + offsetIndex, 0, { key: option, status: 'update' });
            offsetIndex++;
        }
    });

    return [...arr];
}

export function diffStatusArrayInOrderFile(arr1 = [], arr2 = []) {
    const addedOptions = arr2.filter(x => !arr1?.map(a => a?.id).includes(x?.id));
    const deletedOptions = arr1.filter(x => !arr2?.map(a => a?.id).includes(x?.id));

    let arr = arr1.map(option => {
        if (deletedOptions?.map(dlO => dlO?.id)?.includes(option?.id)) {
            return { key: option, status: 'delete' };
        }
        return { key: option, status: 'normal' };
    });

    let offsetIndex = 1;
    addedOptions.forEach((option, index) => {
        const insertIndex = arr2.findIndex(item => item?.id === option?.id);
        if (insertIndex !== -1) {
            arr.splice(insertIndex + offsetIndex, 0, { key: option, status: 'update' });
            offsetIndex++;
        }
    });

    return [...arr];
}

// let arr1 = ['Option 1', 'Option 2', 'Option 3', 'Option 8', 'Option 9', 'Option 10', 'Option 5'];
// let arr2 = ['Option 1', 'Option 5', 'Option 2', 'Option 7'];

// console.log('testOrder', diffStatusArrayInOrder(arr1, arr2));

export function isLDEmpty(data) {
    if (data instanceof Array) {
        return data?.length === 0;
    }
    if (typeof data === 'number') {
        return false;
    }
    if (typeof data === 'string') {
        return data?.length === 0;
    }
    if (typeof data === 'boolean') {
        return false;
    }

    if (!data) return true;

    if (typeof data === 'object' && Object.keys(data).length === 0) {
        return true;
    }

    return false;
}

export function generateRangeNumber(start, stop) {
    let arr = [];
    for (let i = start; i <= stop; i++) {
        arr.push(i);
    }
    return arr;
}

export function isArrayObject(array) {
    if (!(array instanceof Array)) {
        return false;
    }
    return !array?.some(i => {
        if (isLDEmpty(i)) return true;
        return typeof i !== 'object';
    });
}
