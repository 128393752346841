import * as types from '../types';

const handler = {
    [types.CLEAR_WHEN_SWITCH_VIEW_OR_SWITCH_GRID](state, { payload }) {
        return {
            ...state,
            quickFilters: {},
            quickSorts: {}
        };
    },
    [types.CLEAR_QUICK_FILTER_WHEN_VIEW_FILTER_IS_UPDATED_SOCKET](state, { payload }) {
        const { columnId } = payload;
        const newQuickFilters = { ...state?.quickFilters };
        delete newQuickFilters[columnId];
        return {
            ...state,
            quickFilters: newQuickFilters
        };
    }
};

export default handler;
