import React from 'react';

function ArrowNextSVG({ color = 'white', ...other }) {
    return (
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M0.562114 3.2447L0.546504 3.24799H7.23805L5.13447 1.21743C5.03146 1.11829 4.97496 0.983996 4.97496 0.843045C4.97496 0.702094 5.03146 0.568738 5.13447 0.469368L5.46179 0.15395C5.56471 0.0548143 5.70187 0 5.84813 0C5.99447 0 6.13171 0.0544229 6.23463 0.153559L9.84057 3.62636C9.9439 3.72589 10.0004 3.85846 10 3.99949C10.0004 4.1413 9.9439 4.27395 9.84057 4.37333L6.23463 7.84644C6.13171 7.9455 5.99455 8 5.84813 8C5.70187 8 5.56471 7.94542 5.46179 7.84644L5.13447 7.53102C5.03146 7.43204 4.97496 7.29986 4.97496 7.15891C4.97496 7.01804 5.03146 6.89283 5.13447 6.79377L7.26179 4.75186H0.554634C0.253252 4.75186 0 4.50167 0 4.21154V3.76543C0 3.47531 0.260732 3.2447 0.562114 3.2447Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ArrowNextSVG);
