import React from 'react';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import Element from 'components/formula/components';
import ToolTipAutoQAClick from 'components/tooltip/ToolTipAutoQAClick';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
    useAutoQAByCategory,
    useDependencyListWithoutFakeId,
    useViewColumnsWithUserLanguageViewsAndMetadata
} from 'hooks/gridUI';
import WarningRoundSVG from 'assets/images/svg/WarningRoundSVG';
import autoQATooltips from 'components/lqa/autoQATooltips.json';
import { Editor, Transforms, createEditor } from 'slate';
import * as columnTypes from 'const/columnTypes';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import CountryFlag from 'components/svg-icon/CountryFlag';
import { useActiveCategories } from 'hooks/gridUI/autoQA';

const useStyles = makeStyles(theme => ({
    errorList: {
        margin: 0,
        paddingLeft: theme.spacing(4)
    },
    targetName: {
        color: theme.colors.midGrey
    }
}));

function LQASourceHighlighter({
    value,
    onChange,
    placeholder,
    onBlur,
    onKeyDown,
    inputRef,
    readOnly,
    rowId,
    columnId,
    showTooltip = false,
    targetValidations,
    ...rest
}) {
    const isPreventBlurRef = React.useRef(false);
    const dependencies = useDependencyListWithoutFakeId();
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const activeCategories = useActiveCategories();

    const childDpDcColIds = React.useMemo(() => {
        return dependencies?.filter(dpDc => dpDc?.parent === columnId)?.map(dpDc => dpDc?.child);
    }, [dependencies, columnId]);

    const targetOptions = React.useMemo(() => {
        return viewColumns
            .filter(viewCol => {
                const columnId = viewCol?.id;
                const isChild = childDpDcColIds?.includes(columnId);
                const columnType = getCorrectColumnType(viewCol);
                return isChild && columnType === columnTypes.TRANSLATION;
            })
            .map(lang => ({
                ...lang,
                value: lang.id,
                group: lang.group,
                label: `${lang.name}`,
                icon: () => <CountryFlag languageCountryCode={lang.group} />
            }));
    }, [viewColumns, childDpDcColIds]);

    const errorsByType = React.useMemo(() => {
        let errorsByType = {};
        Object.keys(targetValidations).forEach(targetColumnId => {
            targetValidations[targetColumnId].forEach(error => {
                const errorModule = error.module;
                if (!errorsByType[errorModule]) errorsByType[errorModule] = [];
                const column = targetOptions.find(option => option.id === targetColumnId);
                errorsByType[errorModule] = [...new Set([...errorsByType[errorModule], ...(column ? [column] : [])])];
            });
        });
        return errorsByType;
    }, [targetValidations, targetOptions]);

    const renderLeaf = props => <Leaf errorsByType={errorsByType} showTooltip={showTooltip} {...props} />;

    const renderElement = props => <Element {...props} />;

    const editor = React.useMemo(() => {
        return withReact(withHistory(createEditor()));
    }, []);

    const decorate = React.useCallback(
        ([node, path]) => {
            const ranges = [];
            let validations = [];
            Object.keys(targetValidations).forEach(targetColumnId => {
                targetValidations[targetColumnId].forEach(error => {
                    const isAnyExistedError = validations.some(validation => validation.errorid === error.errorid);
                    const isIgnored = error.ignored;
                    const isActiveCategory = activeCategories?.includes(error?.category);
                    if (isAnyExistedError || isIgnored || !isActiveCategory) return;
                    validations.push(error);
                });
            });

            for (const validation of validations) {
                ranges.push({
                    ...validation,
                    anchor: { path, offset: validation.start },
                    focus: { path, offset: validation.end },
                    rowId,
                    columnId,
                    selection: {
                        anchor: { path, offset: validation.start },
                        focus: { path, offset: validation.end }
                    }
                });
            }

            return ranges;
        },
        [targetValidations, rowId, columnId, activeCategories]
    );

    const handleChange = React.useCallback(
        changedValue => {
            const isAstChange = editor.operations.some(op => 'set_selection' !== op.type);
            if (isAstChange) {
                onChange && onChange(changedValue);
            }
        },
        [onChange, editor]
    );

    React.useEffect(() => {
        if (inputRef && editor) {
            inputRef.current = editor;
        }
    }, [editor, inputRef]);

    const handleBlur = React.useCallback(() => {
        editor.blurSelection = editor.selection;
        setTimeout(() => {
            if (isPreventBlurRef.current) {
                isPreventBlurRef.current = false;
                return;
            }
            onBlur();
        }, 200);
    }, [onBlur, editor]);

    React.useEffect(() => {
        if (editor && !readOnly) {
            Transforms.select(editor, Editor.end(editor, []));
            ReactEditor.focus(editor);
        }
    }, [editor, readOnly]);

    return (
        <>
            <Slate editor={editor} value={value} onChange={handleChange}>
                <Editable
                    onKeyDown={onKeyDown}
                    renderElement={renderElement}
                    onPaste={() => {}}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    decorate={decorate}
                    renderLeaf={renderLeaf}
                    itemRef={inputRef}
                    readOnly={readOnly}
                    {...rest}
                />
            </Slate>
        </>
    );
}

const Leaf = ({ attributes, children, leaf, errorsByType, showTooltip }) => {
    const classes = useStyles();
    const validation = leaf;
    const errorType = useAutoQAByCategory(validation.category);
    const sameErrorTypecolumns = errorsByType[validation.module];

    const generateSourceError = () => {
        let validationError = autoQATooltips[validation.module].s;
        validationError = validationError.replace('#xxx#', validation.msg);
        return validationError;
    };

    if (validation.category) {
        return showTooltip ? (
            <ToolTipAutoQAClick
                title={
                    <>
                        <Grid item container alignItems="center" style={{ padding: 8, paddingTop: 0 }}>
                            <Grid item style={{ display: 'flex' }}>
                                <WarningRoundSVG />
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                                <Typography variant="body1">{generateSourceError()}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            {sameErrorTypecolumns.map(column => (
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    key={column?.id}
                                    style={{ marginBottom: 8, marginLeft: 14 }}
                                >
                                    <Grid item className={classes.targetName}>
                                        •
                                    </Grid>
                                    <Grid item style={{ marginLeft: 8, display: 'flex' }}>
                                        {column?.icon()}
                                    </Grid>
                                    <Grid item style={{ marginLeft: 8 }}>
                                        <Typography variant="body2" className={classes.targetName}>
                                            (Target_{column?.group})
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                }
            >
                <span {...attributes} style={{ background: errorType?.color }}>
                    {children}
                </span>
            </ToolTipAutoQAClick>
        ) : (
            <span {...attributes} style={{ background: errorType?.color }}>
                {children}
            </span>
        );
    }

    return <span {...attributes}>{children}</span>;
};

export default LQASourceHighlighter;
