import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { TRANSLATION_OVERVIEW_TYPES } from 'const/gridUI';
import SourceItem from './SourceItem';
import TargetItem from './TargetItem';

const useStyles = makeStyles(theme => ({
    root: {},
    spacing4: {
        marginBottom: theme.spacing(3)
    }
}));

function TranslationItem({ group, translationData, sourceLanguageCode, type, showPercentage }) {
    const classes = useStyles();

    const content = React.useCallback(() => {
        switch (type) {
            case TRANSLATION_OVERVIEW_TYPES.SOURCE:
                return (
                    <Grid item container direction="column" wrap="nowrap" className={classes.spacing4}>
                        <Grid item>
                            <SourceItem
                                languageCode={group}
                                totalRecords={translationData?.all?.recordCount}
                                totalWords={translationData?.all?.wordCount}
                            />
                        </Grid>
                    </Grid>
                );
            case TRANSLATION_OVERVIEW_TYPES.SOURCE_TARGET:
            case TRANSLATION_OVERVIEW_TYPES.TARGET:
                return (
                    <Grid item container wrap="nowrap" direction="column" className={classes.spacing4}>
                        <Grid item>
                            <TargetItem
                                languageCode={group}
                                upToDateRecords={translationData?.upToDate?.recordCount}
                                upToDateWords={translationData?.upToDate?.wordCount}
                                outOfDateRecords={translationData?.outOfDate?.recordCount}
                                outOfDateWords={translationData?.outOfDate?.wordCount}
                                unSetRecords={translationData?.unset?.recordCount}
                                unSetWords={translationData?.unset?.wordCount}
                                sourceLanguageCode={sourceLanguageCode}
                                showPercentage={showPercentage}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }, [type, classes, group, translationData, sourceLanguageCode, showPercentage]);

    return (
        <Grid container className={classes.root}>
            {content()}
        </Grid>
    );
}

export default React.memo(TranslationItem);
