import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Option from 'components/option/Base';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    optionList: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));

function Text({ value: cellValue }) {
    const classes = useStyles();
    const theme = useTheme();
    if (isEmpty(cellValue)) return null;

    const getJsonValue = cellValue => {
        if (isArray(cellValue)) {
            let values = cellValue.map(i => i.referencedDataItem);
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(cellValue);
    if (!isArray(newData) || !newData) return null;

    return (
        <Grid
            container
            spacing={1}
            className={classes.container}
            wrap="nowrap"
            justify="flex-start"
            alignItems="center"
        >
            {newData.map((item, index) => (
                <Grid item key={index}>
                    <Option className={classes.option} size="small" label={item} color={theme.colors.dodgerBlue} />
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(Text);
