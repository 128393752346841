import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import ListItem from 'components/list/Item';
import PopperMenu from 'components/menus/Popper';
import { INPUT_HEIGHT, SPACING_LIST_NAME_WITH_ICON } from 'const/style';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        height: INPUT_HEIGHT,
        padding: `0px 14px`,
        borderRadius: 4,
        position: 'relative',
        cursor: 'pointer',
        border: `1px solid ${theme.colors.silver}`
    },
    arrowDown: {
        display: 'flex',
        marginLeft: SPACING_LIST_NAME_WITH_ICON
    }
}));

function IdentifySelect({ jobs, selectedJob, onChange }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleJobChange = role => {
        onChange && onChange(role);
        handleClickAway();
    };

    return (
        <>
            <Grid container className={classes.root} wrap="nowrap" alignItems="center" onClick={handleClick}>
                <Grid item>
                    <Typography variant="body2">{selectedJob?.label}</Typography>
                </Grid>
                <Grid item style={{ flex: 1 }} />
                <Grid item className={classes.arrowDown}>
                    <ArrowDownSVG />
                </Grid>
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-center'} handleClickAway={handleClickAway}>
                    <Grid container direction="column" style={{ width: anchorEl?.offsetWidth }}>
                        {jobs?.map(job => {
                            const isSelected = job?.value === selectedJob?.value;
                            return (
                                <ListItem
                                    onClick={() => handleJobChange(job)}
                                    key={job?.value}
                                    isSelected={isSelected}
                                    name={job?.label}
                                />
                            );
                        })}
                    </Grid>
                </PopperMenu>
            )}
        </>
    );
}

export default React.memo(IdentifySelect);
