import React from 'react';
import * as columnTypes from 'const/columnTypes';
import ExtraOptionsWithSelectionAndTag from './ExtraOptionsWithSelectionAndTag';
import ExtraOptionsWithReference from './ExtraOptionsWithReference';
import ExtraOptionsWithLocalization from './ExtraOptionsWithLocalization';
import ExtraOptionsWithTranslation from './ExtraOptionsWithTranslation';
import ExtraNumberOptions from './ExtraNumberOptions';
import ExtraFormula from './ExtraFormula';
import ExtraFiles from './ExtraFiles';

function ExtraOptionsByType({ type, ...rest }) {
    switch (type) {
        case columnTypes.MULTIPLE_SELECTIONS:
        case columnTypes.GROUP_TAGS:
        case columnTypes.SINGLE_SELECTION:
            return <ExtraOptionsWithSelectionAndTag {...rest} />;
        case columnTypes.REFERENCE:
            return <ExtraOptionsWithReference {...rest} />;
        case columnTypes.LOCALIZATION:
            return <ExtraOptionsWithLocalization {...rest} />;
        case columnTypes.TRANSLATION:
            return <ExtraOptionsWithTranslation {...rest} />;
        case columnTypes.NUMBER:
            return <ExtraNumberOptions {...rest} />;
        case columnTypes.FORMULA:
            return <ExtraFormula {...rest} />;
        case columnTypes.FILES:
            return <ExtraFiles {...rest} />;
        default:
            return null;
    }
}

export default React.memo(ExtraOptionsByType);
