import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as authActions from 'auth/actions';
import { getSsoRegisterInfoApi } from 'services/auth';
import { getCompaniesApi } from 'services/company';

const ERROR_MAP = {
    userNotFound: "Your google email address doesn't exist. Please check your entry and try again"
};

function SsoRedirect() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const queryParamObject = new URLSearchParams(search);
    const provider = queryParamObject.get('provider');
    const error = queryParamObject.get('error');

    useEffect(() => {
        const handleSsoRedirect = async () => {
            if (error) return history.push('/signin', { error: ERROR_MAP[error] || error });
            const registerUser = await getSsoRegisterInfoApi();
            if (registerUser) {
                history.push(`/signup?email=${registerUser.email}`, {
                    firstName: registerUser.firstName,
                    lastName: registerUser.lastName
                });
            } else {
                dispatch(
                    authActions.fetchMe({
                        successCallback: async () => {
                            console.log('fetch user info successfully');
                            const companies = await getCompaniesApi();

                            if (companies.length > 1) {
                                history.push('/companies');
                            } else {
                                history.push('/');
                            }
                        },
                        errorCallback: () => {
                            console.log('failed to fetch user info');
                            history.push('/signin');
                        }
                    })
                );
            }
        };

        if (provider) {
            handleSsoRedirect();
        } else {
            dispatch(
                authActions.fetchMe({
                    successCallback: () => {
                        console.log('fetch user info successfully');
                        // dispatch(_setCompanyId({ companyId: userInfo.companyId }));
                        history.push('/');
                    },
                    errorCallback: () => {
                        console.log('failed to fetch user info');
                    }
                })
            );
        }
    }, [dispatch, history, provider, error]);

    return <div></div>;
}

export default React.memo(SsoRedirect);
