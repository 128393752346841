import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Dialog, Fade, alpha } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import GridTable from './table';
import { AutoSizer } from 'react-virtualized-dn';
import { ROW_HEIGHT } from 'const/gridUI';
import {
    useIsFetching,
    useIsCellPreview,
    useCurrentDefaultPreviewFile,
    useCellPreviewData,
    useIsCellPreviewCanEdit,
    useCurrentPreviewData,
    useTotalRecords,
    useAdvancedSearchCreatedTimeAtById,
    useIsBlockedGrid,
    useAdvancedSearch,
    useSelectedAdvancedSearchId,
    useAdvancedSearchId,
    useHideWarning,
    useCurrentAdvancedSearchId,
    useGridColumns,
    useColumnWidthStore
} from 'hooks/advanced';
import Spinner from 'components/spinner/Base';
import * as advancedSearchActions from 'advancedSearch/actions';
import ManageActionStatus from './toolbar/ManageActionStatus';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { checkContainClassName, checkContainId } from 'utils/clickAway';
import FilesPreview from 'components/filesPreview';
import ManageSearch from './search';
import ManageFilter from './toolbar/ManageFilter';
import ManageSort from './toolbar/ManageSort';
import ManageExport from './toolbar/ManageExport';
import moment from 'moment';
// import ManageSave from './toolbar/ManageSave';
// import ManageShare from './toolbar/ManageShare';
// import ManageDuplicate from './toolbar/ManageDuplicate';
import { DISABLED_OPACITY } from 'const/style';
import ws from 'socket';
import * as appActions from 'app/actions';
import { useCompanyId, useCurrentUserId } from 'hooks/auth';
import { ADVANCED_SEARCH, ADVANCED_SEARCH_STATUS } from 'const';
import { useParams } from 'react-router-dom';
import InfoIconSVG from 'assets/images/svg/InfoIconSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useIsShareViewLink } from 'hooks/app';
import { useTranslation } from 'react-i18next';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import TMGuideSVG from 'assets/images/svg/TMGuideSVG';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        background: theme.colors.ghostwhite
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    },
    propertyButton: {
        display: 'flex',
        '&:hover': {
            background: theme.colors.divider
        }
    },
    search: {
        minWidth: 260
    },
    gridTable: {
        flex: 1,
        borderRadius: 6,
        userSelect: 'none',
        position: 'relative'
    },
    toolbar: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        borderTop: `1px solid ${theme.colors.border}`,
        borderBottom: `1px solid ${theme.colors.border}`,
        background: theme.palette.common.white
    },
    result: {
        padding: `8px 10px`,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    },
    primaryText: {
        color: theme.colors.primaryText
    },
    warning: {
        height: 32,
        background: alpha(theme.colors.sun, 0.3),
        borderLeft: `3px solid ${alpha(theme.colors.sun, 0.8)}`,
        borderRadius: `4px`
    },
    flex: {
        display: 'flex'
    },
    closeIcon: {
        display: 'flex',
        cursor: 'pointer'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function WarningTag() {
    const classes = useStyles();
    const theme = useTheme();
    const advancedSearch = useAdvancedSearch();
    const hideWarning = useHideWarning();
    const dispatch = useDispatch();

    const handleHideWarning = React.useCallback(() => {
        dispatch(advancedSearchActions.setHideWarning(true));
    }, [dispatch]);

    if (hideWarning) return null;
    if (!advancedSearch || advancedSearch?.status !== ADVANCED_SEARCH_STATUS.DRAFT) return null;

    return (
        <Grid item>
            <Grid
                container
                className={classes.warning}
                spacing={3}
                wrap="nowrap"
                alignItems="center"
                justify="space-between"
            >
                <Grid item className={classes.expand}>
                    <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item className={classes.flex}>
                            <InfoIconSVG color={theme.colors.sun} />
                        </Grid>
                        <Grid item className={classes.expand}>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{
                                    __html: `Modifying a cell here will update its value in the grid directly`
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item onClick={handleHideWarning} className={classes.closeIcon}>
                    <CloseIconSVG />
                </Grid>
            </Grid>
        </Grid>
    );
}

function TableWrapper({ isSideBarOn }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const rowHeight = ROW_HEIGHT * 2;
    const isFetching = useIsFetching();
    const rootRef = React.useRef();
    const isOpenPreview = useIsCellPreview();
    const previewDefaultFile = useCurrentDefaultPreviewFile();
    const isPreviewFileReadOnly = useIsCellPreviewCanEdit();
    const currentPreviewData = useCurrentPreviewData();
    const filesPreview = useCellPreviewData();
    const totalRecords = useTotalRecords();
    const advancedSearchId = useAdvancedSearchId();
    const selectedAdvancedSearchId = useSelectedAdvancedSearchId();
    const currentAdvancedSearchId = useCurrentAdvancedSearchId();
    const { workspaceId } = useParams();
    const gridColumns = useGridColumns();
    const isShareViewLink = useIsShareViewLink();
    const columnWidthStore = useColumnWidthStore();

    const createdTime = useAdvancedSearchCreatedTimeAtById(advancedSearchId || selectedAdvancedSearchId);
    const isBlockedGrid = useIsBlockedGrid();
    const roles = useRole();

    const accessManageGridRecord = roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_RECORD];
    const accessManageGridColumn = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN];

    const userId = useCurrentUserId();
    const companyId = useCompanyId();

    const isNoSelect = React.useMemo(() => {
        return !(advancedSearchId || selectedAdvancedSearchId);
    }, [advancedSearchId, selectedAdvancedSearchId]);

    const fetchMoreRows = React.useCallback(() => {
        dispatch(
            advancedSearchActions.fetchMoreRecords({
                wsId: workspaceId,
                advancedSearchId: currentAdvancedSearchId,
                success: () => {
                    console.log('fetch more success');
                },
                error: () => {
                    console.log('fetch more failed');
                }
            })
        );
    }, [currentAdvancedSearchId, dispatch, workspaceId]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(advancedSearchActions.closeCellFilePreview());
    };

    const deleteImageHandler = React.useCallback(
        file => {
            console.log('delete', currentPreviewData);
        },
        [currentPreviewData]
    );

    const handleClickAway = React.useCallback(
        e => {
            if (
                checkContainId(e, 'sidebar-right-lqa') ||
                checkContainId(e, 'sidebar-right-content-lqa') ||
                checkContainId(e, 'search-record')
            ) {
                return false;
            }
            if (checkContainId(e, 'cell_context_menu')) {
                return false;
            }
            if (checkContainId(e, 'comment-emoji')) {
                return false;
            }
            if (checkContainId(e, 'comment_edit_emoji')) {
                return false;
            }

            if (checkContainId(e, 'rows_popup_context')) {
                return false;
            }

            if (checkContainId(e, 'cell_auto_expand')) {
                return false;
            }
            if (checkContainId(e, 'fill-color-panel-trigger') || checkContainId(e, 'popper-fill-color')) {
                return false;
            }
            if (checkContainId(e, 'file-previews')) {
                return false;
            }
            if (checkContainId(e, 'trigger-comment')) {
                return false;
            }
            if (checkContainId(e, 'more-actions-trigger') || checkContainId(e, 'popper-more-actions')) {
                return false;
            }
            if (checkContainId(e, 'portal-mention-users')) {
                return false;
            }

            if (checkContainId(e, 'select-target-language')) {
                return false;
            }

            if (checkContainId(e, 'lqa-suggestion')) {
                return false;
            }

            if (checkContainId(e, 'confirm-column-box')) {
                return false;
            }

            if (
                checkContainId(e, 'confirm-retranslate-popper') ||
                checkContainId(e, 'calendar-portal') ||
                checkContainClassName(e, 'react-datepicker-popper')
            ) {
                return false;
            }

            dispatch(advancedSearchActions.resetCellStatus());
        },
        [dispatch]
    );

    React.useEffect(() => {
        if (currentAdvancedSearchId) {
            ws.advancedSearchSubscribeTopics({ userId, companyId, advancedSearchId: currentAdvancedSearchId });
            dispatch(
                appActions.setCurrentPage({
                    currentPage: ADVANCED_SEARCH
                })
            );
        }

        return () => {
            if (currentAdvancedSearchId) {
                ws.unsubscribeMultipleTopic();
            }
        };
    }, [dispatch, userId, companyId, currentAdvancedSearchId]);

    return (
        <Grid
            container
            direction="column"
            className={`${classes.root} ${isBlockedGrid && classes.disabled}`}
            style={{ background: isNoSelect && theme.palette?.common.white }}
        >
            <Grid item>
                <Grid
                    className={`${classes.toolbar} ${isNoSelect && classes.disabled}`}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={3}>
                            <ManageFilter />
                            <ManageSort />
                            <ManageExport />
                            {/* <ManageShare />
                            <ManageDuplicate /> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={3}>
                            <WarningTag />
                            <ManageActionStatus />
                            <Grid item>
                                <ManageSearch />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!isNoSelect && (
                <Grid item className={classes.result}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="caption" display="inline">
                                Search result:
                            </Typography>{' '}
                            <Typography variant="body1" display="inline">
                                found {totalRecords} records
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" display="inline">
                                Result is generated at:
                            </Typography>{' '}
                            <Typography variant="body1" display="inline">
                                {moment(createdTime)?.format('hh:mm A, DD MMM YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid ref={rootRef} id={`gridUI-table`} item className={classes.gridTable}>
                <AutoSizer>
                    {({ width, height }) => {
                        if (isNoSelect)
                            return (
                                <Grid
                                    style={{
                                        width,
                                        height,
                                        position: 'absolute',
                                        zIndex: 1000
                                    }}
                                    container
                                    direction="column"
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item>
                                        <TMGuideSVG />
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ fontWeight: 400 }} variant="h3">
                                            {t('nothing_here_yet')}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ textAlign: 'center' }}>
                                        <Typography variant="body2" align="center">
                                            Gridly will automatically match all the columns with same name and type.
                                            <br />
                                            Please select the <strong>databases</strong>,<strong>grids</strong>, and{' '}
                                            <strong>columns</strong> to define search range.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );

                        return (
                            <>
                                {isFetching && (
                                    <div
                                        style={{
                                            width,
                                            height,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            zIndex: 1000
                                        }}
                                    >
                                        <Spinner size={40} thick={4} />
                                    </div>
                                )}
                                <GridTable
                                    width={width}
                                    height={height}
                                    pathRef={{}}
                                    maxHeight={height}
                                    rowHeight={rowHeight}
                                    fixedRowCount={2}
                                    ROW_START_INDEX={0}
                                    fetchMoreRows={fetchMoreRows}
                                    prefix={'_advanced'}
                                    isSideBarOn={isSideBarOn}
                                    columns={gridColumns}
                                    isShareViewLink={isShareViewLink}
                                    columnWidthStore={columnWidthStore}
                                    accessManageGridRecord={accessManageGridRecord}
                                    accessManageGridColumn={accessManageGridColumn}
                                />
                            </>
                        );
                    }}
                </AutoSizer>
            </Grid>

            {isOpenPreview && (
                <Dialog
                    className={classes.dialog}
                    fullScreen
                    open={isOpenPreview}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <FilesPreview
                        isReadOnly={!isPreviewFileReadOnly}
                        onDelete={deleteImageHandler}
                        files={filesPreview}
                        onClose={handleClose}
                        defaultImage={previewDefaultFile}
                        dbId={currentPreviewData?.dbId}
                    />
                </Dialog>
            )}
        </Grid>
    );
}

export default TableWrapper;
