import React from 'react';

function UpdateIconSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Path"
                d="M11.9377 0.0952941L13.43 4.52917C13.4546 4.60475 13.4015 4.68047 13.3221 4.68321L8.64524 4.79742C8.53845 4.80046 8.46733 4.68062 8.524 4.59969L9.57517 3.09846C7.26347 1.53426 4.06506 2.2051 2.58676 4.64846C1.31357 6.74907 1.80352 9.52007 3.71396 11.0484C5.73856 12.6684 8.60489 12.4109 10.3351 10.6264C10.6563 10.2949 11.1715 10.251 11.546 10.5133C12.0116 10.8393 12.085 11.5093 11.6886 11.9205C9.23062 14.4677 5.09145 14.7716 2.28019 12.2934C-0.0727129 10.2179 -0.684468 6.7352 0.819465 3.96739C2.78447 0.34737 7.37822 -0.688814 10.6574 1.55284L11.7104 0.0489944C11.7689 -0.0345525 11.904 -0.00609253 11.9377 0.0952941Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(UpdateIconSVG);
