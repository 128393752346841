import store from 'store/configStore';
import * as types from 'socket/types';
import * as viewFilterActions from 'gridUI/actions/viewFilter';
import { PATH_TAG_ID } from 'const';

const { dispatch } = store;

const viewFilterHandler = ({ body }) => {
    const { subAction, data, filterId } = body;
    switch (subAction) {
        case types.UPDATE:
            return _updateViewFilter({ filterId: data.id, newFilter: data });
        case types.DELETE:
            return _deleteViewFilter({ filterId });
        case types.CREATE:
            return _createViewFilter({ data });
        default:
            return;
    }
};

function _createViewFilter({ data }) {
    dispatch(viewFilterActions._createViewFilterAction({ filter: data }));
    if (data?.columnId === PATH_TAG_ID && data?.values?.length !== 0) {
        _updateViewFilter({ filterId: data?.filterId, newFilter: data });
    }
}

function _deleteViewFilter({ filterId }) {
    dispatch(viewFilterActions.deleteViewFilterSocket({ filterId }));
}

function _updateViewFilter({ filterId, newFilter }) {
    dispatch(viewFilterActions.updateViewFilterSocket({ filterId, newFilter }));
}

export default viewFilterHandler;
