import React from 'react';
import { useSelector } from 'react-redux';
import { SUPPORTED_LANGUAGES } from 'const/languageData';
import CountryFlag from 'components/svg-icon/CountryFlag';
import orderBy from 'lodash/orderBy';
import { useDefaultThresHoldValueByProperty } from 'hooks/payment/usage';
import { USAGE_PROPERTIES } from 'const';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { Typography, Grid } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useHistory } from 'react-router-dom';
import AvatarBase from 'components/avatar/Base';
import { isAdmin, isOwner } from 'utils/permission/role';
import { useCurrentUserFullInfo } from 'hooks/auth';
import packageJson from '../../../package.json';
import TwoDigitsLang from 'common/TwoDigitsLang';
import { ENV } from 'config';
import { PRODUCTION } from 'config/env';

export function useAppVersion() {
    return useSelector(state => state.app.version);
}

export function useShowAppVersion() {
    const appVersion = useAppVersion();
    console.log('appVersion', appVersion);
    console.log('packageJson?.version', packageJson?.version);
    if (ENV !== PRODUCTION) return false;
    return (appVersion && appVersion !== packageJson?.version) || window.location.search.includes('testVersion');
}

export function useContextMenuId() {
    return useSelector(state => state.app.contextMenuId);
}

export function useRightSidebarType() {
    return useSelector(state => state.app.rightSidebar?.type);
}

export function useRightSidebarBody() {
    return useSelector(state => state.app.rightSidebar?.body);
}

export function useCompanyInfo() {
    return useSelector(state => state.app.companyInfo);
}

export function useCompanyLocales() {
    return useCompanyInfo()?.locales;
}

export function useFeedback() {
    return useSelector(state => state.app.feedback);
}

export function useJiraCreateTypeOptions() {
    const feedback = useFeedback();
    return feedback.createJiraTypes?.map(item => ({
        ...item,
        label: item.name,
        value: item.id,
        icon: () => <AvatarBase size={24} src={item?.iconUrl} />
    }));
}

export function useSelectedFeedbackOption() {
    const feedback = useFeedback();
    return feedback.selectedFeedbackOption;
}

export function useIsOpenFeedback() {
    const feedback = useFeedback();
    return feedback.isOpen;
}

export function useIsShowTemplates() {
    return useSelector(state => state.app.isShowTemplates);
}

export function useLanguageOptions() {
    return SUPPORTED_LANGUAGES.map(lang => ({
        value: lang.id,
        label: lang.name,
        icon: () => <CountryFlag languageCountryCode={lang.id} />
    }));
}

export function useLanguageOptionsWithUsageValidate() {
    const validLocales = useCompanyLocales();
    const isInfinityLocale = useIsLocalesLimitInfinity();
    const history = useHistory();

    const handleUpgrade = () => {
        history.push('company-settings/billing/locales');
    };

    return orderBy(
        SUPPORTED_LANGUAGES.map(lang => {
            const isDisabled = isInfinityLocale ? false : !validLocales?.includes(lang.id);
            const is2Digits = lang?.id?.length === 2;
            return {
                value: lang.id,
                label: lang.id,
                tooltip: lang.name,
                icon: () =>
                    is2Digits ? <TwoDigitsLang lang={lang.id} /> : <CountryFlag languageCountryCode={lang.id} />,
                isDisabled,
                disabledTitle: isDisabled ? (
                    <Grid container direction="column" style={{ padding: 20, maxWidth: 300 }}>
                        <Grid item>
                            <Typography variant="body2">
                                To select this language, please go to the company Locales setup
                            </Typography>
                        </Grid>
                        <Grid item container justify="flex-end" alignItems="center" style={{ paddingTop: 24 }}>
                            <Grid item style={{ width: '100%' }} onClick={handleUpgrade}>
                                <ButtonBase fullWidth variant="contained">
                                    Go to locales setup
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    undefined
                ),
                order: isDisabled ? 2 : 1,
                moreIcon: isDisabled ? () => <UpgradeSVG /> : undefined
            };
        }),
        ['order'],
        ['asc']
    );
}

export function useSelectedLocalesOptions() {
    const companyLocales = useCompanyLocales();
    return companyLocales?.map(langId => ({
        value: langId,
        label: SUPPORTED_LANGUAGES?.find(lang => lang.id === langId)?.name,
        icon: () => <CountryFlag languageCountryCode={langId} />
    }));
}

export function useIsLocalesLimitInfinity() {
    const localesLimit = useDefaultThresHoldValueByProperty(USAGE_PROPERTIES.MAX_LOCALES);
    return localesLimit === Number.MAX_SAFE_INTEGER;
}

export function useShowWelcome() {
    return useSelector(state => state.app.isShowWelcome);
}

export function useShowHelpMenuTooltip() {
    return useSelector(state => state.app.isShowHelpMenuTooltip);
}

export function useIsShowChromeNotify() {
    return useSelector(state => state.app.isShowChromeNotify);
}

export function useIsShowMaintainMode() {
    return useSelector(state => state.app.isShowMaintainMode);
}

export function useMaintainType() {
    return useSelector(state => state.app.maintainType);
}

export function useCurrentTutorial() {
    const userInfo = useCurrentUserFullInfo();
    const isCurrentOwner = isOwner({ currentUser: userInfo });
    const isCurrentAdmin = isAdmin({ currentUser: userInfo });
    const currentTutorial = useSelector(state => state.app.currentTutorial);
    if (isCurrentOwner || isCurrentAdmin) return currentTutorial;
    return '';
}

export function useIsShowFreeTrial() {
    return useSelector(state => state.app.isShowFreeTrial);
}

export function useIsRetrialPeriod() {
    const retrialDate = useCompanyInfo()?.retrialDate;
    const userInfo = useCurrentUserFullInfo();
    const isCurrentOwner = isOwner({ currentUser: userInfo });

    try {
        const today = new Date().getTime();
        const retrialTime = new Date(retrialDate).getTime();
        const days = (today - retrialTime) / (1000 * 60 * 60 * 24);

        return days > 0 && days < 30 && isCurrentOwner;
    } catch (error) {
        return false;
    }
}

export function useIsShowToggleKeyboardShortCut() {
    return useSelector(state => state.app.isShowKeyBoardShotCut);
}

export function useNotificationType() {
    return useSelector(state => state.app.notification.type);
}

export function useNotificationData() {
    return useSelector(state => state.app.notification.data);
}

export function useIsShareViewLink() {
    return useSelector(state => state?.app.isShareViewLink);
}

export function useAppApiStatus() {
    return useSelector(state => state?.app.apiStatus);
}

export function useAppPromoteFeatures() {
    return useSelector(state => state?.app.promoteFeatures);
}

export function useSelfHostingLicense() {
    return useSelector(state => state?.app.selfHostingLicense);
}
