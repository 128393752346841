export function isAdmin({ currentUser }) {
    const roles = currentUser?.roles || [];
    let isAdmin = false;
    for (let role of roles) {
        if (role?.name?.toLowerCase() === 'admin' && role?.isSystemRole) {
            isAdmin = true;
            break;
        }
    }
    return isAdmin;
}

export function isOwner({ currentUser }) {
    const roles = currentUser?.roles || [];
    let isOwner = false;
    for (let role of roles) {
        if (role?.name?.toLowerCase() === 'owner' && role?.isSystemRole) {
            isOwner = true;
            break;
        }
    }
    return isOwner;
}

export function isUser({ currentUser }) {
    const roles = currentUser?.roles || [];
    let isUser = false;
    for (let role of roles) {
        if (role?.name?.toLowerCase() === 'user' && role?.isSystemRole) {
            isUser = true;
            break;
        }
    }
    return isUser;
}

export function getUserRoleDetail({ roleList }) {
    let userRole = null;
    for (let role of roleList) {
        if (role?.name?.toLowerCase() === 'user' && role?.isSystemRole) {
            userRole = role;
        }
    }
    return userRole;
}

export function getAdminRoleDetail({ roleList }) {
    let adminRole = null;
    for (let role of roleList) {
        if (role?.name?.toLowerCase() === 'administrator' && role?.isSystemRole) {
            adminRole = role;
        }
    }
    return adminRole;
}
