import React from 'react';

function ChangedFromToSVG({ color = '#C9C9CE', ...other }) {
    return (
        <svg {...other} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 4.99603C9.99557 4.88603 9.94906 4.7589 9.87157 4.67871L7.3426 2.06321C7.17123 1.91332 6.84262 1.85144 6.65108 2.02475C6.46242 2.19544 6.46803 2.52649 6.65598 2.69786L8.43417 4.53448H0.474183C0.212308 4.53448 0 4.74112 0 4.99603C0 5.25094 0.212308 5.45759 0.474183 5.45759H8.43417L6.65598 7.29422C6.49417 7.45199 6.46427 7.79468 6.65108 7.96733C6.83784 8.13996 7.17826 8.08481 7.3426 7.92886L9.87157 5.31336C9.95811 5.22385 10.0003 5.11883 10 4.99603Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ChangedFromToSVG);
