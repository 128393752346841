import React from 'react';

function FolderUploadSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.60633 1C2.04778 1 1.50942 1.22207 1.11911 1.61911C0.722073 2.00942 0.5 2.54778 0.5 3.10633V12.5478C0.5 13.1063 0.722073 13.6447 1.11911 14.0283C1.50942 14.4253 2.04778 14.6474 2.60633 14.6474H13.3937C13.9522 14.6474 14.4838 14.4253 14.8809 14.0283C15.2779 13.638 15.5 13.0996 15.5 12.541V5.12517C15.5 4.56662 15.2779 4.03499 14.8809 3.63795C14.4906 3.24092 13.9522 3.01884 13.3937 3.01884H7.73419L6.61036 1.33647C6.46904 1.12786 6.23351 1 5.97779 1H2.60633ZM2.1891 2.6891C2.29677 2.5747 2.45155 2.51413 2.60633 2.51413V2.52086H5.5673L6.69112 4.20323C6.83244 4.41184 7.06797 4.5397 7.32369 4.5397H13.3937C13.5485 4.5397 13.7032 4.607 13.8109 4.71467C13.9253 4.82234 13.9859 4.97712 13.9859 5.1319V12.5478C13.9859 12.7026 13.9186 12.8573 13.8109 12.965C13.7032 13.0794 13.5485 13.14 13.3937 13.14H2.60633C2.45155 13.14 2.29677 13.0727 2.1891 12.965C2.0747 12.8573 2.01413 12.7026 2.01413 12.5478V3.10633C2.01413 2.95155 2.08143 2.79677 2.1891 2.6891ZM7.72607 6.58071C7.72944 6.57903 7.7328 6.57734 7.73617 6.57398C7.82365 6.52687 7.91786 6.49996 8.02553 6.49996C8.10629 6.49996 8.18704 6.51341 8.25434 6.54706C8.3149 6.58071 8.37547 6.62109 8.42257 6.66819L10.0376 8.28326C10.1453 8.3842 10.2059 8.53225 10.2059 8.6803C10.2059 8.83508 10.1453 8.9764 10.0376 9.08407C9.81557 9.30614 9.45891 9.30614 9.23684 9.08407L8.58408 8.43131V11.2853C8.58408 11.5948 8.32836 11.8506 8.0188 11.8506C7.70925 11.8506 7.45353 11.5948 7.45353 11.2853V8.43804L6.80077 9.0908C6.69983 9.19847 6.55178 9.25903 6.40373 9.25903C6.24895 9.25903 6.10763 9.19847 5.99996 9.0908C5.78462 8.87545 5.78462 8.50533 5.99996 8.28999L7.61504 6.67492C7.63522 6.648 7.66887 6.62109 7.69579 6.6009C7.70252 6.59417 7.70925 6.58744 7.71598 6.58744C7.71934 6.58407 7.72271 6.58239 7.72607 6.58071Z"
                fill="#78778B"
            />
        </svg>
    );
}

export default React.memo(FolderUploadSVG);
