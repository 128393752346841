/* eslint-disable eqeqeq */
import React from 'react';
import { ScrollSync, Grid } from 'react-virtualized-dn';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CellRow from 'gridUI/table/grid/Cell';
import { getColumnData } from 'utils/gridUI/column';
import ColumnIdCell from 'gridUI/table/grid/ColumnIdCell';
import { SmoothScroll } from './scroll';
import ColumnIdHeader from 'gridUI/table/grid/ColumnIdHeader';
import hexToRgba from 'hex-to-rgba';
import { AGG_HEIGHT as AGG_HEIGHT_CONST, LIMIT_ROWS, DEFAULT_COLUMN_WIDTH, ROW_HEIGHT } from 'const/gridUI';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { getCellData } from 'utils/gridUI/cell';
import { SCROLLBAR_RELEASE_TIME } from 'const';
import classNames from 'classnames';
import { isChrome, isMac, isWindows } from 'utils/os';

const useStyles = makeStyles(theme => ({
    leftCell: {
        outline: 'none',
        overflow: 'hidden !important'
    },
    rightCell: {
        outline: 'none',
        overflow: 'hidden !important'
    },
    rightCellGridUI: {
        scrollBehavior: 'smooth',
        outline: 'none'
    },
    HeaderGridUI: {
        width: '100%',
        overflow: 'hidden !important',
        outline: 'none'
    },
    LeftSideGridUI: {
        overflow: 'hidden !important',
        outline: 'none'
    },
    GridUIColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    BodyGridUI: {
        width: '100%',
        outline: 'none'
    },
    cell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        boxSizing: 'border-box',
        background: 'white',
        overflow: 'hidden',
        '&.ui-selected': {
            background: theme.colors.selectionColor
        },
        '&.ui-selecting': {
            background: theme.colors.selectionColor
        }
    },
    indexCell: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: props => `${props.borderNumber || 1}px solid ${theme.colors.border}`,
        borderRight: `1px solid ${theme.colors.border}`,
        background: theme.colors.white,
        overflow: 'hidden'
    },
    cellSelected: {
        border: `1px solid ${theme.colors.highlight} !important`
    },
    cellBackgroundSelected: {
        background: theme.colors.selectionColor
    },
    header: {
        fontWeight: 'bold',
        background: props => (props.nonHeader ? theme.colors.white : `${theme.colors.paleGrey} !important`)
    },
    headerSelected: {
        background: `${theme.colors.lightGrey} !important`,
        transition: 'background 0.1s ease-in-out'
    },
    footer: {
        background: `${theme.colors.white} !important`
    },
    headerFilter: {
        background: theme.colors.white
    },
    uiSelected: {
        background: theme.colors.selectionColor
    },
    rowSelected: {
        background: theme.colors.paleGrey
    },
    bgHighlight: {
        background: `${hexToRgba(theme.colors.atlantis, 0.1)} !important`
    },
    scrollOverLay: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 100
    },
    simpleBar: {
        '& .simplebar-track': {
            pointerEvents: 'auto'
        },
        '& .simplebar-scrollbar': {
            '&:hover': {
                cursor: 'pointer',
                '&::before': {
                    background: theme.colors.lightGreyBlue,
                    opacity: 1
                }
            },
            '&::before': {
                background: theme.colors.lightGrey,
                opacity: 1
            }
        }
    }
}));

const PREFIX_GRID_UI = `GRID_UI_VIEW_ONLY_`;

function GridUIExample({
    width,
    maxHeight,
    columns,
    totalRecords,
    rows,
    metaData,
    data,
    rowHeight = ROW_HEIGHT,
    fixedColumnCount = 0,
    fixedRowCount = 1,
    overscanColumnCount = 0,
    overscanRowCount = 0,
    dbId,
    defaultAccessViewId,
    gridId,
    isSelectionActive,
    columnsSelected,
    ROW_START_INDEX,
    ROW_STOP_INDEX,
    // isOpenCellEdit,
    isFetchingMore,
    isFirstRowHeader,
    COLUMN_ID_WIDTH = 0,
    headerRenderer,
    nonHeader,
    isUseColumnIdByIndex,
    isEmptyIndexHeaderCell,
    tree,
    isHighlightIndexHeader,
    dependencyStatusData,
    AGG_HEIGHT = AGG_HEIGHT_CONST,
    quickFilters,
    t
}) {
    const isRowOverLimit = totalRecords > LIMIT_ROWS;
    const classes = useStyles({ borderNumber: isRowOverLimit ? 2 : 1, nonHeader });
    const columnCount = React.useMemo(() => columns.length, [columns.length]);
    const heightLeftGridUI = React.useMemo(() => rowHeight * fixedRowCount, [rowHeight, fixedRowCount]);
    const height = React.useMemo(() => maxHeight - AGG_HEIGHT, [maxHeight, AGG_HEIGHT]);
    const topRef = React.useRef(0);
    const leftRef = React.useRef(0);
    const gridRef = React.useRef();
    const theme = useTheme();
    const onScrollRef = React.useRef();

    const getColumn = React.useCallback(
        columnIndex => {
            return getColumnData({ columnIndex, columns, metaData });
        },
        [columns, metaData]
    );

    const getCellDataByIndex = React.useCallback(
        ({ columnIndex, rowIndex }) => {
            const columnId = columns?.[columnIndex];
            const rowId = rows?.[rowIndex];
            return getCellData({ data, rowId, columnId });
        },
        [rows, data, columns]
    );

    const totalLeftOfColumns = React.useCallback(() => {
        return columns?.length * DEFAULT_COLUMN_WIDTH;
    }, [columns]);

    const getAllCellByRowId = React.useCallback(
        ({ rowIndex }) => {
            if (rowIndex < ROW_START_INDEX) return null;
            const rowId = rows?.[rowIndex];
            if (!rowId) return null;
            const rowData = data?.[rowId];
            return rowData;
        },
        [rows, data, ROW_START_INDEX]
    );

    const getDependencyStatus = React.useCallback(
        ({ columnId, rowId }) => {
            return dependencyStatusData?.[rowId]?.[columnId];
        },
        [dependencyStatusData]
    );

    const getTotalFreezingWidth = React.useCallback(() => {
        let arrs = Array.from(Array(fixedColumnCount).keys());
        let total = arrs.reduce((total, num) => (total += DEFAULT_COLUMN_WIDTH), 0);
        return total;
    }, [fixedColumnCount]);

    const getTotalWidthWithIndex = React.useMemo(() => {
        let arrs = Array.from(Array(fixedColumnCount).keys());
        let total = arrs.reduce((total, num) => (total += DEFAULT_COLUMN_WIDTH), COLUMN_ID_WIDTH);
        return total;
    }, [fixedColumnCount, COLUMN_ID_WIDTH]);

    React.useEffect(() => {
        if (scrollBarRef.current) {
            scrollBarRef.current.recalculate();
        }
    }, [columnCount, totalRecords, rows]);

    React.useEffect(() => {
        const MainGridUI = document.getElementById(`${PREFIX_GRID_UI}RightCell`);
        const HeaderGridUI = document.getElementById(`${PREFIX_GRID_UI}RightCellHeader`);
        const IndexGridUI = document.getElementById(`${PREFIX_GRID_UI}IndexCell`);
        const LeftGridUI = document.getElementById(`${PREFIX_GRID_UI}LeftCell`);
        const scrollbarOverlay = scrollBarRef.current.getScrollElement();

        SmoothScroll({
            target: scrollbarOverlay,
            speed: 40,
            smooth: 8,
            moveTopTargets: [LeftGridUI, MainGridUI],
            moveLeftTargets: [HeaderGridUI, MainGridUI]
        });

        SmoothScroll({
            target: MainGridUI,
            speed: 40,
            smooth: 8,
            moveTopTargets: [IndexGridUI, LeftGridUI, scrollbarOverlay],
            moveLeftTargets: [HeaderGridUI, scrollbarOverlay]
        });
    }, [width, height]);

    const _renderHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style, position }) => {
            const column = getColumn(columnIndex);
            // if (!column) return null;
            const headerClassname = rowIndex < 1 ? classes.header : `${classes.headerFilter}`;
            return (
                <div className={`${classes.cell} ${headerClassname}`} key={key} style={style}>
                    {rowIndex === 0 &&
                        headerRenderer({
                            columnIndex,
                            position,
                            key,
                            column
                        })}
                </div>
            );
        },
        [getColumn, classes, headerRenderer]
    );

    const _renderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style, position, parent }) => {
            const column = getColumn(columnIndex);
            const cellData = getCellDataByIndex({ columnIndex, rowIndex });
            const rowDataByRowId = getAllCellByRowId({ rowIndex });

            const columnId = columns?.[columnIndex];
            const filterValue = quickFilters?.[columnId]?.value;

            const rowId = rows?.[rowIndex];
            const dependencyStatus = getDependencyStatus({ rowId, columnId });

            const { state } = parent;
            const { isScrolling } = state;

            const isUseSkeleton = (rowIndex < ROW_START_INDEX || rowIndex > ROW_STOP_INDEX) && isFetchingMore;

            let cellValue = cellData?.value;

            return (
                <div
                    key={key}
                    className={`${classes.cell} 
                    cell
                    cell_${rowIndex}
                    cell_${rowIndex}_${columnIndex} 
                    ${isFirstRowHeader && rowIndex === 0 ? classes.rowSelected : ''}
                    `}
                    style={style}
                >
                    <CellRow
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        position={position}
                        columnId={columnId}
                        rowId={rowId}
                        column={column}
                        rawCellData={cellData}
                        value={cellValue}
                        isScrolling={isScrolling}
                        rowHeight={rowHeight}
                        scrollingIfPossible={() => {}}
                        isRowOverLimit={isRowOverLimit}
                        isUseSkeleton={isUseSkeleton}
                        isViewOnly={true}
                        editable={true}
                        gridId={gridId}
                        defaultAccessViewId={defaultAccessViewId}
                        dbId={dbId}
                        rowDataByRowId={rowDataByRowId}
                        dependencyStatus={dependencyStatus}
                        isForcedShowDependencyStatus={true}
                        filterValue={filterValue}
                        t={t}
                    />
                </div>
            );
        },
        [
            t,
            getAllCellByRowId,
            gridId,
            defaultAccessViewId,
            dbId,
            getCellDataByIndex,
            getColumn,
            classes,
            rowHeight,
            columns,
            rows,
            ROW_START_INDEX,
            isRowOverLimit,
            ROW_STOP_INDEX,
            isFetchingMore,
            isFirstRowHeader,
            getDependencyStatus,
            quickFilters
        ]
    );

    const _renderIndexHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            return (
                <div
                    key={key}
                    style={style}
                    className={`${classes.cell} ${rowIndex === 0 ? classes.header : ''} ${
                        isHighlightIndexHeader ? classes.bgHighlight : ``
                    }`}
                >
                    {rowIndex === 0 && <ColumnIdHeader isEmptyIndexHeaderCell={isEmptyIndexHeaderCell} />}
                </div>
            );
        },
        [classes, isEmptyIndexHeaderCell, isHighlightIndexHeader]
    );

    const _renderIndexCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const rowId = rows?.[rowIndex];

            return (
                <div
                    key={key}
                    style={style}
                    className={`${classes.indexCell} 
                    ${isFirstRowHeader && rowIndex === 0 ? classes.rowSelected : ''}
                    `}
                >
                    <ColumnIdCell isUseColumnIdByIndex={isUseColumnIdByIndex} rowId={rowId} rowIndex={rowIndex} />
                </div>
            );
        },
        [classes, rows, isFirstRowHeader, isUseColumnIdByIndex]
    );

    const _renderLeftHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const position = {
                left: columnIndex * style.width + COLUMN_ID_WIDTH - leftRef.current,
                top: fixedRowCount * rowHeight
            };
            if (columnIndex > fixedColumnCount || rowIndex > fixedRowCount) return;
            return _renderHeaderCell({
                columnIndex,
                key,
                rowIndex,
                position,
                style: {
                    ...style
                }
            });
        },
        [_renderHeaderCell, fixedColumnCount, fixedRowCount, rowHeight, COLUMN_ID_WIDTH]
    );

    const _renderRightHeaderCell = React.useCallback(
        ({ columnIndex, rowIndex, key, style }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;
            const position = {
                left: getTotalFreezingWidth() + columnIndex * style.width + COLUMN_ID_WIDTH - leftRef.current,
                top: fixedRowCount * rowHeight
            };
            return _renderHeaderCell({ columnIndex: newColumnIndex, rowIndex, key, style, position });
        },
        [_renderHeaderCell, fixedColumnCount, rowHeight, fixedRowCount, getTotalFreezingWidth, COLUMN_ID_WIDTH]
    );

    const _renderLeftCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const position = {
                left: columnIndex * style.width + COLUMN_ID_WIDTH,
                top: rowIndex * rowHeight + fixedRowCount * rowHeight - topRef.current
            };
            return _renderCell({
                columnIndex,
                key,
                rowIndex,
                position,
                style: {
                    ...style
                },
                isHeader: true,
                parent
            });
        },
        [_renderCell, rowHeight, fixedRowCount, COLUMN_ID_WIDTH]
    );

    const _renderRightCell = React.useCallback(
        ({ columnIndex, key, rowIndex, style, parent }) => {
            const newColumnIndex = columnIndex + fixedColumnCount;

            const position = {
                left: getTotalFreezingWidth() + columnIndex * style.width + COLUMN_ID_WIDTH - leftRef.current,
                top: rowIndex * rowHeight + fixedRowCount * rowHeight - topRef.current
            };
            return _renderCell({ columnIndex: newColumnIndex, key, rowIndex, style, position, parent });
        },
        [_renderCell, fixedColumnCount, fixedRowCount, rowHeight, getTotalFreezingWidth, COLUMN_ID_WIDTH]
    );

    const scrollBarRef = React.useRef();
    const scrollableNodeRef = React.useRef();

    const timerSimpleBarScroll = React.useRef();

    const handleOverlayScroll = React.useCallback(e => {
        const gridState = gridRef.current?.state;
        const scrollRef = scrollableNodeRef?.current;

        const isScrollHorizontalOnly =
            gridState?.scrollTop === scrollRef?.scrollTop && gridState?.scrollLeft !== scrollRef?.scrollLeft;

        if (timerSimpleBarScroll.current) clearTimeout(timerSimpleBarScroll.current);

        timerSimpleBarScroll.current = setTimeout(
            () => {
                const target = scrollableNodeRef?.current;
                onScrollRef.current({ scrollTop: target?.scrollTop, scrollLeft: target?.scrollLeft });
            },
            isScrollHorizontalOnly ? 0 : SCROLLBAR_RELEASE_TIME
        );
    }, []);

    return (
        <ScrollSync>
            {({ clientHeight, clientWidth, onScroll, scrollHeight, scrollLeft, scrollTop, scrollWidth }) => {
                return (
                    <div
                        style={{
                            width,
                            height: height,
                            border: `1px solid ${theme.colors.border}`,
                            boxSizing: 'content-box',
                            background: theme.colors.ghostwhite
                        }}
                        className={classes.gridRow}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: heightLeftGridUI,
                                width
                            }}
                            id={'gridTable'}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: COLUMN_ID_WIDTH
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_UI}IndexHeader`}
                                    cellRenderer={_renderIndexHeaderCell}
                                    className={classes.HeaderGridUI}
                                    width={COLUMN_ID_WIDTH}
                                    height={heightLeftGridUI}
                                    rowHeight={rowHeight}
                                    columnWidth={COLUMN_ID_WIDTH}
                                    rowCount={fixedRowCount}
                                    columnCount={1}
                                />
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    left: COLUMN_ID_WIDTH,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_UI}LeftCellHeader`}
                                    cellRenderer={_renderLeftHeaderCell}
                                    className={classes.HeaderGridUI}
                                    width={getTotalFreezingWidth()}
                                    height={heightLeftGridUI}
                                    rowHeight={rowHeight}
                                    columnWidth={DEFAULT_COLUMN_WIDTH}
                                    rowCount={fixedRowCount}
                                    columnCount={fixedColumnCount}
                                />
                            </div>
                            <div
                                className={classes.scrollOverLay}
                                style={{
                                    left: getTotalWidthWithIndex,
                                    width: width - getTotalWidthWithIndex,
                                    height: height - ROW_HEIGHT,
                                    top: fixedRowCount * ROW_HEIGHT
                                }}
                            >
                                <SimpleBar
                                    autoHide={false}
                                    className={classNames(`simplebar`, {
                                        mac: isMac(),
                                        'window-chrome': isWindows() && isChrome()
                                    })}
                                    style={{
                                        maxWidth: width - getTotalFreezingWidth(),
                                        maxHeight: height - heightLeftGridUI
                                    }}
                                    ref={scrollBarRef}
                                    scrollableNodeProps={{ ref: scrollableNodeRef, onScroll: handleOverlayScroll }}
                                >
                                    <div
                                        style={{
                                            width: totalLeftOfColumns() - getTotalFreezingWidth(),
                                            height: totalRecords * rowHeight,
                                            minHeight: height - ROW_HEIGHT
                                        }}
                                    ></div>
                                </SimpleBar>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalFreezingWidth() + COLUMN_ID_WIDTH,
                                    top: 0,
                                    zIndex: 3,
                                    height: heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_UI}RightCellHeader`}
                                    className={classes.HeaderGridUI}
                                    scrollLeft={scrollLeft}
                                    cellRenderer={_renderRightHeaderCell}
                                    width={width - getTotalWidthWithIndex}
                                    overscanColumnCount={overscanColumnCount}
                                    height={heightLeftGridUI}
                                    rowHeight={rowHeight}
                                    columnWidth={DEFAULT_COLUMN_WIDTH}
                                    rowCount={fixedRowCount}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: height - heightLeftGridUI,
                                width
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: COLUMN_ID_WIDTH
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_UI}IndexCell`}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    scrollTop={scrollTop}
                                    cellRenderer={_renderIndexCell}
                                    className={classes.leftCell}
                                    width={COLUMN_ID_WIDTH}
                                    height={height - heightLeftGridUI}
                                    rowHeight={rowHeight}
                                    columnWidth={COLUMN_ID_WIDTH}
                                    rowCount={totalRecords}
                                    columnCount={1}
                                />
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    left: COLUMN_ID_WIDTH,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: getTotalFreezingWidth()
                                }}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_UI}LeftCell`}
                                    scrollTop={scrollTop}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderLeftCell}
                                    className={classes.leftCell}
                                    width={getTotalFreezingWidth()}
                                    height={height - heightLeftGridUI}
                                    rowHeight={rowHeight}
                                    columnWidth={DEFAULT_COLUMN_WIDTH}
                                    rowCount={totalRecords}
                                    columnCount={fixedColumnCount}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: getTotalWidthWithIndex,
                                    top: 0,
                                    zIndex: 2,
                                    height: height - heightLeftGridUI,
                                    width: width - getTotalWidthWithIndex
                                }}
                                className={classes.rightCellGridUI}
                            >
                                <Grid
                                    id={`${PREFIX_GRID_UI}RightCell`}
                                    ref={gridRef}
                                    scrollTop={scrollTop}
                                    scrollLeft={scrollLeft}
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    cellRenderer={_renderRightCell}
                                    className={classes.rightCell}
                                    width={width - getTotalWidthWithIndex}
                                    height={height - heightLeftGridUI}
                                    rowHeight={rowHeight}
                                    columnWidth={DEFAULT_COLUMN_WIDTH}
                                    rowCount={totalRecords}
                                    columnCount={Math.max(0, columnCount - fixedColumnCount)}
                                    onScroll={props => {
                                        onScroll(props);
                                        topRef.current = props.scrollTop;
                                        leftRef.current = props.scrollLeft;
                                        onScrollRef.current = onScroll;
                                        scrollableNodeRef.current.scrollTop = props?.scrollTop;
                                        scrollableNodeRef.current.scrollLeft = props?.scrollLeft;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            }}
        </ScrollSync>
    );
}

export default React.memo(GridUIExample);
