import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import GridlyLoading from '!file-loader!assets/images/svg/GridlyLoading.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    spinnerWrapper: {
        position: 'fixed',
        left: '50%',
        top: '45%',
        transform: 'translate(-50%, -50%)'
    },
    loadingTitle: {
        marginBottom: theme.spacing(3)
    },
    loadingText: {
        color: '#93929E'
    },
    loadingIcon: {
        width: 300,
        height: 300
    }
}));

function LoadingPage({ spinnerWrapperClassName }) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classnames(classes.spinnerWrapper, spinnerWrapperClassName)}
        >
            <Grid item container justify="center">
                <object
                    className={classes.loadingIcon}
                    aria-label="loading-svg"
                    type="image/svg+xml"
                    data={GridlyLoading}
                />
            </Grid>
            <Grid item className={classes.loadingTitle}>
                <Typography align="center" variant="h3">
                    {t(`auth_loading_page_title`)}
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center" variant="body2" className={classes.loadingText}>
                    {t(`auth_loading_page_subtitle`)}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default LoadingPage;
