import React from 'react';

function WelcomeGirlSVG({ ...other }) {
    return (
        <svg {...other} width="137" height="167" viewBox="0 0 137 167" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="68.5" cy="161.5" rx="68.5" ry="5.5" fill="#DBDCE1" />
            <g>
                <path
                    d="M91.9727 50.8606C90.9258 49.9882 87.3489 58.625 89.4427 60.1081C91.8855 60.0209 93.0196 51.733 91.9727 50.8606Z"
                    fill="#FF7C00"
                />
                <path
                    d="M72.9535 35.333L92.2338 51.0364C96.5086 44.4933 102.267 38.5609 109.246 33.5882C110.467 32.2796 112.823 30.1858 113.172 30.5348C113.521 30.7965 112.561 31.7561 112.91 32.0178C113.259 32.3668 116.836 29.8368 116.836 30.7965C116.836 31.6689 114.48 32.4541 114.568 32.8903C114.655 33.3265 118.319 30.0985 117.883 31.4072C117.708 32.1051 115.527 33.6754 115.44 33.8499C115.353 34.1116 118.057 31.7561 118.232 32.3668C118.406 33.0647 116.312 34.4606 116.312 34.8096C116.312 35.1585 117.97 33.5009 118.493 33.8499C118.93 34.1989 114.916 38.5609 113.608 40.4802C108.112 48.5936 102.179 54.526 95.0255 59.6732C93.5424 60.7201 91.5359 60.9818 89.8783 60.1967C81.8521 56.5326 74.6983 51.1236 69.6384 44.2316C68.5915 42.8357 67.8063 41.1782 67.6318 39.4333C67.3701 37.6885 67.7191 36.4671 68.417 35.5947C69.3766 34.2861 71.5577 34.1989 72.9535 35.333Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M85.1685 65.7788C83.162 61.9402 89.2688 56.793 92.1478 55.2227C92.8457 57.8399 88.0474 65.8661 85.1685 65.7788Z"
                    fill="#FF8994"
                />
                <path
                    d="M62.5728 59.062C61.7004 62.2027 46.6077 57.3172 47.5673 51.123C47.5673 51.123 63.3579 55.9213 62.5728 59.062Z"
                    fill="#FF8994"
                />
                <path
                    d="M10.751 135.05C8.83172 142.378 25.7565 148.921 27.763 146.653C27.6758 146.653 11.7107 131.473 10.751 135.05Z"
                    fill="#002DB3"
                />
                <path
                    d="M81.1543 148.745C91.536 142.464 96.9449 145.43 103.139 146.739C103.924 148.745 86.476 154.329 81.1543 148.745Z"
                    fill="#002DB3"
                />
                <path
                    d="M68.156 34.9828L76.2694 25.4735C76.8801 24.6884 78.1015 25.0373 78.2759 25.997C78.3632 26.4332 78.0142 26.8694 77.6653 27.3056L75.9204 29.3121L75.5715 29.0504L72.867 35.07L70.337 49.0286L66.3239 53.5651L57.1636 66.6513L53.674 93.9577C52.0164 100.937 44.6881 109.05 41.1985 116.117C40.1516 118.211 39.0175 120.304 37.7089 122.311L21.6565 148.658L23.0524 159.825C17.8179 156.597 9.61723 154.677 7 149.966L10.1407 143.249C11.9727 133.74 20.2606 118.211 25.7568 110.882C27.8506 108.091 29.5082 105.125 30.7296 101.897L39.8026 78.6033C40.9368 73.4561 43.903 68.4833 48.0033 63.5978C48.8757 62.551 49.6609 61.4168 50.3588 60.1954C54.1102 53.3906 60.0426 45.3645 68.156 34.9828Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M57.2511 65.9538C64.0559 75.8121 75.7462 88.8982 87.262 101.81C87.7854 102.421 88.3089 103.031 88.8323 103.729C91.1879 107.044 92.5837 110.796 93.1944 115.071C93.3689 116.379 93.7178 117.6 94.0668 118.822L103.314 151.014L112.998 159.912H93.2816C85.3427 145.779 79.2358 130.948 77.9272 113.936C63.0962 102.333 45.5608 89.7706 42.6818 82.6169C42.1584 81.6572 41.8094 80.4358 41.6349 79.1272C41.1115 75.5503 42.1584 71.9735 44.3394 69.0945L48.9632 62.9004L57.2511 65.9538Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M112.91 159.825L106.192 153.631H90.0527C91.0996 155.725 92.2338 157.731 93.3679 159.825H112.91Z"
                    fill="#EB4F5E"
                />
                <path
                    d="M48.7005 62.9004C48.526 63.1621 48.2643 63.4238 48.0898 63.6856C43.9023 68.5711 41.0233 73.5438 39.8892 78.691L30.7289 102.072C29.5075 105.212 27.7627 108.266 25.7561 110.97C21.2196 117.077 13.5424 126.325 10.5762 134.874L13.8913 136.096C16.4213 137.055 18.6024 138.713 19.9982 140.981C22.9644 145.692 26.0178 145.43 27.5009 146.477L37.7081 122.486C38.9295 120.479 40.1509 118.386 41.1978 116.292C44.6002 109.225 52.0157 101.025 53.6733 94.1327L57.1629 66.8262L57.5119 66.3028C54.0222 65.9538 51.056 64.9069 48.7005 62.9004Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M9.79171 144.035L7 149.968C9.61723 154.679 17.8179 156.598 23.1396 160L21.9182 151.8L9.79171 144.035Z"
                    fill="#EB4F5E"
                />
                <path
                    d="M75.9214 29.3998C75.9214 29.3998 75.3107 28.8763 75.3107 28.7891C75.3107 28.7018 75.5724 27.7422 75.4852 27.7422C75.4852 27.7422 74.9618 28.5274 74.8745 28.8763C74.7873 29.2253 74.8745 29.836 75.1362 30.0105L75.4852 29.3125L75.9214 29.3998Z"
                    fill="#002DB3"
                />
                <path
                    d="M68.1553 34.9831C62.5719 42.1369 51.056 45.801 47.5664 51.1227C48.875 53.042 60.4781 56.4444 62.5719 59.0616L64.4912 56.2699C65.7125 54.5251 67.0212 52.8675 68.5043 51.3844L70.7725 48.9417L72.8663 35.1576L73.2153 34.3724L70.1618 32.7148L68.1553 34.9831Z"
                    fill="#EB4F5E"
                />
                <path
                    d="M52.191 98.2341C50.9696 97.8852 42.42 82.618 43.2051 82.7925L54.1103 94.3955C53.5868 95.5297 53.0634 96.751 52.191 98.2341Z"
                    fill="#002DB3"
                />
                <path
                    d="M95.3748 145.693C97.2068 146.042 101.045 147.001 103.226 146.652L93.9789 118.823C93.6299 117.601 93.3682 116.38 93.1065 115.071C92.4958 110.796 91.0999 106.958 88.7444 103.73C88.3082 103.032 87.7848 102.421 87.1741 101.811C75.6583 88.8989 64.2297 76.1617 57.4249 66.3034C53.1501 66.0417 50.7946 64.5586 48.6135 62.9883L44.2515 69.0079C42.0705 71.8869 41.0236 75.551 41.547 79.0406C41.7215 80.3492 42.0705 81.5706 42.5939 82.5303C45.4729 89.684 63.0083 102.247 77.8393 113.85C78.7989 127.285 78.0138 136.445 81.0672 148.572C81.3289 148.484 82.9865 147.874 87.3486 146.303C90.053 145.431 92.7575 145.257 95.3748 145.693Z"
                    fill="#A7BCFB"
                />
                <path
                    d="M69.6391 50.2508C67.9816 48.3315 66.1495 43.2715 67.0219 41.9629L70.4243 49.3784L69.6391 50.2508Z"
                    fill="#FF8994"
                />
                <path
                    d="M72.9542 35.3328C71.5583 34.1987 69.3773 34.2859 68.2432 35.5945C67.5452 36.4669 67.1963 37.6883 67.458 39.4331C67.7197 41.178 68.4176 42.8355 69.4645 44.2314C74.4373 51.0362 81.5038 56.3579 89.3555 60.022C88.2214 55.7472 93.6303 56.1834 91.8855 50.7745L72.9542 35.3328Z"
                    fill="#EB4F5E"
                />
                <path
                    d="M80.8066 43.1836C85.3432 47.8946 88.8328 51.2098 92.148 53.478C92.9331 54.0015 91.3628 56.9677 89.0073 55.1356C85.6921 52.5184 83.9473 47.6329 80.8066 43.1836Z"
                    fill="#FF8994"
                />
                <path
                    d="M74.263 35.6811C78.8868 40.3921 84.9937 49.9014 93.5433 56.3572C93.9795 56.7062 94.5902 56.7934 95.1137 56.7062C99.9992 55.6593 109.421 48.8545 118.145 43.0093C121.809 40.1304 128.701 38.909 128.265 39.9559C128.004 40.6538 125.561 40.5666 125.91 41.2645C126.171 41.9624 129.574 39.5197 129.225 40.5666C128.876 41.6135 127.044 42.3986 127.131 42.8348C127.218 43.271 129.399 41.5262 129.312 42.3114C129.225 43.0093 127.567 43.7945 127.567 44.1435C127.567 44.4924 129.138 43.7072 129.05 44.0562C128.963 44.2307 128.353 44.9286 127.655 45.2776C127.044 45.5393 126.782 46.15 126.346 46.6734C116.837 56.4444 107.938 65.692 95.0264 66.2154C93.8923 66.3027 92.8454 66.1282 91.7112 65.9537C82.0275 63.8599 74.0885 56.6189 67.8072 44.4924C66.3241 41.0028 66.3241 38.4728 67.1965 36.6407C68.5924 33.9363 72.2565 33.5873 74.263 35.6811Z"
                    fill="#FFDDCD"
                />
                <path
                    d="M74.2624 35.6823C72.1687 33.5885 68.5045 33.9375 67.1959 36.5547C66.2363 38.474 66.3235 40.9168 67.8066 44.4064C73.0411 54.5264 77.4904 62.4653 85.0803 65.6932C84.9931 63.5994 87.6103 58.2777 92.0596 55.1371C84.2952 48.7685 78.6245 40.1316 74.2624 35.6823Z"
                    fill="#EB4F5E"
                />
                <path
                    d="M79.2348 24.253C78.3624 18.8441 76.7049 15.7034 74.0876 14.7437C72.6918 14.133 71.2087 12.5627 69.7256 10.6434C69.2894 10.12 68.9404 9.59651 68.5914 8.89858C65.887 4.10033 63.0953 1.04689 60.1291 0L51.2305 12.4755C52.1901 15.3544 54.6329 16.9248 58.2098 17.1865C59.1694 17.2737 59.8673 17.7972 60.3035 18.7568L60.478 19.193C60.9142 20.0654 61.2632 21.0251 61.6122 21.9847C63.0953 25.9978 65.538 27.7426 69.1149 27.3064C70.1618 27.2192 71.1214 27.3064 71.9066 27.7426C73.7387 28.615 74.7856 29.8364 77.2283 27.2192C77.49 27.3937 78.7114 26.434 79.0604 25.7361C79.2348 25.2999 79.3221 24.602 79.2348 24.253Z"
                    fill="#F8AD13"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="122.225" height="160" fill="white" transform="translate(7)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(WelcomeGirlSVG);
