import React from 'react';

function SettingSVG({ color = '#7B92A7', width = 16, height = 16, ...other }) {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.009 9.4C15.009 9.1 15.109 8.9 15.109 8.6C15.109 8.3 15.109 8 15.009 7.8L16.809 6.4C17.009 6.2 17.009 6 16.909 5.8L15.209 2.9C15.109 2.7 14.909 2.6 14.709 2.7L12.509 3.5C12.009 3.2 11.509 2.9 11.009 2.7L10.709 0.4C10.709 0.2 10.509 0 10.309 0H6.80902C6.50902 0.1 6.40902 0.2 6.30902 0.5L6.00902 2.7C5.50902 2.9 5.00902 3.2 4.50902 3.5L2.30902 2.7C2.10902 2.6 1.90902 2.7 1.80902 2.9L0.109017 5.8C-0.0909831 6 0.00901709 6.2 0.209017 6.4L2.00902 7.8C2.00902 8 2.00902 8.3 2.00902 8.6C2.00902 8.9 2.00902 9.2 2.10902 9.4L0.309017 10.8C0.00901699 11 0.00901695 11.2 0.109017 11.4L1.80902 14.3C1.90902 14.5 2.10902 14.6 2.30902 14.5L4.50902 13.6C5.00902 14 5.50902 14.3 6.00902 14.5L6.30902 16.8C6.30902 17 6.50902 17.2 6.70902 17.2H10.209C10.409 17.2 10.609 17.1 10.609 16.8L10.909 14.5C11.409 14.3 11.909 14 12.409 13.7L14.609 14.6C14.809 14.7 15.009 14.6 15.109 14.4L16.809 11.5C16.909 11.3 16.909 11.1 16.709 11L15.009 9.4ZM8.50902 11.6C6.80902 11.6 5.50902 10.3 5.50902 8.6C5.50902 6.9 6.80902 5.6 8.50902 5.6C10.209 5.6 11.509 6.9 11.509 8.6C11.509 10.3 10.209 11.6 8.50902 11.6Z"
                fill="#7B92A7"
            />
        </svg>
    );
}

export default React.memo(SettingSVG);
