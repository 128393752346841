import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton, CircularProgress, useTheme, Link, Tabs, Tab } from '@material-ui/core';
import AddonLayout from 'addons/layout';
import ButtonBase from 'components/button/Base';
import hexToRgba from 'hex-to-rgba';
import GridlyShortLogoSVG from 'assets/images/svg/addon/GridlyShortLogoSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import MemoQGridlySVG from 'assets/images/svg/addon/MemoQGridlySVG';
import PopperMenu from 'components/menus/Popper';
import MenuOption from '../MenuOption';
import * as addonActions from 'addons/actions';
import { useDispatch } from 'react-redux';
import MemoQSetting from './Setting';
import { ADD_ON_STATES, PAYMENT_METHODS, PAYMENT_WARNING_TYPES, PLAN_TYPES } from 'const';
import CheckedSVG from 'assets/images/svg/CheckedSVG';
import WarningBox from 'payment/components/warning';
import { useTranslation } from 'react-i18next';
import { sendManualTrack } from 'tracker';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import * as paymentActions from 'payment/actions';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useHistory } from 'react-router-dom';
import {
    useCurrentSubscriptionIs,
    useIsAddonPaid,
    useIsBlockedAddonContent,
    useIsProTrial,
    usePaymentMethod,
    useCardInfo
} from 'hooks/payment';
import Checkbox from 'components/checkbox/Base';
import { getFriendlyDateFromUnix } from 'utils/datetime';
import { beautifyPrice } from 'utils/payment';
import Button from 'components/button/Base';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { isOwner } from 'utils/permission/role';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    stepSpacing: {
        marginBottom: theme.spacing(3)
    },
    sendFeedback: {
        color: hexToRgba(theme.colors.dodgerBlue, 0.8)
    },
    dlFlex: {
        display: 'flex'
    },
    avatar: {
        '& svg': {
            width: 88,
            height: 88
        }
    },
    expand: {
        flex: 1
    },
    more: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 10
    },
    notEnabled: {
        background: `#F1F7FA`,
        height: '100%',
        padding: theme.spacing(3)
    },
    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    buttonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    center: {
        textAlign: 'center',
        maxWidth: '70%',
        margin: `0 auto`
    },
    spacing2: {
        marginBottom: theme.spacing(2)
    },
    sidebar: {
        padding: theme.spacing(4)
    },
    upgradeIcon: {
        marginRight: theme.spacing(1)
    },
    greyText: {
        color: theme.colors.midGrey
    },
    addonWrapper: {
        padding: 40,
        background: theme.colors.ghostwhite,
        borderRadius: 8,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    tabContent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

export function MemoQContent({ isBlockedAddonContent, integration }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChangeSetting = () => {
        dispatch(addonActions._updateAddonState({ addonId: integration?.id, addonState: ADD_ON_STATES.DRAFT }));
    };

    const correctState = React.useMemo(() => {
        return isBlockedAddonContent ? ADD_ON_STATES.INACTIVE : integration?.state;
    }, [isBlockedAddonContent, integration]);

    switch (correctState) {
        case ADD_ON_STATES.DRAFT:
            return <MemoQSetting integration={integration} />;

        case ADD_ON_STATES.ACTIVE:
            return (
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    wrap="nowrap"
                    className={classes.notEnabled}
                >
                    <Grid item className={classes.spacing2}>
                        <MemoQGridlySVG />
                    </Grid>
                    <Grid item className={classes.spacing2}>
                        <Typography variant="h3">{t('addon_memoQ_connected_title')}</Typography>
                    </Grid>
                    <Grid item className={`${classes.center} ${classes.spacing2}`}>
                        <Typography variant="caption" display="inline">
                            {t('addon_memoQ_connected_subtitle')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link
                            style={{ cursor: 'pointer', fontWeight: 500 }}
                            variant="contained"
                            onClick={handleChangeSetting}
                        >
                            {t('addon_memoQ_connected_change_setting')}
                        </Link>
                    </Grid>
                </Grid>
            );
        case ADD_ON_STATES.INACTIVE:
        default:
            return (
                <Grid container alignItems="center" justify="center" className={classes.notEnabled}>
                    <Grid item>
                        <MemoQGridlySVG />
                    </Grid>
                </Grid>
            );
    }
}

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    SETTING: 'SETTING',
    OVERVIEW: 'OVERVIEW'
};

function MemoQ({ addon, onBack, onClose }) {
    const { icon, title, description, provider, integration, subscription, plan } = addon;
    const integrationId = integration?.id;
    const integrationState = integration?.state;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const [isDoing, setIsDoing] = React.useState(false);

    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isEnterprisePlan = useCurrentSubscriptionIs(PLAN_TYPES.ENTERPRISE);
    const history = useHistory();
    const [openBuyAddonModal, setOpenBuyAddonModal] = React.useState(false);
    const [openConfirmUninstallModal, setOpenConfirmUninstallModal] = React.useState(false);
    const [isBuying, setIsBuying] = React.useState(false);
    const addonPrice = subscription?.price || plan?.prices?.[0];
    const memoQSubscription = addon?.subscription;
    const isHavePaidExtraAddonSubscription = useIsAddonPaid(PLAN_TYPES.MEMO_Q);
    const isBlockedAddonContent = useIsBlockedAddonContent(PLAN_TYPES.MEMO_Q);
    const [cancelSubscription, setCancelSubscription] = React.useState(false);
    const tabClasses = useTabStyles();
    const [tabValue, setTabValue] = React.useState(TABS.OVERVIEW);
    const isProTrial = useIsProTrial();
    const paymentMethod = usePaymentMethod();
    const cardInfo = useCardInfo();
    const [isOpenNoCard, setIsOpenNoCard] = React.useState(false);
    const [isOpenContact, setIsOpenContact] = React.useState(false);
    const userInfo = useCurrentUserFullInfo();
    const isCurrentOwner = isOwner({ currentUser: userInfo });

    const handleCloseNoCard = () => {
        setIsOpenNoCard(false);
    };

    const handleOpenNoCard = () => {
        setIsOpenNoCard(true);
    };

    const handleCloseContact = () => {
        setIsOpenContact(false);
    };

    const handleOpenContact = () => {
        setIsOpenContact(true);
    };

    const handleNoCard = () => {
        onClose();
        history.push(`/company-settings/billing/detail`);
    };

    const handleCloseBuyModal = React.useCallback(() => {
        setOpenBuyAddonModal(false);
    }, []);

    const handleOpenBuyModal = React.useCallback(() => {
        if (!Boolean(cardInfo) && !isProTrial) {
            handleOpenNoCard();
            return;
        }

        if (isEnterprisePlan || paymentMethod === PAYMENT_METHODS.INVOICE) {
            handleOpenContact();
            return;
        }
        setOpenBuyAddonModal(true);
    }, [cardInfo, isEnterprisePlan, isProTrial, paymentMethod]);

    const handleOpenConfirmUninstallModal = React.useCallback(() => {
        setOpenConfirmUninstallModal(true);
    }, []);

    const handleCloseConfirmUninstallModal = React.useCallback(() => {
        setOpenConfirmUninstallModal(false);
    }, []);

    const handleClick = React.useCallback(
        event => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    const handleClickAway = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const isInstalled = React.useMemo(() => {
        return integrationState === ADD_ON_STATES.ACTIVE || integrationState === ADD_ON_STATES.DRAFT;
    }, [integrationState]);

    const handleEnableAddon = React.useCallback(() => {
        setIsDoing(true);
        sendManualTrack({ type: 'Install memoQ' });
        if (!integrationId) {
            dispatch(
                addonActions.enableAddon({
                    body: {
                        title,
                        description,
                        provider
                    },
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('Enable add on success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('Enable add on failed');
                    }
                })
            );
        } else {
            dispatch(
                addonActions.changeAddonState({
                    addonId: integrationId,
                    newState: ADD_ON_STATES.DRAFT,
                    successCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Success');
                    },
                    errorCallback: () => {
                        setIsDoing(false);
                        console.log('disabled Add On Failed');
                    }
                })
            );
        }
    }, [description, integrationId, dispatch, title, provider]);

    const handleRemoveAddon = React.useCallback(
        e => {
            sendManualTrack({ type: 'Uninstall memoQ' });

            if (!cancelSubscription) {
                setIsDoing(true);
                dispatch(
                    addonActions.changeAddonState({
                        addonId: integrationId,
                        newState: ADD_ON_STATES.INACTIVE,
                        successCallback: () => {
                            setIsDoing(false);
                            console.log('disabled Add On Success');
                            handleCloseConfirmUninstallModal();
                        },
                        errorCallback: () => {
                            setIsDoing(false);
                            console.log('disabled Add On Failed');
                        }
                    })
                );
            } else {
                setIsDoing(true);
                dispatch(
                    paymentActions.cancelAddonSubscription({
                        id: memoQSubscription?.id,
                        successCallback: () => {
                            console.log('canceled success');
                            setCancelSubscription(false);
                            dispatch(
                                addonActions.changeAddonState({
                                    addonId: integrationId,
                                    newState: ADD_ON_STATES.INACTIVE,
                                    successCallback: () => {
                                        setIsDoing(false);
                                        handleCloseConfirmUninstallModal();
                                    },
                                    errorCallback: () => {
                                        setIsDoing(false);
                                        console.log('disabled Add On Failed');
                                    }
                                })
                            );
                        },
                        errorCallback: () => {
                            console.log('canceled failed');
                            setIsDoing(false);
                        }
                    })
                );
            }
        },
        [dispatch, cancelSubscription, integrationId, handleCloseConfirmUninstallModal, memoQSubscription]
    );

    const handleUpgrade = React.useCallback(() => {
        onClose();
        history.push(`/company-settings/billing/overview`);
    }, [history, onClose]);

    const handleBuy = React.useCallback(() => {
        setIsBuying(true);
        dispatch(
            paymentActions.buyAddon({
                body: {
                    priceId: addonPrice?.id
                },
                errorCallback: () => {
                    console.log('error');
                    setIsBuying(false);
                },
                successCallback: () => {
                    console.log('success');
                    setIsBuying(false);
                    handleCloseBuyModal();
                }
            })
        );
    }, [addonPrice, dispatch, handleCloseBuyModal]);

    const ButtonInstallRender = React.useMemo(() => {
        const isInstallOrNot = isInstalled ? (
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <CheckedSVG
                        color={theme.colors.white}
                        fillColor={hexToRgba(theme.colors.atlantis, 0.8)}
                        style={{ marginRight: 4 }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="body1">{t('global_installed')}</Typography>
                </Grid>
            </Grid>
        ) : (
            <Typography variant="body1" style={{ color: theme.colors.white }}>
                {t('global_install')}
            </Typography>
        );

        const notFreePlanRender = !isHavePaidExtraAddonSubscription ? (
            <ButtonBase width={120} onClick={handleOpenBuyModal} variant="contained">
                {beautifyPrice({
                    unitAmount: addonPrice?.unitAmount,
                    currency: addonPrice?.currency
                })}
                /{addonPrice?.recurringInterval}
            </ButtonBase>
        ) : (
            <>
                <Grid item className={`${classes.expand} ${classes.buttonWrapper}`}>
                    <ButtonBase
                        onClick={handleEnableAddon}
                        disabled={isInstalled || isDoing}
                        variant="contained"
                        fullWidth
                    >
                        {isInstallOrNot}
                    </ButtonBase>
                    {isDoing && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
                {isInstalled && (
                    <Grid item>
                        <IconButton onClick={handleClick} className={classes.more}>
                            <IconMoreActionsSVG />
                        </IconButton>
                    </Grid>
                )}
                {anchorEl && (
                    <PopperMenu
                        className={classes.commentPopup}
                        anchorEl={anchorEl}
                        placement={'bottom-end'}
                        id={`add-on-setting`}
                    >
                        <MenuOption
                            onRemoveAddon={() => {
                                handleOpenConfirmUninstallModal();
                                handleClickAway();
                            }}
                            id={integrationId}
                            handleClickAway={handleClickAway}
                        />
                    </PopperMenu>
                )}
            </>
        );
        return (
            <Grid container spacing={2} direction="row" alignItems="center">
                {isFreePlan ? (
                    <ButtonBase width={120} onClick={handleUpgrade} variant="contained">
                        <UpgradeSVG className={classes.upgradeIcon} />
                        {t('global_upgrade')}
                    </ButtonBase>
                ) : (
                    notFreePlanRender
                )}
            </Grid>
        );
    }, [
        handleOpenBuyModal,
        anchorEl,
        classes,
        handleClick,
        handleClickAway,
        handleEnableAddon,
        integrationId,
        handleOpenConfirmUninstallModal,
        handleUpgrade,
        isDoing,
        isFreePlan,
        isHavePaidExtraAddonSubscription,
        isInstalled,
        theme,
        addonPrice,
        t
    ]);

    return (
        <>
            <AddonLayout onBack={onBack}>
                <Grid container direction="column">
                    <Grid item className={classes.sidebar}>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justify="space-between"
                                    direction="row"
                                    wrap="nowrap"
                                >
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" spacing={3}>
                                            <Grid item className={classes.avatar}>
                                                {icon()}
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <Typography variant="h4">{title}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="caption">
                                                            {t('global_last_update_in')} Sep 14th, 2020
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item className={classes.dlFlex}>
                                                                <GridlyShortLogoSVG />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    {t('global_gridly_text')}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>{ButtonInstallRender}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Tabs
                                    className={classes.tabs}
                                    value={tabValue}
                                    onChange={(e, newValue) => setTabValue(newValue)}
                                >
                                    <Tab classes={tabClasses} value={TABS.OVERVIEW} label={t('overview')} />
                                    <Tab classes={tabClasses} value={TABS.SETTING} label={t('global_settings')} />
                                </Tabs>
                            </Grid>
                            <Grid item className={classes.tabContent}>
                                {tabValue === TABS.OVERVIEW ? (
                                    <Grid container direction="column" spacing={5}>
                                        <Grid item>
                                            <Grid container direction="column" spacing={4}>
                                                {isFreePlan && (
                                                    <Grid item>
                                                        <WarningBox
                                                            warningType={PAYMENT_WARNING_TYPES.INFO}
                                                            message={t('addon_memsource_install_warning_free')}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {t('global_description')}
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                - {t(`addon_memoq_install_description`)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="column" spacing={1}>
                                                        <Grid item>
                                                            <Typography variant="body1">
                                                                {t('global_permission')}:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                - {t(`addon_memoQ_install_note`)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <MemoQContent
                                        integration={integration}
                                        isBlockedAddonContent={isBlockedAddonContent}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AddonLayout>
            <Dialog open={openBuyAddonModal} onClose={handleCloseBuyModal}>
                <ConfirmBox
                    title={'Buy memoQ Add-on'}
                    body={
                        <>
                            <Typography component="div" style={{ marginBottom: theme.spacing(2) }} variant="body2">
                                Are you sure you want to buy memoQ Add-on?
                            </Typography>
                            <Typography variant="body2" display="inline">
                                We will automatically update your billing.
                            </Typography>{' '}
                            <Typography variant="body1" display="inline">
                                {beautifyPrice({
                                    unitAmount: addonPrice?.unitAmount,
                                    currency: addonPrice?.currency
                                })}
                                /{addonPrice?.recurringInterval}{' '}
                            </Typography>{' '}
                            <Typography variant="body2" display="inline">
                                {' '}
                                will be added to your bill.
                            </Typography>
                        </>
                    }
                    handleCancel={handleCloseBuyModal}
                    onClose={handleCloseBuyModal}
                    handleAgreed={handleBuy}
                    agreeLabel="Confirm"
                    isLoading={isBuying}
                />
            </Dialog>
            <Dialog open={openConfirmUninstallModal} onClose={handleCloseConfirmUninstallModal}>
                <ConfirmBox
                    title={'Uninstall memoQ Add-on'}
                    body={
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography component="div" style={{ marginBottom: theme.spacing(2) }} variant="body2">
                                    Are you sure you want to uninstall memoQ Add-on?
                                </Typography>
                            </Grid>
                            {Boolean(memoQSubscription) &&
                                !isEnterprisePlan &&
                                paymentMethod !== PAYMENT_METHODS.INVOICE && (
                                    <Grid item>
                                        <Grid
                                            style={{ position: 'relative', left: -12 }}
                                            container
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item>
                                                <Checkbox
                                                    onChange={e => setCancelSubscription(e?.target?.checked)}
                                                    checked={cancelSubscription}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    Cancel subscription for this plugin
                                                </Typography>
                                            </Grid>
                                            {cancelSubscription && memoQSubscription?.endDate && (
                                                <Grid item style={{ marginLeft: 46 }}>
                                                    <Typography variant="body2" className={classes.greyText}>
                                                        Your subscription will expire on{' '}
                                                        {getFriendlyDateFromUnix(memoQSubscription?.endDate)}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                    }
                    handleCancel={handleCloseConfirmUninstallModal}
                    onClose={handleCloseConfirmUninstallModal}
                    handleAgreed={handleRemoveAddon}
                    agreeLabel="Uninstall"
                    isLoading={isDoing}
                />
            </Dialog>
            <Dialog open={isOpenNoCard} onClose={handleCloseNoCard}>
                <ConfirmBox
                    title={'Payment info needed'}
                    body={
                        <Typography variant="body2">
                            Please fill in your payment detail before installing this add-on.
                        </Typography>
                    }
                    handleCancel={handleCloseNoCard}
                    onClose={handleCloseNoCard}
                    handleAgreed={handleNoCard}
                    agreeLabel="Go to Billing Details"
                    agreeWidth={180}
                />
            </Dialog>
            <Dialog open={isOpenContact} onClose={handleCloseContact}>
                <ConfirmBox
                    title={'Add-on is managed by account owner'}
                    body={
                        <Typography variant="body2">
                            {isCurrentOwner
                                ? 'Please contact Gridly Support team to install this add-on for your account.'
                                : 'Please contact your account owner.'}
                        </Typography>
                    }
                    handleCancel={handleCloseContact}
                    onClose={handleCloseContact}
                    action={
                        <Button onClick={handleCloseContact} width={100} variant="contained">
                            {t('global_ok')}
                        </Button>
                    }
                />
            </Dialog>
        </>
    );
}

export default React.memo(MemoQ);
