import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider, CircularProgress } from '@material-ui/core';
import InputText from 'components/inputs/InputText';
import Upload from 'components/upload/Base';
import IconCameraSVG from 'assets/images/svg/IconCameraSVG';
import Avatar from 'components/avatar/User';
import AvatarCropper from 'components/avatar/Cropper';
import SettingContentLayout from 'auth/common/Layout';
import { useCurrentUserFullInfo } from 'hooks/auth';
import Button from 'components/button/Base';
import UserCompanies from 'permission/companies/UserCompanies';
import Spinner from 'components/spinner/Base';
import Dialog from 'components/dialog/Dialog';
import * as authActions from 'auth/actions';
import { getAvatarUrl, readFile } from 'utils/images';
import { AVATAR_EXTENSIONS, PAYMENT_WARNING_TYPES } from 'const';
import { SHARED_STYLES, PAGE_PADDING } from 'const/style';
import { Trans, useTranslation } from 'react-i18next';
import WarningBox from 'payment/components/warning';
import DeleteAccount from 'components/deleteAccount';
import { enqueueSnackbar } from 'notifier/actions';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        padding: PAGE_PADDING
    },
    form: SHARED_STYLES.settingForm,
    divider: {
        width: PAGE_PADDING * 2,
        alignContent: `stretch`,
        display: 'flex',
        justifyContent: 'center',
        '& span': {
            width: 1,
            background: theme.colors.border
        }
    },
    fileInput: {
        borderRadius: '50%',
        width: 130,
        height: 130,
        position: 'relative',
        '& .hoverWrapper': {
            position: 'absolute',
            width: 130,
            height: 130,
            borderRadius: '50%',
            opacity: 0.6,
            background: 'black',
            display: 'none',
            '& path': {
                fill: theme.colors.white
            }
        },
        '&:hover': {
            '& .hoverWrapper': {
                display: 'block'
            }
        },
        overflow: 'hidden',
        outline: 'none'
    },
    spinnerWrapper: {
        position: 'absolute',
        width: 130,
        height: 130,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    uploading: {
        opacity: 0.6,
        background: 'black'
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    }
}));
function UserInfo() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userInfo = useCurrentUserFullInfo();
    const { imageUrl, id } = userInfo;
    const [thumbnail, setThumbnail] = React.useState(imageUrl);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [firstName, setFirstName] = useState(userInfo.firstName);
    const [lastName, setLastName] = useState(userInfo.lastName);
    const [email, setEmail] = useState(userInfo.email);
    const [selectedImg, setSelectedImg] = useState(null);
    const [isResendingDelete, setIsResendingDelete] = React.useState(false);

    React.useEffect(() => {
        if (imageUrl) {
            setThumbnail(getAvatarUrl(imageUrl));
        }
    }, [imageUrl]);

    const onRejectedHandler = useCallback(
        rejectedFiles => {
            dispatch(authActions.uploadUserException({ error: '' }));
        },
        [dispatch]
    );

    const onDropAcceptedHandler = useCallback(async acceptedFiles => {
        const file = acceptedFiles?.[0];

        const imageDataUrl = await readFile(file);

        setSelectedImg(imageDataUrl);
    }, []);

    const onAvatarSave = useCallback(
        file => {
            setIsUploading(true);
            setSelectedImg(null);
            if (file) {
                if (thumbnail) {
                    window.URL.revokeObjectURL(thumbnail);
                }
                setThumbnail(window.URL.createObjectURL(file));
            }
            dispatch(
                authActions.uploadUserAvatar({
                    file,
                    successCallback: () => {
                        setIsUploading(false);
                    },
                    errorCallback: () => {
                        setIsUploading(false);
                    }
                })
            );
        },
        [dispatch, thumbnail]
    );

    React.useEffect(() => {
        return () => {
            if (thumbnail) {
                window.URL.revokeObjectURL(thumbnail);
            }
        };
    }, [thumbnail]);

    const handleUpdateUserInfo = () => {
        dispatch(
            authActions.updateUserInfo({
                userInfo: {
                    ...userInfo,
                    firstName,
                    lastName,
                    id,
                    email
                },
                successCallback: () => {
                    console.log('update user info successfully');
                },
                errorCallback: () => {
                    console.log('failed update user info');
                }
            })
        );
    };

    const handleResendChangeEmail = () => {
        setIsLoading(true);
        dispatch(
            authActions.resendChangeEmail({
                successCallback: () => {
                    setIsLoading(false);
                    console.log('handleResendChangeEmail successfully');
                },
                errorCallback: () => {
                    setIsLoading(false);
                    console.log('handleResendChangeEmail failed');
                }
            })
        );
    };

    const handleRequestDeleteAccount = useCallback(() => {
        setIsResendingDelete(true);
        dispatch(
            authActions.requestDeleteAccount({
                isResend: true,
                successCallback: data => {
                    setIsResendingDelete(false);
                    dispatch(
                        enqueueSnackbar({
                            message: (
                                <div>
                                    <Trans
                                        i18nKey={
                                            data?.deleteAccount?.waitConfirm
                                                ? 'delete_account_wait_cs_resend_success'
                                                : 'delete_account_resend_success'
                                        }
                                        components={{ b: <b /> }}
                                        values={{ email: data?.email }}
                                    />
                                </div>
                            ),
                            type: 'info'
                        })
                    );
                },
                errorCallback: () => {
                    setIsResendingDelete(false);
                }
            })
        );
    }, [dispatch]);

    return (
        <SettingContentLayout
            name={t('auth_my_profile')}
            headerClassName="!flex-nowrap !items-baseline"
            nameClassName="flex-none"
            AddComponent={
                <Grid item className="!ml-[120px] flex flex-col gap-2">
                    {userInfo.changeEmail &&
                        (userInfo.changeEmail.resendTime ? (
                            <WarningBox
                                warningType={PAYMENT_WARNING_TYPES.INFO}
                                message="We've sent you an email to confirm your account. Please check your inbox (sometimes the mail goes to Junk/Spam folder), and follow the instruction in the email to finish changing your email."
                            />
                        ) : (
                            <WarningBox
                                warningType={PAYMENT_WARNING_TYPES.INFO}
                                message="We've sent you a confirmation email. Click the link in the email to update the email address."
                                action={
                                    <Button
                                        width={80}
                                        style={{ marginLeft: 14 }}
                                        variant="contained"
                                        onClick={handleResendChangeEmail}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <CircularProgress size={24} /> : 'Resend'}
                                    </Button>
                                }
                            />
                        ))}
                    {userInfo?.deleteAccount &&
                        !userInfo?.deleteAccount?.expired &&
                        (userInfo?.deleteAccount?.resendTime ? (
                            <WarningBox
                                warningType={PAYMENT_WARNING_TYPES.ERROR}
                                message={
                                    <Trans
                                        i18nKey={
                                            userInfo?.deleteAccount?.requireCSConfirm
                                                ? 'delete_account_wait_cs_resend_success'
                                                : 'delete_account_resend_success'
                                        }
                                        components={{ b: <b /> }}
                                        values={{ email: userInfo?.email }}
                                    />
                                }
                            />
                        ) : (
                            <WarningBox
                                warningType={PAYMENT_WARNING_TYPES.ERROR}
                                message={
                                    <Trans
                                        i18nKey={
                                            userInfo?.deleteAccount?.requireCSConfirm
                                                ? 'delete_account_wait_cs_resend_success'
                                                : 'delete_account_resend_success'
                                        }
                                        components={{ b: <b /> }}
                                        values={{ email: userInfo?.email }}
                                    />
                                }
                                action={
                                    <Button
                                        width={80}
                                        style={{ marginLeft: 14 }}
                                        variant="contained"
                                        onClick={handleRequestDeleteAccount}
                                        disabled={isResendingDelete}
                                    >
                                        {isResendingDelete ? <CircularProgress size={24} /> : 'Resend'}
                                    </Button>
                                }
                            />
                        ))}
                </Grid>
            }
        >
            <Grid className={classes.root} container direction="row" spacing={3}>
                <Grid item>
                    <Upload
                        onDropAccepted={onDropAcceptedHandler}
                        onDropRejected={onRejectedHandler}
                        className={`${classes.fileInput} `}
                        accept={AVATAR_EXTENSIONS}
                        hideBorder={!!imageUrl}
                    >
                        {thumbnail && (
                            <>
                                <Avatar className={classes.avatar} size={130} src={thumbnail} />
                                {!isUploading && (
                                    <Grid item className={`hoverWrapper`}>
                                        <div className={classes.center}>
                                            <IconCameraSVG />
                                        </div>
                                    </Grid>
                                )}
                                {isUploading && (
                                    <Grid
                                        item
                                        className={`${classes.spinnerWrapper} ${isUploading ? classes.uploading : ''}`}
                                    >
                                        <Spinner size={18} thick={3} />
                                    </Grid>
                                )}
                            </>
                        )}

                        {!thumbnail && <IconCameraSVG />}
                    </Upload>
                    <Dialog
                        open={!!selectedImg}
                        onClose={e => {
                            setSelectedImg(null);
                        }}
                    >
                        {selectedImg && <AvatarCropper src={selectedImg} shape={'round'} onSave={onAvatarSave} />}
                    </Dialog>
                </Grid>
                <Grid item className={classes.divider}>
                    <span></span>
                </Grid>
                <Grid item className={classes.form} style={{ flex: 1 }}>
                    <Grid container direction="column" wrap="nowrap" spacing={4}>
                        <Grid item>
                            <Grid container direction="column" spacing={4}>
                                <Grid item>
                                    <Typography variant="h4">{t(`auth_user_info_personal_info`)}</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <Typography variant="body1">{t(`global_first_name`)}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <InputText
                                                name="name"
                                                type="text"
                                                value={firstName}
                                                height={40}
                                                onChange={e => setFirstName(e.target.value)}
                                                placeholder=""
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <Typography variant="body1">{t(`global_last_name`)}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <InputText
                                                name="name"
                                                type="text"
                                                value={lastName}
                                                height={40}
                                                onChange={e => setLastName(e.target.value)}
                                                placeholder=""
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <Typography variant="body1">{t(`global_email`)}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <InputText
                                                name="name"
                                                type="text"
                                                value={email}
                                                height={40}
                                                onChange={e => setEmail(e.target.value)}
                                                placeholder=""
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={
                                            !firstName ||
                                            !lastName ||
                                            !email ||
                                            (firstName === userInfo.firstName &&
                                                lastName === userInfo.lastName &&
                                                email === userInfo.email)
                                        }
                                        onClick={handleUpdateUserInfo}
                                        width={120}
                                        variant="contained"
                                    >
                                        {t(`global_save`)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={4}>
                                <Grid item>
                                    <Typography variant="h4">{t(`auth_user_info_your_company`)}</Typography>
                                </Grid>
                                <Grid item>
                                    <UserCompanies />
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isExternal && (
                            <>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="h4">{t(`delete_my_account`)}</Typography>
                                        </Grid>
                                        <Grid item className="!mt-1">
                                            <Typography className="!text-grey-mid" variant="body2">
                                                {t(`permanent_delete_your_account_gridly`)}
                                            </Typography>
                                        </Grid>
                                        <Grid item className="!mt-4 !mb-8">
                                            <DeleteAccount />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
}

export default UserInfo;
