import request from './request';

const shareLinkApis = {
    getAuthShareLink: shareKey => `/auth/share?key=${shareKey}`,
    getShareViewDetail: compositeViewId => `/v1/shares/view/${compositeViewId}`,
    requestShareView: compositeViewId => `/gridly/v1/shares/view/${compositeViewId}`,
    deleteShareViewLink: compositeViewId => `/v1/shares/view/${compositeViewId}`
};

export async function getAuthShareLinkApi({ shareKey }) {
    const response = await request.get(shareLinkApis.getAuthShareLink(shareKey));
    return response.data;
}

export async function getShareViewDetailApi({ compositeViewId, key }) {
    let response;
    if (key) {
        response = await request.get(shareLinkApis.getShareViewDetail(compositeViewId), {
            headers: {
                'X-Share-Key': key
            }
        });
    } else {
        response = await request.get(shareLinkApis.getShareViewDetail(compositeViewId));
    }
    return response.data;
}

export async function requestShareViewApi({ compositeViewId, body }) {
    const response = await request.put(shareLinkApis.requestShareView(compositeViewId), body);
    return response.data;
}

export async function deleteShareViewLinkApi({ compositeViewId }) {
    const response = await request.delete(shareLinkApis.deleteShareViewLink(compositeViewId));
    return response.data;
}
