import React from 'react';

function Minus({ color = '#EB4F5E', width = 16, height = 14, ...other }) {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.5" cy="4.5" r="4.5" fill="white" />
            <circle cx="4.5" cy="4.5" r="4.5" fill="#EB4F5E" fillOpacity="0.8" />
            <rect x="2.25" y="4.25" width="4.5" height="0.5" fill="white" stroke="white" strokeWidth="0.5" />
        </svg>
    );
}

export default React.memo(Minus);
