import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';

const useStyles = makeStyles(theme => ({
    symbol: {
        display: 'inline-block',
        borderRadius: 4,
        width: 10,
        height: 10,
        marginRight: theme.spacing(1)
    }
}));

function DependencyStatusGuideItem({ label, color, labelColor, className }) {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" className={`${classes.root} ${className}`}>
            <Grid item style={{ background: hexToRgba(color, 0.8) }} className={classes.symbol}></Grid>
            <Grid item>
                <Typography style={{ color: labelColor ? labelColor : `` }} variant="body1" display="inline">
                    {label}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default DependencyStatusGuideItem;
