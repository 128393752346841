import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IOSSwitch from 'components/switches/IOS';
import { RESOLVED } from 'const/commentStatus';
import CommentNotifySVG from 'assets/images/svg/CommentNotifySVG';
import CommentList from 'gridUI/cellComment/CommentList';
import { useCurrentUserFullInfo } from 'hooks/auth';
import { useDispatch } from 'react-redux';
import * as gridActions from '../actions';
import ReplyBox from 'components/cellComment/ReplyBox';
import { scrollInToView } from 'utils/scroll';
import { useDisabledColumnsByType, useViewColumnsWithUserLanguageViewsAndMetadata } from 'hooks/gridUI/column';
import { useCommentByThreadId, useCommentRecordDataByRowId, useDbIdInGridUI } from 'hooks/gridUI';
import ColumnTypeDisplay from '../ColumnTypeDisplay';
import hexToRgba from 'hex-to-rgba';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import Text from '../cellDisplay/Text';
import SingleLine from '../cellDisplay/SingleLine';
import Translation from '../cellDisplay/Translation';
import Number from '../cellDisplay/Number';
import MultiLines from '../cellDisplay/MultiLines';
import RecordId from '../cellDisplay/RecordID';
import SingleSelect from '../cellDisplay/SingleSelect';
import MultipleSelect from '../cellDisplay/MultipleSelect';
import Datetime from '../cellDisplay/Datetime';
import MarkDown from '../cellDisplay/MarkDown';
import RichText from '../cellDisplay/RichText';
import Files from '../cellDisplay/Files';
import Boolean from '../cellDisplay/Boolean';
import Formula from '../cellDisplay/Formula';
import Yaml from '../cellDisplay/Yaml';
import Html from '../cellDisplay/Html';
import Json from '../cellDisplay/Json';
import PathTag from '../cellDisplay/PathTag';
import Ref from '../cellDisplay/Ref';
import LastModifiedBy from '../cellDisplay/LastModifiedBy';
import LastModifiedDate from '../cellDisplay/LastModifiedDate';
import { COMMENT_HEADER_HEIGHT, COMMENT_CHAT } from 'const/style';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import * as gridUIActions from '../actions';
import { DISABLED_OPACITY } from 'const/style';
import Spinner from 'components/spinner/Base';
import { isKbArrowLeft, isKbArrowRight, isKbEscape } from 'utils/keyboard';
import { useDisabledSourceColumns, useProcessingColumns, useDisabledColumns } from 'hooks/gridUI';
import * as roleConst from 'auth/roleConst';
import { useRole } from 'hooks/auth/role';
import IconLockSVG from 'assets/images/svg/IconLockSVG';
import Tooltip from 'components/tooltip/Base';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useLdUserEmailMap } from 'hooks/permission';

const CELL_COMMENT_HEIGHT = 720;
const MODAL_WIDTH = 800;

const useStyles = makeStyles(theme => ({
    root: {
        width: MODAL_WIDTH,
        background: theme.colors.white,
        boxShadow: theme.shadows[1],
        borderRadius: 4,
        height: CELL_COMMENT_HEIGHT,
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            width: '100%'
        },
        position: 'relative',
        paddingBottom: 4
    },
    header: {
        height: COMMENT_HEADER_HEIGHT,
        padding: `14px 24px`,
        borderBottom: `1px solid ${theme.colors.border}`
    },
    dlFlex: {
        display: 'flex'
    },
    columns: {
        background: theme.colors.ghostwhite,
        height: CELL_COMMENT_HEIGHT - COMMENT_HEADER_HEIGHT,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            maxHeight: 200
        }
    },

    columnItem: {
        position: 'relative'
    },

    commentActiveWrapper: {
        position: 'absolute',
        top: 5,
        right: 5,
        cursor: 'pointer',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end'
    },
    commentActive: {
        width: 0,
        height: 0,
        borderStyle: `solid`,
        borderWidth: `0 10px 10px 0`
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    commentDashboard: {
        position: 'relative',
        height: CELL_COMMENT_HEIGHT - COMMENT_HEADER_HEIGHT
    },
    replyBox: {
        borderTop: `1px solid ${theme.colors.divider}`,
        position: 'absolute',
        background: theme.colors.white,
        bottom: 0,
        left: 0,
        width: '100%'
    },
    sp3: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(3),
        fontWeight: 500
    },
    arrowWrapper: {
        width: 48,
        height: 48,
        background: hexToRgba(theme.colors.dimGrey, 0.5),
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrow: {
        cursor: 'pointer',
        height: 25,
        width: 25,
        '& path': {
            fill: theme.colors.white
        }
    },
    arrowLeft: {
        position: 'absolute',
        left: -65,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    arrowRight: {
        position: 'absolute',
        right: -65,
        top: 0,
        bottom: 0,
        margin: 'auto'
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none',
        cursor: 'not-allowed'
    },
    spinner: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textWhite: {
        color: theme.colors.white
    },
    fullWidth: {
        width: '100%'
    }
}));

function CommentContent({ columnType, options = [], customProperties, referencedColumnType, ...rest }) {
    switch (columnType) {
        case columnTypes.SINGLE_LINE:
            return <SingleLine {...rest} />;
        case columnTypes.RECORD_ID:
            return <RecordId {...rest} />;
        case columnTypes.FORMULA:
            return <Formula isReadOnly={true} {...rest} />;
        case columnTypes.MULTIPLE_LINES:
            return <MultiLines {...rest} />;
        case columnTypes.NUMBER:
            return <Number {...rest} />;
        case columnTypes.TRANSLATION:
            return <Translation {...rest} />;
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
            return <LastModifiedBy {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelect options={options} customProperties={customProperties} {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelect options={options} customProperties={customProperties} {...rest} />;
        case columnTypes.DATETIME:
            return <Datetime {...rest} />;
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <LastModifiedDate {...rest} />;
        case columnTypes.MARKDOWN:
            return <MarkDown {...rest} />;
        case columnTypes.RICH_TEXT:
            return <RichText {...rest} />;
        case columnTypes.FILES:
            return <Files {...rest} />;
        case columnTypes.BOOLEAN:
            return <Boolean {...rest} />;
        case columnTypes.YAML:
            return <Yaml {...rest} />;
        case columnTypes.JSON_LD:
            return <Json {...rest} />;
        case columnTypes.HTML:
            return <Html {...rest} />;
        case columnTypes.PATH_TAG:
            return <PathTag options={options} customProperties={customProperties} {...rest} />;
        case columnTypes.REFERENCE:
            return (
                <Ref
                    referencedColumnType={referencedColumnType}
                    options={options}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        default:
            return <Text {...rest} />;
    }
}

function CellComment({ defaultComment, compositeViewId, onChangeThread, commentList = [], onClose }) {
    const threadId = defaultComment?.thread?.id;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const comment = useCommentByThreadId({ threadId });
    const viewColumns = useViewColumnsWithUserLanguageViewsAndMetadata();
    const dbId = useDbIdInGridUI();
    const thread = comment?.thread;
    const rowId = thread?.rowId;
    const columnId = thread?.columnId;
    const comments = comment?.comments || [];
    const currentUser = useCurrentUserFullInfo();
    const commentRecordData = useCommentRecordDataByRowId({ rowId });
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = React.useState(false);
    const disabledColumns = useDisabledColumns();
    const processingColumns = useProcessingColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const ldUserEmailMap = useLdUserEmailMap();
    const roles = useRole();
    const accessEditsRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const workspaceRole = roles?.[roleConst.WORKSPACE_ROLE];
    const accessEditTranslation = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION];

    const accessRecordCombined = React.useMemo(() => {
        return workspaceRole === roleConst.TRANSLATOR ? accessEditTranslation : accessEditsRecords;
    }, [accessEditsRecords, accessEditTranslation, workspaceRole]);

    const lockColumnIds = [
        ...new Set([...disabledColumns, ...processingColumns, ...disabledSourceColumns, ...disabledColumnIdsByType])
    ];

    const selectedColumn = React.useMemo(() => {
        return viewColumns?.find(viewCol => viewCol?.id === columnId);
    }, [columnId, viewColumns]);

    const isChecked = React.useMemo(() => {
        return thread?.status === RESOLVED ? true : false;
    }, [thread]);

    const COMMENT_LIST_HEIGHT = isChecked
        ? CELL_COMMENT_HEIGHT - COMMENT_HEADER_HEIGHT
        : CELL_COMMENT_HEIGHT - (COMMENT_CHAT + COMMENT_HEADER_HEIGHT);

    React.useEffect(() => {
        if (threadId) {
            const thread = defaultComment?.thread;
            const columnId = thread?.columnId;
            const rowId = thread?.rowId;
            setIsFetching(true);
            dispatch(
                gridUIActions.checkCanOpenComment({
                    compositeViewId,
                    columnId,
                    rowId,
                    threadId,
                    callback: ({ isCanOpen }) => {
                        if (isCanOpen) {
                            setIsFetching(false);
                            return;
                        }
                        setIsFetching(false);
                    }
                })
            );
        }
    }, [defaultComment, dispatch, compositeViewId, threadId]);

    const handleSwitch = e => {
        if (!columnId || !rowId) return;

        dispatch(
            gridActions.updateCellCommentThreadStatus({
                rowId,
                columnId,
                threadId,
                newStatus: e.target.checked,
                successCallback: () => {
                    console.log('update thread status Success');
                    const node = document.getElementById('cellCommentModal');
                    scrollInToView(node);
                },
                errorCallback: () => {
                    console.log('update thread status failed');
                }
            })
        );
    };

    const updateThreadHandler = ({ commentId, oldContent, newContent, callback, editedAt }) => {
        dispatch(
            gridActions.updateCellThreadComment({
                threadId,
                commentId,

                oldContent,
                newContent,
                editedAt,
                errorCallback: () => {
                    console.log('update failed');
                }
            })
        );
        callback && callback();
    };

    const deleteThreadHandler = () => {
        console.log('delete thread comment');
    };

    const updateCommentHandler = ({ commentId, oldContent, newContent, callback, editedAt }) => {
        dispatch(
            gridActions.updateCellComment({
                threadId,

                commentId,
                oldContent,
                newContent,
                editedAt,
                errorCallback: () => {
                    console.log('update failed');
                }
            })
        );
        callback && callback();
    };

    const deleteCommentHandler = ({ commentId }) => {
        dispatch(gridActions.deleteCellComment({ commentId, threadId }));
    };

    const saveHandler = content => {
        if (!rowId || !columnId) return;
        dispatch(
            gridActions.createComment({
                rowId,
                columnId,
                content,
                threadId,
                successCallback: () => {
                    console.log('create comment Success');
                    const node = document.getElementById('cellCommentModal');
                    scrollInToView(node);
                },
                errorCallback: () => {
                    console.log('create comment failed');
                }
            })
        );
    };

    const goNextStep = React.useCallback(() => {
        const nextCommentIndex = commentList?.findIndex(comment => comment?.thread?.id === threadId) + 1;
        if (nextCommentIndex >= commentList?.length) return;

        onChangeThread && onChangeThread(commentList?.[nextCommentIndex]);
    }, [commentList, onChangeThread, threadId]);

    const goPrevStep = React.useCallback(() => {
        const prevCommentIndex = commentList?.findIndex(comment => comment?.thread?.id === threadId) - 1;
        if (prevCommentIndex < 0) return;
        onChangeThread && onChangeThread(commentList?.[prevCommentIndex]);
    }, [commentList, onChangeThread, threadId]);

    React.useEffect(() => {
        function downHandler(e) {
            var event = document.all ? window.event : e;
            if (!/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) {
                if (isKbArrowRight(event)) {
                    goNextStep();
                    return;
                }

                if (isKbArrowLeft(event)) {
                    goPrevStep();
                    return;
                }

                if (isKbEscape(event)) {
                    onClose && onClose();
                }
            }
        }

        window.addEventListener('keydown', downHandler, true);

        return () => {
            window.removeEventListener('keydown', downHandler, true);
        };
    }, [goNextStep, goPrevStep, onClose]);

    React.useEffect(() => {
        if (!isFetching) {
            const node = document.getElementById('columnIdScrolled');
            scrollInToView(node, {
                block: 'center',
                inline: 'center',
                duration: 100
            });
        }
    }, [isFetching]);

    const preventDefault = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Grid onClick={preventDefault} container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item>
                <Grid container className={classes.header} alignItems="center" justify="space-between">
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item className={classes.dlFlex}>
                                <CommentNotifySVG
                                    width={16}
                                    height={16}
                                    color={thread?.status !== RESOLVED ? theme.colors.crusta : theme.colors.atlantis}
                                />
                            </Grid>
                            <Grid item className={classes.sp3}>
                                {threadId ? `#${threadId}` : `NA`}
                            </Grid>
                            {selectedColumn && (
                                <Grid item>
                                    <ColumnTypeDisplay
                                        name={selectedColumn?.name}
                                        group={selectedColumn.group}
                                        type={selectedColumn?.columnType}
                                        customProperties={selectedColumn?.customProperties}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>{t('global_resolved')}</Grid>
                            <Grid item>
                                <IOSSwitch checked={isChecked} onChange={handleSwitch} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!isFetching && (
                <Grid item style={{ overflowY: 'auto' }}>
                    <Grid container direction="row">
                        <Grid item xs={12} md={6} className={`${classes.columns}`}>
                            <Grid container direction="column">
                                {viewColumns?.map(column => {
                                    const viewColumnId = column?.id;
                                    const isActive = viewColumnId === columnId;
                                    const columnType = getCorrectColumnType(column);
                                    let cellData = commentRecordData?.[column?.id];

                                    const isEditable = !lockColumnIds?.includes(viewColumnId) && column?.editable;
                                    const isEditReadOnly = accessRecordCombined !== roleConst.FULL;

                                    const isReadOnly = isEditReadOnly || !isEditable;
                                    const isNotSupportEditForNowTypes = [
                                        columnTypes.DATETIME,
                                        columnTypes.SINGLE_SELECTION,
                                        columnTypes.MULTIPLE_SELECTIONS,
                                        columnTypes.REFERENCE,
                                        columnTypes.FILES,
                                        columnTypes.MARKDOWN,
                                        columnTypes.RICH_TEXT,
                                        columnTypes.PATH_TAG,
                                        columnTypes.FORMULA
                                    ].includes(columnType);
                                    return (
                                        <Grid key={column?.id} item className={classes.fullWidth}>
                                            <Grid
                                                container
                                                direction="column"
                                                className={`${classes.columnItem}`}
                                                style={{
                                                    background: isActive
                                                        ? thread?.status === RESOLVED
                                                            ? hexToRgba(theme.colors.atlantis, 0.1)
                                                            : hexToRgba(theme.colors.crusta, 0.1)
                                                        : ``,
                                                    marginLeft: theme.spacing(3),
                                                    marginRight: theme.spacing(3),
                                                    marginBottom: theme.spacing(2),
                                                    width: `calc(100% - ${2 * theme.spacing(3)}px)`,
                                                    padding: 10,
                                                    borderRadius: 4
                                                }}
                                            >
                                                {isActive && (
                                                    <span className={classes.commentActiveWrapper}>
                                                        <CommentNotifySVG
                                                            color={
                                                                thread?.status === RESOLVED
                                                                    ? theme.colors.atlantis
                                                                    : theme.colors.crusta
                                                            }
                                                        />
                                                    </span>
                                                )}
                                                <Grid item className={classes.spacing}>
                                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                                        <Grid item>
                                                            <ColumnTypeDisplay
                                                                name={column?.name}
                                                                group={column?.group}
                                                                type={columnType}
                                                                customProperties={column?.customProperties}
                                                            />
                                                        </Grid>
                                                        {isNotSupportEditForNowTypes && (
                                                            <Tooltip
                                                                title={
                                                                    <p className="text-light">
                                                                        <Trans i18nKey="cell_comments_editing">
                                                                            Editing this data type here <br /> is not
                                                                            supported for now
                                                                        </Trans>
                                                                    </p>
                                                                }
                                                            >
                                                                <Grid item className={classes.dlFlex}>
                                                                    <IconLockSVG color={theme.colors.highlight} />
                                                                </Grid>
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ maxWidth: '100%' }}>
                                                    <CommentContent
                                                        rowId={rowId}
                                                        columnId={viewColumnId}
                                                        referencedColumnType={column?.referencedColumnType}
                                                        columnType={columnType}
                                                        value={cellData?.value}
                                                        cellData={cellData}
                                                        options={column?.options}
                                                        customProperties={column?.customProperties}
                                                        group={column?.group}
                                                        dbId={dbId}
                                                        isReadOnly={isReadOnly || isNotSupportEditForNowTypes}
                                                        ldUserEmailMap={ldUserEmailMap}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {isActive && <span id={'columnIdScrolled'} style={{ height: 1 }}></span>}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid className={classes.commentDashboard} container direction="column" wrap="nowrap">
                                <Grid item>
                                    <CommentList
                                        comments={comments}
                                        updateCommentHandler={updateCommentHandler}
                                        updateThreadHandler={updateThreadHandler}
                                        deleteThreadHandler={deleteThreadHandler}
                                        currentUser={currentUser}
                                        thread={thread}
                                        deleteCommentHandler={deleteCommentHandler}
                                        scrollCommentId={'cellCommentModal'}
                                        COMMENT_LIST_HEIGHT={COMMENT_LIST_HEIGHT}
                                        t={t}
                                    />
                                </Grid>
                                {!isChecked && (
                                    <Grid item className={classes.replyBox}>
                                        <ReplyBox saveHandler={saveHandler} isHideAction={true} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                className={`${classes.arrowLeft} ${classes.arrowWrapper} ${
                    threadId === commentList?.[0]?.thread?.id ? classes.disabled : ''
                }`}
            >
                <ArrowLeftSVG onClick={goPrevStep} className={`${classes.arrow}`} />
            </Grid>
            <Grid
                item
                className={`${classes.arrowRight} ${classes.arrowWrapper} ${
                    threadId === commentList?.[commentList?.length - 1]?.thread?.id ? classes.disabled : ''
                }`}
            >
                <ArrowRightSVG onClick={goNextStep} className={`${classes.arrow}`} />
            </Grid>
            {isFetching && (
                <Grid item className={`${classes.spinner}`}>
                    <Spinner size={18} thick={3} />
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(CellComment);
