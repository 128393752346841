import { STAGING, PRODUCTION, DEVELOPMENT, INTEGRATION, LOCAL, EXTERNAL } from './env';
const env = process.env.REACT_APP_ENVIRONMENT || INTEGRATION;

const apiUrls = {
    [PRODUCTION]: {
        baseUrl: 'https://api.gridly.com',
        title: 'Gridly',
        host: `https://app.gridly.com`,
        status: `https://status.gridly.com/api/v2/summary.json`,
        enabledRegion: false
    },
    [DEVELOPMENT]: {
        baseUrl: 'https://gateway.dev.gridly.com',
        title: 'Gridly Development',
        host: `https://dev.gridly.com`,
        status: `https://status.gridly.com/api/v2/summary.json`,
        enabledRegion: true
    },
    [INTEGRATION]: {
        baseUrl: 'https://gateway.integration.gridly.com',
        title: 'Gridly Integration',
        host: `https://integration.gridly.com`,
        status: `https://status.gridly.com/api/v2/summary.json`,
        enabledRegion: true
    },
    [STAGING]: {
        baseUrl: 'https://gateway.staging.gridly.com',
        title: 'Gridly Staging',
        host: `https://staging.gridly.com`,
        status: `https://status.gridly.com/api/v2/summary.json`,
        enabledRegion: false
    },
    [LOCAL]: {
        baseUrl: 'https://gateway.local.gridly.com',
        title: 'Gridly Local',
        host: `https://local.gridly.com`,
        status: `https://status.gridly.com/api/v2/summary.json`,
        enabledRegion: false
    },
    [EXTERNAL]: {
        baseUrl: `https://api.selfhosted.gridly.com`,
        title: `Gridly Self Hosted`,
        host: `https://selfhosted.gridly.com`,
        status: `https://status.gridly.com/api/v2/summary.json`,
        enabledRegion: false
    }
};

const landingUrls = {
    [PRODUCTION]: 'https://www.gridly.com',
    [DEVELOPMENT]: 'https://dev-gridly-landing.netlify.app'
};

export const ENV = env;
export const isExternal = ENV === EXTERNAL;
export const envConfig = apiUrls[env];
export const enabledRegion = envConfig?.enabledRegion;
export const API_URL = apiUrls[env]['baseUrl'];
export const GRIDLY_SITE_URL = landingUrls[env] || landingUrls[DEVELOPMENT];
export const AGILE_API_URL = `${GRIDLY_SITE_URL}/.netlify/functions`;
export const host = apiUrls?.[env]?.host;
export const STATUS_AIP = apiUrls?.[env]?.status;
export const PROD_API_URL = apiUrls[PRODUCTION]['baseUrl'];

export const APP_TITLE = 'Gridly';
export const APP_API_PREFIX_GRID_UI = '/gridly';
export const APP_API_PREFIX_ORGANIZATION = '/organization';
export const APP_API_PREFIX_LICENSE = '/organization';
export const APP_API_PREFIX_EVENT_LOG = '/eventlog';
export const APP_API_PREFIX_AUTHENTICATION = '/dbdouaa';
export const APP_API_PREFIX_TM = '/lqa';
export const APP_API_WORK_FLOW = '/workflow';
export const APP_API_PREFIX_DATABASE_SERVICE = '/databaseservice';
export const APP_API_PREFIX_DATA_COLLECTOR = '/eventlog';
export const APP_API_PREFIX_LQA = '/lqa';
export const APP_API_PREFIX_TB = '/lqa/api';
export const APP_API_NOTIFICATION = '/notification';
export const IMAGE_PREFIX = `${API_URL}${APP_API_PREFIX_GRID_UI}/api/v1`;
export const IMAGE_ORGANIZATION_PREFIX = `${API_URL}${APP_API_PREFIX_ORGANIZATION}`;
export const IMAGE_WORKFLOW_PREFIX = `${API_URL}${APP_API_WORK_FLOW}`;
export const REACT_APP_RUDDER_STACK_WRITE_KEY =
    process.env.REACT_APP_RUDDER_STACK_WRITE_KEY || '1hoY2lkSAh7F0rNfjsAY4evW8ZQ';
export const REACT_APP_RUDDER_STACK_HOST = process.env.REACT_APP_RUDDER_STACK_HOST || 'https://hosted.rudderlabs.com';
export const REACT_APP_RUDDER_STACK_CONTROL_PLANE_URL = process.env.REACT_APP_RUDDER_STACK_CONTROL_PLANE_URL;
export const FILE_STACK_API = 'AWumO5BVlRiu8dvUtKW0hz';
export const SENTRY_DSN =
    process.env.SENTRY_DSN || 'https://f188317a3ffe49aab0d6ec7662c31244@sentry.internal.gridly.com/16';

export const SENTRY_ORG_SLUG = 'gridly';

export const PROMOTE_FEATURE_VIEW = process.env.REACT_APP_PROMOTE_FEATURE_VIEW || 'mddjj55211dx6y5';
export const PROMOTE_FEATURE_API_KEY = process.env.REACT_APP_PROMOTE_FEATURE_API_KEY || 'IbR6bxk6zvk9Bs';
