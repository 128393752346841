import { useSelector } from 'react-redux';

export function useTMSuggestion({ rowId, columnId }) {
    const dataFromTM = useSelector(state => state.gridUI.dataFromTM);
    return dataFromTM?.[rowId]?.[columnId];
}

export function useDefaultTm() {
    return useSelector(state => state.gridUI.defaultTm);
}

export function useIsTmDisabled() {
    const defaultTm = useDefaultTm();
    return defaultTm?.isDisabled;
}

export function useIsApplyingTM() {
    return useSelector(state => state.gridUI.isApplyingTM);
}
