import React from 'react';

function MicrosoftAuthSVG() {
    return (
        <svg height="36" viewBox="-.7 -.1 511.9 511.9" width="36" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m479.8 511.8h-449.1c-17.4 0-31.4-14.1-31.4-31.4v-449.1c0-17.4 14.1-31.4 31.4-31.4h449.1c17.4 0 31.4 14.1 31.4 31.4v449.1c0 17.4-14 31.4-31.4 31.4z"
                fill="#0078d7"
            />
            <path
                d="m351.4 159.8c0 1.5 0 2.9-.1 4.4-11.6-2.5-23.7-4.5-36.2-6.1-.9-32.3-27.3-58.2-59.8-58.2s-59 25.9-59.8 58.2c-12.5 1.6-24.6 3.6-36.2 6.1-.1-1.5-.1-3-.1-4.5 0-53.1 43-96.2 96.2-96.2 52.9.2 96 43.2 96 96.3zm-95.7 288.4c169.8-71.4 143.9-253.2 143.9-253.2-39.6-14.8-89.6-23.6-144.1-23.6s-104.5 8.8-144.1 23.6c0 0-25.9 181.8 143.9 253.2z"
                fill="#fff"
            />
            <path
                d="m302.5 333.7c0 2.1-.1 4.1-.4 6.1h-93.3c-.3-2-.4-4-.4-6.1 0-21.2 14-39.1 33.3-45-13-5.4-22.2-18.2-22.2-33.2 0-19.9 16.1-35.9 35.9-35.9s35.9 16.1 35.9 35.9c0 15-9.2 27.8-22.2 33.2 19.4 5.8 33.4 23.8 33.4 45z"
                fill="#0078d7"
            />
        </svg>
    );
}

export default React.memo(MicrosoftAuthSVG);
