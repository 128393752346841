import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import SidebarRightLayout from '../layout/rightSidebar';
import Search from 'components/search';
import QuickFilterSVG from 'assets/images/svg/QuickFilterSVG';
import { useTranslation } from 'react-i18next';
import PopperMenu from 'components/menus/Popper';
import FilterMenu from './FilterMenu';
import { useGetLQAModelDefault, useLQAHook } from 'hooks/gridUI/lqa';
import TicketTabs from './TicketTabs';
import ExportSVG from 'assets/images/svg/ExportSVG';
import { useDispatch } from 'react-redux';
import { handleExportLQA } from 'gridUI/actions';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import ButtonBase from 'components/button/Base';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import TicketForm from './TicketForm';
import ArrowLeftSVG from 'assets/images/svg/ArrowLeftSVG';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        height: '100%'
    },
    expand: {
        flex: 1
    },
    search: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    filterButton: {
        background: theme.colors.paleGrey,
        borderRadius: 4,
        padding: 10,
        '&.active': {
            background: theme.colors.paleNavy
        }
    },
    addIconSVG: {
        position: 'absolute'
    },
    viewAll: {
        padding: '30px 24px 0',
        cursor: 'pointer'
    },
    arrowLeft: {
        verticalAlign: 'text-top'
    },
    viewAllContent: {
        color: theme.colors.highlight
    },
    badgeDot: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: theme.colors.burntSlena,
        border: `1px solid ${theme.colors.solitude}`,
        position: 'absolute',
        right: 0,
        top: 0
    },
    relative: {
        position: 'relative'
    }
}));

function LQA({ handleClose }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addingTicket, setAddingTicket] = React.useState(false);
    const lqaModelDefault = useGetLQAModelDefault();
    const dispatch = useDispatch();
    const roles = useRole();
    const accessManageTicket = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_TICKET];

    const {
        filterValue,
        handleChangeFilter,
        tab,
        lqaTabs,
        queryTickets,
        handleChangeTab,
        query,
        activeQuery,
        handleQueryChange,
        isLoading,
        handleFetchMore,
        isLoadingMore
    } = useLQAHook();

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const menuOptions = React.useMemo(() => {
        return [
            {
                name: t('global_export_ticket'),
                icon: <ExportSVG />,
                onClick: e => {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(handleExportLQA());
                    sendManualTrack({ type: 'Export Ticket' });
                }
            }
        ];
    }, [dispatch, t]);

    const handleClickAdd = React.useCallback(() => {
        setAddingTicket(true);
        sendManualTrack({ type: `Open Add Ticket in Ticket Center` });
    }, []);

    const handleCloseAdd = React.useCallback(() => {
        setAddingTicket(false);
    }, []);

    const isReadOnly = React.useMemo(() => {
        return accessManageTicket !== roleConst.FULL;
    }, [accessManageTicket]);

    return (
        <SidebarRightLayout
            id="sidebar-right-content-lqa"
            title={t('right_toolbar_lqa_tooltip')}
            onClose={handleClose}
            showMenuOption={!isReadOnly}
            menuOptions={menuOptions}
        >
            {!addingTicket ? (
                <Grid container className={classes.root} direction="column" wrap="nowrap">
                    <Grid item className={classes.search}>
                        <Grid container alignItems="center" direction="row" spacing={2}>
                            <Grid item className={classes.expand}>
                                <Search
                                    value={filterValue}
                                    onChange={e => handleChangeFilter(e.target.value)}
                                    onClear={() => handleChangeFilter('')}
                                    background={theme.colors.paleGrey}
                                    placeholder={t('lqa_search_placeholder')}
                                />
                            </Grid>
                            <Grid item className={classes.relative}>
                                <IconButton
                                    onClick={handleClick}
                                    className={`${classes.filterButton} ${activeQuery || anchorEl ? 'active' : ''}`}
                                    style={{
                                        background: '',
                                        position: 'relative'
                                    }}
                                >
                                    <QuickFilterSVG
                                        color={
                                            activeQuery || anchorEl ? theme.colors.lightLavender : theme.colors.darkness
                                        }
                                    />
                                </IconButton>
                                {activeQuery && <div className={classes.badgeDot} />}
                                {anchorEl && (
                                    <PopperMenu
                                        className={classes.commentPopup}
                                        anchorEl={anchorEl}
                                        placement={'bottom-end'}
                                        id={`filter-comment-menu`}
                                    >
                                        <FilterMenu
                                            query={query}
                                            onQueryChange={handleQueryChange}
                                            handleClickAway={handleClickAway}
                                            lqaModelDefault={lqaModelDefault}
                                        />
                                    </PopperMenu>
                                )}
                            </Grid>
                            {!isReadOnly && (
                                <Grid item>
                                    <ButtonBase width={36} onClick={handleClickAdd} variant="contained">
                                        <AddIconSVG className={classes.addIconSVG} color={theme.colors.white} />
                                    </ButtonBase>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <TicketTabs
                        tabs={lqaTabs}
                        tab={tab}
                        handleChangeTab={handleChangeTab}
                        queryTickets={queryTickets}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        fetchMore={handleFetchMore}
                    />
                </Grid>
            ) : (
                <Grid item className={classes.root} direction="column">
                    <Grid container spacing={2} className={classes.viewAll} onClick={handleCloseAdd}>
                        <Grid item>
                            <ArrowLeftSVG className={classes.arrowLeft} color={theme.colors.dodgerBlue} />
                        </Grid>
                        <Grid item>
                            <p className="body2 text-highlight">{t('view_all_tickets')}</p>
                        </Grid>
                    </Grid>
                    <TicketForm isRightSidebar onAdded={handleCloseAdd} />
                </Grid>
            )}
        </SidebarRightLayout>
    );
}

export default LQA;
