import React, { memo, useCallback, useMemo, useRef } from 'react';
import { areEqual, FixedSizeList as List } from 'react-window';
import StyledTreeItem from './StyledTreeItem2';

const SpeedTree = ({
    t,
    data,
    nodeIdShowPopup,
    isShareViewLink,
    isCanEditPathTag,
    isJsonOrPoGrid,
    openedNodeIds,
    setOpenedNodeIds,
    width,
    height
}) => {
    const listRef = useRef();

    const flattenNode = useCallback(
        (node, depth, result) => {
            const { id, label, children, name } = node;
            let collapsed = !openedNodeIds.includes(id);
            result.push({
                id,
                label: label || name,
                depth,
                ...node
            });

            if (!collapsed && children) {
                for (let child of children) {
                    flattenNode(child, depth + 1, result);
                }
            }
        },
        [openedNodeIds]
    );

    const flattenOpened = useCallback(
        data => {
            const result = [];
            for (let node of data) {
                flattenNode(node, 0, result);
            }
            return result;
        },
        [flattenNode]
    );

    const flattenedData = useMemo(() => {
        return flattenOpened(data);
    }, [flattenOpened, data]);

    const onOpen = useCallback(
        node => {
            if (!openedNodeIds?.includes(node.id)) {
                setOpenedNodeIds(prev => [...prev, node.id]);
            }
        },
        [openedNodeIds, setOpenedNodeIds]
    );

    const onToggle = useCallback(
        node => () => {
            !openedNodeIds?.includes(node.id)
                ? setOpenedNodeIds(prev => [...prev, node.id])
                : setOpenedNodeIds(prev => prev.filter(id => id !== node.id));
        },
        [openedNodeIds, setOpenedNodeIds]
    );

    const scrollToIndex = useCallback(index => {
        listRef.current.scrollToItem(index, 'smart');
    }, []);

    const Row = memo(({ index, style }) => {
        const node = flattenedData[index];
        return (
            <div key={node.id} style={style}>
                <StyledTreeItem
                    level={node.depth}
                    node={node}
                    nodeId={node.id}
                    labelText={node.name}
                    color={node?.customProperties?.color}
                    parentIdPath={node?.parentIdPath}
                    collapsed={!openedNodeIds?.includes(node.id)}
                    onOpen={onOpen}
                    onToggle={onToggle(node)}
                    nodeIdShowPopup={nodeIdShowPopup}
                    isShareViewLink={isShareViewLink}
                    t={t}
                    isCanEditPathTag={isCanEditPathTag}
                    isJsonOrPoGrid={isJsonOrPoGrid}
                />
            </div>
        );
    }, areEqual);

    React.useEffect(() => {
        if (!!nodeIdShowPopup) {
            const index = flattenedData.findIndex(node => node.id === nodeIdShowPopup);
            if (index) {
                scrollToIndex(index);
            }
        }
    }, [nodeIdShowPopup, scrollToIndex, flattenedData]);

    return (
        <List
            ref={listRef}
            className="List scrollbar-app"
            height={height}
            itemCount={flattenedData.length}
            itemSize={39}
            width={width}
            itemKey={index => flattenedData[index].id}
        >
            {Row}
        </List>
    );
};

export default React.memo(SpeedTree);
