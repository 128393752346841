import React from 'react';

function OrganizationSettingSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M9.47981 8.79846V1.44972C9.47981 1.19988 9.27407 1 9.01689 1C8.75971 1 8.55396 1.19988 8.55396 1.44972V8.79846C7.48066 9.00833 6.66797 9.93108 6.66797 11.0371C6.66797 12.143 7.48066 13.0658 8.55396 13.2757V16.547C8.55396 16.7968 8.75971 16.9967 9.01689 16.9967C9.27407 16.9967 9.47981 16.7968 9.47981 16.547V13.2757C10.5531 13.0658 11.3658 12.143 11.3658 11.0371C11.3658 9.93442 10.5565 9.00833 9.47981 8.79846ZM9.01689 12.4195C8.23163 12.4195 7.59382 11.7999 7.59382 11.0371C7.59382 10.2742 8.23163 9.65459 9.01689 9.65459C9.80214 9.65459 10.44 10.2742 10.44 11.0371C10.44 11.7999 9.80214 12.4195 9.01689 12.4195Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
            <path
                d="M3.81184 5.02748V1.44972C3.81184 1.19988 3.6061 1 3.34892 1C3.09174 1 2.88599 1.19988 2.88599 1.44972V5.02748C1.81269 5.23735 1 6.16011 1 7.26608C1 8.37206 1.81269 9.29482 2.88599 9.50469V16.547C2.88599 16.7968 3.09174 16.9967 3.34892 16.9967C3.6061 16.9967 3.81184 16.7968 3.81184 16.547V9.50135C4.88514 9.29148 5.69783 8.36873 5.69783 7.26275C5.69783 6.15678 4.88514 5.23735 3.81184 5.02748ZM3.34892 8.64855C2.56366 8.64855 1.92585 8.02894 1.92585 7.26608C1.92585 6.50323 2.56366 5.88361 3.34892 5.88361C4.13418 5.88361 4.77198 6.50323 4.77198 7.26608C4.77198 8.02894 4.13075 8.64855 3.34892 8.64855Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
            <path
                d="M15.1136 5.02748V1.44972C15.1136 1.19988 14.9079 1 14.6507 1C14.3935 1 14.1877 1.19988 14.1877 1.44972V5.02748C13.1144 5.23735 12.3018 6.16011 12.3018 7.26608C12.3018 8.37206 13.1144 9.29482 14.1877 9.50469V16.5503C14.1877 16.8001 14.3935 17 14.6507 17C14.9079 17 15.1136 16.8001 15.1136 16.5503V9.50135C16.1869 9.29148 16.9996 8.36873 16.9996 7.26275C16.9996 6.15678 16.1903 5.23735 15.1136 5.02748ZM14.6507 8.64855C13.8654 8.64855 13.2276 8.02894 13.2276 7.26608C13.2276 6.50323 13.8654 5.88361 14.6507 5.88361C15.4359 5.88361 16.0737 6.50323 16.0737 7.26608C16.0737 8.02894 15.4359 8.64855 14.6507 8.64855Z"
                fill={color}
                stroke={color}
                strokeWidth="0.5"
            />
        </svg>
    );
}

export default React.memo(OrganizationSettingSVG);
