import React from 'react';

const GridIconSVG = ({ color = '#79778B', ...rest }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M16.646 0H3.22981C1.36646 0 0 1.375 0 3.25V16.875C0 18.5 1.36646 20 3.22981 20H16.7702C18.5093 20 20 18.625 20 16.75V3.25C19.8758 1.375 18.3851 0 16.646 0ZM3.22981 1.875H16.7702C17.5155 1.875 18.1366 2.5 18.1366 3.25V5.375H1.98758V3.25C1.86335 2.5 2.48447 1.875 3.22981 1.875ZM1.86335 16.75V7.125H6.3354V18.125H3.22981C2.48447 18.125 1.86335 17.5 1.86335 16.75ZM16.646 18.125H8.19876V7.125H18.0124V16.75C18.0124 17.5 17.3913 18.125 16.646 18.125Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(GridIconSVG);
