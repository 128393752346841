import React from 'react';

function EditIconSVG({ color = 'white', ...other }) {
    return (
        <svg {...other} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.46137 2.04716C5.41755 1.97935 5.35833 1.9221 5.28819 1.87975C5.03336 1.72587 4.69774 1.80082 4.53856 2.04716L1.08262 7.39528C1.02863 7.47884 1 7.57538 1 7.6739C1 7.96435 1.24357 8.1998 1.54403 8.1998H8.4559C8.55782 8.1998 8.65768 8.17213 8.74412 8.11993C8.99895 7.96606 9.07648 7.64162 8.9173 7.39528L5.46137 2.04716ZM4.66805 4.13096C4.69969 4.08397 4.74246 4.04429 4.79311 4.01493C4.97713 3.90828 5.21949 3.96023 5.33443 4.13096L6.97032 6.56073C6.98982 6.58968 7.00016 6.62314 7.00016 6.65728C7.00016 6.75793 6.91221 6.83953 6.80373 6.83953H3.55306C3.47947 6.83953 3.40735 6.82035 3.34493 6.78417C3.16092 6.67752 3.10493 6.45266 3.21987 6.28193L4.66805 4.13096Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(EditIconSVG);
