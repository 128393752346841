import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_RADIUS, INPUT_PADDING_LEFT } from 'const/style';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import RolePopup from './RolePopup';
import { useDispatch } from 'react-redux';
import * as roleActions from '../actions/role';
import { changeContext } from 'app/actions';
import PopperMenu from 'components/menus/Popper';
import SystemRoleFlag from './SystemRoleFlag';
import ConfirmDeleteRoleBox from './ConfirmDeleteRoleBox';
import ModalBase from 'components/modal/Base';
import { useHistory } from 'react-router-dom';
import { sendManualTrack } from 'tracker';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
            background: theme.colors.hoverItem
        }
    },
    listItem: {
        height: 42,
        borderRadius: INPUT_RADIUS,
        paddingLeft: INPUT_PADDING_LEFT,
        paddingRight: INPUT_PADDING_LEFT
    },
    active: {
        background: theme.colors.hoverItem
    },
    name: {
        fontSize: '0.875rem',
        color: theme.colors.dimGrey
    },
    dropDownIcon: {
        display: 'flex',
        cursor: 'pointer'
    },
    roleName: {
        width: 180,

        [theme.breakpoints.down('xs')]: {
            width: 100
        }
    }
}));

function RoleItem({ role, active, handleItemClick, isShowLastPopupCreate, contextMenuId, isScrolling }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [isShowMore, setIsShowMore] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = React.useState(false);
    const history = useHistory();

    const closeConfirmDeleteModalHandler = () => {
        setOpenConfirmDeleteModal(false);
    };

    const openConfirmDeleteModalHandler = e => {
        handleClickAway();
        setOpenConfirmDeleteModal(true);
    };

    const isSystemRole = React.useMemo(() => {
        return role?.isSystemRole;
    }, [role]);

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `ROLE_CONTEXT_MENU_ID_${role.id}`;
    }, [role]);

    const handleClick = event => {
        stopPropagation(event);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setIsShowMore(false);
        setAnchorEl(null);
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDeleteHandler = e => {
        stopPropagation(e);
        openConfirmDeleteModalHandler();
    };

    const cancelHandler = e => {
        stopPropagation(e);
        closeConfirmDeleteModalHandler();
    };

    React.useEffect(() => {
        if (isShowMore && isShowLastPopupCreate) {
            let $arrow = document.getElementById(`role_more_${role.id}`);
            if ($arrow) {
                $arrow.click();
                dispatch(roleActions.turnOffLastRolePopup());
            }
        }
    }, [isShowMore, isShowLastPopupCreate, role.id, dispatch]);

    React.useEffect(() => {
        if (isShowLastPopupCreate) {
            setIsShowMore(true);
        }
    }, [isShowLastPopupCreate]);

    React.useEffect(() => {
        if ((contextMenuId && contextMenuId !== CONTEXT_MENU_ID) || isScrolling) {
            handleClickAway();
        }
    }, [contextMenuId, CONTEXT_MENU_ID, isScrolling]);

    const handleOnContextClick = e => {
        if (!isSystemRole) {
            dispatch(changeContext({ contextMenuId: CONTEXT_MENU_ID }));
            handleClick(e);
        }
    };

    const handleDeleteRole = e => {
        stopPropagation(e);
        sendManualTrack({
            type: `Delete Company Role`,
            customData: {
                roleId: role?.id
            }
        });
        dispatch(
            roleActions.deleteRole({
                roleId: role.id,
                successCallback: firstRole => {
                    history.push(`company-settings/roles/${firstRole?.id}`);
                },
                errorCallback: error => {
                    console.log('error delete');
                }
            })
        );
        handleClickAway();
    };

    return (
        <Grid
            item
            onMouseOver={() => setIsShowMore(true)}
            onMouseLeave={() => setIsShowMore(false)}
            className={classes.root}
            onClick={() => handleItemClick(role.id)}
            onContextMenu={handleOnContextClick}
        >
            <Grid
                className={`${classes.listItem} ${active ? classes.active : ''}`}
                container
                justify="space-between"
                alignItems="center"
            >
                <Grid item className={classes.name}>
                    <Grid container spacing={2} direction="row" alignItems="center">
                        {isSystemRole && (
                            <Grid item style={{ display: 'flex' }}>
                                <SystemRoleFlag role={role} />
                            </Grid>
                        )}
                        <Grid item className={classes.roleName}>
                            <Typography noWrap variant={active ? 'body1' : 'body2'}>
                                {role?.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {!isSystemRole && (
                    <Grid id={`role_more_${role.id}`} item className={classes.dropDownIcon} onClick={handleClick}>
                        {(isShowMore || anchorEl) && <IconMoreActionsSVG color={theme.colors.steel} />}
                    </Grid>
                )}
            </Grid>
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl}>
                    <RolePopup role={role} handleClickAway={handleClickAway} onDeleteRole={onDeleteHandler} />
                </PopperMenu>
            )}
            <ModalBase open={openConfirmDeleteModal} handleClose={cancelHandler}>
                <ConfirmDeleteRoleBox
                    roleName={role?.name}
                    handleCancel={cancelHandler}
                    handleAgreed={handleDeleteRole}
                />
            </ModalBase>
        </Grid>
    );
}

export default React.memo(RoleItem);
