import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import { GRID_COLOR_OPTIONS } from 'utils/color';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { isKbEscape, isKbEnter } from 'utils/keyboard';
import InputText from 'components/inputs/InputText';
import { GRID_PALETTE } from 'const/paletteTypes';
import Palette from 'layout/Palette';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import ListItem from 'components/list/Item';
import * as gridUIActions from '../actions';
import { useDispatch } from 'react-redux';
import trim from 'lodash/trim';
import { sendManualTrack } from 'tracker';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { checkContainId } from 'utils/clickAway';
import { COLOR_TYPES } from 'const';

const useStyles = makeStyles(theme => ({
    root: {
        width: 260
    },
    body: {
        paddingLeft: 8,
        paddingRight: 8
    },
    colorPallet: {
        padding: 8
    }
}));

function TreeEditPopup({ name, color, nodeId, node, handleClickAway, t }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const rootRef = React.useRef();
    const [nodeName, setNodeName] = React.useState(name);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);

    const parentNodeId = React.useMemo(() => {
        const arr = node?.parentIdPath?.split('/');
        return arr?.[arr?.length - 1];
    }, [node]);

    useClickAwaitListener(rootRef, e => {
        if (checkContainId(e, 'dialog-delete-path')) return;
        clickAwayHandler(e);
    });

    const clickAwayHandler = () => {
        if (trim(nodeName) === '') {
            handleClickAway();
            return;
        }

        if (nodeName === name) {
            handleClickAway();
            return;
        }

        dispatch(
            gridUIActions.updateNode({
                nodeId,
                parentNodeId,
                newNode: {
                    ...node,
                    name: nodeName
                },
                oldNode: node,
                successCallback: () => {
                    console.log('update node success');
                },
                errorCallback: () => {
                    console.log('update node failed');
                }
            })
        );
        handleClickAway();
    };

    const stopPropagation = e => {
        e.stopPropagation();
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            handleClickAway();
            return;
        }
        if (!isKbEnter(e)) return;
        clickAwayHandler();
    };
    const changeColorHandler = color => {
        dispatch(
            gridUIActions.updateNode({
                nodeId,
                parentNodeId,
                newNode: {
                    ...node,
                    customProperties: {
                        color: color?.value
                    }
                },
                oldNode: node,
                successCallback: () => {
                    console.log('update node success');
                },
                errorCallback: () => {
                    console.log('update node failed');
                }
            })
        );
    };

    const handleOpenConfirmDelete = e => {
        stopPropagation(e);
        setOpenConfirmDelete(true);
    };

    const handleCloseConfirmDelete = e => {
        stopPropagation(e);
        setOpenConfirmDelete(false);
    };

    const deleteNodeHandler = e => {
        stopPropagation(e);
        sendManualTrack({
            type: `Delete Path`,
            customData: {
                nodeId
            }
        });
        setConfirming(true);
        dispatch(
            gridUIActions.deleteNode({
                nodeId,
                successCallback: () => {
                    console.log('delete node success');
                    setConfirming(false);
                    handleClickAway();
                },
                errorCallback: () => {
                    console.log('delete node failed');
                    setConfirming(false);
                }
            })
        );
    };

    return (
        <Grid ref={rootRef} container direction="column" className={classes.root}>
            <Grid item container direction="column" className={classes.body}>
                <Grid item>
                    <InputText
                        id={`gridInput`}
                        name="nodeName"
                        value={nodeName}
                        onChange={e => setNodeName(e.target.value)}
                        placeholder="Path"
                        onFocus={stopPropagation}
                        onKeyDown={handleKeyDown}
                        onClick={stopPropagation}
                        autoFocus
                        className={classes.input}
                        autoSelect={true}
                    />
                </Grid>
                <Palette
                    className={classes.colorPallet}
                    value={color}
                    options={GRID_COLOR_OPTIONS}
                    onSelect={changeColorHandler}
                    type={GRID_PALETTE}
                    onClick={stopPropagation}
                />
            </Grid>

            <Grid item>
                <Divider />
            </Grid>
            <Grid item onClick={handleOpenConfirmDelete}>
                <ListItem icon={<DeleteSVG />} name={t(`global_delete`)} />
            </Grid>
            <Dialog
                id="dialog-delete-path"
                open={openConfirmDelete}
                onClose={handleCloseConfirmDelete}
                onClick={stopPropagation}
                style={{ zIndex: 1301 }}
            >
                <ConfirmBox
                    title={'Delete path'}
                    body={<p className="body2">Do you want to permanently delete this path?</p>}
                    handleCancel={handleCloseConfirmDelete}
                    onClose={handleCloseConfirmDelete}
                    handleAgreed={deleteNodeHandler}
                    agreeLabel="Confirm"
                    isLoading={confirming}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
        </Grid>
    );
}

export default React.memo(TreeEditPopup);
