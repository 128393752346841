import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LQA_TICKET_STATUS } from 'const/gridUI';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLQATicketsTotal,
    resetDefaultLQA,
    setLqaPageNumber,
    setLqaResetQuery,
    setLqaTab,
    setLqaTicket
} from 'gridUI/actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function useGetLQAModels() {
    return useSelector(state => state.gridUI.lqaModels) || [];
}

export function useGetLQAModelDefault() {
    return useSelector(state => state.gridUI.lqaModelDefault);
}

export function useGetOpenLQATicketDetail() {
    return useSelector(state => state.gridUI.openLQATicketDetail);
}

export function useGetLQATicketDetail() {
    return useSelector(state => state.gridUI.lqaTicketDetail) || {};
}

export function useIsOpenedLQATickets() {
    const totalOpenedTicket = useGetLQATotalByStatus(LQA_TICKET_STATUS.OPEN);
    return totalOpenedTicket > 0;
}

export function useGetLQAConfirmDeleteTicket() {
    return useSelector(state => state.gridUI.confirmDeleteTicket) || {};
}

export function checkIsTicketOpen(status) {
    return [LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.REOPENED].includes(status);
}

export function useGetLQATotal() {
    return useSelector(state => state.gridUI.lqaTotal) || {};
}

export function useGetLQATotalByStatus(status) {
    return useSelector(state => state.gridUI.lqaTotal?.[status]) || 0;
}

export function useGetLQAResetQuery() {
    return useSelector(state => state.gridUI.lqaResetQuery);
}

export function useGetLQATicket() {
    return useSelector(state => state.gridUI.lqaTicket) || {};
}

export function useGetLQATicketByStatus(status) {
    return useSelector(state => state.gridUI.lqaTicket?.[status]) || [];
}

export function useGetLQAPageNumber() {
    return useSelector(state => state.gridUI.lqaPageNumber) || {};
}

export function useGetLqaTab() {
    return useSelector(state => state.gridUI.lqaTab);
}

export function useGetLQAExporting() {
    return useSelector(state => state.gridUI.lqaExporting);
}

export function useGetLQACreateType() {
    return useSelector(state => state.gridUI.lqaCreateType);
}

const defaultQuery = {
    author: { value: '', label: 'All' },
    assignee: { value: '', label: 'All' },
    ticketType: { value: '', label: 'All' },
    category: null,
    severity: null,
    subCategory: null,
    sort: 'asc'
};

export function useLQAHook() {
    const { viewId, dbId } = useParams();
    const { t } = useTranslation();
    const tab = useGetLqaTab();
    const [filterValue, setFilterValue] = useState('');
    const lqaPageNumber = useGetLQAPageNumber();
    const [loading, setLoading] = useState({
        [LQA_TICKET_STATUS.OPEN]: false,
        [LQA_TICKET_STATUS.RESOLVED]: false,
        [LQA_TICKET_STATUS.ALL]: false
    });
    const [loadingMore, setLoadingMore] = useState({
        [LQA_TICKET_STATUS.OPEN]: false,
        [LQA_TICKET_STATUS.RESOLVED]: false,
        [LQA_TICKET_STATUS.ALL]: false
    });
    const [query, setQuery] = useState({ ...defaultQuery });
    const dispatch = useDispatch();
    const lqaTotal = useGetLQATotal();
    const lqaTicketByStatus = useGetLQATicketByStatus(tab);
    const lqaResetQuery = useGetLQAResetQuery();
    const timerRef = useRef();
    const timerFetchRef = useRef();

    const resetPageNumber = useCallback(() => {
        dispatch(
            setLqaPageNumber({
                [LQA_TICKET_STATUS.OPEN]: 1,
                [LQA_TICKET_STATUS.RESOLVED]: 1,
                [LQA_TICKET_STATUS.ALL]: 1
            })
        );
    }, [dispatch]);

    const resetTickets = useCallback(() => {
        setLoading({
            [LQA_TICKET_STATUS.OPEN]: false,
            [LQA_TICKET_STATUS.RESOLVED]: false,
            [LQA_TICKET_STATUS.ALL]: false
        });
        dispatch(
            setLqaTicket({
                [LQA_TICKET_STATUS.OPEN]: [],
                [LQA_TICKET_STATUS.RESOLVED]: [],
                [LQA_TICKET_STATUS.ALL]: []
            })
        );
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetDefaultLQA({ total: false }));
            dispatch(
                fetchLQATicketsTotal({
                    dbId,
                    viewId,
                    params: {
                        status: [LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.REOPENED],
                        pageSize: 1
                    },
                    totalKey: LQA_TICKET_STATUS.OPEN,
                    storeOnlyTotal: true
                })
            );
        };
    }, [dispatch, dbId, viewId]);

    useEffect(() => {
        if (lqaResetQuery) {
            setFilterValue('');
            resetPageNumber();
            resetTickets();
            setQuery({ ...defaultQuery });
            dispatch(setLqaResetQuery(false));
        }
    }, [dispatch, lqaResetQuery, resetPageNumber, resetTickets]);

    useEffect(() => {
        resetPageNumber();
        resetTickets();
    }, [query, resetPageNumber, resetTickets]);

    const fetchTickets = useCallback(
        ({ defaultParams = {}, status, appending, onSuccess, onError }) => {
            const handleLoading = appending ? setLoadingMore : setLoading;
            handleLoading(prev => ({ ...prev, [status]: true }));

            dispatch(
                fetchLQATicketsTotal({
                    dbId,
                    viewId,
                    params: {
                        pageSize: 20,
                        keyword: filterValue,
                        createdById: query.author?.value || undefined,
                        assigneeId: query.assignee?.value || undefined,
                        type: query.ticketType?.value || undefined,
                        lqaModelCategoryId: query.category?.value || undefined,
                        lqaModelSubCategoryId: query.subCategory?.value || undefined,
                        lqaModelSeverityId: query.severity?.value || undefined,
                        sortByOldest: query.sort !== 'asc',
                        ...defaultParams,
                        ...([LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.RESOLVED].includes(status)
                            ? {
                                  status:
                                      status === LQA_TICKET_STATUS.OPEN
                                          ? [LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.REOPENED]
                                          : status
                              }
                            : {})
                    },
                    totalKey: status,
                    appending,
                    successCallback: data => {
                        handleLoading(prev => ({ ...prev, [status]: false }));
                        onSuccess && onSuccess(data);
                    },
                    errorCallback: () => {
                        handleLoading(prev => ({ ...prev, [status]: false }));
                        onError && onError();
                    }
                })
            );
        },
        [dispatch, filterValue, query, dbId, viewId]
    );

    useEffect(() => {
        if (timerFetchRef.current) clearTimeout(timerFetchRef.current);
        timerFetchRef.current = setTimeout(() => {
            if (Object.values(lqaPageNumber).some(n => n === 1)) {
                [LQA_TICKET_STATUS.OPEN, LQA_TICKET_STATUS.RESOLVED, LQA_TICKET_STATUS.ALL].map(status => {
                    if (lqaPageNumber[status] === 1) {
                        lqaPageNumber[status] = 2;
                        fetchTickets({ status });
                    }
                    return false;
                });
                dispatch(setLqaPageNumber({ ...lqaPageNumber }));
            }
        }, 0);
    }, [dispatch, lqaPageNumber, fetchTickets]);

    const lqaTabs = useMemo(() => {
        return [
            { label: `${t('global_open')} (${lqaTotal[LQA_TICKET_STATUS.OPEN]})`, value: LQA_TICKET_STATUS.OPEN },
            {
                label: `${t('global_resolved')} (${lqaTotal[LQA_TICKET_STATUS.RESOLVED]})`,
                value: LQA_TICKET_STATUS.RESOLVED
            },
            { label: `${t('global_all')} (${lqaTotal[LQA_TICKET_STATUS.ALL]})`, value: LQA_TICKET_STATUS.ALL }
        ];
    }, [lqaTotal, t]);

    const isLoading = useMemo(() => {
        return loading[tab];
    }, [loading, tab]);

    const isLoadingMore = useMemo(() => {
        return loadingMore[tab];
    }, [loadingMore, tab]);

    const handleChangeTab = useCallback(
        (e, value) => {
            dispatch(setLqaTab(value));
        },
        [dispatch]
    );

    const handleQueryChange = useCallback(
        changedQuery => {
            setQuery({ ...query, ...changedQuery });
        },
        [query]
    );

    const handleChangeFilter = useCallback(
        value => {
            setFilterValue(value);
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                resetPageNumber();
                resetTickets();
            }, 800);
        },
        [resetPageNumber, resetTickets]
    );

    const handleFetchMore = useCallback(() => {
        if (loading[tab]) return;
        const defaultParams = { pageNumber: lqaPageNumber[tab] };
        fetchTickets({
            defaultParams,
            status: tab,
            appending: true,
            onSuccess: data => {
                if (data?.length) {
                    dispatch(setLqaPageNumber({ [tab]: lqaPageNumber[tab] + 1 }));
                }
            }
        });
    }, [dispatch, fetchTickets, lqaPageNumber, tab, loading]);

    const activeQuery = useMemo(() => {
        return (
            query?.author?.value ||
            query?.assignee?.value ||
            query?.ticketType?.value ||
            query?.category ||
            query?.severity ||
            query?.subCategory ||
            query?.sort !== 'asc'
        );
    }, [query]);

    return {
        filterValue,
        handleChangeFilter,
        tab,
        lqaTabs,
        queryTickets: lqaTicketByStatus,
        handleChangeTab,
        query,
        activeQuery,
        handleQueryChange,
        isLoading,
        handleFetchMore,
        isLoadingMore
    };
}
