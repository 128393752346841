import React from 'react';
import {
    OBJECT_TYPES,
    WEB_HOOK_EVENT_TYPES,
    WEB_HOOK_TRIGGERS_ACTIONS_LABEL,
    WEB_HOOK_TRIGGER_ACTIONS
} from './gridUI';
import WebHookLabel from 'gridUI/webHook/components/WebHookLabel';
import store from 'store/configStore';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { serverSpacerSymbol, serverTriggerSymbol, TRIGGER_MATCH_STRING_SERVER_REGEX } from 'gridUI/automations/const';

const { getState } = store;

export const WEB_HOOK_TRIGGER_ACTIONS_OPTIONS = [
    {
        label: 'Record',
        options: [
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED],
                subTitle: 'Rule run when a record is created on current grid',
                objectType: OBJECT_TYPES.RECORD,
                eventType: WEB_HOOK_EVENT_TYPES.CREATE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED],
                subTitle: 'Rule run when a record is updated on current grid',
                objectType: OBJECT_TYPES.RECORD,
                eventType: WEB_HOOK_EVENT_TYPES.UPDATE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED],
                subTitle: 'Rule run when a record is deleted on current grid',
                objectType: OBJECT_TYPES.RECORD,
                eventType: WEB_HOOK_EVENT_TYPES.DELETE
            }
        ]
    },
    {
        label: 'Column',
        options: [
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED],
                subTitle: 'Rule run when a column is created on current grid',
                objectType: OBJECT_TYPES.COLUMN,
                eventType: WEB_HOOK_EVENT_TYPES.CREATE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED],
                subTitle: 'Rule run when a column is updated on current grid',
                objectType: OBJECT_TYPES.COLUMN,
                eventType: WEB_HOOK_EVENT_TYPES.UPDATE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED],
                subTitle: 'Rule run when a column is deleted on current grid',
                objectType: OBJECT_TYPES.COLUMN,
                eventType: WEB_HOOK_EVENT_TYPES.DELETE
            }
        ]
    },
    {
        label: 'Tickets',
        options: [
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED],
                subTitle: 'Trigger run when tickets are created on current grid',
                objectType: OBJECT_TYPES.TICKET,
                eventType: WEB_HOOK_EVENT_TYPES.CREATE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED],
                subTitle: 'Trigger run when tickets are updated on current grid',
                objectType: OBJECT_TYPES.TICKET,
                eventType: WEB_HOOK_EVENT_TYPES.UPDATE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED],
                subTitle: 'Trigger run when tickets are deleted on current grid',
                objectType: OBJECT_TYPES.TICKET,
                eventType: WEB_HOOK_EVENT_TYPES.DELETE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED],
                subTitle: 'Trigger run when tickets are resolved or reopened',
                objectType: OBJECT_TYPES.TICKET,
                eventType: WEB_HOOK_EVENT_TYPES.STATUS_CHANGE
            },
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED],
                subTitle: 'Trigger run when comments are added in a ticket',
                objectType: OBJECT_TYPES.COMMENT,
                eventType: WEB_HOOK_EVENT_TYPES.CREATE
            }
        ]
    },
    {
        label: 'Manual',
        options: [
            {
                value: WEB_HOOK_TRIGGER_ACTIONS.MANUAL,
                icon: <WebHookLabel type={WEB_HOOK_TRIGGER_ACTIONS.MANUAL} />,
                label: WEB_HOOK_TRIGGERS_ACTIONS_LABEL[WEB_HOOK_TRIGGER_ACTIONS.MANUAL],
                subTitle: 'Trigger run when the button is clicked on trigger list',
                objectType: OBJECT_TYPES.VIEW,
                eventType: WEB_HOOK_EVENT_TYPES.MANUAL
            }
        ]
    }
];

export const generateTemplate = event => {
    switch (event) {
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_CREATED:
            // eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* created a record. \\nContent: ${rawData.cells[0].value}. \\nGrid name: ${grid.name}. \\nView name: ${view.name}. \\nRecord ID: ${rawData.id}. \\nColumn ID: ${rawData.cells[0].columnId}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED:
            // eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* updated a record. \\nContent: ${rawData.cells[0].value}. \\nGrid name: ${grid.name}. \\nView name: ${view.name}. \\nRecord ID: ${rawData.id}. \\nColumn ID: ${rawData.cells[0].columnId}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_DELETED:
            // eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* deleted a record. \\nGrid name: ${grid.name}. \\nView name: ${view.name}. \\nRecord ID: ${rawData.id}."\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.COLUMN_CREATED:
            // eslint-disable-next-line
            return '{\n    "text":"${user.fullName}* created a new column. \\nColumn Name: ${rawData.name}. \\nColumn ID: ${rawData.id}. \\nColumn Type: ${rawData.type}. \\nGrid name: ${grid.name}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.COLUMN_UPDATED:
            // eslint-disable-next-line
            return '{\n    "text":"${user.fullName}* updated a column. \\nColumn Name: ${rawData.name}. \\nColumn ID: ${rawData.id}. \\nColumn Type: ${rawData.type}. \\nGrid name: ${grid.name}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.COLUMN_DELETED:
            // eslint-disable-next-line
            return '{\n    "text":"${user.fullName}* deleted a column. \\nColumn Name: ${rawData.name}. \\nColumn ID: ${rawData.id}. \\nColumn Type: ${rawData.type}. \\nGrid name: ${grid.name}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.COMMENT_CREATED:
            // eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* created a comment. \\nContent: ${rawData.content}. \\nGrid name: ${rawData.grid.name}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED:
            //eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* created *Ticket-${rawData.id}*. \\nType: ${rawData.type}. Priority: ${rawData.priority}. Status: ${rawData.status}. \\nSummary: ${rawData.summary}. \\nGrid name: ${grid.name}. \\nRecord ID: ${rawData.recordId}. \\nColumn ID: ${rawData.columnId}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_UPDATED:
            //eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* updated *Ticket-${rawData.id}*. \\nType: ${rawData.type}. Priority: ${rawData.priority}. Status: ${rawData.status}. \\nSummary: ${rawData.summary}. \\nGrid name: ${grid.name}. \\nRecord ID: ${rawData.recordId}. \\nColumn ID: ${rawData.columnId}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_DELETED:
            //eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* deleted *Ticket-${rawData.id}*. \\nType: ${rawData.type}. Priority: ${rawData.priority}. Status: ${rawData.status}. \\nSummary: ${rawData.summary}. \\nGrid name: ${grid.name}. \\nRecord ID: ${rawData.recordId}. \\nColumn ID: ${rawData.columnId}"\n}';
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_STATUS_CHANGED:
            //eslint-disable-next-line
            return '{\n    "text":"*${user.fullName}* updated the status of *Ticket-${rawData.id}*. \\nType: ${rawData.type}. Priority: ${rawData.priority}. Status: ${rawData.status}. \\nSummary: ${rawData.summary}. \\nGrid name: ${grid.name}. \\nRecord ID: ${rawData.recordId}. \\nColumn ID: ${rawData.columnId}. \\nCreated by: ${rawData.user.email}"\n}';
        default:
            return '{\n    "text":""\n}';
    }
};

const _getServerPayloadText = text => `${serverTriggerSymbol}${text}${serverSpacerSymbol}`;

const generateGoogleTranslateTemplate = trigger => {
    if (!trigger || !trigger?.watchingColumns?.length) return '';
    const firstWatchingColumn = trigger.watchingColumns[0];
    switch (trigger.trigger) {
        case WEB_HOOK_TRIGGER_ACTIONS.RECORD_UPDATED:
            return _getServerPayloadText(`trigger${trigger.id}.rawData.${firstWatchingColumn}.value`);
        default:
            return '';
    }
};

const generateJiraParams = trigger => {
    const triggerName = `trigger${trigger.id}`;
    switch (trigger.trigger) {
        case WEB_HOOK_TRIGGER_ACTIONS.TICKET_CREATED:
            return {
                issueSummary: `${_getServerPayloadText(`${triggerName}.grid.name`)} | Ticket-${_getServerPayloadText(
                    `${triggerName}.rawData.id`
                )}*: ${_getServerPayloadText(`${triggerName}.rawData.summary`)}`,
                message: _getServerPayloadText(`${triggerName}.rawData.description`)
            };
        default:
            return {};
    }
};

export const generateAutomationActionNodeDefaultParams = ({ app, trigger }) => {
    let params = {};
    const { gridUI } = getState();
    const { automationDetail } = gridUI;
    let lastTrigger = trigger;
    if (!lastTrigger && automationDetail?.triggerNodes) {
        lastTrigger = automationDetail.triggerNodes[automationDetail.triggerNodes.length - 1];
    }
    if (lastTrigger) {
        switch (app) {
            case WEB_HOOK_EXTERNAL_SYSTEMS.SLACK: {
                const regex = new RegExp(TRIGGER_MATCH_STRING_SERVER_REGEX, 'g');
                let message = generateTemplate(lastTrigger.trigger);
                message = message.replace(regex, match => {
                    let text = match.replace(/[${}]/g, '');
                    return `\${trigger${lastTrigger.id}.${text}}`;
                });
                params.message = message;
                break;
            }
            case WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE: {
                params = {
                    sourceLang: 'auto',
                    message: generateGoogleTranslateTemplate(lastTrigger)
                };
                break;
            }
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND: {
                params.message = generateGoogleTranslateTemplate(lastTrigger);
                break;
            }
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE: {
                params = {
                    formality: 'FORMAL',
                    profanity: 'MASK',
                    sourceLang: 'auto',
                    message: generateGoogleTranslateTemplate(lastTrigger)
                };
                break;
            }
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH: {
                params = {
                    engine: 'standard',
                    voiceId: '',
                    message: generateGoogleTranslateTemplate(lastTrigger)
                };
                break;
            }
            case WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL: {
                params = {
                    engine: 'neural',
                    voiceId: '',
                    message: generateGoogleTranslateTemplate(lastTrigger)
                };
                break;
            }
            case WEB_HOOK_EXTERNAL_SYSTEMS.JIRA: {
                params = generateJiraParams(lastTrigger);
                break;
            }
            default:
                break;
        }
    }

    return params;
};

export const AMAZON_LANGS = [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Chinese (Simplified)', value: 'zh' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dari', value: 'fa-AF' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Estonian', value: 'et' },
    { label: 'Farsi (Persian)', value: 'fa' },
    { label: 'Filipino, Tagalog', value: 'tl' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'French (Canada)', value: 'fr-CA' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Haitian Creole', value: 'ht' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Korean', value: 'ko' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Brazil)', value: 'pt' },
    { label: 'Portuguese (Portugal)', value: 'pt-PT' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Sinhala', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Spanish (Mexico)', value: 'es-MX' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Welsh', value: 'cy' }
];

export const GOOGLE_LANGS = [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Basque', value: 'eu' },
    { label: 'Belarusian', value: 'be' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Cebuano', value: 'ceb' },
    { label: 'Chinese (Simplified)', value: 'zh-CN' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Corsican', value: 'co' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Esperanto', value: 'eo' },
    { label: 'Estonian', value: 'et' },
    { label: 'Filipino (Tagalog)', value: 'fil' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'Frisian', value: 'fy' },
    { label: 'Galician', value: 'gl' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Haitian Creole', value: 'ht' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hawaiian', value: 'haw' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hmong', value: 'hmn' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Igbo', value: 'ig' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Javanese', value: 'jv' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Khmer', value: 'km' },
    { label: 'Kinyarwanda', value: 'rw' },
    { label: 'Korean', value: 'ko' },
    { label: 'Kurdish', value: 'ku' },
    { label: 'Kyrgyz', value: 'ky' },
    { label: 'Lao', value: 'lo' },
    { label: 'Latin', value: 'la' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Luxembourgish', value: 'lb' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Malagasy', value: 'mg' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Maori', value: 'mi' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Myanmar (Burmese)', value: 'my' },
    { label: 'Nepali', value: 'ne' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Nyanja (Chichewa)', value: 'ny' },
    { label: 'Odia (Oriya)', value: 'or' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Persian', value: 'fa' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Portugal, Brazil)', value: 'pt' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Samoan', value: 'sm' },
    { label: 'Scots Gaelic', value: 'gd' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Sesotho', value: 'st' },
    { label: 'Shona', value: 'sn' },
    { label: 'Sindhi', value: 'sd' },
    { label: 'Sinhala (Sinhalese)', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Sundanese', value: 'su' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tagalog (Filipino)', value: 'tl' },
    { label: 'Tajik', value: 'tg' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Tatar', value: 'tt' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Turkmen', value: 'tk' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uyghur', value: 'ug' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Welsh', value: 'cy' },
    { label: 'Xhosa', value: 'xh' },
    { label: 'Yiddish', value: 'yi' },
    { label: 'Yoruba', value: 'yo' },
    { label: 'Zulu', value: 'zu' }
];

export const AUTOMATION_VOICE_OPTIONS = [
    { value: 'Zeina', language: 'Arabic (arb)', group: 'ar', gender: 'Female', neural: false, standard: true },
    {
        value: 'Arlet',
        language: 'Catalan (ca-ES)',
        group: 'caES',
        gender: 'Female',
        neural: true,
        standard: false
    },
    {
        value: 'Zhiyu',
        language: 'Chinese, Mandarin (cmn-CN)',
        group: 'zhCN',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Naja',
        language: 'Danish (da-DK)',
        group: 'daDK',
        gender: 'Female',
        neural: false,
        standard: true
    },
    { value: 'Mads', language: 'Danish (da-DK)', group: 'daDK', gender: 'Male', neural: false, standard: true },
    {
        value: 'Lotte',
        language: 'Dutch (nl-NL)',
        group: 'nlNL',
        gender: 'Female',
        neural: false,
        standard: true
    },
    { value: 'Ruben', language: 'Dutch (nl-NL)', group: 'nlNL', gender: 'Male', neural: false, standard: true },
    {
        value: 'Nicole',
        language: 'English (Australian) (en-AU)',
        group: 'enAU',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Olivia',
        language: 'English (Australian) (en-AU)',
        group: 'enAU',
        gender: 'Female',
        neural: true,
        standard: false
    },
    {
        value: 'Russell',
        language: 'English (Australian) (en-AU)',
        group: 'enAU',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Amy',
        language: 'English (British) (en-GB)',
        group: 'enGB',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Emma',
        language: 'English (British) (en-GB)',
        group: 'enGB',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Brian',
        language: 'English (British) (en-GB)',
        group: 'enGB',
        gender: 'Male',
        neural: true,
        standard: true
    },
    {
        value: 'Arthur',
        language: 'English (British) (en-GB)',
        group: 'enGB',
        gender: 'Male',
        neural: true,
        standard: false
    },
    {
        value: 'Aditi*',
        language: 'English (Indian) (en-IN)',
        group: 'en',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Raveena',
        language: 'English (Indian) (en-IN)',
        group: 'en',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Aria',
        language: 'English (New Zealand) (en-NZ)',
        group: 'enNZ',
        gender: 'Female',
        neural: true,
        standard: false
    },
    {
        value: 'Ayanda',
        language: 'English (South African) (en-ZA)',
        group: 'enZA',
        gender: 'Female',
        neural: true,
        standard: false
    },
    {
        value: 'Ivy',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Female (child)',
        neural: true,
        standard: true
    },
    {
        value: 'Joanna',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Kendra',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Kimberly',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Salli',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Joey',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Male',
        neural: true,
        standard: true
    },
    {
        value: 'Justin',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Male (child)',
        neural: true,
        standard: true
    },
    {
        value: 'Kevin',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Male (child)',
        neural: true,
        standard: false
    },
    {
        value: 'Matthew',
        language: 'English (US) (en-US)',
        group: 'enUS',
        gender: 'Male',
        neural: true,
        standard: true
    },
    {
        value: 'Geraint',
        language: 'English (Welsh) (en-GB-WLS)',
        group: 'enGB',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Celine',
        language: 'French (fr-FR)',
        group: 'frFR',
        gender: 'Female',
        neural: false,
        standard: true
    },
    { value: 'Lea', language: 'French (fr-FR)', group: 'frFR', gender: 'Female', neural: true, standard: true },
    {
        value: 'Mathieu',
        language: 'French (fr-FR)',
        group: 'frFR',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Chantal',
        language: 'French (Canadian) (fr-CA)',
        group: 'frCA',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Gabrielle',
        language: 'French (Canadian) (fr-CA)',
        group: 'frCA',
        gender: 'Female',
        neural: true,
        standard: false
    },
    {
        value: 'Liam',
        language: 'French (Canadian) (fr-CA)',
        group: 'frCA',
        gender: 'Male',
        neural: true,
        standard: false
    },
    {
        value: 'Marlene',
        language: 'German (de-DE)',
        group: 'deDE',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Vicki',
        language: 'German (de-DE)',
        group: 'deDE',
        gender: 'Female',
        neural: true,
        standard: true
    },
    { value: 'Hans', language: 'German (de-DE)', group: 'deDE', gender: 'Male', neural: false, standard: true },
    {
        value: 'Daniel',
        language: 'German (de-DE)',
        group: 'deDE',
        gender: 'Male',
        neural: true,
        standard: false
    },
    {
        value: 'Hannah',
        language: 'German (Austrian) (de-AT)',
        group: 'deAT',
        gender: 'Female',
        neural: true,
        standard: false
    },
    // {
    //     value: 'Aditi*',
    //     language: 'Hindi (hi-IN)',
    //     group: 'hiIN',
    //     gender: 'Female',
    //     neural: false,
    //     standard: true
    // },
    {
        value: 'Dora',
        language: 'Icelandic (is-IS)',
        group: 'isIS',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Karl',
        language: 'Icelandic (is-IS)',
        group: 'isIS',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Carla',
        language: 'Italian (it-IT)',
        group: 'itIT',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Bianca',
        language: 'Italian (it-IT)',
        group: 'itIT',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Giorgio',
        language: 'Italian (it-IT)',
        group: 'itIT',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Mizuki',
        language: 'Japanese (ja-JP)',
        group: 'jaJP',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Takumi',
        language: 'Japanese (ja-JP)',
        group: 'jaJP',
        gender: 'Male',
        neural: true,
        standard: true
    },
    {
        value: 'Seoyeon',
        language: 'Korean (ko-KR)',
        group: 'koKR',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Liv',
        language: 'Norwegian (nb-NO)',
        group: 'nbNO',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Ewa',
        language: 'Polish (pl-PL)',
        group: 'plPL',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Maja',
        language: 'Polish (pl-PL)',
        group: 'plPL',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Jacek',
        language: 'Polish (pl-PL)',
        group: 'plPL',
        gender: 'Male',
        neural: false,
        standard: true
    },
    { value: 'Jan', language: 'Polish (pl-PL)', group: 'plPL', gender: 'Male', neural: false, standard: true },
    {
        value: 'Camila',
        language: 'Portuguese (Brazilian) (pt-BR)',
        group: 'ptBR',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Vitoria',
        language: 'Portuguese (Brazilian) (pt-BR)',
        group: 'ptBR',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Ricardo',
        language: 'Portuguese (Brazilian) (pt-BR)',
        group: 'ptBR',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Ines',
        language: 'Portuguese (European) (pt-PT)',
        group: 'ptPT',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Cristiano',
        language: 'Portuguese (European) (pt-PT)',
        group: 'ptPT',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Carmen',
        language: 'Romanian (ro-RO)',
        group: 'roRO',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Tatyana',
        language: 'Russian (ru-RU)',
        group: 'ruRU',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Maxim',
        language: 'Russian (ru-RU)',
        group: 'ruRU',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Conchita',
        language: 'Spanish (European) (es-ES)',
        group: 'esES',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Lucia',
        language: 'Spanish (European) (es-ES)',
        group: 'esES',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Enrique',
        language: 'Spanish (European) (es-ES)',
        group: 'esES',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Mia',
        language: 'Spanish (Mexican) (es-MX)',
        group: 'esMX',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Lupe',
        language: 'Spanish (US) (es-US)',
        group: 'esUS',
        gender: 'Female',
        neural: true,
        standard: true
    },
    {
        value: 'Penelope',
        language: 'Spanish (US) (es-US)',
        group: 'esUS',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Miguel',
        language: 'Spanish (US) (es-US)',
        group: 'esUS',
        gender: 'Male',
        neural: false,
        standard: true
    },
    {
        value: 'Pedro',
        language: 'Spanish (US) (es-US)',
        group: 'esUS',
        gender: 'Male',
        neural: true,
        standard: false
    },
    {
        value: 'Astrid',
        language: 'Swedish (sv-SE)',
        group: 'svSE',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Filiz',
        language: 'Turkish (tr-TR)',
        group: 'trTR',
        gender: 'Female',
        neural: false,
        standard: true
    },
    {
        value: 'Gwyneth',
        language: 'Welsh (cy-GB)',
        group: 'cyGB',
        gender: 'Female',
        neural: false,
        standard: true
    }
];

export const AUTOMATION_LANG_SENTIMENT_OPTIONS = [
    { value: 'de', label: 'German' },
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'it', label: 'Italian' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'fr', label: 'French' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
    { value: 'hi', label: 'Hindi' },
    { value: 'ar', label: 'Arabic' },
    { value: 'zh', label: 'Chinese (simplified)' },
    { value: 'zh-TW', label: 'Chinese (traditional)' }
];

export const AUTOMATION_GOOGLE_OCR_ACTIONS = [
    { value: 'textDetection', label: 'Text in Image' },
    { value: 'documentTextDetection', label: 'Handwriting in Image' }
];
