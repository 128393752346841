import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SidebarRightLayout from '../layout/rightSidebar';
import { ADD_ON_NAME } from 'const/inAppAddon';
import { useAddonDetailByName } from 'hooks/addons';
import { ADD_ON_STATES } from 'const';
import MemoQSetting from 'addons/inapp/memoQ/Setting';
import { Grid } from '@material-ui/core';
import MemoQSVG from 'assets/images/svg/addon/MemoQSVG';
import MemoQGridlySVG from 'assets/images/svg/addon/MemoQGridlySVG';
import ButtonBase from 'components/button/Base';
import ModalBase from 'components/modal/Base';
import ChooseColumns from './ChooseColumns';
import { useViewOnlyLanguageColumnsHaveDependency } from 'hooks/gridUI';
import NoTranslationOverviewSVG from 'assets/images/svg/NoTranslationOverviewSVG';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        height: '100%'
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    stepSpacing: {
        marginBottom: theme.spacing(3)
    },
    dlFlex: {
        display: 'flex'
    },
    avatar: {
        '& svg': {
            width: 88,
            height: 88
        }
    },
    expand: {
        flex: 1
    },
    more: {
        background: theme.colors.white,
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        padding: 10
    },
    notEnabled: {
        background: `#F1F7FA`,
        height: '100%'
    },
    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    center: {
        textAlign: 'center',
        maxWidth: '80%',
        margin: `0 auto`
    }
}));

function MemoQContent({ integration }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isOpenChooseColumnsModal, setIsOpenChooseColumnsModal] = React.useState(false);
    const columns = useViewOnlyLanguageColumnsHaveDependency();

    const openChooseColumnsModal = () => setIsOpenChooseColumnsModal(true);
    const closeChooseColumnsModal = () => setIsOpenChooseColumnsModal(false);

    switch (integration?.state) {
        case ADD_ON_STATES.DRAFT:
            return <MemoQSetting integration={integration} />;
        case ADD_ON_STATES.INACTIVE:
            return (
                <Grid item>
                    <MemoQSVG />
                </Grid>
            );
        case ADD_ON_STATES.ACTIVE:
            if (columns.length === 0) {
                return (
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <NoTranslationOverviewSVG />
                        </Grid>
                        <Grid item style={{ marginTop: 24 }}>
                            <h3 className="text-center prose prose-2xl font-medium">
                                {t('memsource_tracking_not_set')}
                            </h3>
                        </Grid>
                        <Grid item style={{ textAlign: 'center' }}>
                            <p className="caption text-center">
                                <Trans i18nKey="memsource_tracking_not_set_subtitle" t={t}>
                                    Add columns with source and target languages and
                                    <br />
                                    set dependencies to track translation status.
                                </Trans>
                            </p>
                        </Grid>
                    </Grid>
                );
            }
            return (
                <Grid container alignItems="center" direction="column" spacing={2}>
                    <Grid item>
                        <MemoQGridlySVG />
                    </Grid>
                    <Grid item>
                        <ButtonBase width={300} variant="contained" onClick={openChooseColumnsModal}>
                            {t(`memoq_push_button`)}
                        </ButtonBase>
                    </Grid>
                    <ModalBase open={isOpenChooseColumnsModal} handleClose={closeChooseColumnsModal}>
                        <ChooseColumns t={t} onClose={closeChooseColumnsModal} id={integration?.id} />
                    </ModalBase>
                </Grid>
            );

        default:
            return (
                <Grid container alignItems="center" justify="center" className={classes.notEnabled}>
                    <Grid item>
                        <MemoQGridlySVG />
                    </Grid>
                </Grid>
            );
    }
}

function MemoQSidebar({ handleClose, ...rest }) {
    const classes = useStyles();
    const memoQIntegration = useAddonDetailByName(ADD_ON_NAME.MEMO_Q)?.integration;

    React.useEffect(() => {
        if (memoQIntegration.state === ADD_ON_STATES.INACTIVE) {
            handleClose && handleClose();
        }
    }, [memoQIntegration, handleClose]);

    return (
        <SidebarRightLayout title={'memoQ'} onClose={handleClose}>
            <Grid
                container
                alignItems={memoQIntegration?.state === ADD_ON_STATES.DRAFT ? 'flex-start' : 'center'}
                justify={memoQIntegration?.state === ADD_ON_STATES.DRAFT ? 'flex-start' : 'center'}
                className={classes.root}
            >
                <MemoQContent integration={memoQIntegration} {...rest} />
            </Grid>
        </SidebarRightLayout>
    );
}

export default React.memo(MemoQSidebar);
