import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5, 4)
    },
    title: {
        marginBottom: theme.spacing(4)
    }
}));

function PaymentContentLayout({ title, children }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} direction="column" wrap="nowrap">
            {title && (
                <Grid item className={classes.title}>
                    <Typography variant="h4" component="div">
                        {title}
                    </Typography>
                </Grid>
            )}
            <Grid item>{children}</Grid>
        </Grid>
    );
}

export default React.memo(PaymentContentLayout);
