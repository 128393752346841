import React from 'react';
import LDSelectBaseGroup from 'components/selects/LDBasePortalGroup';
import { useColumnTypes } from 'hooks/gridUI/column';
import { COLUMN_GROUP_SECTIONS } from 'const';
import produce from 'immer';
import { useTranslation } from 'react-i18next';

function ColumnTypesSelect({ defaultValue, handleOptionChange, LOST_COLUMN_TYPES, ...rest }) {
    const { t } = useTranslation();
    let columnTypes = useColumnTypes([
        COLUMN_GROUP_SECTIONS.SPECIAL,
        COLUMN_GROUP_SECTIONS.BASIC,
        COLUMN_GROUP_SECTIONS.CODE_FORMATTING
    ]);

    const options = React.useMemo(() => {
        return produce(columnTypes, draft => {
            for (let group of draft) {
                group.options = group?.options?.map(option => {
                    return option;
                });
            }
            return draft;
        });
    }, [columnTypes]);

    const formatGroupLabel = data => (
        <div
            style={{
                marginTop: 0,
                paddingTop: 0
            }}
        >
            <span>{data.label}</span>
        </div>
    );

    return (
        <LDSelectBaseGroup
            ddPlaceholder={'Select a type'}
            menuPlaceholder={t('global_find_a_type')}
            options={options}
            onChange={handleOptionChange}
            defaultValue={defaultValue}
            isMulti={false}
            formatGroupLabel={formatGroupLabel}
            isUsingContainer={true}
            {...rest}
        />
    );
}

export default React.memo(ColumnTypesSelect);
