import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { SELECTION_LIST_MARGIN_TOP } from 'const/style';
import { Link, useHistory } from 'react-router-dom';
import LogoutSVG from 'assets/images/svg/LogoutSVG';
import UserIconSVG from 'assets/images/svg/UserIconSVG';
import * as authActions from 'auth/actions';
import { sendManualTrack } from 'tracker';
import ListItem from 'components/list/Item';
import ws from 'socket';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menu: {
        paddingTop: SELECTION_LIST_MARGIN_TOP,
        paddingBottom: SELECTION_LIST_MARGIN_TOP,
        boxShadow: theme.shadows[1]
    },
    menuItem: {
        '& svg': {
            width: 14
        }
    },
    link: {
        textDecoration: 'none'
    }
}));

function UserMenu({ setOpen }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const handleClick = () => {
        sendManualTrack({ type: 'Go To My Profile' });
        setOpen(false);
    };

    const handleLogout = () => {
        ws.disconnect();
        dispatch(
            authActions.logOut({
                successCallback: () => {
                    history.replace({ ...history.location, state: undefined });
                    sendManualTrack({ type: 'Log out' });
                    setOpen(false);
                },
                errorCallback: () => {}
            })
        );
    };

    return (
        <Paper className={`${classes.menu} user-menu`}>
            <Grid container direction="column">
                <Grid onClick={handleClick} item>
                    <Link to="/profile/info" className={`${classes.menuItem} ${classes.link} user-menu-item-profile`}>
                        <ListItem icon={<UserIconSVG color={'#78778B'} />} name={t('header_avatar_dropdown_profile')} />
                    </Link>
                </Grid>
                <Grid onClick={handleLogout} item className={`${classes.menuItem} user-menu-item-logout`}>
                    <ListItem icon={<LogoutSVG color={'#78778B'} />} name={t('header_avatar_dropdown_logout')} />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default React.memo(UserMenu);
