import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    arrow: {
        color: theme.colors.tooltip
    },
    tooltip: {
        backgroundColor: theme.colors.tooltip,
        zIndex: 99999,
        fontSize: 13,
        maxWidth: 500,
        '& p': {
            color: theme.colors.white
        }
    },
    tooltipPlacementBottom: {
        marginTop: 5
    },
    popper: {
        pointerEvents: 'auto'
    }
}));

function LDTooltipClickContent({ className = '', children, placement = 'bottom', ...rest }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        if (rest?.title) {
            setOpen(true);
        }
    };

    return (
        <div className={className} onMouseOver={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
            <Tooltip placement={placement} onClose={handleTooltipClose} open={open} arrow classes={classes} {...rest}>
                <div>{children}</div>
            </Tooltip>
        </div>
    );
}

export default React.memo(LDTooltipClickContent);
