import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import ExtendViewSVG from 'assets/images/svg/ExtendViewSVG';
import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import HistorySVG from 'assets/images/svg/HistorySVG';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import TranslatorView from './translator';
import RecordHistory from './recordHistory';
import PopperMenu from 'components/menus/Popper';
import {
    getVhBySteps,
    DEFAULT_RECORD_HISTORY_HEIGHT,
    EXTEND_VIEW_TYPES,
    ROW_HEIGHT,
    ROW_HEIGHT_OPTIONS
} from 'const/gridUI';
import * as workspaceActions from 'workspaces/actions';
import { useParams } from 'react-router-dom';
import { RESOURCE_TYPES, USER_SETTINGS } from 'const';
import { useUserGridSettings, useGridSettingByProperty } from 'hooks/workspace';
import * as gridUIActions from 'gridUI/actions';
import { useRecordHistoryType } from 'hooks/gridUI/recordHistory';
import Small from 'assets/images/svg/extendViews/Small';
import Medium from 'assets/images/svg/extendViews/Medium';
import Large from 'assets/images/svg/extendViews/Large';
import ListItem from 'components/list/Item';
import EditorView from './editor';
import EditSVG from 'assets/images/svg/EditSVG';
import { useSelectedCellData } from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import RowHeightSVG from 'assets/images/svg/RowHeightSVG';
import ShortSVG from 'assets/images/svg/ShortSVG';
import CustomSVG from 'assets/images/svg/CustomSVG';
import TallSVG from 'assets/images/svg/TallSVG';
import MediumSVG from 'assets/images/svg/MediumSVG';
import Dialog from 'components/dialog/Dialog';
import CustomRowHeight from 'gridUI/CustomRowHeight';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const useStyles = makeStyles(theme => ({
    root: {},
    header: {
        height: 48,
        boxShadow: '0px 1px 0px #D7D7DC',
        background: theme.colors.white,
        paddingLeft: 0,
        paddingRight: theme.spacing(4),
        borderTop: `1px solid ${theme.colors.border}`,
        position: 'relative',
        '& .MuiTab-root': {
            width: 228
        }
    },
    dpFlex: {
        display: 'flex'
    },
    fullWidth: {
        width: '100%'
    },
    roleSelect: {
        minWidth: 188,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 20,
        paddingLeft: 20
    },
    resizeHandler: {
        width: '100%',
        background: theme.colors.resizeColor,
        height: 3,
        position: 'absolute',
        top: 0,
        right: 0,
        opacity: 0,
        '&:hover': {
            opacity: 1,
            cursor: 'ns-resize'
        }
    }
}));

const VIEW_HEIGHT_OPTIONS = [
    { label: i18n.t('global_small'), icon: () => <Small />, value: getVhBySteps(0) },
    { label: i18n.t('global_medium'), icon: () => <Medium />, value: getVhBySteps(55) },
    { label: i18n.t('global_large'), icon: () => <Large />, value: getVhBySteps(100) }
];

function ExtendView({ width, height, onStartResize, ...props }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const [tabIndex, setTabIndex] = React.useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [showDiff, setShowDiff] = React.useState(false);
    const { dbId, branchId, workspaceId, viewId } = useParams();
    const userGridSetting = useUserGridSettings({ wsId: workspaceId, dbId, gridId: branchId });
    const extendViewType = useRecordHistoryType();
    const selectedCellData = useSelectedCellData();
    const firstRender = React.useRef(true);
    const [rowHeight, setRowHeight] = React.useState(ROW_HEIGHT * 2);
    const [defaultRowHeightAnchorEl, setDefaultRowHeightAnchorEl] = React.useState(null);
    const [openCustomRowHeight, setOpenCustomRowHeight] = React.useState(false);

    const recordHistoryHeight =
        useGridSettingByProperty({
            dbId,
            gridId: branchId,
            wsId: workspaceId,
            property: USER_SETTINGS.GRID.RECORD_HISTORY_HEIGHT
        }) || DEFAULT_RECORD_HISTORY_HEIGHT;

    const handleChange = React.useCallback((event, newValue) => {
        setTabIndex(newValue);

        if (newValue === EXTEND_VIEW_TYPES.EDITOR) {
            setShowDiff(false);
        }
    }, []);

    React.useEffect(() => {
        setTabIndex(extendViewType);
    }, [extendViewType]);

    const isParentDependency = useMemo(() => {
        return selectedCellData?.isParentDependency;
    }, [selectedCellData]);

    const columnType = useMemo(() => {
        return selectedCellData?.column?.type;
    }, [selectedCellData]);

    React.useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (tabIndex !== EXTEND_VIEW_TYPES.RECORD_HISTORY) {
            const isTranslator = columnType === columnTypes.TRANSLATION && !isParentDependency;
            if (!isTranslator) {
                setShowDiff(false);
            }
            setTabIndex(isTranslator ? EXTEND_VIEW_TYPES.TRANSLATOR : EXTEND_VIEW_TYPES.EDITOR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnType, isParentDependency]);

    const handleViewHeightChange = React.useCallback(
        newHeight => {
            dispatch(
                workspaceActions.setUserSettings({
                    dbId,
                    workspaceId,
                    resourceId: branchId,
                    resourceType: RESOURCE_TYPES.GRID,
                    value: {
                        ...userGridSetting,
                        [USER_SETTINGS.GRID.RECORD_HISTORY_HEIGHT]: newHeight
                    }
                })
            );
        },
        [dispatch, branchId, dbId, userGridSetting, workspaceId]
    );

    const handleClick = React.useCallback(event => setAnchorEl(anchorEl ? null : event.currentTarget), [anchorEl]);
    const handleClickAway = React.useCallback(() => setAnchorEl(null), []);

    const handleRowHeightClick = React.useCallback(
        event => {
            setDefaultRowHeightAnchorEl(defaultRowHeightAnchorEl ? null : event.currentTarget);
        },
        [defaultRowHeightAnchorEl]
    );

    const handleCloseRowHeight = React.useCallback(event => {
        setDefaultRowHeightAnchorEl(null);
    }, []);

    const changeDefaultRowHeight = React.useCallback(
        newRowHeight => {
            setRowHeight(newRowHeight);
            handleCloseRowHeight();
        },
        [handleCloseRowHeight]
    );

    const openCustomRowHeightHandler = React.useCallback(() => {
        setOpenCustomRowHeight(true);
        handleCloseRowHeight();
    }, [handleCloseRowHeight]);

    const closeRecordHistory = React.useCallback(() => {
        dispatch(gridUIActions.closeRecordHistory());
        dispatch(
            workspaceActions.setUserSettings({
                dbId,
                workspaceId,
                resourceId: branchId,
                resourceType: RESOURCE_TYPES.GRID,
                value: {
                    ...userGridSetting,
                    [USER_SETTINGS.GRID.OPEN_EXTEND_VIEW]: false
                }
            })
        );
    }, [dispatch, userGridSetting, branchId, dbId, workspaceId]);

    const showTranslatorView = React.useMemo(() => {
        return selectedCellData?.column?.type === columnTypes.TRANSLATION && !selectedCellData.isParentDependency;
    }, [selectedCellData]);

    const closeCustomRowHeightHandler = React.useCallback(() => setOpenCustomRowHeight(false), []);

    const handleCustomRowHeightChange = React.useCallback(
        newHeight => {
            setRowHeight(newHeight);
            closeCustomRowHeightHandler();
        },
        [closeCustomRowHeightHandler]
    );

    return (
        <Grid container direction="column" style={{ width, height, background: theme.colors.paleNavy }}>
            <Grid item className={classes.fullWidth}>
                <Grid item container className={classes.header} alignItems="center" justify="space-between">
                    <div onMouseDown={onStartResize} className={classes.resizeHandler}></div>

                    <Grid item>
                        <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example">
                            {(showTranslatorView ||
                                (selectedCellData?.column?.type === columnTypes.TRANSLATION &&
                                    selectedCellData?.isParentDependency)) && (
                                <Tab
                                    value={EXTEND_VIEW_TYPES.TRANSLATOR}
                                    label={
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item className={classes.dpFlex}>
                                                <ExtendViewSVG color="#93929E" />
                                            </Grid>
                                            <Grid item>
                                                <p className="body1">{t('translator_view')}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            )}
                            {!showTranslatorView && (
                                <Tab
                                    value={EXTEND_VIEW_TYPES.EDITOR}
                                    label={
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item className={classes.dpFlex}>
                                                <EditSVG color="#93929E" />
                                            </Grid>
                                            <Grid item>
                                                <p className="body1">Editor view</p>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            )}
                            <Tab
                                value={EXTEND_VIEW_TYPES.RECORD_HISTORY}
                                label={
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item className={classes.dpFlex}>
                                            <HistorySVG color="#93929E" />
                                        </Grid>
                                        <Grid item>
                                            <p className="body1">Record history</p>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} direction="row" alignItems="center">
                            {tabIndex === EXTEND_VIEW_TYPES.RECORD_HISTORY && (
                                <Grid item className={classes.dpFlex}>
                                    <Tooltip title={t('toolbar_default_rowHeight_tooltip')}>
                                        <IconButton onClick={handleRowHeightClick} size="small">
                                            <RowHeightSVG color={theme.colors.darkness} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            <Grid item className={classes.dpFlex}>
                                <IconButton onClick={handleClick} size="small">
                                    <IconMoreActionsSVG />
                                </IconButton>
                            </Grid>
                            <Grid item onClick={closeRecordHistory} className={classes.dpFlex}>
                                <IconButton size="small">
                                    <CloseIconSVG />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidth}>
                {tabIndex === EXTEND_VIEW_TYPES.TRANSLATOR && (
                    <TranslatorView height={height - 60} showDiff={showDiff} setShowDiff={setShowDiff} {...props} />
                )}
                {tabIndex === EXTEND_VIEW_TYPES.EDITOR && (
                    <EditorView height={height - 60} showDiff={showDiff} setShowDiff={setShowDiff} {...props} />
                )}
                {tabIndex === EXTEND_VIEW_TYPES.RECORD_HISTORY && (
                    <RecordHistory height={height - 60} showDiff={showDiff} rowHeight={rowHeight} {...props} />
                )}
            </Grid>

            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} placement={'bottom-end'} handleClickAway={handleClickAway}>
                    <Grid container direction="column">
                        <Grid item className={classes.roleSelect}>
                            <p className="body1" gutterBottom>
                                {t('set_view_height')}
                            </p>
                        </Grid>
                        <Grid item container direction="column">
                            {VIEW_HEIGHT_OPTIONS?.map(option => {
                                return (
                                    <Grid key={option?.value}>
                                        <ListItem
                                            onClick={() => handleViewHeightChange(option?.value)}
                                            name={option?.label}
                                            icon={option?.icon}
                                            isSelected={recordHistoryHeight === option?.value}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </PopperMenu>
            )}

            {defaultRowHeightAnchorEl && (
                <PopperMenu
                    placement={`bottom-end`}
                    anchorEl={defaultRowHeightAnchorEl}
                    handleClickAway={handleCloseRowHeight}
                    className={classes.popperMenu}
                >
                    <ListItem
                        name={t('global_short')}
                        icon={<ShortSVG />}
                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.DEFAULT)}
                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.DEFAULT}
                    />
                    <ListItem
                        name={t('global_medium')}
                        icon={<MediumSVG />}
                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMFORTABLE)}
                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMFORTABLE}
                    />
                    <ListItem
                        icon={<TallSVG />}
                        name={t('global_tall')}
                        onClick={() => changeDefaultRowHeight(ROW_HEIGHT_OPTIONS.COMPACT)}
                        isSelected={rowHeight === ROW_HEIGHT_OPTIONS.COMPACT}
                    />

                    <ListItem
                        icon={<CustomSVG />}
                        name={t('global_custom')}
                        onClick={openCustomRowHeightHandler}
                        isSelected={
                            !Object.keys(ROW_HEIGHT_OPTIONS)
                                ?.map(key => ROW_HEIGHT_OPTIONS?.[key])
                                ?.includes(rowHeight)
                        }
                    />
                </PopperMenu>
            )}

            <Dialog maxWidth={false} open={openCustomRowHeight} onClose={closeCustomRowHeightHandler}>
                <CustomRowHeight
                    viewId={viewId}
                    workspaceId={workspaceId}
                    dbId={dbId}
                    rowHeight={rowHeight}
                    onClose={closeCustomRowHeightHandler}
                    onHeightChange={handleCustomRowHeightChange}
                />
            </Dialog>
        </Grid>
    );
}

export default ExtendView;
