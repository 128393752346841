import * as types from './types';
import { USER_LOGOUT_SUCCESS } from '../auth/types';

import createReducer from 'utils/createReducer';

import {
    roleHandler,
    groupHandler,
    memberHandler,
    companyHandler,
    workspaceHandler,
    apiKeyHandler,
    twoFactorHandler
} from './reducers';

const INITIAL_STATE = {
    roles: {
        isFetchingPrivileges: false,
        defaultPrivileges: [],
        list: [],
        isFetchingRoles: false,
        selectedRoleId: null,
        isCreatingRole: false,
        isShowLastPopupCreate: false
    },
    groups: {
        list: [],
        isFetchingGroups: false,
        isFetchingMembersAndWorkspaceAccess: false,
        isCreatingGroup: false,
        isShowLastPopupCreate: false,
        selectedGroupId: null,
        groupMemberAccess: {
            groupId: {
                isFetchingMembers: false,
                isFetchingGroupWorkspaceAccess: false,
                workspacesAccess: null,
                members: null
            }
        },
        selectedGroups: []
    },
    members: {
        isFetching: false,
        list: null,
        workspacesRoles: {
            sample_user_id: {
                isFetching: false,
                list: []
            }
        },
        invitedMembers: []
    },
    companies: {
        isFetching: false,
        list: []
    },
    workspaces: {
        isFetching: false,
        list: null,
        listViewsDetail: null,
        isFetchingWorkspaceViewsDetail: false
    },
    apiKeys: {
        isFetching: false,
        list: null,
        isCreating: false,
        isUpdating: false,
        isDeleting: false,
        isFetchingDetail: false
    },
    workspacePrivileges: [],
    userFactor: {}
};

const commonHandler = {
    [USER_LOGOUT_SUCCESS]() {
        return INITIAL_STATE;
    },
    [types.SET_DEFAULT_COMPANY_SETTING](state, action) {
        const { companyId } = action.payload;
        return {
            ...state,
            companyId
        };
    },
    [types.FETCH_PRIVILEGE](state) {
        return {
            ...state,
            roles: {
                ...state.roles,
                isFetchingPrivileges: true
            }
        };
    },
    [types.FETCH_PRIVILEGE_SUCCESS](state, action) {
        const { privileges } = action.payload;
        return {
            ...state,
            roles: {
                ...state.roles,
                defaultPrivileges: privileges,
                isFetchingPrivileges: false
            }
        };
    },
    [types.FETCH_WORKSPACE_PRIVILEGE_SUCCESS](state, action) {
        const { workspacePrivileges } = action.payload;
        return {
            ...state,
            workspacePrivileges
        };
    }
};

export default createReducer(
    INITIAL_STATE,
    Object.assign(
        commonHandler,
        roleHandler,
        groupHandler,
        memberHandler,
        companyHandler,
        workspaceHandler,
        apiKeyHandler,
        twoFactorHandler
    )
);
