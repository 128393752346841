import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from 'components/tabs/Base';
import GroupContentSkeleton from './GroupContentSkeleton';
import GroupTeam from './GroupTeam';
import GroupAccess from './GroupAccess';
import GroupMember from './GroupMember';
import { useSelectedGroupId, useGroupIsFetchingGroups, useMembersInSelectedGroup } from 'hooks/permission';
import { useDispatch } from 'react-redux';
import * as groupActions from 'permission/actions/group';
import { useParams } from 'react-router-dom';
import { useGroupList, useSelectedGroupDetail } from 'hooks/permission';
import { useCanCustomGroup } from 'hooks/payment';
import { DISABLED_OPACITY } from 'const/style';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `20px 24px`
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

function GroupContent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedGroupId = useSelectedGroupId();
    const selectedGroupDetail = useSelectedGroupDetail();
    const isFetchingGroups = useGroupIsFetchingGroups();
    const params = useParams();
    const { groupId } = params;
    const groups = useGroupList();
    const canCustomGroup = useCanCustomGroup();
    const groupMembers = useMembersInSelectedGroup();

    React.useEffect(() => {
        if (!selectedGroupId || !groupId) return;
        dispatch(
            groupActions.fetchGroupMembers({
                successCallback: () => {
                    console.log('fetch member successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch group members');
                }
            })
        );
        dispatch(
            groupActions.fetchGroupWorkspacesAccess({
                successCallback: () => {
                    console.log('fetch group Access successfully');
                },
                errorCallback: () => {
                    console.log('failed to fetch group Access');
                }
            })
        );
    }, [selectedGroupId, dispatch, groupId]);

    const isNotSystemGroupAndCantEditGroup = React.useMemo(() => {
        return !selectedGroupDetail?.systemGroup && !canCustomGroup;
    }, [selectedGroupDetail, canCustomGroup]);

    const tabs = [
        { label: `${t('group_member_tab')} (${(groupMembers || [])?.length})`, content: <GroupMember /> },
        { label: t('group_member_access'), content: <GroupAccess /> }
    ];

    if (isFetchingGroups && groups === null) return <GroupContentSkeleton />;
    if (!selectedGroupDetail) return null;
    return (
        <Grid className={classes.root} direction="column" container>
            <Grid item>
                <GroupTeam isNotSystemGroupAndCantEditGroup={isNotSystemGroupAndCantEditGroup} />
            </Grid>
            <Grid item className={isNotSystemGroupAndCantEditGroup ? classes.disabled : ''}>
                <Tabs tabs={tabs} />
            </Grid>
        </Grid>
    );
}

export default React.memo(GroupContent);
