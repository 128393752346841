import React from 'react';
import DialogTitle from 'components/dialog/DialogTitle';
import DialogContent from 'components/dialog/DialogContent';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import InviteMemberSuccessSVG from 'assets/images/svg/InviteMemberSuccessSVG';
import ButtonBase from 'components/button/Base';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        paddingBottom: 0
    },
    contentRoot: {
        minHeight: 550,
        maxHeight: 700
    },
    imageWrapper: {
        position: 'relative',
        left: -23
    },
    title: {
        marginTop: 32
    },
    subTitle: {
        marginTop: 20,
        '& p': {
            color: theme.colors.steel,
            fontSize: 18,
            maxWidth: 616,
            textAlign: 'center',
            lineHeight: `28px`
        }
    },
    btnWrapper: {
        marginTop: 46
    }
}));

const InviteMemberSuccess = ({ navigateToInvite, onClose }) => {
    const classes = useStyles();

    return (
        <>
            <DialogTitle onClose={onClose} />
            <DialogContent className={classes.dialogContent}>
                <Grid
                    className={classes.contentRoot}
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item className={classes.imageWrapper}>
                        <InviteMemberSuccessSVG />
                    </Grid>
                    <Grid item className={classes.title}>
                        <Typography variant="h3">Your invitation has been sent</Typography>
                    </Grid>
                    <Grid item className={classes.subTitle}>
                        <Typography variant="body2">
                            Your invitation has been sent successfully. Please notify your team members to check the
                            invitation email and click Join Now to start onboarding.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.btnWrapper}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <ButtonBase variant="outlined" width={150} onClick={navigateToInvite}>
                                    Invite more
                                </ButtonBase>
                            </Grid>
                            <Grid item>
                                <ButtonBase variant="contained" width={150} onClick={onClose}>
                                    Close
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

export default React.memo(InviteMemberSuccess);
