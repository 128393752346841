import React from 'react';
import Header from './Header';
import useMediaQueries from '../hooks/useMediaQueries';
import { fetchWorkspaceDetail } from 'workspaces/actions';
import { useDispatch } from 'react-redux';
import { useIsShareViewLink } from 'hooks/app';
import { useParams } from 'react-router-dom';

function GridUILayout({ children }) {
    let { isMobile, isTablet } = useMediaQueries();
    const dispatch = useDispatch();
    const isShareViewLink = useIsShareViewLink();
    const { workspaceId } = useParams();
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (!isShareViewLink) {
            setIsLoading(true);
            dispatch(
                fetchWorkspaceDetail({
                    workspaceId,
                    successCallback: () => {
                        setIsLoading(false);
                    },
                    errorCallback: () => {
                        setIsLoading(false);
                    }
                })
            );
        }
    }, [workspaceId, dispatch, isShareViewLink]);

    if (isLoading) return null;

    return (
        <div className={'flex flex-col h-screen'}>
            <div className="w-full">
                <Header isGridUI isShowToggle={isMobile} isTablet={isTablet} />
            </div>
            <div className="flex-1 bg-background">{children}</div>
        </div>
    );
}

export default GridUILayout;
