import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HistorySVG from 'assets/images/svg/HistorySVG';
import ListItem from 'components/list/Item';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { width: 187 }
}));

function BackupItemPopup({ onBackupRestore }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleBackupRestore = () => {
        onBackupRestore();
    };

    return (
        <Grid container direction="column" className={classes.root}>
            <ListItem icon={<HistorySVG />} name={t(`database_restore_this_version`)} onClick={handleBackupRestore} />
        </Grid>
    );
}

export default React.memo(BackupItemPopup);
