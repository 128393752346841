import { useSelector } from 'react-redux';
import { useViewColumnsWithReOrder } from './column';

export function useListTB() {
    return useSelector(state => state.gridUI.listTermBases);
}

export function useDataFromTBTargetList({ rowId, langSource, langTarget }) {
    return (
        useSelector(state => {
            return state.gridUI.dataFromTB?.sourceData?.[rowId]?.[langSource]?.[langTarget]?.filter(
                el => !!el.langSourceText || !!el.langTargetText
            );
        }) || null
    );
}

export function useDataFromTBCurrentSource({ rowId, langSource, isChildDependency }) {
    const columns = useViewColumnsWithReOrder();
    return useSelector(state => {
        const rowLangSource = state.gridUI.dataFromTB?.sourceData?.[rowId]?.[langSource];
        const { columnStartIndex, metaData, rowStartIndex, rows, ROW_START_INDEX } = state?.gridUI;
        const recordId = rows?.[rowStartIndex - ROW_START_INDEX];
        const viewColumn = columns?.[columnStartIndex];
        const columnId = viewColumn?.id;
        const column = metaData?.[columnId];

        if (recordId !== rowId || Boolean(isChildDependency)) return null;
        return rowLangSource?.[column?.group]?.map(text => text?.langSourceText);
    });
}
