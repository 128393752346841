import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TEXT_LINE_HEIGHT, PREVIEW_STATUS } from 'const/gridUI';
import DatetimeDelete from '../diffCheckComponents/delete/Datetime';
import DatetimeUpdate from '../diffCheckComponents/update/Datetime';
import Datetime from '../diffCheckComponents/components/Datetime';

import DiffDatetimeWithOtherTypes from './diffType/Datetime';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'left',
        lineHeight: `${TEXT_LINE_HEIGHT}px`,
        wordBreak: 'break-word',
        wordWrap: 'break-word'
    }
}));

function DatetimeRender({
    rowIndex,
    columnIndex,
    rowHeight,
    status,
    columnType,
    previousColumnType,
    data,
    previousData,
    options,
    customProperties,
    isOverride,
    dbId
}) {
    const classes = useStyles();
    const isSameColumnType = columnType === previousColumnType;

    return (
        <div
            className={`cell_${rowIndex}_${columnIndex} ${classes.root}`}
            style={{
                height: rowHeight,
                pointerEvents: 'none'
            }}
        >
            {isSameColumnType && status === PREVIEW_STATUS.DELETED && <DatetimeDelete previousData={previousData} />}
            {isSameColumnType && status === PREVIEW_STATUS.UPDATED && (
                <DatetimeUpdate data={data} previousData={previousData} />
            )}
            {isSameColumnType && [PREVIEW_STATUS.NORMAL, PREVIEW_STATUS.CONFLICT].includes(status) && (
                <Datetime state={'normal'} data={data} />
            )}
            {!isSameColumnType && (
                <DiffDatetimeWithOtherTypes
                    previousData={previousData}
                    data={data}
                    options={options}
                    customProperties={customProperties}
                    previousColumnType={previousColumnType}
                    isHideMarkIcon={true}
                    dbId={dbId}
                />
            )}
        </div>
    );
}

export default React.memo(DatetimeRender);
