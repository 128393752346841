import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import AdminGroupAvatar from 'permission/groups/AdminGroupAvatar';
import TranslatorGroupAvatar from 'permission/groups/TranslatorGroupAvatar';
import { GROUP_ADMIN_TYPES } from 'const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    groupTag: {
        marginLeft: 8,
        padding: 2,
        borderRadius: 15,
        backgroundColor: theme.colors.background,
        border: '1px solid rgba(74, 145, 226, 0.3)'
    },
    closeIcon: {
        width: 8,
        height: 8
    }
}));

function GroupTag({ members, selectedGroup, onClose }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container justify="space-between">
            <Grid item>
                <p className="body1">{t(`share_view_groups_members`, { length: members?.length })}</p>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item>
                        <p className="caption">{t(`global_filtered_by`)}</p>
                    </Grid>
                    <Grid item className={classes.groupTag}>
                        <Grid container alignItems="center">
                            <Grid item>
                                {selectedGroup?.systemGroup ? (
                                    selectedGroup?.type === GROUP_ADMIN_TYPES.ADMIN ? (
                                        <AdminGroupAvatar size={20} />
                                    ) : (
                                        <TranslatorGroupAvatar size={20} />
                                    )
                                ) : (
                                    <AvatarBase size={20}>{selectedGroup?.name.slice(0, 1)}</AvatarBase>
                                )}
                            </Grid>
                            <Grid item className="ml-1">
                                <p className="body2">{selectedGroup?.name}</p>
                            </Grid>
                            <Grid
                                item
                                style={{ display: 'flex', cursor: 'pointer', padding: '0 8px' }}
                                onClick={onClose}
                            >
                                <CloseIconSVG className={classes.closeIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(GroupTag);
