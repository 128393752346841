import React from 'react';
import InternalManagerSVG from 'assets/images/svg/InternalManagerSVG';
import InternalUserSVG from 'assets/images/svg/InternalUserSVG';

export function getFullName(user) {
    if (!user) return '';
    const { fullName, firstName, lastName, email } = user;

    if (fullName) return fullName;
    if (firstName === lastName && firstName) return firstName;
    if (!firstName && !lastName) return email?.split('@')?.[0];

    return (firstName + ' ' + lastName).trim();
}

export function formatUserRealtimeBody(user) {
    return {
        id: user?.id,
        name: !user ? 'Unknown' : getFullName(user)
    };
}

export function generateUserBadge(user) {
    if (user.ldManager) return <InternalManagerSVG />;
    if (user.ldUser) return <InternalUserSVG />;
}
