import React from 'react';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

const TwoDigitsLang = ({ lang, style, ...rest }) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t('global_tooltip_2_digits_language')}>
            <Grid
                item
                style={{
                    width: 24,
                    height: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#EBEBF6',
                    borderRadius: 1,
                    ...style
                }}
                {...rest}
            >
                <Typography
                    variant="h6"
                    style={{
                        fontSize: 11,
                        color: '#7869B9'
                    }}
                >
                    {lang}
                </Typography>
            </Grid>
        </Tooltip>
    );
};

export default React.memo(TwoDigitsLang);
