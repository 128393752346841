import React from 'react';

function FilterSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/ filfter">
                <g id="Group 12">
                    <path
                        id="Shape"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.8941 0.799988C15.13 0.799988 15.3445 0.942934 15.4437 1.16636C15.543 1.38979 15.5091 1.65308 15.3568 1.84117L9.98477 8.47389V14.3895C9.98477 14.6088 9.87602 14.8124 9.69739 14.9277C9.60007 14.9905 9.48962 15.0222 9.37882 15.0222C9.28621 15.0222 9.19337 15.0001 9.10785 14.9554L6.35022 13.5158C6.14494 13.4086 6.01527 13.1895 6.01527 12.9499V8.47389L0.643266 1.84117C0.490952 1.65308 0.456996 1.38982 0.556284 1.16636C0.655571 0.942903 0.870054 0.799988 1.10592 0.799988H14.8941ZM8.77263 8.24218C8.77263 8.09262 8.82337 7.94787 8.91586 7.83365L13.5879 2.06525H2.41162L7.08357 7.83368C7.17606 7.9479 7.22683 8.09262 7.22683 8.24221V12.5589L8.77263 13.3658V8.24218Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(FilterSVG);
