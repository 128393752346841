import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import SettingContentLayout from 'permission/common/Layout';
import Overview from './Overview';
import Setting from './Setting';
import * as integrationActions from 'integrations/actions';
import { useDispatch } from 'react-redux';
import { useSourceById } from 'hooks/integration';
import * as workspaceActions from 'workspaces/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(1),
        position: 'relative'
    },
    link: {
        color: theme.colors.dodgerBlue,
        textDecoration: 'underline'
    },
    tabContent: {
        height: 'calc(100% - 48px - 32px)'
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    OVERVIEW: 'OVERVIEW',
    SETTING: 'SETTING'
};

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SourceDetail() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const tabClasses = useTabStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const source = useSourceById(id);

    const query = useQuery();

    const tab = React.useMemo(() => {
        return query?.get('tab');
    }, [query]);

    const isSetting = React.useMemo(() => {
        return tab === 'setting';
    }, [tab]);

    const [tabValue, setTabValue] = React.useState(isSetting ? TABS.SETTING : TABS.OVERVIEW);

    const handleChangeTab = React.useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    const handleBack = React.useCallback(() => {
        history.push('/integration-settings/connectors?tab=source');
    }, [history]);

    React.useEffect(() => {
        if (id) {
            dispatch(
                integrationActions.getSource({
                    sId: id,
                    success: nSource => {},
                    error: () => {}
                })
            );
        }
    }, [id, dispatch]);

    const handleCancel = React.useCallback(() => {
        setTabValue(TABS.OVERVIEW);
    }, []);

    React.useEffect(() => {
        dispatch(
            workspaceActions.fetchWorkspacesByGraphql({
                successCallback: () => {
                    console.log('fetch workspaces success');
                },
                errorCallback: () => {
                    console.log('fetch workspaces failed');
                }
            })
        );
    }, [dispatch]);

    return (
        <SettingContentLayout name={source?.name} onBack={handleBack}>
            <Grid container className={classes.root} direction="column">
                <Grid item style={{ marginBottom: theme.spacing(5) }}>
                    <Tabs className={classes.tabs} value={tabValue} onChange={handleChangeTab}>
                        <Tab classes={tabClasses} value={TABS.OVERVIEW} label={t('overview')} />
                        <Tab classes={tabClasses} value={TABS.SETTING} label={t('global_settings')} />
                    </Tabs>
                </Grid>
                {source && (
                    <Grid item className={classes.tabContent}>
                        {tabValue === TABS.OVERVIEW ? (
                            <Overview source={source} />
                        ) : (
                            <Setting onCancel={handleCancel} source={source} />
                        )}
                    </Grid>
                )}
            </Grid>
        </SettingContentLayout>
    );
}

export default SourceDetail;
