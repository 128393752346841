import React from 'react';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import Element from 'components/formula/components';
import ToolTipAutoQAClick from 'components/tooltip/ToolTipAutoQAClick';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useActiveCategories, useAutoQAByCategory } from 'hooks/gridUI';
import ListItem from 'components/list/Item';
import WarningRoundSVG from 'assets/images/svg/WarningRoundSVG';
import Divider from 'components/divider/Base';
import IgnoreSingleSVG from 'assets/images/svg/IgnoreSingleSVG';
import IgnoreSameSVG from 'assets/images/svg/IgnoreSameSVG';
import { useDispatch } from 'react-redux';
import { ignoreAutoQASingleError, openIgnoreSimilarErrorsConfirm } from 'gridUI/actions';
import autoQATooltips from 'components/lqa/autoQATooltips.json';
import { Editor, Transforms, createEditor, Text } from 'slate';
import Prism from 'prismjs';
import NonBreakingSpaceSVG from 'assets/images/svg/NonBreakingSpaceSVG';
import { regexSpecialCharacter, regexZeroWidthSpace } from 'const';

// eslint-disable-next-line
Prism.languages.token=Prism.languages.extend("markup",{}),Prism.languages.insertBefore("token","prolog",{}); // prettier-ignore

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        zIndex: 1,
        height: `100%`
    },
    nonBreakingSpace: {
        position: 'relative',
        zIndex: -1,
        letterSpacing: 9,
        borderRadius: 2,
        background: '#f8ad14',
        '& svg': {
            position: 'absolute',
            bottom: 0,
            left: 0
        }
    },
    zeroWidthSpace: {
        '&:before': {
            content: `'•'`
        }
    }
}));

function LQATargetHighlighter({
    value,
    onChange,
    placeholder,
    onBlur,
    onKeyDown,
    inputRef,
    validations,
    readOnly,
    rowId,
    columnId,
    onReplace,
    showTooltip = true,
    isHighlightSymbolCharacter,
    ...rest
}) {
    const classes = useStyles();
    const activeCategories = useActiveCategories();

    const validationsFiltered = React.useMemo(() => {
        return validations?.filter(v => !v?.ignored && activeCategories?.includes(v?.category));
    }, [validations, activeCategories]);
    const isPreventBlurRef = React.useRef(false);
    const renderedZeroWidthSpaceLeafRef = React.useRef({});

    const highlightToken = React.useMemo(() => {
        const tokenize =
            (Prism.languages.extend('markup', {}),
            Prism.languages.insertBefore('token', 'prolog', {
                ...(isHighlightSymbolCharacter
                    ? {
                          symbol: { pattern: new RegExp(regexSpecialCharacter), alias: 'punctuation' },
                          nonBreakingSpace: { pattern: new RegExp(/\u00A0/), alias: 'punctuation' },
                          zeroWidthSpace: { pattern: new RegExp(regexZeroWidthSpace), alias: 'punctuation' }
                      }
                    : {})
            }));
        return tokenize;
    }, [isHighlightSymbolCharacter]);

    const renderLeaf = props => (
        <Leaf
            showTooltip={showTooltip}
            onReplace={onReplace}
            isHighlightSymbolCharacter={isHighlightSymbolCharacter}
            renderedZeroWidthSpaceLeafRef={renderedZeroWidthSpaceLeafRef}
            {...props}
        />
    );

    const renderElement = React.useCallback(
        props => <Element isHighlightSymbolCharacter={isHighlightSymbolCharacter} {...props} />,
        [isHighlightSymbolCharacter]
    );

    const editor = React.useMemo(() => {
        return withReact(withHistory(createEditor()));
    }, []);

    const decorate = React.useCallback(
        ([node, path]) => {
            renderedZeroWidthSpaceLeafRef.current = {};
            const ranges = [];

            for (const validation of validationsFiltered) {
                ranges.push({
                    ...validation,
                    anchor: { path, offset: validation.start },
                    focus: { path, offset: validation.end },
                    rowId,
                    columnId,
                    selection: {
                        anchor: { path, offset: validation.start },
                        focus: { path, offset: validation.end }
                    }
                });
            }

            if (!Text.isText(node)) {
                return ranges;
            }

            const getLength = token => {
                if (typeof token === 'string') {
                    return token.length;
                } else if (typeof token.content === 'string') {
                    return token.content.length;
                } else {
                    return token.content.reduce((l, t) => l + getLength(t), 0);
                }
            };

            const tokens = Prism.tokenize(node.text, highlightToken);
            let start = 0;
            let i = 0;
            for (const token of tokens) {
                const length = getLength(token);
                const end = start + length;

                if (typeof token !== 'string') {
                    ranges.push({
                        [token.type]: true,
                        anchor: { path, offset: start },
                        focus: { path, offset: end },
                        index: i++
                    });
                }

                start = end;
            }

            return ranges;
        },
        [validationsFiltered, rowId, columnId, highlightToken]
    );

    const handleChange = React.useCallback(
        changedValue => {
            const isAstChange = editor.operations.some(op => 'set_selection' !== op.type);
            if (isAstChange) {
                onChange && onChange(changedValue);
            }
        },
        [onChange, editor]
    );

    React.useEffect(() => {
        if (inputRef && editor) {
            inputRef.current = editor;
        }
    }, [editor, inputRef]);

    const handleBlur = React.useCallback(() => {
        editor.blurSelection = editor.selection;
        setTimeout(() => {
            if (isPreventBlurRef.current) {
                isPreventBlurRef.current = false;
                return;
            }
            onBlur();
        }, 200);
    }, [onBlur, editor]);

    React.useEffect(() => {
        if (editor && !readOnly) {
            Transforms.select(editor, Editor.end(editor, []));
            ReactEditor.focus(editor);
        }
    }, [editor, readOnly]);

    return (
        <div className={classes.root} style={rest.rootStyle}>
            <Slate editor={editor} value={value} onChange={handleChange}>
                <Editable
                    id="editable-lqa"
                    onKeyDown={onKeyDown}
                    renderElement={renderElement}
                    onPaste={() => {}}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    decorate={decorate}
                    renderLeaf={renderLeaf}
                    itemRef={inputRef}
                    readOnly={readOnly}
                    {...rest}
                />
            </Slate>
        </div>
    );
}

const Leaf = ({
    attributes,
    children,
    leaf,
    onReplace,
    showTooltip,
    isHighlightSymbolCharacter,
    renderedZeroWidthSpaceLeafRef
}) => {
    const dispatch = useDispatch();
    const validation = leaf;
    const errorType = useAutoQAByCategory(validation.category);
    const classes = useStyles();

    const ignoreError = React.useCallback(
        async (e, validation) => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(
                ignoreAutoQASingleError({
                    errorId: validation.errorid,
                    rowId: validation.rowId,
                    columnId: validation.columnId,
                    successCallback: () => {},
                    errorCallback: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleReplaceSuggestion = React.useCallback(
        (e, validation, suggestion) => {
            e.stopPropagation();
            e.preventDefault();
            onReplace(e, validation, suggestion);
        },
        [onReplace]
    );

    const generateTargetError = () => {
        let validationError = autoQATooltips[validation.module].t;
        validationError = validationError.replace('##num##', validation.msg).replace('#xxx#', validation.msg);
        return validationError;
    };

    if (validation.category) {
        return showTooltip ? (
            <ToolTipAutoQAClick
                id="lqa-suggestion"
                title={
                    <>
                        <Grid container alignItems="center" style={{ padding: 8, paddingTop: 0 }}>
                            <Grid item style={{ display: 'flex' }}>
                                <WarningRoundSVG />
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                                <Typography variant="body1">{generateTargetError()}</Typography>
                            </Grid>
                        </Grid>
                        {(validation.suggestions || []).map(suggestion => (
                            <ListItem
                                key={suggestion}
                                name={suggestion}
                                onClick={e => handleReplaceSuggestion(e, validation, suggestion)}
                            />
                        ))}
                        <Divider />
                        <ListItem
                            name="Ignore this error"
                            icon={<IgnoreSingleSVG />}
                            onClick={e => ignoreError(e, validation)}
                        />
                        <ListItem
                            name="Ignore similar errors"
                            icon={<IgnoreSameSVG />}
                            onClick={() => dispatch(openIgnoreSimilarErrorsConfirm(validation))}
                        />
                    </>
                }
            >
                <span {...attributes} style={{ background: errorType?.color }}>
                    {children}
                </span>
            </ToolTipAutoQAClick>
        ) : (
            <span {...attributes} style={{ background: errorType?.color }}>
                {children}
            </span>
        );
    }

    if (leaf.zeroWidthSpace && isHighlightSymbolCharacter && !renderedZeroWidthSpaceLeafRef.current[leaf.index]) {
        renderedZeroWidthSpaceLeafRef.current[leaf.index] = true;
        return (
            <span {...attributes} className={classes.zeroWidthSpace} style={{ background: '#f8ad14', borderRadius: 2 }}>
                {children}
            </span>
        );
    }

    if (leaf.symbol && leaf.text && isHighlightSymbolCharacter) {
        return (
            <span {...attributes} style={{ background: '#f8ad14', borderRadius: 2 }}>
                {children}
            </span>
        );
    }

    if (leaf.nonBreakingSpace && leaf.text && isHighlightSymbolCharacter) {
        return (
            <span {...attributes} className={classes.nonBreakingSpace}>
                {children}
                <NonBreakingSpaceSVG />
            </span>
        );
    }

    if (leaf.nonBreakingSpace && leaf.text && isHighlightSymbolCharacter) {
        return (
            <span {...attributes} className={classes.nonBreakingSpace}>
                {children}
                <NonBreakingSpaceSVG />
            </span>
        );
    }

    return <span {...attributes}>{children}</span>;
};

export default LQATargetHighlighter;
