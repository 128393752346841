import React from 'react';

const InfoContainedIconSVG = ({ color = 'white', bgColor = '#7869B9', ...rest }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M0 8.00002C0 3.58005 3.58001 4.1008e-05 7.99998 4.1008e-05C12.42 4.1008e-05 16 3.58001 16 8.00002C16 12.42 12.42 16 7.99998 16C3.58001 16 0 12.42 0 8.00002Z"
                fill={color}
            />
            <path
                d="M0 8.00002C0 3.58005 3.58001 4.1008e-05 7.99998 4.1008e-05C12.42 4.1008e-05 16 3.58001 16 8.00002C16 12.42 12.42 16 7.99998 16C3.58001 16 0 12.42 0 8.00002Z"
                fill={bgColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00001 12.1993C8.55229 12.1993 9.00001 11.7516 9.00001 11.1993C9.00001 10.647 8.55229 10.1993 8.00001 10.1993C7.44772 10.1993 7 10.647 7 11.1993C7 11.7516 7.44772 12.1993 8.00001 12.1993ZM8.00001 8.99931C8.55229 8.99931 9.00001 8.55159 9.00001 7.99931L9.00001 4.79932C9.00001 4.24703 8.55229 3.79932 8.00001 3.79932C7.44772 3.79932 7 4.24703 7 4.79932V7.99931C7 8.55159 7.44772 8.99931 8.00001 8.99931Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(InfoContainedIconSVG);
