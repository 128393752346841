import request from './request';
import { APP_API_PREFIX_LICENSE, API_URL } from 'config';
import { downloadFileFromResponse } from 'utils/fileUtils';

const paymentApis = {
    card: `${APP_API_PREFIX_LICENSE}/api/v1/customers/cards`,
    getPublicKey: `${APP_API_PREFIX_LICENSE}/api/v1/stripe/public-key`,
    createSetupIntent: `${APP_API_PREFIX_LICENSE}/api/v1/stripe/create-setup-intent`,
    plans: `${APP_API_PREFIX_LICENSE}/api/v1/plans`,
    subscription: id => `${APP_API_PREFIX_LICENSE}/api/v1/subscriptions/${id}`,
    cancelSubscription: id => `${APP_API_PREFIX_LICENSE}/api/v1/subscriptions/${id}/cancel`,
    changePlan: subId => `${APP_API_PREFIX_LICENSE}/api/v1/subscriptions/${subId}/change-plan`,
    billingInfo: `${APP_API_PREFIX_LICENSE}/api/v1/billings`,
    billingHistory: `${APP_API_PREFIX_LICENSE}/api/v1/invoices`,
    receiptInfo: `${APP_API_PREFIX_LICENSE}/api/v1/invoices/monthly`,
    taxRate: `${APP_API_PREFIX_LICENSE}/api/v1/tax`,
    changePlanPreview: `${APP_API_PREFIX_LICENSE}/api/v1/subscriptions/preview`,
    futureSeatPreview: `${APP_API_PREFIX_LICENSE}/api/v1/invoices/preview/seat`,
    customer: `${APP_API_PREFIX_LICENSE}/api/v1/customers`,
    pdfInvoice: invoiceId => `${APP_API_PREFIX_LICENSE}/api/v1/invoices/invoice-pdf/${invoiceId}`,
    getAddonPlans: `${APP_API_PREFIX_LICENSE}/api/v1/addons`,
    buyAddonSubscription: `${APP_API_PREFIX_LICENSE}/api/v1/subscriptions/addons`,
    pdfFortnoxInvoice: id => `${APP_API_PREFIX_LICENSE}/api/v1/invoices/fortnox-invoice-pdf/${id}`,
    downloadAllFortnoxInvoice: ({ year, month }) =>
        `${APP_API_PREFIX_LICENSE}/api/v1/invoices/fortnox-invoice-zip?year=${year}&month=${month}`,
    addonUsage: `${APP_API_PREFIX_LICENSE}/api/v1/subscriptions/addons/usage`
};

export async function getCardApi() {
    const response = await request.get(paymentApis.card);
    return response.data;
}

export async function getPublicKeyApi() {
    const response = await request.get(paymentApis.getPublicKey);
    return response.data;
}

export async function createSetupIntentApi() {
    const response = await request.post(paymentApis.createSetupIntent);
    return response.data;
}

export async function getPlansApi() {
    const response = await request.get(paymentApis.plans);
    return response.data;
}

export async function cancelSubscriptionApi(id) {
    const response = await request.put(paymentApis.cancelSubscription(id));
    return response.data;
}

export async function getBillingInfoApi() {
    const response = await request.get(paymentApis.billingInfo);
    return response.data;
}

export async function updateBillingInfoApi({ body }) {
    const response = await request.put(paymentApis.billingInfo, body);
    return response.data;
}

export async function changePlanApi({ subscriptionId, plan, annually, totalSeat }) {
    const response = await request.patch(paymentApis.changePlan(subscriptionId), {
        plan,
        annually,
        totalSeat
    });
    return response.data;
}

export async function getBillingHistoryApi() {
    const response = await request.get(paymentApis.billingHistory);
    return response.data;
}

export async function getReceiptInfoApi({ month, year }) {
    const response = await request.get(`${paymentApis.receiptInfo}?month=${month}&year=${year}`);
    return response.data;
}

export async function getTaxRateApi() {
    const response = await request.get(paymentApis.taxRate);
    return response.data;
}

export async function getChangePlanPreviewApi({ totalSeat, annually, currency }) {
    const queryString = new URLSearchParams({ totalSeat, annually, currency }).toString();
    const response = await request.get(`${paymentApis.changePlanPreview}?${queryString}`);
    return response.data;
}

export async function getCustomerApi() {
    const response = await request.get(paymentApis.customer);
    return response.data;
}

export async function getPdfInvoiceApi(invoiceId) {
    window.open(`${API_URL}${paymentApis.pdfInvoice(invoiceId)}`, '_blank');
}

export async function getAddonPlansApi() {
    const response = await request.get(paymentApis.getAddonPlans);
    return response.data;
}

export async function buyAddonApi(body) {
    const response = await request.post(`${paymentApis.buyAddonSubscription}`, body);
    return response.data;
}

export async function getPdfInvoiceFortnoxApi(invoiceId) {
    window.open(`${API_URL}${paymentApis.pdfFortnoxInvoice(invoiceId)}`, '_blank');
}

export async function downloadAllPdfInvoiceFortnoxApi({ month, year }) {
    const response = await request.get(`${API_URL}${paymentApis.downloadAllFortnoxInvoice({ month, year })}`, {
        responseType: 'blob'
    });
    downloadFileFromResponse(response);
}

export async function getAddonUsageApi() {
    const response = await request.get(paymentApis.addonUsage);
    return response.data;
}
