import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isMac } from 'utils/os';
import MAC_KEYBOARD_LIST from './mac';
import WINDOWS_KEYBOARD_LIST from './windows';

const useStyles = makeStyles(theme => ({
    root: {
        width: 630
    },
    section: {
        paddingTop: 24
    },
    key: {
        borderRadius: 5,
        border: `1px solid ${theme.colors.border}`,
        background: theme.colors.ghostwhite,
        boxSizing: 'border-box',
        boxShadow: `1px 1px 0px rgba(0, 0, 0, 0.2)`,
        minWidth: 26,
        height: 26,
        paddingLeft: 7,
        paddingRight: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.dimGrey
    }
}));

function Keyboard({ keyboard }) {
    const { label, keys } = keyboard;
    const classes = useStyles();
    return (
        <Grid item className={classes.root}>
            <Grid container direction="row" alignItems="flex-start" justify="space-between">
                <Grid item xs={6}>
                    <Typography variant="body2">{label}</Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <Grid container spacing={2} alignItems="center">
                        {keys.map((key, index) => {
                            return (
                                <>
                                    <Grid key={key.toString()} item>
                                        <Grid container spacing={1}>
                                            {key.map(k => {
                                                return (
                                                    <Grid key={k} item>
                                                        <Typography className={classes.key} variant="body2">
                                                            {k}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    {index !== keys.length - 1 && (
                                        <Grid item>
                                            <Typography variant="caption">or</Typography>
                                        </Grid>
                                    )}
                                </>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

function KeyboardList() {
    const classes = useStyles();
    const KEYBOARD_LIST = isMac() ? MAC_KEYBOARD_LIST : WINDOWS_KEYBOARD_LIST;

    return (
        <Grid container className={classes.root} direction="column" wrap="nowrap">
            {Object.keys(KEYBOARD_LIST).map(section => {
                const keyboards = KEYBOARD_LIST[section];
                return (
                    <Grid key={section} item className={classes.section}>
                        <Grid container direction="column" spacing={3} wrap="nowrap">
                            <Grid item>
                                <Typography variant="h4">{section}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={3} wrap="nowrap">
                                    {keyboards.map(keyboard => {
                                        return <Keyboard key={keyboard.label} keyboard={keyboard} />;
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default KeyboardList;
