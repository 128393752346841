import React from 'react';

function RecordIdSVG({ color = '#6C75C5', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.73192 13.3929H4.79692V13.3279V12.5757V12.5238L4.74626 12.5123L3.58187 12.2489V4.09281L4.74697 3.8154L4.79692 3.80351V3.75217V3V2.935H4.73192H1H0.935V3V3.75217V3.80351L0.984945 3.8154L2.15004 4.09281V12.2489L0.98566 12.5123L0.935 12.5238V12.5757V13.3279V13.3929H1H4.73192ZM13.6122 12.0592L13.6122 12.0592C14.5842 11.1559 15.065 9.81977 15.065 8.06268C15.065 6.94598 14.8563 6.00861 14.4349 5.25392C14.0139 4.49045 13.4162 3.91224 12.6427 3.5206C11.87 3.12936 10.9579 2.935 9.90839 2.935H6.72614H6.66114V3V13.3279V13.3929H6.72614H9.60463C11.3129 13.3929 12.6518 12.9511 13.6122 12.0592ZM12.5539 5.12989L12.5546 5.13054C13.2208 5.74984 13.5608 6.73652 13.5608 8.10607C13.5608 9.46504 13.214 10.4724 12.5317 11.1406C11.8491 11.8091 10.8196 12.1491 9.43105 12.1491H8.09297V4.17879H9.72035C10.9533 4.17879 11.8945 4.49879 12.5539 5.12989Z"
                fill={color}
                stroke={color}
                strokeWidth="0.13"
            />
        </svg>
    );
}

export default React.memo(RecordIdSVG);
