import { useSelector } from 'react-redux';

export function useCardInfo() {
    return useSelector(state => state.payment?.cardInfo);
}

export function useIsFetchingCardInfo() {
    return useSelector(state => state.payment?.isFetchingCardInfo);
}

export function usePublicKey() {
    return useSelector(state => state.payment?.publicKey);
}
