import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PopperMenu from 'components/menus/Popper';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { useOpenRightSidebar, usePinnedSearch } from 'hooks/gridUI';
import { useParams } from 'react-router-dom';
import { sendManualTrack } from 'tracker';
import SearchRecordBox from '../searchRecord';
import { isKbSearch, isKbEscape } from 'utils/keyboard';
import { useDocumentEventListener } from 'hooks/useDocumentEventListener';
import SearchSVG from 'assets/images/svg/SearchSVG';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    menuItem: {
        // marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    searchBox: {
        height: 40,
        background: theme.colors.background,
        '& input': {
            fontSize: '14px !important',
            '&::placeholder': {
                color: `${theme.colors.disabledGrey} !important`,
                opacity: 1
            }
        }
    },
    popperSearchMenu: {
        top: '-35px !important',
        '& > div': {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    searchIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        padding: 8,
        borderRadius: 4,
        '&:hover': {
            background: theme.colors.hoverItem
        },
        '& svg': {
            position: 'relative',
            width: 18,
            height: 18,
            '& path': {
                fill: '#606066'
            }
        }
    }
}));

const ManageSearch = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { dbId, viewId } = useParams();
    const pinnedSearch = usePinnedSearch();
    const [searchValue, setSearchValue] = React.useState('');
    const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
    const searchRecordRef = React.useRef();
    const searchRef = React.useRef();
    const timerRef = React.useRef();
    const popperRef = React.useRef();
    const openRightSidebar = useOpenRightSidebar();

    React.useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    }, [openRightSidebar]);

    const handleSearchChange = React.useCallback(e => {
        const value = e?.target?.value;
        setSearchValue(value);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            sendManualTrack({ type: 'Search in Grid', customData: { searchText: value } });
        }, DEBOUNCE_TIME_SEARCHING);
    }, []);

    const handleClearSearch = React.useCallback(() => {
        setSearchValue('');
    }, []);

    const handleOpenSearchBox = React.useCallback(e => {
        setSearchAnchorEl(searchRecordRef?.current);
    }, []);

    const handleCloseSearchRecordBox = React.useCallback(e => {
        setSearchAnchorEl(null);
    }, []);

    const handleClickAwaySearch = React.useCallback(
        e => {
            if (!pinnedSearch) {
                handleCloseSearchRecordBox();
            }
        },
        [handleCloseSearchRecordBox, pinnedSearch]
    );

    const keydownHandler = React.useCallback(
        e => {
            if (isKbSearch(e)) {
                e.preventDefault();
                handleOpenSearchBox();
                if (searchRef.current) {
                    searchRef.current.focus();
                }
            }
            if (isKbEscape(e)) {
                handleClickAwaySearch();
            }
        },
        [handleOpenSearchBox, handleClickAwaySearch]
    );

    useDocumentEventListener('keydown', keydownHandler);

    return (
        <>
            <Grid
                ref={searchRecordRef}
                item
                id={'search_record'}
                className={classes.menuItem}
                onClick={handleOpenSearchBox}
            >
                <Tooltip title={`${t('find_in_grid')} (Ctrl / Cmd + F)`} placement="bottom-end">
                    <Grid item className={classes.searchIcon}>
                        <SearchSVG />
                    </Grid>
                </Tooltip>
            </Grid>
            {searchAnchorEl && (
                <PopperMenu
                    popperRef={popperRef}
                    id={'popper-searchBox'}
                    placement={'bottom-end'}
                    anchorEl={document.getElementById('grid-header-ghost')}
                    handleClickAway={handleClickAwaySearch}
                    className={classes.popperSearchMenu}
                >
                    <SearchRecordBox
                        dbId={dbId}
                        viewId={viewId}
                        onClose={handleCloseSearchRecordBox}
                        value={searchValue}
                        handleSearchChange={handleSearchChange}
                        handleClearSearch={handleClearSearch}
                    />
                </PopperMenu>
            )}
        </>
    );
};

export default React.memo(ManageSearch);
