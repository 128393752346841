import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NoDatabaseSVG from 'assets/images/svg/DatabaseEmptySVG';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    image: {},
    detail: {
        color: theme.colors.secondaryText,
        '& span': {
            color: theme.colors.blueberry,
            cursor: 'pointer'
        }
    }
}));

function NoDatabase() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid id="no-database" className={classes.root} spacing={3} container direction="column" alignItems="center">
            <Grid item>
                <NoDatabaseSVG className={classes.image} />
            </Grid>
            <Grid item>
                <Typography variant="h3" style={{ fontWeight: 'normal' }}>
                    {t(`no_database_available_title`)}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body2" className={classes.detail}>
                    {t(`no_database_available_subtitle`)}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default NoDatabase;
