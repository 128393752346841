import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { INPUT_HEIGHT, SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT } from 'const/style';
import NestedMenuItem from 'components/menus/HoverMenu';
import CountrySelect from './common/CountrySelect';
import { getLabelColumnType, getLocalizationColumnTypeLabel, TRANSLATION_TYPES } from 'gridUI/column-types';
import { useDispatch } from 'react-redux';
import * as importActions from 'gridUI/actions/import';
import ListItem from 'components/list/Item';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useLanguageOptionsWithUsageValidate } from 'hooks/app';
import RecordIdSVG from 'components/svg-icon/RecordIdSVG';
import PathTagSVG from 'components/svg-icon/PathTagSVG';
import { useTranslation } from 'react-i18next';
import InputText from 'components/inputs/InputText';
import {
    COLUMN_GROUP_SECTIONS,
    IMPORT_VALID_COLUMN_TYPES,
    IMPORT_WARNING_COLUMN_TYPES,
    SYSTEM_COLUMN_IDS
} from 'const';
import { isKbEnter, isKbEscape } from 'utils/keyboard';
import produce from 'immer';
import { useColumnTypes } from 'hooks/gridUI';
import { Trans } from 'react-i18next';
import Select from 'components/selects/Base';
import Control from 'components/selects/common/Control';
import DropdownIndicator from 'components/selects/common/DropdownIndicator';
import Menu from 'components/selects/common/Menu';
import MultiValue from 'components/selects/common/MultiValue';
import NoOptionsMessage from 'components/selects/common/NoOptionsMessage';
import Option from 'components/selects/common/Option';
import Placeholder from 'components/selects/common/Placeholder';
import SingleValue from 'components/selects/common/SingleValue';
import ValueContainer from 'components/selects/common/ValueContainer';
import SvgIcon from 'components/svg-icon/Base';
import { MULTIPLE_LINES } from 'const/columnTypes';

const useStyles = makeStyles(theme => ({
    root: {
        width: 300
    },
    item: {
        paddingRight: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        padding: SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT,
        height: INPUT_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            background: theme.colors.hoverItem
        }
    },
    inputWrapper: {
        padding: '0 10px',
        '& .MuiInputBase-root': {
            background: `${theme.colors.backgroundDisabled} !important`,
            border: 0
        }
    },
    error: {
        marginBottom: theme.spacing(2)
    },
    select: {
        width: 220
    }
}));

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    IndicatorSeparator: null
};

function ColumnSetup({
    handleClickAway,
    columnId,
    importType,
    country,
    onChange,
    isHasSource,
    isHasCharacterLimit,
    isHasOriginal,
    isHasStringIdentifer,
    isHasAdditionalInformations,
    isHasPathTag,
    isModeSingle,
    columnMap,
    columnIndex,
    column
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    const isItSelf = React.useCallback(
        ({ value }) => {
            return importType === value;
        },
        [importType]
    );

    const isSource = isItSelf({ value: TRANSLATION_TYPES.SOURCE_LANG });
    const isTarget = isItSelf({ value: TRANSLATION_TYPES.TARGET_LANG });
    const isStringIndentifier = isItSelf({ value: TRANSLATION_TYPES.RECORD_ID });
    const isPathTag = isItSelf({ value: TRANSLATION_TYPES.PATH_TAG });
    // const isAdditionalInformations = isItSelf({ value: TRANSLATION_TYPES.ADDITIONAL_INFO });
    // const isCharacterLimit = isItSelf({ value: TRANSLATION_TYPES.MAX_CHARACTERS });

    const langOptions = useLanguageOptionsWithUsageValidate();

    const defaultOption = React.useMemo(() => {
        if (!country) return null;
        return langOptions.find(option => option.value === country);
    }, [country, langOptions]);

    /**
     * TODO: later maybe index will start from 1
     */
    const nextColumnIndex = React.useMemo(() => {
        return columnId;
    }, [columnId]);

    const options = React.useMemo(() => {
        // const columnIds = Object.keys(columnMap);
        // const otherOptions = [];
        // columnIds.forEach(mapColId => {
        //     if (mapColId !== columnId) {
        //         const detail = columnMap[mapColId];
        //         if (
        //             detail?.importType &&
        //             [
        //                 TRANSLATION_TYPES.SOURCE_LANG,
        //                 TRANSLATION_TYPES.ORIGINAL_LANG,
        //                 TRANSLATION_TYPES.TARGET_LANG
        //             ].includes(detail?.importType)
        //         ) {
        //             otherOptions.push(detail?.country);
        //         }
        //     }
        // });
        return langOptions;
    }, [langOptions]);

    const handleSave = ({ body, name, isEmptyName }) => {
        dispatch(
            importActions.setLocalizationColumn({
                columnId,
                body: {
                    ...body,
                    index: nextColumnIndex,
                    name: isEmptyName ? '' : typeof name !== 'undefined' ? name : column?.name || ''
                }
            })
        );
    };

    const handleStringIdentifierSelect = () => {
        handleSave({
            isEmptyName: true,
            body: {
                importType: TRANSLATION_TYPES.RECORD_ID
            }
        });
        handleClickAway();
    };

    const handlePathTagSelect = () => {
        handleSave({
            isEmptyName: true,
            body: {
                importType: TRANSLATION_TYPES.PATH_TAG
            }
        });
        handleClickAway();
    };

    // const handleAdditionalInformationsSelect = () => {
    //     handleSave({
    //         body: {
    //             importType: TRANSLATION_TYPES.ADDITIONAL_INFO
    //         }
    //     });
    //     handleClickAway();
    // };

    // const handleCharacterLimitSelect = () => {
    //     handleSave({
    //         body: {
    //             importType: TRANSLATION_TYPES.MAX_CHARACTERS
    //         }
    //     });
    //     handleClickAway();
    // };

    const handleSourceLanguageChange = option => {
        if (isModeSingle) {
            const columnIds = Object.keys(columnMap);

            for (const columnId of columnIds) {
                const column = columnMap?.[columnId];
                if (column?.importType === TRANSLATION_TYPES.SOURCE_LANG) {
                    dispatch(
                        importActions.setLocalizationColumn({
                            columnId,
                            body: {
                                ...column,
                                country: undefined,
                                importType: undefined
                            }
                        })
                    );
                }
            }
        }

        handleSave({
            isEmptyName: true,
            body: {
                importType: TRANSLATION_TYPES.SOURCE_LANG,
                country: option.value
            }
        });

        handleClickAway();
    };

    const handleTargetLanguageChange = option => {
        handleSave({
            isEmptyName: true,
            body: {
                importType: TRANSLATION_TYPES.TARGET_LANG,
                country: option.value
            }
        });
        handleClickAway();
    };

    const resetColumn = () => {
        dispatch(
            importActions.setLocalizationColumn({
                columnId,
                body: {
                    ...column,
                    name: '',
                    country: undefined,
                    importType: undefined
                }
            })
        );
        handleClickAway();
    };

    const isHiddenInputText = React.useMemo(() => {
        return [...(SYSTEM_COLUMN_IDS || []), TRANSLATION_TYPES.SOURCE_LANG, TRANSLATION_TYPES.TARGET_LANG].includes(
            importType
        );
    }, [importType]);

    const handleSetName = name => {
        handleSave({
            name,
            body: {
                ...column,
                importType: column.importType || MULTIPLE_LINES
            }
        });
    };

    const handleOnKeyDown = e => {
        if (isKbEscape(e) || isKbEnter(e)) {
            handleClickAway();
            return;
        }
        return;
    };

    let columnTypes = useColumnTypes([COLUMN_GROUP_SECTIONS.BASIC, COLUMN_GROUP_SECTIONS.CODE_FORMATTING]);

    const validOptions = React.useMemo(() => {
        return produce(columnTypes, draft => {
            for (let group of draft) {
                group.options = group?.options
                    ?.filter(option =>
                        [...IMPORT_VALID_COLUMN_TYPES, ...IMPORT_WARNING_COLUMN_TYPES]?.includes(option?.value)
                    )
                    ?.map(option => {
                        if (IMPORT_WARNING_COLUMN_TYPES?.includes(option?.value)) {
                            return {
                                ...option,
                                isDisabled: true,
                                disabledTitle: (
                                    <Typography variant="body2">
                                        <Trans i18nKey="localization_upload_datatype_not_supported">
                                            Uploading this data type is <br /> not supported for now.
                                        </Trans>
                                    </Typography>
                                ),
                                placement: 'right'
                            };
                        }
                        return option;
                    });
            }
            return draft;
        });
    }, [columnTypes]);

    const handleColumnTypeChange = type => {
        handleSave({
            body: {
                importType: type.value
            }
        });
        handleClickAway();
    };

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit'
            }
        })
    };

    const filterOption = ({ label, value }, string) => {
        if (
            label?.toLocaleLowerCase()?.includes(string?.toLocaleLowerCase()) ||
            value?.toLocaleLowerCase()?.includes(string?.toLocaleLowerCase())
        )
            return true;
        const groupOptions = options.filter(group =>
            group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())
        );
        if (groupOptions) {
            for (const groupOption of groupOptions) {
                const option = groupOption?.options?.find(opt => opt.value === value);
                if (option) {
                    return true;
                }
            }
        }
        return false;
    };

    const defaultValue = React.useMemo(() => {
        return {
            value: importType,
            label: getLabelColumnType(importType),
            icon: () => <SvgIcon type={importType} />
        };
    }, [importType]);

    const isDuplicateName = React.useMemo(() => {
        const names = Object.keys(columnMap)
            ?.filter(key => columnMap?.[key]?.index !== column?.index)
            ?.map(key => columnMap?.[key]?.name);
        return column?.name && names?.includes(column?.name);
    }, [column, columnMap]);

    return (
        <Grid ref={rootRef} container direction="column" wrap="nowrap" className={classes.root}>
            {!isHiddenInputText && (
                <Grid item className={`${classes.inputWrapper} ${isDuplicateName ? classes.error : ''}`}>
                    <InputText
                        name="columnName"
                        value={column?.name || ''}
                        onChange={e => handleSetName(e.target.value)}
                        placeholder="Input column name"
                        autoFocus
                        onKeyDown={handleOnKeyDown}
                        autoSelect={true}
                        error={isDuplicateName}
                        errorText={isDuplicateName ? t(`column_format_column_name_warning`) : ``}
                    />
                </Grid>
            )}
            <Grid item className={classes.item} onClick={resetColumn}>
                <Typography variant="caption">{t('localization_setup_reset_action')}</Typography>
            </Grid>

            <Grid item className={classes.content}>
                <Grid container direction="column" wrap="nowrap">
                    {(!isHasStringIdentifer || isStringIndentifier) && (
                        <ListItem
                            icon={<RecordIdSVG color={theme.colors.systemIconColor} />}
                            onClick={handleStringIdentifierSelect}
                            name={getLocalizationColumnTypeLabel({
                                value: TRANSLATION_TYPES.RECORD_ID
                            })}
                            isSelected={isStringIndentifier}
                        />
                    )}
                    {(!isHasPathTag || isPathTag) && (
                        <ListItem
                            icon={<PathTagSVG color={theme.colors.systemIconColor} />}
                            onClick={handlePathTagSelect}
                            name={getLocalizationColumnTypeLabel({
                                value: TRANSLATION_TYPES.PATH_TAG
                            })}
                            isSelected={isPathTag}
                        />
                    )}
                    <NestedMenuItem
                        label={getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.SOURCE_LANG })}
                        parentMenuOpen={true}
                    >
                        <CountrySelect
                            defaultValue={isSource ? defaultOption : null}
                            options={options}
                            onChange={handleSourceLanguageChange}
                        />
                    </NestedMenuItem>

                    <NestedMenuItem
                        label={getLocalizationColumnTypeLabel({ value: TRANSLATION_TYPES.TARGET_LANG })}
                        parentMenuOpen={true}
                    >
                        <CountrySelect
                            defaultValue={isTarget ? defaultOption : null}
                            options={options}
                            onChange={handleTargetLanguageChange}
                        />
                    </NestedMenuItem>

                    <NestedMenuItem label="Other column" parentMenuOpen={true}>
                        <Select
                            className={classes.select}
                            cacheOptions
                            options={validOptions}
                            filterOption={filterOption}
                            autoFocus
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            placeholder={t('column_format_data_type_search')}
                            isClearable={false}
                            tabSelectsValue={false}
                            components={components}
                            defaultValue={defaultValue}
                            styles={selectStyles}
                            onChange={handleColumnTypeChange}
                            menuIsOpen
                            isMulti={false}
                        />
                    </NestedMenuItem>

                    {/* <ListItem
                        onClick={handleAdditionalInformationsSelect}
                        name={getLocalizationColumnTypeLabel({
                            value: TRANSLATION_TYPES.ADDITIONAL_INFO
                        })}
                        isSelected={isAdditionalInformations}
                    />
                    <ListItem
                        onClick={handleCharacterLimitSelect}
                        name={getLocalizationColumnTypeLabel({
                            value: TRANSLATION_TYPES.MAX_CHARACTERS
                        })}
                        isSelected={isCharacterLimit}
                    /> */}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(ColumnSetup);
