import React from 'react';

function MemoQSVG({ color = '#17B7FF', ...other }) {
    return (
        <svg width="64" height="65" viewBox="0 0 200 201" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M133.335 100.078C133.335 88.38 131.326 77.1528 127.641 66.7187H127.629C121.357 48.9647 110.223 33.5099 95.8274 21.9582C95.8024 21.9384 95.7779 21.9184 95.7529 21.8987C94.3483 20.7739 92.9128 19.6867 91.4483 18.6377C91.3863 18.5936 91.3243 18.5499 91.2628 18.5059C90.5578 18.0034 89.8468 17.5089 89.1278 17.0245C89.1123 17.0135 89.0963 17.0025 89.0798 16.9918C88.3478 16.4986 87.6073 16.0156 86.8613 15.5416C86.7728 15.4854 86.6833 15.4299 86.5943 15.3739C85.9288 14.9537 85.2578 14.5415 84.5818 14.1368C84.4898 14.0816 84.3983 14.0256 84.3058 13.9706C83.5697 13.5332 82.8277 13.1054 82.0802 12.6866C81.9752 12.6278 81.8692 12.5707 81.7637 12.5125C81.1132 12.1511 80.4582 11.7977 79.7987 11.4509C79.6537 11.3746 79.5092 11.2974 79.3637 11.2219C78.6187 10.8344 77.8687 10.4549 77.1127 10.0861C77.0097 10.0356 76.9052 9.9873 76.8017 9.93731C76.1417 9.61796 75.4777 9.30682 74.8102 9.00174C74.6282 8.91852 74.4467 8.83531 74.2642 8.75325C73.5047 8.41163 72.7411 8.07717 71.9716 7.75392C71.8976 7.7229 71.8231 7.69383 71.7486 7.6628C71.0471 7.37033 70.3406 7.08696 69.6306 6.81014C69.4296 6.73188 69.2286 6.65387 69.0271 6.57671C68.2456 6.27798 67.4611 5.98496 66.6706 5.70514H66.6701C27.8299 19.4429 0 56.5048 0 100.079C0 111.777 2.01238 123.003 5.6983 133.438C19.4272 94.5697 56.461 66.7197 100.001 66.7197C118.411 66.7187 133.335 81.6543 133.335 100.078Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M66.7118 101.81C66.6823 101.236 66.6668 100.658 66.6668 100.078C66.6668 100.06 66.6703 100.044 66.6703 100.026C66.6963 81.6276 81.606 66.7196 99.9956 66.7191C98.6021 66.7191 97.2146 66.7496 95.8346 66.8062H95.8341C93.2411 66.9122 90.6736 67.1189 88.135 67.4196C88.116 67.4221 88.0965 67.4231 88.076 67.4256C85.6065 67.7194 83.1665 68.1107 80.755 68.5815C80.648 68.6025 80.539 68.6191 80.432 68.6401C78.0894 69.1054 75.7799 69.6609 73.4999 70.2863C73.3334 70.3324 73.1639 70.3724 72.9979 70.4189C70.7729 71.0424 68.5824 71.749 66.4223 72.5191C66.2128 72.5941 66.0008 72.6642 65.7913 72.7402C63.6743 73.5118 61.5938 74.3595 59.5463 75.2672C59.3128 75.3713 59.0768 75.4704 58.8438 75.5759C56.8313 76.4871 54.8562 77.4689 52.9162 78.5072C52.6672 78.6403 52.4177 78.7704 52.1702 78.9055C50.2592 79.9493 48.3886 81.0572 46.5562 82.2196C46.3024 82.3807 46.0478 82.5408 45.7951 82.7044C43.9846 83.8759 42.2151 85.1058 40.4872 86.3888C40.24 86.5724 39.9933 86.7566 39.748 86.9422C38.0361 88.2382 36.3678 89.5868 34.7445 90.9879C34.5091 91.191 34.2764 91.3962 34.0424 91.6018C32.4316 93.0184 30.8643 94.4841 29.3475 96.0002C29.134 96.2139 28.9236 96.4306 28.712 96.6462C27.2004 98.1849 25.7343 99.7686 24.3234 101.402C24.1382 101.617 23.9585 101.835 23.7746 102.051C22.3648 103.713 21.0027 105.416 19.7021 107.167C19.5479 107.375 19.3992 107.588 19.2466 107.797C17.9433 109.581 16.6901 111.405 15.5039 113.277C15.3856 113.463 15.2737 113.655 15.156 113.843C13.9605 115.758 12.8204 117.71 11.7538 119.708C11.6745 119.856 11.6018 120.008 11.5231 120.156C10.4406 122.209 9.41903 124.3 8.47732 126.434C8.43752 126.524 8.40267 126.617 8.36287 126.707C7.39866 128.911 6.50471 131.153 5.698 133.437C19.4266 172.305 56.4608 200.155 100.001 200.155C111.69 200.155 129.167 200.155 141.668 200.155C103.345 186.598 67.6519 144.779 66.7118 101.81Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M133.333 100.077C133.333 118.231 118.84 132.988 100.803 133.416C100.862 133.43 100.673 133.436 99.9991 133.436H129.166C150.226 133.436 168.992 143.219 181.209 158.479C193.032 142.043 200 121.875 200 100.077C200 99.2747 199.988 98.4751 199.969 97.677C199.963 97.4068 199.952 97.1376 199.943 96.8674C199.926 96.3425 199.908 95.8176 199.883 95.2942C199.868 94.9694 199.849 94.6452 199.831 94.3214C199.805 93.8576 199.776 93.3942 199.743 92.9323C199.718 92.5816 199.692 92.2318 199.664 91.8825C199.629 91.4527 199.59 91.0239 199.55 90.5955C199.515 90.2278 199.481 89.86 199.443 89.4932C199.401 89.0964 199.354 88.7016 199.307 88.3058C199.262 87.915 199.219 87.5237 199.169 87.1349C199.163 87.0893 199.155 87.0443 199.149 86.9993C198.995 85.8163 198.816 84.6409 198.621 83.471C198.584 83.2534 198.551 83.0347 198.513 82.818C198.422 82.2951 198.324 81.7747 198.225 81.2543C198.186 81.0452 198.146 80.835 198.105 80.6258C197.999 80.0874 197.888 79.551 197.774 79.0161C197.737 78.846 197.7 78.6768 197.663 78.5077C197.537 77.9327 197.407 77.3588 197.271 76.7874C197.253 76.7138 197.235 76.6407 197.217 76.5677C186.649 32.641 147.136 0 99.9991 0C88.31 0 77.0924 2.01392 66.6658 5.70266C105.504 19.4417 133.333 56.5042 133.333 100.077Z"
                fill="url(#paint2_linear)"
            />
            <path
                d="M129.169 133.437H100.003C82.1735 133.437 67.6144 119.428 66.7139 101.81C67.5544 140.208 96.1506 177.67 129.572 194.884C130.366 195.293 131.163 195.692 131.962 196.079C132.281 196.234 132.602 196.382 132.923 196.533C133.653 196.877 134.385 197.217 135.118 197.541C135.625 197.764 136.133 197.975 136.641 198.188C137.208 198.427 137.774 198.672 138.342 198.899C139.449 199.339 140.558 199.761 141.67 200.155H195.837C195.837 163.307 165.989 133.437 129.169 133.437Z"
                fill="url(#paint3_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="94.1308"
                    y1="15.7042"
                    x2="45.9111"
                    y2="148.085"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="45.5797"
                    y1="80.1019"
                    x2="95.4419"
                    y2="216.993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="133.333"
                    y1="158.479"
                    x2="133.333"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="66.7139"
                    y1="150.982"
                    x2="195.837"
                    y2="150.982"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF3F3F" />
                    <stop offset="1" stopColor="#F47623" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default React.memo(MemoQSVG);
