import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    placeholder: {
        position: 'absolute',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        left: 10,
        bottom: 6,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        width: `calc(100% - 24px)`,
        textOverflow: 'ellipsis'
    }
}));

function Placeholder(props) {
    const classes = useStyles();
    const { innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired
};

export default React.memo(Placeholder);
