import React from 'react';

function TextSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.116 10.9389H5.86898L4.98042 13.5H3L7.14157 2.5H8.8509L13 13.5H11.012L10.116 10.9389ZM6.40361 9.39766H9.58133L7.99247 4.83448L6.40361 9.39766Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TextSVG);
