import React from 'react';

function LambdaSVG({ ...other }) {
    return (
        <svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M29.5627 26.7208L17.6973 1.10947C17.5442 0.778671 17.2998 0.498499 16.9928 0.301942C16.6858 0.105385 16.3291 0.000621166 15.9646 0H6.79354C6.2853 0 5.79787 0.201902 5.43848 0.561286C5.0791 0.92067 4.8772 1.4081 4.8772 1.91635V7.34479C4.8772 7.85337 5.07898 8.34117 5.43826 8.70113C5.79754 9.06108 6.28496 9.26378 6.79354 9.26473H9.49876C9.86797 9.26493 10.2293 9.37179 10.5392 9.57245C10.8491 9.7731 11.0945 10.059 11.2458 10.3958L22.2288 34.8544C22.3807 35.1915 22.6267 35.4775 22.9372 35.6782C23.2478 35.8788 23.6097 35.9855 23.9794 35.9855H30.6542C30.9717 35.9853 31.2841 35.9062 31.5635 35.7553C31.8428 35.6045 32.0803 35.3865 32.2546 35.1212C32.4289 34.8558 32.5345 34.5513 32.562 34.235C32.5895 33.9187 32.538 33.6005 32.412 33.3091L29.5627 26.7208Z"
                fill="#3785FC"
            />
            <path
                d="M8.29209 16.808L0.198054 33.2266C0.0538399 33.5193 -0.0135394 33.8439 0.00225492 34.1698C0.0180493 34.4957 0.116498 34.8123 0.288339 35.0897C0.460181 35.3671 0.699766 35.5962 0.984554 35.7555C1.26934 35.9148 1.58997 35.999 1.91628 36.0002H8.03994C8.38992 36 8.73319 35.9042 9.03279 35.7233C9.33239 35.5424 9.57696 35.2832 9.74015 34.9736L14.7832 25.4639C14.9197 25.207 14.9961 24.9224 15.0067 24.6317C15.0173 24.3409 14.9618 24.0515 14.8444 23.7853L11.7826 16.8764C11.6349 16.5403 11.3942 16.2535 11.0888 16.0499C10.7835 15.8462 10.4262 15.7341 10.0592 15.7269C9.6922 15.7197 9.33081 15.8177 9.01769 16.0093C8.70457 16.2008 8.45279 16.4779 8.29209 16.808Z"
                fill="#4DCA69"
            />
        </svg>
    );
}

export default React.memo(LambdaSVG);
