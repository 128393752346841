import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import InputText from 'components/inputs/InputText';
import { useDispatch } from 'react-redux';
import { updateActionNode } from '../../action';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { ACTION_STATUS } from 'gridUI/automations/const';
import { DISABLED_OPACITY } from 'const/style';
import Payloadv2 from './Payloadv2';

const useStyles = makeStyles(theme => ({
    root: {},
    flex: { display: 'flex' },
    mb12: {
        marginBottom: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    mb8: {
        marginBottom: 8
    },
    addHeader: {
        color: theme.palette.primary.main
    },
    disabled: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

const ActionSlack = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { params = {} } = selectedNode;
    const { incomingWebhookUrl, channels = '' } = params;
    const [url, setUrl] = useState(incomingWebhookUrl);
    const [channel, setChannel] = useState(channels);
    const timeout = useRef();

    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);

    const errorMessage = nodeError?.url || '';

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        setUrl(incomingWebhookUrl || '');
        setChannel(channels || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const onChannelChange = useCallback(
        e => {
            const value = e.target.value;
            setChannel(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                channels: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 800);
        },
        [automationDetail.id, dispatch, selectedNode, setSelectedNode]
    );

    const onUrlChange = useCallback(
        e => {
            const value = e.target.value;
            setUrl(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                incomingWebhookUrl: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 800);
        },
        [automationDetail.id, dispatch, selectedNode, setSelectedNode]
    );

    return (
        <Grid item className={classes.root}>
            <Grid item className={classes.mb8}>
                <InputText
                    name="url"
                    label={t('automation_incoming_webhook_url')}
                    value={url}
                    error={Boolean(errorMessage)}
                    errorText={errorMessage}
                    onChange={onUrlChange}
                    placeholder={t('automation_incoming_webhook_url')}
                    disableErrorAbsolute
                    labelClassName={classes.mb14}
                    inputWrapperClassName={isDisabled ? classes.disabled : ''}
                    tooltipTitle={isDisabled ? t('automation_tooltip_action_disable') : ''}
                    placement="left"
                    required
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <InputText
                    name="channel"
                    label={t('global_channels')}
                    value={channel}
                    onChange={onChannelChange}
                    placeholder={t('channels_placeholder')}
                    labelClassName={classes.mb14}
                    placement="left"
                />
            </Grid>

            <Grid item>
                <Payloadv2 selectedNode={selectedNode} setSelectedNode={setSelectedNode} showHowToCustomize />
            </Grid>
        </Grid>
    );
};

export default React.memo(ActionSlack);
