import React from 'react';

function SamlLoginSVG() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.618 4.62487C12.618 2.0747 10.5443 0 7.99561 0C5.44678 0 3.37317 2.0747 3.37317 4.62487C3.37317 6.20444 4.16895 7.60141 5.38013 8.43608C4.24927 8.82655 3.21265 9.47154 2.3418 10.3428C0.831665 11.8538 0 13.8628 0 15.9995H1.24927C1.24927 12.2777 4.27563 9.24974 7.99561 9.24974C10.5443 9.24974 12.618 7.17504 12.618 4.62487ZM7.99561 7.99982C6.13562 7.99982 4.62244 6.48584 4.62244 4.62487C4.62244 2.76391 6.13562 1.24993 7.99561 1.24993C9.85547 1.24993 11.3687 2.76391 11.3687 4.62487C11.3687 6.48584 9.85547 7.99982 7.99561 7.99982ZM16 11.4997L13.0597 14.4416L12.1763 13.5577L13.6398 12.0934H12.5563C11.0594 12.0934 9.84058 13.3119 9.83936 14.8096L9.83826 16L8.58899 15.999L8.58997 14.8085C8.5918 12.6221 10.3711 10.8434 12.5563 10.8434H13.5773L12.1763 9.44162L13.0597 8.55785L16 11.4997Z"
                fill="#7869B9"
            />
        </svg>
    );
}

export default React.memo(SamlLoginSVG);
