import React from 'react';
import { Grid } from '@material-ui/core';
import EmptyGroupAccessSVG from 'assets/images/svg/EmptyGroupAccessSVG';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: { marginTop: 150 }
}));

function NoMember({ className }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid className={`${classes.root} ${className}`} container direction="column" spacing={2} alignItems="center">
            <Grid item>
                <EmptyGroupAccessSVG />
            </Grid>
            <Grid item>
                <h4 className="prose prose-lg font-medium">{t(`share_view_no_member_invited`)}</h4>
            </Grid>
            <Grid item>
                <Grid container direction="column" wrap="nowrap" justify="center" alignItems="center">
                    <Grid item>
                        <p className="caption">{t(`share_view_no_member_invited_description`)}</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(NoMember);
