import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import ListItem from 'components/list/Item';
import SettingSVG from 'assets/images/svg/SettingSVG';
import DeleteSVG from 'assets/images/svg/DeleteSVG';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { useTranslation } from 'react-i18next';

function Popup({ source, onClickAway, onEdit, onDelete }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const rootRef = React.useRef();

    useClickAwaitListener(rootRef, e => {
        onClickAway(e);
    });

    const stopPropagation = React.useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onEditHandler = React.useCallback(
        e => {
            stopPropagation(e);
            onEdit && onEdit();
        },
        [stopPropagation, onEdit]
    );

    const onDeleteHandler = React.useCallback(
        e => {
            stopPropagation(e);
            onDelete && onDelete();
        },
        [stopPropagation, onDelete]
    );

    return (
        <Grid ref={rootRef} container direction="column">
            <Grid item onClick={onEditHandler}>
                <ListItem icon={() => <SettingSVG />} name={t('edit_connection')} />
            </Grid>
            <Grid item onClick={onDeleteHandler}>
                <ListItem
                    icon={() => <DeleteSVG color={theme.colors.snackBarError} />}
                    name={
                        <Typography variant="body2" style={{ color: theme.colors.snackBarError }}>
                            {t('delete_connection')}
                        </Typography>
                    }
                />{' '}
            </Grid>
        </Grid>
    );
}

export default Popup;
