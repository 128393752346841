import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LogoGridlySVG from 'assets/images/svg/LogoGridlySVG';
import AuthBgIconLeftSVG from 'assets/images/auth-bkg-icon-left.svg';
import AuthBgIconCenterSVG from 'assets/images/auth-bkg-icon-center.svg';
import AuthBgIconRightSVG from 'assets/images/auth-bkg-icon-right.svg';

import { Grid } from '@material-ui/core';
import { HEADER_HEIGHT, API_REQUEST_STATUS_LABEL } from 'const';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useMediaQueries from '../hooks/useMediaQueries';
import { useAppApiStatus } from 'hooks/app';
import ApiStatusSVG from 'assets/images/svg/ApiStatusSVG';
import { getColorByStatusApi } from 'utils/color';
import hexToRgba from 'hex-to-rgba';
import { INPUT_HEIGHT } from 'const/style';
import Tooltip from 'components/tooltip/Base';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        position: 'relative',
        minHeight: '100vh',
        width: '100%'
    },
    body: {
        width: '100%',
        justifyContent: 'center',
        padding: '5vh 30px',

        flexWrap: 'wrap',
        overflowY: 'auto',
        height: `calc(100vh - ${HEADER_HEIGHT}px)`
    },
    pageContent: {
        zIndex: 1,
        marginBottom: 100,

        [theme.breakpoints.down('xs')]: {
            marginBottom: 'inherit'
        }
    },
    btnLink: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '-6px'
        }
    },
    header: {
        padding: '50px 60px',
        borderRadius: 0,
        height: HEADER_HEIGHT,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            padding: '30px 30px'
        }
    },
    footer: {
        width: '100%',
        height: '20vh',
        maxHeight: 100,
        //overflow: 'hidden',
        position: 'absolute',
        left: 0,
        bottom: 40,

        '& .bg-icon': {
            backgroundSize: 'cover'
        },

        '& .left': {
            width: 250,
            height: 180,
            background: `url(${AuthBgIconLeftSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        '& .center': {
            width: 568,
            height: 135,
            background: `url(${AuthBgIconCenterSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        '& .right': {
            width: 248,
            height: 183,
            background: `url(${AuthBgIconRightSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },

        [theme.breakpoints.down('sm')]: {
            bottom: 20
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    pointWrapper: {
        width: 12,
        height: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6
    },
    point: {
        width: 6,
        height: 6,
        borderRadius: 4
    },
    status: {
        height: INPUT_HEIGHT,
        borderRadius: 4,
        border: `1px solid ${theme.colors.border}`,
        marginRight: theme.spacing(3),
        cursor: 'pointer'
    },
    statusIcon: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        display: 'flex'
    },
    statusLink: {
        textDecoration: 'none'
    }
}));

function AuthenticationWrapper({ hideFooter, isSigningIn, isSigningUp, isGetStarted, isForgotPassword, children }) {
    const { isMobile } = useMediaQueries();
    const classes = useStyles();
    const { t } = useTranslation();
    const apiStatus = useAppApiStatus();
    const theme = useTheme();

    const statusColor = React.useMemo(() => {
        return getColorByStatusApi({ theme, apiStatus });
    }, [apiStatus, theme]);

    return (
        <Grid className={classes.root} container direction={'column'}>
            <Grid item container justify="space-between" className={classes.header}>
                <Grid item>
                    <LogoGridlySVG />
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                        {!isMobile && !isExternal && (
                            <Tooltip title={t('gridly_server_status')}>
                                <Link
                                    to={{ pathname: 'https://status.gridly.com/' }}
                                    target="_blank"
                                    item
                                    className={
                                        'no-underline btn btn-outlined mr-3.5 flex flex-row gap-2 items-center rounded border-app cursor-pointer'
                                    }
                                >
                                    <ApiStatusSVG size={16} color={theme.colors.steel} />
                                    <span
                                        className={'flex items-center justify-center w-3 h-3 rounded-full'}
                                        style={{ background: hexToRgba(statusColor, 0.3) }}
                                    >
                                        <span className={classes.point} style={{ background: statusColor }} />
                                    </span>
                                    <p>{API_REQUEST_STATUS_LABEL?.[apiStatus]}</p>
                                </Link>
                            </Tooltip>
                        )}
                        {isSigningIn && !isExternal && (
                            <Grid item>
                                <Link to="/getstarted" className="btn btn-outlined w-[120px]">
                                    {t(`global_sign_up`)}
                                </Link>
                            </Grid>
                        )}
                        {(isGetStarted || isSigningUp || isForgotPassword) && (
                            <Grid item>
                                <Link to="/signin" className="btn btn-outlined w-[120px]">
                                    {t(`global_sign_in`)}
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.body}>
                <Grid container direction={'column'} alignItems="center">
                    <Grid item className={classes.pageContent}>
                        {children}
                    </Grid>
                    {!isMobile && (
                        <Grid item>
                            <Grid
                                className={classes.footer}
                                container
                                direction={'row'}
                                justify="space-between"
                                alignItems="flex-end"
                            >
                                <Grid item className="bg-icon left"></Grid>
                                <Grid item className="bg-icon center"></Grid>
                                <Grid item className="bg-icon right"></Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AuthenticationWrapper;
