import React from 'react';
import MemSource from './memSource/MemSource';
import MemoQ from './memoQ/MemoQ';
import LQA from './LQA/LQA';
import TranslationOverview from './translation/TranslationOverview';
import { ADD_ON_NAME } from 'const/inAppAddon';
import AddonDetail from 'addons/automation/AddonDetail';

function RenderInAppAddon({ addon, onBack, onClose }) {
    switch (addon.name) {
        case ADD_ON_NAME.MEM_SOURCE:
            return <MemSource addon={addon} onBack={onBack} onClose={onClose} />;
        case ADD_ON_NAME.TRANSLATION_DASHBOARD:
            return <TranslationOverview addon={addon} onBack={onBack} />;
        case ADD_ON_NAME.MEMO_Q:
            return <MemoQ addon={addon} onBack={onBack} onClose={onClose} />;
        case ADD_ON_NAME.LQA:
            return <LQA addon={addon} onBack={onBack} />;
        case ADD_ON_NAME.AUTO_QA:
            return <AddonDetail addon={addon} onBack={onBack} onClose={onClose} />;
        default:
            return null;
    }
}

export default React.memo(RenderInAppAddon);
