import React from 'react';

function LQAIssueType({ color = '#A69FC4', ...other }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M20.609 8.91992C21.4844 8.91992 22.202 9.59577 22.2683 10.4542L22.2733 10.5843V20.8104C22.2733 21.1647 21.873 21.3544 21.6015 21.1601L21.5455 21.1125L19.8985 19.4654C19.8385 19.406 19.7627 19.3656 19.6808 19.3492L19.5973 19.3408L10.4744 19.3169C9.83136 19.3169 9.30162 18.825 9.24152 18.1982L9.23584 18.0792V10.5843C9.23584 9.70881 9.91168 8.99128 10.7701 8.92493L10.9002 8.91992H20.609Z"
                fill="#5DCDE3"
            />
            <path
                d="M17.191 16.2391H14.4783L13.9625 17.7863H12.3174L15.1126 10.2773H16.5463L19.357 17.7863H17.7119L17.191 16.2391ZM14.896 14.9859H16.7732L15.8295 12.1752L14.896 14.9859Z"
                fill="white"
            />
            <path
                d="M3.39101 2.76172C2.51556 2.76172 1.79803 3.43756 1.73168 4.29599L1.72667 4.42606V14.6522C1.72667 15.0065 2.12704 15.1962 2.39846 15.0018L2.45446 14.9542L4.10147 13.3072C4.1615 13.2478 4.23731 13.2074 4.31916 13.191L4.40266 13.1826L13.5256 13.1587C14.1686 13.1587 14.6984 12.6668 14.7585 12.04L14.7642 11.921V4.42606C14.7642 3.55061 14.0883 2.83308 13.2299 2.76673L13.0998 2.76172H3.39101Z"
                fill="#7869B9"
            />
            <path
                d="M6.7921 6.44784C7.13272 7.34297 7.58425 8.08759 8.1546 8.70547C8.76456 8.06383 9.23193 7.30336 9.57255 6.44784H6.7921ZM4.54239 6.44784V5.52894H7.68723V4.27734H8.64573V5.52894H11.751V6.44784H10.6182C10.1904 7.60438 9.64384 8.59457 8.93091 9.41048C9.73098 10.0204 10.7212 10.472 11.8936 10.7809C11.7034 11.0106 11.4103 11.4542 11.2994 11.6998C10.0954 11.3196 9.0735 10.8047 8.21005 10.1155C7.37829 10.8205 6.34849 11.3513 5.07313 11.676C4.93054 11.4067 4.62953 10.9631 4.3998 10.7334C5.60387 10.464 6.60198 9.99668 7.4179 9.37088C6.68912 8.57873 6.11085 7.62022 5.68309 6.44784H4.54239Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(LQAIssueType);
