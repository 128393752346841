import { useSelector } from 'react-redux';

export function useViewSortOrderList() {
    return useSelector(state => state.gridUI.viewSorts);
}

export function useIsHighlightSort() {
    let viewSorts = useViewSortOrderList();
    return viewSorts?.length;
}
