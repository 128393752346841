import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useConnectionsByDestinationGridId } from 'hooks/integration';
import { Grid, CircularProgress } from '@material-ui/core';
import SyncConnectionIllustratorSVG from 'assets/images/svg/SyncConnectionIllustratorSVG';
import ButtonBase from 'components/button/Base';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useDispatch } from 'react-redux';
import { syncConnectionApi, getSyncStatusApi } from 'services/workflow';
import { enqueueSnackbar } from 'notifier/actions';
import { SOURCE_STATUS } from 'const';
import { Link } from 'react-router-dom';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: theme.spacing(4)
    },
    buttonWrapper: {
        position: 'relative',
        marginTop: 8
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

function SyncConnection({ branchId }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const roles = useRole();
    const connections = useConnectionsByDestinationGridId(branchId);
    const [isSyncing, setIsSyncing] = React.useState(false);

    const isShowConnectorUrl = React.useMemo(() => {
        return roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_ADD_ON] === roleConst.FULL;
    }, [roles]);

    const connectionOptions = React.useMemo(() => {
        return connections?.map(c => ({
            ...c,
            label: c?.name,
            value: c?.id
        }));
    }, [connections]);

    const [selectedConnection, setSelectedConnection] = React.useState(connectionOptions?.[0]);

    const syncConnection = React.useCallback(async () => {
        try {
            if (!selectedConnection) return;
            setIsSyncing(true);
            const task = await syncConnectionApi(selectedConnection?.value);

            const syncTaskInterval = setInterval(async function() {
                try {
                    const sync = await getSyncStatusApi(task?.id);
                    if (sync?.status === SOURCE_STATUS.SUCCEEDED) {
                        clearInterval(syncTaskInterval);
                        setIsSyncing(false);
                    }
                } catch (error) {
                    clearInterval(syncTaskInterval);
                    setIsSyncing(false);
                }
            }, 3000);
        } catch ({ message }) {
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    }, [selectedConnection, dispatch]);

    const connectionLength = React.useMemo(() => {
        return connectionOptions?.length || 0;
    }, [connectionOptions]);

    return (
        <Grid
            direction="column"
            container
            alignItems={'center'}
            justify={'center'}
            className={classes.root}
            spacing={3}
        >
            <Grid item>
                <SyncConnectionIllustratorSVG />
            </Grid>
            {connectionLength <= 0 ? (
                <Grid item>
                    {isShowConnectorUrl ? (
                        <p className="body2">
                            This grid doesn’t have any connection to sync <br /> Go to{' '}
                            <Link className="body1 inline text-brand-main" to="/integration-settings/connectors">
                                Gridly connector
                            </Link>{' '}
                            to setup connection first
                        </p>
                    ) : (
                        <p className="body2">
                            This grid doesn’t have any connection to sync
                            <br />
                            Contact your administrator to setup <p className="body1 inline">Gridly connector.</p>
                        </p>
                    )}
                </Grid>
            ) : (
                <Grid className={classes.fullWidth} item container direction="column" spacing={3}>
                    <Grid item className={classes.fullWidth} container direction="column">
                        <Grid item style={{ marginBottom: theme.spacing(1) }}>
                            <p className="body1">{t('select_a_connection_to_sync')}</p>
                        </Grid>
                        <Grid item>
                            <LDBasePortal
                                ddPlaceholder={'Select connection'}
                                menuPlaceholder={'Find connection'}
                                options={connectionOptions}
                                onChange={option => setSelectedConnection(option)}
                                defaultValue={selectedConnection}
                                isMulti={false}
                                isDisabled={isSyncing}
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={`${classes.expand} ${classes.buttonWrapper}`}>
                        <ButtonBase
                            onClick={syncConnection}
                            disabled={!selectedConnection || isSyncing}
                            fullWidth
                            variant="contained"
                        >
                            {t('sync_now')}
                        </ButtonBase>
                        {isSyncing && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default SyncConnection;
