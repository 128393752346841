import { enqueueSnackbar } from 'notifier/actions';
import { getCompaniesApi, inviteUsersApi, updateCompanyInfoApi, uploadCompanyLogoApi } from 'services/company';
import { requestData, receiveData } from '../../api/actions';
import * as types from '../types';
import * as appActions from 'app/actions';
import i18n from 'i18n';

export function uploadCompanyException({ error }) {
    return async function(dispatch) {
        dispatch(
            enqueueSnackbar({
                message: `[uploadCompanyException]: ${error}`,
                type: 'info'
            })
        );
    };
}

export function uploadCompanyLogo({ file, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser?.companyId;
        try {
            const company = await uploadCompanyLogoApi(file);
            dispatch(appActions.changeCompanyLogo({ logoUrl: company?.url }));
            dispatch(changeCompaniesLogo({ logoUrl: company?.url, companyId }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

export function changeCompaniesLogo({ logoUrl, companyId }) {
    return {
        type: types.CHANGE_COMPANIES_LOGO,
        payload: {
            logoUrl,
            companyId
        }
    };
}

export function changeCompanyInfo({ newData, notify, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        try {
            const newCompanyInfo = await updateCompanyInfoApi({ companyId, body: newData });
            if (notify) {
                dispatch(
                    enqueueSnackbar({
                        message: i18n.t('update_company_info_successfully'),
                        type: 'info'
                    })
                );
            }
            dispatch(appActions._setCompanyInfo(newCompanyInfo));

            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            errorCallback && errorCallback();
        }
    };
}

export function fetchCompanies({ successCallback, errorCallback }) {
    return async function(dispatch) {
        try {
            dispatch(requestData(types.FETCH_COMPANIES));

            const result = await getCompaniesApi();

            dispatch(receiveData(types.FETCH_COMPANIES_SUCCESS, result));

            return successCallback && successCallback(result);
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}

export function inviteUsersToCompany({ userProps, successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;

        try {
            await inviteUsersApi({ companyId, body: userProps });

            dispatch(receiveData(types.INVITE_USERS_TO_COMPANY_SUCCESS, {}));

            dispatch(receiveData(types.ASSIGN_USER_TO_GROUP_SUCCESS, { groupId: userProps[0].groupIds[0] }));

            return successCallback && successCallback();
        } catch (error) {
            const { message } = error;

            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            return errorCallback && errorCallback(message);
        }
    };
}
