import React from 'react';
import { SOURCE_STATUS } from 'const';
import { useSelector } from 'react-redux';
import { getConnectorIcon } from 'utils/connector';
import { Chip, Typography } from '@material-ui/core';
import { useGridIdInGridUI } from '../gridUI';

export function useConnections() {
    return useSelector(state => state.integration?.connections);
}

export function useConnectionsLoading() {
    return useSelector(state => state.integration?.connectionsLoading);
}

export function useConnectionMapping() {
    const connections = useConnections();

    return connections?.reduce((obj, c) => {
        obj[c?.id] = c;
        return obj;
    }, {});
}

export function useConnectionById(cId) {
    const connectionMapping = useConnectionMapping();
    return connectionMapping?.[cId];
}

export function useSyncHistoriesByCId(cId) {
    return useSelector(state => state.integration?.syncHistoriesByCId?.[cId]);
}

export function useSyncHistoriesByDestinationId(dId) {
    return useSelector(state => state.integration?.syncHistoriesByDestinationId?.[dId]);
}

export function useSources() {
    return useSelector(state => state.integration?.sources);
}

export function useSourcesLoading() {
    return useSelector(state => state.integration?.sourcesLoading);
}

export function useSourceOptions() {
    const sources = useSources();
    return sources?.map(source => ({
        ...source,
        label: source?.name,
        icon: () => getConnectorIcon(source?.connector, '16px'),
        value: source?.id,
        moreIcon: () => (
            <Chip
                style={{
                    background: source?.lastCheckStatus === SOURCE_STATUS.SUCCEEDED ? '#42B883' : '#E86E5E'
                }}
                size="small"
                label={
                    <Typography style={{ textTransform: 'capitalize', color: 'white' }} variant="body2">
                        {source?.lastCheckStatus}
                    </Typography>
                }
            />
        ),
        isDisabled: source?.lastCheckStatus === SOURCE_STATUS.FAILED
    }));
}

export function useSourceMapping() {
    const sources = useSources();

    return sources?.reduce((obj, s) => {
        obj[s?.id] = s;
        return obj;
    }, {});
}

export function useSourceById(sId) {
    const sourceMapping = useSourceMapping();
    return sourceMapping?.[sId];
}

export function useSourceInfoUrl() {
    return useSelector(state => state.integration?.sourceInfoUrl);
}

export function useSheetsBySourceId(sId) {
    return useSelector(state => {
        return state?.integration.sheetsBySourceId?.[sId];
    });
}

export function useConnectionsBySource() {
    return useSelector(state => state?.integration.connectionsBySourceId);
}

export function useSyncingConnectionTasks() {
    const connections = useConnections();
    const connectionTasks = connections?.filter(c => c?.task)?.map(c => c?.task);

    const connectionsBySource = useConnectionsBySource();
    const sIds = Object.keys(connectionsBySource);

    let tasks = [];

    for (const sId of sIds) {
        const syncingTasks = connectionsBySource?.[sId]?.filter(c => c?.task)?.map(c => c?.task);
        tasks = tasks.concat(syncingTasks);
    }

    return [...connectionTasks, ...tasks];
}

export function useConnectionsBySourceId(sId) {
    return useSelector(state => {
        return state?.integration.connectionsBySourceId?.[sId];
    });
}

export function useConnectionsByDestinationGridId(destinationGridId) {
    return useSelector(state => {
        return state?.integration.connectionsByDestinationGridId?.[destinationGridId];
    });
}

export function useIsShowSyncConnectionButton() {
    const gridId = useGridIdInGridUI();
    const connections = useConnectionsByDestinationGridId(gridId);

    return connections?.length > 0;
}
