import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        outline: 'none'
    }
}));

function getSupportType(extension) {
    switch (extension) {
        case 'ogg':
            return 'audio/ogg';
        case 'mp3':
            return 'audio/mpeg';
        case 'wav':
            return 'audio/wav';

        default:
            return 'audio/mpeg';
    }
}

function AudioPlay({ src, autoPlay, ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const file = src?.split('.');
    const extension = file?.pop();
    const type = getSupportType(extension);
    const audioRef = useRef();

    useEffect(() => {
        //fix bug
        //https://stackoverflow.com/questions/43577182/react-js-audio-src-is-updating-on-setstate-but-the-audio-playing-doesnt-chang
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
        }
    }, [src]);

    if (!type || !extension) return null;
    return (
        <audio ref={audioRef} className={classes.root} controls autoPlay={autoPlay} {...rest}>
            <source src={src} type={type} />
            {t(`global_browser_does_not_support_audio`)}
        </audio>
    );
}

export default React.memo(AudioPlay);
