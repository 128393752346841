import React, { useState, useRef, useEffect } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Editor, Range } from 'slate';
import { Portal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import hexToRgba from 'hex-to-rgba';

import editorController from '../editorController';

import BoldSVG from 'assets/images/svg/BoldSVG';
import ItalicSVG from 'assets/images/svg/ItalicSVG';
//import UnderlineSVG from 'assets/images/svg/UnderlineSVG';
import StrikethroughSVG from 'assets/images/svg/StrikethroughSVG';
import BlockQuoteSVG from 'assets/images/svg/BlockQuoteSVG';
import CodeSVG from 'assets/images/svg/CodeSVG';
import BulletListSVG from 'assets/images/svg/BulletList';
//import NumberList from 'assets/images/svg/NumberList';
import LinkIconSVG from 'assets/images/svg/LinkIconSVG';
import H1IconSVG from 'assets/images/svg/H1IconSVG';
import H2IconSVG from 'assets/images/svg/H2IconSVG';
import H3IconSVG from 'assets/images/svg/H3IconSVG';
import IconImageSVG from 'assets/images/svg/IconImageSVG';

import types from '../types';

const useStyles = makeStyles(theme => ({
    root: {
        width: 440,
        height: 36,
        padding: `0px 14px`,
        display: 'flex',
        alignItems: 'center',
        position: `absolute`,
        marginTop: '-8px',
        zIndex: 10000,
        top: '-10000px',
        left: '-10000px',
        opacity: 0,
        boxShadow: theme.shadows[1],
        background: theme.colors.dimGrey,
        borderRadius: 4,
        '& > *': {
            display: `inline-block`
        },
        '& button': {
            minWidth: 26,
            minHeight: 26,
            width: 26,
            height: 26,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            marginLeft: 8,
            '&:hover': {
                background: hexToRgba(theme.colors.white, 0.1)
            },
            '&:first-child': {
                marginLeft: 0
            }
        }
    },
    wrapperDivider: {
        paddingLeft: 14,
        paddingRight: 6,
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center'
    },
    divider: {
        width: 1,
        height: 20,
        background: hexToRgba(`#9392b4`, 0.1)
    },
    active: {
        background: hexToRgba(theme.colors.white, 0.1)
    },
    item: {
        cursor: 'pointer'
    }
}));

const markOptions = [
    { value: types.BOLD, icon: <BoldSVG color="#fff" /> },
    { value: types.ITALIC, icon: <ItalicSVG color="#fff" /> },
    //{ value: types.UNDERLINE, icon: <UnderlineSVG color="#fff" /> },
    { value: types.STRIKETHROUGH, icon: <StrikethroughSVG color="#fff" /> },
    { value: 'divider' },
    { value: types.H1, isBlock: true, icon: <H1IconSVG color="#fff" /> },
    { value: types.H2, isBlock: true, icon: <H2IconSVG color="#fff" /> },
    { value: types.H3, isBlock: true, icon: <H3IconSVG color="#fff" /> },
    { value: 'divider' },
    //{ value: types.OL, isBlock: true, icon: <NumberList color="#fff" /> },
    { value: types.UL, isBlock: true, icon: <BulletListSVG color="#fff" /> },
    //{ value: 'divider' },
    { value: types.LINK, icon: <LinkIconSVG color="#fff" /> },
    { value: types.IMG, isBlock: true, icon: <IconImageSVG color="#fff" /> },
    { value: types.BLOCKQUOTE, isBlock: true, icon: <BlockQuoteSVG color="#fff" /> },
    { value: types.CODE, icon: <CodeSVG color="#fff" /> }
];

const Hover = ({ containerRef }) => {
    const ref = useRef();
    const editor = useSlate();
    const classes = useStyles();

    const [toolBarRef, setToolBarRef] = useState(null);

    useEffect(() => {
        const toolbar = document.getElementById('markdownToolbar');
        setToolBarRef(toolbar);
    }, []);

    useEffect(() => {
        const el = ref.current;
        const { selection } = editor;
        if (!el) {
            return;
        }

        if (
            !selection ||
            !ReactEditor.isFocused(editor) ||
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ''
        ) {
            el.removeAttribute('style');
            return;
        }

        const domSelection = window.getSelection();
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();

        el.style.opacity = '1';
        el.style.top = `${rect.top - 227 + 40}px`;
        el.style.left = 0;
        const rect1 = el.getBoundingClientRect();
        el.style.left = `${rect.left - rect1.left}px`;
    });

    const handleMarkMouseDown = ({ e, mark }) => {
        e.stopPropagation();
        e.preventDefault();

        if (mark.value === types.LINK) {
            return handleLinkClick(mark);
        }

        if (mark.value === types.IMG) {
            return handleImageClick(mark);
        }

        if (mark.isBlock) {
            editorController.toggleBlock(editor, mark.value);
        } else {
            editorController.toggleMark(editor, mark.value);
        }
    };

    const handleLinkClick = mark => {
        const url = window.prompt('Enter the URL of the link:');

        if (!url) return;

        editorController.insertLink(editor, url);
    };

    const handleImageClick = mark => {
        const url = window.prompt('Enter the URL of the image:');

        if (!url) return;

        editorController.insertImage(editor, url);
    };

    const buildButtonMenu = btnOptions => {
        return btnOptions.map((mark, index) => {
            if (mark.value === 'divider') {
                return (
                    <span key={`editor-menu-diviver-${index}`} className={classes.wrapperDivider}>
                        <span className={classes.divider}></span>
                    </span>
                );
            }
            const active = mark.isBlock
                ? editorController.isBlockActive(editor, mark.value)
                : editorController.isMarkActive(editor, mark.value);
            return (
                <Button
                    key={`${mark.value}-button`}
                    size="small"
                    item="true"
                    onMouseDown={e => handleMarkMouseDown({ e, mark })}
                    className={`${active ? classes.active : ``} ${classes.item}`}
                >
                    {mark?.icon}
                </Button>
            );
        });
    };

    return (
        <>
            <div id={'markdownToolbar'} />
            <Portal container={toolBarRef}>
                <div ref={ref} className={classes.root}>
                    {buildButtonMenu(markOptions)}
                </div>
            </Portal>
        </>
    );
};

export default React.memo(Hover);
