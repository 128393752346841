import request from './request';
import { APP_API_PREFIX_GRID_UI } from 'config';

const dependencyApis = {
    dependencies: ({ dbId, gridId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/dependencies`,
    dependency: ({ dbId, gridId, dependencyId }) =>
        `${APP_API_PREFIX_GRID_UI}/api/v1/databases/${dbId}/grids/${gridId}/dependencies/${dependencyId}`
};

export async function fetchDependenciesApi({ dbId, gridId }) {
    const response = await request.get(dependencyApis.dependencies({ dbId, gridId }));
    return response.data;
}

export async function createDependencyApi({ dbId, gridId, body }) {
    const response = await request.post(dependencyApis.dependencies({ dbId, gridId }), body);
    return response.data;
}

export async function updateDependencyApi({ dependencyId, dbId, gridId, body }) {
    const response = await request.put(dependencyApis.dependency({ dependencyId, dbId, gridId }), body);
    return response.data;
}

export async function deleteDependencyApi({ dependencyId, dbId, gridId }) {
    const response = await request.delete(dependencyApis.dependency({ dependencyId, dbId, gridId }));
    return response.data;
}
