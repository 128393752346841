import React from 'react';
import DialogContent from 'components/dialog/DialogContent';
import DialogTitle from 'components/dialog/DialogTitle';
import { Typography, Grid, Divider, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Search from 'components/search';
import GridTemplate from './GridTemplate';
import BackIOSSVG from 'assets/images/svg/BackIOSSVG';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import GridTemplateDetail from './GridTemplateDetail';
import { useGridTemplates } from 'hooks/grid';
import * as gridActions from 'grids/actions';
import { useDispatch } from 'react-redux';
import Spinner from 'components/spinner/Base';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        width: 1200,
        minHeight: 600,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        position: 'relative'
    },
    search: {
        minWidth: 256
    },
    template: {
        cursor: 'pointer',
        borderRadius: 6,
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.white,
        zIndex: 10
    },
    description: {
        marginTop: theme.spacing(3),
        color: theme.colors.midGrey
    },
    backIcon: {
        position: 'relative',
        left: -12
    }
}));

const STATES = {
    LIST: 'LIST',
    DETAIL: 'DETAIL'
};

function GridTemplates({ onClose, dbId, onOpenChoosingWorkspace }) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const templates = useGridTemplates();
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search);

    const [searchValue, setSearchValue] = React.useState('');
    const [currentState, setCurrentState] = React.useState(STATES.LIST);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [isFetching, setIsFetching] = React.useState(false);

    const filteredTemplates = templates.filter(template =>
        template.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleTemplateSelect = React.useCallback(template => {
        setSelectedTemplate(template);
        setCurrentState(STATES.DETAIL);
    }, []);

    React.useEffect(() => {
        return () => history.replace(pathname);
    }, [history, pathname]);

    React.useEffect(() => {
        setIsFetching(true);
        dispatch(
            gridActions.fetchGridTemplates({
                successCallback: () => {
                    setIsFetching(false);
                },
                errorCallback: () => {
                    setIsFetching(false);
                }
            })
        );
    }, [dispatch]);

    React.useEffect(() => {
        const foundTemplate = filteredTemplates.find(template => template.id === searchParams.get('template'));
        if (foundTemplate) {
            setSelectedTemplate(foundTemplate);
            setCurrentState(STATES.DETAIL);
        }
    }, [searchParams, filteredTemplates]);

    const content = React.useMemo(() => {
        switch (currentState) {
            case STATES.LIST: {
                return (
                    <DialogContent>
                        <Grid spacing={5} container className={classes.content}>
                            {isFetching && (
                                <div className={classes.loadingSpinner}>
                                    <Spinner size={30} thick={4} />
                                </div>
                            )}
                            {filteredTemplates?.length === 0 && (
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <NotFoundBoxSVG />
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" style={{ fontWeight: 400 }} variant="h3">
                                            {t('template_comming_soon')}
                                        </Typography>
                                        <Typography align="center" variant="body2" className={classes.description}>
                                            {t('template_get_productive')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {filteredTemplates?.map(template => (
                                <Grid item xs={3} key={template?.id}>
                                    <Grid container className={classes.template}>
                                        <GridTemplate onClick={() => handleTemplateSelect(template)} {...template} />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                );
            }

            case STATES.DETAIL: {
                return (
                    <GridTemplateDetail
                        dbId={dbId}
                        {...selectedTemplate}
                        onOpenChoosingWorkspace={onOpenChoosingWorkspace}
                        template={selectedTemplate}
                        onClose={onClose}
                    />
                );
            }

            default:
                return null;
        }
    }, [
        onClose,
        classes,
        filteredTemplates,
        dbId,
        currentState,
        handleTemplateSelect,
        selectedTemplate,
        onOpenChoosingWorkspace,
        isFetching,
        t
    ]);

    const handleBack = () => {
        setSelectedTemplate(null);
        setCurrentState(STATES.LIST);
        history.replace(pathname);
    };

    return (
        <>
            <DialogTitle
                title={
                    currentState === STATES.LIST ? (
                        <Typography variant="h4" component="div">
                            {t('global_templates')}
                        </Typography>
                    ) : (
                        <Grid className={classes.backIcon} container direction="row" alignItems="center" spacing={1}>
                            <Grid item onClick={handleBack}>
                                <IconButton size="medium">
                                    <BackIOSSVG width={12} height={12} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">Back</Typography>
                            </Grid>
                        </Grid>
                    )
                }
                actions={
                    currentState === STATES.LIST && (
                        <Search
                            className={classes.search}
                            onChange={e => setSearchValue(e.target.value)}
                            background={theme.colors.paleGrey}
                            placeholder={t('global_search')}
                        />
                    )
                }
                onClose={onClose}
            />
            <Divider />
            {content}
        </>
    );
}

export default React.memo(GridTemplates);
