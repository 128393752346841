import React from 'react';

const CopySourceSVG = ({ color = '#78778B', ...other }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <mask id="path-1-inside-1_1580_156768" fill="white">
                <path d="M5.79473 8.94141V4.38945H13.3214V13.9541H5.79473V11.7609H4.59473V14.3541C4.59473 14.796 4.9529 15.1541 5.39473 15.1541H13.7214C14.1632 15.1541 14.5214 14.796 14.5214 14.3541V3.98945C14.5214 3.54763 14.1632 3.18945 13.7214 3.18945H5.39473C4.9529 3.18945 4.59473 3.54763 4.59473 3.98945V8.94141H5.79473Z" />
            </mask>
            <path
                d="M5.79473 8.94141V10.2014H7.05473V8.94141H5.79473ZM5.79473 4.38945V3.12945H4.53473V4.38945H5.79473ZM13.3214 4.38945H14.5814V3.12945H13.3214V4.38945ZM13.3214 13.9541V15.2141H14.5814V13.9541H13.3214ZM5.79473 13.9541H4.53473V15.2141H5.79473V13.9541ZM5.79473 11.7609H7.05473V10.5009H5.79473V11.7609ZM4.59473 11.7609V10.5009H3.33473V11.7609H4.59473ZM4.59473 8.94141H3.33473V10.2014H4.59473V8.94141ZM7.05473 8.94141V4.38945H4.53473V8.94141H7.05473ZM5.79473 5.64945H13.3214V3.12945H5.79473V5.64945ZM12.0614 4.38945V13.9541H14.5814V4.38945H12.0614ZM13.3214 12.6941H5.79473V15.2141H13.3214V12.6941ZM7.05473 13.9541V11.7609H4.53473V13.9541H7.05473ZM5.79473 10.5009H4.59473V13.0209H5.79473V10.5009ZM3.33473 11.7609V14.3541H5.85473V11.7609H3.33473ZM3.33473 14.3541C3.33473 15.4918 4.25702 16.4141 5.39473 16.4141V13.8941C5.64878 13.8941 5.85473 14.1001 5.85473 14.3541H3.33473ZM5.39473 16.4141H13.7214V13.8941H5.39473V16.4141ZM13.7214 16.4141C14.8591 16.4141 15.7814 15.4918 15.7814 14.3541H13.2614C13.2614 14.1001 13.4673 13.8941 13.7214 13.8941V16.4141ZM15.7814 14.3541V3.98945H13.2614V14.3541H15.7814ZM15.7814 3.98945C15.7814 2.85175 14.8591 1.92945 13.7214 1.92945V4.44945C13.4673 4.44945 13.2614 4.2435 13.2614 3.98945H15.7814ZM13.7214 1.92945H5.39473V4.44945H13.7214V1.92945ZM5.39473 1.92945C4.25702 1.92945 3.33473 2.85175 3.33473 3.98945H5.85473C5.85473 4.2435 5.64878 4.44945 5.39473 4.44945V1.92945ZM3.33473 3.98945V8.94141H5.85473V3.98945H3.33473ZM4.59473 10.2014H5.79473V7.68141H4.59473V10.2014Z"
                fill={color}
                mask="url(#path-1-inside-1_1580_156768)"
            />
            <path
                d="M10.0404 1.44238H2.60905C2.24086 1.44238 1.94238 1.74086 1.94238 2.10905V9.68491C1.94238 10.0531 2.24086 10.3516 2.60905 10.3516H7.65055"
                stroke={color}
                strokeWidth="1.26"
            />
            <path d="M8.12695 9.12142L9.58959 10.3511L8.12695 11.5807L8.12695 9.12142Z" fill={color} stroke={color} />
        </svg>
    );
};

export default React.memo(CopySourceSVG);
