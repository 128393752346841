import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width,
        height: props => props.height
    }
}));

const SIZES = {
    small: {
        height: 16,
        width: 16
    },
    medium: {
        width: 24,
        height: 24
    },
    large: {
        width: 32,
        height: 32
    }
};

function getSize({ size }) {
    if (typeof size === 'string') return SIZES[size] || size['small'];
    return { width: size, height: size };
}

function ArrowLeft2SVG({ color = '#ACADB9', className, size = 'small', ...other }) {
    const { width, height } = getSize({ size });
    const classes = useStyles({ width, height });
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${classes.root} ${className}`}
            {...other}
        >
            <g opacity="0.6">
                <path
                    d="M15 18L9 12L15 6"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

export default React.memo(ArrowLeft2SVG);
