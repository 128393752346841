import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GridList from 'grids/GridList';
import GridAdd from 'grids/GridAdd';
import { useDispatch } from 'react-redux';
import { turnOffFirstPopup, deleteDatabase } from './actions';
import { createGridByImportFile } from 'grids/actions';
import { changeContext } from 'app/actions';

import IconMoreActionsSVG from 'assets/images/svg/IconMoreActionsSVG';
import DatabasePopup from './DatabasePopup';
import Divider from 'components/divider/Base';
import { POPUP_RADIUS, POPUP_PADDING_TOP_BOTTOM, DISABLED_OPACITY } from 'const/style';
import ProgressLinear from 'components/notify/Import';
import { COLOR_TYPES, DATABASE_STATUS } from 'const';
import { useRole } from 'hooks/auth/role';
import * as roleConst from 'auth/roleConst';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import ModalBase from 'components/modal/Base';
import { sendManualTrack } from 'tracker';
import DuplicateDatabaseForm from './DuplicateDatabaseForm';
import { useDropzone } from 'react-dropzone';
import { useCanViewGroupShare } from 'hooks/payment';
import LimitPopup from 'payment/components/popups/LimitPopup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { defaultBorder } from 'tutorials/helpers';
import ShareDatabase from './ShareDatabase';
import LocalizationImportJSONSetup from '../grids/localization/LocalizationImportJSONSetup';
import LocalizationImportPOSetup from '../grids/localization/LocalizationImportPOSetup';
import GridImport from '../grids/GridImport';
import { isFileJSON, isFilePO } from 'utils/fileUtils';

import CreateGroup from 'permission/groups/createGroup';
import { useSelectedGroupAccessIds, useSelectedGroupDetail } from 'hooks/permission';
import * as shareViewActions from 'gridUI/actions/shareView';
import { GRID_UPLOAD_LOCALIZATION_SUPPORTED_EXTENSIONS, GRID_UPLOAD_MAX_SIZE } from 'const';
import { getUploadErrorMessage } from 'utils/upload';
import { enqueueSnackbar } from 'notifier/actions';
import { Popper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 24,
        background: theme.colors.white,
        padding: `20px 8px`,
        borderRadius: 4,
        position: 'relative',
        border: defaultBorder
    },
    arrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8
    },
    dbItemWrapper: {
        padding: '0 24px',

        [theme.breakpoints.down('sm')]: {
            padding: '0 12px'
        }
    },
    dbItem: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative'
        }
    },
    dropDownIcon: {
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        minWidth: 32,
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            position: 'initial'
        }
    },
    gridList: {
        padding: `0 8px 8px`,
        marginTop: 24,
        minHeight: 30
    },
    name: {
        fontSize: '1.5rem',
        fontWeight: 500
    },
    dbName: {
        maxWidth: 800,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            maxWidth: 500
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 150
        }
    },
    buttons: {
        marginLeft: 10,
        '& > div': {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        }
    },
    divider: {
        width: 'calc(100% - 48px)',
        margin: 'auto',
        marginTop: 14
    },
    editor: {
        position: 'absolute',
        overflow: 'hidden',
        zIndex: 10,
        top: 35,
        left: 0,
        boxShadow: theme.shadows[1],
        borderRadius: POPUP_RADIUS,
        paddingBottom: POPUP_PADDING_TOP_BOTTOM,
        paddingTop: POPUP_PADDING_TOP_BOTTOM,
        background: theme.colors.white
    },
    fullWidth: {
        width: '100%'
    },
    restoring: {
        opacity: DISABLED_OPACITY,
        pointerEvents: 'none'
    }
}));

function DatabaseItem({
    index,
    workspaceId,
    id,
    name,
    status,
    database,
    isShowFirstPopupCreate,
    contextMenuId,
    fetchingDisabled
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const [openEdit, setOpenEdit] = React.useState(false);
    const roles = useRole();
    const history = useHistory();
    const menuRef = React.useRef();
    const [isOpenLimitModal, setIsOpenLimitModal] = React.useState(false);
    const [isOpenShareDatabase, setIsOpenShareDatabase] = React.useState(false);
    const [openEditAccessModal, setOpenEditAccessModal] = React.useState(false);
    const [isOpenLocalizationSetup, setIsOpenLocalizationSetup] = React.useState(false);
    const [files, setFiles] = React.useState(null);
    const selectedGroupDetail = useSelectedGroupDetail();
    const checkedListDefault = useSelectedGroupAccessIds();
    const canViewGroupShare = useCanViewGroupShare();

    const handleBack = React.useCallback(() => {
        dispatch(shareViewActions.fetchGroupsByView());
        setOpenEditAccessModal(false);
    }, [dispatch]);

    const handleOpenAddGroupModal = React.useCallback(() => {
        handleCloseShareDatabase();
        if (!canViewGroupShare) {
            handleOpenLimitModal();
            return;
        }
        setOpenEditAccessModal(true);
    }, [canViewGroupShare]);

    const handleCloseAddGroupModal = React.useCallback(() => {
        setOpenEditAccessModal(false);
    }, []);

    const handleCloseShareDatabase = () => {
        setIsOpenShareDatabase(false);
    };

    const handleOpenShareDatabase = () => {
        handleClickAway();
        setIsOpenShareDatabase(true);
    };

    const handleOpenLimitModal = () => setIsOpenLimitModal(true);
    const handleCloseLimitModal = () => {
        setIsOpenLimitModal(false);
    };

    const handleUpgrade = () => {
        handleCloseLimitModal();
        history.push(`/company-settings/billing/overview`);
    };

    const onDrop = React.useCallback(
        (acceptedFiles = []) => {
            if (acceptedFiles.length === 1) {
                setFiles(acceptedFiles);
                setIsOpenLocalizationSetup(true);
            } else {
                acceptedFiles.forEach(file => {
                    const fileName = file?.name?.split(`.`)?.[0];
                    dispatch(
                        createGridByImportFile({
                            name: fileName,
                            file,
                            dbId: database.id,
                            successCallback: () => {
                                console.log(`upload file ${fileName} success`);
                            },
                            errorCallback: () => {
                                console.log(`Failed to upload file ${fileName}`);
                            }
                        })
                    );
                });
            }
        },
        [dispatch, database]
    );

    const onDropRejected = React.useCallback(
        rejectedFiles => {
            const fileErrors = getUploadErrorMessage(rejectedFiles, GRID_UPLOAD_LOCALIZATION_SUPPORTED_EXTENSIONS);
            const fileError = fileErrors?.[0];

            sendManualTrack({ type: t('grid_upload_rejected_mode') });
            dispatch(
                enqueueSnackbar({
                    message: fileError?.[0],
                    type: 'info'
                })
            );
        },
        [dispatch, t]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDropRejected,
        noClick: true,
        accept: GRID_UPLOAD_LOCALIZATION_SUPPORTED_EXTENSIONS,
        multiple: true,
        maxSize: GRID_UPLOAD_MAX_SIZE,
        noDragEventsBubbling: true
    });

    const accessManageDatabase = roles?.[roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE];
    const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);
    const [isOpenDuplicateForm, setIsOpenDuplicateForm] = React.useState(false);

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `DATABASE_CONTEXT_MENU_ID_${id}`;
    }, [id]);

    const isDBBackingUp = React.useMemo(() => {
        return status?.toLowerCase() === DATABASE_STATUS.BACKING_UP;
    }, [status]);

    const isDBRestoring = React.useMemo(() => {
        return status?.toLowerCase() === DATABASE_STATUS.RESTORING;
    }, [status]);

    const isDBDuplicating = React.useMemo(() => {
        return status?.toLowerCase() === DATABASE_STATUS.DUPLICATING;
    }, [status]);

    const handleClick = event => {
        setOpenEdit(true);
    };

    const handleClickAway = () => {
        setOpenEdit(false);
    };

    const openDuplicateForm = () => {
        setIsOpenDuplicateForm(true);
        setOpenEdit(false);
    };

    const closeDuplicateForm = () => {
        setIsOpenDuplicateForm(false);
    };

    React.useEffect(() => {
        if (isShowFirstPopupCreate) {
            let $arrow = document.getElementById(`database_more_${id}`);
            if ($arrow) {
                $arrow.click();
                dispatch(turnOffFirstPopup());
            }
        }
    }, [isShowFirstPopupCreate, id, dispatch]);

    React.useEffect(() => {
        if (contextMenuId && contextMenuId !== CONTEXT_MENU_ID) {
            handleClickAway();
        }
    }, [contextMenuId, CONTEXT_MENU_ID]);

    const handleOnContextClick = () => {
        if (accessManageDatabase !== roleConst.FULL) return;
        dispatch(changeContext({ contextMenuId: CONTEXT_MENU_ID }));
        setOpenEdit(true);
    };

    const openDeleteConfirm = e => {
        setIsOpenConfirmModal(true);
    };

    const closeDeleteConfirm = () => {
        setIsOpenConfirmModal(false);
    };

    const deleteDatabaseHandler = e => {
        sendManualTrack({ type: `Delete Database` });

        dispatch(
            deleteDatabase({
                dbId: database.id,
                successCallback: () => {
                    console.log('delete successfully');
                    closeDeleteConfirm();
                },
                errorCallback: error => {
                    console.log('delete failed');
                    closeDeleteConfirm();
                }
            })
        );
    };

    const closeLocalizationSetupHandler = () => {
        setIsOpenLocalizationSetup(false);
        setFiles(null);
    };

    return (
        <>
            <Grid
                id={id}
                container
                className={`databaseItem ${classes.root} ${
                    isDBBackingUp || isDBRestoring || isDBDuplicating ? classes.restoring : ''
                }`}
                direction="row"
                spacing={0}
                {...getRootProps({
                    style: {
                        outline: 'none',
                        border: isDragActive ? `2px dashed #B4B4B4` : '2px dashed transparent',
                        boxSizing: 'content-box'
                    }
                })}
            >
                <input {...getInputProps()} />
                <Grid item xs={12} className={classes.dbItemWrapper}>
                    <Grid container direction={'row'} justify="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" justify="flex-start">
                                <Grid item className={classes.fullWidth}>
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        style={{ position: 'relative' }}
                                        onContextMenu={handleOnContextClick}
                                        className={`${classes.dbItem} dbItem`}
                                    >
                                        <Grid item>
                                            <Typography variant="h4" className={`${classes.dbName} dbName`}>
                                                {name}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            ref={menuRef}
                                            item
                                            id={`database_more_${id}`}
                                            onClick={handleClick}
                                            className={`${classes.dropDownIcon} dropdownIcon`}
                                        >
                                            <IconMoreActionsSVG color={theme.colors.steel} tr-dt="Open Edit Database" />
                                            <Popper
                                                open={openEdit}
                                                anchorEl={menuRef.current}
                                                placement="bottom-start"
                                                className={classes.editor}
                                                modifiers={{ preventOverflow: { escapeWithReference: true } }}
                                            >
                                                <DatabasePopup
                                                    database={database}
                                                    workspaceId={workspaceId}
                                                    handleClickAway={handleClickAway}
                                                    onDeleteDatabase={openDeleteConfirm}
                                                    onDuplicate={openDuplicateForm}
                                                    onShareDatabaseClick={handleOpenShareDatabase}
                                                    isManageDatabaseOrWorkspace={
                                                        roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE] ===
                                                            roleConst.FULL ||
                                                        roles[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] ===
                                                            roleConst.FULL
                                                    }
                                                />
                                            </Popper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {(roles[roleConst.WORKSPACE_AUTHORITIES.MANAGE_DATABASE] === roleConst.FULL ||
                                roles[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] === roleConst.FULL) && (
                                <Grid item container alignItems="center" spacing={1} wrap="nowrap">
                                    <Grid item>
                                        <Grid container spacing={3}>
                                            {(isDBRestoring || isDBBackingUp || isDBDuplicating) && (
                                                <Grid item style={{ minWidth: 200 }}>
                                                    <ProgressLinear
                                                        title={
                                                            isDBRestoring
                                                                ? t(`global_restoring`)
                                                                : isDBDuplicating
                                                                ? t(`global_duplicating`)
                                                                : t(`global_backing_up`)
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <GridAdd dbId={id} fetchingDisabled={fetchingDisabled} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid xs={12} item className={classes.gridList} id={`grid-list-${index}`}>
                    <Grid container direction="column">
                        <GridList fetchingDisabled={fetchingDisabled} workspaceId={workspaceId} dbId={id} />
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={isOpenConfirmModal} onClose={closeDeleteConfirm}>
                <ConfirmBox
                    title={t('modal_delete_database_title')}
                    body={
                        <Trans
                            t={t}
                            i18nKey="modal_delete_database_text"
                            defaults="Are you sure you want to delete the database <bold>{{databaseName}}</bold>?"
                            values={{ databaseName: database.name }}
                            components={{
                                bold: <Typography variant="body1" display="inline" />
                            }}
                        />
                    }
                    handleCancel={closeDeleteConfirm}
                    onClose={closeDeleteConfirm}
                    handleAgreed={deleteDatabaseHandler}
                    agreeLabel={t('global_delete')}
                    colorType={COLOR_TYPES.SECONDARY}
                />
            </Dialog>
            <Dialog open={isOpenDuplicateForm} onClose={closeDuplicateForm}>
                <DuplicateDatabaseForm
                    workspaceId={workspaceId}
                    clonedDatabase={database}
                    onClose={closeDuplicateForm}
                />
            </Dialog>
            <ModalBase open={isOpenLimitModal} handleClose={handleCloseLimitModal}>
                <LimitPopup
                    title={`Record limit reached`}
                    message={`Your Company has hit the max number of records on this plan. Your Grid will be temporarily locked. Please remove records or upgrade to a higher plan to unlock the Grid.`}
                    onCancel={handleCloseLimitModal}
                    onUpgrade={handleUpgrade}
                    onClose={handleCloseLimitModal}
                />
            </ModalBase>

            <Dialog maxWidth={false} open={openEditAccessModal} onClose={handleCloseAddGroupModal}>
                <CreateGroup
                    defaultGroupName={selectedGroupDetail?.name}
                    checkedListDefaultIds={checkedListDefault?.map(dbAccess => dbAccess.id)}
                    checkedListDefault={checkedListDefault}
                    onClose={handleCloseAddGroupModal}
                    isEdit={false}
                    onBack={handleBack}
                />
            </Dialog>
            <Dialog open={isOpenShareDatabase} onClose={handleCloseShareDatabase}>
                <ShareDatabase
                    onClose={handleCloseShareDatabase}
                    database={database}
                    onOpenAddGroupModal={handleOpenAddGroupModal}
                />
            </Dialog>
            <Dialog maxWidth="lg" open={isOpenLocalizationSetup} onClose={closeLocalizationSetupHandler}>
                {isFileJSON(files?.[0]) ? (
                    <LocalizationImportJSONSetup
                        dbId={database?.id}
                        onClose={closeLocalizationSetupHandler}
                        isMulti={false}
                        isUploading={false}
                        files={files}
                    />
                ) : isFilePO(files?.[0]) ? (
                    <LocalizationImportPOSetup
                        dbId={database?.id}
                        onClose={closeLocalizationSetupHandler}
                        isMulti={false}
                        isUploading={false}
                        files={files}
                    />
                ) : (
                    files && <GridImport dbId={database.id} onClose={closeLocalizationSetupHandler} files={files} />
                )}
            </Dialog>
        </>
    );
}

export default React.memo(DatabaseItem);
