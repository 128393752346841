import React from 'react';

function ImageSlideShowSVG({ ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_10_484)">
                <path
                    d="M13.3404 2H2.66809C2.4751 2 2.29002 2.07666 2.15356 2.21313C2.01709 2.34959 1.94043 2.53467 1.94043 2.72766V13.4C1.94043 13.593 2.01709 13.7781 2.15356 13.9145C2.29002 14.051 2.4751 14.1276 2.66809 14.1276H13.3404C13.5334 14.1276 13.7185 14.051 13.8549 13.9145C13.9914 13.7781 14.0681 13.593 14.0681 13.4V2.72766C14.0681 2.53467 13.9914 2.34959 13.8549 2.21313C13.7185 2.07666 13.5334 2 13.3404 2ZM12.6128 3.45532V12.6723H3.39575V3.45532H12.6128Z"
                    fill="#78778B"
                />
                <path
                    d="M6.1945 11.1261H6.19663C6.31021 11.1889 6.43853 11.2202 6.56829 11.2167C6.69805 11.2133 6.82452 11.1752 6.93459 11.1063L10.8154 8.68081C10.92 8.61538 11.0062 8.52445 11.066 8.41654C11.1257 8.30864 11.1571 8.18732 11.1571 8.06397C11.1571 7.94062 11.1257 7.8193 11.066 7.7114C11.0062 7.6035 10.92 7.51256 10.8154 7.44713L6.93459 5.0216C6.82447 4.95278 6.69794 4.91467 6.56812 4.91125C6.43831 4.90782 6.30994 4.9392 6.19635 5.00213C6.08275 5.06505 5.98806 5.15723 5.92211 5.26909C5.85615 5.38096 5.82134 5.50843 5.82127 5.63829V10.4893C5.82106 10.6191 5.85555 10.7466 5.92118 10.8585C5.9868 10.9705 6.08117 11.0628 6.1945 11.1261ZM7.27659 9.17623V6.95081L9.05693 8.06382L7.27659 9.17623Z"
                    fill="#78778B"
                />
                <path d="M16 3.06541H14.5447V13.2526H16V3.06541Z" fill="#78778B" />
                <path d="M1.45532 3.06541H0V13.2526H1.45532V3.06541Z" fill="#78778B" />
            </g>
            <defs>
                <clipPath id="clip0_10_484">
                    <rect width="16" height="12.1276" fill="white" transform="translate(0 2)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(ImageSlideShowSVG);
