import React from 'react';

function PercentageSymbolSVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.0001 2.77461C3.32355 2.77461 2.7751 3.32306 2.7751 3.99961C2.7751 4.67616 3.32355 5.22461 4.0001 5.22461C4.67665 5.22461 5.2251 4.67616 5.2251 3.99961C5.2251 3.32306 4.67665 2.77461 4.0001 2.77461ZM1.2251 3.99961C1.2251 2.46702 2.46751 1.22461 4.0001 1.22461C5.53269 1.22461 6.7751 2.46702 6.7751 3.99961C6.7751 5.5322 5.53269 6.77461 4.0001 6.77461C2.46751 6.77461 1.2251 5.5322 1.2251 3.99961ZM13.5481 2.4516C13.8508 2.75426 13.8508 3.24496 13.5481 3.54762L2.54811 14.5476C2.24545 14.8503 1.75475 14.8503 1.45209 14.5476C1.14943 14.245 1.14943 13.7543 1.45209 13.4516L12.4521 2.4516C12.7547 2.14895 13.2454 2.14895 13.5481 2.4516ZM10.7751 11.9996C10.7751 11.3231 11.3235 10.7746 12.0001 10.7746C12.6766 10.7746 13.2251 11.3231 13.2251 11.9996C13.2251 12.6762 12.6766 13.2246 12.0001 13.2246C11.3235 13.2246 10.7751 12.6762 10.7751 11.9996ZM12.0001 9.22461C10.4675 9.22461 9.2251 10.467 9.2251 11.9996C9.2251 13.5322 10.4675 14.7746 12.0001 14.7746C13.5327 14.7746 14.7751 13.5322 14.7751 11.9996C14.7751 10.467 13.5327 9.22461 12.0001 9.22461Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(PercentageSymbolSVG);
