import React from 'react';

function PauseContained2SVG({ ...other }) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#clip0_808_2065)">
                <path
                    d="M23.8973 4.1027C18.4274 -1.36718 9.57338 -1.36795 4.10273 4.1027C-1.36716 9.57259 -1.36799 18.4266 4.10273 23.8972C9.57261 29.3671 18.4266 29.368 23.8973 23.8972C29.3671 18.4274 29.3679 9.57342 23.8973 4.1027ZM12.8138 18.0877C12.8138 18.7227 12.299 19.2374 11.6641 19.2374C11.0291 19.2374 10.5144 18.7227 10.5144 18.0877V9.91224C10.5144 9.27729 11.0291 8.76256 11.6641 8.76256C12.299 8.76256 12.8138 9.27729 12.8138 9.91224V18.0877ZM17.4855 18.0877C17.4855 18.7227 16.9707 19.2374 16.3358 19.2374C15.7008 19.2374 15.1861 18.7227 15.1861 18.0877V9.91224C15.1861 9.27729 15.7008 8.76256 16.3358 8.76256C16.9707 8.76256 17.4855 9.27729 17.4855 9.91224V18.0877Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_808_2065">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(PauseContained2SVG);
