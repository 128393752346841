import * as types from 'socket/types';
import store from 'store/configStore';
import { mapResponse } from 'utils/socket';
import viewListHandler from './viewList';
import viewFilterHandler from './viewFilter';
import viewSortHandler from './viewSort';
import columnHandler from '../gridUI/column';
import { isForOthers, isForYou, isForAll } from 'socket/utils/room';
import { handleAutomationListExecutions } from 'gridUI/automations/action';

const { dispatch, getState } = store;

const viewHandler = message => {
    const state = getState();
    let socketId = state?.app?.socketId;
    let { headers, body } = mapResponse(message);

    const serverSocketId = headers['X-Socket-Id'];
    console.log('LOCAL SOCKETID', socketId);
    console.log('HEADER SOCKETID', serverSocketId);
    if (!serverSocketId) {
        console.log('WHY THIS HAPPENED A.TRONG???');
    }

    if (isForAll({ headers, state })) {
        console.log('body', body);
        const { subObjectType, objectType, subAction, automationId, executionId } = body;
        if (objectType === types.VIEW && subObjectType === types.COLUMN) {
            if (
                [
                    types.UNDO_UPDATE,
                    types.REDO_UPDATE,
                    types.UNDO_DELETE,
                    types.REDO_DELETE,
                    types.UNDO_CREATE,
                    types.REDO_CREATE,
                    types.UNDO_REORDER,
                    types.REDO_REORDER,
                    types.UNDO_PASTE_COLUMN_END,
                    types.REDO_PASTE_COLUMN_END
                ].includes(subAction)
            ) {
                return columnHandler({ body });
            }
        }
        if (automationId && executionId) {
            return dispatch(handleAutomationListExecutions(body));
        }
    }

    if (isForYou({ headers, state })) {
        console.log('IGNORE');
        return;
    } else if (isForOthers({ headers, state })) {
        return _viewHandler({ body });
    }
};

function _viewHandler({ body }) {
    const { subObjectType } = body;
    switch (subObjectType) {
        case types.VIEW:
            return viewListHandler({ body });
        case types.VIEW_FILTER:
            return viewFilterHandler({ body });
        case types.VIEW_SORT:
            return viewSortHandler({ body });
        case types.COLUMN:
            return columnHandler({ body });
        default:
            return;
    }
}

export default viewHandler;
