import React from 'react';

function FlightSVG({ color = '#2D97F2', ...other }) {
    return (
        <svg {...other} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.8"
                d="M11.6871 0.600621C11.4955 0.409029 11.1124 0.313232 10.7292 0.504825L0.766369 4.14508C0.191592 4.33667 0 4.81565 0 5.00724C0 5.19884 0 5.67782 0.574777 6.061L3.35287 7.78533V10.4676C3.35287 11.1382 3.73605 11.4256 3.92764 11.5214C4.02344 11.5214 4.11924 11.5214 4.21503 11.5214C4.50242 11.5214 4.78981 11.3298 5.0772 11.0424L6.32255 9.50966L7.66369 10.276C7.95108 10.4676 8.23847 10.4676 8.52586 10.3718C8.81325 10.276 9.00484 10.0844 9.10063 9.79705L11.9745 1.65438C12.0703 1.0796 11.8787 0.792213 11.6871 0.600621ZM9.10063 2.22916L3.92764 6.73157L1.24535 5.10304L9.10063 2.22916ZM10.346 2.70814L7.95108 9.31807L4.8856 7.40215L10.346 2.70814ZM5.26879 8.93489L4.50242 9.89285V8.4559L5.26879 8.93489Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(FlightSVG);
