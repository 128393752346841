import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'advancedSearch/actions';
import ViewCellInfo from './ViewCellInfo';
import {
    TEXT_LINE_HEIGHT,
    MAX_ROW_RESIZE,
    CELL_PADDING,
    AGG_HEIGHT,
    GLOBAL_FILTER_HEIGHT,
    BORDER_HIGHLIGHT
} from 'const/gridUI';
import { HEADER_HEIGHT } from 'const';
import { moveCaretAtEnd } from 'utils/cursor';
import { isKbEscape, isKbTab, isKbShiftTab, isKbEnter, isKbShiftEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import TextareaAutosize from 'react-autosize-textarea';
import { getViewport } from './calculatePosition';
import { getCellBackgroundByHex } from 'utils/color';

const useStyles = makeStyles(theme => ({
    multiText: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        padding: CELL_PADDING,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875rem',
        resize: 'auto !important',
        '&:focus': {
            outline: 'none',
            border: `1px solid ${theme.palette.primary.main}`
        }
    }
}));

const MIN_WIDTH = 200;
const MIN_HEIGHT = 200;

function MultiTextEdit({
    columnId,
    value: cellValue,
    rowId,
    top,
    width,
    height,
    rowIndex,
    background,
    rowHeight,
    fillColorBlindness,
    columnIndex,
    originalValue
}) {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(cellValue);
    const classes = useStyles();
    const theme = useTheme();
    const inputRef = React.useRef();
    const isEnterAlready = React.useRef(false);
    const rootRef = React.useRef();
    // eslint-disable-next-line no-unused-vars
    const [viewPortWidth, viewPortHeight] = getViewport();

    const isEditReverseMode = React.useMemo(() => {
        return top + MIN_HEIGHT > viewPortHeight - AGG_HEIGHT - HEADER_HEIGHT - GLOBAL_FILTER_HEIGHT;
    }, [viewPortHeight, top]);

    let style = React.useMemo(() => {
        if (isEditReverseMode) {
            return {
                position: 'absolute',
                left: 0,
                bottom: 0
            };
        }
        return {};
    }, [isEditReverseMode]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway(e);
    });

    React.useEffect(() => {
        let textarea = inputRef.current;
        if (textarea) {
            textarea.scrollTop = textarea.scrollHeight;
            textarea.focus({ preventScroll: true });
        }
    }, []);

    const isSameData = () => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    };

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);

        if (isSameData()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameData()) {
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value,
                    rowId,
                    columnId
                })
            );
        }
    };

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            dispatch(gridActions.cancelCellEdit());
        }

        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            if (value === originalValue) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextRow({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoNextRow({
                    value,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }

        if (isKbTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoNextColumn({
                    value,
                    rowId,
                    columnId,
                    rowIndex,
                    columnIndex
                })
            );
        }

        if (isKbShiftTab(e)) {
            e.preventDefault();
            if (isSameData()) {
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
                return;
            }
            isEnterAlready.current = true;
            dispatch(
                gridActions.cellClickAwayAndGoPreviousColumn({
                    value,
                    rowId,
                    rowIndex,
                    columnId,
                    columnIndex
                })
            );
        }
    };

    return (
        <div ref={rootRef} style={{ position: 'relative' }}>
            <ViewCellInfo rowIndex={rowIndex} />
            <TextareaAutosize
                rows={4}
                ref={inputRef}
                className={`${classes.multiText} cell-editor`}
                style={{
                    width: width,
                    minWidth: MIN_WIDTH,
                    border: `${BORDER_HIGHLIGHT}px solid ${theme.colors.highlight}`,
                    height: height,
                    minHeight: MIN_HEIGHT,
                    maxHeight: isEditReverseMode
                        ? MIN_HEIGHT
                        : Math.min(
                              viewPortHeight - HEADER_HEIGHT - GLOBAL_FILTER_HEIGHT - rowHeight * 3,
                              MAX_ROW_RESIZE
                          ),
                    lineHeight: `${TEXT_LINE_HEIGHT}px`,
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white,
                    ...style
                }}
                onKeyDown={handleKeyDown}
                onFocus={moveCaretAtEnd}
                onChange={e => setValue(e.target.value)}
                value={value || ''}
            />
        </div>
    );
}

export default React.memo(MultiTextEdit);
