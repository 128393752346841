export const FETCH_CONNECTIONS_SUCCESS = '[INTEGRATION]FETCH_CONNECTIONS_SUCCESS';
export const CREATE_CONNECTION = '[INTEGRATION]CREATE_CONNECTION';
export const UPDATE_CONNECTION = '[INTEGRATION]UPDATE_CONNECTION';
export const DELETE_CONNECTION = '[INTEGRATION]DELETE_CONNECTION';
export const DELETE_CONNECTION_BY_SOURCE_ID = '[INTEGRATION]DELETE_CONNECTION_BY_SOURCE_ID';
export const FETCH_CONNECTION_SUCCESS = '[INTEGRATION]FETCH_CONNECTION_SUCCESS';
export const FETCH_HISTORIES_BY_CONNECTION_ID = '[INTEGRATION]FETCH_HISTORIES_BY_CONNECTION_ID';
export const FETCH_HISTORIES_BY_DESTINATION_ID = '[INTEGRATION]FETCH_HISTORIES_BY_DESTINATION_ID';
export const SET_CONNECTIONS_LOADING = '[INTEGRATION]SET_CONNECTIONS_LOADING';

export const FETCH_SOURCES_SUCCESS = '[INTEGRATION]FETCH_SOURCES_SUCCESS';
export const CREATE_SOURCE = '[INTEGRATION]CREATE_SOURCE';
export const DELETE_SOURCE = '[INTEGRATION]DELETE_SOURCE';
export const UPDATE_SOURCE = '[INTEGRATION]UPDATE_SOURCE';
export const FETCH_SOURCE_SUCCESS = '[INTEGRATION]FETCH_SOURCE_SUCCESS';
export const SET_SOURCE_INFO_URL = '[INTEGRATION]SET_SOURCE_INFO_URL';
export const SET_SOURCES_LOADING = '[INTEGRATION]SET_SOURCES_LOADING';

export const FETCH_SHEET_BY_SOURCE_ID = '[INTEGRATION]FETCH_SHEET_BY_SOURCE_ID';
export const FETCH_CONNECTIONS_BY_SOURCE_ID = '[INTEGRATION]FETCH_CONNECTIONS_BY_SOURCE_ID';
export const FETCH_CONNECTIONS_BY_DESTINATION_GRID_ID = '[INTEGRATION]FETCH_CONNECTIONS_BY_DESTINATION_GRID_ID';

export const SET_CONNECTION_SYNC = `[INTEGRATION]SET_CONNECTION_SYNC`;
export const CLEAR_CONNECTION_SYNC = `[INTEGRATION]CLEAR_CONNECTION_SYNC`;
