import React from 'react';

function VersionControlBanner({ color = 'white', ...other }) {
    return (
        <svg width="229" height="200" viewBox="0 0 229 200" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                opacity="0.7"
                d="M169.088 13.2002C155.108 13.2002 141.528 18.1335 130.743 27.0669C126.35 30.6669 121.424 35.8669 118.628 40.1335C114.767 46.1335 111.305 51.7335 107.178 56.8002H173.881C180.538 56.8002 185.996 62.2669 185.996 68.9335V130.134C192.254 128.667 198.378 126.267 204.236 122.134C219.681 110.8 229 92.4002 229 73.2002C229 40.1335 202.239 13.2002 169.088 13.2002Z"
                fill="#DDD7EB"
            />
            <path
                opacity="0.7"
                d="M62.4424 155.6C55.7855 155.6 50.3267 150.134 50.3267 143.467V82.1338C46.3326 82.5338 42.3384 82.9338 38.4773 84.1338C15.8436 91.0671 0 112.667 0 136.4C0 166.534 24.4977 191.067 54.5872 191.067C74.5581 191.067 91.9994 180.4 101.452 164.4C103.316 161.2 105.713 158.4 108.242 155.6H62.4424Z"
                fill="#DDD7EB"
            />
            <path
                d="M31.0219 133.867C33.7425 133.867 35.948 131.658 35.948 128.933C35.948 126.209 33.7425 124 31.0219 124C28.3012 124 26.0957 126.209 26.0957 128.933C26.0957 131.658 28.3012 133.867 31.0219 133.867Z"
                fill="#7869B9"
            />
            <path
                d="M41.9388 144.667C43.5565 144.667 44.8679 143.354 44.8679 141.734C44.8679 140.114 43.5565 138.801 41.9388 138.801C40.3212 138.801 39.0098 140.114 39.0098 141.734C39.0098 143.354 40.3212 144.667 41.9388 144.667Z"
                fill="white"
            />
            <path
                d="M138.332 170.267C139.95 170.267 141.261 168.954 141.261 167.334C141.261 165.714 139.95 164.4 138.332 164.4C136.715 164.4 135.403 165.714 135.403 167.334C135.403 168.954 136.715 170.267 138.332 170.267Z"
                fill="#7869B9"
            />
            <path
                d="M123.287 173.867C127.478 173.867 130.876 170.464 130.876 166.267C130.876 162.07 127.478 158.667 123.287 158.667C119.096 158.667 115.698 162.07 115.698 166.267C115.698 170.464 119.096 173.867 123.287 173.867Z"
                fill="#E8DDEC"
            />
            <path
                d="M154.889 33.4291C156.189 33.1806 157.041 31.9236 156.793 30.6216C156.545 29.3196 155.29 28.4655 153.99 28.7141C152.69 28.9626 151.837 30.2196 152.085 31.5216C152.333 32.8236 153.588 33.6776 154.889 33.4291Z"
                fill="white"
            />
            <path
                d="M184.132 86.1341V143.334C184.132 149.067 179.473 153.601 173.881 153.601H62.4428C56.7178 153.601 52.1911 148.934 52.1911 143.334V68.9341C52.1911 63.2008 56.851 58.6674 62.4428 58.6674H157.371C157.504 58.0008 157.638 57.4674 157.771 56.8008H62.4428C55.7859 56.8008 50.3271 62.2675 50.3271 68.9341V143.467C50.3271 150.134 55.7859 155.601 62.4428 155.601H173.747C180.404 155.601 185.863 150.134 185.863 143.467V85.8675C185.331 86.0008 184.665 86.1341 184.132 86.1341Z"
                fill="#7869B9"
            />
            <path
                d="M181.07 86.3999C167.756 86.3999 156.971 75.5999 156.971 62.2665C156.971 61.0665 157.104 59.7332 157.238 58.5332H62.4422C56.7172 58.5332 52.1904 63.1999 52.1904 68.7999V143.333C52.1904 149.067 56.8503 153.6 62.4422 153.6H173.747C179.472 153.6 183.999 148.933 183.999 143.333V86.1332C183.067 86.2665 182.135 86.3999 181.07 86.3999Z"
                fill="#FAF9FF"
            />
            <path d="M99.9875 89.4668H78.8184V91.3335H99.9875V89.4668Z" fill="#7869B9" />
            <path d="M90.9341 95.334H78.8184V97.2007H90.9341V95.334Z" fill="#7869B9" />
            <path d="M159.634 89.4668H138.465V91.3335H159.634V89.4668Z" fill="#7869B9" />
            <path d="M150.581 95.334H138.465V97.2007H150.581V95.334Z" fill="#7869B9" />
            <path d="M99.9875 122.934H78.8184V124.8H99.9875V122.934Z" fill="#7869B9" />
            <path d="M90.9341 128.934H78.8184V130.8H90.9341V128.934Z" fill="#7869B9" />
            <path
                d="M126.748 102.267C121.822 102.267 117.961 98.267 117.961 93.467C117.961 88.667 121.955 84.667 126.748 84.667C131.541 84.667 135.535 88.667 135.535 93.467C135.535 98.267 131.674 102.267 126.748 102.267ZM126.748 86.4003C122.887 86.4003 119.825 89.467 119.825 93.3337C119.825 97.2003 122.887 100.267 126.748 100.267C130.609 100.267 133.671 97.2003 133.671 93.3337C133.671 89.467 130.609 86.4003 126.748 86.4003Z"
                fill="#7869B9"
            />
            <path
                d="M126.748 100.267C130.572 100.267 133.672 97.1629 133.672 93.3337C133.672 89.5046 130.572 86.4004 126.748 86.4004C122.925 86.4004 119.825 89.5046 119.825 93.3337C119.825 97.1629 122.925 100.267 126.748 100.267Z"
                fill="#E8DDEC"
            />
            <path
                d="M126.748 135.734C121.822 135.734 117.961 131.734 117.961 126.934C117.961 122.134 121.955 118.134 126.748 118.134C131.541 118.134 135.535 122.134 135.535 126.934C135.535 131.734 131.674 135.734 126.748 135.734ZM126.748 120C122.887 120 119.825 123.067 119.825 126.934C119.825 130.8 122.887 133.867 126.748 133.867C130.609 133.867 133.671 130.8 133.671 126.934C133.671 123.067 130.609 120 126.748 120Z"
                fill="#7869B9"
            />
            <path
                d="M126.748 133.867C130.572 133.867 133.672 130.762 133.672 126.933C133.672 123.104 130.572 120 126.748 120C122.925 120 119.825 123.104 119.825 126.933C119.825 130.762 122.925 133.867 126.748 133.867Z"
                fill="#E8DDEC"
            />
            <path
                d="M67.7013 102.267C62.7751 102.267 58.9141 98.267 58.9141 93.467C58.9141 88.667 62.9082 84.667 67.7013 84.667C72.4943 84.667 76.4885 88.667 76.4885 93.467C76.4885 98.267 72.6274 102.267 67.7013 102.267ZM67.7013 86.4003C63.8402 86.4003 60.778 89.467 60.778 93.3337C60.778 97.2003 63.8402 100.267 67.7013 100.267C71.5623 100.267 74.6245 97.2003 74.6245 93.3337C74.6245 89.467 71.5623 86.4003 67.7013 86.4003Z"
                fill="#7869B9"
            />
            <path
                d="M67.7016 100.267C71.5252 100.267 74.6248 97.1629 74.6248 93.3337C74.6248 89.5046 71.5252 86.4004 67.7016 86.4004C63.878 86.4004 60.7783 89.5046 60.7783 93.3337C60.7783 97.1629 63.878 100.267 67.7016 100.267Z"
                fill="#E8DDEC"
            />
            <path
                d="M67.7013 135.734C62.7751 135.734 58.9141 131.734 58.9141 126.934C58.9141 122.134 62.9082 118.134 67.7013 118.134C72.4943 118.134 76.4885 122.134 76.4885 126.934C76.4885 131.734 72.6274 135.734 67.7013 135.734ZM67.7013 120C63.8402 120 60.778 123.067 60.778 126.934C60.778 130.8 63.8402 133.867 67.7013 133.867C71.5623 133.867 74.6245 130.8 74.6245 126.934C74.6245 123.067 71.5623 120 67.7013 120Z"
                fill="#7869B9"
            />
            <path
                d="M67.7016 133.867C71.5252 133.867 74.6248 130.762 74.6248 126.933C74.6248 123.104 71.5252 120 67.7016 120C63.878 120 60.7783 123.104 60.7783 126.933C60.7783 130.762 63.878 133.867 67.7016 133.867Z"
                fill="#E8DDEC"
            />
            <path d="M159.634 122.934H138.465V124.8H159.634V122.934Z" fill="#7869B9" />
            <path d="M150.581 128.934H138.465V130.8H150.581V128.934Z" fill="#7869B9" />
            <path
                d="M115.565 30.8003C115.299 30.8003 115.166 30.667 114.9 30.5337C114.5 30.1337 114.5 29.6003 114.767 29.2003C114.9 28.9337 133.273 8.40033 157.504 6.66699C158.037 6.66699 158.436 7.06699 158.436 7.46699C158.436 8.00033 158.037 8.40033 157.638 8.40033C134.072 10.1337 116.231 30.1337 116.098 30.267C115.965 30.667 115.699 30.8003 115.565 30.8003Z"
                fill="#7869B9"
            />
            <path
                d="M78.9517 193.734C78.5523 193.734 78.286 193.467 78.0197 193.2C77.8866 192.667 78.0197 192.134 78.5523 192C93.597 186 101.985 173.734 102.118 173.734C102.384 173.334 103.05 173.2 103.449 173.467C103.849 173.734 103.982 174.4 103.716 174.8C103.316 175.334 94.9284 187.6 79.3511 193.867C79.218 193.734 79.0848 193.734 78.9517 193.734Z"
                fill="#7869B9"
            />
            <path
                d="M181.07 38.2666C180.271 38.2666 179.605 38.2666 178.94 38.3999C178.807 38.3999 178.673 38.3999 178.54 38.3999C177.875 38.5333 177.209 38.5333 176.41 38.6666C176.277 38.6666 176.144 38.6666 176.011 38.7999C175.345 38.9333 174.812 39.0666 174.147 39.1999C174.014 39.1999 173.88 39.1999 173.747 39.3333C173.082 39.5999 172.416 39.7333 171.883 39.9999C171.75 39.9999 171.617 40.1333 171.484 40.1333C170.818 40.3999 170.286 40.6666 169.62 41.0666C170.019 41.9999 170.152 42.9333 170.152 43.8666C170.152 47.9999 166.824 51.4666 162.564 51.4666C161.632 51.4666 160.7 51.3333 159.768 50.9333C159.501 51.4666 159.235 51.9999 158.969 52.5333C158.836 52.7999 158.702 53.0666 158.702 53.1999C158.436 53.7333 158.303 54.2666 158.037 54.9333C158.037 55.0666 157.904 55.3333 157.904 55.4666C157.771 55.9999 157.637 56.5333 157.504 57.0666C157.504 57.1999 157.371 57.4666 157.371 57.5999C157.238 58.2666 157.105 58.9333 157.105 59.5999C157.105 59.7333 157.105 59.8666 157.105 60.1333C157.105 60.7999 156.972 61.5999 156.972 62.2666C156.972 75.5999 167.756 86.3999 181.07 86.3999C194.384 86.3999 205.035 75.5999 205.035 62.2666C205.035 48.9333 194.251 38.2666 181.07 38.2666Z"
                fill="#DDD7EB"
            />
            <path
                d="M181.07 36.4004C176.543 36.4004 172.416 37.6004 168.688 39.6004C167.357 37.7337 165.093 36.5337 162.564 36.5337C158.436 36.5337 154.975 39.8671 154.975 44.1337C154.975 46.6671 156.173 48.9337 158.17 50.2671C156.173 53.8671 155.108 58.0004 155.108 62.4004C155.108 76.6671 166.691 88.4004 181.07 88.4004C195.316 88.4004 207.032 76.8004 207.032 62.4004C207.032 48.0004 195.316 36.4004 181.07 36.4004ZM181.07 86.4004C167.756 86.4004 156.972 75.6004 156.972 62.2671C156.972 58.1337 158.037 54.4004 159.768 50.9337C160.566 51.3337 161.632 51.4671 162.564 51.4671C166.691 51.4671 170.153 48.1337 170.153 43.8671C170.153 42.8004 169.886 41.8671 169.62 41.0671C173.082 39.2004 176.943 38.1337 181.07 38.1337C194.384 38.1337 205.168 48.9337 205.168 62.2671C205.168 75.6004 194.251 86.4004 181.07 86.4004Z"
                fill="#7869B9"
            />
            <path
                d="M181.736 50.2668C181.47 49.8668 180.671 49.8668 180.404 50.2668L172.15 61.8668C172.017 62.1335 171.884 62.4001 172.15 62.6668C172.283 62.9335 172.549 63.0668 172.949 63.0668H177.076V73.0668C177.076 73.4668 177.209 73.8668 177.609 74.2668C177.875 74.5335 178.274 74.8001 178.807 74.8001C178.94 74.8001 183.733 74.8001 183.733 74.8001C184.132 74.8001 184.532 74.6668 184.931 74.2668C185.198 74.0001 185.464 73.6001 185.464 73.0668V63.0668H189.591C189.857 63.0668 190.124 62.9335 190.39 62.6668C190.523 62.4001 190.523 62.0001 190.39 61.8668L181.736 50.2668Z"
                fill="#7869B9"
            />
            <path d="M115.965 67.0674H59.1143V72.0007H115.965V67.0674Z" fill="#AEA1CD" />
        </svg>
    );
}

export default React.memo(VersionControlBanner);
