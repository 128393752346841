import React, { useCallback, useMemo, useRef } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import EmptyBoxSVG from 'assets/images/svg/EmptyBoxSVG';
import ThreeStateCheckBox from 'components/checkbox/ThreeStateCheckBox';
import Spinner from 'components/spinner/Base';
import { useDispatch } from 'react-redux';
import { createTerm } from 'gridUI/actions';
import groupBy from 'lodash/groupBy';
import { useMemberMappingById } from 'hooks/permission/member';
import TBDetailGroupTerm from './TBDetailGroupTerm';
import TBDetailFilter from './TBDetailFilter';
import { AutoSizer } from 'react-virtualized-dn';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    empty: {
        height: '100%'
    },
    emptyList: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%'
    },
    whiteBackground: {
        background: theme.colors.white
    },
    checkbox: {
        '& rect': {
            fill: theme.colors.white
        }
    },
    loading: {
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
        background: 'rgba(0, 0, 0, 0.1)'
    }
}));

const TBRow = ({
    t,
    selectedRows,
    glossary,
    handleCheckRow,
    COLUMNS,
    memberMapping,
    onCreateTerm,
    onCancelCreateTerm,
    onClickTerm
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const { entryId, terms, isNew } = t;

    const isChecked = useMemo(() => {
        return selectedRows.includes(entryId);
    }, [selectedRows, entryId]);

    const groupTerms = useMemo(() => {
        const groups = groupBy(terms, 'lang');
        [...(glossary.langs || [])].forEach(lang => {
            if (!groups[lang]) {
                groups[lang] = [
                    { id: `${lang}-${new Date().getTime()}`, text: '', lang, isNew: true, manuallyAdded: true }
                ];
            }
        });
        return groups;
    }, [glossary.langs, terms]);

    const termHasOnlyOneData = useMemo(() => {
        let temp = true;
        for (let i = 0; i < glossary.langs.length; i++) {
            const lang = glossary.langs[i];
            const data = terms.filter(term => term.lang === lang);
            if (data.length > 1) {
                temp = false;
                break;
            }
        }
        return temp;
    }, [glossary.langs, terms]);

    return (
        <TableRow
            key={entryId}
            hover
            role="checkbox"
            tabIndex={-1}
            style={{
                verticalAlign: termHasOnlyOneData ? 'middle' : 'baseline',
                ...(isChecked
                    ? {
                          background: '#E0EFFD'
                      }
                    : {
                          background: theme.palette.common.white
                      })
            }}
        >
            <TableCell style={{ verticalAlign: 'middle' }}>
                {!t.isNew && (
                    <ThreeStateCheckBox
                        className={!isChecked ? classes.checkbox : ''}
                        state={isChecked ? 1 : 2}
                        onChange={e => handleCheckRow(e, entryId)}
                    />
                )}
            </TableCell>

            <TBDetailGroupTerm
                glossaryId={glossary.id}
                entryId={entryId}
                isChecked={isChecked}
                isNewTerm={isNew}
                columns={COLUMNS}
                externalGroupTerms={groupTerms}
                memberMapping={memberMapping}
                onCreateTerm={onCreateTerm}
                onCancelCreateTerm={onCancelCreateTerm}
                onClickTerm={onClickTerm}
            />
        </TableRow>
    );
};

const TBDetailTable = ({
    glossary,
    listTerms,
    setListTerms,
    isFetching,
    selectedRows,
    setSelectedRows,
    handleResetFetch,
    isFilteringOption,
    handleChangeFilterOption,
    filterOptions,
    onClickTerm,
    tableContainerRef,
    auditors
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const tableHeadRef = useRef();
    const memberMapping = useMemberMappingById();

    const COLUMNS = React.useMemo(() => {
        return [
            { id: 'language', label: 'Language', minWidth: 170 },
            { id: 'text', label: 'Term', minWidth: 300 },
            {
                id: 'createdBy',
                label: 'Created by',
                minWidth: 170
            },
            {
                id: 'createdTime',
                label: 'Date created',
                minWidth: 200
            },
            {
                id: 'alteredTime',
                label: 'Last modified',
                minWidth: 200
            },
            {
                id: 'status',
                label: t('global_status'),
                minWidth: 170
            },
            {
                id: 'alteredBy',
                label: 'Last modified by',
                minWidth: 170
            }
        ];
    }, [t]);

    const renderLoading = useCallback(() => {
        if (isFetching) {
            return (
                <Grid
                    container
                    className={classes.loading}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    style={{ top: tableContainerRef?.current?.scrollTop, left: tableContainerRef?.current?.scrollLeft }}
                >
                    <Spinner size={30} thick={4} />
                </Grid>
            );
        }
        return null;
    }, [isFetching, classes, tableContainerRef]);

    const renderEmpty = useCallback(() => {
        if (!isFetching && !listTerms?.length && !isFilteringOption) {
            return (
                <Grid
                    container
                    className={classes.empty}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <EmptyBoxSVG width="252" height="196" />
                    </Grid>
                    <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">{t('nothing_here_yet')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">Add a new Term now to begin</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }, [classes.empty, isFetching, listTerms.length, isFilteringOption, t]);

    const renderEmptyList = useCallback(
        ({ width, height }) => {
            if (isFetching || listTerms.length) return null;
            return (
                <Grid
                    container
                    className={classes.emptyList}
                    justify="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                    style={{ width, height }}
                >
                    <Grid item>
                        <EmptyBoxSVG width="252" height="196" />
                    </Grid>
                    <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h4">Nothing here</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        },
        [classes.emptyList, listTerms.length, isFetching]
    );

    const renderTableHeader = useCallback(() => {
        return (
            <TableHead ref={tableHeadRef}>
                <TableRow>
                    <TableCell className={classes.whiteBackground} />
                    {COLUMNS.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                className={classes.whiteBackground}
                            >
                                {column.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
                <TableRow>
                    <TableCell className={classes.whiteBackground} style={{ top: 57 }} />
                    {COLUMNS.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth, top: 57 }}
                                className={classes.whiteBackground}
                            >
                                <TBDetailFilter
                                    columnKey={column.id}
                                    handleChangeFilterOption={handleChangeFilterOption}
                                    defaultValue={filterOptions[column.id]}
                                    auditors={auditors}
                                />
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
        );
    }, [COLUMNS, classes.whiteBackground, filterOptions, handleChangeFilterOption, auditors]);

    const handleCheckRow = React.useCallback(
        (e, id) => {
            e.stopPropagation();
            e.preventDefault();
            if (selectedRows.includes(id)) {
                const newSelectedRows = selectedRows.filter(r => r !== id);
                setSelectedRows(newSelectedRows);
                return;
            }
            selectedRows.push(id);
            setSelectedRows([...selectedRows]);
        },
        [selectedRows, setSelectedRows]
    );

    const onCancelCreateTerm = useCallback(() => {
        setListTerms(prev => prev.filter(el => !el.isNew));
    }, [setListTerms]);

    const onCreateTerm = useCallback(
        ({ newGroups, onError }) => {
            const body = {
                terms: Object.values(newGroups)
            };
            dispatch(
                createTerm({
                    tbId: glossary.id,
                    body,
                    successCallback: () => {
                        handleResetFetch({ resetPage: true, resetSelectedRows: true });
                    },
                    errorCallback: onError
                })
            );
        },
        [dispatch, glossary.id, handleResetFetch]
    );

    return (
        <Grid item style={{ flex: 1 }}>
            {renderEmpty()}
            {(listTerms.length > 0 || isFilteringOption || isFetching) && (
                <Grid item style={{ width: '100%', height: '100%' }}>
                    <AutoSizer style={{ width: '100%' }}>
                        {({ width, height }) => {
                            return (
                                <TableContainer
                                    ref={tableContainerRef}
                                    style={{
                                        height,
                                        position: 'relative',
                                        ...(isFetching
                                            ? { overflow: 'hidden' }
                                            : !listTerms.length
                                            ? {
                                                  overflowY: 'hidden'
                                              }
                                            : {})
                                    }}
                                >
                                    {renderLoading()}
                                    <Table stickyHeader aria-label="sticky table">
                                        {renderTableHeader()}
                                        <TableBody>
                                            {renderEmptyList({
                                                width,
                                                height: height - tableHeadRef.current?.offsetHeight
                                            })}

                                            {listTerms.map(t => (
                                                <TBRow
                                                    key={t.entryId}
                                                    t={t}
                                                    selectedRows={selectedRows}
                                                    glossary={glossary}
                                                    handleCheckRow={handleCheckRow}
                                                    COLUMNS={COLUMNS}
                                                    memberMapping={memberMapping}
                                                    onCreateTerm={onCreateTerm}
                                                    onCancelCreateTerm={onCancelCreateTerm}
                                                    onClickTerm={onClickTerm}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        }}
                    </AutoSizer>
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(TBDetailTable);
