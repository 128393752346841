import React from 'react';

function AddColumnSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg
            {...other}
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="icon/add-column" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    fill={color}
                    d="M1.368,14.5458 L3.137,14.5458 L3.137,1.2548 L1.368,1.2548 L1.368,14.5458 Z M3.822,-0.0002 C4.199,-0.0002 4.506,0.2808 4.506,0.6268 L4.506,15.1728 C4.506,15.5178 4.199,15.7998 3.822,15.7998 L0.685,15.7998 C0.307,15.7998 6.11066753e-13,15.5178 6.11066753e-13,15.1728 L6.11066753e-13,0.6268 C6.11066753e-13,0.2808 0.307,-0.0002 0.685,-0.0002 L3.822,-0.0002 Z M8.6582,6.4902 L10.0272,6.4902 L10.0272,15.1722 C10.0272,15.5182 9.7202,15.8002 9.3432,15.8002 L6.2052,15.8002 C5.8282,15.8002 5.5212,15.5182 5.5212,15.1722 L5.5212,0.6272 C5.5212,0.2812 5.8282,0.0002 6.2052,0.0002 L9.3432,0.0002 C9.7202,0.0002 10.0272,0.2812 10.0272,0.6272 L10.0272,1.3702 L8.6582,1.3702 L6.8892,1.3702 L6.8892,14.5452 L8.6582,14.5452 L8.6582,6.4902 Z M14.1787,6.4902 L15.5477,6.4902 L15.5477,15.1722 C15.5477,15.5182 15.2407,15.8002 14.8637,15.8002 L11.7257,15.8002 C11.3487,15.8002 11.0417,15.5182 11.0417,15.1722 L11.0417,9.4492 L12.4097,9.4492 L12.4097,14.5452 L14.1787,14.5452 L14.1787,6.4902 Z M15.3774,3.334 L15.3774,4.709 L12.4184,4.709 L12.4184,7.668 L11.0434,7.668 L11.0434,4.709 L8.0854,4.709 L8.0854,3.334 L11.0434,3.334 L11.0434,0.375 L12.4184,0.375 L12.4184,3.334 L15.3774,3.334 Z"
                    id="Combined-Shape"
                ></path>
            </g>
        </svg>
    );
}

export default React.memo(AddColumnSVG);
