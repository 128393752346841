import * as types from './type';
import { USER_LOGOUT_SUCCESS } from '../auth/types';
import produce from 'immer';
import { getItem } from 'helpers/localstorage';
import { MAINTAIN_TYPES } from 'const/index';

function getShowWelcomeStatus() {
    const email = getItem('user')?.email;
    const identify = getItem('identify')?.[email];
    return identify?.isShowWelcome;
}

const initialState = {
    companyInfo: {},
    socketId: null,
    currentPage: null,
    contextMenuId: null,
    locales: null,
    rightSidebar: {
        type: null,
        body: {}
    },
    feedback: {
        isOpen: false,
        selectedFeedbackOption: null,
        createJiraTypes: null
    },
    isShowWelcome: getShowWelcomeStatus(),
    isShowHelpMenuTooltip: false,
    version: null,
    isShowChromeNotify: false,
    isShowMaintainMode: false,
    maintainType: MAINTAIN_TYPES.MAINTAIN,
    shareViewKey: null,
    isShareViewLink: false,
    isDisabledSnackbar: false,
    currentTutorial: '',
    isShowFreeTrial: false,
    isShowKeyBoardShotCut: false,
    isShowTemplates: false,
    notification: {
        type: null,
        data: null
    },
    apiStatus: null,
    promoteFeatures: [],
    selfHostingLicense: null
};

export default function Database(state = initialState, action) {
    switch (action.type) {
        case USER_LOGOUT_SUCCESS:
            return {
                ...initialState,
                isShowTemplates: false,
                apiStatus: state.apiStatus
            };

        case types.SAVE_SOCKET_ID: {
            const { socketId } = action.payload;
            return {
                ...state,
                socketId
            };
        }

        case types.SET_ACCESS_TOKEN: {
            const { accessToken } = action.payload;
            return {
                ...state,
                accessToken
            };
        }

        case types.SET_COMPANY_INFO: {
            const { companyInfo } = action.payload;
            return {
                ...state,
                companyInfo
            };
        }

        case types.CHANGE_COMPANY_LOGO: {
            const { logoUrl } = action.payload;
            return {
                ...state,
                companyInfo: {
                    ...state.companyInfo,
                    logoUrl
                }
            };
        }

        case types.SET_CURRENT_PAGE: {
            const { currentPage } = action.payload;
            return {
                ...state,
                currentPage
            };
        }

        case types.CHANGE_CONTEXT_MENU: {
            const { contextMenuId } = action.payload;
            return {
                ...state,
                contextMenuId
            };
        }
        case types.SET_RIGHT_SIDEBAR: {
            const { type, body } = action.payload;
            return {
                ...state,
                rightSidebar: {
                    ...state.rightSidebar,
                    type,
                    body
                }
            };
        }
        case types.WRITE_CLIPBOARD: {
            const { content, raw } = action.payload;
            const clipboard = state.clipboard || {};

            const newClipboard = produce(clipboard, draft => {
                draft.content = content;
                draft.raw = raw;
            });

            return {
                ...state,
                clipboard: newClipboard
            };
        }

        case types.GET_JIRA_CREATE_TYPES: {
            const { createJiraTypes } = action.payload;

            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    createJiraTypes
                }
            };
        }

        case types.SET_FEEDBACK_OPTION: {
            const { feedbackOption } = action.payload;

            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    selectedFeedbackOption: feedbackOption
                }
            };
        }

        case types.SET_IS_OPEN_FEEDBACK: {
            const { boolean } = action.payload;

            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    isOpen: boolean
                }
            };
        }

        case types.OPEN_WELCOME: {
            return {
                ...state,
                isShowWelcome: true
            };
        }

        case types.CLOSE_WELCOME: {
            return {
                ...state,
                isShowWelcome: false
            };
        }

        case types.OPEN_HELP_MENU_TOOTLTIP: {
            return {
                ...state,
                isShowHelpMenuTooltip: true
            };
        }

        case types.CLOSE_HELP_MENU_TOOTLTIP: {
            return {
                ...state,
                isShowHelpMenuTooltip: false
            };
        }

        case types.SET_APP_VERSION: {
            const { version } = action.payload;

            return {
                ...state,
                version
            };
        }
        case types.TOGGLE_CHROME_NOTIFY: {
            const { isShowChromeNotify } = state;
            return {
                ...state,
                isShowChromeNotify: !isShowChromeNotify
            };
        }

        case types.SET_STATUS_CHROME_NOTIFY: {
            const { status } = action.payload;
            return {
                ...state,
                isShowChromeNotify: status
            };
        }

        case types.TURN_OFF_MAINTAIN_POPUP: {
            return {
                ...state,
                isShowMaintainMode: false
            };
        }

        case types.TURN_ON_MAINTAIN_POPUP: {
            const { maintainType } = action.payload;
            return {
                ...state,
                isShowMaintainMode: true,
                maintainType
            };
        }

        case types.IS_TOGGLE_DISABLED_SNACKBAR: {
            const { isDisabledSnackbar } = state;
            return {
                ...state,
                isDisabledSnackbar: !isDisabledSnackbar
            };
        }

        case types.SAVE_SHARE_KEY_ID: {
            const shareViewKey = action?.payload?.shareViewKey;
            return {
                ...state,
                shareViewKey,
                isShareViewLink: !!shareViewKey
            };
        }

        case types.SET_CURRENT_TUTORIAL: {
            const { tutorialType } = action.payload;
            return {
                ...state,
                currentTutorial: tutorialType
            };
        }

        case types.SHOW_FREE_TRIAL: {
            return {
                ...state,
                isShowFreeTrial: true
            };
        }

        case types.HIDE_FREE_TRIAL: {
            return {
                ...state,
                isShowFreeTrial: false
            };
        }

        case types.TOGGLE_KEY_BOARD_SHORT_CUT: {
            const { isShowKeyBoardShotCut } = state;
            return {
                ...state,
                isShowKeyBoardShotCut: !isShowKeyBoardShotCut
            };
        }

        case types.SET_SHOW_TEMPLATES: {
            const { show } = action.payload;

            return {
                ...state,
                isShowTemplates: show
            };
        }

        case types.OPEN_NOTIFICATION_INFO: {
            const { type, data } = action?.payload;
            const { notification } = state;

            return {
                ...state,
                notification: {
                    ...notification,
                    type,
                    data
                }
            };
        }

        case types.CLOSE_NOTIFICATION_INFO: {
            return {
                ...state,
                notification: {
                    type: null,
                    data: null
                }
            };
        }

        case types.SET_API_STATUS: {
            const { apiStatus } = action?.payload;

            return {
                ...state,
                apiStatus
            };
        }
        case types.SET_PROMOTE_FEATURES: {
            return {
                ...state,
                promoteFeatures: action?.payload || []
            };
        }
        case types.SET_SELF_HOSTING_LICENSE: {
            const { selfHostingLicense } = action?.payload;

            return {
                ...state,
                selfHostingLicense
            };
        }

        default:
            return state;
    }
}
