import React from 'react';

function BackIOSSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M10.2333 1.39773L8.85845 0L0 8.70695L8.85845 17.4139L10.2333 16.0162L2.79629 8.70595L10.2333 1.39773Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(BackIOSSVG);
