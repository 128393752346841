import React from 'react';

function WarningSwitchingColumnSVG({ color = '#F6BD42', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M0 8.00002C0 3.58005 3.58001 4.1008e-05 7.99998 4.1008e-05C12.42 4.1008e-05 16 3.58001 16 8.00002C16 12.42 12.42 16 7.99998 16C3.58001 16 0 12.42 0 8.00002Z"
                fill="white"
            />
            <path
                d="M0 8.00002C0 3.58005 3.58001 4.1008e-05 7.99998 4.1008e-05C12.42 4.1008e-05 16 3.58001 16 8.00002C16 12.42 12.42 16 7.99998 16C3.58001 16 0 12.42 0 8.00002Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00001 12.1988C8.55229 12.1988 9.00001 11.7511 9.00001 11.1988C9.00001 10.6466 8.55229 10.1988 8.00001 10.1988C7.44772 10.1988 7 10.6466 7 11.1988C7 11.7511 7.44772 12.1988 8.00001 12.1988ZM8.00001 8.99882C8.55229 8.99882 9.00001 8.5511 9.00001 7.99882L9.00001 4.79883C9.00001 4.24655 8.55229 3.79883 8.00001 3.79883C7.44772 3.79883 7 4.24655 7 4.79883V7.99882C7 8.55111 7.44772 8.99882 8.00001 8.99882Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(WarningSwitchingColumnSVG);
