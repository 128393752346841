import React from 'react';

function ImportLocalizationDropSVG({ color = '#93929e', ...other }) {
    return (
        <svg width="67" height="73" viewBox="0 0 67 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M46.2722 71.9722L3.47222 60.2722C0.972222 59.5722 -0.527778 56.9722 0.172222 54.3722L14.1722 3.47222C14.8722 0.972222 17.4722 -0.527778 20.0722 0.172222L62.8722 11.9722C65.3722 12.6722 66.8722 15.2722 66.1722 17.8722L52.1722 68.7722C51.3722 71.1722 48.7722 72.6722 46.2722 71.9722Z"
                fill="#E9EAEF"
            />
            <path
                d="M55.4722 67.1727H10.6722C8.17224 67.1727 6.17224 65.1727 6.17224 62.6727V9.47266C6.17224 6.97266 8.17224 4.97266 10.6722 4.97266H45.0722C46.7722 4.97266 48.3722 5.67266 49.4722 6.77266L58.0722 15.2727C59.2722 16.4727 59.9722 18.0727 59.9722 19.7727V62.7727C60.0722 65.1727 57.9722 67.1727 55.4722 67.1727Z"
                fill="white"
            />
            <path
                d="M42.0722 29.576C41.4722 28.876 40.7722 28.376 39.8722 28.176C40.1722 27.776 40.2722 27.276 40.2722 26.776C40.2722 26.076 39.9722 25.476 39.4722 24.876C38.9722 24.376 38.3722 24.076 37.5722 24.076C36.9722 24.076 36.3722 24.276 35.8722 24.676C35.4722 23.676 34.8722 22.876 33.9722 22.276C33.0722 21.676 32.0722 21.376 31.0722 21.376C29.6722 21.376 28.3722 21.876 27.3722 22.876C26.3722 23.876 25.8722 25.176 25.8722 26.576C25.8722 26.676 25.8722 26.776 25.8722 26.976C25.0722 27.376 24.4722 27.876 23.9722 28.676C23.4722 29.476 23.2722 30.276 23.2722 31.176C23.2722 32.476 23.6722 33.476 24.5722 34.376C25.4722 35.276 26.5722 35.676 27.7722 35.676H38.9722C40.0722 35.676 40.9722 35.276 41.7722 34.476C42.5722 34.076 42.9722 33.176 42.9722 32.076C42.9722 31.176 42.6722 30.376 42.0722 29.576ZM36.2722 29.376C36.1722 29.476 36.1722 29.476 36.0722 29.476H33.7722V33.076C33.7722 33.176 33.7722 33.276 33.6722 33.276C33.5722 33.376 33.5722 33.376 33.4722 33.376H31.4722C31.3722 33.376 31.2722 33.376 31.2722 33.276C31.1722 33.176 31.1722 33.176 31.1722 33.076V29.476H28.8722C28.7722 29.476 28.6722 29.476 28.6722 29.376C28.5722 29.276 28.5722 29.276 28.5722 29.176C28.5722 29.076 28.5722 28.976 28.6722 28.976L32.2722 25.376C32.3722 25.276 32.3722 25.276 32.4722 25.276C32.5722 25.276 32.6722 25.276 32.6722 25.376L36.2722 28.976C36.3722 29.076 36.3722 29.076 36.3722 29.176C36.3722 29.176 36.3722 29.276 36.2722 29.376Z"
                fill={color}
            />
            <path
                d="M22.4722 50.3676L25.4722 45.9676L22.7722 41.7676H24.8722L26.6722 44.6676L28.3722 41.8676H30.3722L27.6722 46.0676L30.6722 50.4676H28.4722L26.5722 47.4676L24.6722 50.4676L22.4722 50.3676Z"
                fill={color}
            />
            <path d="M36.7722 50.3676H31.2722V41.7676H33.0722V48.7676H36.7722V50.3676Z" fill={color} />
            <path
                d="M37.4722 48.1678L38.9722 47.5678C39.1722 48.4678 39.7722 48.9678 40.3722 49.0678C41.0722 49.1678 41.8722 48.8678 41.9722 48.1678C42.0722 47.3678 41.2722 46.8678 39.5722 46.3678C38.4722 45.9678 37.6722 44.9678 37.7722 43.9678C37.7722 43.0678 38.4722 41.9678 39.7722 41.7678C41.0722 41.4678 42.7722 41.8678 43.3722 43.4678L41.9722 43.9678C41.6722 43.1678 40.8722 42.8678 40.0722 43.1678C39.2722 43.4678 39.3722 43.9678 39.4722 44.1678C39.5722 44.5678 40.2722 44.8678 40.5722 44.9678C42.5722 45.6678 43.7722 46.2678 43.7722 47.8678C43.7722 49.4678 42.4722 50.3678 40.8722 50.4678C39.2722 50.6678 37.7722 49.7678 37.4722 48.1678Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(ImportLocalizationDropSVG);
