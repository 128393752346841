import React from 'react';
import { Avatar, useTheme } from '@material-ui/core';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { ROW_HEIGHT } from 'const/gridUI';
import { isJson } from 'utils/checkType';
import { isFileAudio, isFileImage, isFileVideo, getFilePreview, isFilePdf } from 'utils/images';
import AudioThumbnailSVG from 'assets/images/svg/files/AudioThumbnailSVG';
import VideoThumbnailSVG from 'assets/images/svg/files/VideoThumbnailSVG';
import OtherFileTypeThumbnailSVG from 'assets/images/svg/files/OtherFileTypeThumbnailSVG';
import hexToRgba from 'hex-to-rgba';
import { isReferenceDataForMulti } from 'utils/gridUI/data';
import { isArrayObject } from 'utils/object';
import PDFFILESVG from 'assets/images/svg/files/PDFFILESVG';

const IMAGE_FIXED_HEIGHT = 28;
const IMAGE_FIXED_WIDTH = 28;

window.isArray = isArray;
window.isJson = isJson;

function ReferenceFileRender({ value, rowHeight = ROW_HEIGHT, rowIndex, columnIndex, columnWidth, dbId, ...rest }) {
    const theme = useTheme();
    const width = IMAGE_FIXED_WIDTH;
    const height = IMAGE_FIXED_HEIGHT;

    if (isEmpty(value) || !isReferenceDataForMulti(value)) return null;

    const getJsonValue = value => {
        if (isArray(value)) {
            let values = value.map(i => {
                const childFiles = isJson(i?.referencedDataItem)
                    ? JSON.parse(i?.referencedDataItem)
                    : isArray(i?.referencedDataItem)
                    ? i?.referencedDataItem
                    : [];
                return childFiles;
            });
            return values.filter(Boolean);
        }
        return null;
    };

    let newData = getJsonValue(value);
    if (!isArray(newData) || !newData || !isArrayObject(newData)) return null;

    return (
        <div className={`w-full cell_${rowIndex}_${columnIndex} flex flex-wrap w-fit gap-1`}>
            {newData.map((items, indexItems) => (
                <div
                    style={{
                        background: hexToRgba(theme.colors.dodgerBlue, 0.1),
                        border: `1px solid ${hexToRgba(theme.colors.dodgerBlue, 0.3)}`
                    }}
                    key={indexItems}
                    className={'rounded px-0.5 flex flex-nowrap gap-1'}
                >
                    {items?.map(fileMeta => {
                        let isImage = isFileImage(fileMeta);
                        let isAudio = isFileAudio(fileMeta);
                        let isVideo = isFileVideo(fileMeta);
                        let isPdf = isFilePdf(fileMeta);

                        let url = getFilePreview({ dbId, fileId: fileMeta?.thumbnailId });

                        return (
                            <div className={'rounded py-0.5'} key={fileMeta?.id}>
                                <Avatar
                                    className={`cell_${rowIndex}_${columnIndex}`}
                                    style={{
                                        lineHeight: `${rowHeight}px`,
                                        height: height,
                                        width: width,
                                        borderRadius: 4,
                                        background: !isImage
                                            ? isAudio
                                                ? '#EEF5FB'
                                                : isVideo
                                                ? '#FDF0ED'
                                                : '#F3F2F9'
                                            : ''
                                    }}
                                    src={url}
                                    alt={fileMeta?.originalName}
                                >
                                    {!isImage &&
                                        (isAudio ? (
                                            <AudioThumbnailSVG style={{ width, height }} />
                                        ) : isVideo ? (
                                            <VideoThumbnailSVG style={{ width, height }} />
                                        ) : isPdf ? (
                                            <PDFFILESVG style={{ width, height }} />
                                        ) : (
                                            <OtherFileTypeThumbnailSVG style={{ width, height }} />
                                        ))}
                                </Avatar>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export default React.memo(ReferenceFileRender);
