import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getBGByAction, getIconByAction } from 'utils/color';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import { DELETE } from 'const/gridUI';
import { parseJson } from 'utils/object';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0px 8px`,
        borderRadius: 4,
        background: props => getBGByAction({ action: props.action, theme, opacity: 0.1 }),
        width: 'auto',
        marginTop: 8
    }
}));

function ColumnDiff({ action, extraJson, t }) {
    const classes = useStyles({ action });
    const theme = useTheme();
    let object = parseJson(extraJson);
    if (!object) return null;

    return (
        <Grid className={classes.root} container alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item>{getIconByAction({ action, theme })}</Grid>
            <Grid item style={{ display: 'flex' }}>
                <ColumnIcon
                    group={object?.group}
                    type={object?.dataType?.toLowerCase() || ''}
                    customProperties={object?.customProperties}
                />
            </Grid>
            <Grid item>
                <p
                    className={classNames(`body2`, {
                        'line-through': action === DELETE
                    })}
                >
                    {object.name}
                </p>
            </Grid>
        </Grid>
    );
}

export default React.memo(ColumnDiff);
