import React from 'react';
import { useDispatch } from 'react-redux';
import { checkContainId } from 'utils/clickAway';
import * as gridActions from 'gridUI/actions';
import ViewCellInfo from './ViewCellInfo';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { beautifyJSONValue } from 'utils/gridUI/formatData';
import JsonEditor from 'components/jsoneditor';
import { Portal } from 'react-overlays';

function YamlTextEdit({
    columnId,
    column,
    value: cellValue,
    rowId,
    position,
    dimension,
    rowIndex,
    originalValue,
    background,
    columnIndex
}) {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(cellValue);
    const isEnterAlready = React.useRef(false);
    const rootRef = React.useRef();
    const pasteRef = React.useRef(``);

    useClickAwaitListener(
        rootRef,
        e => {
            handleClickAway(e);
        },
        0
    );

    const isSameData = () => {
        if ((originalValue === null || !originalValue) && !value) return true;
        return value === originalValue;
    };

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);

        if (isSameData()) {
            return dispatch(gridActions.cancelCellEdit());
        }
        if (!isEnterAlready.current && !isSameData()) {
            dispatch(
                gridActions.cellClickAway({
                    isClickYourSelf,
                    value,
                    columnId,
                    rowId
                })
            );
        }
    };
    const handleSaveAndQuit = editor => {
        const lastedValue = editor?.getValue();
        if (lastedValue === originalValue) {
            dispatch(gridActions.cancelCellEdit());
            dispatch(gridActions.moveCellToNextRow());
            return;
        }
        isEnterAlready.current = true;
        dispatch(
            gridActions.cellClickAwayAndGoNextRow({
                value: lastedValue,
                rowId,
                columnId,
                rowIndex,
                columnIndex
            })
        );
    };

    const handlePaste = pastedValue => {
        pasteRef.current = pastedValue;
    };

    const handleEscape = () => {
        dispatch(gridActions.cancelCellEdit());
    };

    const handleChange = value => {
        let formatValue = value;
        if (pasteRef.current) {
            if (!value) {
                formatValue = beautifyJSONValue(formatValue);
            }
            pasteRef.current = ``;
        }
        setValue(formatValue);
    };

    return (
        <Portal container={document.getElementById('scrollOverlay')}>
            <div
                ref={rootRef}
                className={
                    'absolute -top-[115px] bottom-0 left-0 right-0 m-auto w-[930px] rounded-md shadow h-full max-w-[calc(100% - 40px)] max-h-[calc(100% - 50px)] bg-white pointer-events-auto md:w-full md:max-w-[calc(100vw - 100px)] sm:calc(100vw - 40px)'
                }
            >
                <ViewCellInfo
                    dimension={dimension}
                    columnName={column?.name}
                    rowName={rowId}
                    rowIndex={rowIndex}
                    overrideStyle={{
                        left: 0,
                        top: 10
                    }}
                />

                <div className="flex flex-col h-full w-full">
                    <div className="flex-1 px-0 py-3.5">
                        <JsonEditor
                            onEscape={handleEscape}
                            onSaveAndQuit={handleSaveAndQuit}
                            onPaste={handlePaste}
                            tabSize={2}
                            value={value}
                            onChange={handleChange}
                            placeholder={'Input your value'}
                            focus={true}
                            mode={'yaml'}
                        />
                    </div>
                    <div className="py-1 px-6 bg-grey-ghost rounded-bl-md rounded-br-md flex flex-row gap-2 items-center justify-end">
                        <div>
                            <p className={'body1 inline text-xs'}>Return</p>{' '}
                            <p className={'body2 inline text-xs'}>to insert a line-break</p>
                        </div>
                        <div>
                            <p className={'body1 inline text-xs'}>Shift + Return</p>{' '}
                            <p className={'body2 inline text-xs'}>to finish cell edit</p>
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
}

export default React.memo(YamlTextEdit);
