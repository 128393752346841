import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AvatarBase from 'components/avatar/Base';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from 'components/menus/Popper';
import MemberRolePopup from './MemberItemPopup';
import * as roleConst from 'auth/roleConst';
import CardInfo from 'components/cardInfo/Base';
import { getAvatarUrl } from 'utils/images';
import { generateUserBadge } from 'utils/user';
import { useRole } from 'hooks/auth/role';
import ProfileCard from './ProfileCard';
import { useWorkspaceRole } from 'hooks/auth/role';

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            background: theme.colors.ghostwhite,
            cursor: 'pointer'
        }
    },
    dropdownIcon: {
        display: 'flex',
        alignItems: 'center'
    },
    changeRole: {
        cursor: 'pointer'
    },
    profile: {
        '& .popper-content': {
            paddingBottom: 0,
            paddingTop: 0
        }
    }
}));

function MemberItem({ member, isScrolling, onRoleChange }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const workspaceRole = useWorkspaceRole();
    const roles = useRole();
    const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);

    const handleClick = event => {
        stopPropagation(event);
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleOpenUserDetail = event => {
        setAnchorProfileEl(event.currentTarget);
    };

    const handleCloseUserDetail = () => {
        setAnchorProfileEl(null);
    };

    const stopPropagation = e => {
        e.stopPropagation();
        e.preventDefault();
    };

    React.useEffect(() => {
        if (isScrolling) {
            handleClickAway();
        }
    }, [isScrolling]);

    const isHavePermissionManageWorkspace = React.useMemo(() => {
        return (
            roles?.[roleConst.COMPANY_AUTHORITIES.MANAGE_WORKSPACE] === roleConst.FULL ||
            workspaceRole === roleConst.CREATOR
        );
    }, [roles, workspaceRole]);

    const isShowExtraOption = React.useMemo(() => {
        return isHavePermissionManageWorkspace;
    }, [isHavePermissionManageWorkspace]);

    return (
        <Grid item onClick={handleOpenUserDetail} className={classes.root}>
            <CardInfo
                avatar={
                    <AvatarBase
                        src={getAvatarUrl(member?.user?.imageUrl)}
                        size="small"
                        badgeContent={generateUserBadge(member?.user)}
                    >
                        {member?.user?.fullName?.slice(0, 1)}
                    </AvatarBase>
                }
                title={member?.user?.fullName}
                action={
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        wrap="nowrap"
                        style={{
                            cursor: isShowExtraOption ? 'pointer' : 'default'
                        }}
                        onClick={isShowExtraOption ? handleClick : () => {}}
                    >
                        <Grid item>
                            <p className="body2">{member?.role?.name}</p>
                        </Grid>
                        {isShowExtraOption && (
                            <Grid item className={classes.dropdownIcon}>
                                <ArrowDownSVG />
                            </Grid>
                        )}
                    </Grid>
                }
            />
            {anchorEl && (
                <PopperMenu anchorEl={anchorEl} handleClickAway={handleClickAway} placement={'bottom-end'}>
                    <MemberRolePopup handleClickAway={handleClickAway} member={member} onRoleChange={onRoleChange} />
                </PopperMenu>
            )}

            {anchorProfileEl && (
                <PopperMenu
                    className={classes.profile}
                    anchorEl={anchorProfileEl}
                    handleClickAway={handleCloseUserDetail}
                    placement={'left'}
                >
                    <ProfileCard handleClickAway={handleCloseUserDetail} member={member} />
                </PopperMenu>
            )}
        </Grid>
    );
}

export default React.memo(MemberItem);
