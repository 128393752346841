import React from 'react';
import { Button as ButtonBase, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddColumnRightSVG from 'assets/images/svg/AddColumnRightSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import Tooltip from 'components/tooltip/Base';
import { DISABLED_OPACITY } from 'const/style';
import { useIsCreatingColumn } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as gridActions from '../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    spb2: {
        marginBottom: theme.spacing(1)
    },
    menuItem: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    },
    addButton: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        minWidth: 32,
        minHeight: 32,
        maxHeight: 32,
        padding: `6px 7px`
    }
}));

const ManageCreateColumn = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { viewId, dbId, branchId } = useParams();
    const isCreatingColumn = useIsCreatingColumn();

    const handleCreateColumn = React.useCallback(() => {
        if (!branchId || !viewId || viewId === 'undefined' || !dbId) {
            return;
        }

        dispatch(gridActions.toggleFakeColumn());
    }, [dispatch, branchId, dbId, viewId]);

    return (
        <AccessControl view={roleConst.EXTRA_AUTHORITIES.SHOW_COLUMN_PERMISSION}>
            {({ isReadOnly }) => (
                <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.MANAGE_GRID_COLUMN}>
                    {({ isReadOnly }) => (
                        <Tooltip
                            title={
                                isReadOnly ? (
                                    <>
                                        <p className="text-light">
                                            You do not have the appropriate permission rights to select.
                                        </p>
                                        <p className="text-light">Please message your admin.</p>
                                    </>
                                ) : (
                                    t('global_create_column')
                                )
                            }
                        >
                            <Grid item className={classes.menuItem}>
                                <ButtonBase
                                    id="g-h-menu-item-columns-add"
                                    className={`${classes.addButton} ${classes.button} ${
                                        isReadOnly || isCreatingColumn ? classes.disabled : ''
                                    }`}
                                    onClick={handleCreateColumn}
                                    tr-dt="Create Column"
                                >
                                    <AddColumnRightSVG height={18} width={18} color={theme.colors.darkness} />
                                </ButtonBase>
                            </Grid>
                        </Tooltip>
                    )}
                </AccessControl>
            )}
        </AccessControl>
    );
};

export default React.memo(ManageCreateColumn);
