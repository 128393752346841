import { DATA_QUERY_OPTIONS, MAX_SELECTION_RECORDS, RANGE_TYPES } from 'const/gridUI';
import * as dataActions from './data';
import { enqueueSnackbar } from 'notifier/actions';
import { getDisableColumnIdsByType, getDisabledColumnIds, getReferenceDisabledColumns } from 'utils/gridUI/column';
import { removeArrayInArray } from 'utils/object';
import { SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG } from 'const';
import * as gridUIActions from 'gridUI/actions';

export function deleteKeyEvent({ onOpenConfirm }) {
    return async function(dispatch, getState) {
        const { gridUI, auth, app } = getState();
        const { isShareViewLink } = app;

        if (isShareViewLink) return;

        const {
            columnIds: rangeColumnIds,
            recordIds,
            isOverRecordLimit,
            totalSelectedRecords
        } = await dataActions.getRangeData({
            auth,
            gridUI,
            dataOptions: [DATA_QUERY_OPTIONS.DATA],
            type: RANGE_TYPES.INDEX
        });

        if (isOverRecordLimit) {
            dispatch(
                enqueueSnackbar({
                    type: 'info',
                    message: `${totalSelectedRecords} records selected. But maximum is ${MAX_SELECTION_RECORDS}`
                })
            );
            return;
        }

        const { disabledColumns, processingColumns, disabledSourceColumns, viewColumns, metaData } = gridUI;

        const disabledColumnIdsByType = getDisableColumnIdsByType({ viewColumns, metaData });
        const referenceDisabledColumns = getReferenceDisabledColumns({ auth, gridUI });
        const disabledCols = getDisabledColumnIds({
            disabledColumns,
            viewColumns,
            processingColumns,
            disabledSourceColumns,
            disabledColumnIdsByType,
            referenceDisabledColumns
        });

        const affectedColumnIds = removeArrayInArray(rangeColumnIds, [
            ...disabledCols,
            ...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG
        ]);

        if (!affectedColumnIds?.length && !recordIds?.length) return;

        if (affectedColumnIds?.length && !recordIds?.length) {
            onOpenConfirm && onOpenConfirm();
        }

        if (recordIds?.length) {
            dispatch(gridUIActions.deleteCells());
        }
    };
}
