import React from 'react';

function PreviewCellSVG({ color = '#4A90E2', ...other }) {
    return (
        <svg {...other} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="10" height="10" rx="2" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.13302 4.14537L5.85265 4.86445C6.03582 5.04764 6.10722 5.0427 6.28544 4.86445L7.36663 3.78409L8.2306 4.64902V1.76923H5.34845L6.2142 2.63254L5.13302 3.7129C4.95796 3.88783 4.95293 3.96566 5.13302 4.14537ZM4.86668 5.85462L4.14704 5.13554C3.96387 4.95235 3.89246 4.95729 3.71424 5.13554L2.63303 6.2159L1.76904 5.35097V8.23076H4.65109L3.78548 7.36745L4.86668 6.28709C5.04175 6.11216 5.04661 6.03433 4.86668 5.85462Z"
                fill="white"
            />
        </svg>
    );
}

export default React.memo(PreviewCellSVG);
