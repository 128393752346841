import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CensorVersionControlSVG from 'assets/images/svg/censors/VersionControlSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 285
    },
    spacing: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    wf80: {
        width: '80%',
        margin: 'auto',
        textAlign: 'center'
    }
}));

function CommingSoon() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container alignItems="center" justify="center" direction="column" className={classes.root}>
            <Grid item>
                <CensorVersionControlSVG />
            </Grid>
            <Grid item className={classes.spacing}>
                <h4 className="prose prose-lg font-medium">{t('branch_comming_soon_1')}</h4>
            </Grid>
            <Grid item className={classes.wf80}>
                <p className="caption">{t('branch_comming_soon_2')}</p>
            </Grid>
        </Grid>
    );
}

export default React.memo(CommingSoon);
