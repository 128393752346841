import React from 'react';

function TMSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="210" height="80" viewBox="0 0 210 80">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h210v80H0z" />
                <g fillRule="nonzero">
                    <path
                        fill="#00C887"
                        d="M118.091 28.167a4.849 4.849 0 0 1 4.405 2.8l.164.4 10.27 28.781a1.225 1.225 0 0 1-.914 1.605l-.25.025h-2.508c-.454 0-.865-.246-1.08-.63l-.09-.203-3.144-9.259h-13.706l-3.144 9.26a1.23 1.23 0 0 1-.946.812l-.223.02h-2.509c-.14 0-.28-.024-.411-.07a1.222 1.222 0 0 1-.811-1.32l.059-.24 10.27-28.78a4.843 4.843 0 0 1 4.568-3.201zm0 5.045l-5.14 13.429 10.28.081-5.14-13.51z"
                    />
                    <path
                        fill="#47B6FE"
                        d="M91.968 19h2.99c.596 0 1.094.419 1.209.976l.025.246v5.451h11.414c.596 0 1.093.42 1.208.976l.025.247v2.6c0 .592-.422 1.084-.985 1.198l-.248.025h-11.5c1.159 4.305 3.849 8.461 8.07 12.469.297.283.429.69.362 1.083l-.064.234-.914 2.382a1.222 1.222 0 0 1-.314.461c-.5.46-1.28.43-1.743-.065-3.67-3.92-6.046-7.19-7.13-9.81-1.85 4.707-6.259 9.455-13.225 14.244a1.24 1.24 0 0 1-1.757-.37l-.108-.222-.948-2.631a1.216 1.216 0 0 1 .501-1.444c7.373-4.629 11.339-9.476 11.898-14.54l.114-.576.146-1.133h-11.76a1.231 1.231 0 0 1-1.209-.976L78 29.578v-2.601c0-.59.423-1.083.985-1.197l.249-.025h11.5v-5.533c0-.59.423-1.083.985-1.197l.249-.025h2.99-2.99z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(TMSVG);
