import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SettingContentLayout from 'permission/common/Layout';
import { Grid, Tab, Tabs } from '@material-ui/core';
import Connections from './connections';
import Sources from './sources';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        height: '100%'
    },
    fullHeight: {
        height: 'calc(100% - 116px)'
        // overflowY: 'auto'
    }
}));

const useTabStyles = makeStyles(theme => ({
    root: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.colors.lightGreyBlue
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const TABS = {
    CONNECTIONS: 'CONNECTIONS',
    SOURCE: 'SOURCE'
};

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Connectors() {
    const classes = useStyles();
    const theme = useTheme();
    const tabClasses = useTabStyles();
    const query = useQuery();
    const { t } = useTranslation();

    const tab = React.useMemo(() => {
        return query?.get('tab');
    }, [query]);

    const isSource = React.useMemo(() => {
        return tab === 'source';
    }, [tab]);

    const [tabValue, setTabValue] = React.useState(isSource ? TABS.SOURCE : TABS.CONNECTIONS);

    const handleChangeTab = React.useCallback((e, newValue) => {
        setTabValue(newValue);
    }, []);

    return (
        <SettingContentLayout
            name={
                <Grid container direction="column" spacing={3}>
                    <Grid item>{t('gridly_connector')}</Grid>
                </Grid>
            }
        >
            <Grid container className={classes.root} direction="column">
                <Grid item style={{ marginBottom: theme.spacing(5) }}>
                    <Tabs className={classes.tabs} value={tabValue} onChange={handleChangeTab}>
                        <Tab classes={tabClasses} value={TABS.CONNECTIONS} label={t('connections')} />
                        <Tab classes={tabClasses} value={TABS.SOURCE} label={t('sources')} />
                    </Tabs>
                </Grid>
                <Grid item className={classes.fullHeight}>
                    {tabValue === TABS.CONNECTIONS ? <Connections /> : <Sources />}
                </Grid>
            </Grid>
        </SettingContentLayout>
    );
}

export default React.memo(Connectors);
