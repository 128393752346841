import React from 'react';

function SecuritySVG({ color = '#78778B', ...other }) {
    return (
        <svg width="107" height="120" viewBox="0 0 107 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M106.528 18.0968V56.6296C106.528 66.1666 102.156 77.2366 94.2224 87.8003C84.976 100.108 71.3559 110.972 54.8342 119.217L53.2638 120L51.6934 119.217C35.1717 110.972 21.5516 100.108 12.3052 87.8003C4.37126 77.2366 0 66.1666 0 56.6296V18.0968L25.9135 0H80.6141L106.528 18.0968Z"
                fill="#CCCEE3"
            />
            <path
                d="M76.1884 14.063H30.3381L14.0625 25.4283V56.6295C14.0625 67.3526 24.6121 88.3065 53.2633 104.158C81.9144 88.3065 92.464 67.3526 92.464 56.6295V25.4283L76.1884 14.063Z"
                fill="#EAEAF3"
            />
            <path
                d="M77.2948 10.5474H29.232L10.5469 23.5955V56.6297C10.5469 63.4877 14.0204 72.2021 20.0746 80.5368C27.446 90.6857 38.3331 99.9181 51.5617 107.236L53.2634 108.178L54.965 107.236C68.1936 99.9181 79.0808 90.6857 86.4522 80.5368C92.5063 72.2021 95.9799 63.4877 95.9799 56.6297V23.5955L77.2948 10.5474ZM88.9484 56.6297C88.9484 65.9839 79.4066 85.0839 53.2634 100.122C27.1202 85.0839 17.5784 65.9839 17.5784 56.6297V27.2613L31.4446 17.5789H75.0822L88.9484 27.2613V56.6297Z"
                fill="white"
            />
            <path
                d="M75.7057 40.8386L53.261 63.9067L53.2142 63.9559L47.5069 69.8202L30.8164 54.2102L35.6189 49.0749L47.2725 59.9761L53.2142 53.8727L53.261 53.8235L70.6664 35.9353L75.7057 40.8386Z"
                fill="#BFC1D9"
            />
        </svg>
    );
}

export default React.memo(SecuritySVG);
