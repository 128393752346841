import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ButtonBase from 'components/button/Base';
import { useSelectedBranchDetail } from 'hooks/versionControl';
import Dialog from 'components/dialog/Dialog';
import MergingPreview from './diffCheck';
import SearchSVG from 'assets/images/svg/SearchSVG';
import { useTranslation } from 'react-i18next';
import BranchSVG from 'assets/images/svg/BranchSVG';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useBranchesOptions } from 'hooks/versionControl/branch';
import { useGridDetail } from 'hooks/grid';

const useStyles = makeStyles(theme => ({
    root: {},
    expand: {
        flex: 1,
        overflow: 'hidden'
    },
    merged: {},

    spacing: {
        marginBottom: theme.spacing(3),
        width: '100%'
    },
    checkbox: {
        padding: 0
    },
    fullWidth: {
        width: '100%'
    },
    lightLavender: {
        color: theme.colors.lightLavender
    },
    mb5: {
        marginBottom: 5
    },
    flex: {
        display: 'flex'
    },
    pointer: {
        cursor: 'pointer'
    },
    popperClassName: {
        width: `500px !important`
    },
    customButton: {
        width: '100%',
        fontWeight: 500,
        color: `${theme.colors.lightLavender} !important`,
        border: `none !important`,
        background: `${theme.colors.ghostwhite} !important`
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    branchWrapper: {
        background: theme.colors.ghostwhite,
        borderRadius: 4,
        padding: theme.spacing(2),
        overflow: 'hidden'
    },
    arrowIcon: {
        position: 'relative',
        top: 14
    },
    textHighLight: {
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    link: {
        color: theme.colors.dodgerBlue,
        fontWeight: 500,
        textDecoration: 'none'
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    overflowHidden: {
        overflow: 'hidden'
    }
}));

function Merge({ handleBack, handleClose, gridId, branchId, dbId }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const branchDetail = useSelectedBranchDetail({ masterGridId: gridId, selectedBranchIdOutSide: branchId });

    const [isOpenGridPreview, setIsOpenGridPreview] = React.useState(false);
    const handleClosePreview = React.useCallback(() => setIsOpenGridPreview(false), []);
    const handleOpenPreview = React.useCallback(() => setIsOpenGridPreview(true), []);
    const branchOptions = useBranchesOptions({ dbId, gridId }) || [];
    const grid = useGridDetail({ dbId, gridId });

    const filteredBranchOptions = React.useMemo(() => {
        return branchOptions?.filter(branch => branch?.id !== branchId);
    }, [branchOptions, branchId]);

    const parentGridId = React.useMemo(() => {
        return branchDetail?.parentGridId;
    }, [branchDetail]);

    const firstOption = React.useMemo(() => {
        return filteredBranchOptions?.[0];
    }, [filteredBranchOptions]);

    const defaultMergeToBranch = React.useMemo(() => {
        return filteredBranchOptions?.find(branch => branch?.id === parentGridId) || firstOption;
    }, [filteredBranchOptions, parentGridId, firstOption]);

    const [mergeToBranch, setMergeToBranch] = React.useState(defaultMergeToBranch);

    const mergeToBranchCombined = React.useMemo(() => {
        if (!mergeToBranch) return defaultMergeToBranch;
        const isNotFound = !filteredBranchOptions?.find(option => option?.value === mergeToBranch?.value);
        if (isNotFound) return defaultMergeToBranch;
        return mergeToBranch;
    }, [mergeToBranch, filteredBranchOptions, defaultMergeToBranch]);

    const handleBrachChange = React.useCallback(option => {
        setMergeToBranch(option);
    }, []);

    return (
        <Grid container spacing={3} className={classes.root} direction="column">
            <Grid item className={classes.fullWidth}>
                <Grid container alignItems={'center'} direction="row" wrap="nowrap" spacing={2}>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <p className="caption"> {t('branch_merge_merge_from')}</p>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid
                                    container
                                    className={classes.branchWrapper}
                                    alignItems="center"
                                    direction="row"
                                    spacing={2}
                                    wrap="nowrap"
                                >
                                    <Grid item className={classes.flex}>
                                        <BranchSVG color={theme.palette.primary.main} />
                                    </Grid>
                                    <Grid item className={classes.overflowHidden}>
                                        <p className="body1 text-brand-main line-clamp-1">
                                            {branchId === gridId ? t('master_branch') : branchDetail?.name}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.arrowIcon}>
                        <ArrowRightSVG />
                    </Grid>
                    <Grid item className={classes.expand}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <p className="caption">{t('branch_merge_merge_to')}</p>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <LDBasePortal
                                    ddPlaceholder={t(`global_label_find_an_option`)}
                                    menuPlaceholder={t(`global_label_find_an_option`)}
                                    options={filteredBranchOptions}
                                    onChange={handleBrachChange}
                                    defaultValue={mergeToBranchCombined}
                                    isMulti={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <ButtonBase onClick={handleOpenPreview} variant="contained" fullWidth>
                    <SearchSVG className={classes.icon} color={theme.colors.white} />
                    {t('compare_changes')}
                </ButtonBase>
            </Grid>

            <Grid item container direction="column" spacing={3}>
                <Grid item>
                    <h4 className="prose prose-lg font-medium">How to merge branch in Gridly?</h4>
                </Grid>

                <Grid item>
                    <p className="body1 inline">Step 1:</p>{' '}
                    <p className="body2 inline">Preview changes in the current branch</p>
                </Grid>

                <Grid item>
                    <p className="body1 inline">Step 2:</p> <p className="body2 inline">Select merge options</p>
                </Grid>
                <Grid item>
                    <p className="body1 inline">Step 3:</p>{' '}
                    <p className="body2 inline">Confirm your action once the popup appears</p>
                </Grid>
                <Grid item>
                    <p className="body1 inline">Step 4:</p>{' '}
                    <p className="body2 inline">Sit back and have a coffee while the merge is processing</p>
                </Grid>
            </Grid>

            <Dialog
                maxWidth={false}
                classes={{
                    paper: 'w-full !max-w-full !max-h-full !m-0'
                }}
                open={isOpenGridPreview && mergeToBranchCombined}
                onClose={handleClosePreview}
            >
                <MergingPreview
                    grid={grid}
                    branch={branchDetail}
                    onClose={handleClosePreview}
                    mergeToBranch={mergeToBranchCombined}
                    dbId={dbId}
                    gridId={gridId}
                />
            </Dialog>
        </Grid>
    );
}

export default React.memo(Merge);
