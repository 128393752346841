import React, { useCallback, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { getStatusCtrlOrShiftKey } from 'utils/keyboard';
import { useDispatch } from 'react-redux';
import * as gridUIActions from 'gridUI/actions';
import PopperMenu from 'components/menus/Popper';
import CellSingleRowPopup from './CellSingleRowPopup';
import CellMultipleRowsPopup from './CellMultipleRowsPopup';
import { changeContext } from 'app/actions';
import hexToRgba from 'hex-to-rgba';
import ExpandRecordSVG from 'assets/images/svg/ExpandRecordSVG';
import { useIsRowInRange, useIsRowSelected, useTotalSelectedRowsByRange } from 'hooks/gridUI';
import ThreeStateCheckbox from 'components/checkbox/ThreeStateCheckBox';
import classNames from 'classnames';
import { setSearchRange } from 'gridUI/actions';

const HOLD_TIME = 200;

function IndexCell({
    rowId,
    rowIndex,
    setRowHandlerStartPosition,
    isDragging,
    recordHistoryId,
    accessRecordHistory,
    accessManageGridRecord,
    contextMenuId,
    isImporting,
    isPathTagOn,
    accessEditTranslation,
    positionTop,
    positionBottom,
    previousRowId,
    nextRowId,
    accessEditRecords,
    isShareViewLink,
    t,
    isDisabledCreateRecord
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSingle, setIsSingle] = useState(true);
    const timerDragRef = React.useRef();
    const contextRef = React.useRef(null);

    const isRowSelected = useIsRowSelected({ rowIndex });
    const isRowInRange = useIsRowInRange({ rowIndex });

    const totalSelectedRows = useTotalSelectedRowsByRange();

    const CONTEXT_MENU_ID = React.useMemo(() => {
        return `RECORDS_CONTEXT_MENU_ID_${rowId}`;
    }, [rowId]);

    React.useEffect(() => {
        if (totalSelectedRows > 0 && contextMenuId !== CONTEXT_MENU_ID) {
            setAnchorEl(null);
        }
    }, [contextMenuId, CONTEXT_MENU_ID, totalSelectedRows]);

    const stopPropagation = React.useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const handleIndexClick = useCallback(
        e => {
            stopPropagation(e);
            const { isShift, isCtrl } = getStatusCtrlOrShiftKey(e);
            if (!isCtrl && !isShift) {
                dispatch(gridUIActions.resetCellStatus());
            }
            dispatch(
                gridUIActions.rowSelection({
                    isCtrl,
                    isShift,
                    rowId,
                    rowIndex
                })
            );
            if (recordHistoryId && recordHistoryId !== rowId && !isShift) {
                dispatch(gridUIActions.openRecordHistory({ rowId, rowIndex }));
            }
        },
        [dispatch, rowId, rowIndex, recordHistoryId, stopPropagation]
    );

    const handleOnContextClick = React.useCallback(
        event => {
            stopPropagation(event);
            const { isShift, isCtrl } = getStatusCtrlOrShiftKey(event);
            dispatch(changeContext({ contextMenuId: CONTEXT_MENU_ID }));
            if (totalSelectedRows > 1 && isRowSelected) {
                setIsSingle(false);
            } else {
                setIsSingle(true);
                dispatch(gridUIActions.resetCellStatus());
                dispatch(setSearchRange(null));
                dispatch(
                    gridUIActions.rowSelection({
                        isCtrl,
                        isShift,
                        rowId,
                        rowIndex
                    })
                );
                if (recordHistoryId && recordHistoryId !== rowId && !isShift) {
                    dispatch(gridUIActions.openRecordHistory({ rowId, rowIndex }));
                }
            }

            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [
            CONTEXT_MENU_ID,
            dispatch,
            anchorEl,
            recordHistoryId,
            rowId,
            rowIndex,
            isRowSelected,
            stopPropagation,
            totalSelectedRows
        ]
    );

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleMouseUpOrLeave = () => {
        if (timerDragRef.current) {
            clearTimeout(timerDragRef.current);
        }
    };

    const handleMouseDown = React.useCallback(
        e => {
            stopPropagation(e);
            dispatch(setSearchRange(null));
            if (totalSelectedRows === 0) {
                const { isShift, isCtrl } = getStatusCtrlOrShiftKey(e);
                dispatch(
                    gridUIActions.rowSelection({
                        isCtrl,
                        isShift,
                        rowId,
                        rowIndex
                    })
                );
            }
            timerDragRef.current = setTimeout(() => {
                dispatch(gridUIActions.turnOnDraggingRows());
            }, HOLD_TIME);
        },
        [dispatch, stopPropagation, rowIndex, rowId, totalSelectedRows]
    );

    const handleOpenRecordPanel = React.useCallback(
        e => {
            stopPropagation(e);
            dispatch(gridUIActions.turnOnRecordPanel());
            dispatch(
                gridUIActions.rowSelection({
                    isCtrl: false,
                    isShift: false,
                    rowId,
                    rowIndex
                })
            );
            if (recordHistoryId && recordHistoryId !== rowId) {
                dispatch(gridUIActions.openRecordHistory({ rowId, rowIndex }));
            }
        },
        [dispatch, recordHistoryId, rowIndex, rowId, stopPropagation]
    );

    const handleCheckbox = useCallback(
        (e, nextState) => {
            stopPropagation(e);
            const { isCtrl, isShift } = getStatusCtrlOrShiftKey(e);
            dispatch(
                gridUIActions.rowSelection({
                    isCtrl: isShift ? isCtrl : true,
                    isShift,
                    rowId,
                    rowIndex
                })
            );
        },
        [dispatch, rowId, rowIndex, stopPropagation]
    );

    const handleRepositionContext = React.useCallback(() => {
        if (contextRef.current) {
            contextRef.current.update();
        }
    }, []);

    return (
        <>
            <Grid
                onClick={handleIndexClick}
                container
                onMouseDown={handleMouseDown}
                justifyContent="center"
                alignItems="center"
                className={classNames(`relative cursor-pointer h-full group RECORD_${rowId}`, {
                    '!bg-[#DBDCE1]': isRowInRange,
                    disabled: isImporting
                })}
                style={{
                    background: isDragging && isRowSelected && `${hexToRgba(theme.colors.highlight, 0.3)} !important`
                }}
                onMouseUp={handleMouseUpOrLeave}
                pst={positionTop}
                psb={positionBottom}
                prid={previousRowId}
                nrid={nextRowId}
                rindex={rowIndex}
                id={`INDEX_RECORD_${rowId}`}
            >
                <Grid
                    style={{
                        height: `calc(100% - 10px)`,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1
                    }}
                    item
                    onContextMenu={handleOnContextClick}
                    pst={positionTop}
                    psb={positionBottom}
                    prid={previousRowId}
                    nrid={nextRowId}
                    rindex={rowIndex}
                    className={`RECORD_${rowId}`}
                >
                    {!isRowSelected || !accessManageGridRecord ? (
                        <p
                            pst={positionTop}
                            psb={positionBottom}
                            prid={previousRowId}
                            nrid={nextRowId}
                            rindex={rowIndex}
                            className={`body2 min-w-[40px] min-h-[40px] flex justify-center items-center text RECORD_${rowId}`}
                            onClick={handleCheckbox}
                            onMouseDown={e => stopPropagation(e)}
                        >
                            {rowIndex + 1}
                        </p>
                    ) : (
                        <ThreeStateCheckbox state={isRowSelected ? 1 : 2} onChange={handleCheckbox} />
                    )}
                </Grid>
                <Grid
                    item
                    className={`opacity-0 p-1 flex absolute right-0 top-[50%]`}
                    style={{
                        transform: 'translate(0, -50%)'
                    }}
                    onClick={handleOpenRecordPanel}
                >
                    <ExpandRecordSVG width={20} height={20} />
                </Grid>
                {isSingle && anchorEl && !isShareViewLink && (
                    <PopperMenu
                        anchorEl={anchorEl}
                        placement={'right-start'}
                        id={`rows_popup_context`}
                        popperRef={contextRef}
                    >
                        <CellSingleRowPopup
                            handleClickAway={handleClickAway}
                            rowIndex={rowIndex}
                            rowId={rowId}
                            closePopup={handleClickAway}
                            accessManageGridRecord={accessManageGridRecord}
                            accessRecordHistory={accessRecordHistory}
                            accessEditTranslation={accessEditTranslation}
                            accessEditRecords={accessEditRecords}
                            isDisabledCreateRecord={isDisabledCreateRecord}
                            t={t}
                            onRePosContext={handleRepositionContext}
                        />
                    </PopperMenu>
                )}
                {!isSingle && !isShareViewLink && (
                    <CellMultipleRowsPopup
                        anchorEl={anchorEl}
                        handleClickAway={handleClickAway}
                        closePopup={handleClickAway}
                        accessManageGridRecord={accessManageGridRecord}
                        accessEditTranslation={accessEditTranslation}
                        accessEditRecords={accessEditRecords}
                        totalSelectedRows={totalSelectedRows}
                        t={t}
                        onRePosContext={handleRepositionContext}
                    />
                )}
            </Grid>
        </>
    );
}

export default React.memo(IndexCell);
