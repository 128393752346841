import React from 'react';
import { useUndoRedoQueue } from 'hooks/gridUI';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { USER_ACTION_TYPES } from 'const/gridUI';
import { undoApi, redoApi } from 'services/view';
import { enqueueSnackbar } from 'notifier/actions';
import * as statusActions from 'gridUI/actions/status';

function UndoProvider() {
    const [handledStacks, setHandledStacks] = React.useState([]);
    const jobList = useUndoRedoQueue();
    const dispatch = useDispatch();

    const addHandledStack = React.useCallback(
        key => {
            setHandledStacks([...handledStacks, key]);
        },
        [handledStacks]
    );

    React.useEffect(() => {
        if (!isEmpty(jobList)) {
            const loop = async () => {
                for await (const job of jobList) {
                    const { type, id } = job;

                    if (handledStacks.includes(id)) {
                        continue;
                    }

                    addHandledStack(id);
                    try {
                        dispatch(statusActions.registerDoingAction({ actionId: id }));
                        if (type === USER_ACTION_TYPES.REDO) {
                            const response = await redoApi();

                            if (response?.status === 'noMoreAction') {
                                dispatch(
                                    enqueueSnackbar({
                                        message: 'No More Action',
                                        type: 'info'
                                    })
                                );
                            }
                        }

                        if (type === USER_ACTION_TYPES.UNDO) {
                            const response = await undoApi();

                            if (response?.status === 'noMoreAction') {
                                dispatch(
                                    enqueueSnackbar({
                                        message: 'No More Action',
                                        type: 'info'
                                    })
                                );
                            }
                        }
                        dispatch(statusActions.removeDoingAction({ actionId: id }));
                    } catch (error) {
                        dispatch(statusActions.removeDoingAction({ actionId: id }));
                    }
                }
            };
            loop();
        }
    }, [jobList, dispatch, handledStacks, addHandledStack]);
    return null;
}

export default React.memo(UndoProvider);
