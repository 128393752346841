export const FETCH_BRANCHES_OF_DATABASE = '[VERSION-CONTROL]FETCH_BRANCHES_OF_DATABASE';
export const FETCH_BRANCHES_OF_DATABASE_SUCCESS = '[VERSION-CONTROL]FETCH_BRANCHES_OF_DATABASE_SUCCESS';
export const FETCH_BRANCHES_OF_DATABASE_FAILED = '[VERSION-CONTROL]FETCH_BRANCHES_OF_DATABASE_FAILED';

export const FETCH_BRANCHES_OF_GRID = '[VERSION-CONTROL]FETCH_BRANCHES_OF_GRID';
export const FETCH_BRANCHES_OF_GRID_SUCCESS = '[VERSION-CONTROL]FETCH_BRANCHES_OF_GRID_SUCCESS';
export const FETCH_BRANCHES_OF_GRID_FAILED = '[VERSION-CONTROL]FETCH_BRANCHES_OF_GRID_FAILED';

export const CREATE_GRID_BRANCH = '[VERSION-CONTROL]CREATE_GRID_BRANCH';
export const CREATE_GRID_BRANCH_FAILED = '[VERSION-CONTROL]CREATE_GRID_BRANCH_FAILED';
export const CREATE_GRID_BRANCH_SUCCESS = '[VERSION-CONTROL]CREATE_GRID_BRANCH_SUCCESS';

export const DELETE_GRID_BRANCH = '[VERSION-CONTROL]DELETE_GRID_BRANCH';
export const DELETE_GRID_BRANCH_FAILED = '[VERSION-CONTROL]DELETE_GRID_BRANCH_FAILED';
export const DELETE_GRID_BRANCH_SUCCESS = '[VERSION-CONTROL]DELETE_GRID_BRANCH_SUCCESS';

export const UPDATE_GRID_BRANCH = '[VERSION-CONTROL]UPDATE_GRID_BRANCH';
export const UPDATE_GRID_BRANCH_FAILED = '[VERSION-CONTROL]UPDATE_GRID_BRANCH_FAILED';
export const UPDATE_GRID_BRANCH_SUCCESS = '[VERSION-CONTROL]UPDATE_GRID_BRANCH_SUCCESS';

export const SWITCH_BRANCH = '[VERSION-CONTROL]SWITCH_BRANCH';
export const INITITAL_BRANCH_TYPE = '[VERSION-CONTROL]INITITAL_BRANCH_TYPE';

export const FETCH_ALL_RECORD_ID = '[VERSION-CONTROL]FETCH_ALL_RECORD_ID';
export const FETCH_PREVIEW_SECTION = '[VERSION-CONTROL]FETCH_PREVIEW_SECTION';

export const MERGE_BRANCH = '[VERSION-CONTROL]MERGE_BRANCH';
export const MERGE_BRANCH_SUCCESS = '[VERSION-CONTROL]MERGE_BRANCH_SUCCESS';
export const MERGE_BRANCH_FAILED = '[VERSION-CONTROL]MERGE_BRANCH_FAILED';

export const INIT_MERGING_GRID_PREVIEW = '[VERSION-CONTROL]INIT_MERGING_GRID_PREVIEW';
export const INIT_MERGING_GRID_PREVIEW_FAILED = '[VERSION-CONTROL]INIT_MERGING_GRID_PREVIEW_FAILED';
export const INIT_MERGING_GRID_PREVIEW_SUCCESS = '[VERSION-CONTROL]INIT_MERGING_GRID_PREVIEW_SUCCESS';

export const FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW = '[VERSION-CONTROL]FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW';
export const FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW_SUCCESS =
    '[VERSION-CONTROL]FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW_SUCCESS';
export const FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW_FAILED =
    '[VERSION-CONTROL]FETCH_MORE_RECORDS_GRID_MERGING_PREVIEW_FAILED';

export const CHANGE_BRANCH_STATUS = `[VERSION_CONTROL]CHANGE_BRANCH_STATUS`;
