import * as types from '../types';
import { USER_ACTION_TYPES } from 'const/gridUI';
import { clearApi } from 'services/view';
import { enqueueSnackbar } from 'notifier/actions';

export function registerUndoRedo(type = USER_ACTION_TYPES.UNDO) {
    return {
        type: types.REGISTER_UNDO_REDO,
        payload: {
            type
        }
    };
}

export function toggleProcessingUndoRedo() {
    return {
        type: types.TOGGLE_PROCESSING_UNDO_REDO
    };
}

export function clearUndo() {
    return async function(dispatch, getState) {
        try {
            await clearApi();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
        }
    };
}
