import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import AvatarBase from 'components/avatar/Base';
import IconAdminUserSVG from 'assets/images/svg/IconAdminUserSVG';

function AdminGroupAvatar({ size = 48 }) {
    const theme = useTheme();
    return (
        <AvatarBase size={size} color={theme.colors.sun}>
            <IconAdminUserSVG />
        </AvatarBase>
    );
}

export default AdminGroupAvatar;
