import { CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import SettingContentLayout from 'permission/common/Layout';
import ButtonBase from 'components/button/Base';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    updateCompanyInfoApi,
    getCompanyInfoApi,
    getUserFactorApi,
    getCompanySamlConfigApi,
    updateCompanySamlUrlApi,
    disableCompanySamlApi
} from 'services/company';
import { useCompanyId } from 'hooks/auth';
import Divider from 'components/divider/Base';
import Dialog from 'components/dialog/Dialog';
import SAMLSetting from './SAMLSetting';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notifier/actions';
import { useCurrentSubscriptionIs } from 'hooks/payment';
import { PLAN_TYPES } from 'const';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import { useTranslation } from 'react-i18next';
import SecuritySVG from 'assets/images/svg/SecuritySVG';
import * as memberActions from 'permission/actions/member';
import ArrowRightSVG from 'assets/images/svg/ArrowRightSVG';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 84px - 60px)',
        overflow: 'auto'
    },
    wrapper: {
        width: '50%'
    },
    divider: {
        width: '50%',
        marginTop: 32,
        marginBottom: 32
    },
    emptySecurity: {
        maxWidth: 500,
        textAlign: 'center',
        margin: 'auto'
    }
}));

function Security() {
    const classes = useStyles();
    const history = useHistory();
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const companyId = useCompanyId();
    const { t } = useTranslation();
    const isFreePlan = useCurrentSubscriptionIs(PLAN_TYPES.FREE);
    const isProPlan = useCurrentSubscriptionIs(PLAN_TYPES.PROFESSIONAL);
    const [userFactor, setUserFactor] = React.useState({});
    const [companyInfo, setCompanyInfo] = React.useState({});
    const [isOpenSAMLSetting, setIsOpenSAMLSetting] = React.useState(false);
    const [isLoadingFactor, setIsLoadingFactor] = React.useState(false);
    const [samlConfig, setSamlConfig] = React.useState({});
    const [isLoadingSaml, setIsLoadingSaml] = React.useState(false);

    React.useEffect(() => {
        const fetchFactorStatus = async () => {
            try {
                setIsLoadingFactor(true);
                const factorStatus = await getUserFactorApi();
                const cInfo = await getCompanyInfoApi({ companyId });
                setUserFactor(factorStatus);
                setCompanyInfo(cInfo);
                setIsLoadingFactor(false);
            } catch (error) {
                dispatch(
                    enqueueSnackbar({
                        message: error.message,
                        type: 'error'
                    })
                );
                setIsLoadingFactor(false);
            }
        };
        fetchFactorStatus();
    }, [dispatch, companyId]);

    React.useEffect(() => {
        const fetchSamlConfig = async () => {
            try {
                setIsLoadingSaml(true);
                const samlConfig = await getCompanySamlConfigApi(companyId);
                setSamlConfig(samlConfig);
                setIsLoadingSaml(false);
            } catch (error) {
                dispatch(
                    enqueueSnackbar({
                        message: error.message,
                        type: 'error'
                    })
                );
                setIsLoadingSaml(false);
            }
        };
        fetchSamlConfig();
    }, [dispatch, companyId]);

    React.useEffect(() => {
        dispatch(
            memberActions.fetchMembers({
                successCallback: () => {
                    console.log('fetch members successfully');
                },
                errorCallback: () => {
                    console.log('failed  to fetch members');
                }
            })
        );
    }, [dispatch]);

    const handleSaveSamlUrl = async ({ url, enforceSso, excludedUserIds, includedDomains }) => {
        try {
            setIsLoadingSaml(true);
            const samlConfig = await updateCompanySamlUrlApi({
                companyId,
                url,
                enforceSso,
                excludedUserIds,
                includedDomains
            });
            setSamlConfig(samlConfig);
            setIsLoadingSaml(false);
            closeSAMLSetting();
        } catch (error) {
            setIsLoadingSaml(false);
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'error'
                })
            );
        }
    };

    const disableSaml = async () => {
        try {
            setIsLoadingSaml(true);
            const samlConfig = await disableCompanySamlApi(companyId);
            setSamlConfig(samlConfig);
            setIsLoadingSaml(false);
            closeSAMLSetting();
        } catch (error) {
            setIsLoadingSaml(false);
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'error'
                })
            );
        }
    };

    const goToCompanyFactorSetting = async () => {
        try {
            setIsLoadingFactor(true);
            const body = { setting: { factorRequired: true } };
            const cInfo = await updateCompanyInfoApi({ companyId, body });
            setCompanyInfo(cInfo);
            setIsLoadingFactor(false);
            if (!userFactor.factorEnabled) history.push(`/company-2fa`);
        } catch (error) {
            setIsLoadingFactor(false);
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'error'
                })
            );
        }
    };

    const disableCompanyFactor = async () => {
        try {
            setIsLoadingFactor(true);
            const body = { setting: { factorRequired: false } };
            const companyInfo = await updateCompanyInfoApi({ companyId, body });
            setCompanyInfo(companyInfo);
            setIsLoadingFactor(false);
        } catch (error) {
            setIsLoadingFactor(false);
            dispatch(
                enqueueSnackbar({
                    message: error.message,
                    type: 'error'
                })
            );
        }
    };

    const openSAMLSetting = () => setIsOpenSAMLSetting(true);
    const closeSAMLSetting = () => setIsOpenSAMLSetting(false);
    const handleUpgrade = () => {
        history.push(`/company-settings/billing/overview`);
    };

    const goToPasswordSettingPage = e => {
        e.preventDefault();
        history.push(`${path}/password`);
    };

    return (
        <SettingContentLayout contentClassName={'content-medium'} name={t('security')} height={'auto'}>
            <Grid item container direction="column" className={classes.root} wrap="nowrap">
                {isFreePlan && (
                    <div className="flex flex-col items-center justify-center max-w-[500px] m-auto">
                        <SecuritySVG />
                        <h3 className="mt-6">Security is not available</h3>
                        <p className="caption mt-3.5">
                            You are using "Free plan" but Security is only available on the Pro & Enterprise plan.
                            Please upgrade to experience this feature
                        </p>
                        <ButtonBase width={120} onClick={handleUpgrade} variant="contained">
                            <UpgradeSVG className={classes.upgradeIcon} />
                            {t('global_upgrade')}
                        </ButtonBase>
                    </div>
                )}
                {!isFreePlan && (
                    <Grid item container direction="column" className={classes.wrapper}>
                        <Grid item>
                            <Typography variant="h4">{t('setting_two_factor_auth')}</Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 32 }}>
                            <Typography variant="body1">{t('enforce_two_factor_auth')}</Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 18 }}>
                            <Typography variant="body2">{t('company_two_factor_explain')}</Typography>
                        </Grid>
                        <Grid item style={{ marginTop: 24, marginLeft: 'auto' }}>
                            {isLoadingFactor ? (
                                <ButtonBase variant="outlined" width={100} disabled>
                                    <CircularProgress size={24} />
                                </ButtonBase>
                            ) : companyInfo?.setting?.factorRequired ? (
                                <ButtonBase variant="outlined" width={100} onClick={disableCompanyFactor}>
                                    {t('stop')}
                                </ButtonBase>
                            ) : (
                                <ButtonBase variant="contained" width={100} onClick={goToCompanyFactorSetting}>
                                    {t('enforce')}
                                </ButtonBase>
                            )}
                        </Grid>
                    </Grid>
                )}
                {!isFreePlan && !isProPlan && (
                    <>
                        <Grid item className={classes.divider}>
                            <Divider />
                        </Grid>
                        <Grid item container direction="column" className={classes.wrapper}>
                            <Grid item>
                                <Typography variant="h4">{t('setting_single_sign_on')}</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 32 }}>
                                <Typography variant="body1">Enable SAML</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 18 }}>
                                <Typography variant="body2">{t('saml_explain')}</Typography>
                            </Grid>
                            <div className="mt-6 flex items-center gap-2 justify-end">
                                {samlConfig.samlEnabled && (
                                    <ButtonBase variant="outlined" width={100} onClick={disableSaml}>
                                        {t('global_disable')}
                                    </ButtonBase>
                                )}
                                <ButtonBase variant="contained" width={100} onClick={openSAMLSetting}>
                                    {samlConfig.samlEnabled ? t('edit') : t('global_enable')}
                                </ButtonBase>
                            </div>
                        </Grid>
                    </>
                )}
                {!isFreePlan && !isProPlan && (
                    <>
                        <Grid item className={classes.divider}>
                            <Divider />
                        </Grid>
                        <Grid item container direction="column" className={classes.wrapper}>
                            <Grid item>
                                <Typography variant="h4">{t('password_settings')}</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 32 }}>
                                <Typography variant="body1">{t('set_password_policies')}</Typography>
                            </Grid>
                            <Grid container item style={{ marginTop: 18 }} align-items="center" wrap="nowrap">
                                <Grid item>
                                    <Typography variant="body2">{t('password_complexity_setting_explain')}</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton href={`${path}/password`} onClick={goToPasswordSettingPage}>
                                        <ArrowRightSVG size="medium" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Dialog open={isOpenSAMLSetting} onClose={closeSAMLSetting}>
                <SAMLSetting
                    samlConfig={samlConfig}
                    onClose={closeSAMLSetting}
                    onSave={handleSaveSamlUrl}
                    isLoading={isLoadingSaml}
                />
            </Dialog>
        </SettingContentLayout>
    );
}

export default React.memo(Security);
