import {
    SPECIAL_SPLIT_KEY,
    DEPENDENCY_STATUS,
    EXTRA_QUICK_FILTER,
    EXTRA_PREVIEW_STATUS,
    SOURCE_STATUS,
    FILTER_MODES
} from 'const/gridUI';
import * as columnTypes from 'const/columnTypes';
import { generateDateRange } from 'utils/gridUI/formatData';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import produce from 'immer';
import { OPERATOR } from 'gridUI/conditions';
import { isLDEmpty } from 'utils/object';
import { getFloatNumber } from './cell';
import { getServerReferenceValueAndOperator } from 'utils/gridUI/reference';
import { isValidRegex } from 'utils/regex';

export function generateServerFilterValue({ type, values }) {
    switch (type) {
        case columnTypes.MULTIPLE_SELECTIONS: {
            if (!values) return [];
            return values.split(SPECIAL_SPLIT_KEY);
        }
        case columnTypes.BOOLEAN: {
            if (isBoolean(values)) {
                return [values];
            }
            return [];
        }
        case columnTypes.NUMBER: {
            const valueParsed = values ? Number(values) : values;
            if (isNumber(valueParsed)) {
                return [valueParsed];
            }
            return [];
        }
        default: {
            if (isLDEmpty(values)) return [];

            return typeof values === 'string' || typeof values === 'boolean' ? [values] : values;
        }
    }
}

export function convertServerFilterValue({ type, values, operator }) {
    if (
        [columnTypes.FORMULA, columnTypes.DATETIME, columnTypes.ALTERED_TIME, columnTypes.CREATED_TIME].includes(
            type
        ) &&
        [OPERATOR.between, OPERATOR.notBetween].includes(operator)
    ) {
        return values;
    }
    switch (type) {
        case columnTypes.MULTIPLE_SELECTIONS: {
            if (!values) return values;
            return values.join(SPECIAL_SPLIT_KEY);
        }
        default:
            return values && values[0];
    }
}

function formatMultiSelection(options) {
    if (options.length === 1) return options[0] && options[0].value;
    return options.map(option => option.value);
}

function formatRefSelection({ options, currentState }) {
    return getServerReferenceValueAndOperator({ options, referencedColumnType: currentState });
}

function getExtraFilter({ extraFilter, columnId }) {
    let normalFilter = {};
    let expandFilter = {};

    const _data = extraFilter?._data;
    const _dependencyStatus = extraFilter?._dependencyStatus;
    const _sourceStatus = extraFilter?._sourceStatus;
    const _tm = extraFilter?._tm;
    const _color = extraFilter?._color;
    const _previewStatus = extraFilter?._previewStatus;

    if (_previewStatus) {
        if (_previewStatus === EXTRA_PREVIEW_STATUS.CONFLICT) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._previewStatus`]: { '=': 'conflicted' }
            };
        }

        if (_previewStatus === EXTRA_PREVIEW_STATUS.NONE_CONFLICT) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._previewStatus`]: { '!=': 'conflicted' }
            };
        }
    }
    const _cell_metadata = extraFilter?._cell_metadata;

    if (_tm) {
        if (_tm === EXTRA_QUICK_FILTER.IS_TM) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._tm`]: true
            };
        }

        if (_tm === EXTRA_QUICK_FILTER.IS_NOT_TM) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._tm`]: false
            };
        }
    }

    if (_color) {
        expandFilter = {
            ...expandFilter,
            [`${columnId}._color`]: _color
        };
    }

    if (_cell_metadata) {
        expandFilter = {
            ...expandFilter,
            [`${columnId}._cell_metadata`]: { hasQaError: true }
        };
    }

    if (_sourceStatus) {
        if (_sourceStatus === SOURCE_STATUS.DO_NOT_TRANSLATE) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._sourceStatus`]: SOURCE_STATUS.DO_NOT_TRANSLATE
            };
        }

        if (_sourceStatus === SOURCE_STATUS.UNSET) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._sourceStatus`]: SOURCE_STATUS.UNSET
            };
        }
        if (_sourceStatus === SOURCE_STATUS.READY_FOR_TRANSLATION) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._sourceStatus`]: SOURCE_STATUS.READY_FOR_TRANSLATION
            };
        }
        if (_sourceStatus === SOURCE_STATUS.NOT_READY_FOR_TRANSLATION) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._sourceStatus`]: SOURCE_STATUS.NOT_READY_FOR_TRANSLATION
            };
        }
        if (_sourceStatus === SOURCE_STATUS.LOCKED) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._sourceStatus`]: SOURCE_STATUS.LOCKED
            };
        }
    }

    if (_dependencyStatus) {
        if (_dependencyStatus === EXTRA_QUICK_FILTER.IS_UNSET) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._dependencyStatus`]: DEPENDENCY_STATUS.UNSET
            };
        }

        if (_dependencyStatus === EXTRA_QUICK_FILTER.IS_OUT_OF_DATE) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._dependencyStatus`]: DEPENDENCY_STATUS.OUT_OF_DATE
            };
        }

        if (_dependencyStatus === EXTRA_QUICK_FILTER.IS_UP_TO_DATE) {
            expandFilter = {
                ...expandFilter,
                [`${columnId}._dependencyStatus`]: DEPENDENCY_STATUS.UP_TO_DATE
            };
        }
    }

    if (_data) {
        if (_data === EXTRA_QUICK_FILTER.IS_EMPTY) {
            normalFilter = {
                ...normalFilter,
                [columnId]: { isEmpty: 'something' }
            };
        }

        if (_data === EXTRA_QUICK_FILTER.IS_FILLED) {
            normalFilter = {
                ...normalFilter,
                [columnId]: { isNotEmpty: 'something' }
            };
        }
    }

    return { normalFilter, expandFilter };
}

export function formatQuickFilters(quickFilters) {
    let newQuickFilters = {};

    Object.keys(quickFilters).forEach(columnId => {
        let { type, value, extraFilter, currentState, operator, filterMode } = quickFilters[columnId];

        const isRegex = filterMode === FILTER_MODES.REGEX;
        const isCaseSensitive = filterMode === FILTER_MODES.CASE_SENSITIVE;

        const newOperator = isRegex ? OPERATOR.regexp : operator;

        if (extraFilter) {
            let { normalFilter, expandFilter } = getExtraFilter({ extraFilter, columnId });

            if (!isEmpty(expandFilter)) {
                newQuickFilters = {
                    ...newQuickFilters,
                    ...expandFilter
                };
            }

            if (!isEmpty(normalFilter)) {
                const normalFilterByColumnId = normalFilter?.[columnId];
                newQuickFilters = produce(newQuickFilters, draft => {
                    const oldFilterOfColumnId = draft?.[columnId] || {};
                    draft[columnId] = { ...oldFilterOfColumnId, ...normalFilterByColumnId };
                });
            }
        }

        let condition = {};

        switch (type) {
            case columnTypes.DATETIME: {
                if (!value) break;
                condition[operator] = generateDateRange(value);
                break;
            }

            case columnTypes.SINGLE_SELECTION: {
                if (!value) break;
                condition[operator] = value && value.value;
                break;
            }

            case columnTypes.REFERENCE: {
                if (!value || isEmpty(value)) break;

                const { value: valueConvert, operator: operatorConvert } = formatRefSelection({
                    options: value,
                    currentState
                });

                condition[operatorConvert] = valueConvert;
                break;
            }

            case columnTypes.MULTIPLE_SELECTIONS:
            case columnTypes.GROUP_TAGS: {
                if (!value || isEmpty(value)) break;
                condition[operator] = formatMultiSelection(value);
                break;
            }

            case columnTypes.PATH_TAG: {
                if (!value || isEmpty(value)) break;
                condition[operator] = value;
                break;
            }

            case columnTypes.BOOLEAN: {
                if (currentState === 0) break;
                condition[operator] = value;
                break;
            }

            case columnTypes.NUMBER: {
                if (!value) break;
                const numberFormat = getFloatNumber(value);
                if (numberFormat) {
                    condition[operator] = numberFormat;
                }
                break;
            }

            default: {
                if (!value) break;
                if (isRegex && !isValidRegex(value)) break;
                condition[newOperator] = value;
                break;
            }
        }

        if (!isEmpty(condition)) {
            newQuickFilters = produce(newQuickFilters, draft => {
                const oldFilterOfColumnId = draft?.[columnId] || {};

                if (isCaseSensitive) {
                    const operator = Object.keys(condition)?.[0];
                    const value = Object.values(condition)?.[0];
                    draft[columnId] = {
                        ...oldFilterOfColumnId,
                        [operator]: {
                            value,
                            caseSensitive: true
                        }
                    };
                } else {
                    draft[columnId] = { ...oldFilterOfColumnId, ...condition };
                }
            });
        }
    });

    return newQuickFilters;
}

export function generateDefaultConditionByColumnType({ column, validOperators, isChildDependency }) {
    if (!isChildDependency) {
        const validOperator = validOperators?.[0];
        return {
            label: validOperator?.label,
            value: validOperator?.value
        };
    }

    let equalOperator = validOperators?.find(operator => operator?.value === OPERATOR.equal);

    if (!equalOperator) {
        const validOperator = validOperators?.[0];
        return {
            label: validOperator?.label,
            value: validOperator?.value
        };
    }

    return equalOperator;
}
