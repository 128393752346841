import React from 'react';

function SendCommentSVG({ color = '#C9C9CE', width = 16, height = 14, ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g clipPath="url(#sendCommentSVG)">
                <path
                    d="M1.43448 1C1.1931 1 1 1.21724 1 1.48276C1 1.5069 1 1.55517 1.02414 1.57931L2.47241 7.01034L8.91724 7.97586L2.47241 8.98966L1.02414 14.4207C0.951724 14.6621 1.09655 14.9276 1.36207 14.9759C1.45862 15 1.57931 15 1.67586 14.9517L14.7586 8.41035C14.9759 8.28966 15.0724 8.02414 14.9517 7.78276C14.9034 7.68621 14.831 7.61379 14.7586 7.58966L1.67586 1.04828C1.60345 1.02414 1.53103 1 1.43448 1Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="sendCommentSVG">
                    <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default React.memo(SendCommentSVG);
