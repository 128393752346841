import React from 'react';

function IconCopySVG({ color = '#78778B', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v16H0z" />
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M9.6 3.174a2.007 2.007 0 0 1 1.991 1.856l.006.15v8.815a2.007 2.007 0 0 1-1.848 1.999L9.6 16H2.904a2.007 2.007 0 0 1-1.998-1.855l-.005-.15V5.18c0-1.056.817-1.92 1.854-1.999l.149-.006H9.6zm0 1.492H2.904a.514.514 0 0 0-.505.42l-.009.093v8.816c0 .253.182.463.422.507l.092.008H9.6a.515.515 0 0 0 .507-.422l.008-.093V5.18a.515.515 0 0 0-.423-.505L9.6 4.666zM13.096 0a2.007 2.007 0 0 1 1.998 1.855l.005.15v8.312c0 .948-.73 1.726-1.66 1.803l-.148.006h-.382a.746.746 0 0 1-.101-1.484l.1-.007h.382a.32.32 0 0 0 .311-.246l.009-.074v-8.31a.515.515 0 0 0-.422-.507l-.092-.008H6.914a.32.32 0 0 0-.312.246l-.008.074a.746.746 0 0 1-1.49 0c0-.95.732-1.728 1.661-1.804L6.914 0h6.182z"
                />
            </g>
        </svg>
    );
}

export default React.memo(IconCopySVG);
