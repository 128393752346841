import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import * as gridUIActions from 'gridUI/actions';
import { useDispatch } from 'react-redux';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import { getCellData } from 'utils/gridUI/cell';
import { getNodesByPath } from 'utils/gridUI/pathTag';
import * as roleConst from 'auth/roleConst';
import ColumnTypeDisplay from '../ColumnTypeDisplay';
import Tooltip from 'components/tooltip/Base';
import { Trans } from 'react-i18next';
import IconLockSVG from 'assets/images/svg/IconLockSVG';
import Text from '../cellDisplay/Text';
import SingleLine from '../cellDisplay/SingleLine';
import Translation from '../cellDisplay/Translation';
import Number from '../cellDisplay/Number';
import MultiLines from '../cellDisplay/MultiLines';
import RecordId from '../cellDisplay/RecordID';
import SingleSelect from '../cellDisplay/SingleSelect';
import MultipleSelect from '../cellDisplay/MultipleSelect';
import Datetime from '../cellDisplay/Datetime';
import MarkDown from '../cellDisplay/MarkDown';
import RichText from '../cellDisplay/RichText';
import Formula from '../cellDisplay/Formula';
import Files from '../cellDisplay/Files';
import Boolean from '../cellDisplay/Boolean';
import Yaml from '../cellDisplay/Yaml';
import Html from '../cellDisplay/Html';
import Json from '../cellDisplay/Json';
import PathTag from '../cellDisplay/PathTag';
import Ref from '../cellDisplay/Ref';
import LastModifiedBy from '../cellDisplay/LastModifiedBy';
import LastModifiedDate from '../cellDisplay/LastModifiedDate';
import { scrollInToView } from 'utils/scroll';
import hexToRgba from 'hex-to-rgba';
import RecordPanelEmptyRecordSVG from 'assets/images/svg/RecordPanelEmptyRecordSVG';
import {
    useData,
    useSectionRowStartIndex,
    useDisabledColumns,
    useProcessingColumns,
    useDisabledSourceColumns,
    useSelectedRecordId,
    useViewColumnsWithUserLanguageViewsAndMetadata,
    useRecordIndex,
    useDisabledColumnsByType,
    usePathTagRef,
    useSelectedCellData,
    useDependencies
} from 'hooks/gridUI';
import { useLdUserEmailMap } from 'hooks/permission';
import { useRole } from 'hooks/auth/role';
import { useTranslation } from 'react-i18next';
import { SOURCE_STATUS } from 'const/gridUI';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        background: theme.colors.white,
        borderLeft: `1px solid ${theme.colors.border}`,
        position: 'relative'
    },
    header: {
        height: 54,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottom: `1px solid ${theme.colors.border}`
    },
    body: {
        flex: 1,
        height: `calc(100% - 54px)`,
        overflowY: 'scroll'
    },
    mw300: {
        maxWidth: 300
    },
    columnItem: {
        position: 'relative'
    },
    fullWidth: {
        width: '100%'
    },
    spacing: {
        marginBottom: theme.spacing(3)
    },
    textWhite: {
        color: theme.colors.white
    },
    dlFlex: {
        display: 'flex'
    },
    closeIcon: {
        position: 'absolute',
        top: 20,
        right: 20
    }
}));

function CommentContent({ columnType, options = [], customProperties, referencedColumnType, ...rest }) {
    switch (columnType) {
        case columnTypes.SINGLE_LINE:
            return <SingleLine {...rest} />;
        case columnTypes.FORMULA:
            return <Formula {...rest} />;
        case columnTypes.RECORD_ID:
            return <RecordId {...rest} />;
        case columnTypes.MULTIPLE_LINES:
            return <MultiLines {...rest} />;
        case columnTypes.NUMBER:
            return <Number {...rest} />;
        case columnTypes.TRANSLATION:
            return <Translation {...rest} />;
        case columnTypes.ALTERED_BY:
        case columnTypes.CREATED_BY:
            return <LastModifiedBy {...rest} />;
        case columnTypes.SINGLE_SELECTION:
            return <SingleSelect options={options} customProperties={customProperties} {...rest} />;
        case columnTypes.MULTIPLE_SELECTIONS:
            return <MultipleSelect options={options} customProperties={customProperties} {...rest} />;
        case columnTypes.DATETIME:
            return <Datetime {...rest} />;
        case columnTypes.ALTERED_TIME:
        case columnTypes.CREATED_TIME:
            return <LastModifiedDate {...rest} />;
        case columnTypes.MARKDOWN:
            return <MarkDown {...rest} />;
        case columnTypes.RICH_TEXT:
            return <RichText {...rest} />;
        case columnTypes.FILES:
            return <Files {...rest} />;
        case columnTypes.BOOLEAN:
            return <Boolean {...rest} />;
        case columnTypes.YAML:
            return <Yaml {...rest} />;
        case columnTypes.JSON_LD:
            return <Json {...rest} />;
        case columnTypes.HTML:
            return <Html {...rest} />;
        case columnTypes.PATH_TAG:
            return <PathTag options={options} customProperties={customProperties} {...rest} />;
        case columnTypes.REFERENCE:
            return (
                <Ref
                    referencedColumnType={referencedColumnType}
                    options={options}
                    customProperties={customProperties}
                    {...rest}
                />
            );
        default:
            return <Text {...rest} />;
    }
}

function RecordPanel({ dbId }) {
    const roles = useRole();
    const data = useData();
    const selectedRecordId = useSelectedRecordId();
    const disabledColumns = useDisabledColumns();
    const processingColumns = useProcessingColumns();
    const disabledSourceColumns = useDisabledSourceColumns();
    const disabledColumnIdsByType = useDisabledColumnsByType();
    const pathRef = usePathTagRef();
    const accessEditRecords = roles?.[roleConst.WORKSPACE_AUTHORITIES.EDIT_RECORDS];
    const viewColumnLanguages = useViewColumnsWithUserLanguageViewsAndMetadata();
    const recordIndex = useRecordIndex();
    const ROW_START_INDEX = useSectionRowStartIndex();
    const selectedCellData = useSelectedCellData();
    const ldUserEmailMap = useLdUserEmailMap();
    const dependencies = useDependencies();
    const { t } = useTranslation();

    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const selectedColumnId = React.useMemo(() => {
        return selectedCellData?.columnId;
    }, [selectedCellData]);

    const handleTurnOffRecordPanel = React.useCallback(() => {
        dispatch(gridUIActions.turnOffRecordPanel());
    }, [dispatch]);

    const lockColumnIds = React.useMemo(() => {
        return [
            ...new Set([...disabledColumns, ...processingColumns, ...disabledSourceColumns, ...disabledColumnIdsByType])
        ];
    }, [disabledSourceColumns, disabledColumns, processingColumns, disabledColumnIdsByType]);

    React.useEffect(() => {
        if (selectedColumnId) {
            const node = document.getElementById('recordPanelScroll');
            scrollInToView(node, {
                boundary: document.getElementById('recordPanelBoundary'),
                block: 'center',
                inline: 'center',
                duration: 100
            });
        }
    }, [selectedColumnId]);

    if (!selectedRecordId)
        return (
            <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
                justify="center"
                className={`${classes.root} `}
            >
                <Grid item className={classes.closeIcon}>
                    <IconButton onClick={handleTurnOffRecordPanel}>
                        <CloseIconSVG />
                    </IconButton>
                </Grid>
                <Grid item>
                    <RecordPanelEmptyRecordSVG />
                </Grid>
                <Grid item>
                    <h3 className="prose prose-2xl text-center font-normal">{t(`record_empty_to_preview_title`)}</h3>
                </Grid>
                <Grid item>
                    <p className="text-center caption">{t(`record_empty_to_preview_subtitle`)}</p>
                </Grid>
            </Grid>
        );

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Grid item container direction="row" alignItems="center" justify="space-between" className={classes.header}>
                <Grid item>
                    <h3 className="prose prose-2xl font-medium">#{recordIndex + ROW_START_INDEX + 1}</h3>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleTurnOffRecordPanel}>
                        <CloseIconSVG />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid id="recordPanelBoundary" container direction="column" wrap="nowrap" item className={classes.body}>
                {viewColumnLanguages?.map(column => {
                    const columnId = column?.id;
                    const columnType = getCorrectColumnType(column);
                    const dependency = dependencies?.find(dpDc => dpDc?.child === columnId);

                    const parentColumnId = dependency?.parent;
                    const parentCellData = data?.[selectedRecordId]?.[parentColumnId];

                    const isParentDisabledStatus = [
                        SOURCE_STATUS.DO_NOT_TRANSLATE,
                        SOURCE_STATUS.NOT_READY_FOR_TRANSLATION
                    ]?.includes(parentCellData?._sourceStatus);

                    let cellData = getCellData({ data, rowId: selectedRecordId, columnId });
                    cellData =
                        columnType === columnTypes.PATH_TAG
                            ? {
                                  ...cellData,
                                  value: getNodesByPath({ pathRef, path: cellData?.value })
                              }
                            : cellData;

                    const isSourceLocked = [SOURCE_STATUS.LOCKED]?.includes(cellData?._sourceStatus);

                    const isEditable = !lockColumnIds?.includes(columnId) && column?.editable;
                    const isEditReadOnly = accessEditRecords !== roleConst.FULL;

                    const isReadOnly = isEditReadOnly || !isEditable || isSourceLocked || isParentDisabledStatus;

                    const isNotSupportEditForNowTypes = [
                        columnTypes.DATETIME,
                        columnTypes.SINGLE_SELECTION,
                        columnTypes.MULTIPLE_SELECTIONS,
                        columnTypes.REFERENCE,
                        columnTypes.FILES,
                        columnTypes.MARKDOWN,
                        columnTypes.RICH_TEXT,
                        columnTypes.PATH_TAG,
                        columnTypes.FORMULA
                    ].includes(columnType);

                    const isActive = selectedColumnId === columnId;

                    return (
                        <Grid key={column?.id} item className={classes.fullWidth}>
                            <Grid
                                container
                                direction="column"
                                className={`${classes.columnItem}`}
                                style={{
                                    marginLeft: theme.spacing(3),
                                    marginRight: theme.spacing(3),
                                    marginBottom: theme.spacing(2),
                                    width: `calc(100% - ${2 * theme.spacing(3)}px)`,
                                    padding: 10,
                                    borderRadius: 4,
                                    background: isActive ? hexToRgba(theme.colors.crusta, 0.1) : ``
                                }}
                            >
                                <Grid item className={classes.spacing}>
                                    <Grid container alignItems="center" direction="row" spacing={1}>
                                        <Grid item>
                                            <ColumnTypeDisplay
                                                name={column?.name}
                                                group={column?.group}
                                                type={columnType}
                                                customProperties={column?.customProperties}
                                            />
                                        </Grid>
                                        {isNotSupportEditForNowTypes && (
                                            <Tooltip
                                                title={
                                                    <p className="text-light">
                                                        <Trans i18nKey="cell_comments_editing">
                                                            Editing this data type here <br /> is not supported for now
                                                        </Trans>
                                                    </p>
                                                }
                                            >
                                                <Grid item className={classes.dlFlex}>
                                                    <IconLockSVG color={theme.colors.highlight} />
                                                </Grid>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item style={{ maxWidth: '100%' }}>
                                    <CommentContent
                                        rowId={selectedRecordId}
                                        columnId={columnId}
                                        referencedColumnType={column?.referencedColumnType}
                                        columnType={columnType}
                                        value={cellData?.value}
                                        cellData={cellData}
                                        options={column?.options}
                                        group={column?.group}
                                        customProperties={column?.customProperties}
                                        dbId={dbId}
                                        isReadOnly={isReadOnly || isNotSupportEditForNowTypes}
                                        ldUserEmailMap={ldUserEmailMap}
                                    />
                                </Grid>
                            </Grid>
                            {isActive && <span id={'recordPanelScroll'} style={{ height: 0, width: 0 }}></span>}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}

export default RecordPanel;
