import React, { useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import CheckIconSVG from 'assets/images/svg/CheckIconSVG';
import hexToRgba from 'hex-to-rgba';
import useScrollInViewListener from 'hooks/useScrollInViewListener';
import ThreeStateCheckBox from 'components/checkbox/ThreeStateCheckBox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 526
    },
    resolved: {
        borderColor: hexToRgba(theme.colors.atlantis, 0.8),
        background: hexToRgba(theme.colors.atlantis, 0.8),
        color: theme.colors.white,
        '&:hover': {
            borderColor: `${hexToRgba(theme.colors.atlantis, 0.8)} !important`,
            background: `${hexToRgba(theme.colors.atlantis, 0.8)} !important`,
            color: theme.colors.white,
            opacity: 0.7
        }
    },
    checkIcon: {
        paddingRight: theme.spacing(1)
    },
    checkbox: {
        pointerEvents: props => (props?.canManageTicket ? `` : `none`),
        padding: 0
    },
    relative: {
        position: 'relative'
    },
    iconWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
    checkIcon2: {
        height: 8
    },
    ticketId: {
        color: theme.colors.lightGreyBlue,
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
    summary: {
        fontSize: 14,
        fontWeight: 500,
        ...theme.ellipsis(1)
    }
}));

const TicketDetailTitle = ({
    isResolved,
    changeStatusHandler,
    openLQATicketDetail,
    ticketId,
    ticketSummary,
    canManageTicket
}) => {
    const classes = useStyles({ canManageTicket });
    const theme = useTheme();
    const { t } = useTranslation();
    const { isTotal } = useScrollInViewListener({
        container: document.getElementById('ticket-dialog-content'),
        element: document.getElementById('ticket-detail-summary'),
        condition: openLQATicketDetail
    });

    const checkboxState = useMemo(() => {
        return isResolved ? 1 : 2;
    }, [isResolved]);

    if (isTotal && canManageTicket) {
        return (
            <Button
                className={isResolved ? classes.resolved : ``}
                variant="outlined"
                size="small"
                onClick={changeStatusHandler}
            >
                <CheckIconSVG
                    color={isResolved ? theme.colors.white : theme.colors.steel}
                    className={classes.checkIcon}
                />{' '}
                {isResolved ? t('global_resolved') : t('mark_resolve')}
            </Button>
        );
    }
    return (
        <Grid container spacing={1} className={classes.root} wrap="nowrap">
            <Grid item className={classes.relative} onClick={canManageTicket ? changeStatusHandler : () => {}}>
                <ThreeStateCheckBox state={checkboxState} className={classes.checkbox} onChange={() => {}} />
                {!isResolved && (
                    <Grid item className={classes.iconWrapper}>
                        <CheckIconSVG className={classes.checkIcon2} color="#ACADB9" />
                    </Grid>
                )}
            </Grid>
            <Grid item>
                <p className="body2 whitespace-nowrap text-grey-light">#Ticket-{ticketId}</p>
            </Grid>
            <Grid item>
                <p className={'body2 whitespace-nowrap line-clamp-1 font-semibold'}>{ticketSummary}</p>
            </Grid>
        </Grid>
    );
};

export default React.memo(TicketDetailTitle);
