import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isKbEnter, isKbEscape, isKbShiftEnter } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import { Grid, InputBase } from '@material-ui/core';
import ButtonBase from 'components/button/Base';

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: '2px'
    },
    editBox: {
        width: '100%',
        position: 'relative',
        borderColor: 'rgb(233, 234, 239)',
        transition: 'padding 0.5s ease, background 0.5s ease, border 0.5s ease',
        padding: '5px 3px',
        borderRadius: '4px'
    },
    editBoxAction: {
        cursor: 'text',
        '&:hover': {
            background: theme.colors.ghostwhite
        }
    },
    edit: {
        border: '1px solid rgb(233, 234, 239)',
        background: 'rgb(255, 255, 255) !important'
    },
    content: {
        width: '100%',
        resize: `none`,
        border: `none`,
        background: `transparent`,
        '&:focus': {
            outline: 'none'
        },
        '& input': {
            paddingRight: 10,
            fontSize: 18
        }
    },
    normalContent: {
        fontWeight: 500,
        fontSize: 18,
        width: '100%',
        '& input': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    },
    contentDisabled: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    enterBtn: {
        minWidth: 'unset',
        minHeight: 'unset',
        width: 48,
        height: 24,
        fontWeight: 400,
        fontSize: 13,
        marginRight: 6
    }
}));

const TicketDetailSummary = ({ content, onChange, isAllowEdit }) => {
    const [isOpenEdit, setIsOpenEdit] = useState();
    const [currentContent, setCurrentContent] = useState(content);
    const rootRef = useRef();
    const inputRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        setCurrentContent(content);
    }, [content]);

    useClickAwaitListener(rootRef, e => {
        handleClickAway();
    });

    const handleFocus = React.useCallback(() => {
        const input = inputRef.current;
        if (input) {
            input.focus();
        }
    }, []);

    const handleOpenEdit = useCallback(() => {
        if (!isAllowEdit || isOpenEdit) return;
        setIsOpenEdit(true);
        handleFocus();
    }, [handleFocus, isAllowEdit, isOpenEdit]);

    const cancelHandler = useCallback(
        e => {
            setCurrentContent(content);
            setIsOpenEdit(false);
        },
        [content]
    );

    const handleClickAway = useCallback(() => {
        cancelHandler();
    }, [cancelHandler]);

    const handleValueChange = e => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentContent(e.target.value);
    };

    const handleUpdate = useCallback(() => {
        const newContent = currentContent.trim();
        if (newContent !== content) {
            onChange(currentContent.trim());
        }
        setIsOpenEdit(false);
        const input = inputRef.current;
        if (input) {
            input.blur();
        }
    }, [onChange, currentContent, content]);

    const handleKeyDown = e => {
        if (isKbEscape(e)) {
            cancelHandler();
        }
        if (isKbEnter(e) && !isKbShiftEnter(e)) {
            e.preventDefault();
            e.stopPropagation();
            handleUpdate();
        }
    };

    return (
        <Grid className={classes.root} ref={rootRef} container onClick={handleOpenEdit} id="ticket-detail-summary">
            <Grid
                className={`
                ${classes.editBox} 
                ${isOpenEdit ? classes.edit : ''} 
                ${isAllowEdit ? classes.editBoxAction : ''}
                `}
                item
                onClick={handleFocus}
            >
                <InputBase
                    classes={{ disabled: classes.contentDisabled }}
                    inputRef={el => (inputRef.current = el)}
                    className={isOpenEdit ? classes.content : classes.normalContent}
                    value={currentContent}
                    onChange={handleValueChange}
                    onKeyDown={handleKeyDown}
                    disabled={!isAllowEdit}
                    readOnly={!isOpenEdit}
                    endAdornment={
                        isOpenEdit ? (
                            <ButtonBase className={classes.enterBtn} variant="outlined" onClick={handleUpdate}>
                                Enter
                            </ButtonBase>
                        ) : null
                    }
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(TicketDetailSummary);
