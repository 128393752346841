import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import LQAStep2SVG from 'assets/images/svg/addon/LQAStep2SVG';
import LQASVG from 'assets/images/svg/LQASVG';
import AddButtonContainerSVG from 'assets/images/svg/addon/AddButtonContainerSVG';

const useStyles = makeStyles(theme => ({
    root: {},
    numberStep: {
        width: 16,
        minWidth: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightLavender,
        color: theme.colors.white,
        borderRadius: '50%',
        fontSize: 10,
        marginRight: theme.spacing(2),
        position: 'relative',
        top: 3
    },
    memoqType: {
        cursor: 'pointer',
        background: theme.colors.paleGrey,
        borderRadius: 4,
        padding: 4
    },
    dpFlex: {
        display: 'flex'
    },
    expand: {
        flex: 1
    },

    highlight: {
        color: theme.colors.dodgerBlue,
        fontWeight: 700,
        textDecoration: 'none'
    },
    settingIcon: {
        position: 'relative',
        top: 4
    },
    buttonWrapper: {
        position: 'relative',
        marginTop: 8
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    pb4: {
        paddingBottom: theme.spacing(4)
    },
    pb2: {
        paddingBottom: theme.spacing(2)
    },
    pb1: {
        paddingBottom: theme.spacing(1)
    }
}));

function LQASetting() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction="column">
            <Grid item className={classes.pb4}>
                <Grid container direction="column">
                    <Grid item className={classes.pb2}>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_1')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Typography variant="body1">{t(`addon_lqa_setting_step_1_description`)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Trans
                            t={t}
                            i18nKey="addon_lqa_setting_step_2_detail"
                            defaults="• <normal>To setup the LQA models, go to</normal> <highlight>Localization Settings/ LQA Models</highlight>"
                            components={{
                                normal: <Typography variant="body2" display="inline" />,
                                highlight: (
                                    <Link
                                        to="/localization-settings/lqa"
                                        className="text-dodgerBlue no-underline font-medium inline"
                                    >
                                        Localization Settings/ LQA Models
                                    </Link>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.pb4}>
                <Grid container direction="column">
                    <Grid item className={classes.pb2}>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_2')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Typography variant="body1">{t(`addon_lqa_setting_step_2_description`)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.pb1}>
                        <Typography variant="body2">• {t(`addon_lqa_setting_step_2_detail_1`)}</Typography>
                    </Grid>
                    <Grid container justify="center">
                        <LQAStep2SVG />
                    </Grid>
                    <Grid item className={classes.pb1}>
                        <Typography variant="body2">
                            • {t(`addon_lqa_setting_step_2_detail_2`)}{' '}
                            <span>
                                <LQASVG style={{ verticalAlign: 'text-top' }} />
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Trans
                            t={t}
                            i18nKey="addon_lqa_setting_step_2_detail_3"
                            // defaults="• Then click <span>{{iconAdd}}</span> button to add."
                            defaults="• Then click <span><iconAdd /></span> button to add."
                            components={{
                                span: <span />,
                                iconAdd: <AddButtonContainerSVG style={{ verticalAlign: 'text-top' }} />
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.pb4}>
                <Grid container direction="column">
                    <Grid item className={classes.pb2}>
                        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
                            <Grid item className={`${classes.numberStep}`}>
                                {t('global_step_3')}
                            </Grid>
                            <Grid item className={classes.expand}>
                                <Typography variant="body1">{t(`addon_lqa_setting_step_3_description`)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.pb1}>
                        <Typography variant="body2">
                            • {t(`addon_lqa_setting_step_3_detail_1`)}{' '}
                            <span>
                                <LQASVG style={{ verticalAlign: 'text-top' }} />
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">• {t(`addon_lqa_setting_step_3_detail_2`)}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(LQASetting);
