import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useFixedColumnCount, useRowHeight, useTotalRecords, useViewColumnsWithReOrder } from 'hooks/gridUI';
import { useIsShowSearchRange, useSearchRange } from 'hooks/gridUI/search';
import { _getStyleInfo } from '../cellOverlay';
import { isEmpty } from 'lodash';
import { INDEX_COLUMN_WIDTH } from 'const/gridUI';
import { getViewColumnWidth } from 'utils/gridUI/column';

const OVERLAY_TYPE = {
    SINGLE_RANGE: 'SINGLE_RANGE',
    COLUMN_RANGE: 'COLUMN_RANGE'
};

const ALIGN = {
    BOTH: 'BOTH',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    CENTER: 'CENTER'
};

function getGroupClosestIndex(array) {
    return array.reduce(function(r, a, i, aa) {
        if (a - aa[i - 1] < 2) {
            if (!Array.isArray(r[r.length - 1])) {
                r[r.length - 1] = [r[r.length - 1]];
            }
            r[r.length - 1].push(a);
            return r;
        }
        r.push(a);
        return r;
    }, []);
}

function getAlign({ range, groups }) {
    const first = range[0];
    const last = range[range.length - 1];
    const foundGroup = groups?.filter(el => Array.isArray(el))?.find(el => el?.includes(last));
    if (!foundGroup || foundGroup.length === range.length) {
        return ALIGN.BOTH;
    }
    if (first === foundGroup[0]) {
        return ALIGN.LEFT;
    }
    if (last === foundGroup[foundGroup.length - 1]) {
        return ALIGN.RIGHT;
    }
    return ALIGN.CENTER;
}

function getColumnsSelectedStyle({ type, columnsSelected, fixedColumnCount, viewColumns }) {
    const arr = [];
    const columnsSelectedIndex = columnsSelected?.map(id => viewColumns.findIndex(el => el.id === id))?.sort();
    const groupClosestIndex = getGroupClosestIndex(columnsSelectedIndex);
    const isLeft = type === 'left';

    const selectedColumnsIndex = isLeft
        ? columnsSelectedIndex.filter(idx => idx < fixedColumnCount)
        : columnsSelectedIndex.filter(idx => idx >= fixedColumnCount);

    const groupClosestSideIndex = getGroupClosestIndex(selectedColumnsIndex);

    groupClosestSideIndex.forEach(range => {
        if (!Array.isArray(range)) {
            range = [range];
        }
        const beforeColumnsIndex = isLeft
            ? Array.from(Array(range[0]).keys())
            : Array.from(Array(range[0]).keys()).filter(el => el >= fixedColumnCount);
        arr.push({
            overlayType: OVERLAY_TYPE.COLUMN_RANGE,
            data: {
                type,
                top: 0,
                left: isLeft
                    ? INDEX_COLUMN_WIDTH +
                      beforeColumnsIndex?.reduce((t, idx) => t + getViewColumnWidth(viewColumns[idx]), 0)
                    : beforeColumnsIndex?.reduce((t, idx) => t + getViewColumnWidth(viewColumns[idx]), 0),
                width: range?.reduce((t, idx) => t + getViewColumnWidth(viewColumns[idx]), 0),
                align: getAlign({ range, groups: groupClosestIndex })
            }
        });
    });
    return arr;
}

function getRowsSelectedStyle({ type, rowsRangeIndexes, viewColumns, fixedColumnCount, rowHeight }) {
    return rowsRangeIndexes.reduce((acc, range) => {
        const columnStartIndex = 0;
        const columnStopIndex = viewColumns.length - 1;
        const rowStartIndex = range[0];
        const rowStopIndex = range[range.length - 1];
        const data = _getStyleInfo({
            type,
            fixedColumnCount,
            rowHeight,
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex,
            viewColumns
        });
        acc.push({
            overlayType: OVERLAY_TYPE.SINGLE_RANGE,
            data: {
                type,
                ...data
            }
        });
        return acc;
    }, []);
}

const SingleRange = React.memo(({ data }) => {
    const { type, top, left, isRemoveEdgeBorder, width, height } = data;
    if (!height) return null;
    return (
        <div
            style={{
                position: 'relative',
                top: top,
                left: left
            }}
        >
            <div
                className={classnames(
                    `search-overlay absolute pointer-events-none border-[2px] border-green-400 bg-green-300 bg-opacity-[0.15]`,
                    {
                        'border-l-0': isRemoveEdgeBorder && type === 'right',
                        'border-r-0': isRemoveEdgeBorder && type === 'left'
                    }
                )}
                container
                style={{
                    top: 0,
                    left: 0,
                    width,
                    height
                }}
            />
        </div>
    );
});

const ColumnRange = React.memo(({ data }) => {
    const totalRecords = useTotalRecords();
    const rowHeight = useRowHeight();
    const { top, left, align, width } = data;
    return (
        <div
            style={{
                position: 'relative',
                top: top,
                left: left
            }}
        >
            <div
                className={classnames(
                    `search-overlay absolute pointer-events-none border-[2px] border-green-400 bg-green-300 bg-opacity-[0.15]`,
                    {
                        'border-l-0': [ALIGN.RIGHT, ALIGN.CENTER].includes(align),
                        'border-r-0': [ALIGN.LEFT, ALIGN.CENTER].includes(align)
                    }
                )}
                container
                style={{
                    top: 0,
                    left: 0,
                    width,
                    height: rowHeight * totalRecords
                }}
            />
        </div>
    );
});

const SearchOverlay = ({ type }) => {
    const fixedColumnCount = useFixedColumnCount() || 0;
    const rowHeight = useRowHeight();
    const viewColumns = useViewColumnsWithReOrder();
    const {
        rowStartIndex,
        rowStopIndex,
        columnStartIndex,
        columnStopIndex,
        columnsSelected,
        rowsRangeIndexes
    } = useSearchRange();

    const singleRange = useMemo(() => {
        const data = _getStyleInfo({
            type,
            fixedColumnCount,
            rowHeight,
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex,
            viewColumns
        });
        return {
            overlayType: OVERLAY_TYPE.SINGLE_RANGE,
            data: {
                type,
                ...data
            }
        };
    }, [
        type,
        fixedColumnCount,
        rowHeight,
        viewColumns,
        rowStartIndex,
        rowStopIndex,
        columnStartIndex,
        columnStopIndex
    ]);

    const columnsRange = useMemo(() => {
        if (isEmpty(columnsSelected)) return [];
        return getColumnsSelectedStyle({
            type,
            fixedColumnCount,
            rowHeight,
            viewColumns,
            columnsSelected
        });
    }, [columnsSelected, type, fixedColumnCount, rowHeight, viewColumns]);

    const rowsRange = useMemo(() => {
        if (isEmpty(rowsRangeIndexes)) return [];
        return getRowsSelectedStyle({
            type,
            rowsRangeIndexes,
            viewColumns,
            fixedColumnCount,
            rowHeight
        });
    }, [rowsRangeIndexes, type, viewColumns, fixedColumnCount, rowHeight]);

    return [singleRange, ...columnsRange, ...rowsRange]?.map((overlay, index) => {
        switch (overlay.overlayType) {
            case OVERLAY_TYPE.SINGLE_RANGE:
                return <SingleRange key={index} data={overlay.data} />;
            case OVERLAY_TYPE.COLUMN_RANGE:
                return <ColumnRange key={index} data={overlay.data} />;
            default:
                return null;
        }
    });
};

const SearchOverlayMemo = React.memo(SearchOverlay);

const SearchOverlayWrapper = props => {
    const isShowSearchRange = useIsShowSearchRange();
    const searchRange = useSearchRange();
    if (!isShowSearchRange || isEmpty(searchRange)) return null;
    return <SearchOverlayMemo {...props} />;
};

export default React.memo(SearchOverlayWrapper);
