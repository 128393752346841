import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthenticationWrapper from './AuthenticationWrapper';
import CompanyList from '../permission/companies/CompanyList';
import AuthFormWrapper from './common/AuthFormWrapper';
import Grid from '@material-ui/core/Grid';
import { useCompanyList, useIsFetching } from 'hooks/permission';
import AddIconSVG from 'assets/images/svg/AddIconSVG';
import { useHistory } from 'react-router-dom';
import hexToRgba from 'hex-to-rgba';
import { queryParamsToObject } from 'utils/urlQueryUtils';
import { useTranslation } from 'react-i18next';
import * as actions from 'permission/actions/company';
import { changeAccountCompany } from './actions';
import { _invalidateWorkspaces } from 'workspaces/actions';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    progress: {
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%'
    },
    addMoreCompany: {
        marginTop: 20,
        marginLeft: 14,
        cursor: 'pointer'
    },
    avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: hexToRgba(theme.palette.primary.main, 0.8),
        borderRadius: '50%',
        width: 45,
        height: 45,
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
        '& svg': {
            width: 20,
            height: 20,
            '& path': {
                fill: theme.colors.white
            }
        }
    }
}));

function AddMoreCompany({ history }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const gotoCreateCompany = () => {
        history.push('/create-company');
    };
    return (
        <Grid
            className={classes.addMoreCompany}
            onClick={gotoCreateCompany}
            container
            justify="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid item>
                <div className={classes.avatar}>
                    <AddIconSVG />
                </div>
            </Grid>
            <Grid item>
                <Typography variant="body1">{t(`auth_create_a_new_company`)}</Typography>
            </Grid>
        </Grid>
    );
}

function SwitchCompany() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const queryParams = queryParamsToObject(history.location?.search);
    const paramCompanyId = queryParams?.id;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const companies = useCompanyList();
    const isFetchingCompanies = useIsFetching();

    useEffect(() => {
        dispatch(actions.fetchCompanies({}));
    }, [dispatch]);

    const handleCompanySelect = async company => {
        setIsSubmitting(true);
        dispatch(
            changeAccountCompany({
                companyId: company.id,
                successCallback: () => {
                    dispatch(_invalidateWorkspaces());
                    history.push('/');
                    setIsSubmitting(false);
                },
                errorCallback: error => {
                    setIsSubmitting(false);
                }
            })
        );
    };

    return paramCompanyId ? (
        <AuthenticationWrapper>
            <div className={classes.progress}>
                <CircularProgress />
            </div>
        </AuthenticationWrapper>
    ) : (
        <AuthenticationWrapper>
            <AuthFormWrapper title={t(`auth_choose_company_title`)} subTitle={t(`auth_choose_company_subtitle`)}>
                <Grid className={classes.root} container direction="column" wrap="nowrap">
                    {(isSubmitting || isFetchingCompanies) && (
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>
                    )}
                    <Grid item>
                        <CompanyList companies={companies} onItemClick={handleCompanySelect} />
                    </Grid>
                    <Grid item>
                        <AddMoreCompany history={history} />
                    </Grid>
                </Grid>
            </AuthFormWrapper>
        </AuthenticationWrapper>
    );
}

export default React.memo(SwitchCompany);
