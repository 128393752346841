import { enqueueSnackbar } from 'notifier/actions';
import { getWorkspaceViewsDetailApi } from 'services/workspaces';
import * as types from '../types';
import orderBy from 'lodash/orderBy';
import { generateWsAccessId } from 'utils/permission/workspaceAccess';

export function fetchWorkspaceViewsDetail({ successCallback, errorCallback }) {
    return async function(dispatch, getState) {
        const { auth } = getState();
        const companyId = auth.companyId || auth.currentUser.companyId;
        dispatch(_fetchWorkspaceViewsAction());
        try {
            const workspaceViewsDetail = await getWorkspaceViewsDetailApi({
                companyId,
                paramOptions: {
                    type: 'access'
                }
            });
            const workspaceViewsDetailWithOrder = orderBy(workspaceViewsDetail, `workspaceId`).map(item => ({
                ...item,
                id: generateWsAccessId(item)
            }));

            dispatch(
                _fetchWorkspaceViewsSuccessAction({
                    workspaceViewsDetail: workspaceViewsDetailWithOrder
                })
            );
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'info'
                })
            );
            dispatch(_fetchWorkspaceViewsFailedAction({ error: message }));
            errorCallback && errorCallback();
        }
    };
}

function _fetchWorkspaceViewsAction() {
    return {
        type: types.FETCH_WORKSPACE_VIEWS_DETAIL
    };
}

function _fetchWorkspaceViewsSuccessAction({ workspaceViewsDetail }) {
    return {
        type: types.FETCH_WORKSPACE_VIEWS_DETAIL_SUCCESS,
        payload: {
            workspaceViewsDetail
        }
    };
}

function _fetchWorkspaceViewsFailedAction({ error }) {
    return {
        type: types.FETCH_WORKSPACE_VIEWS_DETAIL_FAILED,
        payload: {
            error
        }
    };
}
