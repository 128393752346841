import React from 'react';
import { Grid } from '@material-ui/core';
import ListItem from 'components/list/Item';
import ExportSVG from 'assets/images/svg/ExportSVG';
import ImportSVG from 'assets/images/svg/ImportSVG';
import AccessControl from 'auth/AccessControl';
import * as roleConst from 'auth/roleConst';
import { sendManualTrack } from 'tracker';
import SynchronizeTMSVG from 'assets/images/svg/SynchronizeTMSVG';
import { useTranslation } from 'react-i18next';
import { useIsTmDisabled } from 'hooks/gridUI';
import Tooltip from 'components/tooltip/Base';

function LocalizationPopup({
    handleClose,
    handleExportLocalization,
    hideOtherTranlationColumnsHandler,
    onOpenTranslationOverview,
    handleImportLocalization,
    onOpenGridTypeSetting,
    isJsonGrid,
    isPoGrid,
    handleSynchronizeTM
}) {
    const { t } = useTranslation();

    const isTmDisabled = useIsTmDisabled();

    const handleImport = React.useCallback(() => {
        handleClose && handleClose();
        sendManualTrack({ type: `Click On Import Localization File` });
        handleImportLocalization && handleImportLocalization();
    }, [handleClose, handleImportLocalization]);

    const handleExport = () => {
        handleExportLocalization && handleExportLocalization();
    };

    // const gridTypeSettingTitle = React.useMemo(() => {
    //     if (isJsonGrid) return `Json file settings`;
    //     if (isPoGrid) return `Po file settings`;

    //     return null;
    // }, [isPoGrid, isJsonGrid]);

    return (
        <Grid container direction="column">
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION}>
                {({ isReadyOnly }) => (
                    <Grid item onClick={handleImport}>
                        <ListItem icon={<ImportSVG />} name={t('localization_import')} />
                    </Grid>
                )}
            </AccessControl>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION}>
                {({ isReadyOnly }) => (
                    <Grid item>
                        <ListItem onClick={handleExport} icon={<ExportSVG />} name={t('localization_export')} />
                    </Grid>
                )}
            </AccessControl>
            <AccessControl view={roleConst.WORKSPACE_AUTHORITIES.EDIT_TRANSLATION}>
                {({ isReadyOnly }) => (
                    <Tooltip title={isTmDisabled ? 'An active TM requried for sync' : ''}>
                        <Grid item>
                            <ListItem
                                onClick={handleSynchronizeTM}
                                icon={<SynchronizeTMSVG />}
                                name={t('synchronize_tm')}
                                disabled={isTmDisabled}
                            />
                        </Grid>
                    </Tooltip>
                )}
            </AccessControl>
        </Grid>
    );
}

export default React.memo(LocalizationPopup);
