import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import PopperMenu from './Popper';
import DropdownMenuItem from './DropdownMenuItem';
import Search from '../search';

import { SELECTION_LIST_ITEM_PADDING_TOP_BOTTOM } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 4,
        cursor: 'pointer',
        margin: 0,
        width: '100%'
    },
    arrow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8
    },
    trigger: {
        paddingLeft: 12,
        paddingRight: 12
    },
    flex: {
        display: 'flex'
    },
    item: {
        padding: SELECTION_LIST_ITEM_PADDING_TOP_BOTTOM
    },

    list: {
        maxHeight: 250,
        minWidth: 200,
        overflow: 'auto',
        padding: 0
    }
}));

function DropdownMenu({
    id,
    value,
    children,
    options,
    className,
    color,
    placement,
    search,
    onChange,
    Control,
    menuClassName
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterValue, setFilterValue] = React.useState('');
    const selectedOption = (options && options.find(option => option.value === value)) || {};

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);

        event.preventDefault();
        event.stopPropagation();
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleItemSelect = props => {
        onChange && onChange(props);

        handleClickAway();
    };

    const buildMenuItems = (selectedOption, options, filterValue) => {
        const elements = [];

        if (options?.length > 0) {
            options.forEach(option => {
                if (!filterValue || option.label.toLowerCase().includes(filterValue.toLowerCase())) {
                    const selected = option.value === selectedOption.value;

                    elements.push(
                        <DropdownMenuItem
                            key={`menu-item-${option.value}`}
                            {...option}
                            selected={selected}
                            onClick={handleItemSelect}
                        />
                    );
                }
            });
        }

        return elements;
    };

    return (
        <Grid id={id} className={`dropdown-menu ${classes.root} ${className}`}>
            {children ? (
                <div onClick={handleClick}>{children}</div>
            ) : (
                <Grid
                    className={`${classes.trigger} input`}
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    onClick={handleClick}
                >
                    {selectedOption.icon && (
                        <Grid item className={`${classes.flex} ${classes.item}`}>
                            {typeof selectedOption.icon === 'function' ? selectedOption.icon() : selectedOption.icon}
                        </Grid>
                    )}
                    <Grid item className={`${classes.item} ${classes.flex}`}>
                        {selectedOption.label}
                    </Grid>
                    <Grid item className={`${classes.item} ${classes.flex}`}>
                        <ArrowDownSVG color={color} />
                    </Grid>
                </Grid>
            )}
            {anchorEl && (
                <PopperMenu
                    id={`${id || 'dropdown'}-popper-menu`}
                    anchorEl={anchorEl}
                    handleClickAway={handleClickAway}
                    placement={placement}
                >
                    {search && (
                        <ListItem>
                            <Search
                                className={classes.search}
                                onChange={e => setFilterValue(e.target.value)}
                                background={theme.colors.paleGrey}
                                placeholder={search}
                            />
                        </ListItem>
                    )}
                    <List className={`${classes.list} ${menuClassName}`}>
                        {buildMenuItems(selectedOption, options, filterValue)}
                    </List>
                    {Control && <Control handleClickAway={handleClickAway} />}
                </PopperMenu>
            )}
        </Grid>
    );
}

export default React.memo(DropdownMenu);
