import hexToRgba from 'hex-to-rgba';
import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

const theme = {
    spacing: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    pb: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    pt: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    pr: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    pl: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    mt: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    mr: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    mb: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    ml: factor => [0, 5, 8, 14, 24, 32, 64][factor],
    palette: {
        primary: {
            main: '#7869B9'
        },
        secondary: {
            main: '#4D3F8A'
        }
    },
    buttonColors: {
        contained: {
            primary: `#7869B9`,
            hover: `#4D3F8A`,
            press: `#392B76`,
            disabled: `#DBDCE1`
        },
        outlined: {
            primary: `#ffffff`,
            hover: `#F3F4FC`,
            press: `#EBEBF6`,
            disabled: `#ffffff`,
            border: `#A69FC4`
        },
        default: {
            primary: `#7869B9`,
            hover: `#F3F4FC`,
            press: `#EBEBF6`,
            disabled: `#ffffff`
        }
    },
    colors: {
        lavender: '#4D3F8A',
        darkLavender: '#392B76',
        lightLavender: '#7869B9',
        paleLavender: '#A69FC4',
        solitude: '#EBEBF6',
        paleNavy: '#F3F4FC',
        codeHeader: `#787791`,

        brightRed: `#EB4F5E`,
        burntSlena: '#EA6A46',
        crusta: '#F9772A',
        sun: '#F8AD13',
        atlantis: '#77B32B',
        shamRock: '#2DD2A3',
        lightCyan: `#5DCDE3`,
        dodgerBlue: '#2D97F2',
        funBlue: '#1D66AE',
        shipCove: '#6A7AB9',
        fuchsiaBlue: '#6E5BBE',
        froly: '#F67B8E',
        darkness: '#606066',
        systemIconColor: '#6C75C5',
        hawkesBlue: '#E0EFFD',
        cherub: '#FBE5E7',
        lightSun: '#FBD17B',
        grannyApple: '#E0F8F1',
        pinka: '#FAB7BC',
        griptide: '#96E8D1',

        black: '#000000',
        greySuit: '#8C84AF',

        primaryText: '#2A293A',
        secondaryText: '#93929E',
        dimGrey: '#2A293A',
        steel: '#78778B',
        midGrey: '#93929E',
        lightGreyBlue: '#ACADB9',
        disabledGrey: '#C9C9CE',
        silver: '#D7D7DC',
        lightGrey: '#DBDCE1',
        divider: '#E9EAEF',
        paleGrey: '#F4F5F7',
        ghostwhite: '#F7F8FA',
        white: '#FFFFFF',
        border: '#E9EAEF',
        background: '#F7F8FA',
        backgroundDisabled: '#F4F5F7',
        disabledText: '#C9C9CE',
        disabledButtonBackground: '#dbdce1',
        disabledButtonColor: '#ffffff',
        highlight: '#4A91E2',
        hoverItem: '#f7f8fa',
        hoverAddItem: '#7869b9',
        skeletonColor: '#F4F5F7',
        selectionColor: '#E0EFFD',
        seaGreen: '#42B883',
        checkboxBorder: '#b4bdc8',
        iconGridUIColor: '#78778B',
        resizeColor: '#57ACF5',
        freezingColor: `#7869B9`,
        snackBarColor: '#353445',
        iconHeaderColor: hexToRgba('#78778B', 0.3),
        snackBarSuccess: '#96C060',
        snackBarError: '#E86E5E',
        snackBarWarning: '#E86E5E',
        snackBarInfo: '#353445',
        tutorialColor: `#2A2A2A`,
        uploadBkgColor: '#F4F8FD',
        hoverWarningButton: '#DB4F3C',
        systemColumn: '#6C75C5',
        indicator: '#FF8282',
        tooltip: '#2A2A2A',
        token: '#6A7AB9',
        missingTag: '#EF727E',
        lightError: '#E86E5E',
        hoverError: '#FF440F'
    },
    typography: {
        fontFamily:
            'Roboto, apple-system, BlinkMacSystemFont, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",SimSun, sans-serif;',

        subtitle1: {
            color: '#78778B',
            fontWeight: 'normal',
            lineHeight: 1.5,
            fontSize: 18,
            fontStyle: 'normal',

            [breakpoints.down('xs')]: {
                fontSize: 16
            }
        },
        subtitle2: {
            color: 'white',
            fontWeight: 'normal',
            lineHeight: 1.5,
            fontSize: 13,
            fontStyle: 'normal',
            [breakpoints.down('xs')]: {
                fontSize: 16
            }
        },
        h1: {
            // [SEO note] Make sure on a page have only ONE h1 used.
            fontSize: 36,
            fontWeight: 'normal',
            color: '#2A293A',
            fontStyle: 'normal',
            [breakpoints.down('xs')]: {
                fontSize: 34
            }
        },
        h2: {
            fontSize: 30,
            color: '#2A293A',
            fontWeight: 500,
            fontStyle: 'normal'
        },
        h3: {
            fontSize: 24,
            color: '#2A293A',
            fontWeight: 500,
            fontStyle: 'normal'
        },
        h4: {
            fontSize: 18,
            color: '#2A293A',
            fontWeight: 500,
            fontStyle: 'normal'
        },
        h5: {
            fontSize: 14,
            color: '#2A293A',
            fontWeight: 500,
            fontStyle: 'normal'
        },
        h6: {
            fontSize: 14,
            color: '#2A293A',
            fontWeight: 700,
            fontStyle: 'normal'
        },
        button: {
            color: '#78778B',
            fontWeight: 'normal',
            lineHeight: 1.5,
            fontSize: 14,
            fontStyle: 'normal',
            textTransform: 'normal'
        },
        body1: {
            fontWeight: 500,
            color: '#2A293A',
            fontSize: 14,
            fontStyle: 'normal',
            lineHeight: `1.375rem`
        },
        body2: {
            fontWeight: 'normal',
            color: '#2A293A',
            fontSize: 14,
            fontStyle: 'normal',
            lineHeight: `1.375rem`
        },
        caption: {
            fontSize: 13,
            color: '#ACADB9',
            fontWeight: 'normal',
            fontStyle: 'normal'
        },
        overline: {
            color: 'white',
            fontWeight: 500,
            lineHeight: `1.375rem`,
            fontSize: 13,
            fontStyle: 'normal',
            textTransform: 'normal'
        }
    },
    overrides: {
        MuiButton: {
            textPrimary: {},
            textSizeSmall: {},
            iconSizeSmall: {},
            outlinedSizeSmall: {},
            containedSizeSmall: {},
            sizeSmall: {}
        }
    },
    ellipsis: (line = 1) => ({
        WebkitLineClamp: line,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        wordBreak: 'break-all'
    })
};

export default theme;
