import React from 'react';

function MediumSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect x="1" y="1" width="16" height="16" rx="2" stroke={color} strokeWidth="1.5" />
            <path d="M1 9H17" stroke={color} strokeWidth="1.5" />
        </svg>
    );
}

export default React.memo(MediumSVG);
