import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import LimitSVG from 'assets/images/svg/payment/illusttration/Limit';
import ButtonBase from 'components/button/Base';
import UpgradeSVG from 'assets/images/svg/payment/UpgradeSVG';
import CloseIconSVG from 'assets/images/svg/CloseIconSVG';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 650,
        background: theme.colors.white,
        height: 300,
        borderRadius: 6
    },
    leftIcon: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    expand: {
        flex: 1
    },
    content: {
        padding: theme.spacing(5),
        position: 'relative'
    },
    box: {
        height: '100%'
    },
    message: {
        flex: 1,
        paddingTop: theme.spacing(3)
    },
    upgradeIcon: {
        marginRight: theme.spacing(1)
    },
    closeIcon: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

function LimitPopup({ title, message, className, onClose, onCancel, onUpgrade }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container wrap="nowrap" className={`${classes.root} ${className}`}>
            <Grid item className={classes.leftIcon}>
                <LimitSVG />
            </Grid>
            <Grid item className={`${classes.expand} ${classes.content}`}>
                {onClose && (
                    <span className={classes.closeIcon}>
                        <IconButton onClick={onClose} size="medium">
                            <CloseIconSVG size="medium" />
                        </IconButton>
                    </span>
                )}
                <Grid container className={classes.box} direction="column">
                    <Grid item>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item className={classes.message}>
                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message }}></Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justify="flex-end" spacing={2}>
                            {onCancel && (
                                <Grid item onClick={onCancel}>
                                    <ButtonBase width={120} variant="outlined">
                                        {t('global_cancel')}
                                    </ButtonBase>
                                </Grid>
                            )}
                            {onUpgrade && (
                                <Grid item onClick={onUpgrade}>
                                    <ButtonBase color="primary" width={120} variant="contained">
                                        <UpgradeSVG className={classes.upgradeIcon} />
                                        {t('global_upgrade')}
                                    </ButtonBase>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default React.memo(LimitPopup);
