import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import PathSVG from 'assets/images/svg/localization/PathSVG';
import LDBasePortal from 'components/selects/LDBasePortal';
import { useColumnsPermission } from 'hooks/gridUI';
import * as columnTypes from 'const/columnTypes';
import { ColumnIcon } from 'gridUI/ColumnTypeDisplay';
import RefreshSVG from 'assets/images/svg/RefreshSVG';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateActionNode } from 'gridUI/automations/action';
import Tooltip from 'components/tooltip/Base';
import ExclaimationMarkSVG from 'assets/images/svg/ExclaimationMarkSVG';
import { useGetAutomationDetail } from 'hooks/gridUI/automation';
import { ACTION_STATUS, showWatchingColumnsTriggers } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import { useEffect } from 'react';
import { SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG } from 'const';

const useStyles = makeStyles(theme => ({
    flex: { display: 'flex' },
    mr5: { marginRight: 5 },
    ml8: { marginLeft: 8 },
    mb12: { marginBottom: 12 },
    mb40: { marginBottom: 40 },
    midgrey: { color: theme.colors.midGrey },
    name: {
        background: theme.colors.paleGrey,
        borderRadius: 4,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        border: `1px solid ${theme.colors.disabledGrey}`,
        minHeight: 36
    },
    resetMapping: {
        width: 'fit-content',
        height: 36,
        padding: '0 9px 0 13px',
        border: `1px solid ${theme.colors.silver}`,
        borderRadius: 4,
        cursor: 'pointer'
    },
    rotateY180: {
        transform: 'rotateY(180deg)'
    },
    controlClassName: {
        borderColor: theme.colors.silver
    },
    exclaimationMark: {
        border: `1px solid #78778B`,
        borderRadius: `50%`
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: `none`
    }
}));

const MappingColumnItem = ({ label = '', selectedColumnOption, columnOptions, isLast, onChange, index }) => {
    const classes = useStyles();

    const handleChange = useCallback(
        option => {
            onChange({ option, index });
        },
        [onChange, index]
    );

    return (
        <Grid
            item
            container
            alignItems="center"
            wrap="nowrap"
            className={classnames({
                [classes.mb12]: !isLast
            })}
        >
            <Grid item container alignItems="center" xs={5} className={classes.name}>
                <p className="body2">{label}</p>
            </Grid>
            <Grid item container alignItems="center" justify="center" xs={2}>
                <PathSVG />
            </Grid>
            <Grid item xs={5}>
                <LDBasePortal
                    ddPlaceholder={'Select column'}
                    menuPlaceholder={'Find column'}
                    options={columnOptions}
                    controlClassName={classes.controlClassName}
                    onChange={handleChange}
                    defaultValue={selectedColumnOption}
                    isUsingContainer
                />
            </Grid>
        </Grid>
    );
};

const Postbacks = ({ selectedNode = {}, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [columnMapings, setColumnMapings] = useState([]);
    const timeout = useRef();
    const autoUpdateRef = useRef();
    const automationDetail = useGetAutomationDetail();
    const { params = {} } = selectedNode;
    const noneOption = { label: 'None', value: '' };

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useLayoutEffect(() => {
        const columnMap = params.postbackCellMap || {};
        const columnMapings = (params.outputFields || [])
            .filter(el => !!el)
            .map(field => ({
                outputKey: field,
                publicColumnId: columnMap?.[field]?.publicId
            }));
        setColumnMapings(columnMapings);
    }, [params.outputFields, params.postbackCellMap]);

    const watchingColumns = useMemo(() => {
        if (automationDetail?.triggerNodes?.length) {
            const arr = automationDetail.triggerNodes.reduce((acc, cur) => {
                if (cur?.watchingColumns?.length) {
                    acc = [...acc, ...cur.watchingColumns];
                }
                return acc;
            }, []);
            return [...new Set(arr)];
        }
        return [];
    }, [automationDetail.triggerNodes]);

    const columnsPermission = useColumnsPermission();

    const viewColumnOptions = useMemo(() => {
        return columnsPermission
            .filter(col => {
                return (
                    ![...SYSTEM_COLUMN_IDS_WITHOUT_PATH_TAG]?.includes(col.id) &&
                    ![columnTypes.FORMULA].includes(col.type) &&
                    !(
                        [columnTypes.REFERENCE].includes(col.type) &&
                        (col?.referenceType !== 'row' || !col?.primaryReference)
                    )
                );
            })
            .map(col => {
                let value = '';
                if (col.id === columnTypes.RECORD_ID) value = '_recordId';
                else if (col.id === columnTypes.PATH_TAG) value = '_path';
                else value = col.publicId;

                return {
                    ...col,
                    value,
                    label: col.name,
                    icon: () => (
                        <ColumnIcon group={col?.group} type={col?.type} customProperties={col?.customProperties} />
                    ),
                    options: null
                };
            });
    }, [columnsPermission]);

    const viewableColumnOptions = useMemo(() => {
        return viewColumnOptions.filter(option =>
            typeof option?.viewable === 'object' ? !!option?.viewable?.viewable : !!option?.viewable
        );
    }, [viewColumnOptions]);

    const filteredColumnOptions = useMemo(() => {
        const publicIds = columnMapings.map(output => output?.publicColumnId);
        return viewableColumnOptions.filter(option => {
            return ![...publicIds, ...watchingColumns].includes(option.value);
        });
    }, [viewableColumnOptions, columnMapings, watchingColumns]);

    const disabledPostback = useMemo(() => {
        if (
            automationDetail?.triggerNodes?.length &&
            automationDetail?.triggerNodes.some(
                trigger => showWatchingColumnsTriggers.includes(trigger.trigger) && trigger.watchingAll
            )
        ) {
            return true;
        }
        const fitlerOutWatchingColumns = viewableColumnOptions.filter(
            option => !watchingColumns?.includes(option.value)
        );
        if (watchingColumns?.length > 0 && !fitlerOutWatchingColumns?.length) {
            return true;
        }
        return false;
    }, [automationDetail.triggerNodes, viewableColumnOptions, watchingColumns]);

    const updatePostbackCellMap = useCallback(
        newColumnMapings => {
            setColumnMapings([...newColumnMapings]);
            const oldColumnMappings = [...columnMapings];
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                postbackCellMap: newColumnMapings.reduce((acc, cur) => {
                                    if (cur.publicColumnId) {
                                        acc[cur.outputKey] = cur.publicColumnId;
                                    }
                                    return acc;
                                }, {})
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {
                            setColumnMapings(oldColumnMappings);
                        }
                    })
                );
            }, 100);
        },
        [columnMapings, automationDetail.id, dispatch, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const onChangeMappingColumn = useCallback(
        ({ option, index }) => {
            columnMapings[index].publicColumnId = option.value;
            updatePostbackCellMap(columnMapings);
        },
        [columnMapings, updatePostbackCellMap]
    );

    const onResetMapping = useCallback(() => {
        const newColumnMapings = columnMapings.map(mapping => ({ ...mapping, publicColumnId: '' }));
        updatePostbackCellMap(newColumnMapings);
    }, [columnMapings, updatePostbackCellMap]);

    useLayoutEffect(() => {
        autoUpdateRef.current = false;
    }, [selectedNode.id]);

    useEffect(() => {
        if (!autoUpdateRef.current && columnMapings.length > 0) {
            const newColumnMapings = [];
            columnMapings.forEach(el => {
                if (el.publicColumnId && !viewableColumnOptions.find(option => option.value === el.publicColumnId)) {
                    autoUpdateRef.current = true;
                    el.publicColumnId = '';
                }
                newColumnMapings.push(el);
            });
            if (autoUpdateRef.current) {
                updatePostbackCellMap(newColumnMapings);
            }
        }
    }, [columnMapings, viewableColumnOptions, updatePostbackCellMap]);

    return (
        <Grid item container direction="column" wrap="nowrap">
            <Grid item container wrap="nowrap" className={classes.mb12}>
                <Grid item>
                    <p className="body1">{t('automation_postbacks')}</p>
                </Grid>
                {disabledPostback && (
                    <Tooltip title={t('automation_postback_hint')}>
                        <Grid item className={classes.ml8}>
                            <ExclaimationMarkSVG color="#78778B" bgColor="white" className={classes.exclaimationMark} />
                        </Grid>
                    </Tooltip>
                )}
            </Grid>
            <TooltipActionDisable isAction disabled={isDisabled}>
                <Grid
                    item
                    className={classnames({
                        [classes.disabled]: disabledPostback
                    })}
                >
                    <Grid
                        item
                        className={classnames({
                            [classes.mb12]: columnMapings.length > 0
                        })}
                    >
                        <p className="body2 text-grey-mid">
                            {columnMapings.length === 0
                                ? t('automation_no_output_field_for_mapping')
                                : t('automation_choose_your_column_for_mapping_data')}
                        </p>
                    </Grid>
                    {columnMapings.length > 0 && (
                        <>
                            <Grid item container direction="column" wrap="nowrap" className={classes.mb40}>
                                {columnMapings.map((el, idx) => (
                                    <MappingColumnItem
                                        key={idx}
                                        index={idx}
                                        isLast={idx === columnMapings.length - 1}
                                        label={el.outputKey}
                                        selectedColumnOption={
                                            viewableColumnOptions.find(option => option.value === el.publicColumnId) ||
                                            undefined
                                        }
                                        columnOptions={[noneOption, ...filteredColumnOptions]}
                                        onChange={onChangeMappingColumn}
                                    />
                                ))}
                            </Grid>
                            <Grid item container wrap="nowrap" justifyContent="flex-end">
                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    wrap="nowrap"
                                    className={classes.resetMapping}
                                    onClick={onResetMapping}
                                >
                                    <Grid item className={classnames(classes.flex, classes.mr5, classes.rotateY180)}>
                                        <RefreshSVG />
                                    </Grid>
                                    <Grid item>
                                        <p className="body1">{t('global_reset_mapping')}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </TooltipActionDisable>
        </Grid>
    );
};

export default React.memo(Postbacks);
