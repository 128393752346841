import { useSelector } from 'react-redux';

export function useBillingInfo() {
    return useSelector(state => state.payment?.billingInfo);
}

export function useBillingHistory() {
    return useSelector(state => state.payment?.billingHistory);
}

export function useReceiptInfo() {
    return useSelector(state => state.payment?.receiptInfo);
}

export function useTaxRate() {
    return useSelector(state => state.payment?.taxRate);
}

export function useIsTrialed() {
    return useSelector(state => state.payment?.customer?.trialed);
}
