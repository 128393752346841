import React from 'react';

function MemSourceSVG({ color = '#17B7FF', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M11.5852 0H4C4.20336 0.00462038 4.39988 0.0804814 4.56015 0.216234L6.64201 1.94805H11.5852C12.0142 1.94805 12.4256 2.13277 12.7289 2.46157C13.0323 2.79036 13.2027 3.23631 13.2027 3.7013V11.2987C13.2027 11.7637 13.0323 12.2097 12.7289 12.5385C12.4256 12.8672 12.0142 13.052 11.5852 13.052H10.1473C10.0838 13.052 10.0228 13.0793 9.9779 13.128C9.93292 13.1767 9.9077 13.2428 9.9077 13.3117V14.7403C9.9077 14.8091 9.93292 14.8752 9.9779 14.9239C10.0228 14.9726 10.0838 15 10.1473 15H11.5852C12.4909 15 13.3594 14.6101 13.9998 13.9159C14.6402 13.2218 15 12.2804 15 11.2987V3.7013C15 2.71966 14.6402 1.77821 13.9998 1.08409C13.3594 0.389958 12.4909 0 11.5852 0Z"
                fill="#101010"
            />
            <path
                d="M7.4556 17.797L3.7512 14.888C3.51753 14.7045 3.32824 14.4685 3.19798 14.1982C3.06772 13.9279 2.99998 13.6305 3 13.3291V0.980283C3.00038 0.797107 3.0508 0.617692 3.14557 0.462289C3.24033 0.306889 3.37567 0.181697 3.5363 0.100853C3.69693 0.0200087 3.87645 -0.013268 4.05459 0.00478084C4.23273 0.0228303 4.40238 0.0914859 4.5444 0.202995L8.2488 3.11139C8.48247 3.29486 8.67176 3.53088 8.80202 3.8012C8.93228 4.07152 9.00002 4.36887 9 4.67026V17.0173C9.00007 17.2006 8.94997 17.3804 8.85538 17.5361C8.7608 17.6919 8.62548 17.8175 8.46477 17.8986C8.30406 17.9798 8.12436 18.0132 7.94601 17.9953C7.76766 17.9773 7.59778 17.9086 7.4556 17.797Z"
                fill="#03EAB3"
            />
        </svg>
    );
}

export default React.memo(MemSourceSVG);
