import React from 'react';

function MailgunSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg {...other} xmlns="http://www.w3.org/2000/svg" width="210" height="80" viewBox="0 0 210 80">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h210v80H0z" />
                <g fillRule="nonzero">
                    <path
                        fill="#C02428"
                        d="M57.046 34.94c-2.9 0-5.347 2.185-5.347 4.958-.013 1.318.546 2.586 1.552 3.518 1.005.932 2.373 1.45 3.795 1.438 2.9 0 5.345-2.185 5.345-4.956 0-2.773-2.446-4.958-5.345-4.958zm-8.337 4.873c0-4.2 3.715-7.645 8.247-7.645 4.53 0 8.245 3.444 8.245 7.645 0 .252 0 .589-.09.84-.092.756.543 1.344 1.36 1.344 1.359 0 1.54-1.595 1.54-2.184 0-5.63-4.985-10.25-11.055-10.25-6.073 0-11.055 4.62-11.055 10.25s4.982 10.25 11.055 10.25c3.261 0 6.16-1.26 8.155-3.36l2.264 1.764c-2.446 2.69-6.252 4.37-10.33 4.37-3.73.01-7.31-1.358-9.946-3.803-2.637-2.446-4.112-5.765-4.099-9.222 0-7.14 6.252-13.021 14.046-13.021C64.747 26.79 71 32.672 71 39.814c0 2.856-1.45 5.21-4.44 5.21-1.269 0-2.084-.59-2.537-1.177-1.45 2.185-4.079 3.613-7.069 3.613-2.189.007-4.29-.797-5.839-2.232-1.548-1.435-2.414-3.383-2.407-5.413l.001-.002zm8.336-2.269c1.36 0 2.447 1.008 2.447 2.269 0 1.26-1.088 2.269-2.446 2.269a2.539 2.539 0 0 1-1.734-.662 2.179 2.179 0 0 1-.713-1.607c0-1.26 1.087-2.269 2.447-2.269h-.001z"
                    />
                    <path
                        fill="#66676B"
                        d="M74 35.117v13.67h3.392v-6.999c0-2.883.804-4.118 2.767-4.118 1.517 0 2.231 1.153 2.231 3.623v7.576h3.392V41.87c0-2.883.804-4.118 2.766-4.118 1.607 0 2.232.988 2.232 3.623v7.576h3.392v-8.646c0-3.706-1.696-5.6-4.998-5.6-1.786 0-3.303.741-4.374 1.977-.893-1.318-2.32-1.976-3.927-1.976-1.429 0-2.232.328-3.48 1.646v-1.235H74zm32.934 0v1.565c-1.516-1.4-2.766-1.976-4.64-1.976-4.017 0-6.963 3.046-6.963 7.081 0 4.283 2.946 7.33 7.052 7.33 1.785 0 2.946-.494 4.551-1.811v1.399h3.393V35.117h-3.393zm-4.016 2.387c2.5 0 4.195 1.813 4.195 4.366 0 2.635-1.695 4.446-4.195 4.446-2.409 0-4.105-1.811-4.105-4.446 0-2.471 1.785-4.365 4.105-4.365zm13.567-2.387h-3.392v13.67h3.392v-13.67zm-1.697-7.659a2.32 2.32 0 0 0-1.586.6 1.95 1.95 0 0 0-.645 1.459c0 1.153.982 2.06 2.232 2.06a2.32 2.32 0 0 0 1.588-.6 1.95 1.95 0 0 0 .644-1.46 1.95 1.95 0 0 0-.645-1.46 2.32 2.32 0 0 0-1.587-.599zm4.82-3.458H123v24.705h-3.391V24z"
                    />
                    <path
                        fill="#C02729"
                        d="M139.784 35.571h-3.344v1.552c-1.144-1.226-2.815-1.879-4.576-1.879-3.96 0-6.864 3.021-6.864 7.268 0 4.083 2.905 7.024 6.776 7.024 1.848 0 3.256-.572 4.576-1.96v1.877c0 2.86-1.232 4.247-3.696 4.247-1.32 0-2.64-.571-3.256-1.551-.352-.572-.44-1.062-.528-2.205h-3.256c0 4.082 2.728 6.614 7.04 6.614 2.464 0 4.664-.898 5.897-2.613.967-1.307 1.23-2.45 1.23-5.146V35.571zm-7.304 2.367c2.464 0 4.048 1.798 4.048 4.33 0 2.612-1.672 4.409-4.048 4.409-2.464 0-4.048-1.715-4.048-4.328 0-2.613 1.584-4.41 4.048-4.41zm12.672-2.367h-3.344v7.84c0 2.122.44 3.266 1.496 4.41 1.145 1.143 2.816 1.715 4.664 1.715 1.937 0 3.52-.572 4.752-1.716 1.144-1.143 1.496-2.287 1.496-4.409v-7.84h-3.344v7.758c0 2.287-.88 3.348-2.904 3.348-1.936 0-2.816-1.061-2.816-3.348V35.57zm11.352 0v13.556h3.344v-6.534c0-3.348.793-4.572 3.08-4.572 2.112 0 2.727.898 2.727 3.511v7.595H169v-8.33c0-3.756-1.672-5.634-5.28-5.634-1.584 0-2.64.408-3.96 1.633V35.57h-3.256z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default React.memo(MailgunSVG);
