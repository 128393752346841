import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ApplyIconSVG from 'assets/images/svg/ApplyIconSVG';
import Tooltip from 'components/tooltip/Base';
import { useTranslation } from 'react-i18next';
import { DISABLED_OPACITY } from 'const/style';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'auto'
    },
    tbItem: {
        padding: '8px 12px',
        '&:hover': {
            background: theme.colors.background,
            '& .apply-icon': {
                opacity: 1,
                cursor: 'pointer'
            }
        }
    },
    tag: {
        padding: `1px 4px`,
        borderRadius: 2,
        background: theme.colors.token,
        color: theme.colors.white,
        fontWeight: 500
    },
    disabled: {
        pointerEvents: 'none',
        opacity: DISABLED_OPACITY
    }
}));

function MissingTag({ texts, value, onChange, disabled }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item container direction="column">
                {texts?.length === 0 && !value && (
                    <Grid item style={{ width: '100%', paddingLeft: 20, paddingTop: 20 }} container alignItems="center">
                        <p className="body2 text-center text-grey-blue">{t(`cell_edit_tm_no_suggestion`)}</p>
                    </Grid>
                )}
                {(texts || []).map((text, idx) => (
                    <Grid
                        key={idx}
                        item
                        className={`${classes.tbItem} ${disabled && classes.disabled}`}
                        onDoubleClick={() => onChange(text)}
                    >
                        <Grid container alignItems="center" direction="row" justify="space-between" wrap="nowrap">
                            <Grid item>
                                <p className={'body2 py-[1px] px-1 rounded bg-token text-white font-medium'}>{text}</p>
                            </Grid>
                            <Tooltip title="Apply this tag">
                                <Grid item className={`${classes.applyIcon} apply-icon`}>
                                    <ApplyIconSVG
                                        width="20"
                                        height="20"
                                        style={{ verticalAlign: 'text-bottom' }}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            onChange(text);
                                        }}
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default MissingTag;
