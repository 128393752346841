import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import PlanBenefitItem from 'payment/components/PlanBenefitItem';
import Button from 'components/button/Base';
import { capitalize } from 'lodash';
import { PLAN_TYPES } from 'const';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        width: 290,
        boxShadow: theme.shadows[1],
        borderRadius: 6,
        padding: theme.spacing(4),
        background: theme.colors.white,
        position: 'relative',
        borderColor: plan => (plan.type === PLAN_TYPES.PROFESSIONAL ? theme.palette.primary.main : 'transparent'),
        borderStyle: 'solid',
        borderWidth: 2
    },
    triangle: {
        position: 'absolute',
        top: 0,
        left: 85,
        transform: 'translate(-50%, -100%)',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 0 18px 18px',
        borderColor: 'transparent transparent #2268B8 transparent'
    },
    mostPopular: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.colors.highlight,
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: theme.spacing(2),
        fontWeight: 500,
        color: theme.colors.white
    },
    header: {
        marginBottom: theme.spacing(3)
    },
    planIcon: {
        display: 'flex',
        marginRight: theme.spacing(2)
    },
    price: {
        marginBottom: theme.spacing(3),
        height: 30
    },
    benefitList: {
        minHeight: 90,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    benefitItem: {
        marginBottom: theme.spacing(2)
    },
    buttonItem: {
        margin: 'auto'
    },
    boughtButton: {
        '&.MuiButton-root': {
            border: `1px solid ${theme.colors.silver}`,
            color: theme.colors.midGrey
        }
    },
    greyText: { color: theme.colors.midGrey }
}));

function PlanCard({ plan, isDisabled, onSelect, isSubmitting }) {
    const classes = useStyles(plan);
    const { t } = useTranslation();

    React.useEffect(() => {});

    return (
        <Grid container className={classes.root} direction="column" wrap="nowrap">
            {plan.type === PLAN_TYPES.PROFESSIONAL && (
                <>
                    <div className={classes.triangle}></div>
                    <div className={classes.mostPopular}>Most popular</div>
                </>
            )}
            <Grid item container alignItems="center" justifyContent="center" className={classes.header}>
                <Grid item className={classes.planIcon}>
                    {plan.icon}
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        {capitalize(plan.type === PLAN_TYPES.PROFESSIONAL ? 'Pro' : plan.type)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item style={{ height: 60 }}>
                <Typography variant="body2" align="center" className={classes.greyText}>
                    {plan.description}
                </Typography>
            </Grid>
            <Grid item className={classes.price}>
                {plan.priceText}
            </Grid>
            <Grid item container style={{ minHeight: 50 }} alignItems="center" justifyContent="center">
                {plan.seat}
            </Grid>
            <Grid item container direction="column" className={classes.benefitList}>
                {plan.benefits?.map((benefit, index) => (
                    <Grid item key={index} className={classes.benefitItem}>
                        <PlanBenefitItem description={benefit} />
                    </Grid>
                ))}
            </Grid>
            <Grid item className={classes.buttonItem}>
                {plan.type === PLAN_TYPES.ENTERPRISE ? (
                    <a
                        href="https://www.gridly.com/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-contained w-[240px]"
                    >
                        {t('global_contact_sales')}
                    </a>
                ) : (
                    <Button width={240} disabled={isDisabled || isSubmitting} variant="contained" onClick={onSelect}>
                        {isSubmitting ? <CircularProgress size={24} /> : t('global_select_plan')}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

export default React.memo(PlanCard);
