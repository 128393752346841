import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Avatar from 'components/avatar/Base';
import { useMemberMappingById } from 'hooks/permission/member';
import { getFriendlyDateTimeFromUnix } from 'utils/datetime';
import ChipStatus from 'components/chipStatus/ChipStatus';
import { UpperCaseFirstCharacter } from 'utils/name';
import { SOURCE_STATUS, BATCH_STATUS } from 'const';
import ArrowDownSVG from 'assets/images/svg/ArrowDownSVG';
import { getAvatarUrl } from 'utils/images';
import { useConnectionsByDestinationGridId } from 'hooks/integration';
import { useDispatch } from 'react-redux';
import { useSyncHistoriesByDestinationId } from 'hooks/integration';
import * as integrationActions from 'integrations/actions';
import { useCompanyId } from 'hooks/auth';
import * as memberActions from 'permission/actions/member';
import { getBatchesApi } from 'services/workflow';
import moment from 'moment';
import NotFoundBoxSVG from 'assets/images/svg/NotFoundBoxSVG';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(3)
    },
    link: {
        '& p': {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        },
        '& svg path': {
            fill: theme.palette.primary.main
        }
    },
    item: {
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(3),
            borderBottom: `1px solid ${theme.colors.border}`,
            marginBottom: theme.spacing(3)
        }
    },
    reset: {
        margin: 0,
        fontFamily: 'Roboto Mono, monospace !important',
        '& *': {
            fontFamily: 'Roboto Mono, monospace !important'
        },
        '& p': {
            margin: 0
        }
    },
    notFound: {
        fontSize: 24,
        fontWeight: '400',
        marginTop: 24
    }
}));

function SyncHistory({ branchId }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const companyId = useCompanyId();
    const memberMappingById = useMemberMappingById();
    const data = useConnectionsByDestinationGridId(branchId);

    const dId = data[0]?.destinationGridId;
    const syncHistoriesByDestinationId = useSyncHistoriesByDestinationId(dId);

    React.useEffect(() => {
        dispatch(memberActions.fetchMembers({ companyId }));
    }, [companyId, dispatch]);

    React.useEffect(() => {
        dispatch(
            integrationActions.getSyncHistoriesByDestinationId({
                dId: dId,
                successCallback: () => {
                    console.log('getConnectionsByDestinationGridId success');
                },
                errorCallback: () => {
                    console.log('getConnectionsByDestinationGridId failed');
                }
            })
        );
    }, [dispatch, dId]);

    function History({ history, user, status }) {
        const [historyId, setHistoryId] = React.useState();
        const [isShowViewDetail, setIsShowViewDetail] = React.useState(false);
        const [detail, setDetail] = React.useState();

        const handleViewDetail = React.useCallback(
            id => {
                setHistoryId(id);
                setIsShowViewDetail(!isShowViewDetail);
            },
            [isShowViewDetail]
        );
        React.useEffect(() => {
            const fetchTaskDetail = async () => {
                try {
                    const batches = await getBatchesApi(history?.id);
                    setDetail({
                        ...detail,
                        [history.id]: batches
                    });
                } catch (error) {
                    console.log(error);
                }
            };
            if (!detail) {
                fetchTaskDetail();
            }
        }, [history, detail]);

        return (
            <>
                <Grid key={history?.id} className={classes.item} item>
                    <Grid container spacing={1} wrap="nowrap" direction="row">
                        <Grid item>
                            <Avatar src={getAvatarUrl(user?.imageUrl)} alt={user?.fullName} />
                        </Grid>
                        <Grid item container spacing={1} direction="column">
                            <Grid item>
                                <p className="body2 inline">
                                    <Trans
                                        t={t}
                                        i18nKey="connection_synced_this_grid"
                                        values={{ username: user?.fullName, connectionName: history?.connectionName }}
                                        components={{
                                            bold: <p className="body1 inline" />
                                        }}
                                    />
                                </p>
                            </Grid>
                            <Grid item>
                                <p className="caption">{getFriendlyDateTimeFromUnix(history?.createdAt)}</p>
                            </Grid>
                            <Grid item>
                                <ChipStatus label={UpperCaseFirstCharacter(status)} status={status} />
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    className={classes.link}
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                    onClick={() => {
                                        setHistoryId(history.id);
                                        handleViewDetail(history.id);
                                        // fetchDetail(history.id);
                                    }}
                                >
                                    <Grid item>
                                        <p className="body1">{t('view_details')}</p>
                                    </Grid>
                                    <Grid item style={{ display: 'flex' }}>
                                        <ArrowDownSVG />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {isShowViewDetail && historyId === history.id && (
                                <DetailHistory detail={detail?.[history.id]} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    function DetailHistory({ detail }) {
        return (
            <>
                <Grid item container direction="column" spacing={1}>
                    {detail?.map((log, index) => {
                        return (
                            <Grid key={index} item container direction="column" spacing={2}>
                                <Grid item>
                                    <pre className={classes.reset}>
                                        [{BATCH_STATUS?.[log?.status]}]{' '}
                                        {moment(log?.receivingTime).format('YYYY-MM-DD HH:mm:ss')}
                                        <br />
                                        {log?.status === 'FAILED' ? (
                                            <p>
                                                Message: <strong>{log?.failedMessage}</strong>
                                            </p>
                                        ) : (
                                            ``
                                        )}
                                        Emit records: <strong>{log?.emittedRecordsCount}</strong>
                                        <br />
                                        Commit records: <strong>{log?.committedRecordsCount}</strong>
                                    </pre>
                                </Grid>
                                <Grid item>
                                    <p className={classNames(`body2 m-0`, classes.reset)}>
                                        --------------------------------
                                    </p>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    }

    return (
        <Grid container direction="column" className={classes.root}>
            {dId ? (
                syncHistoriesByDestinationId?.map(history => {
                    const user = memberMappingById?.[history?.createdBy];
                    const status = history?.status === SOURCE_STATUS.SUCCEEDED ? 'success' : history?.status;
                    return <History key={history.id} history={history} status={status} user={user} />;
                })
            ) : (
                <Grid
                    className={classes.root}
                    direction="column"
                    container
                    item
                    alignItems={'center'}
                    justify={'center'}
                >
                    <NotFoundBoxSVG />
                    <p className={'prose prose-2xl font-normal mt-6'}>No sync history found</p>
                    <p className={'body2'}>Sync history will be displayed here once it was executed</p>
                </Grid>
            )}
        </Grid>
    );
}

export default SyncHistory;
