import React, { useRef, useState, useMemo } from 'react';
import classnames from 'classnames';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import QuestionSVG from 'assets/images/svg/QuestionSVG';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { WEB_HOOK_EXTERNAL_SYSTEMS } from 'const';
import { useDispatch } from 'react-redux';
import { updateActionNode, updateAutomationDetailErrorWithKey } from 'gridUI/automations/action';
import { ACTION_STATUS } from 'gridUI/automations/const';
import { useEffect } from 'react';
import TooltipActionDisable from '../TooltipActionDisable';
import EditorPayload from '../suggestion/EditorPayload';
import IOSSwitch from 'components/switches/IOS';
import ConfirmBox from 'components/confirmBox/Base';
import Dialog from 'components/dialog/Dialog';
import { generateAutomationActionNodeDefaultParams } from 'const/automation';
import IconCopySVG from 'assets/images/svg/IconCopySVG';
import Tooltip from 'components/tooltip/Base';
import * as clipboardy from 'clipboardy';
import { enqueueSnackbar } from 'notifier/actions';

const useStyles = makeStyles(theme => ({
    flex: { display: 'flex' },
    mt12: { marginTop: 12 },
    ml12: { marginLeft: 12 },
    mb14: { marginBottom: 14 },
    mb24: { marginBottom: 24 },
    suggestionBoxContainer: {
        borderRadius: 4
    },
    textareaClassName: {
        '&::placeholder': {
            color: `#ACADB9`
        }
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'unset'
        },
        '& .MuiDialogContent-root': {
            overflowY: 'unset'
        }
    },
    howToCustomize: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: `unset !important`
    },
    required: {
        color: theme.colors.brightRed
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    editorError: {
        borderColor: theme.colors.brightRed
    },
    editorWrapper: {
        position: 'relative'
    },
    copyIcon: {
        position: 'absolute',
        right: 13,
        bottom: 5,
        cursor: 'pointer'
    }
}));

const ADVANCED_ACTIONS = [
    WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_TRANSLATE,
    WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TRANSLATE,
    WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH,
    WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_TEXT_TO_SPEECH_NEURAL,
    WEB_HOOK_EXTERNAL_SYSTEMS.AMAZON_COMPREHEND,
    WEB_HOOK_EXTERNAL_SYSTEMS.JIRA,
    WEB_HOOK_EXTERNAL_SYSTEMS.GOOGLE_OCR,
    WEB_HOOK_EXTERNAL_SYSTEMS.SLACK
];

const Payloadv2 = ({
    label,
    hideLabel,
    placeholder,
    editorClassName,
    selectedNode = {},
    setSelectedNode,
    showHowToCustomize,
    isRequired,
    messageField = 'message'
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const automationDetail = useGetAutomationDetail();
    const nodeError = useGetAutomationDetailError(selectedNode.id);
    const { app, params = {} } = selectedNode;
    const defaultAdvancedMode = !ADVANCED_ACTIONS.includes(app);
    const [simpleMessage, setSimpleMessage] = useState('');
    const [message, setMessage] = useState(params[messageField]);
    const [advancedMode, setAdvancedMode] = useState(defaultAdvancedMode);
    const [openWarningFormat, setOpenWarningFormat] = useState(false);
    const [showEditor, setShowEditor] = useState(true);
    const timeout = useRef();
    const editorRef = useRef();

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    const errorMessage = nodeError?.[messageField] || '';

    const resetEditor = useCallback(() => {
        setShowEditor(false);
        setTimeout(() => {
            setShowEditor(true);
        }, 100);
    }, []);

    useEffect(() => {
        setMessage(params[messageField]);
        setAdvancedMode(defaultAdvancedMode);
        if (app === WEB_HOOK_EXTERNAL_SYSTEMS.SLACK) {
            try {
                const json = JSON.parse(params[messageField]);
                setSimpleMessage(json.text || '');
            } catch (e) {
                console.log(e);
            }
        } else if (ADVANCED_ACTIONS.includes(app)) {
            setSimpleMessage(params[messageField]);
        }
        resetEditor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id, automationDetail.triggerNodes.length]);

    const onBlur = useCallback(
        ({ text }) => {
            if (!isRequired) return;
            dispatch(
                updateAutomationDetailErrorWithKey({
                    key: selectedNode.id,
                    newValue: {
                        [messageField]: !text ? t('automation_field_required') : undefined
                    }
                })
            );
        },
        [dispatch, isRequired, selectedNode.id, t, messageField]
    );

    const onValueChange = useCallback(
        value => {
            if (value === message) return;
            setMessage(value);
            onBlur({ text: value });
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                // const message = getServerMessage(
                //     value,
                //     automationDetail.triggerNodes || [],
                //     automationDetail.nodes || []
                // );
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                [messageField]: value
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                        },
                        errorCallback: () => {}
                    })
                );
            }, 800);
        },
        [
            message,
            onBlur,
            automationDetail.id,
            dispatch,
            selectedNode.id,
            selectedNode.params,
            messageField,
            setSelectedNode
        ]
    );

    const onSimpleValueChange = useCallback(
        value => {
            if (value === simpleMessage) return;
            setSimpleMessage(value);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                if (WEB_HOOK_EXTERNAL_SYSTEMS.SLACK === app) {
                    try {
                        const json = JSON.parse(message);
                        if (json.text !== value) {
                            json.text = value;
                            const newMessage = JSON.stringify(json, null, 2);
                            setMessage(newMessage);
                            dispatch(
                                updateActionNode({
                                    automationId: automationDetail.id,
                                    nodeId: selectedNode.id,
                                    body: {
                                        params: {
                                            ...selectedNode.params,
                                            [messageField]: newMessage
                                        }
                                    },
                                    successCallback: responseData => {
                                        setSelectedNode(responseData);
                                    },
                                    errorCallback: () => {}
                                })
                            );
                        }
                    } catch (e) {
                        console.log('onSimpleValueChange error: ' + e.message);
                    }
                } else {
                    dispatch(
                        updateActionNode({
                            automationId: automationDetail.id,
                            nodeId: selectedNode.id,
                            body: {
                                params: {
                                    ...selectedNode.params,
                                    [messageField]: value
                                }
                            },
                            successCallback: responseData => {
                                setSelectedNode(responseData);
                            },
                            errorCallback: () => {}
                        })
                    );
                }
            }, 800);
        },
        [
            simpleMessage,
            message,
            automationDetail.id,
            dispatch,
            selectedNode.id,
            selectedNode.params,
            messageField,
            app,
            setSelectedNode
        ]
    );

    const onAdvanceModeChange = useCallback(
        (e, value) => {
            if (app === WEB_HOOK_EXTERNAL_SYSTEMS.SLACK) {
                if (!value) {
                    try {
                        const json = JSON.parse(message);
                        setSimpleMessage(json.text || '');
                        setAdvancedMode(value);
                        editorRef.current.reset({ text: json.text || '' });
                    } catch (error) {
                        setOpenWarningFormat(true);
                        return;
                    }
                } else {
                    try {
                        const json = JSON.parse(message);
                        if (json.text !== simpleMessage) {
                            json.text = simpleMessage;
                            const newMessage = JSON.stringify(json, null, 2);
                            onValueChange(newMessage);
                            editorRef.current.reset({ text: newMessage });
                        } else {
                            editorRef.current.reset({ text: message });
                        }
                        setAdvancedMode(value);
                    } catch (error) {
                        setOpenWarningFormat(true);
                        return;
                    }
                }
            } else {
                setSimpleMessage(message);
                setMessage(message);
                setAdvancedMode(value);
            }
        },
        [app, message, simpleMessage, onValueChange]
    );

    const handleCloseOpenWarningFormat = useCallback(e => {
        setOpenWarningFormat(false);
    }, []);

    const handleResetPayload = useCallback(
        e => {
            handleCloseOpenWarningFormat();
            const defaultActionParams = generateAutomationActionNodeDefaultParams({ app });
            let resetMessage = defaultActionParams[messageField];
            if (app === WEB_HOOK_EXTERNAL_SYSTEMS.SLACK) {
                if (!advancedMode) {
                    if (!simpleMessage) {
                        try {
                            const json = JSON.parse(resetMessage);
                            setSimpleMessage(json.text);
                        } catch (error) {
                            console.log('handleResetPayload error', error);
                        }
                    }
                }
            }
            onValueChange(resetMessage);
            resetEditor();
        },
        [handleCloseOpenWarningFormat, app, messageField, onValueChange, resetEditor, advancedMode, simpleMessage]
    );

    const copy = useCallback(async () => {
        try {
            await clipboardy.write(advancedMode ? message : simpleMessage);
            dispatch(
                enqueueSnackbar({
                    message: `Copied`,
                    type: 'info'
                })
            );
        } catch (error) {
            dispatch(
                enqueueSnackbar({
                    message: error?.message,
                    type: 'error'
                })
            );
        }
    }, [advancedMode, dispatch, message, simpleMessage]);

    return (
        <Grid item container direction="column">
            {ADVANCED_ACTIONS.includes(app) && (
                <Grid item className={classes.mb24}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <IOSSwitch checked={advancedMode} onChange={onAdvanceModeChange} />
                        </Grid>
                        <Grid item>
                            <p className="body2">Show all raw Parameters</p>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {!hideLabel && (
                <Grid item className={classes.mb14}>
                    <p className="body1">
                        {label || t('global_payload')}
                        {isRequired && <span className={classes.required}>*</span>}
                    </p>
                </Grid>
            )}
            <TooltipActionDisable isAction disabled={isDisabled}>
                <Grid
                    item
                    className={classnames({
                        [classes.mb14]: !!showHowToCustomize
                    })}
                >
                    {showEditor && (
                        <>
                            <Grid item className={classes.editorWrapper}>
                                <EditorPayload
                                    ref={editorRef}
                                    className={classnames(editorClassName, {
                                        [classes.editorError]: !!errorMessage
                                    })}
                                    text={advancedMode ? message || '' : simpleMessage || ''}
                                    onChange={advancedMode ? onValueChange : onSimpleValueChange}
                                    placeholder={
                                        advancedMode ? placeholder || 'Input your payload' : 'Input your message'
                                    }
                                    onBlur={onBlur}
                                    selectedNodeOrder={selectedNode.order}
                                    advancedMode={advancedMode}
                                />
                                <Grid item className={classes.copyIcon}>
                                    <Tooltip title="Copy all">
                                        <Grid item>
                                            <IconCopySVG onClick={copy} />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            {errorMessage && (
                                <Grid item className={classes.mt12}>
                                    <p className="text-error">{errorMessage}</p>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </TooltipActionDisable>
            {showHowToCustomize && (
                <Grid item container wrap="nowrap" alignItems="center" className={classes.mb14}>
                    <Grid item className={classes.flex}>
                        <QuestionSVG />
                    </Grid>
                    <Grid item style={{ marginLeft: 4 }}>
                        <a
                            target="_blank"
                            href="https://app.slack.com/block-kit-builder"
                            className={classes.howToCustomize}
                            rel="noopener noreferrer"
                        >
                            {t('global_how_to_customize_payload')}
                        </a>
                    </Grid>
                </Grid>
            )}
            {app === WEB_HOOK_EXTERNAL_SYSTEMS.HTTP && (
                <Grid item>
                    <Trans
                        t={t}
                        i18nKey="web_request_note"
                        defaults="<bold>Note:</bold> <normal>If your webhook endpoint is behind a firewall/private network, you need
                                to whitelist our IP address:</normal> <bold>18.192.141.172/32</bold>"
                        components={{
                            normal: <p className="body2 inline" />,
                            bold: <p className="body1 inline" />
                        }}
                    />
                </Grid>
            )}
            {ADVANCED_ACTIONS.includes(app) && (
                <Dialog open={openWarningFormat} onClose={handleCloseOpenWarningFormat}>
                    <ConfirmBox
                        title={'Payload is not well forrmatted'}
                        body={
                            <p className="body2">
                                Your payload is not correct.
                                <br />
                                Please check your payload in advanced mode
                                <br />
                                Or reset the payload (your changes on payload will be discard)
                                <br />
                            </p>
                        }
                        handleCancel={handleResetPayload}
                        onClose={handleCloseOpenWarningFormat}
                        handleAgreed={handleCloseOpenWarningFormat}
                        agreeLabel="Edit Payload"
                        disagreeLabel="Reset"
                    />
                </Dialog>
            )}
        </Grid>
    );
};

export default React.memo(Payloadv2);
