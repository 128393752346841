import React from 'react';
import { Grid } from '@material-ui/core';

import MemberItem from './MemberItem';

function MemberList({ members, onRoleChange }) {
    const [isScrolling, setIsScrolling] = React.useState(false);
    const timerRef = React.useRef(null);

    React.useEffect(() => {
        const target = document.getElementById('memberList');
        if (!target) return;
        target.addEventListener('mousewheel', handleScroll);
        target.addEventListener('DOMMouseScroll', handleScroll);
        target.addEventListener('scroll', handleScroll);
        return () => {
            target.removeEventListener('mousewheel', handleScroll);
            target.removeEventListener('DOMMouseScroll', handleScroll);
            target.removeEventListener('scroll', handleScroll);
        };
    });

    function handleScroll() {
        setIsScrolling(true);
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setIsScrolling(false);
        }, 100);
    }

    return (
        <Grid container spacing={3} direction="column" wrap="nowrap" id="memberList">
            {members &&
                members.map(member => (
                    <MemberItem
                        key={member.user.id}
                        member={member}
                        isScrolling={isScrolling}
                        onRoleChange={onRoleChange}
                    />
                ))}
        </Grid>
    );
}

export default React.memo(MemberList);
