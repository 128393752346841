import React from 'react';

function DoNotTranslateSVG({ color = '#93929E', ...rest }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 15.4286C12.5504 15.4286 15.4286 12.5504 15.4286 9C15.4286 5.4496 12.5504 2.57143 9 2.57143C5.4496 2.57143 2.57143 5.4496 2.57143 9C2.57143 12.5504 5.4496 15.4286 9 15.4286ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                fill={color}
            />
            <path d="M2.62289 11.3154L14.5008 3.80959L16.2866 6.63551L4.40864 14.1413L2.62289 11.3154Z" fill={color} />
        </svg>
    );
}

export default React.memo(DoNotTranslateSVG);
