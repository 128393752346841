import React from 'react';
import { Grid } from '@material-ui/core';
import GroupItem from './GroupItem';
import { useCanViewGroupShare } from 'hooks/payment';

function GroupList({ level, groups, selectedGroupId, onGroupSelect, onRemoveGroup }) {
    const canViewGroupShare = useCanViewGroupShare();

    return (
        <Grid container>
            {groups &&
                groups.map(group => (
                    <GroupItem
                        level={level}
                        key={group?.id}
                        group={group}
                        disabled={!canViewGroupShare && !group?.systemGroup}
                        selectedGroupId={selectedGroupId}
                        onGroupSelect={onGroupSelect}
                        onRemoveGroup={onRemoveGroup}
                    />
                ))}
        </Grid>
    );
}

export default React.memo(GroupList);
