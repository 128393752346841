import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from 'components/button/Base';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 32,
        width: 440,
        background: theme.colors.white
    }
}));

function ConfirmDeleteRoleBox({ roleName = 'Empty Role Name', handleCancel, handleAgreed }) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container wrap="nowrap" direction="column" spacing={4} className={classes.root}>
            <Grid item>
                <Typography variant="h4">Delete {roleName}</Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Typography variant="body1">What happens when you delete a role?</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">• Members with this role will be changed to</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">• This action cannot be undone</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid wrap="nowrap" container direction="row" alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <ButtonBase onClick={handleCancel} width={100} variant="outlined">
                            {t('global_cancel')}
                        </ButtonBase>
                    </Grid>
                    <Grid item>
                        <ButtonBase onClick={handleAgreed} width={100} variant="contained" color={'secondary'}>
                            Delete
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default React.memo(ConfirmDeleteRoleBox);
