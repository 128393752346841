import React from 'react';

function IconLockSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                fill={color}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.76215 5.24332C5.76215 4.04031 6.60697 3.20015 7.81654 3.20015C9.02611 3.20015 9.87085 4.04031 9.87085 5.24332V6.12843H11.2569V5.24332C11.2569 3.39435 9.77786 2 7.81654 2C5.85521 2 4.37614 3.39435 4.37614 5.24332V6.12843H5.76215V5.24332ZM4.8486 6.95412H11.0596C11.5499 6.95425 12.02 7.15321 12.3666 7.50724C12.7133 7.86127 12.9081 8.3414 12.9082 8.84207V12.112C12.9081 12.6127 12.7133 13.0928 12.3666 13.4469C12.02 13.8009 11.5499 13.9998 11.0596 14H4.8486C4.35836 13.9998 3.88824 13.8009 3.54159 13.4469C3.19494 13.0928 3.00014 12.6127 3 12.112V8.84207C3.00014 8.3414 3.19494 7.86127 3.54159 7.50724C3.88824 7.15321 4.35836 6.95425 4.8486 6.95412ZM5.12131 8.27518C4.67949 8.27518 4.32131 8.63335 4.32131 9.07518V11.8788C4.32131 12.3207 4.67949 12.6788 5.12131 12.6788H10.7874C11.2292 12.6788 11.5874 12.3207 11.5874 11.8788V9.07518C11.5874 8.63335 11.2292 8.27518 10.7874 8.27518H5.12131ZM8.32756 10.5929V11.7356C8.32756 11.7872 8.3174 11.8382 8.29766 11.8859C8.27792 11.9335 8.24898 11.9768 8.21251 12.0133C8.17604 12.0498 8.13274 12.0787 8.08508 12.0985C8.03743 12.1182 7.98635 12.1284 7.93477 12.1284C7.88319 12.1284 7.83211 12.1182 7.78445 12.0985C7.7368 12.0787 7.6935 12.0498 7.65703 12.0133C7.62055 11.9768 7.59162 11.9335 7.57188 11.8859C7.55214 11.8382 7.54198 11.7872 7.54198 11.7356V10.5929C7.35087 10.5035 7.19594 10.3517 7.10278 10.1624C7.00962 9.97307 6.9838 9.75767 7.02957 9.55172C7.07534 9.34577 7.18998 9.16158 7.35455 9.02957C7.51912 8.89756 7.72379 8.82562 7.93477 8.82562C8.14574 8.82562 8.35041 8.89756 8.51498 9.02957C8.67956 9.16158 8.79419 9.34577 8.83997 9.55172C8.88574 9.75767 8.85991 9.97307 8.76676 10.1624C8.67359 10.3517 8.51867 10.5035 8.32756 10.5929Z"
            />
        </svg>
    );
}

export default React.memo(IconLockSVG);
