export const SELECTION_LIST_MARGIN_TOP = 8;
export const SELECTION_LIST_ITEM_MIN_HEIGHT = 36;
export const SELECTION_LIST_ITEM_PADDING_LEFT_RIGHT = 20;
export const SELECTION_LIST_ITEM_PADDING_TOP_BOTTOM = 4;
export const SPACING_LIST_NAME_WITH_ICON = 8;
export const SPACING_LIST_NAME_WITH_DOTS_ICON = 4;
export const ICON_SIZE_IN_GRID_UI = 18;

export const OPTION_SPACING = 5;
//POPUP
export const POPUP_RADIUS = 6;
export const POPUP_PADDING_TOP_BOTTOM = 8;
export const POPUP_MARGIN_TOP = 5;

//INPUT
export const INPUT_RADIUS = 4;
export const INPUT_SPACING = 8;
export const INPUT_PADDING_LEFT = 14;
export const INPUT_HEIGHT = 36;
export const INPUT_QUICK_FILTER_HEIGHT = 26;
export const INPUT_BOX_RADIUS = 6;

export const WORK_SPACE_HEIGHT = 42;

export const CELL_COMMENT_HEIGHT = 500;
export const COMMENT_HEADER_HEIGHT = 48;
export const COMMENT_CHAT = 85;

//RECORD HISTORY
export const RECORD_HISTORY = 480;
export const DISABLED_OPACITY = 0.4;
export const DEFAULT_FIRST_TABLE_COLUMN_WIDTH = 10;

//TAG
export const TAG_HEIGHT = 24;

//TAG
export const USAGE_HEIGHT = 48;
export const USAGE_BOX = 72;

//Page
export const PAGE_PADDING = 32;

export const SHARED_STYLES = {
    settingForm: {
        maxWidth: 500
    }
};

export const LIST_MENU_MAX_HEIGHT = '60vh';
