import React from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import * as gridActions from 'gridUI/actions';
import ViewCellOnly from './ViewCellOnly';
import { Paper, Grid, Popper } from '@material-ui/core';
import { checkContainId } from 'utils/clickAway';
import ViewCellInfo from './ViewCellInfo';
import { isKbEscape, isKbTab, isKbShiftTab } from 'utils/keyboard';
import useClickAwaitListener from 'hooks/useClickAwaitListener';
import FolderPath from 'components/folderPath/Base';
import { usePathTagTree } from 'hooks/gridUI';
import { generatePath } from 'utils/gridUI/pathTag';
import { getCellBackgroundByHex } from 'utils/color';

function MultiSelect({
    columnId,
    column,
    value: cellValue,
    rowId,
    width,
    height,
    character,
    rowIndex,
    columnIndex,
    customProperties,
    options,
    background,
    fillColorBlindness,
    originalValue
}) {
    const dispatch = useDispatch();
    const tree = usePathTagTree();
    const theme = useTheme();
    const rootRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorElRef = React.useRef();
    const defaultPath = generatePath(cellValue);
    const [path, setPath] = React.useState(defaultPath);

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;

    useClickAwaitListener(
        rootRef,
        e => {
            if (checkContainId(e, id)) return;
            handleClickAway(e);
        },
        0
    );

    React.useEffect(() => {
        if (anchorElRef.current) {
            setAnchorEl(anchorElRef.current);
        }
    }, []);

    React.useEffect(() => {
        const keyDownHandler = e => {
            if (isKbEscape(e)) {
                dispatch(gridActions.cancelCellEdit());
            }

            if (isKbTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToNextColumn({ rowIndex, columnIndex }));
            }

            if (isKbShiftTab(e)) {
                e.preventDefault();
                dispatch(gridActions.cancelCellEdit());
                dispatch(gridActions.moveCellToPreviousColumn({ rowIndex, columnIndex }));
            }
        };
        document.addEventListener('keydown', keyDownHandler, true);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleClickAway = e => {
        let isClickYourSelf = checkContainId(e, `cell_${rowId}_${columnId}`);

        if (path === defaultPath) {
            return dispatch(gridActions.cancelCellEdit());
        }
        dispatch(
            gridActions.cellClickAway({
                isClickYourSelf,
                value: path,
                rowId,
                columnId
            })
        );
    };

    const handleOnChange = newPath => {
        if (newPath === path) {
            console.log('SAME PATH');
            return dispatch(gridActions.cancelCellEdit());
        }
        setPath(newPath);
        dispatch(
            gridActions.cellClickAwayAndStay({
                value: newPath,
                rowId,
                columnId
            })
        );
    };

    const handleClose = () => {
        dispatch(gridActions.cancelCellEdit());
    };

    return (
        <Grid ref={rootRef} className={'relative'}>
            <ViewCellInfo rowIndex={rowIndex} />
            <div
                ref={anchorElRef}
                style={{
                    background: background
                        ? fillColorBlindness === 'BLIND'
                            ? `${background} url(${getCellBackgroundByHex(background)})`
                            : background
                        : theme.colors.white
                }}
            >
                <ViewCellOnly
                    dimension={{ width, height }}
                    value={cellValue}
                    type={column.type}
                    customProperties={customProperties}
                    options={options}
                />
            </div>
            <Popper id={id} placement={`bottom-start`} open={open} anchorEl={anchorEl} className={'z-[999]'}>
                <Paper className={'min-w-[200px]'}>
                    <FolderPath
                        tree={tree}
                        handleClickAway={handleClose}
                        defaultPath={path}
                        onChange={handleOnChange}
                    />
                </Paper>
            </Popper>
        </Grid>
    );
}

export default React.memo(MultiSelect);
