import store from 'store/configStore';
import * as types from 'socket/types';
import { isTempId } from 'utils/uuid';
import { getCorrectColumnType } from 'utils/gridUI/formatData';
import * as columnTypes from 'const/columnTypes';
import * as translationDashboardActions from 'gridUI/actions';

const { dispatch, getState } = store;

const dependencyAllHandler = ({ body }) => {
    const { subAction } = body;
    switch (subAction) {
        case types.UPDATE:
        case types.CREATE:
        case types.DELETE:
            return _createDependencyAllHandler({ body });
        default:
            return;
    }
};

function _createDependencyAllHandler({ body }) {
    if (!document.getElementById('translation_overview')) return;
    const childColumnId = body?.data?.child;
    const { gridUI } = getState();
    const { dependencies, metaData, currentView } = gridUI;
    const dependencyWithoutFake = dependencies?.filter(dpDc => !isTempId(dpDc?.id));
    const columnDetail = metaData?.[childColumnId];
    const columnType = getCorrectColumnType(columnDetail);
    const dependencyFound = dependencyWithoutFake?.find(
        dpDc => dpDc?.parent === childColumnId || dpDc?.child === childColumnId
    );
    if (columnType === columnTypes.TRANSLATION && dependencyFound) {
        dispatch(
            translationDashboardActions.fetchTranslationDashboard({
                viewId: currentView?.id,
                dbId: body.dbId,
                successCallback: () => {},
                errorCallback: () => {}
            })
        );
    }
}

export default dependencyAllHandler;
