import React from 'react';

const LQAContainerSVG = props => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="16" height="16" rx="3" fill="#F8AD13" fillOpacity="0.8" />
            <path
                d="M11.5 3.5H9.41C9.2 2.92 8.65 2.5 8 2.5C7.35 2.5 6.8 2.92 6.59 3.5H4.5C3.95 3.5 3.5 3.95 3.5 4.5V11.5C3.5 12.05 3.95 12.5 4.5 12.5H11.5C12.05 12.5 12.5 12.05 12.5 11.5V4.5C12.5 3.95 12.05 3.5 11.5 3.5ZM8 3.5C8.275 3.5 8.5 3.725 8.5 4C8.5 4.275 8.275 4.5 8 4.5C7.725 4.5 7.5 4.275 7.5 4C7.5 3.725 7.725 3.5 8 3.5ZM9 10.5H5.5V9.5H9V10.5ZM10.5 8.5H5.5V7.5H10.5V8.5ZM10.5 6.5H5.5V5.5H10.5V6.5Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(LQAContainerSVG);
