import React from 'react';

const TBIconSVG = props => {
    return (
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="14" height="9" rx="1" fill="#2D97F2" fillOpacity="0.8" />
            <path
                d="M9.63379 6.67773C9.39648 6.96191 9.06104 7.18311 8.62744 7.34131C8.19385 7.49658 7.71338 7.57422 7.18604 7.57422C6.63232 7.57422 6.146 7.4541 5.72705 7.21387C5.31104 6.9707 4.98877 6.61914 4.76025 6.15918C4.53467 5.69922 4.41895 5.15869 4.41309 4.5376V4.10254C4.41309 3.46387 4.52002 2.91162 4.73389 2.4458C4.95068 1.97705 5.26123 1.61963 5.66553 1.37354C6.07275 1.12451 6.54883 1 7.09375 1C7.85254 1 8.4458 1.18164 8.87354 1.54492C9.30127 1.90527 9.55469 2.43115 9.63379 3.12256H8.35059C8.29199 2.75635 8.16162 2.48828 7.95947 2.31836C7.76025 2.14844 7.48486 2.06348 7.1333 2.06348C6.68506 2.06348 6.34375 2.23193 6.10938 2.56885C5.875 2.90576 5.75635 3.40674 5.75342 4.07178V4.48047C5.75342 5.15137 5.88086 5.6582 6.13574 6.00098C6.39062 6.34375 6.76416 6.51514 7.25635 6.51514C7.75146 6.51514 8.10449 6.40967 8.31543 6.19873V5.0957H7.11572V4.12451H9.63379V6.67773Z"
                fill="white"
            />
        </svg>
    );
};

export default React.memo(TBIconSVG);
