import React from 'react';

const TBSettingSVG = ({ color = '#78778B', ...other }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.33366 5.00033H1.66699V16.667C1.66699 17.5837 2.41699 18.3337 3.33366 18.3337H15.0003V16.667H3.33366V5.00033ZM16.667 1.66699H6.66699C5.75033 1.66699 5.00033 2.41699 5.00033 3.33366V13.3337C5.00033 14.2503 5.75033 15.0003 6.66699 15.0003H16.667C17.5837 15.0003 18.3337 14.2503 18.3337 13.3337V3.33366C18.3337 2.41699 17.5837 1.66699 16.667 1.66699ZM16.667 13.3337H6.66699V3.33366H16.667V13.3337ZM8.33366 7.50033H15.0003V9.16699H8.33366V7.50033ZM8.33366 10.0003H11.667V11.667H8.33366V10.0003ZM8.33366 5.00033H15.0003V6.66699H8.33366V5.00033Z"
                fill={color}
            />
        </svg>
    );
};

export default React.memo(TBSettingSVG);
