import React from 'react';

function TMSettingSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.232 13.4934V13.3829C18.232 12.9409 18.674 12.6094 19.0055 12.7199C19.558 12.7199 19.8895 13.0514 19.8895 13.4934V13.6039C19.8895 15.5928 18.232 17.2503 16.2431 17.2503H3.64641C1.65746 17.2503 0 15.5928 0 13.6039V5.86907C0 3.88012 1.65746 2.22266 3.64641 2.22266H16.2431C18.3425 2.22266 19.8895 3.88012 20 5.86907V8.85249C20 9.29448 19.558 9.62597 19.116 9.62597C18.5635 9.62597 18.232 9.29448 18.232 8.85249V5.75857C18.232 4.6536 17.3481 3.76962 16.2431 3.76962H3.64641C2.54144 3.76962 1.65746 4.6536 1.65746 5.75857V13.4934C1.65746 14.5983 2.54144 15.4823 3.64641 15.4823H16.2431C17.3481 15.4823 18.232 14.5983 18.232 13.4934ZM6.796 7.69478H8.6822V6.6671H3.66644V7.69478H5.52726V12.8247H6.796V7.69478ZM12.6491 11.1331L11.0674 6.6671H9.40961V12.8247H10.6784V11.1415L10.5515 8.24034L12.2093 12.8247H13.0805L14.7426 8.23611L14.6157 11.1415V12.8247H15.8887V6.6671H14.2224L12.6491 11.1331Z"
                fill={color}
            />
        </svg>
    );
}

export default React.memo(TMSettingSVG);
