import React from 'react';

function ElsaSVG({ color = '#FFC400', ...other }) {
    return (
        <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <rect y="0.140625" width="24" height="15.72" rx="1.8" fill="#FFC400" />
            <path
                d="M4.38117 11.2432C3.96515 11.2402 3.59601 11.1392 3.27375 10.9399C2.95441 10.7378 2.71564 10.4624 2.55744 10.1138C2.39924 9.76221 2.33771 9.37549 2.37287 8.95361L2.38605 8.77783C2.43 8.31494 2.56476 7.89014 2.79035 7.50342C3.01594 7.1167 3.30597 6.81934 3.66047 6.61133C4.01496 6.40039 4.40754 6.30078 4.8382 6.3125C5.45051 6.32422 5.9134 6.5498 6.22687 6.98926C6.54328 7.42578 6.6634 7.97949 6.58722 8.65039L6.51691 9.19092H3.60334C3.59162 9.49854 3.66633 9.74463 3.82746 9.9292C3.98859 10.1138 4.20832 10.209 4.48664 10.2148C4.93781 10.2266 5.33332 10.0522 5.67316 9.69189L6.24006 10.395C6.06135 10.6646 5.80353 10.8755 5.46662 11.0278C5.12971 11.1772 4.76789 11.249 4.38117 11.2432ZM4.77668 7.33643C4.54816 7.33057 4.34455 7.40234 4.16584 7.55176C3.99006 7.70117 3.83185 7.96045 3.69123 8.32959H5.39631L5.42267 8.21533C5.44025 8.09814 5.44172 7.98682 5.42707 7.88135C5.39777 7.71143 5.32599 7.57959 5.21174 7.48584C5.09748 7.38916 4.95246 7.33936 4.77668 7.33643Z"
                fill="#C60B1E"
            />
            <path
                d="M9.64142 9.81494C9.6883 9.58057 9.5257 9.41064 9.15363 9.30518L8.75373 9.2041C7.80744 8.94336 7.34748 8.4834 7.37385 7.82422C7.38849 7.37598 7.58625 7.01123 7.96711 6.72998C8.3509 6.4458 8.81232 6.30664 9.35139 6.3125C9.88752 6.31836 10.3211 6.46045 10.6522 6.73877C10.9832 7.01709 11.1517 7.3877 11.1575 7.85059L9.92707 7.84619C9.93293 7.43896 9.73078 7.23242 9.32062 7.22656C9.12726 7.22656 8.95881 7.2749 8.81525 7.37158C8.67463 7.46826 8.5882 7.59863 8.55597 7.7627C8.51203 7.99707 8.68928 8.1626 9.08771 8.25928L9.26349 8.29883C9.66779 8.39551 9.97687 8.50684 10.1907 8.63281C10.4046 8.75586 10.5687 8.90967 10.6829 9.09424C10.8001 9.27881 10.8543 9.5 10.8455 9.75781C10.8367 10.0596 10.7386 10.3232 10.5511 10.5488C10.3665 10.7744 10.1116 10.9487 9.78644 11.0718C9.46418 11.1948 9.12433 11.252 8.76691 11.2432C8.23957 11.2373 7.80012 11.0864 7.44855 10.7905C7.09699 10.4917 6.91828 10.1064 6.91242 9.63477L8.08137 9.64355C8.09308 10.0977 8.33625 10.3276 8.81086 10.3335C9.03937 10.3335 9.22687 10.2866 9.37336 10.1929C9.52277 10.0991 9.61213 9.97314 9.64142 9.81494Z"
                fill="#C60B1E"
            />
            <path
                d="M12.9725 10.0962H15.6883L15.5037 11.1553H11.5003L12.6121 4.75684H13.8997L12.9725 10.0962Z"
                fill="#C60B1E"
            />
            <path
                d="M20.1224 9.83691H17.89L17.2308 11.1553H15.8113L19.2303 4.75684H20.4212L21.6385 11.1553H20.3245L20.1224 9.83691ZM18.4173 8.76904H19.9598L19.5994 6.38721L18.4173 8.76904Z"
                fill="#C60B1E"
            />
        </svg>
    );
}

export default React.memo(ElsaSVG);
