import { ROW_HEIGHT, PREVIEW_IMAGE_TYPES, ADVANCED_SEARCH_TAB } from 'const/gridUI';
import { combinedData } from 'utils/gridUI/data';
import * as types from './types';
import produce from 'immer';
import { revertHandler } from './optimistic-handler';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
    columns: [],
    advancedSearchId: null,
    selectedAdvancedSearchId: null,
    advancedSearch: null,
    advancedSearchSnapshot: null,
    list: [],
    sharedList: [],
    isFetchingList: false,
    recordIds: [],
    data: {},
    columnIds: [],
    lastId: null,
    rowStartIndex: -1,
    rowStopIndex: -1,
    columnStartIndex: -1,
    columnStopIndex: -1,
    fixedColumnCount: 0,
    rowHeight: ROW_HEIGHT * 2,
    isOpenCellEdit: false,
    tableInfo: null,
    holdEvent: null,
    cellCopySelection: {
        rowStartIndex: -1,
        rowStopIndex: -1,
        columnStartIndex: -1,
        columnStopIndex: -1
    },
    doingActionIds: [],
    optimistics: [],
    isFetching: false,
    isFetchingMore: false,
    quickFilters: {},
    quickSorts: {},
    routeInfo: null,
    viewFilters: [],
    viewSorts: [],
    exportPreview: {
        advancedSearchId: {
            data: {},
            recordIds: []
        }
    },
    exportRemovedColumns: [],
    selecting: false,
    selectingCopy: false,
    columnsSelected: [],
    rowsRangeIndexes: [],
    disabledColumns: [],
    disabledSourceColumns: [],
    processingColumns: [],
    dependencies: [],
    cellPreview: null,
    pinnedSearch: false,
    replacingText: '',
    searchRecords: [],
    successReplaceAll: {},
    replacingType: null,
    isOpenReplaceSearch: false,
    searchValue: ``,
    selectedColumns: [],
    matches: [],
    globalAction: null,
    hideWarning: false,
    clearGrid: false,
    tab: ADVANCED_SEARCH_TAB.OWNER,
    isOpenConfirmation: false,
    nextAdvancedSearchId: null
};

export default function Database(state = initialState, { type, payload }) {
    switch (type) {
        case types.FETCH_COLUMNS: {
            const { columns } = payload;
            return {
                ...state,
                columns
            };
        }

        case types.CREATE_ADVANCED_SEARCH: {
            const { advancedSearchId } = payload;
            return {
                ...state,
                advancedSearchId
            };
        }

        case types.FETCH_ADVANCED_SEARCH: {
            const { advancedSearch, takeSnapshot } = payload;

            if (takeSnapshot) {
                return {
                    ...state,
                    advancedSearch,
                    advancedSearchSnapshot: advancedSearch,
                    viewFilters: advancedSearch?.filters,
                    viewSorts: advancedSearch?.sorters.map(viewSort => {
                        return {
                            ...viewSort,
                            oldValue: viewSort.direction
                        };
                    })
                };
            }

            return {
                ...state,
                advancedSearch,
                viewFilters: advancedSearch?.filters,
                viewSorts: advancedSearch?.sorters.map(viewSort => {
                    return {
                        ...viewSort,
                        oldValue: viewSort.direction
                    };
                })
            };
        }

        case types.FETCH_ADVANCED_SEARCH_RECORDS_SUCCESS: {
            const { data: storeData } = state;
            const { data, lastId, columnIds, recordIds, totalRecords } = payload;

            const newData = combinedData({ data: storeData, newData: data, isCareData: true });

            return {
                ...state,
                data: newData,
                lastId,
                columnIds,
                recordIds,
                totalRecords,
                isFetching: false,
                fixedColumnCount: 1
            };
        }

        case types.FETCH_ADVANCED_SEARCH_RECORDS_START: {
            return {
                ...state,
                isFetching: true
            };
        }

        case types.FETCH_ADVANCED_SEARCH_RECORDS_FAILED: {
            return {
                ...state,
                isFetching: false
            };
        }

        case types.CHANGE_FIXED_COLUMN_COUNT: {
            const { index } = payload;
            return {
                ...state,
                fixedColumnCount: index
            };
        }

        case types.SAVE_TABLE_INFO: {
            const { tableInfo } = payload;

            return {
                ...state,
                tableInfo
            };
        }

        case types.STARTING_CELL_COPY_SELECTION: {
            const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = payload;
            const { cellCopySelection } = state;
            const newCellCopySelection = produce(cellCopySelection, draft => {
                if (!draft) {
                    draft = {};
                }
                draft.rowStartIndex = rowStartIndex;
                draft.rowStopIndex = rowStopIndex;
                draft.columnStartIndex = columnStartIndex;
                draft.columnStopIndex = columnStopIndex;
            });

            return {
                ...state,
                cellCopySelection: newCellCopySelection
            };
        }

        case types.SELECTING_CELL_SELECTION: {
            const { rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex } = payload;

            return {
                ...state,
                rowStartIndex,
                rowStopIndex,
                columnStartIndex,
                columnStopIndex
            };
        }

        case types.REGISTER_HOLD_EVENT: {
            const { event } = payload;
            return {
                ...state,
                holdEvent: event
            };
        }

        case types.REGISTER_DOING_ACTION: {
            const { actionId } = payload;
            const { doingActionIds } = state;
            return {
                ...state,
                doingActionIds: [...doingActionIds, actionId]
            };
        }

        case types.REMOVE_DOING_ACTION: {
            const { actionId } = payload;
            const { doingActionIds } = state;
            const updatedDoingActionIds = doingActionIds.filter(dacId => actionId !== dacId);
            return {
                ...state,
                doingActionIds: updatedDoingActionIds
            };
        }

        case types.CHANGE_CELL_DATA: {
            const { data } = state;
            const { value, columnId, rowId } = payload;

            const newData = combinedData({
                data,
                newData: {
                    [rowId]: {
                        [columnId]: {
                            value
                        }
                    }
                }
            });

            return {
                ...state,
                data: newData,
                isOpenCellEdit: false
            };
        }

        case types.OPEN_CELL_EDIT: {
            return {
                ...state,
                isOpenCellEdit: true,
                isOpenCellPreview: false
            };
        }

        case types.CANCEL_CELL_EDIT: {
            return {
                ...state,
                isOpenCellEdit: false,
                character: ''
            };
        }

        case types.DELETE_CELL_INFO: {
            // const { recordIds, columnIds } = payload;
            /**
             * TODO: implement deleteCells
             */

            return {
                ...state
            };
        }

        case types.SET_CHARACTER_KEY: {
            const { character } = payload;
            return {
                ...state,
                character
            };
        }

        case types.COMMIT_ACTION: {
            const { actionId, type, body } = payload;
            const { optimistics } = state;
            const newOptimistic = {
                actionId,
                type,
                body
            };
            return {
                ...state,
                optimistics: [...optimistics, newOptimistic]
            };
        }

        case types.REMOVE_ACTION: {
            const { actionId } = payload;
            const { optimistics } = state;
            const newOptimistic = optimistics.filter(optimistic => optimistic.actionId !== actionId);
            return {
                ...state,
                optimistics: newOptimistic
            };
        }

        case types.REVERT_ACTION: {
            const { actionId } = payload;
            const { optimistics } = state;
            const found = optimistics.find(optimistic => optimistic.actionId === actionId);
            let newState = { ...state };
            if (found) {
                const { type, body, actionId } = found;
                newState = {
                    ...revertHandler({ type, body, state }),
                    optimistics: optimistics.filter(optimistic => optimistic.actionId !== actionId)
                };
            }
            return newState;
        }

        case types.FETCH_MORE_ROWS_START: {
            return {
                ...state,
                isFetchingMore: true
            };
        }

        case types.FETCH_MORE_ROWS_FAILED: {
            return {
                ...state,
                isFetchingMore: false
            };
        }

        case types.FETCH_MORE_ROWS_SUCCESS: {
            const { data: storeData, recordIds: oldRecordIds } = state;
            const { data, lastId, recordIds, totalRecords } = payload;

            const newData = combinedData({ data: storeData, newData: data, isCareData: true });

            return {
                ...state,
                data: newData,
                lastId,
                recordIds: [...oldRecordIds, ...recordIds],
                totalRecords,
                isFetchingMore: false
            };
        }

        case types.QUICK_FILTER_INPUT_CHANGE: {
            const { columnId, value, type, currentState, operator, fullPathId } = payload;
            const newQuickFilters = { ...state.quickFilters };

            newQuickFilters[columnId] = {
                ...newQuickFilters[columnId],
                type,
                value,
                currentState,
                operator,
                fullPathId
            };

            return {
                ...state,
                quickFilters: newQuickFilters
            };
        }

        case types.RESET_QUICK_FILTER_VALUE: {
            const { columnId } = payload;
            const newQuickFilters = { ...state.quickFilters };

            newQuickFilters[columnId] = {
                ...newQuickFilters[columnId],
                value: null
            };

            return {
                ...state,
                quickFilters: newQuickFilters
            };
        }

        case types.QUICK_FILTER_EXTRA_CHANGE: {
            const { extraFilter, columnId } = payload;
            const newQuickFilters = { ...state.quickFilters };

            newQuickFilters[columnId] = {
                ...newQuickFilters[columnId],
                extraFilter
            };

            return {
                ...state,
                quickFilters: newQuickFilters
            };
        }

        case types.QUICK_FILTER_REGEX_CHANGE: {
            const { filterMode, columnId } = payload;
            const newQuickFilters = { ...state.quickFilters };

            newQuickFilters[columnId] = {
                ...newQuickFilters[columnId],
                filterMode
            };

            return {
                ...state,
                quickFilters: newQuickFilters
            };
        }

        case types.CLEAR_QUICK_FILTER_AFTER_COLUMN_DELETED_OR_HIDDEN: {
            const { columnId } = payload;
            const newQuickFilters = { ...state.quickFilters };
            if (newQuickFilters[columnId]) delete newQuickFilters[columnId];
            return {
                ...state,
                quickFilters: newQuickFilters
            };
        }

        case types.CLEAR_QUICK_SORT_AFTER_COLUMN_DELETED_OR_HIDDEN: {
            const { columnId } = payload;
            let newQuickSorts = { ...state.quickSorts };
            if (newQuickSorts[columnId]) delete newQuickSorts[columnId];
            return {
                ...state,
                quickSorts: newQuickSorts
            };
        }

        case types.QUICK_SORT_CHANGE: {
            const { columnId, value } = payload;
            let newQuickSorts = { ...state.quickSorts };
            let reOrderQuickSorts = {
                [columnId]: value
            };
            if (value === '') {
                if (newQuickSorts[columnId]) delete newQuickSorts[columnId];
            } else {
                Object.keys(newQuickSorts).forEach(each => {
                    if (columnId !== each) {
                        reOrderQuickSorts[each] = newQuickSorts[each];
                    }
                });
            }

            return {
                ...state,
                quickSorts: value === '' ? newQuickSorts : reOrderQuickSorts
            };
        }

        case types.FETCH_RECORDS_WITH_FILTERS_SORTS_SUCCESS: {
            const { data, lastId, recordIds, totalRecords } = payload;

            return {
                ...state,
                data,
                lastId,
                recordIds,
                totalRecords,
                isFetching: false
            };
        }

        case types.RESET_CELL_STATUS: {
            return {
                ...state,
                isOpenCellEdit: false,
                isSelecting: false,
                rowStartIndex: -1,
                rowStopIndex: -1,
                columnStartIndex: -1,
                columnStopIndex: -1,
                character: ''
            };
        }

        case types.SET_ROUTE_INFO: {
            const { routeInfo } = payload;
            return {
                ...state,
                routeInfo
            };
        }

        case types.FETCH_LIST: {
            return {
                ...state,
                isFetchingList: true
            };
        }

        case types.FETCH_LIST_SUCCESS: {
            const { shared, list } = payload;

            if (shared) {
                return {
                    ...state,
                    sharedList: list,
                    isFetchingList: false
                };
            }

            return {
                ...state,
                list,
                isFetchingList: false
            };
        }

        case types.FETCH_LIST_FAILED: {
            return {
                ...state,
                isFetchingList: false
            };
        }

        case types.CREATE_VIEW_FILTER: {
            let { filter } = payload;
            let { viewFilters } = state;
            let newViewFilters = [...viewFilters, filter];
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.CREATE_VIEW_FILTER_FAILED: {
            let { filterId } = payload;
            let { viewFilters } = state;
            let newViewFilters = [...viewFilters].filter(filter => filter.id !== filterId);
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.CREATE_VIEW_FILTER_SUCCESS: {
            let { oldFilterId, newFilter, sourceId } = payload;
            let { viewFilters } = state;
            let newViewFilters = [...viewFilters].map(filter => {
                if (filter.id === sourceId) {
                    filter = { ...filter, groupId: newFilter.groupId };
                }
                if (filter.id === oldFilterId) {
                    filter = newFilter;
                }
                return filter;
            });
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.DELETE_VIEW_FILTER: {
            let { filterId } = payload;
            let { viewFilters } = state;
            let newViewFilters = viewFilters.map(filter => {
                if (filter.id === filterId) {
                    filter.isDeleted = true;
                }
                return filter;
            });
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.DELETE_VIEW_FILTER_FAILED: {
            let { filterId } = payload;
            let { viewFilters } = state;
            let newViewFilters = viewFilters.map(filter => {
                if (filter.id === filterId) {
                    filter.isDeleted = false;
                }
                return filter;
            });
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.DELETE_VIEW_FILTER_SUCCESS: {
            let { filterId } = payload;
            let { viewFilters } = state;
            let newViewFilters = viewFilters.filter(filter => filter?.id !== filterId);
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.UPDATE_VIEW_FILTER_SUCCESS: {
            let { filterId, newFilter } = payload;
            const { viewFilters } = state;
            let newViewFilters = viewFilters.map(filter => {
                if (filter.id === filterId) {
                    return newFilter;
                }
                return filter;
            });
            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.UPDATE_VIEW_FILTER_ID_AFTER_CREATE: {
            let { filter, oldFilterId } = payload;
            let viewFilters = cloneDeep(state.viewFilters);

            let newViewFilters = viewFilters.map(each => {
                if (each.id === oldFilterId) {
                    return filter;
                }
                return each;
            });

            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.FETCH_VIEW_FILTERS_SUCCESS: {
            const { viewFilters } = payload;
            return {
                ...state,
                viewFilters
            };
        }

        case types.UPDATE_VIEW_FILTER_ONLY: {
            let { filterId, newFilter } = payload;
            let newViewFilters = state.viewFilters.map(each => {
                if (each.id === filterId) {
                    return {
                        oldValues: each.values,
                        ...newFilter
                    };
                }
                return each;
            });

            return {
                ...state,
                viewFilters: newViewFilters
            };
        }

        case types.CREATE_VIEW_SORT: {
            let { sortOrder } = payload;
            let { viewSorts } = state;
            let newViewSorts = [...viewSorts, { ...sortOrder, oldValue: sortOrder.direction }];

            return {
                ...state,
                viewSorts: newViewSorts
            };
        }

        case types.DELETE_VIEW_SORT: {
            let { sortOrderId } = payload;
            let { viewSorts } = state;
            let newViewSorts = viewSorts.filter(sort => sort.id !== sortOrderId);

            return {
                ...state,
                viewSorts: newViewSorts
            };
        }

        case types.UPDATE_VIEW_SORT: {
            let { sortOrderId, newSortOrder } = payload;
            const { viewSorts } = state;
            let newViewSorts = viewSorts.map(each => {
                if (each.id === sortOrderId) {
                    return { oldValue: each.direction, ...newSortOrder };
                }
                return each;
            });
            return {
                ...state,
                viewSorts: newViewSorts
            };
        }

        case types.DELETE_VIEW_SORTS_AFTER_COLUMN_IS_DELETED_SOCKET: {
            const { newViewSorts } = payload;
            return {
                ...state,
                viewSorts: newViewSorts
            };
        }

        case types.FETCH_EXPORT_PREVIEW_DATA: {
            const { advancedSearchId, data, recordIds } = payload;
            const { exportPreview } = state;

            const newExportPreview = produce(exportPreview, draft => {
                if (!draft?.[advancedSearchId]) {
                    draft[advancedSearchId] = {};
                }
                draft[advancedSearchId].data = data;
                draft[advancedSearchId].recordIds = recordIds;
            });

            return {
                ...state,
                exportPreview: newExportPreview
            };
        }

        case types.RESET_EXPORT_REMOVED_COLUMNS: {
            return {
                ...state,
                exportRemovedColumns: []
            };
        }

        case types.ADD_EXPORT_REMOVED_COLUMN: {
            const { hashColumnId } = payload;
            return {
                ...state,
                exportRemovedColumns: [...state.exportRemovedColumns, hashColumnId]
            };
        }

        case types.RESTORE_EXPORT_REMOVED_COLUMN: {
            const { hashColumnId } = payload;
            return {
                ...state,
                exportRemovedColumns: [...state.exportRemovedColumns].filter(id => hashColumnId !== id)
            };
        }

        case types.SET_SELECTING: {
            const { enabled } = payload;
            return {
                ...state,
                selecting: enabled
            };
        }

        case types.SET_SELECTING_COPY: {
            const { enabled } = payload;
            return {
                ...state,
                selectingCopy: enabled
            };
        }

        case types.RESET_GRID: {
            const { ignoreFields } = payload;

            for (const field of ignoreFields) {
                initialState[field] = state?.[field];
            }

            return initialState;
        }

        case types.OPEN_CELL_FILE_PREVIEW: {
            const { rowId, columnId, defaultPreviewFile, type, dbId } = payload;
            return {
                ...state,
                cellPreview: {
                    rowId,
                    columnId,
                    defaultPreviewFile,
                    type: type || PREVIEW_IMAGE_TYPES.DEFAULT,
                    dbId
                }
            };
        }

        case types.CLOSE_CELL_FILE_PREVIEW: {
            return {
                ...state,
                cellPreview: null
            };
        }

        case types.PINNED_SEARCH: {
            return {
                ...state,
                pinnedSearch: true
            };
        }
        case types.UNPINNED_SEARCH: {
            return {
                ...state,
                pinnedSearch: false
            };
        }

        case types.SEARCHING_RECORD: {
            const { isSearchingRecord } = payload;
            return {
                ...state,
                isSearchingRecord
            };
        }

        case types.OPEN_REPLACE_SEARCH: {
            return {
                ...state,
                isOpenReplaceSearch: true
            };
        }

        case types.CLOSE_REPLACE_SEARCH: {
            return {
                ...state,
                isOpenReplaceSearch: false
            };
        }
        case types.SET_REPLACING_TEXT: {
            return {
                ...state,
                replacingText: payload?.text
            };
        }
        case types.SET_SEARCH_RECORDS: {
            return {
                ...state,
                searchRecords: payload
            };
        }

        case types.SET_SUCCESS_REPLACE_ALL: {
            return {
                ...state,
                successReplaceAll: payload
            };
        }
        case types.SET_REPLACING_TYPE: {
            return {
                ...state,
                replacingType: payload
            };
        }

        case types.SET_CLEAR_SEARCH_STATE: {
            return {
                ...state,
                searchRecords: [],
                successReplaceAll: {},
                replacingType: {}
            };
        }

        case types.SET_SEARCH: {
            const { value } = payload;

            return {
                ...state,
                searchValue: value
            };
        }

        case types.SET_SELECTED_COLUMNS: {
            return {
                ...state,
                selectedColumns: payload?.columns
            };
        }

        case types.SET_MATCHES: {
            return {
                ...state,
                matches: payload?.matches
            };
        }

        case types.UPDATE_DATA: {
            const { newData, isCareData } = payload;
            const { data } = state;

            return {
                ...state,
                data: combinedData({ data, newData, isCareData })
            };
        }

        case types.SET_GLOBAL_ACTION: {
            const { action } = payload;

            return {
                ...state,
                globalAction: action
            };
        }

        case types.DELETE_ADVANCED_SEARCH: {
            const { advancedSearchId } = payload;

            return {
                ...state,
                list: state?.list?.filter(item => item?.advancedSearchId !== advancedSearchId),
                sharedList: state?.sharedList?.filter(item => item?.advancedSearchId !== advancedSearchId)
            };
        }

        case types.SET_SELECTED_ADVANCED_SEARCH_ID: {
            const { aId } = payload;

            return {
                ...state,
                selectedAdvancedSearchId: aId
            };
        }

        case types.SET_HIDE_WARNING: {
            const { value } = payload;

            return {
                ...state,
                hideWarning: value
            };
        }

        case types.SET_CLEAR_GRID: {
            const { value } = payload;
            console.log('SET_CLEAR_GRID');
            return {
                ...state,
                clearGrid: value
            };
        }

        case types.SET_TAB: {
            const { value } = payload;
            return {
                ...state,
                tab: value
            };
        }

        case types.UPDATE_ADVANCED_SEARCH: {
            const { aId, name } = payload;
            const { advancedSearch, list, sharedList } = state;

            const newList = list?.map(item => {
                if (item?.advancedSearchId === aId) {
                    item.name = name;
                }
                return item;
            });

            const newSharedList = sharedList?.map(item => {
                if (item?.advancedSearchId === aId) {
                    item.name = name;
                }
                return item;
            });

            if (advancedSearch?.advancedSearchId === aId) {
                return {
                    ...state,
                    advancedSearch: { ...advancedSearch, name },
                    list: newList,
                    sharedList: newSharedList
                };
            }

            return {
                ...state,
                list: newList,
                sharedList: newSharedList
            };
        }

        case types.SET_ADVANCED_SEARCH_SNAPSHOT: {
            const { advancedSearch } = payload;
            return {
                ...state,
                advancedSearchSnapshot: advancedSearch
            };
        }

        case types.SET_OPEN_CONFIRMATION: {
            const { nextAdvancedSearchId, open } = payload;
            return {
                ...state,
                isOpenConfirmation: open,
                nextAdvancedSearchId
            };
        }

        case types.COLUMN_SELECTION: {
            const { columnsSelected, oldColumnIdSelected } = payload;

            return {
                ...state,
                columnsSelected,
                oldColumnIdSelected,
                oldRowIdSelected: null,
                oldRowIndexSelected: null,
                isOpenCellEdit: false,
                rowStartIndex: -1,
                rowStopIndex: -1,
                columnStartIndex: -1,
                columnStopIndex: -1,
                rowIndexMap: {},
                rowsRangeIndexes: [],
                isOpenColumnFormat: false
            };
        }

        case types.CLEAR_COLUMNS_SELECTION: {
            const { columnsSelected } = state;
            return {
                ...state,
                columnsSelected,
                oldColumnIdSelected: null
            };
        }

        case types.SET_COLUMNS_SELECTION: {
            const { columnsSelected, columnId } = payload;
            return {
                ...state,
                columnsSelected,
                oldColumnIdSelected: columnId
            };
        }

        case types.TRIGGER_RECOMPUTED_GRID: {
            return {
                ...state,
                isRecomputedGrid: true
            };
        }

        case types.RESET_TRIGGER_RECOMPUTED_GRID: {
            return {
                ...state,
                isRecomputedGrid: false
            };
        }

        case types.REORDER_COLUMNS_SUCCESS: {
            const { columnIds } = payload;
            return {
                ...state,
                columnIds
            };
        }

        case types.RESIZE_COLUMN: {
            const { columnId, columnWidthChange } = payload;
            const { advancedSearch } = state;

            const newColumns = advancedSearch?.columns?.map(column => {
                if (column?.hashColumnId === columnId) {
                    return {
                        ...column,
                        width: columnWidthChange
                    };
                }
                return column;
            });

            return {
                ...state,
                advancedSearch: {
                    ...advancedSearch,
                    columns: newColumns
                }
            };
        }

        default:
            return state;
    }
}
