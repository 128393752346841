import React from 'react';

const SleepBellSVG = props => {
    return (
        <svg width="88" height="65" viewBox="0 0 88 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M60.3334 58.9129C75.6134 58.9129 88.0003 46.526 88.0003 31.246C88.0003 15.966 75.6134 3.5791 60.3334 3.5791C45.0534 3.5791 32.6665 15.966 32.6665 31.246C32.6665 46.526 45.0534 58.9129 60.3334 58.9129Z"
                fill="#F7F8FA"
            />
            <path
                d="M40.2789 54.6521C44.1695 54.6521 47.3235 51.4981 47.3235 47.6075C47.3235 43.7169 44.1695 40.563 40.2789 40.563C36.3883 40.563 33.2344 43.7169 33.2344 47.6075C33.2344 51.4981 36.3883 54.6521 40.2789 54.6521Z"
                fill="#BFC1D9"
            />
            <path
                d="M18.1791 49.8229C19.3153 52.6067 18.5767 53.9133 17.0996 56.5834C16.4747 57.7765 15.7362 59.0831 15.0545 60.7874C14.9408 61.0147 14.9408 61.2987 14.9976 61.526C14.8272 62.0373 14.8272 62.4918 14.9976 62.8894C16.2475 65.9572 22.9512 64.1961 25.1668 63.7416C30.223 62.719 35.1087 61.0147 39.9376 59.1967C43.4031 57.8901 46.8117 56.5266 50.2204 55.0495C59.651 50.6183 69.4224 44.71 68.0022 41.3013C67.7749 40.7332 67.2068 40.3355 66.4683 40.0515C66.4115 39.9947 66.3546 39.9379 66.241 39.9379C64.6503 39.2561 63.2869 38.7448 62.037 38.3472C58.9692 37.3246 57.3217 36.7565 56.1287 33.9159L52.0951 24.0308C48.5729 15.5092 39.7672 10.169 30.9047 11.1348C29.7117 9.54406 27.3256 7.78292 23.8601 9.20319C20.3947 10.6235 19.8834 13.6344 20.1674 15.6228C13.1797 21.1335 10.5664 31.0754 13.9751 39.597L18.1791 49.8229ZM19.3153 58.9695C20.8492 57.5492 22.6103 56.3562 24.3714 55.2768C29.1435 52.3226 34.3133 49.9366 39.4831 47.7778C45.5051 45.2781 51.6975 43.0625 58.1739 41.9262C59.7646 41.6422 61.3553 41.4149 62.946 41.4718C63.5141 41.4718 64.6503 41.5286 64.9344 42.1535C64.9912 42.2103 64.9344 42.3239 64.9344 42.4375C64.8776 43.0625 64.3663 43.6306 63.9686 44.0851C61.1849 47.096 57.1513 49.198 53.5722 51.0728C51.8111 51.9818 49.9931 52.8907 48.1752 53.6861C42.1532 56.413 35.904 58.8559 29.4844 60.5034C26.9847 61.1851 24.4282 61.7532 21.8149 61.9805C20.7355 62.0941 19.0312 62.3213 18.0654 61.6396C17.9518 61.5828 17.8382 61.4692 17.7814 61.3556C17.7814 61.2987 17.7814 61.2987 17.7814 61.2419C17.6109 60.4466 18.7472 59.4808 19.3153 58.9695Z"
                fill="#DCDCED"
            />
            <path
                opacity="0.43"
                d="M44.4304 19.153C45.1092 18.1727 44.4046 16.5092 42.8568 15.4375C41.309 14.3657 39.504 14.2916 38.8252 15.2719C38.1464 16.2522 38.851 17.9157 40.3988 18.9874C41.9466 20.0592 43.7516 20.1333 44.4304 19.153Z"
                fill="white"
            />
            <path
                d="M18.1791 54.5953C18.2927 54.6521 19.4857 53.4023 19.5993 53.3455C27.2688 47.0963 36.9834 43.8012 46.13 40.4494C42.6077 41.2447 38.915 41.756 35.2791 42.0401C32.0977 42.3241 28.6891 42.6082 25.6781 41.472C21.6445 39.9381 19.5993 35.3932 18.804 31.4165C17.7246 26.1899 18.2359 20.5656 20.1674 15.623C13.1797 21.1337 10.5664 31.0756 13.9751 39.5972C15.1113 42.381 16.2475 45.1647 17.3837 47.9484C18.2927 50.2209 19.2017 52.2092 18.1222 54.5953C18.1222 54.5385 18.1791 54.5953 18.1791 54.5953Z"
                fill="#CCCEE3"
            />
            <path
                d="M24.883 31.9277C24.0308 31.9277 23.1786 31.7573 22.4401 31.3596C22.156 31.246 21.9856 31.0188 21.872 30.7347C21.7584 30.4506 21.8152 30.1666 21.9288 29.8825C22.0992 29.5417 22.4969 29.3144 22.8946 29.3144C23.065 29.3144 23.2355 29.3712 23.4059 29.428C23.8604 29.6553 24.3149 29.7689 24.8262 29.7689C25.167 29.7689 25.5079 29.7121 25.8488 29.5417C26.6441 29.2576 27.269 28.5759 27.5531 27.7805C27.7235 27.326 28.1212 27.042 28.5757 27.042C28.6893 27.042 28.8597 27.042 28.9733 27.0988C29.5415 27.326 29.8255 27.951 29.6551 28.5191C29.1438 29.9393 28.0644 31.0188 26.7009 31.5869C26.076 31.8141 25.5079 31.9277 24.883 31.9277Z"
                fill="#B4B7D1"
            />
            <path
                d="M36.4156 27.269C35.5635 27.269 34.7113 27.0986 33.9728 26.7009C33.4615 26.4169 33.2342 25.7351 33.5183 25.2238C33.6887 24.883 34.0864 24.6557 34.4841 24.6557C34.6545 24.6557 34.8249 24.7125 34.9954 24.7694C35.4498 24.9966 35.9043 25.1102 36.4156 25.1102C36.7565 25.1102 37.0974 25.0534 37.4382 24.883C38.2336 24.5989 38.8585 23.9172 39.1425 23.1218C39.313 22.6674 39.7107 22.3833 40.1651 22.3833C40.2788 22.3833 40.4492 22.3833 40.5628 22.4401C41.1309 22.6674 41.415 23.2923 41.2445 23.8604C40.7333 25.2807 39.6538 26.3601 38.2904 26.9282C37.6655 27.1554 37.0405 27.269 36.4156 27.269Z"
                fill="#B4B7D1"
            />
            <path
                d="M68.2415 29.4847H61.8219C61.2538 29.4847 60.6857 29.1438 60.4016 28.6325C60.1175 28.1212 60.1744 27.4963 60.5152 26.985L64.9465 20.5086H61.9355C61.0833 20.5086 60.3448 19.8268 60.3448 18.9179C60.3448 18.0657 61.0265 17.3271 61.9355 17.3271H67.9574C68.5255 17.3271 69.0937 17.668 69.3777 18.1793C69.6618 18.6906 69.605 19.3155 69.2641 19.8268L64.8328 26.3033H68.2415C69.0937 26.3033 69.8322 26.985 69.8322 27.894C69.8322 28.7461 69.1505 29.4847 68.2415 29.4847Z"
                fill="#BFC1D9"
            />
            <path
                d="M82.8415 16.5888H73.1837C72.6156 16.5888 72.0475 16.2479 71.7634 15.7366C71.4794 15.2253 71.5362 14.6004 71.877 14.0891L79.4329 3.1246H73.4109C72.5588 3.1246 71.8202 2.44287 71.8202 1.53389C71.8202 0.624919 72.5588 0 73.4109 0H82.4439C83.012 0 83.5801 0.340865 83.8641 0.852163C84.1482 1.36346 84.0914 1.98838 83.7505 2.49968L76.1947 13.4642H82.8415C83.6937 13.4642 84.4322 14.1459 84.4322 15.0549C84.4322 15.9638 83.6937 16.5888 82.8415 16.5888Z"
                fill="#BFC1D9"
            />
            <path
                d="M53.9704 6.5237C53.6335 6.4471 51.4931 5.96836 50.859 4.99172C50.3833 4.24488 49.8285 2.63631 49.7095 2.31076V2.27246V2.29161C49.7095 2.29161 49.7095 2.29161 49.7095 2.27246V2.29161C49.5906 2.63631 49.0357 4.22573 48.5601 4.97257C47.9259 5.96836 45.7856 6.4471 45.4487 6.5237C45.7856 6.6003 47.9259 7.07904 48.5601 8.07483C49.0357 8.82167 49.5906 10.4302 49.7095 10.7558V10.7941V10.7749V10.7558C49.8285 10.4111 50.3833 8.82167 50.859 8.07483C51.513 7.07904 53.6533 6.6003 53.9704 6.5237Z"
                fill="#DCDCED"
            />
            <path
                d="M12.4984 47.7068C12.0043 47.5945 8.86513 46.8923 7.93502 45.4599C7.23744 44.3646 6.42359 42.0053 6.24919 41.5279V41.4717V41.4998C6.24919 41.4998 6.24919 41.4998 6.24919 41.4717V41.4998C6.0748 42.0053 5.26095 44.3365 4.56336 45.4318C3.63325 46.8923 0.494122 47.5945 0 47.7068C0.494122 47.8192 3.63325 48.5213 4.56336 49.9818C5.26095 51.0772 6.0748 53.4364 6.24919 53.9139V53.9701V53.942V53.9139C6.42359 53.4083 7.23744 51.0772 7.93502 49.9818C8.8942 48.5213 12.0333 47.8192 12.4984 47.7068Z"
                fill="#EAEAF3"
            />
        </svg>
    );
};

export default React.memo(SleepBellSVG);
