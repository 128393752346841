import { Tab as MaterialTab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Tab = withStyles(theme => ({
    root: {},
    textColorPrimary: {
        color: theme.colors.secondaryText,
        fontWeight: 400
    }
}))(MaterialTab);

export default Tab;
