import React from 'react';

function MemSourceGridlySVG({ color = '#17B7FF', width = 16, height = 16, ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="310" height="214" fill="none" viewBox="0 0 310 214">
            <g filter="url(#filter0_d_2_2046)">
                <path
                    fill="#fff"
                    d="M123.654 182c10.771 0 19.503-8.721 19.503-19.479v-18.205c0-2.209 1.807-3.962 3.98-4.361C157.3 138.086 165 129.191 165 118.5c0-10.691-7.7-19.586-17.863-21.455-2.173-.4-3.98-2.152-3.98-4.361V74.478c0-10.757-8.732-19.478-19.503-19.478H35.503C24.732 55 16 63.72 16 74.478v88.043C16 173.279 24.732 182 35.503 182h88.151z"
                ></path>
                <path
                    stroke="#E9EDF1"
                    d="M142.657 162.521c0 10.481-8.507 18.979-19.003 18.979H35.503c-10.496 0-19.003-8.498-19.003-18.979V74.479c0-10.481 8.507-18.979 19.003-18.979h88.151c10.496 0 19.003 8.498 19.003 18.978v18.206c0 2.503 2.035 4.42 4.389 4.853 9.931 1.826 17.454 10.518 17.454 20.963s-7.523 19.137-17.454 20.963c-2.354.433-4.389 2.35-4.389 4.853v18.205z"
                ></path>
            </g>
            <g filter="url(#filter1_d_2_2046)">
                <path
                    fill="#fff"
                    d="M277.521 182c10.758 0 19.479-8.721 19.479-19.479V74.479C297 63.72 288.279 55 277.521 55h-88.042C178.721 55 170 63.72 170 74.478v18.206c0 2.21 1.807 3.962 3.979 4.362 10.149 1.87 17.837 10.764 17.837 21.454 0 10.69-7.688 19.583-17.837 21.454-2.172.4-3.979 2.153-3.979 4.362v18.205c0 10.758 8.721 19.479 19.479 19.479h88.042z"
                ></path>
                <path
                    stroke="#E9EDF1"
                    d="M296.5 162.521c0 10.482-8.497 18.979-18.979 18.979h-88.042c-10.482 0-18.979-8.497-18.979-18.979v-18.205c0-1.915 1.578-3.503 3.57-3.87 10.381-1.914 18.246-11.011 18.246-21.946 0-10.935-7.865-20.032-18.246-21.946-1.992-.367-3.57-1.955-3.57-3.87V74.478c0-10.481 8.497-18.978 18.979-18.978h88.042c10.482 0 18.979 8.497 18.979 18.978v88.043z"
                ></path>
            </g>
            <path
                fill="#FBAF17"
                fillRule="evenodd"
                d="M120.058 109.858l-22.809-14.1-8.496 5.229v.01a7.587 7.587 0 00-3.783 6.566 7.59 7.59 0 003.984 6.682l3.764 2.301 18.941 11.862 8.288-5.139a7.587 7.587 0 004.087-6.734 7.589 7.589 0 00-3.976-6.677z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E53182"
                fillRule="evenodd"
                d="M111.66 128.409l-18.941-11.862-12.635 7.766v20.274h.023c3.867 0 7.464-1.146 10.478-3.112"
                clipRule="evenodd"
            ></path>
            <path
                fill="#9643DC"
                fillRule="evenodd"
                d="M80.049 124.334l-9.532-5.879-22.396-13.716c-2.855 1.787-5.739 3.537-8.566 5.367-2.187 1.416-3.564 3.802-3.564 6.429a7.59 7.59 0 004.011 6.696s29.608 18.235 29.61 18.233a19.118 19.118 0 0010.471 3.123v-20.274"
                clipRule="evenodd"
            ></path>
            <path
                fill="#17C0FF"
                fillRule="evenodd"
                d="M48.121 104.739l22.398 13.717-3.257-2.01 9.25-5.556v-.006a7.586 7.586 0 003.55-6.426 7.584 7.584 0 00-3.55-6.426l-8.751-5.374-19.56 12.031-.08.05z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M207.906 87c-3.262 0-5.906 2.602-5.906 5.813v50.375c0 3.21 2.644 5.812 5.906 5.812h51.188c3.262 0 5.906-2.602 5.906-5.812V92.813c0-3.21-2.644-5.813-5.906-5.813h-51.188z"
            ></path>
            <path
                fill="#17B7FF"
                d="M258.411 87h.683c3.262 0 5.906 2.602 5.906 5.813v50.375c0 3.21-2.644 5.812-5.906 5.812h-51.188c-3.262 0-5.906-2.602-5.906-5.812V92.813c0-3.21 2.644-5.813 5.906-5.813h21.449l-8.977 17.346c1.828 3.178 5.378 6.397 10.012 8.721 4.635 2.323 9.37 3.255 13.059 2.842L258.411 87zm-41.419 27.798l1.159 21.701 18.522-11.836c-3.147.35-7.186-.446-11.14-2.427-3.953-1.983-6.979-4.725-8.541-7.438z"
            ></path>
            <rect width="64" height="64" x="201" y="86" fill="#F4F5F7" rx="8"></rect>
            <path
                fill="#101010"
                d="M238.618 96H220c.499.01.982.188 1.375.504l5.11 4.041h12.133c1.053 0 2.063.431 2.808 1.199a4.155 4.155 0 011.163 2.892v17.728a4.155 4.155 0 01-1.163 2.892 3.911 3.911 0 01-2.808 1.199h-3.529a.577.577 0 00-.416.177.618.618 0 00-.172.429v3.333c0 .161.062.315.172.428.11.114.26.178.416.178h3.529c2.223 0 4.355-.91 5.927-2.53a8.769 8.769 0 002.455-6.106v-17.728a8.77 8.77 0 00-2.455-6.106 8.26 8.26 0 00-5.927-2.53z"
            ></path>
            <path
                fill="#03EAB3"
                d="M229.139 139.504l-9.261-7.111a4.803 4.803 0 01-1.878-3.811V98.396a2.402 2.402 0 012.636-2.384c.446.044.87.212 1.225.484l9.261 7.11a4.8 4.8 0 011.878 3.81v30.182a2.4 2.4 0 01-3.861 1.906z"
            ></path>
            <circle cx="36.5" cy="9.5" r="9.5" fill="#FD9"></circle>
            <circle cx="8.5" cy="206.5" r="5.5" fill="#E1C8F5"></circle>
            <circle cx="306" cy="41" r="4" fill="#FBCBE0"></circle>
            <path
                fill="#17B7FF"
                fillOpacity="0.54"
                fillRule="evenodd"
                d="M15.273 31.836c-4.51-1.454-4.655-1.745-6.11-6.109C9.019 25.145 8.583 25 8 25c-.436 0-.873.29-1.164.727-1.454 4.364-1.6 4.655-6.109 6.11C.291 31.981 0 32.417 0 33c0 .582.29.873.727 1.164 4.218 1.309 4.655 1.745 6.11 6.109.29.436.727.727 1.163.727.436 0 .873-.29 1.164-.727 1.454-4.364 1.89-4.8 6.109-6.11.436-.29.727-.727.727-1.163 0-.436-.29-1.018-.727-1.164zM308.818 199.109c-7.327-2.364-7.563-2.836-9.927-9.927-.236-.946-.946-1.182-1.891-1.182-.709 0-1.418.473-1.891 1.182-2.364 7.091-2.6 7.563-9.927 9.927-.709.236-1.182.946-1.182 1.891 0 .945.473 1.418 1.182 1.891 6.854 2.127 7.563 2.836 9.927 9.927.473.709 1.182 1.182 1.891 1.182.709 0 1.418-.473 1.891-1.182 2.364-7.091 3.073-7.8 9.927-9.927.709-.473 1.182-1.182 1.182-1.891 0-.709-.473-1.655-1.182-1.891z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#17B7FF"
                fillOpacity="0.76"
                fillRule="evenodd"
                d="M244.455 12.127c-3.382-1.09-3.491-1.309-4.582-4.582C239.764 7.11 239.436 7 239 7c-.327 0-.655.218-.873.545-1.091 3.273-1.2 3.491-4.582 4.582-.327.11-.545.437-.545.873s.218.655.545.873c3.164.982 3.491 1.309 4.582 4.582.218.327.546.545.873.545.327 0 .655-.218.873-.546 1.091-3.272 1.418-3.6 4.582-4.581.327-.218.545-.546.545-.873 0-.327-.218-.764-.545-.873z"
                clipRule="evenodd"
            ></path>
            <defs>
                <filter
                    id="filter0_d_2_2046"
                    width="159"
                    height="137"
                    x="11"
                    y="51"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.891667 0 0 0 0 0.939815 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_2046"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_2_2046" result="shape"></feBlend>
                </filter>
                <filter
                    id="filter1_d_2_2046"
                    width="137"
                    height="137"
                    x="165"
                    y="51"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="1"></feOffset>
                    <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.891667 0 0 0 0 0.939815 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_2046"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_2_2046" result="shape"></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default React.memo(MemSourceGridlySVG);
