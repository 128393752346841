import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { USAGE_BOX, SPACING_LIST_NAME_WITH_ICON, INPUT_RADIUS } from 'const/style';
import hexToRgba from 'hex-to-rgba';
import CircularProgressWithLabel from 'components/circularPercentage/Base';

const useStyles = makeStyles(theme => ({
    root: {
        height: USAGE_BOX,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: INPUT_RADIUS,
        border: props => `1px solid ${props?.isOver ? hexToRgba(theme.colors.snackBarError, 0.5) : theme.colors.white}`,
        background: props => (props.isOver ? hexToRgba(theme.colors.snackBarError, 0.05) : theme.colors.white),
        minWidth: 200,
        width: '100%',
        boxShadow: theme.shadows[1]
    },
    icon: {
        display: 'flex',
        marginRight: SPACING_LIST_NAME_WITH_ICON
    },
    expand: {
        flex: 1
    },
    detail: {},
    opc6: {
        opacity: 0.6
    },
    overColor: {
        color: theme.colors.snackBarError
    },
    isOverUsageText: {
        fontWeight: 700
    },
    horizontalDivider: {
        width: 1,
        height: '100%',
        background: theme.colors.paleGrey,
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4)
    }
}));

function UsageBox({ isOver, color, icon, title, defaultValue, unit, actualValue, ...rest }) {
    const classes = useStyles({ isOver });

    return (
        <Grid container className={classes.root} alignItems="center" {...rest}>
            {icon && (
                <Grid item className={classes.icon}>
                    {typeof icon === 'function' ? icon() : icon}
                </Grid>
            )}
            <Grid item className={classes.expand}>
                <Typography variant="body2">{title}</Typography>
            </Grid>
            <Grid item className={classes.detail}>
                <Typography
                    className={isOver ? `${classes.isOverUsageText} ${classes.overColor}` : ''}
                    variant="body1"
                    display="inline"
                >
                    {actualValue}
                </Typography>
                <Typography className={isOver ? classes.overColor : ''} variant="body2" display="inline">
                    /{defaultValue}
                    {unit ? unit : ''}
                </Typography>
            </Grid>
            <Grid item className={classes.horizontalDivider}></Grid>
            <Grid item className={classes.percentage}>
                <CircularProgressWithLabel
                    color={color}
                    value={Math.min(100, Math.floor((actualValue / defaultValue) * 100))}
                />
            </Grid>
        </Grid>
    );
}

export default React.memo(UsageBox);
