import * as types from '../types';

const handler = {
    [types.RESET_GRID_LOADING_STATE](state) {
        return {
            ...state,
            isLoading: null
        };
    },

    [types.RENDER_GRID_UI_TABLE](state) {
        return {
            ...state,
            isLoading: true
        };
    },

    [types.RENDER_GRID_UI_TABLE_AFTER_NETWORK_CONNECTED](state) {
        return {
            ...state,
            isFetchingMore: true
        };
    },

    [types.RENDER_GRID_UI_TABLE_FAILED](state, action) {
        const { error } = action.payload;
        return {
            ...state,
            isLoading: false,
            isFetchingMore: false,
            error
        };
    },
    [types.TRIGGER_RECOMPUTED_GRID](state) {
        return {
            ...state,
            isRecomputedGrid: true
        };
    },
    [types.RESET_TRIGGER_RECOMPUTED_GRID](state) {
        return {
            ...state,
            isRecomputedGrid: false
        };
    },

    [types.RENDER_GRID_UI_TABLE_SUCCESS](state, action) {
        console.log('action.payload', action.payload);
        return {
            ...state,
            ...action.payload,
            isLoading: false,
            isFetchingMore: false
        };
    },
    [types.FETCH_GRID_LIST_SUCCESS](state, action) {
        const { grids } = action.payload;
        return {
            ...state,
            grids
        };
    },
    [types.GET_TOTAL_GRID_RECORDS_SUCCESS](state, action) {
        const { gridTotalRecords } = action.payload;
        return {
            ...state,
            gridTotalRecords
        };
    },
    [types.SET_GRID_CLEARING_RECORDS](state, action) {
        const { clearingRecords } = action.payload;
        return {
            ...state,
            clearingRecords
        };
    },

    [types.UPDATE_CURRENT_GRID](state, action) {
        const { grid } = action.payload;
        return {
            ...state,
            currentGrid: grid
        };
    }
};

export default handler;
