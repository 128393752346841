import React from 'react';

function ProjectUsageSVG({ ...other }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M39.4141 1.36001V3.49167L20 4.26964L0.585938 3.49167V1.36001C0.585938 0.932121 0.9375 0.582031 1.36719 0.582031H38.6328C39.0625 0.582031 39.4141 0.932121 39.4141 1.36001Z"
                fill="#EB4F5E"
                fillOpacity="0.8"
            />
            <path
                d="M39.4141 3.49219V27.1116C39.4141 27.5395 39.0625 27.8896 38.6328 27.8896H1.36719C0.9375 27.8896 0.585938 27.5395 0.585938 27.1116V3.49219H39.4141Z"
                fill="white"
            />
            <path
                d="M39.4141 3.49219V5.04814H2.92969C2.5 5.04814 2.14844 5.39823 2.14844 5.82612V27.8896H1.36719C0.9375 27.8896 0.585938 27.5395 0.585938 27.1116V3.49219H19.7656H39.4141Z"
                fill="white"
            />
            <path
                d="M24.1406 8.04587H5.50781C5.1875 8.04587 4.92188 7.78136 4.92188 7.46239C4.92188 7.14342 5.1875 6.87891 5.50781 6.87891H24.1406C24.4609 6.87891 24.7266 7.14342 24.7266 7.46239C24.7266 7.78914 24.4609 8.04587 24.1406 8.04587Z"
                fill="#4D3F8A"
            />
            <path
                d="M20.25 12.0107H5.50781C5.1875 12.0107 4.92188 11.7462 4.92188 11.4272C4.92188 11.1083 5.1875 10.8438 5.50781 10.8438H20.2578C20.5781 10.8438 20.8438 11.1083 20.8438 11.4272C20.8438 11.7462 20.5781 12.0107 20.25 12.0107Z"
                fill="#4D3F8A"
            />
            <path
                d="M33.9453 24.6164H27.7344C27.3047 24.6164 26.9531 24.2663 26.9531 23.8384V12.2077C26.9531 11.7798 27.3047 11.4297 27.7344 11.4297H33.9453C34.375 11.4297 34.7266 11.7798 34.7266 12.2077V23.8384C34.7266 24.2663 34.375 24.6164 33.9453 24.6164Z"
                fill="#EB4F5E"
                fillOpacity="0.8"
            />
            <path
                d="M16.7969 15.4727H23.0078C23.4375 15.4727 23.7891 15.8227 23.7891 16.2506V23.8359C23.7891 24.2638 23.4375 24.6139 23.0078 24.6139H16.7969C16.3672 24.6139 16.0156 24.2638 16.0156 23.8359V16.2506C16.0156 15.8227 16.3672 15.4727 16.7969 15.4727Z"
                fill="#EB4F5E"
                fillOpacity="0.8"
            />
            <path
                d="M6.28906 19.4414H12.5C12.9297 19.4414 13.2812 19.7915 13.2812 20.2194V23.837C13.2812 24.2649 12.9297 24.615 12.5 24.615H6.28906C5.85938 24.615 5.50781 24.2649 5.50781 23.837V20.2194C5.50781 19.7915 5.85938 19.4414 6.28906 19.4414Z"
                fill="#EB4F5E"
                fillOpacity="0.8"
            />
            <path
                d="M24.1406 6.87891H5.50781C5.1875 6.87891 4.92188 7.14342 4.92188 7.46239C4.92188 7.78136 5.1875 8.04587 5.50781 8.04587H24.1406C24.4609 8.04587 24.7266 7.78136 24.7266 7.46239C24.7266 7.14342 24.4609 6.87891 24.1406 6.87891Z"
                fill="#4D3F8A"
            />
            <path
                d="M20.8438 11.4311C20.8438 11.1122 20.5781 10.8477 20.2578 10.8477H5.50781C5.1875 10.8477 4.92188 11.1122 4.92188 11.4311C4.92188 11.7501 5.1875 12.0146 5.50781 12.0146H20.2578C20.5781 12.0146 20.8438 11.7579 20.8438 11.4311Z"
                fill="#4D3F8A"
            />
            <path
                d="M35.0781 17.4216C35.3984 17.4216 35.6641 17.1571 35.6641 16.8381V11.4311C35.6641 11.1122 35.3984 10.8477 35.0781 10.8477H27.3047C26.9844 10.8477 26.7188 11.1122 26.7188 11.4311V24.6179C26.7188 24.9368 26.9844 25.2013 27.3047 25.2013H35.0781C35.3984 25.2013 35.6641 24.9368 35.6641 24.6179V19.561C35.6641 19.242 35.3984 18.9775 35.0781 18.9775C34.7578 18.9775 34.4922 19.242 34.4922 19.561V24.0344H27.8906V12.0146H34.4922V16.8381C34.4922 17.1648 34.7578 17.4216 35.0781 17.4216Z"
                fill="#4D3F8A"
            />
            <path
                d="M16.3672 25.1988H24.1406C24.4609 25.1988 24.7266 24.9343 24.7266 24.6153V15.4741C24.7266 15.1551 24.4609 14.8906 24.1406 14.8906H16.3672C16.0469 14.8906 15.7812 15.1551 15.7812 15.4741V24.6153C15.7812 24.9421 16.0469 25.1988 16.3672 25.1988ZM16.9531 16.0576H23.5547V24.0319H16.9531V16.0576Z"
                fill="#4D3F8A"
            />
            <path
                d="M5.50781 18.8594C5.1875 18.8594 4.92188 19.1239 4.92188 19.4429V24.6164C4.92188 24.9354 5.1875 25.1999 5.50781 25.1999H13.2812C13.6016 25.1999 13.8672 24.9354 13.8672 24.6164V19.4429C13.8672 19.1239 13.6016 18.8594 13.2812 18.8594H5.50781ZM12.6953 24.0329H6.09375V20.0263H12.6953V24.0329Z"
                fill="#4D3F8A"
            />
            <path
                d="M38.6328 0H8.20312C7.88281 0 7.61719 0.264512 7.61719 0.583483C7.61719 0.902453 7.88281 1.16697 8.20312 1.16697H38.6328C38.7422 1.16697 38.8281 1.25254 38.8281 1.36146V2.90963H27.5C27.1797 2.90963 26.9141 3.17414 26.9141 3.49312C26.9141 3.81209 27.1797 4.0766 27.5 4.0766H38.8281V27.1125C38.8281 27.2214 38.7422 27.307 38.6328 27.307H1.36719C1.25781 27.307 1.17188 27.2214 1.17188 27.1125V4.0766H24.7656C25.0859 4.0766 25.3516 3.81209 25.3516 3.49312C25.3516 3.17414 25.0859 2.90963 24.7656 2.90963H1.17188V1.36146C1.17188 1.25254 1.25781 1.16697 1.36719 1.16697H5.46875C5.78906 1.16697 6.05469 0.902453 6.05469 0.583483C6.05469 0.264512 5.78906 0 5.46875 0H1.36719C0.617188 0 0 0.614602 0 1.36146V27.1125C0 27.8593 0.617188 28.4739 1.36719 28.4739H15.2812L13.6406 31.049C13.4688 31.3213 13.5469 31.6792 13.8203 31.8581C14.0859 32.0293 14.4531 31.9515 14.6328 31.6792L16.6797 28.4817H17.6953L12.0391 37.5996C11.7109 38.152 10.8438 37.623 11.1875 37.0861L13.1797 33.9665C13.3516 33.6942 13.2734 33.3363 13 33.1574C12.7266 32.9862 12.3672 33.064 12.1875 33.3363L10.1875 36.4638C9.69531 37.2184 9.95312 38.3076 10.7344 38.7588C11.4922 39.2411 12.5781 38.9844 13.0312 38.2142L15.5625 34.1298H24.6562L27.2031 38.2142C27.6563 38.9922 28.7422 39.2411 29.5 38.7588C30.2812 38.2765 30.5312 37.2495 30.0469 36.4715C30.0469 36.4715 30.0469 36.4638 30.0391 36.4638L24.9375 28.4739H38.6328C39.3828 28.4739 40 27.8593 40 27.1125V1.36146C40 0.614602 39.3828 0 38.6328 0ZM21.125 28.4739L22.5625 30.7768H17.6328L19.0625 28.4739H21.125ZM16.2812 32.9551L16.9062 31.9437H23.2891L23.9219 32.9551H16.2812ZM29.0391 37.0784C29.3828 37.623 28.5156 38.152 28.1875 37.5918L22.5078 28.4739H23.5469L29.0391 37.0784Z"
                fill="#4D3F8A"
            />
        </svg>
    );
}

export default React.memo(ProjectUsageSVG);
