import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LogoSVG from 'assets/images/svg/LogoGridUISVG';
import { Grid, Paper, Typography } from '@material-ui/core';
import { HEADER_HEIGHT } from 'const';
import HambergerMenuSVG from 'assets/images/svg/HambergerMenuSVG';
import { ENV } from 'config';
import { STAGING, DEVELOPMENT, INTEGRATION } from 'config/env';
import UserPlusSVG from 'assets/images/svg/UserPlusSVG';
import { isExternal } from 'config';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 14,
        paddingRight: 14,
        borderRadius: 0,
        height: HEADER_HEIGHT,
        background: theme.palette.secondary.main,
        position: 'relative'
    },
    header: {
        height: HEADER_HEIGHT
    },
    logoHeight: {
        height: 24,
        cursor: 'pointer'
    },
    hambergerIcon: {
        marginRight: 14
    },
    menu: {
        cursor: 'pointer'
    },
    envLabel: {
        position: 'relative',
        marginLeft: 8,
        top: -6,
        background: ENV === STAGING ? '#E94C8B' : '#32BCF0',
        borderRadius: 18,
        color: theme.colors.white,
        fontWeight: 'bold',
        padding: `2px 6px`,
        fontSize: 9,
        textTransform: 'uppercase',

        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}));

function Header({ isGridUI = false, onToggleDrawer, isShowToggle }) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles({ isGridUI });
    const theme = useTheme();

    const goToRoot = () => {
        history.push('/');
    };

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container className={classes.header} alignItems="center" justify="space-between" wrap="nowrap">
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                        {isShowToggle && !isGridUI && (
                            <Grid id="header-hamberger-icon" className={classes.hambergerIcon} item>
                                <HambergerMenuSVG
                                    color={theme.colors.white}
                                    className={classes.menu}
                                    onClick={onToggleDrawer}
                                    variant="contained"
                                />
                            </Grid>
                        )}
                        <Grid id="header-logo" onClick={goToRoot} className={classes.logoHeight}>
                            <LogoSVG className={classes.logo} />
                        </Grid>
                        {(ENV === STAGING || ENV === DEVELOPMENT || ENV === INTEGRATION) && (
                            <Grid id="header-env" item className={classes.envLabel}>
                                <span>
                                    {ENV === STAGING && 'Staging'}
                                    {ENV === DEVELOPMENT && 'Dev'}
                                    {ENV === INTEGRATION && 'Integration'}
                                </span>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {!isExternal && (
                    <Grid item>
                        <a
                            target={'_blank'}
                            href="/getstarted"
                            className="btn btn-outlined w-[164px]"
                            rel="noopener noreferrer"
                        >
                            <UserPlusSVG style={{ marginRight: 8 }} />
                            <Typography variant="body1">{t(`global_sign_up_btn`)}</Typography>
                        </a>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
}

export default React.memo(Header);
