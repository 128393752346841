import cloneDeep from 'lodash/cloneDeep';
import * as columnTypes from 'const/columnTypes';
import { getCellData } from './cell';
import { isEmpty } from 'lodash';

export let pathNodesObj = {};

export function setPathNodesObj(obj) {
    pathNodesObj = { ...obj };
}

export function getAllNodeIds(tree) {
    let ids = [];
    for (let treeItem of tree) {
        ids.push(treeItem?.id);
        const itemChildren = treeItem?.children || [];
        if (itemChildren.length > 0) {
            ids = [...ids, ...getAllNodeIds(itemChildren)];
        }
    }
    return ids;
}

export function getFirstNodeIdsWithLevel(tree, maxLevel = 1) {
    const firstNodeInTree = tree?.[0] || [];

    let ids = [];
    const children = firstNodeInTree?.children || [];

    if (children?.length) {
        ids = [firstNodeInTree?.id];
    }
    for (let treeItem of children) {
        ids.push(treeItem?.id);
        const itemChildren = treeItem?.children || [];
        if (itemChildren.length > 0 && maxLevel > 1) {
            ids = [...ids, ...getFirstNodeIdsWithLevel(itemChildren, --maxLevel)];
        }
    }
    return ids;
}

export function findAndAddNode({ tree = [], node, parentNodeId, isLast }) {
    for (let treeItem of tree) {
        const itemChildren = treeItem?.children || [];
        if (treeItem?.id === parentNodeId) {
            treeItem['children'] = isLast ? [...itemChildren, node] : [node, ...itemChildren];
            break;
        } else if (itemChildren.length > 0) {
            findAndAddNode({
                tree: itemChildren,
                parentNodeId,
                node,
                isLast
            });
        }
    }
    return cloneDeep(tree);
}

export function findAndUpdateNode({ tree = [], nodeId, updatedNode, parentNodeId }) {
    for (let treeItem of tree) {
        const itemChildren = treeItem?.children || [];
        if (treeItem?.id === parentNodeId) {
            treeItem['children'] = itemChildren?.map(node => {
                if (node.id === nodeId) {
                    return updatedNode;
                }

                return node;
            });
            break;
        } else if (itemChildren.length > 0) {
            findAndUpdateNode({
                tree: itemChildren,
                parentNodeId,
                nodeId,
                updatedNode
            });
        }
    }
    return cloneDeep(tree);
}

export function findAndRemoveNode({ tree = [], nodeId, parentNodeId }) {
    for (let treeItem of tree) {
        const itemChildren = treeItem?.children || [];
        if (treeItem?.id === parentNodeId) {
            treeItem['children'] = itemChildren?.filter(node => node.id !== nodeId);
            break;
        } else if (itemChildren.length > 0) {
            findAndRemoveNode({
                tree: itemChildren,
                parentNodeId,
                nodeId
            });
        }
    }
    return cloneDeep(tree);
}

export function findAndUpdateDeletedNode({ tree = [], nodeId, parentNodeId, isDeleted }) {
    for (let treeItem of tree) {
        const itemChildren = treeItem?.children || [];
        if (treeItem?.id === parentNodeId) {
            treeItem['children'] =
                itemChildren?.map(node => {
                    if (node.id === nodeId) {
                        node.isDeleted = isDeleted;
                    }
                    return node;
                }) || [];
            break;
        } else if (itemChildren.length > 0) {
            findAndUpdateDeletedNode({
                tree: itemChildren,
                parentNodeId,
                nodeId,
                isDeleted
            });
        }
    }
    return cloneDeep(tree);
}

export function findNode({ tree, nodeId }) {
    for (let treeItem of tree) {
        const itemChildren = treeItem?.children || [];
        if (treeItem?.id === nodeId) {
            return treeItem;
        } else if (itemChildren.length > 0) {
            const current = findNode({
                tree: itemChildren,
                nodeId
            });
            if (current) {
                return current;
            }
        }
    }
}

export function getPath({ tree, nodeId }) {
    var index = {};
    function buildIndex(root, children) {
        for (var i in children) {
            index[children[i].id] = root;
            buildIndex(children[i].id, children[i].children);
        }
    }
    buildIndex(false, tree);
    function getPath(leaf) {
        return index[leaf] ? getPath(index[leaf]).concat([leaf]) : [leaf];
    }

    const path = getPath(nodeId);
    const pathArrIdAndName = path.map(nodeId => {
        const node = findNode({ nodeId, tree });
        return {
            id: node?.id,
            name: node?.name
        };
    });

    return {
        pathArr: path,
        pathName: pathArrIdAndName.map(node => node.name).join('/'),
        pathArrIdAndName,
        name: pathArrIdAndName?.slice(-1)?.[0]?.name,
        parentPathId: path?.[path?.length - 2],
        pathId: path?.slice(-1)?.[0]
    };
}

export function findTagByPath({ tree = [], path }) {
    if (typeof path !== 'string' || !path) {
        return null;
    }
    let pathArr = path.split('/');
    let pathLength = pathArr.length;
    let currentLevelToLookupNodes = tree;
    let matchedTag = [];
    let idx = 0;

    while (idx < pathLength) {
        let currentTagValue = pathArr?.[idx];
        let currentLevelNode = null;
        for (let node of currentLevelToLookupNodes) {
            if (currentTagValue === node.name) {
                currentLevelNode = node;
                break;
            }
        }
        if (currentLevelNode == null) {
            return null;
        }
        matchedTag = [...matchedTag, currentLevelNode];
        // matchedTag = currentLevelNode;
        currentLevelToLookupNodes = currentLevelNode?.children;
        idx++;
    }
    return matchedTag;
}

export function generatePathTagRef({ tree = [] }) {
    let data = {};
    function buildPathTagData(data, tree, parentNodePath, parentChildNodeIds = []) {
        for (let treeItem of tree) {
            if (parentChildNodeIds) {
                parentChildNodeIds.forEach(arr => {
                    arr.push(treeItem.id);
                });
            }
            const childrenNodeIds = [];
            const pathName = parentNodePath ? `${parentNodePath}/${treeItem.name}` : treeItem.name;
            const itemChildren = treeItem.children || [];
            if (itemChildren.length > 0) {
                buildPathTagData(data, itemChildren, pathName, [...parentChildNodeIds, childrenNodeIds]);
            }
            data[pathName] = treeItem;
            data[pathName].parentNodeIds = treeItem.parentIdPath?.split('/') || [];
            data[pathName].childrenNodeIds = childrenNodeIds;
        }
    }
    buildPathTagData(data, tree);
    setPathNodesObj(
        Object.values(data).reduce((acc, cur) => {
            if (cur.id) {
                acc[cur.id] = cur;
            }
            return acc;
        }, {})
    );
    return data;
}

export function findChildrenByPath({ tree = [], path }) {
    if (typeof path !== 'string' || !path) {
        return tree;
    }
    let pathArr = path.split('/');
    let pathLength = pathArr.length;
    let currentLevelToLookupNodes = tree;
    let matchedTag = [];
    let idx = 0;

    while (idx < pathLength) {
        let currentTagValue = pathArr?.[idx];
        let currentLevelNode = null;
        for (let node of currentLevelToLookupNodes) {
            if (currentTagValue === node.name) {
                currentLevelNode = node;
                break;
            }
        }
        if (currentLevelNode == null) {
            return null;
        }
        matchedTag = currentLevelNode;
        currentLevelToLookupNodes = currentLevelNode?.children;
        idx++;
    }
    return matchedTag?.children || [];
}

export function findTagByPathId({ tree = [], fullPathId }) {
    if (!fullPathId) {
        return null;
    }
    let pathArr = fullPathId.split('/');
    let pathLength = pathArr.length;
    let currentLevelToLookupNodes = tree;
    let matchedTag = [];
    let idx = 0;

    while (idx < pathLength) {
        let currentTagValue = pathArr?.[idx];
        let currentLevelNode = null;
        for (let node of currentLevelToLookupNodes) {
            if (currentTagValue === node.id) {
                currentLevelNode = node;
                break;
            }
        }
        if (currentLevelNode == null) {
            return null;
        }
        matchedTag = [...matchedTag, currentLevelNode];
        currentLevelToLookupNodes = currentLevelNode?.children;
        idx++;
    }
    return matchedTag;
}

export function generatePath(nodes) {
    if (!nodes) return null;
    return nodes?.map(node => node?.name)?.join('/');
}

export function findNodeByFullPath({ tree = [], fullPath }) {
    if (typeof fullPath !== 'string' || !fullPath) {
        return null;
    }
    let pathArr = fullPath.split('/');
    let pathLength = pathArr.length;
    let currentLevelToLookupNodes = tree;
    let matchedTag = null;
    let idx = 0;

    while (idx < pathLength) {
        let currentTagValue = pathArr?.[idx];
        let currentLevelNode = null;
        for (let node of currentLevelToLookupNodes) {
            if (currentTagValue === node.name) {
                currentLevelNode = node;
                break;
            }
        }
        if (currentLevelNode == null) {
            return null;
        }
        matchedTag = currentLevelNode;
        // matchedTag = currentLevelNode;
        currentLevelToLookupNodes = currentLevelNode?.children;
        idx++;
    }
    return matchedTag;
}

export function generateNewPathTagName(tree) {
    let newPathTagName;
    for (let i = 0; i <= tree.length; i++) {
        const checkingPathTagName = `New Path ${i + 1}`;
        const found = tree.some(item => item.name === checkingPathTagName);
        if (!found) {
            newPathTagName = checkingPathTagName;
            break;
        }
    }
    return newPathTagName;
}

export function getDropRecordIntoPathData({ recordIds, data, path }) {
    let redoData = {};
    let undoData = {};
    let serverData = [];

    for (const recordId of recordIds) {
        undoData[recordId] = {
            [columnTypes.PATH_TAG]: getCellData({ rowId: recordId, data, columnId: columnTypes.PATH_TAG })
        };

        redoData[recordId] = {
            [columnTypes.PATH_TAG]: {
                value: path
            }
        };
        serverData.push([recordId, path]);
    }

    return {
        redoData,
        undoData,
        serverData
    };
}

export function getNodesByPath({ path, pathRef }) {
    if (!path || isEmpty(pathRef)) return [];
    let pathArr = path.split('/');

    return pathArr?.map((i, index) => {
        const pathCombined = pathArr?.slice(0, index + 1)?.join('/');
        return pathRef?.[pathCombined];
    });
}
