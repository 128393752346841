import React from 'react';

function SortFilterSVG({ color = '#78778B', ...other }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <g id="Toolbar/Sort">
                <path
                    id="s copy 2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 2.09998H15.5V3.76664H0.5V2.09998ZM0.5 8.76634V7.09967H9.66667V8.76634H0.5ZM6.125 12.1003H0.5V13.7669H6.125V12.1003Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default React.memo(SortFilterSVG);
