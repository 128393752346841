import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useFocused, useSelected } from 'slate-react';

const useStyles = ({ selected, focused }) =>
    makeStyles(theme => ({
        root: {
            padding: '1.5px 8px',
            margin: '0 1px',
            verticalAlign: 'baseline',
            display: 'inline-block',
            borderRadius: '22px',
            backgroundColor: '#F3F4FC',
            color: '#7869B9',
            fontSize: '12px',
            fontWeight: 500,
            boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none'
        }
    }));

const Mention = ({ attributes, children, element, mentionClassName }) => {
    const selected = useSelected();
    const focused = useFocused();
    const classes = useStyles({ selected, focused })();
    return (
        <span {...attributes} contentEditable={false} className={`${classes.root} ${mentionClassName}`}>
            {children}@ {element?.user?.fullName || element?.user?.name}
        </span>
    );
};

export default Mention;
