import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Grid, IconButton, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DEBOUNCE_TIME_SEARCHING } from 'const/gridUI';
import { useDispatch } from 'react-redux';
import { useGetAutomationDetail, useGetAutomationDetailError } from 'hooks/gridUI/automation';
import { updateActionNode, updateAutomationDetailErrorWithKey } from 'gridUI/automations/action';
import isValidEmail from 'utils/isValidEmail';
import isUrl from 'is-url';
import Payloadv2 from './Payloadv2';
import { ACTION_STATUS } from 'gridUI/automations/const';
import TooltipActionDisable from '../TooltipActionDisable';
import LDBasePortal from 'components/selects/LDBasePortal';
import MapTranslatedColumn from './MapTranslatedColumn';
import EyeCloseIconSVG from 'assets/images/svg/EyeCloseIconSVG';
import EyeOpenIconSVG from 'assets/images/svg/EyeOpenIconSVG';
import JiraBugSVG from 'assets/images/svg/webHook/JiraBugSVG';
import JiraTaskSVG from 'assets/images/svg/webHook/JiraTaskSVG';
import JiraStorySVG from 'assets/images/svg/webHook/JiraStorySVG';

const useStyles = makeStyles(theme => ({
    mt12: {
        marginTop: 12
    },
    mb14: {
        marginBottom: 14
    },
    mb24: {
        marginBottom: 24
    },
    requiredMark: {
        color: theme.colors.brightRed
    },
    errorMessage: {
        color: theme.colors.brightRed
    },
    editor: {
        maxHeight: 280,
        height: 'unset'
    },
    editorSummary: {
        minHeight: 36,
        maxHeight: 280,
        height: 'unset'
    },
    input: {
        height: 36,
        width: '100%',
        padding: '10px 14px',
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4,
        fontWeight: 400
    },
    inputError: {
        borderColor: theme.colors.brightRed
    },
    hideControl: {
        '& .MuiFormControl-root': {
            display: 'none'
        }
    },
    controlClassName: {
        minHeight: 60
    },
    flagWrapper: {
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.colors.border}`,
        borderRadius: 4
    },
    selectError: {
        borderColor: theme.colors.brightRed
    }
}));

const CustomInput = React.memo(
    ({ type = 'text', field, label, placeholder, isRequired, selectedNode, onBlur, handleChange, ...rest }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const errorMessage = useGetAutomationDetailError(selectedNode.id, field);
        const defaultValue = selectedNode?.params?.[field] || '';
        const [value, setValue] = useState(defaultValue);
        const [isShowPassword, setIsShowPassword] = useState(false);

        const isDisabled = useMemo(() => {
            return selectedNode.status === ACTION_STATUS.DISABLED;
        }, [selectedNode.status]);

        useEffect(() => {
            setValue(defaultValue);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedNode.id]);

        const onChange = useCallback(
            e => {
                const newValue = e.target.value;
                setValue(e.target.value);
                handleChange({
                    field,
                    value: newValue
                });
            },
            [field, handleChange]
        );

        const handleBlur = useCallback(
            e => {
                if (onBlur) return onBlur(e);
                const value = e.target.value;
                if (field === 'email' && !!value) {
                    dispatch(
                        updateAutomationDetailErrorWithKey({
                            key: selectedNode.id,
                            newValue: {
                                [field]: !isValidEmail(value) ? t('automation_invalid_email') : undefined
                            }
                        })
                    );
                    return;
                }
                if (field === 'domain' && !!value) {
                    dispatch(
                        updateAutomationDetailErrorWithKey({
                            key: selectedNode.id,
                            newValue: {
                                [field]: !isUrl(value) ? t('automation_invalid_url') : undefined
                            }
                        })
                    );
                    return;
                }
            },
            [dispatch, field, onBlur, selectedNode.id, t]
        );

        const handleClickShowPassword = useCallback(() => {
            setIsShowPassword(prev => !prev);
        }, []);

        return (
            <>
                <Grid item className={classes.mb14}>
                    <p className="body1">
                        {label}
                        {isRequired && <span className={classes.requiredMark}>*</span>}
                    </p>
                </Grid>
                <TooltipActionDisable isAction disabled={isDisabled}>
                    <Grid item>
                        <InputBase
                            className={classnames(classes.input, {
                                [classes.inputError]: !!errorMessage
                            })}
                            placeholder={placeholder}
                            onChange={onChange}
                            value={value}
                            onBlur={handleBlur}
                            inputProps={{
                                autoComplete: 'new-password'
                            }}
                            type={isShowPassword ? 'text' : type}
                            endAdornment={
                                type === 'password' ? (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} edge="end">
                                            {isShowPassword ? <EyeCloseIconSVG /> : <EyeOpenIconSVG />}
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }
                            {...rest}
                        />
                    </Grid>
                    {errorMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorMessage}</p>
                        </Grid>
                    )}
                </TooltipActionDisable>
            </>
        );
    }
);

const ActionJira = ({ selectedNode, setSelectedNode }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const errorIssueTypeMessage = useGetAutomationDetailError(selectedNode.id, 'issueType');
    const automationDetail = useGetAutomationDetail();
    const [issueType, setIssueType] = useState(selectedNode?.params?.issueType || '');
    const timeout = useRef();
    const savedParams = useRef({});

    const isDisabled = useMemo(() => {
        return selectedNode.status === ACTION_STATUS.DISABLED;
    }, [selectedNode.status]);

    useEffect(() => {
        savedParams.current = {};
        setIssueType(selectedNode?.params?.issueType || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNode.id]);

    const issueTypes = useMemo(() => {
        return [
            { value: 'Task', label: 'Task' },
            { value: 'Story', label: 'User story' },
            { value: 'Bug', label: 'Bug' }
        ].map(el => ({
            ...el,
            icon: () => (
                <Grid item className={classes.flagWrapper}>
                    {el.value === 'Bug' ? <JiraBugSVG /> : el.value === 'Story' ? <JiraStorySVG /> : <JiraTaskSVG />}
                </Grid>
            )
        }));
    }, [classes.flagWrapper]);

    const selectedIssueType = useMemo(() => {
        return issueTypes.find(opt => opt.value === issueType);
    }, [issueType, issueTypes]);

    const handleChangeIssueType = useCallback(
        option => {
            const newValue = option.value;
            if (newValue === issueType) return;
            setIssueType(newValue);
            dispatch(
                updateActionNode({
                    automationId: automationDetail.id,
                    nodeId: selectedNode.id,
                    body: {
                        params: {
                            ...selectedNode.params,
                            issueType: newValue
                        }
                    },
                    successCallback: responseData => {
                        setSelectedNode(responseData);
                    },
                    errorCallback: () => {
                        setIssueType(selectedNode?.params?.issueType || '');
                    }
                })
            );
        },
        [automationDetail.id, dispatch, issueType, selectedNode.id, selectedNode.params, setSelectedNode]
    );

    const handleChange = useCallback(
        ({ field, value, onSuccess, onError }) => {
            savedParams.current[field] = value;
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(
                    updateActionNode({
                        automationId: automationDetail.id,
                        nodeId: selectedNode.id,
                        body: {
                            params: {
                                ...selectedNode.params,
                                ...savedParams.current
                            }
                        },
                        successCallback: responseData => {
                            setSelectedNode(responseData);
                            onSuccess && onSuccess();
                        },
                        errorCallback: () => {
                            onError && onError();
                        }
                    })
                );
            }, DEBOUNCE_TIME_SEARCHING);
        },
        [automationDetail.id, dispatch, selectedNode, setSelectedNode]
    );

    return (
        <Grid item>
            <Grid item className={classes.mb24}>
                <CustomInput
                    field="email"
                    label={t('automation_jira_email')}
                    placeholder="Your-Jira-mail@your-company.com"
                    isRequired
                    selectedNode={selectedNode}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <CustomInput
                    type="password"
                    field="apiToken"
                    label={t('automation_jira_token')}
                    placeholder="Enter your Jira Token here"
                    isRequired
                    selectedNode={selectedNode}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <CustomInput
                    field="domain"
                    label={t('automation_jira_project_url')}
                    placeholder="http://your-domain.atlatsian.net"
                    isRequired
                    selectedNode={selectedNode}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <CustomInput
                    field="projectKey"
                    label={t('automation_jira_project_key')}
                    placeholder="Enter your project key here"
                    isRequired
                    selectedNode={selectedNode}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <Grid item className={classes.mb14}>
                    <p className="body1">
                        {t('ticket_type')}
                        <span className={classes.requiredMark}>*</span>
                    </p>
                </Grid>
                <TooltipActionDisable isAction disabled={isDisabled}>
                    <LDBasePortal
                        ddPlaceholder={'Choose ticket type'}
                        options={issueTypes}
                        onChange={handleChangeIssueType}
                        defaultValue={selectedIssueType}
                        isUsingContainer
                        controlClassName={classnames(classes.controlClassName, {
                            [classes.selectError]: !!errorIssueTypeMessage
                        })}
                        popperClassName={classnames(classes.hideControl)}
                    />
                    {errorIssueTypeMessage && (
                        <Grid item className={classes.mt12}>
                            <p className="text-error">{errorIssueTypeMessage}</p>
                        </Grid>
                    )}
                </TooltipActionDisable>
            </Grid>
            <Grid item className={classes.mb24}>
                <Payloadv2
                    isRequired
                    label={t('automation_ticket_summary')}
                    placeholder={'Enter your ticket summary here'}
                    editorClassName={classes.editorSummary}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                    messageField="issueSummary"
                />
            </Grid>
            <Grid item className={classes.mb24}>
                <Payloadv2
                    label={t('automation_ticket_description')}
                    placeholder={'Enter your ticket description here'}
                    editorClassName={classes.editor}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />
            </Grid>
            <Grid item>
                <MapTranslatedColumn
                    label={t('automation_result_column')}
                    postbackCellField="self"
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(ActionJira);
