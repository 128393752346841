import * as types from '../types';
import produce from 'immer';
import { DEFAULT_GRID_HISTORY_LIMIT, DEFAULT_GRID_HISTORY_OFFSET } from 'const/gridUI';

const handler = {
    [types.FETCH_EVENT_LOGS](state, { payload }) {
        const { branchId } = payload;
        const { historyOfGrid } = state;
        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].isFetching = true;
        });
        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.FETCH_EVENT_LOGS_FAILED](state, { payload }) {
        const { error, branchId } = payload;
        const { historyOfGrid } = state;
        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].isFetching = false;
            draft[branchId].error = error;
        });
        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.FETCH_EVENT_LOGS_SUCCESS](state, { payload }) {
        const { histories, newOffset, totalGridHistory, branchId } = payload;
        const { historyOfGrid } = state;

        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].isFetching = false;
            draft[branchId].GRID_HISTORY_OFFSET = newOffset;
            const oldGridHistory = draft?.[branchId]?.gridHistory || [];
            draft[branchId].gridHistory = [...oldGridHistory, ...histories];
            draft[branchId].totalGridHistory = totalGridHistory;
        });

        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.REFRESH_EVENT_LOGS](state, { payload }) {
        const { branchId } = payload;
        const { historyOfGrid } = state;
        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].isFetching = true;
        });

        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.REFRESH_EVENT_LOGS_FAILED](state, { payload }) {
        const { error, branchId } = payload;
        const { historyOfGrid } = state;
        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].isFetching = false;
            draft[branchId].error = error;
        });

        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.REFRESH_EVENT_LOGS_SUCCESS](state, { payload }) {
        const { histories, newOffset, totalGridHistory, branchId } = payload;

        const { historyOfGrid } = state;

        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].isFetching = false;
            draft[branchId].GRID_HISTORY_OFFSET = newOffset;
            draft[branchId].gridHistory = histories;
            draft[branchId].totalGridHistory = totalGridHistory;
        });

        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.CHANGE_BEGIN_FILTER](state, { payload }) {
        const { begin, branchId } = payload;

        const { historyOfGrid } = state;
        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].GRID_HISTORY_OFFSET = DEFAULT_GRID_HISTORY_OFFSET;
            draft[branchId].grid_begin = begin;
            draft[branchId].gridHistory = [];
        });

        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    },
    [types.RESET_GRID_HISTORY](state, { payload }) {
        const { branchId } = payload;
        const { historyOfGrid } = state;
        const newHistoryOfGrid = produce(historyOfGrid, draft => {
            if (!draft?.[branchId]) {
                draft[branchId] = {};
            }
            draft[branchId].GRID_HISTORY_OFFSET = DEFAULT_GRID_HISTORY_OFFSET;
            draft[branchId].GRID_HISTORY_LIMIT = DEFAULT_GRID_HISTORY_LIMIT;
            draft[branchId].grid_begin = null;
            draft[branchId].gridHistory = [];
            draft[branchId].totalGridHistory = 0;
        });

        return {
            ...state,
            historyOfGrid: newHistoryOfGrid
        };
    }
};

export default handler;
