import React from 'react';

function ApproveSVG({ color = '#77B32B', ...other }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
            <path
                d="M15.3001 3L13.1001 0.8C12.5001 0.3 11.8001 0 11.1001 0C10.4001 0 9.7001 0.3 9.2001 0.8L4.8001 5.2C3.7001 6.2 3.7001 8 4.8001 9.1L7.0001 11.3C7.5001 11.8 8.2001 12.1 8.9001 12.1C9.6001 12.1 10.3001 11.8 10.8001 11.3L11.7001 10.4L10.5001 9.2L9.7001 10C9.3001 10.4 8.7001 10.4 8.3001 10L6.1001 7.8C5.7001 7.4 5.7001 6.8 6.1001 6.4L10.5001 2C10.7001 1.8 10.9001 1.7 11.2001 1.7C11.5001 1.7 11.7001 1.8 11.9001 2L14.0001 4.3C14.2001 4.5 14.3001 4.7 14.3001 5C14.3001 5.3 14.2001 5.5 14.0001 5.7L13.2001 6.5L14.5001 7.8L15.3001 7C15.8001 6.5 16.1001 5.8 16.1001 5.1C16.1001 4.2 15.8001 3.5 15.3001 3Z"
                fill={color}
                fillOpacity="0.8"
            />
            <path
                d="M11.9 6.8C11.4 6.3 10.8 6 10 6C9.2 6 8.6 6.3 8.1 6.8L7.5 7.5L8.6 8.6L9.3 7.9C9.5 7.7 9.8 7.6 10 7.6C10.2 7.6 10.5 7.7 10.7 7.9L12.9 10.1C13.3 10.5 13.3 11.2 12.9 11.6L8.6 16C8.4 16.2 8.1 16.3 7.9 16.3C7.6 16.3 7.3 16.2 7.2 16L5 13.8C4.8 13.6 4.7 13.3 4.7 13.1C4.7 12.8 4.8 12.6 5 12.4L5.6 11.8L4.5 10.7L3.9 11.3C3.3 11.7 3 12.3 3 13C3 13.7 3.3 14.4 3.8 14.9L6 17.1C6.5 17.6 7.2 17.9 7.9 17.9C8.6 17.9 9.3 17.6 9.8 17.1L14.2 12.7C14.7 12.2 15 11.5 15 10.8C15 10.1 14.6 9.5 14.1 9L11.9 6.8Z"
                fill={color}
                fillOpacity="0.8"
            />
        </svg>
    );
}

export default React.memo(ApproveSVG);
