import * as types from '../types';

export function setCopiedRange({ rowStartIndex, rowStopIndex, columnStartIndex, columnStopIndex }) {
    return {
        type: types.SET_COPIED_RANGE,
        payload: {
            rowStartIndex,
            rowStopIndex,
            columnStartIndex,
            columnStopIndex
        }
    };
}
