import React from 'react';
function FillStarSVG({ color = '#ACADB9', ...other }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none" {...other}>
            <path
                d="M10.637 5.33661L13.2304 5.71038C13.9658 5.81632 14.2605 6.71967 13.7291 7.23887L11.8549 9.06996C11.6438 9.27618 11.5479 9.57299 11.598 9.86372L12.044 12.4457C12.1704 13.1779 11.4026 13.7372 10.7444 13.3923L8.42376 12.1759C8.16241 12.0389 7.85037 12.0392 7.58943 12.1768L5.27162 13.3989C4.6144 13.7455 3.84503 13.1879 3.96971 12.4554L4.40952 9.87235C4.45899 9.58148 4.36234 9.28481 4.15073 9.07912L2.27246 7.25248C1.73978 6.7345 2.03227 5.83048 2.76754 5.72278L5.36004 5.34295C5.65199 5.30022 5.90418 5.11651 6.03438 4.85178L7.19126 2.50069C7.51934 1.83403 8.46945 1.83281 8.79901 2.4988L9.96142 4.84707C10.0923 5.11138 10.3449 5.29456 10.637 5.33661Z"
                fill="#F8AD13"
                fillOpacity="0.8"
            />
        </svg>
    );
}
export default React.memo(FillStarSVG);
